import React from "react";
import { Button, FormGroup, Form } from "react-bootstrap";
import LocalizationStore from 'Stores/Localization/LocalizationStore.js';
import FundamentalTabPeriodicityType from 'Constants/FundamentalTabPeriodicityType.js';
import TabDataGraphStore from "Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore.js";

export default class FundamentalsPeriodicityControl extends React.Component {
    constructor() {
        super();
    }

    getPeriodicityButtonState(symbolInfo) {
        let periodicityStates = [];
        if (symbolInfo) {
            if (symbolInfo.IsFundamentalAnnaulReportsAvailable) { periodicityStates.push(!symbolInfo.IsFundamentalAnnaulReportsAvailable); }
            else { periodicityStates.push(true); }

            if (symbolInfo.IsFundamentalSemiAnnaulReportsAvailable) { periodicityStates.push(!symbolInfo.IsFundamentalSemiAnnaulReportsAvailable); }
            else { periodicityStates.push(true); }

            if (symbolInfo.IsFundamentalQuarterlyReportsAvailable) { periodicityStates.push(!symbolInfo.IsFundamentalQuarterlyReportsAvailable); }
            else { periodicityStates.push(true); }
        }
        return periodicityStates;
    }

    getFundamentalPeriodicityText(key) {
        if (key === 'Annual')
            return LocalizationStore.getTranslatedData('fr_annual', key);
        if (key === 'Semi Annual')
            return LocalizationStore.getTranslatedData('fr_semiAnnual', key);
        if (key === 'Quarterly')
            return LocalizationStore.getTranslatedData('fr_quarterly', key);
        return key;
    }

    render() {
        let self = this;
        let datagraphSettings = TabDataGraphStore.getState();
        let symbolInfo = datagraphSettings.SymbolInfo;
        //let settings = this.props.settings;

        let fundamentalPeriodicities = ["Annual", "Semi Annual", "Quarterly"];
        let periodicities = [FundamentalTabPeriodicityType.FundamentalsAnnual,
        FundamentalTabPeriodicityType.FundamentalsSemiAnnual,
        FundamentalTabPeriodicityType.FundamentalsQuarterly];
        let availablePeriodicities = this.getPeriodicityButtonState(symbolInfo);
        let handlePeriodicityFunc = this.props.handlePeriodicityChange;
        let currentPeriodicity = this.props.settings.FundamentalPeriodicity;

        let periodicityButtons = fundamentalPeriodicities.map((fundamentalPeriodicities, index) => {
            let handlePeriodicityChange = handlePeriodicityFunc.bind(self, periodicities[index]);
            return (
                <FormGroup key={index + 1}>
                    <Button className="periodicty-item" active={currentPeriodicity === periodicities[index]} bsSize="small" disabled={availablePeriodicities[index]} key={index + 1} data-report-periodicityid={periodicities[index]} onClick={handlePeriodicityChange}><span className="periodicity-text" rel={this.getFundamentalPeriodicityText(fundamentalPeriodicities)}>{this.getFundamentalPeriodicityText(fundamentalPeriodicities)}</span></Button>
                </FormGroup>
            );
        });

        return (<Form className="pull-left" inline><div className="actionButton">{periodicityButtons}</div></Form>
        );
    }
}
