const { ActionTypes } = SummaryBlockConstants;
import { DataGraphConstants } from '../../../Constants/DataGraphConstants.js';
import DatagraphHelper from '../../../Utils/DatagraphHelper.js';
import PeriodicityHelper from "PeriodicityHelper";
import SettingsStore from "../../../Stores/ConsoleWindow/Settings/SettingsStore.js";
import { SummaryBlockConstants } from "../../../Constants/SummaryBlockConstants";
import SummaryBlockSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/SummaryBlockSettings";
import SymbolType from '../../../Constants/SymbolType.js';

const initialState = {
    /* common */
    settingsAvailable: true,
    isOpen: false,
    symbolInfo: {},
    error: "",
    constituentsCount: "",
    instrumentStyle: {
        pointerEvents: "none",
        zIndex: 12,
        width: "790px"
    },

    /* for stocks */
    stockViewSettings: {},
    annualdata: {
        rowSource: []
    },
    weeklyData: {
        rowSource: []
    },
    estimateData: {},
    headerData: {},
    growthData: {},
    miscData: {},
    ratingData: {},
    snapshotDataReady: false,
    hasError: "",
    /*for currency */
    descriptionData: {
        description: "",
        isDescriptionAvailable: false
    },
    peggedData: {},
    countriesData: {},
    symbolContentData: {},
    currencyImages: [],
    currencyViewSettings: {},
    /* for funds */
    fundDetailItems: {
        dataSource: []
    },
    ideaList: {
        dataSource: []
    },
    stockData: {
        stockNewSource: [],
        stockDecreaseSource: [],
        stockIncreaseSource: []
    },
    /* for industrygroup */
    industryView: false,
    summaryBlockSetting: new SummaryBlockSettings()
}

const summaryReducer = (state = initialState, action) => {
    const getIsIndex = (symbolType) => {
        switch (symbolType) {
            case SymbolType.INDEXNOINTRADAYVOLDATA:
            case SymbolType.ECONOMICINDICATOR:
            case SymbolType.INDEXNOINTRADAYDATA:
            case SymbolType.INDEXNOINTRADAYVOL:
            case SymbolType.INTERNATIONALINDEX:
            case SymbolType.HISTORICINDEX:
            case SymbolType.INDEX:
                return true;
            default:
                return false;
        }
    }

    switch (action.type) {
        case ActionTypes.SET_SUMMARY_BLOCK_SETTINGS:
            return {
                ...state,
                isOpen: action.summaryBlockSetting.isOpen,
                summaryBlockSetting: action.summaryBlockSetting,
                settingsAvailable: true
            }

        case ActionTypes.UPDATE_SYMBOL_DATA:
            const data = action.data;
            let count = state.constituentsCount;
            action.currentSymbolData && getIsIndex(action.currentSymbolData.symbolInfo.SymTypeEnum) ? count = data.constituentsCount : count = ""
            return {
                ...state, ...action.currentSymbolData, constituentsCount: count
            }

        case ActionTypes.SET_SUMMARY_BLOCK_MSG:
            return {
                ...state, ...action.newState
            }

        case ActionTypes.SET_SNAPSHOT_DATA_READY:
            return {
                ...state,
                snapshotDataReady: action.snapshotDataReady
            }

        case DataGraphConstants.ActionTypes.PERIODICITY_CHANGE:
        case ActionTypes.OPEN_COLLAPSE_TRIGGERED: {
            if (action.isOpen !== undefined) {
                state.summaryBlockSetting.isOpen = action.isOpen;
                SettingsStore.saveSettings();

                return {
                    ...state,
                    isOpen: action.isOpen,
                    instrumentStyle: {
                        ...state.instrumentStyle,
                        pointerEvents: action.isOpen ? "all" : "none"
                    }
                }
            }
            else {
                /* for periodicity */
                const datagraph = DatagraphHelper.getSettingsObject(SettingsStore.getConsoleSettings())
                const tabDataGraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
                const periodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
                const summaryBlockSettings = datagraph.SummaryBlockSettings ? datagraph.SummaryBlockSettings[periodicity] : undefined;
                state.summaryBlockSetting = summaryBlockSettings;
                if (state.summaryBlockSetting !== undefined) {
                    return {
                        ...state,
                        isOpen: state.summaryBlockSetting.isOpen,
                        instrumentStyle: {
                            ...state.instrumentStyle,
                            pointerEvents: state.summaryBlockSetting.isOpen ? "all" : "none"
                        },
                        headerData: {
                            ...state.headerData,
                            showMore: state.summaryBlockSetting.showMoreStory
                        }
                    }
                }
            }
            return state
        }

        case ActionTypes.SHOW_MORE_SUMMARY:
            state.summaryBlockSetting.showMoreStory = action.showMore;
            SettingsStore.saveSettings();
            return {
                ...state,
                headerData: {
                    ...state.headerData,
                    showMore: action.showMore
                }
            }

        case ActionTypes.SET_RELATIVE_YTD:
            state.summaryBlockSetting.isYtdAbsolute = action.isYtdAbsolute
            SettingsStore.saveSettings();
            return {
                ...state,
                fundDetailItems: {
                    ...state.fundDetailItems,
                    isYtdAbsolute: action.isYtdAbsolute
                }
            }

        case ActionTypes.CLOSE_SUMMARY_BLOCK: {
            return { ...state, summaryBlockSetting: undefined, settingsAvailable: false, isOpen: false }
        }

        default:
            return state;
    }


}

export default summaryReducer