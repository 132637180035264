import { ComparisonAndRotationMenuConstants } from "../../../Constants/RotationGraphConstants";
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import { SettingsConstants } from "Constants/SettingsConstants.js";
import { extend, map } from "underscore";
import CompareTabType from "CompareTabType";
extend(ActionTypes, SettingsConstants.ActionTypes);

let initialState = {
  searchtext: '',
  searchResult: [],
  isOpen: false,
  resultAvail: false,
  activeIndex: -1,
  columnWidths: 0,
  menuWidth: 0,
  hasErrors: false,
  errorMsg: "",
  enterPressed: false
}


initialState = {ComparisonMenuStates: {...initialState},RotationMenuStates:{...initialState}};

const addSymbol = (state = initialState, action) => {
  let results;
  let isOpen;
  let localStateData;

  const setStateData = (obj) => {
    if (state.selectedTab === CompareTabType.ComparisonGraph) {
        map(obj, (value, property) => state.ComparisonMenuStates[property] = value);
    }
    else {
        map(obj, (value, property) => state.RotationMenuStates[property] = value);
    }

    return {...state};
}


const getStateData = () => {

    if (state.selectedTab === CompareTabType.ComparisonGraph) {
       return state.ComparisonMenuStates ;
    }
    else {
      return state.RotationMenuStates ;
    }
}

switch (action.type) {

    case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
        return {
            ...state,
            selectedTab: action.selectedTab
        }

    case ActionTypes.SEARCH_SYMBOL:
      return setStateData({ searchtext: `${action.text}`, hasErrors: false, errorMsg: "" });

    case ActionTypes.SEARCH_SYMBOL_API_SUCCESS:
      results = action.returnData.searchResult.results;
      localStateData = getStateData();
      if (!localStateData.enterPressed) {
        isOpen = true;
      } else {
        isOpen = false;
      }
      const withOutInactiveResults = results && results.filter((r) => r.active); //fix PANWEB-2356
      return setStateData({
        searchResult: withOutInactiveResults,
        isOpen: isOpen,
        resultAvail: action.returnData.resultAvail,
        activeIndex: -1,
        columnWidths: action.returnData.columnWidths,
        menuWidth: action.returnData.menuWidth,
        enterPressed: false
      });
    
    case ActionTypes.HIDE_DROPDOWN:
      return setStateData({ isOpen: false });

    case ActionTypes.CLEAR_SYMBOL_SEARCH_TEXT:
      return setStateData({searchtext:'' });

    case ActionTypes.HANDLE_ARROW_KEYS:
      return setStateData({ activeIndex: action.index });

    case ActionTypes.HIDE_ERROR_MSG:
      return setStateData({hasErrors: false, errorMsg: "", searchtext: '', isOpen: false });

    case ActionTypes.SET_ERROR:
      return setStateData({
        hasErrors: action.hasErrors,
        errorMsg: action.errorMsg,
        searchtext: ''
      });

    default:
      return state
  }
}
export default addSymbol;
