const ListManagerListCategory = {
    FAVORITE_ListCategory: 'Favorites',
    USER_ListCategory: 'User',
    DATABASELIST_ListCategory: 'DbList',
    APLIST_ListCategory: 'ApList',
    PORTFOLIOLIST_ListCategory: 'PortfolioList',
    VARIABLELIST_ListCategory: 'Variable',
    THEMELIST_ListCategory: 'Theme',
    POSITIONHISTORYLIST_ListCategory: 'PositionHistory',
    TRANSACTIONSLIST_ListCategory: 'Transactions',
    SHAREDLIST_ListCategory: 'Share',
    SMARTLIST_ListCategory: 'Smart',
    PRESET_ListCategory: 'Preset',
    FUTURESCASHSPOTSLIST_ListCategory: 'FutureCashSpot'
}

export default ListManagerListCategory;
