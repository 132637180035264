import Serializable from "Serializable";
import { CompareMenuType } from "CompareTabType";
import ComparisonGroupsMenuSettings from './ComparisonGroupsMenuSettings';
import ComparisonSymbolsMenuSettings from './ComparisonSymbolsMenuSettings';

class TabComparisonGraphSettings extends Serializable {
  constructor() {
    super();
  }

  onSeralisedInfoRecieved(serializedValue) {
    this.comparisonMenuTab = (serializedValue.comparisonMenuTab != undefined && serializedValue.comparisonMenuTab != null) ? serializedValue.comparisonMenuTab : this.getDefaultComparisonMenuTab();
    this.isComparisonMenuPanelOpen = serializedValue.isComparisonMenuPanelOpen !== '' ? serializedValue.isComparisonMenuPanelOpen : this.getDefaultIsCompMenuOpen();
    this.comparisonGroupsMenuSettings = serializedValue.comparisonGroupsMenuSettings ? serializedValue.comparisonGroupsMenuSettings : this.getDefaultComparisonGroupsMenuSettings();
    this.comparisonSymbolsMenuSettings = serializedValue.comparisonSymbolsMenuSettings ? serializedValue.comparisonSymbolsMenuSettings : this.getDefaultComparisonSymbolsMenuSettings();
  }

  getDataObject() {
    return {
      comparisonMenuTab: this.comparisonMenuTab,
      isComparisonMenuPanelOpen: this.isComparisonMenuPanelOpen,
      comparisonGroupsMenuSettings: this.comparisonGroupsMenuSettings,
      comparisonSymbolsMenuSettings: this.comparisonSymbolsMenuSettings
    };
  }

  getObjectMapKey() {
    return "TabComparisonGraphSettings";
  }

  getDefaultTabComparisonGraphSettings() {
    this.comparisonMenuTab = this.getDefaultComparisonMenuTab();
    this.isComparisonMenuPanelOpen = this.getDefaultIsCompMenuOpen();
    this.comparisonGroupsMenuSettings = this.getDefaultComparisonGroupsMenuSettings();
    this.comparisonSymbolsMenuSettings = this.getDefaultComparisonSymbolsMenuSettings();
    return this;
  }

  getDefaultComparisonMenuTab() {
    return CompareMenuType.Symbols;
  }

  getDefaultComparisonGroupsMenuSettings() {
    let tabComparisonGroupsMenuSettings = new ComparisonGroupsMenuSettings();
    return tabComparisonGroupsMenuSettings.getDefaultComparisonGroupsMenuSettings();
  }

  getDefaultComparisonSymbolsMenuSettings() {
    let tabComparisonSymbolsMenuSettings = new ComparisonSymbolsMenuSettings();
    return tabComparisonSymbolsMenuSettings.getDefaultComparisonSymbolsMenuSettings();
  }

  getDefaultIsCompMenuOpen() {
    return true;
  }

}

export default TabComparisonGraphSettings;
