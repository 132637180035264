import AddAnchor from '../Presentational/CustomTransformerControl.jsx';
import BrowserUtil from "BrowserUtil";
import PropTypes from 'prop-types';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { Line, Group, Image } from 'react-konva';
import React, { Component } from 'react'

class LineControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isImageLoading: false
        }
    }
    componentDidMount() {
        if (this.props.line.isAlertChecked && this.props.showAlert) {
            TimeTrackingWindow.setIsAlertImageLoading()
            if (this.props.defaultImage.complete) {
                TimeTrackingWindow.endAlertImageLoading();
            }
            else {
                this.props.defaultImage.onload = () => {
                    this.setState({ isImageLoading: !this.state.isImageLoading });
                    TimeTrackingWindow.endAlertImageLoading();
                }

            }

        }
    }

    handleAnchorDragEnd = () => {
        if (this.props.activeAnchor !== '') {
            this.props.handleAnchorDragEnd();
        }
    }

    render() {
        const { id, color, stroke, weight, opacity, x, y, startMouseX, startMouseY, endMouseX, endMouseY,
            currentMouseX, currentMouseY, startExtendPoint, endExtendPoint, startExtendChecked, endExtendChecked,
            startArrowChecked, endArrowChecked, startArrowPoint, endArrowPoint, isAlertChecked } = this.props.line;

        const { isDragging, isAlertHover, hoveredAnnotId, index, defaultImage, hoverImage, draggable, lockImage, showAlert, isApiReqResolved } = this.props;

        const selectedIds = this.props.selectedAnnotId;
        const lineColor = ThemeHelper.getThemedBrush(color);
        let alertImgWidth = 12;
        let alertImgHeight = 14;
        let alertImgXPosition = 18;

        if (BrowserUtil.getBrowserName() === "IE" || BrowserUtil.getBrowserName() === "Netscape" || BrowserUtil.getBrowserName() === "Firefox") {
            alertImgWidth = 21;
            alertImgHeight = 13;
            alertImgXPosition = 20;
        }
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            <Group>

                {/* normal line from exact cursor start and end position */}
                <Line
                    id={id}
                    shape={RayAnnotationType.LINE_AnnotationType}
                    index={index}
                    x={x}
                    y={y}
                    stroke={lineColor}
                    strokeWidth={weight}
                    opacity={opacity}
                    dash={stroke}
                    points={[startMouseX, startMouseY, currentMouseX, currentMouseY]}
                    draggable={draggable && isApiReqResolved}
                    onDragStart={() => this.props.handleDragStart(this.props.line)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.LINE_AnnotationType) : undefined}
                />

                {/* shadow line - which activates the cursor upon reaching the shadow point. Shadow is required only for thin lines (ex: width < 6)  */}
                {weight < 6 &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.LINE_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        stroke={lineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        points={[startMouseX, startMouseY, currentMouseX, currentMouseY]}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.line)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.LINE_AnnotationType) : undefined}
                    />}

                {/* extended line from cursor start position */}
                {startExtendChecked && startExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.LINE_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        stroke={lineColor}
                        strokeWidth={weight}
                        opacity={opacity}
                        dash={stroke}
                        points={[0, 0, startExtendPoint.x - x, startExtendPoint.y - y]}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.line)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.LINE_AnnotationType) : undefined}
                    />}

                {/* shadow for start extended line */}
                {weight < 6 && startExtendChecked && startExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.LINE_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        stroke={lineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        points={[0, 0, startExtendPoint.x - x, startExtendPoint.y - y]}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.line)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.LINE_AnnotationType) : undefined}
                    />}

                {endExtendChecked && endExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.LINE_AnnotationType}
                        index={index}
                        x={endMouseX}
                        y={endMouseY}
                        stroke={lineColor}
                        strokeWidth={weight}
                        opacity={opacity}
                        dash={stroke}
                        points={[0, 0, endExtendPoint.x - endMouseX, endExtendPoint.y - endMouseY]}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.line)}
                        onDragMove={(e) => this.props.handleDragMove(e, true)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.LINE_AnnotationType) : undefined}
                    />}

                {/* shadow for end extended line */}
                {weight < 6 && endExtendChecked && endExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.LINE_AnnotationType}
                        index={index}
                        x={endMouseX}
                        y={endMouseY}
                        stroke={lineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        points={[0, 0, endExtendPoint.x - endMouseX, endExtendPoint.y - endMouseY]}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.line)}
                        onDragMove={(e) => this.props.handleDragMove(e, true)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.LINE_AnnotationType) : undefined}
                    />}

                { /* arrow cap at the start point of line */}
                {startArrowChecked && startArrowPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.LINE_AnnotationType}
                        index={index}
                        x={startArrowPoint.offSet.x}
                        y={startArrowPoint.offSet.y}
                        offsetX={startArrowPoint.offSet.x}
                        offsetY={startArrowPoint.offSet.y}
                        stroke={lineColor}
                        strokeWidth={1}
                        fill={lineColor}
                        opacity={opacity}
                        points={[startArrowPoint.p.x, startArrowPoint.p.y, startArrowPoint.lPoint.x, startArrowPoint.lPoint.y, startArrowPoint.uPoint.x, startArrowPoint.uPoint.y]}
                        rotation={startArrowPoint.angle}
                        closed
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.line)}
                        onDragMove={(e) => this.props.handleDragMove(e, false, false, true)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    />}

                { /* arrow cap at the end point of line */}
                {endArrowChecked && endArrowPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.LINE_AnnotationType}
                        index={index}
                        x={endArrowPoint.offSet.x}
                        y={endArrowPoint.offSet.y}
                        offsetX={endArrowPoint.offSet.x}
                        offsetY={endArrowPoint.offSet.y}
                        stroke={lineColor}
                        strokeWidth={1}
                        fill={lineColor}
                        opacity={opacity}
                        points={[endArrowPoint.p.x, endArrowPoint.p.y, endArrowPoint.lPoint.x, endArrowPoint.lPoint.y, endArrowPoint.uPoint.x, endArrowPoint.uPoint.y]}
                        rotation={endArrowPoint.angle}
                        closed
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.line)}
                        onDragMove={(e) => this.props.handleDragMove(e, false, false, false, true)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    />}

                {

                    isAlertChecked && showAlert && <Image height={alertImgHeight} width={alertImgWidth} image={isAlertHover && id === hoveredAnnotId ? hoverImage : defaultImage} x={x - alertImgXPosition} y={y - 7}
                        onMouseEnter={() => this.props.highlightAlert(id)} onMouseLeave={this.props.removeHighlightAlert} />
                }

                {selectedIds.includes(id) && !isDragging &&
                    <>
                        <AddAnchor x={x} y={y} annotSelected={id} type={RayAnnotationType.LINE_AnnotationType}
                            handleAnchorDragStart={this.props.handleAnchorDragStart}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'start'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={x - 3} y={y - 3} />}
                        <AddAnchor x={endMouseX} y={endMouseY} annotSelected={id} type={RayAnnotationType.LINE_AnnotationType}
                            handleAnchorDragStart={this.props.handleAnchorDragStart}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'end'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - 3} y={endMouseY - 3} />}
                    </>
                }
            </Group>
        );
    }
}

LineControl.propTypes = {
    index: PropTypes.number.isRequired,
    line: PropTypes.object.isRequired,
    handleDragEnd: PropTypes.func.isRequired,
    handleDragStart: PropTypes.func.isRequired
}

export default LineControl;