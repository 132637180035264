import React from "react";
import ExplorerTop from "../Explorer/ExplorerTop.jsx";
import ExplorerBottom from "../Explorer/ExplorerBottom.jsx";
import OwnershipListView from "../ListManager/OwnershipListView.jsx";
import OwnershipViewStore from "OwnershipViewStore";
import ListProperty from "../../../../RayCustomControls/ListExplorer/ListProperty.jsx"
import { setActiveNodeToOwnershipStore, reloadListCatalog, setShowOriginalActiveNode } from "../../../../Actions/OwnerShipActions";
import ListManagerTabType from "ListManagerTabType";
import { ListExplorerConstants } from "ListExplorerConstants";
import ListExplorerUtil from "ListExplorerUtil";
import ListStore from "ListStore";
import GridStore from "GridStore";
import ResizeActions from "Actions/ResizeActions.js";
import BaseServiceApi from 'BaseServiceApi';
import StringUtil from "StringUtil";
import ResizableWindow from "RayCustomControls/ResizableWindow/ResizableWindow.jsx";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import ListExplorerStore from "ListExplorerStore";
import ScrollBar from "ScrollBar";
import { OwnershipViewConstants, OwnerShipFetchDetails } from "../../../../Constants/OwnershipConstants";
import { isDeleteAllowed, findActiveListAvailibility,
   expandAndFindParentNode,isExistInUserListCategory,
   getListInFavorite,getListByCategory, getParentId } from "Utils/OwnerShipUtil.js";
import { dispatch } from "../../../../Redux/dispatch";
import Owner from "../../../../RayCustomControls/OwnerShip/Owner.jsx"
import store from "../../../../Redux/store.js";
import { setActiveNode,updateOwnership,setSelectedNode,deleteSelectedItem } from "../../../../Actions/ListViewActions";
import TabOwnershipSettings from '../../../../Stores/ConsoleWindow/Settings/Modules/ListManager/TabOwnershipSettings.js';
import SettingsAction from "SettingsAction";
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";
import ContextMenuConstants from "ContextMenuConstants";

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

export default class TabOwnership extends React.Component {

  constructor(props) {
    super(props);
    this.ownershipTableData = this.ownershipTableData.bind(this);
    this.ownershipViewStoreStateChange = this.ownershipViewStoreStateChange.bind(this);
    this.addNewItem = this.addNewItem.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onStopResize = this.onStopResize.bind(this);
    this.showExplorer = this.showExplorer.bind(this);
    this.showOriginalClick = this.showOriginalClick.bind(this);
    this.loadingListExplorer = this.loadingListExplorer.bind(this);
    this.listStoreStateChange = this.listStoreStateChange.bind(this);
    this.fetchListCataglogData = this.fetchListCataglogData.bind(this);
    this.onGridColumnContextMenuClick = this.onGridColumnContextMenuClick.bind(this);
    this.onGridCustomFilterContextMenuClick = this.onGridCustomFilterContextMenuClick.bind(this);
    this.onGridRowContextMenuClick = this.onGridRowContextMenuClick.bind(this);
    this.updateScrollBar = this.updateScrollBar.bind(this);
    this.reloadExplorer = this.reloadExplorer.bind(this);
    this.listExplorerStateChange = this.listExplorerStateChange.bind(this);
    this.onExplorerContextMenuClick = this.onExplorerContextMenuClick.bind(this);

    this.state = {
      addNewItemToList: false,
      showHelp: false,
      listId: this.props.listId,
      actualListId: this.props.actualListId,
      nodeId: this.props.actualListId,
      showContextMenu: false,
      deleteEnable: false,
      isDelete: false,
      deleteNode: null,
      listExplorerWidth: ListStore.getListExplorerSettings().Width,
      resizeHandle: false,
      showTable: false,
      tableData: null,
      ownerShipData: [],
      ownerShipData1: [],
      activeNode: this.props.listId,
      reLoad: false
    }
    this.rootParentId = null;
    this.parentId = null;
    this.selectedNodeId = null;
    this.categoryType = null;
    this.listType = null;
    this.nodeType = null;
    this.listExplorerSettings = ListStore.getListExplorerSettings();
    this.showExplorerWindow = true;
    this.listApiCalled = false;
  }

  UNSAFE_componentWillMount() {
    ListStore.addChangeListener(this.listStoreStateChange);
    ListExplorerStore.addChangeListener(this.listExplorerStateChange);
    OwnershipViewStore.addChangeListener(this.ownershipViewStoreStateChange);
  }

  componentDidMount() {
    const state = OwnershipViewStore.getState();
    if (OwnershipViewStore.initialCall === true || state.actualListId !== this.state.listId || this.props.nodeType === ListCategoryType.TEMPORARYLIST_ListCategory || this.props.nodeType == ListCategoryType.NOTIMPORTEDLIST_ListCategory) { // make basic call if user switch to list manager immediate after sign in or user has changed list item on mini list
      const listDataSource = ListExplorerStore.getDataSource();
        const listAvailable = findActiveListAvailibility(listDataSource, this.state.actualListId);
        if(listDataSource !== null){
        if (!listAvailable && state.listId === null) {
          this.listId = this.state.listId;
          const tabOwnershipSettings = new TabOwnershipSettings();
          const readyData = tabOwnershipSettings.getDefaultSelectedListInfo();
          dispatch(setActiveNode(readyData.SelectedListId));
          expandAndFindParentNode(readyData.SelectedListId, listDataSource);
          const newActiveNode = getParentId(parseInt(readyData.SelectedListId), listDataSource); 
          setActiveNodeToOwnershipStore(newActiveNode);
          this.fetchListCataglogData(readyData.SelectedListId, readyData.SelectedActualListId, readyData.SelectedListName, true, 0);
        }
        else {
          this.listId = this.state.listId;
          expandAndFindParentNode(this.state.listId, listDataSource);
          const newActiveNode = getParentId(parseInt(this.state.listId), listDataSource); 
          setActiveNodeToOwnershipStore(newActiveNode);
          if (this.props.nodeType === ListCategoryType.FAVORITE_ListCategory && this.props.actualListId !== this.listId) {
            this.refs.OwnershipListView.getListCatalog(this.state.actualListId, this.state.listId, this.props.listName);
          } else{
            this.refs.OwnershipListView.getListCatalog(this.state.listId, this.state.actualListId, this.props.listName); //actual ListId appended
          }
          dispatch(setActiveNode(typeof (this.state.listId) === 'object' ? this.state.listId.low : this.state.listId));
        }
        this.listApiCalled = true;
      }
      dispatch(updateOwnership(true));
    }
    else { // Do not make basic call if user switch from symbol to list and has not changed list item on mini list
      if (this.refs.OwnershipListView.listCatalog.listId !== this.state.listId) {
        this.refs.OwnershipListView.listCatalog = {
          ...this.refs.OwnershipListView.listCatalog, searchText: '', listId: this.state.listId, actualListId: this.state.actualListId, listName: this.props.listName
        }
      }
      this.listApiCalled = true;
      reloadListCatalog();
    }
  }

  componentWillUnmount() {
    ListStore.removeChangeListener(this.listStoreStateChange);
    ListExplorerStore.removeChangeListener(this.listExplorerStateChange);
    OwnershipViewStore.removeChangeListener(this.ownershipViewStoreStateChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.listApiCalled) {
      const listDataSource = ListExplorerStore.getDataSource();
      if (listDataSource !== null) {
        let listAvailable = ListExplorerStore.findActiveListAvailibility(this.props.listId);
        if (!listAvailable) {
          const tabOwnershipSettings = new TabOwnershipSettings();
          const readyData = tabOwnershipSettings.getDefaultSelectedListInfo();
          this.listId = this.state.listId;
          this.fetchListCataglogData(readyData.SelectedListId, readyData.SelectedActualListId, readyData.SelectedListName, true, 0);
        } else {
          this.listId = this.state.listId;
          // if the list is inside Favourite list then send actualListId first i.e. id of it's parent for the get API call
          if (this.props.nodeType === ListCategoryType.FAVORITE_ListCategory && this.props.actualListId !== this.listId) {
            this.refs.OwnershipListView.getListCatalog(this.state.actualListId, this.state.listId, this.props.listName);
          } else {
            // In this case i.e. non Favuorite Lists the listIS and actualListId will be same
            this.refs.OwnershipListView.getListCatalog(this.state.listId, this.state.actualListId, this.props.listName); //actual ListId appended
          }
        }
        this.listApiCalled = true;
      }
    }

    if (nextProps.loadCompleteDb === true) {
      this.refs.OwnershipListView.resetListCatelogData();
      OwnershipViewStore.setListCatalogSearchKeyword('');
      this.fetchListCataglogData(-2, -2, LocalizationStore.getTranslatedData("LM_ownershipDB", "Ownership DB Results"));
     // dispatch(setActiveNode(-2));
    }
    this.setState({ addNewItem: nextProps.addNewItem });
  }

  listExplorerStateChange() {
    let currentAction = ListExplorerStore.getCurrentAction();
    if (currentAction == ListExplorerConstants.ActionTypes.GET_NOT_IMPORTED_LIST_DATA) {
      const activenode = OwnershipViewStore.activeNode;
      dispatch(setActiveNode(typeof activenode.nodeId == 'object' ? activenode.nodeId.low : activenode.nodeId));
      this.fetchListCataglogData(activenode.nodeId, activenode.nodeId, activenode.name);
    }
  }

  updateScrollBar() {
    this.forceUpdate();
  }

  onExplorerContextMenuClick(e, existAlerts) {
    if(e == ContextMenuConstants.DELETE && OwnershipViewStore.isListLoaded)
      dispatch(deleteSelectedItem());
  }

  showOriginalClick(node) {
    this.showOriginal(node);
  }

  showOriginal(node) {    
    setActiveNodeToOwnershipStore(node);
    setShowOriginalActiveNode(node.sourceListId.low);
    dispatch(updateOwnership(true)); 
  }

  onGridColumnContextMenuClick(e, listId, dataItemId, colSeq, quoteBoard, isFrozen, previousColumnDataItemId, displayName, isVisualizationOn) {
    e.preventDefault();
    const obj = {
      contextMenuType: 'gridColumnHeader', xPos: e.clientX, yPos: e.clientY - 25, listId: listId, dataItemId: dataItemId, displayName: displayName, colSeq: colSeq,
      quoteBoard: quoteBoard, isFrozen: isFrozen, previousColumnDataItemId: previousColumnDataItemId, isVisualizationOn: isVisualizationOn, showContextMenu: true,
    }
    this.props.onContextMenuClick(obj);
  }

  onGridCustomFilterContextMenuClick(e) {
    e.preventDefault();    
    const obj = {
      contextMenuType: 'gridCustomFilter', xPos: e.clientX, yPos: e.clientY - 25, showContextMenu: true
    };
    this.props.onContextMenuClick(obj);
  }

  onGridRowContextMenuClick(e, selectedIndexes) {
    e.preventDefault();    
    const obj = {
      contextMenuType: 'gridRow', xPos: e.clientX, yPos: e.clientY - 25, listId: this.state.actualListId,
      selectedIndexes: selectedIndexes, showContextMenu: true
    };
    this.props.onContextMenuClick(obj);
  }

  ownershipTableData(activeNode) {      
    if (!activeNode) return;
    setActiveNodeToOwnershipStore(activeNode);
    let isUserList = 0;
    if(activeNode.categoryType === ListCategoryType.USER_ListCategory)isUserList=1;
    const sourceListId = activeNode.sourceListId !== null && activeNode.sourceListId.low ? activeNode.sourceListId.low : activeNode.nodeId.low;
    const nodeId = typeof (activeNode.nodeId) === 'object' ? activeNode.nodeId.low : activeNode.nodeId;
    this.fetchListCataglogData(nodeId, sourceListId, activeNode.name, true, isUserList);    
    this.setState({ activeNode: activeNode, reLoad: false });
  }

  fetchListCataglogData(listId, actualListId, nodeName, loading = true, isUserList) {
    //GridStore.setScrollPos(0);//if uncommented it will create problem for minilist
    let dataSource = this.props.dataSource; 
    if (!this.props.dataSource) {
      dataSource = ListExplorerStore.getDataSource();
    } 
    const existInUserAndFav =  isExistInUserListCategory(dataSource,actualListId);
    const existInFav= getListInFavorite(getListByCategory(dataSource, ListCategoryType.FAVORITE_ListCategory).childNodes, actualListId);
    OwnershipViewStore.isListLoaded = false;  //disabled context menu delete untill the grid loads data
    GridStore.isPasteEvent = false;
    OwnershipViewStore.isExistInUserCategory = false;
    if(existInUserAndFav){
      OwnershipViewStore.isExistInUserCategory = true;     
    }
    ListStore.updateSelectedListItem(listId, actualListId, nodeName, OwnershipViewStore.activeNode.categoryType, OwnershipViewStore.activeNode.parentNodeId, OwnershipViewStore.activeNode.parentCategoryType, escape(OwnershipViewStore.activeNode.parentCategoryName), OwnershipViewStore.activeNode.parentListType, OwnershipViewStore.activeNode.sourceCategoryType, OwnershipViewStore.activeNode.sourceParentCategoryName);
    this.props.setLoading(listId, nodeName, loading);  
    if(existInUserAndFav || existInFav){
      this.refs.OwnershipListView && this.refs.OwnershipListView.getListCatalog(actualListId, listId, nodeName, 0, -1, isUserList);
    }
    else{
      this.refs.OwnershipListView && this.refs.OwnershipListView.getListCatalog(listId, actualListId, nodeName, 0, -1, isUserList); //actual ListId appended
    }
    this.setState({ listId: listId, actualListId: actualListId });      
  }

  addNewItem(type, parentNodeId) {
    this.setState({ addNewItem: type, parentNodeId: parentNodeId })
    return this.props.addNewItem;
  }

  onResize(width) {
    const self = this;
    this.resizeHandle = true;
    self.setState({ listExplorerWidth: width, resizeHandle: true });
  }

  onStopResize() {
    let width = this.state.listExplorerWidth;
    ListStore.updateListExplorerPreviousWidth(ListStore.getListExplorerSettings().Width)
    if (width < this.listExplorerSettings.MinWidth) width = 0;
    ListStore.updateListExplorerWidth(width);
    ResizeActions.updateBrowserFilterWidths();
    this.setState({ resizeHandle: false });
  }

  ownershipViewStoreStateChange() {
    const currentAction = OwnershipViewStore.getCurrentAction();
    if (currentAction === OwnerShipFetchDetails.GET_OWNERSHIP_DATA) {
      this.setState({ ownerShipData1: OwnershipViewStore.getSourceData() });
    }
  }

  listStoreStateChange() {
    const currentAction = ListStore.getCurrentAction();
    const state = ListStore.getState();    
    if (currentAction === OwnershipViewConstants.ActionTypes.GET_REFRESHED_VIEW_OWNERSHIP) {
      if (state.SelectedTabKey === ListManagerTabType.Ownership) {
        const selectedListItem = ListStore.getSelectedListItem()
        const isUserList = selectedListItem.SelectedCategoryType === ListCategoryType.USER_ListCategory ? 1 : 0;
          this.fetchListCataglogData(selectedListItem.SelectedListId, selectedListItem.SelectedActualListId, selectedListItem.SelectedListName, true , isUserList);               
      }
    }
    else if (currentAction === OwnerShipFetchDetails.GET_OWNERSHIP_DATA) {
      if (state.SelectedTabKey === ListManagerTabType.Ownership) {
        this.setState({ ownerShipData: OwnershipViewStore.getOwnershipData() });
      }
    }
    else if (currentAction === ListExplorerConstants.ActionTypes.UPDATE_LIST_EXPLORER_WIDTH) {
      if (state.SelectedTabKey === ListManagerTabType.Ownership) {
        this.setState({ listExplorerWidth: ListStore.getListExplorerWidthForMetricLibrary() });
      }
    }
    else if (currentAction == SettingsConstants.ActionTypes.LIST_EXPLORER_UPDATE_TAB){
      SettingsAction.saveUserSettings();
    }
  }

  toggleHelp() {
    this.setState({ showHelp: !this.state.showHelp });
  }

  reloadExplorer() {
    this.setState({ reLoad: true });
  }

  showExplorer() {
    this.setState({
      listExplorerWidth: ListStore.getListExplorerSettings().PreviousWidth,
      resizeHandle: false
    })
    ListStore.updateListExplorerPreviousWidth(ListStore.getListExplorerSettings().PreviousWidth);
    ListStore.updateListExplorerWidth(ListStore.getListExplorerSettings().PreviousWidth);

    window.setTimeout(() => {
      this.loadingListExplorer();
      if (StringUtil.isEmpty(ListStore.currentScrollPosition))
        ListExplorerUtil.getScrollPosition(this.state.listId);
        ResizeActions.updateBrowserFilterWidths();
    }, 0);
  }

  loadingListExplorer() {
    this.setState({ loading: false });
  }

  render() {
    const isDeleteEnabled = isDeleteAllowed(OwnershipViewStore.activeNode && OwnershipViewStore.activeNode.categoryType)
    const listHeightClass = this.state.showHelp ? 'middel-inner w50p-h' : 'middel-inner';
    const activeNode = (this.state.activeNode && this.state.activeNode.nodeId === undefined) ? this.state.activeNode : (this.state.activeNode && this.state.activeNode.nodeId) ? this.state.activeNode.nodeId.low : null
    this.showExplorerWindow = this.state.listExplorerWidth <= this.listExplorerSettings.MinWidth && !this.state.resizeHandle ? false : true;
    return (
      <span>
        {this.showExplorerWindow ?
          <div className="left-list-view" id="listExplorer" style={{ width: this.state.listExplorerWidth + 2 }}>
            <ResizableWindow className="list-explorer-resize" minWidth={23} maxWidth={this.listExplorerSettings.MaxWidth} width={this.state.listExplorerWidth} height={'100%'} direction="Horizontal" onStartResize={this.onResize} onStopResize={this.onStopResize} pointerEvents="inherit">
              <ExplorerTop tabCollection={this.props.tabCollection} nodeId={this.state.listId} loading={this.state.loading} selectedTabKey={this.props.selectedTabKey} onTabSelectionChanged={this.props.onTabSelectionChanged} />
              <div className="tab-view-left-middel">
                <div className={listHeightClass}>
                  <div className="custom-scroll">
                    {this.state.loading ? <span className="explorer-loading"><div className="group-box "><div className="list-load"><div className="loading loading-spinner"></div></div></div></span> : ''}
                    <div id='ownershipListScroll' >
                      {
                        this.props.dataSource ?
                          <Owner
                            dataSource={this.props.dataSource}
                            ownershipTableData={this.ownershipTableData}
                            ownerShipData={this.state.ownerShipData1}
                            fontSizeClass={this.props.fontSizeClass}
                            showValidationWindow={this.props.showValidationWindow}
                            updateScrollBar={this.updateScrollBar}
                          />
                         :
                          <span className="explorer-loading"><div className="group-box "><div className="list-load"><div className="loading loading-spinner"></div></div></div></span>
                      }
                      <ScrollBar scrollId="ownershipListScroll" vScroll={true} />

                    </div>
                  </div>
                </div>
                {this.state.showHelp && this.props.dataSource && <ListProperty
                  toggleHelp={this.toggleHelp}
                  showHelp={this.state.showHelp}
                  listId={activeNode}
                  dataSource={this.props.dataSource}
                  selectedTabKey={this.props.selectedTabKey}
                  showOriginalClick={this.showOriginalClick}
                  onExplorerContextMenuClick={this.onExplorerContextMenuClick}
                />}
              </div>
              <ExplorerBottom
                addNewItem={this.addNewItem}
                toggleHelp={this.toggleHelp}
                renderRenameNode={this.renderRenameNode}
                loading={this.props.loading}
                selectedTabKey={this.props.selectedTabKey}
                showValidationWindow={this.props.showValidationWindow}
                isDeleteEnable={isDeleteEnabled}
                store={store}
                fontSizeClass={this.props.fontSizeClass} />
            </ResizableWindow>

          </div>
          : <div className="left-list-view add-side-bar" id="listExplorer" role="button" tabIndex="-1" onClick={this.showExplorer}>
            <div className="dot-slide-bar"></div>
            <div className="dot-slide-bar1"></div>
          </div>}


        <OwnershipListView
          ref="OwnershipListView"
          ownerShipData={this.state.ownerShipData}
          fetchListCataglogData={this.fetchListCataglogData}
          setLoading={this.props.setLoading}
          listId={this.state.listId}          
          setSelectedItemHeader={this.setSelectedItemHeader}
          loading={this.props.loading}          
          actualListId={this.state.actualListId}
          listName={this.props.listName}
          onGridColumnContextMenuClick={this.onGridColumnContextMenuClick}
          onGridCustomFilterContextMenuClick={this.onGridCustomFilterContextMenuClick}
          onGridRowContextMenuClick={this.onGridRowContextMenuClick}
          updateGlobalFlag={this.props.updateGlobalFlag}
          onGridRowContextMenuItemClick={this.props.onGridRowContextMenuItemClick}
          fontSizeClass={this.props.fontSizeClass}
          reloadExplorer={this.reloadExplorer}
        />
      </span>
    );
  }
}
