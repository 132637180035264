import {each} from 'underscore';
import DateHelper from "DateHelper";
import ClientSystemInfo from 'ClientSystemInfo';
import UserInfoUtil from "UserInfoUtil";

class AdobeUtil {
    DTMTrack(data, trackID) {
        digitalData = window.digitalData = {};
        const userData = ClientSystemInfo.getClientSystemInfo();
        let keys = Object.keys(data);
        // console.clear()
        // console.log(data)
        each(keys, (item) => {
            if(item == "userIdRequired") {
                userData.userID = UserInfoUtil.getUserInfo().userId;
            } else {
                digitalData[item] = data[item];
            }
        });

        userData.dayofWeek = DateHelper.getDateString(new Date());
        userData.timeofDay = DateHelper.getTimeString(new Date());
        digitalData.userData = userData;      
        if (window.hasOwnProperty('_satellite')) {
            _satellite.track(trackID);
        }
    }
}

const adobeUtil = new AdobeUtil();
export default adobeUtil;