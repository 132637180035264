import Serializable from "Serializable";
export default class StochasticsChartSettings extends Serializable {
  constructor() {
    super();
  }

  getDefaultLength() {
    return 14;
  }

  getDefaultMovingAverage() {
    return 3;
  }

  getDefaultOverbought() {
    return 80;
  }

  getDefaultOversold() {
    return 20;
  }

  getDefaultSlowValue() {
    return false;
  }

  getDefaultFastValue() {
    return true;
  }

  getDefaultStochasticsLineObj() {
    const RSILine = [{ isActive: true, color: "PCCCCCC262626", weight: 2 }];
    return RSILine.map((item) => this.getSerizableObject(item));
  }

  getDefaultMovingAverageObj() {
    const OverboughtObj = [{ isActive: false, color: "PFF0000670000", weight: 1 }];
    return OverboughtObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultOverboughtObj() {
    const OversoldObj = [{ isActive: false, color: "P670000FF0000", weight: 1 }];
    return OversoldObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultOversoldObj() {
    const OversoldObj = [{ isActive: false, color: "P00660034FF33", weight: 1 }];
    return OversoldObj.map((item) => this.getSerizableObject(item));
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

  getDefaultseqNumber(){
    return 4;
  } 
  onSeralisedInfoRecieved(serializedValue) {
    this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
    this.Height = serializedValue.Height ? serializedValue.Height : 100;
    this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
    this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
    this.Length = serializedValue.Length ? serializedValue.Length : this.getDefaultLength();
    this.MovingAverage = serializedValue.MovingAverage ? serializedValue.MovingAverage : this.getDefaultMovingAverage();
    this.Overbought = serializedValue.Overbought ? serializedValue.Overbought : this.getDefaultOverbought();
    this.Oversold = serializedValue.Oversold ? serializedValue.Oversold : this.getDefaultOversold();
    this.StochasticsColor = serializedValue.StochasticsColor ? serializedValue.StochasticsColor : this.getDefaultStochasticsLineObj();
    this.MovingAverageColor = serializedValue.MovingAverageColor ? serializedValue.MovingAverageColor : this.getDefaultMovingAverageObj();
    this.OverboughtColor = serializedValue.OverboughtColor ? serializedValue.OverboughtColor : this.getDefaultOverboughtObj();
    this.OversoldColor = serializedValue.OversoldColor ? serializedValue.OversoldColor : this.getDefaultOversoldObj();
    this.Slow = serializedValue.Slow !== undefined ? serializedValue.Slow : this.getDefaultSlowValue();
    this.Fast = serializedValue.Fast !== undefined ? serializedValue.Fast : this.getDefaultFastValue();
    this.isApplyToAllIntervals = serializedValue.isApplyToAllIntervals ? serializedValue.isApplyToAllIntervals : false;
    this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : this.getDefaultseqNumber();
  }

  getDataObject() {
    return {
      IsVisible: this.IsVisible,
      Height: this.Height,
      IsAvailable: this.IsAvailable,
      IsRelative: this.IsRelative,
      Length: this.Length,
      MovingAverage: this.MovingAverage,
      Overbought: this.Overbought,
      Oversold: this.Oversold,
      StochasticsColor: this.StochasticsColor,
      MovingAverageColor: this.MovingAverageColor,
      OverboughtColor: this.OverboughtColor,
      OversoldColor: this.OversoldColor,
      Slow: this.Slow,
      Fast: this.Fast,
      isApplyToAllIntervals: this.isApplyToAllIntervals,
      seqNumber : this.seqNumber
    }
  }

  getDefaultStochasticsChartSettings() {
    this.IsVisible = true;
    this.Height = 100;
    this.IsAvailable = true;
    this.IsRelative = false;
    this.Length = this.getDefaultLength();
    this.MovingAverage = this.getDefaultMovingAverage();
    this.Overbought = this.getDefaultOverbought();
    this.Oversold = this.getDefaultOversold();
    this.StochasticsColor = this.getDefaultStochasticsLineObj();
    this.MovingAverageColor = this.getDefaultMovingAverageObj();
    this.OverboughtColor = this.getDefaultOverboughtObj();
    this.OversoldColor = this.getDefaultOversoldObj();
    this.Slow = this.getDefaultSlowValue();
    this.Fast = this.getDefaultFastValue();
    this.seqNumber = this.getDefaultseqNumber();
    this.isApplyToAllIntervals = false;
    return this;
  }

  getObjectMapKey() {
    return "StochasticsChartSettings";
  }
}
