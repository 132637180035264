import React, { Component } from "react";

export default class UserDetailofContact extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {detail} = this.props;
        return (
            <div className="support-box">
                <div className="support-image" style={{backgroundImage:`url(${detail.photo})`}}></div>
                <div className="support-detail">
                     <div className="user-name-contact">
                         <h6 title={detail.fullName}>{detail.fullName}</h6> 
                         <h5>{detail.phoneNumber}</h5>
                     </div>
                     <p title={detail.title}>{detail.title}</p>
                     <div className="email-link"><a href={`mailto:${detail.email}?subject=PANARAY Support`}>Email {detail.firstName}</a></div>
                </div>
            </div>
        )
    }
}

