export const getNupReducer = (state) => state.listViewReducer.NupViewReducer
export const getState = (state) => getNupReducer(state);
export const getChartNode = (state) => getNupReducer(state).nodeCount;
export const getNodeWidth = (state) => getNupReducer(state).nodeWidth;
export const getUserSettings = (state) => getNupReducer(state).settings;
export const getNupCharts = (state) => getNupReducer(state).nupCharts;
export const getTotalCount = (state) => getNupReducer(state).totalCount;
export const getScaleByPeriodicity = (state) => getNupReducer(state).scaleByPeriodicity;
export const getSelected = (state) => getNupReducer(state).selected;
export const getSymbolList = (state) => getNupReducer(state).symbolList;
export const getListData = (state) => getNupReducer(state).listData;
export const getSelectdIndex = (state) => getNupReducer(state).selectedIndex;
export const getNupQuoteSymbol = (state) => getNupReducer(state).qouteSymbol;
