import React from "react";
import { connect } from 'react-redux';
import { Modal, FormControl } from "react-bootstrap";
import {closeExceptionReport } from '../../Actions/ExternalDataUploaderActions';
import ScrollBar from "ScrollBar";
import { map } from "underscore";

class ExceptionReport extends React.Component {
  constructor(props) {
    super(props);
  }

  
  getReport = (exceptionMessage,ekey) => {
    let exceptionRows = [];
    exceptionRows = exceptionMessage.externalExceptionData;
    exceptionRows.sort((a,b) => (a.num > b.num) ? 1 : ((b.num > a.num) ? -1 : 0));

    return( <div className="exception-box" key={ekey}>
                <FormControl.Static>
                    This following rows of data were not imported due to {exceptionMessage.category} error.
                </FormControl.Static>
                {/* <div className="exception-box"> */}
                    {/* <div className="exception-scrolling" id="external-exception"> */}
                        <div className="error-details">
                            <ul>
                            {exceptionRows.map((err,index) => {
                                    return(
                                            <li key={index} > 
                                                <span className="rowname">Row:</span> 
                                                <span className="rownumber">{err.num } : </span>
                                                <div className="rowdescribe" dangerouslySetInnerHTML={{ __html: err.data }}/>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    {/* </div> */}
                {/* </div> */}
            </div>
        )
    }
  render() {
    let {exceptionDialogShow,exceptionMessage} = this.props;//isFileFormatError
    if(exceptionMessage == '' || exceptionMessage == null)
        return (<div></div>);
    else{
        // if(!isFileFormatError)
            exceptionMessage = JSON.parse(exceptionMessage);
    }
    return (
      <div id="exectionWindow">
        <Modal className="modal-popup ext-dialog exception-upload" show={exceptionDialogShow}>
             <Modal.Header>
                <Modal.Title>
                    <span className="cap-header">Exception Report</span>
                    <button type="button"  onClick={() => this.props.closeWizard()} className="btn btn-xxs btn-secondary btn-close" data-effect="fadeOut"><span className="icn-close"></span></button>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="modal-region">
                    <div className="exception-msg">
                        <div className="custom-scroll-light">
                            <div className="exception-scrolling" id="external-exception">
                                {exceptionMessage &&
                                    map(exceptionMessage, (err, key) => 
                                        this.getReport(err,key)
                                    ) 
                                }
                                {/* {exceptionMessage && isFileFormatError &&
                                    this.getReport(exceptionMessage)
                                } */}
                                <ScrollBar scrollId="external-exception" vScroll={true} hScroll={true} scrollOnHover={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="btn-box center">
                    <button type="button" className="btn btn-xs btn-secondary" rel="Close Window" onClick={() => this.props.closeWizard()}>Close Window</button>
                </div>
            </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
    closeWizard: () => dispatch(closeExceptionReport()),
})
const mapStateToProps = ({ externalDataUploader }) => {
    const { exceptionDialogShow,exceptionMessage,isFileFormatError } = externalDataUploader.ExternalDataUploaderReducer;
    return ({ exceptionDialogShow,exceptionMessage,isFileFormatError });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExceptionReport);