import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';


class GetCheckListDataRequest extends BaseServiceRequest {
    constructor(msid, columnSetId, sourceColumnSetId, hideMetericWithoutThresholds) {
        super();
        super.addRequestUri("checklistdata.pb");
        super.addRequestParameter("msid", msid.toString());
        super.addRequestParameter("cid", columnSetId.toString());
        super.addRequestParameter("scid", sourceColumnSetId.toString());
        super.addRequestParameter("hmwt", hideMetericWithoutThresholds ? "1" : "0");
        super.addRequestParameter("rts", "0");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "CheckListResponse";
    }
}

export default GetCheckListDataRequest;