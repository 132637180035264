import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap';
import { map } from "underscore";

class PeriodicityDropdown extends PureComponent {
    onSelect = (eventKey, event) => {
        this.props.onClick(eventKey, event);
    }

    render() {
        const { title, periodicity, periodicityDisplayLabel } = this.props;

        return (
            <ButtonToolbar>
                <DropdownButton title={title} bsSize="xsmall" block dropup={false} id="dropdown-data-plan"
                onSelect={(eventKey, event) => this.onSelect(eventKey, event)}>
                {map(periodicityDisplayLabel, (item, key) => 
                    <MenuItem eventKey={key} key={key} ><div className={(item == periodicity) ? "icn-right-icon" : " " }></div>{item}</MenuItem>
                    )}                
                </DropdownButton>
            </ButtonToolbar>
        );
    }
}

PeriodicityDropdown.propTypes = {
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    periodicity: PropTypes.string.isRequired,
    periodicityDisplayLabel: PropTypes.object.isRequired
}

export default PeriodicityDropdown;