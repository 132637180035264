import React from "react";
import onClickOutside from "react-onclickoutside";
import LocalizationStore from "LocalizationStore";
import NavType from "NavType";
import ConsoleStore from "../../Stores/ConsoleWindow/ConsoleStore";
import CompareTabType from "../../Constants/CompareTabType";

class ShareControlMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClickOutside() {
        try {
            this.props.hideContextMenu();
        }
        catch (ex) {
            console.log(ex);
        }
    }

    render() {
        return (
            <div className="context-menu dropdown icons-dropdown" id='contextMenu' style={this.props.style}>
                <ul className="context-menu-inner list-contex" aria-labelledby="contextMenu">
                    <li key="1">
                        <a href="javascript:void(0);" onClick={() => this.props.startPrint()}>{LocalizationStore.getTranslatedData("lp_print", "Print")}</a>
                    </li>
                </ul>
            </div>
        );
    }

}

const shareControlMenu = onClickOutside(ShareControlMenu);
export default shareControlMenu;