import ListApi from "ListApi";
import UserInfoUtil from "UserInfoUtil";
import BaseServiceApi from "BaseServiceApi";
import StringUtil from "StringUtil";
class PanarayDefault {
    constructor() {
        this.loginCalled = false;
    }
    getDefaultSymbolData(login = false) {
        if(login)
            this.loginCalled = true;
        return new Promise((resolve) => {
            let entId = -1;
            let EntitlementType = BaseServiceApi.rayData["EntitlementType"];
            if (UserInfoUtil.IsUserEntitled(EntitlementType.RAY_CHINA_Entitlement)) {
                entId = Number(EntitlementType.RAY_CHINA_Entitlement);
            }
            ListApi.getDefaultSymbolData(entId).then((result) => {
                this.setDefaultData(result.defaultsymbolData);
                resolve(true);
            });
        });

    }
    setDefaultData(defaultsymboldata) {

        this.Symbol = defaultsymboldata.defaultSymbol;
        this.Msid = StringUtil.convertFromLongValueToInt(defaultsymboldata.msid);
        this.Osid = StringUtil.convertFromLongValueToInt(defaultsymboldata.osid);
        this.SymbolTypeEnum = defaultsymboldata.symbolTypeEnum;
        this.DefaultOwnershipListId = StringUtil.convertFromLongValueToInt(defaultsymboldata.ownershipDefaultListId);

        this.IntlMorgueListId = defaultsymboldata.intMorgueId;
        this.DomesticMorgueListId = defaultsymboldata.domMorgueId;
        this.FutureListId = defaultsymboldata.futuresListId;
        this.IndexSymbol = defaultsymboldata.indexDefaultSymbol;
        this.IndexMsid = StringUtil.convertFromLongValueToInt(defaultsymboldata.indexMsid);
        this.IndexOsid = StringUtil.convertFromLongValueToInt(defaultsymboldata.indexOsid);
        this.IndexSymbolTypeEnum = defaultsymboldata.indexSymbolTypeEnum;
        this.SymphonyWebsiteUrl = defaultsymboldata.symphonyWebsiteUrl;
        this.SymphonyAuthenticateUrl = defaultsymboldata.symphonyAuthenticateUrl;
        this.SymphonyUserInfoUrl = defaultsymboldata.symphonyUserInfoUrl;
        this.SymphonyUserPresenceRegisterUrl = defaultsymboldata.symphonyUserPresenceRegisterUrl;
        this.SymphonyUserPresenceUrl = defaultsymboldata.symphonyUserPresenceUrl;
        this.defaultListID =  defaultsymboldata?.defaultListId?.low;
        this.defaultListName = defaultsymboldata?.defaultListName;
        this.defaultCategoryType = defaultsymboldata?.defaultCategoryType?.low;
        this.parentNodeId = defaultsymboldata?.parentNodeId?.low;
        this.parentCategoryType = defaultsymboldata?.parentCategoryType?.low;
        this.parentCategoryName = defaultsymboldata?.parentCategoryName;
        this.parentListType = defaultsymboldata?.parentListType?.low;
        this.listEntData = {};
        const output = {};
        defaultsymboldata?.entSnpListData.forEach(item => {
            const snpListData = item.snpListData.low;
            const entId = item.entId.low;
            if (!output[snpListData]) { output[snpListData] = []; }
            output[snpListData].push(entId);
        });
        this.listEntData = { ...output }
    }

    set symTypeEnum(value){
        this.symbolTypeEnum = value;
    }
}

const panarayDefault = new PanarayDefault();
export default panarayDefault;