import AddAnchor from '../Presentational/CustomTransformerControl.jsx';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { Line, Group, Image } from 'react-konva';
import React, { Component } from 'react'

class MeasurementControl extends Component {

    handleAnchorDragEnd = () => {
        if (this.props.activeAnchor !== '') {
            this.props.handleAnchorDragEnd();
        }
    }

    render() {
        const { id, color, x, y, startMouseX, startMouseY, currentMouseX, currentMouseY, endMouseX, endMouseY, isLeaderlinesChecked, snapStartPosition, snapEndPosition } = this.props.line;
        const measurementLineColor = ThemeHelper.getThemedBrush(color);
        const { isDragging, index, draggable, lockImage, isApiReqResolved } = this.props;
        const selectedIds = this.props.selectedAnnotId;
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            <Group>
                <Line
                    id={id}
                    shape={RayAnnotationType.MEASUREMENT_AnnotationType}
                    index={index}
                    x={x}
                    y={y}
                    points={[startMouseX, startMouseY, currentMouseX, currentMouseY]}
                    stroke={measurementLineColor}
                    strokeWidth={2}
                    draggable={draggable}
                    onDragStart={() => this.props.handleDragStart(this.props.line)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.MEASUREMENT_AnnotationType) : undefined}
                />

                {/* Left leader line */}
                {isLeaderlinesChecked && snapStartPosition && <Line
                    id={id}
                    type="start"
                    shape={RayAnnotationType.MEASUREMENT_AnnotationType}
                    index={index}
                    x={x}
                    y={y}
                    points={[startMouseX, startMouseY, snapStartPosition.x - x, snapStartPosition.y - y]}
                    stroke={measurementLineColor}
                    strokeWidth={2}
                    dash={[5, 2]}
                    opacity={0.7}
                    draggable
                    onDragStart={() => this.props.handleDragStart(this.props.line)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.MEASUREMENT_AnnotationType) : undefined}
                />}

                {/* Right Leader line */}
                {isLeaderlinesChecked && snapEndPosition && <Line
                    id={id}
                    type="end"
                    shape={RayAnnotationType.MEASUREMENT_AnnotationType}
                    index={index}
                    x={endMouseX}
                    y={endMouseY}
                    points={[startMouseX, startMouseY, snapEndPosition.x - endMouseX, snapEndPosition.y - endMouseY]}
                    stroke={measurementLineColor}
                    strokeWidth={2}
                    dash={[5, 2]}
                    opacity={0.7}
                    draggable
                    onDragStart={() => this.props.handleDragStart(this.props.line)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.MEASUREMENT_AnnotationType) : undefined}
                />}

                {/* Shadow line */}
                <Line
                    id={id}
                    shape={RayAnnotationType.MEASUREMENT_AnnotationType}
                    index={index}
                    x={x}
                    y={y}
                    points={[startMouseX, startMouseY, currentMouseX, currentMouseY]}
                    stroke={measurementLineColor}
                    strokeWidth={6}
                    opacity={0}
                    draggable
                    onDragStart={() => this.props.handleDragStart(this.props.line)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.MEASUREMENT_AnnotationType) : undefined}
                />

                {/* Shadow Left leader line */}
                {isLeaderlinesChecked && snapStartPosition && <Line
                    id={id}
                    type="start"
                    shape={RayAnnotationType.MEASUREMENT_AnnotationType}
                    index={index}
                    x={x}
                    y={y}
                    points={[startMouseX, startMouseY, snapStartPosition.x - x, snapStartPosition.y - y]}
                    stroke={measurementLineColor}
                    strokeWidth={6}
                    opacity={0}
                    draggable
                    onDragStart={() => this.props.handleDragStart(this.props.line)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.MEASUREMENT_AnnotationType) : undefined}
                />}

                {/* Shadow Right Leader line */}
                {isLeaderlinesChecked && snapEndPosition && <Line
                    id={id}
                    type="end"
                    shape={RayAnnotationType.MEASUREMENT_AnnotationType}
                    index={index}
                    x={endMouseX}
                    y={endMouseY}
                    points={[startMouseX, startMouseY, snapEndPosition.x - endMouseX, snapEndPosition.y - endMouseY]}
                    stroke={measurementLineColor}
                    strokeWidth={6}
                    opacity={0}
                    draggable
                    onDragStart={() => this.props.handleDragStart(this.props.line)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.MEASUREMENT_AnnotationType) : undefined}
                />}

                {selectedIds.includes(id) && !isDragging &&
                    <>
                        <AddAnchor x={x} y={y} annotSelected={id} type={RayAnnotationType.MEASUREMENT_AnnotationType}
                            handleAnchorDragStart={this.props.handleAnchorDragStart}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'start'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={x - 3} y={y - 3} />}
                        <AddAnchor x={endMouseX} y={endMouseY} annotSelected={id} type={RayAnnotationType.MEASUREMENT_AnnotationType}
                            handleAnchorDragStart={this.props.handleAnchorDragStart}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'end'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - 3} y={endMouseY - 3} />}
                    </>
                }
            </Group>
        );
    }
}

export default MeasurementControl;