import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import ListActions, { LoadConstituents } from "ListActions";
import ListStore from "ListStore";
import ListManagerTabType from "ListManagerTabType";
import ListExplorerStore from "../../../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import { getParentId } from "../../../../Utils/OwnerShipUtil.js";
import { ListExplorerConstants } from "ListExplorerConstants";
import SettingsAction from "SettingsAction";
import TabONeil from "../TabONeil/TabONeil.jsx";
import TabOwnership from "../TabOwnership/TabOwnership.jsx";
import ContextMenu from "ContextMenu";
import StringUtil from "StringUtil";
import ContextMenuConstants, { TempListMenuItem } from "ContextMenuConstants";
import AlertSourceType from "Constants/AlertSourceType";
import DeleteListItem from "./DeleteListItem";
import BaseServiceApi from 'BaseServiceApi';
import AboutCustomFilter from '../../../../RayCustomControls/ListViewCustomFilter/AboutCustomFilter';
import GridActions from "GridActions";
import GridStore from "GridStore";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import AlertActions from "AlertActions";
import BrowserUtil from "BrowserUtil";
import KeyCodes from "KeyCodes";
import ValidationWindow from "./ValidationWindow.jsx";
import ListExplorerUtil from "../../../../Utils/ListExplorerUtil";
import AlertsDialog from "../../../../RayCustomControls/Alerts/AlertsDialog.jsx";
import AndAlertsDialog from "../../../../RayCustomControls/Alerts/AndAlertsDialog.jsx";
import ConsoleStore from "../../../../Stores/ConsoleWindow/ConsoleStore";
import LocalizationStore from '../../../../Stores/Localization/LocalizationStore';
import CommonDialog from "../../../../RayCustomControls/CommonDialog/CommonDialog"
import AlertConditionType from "Constants/AlertConditionType";
import { updateStorewithSharedData } from "../../../../Actions/ShareAction";
import { setActiveNodeToOwnershipStore } from "../../../../Actions/OwnerShipActions"
import { setActiveNode,updateOwnership } from "Actions/ListViewActions";
import ConsoleActions, { RouteToDataGraph } from "Actions/ConsoleActions";
import { ExportExcelConstants } from "../../../../Constants/ExportExcelConstants";
import MetricDefinition from "../../../../RayCustomControls/CommonDialog/MetricDefinition.jsx";
import ExportExcelAction from "../../../../Actions/ExportExcelAction";
import { extend, reject } from "underscore";
import { addMetric } from '../../../../Actions/customMetricsAction';
import { dispatch } from "../../../../Redux/dispatch";
import TabONeilSettings from '../../../../Stores/ConsoleWindow/Settings/Modules/ListManager/TabONeilSettings.js';
import TabExternalData from "../TabExternalData/TabExternalData.jsx"
import { isUpdated } from "../../../../Actions/ExternalDataUploaderActions";
import { updateSelectAll } from "../../../../Actions/NupViewActions";
import { GridConstants } from "../../../../Constants/GridConstants";
import settingStore from "../../../../Stores/ConsoleWindow/Settings/SettingsStore";


const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const ListType = BaseServiceApi.rayData["ListType"];
const ListAlertStatusType = BaseServiceApi.rayData["ListAlertStatusType"];
const symbolProcessLimitMessage = "Only 10,000 symbols may be selected for this operation";

export default class ListTabControl extends React.Component {

    constructor(props) {
        super(props);
        this.onTabSelectionChanged = this.onTabSelectionChanged.bind(this);
        this.listExplorerStateChange = this.listExplorerStateChange.bind(this);
        this.renderRenameNode = this.renderRenameNode.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.onExplorerContextMenuItemClick = this.onExplorerContextMenuItemClick.bind(this);
        this.onGridColumnsContextMenuItemClick = this.onGridColumnsContextMenuItemClick.bind(this);
        this.onGridCustomFilterContextMenuItemClick = this.onGridCustomFilterContextMenuItemClick.bind(this);
        this.onGridRowContextMenuItemClick = this.onGridRowContextMenuItemClick.bind(this);
        this.deleteListItem = this.deleteListItem.bind(this);
        this.duplicateItem = this.duplicateItem.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showOriginal = this.showOriginal.bind(this);
        this.updateGlobalFlag = this.updateGlobalFlag.bind(this);
        this.renderContextMenu = this.renderContextMenu.bind(this);
        this.handleOnKeyup = this.handleOnKeyup.bind(this);
        this.showValidationWindow = this.showValidationWindow.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.closeAndAlert = this.closeAndAlert.bind(this);
        this.closeMetricAlert = this.closeMetricAlert.bind(this);
        this.openAlert = this.openAlert.bind(this);
        this.deleteUnsharedList = this.deleteUnsharedList.bind(this);

        this.state = {
            ListExplorer: {},
            showContextMenu: false,
            renameNode: false,
            duplicateNode: false,
            showModal: false,
            activeModal: null,
            showOriginalClick: false,
            showValidationModal: false,
            activeValidationModal: null,
            addMetricModal: null,
            alertModal: null,
            andAlertModal : null,
            showAlertModal: false,
            commonDialog: null,
            activeSybol: ListExplorerStore.getActiveSymbol(),
            showMetricModal: false,
            showViewMetricDefModal: false,
            activeNode: this.props.listId,
            preClass: 'msg-modal'
        };
        this.renameNodeParentId = null;
        this.nodeId = null;
        this.contextMenuCurrentAction = null;
        this.prevNodeName = null;
        this.contextMenuObj = null;
        this.previousSelectedNodeId = null;
        this.loadCompleteDb = false;
        this.previousTag = null;
        this.keyCodePressed = null;
        this.textLimit = 150;
        this.ieFocusTag = 1; 
    }

    UNSAFE_componentWillMount() {
        ListExplorerStore.addChangeListener(this.listExplorerStateChange);
        if(!ListExplorerStore.isExplorerCalled()){
            ListActions.getListExplorer();
        }
        GridStore.addChangeListener(this.deleteUnsharedList);
    }

    componentWillUnmount() {
        ListExplorerStore.removeChangeListener(this.listExplorerStateChange);
        GridStore.removeChangeListener(this.deleteUnsharedList);
    }

    deleteUnsharedList() {
        const curr = GridStore.getCurrentAction();
        if(curr === GridConstants.ActionTypes.REMOVE_UNSHARED_LIST) {
            ListExplorerStore.contextNode = ListExplorerStore.activeNode;
            ListExplorerStore.contextNode.isUnShared = true;
            const { nodeId, name, rootParentNodeId, parentNodeId, listType, categoryType } = ListExplorerStore.activeNode;
            this.deleteListItem(nodeId, name, rootParentNodeId, parentNodeId, listType, categoryType);
        }
    }
    UNSAFE_componentWillReceiveProps() {
        this.setState({ isDelete: false });
    }

    closeModal() {
        BrowserUtil.enableEvents();
        this.setState({ activeModal: null, showModal: false, activeValidationModal: null, showValidationModal: false, showMetricModal: false, showViewMetricDefModal: false });
    }

    openModal() {
        this.setState({ showModal: true });
    }

    getCurrentNode(nodeId) {
        const ParentListTreeItem = [];
        ListExplorerStore.findParentNode(nodeId, ParentListTreeItem);
        const currentNode = reject(ParentListTreeItem, (item) => item.childNodes.length > 0);
        return currentNode;
    }

    isScheduledList(nodeId = ListStore.getSelectedListItem().SelectedActualListId) {
        const currentNode = this.getCurrentNode(nodeId);
        if (currentNode.length > 0 && currentNode[0].categoryType === ListCategoryType.SCHEDULESCREENLIST_ListCategory) {
            return true;
        }
        return false;
    }

    listExplorerStateChange() {
        const currentAction = ListExplorerStore.getCurrentAction();
        const state = ListExplorerStore.getState();
        if (currentAction === ListExplorerConstants.ActionTypes.GET_CREATE_TREE_NODE) {
            this.setState({ addNewItem: undefined });
            this.setState({ ListExplorer: ListExplorerStore.getListExplorer() });
        }
        else if (currentAction === ListExplorerConstants.ActionTypes.GET_UPDATE_LIST_NODE) {
            ListExplorerStore.isRename = false;
            this.setState({ renameNode: false });
            this.setState({ ListExplorer: ListExplorerStore.getListExplorer() });
            BrowserUtil.enableEvents();
        }
        else if (currentAction === ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST || currentAction === ListExplorerConstants.ActionTypes.COPY_PASTE || currentAction === ListExplorerConstants.ActionTypes.SAVE_SCREENED_RESULTS
        ) {
            const newNode = ListExplorerStore.getDuplicateNode();
            this.setState({ renameNode: true });
            ListExplorerStore.isRename = true;
            this.renderRenameNode(ListCategoryType.USER_ListCategory, newNode.nodeId.low, newNode.listType, currentAction);
            this.setState({ ListExplorer: ListExplorerStore.getListExplorer() });
        }
        else if (currentAction === ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER) {
            const listDataSource = ListExplorerStore.getDataSource(); 
            if(listDataSource){
                const listAvailable = ListExplorerStore.findActiveListAvailibility(this.props.listId);
                if(!listAvailable){
                    const tabONeilSettings = new TabONeilSettings();
                    const readyData = tabONeilSettings.getDefaultSelectedListInfo();
                    this.TabONeil && this.TabONeil.fetchListCataglogData(readyData.SelectedListId, readyData.SelectedActualListId, readyData.SelectedListName);
                }
            }
            //Select default list
            this.setState({ ListExplorer: ListExplorerStore.getListExplorer() });
            ListExplorerStore.activeNode != undefined && !ListExplorerStore.activeNode.symbol && this.expandAndFindParentNode(Number(this.props.listId));
            const activeNodeId = ListExplorerStore.activeNode && ListExplorerStore.activeNode.nodeId ? typeof (ListExplorerStore.activeNode.nodeId) === 'object' ? ListExplorerStore.activeNode.nodeId.low : ListExplorerStore.activeNode.nodeId : null;
            const actualListId = ListExplorerStore.activeNode && ListExplorerStore.activeNode.sourceListId ? typeof (ListExplorerStore.activeNode.sourceListId) === 'object' ? ListExplorerStore.activeNode.sourceListId.low : ListExplorerStore.activeNode.sourceListId : activeNodeId;
            ListExplorerStore.activeNode != undefined && ListStore.updateSelectedListItem(activeNodeId, actualListId, ListExplorerStore.activeNode.name, ListExplorerStore.activeNode.categoryType, ListExplorerStore.activeNode.parentNodeId, ListExplorerStore.activeNode.parentCategoryType, escape(ListExplorerStore.activeNode.parentCategoryName), ListExplorerStore.activeNode.parentListType, ListExplorerStore.activeNode.sourceCategoryType, ListExplorerStore.activeNode.sourceParentCategoryName);
        }
        else if (currentAction === ListExplorerConstants.ActionTypes.REMOVE_ITEMS_FROM_USER_LIST || currentAction === ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST) {
            const activeNodeId = ListExplorerStore.activeNode && ListExplorerStore.activeNode.nodeId ? typeof (ListExplorerStore.activeNode.nodeId) === 'object' ? ListExplorerStore.activeNode.nodeId.low : ListExplorerStore.activeNode.nodeId : null;
            if (!StringUtil.isEmpty(activeNodeId) && activeNodeId === state.removeItemsFromUserList.targetListId) {
                if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                    this.TabONeil.fetchListCataglogData(this.props.listId.toString(), this.props.listId.toString(), this.props.listName);
                }
                if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                    this.TabOwnership.fetchListCataglogData(this.props.listId.toString(), this.props.listId.toString(), this.props.listName);
                }
            }
        }
        else if (currentAction === ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE || currentAction === ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES) {
            this.updateListOnDelete();
            ListExplorerStore.selectedNodeIds = [];
        }
    }

    onTabSelectionChanged(e) {
        let tabKey = (ReactDOM.findDOMNode(e.target).getAttribute('data-tabkey'));
        if(tabKey === null){
            return;
        }
    
        SettingsAction.updateListExplorerTabSelection(tabKey);
        ListExplorerStore.setActiveNodeFromSelectedList(tabKey);
        
        this.props.updateMetricLibraryData()
        this.loadCompleteDb = false;
        const dataNodeId = e.target.getAttribute('data-node-id');
        if (this.props.selectedTabKey.toLowerCase() === tabKey.toLowerCase()) {
            this.previousSelectedNodeId = this.props.listId;
            this.loadCompleteDb = true;
        }
        if ( this.props.selectedTabKey.toLowerCase() == tabKey.toLowerCase() && this.props.selectedTabKey == ListManagerTabType.Oneil) {
            ListExplorerStore.contextNode = null // fix PANWEB-4200
            ListExplorerStore.activeNode = ListExplorerStore.getOneilOwnershipActiveNode(dataNodeId);        
        }
        else if( this.props.selectedTabKey.toLowerCase() == tabKey.toLowerCase() && this.props.selectedTabKey == ListManagerTabType.Ownership){  
            const newActiveNode = getParentId(parseInt(dataNodeId), this.state.ListExplorer.Ownership);         
            setActiveNodeToOwnershipStore(newActiveNode);
            dispatch(setActiveNode(parseInt(dataNodeId)));
            dispatch(updateOwnership(true)); 
        }    
        else{
            dispatch(isUpdated(true));
        }
         this.getActiveTabContent();
        //Log Usage
        ConsoleStore.trackUsage(ListExplorerStore.getListDatabaseName(), '', []);
    }

    async  onGridColumnsContextMenuItemClick(action, listId, dataItemId, displayName, colSeq, quoteBoard, isFrozen, previousColumnDataItemId) {
        let dbType = 1;
        let msIDS, selectedIndexesLength, selectedIndexes;
        switch (action) {
            case ContextMenuConstants.AUTO_SIZE_COLUMN_WIDTH:
                BrowserUtil.disableEvents();
                GridActions.addColumnSetWidth(listId, colSeq, quoteBoard);
                if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                    this.TabONeil.refs.ONeilListView.listCatalog.colsetId = '-1';
                }
                if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                    this.TabOwnership.refs.OwnershipListView.listCatalog.colsetId = '-1';
                }
                break;

            case ContextMenuConstants.SET_NEW_CUSTOM_ORDER:
                if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                    this.TabONeil.refs.ONeilListView.handleCustomOrder();
                }
                if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                    this.TabOwnership.refs.OwnershipListView.handleCustomOrder();
                }
                break;

            case ContextMenuConstants.REMOVE_COLUMN:
                if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                    this.TabONeil.refs.ONeilListView.removeColumnToList(listId, dataItemId, quoteBoard, isFrozen, previousColumnDataItemId);
                }
                if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                    this.TabOwnership.refs.OwnershipListView.removeColumnToList(listId, dataItemId, quoteBoard, isFrozen, previousColumnDataItemId);
                }
                this.props.clearAlertMessageInterval();
                break;

            case ContextMenuConstants.CREATE_CUSTOM_METRIC:
            case ContextMenuConstants.EDIT_CUSTOM_METRIC:
                {
                const metricState = ListStore.getCustomMetricState();
                 if(!metricState){
                this.openAddcustomMetrics(dataItemId);
                 }
                break;
                }
            case ContextMenuConstants.VIEW_METRIC_DEFINITION:
                {                
                let colData = undefined; 
                let activeModal ='';
                 const colsHeader =  ListStore.getMetricLibrary();
                 colsHeader.itemData.map((col) => {
                   if(col.MsItemID === dataItemId){
                    colData = col;
                   }   
                   return col;
                });

                if(colData == undefined){
                    colData = {Definition:"\"Definition not available\"",MetricDisplayName:displayName};
                }
                activeModal =  <MetricDefinition
                        definition={colData.Definition}
                        industryName={colData.MetricDisplayName}
                        caller={'Ownership'}
                        x={200}
                        y={200}
                        showModal={!this.state.showModal} closeModal={this.closeModal}
                    />
                    this.setState({ activeModal: activeModal, showViewMetricDefModal: false });
                    break;
            }
            case ContextMenuConstants.FLAG_ALL_SYMBOLS:
                if (ONeilViewStore.getSourceData().length > 10000 || OwnershipViewStore.getSourceData().length > 10000) {
                    ConsoleActions.exceptionHandle(null, false, symbolProcessLimitMessage, false, "FLAG/UNFLAG", true);
                    return;
                }
                BrowserUtil.disableEvents();
                if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                    GridActions.updateGlobalFlagAll(listId, this.TabONeil.refs.ONeilListView.state.cacheId, false, dbType);
                }
                if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                    dbType = 2;
                    selectedIndexesLength = OwnershipViewStore.getSourceData().length;
                    selectedIndexes = Array.apply(null, { length: selectedIndexesLength }).map(Number.call, Number);
                    msIDS = await OwnershipViewStore.getMsIds(selectedIndexes);
                    this.updateGlobalFlag(msIDS, false, selectedIndexes);
                }
                break;

            case ContextMenuConstants.REMOVE_ALL_FLAGS:
                if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                    if (ONeilViewStore.getSourceData().length > 10000) {
                        ConsoleActions.exceptionHandle(null, false, symbolProcessLimitMessage, false, "FLAG/UNFLAG", true);
                        return;
                    }
                }
                if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                    if (OwnershipViewStore.getSourceData().length > 10000) {
                        ConsoleActions.exceptionHandle(null, false, symbolProcessLimitMessage, false, "FLAG/UNFLAG", true);
                        return;
                    }
                }
                BrowserUtil.disableEvents();
                if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                    dbType = 1;
                    GridActions.updateGlobalFlagAll(listId, this.TabONeil.refs.ONeilListView.state.cacheId, true, dbType);
                }
                if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                    dbType = 2;
                    selectedIndexesLength = OwnershipViewStore.getSourceData().length;
                    selectedIndexes = Array.apply(null, { length: selectedIndexesLength }).map(Number.call, Number);
                    msIDS = await OwnershipViewStore.getMsIds(selectedIndexes);
                    this.updateGlobalFlag(msIDS, true, selectedIndexes);
                }
                break;

            case ContextMenuConstants.COLUMN_VISUALIZATION:
                BrowserUtil.disableEvents();
                if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                    this.TabONeil.refs.ONeilListView.handleColumnVisualization(listId, dataItemId, quoteBoard);
                }
                if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                    this.TabOwnership.refs.OwnershipListView.handleColumnVisualization(listId, dataItemId, quoteBoard);
                }
                break;
            default:
                return;
        }
        this.setState({ showContextMenu: false });
    }

    onGridCustomFilterContextMenuItemClick(action) {
        let activeModal = '';
        switch (action) {
            case ContextMenuConstants.ABOUT:
                activeModal = <AboutCustomFilter showModal={!this.state.showModal} closeModal={this.closeModal} />
                break;
            default:
                return;
        }
        this.setState({ activeModal: activeModal, showContextMenu: false });
    }

    async onGridRowContextMenuItemClick(action, contextMenuObj) {
        const self = this;
        setTimeout(async () => {
            //let dbType = 1;
            let msIDS = null;
            let symbol = null;
            let currencyCode = null;
            switch (action) {
                case ContextMenuConstants.ALERT:
                    if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                        msIDS = await ONeilViewStore.getMsIds(contextMenuObj.selectedIndexes);
                        // instrumentIDs = ONeilViewStore.getInstrumentIdFromMsIds(msIDS);
                        symbol = ONeilViewStore.getSymbolFromMsIds(msIDS);
                    }
                    if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                        msIDS = await OwnershipViewStore.getMsIds(contextMenuObj.selectedIndexes);
                        // instrumentIDs = ONeilViewStore.getInstrumentIdFromMsIds(msIDS);
                        symbol = OwnershipViewStore.getSymbolFromMsIds(msIDS);
                    }
                     this.openAlert(msIDS, symbol, AlertSourceType.Stock,null,null,contextMenuObj.instrumentType);
                    break;
                case ContextMenuConstants.AND_CONDITION_ALERTS:
                    msIDS = await ONeilViewStore.getMsIds(contextMenuObj.selectedIndexes);
                    symbol = ONeilViewStore.getSymbolFromMsIds(msIDS)
                    currencyCode = ONeilViewStore.getCurrencyCodeFromMsIds(msIDS)
                    this.openAndAlert(msIDS, symbol, AlertSourceType.Stock,null,null,contextMenuObj.instrumentType, currencyCode);
                    break;
                case ContextMenuConstants.ROW_FLAG:
                case ContextMenuConstants.ROW_REMOVE_FLAG:
                    {
                        if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                            if (ONeilViewStore.getSourceData().length <= contextMenuObj.selectedIndexes.length && ONeilViewStore.getSourceData().length > 10000) {
                                ConsoleActions.exceptionHandle(null, false, symbolProcessLimitMessage, false, "FLAG/UNFLAG", true);
                                return;
                            }
                        }
                        if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                            if (OwnershipViewStore.getSourceData().length <= contextMenuObj.selectedIndexes.length && OwnershipViewStore.getSourceData().length > 10000) {
                                ConsoleActions.exceptionHandle(null, false, symbolProcessLimitMessage, false, "FLAG/UNFLAG", true);
                                return;
                            }
                        }
                        BrowserUtil.disableEvents();
                        msIDS = this.props.selectedTabKey === ListManagerTabType.Oneil ? await ONeilViewStore.getMsIds(contextMenuObj.selectedIndexes) : await OwnershipViewStore.getMsIds(contextMenuObj.selectedIndexes);
                        const boolValue = (action === ContextMenuConstants.ROW_FLAG) ? false : true;
                        this.updateGlobalFlag(msIDS, boolValue, contextMenuObj.selectedIndexes);
                        break;
                    }
                case ContextMenuConstants.ROW_SELECT_ALL:
                    ONeilViewStore.showChart ? dispatch(updateSelectAll()): GridActions.handleSelectAllRows();
                    break;
                case ContextMenuConstants.ROW_REMOVE:
                    {
                        let snapshotDates;
                        if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                            if (ONeilViewStore.isListEditable() !== false || self.isScheduledList()) {
                                BrowserUtil.disableEvents();
                                msIDS = await ONeilViewStore.getMsIds(contextMenuObj.selectedIndexes);
                                snapshotDates = ONeilViewStore.getSnapshotDateFromMsIds(msIDS.split(';'));
                                GridActions.removeGridRows(contextMenuObj.listId, this.TabONeil.refs.ONeilListView.state.cacheId, msIDS, snapshotDates);
                                this.props.clearAlertMessageInterval();
                            }
                        }
                        if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                            if (OwnershipViewStore.isListEditable() !== false || self.isScheduledList()) {
                                BrowserUtil.disableEvents();
                                msIDS = await OwnershipViewStore.getMsIds(contextMenuObj.selectedIndexes);
                                snapshotDates = '';
                                GridActions.removeGridRows(contextMenuObj.listId, this.TabOwnership.refs.OwnershipListView.state.cacheId, msIDS, snapshotDates);
                                this.props.clearAlertMessageInterval();
                            }
                        }
                        break;
                    }
                case ContextMenuConstants.ROW_COPY:
                    {
                        BrowserUtil.disableEvents();
                        let snapshotDates_copy;
                        if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                            msIDS = await ONeilViewStore.getMsIds(contextMenuObj.selectedIndexes);
                            if(msIDS){
                                snapshotDates_copy = ONeilViewStore.getSnapshotDateFromMsIds(msIDS.split(';'));
                            }
                        }
                        if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                            msIDS = await OwnershipViewStore.getMsIds(contextMenuObj.selectedIndexes);
                            snapshotDates_copy = '';
                        }
                        GridActions.copyGridRows(contextMenuObj.listId, msIDS, snapshotDates_copy);
                        this.props.clearAlertMessageInterval();
                        break;
                    }
                case ContextMenuConstants.ROW_PASTE:                    
                    {
                        let cacheId;
                        
                        if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                            if (ONeilViewStore.isListEditable() !== false || self.isScheduledList()) {
                                BrowserUtil.disableEvents();
                                const content = await GridStore.getClipboardContents(contextMenuObj.e);          
                                const isClipboardContainSpace = !StringUtil.isEmpty(content) && content.trim() === '';               
                                const cacheId = this.TabONeil.refs.ONeilListView.state.cacheId;
                                console.log("response from getClipboardContents --- clipboard content - ", content, " &&&& cacheId - ", cacheId);
                                if (!StringUtil.isEmpty(content) && !isClipboardContainSpace) {
                                    GridActions.pasteGridRows(contextMenuObj.listId, cacheId, content);
                                }
                                else if(isClipboardContainSpace)
                                {  
                                    BrowserUtil.enableEvents();
                                    GridStore.displayAlertMessageforNull()
                                }
                                else {
                                    GridStore.displayAlertMessage();
                                }
                                self.setState({ showContextMenu: false });
                                // this.props.clearAlertMessageInterval();
                            }
                        }
                        if (this.props.selectedTabKey === ListManagerTabType.Ownership) {                            
                            if (OwnershipViewStore.isListEditable() !== false) {
                                BrowserUtil.disableEvents();
                                const content = await GridStore.getClipboardContents(contextMenuObj.e);   
                                const isClipboardContainSpace = !StringUtil.isEmpty(content) && content.trim() === '';                                 
                                cacheId = this.TabOwnership.refs.OwnershipListView.state.cacheId;                                
                                if (!StringUtil.isEmpty(content) && !isClipboardContainSpace) {
                                    GridActions.pasteGridRows(contextMenuObj.listId, cacheId, content);
                                }
                                else if(isClipboardContainSpace)
                                {  
                                    BrowserUtil.enableEvents();
                                    GridStore.displayAlertMessageforNull()
                                }
                                else {
                                    GridStore.displayAlertMessage();
                                }
                                self.setState({ showContextMenu: false });
                                // this.props.clearAlertMessageInterval();
                            }
                        }
                        break;
                    }
                case ContextMenuConstants.DELETE_ALL_ALERTS_BY_MSIDS:
                    this.confirmAlertDelete();
                    break;
                case ContextMenuConstants.SHIFT_DOWN_SELECTION:
                    GridActions.handleShiftDown();
                    break;
                case ContextMenuConstants.SHIFT_UP_SELECTION:
                    GridActions.handleShiftUp();
                    break;
                case ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS:
                    {
                    const activeData = await OwnershipViewStore.getActiveGridRows(contextMenuObj.selectedIndexes);
                    LoadConstituents(activeData);
                    break;
                    }
                case ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG:
                    const settings = settingStore.getConsoleSettings();
                    const navListManagerSettings = settings && settings.NavListManagerSettings;
                    const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
                    if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
                       if(exportListSettings.IsShowExportTypeDialog === false) {
                          ExportExcelAction.exportData( exportListSettings.ExportType);
                        }else{
                            ExportExcelAction.showExportDialog();
                        }
                    }
                    if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
                        if(exportListSettings.IsShowExportTypeDialog === false) {
                            ExportExcelAction.exportData( exportListSettings.ExportType);
                          }else{
                            ExportExcelAction.showExportDialog(this.props.nodeType, '', this.props.actualListId, this.props.listName);
                          }
                    }
                    break;
                case ContextMenuConstants.EDIT_ALERT:
                    symbol = ONeilViewStore.getSourceData()[contextMenuObj.selectedIndexes[0]].symbol;
                    SettingsAction.updateTabSelection("DATAGRAPH");
                    RouteToDataGraph(symbol);
                    AlertActions.editAlert(symbol);
                    break;
                default:
                    return;
            }
            self.setState({ showContextMenu: false });
            document.getElementById('main-grid-panel').focus();
        }, 0);
            }

    onExplorerContextMenuItemClick(categoryType, action, rootParentId, nodeId, nodeName, listType, sourceListId, parentId, alertStatusType, existAlerts, isDupFav, spId, conditionTypeId) {
        let activeModal = '';
        let nodeDesc = '';
        this.contextMenuCurrentAction = action;
        switch (action) {
            case ContextMenuConstants.ALERT:
                this.openAlert(nodeId, null, AlertSourceType.List, alertStatusType, existAlerts);
                break;
            case ContextMenuConstants.AND_CONDITION_ALERTS:
                this.openAndAlert(nodeId, null, AlertSourceType.List, alertStatusType, existAlerts);
                break;
            case ContextMenuConstants.DELETE_LIST_ALERTS:
                this.confirmListAlertDelete(nodeId,conditionTypeId);   
            // AlertActions.deleteAllListAlerts(nodeId);
                break;
            case ContextMenuConstants.SHOW_ORIGINAL:
                BrowserUtil.disableEvents();
                this.showOriginal(sourceListId, true);
                break;

            case ContextMenuConstants.ADD_TO_FAVORITES:
                BrowserUtil.disableEvents();
                this.addToFavorites(nodeId, nodeName, categoryType, ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES);
                break;

            case ContextMenuConstants.REMOVE_FROM_FAVORITES:
                if (!ONeilViewStore.isListLoaded) return;
                BrowserUtil.disableEvents();
                if (listType === ListType.FOLDER_List) {
                    activeModal = <DeleteListItem showModal={!this.state.showModal} closeModal={this.closeModal} deleteListItem={this.deleteListItem} nodeId={nodeId} nodeName={nodeName} rootParentId={rootParentId} parentId={parentId} listType={listType} />
                }
                else {
                    this.removeFromFavorites(nodeId, nodeName, rootParentId, parentId, listType, categoryType);
                }
                break;

            case ContextMenuConstants.DUPLICATE:
            case TempListMenuItem.SAVE_RESULTS:

                nodeName = LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(nodeName, listType), nodeName);
                nodeDesc = nodeName;
                BrowserUtil.disableEvents();
                this.duplicateItem(nodeId, nodeName, rootParentId, categoryType, ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST, nodeDesc, isDupFav, spId);
                break;

            case ContextMenuConstants.RENAME:

                this.setState({ renameNode: true });
                ListExplorerStore.isRename = true;
                this.renderRenameNode(rootParentId, nodeId, listType, ListExplorerConstants.ActionTypes.GET_UPDATE_LIST_NODE);
                break;

            case ContextMenuConstants.DELETE:
            case TempListMenuItem.REMOVE_RESULT:
                if (!ONeilViewStore.isListLoaded) return;
                BrowserUtil.disableEvents();
                if (listType === ListType.FOLDER_List) {
                    activeModal = <DeleteListItem showModal={!this.state.showModal} closeModal={this.closeModal} deleteListItem={this.deleteListItem} nodeId={nodeId} nodeName={nodeName} rootParentId={rootParentId} parentId={parentId} listType={listType} />
                }
                else {
                    this.deleteListItem(nodeId, nodeName, rootParentId, parentId, listType, categoryType);
                }
                break;
            case ContextMenuConstants.SAVE_SCREENED_RESULTS:
                if (ListExplorerStore.getSaveScreenedResults()) {
                    BrowserUtil.disableEvents();
                    ListActions.saveScreenedResults();
                }
                break;
            case ContextMenuConstants.SHARE:
                // BrowserUtil.disableEvents();
                this.shareList(nodeId, nodeName, categoryType, ListExplorerConstants.ActionTypes.SHARE_LIST);
                break;
            case ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG:
                const settings = settingStore.getConsoleSettings();
                const navListManagerSettings = settings && settings.NavListManagerSettings;
                const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
                if(exportListSettings.IsShowExportTypeDialog) {
                    ExportExcelAction.showExportDialog(categoryType, nodeId, sourceListId);
                }
                else{
                      ExportExcelAction.exportData( exportListSettings.ExportType, categoryType, nodeId, sourceListId);
                  }
                break;
            default:
                return;
        }
        this.setState({ activeModal: activeModal, showContextMenu: false });
    }

    /***************** Expand and find parent node *********************/

    expandAndFindParentNode(nodeId) {
        if (nodeId === -1) {
            ListExplorerStore.activeNode = ListExplorerStore.getOneilOwnershipActiveNode(nodeId);
            return;
        }
        const nodeList = [];
        ListExplorerStore.expandAndFindParentNode(nodeId, nodeList);
        ListExplorerStore.activeNode = nodeList[0];
    }

    /***************** Expand and find parent node ***********************/

    /***************** Start Show original *********************/

    showOriginal(nodeId, showOriginalClick) {
        const nodeList = [];
        ListExplorerStore.expandAndFindParentNode(nodeId, nodeList);
        ListExplorerStore.activeNode = nodeList[0];
        if (showOriginalClick) {
            this.previousSelectedNodeId = this.props.listId;
            ListActions.showOriginal();
            if (nodeList.length > 0)
                this.TabONeil.fetchListCataglogData(nodeId.toString(), nodeId.toString(), nodeList[0].name);
        }
    }

    /***************** End Show original ***********************/

    /***************** Start Add to favorites ******************/

    addToFavorites(nodeId, nodeName, sourceCategoryType, action) {
        ListExplorerStore.ExpandItemLevel(ListCategoryType.FAVORITE_ListCategory, null, true);
        this.duplicateList(1, nodeId, nodeName, true, sourceCategoryType, action);

        //Log Usage
        const selectedItemsCount = ListExplorerStore.selectedNodeIds.length > 0 ? ListExplorerStore.selectedNodeIds.length : 1;
        for (let i = 0; i < selectedItemsCount; i++)
            ConsoleStore.trackUsage("AddedListToFavorite", '', [ListExplorerStore.getListDatabaseName()]);
    }

    /***************** End Add to favorites ********************/

    /***************** Start share list ******************/

    shareList(nodeId, nodeName, sourceCategoryType, action) {
        let nodeIds = undefined;
        let nodeNames = undefined;
        if (ListExplorerStore.selectedNodeIds.length > 0) {
            ListExplorerStore.selectedNodeIds.map((item) => {
                nodeIds = nodeIds ? `${nodeIds};${item.nodeId.low}` : `${item.nodeId.low}`;
                nodeNames = nodeNames ? `${nodeNames},${item.name}` : `${item.name}`;
                return item;
            });
        }
        else {
            nodeIds = nodeId;
            nodeNames = nodeName;
        }

        const sharedListData = {
            showSharedDialog: true,
            id: nodeIds,
            name: nodeNames,
            type: sourceCategoryType,
            action: action,
            IncludeAllUsers: 0,
            ntid: "1"
        }
        updateStorewithSharedData(sharedListData);
    }

    /***************** End share list ********************/

    /*************** Start Remove From Favorites ***************/
    removeFromFavorites(nodeId, nodeName, rootParentId, parentId, listType, categoryType) {
        if (nodeId !== rootParentId) {            
            const nodesToSearch = ListExplorerStore.nodesToSearch(ListCategoryType.FAVORITE_ListCategory);
            const result = this.findNodeByName(nodesToSearch[0], nodeName);
            if (!StringUtil.isEmpty(result)) {
                this.deleteNode(result.nodeId.low, result.name, rootParentId, parentId, listType, ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES, categoryType);               
            }
        }
    }

    findNodeByName(nodesToSearch, nodeName) {
        let currentChild, result;
        if (nodeName === nodesToSearch.name) {
            return nodesToSearch;
        } else {
            for (let i = 0; i < nodesToSearch.childNodes.length; i += 1) {
                currentChild = nodesToSearch.childNodes[i];
                result = this.findNodeByName(currentChild, nodeName);
                if (result !== false) {
                    return result;
                }
            }
            return false;
        }
    }
    /************* End Remove From Favorites *****************/

    /************* Start Duplicate Item *******************/
    duplicateItem(nodeId, nodeName, rootParentId, sourceCategoryType, action, nodeDesc, isDupFav, spId) {
        if (nodeId !== rootParentId || sourceCategoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
            let nodeSymbol = '';
            if (sourceCategoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
                nodeSymbol = ListExplorerStore.checkTempListName(nodeName);
            }
            BrowserUtil.disableEvents();
            let newName = null;
            this.nodeId = nodeId;
            ListExplorerStore.ExpandItemLevel(ListCategoryType.USER_ListCategory, null, true);
            newName = ListExplorerStore.validateListName(nodeName, ListCategoryType.USER_ListCategory, null, ListType.LIST_List);
            this.duplicateNode(newName, sourceCategoryType, action, nodeDesc,nodeSymbol, isDupFav, spId);
        }
    }

    duplicateNode(nodeName, sourceCategoryType, action, nodeDesc,nodeSymbol, isDupFav = false, spId) {

        if (StringUtil.isEmpty(nodeName)) {
            return;
        }
        else {
            this.duplicateList(ListCategoryType.USER_ListCategory, this.nodeId, nodeName, false, sourceCategoryType, action, nodeDesc,nodeSymbol, isDupFav, spId);
        }
    }

    duplicateList(rootParentId, nodeId, nodeName, isFav, sourceCategoryType, action, nodeDesc, nodeSymbol, isDupFav = false, spId) {

        const duplicateList = [];
        let listItemsToAdd = [];
        let symCode = '';
        if ((this.contextMenuCurrentAction === ContextMenuConstants.ADD_TO_FAVORITES || this.contextMenuCurrentAction === TempListMenuItem.SAVE_RESULTS) && ListExplorerStore.selectedNodeIds.length > 0) {
            const nodesToSearch = ListExplorerStore.nodesToSearch(ListCategoryType.FAVORITE_ListCategory);
            listItemsToAdd = ListExplorerStore.findListItemsToCopy(ListExplorerStore.selectedNodeIds, nodesToSearch.childNodes);
            if (listItemsToAdd.length > 0) {
                listItemsToAdd.map((item) => {
                    if(item.categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory){
                        duplicateList.push({
                            symCode: item.symbol ? item.symbol : '',
                            parentNodeId: rootParentId,
                            name: item.name,
                            sourceListId: item.nodeId.low,
                            destListId: '',
                            cacheId: '',
                            sortCols: null,
                            isFav: isFav,
                            isFlagList: false,
                            spId: spId,
                            isDupFav: isDupFav
                        });
                    }
                    return item;
                });
            }
        }
        else {
            if (this.contextMenuCurrentAction === TempListMenuItem.SAVE_RESULTS) {
                symCode = nodeSymbol;
            } else {
                symCode = ListExplorerStore.activeSybol ? ListExplorerStore.activeSybol.symbol : '';
            }
            duplicateList.push({
                symCode: symCode,
                parentNodeId: rootParentId,
                name: nodeName,
                sourceListId: nodeId,
                destListId: '',
                cacheId: '',
                sortCols: null,
                isFav: isFav,
                isFlagList: false,
                spId: spId,
                description: nodeDesc,
                isDupFav: isDupFav
            });
        }

        ListActions.getDuplicateList(duplicateList, rootParentId, sourceCategoryType, action);
    }
    /************** End Duplicate Item **************/

    /************** Start Rename Item ***************/
    renderRenameNode(rootParentId, nodeId, listType, currentAction = '') {

        BrowserUtil.disableEvents();
        //let divNodeId;
        let text;
        let activeNode = {};
        this.nodeId = nodeId;
        const fontSizeClass = this.getFontSizeClass();
        let isFolder = false;
        let activeElm = null;

        activeElm = ListExplorerUtil.getElementByQuery(`a[data-node-id='${  nodeId  }']`);
        if (!StringUtil.isEmpty(activeElm))
            activeNode = JSON.parse(activeElm.getAttribute('data-selectednode'));

        const divNodeId = (listType === ListType.FOLDER_List ? `span[data-node-id='${  this.nodeId  }']`
            : `div[data-node-id='${  this.nodeId  }']`);

        let elm = ListExplorerUtil.getElementByQuery(divNodeId);
        this.previousTag = elm.children[0];

        const newSpan = document.createElement('span');
        const newI = document.createElement('i');

        if (listType === ListType.FOLDER_List) {
            ListExplorerStore.selectedFolder = ListExplorerStore.getActiveNode(this.nodeId);;
            this.renameNodeParentId = ListExplorerStore.selectedFolder.parentNodeId;
            text = ListExplorerStore.selectedFolder.name;
            isFolder = true;
        }
        else {
            this.renameNodeParentId = activeNode.parentNodeId;
            elm = elm.firstChild;
            elm = elm.childNodes[1];
            text = elm.textContent;
        }
        elm.removeChild(elm.firstChild);
        this.prevNodeName = text;

        const input = document.createElement('input');

        //Class Assigned only to set scroll bar position and set font size class
        const inputClass = `${fontSizeClass  } renameClass clearButton`;

        //Set input style based on font size
        switch (fontSizeClass) {
            case "small-normal":
                input.style.lineHeight = 1.5;
                break;
            case "medium-normal":
                input.style.lineHeight = 1.3;
                break;
            case "large-normal":
                input.style.lineHeight = 1;
                break;
            default:
                input.style.lineHeight = 1.3;
                break;
        }

        //Set input style based on List/Folder
        if (listType === ListType.FOLDER_List) {
            input.style.height = (fontSizeClass === 'large-normal') ? '22px' : '21px';
            input.style.marginTop = '-1px';
            input.style.marginLeft = '8px';
        }
        else {
            input.style.height = (fontSizeClass === 'large-normal') ? '23px' : '21px';
            if (BrowserUtil.isIEBrowser())
                input.style.marginTop = '-22px';

        }

        input.setAttribute('class', inputClass);
        input.style.minWidth = '6px';
        input.style.maxWidth = ListExplorerUtil.getListExplorerWindowWidth(true, nodeId, isFolder, activeNode.listTreeItemLevel);
        input.maxLength = this.textLimit;
        input.type = 'text';
        input.value = text;
        input.setAttribute('size', input.value.length);
        input.autofocus = 'true';
        input.onfocus = this.handleFocus;
        input.onblur = this.handleBlur;
        input.onkeyup = this.handleOnKeyup;
        input.onkeydown = this.handleOnKeyDown;
        input.setAttribute('data-node-id', nodeId);

        newSpan.appendChild(newI);
        newSpan.appendChild(input);
        elm.appendChild(newSpan);

        //Set Scrollbar position
        if (currentAction === ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST || currentAction === ListExplorerConstants.ActionTypes.COPY_PASTE) {
            ListExplorerUtil.getScrollPosition(nodeId);
        }
        input.focus();
        BrowserUtil.enableEvents();

    }

    renameNode(event) {

        const newNodeName = StringUtil.trim(event.target.value);
        const selectedNode = ListExplorerStore.getActiveNode(this.nodeId);
        const elm = event.target.parentNode.parentNode;
        if (elm === undefined || elm === null) return;

        if (StringUtil.isEmpty(newNodeName)) {

            if (selectedNode.listType === ListType.FOLDER_List) {
                this.removeChild(elm, selectedNode.name);
            }
           else if (selectedNode.listType === ListType.LIST_List) {               
                // const prevNodeName = document.createTextNode(selectedNode.name);
                // if (!StringUtil.isEmpty(elm) && !StringUtil.isEmpty(prevNodeName))
                //     elm.replaceChild(prevNodeName, elm.childNodes[0]);   
                event.target.parentNode.parentNode.textContent = selectedNode.name;                
            }
            this.setState({ renameNode: false });
            ListExplorerStore.isRename = false;
        }
        else {
            if ((this.prevNodeName === newNodeName)) {
                if (selectedNode.listType === ListType.FOLDER_List) {
                    this.removeChild(elm, selectedNode.name);
                }
                else {
                    event.target.parentNode.parentNode.textContent = newNodeName;
                    // if (this.contextMenuCurrentAction === ContextMenuConstants.DUPLICATE)
                    //     this.TabONeil.fetchListCataglogData(this.nodeId.toString(), this.nodeId.toString(), newNodeName);
                }
                this.setState({ renameNode: false });
                ListExplorerStore.isRename = false;
            }
            else {
                const listName = ListExplorerStore.validateListName(newNodeName, this.renameNodeParentId, ContextMenuConstants.RENAME, selectedNode.listType);
                const myListRename = {
                    parentNodeId: this.renameNodeParentId,
                    //nodeName: StringUtil.stripHTML(listName),
                    nodeName: listName,
                    nodeId: this.nodeId
                };
                listName === null;
                BrowserUtil.disableEvents();
                ListActions.getUpdateListNode(myListRename.nodeId, myListRename.nodeName, myListRename.parentNodeId);

                if (selectedNode.listType === ListType.FOLDER_List) {
                    this.removeChild(elm, selectedNode.name);
                }
                if (this.props.listId.toString() === this.nodeId.toString()) {
                    ListStore.updateSelectedListItem(myListRename.nodeId, myListRename.nodeId, myListRename.nodeName, ListExplorerStore.activeNode.categoryType, ListExplorerStore.activeNode.parentNodeId, ListExplorerStore.activeNode.parentCategoryType, escape(ListExplorerStore.activeNode.parentCategoryName), ListExplorerStore.activeNode.parentListType, ListExplorerStore.activeNode.sourceCategoryType, ListExplorerStore.activeNode.sourceParentCategoryName);
                    this.props.setLoading(myListRename.nodeId, myListRename.nodeName, false);
                }
                this.setState({ renameNode: false });
                ListExplorerStore.isRename = false;
                // else if (selectedNode.listType == ListType.LIST_List) {
                // this.TabONeil.fetchListCataglogData(this.nodeId.toString(), this.nodeId.toString(), newNodeName);
                // }
            }
        }
    }

    removeChild(elm, nodeName) {
        this.previousTag.textContent = nodeName;

        elm.removeChild(elm.children[1]);
        elm.insertBefore(this.previousTag, elm.firstChild);
    }

    handleOnKeyDown(e) {
        const self = e.target;
        self.size = (self.value.length > 1) ? self.value.length : 1;
    }

    handleBlur(e) {
        if (this.keyCodePressed !== 13 && e.type === 'blur') {
            // PANWEB-2740
            if (BrowserUtil.isIEBrowser()) {
                if ((this.ieFocusTag % 2) === 1) {
                    e.target.focus();
                } else {
                    this.renameNode(e);
                }
                this.ieFocusTag += 1;
            } else {
                this.renameNode(e);
            }
        }
        if (this.keyCodePressed) {
            this.keyCodePressed = null;
        }
    }

    handleOnKeyup(e) {
        if (KeyCodes.ENTER === e.keyCode) {
            this.keyCodePressed = e.keyCode;
            this.renameNode(e);
        }
    }

    handleFocus(e) {
        e.target.select();
    }
    /************** End Rename Item ***************/

    /************** Start Delete Item *************/
    deleteListItem(nodeId, nodeName, rootParentId, parentId, listType, categoryType) {
        if (this.contextMenuCurrentAction === ContextMenuConstants.REMOVE_FROM_FAVORITES)
            this.removeFromFavorites(nodeId, nodeName, rootParentId, parentId, listType);
        else
            this.deleteItem(nodeId, nodeName, rootParentId, parentId, listType, categoryType);
    }

    deleteItem(nodeId, nodeName, rootParentId, parentId, listType, categoryType) {
        let deleteNodeIds = null;
        let elm = null;
        const self = this;
        // let category = ListExplorerStore.findOneilOwnershipNodeById(nodeId)
        // let node = null;       
        // let childNodeIds = null;

        // if (category)
        //     node = category.childNodes.find((n) => n.nodeId.low === nodeId)
        // if (node)
        //     childNodeIds = node.childNodes.map((item) => item.nodeId.low).join("; ");

        if (!this.state.renameNode) {
            if (!StringUtil.isEmpty(ListExplorerStore.selectedNodeIds) && ListExplorerStore.selectedNodeIds.length > 0) {

                ListExplorerStore.selectedNodeIds.map((item) => {
                    elm = ListExplorerUtil.getElementByQuery(`div[data-node-id='${  item.nodeId.low  }']`);
                    if (elm) elm.className = `child-hover ${  self.getFontSizeClass()  } child-item`;
                    //Log Usage
                    ConsoleStore.trackUsage("ListIsDeleted", '', []);
                    return item;
                });
                deleteNodeIds = ListExplorerStore.selectedNodeIds.map((item) => item.nodeId.low).join("; ");
            }
            else {
                deleteNodeIds = typeof (nodeId) === 'object' ? nodeId.low : nodeId;
                //Log Usage
                ConsoleStore.trackUsage("ListIsDeleted", '', []);
            }

            //Delete non empty folder
            // if(!StringUtil.isEmpty(childNodeIds)) {
            //     deleteNodeIds = deleteNodeIds + "; " + childNodeIds
            // }

            this.deleteNode(deleteNodeIds, nodeName, rootParentId, parentId, listType, ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE, categoryType);
        }
    }

    deleteNode(nodeId, nodeName, rootParentId, parentId, listType, action, categoryType = null) {
        let flatternList = [], activeNodeData;

        _.clone(ListExplorerStore.flatternDataSource(ListExplorerStore.getDataSource(), flatternList));
        if (ListExplorerStore.selectedNodeIds.length > 1) {
            activeNodeData = ListStore.getSelectedListItem();
            // const tempArr = _.filter(nodeId.toString().split(';').map(Number), (x) => x !== activeNodeData.SelectedListId);
            const tempArr = _.filter(nodeId.toString().split(';').map(Number), (x) => x !== Number(activeNodeData.SelectedListId));
            flatternList = _.filter(flatternList, (i) => tempArr.indexOf(i.nodeId.low) === -1 );
        }

        this.deleteInfo = {
            nodeId, nodeName, rootParentId, parentId, listType, action, categoryType: categoryType, dataSource: flatternList, activeNodeData: activeNodeData
        }

        ListExplorerStore.setDeleteInfo(this.deleteInfo);
        // below if-block is commented because it was clearing the current-active-list while deleting a list (PANWEB-6607)
        // if(action !== ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES){
        //     this.TabONeil.clearGridData();
        // }
        if(ListExplorerStore.contextNode.isUnShared){
            ListExplorerStore.deleteExplorerNode({ nodeIds: nodeId, nodeName: nodeName }) 
        }
        else{
            ListActions.getDeleteTreeNode(nodeId, nodeName, action);
        }
        
    }

    updateListOnDelete() {
        let deletInfo = this.deleteInfo;
        if(this.deleteInfo === undefined || this.deleteInfo === null){
            deletInfo = ListExplorerStore.getDeleteInfo();
        }
        if (deletInfo === undefined || deletInfo === null) return;
        let {
            nodeId
        } = deletInfo;
        const { nodeName, rootParentId, parentId, listType, action, categoryType, dataSource, activeNodeData } = deletInfo;
        let selectedNodeId = null;
        //let favNode;
        let sourceListId;
        //let nodesToSearch;

        if (!StringUtil.isEmpty(ListExplorerStore.selectedNodeIds)) {
            //nodeId = ListExplorerStore.selectedNodeIds[ListExplorerStore.selectedNodeIds.length - 1].nodeId.low;
            nodeId = selectedNodeId = activeNodeData.SelectedListId
        }
        else
            selectedNodeId =typeof (nodeId) === 'object' ? nodeId.low : nodeId;

        // const nodesToSearch = ListExplorerStore.nodesToSearch(ListCategoryType.FAVORITE_ListCategory);
        // const favNode = this.findNodeByName(nodesToSearch[0], nodeName);
        const favNode = _.filter(dataSource, (item) => item.categoryType === ListCategoryType.FAVORITE_ListCategory && item.name === nodeName)[0];
        const currentNodeId = typeof (ListExplorerStore.activeNode.nodeId) === 'object' ? ListExplorerStore.activeNode.nodeId.low : ListExplorerStore.activeNode.nodeId;
        if (!StringUtil.isEmpty(favNode) && favNode.nodeId.low === currentNodeId) {
            if (!StringUtil.isEmpty(favNode.sourceListId)) {
                sourceListId = typeof (favNode.sourceListId) === 'object' ? favNode.sourceListId.low : favNode.sourceListId;
            }
        }

        let selectedNodeIdValue = typeof (selectedNodeId) === 'object'? selectedNodeId.low : selectedNodeId;

        if ((parseInt(listType) === ListType.LIST_List && currentNodeId === selectedNodeIdValue) || !StringUtil.isEmpty(ListExplorerStore.selectedNodeIds)) {
            if (action !== ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES || categoryType === ListCategoryType.FAVORITE_ListCategory) {
                const nextActiveItem = ListExplorerStore.getNextAvailableList(typeof(nodeId) === 'object' ? nodeId.low : nodeId, rootParentId, parentId, listType, dataSource);
                if (nextActiveItem.categoryType === ListCategoryType.FAVORITE_ListCategory)
                    this.TabONeil.fetchListCataglogData(nextActiveItem.nodeId.low.toString(), nextActiveItem.sourceListId.low.toString(), nextActiveItem.name);
                else
                    this.TabONeil.fetchListCataglogData(nextActiveItem.nodeId.low.toString(), nextActiveItem.nodeId.low.toString(), nextActiveItem.name);
            }
        }
        else if ((parseInt(listType) === ListType.LIST_List && currentNodeId !== selectedNodeIdValue && sourceListId === selectedNodeIdValue) || !StringUtil.isEmpty(ListExplorerStore.selectedNodeIds)) {
            if (action !== ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES || categoryType === ListCategoryType.FAVORITE_ListCategory) {
                const nextActiveItem = ListExplorerStore.getNextAvailableList(favNode.nodeId.low, 1, 1, listType, dataSource);
                if (nextActiveItem.categoryType === ListCategoryType.FAVORITE_ListCategory)
                    this.TabONeil.fetchListCataglogData(nextActiveItem.nodeId.low.toString(), nextActiveItem.sourceListId.low.toString(), nextActiveItem.name);
                else
                    this.TabONeil.fetchListCataglogData(nextActiveItem.nodeId.low.toString(), nextActiveItem.nodeId.low.toString(), nextActiveItem.name);
            }
        }
        else if (parseInt(listType) === ListType.FOLDER_List) {
            ListExplorerStore.nextAvailableFolder = ListExplorerStore.getNextAvailableList(nodeId, rootParentId, parentId, listType, dataSource);
        }
        this.deleteInfo = null;
        ListExplorerStore.setDeleteInfo(null);
    }

    /*************** End Delete Item **************/

    /*************** Open Alert *******************/
    openAlert(msId, symbol, sourceTypeId, alertStatusType, existAlerts,instrumentType) {

        if (alertStatusType === ListAlertStatusType.ActiveDisabled || alertStatusType === ListAlertStatusType.TriggeredDisabled) {
            ConsoleActions.exceptionHandle(null, false, 'List alerts can be created for up to 10 lists, and only for lists which have 1,000 symbols or less.', false, "LIST ALERT LIMIT", true);
        }
        else {
            const alertModal = <AlertsDialog
            conditionTypeId={AlertConditionType.Or}
            msId={msId}
            instrumentId={msId}//{instrumentId} SK TO DO confirm whether instrument id needs to be passed or not
            symbol={symbol}
            sourceTypeId={sourceTypeId}
            open={true}
            existAlerts={existAlerts}
            closeAlert={this.closeAlert} 
            instrumentType = {instrumentType}/>

            this.setState({ alertModal: alertModal });
        }

    }

    openAndAlert = (msId, symbol, sourceTypeId, alertStatusType, existAlerts,instrumentType,currencyCode) => {
        let andAlertModal = <AndAlertsDialog
            msId={msId}
            instrumentId={msId}
            symbol={symbol}
            sourceTypeId={sourceTypeId}
            open={true}
            alertId={0}
            existAlerts={existAlerts}
            closeAndAlert={this.closeAndAlert}
            instrumentType = {instrumentType}
            currencyCode={currencyCode}
            />

        this.setState({ andAlertModal: andAlertModal });
    }
    closeAndAlert() {
        this.setState({ andAlertModal: null });
    }
    closeAlert() {
        this.setState({ alertModal: null });
    }
    closeMetricAlert() {
        ListActions.openMetricLibrary(false);
    }
    confirmAlertDelete() {
        const dialog = <CommonDialog preClass={this.state.preClass}
            isOpen={true}
            title={LocalizationStore.getTranslatedData("list_DelAlt", 'Delete Alerts')}
            hideModal={this.cancelDelete}
            backButtonShow={false}
            cancelButtonShow={true}
            submitButtonShow={true}
            submitHandler={this.deleteAllAlertsByMSID}
            submitButtonText={LocalizationStore.getTranslatedData("delete_upper", 'DELETE')}
        >
            <Fragment><div className="alert-stuff"><p>{LocalizationStore.getTranslatedData("list_DelAltConfirmMsg", "Are you sure you want to delete all alerts for the selected symbols?")}</p></div><div className="clearfix"></div></Fragment>
        </CommonDialog>
        this.setState({ commonDialog: dialog });
    }

    deleteAllAlertsByMSID = async () => {
        const msIDS = await ONeilViewStore.getMsIds(this.contextMenuObj.selectedIndexes);
        await AlertActions.deleteAllAlertsByMsId(msIDS, this.TabONeil.refs.ONeilListView.state.cacheId);
        this.cancelDelete();
    }

    confirmListAlertDelete(nodeId,conditionTypeId){
        let alertMessage = null;
        if(conditionTypeId == AlertConditionType.And){
            alertMessage = 'Do you want to delete And-condition alerts?';
        }else{
            alertMessage = 'Do you want to delete Or-condition alerts?';
        }
        
        let dialog = <CommonDialog preClass={this.state.preClass}
        isOpen={true}
        title={LocalizationStore.getTranslatedData("list_DelAlt",'Delete Alerts')}
        hideModal={this.cancelDelete}
        backButtonShow={false}
        cancelButtonShow={true}
        submitButtonShow={true}
        submitHandler={() => this.deleteListAlert(nodeId,conditionTypeId)}
        submitButtonText={LocalizationStore.getTranslatedData("delete_upper",'DELETE')}
    >
        <Fragment><div className="alert-stuff"><p>{alertMessage}</p></div><div className="clearfix"></div></Fragment>
    </CommonDialog>
    this.setState({ commonDialog: dialog });
    }

    deleteListAlert = async (nodeId,conditionTypeId) => {
        await AlertActions.deleteAllListAlerts(nodeId,conditionTypeId)
        this.cancelDelete();
    }

    cancelDelete = () => {
        this.setState({ commonDialog: null });
    }
    /*************** End Open Alert *******************/

    /*************** Flag update onClick **************/
    updateGlobalFlag(msId, boolValue, selectedIndexes) {
        const selectedTab = ListStore.getState().SelectedTabKey;
        const tabType = ListStore.getTabType(selectedTab);
        const msids = msId;
        const snapshotdates = "";
        const isDelete = boolValue;
        let cacheId;
        let dbType;
        switch (tabType) {
            case ListManagerTabType.Oneil:
                cacheId = this.TabONeil.refs.ONeilListView.state.cacheId;
                dbType = 1;
                break;
            case ListManagerTabType.Ownership:
                cacheId = 0;
                dbType = 2;
                break;
                default: break;
        }
        GridActions.updateGlobalFlag(msids.toString(), snapshotdates, isDelete, cacheId, dbType, selectedIndexes);
    }

    // Get Font Size class
    getFontSizeClass() {
        const selectedTab = ListStore.getState().SelectedTabKey;
        const tabType = ListStore.getTabType(selectedTab);
        let fontSizeClass = 'medium-normal';
            if(tabType === ListManagerTabType.Oneil){
                fontSizeClass = this.getFontSize(this.props.textSize)
            }else if(tabType === ListManagerTabType.Ownership){
                fontSizeClass = this.getFontSize(this.props.textSizeOwnership)
            }else{
                fontSizeClass = this.getFontSize(this.props.textSizeExternal)
            }
        return fontSizeClass;
    }
    getFontSize(textSize) {
        let fontSizeClass = '';
        switch (textSize) {
            case "Small":
                fontSizeClass = 'small-normal';
                break;
            case "Medium":
                fontSizeClass = 'medium-normal';
                break;
            case "Large":
                fontSizeClass = 'large-normal';
                break;
            default:
                fontSizeClass = 'medium-normal';
                break;
        }
        return fontSizeClass;
    }

    showValidationWindow() {
        let activeValidationModal = null;
        activeValidationModal = <ValidationWindow showModal={!this.state.showValidationModal} closeModal={this.closeModal} />
        this.setState({ activeValidationModal: activeValidationModal });
    }

    getActiveTabContent() {
        let tabContent = null;
        const tabType = ListStore.getTabType(this.props.selectedTabKey);
        if (tabType === undefined) return tabContent;

        const fontSizeClass = this.getFontSizeClass();
        const tabCollection = this.props.TabCollection;
        const selectedTabKey = this.props.selectedTabKey;

        switch (tabType) {
            case ListManagerTabType.Oneil:
                tabContent = <TabONeil ref={(ref) => { this.TabONeil = ref; }}
                    tabCollection={tabCollection}
                    selectedTabKey={selectedTabKey}
                    dataSource={this.state.ListExplorer.ONeil}
                    onTabSelectionChanged={this.onTabSelectionChanged}
                    addNewItem={this.state.addNewItem}
                    duplicateItem={this.duplicateItem}
                    showOriginal={this.showOriginal}
                    setLoading={this.props.setLoading}
                    loading={this.props.loading}
                    listId={this.props.listId}
                    actualListId={this.props.actualListId}
                    listName={this.props.listName}
                    nodeType={this.props.nodeType}
                    onContextMenuClick={this.onContextMenuClick}
                    renderRenameNode={this.renderRenameNode}
                    renameNode={this.state.renameNode}
                    duplicateNode={this.state.duplicateNode}
                    parentNodeIdList={this.state.parentNodeIdList}
                    showOriginalClick={this.state.showOriginalClick}
                    previousSelectedNodeId={this.previousSelectedNodeId}
                    deleteListItem={this.deleteListItem}
                    updateGlobalFlag={this.updateGlobalFlag}
                    loadCompleteDb={this.loadCompleteDb}
                    fontSizeClass={fontSizeClass}
                    onGridRowContextMenuItemClick={this.onGridRowContextMenuItemClick}
                    showValidationWindow={this.showValidationWindow} />;
                break;
            case ListManagerTabType.Ownership:
                tabContent = <TabOwnership ref={(ref) => { this.TabOwnership = ref; }}
                    tabCollection={tabCollection}
                    selectedTabKey={selectedTabKey}
                    dataSource={this.state.ListExplorer.Ownership}
                    onTabSelectionChanged={this.onTabSelectionChanged}
                    addNewItem={this.state.addNewItem}
                    setLoading={this.props.setLoading}
                    loading={this.props.loading}
                    listId={this.props.listId}
                    actualListId={this.props.actualListId}
                    listName={this.props.listName}
                    onContextMenuClick={this.onContextMenuClick}
                    renderRenameNode={this.renderRenameNode}
                    renameNode={this.state.renameNode}
                    duplicateNode={this.state.duplicateNode}
                    updateGlobalFlag={this.updateGlobalFlag}
                    fontSizeClass={fontSizeClass}
                    loadCompleteDb={this.loadCompleteDb}
                    duplicateItem={this.duplicateItem}
                    showOriginal={this.showOriginal}
                    nodeType={this.props.nodeType}
                    parentNodeIdList={this.state.parentNodeIdList}
                    showOriginalClick={this.state.showOriginalClick}
                    previousSelectedNodeId={this.previousSelectedNodeId}
                    deleteListItem={this.deleteListItem}
                    onGridRowContextMenuItemClick={this.onGridRowContextMenuItemClick}
                    showValidationWindow={this.showValidationWindow}
                />;

                break;
            case ListManagerTabType.External:
                tabContent = <TabExternalData ref="TabExternalData"
                    tabCollection={tabCollection}
                    selectedTabKey={selectedTabKey}
                    dataSource={this.state.ListExplorer.External}
                    onTabSelectionChanged={this.onTabSelectionChanged}
                    addNewItem={this.state.addNewItem}
                    setLoading={this.props.setLoading}
                    loading={this.props.loading}
                    listId={this.props.listId}
                    actualListId={this.props.actualListId}
                    listName={this.props.listName}
                    onContextMenuClick={this.onContextMenuClick}
                    renderRenameNode={this.renderRenameNode}
                    renameNode={this.state.renameNode}
                    duplicateNode={this.state.duplicateNode}
                    updateGlobalFlag={this.updateGlobalFlag}
                    fontSizeClass={fontSizeClass}
                    loadCompleteDb={this.loadCompleteDb}
                    selectedExternalNodes={this.props.selectedExternalNodes}
                    undoActionObj={this.props.undoActionObj}
                    />;
            default:
        }
        return tabContent;
    }

    onContextMenuClick(obj, existAlerts) {
        this.contextMenuObj = obj;
        if (obj.showContextMenu) {
            this.setState({ showContextMenu: true })
        }
        else {
            this.setState({ showContextMenu: false });
            this.onExplorerContextMenuItemClick(obj.categoryType, obj.action, obj.rootParentId, obj.nodeId, obj.nodeName, obj.listType, obj.sourceListId, obj.parentId,obj.alertStatusType, existAlerts, obj.spId);
        }

    }

    hideContextMenu = () => {
        this.setState({ showContextMenu: false });
    }

    renderContextMenu() {
        return (
            <ContextMenu
                showContextMenu={this.state.showContextMenu}
                contextMenuObj={this.contextMenuObj}
                hideContextMenu={this.hideContextMenu}
                onExplorerContextMenuItemClick={this.onExplorerContextMenuItemClick}
                onGridColumnsContextMenuItemClick={this.onGridColumnsContextMenuItemClick}
                onGridRowContextMenuItemClick={this.onGridRowContextMenuItemClick}
                onGridCustomFilterContextMenuItemClick={this.onGridCustomFilterContextMenuItemClick}
            />
        )
    }

    openAddcustomMetrics(dataItemId) {
        const customMetricState = ListStore.getCustomMetricState();
        if (!customMetricState) {
            let lib = ListStore.getMetricLibrary();
            // let lib = oNeilViewStore.getColumnInfo();
            if (lib == "") return null;
            let selectedColumn = lib.itemData.find((element) => element.MsItemID == dataItemId);
            let listId = this.props.actualListId;
            let selectedCategory = '';
            if (selectedColumn && (selectedColumn.periodicity != 0 || selectedColumn.timeback != 0)) {
                let metricList = ListStore.getSearchData(selectedColumn.itemSymbol);
                selectedCategory = metricList.find((element) => element.CategoryId == selectedColumn.CategoryId);
            }
            dispatch(addMetric(listId, selectedColumn, selectedCategory));
            document.body.classList.add('add-Matric-custom-Pop');
            ListActions.openMetricLibrary(true);
        }
    }

    render() {
        const activeTabContent = this.getActiveTabContent();
        this.loadCompleteDb = false;
        return (
            <span>{this.renderContextMenu()}{activeTabContent}{this.state.alertModal}{this.state.andAlertModal}{this.state.commonDialog}{this.state.activeModal}{this.state.activeValidationModal}</span>
        );
    }
}
