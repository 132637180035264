import { combineReducers } from 'redux';
import GroupsPanelReducer from './GroupsPanelReducer';
import SymbolsPanelReducer from './SymbolsPanelReducer';
import AddSymbol from './AddSymbol';
import LiqAndRatingFilterReducer from './LiqAndRatingFilterReducer';
import MenuTabReducer from './MenuTabReducer';

const menuPanel = combineReducers({
    AddSymbol,
    GroupsPanelReducer,
    LiqAndRatingFilterReducer,
    SymbolsPanelReducer,
    MenuTabReducer
});
  
  
export default menuPanel;