import keyMirror from "keymirror";

export const MenuDialogConstants = {
  ActionTypes: keyMirror({
    OPEN: null,
    QUOTE_SERVICE: null,
    APPEARANCE: null,
    NOTIFICATION:null,
    ACCOUNT: null,
    HELP: null,
    SET_SELECTQUOTESERVICEOPTION: null,
    REPORTED_EARNINGS: null,
    CURRENCY_CONVERSION: null,
    UPDATE_REVENUE: null,
    UPDATE_EARNINGS:null,
    OPEN_MOVING_AVERAGE_DIALOG:null,
    OPEN_CEF_MOVING_AVERAGE_DIALOG:null,
    OPEN_INDEX_MOVING_AVERAGE_DIALOG:null,
    OPEN_ETF_MOVING_AVERAGE_DIALOG:null,
    OPEN_INDUSTRYGROUP_MOVING_AVERAGE_DIALOG:null,
    OPEN_CASHSPOTS_MOVING_AVERAGE_DIALOG:null,
    OPEN_FUTURES_MOVING_AVERAGE_DIALOG: null,
    OPEN_CURRENCY_MOVING_AVERAGE_DIALOG:null,
    OPEN_CRYPTO_CURRENCY_MOVING_AVERAGE_DIALOG: null,
    OPEN_FUNDS_MOVING_AVERAGE_DIALOG: null,
    OPEN_CASHSPOTS_MOVING_AVERAGE_DIALOG:null,
    OPEN_FUTURES_MOVING_AVERAGE_DIALOG: null,
    OPEN_INDEXLINE_DIALOG: null,
    OPEN_CEF_INDEXLINE_DIALOG:null,
    OPEN_INDEX_INDEXLINE_DIALOG:null,
    OPEN_ETF_INDEXLINE_DIALOG:null,
    OPEN_FUNDS_INDEXLINE_DIALOG:null,
    OPEN_INDUSTRYGROUP_INDEXLINE_DIALOG:null,
    OPEN_INDUSTRYGROUP_RS1_DIALOG: null,
    OPEN_INDUSTRYGROUP_RS2_DIALOG:null,
    OPEN_PR_DIALOG: null,
    OPEN_CEF_PR_DIALOG: null,
    OPEN_INDEX_PR_DIALOG: null,
    OPEN_ETF_PR_DIALOG: null,
    OPEN_RS1_DIALOG: null,
    OPEN_RS2_DIALOG: null,
    OPEN_CEF_RS1_DIALOG: null,
    OPEN_CEF_RS2_DIALOG: null,
    OPEN_INDEX_RS1_DIALOG: null,
    OPEN_INDEX_RS2_DIALOG: null,
    OPEN_ETF_RS1_DIALOG: null,
    OPEN_ETF_RS2_DIALOG: null,
    OPEN_FUND_RS1_DIALOG: null,
    OPEN_FUND_RS2_DIALOG: null,
    OPEN_STREET_TARGET_PRICE_DIALOG: null,
    OPEN_WHATS_NEW_DIALOG: null
  })
};
