import React, { Component } from 'react';
import { CompIntervalDropDown } from "../Presentational";
import { connect } from 'react-redux';
import { updateInterval, resetCalendar } from '../../../../../Actions/ComparisonActions';
import GraphType from "GraphType";
import { CompareMenuType } from "CompareTabType";

class IntervalDropdown extends Component {
    onClick = (periodicity, event) => {
        const title = event.target.textContent;
        const periodicityDuration = this.props.periodicityDuration;
        const prevPeriodicity = this.props.periodicity;
        let duration;

        if (prevPeriodicity != periodicity) {
            switch (periodicity) {
                case GraphType.Daily:
                    duration = periodicityDuration.Daily;
                    break;
                case GraphType.Weekly:
                    duration = periodicityDuration.Weekly;
                    break;
                case GraphType.Monthly:
                    duration = periodicityDuration.Monthly;
                    break;
                case GraphType.Quarterly:
                    duration = periodicityDuration.Quarterly;
                    break;
                case GraphType.Annual:
                    duration = periodicityDuration.Annual;
                    break;
                default:
                    break;
            }

            const data = { periodicity, duration, title: title }
            this.props.updateInterval(data);
            this.props.resetCalendar();
        }
    }

    render() {
        const { title, periodicity, periodicityDisplayLabel } = this.props;

        return (
            <CompIntervalDropDown onClick={this.onClick} title={title} periodicity={periodicity} periodicityDisplayLabel={periodicityDisplayLabel} />
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.calendarControl;
    const { periodicityDuration, title, periodicity, periodicityDisplayLabel } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

    return { periodicityDuration, title, periodicity, periodicityDisplayLabel };
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateInterval: (interval) => dispatch(updateInterval(interval)),
        resetCalendar: () => dispatch(resetCalendar())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IntervalDropdown);