import { connect } from "react-redux";
import { onDefaultHeadersRestored, setFinancialBlockDialogState } from "../../../../../../Actions/FinancialBlockDialogActions.js"
import React, { useState, useEffect, useRef } from "react";

const RestoreDefaultDialog = (props) => {

    const [state, setState] = useState({ show: false })
    const restoreBtnRef = useRef(null)
    const cancelBtnRef = useRef(null)
    
    useEffect(() => {
        trueState()
        restoreBtnRef.current.focus()
        restoreBtnRef.current.style.border="1px blue solid"
        return () => {
            falseState()
        }
    }, [])

    const trueState = () => {
        setTimeout(() => {
            setState({ show: true });
        }, 400);
    }
    const falseState = () => {
        setTimeout(() => {
            setState({ show: false });
        }, 400);
    }

    const onCancel = () => {
        setState({ show: false });
        cancelBtnRef.current.style.border="1px blue solid"
        restoreBtnRef.current.style.border="none"
        setTimeout((() => {
            // props.onCancel();
            props.setFinancialBlockDialogState({ IsFinancalRestoreDialogOpen: false });
        }).bind(), 600);
    }
    const onRestore = () => {
        setState({ show: false });
        setTimeout((() => {
            // props.onRestore();
            props.onDefaultHeadersRestored();
        }).bind(), 600);
    }

    if (props.IsFinancalRestoreDialogOpen) {
        return (
            <div className={state.show ? 'modal-internal show' : 'modal-internal'}>
                <div className="modal-internal-hold">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="modal-material">
                                <div className="modal-material-hold">
                                    <p>{"Are you sure you want to restore metric selection to default settings ?"}</p>
                                    <p className="restore-presentation">{"Restore will apply to current chart interval only."}</p>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <span className="btn-box center">
                                <button ref={cancelBtnRef} type="button" className="btn btn-xs btn-hover-lightblue" onClick={onCancel}>{"CANCEL"}</button>
                                <button ref={restoreBtnRef} type="button" className="btn btn-xs btn-hover-lightblue" onClick={onRestore} >{"RESTORE"}</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (<div></div>)
    }
}

const mapStateToProps = (state) => {
    const { IsFinancalRestoreDialogOpen } = state.DatagraphReducers.financialDialogBoxReducer;
    return { IsFinancalRestoreDialogOpen }
}

const mapDispatchToProps = (dispatch) => ({
    setFinancialBlockDialogState: (data) => dispatch(setFinancialBlockDialogState(data)),
    onDefaultHeadersRestored: () => dispatch(onDefaultHeadersRestored())
})

export default connect(mapStateToProps, mapDispatchToProps)(RestoreDefaultDialog)