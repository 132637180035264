import keyMirror from "keymirror";

export const NupViewConstant = {
    ActionTypes: keyMirror({
        UPDATE_NUP_SYMBOL_LIST: null,
        PROCESS_LIST: null,
        PROCESS_CHART: null,
        UPDATE_NUP_SETTINGS: null,
        UPDATE_NUP_SELECTION: null,
        UPDATE_NUP_CHART_APPEARANCE: null,
        NUP_SYMBOL_CHANGE: null,
        UPDATE_SHOW_CHART: null,
        UPDATE_NUP_TOTAL_COUNT: null,
        UPDATE_NUP_CHART: null,
        UPDATE_NUP_SCALE_PERIODICY: null,
        UPDATE_NUP_SELECTED: null,
        UPDATE_SHOW_STORY: null,
        UPDATE_NUP_STORY: null,
        UPDATE_NUP_CHART_DIMENSION: null,
        UPDATE_CHART_DIMENSION: null,
        UPDATE_NUP_CHART_LOADING: null,
        NUP_PERIODICITY_CHANGE: null,
        NUP_SINGLE_PERIODICITY_CHANGE: null,
        UPDATE_PERIODICITY_OPTIONS: null,
        UPDATE_PERIODICITY_BUTTON_STATE: null,
        NUP_COLUMN_PERIODICITY_CHANGE: null,
        UPDATE_NUP_STORE: null,
        UPDATE_COLS_ROWS: null,
        NUP_EPS_RPS: null,
        UPDATE_NUP_LIST_DATA: null,
        UPDATE_NUP_REDRAW: null,
        INIT_NUP_CHART: null,
        UPDATE_NUP_VISIBLE_ITEM: null,
        PROCESS_NUP_LIST_DATA: null,
        UPDATE_NUP_ISNASDAQBASIC: null,
        UPDATE_CHART_SELECTION: null,
        UPDATE_SELECTED_INDEX: null,
        UPDATE_NUP_LIST_FLAGES: null,
        UPDATE_GLOBAL_FLAG: null,
        UPDATE_ALL_GLOBAL_FLAG: null,
        UPDATE_SELECT_ALL: null,
        UPDATE_NUP_FLAG: null,
        UPDATE_EPS_RPS_WITH_PERIODICITY: null,
        SHOULD_UPDATE_NUP_COMPONENT: null,
        UPDATE_NUP_QUOTE_SYMBOL: null,
        RESET_NUP_STATE: null,
        UPDATE_NUP_GLOBAL_SELECTED_INDEX: null,
        UPDATE_NUP_IS_SCROLL_AVAILABLE: null,
        NUP_IS_NON_IMPORTED: null,
        UPDATE_NUP_THEME: null,
        UPDATE_NUP_RESIZING: null,
        UPDATE_NUP_RT_VOLUME: null,
        UPDATE_NUP_NODE_COUNT: null
    })
}