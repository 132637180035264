import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class ChangeColumnSetLocationRequest extends BaseServiceRequest {

    constructor(parentNodeID, columnsetId) {
        super();
        super.addRequestUri("columnsetfolder.pb");
        super.addRequestParameter("p", parentNodeID.toString());
        super.addRequestParameter("c", columnsetId.toString());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}

export default ChangeColumnSetLocationRequest;
