import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BlockType from "../../../../Constants/BlockType";
import { IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper";

const { ActionTypes } = IndicatorsConstants;
const blockTypeArrayConst = [[BlockType.AccDist], [BlockType.PTOE], [BlockType.PTOS], [BlockType.EPSR], [BlockType.TechIndicator], [BlockType.MACD], [BlockType.RSI], [BlockType.Stochastics], [BlockType.WonStochastics], [BlockType.YTD], [BlockType.YTD1], [BlockType.YTD3], [BlockType.YTD5], [BlockType.YTD10]]
function getPropertiesObj(value){
    const propertiesObj = {};
    blockTypeArrayConst.map((item)=>{
        propertiesObj[item] = value;
        return item;
    })
    return propertiesObj;
}
function getArrayPropertiesObj(){
    const propertiesObj = {};
    blockTypeArrayConst.map((item)=>{
        propertiesObj[item] = [];
        return item;
    })
    return propertiesObj;
}
function getArithmaticScalePropertiesObj(){
    const propertiesObj = {};
    blockTypeArrayConst.map((item)=>{
        propertiesObj[item] = new ArithmaticScale();
        return item;
    })
    return propertiesObj;
}
const intialState = {
    indicatorMenu: [],
    blockMenu: [],
    SymbolInfo: null,
    indicatorVisuals: getArrayPropertiesObj(),
    indicatorLabels: {
        [BlockType.AccDist]: {
            labels: [],
            isTechRating: true,
            LabelMenu: {
                label: IndicatorLabelTranslateHelper[BlockType.AccDist],
                isTICustomModal: true,
                isShowSettingsDialog: false,
                isShowAboutDialog: true,
            },
            rank: 0,
            techText: IndicatorCommonTranslateHelper["SDRating"]
        },
        [BlockType.PTOE]: {
            labels: [],
            isType: true,
            LabelMenu: {
                label: "P/E",
                isTICustomModal: true,
                isShowSettingsDialog: true,
                isShowAboutDialog: true,
            },
            borderBottom: "none",
            pointerEvents: "none",
            cursor: "default",
            class: "types-n-scales",
            scaleText: IndicatorCommonTranslateHelper['Absolute'],
        },
        [BlockType.PTOS]: {
            labels: [],
            isType: true,
            LabelMenu: {
                label: "P/S",
                isTICustomModal: true,
                isShowSettingsDialog: true,
                isShowAboutDialog: true,
            },
            borderBottom: "none",
            pointerEvents: "none",
            cursor: "default",
            class: "types-n-scales",
            scaleText: IndicatorCommonTranslateHelper['Absolute'],
        },
        [BlockType.EPSR]: {
            labels: [],
            isType: false,
            LabelMenu: {
                label: IndicatorLabelTranslateHelper[BlockType.EPSR],
                isTICustomModal: true,
                isShowSettingsDialog: true,
            }
        },
        [BlockType.TechIndicator]: {
            labels: [],
            LabelMenu: {
                label: IndicatorLabelTranslateHelper[BlockType.TechIndicator],
                isTICustomModal: true,
                isShowSettingsDialog: true,
            },
        },
        [BlockType.MACD]: {
            labels: [],
            isScale: true,
            LabelMenu: {
                label: IndicatorLabelTranslateHelper[BlockType.MACD],
                isTICustomModal: true,
                isShowSettingsDialog: true,
            },
            scaleText: '',
        },
        [BlockType.RSI]: {
            labels: [],
            isScale: true,
            LabelMenu: {
                label: IndicatorLabelTranslateHelper[BlockType.RSI],
                isTICustomModal: true,
                isShowSettingsDialog: true,
            },
            scaleText: 'RSI',
        },
        [BlockType.Stochastics]: {
            labels: [],
            isScale: true,
            LabelMenu: {
                label: IndicatorLabelTranslateHelper[BlockType.Stochastics],
                isTICustomModal: true,
                isShowSettingsDialog: true,
            },
            scaleText: 'Stochastics',
        },
        [BlockType.WonStochastics]: {
            labels: [],
            isScale: true,
            LabelMenu: {
                label: IndicatorLabelTranslateHelper[BlockType.WonStochastics],
                isTICustomModal: true,
                isShowSettingsDialog: true,
            },
            scaleText: 'Stochastics',
        },
        [BlockType.YTD]: {
            labels: [],
            isScale: true,
            scaleText: '',
            LabelMenu: {
                label: IndicatorLabelTranslateHelper[BlockType.YTD],
                isTICustomModal: true,
                isShowAppearanceDialog: false,
                isShowAboutDialog: true,
            },
        },
        [BlockType.YTD1]: {
            labels: [],
            scaleText: '',
            isScale: true,
            LabelMenu: {
                label: `1-${IndicatorCommonTranslateHelper['Year']}`,
                isTICustomModal: true,
                isShowAppearanceDialog: false,
                isShowAboutDialog: true,
            },
        },
        [BlockType.YTD3]: {
            labels: [],
            scaleText: '',
            isScale: true,
            LabelMenu: {
                label: `3-${IndicatorCommonTranslateHelper['Year']}`,
                isTICustomModal: true,
                isShowAppearanceDialog: false,
                isShowAboutDialog: true,
            },
        },
        [BlockType.YTD5]: {
            labels: [],
            scaleText: '',
            isScale: true,
            LabelMenu: {
                label: `5-${IndicatorCommonTranslateHelper['Year']}`,
                isTICustomModal: true,
                isShowAppearanceDialog: false,
                isShowAboutDialog: true,
            },
        },
        [BlockType.YTD10]: {
            labels: [],
            scaleText: '',
            isScale: true,
            LabelMenu: {
                label: `10-${IndicatorCommonTranslateHelper['Year']}`,
                isTICustomModal: true,
                isShowAppearanceDialog: false,
                isShowAboutDialog: true,
            },
        },
    },
    GraphData: getArrayPropertiesObj(),
    RelativeData: getArrayPropertiesObj(),
    AbsoluteData: getArrayPropertiesObj(),
    scale: getArithmaticScalePropertiesObj(),
    startx: getPropertiesObj(0),
    SettingDict: getPropertiesObj(null),
    isAllIndicatorDataApiInitiated: false,
    indicatorResponse: null,
}

const IndicatorReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.RESET_INDICATOR_STATE:
            return { ...intialState };
        case ActionTypes.INIT_INDICATOR_STATE:
            return { ...state, ...action.states }
        case ActionTypes.UPDATE_INDICATOR_SETTINGS:
            state.SettingDict = { ...state.SettingDict, ...action.SettingDict };
            return { ...state };
        case ActionTypes.UPDATE_INDICATOR_GRAPH_DATA:
            state.GraphData[action.chartType] = action.GraphData;
            return { ...state };
        case ActionTypes.UPDATE_INDICATOR_RELATIVE_DATA:
            state.RelativeData[action.chartType] = action.RelativeData;
            return { ...state };
        case ActionTypes.UPDATE_INDICATOR_ABSOLUTE_DATA:
            state.AbsoluteData[action.chartType] = action.AbsoluteData;
            return { ...state };
        case ActionTypes.UPDATE_INDICATOR_DATA:
            state.indicatorVisuals[action.chartType] = action.indicatorVisuals[action.chartType];
            state.indicatorLabels[action.chartType] = action.indicatorLabels[action.chartType];
            state.scale[action.chartType] = action.scale;
            return { ...state };
        case ActionTypes.UPDATE_INDICATOR_SCALE:
            state.scale[action.chartType] = action.scale;
            return { ...state };
        case ActionTypes.UPDATE_INDICATOR_MENU:
            return { ...state,
                indicatorMenu: action.indicatorMenu,
                blockMenu: action.blockMenu 
            };
        case ActionTypes.PREPARE_INDICATOR_MENU:
            state.SymbolInfo = action.symbolInfo;
            return { ...state };
        case ActionTypes.UPDATE_IS_ALL_INDICATOR_API_INITIATED:
            return{
                ...state,
                isAllIndicatorDataApiInitiated: action.isAllIndicatorDataApiInitiated
            };
        case ActionTypes.UPDATE_INDICATOR_RAW_DATA:
            return {
                ...state, 
                indicatorResponse: action.indicatorResponse,
                isAllIndicatorDataApiInitiated: false
            };
        default:
            return state;
    }
}

export default IndicatorReducer;