import {
    CurrencyConversion
} from "../Constants/CurrencyConversion";

const {
    ActionTypes
} = CurrencyConversion;

export const setCurrencyData = (currencyData) => ({
    type: ActionTypes.SET_CURRENCY_DATA,
    currencyData
});

export const setESCurrencyData = (esCurrencyData) => ({
    type: ActionTypes.SET_ES_CURRENCY_DATA,
    esCurrencyData
});

export const setDefaultCurrencySign = (isDualCurrency, isMatchFinancials) => ({
    type: ActionTypes.SET_DEFAULT_CURRENCY_SIGN,
    isDualCurrency, isMatchFinancials
})


export const setIsCurrencyConversionChecked = (isCurrencyConversionChecked, symbol, nodeCount, isSymbolChange) => ({
    type: ActionTypes.SET_IS_CURRENCY_CONVERSION_CHECKED,
    isCurrencyConversionChecked, symbol, nodeCount, isSymbolChange
});

export const setInitCurrencySetting = (setting) => ({
    type: ActionTypes.SET_INIT_CURRENCY_SETTING,
    setting
});

export const setSelectedCurrencyCode = (selectedCurrencyCode) => ({
    type: ActionTypes.SET_SELECTED_CURRENCY_CODE,
    selectedCurrencyCode
});
