import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AlertStore from "AlertStore";
import AlertActions from 'AlertActions';
import _ from "underscore";
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import ReactDOM from "react-dom";
import LocalizationStore from "LocalizationStore";
export default class GeographyAlertDialog extends Component {
    constructor(props) {
        super(props);

        this.countryRegion = AlertStore.getCountryCollection();

        this.state = {
            browserFilterText: AlertStore.getBrowserFilterText()
        }

        // this.alertStoreStateChange = this.alertStoreStateChange.bind(this);
        this.getIndex = this.getIndex.bind(this);
        this.RowClick = this.RowClick.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
        this.selectRowsBetweenIndexes = this.selectRowsBetweenIndexes.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.setSelection = this.setSelection.bind(this);
        this.handleMouseDownGeographyModal = this.handleMouseDownGeographyModal.bind(this);
        this.handleMouseUpGeographyModal = this.handleMouseUpGeographyModal.bind(this);
        this.handleAlertGeographyOutsideClick = this.handleAlertGeographyOutsideClick.bind(this);

        this.lis = null;
        this.lastSelectedRow;

        this.mouseDownOnGeographyModal = false;
        this.mouseUpOnGeographytModal = false;

    }

    componentDidMount() {
        this.countryRegion = AlertStore.getCountryCollection();

        this.lis = [];
        setTimeout(() => {
            if (this.multiFilterListCountry) {
                this.lis = this.multiFilterListCountry.querySelectorAll('li')
                this.allRows = this.multiFilterListCountry.querySelectorAll('li')
            }
        }, 0)

        //AlertStore.addChangeListener(this.alertStoreStateChange);
        document.addEventListener('click', this.handleAlertGeographyOutsideClick, false);
    }
    componentWillUnmount() {
        //AlertStore.removeChangeListener(this.alertStoreStateChange);
        document.removeEventListener('click', this.handleAlertGeographyOutsideClick, false);
    }

    handleAlertGeographyOutsideClick(e) {
        let wasDown = this.mouseDownOnGeographyModal;
        let wasUp = this.mouseUpOnGeographytModal;
        this.mouseDownOnGeographyModal = false;
        this.mouseUpOnGeographytModal = false;

        if (!wasDown && !wasUp) {
            this.props.closeGeographyWindow();
        }

        e.stopPropagation();
    }

    handleMouseDownGeographyModal() {
        this.mouseDownOnGeographyModal = true;
    }

    handleMouseUpGeographyModal() {
        this.mouseUpOnGeographytModal = true;
    }

    //Find Index of Li from Li List
    getIndex(array, element) {
        for (let i = 0; i < array.length; i++) {
            if (array[i] == element) return i;
        }
        return -1;
    }

    RowClick(event, currenttr) {
        if (event.ctrlKey || event.metaKey) {
            this.toggleRow(currenttr);
        }

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey && !event.metaKey) {
                this.clearAll();
                this.toggleRow(currenttr);
            }

        if (event.shiftKey) {
            this.setSelectedRow(null, currenttr);
            this.selectRowsBetweenIndexes([this.getIndex(this.allRows, this.lastSelectedRow), this.getIndex(this.allRows, currenttr) ]);
            }
        }
        this.setSelection();
    }

    setSelectedRow(row, currenttr) {
        if (row != null)
            this.lastSelectedRow = row;
        else {
            if (this.multiFilterListCountry) {
                let withActive = [];
                if(this.lis.children) {
                    for (let i = 0; i < this.lis.children.length; i++) {
                        if (StringUtil.hasClass(this.lis[i], 'active'))
                            withActive.push(i);
                    }
                }
                else
                {
                    for (let i = 0; i < this.lis.length; i++) {
                        if (StringUtil.hasClass(this.lis[i], 'active'))
                            withActive.push(i);
                    }
                }
                let indexCurrenttr = this.getIndex(this.allRows, currenttr);
                if((withActive[0] < indexCurrenttr) || indexCurrenttr > withActive.length - 1 )
                {
                    this.lastSelectedRow = this.lis[withActive[0]];
                }
                else if (indexCurrenttr < withActive.length - 1) {
                    this.lastSelectedRow =  this.lis[withActive[withActive.length - 1]];
                }
                else {
                    this.lastSelectedRow =  this.lis[withActive[0]];
                }
            }
        }
    }

    setSelection() {
        this.lis[0].classList.remove('active');
        let selection = [];
        let filterNames = [];
        for (let i = 0; i < this.lis.length; i++) {
            if (this.lis[i].id == 'RegionLabel') {
                this.lis[i].classList.remove('active');
            }
            if (StringUtil.hasClass(this.lis[i], 'active') && this.lis[i].id != 'RegionLabel') {
                selection.push(this.lis[i].getAttribute('data-nodefilterid'));
                filterNames.push(this.lis[i].getAttribute('data-nodefiltername'));
            }
        }
        let filterName = '';

        let filterNameCount = (filterNames.length > 0) ? filterNames.length - 1 : 0;
        //parentName = this.multiFilterListCountry.getAttribute('label');
        filterName = selection.length > 1 && filterNames[0] != 'All' ?
        LocalizationStore.getTranslatedData("Country_"+filterNames[0].replace(/ /ig, ""),filterNames[0]) + "[+" + filterNameCount + ` ${LocalizationStore.getTranslatedData("alert_Geography_more","more")}]`
        : filterNames[0] !== 'All' && selection.length >= 1 ?
        LocalizationStore.getTranslatedData("Country_"+filterNames[0].replace(/ /ig, ""),filterNames[0])
        : LocalizationStore.getTranslatedData("FilterBrowser_Country_-1",filterNames[0]);
        let nodeFilterId = '';
        if (selection.length == 0) {
            this.lis[0].classList.add('active');
            nodeFilterId = -1;
        }
        _.each(selection, (selectItem, i) => {
            if (selectItem != -1) {
                if (i == 0 || nodeFilterId == '') {
                    nodeFilterId += selectItem;
                }
                else {
                    nodeFilterId += ',' + selectItem;
                }
            }
            else if (selectItem == -1 && selection.length == 1) {
                this.lis[0].classList.add('active');
                nodeFilterId = -1;
            }
            else {
                return;
            }
        });
        AlertActions.onclickFilterSet(nodeFilterId.toString(), filterName);
    }

    toggleRow(row) {
        row.classList.toggle('active');
        if (row.classList.contains('active'))
        this.lastSelectedRow = row;
    else
        this.lastSelectedRow = undefined;
    }

    selectRowsBetweenIndexes(indexes) {
        indexes.sort((a, b) => a - b);
        this.clearAll();
        for (let i = indexes[0]; i <= indexes[1]; i++) {
            this.lis[i].classList.add('active');
        }
    }

    clearAll() {
        for (let i = 0; i < this.lis.length; i++) {
            if (this.lis[i].classList.contains('active')) {
                this.lis[i].classList.remove('active');
            }
        }
    }

    createCountryItemsBox(Countrys) {

        return _.map(Countrys, this.createCountryBoxItem, this);
    }

    createCountryBoxItem(CountrysboxItem, key) {

        let self = this;
        return <CountryBox source={CountrysboxItem} region={key} key={key} RowClick={self.RowClick} existAlerts={this.props.existAlerts}/>;
    }

    /* ---- Update Country Region ----------- */

    setCountryRegion(countryRegionData) {

        let sortedCountries = _.sortBy(countryRegionData, 'regionName');
        let countries = _.groupBy(sortedCountries, 'regionName');

        _.each(countries, (c) => {
            let count = 0;
            for (let i = 0; i < c.length; i++) {
                count += c[i].count;
            }
            c["count"] = count;
        });
        return countries;
    }

    render() {
        let countries = this.setCountryRegion(this.countryRegion);

        return (
            <div id="geographyAlertDialog">
                <Modal className="modal-popup geography-alert-dialog alert-geography" show={true} style={{ left: this.props.left, top: this.props.top }}>
                    <Modal.Header>
                        <Modal.Title onMouseDown={this.handleMouseDownGeographyModal} onMouseUp={this.handleMouseUpGeographyModal}>
                            <span className="cap-header">{LocalizationStore.getTranslatedData("alert_Geography_title","Select Countries")}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body onMouseDown={this.handleMouseDownGeographyModal} onMouseUp={this.handleMouseUpGeographyModal}>
                        <div className="modal-area column-geo-alert custom-scroll-light">
                            <div className="custom-scroll">
                                <div id="gergraph-block">
                                    <ul className="geograph-main-block-ul" ref={(ul) => this.multiFilterListCountry = ul}>
                                        <li className="geograph-main-block-li" >
                                            {this.createCountryItemsBox(countries)}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId="gergraph-block" vScroll={true} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

class CountryBox extends React.Component {

    constructor(props) {
        super(props);
    }

    createItem(item, i) {

        return <CountryListItem item={item} key={i} RowClick={this.props.RowClick} existAlerts={this.props.existAlerts}/>;
    }

    createItems(items) {

        return items.map(this.createItem, this);
    }

    handleFilterListClick(e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        let nodeFilterId = ReactDOM.findDOMNode(target).parentNode;
        let node = nodeFilterId.childNodes;
        let filterName = '';
        let list_items = ReactDOM.findDOMNode(target.parentNode.parentNode).childNodes;
        let activeCount = 0;
        let nodeFilterIds = '';
        if (!(e.ctrlKey || e.metaKey)) {
            for (let i = 0; i < list_items.length; i++) {
                let child = list_items[i].childNodes;
                for (let j = 0; j < child.length; j++)
                    child[j].className = child[j].className.replace(/\bactive\b/g, '');
            }
        }
        else {
            for (let i = 0; i < list_items.length; i++) {
                let child = list_items[i].childNodes;
                for (let j = 0; j < child.length; j++)
                   if(child[j].classList.contains('active'))
                   {
                        if (nodeFilterIds === '') {
                            nodeFilterIds = child[j].getAttribute("data-nodefilterid");
                        }
                        else {
                            nodeFilterIds += ',' + child[j].getAttribute("data-nodefilterid");
                        }
                     activeCount++;
                   }
            }
        }

        let nodefilterName = node[1].getAttribute("data-nodefiltername") ? node[1].getAttribute("data-nodefiltername").toString() : '';
        let moreNumbers = (node && node.length >= 3) ? (node.length - 2).toString() : '';
        let count = parseInt(moreNumbers) + parseInt(activeCount);
        if (node.length < 3) {
            filterName = node[1].getAttribute("data-nodefiltername");
        }
        else {
            filterName = nodefilterName + " [+" + count + " more]";
        }

        for (let i = 1; i < node.length; i++) {
            ReactDOM.findDOMNode(node[i]).setAttribute('class', ' active');
            if (nodeFilterIds === '') {
                nodeFilterIds = node[i].getAttribute("data-nodefilterid");
            }
            else {
                nodeFilterIds += ',' + node[i].getAttribute("data-nodefilterid");
            }
        }
        AlertActions.onclickFilterSet(nodeFilterIds.toString(), filterName);
    }

    render() {
        return (
            <ul className="geograph-inner-block-ul" >
                {this.props.region !== 'All' ?
                    <li className="geograph-inner-block-li" onClick={this.handleFilterListClick} id="RegionLabel">
                        <span className="filter-value geograph-inner-region">{LocalizationStore.getTranslatedData("Region_" + this.props.region.replace(/ /ig, ""),this.props.region)}</span>
                    </li> : null}
                {this.createItems(this.props.source)}
            </ul>
        );
    }
}

class CountryListItem extends React.Component {

    constructor(props) {
        super(props);
        this.handleFilterListClick = this.handleFilterListClick.bind(this);
        this.inlineLiPadding = {};
    }

    handleFilterListClick(e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        this.props.RowClick(e, target, false);
    }

    render() {
        let activeClass = "";
        if(!this.props.existAlerts){
            this.props.item.itemName == 'All' ? activeClass = 'active' : ''
        }
        else
            activeClass = (this.props.item.isSelected) ? 'active' : '';

        let countryList = (this.props.item.itemName != 'All') ? this.inlineLiPadding : null;
        return (
            <li className={" " + activeClass}
                style={countryList}
                data-nodefilterid={this.props.item.itemId} data-nodefiltername={this.props.item.itemName}
                onClick={this.handleFilterListClick}>
                <span className="filter-value geograph-inner-region">{this.props.item.itemName == "All" ? LocalizationStore.getTranslatedData("FilterBrowser_Country_-1",this.props.item.itemName):LocalizationStore.getTranslatedData("Country_" + this.props.item.itemName.replace(/ /ig,""),this.props.item.itemName)}</span>
            </li>
        );
    }
}

