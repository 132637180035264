import React from "react";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import StringUtil from "Utils/StringUtil.js";
import moment from "moment";
import ExtremeDataValue from "ExtremeDataValue";

export default class TabHeaderOwnership extends React.Component {
    constructor(props) {
        super(props);
    }

    AbbreviateSharesValueForHeader(shares) {
        if (shares == null || shares == 0) {
            return "0";
        }
        let mShares = (shares / 1000000).toFixed(1);
        if (mShares < 0.1) // 0.0 ~ 94.4 (K)
        {
            return (shares / 1000).toFixed(1); // Does not show the "K" 
        }
        else if (mShares < 1000) // 0.1M ~ 999.4 M
        {
            return mShares + "M";
        }
        else if (mShares < 1000000) // 1.0 Billion ~ 
        {
            return (mShares / 1000).toFixed(1) + "B";
        }
        return (shares / 1000);
    }
    
    render() {
        
        const symbolInfo = DatagraphStore.getSymbolInfo();
        const reportHeader = this.props.data.reportHeader;
        let isHeaderLoaded = (reportHeader != null &&  (reportHeader.symbolInfo == undefined || reportHeader.symbolInfo == symbolInfo.Symbol)) ? true : false;
        if (isHeaderLoaded){
            reportHeader.symbolInfo = symbolInfo.Symbol;
        }

        return (
            <div className="flexbox-item header">
                {isHeaderLoaded ? <div className="digit-report-display digit-ownership">
                    <div className="digitboard">
                        <div className="digitblock"><div className={reportHeader.ownerTitle.length > 11 ? "digitbox extra-text" : "digitbox"}><span className="digiboard-row-title">{reportHeader.ownerTitle}/</span>{(reportHeader.ownerTitle === 'Owners' ? " †" : "")  + "Shares"}</div></div>
                        <div className="digitblock active">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{ExtremeDataValue.numberWithCommas(reportHeader.ownersQ1) + "/" + this.AbbreviateSharesValueForHeader(reportHeader.sharesQ1)}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox"> {moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate2)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{ExtremeDataValue.numberWithCommas(reportHeader.ownersQ2) + "/" + this.AbbreviateSharesValueForHeader(reportHeader.sharesQ2)}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate3)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{ExtremeDataValue.numberWithCommas(reportHeader.ownersQ3) + "/" + this.AbbreviateSharesValueForHeader(reportHeader.sharesQ3)}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate4)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{ExtremeDataValue.numberWithCommas(reportHeader.ownersQ4) + "/" + this.AbbreviateSharesValueForHeader(reportHeader.sharesQ4)}</div>
                        </div>
                    </div>
                    <div className="digitboard">
                        <div className="digitblock"><div className="digitbox">Report Date Distribution</div></div>
                        <div className="digitblock active">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{reportHeader.quarterEndDate1Pct.toFixed(1) + "%"}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate2)).format("MMM, YYYY") + "*"}</div>
                            <div className="digitbox">{reportHeader.quarterEndDate2Pct.toFixed(1) + "%"}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox">OLDER**</div>
                            <div className="digitbox">{reportHeader.quarterEndDate3Pct.toFixed(1) + "%"}</div>
                        </div>
                    </div>
                </div> : null}
                <div className="contain-title">
                    <div className="ellipsed">
                        <div className="main-heading" onMouseMove={(e) => this.props.onTitleMouseMove(e, symbolInfo.CompanyName + " (" + symbolInfo.Symbol + ")", 20, true)} onMouseOut={() => this.props.onMessageMouseLeave()} onBlur={() => this.props.onMessageMouseLeave()}>{symbolInfo.CompanyName + " (" + symbolInfo.Symbol + ")"}</div>
                        {isHeaderLoaded ? <div className="sub-heading" onMouseMove={(e) => this.props.onTitleMouseMove(e, ExtremeDataValue.getFormattedNumber(reportHeader.numberOfOwners) + " " + reportHeader.ownerTitle + (reportHeader.ownerTitle === 'Owners' ? " † " : "") + " Owned " + ExtremeDataValue.getFormattedNumber( reportHeader.totalShares) + " (" + reportHeader.percentOwned + "%)  as of " + moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MM/DD/YYYY"), 14, false)} onMouseOut={() => this.props.onMessageMouseLeave()} onBlur={() => this.props.onMessageMouseLeave()}>
                                                                                                                                  {ExtremeDataValue.getFormattedNumber(reportHeader.numberOfOwners) + " " + reportHeader.ownerTitle + (reportHeader.ownerTitle === 'Owners' ? " † " : "") + " Owned " + ExtremeDataValue.getFormattedNumber( reportHeader.totalShares) + " (" + reportHeader.percentOwned + "%)  as of " + moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MM/DD/YYYY")}</div>: null}
                        {isHeaderLoaded ? <div className="additional-heading" onMouseMove={(e) => this.props.onTitleMouseMove(e, reportHeader.ownerTitle == "Owners" ? "†Funds, Banks and Insurance Cos" : "", 12, false)} onMouseOut={() => this.props.onMessageMouseLeave()} onBlur={() => this.props.onMessageMouseLeave()}>{reportHeader.ownerTitle === 'Owners' ? "†Funds, Banks and Insurance Cos" : ""}</div>: null}
                    </div>
                </div>
            </div>
        )    
    }
}