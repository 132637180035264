import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import LiquidityRatingsFilter from '../../ToggleMenuPanel/Containers/LiquidityRatingsFilter';
import { toggleLiqFilter } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType, { CompareMenuType } from "CompareTabType";

class LiqAndRatingFilterDialog extends PureComponent {

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    handleKeyPress = (e) => {
        if (e.keyCode === 9) {
            e.preventDefault();
        }
    }

    closeLiqFilterWindow = () => {
        this.props.toggleLiqFilter(false);
    }

    render() {

        const { openLiquidityRatings } = this.props;

        return (
            <div className="compare-Liquidity-ratings">
                {openLiquidityRatings && <ErrorBoundary><LiquidityRatingsFilter closeLiqFilterWindow={this.closeLiqFilterWindow} openLiquidityRatingsFilterSetting={openLiquidityRatings} /></ErrorBoundary>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const selectedTab = state.navCompareReducer.selectedTab;
    const liqFilterData = state.comparisonAndRotationMenuPanel.LiqAndRatingFilterReducer;

    if (selectedTab === CompareTabType.ComparisonGraph) {
        const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.ComparisonMenuTab;
        const { openLiquidityRatings } = selectedMenuTab == CompareMenuType.Symbols ? liqFilterData.ComparisonMenuStates.Symbols : liqFilterData.ComparisonMenuStates.Groups;

        return { openLiquidityRatings };
    }
    else {
        const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
        const { openLiquidityRatings } = selectedMenuTab == CompareMenuType.Symbols ? liqFilterData.RotationMenuStates.Symbols : liqFilterData.RotationMenuStates.Groups;

        return { openLiquidityRatings };
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLiqFilter: (isOpen) => dispatch(toggleLiqFilter(isOpen))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(LiqAndRatingFilterDialog);
