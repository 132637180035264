import { Carousel } from "react-bootstrap";
import { PrintMode } from '../../../../../../print/printmode';
import SettingsStore from "SettingsStore";
import TimeTrackingWindow from 'TimeTrackingWindow';
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

function ImageSliderControl() {
    const data = useSelector((state) => state.summaryReducer.currencyImages)
    const printSettings = SettingsStore.getConsoleSettings().printSettings;
    const [state, setState] = useState({
        index: PrintMode.printing ? printSettings.CurrencySummarySelectedCurrencyIndex : 0,
        direction: null
    })
    const height = 190;
    const width = 315;

    useEffect(() => {
        TimeTrackingWindow.setIsSummaryBlockImageLoading();
    }, [])

    const handleSelect = (selectedIndex, e) => {
        printSettings.CurrencySummarySelectedCurrencyIndex = selectedIndex;
        SettingsStore.saveSettings();
        setState({
            index: selectedIndex,
            direction: e.direction
        });
    }
    const isImageLoaded = () => {
        TimeTrackingWindow.endSummaryBlockImageLoadTracker();
    }

    return (
        <div className="sb-carousel" style={{ height: height, width: width }}>
            <Carousel activeIndex={state.index} direction={state.direction} onSelect={handleSelect}
                style={{ width: width - 15, height: height }}>
                {data.map((item, index) =>
                (
                    <Carousel.Item key={index}>
                        <img alt="" width={width - 15} height={height - 65}
                            src={item} onLoad={() => { isImageLoaded() }} />
                    </Carousel.Item>
                )
                )}
            </Carousel>
        </div>
    );

}

export default ImageSliderControl