import PropTypes from 'prop-types';
import React from "react";
import ChartVisual from "./ChartVisual.jsx";
import ThemeHelper from "ThemeHelper";

export default class LineQtrsVisual extends ChartVisual {
    constructor(props) {
        super(props);
        this.state = {
            source: props.DataSource,
            x: 0,
            y: 0,
            dragging: false,
            draggable: props.Draggable,
            shouldUpdate: true,
            lineColor: props.LineColor,
            lineThickness: props.LineThickness,
            opacity: props.Opacity,
            fill: props.Fill ? props.Fill : "none"
        };
    }
    getPath(pathData, lineStyle, dashValue = 0, fill = "none", needChangeLineThickness = false) {
        return (<g>
            <path d={pathData} data-disable-track-price="true"
                ref={(ref) => this.line = ref}
                className={needChangeLineThickness ? this.getDraggingState() : undefined}
                stroke={ThemeHelper.getThemedBrush(this.state.lineColor)}
                strokeDasharray={dashValue}
                strokeWidth={needChangeLineThickness ? (this.state.dragging ? undefined : this.state.lineThickness) : undefined}
                fill={fill}
                cursor="pointer"
                pointerEvents="none"
            />
            <path d={pathData} data-disable-track-price="true"
                id={this.state.type}
                strokeWidth="5px"
                stroke="transparent"
                onMouseDown={this.handleMouseDown.bind(this)}
                onMouseOver={this.handleMouseOver.bind(this)}
                onFocus={this.handleMouseOver.bind(this)}
                onMouseLeave={this.handleMouseLeave.bind(this)}
                fill="none"
                cursor="pointer"
                pointerEvents="visible">
            </path>
        </g>);
    }
    rectPath(x1, y1, x2, y2) {
        return "M " + x1 + ',' + y1 + ' ' + x2 + ',' + y1 + ' ' + x2 + ', ' + y2 + ' ' + x1 + ',' + y2 + ' ';
    }
    circlePath(cx, cy, r) {
        return "M " + cx + ' ' + cy + ' m -' + r + ', 0 a ' + r + ',' + r + ' 0 1,0 ' + (r * 2) + ',0 a ' + r + ',' + r + ' 0 1,0 -' + (r * 2) + ',0 ';
    }
    PrepareBoxes2(source) {
        const prcLength = source.length;
        let d = "";
        let j = 0;
        for (; j < prcLength; j++) {
            if (source[j] && !isNaN(source[j].yPrice)) {
                d += this.rectPath(source[j].xAxis - 2.5, (source[j].yPrice - 2.5).toFixed(0), source[j].xAxis + 2.5, (source[j].yPrice + 2.5).toFixed(0));
            }
        }
        if (source[j - 1] && !isNaN(source[j - 1].yPrice)) {
            d += this.rectPath(source[j - 1].xAxis - 2.5, (source[j - 1].yPrice - 2.5).toFixed(0), source[j - 1].xAxis + 2.5, (source[j - 1].yPrice + 2.5).toFixed(0));
        }
        return d;
    }
    PrepareElipses2(source) {
        const prcLength = source.length;
        let d = "";
        let j = 0;
        for (; j < prcLength; j++) {
            if (source[j] && !isNaN(source[j].yPrice) && source[j].yValue > 0) {
                d += this.circlePath(source[j].xAxis, source[j].yPrice, 2.5);
            }
        }
        if (source[j - 1] && !isNaN(source[j - 1].yPrice) && source[j - 1].yValue > 0) {
            d += this.circlePath(source[j - 1].xAxis, source[j - 1].yPrice, 2.5);
        }
        return d;
    }
    prepareLine(source) {
        if (!this.mainCanvas) {
            return;
        }
        const rect = this.mainCanvas.getBoundingClientRect();
        const ctx = this.mainCanvas.getContext("2d");
        ctx.canvas.height = rect.height;
        ctx.canvas.width = rect.width;
        ctx.clearRect(0, 0, rect.width, rect.height);
        if (!source || source.length < 1) {
            return;
        }
        ctx.strokeStyle = this.state.lineColor;
        ctx.fillStyle = this.state.lineColor;
        if (this.state.lineThickness === 1) {
            ctx.translate(0.5, 0.5);
        }
        const prcLength = source.length;
        for (let j = 0; j < prcLength; j++) {
            const linePoint = source[j];
            if (linePoint) {
                ctx.moveTo(linePoint.xAxis, linePoint.yPrice);
                ctx.arc(linePoint.xAxis, linePoint.yPrice, 2.5, 0, 2 * Math.PI);
            }
        }
        ctx.fill();
        ctx.stroke();
        ctx.closePath();
    }

    UNSAFE_componentWillReceiveProps(nextprops) {
        this.state.source = nextprops.DataSource;
        this.state.lineColor = nextprops.LineColor;
        this.state.lineThickness = nextprops.LineThickness;

        if (nextprops.Draggable) {
            this.state.draggable = nextprops.Draggable;
        }
        if (nextprops.Opacity) {
            this.state.opacity = nextprops.Opacity;
        }
        this.setState(this.state);
    }
    onContextMenuClick(e) {
        e.preventDefault();
        // alert('aaaa');
        return false;
    }

    render() {
        if (!this.state.source || this.state.source.length <= 0)
            return (<div></div>);
        const lineStyle = {
            fitPosition: "fill",
            position: "absolute",
            left: "0px",
            top: "0px",
            zIndex: this.state.zIndex,
            pointerEvents: "none",
            opacity: this.state.opacity
        };
        let points;
        if (this.state.boxNodes)
            points = this.PrepareBoxes2(this.state.source);
        else
            points = this.PrepareElipses2(this.state.source);

        return (
            <div>
                <svg className="svg line-qtrs-visual" style={lineStyle} id="RSLayer" ref={(ref) => this.main = ref}
                    height="100%"
                    width="100%">
                    {this.getPath(points, lineStyle, 0, ThemeHelper.getThemedBrush(this.state.lineColor))}
                </svg>
            </div>);
    }
}

LineQtrsVisual.propTypes = {
    LineColor: PropTypes.string.isRequired,
    LineThickness: PropTypes.number.isRequired,
    Draggable: PropTypes.bool.isRequired

};
