import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetAllocationDataRequest extends BaseServiceRequest {
    constructor(fundticker) { //isEntitled = false
        super();
        super.addRequestUri("allocationdata.pb");
        super.addRequestParameter("fundticker", fundticker);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "AllocationDataResponse";
    }
}

export default GetAllocationDataRequest;
