import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from 'BaseServiceApi';

class UpdateGlobalFlagRequest extends BaseServiceRequest {

    constructor(msids, snapshotdates, isDelete, cacheId, dbType) {
        super();
        super.addRequestUri("flag2.pb");
        super.addRequestParameter("db", dbType.toString());
        // super.addRequestParameter("msids", msids.toString());
        // super.addRequestParameter("snapshotdates", "");
        // super.addRequestParameter("isDelete", isDelete);
        // super.addRequestParameter("cacheId", parseInt(cacheId));
        // super.addRequestParameter("userDateformat", "M/d/yyyy"); 
        /* fixes PANWEB-2070 */
        const ReqProto = BaseServiceApi.rayData["UpdateGlobalFlagRequestData"];
        let updateGlobalFlagRequestData = new ReqProto();
        updateGlobalFlagRequestData.msids = msids !== null && msids !== undefined ? msids.toString() : "";
        updateGlobalFlagRequestData.snapshotdates = snapshotdates ? snapshotdates : "";
        updateGlobalFlagRequestData.isDelete = isDelete;
        updateGlobalFlagRequestData.cacheId = parseInt(cacheId);
        updateGlobalFlagRequestData.userDateformat = "M/d/yyyy";
        const reqcontent = updateGlobalFlagRequestData.encode();
        super.setContent(reqcontent);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}

export default UpdateGlobalFlagRequest;