import cx from 'classnames'
import PriceMenuHelpers from '../../../../../../Sagas/NavDataGraph/PriceMenu/PriceMenuHelper';
import PropTypes from 'prop-types'
import ResizeObserver from 'resize-observer-polyfill'
import ThemeHelper from "ThemeHelper";
import React, { Component } from 'react'


/**
 * Capitalize first letter of string
 * @private
 * @param  {string} - String
 * @return {string} - String with first letter capitalized
 */
function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substr(1)
}

/**
 * Clamp position between a range
 * @param  {number} - Value to be clamped
 * @param  {number} - Minimum value in range
 * @param  {number} - Maximum value in range
 * @return {number} - Clamped value
 */
function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max)
}

/**
 * Predefined constants
 * @type {Object}
 */
const constants = {
    orientation: {
        horizontal: {
            dimension: 'width',
            direction: 'left',
            reverseDirection: 'right',
            coordinate: 'x'
        },
        vertical: {
            dimension: 'height',
            direction: 'top',
            reverseDirection: 'bottom',
            coordinate: 'y'
        }
    }
}
export default class AnimationSlider extends Component {
    static propTypes = {
        min: PropTypes.number,
        max: PropTypes.number,
        step: PropTypes.number,
        value: PropTypes.number,
        orientation: PropTypes.string,
        reverse: PropTypes.bool,
        labels: PropTypes.object,
        handleLabel: PropTypes.string,
        format: PropTypes.func,
        onChangeStart: PropTypes.func,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onChangeComplete: PropTypes.func
    };

    static defaultProps = {
        min: 0,
        max: 100,
        step: 1,
        value: 0,
        orientation: 'horizontal',
        reverse: false,
        labels: {},
        handleLabel: ''
    };

    constructor(props, context) {
        super(props, context)

        this.state = {
            active: false,
            limit: 0,
            grab: 0,
            hover: false
        }
    }

    componentDidMount() {
        this.handleUpdate()
        this._isUnmount = false;
        const resizeObserver = new ResizeObserver(this.handleUpdate)
        resizeObserver.observe(this.slider)
    }
    componentWillUnmount(){
        this._isUnmount = true;
        document.removeEventListener('mousemove', this.handleDrag)
        document.removeEventListener('mouseup', this.handleEnd)
    }

    /**
     * Format label/tooltip value
     * @param  {Number} - value
     * @return {Formatted Number}
     */
    handleFormat = (value) => {
        const { format } = this.props
        return format ? format(value) : value
    };

    /**
     * Update slider state on change
     * @return {void}
     */
    handleUpdate = () => {
        if (!this.slider) {
            // for shallow rendering
            return
        }
        const { orientation } = this.props
        const dimension = capitalize(constants.orientation[orientation].dimension)
        const sliderPos = this.slider[`offset${dimension}`]
        const handlePos = this.handle[`offset${dimension}`]

        this.setState({
            limit: sliderPos - handlePos,
            grab: handlePos / 2
        })
    };

    /**
     * Attach event listeners to mousemove/mouseup events
     * @return {void}
     */
    handleStart = (e) => {
        e.preventDefault()
        e.stopPropagation()
        
        const { onChangeStart, value } = this.props
        document.addEventListener('mousemove', this.handleDrag)
        document.addEventListener('mouseup', this.handleEnd)
        this.startX = e.pageX
        this.startIndex = value
        this.dragging = false
        
        if(this.props.snapshots && this.props.snapshots.length > 0){
            this.setState(
                {
                    active: true,
                    hover: false
                },
                () => {
                    onChangeStart && onChangeStart(e)
                }
            )
        }
        else{
            this.setState(
                {
                    active: false,
                    hover: false
                },
                () => {
                    onChangeStart && onChangeStart(e)
                }
            )
        }
    };

    /**
     * Handle drag/mousemove event
     * @param  {Object} e - Event object
     * @return {void}
     */
    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const { onChange } = this.props
        const { target: { className, classList, dataset } } = e
        if (this.startX !== null) {
            if (Math.abs(e.pageX - this.startX) > 4) {
                this.dragging = true;
            }
        }

        if (!this.dragging) {
            if (!onChange || className === 'animationslider__labels' || classList.contains('animationslider__handle-label') || classList.contains('handle-label-date')){ 
                return
            }
        }

        let nvalue = this.position(e)

        if (
            classList &&
            classList.contains('animationslider__label-item') &&
            dataset.value
        ) {
            nvalue = parseFloat(dataset.value)
        }

        onChange && onChange(nvalue, e)
    };

    /**
     * Detach event listeners to mousemove/mouseup events
     * @return {void}
     */
    handleEnd = (e) => {
        const { onChangeComplete, max, rightWidth } = this.props
        const moveWidth = (max - this.startIndex) * 4;  
        const dockWidth = rightWidth < 100 ? rightWidth : 100;
        if ((e.pageX - this.startX) > moveWidth + dockWidth) {
            this.props.dockedEpsScrubber();
        }
        this.startIndex = null;
        this.startX = null;
        const self = this;
        if(!this._isUnmount){
            this.setState({ active: false })
        }
        onChangeComplete && onChangeComplete(e);
        window.setTimeout(() => {
            self.dragging = false;
        }, 500);
        document.removeEventListener('mousemove', this.handleDrag)
        document.removeEventListener('mouseup', this.handleEnd)

    };

    handleOver = () => {
        this.setState({
            hover: true
        });

    }

    handleOut = () => {
        this.setState({
            hover: false
        });

    }

    /**
     * Calculate position of slider based on its value
     * @param  {number} value - Current value of slider
     * @return {position} pos - Calculated position of slider based on value
     */
    getPositionFromValue = (value) => {
        const { limit } = this.state
        const { min, max } = this.props
        const diffMaxMin = max - min
        const diffValMin = value - min
        const percentage = diffValMin / diffMaxMin
        const pos = Math.round(percentage * limit)

        return pos
    };

    /**
     * Translate position of slider to slider value
     * @param  {number} pos - Current position/coordinates of slider
     * @return {number} value - Slider value
     */
    getValueFromPosition = (pos) => {
        const { limit } = this.state
        const { orientation, min, max, step } = this.props
        const percentage = clamp(pos, 0, limit) / (limit || 1)
        const baseVal = step * Math.round(percentage * (max - min) / step)
        const value = orientation === 'horizontal' ? baseVal + min : max - baseVal

        return clamp(value, min, max)
    };

    /**
     * Calculate position of slider based on value
     * @param  {Object} e - Event object
     * @return {number} value - Slider value
     */
    position = (e) => {
       
        const { orientation, reverse } = this.props

        const node = this.slider
        const coordinateStyle = constants.orientation[orientation].coordinate
        const directionStyle = reverse
            ? constants.orientation[orientation].reverseDirection
            : constants.orientation[orientation].direction
        const clientCoordinateStyle = `client${capitalize(coordinateStyle)}`
        const coordinate = !e.touches
            ? e[clientCoordinateStyle]
            : e.touches[0][clientCoordinateStyle]
        const direction = node ? node.getBoundingClientRect()[directionStyle] : 0;
        const pos = reverse
            ? direction - coordinate
            : coordinate - direction
        const value = this.getValueFromPosition(pos)

        return value
    };

    /**
     * Grab coordinates of slider
     * @param  {Object} pos - Position object
     * @return {Object} - Slider fill/handle coordinates
     */
    coordinates = (pos) => {
        const { limit } = this.state
        const { orientation } = this.props
        const value = this.getValueFromPosition(pos)
        const position = this.getPositionFromValue(value)
        const handlePos = orientation === 'horizontal' ? position : position
        const fillPos = orientation === 'horizontal'
            ? handlePos
            : limit - handlePos
        
        return {
            fill: fillPos,
            handle: handlePos,
            label: handlePos
        }
    };
    getCrossHairs() {
        return `M 0 ${38-this.props.animationSize.aboveHeight} L 0 0 M 0 46 L 0 ${this.props.animationSize.belowHeight > 0 ? this.props.animationSize.belowHeight + 38 : 46}`;
    }

    renderLabels = (labels) => (
        <ul
            ref={(sl) => { this.labels = sl }}
            className={cx('animationslider__labels')}>
            {labels}
        </ul>
    );

    handleClick = (e) => {
        const { onClick } = this.props;
        if (!this.dragging){ 
            onClick(e);
        }
    }

    render() {
        const {
            value,
            orientation,
            className,
            reverse,
            labels,
            min,
            max,
            handleLabel,
            isPlay,
            rightWidth
        } = this.props
        const { active, hover } = this.state
        const dimension = constants.orientation[orientation].dimension
        const direction = reverse
            ? constants.orientation[orientation].reverseDirection
            : constants.orientation[orientation].direction
        const position = this.getPositionFromValue(value)
        const coords = this.coordinates(position)
        const fillStyle = { [dimension]: `${coords.fill}px` }
        const handleStyle = { [direction]: `${coords.handle}px` }
        const crossHairPath = this.getCrossHairs();
        const style = { strokeWidth: "2", shapeRendering: "crispEdges" };
        const labelItems = []
        let labelKeys = Object.keys(labels)
        const shadeRect = { width: PriceMenuHelpers.getWidth(), height: PriceMenuHelpers.getHeight() };
        const shadeWidth = (max - value) * 4 + rightWidth;
        

        if (labelKeys.length > 0) {
            labelKeys = labelKeys.sort((a, b) => (reverse ? a - b : b - a))

            for (const key of labelKeys) {
                const labelPosition = this.getPositionFromValue(key)
                const labelCoords = this.coordinates(labelPosition)
                const labelStyle = { [direction]: `${labelCoords.label}px` }

                labelItems.push(
                    <li
                        key={key}
                        className={cx('animationslider__label-item')}
                        data-value={key}
                        onMouseDown={this.handleDrag}
                        onTouchStart={this.handleStart}
                        onTouchEnd={this.handleEnd}
                        style={labelStyle}
                    >
                        {this.props.labels[key]}
                    </li>
                )
            }
        }

        return (
            <div
                role="slider"
                tabIndex="0"
                ref={(s) => {
                    this.slider = s
                }}
                className={cx(
                    'animationslider',
                    `animationslider-${orientation}`,
                    { 'animationslider-reverse': reverse },
                    className
                )}
                onMouseDown={this.handleDrag}
                onMouseUp={this.handleEnd}
                onTouchStart={this.handleStart}
                onTouchEnd={this.handleEnd}
                aria-valuemin={min}
                aria-valuemax={max}
                aria-valuenow={value}
                aria-orientation={orientation}
            >
                <div className='animationslider__fill' style={fillStyle} />
                <div ref={(sh) => { this.handle = sh }}
                    className={isPlay ? 'animationslider__handle icn-animationslider__handle active' : active ? 'animationslider__handle icn-animationslider__handle animationslider__active' : hover ? 'animationslider__handle icn-animationslider__handle hover' : 'animationslider__handle icn-animationslider__handle'}
                    style={{[direction]: `${coords.handle}px`, zIndex: '1'}} data-disable-track-price="true">
                    <div className='animationslider__handle-label' style={{ pointerEvents: active && this.dragging ? 'none' : 'all', cursor: active ? 'ew-resize' : 'pointer' }}
                        onMouseDown={this.handleStart}
                        onTouchMove={this.handleDrag}
                        onTouchEnd={this.handleEnd}
                        onMouseEnter={!isPlay && !active ? this.handleOver : null}
                        onMouseLeave={!isPlay && !active ? this.handleOut : null}
                        onClick={this.handleClick}>
                        <span className="handle-label-date xx-small-bold">{handleLabel}</span>
                    </div>
                </div>
                <div className={'animationslider__handle'}
                    style={handleStyle} data-disable-track-price="true">
                    <svg ref={(ref) => (this.svg = ref)} style={{ position: "absolute", width: `${shadeWidth}px`, top: "0px", left: `48.5px`, overflow: "visible" }}>
                        <path d={crossHairPath} strokeDasharray="3,3" style={style} stroke={ThemeHelper.getThemedBrush("fff000")} className="crossHair" />
                        <rect x="1" y={37 - shadeRect.height} width={shadeWidth} height={shadeRect.height} fill= {ThemeHelper.getThemedBrush("000fff")} opacity="0.65" />
                        <polygon points="0,12 -7,12 -7,6 0,0 7,6 7,12" fill="none" style={{ pointerEvents: active && this.dragging ? 'none' : 'all', cursor: active ? 'ew-resize' : 'pointer' }}
                            onMouseDown={this.handleStart}
                            onTouchMove={this.handleDrag}
                            onTouchEnd={this.handleEnd}
                            onMouseEnter={!isPlay && !active ? this.handleOver : null}
                            onMouseLeave={!isPlay && !active ? this.handleOut : null}
                            onClick={this.handleClick} />
                        <polygon points="0,37 -7,37 -7,43 0,49 7,43 7,37" fill="none" style={{ pointerEvents: active && this.dragging ? 'none' : 'all', cursor: active ? 'ew-resize' : 'pointer' }}
                            onMouseDown={this.handleStart}
                            onTouchMove={this.handleDrag}
                            onTouchEnd={this.handleEnd}
                            onMouseEnter={!isPlay && !active ? this.handleOver : null}
                            onMouseLeave={!isPlay && !active ? this.handleOut : null}
                            onClick={this.handleClick} />
                    </svg>
                </div>
            </div>
        )
    }
}
