import Serializable from "Serializable";
class QuoteServiceSettings extends Serializable {
    constructor() {
        super();
        this.SelectQuoteServiceOption = 0;
        this.SelectedQuoteStatusGrid = "";
    }

    onSeralisedInfoRecieved(info) {
        console.log(info.SelectedQuoteStatusGrid);
        this.SelectQuoteServiceOption = typeof info.SelectQuoteServiceOption !== "undefined" ? info.SelectQuoteServiceOption : 0;
        this.SelectedQuoteStatusGrid = typeof info.SelectedQuoteStatusGrid !== "undefined" ? info.SelectedQuoteStatusGrid : "";
    }

    getDataObject() {
        return {
            SelectQuoteServiceOption: this.SelectQuoteServiceOption,
            SelectedQuoteStatusGrid: this.SelectedQuoteStatusGrid
        };
    }

    getObjectMapKey() {
        return "QuoteServiceSettings";
    }
}

export default QuoteServiceSettings;
