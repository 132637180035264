import React, {Component, Fragment} from "react";
import { Modal } from "react-bootstrap";
import LocalizationStore from "LocalizationStore";

export default class DeleteListItem extends Component {
  constructor(props) {
    super(props);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    this.props.deleteListItem(this.props.nodeId, this.props.nodeName, this.props.rootParentId, this.props.parentId, this.props.listType);
    return this.props.closeModal();
  }

  render() {
    return (
      <Fragment>
        <Modal className="modal-popup delete-folder-n-insiders" show={this.props.showModal} onHide={this.props.closeModal}>
          <Modal.Header>
            <Modal.Title className="cap-header">{LocalizationStore.getTranslatedData("deletefolder_tittle", "DELETE FOLDER")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-region">
              <p>{LocalizationStore.getTranslatedData("deletefolder_msg", "Delete Folder and everything in it?")}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box righter">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("set_cancel", "Cancel")}</button>
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.handleDelete}>{LocalizationStore.getTranslatedData("misc_id7", "Yes")}</button>
            </span>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}
