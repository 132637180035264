import React from "react";
import {Modal} from "react-bootstrap";
import LocalizationStore from "LocalizationStore";
import UserInfoUtil from "UserInfoUtil";
import apiWCF from "BaseServiceApiWCF";



export default class AboutCustomFilter extends React.Component {
  constructor(props){
    super(props);
  }

  render() {
    let host = apiWCF.getDomain();
    let pic1 = host+"/PanarayFeatureAbout_China/Image/Quick_Feature_About_img1.png";
    let pic2 = host+"/PanarayFeatureAbout_China/Image/Quick_Feature_About_img3.png";
    return(
      <div>
        <Modal className="modal-popup about-block-list-view" show={this.props.showModal} onHide={this.props.closeModal}>
          <Modal.Header>
            {LocalizationStore.getTranslatedData("ipc_abt","About")}
            <Modal.Title><span className="cap-header">{LocalizationStore.getTranslatedData("FeatureAbout_Name_104","Column-Based Filtering")}</span></Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <div className="modal-region">
            <div className="block-about-filter">
              <div className="row">
                <div className="col-sm-7">
                  <strong>{LocalizationStore.getTranslatedData("AboutCustomFilter_1","Filter")}</strong><br/>
                  {LocalizationStore.getTranslatedData("AboutCustomFilter_2","To filter(i.e. screen) a list or the O'Neil Database, click the cell above a column header, select an operator, enter a value and click Apply.")}
                </div>
                <div className="col-sm-5">
                  {UserInfoUtil.hasChinaEntitlement() ? <img alt="" src={pic1}/>
                   : 
                   <span className="image-normal-filter"></span>
                  // <img alt="" src="/Asset/images/img-about-popup-price.png"/>
                  }
                </div>
              </div>
            </div>
            <hr />
            <div className="block-about-filter">
              <div className="row">
                <div className="col-sm-7">
                  <strong>{LocalizationStore.getTranslatedData("AboutCustomFilter_3","Metric vs. Metric Filtering")}</strong><br/>
                  {LocalizationStore.getTranslatedData("AboutCustomFilter_4","For Metric vs. Metric filtering, click the scales button and select a comparison metric from the Metric Library. Example: Find stocks with improving Relative Strength using: RS Current Week > RS 1 Week Ago.")}
                </div>
                <div className="col-sm-5">
                  {/* <img alt="" className="img-metric-filter" src="/Asset/images/img-about-popup-convert.png"/> */}
                  <span className="image-metric-filter"></span>
                </div>
              </div>
            </div>
            <hr />
            <div className="block-about-filter">
              <div className="row">
                  <div className="col-sm-7">
                    <strong>{LocalizationStore.getTranslatedData("AboutCustomFilter_5","Additional Filters")}</strong><br/>
                    {LocalizationStore.getTranslatedData("AboutCustomFilter_6",'Add additional filters to the same metric by clicking the "Add Row" Link. ')}
                  </div>
                  <div className="col-sm-5">
                    {/* {UserInfoUtil.hasChinaEntitlement() ? <img alt="" className="additional-filter" src={pic2}/> : <img alt="" className="additional-filter" src="/Asset/images/img-about-popup-add.png"/>} */}
                    {UserInfoUtil.hasChinaEntitlement() ? <img alt="" className="additional-filter" src={pic2}/> : <span className="image-additional-filter"></span>}
                  </div>
                </div> 
              </div> 
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </Modal.Body>
        <Modal.Footer>
           <span className="btn-box center"><button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("ipc_cls","CLOSE")}</button></span>
        </Modal.Footer>
      </Modal>
      </div>
    )
  }
}