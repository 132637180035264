import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
class GetFactorEventDataRequest extends BaseServiceRequest {
    constructor(
        symbol,
        //  msId,
        //  osid,
         periodicity,
         enddate
        // startDt, endDt, graphtype, osid, type
        ) {

        super();
        // super.addPathParameter(msId.toString());
        super.addRequestUri("factoreventlist.pb");
        // super.addPathParameter(msId.toString());
        super.addRequestParameter("sym", symbol);
        // super.addRequestParameter("start", startDt);
        // super.addRequestParameter("end", endDt);
        super.addRequestParameter("p", periodicity);
        // super.addRequestParameter("t", type);
        // super.addRequestParameter("osid", osid);
        super.addRequestParameter('enddate', enddate);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "FactorEventDataResponse";
    }
}

export default GetFactorEventDataRequest