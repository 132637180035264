const { ActionTypes } = OwnerShipBlockConstants;
import ConsoleStore from "ConsoleStore";
import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import { OwnerShipBlockConstants } from "../../../Constants/OwnerShipBlock";
import OwnershipBlockSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/OwnershipBlockSettings";
import OwnershipClientType from "../../../Constants/OwnershipClientType.js";
import SettingsStore from "SettingsStore";

const initialState = {
    AumIsVisible: false,
    BlockSize: 0,
    Height: 142,
    OwnershipViewType: 1,
    RowHeight: 20,
    headerMenu: [],
    isDogEarClicked: false,
    isOwnershipOpen: false,
    ownershipBlockData: null,
    ownershipBlockSetting: new OwnershipBlockSettings(),
    HeaderList: [],
    SponsorRanks2: [],
    AUMData: [],
    recentTradeDateUpdated: false,
    ...defaultState
}

const defaultState = {
    /* PANWEB-4506 */
    // "Headers"]: [];
    ownershipRawData: [],
    HeaderList2: [],
    AUMData2: [],
    SponsorRanks3: [],
    // timeLine: [],
    LeftClient: OwnershipClientType.Owner,
    RightClient: OwnershipClientType.Owner,
    dataAvailable: false,
    showDataNotAvailable: false,
    // ListContentId: 0,
    // ListSourceId: 0,
}

const ownershipBlockReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.OWNERSHIP_BLOCK_RESPONSE_READY: {
            return { ...state, ownershipBlockData: action.ownershipBlockData }
        }

        case ActionTypes.OWNERSHIPBLOCK_SET_DATA: {
            state.isOwnershipOpen = action.newState.ownershipBlockSetting.IsVisible;
            return {
                ...state, ...action.newState, AumIsVisible: action.newState.ownershipBlockSetting.AumIsVisible,
                LeftClient: action.newState.ownershipBlockSetting.LeftClient, RightClient: action.newState.ownershipBlockSetting.RightClient,
                OwnershipViewType: action.newState.ownershipBlockSetting.OwnershipViewType
            }
        }

        case ActionTypes.OWNERSHIP_BLOCK_DOG_EAR_TOGGLE: {
            return { ...state, isDogEarClicked: action.isDogEar }
        }

        case ActionTypes.OWNERSHIP_BLOCK_PREPARE_UPDATE_MENU: {
            return { ...state, headerMenu: [...action.menu] }
        }

        case ActionTypes.OWNERSHIP_BLOCK_SET_AUM_TOGGLE: {
            ConsoleStore.trackUsage('OwnershipBlockAumViewChanged', '', ['Manual']);
            return { ...state, ...action.newState }
        }

        case ActionTypes.OWNERSHIP_BLOCK_SET_FUND_TOGGLE: {
            ConsoleStore.trackUsage('OwnershipBlockToggleLeftFund', '', ['Manual']);
            return { ...state, ...action.newState }
        }

        case ActionTypes.OWNERSHIP_BLOCK_OPEN_COLLAPSE: {
            if (action.blockType === "Ownership") {
                state.ownershipBlockSetting.IsVisible = action.isopen;
                SettingsStore.saveSettings();
            }
            return { ...state, isOwnershipOpen: action.isopen }
        }

        case ActionTypes.OWNERSHIP_BLOCK_CLEAR_DATA: {
            return {
                ...state, ...defaultState, ownershipBlockSetting: action.ownershipBlockSetting, isOwnershipOpen: action.ownershipBlockSetting.IsVisible,
                showDataNotAvailable: true
            }
        }

        case ActionTypes.OWNERSHIP_BLOCK_UPDATE_LIST_DATA: {
            return {
                ...state, HeaderList: action.HeaderList, HeaderList2: action.HeaderList2, SponsorRanks2: action.SponsorRanks2,
                SponsorRanks3: action.SponsorRanks3, AUMData: action.AUMData, AUMData2: action.AUMData2, Height: action.Height,
                BlockSize: action.BlockSize
            }
        }

        case ActionTypes.OWNERSHIP_BLOCK_NOT_AVAILABLE: {
            state.ownershipBlockData = null;
            if (action.payload) {
                return { ...state, isOwnershipOpen: false }
            }
            return { ...state }
        }

        case DataGraphConstants.ActionTypes.ON_PLOT_SYMBOL: {
            if (action.isViewChart) {
                return { ...state, isOwnershipOpen: false }
            }
        }

        default: return state
    }
}

export default ownershipBlockReducer