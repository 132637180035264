import React from 'react';

import { UpdateSharedData } from "../../../../Actions/ShareAction";

import { filter, find } from "underscore";
import LocalizationStore from 'LocalizationStore';
const SelectAll = ({ filteredData, sharedData, savedSharedData, icsRowInsharedData }) => {

    const handleSelectAllRecepient = () => (event) => {
        let slectedData = [];
        let slectedRow = undefined;

        filteredData.map((rowData, index) => {
            slectedRow = {
                accessLvl: 1,
                email: rowData.email,
                isICS: false,
                isShared: true,
                organization: rowData.organization,
                recipientId: rowData.recipientId,
                userName: rowData.userName,
                isModified: true,
                isAdded: true
            }
            slectedData.push(slectedRow);
        });
        if (event.target.checked) {
            if (icsRowInsharedData && icsRowInsharedData.length > 0)
                slectedData.push(icsRowInsharedData[0]);

            const filterData = filter(slectedData, sharedDataItem =>
                !find(sharedData, selectDataItem => selectDataItem.recipientId.low === sharedDataItem.recipientId.low));
            
            UpdateSharedData(sharedData.concat(filterData));
        } else {
            const filterData = filter(sharedData, user =>
                !find(slectedData, rowData => rowData.recipientId.low === user.recipientId.low));

            UpdateSharedData(filterData);
        }
    }

    const checkedRows = filter(sharedData, user =>
        !find(savedSharedData, rowData => rowData.recipientId.low === user.recipientId.low));

    const checked = filteredData && checkedRows.length > 0 && checkedRows.filter(checkedRow => find(filteredData, item => item.recipientId.low === checkedRow.recipientId.low)).length === filteredData.length


    return (
        <span className='select-check'>
            <input type='checkbox' id="shareSelectAll" checked={checked} onChange={handleSelectAllRecepient()} />
            <label htmlFor="shareSelectAll">{LocalizationStore.getTranslatedData("ri_id46","Select All")}</label>
        </span>
        // <tr>
        //     <td className='select-info'>
        //         <input type='checkbox' checked={checked} onChange={handleSelectAllRecepient()} />
        //     </td>
        //     <td className='select-Name-info' >
        //         {LocalizationStore.getTranslatedData("ri_id46","Select All")}
        //     </td>
        // </tr>
    )
}


export default SelectAll;
