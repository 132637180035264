import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ScrollBar from "ScrollBar";
import LocalizationStore from "LocalizationStore";
import UserInfoUtil from "UserInfoUtil";
export default class AboutDialog extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div id="AboutAlertDialog">
                <Modal className="modal-popup about-alert alert-dialog about-alert-dialog" show={true}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="cap-header">{LocalizationStore.getTranslatedData("al_alerts","About Alerts")}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className="loading loading-spinner"></div> */}
                        <div className="modal-region">
                            <div className="alert-about-box">
                                <div className="custom-scroll-light">
                                    <div className="custom-scroll">
                                        <div id="aboutDialogSetVerticalScroll">
                                            <div className="alert-about">
                                                <div className="row">
                                                    <div className="col-xs-8">
                                                        <h6>{LocalizationStore.getTranslatedData("ri_id9","Alerts Panel")}</h6>
                                                        <p>{LocalizationStore.getTranslatedData("ri_id10","The Alerts Panel displays active and triggered alerts for the current symbol. Alert types include: Price, Line, Moving Average and Pattern Recognition. To create a Price or Pattern Recognition Alert, right click on a chart. ")}</p>
                                                    </div>
                                                    <div className="col-xs-4">
                                                        {/* <img alt="" className="top-img" src={UserInfoUtil.hasChinaEntitlement() ? "http://iis.services.panaray.com/PanarayFeatureAbout_China/Image/Quick_Feature_About_img4.png" : "/Asset/images/Quick_Feature_About_img4[1].png"} /> */}
                                                        {UserInfoUtil.hasChinaEntitlement() ? <img alt="" className="top-img" src="http://iis.services.panaray.com/PanarayFeatureAbout_China/Image/Quick_Feature_About_img4.png" /> : <span className="image-alert-panel"></span>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-8">
                                                        <h6>{LocalizationStore.getTranslatedData("ri_id11","Line Pen")}</h6>
                                                        <p>{LocalizationStore.getTranslatedData("ri_id12",'For Line Pen alerts select the Alert checkbox in the Annotations panel. For Moving Averages click the moving average and select Create MA Alert. Once created or triggered, the alert will appear in this panel under the "Active" category.')}</p>
                                                    </div>
                                                    <div className="col-xs-4">
                                                        {/* <img  alt="" className="bottom-img" src={UserInfoUtil.hasChinaEntitlement() ? "http://iis.services.panaray.com/PanarayFeatureAbout_China/Image/Quick_Feature_About_img5.png":"/Asset/images/Quick_Feature_About_img5[1].png"}/> */}
                                                        {UserInfoUtil.hasChinaEntitlement() ? <img  alt="" className="bottom-img" src="http://iis.services.panaray.com/PanarayFeatureAbout_China/Image/Quick_Feature_About_img5.png"/> : <span className="image-alert-line-pen"></span>}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-8">
                                                        <h6>{LocalizationStore.getTranslatedData("ri_id13","Triggered Alerts")}</h6>
                                                        <p>{LocalizationStore.getTranslatedData("ri_id14","To view all triggered alerts for all symbols, click the megaphone on the bottom bar.")}</p>
                                                    </div>
                                                    <div className="col-xs-4">
                                                        {/* <img alt="" src="/Asset/images/Quick_Feature_About_img6[1].png" className="top-img1 " /> */}
                                                        <span className="image-alert-triggered"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ScrollBar scrollId="aboutDialogSetVerticalScroll" vScroll={true} />
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.closeDialog}>{LocalizationStore.getTranslatedData("Close","Close")}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}