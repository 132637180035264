import React from "react";

let startX, startY, startWidth, startHeight;

export default class ResizableWindow extends React.Component {
constructor(props) {
    super(props);
    this.doDrag = this.doDrag.bind(this);
    this.stopDrag = this.stopDrag.bind(this);
    this.initDrag = this.initDrag.bind(this);
    this.state = {
      isDisabled: this.props.isDisabled ? this.props.isDisabled : false,
      width: this.props.width,
      height: this.props.height,

			direction: this.props.direction,

			minHeight: this.props.minHeight,
			minWidth: this.props.minWidth,
			maxHeight: this.props.maxHeight,
			maxWidth: this.props.maxWidth,
		};
	}

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      width: nextProps.width,
      height: nextProps.height,
      maxHeight: nextProps.maxHeight,
      maxWidth: nextProps.maxWidth,
      minHeight: nextProps.minHeight,
      minWidth: nextProps.minWidth
    });
  }
  
  // Resizer Style based on Direction
	getResizeHandlerStyle() {
		let resizeHandlerStyle = {};
    let resizerStyle = {left:0,marginTop:0};
    if(this.props.customResizerStyle) { resizerStyle = this.props.customResizerStyle }
    let customWidth = this.state.width - resizerStyle.left;
		if (this.state.direction === 'Vertical') {
			resizeHandlerStyle = {
				width: this.props.isFundamental ? '100%' : customWidth,
				height: this.props.browserFilterRSHeight || '15px',
				cursor: 'ns-resize',
				position: 'absolute',
				left: this.props.customResizerStyle ? resizerStyle.left : 0,
        bottom: -10,
        top: this.state.height ? this.state.height + resizerStyle.marginTop : resizerStyle.marginTop,
        backgroundColor:'transparent',
        pointerEvents: 'auto'
			};
		}

		if (this.state.direction === 'Horizontal') {
			resizeHandlerStyle = {
				width: '15px',
				height: this.props.isFundamental ? '100%' : this.state.height >= this.state.maxHeight ? this.state.maxHeight : this.state.height,
				cursor: 'ew-resize',
				position: 'absolute',
				left: this.state.width,
        backgroundColor:'transparent',
        pointerEvents: 'auto'
			};
    
		}
		resizeHandlerStyle['zIndex'] = 1;
		return resizeHandlerStyle;
    
	}

  // Drag Functions
  initDrag(e) {
   let p = document.getElementsByClassName(this.props.className)[0];
   startX = e.clientX;
   startY = e.clientY;
   startWidth = parseInt(document.defaultView.getComputedStyle(p).width, 10);
   startHeight = parseInt(document.defaultView.getComputedStyle(p).height, 10);

   document.body.classList.add('selectTextDisble');
   document.documentElement.addEventListener('mousemove', this.doDrag, false);
   document.documentElement.addEventListener('mouseup', this.stopDrag, false);
   if(this.props.onInitResize) {
      this.props.onInitResize();
    }
}

  doDrag(e) {
    let calculatedWidth = (startWidth + e.clientX - startX);
    let calculatedHeight = (startHeight + e.clientY - startY);

      if(this.props.direction == "Horizontal") {
        if(calculatedWidth < this.state.minWidth) calculatedWidth = this.state.minWidth;
        if(calculatedWidth > this.state.maxWidth) calculatedWidth = this.state.maxWidth;
        this.setState({width:calculatedWidth});
      }
      else {
        if(calculatedHeight < this.state.minHeight) calculatedHeight = this.state.minHeight;
      if(calculatedHeight > this.state.maxHeight) calculatedHeight = this.state.maxHeight;
        this.setState({height:calculatedHeight});
      }
    
    let resizeValue = this.props.direction == "Horizontal" ? this.state.width : this.state.height;
    this.props.onStartResize(resizeValue);
  }

  stopDrag(e) {
    document.body.classList.remove('selectTextDisble');
    document.documentElement.removeEventListener('mousemove', this.doDrag, false);
    document.documentElement.removeEventListener('mouseup', this.stopDrag, false);
    if(this.props.onStopResize) {
      this.props.onStopResize();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  getOuterDivPosition() {
    let position = 'relative';
    if (this.props.isFilterHeight == true || this.props.isFundamental) { position = 'absolute'; }
    return position;
  }

	render() {
		let outerDivStyle = {
			backgroundColor: 'transparent', width: this.state.width,
			height: this.props.isFundamental ? "65%" : this.state.height,
			cursor: 'default',
      position: this.getOuterDivPosition(),
			zIndex: this.props.customStyles ? this.props.customStyles.zIndex : 'inherit',
      pointerEvents: this.props.pointerEvents ? this.props.pointerEvents : 'auto',
      display:'inline-block',
      maxHeight:this.props.maxHeight, minHeight: this.props.minHeight, maxWidth:this.props.maxWidth, minWidth:this.props.minWidth
		};

    let resizeHandlerStyle = this.getResizeHandlerStyle();

		return (<div className={this.props.className} id="resizable-element" style={outerDivStyle}>
			<div  className="resize-handler" style={resizeHandlerStyle} onMouseDown={this.initDrag}></div>
			{this.props.children}
		</div>);
	}
}
