import React from 'react';

import BaseServiceApi from 'BaseServiceApi';
import StringUtil from "StringUtil";
//import { GetTempListData } from "ListActions";

const TreeNode = (
    {
        entry,
        activeClass,
        activeNodeId,
        activeSybol,
        handleClickFromTempList,
        onContextMenuClick,
        handleDragEnd,
        handleListDrop,
        handleListDragStart,
        handleDragOver
    }) => {

    const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
    const ListAlertStatusType = BaseServiceApi.rayData["ListAlertStatusType"];
    const ListType = BaseServiceApi.rayData["ListType"];
    const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

    const rootParentNodeId = entry.nodeId.low;

    let listClassName = 'userList ';
    if (entry.listType === ListType.LIST_List) {
        switch (entry.categoryType) {
            case ListCategoryType.FAVORITE_ListCategory:
                listClassName += 'fa icn-fa-file-text-fav';
                break;

            case ListCategoryType.USER_ListCategory:
                listClassName += 'fa icn-fa-file-text-userlist';
                break;

            case ListCategoryType.APLIST_ListCategory:
                listClassName += 'fa icn-fa-file-text-ap';
                break;

            case ListCategoryType.SHAREDLIST_ListCategory:
                if (!StringUtil.isEmpty(entry.shareAccess))
                    listClassName += entry.shareAccess === ShareAccessType.SHARE_READANDEDIT ? 'icn-editable' : 'fa icn-fa-file-text-userlist'
                break;
            case ListCategoryType.NOTIMPORTEDLIST_ListCategory:
            case ListCategoryType.TEMPORARYLIST_ListCategory:
                listClassName += 'fa icn-fa-file-text-temp-list';
                break;

            default:
                listClassName += 'fa icn-fa-file-text-p';
                break;
        }
    }

    let bellIcon = '';

    if (entry.alertStatusType == ListAlertStatusType.Active)
        // bellIcon = <img alt="" style={{ position: "absolute", left: "10px", top: "5px", width: "16px", height: "16px" }} src="/Asset/images/bell-silver.svg" />;
        bellIcon = <span className="icn-price-alert-trigger for-list-alert"></span>;
    else if (entry.alertStatusType == ListAlertStatusType.Triggered)
        // bellIcon = <img alt="" style={{ position: "absolute", left: "10px", top: "5px", width: "16px", height: "16px" }} src="/Asset/images/bell-gold.svg" />;
        bellIcon = <span className="icn-price-alert-trigger-hover for-list-alert"></span>;

    let selectedNode = {};
    selectedNode.nodeId = entry.nodeId.low;
    if (entry.parentNodeId !== null)
        selectedNode.parentNodeId = entry.parentNodeId.low;
    selectedNode.rootParentNodeId = rootParentNodeId;
    selectedNode.sourceListId = !StringUtil.isEmpty(entry.sourceListId) ? entry.sourceListId.low : null;
    selectedNode.categoryType = entry.categoryType;
    selectedNode.name = entry.name;
    selectedNode.listType = entry.listType;
    selectedNode.className = listClassName;
    selectedNode.listCategory = entry.listCategory;
    selectedNode.listTreeItemLevel = entry.listTreeItemLevel;
    selectedNode.sourceCategoryType = entry.sourceCategoryType;
    selectedNode.parentCategoryType = entry.parentCategoryType;
    selectedNode.parentCategoryName = entry.parentCategoryName;
    selectedNode.parentListType = entry.parentListType;
    selectedNode.sourceParentCategoryName = entry.sourceParentCategoryName;
    selectedNode.symbol = entry.symbol;
    selectedNode.msid = entry.msid;
    selectedNode.temporaryListType = entry.temporaryListType;
    selectedNode.spId = entry.spId;

    if ((activeSybol && selectedNode.symbol == activeSybol) || (selectedNode.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory && activeNodeId !== null && selectedNode.nodeId == activeNodeId)) activeClass += ' active';

    selectedNode = JSON.stringify(selectedNode);

    const nodeHandleDragOver = (entry) => (event) => {
        handleDragOver(entry, event);
    }

    const nodeHandleListDragStart = (entry) => (event) => {
        handleListDragStart(entry, event);
    }

    const nodeHandleListDrop = (entry) => (event) => {
        handleListDrop(entry, event);
    }

    const nodeHandleDragEnd = (entry) => (event) => {
        handleDragEnd(entry, event);
    }

    // const GetTempData = (entry) => (event) => {
    //     event.preventDefault();
    //     GetTempListData(entry);
    // }


    return (
        rootParentNodeId === ListCategoryType.USER_ListCategory || entry.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory || entry.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory
            ? <div  className={activeClass}
                draggable="true" key={entry.nodeId.low}
                onDragOver={nodeHandleDragOver(entry)}
                onDragStart={nodeHandleListDragStart(entry)}
                onDrop={nodeHandleListDrop(entry)}
                onDragEnd={nodeHandleDragEnd(entry)}
                onContextMenu={onContextMenuClick}
                onClick={handleClickFromTempList} //GetTempData(entry)
                data-node-id={entry.nodeId.low}
                data-symbol={entry.symbol}
            >

                <span style={{ position: 'relative' }} className='listCategoryClass list-no-whiteSpace individual-item list-no-Border ' data-node-id={entry.nodeId.low} data-symbol={entry.symbol}>
                    {bellIcon}
                    <i className={listClassName} data-node-id={entry.nodeId.low} data-symbol={entry.symbol}></i>
                    <span style={{ verticalAlign: 'middle' }} data-node-id={entry.nodeId.low} data-categorytype={entry.categoryType} data-symbol={entry.symbol}>{entry.name}</span>
                </span>
                <a data-node-id={entry.nodeId.low} data-symbol={entry.symbol} data-selectednode={selectedNode}></a>

            </div>
            :
            <div  data-node-id={entry.nodeId.low} className={activeClass}
                onDragOver={nodeHandleDragOver(entry)}
                onDragStart={nodeHandleListDragStart(entry)}
                onDrop={nodeHandleListDrop(entry)}
                onDragEnd={nodeHandleDragEnd(entry)}
                onContextMenu={onContextMenuClick}
                onClick={handleClickFromTempList}
                draggable="true" key={entry.nodeId.low}
            >

                <span style={{ position: 'relative' }} className='listCategoryClass list-no-whiteSpace list-no-Border ' data-node-id={entry.nodeId.low} id={entry.symbol}>
                    {bellIcon}
                    <i className={listClassName} data-node-id={entry.nodeId.low}></i>
                    <span style={{ verticalAlign: 'middle' }} data-node-id={entry.nodeId.low} >{entry.name}</span>
                </span>
                <a data-node-id={entry.nodeId.low} data-selectednode={selectedNode}></a>
            </div>
    )
}


export default TreeNode;
