import GraphType from "../../../../Constants/GraphType";
import { maLineTranslateHelper } from "../../../../Utils/TranslateHelper";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";

const { ActionTypes } = PriceChartConstants;
const initialDialogStates = {
    isMADialogOpen: false,
    maLineHeaderText: maLineTranslateHelper[GraphType.Weekly],
    maType: "SMA",
    maLength: 50,
    defaultMALineColor: 'P34FF33006600',
    lineColor: "P34FF33006600",
    lineThickness: 1,
    selectedMALine: -1,
    hasError: false,
    errorMessage: 'Error: Only Numbers Allowed',
    isMAValidationDialogOpen: false,
}
const initialState = {
    selectedPeriodicity: GraphType.Weekly,
    ...initialDialogStates,
}
const MAReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.MA_DATA_READY:
            return {
                ...state,
                maLineData: action.maLineData,
                maSettings: action.maSettings,
                selectedPeriodicity: action.majorPeriodicity
            };
            case ActionTypes.MA_SETTINGS_UPDATE:
            return {
                ...state,
                maSettings: [...action.maSettings]
            };
        
        case ActionTypes.CANCEL_MA_LINE_EDIT_DIALOG:
            return { ...state, ...initialDialogStates};
        case ActionTypes.ADD_MA_LINE:
            return {...state, ...initialDialogStates,
                isMADialogOpen: true,
                maLineHeaderText:  maLineTranslateHelper[state.selectedPeriodicity]
            };
        case ActionTypes.CHANGE_MA_LINE_WEIGHT:
            state.lineThickness = action.lineThickness;
            return { ...state }
        case ActionTypes.CHANGE_MA_LINE_COLOR:
            state.lineColor = action.lineColor;
            return { ...state }
        case ActionTypes.SET_MA_LINE_ERROR:
            state.hasError = true;
            state.maLength = '';
            return { ...state }
        case ActionTypes.CHANGE_MA_LINE_LENGTH:
            state.hasError = false;
            state.maLength = action.maLength;
            return { ...state }
        case ActionTypes.CHANGE_MA_LINE_TYPE:
            state.maType = action.maType;
            return { ...state }
        case ActionTypes.UPDATE_MA_LINE_STATES:
            state.isMADialogOpen = action.isMADialogOpen;
            state.maLineHeaderText = action.maLineHeaderText;
            state.maType = action.maType;
            state.maLength = action.maLength;
            state.lineColor = action.lineColor;
            state.lineThickness = action.lineThickness;
            state.selectedMALine = action.selectedMALine;
            return { ...state }
        case ActionTypes.TOGGLE_MA_VALIDATION_DIALOG:
            state.isMAValidationDialogOpen = action.isMAValidationDialogOpen;
            return { ...state };
        default:
            return state
    }
}
export default MAReducer;
