import BlockType from "BlockType";
import ClickMenu from "ClickMenu";
import { connect } from 'react-redux';
import { FinancialTransLateHelper } from "../../../../../Utils/TranslateHelper";
import { collapseFinancialBlock, onPageChanged, selectMenuItem } from "../../../../../Actions/FinancialBlockActions";
import React, { Component } from "react";

class BlockTitleBar extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      isDraggable: false
    };
  }

  setDraggableState() {
    this.timeout = setTimeout(() => {
      this.setState({ isDraggable: true });
    }, 600);

  }
  setNonDraggableState() {
    clearTimeout(this.timeout);
    this.setState({ isDraggable: false });
  }

  menuItemSelect(item) {
    if (item.Type === "Visibility") {
      this.props.collapseFinancialBlock(BlockType.Financial, false)
    }
    else {
      this.props.selectMenuItem(item)
    }
  }

  render() {
    const LeftColumnWidth = "220px";
    const title = FinancialTransLateHelper.FINANCIAL_BLOCK_TITLE;
    return (
      <div className="financial-left-heading" style={{ width: LeftColumnWidth }}>
        <div className="financial-heading-distance">
          <div className="financial-head-part">
            <h6>{title}</h6>
            <ClickMenu position="top"
              button="left"
              onselect={(item) => this.menuItemSelect(item)}
              items={this.props.headerMenu}
            />
          </div>
        </div>
        <div className="financial-pagination">
          <ul className="pagging-point">
            {this.props.PagintedSource && this.props.PagintedSource.map((pageItem) => {
              const className = pageItem.isSelected ? "active" : "";
              return <li onMouseDown={() => this.props.onPageChanged(pageItem.PageNum - 1)} key={pageItem.PageNum}><div className={className} /></li>;
            })
            }
          </ul>
        </div>
      </div>
      //</div>
    );
  }
}

const mapStateToProps = (state) => {
  const { PagintedSource, BlockSize, headerMenu } = state.DatagraphReducers.financialBlockReducer;
  return { PagintedSource, BlockSize, headerMenu }
}
const mapDispatchToProps = (dispatch) => ({
  onPageChanged: (pageNum) => dispatch(onPageChanged(pageNum)),
  collapseFinancialBlock: (blockType, isopen) => dispatch(collapseFinancialBlock(blockType, isopen)),
  selectMenuItem: (item) => dispatch(selectMenuItem(item))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BlockTitleBar)