const PeriodicityType = {
    Daily: 1,
    Weekly: 2,
    Monthly: 3,
    Intraday1Min: 4,
    Intraday5Min: 5,
    Intraday10Min: 6,
    Intraday15Min: 7,
    Intraday30Min: 8,
    Intraday60Min: 9,
    Quarterly: 10,
    Annual: 11

}
export default PeriodicityType;