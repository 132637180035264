export const getAnnotationMenu = (state) => state.annotationReducers.annotationMenuReducer;
export const getAnnotationChartDraw = (state) => state.annotationReducers.annotationChartModelReducer;
export const getAnnotationIsDrawing = (state) => getAnnotationChartDraw(state).isDrawing;
export const getAnnotationSelected = (state) => getAnnotationChartDraw(state).annotSelected;
export const getRectangle = (state) => state.annotationReducers.rectangleReducer;
export const getEllipse = (state) => state.annotationReducers.ellipseReducer;
export const getTriangle = (state) => state.annotationReducers.triangleReducer;
export const getLine = (state) => state.annotationReducers.lineReducer;
export const getChannelLine = (state) => state.annotationReducers.channelLineReducer;
export const getAnnotationDimension = (state) => getAnnotationChartDraw(state).annotationDimension;
export const getAnnotationSelectedId = (state) => getAnnotationChartDraw(state).annotSelected ? getAnnotationChartDraw(state).annotSelected.id : null;
export const getText = (state) => state.annotationReducers.textReducer;
export const getPriceNodes = (state) => getAnnotationChartDraw(state).translatedChartNodeDict;
export const getCurToolType = (state) => getAnnotationMenu(state).selectedToolType;
export const getMeasurementData = (state) => state.annotationReducers.measurementReducer;
export const getFibonacciLine = (state) => state.annotationReducers.fibonacciLineReducer;
export const getVisibleAnnotIds = (state) => getAnnotationChartDraw(state).allVisibleAnnotId;
export const getReadOnlyAnnotIds = (state) => getAnnotationChartDraw(state).allReadOnlyAnnotId;
export const getIsRiPanelDelete = (state) => getAnnotationChartDraw(state).isRiPanelDelete;
export const getSelectedAnnotId = (state) => getAnnotationChartDraw(state).selectedAnnotId;
export const getCurrentShape = (state) => state.annotationReducers.annotationMenuReducer.ShapesModelInfo.type;
export const getLayerManagementReducerState = (state) => state.annotationReducers.annotationLayerManagementReducer;
export const getActiveAnchor = (state) => getAnnotationChartDraw(state).activeAnchor;
export const getTargetLayer = (state) => getLayerManagementReducerState(state).selectedLayer;
export const getIsAlertUpdate = (state) => getAnnotationChartDraw(state).isAlertUpdate;
export const getDeletedAnnotId = (state) => getAnnotationChartDraw(state).deletedId;
export const getDeletedAnnotObj = (state) => getAnnotationChartDraw(state).deletedObj;
export const getIsLayerDelete = (state) => getAnnotationChartDraw(state).isLayerDelete;
export const getLayersList = (state) => getLayerManagementReducerState(state).LayersList;
export const getAnnotationsReducers = (state) =>  state;
export const getcombinedAnnotations = (state) => getAnnotationChartDraw(state).combinedAnnotations;
export const getIsDeleteAlert = (state) => getAnnotationChartDraw(state).isDeleteAlert;
export const getAlertDeleteAnnot = (state) => getAnnotationChartDraw(state).alertDeleteAnnot;
export const getIsAlertDelete = (state) => getAnnotationChartDraw(state).isAlertDeleted;
export const getTepmToolType = (state) => getAnnotationMenu(state).tempToolType;
export const getMouseMoved = (state) => getAnnotationMenu(state).mouseMoved;
export const getTempIdsArray = (state) => getAnnotationChartDraw(state).tempIdsArray;
export const getTempAnnotId = (state) => getAnnotationChartDraw(state).tempAnnotId;
