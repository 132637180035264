import React from "react";
import { contains, isEqual } from "underscore";
import { form, ButtonToolbar, DropdownButton, MenuItem, FormGroup } from "react-bootstrap";
import StringUtil from "StringUtil";
import ColumnCustomFilterControl from "./ColumnCustomFilterControl.jsx";
import { Shortcuts } from 'react-shortcuts';
import ScrollBar from "ScrollBar";
import BaseServiceApi from 'BaseServiceApi';
import LocalizationStore from "LocalizationStore";
let ValueOperatorType = BaseServiceApi.rayData["ValueOperatorType"];
export default class MultiSelectFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selection: ['All'],
            title: '=',
            operator: ValueOperatorType.EQ_ValueOperator,
            rows: [],
            pickList: false,
            inputHasValue: false,
            previouseSelection: ['All'],
            previouseOperator: ValueOperatorType.EQ_ValueOperator,
        }
        this.lis = null;
        this.lastSelectedRow;
        this.applyFilter = this.applyFilter.bind(this);
        this.getPickList = this.getPickList.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.setSelection = this.setSelection.bind(this);
        this.clearTextBar = this.clearTextBar.bind(this);
        this.getSelectCount = this.getSelectCount.bind(this);
        this.getDiv = this.getDiv.bind(this);
        this.scrollList = this.scrollList.bind(this);
        this.setSelectedRow = this.setSelectedRow.bind(this);
        this._handleShortcuts = this._handleShortcuts.bind(this);
    }

    componentDidMount() {
        this.lis = [];
        this.lis = document.getElementById('multiFilterList').getElementsByTagName('li');
        this.allRows = document.getElementById('multiFilterList').getElementsByTagName('li');
        this.verticalScrollElm = document.getElementById('list-multiselect-filter-VerticalScroll');
        this.boxDiv.focus();
    }

    UNSAFE_componentWillMount() {
        let selection = this.getValue();
        let operator = this.getOperator();
        let title = this.getOperatorText(operator);
        if (selection.length == 1 && selection[0] == 'All') {
            this.setState({ inputHasValue: false, previouseSelection: ['All'], previouseOperator: ValueOperatorType.EQ_ValueOperator });
        }
        else {
            this.setState({ selection: selection, operator: operator, previouseSelection: selection, previouseOperator: operator, title: title, inputHasValue: true });
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.pickList !== nextProps.pickList) {
            return true;
        }
        if (this.state.selection !== nextState.selection || this.state.operator !== nextState.operator) {
            return true;
        }
        return false;
    }

    _handleShortcuts(action, event) {
        switch (action) {
            case 'MOVE_UP':
            case 'MOVE_DOWN':
                this.scrollList(event);
                break
            case 'SELECT_ALL':
                this.selectRowsBetweenIndexes([1, this.allRows.length]);
                this.setSelection();
                event.preventDefault();
                event.stopPropagation();
                break;
            case 'ENTER_APPLY':
                this.applyFilter();
                break;
            default:
                break;
        }
    }

    getOperatorText(operator) {
        return ColumnCustomFilterControl.toDisplayTextOperator(parseInt(operator)).toString();
    }

    getOperator() {
        if (this.props.activeColumn == null) {
            return ValueOperatorType.EQ_ValueOperator;
        }
        if (this.props.colData.dataItemId == this.props.activeColumn.dataItemId) {
            return this.props.activeColumn.valueOperator.toString();
        }
    }

    getValue() {        
        if (this.props.activeColumn == null) {
            return ['All'];
        }
        if (this.props.colData.dataItemId == this.props.activeColumn.dataItemId) {
            if (this.props.colData.dataItemId == "-7") {
                return ColumnCustomFilterControl.getTextOfValues(this.props.activeColumn.values, true);
            }
            return this.props.activeColumn.values;
        }
    }

    RowClick(event, currenttr) {
        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey && !event.metaKey) {
                this.clearAll();
                this.toggleRow(currenttr);
            }

            else if (event.ctrlKey || event.metaKey) {
                this.toggleRow(currenttr);
            }

            else if (event.shiftKey) {
                if (this.lastSelectedRow == undefined) {
                    this.setSelectedRow(null, currenttr);
                }
                this.selectRowsBetweenIndexes([this.getIndex(this.allRows, this.lastSelectedRow) + 1, this.getIndex(this.allRows, currenttr) + 1]);
            }
        }
        this.setSelection();
    }

    setSelection() {
        let selection = [];
        for (let i = 0; i < this.lis.length; i++) {
            if (this.lis[i].className == 'active') {
                selection.push(this.lis[i].getAttribute("data-rb").trim()); //this.lis[i].textContent
            }
        }
        if (selection.length == 0 || selection == null) {
            this.lis[0].className = 'active';
            this.lis[0].firstChild.className = 'icn-right-icon';
            selection.push(this.lis[0].getAttribute("data-rb").trim()); //this.lis[0].textContent
        }
        this.setState({ selection: selection });
    }

    toggleRow(row) {
        let insideSpan;
        row.className = row.className == 'active' ? '' : 'active';
        if (!StringUtil.isEmpty(row))
            insideSpan = row.firstChild;
        if (!StringUtil.isEmpty(insideSpan))
            insideSpan.className = insideSpan.className == 'icn-right-icon' ? '' : 'icn-right-icon';
        if (row.className == 'active')
            this.lastSelectedRow = row;
        else
            this.lastSelectedRow = undefined;
    }

    selectRowsBetweenIndexes(indexes) {
        this.clearAll();
        if (indexes.length > 0) {
            indexes.sort((a, b) => a - b);

            for (let i = indexes[0]; i <= indexes[1]; i++) {
                let insideSpan;
                if (i > 0) {
                    this.lis[i - 1].className = 'active';
                    insideSpan = this.lis[i - 1].firstChild;
                }
                else {
                    this.lis[0].className = 'active';
                    insideSpan = this.lis[0].firstChild;
                }
                if (!StringUtil.isEmpty(insideSpan))
                    insideSpan.className = 'icn-right-icon';
            }
        }
    }

    clearAll() {
        if (this.lis.length > 0) {
            for (let i = 0; i < this.lis.length; i++) {
                this.lis[i].className = '';
                let insideSpan = this.lis[i].firstChild;
                insideSpan.className = '';
            }
        }
    }

    getDiv() {
        return this.boxDiv;
    }

    //Find Index of Li from Li List
    getIndex(array, element) {
        for (let i = 0; i < array.length; i++) {
            if (array[i] == element) return i;
        }
        return -1;
    }


    handleClick(e) {
        this.RowClick(e, e.target, false);
    }

    setSelectedRow(row, currenttr) {
        if (row != null)
            this.lastSelectedRow = row;
        else {
            if (this.multiFilterList) {
                let withActive = [];
                for (let i = 0; i < this.multiFilterList.children.length; i++) {
                    if (StringUtil.hasClass(this.multiFilterList.children[i], 'active'))
                        withActive.push(i);
                }
                let indexCurrenttr = this.getIndex(this.allRows, currenttr);
                if (indexCurrenttr < withActive[0])
                    this.lastSelectedRow = this.multiFilterList.children[withActive[0]];
                else if (indexCurrenttr >= withActive[withActive.length - 1]) {
                    this.lastSelectedRow = this.multiFilterList.children[withActive[withActive.length - 1]];
                }
            }
        }
    }

    scrollList(e) {
        if (this.multiFilterList) {
            let index = StringUtil.getIndexForKeyUpDown(e, this.multiFilterList);
            if (index != undefined) {
                if (index != 'submit') {
                    this.clearAll();
                    this.multiFilterList.childNodes[index].className = "active";
                    let insideSpan = this.multiFilterList.childNodes[index].firstChild;
                    insideSpan.className = 'icn-right-icon';
                    this.lastSelectedRow = this.multiFilterList.childNodes[index];
                    let selctedRowClientRect = this.multiFilterList.childNodes[index].getBoundingClientRect();
                    this.verticalScrollElm.scrollTop = this.multiFilterList.childNodes[index].offsetTop - ((Math.floor(selctedRowClientRect.height) * 7) - 14);
                    this.setSelection();
                }
            }

        }
    }


    getPickList() {
        let data = [];
        let self = this;
        let name = null;
        if (contains(self.state.selection, 'All')) {
            data.push(<li key="All" data-rb="All" className="active" onMouseDown={this.handleClick}><span className="icn-right-icon"></span> {LocalizationStore.getTranslatedData("fr_All", "All")}</li>);
        }
        else {
            data.push(<li key="All" data-rb="All" onMouseDown={this.handleClick}><span className=""></span> {LocalizationStore.getTranslatedData("fr_All", "All")}</li>);
        }
        this.props.pickList.map(function (value) {
            name = ColumnCustomFilterControl.getName(value.value);
            if (contains(self.state.selection, value.value)) {
                data.push(<li key={value.value} data-rb={value.value} onMouseDown={this.handleClick} className="active"><span className="icn-right-icon"></span>{name}</li>);
            } else {
                data.push(<li key={value.value} data-rb={value.value} onMouseDown={this.handleClick}><span className=""></span>{name}</li>);
            }
        }, this);
        return data;
    }

    getSelectedTextBar(selection) {
        let selectedTextBar = [];
        if (selection == null) {
            selectedTextBar.push(<span key={0} style={{ whiteSpace: "pre" }}>{" "}</span>);
            return selectedTextBar;
        }
        let length = selection.length;
        selection.map((select, i) => {
            let selectValue = select == "All" ? LocalizationStore.getTranslatedData("fr_All", "All") : ColumnCustomFilterControl.getName(select);
            if (i == length - 1) {
                selectedTextBar.push(<span key={i}>{selectValue} </span>);
            }
            else {
                selectedTextBar.push(<span key={i}>{selectValue},  </span>);
            }

        });
        return selectedTextBar;
    }

    clearTextBar() {
        this.setState({ selection: ['All'] });
        this.lastSelectedRow = undefined;
    }

    getSelectCount() {
        let count = this.state.selection.length;
        return count;
    }

    applyFilter() {        
        let operator = this.state.operator;
        let value = (this.props.colData.dataItemId == -7) ? ColumnCustomFilterControl.getValuesForColumnId7(this.state.selection) : this.state.selection;
        let valueLength = value.length;
        let previouseSelection = (this.props.colData.dataItemId == -7) ? ColumnCustomFilterControl.getValuesForColumnId7(this.state.previouseSelection) : this.state.previouseSelection;
        let previouseOperator = this.state.previouseOperator;
        let validInput = true;
        if (valueLength == 1) {
            if (value[0] == 'All' || value[0] == '')
                validInput = false;

        }
        if (!isEqual(value, previouseSelection)) {
            if (!isEqual(value, previouseSelection) || !isEqual(operator, previouseOperator)) {
                this.props.applyFilter(operator, value, true, false);
            }
            else {
                this.props.applyFilter(operator, value, true, true);
            }
        }
        else {
            if (!isEqual(operator, previouseOperator) && validInput) {
                this.props.applyFilter(operator, value, true, false);
            }
            else {
                this.props.applyFilter(operator, value, true, true);
            }
        }

    }

    onSelect(eventKey, event) {
        this.setState({
            title: event.target.textContent,
            operator: eventKey
        });
    }

    render() {
        let pickListLi = (this.props.pickList != null) ? this.getPickList() : null;
        let displayDiv = (pickListLi == null) ? "none" : "";
        let selection = null;
        let selectCount = 0;
        if (this.props.pickList != null && this.props.pickList.length > 0) {
            selection = this.state.selection;
            selectCount = this.getSelectCount();
        }
        let selectedTextBar = this.getSelectedTextBar(selection);

        return (
            <Shortcuts
                name='NAV_LIST_MANAGER'
                handler={this._handleShortcuts}
                tabIndex={-1}
                targetNodeSelector='.arrow_box-multiple'
                global={true}
                isolate={true}
            >
                <div className="list-filter-header">
                    <div className="arrow_box-multiple" ref={(div) => this.boxDiv = div}>
                        <div className="info-arrow-box-multiple">
                            <div className="inner-arrow-box">
                                <form className="form-inline">
                                    <FormGroup className="customListDropDown">
                                        <ButtonToolbar >
                                            <DropdownButton bsSize="xsmall" title={this.state.title} noCaret id="dd-arrow"
                                                onSelect={(eventKey, event) => {this.onSelect(eventKey, event)}}>
                                                <MenuItem eventKey={ValueOperatorType.EQ_ValueOperator} className={(this.state.operator == ValueOperatorType.EQ_ValueOperator) ? "active" : ""}>&#61;</MenuItem>
                                                <MenuItem eventKey={ValueOperatorType.NOTEQ_ValueOperator} className={(this.state.operator == ValueOperatorType.NOTEQ_ValueOperator) ? "active" : ""}>&#8800;</MenuItem>
                                                {/*<MenuItem eventKey={ValueOperatorType.CONTAINS_ValueOperator} className={(this.state.operator == ValueOperatorType.CONTAINS_ValueOperator) ? "active" : ""}>{LocalizationStore.getTranslatedData("LM_Contains","Contains")}</MenuItem>
                                            <MenuItem eventKey={ValueOperatorType.NOTCONTAINS_ValueOperator} className={(this.state.operator == ValueOperatorType.NOTCONTAINS_ValueOperator) ? "active" : ""}>{LocalizationStore.getTranslatedData("LM_DoesNotContain","Does not contain")}</MenuItem>*/}
                                            </DropdownButton>
                                        </ButtonToolbar>
                                    </FormGroup>
                                    <FormGroup>
                                        <div className="selectValue"><div className="add-select-filter">{selectedTextBar}</div ><span className="icn-circle-custom1 pull-right" style={selection == null ? { visibility: 'hidden' } : {}} onClick={this.clearTextBar}></span></div>
                                        <div className="inner-well inner-well-multiple" style={{ display: displayDiv }} >
                                            <div className="inner-block" style={this.props.pickList != null && this.props.pickList.length > 0 ? { minHeight: "240px", position: "relative" } : { minHeight: "50px", position: "relative" }}>
                                                <div className="custom-scroll-light">
                                                    <div id='list-multiselect-filter-VerticalScroll'>
                                                        <div className="list-cont">
                                                            <div className="title-info">{LocalizationStore.getTranslatedData("LM_ prompt", "Use CTRL or SHIFT key to select multiple items.")}</div>
                                                            <ul className="multiFilterList medium-normal" ref={(ul) => { this.multiFilterList = ul }} id="multiFilterList">
                                                                {(this.props.pickList != null && this.props.pickList.length > 0) ?
                                                                    pickListLi : null}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <ScrollBar scrollId="list-multiselect-filter-VerticalScroll" vScroll={true} />
                                                </div>
                                            </div>
                                            <div className="add-row">
                                                {selectCount != 0 ?
                                                    <span className="add-row-link-select"> {LocalizationStore.getTranslatedData("LM_ Selected", "{0} Selected", selectCount)}</span> :
                                                    <span className="add-row-link-select" style={{ whiteSpace: "pre" }}>{" "}</span>
                                                }
                                                <button type="button" className="add-row-apply" onClick={this.applyFilter}> {LocalizationStore.getTranslatedData("LM_Apply", "Apply")}</button>
                                            </div>
                                        </div>
                                    </FormGroup>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Shortcuts>
        );
    }
}
