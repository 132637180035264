import Scale from "./Scale.js";
//import MathUtil from "../MathUtil";
import ExtremeDataValue from "ExtremeDataValue";

class WArithmaticScale extends Scale {

    getObjectMapKey() {
        return "WArithmaticScale";
    }
    //constructor(Factor, Dlr, I1) {
    constructor(Factor) {
        super(Factor);
        this.Dlr = 0;
        this.I1 = 0;
        this.solidLine = [];
    }
    ComputePrice(y) {
        var dGraphDistance = this.height - y;

        var dPriceDiffFromBottom = dGraphDistance / this.Factor;
        dPriceDiffFromBottom += this.Lowpt;

        return dPriceDiffFromBottom;
    }
    ComputeY(dPrice) {
        var dPriceDiffFromBottom = dPrice - this.Lowpt;

        return this.height - dPriceDiffFromBottom * this.Factor;
//        return Math.round(this.height - dPriceDiffFromBottom * this.Factor, 2);
    }
    ComputeYL(dPrice, dLow) {
        var dPriceDiffFromBottom = dPrice - dLow;

        return this.height - dPriceDiffFromBottom * this.Factor;
//        return Math.round(this.height - dPriceDiffFromBottom * this.Factor, 2);
    }
    digitsCount(n) {
        if(!isFinite(n)) return 0;
        let count;
        count = 0;
        if (n >= 1) ++count;

        while (n / 10 >= 1) {
            n /= 10;
            ++count;
        }

        return count;
    }
    setDlrI1(number) {
        const count = this.digitsCount(number);
        const power = Math.pow(10, count-1);
        if (number <= 1*power) {
            this.Dlr = 0.2*power;
            this.I1 = 0.05*power;
            if (number / this.Dlr < 3) {
                this.Dlr = 0.1*power;
                this.I1 = 0.02*power;
            }
            else
                if (number / this.Dlr > 5) {
                    this.Dlr = 1*power;
                    this.I1 = 0.2*power;
                }
        }
        else
            if (number <= 5*power) {
                this.Dlr = 1*power;
                this.I1 = 0.2*power;
                if (number / this.Dlr < 3) {
                    this.Dlr = 0.5*power;
                    this.I1 = 0.1*power;
                }
                else
                    if (number / this.Dlr > 5) {
                        this.Dlr = 2*power;
                        this.I1 = 0.5*power;
                    }
            }
        else
        if (number <= 10 * power) {
            this.Dlr = 2 * power;
            this.I1 = 0.5 * power;
            if (number / this.Dlr < 3) {
                this.Dlr = 1 * power;
                this.I1 = 0.2 * power;
            }
            else
            if (number / this.Dlr > 5) {
                this.Dlr = 10 * power;
                this.I1 = 2 * power;
            }
        }
    }
    InitScale(minPrice, maxPrice, height, graphType, scaleType, dpi, symbolType, epsMultiplier, rpsMultiplier, t4QMultipliers, externalDataMultiplier) {
        var hiprc = maxPrice;
        var lowprc = minPrice;
        var MLPriceIncrement;
        this.height = height;
        this.mhLines = [];

        MLPriceIncrement = hiprc - lowprc;

        if (MLPriceIncrement < 0.005) {
            if (lowprc < 1)
                MLPriceIncrement = 0.0001;
            else
                MLPriceIncrement = lowprc / 10;
        }

        var div = 1;
        var lowPriceMode = false;

        //if (MLPriceIncrement > 10000)
        //    div = 1000;

        if (hiprc - lowprc < 0.001) {
            lowPriceMode = true;
            div = 0.0001;
        }

        MLPriceIncrement /= div;
        if (lowPriceMode && MLPriceIncrement < 1000 && MLPriceIncrement > 100) {
            MLPriceIncrement = 500;
        }

        if (MLPriceIncrement >= 10) {
            this.setDlrI1(MLPriceIncrement);
        }

        if (MLPriceIncrement <= 0.005) {
            this.Dlr = 0.001;
            this.I1 = 0.0002;
            if (MLPriceIncrement / this.Dlr < 3) {
                this.Dlr = 0.0005;
                this.I1 = 0.0001;
            }
            else
            if (MLPriceIncrement / this.Dlr > 5) {
                this.Dlr = 0.002;
                this.I1 = 0.0005;
            }
        }
        else
        if (MLPriceIncrement <= 0.01) {
            this.Dlr = 0.002;
            this.I1 = 0.0005;
            if (MLPriceIncrement / this.Dlr < 3) {
                this.Dlr = 0.001;
                this.I1 = 0.0002;
            }
            else
            if (MLPriceIncrement / this.Dlr > 5) {
                this.Dlr = 0.01;
                this.I1 = 0.002;
            }
        }
        else
        if (MLPriceIncrement <= 0.05) {
            this.Dlr = 0.01;
            this.I1 = 0.002;
            if (MLPriceIncrement / this.Dlr < 3) {
                this.Dlr = 0.005;
                this.I1 = 0.001;
            }
            else
            if (MLPriceIncrement / this.Dlr > 5) {
                this.Dlr = 0.02;
                this.I1 = 0.005;
            }
        }
        else
        if (MLPriceIncrement <= 0.1) {
            this.Dlr = 0.02;
            this.I1 = 0.005;
            if (MLPriceIncrement / this.Dlr < 3) {
                this.Dlr = 0.01;
                this.I1 = 0.002;
            }
            else
            if (MLPriceIncrement / this.Dlr > 5) {
                this.Dlr = 0.1;
                this.I1 = 0.02;
            }
        }
        else
        if (MLPriceIncrement <= 0.5) {
            this.Dlr = 0.1;
            this.I1 = 0.02;
            if (MLPriceIncrement / this.Dlr < 3) {
                this.Dlr = 0.05;
                this.I1 = 0.01;
            }
            else
            if (MLPriceIncrement / this.Dlr > 5) {
                this.Dlr = 0.2;
                this.I1 = 0.05;
            }
        }
        else
        if (MLPriceIncrement <= 1) {
            this.Dlr = 0.2;
            this.I1 = 0.05;
            if (MLPriceIncrement / this.Dlr < 3) {
                this.Dlr = 0.1;
                this.I1 = 0.02;
            }
            else
            if (MLPriceIncrement / this.Dlr > 5) {
                this.Dlr = 1;
                this.I1 = 0.2;
            }
        }
        else
        if (MLPriceIncrement <= 5) {
            this.Dlr = 1;
            this.I1 = 0.2;
            if (MLPriceIncrement / this.Dlr < 3) {
                this.Dlr = 0.5;
                this.I1 = 0.1;
            }
            else
            if (MLPriceIncrement / this.Dlr > 5) {
                this.Dlr = 2;
                this.I1 = 0.5;
            }
        }
        else
            if (MLPriceIncrement <= 10) {
                this.Dlr = 2;
                this.I1 = 0.5;
                if (MLPriceIncrement / this.Dlr < 3) {
                    this.Dlr = 1;
                    this.I1 = 0.2;
                }
                else
                    if (MLPriceIncrement / this.Dlr > 5) {
                        this.Dlr = 10;
                        this.I1 = 2;
                    }
            }
/*            else
                if (MLPriceIncrement <= 50) {
                    this.Dlr = 10;
                    this.I1 = 2;
                    if (MLPriceIncrement / this.Dlr < 3) {
                        this.Dlr = 5;
                        this.I1 = 1;
                    }
                    else
                        if (MLPriceIncrement / this.Dlr > 5) {
                            this.Dlr = 20;
                            this.I1 = 5;
                        }
                }
                else
                    if (MLPriceIncrement <= 100) {
                        this.Dlr = 20;
                        this.I1 = 5;
                        if (MLPriceIncrement / this.Dlr < 3) {
                            this.Dlr = 10;
                            this.I1 = 2;
                        }
                        else
                            if (MLPriceIncrement / this.Dlr > 5) {
                                this.Dlr = 100;
                                this.I1 = 20;
                            }
                    }
                    else
                        if (MLPriceIncrement <= 500) {
                            this.Dlr = 100;
                            this.I1 = 20;
                            if (MLPriceIncrement / this.Dlr < 3) {
                                this.Dlr = 50;
                                this.I1 = 10;
                            }
                            else
                                if (MLPriceIncrement / this.Dlr > 5) {
                                    this.Dlr = 200;
                                    this.I1 = 50;
                                }
                        }
                        else
                            if (MLPriceIncrement <= 1000) {
                                this.Dlr = 200;
                                this.I1 = 50;
                                if (MLPriceIncrement / this.Dlr < 3) {
                                    this.Dlr = 100;
                                    this.I1 = 20;
                                }
                                else
                                    if (MLPriceIncrement / this.Dlr > 5) {
                                        this.Dlr = 1000;
                                        this.I1 = 200;
                                    }
                            }
                            else
                                if (MLPriceIncrement <= 5000) {
                                    this.Dlr = 1000;
                                    this.I1 = 200;
                                    if (MLPriceIncrement / this.Dlr < 3) {
                                        this.Dlr = 500;
                                        this.I1 = 100;
                                    }
                                    else
                                        if (MLPriceIncrement / this.Dlr > 5) {
                                            this.Dlr = 2000;
                                            this.I1 = 500;
                                        }
                                }
                                else {
                                    this.Dlr = 2000;
                                    this.I1 = 500;
                                    if (MLPriceIncrement / this.Dlr < 3) {
                                        this.Dlr = 1000;
                                        this.I1 = 200;
                                    }
                                    else
                                        if (MLPriceIncrement / this.Dlr > 5) {
                                            this.Dlr = 10000;
                                            this.I1 = 2000;
                                        }
                                }
                                */
        MLPriceIncrement *= div;
        this.Dlr *= div;
        this.I1 *= div;

        this.Lowpt = lowprc;
        this.Hipt = hiprc;

        // Test the conversion Factor.
        let diff = this.Hipt - this.Lowpt;
        if (diff < this.I1) {
            diff = this.I1 * 100;
        }
        this.Factor = height / diff;

        var yPr1 = this.ComputePrice(-50, height);
        var yPr2 = this.ComputePrice(height + 50, height);

        this.Lowpt = this.SetLowpt(yPr2);
        this.Hipt = this.SetHipt(yPr1);

        if (this.Hipt === maxPrice) {
            this.Hipt = this.Hipt + MLPriceIncrement;
        }
        if (this.Hipt < maxPrice) {
            this.Hipt = maxPrice + MLPriceIncrement;
        }

        // Calculate the factor with top and bottom margins
        diff = this.Hipt - this.Lowpt;
        if (diff < this.I1) {
            diff = this.I1 * 100;
        }
        this.Factor = height / diff;

        let eok = isNaN(epsMultiplier) ? 0 : 1;
        let rok = isNaN(rpsMultiplier) || rpsMultiplier < 0 ? 0 : 1;
        this.SetHorizontalGrid(epsMultiplier, rpsMultiplier, 1, eok, rok, t4QMultipliers, externalDataMultiplier);
    }

    SetLowpt(lowprc) {
        let lowpt = Math.floor(lowprc * 1000);
        const dlr = Math.floor(this.Dlr * 1000);
        const i1 = Math.floor(this.I1 * 1000);

        if (dlr < 1) return lowpt / 1000;

        if ((lowpt + dlr) < lowprc * 1000)
            lowpt += dlr;

        let tmp = Math.floor(lowpt / dlr) * dlr;

        if (tmp <= 0)
            tmp = -i1;

        if (tmp === lowpt)// && tmp != 0)
            lowpt = tmp - dlr;
        else
            if (lowpt < tmp)
                lowpt = tmp - dlr;
            else
                lowpt = tmp;

        return lowpt / 1000;
    }
    SetHipt(hiprc) {
        let hipt = Math.floor(hiprc * 1000);
        const dlr = Math.floor(this.Dlr * 1000);
        if (dlr > 0)
            hipt = Math.floor((hipt / dlr) + 1) * dlr;

        return hipt / 1000;
    }
    SetHorizontalGrid(epsMultiplier, rpsMultiplier, pok, eok, rok, t4QMultipliers, externalDataMultiplier) {
        if(!isFinite(this.Hipt) || !isFinite(this.Lowpt) || !isFinite(this.Dlr) || !isFinite(this.height)) return; 
        var height = this.height;
        var ycou = this.I1<0.1 ? 0 : Math.floor((this.Hipt % this.Dlr == 0 ? 0 : this.Dlr - this.Hipt % this.Dlr) * 1000) / 1000;
        this.solidLine = [];

        for (var pricePtr = this.Hipt; pricePtr > this.Lowpt - this.Dlr; pricePtr -= this.I1, ycou += this.I1) {
            var y = this.ComputeY(pricePtr, height);

            if (y + 15 > height) continue;

            var ltype = 1;
            var price = 0;
            var eprice = 0;
            var rprice = 0;
            var t0price = " ";
            var t1price = " ";
            var t2price = " ";
            var t3price = " ";
            var exdprice = " ";
            if (pok === 1) {
                price = Math.round(pricePtr,4);
                if (this.Dlr < 1)
                    price = pricePtr.toFixed(2);
                if (this.Dlr < 0.1)
                    price = pricePtr.toFixed(3);
                if (this.Dlr < 0.01)
                    price = pricePtr.toFixed(4);
            }
            if (eok === 1) {
                eprice = pricePtr / epsMultiplier;
                if (Math.abs(eprice) < 0.1)
                    eprice = (pricePtr / epsMultiplier).toFixed(3);
                else
                    eprice = (pricePtr / epsMultiplier).toFixed(2);
            }
            if (rok === 1) {
                rprice = pricePtr / epsMultiplier;
                if (Math.abs(rprice) < 0.1)
                    rprice = (pricePtr / rpsMultiplier).toFixed(3);
                else
                    rprice = (pricePtr / rpsMultiplier).toFixed(2);
            }
            if (t4QMultipliers && t4QMultipliers[0]) {
                t0price = pricePtr / t4QMultipliers[0];
                if (t0price > 0.1)
                    t0price = (pricePtr / t4QMultipliers[0]).toFixed(2);
                else
                    t0price = (pricePtr / t4QMultipliers[0]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[1]) {
                t1price = pricePtr / t4QMultipliers[1];
                if (t1price > 0.1)
                    t1price = (pricePtr / t4QMultipliers[1]).toFixed(2);
                else
                    t1price = (pricePtr / t4QMultipliers[1]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[2]) {
                t2price = pricePtr / t4QMultipliers[2];
                if (t2price > 0.1)
                    t2price = (pricePtr / t4QMultipliers[2]).toFixed(2);
                else
                    t2price = (pricePtr / t4QMultipliers[2]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[3]) {
                t3price = pricePtr / t4QMultipliers[3];
                if (t3price > 0.1)
                    t3price = (pricePtr / t4QMultipliers[3]).toFixed(2);
                else
                    t3price = (pricePtr / t4QMultipliers[3]).toFixed(3);
            }
            if (externalDataMultiplier) {
                exdprice = pricePtr / externalDataMultiplier;
                if (exdprice > 0.1)
                    exdprice = (pricePtr / externalDataMultiplier).toFixed(2);
                else
                    exdprice = (pricePtr / externalDataMultiplier).toFixed(3);
            }
            if (ycou === this.Dlr) {
                ycou = 0;
                ltype = 0;
            }

            let dPrice = Math.abs(price);
            let dePrice = Math.abs(eprice);
            let drPrice = Math.abs(rprice);
            let dtPrice = Math.abs(t0price);
            let dt1Price = Math.abs(t1price);
            let dt2Price = Math.abs(t2price);
            let dt3Price = Math.abs(t3price);
            let dexdPrice = Math.abs(exdprice);

            var eLine =
            {
                Type: ltype,
                Label: ltype === 0 && dPrice > 0 ? ExtremeDataValue.showChartPrices(price) : ycou === 0 ? price : "",
                eLabel: ltype === 0 && dePrice > 0 ? ExtremeDataValue.abbreviateFinancialValue(eprice, 1) : ycou === 0 ? eprice : "",
                rLabel: ltype === 0 && drPrice > 0 ? ExtremeDataValue.abbreviateFinancialValue(rprice, 1) : ycou === 0 ? rprice : "",
                tLabel: ltype === 0 && dtPrice > 0 ? ExtremeDataValue.abbreviateFinancialValue(t0price, 1) : ycou === 0 ? t0price : "",
                t1Label: ltype === 0 && dt1Price > 0 ? ExtremeDataValue.abbreviateFinancialValue(t1price, 1) : ycou === 0 ? t1price : "",
                t2Label: ltype === 0 && dt2Price > 0 ? ExtremeDataValue.abbreviateFinancialValue(t2price, 1) : ycou === 0 ? t2price : "",
                t3Label: ltype === 0 && dt3Price > 0 ? ExtremeDataValue.abbreviateFinancialValue(t3price, 1) : ycou === 0 ? t3price : "",
                exdLabel: ltype === 0 && dexdPrice > 0 ? ExtremeDataValue.abbreviateFinancialValue(exdprice, 1) : ycou === 0 ? exdprice : "",
                YAxis: y
            };

            if (y > 20) {
                this.mhLines.push(eLine);
                if (ltype === 0)
                    this.solidLine.push(eLine);
            }
        }
    }

}
export default WArithmaticScale;

