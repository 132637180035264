import FormatterUtil from "FormatterUtil";

export default class CellItem {
  Format(header, data, isInternational = false) {
    const value = data.Value;
    if (header.IsPerc) {
      this.Value = this.GetPercentageTextFormat(value, isInternational, header.DigitAfterDecimals);
      if (!data.IsActual && this.Value) {
        this.Value += "e";
      }
    }
    else {
      this.Value = this.GetCellFormat(value, header.DigitAfterDecimals);
      if (header.HeaderId !== 9 && header.HeaderId !== 8) {
        if (!data.IsActual && this.Value) {
          this.Value += "e";
        }
      }
    }
  }

  GetPercentageTextFormat(value, isInternational, digitsAfterDecimal) {
    if (value === undefined || value === null) {
      return "";
    }
    const formatVal = FormatterUtil.formatNumber(value, digitsAfterDecimal);
    return (formatVal > 0) ? `+${formatVal}%` : `${formatVal}%`;
  }

  GetCellFormat(value, digitsAfterDecimal) {
    if (value === undefined || value === null) {
      return "";
    }
    return FormatterUtil.formatNumber(value, digitsAfterDecimal);
  }

  SetClass() {
    if (this.Value) {
      if (this.Value.includes("%")) {
        if (this.Value.substring(0, 1) === "-") {
          this.className = "negative";
          this.IsPositive = false;
        }
        else {
          this.className = "positive";
          this.IsPositive = true;
        }

      }
      else if (this.Value.includes("e")) {
        this.className = "estimate";
      }
      else {
        this.className = "default";
      }
    }
  }
}

