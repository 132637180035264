import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AddColumnToListRequest extends BaseServiceRequest {

    constructor(listId, dataItemId, lastColumnDataItemId, isFrozen, isQuoteboard = false) {
        super();
        super.addRequestUri("column.pb");
        super.addRequestParameter("l", listId.toString());
        super.addRequestParameter("di", dataItemId + ",,");
        if (lastColumnDataItemId)
        {
          super.addRequestParameter("afterID", lastColumnDataItemId.toString());
          super.addRequestParameter("if", isFrozen ? "1" : "0");
        }

        super.addRequestParameter("iqb", isQuoteboard ? "1" : "0");
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default AddColumnToListRequest;
