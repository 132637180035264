import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class DuplicateCustomMetric extends BaseServiceRequest {
    constructor(metricId, name) {
        super();
        super.addRequestUri("dupcustomcol.pb");
        super.addRequestParameter("dataitemid", metricId.toString());
        super.addRequestParameter("dupName", name);
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}

export default DuplicateCustomMetric;