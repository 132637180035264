import React from "react";
import ListStore from "ListStore";
import GridStore from "GridStore";
import MiniListStore from "MiniListStore";
import OwnershipViewStore from "OwnershipViewStore";
import Grid from "../../../../RayCustomControls/Grid/Grid";
import ColumnCustomFilterControl from "../../../../RayCustomControls/ListViewCustomFilter/ColumnCustomFilterControl.jsx";
import { OwnerShipActions, fetchMoreDataOnScroll, updateMiniList } from "../../../../Actions/OwnerShipActions"
import GridActions from "GridActions";
import ListManagerViewType from "ListManagerViewType";
import { ListExplorerConstants } from "ListExplorerConstants";
import { OwnerShipFetchDetails, OwnershipViewConstants } from "../../../../Constants/OwnershipConstants";
import ListExplorerStore from "ListExplorerStore";
import { GridConstants } from "GridConstants";
import { map } from "underscore";
import ContextMenuConstants from "ContextMenuConstants";
import ListManagerTabType from "ListManagerTabType";
import BrowserUtil from "BrowserUtil";
import StringUtil from "StringUtil";
import ErrorBoundary from "ErrorBoundary";
import SelectLimitWarningDialog from "../../../../RayCustomControls/SelectLimitWarningDialog";
import ExportProgressBar from "../../../../RayCustomControls/ExportListSettingsDialog/ExportProgressBar";
import BaseServiceApi from "../../../../ServiceApi/BaseServiceApi";
import { MiniListConstants } from "MiniListConstants";

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const SELECT_LIMIT_EXCEED_EVENT = "SELECT_LIMIT_EXCEED_EVENT"
export default class OwnershipListView extends React.Component {

  constructor(props) {
    super(props);
    this.getListCatalog = this.getListCatalog.bind(this);
    this.ownershipViewStoreStateChange = this.ownershipViewStoreStateChange.bind(this);
    this.listStoreStateChange = this.listStoreStateChange.bind(this);
    this.gridStoreStateChange = this.gridStoreStateChange.bind(this);
    this.symbolsSelectLimitExceed = this.symbolsSelectLimitExceed.bind(this);
    this.fetchMoreDataOnScroll = this.fetchMoreDataOnScroll.bind(this);
    this.addColumnSetWidth = this.addColumnSetWidth.bind(this);
    this.addColumnToList = this.addColumnToList.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.fetchBasicFilterData = this.fetchBasicFilterData.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.resetListCatelogData = this.resetListCatelogData.bind(this);
    this.removeColumnToList = this.removeColumnToList.bind(this);
    this.handleSplitterClick = this.handleSplitterClick.bind(this);
    this.onClearAllFilter = this.onClearAllFilter.bind(this);
    this.onHandleClickOnOff = this.onHandleClickOnOff.bind(this);
    this.state = {
      gridData: [],
      listId: this.props.listId,
      actualListId: this.props.actualListId,
      columns: [],
      totalItems: 0,
      basic: [],
      selectedView: ListManagerViewType.List,
      screenValue: OwnershipViewStore.getScreenOnOffValue(),
      screenUpdate: false,
      showModal: GridStore.getDisplayWarning(),
      limitExceedHeaderText: "COPY SYMBOLS", 
      limitExceedMessasge: "Only 10,000 symbols should be copied from the list.",
      isListChanged: false
    }
    this.listCatalog = {};
    this.pageListRows = {};
    this.labelLoading = [];
    this.keywordFilter = false;
    this.componentMounted = false;
    this.selectedSymbol = ListStore.getSelectedSymbol();
  }

  UNSAFE_componentWillMount() {
    OwnershipViewStore.addChangeListener(this.ownershipViewStoreStateChange);
    ListStore.addChangeListener(this.listStoreStateChange);
    GridStore.addChangeListener(this.gridStoreStateChange);
    GridStore.addChangeListener(this.symbolsSelectLimitExceed,SELECT_LIMIT_EXCEED_EVENT);
    this.resetListCatelogData();
  }

  componentWillUnmount() {
    this.componentMounted = false;
    OwnershipViewStore.removeChangeListener(this.ownershipViewStoreStateChange);
    ListStore.removeChangeListener(this.listStoreStateChange);
    GridStore.removeChangeListener(this.gridStoreStateChange);
    GridStore.removeChangeListener(this.symbolsSelectLimitExceed,SELECT_LIMIT_EXCEED_EVENT);
  }

  componentDidMount(){
    this.componentMounted = true;
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      listId: props.listId,
      actualListId: props.actualListId
    });
  }

  resetListCatelogData() {
    const ucf = (OwnershipViewStore.getScreenOnOffValue()) ? 1 : 0;
    this.listCatalog = {
      listId: null,
      actualListId: null,
      searchText: '',
      sortCols: '',
      needColumnsInfo: 1,
      addlCatalogRequests: null,
      useColFilters: ucf,
      saveColFilters: 0,
      customerOrder: 0,
      colsetId: '-1',
      pageIndex: 0,
      size: 250,
      isQuoteboard: 0,
      msId: null,
      cacheId: -1,
      reorderIds: 0,
      isUserList: 0,
      isMinilist: 0
    }
  }

  fetchMoreDataOnScroll(sequence) {
    let listcatlogForScroll = { ...this.listCatalog }    
    listcatlogForScroll = {
      ...listcatlogForScroll, sortCols: null, pageIndex: sequence, needColumnsInfo: 1, saveColFilters: 0, customerOrder: 0,
      addlCatalogRequests: null, colsetId: '-1', cacheId: OwnershipViewStore.getEsBasicCacheId()
    }
    this.keywordFilter = false;
    fetchMoreDataOnScroll(listcatlogForScroll);
  }

  getListCatalog(listId, actualListId, listName, pageIndex = 0, cacheId = -1, isUserList = 0) {
    if (listId && actualListId) {
      if (this.listCatalog.listId !== listId) {
        this.listCatalog.searchText = '';
        OwnershipViewStore.clearSourecData();
        this.setState({
          columns: [],
          gridData: []
        });
      }                
      const isNotImportedList = OwnershipViewStore.activeNode && OwnershipViewStore.activeNode.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory ? true : false;         
      this.listCatalog = {
        ...this.listCatalog, listId: listId, actualListId: actualListId, isUserList: isUserList,
        listName: listName, pageIndex: pageIndex, cacheId: cacheId !== -1 ? cacheId : 0, isNotImportedList: isNotImportedList
      }
      this.listCatalog.useColFilters = OwnershipViewStore.getScreenOnOffValue()? 1:0;
      ListExplorerStore.activeSybol.symbol = undefined;
      ListExplorerStore.activeSybol.msId = undefined;
      OwnershipViewStore.listCatalog = this.listCatalog;
      //OwnershipViewStore.initialCall = true;
      OwnershipViewStore.initialCall = false;
      OwnerShipActions(this.listCatalog)
    }
  }

  addColumnSetWidth(listId, colSeq, quoteBoard) {
    BrowserUtil.disableEvents();
    GridActions.addColumnSetWidth(listId, colSeq, quoteBoard);
  }

  addColumnToList(listId, dataItemId, previousColumnDataItemId, isFrozen, isQuoteboard) {
    GridActions.addColumnToList(GridConstants.ActionTypes.ADD_COLUMN_TO_LIST, listId, dataItemId, previousColumnDataItemId, isFrozen, isQuoteboard);
  }

  removeColumnToList(listId, dataItemId, isQuoteboard, isFrozen, previousColumnDataItemId) {
    BrowserUtil.disableEvents();
    GridActions.removeColumnToList(listId, dataItemId, isQuoteboard, isFrozen, previousColumnDataItemId);
  }

  handleSplitterClick(isSplit) {
    GridActions.splitFrozenColumns(isSplit);
  }

  handleColumnVisualization(listId, dataItemId, quoteBoard) {    
    const column = OwnershipViewStore.findColumn(dataItemId);
    if (column) {      
      const colSeq = `${dataItemId},${(column.isVisualizationOn ? 0 : 1)}`;
      GridActions.handleColumnVisualization(listId, colSeq, quoteBoard);
    }
  }

  handleColumnSort(e, colData) {
    OwnershipViewStore.updateSortColums(colData, e);
    this.listCatalog.sortCols = OwnershipViewStore.getSortColumns();
    this.listCatalog.isUserList = OwnershipViewStore.activeNode && OwnershipViewStore.activeNode.categoryType === ListCategoryType.USER_ListCategory ? 1 : 0;
    this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
  }

  handleCustomOrder() {
    const sourceData = OwnershipViewStore.getSourceData();       
    let spIdsWithIndex = [];
    map(sourceData, (item, index) => {
      spIdsWithIndex=[...spIdsWithIndex,`${item.sponsorId}:${(index + 1)}`];
    })    
    const ReqProto = BaseServiceApi.rayData["ListCatalogRequestData"];
    const listCatalogRequestData = new ReqProto();
    listCatalogRequestData.ordSeqDetails = spIdsWithIndex.join(';');
    this.listCatalog = { ...this.listCatalog, saveColFilters: 0, addlCatalogRequests: listCatalogRequestData, customerOrder: 1, needColumnsInfo: 1, sortCols: [], cacheId: -1 }
    const setNewCutomOrderPromise = OwnershipViewStore.setNewCustomOrder(this.listCatalog.listId);
    setNewCutomOrderPromise.then((sortItem) => {
      this.listCatalog.sortCols = sortItem;
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
    })
  }

  updateMiniList() {
    const listState = ListStore.getSelectedListItem();
    MiniListStore.setCurrentAction(MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE);
    MiniListStore.setMiniList(OwnershipViewStore.getSourceData(), listState);
  }

  resetListCatalog = () => {
    this.listCatalog = { ...this.listCatalog, customerOrder: 0, saveColFilters: 0 }    
    return;
  }

  ownershipViewStoreStateChange() {
    if(this.componentMounted){
    const currentAction = OwnershipViewStore.getCurrentAction();
    const state = OwnershipViewStore.getState();
    if (currentAction === OwnerShipFetchDetails.GET_OWNERSHIP_DATA || currentAction === OwnershipViewConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL_OWNERSHIP || currentAction === OwnershipViewConstants.ActionTypes.RELOAD_LIST_CATALOG_OWNERSHIP) {
      this.pageListRows.cacheId = state.basic.cacheId;
      this.resetListCatalog();
      this.listCatalog.colsetId = '-1';
      this.setState({
        totalItems: state.basic.total,
        basic: state.basic,
        cacheId: OwnershipViewStore.getEsBasicCacheId(),
        gridData: OwnershipViewStore.getSourceData(),
        columns: state.columns,
        isListChanged: true
      }, OwnershipViewStore.setMoredataLoaded());
      BrowserUtil.enableEvents();
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.GET_SEARCH_TEXT_OWNERSHIP) {
      this.listCatalog.searchText = state.searchKeyword;
      this.keywordFilter = true;
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
      OwnershipViewStore.clearCurrentAction();
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.SELECT_COLUMN_SET_OWNERSHIP) {
      this.listCatalog.colsetId = state.columnSetId;

      //Updating update date field in List property section in List explorer
      ListExplorerStore.findNodeByIdAndUpdateDate(this.props.listId, StringUtil.formatDate(new Date()));

      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.SCREEN_ON_OFF_OWNERSHIP) {
      const screenValue = OwnershipViewStore.getScreenOnOffValue();
      const ucf = screenValue ? 1 : 0;
      const columnFilters = OwnershipViewStore.getcolumnFilters();
      this.setState({ screenValue: screenValue });
      if (columnFilters.length > 0) {
        this.onHandleClickOnOff(ucf);
      }
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG_OWNERSHIP) {
      const screenValue = OwnershipViewStore.getScreenOnOffValue();
      this.resetListCatalog();
      this.setState({ screenValue: screenValue });
    }
    else if (currentAction === OwnershipViewConstants.ActionTypes.SAVE_SCREENED_RESULTS_OWNERSHIP) {
      OwnershipViewStore.clearCurrentAction();
    }
  }}

  listStoreStateChange() {
    const currentAction = ListStore.getCurrentAction();
    const currentColumnSetActions = ListStore.getCurrentColumnSetActions();
    const state = ListStore.getState();
    if (state.refreshGrid && (currentColumnSetActions === ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET || currentAction === ListExplorerConstants.ActionTypes.RESET_COLUMN_SET || currentAction === ListExplorerConstants.ActionTypes.DUPLICATE_COLUMN_SET || currentAction === ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET || currentAction === ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET_FOLDER)) {
      this.resetListCatalog();
      this.listCatalog.colsetId = '-1';
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
    }
  }

  symbolsSelectLimitExceed(){
    if( this.componentMounted && ListExplorerStore.getSelectedTabType() === ListManagerTabType.Ownership){
      const limitExceedType = GridStore.getLimitExceedType();
      let limitExceedHeaderText = '';
      let limitExceedMessasge = '';
      switch (limitExceedType) {
        case GridConstants.ActionTypes.COPY_GRID_ROWS:
          limitExceedHeaderText = "COPY SYMBOLS";
          limitExceedMessasge = "Only 250 symbols should be copied from the list.";
          break;
        case GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG:
          limitExceedHeaderText = "FLAG & UNFLAG SYMBOLS";
          limitExceedMessasge = "Only 10,000 symbols should be flagged/unflagged.";
          break;
        case GridConstants.ActionTypes.REMOVE_GRID_ROWS:
          limitExceedHeaderText = "REMOVE SYMBOLS";
          limitExceedMessasge = "Only 10,000 symbols should be removed from the list.";
          break;
        default:
          limitExceedHeaderText = "COPY SYMBOLS";
          limitExceedMessasge = "Only 250 symbols should be copied from the list.";
          break;
      }
      this.setState({ showModal: GridStore.getDisplayWarning(), limitExceedHeaderText: limitExceedHeaderText, limitExceedMessasge: limitExceedMessasge });
    }}

  gridStoreStateChange() {
    if(this.componentMounted){
    const currentAction = GridStore.getCurrentAction();
    const state = OwnershipViewStore.getState();

    if (currentAction === GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_FROM_METRIC || currentAction === GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_UNDO) {
      this.listCatalog.colsetId = '-1';
      if (currentAction === GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_UNDO) {
        OwnershipViewStore.undoColumnFilters();
        const addlRequestData = ColumnCustomFilterControl.getAddlRequests(true, true);        
        this.listCatalog = { ...this.listCatalog, addlCatalogRequests: addlRequestData, saveColFilters: 1, sortCols: OwnershipViewStore.getSortColumns() }
      }
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
    }
    else if (currentAction === GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST) {
      currentAction === GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST && GridStore.clearCurrentAction();
      this.listCatalog.colsetId = '-1';
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
      GridStore.clearGridBodyAction();
    }
    else if (currentAction === GridConstants.ActionTypes.AUTO_SIZE_ALL_COLUMNS_WIDTH) {
      GridStore.clearCurrentAction();
      this.grid.onAutoSizeAllColumnsWidthClick();
    }
    else if (currentAction === GridConstants.ActionTypes.SPLIT_FROZEN_COLUMNS) {
      this.setState({
        columns: state.columns
      });
    }
    else if (currentAction === GridConstants.ActionTypes.ADD_COLUMN_TO_LIST) {
      this.setState({
        gridData: OwnershipViewStore.getSourceData(),
        columns: state.columns,
        cacheId: OwnershipViewStore.getEsBasicCacheId(),
        shouldUpdateStreamingData: true
      });
    }

    if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.UPDATE_COL_SET_WIDTH || GridStore.getGridBodyAction() === GridConstants.ActionTypes.VISUALIZE_GRID_COLUMN) {
      BrowserUtil.enableEvents();
      GridStore.clearGridBodyAction();
      this.setState({
        basic: state.basic,
        columns: state.columns,
        cacheId: OwnershipViewStore.getEsBasicCacheId()
      });
    }
    else if (GridStore.getGridBodyAction() === ContextMenuConstants.FLAG_ALL_SYMBOLS) {
      BrowserUtil.enableEvents();
      OwnershipViewStore.updateAllFlagIn(false);
      GridStore.clearGridBodyAction();
      this.setState({
        gridData: OwnershipViewStore.getSourceData(),
        cacheId: OwnershipViewStore.getEsBasicCacheId()
      });
    }
    else if (GridStore.getGridBodyAction() === ContextMenuConstants.REMOVE_ALL_FLAGS) {
      BrowserUtil.enableEvents();
      OwnershipViewStore.updateAllFlagIn(true);
      GridStore.clearGridBodyAction();
      this.setState({
        gridData: OwnershipViewStore.getSourceData(),
        cacheId: OwnershipViewStore.getEsBasicCacheId()
      });
    }
    else if (GridStore.getGridBodyAction() === ContextMenuConstants.ROW_FLAG) {
      BrowserUtil.enableEvents();
      OwnershipViewStore.updateFlagIn(false);
      GridStore.clearGridBodyAction();
      this.setState({
        gridData: OwnershipViewStore.getSourceData(),
        cacheId: OwnershipViewStore.getEsBasicCacheId()
      });
    }
    else if (GridStore.getGridBodyAction() === ContextMenuConstants.ROW_REMOVE_FLAG) {
      BrowserUtil.enableEvents();
      OwnershipViewStore.updateFlagIn(true);
      GridStore.clearGridBodyAction();
      this.setState({
        gridData: OwnershipViewStore.getSourceData(),
      });
    }
    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.REMOVE_GRID_ROWS || GridStore.getGridBodyAction() === GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO) {
      this.updateMiniList();
      this.setState({
        totalItems: state.basic.total,
        cacheId: OwnershipViewStore.getEsBasicCacheId(),
        gridData: OwnershipViewStore.getSourceData()
      });
    }
    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.PASTE_GRID_ROWS) {      
      if (GridStore.isRefreshGrid()) {
        this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
        BrowserUtil.enableEvents();
        GridStore.clearGridBodyAction();
        GridStore.clearCurrentAction();
        GridStore.clearCopyValue();
      }
    }

    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.PASTE_GRID_ROWS_UNDO) {
      if (GridStore.isRefreshGrid()) {
        this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, false, this.listCatalog.isUserList);
        BrowserUtil.enableEvents();
        GridStore.clearGridBodyAction();
        GridStore.clearCurrentAction();
      }
    }
    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.DROP_IN_LIST_MANAGER) {
      this.updateMiniList();
      this.setState({
        cacheId: OwnershipViewStore.getEsBasicCacheId(),
        gridData: OwnershipViewStore.getSourceData()
      });
    }

    else if (GridStore.getGridBodyAction() === GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER) {
      // if (GridStore.isLoadDataIfNORowReturn())
      if (GridStore.isRefreshGrid()) {
        this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, false, this.listCatalog.isUserList);
      }
      // else {
      //   this.updateMiniList();
      //   this.setState({
      //     basic: state.basic,
      //     gridData: OwnershipViewStore.getSourceData()
      //   });
      // }
    }
  }}

  fetchBasicFilterData(labelLoading) {
    this.labelLoading = labelLoading;
    this.keywordFilter = true;
    this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
  }

  applyFilter(addlCatalogRequests) {    
    this.listCatalog = { ...this.listCatalog, addlCatalogRequests: addlCatalogRequests, saveColFilters: 1 }
    if (!OwnershipViewStore.getScreenOnOffValue()) {
      this.listCatalog.useColFilters = 0;
      OwnerShipActions(this.listCatalog);
    }
    else {
      this.listCatalog.useColFilters = 1; // manually setting useColFilters to 1 for Screen on condition
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
    }
  }

  onClearAllFilter(addlCatalogRequests) {
    if (!OwnershipViewStore.getScreenOnOffValue()) {
      this.setState({ screenUpdate: true });
    }
    this.applyFilter(addlCatalogRequests);
  }

  onHandleClickOnOff(ucf) {    
    this.listCatalog = { ...this.listCatalog, addlCatalogRequests: null, useColFilters: ucf, saveColFilters: 0 }
    this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true, this.listCatalog.isUserList);
  }

  plotSymbol(sym) {
    ListStore.plotSymbol(sym);
    updateMiniList(sym);
  }

  closeModal() {
    GridActions.CloseSymbolsSelectExceedWarningDialog();
  }

  render() {
    return (
      <div ref={(ref) => { this.main = ref; }} className="main-list-view medium-normal">{this.props.loading}
        <div className="list-wrap">
          <div className="list-grid-wrap">
            <div className="group-filter medium-normal">
              <ExportProgressBar />
              <div className="h27"></div>
            </div>
            <div id="colResizeSeparator" className="col-resize-separator" ref={(div) => { this.columnResizeSeparator = div }}></div>
            <ErrorBoundary>
            {this.state.showModal && <SelectLimitWarningDialog showModal={this.state.showModal} headerText={this.state.limitExceedHeaderText} messasge={this.state.limitExceedMessasge} closeModal={this.closeModal} />}
              <Grid ref={(g) => { this.grid = g; }}
                actualListId={this.state.actualListId}
                onPlotSymbol={(sym) => this.plotSymbol(sym)}
                source={this.state.gridData}
                columns={this.state.columns}
                itemHeight="26"
                pageSize={this.state.basic.count}
                fetchMoreDataOnScroll={this.fetchMoreDataOnScroll}
                itemsCount={this.state.gridData.length}
                keywordFilter={this.keywordFilter}
                selectedListId={this.state.listId}
                selectedListFav={this.listCatalog.listId}
                loading={this.props.loading}
                columnResizeSeparator={this.columnResizeSeparator}
                selectedView={this.state.selectedView}
                addColumnSetWidth={this.addColumnSetWidth}
                addColumnToList={this.addColumnToList}
                selectedSymbol={this.selectedSymbol}
                handleColumnSort={this.handleColumnSort}
                onGridColumnContextMenuClick={this.props.onGridColumnContextMenuClick}
                onGridCustomFilterContextMenuClick={this.props.onGridCustomFilterContextMenuClick}
                onGridRowContextMenuClick={this.props.onGridRowContextMenuClick}
                updateGlobalFlag={this.props.updateGlobalFlag}
                handleSplitterClick={this.handleSplitterClick}
                applyFilter={this.applyFilter}
                totalItems={this.state.totalItems}
                onClearAllFilter={this.onClearAllFilter}
                screenValue={this.state.screenValue}
                listName={this.props.listName}
                cacheId={this.state.basic.cacheId}
                onGridRowContextMenuItemClick={this.props.onGridRowContextMenuItemClick}
                fontSizeClass={this.props.fontSizeClass}
                isListChanged={this.state.isListChanged}
                shouldUpdateStreamingData={this.state.shouldUpdateStreamingData}
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );
  }
}
