import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter } from "../CustomBootstrapModal";
import ModelStyleConstants from "../../Constants/ModelStyleConstants.js";
import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions.js";
import LocalizationStore from 'LocalizationStore';
export default class CommonDialog extends Component {
    constructor(props) {
        super(props); 
        this.backToParentWindow = this.backToParentWindow.bind(this);
    }

    backToParentWindow() {
        if (this.props.parentEventKey) {
            MenuCommonDialogActions.openMenuCommonDialog({ eventKey: this.props.parentEventKey });
        }
    }

    getBackButton(backButtonShow) {
        let backButton = <span  className="icn-back-btn" id="backButton" onClick={this.backToParentWindow}>{LocalizationStore.getTranslatedData('back', "Back")}</span>;
        if (backButtonShow) {
            return backButton;
        }
    }

    getCancelButton(cancelButtonShow) {
        let cancelButton = <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.hideModal}>{LocalizationStore.getTranslatedData('cancel', "CANCEL")}</button>;
        if (cancelButtonShow) {
            return cancelButton;
        }
    }

    getSubmitButton(submitButtonShow){
        let submitButton = <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.submitHandler}> {this.props.submitButtonText ? this.props.submitButtonText : LocalizationStore.getTranslatedData('ok', "OK") }</button>;
        if (submitButtonShow) {
            return submitButton;
        }
    }


    render() {
        const backdropStyles = ModelStyleConstants.MenuCommonDialogBackdropStyles;
        const dialogStyles = ModelStyleConstants.MenuCommonDialogDialogStyles;
        let backButton = this.getBackButton(this.props.backButtonShow);
        let cancelButton = this.getCancelButton(this.props.cancelButtonShow);
        let submitButton = this.getSubmitButton(this.props.submitButtonShow);
        const firstButton = this.props.submitButtonOnRight ? submitButton : cancelButton;
        const secondButton = this.props.submitButtonOnRight ? cancelButton : submitButton;
        const openDialogClass = this.props.isOpen ? "dialog-open" : ""
        return (
            <Modal className={`${this.props.preClass} modal-popup ${openDialogClass}`}
                isOpen={this.props.isOpen} dialogStyles={dialogStyles} backdropStyles={backdropStyles}>
                <ModalHeader>
                    <ModalTitle><span className="cap-header">{this.props.title}</span></ModalTitle>
                    {/*<ModalClose  onClick={this.props.hideModal} />*/}
                    <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.props.hideModal} ><span className="icn-close"></span></button>
                    {backButton}
                </ModalHeader>
                <ModalBody>
                    <div className="modal-region">
                        {this.props.children}
                        <div className="clearfix"></div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <span className="btn-box">
                        {secondButton}
                        {firstButton}
                    </span>
                </ModalFooter>
            </Modal>
        );
    }
}

CommonDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func
};