import BaseServiceApi from 'BaseServiceApi';
import { calculateIdeaPriceChart } from '../../../Actions/PricePanelActions';
import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import DatagraphHelper from "../../../Utils/DatagraphHelper";
import ExtremeDataValue from '../../../Utils/ExtremeDataValue';
import GraphApi from '../../../ServiceApi/Apis/GraphApi'
import { IdeaName } from '../../../Utils/TranslateHelper';
import LocalizationStore from "LocalizationStore";
import MiniListHelper from "MiniListHelper";
import MiniListStore from '../../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore';
import moment from "moment";
import OneilApi from '../../../ServiceApi/Apis/OneilApi';
import PeriodicityHelper from "PeriodicityHelper";
import { riPanelInfoState } from '../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors';
import { RIPanelOneilConstants } from "../../../Constants/RIPanelOneilConstants";
import StringUtil from 'StringUtil';
import UserInfoUtil from 'UserInfoUtil'
import { getDatagraphStates, priceChartReducerselect, RiPanelOneil ,IdeasSelect} from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { takeLatest, call, put, fork, select } from 'redux-saga/effects';
import datagraphStore from '../../../Stores/NavModules/NavDataGraph/DataGraphStore';

const { ActionTypes } = RIPanelOneilConstants;
const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
const IdeaType = BaseServiceApi.rayData["IdeaType"];
const IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];

export function* watchGetOneilRiPanelData() {
    yield takeLatest(ActionTypes.GET_RI_PANEL_ONEIL_INFO, populateRIPanelOneilInfo);
}

export function* watchHandleEntlitment() {
    yield takeLatest(ActionTypes.HANDLE_ENTITLEMENT, handleEntitlement);
}

export function* watchRecieveQuote() {
    yield takeLatest(ActionTypes.RECIEVE_QUOTE, updateRiPanelFocusList);
}
export function* watchGetFocusListData() {
    yield takeLatest(ActionTypes.GET_ONEIL_FOCUS_LIST_DATA, getFocusListData )
}

function* getResearchUrl() {
    if (UserInfoUtil.getUserInfo()) {
        const result = yield call(GraphApi.GetLiveStreamUrlRequest, IntradaySourceType.IntradaySource_NASDAQ);
        return result.liveStreamRequestData.researchPageUrl;
    }
}

function* populateRIPanelOneilInfo() {
    try {
        const { researchAnalystList } = yield select(RiPanelOneil)
        if(researchAnalystList.length === 0){
            const { periodicity, SymbolInfo, SymbolType, viewsSettings } = yield select(getDatagraphStates);
            const { riPanelViewSettings } = yield select(riPanelInfoState);
            const symbol = SymbolInfo ? SymbolInfo.Symbol : '';
            const isMiniListPlay = MiniListStore.getMiniListPlayingStatus();
            const isActiveSymbol = MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay);
            if (isActiveSymbol) {
                const result = yield call(GraphApi.GetOneilResearchAnalystRequest, SymbolInfo.Osid);
                yield put({
                    type: ActionTypes.GET_RESEARCH_ANALYSTLIST_INFO,
                    researchAnalystList: result.analystInfoList,
                    OneilListSettings: riPanelViewSettings.OneilListSettings
                });
            }
            let researchDataList = {};
            let researchData;
    
            yield fork(populateFocusList, SymbolInfo, SymbolType, periodicity);
            yield put({
                type: ActionTypes.UPDATE_RI_HISTORIC_FLAG_STATUS,
                showHistorical: viewsSettings?.LLRSettings?.ShowHistorical
            });
            const encriptMail = encriptEmail(UserInfoUtil.getUserInfo().userEmail);
            const currentEntitlementInfo = populateFlagsDataObject(riPanelViewSettings);
            yield put({
                type: ActionTypes.GET_CURRENT_ENTITLEMENT_INFO,
                currentEntitlementInfo: currentEntitlementInfo
            });
            let researchPageUrl = yield call(getResearchUrl);
            researchPageUrl = researchPageUrl.replace("/research", "/");
            const tokenId = JSON.parse(JSON.parse(yield call(OneilApi.GetOneilLoginApi, encriptMail, researchPageUrl))).tokenid;
            if (tokenId && isActiveSymbol) {
                researchData = JSON.parse(yield call(OneilApi.GetResearchData, SymbolInfo.Symbol, tokenId, researchPageUrl));
            }
            if (isActiveSymbol) {
                researchDataList = JSON.parse(researchData).posts;
                yield put({
                    type: ActionTypes.GET_RESEARCH_ONEIL_INFO,
                    researchList: researchDataList,
                    researchUrl: researchPageUrl,
                });
        }
        }
    } catch (error) {
        console.log(`Error occurs in  ripanelOneilData.js, populateRIPanelOneilInfo ${error}`);
    }
}

function* populateFocusList(SymbolInfo, SymbolType, Periodicity) {
    try {
        if (SymbolInfo === undefined) {
            return;
        }
        const dgState = yield select(getDatagraphStates);
        const endDate = SymbolInfo.LastTradeDate ? moment(SymbolInfo.LastTradeDate)  : dgState.endDate;
        const startDate = new Date(endDate);
        startDate.setFullYear(startDate.getFullYear() - 10);
        const strStartDate = moment(startDate).format('YYYYMMDD');
        const strEndDate = moment(endDate).format('YYYYMMDD');
        const periodicity = PeriodicityHelper.convertToPeriodicity(Periodicity);

        const SymbolData = yield call(GraphApi.GetGraphDataRequest, SymbolInfo.Symbol, SymbolInfo.MsId, periodicity, strEndDate, strStartDate);
        if (SymbolData) {
            const ideasData = yield call(GraphApi.GetAllIdeasRequest, SymbolInfo.Symbol, SymbolInfo.MsId, periodicity, strEndDate, strStartDate, SymbolData.data.header.userhasXfl, datagraphStore.getCurrencyCode(), SymbolInfo.Osid);
            yield put({
                type: DataGraphConstants.ActionTypes.UPDATE_ONEIL_IDEAS_INFO,
                ideasData
            });
            yield call(updateRiPanelFocusList, { ideasData, SymbolInfo});
        }
    }
    catch (e) {
        console.log(`Error occurs in  ripanelOneilData.js, populateFocusList ${e}`);
    }
}

function* updateRiPanelFocusList({ ideasData, SymbolInfo }) {
    try {
        if (SymbolInfo === undefined) {
            return;
        }
        const endDate = moment(SymbolInfo.LastTradeDate);
        if (ideasData.ideas) {
            const { HiLowPoints } = yield select(priceChartReducerselect);
            const focusList = populateCurrentFocusList(ideasData.ideas.currentIdeas, Number(ideasData.ideas.xflType), endDate, HiLowPoints);
            const historicalIdeas = populateHistoricalIdeas(ideasData.ideas.historicalIdeas, endDate, HiLowPoints);
            yield put({
                type: ActionTypes.GET_FOCUS_LIST_INFO,
                focusList: focusList,
                historicalList: historicalIdeas
            });
            yield put(calculateIdeaPriceChart());
        }
    } catch (e) {
        console.log(`Error occurs in  ripanelOneilData.js, updateRiPanelFocusList ${e}`);
    }
}

function* getFocusListData() {
    const { SymbolInfo } = yield select(getDatagraphStates);
    const ideasData = yield select(IdeasSelect);
    yield call(updateRiPanelFocusList, { ideasData, SymbolInfo });
}

function populateCurrentFocusList(focuslistData, xflType, endDate, HiLowPoints) {
    const focusList = [];
    for (let i = 0; i < focuslistData.length; i++) {
        /** This is has been discontinued */
        if (!(focuslistData[i].ideaType === IdeaType.ALLCAPS_LAGGARD_Idea || focuslistData[i].ideaType === IdeaType.ALLCAPS_LEADER_Idea || focuslistData[i].ideaType === IdeaType.LARGECAP_LEADER_Idea || focuslistData[i].ideaType === IdeaType.LARGECAP_LAGGARD_Idea)) {
            const percObj = calculateIdeaPerc(focuslistData[i], HiLowPoints);
            focusList.push({
                ideaType: focuslistData[i].ideaType,
                endDate: endDate,
                inDt: new Date(Number(focuslistData[i].inDt)).toLocaleDateString(),
                outDt: focuslistData[i].outDt ? new Date(Number(focuslistData[i].outDt)).toLocaleDateString() : new Date(endDate).toLocaleDateString(),
                numWks: "",
                percChg: percObj.PercChg,
                isPercNeg: percObj.IsPercNeg,
                ideaName: IdeaName[focuslistData[i].ideaType] || "",
                isPdfDataAvailable: !StringUtil.isEmpty(focuslistData[i].researchPDFPath),
                pdfResearchPath: focuslistData[i].researchPDFPath ? focuslistData[i].researchPDFPath : "",
                isVideoUrlAvailable: !StringUtil.isEmpty(focuslistData[i].researchVideo),
                videoResearchPath: focuslistData[i].researchVideo,
                fieldVisible: true
            });
        }
    }
    if (xflType === 1) {
        focusList.push({
            ideaType: IdeaType.NONE,
            ideaName: "Exclusive Long Idea",
            fieldVisible: true,
            isPdfDataAvailable: !StringUtil.isEmpty(focuslistData.pdfFilename),
            pdfResearchPath: focuslistData.pdfFilename ? focuslistData.pdfFilename : ""
        });
    }
    if (xflType === 2) {
        focusList.push({
            ideaType: IdeaType.NONE,
            ideaName: "Exclusive Short Idea",
            fieldVisible: true,
            isPdfDataAvailable: !StringUtil.isEmpty(focuslistData.pdfFilename),
            pdfResearchPath: focuslistData.pdfFilename ? focuslistData.pdfFilename : ""
        });
    }

    return focusList;
}

function calculateIdeaPerc(idea, HiLowPoints) {
    let percObj = {};
    let perc = idea.outPriceSplitAdj ?
        ((idea.outPriceSplitAdj - idea.inPriceSplitAdj) / idea.inPriceSplitAdj) * 100 :
        ((idea.outDtClosingActualPrice - idea.inPriceSplitAdj) / idea.inPriceSplitAdj) * 100;
    if (HiLowPoints && idea.outDt === null) {
        const lastPrice = HiLowPoints && HiLowPoints.allPoints && HiLowPoints.allPoints[0] && HiLowPoints.allPoints[0].graphData && HiLowPoints.allPoints[0].graphData.Close ?
            HiLowPoints.allPoints[0].graphData.Close :
            idea.outPriceSplitAdj ? idea.outPriceSplitAdj : idea.outDtClosingActualPrice;
        perc = ((lastPrice - idea.inPriceSplitAdj) / idea.inPriceSplitAdj) * 100;
    }
    if (idea.ideaType === IdeaType.USA_SHORT_Idea ||
        idea.ideaType === IdeaType.EMERGING_SHORT_Idea ||
        idea.ideaType === IdeaType.FRONTIER_SHORT_Idea ||
        idea.ideaType === IdeaType.GFL_EUROPE_SHORT_Idea ||
        idea.IdeaType === IdeaType.DEVELOPED_SHORT_Idea) {
        percObj = {
            PercChg: perc < 0 ?
                StringUtil.format("+{0}%", [chgS(perc, false)]) :
                StringUtil.format("-{0}%", [chgS(perc, false)]),
            IsPercNeg: perc >= 0
        }

    } else {
        percObj = {
            PercChg: perc >= 0 ?
                StringUtil.format("+{0}%", [chgS(perc, false)]) :
                StringUtil.format("-{0}%", [chgS(perc, false)]),
            IsPercNeg: perc < 0
        }
    }

    return percObj
}

function chgS(chg, isPrice = true) {
    let price;
    price = Math.abs(chg);

    const round = price < 1.0 && isPrice ? 3 : 2;

    price = parseFloat(price.toFixed(round));

    const chgS = price > 999999 ?
        ExtremeDataValue.abbreviateValue(price, 2) :
        (price > 99999 ?
            Math.round(price) :
            (price < 1.0 && isPrice ?
                price.toFixed(3) :
                price.toFixed(2)));

    return chgS;
}

function encriptEmail(email) {
    if (!email) {
        return;
    }
    const max = 4;
    const min = 1;
    const cst = 64;
    const length = email.length;
    const char = [];
    char.length = length + 1;
    const rnd = min + Math.floor(Math.random() * ((max - min) + 1));
    for (let ipos = 0; ipos < length; ipos++) {
        char[ipos] = String.fromCharCode(email[ipos].charCodeAt() + rnd);
    }
    char[length] = String.fromCharCode(rnd + cst);
    return encodeURI(char.join(""));
}

function populateHistoricalIdeas(ideaList, endDate, HiLowPoints) {
    const historicalIdeasList = [];
    for (let i = 0; i < ideaList.length; i++) {
        /** This is has been discontinued */
        if (!(ideaList[i].ideaType === IdeaType.ALLCAPS_LAGGARD_Idea || ideaList[i].ideaType === IdeaType.ALLCAPS_LEADER_Idea || ideaList[i].ideaType === IdeaType.LARGECAP_LEADER_Idea || ideaList[i].ideaType === IdeaType.LARGECAP_LAGGARD_Idea)) {
            const percObj = calculateIdeaPerc(ideaList[i], HiLowPoints);
            historicalIdeasList.push({
                ideaType: ideaList[i].ideaType,
                endDate: endDate,
                inDt: new Date(Number(ideaList[i].inDt)).toLocaleDateString(),
                outDt: ideaList[i].outDt ? new Date(Number(ideaList[i].outDt)).toLocaleDateString() : new Date(endDate).toLocaleDateString(),
                numWks: "",
                percChg: percObj.PercChg,
                isPercNeg: percObj.IsPercNeg,
                ideaName: IdeaName[ideaList[i].ideaType] || "",
                isPdfDataAvailable: !StringUtil.isEmpty(ideaList[i].researchPDFPath),
                pdfResearchPath: ideaList[i].researchPDFPath ? ideaList[i].researchPDFPath : "",
                isVideoUrlAvailable: !StringUtil.isEmpty(ideaList[i].researchVideo),
                videoResearchPath: ideaList[i].researchVideo,
                fieldVisible: true
            });
        }
    }
    return historicalIdeasList;
}

function populateFlagsDataObject(riPanelViewSettings) {
    const currentEntitlementList = [];
    const ideaTypes = riPanelViewSettings.OneilListSettings.EntitledIdeaTypes;
    if (!StringUtil.isEmpty(ideaTypes)) {
        const ideaEntitlement = {
            IdeaName: "United States",
            LeaderTitle: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_USA_LONG_Entitlement) ? LocalizationStore.getTranslatedData("ri_id90_1", "Long") : null,
            LaggardTitle: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_USA_SHORT_Entitlement) ? LocalizationStore.getTranslatedData("ri_id90_2", "Short") : null,
            LeaderIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_USA_LONG_Entitlement) ? IdeaType.USA_LONG_Idea : IdeaType.NONE,
            LaggardIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_USA_SHORT_Entitlement) ? IdeaType.USA_SHORT_Idea : IdeaType.NONE
        };
        ideaEntitlement.IsCheckedLeaderFlag = ideaTypes.includes(ideaEntitlement.LeaderIdeaType);
        ideaEntitlement.IsCheckedLaggardFlag = ideaTypes.includes(ideaEntitlement.LaggardIdeaType);

        currentEntitlementList.push(ideaEntitlement);
        /** This is has been discontinued */
        // if (UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_ALLCAPS_LEADER_Entitlement) ||
        //     UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_ALLCAPS_LAGGARD_Entitlement)) {
        //     let userEntitlement = {
        //         IdeaName: "All Cap",
        //         LeaderIdeaType: UserInfoUtil.IsUserEntitled(
        //                 EntitlementType.IDEAS_ALLCAPS_LEADER_Entitlement) ?
        //             IdeaType.ALLCAPS_LEADER_Idea :
        //             IdeaType.NONE,
        //         LeaderTitle: UserInfoUtil.IsUserEntitled(
        //                 EntitlementType.IDEAS_ALLCAPS_LEADER_Entitlement) ?
        //             "Leader" :
        //             null,
        //         LaggardTitle: UserInfoUtil.IsUserEntitled(
        //                 EntitlementType.IDEAS_ALLCAPS_LAGGARD_Entitlement) ?
        //             "Laggard" :
        //             null,
        //         LaggardIdeaType: UserInfoUtil.IsUserEntitled(
        //                 EntitlementType.IDEAS_ALLCAPS_LAGGARD_Entitlement) ?
        //             IdeaType.ALLCAPS_LAGGARD_Idea :
        //             IdeaType.NONE
        //     };
        //     userEntitlement.IsCheckedLeaderFlag = ideaTypes.includes(userEntitlement.LeaderIdeaType);
        //     userEntitlement.IsCheckedLaggardFlag = ideaTypes.includes(userEntitlement.LaggardIdeaType);

        //     currentEntitlementList.push(userEntitlement);
        // }

        // if (UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_LARGECAP_LEADER_Entitlement) ||
        //     UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_LARGECAP_LAGGARD_Entitlement)) {
        //     let userEntitlement = {
        //         IdeaName: "Large Cap",
        //         LeaderTitle: UserInfoUtil.IsUserEntitled(
        //                 EntitlementType.IDEAS_LARGECAP_LEADER_Entitlement) ?
        //             "Leader" :
        //             null,
        //         LaggardTitle: UserInfoUtil.IsUserEntitled(
        //                 EntitlementType.IDEAS_LARGECAP_LAGGARD_Entitlement) ?
        //             "Laggard" :
        //             null,
        //         LeaderIdeaType: UserInfoUtil.IsUserEntitled(
        //                 EntitlementType.IDEAS_LARGECAP_LEADER_Entitlement) ?
        //             IdeaType.LARGECAP_LEADER_Idea :
        //             IdeaType.NONE,
        //         LaggardIdeaType: UserInfoUtil.IsUserEntitled(
        //                 EntitlementType.IDEAS_LARGECAP_LAGGARD_Entitlement) ?
        //             IdeaType.LARGECAP_LAGGARD_Idea :
        //             IdeaType.NONE
        //     };
        //     userEntitlement.IsCheckedLeaderFlag = ideaTypes.includes(userEntitlement.LeaderIdeaType);
        //     userEntitlement.IsCheckedLaggardFlag = ideaTypes.includes(userEntitlement.LaggardIdeaType);

        //     currentEntitlementList.push(userEntitlement);
        // }

        const item = {
            IdeaName: "Developed Mkts",
            LeaderTitle: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_DEVELOPED_LONG_Entitlement) ? "Long" : null,
            LaggardTitle: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_DEVELOPED_SHORT_Entitlement) ? "Short" : null,
            LeaderIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_DEVELOPED_LONG_Entitlement) ? IdeaType.DEVELOPED_LONG_Idea : IdeaType.NONE,
            LaggardIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_DEVELOPED_SHORT_Entitlement) ? IdeaType.DEVELOPED_SHORT_Idea : IdeaType.NONE
        };
        item.IsCheckedLeaderFlag = ideaTypes.includes(item.LeaderIdeaType);
        item.IsCheckedLaggardFlag = ideaTypes.includes(item.LaggardIdeaType);

        currentEntitlementList.push(item);

        const entitlement = {
            IdeaName: "Emerging Mkts",
            LeaderTitle: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_EMERGING_LONG_Entitlement) ? "Long" : null,
            LaggardTitle: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_EMERGING_SHORT_Entitlement) ? "Short" : null,
            LeaderIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_EMERGING_LONG_Entitlement) ? IdeaType.EMERGING_LONG_Idea : IdeaType.NONE,
            LaggardIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_EMERGING_SHORT_Entitlement) ? IdeaType.EMERGING_SHORT_Idea : IdeaType.NONE
        };
        entitlement.IsCheckedLeaderFlag = ideaTypes.includes(entitlement.LeaderIdeaType);
        entitlement.IsCheckedLaggardFlag = ideaTypes.includes(entitlement.LaggardIdeaType);

        currentEntitlementList.push(entitlement);

        const entitlement1 = {
            IdeaName: "Frontier Mkts",
            LeaderTitle: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_FRONTIER_LONG_Entitlement) ? "Long" : null,
            LaggardTitle: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_FRONTIER_SHORT_Entitlement) ? "Short" : null,
            LeaderIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_FRONTIER_LONG_Entitlement) ? IdeaType.FRONTIER_LONG_Idea : IdeaType.NONE,
            LaggardIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.IDEAS_FRONTIER_SHORT_Entitlement) ? IdeaType.FRONTIER_SHORT_Idea : IdeaType.NONE
        };

        entitlement1.IsCheckedLeaderFlag = ideaTypes.includes(entitlement1.LeaderIdeaType);
        entitlement1.IsCheckedLaggardFlag = ideaTypes.includes(entitlement1.LaggardIdeaType);

        currentEntitlementList.push(entitlement1);

        const entitlement2 = {
            IdeaName: "Europe Mkts",
            LeaderTitle: UserInfoUtil.IsUserEntitled(EntitlementType.GFL_EUROPE_LONG_Entitlement) ? "Long" : null,
            LaggardTitle: UserInfoUtil.IsUserEntitled(EntitlementType.GFL_EUROPE_SHORT_Entitlement) ? "Short" : null,
            LeaderIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.GFL_EUROPE_LONG_Entitlement) ? IdeaType.GFL_EUROPE_LONG_Idea : IdeaType.NONE,
            LaggardIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.GFL_EUROPE_SHORT_Entitlement) ? IdeaType.GFL_EUROPE_SHORT_Idea : IdeaType.NONE
        };

        entitlement2.IsCheckedLeaderFlag = ideaTypes.includes(entitlement2.LeaderIdeaType);
        entitlement2.IsCheckedLaggardFlag = ideaTypes.includes(entitlement2.LaggardIdeaType);

        currentEntitlementList.push(entitlement2);

        const entitlement3 = {
            IdeaName: "China A Shares",
            LeaderTitle: UserInfoUtil.IsUserEntitled(EntitlementType.CHINA_ASHARE_LONG_Entitlement) ? "Long" : null,
            LaggardTitle: UserInfoUtil.IsUserEntitled(EntitlementType.CHINA_ASHARE_SHORT_Entitlement) ? "Short" : null,
            LeaderIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.CHINA_ASHARE_LONG_Entitlement) ? IdeaType.CHINA_ASHARE_LONG_Idea : IdeaType.NONE,
            LaggardIdeaType: UserInfoUtil.IsUserEntitled(EntitlementType.CHINA_ASHARE_SHORT_Entitlement) ? IdeaType.CHINA_ASHARE_SHORT_Idea : IdeaType.NONE
        };

        entitlement3.IsCheckedLeaderFlag = ideaTypes.includes(entitlement3.LeaderIdeaType);
        entitlement3.IsCheckedLaggardFlag = ideaTypes.includes(entitlement3.LaggardIdeaType);

        currentEntitlementList.push(entitlement3);
    }
    return currentEntitlementList;
}
function* handleEntitlement({ obj }) {
    try {
        let researchPageUrl = yield call(getResearchUrl);
        researchPageUrl = researchPageUrl.replace("/research", "/");
        yield call(OneilApi.SendEntlitmentRequest, obj, researchPageUrl);
    }
    catch (error) {
        console.log(`Error occurs in RIPanelOneilData.js, handleEntitlement, ${error}`)
    }
}
