import { ComparisonGraph } from "../../../Constants/ComparisonGraph";
import { SettingsConstants } from "Constants/SettingsConstants.js";
const { ActionTypes } = ComparisonGraph;
import { extend, map } from "underscore";
import CompareTabType,{CompareMenuType} from "CompareTabType";
extend(ActionTypes, SettingsConstants.ActionTypes);
import moment from "moment";

const curDate = new Date(), format = "MM-DD-YYYY";

const initData = {
  date: moment().format('YYYY-MM-DD'),
  inputFormat: "ddd, DD MMM, YYYY",
  daysOfWeekDisabled: [0, 6],
  showToday: true,
  minDate: null,
  maxDate: null,
  year: curDate.getFullYear(),
  showCalendarLeft: false,
  showCalendarRight: false,
  widgetClasses: '',
  widgetStyle: '',
  selectedDate: null,
  viewDate: null,
  startDate: "",
  endDate: "",
  periodicity: "Daily",
  duration: "",
  isIntervalSelected: false,
  title: "",
  isMostRecentTradeDayTb: false,
  initialDate: "",
  lastTradeDate: "",
  setLatestTradingDate: false,
  periodicityDisplayLabel: {},
  periodicityDuration: null,
  isUserEdit: false
};

const initialState = {
  selectedMenuTab : CompareMenuType.Symbols,
  SymbolsData : {...initData},
  GroupsData : {...initData},
  selectedTab : null
}

const calendarControl = (state = initialState, action) => {   

  let localStateData;
  const setStateData = (obj) => {

    if (state.selectedMenuTab === CompareMenuType.Symbols) {
      map(obj, (value, property) => state.SymbolsData[property] = value);
    }
    else {
      map(obj, (value, property) => state.GroupsData[property] = value);
    }

    return { ...state };
  }

  const getStateData = () => {
    
    if (state.selectedMenuTab === CompareMenuType.Symbols) {
       return state.SymbolsData ;
    }
    else {
      return state.GroupsData ;
    }
}

  switch (action.type) {
    case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
      return {
        ...state,
        selectedTab: action.selectedTab
      }

    case ActionTypes.SET_COMPARE_MENUTAB:
      if (state.selectedTab === CompareTabType.ComparisonGraph) {
        return {
          ...state,
          selectedMenuTab: action.menuTab
        }
      } else {
        return { ...state }
      }

    case ActionTypes.INIT_FROM_SETTINGS_DATA:
      const { periodicity, userEndDate, userStartDate } = action.settingsData;
      return setStateData({ periodicity: periodicity,
         startDate: userStartDate,
         endDate: userEndDate});
        // startDate: "",
        // endDate: ""});
    
    case ActionTypes.TOGGLE_CALENDAR_SUCCESS:
      return setStateData({
        showCalendarLeft: action.toggleData.showCalendarLeft,
        showCalendarRight: action.toggleData.showCalendarRight,
        widgetClasses: action.toggleData.widgetClasses,
        widgetStyle: action.toggleData.widgetStyle,
        selectedDate: action.toggleData.selectedDate,
        viewDate: action.toggleData.viewDate,
        isMostRecentTradeDayTb: action.toggleData.isMostRecentTradeDayTb
      });

    case ActionTypes.SUBTRACT_YEAR:
      localStateData = getStateData();
      return setStateData({
        selectedDate: moment(localStateData.viewDate.year(localStateData.viewDate.year() - 1))
      });

    case ActionTypes.ADD_YEAR:
      localStateData = getStateData();
      return setStateData({
        selectedDate: moment(localStateData.viewDate.year(localStateData.viewDate.year() + 1)),
      });

    case ActionTypes.SET_SELECTED_DATE:
      return setStateData({
        selectedDate: action.data.selectedDate,
        viewDate: action.data.viewDate
      });

    case ActionTypes.UPDATE_YEAR_ON_ENTER:
      return setStateData({
        selectedDate: moment(action.data.selectedDate),
        viewDate: moment(action.data.viewDate)
      });

    case ActionTypes.SET_VIEW_MONTH:
      localStateData = getStateData();
      return setStateData({
        selectedDate: moment(localStateData.selectedDate.month(action.month).year(localStateData.viewDate.year()).date(1)),
        viewDate: moment(localStateData.viewDate.month(action.month))
      });

    case ActionTypes.UPDATE_INTERVAL:
      localStateData = getStateData();
      return setStateData({
        isIntervalSelected: true,
        periodicity: action.data.periodicity,
        duration: action.data.duration,
        title: action.data.title,
        selectedDate: moment(localStateData.endDate, "MM-DD-YYYY", true),
        viewDate: moment(localStateData.endDate, "MM-DD-YYYY", true)
      });

    case ActionTypes.RESET_CALENDAR_SUCCESS:
      return setStateData({
        startDate: action.stateData.startDate,
        endDate: action.stateData.endDate,
        selectedDate: moment(action.stateData.selectedDate, "MM-DD-YYYY", true),
        viewDate: moment(action.stateData.viewDate, "MM-DD-YYYY", true),
        isIntervalSelected: action.stateData.isIntervalSelected,
        isUserEdit: action.isUserEdit
      });

    case ActionTypes.SET_MOST_RECENT_TRADE_DATE:
      localStateData = getStateData();
      return setStateData({
        endDate: localStateData.maxDate.format(format),
        selectedDate: localStateData.maxDate,
        viewDate: localStateData.maxDate
      });

    case ActionTypes.UPDATE_INPUT_VALUE:
      return setStateData({
        startDate: action.data.startDate,
        endDate: action.data.endDate,
        selectedDate: moment(action.data.selectedDate, "MM-DD-YYYY"),
        viewDate: moment(action.data.viewDate, "MM-DD-YYYY"),
        isUserEdit: action.data.isUserEdit
      });

    case ActionTypes.UPDATE_LATEST_TRADING_DATE:
      return setStateData({
        initialDate: action.initialDate,
        lastTradeDate: action.lastTradeDate,
        minDate: action.initialDate,
        maxDate: action.lastTradeDate,
        selectedDate: action.lastTradeDate,
        viewDate: action.lastTradeDate
      });

    case ActionTypes.UPDATE_LATEST_USER_DATES:
      return setStateData({
        startDate: action.startDate,
        endDate: action.endDate
      });

    case ActionTypes.UPDATE_PERIODICITY_DURATION:
      return setStateData({
        periodicityDuration: action.periodicityDuration,
        duration: action.duration
      });

    case ActionTypes.UPDATE_PERIODICITY_DISPLAY_LABEL:
      return setStateData({
        periodicityDisplayLabel: action.periodicityDispalyLabel,
        title: action.title
      });

    default:
      return state;
  }
}

export default calendarControl;