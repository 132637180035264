import { AnnotationConstant } from "Constants/AnnotationConstants";
import AppColorStore from "Stores/AppColor/AppColorStore";
import ColorPicker from "../../../../../../RayCustomControls/ColorPicker/ColorPicker"
import { connect } from 'react-redux';
import { DropdownButton } from "react-bootstrap";
import { getSelectedAnnotationInfo } from '../../../../../../Reducers/AnnotationReducers/reselectorsdata';
import PortalContainer from 'PortalContainer';
import ThemeHelper from "ThemeHelper";
import { handleColorChange, updateTargetAnnotation } from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent, Fragment } from 'react';
const { ActionTypes } = AnnotationConstant;

class AnnotationsColorPicker extends PureComponent {

    constructor() {
        super();
        this.state = {
            isShowDropdown: false,
            style: {}
        }
        this.container = React.createRef();
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onDocumentClick);
        AppColorStore.addChangeListener(this.colorChanged);
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onDocumentClick);
        AppColorStore.removeChangeListener(this.colorChanged);
        window.removeEventListener('resize', this.onResize);
    }

    colorChanged = () => {
        this.forceUpdate();
    }

    onDocumentClick = (event) => {

        if (
            (this.container.current &&
                !this.container.current.contains(event.target))
        ) {
            this.setState({
                isShowDropdown: false,
            });
        }
    }

    onResize = () => {
        this.setState({
            isShowDropdown: false,
        });
    }

    onColorChange = (color) => {
        let action = '';
        const selectedColor = color;
        if (this.props.type === "line" || this.props.type === "text") {
            action = ActionTypes.HANDLE_COLOR_CHANGE;
        }
        else if (this.props.type === "border") {
            action = ActionTypes.HANDLE_BORDER_COLOR_CHANGE;
        }
        else if (this.props.type === "background") {
            action = ActionTypes.HANDLE_BG_COLOR_CHANGE;
        }
        else {
            action = ActionTypes.HANDLE_EXTENSION_COLOR_CHANGE;
        }

        this.props.handleColorChange(action, selectedColor);

        if (this.props.annotSelected && (this.props.annotSelected.type === this.props.selectedToolType || this.props.annotSelected.type === this.props.selectedShapeType)) {
            this.props.updateTargetAnnotation(action, selectedColor);
        }
    }

    getColor() {
        if (this.props.type === "line" || this.props.type === "text") {
            if (this.props.modelInfo.color) {
                return this.props.modelInfo.color;
            }
        } else if (this.props.type === "border") {
            if (this.props.modelInfo.borderColor) {
                return this.props.modelInfo.borderColor;
            }
        } else if (this.props.type === "background") {
            if (this.props.modelInfo.bgColor) {
                return this.props.modelInfo.bgColor
            }
        } else {
            if (this.props.modelInfo.extensionLineColor) {
                return this.props.modelInfo.extensionLineColor
            }
        }
    }

    toggleDropdown = () => {
        const rect = this.dropDownButton.getBoundingClientRect();
        const style = { position: 'absolute', left: rect.left, top: rect.bottom };
        this.setState({ isShowDropdown: !this.state.isShowDropdown, style: style });
    }

    hideDropDownMenu = () => {
        if (this.state.isShowDropdown) {
            this.setState({ isShowDropdown: false, style: {} });
        }
    }

    render() {
        const color = this.getColor();
        const { label } = this.props
        return (
            <Fragment>
                {label && <span className="swatches-label">COLOR</span>}
                <div className="swatches-field color" ref={(ref) => (this.dropDownButton = ref)}>
                    <DropdownButton className="" noCaret title={<span className="selected-color" style={{ backgroundColor: color ? ThemeHelper.getThemedBrush(color) : '' }}></span>} onClick={this.toggleDropdown} >
                        {this.state.isShowDropdown &&
                            <PortalContainer>
                                {/* <MenuItem style={this.state.isShowDropdown ? this.state.style : {}}> */}
                                <div className="portal-dropdown color-dropdown" style={this.state.isShowDropdown ? this.state.style : {}} ref={this.container}>
                                    <div className="color-panel picker">
                                        {/*  style={{ position: 'absolute', top: '0px' }} */}
                                        <ColorPicker width={111} color={color} onChangeComplete={this.onColorChange} />
                                    </div>
                                </div>
                                {/* </MenuItem> */}
                            </PortalContainer>}
                    </DropdownButton>
                </div>
            </Fragment>
        );
    }
}

AnnotationsColorPicker.propTypes = {

};
const mapStateToProps = (state) => ({
    modelInfo: getSelectedAnnotationInfo(state),
    annotSelected: state.annotationReducers.annotationChartModelReducer.annotSelected,
    selectedToolType: state.annotationReducers.annotationMenuReducer.selectedToolType,
    selectedShapeType: state.annotationReducers.annotationMenuReducer.ShapesModelInfo.selectedType
})

const mapDispatchToProps = (dispatch) => ({
    handleColorChange: (action, color) => dispatch(handleColorChange(action, color)),
    updateTargetAnnotation: (action, data) => updateTargetAnnotation(action, data)
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AnnotationsColorPicker);



