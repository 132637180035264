import GraphType from "../../../../Constants/GraphType";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import PatternRecognitionSettings from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/PatternRecognitionSettings";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";

const { ActionTypes } = PriceChartConstants;
const dialogTranlaterHelper = {
    positiveNumber: LocalizationStore.getTranslatedData("ch_field_positive", "* Field accepts positive numeral input only"),
    negativeNubmer: LocalizationStore.getTranslatedData("ch_field_negative", "* Field accepts negative numeral input only"),
    outOfRange: LocalizationStore.getTranslatedData("ch_value_range", "* Value out of range")
}
const initialDialogState = {
    profitStart: 20,
    profitEnd: 25,
    pivotStart: 0,
    pivotEnd: 5,
    lossStart: -8,
    lossEnd: -5,
    showSettingDialog: false,
    TightAreaPattern: false,
    showKPR: true,
    PowerFromPivot: true,
    ChannelLines: false,
    ClimaxTop: false,
    AdvancePattern: false,
    PatternColor: 'PFFFFFF000000',
    TightAreaColor: 'PFFFFFF000000',
    errorMessage: '',
    hasProfitStartErr: false,
    hasProfitEndErr: false,
    hasPivotStartErr: false,
    hasPivotEndErr: false,
    hasLossStartErr: false,
    hasLossEndErr: false,
    ButtonActive: true,
    selectedPeriodicity: ""
}
const initialState = {
    patternsData: {},
    IsVisible: false,
    PatternRecognitionSettings: new PatternRecognitionSettings(true),
    ...initialDialogState
}
const PRReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PATTERN_REC_DATA_READY:
            initialDialogState.selectedPeriodicity = action.majorPeriodicity;
            return {
                ...state,
                patternsData: action.patternsData,
                IsVisible: action.IsVisible,
                priceChangeValue: action.priceChangeValue,
                from: action.from,
                ticks: action.ticks,
                units: action.units,
                baseStatus: action.baseStatus,
                color: action.color,
                PatternRecognitionSettings: action.patternRecognitionSettings,
                selectedPeriodicity: action.majorPeriodicity
            }
        case ActionTypes.PATTERN_REC_SETTINGS_UPDATE:
            return {
                ...state,
                IsVisible: action.IsVisible
            }
        case ActionTypes.CLEAR_PATTERN_REC:
            return {
                ...initialState
            }
        case ActionTypes.UPDATE_PATTERN_REC_DIALOG_SETTING:
            state.showSettingDialog = action.showSettingDialog;
            state.AdvancePattern = action.AdvancePattern;
            state.ChannelLines = action.ChannelLines;
            state.ClimaxTop = action.ClimaxTop;
            state.PowerFromPivot = action.PowerFromPivot;
            state.TightAreaPattern = action.TightAreaPattern;
            state.PatternColor = action.PatternColor;
            state.TightAreaColor = action.TightAreaColor;
            state.lossEnd = action.lossEnd;
            state.lossStart = action.lossStart;
            state.pivotEnd = action.pivotEnd;
            state.pivotStart = action.pivotStart;
            state.profitEnd = action.profitEnd;
            state.profitStart = action.profitStart;
            state.showKPR = action.showKPR;
            state.selectedPeriodicity = action.selectedPeriodicity;
            return {
                ...state
            }
        case ActionTypes.CLOSE_PATTERN_REC_DIALOG:
            return {
                ...state,
                showSettingDialog: false
            }
        case ActionTypes.CANCEL_PATTERN_REC_DIALOG:
            initialDialogState.showSettingDialog = false;
            return {
                ...state,
                ...initialDialogState
            }
        case ActionTypes.RESTORE_PATTERN_REC_SETTING:
            initialDialogState.showSettingDialog = true;
            return {
                ...state,
                ...initialDialogState
            }
        case ActionTypes.CHANGE_PR_PATTERN_COLOR:
            state.PatternColor = action.PatternColor;
            return {
                ...state,
            }
        case ActionTypes.TOGGLE_PR_TIGHT_AREA_PATTERN:
            return {
                ...state,
                TightAreaPattern: !state.TightAreaPattern
            }
        case ActionTypes.CHANGE_PR_TIGHT_AREA_COLOR:
            state.TightAreaColor = action.TightAreaColor;
            return {
                ...state,
            }
        case ActionTypes.TOGGLE_PATTERN_REC_KPR:
            state.showKPR = !state.showKPR;
            return {
                ...state,
            }
        case ActionTypes.TOGGLE_PR_POWER_FROM_PIVOT:
            state.PowerFromPivot = !state.PowerFromPivot;
            return {
                ...state,
            }
        case ActionTypes.TOGGLE_PR_CHANNEL_LINES:
            state.ChannelLines = !state.ChannelLines;
            return {
                ...state,
            }
        case ActionTypes.TOGGLE_PR_CLIMAX_TOP:
            state.ClimaxTop = !state.ClimaxTop;
            return {
                ...state,
            }
        case ActionTypes.TOGGLE_PR_ADVANCE_PATTERN:
            state.AdvancePattern = !state.AdvancePattern;
            return {
                ...state,
            }
        case ActionTypes.CHANGE_PR_PROFIT_START:
            state.hasProfitStartErr = false;
            if(isNaN(action.profitStart) || action.profitStart < 0){
                state.errorMessage = dialogTranlaterHelper.positiveNumber;
                state.hasProfitStartErr = true;
            }
            else if(action.profitStart < state.pivotEnd){
                state.errorMessage = dialogTranlaterHelper.outOfRange;
                state.hasProfitStartErr = true;
            }
            state.ButtonActive = !state.hasProfitStartErr;
            state.profitStart = action.value ? state.hasProfitStartErr ? action.value : action.profitStart : "";
            return {
                ...state,
            }
        case ActionTypes.CHANGE_PR_PROFIT_END:
            state.hasProfitEndErr = false;
            if(isNaN(action.profitEnd) || action.profitEnd < 0){
                state.errorMessage = dialogTranlaterHelper.positiveNumber;
                state.hasProfitEndErr = true;
            }
            else if(action.profitEnd < state.pivotEnd){
                state.errorMessage = dialogTranlaterHelper.outOfRange;
                state.hasProfitEndErr = true;
            }
            state.ButtonActive = !state.hasProfitEndErr;
            state.profitEnd = action.value ? state.hasProfitEndErr ? action.value : action.profitEnd : "";
            return {
                ...state,
            }
        case ActionTypes.CHANGE_PR_PIVOT_START:
            state.hasPivotStartErr = false;
            if(isNaN(action.pivotStart) || action.pivotStart < 0){
                state.errorMessage = dialogTranlaterHelper.positiveNumber;
                state.hasPivotStartErr = true;
            }
            else if(action.pivotStart < state.lossEnd || action.pivotStart > state.profitStart){
                state.errorMessage = dialogTranlaterHelper.outOfRange;
                state.hasPivotStartErr = true;
            }
            state.ButtonActive = !state.hasPivotStartErr;
            state.pivotStart = action.value ? state.hasPivotStartErr ? action.value : action.pivotStart : "";
            return {
                ...state,
            }
        case ActionTypes.CHANGE_PR_PIVOT_END:
            state.hasPivotEndErr = false;
            if(isNaN(action.pivotEnd) || action.pivotEnd < 0){
                state.errorMessage = dialogTranlaterHelper.positiveNumber;
                state.hasPivotEndErr = true;
            }
            else if(action.pivotEnd < state.lossEnd || action.pivotEnd > state.profitStart){
                state.errorMessage = dialogTranlaterHelper.outOfRange;
                state.hasPivotEndErr = true;
            }
            state.ButtonActive = !state.hasPivotEndErr;
            state.pivotEnd = action.value ? state.hasPivotEndErr ? action.value : action.pivotEnd : "";
            return {
                ...state,
            }
        case ActionTypes.CHANGE_PR_LOSS_START:
            state.hasLossStartErr = false;
            if(isNaN(action.lossStart) || action.lossStart > 0){
                state.errorMessage = dialogTranlaterHelper.negativeNubmer;
                state.hasLossStartErr = true;
            }
            else if(action.lossStart > state.pivotStart){
                state.errorMessage = dialogTranlaterHelper.outOfRange;
                state.hasLossStartErr = true;
            }
            state.ButtonActive = !state.hasLossStartErr;
            state.lossStart = action.value ? state.hasLossStartErr ? action.value: action.lossStart : "";
            return {
                ...state,
            }
        case ActionTypes.CHANGE_PR_LOSS_END:
            state.hasLossEndErr = false;
            if(isNaN(action.lossEnd) || action.lossEnd > 0){
                state.errorMessage = dialogTranlaterHelper.negativeNubmer;
                state.hasLossEndErr = true;
            }
            else if(action.lossEnd > state.pivotStart){
                state.errorMessage = dialogTranlaterHelper.outOfRange;
                state.hasLossEndErr = true;
            }
            state.ButtonActive = !state.hasLossEndErr;
            state.lossEnd = action.value ? state.hasLossEndErr ? action.value: action.lossEnd : "";
            return {
                ...state,
            }
        default:
            return state;
    }
}
export default PRReducer;