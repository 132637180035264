import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from "BaseServiceApi";

class GetNewsDetailsRequest extends BaseServiceRequest {
    constructor(streamerList, newsState, index) {
        super();
        super.addRequestUri("newsdetails.pb");        
        let newsRequest = undefined;
        const ReqProto = BaseServiceApi.rayData["NewsStreamerData"];
        newsRequest = new ReqProto();
        if (index >= 0) {
            let streamer = undefined;

            streamer = streamerList[index];
            newsRequest.newsStart = 0;
            newsRequest.newsEnd = 0;

            const ReqProtoNewsStreamer = BaseServiceApi.rayData["NewsStreamer"];
            let newsStreamer = new ReqProtoNewsStreamer();

            newsStreamer.newsStreamerId = streamer.StreamID;
            newsStreamer.title = streamer.StreamName;
            newsStreamer.layoutOrder = streamer.LayoutOrder;
            newsStreamer.isDisplayImg = streamer.DisplayIcons;
            newsStreamer.isSourcesAllSelect = streamer.IsSourcesAllSelect;
            newsStreamer.temp = streamer.isTemp;
            newsStreamer.workSpaceID = 0;
            newsStreamer.consoleID = streamer.ConsoleId;

            const ReqProtoNewsRSSSource = BaseServiceApi.rayData["NewsRSSSource"];

            streamer.Sources.map((rowData) => {
                if (rowData.sourceId) {
                    const newsSource = new ReqProtoNewsRSSSource();

                    newsSource.sourceId = rowData.sourceId;
                    newsSource.sourceName = rowData.sourceName;
                    newsSource.newsType = rowData.newsType;
                    newsSource.parents = rowData.parents;
                    newsSource.isSelect = rowData.isSelect;

                    newsStreamer.newsRSSSource.push(newsSource);
                }
            });
            let newsFilter = streamer.NewsStreamerFilter;
            const ReqProtoNewsStreamerFilter = BaseServiceApi.rayData["NewsStreamerFilter"];
            const newsStreamerFilter = new ReqProtoNewsStreamerFilter();
            if (newsFilter.symbols) {
                newsStreamerFilter.symbols = newsFilter.symbols;
            }
            if (newsFilter.keywords) {
                newsStreamerFilter.keywords = newsFilter.keywords;
            }
            if (newsFilter.operator!=undefined) {
                newsStreamerFilter.operator = newsFilter.operator;
            }

            const ReqProtoNewsStreamerListSelect = BaseServiceApi.rayData["NewsStreamerListSelect"];

            // streamer.NewsStreamerFilter.newsStreamerListSelect.map((rowData) => {
            //     const newsStreamerListSelect = new ReqProtoNewsStreamerListSelect();

            //     newsStreamerListSelect.listId = rowData.listId;
            //     newsStreamerListSelect.listname = rowData.listname;

            //     newsStreamerFilter.push(newsStreamerListSelect);
            // });
            



            const newsStreamerListSelect = new ReqProtoNewsStreamerListSelect();
            const ListType = BaseServiceApi.rayData["ListType"];
            if (streamer.FilterListType === ListType.LIST_List && newsFilter.newsStreamerListSelect.listId !=0) {
            newsStreamerListSelect.listId = newsFilter.newsStreamerListSelect.listId;
            newsStreamerListSelect.listname = newsFilter.newsStreamerListSelect.listname;
            
            newsStreamerFilter.newsStreamerListSelect.push(newsStreamerListSelect);
            }
            newsStreamer.newsStreamerFilter = newsStreamerFilter;

            newsRequest.newsStreamer.push(newsStreamer);
        } else {
            newsRequest.newsStart = newsState.newsStart;
            newsRequest.newsEnd = newsState.newsEnd;


            streamerList.map((rowstreamer) => {
                const ReqProtoNewsStreamer = BaseServiceApi.rayData["NewsStreamer"];
                let newsStreamer = new ReqProtoNewsStreamer();

                newsStreamer.newsStreamerId = rowstreamer.StreamID;
                newsStreamer.title = rowstreamer.StreamName;
                newsStreamer.layoutOrder = rowstreamer.LayoutOrder;
                newsStreamer.isDisplayImg = rowstreamer.DisplayIcons;
                newsStreamer.isSourcesAllSelect = rowstreamer.IsSourcesAllSelect;
                newsStreamer.temp = rowstreamer.isTemp;
                newsStreamer.workSpaceID = 0;
                newsStreamer.consoleID = rowstreamer.ConsoleId;

                const ReqProtoNewsRSSSource = BaseServiceApi.rayData["NewsRSSSource"];

                rowstreamer.Sources.map((rowData) => {
                    if (rowData.sourceId) {
                        const newsSource = new ReqProtoNewsRSSSource();

                        newsSource.sourceId = rowData.sourceId;
                        newsSource.sourceName = rowData.sourceName;
                        newsSource.newsType = rowData.newsType;
                        newsSource.parents = rowData.parents;
                        newsSource.isSelect = rowData.isSelect;

                        newsStreamer.newsRSSSource.push(newsSource);
                    }
                });
                let newsFilter = rowstreamer.NewsStreamerFilter;
                const ReqProtoNewsStreamerFilter = BaseServiceApi.rayData["NewsStreamerFilter"];
                const newsStreamerFilter = new ReqProtoNewsStreamerFilter();

                if (newsFilter.symbols) {
                    newsStreamerFilter.symbols = newsFilter.symbols;
                }
                if (newsFilter.keywords) {
                    newsStreamerFilter.keywords = newsFilter.keywords;
                }
                if (newsFilter.operator!=undefined) {
                    newsStreamerFilter.operator = newsFilter.operator;
                }

                const ReqProtoNewsStreamerListSelect = BaseServiceApi.rayData["NewsStreamerListSelect"];

                // rowstreamer.SelectedLists.map((rowData) => {
                //     const newsStreamerListSelect = new ReqProtoNewsStreamerListSelect();

                //     newsStreamerListSelect.listId = rowData.listId;
                //     newsStreamerListSelect.listname = rowData.listname;

                //     newsStreamerFilter.push(newsStreamerListSelect);
                // });
                


                const newsStreamerListSelect = new ReqProtoNewsStreamerListSelect();
                const ListType = BaseServiceApi.rayData["ListType"];
                if (rowstreamer.FilterListType === ListType.LIST_List && newsFilter.newsStreamerListSelect.listId !=0) {
                newsStreamerListSelect.listId = newsFilter.newsStreamerListSelect.listId;
                newsStreamerListSelect.listname = newsFilter.newsStreamerListSelect.listname;
                
                newsStreamerFilter.newsStreamerListSelect.push(newsStreamerListSelect);
                }

                newsStreamer.newsStreamerFilter = newsStreamerFilter;

                newsRequest.newsStreamer.push(newsStreamer);
            });
        }
        
        const reqcontent = newsRequest.encode();
        super.setContent(reqcontent);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.NEWS;
    }

    getMessageName() {
        return "NewsNewsResponse";
    }
}


export default GetNewsDetailsRequest;
