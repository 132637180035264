import BaseServiceApi from 'BaseServiceApi';
import { connect } from 'react-redux';
import { openFinancialDialogBox } from '../../../../../Actions/FinancialBlockDialogActions.js';
import UserInfoUtil from 'UserInfoUtil';
import { headerToggle, hideShowSideGraph } from '../../../../../Actions/FinancialBlockActions';
import React, { Component } from "react";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

class HeaderCell extends Component {
  constructor(props) {
    super(props);
    this.toggleHeader = this.toggleHeader.bind(this);
    this.state = {
      hover: false,
    };

    this.hideShowGraph = this.hideShowGraph.bind(this);
    this.controlButtonState = this.controlButtonState.bind(this);
    this.enableGraph = UserInfoUtil.IsUserEntitled(EntitlementType.CFB_VISUALIZATION_Entitlement);
  }

  onHover(hover) {
    this.setState({ hover: hover });
  }
  toggleHeader() {
    if (this.props.header && this.props.header.canToggle) {
      this.props.headerToggle(this.props.header, this.props.index);
    }
  }

  hideShowGraph(isShow) {
    if (!this.props.graphData.isActive) {
      this.props.hideShowGraph(this.props.index, isShow, this.props.graphData.isActive);
    }
  }

  controlButtonState() {
    let isClicked = true;
    if (this.props.activeIndex === this.props.index) { isClicked = false; }
    this.props.hideShowGraph(this.props.index, false, isClicked);
  }

  render() {
    const cursor = this.props.header ? "pointer" : "default";
    const divstyle = { height: this.props.RowHeight };

    let eventClass = "";
    if (this.enableGraph) {
      if ((this.props.graphData.isActive && this.props.activeIndex === this.props.index) && this.state.hover) {
        eventClass = "rowHoverWithClicked";
      }
      else if ((this.props.graphData.isActive && this.props.activeIndex !== this.props.index) && this.state.hover) {
        eventClass = "otherRowHoverWithClicked";
      }
      else if (this.state.hover) {
        eventClass = "hover-on-row";
      }
      else if ((this.props.graphData.isActive && this.props.activeIndex === this.props.index)) {
        eventClass = "clicked";
      }
    }

    let label = "";
    if (this.props.header.canToggle) {
      label = this.props.header.ActiveHeader.DisplayName;
    }
    else {
      label = this.props.header.HeaderId === '-999' ? this.state.hover ? 'CLICK TO CHANGE' : '' : this.props.header.DisplayName;
    }
    return (
      <div className={`financial-entry-row ${eventClass}`} style={divstyle}
        onMouseOver={() => this.props.syncHover(this.props.index, true)}
        onFocus={() => this.props.syncHover(this.props.index, true)}
        onMouseLeave={() => this.props.syncHover(this.props.index, false)}
      >
        <span className="financial-entry-name" style={{ cursor: cursor }} onClick={this.props.onOpenFinancialBlockDialog}>{label}</span>
        {
          (this.state.hover && this.props.header.canToggle && this.enableGraph) &&
          <span className="icn-changer-box">
            <button className="icn-changer" onMouseDown={this.toggleHeader}>
            </button>
          </span>
        }
        {
          ((this.state.hover || (this.props.graphData.isActive && this.props.activeIndex === this.props.index)) && this.enableGraph) &&
          <span className="icn-graph-box">
            <button className="icn-graph"
              id="buttonState"
              onMouseOver={() => this.hideShowGraph(true)}
              onMouseLeave={() => this.hideShowGraph(false)}
              onClick={this.controlButtonState}
              onFocus={() => void 0}>
            </button>
          </span>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { graphData, RowHeight } = state.DatagraphReducers.financialBlockReducer;
  const activeIndex = graphData.activeIndex
  return { graphData, RowHeight, activeIndex }
}

const mapDispatchToProps = (dispatch) => ({
  hideShowGraph: (index, isShow, isEnableGraph) => dispatch(hideShowSideGraph(index, isShow, isEnableGraph)),
  onOpenFinancialBlockDialog: () => dispatch(openFinancialDialogBox()),
  headerToggle: (header, index) => dispatch(headerToggle(header, index))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(HeaderCell)
