import Scale from "./Scale.js";
import ExtremeDataValue from "ExtremeDataValue";

class ArithmaticScale extends Scale {
    getObjectMapKey() {
        return "ArithmaticScale";
    }
    ComputeY(price) {
        const dPriceDiffFromBottom = price - this.Lowpt;

        return this.height - dPriceDiffFromBottom * this.Factor;
       // return Math.round(this.height - dPriceDiffFromBottom * this.Factor, 2);
    }

    ComputeYL(dPrice, dLow) {
        const dPriceDiffFromBottom = dPrice - dLow;

        return this.height - dPriceDiffFromBottom * this.Factor;
//        return Math.round(this.height - dPriceDiffFromBottom * this.Factor, 2);
    }

    ComputePrice(y) {

        const dGraphDistance = this.height - y;

        let dPriceDiffFromBottom = dGraphDistance / this.Factor;
        dPriceDiffFromBottom += this.Lowpt;

        return dPriceDiffFromBottom;
    }
    GetInc(price) {
        var pr2 = Math.floor(price);
        var pr0 = Math.floor(this.log10(pr2));
        var pr1 = Math.floor(Math.pow(10, pr0));
        var pr3 = pr2 % pr1;
        var inc = pr2 - pr3;
        if (inc < 10 && inc > 5) inc = 5; else if (inc < 5) inc = 2.5; else
            if (inc < 100 && inc > 50) inc = 50; else if (inc < 50) inc = 25; else
                if (inc < 1000 && inc > 500) inc = 500; else if (inc < 500) inc = 250; else
                    if (inc < 10000 && inc > 5000) inc = 5000; else if (inc < 5000) inc = 2500;

        return inc;
    }
    InitScale(minPrice, maxPrice, height, graphType, scaleType, dpi, symbolType, epsMultiplier, rpsMultiplier, ti = false, fixed = false, kpi = false) {
        var mult = Math.abs(maxPrice) < 1 ? 1000 : 1;
        var mnPrc = minPrice * mult * 1.0;
        var mxPrc = maxPrice * mult * 1.0;
        var iNumberOfIncrements = ti ? 5.0 : 10.0;
        this.mhLines = [];
        this.height = height;
        this.prInc = ((mxPrc - mnPrc) / iNumberOfIncrements) + (mxPrc < 1 ? 0.05 : 0);// + 0.05;
        if (this.prInc <= 0) this.prInc = 1;

        var lowptforScale;
        if (!fixed) {
            var pr = this.log10(this.prInc);
            var pr0 = Math.pow(10, Math.abs(Math.floor(pr, 0) + 1));
            var pr1 = this.prInc < 10 ? Math.pow(10.0, pr0) : 1.0;
            var pr2 = this.prInc * pr1;
            pr2 = pr2 - pr2 % Math.pow(10, Math.floor(this.log10(pr2), 0));

            var amnPrc = Math.abs((mnPrc - 0.5) * pr1);

            amnPrc = mnPrc > 0 ? (amnPrc - amnPrc % Math.pow(10, Math.floor(this.log10(amnPrc), 0))) :
                mnPrc < 0 ? -(amnPrc - amnPrc % Math.pow(10, Math.floor(this.log10(amnPrc), 0))) : 0;

            var amxPrc = Math.abs((mxPrc + 0.5) * pr1);

            amxPrc = mxPrc > 0 ? (amxPrc - amxPrc % Math.pow(10, Math.floor(this.log10(amxPrc), 0))) :
                mxPrc < 0 ? -(amxPrc - amxPrc % Math.pow(10, Math.floor(this.log10(amxPrc), 0))) : 0;

            this.prInc = pr2 / pr1;

            if (this.prInc <= .01 && this.prInc > .005)
                this.prInc = .01;
            else
                if (this.prInc <= .005 && this.prInc > .0025)
                    this.prInc = .005;
                else
                    if (this.prInc <= .0025 && this.prInc > .0001)
                        this.prInc = .0025;

            if (this.prInc <= .1 && this.prInc > .05)
                this.prInc = .1;
            else
                if (this.prInc <= .05 && this.prInc > .025)
                    this.prInc = .05;
                else
                    if (this.prInc <= .025 && this.prInc > .01)
                        this.prInc = .025;

            if (this.prInc <= 1 && this.prInc > .5)
                this.prInc = 1;
            else
                if (this.prInc <= .5 && this.prInc > .25)
                    this.prInc = .5;
                else
                    if (this.prInc <= .25 && this.prInc > .1)
                        this.prInc = .25;

            if (this.prInc <= 10 && this.prInc > 5)
                this.prInc = 10;
            else
                if (this.prInc <= 5 && this.prInc > 2.5)
                    this.prInc = 5;
                else if (ti) {
                    if (this.prInc <= 3 && this.prInc > 1)
                        this.prInc = 2;
                    // else if (this.prInc <= 1)
                    //     this.prInc = 1;
                }
                else
                    if (this.prInc <= 2.5 && this.prInc > 1)
                        this.prInc = 2.5;


            if (this.prInc <= 100 && this.prInc > 50)
                this.prInc = 100;
            else
                if (this.prInc <= 50 && this.prInc > 25)
                    this.prInc = 50;
                else
                    if (this.prInc <= 25 && this.prInc > 10)
                        this.prInc = 25;

            if (this.prInc <= 1000 && this.prInc > 500)
                this.prInc = 1000;
            else
                if (this.prInc <= 500 && this.prInc > 250)
                    this.prInc = 500;
                else
                    if (this.prInc <= 250 && this.prInc > 100)
                        this.prInc = 250;

            if (this.prInc <= 10000 && this.prInc > 5000)
                this.prInc = 10000;
            else
                if (this.prInc <= 5000 && this.prInc > 2500)
                    this.prInc = 5000;
                else
                    if (this.prInc <= 2500 && this.prInc > 1000)
                        this.prInc = 2500;
                    else
                        if (this.prInc > 10000)
                            this.prInc = this.prInc - this.prInc % Math.pow(10, Math.floor(this.log10(this.prInc), 0));

            mxPrc = amxPrc / pr1;
            mnPrc = amnPrc / pr1;

            lowptforScale = 0;

            //             if (!ti) { 
            var amn = mnPrc % this.prInc;
            var diff = !ti ? 60 : 10;
            this.Hipt = maxPrice;
            this.Lowpt = minPrice;

            //// Actual Factor
            this.Factor = (this.height) / (this.Hipt - this.Lowpt);

            var yPr1 = this.ComputePrice(-diff);
            var yPr2 = this.ComputePrice(this.height + diff);

            this.Hipt = yPr1;
            this.Lowpt = yPr2;

            lowptforScale = yPr2;
            //var amn = mnPrc % this.prInc;
            //var buf = (mxPrc - mnPrc) / 50;

            //this.Hipt = mxPrc + buf;
            //lowptforScale = mnPrc - buf;

            this.prInc /= mult;
            this.scaleStart = ((mnPrc - amn) / mult) - this.prInc;
            //if (this.prInc < 1) {
            //    let new1 = Math.round(this.Lowpt * 100);
            //    let new2 = new1 % this.prInc;
            //    this.scaleStart = (new1-new2) / 100;
            //}
            //lowptforScale = this.scaleStart;
        }
        else {
            this.prInc = 20;
            lowptforScale = minPrice;
            this.scaleStart = -20;
            this.Hipt = maxPrice + 10;
        }
        this.Lowpt = lowptforScale;
        // Adjusted Factor with Top/Bottom margins
        this.Factor = (this.height) / (this.Hipt - this.Lowpt);

        let eok = isNaN(epsMultiplier) ? 0 : 1;
        let rok = isNaN(rpsMultiplier) ? 0 : 1;
        this.SetHorizontalGrid(epsMultiplier, rpsMultiplier, 1, eok, rok, scaleType, ti, kpi, dpi);
    }

    SetHorizontalGrid(epsMultiplier, rpsMultiplier, pok, eok, rok, scaleType, ti, kpi, dpi) {
        if(!Number.isFinite(this.Hipt)) return;
        let py = this.ComputeY(this.scaleStart);
        let nodeWidth = dpi / 28;
        let dist = nodeWidth > 12 ? nodeWidth * 2 : 10;
        const topLimit = scaleType > 1 ? 5 : 30;
        let plabel = "";
        for (let pricePtr = this.scaleStart; pricePtr <= this.Hipt; pricePtr += this.prInc) {
            const y = this.ComputeY(pricePtr);

            if (Math.abs(py - y) < dist || y < topLimit || y + 5 > this.height) {
                continue;
            }
            py = y;
            let price = " ";
            let eprice = " ";
            let rprice = " ";

            if (pok === 1) {
                price = pricePtr;
                if (this.prInc < 1)
                    price = pricePtr.toFixed(1);
                if (this.prInc < 0.1)
                    price = pricePtr.toFixed(2);
                if (this.prInc < 0.01)
                    price = pricePtr.toFixed(3);
                if (this.prInc < 0.001 && !ti)
                    price = pricePtr.toFixed(4);
            }
            if (eok === 1) {
                eprice = pricePtr / epsMultiplier;
                if (eprice > 0.1)
                    eprice = (pricePtr / epsMultiplier).toFixed(2);
                else
                    eprice = (pricePtr / epsMultiplier).toFixed(3);
            }
            if (rok === 1) {
                if (eprice > 0.1)
                    rprice = (pricePtr / rpsMultiplier).toFixed(2);
                else
                    rprice = (pricePtr / rpsMultiplier).toFixed(3);
            }
            
            let label = scaleType === 3 ? pricePtr.toFixed(1) + "x" : ExtremeDataValue.showChartPrices(price,kpi);

            const eLine = {
                Type: 2,
                Label: label !== plabel ? label : "",
                eLabel: ExtremeDataValue.abbreviateFinancialValue(eprice, 1),
                rLabel: ExtremeDataValue.abbreviateFinancialValue(rprice, 1),
                YAxis: y
            };
            plabel = label;
            this.mhLines.push(eLine);

            py = y;
        }
    }
}

export default ArithmaticScale;
