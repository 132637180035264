import AppColorStore from "Stores/AppColor/AppColorStore";
import { connect } from 'react-redux';
import { TriangleControl } from '../Presentational/index';
import {
    handleDragEnd, handleAnchorDragStart, handleTriAnchorUp, handleTriAnchorDrag, handleDeleteAnnotationDrawing, handleToggleContextMenu,
    handleDragStart, handleDragMove
} from '../../../../../../Actions/AnnotationActions';
import React, { Component } from 'react';

class GraphicsTriangle extends Component {
    componentDidMount() {
        AppColorStore.addChangeListener(this.AppColorStoreStateChanged);
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.AppColorStoreStateChanged);
    }

    AppColorStoreStateChanged = () => {
        this.forceUpdate();
    }

    handleDragEnd = (e) => {
        this.props.handleDragEnd(e);
    }

    render() {
        const { triangle, index, lockImage, isApiReqResolved } = this.props;
        const shapesModelInfo = this.props.shapesModelInfo;
        const { isDragging, activeAnchor, selectedAnnotId } = this.props.annotationChartModelReducer;

        return (

            <TriangleControl
                onContextMenu={this.props.onContextMenu}
                key={index}
                index={index}
                triangle={triangle}
                shapesModelInfo={shapesModelInfo}
                handleDragStart={this.props.handleDragStart}
                handleDragMove={this.props.handleDragMove}
                handleDragEnd={this.props.handleDragEnd}
                isDragging={isDragging}
                selectedAnnotId={selectedAnnotId}
                activeAnchor={activeAnchor}
                handleAnchorDragStart={this.props.handleAnchorDragStart}
                handleAnchorDragEnd={this.props.handleAnchorDragEnd}
                handleAnchorDragMove={this.props.handleAnchorDragMove}
                draggable={this.props.draggable}
                lockImage={lockImage}
                isApiReqResolved={isApiReqResolved}
                handleDeleteAnnotationDrawing={this.props.handleDeleteAnnotationDrawing}
            />

        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { triangleReducer, annotationChartModelReducer } = annotationReducers;
    const shapesModelInfo = annotationReducers.annotationMenuReducer.ShapesModelInfo
    return ({ triangleReducer, shapesModelInfo, annotationChartModelReducer });
}

const mapDispatchToProps = (dispatch) => ({
    handleDragStart: (triangle) => dispatch(handleDragStart(triangle)),
    handleDragMove: (e) => dispatch(handleDragMove(e)),
    handleDragEnd: () => handleDragEnd(),
    handleAnchorDragStart: (anchor) => dispatch(handleAnchorDragStart(anchor)),
    handleAnchorDragEnd: () => dispatch(handleTriAnchorUp()),
    handleAnchorDragMove: (e) => dispatch(handleTriAnchorDrag(e)),
    handleDeleteAnnotationDrawing: (charCode) => { dispatch(handleDeleteAnnotationDrawing(charCode)), dispatch(handleToggleContextMenu(false, 0, 0, '')) }
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphicsTriangle);