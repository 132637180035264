import React from "react";
import FlexibleModal from "../FlexibleModal";
import ShareDataPage from "./ShareData/ShareDataPage.jsx";
import ManageDataPage from "./ManageData/ManageDatapage.jsx";
const ShareDialog = (
    {
        sharedData,
        managedData,
        targetData,
        userList,
        dialogType,
        sharedAction,
        showUsers,
        nameFilter,
        savedSharedData,
        showConfirmation,
        showInProgress,        
        x_parent,
        y_parent
    }) => {
    const getXPosition = () => {        
        let x = 0;
        let w = 600;
        if (x_parent) {
            x = x_parent - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2;
        }

        if (x < 0)
            x = 0;

        return x;
    }

    const getYPosition = () => {        
        let y = 0;
        let h = 460;
        if (y_parent) {
            y = y_parent - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2;
        }

        if (y < 0)
            y = 0;

        return y;
    }

    return (
        <FlexibleModal left={getXPosition()} top={getYPosition()} open={true} isDefaultDragEnabled = {false}
            draggable="true" className="modal-popup share-dialog">
            {
                dialogType === 'Shared'
                    ? <ShareDataPage className="share-data-page"
                        sharedData={sharedData}
                        targetData={targetData}
                        userList={userList}
                        sharedAction={sharedAction}
                        showUsers={showUsers}
                        nameFilter={nameFilter}
                        savedSharedData={savedSharedData}
                        showConfirmation={showConfirmation}
                    />
                    : 
                    <ManageDataPage className="manage-data-page"
                        nodeSharedData={managedData.nodeData}
                        sharedAction={sharedAction}
                        sort={managedData.sort}
                        sortReversed={managedData.sortReversed}
                        showInProgress={showInProgress}                        
                    />
            }
        </FlexibleModal>
    );
}
export default ShareDialog;