import Serializable from "Serializable";
import _ from 'underscore';

export default class Base64Serialization {
  constructor() {
    this.infos = [];
  }

  serialize(serializable) {
    if (serializable instanceof Serializable) {
      let obj = new Serializable();
      obj[serializable.getType()] = serializable;
      var jsonString = JSON.stringify(obj, (key, value) => {
        if (value instanceof Object) {
          if (_.isArray(value)) {
            return value;
          } else if (value.hasOwnProperty("type") && Serializable.Types[value.type]) {
            return value;
          }
          else {
            return null;
          }

        }
        return value;
      });
      let binaryString = window.btoa(unescape(encodeURIComponent(jsonString)));
      return binaryString;
    }
    else {
      throw new Error("Object need to be derived from Serializable");
    }

  }

  deserialize(buffer) {
    let bytes = buffer.toString("utf8");
    try {
      let consoleSetting = JSON.parse(bytes, (key, value) => {
        if (key !== "" && value && value.hasOwnProperty("type")) {
          if (Serializable.Types[value.type]) {
            let typedObject = Object.create(Serializable.Types[value.type]);
            typedObject.constructor();
            if (typedObject.onSeralisedInfoRecieved) {
              typedObject.onSeralisedInfoRecieved(value);
            }

            if (typedObject.hasOwnProperty("type")) {
              delete typedObject.type;
            }

            return typedObject;
          }
        }
        return value;

      });

      return consoleSetting[Object.keys(consoleSetting)[0]];

    }
    catch (error) {
      return null;
    }

  }
}
