import React from 'react';
import ReactDOM from 'react-dom';
const toolTipRoot = document.getElementById('portaldiv');

export default class PortalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.el = this.props.isCustomColumnFilter ? toolTipRoot : document.createElement('div');
  }

  componentDidMount() {
    !this.props.isCustomColumnFilter && toolTipRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    !this.props.isCustomColumnFilter && toolTipRoot.removeChild(this.el);
  }


  render() {
    return (
      ReactDOM.createPortal(
        this.props.children,
        this.el
      )
    );
  }
}