import ColorPicker from "../../../../../../../RayCustomControls/ColorPicker/ColorPicker.jsx";
import { connect } from "react-redux";
import { DialogHOC } from "./DialogHOC.jsx";
import { Modal } from "react-bootstrap";
import { cancelRSLineDialogSetting, handleChangeRSSymbol, handleChangeRSValueType, handleRSLineDialogCountryChange, handleRSLineDialogRegionChange, handleRSLineDialogRGroupChange, saveRSLineDialogSetting, toggleRSMovingAverageLineType, toggleShowRSGrade, toggleShowRSMovingAverage, toggleShowRSRank, updateRSLength, updateRSLengthError, updateRSLineColor, updateRSLineThickness,updateSymbolError } from "../../../../../../../Actions/PricePanelActions.js";
import { countriesTranslateHelper, LineEditDialogTranslateHelper, regionTranslateHelper, RSLineTranslateHelper, TranslateHelper } from "../../../../../../../Utils/TranslateHelper.js";
import React, { Component } from "react";

const colorPickerWidth = 101;
const thickness = [
    { weight: "thin-line", value: 1 },
    { weight: "normal-line", value: 2 },
    {
        weight: "weight-line", value: 3
    }];


class RSLineDialog extends Component {
    constructor(props) {
        super(props);
        this.handleChangeLength = this.handleChangeLength.bind(this);
    }
    componentDidUpdate(prevProps){
        if(this.othersymbol && prevProps.valueType !== "symbol" && this.props.valueType === "symbol"){
            this.othersymbol.focus();
        }
    }

    handleChangeLength() {
        if (this.lengthinput.value && !(/^[\d]+\.?\d*$/.test(this.lengthinput.value))) {
            this.props.updateRSLengthError("Error: Only Numbers Allowed");
            return;
        }
        this.props.updateRSLength(this.lengthinput.value ? parseInt(this.lengthinput.value) : '')
    }

    render() {

        return (
            <Modal.Body>
                <div className="modal-material">
                    <div className="price-lines">
                        {!this.props.isSymbolIndex && !this.props.isSymbolGroup &&
                            <div className="row">
                                <div className="col-sm-12">
                                    <h5>{this.props.isFund ? RSLineTranslateHelper.Set_Line_Value_For_Fund : RSLineTranslateHelper.Set_Line_Per_Country}</h5>
                                </div>
                            </div>
                        }
                        {!this.props.isSymbolIndex && !this.props.isSymbolGroup && !this.props.isFund &&
                            <div className="row region-n-country">
                                <div className="col-sm-6">
                                    <span className="form-head">{LineEditDialogTranslateHelper.Region}</span>
                                    <div className="form-field"><select className="custom-select" onFocus={() => this.props.updateSymbolError()
                                     } value = {regionTranslateHelper[this.props.selectedRegion]} onChange={this.props.handleRSLineDialogRegionChange } onBlur={this.props.handleRSLineDialogRegionChange }>
                                        {this.props.regions.map((item, index)=>(
                                            <option key={index}>{regionTranslateHelper[item]}</option>
                                        ))}
                                        </select></div>
                                </div>
                                <div className="col-sm-6">
                                    <span className="form-head">{LineEditDialogTranslateHelper.Country}</span>
                                    <div className="form-field"><select className="custom-select"  onFocus={() => this.props.updateSymbolError()
                                    } value = {countriesTranslateHelper[this.props.selectedCountry]} onChange={this.props.handleRSLineDialogCountryChange} onBlur={this.props.handleRSLineDialogCountryChange}>
                                        {this.props.countries.map((item, index)=>(
                                            <option key={index}>{countriesTranslateHelper[item.name]}</option>
                                        ))}
                                        </select></div>
                                </div>
                            </div>
                        }
                        <div className="row value-denoter">
                            <div className="col-sm-12">
                                <span className="form-head">{LineEditDialogTranslateHelper.Value}</span>
                                <ul className="value-denote">
                                    <li>
                                        <input type="radio" name="lineValue" value="default" id="default" checked = {this.props.valueType === 'default'} onChange = {this.props.handleChangeRSValueType.bind(this, 'default')} />
                                        <label htmlFor="default">{this.props.rsLineNum === 1 ? RSLineTranslateHelper.SameAsIndexLne : LineEditDialogTranslateHelper.CountrysDefault}</label>
                                        <label className="right-label" hidden = {this.props.rsLineNum === 1}>{this.props.countryIndex}</label>
                                    </li>
                                    {!this.props.isSymbolIndex && !this.props.isSymbolGroup &&
                                        <li>
                                            <input type="radio" name="lineValue" value="group" id="group" checked={this.props.valueType === 'group'} onChange={this.props.handleChangeRSValueType.bind(this, 'group')} />
                                            <label htmlFor="group">{this.props.isFund ? LineEditDialogTranslateHelper.Funds_Benchmark : LineEditDialogTranslateHelper.Symbols_Group} </label>
                                            {!this.props.isFund && <label className="right-label">
                                                <select disabled={this.props.valueType !== 'group'} className="custom-select" value={this.props.selectedGroup} onChange={this.props.handleRSLineDialogGroupChange} onBlur={this.props.handleRSLineDialogGroupChange}>
                                                    {this.props.groups.map((group, index) => 
                                                        <option key={index} value={group.type}>{group.name}</option>
                                                    )}
                                                    </select>
                                            </label>}
                                        </li>
                                    }
                                    <li>
                                        <input type="radio" name="lineValue" value="symbol" id="symbol" checked={this.props.valueType === 'symbol'} onChange={this.props.handleChangeRSValueType.bind(this, 'symbol')} />
                                        <label htmlFor="symbol">{LineEditDialogTranslateHelper.Symbol}</label>
                                        <label className="right-label"><input type="text" ref={(ref) => (this.othersymbol = ref)} value={this.props.symbolName} disabled = {this.props.valueType !== 'symbol'} onChange = {this.props.handleChangeRSSymbol}></input></label>
                                        {this.props.hasSymbolError && <div className="length-error">
                                            {this.props.errorMessage}
                                        </div>}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr className="price-line-hr" />
                        <div className="row">
                            <div className="col-sm-12">
                                <h5>{RSLineTranslateHelper.ChooseLineDisplaySetting}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-10 col-sm-push-1 color-n-weight">
                                <div className="col-sm-6">
                                    <span className="form-head">{TranslateHelper.Color}</span>
                                    <div className="form-field"><ColorPicker width={colorPickerWidth} color={this.props.RSlineColor} onChangeComplete={this.props.updateRSLineColor.bind(this, "RS")} /></div>
                                </div>
                                <div className="col-sm-6">
                                    <span className="form-head">{TranslateHelper.Weight}</span>
                                    <div className="form-field">
                                        <div className="line-picker">
                                            {thickness.map((item, index) =>
                                                (<a data-disable-track-price="true" key={index}
                                                    className={item.value === this.props.RSlineThickness ?  `${item.weight} active`: item.weight}                                                            
                                                    onClick={() => this.props.updateRSLineThickness("RS", item.value)}>
                                                    <span className="line">
                                                        <hr />
                                                    </span>
                                                </a>)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="price-line-hr" />
                        <div className="row">
                            <div className="col-sm-12">
                                <input type="checkbox" name="show-ma" defaultChecked={this.props.showMALine} onChange={ this.props.toggleShowRSMovingAverage }/>
                                <label htmlFor="show-ma">{RSLineTranslateHelper.ShowMALine}</label>
                            </div>
                        </div>
                        <div className={!this.props.showMALine ? "row mask" : "row"}>
                            <div className="col-sm-10 col-sm-push-1 color-n-weight">
                                <div className="col-sm-6">
                                    <span className="form-head">{TranslateHelper.Length}</span>
                                    <div className="form-field">
                                        <input className="length-txt" onFocus={()=>this.props.updateSymbolError()} id="MAlengthInput" value={this.props.maLineLength} ref={(ref) => (this.lengthinput = ref)} onChange={this.handleChangeLength}></input>
                                        <span className="length-note">(1-200)</span>
                                    </div>
                                    {this.props.hasLengthError && <div className="length-error">
                                        {this.props.errorMessage}
                                    </div>}
                                </div>
                                <div className="col-sm-6">
                                <div className="form-field flow-x-visi">
                                        <div className="sma-ema">
                                        <input type="radio" name="movingaverageType" value="SMA" id="sma" defaultChecked={this.props.maLineType === 'SMA'} onChange={this.props.toggleRSMovingAverageLineType.bind(this, 'SMA')}  />
                                            <label htmlFor="sma">SMA</label>
                                        </div>
                                        <div className="sma-ema">
                                            <input type="radio" name="movingaverageType" value="EMA" id="ema" defaultChecked={this.props.maLineType === 'EMA'} onChange={this.props.toggleRSMovingAverageLineType.bind(this, 'EMA')} />
                                            <label htmlFor="ema">EMA</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-10 col-sm-push-1 color-n-weight">
                                <div className="col-sm-6">
                                    <span className="form-head">{TranslateHelper.Color}</span>
                                    <div className="form-field"><ColorPicker width={colorPickerWidth} color={this.props.MAlineColor} onChangeComplete={this.props.updateRSLineColor.bind(this, "MA")} /></div>
                                </div>
                                <div className="col-sm-6">
                                    <span className="form-head">{TranslateHelper.Weight}</span>
                                    <div className="form-field">
                                        <div className="line-picker">
                                            {thickness.map((item, index) =>
                                                (<a data-disable-track-price="true" key={index}
                                                    className={item.value === this.props.MAlineThickness ? `${item.weight} active`: item.weight}                                                            
                                                    onClick={() => this.props.updateRSLineThickness("MA", item.value)}>
                                                    <span className="line">
                                                        <hr />
                                                    </span>
                                                </a>)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!this.props.isFund && <hr className="price-line-hr" />}
                        {!this.props.isFund && <div className="row show-checks">
                            {!this.props.isSymbolIndex &&
                                <div className="col-sm-12">
                                    <input type="checkbox" name="show-rank" defaultChecked={this.props.showRank} onChange={ this.props.toggleShowRSRank }/>
                                    <label htmlFor="show-rank">{RSLineTranslateHelper.ShowRSRank}</label>
                                </div>
                            }
                            <div className="col-sm-12">
                                <input type="checkbox" name="show-grade" defaultChecked={this.props.showGrade} onChange={ this.props.toggleShowRSGrade }/>
                                <label htmlFor="show-grade">{RSLineTranslateHelper.ShowADLettingGrade}</label>
                            </div>
                        </div>}
                    </div>
                </div>
            </Modal.Body>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers }) =>{
    const { RSLineReducer } = DatagraphReducers
    const state = {}
    state.showSettingDialog = RSLineReducer.isRSLineDialogOpen;
    state.rsLineNum = RSLineReducer.rsLineNum;
    state.countries = RSLineReducer.countries;
    state.countryIndex = RSLineReducer.countryIndex;
    state.selectedCountry = RSLineReducer.selectedCountry;
    state.regions = RSLineReducer.regions;
    state.valueType = RSLineReducer.valueType;
    state.selectedRegion = RSLineReducer.selectedRegion;
    state.industryGroups = RSLineReducer.industryGroups,
    state.selectedGroup = RSLineReducer.selectedGroup;
    state.symbolName = RSLineReducer.symbolName;
    state.groups = RSLineReducer.groups;
    state.showRank = RSLineReducer.showRank;
    state.showGrade = RSLineReducer.showGrade;
    state.showMALine = RSLineReducer.showMALine;
    state.maLineType = RSLineReducer.maLineType;
    state.MAlineThickness = RSLineReducer.lineThickness.MA;
    state.RSlineThickness = RSLineReducer.lineThickness.RS;
    state.MAlineColor = RSLineReducer.lineColor.MA;
    state.RSlineColor = RSLineReducer.lineColor.RS;
    state.hasLengthError = RSLineReducer.hasLengthError;
    state.hasSymbolError = RSLineReducer.hasSymbolError;
    state.errorMessage = RSLineReducer.errorMessage;
    state.maLineLength = RSLineReducer.maLineLength;
    state.isSymbolGroup = RSLineReducer.isSymbolGroup;
    state.isSymbolIndex = RSLineReducer.isSymbolIndex;
    state.isFund = RSLineReducer.isFund;
    state.HeaderText = `${RSLineTranslateHelper.RS_Line} ${RSLineReducer.rsLineNum}`;
    state.headerClass = "rs-line"
    return state;
}
const mapDispatchToProps = (dispatch) =>({
    toggleShowRSRank: ()=> dispatch(toggleShowRSRank()),
    toggleShowRSGrade: ()=> dispatch(toggleShowRSGrade()),
    toggleShowRSMovingAverage: ()=> dispatch(toggleShowRSMovingAverage()),
    toggleRSMovingAverageLineType: (maLineType)=> dispatch(toggleRSMovingAverageLineType(maLineType)),
    updateRSLineColor: (lineType, lineColor)=> dispatch(updateRSLineColor(lineType, lineColor)),
    updateRSLineThickness: (lineType, lineThickness)=> dispatch(updateRSLineThickness(lineType, lineThickness)),
    updateRSLength: (maLineLength)=> dispatch(updateRSLength(maLineLength)),
    updateRSLengthError: (errorMessage)=> dispatch(updateRSLengthError(errorMessage)),
    cancelLineDialog: ()=> dispatch(cancelRSLineDialogSetting()),
    saveLineDialog: ()=> dispatch(saveRSLineDialogSetting()),
    handleRSLineDialogCountryChange: (event)=> dispatch(handleRSLineDialogCountryChange(event.target.value)),
    handleRSLineDialogRegionChange: (event)=> dispatch(handleRSLineDialogRegionChange(event.target.value)),
    handleRSLineDialogGroupChange: (event)=> dispatch(handleRSLineDialogRGroupChange(event.target.value)),
    handleChangeRSValueType: (valueType)=> dispatch(handleChangeRSValueType(valueType)),
    handleChangeRSSymbol: (event)=> dispatch(handleChangeRSSymbol(event.target.value)),
    updateSymbolError: ()=> dispatch(updateSymbolError())
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(RSLineDialog));