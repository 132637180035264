import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetScreenScheduleListRequest extends BaseServiceRequest {
    constructor() {
        super();
        super.addRequestUri("getscreenschedulelist.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "SchedulerScreenListResponse";
    }
}
export default GetScreenScheduleListRequest;