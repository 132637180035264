import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

export default class GetListBenchmarkStatsRequest extends BaseServiceRequest {
    constructor(listId, cacheId, weightType) {
        super();
        super.addRequestUri("benchmarkstats.pb");
        super.addPathParameter(listId.toString());
        super.addRequestParameter("cache", cacheId.toString());
        super.addRequestParameter("w", weightType.toString());
        super.addRequestParameter("rts", "0");//((int)WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.IntradaySourceType).ToString());
        super.addRequestParameter("cst", "1");//((int)ColumnSetViewType.LISTVIEW).ToString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListBenchmarkStatsResponse";
    }
}