import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import SymbolType from 'SymbolType';

const sbxText = LocalizationStore.getTranslatedData("sb_x", "x");
const sbEmployeeText = LocalizationStore.getTranslatedData("sb_employ", "EMPLOYEES");

export default class MiscData {
  constructor(data) {
    this.labelWidth = 29;
    this.setMiscData(data, data.AnnualMiscBlockData, data.WeeklyMiscBlockData);
  }

  setMiscData(data, annualMiscData, weeklyMiscData) {
    if (annualMiscData.LfyEnd) {
      this.threeYrDisplayValue = `${(annualMiscData.LfyEnd - 2).toString()}-${annualMiscData.LfyEnd.toString().substr(2, 2)}`;
    }
    this.debtpct1 = weeklyMiscData.Debt1 || weeklyMiscData.Debt1 === 0 ? `${this.setPercentData(weeklyMiscData.Debt1)}%` : "--";
    this.debtpct2 = weeklyMiscData.Debt2 || weeklyMiscData.Debt2 === 0 ? `${this.setPercentData(weeklyMiscData.Debt2)}%` : "--";
    this.debtpct3 = weeklyMiscData.Debt3 || weeklyMiscData.Debt3 === 0 ? `${this.setPercentData(weeklyMiscData.Debt3)}%` : "--";
    this.taxpct1 = weeklyMiscData.TaxRate1 || weeklyMiscData.TaxRate1 === 0 ? `${this.setPercentData(weeklyMiscData.TaxRate1)}%` : "--";
    this.taxpct2 = weeklyMiscData.TaxRate2 || weeklyMiscData.TaxRate2 === 0 ? `${this.setPercentData(weeklyMiscData.TaxRate2)}%` : "--";
    this.taxpct3 = weeklyMiscData.TaxRate3 || weeklyMiscData.TaxRate3 === 0 ? `${this.setPercentData(weeklyMiscData.TaxRate3)}%` : "--";
    this.backLog1 = weeklyMiscData.BackLog1 || weeklyMiscData.BackLog1 === 0 ? `${this.setPercentData(weeklyMiscData.BackLog1)}%` : "--";
    this.backLog2 = weeklyMiscData.BackLog2 || weeklyMiscData.BackLog2 === 0 ? `${this.setPercentData(weeklyMiscData.BackLog2)}%` : "--";
    this.backLog3 = weeklyMiscData.BackLog3 || weeklyMiscData.BackLog3 === 0 ? `${this.setPercentData(weeklyMiscData.BackLog3)}%` : "--";
    this.rnd = weeklyMiscData.RandD && weeklyMiscData.RandD !== 0.0 ? `${ExtremeDataValue.abbreviateSmallValueFixed(weeklyMiscData.RandD)}%` : "";
    const factor = data.StockInfo ? data.StockInfo.SymTypeEnum === SymbolType.INTERNATIONALSTOCK ? 1 : 100 : 1;
    this.bckValue = weeklyMiscData.BookVal && weeklyMiscData.BeginYear ? `${ExtremeDataValue.abbreviateSmallValueWithEnforedTwoDecimal(weeklyMiscData.BookVal / factor) + sbxText} '${weeklyMiscData.BeginYear.toString().substr(2, 2)}` : "";
    this.divCurr = data.CurrencyLabel;
    //this.divCurr = data.CurrencyLabel.replace(data.StockInfo.DefaultCurrency.Code, data.StockInfo.CurrencyFormat.unit);
    /*  Fix for PANWEB-2076   */
    const dividend = weeklyMiscData.Dividend; /*  * data.StockInfo.CurrencyFormat.ratio;  */
    this.div = weeklyMiscData.Dividend ? this.checkLabelWidth(dividend) ? ExtremeDataValue.abbreviateSmallValueFixed(dividend) : ExtremeDataValue.getAbbrivatedString(dividend, 2) : "";
    this.divYield = weeklyMiscData.Yield ? `(${ExtremeDataValue.abbreviateValWithDecLimit(weeklyMiscData.Yield, false, true, Number.MAX_VALUE, Number.MIN_VALUE, Number.MIN_VALUE, Number.MIN_VALUE)}%)` : "";
    this.divGrowth = weeklyMiscData.DivGRRT || weeklyMiscData.DivGRRT === 0 ? (weeklyMiscData.DivGRRT < 0 ? `${ExtremeDataValue.abbreviateValWithDecLimit(weeklyMiscData.DivGRRT)}%` : `+${ExtremeDataValue.abbreviateValWithDecLimit(weeklyMiscData.DivGRRT)}%`) : "";
    this.divStab = weeklyMiscData.DivStability || weeklyMiscData.DivStability === 0 ? `(${ExtremeDataValue.abbreviateValWithDecLimit(weeklyMiscData.DivStability)})` : "";
    const beta = weeklyMiscData.Beta ? ExtremeDataValue.formatValue(weeklyMiscData.Beta, 2) : (weeklyMiscData.Beta === 0 ? "N/A" : "");
    const betaStab = (weeklyMiscData.BetaST || weeklyMiscData.BetaST === 0) && weeklyMiscData.Beta !== 0 ? `(${ExtremeDataValue.formatValue(weeklyMiscData.BetaST, 2)})` : "(0.00)";
    this.betaDisplayValue = beta + betaStab;
    const alpha = weeklyMiscData.Alpha || weeklyMiscData.Alpha === 0 ? ExtremeDataValue.formatValue(weeklyMiscData.Alpha, 2) : "";
    const alphaStab = weeklyMiscData.AlphaST || weeklyMiscData.AlphaST === 0 ? ` (${ExtremeDataValue.formatValue(weeklyMiscData.AlphaST, 2)})` : "";
    this.alphaDisplayValue = alpha + alphaStab;
    this.setEmployeePercentChange(data);
    if (typeof weeklyMiscData.Dividend !== 'undefined' && weeklyMiscData.Dividend !== null && typeof weeklyMiscData.Yield !== 'undefined' && weeklyMiscData.Yield !== null) {
      this.dividendAmountAndDividendYieldDisplayValue = this.div + this.divYield;
    }
    if (typeof weeklyMiscData.DivGRRT !== 'undefined' && weeklyMiscData.DivGRRT !== null && typeof weeklyMiscData.DivStability !== 'undefined' && weeklyMiscData.DivStability !== null) {
      this.AnnualizedDividendAmountDatumState = "Available";
    }
    this.DividendGrowth = weeklyMiscData.DivGRRT;
    this.Beta = weeklyMiscData.Beta;
  }

  setPercentData(data) {
    if (Math.abs(data) > 1000) {
      return ExtremeDataValue.getAbbrivatedString(data, 1);
    } else {
      return ExtremeDataValue.formatValue(data, 0);
    }
  }

  setEmployeePercentChange(data) {
    if (!data || !data.EmployeePercentChange) {
      this.employeePercentChangeLabel = "";
      this.employeeNumber = "";
      this.employeePercentChangeData = "";
      this.employeePercentChangeIsNegative = false;
      return;
    }
    const lfyEnd = data.AnnualMiscBlockData.LfyEnd ? data.AnnualMiscBlockData.LfyEnd : '';
    this.employeePercentChangeLabel = `${lfyEnd} ${sbEmployeeText} `;
    const employeePercentChangeLabel = `${lfyEnd} EMPLOYEES `;
    this.employeePercentChangeData = data.EmployeePercentChange.substr(employeePercentChangeLabel.length);
    if (this.employeePercentChangeData.indexOf("(") >= 0) {
      const _employeePercentChangeData = this.employeePercentChangeData.split('(');
      this.employeeNumber = ExtremeDataValue.getFormattedNumber(_employeePercentChangeData[0]);
      this.employeePercentChangeIsNegative = _employeePercentChangeData[1][0] === "-";
      this.employeePercentChangeData = ` (${_employeePercentChangeData[1]}`;
    }
  }
  checkLabelWidth(result) {
    const absMaxResult = ExtremeDataValue.getFormattedNumber(result, 0);
    const font = "13 'calibri'";
    const width = absMaxResult.getTextWidth(font);
    return width < this.labelWidth;
  }
}