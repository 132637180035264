import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetCurrencyViewSummaryBlockRequest extends BaseServiceRequest {
    constructor(msid, osid, symbol) {
        super();
        super.addPathParameter(msid.toString());
        super.addRequestParameter("csym", symbol);
        super.addRequestParameter("cosid", osid.toString());
        super.addRequestUri("currencysumblock.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "CurrencySummaryBlockResponse";
    }
}

export default GetCurrencyViewSummaryBlockRequest;
