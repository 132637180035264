import Serializable from "Serializable";
class RelativeStrenghtLineSettings extends Serializable {

    constructor(rsLineNum, isVisible) {
        super();
        this.IsAvailable = true;
        this.IsVisible = isVisible;
        this.rsLineNum = rsLineNum;
        this.lineThickness = 1;
        this.maLineThickness = 1;
        this.maLineType = 'SMA';
        this.maLineLength = 20;        
        this.region = "North America";
        this.country = "United States";
        this.countryIndex = "0S&P5";
        this.showMaLine = false;
        this.showRank = true;
        this.showGrade = true;
        if (rsLineNum == 1) {
            this.IndexSymbol = undefined;
            this.symbolName = undefined;
            this.lineColor = 'P00CCCC009999';
            this.maLineColor = 'PFFFF00676700';
            this.valueType = 'default';
            this.selectedGroup = 20;
        }
        else {
            this.IndexSymbol = undefined;
            this.symbolName = undefined;
            this.lineColor = 'PFF0000670000';
            this.maLineColor = 'P66CCFF013CB3';
            this.valueType = 'group';
            this.selectedGroup = 20;
        }
    }

    onSeralisedInfoRecieved(info) {
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
        this.rsLineNum = info.rsLineNum;
        this.IsAvailable = true;        
        this.lineThickness = info.lineThickness !== undefined ? info.lineThickness : 1;
        this.maLineThickness = info.maLineThickness !== undefined? info.maLineThickness : 1;
        this.maLineType = info.maLineType !== undefined ? info.maLineType : 'SMA';
        this.maLineLength = info.maLineLength !== undefined ? info.maLineLength : 20;        
        this.region = info.region !== undefined ? info.region : "North America";
        this.country = info.country !== undefined ? info.country : "United States";
        this.countryIndex = info.countryIndex !== undefined ? info.countryIndex : "0S&P5";
        this.showMaLine = info.showMaLine !== undefined ? info.showMaLine : false;
        this.showRank = info.showRank !== undefined ? info.showRank : true;
        this.showGrade = info.showGrade !== undefined ? info.showGrade : true;
        if (this.rsLineNum == 1) {
            this.IndexSymbol = info.IndexSymbol !== undefined ? info.IndexSymbol : undefined;
            this.symbolName = info.symbolName != undefined ? info.symbolName : undefined;
            this.lineColor = info.lineColor !== undefined ? info.lineColor : 'P00CCCC009999';
            this.maLineColor = info.maLineColor !== undefined ? info.maLineColor : 'PFFFF00676700';
            this.valueType = info.valueType !== undefined ? info.valueType : "default";
            this.selectedGroup = info.selectedGroup != undefined ? info.selectedGroup : 20;
        }
        else {
            this.IndexSymbol = info.IndexSymbol !== undefined ? info.IndexSymbol : undefined;
            this.symbolName = info.symbolName != undefined ? info.symbolName : undefined;
            this.lineColor = info.lineColor !== undefined ? info.lineColor : 'PFF0000670000';
            this.maLineColor = info.maLineColor !== undefined ? info.maLineColor : 'P66CCFF013CB3';
            this.valueType = info.valueType !== undefined ? info.valueType : "group";
            this.selectedGroup = info.selectedGroup != undefined ? info.selectedGroup : 20;
        }
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            rsLineNum: this.rsLineNum,
            lineColor: this.lineColor,
            lineThickness: this.lineThickness,
            maLineColor: this.maLineColor,
            maLineThickness: this.maLineThickness,
            maLineType: this.maLineType,
            maLineLength: this.maLineLength,
            valueType: this.valueType,
            region: this.region,
            country: this.country,
            countryIndex: this.countryIndex,
            showMaLine: this.showMaLine,
            showRank: this.showRank,
            showGrade: this.showGrade,
            IndexSymbol: this.IndexSymbol,
            symbolName: this.symbolName,
            selectedGroup: this.selectedGroup
        };
    }

    getObjectMapKey() {
        return "RelativeStrenghtLineSettings";
    }
}

export default RelativeStrenghtLineSettings;
