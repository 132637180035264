import { useSelector } from 'react-redux';
import React, { useState } from "react";

function EstimateControl() {
    const positiveBrushText = useSelector((state) => state.appColor.posDTColor)
    const negativeBrushText = useSelector((state) => state.appColor.negDTColor);
    const data = useSelector((state) => state.summaryReducer.estimateData)
    const [state, setState] = useState({ hover: false, dogEarContentHeight: 42, dogEar: false })

    const getClassName = () => {
        let className = "info-row";
        if (state.hover) {
            className = `${className} hover`;
        }
        return className;
    }
    const syncHover = (hover) => {
        setState({ hover: hover });
    }
    const getDogEar = (showCarryOver, top) => {
        if (showCarryOver) {
            return (
                <div
                    className="dogEar"
                    onMouseDown={(e) => handleMouseDown(e, top, true)}
                    onMouseLeave={handleMouseDown}
                >
                </div>
            );
        } else {
            return "";
        }
    }
    const renderIndicator = (trends) => {
        const positiveStyle = { color: positiveBrushText }
        const negativeStyle = { color: negativeBrushText }

        if (trends === 'up') {
            return <i className="fa fa-caret-up" style={positiveStyle}></i>
        }
        if (trends === 'down') {
            return <i className="fa fa-caret-down" style={negativeStyle}></i>
        }
        return "";
    }
    const renderCurrEstimate = () => {
        let color = "#FFF";
        if (data) {
            color = (data.isCurPercPositive) ? positiveBrushText
                : negativeBrushText;
        }

        const carryOver = data ? data.carryOver : false;
        const dogEar = getDogEar(carryOver, 16);
        return <div className="data-cell">
            <div className="indicator-summary-block">
                <div className="saprater-up-down"></div>
                {data && data.currYearTrends3 &&
                    <div className={data && data.currYearTrends3 === "up" ? "estimateUp" : "estimateDown"}>{renderIndicator(data ? data.currYearTrends3 : "")}</div>
                }
                {data && data.currYearTrends2 &&
                    <div className={data && data.currYearTrends2 === "up" ? "estimateUp" : "estimateDown"}>{renderIndicator(data ? data.currYearTrends2 : "")}</div>
                }
                {data && data.currYearTrends &&
                    <div className={data && data.currYearTrends === "up" ? "estimateUp" : "estimateDown"}>{renderIndicator(data ? data.currYearTrends : "")}</div>
                }
            </div>

            <span className="current-estimate">{data ? data.currEstimate : ""}</span>

            <span className="current-percent">
                <span style={{ color: color }}>{data ? data.curPercChange : ""}</span>
                {dogEar}
            </span>
        </div>;
    }
    const renderNxtEstimate = () => {
        const carryOverNextYear = data ? data.carryOverNextYear : false;
        const dogEar = getDogEar(carryOverNextYear, 16);
        let color = "#FFF";
        if (data) {
            color = (data.isNxtPercPositive) ? positiveBrushText
                : negativeBrushText;
        }

        return <div className="data-cell">
            <div className="indicator-summary-block">
                <div className="saprater-up-down"></div>
                {data && data.nxtYearTrends3 &&
                    <div className={data && data.nxtYearTrends3 === "up" ? "estimateUp" : "estimateDown"}>{renderIndicator(data ? data.nxtYearTrends3 : "")}</div>
                }
                {data && data.nxtYearTrends2 &&
                    <div className={data && data.nxtYearTrends2 === "up" ? "estimateUp" : "estimateDown"}>{renderIndicator(data ? data.nxtYearTrends2 : "")}</div>
                }
                {data && data.nxtYearTrends &&

                    <div className={data && data.nxtYearTrends === "up" ? "estimateUp" : "estimateDown"}>{renderIndicator(data ? data.nxtYearTrends : "")}</div>
                }
            </div>

            <span className="current-estimate">{data ? data.nxtEstimate : ""}</span>
            <span className="current-percent" style={{ color: color }}>{data ? data.nxtPercChange : ""}
                {dogEar}
            </span>
        </div>;
    }
    const renderDataRow = () => (
        <div className={getClassName()}
            onMouseOver={() => syncHover(true)}
            onFocus={() => syncHover(true)}
            onMouseLeave={() => syncHover(false)}>
            {renderCurrEstimate()}
            {renderNxtEstimate()}
        </div>)
    const getDogEarContent = () => (<div className="dogEarMain dogear-summery-block" style={{
        //height: this.state.dogEarContentHeight,
        //left: this.state.dogEar.x,
        //top: this.state.dogEar.y
    }}>
        <div className="deBorder">
            <div className="deContent deEstimateWidth">
                {state.dogEar.text}
            </div>
        </div>
    </div>)
    const showDogEar = (dogEarObj) => {
        setState({ dogEar: dogEarObj });
    }
    const handleMouseDown = (e, top, show = false) => {
        if (show) {
            showDogEar({
                x: e.target.offsetParent.offsetLeft - 50,
                y: top - state.dogEarContentHeight,
                text: "Calculation based on a negative number in the previous year"
            });
        }
        else {
            showDogEar();
        }
    }

    return (
        <div className="estimate-info-block">
            <div className="info-row">
                <div className="header-cell"><span>{data ? data.fiscalDateCurr : ""}</span></div>
                <div className="header-cell"><span>{data ? data.fiscalDateNext : ""}</span></div>
            </div>
            {renderDataRow()}
            {state.dogEar && getDogEarContent()}
        </div>
    );
}
export default EstimateControl
