import BlockType from '../../../Constants/BlockType';
import ContextMenuConstants from '../../../Constants/ContextMenuConstants';
import { IndicatorContextConst } from '../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants';
import { PriceChartConstants } from '../../../Constants/PriceChartConstants'
const { ActionTypes } = PriceChartConstants;

const initialState = {
    clientX: 0,
    clientY: 0,
    contextMenuXPos: 0,
    contextMenuYPos: 0,
    priceAtLocation: '',
    showContextMenu: false,
    contextMenuOptions: {},
    selectedConextMenuOption: [],
    contextMenuObj: {}
};

const ContextMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SHOW_CONTEXT_MENU_POS:
            return {
                ...state,
                selectedConextMenuOption: action.selectedConextMenuOption,
                contextMenuXPos: action.clientX,
                contextMenuYPos: action.clientY,
                priceAtLocation: action.priceAtLocation,
                contextMenuObj: {},
                showContextMenu: true
            }
        case PriceChartConstants.ActionTypes.UPDATE_IS_PRICE_DATA_READY:
            return {
                ...initialState
            }
        case ActionTypes.HIDE_PRICE_CONTEXT_MENU:
            return {
                ...state,
                showContextMenu: false
            }
        case ActionTypes.UPDATE_CONTEXT_HIT:
            return {
                ...state,
                contextMenuObj: action.contextMenuObj,
            }
        case ActionTypes.SHOW_PRICE_CONTEXT_MENU: {
            const {clientX, clientY} = action.event;
            if(action.event.preventDefault){
                action.event.preventDefault();

            }

            let selectedConextMenuOption = state.contextMenuOptions[ContextMenuConstants.ALERT_MENU]
            if(state.contextMenuObj.lineID?.indexOf(' SMA') > 0 || state.contextMenuObj.lineID?.indexOf(' EMA') > 0 ){
                selectedConextMenuOption = state.contextMenuOptions[ContextMenuConstants.MA_MENU][state.contextMenuObj.index]
            }
            else if(state.contextMenuObj.lineKey){
                selectedConextMenuOption = [...state.contextMenuOptions[state.contextMenuObj.lineID], state.contextMenuOptions[IndicatorContextConst.Indicator_Lines][state.contextMenuObj.lineKey]]
            }
            else if(state.contextMenuObj.lineID === BlockType.ExternalData){
                state.contextMenuOptions[state.contextMenuObj.lineID][0].header = state.contextMenuObj.header
                state.contextMenuOptions[state.contextMenuObj.lineID][0].listId = state.contextMenuObj.listId
                selectedConextMenuOption = state.contextMenuOptions[state.contextMenuObj.lineID]
            }
            else if(state.contextMenuOptions[state.contextMenuObj.lineID]){
                selectedConextMenuOption = state.contextMenuOptions[state.contextMenuObj.lineID]
            }

            selectedConextMenuOption = selectedConextMenuOption === undefined ? [] : selectedConextMenuOption;
            return {
                ...state,
                selectedConextMenuOption,
                showContextMenu: true,
                priceLocation:  action.top === 0 ? 0 : clientY - action.top,
                contextMenuXPos: clientX, 
                contextMenuYPos: clientY,
                contextMenuObj: {}
            }
        }
        case ActionTypes.UPDATE_CONTEXT_MENU_OPTIONS:
            return {
                ...state,
                contextMenuOptions: action.contextMenuOptions
            }
        default:
            return state;
    }
}
export default ContextMenuReducer;