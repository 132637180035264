import { connect } from 'react-redux';
import { onFooterAction, handleLayerDeleteConfirmPopup } from "../../../../../../Actions/AnnotationActions";
import React, { Component } from 'react';

class RIPanelAnnotationFooter extends Component {

    render() {
        let userCreatedLayerNum = 0;
        this.props.LayersList.map((layer) => {
            if (!layer.isSharedLayer) {
                userCreatedLayerNum++;
            }
        })
        return (
            <div className="annotation-footer-nav">
                <ul>
                    <li className={userCreatedLayerNum < 25 ? "" : "disabled"}>
                        <a onClick={() => userCreatedLayerNum < 25 && this.props.onFooterAction('add')}>
                            <i className="icn-annotate-add"></i>
                        </a>
                    </li>
                    <li className={userCreatedLayerNum < 25 ? "" : "disabled"}>
                        <a onClick={() => userCreatedLayerNum < 25 && this.props.onFooterAction('duplicate')}>
                            <i className="icn-annotate-copy"></i>
                        </a>
                    </li>
                    <li className={this.props.selectedLayer && this.props.selectedLayer.isSharedLayer ? "disabled" : ""}>
                        <a onClick={this.props.selectedLayer && this.props.selectedLayer.isSharedLayer ? null : () => this.props.onFooterAction('share')}>
                            <i className="icn-annotate-share"></i>
                        </a>
                    </li>
                    <li className={this.props.LayersList.length > 1 ? "" : "disabled"}>
                        <a onClick={() => this.props.LayersList.length > 1 && this.props.onDeleteClick(true)}>
                            <i className="icn-annotate-delete"></i>
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { LayersList, selectedLayer } = annotationReducers.annotationLayerManagementReducer;
    return ({ LayersList, selectedLayer });
}

const mapDispatchToProps = (dispatch) => ({
    onFooterAction: (action) => dispatch(onFooterAction(action)),
    onDeleteClick: (showConfirm) => dispatch(handleLayerDeleteConfirmPopup(showConfirm))
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelAnnotationFooter)