import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import { AlertConstants } from "AlertConstants";
import { SettingsConstants } from "SettingsConstants";
import AlertSourceType from "../../Constants/AlertSourceType";
import ListApi from "ListApi";
import AlertsApi from "AlertsApi";
import ConsoleActions from "Actions/ConsoleActions";
import { extend, each, filter, indexOf, find, without, findWhere, sortBy, chain } from "underscore";
import StringUtil from "StringUtil";
import BaseServiceApi from "BaseServiceApi";
import ListExplorerStore from "ListExplorerStore";
import SettingsStore from "SettingsStore";
import NotificationSettingsHelper from "../ConsoleWindow/Settings/Modules/Notification/NotificationSettingsHelper.js"
import moment from "moment";
import PeriodicityType from "PeriodicityType";
import PrefPeriodScaleConstants from "../../Constants/PrefPeriodScaleConstants";
import SplitUtil from "../../Utils/SplitUtil.js";
import DatagraphHelper from "../../Utils/DatagraphHelper.js";
import UserInfoUtil from "UserInfoUtil"
import MessageCenterWindowType from "../../Constants/MessageCenterWindowType";
import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import DataGraphStore from "../NavModules/NavDataGraph/DataGraphStore";
import { alertsDataWorker } from "WebWorker";
import SymbolType from "SymbolType";
import TabDataGraphStore from "../NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore";
import ONeilViewStore from 'ONeilViewStore';
import AlertConditionType from "../../Constants/AlertConditionType";
import LocalizationStore from "../Localization/LocalizationStore";
import LangType from "../../Constants/LangType";
import ConsoleStore from "ConsoleStore";
import _ from "underscore";
import MiniListHelper from "MiniListHelper";
import { dispatch } from "../../Redux/dispatch";
import { removeLineAlertIcon, initAnnotationModel, toggleAlertIconToggle } from "../../Actions/AnnotationActions";
import {prepareContextMenu} from "../../Actions/PricePanelActions";
import { updateTriggeredAlertsList } from "../../Actions/AlertActions";
let _state = undefined;
let ListType = BaseServiceApi.rayData["ListType"];
let ListAlerts = BaseServiceApi.rayData["ListAlerts"];
let ListAlertStatusType = BaseServiceApi.rayData["ListAlertStatusType"];
let MovingAverageType = BaseServiceApi.rayData["MovingAverageType"];
let NotificationSourceType = BaseServiceApi.rayData["NotificationSourceType"];
let NotificationSourceStatus = BaseServiceApi.rayData["NotificationSourceStatus"];
let AlertType = BaseServiceApi.rayData["AlertType"];

let alertsDataWorkerObj = new alertsDataWorker();

let alertDataObj = {
    alertTitle: "",
    alertSubTitle: "",
    sourceId: null,
    sourceTypeId: null,
    alerts: {},
    countryCollection: [],
    selectedFilterItems: [],
    browserFilterText: '',
    browserFilterSelectedIDs: '',
    columnsetCollection: [],
    selectedColumnSet: [],
    selectedColSetString: '',
    columnsetChildItems: [],
    columnsetTreeNodes: [],
    parentColumnSetName: '',
    listAlertSettings: {},
    priceVolumeAlertable: true,
    addRemoveAlertable: false,
    corporateEventAlertable: false,
    priceAlert: null,
    messageCenterState: false,
    listName : '',
    isActiveAlerts: {}
}

let messageCenterData = { showReadMessage: true };

let priceAlertsData = null;

class AlertStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        this.notificationSettings = {};
        this.riPanelAlertsList = [];
        this.highlightedAlertId = null;
        this.notifications = [];
        this.maxNotificationCount = 5;
        this.datagraphAlertsList = [];
        this.showActive = true;
        this.emitWorkerAction = false;
        this.renderListExplorer = false;
        this.renderListView = false;
        this.triggeredAlertsList = [];
        this.shownAlertIds= [];
        this.trendLineAlerts = [];
        this.initializeNotificationList();
    }

    addChangeListener(callback, eventName) {
        this.on(eventName, callback);
    }

    removeChangeListener(callback, eventName) {
        this.removeListener(eventName, callback);
    }

    dispatcherCallback(payload) {

        const action = payload.action;
        const data = action.data;

        switch (action.actionType) {
            case AlertConstants.ActionTypes.OPEN_ALERT:
                this.listAlertsRetrieve(data, action.actionType);
                break;
            case AlertConstants.ActionTypes.OPEN_AND_ALERT:
                this.andAlertsRetrieve(data, action.actionType);
                break;
            case AlertConstants.ActionTypes.OPEN_NOTIFICATION_PREFERENCE:
                this.refreshNotificationSettings(action.actionType);
                break;
            case AlertConstants.ActionTypes.SAVE_NOTIFICATION_PREFERENCE:
                this.saveAlertNotificationSettings(data.notificationSettings, data.userNotificationSettings, action.actionType);
                break;
            case AlertConstants.ActionTypes.SAVE_NOTIFICATION_SUBSCRIPTION:
                this.saveNotificationSubscription(data.endpoint, data.alertType, data.methodType, data.validate, action.actionType);
                break;
            case AlertConstants.ActionTypes.SAVE_ALERT_DATA:
                this.saveAlertData(data.listAlertSettings, data.conditionTypeId, data.alertId, data.priceAlertObj, action.actionType, data.isTriggered);
                break;
            case AlertConstants.ActionTypes.SELECT_ALERT_COLUMN_SET:
                this.setSelectedColumnSet(data);
                this.emit(action.actionType);
                break;
            case AlertConstants.ActionTypes.SELECT_FILTER_BROWSER:
                this.setSelectBrowserFilter(data);
                this.emit(action.actionType);
                break;
            case AlertConstants.ActionTypes.CREATE_PRICE_ALERT:
                this.createPriceAlert(data.msid, data.targetPrice, this.getCurrencyCode(DataGraphStore.getState().SymbolInfo.DefaultCurrency, false), action.actionType, data.note);
                break;
            case AlertConstants.ActionTypes.UPDATE_PRICE_ALERT:
                this.updateAlertRequest(data, action.actionType);
                break;
            case AlertConstants.ActionTypes.DELETE_ALERT:
                this.deleteAlert(data, action.actionType);
                break
            case AlertConstants.ActionTypes.GET_ALL_NOTIFICATIONS:
                this.getAllNotifications(action.actionType, false);
                break;
            case AlertConstants.ActionTypes.OPEN_MESSAGE_CENTER:
                this.getAllNotifications(action.actionType, this.messageCenterState);
                break;
            case AlertConstants.ActionTypes.TOGGLE_ALERT_STATUS:
                this.reactivateAlert(data).then(() => {
                    if (this.messageCenterState) {
                        AlertsApi.getAllNotifications().then((result) => {
                            this.setMessageCenterData(result.notifications);
                            this.emit(action.actionType);
                        });
                    }
                    else {
                        this.emit(action.actionType);
                    }
                });
                break;
            case AlertConstants.ActionTypes.NW_RESET_UNREAD_MESSAGES:
                this.resetUnreadMessages(data.messageCenterWindowType, action.actionType);
                break;
            case AlertConstants.ActionTypes.NW_TOGGLE_STATUS:
                this.toggleNotificationStatus(data.notificationObj).then(() => {
                    this.emit(action.actionType);
                });
                break;
            case AlertConstants.ActionTypes.LOAD_RIPANEL_ALERTS:
                this.getAlertsDataSource(data.showActive).then(() => {
                    this.emit(action.actionType);
                });
                break;
            case AlertConstants.ActionTypes.CREATE_MOVING_AVERAGE_ALERT:
                this.handleMovingAverageAlertAction(data.menuItem, action.actionType);
                break;
            case AlertConstants.ActionTypes.DELETE_LIST_ALERT:
                this.deleteAllListAlerts(data.listId, data.conditionTypeId, action.actionType);
                break;
            case AlertConstants.ActionTypes.TOGGLE_SHOW_ALERTS:
                this.handleToggleShowAlerts(data.showAlerts, action.actionType);
                break;
            case AlertConstants.ActionTypes.HIGHLIGHT_ALERT:
                this.setHighlightedAlert(data.id, action.actionType);
                break;
            case AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED:
                this.handleNewNotificationReceived(data.notifications, action.actionType);
                break;
            case AlertConstants.ActionTypes.REMOVE_NOTIFICATION:
                this.removeNotificationItem(data.index, data.markAsRead);
                this.emit(action.actionType);
                break;
            case AlertConstants.ActionTypes.ALERT_WORKER_COMPLETED:
                const consoleStoreState = ConsoleStore.getStates();
                if(!consoleStoreState.onSwitchToSymbolTab){
                    this.emitWorkerAction = true;
                    consoleStoreState.onSwitchToSymbolTab = false;
                    this.startAlertsDataWorker();
                }
                break;
            case AlertConstants.ActionTypes.DELETE_ALL_ALERTS_BY_MSIDS:
                this.deleteAllAlertsByMsIds(data, action.actionType);
                break;
            case AlertConstants.ActionTypes.SET_MESSAGE_AS_READ:
                this.setMessageAsRead(data.userNotificationID, action.actionType);
                break;
            case AlertConstants.ActionTypes.EDIT_ALERT:
                this.editAlert(data.symbol, action.actionType);
                break;
            case AlertConstants.ActionTypes.DELETE_ALL_ACTIVE_ALERTS:
                this.deleteAllActiveAlerts(data.conditionTypeId,action.actionType);
                break;    
            case SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED:
                this.clearAlertData();
                this.emit(action.actionType);
                break;  
            default:
                break;
        }
        return true;
    }

    clearAlertData(){
        this.riPanelAlertsList = [];
    }
    /* Get Methods  - Start */
    getColumnSet() {
        return alertDataObj.columnsetCollection;
    }

    getSelectedColumnSet() {
        return alertDataObj.selectedColumnSet;
    }

    getBrowserFilterText() {
        return alertDataObj.browserFilterText;
    }

    getAlertSettings() {
        return alertDataObj.listAlertSettings;
    }

    getAlertPriceData() {
        return alertDataObj.priceAlert;
    }

    getPriceVolumeAlertable() {
        return alertDataObj.priceVolumeAlertable;
    }

    getAddRemoveAlertable() {
        return alertDataObj.addRemoveAlertable;
    }

    getCorporateEventAlertable() {
        return alertDataObj.corporateEventAlertable;
    }

    getAlertTitle() {
        return alertDataObj.alertTitle;
    }

    getAlertSubTitle() {
        return alertDataObj.alertSubTitle;
    }

    getListAlertSubTitle() {
        return alertDataObj.listAlertSubTitle;
    }

    getListName() {
        return alertDataObj.listName;
    }

    getIsActiveAlerts() {
        return alertDataObj.isActiveAlerts;
    }
    /* Get Methods  - End */

    /* New Notifications & Message Center sections - Start */

    setMessageCenterState(isOpen) {
        this.messageCenterState = isOpen;
    }

    getMessageCenterState() {
        return this.messageCenterState;
    }

    getAllNotifications(eventName, isOpen) {
        if (!isOpen) {
            AlertsApi.getAllNotifications().then((result) => {
                this.setMessageCenterData(result.notifications);
                this.emit(eventName);
            });
        }
        else {
            this.emit(eventName);
        }
    }

    saveTriggeredAlerts() {
        if (this.triggeredAlertsList && this.triggeredAlertsList.length > 0) sessionStorage.setItem("triggeredAlertsList", JSON.stringify(this.triggeredAlertsList));
    }

    handleNewNotificationReceived(notifications, eventName) {

        notifications = this.extendNotifications(notifications);

        if (this.getUserNotificationSettings().IsAlertSoundOn)
            this.playAlertSound();
        dispatch(updateTriggeredAlertsList(notifications))
        each(notifications, (item) => {

            if (UserInfoUtil.isPatternRecEntitlement(item.alertTypeId)) {

                if (this.getNotificationSettings().notifyInApp)
                    this.addNotification(item);

                if (!StringUtil.isEmpty(messageCenterData.allNotifications))
                    this.extendNotificationItem(item);

                let obj = find(messageCenterData.allNotifications, (itm) => item.userNotificationId == itm.userNotificationId);

                if (messageCenterData.allNotifications && StringUtil.isEmpty(obj)) {
                    messageCenterData.allNotifications.insert(0, item);
                }

                if (item.alertTypeId == AlertType.ALERT_LIST) {

                    this.renderListExplorer = true;
        
                    ListExplorerStore.updateListExplorerForAlerts(item.listid, ListAlertStatusType.Triggered)

                    if (ONeilViewStore._state.listId == item.listid) {

                        let highLightMsid = find(ONeilViewStore._state.highlightSymbolMsIds, (itm) => itm.HighlightMsId == item.msid)

                        if (StringUtil.isEmpty(highLightMsid)) {
                            ONeilViewStore._state.highlightSymbolMsIds.push({
                                HighlightMsId: item.msid,
                                TriggerMessages: [item.message]
                            });
                        }
                        else {
                            highLightMsid.TriggerMessages.push(item.message);
                        }

                        let listDataItem = find(ONeilViewStore.getSourceData(), (itm) => itm.msId == item.msid);

                        if (listDataItem != null) {
                            listDataItem.isTriggeredInstrument = true;
                            listDataItem.ListAlertMessage = StringUtil.isEmpty(listDataItem.ListAlertMessage) ? item.message : listDataItem.ListAlertMessage + "<br/>" + item.message;
                            
                            let recordDataItem = find(listDataItem.recordDatas, (item) => item.dataItemId == -5)

                            if (!recordDataItem.className.includes('summary-list-symbol-triggered'))
                                recordDataItem.className += ' summary-list-symbol-triggered';
                        }
                        
                        this.renderListView = true;
                    }
                }
            }
            
            if(item.annolayerid !== 0 && item.annolayerName !== "") {
                dispatch(initAnnotationModel(true, DataGraphStore.getState().SymbolInfo.Symbol));
            }
            
            let alertId = item.alertId;
            let alertsDatas = this.triggeredAlertsList;
            if (alertsDatas == null) {
                this.triggeredAlertsList = [];
                let alert = { alertId: item.alertId };
                this.triggeredAlertsList.push(alert);
                alertsDatas = this.triggeredAlertsList;
            }
            let alertsData = find(alertsDatas, (item) => item.alertId == alertId);
            if (alertsData == null) {
                alertsDatas.push({ alertId: alertId, isTriggered: 1 });
            }
            else {
                alertsData.isTriggered = 1;
            }


            // // Reset the Trend Line Alert flag.
            // if (alertsData.TLAlertData != null)
            // {
            //     var annotationid = alertsData.TLAlertData.Annotationid;
            //     var graphicsLineModel = _newMainChartViewModel.AnnotationManagementViewModel.ResetAlertFlag(annotationid, false);
            //     ChartPanelModel.States.RaiseRefreshRiAnnotations(graphicsLineModel);
            // }


            // if (ChartPanelModel != null && ChartPanelModel.States != null && ChartPanelModel.States.SymbolInfo != null && ChartPanelModel.States.SymbolInfo.MsId == alertdata.Msid)
            // {
            //     RequestAlertServiceThreadCall();
            // }


        });

        this.emit(eventName);
    }

    playAlertSound() {
        let filePath = 'Asset/sound/alert.mp3';

        let audio = new Audio(filePath);

        setTimeout(() => {
            audio.play();
        });
    }

    initializeNotificationList() {
        for (let i = 0; i < this.maxNotificationCount; i++) {
            this.notifications.push({ type: 'blankNotificationItemData' });
        }
    }

    addNotification(item) {

        let blankObjectCount = filter(this.notifications, (itm) => itm.type == 'blankNotificationItemData').length;

        if (blankObjectCount - 1 >= 0) {
            this.addNotificationItem(item, this.maxNotificationCount - blankObjectCount, false);
        }
        else {
            let notificationObject = this.notifications[this.maxNotificationCount - 1];

            if (!notificationObject.isGroupNotification) {
                let groupNotifications = [];

                //groupNotifications.push(this.notifications[this.maxNotificationCount - 2])
                groupNotifications.push(this.notifications[this.maxNotificationCount - 1])
                groupNotifications.push(item);
                this.notifications.splice(this.maxNotificationCount - 1, 1);

                this.addNotificationItem(groupNotifications, this.maxNotificationCount - 1, true);
            }
            else {
                notificationObject.push(item);
               // this.notifications.splice(this.maxNotificationCount - 1, 1);
            }

            //this.addNotificationItem(item, null, false);
        }


    }

    addNotificationItem(item, index, isGroupNotification = false) {
        extend(item, { timer: 10 });
        extend(item, { isGroupNotification: isGroupNotification })

        if (index != null) {
            this.notifications[index] = item;
        }
        else {
            this.notifications.insert(0, item);
        }
    }

    removeNotificationItem(index, markAsRead) {
        if (markAsRead)
            this.markAsRead(this.notifications[index].userNotificationId);

        this.notifications.splice(index, 1);
        this.notifications.insert(0, { type: 'blankNotificationItemData' });
    }

    getNotifications() {
        return this.notifications;
    }

    setShowReadMessage(value) {
        messageCenterData.showReadMessage = value;
    }

    setMessageAsRead(userNotificationID, eventName) {

        let obj = find(messageCenterData.allNotifications, (itm) => userNotificationID == itm.userNotificationId);
        obj.hasBeenRead = true;

        this.emit(eventName);
    }

    editAlert(symbol, eventName) {
        DataGraphStore.updateIsRIPanelOpen(true);
        this.emit(eventName);
    }

    setMessageCenterData(allNotifications) {
        messageCenterData.allNotifications = this.extendNotifications(allNotifications);
    }

    extendNotifications(allNotifications) {
        each(allNotifications, (item) => {
            this.extendNotificationItem(item);
        });

        return allNotifications;
    }

    extendNotificationItem(item) {
        let status = '';

        switch (item.sourceStatus) {
            case NotificationSourceStatus.ACTIVE:
                status = LocalizationStore.getTranslatedData("mc_active", "Active");
                break;
            case NotificationSourceStatus.INACTIVE:
                status = LocalizationStore.getTranslatedData("mc_inactive", "Inactive");
                break;
            case NotificationSourceStatus.SHARED:
                status = LocalizationStore.getTranslatedData("mc_shared", "Shared");
                break;
            case NotificationSourceStatus.UNSHARED:
                status = LocalizationStore.getTranslatedData("mc_unshared", "Unshared");
                break;
            default:
                break;
        }
        if (item.sourceType == NotificationSourceType.SYSTEM_MESSAGE) {
            status = '';
        }
        else if (item.sourceType == NotificationSourceType.SHARE_LIST){
            item.message = item.message.replace(item.listName, '<i>' + item.listName + '</i>');
        }
        else if(item.sourceType == NotificationSourceType.SHARE_COL_SET){
            item.message = item.message.replace(item.colsetName, '<i>' + item.colsetName + '</i>');
        }
        // || item.sourceType == NotificationSourceType.SHARE_COL_SET
        // || item.sourceType == NotificationSourceType.SHARE_ANNO_LAYER
        if (item.alertTypeId == AlertType.ALERT_STOCK || item.alertTypeId == AlertType.ALERT_LIST) {
            status = '';
        }

        extend(item, { status: status });

        let triggerDate;
        let momentDate = moment("/Date(" + item.date + ")/");
        if (momentDate._d == "Invalid Date")
            item.date = moment(new Date());

        triggerDate = DateHelper.getElapsedTime(moment("/Date(" + item.date + ")/"));

        extend(item, { triggerDate: triggerDate });

        extend(item, { isEmptyMessage: false });
    }

    getMessageCenterData() {
        return messageCenterData;
    }

    toggleNotificationStatus(notifyObj) {

        return new Promise((resolve) => {

            if (StringUtil.isEmpty(notifyObj))
                return;

            this.markAsRead(notifyObj.userNotificationId);

            switch (notifyObj.sourceStatus) {
                case NotificationSourceStatus.ACTIVE:
                    each(messageCenterData.allNotifications, (item) => {
                        if (item.alertId == notifyObj.alertId) {
                            item.sourceStatus = NotificationSourceStatus.INACTIVE;
                            item.status = LocalizationStore.getTranslatedData("mc_inactive", "Inactive");;
                        }
                    });

                    if (notifyObj.alertTypeId == AlertType.ALERT_STOCK) {
                        this.dismissStockAlert(notifyObj.alertId, notifyObj.listAlertTypeId).then(() => {
                            resolve();
                        });
                    }
                    else {
                        this.dismissAlert(notifyObj.alertId).then(() => {
                            resolve();
                        });
                    }
                    break;

                case NotificationSourceStatus.INACTIVE:
                    if (notifyObj.alertTypeId == AlertType.ALERT_STOCK) {
                        AlertsApi.reactivateStockAlert(notifyObj.alertId, notifyObj.listAlertTypeId).then((result) => {
                            if (!result.responseHeader.error) {
                                each(messageCenterData.allNotifications, (item) => {
                                    if (item.userNotificationId == notifyObj.userNotificationId) {
                                        item.sourceStatus = NotificationSourceStatus.ACTIVE;
                                        item.status = LocalizationStore.getTranslatedData("mc_active", "Active");
                                    }
                                });

                            }
                            resolve();
                        });
                    }
                    else {
                        let currentList = filter(messageCenterData.allNotifications, (itm) => itm.alertId == notifyObj.alertId);

                        if (currentList.length > 0) {
                            AlertsApi.reactivate(notifyObj.alertId).then((result) => {

                                if (!result.responseHeader.error) {
                                    each(messageCenterData.allNotifications, (item) => {
                                        if (item.alertId == notifyObj.alertId) {
                                            item.sourceStatus = NotificationSourceStatus.ACTIVE;
                                            item.status = LocalizationStore.getTranslatedData("mc_active", "Active");
                                        }
                                    });
                                }
                                resolve();
                            });
                        }
                    }
                    break;

                default:
                    break;
            }
        });
    }

    markAsRead(messageId) {

        let message = find(messageCenterData.allNotifications, (m) =>
            m.userNotificationId == messageId
        );

        if (StringUtil.isEmpty(message) || message.hasBeenRead)
            return;
        else if (!StringUtil.isEmpty(message))
            message.hasBeenRead = true;

        AlertsApi.markAsRead(messageId);
    }

    dismissStockAlert(alertId, listAlertTypeId) {
        return new Promise((resolve) => {
            AlertsApi.dismissStockAlert(alertId, listAlertTypeId).then((result) => {
                resolve(result);
            });
        });
    }

    dismissAlert(alertId) {
        return new Promise((resolve) => {
            AlertsApi.dismissAlert(alertId).then((result) => {
                this._removeAlert(alertId);
                resolve(result);
            });
        });
    }

    resetUnreadMessages(messageCenterWindowType, eventName) {
        var visitingWindow = !StringUtil.isEmpty(messageCenterWindowType) ? messageCenterWindowType : MessageCenterWindowType.All;
        let notifySource = NotificationSourceType.ALL;

        switch (visitingWindow) {
            case MessageCenterWindowType.All:
                each(messageCenterData.allNotifications, (item) => {
                    item.hasBeenRead = true;
                });
                break;
            case MessageCenterWindowType.Alert:

                notifySource = NotificationSourceType.ALERT;

                each(messageCenterData.allNotifications, (item) => {
                    if (item.sourceType == NotificationSourceType.ALERT)
                        item.hasBeenRead = true;
                });
                break;
            case MessageCenterWindowType.SharedItems:

                notifySource = NotificationSourceType.SHARE_LIST;

                each(messageCenterData.allNotifications, (item) => {
                    if (item.sourceType == NotificationSourceType.SHARE_LIST
                        || item.sourceType == NotificationSourceType.SHARE_COL_SET
                        || item.sourceType == NotificationSourceType.SHARE_ANNO_LAYER)
                        item.hasBeenRead = true;
                });
                break;
            case MessageCenterWindowType.PanarayMessages:

                notifySource = NotificationSourceType.SYSTEM_MESSAGE;

                each(messageCenterData.allNotifications, (item) => {
                    if (item.sourceType == NotificationSourceType.SYSTEM_MESSAGE)
                        item.hasBeenRead = true;
                });
                break;
            default:
                break;
        }

        AlertsApi.markAsReadBySourceType(notifySource).then(() => {
            this.emit(eventName);
        });
    }

    getMessageCenterNotifications(messageCenterWindowType) {
        let notifications;

        let notificationData = {};

        switch (messageCenterWindowType) {
            case MessageCenterWindowType.All:
                notifications = messageCenterData.allNotifications;
                break;
            case MessageCenterWindowType.Alert:
                notifications = filter(messageCenterData.allNotifications, (itm) => itm.sourceType == NotificationSourceType.ALERT);
                break;
            case MessageCenterWindowType.SharedItems:
                notifications = filter(messageCenterData.allNotifications, (itm) =>
                    itm.sourceType == NotificationSourceType.SHARE_LIST
                    || itm.sourceType == NotificationSourceType.SHARE_COL_SET
                    || itm.sourceType == NotificationSourceType.SHARE_ANNO_LAYER
                )
                break;
            case MessageCenterWindowType.PanarayMessages:
                notifications = filter(messageCenterData.allNotifications, (itm) => itm.sourceType == NotificationSourceType.SYSTEM_MESSAGE);
                break;
            default:
                break;
        }

        if (!messageCenterData.showReadMessage) {
            notificationData.notifications = filter(notifications, (itm) => itm.hasBeenRead == messageCenterData.showReadMessage);
        }
        else {
            notificationData.notifications = notifications;
        }

        notificationData.notifications = this.insertEmptyMessages(notificationData.notifications);

        notificationData.unreadCount = filter(notifications, (itm) => itm.hasBeenRead == false).length;

        notificationData.showReadMessage = messageCenterData.showReadMessage;

        return notificationData;
    }

    insertEmptyMessages(notifications) {
        notifications = filter(notifications, (itm) => itm.isEmptyMessage == false);

        let allCount = notifications.length;

        while (allCount < 5) {
            notifications.push({ symbol: '', isEmptyData: true, message: '', triggerDate: '', status: '' });
            allCount++;
        }

        return notifications;
    }
    /* New Notifications & Message Center sections - End */

    /* Notification Settings - Start */

    getAlertNotificationSettings(result) {
        this.notificationSettings = result.settings;
    }

    saveAlertNotificationSettings(data, userNotificationSettings, eventName) {
        if (this.notificationSettings != {}) {
            this.notificationSettings = data;

            if (!StringUtil.isEmpty(userNotificationSettings)) {
                var viewSetting = SettingsStore.getConsoleSettings();
                NotificationSettingsHelper.updateUserNotificationSettings(viewSetting, userNotificationSettings);
                SettingsStore.saveSettings();
            }

            AlertsApi.saveSettings(data).then(() => {
                if (eventName)
                    this.emit(eventName);
            });
        }
    }

    handleToggleShowAlerts(showAlerts, eventName) {
        let userSettings = this.getUserNotificationSettings();
        userSettings.IsShowAlertsIcon = showAlerts;
        this.saveAlertNotificationSettings(this.notificationSettings, userSettings, eventName);
    }

    saveNotificationSubscription(endpoint, alertType, methodType, validate, eventName) {
        AlertsApi.saveNotificationSubscription(endpoint, alertType, methodType, validate).then(() => {
            this.refreshNotificationSettings(eventName);
        });
    }

    getUserNotificationSettings() {
        return SettingsStore.getConsoleSettings().UserNotificationSettings;
    }

    getNotificationSettings() {
        return this.notificationSettings;
    }

    refreshNotificationSettings(eventName) {
        AlertsApi.notificationSettings().then((result) => {
            this.getAlertNotificationSettings(result);
            this.emit(eventName);
        });
    }

    /* Notification Settings - End */

    /* More Symbol Alerts/ List Alerts Section - Start*/
    setSelectBrowserFilter(data) {

        let selectedIDsArr = data.nodeFilterId.split(',');

        each(alertDataObj.countryCollection, (item) => {
            if (indexOf(selectedIDsArr, item.itemId.toString()) != -1) {
                item.isSelected = true;
            }
            else
                item.isSelected = false;
        });

        alertDataObj.browserFilterText = data.filterName;
        alertDataObj.browserFilterSelectedIDs = data.nodeFilterId;
    }

    listAlertsRetrieve(data, eventName) {
        AlertsApi.listAlertsRetrieve(data.sourceId, data.sourceTypeId).then((result) => {
            if (!result.responseHeader.error) {
                alertDataObj.alerts = result.alerts;
                alertDataObj.alerts.sourceId = data.sourceId;
                alertDataObj.alerts.sourceTypeId = data.sourceTypeId;
                alertDataObj.priceAlert = null;
                this.initializeListSettings(data, result).then(() => {
                    this.setAlertSettings(result.alerts.alertDataSetting);
                    this.emit(eventName);
                });
            }
            else {
                ConsoleActions.exceptionHandle();
            }
        });
    }

    andAlertsRetrieve(data, eventName) {
        let currencyCode;
        if(data && data.currencyCode){
            currencyCode = this.getCurrencyCode({Code : data.currencyCode},false);
        }
        AlertsApi.andAlertRetrieve(data.sourceId, data.sourceTypeId,data.alertId,data.sourceId,currencyCode).then((result) => {
            if (!result.responseHeader.error) {
                alertDataObj.alerts = result.alerts.listAlertData;
                alertDataObj.alerts.sourceId = data.sourceId;
                alertDataObj.alerts.sourceTypeId = data.sourceTypeId;
                alertDataObj.priceAlert = result.alerts.priceAlert;
                this.initializeListSettings(data, result).then(() => {
                    this.setAlertSettings(result.alerts.listAlertData.alertDataSetting);
                    this.emit(eventName);
                });
            }
            else {
                ConsoleActions.exceptionHandle();
            }
        });
    }

    initializeListSettings(data, result) {
        return new Promise((resolve) => {
            alertDataObj.priceVolumeAlertable = true;

            alertDataObj.sourceId = data.sourceId;
            alertDataObj.sourceTypeId = data.sourceTypeId

            if (data.sourceTypeId == AlertSourceType.List) {

                let _listTreeItem = null;

                _listTreeItem = ListExplorerStore.contextNode;

                alertDataObj.alertTitle = LocalizationStore.getTranslatedData("alert_list_title", "List Alerts");

                if (!StringUtil.isEmpty(_listTreeItem)) {
                    let listName = _listTreeItem.name;
                    alertDataObj.listName = listName;
                    let chrLimit = 120,
                        str = listName,
                        setStr = '<span class="list-name-ellipsis">'+ str.substring(0, chrLimit) + '</span>',
                        toolTips = str.length>chrLimit?'<span class="ellipsis-dot"><span class="tool-tip">' + str + '</span></span>':"";
                    alertDataObj.listAlertSubTitle = result.alerts.listAlertData ? LocalizationStore.getTranslatedData("ch_msa_sup_aca","And-condition Alerts") : LocalizationStore.getTranslatedData("ch_msa_sup_oca", "Or-condition Alerts");
                    alertDataObj.alertSubTitle =`<div class="tool-tip-box">${LocalizationStore.getTranslatedData("alert_list_msg", 'Alert me when any symbol on ' + setStr + toolTips + ' meets ANY of the following conditions:').replace("{0}", setStr + toolTips)}</div>`;
                    alertDataObj.addRemoveAlertable = ListExplorerStore.isXflList(_listTreeItem) || ListExplorerStore.isPortfolioList(_listTreeItem)
                        || ListExplorerStore.isGflList(_listTreeItem) || ListExplorerStore.isApList(_listTreeItem);
                }

                this.setGeographyFilterData(result);
                this.populateColumnSets(result.alerts.listAlertData ? result.alerts.listAlertData.columnSet : result.alerts.columnSet, data.sourceId, result.alerts.listAlertData ? result.alerts.listAlertData.nocolumnSet : result.alerts.nocolumnSet, data.existAlerts).then(() => {
                    resolve();
                })

                alertDataObj.corporateEventAlertable = true;
            }
            else if (data.sourceTypeId == AlertSourceType.Stock) {

                alertDataObj.alertTitle = LocalizationStore.getTranslatedData("ch_msa_sup_oca", "Or-condition Alerts");

                alertDataObj.alertSubTitle = LocalizationStore.getTranslatedData("ch_msa_msgalert", "Alert me when <b>ANY</b> of the selected conditions are met:"); //' + data.symbol + ' meets
                alertDataObj.corporateEventAlertable = (data.instrumentId ? data.instrumentId : DataGraphStore.getState().SymbolInfo.MsId)  <= 3000000;
                
                alertDataObj.listAlertSubTitle = "";

                alertDataObj.addRemoveAlertable = false;

                resolve();
            }
        });
    }

    populateColumnSets(listalertSelectedColSet, sourceId, nocolumnSet, existAlerts) {

        return new Promise((resolve) => {
            alertDataObj.columnsetCollection = [];
            alertDataObj.selectedColumnSet = [];
            alertDataObj.parentColumnSetName = "";

            ListApi.getColumnSet(sourceId).then((result) => {
                alertDataObj.columnsetTreeNodes = result.data.treeNodes;

                each(alertDataObj.columnsetTreeNodes, (item) => {
                    if (item.listType == ListType.FOLDER_List) {
                        let colsetfolderdata = {
                            colsetId: item.nodeId.low,
                            colsetName: item.name,
                            isFolder: true
                        };

                        alertDataObj.columnsetCollection.push(colsetfolderdata);
                    }
                });

                each(alertDataObj.columnsetTreeNodes, (item) => {
                    if (item.listType == ListType.COLUMNSET_List) {
                        var colsetdata = { colsetId: item.nodeId.low, colsetName: item.name };

                        if (colsetdata.colsetId == listalertSelectedColSet.nodeId.low) {
                            colsetdata.isSelected = true;
                            alertDataObj.selectedColSetString = item.name;
                            alertDataObj.selectedColumnSet = colsetdata;
                        }

                        alertDataObj.columnsetCollection.push(colsetdata);
                    }
                });

                //TO DO SK
                // IsModelListActive = _navListsModel.States.CurrentActiveListTreeItem.IsModelList;

                this.addingNoColumnSetNode(listalertSelectedColSet, nocolumnSet, existAlerts);

                if (!StringUtil.isEmpty(alertDataObj.selectedColumnSet)) {
                    resolve();
                }
                alertDataObj.columnsetChildItems = [];
                
                each(alertDataObj.columnsetTreeNodes, (item) => {
                    let isSelected = false;
                    let colSetArr = [];

                    if (item.listType == ListType.FOLDER_List) { // && item.name == alertDataObj.parentColumnSetName
                        each(item.childNodes, (itm) => {
                            let colsetdata = { isSelected: false, colsetId: itm.nodeId.low, colsetName: itm.name };
                            if ((colsetdata.colsetId == listalertSelectedColSet.nodeId.low) && existAlerts) {
                                colsetdata.isSelected = true;
                                alertDataObj.selectedColSetString = colsetdata.colsetName;
                                alertDataObj.selectedColumnSet = colsetdata;
                                alertDataObj.parentColumnSetName = item.name;
                            }
                            if(item.name == alertDataObj.parentColumnSetName)
                            {
                                isSelected = true;      
                                //alertDataObj.columnsetChildItems.push(colsetdata);
                            }

                            colSetArr.push(colsetdata);
                        });

                        if(isSelected){
                            alertDataObj.columnsetChildItems =  [...alertDataObj.columnsetChildItems , ...colSetArr]
                            isSelected = false;
                        }
                        // if(alertDataObj.columnsetChildItems.length == 0 && alertDataObj.parentColumnSetName != "")
                        // {
                        //     let childItemsObj = find(alertDataObj.columnsetTreeNodes, (item) => item.name  == alertDataObj.parentColumnSetName)
                        //     alertDataObj.columnsetChildItems =  [...alertDataObj.columnsetChildItems , ...childItemsObj.childNodes]
                        // }
                        if (StringUtil.isEmpty(alertDataObj.selectedColumnSet) == null) {
                            alertDataObj.columnsetChildItems = [];
                        }
                        else {
                            return;
                        }
                    }
                });
                resolve();
            });
        });

    }

    getParentColumnSetName() {
        return alertDataObj.parentColumnSetName;
    }

    getColsetChildren(colsetitem) {
        alertDataObj.columnsetChildItems = [];

        each(alertDataObj.columnsetTreeNodes, (item) => {
            if (item.nodeId.low == colsetitem.colsetId) {
                each(item.childNodes, (itm) => {
                    let colsetdata = { colsetId: itm.nodeId.low, colsetName: itm.name };
                    if (colsetdata.colsetId == alertDataObj.selectedColumnSet.colsetId) {
                        colsetdata.isSelected = true;
                    }
                    alertDataObj.columnsetChildItems.push(colsetdata);
                });
            }
        });
    }

    getColumnsetChildItems() {
        return alertDataObj.columnsetChildItems;
    }

    setSelectedColumnSet(data) {
        let colsetitem = data.columnSetDetails;
        let parentColSetName = data.parentColSetName;

        if (!StringUtil.isEmpty(alertDataObj.columnsetCollection)) {
            each(alertDataObj.columnsetCollection, (colset) => {
                colset.isSelected = false;
            });
        }

        if (!StringUtil.isEmpty(alertDataObj.columnsetChildItems)) {
            each(alertDataObj.columnsetChildItems, (colset) => {
                colset.isSelected = false;
            });
        }

        if (colsetitem != null && colsetitem.ColsetId != -1) {
            colsetitem.isSelected = true;
            alertDataObj.selectedColumnSet = colsetitem;
            alertDataObj.selectedColSetString = colsetitem.colsetName;
            alertDataObj.parentColumnSetName = parentColSetName;
        }
    }

    getSelectedColumnSetString() {
        return alertDataObj.selectedColSetString;
    }

    addingNoColumnSetNode(listalertSelectedColSet, nocolumnSet, existAlerts) {
        // Adding no column set node 
        let nocolsetdata = {
            isSelected: false,
            colsetId: nocolumnSet.nodeId.low,
            colsetName: nocolumnSet.name
        };

        if ((StringUtil.isEmpty(alertDataObj.selectedColumnSet) && listalertSelectedColSet.nodeId.low == nocolumnSet.nodeId.low) || existAlerts == false) {
            nocolsetdata.isSelected = true;
            alertDataObj.selectedColSetString = nocolsetdata.colsetName;
            alertDataObj.selectedColumnSet = nocolsetdata;
        }else {
            alertDataObj.selectedColSetString = listalertSelectedColSet.name
        }

        alertDataObj.columnsetCollection.push(nocolsetdata);
    }

    setGeographyFilterData(response) {
        alertDataObj.countryCollection = [];
        alertDataObj.selectedFilterItems = [];

        let browserdata = response.alerts.listAlertData ?  response.alerts.listAlertData.browser.filters : response.alerts.browser.filters;
        each(browserdata, (filtertype) => {
            each(filtertype.filters, (item) => {
                let filteritem = {};

                filteritem.itemId = item.id;
                filteritem.itemName = item.name;
                filteritem.itemCount = item.count;
                filteritem.isSelected = item.selected;
                if (filteritem.isSelected) {
                    alertDataObj.selectedFilterItems.push(filteritem);
                }
                filteritem.parentId = item.parentId;
                filteritem.regionName = item.regionName;
                alertDataObj.countryCollection.push(filteritem);
            })
        });

        this.getSelectedFilterString();
        let _coulntryCollection = _coulntryCollection = chain(alertDataObj.countryCollection).sortBy('itemName').sortBy('regionName').value();
        alertDataObj.countryCollection = _coulntryCollection;
    }

    getCountryCollection() {
        return alertDataObj.countryCollection;
    }

    getSelectedFilterString() {
        let selecteditems = sortBy(alertDataObj.selectedFilterItems, 'regionName');
        let selecteditemcount = selecteditems.length;

        if (selecteditemcount > 1) {
            alertDataObj.browserFilterText = LocalizationStore.getTranslatedData("Country_" + selecteditems[0].itemName.replace(/ /ig,""),selecteditems[0].itemName)
                + "[+" + (selecteditemcount - 1)
                + LocalizationStore.getTranslatedData("alert_Geography_more","more") 
                + "]";
        }
        else if (selecteditemcount == 1) {
            alertDataObj.browserFilterText = LocalizationStore.getTranslatedData("Country_" + selecteditems[0].itemName.replace(/ /ig,""),selecteditems[0].itemName);
        }

        alertDataObj.browserFilterSelectedIDs = '';

        each(alertDataObj.selectedFilterItems, (item) => {
            if (item.isSelected) {
                if (alertDataObj.browserFilterSelectedIDs == '') {
                    alertDataObj.browserFilterSelectedIDs += item.itemId;
                }
                else {
                    alertDataObj.browserFilterSelectedIDs += ',' + item.itemId;
                }
            }
        });
    }

    saveAlertData(listAlertSettings, conditionTypeId, alertId, priceAlertObj, eventName, isTriggered) {
        alertDataObj.listAlertSettings = listAlertSettings;
        if(priceAlertObj && priceAlertObj.currencyCode){
            priceAlertObj.currencyCode = this.getCurrencyCode({Code : priceAlertObj.currencyCode});
        }
        if(isTriggered == undefined ||isTriggered == null){
            isTriggered = 0;
        }
        this.buildAlertData();
        let isAnyPrevSlcNowUnslc = alertDataObj.prevAlertArr.filter(i=>i.isEnabled).some(i=>{
            !alertDataObj.alerts.alertDataSetting.find(x=>x.alertTypeId==i.alertTypeId)
        })
        let isAnyNowSlcDifPrev = alertDataObj.alerts.alertDataSetting.some(i=>{
            let prevItem = alertDataObj.prevAlertArr.find(x=>x.alertTypeId==i.alertTypeId);
            let keys = Object.keys(i);
            for(let j=0;j<keys.length;j++){
                if(prevItem[keys[j]]!=i[keys[j]]){
                    return true;
                }
            }
        })
        let isChanged = isAnyPrevSlcNowUnslc || isAnyNowSlcDifPrev;
        
        let hasSelected = alertDataObj.alerts.alertDataSetting.some(i=>i.isEnabled)
        switch (alertDataObj.sourceTypeId) {
            case AlertSourceType.Stock:
                if(isChanged){
                AlertsApi.saveAlertData(alertDataObj.alerts, null, null, alertDataObj.sourceTypeId, conditionTypeId, alertId, priceAlertObj, isTriggered).then((result) => {
                    if (!result.responseHeader.error) {
                        if(conditionTypeId == AlertConditionType.Or){
                            if(_.findWhere(alertDataObj.alerts.alertDataSetting, {isEnabled: true})){
                                alertDataObj.isActiveAlerts.isActiveOrAlert = 1;
                            }else{
                                alertDataObj.isActiveAlerts.isActiveOrAlert = 0;
                            }
                            
                        }else{
                            alertDataObj.isActiveAlerts.isActiveAndAlert = 1;
                        }
                        this.emit(eventName);
                    }
                    else {
                        this.validateResponse(result);
                    }
                })}else{
                    this.emit(AlertConstants.ActionTypes.ALERT_NOT_CHANGE)
                }
                break;
            //Commented code - Don't delete (R5)
            case AlertSourceType.List:

                let browserfilterStringIds = "-1;" + alertDataObj.browserFilterSelectedIDs + ",;-1;";
                let countryChanged = alertDataObj.browserFilterSelectedIDs != alertDataObj.prevCountryId 
                let columnSetChanged = alertDataObj.selectedColumnSet.colsetId != alertDataObj.prevColsetId

                if(isChanged || hasSelected && (countryChanged || columnSetChanged)){
                AlertsApi.saveAlertData(alertDataObj.alerts, alertDataObj.selectedColumnSet.colsetId, browserfilterStringIds, alertDataObj.sourceTypeId, conditionTypeId, alertId, null, 0).then((result) => {
                    if (!result.responseHeader.error) {
                        //TO DO SK
                        // LogUsageTracking();
                        if (!(listAlertSettings.AdditionSelected || listAlertSettings.RemovalSelected || listAlertSettings.PriceChangeSelected || listAlertSettings.DailyVolumeRateSelected
                            //|| WeeklyVolumeRateSelected
                            || listAlertSettings.AverageDlyDlrVolSelected || listAlertSettings.AverageDlyVolSelected || listAlertSettings.MovingAvg50SmaSelected || listAlertSettings.MovingAvg200SmaSelected
                            || listAlertSettings.MovingAvg200SmaSelected || listAlertSettings.MovingAvg50Cross200Selected || listAlertSettings.DailyBaseChecked || listAlertSettings.WeeklyBaseChecked
                            || listAlertSettings.WeeklyTightChecked || listAlertSettings.DailyFailedBaseChecked || listAlertSettings.WeeklyFailedBaseChecked
                            || listAlertSettings.DailyPriceRisesSelected || listAlertSettings.WeeklyPriceRisesSelected || listAlertSettings.DailyPriceCrossesSelected || listAlertSettings.WeeklyPriceCrossesSelected
                            || listAlertSettings.BuybackAnnouncementSelected || listAlertSettings.DividendChangeSelected || listAlertSettings.StockSplitSelected || listAlertSettings.InsiderTransactionsBuysSelected
                            || listAlertSettings.InsiderTransactionsSellsSelected || listAlertSettings.CeoChangeSelected || listAlertSettings.OfferingsSelected || listAlertSettings.EarningsReleaseSelected || listAlertSettings.AnnouncementsSelected
                            || listAlertSettings.DailyVolumeExceeds50SMASelected || listAlertSettings.BreakingOutToAllTimeHighSelected || listAlertSettings.RSReachesNewHighSelected || listAlertSettings.PullbackTo10WeekLineSelected
                            || listAlertSettings.BreakawayGapSelected || listAlertSettings.Day50BreakOnVolumeSelected || listAlertSettings.GapUpOnEarningsSelected || listAlertSettings.GapDownOnEarningsSelected || listAlertSettings.NegativeAlter1WeekSelected
                            || listAlertSettings.NegativeAlter10WeekSelected || listAlertSettings.DailyEnterExitProfitRangeSelected || listAlertSettings.WeeklyEnterExitProfitRangeSelected
                            || listAlertSettings.DailyEnterExitPivotRangeSelected || listAlertSettings.WeeklyEnterExitPivotRangeSelected || listAlertSettings.DailyEnterExitLossRangeSelected
                            || listAlertSettings.WeeklyEnterExitLossRangeSelected
                        )) {
                            ListExplorerStore.updateListExplorerForAlerts(alertDataObj.alerts.sourceId, 0, conditionTypeId)
                        }
                        else {
                            ListExplorerStore.updateListExplorerForAlerts(alertDataObj.alerts.sourceId, ListAlertStatusType.Active, conditionTypeId)
                        }


                        if (alertDataObj.alerts.sourceId == ONeilViewStore._state.listId) {
                            //if actived list is included
                            //turn off instrument highlight
                            each(ONeilViewStore.getSourceData(), (item) => {
                                extend(item, { 'isTriggeredInstrument': false, 'ListAlertMessage': '' })

                                let recordDataItem = find(item.recordDatas, (item) => item.dataItemId == -5)

                                recordDataItem.className = recordDataItem.className.replace(new RegExp('summary-list-symbol-triggered', 'g'), '');
                            })

                            //update instrument triggered status 

                            ONeilViewStore.getListAlertTriggeredInstruments(alertDataObj.alerts.sourceId).then(() => {

                                this.renderListView = true;

                                each(ONeilViewStore.getSourceData(), (itm) => {

                                    let triggeredinstrument = ONeilViewStore._state.highlightSymbolMsIds.length > 0
                                        ? find(ONeilViewStore._state.highlightSymbolMsIds, (item) =>
                                            item.HighlightMsId == itm.msId)
                                        : null;

                                    let recordDataItem = find(itm.recordDatas, (item) => item.dataItemId == -5)

                                    if (triggeredinstrument != null) {
                                        itm.isTriggeredInstrument = true;

                                        if (!recordDataItem.className.includes('summary-list-symbol-triggered'))
                                            recordDataItem.className += ' summary-list-symbol-triggered'

                                        if (StringUtil.isEmpty(itm.ListAlertMessage))
                                            itm.ListAlertMessage = '';

                                        const len = triggeredinstrument.TriggerMessages;

                                        each(triggeredinstrument.TriggerMessages, (triggeredmsg, i) => {
                                            itm.ListAlertMessage += `${triggeredmsg} 
                                           ${i != len
                                                    ? "<br/>"
                                                    : ''}`;
                                        });
                                    }
                                    else {
                                        recordDataItem.className = recordDataItem.className.replace(new RegExp('summary-list-symbol-triggered', 'g'), '');
                                    }
                                })

                                this.emit(eventName);
                            })

                        }
                        else {
                            this.emit(eventName);
                        }
                    }
                    else {
                        this.validateResponse(result);
                    }
                })}else{
                    this.emit(AlertConstants.ActionTypes.ALERT_NOT_CHANGE)
                }
                break;
            default:
                break;
        }
    }

    setAlertSettings(alertDataSetting) {
        let listalertDataSettingList = alertDataSetting;

        each(listalertDataSettingList, (item) => {
            switch (item.alertTypeId) {
                case ListAlerts.Additions:
                    alertDataObj.listAlertSettings.AdditionSelected = item.isEnabled;
                    break;
                case ListAlerts.Removals:
                    alertDataObj.listAlertSettings.RemovalSelected = item.isEnabled;
                    break;
                case ListAlerts.Dailypricechangeexceeds:
                    alertDataObj.listAlertSettings.PriceChangeSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.PriceChangeThreshold = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                    break;
                case ListAlerts.DailyVolumeRateexceeds:
                    alertDataObj.listAlertSettings.DailyVolumeRateSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.DailyVolumeRateThreshold = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                    if(item.isDelayEnabled && item.isEnabled){
                        alertDataObj.listAlertSettings.DailyVolumeRateDelaySelected = item.isDelayEnabled;
                    }else{
                        alertDataObj.listAlertSettings.DailyVolumeRateDelaySelected = false;
                    }
                    if(item.isEnabled && item.delayTime){
                        alertDataObj.listAlertSettings.DailyVolumeRateDelayThreshold = item.delayTime;
                    }else{
                        alertDataObj.listAlertSettings.DailyVolumeRateDelayThreshold = 0;
                    }
                    break;
                case ListAlerts.WeeklyVolumeRateexceeds:
                    // WeeklyVolumeRateSelected = item.isEnabled;
                    // WeeklyVolumeRateThreshold = item.targetValue;
                    break;
                case ListAlerts.AverageDailyVolumecrosses:
                    alertDataObj.listAlertSettings.AverageDlyVolSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.AverageDlyVolThreshold = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                    break;
                case ListAlerts.AverageDailyDlrVolumecrosses:
                    alertDataObj.listAlertSettings.AverageDlyDlrVolSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.AverageDlyDlrVolThreshold = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                    break;
                case ListAlerts.DailyVolumeExceeds50SMA:
                    alertDataObj.listAlertSettings.DailyVolumeExceeds50SMASelected = item.isEnabled;
                    break;
                case ListAlerts.BreakingOutToAllTimeHigh:
                    alertDataObj.listAlertSettings.BreakingOutToAllTimeHighSelected = item.isEnabled;
                    break;
                case ListAlerts.RSReachesNewHigh:
                    alertDataObj.listAlertSettings.RSReachesNewHighSelected = item.isEnabled;
                    break;
                case ListAlerts.SMAdaily50:
                    alertDataObj.listAlertSettings.MovingAvg50SmaSelected = item.isEnabled;
                    break;
                case ListAlerts.SMAdaily200:
                    alertDataObj.listAlertSettings.MovingAvg200SmaSelected = item.isEnabled;
                    break;
                case ListAlerts.SMA50200crossoverdaily:
                    alertDataObj.listAlertSettings.MovingAvg50Cross200Selected = item.isEnabled;
                    break;
                case ListAlerts.DailyNewBasedetected:
                    alertDataObj.listAlertSettings.DailyBaseChecked = item.isEnabled;
                    break;
                case ListAlerts.WeeklyNewBasedetected:
                    alertDataObj.listAlertSettings.WeeklyBaseChecked = item.isEnabled;
                    break;
                case ListAlerts.TightAreadetected:
                    alertDataObj.listAlertSettings.WeeklyTightChecked = item.isEnabled;
                    break;
                case ListAlerts.DailyFailedBasedetected:
                    alertDataObj.listAlertSettings.DailyFailedBaseChecked = item.isEnabled;
                    break;
                case ListAlerts.WeeklyFailedBasedetected:
                    alertDataObj.listAlertSettings.WeeklyFailedBaseChecked = item.isEnabled;
                    break;
                case ListAlerts.DailyPricerisestowithinofpivot:
                    alertDataObj.listAlertSettings.DailyPriceRisesSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue = item.targetValue;
                    alertDataObj.listAlertSettings.DefaultRisesvalue = item.defaultValue;
                    break;
                case ListAlerts.WeeklyPricerisestowithinofpivot:
                    alertDataObj.listAlertSettings.WeeklyPriceRisesSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.WeeklyPriceRisesTgtvalue = item.targetValue;
                    alertDataObj.listAlertSettings.DefaultRisesvalue = item.defaultValue;
                    break;
                case ListAlerts.DailyPricecrossesoverpivotbreakout:
                    alertDataObj.listAlertSettings.DailyPriceCrossesSelected = item.isEnabled;
                    break;
                case ListAlerts.WeeklyPricecrossesoverpivotbreakout:
                    alertDataObj.listAlertSettings.WeeklyPriceCrossesSelected = item.isEnabled;
                    break;
                case ListAlerts.PullbackTo10WeekLine:
                    alertDataObj.listAlertSettings.PullbackTo10WeekLineSelected = item.isEnabled;
                    break;
                case ListAlerts.BuybackAnnouncement:
                    alertDataObj.listAlertSettings.BuybackAnnouncementSelected = item.isEnabled;
                    break;
                case ListAlerts.StockSplit:
                    alertDataObj.listAlertSettings.StockSplitSelected = item.isEnabled;
                    break;
                case ListAlerts.CEOChange:
                    alertDataObj.listAlertSettings.CeoChangeSelected = item.isEnabled;
                    break;
                case ListAlerts.Earningsreleaseiswithin:
                    alertDataObj.listAlertSettings.EarningsReleaseSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.EarningsReleaseDays = (item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue);
                    break;
                case ListAlerts.DividendChange:
                    alertDataObj.listAlertSettings.DividendChangeSelected = item.isEnabled;
                    break;
                // case ListAlerts.InsiderPurchages:
                //     alertDataObj.listAlertSettings.InsiderPurchasesSelected = item.isEnabled;
                //     break;
                case ListAlerts.InsidersTransactionsBuys:
                    alertDataObj.listAlertSettings.InsiderTransactionsBuysSelected = item.isEnabled;
                    break;
                case ListAlerts.InsidersTransactionsSells:
                    alertDataObj.listAlertSettings.InsiderTransactionsSellsSelected = item.isEnabled;
                    break;
                case ListAlerts.Offerings:
                    alertDataObj.listAlertSettings.OfferingsSelected = item.isEnabled;
                    break;
                case ListAlerts.Announcements:
                    alertDataObj.listAlertSettings.AnnouncementsSelected = item.isEnabled;
                    break;
                case ListAlerts.BreakawayGap:
                    alertDataObj.listAlertSettings.BreakawayGapSelected = item.isEnabled;
                    break;
                case ListAlerts.Day50BreakOnVolume:
                    alertDataObj.listAlertSettings.Day50BreakOnVolumeSelected = item.isEnabled;
                    break;
                case ListAlerts.GapUpOnEarnings:
                    alertDataObj.listAlertSettings.GapUpOnEarningsSelected = item.isEnabled;
                    break;
                case ListAlerts.GapDownOnEarnings:
                    alertDataObj.listAlertSettings.GapDownOnEarningsSelected = item.isEnabled;
                    break;
                case ListAlerts.NegativeAlertScore1Week:
                    alertDataObj.listAlertSettings.NegativeAlter1WeekSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.NegativeAlter1Week = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                    break;
                case ListAlerts.NegativeAlertScore10Week:
                    alertDataObj.listAlertSettings.NegativeAlter10WeekSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.NegativeAlter10Week = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                    break;
                case ListAlerts.DailyEnterExitProfitRange:
                    alertDataObj.listAlertSettings.DailyEnterExitProfitRangeSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.DailyEnterExitProfitRange = item.isEnabled == true ? item.targetValue : null;
                    break;
                case ListAlerts.WeeklyEnterExitProfitRange:
                    alertDataObj.listAlertSettings.WeeklyEnterExitProfitRangeSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.WeeklyEnterExitProfitRange =item.isEnabled == true ? item.targetValue : null;
                    break;
                case ListAlerts.DailyEnterExitPivotRange:
                    alertDataObj.listAlertSettings.DailyEnterExitPivotRangeSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.DailyEnterExitPivotRange = item.isEnabled == true ? item.targetValue : null;
                    break;
                case ListAlerts.WeeklyEnterExitPivotRange:
                    alertDataObj.listAlertSettings.WeeklyEnterExitPivotRangeSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.WeeklyEnterExitPivotRange = item.isEnabled == true ? item.targetValue : null;
                    break;
                case ListAlerts.DailyEnterExitLossRange:
                    alertDataObj.listAlertSettings.DailyEnterExitLossRangeSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.DailyEnterExitLossRange = item.isEnabled == true ? item.targetValue : null;
                    break;
                case ListAlerts.WeeklyEnterExitLossRange:
                    alertDataObj.listAlertSettings.WeeklyEnterExitLossRangeSelected = item.isEnabled;
                    alertDataObj.listAlertSettings.WeeklyEnterExitLossRange = item.isEnabled == true ? item.targetValue : null;
                    break;
                default:
                    break;
            }
        });

        this.setAlertRisesValue();
    }

    setAlertRisesValue() {
        if (alertDataObj.listAlertSettings.DailyPriceRisesSelected || alertDataObj.listAlertSettings.WeeklyPriceRisesSelected) {
            if ((alertDataObj.listAlertSettings.DailyPriceRisesSelected && alertDataObj.listAlertSettings.WeeklyPriceRisesSelected) || alertDataObj.listAlertSettings.DailyPriceRisesSelected) {
                alertDataObj.listAlertSettings.AlertPriceRises = alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue;
            }
            else if (alertDataObj.listAlertSettings.WeeklyPriceRisesSelected) {
                alertDataObj.listAlertSettings.AlertPriceRises = alertDataObj.listAlertSettings.WeeklyPriceRisesTgtvalue;
            }
        }
        else {
            alertDataObj.listAlertSettings.AlertPriceRises = !alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue && !alertDataObj.listAlertSettings.WeeklyPriceRisesTgtvalue ? alertDataObj.listAlertSettings.DefaultRisesvalue : alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue ? alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue : alertDataObj.listAlertSettings.WeeklyPriceRisesTgtvalue;
        }
    }

    buildAlertData() {
        alertDataObj.prevAlertArr = [...alertDataObj.alerts.alertDataSetting]
        alertDataObj.prevCountryId = alertDataObj.alerts.browser.filters[0].filters.find(i=>i.selected)?alertDataObj.alerts.browser.filters[0].filters.find(i=>i.selected).id : null
        alertDataObj.prevColsetId = alertDataObj.alerts.columnSet? alertDataObj.alerts.columnSet.nodeId : null
        let _alertDataSettingsArr = [];

        if (alertDataObj.listAlertSettings.AdditionSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Additions, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.RemovalSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Removals, isEnabled: true })
        }

        // if (alertDataObj.listAlertSettings.PriceChangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Dailypricechangeexceeds, isEnabled: alertDataObj.listAlertSettings.PriceChangeSelected, targetValue: alertDataObj.listAlertSettings.PriceChangeThreshold });
        //}

        
            
        //if (alertDataObj.listAlertSettings.DailyVolumeRateSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyVolumeRateexceeds, 
                isEnabled: alertDataObj.listAlertSettings.DailyVolumeRateSelected, 
                targetValue: alertDataObj.listAlertSettings.DailyVolumeRateThreshold,
                rangeValue: "0",
                delayTime: alertDataObj.listAlertSettings.DailyVolumeRateDelayThreshold,
                isDelayEnabled: alertDataObj.listAlertSettings.DailyVolumeRateDelaySelected});
        // }

        // if (alertDataObj.listAlertSettings.AverageDlyVolSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.AverageDailyVolumecrosses, isEnabled: alertDataObj.listAlertSettings.AverageDlyVolSelected, targetValue: alertDataObj.listAlertSettings.AverageDlyVolThreshold });
        //  }

        // if (alertDataObj.listAlertSettings.AverageDlyDlrVolSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.AverageDailyDlrVolumecrosses, isEnabled: alertDataObj.listAlertSettings.AverageDlyDlrVolSelected, targetValue: alertDataObj.listAlertSettings.AverageDlyDlrVolThreshold });
        //}

        if (alertDataObj.listAlertSettings.DailyVolumeExceeds50SMASelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyVolumeExceeds50SMA, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.BreakingOutToAllTimeHighSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.BreakingOutToAllTimeHigh, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.RSReachesNewHighSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.RSReachesNewHigh, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.MovingAvg50SmaSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.SMAdaily50, isEnabled: true });
        }
        if (alertDataObj.listAlertSettings.MovingAvg200SmaSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.SMAdaily200, isEnabled: true });
        }
        if (alertDataObj.listAlertSettings.MovingAvg50Cross200Selected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.SMA50200crossoverdaily, isEnabled: true });
        }
        if (alertDataObj.listAlertSettings.DailyBaseChecked) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyNewBasedetected, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.WeeklyBaseChecked) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyNewBasedetected, isEnabled: true });
        }
        if (alertDataObj.listAlertSettings.WeeklyTightChecked) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.TightAreadetected, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.DailyFailedBaseChecked) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyFailedBasedetected, isEnabled: true });
        }
        if (alertDataObj.listAlertSettings.WeeklyFailedBaseChecked) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyFailedBasedetected, isEnabled: true });
        }

        //if (alertDataObj.listAlertSettings.DailyPriceRisesSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyPricerisestowithinofpivot, isEnabled: alertDataObj.listAlertSettings.DailyPriceRisesSelected, targetValue: alertDataObj.listAlertSettings.AlertPriceRises });
        // }

        //if (alertDataObj.listAlertSettings.WeeklyPriceRisesSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyPricerisestowithinofpivot, isEnabled: alertDataObj.listAlertSettings.WeeklyPriceRisesSelected, targetValue: alertDataObj.listAlertSettings.AlertPriceRises });
        // }


        if (alertDataObj.listAlertSettings.DailyPriceCrossesSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyPricecrossesoverpivotbreakout, isEnabled: true });
        }


        if (alertDataObj.listAlertSettings.WeeklyPriceCrossesSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyPricecrossesoverpivotbreakout, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.PullbackTo10WeekLineSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.PullbackTo10WeekLine, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.BuybackAnnouncementSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.BuybackAnnouncement, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.DividendChangeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DividendChange, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.StockSplitSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.StockSplit, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.CeoChangeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.CEOChange, isEnabled: true });
        }

        //if (alertDataObj.listAlertSettings.EarningsReleaseSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Earningsreleaseiswithin, isEnabled: alertDataObj.listAlertSettings.EarningsReleaseSelected, targetValue: alertDataObj.listAlertSettings.EarningsReleaseDays });
        // }
        // if (alertDataObj.listAlertSettings.InsiderPurchasesSelected) {
        //     _alertDataSettingsArr.push({ alertTypeId: ListAlerts.InsiderPurchages, isEnabled: true });
        // }
        if (alertDataObj.listAlertSettings.InsiderTransactionsBuysSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.InsidersTransactionsBuys, isEnabled: true });
        }
        if (alertDataObj.listAlertSettings.InsiderTransactionsSellsSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.InsidersTransactionsSells, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.OfferingsSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Offerings, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.AnnouncementsSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Announcements, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.BreakawayGapSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.BreakawayGap, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.Day50BreakOnVolumeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Day50BreakOnVolume, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.GapUpOnEarningsSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.GapUpOnEarnings, isEnabled: true });
        }

        if (alertDataObj.listAlertSettings.GapDownOnEarningsSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.GapDownOnEarnings, isEnabled: true });
        }

        // if (alertDataObj.listAlertSettings.NegativeAlter1WeekSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.NegativeAlertScore1Week, isEnabled: alertDataObj.listAlertSettings.NegativeAlter1WeekSelected, targetValue: alertDataObj.listAlertSettings.NegativeAlter1Week });
        // }

        //if (alertDataObj.listAlertSettings.NegativeAlter10WeekSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.NegativeAlertScore10Week, isEnabled: alertDataObj.listAlertSettings.NegativeAlter10WeekSelected, targetValue: alertDataObj.listAlertSettings.NegativeAlter10Week });
        //}
        const cPrice = DataGraphStore.getState().symbolPriceData ? DataGraphStore.getState().symbolPriceData.CPrice : null;

        if (alertDataObj.listAlertSettings.DailyEnterExitProfitRangeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyEnterExitProfitRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.DailyEnterExitProfitRange ? alertDataObj.listAlertSettings.DailyEnterExitProfitRange : cPrice });
        }
        if (alertDataObj.listAlertSettings.WeeklyEnterExitProfitRangeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyEnterExitProfitRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.WeeklyEnterExitProfitRange ? alertDataObj.listAlertSettings.WeeklyEnterExitProfitRange : cPrice });
        }
        if (alertDataObj.listAlertSettings.DailyEnterExitPivotRangeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyEnterExitPivotRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.DailyEnterExitPivotRange ? alertDataObj.listAlertSettings.DailyEnterExitPivotRange : cPrice });
        }
        if (alertDataObj.listAlertSettings.WeeklyEnterExitPivotRangeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyEnterExitPivotRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.WeeklyEnterExitPivotRange ? alertDataObj.listAlertSettings.WeeklyEnterExitPivotRange : cPrice });
        }
        if (alertDataObj.listAlertSettings.DailyEnterExitLossRangeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyEnterExitLossRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.DailyEnterExitLossRange ? alertDataObj.listAlertSettings.DailyEnterExitLossRange : cPrice });
        }
        if (alertDataObj.listAlertSettings.WeeklyEnterExitLossRangeSelected) {
            _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyEnterExitLossRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.WeeklyEnterExitLossRange ? alertDataObj.listAlertSettings.WeeklyEnterExitLossRange : cPrice });
        }

        alertDataObj.alerts.alertDataSetting = _alertDataSettingsArr;
    }

    deleteAllListAlerts(listId, conditionTypeId, eventName) {
        if (!StringUtil.isEmpty(listId)) {
            AlertsApi.deleteAllListAlerts(listId, conditionTypeId).then(() => {
                ListExplorerStore.updateListExplorerForAlerts(listId, 0, conditionTypeId);
                this.emit(eventName);
            });
        }
    }
    /* More Symbol Alerts/ List Alerts Section - End*/


    /////////////////////////////////////////////////////// RI Panel Alerts Section///////////////////////////////////////////////

    /* Symbol /Datagraph Alert- Start */
    createPriceAlert(msid, targetPrice, currencyCode, eventName, note) {
        if (!StringUtil.isEmpty(msid))
            AlertsApi.createPriceAlert(msid, targetPrice, DataGraphStore.getCurrencyCode(currencyCode), note).then((result) => {
                if (!result.responseHeader.error && eventName)
                    this.emit(eventName);
                else if (result.responseHeader.error) {
                    this.validateResponse(result);
                }
            });
    }

    getRIPnaelAlertsState() {
        if (_state === undefined) {
            this.setDefaultState();
        }
        return _state;
    }

    setDefaultState() {
        _state = {
            AlertDatasource: [],
            loading: true,
            Initialized: undefined

        };
    }

    updateAlertRequest(data, eventName) {
        if (data.isTriggered > 0) {
            this.dismissAlert(data.alertId);
            this.createPriceAlert(data.msid, data.targetPrice, data.priceAlertData.currencyCode, eventName, data.note);
        }
        else {
            AlertsApi.updateAlertRequest(data).then(() => {
                this.emit(eventName);
            });
        }

    }

    deleteAlert(data, eventName) {
        if (data.isTriggered || (data.MAAlertData && data.MAAlertData.alert.isTriggered == true)) {
            if (data.isStockAlert) {
                return new Promise((resolve) => {
                    this.dismissStockAlert(data.alertId, data.StockAlertData.alertTypeId).then((result) => {
                        resolve(result);
                        this._removeAlert(data.alertId);
                        this.emit(eventName);
                    });
                });
            }
            else {
                return new Promise((resolve) => {
                    this.dismissAlert(data.alertId).then((result) => {
                        if(data.TLAlertData != null && !result.responseHeader.error){
                            dispatch(toggleAlertIconToggle(data.TLAlertData.annotationid,false));
                        }
                        resolve(result);
                        this._removeAlert(data.alertId);
                        this.emit(eventName);
                    });
                });
            }
            //data.isActive = false;
        }
        else {
            this.deleteAlertBasedOnType(data, data.isStockAlert, eventName);
            // if (data.AlertDataStructure.TLAlertData != null)
            //     _navSymbolModel.States.ActiveChartPanelModel.ConsoleModel.RaiseResetTrendLineAlertFlagEvent(data.AlertDataStructure.TLAlertData.Annotationid);
        }
    }

    reactivateAlert(data) {
        return new Promise((resolve) => {
            if (data.isActive) {
                if (data.isStockAlert) {
                    this.dismissStockAlert(data.alertId, data.StockAlertData.alertTypeId).then((result) => {
                        if (result) data.isActive = false;
                        resolve(result);
                    });

                }
                else {
                    this.dismissAlert(data.alertId).then((result) => {
                        if(data.TLAlertData != null && !result.responseHeader.error){
                            dispatch(toggleAlertIconToggle(data.TLAlertData.annotationid,false));
                        }
                        if (result) data.isActive = false;
                        resolve(result);
                    });
                }
            }
            else {
                if (!data.isAnotationDeleted) {
                    if (data.isStockAlert) {
                        AlertsApi.reactivateStockAlert(data.alertId, data.StockAlertData.alertTypeId).then((result) => {
                            if (result) data.isActive = true;
                            resolve(result);
                            if (result.responseHeader.error) {
                                this.validateResponse(result);
                            }
                        });

                    }
                    else {
                        AlertsApi.reactivate(data.alertId).then((result) => {
                            if(data.TLAlertData != null && !result.responseHeader.error){
                                dispatch(toggleAlertIconToggle(data.TLAlertData.annotationid,true));
                            }
                            if (result) data.isActive = true;
                            resolve(result);
                            if (result.responseHeader.error) {
                                this.validateResponse(result);
                            }
                        });
                    }

                    //region Usage tracking code

                    //Task.Factory.StartNew(() => LogtrackingHelper.LogUsageTrackingHelper("AlertIsReactivated", string.Empty));

                    //endregion
                }
            }
        });

    }

    deleteAlertBasedOnType(alertDataStructure, isStockAlert, eventName) {
        let alertId = alertDataStructure.length > 0 ? alertDataStructure[0].alertId : alertDataStructure.alertId;
        if (isStockAlert) {
            let alerttypeId = alertDataStructure.StockAlertData.alertTypeId;

            AlertsApi.deleteStockAlert(alertId, alerttypeId).then((result) => {
                if (!result.responseHeader.error) this._afterDelete(alertDataStructure, eventName);
            });

        }
        else {
            AlertsApi.deleteAlert(alertId).then((result) => {
                if(alertDataStructure.TLAlertData != null && !result.responseHeader.error){
                    dispatch(removeLineAlertIcon(alertDataStructure.TLAlertData.annotationid));
                }
                if (!result.responseHeader.error) this._afterDelete(alertDataStructure, eventName)
            });
        }
    }

    _afterDelete(alertDataStructure, eventName) {
        this.markAsRead(alertDataStructure.NotificationId ? alertDataStructure.NotificationId : alertDataStructure.notificationId);
        this._removeAlert(alertDataStructure.alertId);
        this.removeAlertForDeleteAll(alertDataStructure);
        this.emit(eventName);
    }
    removeAlertForDeleteAll(alert) {
        var orCount = 0;
        var andCount = 0;
        var alertsAfterDelete = without(_state.AlertDatasource, alert);
        _.each(alertsAfterDelete,(item)=>{
            if(item.length > 0){
                if(item[0].isTriggered != 1){
                    andCount = andCount + 1;
                }
            }else{
                if(item.isTriggered != 1){
                    orCount = orCount + 1;
                }
            }
        })
        if(orCount == 0 && andCount == 0){
            alertDataObj.isActiveAlerts.isActiveOrAlert = 0;
            alertDataObj.isActiveAlerts.isActiveAndAlert = 0;
        }else if(orCount == 0){
            alertDataObj.isActiveAlerts.isActiveOrAlert = 0;
        }else if(andCount == 0){
            alertDataObj.isActiveAlerts.isActiveAndAlert = 0;
        }
    }

    _removeAlert(alertId) {
        this.datagraphAlertsList = without(this.datagraphAlertsList, findWhere(this.datagraphAlertsList, {
            alertId: alertId
        }));
    }

    deleteAllAlertsByMsIds(data, eventName) {
        AlertsApi.deleteAllAlertsByMsIds(data.msids, data.cacheId).then(() => {
            this.renderListView = true;
            ONeilViewStore.setVisibleRows(data.msids, false);
            ONeilViewStore.processDeleteAllAlerts(data.msids.split(';').length).then(() => {
                this.emit(eventName);
            });
        });

    }

    getAlertsDataSource(showActive) {
        return new Promise((resolve) => {
            this.setDefaultState();
            this.showActive = showActive;
            let state = DataGraphStore.getState();
            if (state.SymbolInfo) {
                AlertsApi.getAlertsByMsid(state.SymbolInfo.MsId, DataGraphStore.getCurrencyCode(this.getCurrencyCode(state.SymbolInfo.DefaultCurrency))).then((result) => {
                    _state.AlertDatasource = this.processData(result.alerts, showActive);
                    _state.loading = false;
                    _state.Initialized = true;
                    let count = 0
                    let _alertList = [];
                    each(_state.AlertDatasource, (alertsData) => {
                        let alertDataObj = this.getAlertsDataObj(alertsData);
                        var alertDataObject =
                        {
                            AlertDate: (alertsData.dateLastTriggered != null || (alertsData.length > 0 && alertsData[0].dateLastTriggered != null)) ? this.getAlertDate(moment("/Date(" + alertDataObj.dateLastTriggered + ")/")) : null,
                            UpdateDate: this.getAlertDate(moment("/Date(" + alertDataObj.dateLastUpdated + ")/")),
                            AlertName: this.getAlertName(alertsData),
                            AlertStatus: (alertsData.alertId > 0 && !StringUtil.isEmpty(alertDataObj.listName) || (alertsData.length > 0 && alertsData[0].StockAlertData && alertsData[0].StockAlertData.isStockAlert == false)) ? '' : (alertDataObj.isActive > 0 ? 'Active' : 'Inactive'),
                            IsActive: alertDataObj.isActive > 0,
                            IsTriggered: alertDataObj.isTriggered > 0,
                            IsAnotationDeleted: alertDataObj.TLAlertData != null && alertDataObj.TLAlertData.isAnnotationDeleted,
                            TriggerList: '',//TriggerControl,
                            DeleteCmd: '', //DeleteCommand,
                            EditCmd: '',//EditCommand,
                            ReactivateCmd: '', //ReactivateCommand,
                            EditVisible: this.isEditVisibleInRIPAnelTile(alertsData) ? 'Visible' : 'Collapsed',
                            AlertDataStructure: alertsData,
                            TextForeground: '',//ThemedBrushHelper.GetClonedThemedBrush("77CAFF-04478A"),
                            ETextForeground: '',//ThemedBrushHelper.GetClonedThemedBrush("77CAFF-04478A"),
                            IsStockAlert: alertDataObj.isStockAlert,
                            ConditionTypeId: alertsData.length > 0 ? AlertConditionType.And : AlertConditionType.Or,
                            note: alertDataObj.note ? alertDataObj.note : ''
                        };

                        if (alertsData.patternRecAlertData != null) {

                            if (UserInfoUtil.isPatternRecEntitlement(alertsData.patternRecAlertData.alert.alertTypeId)) {
                                alertDataObject.AlertStatus = string.Empty;
                            }

                        }
                        if (alertsData.StockAlertData != null) {
                            alertDataObject.AlertStatus = "";
                        }

                        if (!showActive) {
                            if (alertDataObject.IsTriggered) {
                                alertDataObject.Even = count % 2 == 0;
                                alertDataObject.CellBackground = alertDataObject.Even
                                    ? "1E1E1F-DBDCDC"
                                    : "2C2C2C-E9E9E9";
                                _alertList.push(alertDataObject);
                                count++;
                            }
                        }
                        if (showActive) {
                            if (alertDataObject.IsActive) {
                                alertDataObject.Even = count % 2 == 0;
                                alertDataObject.CellBackground = alertDataObject.Even
                                    ? "1E1E1F-DBDCDC"
                                    : "2C2C2C-E9E9E9";
                                _alertList.push(alertDataObject);
                                count++;
                            }
                        }
                    });
                    if (showActive) {
                        let totalActAlrtStr = LocalizationStore.getTranslatedData("ri_id5", "total active alerts.");
                        let remainingStr = LocalizationStore.getTranslatedData("ri_id6", "remaining.");

                        this.AlertsCountMessage = ExtremeDataValue.ReplaceNumberWithCommas(result.alerts.alertCount) + " " + totalActAlrtStr + " " + ExtremeDataValue.ReplaceNumberWithCommas(result.alerts.totalCount) + " " + remainingStr;
                    }
                    else {
                        this.AlertsCountMessage = "";
                    }

                    this.riPanelAlertsList = _alertList;
                    resolve(result);
                });
            }
            else {
                resolve(result);
            }
        });

    }

    getAlertsDataObj(alertsData) {
        if (alertsData.alertId > 0)
            return alertsData;
        else if (alertsData.length > 0)
            return alertsData[0];
        else
            return alertsData;
    }

    isEditVisibleInRIPAnelTile(alertsData) {
        if (alertsData.priceAlertData != null || alertsData.patternRecAlertData != null || alertsData.length > 0) {
            return true;
        }
        else if (alertsData.StockAlertData && alertsData.StockAlertData.isStockAlert) {
            switch (alertsData.StockAlertData.alertTypeId) {
                case ListAlerts.Dailypricechangeexceeds:
                case ListAlerts.DailyVolumeRateexceeds:
                case ListAlerts.AverageDailyVolumecrosses:
                case ListAlerts.AverageDailyDlrVolumecrosses:
                case ListAlerts.DailyPricerisestowithinofpivot:
                case ListAlerts.WeeklyPricerisestowithinofpivot:
                case ListAlerts.Earningsreleaseiswithin:
                    return true;
                default:
                    return false;
            }
        }
        else {
            return false;
        }
    }
    getActiveStockAlerts(){
        let state = DataGraphStore.getState();
        let msid = state.SymbolInfo.MsId;
        const symbol =  state.SymbolInfo ? state.SymbolInfo.Symbol : '';
        const isMiniListPlay= state.IsMiniListPlay;
        if(!state.ActiveStockAlertRequestMSID || state.ActiveStockAlertRequestMSID !== msid){
           state.ActiveStockAlertRequestMSID = msid;
           AlertsApi.getActiveStockAlerts(msid).then((result) => {
                if(MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)){
                    if (result.responseHeader.error) {
                        this.validateResponse(result);
                    }
                    else {
                        alertDataObj.isActiveAlerts = result.activeAlertsData;
                    }
                }
            //ConsoleStore.trackUsage('AlertIsCreated');
            })
            this.emitWorkerAction = true;
            this.startAlertsDataWorker();
        }
    }
    deleteAllActiveAlerts(ConditionTypeId,eventName){
        let state = DataGraphStore.getState();
        let msid = state.SymbolInfo.MsId;
        AlertsApi.deleteAllActiveByMsid(msid,ConditionTypeId).then((result) => {
            if (result.responseHeader.error) {
                this.validateResponse(result);
            }
            else {
                if(ConditionTypeId == AlertConditionType.Or){
                    alertDataObj.isActiveAlerts.isActiveOrAlert = 0;
                }else{
                    alertDataObj.isActiveAlerts.isActiveAndAlert = 0;
                }
                this.emit(eventName);
            }

        })
    }

    getAlertList() {
        return this.riPanelAlertsList;
    }
    getTLAlertList() {
        return this.trendLineAlerts;
    }
    getDatagraphAlertLists() {
        return this.datagraphAlertsList;
    }

    clearDatagraphAlertLists() {
        this.datagraphAlertsList = [];
    }

    getAlertCountMessage() {
        return this.AlertsCountMessage;
    }

    processData(alertList, showActive) {
        if (StringUtil.isEmpty(alertList)) return;

        let _combinedAlerts = [];
        this.trendLineAlerts = [];
        each(alertList.priceAlerts, (item) => {
            _combinedAlerts.push(this.convertPriceAlert(item));
        });

        each(alertList.movingAverageAlerts, (item) => {
            _combinedAlerts.push(this.convertMAAlert(item));
        });

        each(alertList.trendlineAlerts, (item) => {
            this.trendLineAlerts.push(item);
            _combinedAlerts.push(this.convertTLAAlert(item));
        });

        each(alertList.stockAlerts, (item) => {
            _combinedAlerts.push(this.convertStockAlert(item));
        });

        each(alertList.andConditionAlerts, (item) => {
            _combinedAlerts.push(this.convertAndConditionAlert(item));
        });
        let reportList = [];
        if (showActive) {
            reportList = sortBy(_combinedAlerts, (itm) => moment("/Date(" + (itm.length > 0 ? itm[0].dateLastUpdated : itm.dateLastUpdated) + ")/").format("YYYYMMDDHHmmss")).reverse();
        }
        else {
            reportList = sortBy(_combinedAlerts, (itm) => moment("/Date(" + (itm.length > 0 ? itm[0].dateLastTriggered : itm.dateLastTriggered) + ")/").format("YYYYMMDDHHmmss")).reverse();
        }

        return reportList;
    }

    convertPriceAlert(alertData) {
        return {
            msid: alertData.msid,
            targetPrice: alertData.targetPrice,
            alertId: alertData.alert.alertId,
            notificationId: alertData.alert.notificationId,
            isActive: alertData.alert.isActive,
            isTriggered: alertData.alert.isTriggered,
            dateLastUpdated: alertData.alert.dateLastUpdated,
            dateLastTriggered: alertData.alert.dateLastTriggered,
            priceAlertData: alertData,
            MAAlertData: null,
            TLAlertData: null,
            patternRecAlertData: null,
            isStockAlert: 0,
            note: alertData.note
        }
    }

    convertMAAlert(alertData) {
        return {
            msid: alertData.msid,
            targetPrice: alertData.targetPrice,

            alertId: alertData.alert.alertId,
            isActive: alertData.alert.isActive,
            isTriggered: alertData.alert.isTriggered,
            notificationId: alertData.alert.notificationId,
            dateLastUpdated: alertData.alert.dateLastUpdated,
            dateLastTriggered: alertData.alert.dateLastTriggered,
            priceAlertData: null,
            MAAlertData: alertData,
            TLAlertData: null,
            patternRecAlertData: null,
            isStockAlert: 0,
            maTypeDescription: this.getMAAlertType(alertData.maType)
        }
    }

    convertTLAAlert(alertData) {
        return {
            msid: alertData.msid,
            targetPrice: alertData.targetPrice,

            alertId: alertData.alert.alertId,
            isActive: alertData.alert.isActive,
            isTriggered: alertData.alert.isTriggered,
            notificationId: alertData.alert.notificationId,
            dateLastUpdated: alertData.alert.dateLastUpdated,
            dateLastTriggered: alertData.alert.dateLastTriggered,
            priceAlertData: null,
            MAAlertData: null,
            TLAlertData: alertData,
            patternRecAlertData: null,
            isStockAlert: 0
        }
    }

    convertStockAlert(alertData) {
        return {

            alertId: alertData.alertId,
            isActive: alertData.isEnabled ? 1 : 0,
            isTriggered: alertData.isTriggered ? 1 : 0,
            notificationId: alertData.notificationId,
            dateLastUpdated: alertData.dateLastUpdated,
            dateLastTriggered: alertData.dateLastTriggered,
            isStockAlert: alertData.isStockAlert,
            priceAlertData: null,
            MAAlertData: null,
            TLAlertData: null,
            patternRecAlertData: null,
            StockAlertData: alertData,
            listName: !alertData.isStockAlert ? alertData.listName : null,
            geographyName: alertData.geographyName,
            columnSetName: alertData.columnSetName
        }
    }

    convertAndConditionAlert(alertData) {
        let alertObj = [];
        if (alertData.stockAlerts != null && alertData.stockAlerts.length > 0) {
            each(alertData.stockAlerts, (item) => {
                if(alertData.alert && item.isEnabled == true){
                alertObj.push(
                    {
                        alertId: alertData.alert.alertId,
                        isActive: item.isEnabled && item.isStockAlert ? 1 : 0,
                        isTriggered: alertData.alert.isTriggered,
                        notificationId: alertData.alert.notificationId,
                        dateLastUpdated: item.dateLastUpdated,
                        dateLastTriggered: item.dateLastTriggered,
                        priceAlertData: null,
                        MAAlertData: null,
                        TLAlertData: null,
                        patternRecAlertData: null,
                        StockAlertData: item,
                        listName: !item.isStockAlert ? item.listName : null
                    }
                );
            }else if(alertData.alert && item.isTriggered == true){
                alertObj.push(
                    {
                        alertId: alertData.alert.alertId,
                        isActive: item.isEnabled ? 1 : 0,
                        isTriggered: alertData.alert.isTriggered,
                        notificationId: alertData.alert.notificationId,
                        dateLastUpdated: item.dateLastUpdated,
                        dateLastTriggered: item.dateLastTriggered,
                        priceAlertData: null,
                        MAAlertData: null,
                        TLAlertData: null,
                        patternRecAlertData: null,
                        StockAlertData: item,
                        listName: !item.isStockAlert ? item.listName : null,
                        geographyName: item.geographyName,
                        columnSetName: item.columnSetName
                    }
                );
            }
            });
        }

        if (alertData.priceAlerts != null) {
            alertObj.push({
                msid: alertData.priceAlerts.msid,
                targetPrice: alertData.priceAlerts.targetPrice,
                alertId: alertData.alert.alertId,
                notificationId: alertData.alert.notificationId,
                isActive: alertData.alert.isActive,
                isTriggered: alertData.alert.isTriggered,
                dateLastUpdated: alertData.alert.dateLastUpdated,
                dateLastTriggered: alertData.alert.dateLastTriggered,
                priceAlertData: alertData.priceAlerts,
                MAAlertData: null,
                TLAlertData: null,
                patternRecAlertData: null,
                isStockAlert: 0
            })
        }

        return alertObj;
    }

    setHighlightedAlert(id, eventName) {
        this.highlightedAlertId = id;
        this.emit(eventName);
    }

    getAlertDate(date) {
        var curr = moment(new Date());
        let returnDate;
        if (curr._d.toDateString() == date._d.toDateString()) {
            if (Math.abs(curr.diff(date, 'minutes')) < 1) {
                returnDate = parseInt(curr.diff(date, 'seconds')) + LocalizationStore.getTranslatedData("RI_sec_ago_web", " sec ago");
            }
            else if (Math.abs(date.diff(curr, 'minutes')) < 60) {
                returnDate = parseInt(curr.diff(date, 'minutes')) + LocalizationStore.getTranslatedData("RI_mins_ago_web", " min ago");
            }
            else {
                returnDate = parseInt(curr.diff(date, 'hours')) + LocalizationStore.getTranslatedData("RI_hrs_ago_web", " hrs ago");
            }
        }
        else
            returnDate = LocalizationStore.getLang() === LangType.ZH_CN ? LocalizationStore.getTranslatedData("cal_" + date.format('ddd').toLowerCase(), date.format('ddd')) + " " + LocalizationStore.getTranslatedData("cal_" + date.format("MMM"), date.format("MMM")) + LocalizationStore.getTranslatedData("sb_day", "{0}").replace("{0}", date.format("D")) + " " + date.format("hh:mm") + " " + LocalizationStore.getTranslatedData("ss_" + date.format("a").toLowerCase(), date.format("a").toUpperCase())
                : date.format('ddd') + " " + date.format("D") + " " + date.format("MMM") + " " + date.format("hh:mm") + " " + date.format("a").toUpperCase();
        return returnDate;
    }

    getAlertName(alertsData) {
        let alertStr = '';

        if (alertsData.alertId > 0)
            alertStr = this.getAlertType(alertsData, AlertConditionType.Or);
        else if (alertsData.length > 0) {
            for (let i = 0; i < alertsData.length; i++) {
                if (i > 0) {
                    alertStr += "<br /> AND <br />";
                }
                alertStr += this.getAlertType(alertsData[i], AlertConditionType.And,i,alertsData.length);
            }
        }

        return alertStr;
    }

    getAlertType(alertsData,alertType,andAlertCount,length) {
        let strRetVal = "";
        let states = DataGraphStore.getState();

        if (alertsData.priceAlertData != null && !StringUtil.isEmpty(alertsData.priceAlertData.targetPrice)) {

            let type = states.SymbolInfo.SymTypeEnum == SymbolType.USSTOCK ||
                states.SymbolInfo.SymTypeEnum == SymbolType.REIT ||
                states.SymbolInfo.SymTypeEnum == SymbolType.INTERNATIONALSTOCK ||
                states.SymbolInfo.SymTypeEnum == SymbolType.HISTORICSTOCK ||
                states.SymbolInfo.SymTypeEnum == SymbolType.ETF ||
                states.SymbolInfo.SymTypeEnum == SymbolType.ADR||
                states.SymbolInfo.SymTypeEnum == SymbolType.FundClosedEnd;
            let displayLabel;
            if (type) {
                const targetCurrencyCode = DataGraphStore.targetCurrencyCode();
                let defaultCurrency = StringUtil.isEmpty(targetCurrencyCode) ? states.SymbolInfo.DefaultCurrency : { Code: targetCurrencyCode }; 
                displayLabel = this.getCurrencyCode(defaultCurrency, !StringUtil.isEmpty(targetCurrencyCode));
            }
            else displayLabel = "";


            let alertprice = this.formatPrice(alertsData.priceAlertData.targetPrice);
            strRetVal = LocalizationStore.getTranslatedData("MetricLibraryCategory_Price", "Price") + ` \n @ ${displayLabel}${alertprice}`;
        }
        else
            if (alertsData.MAAlertData != null)
                strRetVal = LocalizationStore.getTranslatedData("ipc_md_aprn_ma", "Moving Average") + alertsData.MAAlertData.length + (LocalizationStore.getLang() === LangType.ZH_CN ? this.getPeriodicityUnit(alertsData.MAAlertData.periodicity) : "") + '\n' + this.getMAAlertType(alertsData.MAAlertData.maType) + ' (' + this.getPeriodicity(alertsData.MAAlertData.periodicity) + ')';
            else
                if (alertsData.TLAlertData != null) {
                    const targetCurrencyCode = DataGraphStore.targetCurrencyCode();
                    let defaultCurrency = StringUtil.isEmpty(targetCurrencyCode) ? states.SymbolInfo.DefaultCurrency : { Code: targetCurrencyCode }; 
                    let displayLabel = this.getCurrencyCode(defaultCurrency, !StringUtil.isEmpty(targetCurrencyCode));
                    let scaleStr = this.getCurrStringValue(alertsData.TLAlertData.scale);
                    let direction = alertsData.TLAlertData.alertOperator.trim() == "<" ? LocalizationStore.getTranslatedData("ch_msa_Downward", "Downward") : LocalizationStore.getTranslatedData("ch_msa_Upwar", "Upward");
                    let targetPrice = alertsData.TLAlertData.targetPrice;
                    if (alertsData.isTriggered == 1 && alertsData.isActive < 1)
                        strRetVal = LocalizationStore.getTranslatedData("ch_msa_TrendlineCross", "Trendline \n {0} Cross @{1}{2} ({3}, {4})", direction, displayLabel, this.formatPrice(targetPrice), this.getPeriodicity(alertsData.TLAlertData.periodicity), scaleStr);
                    else
                        strRetVal = LocalizationStore.getTranslatedData("ch_msa_Trendline", "Trendline \n @ {0}{1} ({2}, {3}) ", displayLabel, this.formatPrice(targetPrice), this.getPeriodicity(alertsData.TLAlertData.periodicity), scaleStr);
                }
                else
                    if (alertsData.patternRecAlertData != null)
                        switch (alertsData.patternRecAlertData.alert.alertType) {
                            case AlertType.ALERT_PATTERN_REC_NEW_BASE:
                                strRetVal = LocalizationStore.getTranslatedData("ch_pr", "Pattern Recognition") + "\n " + LocalizationStore.getTranslatedData("ch_msa_nb", "New Base") + "(" + this.getPeriodicity(alertsData.patternRecAlertData.periodicity) + ")";
                                break;
                            case AlertType.ALERT_PATTERN_REC_NEW_TIGHT_AREA:
                                strRetVal = LocalizationStore.getTranslatedData("ch_pr", "Pattern Recognition") + "\n " + LocalizationStore.getTranslatedData("ch_msa_ta", "Tight Area") + this.getPeriodicity(alertsData.patternRecAlertData.periodicity) + ")";
                                break;
                            case AlertType.ALERT_PATTERN_REC_BREAKOUT:
                                strRetVal = LocalizationStore.getTranslatedData("ch_pr", "Pattern Recognition") + "\n " + LocalizationStore.getTranslatedData("ch_msa_ta", "Tight Area") + this.getPeriodicity(alertsData.patternRecAlertData.periodicity) + ")";
                                break;
                            case AlertType.ALERT_PATTERN_REC_PERC_OFF_PIVOT:
                                strRetVal = LocalizationStore.getTranslatedData("ch_pr", "Pattern Recognition") + "\n " + LocalizationStore.getTranslatedData("ch_from_Pivot", alertsData.patternRecAlertData.percentBelowPivot + "% from Pivot").replace("{0}", alertsData.patternRecAlertData.percentBelowPivot) + " (" + this.getPeriodicity(alertsData.patternRecAlertData.periodicity) + ")";
                                break;
                            default:
                                break;
                        }
                    else if (alertsData.StockAlertData != null) {
                        if (!alertsData.StockAlertData.isStockAlert) {
                            if(alertType == AlertConditionType.And){
                                if(andAlertCount == 0 ) {
                                    strRetVal = strRetVal + LocalizationStore.getTranslatedData("la_listalert", "List Alert And-condition: ");
                                }
                            }else{
                                strRetVal = strRetVal + LocalizationStore.getTranslatedData("la_listalert", "List Alert: ");
                            }
                        }
                        else if (alertsData.StockAlertData.isStockAlert) {
                            // strRetVal = strRetVal + "Stock Alert: ";
                        }
                        switch (alertsData.StockAlertData.alertTypeId) {
                            case ListAlerts.Additions:
                                strRetVal += LocalizationStore.getTranslatedData("la_addedtolist", "Added to list");
                                break;
                            case ListAlerts.Removals:
                                strRetVal += LocalizationStore.getTranslatedData("EmailTitle_removefromlist", "Removed from list");
                                break;

                            case ListAlerts.Dailypricechangeexceeds:
                                if (alertsData.StockAlertData.isTriggered) {
                                    strRetVal += LocalizationStore.getTranslatedData("ch_msa_dpces", "Daily price change exceeds ") + (alertsData.StockAlertData.value > 0
                                        ? "+" + alertsData.StockAlertData.value.toString()
                                        : alertsData.StockAlertData.value.toString())
                                        + "%";
                                }
                                else {
                                    strRetVal += LocalizationStore.getTranslatedData("ch_msa_dpce", "Daily price change exceeds +/- ") + alertsData.StockAlertData.value + "%";
                                }
                                break;
                            case ListAlerts.DailyVolumeRateexceeds:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_dvre", "Daily volume rate exceeds ") + alertsData.StockAlertData.value + "%";
                                break;
                            case ListAlerts.WeeklyVolumeRateexceeds:
                                strRetVal += LocalizationStore.getTranslatedData("EmailTitle_WeeklyVolume", "Weekly volume rate exceeds ") + alertsData.StockAlertData.value + "%";
                                break;
                            case ListAlerts.AverageDailyVolumecrosses:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_advcs", "Average daily volume crosses ") + alertsData.StockAlertData.value + "(000) " + LocalizationStore.getTranslatedData("sym_shrs", "shares");
                                break;
                            case ListAlerts.AverageDailyDlrVolumecrosses:
                                strRetVal += LocalizationStore.getTranslatedData("EmailTitle_AveDailyColVolCro", "Average daily dollar volume crosses $") + alertsData.StockAlertData.value + "(000)";
                                break;
                            case ListAlerts.SMAdaily50:
                                if (alertsData.StockAlertData.isTriggered) {
                                    var abovebelowstring = alertsData.StockAlertData.displayValue;
                                    var abovebelowstringResKey = abovebelowstring.substring(0, 1).toUpperCase() + abovebelowstring.substring(1);
                                    strRetVal += LocalizationStore.getTranslatedData("EmailTitle_PriceCross", "Price crosses ") + LocalizationStore.getTranslatedData("EmailTitle_" + abovebelowstringResKey, abovebelowstring) + LocalizationStore.getTranslatedData("EmailTitle_50SMA", " 50 SMA");

                                }
                                else {
                                    strRetVal += LocalizationStore.getTranslatedData("EmailTitle_PriceCross50", "Price crosses 50 SMA");
                                }
                                break;
                            case ListAlerts.SMAdaily200:
                                if (alertsData.StockAlertData.isTriggered) {
                                    var abovbelowstring = alertsData.StockAlertData.displayValue;
                                    var abovbelowstringResKey = abovbelowstring.substring(0, 1).toUpperCase() + abovbelowstring.substring(1);
                                    strRetVal += LocalizationStore.getTranslatedData("EmailTitle_PriceCross", "Price crosses ") + LocalizationStore.getTranslatedData("EmailTitle_" + abovbelowstringResKey, abovbelowstring) + LocalizationStore.getTranslatedData("EmailTitle_200SMA", " 200 SMA");
                                }

                                else {
                                    strRetVal += LocalizationStore.getTranslatedData("EmailTitle_PriceCross200", "Price crosses 200 SMA");
                                }
                                break;
                            case ListAlerts.DailyVolumeExceeds50SMA:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_sup_dv", "Daily volume exceeds 50 SMA");
                                break;
                            case ListAlerts.BreakingOutToAllTimeHigh:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_sup_bo", "Breaking out to all time high");
                                break;
                            case ListAlerts.RSReachesNewHigh:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_sup_rr", "RS reaches new high");
                                break;
                            case ListAlerts.PullbackTo10WeekLine:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_sup_pt", "Pullback to 10-week line");
                                break;
                            case ListAlerts.SMA50200crossoverdaily:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_50200xo", "50 SMA - 200 SMA crossover");
                                break;
                            case ListAlerts.DailyNewBasedetected:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_ndbd", "New daily base detected");
                                if(alertsData.StockAlertData.displayValue){
                                    strRetVal += "," + alertsData.StockAlertData.displayValue;
                                }
                                break;
                            case ListAlerts.WeeklyNewBasedetected:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_nwbd", "New weekly base detected");
                                if(alertsData.StockAlertData.displayValue){
                                    strRetVal += "," + alertsData.StockAlertData.displayValue;
                                }
                                break;
                            case ListAlerts.TightAreadetected:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_tad", "Tight area detected");
                                break;
                            case ListAlerts.DailyFailedBasedetected:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_fdbd", "Failed daily base detected");
                                break;
                            case ListAlerts.WeeklyFailedBasedetected:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_fwbd", "Failed weekly breakout detected");
                                break;
                            case ListAlerts.DailyBreakingOutToday:
                            case ListAlerts.DailyPricecrossesoverpivotbreakout:
                                if (alertsData.StockAlertData.displayValue === null) {
                                    strRetVal += LocalizationStore.getTranslatedData("ch_msa_booadb", "Breaking out of a daily base");
                                } else {
                                    let dailyBaseType = LocalizationStore.getTranslatedData("Es_" + alertsData.StockAlertData.displayValue.replace(/\s+/g, ""), alertsData.StockAlertData.displayValue);
                                    strRetVal += LocalizationStore.getTranslatedData("ch_msa_booadb_basetype", "Breaking out of a daily {0} base", dailyBaseType.replace(" Base", ""));
                                }
                                break;
                            case ListAlerts.WeeklyBreakingOutToday:
                            case ListAlerts.WeeklyPricecrossesoverpivotbreakout:
                                if (alertsData.StockAlertData.displayValue === null) {
                                    strRetVal += LocalizationStore.getTranslatedData("ch_msa_booawb", "Breaking out of a weekly base");
                                } else {
                                    let weeklyBaseType = alertsData.StockAlertData.displayValue === null ? "" : LocalizationStore.getTranslatedData("Es_" + alertsData.StockAlertData.displayValue.replace(/\s+/g, ""), alertsData.StockAlertData.displayValue);
                                    strRetVal += LocalizationStore.getTranslatedData("ch_msa_booawb_basetype", "Breaking out of a weekly {0} base", weeklyBaseType.replace(" Base", ""));
                                }
                                break;
                            case ListAlerts.DailyPricerisestowithinofpivot:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_piwotdp", "Price is within " + alertsData.StockAlertData.value + "% of the daily pivot").replace("{0}", alertsData.StockAlertData.value);
                                break;
                            case ListAlerts.WeeklyPricerisestowithinofpivot:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_piwotwp", "Price is within " + alertsData.StockAlertData.value + "% of the weekly pivot").replace("{0}", alertsData.StockAlertData.value);
                                break;
                            case ListAlerts.BuybackAnnouncement:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_ba", "Buyback announcement");
                                break;
                            case ListAlerts.StockSplit:
                                if (alertsData.StockAlertData.isTriggered) {
                                    strRetVal += SplitUtil.DecToString(alertsData.StockAlertData.triggeredValue); "" //FormatEvents.DecToString(alertsData.stockAlertData.triggeredValue);
                                }
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_ss", " Stock split");
                                break;
                            case ListAlerts.CEOChange:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_ceo", "CEO change");
                                break;
                            case ListAlerts.Earningsreleaseiswithin:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_er_1", "Earnings release is in ") + alertsData.StockAlertData.value + LocalizationStore.getTranslatedData("ch_msa_er_2", " days");
                                break;
                            case ListAlerts.DividendChange:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_dc", "Dividend change");
                                break;
                            // case ListAlerts.InsiderPurchages:
                            //     strRetVal += LocalizationStore.getTranslatedData("ch_msa_it", "Insider transactions");
                            //     break;
                            case ListAlerts.InsidersTransactionsBuys:
                                strRetVal += "Insider transactions - Buys " + (!StringUtil.isEmpty(alertsData.StockAlertData.displayValue) ? alertsData.StockAlertData.displayValue.split(":")[0] + " insiders bought " + alertsData.StockAlertData.displayValue.split(":")[1] + " total shares" : "");
                                break;
                            case ListAlerts.InsidersTransactionsSells:
                                strRetVal += "Insider transactions - Sells " + (!StringUtil.isEmpty(alertsData.StockAlertData.displayValue) ? alertsData.StockAlertData.displayValue.split(":")[0] + " insiders sold " + alertsData.StockAlertData.displayValue.split(":")[1] + " total shares": "");
                                break;
                            case ListAlerts.Offerings:
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_Offering", "Offering");
                                break;
                            case ListAlerts.Announcements:
                                strRetVal += LocalizationStore.getTranslatedData("ch_ce_anncmt", "Announcement");
                                break;
                            case ListAlerts.BreakawayGap:
                                strRetVal += "Breakaway gap"
                                break;
                            case ListAlerts.Day50BreakOnVolume:
                                strRetVal += "50 day break on volume";
                                break;
                            case ListAlerts.GapUpOnEarnings:
                                strRetVal += "Gap up on earnings";
                                break;
                            case ListAlerts.GapDownOnEarnings:
                                strRetVal += "Gap down on earnings"
                                break;
                            case ListAlerts.NegativeAlertScore1Week:
                                if (alertsData.StockAlertData.isTriggered) {
                                    strRetVal += "Negative Alert Score, 1 Week >= " + alertsData.StockAlertData.value;
                                }
                                break;
                            case ListAlerts.NegativeAlertScore10Week:
                                if (alertsData.StockAlertData.isTriggered) {
                                    strRetVal += "Negative Alert Score, 10 Week >= " + alertsData.StockAlertData.value;
                                }
                                break;
                            case ListAlerts.DailyEnterExitProfitRange:
                                strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Profit Range - Daily` : "Enter exit profit range - daily";
                                break;
                            case ListAlerts.WeeklyEnterExitProfitRange:
                                strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Profit Range - Weekly` : "Enter exit profit range - weekly";
                                break;
                            case ListAlerts.DailyEnterExitPivotRange:
                                strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Pivot Range - Daily` : "Enter exit pivot range - daily";
                                break;
                            case ListAlerts.WeeklyEnterExitPivotRange:
                                strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Pivot Range - Weekly` : "Enter exit pivot range - weekly";
                                break;
                            case ListAlerts.DailyEnterExitLossRange:
                                strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Loss Range - Daily` : "Enter exit loss range - daily";
                                break;
                            case ListAlerts.WeeklyEnterExitLossRange:
                                strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Loss Range - Weekly` : "Enter exit loss range - weekly";
                                break;
                            default:
                                break;
                        }
                        if (!alertsData.StockAlertData.isStockAlert) {
                            if((alertType == AlertConditionType.And && andAlertCount == 1) || (alertType == AlertConditionType.And && length == 1) || alertType == AlertConditionType.Or)
                                strRetVal = `${strRetVal} (${alertsData.listName}${(alertsData.columnSetName && alertsData.columnSetName.length > 0) ? ` | ${alertsData.columnSetName}` : ''}${(alertsData.geographyName && alertsData.geographyName.length > 0) ? ` | ${alertsData.geographyName}`:''})`;
                        }

                        if (!StringUtil.isEmpty(alertsData.ListName) && alertsData.StockAlertData == null)//list alerts
                        {
                            switch (alertsData.listAlertTypeId) {
                                case 1:
                                    strRetVal = LocalizationStore.getTranslatedData("ListAlertType_1", "List Alert: Added to list (" + alertsData.listName + ")").replace("[NAME]", alertsData.listName);
                                    break;
                                case 2:
                                    strRetVal = LocalizationStore.getTranslatedData("ListAlertType_2", "List Alert: Removed from list (" + alertsData.listName + ")").replace("[NAME]", alertsData.listName);
                                    break;
                                case 3:
                                    strRetVal = LocalizationStore.getTranslatedData("ListAlertType_3", "List Alert: Price % Chg exceeds " + alertsData.triggeredValue > 0 ? "+" : "-" + alertsData.threshold + "% (" + alertsData.listName + ")").replace("[THRESHOLDVALUE]", alertsData.threshold).replace("[NAME]", alertsData.listName);
                                    break;
                                default:
                                    strRetVal = LocalizationStore.getTranslatedData("ListAlertType_4", "List Alert: Volume Rate exceeds " + alertsData.triggeredValue > 0 ? "+" : "-" + alertsData.threshold + "% (" + alertsData.listName + ")").replace("[THRESHOLDVALUE]", alertsData.threshold).replace("[NAME]", alertsData.listName);
                                    break;

                            }
                        }
                    }
        return strRetVal;
    }

    getCurrencyCode(currencyCode, istargetCurrency) {
        let code = "";
        if (StringUtil.isEmpty(currencyCode.CurrencyCode)) code = currencyCode.Code; else code = currencyCode.CurrencyCode
        if (!istargetCurrency && code == "GBP") {
            return "GBX";
        }
        if (!istargetCurrency && code == "ZAR") {
            return "ZAX";
        }
        return code;
    }

    formatPrice(price) {
        var priceString = price.toString();

        let alertprice;
        if (priceString.indexOf('.') > -1 && priceString.split('.')[1].length <= 4) {
            alertprice = priceString;
        }
        else {
            alertprice = price > 1.00 ? price.toFixed(2).toString() : price.toFixed(3).toString();
        }

        return alertprice;
    }

    getPeriodicity(periodicity) {
        switch (periodicity) {
            case PeriodicityType.Daily:
                return LocalizationStore.getTranslatedData("tp_per_dly", "Daily");
            case PeriodicityType.Weekly:
                return LocalizationStore.getTranslatedData("tp_per_wkly", "Weekly");
            case PeriodicityType.Monthly:
                return LocalizationStore.getTranslatedData("tp_per_mnly", "Monthly");
            case PeriodicityType.Intraday1Min:
                return LocalizationStore.getTranslatedData("tp_per_1min", "1 Min");
            case PeriodicityType.Intraday5Min:
                return LocalizationStore.getTranslatedData("tp_per_5min", "5 Min");
            case PeriodicityType.Intraday10Min:
                return LocalizationStore.getTranslatedData("tp_per_10min", "10 Min");
            case PeriodicityType.Intraday15Min:
                return LocalizationStore.getTranslatedData("tp_per_15min", "15 Min");
            case PeriodicityType.Intraday30Min:
                return LocalizationStore.getTranslatedData("tp_per_30min", "30 Min");
            case PeriodicityType.Intraday60Min:
                return LocalizationStore.getTranslatedData("tp_per_60min", "60 Min");
            case PeriodicityType.Quarterly:
                return LocalizationStore.getTranslatedData("tp_per_qly", "Quarterly");
            case PeriodicityType.Annual:
                return LocalizationStore.getTranslatedData("tp_per_aly", "Annual");
            default:
                return " ";
        }
    }

    getPeriodicityUnit(periodicity) {
        switch (periodicity) {
            case PeriodicityType.Daily:
                return LocalizationStore.getTranslatedData("Periodicity_1", "daily");
            case PeriodicityType.Weekly:
                return LocalizationStore.getTranslatedData("Periodicity_2", "weekly");
            case PeriodicityType.Monthly:
                return LocalizationStore.getTranslatedData("Periodicity_3", "monthly");
            case PeriodicityType.Quarterly:
                return LocalizationStore.getTranslatedData("Periodicity_10", "quarterly");
            case PeriodicityType.Annual:
                return LocalizationStore.getTranslatedData("Periodicity_11", "annual");
            default:
                return " ";
        }
    }

    getPeriodicityEnum(str) {
        switch (str) {
            case "Daily":
                return PeriodicityType.Daily;
            case "Weekly":
                return PeriodicityType.Weekly;
            case "Monthly":
                return PeriodicityType.Monthly;
            case "1 Min":
                return PeriodicityType.Intraday1Min;
            case "5 Min":
                return PeriodicityType.Intraday5Min;
            case "10 Min":
                return PeriodicityType.Intraday10Min;
            case "15 Min":
                return PeriodicityType.Intraday15Min;
            case "30 Min":
                return PeriodicityType.Intraday30Min;
            case "60 Min":
                return PeriodicityType.Intraday60Min;
            case "Quarterly":
                return PeriodicityType.Quarterly;
            case "Annual":
                return PeriodicityType.Annual;
            default:
                return " ";
        }
    }

    getMAAlertType(type) {
        switch (type) {
            case MovingAverageType.SMA:
                return "SMA";
            case MovingAverageType.EMA:
                return "EMA"
            default:
                return "";
        }
    }
    getCurrStringValue(val) {
        switch (val) {
            case PrefPeriodScaleConstants.Auto:
                return "Auto";
            case PrefPeriodScaleConstants.Lin:
                return "Lin";
            case PrefPeriodScaleConstants.Log:
                return "Log";
            default:
                break;
        }

        return val.toString();
    }

    getAlertsData(symbol) {
        if (symbol) {
            alertsDataWorkerObj.postMessage({ MsId: 0, CurrencyCode: DataGraphStore.getCurrencyCode(""), symbol: symbol });

            alertsDataWorkerObj.onmessage = (e) => {
                priceAlertsData = e.data.alerts;
                this.emitWorkerAction = true;
            }
        }
    }

    //Worker call to get alerts data
    startAlertsDataWorker() {
        // return new Promise((resolve, reject) => {

        const self = this;

        let state = DataGraphStore.getState();
        alertsDataWorkerObj = alertsDataWorkerObj ? alertsDataWorkerObj : new alertsDataWorker()

        if (state.SymbolInfo) {
            alertsDataWorkerObj.postMessage({ MsId: state.SymbolInfo.MsId, CurrencyCode: DataGraphStore.getCurrencyCode(this.getCurrencyCode(state.SymbolInfo.DefaultCurrency)) });

            alertsDataWorkerObj.onmessage = function (e) {
                // alertsDataWorkerObj.terminate();
                // alertsDataWorkerObj = undefined;
                self.postAlertsDataWorker(e.data.alerts)
                // resolve();
            }
            alertsDataWorkerObj.onerror = function () {
                // alertsDataWorkerObj.terminate();
                // alertsDataWorkerObj = undefined;
                // resolve();
            }
        }

        // });
    }
    updateShownAlertIds(shownAlertIds){
        this.shownAlertIds = shownAlertIds;
    }
    removeTriggeredAlerts(){
        this.removeTriggeredAlertsFromSession(this.shownAlertIds);
    }
    //once the triggered symbol is displayed remove it from datagraph on next load
    removeTriggeredAlertsFromSession(shownAlertIds) {
        if (shownAlertIds && shownAlertIds.length > 0 && this.triggeredAlertsList && this.triggeredAlertsList.length > 0) {
            for (let i = 0; i < shownAlertIds.length; i++) {
                let index = -1;
                for (let j = 0; j < this.triggeredAlertsList.length; j++) {
                    if (this.triggeredAlertsList[j].alertId == shownAlertIds[i]) {
                        index = j;
                    }
                }
                if (index != -1) this.triggeredAlertsList.splice(index, 1);
            }
        }
        if (!this.triggeredAlertsList || this.triggeredAlertsList.length < 1) sessionStorage.removeItem("triggeredAlertsList"); else {
            sessionStorage.setItem("triggeredAlertsList", JSON.stringify(this.triggeredAlertsList))
        }
    }

    //Worker Complete
    postAlertsDataWorker(priceAlertResponseData = priceAlertsData) {
        if (priceAlertResponseData != null) {
            if (sessionStorage.getItem("triggeredAlertsList")) {
                this.datagraphAlertsList = JSON.parse(sessionStorage.getItem("triggeredAlertsList"));
            }
            //price alerts
            each(priceAlertResponseData.priceAlerts, (alert) => {
                let data = find(this.datagraphAlertsList, (item) => item.alertId == alert.alert.alertId);
                if (data != null) {

                    data.targetPrice = alert.targetPrice > 1 ? alert.targetPrice.toFixed(2) : alert.targetPrice.toFixed(3);
                    data.msid = alert.msid;
                    if (data.isActive == 1 && data.isTriggered == 0 && alert.alert.isTriggered == 1) {
                        data.isTriggered = alert.alert.isTriggered;
                    }
                    data.isActive = alert.alert.isActive;
                    data.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    data.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    data.notificationId = alert.alert.notificationId;
                    data.note = alert.note;
                    data.priceAlertData = alert;
                }
                else {
                    let alertsData = this.convertPriceAlert(alert);
                    alertsData.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    alertsData.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    if (alertsData.isActive == 0 && alertsData.isTriggered == 1 && moment.duration(moment(new Date()).diff(alertsData.dateLastTriggered)).asSeconds() > 15) alertsData.isTriggered = 0; // Not sure about this one.
                    this.datagraphAlertsList.push(alertsData);
                }
            });

            //moving average alerts
            each(priceAlertResponseData.movingAverageAlerts, (alert) => {
                let data = find(this.datagraphAlertsList, (item) => item.alertId == alert.alert.alertId);
                if (data != null) {
                    data.targetPrice = alert.targetPrice;
                    data.msid = alert.msid;
                    if (data.isActive == 1 && data.isTriggered == 0 && alert.alert.isTriggered == 1) {
                        data.isTriggered = alert.alert.isTriggered;
                    }
                    data.isActive = alert.alert.isActive;
                    data.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    data.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    data.MAAlertData = alert;
                }
                else {
                    let alertsData = this.convertMAAlert(alert);
                    alertsData.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    alertsData.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    if (alertsData.isActive == 0 && alertsData.isTriggered == 1 && moment.duration(moment(new Date()).diff(alertsData.dateLastTriggered)).asSeconds() > 15) alertsData.isTriggered = 0; // Not sure about this one.
                    this.datagraphAlertsList.push(alertsData);
                }
            });


            // //trendline alerts
            
            this.trendLineAlerts = [];
            each(priceAlertResponseData.trendlineAlerts, (alert) => {
                this.trendLineAlerts.push(alert);
                let data = find(this.datagraphAlertsList, (item) => item.alertId == alert.alert.alertId);
                if (data != null) {
                    data.targetPrice = alert.targetPrice;
                    data.msid = alert.msid;
                    data.isActive = alert.alert.isActive;
                    data.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    data.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    data.TLAlertData = alert;
                }
                else {
                    let alertsData = this.convertTLAAlert(alert);
                    alertsData.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    alertsData.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    if (alertsData.isActive == 0 && alertsData.isTriggered == 1) alertsData.isTriggered = 0; // Not sure about this one. 
                    this.datagraphAlertsList.push(alertsData);
                }
            });


            // //stock alerts
            each(priceAlertResponseData.stockAlerts, (alert) => {
                let data = find(this.datagraphAlertsList, (item) => item.alertId == alert.alertId);
                if (data != null) {
                    data.isActive = alert.isEnabled ? 1 : 0;
                    data.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.dateLastUpdated));
                    data.dateLastTriggered = alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.dateLastTriggered)) : null;
                    data.isStockAlert = true;
                    data.stockAlertData = alert;
                }
                else {
                    let alertsData = this.convertStockAlert(alert);
                    alertsData.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.dateLastUpdated));
                    alertsData.dateLastTriggered = alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.dateLastTriggered)) : null;
                    if (alertsData.isActive == 0 && alertsData.isTriggered == 1) alertsData.isTriggered = 0; // Not sure about this one. 
                    alertsData.IsStockAlert = true;
                    this.datagraphAlertsList.push(alertsData);
                }
            });

            // }
            //update Moving Average Alerts. set the data structure
            
            // let PriceChartStore = DatagraphHelper.getPriceChartStore();

            //DataGraphStore.getState().SymbolInfo.SymTypeEnum

            // let maSettings = PriceChartStore.getState().maSettings;

            // let movingAverageAlerts = filter(priceAlertResponseData.movingAverageAlerts, (item) => item.alert.isActive == true
            //     && this.getPeriodicity(item.periodicity) == PriceChartStore.getState().periodicity);

            // each(maSettings, (maItem) => {

            //     maItem.AlertIsActive = false;

            //     let data = filter(movingAverageAlerts, (item) =>
            //         (maItem.ma == item.length &&
            //             maItem.maType == this.getMAAlertType(item.maType) &&
            //             DataGraphStore.getState().SymbolInfo.MsId == item.msid))

            //     if (!StringUtil.isEmpty(data)) {
            //         maItem.AlertIsActive = true;
            //     }
            // });

            // PriceChartStore.populateLineMenuItems();
            dispatch(prepareContextMenu)
            // ViewModel.GetChartPanelModel().GenerateLineMenuItems();

            // //nViewModel.GenerateLineMenuItems();
            // foreach(var lineChartViewModel in ViewModel.MAChartViewModelCollection)
            // {
            //     lineChartViewModel.RefreshMenuItem();
            // }
        }
        if (this.emitWorkerAction) {
            this.emit(AlertConstants.ActionTypes.ALERT_WORKER_COMPLETED);
            this.emitWorkerAction = false;
        }
    }

    handleMovingAverageAlertAction(menuItem, eventName) {

        if (!menuItem.maSettingsObj.AlertIsActive) {
            let maLayer = menuItem.maSettingsObj;
            let _DGSstate = DataGraphStore.getState();

            let maAlertType = maLayer.maType;
            let periodicityType = this.getPeriodicityEnum(TabDataGraphStore.getState().periodicity)
            let chartCurrencyCode = (TabDataGraphStore.getState().SymbolInfo.DefaultCurrency.Code == undefined ? TabDataGraphStore.getState().SymbolInfo.DefaultCurrency.CurrencyCode : TabDataGraphStore.getState().SymbolInfo.DefaultCurrency.Code);

            if (chartCurrencyCode.toLowerCase() == "gbp")
                chartCurrencyCode = "GBX";
            else if (chartCurrencyCode.toLowerCase() == "zar")
                chartCurrencyCode = "ZAX";

            let alertData = { isActive: 1, isTriggered: 0 }
            let maAlertData =
            {
                alert: alertData,
                msid: _DGSstate.SymbolInfo.MsId,
                length: maLayer.ma,
                periodicity: periodicityType,
                maType: maAlertType,
                currencyCode: chartCurrencyCode
            }

            AlertsApi.movingAverageAlertUpsert(maAlertData).then((result) => {
                if (result.responseHeader.error) {
                    this.validateResponse(result);
                }
                else {
                    this.startAlertsDataWorker();
                    this.emit(eventName);
                }

                ConsoleStore.trackUsage('AlertIsCreated');
            }
            )
        }
        else {

            let maLayer = menuItem.maSettingsObj;

            let self = this;

            each(self.datagraphAlertsList, (item) => {
                if (!StringUtil.isEmpty(item.MAAlertData)
                    && item.isActive
                    && item.MAAlertData.length == maLayer.ma
                    && self.getMAAlertType(item.MAAlertData.maType) == maLayer.maType
                    && item.MAAlertData.msid == DataGraphStore.getState().SymbolInfo.MsId
                    && item.MAAlertData.periodicity == self.getPeriodicityEnum(TabDataGraphStore.getState().periodicity)) {
                    self.dismissAlert(item.alertId).then(() => {
                        this._removeAlert(item.alertId);
                        self.startAlertsDataWorker();
                        self.emit(eventName);
                    });
                }
            });
        }
    }
    
    validateResponse(result) {
        let code = result.responseHeader.errorCodeData.errorCode;
        switch (code) {
            case 50001:
            case 50003:
                ConsoleActions.exceptionHandle(null, false, 'List alerts can be created for up to 10 lists, and only for lists which have 1,000 symbols or less.', false, "LIST ALERT LIMIT", true);
                break;
            default:
                ConsoleActions.exceptionHandle();
                break;

        }
    }
}
const alertStore = new AlertStore();
export default alertStore;
