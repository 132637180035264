import AppDispatcher from "AppDispatcher";
import { callPriceChartApi } from "../../Actions/DatagraphActions.js";
import { dispatch } from "../../Redux/dispatch.js";
import { EventEmitter } from "events";
import { MenuDialogConstants } from "../../Constants/MenuDialogConstants.js";
import SettingsStore from "SettingsStore";

const CHANGE_EVENT = "change";
let _states = null;

class ReportedEarningsStoreClass extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    if (!_states) {
      _states = {};
      _states.revenue = this.getRevenueSettings();
      _states.earnings = this.getEarningsSettings();
    }
    return _states;
  }

  changeReportedRevenue(data) {
    this.setRevenueSettings(data);
  }

  changeReportedEarnings(data) {
    this.setEarningsSettings(data);
  }

  getRevenueSettings() {
    return SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly;
  }

  getEarningsSettings() {
    return SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly;
  }

  setRevenueSettings(data) {
    this.getState().revenue = data;
    SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly = data;
  }

  setEarningsSettings(data) {
    this.getState().earnings = data;
    SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly = data;
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      case MenuDialogConstants.ActionTypes.UPDATE_REVENUE:
        this.changeReportedRevenue(data);
        SettingsStore.saveSettings();
        dispatch(callPriceChartApi());
        this.emit(CHANGE_EVENT);
        break;
      case MenuDialogConstants.ActionTypes.UPDATE_EARNINGS:
        this.changeReportedEarnings(data);
        SettingsStore.saveSettings();
        dispatch(callPriceChartApi());
        this.emit(CHANGE_EVENT);
        break;
      default:
        return true;
    }
    return true;
  }
}


const ReportedEarningsStore = new ReportedEarningsStoreClass();
export default ReportedEarningsStore;