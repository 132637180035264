import { combineReducers } from 'redux';
import ExternalDataUploaderReducer from './ExternalDataUploaderReducer';
import externalDataUploadSymbolReducer from './externalDataUploadSymbolReducer';
import ExternalDataListViewReducer from './ExternalDataListViewReducer';

const externalDataUploader = combineReducers({
    ExternalDataUploaderReducer,
    externalDataUploadSymbolReducer,
    ExternalDataListViewReducer
});
export default externalDataUploader;