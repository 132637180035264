import AppColorStore from "Stores/AppColor/AppColorStore";
import { connect } from 'react-redux';
import { EllipseControl } from '../Presentational/index';
import {
    handleDragEnd, handleAnchorDragStart, handleEllipAnchorUp, handleEllipAnchorDrag, handleDeleteAnnotationDrawing, handleToggleContextMenu,
    handleDragStart, handleDragMove
} from '../../../../../../Actions/AnnotationActions';
import React, { Component } from 'react';

class GraphicsEllipse extends Component {

    componentDidMount() {
        AppColorStore.addChangeListener(this.AppColorStoreStateChanged);
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.AppColorStoreStateChanged);
    }

    AppColorStoreStateChanged = () => {
        this.forceUpdate();
    }

    handleDragEnd = (e) => {
        this.props.handleDragEnd(e);
    }

    render() {
        const { ellipse, index, lockImage, isApiReqResolved } = this.props;
        const shapesModelInfo = this.props.shapesModelInfo;
        const { isDragging, activeAnchor, selectedAnnotId } = this.props.annotationChartModelReducer;

        return (

            <EllipseControl
                onContextMenu={this.props.onContextMenu}
                key={index}
                index={index}
                ellipse={ellipse}
                shapesModelInfo={shapesModelInfo}
                isDragging={isDragging}
                selectedAnnotId={selectedAnnotId}
                activeAnchor={activeAnchor}
                handleDragStart={this.props.handleDragStart}
                handleDragMove={this.props.handleDragMove}
                handleDragEnd={this.props.handleDragEnd}
                handleAnchorDragStart={this.props.handleAnchorDragStart}
                handleAnchorDragEnd={this.props.handleAnchorDragEnd}
                handleAnchorDragMove={this.props.handleAnchorDragMove}
                draggable={this.props.draggable}
                lockImage={lockImage}
                isApiReqResolved={isApiReqResolved}
                handleDeleteAnnotationDrawing={this.props.handleDeleteAnnotationDrawing}
            />
        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { ellipseReducer, annotationChartModelReducer } = annotationReducers;
    const shapesModelInfo = annotationReducers.annotationMenuReducer.ShapesModelInfo
    return ({ ellipseReducer, shapesModelInfo, annotationChartModelReducer });
}

const mapDispatchToProps = (dispatch) => ({
    handleDragStart: (ellip) => dispatch(handleDragStart(ellip)),
    handleDragMove: (e) => dispatch(handleDragMove(e)),
    handleDragEnd: () => handleDragEnd(),
    handleAnchorDragStart: (anchor) => dispatch(handleAnchorDragStart(anchor)),
    handleAnchorDragEnd: () => dispatch(handleEllipAnchorUp()),
    handleAnchorDragMove: (e) => dispatch(handleEllipAnchorDrag(e)),
    handleDeleteAnnotationDrawing: (charCode) => { dispatch(handleDeleteAnnotationDrawing(charCode)), dispatch(handleToggleContextMenu(false, 0, 0, '')) }
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphicsEllipse);