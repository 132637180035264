import React from "react";

export default class TabModel extends React.Component {
  constructor() {
      super();
  }

  render(){
    return(<div id="modeBlock" style={{color:"white"}}>In Model Tab</div>)
  }
}
