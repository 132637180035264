import { CopyRightTranslateHelper } from '../../../../../Utils/TranslateHelper';
import { getDatagraphStates } from '../../../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { updateShowNQBox } from '../../../../../Actions/DatagraphActions';
import React from 'react';
import ThemeHelper from "ThemeHelper";
import { useSelector } from 'react-redux';
import { dispatch } from "../../../../../Redux/dispatch.js";


function CopyRight() {
    const handleTMouseDown = () => {
        dispatch(updateShowNQBox());
    }
    const backgroundColor = ThemeHelper.getThemedBrush("000fff");
    const copyColor = ThemeHelper.getThemedBrush("fff000");
    let quoteMessage1 = '', quoteMessage2 = '';
    const dt = new Date();
    const copyRight = `\xA9 ${dt.getFullYear()} WILLIAM O'NEIL + CO. INC.`;
    const { isTrading, IsNASDAQBasic, notDelayed, showNQBox } = useSelector(getDatagraphStates);
    if (isTrading) {
        if (IsNASDAQBasic) {
            if (notDelayed) {
                if (IsNASDAQBasic) {
                    quoteMessage1 = CopyRightTranslateHelper.REAL_TIME_PRICES;
                    quoteMessage2 = CopyRightTranslateHelper.REAL_TIME_QUOTES;
                }
            } else {
                quoteMessage1 = CopyRightTranslateHelper.DELAYED_20_MIN;
            }
        } else {
            quoteMessage1 = CopyRightTranslateHelper.DELAYED_20_MIN;
        }
    }

    const Message = `${copyRight} ${quoteMessage1}`;
    let lngth = 16 + (Message.length * 5);
    let left = lngth.toString() + "px";
    return (<>
        {(showNQBox && Message.length > 1) &&
            <div style={
                {
                    position: "absolute",
                    height: 'Auto',
                    width: 'Auto',
                    background: '#FFFFDD',
                    bottom: '2px',
                    font: 'calibri',
                    fontSize: '10px',
                    fontWeight: 'Bold',
                    left: left, zIndex: '1',
                    color: 'black',
                    textAlign: "center"
                }}>
                <div style={
                    {
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        paddingTop: '3px'
                    }}>
                    <div>{Message}</div>
                    <div>{quoteMessage2}</div>
                </div>
            </div>
        }<div className="copyrighting pricing" style={{
            background: backgroundColor,
            color: copyColor,
        }}>
            <div className="text" data-disable-track-price="true" onMouseDown={() => handleTMouseDown()} >
                <p>{Message}</p>
                <p>{quoteMessage2}</p>
            </div>
        </div>
    </>)
}
export default React.memo(CopyRight);