import React, { PureComponent } from "react";
import { MenuHeder, ListContent, SymbolsPanelHeader, GroupsPanelHeader, LiquidityAndRatingsFilterButton, SymbolsGroupsToggler } from './Containers';
import { connect } from 'react-redux';
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType, { CompareMenuType } from "CompareTabType";
import { RotationMenuType } from "Constants/RotationMenuType";
import CompCreateIndexLine from '../ComparisonGraph/Containers/CompCreateIndexLine';
import { updateMenuId } from '../../../../Actions/ComparisonAndRotationMenuActions';
import { PrintMode } from '../../../../print/printmode';
import CompareStore from "CompareStore";

class SubMenuPanel extends PureComponent {

  constructor() {
    super();
    this.getLiqFilterStatus = this.getLiqFilterStatus.bind(this);
    this.liqFilterStatus = true;
  }

  componentDidMount() {
    this.props.updateMenuId(document.getElementById("graphDetails"));
  }

  getLiqFilterStatus(rotationMenuType) {
    if (rotationMenuType == RotationMenuType.Stocks) {
      this.liqFilterStatus = true;
    } else {
      this.liqFilterStatus = false;
    }
  }


  emitTabSelectChanges() {
    this.props.setCompareTab(CompareStore.getState().SelectedTabKey);
  }

  render() {
    const { menuTab, selectedTab, rotationMenuType, isLiqAndRtngFilterVisible,breadCrumbItems } = this.props;

    let additionalClass,breadCrumbClass = '';
    if(breadCrumbItems && breadCrumbItems.length > 1){
      if(breadCrumbItems.length == 2)
        breadCrumbClass = "level-two";
      if(breadCrumbItems.length == 3)
        breadCrumbClass = "level-three";
    }
    switch (selectedTab) {
      case CompareTabType.RotationGraph:
        if (menuTab == CompareMenuType.Symbols) {
          additionalClass = "rotation-symbols";
          this.liqFilterStatus = true;
        }
        else {
          additionalClass = "rotation-groups";
          this.getLiqFilterStatus(rotationMenuType);
        }
        break;
      case CompareTabType.ComparisonGraph:
        if (menuTab == CompareMenuType.Symbols) {
          additionalClass = "comparison-symbols";
          this.liqFilterStatus = true;
        }
        else {
          additionalClass = "comparison-groups "+breadCrumbClass;
          this.getLiqFilterStatus(rotationMenuType);
        }
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <div className="flexbox-parent">
          <SymbolsGroupsToggler />
          <div className="flexbox-item header">
            <div className="top-view-fixed">
              {menuTab == CompareMenuType.Symbols ?
                <ErrorBoundary><SymbolsPanelHeader /></ErrorBoundary>
                :
                <ErrorBoundary><GroupsPanelHeader /></ErrorBoundary>}
            </div>
          </div>

          {selectedTab === CompareTabType.ComparisonGraph ?
            <div className="flexbox-item material-body">
              <div className={"infoContainer " + additionalClass}>
                <div className="inner-infoContainer">
                  <div className="graphDetails">
                    <ErrorBoundary><MenuHeder /></ErrorBoundary>
                    <ErrorBoundary><ListContent /></ErrorBoundary>
                  </div>
                  {/* {!PrintMode.printing && menuTab == "Symbols" ? <CompCreateIndexLine /> : ''} */}
                  {menuTab == CompareMenuType.Symbols ? <ErrorBoundary><CompCreateIndexLine /></ErrorBoundary> : ''}
                </div>
              </div>
            </div>
            :
            <div className="flexbox-item material-body">
              <div className={"infoContainer " + additionalClass}>
                <div className="inner-infoContainer">
                  <div className="graphDetails" id="graphDetails">
                    <ErrorBoundary><MenuHeder /></ErrorBoundary>
                    <ErrorBoundary><ListContent /></ErrorBoundary>
                  </div>
                </div>
              </div>
            </div>
          }
          {isLiqAndRtngFilterVisible && !PrintMode.printing && this.liqFilterStatus ? <div className="flexbox-item footer">
            <div className="graph-list-action-button">
              <ErrorBoundary><LiquidityAndRatingsFilterButton /></ErrorBoundary>
            </div>
          </div> : ''}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const symbolsMenu = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
  const compChartListItem = state.comparisonGraph.compChartListItem;

  if (selectedTab === CompareTabType.ComparisonGraph) {
    const menuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.ComparisonMenuTab;
    const { rotationMenuType,breadCrumbItems } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.ComparisonMenuStates;
    const isLiqAndRtngFilterVisible = menuTab == CompareMenuType.Symbols ? compChartListItem.SymbolsData.length > 0 : true;

    return { selectedTab, menuTab, rotationMenuType, isLiqAndRtngFilterVisible,breadCrumbItems };
  }
  else {
    const menuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    const { rotationMenuType,breadCrumbItems } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.RotationMenuStates;
    const isLiqAndRtngFilterVisible = menuTab == CompareMenuType.Symbols ? symbolsMenu.RotationMenuStates.stockList.length > 0 : true;

    return { selectedTab, menuTab, rotationMenuType, isLiqAndRtngFilterVisible,breadCrumbItems };
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateMenuId: (menuId) => dispatch(updateMenuId(menuId))
})


export default connect(mapStateToProps, mapDispatchToProps)(SubMenuPanel);
