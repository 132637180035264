class BrowserUtil {

  constructor() {
    if (typeof document !== 'undefined') {
      this.appElement = document.getElementById('app');
    }
    
    const isIE = /*@cc_on!@*/false;
    let nAgt = navigator.userAgent;
    this.browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
      this.browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      this.browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      this.browserName = "IE";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In EDGE, the true version is after "edge" in userAgent
    else if (!isIE && (verOffset = nAgt.indexOf("Edge")) != -1) {
      this.browserName = "EDGE";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      this.browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      this.browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      this.browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      this.browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        this.browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
  }

  isIEBrowser() {
    if (this.getBrowserName() == 'IE' || this.getBrowserName() == 'Netscape' || this.getBrowserName() == 'EDGE') return true;
    else return false;
  }

  isEdgeBrowser() {
    if (this.getBrowserName() == 'EDGE') return true;
    else return false;
  }

  getBrowserName() {
    return this.browserName;
  }

  detectMyBrowser() {
  }

  enableEvents() {
    this.appElement.removeAttribute('style');
    document.body.removeAttribute('style');
  }

  disableEvents() {
    this.appElement.style.pointerEvents = 'none';
    document.body.style.cursor = 'wait';
  }

  isDisableEventsOn() {
    if (document.body.style) {
      if (this.appElement.style.pointerEvents == 'none' && document.body.style.cursor == 'wait') return true;
      else return false;
    }
    else {
      return false;
    }
  }

  getChartCurrentTime() {
    const startTime = new Date().getTime();
    return startTime;
  }

}

const browserUtil = new BrowserUtil();
export default browserUtil;
