var DatagraphDataType = {
  FinancialOwnership: "FinancialOwnership",
  SummaryBlock: "SummaryBlock",
  External: "External",
  PricePanelAndFinancial: "PricePanelAndFinancial",
  Factor: "Factor",
  OwnershipReport: "OwnershipReport",
  FinancialBlock: "FinancialBlock",
  OwnershipBlock: "OwnershipBlock",
  DatagraphRender: "DatagraphRender",
  TIPanel: "TIPanel",
  TechnicalIndicators: "TechnicalIndicators",
  KPIPanel: "KPIPanel",
  ExternalDataTIPanel: "ExternalDataTIPanel",
  ExternalDataEvents: "ExternalDataEvents",
  FactorData: "FactorData",
  Annotation: "Annotation",
  ListContent: "ListContent",
  ListExplorer: "ListExplorer",
  SummaryStats_Histogram: "SummaryStats_Histogram",
  SummaryStats_Data: "SummaryStats_Data", 
  ListManagerOverallEnd: "ListManagerOverallEnd"
};

export default DatagraphDataType;
