let CompareTabType = {
  ComparisonGraph: "Comparison Graph",
  RotationGraph: "Rotation Graph"
};

export default CompareTabType;

let CompareMenuType ={
  Symbols:"Symbols",
  Groups:"Groups"
}

export {CompareMenuType};