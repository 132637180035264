import React from "react";
import HeaderCell from "./HeaderCell";
// import NonEditableFilter from "../ListViewCustomFilter/NonEditableFilter.jsx";
// import EditableFilter from "../ListViewCustomFilter/EditableFilter.jsx";
// import MultiSelectFilter from "../ListViewCustomFilter/MultiSelectFilter.jsx";
import ONeilViewStore from "ONeilViewStore";
// import OwnershipViewStore from "OwnershipViewStore";
import ListManagerTabType from "ListManagerTabType";
import ListStore from "ListStore";
import { each } from "underscore";
import ListExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
let left = 0.0;
let currentPosMouseX = undefined;

export default class HeaderRow extends React.Component {

    constructor(props) {
        super(props);
        this.setdefaultDragState = this.setdefaultDragState.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.onColumnDrop = this.onColumnDrop.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.setDraggedState = this.setDraggedState.bind(this);
        this.resetDraggedState = this.resetDraggedState.bind(this);
        this.getMouseDownGhostImage = this.getMouseDownGhostImage.bind(this);
        this.handleColumnSort = this.handleColumnSort.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.getColumnsHeaders = this.getColumnsHeaders.bind(this);
        this.subscribeEvents = this.subscribeEvents.bind(this);
        this.unsubscribeEvents = this.unsubscribeEvents.bind(this);
        this.onCustomFilterContextMenuClick = this.onCustomFilterContextMenuClick.bind(this);
        this.state = {
            shouldUpdate: true,
            isMouseDown: false,
            pos: undefined,
            targetObject: undefined,
            dragStarted: false,
            draggedOverColumn: undefined,
            columns: [],
            isResizingMouseDown: false,
            isResizing: true,
            viewportWidth: 0.0
        }
    }

    componentDidMount() {
        this.arrowElm = document.getElementById('arrow11');
        this.gridHorizontalScroll = document.getElementById('gridHorizontalScroll');
        this.listExplorer = document.getElementById('listExplorer');
        this.gridHeaderLeftRightElm = document.getElementsByClassName('grid-header-left-right');
        this.colResizeSeparator = document.getElementById("colResizeSeparator");
    }


    setdefaultDragState() {
        //this.removePosition();
        currentPosMouseX = undefined;
        this.arrowElm.style.zIndex = -1;
        this.arrowElm.style.display = 'none';
        if (this.colResizeSeparator && this.colResizeSeparator.hasAttribute('style')) this.colResizeSeparator.removeAttribute('style');
        this.setState({
            shouldUpdate: true,
            isMouseDown: false,
            pos: undefined,
            targetObject: undefined,
            dragStarted: false,
            draggedOverColumn: undefined,
            isResizingMouseDown: false,
            isResizing: false
        });
        this.unsubscribeEvents();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.isUpdateNotRequired) {
            return false;
        }
        return nextState.shouldUpdate;
    }

    subscribeEvents ()  {
        document.addEventListener("mousemove", this.handleMouseMove, false);
        document.addEventListener("mouseup", this.handleMouseUp, false);
        //    document.addEventListener("mouseover", this.handleMouseOver.bind(this), false);
    }
    unsubscribeEvents ()  {
        document.removeEventListener('mousemove', this.handleMouseMove, false);
        document.removeEventListener('mouseup', this.handleMouseUp, false);
    }


    componentWillUnmount() {
        this.unsubscribeEvents();
    }
    UNSAFE_componentWillReceiveProps(params) {
        if (params.columns !== undefined || params.viewportWidth >= 0.0) {
            this.setState({
                columns: params.columns,
                viewportWidth: params.viewportWidth,
                shouldUpdate: true
            });
        }
        else {
            this.setState({
                shouldUpdate: false
            });
        }
    }
    handleScroll(scroll) {
        if (this.main) this.main.scrollLeft = scroll;
        if (this.customFilter) this.customFilter.scrollLeft = scroll;
    }

    handleMouseDown(e, colData) {
        this.setState({
            isMouseDown: true,
            pos: { x: e.clientX, y: e.clientY },
            targetObject: colData
        });
        this.subscribeEvents();
        // e.preventDefault();
    }

    getBoundingClientRect() {
        if (this.main)
            return this.main.getBoundingClientRect();
        else
            return null;
    }

    handleMouseUp() {
        this.setdefaultDragState();
        if(typeof this.props.handleMouseUp === "function"){
            this.props.handleMouseUp();
        }
    }

    onColumnDrop(viewportWidth) {
        const defaultState = this.setdefaultDragState();
        defaultState["viewportWidth"] = viewportWidth;
        this.setState(defaultState);

    }

    handleMouseMove(e) {
        if (this.state.isResizingMouseDown) {
            if (e.clientX - this.state.pos.x > this.state.targetObject.minWidth && e.clientX - this.state.pos.x <= 500) {
                this.props.onResize(Math.ceil(e.clientX - this.state.pos.x), this.state.targetObject, e.clientX);
                this.setState({
                    isResizing: true,
                    shouldUpdate: false
                });
            }
        }
        else if (this.state.isMouseDown && this.main) {
            const relPos = e.clientX - this.state.pos.x;
            //let pos = this.main.getBoundingClientRect();
            const scrollX = this.gridHorizontalScroll.scrollLeft;

            if (relPos > 5 || relPos < -5) {
                if (!this.state.dragStarted) {
                    this.setState({ dragStarted: true });
                }
            }
            if (this.state.dragStarted) {
                currentPosMouseX = e.clientX;
                let left, draggedPos;
                if (e.clientX <= this.props.frozenAreaWidth + this.listExplorer.offsetWidth) {
                    left = ((e.clientX + scrollX) - (this.state.targetObject.width / 2))
                    draggedPos = e.clientX - this.listExplorer.offsetWidth;
                }
                else {
                    left = ((e.clientX + scrollX) - (this.state.targetObject.width / 2))
                    draggedPos = (e.clientX + scrollX) - this.listExplorer.offsetWidth;
                }


                // Prevent default frozen columns be dgraggable
                if ((this.props.selectedListId === "-1" || this.props.selectedListId === -1 || this.props.selectedListId === -2)&& this.state.targetObject.id > 2) {
                    this.props.onColumnDragging(e, this.state.targetObject, left, draggedPos, scrollX);
                }
                else if (this.state.targetObject.id > 3) {
                    this.props.onColumnDragging(e, this.state.targetObject, left, draggedPos, scrollX);
                }
            }
            e.preventDefault();
        }
    }

    setDraggedState(draggedOverColumn) {
        // this.setPosition();
        this.setState({
            draggedOverColumn: draggedOverColumn,
            dragStarted: true
        });
    }

    resetDraggedState() {
        this.setState({
            draggedOverColumn: undefined,
        });
    }

    setPosition() {
        for (let i = 0; i < this.gridHeaderLeftRightElm.length; i++) this.gridHeaderLeftRightElm[i].classList.add('pos-initial');
    }

    removePosition() {
        for (let i = 0; i < this.gridHeaderLeftRightElm.length; i++) this.gridHeaderLeftRightElm[i].classList.remove('pos-initial');
    }

    getMouseDownGhostImage() {
        const targetObject = this.state.targetObject;
        const style = { width: targetObject.width };
        return (<div key="temp" className="grid-header-left ghost-image" style={style}>{targetObject.DisplayText}</div>)
    }

    handleResize(colData, posLeft) {
        this.setState({
            isResizingMouseDown: true,
            targetObject: colData,
            pos: { x: posLeft, y: 0.0 },
            shouldUpdate: false
        });
        this.subscribeEvents();

    }
    offset(el) {
        const rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    getPos(ele) {
        const pos = this.offset(ele);
        const lefts = pos.left;
        return { left: lefts, top: pos.top };
    }

    onContextMenuClick(e, colData) {
        this.props.onContextMenuClick(e, colData);
    }

    onCustomFilterContextMenuClick(e) {
        this.props.onCustomFilterContextMenuClick(e);
    }

    onColumnResizerDoubleClick(colData) {
        this.props.onColumnResizerDoubleClick(colData)
    }

    getColumnsHeaders(islabelRequired = true) {
        const columns = [];
        const columnData = this.state.columns;
        left = 0.0;
        let pos = {
            left: 0,
            top: 0
        };
        let posArrow = -10;
        const paddingleft = 5;
        const selectedTab = ListStore.getState().SelectedTabKey;
        const tabType = ListStore.getTabType(selectedTab);
        let disbleFunctionOnSharedColumnSet;
        each(columnData, (colData) => {
            let background = undefined;
            if (this.state.draggedOverColumn !== undefined && colData.id === this.state.draggedOverColumn.id) {
                background = "skyBlue";
                const overDragedElement = document.querySelector(`[data-itemid="${this.state.draggedOverColumn.dataItemId}"]`);
                pos = this.getPos(overDragedElement);
                posArrow = pos.left;
                if (currentPosMouseX && (pos.left + (overDragedElement.offsetWidth / 2)) <= currentPosMouseX) {
                    posArrow = pos.left + overDragedElement.offsetWidth;
                }

                if (this.props.selectedListId === "-1" || this.props.selectedListId === -1 || this.props.selectedListId === -2) { // if O'Neil db  || Ownership db is selected
                    if (this.props.allColumns.indexOf(this.state.draggedOverColumn) === 2) posArrow = pos.left + overDragedElement.offsetWidth;
                }
                else {
                    if (this.props.allColumns.indexOf(this.state.draggedOverColumn) === 3) posArrow = pos.left + overDragedElement.offsetWidth;
                }

                posArrow = posArrow - paddingleft;
            }
            switch (tabType) {
                case ListManagerTabType.Oneil:
                    disbleFunctionOnSharedColumnSet = ONeilViewStore.getIsSharedColumnSet();
                    break;
                case ListManagerTabType.Ownership:
                    disbleFunctionOnSharedColumnSet = false;
                    break;
                    default: break;
            }

            columns.push(<HeaderCell
                background={background}
                key={colData.dataItemId}
                data={colData}
                left={left}
                posArrow={posArrow}
                frozenAreaWidth={this.props.frozenAreaWidth}
                islabelRequired={islabelRequired}
                disbleFunctionOnSharedColumnSet={disbleFunctionOnSharedColumnSet}
                screenOnOff={this.props.screenOnOff}
                handleResize={this.handleResize}
                getColumnWidth={this.props.getColumnWidth}
                onMouseDown={(e, data) => this.handleMouseDown(e, data)}
                handleColumnSort={(e, data) => this.handleColumnSort(e, data)}
                handleColumnCustomFilter={(e, data) => this.handleColumnCustomFilter(e, data)}
                onContextMenuClick={(e) => this.onContextMenuClick(e, colData)}
                onCustomFilterContextMenuClick={this.onCustomFilterContextMenuClick}
                onColumnResizerDoubleClick={() => this.onColumnResizerDoubleClick(colData)}
                dragStarted={this.state.dragStarted}
            />);

            const colWidth = this.props.getColumnWidth(colData);

            left += colWidth;
        }, this);

        return columns;
    }

    handleColumnSort(e, colData) {
        if (colData.isSortingEnable) this.props.handleColumnSort(e, colData);
    }

    handleColumnCustomFilter(e, colData) {
        this.props.handleColumnCustomFilter(e, colData);
    }

    render() {

        const columns = this.getColumnsHeaders();
        const customFiltercolumns = this.getColumnsHeaders(false);
        let width = this.state.viewportWidth;
        if (!this.props.separater) width += 16;
        return (
            <div className="pos-relative1 bdr-bottom-change-color">
                {(this.props.separater && !ListExplorerStore.isNotImportedList() ? <span className={!this.props.spliter ? "icn-divider-switch" : "icn-divider-switch-reverse"} onClick={this.props.onSplitterClick}></span> : '')}
                <div ref={(r) => { this.customFilter = r; }} className="grid-header-left-right lefter">
                    <div style={{ width: width }}>
                        {customFiltercolumns}
                    </div>
                    <div className="grid-header-blank-div" style={{ left: left }}>&nbsp;</div>
                </div>
                <div ref={(r) => { this.main = r; }} className="grid-header-left-right righter">
                    <div style={{ width: width }}>
                        {columns}
                    </div>
                    <div className="grid-header-blank-div" style={{ left: left }}>&nbsp;</div>
                </div>
            </div>
        );
    }
}
