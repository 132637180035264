import Serializable from "Serializable";
export default class KpiSettings extends Serializable {
  constructor() {
    super();
  }
  
  getDefaultKpiLineObj() {
    const KPILine = [{ isActive: true, color: "defaultKPIcolor", weight: 2 }];
    return KPILine.map((item) => this.getSerizableObject(item));
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

  onSeralisedInfoRecieved(serializedValue) {
    this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
    this.Height = serializedValue.Height ? serializedValue.Height : 100;
    this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
    this.KpiColor = serializedValue.KpiColor ? serializedValue.KpiColor : this.getDefaultKpiLineObj();
    this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : '';
}

  getDataObject() {
    return {
      IsVisible: this.IsVisible,
      Height: this.Height,
      IsAvailable: this.IsAvailable,
      KpiColor: this.KpiColor,
      seqNumber: this.seqNumber
    }
  }

  getDefaultKpiChartSettings() {
    this.IsVisible = true;
    this.Height = 100;
    this.IsAvailable = true;
    this.KpiColor = this.getDefaultKpiLineObj();
    this.seqNumber = '';
    return this;
  }

  getObjectMapKey() {
    return "KpiChartSettings";
  }
}
