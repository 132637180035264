var MenuDialogType = {
    Preferences: "3",
    QuoteService:"31",
    Appearance:"32",
    Currency: "33",
    Account: "1",
    Help: "4",
    ReportedEarnings:"2",
    Logout: "5",
    Notification: "6",
    Sharing: "7"
};

export default MenuDialogType;
