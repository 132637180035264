import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import CompareApi from "CompareApi";
import { RotationGraphConstants } from "RotationGraphConstants";
import _ from 'underscore';
import StringUtil from "StringUtil";
import CompareStore from "CompareStore";
import SettingsStore from "SettingsStore";
import RotationGraphMenuStore from "RotationGraphMenuStore";
import DateHelper from "DateHelper";
import moment from "moment";
import ContextMenuConstants from "ContextMenuConstants";
import {RotationMenuType} from "../../../../Constants/RotationMenuType";
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";
import BrowserUtil from "BrowserUtil";
import ConsoleStore from 'ConsoleStore';

import { initializeTimeLineWorker } from "WebWorker";

const CHANGE_EVENT = "change";

let _isDataLoaded = null;

class RotationGraphStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        //Bug fixes - Max Limit error 
        this.setMaxListeners(0);
        this.states = {
            geographyDataList: [],
            groupsMainData: null,
            globalCountDataList: null,
            liqfilterdata: null,
            benchMarkData: null,
            listItemType: null,
            listItems: null,
            quadGraphYearList: null,
            isRefresh: false,
            zoomMatrix: "matrix(" + 1 + "," + 0 + "," + 0 + "," + 1 + "," + 0 + "," + 0 + ")",
            zoomPercentageValue: "AUTO"
        };
        this.groupsTimelineDateDictionary = [];
        this.groupsCountDataList = [];
        this.lastAvailableDate = null;
        this.selectedSliderValue = null;
        this.toolTip = null;
        this.isBenchMarkLoading = false;
        this.isSliderReset = false;
        this.isGeographySelected = false;
        this.benchmarkDataLoaded = false;
        this.timer = null;
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    fetchBechMarkGraphSettings() {
        if (StringUtil.isEmpty(SettingsStore.getConsoleSettings())) return;
        this.selectedSliderValue = this.tabRotationGraphSettings.rotationSymbolsMenuSettings.timeLineIndex;
    }

    updateIsRotationMenuPanelOpen(isRotationMenuPanelOpen) {
        let navCompareSettings = CompareStore.getNavCompareSettings();
        navCompareSettings.TabRotationGraphSettings.isRotationMenuPanelOpen = isRotationMenuPanelOpen;
        SettingsStore.saveSettings();
    }

    updateIsTimeLinePlaying(isTimeLinePlaying) {
        let navCompareSettings = CompareStore.getNavCompareSettings();
        navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings.isTimeLinePlaying = isTimeLinePlaying;
        SettingsStore.saveSettings();
        // //Log Usage
        // ConsoleStore.trackUsage('SliderDate Changed', moment(this.groupsTimelineDateDictionary[this.selectedSliderValue]).format('MM/DD/YYYY'));
    }

    stopISPlaying() {
        if (this.getIsPlaying()) {
            this.isSliderReset = true;
            this.updateIsTimeLinePlaying(false);
            this.currentAction = null;
            this.emit(CHANGE_EVENT);
        }
    }

    getIsPlaying() {
        let navCompareSettings = CompareStore.getNavCompareSettings();
        navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings.isTimeLinePlaying
        return navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings.isTimeLinePlaying;
    }

    getGroupData() {
        return this.states.groupsMainData;
    }


    updateIsUptrendVisible(isUptrendVisible) {
        let navCompareSettings = CompareStore.getNavCompareSettings();
        navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings.isUptrendVisible = isUptrendVisible;
        SettingsStore.saveSettings();
    }

    updateIsDowntrendVisible(isDowntrendVisible) {
        let navCompareSettings = CompareStore.getNavCompareSettings();
        navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings.isDowntrendVisible = isDowntrendVisible;
        SettingsStore.saveSettings();
    }

    updateTimeLineIndex(timeLineIndex) {
        let navCompareSettings = CompareStore.getNavCompareSettings();
        navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings.timeLineIndex = timeLineIndex;
        SettingsStore.saveSettings();
    }

    updateRotationGrpahListItemsData(graphListType, graphListItems) {
        this.states.listItems = graphListItems;
        this.states.listItemType = graphListType;
    }

    resetTimeLinePlaying(isLiqFilterOpen = false) {
        if (this.getIsPlaying()) {
            this.isSliderReset = true;
            if (!isLiqFilterOpen) {
                let navCompareSettings = CompareStore.getNavCompareSettings();
                this.selectedSliderValue = this.groupsTimelineDateDictionary.length - 1;
                navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings.timeLineIndex = this.selectedSliderValue;
            }
            this.updateIsTimeLinePlaying(false);
        }
    }

    getRotationGraphContextMenu(selectedGraphItem) {
        let contextMenu = [];

        switch (selectedGraphItem.selectedGraphItem) {
            case "graphLine":
                contextMenu.push({ contextName: ContextMenuConstants.ROTATION_GRAPH_VIEW_SYMBOL, action: ContextMenuConstants.ROTATION_GRAPH_VIEW_SYMBOL });
                if (this.states.listItemType == RotationMenuType.Stocks) {
                    contextMenu.push({ contextName: !selectedGraphItem.item.isFlagged ? ContextMenuConstants.ROTATION_GRAPH_FLAG : ContextMenuConstants.ROTATION_GRAPH_REMOVE_FLAG, action: ContextMenuConstants.ROTATION_GRAPH_FLAG });
                }
                else {
                    contextMenu.push({ contextName: ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS, action: ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS });
                }
                break;
            case "svgElement":
                contextMenu.push({ contextName: ContextMenuConstants.ROTATION_GRAPH_RESET_TO_100, action: ContextMenuConstants.ROTATION_GRAPH_RESET_TO_100 });
                break;
            default:
                break;

        }
        return contextMenu;
    }

    onContextMenuItemClick(action, item) {
        switch (action) {
            case ContextMenuConstants.ROTATION_GRAPH_VIEW_SYMBOL:
                RotationGraphMenuStore.navigateToSymbolView(item.underlyingSymbol);
                break;
            case ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS:
                RotationGraphMenuStore.clearRotationGraphData();
                RotationGraphMenuStore.enableLoading();
                if (this.states.listItemType == RotationMenuType.Sector)
                    RotationGraphMenuStore.createIndustryListItem(item);
                else if (this.states.listItemType == RotationMenuType.Industry)
                    RotationGraphMenuStore.createStockListItem(item);
                break;
            case ContextMenuConstants.ROTATION_GRAPH_FLAG:
                if (this.states.listItemType == RotationMenuType.Stocks)
                    RotationGraphMenuStore.updateFlag(item);
                break;
            default:
                break;
        }
    }

    initializeTimelineValues() {
        let currentDate = moment.utc();
        let startDate = moment.utc().set({ 'year': 2010, 'month': 0, 'date': 1 }); // mountain chart will always start from Jan 1st 2010, Friday
        this.groupsTimelineDateDictionary = [];
        while (startDate.isSameOrBefore(currentDate, 'day')) {
            this.groupsTimelineDateDictionary.push(startDate.toDate());
            // From Jan 1st 2010 we add every fridays date to the time line dictionary
            startDate.add(7, 'days');
        }
        if (currentDate.day() != 5) {
            this.groupsTimelineDateDictionary.push(currentDate.toDate());
        }

        if (!StringUtil.isEmpty(this.groupsTimelineDateDictionary)) {
            if (StringUtil.isEmpty(this.selectedSliderValue))
                this.selectedSliderValue = this.groupsTimelineDateDictionary.length - 1;
        }
    }

    startinitializeTimeLineBasedOnDataWorker() {
        return new Promise((resolve) => {
            let initializeTimeLineWorkerObj = new initializeTimeLineWorker();
            const self = this;
            self.benchmarkDataLoaded = false;
            self.groupsCountDataList = [];
            let obj = JSON.parse(JSON.stringify(self.states));
            initializeTimeLineWorkerObj.postMessage({ initializeRotationGraphData: 'start', typeObjectStore: obj, groupsTimelineDateDictionary: self.groupsTimelineDateDictionary, groupsCountDataList: self.groupsCountDataList, lastAvailableDate: self.lastAvailableDate, selectedSliderValue: self.selectedSliderValue });

            initializeTimeLineWorkerObj.onmessage = (e) => {
                let { groupsTimelineDateDictionary, groupsCountDataList, selectedSliderValue, quadGraphYearList, benchMarkData } = e.data;
                self.groupsTimelineDateDictionary = groupsTimelineDateDictionary;
                self.groupsCountDataList = groupsCountDataList;
                self.selectedSliderValue = selectedSliderValue;
                self.states.quadGraphYearList = quadGraphYearList;
                self.states.benchMarkData = benchMarkData;

                RotationGraphMenuStore.groupResult = RotationGraphMenuStore.sortQuadData(quadGraphYearList);

                self.isGeographySelected = false;
                self.benchmarkDataLoaded = true;
                self.disableBenchMarkLoading();
                //self.emit(CHANGE_EVENT);
                initializeTimeLineWorkerObj.terminate();
                resolve();
            }
            initializeTimeLineWorkerObj.onerror = () => {
                self.isGeographySelected = false;
                self.benchmarkDataLoaded = true;
                self.disableBenchMarkLoading();
                //self.emit(CHANGE_EVENT);
                initializeTimeLineWorkerObj.terminate();
                resolve();
            }
        });
    }

    getBenchMarkResponseData() {
        return this.states.benchMarkData;
    }

    getGlobalTrendLineData() {
        return this.groupsCountDataList;
    }

    getgroupsTimelineDateDictionary() {
        return this.groupsTimelineDateDictionary;
    }

    getIsDataLoaded() {
        return _isDataLoaded;
    }

    setIsDataLoadedToNull() {
        _isDataLoaded = null;
        this.states.geographyDataList = [];
        this.states.groupsMainData = null;
        this.states.globalCountDataList = null;
        this.states.liqfilterdata = null;
    }

    getGroupsData(caller = '') {
        let self = this;
        let settings = SettingsStore.getConsoleSettings();

        this.tabRotationGraphSettings = settings.NavCompareSettings.TabRotationGraphSettings;
        this.initializeTimelineValues();
        this.fetchBechMarkGraphSettings();
        this.fetchZoomValuesFromSettings();

        if (StringUtil.isEmpty(this.states.geographyDataList) && StringUtil.isEmpty(this.states.groupsMainData) && StringUtil.isEmpty(this.states.globalCountDataList) && StringUtil.isEmpty(this.states.liqfilterdata)) {
            RotationGraphMenuStore.enableLoading();
            self.enableBenchMarkLoading();
            return new Promise((resolve) => {
                Promise.all([
                    self.getGeographyData(),
                    self.getLiqFilterData(),
                    self.getGroupsMainData(),
                    self.getGlobalTrendData()
                ]).then(() => {
                    if (caller === '')
                        return this.loadRotationMenu();
                    else
                        self.emit(CHANGE_EVENT);
                }).then((result) => {
                    _isDataLoaded = true;
                    if (result) {
                        self.checkItsLoaded(resolve);
                    } else {
                        resolve(true);
                    }
                }).catch(() => {
                    RotationGraphMenuStore.disableLoading();
                    self.disableBenchMarkLoading();
                    resolve(true);
                });
            });
        }
    }

    checkItsLoaded(resolve) {
        let self = this;
        if (!self.benchmarkDataLoaded) {
            self.timer = setInterval(() => {
                if (self.benchmarkDataLoaded) {
                    RotationGraphMenuStore.disableLoading();
                    self.emit(CHANGE_EVENT);
                    clearInterval(self.timer);
                    resolve(true);
                }
            }, 350);
        }
        else {
            if (self.timer) clearInterval(self.timer);
            RotationGraphMenuStore.disableLoading();
            self.emit(CHANGE_EVENT);
            resolve(true);
        }
    }

    loadRotationMenu() {
        return new Promise((resolve, reject) => {
            try {
                this.tabRotationGraphSettings = SettingsStore.getConsoleSettings().NavCompareSettings.TabRotationGraphSettings;
                let rotationMenuSettings = this.tabRotationGraphSettings.rotationSymbolsMenuSettings;
                let selectedStock = null;

                if (!StringUtil.isEmpty(this.tabRotationGraphSettings)) {
                    this.isGeographySelected = true;
                    RotationGraphMenuStore.setGeographyByCountryCode(rotationMenuSettings.countryCode);
                }

                RotationGraphMenuStore.breadCrumbItems = rotationMenuSettings.breadCrumbItems;

                if (!StringUtil.isEmpty(rotationMenuSettings.notVisibleOsids)) {
                    RotationGraphMenuStore.isNotVisibleOsids = rotationMenuSettings.notVisibleOsids.split(',');
                }
                if (!StringUtil.isEmpty(rotationMenuSettings.lastHighlightedOsids))
                    RotationGraphMenuStore.lastHighlightedOsids = rotationMenuSettings.lastHighlightedOsids.split(',').map(Number);

                if (RotationGraphMenuStore.breadCrumbItems.length > 1) {
                    RotationGraphMenuStore.selectedIndustry = _.find(RotationGraphMenuStore.breadCrumbItems, (item) => item.rotationMenuType == RotationMenuType.Industry)
                    RotationGraphMenuStore.selectedIndustry.displayName = RotationGraphMenuStore.selectedIndustry.displayName ? RotationGraphMenuStore.transIndustryName(RotationGraphMenuStore.selectedIndustry) : RotationGraphMenuStore.selectedIndustry.displayName;
                }

                if (RotationGraphMenuStore.breadCrumbItems.length > 2) {
                    selectedStock = _.find(RotationGraphMenuStore.breadCrumbItems, (item) => item.rotationMenuType == RotationMenuType.Stocks)
                    selectedStock.displayName = selectedStock.displayName ? RotationGraphMenuStore.transStockName(selectedStock) : selectedStock.displayName
                }

                if (!StringUtil.isEmpty(this.tabRotationGraphSettings.rotationSymbolsMenuSettings)) {

                    switch (rotationMenuSettings.rotationMenuType) {
                        case RotationMenuType.Sector:
                            try {
                                return RotationGraphMenuStore.addSectorListItems().then(() => {
                                    this.setScrollPosition();
                                    return resolve(true);
                                });
                            } catch (error) {
                                console.log(error);
                                return reject();
                            }
                        case RotationMenuType.Industry:
                            return RotationGraphMenuStore.createIndustryListItem(RotationGraphMenuStore.selectedIndustry).then(() => {
                                this.setScrollPosition();
                                return resolve(true);
                            });
                        case RotationMenuType.Stocks:
                            return RotationGraphMenuStore.createStockListItem(selectedStock).then(() => {
                                this.setScrollPosition();
                                return resolve(true);
                            });
                        default:
                            break;
                    }
                }
                resolve(true);
            } catch (error) {
                console.log(error);
                reject(error);
            }
        });
    }

    setScrollPosition() {
        let scrollIndex = 0;
        if (!StringUtil.isEmpty(this.states.listItems))
            scrollIndex = (this.states.listItems.length == RotationGraphMenuStore.lastHighlightedOsids.length) ? 0 : RotationGraphMenuStore.lastHighlightedOsids.length - 1;

        RotationGraphMenuStore.setScrollPosition(RotationGraphMenuStore.lastHighlightedOsids[scrollIndex], true);
    }

    enableBenchMarkLoading() {
        this.isBenchMarkLoading = true;
        this.currentAction = RotationGraphConstants.ActionTypes.BENCHMARK_LOADING;
        this.emit(CHANGE_EVENT);
    }

    disableBenchMarkLoading() {
        this.isBenchMarkLoading = false;
        this.currentAction = RotationGraphConstants.ActionTypes.BENCHMARK_LOADING;
        this.emit(CHANGE_EVENT);
    }

    getGlobalTrendData() {
        let globalTrendRequestData = {
            fromdate: DateHelper.yyyymmdd(moment.tz("US/Pacific").set({ 'year': 2011, 'month': 8, 'date': 30 }).toDate()),
            enddate: DateHelper.yyyymmdd(DateHelper.returnTimeZoneDate(new Date())),
        }
        this.getGlobalTrendCountData(globalTrendRequestData);
    }

    getBenchMarkData(benchMarkRequestData) {
        let self = this;
        if (StringUtil.isEmpty(self.states.benchMarkData)) {
            self.enableBenchMarkLoading();
            CompareApi.getBenchMarkData(benchMarkRequestData).then((result) => {
                if (result.responseHeader.error) {
                    // ConsoleActions.exceptionHandle(result.responseHeader);
                    self.disableBenchMarkLoading();
                }
                else {
                    this.currentAction = RotationGraphConstants.ActionTypes.GET_BENCHMARK_DATA;
                    self.states.benchMarkData = result;
                    self.emit(CHANGE_EVENT);
                }
            }).catch(() => { self.disableBenchMarkLoading(); });
        }
    }

    // Get and Prepare GeographyDataList
    getGeographyData() {
        return CompareApi.getGeographyData().then((result) => {
            if (result.responseHeader.error) {
                // ConsoleActions.exceptionHandle(result.responseHeader);
                throw 'Error in Rotation Graph.';
            }
            else {
                RotationGraphMenuStore.prepareGeoGraphyData(result);
                if (this.states.isRefresh) return true;
            }
        });
    }

    getGlobalTrendCountData(globalTrendRequestData) {
        let self = this;
        CompareApi.getGlobalTrendCountData(globalTrendRequestData).then((result) => {
            if (result.responseHeader.error) {
                //ConsoleActions.exceptionHandle(result.responseHeader);
                throw 'Error in Rotation Graph.';
            }
            else {
                self.states.globalCountDataList = result;
                self.emit(CHANGE_EVENT);
            }
        });
    }

    getGroupsMainData() {
        let self = this;
        return CompareApi.getGroupsMainData().then((result) => {
            if (result.responseHeader.error) {
                //ConsoleActions.exceptionHandle(result.responseHeader);
                throw 'Error in Rotation Graph.';
            }
            else {
                self.states.groupsMainData = result;
                this.currentAction = RotationGraphConstants.ActionTypes.GET_GROUPS_MAIN_DATA;
                this.emit(CHANGE_EVENT);
                return true;
            }
        });
    }

    getGroupsMainDataForSymbol() {
        let self = this;
        return CompareApi.getGroupsMainData().then((result) => {
            if (result.responseHeader.error) {
                //ConsoleActions.exceptionHandle(result.responseHeader);
                throw 'Error in Rotation Graph.';
            }
            else {
                self.states.groupsMainData = result;
                self.emit(CHANGE_EVENT);
                return true;
            }
        });
    }

    getLiqFilterData() {
        let self = this;
        CompareApi.getLiqFilterData().then((result) => {
            if (result.responseHeader.error) {
                //ConsoleActions.exceptionHandle(result.responseHeader);
                throw 'Error in Rotation Graph.';
            }
            else {
                self.states.liqfilterdata = result;
            }
        });
    }

    getCurrentAction() {
        return this.currentAction;
    }

    timeLineSlideronChange(data) {
        this.selectedSliderValue = data.selectedSliderValue;
        RotationGraphMenuStore.preparePointstoRender(this.selectedSliderValue);
        let navCompareSettings = CompareStore.getNavCompareSettings();
        navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings.timeLineIndex = this.selectedSliderValue;
        this.currentAction = RotationGraphConstants.ActionTypes.IS_PLAY;
        this.emit(CHANGE_EVENT);
    }

    refreshData() {
        let self = this;
        return new Promise((resolve) => {
            Promise.all([
                self.getGroupsMainData(), self.getGeographyData()
            ]).then(() => {
                resolve(true);
            });

        });
    }

    execRefreshCommand() {
        let self = this;
        BrowserUtil.disableEvents();
        this.states.isRefresh = true;
        this.currentAction = RotationGraphConstants.ActionTypes.IS_REFRESH;
        this.refreshData().then(() => {
            RotationGraphMenuStore.preparePointstoRender(self.selectedSliderValue);
            self.states.isRefresh = false;
            BrowserUtil.enableEvents();
            self.emit(CHANGE_EVENT);
        });
    }

    autoFitToggle_OnClick(value = "100%") {
        if (value == "AUTO") {
            this.states.autoFitToggleIsChecked = true;
        }
        else {
            this.states.autoFitToggleIsChecked = false;
        }
    }

    saveRoatationGraphData(zoomValue, matrix, reset = false) {
        this.states.zoomPercentageValue = zoomValue;
        this.states.zoomMatrix = matrix;
        if (zoomValue == "AUTO") {
            this.states.autoFitToggleIsChecked = true;
        }
        else {
            this.states.autoFitToggleIsChecked = false;
        }
        CompareSettingHelper.updateRotationGraphZoomValues(this.tabRotationGraphSettings.rotationSymbolsMenuSettings, this.states.zoomPercentageValue, this.states.zoomMatrix, this.states.autoFitToggleIsChecked);
        if (!reset) SettingsStore.saveSettings();
    }

    resetZoomValue() {
        let matrix = "matrix(" + 1 + "," + 0 + "," + 0 + "," + 1 + "," + 0 + "," + 0 + ")";
        let zoomValue = "AUTO"
        if (this.states.zoomPercentageValue != "AUTO") {
            zoomValue = "100%";
        }
        this.saveRoatationGraphData(zoomValue, matrix, true);
        //Log Usage
        ConsoleStore.trackUsage('RotationGraphZoomed');
    }

    fetchZoomValuesFromSettings() {
        if (StringUtil.isEmpty(SettingsStore.getConsoleSettings())) return;

        let rotationGraphZoomValues = this.tabRotationGraphSettings.rotationSymbolsMenuSettings.rotationGraphZoomValues;
        this.states.zoomPercentageValue = rotationGraphZoomValues.zoomValue;
        this.states.zoomMatrix = rotationGraphZoomValues.matrix;
        this.states.autoFitToggleIsChecked = rotationGraphZoomValues.isAutoChecked;
    }


    dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.data;
        switch (action.actionType) {
            case RotationGraphConstants.ActionTypes.GET_BENCHMARK_DATA:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_BENCHMARK_DATA;
                this.getBenchMarkData(data.benchMarkRequestData);
                break;
            case RotationGraphConstants.ActionTypes.GET_GEOGRAPHY_DATA:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_GEOGRAPHY_DATA;
                this.getGeographyData();
                break;
            case RotationGraphConstants.ActionTypes.GET_GLOBAL_TREND_COUNT_DATA:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_GLOBAL_TREND_COUNT_DATA;
                this.getGlobalTrendCountData(data.globalTrendRequestData);
                break;
            case RotationGraphConstants.ActionTypes.GET_GROUPS_MAIN_DATA:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_GROUPS_MAIN_DATA;
                this.getGroupsMainDataForSymbol();
                break;
            case RotationGraphConstants.ActionTypes.GET_LIQ_FILTER_DATA:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_LIQ_FILTER_DATA;
                this.getLiqFilterData();
                break;
            case RotationGraphConstants.ActionTypes.IS_PLAY:
                this.currentAction = RotationGraphConstants.ActionTypes.IS_PLAY;
                this.timeLineSlideronChange(data);
                break;
            case RotationGraphConstants.ActionTypes.GET_GROUPS_DATA:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_GROUPS_MAIN_DATA;
                this.getGroupsData('');
                break;
            default:
                return;
        }
    }
}
const rotationGraphStore = new RotationGraphStore();
export default rotationGraphStore;
