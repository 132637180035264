import keyMirror from "keymirror";

export const SummaryBlockConstants = {
    ActionTypes: keyMirror({
        SET_SUMMARY_BLOCK_MSG: null,
        SET_SNAPSHOT_DATA_READY: null,
        SET_SUMMARY_BLOCK_SETTINGS: null,
        SET_RELATIVE_YTD: null,
        SHOW_MORE_SUMMARY: null,
        OPEN_COLLAPSE_TRIGGERED: null,
        FETCH_SUMMARY_BLOCK_DATA: null,
        UPDATE_SYMBOL_DATA: null,
        CLOSE_SUMMARY_BLOCK: null
    })
}