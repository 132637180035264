import { changeDimensions } from "../../../../../Actions/RelatedInfoPanelActions/RiPanelActions";
import { connect } from "react-redux";
import LabelText from '../../../NavDataGraph/TabDataGraph/TimeLine/LabelText';
import Measure from "react-measure";
import PortalContainer from "PortalContainer";
import ScrollBar from "ScrollBar";
import ThemeHelper from "ThemeHelper";
import React, { Component } from "react";

const cellWidth = require("text-width");

class SectorWeightingGraph extends Component {
    constructor(props) {
        super(props);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.clearToolTip = this.clearToolTip.bind(this);
        this.legendHeight = 20;
        this.legendMargin = { left: 5 };
        this.lineHeight = 16;
        this.fontSize = 11;
    }

    componentDidMount(){
        this.setState({})
    }

    drawGridLines() {
        if (!this.gridCanvas || this.props.chartHeight === 0.0 || this.props.chartWidth === 0.0) {
            return;
        }
        const rect = this.gridCanvas.getBoundingClientRect();
        const ctx = this.gridCanvas.getContext("2d");
        ctx.canvas.height = rect.height;
        ctx.canvas.width = rect.width;
        ctx.clearRect(0, 0, rect.width, this.props.chartHeight);
        ctx.imageSmoothingEnabled = true;
        ctx.save();
        this.drawVerticalLine(ctx);
    }

    drawVerticalLine(ctx) {
        const lineColor = ThemeHelper.getThemedBrush("sectorWeightingGraphColor");
        if (!this.props.scale) {
            return;
        }
        const mhLabel = this.props.scale.mhLabel;
        if (!mhLabel || mhLabel.length === 0) {
            return;
        }
        ctx.strokeStyle = lineColor;
        ctx.translate(0.5, 0.5);
        ctx.lineWidth = 1;
        const height = this.getMaxyAxis(35);
        ctx.beginPath();
        const max = mhLabel.length;
        for (let i = 0; i < max; i++) {
            const label = mhLabel[i];
            if (label.Type === 0) {
                continue;
            }
            ctx.moveTo(Math.round(label.xAxis + this.props.legendWidth), 0);
            ctx.lineTo(Math.round(label.xAxis + this.props.legendWidth), height);
        }
        ctx.stroke();
        ctx.closePath();
    }

    drawLegend() {
        if (!this.legendCanvas || !this.props.sectorWgtList) {
            return;
        }
        const fontColor = ThemeHelper.getThemedBrush("allocationFontColor");
        const rect = this.legendCanvas.getBoundingClientRect();
        const ctx = this.legendCanvas.getContext("2d");
        ctx.canvas.height = rect.height;
        ctx.canvas.width = rect.width;
        ctx.clearRect(0, 0, rect.width, rect.height);
        ctx.font = "11pt Calibri";
        ctx.fillStyle = fontColor;
        const length = this.props.sectorWgtList.length;
        for (let i = 0; i < length; i++) {
            const barItem = this.props.sectorWgtList[i];
            const yValue = this.getYAxis(i, 30) + ((this.props.barWidth - this.fontSize) / 2) + this.fontSize;
            SectorWeightingGraph.canvasWordWrap(ctx, barItem.legend, this.legendMargin.left, yValue, this.lineHeight, this.props.legendWidth, this.fontSize);
        }
        ctx.stroke();
        ctx.closePath();
    }


    drawSVGLegend() {
        if (!this.legendCanvas || !this.props.sectorWgtList) {
            return (<div></div>);
        }
        const textColor = ThemeHelper.getThemedBrush("allocationFontColor");
        return (
            <svg className="svg-scale sector-weighting">
                {this.props.sectorWgtList && this.props.sectorWgtList.map((itm, i) =>
                    <LabelText
                        key={i}
                        textAnchor="begin"
                        isHighlighted={true}
                        textValue={itm.legend}
                        dx="5"
                        dy="0"
                        style={{
                            font: 'calibri',
                            fontSize: '11pt',
                            fill: textColor
                        }}
                        textPosX={this.legendMargin.left}
                        textPosY={this.getYAxis(i, 30) + ((this.props.barWidth - this.fontSize) / 2) + this.fontSize}
                        multiLine={this.props.legendWidth} />)}

            </svg>
        );
    }

    drawSVGScaleLabels() {
        const textColor = ThemeHelper.getThemedBrush("allocationFontColor");
        return (
            <svg className="svg-scale sector-weighting">
                {this.props.scale.mhLabel && this.props.scale.mhLabel.map((itm, i) => itm.eLabel !== " " &&
                    <LabelText
                        key={i}
                        textAnchor="middle"
                        isHighlighted={true}
                        textValue={itm.hLabel}
                        dx="0"
                        dy="0"
                        style={{
                            font: 'calibri',
                            fontSize: '11pt',
                            fill: textColor,
                            zIndex: '10'
                        }}
                        textPosX={itm.xAxis + this.props.legendWidth}
                        textPosY={(this.props.topScaleHeight - this.fontSize) / 2 + this.fontSize} />)}

            </svg>
        );
    }

    drawScale() {
        if (!this.topScale || !this.props.scale) {
            return;
        }
        const fontColor = ThemeHelper.getThemedBrush("allocationFontColor");
        const strokeColor = ThemeHelper.getThemedBrush("sectorWeightingGraphColor");
        const topRect = this.topScale.getBoundingClientRect();
        const topCtx = this.topScale.getContext("2d");
        topCtx.canvas.height = topRect.height;
        topCtx.canvas.width = topRect.width;
        topCtx.clearRect(0, 0, topRect.width, topRect.height);
        topCtx.font = "11pt Calibri";
        topCtx.translate(0.5, 0.5);
        topCtx.fillStyle = fontColor;
        topCtx.strokeStyle = strokeColor;
        const mhLabel = this.props.scale.mhLabel;
        const max = mhLabel.length;
        for (let i = 0; i < max; i++) {
            const scaleItem = mhLabel[i];
            if (!(scaleItem.eLabel === " ")) {
                const widthText = cellWidth(scaleItem.hLabel, {
                    family: "calibri",
                    size: "11pt"
                });
                this.props.isSymbolFund ? topCtx.fillText(scaleItem.hLabel, scaleItem.xAxis + this.props.legendWidth - widthText / 2, (this.props.topScaleHeight - this.fontSize) / 2 + this.fontSize)
                    : topCtx.fillText(scaleItem.hLabel, scaleItem.xAxis + this.props.legendWidth - this.lineHeight / 2, (this.props.topScaleHeight - this.fontSize) / 2 + this.fontSize);
            }
        }
        topCtx.moveTo(0, this.props.topScaleHeight);
        topCtx.lineTo(this.props.chartWidth, this.props.topScaleHeight);
        topCtx.stroke();
        topCtx.closePath();
    }

    drawBar() {
        const source = this.props.sectorWgtList;
        if (!source ||
            this.props.chartHeight === 0.0 ||
            this.props.chartWidth === 0.0 || !this.mainCanvas) {
            return;
        }
        const rect = this.mainCanvas.getBoundingClientRect();
        const ctx = this.mainCanvas.getContext("2d");
        const strokeColor = ThemeHelper.getThemedBrush("sectorWeightingGraphBarColor");
        ctx.canvas.height = rect.height;
        ctx.canvas.width = rect.width;
        ctx.clearRect(0, 0, rect.width, rect.height);
        ctx.lineWidth = this.props.barWidth;
        ctx.imageSmoothingEnabled = true;
        ctx.fillStyle = strokeColor;
        ctx.beginPath();
        const max = source.length;
        for (let i = 0; i < max; i++) {
            const barItem = source[i];
            const yAxis = this.getYAxis(i, 30);
            const x1 = this.props.scale.computeX(barItem.lowValue) + this.props.legendWidth;
            const x2 = this.props.scale.computeX(barItem.highValue) + this.props.legendWidth;
            let barHeight = 0.0;
            if ((x2 - x1) > 0) {
                barHeight = (x2 - x1);
            }
            ctx.fillRect(x1, yAxis, barHeight, this.props.barWidth);
        }
        ctx.stroke();
        ctx.closePath();
    }

    getMaxyAxis(marginTop) {
        const source = this.props.sectorWgtList;
        if (!source) {
            return;
        }
        const max = source.length;
        return ((max * this.props.barMargin.left) + (max * this.props.barWidth) + (max * this.props.barMargin.right)) + marginTop;
    }

    getYAxis(index, marginTop) {
        return ((index * this.props.barMargin.left) + (index * this.props.barWidth) + (index * this.props.barMargin.right)) + marginTop;
    }

    static canvasWordWrap(context, text, x, y, lineHeight, fitWidth, fontSize) {
        fitWidth = fitWidth || 0;

        if (fitWidth <= 0) {
            context.fillText(text, x, y);
            return;
        }
        let words = text.split(' ');
        let currentLine = 0;
        let idx = 1;
        while (words.length > 0 && idx <= words.length) {
            const str = words.slice(0, idx).join(' ');
            const w = context.measureText(str).width;
            if (w > fitWidth) {
                if (idx === 1) {
                    idx = 2;
                }
                context.fillText(words.slice(0, idx - 1).join(' '), x, y + (lineHeight * currentLine) - fontSize);
                currentLine++;
                words = words.splice(idx - 1);
                idx = 1;
            }
            else {
                idx++;
            }
        }
        if (idx > 0) {
            if (currentLine === 0) {
                context.fillText(words.join(' '), x, y + (lineHeight * currentLine));
            } else {
                context.fillText(words.join(' '), x, y + (lineHeight * currentLine) - fontSize);
            }
        }
    }

    handleMouseMove(e) {
        const rect = this.mainCanvas.getBoundingClientRect();
        const offsetX = rect.left;
        const offsetY = rect.top;
        const mouseX = parseInt(e.clientX - offsetX);
        const mouseY = parseInt(e.clientY - offsetY);
        const source = this.props.sectorWgtList;
        let hit = false;
        const max = source.length;
        const widthOfToolTip = 60;
        for (let i = 0; i < max; i++) {
            const barItem = source[i];
            const y1 = this.getYAxis(i, 30);
            const y2 = y1 + this.props.barWidth;
            const x1 = this.props.scale.computeX(barItem.lowValue) + this.props.legendWidth;
            const x2 = this.props.scale.computeX(barItem.highValue) + this.props.legendWidth;
            if (x1 <= mouseX && mouseX <= x2 && y1 <= mouseY && mouseY <= y2) {
                const leftPos = e.clientX + widthOfToolTip >= window.innerWidth ? e.clientX - ((e.clientX + widthOfToolTip) - window.innerWidth) : e.clientX;
                this.toolTip.style.left = `${leftPos}px`;
                this.toolTip.style.top = `${e.clientY + 15}px`;
                this.toolTip.style.display = "block";
                this.toolTip.innerText = `${barItem.highValue.toFixed(1)}%`;
                hit = true;
                return;
            }
        }
        if (!hit) {
            this.clearToolTip();
        }
    }

    clearToolTip() {
        this.toolTip.style.left = "-200px";
        this.toolTip.style.display = "none";
        this.toolTip.innerText = "";
    }

    setDimensions(dim) {
        this.props.changeDimensions(dim);
    }

    getStyles() {
        if (this.props.isSymbolFund) {
            return { top: this.props.chartHeight - 30, width: this.props.chartWidth - this.props.paddingScrollBarWidth }
        }
        else { return { top: this.props.chartHeight - 30, width: this.props.chartWidth } }
    }

    render() {
        try {
            if (this.props.isSymbolFund) {
                if (this.props.useCanvas) {
                    this.drawLegend();
                }
                this.drawScale();
                this.drawGridLines();
                this.drawBar();
            }
            else {
                this.drawLegend();
                this.drawScale();
                this.drawGridLines();
                this.drawBar();
            }
        }
        catch (e) {
            console.error(e);
        }
        return (
            <div ref={(ref) => { this.SectorWeightingGraphContainer = ref }} className="sectorWeightingGraphContainer">
                <div
                    style={{ height: this.props.topScaleHeight, width: this.props.chartWidth, pointerEvents: "all", position: "relative" }}>
                    <canvas ref={(ref) => { this.topScale = ref }} className="canvasStyle"
                        style={{ height: this.props.topScaleHeight + 5, width: this.props.chartWidth }}>
                    </canvas>
                    {this.props.isSymbolFund && !this.props.useCanvas && this.drawSVGScaleLabels()}
                </div>
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.setDimensions(contentRect.bounds)
                    }}
                >
                    {({ measureRef }) =>
                        <div ref={measureRef} className="barArea virtualList showscrollOnHover">
                            <div className="custom-scroll">
                                <div id={'symbol-SectorWeightingGraph-VerticalScroll'}>
                                    <div className="scrollPanel">
                                        <div className="barPanel"
                                            style={{ height: this.props.chartHeight, width: this.props.chartWidth }}>
                                            {this.props.isSymbolFund && !this.props.useCanvas && this.drawSVGLegend()}
                                            <canvas className="canvasStyle" ref={(ref) => { this.gridCanvas = ref }}>
                                            </canvas>
                                            <canvas ref={(ref) => { this.legendCanvas = ref }} className="canvasStyle">
                                            </canvas>
                                            <canvas className="barVisual canvasStyle"
                                                onMouseMove={(e) => this.handleMouseMove(e)}
                                                onMouseOut={this.clearToolTip}
                                                onBlur={this.clearToolTip}
                                                ref={(ref) => { this.mainCanvas = ref }}>
                                            </canvas>
                                        </div>
                                        <div className="asOfData medium-normal"
                                            style={this.getStyles()}>
                                            <span>{this.props.asOfDate}</span>
                                        </div>
                                        <PortalContainer>
                                            <div className="tip" ref={(ref) => { this.toolTip = ref }} />
                                        </PortalContainer>
                                    </div>
                                </div>
                                <ScrollBar scrollId={'symbol-SectorWeightingGraph-VerticalScroll'} vScroll={true}
                                    scrollOnHover={true} />
                            </div>
                        </div>
                    }
                </Measure>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { isSymbolFund, paddingScrollBarWidth, sectorWgtList, scale, barWidth, topScaleHeight, chartHeight, chartWidth, barMargin, legendWidth, asOfDate, symbolType } = state.RelatedInfoPanelReducers.RiPanelAllocationInfo;
    const { useCanvas } = state.DatagraphReducers.DataGraphReducer;
    return { useCanvas, isSymbolFund, paddingScrollBarWidth, sectorWgtList, scale, barWidth, topScaleHeight, chartHeight, chartWidth, barMargin, legendWidth, asOfDate, symbolType }
}
const mapDispatchToProps = (dispatch) => ({
    changeDimensions: (dim) => dispatch(changeDimensions(dim))
})

export default connect(mapStateToProps, mapDispatchToProps)(SectorWeightingGraph)
