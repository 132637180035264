import ChartVisual from "./ChartVisual.jsx";
import { connect } from "react-redux";
import ExtremeDataValue from "ExtremeDataValue";
import React from "react";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import UserInfoUtil from "UserInfoUtil";
import { PatterNameTranslateHelper, PatternRangeTranslateHelper } from "../../../Utils/TranslateHelper.js";

class PatternRecognition extends ChartVisual {
    constructor(props) {
        super(props);
        this.line = [];
        this.infoZindex = 1000;
        this.boxInfo = 1;
        this.state = {
            showBox: false,
            showText: "",
            screenX: 0,
            screenY: 0,
            Title: [],
            Info1: "",
            Info2: "",
            Info3: "",
            Info4: "",
            Info5: "",
        };
    }
    componentDidMount() {
        this.infoZindex = 1000;
        this.boxInfo = 1;
    }
    UNSAFE_componentWillReceiveProps(prevProps) {
        // Check if patternsData prop has changed and if yes then hide the tooltip by changing showBox state
        if (prevProps.patternsData !== this.props.patternsData) {
            this.setState({ showBox: false });
        }
    }
    
    ellipsepath(start_x, start_y, r_x, r_y) {
        return `M ${start_x - r_x} ${start_y} a ${r_x},${r_y} 0, 1, 1, 0, 0.1 z `;
    }
    circlepath(cx, cy, r) {
        return `M ${cx} ${cy} m -${r}, 0 a ${r},${r} 0 1,0 ${r * 2},0 a ${r},${r} 0 1,0 -${r * 2},0 `;
    }
    cupPath(stX, stY, btX, btY, enX, enY) {
        if (isNaN(stY) || isNaN(btY) || isNaN(enY)) {
            return " ";
        }
        let d = `M ${stX} ${stY} C ${stX} ${stY - ((stY - btY) / 3)}, ${stX + ((btX - stX) / 2)} ${btY}, ${btX} ${btY}`;
        d += ` C ${btX + ((enX - btX) / 2)} ${btY}, ${enX} ${enY - ((enY - btY) / 3)}, ${enX} ${enY}`;
        return d;
    }
    rectanglePath(x, y, height, width) {
        return `M ${x} ${y} v ${height} h ${width} v ${-height} z`
    }
    trianglePath(x, y, x1, y1) {
        if (y > y1) {
            return `M ${x} ${y} L ${x1} ${y} L ${x1} ${y1} L ${x} ${y} z`;
        }
        return `M ${x} ${y} L ${x} ${y1} L ${x1} ${y1} L ${x} ${y} z`;
    }
    flagPath(x, y, down, width = 25, height = 40) {
        return `M ${x} ${y} v ${down ? height : -height} h ${-width} v 12 h ${width} z`;
    }
    powerFromPivot(flagNode) {
        return this.flagPath(flagNode.xAxis, flagNode.yAxis, flagNode.Down);
    }
    climaxTop(flagNode) {
        return this.flagPath(flagNode.xAxis, flagNode.yAxis, flagNode.Down, 12, 50);
    }
    powerFromPivotText(flagNode, x) {
        if (flagNode.priceChange > 99000) { flagNode.priceChange = 99000; }
        const dispVal = flagNode.priceChange > 999
            ? ExtremeDataValue.abbreviatePivotValue(flagNode.priceChange)
            : flagNode.priceChange.toFixed(0);
        const pctText = `${dispVal}%`;
        const color = ThemeHelper.getFontColor("#00FF00");
        if (flagNode.xAxis > 0) {
            return (
                <text data-disable-track-price="true"
                    style={{ fill: color }}
                    key={`PFP${flagNode.xAxis}${x}`}
                    fontFamily='calibri'
                    fontSize='10px'
                    textAnchor='end'
                    opacity='1'
                    x={flagNode.xAxis - 2}
                    y={flagNode.yAxis - (flagNode.Down ? -50 : 30)}>{pctText}
                </text>
            );
        }
    }
    climaxTopText(node) {
        const color = ThemeHelper.getFontColor("#FFFF00");
        if (node.flagNode.xAxis > 0) {
            return (
                <text data-disable-track-price="true"
                    style={{ fill: color }}
                    key={`CT${node.flagNode.xAxis}`}
                    fontFamily='calibri'
                    fontSize='10px'
                    textAnchor='end'
                    opacity='1'
                    x={node.flagNode.xAxis - 2}
                    y={node.flagNode.yAxis - (node.flagNode.Down ? -60 : 40)}>{node.numChecksPassed}
                </text>
            );
        }
    }
    prepareTightPatterns(patternsData) {
        const d = [];
        if (patternsData.TightAreaPatternNode) {
            const lng = patternsData.TightAreaPatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.TightAreaPatternNode[i];
                if (tData) {
                    d.push(this.ellipsepath((tData.startIn + tData.endIn) / 2,
                        (tData.minY + tData.maxY) / 2,
                        Math.abs(tData.endIn - tData.startIn),
                        Math.abs(tData.minY - tData.maxY)));
                }
            }
        }
        return d;
    }
    linePath(x, y, x1, y1) {
        if (isNaN(y) || isNaN(y1)) {
            return "";
        } else {
            return `M ${x} ${y.toFixed(0)} L ${x1} ${y1.toFixed(0)} `;
        }
    }
    prepareFlatPatternsSolid(patternsData) {
        const d = [];
        if (patternsData.FlatPatternNode) {
            const lng = patternsData.FlatPatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.FlatPatternNode[i];
                if (tData && tData.startIn) {
                    d.push(this.linePath(tData.startIn, tData.minY, tData.endIn, tData.minY));
                }
            }
        }
        return d;
    }
    prepareFlatPatternsDotted(patternsData) {
        let d = '';
        if (patternsData.FlatPatternNode) {
            const lng = patternsData.FlatPatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.FlatPatternNode[i];
                if (tData && tData.startIn) {
                    d += this.linePath(tData.startIn, tData.pivotPriceY, tData.endIn, tData.pivotPriceY);
                }
            }
        }
        return d;
    }
    prepareIPOBasePatternSolid(patternsData) {
        const d = [];
        if (patternsData.IPOBasePatternNode) {
            const lng = patternsData.IPOBasePatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.IPOBasePatternNode[i];
                if (tData && tData.startIn) {
                    d.push(this.linePath(tData.startIn, tData.minY, tData.endIn, tData.minY));
                }
            }
        }
        return d;
    }
    prepareIPOBasePatternDotted(patternsData) {
        let d = '';
        if (patternsData.IPOBasePatternNode) {
            const lng = patternsData.IPOBasePatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.IPOBasePatternNode[i];
                if (tData && tData.startIn) {
                    d += this.linePath(tData.startIn, tData.pivotPriceY, tData.endIn, tData.pivotPriceY);
                }
            }
        }
        return d;
    }
    prepareChannelLines(patternsData) {
        const d = [];
        if (patternsData.ChannelLinesNode) {
            const lng = patternsData.ChannelLinesNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.ChannelLinesNode[i];
                if (tData && tData.firstPeakDtX) {
                    d.push(this.linePath(tData.firstPeakDtX, tData.firstPeakDtY, tData.endDateX, tData.endDateY));
                }
            }
        }
        return d;
    }

    prepareConsolidationPatternD6(patternsData) {
        const d = [];
        if (patternsData.ConsolidationPatternNode) {
            const lng = patternsData.ConsolidationPatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.ConsolidationPatternNode[i];
                if (tData && tData.startIn) {
                    d.push(this.linePath(tData.startIn, tData.minY, tData.endIn, tData.minY));
                }
            }
        }
        return d;
    }
    prepareConsolidationPatternD2(patternsData) {
        let d = '';
        if (patternsData.ConsolidationPatternNode) {
            const lng = patternsData.ConsolidationPatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.ConsolidationPatternNode[i];
                if (tData && tData.startIn) {
                    d += this.linePath(tData.startIn, tData.pivotPriceY, tData.endIn, tData.pivotPriceY);
                }
            }
        }
        return d;
    }
    prepareCupPattern(patternsData) {
        const cd = [];
        if (patternsData.CupPatternNode) {
            const lng = patternsData.CupPatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.CupPatternNode[i];
                if (tData && tData.startIn) {
                    let d = '';
                    d += this.cupPath(tData.startIn, tData.startY,
                        tData.bottomIn, tData.bottomY,
                        tData.cupEndIn, tData.cupEndY);
                    if (tData.handleBottomIn) {
                        d += this.linePath(tData.cupEndIn, tData.cupEndY, tData.handleBottomIn, tData.handleBottomY);
                    }
                    cd.push(d);
                }
            }
        }
        return cd;
    }
    prepareDoubleBottom(patternsData) {
        const cd = [];
        if (patternsData.DoubleBottomsNode) {
            const lng = patternsData.DoubleBottomsNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.DoubleBottomsNode[i];
                if (tData && tData.startIn) {
                    let d = '';
                    d += this.linePath(tData.startIn, tData.startY, tData.firstBottomIn, tData.firstBottomY);
                    d += this.linePath(tData.firstBottomIn, tData.firstBottomY, tData.middlePeakIn, tData.middlePeakY);
                    d += this.linePath(tData.middlePeakIn, tData.middlePeakY, tData.secondBottomIn, tData.secondBottomY);
                    d += this.linePath(tData.secondBottomIn, tData.secondBottomY, tData.endIn, tData.endY);
                    cd.push(d);
                }
            }
        }
        return cd;
    }
    prepareAscendingBase(patternsData) {
        const cd = [];
        if (patternsData.AscendingBasePatternNode) {
            const lng = patternsData.AscendingBasePatternNode.length;
            for (let i = 0; i < lng; i++) {
                const tData = patternsData.AscendingBasePatternNode[i];
                if (tData && tData.startIn) {
                    let d = '';
                    d += this.cupPath(tData.startIn, tData.startY,
                        tData.firstBottomIn, tData.firstBottomY,
                        tData.secondAscendingHighIn, tData.secondAscendingHighY);
                    d += this.cupPath(tData.secondAscendingHighIn, tData.secondAscendingHighY,
                        tData.secondBottomIn, tData.secondBottomY,
                        tData.thirdAscendingHighIn, tData.thirdAscendingHighY);
                    d += this.cupPath(tData.thirdAscendingHighIn, tData.thirdAscendingHighY,
                        tData.thirdBottomIn, tData.thirdBottomY,
                        tData.endIn, tData.endY);
                    cd.push(d);
                }
            }
        }
        return cd;
    }
    handleMouseLeave() {
        this.setState({ showBox: false });
    }
    isRangeType(text) {
        return Object.values(PatternRangeTranslateHelper).includes(text);
    }
    handleMouseOver(datasource, e) {
        if (datasource) {
            const data = datasource;
            data.zIndex = this.infoZindex;
            this.infoZindex++;
            if (data.stick) { return; }
            this.state.showText = this.translatePatternName(data);
            if (data.Title) {
                const lng = data.Title.length;
                this.state.Title.length = lng;
                for (let x = 0; x < lng; x++) {
                    this.state.Title[x] = data.Title[x];
                }
            }
            this.state.screenX = e.clientX;
            this.state.screenY = e.clientY;
            data.screenX = this.state.screenX;
            data.screenY = this.state.screenY;
            this.state.datasource = datasource;
            data.timestamp = Date.now();
            if (this.props.showToolTip) {
                this.setState({ showBox: true });
            }
        }
    }
    handleMouseDown(datasource, e) {
        if (datasource) {
            const data = datasource;
            if (this.isRangeType(data.PatternName)) { return; }
            data.stick = data.stick ? false : true;
            data.timestamp = Date.now();
            this.state.showText = this.translatePatternName(data);
            if (data.Title) {
                const lng = data.Title.length;
                this.state.Title.length = lng;
                for (let x = 0; x < lng; x++) {
                    this.state.Title[x] = data.Title[x];
                }
            }
            this.state.screenX = e.clientX;
            this.state.screenY = e.clientY;
            data.screenX = this.state.screenX;
            data.screenY = this.state.screenY;
            this.state.datasource = datasource;
            this.setState({ showBox: data.stick });
        }
    }
    getPath(pathData, datasource, key, keyval, dashValue = 0, fill = "none", stroke = "", opacity = "0.5", pointerEvents = "visible", strokeWidth = "2px", cursor = "pointer") {
        if (this.props.videoMode) {
            strokeWidth = "6px";
        }
        if (pointerEvents === "none") { cursor = "default"; }
        const keyValue = key + keyval;
        return (
            <path d={pathData} data-disable-track-price="true"
                ref={(ref) => (this.line[keyValue] = ref)}
                fill={fill}
                key={keyValue}
                stroke={stroke}
                strokeDasharray={dashValue}
                strokeWidth={strokeWidth}
                cursor={cursor}
                opacity={opacity}
                onFocus={(e) => this.handleMouseOver(datasource, e)}
                onMouseDown={(e) => this.handleMouseDown(datasource, e)}
                onMouseOver={(e) => this.handleMouseOver(datasource, e)}
                onMouseLeave={(e) => this.handleMouseLeave(datasource, e)}
                pointerEvents={pointerEvents}
            />
        );
    }
    outputPoints(points, brush) {
        const d = [];
        const lng = points.length;
        for (let x = 0; x < lng; x++) {
            d.push(this.getPath(points[x], this.props.patternsData.TightAreaPatternNode[x], 'points', x, 0, 'transparent', brush));
        }
        return d;
    }
    outputFlatPatterns(flatLine, brush) {
        const d = [];
        const lng = flatLine.length;
        for (let x = 0; x < lng; x++) {
            d.push(this.getPath(flatLine[x], this.props.patternsData.FlatPatternNode[x], 'flat', x, 0, 'transparent', brush));
        }
        return d;
    }
    outputChannelLines(channelLines, brush) {
        const d = [];
        const lng = channelLines.length;
        for (let x = 0; x < lng; x++) {
            d.push(this.getPath(channelLines[x], this.props.patternsData.ChannelLinesNode[x], 'channel', x, 0, 'green', brush));
        }
        return d;
    }
    outputConsalidationPatterns(ConsolidationD6, brush) {
        const d = [];
        const lng = ConsolidationD6.length;
        for (let x = 0; x < lng; x++) {
            d.push(this.getPath(ConsolidationD6[x], this.props.patternsData.ConsolidationPatternNode[x], 'cons', x, 6, 'transparent', brush));
        }
        return d;
    }
    outputCupPatterns(cupData, brush) {
        const d = [];
        const lng = cupData.length;
        for (let x = 0; x < lng; x++) {
            d.push(this.getPath(cupData[x], this.props.patternsData.CupPatternNode[x], 'cup', x, 0, 'none', brush, "0.5", "stroke"));
        }
        return d;
    }
    outputDoubleBottomPatterns(doubleBottom, brush) {
        const d = [];
        const lng = doubleBottom.length;
        for (let x = 0; x < lng; x++) {
            d.push(this.getPath(doubleBottom[x], this.props.patternsData.DoubleBottomsNode[x], 'double', x, 0, 'transparent', brush));
        }
        return d;
    }
    outputAscendingBasePatterns(ascendingBase, brush) {
        const d = [];
        const lng = ascendingBase.length;
        for (let x = 0; x < lng; x++) {
            d.push(this.getPath(ascendingBase[x], this.props.patternsData.AscendingBasePatternNode[x], 'ascend', x, 0, 'transparent', brush));
        }
        return d;
    }
    outputIPOBasePatterns(ipoLine, brush) {
        const d = [];
        const lng = ipoLine.length;
        for (let x = 0; x < lng; x++) {
            d.push(this.getPath(ipoLine[x], this.props.patternsData.IPOBasePatternNode[x], 'ipo', x, 0, 'transparent', brush));
        }
        return d;
    }
    outputPatternInfo(patternInfo, d) {
        const lng = patternInfo.length;
        const hist = patternInfo;
        for (let x = 0; x < lng; x++) {
            const histRecord = hist[x];
            if (histRecord.stick) {
                const showText = this.translatePatternName(histRecord);
                d.push(this.showInfoBox(showText, histRecord.Title, histRecord.screenX, histRecord.screenY, histRecord));
            }
        }
    }
    outputLossArea(loss) {
        if (loss !== '') {
            const negativeColor = UserInfoUtil.hasChinaEntitlement() ? "rgba(8,152,0,0.3)" : "rgba(255, 182, 193, 0.3)";
            return this.getPath(loss,
                { PatternName: PatternRangeTranslateHelper.LOSS_RANGE, Title: [] },
                'loss',
                600,
                0,
                negativeColor,
                'transparent',
                "1",
                "stroke", "2px", "default");
        }
    }
    outputPivotArea(pivot) {
        if (pivot !== '') {
            return this.getPath(pivot,
                { PatternName: PatternRangeTranslateHelper.PIVOT_RANGE, Title: [] },
                'pivot',
                500,
                0,
                'rgba(135, 206, 250, 0.3)',
                'transparent',
                "1",
                "stroke", "2px", "default");
        }
    }
    outputProfitArea(profit) {
        if (profit !== '') {
            const positiveColor = UserInfoUtil.hasChinaEntitlement() ? "rgba(204,1,0,0.3)" : "rgba(144, 238, 144, 0.3)";
            return this.getPath(profit,
                { PatternName: PatternRangeTranslateHelper.PROFIT_RANGE, Title: [] },
                'profit',
                400,
                0,
                positiveColor,
                'transparent',
                "1",
                "stroke", "2px", "default");
        }
    }
    outputAllInfoBox() {
        const d = [];
        if (this.props.patternsData.TightAreaPatternNode !== undefined &&
            this.props.patternsData.TightAreaPatternNode.length > 0) {
            this.outputPatternInfo(this.props.patternsData.TightAreaPatternNode, d)
        }
        if (this.props.patternsData.FlatPatternNode !== undefined &&
            this.props.patternsData.FlatPatternNode.length > 0) {
            this.outputPatternInfo(this.props.patternsData.FlatPatternNode, d)
        }
        if (this.props.patternsData.ConsolidationPatternNode !== undefined &&
            this.props.patternsData.ConsolidationPatternNode.length > 0) {
            this.outputPatternInfo(this.props.patternsData.ConsolidationPatternNode, d)
        }
        if (this.props.patternsData.ChannelLinesNode !== undefined &&
            this.props.patternsData.ChannelLinesNode.length > 0) {
            this.outputPatternInfo(this.props.patternsData.ChannelLinesNode, d)
        }
        if (this.props.patternsData.CupPatternNode !== undefined &&
            this.props.patternsData.CupPatternNode.length > 0) {
            this.outputPatternInfo(this.props.patternsData.CupPatternNode, d)
        }
        if (this.props.patternsData.DoubleBottomsNode !== undefined &&
            this.props.patternsData.DoubleBottomsNode.length > 0) {
            this.outputPatternInfo(this.props.patternsData.DoubleBottomsNode, d)
        }
        if (this.props.patternsData.AscendingBasePatternNode !== undefined &&
            this.props.patternsData.AscendingBasePatternNode.length > 0) {
            this.outputPatternInfo(this.props.patternsData.AscendingBasePatternNode, d)
        }
        if (this.props.patternsData.IPOBasePatternNode !== undefined &&
            this.props.patternsData.IPOBasePatternNode.length > 0) {
            this.outputPatternInfo(this.props.patternsData.IPOBasePatternNode, d)
        }
        return d;
    }
    outputHighLights(patternInfo, d, key) {
        const lng = patternInfo.length;
        for (let x = 0; x < lng; x++) {
            if (patternInfo[x].stick) {
                let currRectangle = null;
                const currColor = "#14589E";
                const tData = patternInfo[x];
                const yVal = tData.PatternName === "Tight Area" ? tData.maxY : tData.PatternName === "Cup with Handle" || tData.PatternName === "Saucer with Handle" ? tData.startHighY : tData.pivotPriceY;
                const minY = tData.PatternName === "Double Bottom" ? tData.secondBottomY : tData.minY;

                if (tData.PatternName === "Channel Lines") {
                    currRectangle = this.trianglePath(tData.firstPeakDtX, tData.firstPeakDtY, tData.endDateX, tData.endDateY);
                } else {
                    currRectangle = this.rectanglePath(tData.startIn, yVal, Math.abs(minY - yVal), Math.abs(tData.startIn - tData.endIn));
                }
                d.push(this.getPath(currRectangle, patternInfo[x], key, x, 2, currColor, 'transparent', "0.2", "none"));
            }
        }
    }
    outputAllHighLights() {
        const d = [];
        if (this.props.patternsData.TightAreaPatternNode !== undefined &&
            this.props.patternsData.TightAreaPatternNode.length > 0) {
            this.outputHighLights(this.props.patternsData.TightAreaPatternNode, d, 'hTight')
        }
        if (this.props.patternsData.FlatPatternNode !== undefined &&
            this.props.patternsData.FlatPatternNode.length > 0) {
            this.outputHighLights(this.props.patternsData.FlatPatternNode, d, 'hFlat')
        }
        if (this.props.patternsData.ConsolidationPatternNode !== undefined &&
            this.props.patternsData.ConsolidationPatternNode.length > 0) {
            this.outputHighLights(this.props.patternsData.ConsolidationPatternNode, d, 'hCons')
        }
        if (this.props.patternsData.ChannelLinesNode !== undefined &&
            this.props.patternsData.ChannelLinesNode.length > 0) {
            this.outputHighLights(this.props.patternsData.ChannelLinesNode, d, 'hChannel')
        }
        if (this.props.patternsData.CupPatternNode !== undefined &&
            this.props.patternsData.CupPatternNode.length > 0) {
            this.outputHighLights(this.props.patternsData.CupPatternNode, d, 'hCup')
        }
        if (this.props.patternsData.DoubleBottomsNode !== undefined &&
            this.props.patternsData.DoubleBottomsNode.length > 0) {
            this.outputHighLights(this.props.patternsData.DoubleBottomsNode, d, 'hDouble')
        }
        if (this.props.patternsData.AscendingBasePatternNode !== undefined &&
            this.props.patternsData.AscendingBasePatternNode.length > 0) {
            this.outputHighLights(this.props.patternsData.AscendingBasePatternNode, d, 'hAscending')
        }
        if (this.props.patternsData.IPOBasePatternNode !== undefined &&
            this.props.patternsData.IPOBasePatternNode.length > 0) {
            this.outputHighLights(this.props.patternsData.IPOBasePatternNode, d, 'hIPO')
        }
        return d;
    }

    getStringLength(str) {
        let len = 0;
        if (str === undefined) {
            return len;
        }
        for (let i = 0; i < str.length; i++) {
            const c = str.charCodeAt(i);
            if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
                len++;
            }
            else {
                len += 2;
            }
        }
        return len;
    }

    translatePatternName(datasource) {
        return PatterNameTranslateHelper[datasource.PatternName] || datasource.PatternName || "Undefined"

    }

    showInfoBox(showText, title, screenX, screenY, datasource) {
        let backgroundColor = ThemeHelper.getThemedBrush("E1E20FEFEF0");
        const tData = datasource;
        const minY = tData.PatternName === "Double Bottom" ? tData.secondBottomY : tData.minY;
        let sX = screenX;
        const advanceSettings = this.props.PatternRecognitionSettings.AdvancePattern;
        if (tData.PatternName === "Channel Lines") {
            sX = tData.firstPeakDtX;
            screenY = minY + 10;
        } else {
            if (tData.startIn) {
                if (tData.startIn < 0) {
                    if (tData.endIn < 0) { return (<div></div>); }
                    sX = tData.endIn;
                }
                else { sX = tData.startIn; }
                screenY = minY + 10;
            } else {
                screenY = screenY - this.props.dimension.top;
            }
        }

        if (sX - this.props.dimension.left < 0) { sX = this.props.dimension.left; }
        let ytop = `${screenY}px`;
        let xLeft = `${sX - this.props.dimension.left}px`;
        let scY = screenY;

        let maxInfoLength = 0;
        let maxTitleLength = 0;
        let totalItems = 0;
        for (let t = 0; t < title.length; t++) {
            if (title[t]) {
                if (this.getStringLength(title[t].info) > maxInfoLength) { maxInfoLength = this.getStringLength(title[t].info); }
                if (this.getStringLength(title[t].title) > maxTitleLength) { maxTitleLength = this.getStringLength(title[t].title); }
                totalItems++;
            }
        }
        if (title.length > 0) {
            totalItems = totalItems + 1;
        }
        const fld1 = `${maxTitleLength * 8}px`;
        const fld2 = `${maxInfoLength * 8}px`;
        const heightV = Math.max(1, totalItems) * 16 + (advanceSettings || totalItems < 1 ? 10 : 18);
        const height = `${heightV}px`;
        const widthV = this.getStringLength(tData.PatternName) * 8;
        const width = title.length < 1 ? `${widthV}px` : `${10 + (maxTitleLength + maxInfoLength) * 8}px`;
        let headerLineText = "";
        const headerLineCount = title.length < 1 ? tData.PatternName.length : (maxTitleLength + maxInfoLength);

        for (let l = 0; l < headerLineCount; l++) {
            headerLineText += "_";
        }

        if (scY > 0 && scY + heightV > this.props.dimension.height && tData.startIn) {
            if (scY > datasource.pivotPriceY - heightV && datasource.pivotPriceY - heightV > 0) {
                const yVal = datasource.PatternName === "Tight Area" ? datasource.maxY : datasource.PatternName === "Cup with Handle" || datasource.PatternName === "Saucer with Handle" ? datasource.startHighY : datasource.pivotPriceY;
                let newY = yVal - heightV;
                if (this.props.isOpen && newY < 350) {
                    newY = 350;
                    if (this.props.dimension.width - tData.endIn > 100) {
                        xLeft = `${tData.endIn + 10}px`;
                    } else {
                        if (tData.startIn < 100) {
                            xLeft = `${tData.endIn + 10}px`;
                        } else {
                            xLeft = `${tData.startIn - 100}px`;
                        }
                    }
                }
                if (newY <= 0) {
                    newY = 20;
                    sX = tData.endIn - 180;
                    xLeft = `${sX - this.props.dimension.left}px`;
                }
                ytop = `${newY}px`;
            } else {
                if (tData.minY < this.props.dimension.height) {
                    ytop = `${tData.minY - heightV - 10}px`;
                } else {
                    ytop = `${this.props.dimension.height - heightV}px`;
                }
                if (this.props.dimension.width - tData.endIn > 100) {
                    sX = tData.endIn + this.props.dimension.left + 10;
                } else {
                    if (tData.startIn < 100) {
                        sX = tData.endIn + this.props.dimension.left + 10;
                    } else {
                        sX = tData.startIn - 100;
                    }
                }
                if (sX - this.props.dimension.left < 0) {
                    sX = tData.endIn + 90;
                }
                if (this.props.dimension.right - (sX - this.props.dimension.left) < 90) {
                    sX = this.props.dimension.left + 90;
                    if (tData.minY < this.props.dimension.height) {
                        ytop = `${tData.minY - heightV}px`;
                    }
                    else {
                        ytop = `${heightV + 20}px`;
                    }
                }
                xLeft = `${sX - this.props.dimension.left}px`;
            }
        }
        if (this.props.isOpen && scY < 350 && sX < 900) {
            if (sX < 900) {
                xLeft = `${900}px`;
            } else {
                scY = 350;
                ytop = `${scY}px`;
            }
        }

        let grayText = ThemeHelper.getThemedBrush("scaleText");
        if (this.isRangeType(tData.PatternName)) {
            backgroundColor = "#FFFFDD";
            grayText = "black";
        }
        const zIndex = tData.zIndex ? tData.zIndex : this.infoZindex;
        const textStyle = {
            backgroundColor: backgroundColor,
            borderColor: "#333333",
            borderStyle: "solid",
            borderWidth: "1px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            fontFamily: "calibri",
            fontSize: "11px",
            color: "gray",
            position: "absolute",
            left: xLeft,
            top: ytop,
            height: height,
            width: width,
            zIndex: zIndex,
            pointerEvents: "auto"
        };
        if (!tData.zIndex) { this.infoZindex++; }
        this.boxInfo++;
        const keyValue = datasource.PatternName + screenX + this.boxInfo;
        tData.zIndex = zIndex;
        return (
            <div id="PRInfoBox" key={keyValue} style={textStyle}>
                {(datasource && datasource.stick) &&
                    <div style={{
                        position: "absolute",
                        right: "-8px",
                        top: "-8px",
                        width: "16px", height: "16px", borderTopLeftRadius: "8px",
                        borderColor: "#333333",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        backgroundColor: backgroundColor,
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        cursor: "pointer",
                        pointerEvents: "visible"
                    }} data-disable-track-price="true" onMouseDown={(e) => this.handleMouseDown(datasource, e)}>
                        <div style={{ textAlign: "center", marginLeft: "1px", marginTop: "-1px", opacity: "0.6", fontFamily: "arial", fontSize: "12px" }}>X</div>
                    </div>}
                <div style={{ textAlign: "Center", fontFamily: "calibri", fontWeight: "bold", color: grayText, marginBottom: "4px", marginTop: "4px" }}>
                    {showText}
                </div>
                {title.length > 0 &&
                    <div style={{ textAlign: "Center", marginTop: "-15px", marginBottom: "4px", color: "#333333" }}>
                        {headerLineText}
                    </div>
                }
                {title.map((title, index) => (
                    <table key={keyValue + index} style={{ marginTop: "-2px" }}>
                        {title && title.info !== "" &&
                            <tbody>
                                <tr>
                                    <td> <div style={{ marginLeft: "8px", color: "gray", width: fld1, textAlign: "left" }}>
                                        {title.title}</div>
                                    </td>
                                    <td> <div style={{ textAlign: "left", color: grayText, width: fld2 }}>
                                        {title.info}</div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </table>
                ))}
            </div>
        );
    }

    getMainSvg() {
        const lineStyle = { position: "absolute", fitPosition: "fill", left: "0px", top: "0px", zIndex: "8" };
        const points = this.prepareTightPatterns(this.props.patternsData);
        const flatLine = this.prepareFlatPatternsSolid(this.props.patternsData);
        let dottedLine = this.prepareFlatPatternsDotted(this.props.patternsData);
        let profit = "";
        let pivot = "";
        let loss = "";
        const flag = [];
        const flag2 = [];
        const sText = [];
        const sText2 = [];
        let i;
        if (this.props.patternsData.FlatPatternNode) {
            const lng = this.props.patternsData.FlatPatternNode.length;
            for (i = 0; i < lng; i++) {
                const tData = this.props.patternsData.FlatPatternNode[i];
                if (tData && tData.pivotH && this.props.PatternRecognitionSettings.showKPR) {
                    profit = this.rectanglePath(tData.endIn, tData.pivotY2 - tData.pivotH2, tData.pivotH2, tData.periotLength);
                    pivot = this.rectanglePath(tData.endIn, tData.pivotY0 - tData.pivotH1, tData.pivotH1, tData.periotLength);
                    loss = this.rectanglePath(tData.endIn, tData.pivotY5 - tData.pivotH3, tData.pivotH3, tData.periotLength);
                }
                if (tData.flagNode && this.props.PatternRecognitionSettings.PowerFromPivot) {
                    flag.push({ path: this.powerFromPivot(tData.flagNode), data: { PatternName: PatternRangeTranslateHelper.POWER_FROM_PIVOT, Title: [], startIn: tData.flagNode.xAxis, minY: tData.flagNode.yAxis } });
                    sText.push(tData);
                }
            }
        }
        const ConsolidationD6 = this.prepareConsolidationPatternD6(this.props.patternsData);
        dottedLine += this.prepareConsolidationPatternD2(this.props.patternsData);
        if (this.props.patternsData.ConsolidationPatternNode) {
            const lng = this.props.patternsData.ConsolidationPatternNode.length;
            for (i = 0; i < lng; i++) {
                const tData = this.props.patternsData.ConsolidationPatternNode[i];
                if (tData && tData.pivotH && this.props.PatternRecognitionSettings.showKPR) {
                    profit = this.rectanglePath(tData.endIn, tData.pivotY2 - tData.pivotH2, tData.pivotH2, tData.periotLength);
                    pivot = this.rectanglePath(tData.endIn, tData.pivotY0 - tData.pivotH1, tData.pivotH1, tData.periotLength);
                    loss = this.rectanglePath(tData.endIn, tData.pivotY5 - tData.pivotH3, tData.pivotH3, tData.periotLength);
                }
                if (tData.flagNode && this.props.PatternRecognitionSettings.PowerFromPivot) {
                    flag.push({ path: this.powerFromPivot(tData.flagNode), data: { PatternName: PatternRangeTranslateHelper.POWER_FROM_PIVOT, Title: [], startIn: tData.flagNode.xAxis, minY: tData.flagNode.yAxis } });
                    sText.push(tData);
                }
            }
        }
        const channelLines = this.prepareChannelLines(this.props.patternsData);
        if (this.props.patternsData.ChannelLinesNode) {
            const lng = this.props.patternsData.ChannelLinesNode.length;
            for (i = 0; i < lng; i++) {
                const tData = this.props.patternsData.ChannelLinesNode[i];
                if (tData && tData.pivotH && this.props.PatternRecognitionSettings.showKPR) {
                    profit = this.rectanglePath(tData.endIn, tData.pivotY2 - tData.pivotH2, tData.pivotH2, tData.periotLength);
                    pivot = this.rectanglePath(tData.endIn, tData.pivotY0 - tData.pivotH1, tData.pivotH1, tData.periotLength);
                    loss = this.rectanglePath(tData.endIn, tData.pivotY5 - tData.pivotH3, tData.pivotH3, tData.periotLength);
                }
                if (tData.flagNode && this.props.PatternRecognitionSettings.PowerFromPivot) {
                    flag.push({ path: this.powerFromPivot(tData.flagNode), data: { PatternName: PatternRangeTranslateHelper.POWER_FROM_PIVOT, Title: [], startIn: tData.flagNode.xAxis, minY: tData.flagNode.yAxis } });
                    sText.push(tData);
                }
            }
        }
        const cupData = this.prepareCupPattern(this.props.patternsData);
        if (this.props.patternsData.CupPatternNode) {
            const lng = this.props.patternsData.CupPatternNode.length;
            for (i = 0; i < lng; i++) {
                const tData = this.props.patternsData.CupPatternNode[i];
                if (tData.pivotPriceIn) {
                    if (tData.handleBottomIn) {
                        dottedLine += this.linePath(tData.pivotPriceIn, tData.pivotPriceY, tData.endIn, tData.pivotPriceY);
                    }
                    else {
                        dottedLine += this.linePath(tData.pivotPriceIn, tData.pivotPriceY, tData.cupEndIn, tData.pivotPriceY);
                    }
                }
                if (tData && tData.pivotH && this.props.PatternRecognitionSettings.showKPR) {
                    profit = this.rectanglePath(tData.endIn, tData.pivotY2 - tData.pivotH2, tData.pivotH2, tData.periotLength);
                    pivot = this.rectanglePath(tData.endIn, tData.pivotY0 - tData.pivotH1, tData.pivotH1, tData.periotLength);
                    loss = this.rectanglePath(tData.endIn, tData.pivotY5 - tData.pivotH3, tData.pivotH3, tData.periotLength);
                }
                if (tData.flagNode && this.props.PatternRecognitionSettings.PowerFromPivot) {
                    flag.push({ path: this.powerFromPivot(tData.flagNode), data: { PatternName: PatternRangeTranslateHelper.POWER_FROM_PIVOT, Title: [], startIn: tData.flagNode.xAxis, minY: tData.flagNode.yAxis } });
                    sText.push(tData);
                }
            }
        }
        const doubleBottom = this.prepareDoubleBottom(this.props.patternsData);
        if (this.props.patternsData.DoubleBottomsNode) {
            const lng = this.props.patternsData.DoubleBottomsNode.length;
            for (i = 0; i < lng; i++) {
                const tData = this.props.patternsData.DoubleBottomsNode[i];
                if (tData.pivotPriceIn) {
                    dottedLine += this.linePath(tData.pivotPriceIn, tData.pivotPriceY, tData.endIn, tData.pivotPriceY);
                }
                if (tData && tData.pivotH && this.props.PatternRecognitionSettings.showKPR) {
                    profit = this.rectanglePath(tData.endIn, tData.pivotY2 - tData.pivotH2, tData.pivotH2, tData.periotLength);
                    pivot = this.rectanglePath(tData.endIn, tData.pivotY0 - tData.pivotH1, tData.pivotH1, tData.periotLength);
                    loss = this.rectanglePath(tData.endIn, tData.pivotY5 - tData.pivotH3, tData.pivotH3, tData.periotLength);
                }
                if (tData.flagNode && this.props.PatternRecognitionSettings.PowerFromPivot) {
                    flag.push({ path: this.powerFromPivot(tData.flagNode), data: { PatternName: PatternRangeTranslateHelper.POWER_FROM_PIVOT, Title: [], startIn: tData.flagNode.xAxis, minY: tData.flagNode.yAxis } });
                    sText.push(tData);
                }
            }
        }
        const ascendingBase = this.prepareAscendingBase(this.props.patternsData);
        if (this.props.patternsData.AscendingBasePatternNode) {
            const lng = this.props.patternsData.AscendingBasePatternNode.length;
            for (i = 0; i < lng; i++) {
                const tData = this.props.patternsData.AscendingBasePatternNode[i];
                if (tData.pivotPriceIn) {
                    dottedLine += this.linePath(tData.pivotPriceIn, tData.pivotPriceY, tData.endIn, tData.pivotPriceY);
                }
                if (tData && tData.pivotH && this.props.PatternRecognitionSettings.showKPR) {
                    profit = this.rectanglePath(tData.endIn, tData.pivotY2 - tData.pivotH2, tData.pivotH2, tData.periotLength);
                    pivot = this.rectanglePath(tData.endIn, tData.pivotY0 - tData.pivotH1, tData.pivotH1, tData.periotLength);
                    loss = this.rectanglePath(tData.endIn, tData.pivotY5 - tData.pivotH3, tData.pivotH3, tData.periotLength);
                }
                if (tData.flagNode && this.props.PatternRecognitionSettings.PowerFromPivot) {
                    flag.push({ path: this.powerFromPivot(tData.flagNode), data: { PatternName: PatternRangeTranslateHelper.POWER_FROM_PIVOT, Title: [], startIn: tData.flagNode.xAxis, minY: tData.flagNode.yAxis } });
                    sText.push(tData);
                }
            }
        }
        const ipoLine = this.prepareIPOBasePatternSolid(this.props.patternsData);
        dottedLine += this.prepareIPOBasePatternDotted(this.props.patternsData);
        if (this.props.patternsData.IPOBasePatternNode) {
            const lng = this.props.patternsData.IPOBasePatternNode.length;
            for (i = 0; i < lng; i++) {
                const tData = this.props.patternsData.IPOBasePatternNode[i];
                if (tData && tData.pivotH && this.props.PatternRecognitionSettings.showKPR) {
                    profit = this.rectanglePath(tData.endIn, tData.pivotY2 - tData.pivotH2, tData.pivotH2, tData.periotLength);
                    pivot = this.rectanglePath(tData.endIn, tData.pivotY0 - tData.pivotH1, tData.pivotH1, tData.periotLength);
                    loss = this.rectanglePath(tData.endIn, tData.pivotY5 - tData.pivotH3, tData.pivotH3, tData.periotLength);
                }
                if (tData.flagNode && this.props.PatternRecognitionSettings.PowerFromPivot) {
                    flag.push({ path: this.powerFromPivot(tData.flagNode), data: { PatternName: PatternRangeTranslateHelper.POWER_FROM_PIVOT, Title: [], startIn: tData.flagNode.xAxis, minY: tData.flagNode.yAxis } });
                    sText.push(tData);
                }
            }
        }
        if (this.props.patternsData.ClimaxTopsNode) {
            for (i = 0; i < this.props.patternsData.ClimaxTopsNode.length; i++) {
                if (this.props.patternsData.ClimaxTopsNode[i].flagNode && this.props.PatternRecognitionSettings.ClimaxTop) {
                    flag2.push({ path: this.climaxTop(this.props.patternsData.ClimaxTopsNode[i].flagNode), data: { PatternName: PatternRangeTranslateHelper.CLIMAX_TOP, Title: [], startIn: this.props.patternsData.ClimaxTopsNode[i].flagNode.xAxis, minY: this.props.patternsData.ClimaxTopsNode[i].flagNode.yAxis } });
                    sText2.push(this.props.patternsData.ClimaxTopsNode[i]);
                }
            }
        }
        let currRectangle = null;
        const currColor = "#14589E";
        if (this.state.showBox) {
            const tData = this.state.datasource;
            const yVal = tData.PatternName === "Tight Area" ? tData.maxY : tData.PatternName === "Cup with Handle" || tData.PatternName === "Saucer with Handle" ? tData.startHighY : tData.pivotPriceY;
            const minY = tData.PatternName === "Double Bottom" ? tData.secondBottomY : tData.minY;
            if (yVal === undefined) {
                currRectangle = "";
            } else {
                if (tData.PatternName === "Channel Lines") {
                    currRectangle = this.trianglePath(tData.firstPeakDtX, tData.firstPeakDtY, tData.endDateX, tData.endDateY);
                } else {
                    currRectangle = this.rectanglePath(tData.startIn, yVal, Math.abs(minY - yVal), Math.abs(tData.startIn - tData.endIn));
                }
            }
        }
        let lineColor = Boolean(this.props.PatternRecognitionSettings.lineColor) ? ThemeHelper.getThemedBrush(this.props.PatternRecognitionSettings.lineColor) : "PFFFFFF000000";
        if (this.props.videoMode) {
            lineColor = ThemeHelper.getThemedBrush("P34FF33006600");
        }
        const lineTColor = Boolean(this.props.PatternRecognitionSettings.lineTColor) ? ThemeHelper.getThemedBrush(this.props.PatternRecognitionSettings.lineTColor) : "PFFFFFF000000";
        let x = 0;
        const scaleText = ThemeHelper.getThemedBrush("scaleText");
        return (
            <svg style={lineStyle}
                className="svg pattern-recognition"
                id="PRAreas"
                height='100%'
                width='100%'>
                {this.outputCupPatterns(cupData, lineColor)}
                {this.outputPoints(points, lineTColor)}
                {this.outputFlatPatterns(flatLine, lineColor)}
                {dottedLine !== '' && this.getPath(dottedLine, undefined, 11, 3000, 2, 'transparent', lineColor, "0.5", "none")}
                {this.outputProfitArea(profit)}
                {this.outputPivotArea(pivot)}
                {this.outputLossArea(loss)}
                {flag.length > 0 && flag.map((t, index) => this.getPath(t.path, t.data, 'flag', index, 0, 'green', scaleText, "0.8", "visible", "1px"))}
                {flag2.length > 0 && flag2.map((t, index) => this.getPath(t.path, t.data, 'flag2', index, 0, 'yellow', scaleText, "0.8", "visible", "1px"))}
                {sText.length > 0 &&
                    sText.map((t) => {
                        x++;
                        return this.powerFromPivotText(t.flagNode, x);
                    })
                }
                {sText2.length > 0 && sText2.map((t) => this.climaxTopText(t))}
                {this.outputConsalidationPatterns(ConsolidationD6, lineColor)}
                {this.outputChannelLines(channelLines, lineColor)}
                {this.outputDoubleBottomPatterns(doubleBottom, lineColor)}
                {this.outputAscendingBasePatterns(ascendingBase, lineColor)}
                {this.outputIPOBasePatterns(ipoLine, lineColor)}
                {this.state.showBox && this.getPath(currRectangle, undefined, 'InfoBox', 1, 2, currColor, 'transparent', "0.2", "none")}
                {this.outputAllHighLights()}
            </svg>
        )
    }

    render() {
        TimeTrackingWindow.trackChartLoadingTime();
        return (this.props.IsVisible && <div>
            {this.state.showBox && this.showInfoBox(this.state.showText, this.state.Title, this.state.screenX, this.state.screenY, this.state.datasource)}
            {this.outputAllInfoBox()}
            <div id="PRInfo"
                style={{ position: "absolute", height: '100%', width: '100%', pointerEvents: 'none' }}>
                {this.getMainSvg()}
            </div>
        </div>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers, appColor, summaryReducer }) => {
    const { SymTypeEnum, showToolTip, videoMode } = DatagraphReducers.DataGraphReducer;
    const { IsVisible, patternsData, PatternRecognitionSettings } = DatagraphReducers.PRReducer;
    const { dimension } = DatagraphReducers.PriceChartReducer;
    const { isThemeChanged } = appColor
    const { isOpen } = summaryReducer;
    return { SymTypeEnum, showToolTip, patternsData, IsVisible, dimension, isThemeChanged, videoMode, PatternRecognitionSettings, isOpen }
}

export default connect(mapStateToProps)(PatternRecognition);
