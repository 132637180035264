import { MenuStates } from '../../../Models/NavCompare/ComparisonAndRotationMenuModels/MenuStates';
import { ComparisonAndRotationMenuConstants, RotationGraphConstants } from "RotationGraphConstants";
import { extend, map } from "underscore";
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import CompareTabType from "CompareTabType";
import { SettingsConstants } from "Constants/SettingsConstants.js";
extend(ActionTypes, SettingsConstants.ActionTypes, RotationGraphConstants.ActionTypes);
// import store from '../../../Redux/store';
// import StringUtil from '../../../Utils/StringUtil';
// import {getState} from 'redux';

let initialState = {
    selectedTab: '',
    geographyDataList: {}
};

extend(initialState, { ComparisonMenuStates: new MenuStates(), RotationMenuStates: new MenuStates() });

const GroupsPanelReducer = (state = initialState, action) => {

    const setState = (obj) => {
        if (state.selectedTab === CompareTabType.ComparisonGraph) {
            map(obj, (value, property) => state.ComparisonMenuStates[property] = value);
        }
        else {
            map(obj, (value, property) => state.RotationMenuStates[property] = value);
        }

        return { ...state };
    }

    const getStateData = () => {

        if (state.selectedTab === CompareTabType.ComparisonGraph) {
            return state.ComparisonMenuStates;
        }
        else {
            return state.RotationMenuStates;
        }
    }

    switch (action.type) {
        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
            return {
                ...state,
                selectedTab: action.selectedTab
            }

        case ActionTypes.SET_GROUPS_LISTITEMS:
            return setState({ 'listItems': action.listItems })

        case ActionTypes.SET_GROUPS_STOCK_LISTITEMS:
            return setState({ 'stockList': action.stockList })

        case ActionTypes.SET_GROUPS_MENU_CURRENT_ACTION:
            return setState({ 'currentAction': action.currentAction });

        case ActionTypes.SET_OPEN_GEOGRAPHY_STATUS:
            return setState({ 'openGeography': action.status });

        case ActionTypes.SET_GEOGRAPHY_BY_COUNTRY_CODE:
            return setState({ 'countryCode': action.countryCode });

        case ActionTypes.SET_ROTATION_MENU_TYPE:
            return setState({ 'rotationMenuType': action.rotationMenuType })

        case ActionTypes.SET_BREADCRUM_ITEMS:
            return setState({ 'breadCrumbItems': action.breadCrumbItems })

        case ActionTypes.UPDATE_GEOGRAPHY_DATA:
            return {
                ...state,
                geographyDataList: action.geographyDataList
            }

        case ActionTypes.SET_GROUPS_QUAD_DATA:
            return setState({
                "stocksTotalList": action.stocksTotalList,
                "quadGraphYearList": action.quadGraphYearList,
                "groupResult": action.groupResult
            })

        case ActionTypes.UPDATE_GROUPS_QUAD_GRAPH_RESULT:
            return setState({ 'quadGraphResults': action.quadGraphResults });

        case ActionTypes.UPDATE_GEOGRAPHY_SELECTION:
            return setState({ 'isGeographySelected': action.isGeographySelected });

        case ActionTypes.HIGHLIGHT_GROUPS_ITEM_SUCCESS:
            return setState({
                isHighLighted: !getStateData().isHighLighted
            });
        default:
            return state
    }
}

export default GroupsPanelReducer;