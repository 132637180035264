import { DataGraphConstants } from "../../Constants/DataGraphConstants";
import LangType from "../../Constants/LangType";
import SettingsStore from "SettingsStore";
import ThemeType from "../../Constants/ThemeTypeConstants";
import UserInfoUtil from "../../Utils/UserInfoUtil";
import { ActionTypes, DefaultColors, DefaultHoverColors, DefaultTextColors, UseDefaultColor } from "../../Constants/AppColorConstants";


const consoleSettings = SettingsStore.getConsoleSettings();
let initState = {isThemeChanged: false};
if (consoleSettings) {
    const appColorSettings = consoleSettings.AppColorSettings;

    initState = {
        darkPosColor: appColorSettings.darkPosColor,
        darkNegColor: appColorSettings.darkNegColor,
        darkPosHoverColor: appColorSettings.darkPosHoverColor,
        darkNegHoverColor: appColorSettings.darkNegHoverColor,
        darkPosDTColor: appColorSettings.darkPostDTColor,
        darkNegDTColor: appColorSettings.darkNegDTColor,
        darkPosDTHoverColor: appColorSettings.darkPosDTHoverColor,
        darkNegDTHoverColor: appColorSettings.darkNegDTHoverColor,

        lightPosColor: appColorSettings.lightPosColor,
        lightNegColor: appColorSettings.lightNegColor,
        lightPosHoverColor: appColorSettings.lightPosHoverColor,
        lightNegHoverColor: appColorSettings.lightNegHoverColor,
        lightPosDTColor: appColorSettings.lightPosDTColor,
        lightNegDTColor: appColorSettings.lightNegDTColor,
        lightPosDTHoverColor: appColorSettings.lightPosDTHoverColor,
        lightNegDTHoverColor: appColorSettings.lightNegDTHoverColor,

        theme: ThemeType.Dark,
        lang: LangType.RAY_DEFAULT,
        isOpen: false,
    }
}

function getCurrentThemeColors(consoleSettings, settings, gridChanged = null){
    const theme = consoleSettings.originalTheme
    const state = {theme}
    if(UserInfoUtil.hasChinaEntitlement()){
        state.posColor = DefaultColors.Red;
        state.negColor = DefaultColors.Green;
        state.posDTColor = DefaultTextColors.Blue;
        state.negDTColor = DefaultTextColors.Magenta;
        state.posHoverColor = DefaultHoverColors.Blue;
        state.negHoverColor = DefaultHoverColors.Magenta;
    }
    else {
        if(theme === ThemeType.Dark || theme === ThemeType.Video){
            state.posColor = settings.darkPosColor;
            state.negColor = settings.darkNegColor;
            state.posDTColor = settings.isDefaultDarkPosColor === UseDefaultColor.Yes ? theme === ThemeType.Video ? DefaultTextColors.VideoBlue : DefaultTextColors.Blue : settings.darkPosColor;
            state.negDTColor = settings.isDefaultDarkNegColor === UseDefaultColor.Yes ? DefaultTextColors.Magenta : settings.darkNegColor;
            state.posHoverColor = settings.darkPosHoverColor;
            state.negHoverColor = settings.darkNegHoverColor;
            state.gridChanged = gridChanged === null ? null : consoleSettings.gridLineDark
        }
        else{
            state.posColor = settings.lightPosColor;
            state.negColor = settings.lightNegColor;
            state.posDTColor = settings.isDefaultLightPosColor === UseDefaultColor.Yes ? DefaultTextColors.Blue : settings.lightPosColor;
            state.negDTColor = settings.isDefaultLightNegColor === UseDefaultColor.Yes ? DefaultTextColors.Magenta : settings.lightNegColor;
            state.posHoverColor = settings.lightPosHoverColor;
            state.negHoverColor = settings.lightNegHoverColor;
            state.gridChanged = gridChanged === null ? null : consoleSettings.gridLineLight
        }
    }
    return state;
}

export default function appColor(state = initState, action) {

    const newState = Object.assign({}, state);
    const consoleSettings = SettingsStore.getConsoleSettings();

    let appColorSettings = null;
    if (consoleSettings) {
        appColorSettings = consoleSettings.AppColorSettings;
    }
    let currentThemeColors = {};
    switch (action.type) {
        case DataGraphConstants.ActionTypes.INIT_SETTINGS_TO_REDUCER:
            return {
                ...newState,
                ...getCurrentThemeColors(consoleSettings, appColorSettings),
            }
        case ActionTypes.INIT_DARK_APP_COLOR:
            return {
                ...newState,
                darkPosColor: action.darkPosColor,
                darkNegColor: action.darkNegColor,
                darkPosHoverColor: action.darkPosHoverColor,
                darkNegHoverColor: action.darkNegHoverColor,
                darkPosDTColor: action.darkPosDTColor,
                darkNegDTColor: action.darkNegDTColor,
                darkPosDTHoverColor: action.darkPosDTHoverColor,
                darkNegDTHoverColor: action.darkNegDTHoverColor,
                theme: action.theme,
                lang: action.lang,
                isOpen: action.isOpen,
            };
        case ActionTypes.INIT_LIGHT_APP_COLOR:
            return {
                ...newState,
                lightPosColor: action.lightPosColor,
                lightNegColor: action.lightNegColor,
                lightPosHoverColor: action.lightPosHoverColor,
                lightNegHoverColor: action.lightNegHoverColor,
                lightPosDTColor: action.lightPosDTColor,
                lightNegDTColor: action.lightNegDTColor,
                lightPosDTHoverColor: action.lightPosDTHoverColor,
                lightNegDTHoverColor: action.lightNegDTHoverColor,
                theme: action.theme,
                lang: action.lang,
                isOpen: action.isOpen,
            };
        case ActionTypes.CHANGE_DARK_POSITIVE_COLOR:
            if (appColorSettings) {
                appColorSettings.darkPosColor = action.darkPosColor;
                appColorSettings.darkPosHoverColor = action.darkPosHoverColor;
                appColorSettings.isDefaultDarkPosColor = action.isDefault ? UseDefaultColor.Yes : UseDefaultColor.No;
                if(consoleSettings.currentTheme === ThemeType.Dark){
                    currentThemeColors = getCurrentThemeColors(consoleSettings, appColorSettings)
                }
                SettingsStore.saveSettings();
            }
            return {
                ...newState,
                ...currentThemeColors,
                darkPosColor: action.darkPosColor,
                darkPosHoverColor: action.darkPosHoverColor,
                isOpen: action.isOpen,
            };
        case ActionTypes.CHANGE_DARK_NEGATIVE_COLOR:
            if (appColorSettings) {
                appColorSettings.darkNegColor = action.darkNegColor;
                appColorSettings.darkNegHoverColor = action.darkNegHoverColor;
                appColorSettings.isDefaultDarkNegColor = action.isDefault ? UseDefaultColor.Yes : UseDefaultColor.No;
                if(consoleSettings.currentTheme === ThemeType.Dark){
                    currentThemeColors = getCurrentThemeColors(consoleSettings, appColorSettings)
                }
            }
            SettingsStore.saveSettings();
            return {
                ...newState,
                ...currentThemeColors,
                darkNegColor: action.darkNegColor,
                darkNegHoverColor: action.darkNegHoverColor,
                isOpen: action.isOpen,
            };
        case ActionTypes.CHANGE_LIGHT_POSITIVE_COLOR:
            if (appColorSettings) {
                appColorSettings.lightPosColor = action.lightPosColor;
                appColorSettings.lightPosHoverColor = action.lightPosHoverColor;
                appColorSettings.isDefaultLightPosColor = action.isDefault ? UseDefaultColor.Yes : UseDefaultColor.No;
                if(consoleSettings.currentTheme === ThemeType.Light){
                    currentThemeColors = getCurrentThemeColors(consoleSettings, appColorSettings)
                }
                SettingsStore.saveSettings();
            }
            return {
                ...newState,
                ...currentThemeColors,
                lightPosColor: action.lightPosColor,
                lightPosHoverColor: action.lightPosHoverColor,
                isOpen: action.isOpen,
            };
        case ActionTypes.CHANGE_LIGHT_NEGATIVE_COLOR:
            if (appColorSettings) {
                appColorSettings.lightNegColor = action.lightNegColor;
                appColorSettings.lightNegHoverColor = action.lightNegHoverColor;
                appColorSettings.isDefaultLightNegColor = action.isDefault ? UseDefaultColor.Yes : UseDefaultColor.No;
                if(consoleSettings.currentTheme === ThemeType.Light){
                    currentThemeColors = getCurrentThemeColors(consoleSettings, appColorSettings)
                }
                SettingsStore.saveSettings();
            }
            return {
                ...newState,
                ...currentThemeColors,
                lightNegColor: action.lightNegColor,
                lightNegHoverColor: action.lightNegHoverColor,
                isOpen: action.isOpen,
            };
        case ActionTypes.TOGGLE_COLOR_PALETTE:
            return {
                ...newState,
                isOpen: action.isOpen,
                theme: action.theme,
                lang: action.lang,
                selectedColor: action.selectedColor,
                posOrNeg: action.posOrNeg
            }

        case ActionTypes.RESTORE_DARK_COLOR:
            if (appColorSettings) {
                appColorSettings.darkPosColor = action.darkPosColor;
                appColorSettings.darkPosHoverColor = action.darkPosHoverColor;
                appColorSettings.darkNegColor = action.darkNegColor;
                appColorSettings.darkNegHoverColor = action.darkNegHoverColor;
                appColorSettings.isDefaultDarkPosColor = UseDefaultColor.Yes;
                appColorSettings.isDefaultDarkNegColor = UseDefaultColor.Yes;
                if(consoleSettings.currentTheme === ThemeType.Dark){
                    currentThemeColors = getCurrentThemeColors(consoleSettings, appColorSettings)
                }
                SettingsStore.saveSettings();
            }
            return {
                ...newState,
                ...currentThemeColors,
                darkPosColor: action.darkPosColor,
                darkNegColor: action.darkNegColor,
                darkPosHoverColor: action.darkPosHoverColor,
                darkNegHoverColor: action.darkNegHoverColor,
                isDefault: action.isDefault,
            }
        case ActionTypes.RESTORE_LIGHT_COLOR:
            if (appColorSettings) {
                appColorSettings.lightPosColor = action.lightPosColor;
                appColorSettings.lightPosHoverColor = action.lightPosHoverColor;
                appColorSettings.lightNegColor = action.lightNegColor;
                appColorSettings.lightNegHoverColor = action.lightNegHoverColor;

                appColorSettings.isDefaultLightPosColor = UseDefaultColor.Yes;
                appColorSettings.isDefaultLightNegColor = UseDefaultColor.Yes;
                if(consoleSettings.currentTheme === ThemeType.Light){
                    currentThemeColors = getCurrentThemeColors(consoleSettings, appColorSettings)
                }
                SettingsStore.saveSettings();
            }

            return {
                ...newState,
                ...currentThemeColors,
                lightPosColor: action.lightPosColor,
                lightNegColor: action.lightNegColor,
                lightPosHoverColor: action.lightPosHoverColor,
                lightNegHoverColor: action.lightNegHoverColor,
                isDefault: action.isDefault,
            }
        case ActionTypes.TOGGLE_COLOR_PALETTE_PANEL:
            return {
                ...newState,
                isOpen: action.isOpen
            }
        case ActionTypes.CUSTOM_THEME_CHANGED:
            return {
                ...newState,
                ...getCurrentThemeColors(consoleSettings, appColorSettings),
                isThemeChanged: !state.isThemeChanged
            }

        case ActionTypes.CHANGE_GRID_LINE_COLOR:
            return {
                ...newState,
                ...getCurrentThemeColors(consoleSettings, appColorSettings, true)
            }
        default:
            return newState;
    }
}