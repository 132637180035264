import { createSelector } from 'reselect';
import { RayAnnotationType } from '../../Constants/EAnnotationType';
import { each, min, max } from "underscore";

export const getAnnotationMenu = (state) => state.annotationReducers.annotationMenuReducer;
export const getLayersList = (state) => state.annotationReducers.annotationLayerManagementReducer.LayersList;
export const getLineAnnotationList = (state) => state.annotationReducers.lineReducer;
export const getTextAnnotationList = (state) => state.annotationReducers.textReducer;
export const getRectangleAnnotationList = (state) => state.annotationReducers.rectangleReducer;
export const getEllipseAnnotationList = (state) => state.annotationReducers.ellipseReducer;
export const getTriangleAnnotationList = (state) => state.annotationReducers.triangleReducer;
export const getChannelLineAnnotationList = (state) => state.annotationReducers.channelLineReducer;
export const getFibonacciAnnotationList = (state) => state.annotationReducers.fibonacciLineReducer;
export const getMeasurementAnnotationList = (state) => state.annotationReducers.measurementReducer;
export const getAnnotationChartModelReducer = (state) => state.annotationReducers.annotationChartModelReducer;
export const getCombinedAnnotationsReducer = (state) => state.annotationReducers.combinedAnnotationsReducer;
export const getAllAnnotId = (state) => state.annotationReducers.annotationChartModelReducer.allVisibleAnnotId;
export const getAllReadOnlyAnnotId = (state) => state.annotationReducers.annotationChartModelReducer.allReadOnlyAnnotId;

export const getSelectedAnnotationInfo = createSelector(getAnnotationMenu, (Menu) => {
    switch (Menu.selectedToolType) {
        case RayAnnotationType.LINE_AnnotationType: return { ...Menu.LineModelInfo }
        case RayAnnotationType.TEXT_AnnotationType: return { ...Menu.TextModelInfo }

        case RayAnnotationType.RECT_AnnotationType:
        case RayAnnotationType.ELLIPSE_AnnotationType:
        case RayAnnotationType.TRIANGLE_AnnotationType:
            return { ...Menu.ShapesModelInfo }

        case RayAnnotationType.PARALLEL_LINES_AnnotationType: return { ...Menu.ChannelLineModelInfo }
        case RayAnnotationType.FIB_AnnotationType: return { ...Menu.FibonacciModelInfo }
        case RayAnnotationType.MEASUREMENT_AnnotationType: return { ...Menu.MeasurementModelInfo }
        default:
            break;
    }
});

export const getAnnotationsCount = createSelector([getLayersList, getAllAnnotId, getAllReadOnlyAnnotId], (Layers, allAnnotId, allReadOnlyAnnotId) => {
    let count = 0;
    const visibleLayers = [];
    const createdDateArray = [];
    const updatedDateArray = [];

    each(Layers, (item) => {
        if (item.isVisible) {
            visibleLayers.push(item.layerID);
        }
    });

    { /* when the annotation id = tempId, user will be still in drawing mode. so do not update the count at this stage */ }
    each(allAnnotId, (item) => {
        if (visibleLayers.indexOf(item.layerId) !== -1 && !isNaN(item.annotId) && !item.deleted) {
            count++;
            createdDateArray.push(item.createdDate);
            updatedDateArray.push(item.updatedDate);
        }
    });

    each(allReadOnlyAnnotId, (item) => {
        if (visibleLayers.indexOf(item.layerId) !== -1 && !isNaN(item.annotId) && !item.deleted) {
            count++;
            createdDateArray.push(item.createdDate);
            updatedDateArray.push(item.updatedDate);
        }
    });

    const createdDate = (visibleLayers.length > 0 && count > 0 && createdDateArray.length > 0) ? min(createdDateArray) : '';
    const updatedDate = (visibleLayers.length > 0 && count > 0 && updatedDateArray.length > 0) ? max(updatedDateArray) : '';

    return { annotationsCount: count, layersCount: visibleLayers.length, createdDate: createdDate, updatedDate: updatedDate };
});

export const getAnnotations = createSelector([getLineAnnotationList, getTextAnnotationList, getRectangleAnnotationList,
    getEllipseAnnotationList, getTriangleAnnotationList, getChannelLineAnnotationList, getFibonacciAnnotationList,
    getMeasurementAnnotationList, getAnnotationChartModelReducer],
    (Lines, Texts, Rectangles, Ellipses, Triangles, ChannelLines, Fibonaccis, Measurements, chartModel) => {
        const combinedArray = [...Lines, ...Texts, ...Rectangles, ...Ellipses, ...Triangles, ...ChannelLines, ...Fibonaccis, ...Measurements];
        const sortOrder = chartModel.combinedAnnotations;
        //sorting based on sort order
        combinedArray.sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id));
        const lastEle = sortOrder[sortOrder.length - 1];
        //move temp to last of array
        combinedArray.forEach((elem, i) => {
            if (isNaN(elem.id) && ((!elem.isTemporaryChecked && isNaN(lastEle)) || !elem.isTemporaryChecked)) {
                combinedArray.push(combinedArray.splice(i, 1)[0]);
            }
        })
        // grouping annotations based on layer id
        return combinedArray.reduce((groups, item) => {
            const group = (groups[item.layerID] || []);
            group.push(item);
            groups[item.layerID] = group;
            return groups;
        }, {});
    });
