import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetAllIdeasRequest extends BaseServiceRequest {
    constructor(symbol, msId, periodicity, endDate, startDate, userhasxfl, targetCurrencyCode, osid) {
        super();
        super.addPathParameter(msId.toString());
        super.addRequestUri("ideas2.pb");
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("p", periodicity.toString());
        super.addRequestParameter("start", startDate);
        super.addRequestParameter("end", endDate);
        super.addRequestParameter("userhasxfl", ((userhasxfl) ? 1 : 0).toString());
        super.addRequestParameter("tgc", targetCurrencyCode);
        super.addRequestParameter("osid",osid);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "IdeasResponse";
    }
}

export default GetAllIdeasRequest;
