
import keyMirror from "keymirror";

export const OwnershipActionConstants = {
    ActionTypes: keyMirror({
        GET_OWNERSHIP_REPORT_DATA: null,
        GET_OWNERSHIP_REPORT_DATA_SUCCESS: null,
        OWNERSHIP_REPORT_CHANGE_TEXT: null,
        OWNERSHIP_REPORT_CHANGE_TEXT_SUCCESS: null,
        OWNERSHIP_COLUMN_RANK_CHANGED: null,
        OWNERSHIP_COLUMN_PORT_CHANGED: null,
        OWNERSHIP_COLUMN_SHARES_CHANGED: null,
        OWNERSHIP_SHOW_LOADER: null,
        GET_OWNERSHIP_REPORT_MORE_DATA_SUCCESS: null,
        OWNERSHIP_TAB_CHANGE: null,
        OWNERSHIP_SUB_TAB_CHANGE: null,
        OWNERSHIP_TAB_CHANGE_SUCCESS: null,
        OWNERSHIP_SUB_TAB_CHANGE_SUCCESS: null,
        OWNERSHIP_COLUMN_SORT_CHANGED: null,
        OWNERSHIP_COLUMN_SORT_CHANGED_SUCCESS: null,
        OWNERSHIP_PASSIVE_OWNER_FILTER_CHANGE: null,
        OWNERSHIP_PASSIVE_OWNER_FILTER_CHANGE_SUCCESS: null,
        OWNERSHIP_LIMIT_CHANGE: null,
        OWNERSHIP_LIMIT_CHANGE_SUCCESS: null,
        OWNERSHIP_SEARCH_TEXT_CHANGE: null,
        OWNERSHIP_TAB_RESIZE: null,
        OWNERSHIP_TAB_RESIZE_SUCCESS: null,
        CLEAR_OWNERSHIP_REPORT_DATA: null
    })
};
