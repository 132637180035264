import AboutDialog from "../../../../RayCustomControls/Alerts/AboutDialog";
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import ErrorBoundary from "ErrorBoundary";
import { map } from "underscore";
import RiPanelAlertInfo from './Alert/RiPanelAlertInfo';
import RIPanelAlertSecondaryInfo from './Alert/RIPanelAlertSecondaryInfo';
import RiPanelAllocationInfo from "./RiPanelAllocationInfo/RiPanelAllocationInfo.jsx";
import RIPanelAnnotationInfo from './RiPanelAnnotation/RIPanelAnnotationInfo';
import RiPanelBrokerReports from './RiPanelBrokerReports';
import RiPanelChecklistInfo from './RiPanelCheckListInfo';
import RiPanelConstituents from './RiPanelConstituents';
import RIPanelExternalInfo from './RIPanelExternalInfo';
import RiPanelFactorInfo from './RiPanelFactorInfo/RiPanelFactorInfo';
import RiPanelHoldings from './RiPanelHoldings';
import RiPanelManagementInfo from './RiPanelManagementInfo';
import RiPanelNewsInfo from './RiPanelNewInfo/RiPanelNewsInfo';
import RIPanelOneilInfo from './RIPanelOneilInfo/RIPanelOneilInfo';
import RIPanelOwnershipInfo from './RiOwnershipInfo/RIPanelOwnershipInfo';
import RiPanelSimilarFunds from './RiPanelSimilarFunds';
import { RIPanelTranslateHelper } from '../../../../Utils/TranslateHelper';
import RiSubCategoryType from '../../../../Constants/RiSubCategoryType';
import { showContextMenuAtPos } from "../../../../Actions/PricePanelActions";
import { handleTabChange, openAlertAboutDialog } from '../../../../Actions/RelatedInfoPanelActions/RiPanelActions';
import React, { Component } from 'react';

class RelatedInfoBody extends Component {
    constructor() {
        super();
        this.getTabItem = this.getTabItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e, item) {
        this.props.handleTabChange(item.SubCategory);
        e.stopPropagation();
        // RelatedInfoStore.getDefaultRIPanelSetting().RIPanelContentSettings.ActiveSelectedSubCategory = item.SubCategory;
        // let eventKey = FactorResearchStore.getState().selectedFactorKey;

        // switch (item.SubCategory) {
        //     case RiSubCategoryType.News:
        //         TabDataGraphAction.getRIPanelNews();
        //         break;
        //     case RiSubCategoryType.LlrView:
        //         dispatch(populateRIPanelOneilInfo('Stocks'));
        //         break;
        //     case RiSubCategoryType.Ownership:
        //         TabDataGraphAction.getRIPanelOwnership();
        //         break;
        //     case RiSubCategoryType.Alerts:
        //         AlertActions.loadAlertsInRIPanel(true);
        //         break;
        //     case RiSubCategoryType.CheckList:
        //         TabDataGraphAction.getRIPanelChecklist();
        //         break;
        //     case RiSubCategoryType.Factor:
        //         TabDataGraphAction.getRIPanelFactorInfo(eventKey);
        //         break;
        //     case RiSubCategoryType.BrokerReports:
        //         dispatch(populateBrokerReport());
        //         break;
        //     default:
        //         break;
        // }

        // dispatch(clearSelectedAnnotation());
        // dispatch(updateRiPanelInfoFromUserSettings());

        // If Annotations tab is active then all the underlying pricechart mouse events must be blocked
        const annotationDiv = document.getElementsByClassName('annotation-graphics')[0];
        // if (!StringUtil.isEmpty(annotationDiv)) {
        //     if (item.SubCategory === RiSubCategoryType.Annotations && !AnnotationUtil.isPointerSelected) {
        //         annotationDiv.className = "annotation-graphics";
        //         annotationDiv.style.zIndex = 10;
        //     }
        //     else {
        //         if (!annotationDiv.classList.contains("selected-selectiontool")) {
        //             annotationDiv.className += " selected-selectiontool";
        //             annotationDiv.style.zIndex = "";
        //         }
        //     }
        // }

        // SettingsAction.saveUserSettings();
    }

    openContextMenu(item, e) {
        if (item.contextMenu) {
            this.props.showContextMenuAtPos(item.contextMenu, e.clientX, e.clientY)
        }
        e.preventDefault();
    }

    getTabContent() {
        let tabContent = <div />;

        switch (this.props.activeTab) {
            case RiSubCategoryType.News:
                tabContent = (<div className="inner-infoContainer news-block"><ErrorBoundary><RiPanelNewsInfo /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.BrokerReports:
                tabContent = (<div className="inner-infoContainer brokerreports-block"><ErrorBoundary><RiPanelBrokerReports /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.Annotations:
                tabContent = (<div className="inner-infoContainer annotation-block"><ErrorBoundary><RIPanelAnnotationInfo /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.LlrView:
                tabContent = (<div className="inner-infoContainer idealList-block"><ErrorBoundary><RIPanelOneilInfo /></ErrorBoundary></div>);
                break;
            // case RiSubCategoryType.Twitter:
            //     tabContent = (<div className="inner-infoContainer twitter-block"><ErrorBoundary><RIPanelTwitterInfo /></ErrorBoundary></div>);
            //     break;
            case RiSubCategoryType.Alerts:
                tabContent = (<div className="alert-listing inner-infoContainer alert-block"><ErrorBoundary><RiPanelAlertInfo /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.Ownership:
                tabContent = (<div className="inner-infoContainer ownership-block"><ErrorBoundary><RIPanelOwnershipInfo /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.CheckList:
                tabContent = (<div className="inner-infoContainer checkList-block"><ErrorBoundary><RiPanelChecklistInfo /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.Holdings:
                tabContent = (<div className="inner-infoContainer holdings-block"><ErrorBoundary><RiPanelHoldings /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.SimilarFunds:
                tabContent = (<div className="inner-infoContainer similarFunds-block"><ErrorBoundary><RiPanelSimilarFunds /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.Management:
                tabContent = (<div className="inner-infoContainer management-block"><ErrorBoundary><RiPanelManagementInfo /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.Allocation:
                tabContent = (<div className="inner-infoContainer allocation-block"><ErrorBoundary><RiPanelAllocationInfo /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.Constituents:
                tabContent = (<div className="inner-infoContainer constituents-block"><ErrorBoundary><RiPanelConstituents /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.Factor:
                tabContent = (<div className="inner-infoContainer factor-block"><ErrorBoundary><RiPanelFactorInfo /></ErrorBoundary></div>);
                break;
            case RiSubCategoryType.ExternalData: {
                tabContent = (<div className="inner-infoContainer ri-panel-external"><ErrorBoundary><RIPanelExternalInfo /></ErrorBoundary></div>);
                break;
            }
            default:
                break;
        }
        return tabContent;
    }

    getSecondaryTabContent() {
        let tabContent = "";
        switch (this.props.activeTab) {
            case RiSubCategoryType.Alerts:
                tabContent = (<RIPanelAlertSecondaryInfo />);
            default:
                break;
        }
        return tabContent;

    }

    getTabItem(item) {
        const isActive = item.SubCategory === this.props.activeTab;
        return (
            <li key={item.SubCategory}>
                <Button className={isActive ? "ri-btn active xx-small-bold" : "ri-btn xx-small-normal"} bsSize="small"
                    onClick={(e) => this.handleChange(e, item)}
                    onContextMenu={(event)=> this.openContextMenu(item, event)}
                >
                    {RIPanelTranslateHelper[item.Title]}
                </Button>
            </li>
        )
    }

    render() {
        return (
            <div ref={(ref) => (this.main = ref)} className=" CELL infoContainer">
                <div className="top-view-fixed">
                    <div className="panel-heading small-normal">{RIPanelTranslateHelper[this.props.mainTitle]}</div>
                    <div className="action-ripanel">
                        <nav id="spy">
                            <ul className="sidebar-nav nav rightB inline">
                                {map(this.props.tabCollection, (item) => this.getTabItem(item))}
                            </ul>
                        </nav>
                    </div>
                </div>
                <div>
                    {this.getSecondaryTabContent()}
                </div>
                <div className="infoContainer">
                    {this.getTabContent()}
                </div>
                {this.props.isAlertAboutOpen && <AboutDialog closeDialog={this.props.openAlertAboutDialog} />}
            </div>

        );
    }
}

const mapStateToProps = ({ RelatedInfoPanelReducers }) => {
    const { mainTitle, activeTab, tabCollection, isAlertAboutOpen } = RelatedInfoPanelReducers.RiPanelReducer;
    return {
        mainTitle, activeTab, tabCollection, isAlertAboutOpen
    };
}

const mapDispatchToProps = (dispatch) => ({
    handleTabChange: (newTab) => dispatch(handleTabChange(newTab)),
    openAlertAboutDialog: () => dispatch(openAlertAboutDialog(false)),
    showContextMenuAtPos: (selectedConextMenuOption, clientX, clientY) => dispatch(showContextMenuAtPos(selectedConextMenuOption, clientX, clientY, 0)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RelatedInfoBody);