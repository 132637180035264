import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import HttpMethodType from 'HttpMethodType';
class FinancialBlockRequest extends BaseServiceRequest {
  constructor(requestObj) {
    super();
    super.addRequestUri("findgetblocks2.pb");
    super.addRequestParameter("sym",requestObj.symbol);
    super.addRequestParameter("gtype",requestObj.periodicity);
    super.addRequestParameter("startdate",requestObj.startDate);

    if (requestObj.endDate !== null)
      super.addRequestParameter("enddate", requestObj.endDate);
    if (requestObj.selectCurrencyCode !== null && requestObj.selectCurrencyCode !== undefined) 
      super.addRequestParameter("scc", requestObj.selectCurrencyCode);
    if (requestObj.isMatchFinancials !== null && requestObj.isMatchFinancials !== undefined)
      super.addRequestParameter("ismf", requestObj.isMatchFinancials);
    super.addRequestParameter("iscf",requestObj.getFinancial);
    super.addRequestParameter("isown",requestObj.getOwnership);
    super.addRequestParameter("listid",requestObj.ownerListId);
    super.addRequestParameter("isflag",requestObj.isFlagged);
    super.addRequestParameter("iswon",requestObj.wonOnly);
    super.addRequestParameter("revwon",requestObj.revWonOnly);
    super.addRequestParameter("headers",requestObj.headers);
    super.addRequestParameter("productVersion", requestObj.ProductVersion);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return "FinancialBlockDataResponse";
  }
}

export default FinancialBlockRequest;