import { RIPanelOneilConstants } from "../Constants/RIPanelOneilConstants";
const { ActionTypes } = RIPanelOneilConstants;
import AppDispatcher from "AppDispatcher";
import { dispatch } from "../Redux/dispatch.js";

export const  populateRIPanelOneilInfo = (symbolType, SymbolInfo, isMiniListPlay) =>({
    type: ActionTypes.GET_RI_PANEL_ONEIL_INFO,
    symbolType: symbolType,
    SymbolInfo: SymbolInfo,
    isMiniListPlay: isMiniListPlay
});

export const  getHistoricalList = (historicalList) =>({
    type: ActionTypes.GET_HISTORICAL_LIST,
    historicalList: historicalList
});
export const getIdealistData = (ideasData) =>({
    type: ActionTypes.GET_IDEALIST_DATA,
    ideasData: ideasData
});

export const updateRiPanelSettingsData=(panelSettings) =>({
    type: ActionTypes.UPDATE_RI_PANEL_SETTINGS_DATA,
    panelSettings: panelSettings
});

export const updateLeaderFlagStatus=(item, isleaderflag) => ({
    type: ActionTypes.UPDATE_RI_LEADER_FLAG_STATUS,
    clickedItem: item,  isleaderflag
});

export const updateShowHistoricalFlag=(showHistorical) =>({
    type: ActionTypes.UPDATE_SHOW_HISTORICAL_FLAG,
    showHistorical: showHistorical
});

export const clearData = () =>({
    type: ActionTypes.CLEAR_RI_PANEL_ONEIL_INFO
});

export const updatePricePanelLeaderFlagStatus = () => {
    AppDispatcher.handleViewAction({
        actionType: ActionTypes.UPDATE_PRICE_PANEL_LEADER_FLAG_STATUS
      });
}
export const updatePricePanelShowHistoricalFlag = (showHistorical) => {
    AppDispatcher.handleViewAction({
        actionType: ActionTypes.UPDATE_PRICE_PANEL_SHOW_HISTORICAL_FLAG,
        data: showHistorical
      });
}

export const clearRIPanelData = () =>({
    type: ActionTypes.ON_CLEAR_RI_PANEL_ONEIL_DATA
});

export const updateContactType = (contactType) =>({
    type: ActionTypes.UPDATE_CONTACT_TYPE,
    contactType: contactType
});

export const updateEntitlementDialogFlag = (entitlementDialogFlag) =>({
    type: ActionTypes.UPDATE_ENTITLEMENT_DIALOG_FLAG,
    entitlementDialogFlag: entitlementDialogFlag
});

export const handleEntitlement = (obj) =>({
    type: ActionTypes.HANDLE_ENTITLEMENT,
    obj: obj
});

export const revieveQuoteToUpdateIdeas = (ideasData, SymbolInfo) =>({
    type: ActionTypes.RECIEVE_QUOTE,
    ideasData,
    SymbolInfo
});

export const updateEntitlementUserName= (userName, validationUserNameClass) =>({
    type: ActionTypes.UPDATE_ENTITLEMENT_USER_NAME,
    entitlmentUserName: userName,
    validationUserNameClass : validationUserNameClass
});

export const updateEntitlementUserEmail= (userEmail, validationUserEmailClass) =>({
    type: ActionTypes.UPDATE_ENTITLEMENT_USER_EMAIL,
    entitlmentUserEmail: userEmail,
    validationUserEmailClass: validationUserEmailClass
});

export const updateEntitlementUserPhone= (userPhone, validationUserPhoneClass) =>({
    type: ActionTypes.UPDATE_ENTITLEMENT_USER_PHONE,
    entitlmentUserPhone: userPhone,
    validationUserPhoneClass: validationUserPhoneClass
});

export const getFocusListData = () =>({
    type:ActionTypes.GET_ONEIL_FOCUS_LIST_DATA
})
