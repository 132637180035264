import React from "react";
import FundamentalTabSortOrderType from 'Constants/FundamentalTabSortOrderType.js';
import FundamentalTabPeriodicityType from 'Constants/FundamentalTabPeriodicityType.js';
import FundamentalsAnnualHeader from "./FundamentalsAnnualHeader.jsx";
import FundamentalsNonAnnualHeader from "./FundamentalsNonAnnualHeader.jsx";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import TabFundamentalsStore from 'Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js';
import DateHelper from "DateHelper";
import ScrollBar from "ScrollBar";
import { each } from "underscore";
import LocalizationStore from 'LocalizationStore';
import FormatterUtil from "FormatterUtil";
import FundamentalsActions from "Actions/FundamentalsActions.js";
import FundamentalsFrozonCell from "../FundamentalsTable/FundamentalsFrozonCell.jsx"
import FundamentalsTabularData from "./FundamentalsTabularData.jsx";
import SettingsStore from "SettingsStore";
import { SettingsConstants } from "Constants/SettingsConstants.js";

let topLeftDivHeight = 0;
let fundamentalPreviousScrollTop = 0;
let tableWrapperWidth = "100%";
let tablePadding = "0px";
let previousScrollLeft = 0;

export default class FundamentalsTable extends React.Component {
    constructor() {
        super();
        this.state = {
            highLightedRowIndex: null,
            isVisiblessb_h: false,
            shouldUpdate: false,
            tableWrapperWidth: "100%"
        }
        this.handleHorizontalScroll = this.handleHorizontalScroll.bind(this);
        this.handleTableScroll = this.handleTableScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.scaleValues = this.scaleValues.bind(this);
        this.frozenTable = false;
        this.preventEvent = false;
        this.rightActive = false;
        this.onMouseClick = this.onMouseClick.bind(this);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this.handleTableScroll = this.handleTableScroll.bind(this);
        this._frozenCell = new Map();
    }
    handleKeyPressed(e) {
        const state =  TabFundamentalsStore.getReportState();
        this.rightActive = state;
        let maxLen = this.props.ratiosData.FundamentalsRatiosList.length * 32;
        fundamentalPreviousScrollTop = fundamentalPreviousScrollTop ? fundamentalPreviousScrollTop : 0;
        const consoleSettings = SettingsStore.getConsoleSettings();
        const isCurrentKeyDown = !consoleSettings.KeyDownActiveZone || consoleSettings.KeyDownActiveZone === SettingsConstants.KeyDownActiveZoneType.FUNDAMENTAL_TAB;
        if(e.keyCode === 38 && isCurrentKeyDown && this.rightActive && fundamentalPreviousScrollTop >= 0) {
            fundamentalPreviousScrollTop = fundamentalPreviousScrollTop - 32;
        }
        if(e.keyCode === 40 && isCurrentKeyDown && this.rightActive && fundamentalPreviousScrollTop < maxLen) {
            fundamentalPreviousScrollTop = fundamentalPreviousScrollTop + 32;
        }
        if(this.rightActive){
            this.fundametalScrollDiv.scrollTop = fundamentalPreviousScrollTop;
                this.fundametalScrollid.scrollTop = fundamentalPreviousScrollTop;
                this.fundametalScrollDiv.scrollTop = fundamentalPreviousScrollTop;
            }
      }

    componentDidMount() {
        let headerElem = document.getElementsByClassName("fundamental-table-block-title")[0];
        if (this.props.isResizing == true) {
            tableWrapperWidth = headerElem.clientWidth - this.props.width;
        }
        if (this.state.tableWrapperWidth !== headerElem.clientWidth - this.props.width){
            tableWrapperWidth = headerElem.clientWidth - this.props.width;
            this.setState({tableWrapperWidth: tableWrapperWidth});
        }
        if (this.fundametalScrollid) this.fundametalScrollid.addEventListener('scroll', this.handleHorizontalScroll, false);
        const fundamentalTable = document.getElementById("fundamental-table");
        const isShowVscroll = fundamentalTable.offsetHeight < this.fundametalScrollid.offsetHeight ? false : true; 
        const isVisiblessb_h = fundamentalTable.offsetWidth < this.fundametalScrollid.offsetWidth - (isShowVscroll ? 18 : 0) ? false : true;
        if (isVisiblessb_h !== this.state.isVisiblessb_h) {
            this.setState({ isVisiblessb_h: isVisiblessb_h});
        }
        window.addEventListener('resize', this.handleResize, false);
    }
    UNSAFE_componentWillMount() {
        document.addEventListener("keydown", this.handleKeyPressed, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPressed, false);
        if (this.fundametalScrollid){
            this.fundametalScrollid.removeEventListener('scroll', this.handleHorizontalScroll, false);
        }
        window.removeEventListener('resize', this.handleResize, false);
    }  
    onMouseClick(){
        FundamentalsActions.setReportClickState(true);
        fundamentalPreviousScrollTop = this.fundametalScrollDiv.scrollTop;
    }

    UNSAFE_componentWillUpdate() {
        let headerElem = document.getElementsByClassName("fundamental-table-block")[0];
        if (this.props.isResizing == false) {
            let width = (headerElem.clientWidth - this.props.width);
            let difference = TabFundamentalsStore.getState().FundamentalResizeSettings.Width - this.props.width;
            if (difference != 0) {
                let percentageDifference = difference / (width / 100);
                let calculatedWidth = 100 + percentageDifference;
                tableWrapperWidth = calculatedWidth.toString() + "%";
            }
        }
    }

    componentDidUpdate() {
        window.setTimeout(() => {
            let el = document.querySelector(".fundamental-table-wrapper .ssb_h_sb");
            if (el) el.scrollLeft = previousScrollLeft;
        }, 0);
        let headerElem = document.getElementsByClassName("fundamental-table-block")[0];
        if (this.state.tableWrapperWidth !== headerElem.clientWidth - this.props.width){
            tableWrapperWidth = headerElem.clientWidth - this.props.width;
            this.setState({tableWrapperWidth: tableWrapperWidth});
        }
        const fundamentalTable = document.getElementById("fundamental-table");
        const isShowVscroll = fundamentalTable.offsetHeight < this.fundametalScrollid.offsetHeight ? false : true; 
        const isVisiblessb_h = fundamentalTable.offsetWidth < this.fundametalScrollid.offsetWidth - (isShowVscroll ? 18 : 0) ? false : true;
        if (isVisiblessb_h !== this.state.isVisiblessb_h) {
            this.setState({ isVisiblessb_h: isVisiblessb_h, shouldUpdate: false});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let headerElem = document.getElementsByClassName("fundamental-table-block-title")[0];
        if (this.props.isResizing == true) {
            tableWrapperWidth = headerElem.clientWidth - nextProps.width;
        }
    }
    handleResize(){
        this.setState({shouldUpdate: true});
    }
    roundUp(val, places) {
        return +(Math.round(val + "e+" + places) + "e-" + places);   
    } 

    // setNumberFormatter = (doubleValue, formatter, scale) => {
    //     let cellValue = '';

    //     switch (formatter) {
    //         case '#,##0%':
    //           doubleValue = (doubleValue * scale);
    //           cellValue = this.roundUp(doubleValue * scale, 0) + "%";
    //           break;

    //         case '#,##0':
    //           cellValue = Number(doubleValue).toLocaleString('en-US');
    //           break;
    //         case '(#,##0)':
    //           cellValue = numberFormatter("(#,##)", Math.abs(doubleValue));
    //           break;

    //         case '#,##0.00':
    //           cellValue = numberFormatter(formatter, doubleValue);
    //           break;
    //         case '#,##0.0%':
    //           let val = this.roundUp(doubleValue * scale, 1);
    //           cellValue = val == 0 ? "0.0%" : val + "%";
    //           break;
    //         default:
    //           cellValue = numberFormatter(formatter, doubleValue);
    //       }
    //       return cellValue;
    //     }

    setNumberFormatter(doubleValue, formatter, scale) {
        let cellValue = '';

        switch (formatter) {
            case '#,##0%':
                doubleValue = (doubleValue * scale);
                cellValue = this.roundUp(doubleValue * scale, 0) + "%";
                break;

            case '#,##0':
                cellValue = FormatterUtil.formatNumber(doubleValue);
                break;

            case '(#,##0)':
                cellValue = `(${FormatterUtil.formatNumber(Math.abs(doubleValue))})`;
                break;

            case '#,##0.00':
                cellValue = FormatterUtil.formatNumber(doubleValue, 2);
                break;

            case '#,##0.0%':
                let val = this.roundUp(doubleValue * scale, 1);
                cellValue = val == 0 ? "0.0%" : val + "%";
                break;

            default:
                cellValue = FormatterUtil.formatNumber(doubleValue);
                break;
        }
        const value = FormatterUtil.isNegetiveZeroValue(cellValue) ? cellValue.replace("-", "") : cellValue;
        return value;
    }

    getTermData(termDataValue, formatterString, scale = null) {
        try {
            let termData = "";


            if (termDataValue >= Number.MAX_VALUE || termDataValue == '' || termDataValue == null) return termData;

            if (termDataValue < 0 && termDataValue != -1) {
                termData = this.setNumberFormatter(Math.abs(termDataValue), formatterString, scale);
                return "-" + termData;
            }

            termData = this.setNumberFormatter(termDataValue, formatterString, scale);
            return termData;
        }
        catch (e) {
            console.log(e);
        }
    }

    GetTextForCategory(categoryType) {
        switch (categoryType) {
            case 1:
                return LocalizationStore.getTranslatedData("Ratis_VALUATION", "VALUATION");
            case 2:
                return LocalizationStore.getTranslatedData("Ratis_LIQUIDITY", "LIQUIDITY");
            case 3:
                return LocalizationStore.getTranslatedData("Ratis_ACTIVITY", "ACTIVITY");
            case 4:
                return LocalizationStore.getTranslatedData("Ratis_LEVERAGE", "LEVERAGE");
            case 5:
                return LocalizationStore.getTranslatedData("Ratis_PROFITABILITY", "PROFITABILITY");
            case 6:
                return LocalizationStore.getTranslatedData("Ratis_OTHER", "OTHER");
            default:
                return categoryType.ToString();
        }
    }

    setRatiosData(data) {
        let requiredKeys = []; let ratiosDetails = [];
        if (data.FundamentalsRatiosList) {
            const hdData = data.FundamentalsRatiosYearList.filter((message) => message.year === 0);
            let fundamentalsList = data.FundamentalsRatiosList;
            var arrCategory = [];
            for (let i = 0; i < fundamentalsList.length; i++) {
                let rowData = fundamentalsList[i];
                if (arrCategory.indexOf(rowData.categoryType) == -1) {
                    let categoryType = [];
                    let categoryHeader = this.GetTextForCategory(rowData.categoryType);
                    categoryType.push(categoryHeader);
                    for (let i = 0; i < data.FundamentalsRatiosYearList.length; i++) {
                        categoryType.push("");
                    }
                    if (hdData.length > 0) {
                        categoryType.splice(requiredKeys.length - hdData.length);
                    }
                    ratiosDetails.push(categoryType);
                    arrCategory.push(rowData.categoryType);
                }
                if (rowData.TermDataArray && rowData.TermDataArray.length > 0) {
                    if (rowData.description) requiredKeys.push(rowData.description)
                    each(rowData.TermDataArray, (termdata) => {
                        let value = null;
                        let tData = this.getTermData(termdata, rowData.FormatterString, rowData.scale);
                        if (tData != null && tData != '') {
                            value = tData;
                        } else {
                            value = "--";
                        }
                        requiredKeys.push(value);
                    });
                } else {
                    for (let key in rowData) {
                        if (key.substring(0, 11) === ("description")) {
                            let cellvalue = rowData[key.toString()];
                            requiredKeys.push(cellvalue);
                        }
                        else if (key.substring(0, 8) === ("termdata") && key.substring(0, 9) != ("termdata8")) {
                            let value = null;
                            let tData = this.getTermData(rowData[key], rowData.FormatterString, rowData.scale);
                            if (tData != null && tData != '') {
                                value = tData;
                            } else {
                                value = "--";
                            }
                            requiredKeys.push(value);
                        }
                        else if (key.substring(0, 9) == ("termdata8") && this.props.settings.FundamentalPeriodicity == FundamentalTabPeriodicityType.FundamentalsQuarterly) {
                            let value = null;
                            let tData = this.getTermData(rowData[key], rowData.FormatterString, rowData.scale);
                            if (tData != null && tData != '') {
                                value = tData;
                            } else {
                                value = "--";
                            }
                            requiredKeys.push(value);
                        }
                    }
                }

                if (hdData.length > 0) {
                    requiredKeys.splice(requiredKeys.length - hdData.length);
                }

                if (this.props.settings.FundamentalSortOrder == FundamentalTabSortOrderType.Descending) {
                    requiredKeys = this.reverseRatiosData(requiredKeys);
                }
                ratiosDetails.push(requiredKeys);
                requiredKeys = [];
            }
        }
        return ratiosDetails;
    }

    scaleValues(rowData, key) {
        let value;
        if (rowData && rowData.FormatterString.indexOf("%") != -1) {
            let calcValue = parseFloat(rowData[key] * rowData.scale).toFixed(1);
            value = calcValue.toString() + "%";
        } else {
            value = parseFloat(rowData[key]).toFixed(2);
        }
        return value;
    }

    reverseRatiosData(report) {
        let firstElement = report[0];
        report.splice(0, 1);
        report.reverse();
        report.unshift(firstElement);
        return report;
    }

    handleHorizontalScroll(e) {
        let scrollLeft = e.target.scrollLeft;
        document.getElementById('fundamental-thead').style.left = "-" + scrollLeft + "px";
        e.stopPropagation();
        e.preventDefault();
    }

    handleTableScroll(e) {
        if (this.preventEvent) {
            this.preventEvent = false;
            return;
        }
        if (e.target && e.target.classList.contains("ratioHeightChangeUpdate")) {
            let top = e.target.scrollTop;
            document.getElementById('fundamental-scroll').scrollTop = top;
            document.getElementById('fundamental-table').scrollTop = top;
            this.preventEvent = false;
            this.frozenTable = true;
        }
        else if (e.target) {
            let top = e.target.scrollTop;
            previousScrollLeft = e.target.scrollLeft;
            //if (fundamentalPreviousScrollTop == top) document.getElementById('fundamental-thead').style.left = -e.target.scrollLeft + 'px';
            fundamentalPreviousScrollTop = top;
            if (!this.frozenTable) {
                this.preventEvent = true;
                this.fundametalScrollDiv.scrollTop = top;
            }
            this.frozenTable = false;
        }

    }

    getRatiosType(categoryType) {
        let ratiosType = "";
        switch (categoryType) {
            case 1: ratiosType = "VALUATION"; break;
            case 2: ratiosType = "LIQUIDITY"; break;
            case 3: ratiosType = "ACTIVITY"; break;
            case 4: ratiosType = "LEVERAGE"; break;
            case 5: ratiosType = "PROFITABILITY"; break;
            case 6: ratiosType = "OTHER"; break;
            default: break;
        }
        return ratiosType;
    }

    syncHover(index, hover) {
        const frozen = this._frozenCell.get(index);
        if(frozen){
            frozen.onHover(hover);
        }
        this.tabularDataTable.onHover(index, hover);
    }

    handleFrozenTableScroll(e) {
        let top = e.target.scrollTop;
        document.getElementById('fundamental-table').style.top = -top + 'px';
        document.getElementById('fundamental-scroll').scrollTop = top;
    }

    getfundamentalHeader() {
        let fundamentalHeader = undefined;
        if (this.props.settings.FundamentalPeriodicity === FundamentalTabPeriodicityType.FundamentalsAnnual) {
            topLeftDivHeight = 33;
            fundamentalHeader = <FundamentalsAnnualHeader headerData={this.props.ratiosData} settings={this.props.settings} handleSortOrderChange={this.props.handleSortOrderChange} width={this.props.width} isLoading={this.props.isLoading} />
        }
        else {
            topLeftDivHeight = 46;
            fundamentalHeader = <FundamentalsNonAnnualHeader headerData={this.props.ratiosData} headerdata={this.props.headerData} settings={this.props.settings} handleSortOrderChange={this.props.handleSortOrderChange} width={this.props.width} isLoading={this.props.isLoading} />
        }
        return fundamentalHeader;
    }

    getClassNameForSortButton() {
        let className = "icn-sort-fundamental";
        if (this.props.settings.FundamentalSortOrder == FundamentalTabSortOrderType.Descending) {
            className = "icn-sort-fundamental-reverse";
        }
        return className;
    }

    getYearEnding() {
        let yearLabel = "";
        if (DatagraphStore.getState().SymbolInfo.FiscalMonthEnd) {
            let month = DateHelper.getMonthbyNumber(DatagraphStore.getState().SymbolInfo.FiscalMonthEnd);
            month = LocalizationStore.getTranslatedData('cal_' + month, month);
            yearLabel = LocalizationStore.getTranslatedData("fr_ForyearEnding", "For Year ending in {0}", month);
        } else {
            yearLabel = "";
        }
        return yearLabel;
    }

    render() {
        let ratios = undefined;
        let headers = undefined;
        let settings = this.props.settings;
        let width = this.props.width;
        let yearEnding = this.getYearEnding(this.props.settings);
        // let isVisiblessb_h = true;
        // if (document.querySelector(".fundamental-table-wrapper .ssb_h")) {
        //     isVisiblessb_h = document.querySelector(".fundamental-table-wrapper .ssb_h").style.display == 'none' ? false : true;
        // }
        if (this.props.ratiosData.FundamentalsRatiosList) {
            ratios = this.setRatiosData(this.props.ratiosData);
        }
        if (settings.FundamentalPeriodicity == FundamentalTabPeriodicityType.FundamentalsQuarterly || settings.FundamentalPeriodicity == FundamentalTabPeriodicityType.FundamentalsSemiAnnual) { tablePadding = "13px"; } else { tablePadding = "0px"; }
        if (ratios != undefined) {
            headers = this.getfundamentalHeader();
        }
        return (<div className="fundamental-table">
            <div className="ratioHeightChangeUpdate" ref={(div) => { this.fundametalScrollDiv = div }} onScroll={this.handleTableScroll} style={{ position: 'absolute', overflow: 'auto', height: '100%', width: '100%' }}>
                <table>
                    <thead>{this.props.isLoading == false && this.props.ratiosData != null ? <tr><td className="sort-title medium-bold " style={{ width: this.props.width, height: topLeftDivHeight }}>{yearEnding}<span className={this.getClassNameForSortButton()} onClick={this.props.handleSortOrderChange}></span></td></tr> : ""}</thead>
                </table>
                <table id="fundamental-frozen-table" style={{ position: 'relative' }}>
                    <tbody style={{ marginTop: topLeftDivHeight }}>
                        {ratios ? ratios.map((report, index) => {
                            const frozenRef = `frozen${index}`;
                            return <FundamentalsFrozonCell
                                syncHover={(itemIndex, hover) => this.syncHover(itemIndex, hover)}
                                onMouseClick={this.onMouseClick}
                                key={frozenRef}
                                index={index}
                                width={width}
                                report={report}
                                settings={this.props.settings}
                                ref={(ref) => this._frozenCell.set(index, ref)} />;
                        }) : undefined
                        }
                        {this.state.isVisiblessb_h && <tr style={{height: "18px"}}/>}
                    </tbody>
                 </table>
            </div>
            <div className="fundamental-main-head" style={{ left: this.props.width, width: this.state.tableWrapperWidth, height: topLeftDivHeight }}>
                <div id="fundamental-thead" style={{ width: "100%", height: "100%", position: 'relative' }} >
                    <table>
                        <thead id="t_head">{headers}</thead>
                    </table>
                </div>
            </div>
            <div className="fundamental-table-wrapper" onScroll={this.handleTableScroll} style={{ left: this.props.width, position: 'relative', width: this.state.tableWrapperWidth, paddingBottom: tablePadding }}>
                <div className="custom-scroll">
                    <div id='fundamental-scroll' ref={(div) => { this.fundametalScrollid = div }}>
                        <div className="fundamental-table-inner-wrapper" style={{ width: "100%", height: "100%" }} >
                                { ratios ? <FundamentalsTabularData
                                    syncHover={(index, hover) => this.syncHover(index, hover)}
                                    onMouseClick={this.onMouseClick}
                                    reportDetails={ratios}
                                    settings={this.props.settings}
                                    ref={(ref) => { this.tabularDataTable = (ref) }}
                                /> : undefined}
                        </div>
                        <ScrollBar scrollId='fundamental-scroll' vScroll={true} hScroll={true} />
                    </div></div></div></div>);
    }
}
