import React, { Component } from 'react'
class DragAndDrop extends Component { 
  constructor() {
    super();
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragIn = this.handleDragIn.bind(this);
    this.handleDragOut = this.handleDragOut.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.dragCounter = 0;
  }
  state = {
    drag: false
  }
  dropRef = React.createRef()
  handleDrag(e) {
    e.preventDefault()
    e.stopPropagation()
  }
  handleDragIn(e) {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    // if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({drag: true})
      if (document.getElementsByClassName('external-data-Uploader')[0]) document.getElementsByClassName('external-data-Uploader')[0].classList.add("stop-scroll");
    // }
  }
  handleDragOut(e) {
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
      if (document.getElementsByClassName('external-data-Uploader')[0]) document.getElementsByClassName('external-data-Uploader')[0].classList.remove("stop-scroll");
    }
  }
  handleDrop(e) {
    // e.dataTransfer.setData('text', e.target.textContent);
    e.preventDefault()
    e.stopPropagation()
    this.setState({drag: false})
    if (document.getElementsByClassName('external-data-Uploader')[0]) document.getElementsByClassName('external-data-Uploader')[0].classList.remove("stop-scroll");
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      this.dragCounter = 0    
    }
  }
  componentDidMount() {
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }
  render() {
    return (
      <div className="ext-drag-drop-catch" ref={this.dropRef} style={{zIndex:this.state.drag ? '1':'0'}}>   
        {this.state.drag &&
          <div className="ext-drag-drop-holder">
            <div className="ext-drag-drop-msg">
              <div className="ext-drag-drop-text">Drop file here</div>
            </div>
          </div>
         }
        {this.props.children}
      </div>
    )
  }
}
export default DragAndDrop