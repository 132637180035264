import GraphicPoints from '../../../../Models/AnnotationModels/GraphicsPoints';
import { PrintMode } from '../../../../print/printmode';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import { safe } from '../../../ErrorModel';
import { ShareAccessType } from '../../../../Constants/ShareConstants';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { AnnotationConstant, TemporaryId } from "Constants/AnnotationConstants.js";
import {
    getAnnotationIsDrawing, getAnnotationChartDraw, getAnnotationSelectedId, getRectangle,
    getAnnotationMenu, getTargetLayer, getActiveAnchor, getLayersList, getAnnotationDimension, getAnnotationSelected
} from '../../../../Reducers/AnnotationReducers/selectors';
import { takeLatest, select, put, call, takeEvery } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;


/********************** Model Logic *************************/

function* handleClick({ e }) {
    try {
        let rectangle = null;
        const annotationMenu = yield select(getAnnotationMenu);
        let isDrawing = yield select(getAnnotationIsDrawing);
        let annotSelectedId = yield select(getAnnotationSelectedId);
        const rectangles = yield select(getRectangle);;
        const targetLayer = yield select(getTargetLayer);

        if (isDrawing) {
            isDrawing = !isDrawing
            rectangle = rectangles.find((itm) => itm.id === annotSelectedId);
            let dir = '';
            if (rectangle.x < rectangle.endMouseX && rectangle.y > rectangle.endMouseY) {
                dir = 'NE';
            } else if (rectangle.x > rectangle.endMouseX && rectangle.y < rectangle.endMouseY) {
                dir = 'SW';
            } else if (rectangle.x > rectangle.endMouseX && rectangle.y > rectangle.endMouseY) {
                dir = 'NW';
            } else {
                dir = 'SE';
            }
            rectangle = Object.assign(rectangle, {
                direction: dir
            })
            yield call(updateRectangle, isDrawing, rectangle);
            yield safe(call(saveRectangle), "GraphicsRectangle.js", "saveRectangle");

            return;
        }

        if (e.target !== e.target.getStage()) {
            annotSelectedId = e.target.id();
            rectangle = rectangles.find((itm) => itm.id === annotSelectedId);
            yield call(updateRectangle, isDrawing, rectangle);
            return;
        }
        const points = new GraphicPoints();
        const annotId = TemporaryId.Id;

        rectangle = {
            ...annotationMenu.ShapesModelInfo,
            id: annotId,
            layerID: targetLayer.layerID,
            type: RayAnnotationType.RECT_AnnotationType,
            x: e.evt.layerX,
            y: e.evt.layerY,
            endMouseX: 0,
            endMouseY: 0,
            points: points,
            width: 0,
            height: 0,
            isEditable: false,
            isReadOnlyAnnotation: false
        }
        isDrawing = true;

        yield call(addRectangle, isDrawing, rectangle);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsRectangle.js, handleClick ${error}`);
    }
}

function* handleMouseMove({ e }) {
    try {
        let rectangle = null;
        const rectangles = yield select(getRectangle);
        const isDrawing = yield select(getAnnotationIsDrawing);
        const annotSelectedId = yield select(getAnnotationSelectedId);

        if (isDrawing && rectangles.length > 0) {
            const rectShape = rectangles.find((itm) => itm.id === annotSelectedId);
            const newWidth = e.evt.layerX - rectShape.x;
            const newHeight = e.evt.layerY - rectShape.y;
            rectangle = Object.assign(rectShape, {
                x: rectShape.x,
                y: rectShape.y,
                width: Math.abs(newWidth),
                height: Math.abs(newHeight),
                endMouseX: e.evt.layerX,
                endMouseY: e.evt.layerY,
            })
        }
        yield call(updateRectangle, isDrawing, rectangle);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsRectangle.js, handleMouseMove ${error}`);
    }
}

function* addRectangle(isDrawing, rectangle) {
    try {
        yield put({
            type: ActionTypes.HANDLE_RECTANGLE_CLICK_SUCCESS,
            rectangle: rectangle,
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: rectangle
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsRectangle.js, addRectangle ${error}`);
    }
}

function* updateRectangle(isDrawing, rectangle) {
    try {
        yield put({
            type: ActionTypes.HANDLE_RECTANGLE_DRAW_SUCCESS,
            rectangle: rectangle,
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: rectangle
        });

        let layersList = yield select(getLayersList);
        layersList = JSON.parse(JSON.stringify(layersList));
        const selectedAnnot = yield select(getAnnotationSelected);
        for (const x in layersList) {
            layersList[x].layerID === selectedAnnot.layerID ? layersList.push(layersList.splice(x, 1)[0]) : 0;
        }
        yield put({
            type: ActionTypes.UPDATE_LAYERS_LIST,
            LayersList: layersList
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsRectangle.js, updateRectangle ${error}`);
    }
}

function* saveRectangle() {
    yield put({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    })
}

function* updateRectangleFromApi({ rectangle, isApiCall = true }) {
    try {
        if (isApiCall) {
            yield put({
                type: ActionTypes.HANDLE_RECTANGLE_CLICK_SUCCESS,
                rectangle: rectangle
            });
        }
        else {
            yield put({
                type: ActionTypes.HANDLE_RECTANGLE_DRAW_SUCCESS,
                rectangle: rectangle
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsRectangle.js, updateRectangleFromApi ${error}`);
    }
}

function* processRectangleData({ data }) {
    try {
        let newWidth = data.endPoint.x - data.startPoint.x;
        if (data.endPoint.x <= data.startPoint.x) {
            newWidth = data.startPoint.x - data.endPoint.x;
        }
        let newHeight = data.endPoint.y - data.startPoint.y;
        if (data.endPoint.y <= data.startPoint.y) {
            newHeight = data.startPoint.y - data.endPoint.y;
        }
        const convertedBackgroundColor = ThemeHelper.convertArgbToHex(data.backgroundColor);
        const convertedForegroundColor = ThemeHelper.convertArgbToHex(data.foregroundColor);
        let dir = '';
        if (data.startPoint.x < data.endPoint.x && data.startPoint.y > data.endPoint.y) {
            dir = 'NE';
        } else if (data.startPoint.x > data.endPoint.x && data.startPoint.y < data.endPoint.y) {
            dir = 'SW';
        } else if (data.startPoint.x > data.endPoint.x && data.startPoint.y > data.endPoint.y) {
            dir = 'NW';
        } else {
            dir = 'SE';
        }
        const layersList = yield select(getLayersList);
        const isReadOnlyAnnotation = layersList.filter((i) => i.layerID === data.layerID)[0].shareAccess === ShareAccessType.SHARE_READONLY;

        const rectangle = {
            id: data.annotationID,
            layerID: data.layerID,
            type: data.annotationType,
            x: data.startPoint.x,
            y: data.startPoint.y,
            width: newWidth,
            height: newHeight,
            borderColor: `P${convertedForegroundColor.color.substring(1)}`,
            borderWeight: data.penSize,
            bgColor: `P${convertedBackgroundColor.color.substring(1)}`,
            opacity: convertedBackgroundColor.opacity,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            isDeleted: false,
            strokeStyle: data.strokeType,
            endMouseX: data.endPoint.x,
            endMouseY: data.endPoint.y,
            isBorderChecked: data.isBorderVisible,
            direction: dir,
            isReadOnlyAnnotation: isReadOnlyAnnotation
        }

        yield put({
            type: ActionTypes.HANDLE_RECTANGLE_CLICK_SUCCESS,
            rectangle: rectangle
        });
        yield put({
            type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
            annotation: rectangle.id
        });

        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsRectangle.js, processRectangleData ${error}`);
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        PrintMode.printing && console.log("PRINT:--Tracker--*** Data processing Error in GraphicsRectangle *** for -", TimeTrackingWindow.currentSymbol);
    }
}

function* handleRectDrag({ e }) {
    try {
        const { isDragging } = yield select(getAnnotationChartDraw);
        const chartDimension = yield select(getAnnotationDimension);
        let rect = null;
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const rects = yield select(getRectangle);
        const curRect = rects.find((itm) => itm.id === annotSelectedId); // current rectangle to be modified
        let rectStart = {}, rectEnd = {};
        const dragPos = {
            x: e.currentTarget.x(), y: e.currentTarget.y()
        }
        if (isDragging) {
            // Fix for PANWEB-6190
            if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                e.currentTarget.attrs.x = curRect.x;
                e.currentTarget.attrs.y = curRect.y;
                return;
            }
            else if (curRect.x < curRect.endMouseX && curRect.y < curRect.endMouseY) {
                rectStart = {
                    x: dragPos.x, y: dragPos.y
                }
                rectEnd = {
                    x: dragPos.x + curRect.width, y: dragPos.y + curRect.height
                }
            } else if (curRect.x < curRect.endMouseX && curRect.y > curRect.endMouseY) {
                rectStart = {
                    x: dragPos.x, y: dragPos.y + curRect.height
                }
                rectEnd = {
                    x: dragPos.x + curRect.width, y: dragPos.y
                }
            } else if (curRect.x > curRect.endMouseX && curRect.y < curRect.endMouseY) {
                rectStart = {
                    x: dragPos.x + curRect.width, y: dragPos.y
                }
                rectEnd = {
                    x: dragPos.x, y: dragPos.y + curRect.height
                }
            } else {
                rectStart = {
                    x: dragPos.x + curRect.width, y: dragPos.y + curRect.height
                }
                rectEnd = {
                    x: dragPos.x, y: dragPos.y
                }
            }
        }

        rect = Object.assign(curRect, {
            x: rectStart.x,
            y: rectStart.y,
            endMouseX: rectEnd.x,
            endMouseY: rectEnd.y
        });
        yield call(updateRectangle, false, rect);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsRectangleSaga.js, handleRectDrag ${error}`);
    }
}
function* handleRectAnchorDrag({ e }) {
    try {
        const chartDimension = yield select(getAnnotationDimension);

        // Fix for PANWEB-6207
        if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
            return;
        }

        const annotSelectedId = yield select(getAnnotationSelectedId);
        const rects = yield select(getRectangle);
        const activeAnchor = yield select(getActiveAnchor);
        let rect = null;
        let rectEnd = null;
        let rectStart = null;
        const curRect = rects.find((itm) => itm.id === annotSelectedId);
        if (activeAnchor === "top-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
            }
            switch (curRect.direction) {
                case 'NW':
                    rectStart = { x: e.evt.layerX, y: curRect.y }
                    rectEnd = { x: curRect.endMouseX, y: e.evt.layerY }
                    break;
                case 'NE':
                    rectStart = { x: curRect.x, y: curRect.y }
                    rectEnd = { x: e.evt.layerX, y: e.evt.layerY }
                    break;
                case 'SW':
                    rectStart = { x: e.evt.layerX, y: e.evt.layerY }
                    rectEnd = { x: curRect.endMouseX, y: curRect.endMouseY }
                    break;
                case 'SE':
                    rectStart = { x: curRect.x, y: e.evt.layerY }
                    rectEnd = { x: e.evt.layerX, y: curRect.endMouseY }
                    break;

                default:
                    break;
            }
        }
        if (activeAnchor === "top-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
            }
            switch (curRect.direction) {
                case 'NW':
                    rectStart = { x: curRect.x, y: curRect.y }
                    rectEnd = { x: e.evt.layerX, y: e.evt.layerY }
                    break;
                case 'NE':
                    rectStart = { x: e.evt.layerX, y: curRect.y }
                    rectEnd = { x: curRect.endMouseX, y: e.evt.layerY }
                    break;
                case 'SW':
                    rectStart = { x: curRect.x, y: e.evt.layerY }
                    rectEnd = { x: e.evt.layerX, y: curRect.endMouseY }
                    break;
                case 'SE':
                    rectStart = { x: e.evt.layerX, y: e.evt.layerY }
                    rectEnd = { x: curRect.endMouseX, y: curRect.endMouseY }
                    break;

                default:
                    break;
            }
        }
        if (activeAnchor === "bottom-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
            }
            switch (curRect.direction) {
                case 'NW':
                    rectStart = { x: curRect.x, y: e.evt.layerY }
                    rectEnd = { x: e.evt.layerX, y: curRect.endMouseY }
                    break;
                case 'NE':
                    rectStart = { x: e.evt.layerX, y: e.evt.layerY }
                    rectEnd = { x: curRect.endMouseX, y: curRect.endMouseY }
                    break;
                case 'SW':
                    rectStart = { x: curRect.x, y: curRect.y }
                    rectEnd = { x: e.evt.layerX, y: e.evt.layerY }
                    break;
                case 'SE':
                    rectStart = { x: e.evt.layerX, y: curRect.y }
                    rectEnd = { x: curRect.endMouseX, y: e.evt.layerY }
                    break;

                default:
                    break;
            }
        }
        if (activeAnchor === "bottom-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
            }
            switch (curRect.direction) {
                case 'NW':
                    rectStart = { x: e.evt.layerX, y: e.evt.layerY }
                    rectEnd = { x: curRect.endMouseX, y: curRect.endMouseY }
                    break;
                case 'NE':
                    rectStart = { x: curRect.x, y: e.evt.layerY }
                    rectEnd = { x: e.evt.layerX, y: curRect.endMouseY }
                    break;
                case 'SW':
                    rectStart = { x: e.evt.layerX, y: curRect.y }
                    rectEnd = { x: curRect.endMouseX, y: e.evt.layerY }
                    break;
                case 'SE':
                    rectStart = { x: curRect.x, y: curRect.y }
                    rectEnd = { x: e.evt.layerX, y: e.evt.layerY }
                    break;

                default:
                    break;
            }
        }
        if (activeAnchor === "top-middle") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ns-resize';
            }
            switch (curRect.direction) {
                case 'NW':
                case 'NE':
                    rectStart = { x: curRect.x, y: curRect.y }
                    rectEnd = { x: curRect.endMouseX, y: e.evt.layerY }
                    break;
                case 'SW':
                case 'SE':
                    rectStart = { x: curRect.x, y: e.evt.layerY }
                    rectEnd = { x: curRect.endMouseX, y: curRect.endMouseY }
                    break;
                default:
                    break;
            }
        }
        if (activeAnchor === "bottom-middle") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ns-resize';
            }
            switch (curRect.direction) {
                case 'NW':
                case 'NE':
                    rectStart = { x: curRect.x, y: e.evt.layerY }
                    rectEnd = { x: curRect.endMouseX, y: curRect.endMouseY }
                    break;
                case 'SW':
                case 'SE':
                    rectStart = { x: curRect.x, y: curRect.y }
                    rectEnd = { x: curRect.endMouseX, y: e.evt.layerY }
                    break;
                default:
                    break;
            }
        }
        if (activeAnchor === "middle-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ew-resize';
            }
            switch (curRect.direction) {
                case 'NW':
                case 'SW':
                    rectStart = { x: curRect.x, y: curRect.y }
                    rectEnd = { x: e.evt.layerX, y: curRect.endMouseY }
                    break;
                case 'NE':
                case 'SE':
                    rectStart = { x: e.evt.layerX, y: curRect.y }
                    rectEnd = { x: curRect.endMouseX, y: curRect.endMouseY }
                    break;
                default:
                    break;
            }
        }
        if (activeAnchor === "middle-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ew-resize';
            }
            switch (curRect.direction) {
                case 'NW':
                case 'SW':
                    rectStart = { x: e.evt.layerX, y: curRect.y }
                    rectEnd = { x: curRect.endMouseX, y: curRect.endMouseY }
                    break;
                case 'NE':
                case 'SE':
                    rectStart = { x: curRect.x, y: curRect.y }
                    rectEnd = { x: e.evt.layerX, y: curRect.endMouseY }
                    break;
                default:
                    break;
            }
        }
        let newWidth = rectEnd.x - rectStart.x;
        if (rectEnd.x <= rectStart.x) {
            newWidth = rectStart.x - rectEnd.x;
        }
        let newHeight = rectEnd.y - rectStart.y;
        if (rectEnd.y <= rectStart.y) {
            newHeight = rectStart.y - rectEnd.y;
        }
        rect = Object.assign(curRect, {
            x: rectStart.x,
            y: rectStart.y,
            endMouseX: rectEnd.x,
            endMouseY: rectEnd.y,
            id: curRect.id,
            width: Math.abs(newWidth),
            height: Math.abs(newHeight)
        });
        yield call(updateRectangle, false, rect);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsRectangle.js, handleRectAnchorDrag ${error}`);
    }
}
function* handleAnchorLeave() {
    try {
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const rectangles = yield select(getRectangle);;
        let rectangle = null;
        rectangle = rectangles.find((itm) => itm.id === annotSelectedId);
        let dir = '';
        if (rectangle.x < rectangle.endMouseX && rectangle.y > rectangle.endMouseY) {
            dir = 'NE';
        } else if (rectangle.x > rectangle.endMouseX && rectangle.y < rectangle.endMouseY) {
            dir = 'SW';
        } else if (rectangle.x > rectangle.endMouseX && rectangle.y > rectangle.endMouseY) {
            dir = 'NW';
        } else {
            dir = 'SE';
        }
        rectangle = Object.assign(rectangle, {
            direction: dir
        })
        yield call(updateRectangle, false, rectangle);
        yield safe(call(saveRectangle), "GraphicsRectangle.js", "saveRectangle");
    }
    catch (error) {
        console.log(`Error occurs in GraphicsRectangle.js, handleAnchorLeave ${error}`);
    }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/


export function* watchHandleRectangleClick() {

    yield takeLatest(ActionTypes.HANDLE_RECTANGLE_CLICK, handleClick);
};

export function* watchHandleRectangleDraw() {
    yield takeLatest(ActionTypes.HANDLE_RECTANGLE_DRAW, handleMouseMove);
};

export function* watchProcessRectangleData() {
    yield takeEvery(ActionTypes.PROCESS_RECTANGLE_DATA, processRectangleData);
};

export function* watchRectDrag() {
    yield takeLatest(ActionTypes.HANDLE_RECTANGLE_DRAG, handleRectDrag)
}

export function* watchRectAnchordrag() {
    yield takeLatest(ActionTypes.HANDLE_RECT_ANCHOR_DRAG, handleRectAnchorDrag)
}

export function* watchRectAnchorLeave() {
    yield takeLatest(ActionTypes.HANDLE_RECT_ANCHOR_LEAVE, handleAnchorLeave);
};

/******************************************************************************/