import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class AnnotationLayerUndoRequest extends BaseServiceRequest {
    constructor(layerId) {
        super();
        super.addRequestUri("annotationlayer.pb");
        super.addRequestParameter("lid", layerId);
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "Response";
    }

}
