import React, { PureComponent } from "react";
import { TextInput,CustomToggle } from '../Presentational';
import { connect } from 'react-redux';
import { Range, Handle } from "rc-slider";
import {updateDataFilterRange,updateActiveSlider,updateSelectedRatingFilterType} from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { Button, ButtonGroup, ButtonToolbar, Overlay, Dropdown, MenuItem } from "react-bootstrap";
import FormatterUtil from "FormatterUtil";
import numeral from "numeral";
import { isEmpty, isEqual, map, isString } from "underscore";
// import SettingsWindow from '../Presentational'
// import CompareTabType from "CompareTabType";
import LocalizationStore from 'LocalizationStore';
// import onClickOutside from "react-onclickoutside";

class RangeSlider extends PureComponent {

  constructor(props) {
    super(props);
    this.getClosetIndex = this.getClosetIndex.bind(this);
    this.getTextTitle = this.getTextTitle.bind(this);
    this.statesData = this.props.statesData;
  }



  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.statesData, this.statesData)) {
      this.statesData = nextProps.statesData;
    }
  }

  updateFilter = (value, dataValueLow, dataValueHigh, id) => {
    this.props.updateDataFilterRange(value, dataValueLow, dataValueHigh, id);
  };

  getFormattedNumber = (numberValue) => FormatterUtil.formatNumber(numberValue);

  getClosetIndex = (num) => {

    let closetNumber = this.statesData.intervalData.reduce((prev, curr) =>
      (Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev)
    );

    return this.statesData.intervalData.indexOf(closetNumber) != -1 ? this.statesData.intervalData.indexOf(closetNumber) : 0;

  };

  getTextTitle = () => {
    if (this.statesData.filterType == "value") {
      return <p>{this.statesData.title}</p>
    }
    else {
      return <div className="rating-filter-text">
        <Dropdown id="ranting-custom-drop-down">
          <CustomToggle bsRole="toggle">
            {this.statesData.selectedRatingFilterTypeOption.description}
          </CustomToggle>
          <Dropdown.Menu className="rating-custom-drop-down">
            <div className="rating-filter-text small-bold cap cursor-default" style={{ padding: 0 }}>{LocalizationStore.getTranslatedData("CCG_SELECTAFILTER","SELECT A FILTER")}</div>
            <MenuItem divider />
            {map(this.statesData.ratingFilterTypeOptions, (option, key) => {
              let classNameMenu = "xx-small-normal ";
              classNameMenu += option.isEnabled ? "" : " disabled";
              return <MenuItem key={key} eventKey={key} className={classNameMenu} onClick={() => option.isEnabled ? this.props.updateSelectedRatingFilterType(key, this.statesData.id) : null}><span className={option.isSelected ? "icn-right-icon" : ""}></span>{option.description}</MenuItem>
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    }
  };


  updateLowRangeSlider = (valText) => {
    if (valText === "" || valText === "-") {
      valText = this.statesData.minValueText;
    }
    if (valText !== "" && valText !== "-") {
      let val = numeral(valText).value();
      val = val > this.statesData.highRangeValue ? this.statesData.highRangeValue : val < this.statesData.minValueText ? this.statesData.minValueText : val;
      let lowSliderRangeValueIndex = this.getClosetIndex(val);
      /* Fixes - PANWEB-2374 */
      if(this.statesData.minValueText < val && lowSliderRangeValueIndex === 0) {
        lowSliderRangeValueIndex = 1;
      }
      this.updateFilter([lowSliderRangeValueIndex, this.statesData.highSliderRangeValueIndex], val, this.statesData.highRangeValue, this.statesData.id)
    }
  }

  updateHighRangeSlider = (valText) => {
    if (valText === "" || valText === "-") {
      valText = this.statesData.minValueText;
    }
    if (valText !== "" && valText !== "-") {
      let val = numeral(valText).value();
      val = val < this.statesData.lowRangeValue ? this.statesData.lowRangeValue : val > this.statesData.maxValueText ? this.statesData.maxValueText : val;
      let highSliderRangeValueIndex = this.getClosetIndex(val);
      /* Fixes - PANWEB-2374 */
      if(this.statesData.intervalData && this.statesData.maxValueText > val && highSliderRangeValueIndex === (this.statesData.intervalData.length - 1)) {
        highSliderRangeValueIndex = highSliderRangeValueIndex - 1;
      }
      this.updateFilter([this.statesData.lowSliderRangeValueIndex, highSliderRangeValueIndex], this.statesData.lowRangeValue, val, this.statesData.id)
    }
  }

  handleClickOfHandle = (index) => {
    let id = this.statesData.id;
    this.props.updateActiveSlider({index, id});
  }

  render() {
    //console.log("states data:",this.statesData);
    const rangeValue = [this.statesData.lowSliderRangeValueIndex, this.statesData.highSliderRangeValueIndex];
    let lqFilterLineSliderClass = (this.statesData.highSliderRangeValueIndex == this.statesData.maxRangeSliderValue && this.statesData.minRangeSliderValue == this.statesData.lowSliderRangeValueIndex) ? "lqFilterLineSlider lqFilterLineSliderDisable " : "lqFilterLineSlider ";

    lqFilterLineSliderClass += !this.props.isFilterOn && !this.props.isFilterManualOff && !(this.statesData.highSliderRangeValueIndex == this.statesData.maxRangeSliderValue && this.statesData.minRangeSliderValue == this.statesData.lowSliderRangeValueIndex) ? " lqFilterLineSliderTracKDisable " : " ";
    let labelDivClass = "get-updated-value";
    labelDivClass += this.statesData.id == "avolFilterViewModel" || this.statesData.id == "ratingFilterTypeOptions2ViewModel" ? " bdr0" : "";

    const filterTitle = this.getTextTitle();
    const handleClick = this.handleClickOfHandle;

    const handles = (props) => {
      let p = {...props};
      p.handleClick = handleClick
      return handle(p);

    }

    return (
      <div className="slide-holder">
        {filterTitle}
        <div className="set-value-setting">
          <TextInput value={this.getFormattedNumber(this.statesData.lowRangeValue)} onBlurEvent={this.updateLowRangeSlider} /><span className="dash">-</span><TextInput value={this.getFormattedNumber(this.statesData.highRangeValue)} onBlurEvent={this.updateHighRangeSlider} />
        </div>
        <div className="set-value-range">
          <div className={lqFilterLineSliderClass}>
            <Range id={this.statesData.id} handle={handles} key={this.statesData.id} min={this.statesData.minRangeSliderValue} max={this.statesData.maxRangeSliderValue} value={rangeValue} onChange={(value) => { this.updateFilter(value, this.statesData.intervalData[value[0]], this.statesData.intervalData[value[1]], this.statesData.id) }} />
          </div>
          <div className={labelDivClass}>
            <label className="get-left-value">{this.getFormattedNumber(this.statesData.minValueText)}</label>
            <label className="get-right-value">{this.getFormattedNumber(this.statesData.maxValueText)}</label>
          </div>
        </div>
      </div>
    );
  }
}

const handle = (props) => {
  const { value, dragging, index, handleClick, ...restProps } = props;
  const handleClicks = () => {
    handleClick(index);
  }
  return (
    <Handle
      key={index}
      onClick={handleClicks}
      value={value}
      {...restProps}
    />
  );
};



const mapStateToProps = ({comparisonAndRotationMenuPanel}) =>{
const { LiqAndRatingFilterReducer } = comparisonAndRotationMenuPanel;
return{ LiqAndRatingFilterReducer };
}

const mapDispatchToProps = (dispatch) =>({
  updateDataFilterRange:(value, dataValueLow, dataValueHigh, id)=>dispatch(updateDataFilterRange(value, dataValueLow, dataValueHigh, id)),
  updateSelectedRatingFilterType:(ratingType,id)=>dispatch(updateSelectedRatingFilterType(ratingType,id)),
  updateActiveSlider:(data)=>dispatch(updateActiveSlider(data))
}
)

export default connect(mapStateToProps, mapDispatchToProps)(RangeSlider);
