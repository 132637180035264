import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';


class GetDownloadIdeaAnalsisReportWebRequest extends BaseServiceRequest {
    constructor(pdfResearchPath) {
        super();
        super.addRequestUri("i/download");
        super.addRequestParameter("path", pdfResearchPath);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "";
    }
}

export default GetDownloadIdeaAnalsisReportWebRequest;
