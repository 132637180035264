import { put, fork, select, call, takeLatest } from 'redux-saga/effects';
import { without, each, filter, contains,extend } from 'underscore';
import StringUtil from 'StringUtil';
import { getCompChartListItem, getCompareChart, getCompareChartViewModel, getSortType } from '../../../Reducers/NavCompare/ComparisonGraphReducers/selectors';
import {getMenuTab} from '../../../Reducers/NavCompare/MenuPanelReducers/selectors';
import { SymbolDataModel } from '../../../Models/ComparisonChartModels/LineDataModel';
import { getSymbolsPanel } from '../../../Reducers/NavCompare/MenuPanelReducers/selectors';
import {onContextMenuItemClick} from 'Actions/RotationGraphActions';
import { ComparisonAndRotationMenuConstants } from '../../../Constants/RotationGraphConstants';
import {ComparisonGraph} from '../../../Constants/ComparisonGraph'
import { CompareMenuType } from '../../../Constants/CompareTabType';
import { RotationMenuType } from '../../../Constants/RotationMenuType';
const {ActionTypes} = ComparisonAndRotationMenuConstants; 
extend(ActionTypes,ComparisonGraph.ActionTypes)

/********************** Model Logic *************************/

function* listItemHighlightSelected({key, item}) {

    try {
        let chartListItem = yield select(getCompChartListItem);
        let compareChart = yield select(getCompareChart);
        let {lastClicked,lastClickedItem,lastHighlightedOsids} = compareChart;
        const selectItemsCount = filter(chartListItem, (s) => s.isSelected);
        let sortType = yield select(getSortType);
       // const filterData = chartListItem.filter((itm) => !itm.isFilter);
        const sortData = chartListItem.sort((a, b) => {
            if (parseFloat(a.percValue) > parseFloat(b.percValue))
                return sortType === 'dsc' ? -1 : 1;
            if (parseFloat(b.percValue) > parseFloat(a.percValue))
                return sortType === 'dsc' ? 1 : -1;
            return a.label.localeCompare(b.label, undefined, { numeric: true });
        });
        chartListItem = sortData;
        if (selectItemsCount.length > 0) {
            if (selectItemsCount.length == 1 && item.isSelected) {
                yield call(highlightAll, item, 'Shift', lastHighlightedOsids, chartListItem);
                lastClicked = item;
                if (lastHighlightedOsids.indexOf(selectItemsCount[0].Osid) === -1) {
                    lastHighlightedOsids.push(selectItemsCount[0].Osid);
                }
                chartListItem = chartListItem.map((entry) => { entry.isLabelHighlighted = false; return entry });
                yield put({
                    type: ActionTypes.HANDLE_HIGHLIGHT_SUCCESS,
                    chartListItem,
                    lastClicked,
                    lastHighlightedOsids
                });
                yield put({ type: ActionTypes.SAVE_SETTINGS });
                return ;
            }
        }

        if (StringUtil.isEmpty(lastClicked))
            lastClicked = chartListItem[0];
        else {
            lastClicked = lastClickedItem;
        }
        lastHighlightedOsids = [];
        selectItemsCount.forEach((l) => {
            lastHighlightedOsids.push(l.Osid);
        })

        switch (key) {
            case 'Shift':
                yield call(listItemShiftClicked, item, lastHighlightedOsids, chartListItem, lastClicked);
                break;
            case 'Ctrl':
                yield call(listItemCtrlClicked, item, lastHighlightedOsids, chartListItem);
                break;
            case 'Click':
                yield call(listItemClicked, item, lastHighlightedOsids, chartListItem);
                break;
            default:
                break;
        }
        lastClicked = item;

        yield put({
            type: ActionTypes.HANDLE_HIGHLIGHT_SUCCESS,
            chartListItem,
            lastClicked,
            lastHighlightedOsids
        });
        yield put({ type: ActionTypes.SAVE_SETTINGS });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, listItemHighlightSelected ${error}`);
    }

}

function* listItemClicked(item, lastHighlightedOsids, chartListItem) {
    if (!highlightAll(item, 'Click', lastHighlightedOsids, chartListItem)) {
        return;
    }
    let lastHighlightedOsidsCopy = [];

    try {
        each(chartListItem, (entry) => {
            if (item.Osid == entry.Osid) {
                entry.isHighlighted = true;
                entry.isSelected = true;
                entry.isLabelHighlighted = true;
                if (lastHighlightedOsidsCopy.indexOf(entry.Osid) === -1)
                    lastHighlightedOsidsCopy.push(entry.Osid);
            }
            else {
                entry.isHighlighted = false;
                entry.isSelected = false;
                entry.isLabelHighlighted = false;
            }
        });
        lastHighlightedOsids.length = 0;
        lastHighlightedOsids.push(...lastHighlightedOsidsCopy);
        if (lastHighlightedOsids.length === chartListItem.length) {
            each(chartListItem, (entry) => { entry.isLabelHighlighted = false; });
        }
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, listItemClicked ${error}`);
    }
}

function* listItemCtrlClicked(item, lastHighlightedOsids, chartListItem) {
    if (!highlightAll(item, 'Ctrl', lastHighlightedOsids, chartListItem)) {
        return;
    }
    try {
        let lastHighlightedOsidsCopy = [...lastHighlightedOsids];
        each(chartListItem, (entry) => {
            if (item.Osid == entry.Osid) {
                if (item.isSelected) {
                    entry.isSelected = false;
                    entry.isHighlighted = false;
                    entry.isLabelHighlighted = false;
                    lastHighlightedOsidsCopy = without(lastHighlightedOsidsCopy, item.Osid);
                }
                else {
                    entry.isSelected = true;
                    entry.isHighlighted = true;
                    entry.isLabelHighlighted = true;
                    if (lastHighlightedOsidsCopy.indexOf(item.Osid) === -1)
                        lastHighlightedOsidsCopy.push(item.Osid)
                }
            }
        });
        lastHighlightedOsids.length = 0;
        lastHighlightedOsids.push(...lastHighlightedOsidsCopy);
        if (lastHighlightedOsids.length === chartListItem.length) {
            each(chartListItem, (entry) => { entry.isLabelHighlighted = true; });
        }

        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, listItemCtrlClicked ${error}`);
    }
}

function getItemIndex(list, item) {
    let index;
    list.find((listItem, key) => {
        if (listItem.label == item.label) {
            index = key
            return true;
        }
    })
    return index;
}

function* listItemShiftClicked(item, lastHighlightedOsids, chartListItem, lastClicked) {
    let preSeletedItems = null;
    let listItems = null;

    let lastClickedItemIndex = getItemIndex(chartListItem,lastClicked);
    let itemIndex = getItemIndex(chartListItem,item);
    let maxIndex = Math.max(itemIndex, lastClickedItemIndex);
    let minIndex = Math.min(itemIndex, lastClickedItemIndex);
    try {
        listItems = filter(chartListItem, (s) => (chartListItem.indexOf(s) <= maxIndex && chartListItem.indexOf(s) >= minIndex && s.Osid != lastClicked.Osid));

        preSeletedItems = filter(chartListItem, (s) => s.Osid !== lastClicked.Osid);
        let lastHighlightedOsidsCopy = [...lastHighlightedOsids];
        if (!StringUtil.isEmpty(preSeletedItems)) {
            each(preSeletedItems, (s) => {
                s.isSelected = false;
                s.isHighlighted = false;
                s.isLabelHighlighted = false;
                unHighlightSelected(s, chartListItem);
                lastHighlightedOsidsCopy = without(lastHighlightedOsidsCopy, s.Osid);
            })
        }

        if (!StringUtil.isEmpty(listItems)) {
            each(listItems, (s) => {
                s.isSelected = true;
                s.isHighlighted = true;
                s.isLabelHighlighted = true;
                highlightSelected(s, chartListItem);
                if (lastHighlightedOsidsCopy.indexOf(s.Osid) === -1)
                    lastHighlightedOsidsCopy.push(s.Osid);
            })
        }

        lastHighlightedOsids.length = 0;
        lastHighlightedOsids.push(...lastHighlightedOsidsCopy);
        if (lastHighlightedOsids.length === chartListItem.length) {
            each(chartListItem, (entry) => { entry.isLabelHighlighted = true; });
        }
        else {
            each(chartListItem, (entry) => {
                entry.isLabelHighlighted = contains(lastHighlightedOsids, entry.Osid) ? true : false;
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, listItemShiftClicked ${error}`);
    }
}

function* highlightAll(item, key, lastHighlightedOsids, chartListItem) {
    try {
        if (key === 'Shift') {
            let lastHighlightedOsidsCopy = [];
            chartListItem.forEach((entry) => {
                entry.isSelected = true;
                entry.isHighlighted = true;
                lastHighlightedOsidsCopy.push(parseInt(entry.Osid));
            });
            lastHighlightedOsids.push(...lastHighlightedOsidsCopy);
            return;
        }
        else {
            if (lastHighlightedOsids.length == 1) {
                if (item.Osid == lastHighlightedOsids[0]) {
                    chartListItem.forEach((entry) => {
                        entry.isSelected = true;
                        entry.isHighlighted = true;
                    });
                    let lastHighlightedOsidsCopy = [];
                    lastHighlightedOsids.push(...lastHighlightedOsidsCopy);
                    return false;
                }
            }
            return true;
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, highlightAll ${error}`);
    }
}

function* unHighlightSelected(item, chartListItem) {
    try {
        each(chartListItem, (s) => {
            if (s.Osid === item.Osid) {
                s.isSelected = false;
                s.isHighlighted = false;
            }
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, unHighlightSelected ${error}`);
    }
}

function* highlightSelected(item, chartListItem) {
    try {
        each(chartListItem, (s) => {
            if (s.Osid === item.Osid) {
                s.isSelected = true;
                s.isHighlighted = true;
            }
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, highlightSelected ${error}`);
    }
}

/***************************** Subroutines ************************************/
// function* handleHighlight({ item, typeOfClick }) {
//     const chartListItem = yield select(getCompChartListItem);
//     const compareChart = yield select(getCompareChart);
//     let stateData = {
//         chartListItem: [...chartListItem],
//         lastClicked: compareChart.lastClicked,
//         lastHighlightedOsids: [...compareChart.lastHighlightedOsids],
//         lastClickedItem: compareChart.lastClickedItem
//     };

//     try {
//         stateData = yield call(listItemHighlightSelected, { ...stateData }, item, typeOfClick);
//         yield put({
//             type: ActionTypes.HANDLE_HIGHLIGHT_SUCCESS,
//             stateData
//         });
//         yield put({ type: ActionTypes.SAVE_SETTINGS });
//     }
//     catch (error) {
//         yield put({
//             type: ActionTypes.HANDLE_ERROR,
//             hasError: true,
//             errorMsg: error
//         })
//         console.log(`Error occurs in compareModelPanel.js, handleHighlight ${error}`);
//     }
// }

function* updateVisibleInSettings(id) {
    try {
        const states = yield select(getCompareChartViewModel);
        const symbolRequest = [...states.symbolRequest].map((symbol) => (symbol.Osid === id) ? { ...symbol, isVisible: !symbol.isVisible } : symbol);
        const indexRequestData = [...states.indexRequest].map((symbol) => (symbol.Osid === id) ? { ...symbol, isVisible: !symbol.isVisible } : symbol);
        yield put({
            type: ActionTypes.UPDATE_SYMBOL_REQ,
            symbolRequest
        });
        yield put({
            type: ActionTypes.UPDATE_INDEX_REQ,
            indexRequestData
        });
        yield put({
            type: ActionTypes.SAVE_SETTINGS
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, updateVisibleInSettings ${error}`);
    }
}

function* handleToggleVisible({ id }) {
    try {
        const states = yield select(getCompChartListItem);
        let maxValue = null;
        let minValue = null;
        const data = states.map((item) => {
            if (item.Osid === id) {
                maxValue = item.maxValue;
                minValue = item.minValue;
                return Object.assign(new SymbolDataModel, { ...item, isVisible: !item.isVisible });
            }
            return item;
        });
        yield put({
            type: ActionTypes.TOGGLE_VISIBLE_SUCCESS,
            data
        });
        const { chartMaxPrice, chartMinPrice } = yield select(getCompareChartViewModel);
        if (maxValue >= chartMaxPrice || minValue <= chartMinPrice) {
            yield put({
                type: ActionTypes.CHART_RE_DRAW
            });
        }
        yield fork(updateVisibleInSettings, id);
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, handleToggleVisible ${error}`);
    }
}

function* handleClearAllWithoutConfirm(){
    const data = [];
 
    try {
        
        if(getMenuTab.ComparisonMenuTab === CompareMenuType.Symbols){
            yield put({
                type: ActionTypes.SET_ADDLIST_LISTNAME,
                listName: ""
            })    
        }
     
        yield put({
            type: ActionTypes.CLEAR_COMPARISON_SYMBOLS_DATA,
            data
        });

        yield put({
            type: ActionTypes.CHART_RE_DRAW
        });
        yield put({ type: ActionTypes.SAVE_SETTINGS });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareModelPanel.js, handleClearAllWithoutDialog ${error}`);
    }
 }

 function* showContextMenu({ pos, item }) {
    const compareChart = yield select(getCompareChart);
    let contextMenuData = [...compareChart.contextManu.contextMenuData];
  
    try {
      yield put({
        type: ActionTypes.SHOW_CONTEXT_MENU_SUCCESS,
        contextMenuData: _.filter(contextMenuData,(menuItem) =>  (menuItem.actionType === 'View Constituents' && item.itemType !== RotationMenuType.Stocks) || menuItem.actionType === 'View Symbol'),
        pos,
        item
      });
    }
    catch (error) {
      yield put({
        type: ActionTypes.HANDLE_ERROR,
        hasError: true,
        errorMsg: error
      })
      console.log(`Error occurs in comapreModelPanel.js, showContextMenu ${error}`);
    }
  }

  function* contextMenuOperation({ ItemType }) {
    const symbolsMenu = yield select(getSymbolsPanel);
    const item = symbolsMenu.ComparisonMenuStates.contextManu.contextItem;
      try {
          onContextMenuItemClick(ItemType, item, item.itemType);
          yield put({
              type: ActionTypes.HIDE_CONTEXT_MENU
          });

          yield put({
              type: ActionTypes.SAVE_SETTINGS
          });
      }

      catch (error) {
          yield put({
              type: ActionTypes.HANDLE_ERROR,
              hasError: true,
              errorMsg: error
          })
          console.log(`Error occurs in comapreModelPanel.js, contextMenuOperation ${error}`);
      }
  }

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchComparisonListItemHighlightSelected() {
    yield takeLatest(ActionTypes.COMPARISON_LIST_ITEM_HIGHLIGHT_SELECTED, listItemHighlightSelected);
}

export function* watchHandleToggleVisible() {
    yield takeLatest(ActionTypes.TOGGLE_VISIBLE, handleToggleVisible);
}

export function* watchHandleClearAllWithoutConfirm(){
    yield takeLatest(ActionTypes.CLEAR_ALL_WITHOUT_CONFIRM, handleClearAllWithoutConfirm);
}

export function* watchCompGroupsChartContextMenu(){
    yield takeLatest(ActionTypes.SHOW_COMP_GROUPS_CHART_CONTEXT_MENU, showContextMenu);
}
  
  export function* watchCompGroupsChartContextMenuOperation() {
    yield takeLatest(ActionTypes.COMP_GROUPS_CHART_CONTEXT_MENU_ITEM_CLICK, contextMenuOperation);
  }
  