import BaseServiceApi from "BaseServiceApi";
import { connect } from 'react-redux';
import { flatten } from "underscore";
import GraphType from "GraphType";
import { initTimeLine } from "../../../../../Actions/TabDataGraphAction";
import LocalizationStore from 'LocalizationStore';
import ReactHtmlParser from "react-html-parser";
import ScrollBar from "ScrollBar";
import { switchFRVisibility } from "../../../../../Actions/RelatedInfoPanelActions/RiPanelActions";
import ThemeHelper from "ThemeHelper";
import UserInfoUtil from "../../../../../Utils/UserInfoUtil";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FactorTranslateHelper, TranslateHelper } from '../../../../../Utils/TranslateHelper';
import React, { Component } from 'react';
import { onPlotSymbol } from "../../../../../Actions/DatagraphActions";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

class RiPanelFactorDetail extends Component {
    constructor(props) {
        super(props);
        this.getFactorDetail = this.getFactorDetail.bind(this);
        this.switchVisibility = this.switchVisibility.bind(this);
        this.isLocalSymbolEntitled = UserInfoUtil.hasLocalSymbol();
        this.isFactorResearchEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.FACTOR_RESEARCH_HELP_Entitlement);
    }
    switchVisibility(){
        this.props.switchOnVisibility();
        this.props.initTimeLine(this.props.factorsEndDate, this.props.nodeCount, this.props.InitialBufferSize, false, false, false);
    }

    getQuestionCircle(item, url) {
        const helpIcon = <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z"></path></svg>


        switch (item) {
            case 'FACTOR':
                if (!url || !this.isFactorResearchEntitled) {
                    return null;
                }
                return <a href={url} target="_blank" className="ri-question-circle" rel="noreferrer">{helpIcon}</a>

            case 'GRAPH':
                {
                    const popoverFocus = (
                        <Popover id="graph-help-popover" placement="top">
                            <ul className="graph-help">
                                <li><span className="help-title">{FactorTranslateHelper["AVG_RETURN_PATH"]}: </span>
                                    <span className="desc">{FactorTranslateHelper["AVG_FORWAD_RETURN_EQUAL_WIEGHT"]}</span></li>
                                <li><span className="help-title">{FactorTranslateHelper["AVG_GAIN"]}: </span>
                                    <span className="desc">{FactorTranslateHelper["SIZE_OF_AVG_GAIN"]}</span></li>
                                <li><span className="help-title">{FactorTranslateHelper["AVG_LOSS"]}: </span><span className="desc">{FactorTranslateHelper["SIZE_OF_AVG_LOSS"]}</span></li>
                                <li><span className="help-title">{FactorTranslateHelper["REVISED_TARGET_PRICE"]}: </span><span className="desc">{FactorTranslateHelper["AVG_FORWAD_RETURN"]}</span></li>
                            </ul>
                        </Popover>)
                    return (<OverlayTrigger trigger="focus" placement="top" overlay={popoverFocus}>
                        <Button bsStyle="link" className="ri-question-circle">{helpIcon}</Button>
                    </OverlayTrigger>)
                }
            default:
                break;
        }

    }

    createMarkup() {
        const summary = this.props.factorEventInfo && this.props.factorEventInfo.factorEventSummaryDescription;

        if (!summary) {
            return null;
        }

        let reg = /.*return of <b><font color="\S+">\s+([-]?\d+\.\d+)/;
        let avgColor = null;
        const result = reg.exec(summary);

        if (result !== null) {
            avgColor = +result[1] > 0 ? this.props.posColor : this.props.negColor
        }

        reg = /(.*return of <b><font color=")\S+(">.*GAIN of <font color=")\S+(".*LOSS of <font color=")\S+(".*)/;

        return summary.replace(reg, (match, p1, p2, p3, p4) => p1 + avgColor + p2 + this.props.posColor + p3 + this.props.negColor + p4);

    }

    getSegmentList() {
        return this.props.factorEventInfo && this.props.factorEventInfo.factorSegmentList.slice(0, 2).map((i, index) => {
            let key;
            if (index === 0) {
                key = `FR_segment_geo_${i.segmentName.split(' ').join('')}`;
            }
            else if (index === 1) {
                key = `FR_segment_size_${i.segmentName.split(' ').join('')}`;
            }

            return LocalizationStore.getTranslatedData(key, i.segmentName);
        });
    }

    handleChange(e, symbol) {
        if (symbol && symbol != "") {
            this.props.onPlotSymbol(symbol)
        }
    }


    getFactorDetail() {
        const { periodicity, isLoading, visibility, factorEventInfo, selectedFactorKey, selectedEvent, graphOpenList, lastEventKey, lastOnly, FRData } = this.props;
        if (periodicity !== GraphType.Weekly && periodicity !== GraphType.Daily) {
            return <div className="large-normal factor-message">{FactorTranslateHelper["SELECT_D/W_PERIODICITY"]}</div>
        }
        if (!visibility) {
            return (
                <div className="large-normal factor-message">
                    <div className="visibility-tip" onClick={this.switchVisibility}>
                        {FactorTranslateHelper["VISIBILITY_ON"]}
                    </div>
                    {FactorTranslateHelper["TO_VIEW_FACTOR"]}
                </div>
            );
        }

        if (!isLoading && factorEventInfo && selectedFactorKey !== null && selectedEvent && selectedEvent.InView &&
            (selectedFactorKey !== lastEventKey && !lastOnly || selectedFactorKey === lastEventKey) && graphOpenList.length > 0) {
            const { factorStockList, factorEventSummaryDescription } = factorEventInfo;

            const eventIndicator = factorEventInfo.eventIndicator ? LocalizationStore.getTranslatedData(`FR_${factorEventInfo.eventIndicator}`, factorEventInfo.eventIndicator) : null;
            const categoryLevel = factorEventInfo.eventCategoryId < 0 ? 0 : 1;
            const categoryCurrId = factorEventInfo.eventCategoryId < 0 ? 0 - factorEventInfo.eventCategoryId : factorEventInfo.eventCategoryId;
            const eventCategory = factorEventInfo.eventCategory ? LocalizationStore.getTranslatedData(`FR_category${categoryLevel}_${categoryCurrId}`, `${factorEventInfo.eventCategory}`) : null;
            const segmentList = this.getSegmentList();
            const _fff000 = ThemeHelper.getThemedBrush('fff000');
            const _000fff = ThemeHelper.getThemedBrush("000fff");
            const outerDashLine = ThemeHelper.getThemedBrush('outerDashLine');
            const summaryHtml = this.createMarkup();

            return (
                <div className="custom-scroll"  >
                    <div className="factor-title">{LocalizationStore.getTranslatedData(`FR_eventid_${factorEventInfo.eventId}`, factorEventInfo.eventName)} {this.getQuestionCircle('FACTOR', factorEventInfo.url)}</div>
                    <div className="factor-subtitle">
                        {eventCategory}{eventCategory ? ',' : null} {eventIndicator}
                    </div>
                    <div className="factor-subtitle">
                        {factorEventInfo.factorSegmentList ? segmentList.join(' / ') : null}
                    </div>
                    <div className="factor-summary">
                        {factorEventSummaryDescription ? <div>{ReactHtmlParser(summaryHtml)}</div> : null}
                    </div>
                    <div className="factor-see-also">
                        <span>{FactorTranslateHelper["SEE_ALSO"]}:</span>
                        {factorStockList ? factorStockList.slice(0, 5).map((item) => <a className="factor-link" key={item.symbol} onClick={(e) => this.handleChange(e, item.symbol)}>{this.isLocalSymbolEntitled ? item.localSymbol : item.symbol}</a>) : null}
                    </div>
                    <div className="factor-graph-explain">
                        <ul>
                            <li>
                                <div className="sample target-price"></div>
                                <span>{FactorTranslateHelper["FACTOR_EVENT"]}</span>
                                {this.getQuestionCircle('GRAPH')}
                            </li>
                            <li>
                                <div className="sample average">
                                    <svg width="50" height="14">
                                        <path d="M 2 7 L 45 7" strokeDasharray="3" strokeWidth="1" stroke={_fff000} ></path>
                                        <circle cx="45" cy="7" r="3" fill={_fff000}></circle>
                                    </svg>
                                </div>
                                <div>
                                    <div>{FactorTranslateHelper["AVG_RETURN_PATH"]} &</div>
                                    <div>{FactorTranslateHelper["TAGET_PRICE"]}</div>
                                </div>
                            </li>
                            <li>
                                <div className="sample max-expected">
                                    <svg width="50" height="20">
                                        <path d="M 48 0 C -10 0, -10 20, 50 20" strokeDasharray="3" strokeWidth="1" fill="none" stroke={outerDashLine} ></path>
                                    </svg>
                                </div>
                                <div>
                                    <div>{FactorTranslateHelper["AVG_GAIN"]}</div>
                                    <div>{FactorTranslateHelper["AVG_LOSS"]}</div>
                                </div>
                            </li>
                            <li>
                                <div className="sample average">
                                    <svg width="50" height="14">
                                        <path d="M 2 7 L 45 7" strokeWidth="1" strokeDasharray="2" stroke={_fff000}></path>
                                        <circle cx="45" cy="7" r="3" stroke={_fff000} fill={_000fff}></circle>
                                    </svg>
                                </div>
                                <div>
                                    <div>{FactorTranslateHelper["REVISED_TARGET_PRICE"]} </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>)
        }

        else if (!isLoading && !FRData || FRData.length === 0 || FRData.every((i) => i.every((factor) => factor.InView === false))) {
            return <div className="large-normal factor-message">{FactorTranslateHelper["NO_FACTOR_EVENT"]}</div>
        }

        else if (!isLoading && lastOnly && !lastEventKey) {
            return (
                <div className="custom-scroll"  >
                    <div className="large-normal factor-message">
                        {FactorTranslateHelper["NO_ACTICE_FACTOR"]}<br></br>
                        {FactorTranslateHelper["UNCHECK_THE"]}
                        <span style={{ fontWeight: 'bolder' }}>{FactorTranslateHelper["SHOW_ACTIVE_EVENT"]}</span>
                        {FactorTranslateHelper["OPTION_TO_INACTIVE_EVENT"]}
                    </div>
                </div>
            )

        }

        else if (!isLoading && graphOpenList.length === 0
            || (selectedFactorKey !== lastEventKey && !graphOpenList.includes(lastEventKey) && lastOnly)
            || (selectedEvent && !selectedEvent.InView && !graphOpenList.some((i) => i !== selectedFactorKey && flatten(FRData).some((j) => j.FactorKey === i)))
        ) {
            return <div className="large-normal factor-message">{FactorTranslateHelper["SELECT_EVENT"]}</div>;
        }

        else if ((!isLoading && selectedEvent && !selectedEvent.InView && graphOpenList.length !== 0)
            || (selectedFactorKey !== lastEventKey && lastOnly && graphOpenList.includes(lastEventKey))
        ) {
            return <div className="large-normal factor-message">{FactorTranslateHelper["SELECT_EVENT_FLAG"]}</div>;
        }
    }


    render() {
        const { isLoading, visibility, periodicity } = this.props;

        return (
            <div id="factor-scroll">
                {isLoading && visibility && (periodicity === 'Daily' || periodicity === 'Weekly')
                    ? <div className="factor-message large-normal">{TranslateHelper["Loading"]}</div>
                    : this.getFactorDetail()}
                < ScrollBar scrollId="factor-scroll" vScroll={true} ref={(ref) => (this.scrollBar = ref)}></ScrollBar>
            </div >
        )
    }
}

const mapStateToProps = ({ DatagraphReducers, appColor }) => {

    const { isLoading, visibility, lastOnly, factorEventInfo, selectedFactorKey, selectedEvent, graphOpenList, lastEventKey, FRData } = DatagraphReducers.FactorResearchReducer;
    const { periodicity, videoMode, nodeCount, InitialBufferSize, factorsEndDate } = DatagraphReducers.DataGraphReducer;
    const { posColor, negColor } = appColor;
    // const FRData = frdata ? frdata.FRData : null;
    return { isLoading, visibility, lastOnly, periodicity, factorEventInfo, selectedFactorKey, selectedEvent, graphOpenList, lastEventKey, 
        FRData, videoMode, nodeCount, InitialBufferSize, factorsEndDate, posColor, negColor };
};


const mapDispatchToProps = (dispatch) => ({
    switchOnVisibility: () => dispatch(switchFRVisibility(true)),
    initTimeLine: (date, nodeCount, InitialBufferSize, isMountainChart, isComparisionChart, isInitial) => dispatch(initTimeLine(date, nodeCount, InitialBufferSize, isMountainChart, isComparisionChart, isInitial)),
    onPlotSymbol: (symbol) => dispatch(onPlotSymbol(symbol))
});

export default connect(mapStateToProps, mapDispatchToProps)(RiPanelFactorDetail);