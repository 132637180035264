import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class ReorderColumnSetItemsRequest extends BaseServiceRequest {

    constructor(listId, columnSetID, dataItemIds, removeItemIds, isQuoteboard = false) {
        super();
        
        super.addRequestUri("columnsetitemreorder.pb");
        super.addRequestParameter("l", listId.toString());
        super.addRequestParameter("cs", columnSetID.toString());
        let dataItemIdStr = "";
        dataItemIds.forEach(item => {
            if(dataItemIdStr === null){
                dataItemIdStr = item;
            }
            else{
                dataItemIdStr = `${dataItemIdStr} ; ${item}`;
            }
        });
        super.addRequestParameter("dis", dataItemIdStr);
        let removeItemIdStr = "";
        removeItemIds.forEach(item => {
            if(removeItemIdStr === null){
                removeItemIdStr = item;
            }
            else{
                removeItemIdStr = `${removeItemIdStr} ; ${item}`;
            }
        });
        super.addRequestParameter("ris", removeItemIdStr);
        super.addRequestParameter("iqb", isQuoteboard ? "1" : "0");
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default ReorderColumnSetItemsRequest;
