import React, { Component } from "react";
import { ComparisonLeftPanel, CompToolTip, CompContextMenu} from './Containers';
import {ClearAllDialog} from '../ToggleMenuPanel/Presentational';
import MainMenuPanel from '../ToggleMenuPanel/MainMenuPanel';
import { connect } from 'react-redux';
import SettingsAction from '../../../../Actions/SettingsActions';
import CompareTabType from "CompareTabType";
import { initCompChart } from "../../../../Actions/ComparisonActions";
import {initLiqViewModel,initRIPanelSettings} from "../../../../Actions/ComparisonAndRotationMenuActions";
import TimeTrackingWindow from "TimeTrackingWindow";
import SettingsStore from "SettingsStore";

let isinit = false;
class ComparisonGraph extends Component {

  UNSAFE_componentWillMount() {
    // TimeTrackingWindow.beginTimeTracker();
    if (!isinit) {
    const settings = SettingsStore.getConsoleSettings().NavCompareSettings.TabComparisonGraphSettings;
    this.props.initRIPanelSettings(settings);

    isinit = true;
    }
  }

  componentDidMount() {
    // TimeTrackingWindow.isMounted = true;
    if (this.props.selectedTabKey !== CompareTabType.ComparisonGraph) {
      SettingsAction.updateCompareTabSelection(CompareTabType.ComparisonGraph);
    }
  }
  
  render() {
    return (
      <div className="COLS">
        <ComparisonLeftPanel />
        <MainMenuPanel/>
         <CompToolTip />
        <CompContextMenu />
        <ClearAllDialog/>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  initRIPanelSettings:(settings) => dispatch(initRIPanelSettings(settings))
});

export default connect(null, mapDispatchToProps)(ComparisonGraph);
