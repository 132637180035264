export const externalUploader = (state) => state.externalDataUploader.ExternalDataUploaderReducer;
export const recurringChecked = (state) => externalUploader(state).isrecurringchecked;
export const tempFile = (state) => externalUploader(state).tempLocalFile;
export const isRecurring = (state) => externalUploader(state).isrecurringchecked;
export const ftpUserName = (state) => externalUploader(state).ftpUser;
export const ftpPassword = (state) => externalUploader(state).ftpPass;
export const scheduledTime = (state) => externalUploader(state).scheduleTime;
export const overWriteData = (state) => externalUploader(state).overwriteExistingData;
export const uploadOnSubmit = (state) => externalUploader(state).uploadFileOnClose;
export const winTimeZone = (state) => externalUploader(state).timeZone;
export const SelisExternalUrl = (state) => externalUploader(state).isExternalUrl;
export const SelisFtpUrl = (state) => externalUploader(state).isFtpUrl;
export const SelisSFtpUrl = (state) => externalUploader(state).isSFtpUrl;
export const SelisWONSFtpUrl = (state) => externalUploader(state).isWonSftpPath;
export const SelisGoogleUrl = (state) => externalUploader(state).isGoogleUrl;
export const storedListItems = (state) => externalUploader(state).explorerListItems;
export const lastUploadListId = (state) => externalUploader(state).lastUploadedListId;
export const lastDeletedIds = (state) => externalUploader(state).deletedListItems;
export const uploadFrequency = (state) => externalUploader(state).frequencyType;
export const reuploadId = (state) => externalUploader(state).reUploadListId;
export const reuploadedListId = (state) => externalUploader(state).reuploadedListId;
export const uploadResponse = (state) => externalUploader(state).fileUploadResponse;
export const exceptionMsg = (state) => externalUploader(state).exceptionMessage;


export const externalDataListViewReducer = (state) => state.externalDataUploader.ExternalDataListViewReducer;
export const currentSelectedList = (state) => externalDataListViewReducer(state).source;
export const activeListId = (state) => externalDataListViewReducer(state).source.listId;
export const getSortColumns = (state) => externalDataListViewReducer(state).sortCols;
export const getLastPageIndex = (state) => externalDataListViewReducer(state).lastPageIndex;
export const startWizardlistId = (state) => externalDataListViewReducer(state).startWizardId;
export const rowSourceCount = (state) => externalDataListViewReducer(state).rowSource.length;
export const startSymbolCol = (state) => externalDataListViewReducer(state).selectSymbolCol;
export const startDateCol = (state) => externalDataListViewReducer(state).selectDateCol;
export const startDateFormat = (state) => externalDataListViewReducer(state).showDateSelect;
export const updatedSymbolCol = (state) => externalDataListViewReducer(state).identifiedSymbolCol;
export const updatedDateCol = (state) => externalDataListViewReducer(state).identifiedDateCol;
export const updatedDateFormat = (state) => externalDataListViewReducer(state).identifiedDateFormat;
export const selectStartWizard = (state) => externalDataListViewReducer(state).showStartWizard;

export const externalDataUploadSymbolReducer = (state) => state.externalDataUploader.externalDataUploadSymbolReducer;
export const getCurrentSettings = (state) => externalDataUploadSymbolReducer(state).settings;
export const getExdResult = (state) => externalDataUploadSymbolReducer(state).exdResult;
export const getExdListResult = (state) => externalDataUploadSymbolReducer(state).listItems;
export const getHeight = (state) => externalDataUploadSymbolReducer(state).height;
export const getTimeSeriesData_pricePane = (state) => externalDataUploadSymbolReducer(state).timeSeriesData_pricePane;
export const getRawTimeSeriesData_pricePane = (state) => externalDataUploadSymbolReducer(state).rawTimeSeriesData_pricePane;
export const getTimeSeriesData_indicatorPane = (state) => externalDataUploadSymbolReducer(state).timeSeriesData_indicatorPane;
export const getTimeSeriesData_indicatorPane_AllPoints = (state) => externalDataUploadSymbolReducer(state).timeSeriesData_indicatorPane_AllPoints;
export const getEventSeriesData = (state) => externalDataUploadSymbolReducer(state).eventSeriesData;
export const getEventSeriesData_AllPoints = (state) => externalDataUploadSymbolReducer(state).eventSeriesData_AllPoints;
export const getIsShowExternalDataPointer = (state) => externalDataUploadSymbolReducer(state).isShowExternalDataPointer;
export const getPDRinfo = (state) => externalDataUploadSymbolReducer(state).PDR_info;
export const getExternalDataResults = (state) => externalDataUploadSymbolReducer(state).externalDataResults;
export const getExpandedEventBadgeData = (state) => externalDataUploadSymbolReducer(state).expandedEventBadgeData;
export const getRiPanelData = (state) => externalDataUploadSymbolReducer(state).riPanelData;
export const getSettingsWindowType = (state) => externalDataUploadSymbolReducer(state).isPriceChartSettings;