import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import BaseServiceApi from "BaseServiceApi";
import SettingsStore from "SettingsStore";
import UserInfoUtil from "UserInfoUtil";
import UserApi from "../../ServiceApi/Apis/UserApi.js";
import GraphApi from "../../ServiceApi/Apis/GraphApi.js";
import { ConsoleConstants } from "../../Constants/ConsoleConstants.js";

const CHANGE_EVENT = "change";
var IntradaySourceType = BaseServiceApi.rayData.IntradaySourceType;

let _state = {
    contactsDatasource: [],
    loading: true,
    Initialized : undefined,
    isSupportHours: false,
    screenConnectUrl: "",
    code: "",
};
class ContactStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getState() {
        if (_state === undefined) {
            this.setDefaultState();
        }
        return _state;
    }

    setDefaultState() {
        _state = {
            contactsDatasource: [],
            loading: true,
            Initialized : undefined,
            isSupportHours: false,
            code: "",
        };
    }

    getContactsDataSource() {
        let user = UserInfoUtil.getUserInfo();
        if(Boolean(user) && Boolean(user.userEmail) && !Boolean(_state.Initialized)) //
        {
            //john.reinsberg@lazard.com, tom.copper@invesco.com, stephen.langford@schroders.com
            UserApi.getUserContactsRequest(user.userEmail).then((result)=> {
                //console.log("contactsDatasource", result)
                _state.contactsDatasource = result.userContactsData;
                _state.loading = false;
                _state.Initialized = true;
                this.emit(CHANGE_EVENT);
            }).catch((e)=> {
                console.log("error", e)
            });
        }
    }

    checkIsInSupportHours(){
        let user = UserInfoUtil.getUserInfo();
        if(Boolean(user) && Boolean(user.userId))
        {
            _state.code = Boolean(user.userInfo) && Boolean(user.userInfo.userCode) ? user.userInfo.userCode.toString().toLowerCase() : user.userId.toString();
            if(_state.code.length < 6)
            {
                var tempCode = "live";
                _state.code += tempCode.substring(0, 6 - _state.code.length);
            }
            UserApi.checkIsInSupportTime(user.userId).then((result)=> {
               _state.isSupportHours = result.isSupportTimeData.DataValue;
               GraphApi.GetLiveStreamUrlRequest(IntradaySourceType.IntradaySource_Default).then((result) => {
                    //_state.screenConnectUrl = "https://livesupportstage.panaray.com/app_extensions/e6c8e9ee-3e67-47c9-906a-262f5c14d243/ConnectToPanaraySupport.html"
                    _state.screenConnectUrl = result.liveStreamRequestData.screenConnectUrl.replace("Handler.ashx", "ConnectToPanaraySupport.html");
                    this.emit(CHANGE_EVENT);
               });
            }).catch((e)=> {
                console.log("error", e)
            });
        }
    }

    getIsInSupportHours(){
        return _state.isSupportHours;
    }

    dispatcherCallback(payload) {
        AppDispatcher.waitFor([SettingsStore.dispatchToken]);
        const action = payload.action;
        switch (action.actionType) {
            case ConsoleConstants.ActionTypes.TOGGLE_SUPPORT_DIALOG:
                    this.getContactsDataSource();
                break;
            case ConsoleConstants.ActionTypes.CHECK_SUPPORT_HOURS:
                    this.getState();
                    this.checkIsInSupportHours();
                break;
            default:

        }
    }
}

const contactStore = new ContactStore();
export default contactStore;