import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class DeleteAllAlertsByMSIDsRequest extends BaseServiceRequest {
    constructor(msids, cacheId) {
        super();
        const ReqProto = BaseServiceApi.rayData["DeleteAlertsRequestData"];
        const deleteAlerts = new ReqProto();
        deleteAlerts.msids = msids.split(';').map((item) => parseInt(item));
        deleteAlerts.cacheId = cacheId ? parseInt(cacheId) : cacheId;
        super.addRequestUri("deleteallbymsids.pb");
        if (msids != null) super.setContent(deleteAlerts);
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "Response";
    }
}