import React, { Component } from 'react';
import { connect } from 'react-redux';
import Measure from "react-measure";
import { onDimensionsChange } from 'Actions/ComparisonActions';
import CompLineVisuals from './CompLineVisuals.jsx';
import ErrorBoundary from 'ErrorBoundary';
import {getCompareChart} from '../../../../../Reducers/NavCompare/ComparisonGraphReducers/selectors'
// import CompareApi from "CompareApi";
// import SymbolTypeEnum from 'SymbolType';
// import GraphTypeEnum from 'GraphType';
import { PrintMode } from "../../../../../print/printmode.js";
import ConsoleStore from "ConsoleStore";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";

let shouldHandleResize = true;
class ComparisonChart extends Component {

    UNSAFE_componentWillMount = () => {
        if (PrintMode.printing) {
            ConsoleStore.addChangeListener(this.ConsoleStateChange);
        }
    }

    componentWillUnmount() {
        if (PrintMode.printing) {
            ConsoleStore.removeChangeListener(this.ConsoleStateChange);
            shouldHandleResize = true;
        }
    }

    ConsoleStateChange() {
        const currentAction = ConsoleStore.getCurrentAction();
        if (currentAction === ConsoleConstants.ActionTypes.SET_CHART_LOADED) {
            shouldHandleResize = false;
        }
    }

    onDimensionsChange = (dim) => {
        if(shouldHandleResize) {
            this.props.onDimensionsChange(dim);
        }
    }

    render() {
        const { width, height } = this.props.chartDimension;
        return (
            <Measure
                bounds
                onResize={(contentRect) => this.onDimensionsChange(contentRect.bounds)}
            >
                {({ measureRef }) =>
                    <div className="comparison-grid" ref={measureRef}>
                        {width !== 0 && height !== 0 && <ErrorBoundary><CompLineVisuals /></ErrorBoundary>}
                    </div>
                }
            </Measure>
        );
    }
}

const mapStateToProps = (state) => {
    const chartDimension = state.comparisonGraph.compareChart.chartDimension;
    return ({ chartDimension });
}

const mapDispatchToProps = (dispatch) => ({ 
    onDimensionsChange: (dim) => dispatch(onDimensionsChange(dim))
});


export default connect(mapStateToProps, mapDispatchToProps)(ComparisonChart);