import FundDescriptionControl from '../FundDescriptionControl.jsx';
import FundDetailsControl from './FundDetailsControl';
import IdeaListControl from '../IdeaListControl.jsx';
import { Panel } from "react-bootstrap";
import React from 'react';
import TabularView from './TabularView.jsx';
import TopHoldingsControl from './TopHoldingsControl.jsx';

function CEFPanelBody() {
    return (
        <>
            <Panel>
                <Panel.Body>
                    <div className="summary-block-chronicle for-CEF">
                        <div className="summary-block-panel-left">
                            <FundDetailsControl />
                        </div>
                        <div className="summary-block-panel-right">
                            <div className="inside-left">
                                <FundDescriptionControl key="fundDescription" id="cefView" height={144} width={299} showDescriptionHeading={true} />
                                <IdeaListControl />
                            </div>
                            <div className="inside-right">
                                <TopHoldingsControl />
                            </div>
                            <div className="summary-block-calendar">
                                <TabularView />
                            </div>
                        </div>
                    </div>
                </Panel.Body>
            </Panel>
        </>
    )
}
export default CEFPanelBody