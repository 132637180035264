import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetAuthRouteRequest extends BaseServiceRequest {
    constructor(userEmail, companyName) {
        super();
        super.addRequestUri("getauthstrat.pb");
        super.addRequestParameter("dt", "web");
        super.addRequestParameter("e", userEmail);
        super.addRequestParameter("coname", companyName);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.SSO;
    }

    getMessageName() {
        return "AuthStrategyResponse";
    }
}

export default GetAuthRouteRequest;