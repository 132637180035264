import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import RiPanelSimilarFundSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelatedInfoPanel/RiPanelSimilarFundSettings";
import { SettingsConstants } from "../../../Constants/SettingsConstants";
import SettingStore from "../../../Stores/ConsoleWindow/Settings/SettingsStore";

const { ActionTypes } = RiPanelConstants;

const initState = {
    loading: true,
    similarFundsData: [],
    ActiveFund: null,
    isRelative: false,
    isFundViewEntitled: false,
    SimilarFundSettings: new RiPanelSimilarFundSettings()
};

const RiPanelSimilarFundsReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_SIMILAR_FUNDS_DATA:
            return {
                ...state,
                similarFundsData: action.similarFundsData,
                ActiveFund: action.ActiveFund,
                loading: action.loading,
                isFundViewEntitled: action.isFundViewEntitled
            };
        case ActionTypes.UPDATE_SIMILAR_FUNDS_RELATIVE: {
            state.SimilarFundSettings.IsRelativeYTD = !state.isRelative;
            SettingStore.saveSettings();
            return {
                ...state,
                isRelative: !state.isRelative
            }
        }
        case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
            return {
                ...initState
            }
        default:
            return state;
    }
};

export default RiPanelSimilarFundsReducer;
