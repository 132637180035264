class NotificationSettingsHelper {

      constructor() {
      }

      updateUserNotificationSettings(viewSettings, userNotificationSettings) {
            viewSettings.UserNotificationSettings = userNotificationSettings
      }
}

const notificationSettingsHelper = new NotificationSettingsHelper();
export default notificationSettingsHelper;
