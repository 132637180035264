import { OwnerShipBlockConstants } from '../Constants/OwnerShipBlock';
const { ActionTypes } = OwnerShipBlockConstants;

export const getOwnershipBlockData = (userSettings, symbol, beginDate, endDate, graphType, periodicity) => ({
    type: ActionTypes.OWNERSHIP_BLOCK_GET_API_DATA,
    userSettings,
    symbol,
    beginDate,
    endDate,
    graphType,
    periodicity
})

export const onOwnershipAumToggle = () => ({
    type: ActionTypes.OWNERSHIP_BLOCK_AUM_TOGGLE
})

export const onOwnershipFundToggle = (side) => ({
    type: ActionTypes.OWNERSHIP_BLOCK_FUND_TOGGLE,
    side
})

export const onOwnershipBlockToggleView = (item = null) => ({
    type: ActionTypes.OWNERSHIP_BLOCK_VIEW_TOGGLE,
    item
})

export const onOwnerShipSizeChanged = (size) => ({
    type: ActionTypes.OWNERSHIP_BLOCK_SIZE_CHANGED,
    size
})

export const onOwnerShipListChanged = (item) => ({
    type: ActionTypes.OWNERSHIP_BLOCK_LIST_CHANGED,
    item
})

export const openCollapseOwnerShip = (blockType, isopen) => ({
    type: ActionTypes.OWNERSHIP_BLOCK_OPEN_COLLAPSE,
    blockType, isopen
})

export const prepareHeaderMenu = () => ({
    type: ActionTypes.OWNERSHIP_BLOCK_PREPARE_HEADERMENU
})

export const showHideDogEar = (isDogEar = false) => ({
    type: ActionTypes.OWNERSHIP_BLOCK_DOG_EAR_TOGGLE,
    isDogEar
})