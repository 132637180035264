import { connect } from "react-redux";
import Measure from "react-measure";
import ReactHtmlParser from "react-html-parser";
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import ThemeHelper from "ThemeHelper";
import React, { Component } from "react";

class TabularView extends Component {

  constructor(props) {
    super(props);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.state = {
      height: 0.0,
      width: 0.0,
      hover: false,
      dogEarContentHeight: 42,
    };
    this.isMac = navigator.platform.indexOf("Mac") === 0,
      this.source = [],
      this.headerCellWidth = 128,
      this.cellWidth = 46,
      this.columnCount = 9,
      this.rowCount = 6,
      this.borderColorText = ThemeHelper.getThemedBrush("summaryBlockBorderColor")
  }

  componentDidMount() {
    if (this.props.id === "annualData") {
      this.source = this.props.reducerAnnualData
    }
    else {
      this.source = this.props.reducerWeeklyData
    }
  }

  handleResize(contentRect) {
    this.setState({ height: contentRect.bounds.height, width: contentRect.bounds.width });
  }

  renderGrid() {
    if (!this.gridCanvas ||
      this.headerCellWidth === 0.0 ||
      this.cellWidth === 0.0 ||
      this.columnCount === 0 ||
      this.rowCount === 0 ||
      this.state.height === 0.0 ||
      this.state.width === 0.0
    ) {
      return;
    }
    const borderColor = this.borderColorText;
    const ctx = this.gridCanvas.getContext("2d");
    ctx.canvas.height = this.state.height;
    ctx.canvas.width = this.state.width;
    ctx.clearRect(0, 0, this.state.width, this.state.height);
    ctx.lineWidth = 1;
    ctx.translate(0.5, 0.5);
    ctx.strokeStyle = borderColor;
    ctx.save();
    this.drawVerticalLines(ctx);
    this.drawHorizontalLines(ctx);
  }

  drawHorizontalLines(ctx) {
    const rowheight = Math.round(this.state.height / (this.rowCount + 1));
    ctx.beginPath();
    ctx.moveTo(0.0, rowheight - 1);
    ctx.lineTo(this.state.width, rowheight - 1);
    ctx.stroke();
    const count = this.rowCount;
    for (let i = 1; i <= count; i++) {
      const yValue = ((i * rowheight) - 1);
      ctx.moveTo(0.0, yValue);
      ctx.lineTo(this.state.width, yValue);
    }
    ctx.stroke();
    ctx.closePath();
  }

  drawVerticalLines(ctx) {
    ctx.beginPath();
    ctx.moveTo(this.headerCellWidth, 0.0);
    ctx.lineTo(this.headerCellWidth, this.state.height);
    ctx.stroke();
    const colCount = this.columnCount;
    for (let i = 1; i < colCount; i++) {
      const xValue = (i * this.cellWidth) + this.headerCellWidth;
      ctx.moveTo(xValue, 0.0);
      ctx.lineTo(xValue, this.state.height);
    }
    ctx.stroke();
    ctx.closePath();
  }

  getCellClassName(cellIndex, index) {
    let className = "tabularView_cell";
    if (cellIndex === 0 && index !== 0) {
      className = "tabularView_cell swatch-color";
    }
    return className;
  }

  getCell(cell, cellIndex, index, top) {
    const cellLineHeight = this.isMac ? "16px" : "15px";
    const dogEar = this.getDogEar(cell, top);
    let width = "0px";
    let color = cell.cell ? cell.cell.color : undefined;

    //apply custom color
    if (cell.cell && cell.cell.isUseCusColor) {
      color = cell.cell.isPositive ? this.props.posDTColor
        : this.props.negDTColor;
    }

    const textAlign = cell.cell ? cell.cell.textAlign : "center";
    if (cellIndex > 0) {
      width = `${this.cellWidth}px`;
    }
    else {
      width = `${this.headerCellWidth}px`;
    }
    if (cell.cell && (cell.cell.value || cell.cell.value === 0)) {
      return (<div className={this.getCellClassName(cellIndex, index)}
        style={{ color: color, width: width, textAlign: textAlign }}
        key={`r${index}c${cellIndex}`}>
        <span className="tabularView_cell_text" style={{ lineHeight: cellLineHeight }}> {cell.cell.value} </span>
        {dogEar}
      </div>);
    } else {
      return (
        <div className={this.getCellClassName(cellIndex, index)} style={{ color: color, width: width, textAlign: textAlign }} key={`r${index}c${cellIndex}`}>&nbsp; {ReactHtmlParser("&nbsp;")}</div>
      );
    }
  }

  getRow(row, index) {
    if (this.state.height === 0.0) {
      return;
    }
    const rowHeight = Math.round(this.state.height / (this.rowCount + 1));
    const top = (index * rowHeight);
    if (index === 1) {
      return (<div className={this.getClassName(index)}
        onMouseOver={() => this.syncHover(true)}
        onFocus={() => this.syncHover(true)}
        onMouseLeave={() => this.syncHover(false)} key={index}
        style={{ height: rowHeight, top: top }}>
        {row.cells.map((cell, cellIndex) => this.getCell(cell, cellIndex, index, top))}
      </div>);
    }
    return (<div className={this.getClassName(index)} key={index}
      style={{ height: rowHeight, top: top }}>
      {row.cells.map((cell, cellIndex) => this.getCell(cell, cellIndex, index, top))}
    </div>);
  }

  syncHover(hover) {
    this.setState({ hover: hover });
  }

  getClassName(index) {
    let className = "tabular-header";
    if (index === 0) {
      className = `${className} tabular-title head`;
    }
    if (index === 1 && this.state.hover) {
      className = `${className} hover`;
    }
    return className;
  }

  getDogEar(data, top) {
    if (data.cell.showCarryOver) {
      return (<div ref={(ref) => { this.dogEar = (ref) }}
        className="dogEar"
        onMouseDown={(e) => this.handleMouseDown(e, top, true)}
        onMouseLeave={this.handleMouseDown}
      >
      </div>);
    } else {
      return "";
    }
  }
  getDogEarContent() {
    return (<div className="dogEarMain dogear-summery-block-tabular"
      //height: this.state.dogEarContentHeight, 
      style={{ left: this.state.dogEar.x, top: this.state.dogEar.y }}
    >
      <div className="deBorder">
        <div className="deContent">
          {this.state.dogEar.text}
        </div>
      </div>
    </div>);
  }

  showDogEar(dogEarObj) {
    this.setState({ dogEar: dogEarObj });
  }

  handleMouseDown(e, top, show = false) {
    const pretaxText = SummaryTransLateHelper.HOVER_PRETAX_TEXT
    if (show) {
      this.showDogEar({ x: e.target.offsetParent.offsetLeft - 50, y: top - this.state.dogEarContentHeight, text: pretaxText });
    }
    else {
      this.showDogEar();
    }
  }

  render() {
    this.renderGrid();
    return (
      <Measure
        bounds
        onResize={(contentRect) => { this.handleResize(contentRect) }}
      >
        {({ measureRef }) =>
          <div ref={measureRef} className="tabular-hold">
            <div ref={(ref) => { this.mainDiv = (ref) }} className="tabular-box">
              <canvas className="tabular-canvas" ref={(ref) => { this.gridCanvas = (ref) }}></canvas>
              {this.source.map((row, index) => this.getRow(row, index))}
              {this.state.dogEar && this.getDogEarContent()}
            </div>
          </div>
        }
      </Measure>
    );
  }
}

const mapStateToProps = ({ summaryReducer, appColor }) => {
  const reducerAnnualData = summaryReducer.annualdata.rowSource
  const reducerWeeklyData = summaryReducer.weeklyData.rowSource;
  const { posDTColor, negDTColor } = appColor;
  return { posDTColor, negDTColor, reducerAnnualData, reducerWeeklyData }
}
export default connect(mapStateToProps, null)(TabularView)