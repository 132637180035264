import React from "react";
import TimeTrackingWindow from "TimeTrackingWindow";

export default class ClusterControl extends React.Component {
    constructor() {
        super()
    }

    render() {
        TimeTrackingWindow.trackChartLoadingTime();
        const DragHandle = this.props.dragHandle;
        const indicatorName = this.props.indicatorName;
        
        // const name = this.props.name;
        return (
            <span className="flyout-cluster-btn">
                <a></a>
                {/* <a onMouseOver={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp}></a> */}
                {DragHandle && <DragHandle indicatorslength={this.props.indicatorslength} indicatorName={indicatorName} />}
            </span>
        );
    }
}
