import { FinancialBlockDialogConstants } from "../../../../Constants/FinancialBlockDialogConstants";
const { ActionTypes } = FinancialBlockDialogConstants;

const initialState = {
    CurrentHeaders: [],
    CurrentPageIndex: 1,
    HeaderTabItemSource: [],
    IsFinancialBlockDialogOpen: false,
    ItemDataList: [],
    LargeBlockSize: 14,
    PickListItems1: [],
    PickListItems2: [],
    PickListItems3: [],
    selectedPages: {},
    IsFinancalRestoreDialogOpen: false
}

function getSelectedPage(CurrentPageIndex, HeaderTabItemSource) {
    return HeaderTabItemSource[CurrentPageIndex - 1];
}

const financialDialogBoxReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_FINANCIALBLOCK_DIALOG_DATA:
            return { ...state, ...action.newState }

        case ActionTypes.FINANCIALBLOCK_DIALOG_BACK: {
            const selectedPage = getSelectedPage(state.CurrentPageIndex, state.HeaderTabItemSource);
            selectedPage.IsShowDetail = false;
            return { ...state, selectedPages: { ...selectedPage } }
        }

        case ActionTypes.FINANCIALBLOCK_DIALOG_CLEAR_SEARCHTEXT: {
            const selectedPage = getSelectedPage(state.CurrentPageIndex, state.HeaderTabItemSource);
            selectedPage.SearchText = "";
            selectedPage.FilterSource = [];
            const clearMouseOverItem = selectedPage.PickListItems.find((item) => item.IsMouseOver);
            if (clearMouseOverItem) { clearMouseOverItem.IsMouseOver = false; }
            state.selectedPages = selectedPage
            return { ...state, selectedPages: { ...selectedPage } }
        }

        case ActionTypes.FINANCIAL_BLOCK_UPDATE_STATE: {
            if (typeof (action.data) === 'boolean') {
                state.IsFinancialBlockDialogOpen = action.data
            }
            else if (typeof (action.data) === 'object') {
                state.IsFinancalRestoreDialogOpen = action.data.IsFinancalRestoreDialogOpen
            } else {
                const selectedPage = getSelectedPage(action.data, state.HeaderTabItemSource)
                state.CurrentPageIndex = action.data
                state.selectedPages = selectedPage
            }
            return { ...state }
        }

        case ActionTypes.FINANCIALBLOCK_DIALOG_SELECT_ROWITEM_CHANGED: {
            const selectedPage = getSelectedPage(state.CurrentPageIndex, state.HeaderTabItemSource);
            const rowItem = action.rowItem;
            if (selectedPage) {
                selectedPage.IsShowDetail = true;
                const isFilter = selectedPage.SearchText.trimLeft().length > 0;
                const source = isFilter ? selectedPage.FilterSource : selectedPage.PickListItems;
                const previousSelectedRowItem = source.find((item) => item.IsSelected);
                if (previousSelectedRowItem) { previousSelectedRowItem.IsSelected = false; }

                const currentSelectedRowItem = source.find((item) => item.HeaderId === (rowItem.HeaderData.canToggle ? rowItem.HeaderData.ActiveHeader.HeaderId : rowItem.HeaderData.HeaderId));
                if (currentSelectedRowItem) { currentSelectedRowItem.IsSelected = true; }

                selectedPage.SelectedRowIndex = rowItem.RowIndex;
            }

            return { ...state, selectedPages: { ...selectedPage } }
        }

        case ActionTypes.FINANCIALBLOCK_DIALOG_METRICITEM_MOUSEOVER: {
            const selectedPage = getSelectedPage(state.CurrentPageIndex, state.HeaderTabItemSource);
            const isFilter = selectedPage.SearchText.trimLeft().length > 0;
            const HeaderID = action.headerId
            const IsmouseOver = action.isMouseOver
            if (selectedPage && selectedPage.PickListItems !== undefined && selectedPage.FilterSource !== undefined) {
                let mouseEnterItem = undefined
                if (isFilter) {
                    mouseEnterItem = selectedPage.FilterSource.find((item) => item.HeaderId === HeaderID);
                } else {
                    mouseEnterItem = selectedPage.PickListItems.find((item) => item.HeaderId === HeaderID);
                }
                if (mouseEnterItem) { mouseEnterItem.IsMouseOver = IsmouseOver; }
            }
            return { ...state, selectedPages: { ...selectedPage } }
        }

        case ActionTypes.FINANCIALBLOCK_DIALOG_REORDER_ROWITEM: {
            const rowItems = action.rowItems;
            const selectedPage = getSelectedPage(state.CurrentPageIndex, state.HeaderTabItemSource);
            if (selectedPage) {
                for (let index = 0; index < rowItems.length; index++) {
                    const rowItem = rowItems[index]
                    rowItem.RowIndex = index + 1;
                }
                selectedPage.IsDirty = true;
                selectedPage.RowItems = rowItems;
            }

            return { ...state, selectedPages: { ...selectedPage } }
        }

        case ActionTypes.FINANCIALBLOCK_DIALOG_UPDATE_CHANGES: {
            return { ...state, ...action.newState }
        }

        case ActionTypes.FINANCIALBLOCK_DIALOG_INPUTBOX_UPDATE:
            return { ...state, selectedPages: { ...action.newState.selectedPage } }

        default:
            return { ...state }
    }
}
export default financialDialogBoxReducer
