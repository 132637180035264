import Serializable from "Serializable";
import DefaultSettings from "./DefaultSettings.js";
import NavType from "NavType";
import { PrintMode } from "../../../../print/printmode";
import ThemeType from "ThemeType";
class ConsoleSetting extends Serializable {

  onSeralisedInfoRecieved(serializedValue) {
    let defaultsetting = this.getDefaultConsoleSetting();
    this.NavDatagraphSettings = serializedValue.NavDatagraphSettings ? serializedValue.NavDatagraphSettings : defaultsetting.NavDatagraphSettings;
    this.NavListManagerSettings = serializedValue.NavListManagerSettings ? serializedValue.NavListManagerSettings : defaultsetting.NavListManagerSettings;
    this.NavCompareSettings = serializedValue.NavCompareSettings ? serializedValue.NavCompareSettings : defaultsetting.NavCompareSettings;
    this.NavNewsSettings = serializedValue.NavNewsSettings ? serializedValue.NavNewsSettings : defaultsetting.NavNewsSettings;
    this.currentTheme = PrintMode.printing ? ThemeType.Light : serializedValue.currentTheme ? serializedValue.currentTheme : defaultsetting.currentTheme;
    this.ActiveNav = PrintMode.printing ? PrintMode.ActiveNav : serializedValue.ActiveNav ? serializedValue.ActiveNav : NavType.NavSymbol;
    this.gridLineDark = !isNaN(serializedValue.gridLineDark) ? serializedValue.gridLineDark : defaultsetting.gridLineDark;
    this.gridLineLight = PrintMode.printing && serializedValue.currentTheme === ThemeType.Dark ? (255-serializedValue.gridLineDark) : !isNaN(serializedValue.gridLineLight) ? serializedValue.gridLineLight : defaultsetting.gridLineLight;
    this.UserNotificationSettings = serializedValue.UserNotificationSettings ? serializedValue.UserNotificationSettings : defaultsetting.UserNotificationSettings;
    this.AppColorSettings = serializedValue.AppColorSettings ? serializedValue.AppColorSettings : defaultsetting.AppColorSettings;
    this.isUploadedExternalData = serializedValue.isUploadedExternalData ? serializedValue.isUploadedExternalData : false;
    this.isCurrencyConversionChecked = serializedValue.isCurrencyConversionChecked ? serializedValue.isCurrencyConversionChecked : false;
    this.isMatchFinancials = serializedValue.isMatchFinancials !== undefined ? serializedValue.isMatchFinancials : true;
    this.selectedCurrencyCode = serializedValue.selectedCurrencyCode ? serializedValue.selectedCurrencyCode : 'USD';
    this.isDualCurrency = serializedValue.isDualCurrency ? serializedValue.isDualCurrency : false;
    this.defaultCurrency = serializedValue.defaultCurrency ? serializedValue.defaultCurrency : 'USD';
    this.originalTheme = serializedValue.currentTheme ? serializedValue.currentTheme : defaultsetting.currentTheme;
    this.printSettings = serializedValue.printSettings ? PrintMode.printing ? serializedValue.printSettings : this.getDefaultPrintSettings(serializedValue.printSettings) : this.getDefaultPrintSettings();
  }
  getDataObject() {
    return {
      NavDatagraphSettings: this.NavDatagraphSettings,
      NavListManagerSettings: this.NavListManagerSettings,
      NavCompareSettings: this.NavCompareSettings,
      NavNewsSettings: this.NavNewsSettings,
      UserNotificationSettings: this.UserNotificationSettings,
      currentTheme: this.currentTheme,
      ActiveNav: this.ActiveNav,
      gridLineDark: this.gridLineDark,
      gridLineLight: this.gridLineLight,
      AppColorSettings: this.AppColorSettings,
      isUploadedExternalData: this.isUploadedExternalData,
      isCurrencyConversionChecked: this.isCurrencyConversionChecked,
      isMatchFinancials: this.isMatchFinancials,
      selectedCurrencyCode: this.selectedCurrencyCode,
      isDualCurrency: this.isDualCurrency,
      defaultCurrency:this.defaultCurrency,
      originalTheme: this.originalTheme,
      printSettings: this.printSettings
    };
  }

  getObjectMapKey() {
    return "ConsoleSetting";
  }

  getDefaultConsoleSetting() {
    return DefaultSettings.getDefaultSettings();
  }
  getDefaultPrintSettings(serializedValue) {
    const print = new Serializable();
    print.showEPSDate = false;
    print.showIPODate = false;
    print.externalScore = new Serializable();
    print.userEndDate = '';
    print.windowWidth = window.screen.width;
    print.windowHeight = window.screen.height;
    print.visibleMeasurementAnno = [];
    print.CurrencySummarySelectedCurrencyIndex = 0;
    print.dragX = serializedValue && serializedValue.dragX ? serializedValue.dragX : 0;
    print.dragY = serializedValue && serializedValue.dragY ? serializedValue.dragY : 0;
    return print;
}


}
export default ConsoleSetting;
