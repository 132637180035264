import BaseServiceApi from "BaseServiceApi";
import { Glyphicon } from "react-bootstrap";
import HubNavigation from "./RiHubPanel/HubNavigation";
import { ListType } from "../../../../../Constants/HubRIPanelConstants.js";
import OwnershipConstants from "../../../../../Constants/OwnershipConstants.js";
import ReactHtmlParser from "react-html-parser";
import RiHubPanelInfo from "./RiHubPanel/RiHubPanelInfo";
import ThemeHelper from "ThemeHelper";
import UserInfoUtil from "UserInfoUtil";
import { useSelector } from 'react-redux';
import VirtualList from "VirtualList";
import React, { useRef } from "react";
import { RiPanelCheckListTransLateHelper, TranslateHelper } from "../../../../../Utils/TranslateHelper";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const RiPanelOwnershipInfo = () => {
    const isFundViewEntitledText = UserInfoUtil.IsUserEntitled(EntitlementType.FUNDS_VIEW_ACNTMGR_Entitlement);
    const RIPanelOwnershipList = useRef();
    const state = useSelector((state) => state.RelatedInfoPanelReducers.RiPanelOwnershipInfo);

    const getIconTemplate = (value) => {
        const tempPositiveStyle = {}
        const tempNegativeStyle = {}
        tempPositiveStyle["color"] = ThemeHelper.getThemedBrush("positiveDataText")
        tempNegativeStyle["color"] = ThemeHelper.getThemedBrush("negativeDataText")

        switch (value) {
            case OwnershipConstants.OwnershipInc:
                return <Glyphicon glyph="arrow-up blueText xxx-small-normal" style={tempPositiveStyle} />;
            case OwnershipConstants.OwnershipDec:
                return <Glyphicon glyph="arrow-down pinkText xxx-small-normal" style={tempNegativeStyle} />;
            case OwnershipConstants.OwnershipUnchg:
                return <Glyphicon glyph="minus greyText xxx-small-normal" />;
            case OwnershipConstants.OwnershipNew:
                return <span className="icon-record blueText small-normal xxx-small-normal"></span>;
            case OwnershipConstants.OwnershipClosed:
                return <Glyphicon glyph="ban-circle greyText xxx-small-normal" />;
            case OwnershipConstants.OwnershipTop:
                return <Glyphicon glyph="" />;
            default:
                return null;
        }
    }

    const getItemRenderer = (item, top, index) => {
        if (item) {
            const ownershipIcon = getIconTemplate(item.position);
            return (<div className="ownership-item x-small-normal" key={index} style={{ top: top }}>
                <div className="ownership-item-column column-1" title={item.sponsorName}>
                    {item.isClient && isFundViewEntitledText ? <div className="active-client" /> : <div className="inactive-client" />}
                    {item.sponsorNameAbbr}
                </div>
                <div className="ownership-item-column column-2">{item.pctOfPort}</div>
                <div className="ownership-item-column column-3 active-orderBy-column">
                    {ownershipIcon}
                    <span className="item-sharesHeld" title={item.sharesHeld}>{item.sharesHeld}</span>
                </div>
            </div>)
        }
    }

    const getOwnershipView = () => {
        const ownershipList = state.OwnershipDatasource;
        return <div className="ownership-content">
            {state.loading ? <div className="news-message large-normal"><p>{TranslateHelper["Loading"]}</p></div> :
                ownershipList.length === 0 ?
                    <div className="news-message large-normal"><p>{TranslateHelper["No_Data_Available"]}</p></div> :
                    <VirtualList id="RIPanelOwnershipInfo" ref={RIPanelOwnershipList}
                        items={ownershipList}
                        itemHeight={26}
                        itemRenderer={(item, top, index) => getItemRenderer(item, top, index)}
                        showScrollOnHover={true}
                    />}
        </div>
    }

    const hubRole = state.hubUserRole;
    const ownershipView = getOwnershipView();
    const { listType } = state;

    return (
        <>
            <div className="ownershipInfo">
                <HubNavigation isFundViewEntitledText={isFundViewEntitledText} />
                {listType === ListType.HubListView && <RiHubPanelInfo hubRole={hubRole} />}

                {listType === ListType.TopOwnerView && <React.Fragment>
                    <div className="ownership-header xx-small-normal">
                        <div className="ownership-header-column column-header-1">{RiPanelCheckListTransLateHelper.OWNERSHIP_INFO_OWNER_NAME}</div>
                        <div className="ownership-header-column column-header-2">
                            {ReactHtmlParser(RiPanelCheckListTransLateHelper.OWNERSHIP_INFO_PORTFOLIO)}</div>
                        <div
                            className="ownership-header-column column-header-3 active-orderBy-column active-orderBy-background">
                            {ReactHtmlParser(RiPanelCheckListTransLateHelper["OWNERSHIP_INFO_RECENT_SHARES"])}</div>
                    </div>
                    {ownershipView}
                </React.Fragment>
                }
            </div>
        </>
    )
}

export default RiPanelOwnershipInfo