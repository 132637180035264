import { OwnershipActionConstants } from "../../Constants/OwnershipActionConstants";

const { ActionTypes } = OwnershipActionConstants;

const initialState = {
    osid: -1,
    headerData: [],
    reportExtData: [],
    searchText: '',
    textSize: "xx-small-normal",
    limitData: true,
    limitDataCompleted : false,
    isColumnRankChanged: true,
    isColumnPortChanged: true,
    isColumnSharesChanged: true,
    isLoading: true,
    orderSeqArr: [{columnName: "SharesHeld", order: "desc"}],
    mainState: { currentTab: 0, currentSubTab: 0, showIndex: 1, SeqNumber: 0, ownerType: 0, orderString: "", pageSize: 0, topOwner: 0, isTopOwner: 0, isByOwner: 0, resultCount: 150 },
    subCategory: { AllActive: "active", MutualFundActive: "", HedgeFundActive: "", OthersAllActive: "", CorporationsActive: "", IndividualsActive: "", InsuranceActive: "", otherManagersActive: "", ByTopOwnersActive: "", ByPositionTypeActive: "", ByOwnerTypeActive: "" },
    resizeCounter: 1
}

export default function ownership(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_OWNERSHIP_REPORT_DATA_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData, limitData: action.result.limitData, isLoading: false, osid: action.result.osid, searchText : '', limitDataCompleted  :action.result.limitDataCopy };
        case ActionTypes.OWNERSHIP_REPORT_CHANGE_TEXT_SUCCESS:
            return { ...state, textSize: action.result };
        case ActionTypes.OWNERSHIP_TAB_RESIZE_SUCCESS:
            return { ...state, resizeCounter: state.resizeCounter + 1 };
        case ActionTypes.OWNERSHIP_COLUMN_RANK_CHANGED:
            return { ...state, isColumnRankChanged: action.result };
        case ActionTypes.OWNERSHIP_SEARCH_TEXT_CHANGE:
            return { ...state, searchText: action.result };
        case ActionTypes.OWNERSHIP_COLUMN_PORT_CHANGED:
            return { ...state, isColumnPortChanged: action.result };
        case ActionTypes.OWNERSHIP_COLUMN_SHARES_CHANGED:
            return { ...state, isColumnSharesChanged: action.result };
        case ActionTypes.OWNERSHIP_SHOW_LOADER:
            return { ...state, isLoading: action.result }
        case ActionTypes.GET_OWNERSHIP_REPORT_MORE_DATA_SUCCESS:
            // let resData = state.reportExtData.resultData.data;
            // resData[0].reportList = resData[0].reportList.concat(action.result.resultData.data[0].reportList);
            // resData[1].reportList = resData[1].reportList.concat(action.result.resultData.data[1].reportList);
            // resData[2].reportList = resData[2].reportList.concat(action.result.resultData.data[2].reportList);
            // resData[3].reportList = resData[3].reportList.concat(action.result.resultData.data[3].reportList);
            //state.reportExtData.resultData.data = action.result.resultData.data;

            return { ...state, reportExtData: action.result.resultData, limitData: action.result.limitData, limitDataCompleted : true };
        
        case ActionTypes.OWNERSHIP_COLUMN_SORT_CHANGED_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid, limitDataCompleted : action.result.limitDataCopy  }
            
        case ActionTypes.OWNERSHIP_LIMIT_CHANGE_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid, searchText: action.result.limitData ? '' : state.searchText, limitDataCompleted : false }
        
        case ActionTypes.OWNERSHIP_TAB_CHANGE_SUCCESS:
        case ActionTypes.OWNERSHIP_SUB_TAB_CHANGE_SUCCESS:    
        case ActionTypes.OWNERSHIP_PASSIVE_OWNER_FILTER_CHANGE_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid, searchText: '', limitDataCompleted : action.result.limitDataCopy }
        case ActionTypes.CLEAR_OWNERSHIP_REPORT_DATA:
            return { ...state, headerData: [],isLoading: action.result };
        default:
            return state;
    }
}
