import AlertConditionType from "../../Constants/AlertConditionType";
import { AlertConstants } from "../../Constants/AlertConstants";
import AlertsApi from "../../ServiceApi/Apis/AlertsApi";
import AlertSourceType from "../../Constants/AlertSourceType";
import BaseServiceApi from "BaseServiceApi";
import ConsoleActions from "../../Actions/ConsoleActions";
import DatagraphHelper from "../../Utils/DatagraphHelper";
import { getAlertDailogStates } from "../../Reducers/Alert/selector";
import ListApi from "../../ServiceApi/Apis/ListApi";
import ListExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import StringUtil from "../../Utils/StringUtil";
import { updateAlertDataObject } from "../../Actions/AlertActions";
import _, { chain, each, sortBy } from "underscore";
import { call, put, select, takeLatest } from "redux-saga/effects";

const ListType = BaseServiceApi.rayData["ListType"];
const ListAlerts = BaseServiceApi.rayData["ListAlerts"];

function* openAndAlertDialog({ msId, instrumentId, symbol, sourceTypeId, conditionTypeId, alertId, currencyCode, existAlerts, isTriggered }) {
    try {
        if (currencyCode) {
            currencyCode = DatagraphHelper.getCurrencyCode({ Code: currencyCode }, false);
        }
        const { alertDataObj } = yield select(getAlertDailogStates);
        const result = conditionTypeId === AlertConditionType.Or ? yield call(AlertsApi.listAlertsRetrieve, msId, sourceTypeId) : yield call(AlertsApi.andAlertRetrieve, msId, sourceTypeId, alertId, msId, currencyCode)
        if (!result.responseHeader.error) {
            alertDataObj.alerts = conditionTypeId === AlertConditionType.And ? result.alerts.listAlertData : result.alerts;
            alertDataObj.alerts.sourceId = msId;
            alertDataObj.alerts.sourceTypeId = sourceTypeId;
            alertDataObj.priceAlert = conditionTypeId === AlertConditionType.And ? result.alerts.priceAlert : null;
            yield call(initializeListSettings, { msId, instrumentId, symbol, sourceTypeId, conditionTypeId, alertId, currencyCode, existAlerts, isTriggered }, result, alertDataObj)
            setAlertSettings( conditionTypeId === AlertConditionType.And ? result.alerts.listAlertData.alertDataSetting : result.alerts.alertDataSetting, alertDataObj);
    
    
            if (conditionTypeId === AlertConditionType.And) {
                let selectedCriteriaCount = 0;
                _.each(alertDataObj.listAlertSettings, (val, key) => {
                    if ((key.lastIndexOf("Selected") === key.length - 8) || (key.lastIndexOf("Checked") === key.length - 7)) {
                        if (val === true) {
                            selectedCriteriaCount++;
                        }
                    }
                });
                if (alertDataObj.priceAlert) {
                    selectedCriteriaCount++;
                }
    
                if (selectedCriteriaCount === 0) //TO DO SK Add PriceAlert condition also
                {
                    alertDataObj.listAlertSettings.PriceChangeSelected = true
                    alertDataObj.listAlertSettings.DailyVolumeRateSelected = true
                }
            }
    
            yield put(updateAlertDataObject(alertDataObj, false))
        }
        else {
            ConsoleActions.exceptionHandle();
        }
    } catch (error) {
        console.log(`Error occurs in AlertDialogSaga.js, openAndAlertDialog, ${error}`);
    }
    
}

function* initializeListSettings(data, result, alertDataObj) {
    alertDataObj.priceVolumeAlertable = true;

    alertDataObj.sourceId = data.msId;
    alertDataObj.sourceTypeId = data.sourceTypeId
    if (data.sourceTypeId === AlertSourceType.List) {

        let _listTreeItem = null;

        _listTreeItem = ListExplorerStore.contextNode;

        alertDataObj.alertTitle = data.conditionTypeId === AlertConditionType.Or ? LocalizationStore.getTranslatedData("alert_list_title", "List Alerts") : "Select Up To 2 Alert Criteria";

        if (!StringUtil.isEmpty(_listTreeItem)) {
            const listName = _listTreeItem.name;
            alertDataObj.listName = listName;
            const chrLimit = 120,
                str = listName,
                setStr = `<span class="list-name-ellipsis">${str.substring(0, chrLimit)}</span>`,
                toolTips = str.length > chrLimit ? `<span class="ellipsis-dot"><span class="tool-tip">${str}</span></span>` : "";
            alertDataObj.listAlertSubTitle = result.alerts.listAlertData ? LocalizationStore.getTranslatedData("ch_msa_sup_aca", "And-condition Alerts") : LocalizationStore.getTranslatedData("ch_msa_sup_oca", "Or-condition Alerts");
            alertDataObj.alertSubTitle = `<div class="tool-tip-box">${LocalizationStore.getTranslatedData("alert_list_msg", `Alert me when any symbol on ${setStr}${toolTips} meets ANY of the following conditions:`).replace("{0}", setStr + toolTips)}</div>`;
            alertDataObj.addRemoveAlertable = ListExplorerStore.isXflList(_listTreeItem) || ListExplorerStore.isPortfolioList(_listTreeItem)
                || ListExplorerStore.isGflList(_listTreeItem) || ListExplorerStore.isApList(_listTreeItem);
        }

        setGeographyFilterData(result, alertDataObj);
        yield call(populateColumnSets, result.alerts.listAlertData ? result.alerts.listAlertData.columnSet : result.alerts.columnSet, data.msId, result.alerts.listAlertData ? result.alerts.listAlertData.nocolumnSet : result.alerts.nocolumnSet, data.existAlerts, alertDataObj)
        alertDataObj.corporateEventAlertable = true;
    }
    else if (data.sourceTypeId === AlertSourceType.Stock) {

        alertDataObj.alertTitle = data.conditionTypeId === AlertConditionType.Or ? LocalizationStore.getTranslatedData("ch_msa_sup_oca", "Or-condition Alerts") : "Select Up To 2 Alert Criteria";

        alertDataObj.alertSubTitle = LocalizationStore.getTranslatedData("ch_msa_msgalert", "Alert me when <b>ANY</b> of the selected conditions are met:"); //' + data.symbol + ' meets
        alertDataObj.corporateEventAlertable = (data.instrumentId ? data.instrumentId : data.msId) <= 3000000;

        alertDataObj.listAlertSubTitle = "";

        alertDataObj.addRemoveAlertable = false;
    }
}

function setAlertSettings(alertDataSetting, alertDataObj) {
    const listalertDataSettingList = alertDataSetting;
    listalertDataSettingList.forEach((item) => {
        switch (item.alertTypeId) {
            case ListAlerts.Additions:
                alertDataObj.listAlertSettings.AdditionSelected = item.isEnabled;
                break;
            case ListAlerts.Removals:
                alertDataObj.listAlertSettings.RemovalSelected = item.isEnabled;
                break;
            case ListAlerts.Dailypricechangeexceeds:
                alertDataObj.listAlertSettings.PriceChangeSelected = item.isEnabled;
                alertDataObj.listAlertSettings.PriceChangeThreshold = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                break;
            case ListAlerts.DailyVolumeRateexceeds:
                alertDataObj.listAlertSettings.DailyVolumeRateSelected = item.isEnabled;
                alertDataObj.listAlertSettings.DailyVolumeRateThreshold = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                if (item.isDelayEnabled && item.isEnabled) {
                    alertDataObj.listAlertSettings.DailyVolumeRateDelaySelected = item.isDelayEnabled;
                } else {
                    alertDataObj.listAlertSettings.DailyVolumeRateDelaySelected = false;
                }
                if (item.isEnabled && item.delayTime) {
                    alertDataObj.listAlertSettings.DailyVolumeRateDelayThreshold = item.delayTime;
                } else {
                    alertDataObj.listAlertSettings.DailyVolumeRateDelayThreshold = 0;
                }
                break;
            case ListAlerts.WeeklyVolumeRateexceeds:
                // WeeklyVolumeRateSelected = item.isEnabled;
                // WeeklyVolumeRateThreshold = item.targetValue;
                break;
            case ListAlerts.AverageDailyVolumecrosses:
                alertDataObj.listAlertSettings.AverageDlyVolSelected = item.isEnabled;
                alertDataObj.listAlertSettings.AverageDlyVolThreshold = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                break;
            case ListAlerts.AverageDailyDlrVolumecrosses:
                alertDataObj.listAlertSettings.AverageDlyDlrVolSelected = item.isEnabled;
                alertDataObj.listAlertSettings.AverageDlyDlrVolThreshold = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                break;
            case ListAlerts.DailyVolumeExceeds50SMA:
                alertDataObj.listAlertSettings.DailyVolumeExceeds50SMASelected = item.isEnabled;
                break;
            case ListAlerts.BreakingOutToAllTimeHigh:
                alertDataObj.listAlertSettings.BreakingOutToAllTimeHighSelected = item.isEnabled;
                break;
            case ListAlerts.RSReachesNewHigh:
                alertDataObj.listAlertSettings.RSReachesNewHighSelected = item.isEnabled;
                break;
            case ListAlerts.SMAdaily50:
                alertDataObj.listAlertSettings.MovingAvg50SmaSelected = item.isEnabled;
                break;
            case ListAlerts.SMAdaily200:
                alertDataObj.listAlertSettings.MovingAvg200SmaSelected = item.isEnabled;
                break;
            case ListAlerts.SMA50200crossoverdaily:
                alertDataObj.listAlertSettings.MovingAvg50Cross200Selected = item.isEnabled;
                break;
            case ListAlerts.DailyNewBasedetected:
                alertDataObj.listAlertSettings.DailyBaseChecked = item.isEnabled;
                break;
            case ListAlerts.WeeklyNewBasedetected:
                alertDataObj.listAlertSettings.WeeklyBaseChecked = item.isEnabled;
                break;
            case ListAlerts.TightAreadetected:
                alertDataObj.listAlertSettings.WeeklyTightChecked = item.isEnabled;
                break;
            case ListAlerts.DailyFailedBasedetected:
                alertDataObj.listAlertSettings.DailyFailedBaseChecked = item.isEnabled;
                break;
            case ListAlerts.WeeklyFailedBasedetected:
                alertDataObj.listAlertSettings.WeeklyFailedBaseChecked = item.isEnabled;
                break;
            case ListAlerts.DailyPricerisestowithinofpivot:
                alertDataObj.listAlertSettings.DailyPriceRisesSelected = item.isEnabled;
                alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue = item.targetValue;
                alertDataObj.listAlertSettings.DefaultRisesvalue = item.defaultValue;
                break;
            case ListAlerts.WeeklyPricerisestowithinofpivot:
                alertDataObj.listAlertSettings.WeeklyPriceRisesSelected = item.isEnabled;
                alertDataObj.listAlertSettings.WeeklyPriceRisesTgtvalue = item.targetValue;
                alertDataObj.listAlertSettings.DefaultRisesvalue = item.defaultValue;
                break;
            case ListAlerts.DailyPricecrossesoverpivotbreakout:
                alertDataObj.listAlertSettings.DailyPriceCrossesSelected = item.isEnabled;
                break;
            case ListAlerts.WeeklyPricecrossesoverpivotbreakout:
                alertDataObj.listAlertSettings.WeeklyPriceCrossesSelected = item.isEnabled;
                break;
            case ListAlerts.PullbackTo10WeekLine:
                alertDataObj.listAlertSettings.PullbackTo10WeekLineSelected = item.isEnabled;
                break;
            case ListAlerts.BuybackAnnouncement:
                alertDataObj.listAlertSettings.BuybackAnnouncementSelected = item.isEnabled;
                break;
            case ListAlerts.StockSplit:
                alertDataObj.listAlertSettings.StockSplitSelected = item.isEnabled;
                break;
            case ListAlerts.CEOChange:
                alertDataObj.listAlertSettings.CeoChangeSelected = item.isEnabled;
                break;
            case ListAlerts.Earningsreleaseiswithin:
                alertDataObj.listAlertSettings.EarningsReleaseSelected = item.isEnabled;
                alertDataObj.listAlertSettings.EarningsReleaseDays = (item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue);
                break;
            case ListAlerts.DividendChange:
                alertDataObj.listAlertSettings.DividendChangeSelected = item.isEnabled;
                break;
            // case ListAlerts.InsiderPurchages:
            //     alertDataObj.listAlertSettings.InsiderPurchasesSelected = item.isEnabled;
            //     break;
            case ListAlerts.InsidersTransactionsBuys:
                alertDataObj.listAlertSettings.InsiderTransactionsBuysSelected = item.isEnabled;
                break;
            case ListAlerts.InsidersTransactionsSells:
                alertDataObj.listAlertSettings.InsiderTransactionsSellsSelected = item.isEnabled;
                break;
            case ListAlerts.Offerings:
                alertDataObj.listAlertSettings.OfferingsSelected = item.isEnabled;
                break;
            case ListAlerts.Announcements:
                alertDataObj.listAlertSettings.AnnouncementsSelected = item.isEnabled;
                break;
            case ListAlerts.BreakawayGap:
                alertDataObj.listAlertSettings.BreakawayGapSelected = item.isEnabled;
                break;
            case ListAlerts.Day50BreakOnVolume:
                alertDataObj.listAlertSettings.Day50BreakOnVolumeSelected = item.isEnabled;
                break;
            case ListAlerts.GapUpOnEarnings:
                alertDataObj.listAlertSettings.GapUpOnEarningsSelected = item.isEnabled;
                break;
            case ListAlerts.GapDownOnEarnings:
                alertDataObj.listAlertSettings.GapDownOnEarningsSelected = item.isEnabled;
                break;
            case ListAlerts.NegativeAlertScore1Week:
                alertDataObj.listAlertSettings.NegativeAlter1WeekSelected = item.isEnabled;
                alertDataObj.listAlertSettings.NegativeAlter1Week = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                break;
            case ListAlerts.NegativeAlertScore10Week:
                alertDataObj.listAlertSettings.NegativeAlter10WeekSelected = item.isEnabled;
                alertDataObj.listAlertSettings.NegativeAlter10Week = item.isEnabled || item.targetValue ? item.targetValue : item.defaultValue;
                break;
            case ListAlerts.DailyEnterExitProfitRange:
                alertDataObj.listAlertSettings.DailyEnterExitProfitRangeSelected = item.isEnabled;
                alertDataObj.listAlertSettings.DailyEnterExitProfitRange = item.isEnabled === true ? item.targetValue : null;
                break;
            case ListAlerts.WeeklyEnterExitProfitRange:
                alertDataObj.listAlertSettings.WeeklyEnterExitProfitRangeSelected = item.isEnabled;
                alertDataObj.listAlertSettings.WeeklyEnterExitProfitRange = item.isEnabled === true ? item.targetValue : null;
                break;
            case ListAlerts.DailyEnterExitPivotRange:
                alertDataObj.listAlertSettings.DailyEnterExitPivotRangeSelected = item.isEnabled;
                alertDataObj.listAlertSettings.DailyEnterExitPivotRange = item.isEnabled === true ? item.targetValue : null;
                break;
            case ListAlerts.WeeklyEnterExitPivotRange:
                alertDataObj.listAlertSettings.WeeklyEnterExitPivotRangeSelected = item.isEnabled;
                alertDataObj.listAlertSettings.WeeklyEnterExitPivotRange = item.isEnabled === true ? item.targetValue : null;
                break;
            case ListAlerts.DailyEnterExitLossRange:
                alertDataObj.listAlertSettings.DailyEnterExitLossRangeSelected = item.isEnabled;
                alertDataObj.listAlertSettings.DailyEnterExitLossRange = item.isEnabled === true ? item.targetValue : null;
                break;
            case ListAlerts.WeeklyEnterExitLossRange:
                alertDataObj.listAlertSettings.WeeklyEnterExitLossRangeSelected = item.isEnabled;
                alertDataObj.listAlertSettings.WeeklyEnterExitLossRange = item.isEnabled === true ? item.targetValue : null;
                break;
            default:
                break;
        }
    });

    setAlertRisesValue(alertDataObj);
}

function setAlertRisesValue(alertDataObj) {
    if (alertDataObj.listAlertSettings.DailyPriceRisesSelected || alertDataObj.listAlertSettings.WeeklyPriceRisesSelected) {
        if ((alertDataObj.listAlertSettings.DailyPriceRisesSelected && alertDataObj.listAlertSettings.WeeklyPriceRisesSelected) || alertDataObj.listAlertSettings.DailyPriceRisesSelected) {
            alertDataObj.listAlertSettings.AlertPriceRises = alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue;
        }
        else if (alertDataObj.listAlertSettings.WeeklyPriceRisesSelected) {
            alertDataObj.listAlertSettings.AlertPriceRises = alertDataObj.listAlertSettings.WeeklyPriceRisesTgtvalue;
        }
    }
    else {
        alertDataObj.listAlertSettings.AlertPriceRises = !alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue && !alertDataObj.listAlertSettings.WeeklyPriceRisesTgtvalue ? alertDataObj.listAlertSettings.DefaultRisesvalue : alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue ? alertDataObj.listAlertSettings.DailyPriceRisesTgtvalue : alertDataObj.listAlertSettings.WeeklyPriceRisesTgtvalue;
    }
}

function setGeographyFilterData(response, alertDataObj) {
    alertDataObj.countryCollection = [];
    alertDataObj.selectedFilterItems = [];

    const browserdata = response.alerts.listAlertData ? response.alerts.listAlertData.browser.filters : response.alerts.browser.filters;
    browserdata.forEach((filtertype) => {
        filtertype.filters.forEach((item) => {
            const filteritem = {};

            filteritem.itemId = item.id;
            filteritem.itemName = item.name;
            filteritem.itemCount = item.count;
            filteritem.isSelected = item.selected;
            if (filteritem.isSelected) {
                alertDataObj.selectedFilterItems.push(filteritem);
            }
            filteritem.parentId = item.parentId;
            filteritem.regionName = item.regionName;
            alertDataObj.countryCollection.push(filteritem);
        })
    });

    getSelectedFilterString(alertDataObj);
    let _coulntryCollection = _coulntryCollection = chain(alertDataObj.countryCollection).sortBy('itemName').sortBy('regionName').value();
    alertDataObj.countryCollection = _coulntryCollection;
}

function* populateColumnSets(listalertSelectedColSet, sourceId, nocolumnSet, existAlerts, alertDataObj) {

    alertDataObj.columnsetCollection = [];
    alertDataObj.selectedColumnSet = [];
    alertDataObj.parentColumnSetName = "";
    const result = yield call(ListApi.getColumnSet, sourceId)
    alertDataObj.columnsetTreeNodes = result.data.treeNodes;

    each(alertDataObj.columnsetTreeNodes, (item) => {
        if (item.listType === ListType.FOLDER_List) {
            const colsetfolderdata = {
                colsetId: item.nodeId.low,
                colsetName: item.name,
                isFolder: true
            };

            alertDataObj.columnsetCollection.push(colsetfolderdata);
        }
    });

    each(alertDataObj.columnsetTreeNodes, (item) => {
        if (item.listType === ListType.COLUMNSET_List) {
            const colsetdata = { colsetId: item.nodeId.low, colsetName: item.name };

            if (colsetdata.colsetId === listalertSelectedColSet.nodeId.low) {
                colsetdata.isSelected = true;
                alertDataObj.selectedColSetString = item.name;
                alertDataObj.selectedColumnSet = colsetdata;
            }

            alertDataObj.columnsetCollection.push(colsetdata);
        }
    });

    //TO DO SK
    // IsModelListActive = _navListsModel.States.CurrentActiveListTreeItem.IsModelList;

    addingNoColumnSetNode(listalertSelectedColSet, nocolumnSet, existAlerts, alertDataObj);

    if (!StringUtil.isEmpty(alertDataObj.selectedColumnSet)) {
        return;
    }
    alertDataObj.columnsetChildItems = [];

    each(alertDataObj.columnsetTreeNodes, (item) => {
        let isSelected = false;
        const colSetArr = [];

        if (item.listType === ListType.FOLDER_List) { // && item.name === alertDataObj.parentColumnSetName
            each(item.childNodes, (itm) => {
                const colsetdata = { isSelected: false, colsetId: itm.nodeId.low, colsetName: itm.name };
                if ((colsetdata.colsetId === listalertSelectedColSet.nodeId.low) && existAlerts) {
                    colsetdata.isSelected = true;
                    alertDataObj.selectedColSetString = colsetdata.colsetName;
                    alertDataObj.selectedColumnSet = colsetdata;
                    alertDataObj.parentColumnSetName = item.name;
                }
                if (item.name === alertDataObj.parentColumnSetName) {
                    isSelected = true;
                    //alertDataObj.columnsetChildItems.push(colsetdata);
                }

                colSetArr.push(colsetdata);
            });

            if (isSelected) {
                alertDataObj.columnsetChildItems = [...alertDataObj.columnsetChildItems, ...colSetArr]
                isSelected = false;
            }
            // if(alertDataObj.columnsetChildItems.length === 0 && alertDataObj.parentColumnSetName !== "")
            // {
            //     let childItemsObj = find(alertDataObj.columnsetTreeNodes, (item) => item.name  === alertDataObj.parentColumnSetName)
            //     alertDataObj.columnsetChildItems =  [...alertDataObj.columnsetChildItems , ...childItemsObj.childNodes]
            // }
            if (StringUtil.isEmpty(alertDataObj.selectedColumnSet) === null) {
                alertDataObj.columnsetChildItems = [];
            }
            else {
                return;
            }
        }
    });

}

function addingNoColumnSetNode(listalertSelectedColSet, nocolumnSet, existAlerts, alertDataObj) {
    // Adding no column set node 
    const nocolsetdata = {
        isSelected: false,
        colsetId: nocolumnSet.nodeId.low,
        colsetName: nocolumnSet.name
    };

    if ((StringUtil.isEmpty(alertDataObj.selectedColumnSet) && listalertSelectedColSet.nodeId.low === nocolumnSet.nodeId.low) || existAlerts) {
        nocolsetdata.isSelected = true;
        alertDataObj.selectedColSetString = nocolsetdata.colsetName;
        alertDataObj.selectedColumnSet = nocolsetdata;
    } else {
        alertDataObj.selectedColSetString = listalertSelectedColSet.name
    }

    alertDataObj.columnsetCollection.push(nocolsetdata);
}

function getSelectedFilterString(alertDataObj) {
    const selecteditems = sortBy(alertDataObj.selectedFilterItems, 'regionName');
    const selecteditemcount = selecteditems.length;

    if (selecteditemcount > 1) {
        alertDataObj.browserFilterText = `${LocalizationStore.getTranslatedData(`Country_${selecteditems[0].itemName.replace(/ /ig, "")}`, selecteditems[0].itemName)
            }[+${selecteditemcount - 1
            }${LocalizationStore.getTranslatedData("alert_Geography_more", "more")
            }]`;
    }
    else if (selecteditemcount === 1) {
        alertDataObj.browserFilterText = LocalizationStore.getTranslatedData(`Country_${selecteditems[0].itemName.replace(/ /ig, "")}`, selecteditems[0].itemName);
    }

    alertDataObj.browserFilterSelectedIDs = '';

    each(alertDataObj.selectedFilterItems, (item) => {
        if (item.isSelected) {
            if (alertDataObj.browserFilterSelectedIDs === '') {
                alertDataObj.browserFilterSelectedIDs += item.itemId;
            }
            else {
                alertDataObj.browserFilterSelectedIDs += `,${item.itemId}`;
            }
        }
    });
}


//Watchers 

export function* watchOpenAndAlertDialog() {
    yield takeLatest(AlertConstants.ActionTypes.OPEN_AND_ALERT_DIALOG, openAndAlertDialog)
    yield takeLatest(AlertConstants.ActionTypes.OPEN_OR_ALERT_DIALOG, openAndAlertDialog)
}