import Serializable from "Serializable";
import QuoteServiceSettings from './QuoteServiceSettings.js';
import ReportedEarningsSettings from './ReportedEarningsSettings.js';

class PreferenceSettings extends Serializable {
    getDefaultSettings() {
        this.QuoteServiceSettings = this.getDefaultQuoteServiceSettings();
        this.ReportedEarningsSettings = this.getDefaultReportedEarningsSettings();
        return this;
    }

    onSeralisedInfoRecieved(info) {
        this.QuoteServiceSettings = typeof info.QuoteServiceSettings !== "undefined" ? info.QuoteServiceSettings : this.getDefaultQuoteServiceSettings();
        this.ReportedEarningsSettings = typeof info.ReportedEarningsSettings !== "undefined" ? info.ReportedEarningsSettings : this.getDefaultReportedEarningsSettings();
    }

    getDefaultQuoteServiceSettings() {
        return new QuoteServiceSettings();
    }

    getDefaultReportedEarningsSettings() {
        return new ReportedEarningsSettings();
    }

    getDataObject() {
        return {
            QuoteServiceSettings: this.QuoteServiceSettings,
            ReportedEarningsSettings: this.ReportedEarningsSettings
        };
    }

    getObjectMapKey() {
        return "PreferenceSettings";
    }
}

export default PreferenceSettings;
