import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class RemoveColumnToListRequest extends BaseServiceRequest {

    constructor(listId, dataItemId, isQuoteboard = false) {
        super();
        super.addRequestUri("column.pb");
        super.addRequestParameter("l", listId.toString());
        super.addRequestParameter("di", dataItemId);
        super.addRequestParameter("iqb", isQuoteboard ? "1" : "0");
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default RemoveColumnToListRequest;
