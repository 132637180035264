import React, { Component } from 'react';
import StringUtil from 'StringUtil';
import { each, contains } from "underscore";
import moment from "moment";
import LocalizationStore from 'LocalizationStore';

class TimeLine extends Component {

    renderBenchmarkLabel() {
        const { dimensions, timeLineData } = this.props;
        const rotationGraphActualWidth = dimensions.width;
        const totalwidthofscrubber = 75;
        const halfthewidthofscr = totalwidthofscrubber / 2;

        if (dimensions.width <= 0) return;
        const itemwidth = ((dimensions.width) - (totalwidthofscrubber)) / (timeLineData.length - 1);
        const benchmarkCanvasMarginLeft = 34;
        const benchmarkLabels = [];
        const labelList = [];
        const benchmarkMinInterval = 770;

        each(timeLineData.slice(0).reverse(), (item, itemKey) => {
            //let economicSummaryLastYear = moment.utc(timeLineData[timeLineData.length - 1], "DD/MM/YYYY").year();
            const itemDate = moment.utc(item, "DD/MM/YYYY");
            const textlabel = itemDate.format(LocalizationStore.getTranslatedData("CRG_YearMonthFormat", "MMM, YYYY"));

            if (!contains(labelList, textlabel)) {
                const monthinterval = rotationGraphActualWidth > benchmarkMinInterval ? 6 : 12;
                if ((itemDate.month() + 1) % monthinterval === 0 && (rotationGraphActualWidth - totalwidthofscrubber) > 0) {
                    const textBlock = {
                        text: textlabel.toUpperCase(),
                        width: rotationGraphActualWidth - totalwidthofscrubber,
                    };

                    const index = itemKey;
                    if (index * itemwidth >= rotationGraphActualWidth - totalwidthofscrubber - benchmarkCanvasMarginLeft) {
                        return;
                    }
                    textBlock.marginLeft = `${(halfthewidthofscr) - (index * itemwidth)}px`;
                    benchmarkLabels.push(textBlock);
                    labelList.push(textlabel);
                }
            }
        });
        return benchmarkLabels.slice().reverse();
    }

    render() {
        const benchMarkIntervalList = !StringUtil.isEmpty(this.props.timeLineData) && this.renderBenchmarkLabel();

        return (
            !StringUtil.isEmpty(benchMarkIntervalList) &&
            <div className="graph-bottom-years">
                {benchMarkIntervalList.map((interval, index) =>
                    <span className="bechmarkLabel" style={{ marginLeft: interval.marginLeft, width: `${this.props.dimensions.width - 76}px` }} key={index + 1}>{interval.text}</span>
                )}
            </div>
        );
    }
}

export default TimeLine;