import CompareTabType from "CompareTabType";
import TabComparisonGraphSettings from './TabComparisonGraphSettings.js';
import TabRotationGraphSettings from './TabRotationGraphSettings.js';
import Serializable from "Serializable";

class CompareSettings extends Serializable {

  onSeralisedInfoRecieved(serializedValue) {
    this.isSettingsRestored = serializedValue.isSettingsRestored ? serializedValue.isSettingsRestored : false;
    this.SelectedTabKey = serializedValue.SelectedTabKey ? serializedValue.SelectedTabKey : this.getDefaultSelectedTab();

    this.TabCollection = [];
    this.TabCollection.push({Header: CompareTabType.ComparisonGraph, TabType: CompareTabType.ComparisonGraph})
    this.TabCollection.push({Header: CompareTabType.RotationGraph, TabType: CompareTabType.RotationGraph})

    this.TabComparisonGraphSettings = serializedValue.TabComparisonGraphSettings ? serializedValue.TabComparisonGraphSettings : this.getDefaultTabComparisonGraphSettings();

    this.TabRotationGraphSettings = serializedValue.TabRotationGraphSettings ? serializedValue.TabRotationGraphSettings : this.getDefaultTabRotationGraphSettings();
  }

  getDefaultCompareSettings() {
    this.isSettingsRestored = true;
    this.SelectedTabKey = this.getDefaultSelectedTab();

    this.TabCollection = [];
    this.TabCollection.push({Header: CompareTabType.ComparisonGraph, TabType: CompareTabType.ComparisonGraph})
    this.TabCollection.push({Header: CompareTabType.RotationGraph, TabType: CompareTabType.RotationGraph})

    this.TabComparisonGraphSettings = this.getDefaultTabComparisonGraphSettings();
    this.TabRotationGraphSettings = this.getDefaultTabRotationGraphSettings();
    return this;
  }

  getDataObject() {
    return {
      isSettingsRestored: this.isSettingsRestored,
      SelectedTabKey: this.SelectedTabKey,
      TabComparisonGraphSettings: this.TabComparisonGraphSettings,
      TabRotationGraphSettings: this.TabRotationGraphSettings
    };
  }

  getObjectMapKey() {
    return "compareSettings";
  }

  getDefaultSelectedTab() {
    return CompareTabType.ComparisonGraph;
  }

  getDefaultTabComparisonGraphSettings() {
    let tabComparisonGraphSettings = new TabComparisonGraphSettings();
    return tabComparisonGraphSettings.getDefaultTabComparisonGraphSettings();
  }

  getDefaultTabRotationGraphSettings() {
    let tabRotationGraphSettings = new TabRotationGraphSettings();
    return tabRotationGraphSettings.getDefaultTabRotationGraphSettings();
  }
}

export default CompareSettings;
