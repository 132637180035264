import StorageUtil from "StorageUtil";
import BaseServiceApi from 'BaseServiceApi';
import LangType from '../Constants/LangType';

var EntitlementType = BaseServiceApi.rayData["EntitlementType"];
let AlertType = BaseServiceApi.rayData["AlertType"];
let userInfo = null;
let shouldUpdateUserInfo = true;
class UserInfoUtil {

    constructor() {
        this.storageUtil = new StorageUtil();
    }

    getUserInfo() {
        if (shouldUpdateUserInfo || this.storageUtil == null){
            userInfo = this.storageUtil.get("user");
            shouldUpdateUserInfo = false;
        }
        return userInfo;       
    }

    setUserInfo(user) {
        this.storageUtil.set("user", user);
        shouldUpdateUserInfo = true;
    }

    /**
     * @return {boolean}
     */
    IsUserEntitledToAnyIdeas() {
        let user = this.getUserInfo();
        let entitlement = (user) ? user.entitlements : [];

        return entitlement.includes(EntitlementType.IDEAS_ALLCAPS_LEADER_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_DEVELOPED_LONG_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_DEVELOPED_SHORT_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_EMERGING_LONG_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_EMERGING_SHORT_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_FRONTIER_LONG_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_FRONTIER_SHORT_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_LARGECAP_LEADER_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_USA_LONG_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_USA_SHORT_Entitlement) ||
            entitlement.includes(EntitlementType.CHINA_ASHARE_LONG_Entitlement) ||
            entitlement.includes(EntitlementType.CHINA_ASHARE_SHORT_Entitlement) ||
            entitlement.includes(EntitlementType.GFL_EUROPE_LONG_Entitlement) ||
            entitlement.includes(EntitlementType.GFL_EUROPE_SHORT_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_LARGECAP_LAGGARD_Entitlement) ||
            entitlement.includes(EntitlementType.IDEAS_ALLCAPS_LAGGARD_Entitlement);
    }

    /**
     * @return {boolean}
     */
    IsUserEntitled(type) {
        let user = this.getUserInfo();
        let entitlement = (user) ? user.entitlements : [];
        if(Array.isArray(type)) return type.every((elem) => entitlement.includes(elem))
        else return entitlement.includes(type);
    }

    /**
     * @return {boolean}
     */
    IsUserEntitledToAnyIdeasHistory() {
        let user = this.getUserInfo();
        let entitlements = (user) ? user.entitlements : [];

        return entitlements.includes(EntitlementType.IDEAS_HISTORY_ALLCAPS_LAGGARD_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_ALLCAPS_LEADER_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_DEVELOPED_SHORT_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_EMERGING_LONG_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_EMERGING_SHORT_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_FRONTIER_LONG_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_FRONTIER_SHORT_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_LARGECAP_LAGGARD_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_LARGECAP_LEADER_Entitlement) ||
            entitlements.includes(EntitlementType.GFL_EUROPE_LONG_HISTORY_Entitlement) ||
            entitlements.includes(EntitlementType.GFL_EUROPE_SHORT_HISTORY_Entitlement) ||
            entitlements.includes(EntitlementType.CHINA_ASHARE_LONG_HISTORY_Entitlement) ||
            entitlements.includes(EntitlementType.CHINA_ASHARE_SHORT_HISTORY_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_USA_LONG_Entitlement) ||
            entitlements.includes(EntitlementType.IDEAS_HISTORY_USA_SHORT_Entitlement);
    }

    /**
     * @return {boolean}
     */
    IsUserEntitledtoSnP(curSymbol='') {
        //let user = this.getUserInfo();
    
        let SnPSymbols = this.getSnPSymbolInfo();
        return curSymbol ? 
            (SnPSymbols && SnPSymbols.Symbol && SnPSymbols.Symbol.includes(curSymbol) ? this.IsUserEntitled(EntitlementType.SP_Entitlement) : true) 
        : 
        this.IsUserEntitled(EntitlementType.SP_Entitlement);
    }

    getSnPSymbolInfo(){
        let user = this.getUserInfo();
        let SnPSymbols = (user)? user.SnPSymbols : [];
        return SnPSymbols;
    }

    getUserEntitlements(){
        let user = this.getUserInfo();
        let entitlements = (user) ? user.entitlements : [];
        return entitlements;
    }

    /**
     * @return {boolean}
     */
    hasLocalSymbol(){
        if(this.IsUserEntitled(EntitlementType.RAY_LOCALSYMBOLS_Entitlement)){
            return true;
        }
        return false;
    }

    hasChinaEntitlement() {
        if(this.IsUserEntitled(EntitlementType.RAY_CHINA_Entitlement)){
            return true;
        }
        return false;
    }

    getCurrentLang(){
        if(this.IsUserEntitled(EntitlementType.RAY_CHINA_Entitlement)){
            return LangType.ZH_CN;
        }
        return LangType.RAY_DEFAULT;
    }

    getCurrentLangCode(){
        let currentLang = this.getCurrentLang();
        if(currentLang === LangType.ZH_CN){
            return 'zh-cn';
        }
        return 'en-us';
    }

    isPatternRecEntitlement(alertType)
    {
        let entitlements = this.getUserInfo().entitlements;

        if (!entitlements.includes(EntitlementType.PR_Entitlement))
        {
            if (alertType == AlertType.ALERT_PATTERN_REC_BREAKOUT ||
                alertType == AlertType.ALERT_PATTERN_REC_NEW_BASE ||
                alertType == AlertType.ALERT_PATTERN_REC_NEW_TIGHT_AREA ||
                alertType == AlertType.ALERT_PATTERN_REC_PERC_OFF_PIVOT)
            {
                return false;
            }
        }
        return true;
    }

    hasExternalDataUploaderEntitlement() {
        if(this.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement)){
            return true;
        }
        return false;
    }
}

export default new UserInfoUtil();
