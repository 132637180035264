import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetCompareChartScaleMaxMinDataRequest extends BaseServiceRequest {

    constructor(compChartScaleInfoObj) {
        super();
        super.addRequestUri("getscale.pb");
        super.setContent(compChartScaleInfoObj.encode());
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.GROUPS;
    }

    getMessageName() {
        return "GetScaleResponseData";
    }
}

export default GetCompareChartScaleMaxMinDataRequest;
