import AppDispatcher from "AppDispatcher";
import { MenuDialogConstants } from "../../Constants/MenuDialogConstants.js";
import { EventEmitter } from "events";
import PreferenceApi from "../../ServiceApi/Apis/PreferenceApi.js";
import UserInfoUtil from "UserInfoUtil"
import BaseServiceApi from 'BaseServiceApi';
import SettingsStore from "SettingsStore";
var EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const CHANGE_EVENT = "change";
let _states = { isOpen: false, eventKey: '', results: null, showbackButton: true };

class PreferenceDialogStoreClass extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    _states.SelectQuoteServiceOption = this.getSelectQuoteServiceOption();
    return _states;
  }

  getSelectQuoteServiceOption() {
    return SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption;
  }

  setSelectQuoteServiceOption(data) {
    SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption = data;
  }

  isOpenDialog(data) {
    let eventKey = data.eventKey;
    _states = { isOpen: true, eventKey: eventKey, showbackButton: data.showbackButton };
  }

  GetDdeParametersNasdaqBasic(data) {
    let appversion = data.appversion;
    let isNasdaqentitled = data.isNasdaqentitled;
    let isBatsentitled = data.isBatsentitled;
    let eventKey = data.eventKey;
    PreferenceApi.GetDdeParametersNasdaqBasic(appversion, isNasdaqentitled, isBatsentitled).then((result) => {
      let entitlements = UserInfoUtil.getUserInfo().entitlements;
      if (entitlements.includes(EntitlementType.NASDAQ_BASIC_WEB_Entitlement)) {
        result.PanarayRtSourceList = result.PanarayRtSourceList.filter((r) => r.DDeInfosList.Name !== 'BATS');
      }
      else if (entitlements.includes(EntitlementType.BATS_Entitlement)) {
        result.PanarayRtSourceList = result.PanarayRtSourceList.filter((r) => r.DDeInfosList.Name !== 'NASDAQ BASIC');
      }
      else {
        result.PanarayRtSourceList = result.PanarayRtSourceList.filter((r) =>
          (r.DDeInfosList.Name !== 'BATS' && r.DDeInfosList.Name !== 'NASDAQ BASIC'));
      }
      _states.results = result;
      _states.isOpen = true;
      _states.eventKey = eventKey;
      _states.showbackButton = data.showbackButton;
      this.emit(CHANGE_EVENT);
    });
  }


  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      case MenuDialogConstants.ActionTypes.OPEN:
        this.isOpenDialog(data);
        this.emit(CHANGE_EVENT);
        break;
      case MenuDialogConstants.ActionTypes.QUOTE_SERVICE:
        this.GetDdeParametersNasdaqBasic(data);
        //  this.isOpenDialog({eventKey: data.eventKey});
        // this.emit(CHANGE_EVENT);
        break;
      case MenuDialogConstants.ActionTypes.APPEARANCE:
        this.isOpenDialog(data);
        this.emit(CHANGE_EVENT);
        break;
      case MenuDialogConstants.ActionTypes.NOTIFICATION:
        this.isOpenDialog(data);
        this.emit(CHANGE_EVENT);
        break;
      case MenuDialogConstants.ActionTypes.SET_SELECTQUOTESERVICEOPTION:
        this.setSelectQuoteServiceOption(data);
        SettingsStore.saveSettings();
        this.emit(CHANGE_EVENT);
        break;
      case MenuDialogConstants.ActionTypes.REPORTED_EARNINGS:
        this.isOpenDialog(data);
        this.emit(CHANGE_EVENT);
        break;
      case MenuDialogConstants.ActionTypes.CURRENCY_CONVERSION:
        this.isOpenDialog(data);
        this.emit(CHANGE_EVENT);
        break;
      default:
        return true;
    }
    return true;
  }
}


const PreferenceDialogStore = new PreferenceDialogStoreClass();
export default PreferenceDialogStore;
