import React from "react";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import StringUtil from "Utils/StringUtil.js";
import moment from "moment";
import ExtremeDataValue from "ExtremeDataValue";

export default class DgTabHeaderHoldings extends React.Component {
    constructor(props) {
        super(props);
    }

    AbbreviateSharesValueForHeader = (shares) => {
        if (shares == null || shares == 0) {
            return "0";
        }
        let mShares = (shares / 1000000).toFixed(1);
        if (mShares < 0.1) // 0.0 ~ 94.4 (K)
        {
            return (shares / 1000).toFixed(1); // Does not show the "K" 
        }
        else if (mShares < 1000) // 0.1M ~ 999.4 M
        {
            return mShares + "M";
        }
        else if (mShares < 1000000) // 1.0 Billion ~ 
        {
            return (mShares / 1000).toFixed(1) + "B";
        }
        return (shares / 1000);
    }

    updateZeroIfNull = (obj) =>(obj == null || obj == undefined) ? 0 : obj;
    
    render() {
        const { props } = this;
        const reportHeader = props.data;
        let stockBarColor = "";
        const symbolInfo = DatagraphStore.getSymbolInfo();
        let companyName = null;
        let symbol = "";
        if (symbolInfo) {
            companyName = symbolInfo.CompanyName;
            symbol = symbolInfo.Symbol;
        }
        let isHeaderLoaded =  (reportHeader != null && reportHeader.length != 0 &&  (reportHeader.symbolInfo == undefined || reportHeader.symbolInfo == symbol) && reportHeader.reportDate1 != null) ? true : false;
        
        if(isHeaderLoaded){
            reportHeader.client = reportHeader.client == true ? true : false;
            reportHeader.holdingsQ1 = this.updateZeroIfNull(reportHeader.holdingsQ1);
            reportHeader.holdingsQ2 = this.updateZeroIfNull(reportHeader.holdingsQ2);
            reportHeader.holdingsQ3 = this.updateZeroIfNull(reportHeader.holdingsQ3);
            reportHeader.holdingsQ4 = this.updateZeroIfNull(reportHeader.holdingsQ4);
            reportHeader.numberOfOwners = this.updateZeroIfNull(reportHeader.numberOfOwners);
            reportHeader.quarterEndDate1Pct = this.updateZeroIfNull(reportHeader.quarterEndDate1Pct);
            reportHeader.quarterEndDate2Pct = this.updateZeroIfNull(reportHeader.quarterEndDate2Pct);
            reportHeader.quarterEndDate3Pct = this.updateZeroIfNull(reportHeader.quarterEndDate3Pct);
            reportHeader.percentOwned = this.updateZeroIfNull(reportHeader.percentOwned);
            reportHeader.ownerTitle = (reportHeader.ownerTitle === undefined || reportHeader.ownerTitle === null) ? '' : reportHeader.ownerTitle;
            reportHeader.symbolInfo = symbol;       
            stockBarColor = reportHeader.client ? "signer sign-green" : "signer";
        }
        

        return (
            <div className="flexbox-item header">
                {isHeaderLoaded ? <div className="digit-report-display digit-holding">
                    <div className="digitboard">
                        <div className="digitblock"><div className={reportHeader.ownerTitle.length > 11 ? "digitbox extra-text" : "digitbox"}><span className="digiboard-row-title">#Holdings/AUM</span></div></div>
                        <div className="digitblock active">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{ExtremeDataValue.getFormattedNumber(reportHeader.numberOfOwners) + "/" + this.AbbreviateSharesValueForHeader((reportHeader.numberOfOwners > 0 ? reportHeader.aumQ1 : 0) * 1000000)}</div>
                        </div>
                        {/* <div className="digitblock">
                            <div className="digitbox"> {moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate2)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{ExtremeDataValue.numberWithCommas(reportHeader.holdingsQ2) + "/" + this.AbbreviateSharesValueForHeader(reportHeader.holdingsQ2.low)}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate3)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{ExtremeDataValue.numberWithCommas(reportHeader.holdingsQ3) + "/" + this.AbbreviateSharesValueForHeader(reportHeader.holdingsQ3.low)}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate4)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{ExtremeDataValue.numberWithCommas(reportHeader.holdingsQ4) + "/" + this.AbbreviateSharesValueForHeader(reportHeader.holdingsQ4.low)}</div>
                        </div> */}
                    </div>
                    <div className="digitboard">
                        <div className="digitblock"><div className="digitbox">Report Date Distribution</div></div>
                        <div className="digitblock active">
                            <div className="digitbox">{moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MMM, YYYY")}</div>
                            <div className="digitbox">{reportHeader.quarterEndDate1Pct.toFixed(1) + "%"}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox">{(reportHeader.quarterEndDate2 ? moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate2)).format("MMM, YYYY") : moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).subtract(3, 'months').format("MMM, YYYY")) + "*"}</div>
                            <div className="digitbox">{reportHeader.quarterEndDate2Pct.toFixed(1) + "%"}</div>
                        </div>
                        <div className="digitblock">
                            <div className="digitbox">OLDER**</div>
                            <div className="digitbox">{(reportHeader.numberOfOwners > 0 ? reportHeader.quarterEndDate3Pct.toFixed(1) : "0.0") + "%"}</div>
                        </div>
                    </div>
                </div> : null}
                <div className="contain-title">
                <div className={stockBarColor}>
                    <div className="ellipsed">
                        <div className="main-heading" onMouseMove={(e) => this.props.onTitleMouseMove(e, companyName + " (" + symbol + ")", 20, true)} onMouseOut={() => this.props.onMessageMouseLeave()} onBlur={() => this.props.onMessageMouseLeave()}>{ symbolInfo ? companyName + " (" + symbol + ")" : ""} </div>
                        {isHeaderLoaded ? <div className="sub-heading" onMouseOut={() => this.props.onMessageMouseLeave()} onBlur={() => this.props.onMessageMouseLeave()}
                        // onMouseMove={(e) => this.props.onTitleMouseMove(e, ExtremeDataValue.getFormattedNumber(reportHeader.numberOfOwners) + " " + reportHeader.ownerTitle + (reportHeader.ownerTitle === 'Owners' ? " † " : "") + " Owned " + ExtremeDataValue.getFormattedNumber( 100) + " (" + reportHeader.percentOwned + "%)  as of " + moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MM/DD/YYYY"), 14, false)} >
                        //                                                   {ExtremeDataValue.getFormattedNumber(reportHeader.numberOfOwners) + " " + reportHeader.ownerTitle + (reportHeader.ownerTitle === 'Owners' ? " † " : "") + " Owned " + ExtremeDataValue.getFormattedNumber( 100) + " (" + reportHeader.percentOwned + "%)  as of " + moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MM/DD/YYYY")}</div>
                        onMouseMove={(e) => this.props.onTitleMouseMove(e, ExtremeDataValue.getFormattedNumber(reportHeader.numberOfOwners) + " constituents held in portfolio as of " + moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MM/DD/YYYY"), 14, false)} >
                                                                          {ExtremeDataValue.getFormattedNumber(reportHeader.numberOfOwners) + " constituents held in portfolio as of " + moment.utc(StringUtil.convertFromLongValueToInt(reportHeader.quarterEndDate1)).format("MM/DD/YYYY")}</div> : null}
                        {isHeaderLoaded ? <div className="additional-heading" onMouseMove={(e) => this.props.onTitleMouseMove(e, reportHeader.ownerTitle == "Owners" ? "†Funds, Banks and Insurance Cos" : "", 12, false)} onMouseOut={() => this.props.onMessageMouseLeave()} onBlur={() => this.props.onMessageMouseLeave()}>{reportHeader.ownerTitle === 'Owners' ? "†Funds, Banks and Insurance Cos" : ""}</div> : null}
                    </div>
                </div>
                </div>
            </div>
        )
    }
}