import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import KeyCodes from '../../../../../Constants/KeyCodes';

class TextInput extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
  }

  keyPress = (e) => {
    if(e.keyCode === KeyCodes.ENTER){
      e.preventDefault();
      e.stopPropagation();
      if(this.inputText)
      this.inputText.blur();
    }
 }

  render() {
    return (
      <input type="text" ref={(r) => this.inputText = r} value={this.state.value} onFocus={(e) => e.target.select()} onChange={(e) => {
        if (/^-?[0-9,]+$/.test(e.target.value) || e.target.value === "" || e.target.value === "-") {
          this.setState({
            value: e.target.value
          })
        }
      }} onBlur={() => this.props.onBlurEvent(this.state.value)} onKeyDown={this.keyPress} />
    );
  }
}

export default connect(null, null)(TextInput);
