import keyMirror from "keymirror";

export const ComparisonGraph = {
  ActionTypes: keyMirror({
    INIT_FROM_SETTINGS_DATA: null,
    SAVE_SETTINGS: null,
    SAVE_ROTATION_GRAPH_SETTINGS:null,
    INIT_COMP_CHART: null,
    HANDLE_EXCEPTION: null,
    ERROR: null,
    ON_DIMENSION_CHANGE: null,
    ON_DIMENSION_CHANGE_SUCSESS: null,
    SHOW_COMP_GROUPS_CHART_CONTEXT_MENU: null,
    COMP_GROUPS_CHART_CONTEXT_MENU_ITEM_CLICK: null,
    TOGGLE_TOOL_TIP: null,
    HANDLE_HIGHLIGHT: null,
    HANDLE_HIGHLIGHT_SUCCESS: null,
    TOGGLE_VISIBLE: null,
    TOGGLE_VISIBLE_SUCCESS: null,
    TOGGLE_SORT: null,
    TOGGLE_SORT_SUCCESS: null,
    TOGGLE_CALENDAR: null,
    SET_SELECTED_DATE: null,
    UPDATE_INTERVAL: null,
    SUBTRACT_YEAR: null,
    ADD_YEAR: null,
    UPDATE_YEAR_ON_ENTER: null,
    SET_VIEW_MONTH: null,
    RESET_CALENDAR: null,
    RESET_CALENDAR_SUCCESS: null,
    TOGGLE_CALENDAR_SUCCESS: null,
    SET_MOST_RECENT_TRADE_DATE: null,
    UPDATE_INPUT_VALUE: null,
    HANDLE_INPUT_CHANGE: null,
    SET_LATEST_TRADING_DATE: null,
    UPDATE_LATEST_TRADING_DATE: null,
    UPDATE_LATEST_USER_DATES: null,
    UPDATE_USER_START_DATE: null,
    UPDATE_USER_END_DATE: null,
    UPDATE_PERIODICITY_DURATION: null,
    UPDATE_PERIODICITY_DISPLAY_LABEL: null,
    ON_INPUT_CHANGE: null,
    ON_RENAME: null,
    ON_INPUT_COMPLETE_SUCCESS: null,
    TOGGLE_EDIT_MODE: null,
    INIT_LINE_COLLECTION: null,
    UPDATE_PRICE_SCALEINFO: null,
    SET_MAX_MIN_CHART_VALUE: null,
    UPDATE_NODE_WIDTH: null,
    UPDATE_NUM_CHART_NODES: null,
    SET_MSIDS:null,
    SET_MH_LINES: null,
    SET_CALENDAR_LABELS: null,
    SET_VERTICAL_LINES: null,
    SET_TIME_LINE_DATA: null,
    CHANGE_SCALE_BASE_VALUE: null,
    CHANGE_SCALE_BASE_VALUE_SUCCESS: null,
    CHANGE_SCALE_TYPE_VALUE: null,
    CHANGE_SCALE_TYPE_VALUE_SUCCESS: null,
    HANDLE_REFRESH: null,
    HANDLE_REFRESH_SUCCSESS: null,
    COMP_UPDATE_LINE: null,
    INIT_LIQ_FILTER: null,
    LIQ_FILTER_CHANGE: null,
    UPDATE_LIQ_FILTER: null,
    UPDATE_SYMBOL_REQ: null,
    UPDATE_INDEX_REQ: null,
    POPULATE_PERIODICITY_DURATION: null,
    FILTER_ALL_DATA: null,
    PROCESS_ALL_DATA: null,
    INIT_LIQ_FILTER_DATA: null,
    DISPLAY_PROGRESS_BAR: null,
    UPDATE_PROGRESS_BAR_PERCENTAGE: null,
    CHART_RE_DRAW: null,
    HANDLE_ERROR: null,
    GRID_LINE_COLOR_CHANGE: null
  })
};