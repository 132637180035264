import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { toggleMAValidationDialog } from "../../../../../../../Actions/PricePanelActions.js";
import { MATranslateHelper, TranslateHelper } from "../../../../../../../Utils/TranslateHelper.js";
import React, { Component, Fragment } from "react";

class MovingAverageValidationDialog extends Component {
    render() {
        return (
            <Fragment>
                <Modal className="modal-popup validate-ma-dialog" show={this.props.isMAValidationDialogOpen} >
                    <Modal.Header>
                        <Modal.Title><span className="cap-header">{MATranslateHelper.VALIDATION}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-region">
                            <p>{this.props.maLength}{MATranslateHelper.MA_FOR}{this.props.maType} {MATranslateHelper.ALREADY_EXIST}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.toggleMAValidationDialog}>{TranslateHelper.OK}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers }) =>{
    const { MAReducer } = DatagraphReducers;
    return { isMAValidationDialogOpen: MAReducer.isMAValidationDialogOpen, maType: MAReducer.maType, maLength: MAReducer.maLength};
}
const mapDispatchToProps = (dispatch) =>({
    toggleMAValidationDialog: ()=> dispatch(toggleMAValidationDialog(false)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MovingAverageValidationDialog);