import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import { toggleClearAllDialog } from 'Actions/ComparisonAndRotationMenuActions';
import LocalizationStore from "LocalizationStore";
import CompareTabType from "CompareTabType";

class ClearAll extends PureComponent {

  render() {
    const { total, toggleClearAllDialog } = this.props;
    const classes = total > 0 ? "page-filter"  : "page-filter disabled";
    return (<span onClick={total > 0 ? toggleClearAllDialog : undefined} className={classes}>{LocalizationStore.getTranslatedData("LM_ClrAll", "Clear")}</span>);
  }
}

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const symbolsMenu = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
  const compChartListItem = state.comparisonGraph.compChartListItem;

  const total = selectedTab == CompareTabType.ComparisonGraph ? compChartListItem.SymbolsData.length : symbolsMenu.RotationMenuStates.stockList.length;

  return { total };
}

const mapDispatchToProps = (dispatch) => ({
  toggleClearAllDialog: () => dispatch(toggleClearAllDialog()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClearAll);

