import keyMirror from "keymirror"

export const DataGraphConstants = {
    ActionTypes: keyMirror({
        ON_PLOT_SYMBOL: null,
        PRICE_PANEL_RESPONSE_READY: null,
        STORE_PADDING_VALUE: null,
        INIT_PERIODICITY_BUTTON: null,
        INIT_SETTINGS_TO_REDUCER: null,
        INIT_CHART_CALL: null,
        //header constants
        SET_HEADER_RESPONSE:null,
        CLEAR_HEADER_STATE: null,
        CHANGE_EPS_FORMAT: null,
        CHANGE_IPO_FORMAT: null,
        UPDATE_HEADER_STATES: null,
        GICS_SELECTED: null,
        UPDATE_TI_MENU: null,
        PREPARE_TI_MENU_NEW_PERIODICITY: null,
        SAVE_TOGGLE_SECTION: null,
        TOGGLE_SECTION_VISIBILITY: null,
        COLLAPSE_BLOCK: null,
        OPEN_BLOCK: null,
        COLLAPSE_KPI_BLOCK: null,
        OPEN_KPI_BLOCK: null,
        SET_HISTORIC_HEADER_RESPONSE: null,
        PERIODICITY_CHANGE: null,
        CALL_PRICE_CHART_API: null,
        INIT_FACTOR_RESEARCH: null,
        FR_DATA_READY: null,
        UPDATE_SYMBOL_ENTRY_STATES: null,
        UPDATE_SYMBOL_ENTRY_FLAG: null,
        UPDATE_SYMBOL_CONVERTED_DIALOG_AVAILABLE: null,
        HANDLE_SYMBOL_ENTRY_SYMBOL_CHANGE: null,
        HANDLE_SYMBOL_ENTRY_CHANGE: null,
        CLOSE_SYMBOL_CONVERTED_DIALOG: null,
        CLOSE_SYMBOL_ENTRY_ERROR_DIALOG: null,
        UPDATE_SYMBOL_ENTRY_LOADING: null,
        CLEAR_FR_DATA: null,
        SAVE_FR_DATA_FOR_RESIZE: null,
        UPDATE_ONEIL_IDEAS_INFO: null,
        FR_BUTTON_TILE_CLICK: null,
        CALL_FACTOR_RI_API: null,
        UPDATE_DATAGRAPH_TAB_HEIGHT: null,
        UPDATE_AVAILABLE_COMPONENT: null,
        TOGGLE_CORP_EVENTS: null,
        VIEW_METRIC_DEF: null,
        VIEW_METRIC_DEF_READY: null,
        CLOSE_METRIC_DEF: null,
        HANDLE_REDRAW_LINE: null,
        SWITCH_FUTURE_EXPITY_DATE: null,
        SAVE_PRICEPANEL_DATA_REDRAW_LINE: null,
        SET_BLOCK_MENU_DATA: null,
        UPDATE_SHOW_TOOL_TIP: null,
        UPDATE_IS_HISTORIC: null,
        INIT_FACTOR_API: null,
        UPDATE_NQBOX_STATE: null,
        TOGGLE_MINI_LIST: null,
        SAVE_FR_DATA_SETTINGS: null,
        UPDATE_COMMON_ABOUT_DAILOG: null,
        FR_DATA_AVAILABLE: null
    })
}