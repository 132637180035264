import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

export default class GetIndustryGroupHeaderRequest extends BaseServiceRequest {
    constructor(msid, symbol, osid, type) {
        super();
        super.addPathParameter(msid.toString());
        super.addRequestParameter("symbol", symbol);
        super.addRequestParameter("osid", osid);
        super.addRequestParameter("p", type);
        super.addRequestUri("esperipheralindgrpdata.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "PeripheralIndGrpDataResponse";
    }
}