import { customTIModalConstants } from "../Constants/TabDataGraphConstants";
const { ActionTypes } = customTIModalConstants;

export const initCustomTIModal = () => ({
    type: ActionTypes.INIT_TI_MODULE
});

export const handleModalHide = (isSaveObject, isRemainOpen = false) => ({
    type: ActionTypes.HANDLE_MODAL_HIDE,
    isSaveObject,
    isRemainOpen
});

export const openCustomTIModal = (data) => ({
    type: ActionTypes.INIT_TI_MODULE,
    data
});
export const openCustomKPIModal = (data) => ({
    type: ActionTypes.INIT_KPI_APPEARANCE,
    data
});
export const updateItemStatus = (isActive, item, color, weight) => ({
    type: ActionTypes.UPDATE_ITEM_STATUS,
    isActive, 
    item, 
    color, 
    weight
});

export const onTabSelectionChanged = (selectedPeriodicityTabKey) => ({
    type: ActionTypes.ON_TAB_SELECTION_CHANGED,
    selectedPeriodicityTabKey
});

export const onInputValChange = (e, item) => ({
    type: ActionTypes.ON_INPUT_VALUE_CHANGE,
    event: e,
    item: item
});

export const onInputFocusLost = (e, item) => ({
    type: ActionTypes.ON_INPUT_FOCUS_LOST,
    event: e,
    item: item
});

export const restoreDefaults = () => ({
    type: ActionTypes.RESTORE_DEFAULTS
});

export const onApplyToAllIntervals = (e) => ({
    type: ActionTypes.ON_APPLY_TO_ALL_INTERVALS,
    event: e
});