import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetAllSharedNodesRequest extends BaseServiceRequest {
    constructor(ntid) {
        super();
        super.addRequestUri("sharedtypes.pb");
        super.addRequestParameter("ntid", ntid);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.SHARE;
    }

    getMessageName() {
        return "ShareNodeInfoSummaryResponse";
    }
}


export default GetAllSharedNodesRequest;
