import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class LabelText extends PureComponent {
    render() {
        const { textValue, textPosX, textPosY, textAnchor, dx, dy, isHighlighted, style, height, textColor } = this.props;

        let multiText = " ";
        if (this.props.multiLine !== undefined) {
            multiText = textValue.split(" ");
            if (multiText.length > 1 && textValue.length * 8 < this.props.multiLine) {
                multiText[0] = textValue;
                multiText[1] = undefined;
            }
        }
        if (this.props.multiLine === undefined) {
            return (<>

                <text
                    textAnchor={textAnchor ? textAnchor : "start"}
                    fillOpacity={isHighlighted ? "1" : "0.3"}
                    style={style}
                    dx={dx}
                    dy={dy}
                    x={textPosX}
                    y={textPosY}>{textValue}
                </text>
                <line
                    x1={textPosX}
                    y1={0}
                    x2={textPosX}
                    y2={height}
                    style={{ stroke: textColor, strokeWidth: "0.4" }} />
            </>
            );
        } else {
            let offset = 0;
            if (multiText.length > 1 && multiText[1] !== undefined) {
                offset = 10;
            }
            return (
                <>
                    <text
                        textAnchor={textAnchor ? textAnchor : "start"}
                        fillOpacity={isHighlighted ? "1" : "0.3"}
                        style={style}
                        x={textPosX}
                        y={textPosY}>
                        <tspan x={dx} dy={dy - offset}>{multiText[0]}</tspan>
                        <tspan x={dx} dy={dy + 14}>{multiText[1]}</tspan>
                    </text>
                    <line
                        x1={textPosX}
                        y1={0}
                        x2={textPosX}
                        y2={height}
                        style={{ stroke: textColor, strokeWidth: "0.4" }} />
                </>
            );
        }
    }
}

LabelText.propTypes = {
    textValue: PropTypes.string.isRequired,
    textPosX: PropTypes.number.isRequired,
    textPosY: PropTypes.number.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    textAnchor: PropTypes.string,
    style: PropTypes.object,
    multiLine: PropTypes.number
}

export default LabelText;
