import React from "react";
import { Glyphicon } from "react-bootstrap";
import ColumnCustomFilterControl from "../ListViewCustomFilter/ColumnCustomFilterControl.jsx";
import StringUtil from "StringUtil";
import LocalizationStore from "LocalizationStore"
import listExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore.js";

export default class HeaderCell extends React.Component {

  constructor(props) {
    super(props);
    this.onResizeHandleMouseDown = this.onResizeHandleMouseDown.bind(this);
    this.onResizerDblClick = this.onResizerDblClick.bind(this);
    this.onClickOnHeaderSort = this.onClickOnHeaderSort.bind(this);
    this.onClickHeaderFilter = this.onClickHeaderFilter.bind(this);
  }

  componentDidMount() {
    this.arrowDiv = document.getElementById('arrow11');
  }

  onResizeHandleMouseDown(e) {
    if (e.button !== 0) return;
    const pos = this.ColHeader.getBoundingClientRect();
    this.props.handleResize(this.props.data, pos.left);
    e.stopPropagation();
    e.preventDefault();
  }

  componentDidUpdate() {
    if (this.props.islabelRequired && !this.props.dragStarted) {
      if (this.props.data.dataItemId !== '-3') { // Flag
        setTimeout(() => {
          const node = this.ColHeader;
          if (node) {
            const data = this.props.data;
            const dataItemId = node.attributes["data-itemid"] && node.attributes["data-itemid"].value;
            const isCurrentDataItem = parseInt(dataItemId) === data.dataItemId;
            const el = node.children[0];
            if(el){
              // let width = data.customWidth ? data.customWidth : data.width;
              // || el.clientWidth !== width // removed this for bugfix PANWEB-6271
              if(data.innerTextValue && data.innerTextValue !== ""){
                StringUtil.ellipsisFill(el, LocalizationStore.getTranslatedData(`DataItemExt_DisplayName_${  this.props.data.dataItemId}`, this.props.data.displayName));
                if(isCurrentDataItem){
                  data.innerTextValue = el.innerText;
                }
              }
            }
          }
        }, 0);
      }
    }
  }

  onResizerDblClick  (e)  {
    if (!this.props.disbleFunctionOnSharedColumnSet) {
      this.props.onColumnResizerDoubleClick();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  onClickOnHeaderSort  (e)  {
    if (e.target && e.target.id !== "reseize-hndl") {
      if (!this.props.disbleFunctionOnSharedColumnSet) {
        this.props.handleColumnSort(e, this.props.data);
      }
      e.stopPropagation();
      // e.preventDefault();
    }

  }

  onClickHeaderFilter  (e)  {
    if (e.target && e.target.id !== "reseize-hndl") {
      if (!this.props.disbleFunctionOnSharedColumnSet) {
        this.props.handleColumnCustomFilter(e, this.props.data);
      }
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onMouseDown(e, data) {
    this.props.onMouseDown(e, data);
  }

  render() {
    const colWidth = this.props.getColumnWidth(this.props.data);
    const islabelRequired = this.props.islabelRequired;
    const style = {
      width: colWidth, minWidth: this.props.data.minWidth, left: this.props.left
    }
    const isNotImportedList = listExplorerStore.isNotImportedList();
    const isCellDisable = isNotImportedList && !islabelRequired;
    let draggedClass = this.props.disbleFunctionOnSharedColumnSet || !this.props.data.isContextMenuEnable || isCellDisable ? 'left-head-frozon disabled small-normal' : 'left-head-frozon small-normal';
    if (this.props.background !== undefined) {
      if (this.arrowDiv.style.display !== 'block') {
        this.arrowDiv.style.display = 'block';
        this.arrowDiv.style.zIndex = '99';
        this.arrowDiv.style.top = '58px';
        this.arrowDiv.style.left = `${this.props.posArrow  }px`;
      }
      else {
        this.arrowDiv.style.left = `${this.props.posArrow  }px`;
      }

      /* Animation Arrow Effect
      if (this.arrowDiv && this.upNode == undefined && this.downNode == undefined) {
        this.upNode = this.arrowDiv.childNodes[0];
        this.downNode = this.arrowDiv.childNodes[1];
        this.upNode.style.WebkitAnimation = 'none';
        this.downNode.style.WebkitAnimation = 'none';
        this.upNode.style.animation = 'none';
        this.downNode.style.animation = 'none';
        let self = this;
        window.setTimeout(function () {
          self.upNode.style.animation = '';
          self.downNode.style.animation = '';
          self.upNode.style.WebkitAnimation = '';
          self.downNode.style.WebkitAnimation = '';
        }, 10);
      }
      */
    }

    const resizeHandle = <div className="header-cell-resize" id="reseize-hndl" onMouseDown={this.onResizeHandleMouseDown} onDoubleClick={this.onResizerDblClick} />

    const displayName = this.props.data.dataItemId !== '-3' ? LocalizationStore.getTranslatedData(`DataItemExt_DisplayName_${  this.props.data.dataItemId}`, this.props.data.displayName) : this.props.data.displayName;
    const className = this.props.data.className;
    const isCustCol =this.props.data.isCustomColumn;
    const custColColor = isCustCol?'#9764E6':'';
    const displayTextColumnFilter = (this.props.data.dataItemId === "-7" || this.props.data.dataItemId === "-4000013") ? ColumnCustomFilterControl.getColumnText(this.props.data.dataItemId) : ColumnCustomFilterControl.getColumnText(this.props.data.dataItemId);
    let sortInfo = '';

    if (this.props.data.isSortingEnable && this.props.data.sortInfo) {
      let sortingTypeClassName = '';

      if (this.props.data.sortInfo.sortAsc) sortingTypeClassName = 'triangle-top';
      else sortingTypeClassName = 'triangle-bottom';
      sortInfo = <div> <span className="sorting-order small-normal">{this.props.data.sortInfo.sortOrderNum}</span><span className="sorting  xx-small-normal"><Glyphicon glyph={sortingTypeClassName} /></span></div>;

      if (islabelRequired) draggedClass = `${draggedClass  } action-sort`;
    }

    if (!islabelRequired) {
      return (
        <div className={draggedClass} ref={(r) => { this.ColHeader = r; }} draggable="false"
          onMouseDown={!this.props.disbleFunctionOnSharedColumnSet && !isCellDisable ? (e) => (this.onMouseDown(e, this.props.data)) : null}
          onClick={!isCellDisable ? this.onClickHeaderFilter : null}
          onContextMenu={!this.props.disbleFunctionOnSharedColumnSet && !isCellDisable ? this.props.onCustomFilterContextMenuClick : null}
          data-itemid={this.props.data.dataItemId}
          key={this.props.data.dataItemId} style={style}>
          {(displayTextColumnFilter !== "") ?
            <div className={(this.props.screenOnOff) ? "filter-select-text medium-normal" : "filter-select-text-off medium-normal"} style={{ width: colWidth }}>{displayTextColumnFilter}</div> : null}{!this.props.disbleFunctionOnSharedColumnSet ? resizeHandle : null}
        </div>
      );
    } else {
      return (
        <div className={draggedClass} ref={(r) => { this.ColHeader = r; }} id={`ColHeader${  this.props.data.dataItemId}`} draggable="false"
          onMouseDown={!this.props.disbleFunctionOnSharedColumnSet ? (e) => (this.onMouseDown(e, this.props.data)) : null}
          onClick={this.onClickOnHeaderSort}
          onContextMenu={!this.props.disbleFunctionOnSharedColumnSet ? this.props.onContextMenuClick : null}
          data-itemid={this.props.data.dataItemId}
          key={this.props.data.dataItemId} style={style}>
          <div className="display-Name-head" style={{ width: colWidth, color: custColColor }}>{displayName}{(className ? <div className={className}></div> : '')}</div> {sortInfo}{!this.props.disbleFunctionOnSharedColumnSet ? resizeHandle : null}
        </div>
      );
    }
  }

}
