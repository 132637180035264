import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import { FundamentalsConstants } from "../../../../Constants/FundamentalsConstants.js";
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";
import TabType from "../../../../Constants/TabType.js";
import DatagraphStore from "../DataGraphStore.js";
import DateHelper from "DateHelper";
import PeriodicityHelper from "PeriodicityHelper";
import SettingsStore from "SettingsStore";
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js';
import FundamentalTabPeriodicityType from 'Constants/FundamentalTabPeriodicityType.js';
import FundamentalTabFilterType from 'Constants/FundamentalTabFilterType.js';
import FundamentalTabSortOrderType from 'Constants/FundamentalTabSortOrderType.js';
import FundamentalTabEPSToggleState from 'Constants/FundamentalTabEPSToggleState.js';
import FundamentalTabReportObject from 'Stores/ConsoleWindow/Settings/Modules/DataGraph/FundamentalTabReportObject.js';
import GraphType from "Constants/GraphType.js";
import PeriodicityType from 'PeriodicityType';
import FundamentalApi from 'ServiceApi/Apis/FundamentalApi.js';
import GraphApi from "ServiceApi/Apis/GraphApi.js";
import ChartApi from "ServiceApi/Apis/ChartApi.js";
import moment from "moment";
import ArrayUtil from "ArrayUtil";
import { each, first, filter } from "underscore";
import TabFundamentalSettingHelper from 'Stores/ConsoleWindow/Settings/Modules/DataGraph/TabFundamentalSettingHelper.js';
import { MenuDialogConstants } from "../../../../Constants/MenuDialogConstants.js";
import LocalizationStore from 'LocalizationStore';
import AdobeUtil from "../../../../Utils/AdobeUtil";
import AdobeConstants from "../../../../Constants/AdobeConstants";
import TabFundamentalsHeaderStore from './TabFundamentalsHeaderStore.js';
import { TabDataGraphConstants } from "../../../../Constants/TabDataGraphConstants.js";
import MiniListHelper from "MiniListHelper";
import SymbolType from "../../../../Constants/SymbolType.js";
import MiniListStore from "../MiniList/MiniListStore.js";
import TabFundamentalsHelper from "../../../../Utils/TabFundamentalsHelper.js";
import TabFundamentalsDataType from "../../../../Constants/TabFundamentalsDataType.js";

let _reportAllFilterData = null;
let _reportRatiosData = null;
let _nonAnnualHeaderData = [];
let _optionsData = {};
let _state = undefined;
let _isLoading = true;
let _isSetNextInterval = false;
let _MetricActionAlert_Fundamentals = false;
let _IsrightReportActive = false;
let _currentAction = null;
let _reportTitle = "";
let _ideaListTitleData = { priceChartData: { SymbolInfo: { CPrice: "" }, endDate: undefined }, stockData: undefined };

const CHANGE_EVENT = "change";

class TabFundamentalsStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getCurrentAction() {
    return _currentAction;
  }

  clearCurrentAction() {
    _currentAction = null;
  }

  setReportData(result) {
    switch (_state["FundamentalReportType"]) {
      case FundamentalTabReportType.Income:
      case FundamentalTabReportType.Balancesheet:
      case FundamentalTabReportType.Cashflow: _reportAllFilterData = result.data; break;
      case FundamentalTabReportType.Ratios: _reportRatiosData = { FundamentalsRatiosList: result.fundamentalRatiosList, FundamentalsRatiosYearList: result.fundamentalRatiosyearList, currency: result.currencyName }; break;
      default:
        break;
    }
    _isLoading = false;

    if (_state["FundamentalSortOrder"] == FundamentalTabSortOrderType.Descending) { this.reverseReportData(); }
    if (_state["FundamentalPeriodicity"] != FundamentalTabPeriodicityType.FundamentalsAnnual) { this.setNonAnnualData(); }
    // if (DatagraphStore.getState().SelectedTabKey === TabType.Fundamental && _state.IsMiniListPlay && !_isSetNextInterval) {
    //   _isSetNextInterval = true;
    //   MiniListStore.setMiniListNextInterval();
    // }
    this.emit(CHANGE_EVENT);
    //this.changeLoadingState(); // fix-3998
  }

  setNonReportData() {
    _reportAllFilterData ? _reportAllFilterData.reportDetails = [] : _reportAllFilterData = null;
    _reportRatiosData ? _reportRatiosData.FundamentalsRatiosList = [] : _reportRatiosData = null;
    _isLoading = false;
    TabFundamentalsHelper.updateDataReadyInfo(TabFundamentalsDataType.NonReport);
    TabFundamentalsHelper.updateDataRenderInfo(TabFundamentalsDataType.NonReport);
    // if (DatagraphStore.getState().SelectedTabKey === TabType.Fundamental && _state.IsMiniListPlay && !_isSetNextInterval) {
    //   _isSetNextInterval = true;
    //   MiniListStore.setMiniListNextInterval();
    // }
    this.emit(CHANGE_EVENT);
  }

  setNonAnnualData() {
    // let state = DatagraphStore.getState();
    // fiscalMonthEnd = DateHelper.getMonthbyNumber(state.SymbolInfo.FiscalMonthEnd);
    switch (_state["FundamentalReportType"]) {
      case FundamentalTabReportType.Income:
      case FundamentalTabReportType.Balancesheet:
      case FundamentalTabReportType.Cashflow: this.setNonAnnualReportData(); break;
      case FundamentalTabReportType.Ratios: this.setNonAnnualRatiosData(); break;
      default:
        break;
    }
  }

  setNonAnnualReportData() {
    if (_reportAllFilterData && _reportAllFilterData.yearLabels.length > 0) {
      // let quarterSplit = "";
      let headerDetails = [];
      let yearLabels = _reportAllFilterData.yearLabels;
      let periodicityLabels = _reportAllFilterData.periodicityLabels;

      let sectionHeaders = [];
      let year = '';

      for (let i = 0; i < yearLabels.length; i++) {
        if (yearLabels[i] !== '') {
          if (_state.FundamentalSortOrder == FundamentalTabSortOrderType.Ascending) {
            year = yearLabels[i].indexOf('Fiscal ') > -1 ? yearLabels[i].replace('Fiscal ', '') : '';
            yearLabels[i] = year ? LocalizationStore.getTranslatedData('fr_Fiscal', 'Fiscal {0}', year) : yearLabels[i];
            sectionHeaders.splice(0, 0, yearLabels[i].toString());
            sectionHeaders.push(periodicityLabels[i]);
            headerDetails.push(sectionHeaders);
            sectionHeaders = [];
          }
          else {
            if (sectionHeaders.length > 0) {
              headerDetails.push(sectionHeaders);
              sectionHeaders = [];
            }
            year = yearLabels[i].indexOf('Fiscal ') > -1 ? yearLabels[i].replace('Fiscal ', '') : '';
            yearLabels[i] = year ? LocalizationStore.getTranslatedData('fr_Fiscal', 'Fiscal {0}', year) : yearLabels[i];
            sectionHeaders.push(yearLabels[i].toString());
            sectionHeaders.push(periodicityLabels[i]);
          }
        }
        else
          sectionHeaders.push(periodicityLabels[i]);
      }

      if (sectionHeaders.length > 0) {
        headerDetails.push(sectionHeaders);
        sectionHeaders = [];
      }

      // for (let i = 0; i < yearLabels.length; i++) {
      //     if (yearLabels[i] == '') {
      //         yearLabels.splice(i, 1);
      //         i--;
      //     } else {
      //         let year = yearLabels[i].indexOf('Fiscal ') > -1 ? yearLabels[i].replace('Fiscal ', '') : '';
      //         yearLabels[i] = year ? LocalizationStore.getTranslatedData('fr_Fiscal', 'Fiscal {0}', year) : yearLabels[i];
      //     }
      // }
      // for (let i = 0; i < periodicityLabels.length; i++) {
      //     if (periodicityLabels[i] == '') {
      //         periodicityLabels.splice(i, 1);
      //         i--;
      //     }
      // }
      // if (_state.FundamentalPeriodicity == FundamentalTabPeriodicityType.FundamentalsQuarterly) {
      //     if (_state.FundamentalSortOrder == FundamentalTabSortOrderType.Descending) {
      //         quarterSplit = fiscalMonthEnd;
      //     } else {
      //         quarterSplit = this.getAscendingQuarterlySplit(fiscalMonthEnd);
      //     }
      // } else {
      //     quarterSplit = periodicityLabels[1];
      // }

      // if (isAlreadySpliced == false) {
      //     for (let i = 0; i < periodicityLabels.length; i++) {
      //         if (periodicityLabels[i].indexOf(quarterSplit) >= 0) {
      //             periodicityLabels.splice(i + 1, 0, "");
      //             isAlreadySpliced = true;
      //         }
      //     }
      // }
      // isAlreadySpliced = false;
      // periodicityLabels.push("");

      // let sectionHeaders = [];
      // let j = 0;
      // let isYearAdded = false;
      // for (let i = 0; i < periodicityLabels.length; i++) {
      //     if (periodicityLabels[i] != "") {
      //         if (isYearAdded == false) {
      //             sectionHeaders.push(yearLabels[j]);
      //             isYearAdded = true;
      //             j++;
      //         }
      //         sectionHeaders.push(periodicityLabels[i]);
      //     } else {
      //         if (sectionHeaders.length > 0) {
      //             headerDetails.push(sectionHeaders);
      //         }
      //         sectionHeaders = [];
      //         isYearAdded = false;
      //     }
      // }
      _nonAnnualHeaderData = headerDetails;
    }
  }

  setNonAnnualRatiosData() {
    let splitLabel = "Q"; //quarterSplit = ""
    let headerDetails = []; let yearLabels = []; //let periodicityLabels = [];
    let yearsList = _reportRatiosData.FundamentalsRatiosYearList;
    yearsList = filter(yearsList, (rowYear) => rowYear.year !== 0);

    if (_state.FundamentalPeriodicity == FundamentalTabPeriodicityType.FundamentalsSemiAnnual) splitLabel = "S";

    if (yearsList != undefined) {
      for (let i = 0; i < yearsList.length; i++) {
        yearLabels.push(yearsList[i].year.toString());
      }
      yearLabels = this.removeDuplicates(yearLabels);

      let sectionHeaders = [];

      yearLabels.map((rowYear) => {
        sectionHeaders.push(LocalizationStore.getTranslatedData("fr_Fiscal", "Fiscal {0}", rowYear.toString()));
        const subRows = filter(yearsList, (rowList) => rowList.year.toString() == rowYear);
        subRows.map((rowdata) => {
          sectionHeaders.push(rowdata.qtrLabel + " (" + splitLabel + rowdata.qtr.toString() + ")");
        });
        headerDetails.push(sectionHeaders);
        sectionHeaders = [];
      });
    }


    // if (yearsList != undefined) {
    //     for (let i = 0; i < yearsList.length; i++) {
    //         let quarterString = yearsList[i].qtrLabel + " (" + splitLabel + yearsList[i].qtr.toString() + ")";
    //         periodicityLabels.push(quarterString);
    //         yearLabels.push(LocalizationStore.getTranslatedData("fr_Fiscal", "Fiscal {0}", yearsList[i].year.toString()));
    //     }
    //     yearLabels = this.removeDuplicates(yearLabels);
    //     if (_state.FundamentalPeriodicity == FundamentalTabPeriodicityType.FundamentalsQuarterly) {
    //         if (_state.FundamentalSortOrder == FundamentalTabSortOrderType.Descending) {
    //             quarterSplit = fiscalMonthEnd;
    //         } else {
    //             quarterSplit = this.getAscendingQuarterlySplit(fiscalMonthEnd);
    //         }
    //     } else {
    //         quarterSplit = periodicityLabels[1];
    //     }
    //     if (isAlreadySpliced == false) {
    //         for (let i = 0; i < periodicityLabels.length; i++) {
    //             if (periodicityLabels[i].indexOf(quarterSplit) >= 0) {
    //                 periodicityLabels.splice(i + 1, 0, "");
    //                 isAlreadySpliced = true;
    //             }
    //         }
    //     }
    //     isAlreadySpliced = false;
    //     periodicityLabels.push("");

    //     let sectionHeaders = [];
    //     let j = 0;
    //     let isYearAdded = false;
    //     for (let i = 0; i < periodicityLabels.length; i++) {
    //         if (periodicityLabels[i] != "") {
    //             if (isYearAdded == false) {
    //                 sectionHeaders.push(yearLabels[j]);
    //                 isYearAdded = true;
    //                 j++;
    //             }
    //             sectionHeaders.push(periodicityLabels[i]);
    //         } else {
    //             headerDetails.push(sectionHeaders);
    //             sectionHeaders = [];
    //             isYearAdded = false;
    //         }
    //     }
    // }
    _nonAnnualHeaderData = headerDetails;
  }

  getNonAnnualHeaderData() {
    return _nonAnnualHeaderData;
  }

  /*getAscendingQuarterlySplit(fiscalMonthEnd) {
    let month1, month2 = null;
    let monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    each(monthsOfYear, (month, num) => { if (fiscalMonthEnd == month) month1 = num + 1; })
    if (month1 < 10) { month2 = "0" + month1.toString(); } else { month2 = month1; }
    let falseDate = moment("1900-" + month2 + "-05"); let futuredate = moment(falseDate).add(3, 'M');
    let futureDateFormat = futuredate.format('DD-MM-YYYY'); let splitmonth = futureDateFormat.substr(3, 2);
    if (splitmonth.charAt(0) == "0") splitmonth = splitmonth.substr(1, 1);
    return monthsOfYear[splitmonth - 1];
  }*/

  removeDuplicates(yearLabels) {
    var a = [];
    for (let i = 0; i < yearLabels.length; i++) {
      var current = yearLabels[i];
      if (a.indexOf(current) < 0) a.push(current);
    }

    yearLabels.length = 0;
    for (let i = 0; i < a.length; i++) {
      yearLabels.push(a[i]);
    }
    return yearLabels;
  }

  getIdeaListTitleData() {
    const datagraphSettings = DatagraphStore.getState();
    _ideaListTitleData.priceChartData = { SymbolInfo: { CPrice: datagraphSettings.priceData && datagraphSettings.priceData.CPrice }, endDate: "" };
    _ideaListTitleData.stockData = {};
    const IdeaEndDate = moment(datagraphSettings.SymbolInfo.LastTradeDate);
    const IdeaStartDate = new Date(IdeaEndDate);
    IdeaStartDate.setFullYear(IdeaStartDate.getFullYear() - 10);
    const strStartDate = moment(IdeaStartDate).format('YYYYMMDD');
    const strEndDate = moment(IdeaEndDate).format('YYYYMMDD');
    const periodicity = PeriodicityHelper.convertToPeriodicity(_state.periodicity);
    const symbol = datagraphSettings.SymbolInfo.Symbol;
    const isMiniListPlay = datagraphSettings.IsMiniListPlay;
    GraphApi.GetAllIdeasRequest(datagraphSettings.Symbol, datagraphSettings.SymbolInfo.MsId, periodicity, strEndDate, strStartDate, 0, DatagraphStore.getCurrencyCode(''), datagraphSettings.SymbolInfo.Osid).then((result) => {
      if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)) {
        AppDispatcher.handleServerAction({
          actionType: FundamentalsConstants.ActionTypes.GET_IDEALIST_TITLE_DATA,
          data: result
        });
      }
    });
  }

  setIdeaListTitleData(data) {
    if (_ideaListTitleData && _ideaListTitleData.stockData) {
      _ideaListTitleData.stockData.ideasData = data;
    }
  }

  getIdeaListData() {
    return _ideaListTitleData;
  }

  getMetricAction() {
    return _MetricActionAlert_Fundamentals;
  }
  getReportState(){
    return _IsrightReportActive;
  }
  getOptionsData() {
    const state = DatagraphStore.getState();
    const symbol = state.SymbolInfo ? state.SymbolInfo.Symbol : '';
    const filterType = state.SymbolInfo.SymTypeEnum === SymbolType.USSTOCK ? FundamentalTabFilterType.All : FundamentalTabFilterType.Dollars
    const isMiniListPlay= state.IsMiniListPlay;
    FundamentalApi.getOptionsData().then((result) => {
      if(MiniListHelper.ActiveSymbolCheck(symbol,isMiniListPlay)){
        TabFundamentalsHelper.updateDataReadyInfo(TabFundamentalsDataType.Options);
        AppDispatcher.handleServerAction({
          actionType: FundamentalsConstants.ActionTypes.GET_OPTIONS_DATA,
          data: result
        });
      }
    });
    this.setReportFilter(filterType);
    this.getReportData();
    //TabFundamentalsHeaderStore.getCompanyInfo();
  }

  setOptionsData(data) {
    if (_optionsData) {
      _optionsData = data;
    }

  }

  getState() {
    if (_state === undefined) {
      _state = DatagraphStore.getState();
      let navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
      let fundamentalStockViewSettings = navDataGraphSettings.TabFundamentalsSettings.StockViewSettings;
      let periodicityOptions = navDataGraphSettings.TabFundamentalsSettings.PeriodicityOptions;
      let resizeSettings = navDataGraphSettings.TabFundamentalsSettings.ResizeSettings;

      _state["FundamentalPeriodicityOptions"] = periodicityOptions;
      _state["FundamentalResizeSettings"] = resizeSettings;
      _state["FundamentalPeriodicity"] = this.getFundamentalPeriodicity(fundamentalStockViewSettings);
      _state["FundamentalReportType"] = fundamentalStockViewSettings.ReportType;
      _state["FundamentalFilter"] = fundamentalStockViewSettings.Filter;
      _state["FundamentalTemplate"] = fundamentalStockViewSettings.Template;
      _state["FundamentalSortOrder"] = fundamentalStockViewSettings.SortOrder;
      _state["FundamentalEPSToggleState"] = FundamentalTabEPSToggleState.Date;
    }
    return _state;
  }

  ClearStateData(){
    if (_state){
      let navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
      let fundamentalStockViewSettings = navDataGraphSettings.TabFundamentalsSettings.StockViewSettings;
      let periodicityOptions = navDataGraphSettings.TabFundamentalsSettings.PeriodicityOptions;
      let resizeSettings = navDataGraphSettings.TabFundamentalsSettings.ResizeSettings;

      _state["FundamentalPeriodicityOptions"] = periodicityOptions;
      _state["FundamentalResizeSettings"] = resizeSettings;
      _state["FundamentalPeriodicity"] = this.getFundamentalPeriodicity(fundamentalStockViewSettings);
      _state["FundamentalReportType"] = fundamentalStockViewSettings.ReportType;
      _state["FundamentalFilter"] = fundamentalStockViewSettings.Filter;
      _state["FundamentalTemplate"] = fundamentalStockViewSettings.Template;
      _state["FundamentalSortOrder"] = fundamentalStockViewSettings.SortOrder;
      _state["FundamentalEPSToggleState"] = FundamentalTabEPSToggleState.Date;
      _optionsData = {};
      _reportAllFilterData = null;
      _reportRatiosData = null;
      _ideaListTitleData = { priceChartData: { SymbolInfo: { CPrice: "" }, endDate: undefined }, stockData: undefined };
      _nonAnnualHeaderData = [];
      _isLoading = true;
    }
  }

  getLoadingState() {
    return _isLoading;
  }

  changeLoadingState() {
    if (_isLoading == false) _isLoading = true;
  }

  getReportData() {
    let datagraphSettings = DatagraphStore.getState();
    if (_state.FundamentalPeriodicity && this.isPeriodicityAvailable(_state.FundamentalPeriodicity) == false) {
      if (this.isPeriodicityAvailable(FundamentalTabPeriodicityType.FundamentalsQuarterly)) {
        _state.FundamentalPeriodicity = FundamentalTabPeriodicityType.FundamentalsQuarterly;
      }
      else if (this.isPeriodicityAvailable(FundamentalTabPeriodicityType.FundamentalsSemiAnnual)) {
        _state.FundamentalPeriodicity = FundamentalTabPeriodicityType.FundamentalsSemiAnnual;
      }
      else if (this.isPeriodicityAvailable(FundamentalTabPeriodicityType.FundamentalsAnnual)) {
        _state.FundamentalPeriodicity = FundamentalTabPeriodicityType.FundamentalsAnnual;
      }
    }
    if(!datagraphSettings.SymbolInfo.IsFundamentalAnnaulReportsAvailable && !datagraphSettings.SymbolInfo.IsFundamentalSemiAnnaulReportsAvailable && !datagraphSettings.SymbolInfo.IsFundamentalQuarterlyReportsAvailable){
      TabFundamentalsHelper.setCurrentReportDataType(TabFundamentalsDataType.NonReport);
      this.setNonReportData();
      return;
    }
    if (_state.FundamentalFilter == FundamentalTabFilterType.All && _state.FundamentalReportType != FundamentalTabReportType.Ratios) {
      TabFundamentalsHelper.setCurrentReportDataType(TabFundamentalsDataType.AllFilterReport);
      this.getAllFilterReportData(datagraphSettings);
    }
    else {
      const symbol = _state.SymbolInfo ? _state.SymbolInfo.Symbol : '';
      const isMiniListPlay= _state.IsMiniListPlay;
      if (_state.FundamentalFilter == FundamentalTabFilterType.Dollars || _state.FundamentalReportType == FundamentalTabReportType.Ratios) {
        TabFundamentalsHelper.setCurrentReportDataType(TabFundamentalsDataType.Report);
        FundamentalApi.getdata(_state).then((result) => {
          if(MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)){
            TabFundamentalsHelper.updateDataReadyInfo(TabFundamentalsDataType.Report);
            _reportTitle = (_state.FundamentalReportType != FundamentalTabReportType.Ratios) ? result.data.titleFormat : "";
            AppDispatcher.handleServerAction({
              actionType: FundamentalsConstants.ActionTypes.GET_REPORT_DATA,
              data: result
            });
          }
        });
      } else {
        TabFundamentalsHelper.setCurrentReportDataType(TabFundamentalsDataType.NonDollarFilterReport);
        let nonDollarFilterReq = [FundamentalApi.getAllFilterdata(FundamentalTabFilterType.Dollars, _state, datagraphSettings),
        FundamentalApi.getAllFilterdata(_state.FundamentalFilter, _state, datagraphSettings)];
        Promise.all(nonDollarFilterReq).then((nonDollarResponse) => {
          if(MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)){
            TabFundamentalsHelper.updateDataReadyInfo(TabFundamentalsDataType.NonDollarFilterReport);
            let result = nonDollarResponse;
            try {
              _reportTitle = result[0].data.titleFormat;
              let resultDetails = _state.FundamentalFilter === FundamentalTabFilterType.Yearoveryear || _state.FundamentalFilter === FundamentalTabFilterType.Commonsize ? result[1] : result[0];
              this.setReportData(resultDetails);
            }
            catch (e) {
              console.error(e);
            }
            TabFundamentalsHelper.updateDataReadyInfo(TabFundamentalsDataType.NonDollarFilterReport);
          }
        })
      }
    }
  }

  getAllFilterReportData(datagraphSettings) {
    let allFilterReq = [FundamentalApi.getAllFilterdata(FundamentalTabFilterType.Dollars, _state, datagraphSettings),
    FundamentalApi.getAllFilterdata(FundamentalTabFilterType.Yearoveryear, _state, datagraphSettings)];

    if (_state.FundamentalReportType !== FundamentalTabReportType.Cashflow)
      allFilterReq.push(FundamentalApi.getAllFilterdata(FundamentalTabFilterType.Commonsize, _state, datagraphSettings));

    const symbol = datagraphSettings.SymbolInfo ? datagraphSettings.SymbolInfo.Symbol : '';
    const isMiniListPlay= datagraphSettings.IsMiniListPlay;
    Promise.all(allFilterReq).then((allFilterResponse) => {
      if(MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)){
        TabFundamentalsHelper.updateDataReadyInfo(TabFundamentalsDataType.AllFilterReport);
      //If you find mismatched data in the fundamental reports, please note the data order first. 
      allFilterResponse[0].data.filterType = FundamentalTabFilterType.Dollars;
      allFilterResponse[allFilterResponse.length - 1].data.filterType = FundamentalTabFilterType.Yearoveryear;

      let result = [];
      if (allFilterResponse.length == 3) {
        allFilterResponse[1].data.filterType = FundamentalTabFilterType.Yearoveryear;
        allFilterResponse[2].data.filterType = FundamentalTabFilterType.Commonsize;
        result.push(allFilterResponse[0]);
        result.push(allFilterResponse[2]);
        result.push(allFilterResponse[1]);
      } else {
        result = allFilterResponse;
      }
      _reportTitle = result[0].data.titleFormat;
      try {
        AppDispatcher.handleServerAction({
          actionType: FundamentalsConstants.ActionTypes.GET_ALL_REPORT_DATA,
          data: result
        });
      }
      catch (e) {
        console.error(e);
      }
      }
    })
  }

  reverseReportData() {
    switch (_state.FundamentalReportType) {
      case FundamentalTabReportType.Income:
      case FundamentalTabReportType.Balancesheet:
      case FundamentalTabReportType.Cashflow: this.setReversedReportData(); break;
      case FundamentalTabReportType.Ratios: this.setReversedRatiosData(); break;
      default:
        break;
    }
  }

  getOptionsDataHelper() {
    let optionsDataHelper = { periodicity: "", report: "" };
    switch (_state.FundamentalPeriodicity) {
      case FundamentalTabPeriodicityType.FundamentalsAnnual: optionsDataHelper.periodicity = "annual"; break;
      case FundamentalTabPeriodicityType.FundamentalsQuarterly: optionsDataHelper.periodicity = "quarterly"; break;
      case FundamentalTabPeriodicityType.FundamentalsSemiAnnual: optionsDataHelper.periodicity = "interim"; break;
      default:
        break;
    }

    switch (_state.FundamentalReportType) {
      case FundamentalTabReportType.Income: optionsDataHelper.report = "IncomeReport"; break;
      case FundamentalTabReportType.Balancesheet: optionsDataHelper.report = "BalanceSheet"; break;
      case FundamentalTabReportType.Cashflow: optionsDataHelper.report = "CashFlow"; break;
      case FundamentalTabReportType.Ratios: optionsDataHelper.report = "Ratios"; break;
      default:
        break;
    }
    return optionsDataHelper;
  }

  setReversedReportData() {
    for (let i = 0; i < _reportAllFilterData.reportDetails.length; i++) {
      _reportAllFilterData.reportDetails[i] = this.reverseReportObject(_reportAllFilterData.reportDetails[i]);
    }
    _reportAllFilterData.yearLabels.reverse();
    _reportAllFilterData.periodicityLabels.reverse();
    this.setNonAnnualData();
  }

  reverseReportObject(report) {
    if (report != undefined) {
      report.cells = this.reverseIndividualReport(report.cells);
      report.cellIsNegative = this.reverseIndividualReport(report.cellIsNegative);
      if (report.cellsRightLineVisibilityAsc) { report.cellsRightLineVisibilityAsc = this.reverseIndividualReport(report.cellsRightLineVisibilityAsc); }
      if (report.cellsRightLineVisibilityDesc) { report.cellsRightLineVisibilityDesc = this.reverseIndividualReport(report.cellsRightLineVisibilityDesc); }
    }
    return report;
  }

  reverseIndividualReport(report) {
    let firstElement = report[0];
    report.splice(0, 1);
    report.reverse();
    report.unshift(firstElement);
    return report;
  }

  setReversedRatiosData() {
    _reportRatiosData.FundamentalsRatiosYearList.reverse();
    this.setNonAnnualData();
  }

  setAllFilterReportData(data) {
   // _state.FundamentalFilter = FundamentalTabFilterType.All;

    let reportsLength = [];
    if (data) {
      each(data, (datum) => {
        if (datum.data.reportDetails.length > 0) reportsLength.push(first(datum.data.reportDetails).cells.length);
      })

      _reportAllFilterData = { reportDetails: [], periodicityLabels: [], yearLabels: [], titleFormat: "" }
      //let lengths = [];

      for (let i = 0; i < data.length; i++) {
        let reports = data[i].data.reportDetails;
        each(reports, (report) => { _reportAllFilterData.reportDetails.push(report); })
        let nextReport = data[i + 1];
        if (nextReport) {
          let obj = new FundamentalTabReportObject(Math.max(...reportsLength), nextReport.data, _state);
          if (_reportAllFilterData.reportDetails.length > 0) _reportAllFilterData.reportDetails.push(obj);
        }
      }
      each(_reportAllFilterData.reportDetails, (allFilterRecord) => {
        if (allFilterRecord.cells.length < Math.max(...reportsLength)) {
          let numOfCellstoAdd = Math.max(...reportsLength) - allFilterRecord.cells.length;
          for (let i = 0; i < numOfCellstoAdd; i++){
            allFilterRecord.cells.push(" ");
            allFilterRecord.cellIsNegative.push(false);
            allFilterRecord.cellsRightLineVisibilityDesc.push(false);
          }
        }
      })
      _reportAllFilterData.periodicityLabels = data[0].data.periodicityLabels;
      _reportAllFilterData.yearLabels = data[0].data.yearLabels;
      _reportAllFilterData.titleFormat = data[0].data.titleFormat;
      _reportAllFilterData.currency = data[0].data.currency;
    } else {
      _reportAllFilterData = [];
    }
    if (_state["FundamentalSortOrder"] == FundamentalTabSortOrderType.Descending) { this.reverseReportData(); }
    if (_state["FundamentalPeriodicity"] != FundamentalTabPeriodicityType.FundamentalsAnnual) { this.setNonAnnualData(); }
    _isLoading = false;
    // if (DatagraphStore.getState().SelectedTabKey === TabType.Fundamental && _state.IsMiniListPlay && !_isSetNextInterval) {
    //   _isSetNextInterval = true;
    //   MiniListStore.setMiniListNextInterval();
    // }
    this.emit(CHANGE_EVENT);    
    //this.changeLoadingState(); // fix-3998
  }

  getFundamentalFilterDisplayLabels(filters) {
    let displayFilterLabels = [];
    each(filters, (filter) => {
      let filterLabel = "";
      switch (filter) {
        case FundamentalTabFilterType.Dollars: filterLabel = "Dollars"; break;
        case FundamentalTabFilterType.Commonsize: filterLabel = "Common Size"; break;
        case FundamentalTabFilterType.Yearoveryear: filterLabel = "Year-Over-Year"; break;
        case FundamentalTabFilterType.All: filterLabel = "All"; break;
        default: break;
      }
      displayFilterLabels.push(filterLabel);
    })
    return displayFilterLabels;
  }

  setReportTemplate(templateId) {
    if (_state) {
      _state["FundamentalTemplate"] = parseInt(templateId);
    }
  }

  setReportFilter(filterId) {
    if (_state) {
      _state["FundamentalFilter"] = parseInt(filterId);
    }
  }

  setReportType(type) {
    if (_state) {
      _state["FundamentalReportType"] = parseInt(type);
    }
  }

  setSortOrderType(sortId) {
    if (_state) {
      _state["FundamentalSortOrder"] = parseInt(sortId);
      _isLoading = false;
      this.reverseReportData();
      this.emit(CHANGE_EVENT);
    }
  }

  setFundamentalsPeriodicity(periodicity) {
    let periodicityOptions = _state["FundamentalPeriodicityOptions"];
    switch (parseInt(periodicity)) {
      case FundamentalTabPeriodicityType.FundamentalsAnnual:
        periodicityOptions.FundamentalsAnnual.IsSelected = true;
        periodicityOptions.FundamentalsSemiAnnual.IsSelected = false;
        periodicityOptions.FundamentalsQuarterly.IsSelected = false;
        _state["FundamentalPeriodicity"] = FundamentalTabPeriodicityType.FundamentalsAnnual;
        _state["FundamentalPeriodicityText"] = GraphType.FundamentalsAnnual;
        break;
      case FundamentalTabPeriodicityType.FundamentalsSemiAnnual:
        periodicityOptions.FundamentalsSemiAnnual.IsSelected = true;
        periodicityOptions.FundamentalsAnnual.IsSelected = false;
        periodicityOptions.FundamentalsQuarterly.IsSelected = false;
        _state["FundamentalPeriodicity"] = FundamentalTabPeriodicityType.FundamentalsSemiAnnual;
        _state["FundamentalPeriodicityText"] = GraphType.FundamentalsSemiAnnual;
        break;
      case FundamentalTabPeriodicityType.FundamentalsQuarterly:
        periodicityOptions.FundamentalsQuarterly.IsSelected = true;
        periodicityOptions.FundamentalsSemiAnnual.IsSelected = false;
        periodicityOptions.FundamentalsAnnual.IsSelected = false;
        _state["FundamentalPeriodicity"] = FundamentalTabPeriodicityType.FundamentalsQuarterly;
        _state["FundamentalPeriodicityText"] = GraphType.FundamentalsQuarterly;
        break;
      default:
        break;
    }
  }

  setEPSToggleState(toggleState) {
    if (_state) {
      _state["FundamentalEPSToggleState"] = parseInt(toggleState);
    }
  }

  isPeriodicityAvailable(periodicity) {
    let datagraphSettings = DatagraphStore.getState();
    let periodicityOption = null;
    if (datagraphSettings.SymbolInfo) {
      switch (periodicity) {
        case FundamentalTabPeriodicityType.FundamentalsAnnual: periodicityOption = "IsFundamentalAnnaulReportsAvailable"; break;
        case FundamentalTabPeriodicityType.FundamentalsSemiAnnual: periodicityOption = "IsFundamentalSemiAnnaulReportsAvailable"; break;
        case FundamentalTabPeriodicityType.FundamentalsQuarterly: periodicityOption = "IsFundamentalQuarterlyReportsAvailable"; break;
        default:
          break;
      }
      return datagraphSettings.SymbolInfo[periodicityOption] ? true : false;
    }
  }

  getFundamentalPeriodicity(fundamentalStockViewSettings) {
    let fundamentalPeriodicity = undefined;
    switch (fundamentalStockViewSettings.Periodicity) {
      case GraphType.FundamentalsAnnual: fundamentalPeriodicity = FundamentalTabPeriodicityType.FundamentalsAnnual; break;
      case GraphType.FundamentalsSemiAnnual: fundamentalPeriodicity = FundamentalTabPeriodicityType.FundamentalsSemiAnnual; break;
      case GraphType.FundamentalsQuarterly: fundamentalPeriodicity = FundamentalTabPeriodicityType.FundamentalsQuarterly; break;
      default: break;
    }
    return fundamentalPeriodicity;
  }

  getFundamentalsReportData(fundamentalReportType) {
    let reportType = '';
    if(_state)
      reportType = _state["FundamentalReportType"];

    if (fundamentalReportType) {
      reportType = fundamentalReportType;
    }

    switch (reportType) {
      case FundamentalTabReportType.Income:
      case FundamentalTabReportType.Balancesheet:
      case FundamentalTabReportType.Cashflow: 
           return _reportAllFilterData;
      case FundamentalTabReportType.Ratios: 
           return _reportRatiosData;
    }
  }

  getFundamentalsOptionsData() {
    return _optionsData;
  }

  getReportUniqueTitle() {
    return _reportTitle;
  }

  getReportTypeString(fundReportType, fundFilter) {
    let strReportType = "";
    switch (fundReportType) {
      case FundamentalTabReportType.Balancesheet:
        strReportType = LocalizationStore.getTranslatedData("fr_BalanceSheetfor", "Balance Sheet for {0}");

        switch (fundFilter) {
          case FundamentalTabFilterType.Dollars:
            break;
          case FundamentalTabFilterType.Commonsize:
            strReportType = LocalizationStore.getTranslatedData("fr_CommonSizeBalancefor", "Common Size Balance for {0}");
            break;
          case FundamentalTabFilterType.Yearoveryear:
            strReportType = LocalizationStore.getTranslatedData("fr_percentChangeFor", "Year-Over-Year Percent Change for {0}");
            break;
          default:
            break;
        }
        break;
      case FundamentalTabReportType.Income:
        strReportType = LocalizationStore.getTranslatedData("fr_incomeStatementfor", "Income Statement for {0}");

        switch (fundFilter) {
          case FundamentalTabFilterType.Dollars:
            break;
          case FundamentalTabFilterType.Commonsize:
            strReportType = LocalizationStore.getTranslatedData("fr_commonSizeIncomeFor", "Common Size Income for {0}");
            break;
          case FundamentalTabFilterType.Yearoveryear:
            strReportType = LocalizationStore.getTranslatedData("fr_percentChangeFor", "Year-Over-Year Percent Change for {0}");
            break;
          default:
            break;
        }
        break;
      case FundamentalTabReportType.Cashflow:
        strReportType = LocalizationStore.getTranslatedData("fr_CashFlowfor", "Cash Flow for {0}");

        switch (fundFilter) {
          case FundamentalTabFilterType.Dollars:
            break;
          case FundamentalTabFilterType.Commonsize:
            break;
          case FundamentalTabFilterType.Yearoveryear:
            strReportType = LocalizationStore.getTranslatedData("fr_percentChangeFor", "Year-Over-Year Percent Change for {0}");
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    return strReportType;
  }

  saveFundamentalSettings(actionType, state) {    
    let navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
    let tabFundamentalsSettings = navDataGraphSettings.TabFundamentalsSettings;

    switch (actionType) {
      case FundamentalsConstants.ActionTypes.SET_TEMPLATE_TYPE:
        TabFundamentalSettingHelper.updateReportTemplate(tabFundamentalsSettings, state);
        break;

      case FundamentalsConstants.ActionTypes.SET_REPORT_TYPE:
        TabFundamentalSettingHelper.updateReportType(tabFundamentalsSettings, state);
        break;

      case FundamentalsConstants.ActionTypes.SET_SORT_TYPE:
        TabFundamentalSettingHelper.updateReportSortOrder(tabFundamentalsSettings, state);
        break;

      case FundamentalsConstants.ActionTypes.SET_FILTER_TYPE:
        TabFundamentalSettingHelper.updateReportFilter(tabFundamentalsSettings, state);
        break;

      case FundamentalsConstants.ActionTypes.SET_FUNDAMENTALS_PERIODICITY:
        TabFundamentalSettingHelper.updateReportPeriodicity(tabFundamentalsSettings, state);
        break;

      case 'EPSToggleState':
        TabFundamentalSettingHelper.updateEPSToggleState(tabFundamentalsSettings, state);
        break;
      case FundamentalsConstants.ActionTypes.GICSSELECTED_FUNDAMENTALS:
        TabFundamentalSettingHelper.updateISGICSFlag(tabFundamentalsSettings, state);
        break;
      default:
        break;
    }
    SettingsStore.saveSettings();
  }

  onSymbolChanged = () => {
    const state = DatagraphStore.getState();
    const tabType = DatagraphStore.getTabType(state.SelectedTabKey);

    if (tabType === TabType.Fundamental) {
      this.getOptionsData();
      this.getIdeaListTitleData();
    }
  }

  pushDataToAdobe() {    
    // Push data to DTM while loading login page
    const state = DatagraphStore.getState();    
    const navDataGraphSetting = DatagraphStore.getNavDataGraphSettings();
    if (state.SelectedTabKey === TabType.Datagraph)
      return;
    const optionsDataHelper = this.getOptionsDataHelper();

    AdobeUtil.DTMTrack({
      pageName: "Panaray : Symbols : Fundamentals",
      siteSection: "Panaray : Symbols",
      userType: "User",
      symbol: navDataGraphSetting.symbol,
      inteval: optionsDataHelper.periodicity,
      reportType: optionsDataHelper.report

    },
      AdobeConstants.TAB_FUNDAMENTALS_PAGE_VIEW);
  }


  dispatcherCallback(payload) {
    AppDispatcher.waitFor([DatagraphStore.dispatchToken]);
    const action = payload.action;
    const data = action.data;    
    switch (action.actionType) {
        case FundamentalsConstants.ActionTypes.GET_REPORT_DATA:
        {
            let responsedata = data;
            if (ArrayUtil.isArray(data) == true) {
                responsedata = data[1];
                _reportTitle = data[0].data.titleFormat;
            } else {
                _reportTitle = (_state["FundamentalReportType"] != FundamentalTabReportType.Ratios)
                    ? data.data.titleFormat
                    : "";
            }
            this.setReportData(responsedata);
            TabFundamentalsHelper.updateDataRenderInfo(TabFundamentalsDataType.Report);
        }
        break;
      case FundamentalsConstants.ActionTypes.GET_OPTIONS_DATA:
        this.setOptionsData(data.data);
        this.pushDataToAdobe();
        TabFundamentalsHelper.updateDataRenderInfo(TabFundamentalsDataType.Options);
        break;
      case FundamentalsConstants.ActionTypes.SET_TEMPLATE_TYPE:
        this.setReportTemplate(data);
        this.saveFundamentalSettings(action.actionType, _state["FundamentalTemplate"]);
        break;
      case FundamentalsConstants.ActionTypes.SET_REPORT_TYPE:
        this.setReportType(data);
        this.saveFundamentalSettings(action.actionType, _state["FundamentalReportType"]);
        break;
      case FundamentalsConstants.ActionTypes.SET_SORT_TYPE:
        this.setSortOrderType(data);
        this.saveFundamentalSettings(action.actionType, _state["FundamentalSortOrder"]);
        break;
      case FundamentalsConstants.ActionTypes.SET_FILTER_TYPE:
        this.setReportFilter(data);
        this.saveFundamentalSettings(action.actionType, _state["FundamentalFilter"]);
        break;
      case FundamentalsConstants.ActionTypes.SET_FUNDAMENTALS_PERIODICITY:
        _currentAction = FundamentalsConstants.ActionTypes.SET_FUNDAMENTALS_PERIODICITY;
        this.setFundamentalsPeriodicity(data);
        this.saveFundamentalSettings(action.actionType, _state["FundamentalPeriodicityText"]);
        this.pushDataToAdobe();
        break;
      case FundamentalsConstants.ActionTypes.GET_ALL_REPORT_DATA:
        if (ArrayUtil.isArray(data) == true) {
          _reportTitle = data[0].data.titleFormat;
        } else {
          _reportTitle = (_state["FundamentalReportType"] != FundamentalTabReportType.Ratios) ? data.data.titleFormat : "";
        }
        this.setAllFilterReportData(data);
        TabFundamentalsHelper.updateDataRenderInfo(TabFundamentalsDataType.AllFilterReport);
        break;
      case FundamentalsConstants.ActionTypes.GET_COMPANY_INFO:
        this.setEPSToggleState(data);
        break;
      case FundamentalsConstants.ActionTypes.GET_IDEALIST_TITLE_DATA:
        this.setIdeaListTitleData(data);
        this.emit(CHANGE_EVENT);
        break;
      case SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED:
      case SettingsConstants.ActionTypes.INDEX_SYMBOL_CHANGED:
        if(!_state) _state = this.getState();
        _state.IsMiniListPlay = data.IsMiniListPlay ? data.IsMiniListPlay : false;
        _isSetNextInterval = false;
        TabFundamentalsHelper.clearDataStatus();
        this.onSymbolChanged();
        break;
      case FundamentalsConstants.ActionTypes.SET_METRIC_ACTION_ALRET_CLOSE_FUNDAMENTALS:
        _MetricActionAlert_Fundamentals = action.data;
        this.emit(CHANGE_EVENT);
        break;
      case FundamentalsConstants.ActionTypes.SET_REPORTS_RIGHTSIDE_CLICK:
        _currentAction = FundamentalsConstants.ActionTypes.SET_REPORTS_RIGHTSIDE_CLICK;
        _IsrightReportActive = action.data;
        const settings = SettingsStore.getConsoleSettings();
	      settings.KeyDownActiveZone = SettingsConstants.KeyDownActiveZoneType.FUNDAMENTAL_TAB;
        break;  
      case TabDataGraphConstants.ActionTypes.SET_MOST_RECENT_TRADE_DATE:
        this.emit(CHANGE_EVENT);
        break;
      case SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED:
        _currentAction = SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED;
        this.ClearStateData();
        this.emit(CHANGE_EVENT);
        break;
      default:
        break;
    }
  }
}

const tabFundamentalsStore = new TabFundamentalsStore();
export default tabFundamentalsStore;
