import Serializable from "Serializable";
class MultiplierSettings extends Serializable {

    constructor(isVisible = true) {
        super();
        this.IsAvailable = true;
        this.IsVisible = isVisible;
        this.Auto = true;
        this.Fixed = false;
    }

    onSeralisedInfoRecieved(info) {
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
        this.Auto = info.Auto !== undefined ? info.Auto : true;
        this.Fixed = info.Fixed !== undefined ? info.Fixed : false;
        this.IsAvailable = true;
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            Auto: this.Auto,
            Fixed: this.Fixed
        };
    }

    getObjectMapKey() {
        return "MultiplierSettings";
    }
}

export default MultiplierSettings;