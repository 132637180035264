import Scale from "./Scale.js";
import GraphType from "GraphType";
import SymbolType from "SymbolType";
import ExtremeDataValue from "ExtremeDataValue";

var rayAppConfigMgr =
{
  LogPriceScaleDailyFactor: { value: 4.0 },
  LogPriceScaleMonthlyFactor: { value: 0.6 },
  LogPriceScaleIntraday1Factor: { value: 64.0 },
  LogPriceScaleIntraday510Factor: { value: 16.0 },
  LogPriceScaleIntraday153060Factor: { value: 6.0 },
  LogPriceScaleDefaultFactor: { value: 1.1 }
};

var PrScl0 = [];
var PrScl1 = [];
var PrScl2 = [];
class FixedLogScale extends Scale {
  getObjectMapKey() {
    if (this.wonFixed) {
      return "FixedWonLogScale";
    } else {
      return "FixedLogScale";
    }
  }
  ComputePrice(y) {
    var dGraphDistance = this.Height - y;
    dGraphDistance += this.Lowpt;

    var dPriceDiffFromBottom = dGraphDistance / this.Factor;
    dPriceDiffFromBottom += this.log10(this.minPrice);

    return Math.pow(10, dPriceDiffFromBottom);
  }
  ComputeY(dPrice) {
    var dPriceDiffFromBottom;

    if (dPrice <= 0) {
      // LogInfo of a negative or zero value would be bad...
      // dPriceDiffFromBottom = 0;
      return this.Height + 100;
    }
    else {
      dPriceDiffFromBottom = this.log10(dPrice) - this.log10(this.minPrice);
    }

    //    return Math.round(this.Height - ((dPriceDiffFromBottom * this.Factor) - this.Lowpt), 2);
    return this.Height - ((dPriceDiffFromBottom * this.Factor) - this.Lowpt);
  }
  //ComputeYL(dPrice, dLow)
  ComputeYL(dPrice) {
    var dPriceDiffFromBottom;

    if (dPrice <= 0) {
      // LogInfo of a negative or zero value would be bad...
      // dPriceDiffFromBottom = 0;
      return this.Height + 100;
    }
    else {
      dPriceDiffFromBottom = this.log10(dPrice) - this.log10(this.minPrice * 0.40);
    }

    //    return Math.round(this.Height - ((dPriceDiffFromBottom * this.Factor) - this.Lowpt), 2);
    return this.Height - ((dPriceDiffFromBottom * this.Factor) - this.Lowpt);
  }
  InitScale(MinPrice, MaxPrice, height, graphType, scaleType, dpi, symbolType, epsMultiplier, rpsMultiplier, t4QMultipliers, externalDataMultiplier) {
    var MaxScales;
    var i;
    var Mnodes;
    this.Height = height;
    this.Factor = 0;
    this.Lowpt = 0;
    this.mhLines = [];
    this.minPrice = MinPrice;
    this.maxPrice = MaxPrice;
    switch (graphType) {
      case GraphType.Intraday1Min:
        Mnodes = 8100;
        MaxScales = 90;
        break;
      case GraphType.Intraday5Min:
      case GraphType.Intraday10Min:
      case GraphType.Intraday15Min:
      case GraphType.Intraday30Min:
      case GraphType.Intraday60Min:
        Mnodes = 3600;
        MaxScales = 60;
        break;
      default:
        Mnodes = 900;
        MaxScales = 30;
        break;
    }

    var sp = [];
    var pt100 = 0;
    var pt50 = 0;
    var k = 0;
    var x = 10;
    var xl;
    var j;

    switch (graphType) {
      case GraphType.Intraday1Min:
        sp[0] = x;
        for (i = 1; i <= MaxScales; i++) {
          if (x < 20)
            x = x + 0.25;
          else
            if (x < 50)
              x = x + 1.5;
            else
              if (x < 100)
                x = x + 1.5;
          sp[i] = x;
        }
        for (i = 1; i < MaxScales; i++) {
          xl = Math.pow(10.0, (i - 5));
          for (j = 0; j <= MaxScales; j++) {
            PrScl0[k] = xl * sp[j];
            if (PrScl0[k] == 100)
              pt100 = k;
            if (PrScl0[k] == 50)
              pt50 = k;

            PrScl1[k] = this.log10(PrScl0[k]);
            PrScl2[k] = 1;
            k++;
          }
        }
        break;
      case GraphType.Intraday5Min:
      case GraphType.Intraday10Min:
      case GraphType.Intraday15Min:
      case GraphType.Intraday30Min:
      case GraphType.Intraday60Min:
        sp[0] = x;
        for (i = 1; i <= MaxScales; i++) {
          if (x < 20)
            x = x + 0.5;
          else
            if (x < 50)
              x = x + 1;
            else
              if (x < 100)
                x = x + 5;
          sp[i] = x;
        }
        for (i = 1; i < MaxScales; i++) {
          xl = Math.pow(10.0, (i - 5));
          for (j = 0; j <= MaxScales; j++) {
            PrScl0[k] = xl * sp[j];
            if (PrScl0[k] == 100)
              pt100 = k;
            if (PrScl0[k] == 50)
              pt50 = k;

            PrScl1[k] = this.log10(PrScl0[k]);
            PrScl2[k] = 1;
            k++;
          }
        }
        break;
      default:
        sp[0] = x;
        for (i = 1; i <= MaxScales; i++) {
          if (x < 20)
            x = x + 1;
          else
            if (x < 50)
              x = x + 2;
            else
              if (x < 100)
                x = x + 10;
          sp[i] = x;
        }
        for (i = 1; i < MaxScales; i++) {
          xl = Math.pow(10.0, (i - 5));
          for (j = 0; j <= MaxScales; j++) {
            PrScl0[k] = xl * sp[j];
            if (PrScl0[k] == 100)
              pt100 = k;
            if (PrScl0[k] == 50)
              pt50 = k;

            PrScl1[k] = this.log10(PrScl0[k]);
            PrScl2[k] = 1;
            k++;
          }
        }
        break;
    }

    if (scaleType == 0) {
      var iLogScalePadding = 0;

      var iStartPoint;
      for (iStartPoint = 0; iStartPoint < Mnodes && PrScl0[iStartPoint] < this.minPrice; iStartPoint++) {
      }
      iStartPoint -= iLogScalePadding;
      iStartPoint = Math.Max(iStartPoint, 0);

      var iEndPoint;
      for (iEndPoint = iStartPoint; iEndPoint < Mnodes && PrScl0[iEndPoint] < this.maxPrice; iEndPoint++) {
      }
      if (iEndPoint != Mnodes - 1)
        iEndPoint += iLogScalePadding;
      iEndPoint = Math.Min(iEndPoint, (Mnodes - 1));

      this.Factor = height / (PrScl1[iEndPoint] - PrScl1[iStartPoint]);
    }
    else {
      if (this.wonFixed) {
        this.Factor = dpi / (PrScl1[pt100] - PrScl1[pt50]);
      } else {
        switch (graphType) {
          case GraphType.Daily:
            this.Factor = rayAppConfigMgr.LogPriceScaleDailyFactor.value * (dpi / (PrScl1[pt100] - PrScl1[pt50]));
            break;
          case GraphType.Annual:
          case GraphType.Quarterly:
          case GraphType.Monthly:
            this.Factor = rayAppConfigMgr.LogPriceScaleMonthlyFactor.value * (dpi / (PrScl1[pt100] - PrScl1[pt50]));
            break;
          case GraphType.Intraday1Min:
            this.Factor = rayAppConfigMgr.LogPriceScaleIntraday1Factor.value * (dpi / (PrScl1[pt100] - PrScl1[pt50]));
            break;
          case GraphType.Intraday5Min:
          case GraphType.Intraday10Min:
            this.Factor = rayAppConfigMgr.LogPriceScaleIntraday510Factor.value * (dpi / (PrScl1[pt100] - PrScl1[pt50]));
            break;
          case GraphType.Intraday15Min:
          case GraphType.Intraday30Min:
          case GraphType.Intraday60Min:
            this.Factor = rayAppConfigMgr.LogPriceScaleIntraday153060Factor.value * (dpi / (PrScl1[pt100] - PrScl1[pt50]));
            break;
          default:
            this.Factor = rayAppConfigMgr.LogPriceScaleDefaultFactor.value * (dpi / (PrScl1[pt100] - PrScl1[pt50]));
            break;
        }
      }

      if (symbolType == SymbolType.INDEX ||
        symbolType == SymbolType.INTERNATIONALINDEX)
        this.Factor *= 2;

      var stockPriceSpan = Math.abs(this.ComputeY(this.maxPrice, height, this.minPrice) - this.ComputeY(this.minPrice, height, this.minPrice));

      var compressed = height * .8 < stockPriceSpan;

      if (compressed)
        this.Factor *= height * .8 / stockPriceSpan;

    }

    let eok = isNaN(epsMultiplier) ? 0 : 1;
    let rok = isNaN(rpsMultiplier) || rpsMultiplier < 0 ? 0 : 1;

    this.SetHorizontalGrid(epsMultiplier, rpsMultiplier, 1, eok, rok, t4QMultipliers, externalDataMultiplier, dpi);
  }

  SetLowpt(lowprc) {
    var I1 = 0;
    var Dlr = 0;
    var lowpt = Math.floor(lowprc * 1000);
    var dlr = Math.floor(Dlr * 1000);
    var i1 = Math.floor(I1 * 1000);

    if ((lowpt + dlr) < lowprc * 1000)
      lowpt += dlr;

    var tmp = Math.floor(lowpt / dlr) * dlr;

    if (tmp <= 0)
      tmp = -i1;

    if (tmp == lowpt)// && tmp != 0)
      lowpt = tmp - dlr;
    else
      if (lowpt < tmp)
        lowpt = tmp - dlr;
      else
        lowpt = tmp;

    return lowpt / 1000;
  }

  SetHipt(hiprc) {
    var Dlr = 0;
    var hipt = Math.floor(hiprc * 1000);// +MLPriceIncrement/ 6;
    var dlr = Math.floor(Dlr * 1000);

    hipt = Math.floor((hipt / dlr) + 1) * dlr;

    return hipt / 1000;
  }

  SetHorizontalGrid(epsMultiplier, rpsMultiplier, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi) {
    var minpr = this.minPrice;
    var maxpr = this.maxPrice;
    var Mnodes = PrScl1.length;
    var height = this.Height;

    var fMinpr = minpr < 1 ? 0 : this.log10(minpr);

    var i = 0;
    for (; i < Mnodes - 1 && PrScl1[i] < fMinpr; i++);

    var mLowpt = Math.max(i, 1);

    var mlLowpt = PrScl1[mLowpt] - fMinpr;
    this.Lowpt = (mlLowpt * this.Factor);

    // Move graph to the Center

    var mHipt = this.ComputeY(maxpr, height);
    var mMnpt = this.ComputeY(minpr, height);

    if (mHipt > 40) {
      var mid = (mMnpt + mHipt) / 2;
      var gmid = height / 2;
      var dif = gmid - mid;
      this.Lowpt -= Math.abs(dif);
    }
    else
      this.Lowpt = 0;

    if (pok == 1)
        this.SetLogGrid(epsMultiplier, rpsMultiplier, height, this.minPrice, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi);
  }

  SetLogGrid(epsMultiplier, rpsMultiplier, height, minPrc, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi) {
    var minPrice = this.ComputePrice(height - 10, height);
    var maxPrice = this.ComputePrice(10, height);

    if (height < 200) {
      minPrice = this.ComputePrice(height, height);
      maxPrice = this.ComputePrice(1, height);
    }

    var minPoint = 0;
    var maxPoint = 0;

    for (; minPoint < PrScl0.length && PrScl0[minPoint] < minPrice; minPoint++);
    for (; maxPoint < PrScl0.length && PrScl0[maxPoint] < maxPrice; maxPoint++);

    var pricePtr = minPoint;

    if (pricePtr >= PrScl0.length)
      return;

    var py = this.ComputeY(PrScl0[pricePtr], height);
    pricePtr++;

    var y;
    var eLine;

    if (pricePtr == maxPoint) {
      y = this.ComputeY(PrScl0[pricePtr], height);
      //      if (this.mhLines.ContainsKey(y)) {
      eLine = {
        Type: 2,
        Label: " ",
        eLabel: " ",
        rLabel: " ",
        YAxis: y
      };
      this.mhLines.push(eLine);
      //      }
    }
      let plabel = "";
      let pelabel = "";
      let prlabel = "";
      let ptlabel = "";
      let pt1label = "";
      let pt2label = "";
      let pt3label = "";
      let pextLabel = "";
      for (; pricePtr < maxPoint; pricePtr++) {
          if (PrScl2[pricePtr] != 1) {
              // No line at this point.
              continue;
          }
          y = this.ComputeY(PrScl0[pricePtr], height);
          let nodeWidth = dpi / 28;
          let dist = nodeWidth > 12 ? nodeWidth * 2 : 10;
          if (Math.abs(py - y) < dist || y < 25 || y + 10 > height)
              continue;

          // if (!mhLines.ContainsKey(y))
          {
              var price = " ";
              var eprice = " ";
              var rprice = " ";
              var t0price = " ";
              var t1price = " ";
              var t2price = " ";
              var t3price = " ";
              var exdprice = " ";

              if (pok == 1) {
                  price = PrScl0[pricePtr];
                  if (price < 6)
                      price = PrScl0[pricePtr].toFixed(1);
                  if (price < 0.6)
                      price = PrScl0[pricePtr].toFixed(2);
                  if (price < 0.06)
                      price = PrScl0[pricePtr].toFixed(3);
              }
              if (eok == 1) {
                  eprice = PrScl0[pricePtr] / epsMultiplier;
                  // if (eprice > 999999)
                  //   eprice = ExtremeDataValue.abbreviateValue(eprice);
                  // if (eprice > 4.9)
                  //   eprice = ExtremeDataValue.abbreviateFinancialValue(eprice);
                  if (eprice > 0.1)
                      eprice = (PrScl0[pricePtr] / epsMultiplier).toFixed(2);
                  else
                      eprice = (PrScl0[pricePtr] / epsMultiplier).toFixed(3);
              }
              if (rok == 1) {
                  rprice = PrScl0[pricePtr] / rpsMultiplier;
                  // if (rprice > 999999)
                  //   rprice = ExtremeDataValue.abbreviateValue(rprice);
                  // if (rprice > 4.9)
                  //   rprice = ExtremeDataValue.abbreviateFinancialValue(rprice);
                  if (rprice > 0.1)
                      rprice = (PrScl0[pricePtr] / rpsMultiplier).toFixed(2);
                  else
                      rprice = (PrScl0[pricePtr] / rpsMultiplier).toFixed(3);
              }
              if (t4QMultipliers && t4QMultipliers[0]) {
                  t0price = PrScl0[pricePtr] / t4QMultipliers[0];
                  if (t0price > 0.1)
                      t0price = (PrScl0[pricePtr] / t4QMultipliers[0]).toFixed(2);
                  else
                      t0price = (PrScl0[pricePtr] / t4QMultipliers[0]).toFixed(3);
              }
              if (t4QMultipliers && t4QMultipliers[1]) {
                  t1price = PrScl0[pricePtr] / t4QMultipliers[1];
                  if (t1price > 0.1)
                      t1price = (PrScl0[pricePtr] / t4QMultipliers[1]).toFixed(2);
                  else
                      t1price = (PrScl0[pricePtr] / t4QMultipliers[1]).toFixed(3);
              }
              if (t4QMultipliers && t4QMultipliers[2]) {
                  t2price = PrScl0[pricePtr] / t4QMultipliers[2];
                  if (t2price > 0.1)
                      t2price = (PrScl0[pricePtr] / t4QMultipliers[2]).toFixed(2);
                  else
                      t2price = (PrScl0[pricePtr] / t4QMultipliers[2]).toFixed(3);
              }
              if (t4QMultipliers && t4QMultipliers[3]) {
                  t3price = PrScl0[pricePtr] / t4QMultipliers[3];
                  if (t3price > 0.1)
                      t3price = (PrScl0[pricePtr] / t4QMultipliers[3]).toFixed(2);
                  else
                      t3price = (PrScl0[pricePtr] / t4QMultipliers[3]).toFixed(3);
              }
              if (externalDataMultiplier) {
                exdprice = PrScl0[pricePtr]  / externalDataMultiplier;
                if (exdprice > 0.1)
                  exdprice = (PrScl0[pricePtr]  / externalDataMultiplier).toFixed(2);
                else
                  exdprice = (PrScl0[pricePtr]  / externalDataMultiplier).toFixed(3);
              }
              let label = price > 0 ? ExtremeDataValue.showChartPrices(price) : "";
              let elabel = ExtremeDataValue.abbreviateFinancialValue(eprice, 1);
              let rlabel = ExtremeDataValue.abbreviateFinancialValue(rprice, 1);
              let tlabel = ExtremeDataValue.abbreviateFinancialValue(t0price, 1);
              let t1label = ExtremeDataValue.abbreviateFinancialValue(t1price, 1);
              let t2label = ExtremeDataValue.abbreviateFinancialValue(t2price, 1);
              let t3label = ExtremeDataValue.abbreviateFinancialValue(t3price, 1);

              let exdLabel = ExtremeDataValue.abbreviateFinancialValue(Math.abs(exdprice), 1);
              eLine =
                  {
                      Type: 2,
                      Label: label !== plabel ? label : "",
                      eLabel: elabel !== pelabel ? elabel : "",
                      rLabel: rlabel !== prlabel ? rlabel : "",
                      tLabel: tlabel !== ptlabel ? tlabel : "",
                      t1Label: t1label !== pt1label ? t1label : "",
                      t2Label: t2label !== pt2label ? t2label : "",
                      t3Label: t3label !== pt3label ? t3label : "",
                      exdLabel: exdLabel !== pextLabel ? exdLabel : "" ,
                      YAxis: y
                  };
              plabel = label;
              pelabel = elabel;
              prlabel = rlabel;
              ptlabel = tlabel;
              pt1label = t1label;
              pt2label = t2label;
              pt3label = t3label;
              pextLabel = exdLabel;
              this.mhLines.push(eLine);
          }
          py = y;
      }
  }
}
export default FixedLogScale;
