import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';

class GetOneilTechRequest extends BaseServiceRequestIIS {
  constructor(symbol, graphtype, enddate, startdate, Tech, EPS, PTOEOP, PTOSOP, TechReq) {
    super();
    super.addRequestUri("GetTechInds");
    super.addPathParameter("symbol", symbol);
    super.addPathParameter("graphType", graphtype);
    super.addPathParameter("endDate", enddate);
    super.addPathParameter("startDate", startdate);
    super.addPathParameter("Tech", Tech);
    super.addPathParameter("EPS", EPS);
    super.addPathParameter("PTOEOP", PTOEOP);
    super.addPathParameter("PTOSOP", PTOSOP);
    super.addPathParameter("TechReq", TechReq);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return undefined;
  }
}
export default GetOneilTechRequest;