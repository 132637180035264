
import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetFundamentalReportRequest extends BaseServiceRequest {
  constructor(instrumentid, international, reporttype, sort, filter, template, periodicity) {
    super();
    super.addRequestUri("report.pb");
    super.addRequestParameter("instrumentid", instrumentid.toString());
    super.addRequestParameter("type", "1");
    super.addRequestParameter("intl", international.toString());
    super.addRequestParameter("periodicity", periodicity.toString());
    super.addRequestParameter("report", reporttype.toString());
    super.addRequestParameter("filter", filter.toString());
    super.addRequestParameter("sort", "1");
    super.addRequestParameter("template", template.toString());
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.FUNDAMENTAL;
  }

  getMessageName() {
    return "FundamentalReportResponse";
  }
}

export default GetFundamentalReportRequest;
