import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetRSSSourcesRequest extends BaseServiceRequest {
    constructor() {
        super();
        super.addRequestUri("newssources.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.NEWS;
    }

    getMessageName() {
        return "NewsRSSSourceResponse";
    }
}


export default GetRSSSourcesRequest;
