import React from 'react';
import TreeView from "../../../../RayCustomControls/ListExplorer/Treeview.js";
import _ from 'underscore';
import ListActions from "ListActions";
import StringUtil from "StringUtil";
import ConsoleStore from "ConsoleStore";
import ListExplorerStore from "ListExplorerStore";
import AlertStore from "AlertStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import { AlertConstants } from "AlertConstants";
import BaseServiceApi from 'BaseServiceApi';
import BrowserUtil from "BrowserUtil";
import ONeilViewStore from "ONeilViewStore";
import { Shortcuts } from 'react-shortcuts';
import ContextMenuConstants from "ContextMenuConstants";
import ListExplorerUtil from "ListExplorerUtil";
import LocalizationStore from 'LocalizationStore';
import TreeNode from "./TreeNode.jsx";
import GridStore from "../../../../Stores/NavModules/NavList/Grid/GridStore";
import GridActions from "../../../../Actions/GridActions";
import { dispatch } from '../../../../Redux/dispatch.js';
import { processList, updateNupIsNonImported } from '../../../../Actions/NupViewActions.js';
import { GridConstants } from "GridConstants";

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let ListType = BaseServiceApi.rayData["ListType"];
let ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
let ListAlertStatusType = BaseServiceApi.rayData["ListAlertStatusType"];

let stop = true;
let mousePos = {
  cX: 0,
  cY: 0
};

class ListExplorer extends React.Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickFromTempList = this.handleClickFromTempList.bind(this);
    this.onContextMenuClick = this.onContextMenuClick.bind(this);
    this.handleFolderClick = this.handleFolderClick.bind(this);
    this.listExplorerStateChange = this.listExplorerStateChange.bind(this);
    this.handlePasteEvent = this.handlePasteEvent.bind(this);
    this.handleShortcuts = this.handleShortcuts.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleFolderDragStart = this.handleFolderDragStart.bind(this);
    this.handleFolderDrop = this.handleFolderDrop.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleDocumentDragover = this.handleDocumentDragover.bind(this);
    this.handleDocumentDrag = this.handleDocumentDrag.bind(this);
    this.handleDocumentDragEnd = this.handleDocumentDragEnd.bind(this);
    this.handleListDrop = this.handleListDrop.bind(this);
    this.explorerMounted = false;
    this.setGetListExplorerAction = false;
    this.state = { addNewItem: this.props.addNewItem };
    this.nodeId = null;
    this.elementDragged = null;
    this.elemIndex = 0;
    this.dataSource = null;
    this.renameNode = null;
    this.onListClick = false;
    this.currentAction = null;
    this.previousSelectedNodeId = null;
    this.previousSelectedParentNodeId = null;
    this.previousSelectedFolderNodeId = null;
    this.previousElementOnOver = null;
    this.isShiftKey = false;
    this.isCtrlKey = false;
    this.dragDiv = null;
    ListExplorerStore.selectedFolder = {};
    this.childObj = null;
    this.dragNodeId=null;
    this.movingParentNodeToChild=null;
  }

  handlePasteEvent(e) {
    if (document.activeElement.tagName.toString().toLowerCase() != 'input') {
      if (BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape" ) {
        window.SafariClipboard = window.clipboardData.getData("Text");
      } else {
        window.SafariClipboard = e.clipboardData.getData('text/plain');
      }
      // if (BrowserUtil.getBrowserName() == 'Safari') {
      //   this.handleShortcuts('PASTE', e, e.clipboardData.getData('text/plain'));
      // }
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('paste', this.handlePasteEvent, false);
    ListExplorerStore.addChangeListener(this.listExplorerStateChange);

    AlertStore.addChangeListener(this.alertStoreStateChangeSaveAlertData, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
    AlertStore.addChangeListener(this.alertStoreStateChangeDeleteListAlert, AlertConstants.ActionTypes.DELETE_LIST_ALERT);
    AlertStore.addChangeListener(this.alertStoreStateChangeNewNotificationReceived, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
  }

  componentWillUnmount() {
    ListExplorerStore.removeChangeListener(this.listExplorerStateChange);

    AlertStore.removeChangeListener(this.alertStoreStateChangeSaveAlertData, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
    AlertStore.removeChangeListener(this.alertStoreStateChangeDeleteListAlert, AlertConstants.ActionTypes.DELETE_LIST_ALERT);
    AlertStore.removeChangeListener(this.alertStoreStateChangeNewNotificationReceived, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);

    document.removeEventListener('dragover', this.handleDocumentDragover, false);
    document.removeEventListener('drag', this.handleDocumentDrag, false);
    document.removeEventListener('dragend', this.handleDocumentDragEnd, false);
    ListExplorerStore.explorerView = null;
    if (BrowserUtil.getBrowserName() == 'Safari') {
      window.removeEventListener('paste', this.handlePasteEvent, false);
    }
  }

  componentDidMount() {
    ListExplorerStore.explorerView = document.querySelector("div[class='listExplorerClass']");
    let currentDiv = document.querySelector("#listExplorer");
    document.addEventListener('dragover', this.handleDocumentDragover, false);
    document.addEventListener('drag', this.handleDocumentDrag, false);
    document.addEventListener('dragend', this.handleDocumentDragEnd, false);
    this.setDomObject(currentDiv);

    if (BrowserUtil.getBrowserName() == 'Safari') {
      window.addEventListener('paste', this.handlePasteEvent, false);
    }

  }

  setDomObject = (currentDiv) => {
    const { top, right, bottom, left, width, height, x, y } = currentDiv.getBoundingClientRect();
    this.bRect = { top, right, bottom, left, width, height, x, y }
  }


  componentDidUpdate() {
    if (StringUtil.isEmpty(ListExplorerStore.explorerView))
      ListExplorerStore.explorerView = document.querySelector("div[class='listExplorerClass']");

    if (!this.isShiftKey && ListExplorerStore.selectedNodeIds == 0) {
      this.previousSelectedNodeId = this.props.nodeId;
      this.isShiftKey = false;
    }
    else {
      if ((!this.isCtrlKey && !this.isShiftKey) && ListExplorerStore.selectedNodeIds.length > 0) {
        if (!StringUtil.isEmpty(this.currentAction)) {
          if (this.currentAction != ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST && this.currentAction != ListExplorerConstants.ActionTypes.COPY_PASTE && this.currentAction != ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE) {
            ListExplorerStore.activeNode = _.find(ListExplorerStore.selectedNodeIds, (item) => {
              if (!StringUtil.isEmpty(item)) return item.nodeId.low == +this.props.nodeId;
            });
          }
        }
      }
    }

    if (ListExplorerStore.getCurrentAction() === ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER && this.explorerMounted && this.setGetListExplorerAction == false) {
      this.setGetListExplorerAction = true;
      this.currentAction = ListExplorerStore.getCurrentAction();
    }

    switch (this.currentAction) {
      case ListExplorerConstants.ActionTypes.SHOW_ORIGINAL:
        this.highLightNode(this.props.nodeId, this.previousSelectedNodeId);
        this.previousSelectedParentNodeId = ListExplorerStore.activeNode.parentNodeId;
        this.setListExplorerScrollPosition(this.props.nodeId);
        this.currentAction = null;
        BrowserUtil.enableEvents();
        break;

      case ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER:
        ListExplorerStore.activeNode != undefined && !ListExplorerStore.activeNode.symbol && this.expandAndFindParentNode(Number(this.props.nodeId));
        this.setListExplorerScrollPosition(this.props.nodeId);
        this.currentAction = null;
        if (GridStore.getGridBodyAction() && GridStore.getGridBodyAction() !== GridConstants.ActionTypes.REMOVE_GRID_ROWS) {
          BrowserUtil.enableEvents();
        }
        break;

      case ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE:
      case ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES:
        if (!StringUtil.isEmpty(ListExplorerStore.nextAvailableFolder)) {
          this.highLigtFolder(null, ListExplorerStore.nextAvailableFolder.nodeId.low);
          ListExplorerStore.nextAvailableFolder = null;
        }
        if (this.currentAction != ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES) {
          this.highLightNode(this.props.nodeId, this.props.nodeId, true);
        }
        this.currentAction = null;
        BrowserUtil.enableEvents();
        break;

      case ListExplorerConstants.ActionTypes.RESTORE_TREE_NODE:
        const activeSymbol = ListExplorerStore.activeSybol.symbol;
        if (activeSymbol)
          this.setScrollPositionBySymbol(activeSymbol);
        this.highLightNode(this.props.nodeId, ListExplorerStore.restoreNodeId, true);
        this.currentAction = null;
        BrowserUtil.enableEvents();
        break;

      case ListExplorerConstants.ActionTypes.MOVE_LIST_ITEMS:
        if (ListExplorerStore.selectedNodeIds.length > 0)
          this.highLightMultipleNode(ListExplorerStore.selectedNodeIds, []);
        BrowserUtil.enableEvents();
        break;

      case ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES:
        if (!StringUtil.isEmpty(ListExplorerStore.duplicateNode))
          this.setListExplorerScrollPosition(ListExplorerStore.duplicateNode.nodeId);
        this.currentAction = null;
        BrowserUtil.enableEvents();
        break;

      case AlertConstants.ActionTypes.SAVE_ALERT_DATA:
      case AlertConstants.ActionTypes.DELETE_LIST_ALERT:
        this.currentAction = null;

      case ListExplorerConstants.ActionTypes.COPY:
      case ListExplorerConstants.ActionTypes.COPY_PASTE:
      case ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST:
      case ListExplorerConstants.ActionTypes.GET_CREATE_TREE_NODE:
      case ListExplorerConstants.ActionTypes.GET_UPDATE_LIST_NODE:
      case ListExplorerConstants.ActionTypes.REMOVE_ITEMS_FROM_USER_LIST:
      case ListExplorerConstants.ActionTypes.GET_UPDATE_DESCRIPTION:
      case ListExplorerConstants.ActionTypes.OPEN_ALERT:
      case ListExplorerConstants.ActionTypes.SAVE_SCREENED_RESULTS:
        this.currentAction = null;
        BrowserUtil.enableEvents();
        break;
      default:
        break;
    }
  }

  handleDocumentDragover(e) {
    mousePos.cX = e.clientX;
    mousePos.cY = e.clientY;
    e.stopPropagation();
  }

  handleDocumentDrag(e) {
    if (!StringUtil.isEmpty(this.bRect)) {
      if (mousePos.cX != 0 && mousePos.cX < this.bRect.right)
        this.handleDragMove(e);
    }
    e.stopPropagation();
  }

  handleDocumentDragEnd(e) {
    stop = true;
    this.setBorderOnDrag(null);
    e.stopPropagation();
  }

  alertStoreStateChangeSaveAlertData = () => {
    this.setState({});
  }

  alertStoreStateChangeDeleteListAlert = () => {
    this.setState({});
  }

  alertStoreStateChangeNewNotificationReceived = () => {
    if (AlertStore.renderListExplorer) {
      AlertStore.renderListExplorer = false;
      this.setState({});
    }
  }

  listExplorerStateChange() {
    this.currentAction = ListExplorerStore.getCurrentAction();
    if (this.currentAction == ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE) {
      if (ListExplorerStore.selectedNodeIds.length > 0) {
        this.highLightMultipleNode([], ListExplorerStore.selectedNodeIds);
        ListExplorerStore.selectedNodeIds = [];
      }
    }
    this.isShiftKey = false;
    this.isCtrlKey = false;
  }

  //Find active node from List Explorer
  getActiveNode(symbol) {
    const listExplorer = document.querySelector("div[class='listExplorerClass']");
    if (!StringUtil.isEmpty(listExplorer)) {
      let activeSymbol = "a[data-symbol='" + symbol + "']";
      let elm = listExplorer.querySelector(activeSymbol);

      if (!StringUtil.isEmpty(elm))
        return JSON.parse(elm.getAttribute('data-selectednode'));
    }
  }

  expandAndFindParentNode(nodeId) {
    if (nodeId === -1) {
      ListExplorerStore.activeNode = ListExplorerStore.getOneilOwnershipActiveNode(nodeId);
      return;
    }
    const nodeList = [];
    ListExplorerStore.expandAndFindParentNode(nodeId, nodeList);
    ListExplorerStore.activeNode = nodeList[0];
  }


  onContextMenuClick(e) {
    let nodeId = null;
    let symbol = null;
    let self = this;
    let actionType = null;
    this.isShiftKey = false;
    if (e !== ContextMenuConstants.DELETE && e !== ContextMenuConstants.REMOVE_FROM_FAVORITES && e !== ContextMenuConstants.RENAME) {
      actionType = e.target && e.target.getAttribute('data-categorytype');

      if (actionType == ListCategoryType.TEMPORARYLIST_ListCategory) {
        symbol = e.target && e.target.getAttribute('data-symbol');
      }
      else {
        nodeId = parseInt(e.target.getAttribute('data-node-id'));

        if (isNaN(nodeId))
          nodeId = e.target.getAttribute('id');
      }
    }
    else {
      if (!StringUtil.isEmpty(ListExplorerStore.selectedNodeIds) && ListExplorerStore.selectedNodeIds.length > 0) {
        let elm = null;
        ListExplorerStore.selectedNodeIds.map((item) => {
          elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + item.nodeId.low + "']");
          if (elm) elm.className = self.getChildHoverClass();
        });
      }
      //On Delete key press from key board
      let isFolderSelected = !StringUtil.isEmpty(ListExplorerStore.selectedFolder) ? true : false;
      ListExplorerStore.contextNode = isFolderSelected ? ListExplorerStore.selectedFolder : ListExplorerStore.activeNode;

      ListExplorerStore.contextNode.action = e;
      return this.props.onExplorerContextMenuClick(e);
    }


    //On Right Click
    if (!StringUtil.isEmpty(ListExplorerStore.activeNode) && ListExplorerStore.activeNode.nodeId == nodeId) {
      ListExplorerStore.contextNode = ListExplorerStore.activeNode;
    }
    else {
      if (symbol !== null)
        ListExplorerStore.contextNode = this.getActiveNode(symbol);
      else
        ListExplorerStore.contextNode = ListExplorerStore.getActiveNode(nodeId);
    }

    //Remove multiple selection if user right click outside selected lists and highlight top most list
    if (!StringUtil.isEmpty(ListExplorerStore.selectedNodeIds) && ListExplorerStore.selectedNodeIds.length > 0) {
      if (actionType == ListCategoryType.TEMPORARYLIST_ListCategory) {
        let isExists = _.find(ListExplorerStore.selectedNodeIds, (item) => item.symbol === symbol);
        if (StringUtil.isEmpty(isExists)) {
          this.highLightNode(ListExplorerStore.selectedNodeIds[0].symbol, null);
          ListExplorerStore.selectedNodeIds = [];
        }
      }
      else {
        let isExists = _.find(ListExplorerStore.selectedNodeIds, (item) => item.nodeId.low === parseInt(nodeId));
        if (StringUtil.isEmpty(isExists)) {
          this.highLightNode(ListExplorerStore.selectedNodeIds[0].nodeId.low, null);
          ListExplorerStore.selectedNodeIds = [];
        }
      }
    }

    //Remove folder selection if user right clicks on list
    if (!StringUtil.isEmpty(ListExplorerStore.selectedFolder) && !StringUtil.isEmpty(ListExplorerStore.contextNode)) {
      if (ListExplorerStore.contextNode.listType == ListType.LIST_List && !StringUtil.isEmpty(ListExplorerStore.selectedFolder)) {
        this.previousSelectedFolderNodeId = ListExplorerStore.selectedFolder.nodeId;
        this.highLigtFolder(null, this.previousSelectedFolderNodeId);
        ListExplorerStore.selectedFolder = null;
      }
    }

    return this.props.onExplorerContextMenuClick(e);
  }

  handleFolderClick(e) {
    if (this.props.renameNode)
      return;
    //If selection is folder Collapse/Expand the folder
    this.handleFolderSelection(e);

    //If selection is list/Node
    this.handleNodeSelection(e);
  }

  handleFolderSelection(e) {
    let rootNodeObj = {};
    let nodeId = null;
    ListExplorerStore.contextNode = null;

    nodeId = e.target.getAttribute('data-node-id');
    ListExplorerStore.isMenuClicked = true;

    if (StringUtil.isEmpty(nodeId))
      nodeId = e.target.getAttribute('id');

    rootNodeObj = ListExplorerStore.getActiveNode(nodeId);

    if (!StringUtil.isEmpty(rootNodeObj) && rootNodeObj.listType !== ListType.LIST_List) {
      ListExplorerStore.ExpandItemLevel(rootNodeObj.categoryType, nodeId);

      if (rootNodeObj.listType == ListType.CATEGORY_List) {
        return this.props.updateScrollBar();
      }

      if (rootNodeObj.listType == ListType.FOLDER_List && !this.onListClick && !StringUtil.isEmpty(rootNodeObj)) {
        if (!StringUtil.isEmpty(ListExplorerStore.selectedFolder))
          this.previousSelectedFolderNodeId = ListExplorerStore.selectedFolder.nodeId;
        ListExplorerStore.selectedFolder = null;

        ListExplorerStore.selectedFolder = rootNodeObj;
        this.highLigtFolder(ListExplorerStore.selectedFolder.nodeId, this.previousSelectedFolderNodeId);

        ListExplorerStore.selectedNodeIds = [];
        return this.props.handleFolderClick(e);
      }
      return;
    }
  }

  handleNodeSelection(e) {
    if (!StringUtil.isEmpty(ListExplorerStore.activeNode)) {
      let nodeId = ListExplorerStore.activeNode.nodeId;
      let categoryType = parseInt(ListExplorerStore.activeNode.categoryType);
      let parentNodeId = ListExplorerStore.activeNode.parentNodeId;
      let rootParentNodeId = parseInt(ListExplorerStore.activeNode.rootParentNodeId);
      let listType = parseInt(ListExplorerStore.activeNode.listType);
      const activeSymbol = ListExplorerStore.activeSybol.symbol;
      const symbol = ListExplorerStore.activeNode.symbol;

      this.isShiftKey = e.shiftKey;
      this.isCtrlKey = e.ctrlKey || e.metaKey;

      if ((e.shiftKey || (e.ctrlKey || e.metaKey)) && this.onListClick && listType !== ListType.CATEGORY_List && (categoryType !== ListCategoryType.FAVORITE_ListCategory || (e.ctrlKey || e.metaKey))) {
        let previousSelectedNodeIdList = [];
        previousSelectedNodeIdList = ListExplorerStore.selectedNodeIds;

        if (ListExplorerStore.selectedNodeIds.length > 0 && ListExplorerStore.selectedNodeIds[0].parentNodeId && ListExplorerStore.selectedNodeIds[0].parentNodeId.low !== parentNodeId && categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
          return;
        }
        else if (!StringUtil.isEmpty(this.previousSelectedParentNodeId) && parseInt(this.previousSelectedParentNodeId) !== parentNodeId && categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
          return;
        }
        else {
          if (e.shiftKey) {
            //Get selectedNodeIds on shift key press and highlight the nodeIds
            ListExplorerStore.selectedNodeIds = [];
            ListExplorerStore.getSelectedNodeIds(categoryType, rootParentNodeId, parentNodeId, nodeId, this.props.nodeId, true, symbol);
            this.highLightMultipleNode(ListExplorerStore.selectedNodeIds, previousSelectedNodeIdList);
          }
          else if ((e.ctrlKey || e.metaKey) && categoryType !== ListCategoryType.FAVORITE_ListCategory) {
            if (activeSymbol && !symbol && categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory)
              return;
            //Get selectedNodeIds on ctrlKey press and highlight the nodeIds
            if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
              if (ListExplorerStore.selectedNodeIds == 0) {
                ListExplorerStore.getSelectedNodeIds(categoryType, rootParentNodeId, parentNodeId, this.props.nodeId, this.props.nodeId, false, activeSymbol);
              }
              ListExplorerStore.getSelectedNodeIds(categoryType, rootParentNodeId, parentNodeId, nodeId, this.props.nodeId, false, symbol);
            }
            else {
              if (ListExplorerStore.selectedNodeIds == 0) {
                ListExplorerStore.getSelectedNodeIds(categoryType, rootParentNodeId, parentNodeId, this.props.nodeId, this.props.nodeId, false);
              }
              ListExplorerStore.getSelectedNodeIds(categoryType, rootParentNodeId, parentNodeId, nodeId, this.props.nodeId, false);
            }
            let previousSelectedNodeIdList = [];
            if (!StringUtil.isEmpty(ListExplorerStore.nodeIdTodeSelect))
              previousSelectedNodeIdList.push(ListExplorerStore.nodeIdTodeSelect);

            this.highLightMultipleNode(ListExplorerStore.selectedNodeIds, previousSelectedNodeIdList);
            ListExplorerStore.nodeIdTodeSelect = null;
          }
          else {
            return;
          }
        }
      }
      else {
        if (listType == ListType.LIST_List && listType !== ListType.CATEGORY_List)
          this.previousSelectedParentNodeId = parentNodeId;
        if (this.props.renameNode == false && StringUtil.isEmpty(this.props.addNewItem)) {
          if (!StringUtil.isEmpty(rootParentNodeId && !StringUtil.isEmpty(parentNodeId))) {
            if (listType !== ListType.LIST_List && this.onListClick == false) {
              ListExplorerStore.ExpandItemLevel(categoryType, nodeId);
            }
          }
        }
        if (listType !== ListType.CATEGORY_List && listType !== ListType.FOLDER_List) {
          if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
            //If selection is category
            const symbol = e.target.getAttribute('data-symbol');
            if (!StringUtil.isEmpty(symbol)) {
              this.highLightNode(nodeId, this.previousSelectedNodeId);
            }
          }
          else if (categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
            //If selection is category
            const nodeId = e.target.getAttribute('data-node-id');
            if (!StringUtil.isEmpty(nodeId)) {
              this.highLightNode(nodeId, this.previousSelectedNodeId);
            }
          }
          else {
            //If selection is category
            const nodeId = e.target.getAttribute('data-node-id');
            if (!StringUtil.isEmpty(nodeId)) {
              if (!ListExplorerStore.isCategoryList(nodeId))
                this.highLightNode(nodeId, this.previousSelectedNodeId);
            }
          }
        }
      }
      if (listType !== ListType.CATEGORY_List && listType !== ListType.FOLDER_List) {
        this.onListClick = false;
        //Setting Visibilty/Disabling event of COPY_PASTE action
        ListExplorerStore.isEnableCopyPasteAction = (ListExplorerStore.selectedNodeIds.length > 0 && (this.isShiftKey || this.isCtrlKey)) ? false : true;
        return this.props.handleFolderClick(e);
      }
    }
  }

  handleClick(e) {
    
    if (ListExplorerStore.isRename)
      return;

    //Get and set selected node details to list explorer store
    ListExplorerStore.activeNode = ListExplorerStore.getActiveNode(e.target.getAttribute('data-node-id'));
    dispatch(updateNupIsNonImported(false))
    //Log Usage
    let value = (ListExplorerStore.activeNode.categoryType == ListCategoryType.USER_ListCategory || ListExplorerStore.activeNode.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) ? 'MY LISTS' : 'non - MY LISTS';
    ConsoleStore.trackUsage('ListIsSelected', value, [ListExplorerStore.activeNode.nodeId.toString()]);

    if (!StringUtil.isEmpty(ListExplorerStore.selectedFolder)) {
      this.previousSelectedFolderNodeId = ListExplorerStore.selectedFolder.nodeId;
      this.highLigtFolder(null, this.previousSelectedFolderNodeId);
      ListExplorerStore.selectedFolder = null;
    }

    if (!StringUtil.isEmpty(ListExplorerStore.activeNode)) {
      let parentNodeId = ListExplorerStore.activeNode.parentNodeId;
      let categoryType = parseInt(ListExplorerStore.activeNode && ListExplorerStore.activeNode.categoryType);
      this.onListClick = true;

      if (!e.shiftKey) {
        if (e.ctrlKey || e.metaKey) {
          if ((!StringUtil.isEmpty(this.previousSelectedParentNodeId) && parseInt(this.previousSelectedParentNodeId) !== parentNodeId) || categoryType == ListCategoryType.FAVORITE_ListCategory)
            return;
        }
        else {
          return this.props.handleClick(e.target);
        }
      }
    }
  }

  handleClickFromTempList(e) {
    //Get and set selected node details to list explorer store
    const symbol = e.target.getAttribute('data-symbol');
    if(symbol){
      ListExplorerStore.activeNode = ListExplorerStore.getActiveNodeUsingSymbol(symbol);
    } else {
      const selectNodeId = e.target.getAttribute('data-node-id');
      ListExplorerStore.activeNode = ListExplorerStore.getActiveNode(selectNodeId);
    }
    if(ListExplorerStore.activeNode.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory){
      dispatch(updateNupIsNonImported(true));
    }
    else{
      dispatch(updateNupIsNonImported(false));
    }
    //Log Usage
    let value = (ListExplorerStore.activeNode && ListExplorerStore.activeNode.categoryType == ListCategoryType.USER_ListCategory) ? 'MY LISTS' : 'non - MY LISTS';
    ConsoleStore.trackUsage('ListIsSelected', value, [ListExplorerStore.activeNode.nodeId.toString()]);

    if (!StringUtil.isEmpty(ListExplorerStore.selectedFolder)) {
      this.previousSelectedFolderNodeId = ListExplorerStore.selectedFolder.nodeId;
      this.highLigtFolder(null, this.previousSelectedFolderNodeId);
      ListExplorerStore.selectedFolder = null;
    }

    if (!StringUtil.isEmpty(ListExplorerStore.activeNode)) {
      let parentNodeId = ListExplorerStore.activeNode.parentNodeId;
      const categoryType = ListExplorerStore.activeNode && parseInt(ListExplorerStore.activeNode.categoryType);
      this.onListClick = true;

      if (!e.shiftKey) {
        if (e.ctrlKey || e.metaKey) {
          if ((!StringUtil.isEmpty(this.previousSelectedParentNodeId) && parseInt(this.previousSelectedParentNodeId) !== parentNodeId) || categoryType == ListCategoryType.FAVORITE_ListCategory)
            return;
        }
        else {
          return this.props.handleClick(e.target);
        }
      }
    }
  }

  highLightNode(nodeId, previousSelectedNodeId, isDelete = false) {
    let self = this;
    let elm = null;

    if (!StringUtil.isEmpty(nodeId))
      nodeId = parseInt(nodeId);
    if (!StringUtil.isEmpty(previousSelectedNodeId))
      previousSelectedNodeId = parseInt(previousSelectedNodeId);

    if (nodeId == -1) {
      if (!StringUtil.isEmpty(previousSelectedNodeId)) {
        elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + previousSelectedNodeId + "']");
        if (!StringUtil.isEmpty(elm)) elm.className = self.getChildHoverClass();
      }
      return;
    }
    if (!this.isShiftKey) {
      if (!StringUtil.isEmpty(ListExplorerStore.selectedNodeIds) && ListExplorerStore.selectedNodeIds.length > 0) {
        ListExplorerStore.selectedNodeIds.map((item) => {
          if (item.symbol) {
            if (item.symbol !== ListExplorerStore.activeSybol.symbol) {
              elm = ListExplorerUtil.getElementByQuery("div[data-symbol='" + item.symbol + "']");
              if (elm) elm.className = self.getChildHoverClass();
            }
          }
          else {
            if (item.nodeId.low !== previousSelectedNodeId) {
              elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + item.nodeId.low + "']");
              if (elm) elm.className = self.getChildHoverClass();
            }
          }
        });

        ListExplorerStore.selectedNodeIds = [];
      }
      //PANWEB-934
      if (ListExplorerStore.activeNode.symbol !== undefined) {
        elm = ListExplorerUtil.getElementByQuery("div[data-symbol='" + ListExplorerStore.activeNode.symbol + "']");
        if (elm)
          elm.className = self.getChildHoverActiveClass();
        return;
      }

      else {
        elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + nodeId + "']");
      }
      if (!StringUtil.isEmpty(elm)) {
        if (nodeId !== previousSelectedNodeId)
          this.previousSelectedParentNodeId = ListExplorerStore.activeNode.parentNodeId;
        if (elm)
          elm.className = self.getChildHoverActiveClass();

        if (isDelete) {
          if (!StringUtil.isEmpty(elm.previousSibling) && elm.previousSibling.getAttribute('class') == 'child-hover active') {
            let prevElm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + elm.previousSibling.getAttribute('data-node-id') + "']");
            if (!StringUtil.isEmpty(prevElm)) prevElm.className = self.getChildHoverClass();
          }
          if (!StringUtil.isEmpty(elm.nextSibling) && elm.nextSibling.getAttribute('class') == 'child-hover active') {
            let nextElm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + elm.nextSibling.getAttribute('data-node-id') + "']");
            if (!StringUtil.isEmpty(nextElm)) nextElm.className = self.getChildHoverClass();
          }
        }

        //Setting selection for current nodeId and Removing selection for previous Selected NodeId
        if (!StringUtil.isEmpty(elm) && !StringUtil.isEmpty(previousSelectedNodeId)) {
          if (previousSelectedNodeId !== nodeId) {
            let elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + previousSelectedNodeId + "']");
            if (!StringUtil.isEmpty(elm)) elm.className = self.getChildHoverClass();
          }
        }
      }
    }
  }

  highLightMultipleNode(selectedNodeIdList, previousSelectedNodeIdList) {
    let self = this;
    let elm = null;
    let categoryType = null;

    if (previousSelectedNodeIdList.length > 0) {
      categoryType = previousSelectedNodeIdList[0].categoryType;
      if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
        previousSelectedNodeIdList = previousSelectedNodeIdList.filter((item) => !selectedNodeIdList.includes(item));
        previousSelectedNodeIdList.map((item) => {
          elm = ListExplorerUtil.getElementByQuery("div[data-symbol='" + item.symbol + "']");
          if (elm) elm.className = self.getChildHoverClass();
        });
      }
      else {
        previousSelectedNodeIdList = previousSelectedNodeIdList.filter((item) => !selectedNodeIdList.includes(item));
        previousSelectedNodeIdList.map((item) => {
          elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + item.nodeId.low + "']");
          if (elm) elm.className = self.getChildHoverClass();
        });
      }
    }

    if (selectedNodeIdList.length > 0) {
      categoryType = selectedNodeIdList[0].categoryType;
      const activeSymbol = ListExplorerStore.activeSybol.symbol;

      if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
        selectedNodeIdList.map((item) => {
          let className = null;
          if (item.symbol === activeSymbol)
            className = self.getChildHoverActiveClass();
          else
            className = "child-hover-selected active " + self.props.fontSizeClass + ' child-item';

          elm = ListExplorerUtil.getElementByQuery("div[data-symbol='" + item.symbol + "']");
          if (elm) {
            elm.className = className;
          }
        });
      }
      else {
        selectedNodeIdList.map((item) => {
          let className = null;
          if (item.nodeId.low == parseInt(self.previousSelectedNodeId))
            className = self.getChildHoverActiveClass();
          else
            className = "child-hover-selected active " + self.props.fontSizeClass + ' child-item';

          elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + item.nodeId.low + "']");
          if (elm) {
            elm.className = className;
          }
        });
      }
    }
  }

  highLigtFolder(selectedFolderNodeId, previousSelectedFolderNodeId) {
    let elm;
    let self = this;

    if (!StringUtil.isEmpty(selectedFolderNodeId)) {
      elm = ListExplorerUtil.getElementByQuery("span[data-node-id='" + selectedFolderNodeId + "']");
      if (!StringUtil.isEmpty(elm) && !StringUtil.isEmpty(elm.parentNode.parentNode)) {
        elm.parentNode.parentNode.className = "tree-view_item child-hover-selected active";
      }
    }

    if (!StringUtil.isEmpty(previousSelectedFolderNodeId) && selectedFolderNodeId !== previousSelectedFolderNodeId) {
      //  divNodeId = null;
      elm = null;
      elm = ListExplorerUtil.getElementByQuery("span[data-node-id='" + previousSelectedFolderNodeId + "']");
      if (!StringUtil.isEmpty(elm) && !StringUtil.isEmpty(elm.parentNode.parentNode)) {
        elm.parentNode.parentNode.className = "tree-view_item";
      };
    }

    if (ListExplorerStore.selectedNodeIds.length > 0) {
      //  divNodeId = null;
      elm = null;
      let selectedNodeIdList = ListExplorerStore.selectedNodeIds;
      selectedNodeIdList.splice(0, 1);

      selectedNodeIdList.map((item) => {
        elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + item.nodeId.low + "']");
        if (elm) elm.className = self.getChildHoverClass();
      });
    }
  }

  async handleShortcuts(key, event) {
    let self = this;
    let activeNode = null;
    if (BrowserUtil.getBrowserName() == 'Safari' && event.code == 'KeyV') return;

    activeNode = !StringUtil.isEmpty(ListExplorerStore.selectedFolder) ? ListExplorerStore.selectedFolder : ListExplorerStore.activeNode;
    if (!StringUtil.isEmpty(activeNode)) {
      if (activeNode.categoryType == ListCategoryType.USER_ListCategory ||
        activeNode.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory ||
        activeNode.categoryType == ListCategoryType.SHAREDLIST_ListCategory ||
        activeNode.categoryType == ListCategoryType.FAVORITE_ListCategory ||
        activeNode.categoryType == ListCategoryType.TEMPORARYLIST_ListCategory ||
        activeNode.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        let action = null;
        switch (key) {
          case 'DELETE_ROW':
            if (!this.props.renameNode && ONeilViewStore.isListLoaded) {
              action = (activeNode.categoryType == ListCategoryType.FAVORITE_ListCategory) ? ContextMenuConstants.REMOVE_FROM_FAVORITES : ContextMenuConstants.DELETE;
              this.onContextMenuClick(action);
            }
            break;

          case 'RENAME':
            if ((!(ListExplorerStore.selectedNodeIds.length > 0) && !this.props.renameNode && activeNode.categoryType !== ListCategoryType.FAVORITE_ListCategory && activeNode.categoryType !== ListCategoryType.SHAREDLIST_ListCategory)
              || (activeNode.categoryType == ListCategoryType.FAVORITE_ListCategory && activeNode.listType == ListType.FOLDER_List)
              || (activeNode.categoryType == ListCategoryType.SHAREDLIST_ListCategory && activeNode.listType == ListType.FOLDER_List)) {
              BrowserUtil.disableEvents();
              action = ContextMenuConstants.RENAME;
              window.setTimeout(() => {
                self.onContextMenuClick(action);
              }, 20);
            }
            break;
          case 'PASTE':
            if (ONeilViewStore.isListEditable() != false || activeNode.categoryType === ListCategoryType.SHAREDLIST_ListCategory) {
              BrowserUtil.disableEvents();
              const content = await GridStore.getClipboardContents();
              if (!StringUtil.isEmpty(content))
                GridActions.pasteGridRows("", "", content);
              else
                GridStore.displayAlertMessage();
            }
            break;
          default:
            return;
        }
      }
    }
  }

  scrollExplorer(step, windowDiv) {
    let self = this;
    let scrollY = windowDiv.scrollTop;
    windowDiv.scrollTop = (scrollY + step);
    if (!stop) {
      window.setTimeout(() => { self.scrollExplorer(step, windowDiv) }, 20);
    }
  }

  getChildHoverClass() {
    return 'child-hover file-bdr ' + this.props.fontSizeClass + ' child-item';
  }

  getChildHoverActiveClass() {
    return 'child-hover active ' + this.props.fontSizeClass + ' child-item';
  }

  setScrollPositionBySymbol(nodeId) {
    ListExplorerUtil.getScrollPosition(nodeId);
    ListExplorerStore.loadingListExplorer = false;
    return this.props.loadingListExplorer();
  }

  setScrollPositionBySymbol(symbol) {
    ListExplorerUtil.setScrollPositionBySymbol(symbol);
    ListExplorerStore.loadingListExplorer = false;
    return this.props.loadingListExplorer();
  }

  setListExplorerScrollPosition(nodeId) {
    ListExplorerUtil.getScrollPosition(nodeId);
    ListExplorerStore.loadingListExplorer = false;
    return this.props.loadingListExplorer();
  }

  render() {
    this.elemIndex = 0;
    let resTree = null;
    this.dataSource = this.props.dataSource;
    if (this.dataSource != null) {
      resTree = this.dataSource.map((entry) => this.nestedTree(entry, entry.nodeId.low));
      this.explorerMounted = true;
    }

    return (
      <Shortcuts
        name='NAV_LIST_MANAGER'
        handler={this.handleShortcuts}
        targetNodeSelector=".listExplorerClass" isolate={true}>
        <div id="content" className="listExplorerClass" style={{ width: "100%" }} onClick={this.handleFolderClick} onContextMenu={this.onContextMenuClick}>
          {resTree}
          <img alt="" id="ghostImage" src="/Asset/images/Blank-Canvas.png" style={{ visibility: 'hidden' }} />
          <div id="list-drag-ghost-image"></div>
        </div>
      </Shortcuts>
    );

  }

  nestedTree(root, rootParentNodeId, colapseNode = true) {
    let self = this;
    colapseNode = !root.expandNode;
    let type = root.listType;
    let childs = root.childNodes;
    let allowedDrop = ListExplorerStore.canAcceptListDrop(root.categoryType);
    let rootNode = (root.listType === ListType.CATEGORY_List ? true : false);
    let folderNode = (root.listType === ListType.FOLDER_List ? true : false);
    let nodeId = root.nodeId.low;
    let isDraggable = false;
    let isVisible = root.isVisible;
    let listTreeItemName = (type === ListType.CATEGORY_List) ? root.name.toUpperCase() : root.name;

    let rootNodeObj = {};
    rootNodeObj.nodeId = root.nodeId.low;
    rootNodeObj.categoryType = root.categoryType;
    rootNodeObj.parentNodeId = !StringUtil.isEmpty(root.parentNodeId) ? root.parentNodeId.low : null;
    rootNodeObj.rootParentNodeId = rootParentNodeId;
    rootNodeObj.listType = root.listType;
    rootNodeObj.name = root.name;
    rootNodeObj.listCategory = root.listCategory;
    rootNodeObj.listTreeItemLevel = root.listTreeItemLevel;
    rootNodeObj.sourceCategoryType = root.sourceCategoryType;
    rootNodeObj.parentCategoryType = root.parentCategoryType;
    rootNodeObj.parentCategoryName = root.parentCategoryName;
    rootNodeObj.parentListType = root.parentListType;
    rootNodeObj.sourceListId = !StringUtil.isEmpty(root.sourceListId) ? root.sourceListId.low : null;
    rootNodeObj.parentCategoryType = root.parentCategoryType;
    rootNodeObj.parentCategoryName = root.parentCategoryName;
    rootNodeObj.parentListType = root.parentListType;
    rootNodeObj.sourceParentCategoryName = root.sourceParentCategoryName;
    rootNodeObj.className = (type != ListType.CATEGORY_List) ? " fa icn-folder-view-openfa icn-folder-view " : null;
    rootNodeObj = JSON.stringify(rootNodeObj);
    isDraggable = root.listType == ListType.CATEGORY_List ? false : true;

    self.childObj = root;

    let label = root.categoryType !== ListCategoryType.DATABASELIST_ListCategory ?
      <span data-node-id={root.nodeId.low}>
        {allowedDrop ?
          <span className={(type != ListType.CATEGORY_List) ? "tree-node categoryClass list-no-whiteSpace " + this.props.fontSizeClass : "tree-node categoryClass list-no-whiteSpace "}
            onDrop={self.handleFolderDrop.bind(self, root)}
            onDragOver={self.handleDragOver.bind(self, root)}
            onDragStart={self.handleFolderDragStart.bind(self, root)}
            onDragEnd={self.handleDragEnd.bind(self, root)}
            data-node-id={root.nodeId.low} draggable={isDraggable}
            onContextMenu={self.onContextMenuClick}>
            {LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(listTreeItemName, type), listTreeItemName)}
          </span>
          :
          <span className={(type != ListType.CATEGORY_List) ? "tree-node categoryClass list-no-whiteSpace " + this.props.fontSizeClass : "tree-node categoryClass list-no-whiteSpace "}
            data-node-id={root.nodeId.low}
            onDragOver={self.handleDragOver.bind(self, root)}
            onContextMenu={self.onContextMenuClick}>
            {LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(listTreeItemName, type), listTreeItemName)}
          </span>
        }
        <a data-node-id={root.nodeId.low} data-selectednode={rootNodeObj}></a>
      </span> : <span></span>;
    return (
      root.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory || root.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory?
        <TreeNode
          entry={root}
          activeClass={self.getChildHoverClass()}
          activeNodeId={self.props.nodeId}
          activeSybol={ListExplorerStore.activeSybol && ListExplorerStore.activeSybol.symbol}
          handleClickFromTempList={self.handleClickFromTempList}
          onContextMenuClick={self.onContextMenuClick}
          handleDragEnd={self.handleDragEnd.bind(self)}
          handleListDrop={self.handleListDrop.bind(self)}
          handleListDragStart={self.handleListDragStart.bind(self)}
          handleDragOver={self.handleDragOver.bind(self)}
          key={type + '|' + self.elemIndex++}
        />
        :
        <TreeView key={type + '|' + self.elemIndex++} nodeLabel={label} rootNode={rootNode} folderNode={folderNode} collapsed={colapseNode} nodeId={nodeId} childObj={self.childObj} isVisible={isVisible}
          handleDragOver={self.handleDragOver}
          handleFolderDragStart={self.handleFolderDragStart}
          handleFolderDrop={self.handleFolderDrop}
          handleDragEnd={self.handleDragEnd}>
          {
            childs.map((entry) => {
              let listClassName = 'userList ';
              self.childObj = entry;
              if (entry.listType === ListType.LIST_List) {
                switch (entry.categoryType) {
                  case ListCategoryType.FAVORITE_ListCategory:
                    listClassName += 'fa icn-fa-file-text-fav';
                    break;

                  case ListCategoryType.USER_ListCategory:
                  case ListCategoryType.SCHEDULESCREENLIST_ListCategory:
                    listClassName += 'fa icn-fa-file-text-userlist';
                    break;

                  case ListCategoryType.APLIST_ListCategory:
                    listClassName += 'fa icn-fa-file-text-ap';
                    break;

                  case ListCategoryType.SHAREDLIST_ListCategory:
                    if (!StringUtil.isEmpty(entry.shareAccess))
                      listClassName += entry.shareAccess === ShareAccessType.SHARE_READANDEDIT ? 'icn-editable' : 'fa icn-fa-file-text-userlist'
                    break;

                  default:
                    listClassName += 'fa icn-fa-file-text-p';
                    break;
                }
              }
              if (entry.isVisible) {
                if (entry.childNodes !== undefined && entry.childNodes.length > 0 || entry.listType === ListCategoryType.USER_ListCategory || entry.listType === ListCategoryType.SCHEDULESCREENLIST_ListCategory)
                  return self.nestedTree(entry, rootParentNodeId);
                else {
                  let selectedNode = {};
                  selectedNode.nodeId = entry.nodeId.low;
                  selectedNode.parentNodeId = entry.parentNodeId.low;
                  selectedNode.rootParentNodeId = rootParentNodeId;
                  selectedNode.sourceListId = !StringUtil.isEmpty(entry.sourceListId) ? entry.sourceListId.low : null;
                  selectedNode.categoryType = entry.categoryType;
                  selectedNode.name = entry.name;
                  selectedNode.listType = entry.listType;
                  selectedNode.className = listClassName;
                  selectedNode.listCategory = entry.listCategory;
                  selectedNode.listTreeItemLevel = entry.listTreeItemLevel;
                  selectedNode.sourceCategoryType = entry.sourceCategoryType;
                  selectedNode.parentCategoryType = entry.parentCategoryType;
                  selectedNode.parentCategoryName = entry.parentCategoryName;
                  selectedNode.parentListType = entry.parentListType;
                  selectedNode.sourceParentCategoryName = entry.sourceParentCategoryName;
                  selectedNode.ownerUserName = entry.ownerUserName;

                  //Adding owner name for SHARED ListCategory
                  let name = entry.name;
                  let ownername = (entry.categoryType == ListCategoryType.SHAREDLIST_ListCategory || entry.categoryType == ListCategoryType.SMARTLIST_ListCategory) && entry.listType == ListType.LIST_List && entry.ownerUserName
                    ? ` (${entry.ownerUserName})` : '';

                  let activeClass = self.getChildHoverClass();
                  if (selectedNode.nodeId == self.props.nodeId) activeClass += ' active';

                  selectedNode = JSON.stringify(selectedNode);

                  if (entry.alertStatusType == ListAlertStatusType.Active)
                    listClassName = `${listClassName} active-alert`;
                  else if (entry.alertStatusType == ListAlertStatusType.Triggered)
                    listClassName = `${listClassName} triggered-alert`;
                  else if (entry.alertStatusType == ListAlertStatusType.ActiveDisabled)
                    listClassName = `${listClassName} disabled-active-alert`
                  else if (entry.alertStatusType == ListAlertStatusType.TriggeredDisabled)
                    listClassName = `${listClassName} disabled-triggered-alert`


                  if ((rootParentNodeId !== ListCategoryType.USER_ListCategory) || (rootParentNodeId !== ListCategoryType.SCHEDULESCREENLIST_ListCategory)) {
                    return (
                      <div data-node-id={entry.nodeId.low} className={activeClass}
                        onDragOver={self.handleDragOver.bind(self, entry)}
                        onDragStart={self.handleListDragStart.bind(self, entry)}
                        onDrop={self.handleListDrop.bind(self, entry)}
                        onDragEnd={self.handleDragEnd.bind(self, entry)}
                        onContextMenu={self.onContextMenuClick}
                        onClick={self.handleClick}
                        key={type + '|' + self.elemIndex++}
                        draggable="true" >

                        <span className='listCategoryClass list-no-whiteSpace list-no-Border ' data-node-id={entry.nodeId.low}>
                          <i className={listClassName} data-node-id={entry.nodeId.low}><b className="icn-sign" data-node-id={entry.nodeId.low}></b></i>
                          <span style={{ verticalAlign: 'middle' }} data-node-id={entry.nodeId.low} >{LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(name, entry.listType), name)}</span>
                          {ownername && <span style={{ fontStyle: 'italic' }} data-node-id={entry.nodeId.low} >{ownername}</span>}
                        </span>
                        <a data-node-id={entry.nodeId.low} data-selectednode={selectedNode}></a>

                      </div>
                    );
                  }
                  else {
                    return (<div className={activeClass}
                      draggable="true" key={i}
                      onDragOver={self.handleDragOver.bind(self, entry)}
                      onDragStart={self.handleListDragStart.bind(self, entry)}
                      onDrop={self.handleListDrop.bind(self, entry)}
                      onDragEnd={self.handleDragEnd.bind(self, entry)}
                      onContextMenu={self.onContextMenuClick}
                      onClick={self.handleClick}
                      data-node-id={entry.nodeId.low}>

                      <span style={{ position: 'relative' }} className='listCategoryClass list-no-whiteSpace list-no-Border ' data-node-id={entry.nodeId.low}>
                        <i className={listClassName} data-node-id={entry.nodeId.low}></i>
                        <span style={{ verticalAlign: 'middle' }} data-node-id={entry.nodeId.low}>{LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(name, entry.listType), name)}</span>
                      </span>
                      <a data-node-id={entry.nodeId.low} data-selectednode={selectedNode}></a>
                    </div>);
                  }
                }
              }
            })
          }
        </TreeView>
    );
  }

  setDragDiv(event, dragMessage, dragIconClass) {
    this.dragDiv = null;
    this.dragDiv = document.getElementById('list-drag-ghost-image');

    let newSpan = document.createElement('span');
    newSpan.style.marginLeft = '7px';
    newSpan.style.display = 'inline-block';

    let newI = document.createElement('i');
    newI.className = dragIconClass + ' marr5';
    newSpan.appendChild(newI);

    let newChildSpan = document.createElement('span');
    newChildSpan.textContent = dragMessage;
    newSpan.appendChild(newChildSpan);

    this.dragDiv.appendChild(newSpan);

    this.dragDiv.className = 'dragDiv';
    this.dragDiv.style.top = (event.clientY + 5) + 'px', this.dragDiv.style.left = (event.clientX + 0) + 'px';
  }

  clearDragMessage() {
    let self = this;
    if (!StringUtil.isEmpty(self.dragDiv)) {
      self.dragDiv.innerHTML = '';
      self.dragDiv.style.zIndex = '-1';
      self.dragDiv.removeAttribute('style');
    }
  }

  setDragMessage(elementDragged, targetElement) {
    let dragMsg = null;
    let action = null;

    if (ListExplorerStore.selectedNodeIds.length > 0 && _.contains(ListExplorerStore.selectedNodeIds, elementDragged)) {
      if (targetElement.categoryType == ListCategoryType.FAVORITE_ListCategory) {
        if (targetElement.listType == ListType.FOLDER_List || targetElement.listType == ListType.CATEGORY_List) {
          action = LocalizationStore.getTranslatedData("LM_Added", 'Add ');
        }
        else {
          if (targetElement.categoryType !== elementDragged.categoryType && ((elementDragged.categoryType !== ListCategoryType.USER_ListCategory) || (elementDragged.categoryType !== ListCategoryType.SCHEDULESCREENLIST_ListCategory)))
            action = LocalizationStore.getTranslatedData("list_copied", 'Copy ');
          else
            action = LocalizationStore.getTranslatedData("list_moved", 'Move ');
        }
        dragMsg = action + (ListExplorerStore.selectedNodeIds.length) +
          LocalizationStore.getTranslatedData("LM_ListCountToFavorites", ' lists to favorites');
      }
      else {
        //action = targetElement.categoryType == ListCategoryType.USER_ListCategory ? 'Copy ' : 'Move ';
        if ((targetElement.categoryType == ListCategoryType.USER_ListCategory) && ((elementDragged.categoryType == ListCategoryType.USER_ListCategory) || (elementDragged.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory))) {
          action = LocalizationStore.getTranslatedData("list_moved", 'Move ');
        } else if (targetElement.categoryType == ListCategoryType.USER_ListCategory) {
          action = LocalizationStore.getTranslatedData("list_copied", 'Copy ');
        } else {
          action = LocalizationStore.getTranslatedData("list_moved", 'Move ');
        }
        dragMsg = LocalizationStore.getTranslatedData("lists_movedTooltipMsg", '{0} {1} lists').replace("{0}", action).replace("{1}", ListExplorerStore.selectedNodeIds.length);
      }
    }
    else {
      if (elementDragged.listType == ListType.LIST_List) {
        if (targetElement.categoryType == ListCategoryType.FAVORITE_ListCategory) {
          dragMsg = (targetElement.categoryType == ListCategoryType.FAVORITE_ListCategory && elementDragged.categoryType == ListCategoryType.FAVORITE_ListCategory)
            ?
            LocalizationStore.getTranslatedData("list_moved", 'Move ') + LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(elementDragged.name, elementDragged.listType), elementDragged.name)
            :
            LocalizationStore.getTranslatedData("LM_AddListToFavorites", 'Add {0} to Favorites').replace("{0}", LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(elementDragged.name, elementDragged.listType), elementDragged.name));
        }
        else if ((targetElement.categoryType == ListCategoryType.USER_ListCategory && (elementDragged.categoryType != ListCategoryType.SCHEDULESCREENLIST_ListCategory && elementDragged.categoryType != ListCategoryType.USER_ListCategory))
          || (targetElement.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory && (elementDragged.categoryType != ListCategoryType.USER_ListCategory && elementDragged.categoryType != ListCategoryType.SCHEDULESCREENLIST_ListCategory))) {
          action = LocalizationStore.getTranslatedData("list_copied", 'Copy ')
          dragMsg = action + LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(elementDragged.name, elementDragged.listType), elementDragged.name);
        }
        else {
          action = LocalizationStore.getTranslatedData("list_moved", 'Move ');
          dragMsg = action + LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(elementDragged.name, elementDragged.listType), elementDragged.name);
        }
      }
      else {
        dragMsg = LocalizationStore.getTranslatedData("list_moved", 'Move ') + LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(elementDragged.name, elementDragged.listType), elementDragged.name);
      }
    }
    return dragMsg;
  }

  setOpacityOnDrag(elementDragged, isClearOpacity = false) {
    let elm = null;
    if (elementDragged.length > 0) {
      elementDragged.map((item) => {
        if (!StringUtil.isEmpty(item.nodeId) || !StringUtil.isEmpty(item.symbol)) {
          if (!StringUtil.isEmpty(item.symbol))
            elm = ListExplorerUtil.getElementByQuery("span[data-symbol='" + item.symbol + "']");
          else
            elm = ListExplorerUtil.getElementByQuery("span[data-node-id='" + item.nodeId.low + "']");
          if (elm) {
            let childElm = null;
            if (item.listType == ListType.FOLDER_List) {
              childElm = elm.children[0];
            }
            else {
              if (isClearOpacity)
                childElm = elm.children[1];
              else {
                childElm = ((item.categoryType == ListCategoryType.USER_ListCategory) || (item.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory)) ? elm.children[2] : elm.children[1];
              }
            }
            if (!StringUtil.isEmpty(childElm))
              childElm.style.opacity = isClearOpacity ? null : '0.5';
          }
        }
      });
    }
  }

  setBorderOnDrag(currentElement) {
    let spanNodeId = null;
    let elm = null;

    if (!StringUtil.isEmpty(currentElement)) {
      if (this.canSetBorder(currentElement)) {
        spanNodeId = (currentElement.listType == ListType.LIST_List) ? "span[data-node-id='" + currentElement.nodeId.low + "']" : "span[id='" + currentElement.nodeId.low + "']";
        elm = ListExplorerUtil.getElementByQuery(spanNodeId);
        if (elm) {
          if (!(document.getElementById("list-border-" + currentElement.nodeId.low))) {
            let borderElm = document.createElement('div');

            let borderMarginLeft = window.getComputedStyle(elm.firstChild).getPropertyValue('margin-left').match(/\d+/);

            borderElm.setAttribute("id", "list-border-" + currentElement.nodeId.low);
            borderElm.setAttribute("class", "listBorder");
            borderElm.style.marginLeft = (borderMarginLeft - 15) + 'px';

            borderElm.style.paddingTop = (currentElement.listType == ListType.LIST_List) ? ((elm.children[0].offsetHeight - 2) + 'px') : ((elm.children[0].offsetHeight) + 3 + 'px');

            if (!StringUtil.isEmpty(elm.children[0])) {
              if (!StringUtil.isEmpty(elm.children[1]))
                borderElm.style.width = (elm.children[0].offsetWidth + elm.children[1].offsetWidth + 33) + "px";
            }
            elm.insertBefore(borderElm, elm.firstChild);
          }
        }
      }
    }

    if (!StringUtil.isEmpty(this.previousElementOnOver)) {
      if (currentElement !== this.previousElementOnOver) {
        spanNodeId = null;
        elm = null;
        spanNodeId = (this.previousElementOnOver.listType == ListType.LIST_List) ? "span[data-node-id='" + this.previousElementOnOver.nodeId.low + "']" : "span[id='" + this.previousElementOnOver.nodeId.low + "']";
        elm = ListExplorerUtil.getElementByQuery(spanNodeId);
        if (elm) {
          if ((document.getElementById("list-border-" + this.previousElementOnOver.nodeId.low)))
            document.getElementById("list-border-" + this.previousElementOnOver.nodeId.low).remove(true);
        }
      }
    }

    this.previousElementOnOver = currentElement;
  }

  canSetBorder(currentElement) {
    if (ListExplorerStore.sourceType == 'Grid') {
      if (((currentElement.categoryType == ListCategoryType.USER_ListCategory) || (currentElement.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory)) && currentElement.listType !== ListType.FOLDER_List) {
        return true;
      }
    }
    else {
      if (((currentElement.categoryType == ListCategoryType.USER_ListCategory) || (currentElement.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) || (currentElement.categoryType == ListCategoryType.FAVORITE_ListCategory && (currentElement.listType == ListType.FOLDER_List || currentElement.listType == ListType.CATEGORY_List
      ))) && this.elementDragged && (this.elementDragged.entry.categoryType !== ListCategoryType.FAVORITE_ListCategory || currentElement.categoryType !== ListCategoryType.USER_ListCategory || currentElement.categoryType !== ListCategoryType.SCHEDULESCREENLIST_ListCategory)) {
        return true;
      }
    }
    return false;
  }


  setHoverOnDrag(elementDragged, isHover = false) {
    try {
      let elm = null;
      let draggedNodeId = null;
      let currentNodeId = null;

      if (!StringUtil.isEmpty(elementDragged)) {
        draggedNodeId = typeof (elementDragged.nodeId) === 'object' ? elementDragged.nodeId.low : elementDragged.nodeId;
        if (elementDragged.listType == ListType.LIST_List) {
          currentNodeId = typeof (ListExplorerStore.activeNode.nodeId) === 'object' ? ListExplorerStore.activeNode.nodeId.low : ListExplorerStore.activeNode.nodeId;
          elm = ListExplorerUtil.getElementByQuery("div[data-node-id='" + draggedNodeId + "']");
          if (elm && draggedNodeId !== currentNodeId && elementDragged.categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory && ListExplorerStore.activeNode.categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory) elm.className = isHover ? elm.className : elm.className.replace('child-hover ', '');
        }
        else if (elementDragged.listType == ListType.FOLDER_List && !StringUtil.isEmpty(ListExplorerStore.selectedFolder)) {
          currentNodeId = !StringUtil.isEmpty(ListExplorerStore.selectedFolder) ? ListExplorerStore.selectedFolder.nodeId : elementDragged.nodeId.low;
          elm = ListExplorerUtil.getElementByQuery("div[id='" + elementDragged.nodeId.low + "']");
          if (elm && ListExplorerStore.selectedFolder.nodeId !== elementDragged.nodeId.low) {
            if (isHover) {
              if (!elm.className.includes('child-hover'))
                elm.className = elm.className + 'child-hover';
            }
            else
              elm.className = elm.className.replace('child-hover', '');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleDragEnd() {
    let elementDragged = [];

    this.clearDragMessage();

    this.setBorderOnDrag(null);

    //Setting the opacity on drag end
    if (ListExplorerStore.selectedNodeIds.length > 0 && _.contains(ListExplorerStore.selectedNodeIds, this.elementDragged.entry))
      elementDragged = ListExplorerStore.selectedNodeIds;
    else {
      //Set hover on drag
      if (ListExplorerStore.selectedNodeIds.length == 0)
        Array.isArray(this.elementDragged.entry) ? this.setHoverOnDrag(this.elementDragged.entry[0], true) : this.setHoverOnDrag(this.elementDragged.entry, true);

      elementDragged.push(this.elementDragged.entry);
    }
    this.setOpacityOnDrag(elementDragged, true);
  }

  handleDragMove() {
    let windowDiv = document.querySelector(".middel-inner");
    let bRectOfWindow = windowDiv.getBoundingClientRect();
    let offSetForScroll = 5;
    let verticalScrollbar = document.querySelector("#explorerVerticalScroll")
    stop = true;
    if (mousePos.cY <= (bRectOfWindow.top + offSetForScroll) && mousePos.cX <= bRectOfWindow.right) {
      stop = false;
      this.scrollExplorer(-1, verticalScrollbar);
    }
    if (mousePos.cY >= (bRectOfWindow.bottom - offSetForScroll) && mousePos.cX <= bRectOfWindow.right) {
      stop = false;
      this.scrollExplorer(1, verticalScrollbar)
    }
  }

  handleFolderDragStart(entry, event) {
    this.dragNodeId = entry;
    let target = event.target || event.srcElement;
    ListExplorerStore.sourceType = 'List';

    entry.name = LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(entry.name, entry.listType), entry.name);
    this.elementDragged = { entry: entry, e: event.target };

    //Clear hover on drag
    if (ListExplorerStore.selectedNodeIds.length == 0) {
      Array.isArray(this.elementDragged.entry) ? this.setHoverOnDrag(this.elementDragged.entry[0], true) : this.setHoverOnDrag(this.elementDragged.entry);
    }

    if (BrowserUtil.isIEBrowser()) {
      let cloneNode = target.cloneNode(true);
      target.parentNode.insertBefore(cloneNode, target);
      target.style.display = "none";
      window.setTimeout(() => {
        target.parentNode.removeChild(cloneNode);
        target.style.display = "inline-block";
      }, 0);
      event.dataTransfer.setData("text", entry.name);
    }
    else {
      let img = document.getElementById('ghostImage');
      event.dataTransfer.setDragImage(img, 0, 0);
      event.dataTransfer.setData("text/plain", entry.name);
    }
  }

  handleDragOver(entry, event) {
    if (!StringUtil.isEmpty(entry))
      this.setBorderOnDrag(entry);

    if (ListExplorerStore.sourceType == 'Grid') {
      /* Restricted - cryptocurrency list item, drag from grid and drop on explorer */
      if (ListExplorerStore.getIsCryptoCurrencyList()) return false;
      if (ListExplorerStore.getIsFuturesCashSpotsList()) return false;
      else if (entry.listType == ListType.FOLDER_List) {
        return false;
      }
      else {
        if (entry.categoryType == ListCategoryType.FAVORITE_ListCategory) {
          if (entry.sourceCategoryType == ListCategoryType.USER_ListCategory || entry.sourceCategoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory || entry.sourceCategoryType == ListCategoryType.APLIST_ListCategory) {
            event.preventDefault();
          }
        }
        else if ((entry.categoryType == ListCategoryType.USER_ListCategory || entry.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory || entry.categoryType == ListCategoryType.APLIST_ListCategory || (entry.categoryType == ListCategoryType.SHAREDLIST_ListCategory && entry.shareAccess == ShareAccessType.SHARE_READANDEDIT)) && entry.listType == ListType.LIST_List) {
          event.preventDefault();
        }
      }
    }
    else {
      //Set drag message
      let dragMsg = null;
      let dragIconClass = null;
      let elementDragged = [];

      this.clearDragMessage();

      if (!this.elementDragged) {
        return false;
      }
      dragMsg = this.setDragMessage(this.elementDragged.entry, entry);

      //Get drag Icon ClassName
      let currentelement = ListExplorerStore.getActiveNode(this.elementDragged.entry.nodeId.low);

      //Setting the opacity on drag
      if (ListExplorerStore.selectedNodeIds.length > 0 && _.contains(ListExplorerStore.selectedNodeIds, this.elementDragged.entry))
        elementDragged = ListExplorerStore.selectedNodeIds;
      else
        elementDragged.push(this.elementDragged.entry);

      this.setOpacityOnDrag(elementDragged);

      if (!StringUtil.isEmpty(currentelement)) {
        if (this.elementDragged.entry.categoryType == ListCategoryType.APLIST_ListCategory || this.elementDragged.entry.categoryType == ListCategoryType.SMARTLIST_ListCategory || this.elementDragged.entry.categoryType == ListCategoryType.MORGUELIST_ListCategory ||
          this.elementDragged.entry.categoryType == ListCategoryType.POSITIONHISTORYLIST_ListCategory || (this.elementDragged.entry.categoryType == ListCategoryType.SHAREDLIST_ListCategory && this.elementDragged.entry.listType == ListType.LIST_List) || ((this.elementDragged.entry.categoryType == ListCategoryType.USER_ListCategory || this.elementDragged.entry.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) && this.elementDragged.entry.listType == ListType.LIST_List)) {
          dragIconClass = null;
        }
        else
          dragIconClass = currentelement.className;
      }

      this.setDragDiv(event, dragMsg, dragIconClass);
      if (!BrowserUtil.isIEBrowser()) {
        event.dataTransfer.dropEffect = "copy";
      }

      /* Restricted - cryptocurrency list, drag and drop on explorer */
      this.movingParentNodeToChild = false;
      this.findChildNodeExistenceStatus(this.dragNodeId,entry);

      if(currentelement && currentelement.parentCategoryType !== undefined && currentelement.parentCategoryType === ListCategoryType.PRESET_ListCategory  && currentelement.categoryType === ListCategoryType.PRESET_ListCategory && currentelement.name === "CryptoCurrency") return false;

      if (ListExplorerStore.canAcceptListDrop(entry.categoryType, entry.parentNodeId, this.movingParentNodeToChild, this.elementDragged.entry.categoryType)) {
        if (this.allowDrop(entry)) {
          return false;
        }
        event.preventDefault();
      }
      return false;
    }
  }

  findChildNodeExistenceStatus(dragNode,dropNode){
    _.some(dragNode.childNodes, (item) => {
       if(item.nodeId.low === dropNode.nodeId.low){
         this.movingParentNodeToChild = true;
       }
       if(item.childNodes)
        {
          this.findChildNodeExistenceStatus(item,dropNode);
        }
    });
     }

  allowDrop(entry) {
    //Avoid List drop to favorites./Avoid multiple list drop to another list./Avoid Folder copy from different categories.
    //Avoid Copy/Move of List/Folder of same.//Avoid List copy to Shared List and folder move is allowed within shared list.
    if ((((entry.categoryType == ListCategoryType.FAVORITE_ListCategory ||
      (entry.categoryType == ListCategoryType.SHAREDLIST_ListCategory && entry.shareAccess !== ShareAccessType.SHARE_READANDEDIT)) &&
      entry.listType == ListType.LIST_List) ||
      (ListExplorerStore.selectedNodeIds.length > 1 && this.elementDragged.entry.listType ===
        ListType.LIST_List && entry.listType === ListType.LIST_List) ||
      (entry.listType == ListType.FOLDER_List && this.elementDragged.entry.listType ==
        ListType.FOLDER_List && entry.categoryType !== this.elementDragged.entry.categoryType) ||
      (entry == this.elementDragged.entry) ||
      (entry.categoryType == ListCategoryType.SHAREDLIST_ListCategory && entry.categoryType !== this.elementDragged.entry.categoryType && entry.shareAccess !== ShareAccessType.SHARE_READANDEDIT)) ||
      (this.elementDragged.entry.categoryType == ListCategoryType.FAVORITE_ListCategory && (entry.categoryType == ListCategoryType.USER_ListCategory || entry.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory)) ||
      (this.elementDragged.entry.categoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory && entry.categoryType === ListCategoryType.USER_ListCategory)) {
      return true;
    }
    return false;
  }

  handleListDragStart(entry, event) {
    this.dragNodeId = entry;
    let target = event.target || event.srcElement;
    entry.name = LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(entry.name, entry.listType), entry.name);

    //Preventing drag on favorite List item
    ListExplorerStore.sourceType = 'List';
    this.elementDragged = { entry: entry, e: event.target };
    if ((entry.categoryType == ListCategoryType.USER_ListCategory || entry.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) && entry.listType == ListType.LIST_List)
      this.previousElementOnOver = entry;

    //Clear hover on drag
    if (ListExplorerStore.selectedNodeIds.length == 0)
      this.setHoverOnDrag(this.elementDragged.entry);
    if (BrowserUtil.isIEBrowser()) {
      let cloneNode = target.cloneNode(true);
      target.parentNode.insertBefore(cloneNode, target);
      target.style.display = "none";
      window.setTimeout(() => {
        target.parentNode.removeChild(cloneNode);
        target.style.display = "block";
      }, 0);
      event.dataTransfer.setData("text", entry.name);
    }
    else {
      let img = document.getElementById('ghostImage');
      event.dataTransfer.setDragImage(img, 0, 0);
      event.dataTransfer.setData("text/plain", entry.name);
    }
  }

  // List to List copy on droping
  handleListDrop(entry, event) {
    try {
      let elementDragged = [];

      this.clearDragMessage();

      if (!BrowserUtil.isIEBrowser()) {
        event.stopPropagation();
        event.preventDefault();
      }
      if (!StringUtil.isEmpty(entry)) {
        let currentNodeId = this.props.nodeId;
        let targetNodeName = entry.name;
        let targetListId = null;

        if (entry.categoryType == ListCategoryType.FAVORITE_ListCategory && !StringUtil.isEmpty(entry.sourceListId)) {
          if ((entry.sourceCategoryType == ListCategoryType.USER_ListCategory || entry.sourceCategoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) || entry.sourceCategoryType == ListCategoryType.APLIST_ListCategory) {
            targetListId = entry.sourceListId.low;
          }
        }
        else
          targetListId = entry.nodeId.low;

        if (ListExplorerStore.sourceType == 'Grid') {
          this.setBorderOnDrag(null);
          BrowserUtil.disableEvents();
          GridStore.verifySelectedSymbolsExceed();
          ListActions.addSymbolsToList(targetListId, targetNodeName, currentNodeId);
        }
        else {
          //Setting the opacity on drop
          if (ListExplorerStore.selectedNodeIds.length > 0)
            elementDragged = ListExplorerStore.selectedNodeIds;
          else
            elementDragged.push(this.elementDragged.entry);
          this.setOpacityOnDrag(elementDragged, true);

          //Set/Clear border on drag
          if (((entry.categoryType == ListCategoryType.USER_ListCategory || entry.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) && entry.listType == ListType.LIST_List) || (entry.categoryType == ListCategoryType.FAVORITE_ListCategory && entry.listType == ListType.FOLDER_List)) {
            this.previousElementOnOver = entry;
            this.setBorderOnDrag(null);
          }

          if (entry === this.elementDragged.entry || (ListExplorerStore.selectedNodeIds.length > 1 && this.elementDragged.entry.listType === ListType.LIST_List && entry.listType === ListType.LIST_List))
            return false;
          if (this.elementDragged.entry.listType === ListType.LIST_List && this.elementDragged.entry.parentNodeId.low !== ListCategoryType.FAVORITE_ListCategory) {
            let itemCopyToList = {
              targetListId: entry.nodeId.low,
              targetNodeName: entry.name,
              consoleId: 0,
              sourceId: this.elementDragged.entry.nodeId.low,
              saveScreenFilter: 1,
              undoAdd: false,
              currentNodeId: this.props.nodeId
            };
            //Action to copy symbol in droped list
            BrowserUtil.disableEvents();
            if (ONeilViewStore.noFilters()) {
              ListActions.getAddItemsToList(itemCopyToList, entry.name);
            }
            else {
              ONeilViewStore.getFullListMsids(this.elementDragged.entry,ONeilViewStore._state.basicEsInfo, true).then((msids) => {
                ListActions.addSymbolsToList(targetListId, targetNodeName, currentNodeId, msids);
              });
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Drop list on favorite category and User List category
  handleFolderDrop(targetItem, event) {
    //let elementDragged = [];

    this.clearDragMessage();

    if (!BrowserUtil.isIEBrowser() || BrowserUtil.isEdgeBrowser()) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (!StringUtil.isEmpty(this.elementDragged)) {
      if (ListExplorerStore.selectedNodeIds.length > 0 && _.contains(ListExplorerStore.selectedNodeIds, this.elementDragged.entry)) {
        this.elementDragged = null;
        this.elementDragged = { entry: ListExplorerStore.selectedNodeIds, categoryType: ListExplorerStore.selectedNodeIds[0].categoryType };
      }
      else {
        this.elementDragged = { entry: [this.elementDragged.entry], categoryType: this.elementDragged.entry.categoryType };
      }

      this.setOpacityOnDrag(this.elementDragged.entry, true);

      //No element draged return
      if (this.elementDragged === null) {
        return false;
      }

      //place is same drag and drop prevent
      if (targetItem === this.elementDragged.entry[0])
        return false;

      //Set/Clear border on drag
      if (((targetItem.categoryType == ListCategoryType.USER_ListCategory || targetItem.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) && targetItem.listType == ListType.FOLDER_List) || (targetItem.categoryType == ListCategoryType.FAVORITE_ListCategory && targetItem.listType == ListType.FOLDER_List)) {
        this.previousElementOnOver = targetItem;
        this.setBorderOnDrag(null);
      }

      //prevent List duplicate in favorite
      let isFav = false;
      if (targetItem.categoryType === ListCategoryType.FAVORITE_ListCategory) {
        this.elementDragged.entry = ListExplorerStore.findListItemsToCopy(this.elementDragged.entry, targetItem.childNodes);
        isFav = true;
      }

      //Drop allowing
      if (ListExplorerStore.canAcceptListDrop(targetItem.categoryType)) {
        if (targetItem.listType == ListType.FOLDER_List || targetItem.listType == ListType.CATEGORY_List) {
          if (targetItem.categoryType == this.elementDragged.categoryType || this.elementDragged.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) {
            if ((targetItem.categoryType == ListCategoryType.USER_ListCategory
              || targetItem.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory
              || targetItem.categoryType == ListCategoryType.SHAREDLIST_ListCategory
              || targetItem.categoryType == ListCategoryType.FAVORITE_ListCategory)) {

              //let targetParentNodeId = !StringUtil.isEmpty(targetItem.parentNodeId) ? targetItem.parentNodeId.low : null;
              let sourceParentNodeId = !StringUtil.isEmpty(this.elementDragged.entry[0].parentNodeId) ? this.elementDragged.entry[0].parentNodeId.low : null;
              if (sourceParentNodeId !== targetItem.nodeId.low) {
                this.highLightMultipleNode([], ListExplorerStore.selectedNodeIds);

                //Allow user to move 5 nested folders
                if (!StringUtil.isEmpty(targetItem)) {
                  if (this.elementDragged.entry[0].listType == ListType.FOLDER_List && ListExplorerStore.folderLimitReached(targetItem)) {
                    return this.props.showValidationWindow();
                  }
                  else if (this.elementDragged.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory
                    && targetItem.categoryType === ListCategoryType.FAVORITE_ListCategory) {
                    this.DuplicateListItem(targetItem, this.elementDragged, isFav);
                  }
                  else {
                    this.moveListItems(this.elementDragged.entry, targetItem);
                  }
                }
              }
              return;
            }
            else {
              return;
            }
          }
          else {
            if (this.elementDragged.entry.length > 0) {
              if (this.elementDragged.entry[0].listType == ListType.FOLDER_List && targetItem.categoryType !== this.elementDragged.categoryType) {
                return;
              }
              if ((this.elementDragged.entry[0].listType == ListType.LIST_List && targetItem.listType == ListType.FOLDER_List) || targetItem.listType == ListType.CATEGORY_List)
                this.DuplicateListItem(targetItem, this.elementDragged, isFav);
            }
            else {
              this.DuplicateListItem(targetItem, this.elementDragged, isFav);
            }
          }
        }
        else {
          return false;
        }
      }
    }
  }

  handleFocus(event) {
    event.target.select();
  }

  DuplicateListItem(targetItem, elementDragged, isFav) {
    let duplicateList = [];
    let sourceCategoryType = null;
    if (elementDragged.entry.length > 0) {

      elementDragged.entry.map((item) => {
        duplicateList.push({
          symCode: item.symbol ? item.symbol : '',
          parentNodeId: typeof(targetItem.nodeId) === "object" ? targetItem.nodeId.low : targetItem.nodeId,
          name: item.name,//item.symbol ? encodeURIComponent(item.name) : StringUtil.stripHTML(item.name),
          sourceListId: item.nodeId && item.nodeId.low ? item.nodeId.low : '',
          destListId: '',
          cacheId: '',
          sortCols: null,
          isFav: isFav,
          isFlagList: false,
          spId: null
        })
        if (targetItem.categoryType == ListCategoryType.FAVORITE_ListCategory || targetItem.categoryType == ListCategoryType.USER_ListCategory || targetItem.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) {
          sourceCategoryType = item.categoryType;
        }
      });
    }
    else
      duplicateList = null;

    let action = null;
    action = targetItem.categoryType == ListCategoryType.FAVORITE_ListCategory ? ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES : ListExplorerConstants.ActionTypes.COPY;
    //API call for Duplicating the List
    BrowserUtil.disableEvents();
    ListActions.getDuplicateList(duplicateList, targetItem.nodeId.low, sourceCategoryType, action);
    //Expand the rootNode where the list dropped
    ListExplorerStore.ExpandItemLevel(targetItem.categoryType, targetItem.nodeId.low, true);
  }

  moveListItems(itemToMove, newParent) {
    BrowserUtil.disableEvents();
    ListActions.moveListItems(itemToMove, newParent);
  }
}

export default ListExplorer;
