import Scale from "./Scale.js";
class AVolumeScale extends Scale {
    getObjectMapKey() {
        return "AVolumeScale";
    }
    // constructor(Factor, MLBaseVolumeForPlot)
    constructor(Factor) {
        super(Factor);
    }
    GetVolInc(price) {
        var pr2 = Math.floor(price);
        var pr0 = Math.floor(this.log10(pr2));
        var pr1 = Math.floor(Math.pow(10, pr0));
        var pr3 = pr2 % pr1;
        var inc = pr2 - pr3;
        return inc;
    }
    SetHorizontalGrid(mhLines, minVolume, maxVolume, avolume, height) {
        if (mhLines.Length > 0)
            mhLines.Clear();
        this.Height = height;
        var maxVol = maxVolume;
        //var mva = avolume;
        var volInc = maxVolume / 8;
        var hBottom = height - 25;

        volInc = this.GetVolInc(volInc);
        if (volInc <= 0.0)
            volInc = maxVol / 8;

        if (maxVolume == minVolume)
            minVolume = maxVolume * 0.1;

        this.Factor = maxVol <= 0 ? 1 : hBottom / maxVol;

        var y = this.ComputeY(volInc);
        var py = y;
        var prv = volInc;

        if(!isFinite(maxVol)) return;
        
        for (var prc = volInc; y > 0 && prc < maxVol; prc += volInc) {
            y = this.ComputeY(prc);

            var label = prc;

            if (prc > 999 && prc < 1000000) {
                label = (prc / 1000) + "K";
            }
            else
                if (prc > 999999 && prc < 1000000000) {
                    label = (prc / 1000000) + "M";
                }
                else
                    if (prc > 999999999) {
                        label = (prc / 1000000000) + "B";
                    }

            if (prv == label) continue;
            prv = label;
            if (py - y < 10 || y < 25) continue;

            var eLine =
            {
                Type: 2,
                Label: label,
                eLabel: " ",
                YAxis: y
            };
            mhLines.push(eLine);
            py = y;
        }
    }
    ComputeY(lVolume) {
        var dPriceDiffFromBottom = lVolume;

        if (dPriceDiffFromBottom < 0.01)
            dPriceDiffFromBottom = 0;

        return Math.max(1, (this.Height - dPriceDiffFromBottom * this.Factor));
    }
    ComputeVolume(y) {
        return (this.Height - y) / this.Factor;
    }
    ComputePrice(y) {
        return (this.Height - y) / this.Factor;
    }
}

export default AVolumeScale;
