import React, { Component, Fragment } from 'react';
import { ScaleLabels } from '../Presentational';
import { connect } from 'react-redux';
import { CompareMenuType } from "CompareTabType";

class ScaleSection extends Component {
    render() {
        const { mhLines } = this.props;
        return (
            <Fragment>
                <div className="left-rotation">
                    <ScaleLabels mhLabels={mhLines} />
                </div>
                <div className="right-rotation">
                    <ScaleLabels mhLabels={mhLines} />
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.compareChart;
    const { mhLines } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

    return ({ mhLines: mhLines });
}

export default connect(mapStateToProps)(ScaleSection);