import Serializable from "Serializable";
import SettingsStore from "SettingsStore";

class NewsSettings extends Serializable {
  onSeralisedInfoRecieved(serializedValue) {   
    this.newsStream = serializedValue.newsStream ? serializedValue.newsStream : this.getDefaultUserNewsStream();
  }

  getObjectMapKey() {
    return "newsSettings";
  }

  getDataObject() {
    return {
      newsStream: this.newsStream
    };
  }

  getDefaultUserNewsStream() {
    
    let userNewStreamObj = new Serializable();
    const viewSetting = SettingsStore.getConsoleSettings();
    const newsSettings = viewSetting.NavNewsSettings
    userNewStreamObj.newsStream = newsSettings;

    return userNewStreamObj;
  }

  getDefaultNewsSettings() {
    this.newsStream = [];
    return this;
  }

}

export default NewsSettings;
