import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class UpdateGlobalFlagAllRequest extends BaseServiceRequest {

    constructor(listId, cacheId, isDelete, dbType) {
        super();
        super.addRequestUri("flagall.pb");
        super.addRequestParameter("db", dbType.toString());
        super.addRequestParameter("listid", listId);
        super.addRequestParameter("isDelete", isDelete.toString());
        super.addRequestParameter("cacheId", cacheId.toString());
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}

export default UpdateGlobalFlagAllRequest;
