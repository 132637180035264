import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Refresh } from '../Presentational';
import { onRefresh } from '../../../../../Actions/ComparisonActions';
import { refreshRotationGraph } from 'Actions/RotationGraphActions';
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType, { CompareMenuType } from "CompareTabType";

class ChartRefresh extends Component {
    render() {
        const action = this.props.isRotationGraph ? this.props.refreshRotationGraph : this.props.onRefresh;

        return (
            <ErrorBoundary><Refresh isLoading={this.props.isLoading} onClick={action} /></ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    const menuState = state.comparisonAndRotationMenuPanel.MenuTabReducer;
    let selectedMenuTab = menuState.ComparisonMenuTab;
    let loadingSection = menuState.isLoading.ComparisonGraph;
    if (menuState.selectedTab === CompareTabType.RotationGraph) {
        selectedMenuTab = menuState.RotationMenuTab;
        loadingSection = menuState.isLoading.RotationGraph;
    }

    let isLoading = loadingSection.SymbolsLoading;
    if (selectedMenuTab === CompareMenuType.Groups) {
        isLoading = loadingSection.GroupsLoading;
    }
    return { isLoading };
}

const mapDispatchToProps = (dispatch) => ({
    onRefresh: () => dispatch(onRefresh()),
    refreshRotationGraph: () => dispatch(refreshRotationGraph())
});

export default connect(mapStateToProps, mapDispatchToProps)(ChartRefresh);