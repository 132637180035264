import _ from 'underscore';
import Serializable from "Serializable";

class CompareSettingHelper {

  constructor() {
  }

  updateTabSelection(viewSettings, header) {
    viewSettings.SelectedTabKey = header;
  }

  updateGroupsMenuBreadCrumList(viewSettings, breadCrumList) {
    if(breadCrumList.length>0){
    viewSettings.breadCrumbItems = _.map(breadCrumList, (item) => this.getSerizableData(item));
    viewSettings.itemName = breadCrumList[breadCrumList.length - 1].itemName;
    viewSettings.displayName = breadCrumList[breadCrumList.length - 1].displayName;
    viewSettings.rotationMenuType = breadCrumList[breadCrumList.length - 1].rotationMenuType;
    viewSettings.countryCode = breadCrumList[breadCrumList.length - 1].countryCode;
    viewSettings.osid = breadCrumList[breadCrumList.length - 1].osid;
    viewSettings.indCode = breadCrumList[breadCrumList.length - 1].indCode;
  }}

  updateNotVisibleOsids(viewSettings, notVisibleOsids) {
    viewSettings.notVisibleOsids = notVisibleOsids;
  }

  updateLastHighlightedOsids(viewSettings, lastHighlightedOsids) {
    viewSettings.lastHighlightedOsids = lastHighlightedOsids;
  }
  

  updateComparisionSymbolsMenuSettings(viewSettings, settings) {
    viewSettings.msids = settings.msids;
    viewSettings.scaleBased = settings.scaleBased;
    viewSettings.userEndDate = settings.userEndDate;
    viewSettings.userStartDate = settings.userStartDate;
    viewSettings.scaleType = settings.scaleType;
    viewSettings.periodicity = settings.periodicity;
    viewSettings.symbolRequest = settings.symbolRequest.map((symbol) => this.getSerizableObject(symbol));
    viewSettings.indexRequest = settings.indexRequest.map((symbol) => {
      let newObj = this.getSerizableObject(symbol);
      if (symbol.indexSymbolRequest) {
        newObj.indexSymbolRequest = symbol.indexSymbolRequest.map((indexSymbol) => this.getSerizableObject(indexSymbol));
      }
      return newObj;
    });
    viewSettings.sortType = settings.sortType;
    viewSettings.listName = settings.listName;
  }

  updateComparisionGroupsMenuSettings(viewSettings, settings) {
    viewSettings.listItems = settings.listItems.map((item) =>this.getSerizableListItem(item));
    viewSettings.scaleBased = settings.scaleBased;
    viewSettings.userEndDate = settings.userEndDate;
    viewSettings.userStartDate = settings.userStartDate;
    viewSettings.scaleType = settings.scaleType;
    viewSettings.periodicity = settings.periodicity;
    viewSettings.symbolRequest = settings.symbolRequest.map((symbol) => this.getSerizableObject(symbol));
    viewSettings.indexRequest = settings.indexRequest.map((symbol) => {
      let newObj = this.getSerizableObject(symbol);
      if (symbol.indexSymbolRequest) {
        newObj.indexSymbolRequest = symbol.indexSymbolRequest.map((indexSymbol) => this.getSerizableObject(indexSymbol));
      }
      return newObj;
    });
    viewSettings.sortType = settings.sortType;
  }

  updateSymbolsMenuRotationSettings(viewSettings,settings){
    viewSettings.symbolsList = settings.symbolsList;
    viewSettings.stockList = settings.stockList.map((item) =>this.getSerizableStockListItem(item));
    viewSettings.isTimeLinePlaying = settings.isTimeLinePlaying;
    viewSettings.isUptrendVisible = settings.isUptrendVisible;
    viewSettings.isDowntrendVisible = settings.isDowntrendVisible;
    // viewSettings.timeLineIndex = settings.timeLineIndex;
    viewSettings.listName = settings.listName;
    viewSettings.notVisibleOsids = settings.notVisibleOsids;
    viewSettings.lastHighlightedOsids = settings.lastHighlightedOsids;
    viewSettings.osidList = settings.osidList;
    viewSettings.countryCodeList = settings.countryCodeList;
    viewSettings.regionCodeList = settings.regionCodeList;
    viewSettings.countryCode = settings.countryCode;
    viewSettings.regionCode = settings.regionCode;
    viewSettings.countryAggArray = settings.countryAggArray.map((item) => this.getSerizableObject(item));
    viewSettings.regionAggArray = settings.regionAggArray.map((item) => this.getSerizableObject(item));
    viewSettings.benchMarkOption = settings.benchMarkOption;
  }

  getSerizableStockListItem(listItem){
    let serializedObj = new Serializable();
    serializedObj.itemName = listItem.itemName;
    serializedObj.displayName = listItem.displayName;
    serializedObj.osid = listItem.osid;
    serializedObj.msid = listItem.msid ;
    serializedObj.countryCode = listItem.countryCode;
    serializedObj.regionCode = listItem.regionCode;
    serializedObj.itemType =  listItem.itemType;
    serializedObj.avol = listItem.avol;
    serializedObj.mktCp = listItem.mktCp ;
    serializedObj.symbol = listItem.symbol;
    serializedObj.localSymbol = listItem.localSymbol ;
    serializedObj.underlyingSymbol = listItem.underlyingSymbol;
    serializedObj.isFlagged = listItem.isFlagged ;
    serializedObj.instrumentId = listItem.instrumentId ;
    serializedObj.isHighlighted = listItem.isHighlighted;
    serializedObj.isVisible = listItem.isVisible;
    serializedObj.isSelected = listItem.isSelected;
    serializedObj.ratingData = Object.assign(new Serializable, { ...listItem.ratingData });
    serializedObj.isLabelHighlighted = listItem.isLabelHighlighted;
    serializedObj.itemColor = listItem.itemColor;
    serializedObj.sectorOrder = listItem.sectorOrder;
    return serializedObj;
  }

  updateGroupsMenuRotationSettings(viewSettings,settings){
    viewSettings.isTimeLinePlaying = settings.isTimeLinePlaying;
    viewSettings.isUptrendVisible = settings.isUptrendVisible;
    viewSettings.isDowntrendVisible = settings.isDowntrendVisible;
    viewSettings.timeLineIndex = settings.timeLineIndex;
    viewSettings.notVisibleOsids = settings.notVisibleOsids;
    viewSettings.lastHighlightedOsids = settings.lastHighlightedOsids;
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

  getSerizableData(breadCrumObj) {
    let rotationMenuObj = new Serializable();
    rotationMenuObj.itemName = breadCrumObj.itemName;
    rotationMenuObj.rotationMenuType = breadCrumObj.rotationMenuType;
    rotationMenuObj.countryCode = breadCrumObj.countryCode;
    rotationMenuObj.osid = breadCrumObj.osid;
    rotationMenuObj.indCode = breadCrumObj.indCode;
    rotationMenuObj.displayName = breadCrumObj.displayName;
    return rotationMenuObj;
  }

  getSerizableListItem(listItem){
    let serializedObj = new Serializable();
    serializedObj.countryCode = listItem.countryCode;
    serializedObj.displayName = listItem.displayName;
    serializedObj.isHighlighted = listItem.isHighlighted;
    serializedObj.isLabelHighlighted = listItem.isLabelHighlighted;
    serializedObj.isSelected = listItem.isSelected;
    serializedObj.isVisible = listItem.isVisible;
    serializedObj.itemColor = listItem.itemColor;
    serializedObj.itemName = listItem.itemName;
    serializedObj.itemType =  listItem.itemType;
    serializedObj.osid = listItem.osid;
    serializedObj.sectorOrder = listItem.sectorOrder;
    serializedObj.symbol = listItem.symbol;
    serializedObj.underlyingSymbol = listItem.underlyingSymbol;
    return serializedObj;
  }
  

  updateLiqFilterData(viewSettings, states) {
    let liqFilter = viewSettings.liqFilter;
    liqFilter.MktCapLowSliderRangeValueIndex = states.mktCapFilterViewModel.lowSliderRangeValueIndex;
    liqFilter.MktCapHighSliderRangeValueIndex = states.mktCapFilterViewModel.highSliderRangeValueIndex;
    liqFilter.MktCapLowValue = Number(states.mktCapFilterViewModel.lowRangeValue);
    liqFilter.MktCapHighValue = Number(states.mktCapFilterViewModel.highRangeValue);

    liqFilter.AvolFilterLowSliderRangeValueIndex = states.avolFilterViewModel.lowSliderRangeValueIndex;
    liqFilter.AvolFilterHighSliderRangeValueIndex = states.avolFilterViewModel.highSliderRangeValueIndex;
    liqFilter.AvolFilterLowValue = Number(states.avolFilterViewModel.lowRangeValue);
    liqFilter.AvolFilterHighValue = Number(states.avolFilterViewModel.highRangeValue);

    liqFilter.RatingFilter1LowSliderRangeValueIndex = states.ratingFilterTypeOptions1ViewModel.lowSliderRangeValueIndex;
    liqFilter.RatingFilter1HighSliderRangeValueIndex = states.ratingFilterTypeOptions1ViewModel.highSliderRangeValueIndex;

    liqFilter.RatingFilter2LowSliderRangeValueIndex = states.ratingFilterTypeOptions2ViewModel.lowSliderRangeValueIndex;
    liqFilter.RatingFilter2HighSliderRangeValueIndex = states.ratingFilterTypeOptions2ViewModel.highSliderRangeValueIndex;

    liqFilter.RatingFilterType2 = states.ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType;

    liqFilter.RatingFilterType1 = states.ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType;

    liqFilter.IsGlobalRatingSelected = states.isGlobalRatingSelected;
    liqFilter.IsCountryRatingSelected = states.isCountryRatingSelected;
    liqFilter.IsFilterOn = states.isFilterOn;
    liqFilter.IsFilterManualOff = states.isFilterManualOff;
  }

  updateRotationGraphZoomValues(viewSettings, zoomValue, matrix, isAutoChecked) {
    if (viewSettings && viewSettings.rotationGraphZoomValues) {
      viewSettings.rotationGraphZoomValues.zoomValue = zoomValue;
      viewSettings.rotationGraphZoomValues.matrix = matrix;
      viewSettings.rotationGraphZoomValues.isAutoChecked = isAutoChecked;
    }
  }

}

const compareSettingHelper = new CompareSettingHelper();
export default compareSettingHelper;
