
import keyMirror from "keymirror";

export const DgHoldingsActionConstants = {
    ActionTypes: keyMirror({
        GET_DG_HOLDINGS_REPORT_DATA: null,
        DG_HOLDINGS_GET_REPORT_DATA_SUCCESS: null,
        DG_HOLDINGS_REPORT_CHANGE_TEXT: null,
        DG_HOLDINGS_REPORT_CHANGE_TEXT_SUCCESS: null,
        DG_HOLDINGS_COLUMN_RS_CHANGED: null,
        DG_HOLDINGS_COLUMN_PORT_CHANGED: null,
        DG_HOLDINGS_COLUMN_SHARES_CHANGED: null,
        DG_HOLDINGS_SHOW_LOADER: null,
        DG_HOLDINGS_GET_REPORT_MORE_DATA_SUCCESS: null,
        DG_HOLDINGS_TAB_CHANGE: null,
        DG_HOLDINGS_SUB_TAB_CHANGE: null,
        DG_HOLDINGS_TAB_CHANGE_SUCCESS: null,
        DG_HOLDINGS_SUB_TAB_CHANGE_SUCCESS: null,
        DG_HOLDINGS_COLUMN_SORT_CHANGED: null,
        DG_HOLDINGS_COLUMN_SORT_CHANGED_SUCCESS: null,
        DG_HOLDINGS_LIMIT_CHANGE: null,
        DG_HOLDINGS_LIMIT_CHANGE_SUCCESS: null,
        DG_HOLDINGS_SEARCH_TEXT_CHANGE: null,
        DG_HOLDINGS_TAB_RESIZE:null,
        DG_HOLDINGS_TAB_RESIZE_SUCCESS:null,
        DG_HOLDINGS_UPDATE_FLAG:null,
        DG_HOLDINGS_UPDATE_FLAG_SUCCESS:null,
        DG_HOLDINGS_LOAD_FLAG_INFO_SUCCESS:null,
        DG_HOLDINGS_CLEAR_DATA: null
        //DG_HOLDINGS_UPDATE_MINI_LIST_OWNERSHIP:null
    })
};
