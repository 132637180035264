import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as ColorConstants from "../../Constants/AppColorConstants";
import ThemeType from "../../Constants/ThemeTypeConstants";
import LangType from "../../Constants/LangType";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import UserInfoUtil from "UserInfoUtil";
import AppColorSettingHelper from "../../Stores/ConsoleWindow/Settings/Modules/AppColor/AppColorSettingHelper";

import AppColorAction, { onDarkPosColorChanged, onDarkNegColorChanged, onLightPosColorChanged, onLightNegColorChanged, onToggleColorPalette, onTogglePalettePanel } from "../../Actions/AppColorActions";
import SettingsStore from "SettingsStore";
import ColorSquare from "./ColorSquare";

class ColorPalette extends React.Component {
    constructor(props) {
        super(props);

        this.dispatchColorChanged = this.dispatchColorChanged.bind(this);
        this.getDefaultColor = this.getDefaultColor.bind(this);
        this.getIsDefaultSettings = this.getIsDefaultSettings.bind(this);
        this.getDefaultHoverColor = this.getDefaultHoverColor.bind(this);
        this.setIsDefaultSettings = this.setIsDefaultSettings.bind(this);
        this.populatePlate = this.populatePlate.bind(this);
        this.populateColorSquares = this.populateColorSquares.bind(this);
        this.renderDefaultPalette = this.renderDefaultPalette.bind(this);
        this.renderChinaPalette = this.renderChinaPalette.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);

        //Show color settings on basis of entitlement other than the displaying language.
        let lang = UserInfoUtil.hasChinaEntitlement() ? LangType.ZH_CN : LangType.RAY_DEFAULT;

        this.state = {
            selectedColor: "",
            selectedHoverColor: "",
            theme: ThemeType.Dark,
            isOpen: false,
            lang: lang,
            posOrNeg: ColorConstants.PosOrNeg.Positive,
            title: "Select Positive Color",
            isDefault: true,
        }
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.dismissPanel, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.dismissPanel, false);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.appColor.isOpen !== this.props.appColor.isOpen) {
            this.setState({
                isOpen: nextProps.appColor.isOpen,
                selectedColor: nextProps.appColor.selectedColor,
                theme: nextProps.appColor.theme,
                // lang:nextProps.applicationColor.lang,
                posOrNeg: nextProps.appColor.posOrNeg,
                title: nextProps.appColor.posOrNeg === ColorConstants.PosOrNeg.Positive ? LocalizationStore.getTranslatedData("set_pre_Select_Positive_Color", "Select Positive Color") : LocalizationStore.getTranslatedData("set_pre_Select_Negative_Color", "Select Negative Color"),
                isDefault: this.getIsDefaultSettings(nextProps.appColor.theme, nextProps.appColor.posOrNeg),
            })
        }
    }


    getIsDefaultSettings(theme, posOrNeg) {
        let consoleSetting = SettingsStore.getConsoleSettings();
        let appSettings = consoleSetting.AppColorSettings;
        switch (theme) {
            case ThemeType.Dark:
                if (posOrNeg === ColorConstants.PosOrNeg.Positive) {
                    return appSettings.isDefaultDarkPosColor === ColorConstants.UseDefaultColor.Yes ? true : false;
                }
                else {
                    return appSettings.isDefaultDarkNegColor === ColorConstants.UseDefaultColor.Yes ? true : false;
                }
            case ThemeType.Light:
                if (posOrNeg === ColorConstants.PosOrNeg.Positive) {
                    return appSettings.isDefaultLightPosColor === ColorConstants.UseDefaultColor.Yes ? true : false;
                }
                else {
                    return appSettings.isDefaultLightNegColor === ColorConstants.UseDefaultColor.Yes ? true : false;
                }
            default: 
                break;
        }
    }

    populatePlate () {

        let colorsPlates = [];
        for (let i = 0; i < ColorConstants.Colors.length; i++) {
            let colorsPlate = ColorConstants.Colors[i].slice(); //deepClone an array.
            //reverse from light to dark if it is in light theme.
            if (this.state.theme === ThemeType.Light) {
                colorsPlate.reverse();
            }
            colorsPlates.push(<div key={i} className="color-palette-column" style={{ float: "left" }}>
                {this.populateColorSquares(colorsPlate, i)}
            </div>)
        }
        return colorsPlates;
    }


    dispatchColorChanged(color, hoverColor) {
        switch (this.state.theme) {
            case ThemeType.Dark:
                if (this.state.posOrNeg === ColorConstants.PosOrNeg.Positive) {
                    this.props.onDarkPosColorChanged(color, hoverColor, false, this.state.isDefault);
                }
                else if (this.state.posOrNeg === ColorConstants.PosOrNeg.Negative) {
                    this.props.onDarkNegColorChanged(color, hoverColor, false, this.state.isDefault);
                }
                break;
            case ThemeType.Light:
                if (this.state.posOrNeg === ColorConstants.PosOrNeg.Positive) {
                    this.props.onLightPosColorChanged(color, hoverColor, false, this.state.isDefault);
                }
                else if (this.state.posOrNeg === ColorConstants.PosOrNeg.Negative) {
                    this.props.onLightNegColorChanged(color, hoverColor, false, this.state.isDefault);
                }
                break;
            default:
                break;
        }

        //inform FLUX module that the color has been changed.
        AppColorAction.customColorChanged(this.state.theme, color, hoverColor, this.state.posOrNeg);
    }

    onClicked() {
        if (this.state.isDefault) {
            this.dispatchColorChanged(this.getDefaultColor(), this.getDefaultHoverColor());
        }
        else {
            this.dispatchColorChanged(this.state.selectedColor, this.state.selectedHoverColor);

        }
    }

    onColorSelected(color, i, j) {
        this.setState({
            selectedColor: color,
            selectedHoverColor: ColorConstants.ColorsOnHover[i][j],
            isDefault: false,
        }, ()=>{
            this.setIsDefaultSettings(this.state.theme, this.state.posOrNeg, this.state.isDefault);
        });

    }

    populateColorSquares(colorSquares, row) {
        let results = []
        colorSquares.forEach((color, index) => {
            let isSelected = false;
            if (color === this.state.selectedColor) {
                isSelected = true;
            }

            let realPos = index;

            if (this.state.theme === ThemeType.Light) {
                realPos = (colorSquares.length - 1) - index;
            }

            results.push(
                <ColorSquare key={color}
                    color={color}
                    onColorSelected={this.onColorSelected.bind(this, color, row, realPos)}
                    isColorSelected={isSelected}
                />)
        })
        return results;
    }

    getDefaultColor() {
        let color = ColorConstants.DefaultColors.Blue;
        switch (this.state.posOrNeg) {
            case ColorConstants.PosOrNeg.Positive:
                color = AppColorSettingHelper.getDefaultPosColor(false, this.state.lang, ColorConstants.ColorCatalog.DataGraph, this.state.theme)
                //color = this.state.lang === LangType.RAY_DEFAULT ? ColorConstants.DefaultColors.Blue : ColorConstants.DefaultColors.Red;
                break;
            case ColorConstants.PosOrNeg.Negative:
                color = AppColorSettingHelper.getDefaultNegColor(false, this.state.lang, ColorConstants.ColorCatalog.DataGraph, this.state.theme)
                //color = this.state.lang === LangType.RAY_DEFAULT ? ColorConstants.DefaultColors.Magenta : ColorConstants.DefaultColors.Green;
                break;
            default:
                break;
        }
        return color;
    }

    getDefaultHoverColor() {
        let color = ColorConstants.DefaultHoverColors.Blue;
        switch (this.state.posOrNeg) {
            case ColorConstants.PosOrNeg.Positive:
                color = AppColorSettingHelper.getDefaultPosColor(true, this.state.lang)
                //color = this.state.lang === LangType.RAY_DEFAULT ? ColorConstants.DefaultHoverColors.Blue : ColorConstants.DefaultHoverColors.Red;
                break;
            case ColorConstants.PosOrNeg.Negative:
                color = AppColorSettingHelper.getDefaultNegColor(true, this.state.lang)
                //color = this.state.lang === LangType.RAY_DEFAULT ? ColorConstants.DefaultHoverColors.Magenta : ColorConstants.DefaultHoverColors.Green;
                break;
            default:
                break;
        }
        return color;
    }

    setIsDefaultSettings(theme, posOrNeg, value) {
        let isDefault = value ? ColorConstants.UseDefaultColor.Yes : ColorConstants.UseDefaultColor.No;
        let consoleSetting = SettingsStore.getConsoleSettings();
        let appSettings = consoleSetting.AppColorSettings;
        switch (theme) {
            case ThemeType.Dark:
                if (posOrNeg === ColorConstants.PosOrNeg.Positive) {
                    appSettings.isDefaultDarkPosColor = isDefault;
                }
                else {
                    appSettings.isDefaultDarkNegColor = isDefault;
                }
                break;
            case ThemeType.Light:
                if (posOrNeg === ColorConstants.PosOrNeg.Positive) {
                    appSettings.isDefaultLightPosColor = isDefault;
                }
                else {
                    appSettings.isDefaultLightNegColor = isDefault;
                }
                break;
            default:
                break;
        }

        SettingsStore.saveSettings();
    }

    handleChange(isDefault) {
        this.setState({
            isDefault: isDefault,
        }, () => {
            this.setIsDefaultSettings(this.state.theme, this.state.posOrNeg, this.state.isDefault);
        });
    }

    renderDefaultPalette() {
        let defaultColor = this.getDefaultColor();
        return (
            <div className="body">
                <div className="color-palette-hold">
                    <div className="holder">
                        <input id="defaultColor" type="radio" name="rbColor" checked={this.state.isDefault} onChange={this.handleChange.bind(this, true)} />
                        <label htmlFor="defaultColor">{LocalizationStore.getTranslatedData("set_pre_Default_Color", "Default Color")}</label>
                        <div className="palette-box">
                            <span className="palette-color-default" style={{ backgroundColor: defaultColor }}></span>
                        </div>
                    </div>
                    <div className="holder">
                        <input id="customColor" type="radio" name="rbColor" checked={!this.state.isDefault} onChange={this.handleChange.bind(this, false)} />
                        <label htmlFor="customColor">{LocalizationStore.getTranslatedData("set_pre_Custom_Color", "Custom Color")}</label>
                        <div className="palette-box">
                            <div className="palette-color-picker">{this.populatePlate()}</div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }

    dismissPanel(e) {
        //close this panel if it is out of this component is clicked. 
        if (this.nodes && !this.nodes.contains(e.target)) {

            //click this target will turn on this panel.
            let btn = e.target.getAttribute("name");
            if (this.state.isOpen === true && btn !== "colorSelectionRow") {
                this.props.onTogglePalettePanel(false);
            }
        }
    }

    renderChinaPalette() {
        return (
            <div className="body">
                <div className="color-palette-hold">
                    <div className="palette-box single">
                        <div className="palette-color-picker">{this.populatePlate()}</div>
                    </div>
                </div>
                
            </div>
        )
    }

    render() {
        let className = this.state.isOpen ? "color-palette fadeIn" : "color-palette fadeOut";
        return (
            <div className={className} ref={(node) => this.nodes = node}>
                <div className="header">
                    {/* <span className="cap-header"> */}
                        {this.state.title}
                    {/* </span> */}
                </div>
                {this.state.lang === LangType.RAY_DEFAULT ? this.renderDefaultPalette() : this.renderChinaPalette()}

                <div className="footer">
                    <Button onClick={this.onClicked.bind(this)} className="btn btn-secondary" >{LocalizationStore.getTranslatedData("ok", "OK")}</Button>
                </div>
            </div>
        )


    }
}

function mapStateToProps(state) {
    return {
        appColor: state.appColor
    }
}
const actionCreators = { onDarkPosColorChanged, onDarkNegColorChanged, onLightNegColorChanged, onLightPosColorChanged, onToggleColorPalette, onTogglePalettePanel };
ColorPalette = connect(mapStateToProps, actionCreators)(ColorPalette);
export default ColorPalette;
