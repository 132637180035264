import AlertSourceType from '../../../../../Constants/AlertSourceType.js';
import { conditionAlertDelete } from '../../../../../Actions/AlertActions.js';
import { connect } from 'react-redux';
import DGContextMenu from "../../../../../RayCustomControls/ContextMenu/DGContextMenu.jsx";
import PortalContainer from 'PortalContainer';
import React from 'react';
import { closeContextMenu, onContextMenuClick, toggleEpsAnimationDisplayed } from "../../../../../Actions/PricePanelActions";


const contextMenu = (props) => {
    const { showContextMenu, contextMenuXPos, contextMenuYPos, selectedConextMenuOption, isPricePanelDataReady } = props;
    return (showContextMenu && isPricePanelDataReady ? <PortalContainer>
        <DGContextMenu contextMenu={selectedConextMenuOption}
            contextMenuXPos={contextMenuXPos}
            contextMenuYPos={contextMenuYPos}
            closeContextMenu={props.closeContextMenu}
            onContextMenuClick={props.onContextMenuClick}
            showAnimationMessageDialog={props.toggleEpsAnimationDisplayed}
            // animationClickAvailable={this.state.isAnimationMessageDialogAvailable}
            sourceTypeId={AlertSourceType.Stock}
            onDelete={props.conditionAlertDelete}
            isActiveAlerts={props.isActiveAlerts}
        />
    </PortalContainer> : null);
};
const mapStateToProps = (state) => {
    const { showContextMenu, contextMenuXPos, contextMenuYPos, selectedConextMenuOption } = state.DatagraphReducers.ContextMenuReducer;
    const { isPricePanelDataReady } = state.DatagraphReducers.DataGraphReducer;
    const { isActiveAlerts } = state.alertReducer.AlertDialogReducer;
    return ({ showContextMenu, contextMenuXPos, contextMenuYPos, selectedConextMenuOption, isActiveAlerts, isPricePanelDataReady });
};
const mapDispatchToProps = (dispatch) => ({
    closeContextMenu: () => dispatch(closeContextMenu()),
    onContextMenuClick: (item) => dispatch(onContextMenuClick(item)),
    toggleEpsAnimationDisplayed: (item) => dispatch(toggleEpsAnimationDisplayed(item)),
    conditionAlertDelete: (item)=> dispatch(conditionAlertDelete(item))
});
export default connect(mapStateToProps, mapDispatchToProps)(contextMenu);