import AppDispatcher from "AppDispatcher";
import { LoginConstants } from "../../Constants/LoginConstants";
import { EventEmitter } from "events";
import Cookies from "universal-cookie";
import UserInfoUtil from "UserInfoUtil";
import LoginApi from "../../ServiceApi/LoginApi.js";
import UserApi from "../../ServiceApi/Apis/UserApi.js";
import LogoutApi from "../../ServiceApi/Apis/LogoutApi";
import TimeTrackingWindow from "TimeTrackingWindow";
import SettingsActions from "../../Actions/SettingsActions";
import PanarayDefault from "../../ServiceApi/RequestHelper/PanarayDefault";
import LocalizationActions from "../../Actions/LocalizationActions";
import { dispatch } from "../../Redux/dispatch";
import { setCurrencyData, setESCurrencyData } from "../../Actions/CurrencyActions";

const history = require("history").createHashHistory();

const CHANGE_EVENT = "change";
let _state = { returnUrl: undefined,
               isLoginHasErr: false,
               loginErrType: 0, action: undefined, SSOEntitle: false
             };
let _windowObjectReference = null;
const cookies = new Cookies();

class LoginStoreClass extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        this.ssoLogin = false;
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    emitChange() {
        if (this.ssoLogin) {
            history.push("/");
            return;
        }
        this.emit(CHANGE_EVENT);
    }

    isUserLoggedIn() {
        let loggedIn = sessionStorage.getItem("_pUser") && cookies.get("_pUser") ? true : false;
        return loggedIn;
    }

    closeWindow() {
        if (_windowObjectReference) {
            _windowObjectReference.close();
        }
    }

    getUserStatusInfo(userData) {
        this.closeWindow();
        const iHeight = 600;
        const iWidth = 1000;
        const iTop = (window.screen.height - 30 - iHeight) / 2;
        const iLeft = (window.screen.width - 10 - iWidth) / 2;
        const userEmail = userData.userEmail;
        const companyName = userData.companyName;
        _state.userEmail = userEmail;
        _state.ssoErrorMessage = null;
        UserApi.getAuthRouteRequest(userEmail, companyName).then((result) => {
            if(result && result.responseHeader && !result.responseHeader.error){
                _state.HaveGetAuthRouteErr= false;
                const isUserAccountExist = result.isUserAccountExist;
                _state.SSOEntitle = result.endPoint ? true : false;
                if(!isUserAccountExist){
                    _state.SSOEntitle = true;
                    _state.HaveGetAuthRouteErr = true;
                    _state.GetAuthRouteErrMsg = `User does not exist`;
                }
                else if (result.endPoint) {
                    _windowObjectReference = window.open(
                        result.endPoint,
                        '_blank',
                        `height=${iHeight},width=${iWidth},top=${iTop},left=${iLeft},toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no`);
                }
            }
            else{
                _state.HaveGetAuthRouteErr = true;
                _state.SSOEntitle = true;
            }
            this.emit(CHANGE_EVENT);
        }).catch((e) => {
            console.error(e && e.status);
            _state.SSOEntitle = true;
            _state.HaveGetAuthRouteErr = true;
            _state.GetAuthRouteErrMsg = `Network connection is not available.`;
            this.emit(CHANGE_EVENT);
        });   
    }

    validateUser(logindata) {
        this.ssoLogin = false;
        const email = logindata.email;
        const password = logindata.password;
        //  let rememberme = logindata.rememberme;
        let self = this;
        let loginapi = new LoginApi();
        _state.isLoginHasErr = false;
        _state.loginErrType = "0";
        loginapi.validateUser(logindata).then((response) => {
            const result = response.responseData;
            let loginErrType =  response.signinRepsondType;
            let userData = result.split("+");
            _state.isLoginHasErr = false;
            if(userData.length > 1 && userData[0] === "LoginErrType"){
                _state.isLoginHasErr = true;
            }

                if (_state.isLoginHasErr) {
                    loginErrType = userData.length > 1 ? userData[1] : null;
                    _state.loginErrType = loginErrType;
                    this.emit(CHANGE_EVENT);
                }
                else {
                    TimeTrackingWindow.initiateLoginTimeTracker();
                    let userId = result.substr(0, result.indexOf("+"));
                    _state.loginErrType = loginErrType;
                    let entitlements = userData[1].split(",");
                    entitlements = entitlements.map((item) => parseInt(item));
                    let user = {};
                    user.userId = userId;
                    user.userEmail = email;
                    user.entitlements = entitlements;
                    user.userPassword = password;
                    user.providerId = userData[userData.length - 2];
                    this.setUserSession(result);
                    UserInfoUtil.setUserInfo(user);
                    this.setUserCookie(userData[userData.length - 1]);
                    //if (rememberme) this.setLoginRememberMeCookie(userId);
                    PanarayDefault.getDefaultSymbolData(true);

                    Promise.all([
                        UserApi.UserInit(),
                        //ListExplorerStore.getListExplorerData()
                    ]).then(([userInitResult, userRoleResult]) => {
                        try {
                            if (userInitResult !== undefined) {
                                //store news
                                if (userInitResult.userInitData) {
                                    let user = UserInfoUtil.getUserInfo();
                                    if (user) {
                                        user.userInfo = userInitResult.userInitData.userInfo;
                                        user.newsSources = userInitResult.userInitData.newsSources;
                                        user.name = userInitResult.userInitData.userInfo.userName;
                                        user.company = userInitResult.userInitData.userInfo.company;
                                        user.SnPSymbols = userInitResult.userInitData.SnPSymbols;
                                        user.regionCountries = userInitResult.userInitData.countries;
                                        user.entitleIdxUserList = userInitResult.userInitData.entitleIdxUserList;
                                        user.entitleIdxFullList = userInitResult.userInitData.entitleIdxFullList;
                                        UserInfoUtil.setUserInfo(user);
                                        dispatch(setCurrencyData(userInitResult.userInitData.currencies));
                                        dispatch(setESCurrencyData(userInitResult.userInitData.esCurrencies));
                                        Promise.resolve().then(
                                            () => LocalizationActions.getLangResource()
                                        ).then(
                                            () => {
                                                SettingsActions.getUserSettings(userInitResult.workspacedata, true);
                                                if(!SettingsActions.isNavSymbol){
                                                    this.emit(CHANGE_EVENT);
                                                }
                                            }
                                        );
                                        
                                    }
                                }
                            }
                        }
                        catch (e) {
                            console.error(e);
                        }
                    }).catch((e) => {
                        console.error(e);
                        //alert("Third party cookies appear to be disabled in your browser.");
                        history.push("/Logout");
                        this.emit(CHANGE_EVENT);
                    })
                }
        }).catch(() => {
            this.emit(CHANGE_EVENT);
        });
    }

    getUserInitData(data){
        this.ssoLogin = true;
        const result = data.cookie;
        const userData = data.userData;
        this.setUserSession(result);
        this.setUserCookie(userData[userData.length - 1]);
        PanarayDefault.getDefaultSymbolData(true);

        Promise.all([
            UserApi.UserInit(),
            //ListExplorerStore.getListExplorerData()
        ]).then(([userInitResult, userRoleResult]) => {
            try {

                if (userInitResult !== undefined) {
                    //store news
                    if (userInitResult.userInitData) {
                        let user = UserInfoUtil.getUserInfo();
                        if (user) {
                            user.userInfo = userInitResult.userInitData.userInfo;
                            user.newsSources = userInitResult.userInitData.newsSources;
                            user.name = userInitResult.userInitData.userInfo.userName;
                            user.company = userInitResult.userInitData.userInfo.company;
                            user.SnPSymbols = userInitResult.userInitData.SnPSymbols;
                            user.regionCountries = userInitResult.userInitData.countries;
                            user.entitleIdxUserList = userInitResult.userInitData.entitleIdxUserList;
                            user.entitleIdxFullList = userInitResult.userInitData.entitleIdxFullList;
                            UserInfoUtil.setUserInfo(user);
                            Promise.resolve().then(
                                () => LocalizationActions.getLangResource()
                            ).then(
                                () => {
                                    SettingsActions.getUserSettings(userInitResult.workspacedata, true);
                                    if(!SettingsActions.isNavSymbol){
                                        this.emit(CHANGE_EVENT);
                                    }
                                }
                            );
                        }
                    }
                }
            }
            catch (e) {
                console.error(e);
            }
        })
        .catch((e) => {
            console.error(e);
            //alert("Third party cookies appear to be disabled in your browser.");
            history.push("/Logout");
            this.emit(CHANGE_EVENT);
        })
    }

    setUserSession(value) {
        localStorage.setItem("_pUserLocal", value);
        sessionStorage.setItem("_pUser", value);
        const brokerReportVisitedHeadlines = JSON.parse(localStorage.getItem("brokerReportVisitedHeadlines"));
        if (brokerReportVisitedHeadlines === null) {
            localStorage.setItem("brokerReportVisitedHeadlines", JSON.stringify([]));
        }
    }

    setUserCookie(value) {
        try {
            let date = new Date();
            const env = process.env.BRANCH_ENV;
            date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
            if( window.location.hostname === 'localhost' || env == 'dev ')
                cookies.set("_pUser", value, { path: "/", expires: date });
            else
                cookies.set("_pUser", value, { path: "/", expires: date, sameSite: "none", secure: true  });

        } catch (e) {
            alert("Third party cookies appear to be disabled in your browser.");
        }
        finally {
            return true;
        }
    }


    setLoginRememberMeCookie(userId) {
        let date = new Date();
        date.setTime(new Date().getTime() + (365 * 24 * 60 * 60 * 1000));
        cookies.set("_pRemember", userId, { path: "/", expires: date });
        return true;
    }

    getState() {
        if(_state){
            return _state;
        }
        else{
            _state = { returnUrl: undefined, action: undefined, SSOEntitle: false };
            return _state;
        }
    }

    logOut() {
        if (cookies.get("_pUser")) {
            let logout = new LogoutApi();
            const user = UserInfoUtil.getUserInfo();
            const userEmail = user ? user.userEmail : null;
            const providerId = user ? user.providerId : null;
            let logOutResult = logout.getdata(userEmail,providerId);
            logOutResult.then(() => {
            })
                .catch((err) => {
                    if (err.status == 401) {
                        //  window.location = "/"; 
                    }
                });


            cookies.remove('_pUser', { path: '/' });
        }
    }

    onSSOUserLoginFailed(errorMessage){
        let state = this.getState();
        state.action = LoginConstants.ActionTypes.ON_SSO_LOGIN_FAILED
        state.isSSOFailed = true;
        state.ssoErrorMessage = errorMessage;
        this.emit(CHANGE_EVENT);
    }

    dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.data;
        switch (action.actionType) {
            case LoginConstants.ActionTypes.LOGIN:
                this.logOut();
                _state.action = null;
                window.setTimeout(() => { this.validateUser(data) }, 0);
                break;
            case LoginConstants.ActionTypes.GET_AUTHROUTE:
                _state.action = LoginConstants.ActionTypes.GET_AUTHROUTE;
                this.getUserStatusInfo(data);
                break;
            case LoginConstants.ActionTypes.GET_USERINIT:
                this.getUserInitData(data);
                break;
            default:
                return true;
        }
        return true;
    }

}

const LoginStore = new LoginStoreClass();
export default LoginStore;
