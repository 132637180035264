const { ActionTypes } = RiPanelConstants;
import { getAllocationState } from "../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors.js";
import GraphApi from "../../../ServiceApi/Apis/GraphApi.js";
import LocalizationStore from "LocalizationStore";
import MiniListHelper from "MiniListHelper";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import RotationArithmeticScale from "../../../Utils/Scales/RotationArithmeticScale.js"
import ScalesHelper from "../../../Utils/Scales/ScalesHelper.js";
import StringUtil from "StringUtil";
import SymbolType from "SymbolType";
import { call, put, select, takeLatest } from "redux-saga/effects";

let isNoDataAvailable;
function* getAllocationInfo({ symbolInfo }) {
    try {
        isNoDataAvailable = false;
        const { sectorWgtList } = yield select(getAllocationState);
        if(sectorWgtList.length === 0){
            const symbolType = symbolInfo.SymTypeEnum;
            const symbol = symbolInfo ? symbolInfo.Symbol : '';
            const isMiniListPlay = symbolInfo.IsMiniListPlay;

            if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)) {
                const isSymbolFund = symbolType === SymbolType.FUND
                const allocationResponse = yield call(isSymbolFund ? GraphApi.GetAllocationDataRequest : GraphApi.GetIndexAllocationDataRequest, symbol);
                const sectorWgtList = populateData(allocationResponse, isSymbolFund);
                const localeFormatAsOfDate = StringUtil.formatDate(allocationResponse.asOfDate, "MM/DD/YYYY", true);
                const asOfDate = LocalizationStore.getTranslatedData("ri_iid119", "As of ") + localeFormatAsOfDate;
                const scale = yield call(getBarScale, sectorWgtList);
                let loading = false;
                // if (sectorWgtList === null || sectorWgtList.length === 0) {
                //     loading = true;
                // }
                yield put({
                    type: ActionTypes.ALLOCATION_INFO_SET_DATA,
                    newState: { sectorWgtList, asOfDate, scale, loading, symbolType, isSymbolFund, isNoDataAvailable }
                })
            }
            
        }
    } catch (error) {
        console.log(`Error occurs in  RiPanelAllocationInfo.js, getAllocationInfo ${error}`);
    }
}
function populateData(allocationResponse, isSymbolFund) {
    try {
        if(isSymbolFund){
            if (allocationResponse === null || allocationResponse.sectorallocationData.length === 0) {
                isNoDataAvailable = true;
                return [];
            }else{
                let sectorMaxValue = allocationResponse.sectorallocationData[0].sectorPercentage;
                let sectorMinValue = allocationResponse.sectorallocationData[0].sectorPercentage;
                const sectorWgtList = allocationResponse.sectorallocationData.map((sectorWeight) => {
                    if(sectorMaxValue < sectorWeight.sectorPercentage){
                        sectorMaxValue = sectorWeight.sectorPercentage;
                    }
                    else if(sectorMinValue > sectorWeight.sectorPercentage){
                        sectorMinValue = sectorWeight.sectorPercentage
                    }
                    return {
                        legend: sectorWeight.sectorName,
                        highValue: sectorWeight.sectorPercentage,
                        lowValue: 0.0
                    };
                });
                if (sectorMaxValue === 0 && sectorMinValue === 0) {
                    isNoDataAvailable = true;
                }
                return sectorWgtList
            }
    
        }else{
            if (allocationResponse === null || allocationResponse.sectorAllocationData.length === 0) {
                isNoDataAvailable = true;
                return [];
            }else{
                let sectorMaxValue = allocationResponse.sectorAllocationData[0].sectMktValWeight;
                let sectorMinValue = allocationResponse.sectorAllocationData[0].sectMktValWeight;
                const sectorWgtList = allocationResponse.sectorAllocationData.map((sectorWeight) => {
                    if(sectorMaxValue < sectorWeight.sectMktValWeight){
                        sectorMaxValue = sectorWeight.sectMktValWeight;
                    }
                    else if(sectorMinValue > sectorWeight.sectMktValWeight){
                        sectorMinValue = sectorWeight.sectMktValWeight
                    }
                    return {
                        legend: sectorWeight.sectName,
                        highValue: sectorWeight.sectMktValWeight,
                        lowValue: 0.0
                    };
                });
                if (sectorMaxValue === 0 && sectorMinValue === 0) {
                    isNoDataAvailable = true;
                }
                return sectorWgtList
            }
        }

    } catch (error) {
        console.log(`Error occurs in  RiPanelAllocationInfo.js, populateSectorData ${error}`)
    }
}
function getBarScale(sectorWgtList) {
    try {
        const legendWidth = 95, paddingScrollBarWidth = 18;
        const epsilon = 4.94065645841247e-324;
        const itemSource = sectorWgtList;
        if (itemSource.length <= 0) { return; }
        const maxValue = itemSource.map((item) => item.highValue).reduce((prev, curr) => Math.max(prev, curr));
        const minValue = itemSource.map((item) => item.lowValue).reduce((prev, curr) => Math.min(prev, curr));
        if (Math.abs(maxValue - minValue) < epsilon) { return; }
        const boxWidth = 298 - (legendWidth + paddingScrollBarWidth);
        const scale = new RotationArithmeticScale();
        const interval = ScalesHelper.getInterval((minValue), (maxValue), boxWidth, 10);
        scale.initXScale(maxValue, minValue, interval, boxWidth);
        return scale
    } catch (error) {
        console.log(`Error occurs in  RiPanelAllocationInfo.js, getBarChart ${error}`)
    }
}

export function* watcherGetAllocationInfo() {
    yield takeLatest(ActionTypes.GET_ALLOCATION_INFO, getAllocationInfo)
}