
import { AlertSecondaryInfoTranslateHelper } from "../../../../../Utils/TranslateHelper";
import { connect } from "react-redux";
import ErrorBoundary from "ErrorBoundary";
import React from "react";
import { showContextMenu } from "../../../../../Actions/PricePanelActions";
import { toggleShowActive, toggleShowAlerts } from "../../../../../Actions/AlertActions.js";

class RIPanelAlertSecondaryInfo extends React.Component {
    render() {
        return (
            <ErrorBoundary>
                <div className="alert-icon-handler">
                    <div className="alertInfo">
                        <div className="alert-acts">
                            <div className="data-info">
                                <span className="cheq-addalert">
                                    <input type="checkbox" id="riAlertCheck" name="ShowAlerts" checked={this.props.IsShowAlertsIcon} onChange={this.props.toggleShowAlerts} />
                                    <label htmlFor="riAlertCheck">{AlertSecondaryInfoTranslateHelper.ALERT_ICON_ON_CHART}</label>
                                </span>
                                <span className="sign-addalert"><span id="addAlertsIcon" className="icn-bell" onClick={this.props.showContextMenu}></span></span>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>


                        <div className="alertTabs">
                            <ul>
                                <li className={this.props.showActive ? "" : "active"}>
                                    <a onClick={() => this.props.toggleShowActive(false)}>{AlertSecondaryInfoTranslateHelper.TRIGGERED}</a>
                                </li>
                                <li className={this.props.showActive ? "active" : ""}>
                                    <a onClick={() => this.props.toggleShowActive(true)}>{AlertSecondaryInfoTranslateHelper.ACTIVE}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = ({ DatagraphReducers, alertReducer }) => {
    const { SymbolInfo } = DatagraphReducers.DataGraphReducer;
    const { showActive, IsShowAlertsIcon } = alertReducer.DataGraphAlertReducer
    return { SymbolInfo, showActive, IsShowAlertsIcon }
}

const mapDispatchToProps = (dispatch) => ({
    toggleShowActive: (showActive) => dispatch(toggleShowActive(showActive)),
    showContextMenu: (e)=> dispatch(showContextMenu({clientX: e.clientX - 150, clientY: e.clientY - 100 }, 0)),
    toggleShowAlerts: ()=> dispatch(toggleShowAlerts())
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RIPanelAlertSecondaryInfo)