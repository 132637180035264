import React, { useState } from 'react';
import { updateStorewithSharedData } from "../../../Actions/ShareAction";
import LocalizationStore from 'LocalizationStore';
import PortalContainer from 'PortalContainer';

const RecipientData = ({ rowData, index, sharedAction }) => {
    const [tooltip, setTooltip] = useState({isShowTooltip:true, style: {}, tooltipText:''})

    const showTooltip = (e) => {
        const style = { left: e.clientX, top: e.clientY + 10, zIndex:1051 };
        setTooltip({ style, isShowTooltip: true, tooltipText: `${e.target.innerText}` });
    }

    const hideTooltip = () => {
        setTooltip({isShowTooltip: false, style: {}, tooltipText: "" });
    }

    const showSharedUserData = (recipientId, recipientName) => (event) => {
        const sharedData = {
            showSharedDialog: true,
            id: recipientId,
            name: recipientName,
            action: sharedAction,
            IncludeAllUsers: 0,
            ntid: undefined
        }
        updateStorewithSharedData(sharedData);
    }

    let displayName = rowData.numLists === 0
        ? ''
        : rowData.numLists === 1
            ? `1 ${LocalizationStore.getTranslatedData("LM_TP_list"," list")}`
            : `${rowData.numLists} ${LocalizationStore.getTranslatedData("LM_TP_lists","lists")}`

    displayName = rowData.numColSets === 0
        ? displayName && `${displayName}`
        : rowData.numColSets === 1
            ? displayName ? `${displayName}, 1 ${LocalizationStore.getTranslatedData("LM_TP_colset","column set")}` : `1 ${LocalizationStore.getTranslatedData("LM_TP_colset","column set")}`
            : displayName ? `${displayName}, ${rowData.numColSets} ${LocalizationStore.getTranslatedData("LM_TP_colsets","column sets")}` : `${rowData.numColSets} ${LocalizationStore.getTranslatedData("LM_TP_colsets","column sets")}`

    displayName = rowData.numAnnoLayers === 0
        ? displayName && `${displayName}`
        : rowData.numAnnoLayers === 1
        ? displayName ? `${displayName}, 1 ${LocalizationStore.getTranslatedData("LM_TP_annotationLayer"," annotation layer")}` : `1 ${LocalizationStore.getTranslatedData("LM_TP_annotationLayer"," annotation layer")}`
            : displayName ? `${displayName}, ${rowData.numAnnoLayers} ${LocalizationStore.getTranslatedData("LM_TP_annotationLayers"," annotation layers")}` : `${rowData.numAnnoLayers} ${LocalizationStore.getTranslatedData("LM_TP_annotationLayers"," annotation layers")}`
    displayName = rowData.numExternal === 0
        ? displayName && `${displayName}`
        : rowData.numExternal === 1
        ? displayName ? `${displayName}, 1 ${" external data set"}` : `1 ${" external data sets"}`
            : displayName ? `${displayName}, ${rowData.numExternal} ${" external data sets"}` : `${rowData.numExternal} ${" external data sets"}`


    return (
        <tr key={index} onClick={showSharedUserData(rowData.recipientId, rowData.recipientName)}>
            <td data-test-id="recipient-data">
                <span className="value-name">{rowData.recipientName}</span>
            </td>
            <td>
                <span className="value-count" onMouseEnter={(e) => showTooltip(e)} onMouseOut={hideTooltip} onBlur={hideTooltip}>
                    {displayName}
                    {tooltip.isShowTooltip && <PortalContainer>
                            <div className="annotation-layer-tooltip" style={ tooltip.style }>{tooltip.tooltipText}</div>
                        </PortalContainer>}
                </span>
            </td>
        </tr>
    );
}

export default RecipientData;