import { PriceChartConstants } from "../Constants/PriceChartConstants.js";
const { ActionTypes } = PriceChartConstants;

export const priceMenuItemClicked = (item) => ({
    type: ActionTypes.PRICE_MENU_ITEM_CLICK,
    item
})

export const openMALineSettingDialog = (item) =>({
    type: ActionTypes.OPEN_MA_LINE_EDIT_DIALOG,
    item
})
export const addMALine = (item) =>({
    type: ActionTypes.ADD_MA_LINE,
    item
})
export const deleteMALine = (item) =>({
    type: ActionTypes.DELETE_MA_LINE,
    item
})
export const saveMALineSettingDialog = () =>({
    type: ActionTypes.SAVE_MA_LINE_EDIT_DIALOG,
})
export const cancelMALineSettingDialog = () =>({
    type: ActionTypes.CANCEL_MA_LINE_EDIT_DIALOG,
})
export const changeMALineWeight = (lineThickness) =>({
    type: ActionTypes.CHANGE_MA_LINE_WEIGHT,
    lineThickness
})
export const changeMALineColor = (lineColor) =>({
    type: ActionTypes.CHANGE_MA_LINE_COLOR,
    lineColor
})
export const changeMALineLength = (maLength) =>({
    type: ActionTypes.CHANGE_MA_LINE_LENGTH,
    maLength
})
export const setMALineError = () =>({
    type: ActionTypes.SET_MA_LINE_ERROR,
})
export const toggleMAValidationDialog = (isMAValidationDialogOpen) =>({
    type: ActionTypes.TOGGLE_MA_VALIDATION_DIALOG,
    isMAValidationDialogOpen
})
export const changeMALineType = (maType) =>({
    type: ActionTypes.CHANGE_MA_LINE_TYPE,
    maType
})

export const openRSLineDialog = (item)=>({
    type: ActionTypes.OPEN_RS_LINE_DIALOG,
    item
})
export const cancelRSLineDialogSetting = ()=>({
    type: ActionTypes.CANCEL_RS_DIALOG_SETTING,
})
export const handleRSLineDialogCountryChange = (selectedCountry)=>({
    type: ActionTypes.HANDLE_RS_DIALOG_COUNTRY_CHANGE,
    selectedCountry
})
export const handleRSLineDialogRegionChange = (selectedRegion)=>({
    type: ActionTypes.HANDLE_RS_DIALOG_REGION_CHANGE,
    selectedRegion
})
export const handleRSLineDialogRGroupChange = (selectedGroup)=>({
    type: ActionTypes.HANDLE_RS_DIALOG_GROUP_CHANGE,
    selectedGroup
})
export const handleChangeRSValueType = (valueType)=>({
    type: ActionTypes.HANDLE_RS_VALUE_TYPE_CHANGE,
    valueType
})
export const handleChangeRSSymbol = (symbolName)=>({
    type: ActionTypes.HANDLE_RS_SYMBOL_CHANGE,
    symbolName
})
export const saveRSLineDialogSetting = ()=>({
    type: ActionTypes.SAVE_RS_DIALOG_SETTING,
})
export const toggleShowRSRank = ()=>({
    type: ActionTypes.TOGGLE_SHOW_RS_RANK
})

export const toggleShowRSGrade = ()=>({
    type: ActionTypes.TOGGLE_SHOW_RS_GRADE
})
export const toggleShowRSMovingAverage = ()=>({
    type: ActionTypes.TOGGLE_SHOW_RS_MOVING_AVERAGE
})
export const toggleRSMovingAverageLineType = (maLineType)=>({
    type: ActionTypes.TOGGLE_RS_MOVING_AVERAGE_LINE_TYPE,
    maLineType
})
export const updateRSLineColor = (lineType, lineColor)=>({
    type: ActionTypes.UPDATE_RS_LINE_COLOR,
    lineType, lineColor
})
export const updateRSLineThickness = (lineType, lineThickness)=>({
    type: ActionTypes.UPDATE_RS_LINE_THICKNESS,
    lineType, lineThickness
})
export const updateRSLength = (maLineLength)=>({
    type: ActionTypes.UPDATE_RS_LENGTH,
    maLineLength
})
export const updateRSLengthError = (errorMessage)=>({
    type: ActionTypes.UPDATE_RS_LENGTH_ERROR,
    errorMessage
})
export const updateRSSymbolError = (errorMessage)=>({
    type: ActionTypes.UPDATE_RS_SYMBOL_ERROR,
    errorMessage
})
export const updateSymbolError = ()=>({
    type: ActionTypes.UPDATE_SYMBOL_ERROR,
})

export const switchFundametalLineLabel = (selectedPerShare, isSaveSetting = true) => ({
    type: ActionTypes.SWITCH_FUNDAMENTAL_LINE_LABEL,
    selectedPerShare, isSaveSetting
})
export const updateFundamentalLineSelectPerShare = (selectedPerShare, isSaveSetting = true) => ({
    type: ActionTypes.UPDATE_SELECT_PER_SHARE,
    selectedPerShare, isSaveSetting
})

export const showFundamentalLineDialog = (item)=> ({
    type: ActionTypes.SHOW_FUNDAMENTAL_EDIT_DIALOG,
    item
})
export const saveFundamentalLineDialog = ()=> ({
    type: ActionTypes.SAVE_FUNDAMENTAL_EDIT_DIALOG
})
export const cancelFundamentalLineDialog = ()=> ({
    type: ActionTypes.CANCEL_FUNDAMENTAL_EDIT_DIALOG
})

//Index Line dialog actions
export const openIndexLineDialog = (item)=>({
    type: ActionTypes.OPEN_INDEX_LINE_DIALOG,
    item
})
export const cancelIndexLineDialogSetting = ()=>({
    type: ActionTypes.CANCEL_INDEX_DIALOG_SETTING,
})
export const handleIndexLineDialogCountryChange = (selectedCountry)=>({
    type: ActionTypes.HANDLE_INDEX_DIALOG_COUNTRY_CHANGE,
    selectedCountry
})
export const handleIndexLineDialogRegionChange = (selectedRegion)=>({
    type: ActionTypes.HANDLE_INDEX_DIALOG_REGION_CHANGE,
    selectedRegion
})
export const handleIndexLineDialogGroupChange = (selectedGroup)=>({
    type: ActionTypes.HANDLE_INDEX_DIALOG_GROUP_CHANGE,
    selectedGroup
})
export const handleChangeIndexValueType = (valueType)=>({
    type: ActionTypes.HANDLE_INDEX_VALUE_TYPE_CHANGE,
    valueType
})
export const handleChangeIndexSymbol = (symbolName)=>({
    type: ActionTypes.HANDLE_INDEX_SYMBOL_CHANGE,
    symbolName
})
export const saveIndexLineDialogSetting = ()=>({
    type: ActionTypes.SAVE_INDEX_DIALOG_SETTING,
})
export const updateIndexLineShowAlert = ()=>({
    type: PriceChartConstants.ActionTypes.UPDATE_INDEX_DIALOG_SHOW_ALERT,
})

export const updateIndexLineColor = (lineColor)=>({
    type: ActionTypes.UPDATE_INDEX_LINE_COLOR,
    lineColor
})
export const updateIndexLineThickness = (lineThickness)=>({
    type: ActionTypes.UPDATE_INDEX_LINE_THICKNESS,
    lineThickness
})
export const updateIndexSymbolError = (errorMessage)=>({
    type: ActionTypes.UPDATE_INDEX_SYMBOL_ERROR,
    errorMessage
})
//

//Corp event Dialog Action
export const openCorpEventDialog = (item)=>({
    type: ActionTypes.OPEN_CORP_EVENT_DIALOG,
    item
})
export const cancelCorpEventDialogSetting = ()=>({
    type: ActionTypes.CANCEL_CORP_EVENT_DIALOG,
})
export const restoreCorpEventSetting = ()=>({
    type: ActionTypes.RESTORE_CORP_EVENT_SETTING,
})
export const saveCorpEventSetting = ()=>({
    type: ActionTypes.SAVE_CORP_EVENT_SETTING,
})
export const changeCorpEventSelectedPeriodicity = (selectedPeriodicity)=>({
    type: ActionTypes.CHANGE_CORP_EVENT_SELECTED_PERIODICITY,
    selectedPeriodicity
})
export const toggleCorpEventProperty = (property)=>({
    type: ActionTypes.TOGGLE_CORP_EVENT_PROPERTY,
    property
})
//

// Pattern Rec Actions
export const openPatternRecDialog = (item)=>({
    type: ActionTypes.OPEN_PATTERN_REC_DIALOG,
    item
})
export const cancelPatternRecDialogSetting = ()=>({
    type: ActionTypes.CANCEL_PATTERN_REC_DIALOG,
})
export const restorePatternRecSetting = ()=>({
    type: ActionTypes.RESTORE_PATTERN_REC_SETTING,
})
export const savePatternRecSetting = ()=>({
    type: ActionTypes.SAVE_PATTERN_REC_SETTING,
})
export const togglePRTightAreaPattern = ()=>({
    type: ActionTypes.TOGGLE_PR_TIGHT_AREA_PATTERN,
})
export const handleTightAreaColorChange = (TightAreaColor)=>({
    type: ActionTypes.CHANGE_PR_TIGHT_AREA_COLOR,
    TightAreaColor
})
export const togglePatternRecKPR = ()=>({
    type: ActionTypes.TOGGLE_PATTERN_REC_KPR,
})
export const togglePRPowerFromPivot = ()=>({
    type: ActionTypes.TOGGLE_PR_POWER_FROM_PIVOT,
})
export const togglePRChannelLines = ()=>({
    type: ActionTypes.TOGGLE_PR_CHANNEL_LINES,
})
export const togglePRClimaxTop = ()=>({
    type: ActionTypes.TOGGLE_PR_CLIMAX_TOP,
})
export const togglePRAdvancePattern = ()=>({
    type: ActionTypes.TOGGLE_PR_ADVANCE_PATTERN,
})
export const handlePatternColorChange = (PatternColor)=>({
    type: ActionTypes. CHANGE_PR_PATTERN_COLOR,
    PatternColor
})
export const handleProfitStartChange = (profitStart, value)=>({
    type: ActionTypes. CHANGE_PR_PROFIT_START,
    profitStart, value
})
export const handleProfitEndChange = (profitEnd, value)=>({
    type: ActionTypes. CHANGE_PR_PROFIT_END,
    profitEnd, value
})
export const handlePivotStartChange = (pivotStart, value)=>({
    type: ActionTypes. CHANGE_PR_PIVOT_START,
    pivotStart, value
})
export const handlePivotEndChange = (pivotEnd, value)=>({
    type: ActionTypes. CHANGE_PR_PIVOT_END,
    pivotEnd, value
})
export const handleLossStartChange = (lossStart, value)=>({
    type: ActionTypes. CHANGE_PR_LOSS_START,
    lossStart, value
})
export const handleLossEndChange = (lossEnd, value)=>({
    type: ActionTypes. CHANGE_PR_LOSS_END,
    lossEnd, value
})
//
export const changeFundamentalLineDialogTab = (selectedPeriodicity)=> ({
    type: ActionTypes.CHANGE_FUNDAMENTAL_EDIT_DIALOG_TAB,
    selectedPeriodicity
})
export const updateExternalToggleButton = (isExternalDataVisible)=> ({
    type: ActionTypes.UPDATE_EXTERNAL_DATA_TOGGLE_BUTTON,
    isExternalDataVisible
})
export const resetFundamentalLineStates = ()=> ({
    type: ActionTypes.RESET_FUNDAMENTAL_lINE_STATE,
})
export const updateFundamentalLineStates = (states)=> ({
    type: PriceChartConstants.ActionTypes.UPDATE_FUNDAMENTAL_LINE_STATES,
    states
})
export const updateIsEarningsAnimationDocked = (IsEarningsAnimationDocked)=> ({
    type: PriceChartConstants.ActionTypes.UPDATE_IS_EPS_ANIMATION_DOCKED,
    IsEarningsAnimationDocked
})
export const updateEpsIsPlaying = (isPlaying)=> ({
    type: PriceChartConstants.ActionTypes.TOGGLE_EPS_ANIMAITON_IS_PLAYING,
    isPlaying
})
export const updateIsHistoricEpsLine = (isHistoricEpsLine)=> ({
    type: PriceChartConstants.ActionTypes.UPDATE_IS_HISTORIC_EPS_LINE,
    isHistoricEpsLine
})
export const updateIsEPSChartLoaded = (isEPSChartLoaded)=> ({
    type: PriceChartConstants.ActionTypes.UPDATE_IS_EPS_CHART_LOADED,
    isEPSChartLoaded
})
export const getEarningsAnimationSetFromController = ()=> ({
    type: PriceChartConstants.ActionTypes.GET_EARNINGS_ANIMATION_SET_FROM_CONTROLLER,
})
export const openEPSAnimationDialog = (item)=> ({
    type: PriceChartConstants.ActionTypes.OPEN_EPS_ANIMAITON_DIALOG,
    item
})
export const toggleEpsAnimationDisplayed = (item)=> ({
    type: PriceChartConstants.ActionTypes.TOGGLE_EPS_ANIMAITON_DISPLAYED,
    item
})
export const toggleEpsAnimationMessageDialog = (isAnimationMessageDialogOpen)=> ({
    type: PriceChartConstants.ActionTypes.TOGGLE_EPS_ANIMAITON_MESSAGE_DIALOG,
    isAnimationMessageDialogOpen
})
export const toggleEpsAnimationMessageDialogAvailable = (isAnimationMessageDialogAvailable)=> ({
    type: PriceChartConstants.ActionTypes.TOGGLE_EPS_ANIMAITON_MESSAGE_DIALOG_AVAILABLE,
    isAnimationMessageDialogAvailable
})
export const saveEPSAnimationDialog = ()=> ({
    type: PriceChartConstants.ActionTypes.SAVE_EPS_ANIMAITON_DIALOG,
})
export const cancelEPSAnimationDialog = ()=> ({
    type: PriceChartConstants.ActionTypes.CANCEL_EPS_ANIMAITON_DIALOG,
})
export const restoreEPSAnimationLineSetting = ()=> ({
    type: PriceChartConstants.ActionTypes.RESET_EPS_ANIMAITON_DIALOG,
})
export const changeEPSAnimationDialogProperty = (property, value)=> ({
    type: PriceChartConstants.ActionTypes.CHANGE_EPS_ANIMATION_DIALOG_PROPERTY,
    property, value
})
export const changeFundamentalLineEstimate = ()=> ({
    type: ActionTypes.CHANGE_FUNDAMENTAL_LINE_ESTIMATE,
})
export const changeFundamentalLineEarning = ()=> ({
    type: ActionTypes.CHANGE_FUNDAMENTAL_LINE_EARNING,
})
export const changeFundamentalLineWeight = (lineThickness)=> ({
    type: ActionTypes.CHANGE_FUNDAMENTAL_LINE_WEIGHT,
    lineThickness
})
export const changeFundamentalLineColor = (lineColor)=> ({
    type: ActionTypes.CHANGE_FUNDAMENTAL_LINE_COLOR,
    lineColor
})
export const restoreFundamentalLineSetting = ()=> ({
    type: ActionTypes.RESTORE_FUNDAMENTAL_LINE_SETTING,
})
export const updateFundamentalLineMultiplier = (currPt, prevPt, lineType)=> ({
    type: ActionTypes.UPDATE_FUNDAMENTAL_LINE_MULTIPLIER,
    currPt, prevPt, lineType
})

export const fundametnalLineDataReady = (fundamentalLineData, selectedPerShare, rawLineData, processedLineData, lastQtrData, lastQtrY, showPointer, multiplierPriceMenu, scale, multiplier, fundamentalLineSettings, fundamentalLineMultipierSettings, lastnodeIndex, minPrice, maxPrice) => ({
    type: ActionTypes.FUNDAMENTAL_LINE_DATA_READY,
    fundamentalLineData, selectedPerShare, rawLineData, processedLineData, lastQtrData, lastQtrY, showPointer, multiplierPriceMenu, scale, multiplier, fundamentalLineSettings, fundamentalLineMultipierSettings, lastnodeIndex, minPrice, maxPrice
})
export const updateFundamentalLineSetting = (lineType, settings, multiplierSettings) => ({
    type: ActionTypes.FUNDAMENTAL_LINE_SETTING_UPDATE,
    lineType, settings, multiplierSettings
})
export const updateFundamentalLine = (pricePanelData, chartHeight, lineType) => ({
    type: ActionTypes.UPDATE_FUNDAMENTAL_LINE,
    pricePanelData, chartHeight, lineType
})
export const processEPSAnimation = () => ({
    type: ActionTypes.PROCESS_EPS_ANIMATION,
})
export const redrawExternalData = (isReposiotioning, uncheckedListId, isService) => ({
    type: ActionTypes.RE_DRAW_EXTERNAL_DATA,
    isReposiotioning, uncheckedListId , isService
})

export const dgDimensionChange = (dimension) => ({
    type: ActionTypes.CHART_RESIZE,
    dimension
})
export const updateIndicatorHeight = () => ({
    type: ActionTypes.INDICATOR_RESIZE,
})
export const updateChartRefs = (chart, chartType) => ({
    type: PriceChartConstants.ActionTypes.UPDATE_CHARTS_REF,
    chart, chartType
})
export const updateSTPTagSEttings = (showTag) => ({
    type: ActionTypes.UPDATE_SPTTAG_SETTINGS,
    showTag
})
export const updateSTPStates = (states) => ({
    type: ActionTypes.UPDATE_SPT_STATES,
    states
})
export const openSTPSettingsDialog = () => ({
    type: ActionTypes.OPEN_STP_SETTINGS_DIALOG,
})
export const saveSTPSettingsDialog = () => ({
    type: ActionTypes.SAVE_STP_SETTINGS_DIALOG,
})
export const cancelSTPSettingsDialog = () => ({
    type: ActionTypes.CANCEL_STP_SETTINGS_DIALOG,
})
export const restoreSTPSettingsDialog = () => ({
    type: ActionTypes.RESTORE_STP_SETTINGS_DIALOG,
})
export const changeSTPDialogTab = (periodicity) => ({
    type: ActionTypes.CHANGE_STP_DIALOG_TAB,
    periodicity
})
export const changeSTPHiLowStatus = () => ({
    type: ActionTypes.CHANGE_STP_HI_LOW_STATUS,
})
export const changeSTPPriceStatus = () => ({
    type: ActionTypes.CHANGE_STP_PRICE_STATUS,
})
export const updateSTPVisibilty = (isStreetTargetPriceVisible) => ({
    type: ActionTypes.UPDATE_STP_VISIBILTY,
    isStreetTargetPriceVisible
})
export const handleSTPChangeColor = (color) => ({
    type: ActionTypes.HANDLE_STP_COLOR_CHANGE,
    color
})

export const showContextMenu = (event,top,lineID, maNumber = 0) => ({
    type: ActionTypes.SHOW_PRICE_CONTEXT_MENU,
    event,top,lineID, maNumber
})
export const showContextMenuAtPos = (selectedConextMenuOption, clientX, clientY, priceAtLocation) => ({
    type: ActionTypes.SHOW_CONTEXT_MENU_POS,
    selectedConextMenuOption, clientX, clientY, priceAtLocation
})
export const showMAContextMenu = (maObj) => ({
    type: ActionTypes.SHOW_MA_CONTEXT_MENU,
    maObj
})

export const closeContextMenu = () => ({
    type: ActionTypes.HIDE_PRICE_CONTEXT_MENU
})
export const updateContextHit = (contextMenuObj) => ({
    type: ActionTypes.UPDATE_CONTEXT_HIT,
    contextMenuObj
})
export const onContextMenuClick = (item) => ({
    type: ActionTypes.ON_CONTEXT_OPTION_SELECT,
    item
})
export const updateChartOnStreaming = (DdeStatusType) => ({
    type: ActionTypes.UPDATE_CHART_ON_STREAMING,
    DdeStatusType
});
export const ideaMouseDown = (ideaType) => ({
    type: ActionTypes.IDEA_MOUSE_DOWN,
    ideaType
})
export const ideaMouseOver = (ideaType) => ({
    type: ActionTypes.IDEA_MOUSE_OVER,
    ideaType
})
export const handleMouseLeave = () => ({
    type: ActionTypes.IDEA_MOUSE_LEAVE
})
export const prepareContextMenu = () => ({
    type: ActionTypes.PREPARE_CONTEXTMENU
})
export const toggleIdeaBox = (ideasDisplay) => ({
    type: ActionTypes.SHOW_IDEA_BOX,
    ideasDisplay
})

export const calculateIdeaPriceChart = () => ({
    type: ActionTypes.CALCULATE_IDEA_DG,
})
export const stopDatagraphStreaming = () => ({
    type: ActionTypes.STOP_DATAGRAPH_STREAMING,
})

export const setIdeaStickToReducer = (stick) =>({
    type:ActionTypes.STORE_STICK_IDEAS,
    stick: stick
})