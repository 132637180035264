import LogoutRequest from '../Requests/LogoutRequest'
import BaseServiceApi from 'BaseServiceApi'

class LogoutApi {

  getdata(userEmail) {
    const req = new LogoutRequest(userEmail);
    return BaseServiceApi.logoutserverCall(req);
  }
}

export default LogoutApi;
