import React from 'react';

import { isGICSSelected, MetricActionAlert } from "../../Actions/TabDataGraphAction";
import { isGICSSelected_Fundamentals, MetricActionAlert_Fundamentals } from "../../Actions/FundamentalsActions";
import ClickMenu from "ClickMenu";
import LocalizationStore from 'LocalizationStore';
import { definationSymbols } from "../../Constants/TabDataGraphConstants.js";

const leftMenuItemNonStock = [
    { header: LocalizationStore.getTranslatedData('MetDef_Menu','View Metric Definition'), key: 'VMD' }
];
const HeaderDefination = (
    { industryGroup,
        infoData,
        isStock,
        isHistoric,
        view,
        definationSymbol,
        isInteractiveData
    }) => {

    let cursor = definationSymbol === definationSymbols.DEF_EPSDUEDATE || (definationSymbols.DEF_EXTERNALDATA && isInteractiveData) ? 'pointer' : 'default';
    const textIndGrouptyle = { textDecoration: 'none', cursor: cursor, pointerEvents: 'all', marginLeft: '0px' };

    let defClass = "info-wht medium-normal";
    if (definationSymbol === definationSymbols.DEF_EPSDUEDATE) {
        defClass = "info-dot medium-normal";
    }
    if ((definationSymbol === definationSymbols.DEF_EXTERNALDATA )) {
        (isInteractiveData) ? defClass = "info-dot medium-normal ext-toggle" : defClass = "info-wht medium-normal ext-toggle";
     }
    if (definationSymbol === definationSymbols.DEF_EPSNEARDATE) {
        defClass = isHistoric ? "info-dot-orange medium-normal" : "info-dot-yellow medium-normal";
    }
    // if(definationSymbol === definationSymbols.DEF_EXTERNALDATA && !isInteractiveData) {
    //     defClass = "info-wht";
    // }
    const onBlockMenuItemRightClicked = (option) => {
        switch (option.key) {
            case 'SWI':
                if (view === 'FUNDAMENTALS') {
                    isGICSSelected_Fundamentals(false);
                }
                else {
                    isGICSSelected(false);
                }
                break;
            case 'SGI':
                if (view === 'FUNDAMENTALS') {
                    isGICSSelected_Fundamentals(true);
                }
                else {
                    isGICSSelected(true);
                }
                break;
            case 'VMD':
                if (view === 'FUNDAMENTALS') {
                    MetricActionAlert_Fundamentals(true);
                }
                else {
                    MetricActionAlert(true, definationSymbol);
                }

                break;
            default:
                break;
        }
    }

    return (
        < div className="graph-contex-menu shadow-none-btn" >
            <div className={defClass} id="div2" style={textIndGrouptyle}>{industryGroup}</div>
                {/* <ClickMenu data-disable-track-price="true" items={leftMenuItemNonStock} position="bottom" onselect={(option) => onBlockMenuItemRightClicked(option)} />     */}
        </div >
    )
}

export default HeaderDefination;
