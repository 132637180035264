import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from "LocalizationStore";
import {
  PrintMode
} from "../../../../../print/printmode";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";
import numeral from "numeral";

const annualText = LocalizationStore.getTranslatedData("fr_annual", "ANNUAL");

export default class AnnualData {
  constructor(annualData, headers, stockInfo, fye) {
    this.labelWidth = 40;
    this.stockInfo = stockInfo;
    this.currencyFormat = stockInfo.CurrencyFormat;
    this.rowSource = [];
    this.positiveBrush = ThemeHelper.getThemedBrush("positiveDataText");
    this.negativeBrush = ThemeHelper.getThemedBrush("negativeDataText");
    this.setAnnualData(annualData, headers, fye);

  }

  setAnnualData(annualData, headers, fye) {
    this.rowSource = [];
    let latestDate = Date.now();
    let earliestDate = Date.now();
    const notNullData = annualData.filter((item) => (item && DateHelper.getPSTFromLong(item.fiscalDate)));
    if (notNullData.length > 0) {
      latestDate = notNullData.reduce((a, b) => this.compareDates(a, b));
      earliestDate = notNullData.reduce((a, b) => this.compareDates(b, a));
    }
    let dataCount = annualData.length;
    earliestDate = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(annualData[annualData.length - 1].fiscalDate), this.stockInfo.MomentZoneID);
    const copyOfearliestDate = new Date(earliestDate.valueOf())

    if (dataCount < 10) {
      while (dataCount < 10) {
        copyOfearliestDate.setFullYear(copyOfearliestDate.getFullYear() - 1);
        annualData.push({
          FiscalDate: new Date(copyOfearliestDate.getTime())
        });
        dataCount++;
      }
    } else {
      annualData.splice(10, annualData.length - 10);
    }

    const espResults = annualData.map((item) =>
      item.EPS && Math.abs(item.EPS)
    );
    const salesResults = annualData.map((item) => {
      if (item.Sales) {
        return Math.abs(item.Sales);
      }
      return 0.0;
    });
    let epsLabel = "";
    let salesLabel = "";
    let salesDivider;
    let epsDivider;
    if (salesResults.length > 0) {
      const saleLabelAndDivider = this.getSalesLabelAndSalesDivider(salesResults);
      salesLabel = saleLabelAndDivider.salesLabel;
      salesDivider = saleLabelAndDivider.salesDivider;
    }

    headers[5] = headers[5].replace("MIL", salesLabel);
    if (espResults.length > 0) {
      const epsLabelAndDivider = this.getEpsLabelAndDivider(espResults);
      epsLabel = epsLabelAndDivider.label;
      epsDivider = epsLabelAndDivider.divider;
    }
    headers[0] += epsLabel;
    for (let i = 0; i < 7; i++) {
      const row = {
        cells: []
      };
      for (let j = 0; j < 10; j++) {
        let cell = {};
        if (i === 0 && j === 0) {
          let month = null;
          if (this.stockInfo.SymTypeEnum === SymbolType.INTERNATIONALSTOCK) {
            month = fye ? fye.toUpperCase() : null;
          } else {
            month = DateHelper.getMonth(latestDate.getMonth());
          }
          if (month !== null) {
            month = LocalizationStore.getTranslatedData(`cal_${month.toLowerCase().replace(/^\S/g, (s) => s.toUpperCase())}`, month);
            cell.value = LocalizationStore.getTranslatedData("sb_annllabel", "ANNUAL - {0} END", month);
          } else {
            cell.value = annualText;
          }
          cell.IsLabelCell = true;
          cell.textAlign = "left";
        } else if (i === 0 && j > 0 && annualData.length >= (j + 1)) {
          cell.value = annualData[annualData.length - (j + 1)].Year;
          cell.textAlign = "center";
        } else if (j === 0) {
          cell.value = headers[i - 1].replace(this.stockInfo.DefaultCurrency.Code, this.currencyFormat.unit);
          cell.IsLabelCell = true;
          cell.textAlign = "left";
        } else {
          cell = this.getDataCell(annualData, i, j, epsDivider, salesDivider);
        }
        // translat first column begin
        if (j === 0) {
          switch (i) {
            case 0:
              break;
            case 1:
              break;
            case 2:
              cell.value = LocalizationStore.getTranslatedData("sb_cfshr", cell.value);
              break;
            case 3:
              cell.value = LocalizationStore.getTranslatedData("sb_premarpct", cell.value);
              break;
            case 4:
              cell.value = LocalizationStore.getTranslatedData("sb_roe", cell.value);
              break;
            case 5:
              cell.value = LocalizationStore.getTranslatedData("sb_slspctchg", cell.value);
              break;
            case 6:
              cell.value = LocalizationStore.getTranslatedData("sb_sls", cell.value);
              break;
            default:
              break;
          }
        }
        // translate end


        row.cells.push({
          cell
        });
      }

      this.rowSource.push(row);
    }


  }
  getDataCell(annualData, row, cell, epsDivider, salesDivider) {
    const item = annualData[annualData.length - (cell + 1)];
    if (row === 1) {
      return this.getEpsCell(item, epsDivider);
    } else if (row === 2) {
      return this.getCashFlow(item);
    } else if (row === 3) {
      return this.getPretaxmargin(item);
    } else if (row === 4) {
      return this.getReturnOnEquity(item);
    } else if (row === 5) {
      return this.getSalesPercentage(item, annualData);
    } else if (row === 6) {
      return this.getsales(item, salesDivider);
    }

    return {};
  }
  getsales(annualDataItem, salesDivider) {
    if (!annualDataItem || (!annualDataItem.Sales && annualDataItem.Sales !== 0)) {
      return {
        value: ""
      };
    }
    const cell = {};
    const sales = annualDataItem.Sales / salesDivider; /* * this.currencyFormat.ratio */
    if (this.checkTextWidth(sales)) {
      //  cell.value = ExtremeDataValue.toLocalValue(sales, 0);
      cell.value = ExtremeDataValue.getFormattedNumber(sales, 0);
      if (cell.value === "0" && sales !== 0) {
        cell.value = ExtremeDataValue.formatValue(sales, 1);
      }
    } else {
      cell.value = ExtremeDataValue.abbreviateValue(sales, 0);
    }
    cell.textAlign = "right";
    return cell;
  }
  getSalesPercentage(annualDataItem, annualData) {
    if (!annualDataItem || (!annualDataItem.SalesPctChg && annualDataItem.SalesPctChg !== 0) || (!annualDataItem.Sales && annualDataItem.Sales !== 0)) {
      return {
        value: ""
      };
    }
    if (!annualData.some((x) => DateHelper.getUtcFromLong(x.fiscalDate) < DateHelper.getUtcFromLong(annualDataItem.fiscalDate))) {
      return {
        value: ""
      };
    }
    const brush = annualDataItem.SalesPctChg < 0 ? this.negativeBrush : this.positiveBrush;
    const cell = {};
    cell.value = `${ExtremeDataValue.numberWithCommas(ExtremeDataValue.formatValue(annualDataItem.SalesPctChg, 0))}%`;
    cell.textAlign = "right";
    cell.color = brush;
    cell.isPositive = !(annualDataItem.SalesPctChg < 0);
    cell.isUseCusColor = true;
    return cell;
  }
  getReturnOnEquity(annualDataItem) {
    if (!annualDataItem || (!annualDataItem.ROE && annualDataItem.ROE !== 0)) {
      const cell = {};
      cell.value = "";
      return cell;
    }
    const brush = annualDataItem.ROE < 0 ? this.negativeBrush : this.positiveBrush;
    const cell = {};
    cell.value = `${ExtremeDataValue.numberWithCommas(ExtremeDataValue.formatValue(annualDataItem.ROE, 0))}%`;
    cell.textAlign = "right";
    cell.color = brush;
    cell.isPositive = !(annualDataItem.ROE < 0);
    cell.isUseCusColor = true;
    return cell;
  }
  getPretaxmargin(annualDataItem) {
    if (!annualDataItem || (!annualDataItem.PreTaxMargin && annualDataItem.PreTaxMargin !== 0)) {
      const cell = {};
      cell.value = "";
      return cell;
    }
    const brush = annualDataItem.PreTaxMargin < 0 ? this.negativeBrush : this.positiveBrush;
    const cell = {};
    const decimalPlaces = this.countDecimalPlacesInValue(annualDataItem.PreTaxMargin)
    cell.value = `${numeral(annualDataItem.PreTaxMargin).format(`${decimalPlaces}a`).toUpperCase()}%`
    cell.textAlign = "right";
    cell.color = brush;
    cell.isPositive = !(annualDataItem.PreTaxMargin < 0);
    cell.isUseCusColor = true;
    return cell;
  }
  // function written to check how many digits are present after the decimal
  countDecimalPlacesInValue(str) {
    const cleanedStr = str.toString();
    if (cleanedStr.includes('.')) {
      const decimalIndex = cleanedStr.indexOf('.');
      const decimalDigits = cleanedStr.length - decimalIndex - 1;
      return decimalDigits;
    }
    return 0;
  }
  getCashFlow(annualDataItem) {
    if (!annualDataItem || (!annualDataItem.CashFlow && annualDataItem.CashFlow !== 0)) {
      const cell = {};
      cell.value = "";
      return cell;
    }
    const cell = {};
    const cashFlow = annualDataItem.CashFlow;
    if (this.checkTextWidth(cashFlow)) {
      if (Math.abs(cashFlow) > 999) {
        cell.value = ExtremeDataValue.abbreviateFinancialValue(cashFlow, 0);
      } else {
        cell.value = ExtremeDataValue.numberWithCommas(ExtremeDataValue.abbreviateValue2(cashFlow, 2));
      }
    } else {
      if (Math.abs(cashFlow) > 999) {
        cell.value = ExtremeDataValue.abbreviateFinancialValue(cashFlow, 0);
      } else {
        cell.value = ExtremeDataValue.numberWithCommas(ExtremeDataValue.abbreviateValue(cashFlow, 0));
      }
    }
    cell.textAlign = "right";
    return cell;
  }
  getEpsCell(annualDataItem, epsDivider) {
    if (!annualDataItem || (!annualDataItem.EPS && annualDataItem.EPS !== 0)) {
      const cell = {};
      cell.value = "";
      return cell;
    }
    const eps = annualDataItem.EPS / epsDivider;
    const brush = eps < 0 ? this.negativeBrush : this.positiveBrush;
    const cell = {};
    if (this.checkTextWidth(eps)) {
      cell.value = ExtremeDataValue.numberWithCommas(ExtremeDataValue.abbreviateValue2(eps, 2));
    } else {
      cell.value = ExtremeDataValue.numberWithCommas(ExtremeDataValue.abbreviateValue(eps, 0));
    }
    cell.showCarryOver = annualDataItem.EpsExtra === 1;
    cell.textAlign = "right";
    cell.color = brush;
    cell.isPositive = !(annualDataItem.EPS < 0);
    cell.isUseCusColor = true;
    return cell;
  }
  getEpsLabelAndDivider(espResults) {
    let epsObj = {
      label: "",
      divider: 1
    };
    const max = Math.max.apply(null, espResults);
    const absVal = Math.abs(max);
    if (!this.checkTextWidth(absVal)) {
      if (absVal > 1000000000) {
        epsObj = {
          label: "(B)",
          divider: 1000000000
        };
      } else if (absVal > 1000000) {
        epsObj = {
          label: "(M)",
          divider: 1000000
        };
      } else if (absVal > 1000) {
        epsObj = {
          label: "(000)",
          divider: 1000
        };
      }
    }
    return epsObj;
  }
  getSalesLabelAndSalesDivider(salesResults) {
    let saleObj = {
      salesLabel: "MIL",
      salesDivider: 1
    };
    if (salesResults) {
      const maxSale = Math.max.apply(null, salesResults);
      const absMaxSale = ExtremeDataValue.getFormattedNumber(maxSale, 0);
      const font = "13.3333333 'calibri'";
      const width = absMaxSale.getTextWidthSize(font);
      if (width > this.labelWidth && this.stockInfo.DefaultCurrency && (!(this.stockInfo.DefaultCurrency === "KRW") && maxSale > 100000000)) {
        saleObj = {
          salesLabel: "BIL",
          salesDivider: 1000
        };
      }
    }
    return saleObj;
  }

  checkLabelWidth(result) {
    const absMaxResult = ExtremeDataValue.getFormattedNumber(result, 0);
    const font = "13.3333333333333 'calibri'";
    const width = absMaxResult.getTextWidth(font);
    return width < this.labelWidth;
  }

  checkTextWidth(result) {
    const absMaxResult = ExtremeDataValue.getFormattedNumber(result, 0);
    const font = PrintMode.printing ? "8px sans-serif" : "13.3333333333333 'calibri'";
    const width = absMaxResult.getTextWidthSize(font);
    return width < this.labelWidth;
  }

  tranformToDate(jsonDate) {
    return jsonDate instanceof Date ? jsonDate : DateHelper.parseJsonDate(jsonDate);
  }

  compareDates(jsonDate1, jsonDate2) {
    const date1 = jsonDate1 instanceof Date ? jsonDate1 : new Date(DateHelper.getUtcFromLong(jsonDate1.fiscalDate));
    const date2 = jsonDate2 instanceof Date ? jsonDate2 : new Date(DateHelper.getUtcFromLong(jsonDate2.fiscalDate));
    return date1 > date2 ? date1 : date2;
  }

}