import { without, findWhere } from "underscore";
import { ListManagerCustomMetrics } from "../../Constants/ListManagerCustomMetrics";
import LocalizationStore from "LocalizationStore";
const { ActionTypes } = ListManagerCustomMetrics;

const initialState = {
  isMetricLibraryOpen: false,
  loadingMetricLibrary: false,
  isMetricManageOpen: false,
  isColumnFilter: false,
  MetricLibrary: null,
  results: [],
  selectedMetricData: '',
  openDefinition: false,
  openDescription: false,
  openEditFormat: false,
  categoryData: {},
  isNameEdit: false,
  customMetricName: '',
  renameSelected: [],
  customUnits: '',
  customExampleText: '',
  uniteditStart: false,
  decimaleditStart: false,
  selectedUnitId: '',
  selecteddecimalsId: '',
  exampleFormatId: 5,
  exampleFormat: '1,000',
  exampleUnit: '',
  exampleDecimals: '',
  unitSymbol: '',
  unitName: '',
  unitDecimal: '',
  saveData: [],
  custDescription: '',
  userInitial: '',
  custmetricobj: '',
  customMetricId: '',
  listId: '',
  alreadySaved: false,
  custUnits: {},
  hasError: false,
  errorMsg: '',
  deleteCustId: null,
  deleteAlert: false,
  searchResults: null,
  metricListResults: null,
  isSearchOpen: false,
  tempcustomMetricName: '',
  alertShown: true,
  isLoading: false,
  isLibraryLoading: false,
  variable: '',
  addMetricClosed: false,
  metricSaved: false,
  alreadySaved: false,
  isCustomColEdit: false
}
const addCustomMetric = (state = initialState, action) => {
  let saveFormat;
  switch (action.type) {
    case ActionTypes.BROWSE_METRICS_CLICK:
      return {
        ...state,
        isMetricLibraryOpen: action.isMetricLibraryOpen,
        loadingMetricLibrary: action.loadingMetricLibrary,
        isColumnFilter: action.isColumnFilter
      }

    case ActionTypes.ADD_TO_METRIC_DIALOG:
      return {
        ...state,
        results: action.returnData,
        selectedMetricData: action.selectedMetricData,
        categoryData: action.categoryData ? action.categoryData : '',
        customMetricName: action.customMetricName,
        metricList: action.itemArray,
        tempcustomMetricName: action.customMetricName,
        userInitial: action.userInitial,
        customMetricId: action.customMetricId,
        isCustomColEdit: action.isCustomColEdit,
        selectedUnitId: action.selectedUnitId,
        selecteddecimalsId: action.selecteddecimalsId,
        exampleFormat: action.exampleFormat,
        custColEditData: action.custColEditData,
        listId: action.listId,
        saveData: action.saveData,
        customUnits: action.custUnits,
        custDescription: action.custDescription,
        existingColumn: action.existingColumn,
        isSearchOpen: action.isSearchOpen,
        metricSaved: action.metricSaved,
        isMetricManageOpen: action.isMetricManageOpen,
        isNameEdit: state.tempcustomMetricName === '' && action.customMetricName === '' ? true : action.isNameEdit,
        alreadySaved: action.alreadySaved
      }


    case ActionTypes.OPEN_MANAGE_METRICS_TRUE:
      return {
        ...state,
        isMetricManageOpen: action.manageMetricState,
        custmetricobj: action.custmetricobj,
        openDefinition: false,
        isLoading: false 
      }

    case ActionTypes.OPEN_METRICS_DEFINITION:
      return {
        ...state,
        openDefinition: action.data
      }

    case ActionTypes.OPEN_METRICS_DESCRIPTION:
      return {
        ...state,
        openDescription: action.data
      }

    case ActionTypes.OPEN_METRICS_EDITFORMAT:
      return {
        ...state,
        openEditFormat: action.data
      }

    case ActionTypes.HANDLE_INTERVAL_CHANGE_SUCCESS:

      return {
        ...state,
        selectedMetricData: action.data,
        results:
        {
          ...state.results,
          ColumnDisplayName: action.data.ColumnDisplayName ? LocalizationStore.getTranslatedData("DataItemExt_DisplayName_"+ action.data.MsItemID, action.data.ColumnDisplayName.trim()) : "",
          Definition: action.data.Definition ? action.data.Definition.trim() : "",
          itemSymbol: action.data.itemSymbol ? action.data.itemSymbol.trim() : "",
          isLookBackAvailable: action.isLookBackAvailable
        }
      }

    case ActionTypes.HANDLE_LOOKBACK_CHANGE_SUCCESS:

      return {
        ...state,
        selectedMetricData: action.data,
        results:
        {
          ...state.results,
          ColumnDisplayName: action.data.ColumnDisplayName ? LocalizationStore.getTranslatedData("DataItemExt_DisplayName_"+ action.data.MsItemID, action.data.ColumnDisplayName.trim()) : "",
          Definition: action.data.Definition ? action.data.Definition.trim() : "",
          itemSymbol: action.data.itemSymbol ? action.data.itemSymbol.trim() : "",
          isLookBackAvailable: action.isLookBackAvailable
        }
      }

    case ActionTypes.HANDLE_NAME_CHANGE_SUCCESS:

      return {
        ...state,
        tempcustomMetricName: action.isNameEdit ? action.text : state.customMetricName,
        isNameEdit: action.isNameEdit,
        alertShown: action.alertShown,
      }

    case ActionTypes.HANDLE_FORMULA_CHANGE_SUCCESS:

      if (state.tempcustomMetricName === '' && !state.alertShown) {
        return {
          ...state,
          hasError: true,
          errorMsg: LocalizationStore.getTranslatedData("cm_peavnfcm","Please enter a valid name for custom metric."),
          isNameEdit: true,
          alertShown: true
        }
      }
      else {
        return {
          ...state,
          alreadySaved: action.alreadySaved,
          isNameEdit: state.tempcustomMetricName == ''? true : action.isNameEdit,
          customMetricName: state.tempcustomMetricName,
          results: {
            ...state.results,
            Formula: state.results.Formula != action.data ? action.data : state.results.Formula,
          }
        }
      }

    case ActionTypes.HANDLE_SYMBOL_CHANGE_SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          itemSymbol: state.results.itemSymbol != action.data ? action.data : state.results.itemSymbol,
        },
      }

    case ActionTypes.HANDLE_EDIT_NAME_OUTSIDE_CLICK:

      if (state.tempcustomMetricName === '' && !state.alertShown) {
        return {
          ...state,
          hasError: true,
          errorMsg: LocalizationStore.getTranslatedData("cm_peavnfcm","Please enter a valid name for custom metric."),
          isNameEdit: true,
          alertShown: true,
          customMetricName:state.tempcustomMetricName
        }
      }
      else {
        return {
          ...state,
          customMetricName: state.tempcustomMetricName,
          isNameEdit: state.tempcustomMetricName == '' ? true : false,
          alreadySaved: false
        }
      }

    case ActionTypes.HANDLE_RENAME_CLICK_SUCCESS:
      return {
        ...state,
        renameSelected: action.renameSelected
      }

    case ActionTypes.HANDLE_RENAME_EDIT_CHANGE:
      for (let i = 0; i < state.renameSelected.length; i++) {
        if (state.renameSelected[i].renameId == action.id) {
          state.renameSelected[i].renameName = `${action.value}`;
        }
      }
      return { ...state }
    case ActionTypes.HANDLE_RENAME_SAVE_CHANGE_SUCCESS:
      state.renameSelected = without(state.renameSelected, findWhere(state.renameSelected, {
        renameId: action.id
      }));
      let custObj = state.custmetricobj;
      for (let j = 0; j < custObj.length; j++) {
        if (custObj[j].CategoryId == action.id) {
          custObj[j].CategoryName = action.nameChanged;
        }
      }
      // let custmetricobject =  custObj;
      return {
         ...state,
        isLoading: false 
       }

    case ActionTypes.HANDLE_RESULT_UNITS_CHANGED:
      return {
        ...state,
        uniteditStart: true,
        selectedUnitId: action.unitSymbolId,
        unitName: action.unitName,
        unitSymbol: action.unitSymbol,
        exampleFormat: action.exampleFormat
      }

    case ActionTypes.HANDLE_RESULT_DECIMALS_CHANGED:
      return {
        ...state,
        decimaleditStart: true,
        selecteddecimalsId: action.unitDecimalId,
        unitDecimal: action.unitDecimal,
        exampleFormat: action.exampleFormat
      }

    case ActionTypes.HANDLE_RESULT_FORMAT_CHANGED:
      return {
        ...state,
        exampleFormatId: action.exampleFormatId,
        exampleFormat: action.exampleFormat
      }

    case ActionTypes.SAVE_CUSTOM_METRIC_DESCRIPTION:
      return {
        ...state,
        custDescription: action.data,
        openDescription: false
      }

    case ActionTypes.CLEAR_METRICS_EDITFORMAT_SUCCESS:
      return {
        ...state,
        openEditFormat: action.openEditFormat,
        exampleFormatId: action.exampleFormatId,
        selectedUnitId: action.selectedUnitId,
        selecteddecimalsId: action.selecteddecimalsId,
        exampleFormat: action.exampleFormat
      }

    case ActionTypes.SAVE_METRICS_EDITFORMAT_SUCCESS:
      saveFormat = action.saveData;
      return {
        ...state,
        openEditFormat: action.openEditFormat,
        saveData: {
          ...state.saveData,
          foramtData: saveFormat.foramtData,
          decimal: saveFormat.decimal,
          unit: saveFormat.unit,
          unitName: saveFormat.unitName,
          decimalID: saveFormat.decimalID,
          unitId: saveFormat.unitId,
          formatId: saveFormat.formatId,
        }

      }

    case ActionTypes.HANDLE_CREATE_CUSTOM_METRICS_SUCCESS:
      return {
        ...state,
        customMetricId: action.customMetricId,
        results:
        {
          ...state.results,
          metricName:action.custMetricName
        },
        alreadySaved: action.alreadySaved,
        addMetricClosed: action.closeMetricDialog,
        metricSaved: action.metricSaved,
        openDefinition: action.openDefinition,
        openDescription: action.openDefinition,
        openEditFormat: action.openDefinition,
        isNameEdit: action.openDefinition,
        existingColumn: action.existingColumn,
        isCustomColEdit:false
      }

    case ActionTypes.HANDLE_ADD_TO_COLUMN_SUCCESS:
      return {
        ...state,
        addMetricClosed: action.closeMetricDialog
      }

    case ActionTypes.HANDLE_VALIDATION_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        errorMsg: action.errorMsg,
        isLoading: false
      }

    case ActionTypes.HANDLE_CLOSE_ERROR_MESSAGE:
      return {
        ...state,
        hasError: false
      }

    case ActionTypes.HANDLE_DELETE_METRIC:
      return {
        ...state,
        deleteCustId: action.nodeid,
        deleteAlert: true
      }

    case ActionTypes.HANDLE_DELETE_METRIC_CANCEL:
      return {
        ...state,
        deleteCustId: null,
        deleteAlert: false
      }

    case ActionTypes.HANDLE_DELETE_METRIC_SUCCESS:
      return {
        ...state,
        deleteCustId: null,
        deleteAlert: false,
        isLoading: false
      }

    case ActionTypes.HANDLE_NAME_SEARCH_SUCCESS:

      return {
        ...state,
        searchResults: action.searchResults,
        metricListResults: action.metricListResults,
        isSearchOpen: action.isSearchOpen,
        results:
        {
          ...state.results,
          ColumnDisplayName: action.text,
          itemSymbol: action.itemSymbol
        }
      }

    case ActionTypes.HIDE_SEARCH_DROPDOWN:
      return {
        ...state,
        isSearchOpen: false
      }
    case ActionTypes.HANDLE_DEFINITION_SELECTED_SUCCESS:
      return {
        ...state,
        results:
        {
          ...state.results,
          Definition: action.Definition,
          metricName:action.metricName
        }

      }
    case ActionTypes.HANDLE_CLOSE_CUSTOM_METRIC:
      return {
        ...state,
        openDefinition: false,
        openDescription: false,
        openEditFormat: false,
        isMetricManageOpen: false,
        isCustomColEdit: false,
        isNameEdit:false,
        results: [],
        openDefinition: false
      }

    case ActionTypes.HANDLE_CLICK_LOADER:
      return {
        ...state,
        isLoading: action.isLoading
      }
    default:
      return state
  }
}
export default addCustomMetric;
