export const addCustomMetric = (state) => state.customMetrics.addCustomMetric;
export const addCustomMetricUnits = (state) => addCustomMetric(state).customUnits.defaultUnitsData;
export const unit = (state) => addCustomMetric(state).unitSymbol;
export const decimalsSelect = (state) => addCustomMetric(state).unitDecimal;
export const exampleFormatIdSelect = (state) => addCustomMetric(state).exampleFormatId;
export const renameObj = (state) => addCustomMetric(state).renameSelected;
export const selectedMetric = (state) => addCustomMetric(state).selectedMetricData;
export const custDescription = (state) => addCustomMetric(state).custDescription;
export const saveDataformat = (state) => addCustomMetric(state).saveData;
export const customMetricNames = (state) => addCustomMetric(state).customMetricName;
export const tempmetricName = (state) => addCustomMetric(state).tempcustomMetricName;
export const results = (state) => addCustomMetric(state).results;
export const custUnits = (state) => addCustomMetric(state).customUnits;
export const custColEditDataSelect = (state) => addCustomMetric(state).custColEditData;
export const isCustomColEditSelect = (state) => addCustomMetric(state).isCustomColEdit;
export const deleteCustId = (state) => addCustomMetric(state).deleteCustId;
export const uniteditStart = (state) => addCustomMetric(state).uniteditStart;
export const decimaleditStart = (state) => addCustomMetric(state).decimaleditStart;
export const metricListFull = (state) => addCustomMetric(state).metricList;
export const metricSearchOpen = (state) => addCustomMetric(state).isSearchOpen;
export const metricItemSymbol = (state) => addCustomMetric(state).results.itemSymbol;
export const metricCategories = (state) => addCustomMetric(state).categoryData;
export const exampleFormat = (state) => addCustomMetric(state).exampleFormat;
export const unitNameSelect = (state) => addCustomMetric(state).unitName;
export const selectedDecimalId = (state) => addCustomMetric(state).selecteddecimalsId;
export const selectedUnitId = (state) => addCustomMetric(state).selectedUnitId;
export const metricDefinition = (state) => addCustomMetric(state).results.Definition;
export const metricName = (state) => addCustomMetric(state).results.metricName;
export const stateSaved = (state) => addCustomMetric(state).alreadySaved;
export const editFormatOpen = (state) => addCustomMetric(state).openEditFormat;
export const currentCustomMetricId = (state) => addCustomMetric(state).customMetricId;










