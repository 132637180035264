import ClickMenu from "ClickMenu";
import { connect } from "react-redux";
import LocalizationStore from "LocalizationStore";
import { onOwnershipBlockToggleView } from "../../../../../Actions/OwnerShipBlockActions";
import { OwnershipBlockTranslateHelper } from "../../../../../Utils/TranslateHelper";
import OwnershipView from "../../../../../Constants/OwnershipView.js";
import React, { Component } from "react";

const leftColumnWidth = "220px";
const ownerShipViewObj = {
  [OwnershipView.HoldingRank]: { sViewName: "Rank", sViewClass: "rank" },
  [OwnershipView.SharesHeld]: { sViewName: "Shares", sViewClass: "shares" },
  [OwnershipView.SharesChange]: { sViewName: "Shares", sViewClass: "shares-tringle" }
}

class BlockTitleOwnership extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      isDraggable: false,
      blockSize: props.BlockSize
    };
  }

  setDraggableState() {
    this.timeout = setTimeout(() => {
      this.setState({ isDraggable: true });
    }, 600);
  }

  setNonDraggableState() {
    clearTimeout(this.timeout);
    this.setState({ isDraggable: false });
  }

  getSubMenuRender(item) {
    if (item !== undefined && item !== null) {
      item.onSelect = this.props.onClick;
      return;
    }
  }

  render() {
    let sViewName = "", sViewClass = "";
    const OwnersTitle = OwnershipBlockTranslateHelper.OWNERS
    const chinese = {
      "rank": "ob_id2",
      "shares": "ob_id3",
      "shares-tringle": "ob_id3"
    }

    if (this.props.dataAvailable && this.props.OwnershipViewType) {
      if (this.props.dataAvailable) {
        sViewName = ownerShipViewObj[this.props.OwnershipViewType].sViewName;
        sViewClass = ownerShipViewObj[this.props.OwnershipViewType].sViewClass;
      }
    }

    return (
      <div className="ownership-left-heading" style={{ width: leftColumnWidth }}>
        <div className="ownership-heading-distance">
          <div className="ownership-head-part">
            <h6>{OwnersTitle}</h6>
            <ClickMenu position="top"
              data-disable-track-price="true"
              subMenuItemRender={(item) => this.getSubMenuRender(item)}
              onselect={(item, isEditButton) => this.props.onClick(item, isEditButton)}
              alignment="left"
              subMenuAlignment="left"
              button="left"
              subMenuButtonDirection="right"
              items={this.props.headerMenu}
              hideOnItemClick={true} />
          </div>
          {sViewName &&
            <div id="ownershipBlockData" className="ownership-head-part" onClick={() => this.props.onOwnershipBlockToggleView()}>
              <span className="ownership-value-changer">
                <strong>( </strong>
                <strong className={`ownership-value-text ${sViewClass}`}>{LocalizationStore.getTranslatedData(chinese[sViewClass], sViewName)}</strong>
                <strong> )</strong>
              </span>
            </div>
          }
        </div>
        <div className="ytd-heading">YTD</div>
      </div>
    );
  }
}

const mapStateToProps = ({ DatagraphReducers }) => {
  const { BlockSize, headerMenu, dataAvailable, OwnershipViewType } = DatagraphReducers.ownershipBlockReducer;
  return { BlockSize, headerMenu, dataAvailable, OwnershipViewType }
}

const mapDispatchToProps = (dispatch) => ({
  onOwnershipBlockToggleView: () => dispatch(onOwnershipBlockToggleView())
})
export default connect(mapStateToProps, mapDispatchToProps)(BlockTitleOwnership)