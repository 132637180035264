import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import SettingsStore from "SettingsStore";
import UserInfoUtil from "../../../Utils/UserInfoUtil";

const initialState = {
    menuHeight: 180,
    itemHeight: 20,
    isFlagged: 0,
    companyNameDisplay: '',
    recentSymbols: [],
    volumePercentChange: 0, // This needs to remove when volumeChart changes done.
    // hasLocalSymbolEntitlement: UserInfoUtil.hasLocalSymbol(),
    isSymbolConvertedDialogAvailable: true,
    hasError: false,
    isInputActive: false,
    loadedSymbol: '',
    symbol: '',
    isSymbolConvertedDialogOpen: false,
    showRecentSymbol: false,
    searchedSymbols: [],
    searchedSymbolsWidth: 0,
    columnWidths: {},

}
const SymbolEntryReducer = (state = initialState, action) => {
    switch (action.type) {
        case DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY:
            state.isSymbolConvertedDialogAvailable = SettingsStore.getConsoleSettings().NavDatagraphSettings.isSymbolConvertedDialogAvailable
            if(action.pricePanelData.volumePriceData){
                state.isFlagged = action.pricePanelData.volumePriceData.isFlag; 
            }
            if (state.recentSymbols.length >= 100) {
                state.recentSymbols.splice(99, 1);
              }
            if (action.pricePanelData.SymbolInfo.Symbol && action.pricePanelData.SymbolInfo.CompanyName) {
                const exchangeCode = !action.pricePanelData.SymbolInfo.exchange ? " " : action.pricePanelData.SymbolInfo.exchange;
                const searchSymbol = action.pricePanelData.SymbolInfo.Symbol.toUpperCase();
                const localSym = UserInfoUtil.hasLocalSymbol() && action.pricePanelData.SymbolInfo.localSymbol ? action.pricePanelData.SymbolInfo.localSymbol : "";
                state.loadedSymbol = state.symbol = searchSymbol;
                state.recentSymbols = state.recentSymbols.filter((item)=> item.symbol !== searchSymbol);
                state.recentSymbols.insert(0, { localSymbol: action.pricePanelData.SymbolInfo.localSymbol, symbol: action.pricePanelData.SymbolInfo.Symbol, companyName: action.pricePanelData.SymbolInfo.CompanyName.trim(), exgCode: exchangeCode.trim() });
                state.companyNameDisplay = localSym ? `${action.pricePanelData.SymbolInfo.CompanyName} | ${localSym}`: `${action.pricePanelData.SymbolInfo.CompanyName}`
            }
            return {
                ...state
            };

        case DataGraphConstants.ActionTypes.UPDATE_SYMBOL_ENTRY_FLAG:
            return {
                ...state,
                isFlagged: action.isFlagged
            }
        case DataGraphConstants.ActionTypes.UPDATE_SYMBOL_ENTRY_STATES:
            return {
                ...state,
                ...action.states
            }
        case DataGraphConstants.ActionTypes.CLOSE_SYMBOL_CONVERTED_DIALOG:
            return {
                ...state,
                isSymbolConvertedDialogOpen: false
            }
        case DataGraphConstants.ActionTypes.CLOSE_SYMBOL_ENTRY_ERROR_DIALOG:
            return {
                ...state,
                hasError: false,
                symbol: state.loadedSymbol,
                isInputActive: false,
            }
        case DataGraphConstants.ActionTypes.UPDATE_SYMBOL_CONVERTED_DIALOG_AVAILABLE:
            SettingsStore.getConsoleSettings().NavDatagraphSettings.isSymbolConvertedDialogAvailable = !state.isSymbolConvertedDialogAvailable;
            return {
                ...state,
                isSymbolConvertedDialogAvailable: !state.isSymbolConvertedDialogAvailable
            }
        default:
            return state;
    }
}
export default SymbolEntryReducer;