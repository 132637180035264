import React, { Component } from 'react';

export default class ProgressLoadingBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="fade modal-backdrop in"></div>
                <div role="dialog" className="modal-popup loadingdialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="modal-title">
                                    <span className="cap-header">Progress</span>
                                </div>
                            </div>
                            <div className="modal-body">
                                
                                <div className="progressing">
                                    <p>{this.props.progressMsg}</p>
                                    <div className="progress-track">
                                        <div className="progress-tracker" style={{ width: this.props.progressPercentage }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}