import NewTargetInfo from './NewTargetInfo';
import NewArithPriceScale from '../../Utils/Scales/NewArithPriceScale';


import NewSLogPriceScale from '../../Utils/Scales/NewSLogPriceScale';
export const ScaleType = {
    Lin: "Lin",
    LogAuto: "LogAuto"
};

export default class GraphPanelModelStates {
    constructor() {
        this.nodeWidth = 0;
        this.numOfChartNodes = 0;
        this.lastTradeDate = null;
        this.chartWidth = 0;
        this.symbolRequest = [];
        this.indexRequest = [];
        this.timeLineData = [];
        this.scaleType = ScaleType.Lin;
        this.scaleBasedValue = 100;
        this.latestDate = new Date();
        this.initialDate = null;
        this.graphStartDate = null;
        this.StartChartIndex = 0;
        this.mcSymbol = "0DJIA";
        this.priceScaleInfo = new NewTargetInfo();
        this.priceScale = new NewArithPriceScale();
        this.priceLogScale = new NewSLogPriceScale();
        this.chartMinPrice = 0;
        this.chartMaxPrice = 0;
        this.chartHeight = 0;
        this.liqfilterdata = null;
        this.liqFilterState = null;
    }
}
