import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import NotificationContent from "../Preferences/NotificationContent";
import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions.js";
import LocalizationStore from 'LocalizationStore';
import MenuDialogType from "../../Constants/MenuDialogType.js";

//import MaskedInput from 'react-text-mask';

export default class NotificationContentContainer extends Component {
    constructor(props) {
        super(props);
    }

    backToParentWindow = () => {
        this.props.hideModal();
        MenuCommonDialogActions.openMenuCommonDialog({ eventKey: MenuDialogType.Preferences });
    }


    render() {
        return (
            <div id="notificationContent">
                <Modal className="modal-popup Notification" show={this.props.open}>
                    <Modal.Header>
                        <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.props.hideModal} ><span className="icn-close"></span></button> 					
                        {this.props.showBackButton && <span className="icn-back-btn" id="backButton" onClick={this.backToParentWindow}>{LocalizationStore.getTranslatedData('back', "Back")}</span>}
                        <Modal.Title><span className="cap-header">{LocalizationStore.getTranslatedData('set_pre_nap', "NOTIFICATION & ALERT PREFERENCES")}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-region">
                            <NotificationContent/>
                            <div className="clearfix"></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </div>
        );
    }
}