import ConsoleStore from "ConsoleStore";
import { dispatch } from '../../../Redux/dispatch.js';
import { PrintMode } from '../../../print/printmode';
import PropTypes from 'prop-types';
import StringUtil from '../../../Utils/StringUtil';
import { updateContextHit,updateFundamentalLineMultiplier } from '../../../Actions/PricePanelActions.js';
import { updateShowToolTip } from '../../../Actions/DatagraphActions';
import React, { Component } from "react";

export default class ChartVisual extends Component {
  constructor(props) {
    super(props);
    this.PriceMenu,
      this.state = {
        source: props.DataSource,
        source2: props.DataSource2,
        dragging: false,
        draggable: props.Draggable,
        lineThickness: props.LineThickness,
        lineColor: props.LineColor,
        lineThickness2: props.LineThickness2,
        lineColor2: props.LineColor2,
        priceMenu: props.PriceMenu,
        lineID: props.lineID,
        lineID2: props.lineID2
      };
    this.isDrag = 0;
  }
  subscribeEvents() {
    document.addEventListener("mousemove", this.handleMouseMove.bind(this), false);
    document.addEventListener("mouseup", this.handleMouseUp.bind(this), false);
    //    document.addEventListener("mouseover", this.handleMouseOver.bind(this), false);
  }
  unsubscribeEvents() {
    document.removeEventListener("mousemove", this.handleMouseMove.bind(this), false);
    document.removeEventListener("mouseup", this.handleMouseUp.bind(this), false);
    //    document.removeEventListener("mouseover", this.handleMouseOver.bind(this), false);
  }

  getClosestParent(elem, selector) {
    while (elem !== document) {
      elem = elem.parentNode;
      if (elem.id === selector) {
        return elem;
      }
    }
    return null;
  }
  handleMouseDown(e) {
    if (e.button === 2) {
       this.handleRightClick(e);
    }
    else {
      setTimeout(()=> dispatch(updateShowToolTip(false)), 0)
      this.isDrag = 1;
      this.subscribeEvents();
      if (this.setQuoteReceive)
        this.setQuoteReceive(true);
      this.setState({ dragging: true, y: e.pageY, currY: e.pageY });
      this.unsubscribeEvents();
    }
  }
  hitTest(x, y) {
      //Fix for PANWEB - 1068 - Check if any modal open
      const openModals = document.getElementsByClassName('modal-open');
      if (openModals.length >= 1) {
          return 0;
      }
      if (this.isPointInStroke(x, y)) {
          return 1;
      }
      if (this.isPointInStroke2(x, y)) {
          return 2;
      }
  }
  isPointInStroke(x, y) {
      const prcLength = this.state.source.length;
      for (let j = 0; j < prcLength; j++) {
          const linePoint = this.state.source[j];
          if (linePoint &&
              (linePoint.xAxis >= x - 3 && linePoint.xAxis <= x + 3) &&
              (linePoint.yPrice >= y - 3 && linePoint.yPrice <= y + 3)) {
              return true;
          }
      }
      return false;
  }
  handleRightClick(e) {
      if (!this.main || e.button !== 3) return;

      const dimensions = this.main.getBoundingClientRect();
      const xAxis = parseInt(e.clientX - dimensions.left);
      const yAxis = parseInt(e.clientY - dimensions.top);
      const isHit = this.hitTest(xAxis, yAxis);

      if (isHit === 1 && this.state.maValue) {
          dispatch(updateContextHit({lineID: this.props.lineID, index: this.props.index}))
          e.preventDefault();
      }
      else
      if (isHit === 2 && this.state.maValue) {
          dispatch(updateContextHit({lineID: this.props.lineID, index: this.props.index}));
          e.preventDefault();
      }
      else
      if (isHit === 0) {
          dispatch(updateContextHit({}))
          e.preventDefault();
      }
      else {
          if (this.hitTestForLabel(xAxis, yAxis)) {
              dispatch(updateContextHit({}))
              e.preventDefault();
          }
      }
  }
  handleMouseUp(e) {
    if (e.button === 2) {
    }
    else {
      if (this.state.dragging && this.state.draggable) {
        //Log Usage
        if (this.isDrag === 1) {
          ConsoleStore.trackUsage('LineIsMoved');
        }
        setTimeout(()=> dispatch(updateShowToolTip(true)), 0)
        this.isDrag = 0;
        this.unsubscribeEvents();
        if (this.setQuoteReceive)
          this.setQuoteReceive(false);
        this.setState({ dragging: false });
        
        dispatch(updateFundamentalLineMultiplier(e.pageY,this.props.isPlaying ? this.state.y : this.state.currY,this.state.type))
      }
    }
  }
  handleMouseMove(e) {
    if (this.state.dragging && this.state.draggable) {
      this.isDrag = 1;
      if (this.state.source.length < 1) {
        return;
      }
      e.preventDefault();
      let len = this.state.source.length;
      for (var index = 0; index < len; index++) {
          if (this.state.source[index]) {
              this.state = {
                  ...this.state,
                  source: this.state.source.map((s, k) => {
                      if (k === index) {
                          s.yPrice = this.state.source[index].yPrice - (this.state.y - e.pageY)
                      }
                      return s
                  })
              }
          }
          if (this.state.source2 !== undefined && this.state.source2[index]) {
          this.state = {
              ...this.state, source2: this.state.source2.map((s, k) => {
                  if (k === index) {
                      s.yPrice = this.state.source2[index].yPrice - (this.state.y - e.pageY)
                  }
                  return s
              })
          }
          //this.state.source[index].yPrice = this.state.source[index].yPrice - (this.state.y - e.pageY);
        }
      }
      this.state = { ...this.state, y: e.pageY };
      //this.state.y = e.pageY;
      this.setState(this.state);
    }
  }
  handleMouseOver(e) {
    return e;
  }
  handleMouseLeave(e) {
    return e;
  }
  prepareSvgLine(lineData) {
    if (lineData === undefined || lineData === null || lineData[0] === undefined || lineData.length < 1) {
      return " ";
    }
    const f = lineData[0];
    if (f === undefined || isNaN(f.yPrice)) return " ";
    const d = [("M " + f.xAxis + " " + f.yPrice.toFixed(0))];
    let collector = lineData.map((obj) => {
      if(obj.yPrice) {
      const xNext = obj.xAxis;
      const yNext = obj.yPrice.toFixed(0);
      return `L ${xNext} ${yNext}`;
      }
    });
    collector = collector.filter((item) => item !== undefined);

    return !StringUtil.isEmpty(collector) && d.concat(collector).join(" ");
  }
  getDraggingState() {
    return (this.state.dragging && this.state.draggable) ? "dragging" : "";
  }
  onContextMenuClick(e) {
    e.preventDefault();
  }
  handleOnClick() {
    //Log Usage
    ConsoleStore.trackUsage('LineIsSelected');
  }
  getDraggableView(lineStyle, dashValue = 0) {
      const pathData = this.prepareSvgLine(this.state.source);
      const pathData2 = this.state.source2 ? this.prepareSvgLine(this.state.source2) : undefined;
      const sWidth = this.state.draggable ? "5px" : "0px";

    return (<svg style={lineStyle}
      id="RSLayer"
      height='100%'
      width='100%'>
      {!StringUtil.isEmpty(pathData) && <path d={pathData} data-disable-track-price="true"
        ref={(ref) => this.line = ref}
        className={this.getDraggingState()}
        stroke={this.state.lineColor}
        strokeDasharray={dashValue}
        strokeWidth={this.state.lineThickness}
        onContextMenu={this.onContextMenuClick.bind(this)}
        fill="none"
        pointerEvents="none">
        {this.state.lineID !== '' &&
          <title>{this.state.lineID}</title>
        }
      </path>}
      {!StringUtil.isEmpty(pathData) && <path d={pathData} data-disable-track-price="true"
        strokeWidth={sWidth}
        stroke="transparent"
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseOver={this.handleMouseOver.bind(this)}
        onFocus={this.handleMouseOver.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        onClick={this.handleOnClick.bind(this)}
        fill="none"
        cursor="pointer"
        pointerEvents="painted">
        </path>}
        {this.state.source2 !== undefined && !StringUtil.isEmpty(pathData2) &&
            <path d={pathData2} data-disable-track-price="true"
            ref={(ref) => this.line = ref}
            className={this.getDraggingState()}
            stroke={this.state.lineColor2}
            strokeDasharray={dashValue}
            strokeWidth={this.state.lineThickness2}
            onContextMenu={this.onContextMenuClick.bind(this)}
            fill="none"
            pointerEvents="none">
            {this.state.lineID2 !== '' &&
              <title>{this.state.lineID2}</title>
            }
        </path>
        }
        {this.state.source2 !== undefined && !StringUtil.isEmpty(pathData2) &&
            <path d={pathData2} data-disable-track-price="true"
                strokeWidth="5px"
                stroke="transparent"
                onMouseDown={this.handleMouseDown.bind(this)}
                onMouseOver={this.handleMouseOver.bind(this)}
                onFocus={this.handleMouseOver.bind(this)}
                onMouseLeave={this.handleMouseLeave.bind(this)}
                onClick={this.handleOnClick.bind(this)}
                fill="none"
                cursor="pointer"
                pointerEvents="painted">
            </path>
        }
    </svg>);
  }
}
ChartVisual.defaultProps = {
  isPrintMode: PrintMode.printing // For testing set this to true
}
ChartVisual.propTypes = {
  DataSource: PropTypes.array,
  Draggable: PropTypes.bool,
  priceMenu: PropTypes.array,
  isPrintMode: PropTypes.bool
};
