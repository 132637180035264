import {
    watchOpenAndAlertDialog
} from './AlertDialogSaga';

import {
    watchGetAlertDate,
    watchCreateMovingAverageAlert,
    watchDeleteAllActiveAlerts,
    watchAlertDataSource,
    watchReactivateAlert,
    watchDeletePriceAlert,
    watchCreatePriceAlert,
    watchUpdatePriceAlert,
    watchInitAlertOnSymbolChange,
    watchSaveAlertData,
    watchStartAlertsDataWorker,
    watchPostAlertDataWorker
} from './DataGraphAlertSaga';

export const AlertWatcher = {
    watchGetAlertDate,
    watchCreateMovingAverageAlert,
    watchDeleteAllActiveAlerts,
    watchAlertDataSource,
    watchReactivateAlert,
    watchDeletePriceAlert,
    watchCreatePriceAlert,
    watchUpdatePriceAlert,
    watchInitAlertOnSymbolChange,
    watchSaveAlertData,
    watchStartAlertsDataWorker,
    watchPostAlertDataWorker,

    watchOpenAndAlertDialog
};