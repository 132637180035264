import { connect } from 'react-redux';
import { getAnnotationsCount } from '../../../../../../Reducers/AnnotationReducers/reselectorsdata';
import { handleDeleteAllAnnotation } from "../../../../../../Actions/AnnotationActions";
import moment from "moment";
import React, { Component } from 'react';

let annotations_Count, layers_Count, created_Date, updated_Date;

class RIPanelAnnotationContent extends Component {
    render() {
        const layerInfo = layers_Count > 1 ? ' layers' : ' layer';

        return (
            <div className="annotation-report">
                <ul>
                    <li>
                        <span className="heads">Annotations:</span>
                        <span className="datas">{annotations_Count} on {layers_Count} {layerInfo}</span>
                    </li>
                    <li>
                        <span className="heads">Created:</span>
                        <span className="datas">{created_Date !== '' ? moment(created_Date).format('MMM DD, YYYY h:mm A') : ''}</span>
                    </li>
                    <li>
                        <span className="heads">Last Updated:</span>
                        <span className="datas">{updated_Date !== '' ? moment(updated_Date).format('MMM DD, YYYY h:mm A') : ''}</span>
                    </li>
                </ul>
                <div className="annotation-report-delete">
                    <a onClick={() => this.props.deleteAllAnnotation(true)}>Delete All Annotations</a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { isDrawing, isDragging } = state.annotationReducers.annotationChartModelReducer;

    { /* avoid recomputation of count and date while drawing and draggging annotations */ }
    if (!isDrawing && !isDragging) {
        const { annotationsCount, layersCount, createdDate, updatedDate } = getAnnotationsCount(state);
        annotations_Count = annotationsCount;
        layers_Count = layersCount;
        created_Date = createdDate;
        updated_Date = updatedDate;
    }

    return ({ annotations_Count, layers_Count, created_Date, updated_Date });
};

const mapDispatchToProps = (dispatch) => ({
    deleteAllAnnotation: (isRiPanelDelete) => dispatch(handleDeleteAllAnnotation(isRiPanelDelete))
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelAnnotationContent)