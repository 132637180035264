import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class DeleteAlertRequest2 extends BaseServiceRequest {
    constructor(alertID, alertTypeID) {
        super();
        super.addRequestUri("delete2.pb");
        super.addPathParameter(alertID.toString());
        super.addRequestParameter("alertTypeId", alertTypeID);
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "Response";
    }
}