import React, { Component } from "react";
import ReactDOM from "react-dom";
import styles from "./PrintStyle";
import { Table } from "react-bootstrap";
import LocalizationStore from 'LocalizationStore';
import ConsoleActions from "../../Actions/ConsoleActions";
import PrintStatus from "../../Constants/PrintStatus";

export default class PortalDialog extends Component {
    constructor(props) {
        super(props);
        this.cancelPrint = this.cancelPrint.bind(this);
        this.containerEl = null
        this.externalWindow = null;
        this.state = {
            errorSymbols: []
        };
    }



    componentDidMount() {
        if (!this.open) {
            const left = (screen.width - 480);
            const top = (screen.height - 320);
            this.externalWindow = window.open('', '', `width=${450},height=${200},left=${left},top=${top}`);
            if (!this.externalWindow) {
                this.props.closeWindowPortal();
                this.props.onPopUpBlockerActive();
                return;
            }
            if(this.externalWindow){
                this.externalWindow.document.title = this.props.title;
                this.containerEl = this.externalWindow.document.createElement('div')
                this.containerEl.style.cssText = 'height: 100%; position: absolute;width: 100%;top: 0;left: 0;';
                this.externalWindow.document.body.appendChild(this.containerEl);
                let selfOpening = this;
                this.externalWindow.onbeforeunload = () => selfOpening.props.closeWindowPortal();
                this.open = true;
            }
        }
    }
    componentDidUpdate() {
        if (!this.open) {
            const left = (screen.width - 480);
            const top = (screen.height - 320);
            this.externalWindow = window.open('', '', `width=${450},height=${200},left=${left},top=${top}`);
            if(this.externalWindow){
                this.externalWindow.document.title = this.props.title;
                this.containerEl = this.externalWindow.document.createElement('div')
                this.containerEl.style.cssText = 'height: 100%; position: absolute;width: 100%;top: 0;left: 0;';
                this.externalWindow.document.body.appendChild(this.containerEl);
                this.open = true;
            }
        }
    }

    cancelPrint(event) {
        ConsoleActions.cancelPrint(event.target.value);
    }

    renderPrintProgress() {
        return (
            <Table cellSpacing="0" cellPadding="0" style={styles.tableResponsive} >
                <thead style={styles.customPrintHead}>
                    <tr style={styles.headTr}>
                        <th style={styles.headThName}>{LocalizationStore.getTranslatedData("tp_pt_dn", "Document Name")}</th>
                        <th style={styles.headThStatus}>{LocalizationStore.getTranslatedData("tp_pt_status", "Status")}</th>
                        <th style={styles.headThProgress}>{LocalizationStore.getTranslatedData("tp_pt_progress", "Progress")}</th>
                        <th style={styles.headThAction}>{LocalizationStore.getTranslatedData("tp_pt_act", "Action")}</th>
                    </tr>
                </thead>
                <tbody style={styles.customPrintBody} >
                    {
                        this.renderPrintJob()
                    }
                </tbody>
            </Table>
        );
    }

    getErrorSymbols(errorSymbols) {
        const errorSymbolsList = JSON.parse(errorSymbols);
        return errorSymbolsList.map((arr, index) =>
        (
        // <tr style={styles.bodyTr} key={index} >
        //     <th style={styles.bodyTd} colSpan="4">{arr.symbolName}</th>
        // </tr>
        
            <li style={styles.errorListLi} key={index} >{arr.symbolName}</li>

        )
        );
    }
toggleErrorSymbols(reqid){
    if(this.state.errorSymbols.includes(reqid)){
        this.setState({
            errorSymbols: this.state.errorSymbols.filter(ids => ids !== reqid)
        })
    }else{
        this.setState({
            errorSymbols: [...this.state.errorSymbols,reqid]
        })
    }
}
    renderPrintJob() {
        return this.props.PrintJobs.map((job, index) =>
            <React.Fragment key={index}>
                <tr style={styles.bodyTr} key={job.PrintRequestId}>
                    <td style={styles.bodyTdFirst} ><div style={styles.bodyTdFirstHold}>{job.Symbol}</div></td>
                    <td style={styles.bodyTd}>{job.Pages}</td>
                    <td style={styles.bodyTd}><progress style={styles.bodyTdProgress} value={job.Progress} max="100" /></td>
                    <td style={styles.bodyTd}>
                        <button style={styles.bodyTdButton} value={job.PrintRequestId} type="button" onClick={this.cancelPrint} className=" PrintButton average-btn cancel model-action btn btn-xs btn-default btn-secondary small-bold">{LocalizationStore.getTranslatedData("set_cancel", "Cancel")}</button>
                    </td>
                </tr>
                {job.Progress == 100 && job.errorList != undefined && (
                    <tr>
                        <td colSpan="4" style={styles.bodyTrError}>
                            { !this.state.errorSymbols.includes(job.PrintRequestId) && <h5 style={styles.errorListH5} onClick={() => this.toggleErrorSymbols(job.PrintRequestId)}>Errored Pages <span style={styles.carrotR}></span></h5> }
                            { this.state.errorSymbols.includes(job.PrintRequestId) && <h5 style={styles.errorListH5} onClick={() => this.toggleErrorSymbols(job.PrintRequestId)}>Errored Pages <span style={styles.carrotB}></span></h5> }
                            <ul style={styles.errorListUl}>
                                {
                                    this.state.errorSymbols.includes(job.PrintRequestId) && this.getErrorSymbols(job.errorList)
                                }
                            </ul>
                        </td>
                    </tr>
                )}

                {job.Symbols.length > 1 && job.Status === PrintStatus.Error &&
                    (<tr style={styles.bodyTr} key={job.PrintRequestId + index} >
                        <th style={styles.bodyTd} colSpan="4">{job.Pages} : {job.ErroredPages}</th>
                    </tr>)}
            </React.Fragment>
        );
    }
    renderPrint() {
        return (<div style={styles.printScreenBlock}>
            {this.renderPrintProgress()}
        </div>);
    }

   

    componentWillUnmount() {
        if (this.externalWindow) {
            this.props.closeWindowPortal();
            this.externalWindow.close();
        }
    }

    render() {
        if (!this.containerEl) {
            return null;
        }
        return ReactDOM.createPortal(this.renderPrint(), this.containerEl);
    }
}