import { MenuStates } from '../../../Models/NavCompare/ComparisonAndRotationMenuModels/MenuStates';
import { ComparisonAndRotationMenuConstants, RotationGraphConstants,  } from "RotationGraphConstants";
import { extend, map } from "underscore";
import CompareTabType from "CompareTabType";
import { ComparisonGraph } from "Constants/ComparisonGraph";
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import { SettingsConstants } from "Constants/SettingsConstants.js";
extend(ActionTypes, SettingsConstants.ActionTypes, RotationGraphConstants.ActionTypes, ComparisonGraph.ActionTypes);

let initialState = {
    showTitle: false,
    posX: null,
    posY: null
}

extend(initialState, { ComparisonMenuStates: new MenuStates(), RotationMenuStates: new MenuStates() });

const SymbolsPanelReducer = (state = initialState, action) => {

    const setStateData = (obj) => {

        if (state.selectedTab === CompareTabType.ComparisonGraph) {
            map(obj, (value, property) => state.ComparisonMenuStates[property] = value);
        }
        else {
            map(obj, (value, property) => state.RotationMenuStates[property] = value);
        }

        return { ...state };
    }


    const getStateData = () => {

        if (state.selectedTab === CompareTabType.ComparisonGraph) {
            return state.ComparisonMenuStates;
        }
        else {
            return state.RotationMenuStates;
        }
    }

    switch (action.type) {

        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
            return {
                ...state,
                selectedTab: action.selectedTab
            }
          
        case ActionTypes.HANDLE_TITLE_MOUSEOVER:
            return {
                ...state,
                showTitle: action.titleShow,
                posX: action.posx,
                posY: action.posy,
            }
        case ActionTypes.SHOW_CONTEXT_MENU_SUCCESS:
            return setStateData({
                contextManu: {
                    ...getStateData().contextManu,
                    isShowContextMenu: true,
                    contextMenuData: action.contextMenuData,
                    pos: action.pos,
                    contextItem: action.item
                }
            });

        case ActionTypes.HIDE_CONTEXT_MENU:
            return setStateData({
                contextManu: {
                    ...getStateData().contextManu,
                    isShowContextMenu: false,
                    pos: {
                        x: 0,
                        y: 0
                    },
                    contextItem: null
                }
            });

        case ActionTypes.SET_SYMBOLS_LISTITEMS:
            return setStateData({ 'listItems': action.listItems });

        case ActionTypes.SET_SYMBOLS_STOCK_LISTITEMS:
            return setStateData({ 'stockList': action.stockList })

        case ActionTypes.SET_LISTALERT_MSG:
            return setStateData({ 'alertMessage': action.alertMessage });

        case ActionTypes.SHOW_LISTALERT_MSG:
            return setStateData({ 'showAddListAlertMessage': action.showAddListAlertMessage });

        case ActionTypes.SET_ADDLIST_MODAL_SHOW_STATUS:
            return setStateData({ 'addListModalShowStatus': action.status });

        case ActionTypes.SET_ADDLIST_LISTNAME:
            return setStateData({ 'listName': action.listName });

        case ActionTypes.ADD_SYMBOLS:
            return setStateData({ 'symbolsList': action.symbolsList, 'selectedOsid': action.selectedOsid });

        case ActionTypes.CLEAR_ROTATION_SYMBOLS_DATA:
            return setStateData({
                selectedOsid: "",
                symbolsList: [],
                listItems: [],
                lastHighlightedOsids: [],
                stockList: [],
                countryCode: null,
                regionCode: null,
                countryAggArray: [],
                regionAggArray: [],
                countryCodeList: '',
                regionCodeList: '',
                quadGraphResults: {},
                stocksTotalList: [],
                groupResult: {},
                quadGraphYearList: []
            });

        case ActionTypes.SET_SYMBOLS_QUAD_DATA:
            return setStateData({
                "stocksTotalList": action.stocksTotalList,
                "quadGraphYearList": action.quadGraphYearList,
                "groupResult": action.groupResult
            });

        case ActionTypes.CLEAR_SYMBOLS_QUAD_DATA:
                return setStateData({
                    "stocksTotalList": [],
                    "quadGraphYearList": [],
                    "groupResult": {}
                });

        case ActionTypes.UPDATE_SYMBOLS_QUAD_GRAPH_RESULT:
            return setStateData({ 'quadGraphResults': action.quadGraphResults });

        case ActionTypes.REMOVE_ROTATION_LIST_ITEMS:
            return setStateData({
                selectedOsid: action.selectedOsid,
                symbolsList: action.symbolsList,
                listItems: action.listItems,
                stockList: action.stockList
            });

        case ActionTypes.TOGGLE_CLEAR_ALL_DIALOG:
            return setStateData({
                isClearAllDialogOpen: !getStateData().isClearAllDialogOpen
            });

        case ActionTypes.UPDATE_BENCHMARK_INDEX_CODE:
            return setStateData({
                countryCode: action.countryCode,
                regionCode: action.regionCode
            });

        case ActionTypes.HIGHLIGHT_SYMBOLS_ITEM_SUCCESS:
            return setStateData({
                isHighLighted: !getStateData().isHighLighted
            }); 
            
        case ActionTypes.INIT_FROM_SETTINGS_DATA:
            return setStateData({
                listName: action.settingsData.listName
            });
            
        default:
            return state
    }
}

export default SymbolsPanelReducer;