import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetSymbolSearchRequest extends BaseServiceRequest {
  constructor(symbol,SearchCount, rgview, isfinal) {
    super();
    super.addRequestUri("search2.pb");
    super.addRequestParameter("text", symbol);
    super.addRequestParameter("count", SearchCount);
    super.addRequestParameter("rgview", rgview);
    super.addRequestParameter("rgview", isfinal);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return "SymbolSearchResponse";
  }
}

export default GetSymbolSearchRequest;
