import React from "react";
import ReactHtmlParser from "react-html-parser";
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import { useSelector } from "react-redux";

function RatingControl() {
  const data = useSelector((state) => state.summaryReducer.ratingData)

  const renderSpace = (spaceNum) => {
    let result = "";
    for (let i = 0; i < spaceNum; i++) {
      result += "&nbsp;";
    }
    return <span>{ReactHtmlParser(result)}</span>;
  }

  const renderSmrRating = () => {
    if (data && data.smrRating && data.smrRating.trim().length > 0) { return <span>{renderSpace(data.smrRatingSpaceNum)}{data.smrRating}</span>; }
    return <span>--</span>;
  }

  const renderRatingTurnoverAndRankDisplayValue = () => {
    if (data && data.ratingTurnoverAndRankDisplayValue) {
      return <div className="data-cell single-cell">
        <span className="label bolds">{SummaryTransLateHelper.INV_TO_TEXT}</span>
        <span>{data.ratingTurnoverAndRankDisplayValue}</span>
      </div>;
    }
    return <div className="data-cell opacity50 single-cell">
      <span className="label bolds">{SummaryTransLateHelper.INV_TO_TEXT}</span>
      <span>--</span>
    </div>;
  }
  return (
    <div className="rating-info-block">
      <div className="info-row">
        <div className="data-cell">
          <span className="label">{SummaryTransLateHelper.COMP_RATING_TEXT}</span>
          <span>{data ? data.compRating : ""}</span>
        </div>
        <div className="data-cell">
          <span className="label">{SummaryTransLateHelper.SMR_RATING_TEXT}</span>
          {renderSmrRating()}
        </div>
      </div>
      <div className="info-row">
        {renderRatingTurnoverAndRankDisplayValue()}
      </div>
    </div>
  );
}

export default RatingControl
