module.exports = {
  "666777": "#777",
  "999000": "#999",
  "sprite": "/Asset/images/darkSprite.png",
  "sprite2x": "/Asset/images/darkSprite2x.png",
  "chartGridColor": "#262626",
  "eighteenMnth": "#333333",
  "fff333": "#fff",
  "fff000": "#fff",
  "000fff": "#090926",
  "fff666": "#fff",
  "ddd333": "#ddd",
  "f1f1f1EBEBEB": "#1f1f1f",
  "E1E20FEFEF0": "#1E1E20",
  "999999333333": "#999999",
  "1E1E1FDBDCDC": "#1E1E1F",
  "2C2C2CE9E9E9": "#2C2C2C",
  "000000F2F2F2": "#000000",
  "E0E189FF6700": "#E0E189",
  "CCCCCC262626": "#CCCCCC",
  "999999000000": "#999999",
  "191919666666": "#191919",
  "3B3C42CCCCCC": "#3B3C42",
  "77CAFE04478A": "#77CAFE",
  "scaleText": "#CCCCCC",
  "insiderLine": "#42424a",
  "dateText": "#CCCCCC",
  "dividerColor": "#090926",
  "overBought": "#670000",
  "overSold": "#006600",
  "PTOIndex": "#4C4C4C",
  "PTOEStock": "#FFCC99",
  "PTOSStock": "#99FF99",
  "grayCmLb": "gray",
  "sma10": "#0066CC",
  "pointerBox": "#262626",
  "tiLabel": "#cccccc",
  "applicationHtml": "#191922",
  "applicationBody": "#090926",
  "AppBackground": "#090926",
  "AppSecondaryBackground": "#0000ff",
  "activeHighlightBackground": "#b8daff",
  "textDefault": "#999999",
  "textTitles": "#ffffff",
  "inactiveText": "#333333",
  "linkText": "#77cafe",
  "linkTextMouseover": "#ffffff",
  "positiveDataText": "#018fee",
  "positiveDataTextHover": "#77cafe",
  "negativeDataText": "#ff33cc",
  "negativeDataTextHover": "#fd7ef2",
  "estimateDataText": "#E0E189",
  "positiveNode": "#0065d1",
  "negativeNode": "#f0009d",
  "textNewsStemp": "#ccc",
  "grayText": "#888888",
  "dogear": "#FAFD02",
  "headerTabNavBg": "#090926",
  "tabLabel": "#999999",
  "tabBg": "#090926",
  "tabBgHover": "#4c4c51",
  "tabBgActive": "#4c4c51",
  "tabDivider": "transparent",
  "tabDividerHover": "#444444",
  "tabLabelHover": "#ffffff",
  "tabLabelActive": "#ffffff",
  "tabSearchInputBg": "#FFF",
  "tabSearchInputTxt": "#000",
  "volRateLabel": "#999999",
  "buttonLabel": "#ffffff",
  "buttonLabelShadow": "#000000",
  "buttonBgTop": "#42424b",
  "buttonBgTopHover": "#595a63",
  "buttonBgBottom": "#283031",
  "buttonBgBottomHover": "#494952",
  "buttonStroke": "#000000",
  "buttonLabelGray": "#999999",
  "buttonLabelHover": "#ffffff",
  "buttonLabelShadowHover": "#000000",
  "ideasBgTop": "#42424b",
  "ideasBgTopHover": "#595a63",
  "ideasBgBottom": "#283031",
  "ideasBgBottomHover": "#494952",
  "buttonLabelActive": "#000000",
  "buttonLabelShadowActive": "#b8daff",
  "buttonBgActive": "#b8daff",
  "buttonLabelInactive": "#999999",
  "buttonLabelShadowInactive": "#000000",
  "buttonBgTopInactive": "#42424b",
  "buttonBgBottomInactive": "#283031",
  "buttonSecondaryLabel": "#333333",
  "buttonSecondaryBgTop": "#f6f6f6",
  "buttonSecondaryBgBottom": "#dbdbdb",
  "buttonSecondaryBgBottomHover": "#f6f6f6",
  "buttonSecondaryStroke": "#666666",
  "buttonInfoBgTop": "#385f77",
  "buttonInfoBgTopHover": "#3797e0",
  "buttonInfoBgBottom": "#183343",
  "buttonInfoBgBottomHover": "#1b5e9b",
  "financialDividerLines": "#2D343B",
  "contextMenuText": "#000000",
  "contextMenuBg": "#ececec",
  "contextMenuStroke": "#b3b3b3",
  "contextMenuListHover": "#bddeff",
  "contextMenuInactiveText": "#666666",
  "contextMenuDividers": "#b3b3b3",
  "contextMenuCheckmark": "#000000",
  "contextMenuSecondaryLinks": "#006699",
  "contextMenuListHoverTop": "#c8e3fe",
  "contextMenuListHoverBottom": "#a9d2fe",
  "fundamentalConsoleBlockTitleTop": "#37393d",
  "fundamentalConsoleBlockTitleBottom": "#0e1116",
  "fundamentalConsoleRowtableBgHover": "#2B2F34",
  "fundamentalConsoleSearchResultBg": "#000000",
  "fundamentalConsoleValue": "#77CAFF",
  "fundamentalConsoleTitle": "#bdd683",
  "tableHeaderBgTop": "#090926",
  "tableHeaderBgBottom": "#090926",
  "fundamentalConsoleExploreListBg": "#37393d",
  "fundamentalConsoleExploreListColor": "#d0d0d0",
  "headCellTableHeaderTop": "#575a48",
  "headCellTableHeaderBottom": "#3a3c31",
  "fundamentalConsoleSortingTop": "#42454a",
  "fundamentalConsoleSortingBottom": "#2e3136",
  "fundamentalConsoleSortingTopHover": "#606267",
  "fundamentalConsoleSortingBottomHover": "#44474d",
  "fundamentalRadioBorder": "#333",
  "headTableGreenHeading": "#BDD396",
  "headTableGrayTxt": "#CACACA",
  "fundamentalFrozenBorder": "#000",
  "fundamentalValueLessDay": "#bdd683",
  "fundamentalValueLessDayHover": "#fff",
  "zebraStripeColor": "#0E1216",
  "footerBg": "#161618",
  "footerTabBgTop": "#424242",
  "footerTabBgBottom": "#191919",
  "footerTablinkText": "#77cafe",
  "footerTabActive": "#090926",
  "footerTabDisabled": "#A9A9A9",
  "footerTabBorder": "#444",
  "footerlogoWrapBorder": "#090926",
  "footerTablinkTextShadow": "#000000",
  "logoWrapBorderOne": "#5a5d5a",
  "logoWrapBorderTwo": "#000000",
  "versionAppText": "#999",
  "gridRowActiveBg": "#2C4258",
  "pointerColor": "#3b3c42",
  "riPanelBg": "#161618",
  "riBorderColor": "#161719",
  "riActionBg": "#3C3E42",
  "startGradientColor": "#3b3c42",
  "endGradientColor": "#27292c",
  "ownershipBorderColor": "#999999",
  "ownershipHeaderBg": "#1E1E1F",
  "cellActiveBg": "#414347",
  "idealistWellBg": "#1f1f1f",
  "idealistWellText": "#999999",
  "holdingAmountBg": "#45474B",
  "indicatorBrush1": "#46D9B2",
  "indicatorBrush2": "#FF8A48",
  "indicatorBrush3": "#888888",
  "activeFundBg": "#2F3A4F",
  "sideBarStartColor": "#716d6d",
  "sideBarEndColor": "#090926",
  "sideBarHoverStartColor": "rgba(113, 113, 113, 0.94)",
  "sideBarHoverEndColor": "rgba(25, 25, 25, 1)",
  "newsItemBorderColor": "#26A0DA",
  "allocationFontColor": "#CCCCCC",
  "ideaListFlagButtonBg": "#1F1F1F",
  "ideaListFlagActiveButtonBg": "#191919",
  "entitlementBtnStartBg": "#3a3a42",
  "entitlementBtnEndBg": "#293031",
  "entitlementBorderBrush": "#444444",
  "sectorWeightingGraphColor": "#464646",
  "sectorWeightingGraphBarColor": "#ACD166",
  "newsReloadColor": "#90EE90",
  "idealistflagBorderColor": "#333333",
  "riPanelBorderColor": "#666666",
  "riPanelHeaderStartBg": "#cfd4dd",
  "riPanelHeaderEndBg": "#767a7e",
  "periodicityBGBrushStart": "#43464B",
  "periodicityBGBrushStop": "#2E3136",
  "miniListPanelBg": "#F7F7F7",
  "miniListName": "#77cafe",
  "miniListItemColor": "#999999",
  "miniListNameHover": "#cccccc",
  "sideBarDotsColor": "#6d6666",
  "miniListSelectedBg": "#37393D",
  "miniListSelectedBdr": "#666",
  "miniListSelectedHover": "#fff",
  "chartSeparatorBorder": "#30373F",
  "financialTitleBar": "#090926",
  "financialHorizontalGridBg": "#2B2F34",
  "financeBlockTitleBarBgTop": "#646973",
  "financeBlockTitleBarBgBottom": "#646973",
  "financeBlockTitleColor": "#77CAFF",
  "activePage": "#fff",
  "inActivePage": "#666",
  "summaryBlockBg": "#171D24",
  "summaryBlockTitleBg": "#2B313A",
  "summaryBlockSwatchColor": "#999",
  "summaryBlockInfoRowBg": "#2B333E",
  "summaryBlockBorderColor": "#485461",
  "summaryBlockHeaderText": "#dedede",
  "summaryBlockHeaderCell": "#afafaf",
  "summaryBlockHeaderCellBg": "#2B333D",
  "summaryBlockWeeklyHover": "#2B2F34",
  "summaryBlockWeeklyText": "#c7c7c7",
  "summaryBlockWeeklyBg": "#2B333D",
  "SummaryBlockCarouselIndicatorsBg": "#808080",
  "summaryBlockCRIcon": "-150px -850px",
  "summaryBlockDogEarBg": "yellow",
  "summaryBlockDogEarColor": "#000",
  "summaryBlockDeBorderBg": "#FFFF99",
  "summaryBlockBackgroundPosition": "-50px -33px",
  "summaryBlockBackgroundHoverPosition": "-50px 0",
  "summaryBlockLoadingBackGround": "/Asset/images/SummaryBlock_Bg_Dk.png",
  "verticalScrollMiddle": "#525252",
  "verticalScrollMiddleBorder": "#000000",
  "verticalScrollBackground": "#292929",
  "verticalScrollBorder": "#292929",
  "horizontalScrollMiddle": "#525252",
  "horizontalScrollMiddleBorder": "#000000",
  "horizontalScrollBackground": "#292929",
  "horizontalScrollBorder": "#292929",
  "loadingSpinner": "/Asset/images/loading-dark.gif",
  "layoutLoadingSpinner": "/Asset/images/loading-dark.gif",
  "pePsBgTop": "#090909",
  "pePsBgBottom": "#3d3d3d",
  "pePsBorder": "#30373F",
  "pePsColor": "#928e8e",
  "blockTitleBarBg": "#090926",
  "tabViewBgTop": "#212225",
  "tabViewBgBottom": "#323337",
  "tabViewBgBorder": "#7f7f7f",
  "tabViewBottomFooterTop": "#323337",
  "tabViewBottomFooterBottom": "#212225",
  "groupFilterColor": "#a9a9a9",
  "groupFilterBadgeElementColor": "#a9a9a9",
  "groupFilterBg": "#0e0e0e",
  "gridHeaderTop": "#323338",
  "gridHeaderBottom": "#141518",
  "gridHeadeHoverTop": "#878992",
  "gridHeaderHoverBottom": "#404248",
  "gridHeadeActiveTop": "#54555C",
  "gridHeaderActiveBottom": "#26272b",
  "gridHeaderActiveBorder": "#000000",
  "sortingOrder": "#91d072",
  "gridloading": "#1F2022",
  "gridRowOddBg": "#161719",
  "gridRowEvenBg": "#090926",
  "gridRowOddEvenColor": "#a9a9a9",
  "gridCellSymbolText": "#77cafe",
  "treeViewItemHover": "#37393D",
  "oNeilTabActive": "#444",
  "groupListInnerSection": "#4B4B4B",
  "containerSwitchTab": "#333",
  "groupListInnerBlock": "#161719",
  "groupBoxBorder": "#535353",
  "groupBoxHover": "#414B56",
  "bottomTabBrowserFilterBg": "#4B4B4B",
  "bottomTabBrowserFilterBgInactive": "#333",
  "bottomTabBrowserFilterBgHover": "#4B4B4B",
  "bottomTabBrowserFilterInactiveColor": "#999999",
  "gridRowSelected": "#3d3d3d",
  "gridRowSelectedBorder": "#eee",
  "listPropertiesBg": "#14171A",
  "columnSetTitle": "#999999",
  "listBg": "#090926",
  "listActiveText": "#000",
  "filterBadgeElement": "#77cafe",
  "listPropertiesDetails": "#B1B1B1",
  "oNeilTabActiveBorder": "#999",
  "oNeilTabActiveColor": "#fff",
  "symbolEntryBorderColor": "#444444",
  "symbolEntryMenuBorderColor": "#cccccc",
  "footerRotationBG": "#2b2b2d",
  "footerBottomYear": "#454545",
  "rotationCycleArrow1": "#222222",
  "rotationCycleArrow2": "#262626",
  "rotationAxisLine": "#333333",
  "headCenterRotation": "#999",
  "headHighLevelRotation": "#999",
  "headLowLevelRotation": "#666",
  "RotationsSymbolName": "#a5a5a5",
  "RotationsRiPanelBde": "#222",
  "RotationsRiPanelHeadTopBdr": "#515151",
  "RotationsRiPanelHeadBottompBdr": "#5a5a5a",
  "graphChartAreaPathBg": "#454545",
  "graphChartAreaPathBorder": "#999",
  "graphListActionButtonBg": "#222",
  "graphListActionButtonBorder": "#5a5a5a",
  "graphListActionButtonBgCustomtop": "#42424b",
  "graphListActionButtonBgCustomButtom": "#283031",
  "graphListActionButtonBgCustomtopHover": "#595a63",
  "graphListActionButtonBgCustomButtomHover": "#494952",
  "compareRiPanelBorder": "#5C5C5C",
  "graphtabBg": "#3A3A43",
  "RotationsHeadTopBdr": "#515151",
  "rangeThumbHandle": "/Asset/images/btn-scrubber-dk.svg",
  "rangeThumbHandleActive": "/Asset/images/btn-scrubber-active-dk.svg",
  "rangeThumbHandleHover": "/Asset/images/btn-scrubber-dk-ro.svg",
  "uptrendStroke": "#0066CC",
  "downtrendStroke": "#CC0099",
  "defaultScaleText": "#ffffff",
  "labelRotation": "#fff",
  "labelRotationGrey": "#999",
  "00339904478a": "#003399",
  "a5a5a5333333": "#a5a5a5",
  "77cafe04478a": "#77cafe",
  "ffffff333333": "#ffffff",
  "333333d3d3d3": "#333333",
  "77cafe003399": "#77cafe",
  "0f0f109c9d9f": "#0f0f10",
  "P000000FFFFFF": "#000000",
  "P262626CCCCCC": "#262626",
  "P4C4C4C999999": "#4C4C4C",
  "P9999994C4C4C": "#999999",
  "PCCCCCC262626": "#CCCCCC",
  "PFFFFFF000000": "#FFFFFF",
  "P00333399FFFF": "#003333",
  "P00666633FFFF": "#006666",
  "P00999900CCCC": "#009999",
  "P00CCCC009999": "#00CCCC",
  "P33FFFF006666": "#33FFFF",
  "P99FFFF003333": "#99FFFF",
  "P00003399CCFF": "#000033",
  "P013CB366CCFF": "#013CB3",
  "P0066CC3399FF": "#0066CC",
  "P3399FF0066CC": "#3399FF",
  "P66CCFF013CB3": "#66CCFF",
  "P99CCFF000033": "#99CCFF",
  "P013CB3013CB3": "#013CB3",
  "P000000111111": "#000000",
  "P340033CDCCFF": "#340033",
  "P3400669866FF": "#340066",
  "P6600996734CC": "#660099",
  "P6734CC660099": "#6734CC",
  "P9866FF340066": "#9866FF",
  "PCDCCFF340033": "#CDCCFF",
  "P330000FDBCBC": "#330000",
  "P670000FF0000": "#670000",
  "P990000CC0100": "#990000",
  "PCC0100990000": "#CC0100",
  "PFF0000670000": "#FF0000",
  "PFDBCBC330000": "#FDBCBC",
  "P5B1B00FFCC99": "#5B1B00",
  "PAE3E00FDA57D": "#AE3E00",
  "PFC6901FD8548": "#FC6901",
  "PFD8548FC6901": "#FD8548",
  "PFDA57DAE3E00": "#FDA57D",
  "PFFCC995B1B00": "#FFCC99",
  "P333300FFFF9A": "#333300",
  "P676700FFFF00": "#676700",
  "P999900CBCB01": "#999900",
  "PCBCB01999900": "#CBCB01",
  "PFFFF00676700": "#FFFF00",
  "PFFFF9A333300": "#FFFF9A",
  "P00330099FF99": "#003300",
  "P00660034FF33": "#006600",
  "P08980009CB01": "#089800",
  "P09CB01089800": "#09CB01",
  "P34FF33006600": "#34FF33",
  "P99FF99003300": "#99FF99",
  "ownershipDigitboxTxt": "#9EA8AF",
  "ownershipDigitboxBorder": "#5D5C5A",
  "ownershipTblBg": "#2B2E33",
  "ownershipTblOddBg": "#000",
  "ownershipTblEvenBg": "#161719",
  "ownershipGrayThemeTblEven": "#282B2F",
  "ownershipGrayThemeTblOdd": "#25272B",
  "checkListboxBorder": "#333333",
  "checklistItemHover": "#3B3B3B",
  "checklistInfoBg": "#3B3E43",
  "checkListIsThreshold": "#161618",
  "ListgraphAreaMain": "#232428",
  "ListgraphAreaSecond": "#000000",
  "ListgraphAreaBorder": "#414143",
  "listResizeBarGraphBorderBottom": "#000",
  "listResizeBarGraphBorderTop": "#3C3C3D",
  "listResizeBarGraphBorderBg": "#2b2b30",
  "listResizeBarGraphBorderLinerGradientMain": "#2b2b30",
  "listResizeBarGraphBorderLinerGradientSecond": "#212224",
  "barGraphinnerbg": "#262626",
  "barGraphinnerStrock": "#666666",
  "barToolTipBackground": "#090926",
  "barToolTipForground": "#ffffff",
  "listSummaryStatsDataOdd": "#1a1a1a",
  "listSummaryStatsDataEven": "#2c2c2e",
  "financialHover": "#2b2f34",
  "financialSpellHover": "#77CAFF",
  "financialIconBg": "#c8daef",
  "financialWithActive": "#2b2f34",
  "financialSpellWithActiveHover": "#77CAFF",
  "financialIconBgWithActive": "#2b2f34",
  "financialIconBgWithActiveHover": "#c8daef",
  "financialClicked": "#A0A0A0",
  "financialSpellClickedHover": "#77CAFF",
  "financialIconBgClickedHover": "#c8daef",
  "paginateViewGraph": "#000",
  "fundamentalIconBgForHover": "#2b2f34",
  "imageOfFinancial": "-195px -299px",
  "hoverImageOfFinancial": "-195px -299px",
  "imageFinancialClicked": "-195px -299px",
  "normalHoverImageOfFinancial": "-195px -299px",
  "fefbce": "#fefbce",
  "8f8e7a": "#8f8e7a",
  "000": "#090926",
  "summeryStratcer": "#3a3a3a",
  "summeryStateHoldBG": "#616363",
  "summeryStateHoldColor": "#fff",
  "summeryStateHoldBGHover": "#b5b5b5",
  "summeryStateHoldColorHover": "#000",
  "summaryBlockBgPos": "-251px 0px",
  "summaryBlockBgPosHover": "0 -550px",
  "summaryBlockBgPosActiveHover": "0 -551px",
  "listSummaryBlockTitle": "#616363",
  "listSummaryBlockTitleHover": "#D6D6DF",
  "listSummaryBlockTitleHoverColor": "#000",
  "electricUtilitiesTopBorder": "#3c3c3d",
  "crossHairWindowBG": "#1e1e20",
  "crossHairWindowBorderColor": "#333",
  "crossHairLabel": "#999",
  "crossHairLabelValue": "#ccc",
  "newsBlockBg": "#161618",
  "newsDetailsTabData": "#999",
  "NewslistGroupItemEven": "#292929",
  "NewslistGroupItemOdd": "#191919",
  "newsBlockTitleBarBgTop": "#646973",
  "newsBlockTitleBarBgBottom": "#646973",
  "newsMaintabMainBlock": "#232323",
  "newsFillAreaContent": "#000",
  "metrickDialogBg": "#202022",
  "metrickDialogHeaderTitleColor": "#CCC",
  "metrickDialogHeaderTopBorderColor": "#424242",
  "metrickDialogHeaderTopColor": "#424242",
  "metrickDialogHeaderbottomColor": "#151515",
  "metrickDialogHeaderbottomBorderColor": "#151515",
  "metrickDialogBodyTopBorderColor": "#767B81",
  "metrickInnerBlockBg": "#515151",
  "metrickBtnDefaultTextColor": "#FFF",
  "metrickBtnDefaultTextShadowColor": "#000",
  "metrickBtnDefaultborder": "#000",
  "metrickBtnDefaultBgTopColor": "#4F4F50",
  "metrickBtnDefaultBgBottomColor": "#2E3033",
  "metrickBtnDefaultBgTopColorHover": "#606166",
  "metrickBtnDefaultBgBottomColorHover": "#46494E",
  "metrickLabelTextColor": "#FFF",
  "comDialogContentBorderColor": "#767B81",
  "comDialogContentBG": "#202022",
  "comDialogContentTextColor": "#FFF",
  "MsgDefMainWrap": "#808080",
  "MsgTabNavBg": "#6C6F74",
  "MsgTabNavTextColor": "#fff",
  "MsgTabNavBgHover": "#515151",
  "MsgTabNavTextColorHover": "#fff",
  "MsgTabNavBgColorActive": "#515151",
  "MsgTabNavBoxBG": "#202022",
  "MsgTabNavBoxBottomBorderColor": "#515151",
  "MsgTabNavContentBg": "#515151",
  "MsgTabNavContentThBg": "#A0A0A0",
  "MsgTabNavContentThTextColor": "#000",
  "MsgTabNavContentTdBg": "#515151",
  "MsgTabNavContentTdTextColor": "#bcb9b9",
  "MsgTabNavContentTdTextColorUnreadDetail": "#FFF",
  "MsgTabNavContentTdTextColorUnreadName": "#77cafe",
  "MsgTabNavContentTdTextColorUnreadNameHover": "#FFF",
  "MsgTabNavContentTdTextColorReadDetail": "#A5A5A5",
  "MsgTabNavContentTdTextColorReadName": "#A5A5A5",
  "MsgTabNavContentTdTextColorReadNameHover": "#FFF",
  "MsgTabNavContentTdTextColorReadTrHover": "#FFF",
  "MsgTabNavContentTdTextColorTrHover": "#FFF",
  "MsgBlueTextColor": "#77cafe",
  "MsgBlueTextColorHover": "#fff",
  "notifyInnerChildBg": "#E8E8E8",
  "notifyInnerChildTextColor": "#000",
  "notifyInnerChildHLStockNameTextColor": "#158ce0",
  "notifyInnerChildHLStockNameTextColorHover": "#000",
  "notifyInnerChildHLStockDetailsTextColor": "#000",
  "notifyInnerChildCloseColor": "#158ce0",
  "notifyInnerChildCloseColorHover": "#000",
  "MsgDefTTBorderColor": "#666",
  "MsgDefTTBg": "#FEFBCE",
  "MsgDefTTTxtColor": "#000",
  "alePopLGIBg": "#fff",
  "alePopLGIBorderColor": "#CCCCCC",
  "alePopLGIActiveBg": "#B8DAFE",
  "alePopLGIPanelAColor": "#000",
  "PrefBtnColor": "#00528d",
  "ownerShipBlockMainPanelTopBorder": "#30373F",
  "ownershipBlockHeaderBG": "#000",
  "ownershipBlockRightHeaderLeftBorder": "#2D343B",
  "ownershipGridDataHoldBg": "#000",
  "ownershipGridDataBG": "#000",
  "ownershipGridDataRowHoverBG": "#2B2F34",
  "ownershipGridDataRowHoverBorder": "#656A70",
  "ownershipGridRowBorder": "#2D343B",
  "ownershipGridRowBG": "#000",
  "ownershipGridRowColor": "#CCC",
  "ownerTogglerBoxBG": "#0E1216",
  "ownerTogglerBoxBorder": "#30373F",
  "ownerTogglerCellFont": "#FFF",
  "ownershipGridPercentileFont": "#fff",
  "ownershipBlockleftHeading": "#77CAFF",
  "ownershipBlockrightYTDHeading": "#808080",
  "yellowDogear": "#FF0",
  "yellowDogearHover": "#FF0",
  "yellowDogearText": "#000",
  "yellowDogearHoldBG": "#FFFF99",
  "yellowDogearHoldBorder": "#000",
  "ownershipValueChanger": "#FFF",
  "loftBg": "#676b6f",
  "loftBgHover": "#d4d4d8",
  "ownershipBrecketsDottedBorder": "#77CAFF",
  "blackTooltipBg": "#000",
  "blackTooltipColor": "#DDD",
  "blackTooltipBorder": "#ccc",
  "blackTooltipShadow": "#BBB",
  "oneilRatingBtnBorder": "#CCC",
  "oneilRatingBtnBG": "#BABEC2",
  "oneilRatingBtnBorderIside": "#999",
  "oneilRatingBtnColor": "#000",
  "oneilRatingBtnHoverBG": "#BABEC2",
  "oneilRatingBtnHoverColor": "#000",
  "oneilRatingBtnActiveBG": "#ECECEC",
  "oneilRatingBtnActiveColor": "#000",
  "oneilRatingBtnBorderActiveHoverIside": "#999",
  "oneilRatingTabContentBorder": "#CCC",
  "oneilRatingTabContentBG": "#ECECEC",
  "oneilRatingTabContentPaneBorder": "#8B929B",
  "oneilRatingRestoreLinkColor": "#039",
  "oneilRatingRestoreLinkHoverColor": "#000",
  "oneilRatingApearanceBGColor": "#EBEBEB",
  "oneilRatingApearanceBorderColor": "#8B929A",
  "oneilRatingMACDMeasureCriteria": "#555",
  "oneilAboutRowBottomBorder": "#AFB3B6",
  "oneilAboutRowtopBorder": "#FFF",
  "oneilRatingLabelText": "#000",
  "oneilRatingMacdInputText": "#000",
  "accdisTiIndicatorholdBg": "#fff",
  "accdisTiIndicatorholdBorder": "#D5D6D8",
  "accdisTiIndicatorListColor": "#000",
  "liningParent": "#000",
  "liningParentBg": "#B4B4B4",
  "liningParentGradBgtop": "#f6f6f6",
  "liningParentGradBgBtm": "#B4B4B4",
  "liningParentGradBgtopHover": "#FFF",
  "liningParentGradBgBtmHover": "#D5D5D5",
  "liningHrBg": "#000",
  "liningHrColor": "#000",
  "appearanceBoxShadow": "#888",
  "appearanceBoxGradBgTop": "#f6f6f6",
  "appearanceBoxGradBgBtm": "#E0E0E0",
  "oneilAppearWeightBg": "#B8DAFF",
  "tiMacdErrorBox": "#666",
  "tiMacdErrorBG": "#FEFBCE",
  "tiMacdErrorColor": "#BB0812",
  "buttonActiveState": "#04CCFE",
  "dg-symbolStackBg": "#090926",
  "dg-rayChartContainer": "#fff",
  "dg-symbolChartTitle": "#77CAFE",
  "dg-symbolChartTitleHover": "#FFF",
  "dg-symbolChartTitleGray": "#969696",
  "dg-typeNScale": "#808080",
  "dg-typeNScaleHover": "#FFF",
  "dg-techIndicators": "#808080",
  "dg-legendListText": "#999",
  "dg-techratingBg": "#0E1216",
  "dg-techratingText": "#808080",
  "dg-logNLin": "#808080",
  "dg-logNLinHover": "#FFF",
  "dg-copyRightsTxt": "#666",
  "dg-copyRightsBg": "rgba(30, 30, 32, 0.7)",
  "dg-copyRightsTxtyellow": "#111",
  "dg-copyRightsBgyellow": "rgba(0, 0, 2, 0.7)",
  "dg-pricesDate": "#FFF",
  "dg-ohPercentHead": "#808080",
  "dg-ohPercentHeadHover": "#FFF",
  "dg-ohPercentData": "#FFF",
  "dg-pivotPercentText": "#ff33cc",
  "dg-chartPanelLeftBg": "#090926",
  "dg-chartMultiOptionMenuBtnTxt": "#FFF",
  "dg-chartMultipleTitle": "#000",
  "dg-chartMultipleTitleBorder": "#AEB0B3",
  "dg-chartCorporateEventBtn": "#FFF",
  "dg-intraDayBg": "#090926",
  "dg-intraDayBorder": "#2D343B",
  "dg-intraDayTitleColor": "#999",
  "dg-intraDayVolume": "#FFF",
  "dg-timeLineBorder": "#FFF",
  "dg-timelineLeftSpacer": "#090926",
  "dg-legendListPopover": "#FFF",
  "dg-legendListPopoverDiff": "#000",
  "dg-emailPopupPlaceHolder": "#999",
  "dg-toolTipTexts": "#555",
  "dg-toolTipTextsStrock": "#666",
  "dg-financialValueDigit": "#FFF",
  "dg-financialMetricMesure": "#928e8e",
  "dg-financialMetricMesureValue": "#FFF",
  "calInputDataValue1": "#434343",
  "calInputDataValue2": "#313131",
  "calInputDataValue3": "#222",
  "calInputDataValue4": "#1e1e1e",
  "calInputDataValuehistoricalBg1": "#b6ad93",
  "calInputDataValuehistoricalBg2": "#9f9472",
  "calInputDataValuehistoricalBg3": "#82775d",
  "calInputDataValuehistoricalBg4": "#685f55",
  "shareMsgBg": "#afb3b6",
  "shareMsgBorder": "#767b81",
  "shareActionLink": "#000",
  "shareActionLinkHover": "#267CD1",
  "shareActionManageBtn": "#666"
};
