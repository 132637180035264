import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';

class GetStockHistoryByDateRequest extends BaseServiceRequestIIS {
  constructor(symbol, graphtype, enddate, startdate, YTD, YTD1, YTD3, YTD5, YTD10, GetRTHistory, IndexSymbol, RS1Symbol, RS2Symbol, YTDReq) {
    super();
    super.addRequestUri("getstockhistoryByDate");
    super.addPathParameter("symbol", symbol);
    super.addPathParameter("graphType", graphtype);
    super.addPathParameter("endDate", enddate);
    super.addPathParameter("startDate", startdate);
    if (YTD) super.addPathParameter("YTD", YTD);
    if (YTD1) super.addPathParameter("YTD1", YTD1);
    if (YTD3) super.addPathParameter("YTD3", YTD3);
    if (YTD5) super.addPathParameter("YTD5", YTD5);
    if (YTD10) super.addPathParameter("YTD10", YTD10);
    if (GetRTHistory) super.addPathParameter("GetRTHistory", GetRTHistory);
    if (IndexSymbol) super.addPathParameter("IndexSymbol", IndexSymbol);
    if (RS1Symbol) super.addPathParameter("RS1Symbol", RS1Symbol);
    if (RS2Symbol) super.addPathParameter("RS2Symbol", RS2Symbol);
    if (YTDReq) super.addPathParameter("YTDReq", YTDReq);
    super.addPathParameter("TimeReq", new Date());
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return undefined;
  }
}
export default GetStockHistoryByDateRequest;
