import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AlertActions from "../../Actions/AlertActions.js";
import BaseServiceApi from "BaseServiceApi";
import HttpMethodType from "HttpMethodType";
import StringUtil from "StringUtil";
import validator from 'validator';
import LocalizationStore from 'LocalizationStore';


export default class AddEmailAddress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: (this.props.email ? this.props.email : ""),
            validationClass: 'displayNone'
        }

        this.hideDialog = this.hideDialog.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.saveUserNotificationSettings = this.saveUserNotificationSettings.bind(this);
    }


    hideDialog() {
        this.props.hideAddEmailDialog();
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        });

    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            this.saveUserNotificationSettings();
            e.preventDefault();
        }
    }

    saveUserNotificationSettings() {
        let value = this.state.email;
        if (StringUtil.isEmpty(value) || validator.isEmail(value)) {
            let methodType = StringUtil.isEmpty(value) ? HttpMethodType.DELETE : HttpMethodType.POST
            AlertActions.saveNotificationSubscription(value, BaseServiceApi.rayData["NotificationType"].EMAIL, methodType, true);
            this.hideDialog();
        }
        else {
            this.setState({ validationClass: '' });
            return false;
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.open != nextProps.open && nextProps.open == true) {
            this.setState({
                email: nextProps.email,
                validationClass: 'displayNone'
            });
        }
    }

    render() {
        return (
            <div id="addEmailAddress">
                <Modal className="modal-popup wide verification" show={this.props.open}>
                    <Modal.Body>
                        <div className="modal-region">
                            <div className="verify-credential email">
                                <p>{LocalizationStore.getTranslatedData('set_pre_nap_ente', 'Enter an email address for alert')}</p>
                                <form>
                                    <input
                                        id="formControlsEmail"
                                        type="email"
                                        value={this.state.email}
                                        onChange={this.handleEmailChange}
                                        onKeyDown = {this.handleKeyDown}
                                        placeholder={LocalizationStore.getTranslatedData('set_pre_nap_entere', 'Enter email')}
                                        spellCheck="false"
                                    />
                                    <small className={this.state.validationClass + ' text-danger'}> {LocalizationStore.getTranslatedData('set_pre_nap_enterr', 'Please Enter a valid email address')} </small>
                                </form>
                            </div>
                            <div className="box clear"></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.hideDialog}>{LocalizationStore.getTranslatedData('set_cancel', 'Cancel')}</button>
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.state.email == this.props.email ? this.hideDialog : this.saveUserNotificationSettings}>{LocalizationStore.getTranslatedData('set_ws_sv', 'Save')}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}