import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetDetailRecipientStatusRequest extends BaseServiceRequest {
    constructor(recipientId) {
        super();
        super.addRequestUri("recipientdetails.pb");
        super.addRequestParameter("rid", recipientId.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.SHARE;
    }

    getMessageName() {
        return "ShareRecipientNodeSummaryResponse";
    }
}


export default GetDetailRecipientStatusRequest;
