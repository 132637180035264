import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetBrokerReportRequest extends BaseServiceRequest {
    constructor(symbol, startDate, endDate) {
        super();
        super.addRequestUri("b/docs.pb");
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("start", startDate);
        super.addRequestParameter("end", endDate);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "BrokerReportsResponse";
    }
}

export default GetBrokerReportRequest;