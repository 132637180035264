import BaseServiceApi from "BaseServiceApi";
import { connect } from "react-redux";
import { onOwnershipFundToggle } from "../../../../../Actions/OwnerShipBlockActions";
import UserInfoUtil from "UserInfoUtil";
import React, { Component } from "react";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

class HeaderCell extends Component {
  constructor(props) {
    super(props);
    this.toggleFund = this.toggleFund.bind(this);
    this.state = {
      hover: false,
      canShowtoolTip: false
    };
    this.isFundViewEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.FUNDS_VIEW_ACNTMGR_Entitlement);
  }
  onHover(hover) {
    this.setState({ hover: hover });
  }
  toggleFund() {
    if (this.props.header) {
      this.props.onOwnershipFundToggle(1);
    }
  }
  showTooltip(e, client, displayName) {
    let len = 30;
    if (client && client === 1) {
      len = 24;
    }
    if (displayName && displayName.length > len) {
      this.setState({ canShowtoolTip: true });
    }
  }

  syncHover(index, hover) {
    this.props.syncHover(index, hover);
  }

  clearCellTitle() {
    this.setState({ canShowtoolTip: false });
  }
  get_tex_width(txt, font) {
    this.element = document.createElement('canvas');
    this.context = this.element.getContext("2d");
    this.context.font = font;
    return Math.ceil(this.context.measureText(txt).width) + 7;
  }

  render() {
    const divstyle = { height: this.props.RowHeight, cursor: 'pointer' };
    const clsHover = this.state.hover ? "hover-on-row" : " ";
    let ownershiprowlabel = "";
    let YTD = "";
    if (this.props.LeftClient && this.props.LeftClient === 1) {
      ownershiprowlabel = this.props.header.SponsorName.toUpperCase();
    }
    else {
      ownershiprowlabel = this.props.header.ManagementTeam;
    }
    let labelWidth = this.get_tex_width(ownershiprowlabel, '13px calibri');
    labelWidth = labelWidth > window.innerWidth ? window.innerWidth - 120 : labelWidth;
    if (this.props.header.PercentageChgYTD || this.props.header.PercentageChgYTD === 0) {
      if (!isNaN(parseFloat(this.props.header.PercentageChgYTD))) {
        YTD = `${parseFloat(this.props.header.PercentageChgYTD).toFixed(1)}%`;
      }
    }

    return (
      <div className={`ownership-entry-row ${clsHover}`} style={divstyle}
        onMouseOver={(e) => { this.syncHover(this.props.index, true); this.showTooltip(e, this.props.LeftClient, ownershiprowlabel) }}
        onFocus={() => this.syncHover(this.props.index, true)}
        onMouseLeave={() => { this.syncHover(this.props.index, false); this.clearCellTitle() }}>

        {this.props.header.IsClient && this.isFundViewEntitled ? <span className="active-client" /> : <span className="inactive-client" />}
        <div className="ownership-entry-name" ref={(r) => { this.labelName = r }} onMouseDown={this.toggleFund}>{ownershiprowlabel}</div>
        <div className="ownership-entry-percent">{YTD}</div>
        <div className="ownership-black-tooltip" onMouseDown={this.toggleFund} style={{ width: `${labelWidth}px`, display: this.state.canShowtoolTip ? 'block' : 'none' }}>{ownershiprowlabel}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { LeftClient, RowHeight } = state.DatagraphReducers.ownershipBlockReducer;
  return { LeftClient, RowHeight }
}
const mapDispatchToProps = (dispatch) => ({
  onOwnershipFundToggle: (side) => dispatch(onOwnershipFundToggle(side))
})
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef : true})(HeaderCell)
