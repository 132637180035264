const { ActionTypes } = RiPanelConstants;
import { each } from "underscore";
import FormatterUtil from "FormatterUtil";
import { getRiPanelOwnershipInfoState } from "../../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors.js"; 
import GraphApi from "../../../../ServiceApi/Apis/GraphApi.js";
import HubApi from "../../../../ServiceApi/Apis/HubApi.js"
import MiniListHelper from "MiniListHelper";
import moment from "moment";
import { RiPanelConstants } from '../../../../Constants/RiPanelConstants';
import UserInfoUtil from "UserInfoUtil";
import { ConstituentIndexEntitlements, ENTITLEMENTS } from "../../../../Constants/HubRIPanelConstants.js";
import { put, takeLatest, call, select } from 'redux-saga/effects';

//Change ListType and role of getHubUserRole to see different view 

function* getOwnershipInfo({ symbolInfo }) {
    try {
        const { OwnershipDatasource } = yield select(getRiPanelOwnershipInfoState);
        if(OwnershipDatasource.length === 0){
            const symbol = symbolInfo.Symbol;
            const isMiniListPlay = symbolInfo.IsMiniListPlay;
            if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)) {
                const result = yield call(GraphApi.GetOwnershipReportRequestExt, symbolInfo.Osid, "0", null, 1, 1, 100, true, false);
                const OwnershipDatasource = yield call(processData, result.reportSet);
                yield put({
                    type: ActionTypes.OWNERSHIP_INFO_SET_DATA,
                    newState: { OwnershipDatasource, loading: false, Initialized: true }
                })
            }
            
        }
        
    } catch (error) {
        console.log(`%cError occurs in RiPanelOwnershipInfo.js, getOwnershipInfo ${error}`);
    }
}
function processData(dataList) {
    try {
        dataList.forEach((item) => {
            const shares = item.sharesHeld.toNumber();
            item.sharesHeld = abbreviateShareValue(shares);
            item.pctOfPort = item.pctOfPort.toFixed(1);
            item.sponsorNameAbbr = getSponsorNameAbbr(item);
            item.sponsorName = item && item.sponsorNameAbbr ? getSponsorName(item) : "";
        });
        return dataList
    } catch (error) {
        console.log(`%cError occurs in RiPanelOwnershipInfo.js, processData ${error}`);
    }
}
function abbreviateShareValue(shares) {
    try {
        if (shares === 0) {
            return "0";
        }
        if (shares < 10000000000) {
            if (shares < 9900 && shares > -9900) {
                return FormatterUtil.formatNumber(shares / 1000, 1);
            }
            else {
                return FormatterUtil.formatNumber(shares / 1000);
            }
        }
        else if (shares < 100000000000) {
            return FormatterUtil.formatNumber(shares / 1000);
        }
        else {
            return FormatterUtil.formatNumber(shares / 1000, 6);
        }
    } catch (error) {
        console.log(`%cError occurs in RiPanelOwnershipInfo.js, abbreviateShareValue ${error}`);
    }
}
function getSponsorNameAbbr(item) {
    try {
        let passiveOwnerPrefix = "";
        if (item.carryForward === "1") {
            passiveOwnerPrefix = "*";
        }
        if (item.carryForward === "2") {
            passiveOwnerPrefix = "**";
        }
        return (passiveOwnerPrefix + item.sponsorNameAbbr);
    } catch (error) {
        console.log(`%cError occurs in RiPanelOwnershipInfo.js, getSponsorNameAbbr ${error}`);
    }
}
function getSponsorName(item) {
    try {
        return item && item.sponsorName ? (item.carryForward !== "0" ? `${item.sponsorName} as of ${getQuarterEndDate(item)}` : item.sponsorName) : '';
    } catch (error) {
        console.log(`%cError occurs in RiPanelOwnershipInfo.js, getSponsorName ${error}`);
    }
}
function getQuarterEndDate(item) {
    try {
        return item && item.quarterEndDate ? moment(new Date(item.quarterEndDate.toNumber()).toUTCString()).format('MM/DD/YYYY') : '';
    } catch (error) {
        console.log(`%cError occurs in RiPanelOwnershipInfo.js, getQuarterEndDate ${error}`);
    }
}
function* getHubUserRole({ isLogin }) {
    try {
        let indexCodes = [];
        each(ENTITLEMENTS, (entitlement) => {
            if (UserInfoUtil.IsUserEntitled(entitlement)) {
                const entitlementIndices = _.filter(ConstituentIndexEntitlements, (item) => item.index === parseInt(entitlement))
                indexCodes = indexCodes.concat(entitlementIndices[0].IndexConstituentAccess);
            }
        })
        if (indexCodes.length > 0) {
            yield put({
                type: ActionTypes.OWNERSHIP_INFO_UPDATE_INDEX_CODES,
                indexCodes
            })
        }

        if (isLogin) {
            const user = UserInfoUtil.getUserInfo();
            if (Boolean(user) && !Boolean(user.hubRole)) {
                try {
                    const result = yield call(HubApi.getUserRole, user.userEmail);
                    if (result.hubUserRoleDatas.length > 0) {
                        user.hubRole = result.hubUserRoleDatas[0].hubUserRole;
                    }
                    else {
                        user.hubRole = 0;
                    }
                    UserInfoUtil.setUserInfo(user);
                } catch (e) {
                    console.log(`%cError occurs in RiPanelOwnershipInfo.js, getHubUserRole ${e}`);
                }
            }
            yield put({
                type: ActionTypes.OWNERSHIP_INFO_UPDATE_HUB_ROLE
            })
        }
    } catch (error) {
        console.log(`%cError occurs in RiPanelOwnershipInfo.js, getHubUserRole ${error}`);
    }
}
/*************** WATCHERS ***************/
export function* watcherGetOwnershipInfo() {
    yield takeLatest(ActionTypes.GET_OWNERSHIP_INFO, getOwnershipInfo)
}
export function* watcherGetUsersHubRole() {
    yield takeLatest(ActionTypes.OWNERSHIP_INFO_SET_HUB_USER_ROLE, getHubUserRole)
}