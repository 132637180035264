import React from "react";
import { Modal, Form, FormGroup, ControlLabel } from "react-bootstrap";
import FlexibleModal from "../../FlexibleModal.jsx";

import { UpdateDialogType, SaveSharedData } from "../../../Actions/ShareAction";
import LocalizationStore from "LocalizationStore";

const Confirmation = ({ savedSharedData, sharedAction, targetData, x_parent, y_parent }) => {

    const handleCancel = () => {
        UpdateDialogType('managed', targetData.ntid, sharedAction);
    }
    const handleSave = () => {
        SaveSharedData('confirm', savedSharedData, targetData, sharedAction);
    }
    const getXPosition = () => {
        let x = 0;
        let w = 280;

        if (x_parent) {
            x = x_parent - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2;
        }

        if (x < 0)
            x = 0;

        return x;
    }

    const getYPosition = () => {
        let y = 0;
        let h = 260;

        if (y_parent) {
            y = y_parent - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2;
        }

        if (y < 0)
            y = 0;

        return y;
    }

    return (        
        <div className="share-listing share-message" data-test-id="main-div">           
            <div className="modal-popup">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <Modal.Body>
                            <div className="modal-region">
                                <p>{LocalizationStore.getTranslatedData("set_shar_saveChangeMsg","Do you want to save your changes?")}</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <span className="btn-box center">
                                <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={handleCancel}>{LocalizationStore.getTranslatedData("set_cancel","Cancel")}</button>
                                <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={handleSave}>{LocalizationStore.getTranslatedData("misc_id7","Yes")}</button>
                            </span>
                        </Modal.Footer>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Confirmation;
