import StringUtil from "StringUtil";
import { EventEmitter } from "events";
import {PrintMode} from '../print/printmode';
const DATARECIEVED_EVENT = "datarecieved";
const SERVERSTREAMINGRECEIVED_EVENT ="ServerStreamingReceived";
let _providerName = "Nasdaq";
const RESET_STATE = 0;
//const SUBSCRIBING_STATE = 1;
const CONNECTING_STATE = 1;
//let _remoteUrl;
let _webSyncClient;
let _isConnecting;
//let _isSubscribing;
let _pendingSymbols = [];
let _subscribedSymbols = [];

class WebSyncUtil extends EventEmitter {
	constructor() {
		super();
		//http://panaraystrmdev1/nasdaqquotes/request.ashx
		//http://BATSStreamerDev1/NasdaqQuotes/websync.ashx
		//BATS http://streamerdev.marketsmith.com/BATSQuotesv4.0/websync.ashx
	}

	/**
	 * @return {boolean}
	 */
	get IsConnected() {
		if(_webSyncClient){
			return _webSyncClient.getIsConnected();
		}else {
			return false;
		}
		
	}

//test
	CreateClient(url){
		//let self = this;
		if (!_webSyncClient && !PrintMode.printing) { // Stop streaming during the print mode
			_webSyncClient = new fm.websync.client(url);
			this.ConnectAsync();
		}
	}
	trace(msg) {
		console.log("%c" + _providerName + "Model::" + msg, 'font-size:12px;color:#508F4E;');
	}

	addDataReceivedListener(callback) {
		this.on(DATARECIEVED_EVENT, callback);
	}
	removeDataReceivedListener(callback) {
		this.removeListener(DATARECIEVED_EVENT, callback);
	}

	addServerStreamingReceivedListener(callback){
		this.on(SERVERSTREAMINGRECEIVED_EVENT, callback);
	}
	removeServerStreamingReceivedListener(callback){
		this.removeListener(SERVERSTREAMINGRECEIVED_EVENT, callback);
	}

	ConnectAsync() {
		let self = this;
		if (!this.IsConnected) {
			_webSyncClient.connect({
				onSuccess: () => {
					self.trace(" Connect Success...");
					//  subscribe to a symbol channel.
					self.HandleClientConnected();
				},
				onFailure: (e) => {
					
					self.trace(_providerName + " Connect onFailure...");
					
					if (e._isReconnect) {
						self.trace('Could not reconnect: ' + e.getErrorMessage());
					} else {
						self.trace('Could not connect: ' + e.getErrorMessage());
					}
						
					if (_isConnecting != CONNECTING_STATE && !_webSyncClient.getIsConnected()) {
						console.log(`ConnectAsync:onFailure:reconnect to streaming server manually.`);
						e.setRetry(false);				
						self.ConnectAsync();
					}    
				},
				OnComplete: () => {
					_isConnecting = RESET_STATE;
				},
				onStreamFailure: (e) => {
					self.trace(_providerName + " Connect onStreamFailure...");
					if (e._willReconnect) {
						self.trace('Connection to server lost, reconnecting..., ' + e.getErrorMessage());
					} else {
						_isConnecting = RESET_STATE;
						self.trace('Connection to server lost permanently. ' + e.getErrorMessage());
						e.setRetry(false);
						if(_webSyncClient.getIsConnected()){
						   _webSyncClient.disconnect();
						   _webSyncClient.setIsConnected(false);
						}                   
						if (_isConnecting != CONNECTING_STATE) {
							console.log(`ConnectAsync:onStreamFailure:reconnect to streaming server manually.`);				
							self.ConnectAsync();
						}
					}
				}
			});
		}
	}

	SubscribeLiveStreamHandshake() {
		let self = this;
		_webSyncClient.subscribe({
			channel: "/PanarayLiveStream",
			OnReceive: (args) => {
				self.HandleServerHeartBeatReceived(args.getTimestamp());
			}
		});
	}

	HandleServerHeartBeatReceived(hbTime) {
		this.emit(SERVERSTREAMINGRECEIVED_EVENT, hbTime);
	}

	SubscribeAsync(symbol) {
		if (_subscribedSymbols.includes(symbol) || _pendingSymbols.includes(symbol)) {
			return;
		}
		let self = this;
		let pendingWonSymbol = symbol;
		if (this.IsConnected && !StringUtil.isEmpty(pendingWonSymbol) && pendingWonSymbol !== null) {
			_webSyncClient.subscribe({
				channel: '/' + pendingWonSymbol,
				onSuccess: (args) => {
					self.AddSubscribedSymbol(args.getChannel().substring(1));
					//self.trace("subscribe [" + args.getChannel() + "] Success");
					if (args._isResubscribe) {
						self.trace(_providerName + ": " + args.getTimestamp());
					}
				},
				onFailure: (args) => {
					if (args._isResubscribe) {
						self.trace('Could not resubscribe: ' + args.getErrorMessage());
					} else {
						self.trace('Could not subscribe: ' + args.getErrorMessage());
					}
				},
				OnComplete: () => { //args
				}, // invoke after OnSuccess or OnFailure.
				onReceive: (args) => {				
					var data = args.getData();
					//data = self.randomQuote.data;//Test not at the market hours
					self.HandleQuoteReceived(data);
				}
			});
		} else { // not connected.
			if (StringUtil.isEmpty(pendingWonSymbol) || pendingWonSymbol === null) {
				return
			}
			_pendingSymbols.push(pendingWonSymbol);
			if (_isConnecting != CONNECTING_STATE && _isConnecting != RESET_STATE) {
				self.ConnectAsync();
			}
		}
	}

	SubscribeSymbolsAsync(wonSymbols) {
		//validate symbols
		if (wonSymbols.length == 0) {
			return;
		}
		let symbols = [];
        wonSymbols.forEach((symbol) => {
            if (!StringUtil.isEmpty(symbol) && symbol !== null &&
                !_subscribedSymbols.includes(symbol) &&
                !_pendingSymbols.includes(symbol)) {
                symbols.push("/" + symbol);
            }
        });

		if (symbols.length <= 0) {
			return;
		}

		let self = this;
		if (this.IsConnected) {
		  try{
			_webSyncClient.subscribe({
				channels: symbols,
				OnSuccess: (args) => {		
					self.trace("**** All Symbols Subscribed Done - Success");
					self.AddSubscribedSymbols(args.getChannels());
					args.getChannels().forEach((channel) => {
						self.trace(StringUtil.format("*** The client {0}subscribed to {1}.", [(args._IsResubscribe ? "re" : ""), channel]));
					});
				},
				OnFailure: (args) => {
					if (args._IsResubscribe) {
					} else {
						self.trace(StringUtil.format("Could not subscribe. (ErrorCode={0}).", [args.getErrorCode()]));
					}

				},
				OnComplete: () => { //args
				}, // invoke after OnSuccess or OnFailure.
				OnReceive: (args) => {	
					var data = args.getData();
					self.HandleQuoteReceived(data);
				}
			});
		  }catch(error){
			  console.log(`something wrong with the process of subscribed. ${error}`);
		  }
		} else { // not connected.
			wonSymbols.forEach((sym) => {
				_pendingSymbols.push(sym);
			});

			if (CONNECTING_STATE != _isConnecting && RESET_STATE != _isConnecting) {
				this.ConnectAsync();
			}
		}
	}

	AddSubscribedSymbol(symbol) {
		if (!_subscribedSymbols.includes(symbol)) {
			_subscribedSymbols.push(symbol);
		}
	}

	AddSubscribedSymbols(symbols) {	
		symbols.forEach((symbol) => {
			var currSymbol = symbol.substring(1);
			if (!_subscribedSymbols.contains(currSymbol))
				_subscribedSymbols.push(currSymbol);
		});
		this.trace("***** All Symbols Subscribed : Count : " + _subscribedSymbols.length);
	}

	HandleQuoteReceived(data) {
		this.emit(DATARECIEVED_EVENT, data);
	}

	HandleClientConnected() {
		let currentPendingSymbol;
		_pendingSymbols.forEach((symbol) => {
			currentPendingSymbol = symbol;
			if (StringUtil.isEmpty(currentPendingSymbol)) {
				this.SubscribeAsync(currentPendingSymbol);
			}
		});
		_pendingSymbols = [];
	}

	UnsubscribeAsync(wonSymbol) {
		if (!this.RemoveSubscribedSymbol(wonSymbol) || wonSymbol === null)
			return;
		let self = this;
		_webSyncClient.unsubscribe({
			channel: '/' + wonSymbol,
            OnSuccess: () => {},//args => self.trace(StringUtil.format("Unsubscribed from {0}.", [args.getChannel()])),
			OnFailure: (args) => self.trace(StringUtil.format("Could not unsubscribe {0}. (ErrorCode={1}).", [wonSymbol, args.getErrorCode()]))
		});
	}

	UnsubscribeSymbolsAsync(wonSymbols) {
		if (!this.RemoveSubscribedSymbols(wonSymbols))
			return;

		let symbols = [];
		wonSymbols.forEach((symbol) => {
			if (!StringUtil.isEmpty(symbol) && symbol !== null) {
				symbols.push("/" + symbol);
			}
		});

        if (symbols.length <= 0) {
            return;
        }

        _webSyncClient.unsubscribe({
			channels: symbols,
			OnSuccess: (args) => {
				this.trace("**** All Symbols UnSubscribed Done - Success");
				args.getChannels().forEach((channel) => {
					this.trace(StringUtil.format("*** Unsubscribed from {0}.", [channel]));
				});
			},
			OnFailure: (args) => this.trace(StringUtil.format("Could not unsubscribe. (ErrorCode={0}).", [args.getErrorCode()]))
		});
	}

	/**
	 * @return {boolean}
	 */
	RemoveSubscribedSymbol(symbol) {

		let indexOfSymbol = _subscribedSymbols.indexOf(symbol);
		if (indexOfSymbol >= 0) {
			_subscribedSymbols.splice(indexOfSymbol, 1);
		}

		return true;
	}

	/**
	 * @return {boolean}
	 */
	RemoveSubscribedSymbols(symbols) {
		symbols.forEach((symbol) => {
			let indexOfSymbol = _subscribedSymbols.indexOf(symbol);
			if (indexOfSymbol >= 0) {
				_subscribedSymbols.splice(indexOfSymbol, 1);
			}
		})
		this.trace("**** All Symbols unsubscribed : Count : " + _subscribedSymbols.length);

		return true;
	}

	/**
	 * @return {boolean}
	 */
	IsSubscribedSymbol(symbol) {
		let isSubscribedSymbol = false;

		if (_subscribedSymbols.includes(symbol)) {
			isSubscribedSymbol = true;
		}

		return isSubscribedSymbol;
	}


	ResetFlags() {
		_isSubscribing = RESET_STATE;
		_isConnecting = RESET_STATE;
	}

	get randomQuote() {
		var randomprice = Math.random();
		return {
			"channel": "\/FB",
			"successful": true,
			"timestamp": "2016-11-11T15:59:02.78",
			"ext": {"fm.publishingClient": {"boundRecords": {}}},
			"data": {
				"AvgVol": 16119,
				"DailyHigh": 131,
				"DailyLow": 130.5201,
				"DailyOpen": 130.91,
				"DlyAvgVolDlr": 2106189,
				"DlyPrcChg": 0,
				"DlyPrcPercChg": -0.25,
				"High": 130.665  + randomprice,
				"Last": 130.665 + randomprice,
				"Low": 130.665 + randomprice,
				"Msid": 2902554,
				"Open": 130.665,
				"PrevDayClose": 130.99,
				"ReservedItemIds": [{"Key": 2217, "Value": 1.87}, {"Key": 2219, "Value": 1.87}, {
					"Key": 63,
					"Value": -2.12
				}, {"Key": 656, "Value": 24.85}, {"Key": 71, "Value": 11.25}, {"Key": 70, "Value": 1.73}, {
					"Key": 486,
					"Value": 6819
				}, {"Key": 61, "Value": 130.665}, {"Key": 2526, "Value": 130.665}, {
					"Key": 2435,
					"Value": -0.33
				}, {"Key": 65, "Value": -0.33}, {"Key": 2525, "Value": -0.33}, {
					"Key": 64,
					"Value": -0.25
				}, {"Key": 4793, "Value": 30.2}, {"Key": 2012, "Value": 131}, {
					"Key": 2080,
					"Value": 130.5201
				}, {"Key": 2233, "Value": 130.91}, {"Key": 4871, "Value": 131.10126}, {
					"Key": 4872,
					"Value": -7.93
				}, {"Key": 4887, "Value": 6.52}, {"Key": 2528, "Value": 280.28}, {
					"Key": 4637,
					"Value": null
				}, {"Key": 4796, "Value": -23.47}, {"Key": 4795, "Value": -55.61}, {
					"Key": 4794,
					"Value": -34.18
				}, {"Key": 1599, "Value": 116.1}, {"Key": 1594, "Value": 0}, {"Key": 68, "Value": 22.17}],
				"Sma200": 0,
				"Sma50": 116.1,
				"Symbol": "FB",
				"TradeSize": 6818898,
				"TradeTime": new Date(),
				"UtcOffset": -240,
				"VolRateDaily": -7.93,
				"VolRateWeekly": 6.52,
				"Volume": 6818898,
				"Vwap": 131.10126
			}
		}
	}
}
const webSyncUtil = new WebSyncUtil();
export default webSyncUtil;
