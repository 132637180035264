import React from "react";
import ReactHtmlParser from "react-html-parser";
import ScrollBar from "ScrollBar";
import { SummaryTransLateHelper } from "../../../../../Utils/TranslateHelper";
import textWidth from "text-width";
import { useSelector } from "react-redux";

function FundDescriptionControl(props) {
    const data = useSelector((state) => state.summaryReducer.descriptionData)
    const height = props.height ? props.height : null
    const width = props.width ? props.width : null
    const showDescriptionHeading = props.showDescriptionHeading ? props.showDescriptionHeading : false

    let descDiv;

    const getTextWidth = (str, family, size) => {
        const strWidth = textWidth(str, {
            family: family,
            size: size
        });
        return strWidth;
    }

    const ellipsisTextArea = (filterStr, rows, rowWidth) => {
        const d = '...';
        const dwidth = getTextWidth(d, "calibri", 12);
        let textWidth = 0;
        let index = 0;
        const textAreaWidth = rows * rowWidth;
        let innerStr = filterStr;
        const htm = filterStr.replace(/\s+/g, ' ');
        const strArray = htm.split(/(\s)|([\u4e00-\u9fa5])|(-)/).filter((t) => t !== undefined && t !== "");
        for (let z = 0; z < strArray.length; z++) {
            let n = 0;
            for (const codePoint of strArray[z]) {
                textWidth += getTextWidth(codePoint, "calibri", 14);
                if ((textWidth) > textAreaWidth) {
                    for (index; index > 0; index--) {
                        if ((textWidth - getTextWidth(htm[index], "calibri", 14) + dwidth) < textAreaWidth) {
                            innerStr = htm.substring(0, index) + d;
                            return innerStr;
                        }
                        textWidth -= getTextWidth(htm[index], "calibri", 14);
                    }
                }
                const rowW = textWidth % rowWidth;
                const nextStr = strArray[z][n + 1];
                const nextWidth = nextStr ? getTextWidth(nextStr, "calibri", 14) : 0;
                if ((rowWidth - rowW) < nextWidth) {
                    textWidth += (rowWidth - rowW);
                }
                n++;
                index++;
            }
            const rowW2 = textWidth % rowWidth;
            const nextStrArray = strArray[z + 1];
            const nextStrWidth = nextStrArray ? getTextWidth(nextStrArray, "calibri", 14) : 0;
            if ((rowWidth - rowW2) < nextStrWidth) {
                const preRow = Math.floor(textWidth / rowWidth) + 1;
                if (preRow === rows && nextStrArray) {
                    continue;
                }
                if (nextStrArray === ' ') {
                    z += 1;
                    index += 1;
                }
                textWidth += (rowWidth - rowW2);
            }
        }
        return innerStr;
    }

    const desc = data && data.description ? ReactHtmlParser(data.description)[0] : null;
    if (desc !== null && descDiv && descDiv.offsetHeight > 80) {
        descDiv.innerText = ellipsisTextArea(desc, 4, descDiv.offsetWidth);
    }

    return (
        <div className="scrollable-description" style={{ height: height, width: width }}>
            {showDescriptionHeading && <div className="info-title">{SummaryTransLateHelper.FUND_DESCRIPTION}</div>}
            <div className="info-content showScrollOnHover">
                {data.isDescriptionAvailable ?
                    <div className="scroll-wrapper">
                        <div className="custom-scroll">
                            <div className="custom-scroll-vertical" id='symbol-index-desc-VerticalScroll'>
                                <div className="content" style={{ width: width - 18 }}>
                                    {
                                        height && width ?
                                            <div className="content-holds">{ReactHtmlParser(data.description)}</div>
                                            :
                                            <div className="indexEllipsis" ref={(ref) => (descDiv = (ref))}>{desc}</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId='symbol-index-desc-VerticalScroll' vScroll={true} scrollOnHover={true} />
                    </div>
                    :
                    <div className="message-info"><p>{SummaryTransLateHelper.DESCRIPTION_NOT_AVAILABLE}</p></div>}
            </div>
        </div>
    );
}
export default FundDescriptionControl