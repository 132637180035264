import ArithmaticScale from "./ArithmaticScale"
import FixedLogScale from "./FixedLogScale"
import LogScale from "./LogScale"
import SymbolType from "../../Constants/SymbolType"
import WArithmaticScale from "./WArithmaticScale"

export const PriceScaleLabelConst = {
    LIN: "LIN",
    LIN_AIRTH: "LIN (Airth)",
    LOG_FIXED: "LOG (Fixed)",
    LOG_WON: "LOG (WON)",
    LOG: "LOG"
  }

  export const PriceScalesConst = { 
    [PriceScaleLabelConst.LIN]: WArithmaticScale, 
    [PriceScaleLabelConst.LIN_AIRTH]: ArithmaticScale, 
    [PriceScaleLabelConst.LOG_FIXED]: FixedLogScale, 
    [PriceScaleLabelConst.LOG_WON]: FixedLogScale, 
    [PriceScaleLabelConst.LOG]: LogScale 
}

export function getPriceChartScale(scaleLabel, isIntraday, SymTypeEnum){
    const newLabel = scaleLabel === PriceScaleLabelConst.LIN && (isIntraday || SymTypeEnum === SymbolType.WONSECTOR11) ? PriceScaleLabelConst.LIN_AIRTH : scaleLabel; 
    return (new (PriceScalesConst[newLabel] || PriceScalesConst.LOG)(newLabel === PriceScaleLabelConst.LOG_WON));
}