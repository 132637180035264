import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetGraphDataRequest extends BaseServiceRequest {
    constructor(symbol, msId, periodicity, endDate, startDate, modelId, options) {
        super();
        super.addPathParameter(msId.toString());
        super.addRequestUri("data.pb");
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("start", startDate);
        if (endDate) {
            super.addRequestParameter("end", endDate);
        }
        super.addRequestParameter("p", periodicity.toString());
        if (options) {
            super.addRequestParameter("o", options);
        }
        if (modelId) {
            super.addRequestParameter("mid", modelId);
        }
        super.addRequestParameter("ignorellr", "1");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "GraphResponse";
    }
}

export default GetGraphDataRequest;
