/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import TabHeaderOwnership from "./TabHeaderOwnership.jsx"
import DGGridSettings from "../../../../RayCustomControls/DGGrid/DGGridSettings.jsx"
import DGGridColumn from "../../../../RayCustomControls/DGGrid/DGGridColumn.jsx";
import TabOwneshipHeaderRow from "./TabOwneshipHeaderRow.jsx";
import { getReportExtdata, changeTextSize, columnRankChanged, columnPortChanged, columnSharesChanged, showLoader, tabSelectionChanged, subTabSelectionChanged, columnSortSeqChanged, passiveFilterChange, limitChange, onSearchTextChange } from "Actions/OwnershipActions.js";
import { connect } from "react-redux";
import textWidth from "text-width";
import StringUtil from "StringUtil";
import moment from "moment";
import DateHelper from "DateHelper";
import SmartViewType from "SmartViewType";
import {CreateTemplisttoExplorer  } from "Actions/ListActions";
import { OwnershipSubCategoryType } from "../../../../Constants/OwnershipSubCategoryType.js"
// import { OwnershipLeftMenu } from "Constants/OwnershipLeftMenu.js"
// import ClickMenu from "ClickMenu";
import { map, each, find } from "underscore";
import ExtremeDataValue from "ExtremeDataValue";
import ScrollBar from "ScrollBar";
import DatagraphStore from "../../../../Stores/NavModules/NavDataGraph/DataGraphStore";
import MiniListHelper from "MiniListHelper";
import MiniListStore from "../../../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore.js";

let isHideToolTopBox = false;

class TabOwnership extends React.Component {

    onLimitChange = (limitData) => {
        this.props.showLoader(true);
        this.props.limitChange(limitData);
    }

    onPassiveChange = () => {
        let passiveType;

        if (this.props.mainState.showIndex === 1)
            passiveType = 0;
        else
            passiveType = 1;

        this.props.showLoader(true);
        this.props.passiveFilterChange(passiveType);
    }

    onBlockMenuItemLeftClicked = (ownerid) => {
        CreateTemplisttoExplorer(
            {
              symbol: null,
              msId: '',
              view: SmartViewType.OWNERSHIP,
              isGlobal: false,
              sponsorId: ownerid
            });
        isHideToolTopBox = false;
    }

    AbbreviateSharesValueForHeader = (shares) => {
        if (shares === null || shares === 0) {
            return "0";
        }
        if (shares < 10000000000) {
            if (shares < 9900 && shares > -9900)
                return ExtremeDataValue.numberWithCommas( (shares / parseFloat(1000) == 0.0 ? 0 : (shares / parseFloat(1000)).toFixed(1)));
            else
                return ExtremeDataValue.numberWithCommas(Math.round(shares / 1000, 1));
        }
        else if (shares < 100000000000) {
            return ExtremeDataValue.numberWithCommas(Math.round(shares / 1000, 1));
        }
        else {
            return ExtremeDataValue.numberWithCommas((shares / 1000).toFixed(2));
        }
    }

    onColumnRankChanged = () => {
        this.props.columnRankChanged(!this.props.isColumnRankChanged);
    }

    onColumnPortChanged = () => {
        this.props.columnPortChanged(!this.props.isColumnPortChanged);
    }

    onColumnSharesChanged = () => {
        this.props.columnSharesChanged(!this.props.isColumnSharesChanged);
    }

    onTabSelectionChanged = (tabIndex) => {
        this.props.showLoader(true);
        this.props.tabSelectionChanged(tabIndex);
    }

    onSubTabSelectionChanged = (subTabIndex) => {
        this.props.showLoader(true);
        this.props.subTabSelectionChanged(subTabIndex);
    }

    onColumnHeaderClicked = (event, val) => {

        let orderSeqArr = this.props.orderSeqArr;
        let isCtrlKey = false;

        if ((event.ctrlKey || event.metaKey)) {
            isCtrlKey = true;
        }

        let columnName;
        let orderString = "";
        
        switch (val) {
            case 1:
                if (this.props.isColumnRankChanged === true)
                    columnName = "FundPerformanceRating";
                else
                    columnName = "PercentageChgYTD";
                break;
            case 2:
                columnName = "SponsorNameAbbr"
                break;
            case 3:
                if (this.props.isColumnPortChanged === true)
                    columnName = "PctOfPort";
                else
                    columnName = "RatingRank";
                break;
            case 4:
                if (this.props.isColumnSharesChanged === true)
                    columnName = "SharesHeld";
                else
                    columnName = "SharesHeld - Shares1QAgo";
                break;
            case 5:
                if (this.props.isColumnSharesChanged === true)
                    columnName = "Shares1QAgo";
                else
                    columnName = "Shares1QAgo - Shares2QAgo";
                break;
            case 6:
                if (this.props.isColumnSharesChanged === true)
                    columnName = "Shares2QAgo";
                else
                    columnName = "Shares2QAgo - Shares3QAgo";
                break;
            default:
                break;
        }

        const obj = find(orderSeqArr,  (obj) => obj.columnName === columnName);

        if (StringUtil.isEmpty(obj)) {
            if (!isCtrlKey)
                orderSeqArr = [];

            if(columnName === "FundPerformanceRating" || columnName ===  "SponsorNameAbbr")
                orderSeqArr.push({ columnName: columnName, order: "asc" });
            else 
                orderSeqArr.push({ columnName: columnName, order: "desc" });
        }
        else if (obj.order === "asc") {
            if (!isCtrlKey) {
                orderSeqArr = [];
                orderSeqArr.push({ columnName: columnName, order: "desc" });
            }
            else
                obj.order = "desc";
        }
        else if (obj.order === "desc") {
            if (!isCtrlKey) {
                orderSeqArr = [];
                orderSeqArr.push({ columnName: columnName, order: "asc" });
            }
            else
                obj.order = "asc";
        }

        each(orderSeqArr, (item) => {
            orderString += `,${  item.columnName  } ${  item.order}`;
        })

        orderString = orderString.substring(1, orderString.length);

        this.props.showLoader(true);

        this.props.columnSortSeqChanged(orderString, orderSeqArr);
    }

    ShareColumnChangeStyle = () => {
        if (this.props.isColumnSharesChanged !== 0) {
            if (value.shares1QAgo - value.shares2QAgo > 0) { color = '@ownership-pos' }
            else { color = '@ownership-neg' }
        }
    }

    onSearchTextChange = (searchText) => {
        this.props.onSearchTextChange(searchText);
    }


    getReportHeaderHTML = () => {
        if(this.props.ownershipReportData){

        const self = this;
        const reporHeadData = this.props.ownershipReportData.data;
      
        const tablesHeader = map(reporHeadData, (item, i) => (
                <TabOwneshipHeaderRow
                    key={i}
                    value={item}
                    currentTab={self.props.mainState.currentTab}
                    currentSubTab={self.props.mainState.currentSubTab}
                    isColumnPortChanged={self.props.isColumnPortChanged}
                    isColumnRankChanged={self.props.isColumnRankChanged}
                    isColumnSharesChanged={self.props.isColumnSharesChanged}
                    orderString={self.props.mainState.orderString}
                    orderSeqArr={self.props.orderSeqArr}
                    //childrenonColumnHeaderChanged={self.props.childrenonColumnHeaderChanged}
                    onColumnHeaderClicked={self.onColumnHeaderClicked}
                    i={i}
                />
            ));
        return tablesHeader;
        }
    }

    getReportHTML = () => {
        const self = this;
        if(this.props.ownershipReportData.data && this.props.ownershipReportData.data.length >0){
        const reporData = this.props.ownershipReportData.data;
        // let textIndGrouptyle = { textDecoration: 'none', cursor: 'pointer', pointerEvents: 'all' };
        // map(reporData, (item, i) => {
        //             return (
        const tables =
            <div className="report-holder-grid">
                <DGGridColumn
                    // key={`column${i}`}
                    // i={i}
                    item={reporData}
                    isColumnPortChanged={self.props.isColumnPortChanged}
                    isColumnRankChanged={self.props.isColumnRankChanged}
                    isColumnSharesChanged={self.props.isColumnSharesChanged}
                    currentTab={self.props.mainState.currentTab}
                    currentSubTab={self.props.mainState.currentSubTab}
                    orderString={self.props.mainState.orderString}
                    orderSeqArr={self.props.orderSeqArr}
                    textSize={self.props.textSize}
                    searchText={self.props.searchText}
                    onColumnRankChanged={self.onColumnRankChanged}
                    onColumnPortChanged={self.onColumnPortChanged}
                    onColumnSharesChanged={self.onColumnSharesChanged}
                    AbbreviateSharesValueForHeader={self.AbbreviateSharesValueForHeader}
                    onMessageMouseMove={self.onMessageMouseMove}
                    ShowToolTip={self.ShowToolTip}
                    onMessageMouseLeave={self.onMessageMouseLeave}
                    onHideToolTopBox={self.onHideToolTopBox}
                    // childrenonColumnHeaderChanged = {self.childrenonColumnHeaderChanged}
                    onColumnHeaderClicked={self.onColumnHeaderClicked}
                    onBlockMenuItemLeftClicked = {self.onBlockMenuItemLeftClicked}
                />
            </div>
        //     )
        // });
        return tables;
    }
}

    getTextWidth = (text) => {
        let textSize;

        switch (this.props.textSize) {
            case "xxx-small-normal":
                textSize = 10;
                break;
            case "xx-small-normal":
                textSize = 12;
                break;
            case "small-normal":
                textSize = 14;
                break;
            default: textSize = 12;
                break;
        }

        const width = textWidth(text, {
            family: "calibri",
            size: textSize
        });

        return width;
    }



    onMessageMouseMove = (e, message, sponsorName, quarterEndDate, carryForward) => {
    if(!isHideToolTopBox) {
        if (carryForward !== "0") {
            const d = moment(`/Date(${  quarterEndDate  })/`);
            const str = DateHelper.getDateString(d._d);
            sponsorName += ` as of ${  str}`;
        }
        if (this.getTextWidth(message) > e.target.getBoundingClientRect().width - 10
            || this.getTextWidth(sponsorName) > e.target.getBoundingClientRect().width - 10
            || message !== sponsorName) {
            this.toolTopBox.style.left = `${e.clientX - this.main.getBoundingClientRect().left + 10  }px`;
            this.toolTopBox.style.top = `${e.clientY - this.main.getBoundingClientRect().top + 10  }px`;
            this.toolTopBox.innerHTML = `<div><span>${  sponsorName  }</span></div>`;
            this.toolTopBox.style.display = '';
        }
    }
    }

    ShowToolTip = (e) => {
        if (e.target.clientWidth < e.target.scrollWidth) {
            this.toolTopBox.style.left = `${e.clientX - this.main.getBoundingClientRect().left + 10  }px`;
            this.toolTopBox.style.top = `${e.clientY - this.main.getBoundingClientRect().top + 10  }px`;
            this.toolTopBox.innerHTML = `<div><span>${  e.target.innerText  }</span></div>`;
            this.toolTopBox.style.display = '';
        }
    }

    onMessageMouseLeave = () => {
        this.toolTopBox.style.left = 0;
        this.toolTopBox.style.top = 0;
        this.toolTopBox.innerHTML = '';
        this.toolTopBox.style.display = 'none';
    }

    onHideToolTopBox = (isHide) => {
        this.onMessageMouseLeave();
        isHideToolTopBox = isHide;
    }

    getTextSizeWidth = (text, fontSize, isBold) => {
        const fontWeight = isBold ? "bold" : "normal";
        const width = textWidth(text, {
            family: "calibri",
            size: fontSize,
            weight: fontWeight
        });

        return width;
    }

    onTitleMouseMove = (e, message, fontSize, isBold) => {
        if (this.getTextSizeWidth(message, fontSize, isBold) > e.target.getBoundingClientRect().width) {
            this.toolTopBox.style.left = `${e.clientX - this.main.getBoundingClientRect().left + 10  }px`;
            this.toolTopBox.style.top = `${e.clientY - this.main.getBoundingClientRect().top + 10  }px`;
            this.toolTopBox.innerHTML = `<div><span>${  message  }</span></div>`;
            this.toolTopBox.style.display = '';
        }
    }

    render() {
        let riPanelOpnClass = '';

        if (this.props.isRIPanelOpen === true && this.props.isMiniListOpen === true) {
            riPanelOpnClass = 'report-ripanel-minlist-open'
        }
        else if (this.props.isRIPanelOpen === true && this.props.isMiniListOpen === false) {
            riPanelOpnClass = 'report-ripanel-open'
        }
        else if (this.props.isRIPanelOpen === false && this.props.isMiniListOpen === true) {
            riPanelOpnClass = 'report-minlist-open'
        }

        const reportHeaderHTML = this.getReportHeaderHTML();
        const reportHTML = this.getReportHTML();

        const state = DatagraphStore.getState();
        const symbol = state.SymbolInfo ? state.SymbolInfo.Symbol : '';
        const isMiniListPlay= state.IsMiniListPlay;
        const isActiveSymbolValid = MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay);
        if(!this.props.isLoading && isMiniListPlay && isActiveSymbolValid){
            setTimeout(()=>{
                MiniListStore.setMiniListNextInterval();
            })
        }

        return (
            <div ref={(ref) => { this.main = ref }} className="report-console">
                <div className="extender">
                    <div className="head-list-view">
                        <div className="tool-bar-area">
                            <DGGridSettings
                                textSize={this.props.textSize}
                                limitData={this.props.limitData}
                                searchText={this.props.searchText}
                                changeTextSize={this.props.changeTextSize}
                                onLimitChange={this.onLimitChange}
                                onSearchTextChange={this.onSearchTextChange}
                                reportData={this.props.ownershipReportData.data}
                                limitDataCompleted={this.props.limitDataCompleted}
                            />
                        </div>
                    </div>
                    <div className="report-wrapper">
                        <div className="list-inner-container">
                            <div className="list-view-area">
                                <div className="flexbox-parent">
                                    {(this.props.ownershipHeaderData && this.props.ownershipHeaderData.reportHeader) !== undefined ? <TabHeaderOwnership data={this.props.ownershipHeaderData} onTitleMouseMove={this.onTitleMouseMove} onMessageMouseLeave={this.onMessageMouseLeave} /> : ""}
                                    <div className="tabstuff" id="all-tab-ownership">

                                        <div className="message-center-tabs">
                                            <div className="passive-mod">
                                                <span className="head-pass">Passive Owners</span>
                                                <div className="btn-group btn-pass large-bold" role="group" aria-label="">
                                                    <button type="button" onClick={() => this.onPassiveChange()} className={`btn btn-secondary${  this.props.mainState.showIndex === 1 ? " active" : ""}`}>Show</button>
                                                    <button type="button" onClick={() => this.onPassiveChange()} className={`btn btn-secondary${  this.props.mainState.showIndex === 0 ? " active" : ""}`}>Hide</button>
                                                </div>
                                            </div>
                                            <Tabs id="controlled-tab-example" onSelect={this.onTabSelectionChanged.bind(this)} activeKey={this.props.mainState.currentTab + 1}>
                                                <Tab eventKey={1} title="Funds" className="report-sub-tabs">
                                                    <label>Include:</label>
                                                    <div className="template value-blue">
                                                        <form className="form-inline">
                                                            <span className="form-group">
                                                                <a className={this.props.subCategory.AllActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipAllFund)}>All</a>
                                                                <a className={this.props.subCategory.MutualFundActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipMutFund)}>Mutual Funds</a>
                                                                <a className={this.props.subCategory.HedgeFundActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipHedFund)}>Hedge Funds</a>
                                                            </span>
                                                        </form>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey={2} title="Advisors"></Tab>
                                                <Tab eventKey={3} title="Banks"></Tab>
                                                <Tab eventKey={4} title="Others" className="report-sub-tabs">
                                                    <label>Include:</label>
                                                    <div className="template value-blue">
                                                        <form className="form-inline">
                                                            <span className="form-group">
                                                                <a className={this.props.subCategory.OthersAllActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipOther)}>All</a>
                                                                <a className={this.props.subCategory.CorporationsActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipOtherCorporations)}>Corporations</a>
                                                                <a className={this.props.subCategory.IndividualsActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipOtherIndividuals)}>Individuals</a>
                                                                <a className={this.props.subCategory.InsuranceActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipInsurance)}>Insurance Cos</a>
                                                                <a className={this.props.subCategory.otherManagersActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipOtherManagers)}>Other Managers</a>
                                                            </span>
                                                        </form>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey={5} title="All" className="report-sub-tabs">
                                                    <label>Display:</label>
                                                    <div className="template value-blue">
                                                        <form className="form-inline">
                                                            <span className="form-group">
                                                                <a className={this.props.subCategory.ByTopOwnersActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipTopOwners)}>By Top Owners</a>
                                                                <a className={this.props.subCategory.ByPositionTypeActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipByPosition)}>By Position Type</a>
                                                                <a className={this.props.subCategory.ByOwnerTypeActive} onClick={() => this.onSubTabSelectionChanged(OwnershipSubCategoryType.OwnershipByOwner)}>By Owner Type</a>
                                                            </span>
                                                        </form>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="clearfix"></div>

                                    <div className="report-content-panel">
                                    { this.props.isLoading === true ? 
                                        <div className="loading loading-spinner"></div>
                                        :(this.props.ownershipReportData.data && this.props.ownershipReportData.data.length > 0) ?                                             
                                            <div className="report-content">
                                                <div className={`report-content-holder ${  riPanelOpnClass}`}>
                                                    <div className="report-scrolling" id="ownership-scroll">
                                                        <div className="custom-scroll">
                                                            <div id="ownershipContentGrab" className="report-content-grab">
                                                                <div className="report-grid-header">
                                                                    <div className="report-header-grid">
                                                                        <div className="report-grids">
                                                                            {reportHeaderHTML}
                                                                        </div>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                                <div id="ownershipGridHolder" className="report-grid-holder">
                                                                    <div className="report-grid-bg"><div className="report-grid-bg-hold"><b></b><b></b><b></b><b></b></div></div>
                                                                    {reportHTML}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ScrollBar scrollId='ownership-scroll' hScroll={true} />
                                                    </div>
                                                </div>
                                            </div>: <div style={{position: 'absolute',left: '50%',top: '50%'}}>No Data Found</div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={(ref) => { this.toolTopBox = ref }} id="rowTooltip" className="tooltip-box" style={{ display: 'none' }}></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
        ownershipHeaderData: state.ownership.headerData,
        ownershipReportData: state.ownership.reportExtData,
        textSize: state.ownership.textSize,
        limitData: state.ownership.limitData,
        isColumnRankChanged: state.ownership.isColumnRankChanged,
        isColumnPortChanged: state.ownership.isColumnPortChanged,
        isColumnSharesChanged: state.ownership.isColumnSharesChanged,
        isLoading: state.ownership.isLoading,
        mainState: state.ownership.mainState,
        subCategory: state.ownership.subCategory,
        orderSeqArr: state.ownership.orderSeqArr,
        searchText: state.ownership.searchText,
        limitDataCompleted: state.ownership.limitDataCompleted,
        resizeCounter: state.ownership.resizeCounter,
        isMiniListOpen: state.DatagraphReducers.DataGraphReducer.isMiniListOpen,
        isRIPanelOpen: state.RelatedInfoPanelReducers.RiPanelReducer.isRIPanelOpen,

    })

const mapDispatchToProps = (dispatch) => ({
    changeTextSize: (textSize) => dispatch(changeTextSize(textSize)),
    //getHeaderData: (osid, ownertype, currentTab, currentSubTab, showIndex) => dispatch(getHeaderData(osid, ownertype, currentTab, currentSubTab, showIndex)),
    getReportExtdata: (osid) => dispatch(getReportExtdata(osid)),
    columnRankChanged: (isColumnRankChanged) => dispatch(columnRankChanged(isColumnRankChanged)),
    columnPortChanged: (isColumnPortChanged) => dispatch(columnPortChanged(isColumnPortChanged)),
    columnSharesChanged: (isColumnSharesChanged) => dispatch(columnSharesChanged(isColumnSharesChanged)),
    showLoader: (isLoading) => dispatch(showLoader(isLoading)),
    tabSelectionChanged: (tabIndex) => dispatch(tabSelectionChanged(tabIndex)),
    subTabSelectionChanged: (subTabIndex) => dispatch(subTabSelectionChanged(subTabIndex)),
    columnSortSeqChanged: (orderString, orderSeqArr) => dispatch(columnSortSeqChanged(orderString, orderSeqArr)),
    passiveFilterChange: (passiveType) => dispatch(passiveFilterChange(passiveType)),
    limitChange: (limitData) => dispatch(limitChange(limitData)),
    onSearchTextChange: (searchText) => dispatch(onSearchTextChange(searchText))
});

export default connect(mapStateToProps, mapDispatchToProps)(TabOwnership)