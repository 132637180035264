import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetPatternsRequest extends BaseServiceRequest {
    constructor(symbol, msId, startDt, endDt, graphtype, osid, type) {
        super();
        super.addRequestUri("patterns.pb");
        super.addPathParameter(msId.toString());
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("start", startDt);
        super.addRequestParameter("end", endDt);
        super.addRequestParameter("p", graphtype);
        super.addRequestParameter("t", type);
        super.addRequestParameter("o", osid);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "PatternsResponse";
    }
}

export default GetPatternsRequest