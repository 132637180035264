import Serializable from "Serializable";
export default class WonStochasticsUsageSettings extends Serializable {
    constructor() {
        super();
    }
    onSeralisedInfoRecieved(serializedValue) {
        this.Selected = serializedValue.Selected ? serializedValue.Selected : false;
        this.Refresh = serializedValue.Refresh ? serializedValue.Refresh : false;
    }
    getDataObject() {
        return {
            Selected: this.Selected,
            Refresh: this.Refresh,
        }
    }
    getDefaultStochasticsUsageSettings() {
        this.Selected = false;
        this.Refresh = false;
        return this;
    }
    getObjectMapKey() {
        return "StochasticsUsageSettings";
    }
}