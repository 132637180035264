import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class MovingAverageAlertUpsertRequest extends BaseServiceRequest {
  constructor(data) {
    super();
    super.addRequestUri("movingaverage.pb");

    const ReqProto = BaseServiceApi.rayData["MovingAverageAlertData"];
    const alertData = new ReqProto();

    alertData.alert = data.alert;
    alertData.msid = data.msid;

    alertData.length = data.length;
    alertData.periodicity = data.periodicity;
    alertData.maType = data.maType;

    alertData.currencyCode = data.currencyCode;


    const reqcontent = alertData.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.PUT;
  }

  getSubSystem() {
    return SubSystemType.ALERTS;
  }

  getMessageName() {
    return "Response";
  }

}
