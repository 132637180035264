
import { SettingsConstants } from "../../../../../Constants/SettingsConstants";
import { TabDataGraphKpiConstants } from "../../../../../Constants/TabDataGraphKpiConstants";
const { ActionTypes } = TabDataGraphKpiConstants;
const initState = {
  isKpiMenuAvailable: false,
  isUpdated: false,
  kpiSettingsDict: [],
  processedKpiResults: {},
  showBox: false,
  mouseOverId: '',
  showContextMenu: false,
  contextMenuId: '',
  hasError: false,
  errorMsg: '',
  KPIsDataPoints: {},
  availableMenus: [],
  xpos:'',
  ypos:''
}

const KpiModuleReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.KPI_MENU_LOADED:
      return {
        ...state,
        isKpiMenuAvailable: true,
        kpiSettingsDict: action.kpiSettingsDict,
        processedKpiResults: action.processedKpiResults,
        isUpdated: !state.isUpdated
      }
    case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
    case ActionTypes.KPI_CLEAR_STATE:
      return {
        ...state,
        kpiSettingsDict: [],
        processedKpiResults: {},
        showBox: false,
        hasError: false,
        errorMsg: ''
      }
    case ActionTypes.KPI_APPEARANCE_CHANGED: {
      return {
        ...state,
        isUpdated: !state.isUpdated
      }
    }
    case ActionTypes.KPI_LINE_LEAVE:
      return {
        ...state,
        showBox: false,
        mouseOverId: ''
      }
    case ActionTypes.KPI_LINE_OVER:
      return {
        ...state,
        xpos:action.x,
        ypos:action.y,
        showBox: true,
        mouseOverId: action.kpiId
      }
    case ActionTypes.KPI_LINE_CONTEXT_CLICK:
      return {
        ...state,
        showContextMenu: true,
        contextMenuId: action.kpiId
      }
    case ActionTypes.KPI_LINE_CONTEXT_CLOSE:
      return {
        ...state,
        showContextMenu: false
      }
    case ActionTypes.UPDATE_ALL_KPI_DATASET:
      return {
        ...state,
        KPIsDataPoints: action.KPIsDataPoints,
        availableMenus: action.availableMenus,
      }
    default:
      return state
  }
}

export default KpiModuleReducer;