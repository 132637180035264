import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class DeleteScreenScheduleRequest extends BaseServiceRequest {
  constructor(screenid) {
    super();
    super.addRequestUri("deletescreenschedule.pb");
    super.addRequestParameter("screenid", screenid.toString());
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "Response";
  }
}