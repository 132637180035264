import React, { PureComponent } from 'react';

class InputName extends PureComponent {

    componentDidMount() {
        this.inp.focus();
        this.inp.select();
    }

    handleKeyPress = (e) => {
        const keyCode = e.which || e.keyCode;
        if(keyCode === 13) {
            this.props.handleRename(e);
            e.preventDefault();
            e.stopPropagation();
        } 
    } 

    render() {
        return (
            <input type="text" ref={(r) => this.inp = r} autoFocus="true" className="comp-index-inputbox" onBlur={this.props.handleRename} onKeyPress={this.handleKeyPress} defaultValue={this.props.textValue} />
        );
    }
}

export default InputName;