import { put, call, takeLatest, all, fork, select, cancel } from 'redux-saga/effects';
import { getMainState, getSubCategory, getLimitData, getOrderSeqArr, getOsid } from '../../Reducers/OwnershipReport/selectors';
import OwnershipApi from '../../ServiceApi/Apis/OwnershipApi.js'
import { OwnershipActionConstants } from "../../Constants/OwnershipActionConstants";
import OwnershipConstants from "../../Constants/OwnershipConstants.js"
import { each } from "underscore";
import datagraphStore from '../../Stores/NavModules/NavDataGraph/DataGraphStore';
import MiniListHelper from "MiniListHelper";
import TimeTrackingWindow from '../../RayCustomControls/TimeTrackingWindow';
import DatagraphDataType from '../../Constants/DatagraphDataType';
import MiniListStore from '../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore';
import TabType from '../../Constants/TabType.js'
const { ActionTypes } = OwnershipActionConstants;

let getMoreDataTask = null;
let getReportDataTask = null;

/********Watchers***********/
export function* watchHandleReportExtdata() {
    yield takeLatest(ActionTypes.GET_OWNERSHIP_REPORT_DATA, handleReportExtdata);
}

export function* watchHandleChangeTextSize() {
    yield takeLatest(ActionTypes.OWNERSHIP_REPORT_CHANGE_TEXT, handleChangeTextSize);
}

export function* watchHandleResizeOwnershipTab() {
    yield takeLatest(ActionTypes.OWNERSHIP_TAB_RESIZE, handleresizeOwnershipTab);
}

export function* watchTabSelectionChanged() {
    yield takeLatest(ActionTypes.OWNERSHIP_TAB_CHANGE, handleTabSelectionChanged);
}

export function* watchSubTabSelectionChanged() {
    yield takeLatest(ActionTypes.OWNERSHIP_SUB_TAB_CHANGE, handleSubTabSelectionChanged);
}

export function* watchColumnSortSeqChanged() {
    yield takeLatest(ActionTypes.OWNERSHIP_COLUMN_SORT_CHANGED, handleColumnSortSeqChanged);
}

export function* watchPassiveFilterChanged() {
    yield takeLatest(ActionTypes.OWNERSHIP_PASSIVE_OWNER_FILTER_CHANGE, handlePassiveFilterChanged);
}

export function* watchLimitChanged() {
    yield takeLatest(ActionTypes.OWNERSHIP_LIMIT_CHANGE, handleLimitChanged);
}

function* handleChangeTextSize({ textSize }) {
    yield put({
        type: ActionTypes.OWNERSHIP_REPORT_CHANGE_TEXT_SUCCESS,
        result: textSize
    });
}

function* handleresizeOwnershipTab() {
    yield put({
        type: ActionTypes.OWNERSHIP_TAB_RESIZE_SUCCESS
    });
}


/**************Workers***********/
function* handleReportExtdata({ osid }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const _limitData = yield select(getLimitData);
        const orderSeqArr = yield select(getOrderSeqArr);
        if (osid === null) {
            osid = yield select(getOsid);
        }
        _state.ownerType = yield call(getOwnerType)

        yield call(getReportData, osid, _limitData, ActionTypes.OWNERSHIP_PASSIVE_OWNER_FILTER_CHANGE_SUCCESS, _state, _subCategory, orderSeqArr);
    } catch (error) {
        console.log(`Error occured in Ownership.js, handleReportExtdata, ${error}`);
    }
}

function* handleTabSelectionChanged({ tabIndex }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const orderSeqArr = yield select(getOrderSeqArr);
        const _limitData = yield select(getLimitData);
        const osid = yield select(getOsid);

        switch (tabIndex) {
            case 1:
                _state.currentTab = 0;
                _subCategory.AllActive = "active";
                _subCategory.MutualFundActive = "";
                _subCategory.HedgeFundActive = "";
                break;
            case 2:
                _state.currentTab = 1;
                break;
            case 3:
                _state.currentTab = 2;
                break;
            case 4:
                _state.currentTab = 3;
                _subCategory.OthersAllActive = "active";
                _subCategory.CorporationsActive = "";
                _subCategory.IndividualsActive = "";
                _subCategory.InsuranceActive = "";
                _subCategory.otherManagersActive = "";
                break;
            case 5:
                _state.currentTab = 4;
                _subCategory.ByTopOwnersActive = "active";
                _subCategory.ByPositionTypeActive = "";
                _subCategory.ByOwnerTypeActive = "";
                break;
            default:
                _state.currentTab = 0;
                _subCategory.AllActive = "active";
                _subCategory.MutualFundActive = "";
                _subCategory.HedgeFundActive = "";
                break;
        }
        _state.currentSubTab = 0;

        _state.ownerType = yield call(getOwnerType);

        yield call(getReportData, osid, _limitData, ActionTypes.OWNERSHIP_TAB_CHANGE_SUCCESS, _state, _subCategory, orderSeqArr);
    } catch (error) {
        console.log(`Error occured in Ownership.js, handleTabSelectionChanged, ${error}`);
    }
}

function* handleSubTabSelectionChanged({ subTabIndex }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const _limitData = yield select(getLimitData);
        const orderSeqArr = yield select(getOrderSeqArr);
        const osid = yield select(getOsid);

        _state.currentSubTab = subTabIndex;

        if (_state.currentTab === 4 && subTabIndex === 0) {
            _state.isTopOwner = 1;
            _state.isByOwner = 0;
            _state.pageSize = 200;
        }
        else if (_state.currentTab === 4 && subTabIndex === 2) {
            _state.isTopOwner = 0;
            _state.isByOwner = 1;
        }
        else {
            _state.isTopOwner = 0;
            _state.isByOwner = 0;
        }

        if (_state.currentTab === 0 && subTabIndex === 0) {
            _subCategory.AllActive = "active";
            _subCategory.MutualFundActive = "";
            _subCategory.HedgeFundActive = "";
        }
        else if (_state.currentTab === 0 && subTabIndex === 1) {
            _subCategory.AllActive = "";
            _subCategory.MutualFundActive = "active";
            _subCategory.HedgeFundActive = "";
        }
        else if (_state.currentTab === 0 && subTabIndex === 2) {
            _subCategory.AllActive = "";
            _subCategory.MutualFundActive = "";
            _subCategory.HedgeFundActive = "active";
        }
        else if (_state.currentTab === 3 && subTabIndex === 0) {
            _subCategory.OthersAllActive = "active";
            _subCategory.CorporationsActive = "";
            _subCategory.IndividualsActive = "";
            _subCategory.InsuranceActive = "";
            _subCategory.otherManagersActive = "";

        }
        else if (_state.currentTab === 3 && subTabIndex === 1) {
            _subCategory.OthersAllActive = "";
            _subCategory.CorporationsActive = "active";
            _subCategory.IndividualsActive = "";
            _subCategory.InsuranceActive = "";
            _subCategory.otherManagersActive = "";
        }
        else if (_state.currentTab === 3 && subTabIndex === 2) {
            _subCategory.OthersAllActive = "";
            _subCategory.CorporationsActive = "";
            _subCategory.IndividualsActive = "active";
            _subCategory.InsuranceActive = "";
            _subCategory.otherManagersActive = "";
        }
        else if (_state.currentTab === 3 && subTabIndex === 3) {
            _subCategory.OthersAllActive = "";
            _subCategory.CorporationsActive = "";
            _subCategory.IndividualsActive = "";
            _subCategory.InsuranceActive = "active";
            _subCategory.otherManagersActive = "";
        }
        else if (_state.currentTab === 3 && subTabIndex === 4) {
            _subCategory.OthersAllActive = "";
            _subCategory.CorporationsActive = "";
            _subCategory.IndividualsActive = "";
            _subCategory.InsuranceActive = "";
            _subCategory.otherManagersActive = "active";
        }
        else if (_state.currentTab === 4 && subTabIndex === 0) {
            _subCategory.ByTopOwnersActive = "active";
            _subCategory.ByPositionTypeActive = "";
            _subCategory.ByOwnerTypeActive = "";
        }
        else if (_state.currentTab === 4 && subTabIndex === 1) {
            _subCategory.ByTopOwnersActive = "";
            _subCategory.ByPositionTypeActive = "active";
            _subCategory.ByOwnerTypeActive = "";
        }
        else if (_state.currentTab === 4 && subTabIndex === 2) {
            _subCategory.ByTopOwnersActive = "";
            _subCategory.ByPositionTypeActive = "";
            _subCategory.ByOwnerTypeActive = "active";
        }

        _state.ownerType = yield call(getOwnerType);

        yield call(getReportData, osid, _limitData, ActionTypes.OWNERSHIP_SUB_TAB_CHANGE_SUCCESS, _state, _subCategory, orderSeqArr);
    } catch (error) {
        console.log(`Error occured in Ownership.js, handleSubTabSelectionChanged, ${error}`);
    }
}

function* handleColumnSortSeqChanged({ orderString, orderSeqArr }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const _limitData = yield select(getLimitData);
        const osid = yield select(getOsid);

        //_state.ownerType = yield call(getOwnerType);
        _state.orderString = orderString;

        yield call(getReportData, osid, _limitData, ActionTypes.OWNERSHIP_COLUMN_SORT_CHANGED_SUCCESS, _state, _subCategory, orderSeqArr);
    } catch (error) {
        console.log(`Error occured in Ownership.js, handleColumnSortSeqChanged, ${error}`);
    }
}

function* handlePassiveFilterChanged({ passiveType }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const _limitData = yield select(getLimitData);
        const orderSeqArr = yield select(getOrderSeqArr);
        const osid = yield select(getOsid);

        _state.showIndex = passiveType;
        yield call(getReportData, osid, _limitData, ActionTypes.OWNERSHIP_PASSIVE_OWNER_FILTER_CHANGE_SUCCESS, _state, _subCategory, orderSeqArr);
    } catch (error) {
        console.log(`Error occured in Ownership.js, handlePassiveFilterChanged, ${error}`);
    }
}

function* handleLimitChanged({ limitData }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const orderSeqArr = yield select(getOrderSeqArr);
        const osid = yield select(getOsid);
        yield call(getReportData, osid, limitData, ActionTypes.OWNERSHIP_LIMIT_CHANGE_SUCCESS, _state, _subCategory, orderSeqArr);
    } catch (error) {
        console.log(`Error occured in Ownership.js, handleLimitChanged, ${error}`);
    }
}

function* getMoreData(osid, ownerType, orderString, showIndex, seqNumber, isTopOwner, isByOwner, currentTab, currentSubTab, limitData, resultHeader, resultCount) {
    try {
        if (resultHeader.reportHeader) {
            const count = resultHeader.reportHeader ? (resultHeader.reportHeader.column1Count + resultHeader.reportHeader.column2Count + resultHeader.reportHeader.column3Count + resultHeader.reportHeader.column4Count) : 0;
            //if(count > 0){
            const res = yield call(OwnershipApi.getReportExtdata, osid, ownerType, orderString, showIndex, seqNumber, count, isTopOwner, isByOwner, resultCount)

            getMoreDataTask = null;

            const state = datagraphStore.getState();
            const symbol = state.SymbolInfo ? state.SymbolInfo.Symbol : '';
            const isMiniListPlay = state.IsMiniListPlay;
            if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)) {
                const result = yield call(setOwnershipReportData, res, resultHeader, currentTab, currentSubTab);
                yield put({
                    type: ActionTypes.GET_OWNERSHIP_REPORT_MORE_DATA_SUCCESS,
                    result: { resultData: result, limitData: limitData }
                });
            }
        }
    } catch (error) {
        console.log(`Error occured in Ownership.js, getMoreData, ${error}`);
    }
}

function* getReportData(osid, limitData, action, _state, _subCategory, orderSeqArr) {
    try {
        if (getReportDataTask !== null) {
            yield cancel(getReportDataTask);
            getReportDataTask = null;
        }
        getReportDataTask = yield fork(getReportDataCall, osid, limitData, action, _state, _subCategory, orderSeqArr);
        //return {resultData: result, resultHeader : resultHeader, limitData: limitData};
    } catch (error) {
        console.log(`Error occured in Ownership.js, getReportData, ${error}`);
    }
}

function* getReportDataCall(osid, limitData, action, _state, _subCategory, orderSeqArr) {
    try {
        let limitDataCopy = limitData;

        if (getMoreDataTask !== null) {
            yield cancel(getMoreDataTask);
            getMoreDataTask = null;
        }

        if (limitData === undefined || limitData === null) {
            const _limitData = yield select(getLimitData);
            limitData = limitData === undefined ? _limitData : limitData;
        }

        let resultHeader, resultData;

        if (_state.currentTab === 4 && _state.currentSubTab === 0) {
            limitDataCopy = true;

            [resultHeader, resultData] = yield all([
                call(OwnershipApi.getHeaderData, osid, _state.ownerType, _state.currentTab, _state.currentSubTab, _state.showIndex),
                call(OwnershipApi.getReportExtdata, osid, _state.ownerType, _state.orderString, _state.showIndex, 1, 200, true, false, 100)
            ]);
        }
        else if (_state.currentTab === 4 && _state.currentSubTab === 2) {
            [resultHeader, resultData] = yield all([
                call(OwnershipApi.getHeaderData, osid, _state.ownerType, _state.currentTab, _state.currentSubTab, _state.showIndex),
                call(OwnershipApi.getReportExtdata, osid, _state.ownerType, _state.orderString, _state.showIndex, 1, 100, false, true, 100)
            ]);
        }
        else {
            [resultHeader, resultData] = yield all([
                call(OwnershipApi.getHeaderData, osid, _state.ownerType, _state.currentTab, _state.currentSubTab, _state.showIndex),
                call(OwnershipApi.getReportExtdata, osid, _state.ownerType, _state.orderString, _state.showIndex, 1, 100, false, false, 100)
            ]);
        }

        //const result = yield call(setOwnershipReportData, resultData, resultHeader, _state.currentTab, _state.currentSubTab);

        //yield call(UpdateAction, action, result, resultHeader, limitData, _state, _subCategory, orderSeqArr, osid, limitDataCopy);
        const state = datagraphStore.getState();
        const symbol = state.SymbolInfo ? state.SymbolInfo.Symbol : '';
        const isMiniListPlay = state.IsMiniListPlay;
        const isActiveSymbolValid = MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay);
        if ((resultData.reportSet.length > 0 || resultHeader.reportHeader) && isActiveSymbolValid) {
            const result = yield call(setOwnershipReportData, resultData, resultHeader, _state.currentTab, _state.currentSubTab);
            yield call(UpdateAction, action, result, resultHeader, limitData, _state, _subCategory, orderSeqArr, osid, limitDataCopy);
        }
        else {
            const setData = { data: [] };
            yield call(UpdateAction, action, setData, resultHeader, limitData, _state, _subCategory, orderSeqArr, osid, limitDataCopy);
        }
        // if(state.SelectedTabKey === TabType.Ownership && isMiniListPlay) {
        //     MiniListStore.setMiniListNextInterval();
        // }

        if (limitDataCopy === false && isActiveSymbolValid) {
            getMoreDataTask = yield fork(getMoreData, osid, _state.ownerType, _state.orderString, _state.showIndex, 401, _state.isTopOwner, _state.isByOwner, _state.currentTab, _state.currentSubTab, limitData, resultHeader, _state.resultCount);
        }
    } catch (error) {
        console.log(`Error occured in Ownership.js, getReportData, ${error}`);
    }
}

function* getOwnerType() {
    try {
        const _state = yield select(getMainState);
        let ownerType;
        switch (_state.currentTab) {
            case 0:
                {
                    switch (_state.currentSubTab) {
                        case 0:
                            ownerType = "401,402,106";
                            break;
                        case 1:
                            ownerType = "401";
                            break;
                        case 2:
                            ownerType = "402,106";
                            break;
                        default:
                            ownerType = "401,402,106";
                            break;
                    }
                    break;

                }
            case 1:
                {
                    ownerType = "107,113,201,202";
                    break;
                }
            case 2:
                {
                    ownerType = "101";
                    break;
                }
            case 3:
                {
                    switch (_state.currentSubTab) {
                        case 0:
                            ownerType = "102,103,104,105,108,110,111,112,301,302,303";
                            break;
                        case 1:
                            ownerType = "301,302";
                            break;
                        case 2:
                            ownerType = "303";
                            break;
                        case 3:
                            ownerType = "108";
                            break;
                        case 4:
                            ownerType = "102,103,104,105,110,111,112";
                            break;
                        default:
                            ownerType = "102,103,104,105,108,110,111,112,301,302,303";
                            break;
                    }
                    break;
                }
            case 4:
                {
                    ownerType = "0";
                    break;
                }
            default:
                {
                    ownerType = "401,402,106";
                    break;
                }
        }

        return ownerType;
    } catch (error) {
        console.log(`Error occured in Ownership.js, getOwnerType, ${error}`);
    }
}

function getOwnershipDataSetData(reportdata, type) {
    try {
        const DataList = [];
        const SetData = {};

        switch (type) {
            case "Funds":
                for (let i = 0; i < reportdata.length; i++) {
                    if (reportdata[i].wONType.includes("Funds")) { DataList.push(reportdata[i]); }
                }
                SetData.reportList = DataList;
                break;
            case "Advisors":
                for (let i = 0; i < reportdata.length; i++) {
                    if (reportdata[i].wONType.includes("Advisors")) { DataList.push(reportdata[i]); }
                }
                SetData.reportList = DataList;
                break;
            case "Banks":
                for (let i = 0; i < reportdata.length; i++) {
                    if (reportdata[i].wONType.includes("Banks")) { DataList.push(reportdata[i]); }
                }
                SetData.reportList = DataList;
                break;
            case "Other":
                for (let i = 0; i < reportdata.length; i++) {
                    if (reportdata[i].wONType.includes("Other")) { DataList.push(reportdata[i]); }
                }
                SetData.reportList = DataList;
                break;
            case "O1":
                for (let i = 0; i < 50 && i < reportdata.length; i++) {
                    DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
            case "O2":
                for (let i = 50; i < 100 && i < reportdata.length; i++) {
                    DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
            case "O3":
                for (let i = 100; i < 150 && i < reportdata.length; i++) {
                    DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
            case "O4":
                for (let i = 150; i < 200 && i < reportdata.length; i++) {
                    DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
            default:
                for (let i = 0; i < reportdata.length; i++) {
                    if (reportdata[i].position === type) { DataList.push(reportdata[i]); }
                }
                SetData.reportList = DataList;
                break;
        }
        SetData.Key = type;
        return SetData;

    } catch (error) {
        console.log(`Error occured in Ownership.js, getOwnershipDataSetData, ${error}`);
    }
}

function* setOwnershipReportData(reportinfo, resultHeader, currentTab, currentSubTab) {
    try {
        let type = [];
        let set = {};
        let reportName = [];
        const setData = { data: [] };
        if (resultHeader.reportHeader && (resultHeader.reportHeader.column1Count > 0 || resultHeader.reportHeader.column2Count > 0 || resultHeader.reportHeader.column3Count > 0 || resultHeader.reportHeader.column4Count > 0)) {
            const counts = [resultHeader.reportHeader ? resultHeader.reportHeader.column1Count : 0,
            resultHeader.reportHeader ? resultHeader.reportHeader.column2Count : 0,
            resultHeader.reportHeader ? resultHeader.reportHeader.column3Count : 0,
            resultHeader.reportHeader ? resultHeader.reportHeader.column4Count : 0];

            const reportdata = reportinfo.reportSet;

            each(reportdata, (item) => {
                if (item.carryForward === "1") {
                    item.name = `* ${item.sponsorName}`;
                    item.nameAbbr = `* ${item.sponsorNameAbbr}`;
                } else if (item.carryForward === "2") {
                    item.name = `** ${item.sponsorName}`;
                    item.nameAbbr = `** ${item.sponsorNameAbbr}`;
                } else {
                    item.name = `${item.sponsorName}`;
                    item.nameAbbr = `${item.sponsorNameAbbr}`;
                }
            })

            if (currentTab === 4 && currentSubTab === 0) {
                type = ["O1", "O2", "O3", "O4"];
                for (let i = 0; i < 4; i++) {
                    const strFromTo = i === 0 ? " : 1 - 25" : i === 1 ? " : 26 - 50" : i === 2 ? " : 51 - 75" : " : 76 - 100";
                    set = yield call(getOwnershipDataSetData, reportdata, type[i]);
                    set.reportName = `Top Owners${strFromTo}`;
                    set.key = type[i];
                    set.count = counts[i];
                    setData.data.push(set);
                }
            }
            else if (currentTab === 4 && currentSubTab === 2) {

                type = ["Funds", "Advisors", "Banks", "Other"];
                for (let i = 0; i < 4; i++) {
                    set = yield call(getOwnershipDataSetData, reportdata, type[i]);
                    set.reportName = `${type[i]}: `;
                    //set.key = type[i];
                    set.count = counts[i];
                    setData.data.push(set);
                }
            }
            else {
                type = [OwnershipConstants.OwnershipNew, OwnershipConstants.OwnershipInc, OwnershipConstants.OwnershipUnchg, OwnershipConstants.OwnershipDec];
                reportName = ["New: ", "Added: ", "Unchanged: ", "Reduced: "];
                for (let i = 0; i < 4; i++) {
                    set = yield call(getOwnershipDataSetData, reportdata, type[i]);
                    set.reportName = reportName[i];
                    set.key = type[i];
                    set.count = counts[i];
                    setData.data.push(set);
                }
            }
        }
        return setData;
    } catch (error) {
        console.log(`Error occured in Ownership.js, setOwnershipReportData, ${error}`);
    }
}

function* UpdateAction(action, resultData, resultHeader, limitData, _state, _subCategory, orderSeqArr, osid, limitDataCopy) {
    try {
        getReportDataTask = null;
        yield put({
            type: action,
            result: {
                mainState: _state,
                subCategory: _subCategory,
                resultData: resultData,
                headerData: resultHeader,
                limitData: limitData,
                orderSeqArr: orderSeqArr,
                osid: osid,
                limitDataCopy: limitDataCopy
            }
        });

    } catch (error) {
        console.log(`Error occured in Ownership.js, UpdateAction, ${error}`);
    }
}







