import DataGraphStore from "../Stores/NavModules/NavDataGraph/DataGraphStore";
import TabDataGraphStore from "../Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore.js";
import DateHelper from "DateHelper";
import SymbolType from "SymbolType";
import ConsoleStore from "ConsoleStore";
import moment from "moment";
import GraphType from "GraphType";
import StockMarketUtil from "../Utils/StockMarketUtil";
import WorkSpaceRealTimePrices from "./RealTimeHelper/WorkSpaceRealTimePrices";
import SmartViewType from "../Constants/SmartViewType.js";
import SettingsStore from "SettingsStore";
import PeriodicityHelper from "../Utils/PeriodicityHelper";
import StringUtil from "./StringUtil";
/********* Helper Functions - Start */

class DatagraphHelper {

    getIsHistoricValue(symbolInfo) {

        const settings = TabDataGraphStore.getTabDatagraphSettings();
        const lastTradeDate = new Date(DateHelper.getUtcFromLong(symbolInfo.LastTradeDate));
        const IPODate = new Date(DateHelper.getUtcFromLong(symbolInfo.IPODate));
        const userDate = ConsoleStore.getUserEndDate();
        let endDate = null;
        let gEndDate = userDate;
        if ((gEndDate === null || gEndDate === undefined) && settings.isHistoric && settings.userEndDate && new Date(settings.userEndDate) <= lastTradeDate && new Date(settings.userEndDate) >= IPODate) {
            endDate = new Date(settings.userEndDate);
        }
        else if (gEndDate === null || gEndDate === undefined || lastTradeDate < gEndDate) {
            endDate = lastTradeDate;
        }
        else {
            endDate = gEndDate;
        }

        const getEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        const getTradeDate = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate());

        if (symbolInfo.SymTypeEnum === SymbolType.HISTORICSTOCK ||
            symbolInfo.SymTypeEnum === SymbolType.HISTORICINDEX) {
            return true;
        }
        else {
            return getEndDate.getTime() < getTradeDate.getTime();
        }
    }

    getPriceChartStore() {
    }
    getInstSettings() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        return this.getSettingsObject(consoleSettings);
    }
    getSettingsObject(userSettings, settingsObj = null, symbolTypeEnum, isNupview = false) {
        const viewSettings = settingsObj ? settingsObj : userSettings.NavDatagraphSettings.TabDataGraphSettings;
        symbolTypeEnum = isNupview || symbolTypeEnum ? symbolTypeEnum ? symbolTypeEnum : userSettings.NavDatagraphSettings.symbolTypeEnum : userSettings.NavDatagraphSettings.symbolTypeEnum;

        switch (symbolTypeEnum) {
            case SymbolType.USSTOCK:
                return viewSettings.StockViewSettings;
            case SymbolType.CURRENCY:
                return viewSettings.CurrencyViewSettings;
            case SymbolType.CRYPTOCURRENCY:
                return viewSettings.CryptoCurrencyViewSettings;
            case SymbolType.ETF:
                return viewSettings.ETFViewSettings;
            case SymbolType.FundClosedEnd:
                return viewSettings.CEFViewSettings;
            case SymbolType.FUND:
                return viewSettings.FUNDViewSettings;
            case SymbolType.INDEXNOINTRADAYVOLDATA:
            case SymbolType.ECONOMICINDICATOR:
            case SymbolType.INDEXNOINTRADAYDATA:
            case SymbolType.INDEXNOINTRADAYVOL:
            case SymbolType.INTERNATIONALINDEX:
            case SymbolType.HISTORICINDEX:
            case SymbolType.INDEX:
                return viewSettings.IndexViewSettings;
            case SymbolType.FUTURECONTRACTS:
                return viewSettings.FuturesViewSettings;
            case SymbolType.CASHSPOTS:
                return viewSettings.CashSpotsViewSettings;
            case SymbolType.WONINDUSTRYGROUP197:
            case SymbolType.WONMAJORINDUSTRY89:
            case SymbolType.WONSECTOR11:
            case SymbolType.WONSUBSECTOR33:
                return viewSettings.IndustryGroupViewSettings;
            default:
                return viewSettings.StockViewSettings;
        }
    }

    getViewStore() {
        // let symbolType;
        // if (DataGraphStore.getState().SymbolInfo)
        //     symbolType = DataGraphStore.getState().SymbolInfo.SymTypeEnum;

        // switch (symbolType) {
        //     case SymbolType.USSTOCK:
        //         return StockViewStore;

        //     case SymbolType.CURRENCY:
        //         return CurrencyViewStore;

        //     case SymbolType.CRYPTOCURRENCY:
        //         return CryptoCurrencyViewStore;

        //     case SymbolType.ETF:
        //         return ETFViewStore;

        //     case SymbolType.FundClosedEnd:
        //         return CEFViewStore;

        //     case SymbolType.INDEXNOINTRADAYVOLDATA:
        //     case SymbolType.ECONOMICINDICATOR:
        //     case SymbolType.INDEXNOINTRADAYDATA:
        //     case SymbolType.INDEXNOINTRADAYVOL:
        //     case SymbolType.INTERNATIONALINDEX:
        //     case SymbolType.HISTORICINDEX:
        //     case SymbolType.INDEX:
        //         return IndexViewStore;

        //     case SymbolType.FUND:
        //         return FundsViewStore;

        //     case SymbolType.CASHSPOTS:
        //         return CashSpotsViewStore;

        //     case SymbolType.FUTURECONTRACTS:
        //         return FuturesViewStore;

        //     case SymbolType.WONINDUSTRYGROUP197:
        //     case SymbolType.WONMAJORINDUSTRY89:
        //     case SymbolType.WONSECTOR11:
        //     case SymbolType.WONSUBSECTOR33:
        //         return IndustryGroupViewStore;

        //     default:
        //         return StockViewStore;
        // }
    }

    getBufferNode() {
        const state = this.getPriceChartStore().getState();

        switch (state.periodicity) {
            case GraphType.Intraday1Min:
                const store = this.getViewStore();
                return store.IsTradingHours && store.IsTradingHours() && WorkSpaceRealTimePrices.IsNasdaqMarketHours() ? 4 : 0;

            case GraphType.Weekly:
                return state.endDate.getDay() === 5 ? 0 : 4;

            case GraphType.Monthly:
                let endOfMonth = moment(state.endDate).endOf("month");
                endOfMonth = endOfMonth.day() === 6 ? endOfMonth.subtract(1, 'day') : endOfMonth.day() === 0 ? endOfMonth.subtract(2, 'day') : endOfMonth;
                return endOfMonth.date() === state.endDate.getDate() ? 0 : 4;

            case GraphType.Quarterly:
                const month = moment(state.endDate).month() + 1;
                if (month === 3 || month === 6 || month === 9 || month === 12) {
                    let endOfMonth = moment(state.endDate).endOf("month");
                    endOfMonth = endOfMonth.day() === 6 ? endOfMonth.subtract(1, 'day') : endOfMonth.day() === 0 ? endOfMonth.subtract(2, 'day') : endOfMonth;
                    return endOfMonth.date() === state.endDate.getDate() ? 0 : 4;
                }
                return 4;
            case GraphType.Annual:
                if (moment(state.endDate).endOf("year").month() === moment(state.endDate).month()) {
                    let endOfYear = moment(state.endDate).endOf("year");
                    endOfYear = endOfYear.day() === 6 ? endOfYear.subtract(1, 'day') : endOfYear.day() === 0 ? endOfYear.subtract(2, 'day') : endOfYear;
                    return endOfYear.date() === state.endDate.getDate() ? 0 : 4;
                }
                return 4;

            default: return 0;
        }
    }


    getSymbolType(SymTypeEnum) {
        let symbolType = "";
        switch (SymTypeEnum) {
            case SymbolType.ADR:
            case SymbolType.USSTOCK:
            case SymbolType.INTERNATIONALSTOCK:
            case SymbolType.REIT:
            case SymbolType.PREIPO:
            case SymbolType.HISTORICSTOCK:
            case SymbolType.HISTORICALMODELSTOCK:
                symbolType = SmartViewType.STOCK;
                break;
            case SymbolType.FUTURECONTRACTS:
                symbolType = SmartViewType.FUTURES;
                break;
            case SymbolType.CASHSPOTS:
                symbolType = SmartViewType.CASHSPOTS;
                break;
            case SymbolType.INDEX:
            case SymbolType.INDEXNOINTRADAYDATA:
            case SymbolType.INDEXNOINTRADAYVOL:
            case SymbolType.INDEXNOINTRADAYVOLDATA:
            case SymbolType.INTERNATIONALINDEX:
            case SymbolType.HISTORICINDEX:
            case SymbolType.ECONOMICINDICATOR:
                symbolType = SmartViewType.INDEX;
                break;
            case SymbolType.FundClosedEnd:
                symbolType = SmartViewType.CEF;
                break;
            case SymbolType.ETF:
                symbolType = SmartViewType.ETF;
                break;
            case SymbolType.FUND:
                symbolType = SmartViewType.FUND;
                break;
            case SymbolType.WONINDUSTRYGROUP197:
            case SymbolType.WONMAJORINDUSTRY89:
            case SymbolType.WONSECTOR11:
            case SymbolType.WONSUBSECTOR33:
                symbolType = SmartViewType.INDUSTRYGROUP;
                break;
            case SymbolType.CURRENCY:
                symbolType = SmartViewType.CURRENCY;
                break;
            case SymbolType.CRYPTOCURRENCY:
                symbolType = SmartViewType.CRYPTOCURRENCY;
                break;
            default:
                symbolType = SmartViewType.STOCK;
                break;
        }
        return symbolType;
    }

    getAllOwnershipBlockSettings() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        const periodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
        let ownershipListIds = '';
        const stockOwnership = tabDataGraphSettings.StockViewSettings.OwnershipBlockSettings[periodicity];
        const etfOwnership = tabDataGraphSettings.ETFViewSettings.OwnershipBlockSettings[periodicity];
        const cefOwnership = tabDataGraphSettings.CEFViewSettings.OwnershipBlockSettings[periodicity];
        let stockOwnershipListId = stockOwnership.IsFavList ? stockOwnership.ListContentId : stockOwnership.ListId;
        let etfOwnershipListId = etfOwnership.IsFavList ? etfOwnership.ListContentId : etfOwnership.ListId;
        let cefOwnershipListId = cefOwnership.IsFavList ? cefOwnership.ListContentId : cefOwnership.ListId;
        stockOwnershipListId = stockOwnershipListId ? stockOwnershipListId : PanarayDefault.DefaultOwnershipListId;
        etfOwnershipListId = etfOwnershipListId ? etfOwnershipListId : PanarayDefault.DefaultOwnershipListId;
        cefOwnershipListId = cefOwnershipListId ? cefOwnershipListId : PanarayDefault.DefaultOwnershipListId;
        ownershipListIds = "stock=" + stockOwnershipListId + ";etf=" + etfOwnershipListId + ";cef=" + cefOwnershipListId;
        return ownershipListIds;
    }
    getFlaggedIdsString() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        const periodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
        let ownershipflags = '';
        const stockOwnership = tabDataGraphSettings.StockViewSettings.OwnershipBlockSettings[periodicity];
        const etfOwnership = tabDataGraphSettings.ETFViewSettings.OwnershipBlockSettings[periodicity];
        const cefOwnership = tabDataGraphSettings.CEFViewSettings.OwnershipBlockSettings[periodicity];
        let stockOwnershipFlagged = stockOwnership.IsFlaggedList ? 1 : 0;
        let etfOwnershipFlagged = etfOwnership.IsFlaggedList ? 1 : 0;
        let cefOwnershipFlagged = cefOwnership.IsFlaggedList ? 1 : 0;
        ownershipflags = "stock=" + stockOwnershipFlagged + ";etf=" + etfOwnershipFlagged + ";cef=" + cefOwnershipFlagged;
        return ownershipflags;
    }

    selectCurrencyCode() {
        const settings = SettingsStore.getConsoleSettings();
        const {
            isCurrencyConversionChecked,
            selectedCurrencyCode
        } = settings;
        if (isCurrencyConversionChecked) {
            if (selectedCurrencyCode == "GBP") {
                return "GBX";
            }
            if (selectedCurrencyCode == "ZAR") {
                return "ZAX";
            }
            return selectedCurrencyCode;
        }
        return null;
    }

    commonStock(info) {
        return (info &&
            info.SymTypeEnum &&
            (info.SymTypeEnum === SymbolType.USSTOCK ||
                info.SymTypeEnum === SymbolType.PREIPO ||
                info.SymTypeEnum === SymbolType.ADR ||
                info.SymTypeEnum === SymbolType.HISTORICSTOCK ||
                info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK ||
                info.SymTypeEnum === SymbolType.MODELSTOCK ||
                info.SymTypeEnum === SymbolType.ETF ||
                info.SymTypeEnum === SymbolType.REIT ||
                info.SymTypeEnum === SymbolType.FundClosedEnd ||
                info.SymTypeEnum === SymbolType.HISTORICALMODELSTOCK ||
                info.SymTypeEnum === SymbolType.ECONOMICINDICATOR
            )
        );
    }

    internationalStock(info) {
        return (info &&
            info.SymTypeEnum &&
            info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK
        );
    }

    getIsShowSelectedCurrency(SymTypeEnum) {
        const { isDualCurrency, isMatchFinancials, defaultCurrency, selectedCurrencyCode } = SettingsStore.getConsoleSettings();
        let isShowSelectedCurrency = false;
        if (isDualCurrency && isMatchFinancials) {
            isShowSelectedCurrency = defaultCurrency != selectedCurrencyCode;
        } else if (isDualCurrency && !isMatchFinancials) {
            isShowSelectedCurrency = true;
        } else if (!defaultCurrency) {
            isShowSelectedCurrency = false;
        } else if (SymTypeEnum == SymbolType.FUTURECONTRACTS ||
            SymTypeEnum == SymbolType.CASHSPOTS) {
            isShowSelectedCurrency = false;
        } else {
            isShowSelectedCurrency = defaultCurrency != selectedCurrencyCode;
        }
        return isShowSelectedCurrency;
    }
    isShowPatternORFactor(SymTypeEnum) {
        const settings = SettingsStore.getConsoleSettings();
        const {
            isCurrencyConversionChecked
        } = settings;
        const isShowSelectedCurrency = this.getIsShowSelectedCurrency(SymTypeEnum);
        return !(isCurrencyConversionChecked && isShowSelectedCurrency);
    }
    getMaxBuffer(){
        return 200;
    }

    getCurrencyCode(currencyCode, istargetCurrency) {
        let code = "";
        if (StringUtil.isEmpty(currencyCode.CurrencyCode)) {
            code = currencyCode.Code;
        }
        else {
            code = currencyCode.CurrencyCode
        }
        if (!istargetCurrency && code === "GBP") {
            return "GBX";
        }
        if (!istargetCurrency && code === "ZAR") {
            return "ZAX";
        }
        return code;
    }

    isCommonStock(info) {
        return (info &&
            info.SymTypeEnum &&
            (info.SymTypeEnum === SymbolType.USSTOCK ||
                info.SymTypeEnum === SymbolType.PREIPO ||
                info.SymTypeEnum === SymbolType.ADR ||
                info.SymTypeEnum === SymbolType.HISTORICSTOCK ||
                info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK ||
                info.SymTypeEnum === SymbolType.MODELSTOCK ||
                info.SymTypeEnum === SymbolType.ETF ||
                info.SymTypeEnum === SymbolType.REIT ||
                info.SymTypeEnum === SymbolType.FundClosedEnd ||
                info.SymTypeEnum === SymbolType.HISTORICALMODELSTOCK
            )
        );
    }

    isDomesticStock(info) {
        return (info &&
            info.SymTypeEnum &&
            (info.SymTypeEnum === SymbolType.USSTOCK ||
                info.SymTypeEnum === SymbolType.REIT ||
                info.SymTypeEnum === SymbolType.PREIPO ||
                info.SymTypeEnum === SymbolType.ADR ||
                info.SymTypeEnum === SymbolType.HISTORICSTOCK ||
                info.SymTypeEnum === SymbolType.MODELSTOCK ||
                info.SymTypeEnum === SymbolType.HISTORICALMODELSTOCK
            )
        );
    }

    NDateNode(nDate, absolute = false, endDate, periodicity) {
        const dayOfWeek = nDate.getDay();
        if (dayOfWeek === 6) { nDate.setDate(nDate.getDate() - 1); }
        if (dayOfWeek === 0) { nDate.setDate(nDate.getDate() - 2); }

        switch (periodicity) {
            case GraphType.Weekly:
                if (dayOfWeek !== 5) {
                    const dys = 5 - dayOfWeek;
                    nDate = new Date(nDate.getFullYear(), nDate.getMonth(), nDate.getDate() + dys);
                    if (nDate > endDate && !absolute) { nDate = endDate; }
                }
                break;
            case GraphType.Monthly:
                nDate = StockMarketUtil.GetMEndDate(nDate);
                if (nDate > endDate && !absolute) { nDate = endDate; }
                break;
            case GraphType.Annual: {
                nDate = StockMarketUtil.GetAEndDate(nDate);
                if (nDate > endDate && !absolute) { nDate = endDate; }
            }
                break;
            case GraphType.Quarterly: {
                let year = nDate.getFullYear();
                const cMonth = nDate.getMonth() + 1;
                const add = cMonth % 3;
                let month = (cMonth + (add === 1 ? 2 : add === 2 ? 1 : 0)) + 1;
                if (month > 12) {
                    month = 1;
                    year++;
                }

                nDate = new Date(year, month - 1, 1);
                nDate.setDate(nDate.getDate() - 1);
                nDate = StockMarketUtil.GetMEndDate(nDate);
                if (nDate > endDate && !absolute) { nDate = endDate; }
            }
                break;
            default:
                break;
        }
        return nDate;

    }
    /********* Helper Functions - End */
}

const datagraphHelper = new DatagraphHelper();
export default datagraphHelper;


export const DatagraphConst = {
    nodeWidth: 4,
    leftScaleWidth: 42,
    rightScaleWidth: 115
}
