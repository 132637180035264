import { connect } from 'react-redux';
import LocalizationStore from 'LocalizationStore';
import { Modal } from "react-bootstrap";
import React from 'react';
import { updateCommonAboutDialog } from '../../../../Actions/DatagraphActions';
import UserInfoUtil from "UserInfoUtil";

function CommonAboutDialog(props) {
    const aboutUrl = UserInfoUtil.hasChinaEntitlement() ? props.commonAboutDialogUrl.replace(/PanarayFeatureAbout/, 'PanarayFeatureAbout_China') : props.commonAboutDialogUrl
    const openDialogClass = props.isCommonAboutDialogOpen ? " dialog-open" : "";
    return (
        <div>
            <Modal className={`modal-popup indicator-about-us${openDialogClass}`} show={props.isCommonAboutDialogOpen}>
                <Modal.Header>
                    <Modal.Title>
                        <span className="cap-header">
                            <span className="indi-about">{LocalizationStore.getTranslatedData("ipc_abt", "About")}</span>
                            <span className="indica-about">{LocalizationStore.getTranslatedData(`ri_${props.commonAboutDialogTitle.replace(/ /ig, "")}`, props.commonAboutDialogTitle)}</span>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="ps-tiindicator-box">
                        <iframe src={aboutUrl} title={`This iframe is show about ${props.commonAboutDialogTitle}`} className="about-iframe"></iframe>
                        <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn-box center">
                        <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={props.closeAboutDialog}>{LocalizationStore.getTranslatedData("ipc_cls", "CLOSE")}</button>
                    </span>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ DatagraphReducers })=>{
    const { isCommonAboutDialogOpen, commonAboutDialogUrl, commonAboutDialogTitle } = DatagraphReducers.DataGraphReducer;
    return { isCommonAboutDialogOpen, commonAboutDialogUrl, commonAboutDialogTitle }
}

const mapDispatchToProps = (dispatch)=>({
    closeAboutDialog: ()=> dispatch(updateCommonAboutDialog(false))
})

export default connect(mapStateToProps, mapDispatchToProps)(CommonAboutDialog);