import EventUtil from '../../../../../../Utils/EventUtil.js';
import LocalizationStore from '../../../../../../Stores/Localization/LocalizationStore';
import React from "react";
import { setRelativeYTD } from "../../../../../../Actions/SummaryBlockActions";
import SettingsAction from "SettingsAction";
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import { useSelector, useDispatch } from "react-redux";

function FundDetailsControl() {
    const positiveBrushText = useSelector((state) => state.appColor.posDTColor)
    const negativeBrushText = useSelector((state) => state.appColor.negDTColor);
    const detailData = useSelector((state) => state.summaryReducer.fundDetailItems);
    let data = detailData.dataSource;
    const isYtdAbsolute = detailData.isYtdAbsolute;
    const dispatch = useDispatch();

    const mouseOverShowTitle = (e, value) => {
        EventUtil.mouseOverShowTitle(e, value);
    }
    const getItem = (item, index) => {
        const header = LocalizationStore.getTranslatedData(`SumBlockName_${item.headerId}`, item.header)
        return (
            <div className="info-row" key={index}>
                <div className="data-cell swatch-color">{header}</div>
                <div className="data-cell">
                    <p className="text-right" onMouseOver={(e) => mouseOverShowTitle(e, item.value)} onFocus={(e) => mouseOverShowTitle(e, item.value)}>{item.value}</p>
                </div>
            </div>
        );
    }
    const handleChange = (e, index) => {
        const toggleItem = data[index];
        toggleItem.activeItemData = !isYtdAbsolute ? toggleItem.defualtItemData : toggleItem.toggleItemData;
        const tempStateData = data;
        tempStateData[index] = toggleItem;
        data = tempStateData;
        dispatch(setRelativeYTD(!isYtdAbsolute, index));
        SettingsAction.saveUserSettings();
    }
    const getYTDItem = (item, index) => {
        const header = LocalizationStore.getTranslatedData(`SumBlockName_${item.activeItemData.headerId}`, item.activeItemData.header);
        let brush = item.activeItemData.color ? item.activeItemData.color : null;
        const subHeader = LocalizationStore.getTranslatedData(`sb_mf_${item.activeItemData.headerId}`, item.activeItemData.subHeaderText);
        //apply custom color
        if (item.activeItemData && item.activeItemData.isUseCusColor) {
            brush = item.activeItemData.isPositive ? positiveBrushText
                : negativeBrushText
        }
        return (
            <div className="info-row" key={index}>
                <div className="data-cell swatch-color" >{header} <span className="link-txt" onClick={(e) => handleChange(e, index)}>{subHeader}</span></div>
                <div className="data-cell"><p className="text-right" style={{ color: brush }}>{item.activeItemData.value}</p></div>
            </div>
        );
    }

    return (
        <div className="fundDetail-info-block">
            <div className="info-title">{SummaryTransLateHelper.FUND_DETAILS}</div>
            {data.map((item, index) =>
                item.header ? getItem(item, index) : getYTDItem(item, index)
            )}
        </div>
    );

}
export default FundDetailsControl