import React, { Component } from 'react';
import { connect } from 'react-redux';
import ThemeHelper from "ThemeHelper";
import { map, isEmpty, find } from "underscore";
import { showExternalInfoText } from '../../../../../../Actions/ExternalDataUploaderActions';
import moment from "moment";
import ScrollBar from "ScrollBar";

class ExternalDataEvents extends Component {
    constructor(props) {
        super(props)
        this.leftPx = 0;
        this.topPx = 0;
        this.xAxis = 0;
        this.yAxis = 0;
        this.infobox = '';
        this.infoOpen = '';
        this.mouseOut = true;
    }

    getCurrentBadgeInfo = (listId, date) => {
        const data = find(this.props.expandedEventBadgeData[listId], (item) => moment(item.date).isSame(moment(date)));
        return data;
    }

    showInfoBoxDivIn = () => {
        this.mouseOut = false;
        if (!this.infoOpen) { this.props.showExternalInfoText(this.infobox); }
    }

    showInfoBoxDivOut = () => {
        this.mouseOut = true;
        this.props.showExternalInfoText("");
    }

    showInfoBox = (xAxis, yAxis, category, listId, advancedDataSettings, count, date) => {
        const expandedEventBadgeData = count <= 1 && this.getCurrentBadgeInfo(listId, date);
        let isShowInfoBox = false;
        const popOverWidthDimention = 150;
        const popOverHeightDimention = 125;
        this.mouseOut = true;
        const compareWidth = this.props.dimension.width;
        const compareHeight = this.props.dimension.height;

        const leftPx = (compareWidth - xAxis) <= popOverWidthDimention ? `${xAxis - popOverWidthDimention - 10}px` : `${xAxis + 2}px`;
        const topPx = (compareHeight - yAxis) <= popOverHeightDimention ? `${yAxis - popOverHeightDimention - 2}px` : `${yAxis + 12}px`;

        let infobox = '';
        if (count > 1) {
            isShowInfoBox = true;
            infobox = (
                <div className="ext-additionals event-counting" id="externalInfoBox" style={{ top: `${topPx}`, left: `${leftPx}` }}>
                    <div className="title">
                        <h5>{"Events"}</h5>
                        <span className="counting">{count}</span>
                    </div>
                    <div className="ext-additional-content">
                        <p>This bar contains <span className="count-digit">{count}</span> events To view individual items, reduce chart interval to a lower such as <i className="count-interval">Daily</i></p>
                    </div>
                </div>
            );
        }
        else if (!isEmpty(expandedEventBadgeData) && !isEmpty(expandedEventBadgeData[category])) {
            infobox = (
                <div onMouseOver={() => this.showInfoBoxDivIn()}
                    onFocus={() => this.showInfoBoxDivIn()}
                    onMouseLeave={() => this.showInfoBoxDivOut()}
                    className="ext-additionals event-badging virtualList showscrollOnHover" id="externalInfoBox" style={{ top: `${topPx}`, left: `${leftPx}` }}>
                    <div className="title"><h6>{"Events"}</h6></div>
                    <div className="ext-additional-scroll">
                        <div className="custom-scroll">
                            <div id='scrollExternalAdditionalEventsBadge'>
                                <div className="ext-additional-content">
                                    <ul>
                                        {advancedDataSettings && map(advancedDataSettings, (item, esField) => {
                                            const data = item.IsVisible && expandedEventBadgeData[category][esField];
                                            if (data && item.IsVisible) {
                                                isShowInfoBox = true;
                                                return (
                                                    <li key={esField}><span className="ext-entry">{item.displayName} :</span><span className="ext-value">{data}</span></li>
                                                )
                                            }
                                        })
                                        }
                                        {/* <li>Comments: Facebook increases its widget mindshare with acquisition.</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId='scrollExternalAdditionalEventsBadge' vScroll={true} scrollOnHover={true} />
                    </div>
                    <div className="clearfix"></div>
                </div>
            );
        }

        infobox = isShowInfoBox ? infobox : '';
        this.infobox = infobox;
        this.infoOpen = isShowInfoBox;
        this.props.showExternalInfoText(infobox);

    }

    hideInfoBox = () => {
        setTimeout(() => {
            if (this.mouseOut) {
                this.props.showExternalInfoText('');
            }
        }, 0);

    }

    renderBadges = (item, category, listId) => {
        let color = ThemeHelper.getThemedBrush(item.Color);
        const fontColor = ThemeHelper.getFontColor(color);
        item.FontColor = fontColor;
        const advancedDataSettings = this.props.viewsSettings.ExternalDataSettings[listId][this.props.majorPeriodicity].AdvancedData;

        return (
            this.props.eventSeriesData[listId] && map(this.props.eventSeriesData[listId], (value, key) => {
                if (category === value.category || value.count > 1) {
                    color = value.color > 1 ? value.color : color;
                    return (<g id={`${category}-${key}-${listId}`} key={`${category}-${key}`} onMouseEnter={() => this.showInfoBox(value.xAxis, value.yAxis, category, listId, advancedDataSettings, value.count, value.Date)} onMouseLeave={this.hideInfoBox}>
                        <rect id={`rect-${category}-${key}-${listId}`} x={value.xAxis - 21} y={value.yAxis} width="23" height="13" fill={color} stroke={color} />
                        <text id={`text-${category}-${key}-${listId}`} x={value.xAxis - 9.5} y={value.yAxis + 10} fill={fontColor} fontSize="9px" fontFamily="Arial" textAnchor="middle">{value.count > 1 ? value.count : item.Label.toUpperCase()}</text>
                        {/* <path id={`arrow-line-${category}-${key}-${listId}`} d={`M${value.xAxis + 2} ${value.yAxis} L${value.xAxis + 2} ${value.yAxis - 15} z`} fill={color} stroke={color} strokeWidth='1px' /> */}
                        <path id={`arrow-head-${category}-${key}-${listId}`} d={`M${value.xAxis - 3} ${value.yAxis} L${value.xAxis + 2} ${value.yAxis} L${value.xAxis + 2} ${value.yAxis - 9} z`} fill={color} stroke={color} />
                    </g>
                    )
                }
            })
        )
    }

    render() {
        const cls = this.props.externalInfoText ? "inner-plot" : "";
        const badgeOpened = this.props.externalInfoText ? " badge-open" : "";
        return (this.props.viewsSettings.ExternalDataSettings ?
            <div id="ExternalDataEventArea" className={`edu-in-price-chart${badgeOpened}`}>
                <svg id="ExternalDataEvents" width="100%" height="100%" fill="none" className={`${cls}`} ref={(ref) => (this.eduEventGraph = ref)}>
                    {map(this.props.viewsSettings.ExternalDataSettings, (listData, listId) => {
                        if (listData[this.props.majorPeriodicity] && listData[this.props.majorPeriodicity].IsVisibleInPriceMenu && listData[this.props.majorPeriodicity].isDateBasedEvents && !listData[this.props.majorPeriodicity].isDisabledForCurrentSymbol) {
                            const activeList = listData[this.props.majorPeriodicity].priceChart;

                            if (activeList && activeList.children && this.props.eventSeriesData) {
                                return (
                                    map(activeList.children, (item, category) =>
                                        item.IsVisible && this.renderBadges(item, category, listId)
                                    )
                                )
                            }
                        }
                    })
                    }
                </svg>

                <div ref={(ref) => (this.additionalsInfo = ref)}>
                    {
                        this.props.externalInfoText
                    }
                </div>

            </div >
            :
            null
        )
    }
}

const mapStateToProps = ({ externalDataUploader, DatagraphReducers }) => {
    const { majorPeriodicity, viewsSettings } = DatagraphReducers.DataGraphReducer;
    const { dimension } = DatagraphReducers.PriceChartReducer;
    const externalData = externalDataUploader.externalDataUploadSymbolReducer;
    const { expandedEventBadgeData, eventSeriesData, externalInfoText } = externalDataUploader.externalDataUploadSymbolReducer
    return ({ externalData, majorPeriodicity, expandedEventBadgeData, eventSeriesData, viewsSettings, externalInfoText, dimension})
}

const mapDispatchToProps = (dispatch) => ({
    showExternalInfoText: (infobox) => dispatch(showExternalInfoText(infobox))
})
export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataEvents);