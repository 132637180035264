import EventUtil from '../../../../../../Utils/EventUtil.js';
import LocalizationStore from 'LocalizationStore';
import React from "react";
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import { useSelector } from "react-redux";

const FundDetailsControl = () => {
    const positiveBrushText = useSelector((state) => state.appColor.posDTColor)
    const negativeBrushText = useSelector((state) => state.appColor.negDTColor);
    const data = useSelector((state) => state.summaryReducer.fundDetailItems.dataSource);

    const mouseOverShowTitle = (e, value) => {
        EventUtil.mouseOverShowTitle(e, value);
    };

    return (
        <div className="fundDetail-info-block">
            <div className="info-title">{SummaryTransLateHelper.FUND_DETAILS}</div>
            {data.map((item, index) => {
                let color = item.color ? item.color : null;

                const header = (item.headerId === 20) ? LocalizationStore.getTranslatedData("sb_etf_7", item.header) : LocalizationStore.getTranslatedData(`SumBlockName_${item.headerId}`, item.header);
                //apply custom color
                if (item && item.isUseCusColor) {
                    color = item.isPositive ? positiveBrushText
                        : negativeBrushText;
                }
                return (
                    <div className="info-row" key={index}>
                        <div className="data-cell swatch-color">{header}</div>
                        <div className="data-cell"><p onFocus={(e) => mouseOverShowTitle(e, item.value)} onMouseOver={(e) => mouseOverShowTitle(e, item.value)} style={{ color: color }}>{item.value}</p></div>
                    </div>
                )
            })}
        </div>
    );
}
export default FundDetailsControl