import Serializable from "Serializable";
import GraphType from "../../../../../Constants/GraphType.js";
class VolumeBlockSettings extends Serializable {

  constructor(periodicity, isFutures = false) {
    super();
    this.Height = 100;
    this.IsAvailable = true;
    this.IsVisible = true;
    this.IsLogScale = (periodicity === GraphType.Daily && isFutures) ? false : true;
  }

  onSeralisedInfoRecieved(info) {
    this.Height = info.Height ? info.Height : 100;
    this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
    this.IsLogScale = info.IsLogScale !== undefined ? info.IsLogScale : true;
    this.IsAvailable = true;
  }

  getDataObject() {
    return {
      Height: this.Height,
      IsVisible: this.IsVisible,
      IsLogScale: this.IsLogScale,
    };
  }

  getObjectMapKey() {
    return "VolumeBlockSettings";
  }
}

export default VolumeBlockSettings;
