import React, { Component } from 'react';
import CompareTabType, { CompareMenuType } from "CompareTabType";
import { setCompareMenuTab, initCompSymbolsMenu, initializeData } from 'Actions/ComparisonAndRotationMenuActions';
import { connect } from 'react-redux';
import SettingsStore from "SettingsStore";
import { PrintMode } from "../../../../../print/printmode";

let activeClassText = "btn btn-sm btn-default active"
let inactiveClassText = "btn btn-sm btn-default";
let buttonText1 = activeClassText;
let buttonText2 = inactiveClassText;
let comparisonMenuInit = false;
let rotationMenuInit = false;
let compSymbolsMenuInit = false;

class SymbolsGroupsToggler extends Component {
  constructor() {
    super();
    this.listName = '';
  }

  componentWillMount() {
    this.initMenu();
  }

  initMenu(menuName = null) {
    const { selectedTab, compGroupsMenuInit, rotationSymbolsMenuInit, rotationGroupsMenuInit } = this.props;
    const settings = SettingsStore.getConsoleSettings().NavCompareSettings;
    const isComparisonTabSelected = selectedTab === CompareTabType.ComparisonGraph;
    if ( isComparisonTabSelected && (comparisonMenuInit == false || menuName !== null)) {
      menuName = menuName == null ? settings.TabComparisonGraphSettings.comparisonMenuTab : menuName;
      this.props.setCompareMenuTab(menuName);
      comparisonMenuInit = true;
    } else if (!isComparisonTabSelected && (rotationMenuInit == false || menuName !== null)) {
      menuName = menuName == null ? settings.TabRotationGraphSettings.rotationMenuTab : menuName;
      this.props.setCompareMenuTab(menuName);
      rotationMenuInit = true;
    }

    if (selectedTab === CompareTabType.ComparisonGraph) {
      if (menuName == CompareMenuType.Symbols && compSymbolsMenuInit == false) {
        this.props.initCompSymbolsMenu();
        compSymbolsMenuInit = true;
      } else if (menuName == CompareMenuType.Groups && compGroupsMenuInit == false) {
        this.props.initializeData();
      }
    } else {
      if (menuName == CompareMenuType.Symbols && rotationSymbolsMenuInit == false) {
        this.props.initializeData();
      } else if (menuName == CompareMenuType.Groups && rotationGroupsMenuInit == false) {
        this.props.initializeData();
      }
    }
  }

  onclickButton(e) {
    let section = e.target.innerText;
    const { activeMenuTab } = this.props;

    if (activeMenuTab != section) {
      this.initMenu(section);
    }

  }

  render() {
    const { activeMenuTab } = this.props;
    if (activeMenuTab == CompareMenuType.Symbols) {
      buttonText1 = activeClassText;
      buttonText2 = inactiveClassText;
    } else {
      buttonText1 = inactiveClassText;
      buttonText2 = activeClassText;
    }
    return (
      <React.Fragment>
      { !PrintMode.printing && <div className="symbol-n-group">
        <span className="lead-tabs">
          <a><button onClick={() => this.onclickButton(event)} type="button" className={buttonText1}>Symbols</button></a>
          <a><button onClick={() => this.onclickButton(event)} type="button" className={buttonText2}>Groups</button></a>
        </span>
      </div> }
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCompareMenuTab: (menuTab) => dispatch(setCompareMenuTab(menuTab)),
  initCompSymbolsMenu: () => dispatch(initCompSymbolsMenu()),
  initializeData: () => dispatch(initializeData())
})

const mapStateToProps = (state) => {
  const { selectedTab, compGroupsMenuInit, rotationSymbolsMenuInit, rotationGroupsMenuInit } = state.navCompareReducer;
  const activeMenuTab = selectedTab === CompareTabType.ComparisonGraph ? state.comparisonAndRotationMenuPanel.MenuTabReducer.ComparisonMenuTab : state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;

  return { selectedTab, compGroupsMenuInit, rotationSymbolsMenuInit, rotationGroupsMenuInit, activeMenuTab };
}

export default connect(mapStateToProps, mapDispatchToProps)(SymbolsGroupsToggler);