import TimeLine from './TimeLine.js';

class MonthlyTimeline extends TimeLine {
    Padding(quarters, xDate, endDate, targetDate = null) {
        let curDate;
        let i;
        if (quarters === 0) {
            curDate = new Date(super.GetMDate(xDate));
            if (this.padding) {
                for (i = 0; i < 4; i++) {
                    curDate.setMonth(curDate.getMonth() + 1);
                    if (curDate.getDay() === 6)
                        curDate.setDate(curDate.getDate() - 1);
                    if (curDate.getDay() === 0)
                        curDate.setDate(curDate.getDate() - 2);
                }
            }
            curDate = super.GetMDate(curDate);
        }
        else {
            curDate = new Date(super.GetMDate(xDate));
            curDate = new Date(new Date(curDate).setMonth(curDate.getMonth() + (3 * quarters)));
            if (endDate > curDate)
                curDate = new Date(endDate);
            for (i = 0; i < 3; i++) {
                curDate.setMonth(curDate.getMonth() + 1);
                if (curDate.getDay() === 6)
                    curDate.setDate(curDate.getDate() - 1);
                if (curDate.getDay() === 0)
                    curDate.setDate(curDate.getDate() - 2);
            }
            curDate = super.GetMDate(curDate);
        }
        return targetDate !== null && curDate.getTime() < targetDate.getTime() ? targetDate : curDate;
    }
    SetMCVerticalGrid(startTime, endDate, mtDates, EarnReportDate, FCQtrEstCount) {

        var x = 0;
        var quarters = 8;
        var padDate = new Date(endDate);
        if (quarters > 0 && FCQtrEstCount > 0 && EarnReportDate.getFullYear() > 1900)
            padDate = new Date(EarnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 4 * 30);
        }
        var pDate = new Date(this.Padding(quarters, padDate, endDate));
        pDate.setDate(pDate.getDate() + 4 * 30);
        var cDate = new Date(pDate);

        while (cDate >= startTime) {
            var xDate = new Date(cDate);

            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };
            mtDates[x] = info;
            var cMonth = cDate.getMonth();
            var year = cDate.getFullYear();
            if (pDate.getFullYear() != cDate.getFullYear()) {
                if (cDate.getFullYear() % 5 == 0) {
                    if (this.FirstLabelIndex === -1) {
                        this.FirstLabelIndex = x
                    }
                    mtDates[x].XLabel = cMonth + 1 == 12 ? cDate.getFullYear() : "";
                    mtDates[x].IsCalendarLabel = true;
                    mtDates[x].IsFiscalLabel1 = true;
                    mtDates[x].IsFiscalLabel2 = true;
                    mtDates[x].XLine = 1;
                    mtDates[x].IsCalendarLine = true;
                }
                pDate = new Date(xDate);
            }
            x++;
            cDate = new Date(year, cMonth, 1);
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() == 6)
                cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() == 0)
                cDate.setDate(cDate.getDate() - 2);
        }
    }
    SetVerticalGrid(startTime, endDate, timeLineData, EarnReportDate, quarters = 8, funds = false, videoMode = false) {
        let mtDates = timeLineData.dates;
        var x = 0;
        var padDate = new Date(endDate);
        if (quarters > 0 && EarnReportDate.getFullYear() > 1900)
            padDate = new Date(EarnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 4 * 30);
        }
        var pDate = new Date(this.Padding(quarters, padDate, endDate));
        pDate.setDate(pDate.getDate() + 4 * 30);
        var cDate = new Date(pDate);

        while (cDate >= startTime) {
            var xDate = new Date(cDate);
            
            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };
            mtDates[x] = info;
            const uDate = videoMode === true ? pDate : cDate;
            var cMonth = cDate.getMonth();
            var year = cDate.getFullYear();
            if (pDate.getFullYear() != cDate.getFullYear()) {
                mtDates[x].XLabel = uDate.getMonth() + 1 === 12 ? uDate.getFullYear() : "";
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].IsFiscalLabel1 = true;
                mtDates[x].IsFiscalLabel2 = true;
                mtDates[x].XLine = 1;
                mtDates[x].IsCalendarLine = true;
                pDate = new Date(xDate);
                if (timeLineData.FirstLabelIndex === -1 && mtDates[x].XLabel !== "") {
                    timeLineData.FirstLabelIndex = x
                }
                if (mtDates[x].XLabel !== "") {
                    timeLineData.TimeLineLabel.push(mtDates[x])
                }
            }
            x++;
            //if(cDate.getFullYear() === endDate.getFullYear() && cDate.getMonth() === endDate.getMonth() && cDate.getDate() !== endDate.getDate()){
            //    cDate.setDate(endDate.getDate());
            //    continue;
            //}
            cDate = new Date(year, cMonth, 1);
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() == 6)
                cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() == 0)
                cDate.setDate(cDate.getDate() - 2);
        }
    }
}
const monthlyTimeline = new MonthlyTimeline();
export default monthlyTimeline;
