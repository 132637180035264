import { RayAnnotationType } from 'Constants/EAnnotationType';

export class MeasurementInfo {
    constructor() {
        this.AnnotationType = RayAnnotationType.MEASUREMENT_AnnotationType;
        this.ObjectBrushHex = "#FF0066CC";
        //this.OriginalBackgroundBrushHex = ObjectBrushHex;
        this.LineWidth = 2;
        this.IsLeaderLinesEnabled = true;
        this.ActualScale = 1.0;
    }
}