import React from 'react';

const BoldText = ({ text, nameFilter }) => {  
    const filter = new RegExp(nameFilter, 'ig');       
    const updatedText = text && text.replace(filter, function(nameFilter){
        return nameFilter.bold();
    });
    
    return (
        <span className="add-width" dangerouslySetInnerHTML={{ __html: updatedText }} />
    )
}

export default BoldText;