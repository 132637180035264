import timeLine from "./TimeLine.js";

class QuarterlyTimeline extends timeLine {
    Padding(quarters, xDate, endDate) {
        let curDate;
        let i;
        if (quarters === 0) {
            curDate = new Date(super.GetMDate(xDate));
            if (this.padding) {
                for (i = 0; i < 4; i++) {
                    curDate.setMonth(curDate.getMonth() + 1);
                    if (curDate.getDay() === 6)
                        curDate.setDate(curDate.getDate() - 1);
                    if (curDate.getDay() === 0)
                        curDate.setDate(curDate.getDate() - 2);
                }
            }
            curDate = super.GetMDate(curDate);
        }
        else {
            curDate = new Date(super.GetMDate(xDate));
            curDate = new Date(new Date(curDate).setMonth(curDate.getMonth() + (3 * quarters)));
            if (endDate > curDate)
                curDate = new Date(endDate);
            for (i = 0; i < 4; i++) {
                curDate.setMonth(curDate.getMonth() + 1);
                if (curDate.getDay() === 6)
                    curDate.setDate(curDate.getDate() - 1);
                if (curDate.getDay() === 0)
                    curDate.setDate(curDate.getDate() - 2);
            }
            curDate = super.GetMDate(curDate);
        }
        return curDate;
    }
    SetMCVerticalGrid(startTime, endDate, mtDates, earnReportDate, fcQtrEstCount) {
        var x = 0;
        var quarters = 8;
        var padDate = new Date(endDate);
        if (quarters > 0 && fcQtrEstCount > 0 && earnReportDate.getFullYear() > 1900)
            padDate = new Date(earnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 365);
        }
        var pDate = new Date(this.Padding(quarters, padDate, endDate));
        pDate.setDate(pDate.getDate() + 365);
        var cDate = new Date(pDate);

        while (cDate >= startTime) {
            var xDate = new Date(cDate);

            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };
            mtDates[x] = info;
            var cMonth = cDate.getMonth() + 1;
            var year = cDate.getFullYear();
            if (pDate.getFullYear() !== year) {
                if (year % 15 === 0) {
                    mtDates[x].XLabel = year;
                    mtDates[x].IsCalendarLabel = true;
                    mtDates[x].IsFiscalLabel1 = true;
                    mtDates[x].IsFiscalLabel2 = true;
                    mtDates[x].XLine = 3;
                    mtDates[x].IsCalendarLine = true;
                }
                pDate = xDate;
            }
            x++;
            var aRem = cMonth % 3;
            var dMonth = aRem !== 0 ? cMonth - aRem : cMonth - 3;
            var dDay = Math.min(28, cDate.getDate());

            cDate = new Date(year, dMonth - 1, dDay);

            var m = cDate.getMonth();
            var y = cDate.getFullYear();
            if (m === 11) {
                m = 0;
                y = y + 1;
            }
            else {
                m = m + 1;
            }
            cDate = new Date(y, m, 1);
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === 6)
                cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === 0)
                cDate.setDate(cDate.getDate() - 2);
        }
    }
    SetVerticalGrid(startTime, endDate, timeLineData, earnReportDate, quarters = 8) {
        let mtDates = timeLineData.dates;
        var x = 0;
        var padDate = new Date(endDate);
        if (quarters > 0 && earnReportDate.getFullYear() > 1900)
            padDate = new Date(earnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 365);
        }
        var pDate = new Date(this.Padding(quarters, padDate, endDate));
        pDate.setDate(pDate.getDate() + 365);
        var cDate = new Date(pDate);
        //let isAddEndDate = false;

        while (cDate >= startTime) {
            //if(isAddEndDate){
                //mtDates[x] = {
                //    Date: new Date(endDate),
                //    XAxis: x,
                //    XLine: 0,
                //    XLabel: "",
                //    IsFiscalLabel1: false,
                //    IsFiscalLabel2: false,
                //    IsCalendarLabel: false
                //};
                //x++;
            //}
            var xDate = new Date(cDate);

            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };
            mtDates[x] = info;
            var cMonth = cDate.getMonth() + 1;
            var year = cDate.getFullYear();

            if (pDate.getFullYear() !== year && year % 10 === 0 && cMonth === 3) {
                mtDates[x].XLabel = cMonth === 3 ? year : "";
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].IsFiscalLabel1 = true;
                mtDates[x].IsFiscalLabel2 = true;
                mtDates[x].XLine = 2;
                mtDates[x].IsCalendarLine = true;
                pDate = xDate;
                if (timeLineData.FirstLabelIndex === -1 && mtDates[x].XLabel !== "") {
                    timeLineData.FirstLabelIndex = x;
                }
                if (mtDates[x].XLabel !== "") {
                    timeLineData.TimeLineLabel.push(mtDates[x]);
                }
            }
            if (pDate.getFullYear() !== year && cMonth === 3) {
                mtDates[x].XLabel = "";
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].IsFiscalLabel1 = true;
                mtDates[x].IsFiscalLabel2 = true;
                mtDates[x].XLine = 1;
                mtDates[x].IsCalendarLine = true;
                pDate = xDate;
            }
            x++;
            var aRem = cMonth % 3;
            var dMonth = aRem !== 0 ? cMonth - aRem : cMonth - 3;
            var dDay = Math.min(28, cDate.getDate());

            cDate = new Date(year, dMonth - 1, dDay);

            var m = cDate.getMonth();
            var y = cDate.getFullYear();
            if (m === 11) {
                m = 0;
                y = y + 1;
            }
            else {
                m = m + 1;
            }
            cDate = new Date(y, m, 1);
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === 6)
                cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === 0)
                cDate.setDate(cDate.getDate() - 2);
            
            //isAddEndDate = endDate > cDate && endDate < xDate;
        }
    }
}
const quarterlyTimeline = new QuarterlyTimeline();
export default quarterlyTimeline;
