import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import UserInfoUtil from "UserInfoUtil";
import ContactStore from "Stores/ContactWindow/ContactStore"
import ConsoleStore from "Stores/ConsoleWindow/ConsoleStore";
import ConsoleActions from "Actions/ConsoleActions";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";

export default class LiveSupportDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            isOpen: false,
        }
        this.ConsoleStateChange = this.ConsoleStateChange.bind(this);
        this.closeLiveSupportDialog = this.closeLiveSupportDialog.bind(this);
    }

    UNSAFE_componentWillMount() {
        ConsoleStore.addChangeListener(this.ConsoleStateChange); 
    }

    componentWillUnmount() {
        ConsoleStore.removeChangeListener(this.ConsoleStateChange);
    }

    ConsoleStateChange() {
        let currentAction = ConsoleStore.getCurrentAction();
        if (currentAction == ConsoleConstants.ActionTypes.TOGGLE_LIVE_SUPPORT_DIALOG) {
            let openStatus = ConsoleStore.getLiveSupportDialogOpen();
            let screenConnectUrl = "";
            if(Boolean(openStatus) && !Boolean(screenConnectUrl))
            {
                let user = UserInfoUtil.getUserInfo();
                let contactState = ContactStore.getState();
                let serverURL = contactState.screenConnectUrl;
                let code = contactState.code;
                if(Boolean(user) && Boolean(user.userEmail))
                {
                    screenConnectUrl = serverURL + "?email=" + user.userEmail + "&code=" + code + "&userCode=" + user.userEmail.split("@")[0];
                }  
            }
             
            this.setState({
                isOpen: openStatus,
                url: screenConnectUrl
            });
            
            ConsoleStore.clearCurrentAction();
        }
    }

    closeLiveSupportDialog() {
        this.setState({ isOpen: false });
        ConsoleActions.toggleSupportDialog(false);
        ConsoleActions.toggleLiveSupportDialog(false);
    }

    render(){
        return (
            Boolean(this.state.isOpen) ?
            <div id="liveSupportDialog">
                <Modal className="modal-popup live-support" show={this.state.isOpen} onHide={this.closeLiveSupportDialog}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="cap-header ">Live Support</span>
                            <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.closeLiveSupportDialog}><span className="icn-close"></span></button>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe className="connection" ref={(ref)=> this.frame = ref} src={this.state.url}/>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </div>
            :
            null
        )
    }
}