import { LiquidityFilter } from '../../../Models/NavCompare/ComparisonAndRotationMenuModels/MenuStates';
import { ComparisonAndRotationMenuConstants } from "RotationGraphConstants";
import {selectedMenuTab} from '../MenuPanelReducers/MenuTabReducer'
import { extend } from "underscore";
import CompareTabType,{CompareMenuType} from "CompareTabType";
import CompareStore from "CompareStore";
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import { SettingsConstants } from "Constants/SettingsConstants.js";
import {cloneDeep} from "lodash";
extend(ActionTypes, SettingsConstants.ActionTypes);

let initialState ={
    comparisonMenuSelectedTab : CompareMenuType.Symbols,
    rotationMenuSelectedTab : CompareMenuType.Symbols,
    liqFilterValues:null
} 
extend(initialState, { ComparisonMenuStates:{Symbols : new LiquidityFilter(), Groups : new LiquidityFilter }, 
                        RotationMenuStates: {Symbols : new LiquidityFilter(), Groups : new LiquidityFilter }});


const LiqAndRatingFilterReducer = (state = initialState, action) => {
    let data;
    let id;
    const setData = (property, actionValue) => {

        if (state.selectedTab == CompareTabType.ComparisonGraph) {
            if (state.comparisonMenuSelectedTab == CompareMenuType.Symbols) {
                state.ComparisonMenuStates.Symbols[property] = actionValue;
                return state;
            } else {
                state.ComparisonMenuStates.Groups[property] = actionValue;
                return state;
            }
        }
        else {
            if (state.rotationMenuSelectedTab == CompareMenuType.Symbols) {
                state.RotationMenuStates.Symbols[property] = actionValue;
                return state;

            } else {
                state.RotationMenuStates.Groups[property] = actionValue;
                return state;
            }
        }
    }

    const setInitData = (actionValue) =>{
        let localState = cloneDeep(state);
        if(state.selectedTab == CompareTabType.ComparisonGraph)
        { 
             
            if(state.comparisonMenuSelectedTab == CompareMenuType.Symbols){
                extend(localState.ComparisonMenuStates.Symbols,actionValue);
                return localState;
            }else{
                extend(localState.ComparisonMenuStates.Groups,actionValue);
                return localState;
            }
             
        }
        else{ 
            if(state.rotationMenuSelectedTab == CompareMenuType.Symbols){
                extend(localState.RotationMenuStates.Symbols,actionValue);
                return localState;
            }else{
                extend(localState.RotationMenuStates.Groups,actionValue);
                return localState;
        }
    }
    }

    const setArrData = (property, actionValue) => {
        let localState = cloneDeep(state);
        if (state.selectedTab == CompareTabType.ComparisonGraph) {
            if (state.comparisonMenuSelectedTab == CompareMenuType.Symbols) {
                extend(localState.ComparisonMenuStates.Symbols[property], actionValue);
                return localState;
            } else {
                extend(localState.ComparisonMenuStates.Groups[property], actionValue);
                return localState;
            }

        }
        else {
            if (state.rotationMenuSelectedTab == CompareMenuType.Symbols) {
                extend(localState.RotationMenuStates.Symbols[property], actionValue);
                return localState;
            } else {
                extend(localState.RotationMenuStates.Groups[property], actionValue);
                return localState;
            }

        }
    }

    switch (action.type) {

        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
            return {
                ...state,
                selectedTab: action.selectedTab
            }

        case ActionTypes.SET_COMPARE_MENUTAB:
            if (state.selectedTab === CompareTabType.ComparisonGraph) {
                return {
                    ...state,
                    comparisonMenuSelectedTab: action.menuTab
                }
            } else {
                return {
                    ...state,
                    rotationMenuSelectedTab: action.menuTab
                }
            }

        case ActionTypes.INIT_LIQ_FILTER_VALUES:
            return{
                ...state,
                liqFilterValues:action.liqFilterValues
            }

        case ActionTypes.UPDATE_RATING_FILTER__TITLE:
            data=setData(action.id,action.ratingFilterTypeOptions)
            return{
                ...state,
                ...data
            }


        case ActionTypes.SET_SELECTED_RATINGS_OPTION:
            data = setData("selectedOptions",action.selectedOptions);
            return {
                ...state,
                ...data
            }

        case ActionTypes.TOGGLE_LIQ_BUTTON:
            data = setData("isFilterOn",action.isFilterOn);
         
            return {
                ...state,
                ...data
            }
            
        case ActionTypes.INIT_LIQUIDITY_FILTER_DATA:
            data = setInitData(action.initData);
            return {
                ...state,
                ...data
            }

        case ActionTypes.UPDATE_DATA_FILTER_RANGE:
            id = action.id;
            data = setArrData(id, action.data);
            return {
                ...state,
                ...data
            }

        case ActionTypes.UPDATE_SELECTED_RATING_FILTERTYPE:
            id = action.id;
            data = setArrData(id, action.ratingFilterTypeOptions);
            return {
                ...state,
                ...data
            }

        case ActionTypes.SET_FILTER_ON:
            data = setData("isFilterOn",action.isFilterOn);
            return {
                ...state,
                ...data
            }

        case ActionTypes.SET_FILTER_MANUAL_OFF:
            data = setData("isFilterManualOff", action.isFilterManualOff);
            return {
                ...state,
                ...data
            }

        case ActionTypes.UPDATE_ACTIVESLIDER:
            data = setData("activeSlider", action.data);
            return {
                ...state,
                ...data
            }

        case ActionTypes.LIQ_SHOW_SETTING_WINDOW:
            data = setData("showSettingsWindow", action.showSettingsWindow);
            return {
                ...state,
                ...data
            }

        case ActionTypes.TOGGLE_LIQ_FILTER:
            data = setData("openLiquidityRatings", action.isOpen);
            return {
                ...state,
                ...data
            }

        case ActionTypes.UPDATE_RATING_FILTER:
            data = setArrData(action.id,action.ratingFilterTypeOptions);
            return {
                ...state,
                ...data
            }

        case ActionTypes.SET_IS_GLOBAL_RATING_SELECTED:
            data = setData("isGlobalRatingSelected", action.value)
            return {
                ...state,
                ...data
            }

        case ActionTypes.SET_IS_COUNTRY_RATING_SELECTED:
            data = setData("isCountryRatingSelected", action.value)
            return {
                ...state,
                ...data
            }
        default:
            return state
    }
}

export default LiqAndRatingFilterReducer;



