const { ActionTypes } = OwnerShipBlockConstants;
import ArrayUtil from "ArrayUtil";
import BaseServiceApi from 'BaseServiceApi';
import BlockSize from "../../../Constants/BlockSize.js";
import ChartApi from "../../../ServiceApi/Apis/ChartApi.js";
import ConsoleStore from "ConsoleStore";
import DatagraphDataType from "../../../Constants/DatagraphDataType.js";
import DateHelper from "DateHelper";
import GraphComponent from "GraphComponent";
import GraphType from "GraphType";
import ListExplorerStore from "../../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import ListManagerTabType from "../../../Constants/ListManagerTabType.js";
import LocalizationStore from "LocalizationStore";
import { OwnerShipBlockConstants } from "../../../Constants/OwnerShipBlock";
import OwnershipBlockMenuType from "../../../Constants/OwnershipBlockMenuType.js";
import OwnershipClientType from "../../../Constants/OwnershipClientType.js";
import OwnershipView from "../../../Constants/OwnershipView.js";
import PanarayDefault from "../../../ServiceApi/RequestHelper/PanarayDefault.js";
import PeriodicityHelper from "../../../Utils/PeriodicityHelper.js";
import SettingsStore from "SettingsStore";
import SymbolType from 'SymbolType';
import ThemeHelper from "ThemeHelper";
import { TimelineConstant } from "../../../Constants/TabDataGraphConstants.js";
import TimeTrackingWindow from "TimeTrackingWindow";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { FinancialTransLateHelper, OwnershipBlockTranslateHelper } from "../../../Utils/TranslateHelper";
import { getOwnerShipState, getDatagraphStates, timeLineReducerSelect } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";

const ownerShipViewObj = {
    [OwnershipView.HoldingRank]: OwnershipView.SharesHeld, [OwnershipView.SharesHeld]: OwnershipView.SharesChange,
    [OwnershipView.SharesChange]: OwnershipView.HoldingRank
};
const ownerShipViewTypeObj = {
    [OwnershipView.HoldingRank]: OwnershipView.HoldingRank, [OwnershipView.SharesHeld]: OwnershipView.SharesHeld,
    [OwnershipView.SharesChange]: OwnershipView.SharesChange
};

function getHeaderIds(userSettings, periodicity) {
    let ids = "";
    const financialBlockSetting = userSettings.NavDatagraphSettings.TabDataGraphSettings.StockViewSettings.FinancialBlockSettings[periodicity];
    const CurrentHeaders = ArrayUtil.isArray(financialBlockSetting.CurrentHeaders) && financialBlockSetting.CurrentHeaders.filter((header) => !header).length === 0 ? financialBlockSetting.CurrentHeaders : [];

    if (CurrentHeaders && CurrentHeaders.length > 0) {
        for (let index = 0; index < CurrentHeaders.length; index++) {
            const header = CurrentHeaders[index];
            if (!header) {
                continue;
            }
            if (header.canToggle) {
                for (const singleHeader of header.Headers) {
                    ids += (`${singleHeader.HeaderId},`);
                }
            } else {
                ids += (`${header.HeaderId},`);
            }
        }
        ids = ids.substring(0, ids.lastIndexOf(","));
    }
    return ids;
}

function* getOwnerShipApiData({ userSettings, symbol, beginDate, endDate, graphType, periodicity }) {
    const ownershipBlockSettings = yield call(getAllOwnershipBlockSettings);
    const headerIds = yield call(getHeaderIds, userSettings, periodicity);
    const flaggedListIds = yield call(getAllOwnershipBlockSettings, true);

    const getOwnBlockReq = {
        wonOnly: userSettings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly,
        revWonOnly: userSettings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly,
        startDate: beginDate,
        endDate: endDate,
        symbol: symbol,
        headers: headerIds,
        ownerListId: ownershipBlockSettings,
        periodicity: graphType,
        getFinancial: 0,
        getOwnership: 1,
        ProductVersion: 43,
        isFlagged: flaggedListIds
    }
    TimeTrackingWindow.beginFinancialOwnerShipBlockTimeTracker();
    if (periodicity === GraphType.Weekly || periodicity === GraphType.Daily) {
        try {
            const response = yield call(ChartApi.GetFinancialBlockData, getOwnBlockReq)
            TimeTrackingWindow.endFinancialOwnerShipBlockApiTimeTracker();

            if (response.responseHeader.errorCode === 404 || response.responseHeader.errorLongDesc === "Invalid Symbol") {
                // If invalid symbol is searched.
                yield put({
                    type: ActionTypes.OWNERSHIP_BLOCK_NOT_AVAILABLE
                })
            } else if (response.ownershipBlockData) {
                // If proper response is present.
                yield put({
                    type: ActionTypes.OWNERSHIP_BLOCK_RESPONSE_READY,
                    ownershipBlockData: response.ownershipBlockData
                })
                try {
                    
                    yield call(onOwnershipBlockDataReceived);
                    
                } catch (e) {
                    console.error(e);
                    TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.FinancialOwnership);
                    TimeTrackingWindow.endFinancialOwnerShipBlockLoadEndTimeTracker();
                }
            } else {
                // Symbol is valid but no response. ex. FCNTX
                yield put({
                    type: ActionTypes.OWNERSHIP_BLOCK_NOT_AVAILABLE,
                    payload: true
                })
            }
        } catch (error) {
            console.error(error);
            TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.FinancialOwnership);
            TimeTrackingWindow.endFinancialOwnerShipBlockApiTimeTracker();
        }
    }
}

function getAllOwnershipBlockSettings(flags = false) {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const periodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
    let ownershipListIds = '', ownershipflags = '';
    const stockOwnership = tabDataGraphSettings.StockViewSettings.OwnershipBlockSettings[periodicity];
    const etfOwnership = tabDataGraphSettings.ETFViewSettings.OwnershipBlockSettings[periodicity];
    const cefOwnership = tabDataGraphSettings.CEFViewSettings.OwnershipBlockSettings[periodicity];
    if (flags === true) {
        const stockOwnershipFlagged = stockOwnership.IsFlaggedList ? 1 : 0;
        const etfOwnershipFlagged = etfOwnership.IsFlaggedList ? 1 : 0;
        const cefOwnershipFlagged = cefOwnership.IsFlaggedList ? 1 : 0;
        ownershipflags = `stock=${stockOwnershipFlagged};etf=${etfOwnershipFlagged};cef=${cefOwnershipFlagged}`;
        return ownershipflags;
    } else {
        let stockOwnershipListId = stockOwnership.IsFavList ? stockOwnership.ListContentId : stockOwnership.ListId;
        let etfOwnershipListId = etfOwnership.IsFavList ? etfOwnership.ListContentId : etfOwnership.ListId;
        let cefOwnershipListId = cefOwnership.IsFavList ? cefOwnership.ListContentId : cefOwnership.ListId;
        stockOwnershipListId = stockOwnershipListId ? stockOwnershipListId : PanarayDefault.DefaultOwnershipListId;
        etfOwnershipListId = etfOwnershipListId ? etfOwnershipListId : PanarayDefault.DefaultOwnershipListId;
        cefOwnershipListId = cefOwnershipListId ? cefOwnershipListId : PanarayDefault.DefaultOwnershipListId;
        ownershipListIds = `stock=${stockOwnershipListId};etf=${etfOwnershipListId};cef=${cefOwnershipListId}`;
        return ownershipListIds;
    }
}
function* onOwnershipBlockDataReceived() {
    try {
        let showDataNotAvailable = false, dataAvailable;
        const { isHistoric, isPricePanelDataReady, SymbolInfo, viewsSettings, majorPeriodicity, rightScaleWidth, nodeWidth } = yield select(getDatagraphStates)
        const ownershipBlockSetting = viewsSettings.OwnershipBlockSettings ? viewsSettings.OwnershipBlockSettings[majorPeriodicity] : null;
        const { ownershipBlockData } = yield select(getOwnerShipState);

        if (isPricePanelDataReady) {
            const ownershipRawData = ownershipBlockData ? ownershipBlockData : [];

            if (!ownershipBlockSetting || ownershipBlockSetting && !ownershipBlockSetting.IsAvailable) {
                yield put({
                    type: ActionTypes.OWNERSHIP_BLOCK_NOT_AVAILABLE,
                    payload: ownershipBlockSetting
                })
                return;
            }
            else if (!ownershipBlockData || ownershipBlockData.SpnDatas.length === 0 || isHistoric) {
                yield fork(prepareHeaderMenu, ownershipBlockSetting);
                dataAvailable = false;
                showDataNotAvailable = true;
                yield put({
                    type: ActionTypes.OWNERSHIP_BLOCK_CLEAR_DATA,
                    ownershipBlockSetting
                })
            } else {
                TimeTrackingWindow.setIsOwnershipBlcokAvailable(ownershipBlockSetting.IsVisible, SymbolInfo);
                TimeTrackingWindow.beginFinancialOwnerShipBlockRenderTimeTracker();
                yield fork(prepareHeaderMenu, ownershipBlockSetting);
                const RowHeight = 20;
                yield call(processRawData, ownershipBlockData, majorPeriodicity, ownershipBlockSetting);
                dataAvailable = true;
                showDataNotAvailable = false;
                const newState = {
                    RowHeight, ownershipRawData,
                    dataAvailable, nodeWidth, rightScaleWidth, showDataNotAvailable, SymbolInfo, ownershipBlockSetting
                }
                yield put({
                    type: ActionTypes.OWNERSHIPBLOCK_SET_DATA,
                    newState
                })
                TimeTrackingWindow.endFinancialOwnerShipBlockLoadEndTimeTracker();
            }
        }

    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, onOwnershipBlockDataReceived ${error}`, error);
    }

}
function* processRawData(ownershipBlockData, majorPeriodicity, ownershipBlockSetting) {
    try {
        const HeaderList = [], HeaderList2 = [], AUMData = [], AUMData2 = [], SponsorRanks = [], SponsorRanks2 = [], SponsorRanks3 = [];
        const { CalenderTimeLineLabels } = yield select(timeLineReducerSelect);
        const LARGE_PAGE_SIZE = 14;
        ownershipBlockData.SpnDatas && ownershipBlockData.SpnDatas.forEach((item, index) => {
            if (item.NoOfStocksHeld === null || item.TurnOverValue === null || item.AUM === null) {
                ownershipBlockData.SpnDatas.splice(index, 1);
            }
        });
        if (!(ownershipBlockData.SpnDatas === undefined || ownershipBlockData.SpnDatas === null || ownershipBlockData.SpnDatas.length === 0)) {
            for (let i = 0; i < ownershipBlockData.SpnDatas.length; i++) {
                const headerData = {};
                const aumData = {};
                const ShareInfoList = [];
                headerData.SponsorName = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorName);
                headerData.IsClient = ownershipBlockData.SpnDatas[i].IsClient;
                headerData.ManagementTeam = getEmptyStrIf(ownershipBlockData.SpnDatas[i].ManagementTeam);
                headerData.PercentageChgYTD = ownershipBlockData.SpnDatas[i].PercentageChgYTD;
                HeaderList.push(headerData);

                aumData.AUM = ownershipBlockData.SpnDatas[i].AUM;
                aumData.TurnOverValue = ownershipBlockData.SpnDatas[i].TurnOverValue * 100;
                aumData.NoOfStocksHeld = ownershipBlockData.SpnDatas[i].NoOfStocksHeld.low;
                AUMData.push(aumData);
                SponsorRanks.push(ShareInfoList);
            }
            for (let i = ownershipBlockData.SpnDatas.length; i < LARGE_PAGE_SIZE; i++) {
                const headerData = {};
                const aumData = {};
                const ShareInfoList = [];
                headerData.SponsorName = "";
                headerData.ManagementTeam = "";
                headerData.PercentageChgYTD = "";
                HeaderList.push(headerData);

                aumData.AUM = "";
                aumData.TurnOverValue = "";
                aumData.NoOfStocksHeld = "";
                AUMData.push(aumData);
                SponsorRanks.push(ShareInfoList);
            }
        }

        const timeLineLabels2 = [];
        for (let ti = CalenderTimeLineLabels.length - 1; ti >= 0; ti--) {
            timeLineLabels2.push(CalenderTimeLineLabels[ti]);
        }
        // Fix data lower and upper limit in  SponsorRanks
        let SpnSIdx = 0;
        if (ownershipBlockData.SpnDatas[0] === undefined || ownershipBlockData.SpnDatas[0].SponsorRanks === undefined) {
            // || ownershipBlockData.SpnDatas[0].SponsorRanks.length==0
            return;
        }
        let tSkeyIdx = 0;
        for (let ti = 0; ti < timeLineLabels2.length; ti++) //Jun 19" to "Sep 11" 0 to 31 length=32
        {
            tSkeyIdx = ti;
            break;
        }

        //const tSKey=(timeLineLabels2[tSkeyIdx].Date.getMonth()+1)/3+""+timeLineLabels2[tSkeyIdx].Date.getFullYear();
        const tSKey = `${(timeLineLabels2[tSkeyIdx].Date.getMonth() + 1) / 3}${timeLineLabels2[tSkeyIdx].Date.getFullYear()}`;
        const tSmKey = `${timeLineLabels2[tSkeyIdx].Date.getMonth() + 1}${timeLineLabels2[tSkeyIdx].Date.getFullYear()}`;
        const tSDate = majorPeriodicity !== GraphType.Daily ? getDateFromKey(tSKey) : getDateFromKey(tSmKey);

        for (let sr = 0; sr <= ownershipBlockData.SpnDatas[0].SponsorRanks.length - 1; sr++) {
            const sqKey = majorPeriodicity !== GraphType.Daily ? ownershipBlockData.SpnDatas[0].SponsorRanks[sr].QuarterKey :
                ownershipBlockData.SpnDatas[0].SponsorRanks[sr].MonthKey;
            const srDate = getDateFromKey(sqKey);
            if (srDate.getTime() >= tSDate.getTime()) {
                SpnSIdx = sr;
                break;
            }
        }

        let SponsorRanksIncr = 0;
        try {
            for (let ti = 0; ti < timeLineLabels2.length; ti++) //Jun 19" to "Sep 11" 0 to 31 length=32
            {
                SponsorRanksIncr = SponsorRanksIncr + 1;
                //const tqKey=(timeLineLabels2[ti].Date.getMonth()+1)/3+""+timeLineLabels2[ti].Date.getFullYear();
                const tqKey = `${(timeLineLabels2[ti].Date.getMonth() + 1) / 3}${timeLineLabels2[ti].Date.getFullYear()}`;
                const tmKey = `${timeLineLabels2[ti].Date.getMonth() + 1}${timeLineLabels2[ti].Date.getFullYear()}`;
                const tDate = getDateFromKey(majorPeriodicity !== GraphType.Daily ? tqKey : tmKey);
                try {
                    for (let i = 0; i < ownershipBlockData.SpnDatas.length; i++) {
                        const shareInfo = {};
                        const spIdx = SpnSIdx + SponsorRanksIncr - 1;
                        const cellRowLength = ownershipBlockData.SpnDatas[i].SponsorRanks.length
                        if ((spIdx) <= cellRowLength - 1) {
                            const srDate = majorPeriodicity !== GraphType.Daily ? (getDateFromKey(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].QuarterKey)) :
                                (getDateFromKey(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].MonthKey));
                            if (tDate.getTime() <= srDate.getTime()) {

                                shareInfo.FormattedSharesChanged = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].FormattedSharesChanged);
                                shareInfo.TurnOverValue = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].FormattedSharesHeld);
                                shareInfo.NoOfStocksHeld = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].FormattedSponsorRank);
                                shareInfo.Quarter = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].Quarter);
                                shareInfo.QuarterKey = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].QuarterKey);
                                shareInfo.Month = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].Month);
                                shareInfo.MonthKey = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].MonthKey);
                                shareInfo.Year = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].Year);
                                shareInfo.PivotType = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].PivotType);
                                shareInfo.PivotTypeEnum = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].PivotTypeEnum);
                                shareInfo.CalendarEndDate = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].CalendarEndDate);
                                shareInfo.SponsorRank = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].SponsorRank);
                                shareInfo.CarryForward = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].CarryForward);
                                shareInfo.DisplayFlag = getEmptyStrIf(ownershipBlockData.SpnDatas[i].SponsorRanks[spIdx].DisplayFlag);
                                if (shareInfo.CarryForward.length === 0) {
                                    shareInfo.CarryForward = 0;
                                }
                                else {
                                    if (shareInfo.CarryForward === 1) {
                                        shareInfo.CarryForward = 1;
                                    }
                                    else {
                                        shareInfo.CarryForward = 0;
                                    }
                                }

                                shareInfo.XPoint = timeLineLabels2[ti].XAxis;
                                shareInfo.XLabel = timeLineLabels2[ti].XLabel;
                                shareInfo.IsCalendarLabel = timeLineLabels2[ti].IsCalendarLabel;
                                shareInfo.IsCalendarLine = timeLineLabels2[ti].IsCalendarLine;
                                shareInfo.IsFiscalLabel1 = timeLineLabels2[ti].IsFiscalLabel1;
                                shareInfo.IsFiscalLabel2 = timeLineLabels2[ti].IsFiscalLabel2;
                                shareInfo.XLine = timeLineLabels2[ti].XLine;
                                shareInfo.cellClassName = shareInfoClassNameObj[shareInfo.PivotTypeEnum];
                            }
                            else {
                                console.log('Problem occured taking ownershipBlockData.SpnDatas[i].SponsorRanks', tDate.getTime(), srDate.getTime());
                            }
                        } else {
                            shareInfo.FormattedSharesChanged = "";
                            shareInfo.TurnOverValue = "";
                            shareInfo.NoOfStocksHeld = "";
                            shareInfo.Quarter = (timeLineLabels2[ti].Date.getMonth() + 1) / 3;
                            shareInfo.QuarterKey = tqKey;
                            shareInfo.Month = timeLineLabels2[ti].Date.getMonth() + 1;
                            shareInfo.MonthKey = tmKey;
                            shareInfo.Year = timeLineLabels2[ti].Date.getFullYear();
                            shareInfo.PivotType = 0;
                            shareInfo.PivotTypeEnum = 0;
                            shareInfo.CalendarEndDate = "";
                            shareInfo.SponsorRank = "";
                            shareInfo.CarryForward = 0;
                            shareInfo.DisplayFlag = true;
                            shareInfo.XPoint = timeLineLabels2[ti].XAxis;
                            shareInfo.XLabel = timeLineLabels2[ti].XLabel;
                            shareInfo.IsCalendarLabel = timeLineLabels2[ti].IsCalendarLabel;
                            shareInfo.IsCalendarLine = timeLineLabels2[ti].IsCalendarLine;
                            shareInfo.IsFiscalLabel1 = timeLineLabels2[ti].IsFiscalLabel1;
                            shareInfo.IsFiscalLabel2 = timeLineLabels2[ti].IsFiscalLabel2;
                            shareInfo.XLine = timeLineLabels2[ti].XLine;
                            shareInfo.cellClassName = shareInfoClassNameObj[shareInfo.PivotTypeEnum];
                        }
                        SponsorRanks[i].push(shareInfo);
                    }
                } catch (error) {
                    console.log(error)
                }
                try {
                    for (let i = ownershipBlockData.SpnDatas.length; i < LARGE_PAGE_SIZE; i++) {
                        const shareInfo = {};
                        shareInfo.FormattedSharesChanged = "";
                        shareInfo.TurnOverValue = "";
                        shareInfo.NoOfStocksHeld = "";
                        shareInfo.Quarter = (timeLineLabels2[ti].Date.getMonth() + 1) / 3;
                        shareInfo.QuarterKey = tqKey;
                        shareInfo.Month = timeLineLabels2[ti].Date.getMonth() + 1;
                        shareInfo.MonthKey = tmKey;
                        shareInfo.Year = timeLineLabels2[ti].Date.getFullYear();
                        shareInfo.PivotType = 0;
                        shareInfo.PivotTypeEnum = 0;
                        shareInfo.CalendarEndDate = "";
                        shareInfo.SponsorRank = "";
                        shareInfo.CarryForward = 0;
                        shareInfo.DisplayFlag = true;
                        shareInfo.XPoint = timeLineLabels2[ti].XAxis;
                        shareInfo.XLabel = timeLineLabels2[ti].XLabel;
                        shareInfo.IsCalendarLabel = timeLineLabels2[ti].IsCalendarLabel;
                        shareInfo.IsCalendarLine = timeLineLabels2[ti].IsCalendarLine;
                        shareInfo.IsFiscalLabel1 = timeLineLabels2[ti].IsFiscalLabel1;
                        shareInfo.IsFiscalLabel2 = timeLineLabels2[ti].IsFiscalLabel2;
                        shareInfo.XLine = timeLineLabels2[ti].XLine;
                        shareInfo.cellClassName = shareInfoClassNameObj[shareInfo.PivotTypeEnum];
                        SponsorRanks[i].push(shareInfo);
                    }
                } catch (error) {
                    console.log(error)
                }

            }
        } catch (error) {
            console.log(error)
        }

        for (let j = 0; j < SponsorRanks.length; j++) {
            const ShareInfoList2 = [];
            for (let k = SponsorRanks[j].length - 1; k >= 0; k--) {
                ShareInfoList2.push(SponsorRanks[j][k]);
            }
            SponsorRanks2.push(ShareInfoList2);
        }
        yield call(setDataToBlockSize, ownershipBlockSetting, HeaderList, HeaderList2, AUMData, AUMData2, SponsorRanks2,
            SponsorRanks3);
    }
    catch (e) {
        console.error(`Error occures in OwnerShipBlock.js, processRawData ${e}`, e);
    }
}
function* prepareHeaderMenu(ownershipBlockSetting) {
    try {
        let listExplorerResult = false;
        const listExplorer = yield ListExplorerStore.getListExplorer();
        if (ownershipBlockSetting === null || ownershipBlockSetting === undefined || ownershipBlockSetting.type) {
            const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
            ownershipBlockSetting = viewsSettings.OwnershipBlockSettings ? viewsSettings.OwnershipBlockSettings[majorPeriodicity] : null;
        }
        const presetLists = [];
        const favLists = [];
        let favListSorted = [];
        if (ownershipBlockSetting !== null) {
            if (listExplorer && listExplorer.Ownership) {
                listExplorerResult = true;
                // eslint-disable-next-line array-callback-return
                listExplorer.Ownership.map((owner) => {
                    if (owner.listCategory === "Preset" && owner.childNodes && owner.childNodes.length > 0) {
                        addChildLists(owner.childNodes, presetLists, OwnershipBlockMenuType.PanarayOwnerLists, ownershipBlockSetting.ListId, ownershipBlockSetting.ListSourceId, ownershipBlockSetting.IsFavList);
                    };
                });
                // eslint-disable-next-line array-callback-return
                listExplorer.Ownership.map((owner) => {
                    if (owner.listCategory === "Favorites" && owner.childNodes && owner.childNodes.length > 0) {
                        addChildLists(owner.childNodes, favLists, OwnershipBlockMenuType.FavoriteOwnerLists, ownershipBlockSetting.ListContentId, ownershipBlockSetting.ListSourceId, ownershipBlockSetting.IsFavList);
                    };
                });
                favListSorted = favLists.sort((a, b) => (a.header.toString().toLowerCase() < b.header.toString().toLowerCase())
                    ? -1 : (a.header.toString().toLowerCase() > b.header.toString().toLowerCase()) ? 1 : 0);
                //Additional Menus
                const topOwnersActive = ownershipBlockSetting.IsFavList ? (ownershipBlockSetting.ListContentId === -1) : (ownershipBlockSetting.ListId === -1);
                const topOwners = {
                    header: OwnershipBlockTranslateHelper.TOP_OWNERS,
                    isActive: topOwnersActive,
                    isVisible: true,
                    Type: OwnershipBlockMenuType.PanarayOwnerLists,
                    Value: -1,
                    listId: -1,
                    parentId: -1,
                    sourceListId: -1,
                    canEdit: false,
                    ownerUserId: -1,
                };
                presetLists.push(topOwners);
            }

            const menu = [];

            //Rank Menus
            const hrItem = {
                header: OwnershipBlockTranslateHelper.HOLDINGS_RANK,
                Type: OwnershipBlockMenuType.DataType,
                hasDivider: false,
                Value: OwnershipView.HoldingRank,
                isActive: ownershipBlockSetting.OwnershipViewType === OwnershipView.HoldingRank,
                hasSubMenu: false,
            };
            menu.push(hrItem);
            const sharesItem = {
                header: OwnershipBlockTranslateHelper.SHARES_HELD,
                Type: OwnershipBlockMenuType.DataType,
                hasDivider: false,
                Value: OwnershipView.SharesHeld,
                isActive: ownershipBlockSetting.OwnershipViewType === OwnershipView.SharesHeld,
                hasSubMenu: false,
            };
            menu.push(sharesItem);
            const csharesItem = {
                header: OwnershipBlockTranslateHelper.CHANGE_IN_SHARES,
                Type: OwnershipBlockMenuType.DataType,
                hasDivider: true,
                Value: OwnershipView.SharesChange,
                isActive: ownershipBlockSetting.OwnershipViewType === OwnershipView.SharesChange,
                hasSubMenu: false,
            };
            menu.push(csharesItem);

            //Size Menu Items
            const sSizeItem = {
                header: FinancialTransLateHelper.SMALL_BLOCK,
                isActive: ownershipBlockSetting.BlockSize === BlockSize.Small,
                Type: "BlockSize",
                Value: BlockSize.Small,
                hasSubMenu: false,
            };
            menu.push(sSizeItem);
            const mSizeItem = {
                header: FinancialTransLateHelper.MEDIUM_BLOCK,
                isActive: ownershipBlockSetting.BlockSize === BlockSize.Medium,
                Type: "BlockSize",
                Value: BlockSize.Medium,
                hasSubMenu: false,
            };
            menu.push(mSizeItem);
            const lSizeItem = {
                header: FinancialTransLateHelper.LARGE_BLOCK,
                hasDivider: true,
                Type: "BlockSize",
                Value: BlockSize.Large,
                isActive: ownershipBlockSetting.BlockSize === BlockSize.Large,
                hasSubMenu: false,
            };
            menu.push(lSizeItem);

            //Panaray Owner Lists
            const panarayOwnerLists = {
                header: OwnershipBlockTranslateHelper.PANARAY_OWNER_LIST,
                isActive: false,
                Type: OwnershipBlockMenuType.PanarayOwnerLists,
                Value: OwnershipBlockMenuType.PanarayOwnerLists,
                hasSubMenu: true,
                items: presetLists.length > 0 ? presetLists : ""
            };
            menu.push(panarayOwnerLists);

            const favoriteLists = {
                header: OwnershipBlockTranslateHelper.FAV_OWNERS_LIST,
                isActive: false,
                Type: OwnershipBlockMenuType.FavoriteOwnerLists,
                hasDivider: false,
                Value: OwnershipBlockMenuType.FavoriteOwnerLists,
                hasSubMenu: true,
                items: favListSorted.length > 0 ? favListSorted : ""
            };
            menu.push(favoriteLists);

            const createList = {
                header: OwnershipBlockTranslateHelper.CREATE_OWNERS_LIST,
                isActive: false,
                Type: OwnershipBlockMenuType.CreateOwnerLists,
                hasDivider: true,
                Value: OwnershipBlockMenuType.CreateOwnerLists,
                hasSubMenu: false,
            };
            menu.push(createList);
            const osItem = {
                header: OwnershipBlockTranslateHelper.HIDE_OWNERSHIP_BLOCK,
                Type: OwnershipBlockMenuType.Hide,
                Value: OwnershipBlockMenuType.Hide,
                isActive: false,
                hasSubMenu: false,
            };
            menu.push(osItem);

            if (listExplorerResult) {
                yield put({
                    type: ActionTypes.OWNERSHIP_BLOCK_PREPARE_UPDATE_MENU,
                    menu
                })
            }
        }
    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, prepareHeaderMenu ${error}`, error);
    }
}

function getEmptyStrIf(stringInfo) {
    if (!(stringInfo === undefined || stringInfo === null || stringInfo.length === 0)) {
        return stringInfo;
    }
    else {
        return "";
    }
}
function getDateFromKey(key) {
    if (key !== null && key !== undefined && key.length > 4) {
        const tyear = key.substring(key.length - 4, key.length);
        const tmonth = key.substring(0, key.length - 4);
        const tSDate = new Date(parseInt(tyear), parseInt(tmonth) - 1, 1);
        return tSDate;
    }
    else {
        return 0;
    }
}
const shareInfoClassNameObj = {
    [0]: "textDefault xx-small-normal x1", 
    [1]: "default xx-small-normal x2",
    [2]:  "positive xx-small-normal x3",
    [3]: "negative xx-small-normal x4"
};
function* setDataToBlockSize(ownershipBlockSetting, HeaderList, HeaderList2, AUMData, AUMData2, SponsorRanks2,
    SponsorRanks3) {
    try {
        const RowHeight = 20;
        const BlockSize = ownershipBlockSetting.BlockSize;
        const PageSize = ownershipBlockSetting.getPageSize(BlockSize);
        const Height = (RowHeight * PageSize) + 2;

        if (HeaderList && HeaderList.length > 0) {
            HeaderList2 = HeaderList.slice(0, PageSize);
        }
        if (SponsorRanks2 && SponsorRanks2.length > 0) {
            SponsorRanks3 = SponsorRanks2.slice(0, PageSize);
        }
        if (AUMData && AUMData.length > 0) {
            AUMData2 = AUMData.slice(0, PageSize);
        }
        yield put({
            type: ActionTypes.OWNERSHIP_BLOCK_UPDATE_LIST_DATA,
            HeaderList, HeaderList2, SponsorRanks2, SponsorRanks3, AUMData, AUMData2, Height, BlockSize
        })

    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, setDataToBlockSize ${e}`, e);
    }
}
function addChildLists(presetOwnershipList, listCollection, listType, currentListId, sourceListId, isCurrentFavoriteList) {
    const ListType = BaseServiceApi.rayData["ListType"];
    if (presetOwnershipList && presetOwnershipList.length > 0) {
        // eslint-disable-next-line array-callback-return
        presetOwnershipList.map((item) => {
            if (item.listType === ListType.FOLDER_List && item.childNodes.length > 0 && item.isVisible) {
                addChildLists(item.childNodes, listCollection, listType, currentListId, sourceListId, isCurrentFavoriteList);
            }
            if (item.listType !== ListType.FOLDER_List) {
                const pListId = item.nodeId && item.nodeId !== null ? (item.nodeId.low && item.nodeId.low !== null ? item.nodeId.low : 0) : 0;
                const pParentId = item.parentNodeId && item.parentNodeId !== null ? (item.parentNodeId.low && item.parentNodeId.low !== null ? item.parentNodeId.low : 0) : 0;
                const pSourceListId = item.sourceListId && item.sourceListId !== null ? (item.sourceListId.low && item.sourceListId.low !== null ? item.sourceListId.low : 0) : 0;

                let pIsActive = false;
                if (listType === OwnershipBlockMenuType.PanarayOwnerLists) {
                    if (!isCurrentFavoriteList) {
                        pIsActive = (pListId === currentListId);
                    }
                }
                else {
                    if (isCurrentFavoriteList) {
                        pIsActive = (pSourceListId === currentListId);
                    }
                }
                const chinese = {
                    "Leading Growth Funds": "ob_id10",
                    "Leading International Funds": "ob_id11",
                    "Leading Value Funds": "ob_id8"
                }
                const sSizeItem = {
                    header: LocalizationStore.getTranslatedData(chinese[item.name], item.name),
                    isActive: pIsActive,
                    isVisible: item.isVisible,
                    Type: listType,
                    Value: item.listType,
                    listId: pListId,
                    parentId: pParentId,
                    sourceListId: pSourceListId,
                    canEdit: item.listCategory === "Favorites",
                    action: item.listCategory === "Favorites" ? [{ text: "Edit", resKey: 'edit' }] : [],
                    graphComponent: item.listCategory === "Favorites" ? GraphComponent.OwnershipBlockMenuFavorites : "",
                    ownerUserId: item.ownerUserId && item.ownerUserId !== null ? (item.ownerUserId.low && item.ownerUserId.low !== null ? item.ownerUserId.low : 0) : 0,
                };
                listCollection.push(sSizeItem);
            }
        });
    }
}
function* toggleAumView() {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const ownershipBlockSetting = viewsSettings.OwnershipBlockSettings ? viewsSettings.OwnershipBlockSettings[majorPeriodicity] : null;
        const AumIsVisible = !ownershipBlockSetting.AumIsVisible;
        ownershipBlockSetting.AumIsVisible = !ownershipBlockSetting.AumIsVisible;
        yield call(prepareHeaderMenu, ownershipBlockSetting);
        yield put({
            type: ActionTypes.OWNERSHIP_BLOCK_SET_AUM_TOGGLE,
            newState: { AumIsVisible }
        })
    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, toggleAumView ${e}`, e);
    }
}

function* toggleFund({ side }) {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const ownershipBlockSetting = viewsSettings.OwnershipBlockSettings ? viewsSettings.OwnershipBlockSettings[majorPeriodicity] : null;
        let newState;
        if (side) {
            if (side === 1) {
                if (ownershipBlockSetting.LeftClient === OwnershipClientType.Owner) {
                    ownershipBlockSetting.LeftClient = OwnershipClientType.Manager;
                }
                else {
                    ownershipBlockSetting.LeftClient = OwnershipClientType.Owner;
                }
                const LeftClient = ownershipBlockSetting.LeftClient;
                newState = { LeftClient }
            } else {
                if (ownershipBlockSetting.RightClient === OwnershipClientType.Owner) {
                    ownershipBlockSetting.RightClient = OwnershipClientType.Manager;
                }
                else {
                    ownershipBlockSetting.RightClient = OwnershipClientType.Owner;
                }
                const RightClient = ownershipBlockSetting.RightClient;
                newState = { RightClient }
            }
        }
        yield put({
            type: ActionTypes.OWNERSHIP_BLOCK_SET_FUND_TOGGLE,
            newState
        })
    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, toggleFund ${e}`, e);
    }
}
function* toggleDataView({ item }) {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const ownershipBlockSetting = viewsSettings.OwnershipBlockSettings ? viewsSettings.OwnershipBlockSettings[majorPeriodicity] : null;
        const state = yield select(getOwnerShipState);
        if (state.isDogEarClicked === false) {
            if (item === null || item === undefined) {
                ownershipBlockSetting.OwnershipViewType = ownerShipViewObj[ownershipBlockSetting.OwnershipViewType];
            }
            else {
                ownershipBlockSetting.OwnershipViewType = ownerShipViewTypeObj[item.Value]
            }
            SettingsStore.saveSettings();
            if (state) {
                state.OwnershipViewType = ownershipBlockSetting.OwnershipViewType;
            }
            ConsoleStore.trackUsage('OwnershipBlockViewChanged', '', ['Manual']);
            yield call(onOwnershipBlockDataReceived);
        }
    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, toggleDataView ${error}`, error);
    }
}
function* updateBlockSize({ size }) {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const ownershipBlockSetting = viewsSettings.OwnershipBlockSettings ? viewsSettings.OwnershipBlockSettings[majorPeriodicity] : null;
        ownershipBlockSetting.BlockSize = ownershipBlockSetting.getBlockSize(size);
        SettingsStore.saveSettings();
        const state = yield select(getOwnerShipState);
        yield call(setDataToBlockSize, ownershipBlockSetting, state.HeaderList, state.HeaderList2, state.AUMData, state.AUMData2,
            state.SponsorRanks2, state.SponsorRanks3);
        yield call(prepareHeaderMenu, ownershipBlockSetting);

    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, updateBlockSize ${error}`, error);
    }
}
function* getOwnershipBlockData(SymbolInfo, nodeCount, viewsSettings, majorPeriodicity, periodicityValue) {
    try {
        const ownershipBlockSetting = viewsSettings.OwnershipBlockSettings ? viewsSettings.OwnershipBlockSettings[majorPeriodicity] : null;
        const stockInfo = SymbolInfo;
        const periodicity = majorPeriodicity;
        const stockViewSettings = viewsSettings;
        const country = stockInfo.CountryCode;
        const maSettings = stockViewSettings.MASettings[majorPeriodicity];
        if (ownershipBlockSetting === null || ownershipBlockSetting === undefined || ownershipBlockSetting.IsAvailable === false || stockInfo.SymTypeEnum === SymbolType.INDEX) {
            return false;
        }
        let isHistoric = false;

        const RS1Linesettings = stockViewSettings.RelativeStrenghtLine1Settings[country];
        const RS2Linesettings = stockViewSettings.RelativeStrenghtLine2Settings[country];
        let maxBuffer = 0;

        maSettings.forEach((ma) => {
            if (ma.ma > maxBuffer) {
                maxBuffer = ma.ma;
            }
        });
        if (RS1Linesettings.maLineLength && RS1Linesettings.maLineLength > maxBuffer) {
            maxBuffer = RS1Linesettings.maLineLength;
        }
        if (RS2Linesettings.maLineLength > maxBuffer) {
            maxBuffer = RS2Linesettings.maLineLength;
        }
        const InitialBufferSize = maxBuffer ? maxBuffer : 50;
        const lastTradeDate = stockInfo.LastTradeDate;
        const endDate = lastTradeDate;
        const beginDate = DateHelper.calculateBeginDate(endDate, periodicity, (nodeCount + InitialBufferSize));

        const getEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        const getTradeDate = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate());

        if (stockInfo.SymTypeEnum === SymbolType.HISTORICSTOCK ||
            stockInfo.SymTypeEnum === SymbolType.HISTORICINDEX) {
            isHistoric = true;
        }
        else {
            isHistoric = getEndDate.getTime() < getTradeDate.getTime();
        }
        if (isHistoric) {
            const OwnershipData = undefined;
            yield call(onOwnershipBlockDataReceived, OwnershipData);
            return false;
        }
        try {
            const wonOnly = SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly;
            const revWonOnly = SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly;
            const ownershipBlockSettings = yield call(getAllOwnershipBlockSettings);
            const flaggedListIds = yield call(getAllOwnershipBlockSettings, true);
            const getBlockReq = {
                wonOnly,
                revWonOnly,
                startDate: DateHelper.yyyymmdd(beginDate),
                endDate: DateHelper.yyyymmdd(endDate),
                symbol: stockInfo.Symbol,
                headers: "",
                ownerListId: ownershipBlockSettings,
                periodicity: periodicityValue,
                getFinancial: 0,
                getOwnership: 1,
                ProductVersion: 43,
                isFlagged: flaggedListIds
            }
            const response = yield call(ChartApi.GetFinancialBlockData, getBlockReq);
            if (response) {
                yield put({
                    type: ActionTypes.OWNERSHIP_BLOCK_RESPONSE_READY,
                    ownershipBlockData: response.ownershipBlockData
                })
                try {
                    TimeTrackingWindow.beginFinancialOwnerShipBlockRenderTimeTracker();
                    yield call(onOwnershipBlockDataReceived);
                    TimeTrackingWindow.endFinancialOwnerShipBlockLoadEndTimeTracker();
                } catch (e) {
                    console.error(e);
                    TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.FinancialOwnership);
                    TimeTrackingWindow.endFinancialOwnerShipBlockLoadEndTimeTracker();
                }
            }
        } catch (error) {
            console.error(error);
            TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.FinancialOwnership);
            TimeTrackingWindow.endFinancialOwnerShipBlockApiTimeTracker();
        }
        return true;
    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, getOwnershipBlockData ${error}`, error);
    }
}
function* updateList({ item }) {
    try {
        const { periodicityValue, SymbolInfo, nodeCount, viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const ownershipBlockSetting = viewsSettings.OwnershipBlockSettings ? viewsSettings.OwnershipBlockSettings[majorPeriodicity] : null;
        if (item.Type === OwnershipBlockMenuType.PanarayOwnerLists) {
            ownershipBlockSetting.ListId = item.listId;
            ownershipBlockSetting.ListContentId = item.sourceListId;
            ownershipBlockSetting.IsFavList = false;
            ownershipBlockSetting.IsFlaggedList = false;
        }
        else if (item.Type === OwnershipBlockMenuType.FavoriteOwnerLists) {
            const selectedOwnershipBlockNode = ListExplorerStore.findNodeByNodeId(item.sourceListId, ListManagerTabType.Ownership);
            const isflaggedList = ListExplorerStore.isFlaggedOwnerList(selectedOwnershipBlockNode);
            ownershipBlockSetting.ListId = item.listId;
            ownershipBlockSetting.ListContentId = item.sourceListId;
            ownershipBlockSetting.ListSourceId = item.sourceListId;
            ownershipBlockSetting.IsFavList = true;
            ownershipBlockSetting.IsFlaggedList = isflaggedList;
        }
        yield call(getOwnershipBlockData, SymbolInfo, nodeCount, viewsSettings, majorPeriodicity, periodicityValue);
        yield call(prepareHeaderMenu, ownershipBlockSetting);
        SettingsStore.saveSettings();
    } catch (error) {
        console.error(`Error occures in OwnerShipBlock.js, updateList ${error}`, error);
    }
}

/*************** WATCHERS ***************/
export function* watcherGetOwnershipData() {
    yield takeLatest(ActionTypes.OWNERSHIP_BLOCK_GET_API_DATA, getOwnerShipApiData)
}
export function* watcheronOwnerShipDataReceived() {
    yield takeLatest(TimelineConstant.ActionTypes.PRICE_PANEL_RESPONSE_READY_TIMELINE, onOwnershipBlockDataReceived)
}
export function* watcherOnOwnerShipAumToggle() {
    yield takeLatest(ActionTypes.OWNERSHIP_BLOCK_AUM_TOGGLE, toggleAumView)
}
export function* watcherOnOwnerShipFundToggle() {
    yield takeLatest(ActionTypes.OWNERSHIP_BLOCK_FUND_TOGGLE, toggleFund)
}
export function* watcherOnOwnerShipViewToggle() {
    yield takeLatest(ActionTypes.OWNERSHIP_BLOCK_VIEW_TOGGLE, toggleDataView)
}
export function* watcherOnOwnerShipSizeChanged() {
    yield takeLatest(ActionTypes.OWNERSHIP_BLOCK_SIZE_CHANGED, updateBlockSize)
}
export function* watcherPrepareHeaderMenu() {
    yield takeLatest(ActionTypes.OWNERSHIP_BLOCK_PREPARE_HEADERMENU, prepareHeaderMenu)
}
export function* watcherOnOwnerShipListChanged() {
    yield takeLatest(ActionTypes.OWNERSHIP_BLOCK_LIST_CHANGED, updateList)
}
