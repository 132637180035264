import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import { isString } from "underscore";
import { Button, ButtonGroup, ButtonToolbar, Overlay, Dropdown, MenuItem } from "react-bootstrap";
import { setLiqShowSettingWindow, onKeyPressUpdateSlider, toggleFilterOnOff, saveLiqFilterValue } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import RangeSlider from './RangeSlider';
import { SettingsWindow } from '../Presentational'
import LocalizationStore from 'LocalizationStore';
import onClickOutside from "react-onclickoutside";
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType, { CompareMenuType } from "CompareTabType";

class LiquidityRatingsFilter extends Component {

  componentDidMount() {
    document.addEventListener("keyup", this.onDocumnetKeyPress, false);
  }

  componentWillUnmount() {
    this.props.saveFilterValue();
    document.removeEventListener("keyup", this.onDocumnetKeyPress, false);
  }

  onDocumnetKeyPress = (e) => {
    this.props.onKeyPressUpdateSlider(e.keyCode);
  }


  handleClickOutside(e) {
    this.handleDocumentOutsideClick(e);
  }

  handleDocumentOutsideClick = (e) => {
    if (this.props.openLiquidityRatingsFilterSetting) {
      try {
        const target = e.target || e.srcElement;
        if (isString(target.className)) {
          if (target.className.includes("liq-filter-btn")) return;
          else {
            this.handleClickToggle(false);
            this.props.closeLiqFilterWindow();
          }
        }
        else {
          this.handleClickToggle(false);
          this.props.closeLiqFilterWindow();
        }
      }
      catch (ex) {
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  handleClickToggle = (defaultValue = true) => {
    let { showSettingsWindow } = this.props;
    if (defaultValue) {
      this.props.setLiqShowSettingWindow(!showSettingsWindow);
    }
    else {
      this.props.setLiqShowSettingWindow(false);
    }
  };


  render() {
    const openLiquidityRatingsFilterSetting = (this.props.openLiquidityRatingsFilterSetting ? 'block' : 'none');
    const { showSettingsWindow, isFilterOn, isFilterManualOff, mktCapFilterViewModel, avolFilterViewModel, ratingFilterTypeOptions1ViewModel, ratingFilterTypeOptions2ViewModel } = this.props;

    return (
      <div className='liquidity-ratings-setting' style={{ display: openLiquidityRatingsFilterSetting }}>
        <div className="custom-panel-block liquidity-filter-setting">
          <div className="panel panel-default">
            <div className="panel-heading">
              <span className="panel-title">{LocalizationStore.getTranslatedData("CCG_Lqf_2", "LIQUIDITY FILTERS")}</span>
            </div>
            <div className="panel-body">
              <div className="inner-panel-body">
                <div className="dialog-content set-value-setting-block">
                  <ErrorBoundary><RangeSlider key="mktCap" statesData={mktCapFilterViewModel} isFilterOn={isFilterOn} isFilterManualOff={isFilterManualOff} /></ErrorBoundary>
                  <ErrorBoundary><RangeSlider key="avolFilter" statesData={avolFilterViewModel} isFilterOn={isFilterOn} isFilterManualOff={isFilterManualOff} /></ErrorBoundary>
                </div>
                <div className="setting-graph-middel">
                  <span className="setting-text">{LocalizationStore.getTranslatedData("CCG_RatingFilter", "RATING FILTERS")}</span>
                  <button className="setting-button btn btn-sm btn-secondary" ref={(t) => { this.targetElm = t }} onClick={this.handleClickToggle}><span className="icn-cong "></span></button>
                </div>
                <div className="dialog-content set-value-setting-block">
                  <Overlay
                    show={showSettingsWindow}
                    onHide={() => this.props.setLiqShowSettingWindow(false)}
                    placement="bottom"
                    container={this}
                    target={() => ReactDOM.findDOMNode(this.targetElm)}
                  >
                    <ErrorBoundary><SettingsWindow handleClickToggle={this.handleClickToggle} /></ErrorBoundary>
                  </Overlay>
                  <ErrorBoundary><RangeSlider key="ratings1" statesData={ratingFilterTypeOptions1ViewModel} isFilterOn={isFilterOn} isFilterManualOff={isFilterManualOff} /></ErrorBoundary>
                  <ErrorBoundary><RangeSlider key="ratings2" statesData={ratingFilterTypeOptions2ViewModel} isFilterOn={isFilterOn} isFilterManualOff={isFilterManualOff} /></ErrorBoundary>
                </div>
                <div className="on-off-switch">
                  <ButtonToolbar onClick={isFilterManualOff ? null : this.props.toggleFilterOnOff}>
                    {isFilterOn && !isFilterManualOff ?
                      <ButtonGroup bsSize="xsmall">
                        <Button className="btn-secondary small-bold on focus-inherit ">{LocalizationStore.getTranslatedData("CCG_ON", "ON")}</Button><Button className="btn-secondary small-bold"></Button>
                      </ButtonGroup>
                      :
                      <ButtonGroup bsSize="xsmall" >
                        <Button className="btn-secondary small-bold focus-inherit"></Button><Button className="btn-secondary small-bold ">{LocalizationStore.getTranslatedData("CCG_OFF", "OFF")}</Button>
                      </ButtonGroup>
                    }
                  </ButtonToolbar>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const liqFilterData = state.comparisonAndRotationMenuPanel.LiqAndRatingFilterReducer;

  if (selectedTab === CompareTabType.ComparisonGraph) {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.ComparisonMenuTab;
    const data = selectedMenuTab == CompareMenuType.Symbols ? liqFilterData.ComparisonMenuStates.Symbols : liqFilterData.ComparisonMenuStates.Groups;

    const { showSettingsWindow, isFilterOn, isFilterManualOff, mktCapFilterViewModel, avolFilterViewModel, ratingFilterTypeOptions1ViewModel, ratingFilterTypeOptions2ViewModel } = data;

    return { showSettingsWindow, isFilterOn, isFilterManualOff, mktCapFilterViewModel, avolFilterViewModel, ratingFilterTypeOptions1ViewModel, ratingFilterTypeOptions2ViewModel };
  }
  else {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    const data = selectedMenuTab == CompareMenuType.Symbols ? liqFilterData.RotationMenuStates.Symbols : liqFilterData.RotationMenuStates.Groups;

    const { showSettingsWindow, isFilterOn, isFilterManualOff, mktCapFilterViewModel, avolFilterViewModel, ratingFilterTypeOptions1ViewModel, ratingFilterTypeOptions2ViewModel } = data;

    return { showSettingsWindow, isFilterOn, isFilterManualOff, mktCapFilterViewModel, avolFilterViewModel, ratingFilterTypeOptions1ViewModel, ratingFilterTypeOptions2ViewModel };
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLiqShowSettingWindow: (status) => dispatch(setLiqShowSettingWindow(status)),
  toggleFilterOnOff: () => dispatch(toggleFilterOnOff()),
  onKeyPressUpdateSlider: (keycode) => dispatch(onKeyPressUpdateSlider(keycode)),
  saveFilterValue: () => dispatch(saveLiqFilterValue())
})

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(LiquidityRatingsFilter));