import FundDescriptionControl from '../FundDescriptionControl.jsx';
import { Panel } from "react-bootstrap";
import React from "react";
import SymbolType from "SymbolType";
import TabularView from './TabularView.jsx';

function IndustryPanelBody(props) {
    return (
        <>
            <Panel>
                <Panel.Body>
                    <div className="summary-block-catalog">
                        <div className="summary-block-record description-industry-group">
                            <FundDescriptionControl id="industryView" height={85} width={622} />
                        </div>
                    </div>
                    <div className="summary-block-chronicle for-industry-group">
                        <div className="summary-block-panel-bottom">
                            <div className="summary-block-calendar-hold">
                                {
                                    props.symbolType === SymbolType.WONINDUSTRYGROUP197 ?
                                        <div className="summary-block-calendar" style={{ width: "624px", height: "75px" }}>
                                            <TabularView id="weeklyData" />
                                        </div> : <div />
                                }
                                <div className="summary-block-calendar" style={{ width: "624px", height: "90px" }}>
                                    <TabularView id="ratingData" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Panel.Body>
            </Panel>
        </>
    )
}
export default IndustryPanelBody