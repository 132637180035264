import { SymbolDataModel } from '../../../Models/ComparisonChartModels/LineDataModel';
import { handleSymbolsInputLimit } from '../MenuPanel/SymbolsGroupsCommonSaga';
import store from '../../../Redux/store';
import BaseServiceApi from 'BaseServiceApi';
import { RotationMenuType } from "../../../Constants/RotationMenuType";
import { each, map, sortBy, find, contains, filter, extend, unique, flatten, without, difference, clone } from "underscore";
import chartApi from '../../../ServiceApi/Apis/ChartApi';
import { CompRotationHelper } from '../../../Models/NavCompare/ComparisonAndRotationMenuModels/MenuStates';
import { setScrollPosition } from 'Actions/ComparisonAndRotationMenuActions';
import ListApi from "ListApi";
import BasicEsInfo from 'ServiceApi/RequestHelper/BasicEsInfo';
import { put, call, select, takeLatest, fork, delay } from 'redux-saga/effects';
import { getNavCompareState, getGroupsPanel, getLiquidityFilter } from '../../../Reducers/NavCompare/MenuPanelReducers/selectors';
import CompareTabType, { CompareMenuType } from "CompareTabType";
import SettingsStore from "SettingsStore";
import StringUtil from 'StringUtil';
import { LoadBenchMarkData } from '../RotationGraph/RotationMenuPanelSaga';
import { getCompChartListItem, getCompareChartViewModel, getCompareChart } from '../../../Reducers/NavCompare/ComparisonGraphReducers/selectors';
import { ComparisonAndRotationMenuConstants } from '../../../Constants/RotationGraphConstants';
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import { ComparisonGraph } from '../../../Constants/ComparisonGraph';
import { getSymbolsMenu, getActiveCompNavTabName, getSelectedTabActiveMenuName } from '../../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import { setRotationGraphFilter } from '../MenuPanel/LiqAndRatingFilterSaga';
import { getQuadDataForSymbol, processQuadData } from '../RotationGraph/CenterRotationSaga';
import { callBenchMarkApi } from '../RotationGraph/BenchMarkSaga';
import UserInfoUtil from "UserInfoUtil";
import { getSymbolTypeEnumStringValue, calculateDataPointsForSymbols, processData, getCompareChartScaleDataAndSetScale } from '../ComparisionGraph/chartViewModel';
import CompareApi from "CompareApi";
import { prepareGeoGraphyData } from './GroupsPanelSaga';
import BrowserUtil from 'BrowserUtil';
import ListStore from "ListStore";
import { PrintMode } from '../../../print/printmode';

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
extend(ActionTypes, ComparisonGraph.ActionTypes)

let isRotationUnsupportedSymbolsFiltered = false;
let listSymbols = [];
let listOsids = [];
let filteredSymbols = [];
let filteredOsids = [];
let listCatalog = {
  listId: null,
  actualListId: null,
  searchText: '',
  sortCols: '',
  initialNumRows: 90000,
  needColumnsInfo: 1,
  needBrowserCounts: 0,
  browserFilterText: "",
  igOneilbrowserFilters: "",
  igGicsbrowserFilters: "",
  saveColFilters: 0,
  customerOrder: 0,
  colsetId: '-1',
  isOneilselected: 1,
  isGicsselected: 0,
  isNupview: 0,
  pageIndex: 0,
  pageSize: 10000,
  isQuoteboard: 0,
  msId: null,
  fundId: "",
  indGrpCode: "",
  isExport: false,
  cacheId: -1
};


function* getStoreSymbolsList() {
  const storeSymbols = [];
  const navCompState = yield select(getNavCompareState);
  let currentSymbolsData;
  try {
    if (navCompState.selectedTab == CompareTabType.ComparisonGraph) {
      currentSymbolsData = yield select(getCompChartListItem);
      map(currentSymbolsData, (item) => {
        storeSymbols.push(item.label);
      });

    } else {
      currentSymbolsData = yield select(getSymbolsMenu);
      map(currentSymbolsData.symbolsList, (item) => {
        storeSymbols.push(item);
      });
    }
  }
  catch (e) {
    console.log("Error in add", e)
  }
  return storeSymbols;
}

function* getStoreOsidsList() {
  const storeOsids = [];
  const navCompState = yield select(getNavCompareState);
  let currentStoreData;
  try {
    if (navCompState.selectedTab == CompareTabType.ComparisonGraph) {
      currentStoreData = yield select(getCompChartListItem);
      map(currentStoreData, (item) => {
        storeOsids.push(item.Osid);
      });

    } else {
      currentStoreData = yield select(getSymbolsMenu);
      map(currentStoreData.symbolsList, (item) => {
        storeOsids.push(item);
      });
    }
  }
  catch (e) {
    console.log("Error in add", e)
  }
  return storeOsids;
}


function* onClickListAdd({ item }) {
  try {
    //  let replaceSymbolsCheckedValue = false;
    let storeSymbolsList = yield call(getStoreSymbolsList);
    let storeOsidsList = yield call(getStoreOsidsList);
    const selectedTab = yield select(getActiveCompNavTabName);
    const isComparisonGraphTabSelected = selectedTab === CompareTabType.ComparisonGraph;
    const storeDataList = isComparisonGraphTabSelected ? storeOsidsList : storeSymbolsList;

    yield put({
      type: ActionTypes.SET_ADDLIST_MODAL_SHOW_STATUS,
      status: false
    })

    // if (storeDataList.length > 0) {
    //   replaceSymbolsCheckedValue = document.querySelector("input#replaceSymbols-checkbox").checked
    // }
    const listCatalogData = yield call(getListCatalog, item, item.nodeId.low, item.nodeId.low, item.name);

    yield call(handleLoading, true);

    const esBasicData = yield call(getEsBasicData, listCatalogData);
    isRotationUnsupportedSymbolsFiltered = esBasicData.isUnsupportedFiltered;

    if (!(esBasicData.count <= 100)) {
      const isLimitExceeded = yield call(handleSymbolsInputLimit, esBasicData.count, true)
      if (isLimitExceeded)
        return;
    }

    if (esBasicData && esBasicData.ownershipRecordInfo) {
      if (!isComparisonTab(selectedTab))
        yield call(setBenchMarkIndexArray, esBasicData);
      const data = JSON.parse(esBasicData.ownershipRecordInfo);
      yield call(processSymbolsData, data);
    }

    const listData = isComparisonGraphTabSelected ? listOsids : listSymbols;
    const filterData = isComparisonGraphTabSelected ? filteredOsids : filteredSymbols;

    if (listData.length > 0) {

      //   if (storeDataList.length == 0) {

      yield put({
        type: ActionTypes.SET_ADDLIST_LISTNAME,
        listName: item.name
      })
      // } else if (filterData.length > 0) {
      //   yield put({
      //     type: ActionTypes.SET_ADDLIST_LISTNAME,
      //     listName: ""
      //   })
      // }
      yield put({
        type: ActionTypes.SAVE_SETTINGS
      });
    }
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in SymbolsPanelSaga.js, onClickListAdd ${error}`)
  }
}

function isComparisonTab(selectedTab) {
  return (selectedTab === CompareTabType.ComparisonGraph)
}

function getIsCryptoCurrencyList(listItem) {
  return listItem.parentCategoryType === ListCategoryType.PRESET_ListCategory && listItem.categoryType === ListCategoryType.PRESET_ListCategory && listItem.name === "CryptoCurrency" ? true : false;
}

function isFactorList(listItem) {
  if (listItem.categoryType && listItem.categoryType == ListCategoryType.FACTORLIST_ListCategory) {
    return true;
  } else {
    return false;
  }
}

function getIsFuturesCashSpotsList(listItem) {
  return listItem.parentCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory && listItem.categoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory ? true : false;
}

function isTempList(listItem) {
  return listItem.categoryType == ListCategoryType.TEMPORARYLIST_ListCategory;
}

function isUserList(listItem) {
  return listItem.categoryType === ListCategoryType.USER_ListCategory
}

function* getListCatalog(item, listId, actualListId, listName) {
  const esbasicinfoProto = BaseServiceApi.rayData["EsBasicListInfoData"];
  const BasicEsInfoData = new esbasicinfoProto();
  const selectedTab = yield select(getActiveCompNavTabName);
  if (listId && actualListId) {
    listCatalog.listId = listId;
    listCatalog.actualListId = actualListId;
    listCatalog.listName = listName;
    if (item.categoryType == 9) {
      listCatalog.msId = item.msId;
      listCatalog.indGrpCode = item.symbol;
    }
    let NavListManagerSettings = ListStore.getNavListManagerSettings();

    if (getIsCryptoCurrencyList(item) || getIsFuturesCashSpotsList(item) || isFactorList(item) || isTempList(item)) {
      listCatalog.needBrowserCounts = 1;
      listCatalog.browserFilterText = "-1;-1;-1;";
      listCatalog.igOneilbrowserFilters = "-1;-1;-1;";
      listCatalog.igGicsbrowserFilters = "-1;-1;-1;-1;";
    } else {
      listCatalog.needBrowserCounts = NavListManagerSettings.TabONeilSettings.BrowserFilter.needBrowserCounts;
      listCatalog.browserFilterText = NavListManagerSettings.TabONeilSettings.BrowserFilter.browserFilterText;
      listCatalog.igOneilbrowserFilters = NavListManagerSettings.TabONeilSettings.BrowserFilter.igOneilbrowserFilters;
      listCatalog.igGicsbrowserFilters = NavListManagerSettings.TabONeilSettings.BrowserFilter.igGicsbrowserFilters;
    }

    const { searchText, sortCols, needColumnsInfo, saveColFilters, customerOrder, colsetId, needBrowserCounts, isOneilselected, isGicsselected, browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, pageIndex, pageSize, msId, fundId, indGrpCode, isExport, cacheId } = listCatalog;
    const colFilter = NavListManagerSettings.TabONeilSettings.ScreenOnOff == "Off" ? 0 : 1;
    const BasicEsInfoObj = BasicEsInfo.setBasicEsInfo(listId, actualListId, searchText, sortCols, pageIndex, pageSize, Boolean(needColumnsInfo), Boolean(colFilter), Boolean(saveColFilters), Boolean(customerOrder), null, colsetId, Boolean(needBrowserCounts), Boolean(isOneilselected), Boolean(isGicsselected), browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, cacheId, indGrpCode, msId, fundId, isExport);
    extend(BasicEsInfoData, { ...BasicEsInfoObj });
    extend(BasicEsInfoData, { isUserList: isUserList(item) });
    extend(BasicEsInfoData, { isComparison: isComparisonTab(selectedTab) });
    extend(BasicEsInfoData, { isRotation: !isComparisonTab(selectedTab) });
    return BasicEsInfoData;
  }
}

async function getEsBasicData(BasicEsInfoData) {
  let cancelablePromise = null;
  let data = [];
  if (cancelablePromise) {
    cancelablePromise.cancel();
  }
  cancelablePromise = ListApi.getListCatalogEsData(BasicEsInfoData);
  if (!cancelablePromise) {
    cancelablePromise = null;
    return;
  }

  await cancelablePromise.promise.then((esBasicResult) => {
    try {
      if (!esBasicResult.responseHeader.error) {
        data = esBasicResult.catalog;
        cancelablePromise = null;
      }
      else {
        cancelablePromise = null;
      }
    }
    catch (e) {
      cancelablePromise = null;
    }
  }).catch(() => console.log('isCanceled'));
  return data;
}

function* handleLoading(isLoading = false) {

  try {
    yield put({
      type: ActionTypes.MENU_LOADING,
      isLoading
    });
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in SymbolsPanelSaga.js, handleLoading ${error}`);
  }
}

function* contextMenuOperation({ ItemType }) {
  const symbolsMenu = yield select(getSymbolsMenu);
  const contextMenuData = symbolsMenu.contextManu;
  const compareStateData = yield select(getCompChartListItem);
  let compChartViewModelState = yield select(getCompareChartViewModel);
  let filteredIndexRequestData = [];
  let filteredSymbolRequestData = [];
  let filteredMsidData = [];
  try {
    switch (ItemType) {
      case 'delete':
        if (contextMenuData && contextMenuData.contextItem) {
          yield put({
            type: ActionTypes.SAVE_SETTINGS
          });
          const selectedTab = yield select(getActiveCompNavTabName);
          if (selectedTab == CompareTabType.ComparisonGraph) {
            const isIndexLine = contextMenuData.contextItem.isUserCreated;
            const filteredSymbolData = compareStateData.filter((item) => item.Osid !== contextMenuData.contextItem.Osid);
            if (isIndexLine) {
              filteredIndexRequestData = compChartViewModelState.indexRequest.filter((item) => item.Osid !== contextMenuData.contextItem.Osid);
            }
            else {
              filteredMsidData = compChartViewModelState.msids.filter((msid) => msid !== contextMenuData.contextItem.msid);
              filteredSymbolRequestData = compChartViewModelState.symbolRequest.filter((item) => item.Osid !== contextMenuData.contextItem.Osid);
            }
            yield put({
              type: ActionTypes.REMOVE_COMPARISON_LIST_ITEM,
              filteredSymbolData: filteredSymbolData,
              filteredMsidData: filteredMsidData,
              filteredSymbolRequestData: filteredSymbolRequestData,
              filteredIndexRequestData: filteredIndexRequestData,
              isIndexLine: isIndexLine
            });
            const { chartMaxPrice, chartMinPrice } = yield select(getCompareChartViewModel);
            if (Math.floor(contextMenuData.contextItem.maxValue) === Math.floor(chartMaxPrice) || Math.floor(contextMenuData.contextItem.minValue) === Math.floor(chartMinPrice)) {
              yield put({
                type: ActionTypes.CHART_RE_DRAW
              });
            }

          }
          else {
            yield fork(deleteBenchMarkItem, symbolsMenu, contextMenuData.contextItem.countryCode, contextMenuData.contextItem.regionCode, true);
            let selectedOsid = "";
            let osidArray = symbolsMenu.selectedOsid.split(";");
            osidArray = filter(osidArray, (osid) => osid !== "" && parseInt(osid) !== parseInt(contextMenuData.contextItem.osid));
            osidArray.map((osid) => {
              selectedOsid = selectedOsid.concat(osid.concat(";"))
            });

            const listItems = filter(symbolsMenu.listItems, (item) => item.symbol !== contextMenuData.contextItem.symbol);
            const stockList = filter(symbolsMenu.stockList, (item) => item.symbol !== contextMenuData.contextItem.symbol);
            const symbolsList = filter(symbolsMenu.symbolsList, (symbol) => symbol !== contextMenuData.contextItem.symbol);
            yield put({
              type: ActionTypes.REMOVE_ROTATION_LIST_ITEMS,
              listItems: listItems,
              stockList: stockList,
              selectedOsid: selectedOsid,
              symbolsList: symbolsList
            });
          }
        }
        break;

      case 'rename':
        if (contextMenuData && contextMenuData.contextItem) {
          yield put({
            type: ActionTypes.TOGGLE_EDIT_MODE,
            id: contextMenuData.contextItem.Osid
          });
        }
        break;

      default:
        break;
    }
    yield put({
      type: ActionTypes.HIDE_CONTEXT_MENU
    });
    yield put({
      type: ActionTypes.SAVE_SETTINGS
    });
  }

  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in SymbolsPanelSaga.js, contextMenuOperation ${error}`);
  }
}

function* showContextMenu({ pos, item }) {
  const compareChart = yield select(getCompareChart);
  let contextMenuData = [...compareChart.contextManu.contextMenuData];

  try {
    yield put({
      type: ActionTypes.SHOW_CONTEXT_MENU_SUCCESS,
      contextMenuData: contextMenuData.map((c) => {
        !item.isUserCreated && c.actionType === 'rename' || c.actionType === 'View Symbol' || c.actionType === 'View Constituents' ? c.isShow = false : c.isShow = true
        return c;
      }),
      pos,
      item
    });
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in SymbolsPanelSaga.js, showContextMenu ${error}`);
  }
}

function* getRotationSymbolsOsid(symbol) {
  const symbolsMenu = yield select(getSymbolsMenu);
  let matchingItem = filter(symbolsMenu.listItems, (item) => item.symbol === symbol)[0];
  return matchingItem.osid;
}

function* processSymbolsData(data, rotationAddSymbolData = false) {
  try {
    let storeSymbols = yield call(getStoreSymbolsList);
    let storeOsids = yield call(getStoreOsidsList);
    let uncommmonOsids = [];
    let symbolRequestArray = [];
    let msids = [];
    let message;
    listSymbols = [];
    listOsids = [];
    filteredSymbols = [];
    filteredOsids = [];
    const selectedTab = yield select(getActiveCompNavTabName)

    if (selectedTab == CompareTabType.ComparisonGraph) {
      let states = yield select(getCompareChartViewModel);
      let newLineArray = [];

      for (let i = 0; i < data.length; i++) {
        let currentSymbol = data[i][-5]
        let SymTypeEnum = data[i]["symboltypeid"];
        let msid = data[i]["msid"];
        let currentOsid = data[i][-9];
        if (SymTypeEnum == 8) {
          currentOsid = 40000000 + Number(currentOsid);
        } else if (SymTypeEnum == 28 || SymTypeEnum == 29) {
          currentOsid = 50000000 + Number(currentOsid);
        }
        if (currentOsid && currentOsid != undefined) {
          listOsids.push(currentOsid)

          if (!storeOsids.includes(currentOsid)) {
            const index = storeOsids.length + i;
            let newLine = new SymbolDataModel();
            newLine.label = UserInfoUtil.hasLocalSymbol() ? null : currentSymbol;
            newLine.index = index;
            newLine.isVisible = true;
            newLine.id = currentOsid.toString();
            newLine.Osid = currentOsid;
            newLine.msid = msid;
            newLine.name = null;
            newLine.localSymbol = null;
            newLine.actualSymbol = currentSymbol;
            newLine.SymTypeEnum = getSymbolTypeEnumStringValue(SymTypeEnum);
            newLine.isFilter = true;
            newLine.reset();
            symbolRequestArray.push({ Osid: currentOsid, msid: msid, SymTypeEnum: newLine.SymTypeEnum, index: index, isVisible: true, isHighlighted: true })
            newLineArray.push(newLine);
            msids.push(msid.toString());
          }
        }
      }

      filteredOsids = filter(listOsids, osid => !storeOsids.includes(osid));
      uncommmonOsids = filter(storeOsids, osid => !listOsids.includes(osid));


      if (listOsids.length > 0) {
        if (storeOsids.length > 0) {
          message = listOsids.length == 1 ? `${listOsids.length} Symbol is Added.` : `${listOsids.length} Symbols Added.`
          if (uncommmonOsids.length > 0) {
            yield call(removeCompareListItems, uncommmonOsids);
          }
        } else {
          message = filteredOsids.length > 0 ? filteredOsids.length == 1 ? `${filteredOsids.length} Symbol is Added.` : `${filteredOsids.length} Symbols Added.` : "No Symbols to Add";
        }
      }
      else {
        message = "No Symbols Added - List contains no Eligible Symbols";
      }

      if (filteredOsids.length > 0) {
        yield put({
          type: ActionTypes.SET_LISTALERT_MSG,
          alertMessage: message
        })

        states = yield select(getCompareChartViewModel);

        const symbolRequest = [...states.symbolRequest, ...symbolRequestArray];

        const msidsArray = [...states.msids, ...msids];

        yield put({
          type: ActionTypes.UPDATE_SYMBOL_REQ,
          symbolRequest
        })

        yield put({
          type: ActionTypes.SET_MSIDS,
          msids: msidsArray
        })

        const preLinePlotRequired = msidsArray.length > 100;

        if (preLinePlotRequired) {
          yield call(getCompareChartScaleDataAndSetScale, msidsArray);
          yield call(processData, true);
        }

        yield call(calculateDataPointsForSymbols, newLineArray, preLinePlotRequired, false);

        yield call(handleLoading, false);

        let menuTab = yield select(getSelectedTabActiveMenuName);
        if (menuTab == CompareMenuType.Symbols) {
          yield put({
            type: ActionTypes.SHOW_LISTALERT_MSG,
            showAddListAlertMessage: true
          });
        }
        yield put({ type: ActionTypes.SAVE_SETTINGS });

      }
      else {
        yield call(handleLoading, false);

        yield put({
          type: ActionTypes.SET_LISTALERT_MSG,
          alertMessage: message
        })

        yield put({
          type: ActionTypes.SHOW_LISTALERT_MSG,
          showAddListAlertMessage: true
        })
      }
    }
    else {
      const rotationGraphsymbolsMenuState = yield select(getSymbolsMenu);
      const newSymbols = [];
      let osidList = '', countryCodeList = '', regionCodeList = '';
      let symbolsDoesntSupportMessage = isRotationUnsupportedSymbolsFiltered ? " (Doesn't support all the symbols)" : "";
      for (let i in data) {
        let currentSymbol = data[i][-5]
        let symbolTypeId = data[i]["symboltypeid"];
        let osid = data[i][-9];
        if (symbolTypeId == 8) {
          osid = 40000000 + Number(osid);
        } else if (symbolTypeId == 28 || symbolTypeId == 29) {
          osid = 50000000 + Number(osid);
        }
        if (currentSymbol && currentSymbol !== undefined) {
          if (!storeSymbols.includes(currentSymbol)) {
            newSymbols.push(currentSymbol);
            symbolRequestArray.push(data[i]);
            osidList = osidList.concat(`${osid};`);
            countryCodeList = countryCodeList.concat(`${data[i].ctrycode};`)
            regionCodeList = regionCodeList.concat(`${(parseInt(data[i].wonrgcode) + 1000).toString()};`);
          }
          else {
            yield call(deleteBenchMarkItem, rotationGraphsymbolsMenuState, data[i].ctrycode, parseInt(data[i].wonrgcode) + 1000);
          }
          listSymbols.push(currentSymbol);
        }
      }

      let selectedOsid = rotationGraphsymbolsMenuState.selectedOsid;
      let filteredStoresymbolsList = rotationGraphsymbolsMenuState.symbolsList;
      filteredSymbols = difference(listSymbols, storeSymbols);

      if (listSymbols.length > 0) {
        if (storeSymbols.length > 0 && !rotationAddSymbolData) {
          message = listSymbols.length == 1 ? `${listSymbols.length} Symbol is Added` : `${listSymbols.length} Symbols Added `

          const uncommonItems = difference(storeSymbols, listSymbols);

          if (uncommonItems.length > 0) {
            selectedOsid = '';
            const uncommonItemsOsidArray = [];
            map(uncommonItems, (uncommonItem) => {
              const uncommonListItem = filter(rotationGraphsymbolsMenuState.stockList, (item) => item.symbol === uncommonItem);
              if (!StringUtil.isEmpty(uncommonListItem))
                uncommonItemsOsidArray.push(uncommonListItem[0].osid.toString());
            });

            let selectedOsidArray = without(rotationGraphsymbolsMenuState.selectedOsid.split(";"), "");
            selectedOsidArray = difference(selectedOsidArray, uncommonItemsOsidArray);
            selectedOsidArray = unique(selectedOsidArray);

            selectedOsidArray.map((osid) => {
              selectedOsid = selectedOsid.concat(`${osid};`);
            });

            const filteredStorelistItems = [];
            for (let item of rotationGraphsymbolsMenuState.stockList) {
              if (uncommonItems.contains(item.symbol)) {
                yield call(deleteBenchMarkItem, rotationGraphsymbolsMenuState, item.countryCode, item.regionCode);
              }
              else {
                filteredStorelistItems.push(item);
              }
            };

            filteredStoresymbolsList = filter(rotationGraphsymbolsMenuState.symbolsList, (symbol) => !uncommonItems.contains(symbol));
            rotationGraphsymbolsMenuState.quadGraphResults.quadGraphItemsData2 = filter(rotationGraphsymbolsMenuState.quadGraphResults.quadGraphItemsData2, (item) => !uncommonItemsOsidArray.contains(item.id.low.toString()));

            yield put({
              type: ActionTypes.REMOVE_ROTATION_LIST_ITEMS,
              listItems: [...filteredStorelistItems],
              selectedOsid: selectedOsid,
              symbolsList: filteredStoresymbolsList,
              stockList: [...filteredStorelistItems]
            });
          }
        } else {
          if (!rotationAddSymbolData)
            message = filteredSymbols.length > 0 ? filteredSymbols.length == 1 ? `${filteredSymbols.length} Symbol is Added` : `${filteredSymbols.length} Symbols added` : "No Symbols Added - List contains no Eligible Symbols"
          else
            message = filteredSymbols.length > 0 ? filteredSymbols.length == 1 ? `${filteredSymbols.length} Symbol is Added.` : `${filteredSymbols.length} Symbols added` : "No Symbol is Added";
        }
      }
      else {
        message = "No Symbols Added - List contains no Eligible Symbols";
      }

      if (symbolRequestArray.length > 0) {

        yield call(setBenchMarkIndex, rotationGraphsymbolsMenuState);

        yield put({
          type: ActionTypes.ADD_SYMBOLS,
          symbolsList: filteredStoresymbolsList.concat(newSymbols),
          selectedOsid: selectedOsid.concat(osidList)
        });
        const newList = true;
        yield call(addStocks, osidList, countryCodeList, regionCodeList, symbolRequestArray, false, true, true, newList);
      }
      else {

        yield call(setBenchMarkIndex, rotationGraphsymbolsMenuState);

        yield call(setRotationGraphFilter);

        yield call(handleLoading, false);

        yield put({
          type: ActionTypes.SHOW_LISTALERT_MSG,
          showAddListAlertMessage: true
        });

        yield put({
          type: ActionTypes.SAVE_SETTINGS
        });
      }

      yield put({
        type: ActionTypes.SET_LISTALERT_MSG,
        alertMessage: message
      });
    }
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in SymbolsPanelSaga.js, processSymbolsData ${error}`);
  }
}

function* findGeographyData(countryCode) {
  try {
    const userData = UserInfoUtil.getUserInfo();
    const geographyData = find(userData.regionCountries, (item) => item.countryCode === countryCode);
    let regionName = "", regionCode = "";

    switch (geographyData.region) {
      case "Central America":
      case "North America":
      case "South America":
        regionName = "Americas";
        regionCode = 1;
        break;
      case "Eastern Europe":
      case "Western Europe":
      case "Middle East/Africa":
        regionName = "EMEA";
        regionCode = 2;
        break;      
      case "Asia/Pacific":
        regionName = "Asia/Pacific";
        regionCode = 3;
        break;
      default: break;
    }

    return { countryName: geographyData.name, regionName, regionCode };
  }
  catch (error) {
    console.log(`Error occurs in SymbolsPanelSaga.js, findGeographyData ${error}`);
  }
}

function* initCompChart() {
  console.log("PRINT:--Tracker -- initCompChart loop");
  yield delay(200);
  const liqFilterState = yield select(getLiquidityFilter);

  if (liqFilterState.liqFilterValues !== null) {
    yield put({
      type: ActionTypes.INIT_COMP_CHART,
    });
  }
  else {
    yield fork(initCompChart);
  }
}

function* initCompSymbolsMenu() {

  yield put({
    type: ActionTypes.INIT_LIQ_VIEW_MODEL,
  })

  yield fork(initCompChart);
}

export function* initRotationSymbolsMenu({ isRefresh }) {
  try {
    let isPopulateData = false;
    const symbolsMenuState = store.getState().comparisonAndRotationMenuPanel.SymbolsPanelReducer["RotationMenuStates"];

    let settings = SettingsStore.getConsoleSettings().NavCompareSettings;
    let menuSettings = null

    settings = settings.TabRotationGraphSettings;
    menuSettings = settings.rotationSymbolsMenuSettings;

    if (!StringUtil.isEmpty(settings) && !StringUtil.isEmpty(menuSettings)) {
      const { listName,notVisibleOsids, lastHighlightedOsids, symbolsList, stockList, osidList, countryCodeList, regionCodeList, countryCode, regionCode, countryAggArray, regionAggArray, benchMarkOption } = menuSettings;
      _.each(stockList, (entry) => {
        if (lastHighlightedOsids.indexOf(entry.osid) > -1) {
          entry.isHighlighted = true;
          entry.isSelected = true;
          entry.isLabelHighlighted = true;
        }
        else {
          entry.isHighlighted = false;
          entry.isSelected = false;
          entry.isLabelHighlighted = false;
        }
        if (notVisibleOsids.indexOf(entry.osid) > -1) {
          entry.isVisible = false;
        } else {
          entry.isVisible = true;
        }

      });

      symbolsMenuState.symbolsList = symbolsList;
      symbolsMenuState.stockList = stockList;
      symbolsMenuState.listItemType = 4;
      symbolsMenuState.isGeographySelected = true;
      symbolsMenuState.listItems = [];
      symbolsMenuState.selectedOsid = osidList;
      symbolsMenuState.countryCodeList = countryCodeList;
      symbolsMenuState.regionCodeList = regionCodeList;
      symbolsMenuState.countryCode = countryCode;
      symbolsMenuState.regionCode = regionCode;
      symbolsMenuState.countryAggArray = countryAggArray;
      symbolsMenuState.regionAggArray = regionAggArray;

      if (!StringUtil.isEmpty(notVisibleOsids)) {
        symbolsMenuState.isNotVisibleOsids = [...notVisibleOsids];
      }
      if (!StringUtil.isEmpty(lastHighlightedOsids))
        symbolsMenuState.lastHighlightedOsids = [...lastHighlightedOsids];
        
      symbolsMenuState.listName = listName;

      const symbolRequestArray = clone(stockList);


      if (StringUtil.isEmpty(symbolsMenuState.selectedOsid) && !isRefresh) {
        const result = yield call(chartApi.findStockInfo, symbolsList[0]);

        symbolsMenuState.selectedOsid = `${result.Osid.low.toString()};`;
        symbolsMenuState.countryCode = result.CountryCode.toString();
        symbolsMenuState.countryCodeList = `${result.CountryCode.toString()};`;

        const { countryName, regionName, regionCode } = yield call(findGeographyData, result.CountryCode);

        symbolRequestArray.push({ "2161": result.Osid.low, "-9": result.Osid.low, "-5": symbolsList[0], msid: result.MsId, ctrycode: result.CountryCode, wonrgcode: regionCode, "4421": symbolsList[0] });
        symbolsMenuState.countryAggArray.push({ "key": 1, "value": 1, "name": countryName });
        symbolsMenuState.regionCode = regionCode + 1000;
        symbolsMenuState.regionCodeList = `${regionCode + 1000};`;
        symbolsMenuState.regionAggArray.push({ "key": regionCode, "value": 1, "name": regionName });
        isPopulateData = true;
      }

      if (symbolRequestArray.length > 0) {
        let isSaveSettings = false;

        if (!isRefresh) {
          yield fork(setInitialScrollPosition);

          isSaveSettings = true;
        }

        const code = !PrintMode.printing || benchMarkOption === "Country" ? symbolsMenuState.countryCode : benchMarkOption === "Regional" ? symbolsMenuState.regionCode : 1000;
        yield call(LoadBenchMarkData, code);
        yield call(addStocks, symbolsMenuState.selectedOsid, symbolsMenuState.countryCodeList, symbolsMenuState.regionCodeList, symbolRequestArray, true, isPopulateData, isSaveSettings,false,symbolsMenuState.listName);

        yield put({
          type: ActionTypes.INIT_LIQ_VIEW_MODEL,
        });
      }
      else {
        yield call(handleLoading, false);
      }
    }
  } catch (error) {
    console.log(`Error occurs in SymbolsPanelSaga.js, initRotationSymbolsMenu ${error}`);
  }
}

export function* addSymbolToMenu(result) {
  try {
    yield call(handleLoading, true);

    const { countryName, regionName, regionCode } = yield call(findGeographyData, result.ctrycode);

    const countryAgg = [{ "key": result.ctrycode, "value": 1, "name": countryName }];
    const regionAgg = [{ "key": regionCode, "value": 1, "name": regionName }];
    const ownershipRecordInfo = [{ "2161": result.Osid, "-9": result.Osid, "-5": result.Symbol, msid: result.MsId, ctrycode: result.ctrycode, wonrgcode: regionCode, "4421": result.LocalSymbol }];

    const symbolData = { countryAgg: countryAgg, regionAgg: regionAgg, ownershipRecordInfo: ownershipRecordInfo };

    yield call(setBenchMarkIndexArray, symbolData);

    yield call(processSymbolsData, symbolData.ownershipRecordInfo, true);
  }
  catch (error) {
    console.log(`Error occurs in SymbolsPanelSaga.js, addSymbolToMenu ${error}`);
  }
}

function* setBenchMarkIndexArray(esBasicData) {
  try {
    const symbolsMenuState = store.getState().comparisonAndRotationMenuPanel.SymbolsPanelReducer["RotationMenuStates"];

    yield put({
      type: ActionTypes.UPDATE_BENCHMARKDATA_LOADING_STATUS,
      isBenchMarkLoading: true
    });

    const prevCountryAggArray = symbolsMenuState.countryAggArray;
    const prevRegionAggArray = symbolsMenuState.regionAggArray;

    const countryData = yield call(populateNewAgg, esBasicData.countryAgg, prevCountryAggArray, symbolsMenuState.countryCodeList, symbolsMenuState.regionCodeList, true);
    symbolsMenuState.countryAggArray = countryData.newAggArray;
    symbolsMenuState.countryCodeList = countryData.countryCodeList;

    const regionData = yield call(populateNewAgg, esBasicData.regionAgg, prevRegionAggArray, symbolsMenuState.countryCodeList, symbolsMenuState.regionCodeList, false);
    symbolsMenuState.regionAggArray = regionData.newAggArray;
    symbolsMenuState.regionCodeList = regionData.regionCodeList;

    // yield put({
    //   type: ActionTypes.SAVE_SETTINGS
    // });
  }
  catch (error) {
    console.log(`Error occurs in SymbolsPanelSaga.js, setBenchMarkIndexArray ${error}`);
  }
}

function populateNewAgg(response, prevAggArray, countryCodeList, regionCodeList, isCountryData) {
  let newAggArray = [];

  for (let i = 0; i < response.length; i++) {
    const item = response[i];

    if (prevAggArray.length > 0) {
      const prevData = find(prevAggArray, (arr) => arr.key == item.key);
      if (!StringUtil.isEmpty(prevData)) {
        prevData.value += parseInt(item.value);
      }
      else {
        newAggArray.push({ "key": parseInt(item.key), "value": parseInt(item.value), "name": item.name });

        if (isCountryData)
          countryCodeList = countryCodeList.concat(`${item.key};`);
        else
          regionCodeList = regionCodeList.concat(`${(parseInt(item.key) + 1000).toString()};`);
      }
    }
    else {
      newAggArray.push({ "key": parseInt(item.key), "value": parseInt(item.value), "name": item.name });

      if (isCountryData)
        countryCodeList = countryCodeList.concat(`${item.key};`);
      else
        regionCodeList = regionCodeList.concat(`${(parseInt(item.key) + 1000).toString()};`);
    }
  }

  newAggArray.push(prevAggArray);
  newAggArray = flatten(newAggArray);
  return { newAggArray, countryCodeList, regionCodeList };
}

function* setBenchMarkIndex(symbolsMenuState) {
  try {
    const countryAggArray = symbolsMenuState.countryAggArray;
    const regionAggArray = symbolsMenuState.regionAggArray;

    if (countryAggArray.length > 0 && regionAggArray.length > 0) {
      const countryCode = yield call(getMajorityItem, countryAggArray);
      const regionCode = (parseInt(yield call(getMajorityItem, regionAggArray, true)) + 1000).toString();

      yield fork(callBenchMarkApi, symbolsMenuState.countryCode, symbolsMenuState.regionCode, countryCode, regionCode);

      yield put({
        type: ActionTypes.UPDATE_BENCHMARK_INDEX_CODE,
        countryCode,
        regionCode
      });
    }
  }
  catch (error) {
    console.log(`Error occurs in SymbolsPanelSaga.js, setBenchMarkIndex ${error}`);
  }
}

function* getMajorityItem(array, isRegion = false) {
  let majorityOfArray = sortBy(array, (a) => parseInt(a.value)).reverse();
  const max = majorityOfArray[0].value;

  majorityOfArray = filter(array, (item) => item.value == max);
  if (majorityOfArray.length > 0) {
    if (isRegion) {
      majorityOfArray = yield call(updateRegionName, majorityOfArray);
    }
    majorityOfArray = sortBy(majorityOfArray, (a) => a.name);
  }

  return majorityOfArray[0].key;
}

function updateRegionName(majorityOfArray) {
  map(majorityOfArray, (item) => {
    switch (item.key) {
      case 1:
        item.name = "Americas";
        break;
      case 2:
        item.name = "EMEA";
        break;
      case 3:
        item.name = "Asia Pacific";
        break;
      default:
        break;
    }
  });
  return majorityOfArray;
}

function* deleteBenchMarkItem(symbolsMenuState, ctrycode, wonrgcode, reloadBenchMarkGraph = false) {
  const countryAggArray = symbolsMenuState.countryAggArray;
  const regionAggArray = symbolsMenuState.regionAggArray;

  map(countryAggArray, (item) => {
    if (item.key === ctrycode) {
      item.value--;
    }
  });

  map(regionAggArray, (item) => {
    if (item.key === wonrgcode) {
      item.value--;
    }
  });

  if (reloadBenchMarkGraph) {
    yield call(setBenchMarkIndex, symbolsMenuState);
  }
}


export function* addStocks(osidList, countryCodeList, regionCodeList, symbolRequestArray, isInit = false, isPopulateData = true, isSaveSettings = true, newList = false,listName='') {
  try {
    let rotationGraphsymbolsMenuState = yield select(getSymbolsMenu);
    const { lastHighlightedOsids, isNotVisibleOsids } = yield select(getSymbolsMenu);

    let listItems = [];

    const quadDataResult = yield call(getQuadDataForSymbol, osidList, countryCodeList, regionCodeList);

    if (quadDataResult && quadDataResult.QuadGraphItemList) {
      if (isPopulateData) {
        const filteredQuadDataResult = unique(quadDataResult.QuadGraphItemList, (item) => item.SectorOsid.low);

        for (let i = 0; i < filteredQuadDataResult.length; i++) {
          const quadData = filteredQuadDataResult[i];

          const symbolData = find(symbolRequestArray, (item) => {
            const osid = !StringUtil.isEmpty(item[-9]) ? item[-9] : item.osid;
            return osid == quadData.SectorOsid.low;
          });
          
          listItems.push({
            itemName: quadData.SectorName,
            displayName: quadData.SectorName,
            osid: quadData.SectorOsid.low,
            msid: symbolData.msid,
            countryCode: symbolData.ctrycode,
            regionCode: symbolData.wonrgcode,
            itemType: RotationMenuType.Stocks,
            avol: quadData.InstrumentLiqFilterData.Avol,
            mktCp: quadData.InstrumentLiqFilterData.MktCp,
            symbol: quadData.SectorLabl,
            localSymbol: quadData.localSymbol,
            underlyingSymbol: quadData.SectorLabl,
            isFlagged: quadData.InstrumentLiqFilterData.IsFlagged,
            instrumentId: quadData.SectorOsid.low,
            isHighlighted: isInit && lastHighlightedOsids.length > 0 ? contains(lastHighlightedOsids, quadData.SectorOsid.low) : true,
            isVisible: isInit && isNotVisibleOsids.length > 0 ? !contains(isNotVisibleOsids, quadData.SectorOsid.low) : true,
            isSelected: isInit && lastHighlightedOsids.length > 0 ? contains(lastHighlightedOsids, quadData.SectorOsid.low) : true,
            ratingData: quadData.InstrumentLiqFilterData,
            isLabelHighlighted: isInit && lastHighlightedOsids.length > 0 ? contains(lastHighlightedOsids, quadData.SectorOsid.low) : false,
            listName: listName
          });
        }
      }
      else {
        listItems = symbolRequestArray;
      }

      //Sort on itemName
      let itemNames = [];
      rotationGraphsymbolsMenuState.stockList.map((item) => {
        itemNames.push(item.displayName);
      })

      listItems.map((item) => {
        if (!itemNames.contains(item.displayName)) {
          rotationGraphsymbolsMenuState.stockList.push(item);
        }
      })

      if (rotationGraphsymbolsMenuState.lastHighlightedOsids.length === rotationGraphsymbolsMenuState.stockList.length)
        each(rotationGraphsymbolsMenuState.stockList, (entry) => { entry.isLabelHighlighted = false; });


      rotationGraphsymbolsMenuState.lastHighlightedOsids = yield call(addLastHighlightedOsids, rotationGraphsymbolsMenuState.stockList);

      let filteredList = each(sortBy(rotationGraphsymbolsMenuState.stockList, 'itemName'), (item, i) => {
        item.itemColor = CompRotationHelper.prototype.getLineColor(i);
      });

      rotationGraphsymbolsMenuState.stockList = [...filteredList];


      if (isSaveSettings) {
        yield put({
          type: ActionTypes.SAVE_SETTINGS
        });
      }

      yield call(processQuadData, quadDataResult, "3", newList);

      yield call(setRotationGraphFilter);


      yield put({
        type: ActionTypes.SHOW_LISTALERT_MSG,
        showAddListAlertMessage: true
      });
    }
  }
  catch (error) {
    BrowserUtil.enableEvents();
    console.log(`Error occurs in symbolsPanelSaga.js, addStocks ${error}`);
  }
}

function* handleRenameValueUpdate({ item, itemValue }) {
  try {
    if (item.isEditMode) {
      yield put({
        type: ActionTypes.TOGGLE_EDIT_MODE,
        id: item.Osid,
        itemValue
      });
      if (item.name !== itemValue) {
        const states = yield select(getCompareChartViewModel);
        const indexRequestData = [...states.indexRequest].map((indexLine) => (item.Osid === indexLine.Osid) ? Object.assign(indexLine, { name: itemValue }) : indexLine);
        yield put({
          type: ActionTypes.UPDATE_INDEX_REQ,
          indexRequestData
        });
        yield put({
          type: ActionTypes.SAVE_SETTINGS
        })
      }

    }
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in SymbolsPanelSaga.js, handleRenameValueUpdate ${error}`);
  }
}


function* handleToggleSort({ sortType }) {
  try {
    yield put({
      type: ActionTypes.TOGGLE_SORT_SUCCESS,
      sortType
    });
    yield put({
      type: ActionTypes.SAVE_SETTINGS
    });
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in SymbolsPanelSaga.js, handleToggleSort ${error}`);
  }
}


function* addLastHighlightedOsids(listItems) {
  const { lastHighlightedOsids } = yield select(getSymbolsMenu);

  if (!StringUtil.isEmpty(listItems))
    map(listItems, (item) => {
      if (lastHighlightedOsids.indexOf(parseInt(item.osid)) === -1)
        lastHighlightedOsids.push(parseInt(item.osid));
    });
  return lastHighlightedOsids;
}

function* setInitialScrollPosition() {
  const { listItems, lastHighlightedOsids } = yield select(getSymbolsMenu);
  let scrollIndex = 0;
  if (!StringUtil.isEmpty(listItems))
    scrollIndex = (listItems.length === lastHighlightedOsids.length) ? 0 : lastHighlightedOsids.length - 1;

  yield put(setScrollPosition(lastHighlightedOsids[scrollIndex], true));
}

function* removeCompareListItems(removeItems) {
  let compareChartListItems = yield select(getCompChartListItem);
  let compChartViewModelState = yield select(getCompareChartViewModel);
  compareChartListItems = compareChartListItems.filter((item) => !removeItems.includes(item.Osid));
  let filteredIndexRequestData = compChartViewModelState.indexRequest;
  let filteredSymbolRequestData = compChartViewModelState.symbolRequest;
  let filteredMsidData = [];

  filteredIndexRequestData = filteredIndexRequestData.filter((item) => !removeItems.includes(item.Osid));

  filteredSymbolRequestData = filteredSymbolRequestData.filter((item) => !removeItems.includes(item.Osid));

  filteredSymbolRequestData.map((item) => filteredMsidData.push(item.msid))

  yield put({
    type: ActionTypes.FILTERED_INDEX_REQUEST_DATA,
    indexRequest: filteredIndexRequestData
  })

  yield put({
    type: ActionTypes.FILTERED_SYMBOL_REQUEST_DATA,
    symbolRequest: filteredSymbolRequestData,
    filteredMsids: filteredMsidData
  })

  yield put({
    type: ActionTypes.FILTER_ALL_DATA,
    data: compareChartListItems
  })

  yield put({
    type: ActionTypes.CHART_RE_DRAW
  });

  yield put({
    type: ActionTypes.SAVE_SETTINGS
  });

}

function* handleClearAll() {
  const data = [];
  const selectedTab = yield select(getActiveCompNavTabName);
  yield put({
    type: ActionTypes.SET_ADDLIST_LISTNAME,
    listName: ""
  })

  try {

    if (selectedTab === CompareTabType.ComparisonGraph) {
      yield put({
        type: ActionTypes.CLEAR_COMPARISON_SYMBOLS_DATA,
        data
      });

      yield put({
        type: ActionTypes.CHART_RE_DRAW
      });
    } else {
      yield put({
        type: ActionTypes.CLEAR_ROTATION_SYMBOLS_DATA,
      });
    }

    yield put({
      type: ActionTypes.TOGGLE_CLEAR_ALL_DIALOG
    });

    yield put({ type: ActionTypes.SAVE_SETTINGS });
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in SymbolsPanelSaga.js, handleClearAll ${error}`);
  }
}

function* removeItems({ removeItems }) { }

export function* watchHandleOnClickListAdd() {
  yield takeLatest(ActionTypes.ONCLICK_LIST_ADD, onClickListAdd);
}

export function* watchInitCompSymbolsMenu() {
  yield takeLatest(ActionTypes.INIT_COMP_SYMBOLS_MENU, initCompSymbolsMenu)
}

export function* watchInitRotationSymbolsMenu() {
  yield takeLatest(ActionTypes.INIT_ROTATION_SYMBOLS_MENU, initRotationSymbolsMenu);
}

export function* watchHandleClearAll() {
  yield takeLatest(ActionTypes.CLEAR_ALL, handleClearAll);
}

export function* watchContextMenu() {
  yield takeLatest(ActionTypes.SHOW_CONTEXT_MENU, showContextMenu);
}

export function* watchContextMenuOperation() {
  yield takeLatest(ActionTypes.CONTEXT_MENU_ITEM_CLICK, contextMenuOperation);
}

export function* watchHandleRemoveListItems() {
  yield takeLatest(ActionTypes.REMOVE_LIST_ITEMS, removeItems)
}

export function* watchHandleRenameValueUpdate() {
  yield takeLatest(ActionTypes.ON_RENAME, handleRenameValueUpdate);
}

export function* watchToggleSort() {
  yield takeLatest(ActionTypes.TOGGLE_SORT, handleToggleSort);
}