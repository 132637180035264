
import { AnnotationConstant } from "../../Constants/AnnotationConstants";
const { ActionTypes } = AnnotationConstant;
import { LineInfo } from "../../Models/AnnotationModels/LineModelInfo";

const lines = []

const lineReducer = (state = lines, action) => {

    switch (action.type) {
        case ActionTypes.INIT_LINE_COLLECTION:
            return action.EllipseCollection;
        case ActionTypes.HANDLE_LINE_CLICK_SUCCESS:
            return [...state, action.line]
        case ActionTypes.HANDLE_LINE_DRAW_SUCCESS:
            return state.map((item) => (item.id === action.line.id) ? action.line : item)
        case ActionTypes.HANDLE_LINE_SAVE_ID_SUCCESS:
            return action.lines
        case ActionTypes.HANDLE_LINE_TRANSFORM_SUCCESS:
            return state.map((item) => (item.id === action.line.id) ? action.line : item)
        case ActionTypes.HANDLE_REMOVE_LINE_DRAW:
            return state.filter((item) => !action.annotSelectedId.includes(item.id))
        case ActionTypes.REMOVE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new LineInfo, { ...item, isDeleted: true }) : item)
        case ActionTypes.RESTORE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new LineInfo, { ...item, isDeleted: false }) : item)
        case ActionTypes.CLEAR_ANNOTATIONS:
            return [];
        case ActionTypes.HANDLE_CHECKBOX_CHANGE:
            switch (action.event) {
                case 'temporary':
                    return state.map((item) =>  ( action.selectedAnnotation && item.id === action.selectedAnnotation.id ? { ...item, isTemporaryChecked: action.newState, isAlertChecked: false} : item))  ;
                case 'alert':
                    return state.map((item) =>  ( action.selectedAnnotation && item.id === action.selectedAnnotation.id ? { ...item, isTemporaryChecked: false} : item))  ;
                case 'snap':
                    return state.map((item) =>  ( action.selectedAnnotation && item.id === action.selectedAnnotation.id ? { ...item, isSnappingChecked: action.newState} : item))  ;
                default:
                    return state;
                }
        case ActionTypes.REMOVE_TEMP_LINE_ANNOTATIONS:
            return state.map((item) => isNaN(item.id) ? Object.assign(new LineInfo, { ...item, isDeleted: true }) : item)
            // return state.filter((item) => !isNaN(item.id))
        case ActionTypes.REMOVE_SELECTED_TEMP_LINE_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new LineInfo, { ...item, isDeleted: true }) : item)
        default:
            return state
    }
}

export default lineReducer;