
var TabFundamentalsDataType = {
  NonReport: "NonReport",
  AllFilterReport: "AllFilterReport",
  Report: "Report",
  NonDollarFilterReport: "NonDollarFilterReport",
  Options: "Options"
};

export default TabFundamentalsDataType;
