import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class RenameCustomMetric extends BaseServiceRequest {
    constructor(metricId, name) {
        super();
        super.addRequestUri("renamecustomcol.pb");
        super.addRequestParameter("dataitemid", metricId.toString());
        super.addRequestParameter("name", name);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}

export default RenameCustomMetric;