import { changeDispView, updateHubRole } from '../../../../../../Actions/RelatedInfoPanelActions/RiPanelActions';
import LocalizationStore from 'LocalizationStore';
import { OwnershipBlockTranslateHelper } from "../../../../../../Utils/TranslateHelper";
import React from "react";
import { HubUserRole, ListType } from "../../../../../../Constants/HubRIPanelConstants";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

const HubNavigation = (props) => {
    const state = useSelector((state) => state.RelatedInfoPanelReducers.RiPanelOwnershipInfo);
    const dispatch = useDispatch();

    const changeView = (e, value) => {
        dispatch(changeDispView(value))
    }

    useEffect(()=> {
        dispatch(updateHubRole())
    }, [])

    const { hubUserRole, listType } = state;
    const title = hubUserRole === HubUserRole.Manager ? "Sales Reps" : "My Contacts"
    let view = "";
    if (props.isFundViewEntitledText) {
        switch (hubUserRole) {
            case HubUserRole.SalesRep:
            case HubUserRole.Manager:
                view = <div className="hub-navigation-container">
                    <div className={listType === ListType.TopOwnerView ? "navi-button active" : "navi-button inactive"} onClick={(e) => changeView(e, ListType.TopOwnerView)} >{OwnershipBlockTranslateHelper.TOP_OWNERS}</div>
                    <div className={listType === ListType.TopOwnerView ? "navi-button inactive" : "navi-button active"} onClick={(e) => changeView(e, ListType.HubListView)} >{LocalizationStore.getTranslatedData("ri_id101", title)}</div>
                </div>
                break;
            default:
                view = <div className="ownership-title large-normal">{OwnershipBlockTranslateHelper.TOP_OWNERS}</div>
                break;

        }
    }
    else {
        view = <div className="ownership-title large-normal">{OwnershipBlockTranslateHelper.TOP_OWNERS}</div>
    }

    return (
        <React.Fragment>
            {view}
        </React.Fragment>
    )

}

export default HubNavigation