import React from 'react';
import LocalizationStore from 'LocalizationStore';
import { ShowUsers } from "../../../Actions/ShareAction";

const AddRecepient = ({ caller, targetData, sharedAction }) => {

    const showRecepients = (ntid, id) => (event) => {
        ShowUsers(ntid, id, "1");
    }

    const text = caller === 'header' ? LocalizationStore.getTranslatedData('LM_TP_AddRecipient','Add Recipient(s)') : LocalizationStore.getTranslatedData('ri_id36','Add someone')
    return (
        <div className="add-recipient" data-test-id="main-div">
            {
                targetData &&
                <a className="link" onClick={showRecepients(targetData.ntid, targetData.id)} >
                    {text}
                </a>
            }
        </div>
    );
}

export default AddRecepient;