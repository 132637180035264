import AppDispatcher from "AppDispatcher";
import { LoginConstants } from "../Constants/LoginConstants.js";

class LoginActionsClass {

  validateUser(logindata) {
    AppDispatcher.handleServerAction({
      actionType: LoginConstants.ActionTypes.LOGIN,
      data: logindata
    });
  }

  getUserStatusInfo(userEmail, companyName = "") {
    AppDispatcher.handleServerAction({
      actionType: LoginConstants.ActionTypes.GET_AUTHROUTE,
      data: { userEmail:userEmail, companyName:companyName }
    });
  }

  getUserInitInfo(cookie, userData) {
    AppDispatcher.handleServerAction({
      actionType: LoginConstants.ActionTypes.GET_USERINIT,
      data: {cookie: cookie, userData: userData}
    });
  }

  changePassword(currentPassword, newPassword, verifyPassword, loginErrType) {
    AppDispatcher.handleServerAction({
      actionType: LoginConstants.ActionTypes.CHANGE_PASSWORD,
      data: { currentPassword: currentPassword, newPassword: newPassword, verifyPassword: verifyPassword, loginErrType: loginErrType }
    });
  }
}

const LoginActions = new LoginActionsClass();

export default LoginActions;
