import Serializable from "Serializable";
class LLRSettings extends Serializable {

    constructor(isVisible) {
        super();
        this.IsAvailable = true;
        this.IsVisible = isVisible;
        this.IsHistorical = true;
        this.ShowHistorical = false;
        this.ActiveIdeaTypes = [];
    }

    onSeralisedInfoRecieved(info) {
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
        this.IsAvailable = true;
        this.IsHistorical = true;
        this.ShowHistorical = info.ShowHistorical !== undefined ? info.ShowHistorical : false;
        this.ActiveIdeaTypes = info.ActiveIdeaTypes ? info.ActiveIdeaTypes : [];
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            ActiveIdeaTypes: this.ActiveIdeaTypes,
            ShowHistorical: this.ShowHistorical
        };
    }

    getObjectMapKey() {
        return "LLRSettings";
    }
}

export default LLRSettings;