import React from 'react';
import { FormControl } from "react-bootstrap";
import { setNameFilter } from "../../../../Actions/ShareAction"
import LocalizationStore from 'LocalizationStore';
import StringUtil from "StringUtil";

const SearchBox = ({ name }) => {
    const handleChangeNameFilter = (e) => {
        setNameFilter(StringUtil.trim(e.target.value));
    }
    const handleOnBlur = (e) => {
        e.target.focus();
    }

    //const isWriting = name && name.length > 0

    return (
        <FormControl
            name='name'
            type="text"            
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder={LocalizationStore.getTranslatedData("ri_idPlaceHolder", 'Search for a user')}
            value={name}
            onChange={handleChangeNameFilter}
            onBlur={handleOnBlur}
        />
    )
}


export default SearchBox;
