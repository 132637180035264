import { IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";

const { ActionTypes } = IndicatorsConstants;

export const resetIndicatorState = () => ({
    type: ActionTypes.RESET_INDICATOR_STATE
});
export const initIndicator = (indicatorData) => ({
    type: ActionTypes.INIT_INDICATOR,
    indicatorData
});
export const processIndicator = () => ({
    type: ActionTypes.PROCESS_INDICATOR,
});

export const initIndicatorState = (states) => ({
    type: ActionTypes.INIT_INDICATOR_STATE,
    states
});

export const prepapreIndicatorData = (graphData) => ({
    type: ActionTypes.PREPARE_INDICATOR_DATA,
    graphData
});

export const prepapreFundamentalIndicatorData = (graphData, chartType, SettingDict = null) => ({
    type: ActionTypes.PREPARE_FUNDAMENTAL_INDICATOR_DATA,
    graphData, chartType, SettingDict
});

export const prepapreADIndicatorData = (graphData, chartType) => ({
    type: ActionTypes.PREPARE_AD_INDICATOR_DATA,
    graphData, chartType
});

export const prepapreORatingIndicatorData = (graphData, chartType, SettingDict = null) => ({
    type: ActionTypes.PREPARE_ORating_INDICATOR_DATA,
    graphData, chartType, SettingDict
});
export const toggleORatingLine = (lineType) => ({
    type: ActionTypes.TOGGLE_ORATING_LINE,
    lineType
});

export const prepapreMACDIndicatorData = (graphData, chartType, SettingDict = null) => ({
    type: ActionTypes.PREPARE_MACD_INDICATOR_DATA,
    graphData, chartType, SettingDict
});

export const prepapreRSIIndicatorData = (graphData, chartType, SettingDict = null) => ({
    type: ActionTypes.PREPARE_RSI_INDICATOR_DATA,
    graphData, chartType, SettingDict
});

export const prepapreSTATSIndicatorData = (graphData, chartType, SettingDict = null) => ({
    type: ActionTypes.PREPARE_STATS_INDICATOR_DATA,
    graphData, chartType, SettingDict
});

export const prepapreYTDIndicatorData = (chartType) => ({
    type: ActionTypes.PREPARE_YTD_INDICATOR_DATA,
    chartType
});

export const updateIndicatorGraphData = (GraphData, chartType) => ({
    type: ActionTypes.UPDATE_INDICATOR_GRAPH_DATA,
    GraphData, chartType
});

export const updateIndicatorRelativeData = (RelativeData, chartType) => ({
    type: ActionTypes.UPDATE_INDICATOR_RELATIVE_DATA,
    RelativeData, chartType
});

export const updateIndicatorAbsoluteData = (AbsoluteData, chartType) => ({
    type: ActionTypes.UPDATE_INDICATOR_ABSOLUTE_DATA,
    AbsoluteData, chartType
});

export const updateIndicatorData = (indicatorVisuals, indicatorLabels, chartType, scale) => ({
    type: ActionTypes.UPDATE_INDICATOR_DATA,
    indicatorVisuals, indicatorLabels, chartType, scale
})

export const updateIndicatorScale = (scale, chartType) => ({
    type: ActionTypes.UPDATE_INDICATOR_SCALE,
    scale, chartType
})

export const toggleIndicatorChart = (chartType) => ({
    type: ActionTypes.TOGGLE_INDICATOR_CHART,
    chartType
});
export const toggleFundamentalLine = (chartType, lineType) => ({
    type: ActionTypes.TOGGLE_FUNDAMENTAL_LINE,
    chartType, lineType
});

export const updateIndicatorSettings = (SettingDict) => ({
    type: ActionTypes.UPDATE_INDICATOR_SETTINGS,
    SettingDict
})

export const updateIndicatorMenu = (indicatorMenu, blockMenu) => ({
    type: ActionTypes.UPDATE_INDICATOR_MENU,
    indicatorMenu, blockMenu
})

export const openCloseIndicatorBlock = (pblockType, blockType, isopen, data, listId) => ({
    type: ActionTypes.OPEN_COLLAPSE_INDICATOR_BLOCK,
    pblockType, blockType, isopen, data, listId
})

export const prepareIndicatorMenu = (symbolInfo) => ({
    type: ActionTypes.PREPARE_INDICATOR_MENU,
    symbolInfo
})

export const saveIndicatorSectionVisiblity = (block, visible) => ({
    type: ActionTypes.SAVE_INDICATOR_TOGGLE_SECTION,
    block, visible
})

export const sortIndicatorSection = (item) => ({
    type: ActionTypes.SORT_INDICATOR_SECTION,
    item
})
export const sortIndicator = (item) => ({
    type: ActionTypes.SORT_INDICATOR,
    item
})