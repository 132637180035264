import Serializable from "Serializable";
export default class TechnicalIndicatorSettings extends Serializable {
  constructor() {
    super();
    this.IsVisible = true;
    this.Height = 100;
    this.IsAvailable = true;
    this.IsRelative = false;
  }

  onSeralisedInfoRecieved(serializedValue) {
    Object.assign(this, serializedValue);
  }
  getDataObject() {
    return Object.assign({}, this);
  }

  getObjectMapKey() {
    return "TechnicalIndicatorSettings";
  }
}
