import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProgressLoadingBar from "../../../../../RayCustomControls/Loader/ProgressLoadingBar";
import PortalContainer from 'PortalContainer';
import { CompareMenuType } from "CompareTabType";

class CompIndexLineProgressBar extends Component {
    render() {
        const { isShowProgressBar, per } = this.props.compareChart;
        return (
            <div>
                {isShowProgressBar && <PortalContainer><ProgressLoadingBar progressPercentage={`${per}%`} progressMsg="Calculating Index Line..." />
                </PortalContainer>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.compareChart;
    const compareChart = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

    return ({ compareChart });
}
export default connect(mapStateToProps)(CompIndexLineProgressBar);