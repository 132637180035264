import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BlockType from "../../../../Constants/BlockType";
import {CustomTiDialogConstant} from "../../../../Constants/CustomTiDialogConstants";
import { DatagraphConst } from "../../../../Utils/DatagraphHelper";
import DatagraphDataType from "../../../../Constants/DatagraphDataType";
import dateHelper from "../../../../Utils/TimeLineHelper/Datehelper";
import GraphType from "../../../../Constants/GraphType";
import { IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper";
import { IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import LocalizationStore from "LocalizationStore";
import SettingsStore from "SettingsStore";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getIndicatorStates, priceChartReducerselect, } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { updateIndicatorData, updateIndicatorGraphData, updateIndicatorSettings } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";

const { ActionTypes } = IndicatorsConstants;
const lineIDConst = {
    [CustomTiDialogConstant.DataGraph]: LocalizationStore.getTranslatedData("ipc_or_dg", "Datagraph Rating"),
    [CustomTiDialogConstant.Rs3Months]: LocalizationStore.getTranslatedData("ipc_or_3mntrs", "3 Month RS Rating"),
    [CustomTiDialogConstant.Rs6Months]: LocalizationStore.getTranslatedData("ipc_or_6mntrs", "6 Month RS Rating"),
    [CustomTiDialogConstant.Rs12Months]: LocalizationStore.getTranslatedData("ipc_or_12mntrs", "12 Month RS Rating"),
    [CustomTiDialogConstant.GrpRs3Months]: LocalizationStore.getTranslatedData("ipc_or_3mntgrprs", "3 Month Group RS"),
    [CustomTiDialogConstant.GrpRs6Months]: LocalizationStore.getTranslatedData("ipc_or_6mntgrprs", "6 Month Group RS"),
    [CustomTiDialogConstant.InstSupDem]: LocalizationStore.getTranslatedData("ipc_or_sd", "Supply/Demand"),
    [CustomTiDialogConstant.SMR]: LocalizationStore.getTranslatedData("ipc_or_smr", "SMR Rating"),
    [CustomTiDialogConstant.Sponsorship]: LocalizationStore.getTranslatedData("ipc_or_spon", "Sponsorship Rank"),
    [CustomTiDialogConstant.Composite]: LocalizationStore.getTranslatedData("ipc_or_comp", "Composite Rating"),
    [CustomTiDialogConstant.EpsRank]: LocalizationStore.getTranslatedData("ipc_or_eps", "EPS Rank")
}

const labelConst = {
    [CustomTiDialogConstant.DataGraph]: LocalizationStore.getTranslatedData('ipc_ptt_dg', "DataGraph"),
    [CustomTiDialogConstant.Rs3Months]: LocalizationStore.getTranslatedData('ipc_pt_3mntrs', "3m RS"),
    [CustomTiDialogConstant.Rs6Months]: LocalizationStore.getTranslatedData('ipc_pt_6mntrs', "6m RS"),
    [CustomTiDialogConstant.Rs12Months]: LocalizationStore.getTranslatedData('ipc_pt_12mntrs', "12m RS"),
    [CustomTiDialogConstant.GrpRs3Months]: LocalizationStore.getTranslatedData('ipc_pt_3mntgrprs', "3m GRP RS"),
    [CustomTiDialogConstant.GrpRs6Months]: LocalizationStore.getTranslatedData('ipc_pt_6mntgrprs', "6m GRP RS"),
    [CustomTiDialogConstant.InstSupDem]: LocalizationStore.getTranslatedData('TI_InstSupDem', "Sup/Dem"),
    [CustomTiDialogConstant.SMR]: LocalizationStore.getTranslatedData("ipc_ptt_smr", "Smr"),
    [CustomTiDialogConstant.Sponsorship]: LocalizationStore.getTranslatedData("ipc_ptt_spon", "Sponsorship"),
    [CustomTiDialogConstant.Composite]: LocalizationStore.getTranslatedData("ipc_ptt_comp", "Composite"),
    [CustomTiDialogConstant.EpsRank]: LocalizationStore.getTranslatedData("ipc_ptt_eps", "EPS Rank")
}

export const OneilRatingLineTypes = [CustomTiDialogConstant.Rs3Months, CustomTiDialogConstant.Rs6Months, CustomTiDialogConstant.Rs12Months, CustomTiDialogConstant.GrpRs3Months, CustomTiDialogConstant.GrpRs6Months, CustomTiDialogConstant.DataGraph, CustomTiDialogConstant.InstSupDem, CustomTiDialogConstant.SMR, CustomTiDialogConstant.Sponsorship, CustomTiDialogConstant.Composite, CustomTiDialogConstant.EpsRank]

function getTechChart(hsfResults, lastNode, scale, HiLowPoints) {
    if (!hsfResults || hsfResults.length < 1 || !HiLowPoints.allPoints || !HiLowPoints.allPoints[0]) {
        return [];
    }
    let stIndex = 0;
    const stLength = HiLowPoints.allPoints.length;
    let info;
    hsfResults.filter((t) => {
        t.Date = new Date(dateHelper.getPSTFromLong(t.Date));
        return t.Close !== null;
    });

    const prcLength = hsfResults.length;

    if (prcLength < 1) {
        return [];
    }

    const chartData = new Array(prcLength);
    for (; stIndex < stLength; stIndex++) {
        if (!HiLowPoints.allPoints[stIndex] || hsfResults[0].Date >= HiLowPoints.allPoints[stIndex].Date) {
            break;
        }
        const yPrice = scale.ComputeY(hsfResults[0].Close);
        info = {
            Date: HiLowPoints.allPoints[stIndex].Date,
            yPrice: yPrice,
            xAxis: HiLowPoints.allPoints[stIndex].xAxis,
            yValue: hsfResults[0].Close
        };
        chartData[stIndex] = info;
    }
    stIndex = 0;
    let xAxis = lastNode;//priceData.HiLowPoints.allPoints[stIndex].xAxis;
    let x = 0;
    for (; x < prcLength && x < stLength; x++) {
        xAxis -= 4;
        if (xAxis < 0) {
            break;
        }
        if (isNaN(hsfResults[x].Close)) {
            continue;
        }
        const yPrice = scale.ComputeY(hsfResults[x].Close);
        const date = hsfResults[x].Date.getFullYear ? hsfResults[x].Date : new Date(parseInt(hsfResults[x].Date.substr(6)));
        if (stIndex >= stLength) {
            break;
        }
        let prDate = HiLowPoints.allPoints[stIndex].Date;
        while (prDate > date) {
            info = {
                Date: prDate,
                yPrice: yPrice,
                xAxis: xAxis,
                yValue: hsfResults[x].Close
            };
            xAxis -= 4;
            chartData[stIndex] = info;
            stIndex++;

            if (HiLowPoints.allPoints[stIndex]) {
                prDate = HiLowPoints.allPoints[stIndex].Date;
            }
            else {
                prDate = date;
            }
        }
        info = {
            Date: date,
            yPrice: yPrice,
            xAxis: xAxis,
            yValue: hsfResults[x].Close
        };
        chartData[stIndex] = info;
        stIndex++;
    }

    return chartData;
}
function initTechScale(graphType, indicatorsHeight) {
    const scale = new ArithmaticScale();
    scale.InitScale(-20, 100, indicatorsHeight, graphType, 2, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true, true);
    return scale;
}
function* PrepareData({ graphData, chartType, SettingDict }) {
    try {
        const _state = yield select(getIndicatorStates);
        const priceData = yield select(priceChartReducerselect);
        const { indicatorsHeight, startXPoint } = yield select(getDatagraphStates)
        SettingDict = _state.SettingDict
        graphData = _state.GraphData[chartType];
        yield call(processData, graphData, indicatorsHeight, chartType, SettingDict[chartType], startXPoint, priceData.HiLowPoints);
    }
    catch (error) {
        console.log("Error occurs in ORatingIndicatorSaga.js, PrepareData", error);
    }
}

function* processData(graphData, indicatorsHeight, chartType, SettingDict, startXPoint, HiLowPoints) {
    if (graphData) {
        const scale = initTechScale(GraphType.Weekly, indicatorsHeight);
        let DataSource;
        const indicatorVisuals = { [chartType]: [] };
        const indicatorLabels = { [chartType]: { labels: [] } };
       let LineColor, LineThickness;
        OneilRatingLineTypes.map((item) => {
            if (SettingDict[item]) {
                SettingDict[`${item}Color`].forEach((item) => {
                    LineColor = ThemeHelper.getThemedBrush(item.color);
                    LineThickness = item.weight;
                });
                DataSource = getTechChart(graphData[item], startXPoint, scale, HiLowPoints)
                indicatorVisuals[chartType].push({ key: item, DataSource, LineColor, LineThickness, Draggable: false, lineID: lineIDConst[item] });
                indicatorLabels[chartType].labels.push({ backgroundColor: LineColor, key: `${item}Label`, color: LineColor, value: labelConst[item] });
            }
            return null;
        })
        indicatorLabels[chartType].LabelMenu = { label: IndicatorLabelTranslateHelper[BlockType.TechIndicator], isTICustomModal: true, isShowSettingsDialog: true }
        yield put(updateIndicatorData(indicatorVisuals, indicatorLabels, chartType, scale));
    }
}

function* initIndicator() {
    try {
        const { indicatorResponse, isAllIndicatorDataApiInitiated} = yield select(getIndicatorStates);
        if((!isAllIndicatorDataApiInitiated) && indicatorResponse) {
            const { majorPeriodicity, viewsSettings, startXPoint, indicatorsHeight } = yield select(getDatagraphStates);
            const { HiLowPoints } = yield select(priceChartReducerselect);
            const SettingDict = {[BlockType.TechIndicator] : viewsSettings.TechChartSettings ? viewsSettings.TechChartSettings[majorPeriodicity] : {}}
            if (SettingDict[BlockType.TechIndicator].IsAvailable) {
                yield put(updateIndicatorGraphData(indicatorResponse, BlockType.TechIndicator));
                yield call(processData, indicatorResponse, indicatorsHeight,  BlockType.TechIndicator, SettingDict[BlockType.TechIndicator], startXPoint, HiLowPoints);
            }
            yield put(updateIndicatorSettings(SettingDict));
            TimeTrackingWindow.endTechIndicatorLoadEndTimeTracker()
        }
    }
    catch (error) {
        TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.TIPanel);
        TimeTrackingWindow.endTechIndicatorLoadEndTimeTracker();
        console.error("Error occurs in ORatingIndicatorSaga.js, initIndicator", error);
    }

}

function* toggleORatingLine({ lineType }){
    const { SettingDict, GraphData } = yield select(getIndicatorStates);
    SettingDict[BlockType.TechIndicator][lineType] = false;
    const { HiLowPoints } = yield select(priceChartReducerselect);
    const { indicatorsHeight, startXPoint } = yield select(getDatagraphStates);
    yield call(processData, GraphData[BlockType.TechIndicator], indicatorsHeight, BlockType.TechIndicator, SettingDict[BlockType.TechIndicator], startXPoint, HiLowPoints);
}
//===================================================

export function* watchProcessORatingIndicator() {
    yield takeLatest(ActionTypes.PROCESS_INDICATOR, initIndicator)
}

export function* watchInitORatingIndicator() {
    yield takeLatest(ActionTypes.INIT_INDICATOR, initIndicator)
}

export function* watchPrepareORatingIndicatorData() {
    yield takeLatest(ActionTypes.PREPARE_ORating_INDICATOR_DATA, PrepareData);
}

export function* watchToggleORatingLine() {
    yield takeLatest(ActionTypes.TOGGLE_ORATING_LINE, toggleORatingLine)
}