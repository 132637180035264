import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { SettingsConstants } from "SettingsConstants";
import { TranslateHelper } from "../../../Utils/TranslateHelper";
const { ActionTypes } = RiPanelConstants;

const initialState = {
    loading: true,
    loadingMessage: TranslateHelper["Loading"],
    errorMessage: TranslateHelper["No_Data_Available"],
    isNoDataAvailable: false,
    sectorWgtList: [],
    asOfDate: null,
    scale: 1,
    legendWidth: 95,
    paddingScrollBarWidth: 18,
    symbolType: 4,
    barWidth: 20,
    topScaleHeight: 30,
    chartHeight: 510,
    chartWidth: 300,
    barMargin: { left: 10, right: 10 },
    isSymbolFund: false
}

const RiPanelAllocationInfo = (state = initialState, action) => {
    switch (action.type) {
        case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:

            return {
                ...initialState,
                loadingMessage: TranslateHelper["Loading"],
                errorMessage: TranslateHelper["No_Data_Available"]
            }
        case ActionTypes.ALLOCATION_INFO_SET_DEFAULT_STATE: {
            return { ...state, ...action.newState }
        }

        case ActionTypes.ALLOCATION_INFO_SET_DATA: {
            return { ...state, ...action.newState }
        }

        case ActionTypes.ALLOCATION_INFO_CHANGE_DIMENSIONS: {
            state.chartWidth = action.dim.width !== 0 ? action.dim.width : state.chartWidth
            return { ...state }
        }

        default: return state
    }
}

export default RiPanelAllocationInfo