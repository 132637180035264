import ColorPicker from "../../../../../../../RayCustomControls/ColorPicker/ColorPicker.jsx";
import { connect } from "react-redux";
import { DialogHOC } from "./DialogHOC.jsx";
import React from "react";
import { cancelSTPSettingsDialog, changeSTPDialogTab, changeSTPHiLowStatus, changeSTPPriceStatus, handleSTPChangeColor, restoreSTPSettingsDialog, saveSTPSettingsDialog } from "../../../../../../../Actions/PricePanelActions.js";
import { Modal, Tabs, Tab  } from "react-bootstrap";

const colorPickerWidth = 101;
function StreetTargetPriceDialog (props) {
    return (
            <Modal.Body className="medium-normal">
                <div className="oneil-rating-box">
                    <Tabs onSelect={props.changeSTPDialogTab} activeKey={props.selectedPeriodicity} id="controlled-tab-example">
                        {props.STPDialogSettings.map((tabitem) => (
                            <Tab className="tabItem" key={tabitem.periodicity} eventKey={tabitem.periodicity} title={tabitem.header}>
                                <div className="oneil-rate-check price-lines price-targets">
                                    <div className="row target-setting">
                                        <div className="col-sm-12">
                                            <h5 className="cursor-default">Choose line display settings.</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-10 col-sm-push-1 color-n-weight">
                                            <div className="col-sm-6">
                                            <span className="form-head cursor-default">Color</span>
                                                <div className="form-field">
                                                    <ColorPicker width={colorPickerWidth} color={tabitem.lineColor} onChangeComplete={props.handleSTPChangeColor} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row target-setting">
                                        <div className="col-sm-12">
                                            <input className="check-style" type="checkbox" name="high_low_estimate" id="high_low_estimate" value={tabitem.showHiLowEstimate} checked={tabitem.showHiLowEstimate} onChange={props.changeSTPHiLowStatus}/>
                                            <label htmlFor="high_low_estimate">Display High/Low Price Target</label>
                                        </div>
                                        <div className="col-sm-12">
                                            <input className="check-style" type="checkbox" name="target_target_Zones" id="target_target_Zones" value={tabitem.showTargetPriceZones} checked={tabitem.showTargetPriceZones} onChange={props.changeSTPPriceStatus}/>
                                            <label htmlFor="target_target_Zones">Display Price Target Zones</label>
                                        </div>
                                    </div>
                                    <div className="rating-default stp-restore">
                                        <span className="link-restore" onClick={props.restoreSTPSettingsDialog}>{"Restore Defaults"}</span>
                                    </div>
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            </Modal.Body>
    );
}
const mapStateToProps = ({ DatagraphReducers })=>{
    const { isSTPDialogOpen, headerClass, HeaderText, STPDialogSettings, selectedPeriodicity,  } = DatagraphReducers.StreetTargetPriceReducer;
    return { headerClass, HeaderText, showSettingDialog: isSTPDialogOpen, STPDialogSettings, selectedPeriodicity};
}
const mapDispatchToProps = (dispatch)=>({
    saveLineDialog: ()=> dispatch(saveSTPSettingsDialog()),
    cancelLineDialog: ()=> dispatch(cancelSTPSettingsDialog()),
    restoreSTPSettingsDialog: ()=> dispatch(restoreSTPSettingsDialog()),
    changeSTPDialogTab: (periodicity)=> dispatch(changeSTPDialogTab(periodicity)),
    handleSTPChangeColor: (color)=> dispatch(handleSTPChangeColor(color)),
    changeSTPHiLowStatus: ()=> dispatch(changeSTPHiLowStatus()),
    changeSTPPriceStatus: ()=> dispatch(changeSTPPriceStatus()),
})
export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(StreetTargetPriceDialog))
