import DatagraphStore from "../../../../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import ExtremeDataValue from "ExtremeDataValue";
import StringUtil from "StringUtil";

export default class CEFDetailItemData {
    constructor(data, targetCurrencyCode, settings) {
        this.targetCurrencyCode = targetCurrencyCode;
        this.settings = settings;
        // this.positiveBrush = ThemeHelper.getThemedBrush("positiveDataText");
        // this.negativeBrush = ThemeHelper.getThemedBrush("negativeDataText");
        this.setSummaryBlockFundDetailsItem(data);
    }

    setSummaryBlockFundDetailsItem(data) {
        this.dataSource = [];
        const fundDetailList = data.sumBlock;
        const headerDataList = data.headerData;
        const len = fundDetailList.length;
        for (let i = 0; i < len; i++) {
            const fundDetailItemData = fundDetailList[i];
            const itemHeader = headerDataList.find((item) => item.headerId === fundDetailItemData.headerId);
            if (!itemHeader) {
                continue;
            }
            if (itemHeader.toggleId === 0 || fundDetailItemData.headerId === 20 || fundDetailItemData.headerId === 28) {
                const fundDetailItem = this.getFundDetailItem(itemHeader, fundDetailItemData);
                if (fundDetailItemData.headerId === 20) {
                    fundDetailItem.header = `${fundDetailItem.header} (Absolute)`;
                    fundDetailItem.color = (!fundDetailItem.doubleValue || fundDetailItem.doubleValue >= 0) ? this.positiveBrush : this.negativeBrush;
                    fundDetailItem.isPositive = (!fundDetailItem.doubleValue || fundDetailItem.doubleValue >= 0);
                    fundDetailItem.isUseCusColor = true;
                }
                if (fundDetailItemData.headerId === 28) {
                    fundDetailItem.isPriceRelated = true;
                    fundDetailItem.color = (!fundDetailItem.doubleValue || fundDetailItem.doubleValue >= 0) ? this.positiveBrush : this.negativeBrush;
                    fundDetailItem.isPositive = (!fundDetailItem.doubleValue || fundDetailItem.doubleValue >= 0);
                    fundDetailItem.isUseCusColor = true;
                }
                if (fundDetailItem !== null) {
                    //fundDetailItem.header = GetLangHeaderString(fundDetailItem.Header);
                    this.dataSource.push(fundDetailItem);
                }
            } else {
                const toggleDetailItem = {};
                if (itemHeader.isPrimary) {
                    toggleDetailItem.defualtItemData = this.getFundDetailItem(itemHeader, fundDetailItemData);
                    toggleDetailItem.toggleItemData = [];
                } else {
                    continue;
                }
                toggleDetailItem.activeItemData = this.settings.isYtdAbsolute ? toggleDetailItem.defualtItemData : toggleDetailItem.toggleItemData;
                //toggleDetailItem.activeItemData.header = GetLangHeaderString(toggleDetailItem.ActiveItemData.Header);
                //toggleDetailItem.activeItemData.subHeaderText = GetLangHeaderString(toggleDetailItem.ActiveItemData.SubHeaderText);
                this.dataSource.push(toggleDetailItem);
            }
        }
    }

    getFundDetailItem(itemHeader, itemData) {
        const fundDetailItem = {
            header: itemHeader.displayName,
            headerId: itemHeader.headerId
        };
        if (itemHeader.subHeaderDisplayName) {
            fundDetailItem.subHeaderText = StringUtil.format("({0})", [itemHeader.subHeaderDisplayName]);
        }
        if (!this.hasData(itemData)) {
            if (!StringUtil.isEmpty(itemHeader.nullDisplay)) {
                fundDetailItem.value = itemHeader.nullDisplay;
            } else {
                return null;
            }
        } else {
            if (itemData.doubleValue) {
                fundDetailItem.doubleValue = parseFloat(itemData.doubleValue);
                if (typeof (itemHeader.digitsAfterDecimal) !== "undefined") {
                    let prefix = "";
                    let convfactor = 1;
                    if (itemHeader.isCurrencyCodePrefixed) {
                        prefix = StringUtil.isEmpty(this.targetCurrencyCode) ? "$" : this.targetCurrencyCode;
                        if (this.targetCurrencyCode == "GBX") {
                            convfactor = DatagraphStore.getESCurrencyFactor("GBP") * 100;
                        } else if (this.targetCurrencyCode == "ZAX") {
                            convfactor = DatagraphStore.getESCurrencyFactor("ZAR") * 100;
                        } else {
                            convfactor = DatagraphStore.getESCurrencyFactor(this.targetCurrencyCode);
                        }
                        // if (ConsoleModel)
                        //     convfactor = ConsoleModel.States.GetCurrencyFactor(this.targetCurrencyCode);
                    }
                    if (itemHeader.isDisplayNameAbbr) {
                        fundDetailItem.value = ExtremeDataValue.getAbbrivatedString(parseFloat(itemData.doubleValue) * convfactor, itemHeader.digitsAfterDecimal, prefix, "###");
                    } else if (itemHeader.isPerc) {
                        const dblValue = parseFloat(itemData.doubleValue);
                        fundDetailItem.value = itemHeader.isPriceRelated ?
                            `${ExtremeDataValue.getPositiveNegativeString(dblValue, itemHeader.digitsAfterDecimal)}%` :
                            ExtremeDataValue.getPercentageString(dblValue, itemHeader.digitsAfterDecimal);
                        fundDetailItem.isPriceRelated = itemHeader.isPriceRelated;
                        fundDetailItem.isPerc = true;
                    } else {
                        fundDetailItem.value = ExtremeDataValue.decimalFormat(parseFloat(itemData.doubleValue), itemHeader.digitsAfterDecimal, prefix);
                    }
                }
            } else if (itemData.stringValue) {
                let prefix = "";
                if (itemHeader.isCurrencyCodePrefixed) {
                    prefix = StringUtil.isEmpty(this.targetCurrencyCode) ? "$" : this.targetCurrencyCode;
                }
                fundDetailItem.value = prefix + itemData.stringValue.trim();
            } else if (itemData.longValue) {
                fundDetailItem.doubleValue = itemData.longValue.toNumber();
                if (typeof (itemHeader.digitsAfterDecimal) !== "undefined") {
                    let prefix = "";
                    let convfactor = 1;
                    if (itemHeader.isCurrencyCodePrefixed) {
                        prefix = StringUtil.isEmpty(this.targetCurrencyCode) ? "$" : this.targetCurrencyCode;
                        if (this.targetCurrencyCode == "GBX") {
                            convfactor = DatagraphStore.getESCurrencyFactor("GBP") * 100;
                        } else if (this.targetCurrencyCode == "ZAX") {
                            convfactor = DatagraphStore.getESCurrencyFactor("ZAR") * 100;
                        } else {
                            convfactor = DatagraphStore.getESCurrencyFactor(this.targetCurrencyCode);
                        }
                        // if (ConsoleModel)
                        //     convfactor = ConsoleModel.States.GetCurrencyFactor(this.targetCurrencyCode);
                    }
                    if (itemHeader.isDisplayNameAbbr) {
                        fundDetailItem.value = ExtremeDataValue.getAbbrivatedString(itemData.longValue.toNumber() * convfactor, itemHeader.digitsAfterDecimal, prefix, "###");
                    } else if (itemHeader.isPerc) {
                        fundDetailItem.value = ExtremeDataValue.getPercentageString(itemData.longValue.toNumber(), itemHeader.digitsAfterDecimal);
                    } else {
                        fundDetailItem.value = ExtremeDataValue.decimalFormat(itemData.longValue.toNumber(), itemHeader.digitsAfterDecimal, prefix);
                    }
                }
            } else {
                return null;
            }
        }
        return fundDetailItem;
    }

    hasData(itemData) {
        return itemData.doubleValue || itemData.stringValue || itemData.longValue;
    }
}