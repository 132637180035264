import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import LocalizationStore from 'LocalizationStore';
import { Button } from "react-bootstrap";
import { setSelectedRatingsOption, setIsGlobalRatingSelected, setIsCountryRatingSelected, updateRatingFilterTitle } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import CompareTabType, { CompareMenuType } from "CompareTabType";

class SettingsWindow extends PureComponent {

  handleOptionChange = (e, valueTxt) => {
    let selectedOptions = valueTxt ? valueTxt : e.target.value;

    if (selectedOptions == "global") {
      this.props.setIsGlobalRatingSelected(true);
      this.props.setIsCountryRatingSelected(false);
    }
    else {
      this.props.setIsCountryRatingSelected(true);
      this.props.setIsGlobalRatingSelected(false);
    }

    this.selectedRatingsOption = selectedOptions;

    this.props.updateRatingFilterTitle();
  };

  render() {
    this.selectedRatingsOption = this.props.isGlobalRatingSelected ? "global" : "country"
    return (
      <div className="custom-panel-block rating-filter-setting"
        style={{
          ...this.props.style
          // ,
          // position: 'relative',
          // zIndex: 1001,
          // width: "70%",
          // padding: 8,
          // left: "15%"
        }}
      >
        <div className="panel panel-default">
          <div className="panel-heading">
            <span className="panel-title">{LocalizationStore.getTranslatedData("CCG_RatingsSettings", "Ratings Settings")}</span>
          </div>
          <div className="panel-body">
            <div className="modal-casket">
              <div className="radio-box">
                <input type="radio" id="rg-global" name="rg-ratings-filter" className="vMiddel" value="global" checked={this.selectedRatingsOption === 'global'} onChange={this.handleOptionChange} />
                <label htmlFor="rg-global" className="cursor-default" onClick={(e) => this.handleOptionChange(e, "global")}>{LocalizationStore.getTranslatedData("CCG_GlobalRatings", "Global Ratings")}</label>
              </div>
              <div className="radio-box">
                <input type="radio" id="rg-country" name="rg-ratings-filter" className="vMiddel" value="country" checked={this.selectedRatingsOption === 'country'} onChange={this.handleOptionChange} />
                <label htmlFor="rg-country" className="cursor-default" onClick={(e) => this.handleOptionChange(e, "country")}>{LocalizationStore.getTranslatedData("CCG_CountryRatings", "Country Ratings")}</label>
              </div>


              <p>{LocalizationStore.getTranslatedData("CCG_SettingMsg", "This setting applies to Datagraph, EPS and Relative Strength Rating.")}</p>
              <div className="btn-box">
                <Button className="btn btn-secondary btn btn-sm " onClick={this.props.handleClickToggle}>{LocalizationStore.getTranslatedData("CCG_Close", "Close")}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const liqFilterData = state.comparisonAndRotationMenuPanel.LiqAndRatingFilterReducer;

  if (selectedTab === CompareTabType.ComparisonGraph) {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.ComparisonMenuTab;
    const { isGlobalRatingSelected } = selectedMenuTab == CompareMenuType.Symbols ? liqFilterData.ComparisonMenuStates.Symbols : liqFilterData.ComparisonMenuStates.Groups;

    return { isGlobalRatingSelected };
  }
  else {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    const { isGlobalRatingSelected } = selectedMenuTab == CompareMenuType.Symbols ? liqFilterData.RotationMenuStates.Symbols : liqFilterData.RotationMenuStates.Groups;

    return { isGlobalRatingSelected };
  }
};


const mapDispatchToProps = (dispatch) => ({
  selectedRatingsOption: (selectedOptions) => dispatch(setSelectedRatingsOption(selectedOptions)),
  setIsGlobalRatingSelected: (value) => dispatch(setIsGlobalRatingSelected(value)),
  setIsCountryRatingSelected: (value) => dispatch(setIsCountryRatingSelected(value)),
  updateRatingFilterTitle: () => dispatch(updateRatingFilterTitle())
})


export default connect(mapStateToProps, mapDispatchToProps)(SettingsWindow);
