import React, { Component } from "react";
import { connect } from 'react-redux';

import ColorRowItem from "./ColorRowItem";
import * as ColorConstants from "../../Constants/AppColorConstants";
import ThemeType from "../../Constants/ThemeTypeConstants";
import LangType from "../../Constants/LangType";
import { onDarkPosColorChanged, onDarkNegColorChanged, onInitDarkThemeColor, onInitLightThemeColor, onRestoreDarkColor, onRestoreLightColor } from "../../Actions/AppColorActions";
import AppColorStore from "../../Stores/AppColor/AppColorStore";
import UserInfoUtil from "UserInfoUtil";
import SettingsStore from "SettingsStore";

class ColorSelection extends Component {
    constructor(props) {
        super(props);

        let consoleSetting = SettingsStore.getConsoleSettings();
        // let  locale = navigator.language || navigator.browserLanguage;

        this.onCallback = this.onCallback.bind(this);

        //Show color settings on basis of entitlement other than the displaying language.
        let lang = UserInfoUtil.hasChinaEntitlement() ? LangType.ZH_CN : LangType.RAY_DEFAULT;

        this.state = {
            theme: this.props.theme,
            lang: lang,
            darkPosColor: consoleSetting.AppColorSettings.darkPosColor,
            darkNegColor: consoleSetting.AppColorSettings.darkNegColor,
            lightPosColor: consoleSetting.AppColorSettings.lightPosColor,
            lightNegColor: consoleSetting.AppColorSettings.lightNegColor,

            darkPosHoverColor: consoleSetting.AppColorSettings.darkPosHoverColor,
            darkNegHoverColor: consoleSetting.AppColorSettings.darkNegHoverColor,
            lightPosHoverColor: consoleSetting.AppColorSettings.lightPosHoverColor,
            lightNegHoverColor: consoleSetting.AppColorSettings.lightNegHoverColor,

            darkPosDTColor: consoleSetting.AppColorSettings.darkPosDTColor,
            darkNegDTColor: consoleSetting.AppColorSettings.darkNegDTColor,
            lightPosDTColor: consoleSetting.AppColorSettings.lightPosDTColor,
            lightNegDTColor: consoleSetting.AppColorSettings.lightNegDTColor,

            darkPosDTHoverColor: consoleSetting.AppColorSettings.darkPosDTHoverColor,
            darkNegDTHoverColor: consoleSetting.AppColorSettings.darkNegDTHoverColor,
            lightPosDTHoverColor: consoleSetting.AppColorSettings.lightPosDTHoverColor,
            lightNegDTHoverColor: consoleSetting.AppColorSettings.lightNegDTHoverColor,
        }

    }

    componentDidMount () {
        switch (this.props.theme) {
            case ThemeType.Dark:
                this.props.onInitDarkThemeColor(this.state.darkPosColor,
                    this.state.darkNegColor,
                    this.state.darkPosHoverColor,
                    this.state.darkNegHoverColor,
                    this.state.darkPosDTColor,
                    this.state.darkNegDTColor,
                    this.state.darkPosDTHoverColor,
                    this.state.darkNegDTHoverColor,
                    this.state.theme,
                    this.state.lang);
                break;
            case ThemeType.Light:
                this.props.onInitLightThemeColor(this.state.lightPosColor,
                    this.state.lightNegColor,
                    this.state.lightPosHoverColor,
                    this.state.lightNegHoverColor,
                    this.state.lightPosDTColor,
                    this.state.lightNegDTColor,
                    this.state.lightPosDTHoverColor,
                    this.state.lightNegDTHoverColor,
                    this.state.theme,
                    this.state.lang);
                break;
            default:
                break;
        }

        AppColorStore.addChangeListener(this.onCallback);
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.onCallback);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.appColor !== nextProps.appColor) {
            switch (this.state.theme) {
                case ThemeType.Dark:
                    this.setState({
                        darkPosColor: nextProps.darkPosColor,
                        darkNegColor: nextProps.darkNegColor,
                        darkPosHoverColor: nextProps.darkPosHoverColor,
                        darkNegHoverColor: nextProps.darkNegHoverColor,
                    });
                    break;
                case ThemeType.Light:
                    this.setState({
                        lightPosColor: nextProps.lightPosColor,
                        lightNegColor: nextProps.lightNegColor,
                        lightPosHoverColor: nextProps.lightPosHoverColor,
                        lightNegHoverColor: nextProps.lightNegHoverColor,
                    });
                    break;
                default:
                    break;
            }
        }
    }

    onCallback() {

        let defaultPosColor = "";
        let defaultNegColor = "";
        let defaultPosHoverColor = "";
        let defaultNegHoverColor = "";

        //default positive and negative colors in dark & light themes are same under ZH_CN.
        if(this.state.lang === LangType.ZH_CN){
            defaultPosColor = ColorConstants.DefaultColors.Red;
            defaultNegColor = ColorConstants.DefaultColors.Green;
            defaultPosHoverColor = ColorConstants.DefaultHoverColors.Red;
            defaultNegHoverColor = ColorConstants.DefaultHoverColors.Green;
        }

        switch (AppColorStore.getAction()) {
            case ColorConstants.ActionTypes.RESTORE_DARK_COLOR:
                if (this.state.lang === LangType.RAY_DEFAULT) {
                    defaultPosColor = ColorConstants.DefaultColors.OtherDarkBlue;
                    defaultNegColor = ColorConstants.DefaultColors.OtherDarkMagenta;
                    defaultPosHoverColor = ColorConstants.DefaultHoverColors.Blue;
                    defaultNegHoverColor = ColorConstants.DefaultHoverColors.Magenta;
                }  

                this.props.onRestoreDarkColor(defaultPosColor,
                    defaultPosHoverColor,
                    defaultNegColor,
                    defaultNegHoverColor);
                break;
            case ColorConstants.ActionTypes.RESTORE_LIGHT_COLOR:
                if (this.state.lang === LangType.RAY_DEFAULT) {
                    defaultPosColor = ColorConstants.DefaultColors.OtherLightBlue;
                    defaultNegColor = ColorConstants.DefaultColors.OtherLightMagenta;
                    defaultPosHoverColor = ColorConstants.DefaultHoverColors.Blue;
                    defaultNegHoverColor = ColorConstants.DefaultHoverColors.Magenta;
                }

                this.props.onRestoreLightColor(defaultPosColor,
                    defaultPosHoverColor,
                    defaultNegColor,
                    defaultNegHoverColor);
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <React.Fragment>
                <ColorRowItem colorType={ColorConstants.PosOrNeg.Positive} theme={this.state.theme} />
                <ColorRowItem colorType={ColorConstants.PosOrNeg.Negative} theme={this.state.theme} />
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        appColor: state.appColor
    }
}
const actionCreater = { onDarkPosColorChanged, onDarkNegColorChanged, onInitDarkThemeColor, onInitLightThemeColor, onRestoreDarkColor, onRestoreLightColor }
ColorSelection = connect(mapStateToProps, actionCreater)(ColorSelection);
export default ColorSelection
