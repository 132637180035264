import PropTypes from 'prop-types';
import React from "react";
import ChartVisual from "./ChartVisual.jsx";
import Pointer from "Pointer";
import ThemeHelper from "ThemeHelper";
import LocalizationStore from 'LocalizationStore';
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import SymbolType from "../../../Constants/SymbolType.js";
//import ArrayUtil from "ArrayUtil";
import Measure from "react-measure";
// import StockEPSAnimationStore from '../../../Stores/NavModules/NavDataGraph/TabDataGraph/Stock/PriceChart/EPSAnimation/EPSAnimationStore.js';
// import IndexEPSAnimationStore from '../../../Stores/NavModules/NavDataGraph/TabDataGraph/Index/PriceChart/EPSAnimation/EPSAnimationStore.js';
import TimeTrackingWindow from "TimeTrackingWindow";
import BrowserUtil from '../../../Utils/BrowserUtil.js';
import { dispatch } from '../../../Redux/dispatch.js';
import { switchFundametalLineLabel, updateContextHit, updateFundamentalLineMultiplier, updateFundamentalLineSelectPerShare } from '../../../Actions/PricePanelActions.js';

export default class QuarterlyLine extends ChartVisual {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {},
      dataSource: props.DataSource,
      source: props.DataSource && props.DataSource.filter((obj) => obj.dotted == 1),
      x: 0,
      y: 0,
      dragging: false,
      draggable: props.Draggable,
      boxNodes: props.BoxNodes,
      surprise: props.SurpriseNodes,
      shouldUpdate: true,
      lineColor: props.LineColor,
      lineThickness: props.LineThickness,
      pointerEvents: "visible",
      opacity: 1,
      pointerVisibility: props.pointerVisibility ? props.pointerVisibility : "collapse",
      zIndex: 0,
      pointerY: props.pointerYValue,
      type: "EPS",
      showBox: false,
      showSurpriseBox: false,
      frontColor: props.frontColor,
      symbolType: props.SymbolType,
      refresh: true,
      showR:  props.showR
    };
  }
  componentDidMount() {
    this.RayChartContainer = document.getElementById("rayChartContainer");
  }
  handleResize(dimensions) {
    this.setState({ dimensions: dimensions, shouldUpdate: true});
  }

  UNSAFE_componentWillMount() {
    if (this.props.type) {
      this.state.type = this.props.type;
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.DataSource != null) {
      this.state.dataSource = nextprops.DataSource;
      this.state.source = nextprops.DataSource.filter((obj) => obj.dotted == 1);
      this.state.lineColor = nextprops.LineColor;
      this.state.lineThickness = nextprops.LineThickness;
      this.state.frontColor = nextprops.frontColor ? nextprops.frontColor : "white";
      this.state.showPointer = nextprops.showPointer;
      this.state.surprise = nextprops.SurpriseNodes;
      this.state.pointerVisibility = nextprops.pointerVisibility ? nextprops.pointerVisibility : "collapse";
      if(this.props.pointerYValue !== nextprops.pointerYValue){
        this.state.pointerY = nextprops.pointerYValue;
      }
      if (nextprops.zIndex) {
        this.state.zIndex = nextprops.zIndex;
      }
      // if (nextprops.type) {
      //   this.state.type = nextprops.type;
      // }
      if (nextprops.Draggable != null) {
        this.state.draggable = nextprops.Draggable;
        if (nextprops.Draggable == 0)
          this.state.pointerEvents = "auto";
        else
          this.state.pointerEvents = "visible";
      }
      if (nextprops.Opacity != null) {
        this.state.opacity = nextprops.Opacity;
      }
      if (nextprops.showR !== undefined) {
          this.state.showR = nextprops.showR;
      } else {
          this.state.showR = true;
      }
      if ((this.state.shouldUpdate || nextprops.Scale !== this.props.Scale || nextprops.DataSource.isDataChange) &&  BrowserUtil.getBrowserName() === "Safari" ) {
        const refresh = !this.state.refresh;
        this.state.refresh = refresh;
        this.state.shouldUpdate = false;
        nextprops.DataSource.isDataChange = false;
      }
      this.setState(this.state);
    }
  }
  setQuoteReceive(pauseRT) {
    // if(this.state.symbolType === SymbolType.INDEX){
    //   IndexPriceChartStore.setQuoteReceive(pauseRT);
    // }else{
    //   PriceChartStore.setQuoteReceive(pauseRT);
    // }
  }
  updateMultiplier(currPt, prevPt) {
    dispatch(updateFundamentalLineMultiplier(currPt, prevPt, this.state.type));
  }
  updatePointer(contentL, contentR, yValue) {
    if (this.pointer && !this.state.dragging) {
      this.pointer.updatePointer(contentL, contentR, yValue);
      this.state.pointerY = yValue;
      this.state.pointerText = contentL;
    }
  }
  handleContextMenu(e) {
    dispatch(updateContextHit({ lineID: e.currentTarget.id }))
    if(this.props.onOpenFundamentalLineMenu){
      this.props.onOpenFundamentalLineMenu(e); 
    }
  }
  onContextMenuClick(e) {
    e.preventDefault();
    return false;
  }
  handleMouseMove(e) {
    if (this.state.dragging && this.state.draggable) {
      this.isDrag = 1;
      if (this.state.dataSource.length < 1) {
        return;
      }
      e.preventDefault();
      const len = this.state.dataSource.length;
      const xMove = this.state.y - e.pageY;
      for (let index = 0; index < len; index++) {
        if (this.state.dataSource[index]) {
          this.state.dataSource[index].yPrice = this.state.dataSource[index].yPrice - xMove;
          this.state.dataSource[index].y1Line = this.state.dataSource[index].y1Line - xMove;
          if (this.state.dataSource[index].yRep || this.state.dataSource[index].yRep === 0) {
            this.state.dataSource[index].yRep = this.state.dataSource[index].yRep - xMove;
          }
          this.state.dataSource[index].yPoint = this.state.dataSource[index].yPoint - xMove;
        }
      }
      if (this.props.isPlaying) {
        dispatch(updateFundamentalLineMultiplier(e.pageY, this.state.y,this.state.type))
      }  
      this.state.pointerY = this.state.pointerY - xMove;
      this.state.y = e.pageY;
      if (this.pointer)
        this.pointer.movePointer(this.state.pointerY);
      this.setState(this.state);
    }
  }
  handleMouseLeave() {
    this.state.showBox = false;
    this.state.lineID = null;
    this.setState(this.state);
    if (this.state.dragging && this.state.draggable){
      return;
    }
    dispatch(switchFundametalLineLabel('', false))
  }
  handleMouseOver(e) {
    if (this.state.dragging && this.state.draggable){
      return;
    }
    const id = e.currentTarget.id;
    if(id){
      dispatch(updateFundamentalLineSelectPerShare(id, false))
    }
    if (!this.main){ 
      return;
    }
    const dimensions = this.main.getBoundingClientRect();
    const chartWidth = dimensions.right;
    const chartHeight = dimensions.bottom;

    if (this.props.isToolTip && this.state.showR) {
      this.state.lineID = id;
      this.state.lineTitle = this.getTitle(id);
      const titleLen = Math.max(this.state.lineTitle.length * 8, 80);
      const xAxis = (chartWidth - e.clientX) < titleLen ? e.clientX - titleLen : e.clientX;
      const yAxis = (chartHeight - e.clientY) < 20 ? e.clientY - 40 : e.clientY;
      this.state.showBox = true;
      this.state.screenX = xAxis - dimensions.left + 10;
      this.state.screenY = yAxis - dimensions.top + 10;
      this.setState(this.state);
    }
  }
  handleSurpriseMouseOver(e) {
    if (!this.main) return;
    if (this.props.isToolTip) {
      const lineData = this.state.dataSource.filter((obj) =>
        obj.yRep > 0 && obj.ShowRpt && (obj.Surprise !== null || obj.SurpriseDiff)
      );
      const dimensions = this.main.getBoundingClientRect();
      const chartWidth = dimensions.right;
      const chartHeight = dimensions.bottom;
      const xAxis = (chartWidth - e.clientX) < 140 ? e.clientX - 140 : e.clientX;
      const yAxis = (chartHeight - e.clientY) < 20 ? e.clientY - 40 : e.clientY;
      const xMAxis = e.clientX - dimensions.left;
      this.state.boxScreenX = xAxis - dimensions.left + 10;
      this.state.boxScreenY = yAxis - dimensions.top + 10;
      for (var x = 0; x < lineData.length; x++) {
        if (xMAxis < lineData[x].xRep + 5 && xMAxis > lineData[x].xRep - 5) {
          lineData[x].Surprise != null &&
            (this.state.surpriseValue = lineData[x].Surprise > 0
              ? "+" + lineData[x].Surprise.toFixed(2)
              : lineData[x].Surprise.toFixed(2));
          this.state.surpriseDiff = lineData[x].SurpriseDiff > 0
            ? "+" + lineData[x].SurpriseDiff.toFixed(1) + "%"
            : lineData[x].SurpriseDiff.toFixed(1) + "%";
          this.setState(this.state);
          this.state.showSurpriseBox = true;
          break;
        }
      }
    }
  }
  handleSurpriseMouseLeave() {
    this.state.showSurpriseBox = false;
    this.state.surpriseValue = null;
    this.state.surpriseDiff = null;
    this.setState(this.state);
  }
  rectPath(x1, y1, x2, y2) {
    return 'M ' + x1 + ',' + y1 + ' ' + x2 + ',' + y1 + ' ' + x2 + ', ' + y2 + ' ' + x1 + ',' + y2 + ' ';
  }
  circlePath(cx, cy, r) {
    return 'M ' + cx + ' ' + cy + ' m -' + r + ', 0 a ' + r + ',' + r + ' 0 1,0 ' + (r * 2) + ',0 a ' + r + ',' + r + ' 0 1,0 -' + (r * 2) + ',0 ';
  }
  arrowPath(x1, y, x2, y2, x3) {
    return 'M ' + x1 + ',' + y + ' ' + x2 + ',' + y2 + ' ' + x3 + ', ' + y + ' ' + x1 + ',' + y + ' ';
  }
  arrowDownPath(x1, y, x2, x3, y2) {
    return 'M ' + x1 + ',' + y + ' ' + x2 + ',' + y + ' ' + x3 + ',' + y2 + ' ' + x1 + ',' + y + ' ';
  }
  PrepareBoxes2(insource) {
    let source = insource.filter((obj) => obj.ShowQtr == 1 && obj.Display == 1);
    let prcLength = source.length;
    let d = '';
    var j = 0;
    for (; j < prcLength; j++) {
      let linePoint = source[j];
      if (linePoint && !isNaN(linePoint.yPoint)) {
        d += this.rectPath(linePoint.xPoint - 2.5, (linePoint.yPoint - 2.5).toFixed(0), linePoint.xPoint + 2.5, (linePoint.yPoint + 2.5).toFixed(0));
      }
    }
    let linePoint = source[j - 1];
    if (linePoint && !isNaN(linePoint.y1Line)) {
      d += this.rectPath(linePoint.x1Line - 2.5, (linePoint.y1Line - 2.5).toFixed(0), linePoint.x1Line + 2.5, (linePoint.y1Line + 2.5).toFixed(0));
    }
    return d;
  }
  PrepareElipses2(insource) {
    let source = insource.filter((obj) => obj.ShowQtr == 1 && obj.Display == 1);
    let prcLength = source.length;
    let d = '';
    var j = 0;
    for (; j < prcLength; j++) {
      let linePoint = source[j];
      if (linePoint && !isNaN(linePoint.yPoint)) {
        d += this.circlePath(linePoint.xPoint, linePoint.yPoint.toFixed(0), 2.5);
      }
    }
    let linePoint = source[j - 1];
    if (linePoint && !isNaN(linePoint.y1Line)) {
      d += this.circlePath(linePoint.x1Line, linePoint.y1Line.toFixed(0), 2.5);
    }
    return d;
  }
  prepareDottedLines2(insource) {
    let lineData = insource.filter((obj) => obj.dotted == 0);
    if (lineData === undefined || lineData.length < 1) {
      return;
    }
    let prcLength = lineData.length;
    let d = '';

    for (var j = 0; j < prcLength; j++) {
      let linePoint = lineData[j];
      if (linePoint && !isNaN(linePoint.yPrice)) {
        d += "M " + linePoint.xAxis + " " + linePoint.yPrice.toFixed(0) + " L " + linePoint.x1Line + " " + linePoint.y1Line.toFixed(0) + " ";
      }
    }
    return d;
  }
  prepareSolidLines2(insource) {
    let lineData = insource.filter((obj) => obj.dotted == 1);
    if (lineData === undefined || lineData.length < 1) {
      return;
    }
    let prcLength = lineData.length;
    let d = '';

    for (var j = 0; j < prcLength; j++) {
      let linePoint = lineData[j];
      if (linePoint && !isNaN(linePoint.yPrice)) {
        d += "M " + linePoint.xAxis + " " + linePoint.yPrice.toFixed(0) + " L " + linePoint.x1Line + " " + linePoint.y1Line.toFixed(0) + " ";
      }
    }
    return d;

    // var f = lineData[0];
    // let d = [("M " + f.xAxis + " " + f.yPrice.toFixed(0))];
    // let collector = lineData.map(function (obj) {
    //   let xNext = obj.xAxis;
    //   let yNext = obj.yPrice.toFixed(0);
    //   return `L ${xNext} ${yNext}`;
    // });

    // return d.concat(collector).join(" ");
  }
  PrepareReportDates2(insource) {
    let lineData = insource.filter((obj) => obj.yRep > 0 && obj.ShowRpt);
    let prcLength = lineData.length;
    if (prcLength <= 0) return '';
    let d = '';

    for (var j = 0; j < prcLength; j++) {
      let linePoint = lineData[j];
      if (linePoint && !isNaN(linePoint.yRep) && linePoint.ShowRpt === 1) {
        d += "M " + linePoint.xRep + " " + (linePoint.yRep - 6.0).toFixed(0) + " L " + linePoint.xRep + " " + (linePoint.yRep + 6.0).toFixed(0) + " ";
      }
    }
    return d;
  }
  PrepareArrowDatas2(insource) {
    let lineData = insource.filter((obj) => obj.yRep > 0 && obj.ShowRpt && (obj.Surprise > 0 || (obj.Surprise == null && obj.SurpriseDiff > 0)));
    let prcLength = lineData.length;
    if (prcLength <= 0) return '';
    let d = '';

    for (var j = 0; j < prcLength; j++) {
      let linePoint = lineData[j];
      if (linePoint) {
        d += this.arrowPath(linePoint.xRep - 3.5, linePoint.yRep - 7, linePoint.xRep, (linePoint.yRep - 14.0).toFixed(0), linePoint.xRep + 3.5);
      }
    }
    let linePoint = lineData[j - 1];
    if (linePoint) {
      d += this.arrowPath(linePoint.xRep - 3.5, linePoint.yRep - 7, linePoint.xRep, (linePoint.yRep - 14.0).toFixed(0), linePoint.xRep + 3.5);
    }
    return d;
  }
  PrepareArrowDownDatas2(insource) {
    let lineData = insource.filter((obj) => obj.yRep > 0 && obj.ShowRpt && (obj.Surprise < 0 || (obj.Surprise == null && obj.SurpriseDiff < 0)));
    let prcLength = lineData.length;
    if (prcLength <= 0) return '';
    let d = '';
    for (var j = 0; j < prcLength; j++) {
      let linePoint = lineData[j];
      if (linePoint) {
        d += this.arrowDownPath(linePoint.xRep - 3.5, linePoint.yRep - 14, linePoint.xRep + 3.5, linePoint.xRep, (linePoint.yRep - 7.0).toFixed(0));
      }
    }
    let linePoint = lineData[j - 1];
    if (linePoint) {
      d += this.arrowDownPath(linePoint.xRep - 3.5, linePoint.yRep - 14, linePoint.xRep + 3.5, linePoint.xRep, (linePoint.yRep - 7.0).toFixed(0));
    }
    return d;
  }
  PrepareBoxPointDatas2(insource) {
    let lineData = insource.filter((obj) => obj.yRep > 0 && obj.ShowRpt && obj.Surprise == 0);
    let prcLength = lineData.length;
    if (prcLength <= 0) return '';
    let d = '';
    for (var i = 0; i < prcLength; i++) {
      let linePoint = lineData[i];
      if (linePoint) {
        d += this.rectPath(linePoint.xRep - 3.5, linePoint.yRep - 14, linePoint.xRep + 3.5, (linePoint.yRep - 7.0).toFixed(0));
      }
    }
    let linePoint = lineData[i - 1];
    if (linePoint) {
      d += this.rectPath(linePoint.xRep - 3.5, linePoint.yRep - 14, linePoint.xRep + 3.5, (linePoint.yRep - 7.0).toFixed(0));
    }
    return d;
  }
  getPath(pathData, lineStyle, dashValue = 0, fill = "none", needChangeLineThickness = false) {
    const showPointer = this.state.showR;
    const showCursor = showPointer ? "pointer" : "default";
    const pointerEvents = showPointer ? "visible" : "none";
    return (<g>
      <path d={pathData} data-disable-track-price="true"
        ref={(ref) => this.line = ref}
        className={needChangeLineThickness ? this.getDraggingState() : undefined}
        stroke={ThemeHelper.getThemedBrush(this.state.lineColor)}
        strokeDasharray={dashValue}
        strokeWidth={needChangeLineThickness ? (this.state.dragging ? undefined : this.state.lineThickness) : undefined}
        fill={fill}
        cursor={showCursor}
        pointerEvents="none"
      />
      <path d={pathData} data-disable-track-price="true"
        id={this.state.type}
        strokeWidth="5px"
        stroke="transparent"
        onFocus={this.handleMouseOver.bind(this)}
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseOver={this.handleMouseOver.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        onClick={this.handleOnClick.bind(this)}
        onContextMenu={(e)=>this.handleContextMenu(e)}
        fill="none"
        cursor={showCursor}
        pointerEvents={pointerEvents}>
      </path>
    </g>);
  }
  getSurprisePath(pathData, borderColor, dashValue = 0, fill = "none") {
    return (<g>
      <path d={pathData} data-disable-track-price="true"
        ref={(ref) => this.line = ref}
        // className={this.getDraggingState()}
        stroke={borderColor}
        strokeDasharray={dashValue}
        // strokeWidth={this.state.lineThickness}
        fill={fill}
        cursor="pointer"
        pointerEvents="none"
      />
      <path d={pathData} data-disable-track-price="true"
        id={this.state.type}
        strokeWidth="5px"
        stroke="transparent"
        onFocus={this.handleSurpriseMouseOver.bind(this)}
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseOver={(e) => this.handleSurpriseMouseOver(e)}
        onMouseLeave={(e) => this.handleSurpriseMouseLeave(e)}
        onClick={this.handleOnClick.bind(this)}
        fill="none"
        cursor="pointer"
        pointerEvents="visible">
      </path>
    </g>);
  }
  showInfoBox(showText, screenX, screenY, backgroundColor) {
    //  let backgroundColor = ThemeHelper.getThemedBrush("E1E20FEFEF0");
    let xLeft = screenX + "px";
    let ytop = screenY + "px";
    let height = "20px";
    let width = "auto";
    let lineFontColor = ThemeHelper.getFontColor(backgroundColor);
    //var grayText = ThemeHelper.getThemedBrush("pointerBox");
    let textStyle = {
      backgroundColor: backgroundColor,
      borderColor: "#333333",
      borderStyle: "solid",
      borderWidth: "1px",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      borderBottomLeftRadius: "4px",
      fontFamily: "calibri",
      color: lineFontColor,
      position: "absolute",
      left: xLeft,
      top: ytop,
      height: height,
      width: width,
      zIndex: 20
    };
    return (
      <div style={textStyle} key={screenX}>
        <div style={{
          textAlign: "Center",
          fontFamily: "calibri",
          color: lineFontColor,
          marginLeft: '6px',
          marginRight: '6px',
        }}>
          {showText}
        </div>
      </div>
    );
  }
  showSurpriseInfoBox(value, pecDiff, screenX, screenY, backgroundColor) {
    const xLeft = screenX + "px";
    const ytop = screenY + "px";
    const height = "40px";
    const width = "auto";
    const textStyle = {
      backgroundColor: backgroundColor,
      // borderColor: "#333333",
      // borderStyle: "solid",
      // borderWidth: "1px",
      // borderTopLeftRadius: "4px",
      // borderTopRightRadius: "4px",
      // borderBottomRightRadius: "4px",
      // borderBottomLeftRadius: "4px",
      // fontFamily: "calibri",
      color: this.state.frontColor,
      // position: "absolute",
      left: xLeft,
      top: ytop,
      height: height,
      width: width,
      // zIndex: 10
    };
    return (
      <div className="tooltip traditional yellow" style={textStyle} key={screenX}>
        <span className="text">{value}</span>
        <span className="text">{pecDiff}</span>
      </div>
    );
  }

  getTitle(lineID) {
    switch (lineID) {
      case "CFS":
        return LocalizationStore.getTranslatedData("T4Q_2", "Cash Flow per Share");
      case "DIV":
        return LocalizationStore.getTranslatedData("T4Q_3", "Dividend per Share");
      case "FCF":
        return LocalizationStore.getTranslatedData("T4Q_4", "Free Cash Flow per Share");
      case "BV":
        return LocalizationStore.getTranslatedData("T4Q_1", "Book Value per Share");
      case "RPS":
        return LocalizationStore.getTranslatedData("ch_rev", "Revenues");
      default:
        return LocalizationStore.getTranslatedData("ch_er", "Earnings");
    }
  }

  getMainSvg() {
    const lineStyle = {
      fitPosition: "fill",
      position: "absolute",
      left: "0px",
      top: "0px",
      zIndex: this.state.zIndex,
      pointerEvents: "none",
      opacity: this.state.opacity
    };
    const dottedPathData = this.prepareDottedLines2(this.state.dataSource);
    const solidPathData = this.prepareSolidLines2(this.state.dataSource);
    const reportDates = this.PrepareReportDates2(this.state.dataSource);
    let arrowDatas = "";
    let arrowDownDatas = "";
    let boxPointDatas = "";
    if (this.state.surprise) {
      arrowDatas = this.PrepareArrowDatas2(this.state.dataSource);
      arrowDownDatas = this.PrepareArrowDownDatas2(this.state.dataSource);
      boxPointDatas = this.PrepareBoxPointDatas2(this.state.dataSource);
    }
    let points;
    if (this.state.boxNodes)
      points = this.PrepareBoxes2(this.state.dataSource);
    else
      points = this.PrepareElipses2(this.state.dataSource);
    let colorInfo = ThemeHelper.getThemedBrush(this.state.lineColor);

    return (<svg className="svg rslayer-line" style={lineStyle} id="RSLayer" ref={(ref) => this.main = ref}
      onContextMenu={this.onContextMenuClick.bind(this)}
      height='100%'
      width='100%'>
      {this.getPath(solidPathData, lineStyle, undefined, undefined, true)}
      {this.getPath(dottedPathData, lineStyle, 2)}
      {this.getPath(reportDates, lineStyle)}
      {this.getSurprisePath(arrowDatas, "#01E2FE", 0, "#008EED")}
      {this.getSurprisePath(arrowDownDatas, "#C463BD", 0, "#DE34BE")}
      {this.getSurprisePath(boxPointDatas, "#A2A2A2", 0, "#808080")}
      {this.getPath(points, lineStyle, 0, colorInfo)}
    </svg>);
  }

  render() {
    TimeTrackingWindow.trackChartLoadingTime();
    if (!this.state.dataSource || this.state.dataSource.length <= 0)
      return (<div></div>);

    let pointerY = this.state.pointerY > 0 ? this.state.pointerY : 1;
    let showPointer = pointerY > 40 && pointerY < this.state.dimensions.height && this.props.showPointer;
    let colorInfo = ThemeHelper.getThemedBrush(this.state.lineColor);
    let pointerColor = ThemeHelper.getThemedBrush(this.state.lineColor);
    if (showPointer) {
      this.state.frontColor = ThemeHelper.getFontColor(colorInfo);
    } else {
      this.state.frontColor = "Transparent";
      pointerColor = "Transparent";
    }
    const pText = ThemeHelper.getThemedBrush("positiveDataText");
    const nText = ThemeHelper.getThemedBrush("negativeDataText");
    const backgroundColor = ThemeHelper.getThemedBrush("000fff");
    const refresh = this.state.refresh;

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.handleResize(contentRect.bounds)
        }}
      >
        {({ measureRef }) =>
          <div ref={measureRef}>
            { refresh === true &&
              this.getMainSvg()
            }
            { refresh === false &&
              this.getMainSvg()
            }
            {(!showPointer && pointerY > this.state.dimensions.height && this.state.pointerVisibility === "visible") &&
              <span className="epsdown" id="epsLabeldown" style={{ color: nText, background: backgroundColor }}>{this.props.pointerContent}</span>
            }
            {(!showPointer && pointerY < 40 && this.state.pointerVisibility === "visible") &&
              <span className="epsup" id="epsLabelup" style={{ color: pText, background: backgroundColor, zIndex:9999 }}>{this.props.pointerContent}</span>
            }
            <Pointer
              ref={(ref) => this.pointer = ref}
              height={18}
              width={60}
              fill={pointerColor}
              x="-50"
              content = {this.props.pointerContent}
              contentL = {this.props.pointerContent}
              y = {this.state.pointerY}
              direction="right"
              lcolor={this.state.frontColor}
              rcolor={this.state.frontColor}
              visibility={this.state.pointerVisibility} />
            {this.state.showBox && this.showInfoBox(this.state.lineTitle, this.state.screenX, this.state.screenY, colorInfo)}
            {this.state.showSurpriseBox && this.showSurpriseInfoBox(this.state.surpriseValue, this.state.surpriseDiff, this.state.boxScreenX, this.state.boxScreenY, "#FEFBCE")}
          </div>
        }
      </Measure>
    );
  }
}
QuarterlyLine.propTypes = {
  LineColor: PropTypes.string.isRequired,
  LineThickness: PropTypes.number.isRequired,
  Draggable: PropTypes.bool.isRequired,
  BoxNodes: PropTypes.bool.isRequired,
  SurpriseNodes: PropTypes.bool.isRequired,
  showPointer: PropTypes.bool,
  pointerVisibility: PropTypes.string,
  zIndex: PropTypes.string,
  type: PropTypes.string,
  isToolTip: PropTypes.bool,
  SymbolType: PropTypes.number,
  pointerContent: PropTypes.string,
  pointerYValue: PropTypes.number,
  showR: PropTypes.bool
};

QuarterlyLine.defaultProps = {
  showR: true
}
