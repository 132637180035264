import BaseServiceApi from "BaseServiceApi";

export const HubUserRole = {
    None: 0,
    Manager: 1,
    SalesRep: 2
    
}

export const ListType = {
    TopOwnerView: "TopOwnerView",
    HubListView: "HubListView",
}

export const ConstituentIndexEntitlements = [
    { index: 514, IndexConstituentAccess: ["0TRKISTB", "0TKNATA"] },
    { index: 515, IndexConstituentAccess: ["0SXSAX16"] },
    { index: 516, IndexConstituentAccess: ["0IBOMSEN", "0IBOM500"] },
    { index: 517, IndexConstituentAccess: ["0MASIIDX"] },
    { index: 518, IndexConstituentAccess: ["0CHSS300", "0CHSCOMP"] },
    { index: 519, IndexConstituentAccess: ["0DJIA", "0DJI", "0DJT", "0DJU"] },
    { index: 520, IndexConstituentAccess: ["0BGBEL20", "0FSBF250", "0FRCAC40", "0AMSTEOE", "0NLALSHR", "0POPSI20"] },
    { index: 521, IndexConstituentAccess: ["0COSEASH", "0DKKFXIN", "0ESTALSE", "0HEXINDX", "0HEX25IN", "0RIGSEIN", "0LNVILSE", "0SWEDOMX", "0SWSEALI"] },
    { index: 522, IndexConstituentAccess: ["0KLPCOMP", "0SNGPORI"] },
    { index: 523, IndexConstituentAccess: ["0INNSE50"] },
    { index: 524, IndexConstituentAccess: ["0POLWG20"] },
    { index: 525, IndexConstituentAccess: ["0LUXGENI"] },
    { index: 526, IndexConstituentAccess: ["0JAPDOWA"] },
    { index: 527, IndexConstituentAccess: ["0NIGALSH"] },
    { index: 528, IndexConstituentAccess: ["0PSEALLS", "0PSECOMP"] },
    { index: 529, IndexConstituentAccess: ["0ASXAORD", "0ASX100I", "0ASX200I", "0ASX300I"] },
    { index: 530, IndexConstituentAccess: ["0MXIPC35"] },
    { index: 531, IndexConstituentAccess: ["0PEGENRL"] },
    { index: 532, IndexConstituentAccess: ["0NZSEALL"] },
    { index: 533, IndexConstituentAccess: ["0TSE3"] },
    { index: 534, IndexConstituentAccess: ["0S&P5"] },
    { index: 535, IndexConstituentAccess: ["0OEX"] },
    { index: 536, IndexConstituentAccess: ["0SPMC", "0S&P6"] },
    { index: 537, IndexConstituentAccess: ["0MADRIDI"] },
    { index: 538, IndexConstituentAccess: ["0SITOTMK"] },
    { index: 539, IndexConstituentAccess: ["0DAXINDX"] },
    { index: 540, IndexConstituentAccess: ["0TAIWGHT"] },
    { index: 541, IndexConstituentAccess: ["0TOKYOSE"] },
    { index: 542, IndexConstituentAccess: ["0NDQC"] },
    { index: 543, IndexConstituentAccess: ["0ATXINDX", "0CTCROBE", "0CZPXIDX"] },
]

const entitlementType = BaseServiceApi.rayData["EntitlementType"];

export const ENTITLEMENTS = [
    entitlementType.IndexConstituentAccess_BorsaIstanbulIndices_Entitlement,
    entitlementType.IndexConstituentAccess_BratislavaStockExchange_Entitlement,
    entitlementType.IndexConstituentAccess_BSEBroadPackage_Entitlement,
    entitlementType.IndexConstituentAccess_CasablancaStockExchange_Entitlement,
    entitlementType.IndexConstituentAccess_ChinaSecuritiesIndexCo_Entitlement,
    entitlementType.IndexConstituentAccess_DowJonesAverages_Entitlement,
    entitlementType.IndexConstituentAccess_EuronextIndexMembership_Entitlement,
    entitlementType.IndexConstituentAccess_EuropeanClassic_Entitlement,
    entitlementType.IndexConstituentAccess_FTSEPartnerIndices_Entitlement,
    entitlementType.IndexConstituentAccess_IndexConstituentsCNX_Nifty50_Entitlement,
    entitlementType.IndexConstituentAccess_IndexPortfoliosWSEData_Entitlement,
    entitlementType.IndexConstituentAccess_LuXXNationalIndices_Entitlement,
    entitlementType.IndexConstituentAccess_NikkeiIndexConstituents_Entitlement,
    entitlementType.IndexConstituentAccess_NSEEODIndicesandConstituents_Entitlement,
    entitlementType.IndexConstituentAccess_PhilippineEODIndexFile_Entitlement,
    entitlementType.IndexConstituentAccess_SP_ASXIndexPackage_Entitlement,
    entitlementType.IndexConstituentAccess_SP_BMV_Entitlement,
    entitlementType.IndexConstituentAccess_SP_BVLIndexSeries_Entitlement,
    entitlementType.IndexConstituentAccess_SP_NZXEquityIndices_Entitlement,
    entitlementType.IndexConstituentAccess_SP_TSXIndexStockFile1_Entitlement,
    entitlementType.IndexConstituentAccess_SP_Base_Entitlement,
    entitlementType.IndexConstituentAccess_SP_BaseXL_Entitlement,
    entitlementType.IndexConstituentAccess_SP_CompleteXF_Entitlement,
    entitlementType.IndexConstituentAccess_Spain3500IndicesBME_Entitlement,
    entitlementType.IndexConstituentAccess_SPI_Family_Entitlement,
    entitlementType.IndexConstituentAccess_Stoxx_Dax_Entitlement,
    entitlementType.IndexConstituentAccess_TAIEXIndexConstituents_Entitlement,
    entitlementType.IndexConstituentAccess_TSEConstituentMasterFile_Entitlement,
    entitlementType.IndexConstituentAccess_USClassicIndexData_Entitlement,
    entitlementType.IndexConstituentAccess_ViennaSpecialProducts_IndexBaskets_Entitlement,
]

