import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import { ResizeConstants } from "Constants/ResizeConstants.js";
import ListApi from "ListApi";
import { each } from "underscore";
import ListSettingHelper from "../../../ConsoleWindow/Settings/Modules/ListManager/ListManagerSettingHelper.js"
import ListStore from "ListStore";
import StringUtil from "../../../../Utils/StringUtil.js";

const CHANGE_EVENT = "change";

let _currentAction = null;

let _state = {
    previousBrowserFilterMaxWidth: 0,
    labelLoading: [],
    geographyBadge: '',
    oneilBadge: '',
    gicsBadge: '',
    countryRegion: {},
    browserFilterWidths: {},
    dataSetTypeChanged:false
};

let browserFilterBoxItemWidths = {
    Geography: { Region: 0, Country: 0, Instrument: 0 },
    Group: {
        Oneil: { Sectors: 0, MajorIndustries: 0, IndustryGroups: 0 },
        GICS: { Sectors: 0, IndustryGroups: 0, Industries: 0, SubIndustries: 0 }
    }
}
let browserFilterBoxItemMaxWidths = {
    Geography: { Region: 0, Country: 0, Instrument: 0 },
    Group: {
        Oneil: { Sectors: 0, MajorIndustries: 0, IndustryGroups: 0 },
        GICS: { Sectors: 0, IndustryGroups: 0, Industries: 0, SubIndustries: 0 }
    }
}

let _filterObject = {
    Region1: '-1', Country2: '-1', Instrument3: '-1',
    Sectors5: '-1', MajorIndustries6: '-1', IndustryGroups7: '-1',
    Sectors1: '-1', IndustryGroups2: '-1', Industries3: '-1', SubIndustries4: '-1'
};

let _filterObjectName = {
    Region1: '', Country2: '', Instrument3: '',
    Sectors5: '', MajorIndustries6: '', IndustryGroups7: '',
    Sectors1: '', IndustryGroups2: '', Industries3: '', SubIndustries4: ''
};



class BrowserFilterStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        //Bug fixes - Max Limit error 
        this.setMaxListeners(0);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getState() {
        return _state;
    }

    getCurrentAction() {
        return _currentAction;
    }

    clearCurrentAction() {
        _currentAction = null;
    }

    getFilterObject() {
        return _filterObject;
    }

    setTabSelection(tabName) {
        _state.selectedTab = tabName;
        let NavListManagerSettings = ListStore.getNavListManagerSettings();
        ListSettingHelper.updateBrowserFilterData(NavListManagerSettings.TabONeilSettings, _state, _filterObject, _filterObjectName);
        SettingsStore.saveSettings();
        _currentAction = ListExplorerConstants.ActionTypes.BROWSER_FILTER_TAB_TYPE;
        this.emit(CHANGE_EVENT);
    }

    updateCheck() {
        let ListExplorer = SettingsStore.getConsoleSettings().NavListManagerSettings.TabONeilSettings.ListExplorer;
        let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        _state.browserFilterWidths["browserFilterMaxWidth"] = 0.9 * (viewportWidth - ListExplorer.Width);
    }

    setBrowserFilterMaxHeight(gridHeight) {
        _state.browserFilterWidths["browserFilterMaxHeight"] = 0.85 * (gridHeight - 18);
        this.updateCheck();
    }

    setBrowserFilterWidths(isExplorerResized) {
        let leftPanelWidth = 15;
        let ListExplorer = SettingsStore.getConsoleSettings().NavListManagerSettings.TabONeilSettings.ListExplorer;
        let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

        _state.browserFilterWidths["browserFilterMaxWidth"] = 0.9 * (viewportWidth - ListExplorer.Width);
        _state.browserFilterWidths["browserFilterMinWidth"] = 615;
        _state.browserFilterWidths["minimumBoxItemWidth"] = 200;
        _state.browserFilterWidths["GICSminimumBoxItemWidth"] = 149;
        _state.browserFilterWidths["browserFilterIntialWidth"] = 900;
        _state.browserFilterWidths["browserFilterMinHeight"] = 200;
        _state.browserFilterWidths["browserFilterMaxHeight"] = 600;

        let groupTypes = ["Geography", "Group"]; let filterTypes = ["Oneil", "GICS"]; let numberofSections = 3;
        each(groupTypes, (group) => {
            let listExplorerWidthDifference = _state.browserFilterWidths["browserFilterMaxWidth"] - _state.previousBrowserFilterMaxWidth;
            if (group == "Geography") {
                let geographyKeys = Object.keys(browserFilterBoxItemWidths[group]);
                each(geographyKeys, (key) => {
                    if (isExplorerResized == false) {
                        browserFilterBoxItemWidths[group][key] = (_state.browserFilterWidths["browserFilterIntialWidth"] - leftPanelWidth) / numberofSections;
                        browserFilterBoxItemMaxWidths[group][key] = (_state.browserFilterWidths["browserFilterMaxWidth"] - leftPanelWidth) / numberofSections;
                    } else {
                        browserFilterBoxItemMaxWidths[group][key] += listExplorerWidthDifference / numberofSections;
                    }
                })
            } else {
                each(filterTypes, (filter) => {
                    let groupKeys = Object.keys(browserFilterBoxItemWidths[group][filter]);
                    each(groupKeys, (key) => {
                        if (filter == "GICS") numberofSections = 4;
                        if (isExplorerResized == false) {
                            browserFilterBoxItemWidths[group][filter][key] = (_state.browserFilterWidths["browserFilterIntialWidth"] - leftPanelWidth) / numberofSections;
                            browserFilterBoxItemMaxWidths[group][filter][key] = (_state.browserFilterWidths["browserFilterMaxWidth"] - leftPanelWidth) / numberofSections;
                        } else {
                            browserFilterBoxItemMaxWidths[group][filter][key] += listExplorerWidthDifference / numberofSections;
                        }
                    })
                })
            }
        })
        _state.browserFilterWidths["browserFilterWidths"] = browserFilterBoxItemWidths;
        _state.browserFilterWidths["browserFilterMaxWidths"] = browserFilterBoxItemMaxWidths;

        _state.previousBrowserFilterMaxWidth = _state.browserFilterWidths["browserFilterMaxWidth"];
    }

    getBrowserFilterWidths() {
        return _state.browserFilterWidths;
    }

    getCountryRegionData() {
        if (!StringUtil.isEmpty(_state.countryRegion)) {
            return;
        }
        Promise.all([
            ListApi.getCountryRegionList()
        ]).then(([CountryRegion]) => {

            try {

                if (CountryRegion.listCountryregionData !== undefined && CountryRegion.listCountryregionData !== null) {

                    _state.countryRegion = CountryRegion.listCountryregionData;
                }

            }
            catch (e) {
                console.log(e);
            }
        });
    }

    getFilterBadge() {
        return _state.filterBadge;
    }

    updateBrowserFilterDataFromSettings() {
        let browserfilterObj = ListStore.getNavListManagerSettings().TabONeilSettings.BrowserFilter;
        _state.isOneilselected = browserfilterObj.isOneilselected;
        _state.isGicsselected = browserfilterObj.isGicsselected;
        _state.needBrowserCounts = browserfilterObj.needBrowserCounts;
        _state.browserFilterText = browserfilterObj.browserFilterText;
        _state.igOneilbrowserFilters = browserfilterObj.igOneilbrowserFilters;
        _state.igGicsbrowserFilters = browserfilterObj.igGicsbrowserFilters;
        _state.filterBadge = browserfilterObj.filterBadge;
        _state.selectedTab = browserfilterObj.selectedTab;
        _filterObject = browserfilterObj.filterObject;
        _filterObjectName = browserfilterObj.filterObjectName;
    }

    //Create Filter Head as Specific formate
    setFilterHead() {
        let oneilOrGicsbadgeData = '';

        if (_state.isOneilselected == 1) {
            oneilOrGicsbadgeData = _state.oneilBadge;

        }
        else if (_state.isGicsselected == 1) {
            oneilOrGicsbadgeData = _state.gicsBadge;
        }
        _state.filterBadge = (_state.geographyBadge != '' && oneilOrGicsbadgeData != '')
            ? _state.geographyBadge + ' :: ' + oneilOrGicsbadgeData
            : (_state.geographyBadge == '' && oneilOrGicsbadgeData != '')
                ? oneilOrGicsbadgeData
                : (_state.geographyBadge != '' && oneilOrGicsbadgeData == '')
                    ? _state.geographyBadge : '';


        this.updateBrowserFilterData();
    }
    // Filter data store in _state as specific formate
    setFilters() {
        _state.browserFilterText = _filterObject.Region1 + ';' + _filterObject.Country2 + ';' + _filterObject.Instrument3 + ';';
        _state.igOneilbrowserFilters = _filterObject.Sectors5 + ';' + _filterObject.MajorIndustries6 + ';' + _filterObject.IndustryGroups7 + ';';
        _state.igGicsbrowserFilters = _filterObject.Sectors1 + ';' + _filterObject.IndustryGroups2 + ';' + _filterObject.Industries3 + ';' + _filterObject.SubIndustries4 + ';';
    }


    setFilterBadge() {
        _state.filterBadge = '';
        _state.geographyBadge = '';
        _state.oneilBadge = '';
        _state.gicsBadge = '';
        each(_filterObjectName, (value, key) => {
            if (_filterObjectName[key] != '' && _filterObjectName[key] != null) {
                if (key == "Region1" || key == "Country2" || key == "Instrument3") {
                    if (_state.geographyBadge != '') {
                        _state.geographyBadge += ' :: ' + value;
                    }
                    else {
                        _state.geographyBadge += value;
                    }
                }
                if (key == "Sectors5" || key == "MajorIndustries6" || key == "IndustryGroups7") {

                    if (_state.oneilBadge != '') {
                        _state.oneilBadge += ' :: ' + value;
                    }
                    else {
                        _state.oneilBadge += value;
                    }
                }
                if (key == "Sectors1" || key == "IndustryGroups2" || key == "Industries3" || key == "SubIndustries4") {
                    if (_state.gicsBadge != '') {
                        _state.gicsBadge += ' :: ' + value;
                    }
                    else {
                        _state.gicsBadge += value;
                    }
                }
            }
        })
        this.setFilterHead();

    }

    // RESET THE FILTER --- CLEAR BUTTON CLICK
    resetFilterSet(type) {
        _state.labelLoading = [];
        switch (type) {
            //Geography Tab 3 Section data Reset
            case 'Geography':
                _state.labelLoading.push("Region1"); //CLASSIFICATION
                _state.labelLoading.push("Country2");
                _state.labelLoading.push("Instrument3");
                _filterObject["Region1"] = '-1';
                _filterObject["Country2"] = '-1';
                _filterObject["Instrument3"] = '-1';
                _filterObjectName["Region1"] = null;
                _filterObjectName["Country2"] = null;
                _filterObjectName["Instrument3"] = null;
                break;

            //Group Tab -> Oneil View -> 3 Section data Reset
            case 'Oneil':
                _state.labelLoading.push("Sectors5");
                _state.labelLoading.push("MajorIndustries6");
                _state.labelLoading.push("IndustryGroups7");
                _filterObject["Sectors5"] = '-1';
                _filterObject["MajorIndustries6"] = '-1';
                _filterObject["IndustryGroups7"] = '-1';
                _filterObjectName["Sectors5"] = null;
                _filterObjectName["MajorIndustries6"] = null;
                _filterObjectName["IndustryGroups7"] = null;
                break;

            //Group Tab -> Gics View -> 3 Section data Reset
            case 'Gics':
                _state.labelLoading.push("Sectors1");
                _state.labelLoading.push("IndustryGroups2");
                _state.labelLoading.push("Industries3");
                _state.labelLoading.push("SubIndustries4");
                _filterObject["Sectors1"] = '-1';
                _filterObject["IndustryGroups2"] = '-1';
                _filterObject["Industries3"] = '-1';
                _filterObject["SubIndustries4"] = '-1';
                _filterObjectName["Sectors1"] = null;
                _filterObjectName["IndustryGroups2"] = null;
                _filterObjectName["Industries3"] = null;
                _filterObjectName["SubIndustries4"] = null;

                break;
            default:
                return;

        }
        this.setFilters();
        this.setFilterBadge();
    }

    //Onclick Store filter ID and Name and Type of Filter
    changeOnclickFilterSet(parentName, nodeFilterId, filterName) {
        let label = parentName.replace(/-/g, '');
        let prevSelection = _filterObject[label];
        _filterObject[label] = nodeFilterId;
        if (nodeFilterId != '-1') {
            _filterObjectName[label] = filterName;
        }
        else {
            _filterObjectName[label] = null;
        }

        _state.labelLoading = [];
        switch (label) {
            case "Region1":
                _state.labelLoading.push("Country2");
                _state.labelLoading.push("Instrument3");
                break;
            case "Country2":
                _state.labelLoading.push("Instrument3");
                break;
            case "Instrument3":
                break;
            case "Sectors5":
                _state.labelLoading.push("MajorIndustries6");
                _state.labelLoading.push("IndustryGroups7");
                if (nodeFilterId != '-1' && prevSelection != nodeFilterId) {
                    _filterObject["MajorIndustries6"] = '-1';
                    _filterObject["IndustryGroups7"] = '-1';
                    _filterObjectName["MajorIndustries6"] = null;
                    _filterObjectName["IndustryGroups7"] = null;
                }
                break;
            case "MajorIndustries6":
                _state.labelLoading.push("IndustryGroups7");
                if (nodeFilterId != '-1' && prevSelection != nodeFilterId) {
                    _filterObject["IndustryGroups7"] = '-1';
                    _filterObjectName["IndustryGroups7"] = null;
                }
                break;
            case "IndustryGroups7":
                break;
            case "Sectors1":

                _state.labelLoading.push("IndustryGroups2");
                _state.labelLoading.push("Industries3");
                _state.labelLoading.push("SubIndustries4");
                if (nodeFilterId != '-1' && prevSelection != nodeFilterId) {
                    _filterObject["IndustryGroups2"] = '-1';
                    _filterObject["Industries3"] = '-1';
                    _filterObject["SubIndustries4"] = '-1';
                    _filterObjectName["IndustryGroups2"] = null;
                    _filterObjectName["Industries3"] = null;
                    _filterObjectName["SubIndustries4"] = null;
                }
                break;
            case "IndustryGroups2":
                _state.labelLoading.push("Industries3");
                _state.labelLoading.push("SubIndustries4");
                if (nodeFilterId != '-1' && prevSelection != nodeFilterId) {
                    _filterObject["Industries3"] = '-1';
                    _filterObject["SubIndustries4"] = '-1';
                    _filterObjectName["Industries3"] = null;
                    _filterObjectName["SubIndustries4"] = null;
                }
                break;
            case "Industries3":
                _state.labelLoading.push("SubIndustries4");
                if (nodeFilterId != '-1' && prevSelection != nodeFilterId) {
                    _filterObject["SubIndustries4"] = '-1';
                    _filterObjectName["SubIndustries4"] = null;
                }
                break;
            case "SubIndustries4":
                break;
            default:
                break;
        }
        this.setFilters();
        this.setFilterBadge();
    }

    //Update Browser Filter Data to TabONeilSettings
    updateFilterDataSetType(dataSetType) {
        _state.dataSetTypeChanged = true;
        _state.labelLoading = [];
        switch (dataSetType) {
            case 'Oneil':
                _state.isOneilselected = 1;
                _state.isGicsselected = 0;
                _state.needBrowserCounts = 1;
                break;
            case 'Gics':
                _state.isOneilselected = 0;
                _state.isGicsselected = 1;
                _state.needBrowserCounts = 1;
                break;
            default:
                break;
        }
        this.setFilterBadge();
    }

    resetDataSetTypeChanged(){
        _state.dataSetTypeChanged = false;
    }


    updateBrowserFilterData() {
        let NavListManagerSettings = ListStore.getNavListManagerSettings();
        ListSettingHelper.updateBrowserFilterData(NavListManagerSettings.TabONeilSettings, _state, _filterObject, _filterObjectName);
        SettingsStore.saveSettings();
    }

    resetCountryBadge() {
        _filterObject["Country2"] = '-1';
        _filterObjectName["Country2"] = null;
        this.setFilters()
        this.setFilterBadge();
    }

    dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.data;
        switch (action.actionType) {
            case ListExplorerConstants.ActionTypes.BROWSER_FILTER:
                this.changeOnclickFilterSet(data.parentName, data.nodeFilterId, data.filterName);
                this.emit(CHANGE_EVENT);
                this.updateBrowserFilterData();
                break;
            case ListExplorerConstants.ActionTypes.BROWSER_FILTER_RESET:
                this.resetFilterSet(data.type);
                this.emit(CHANGE_EVENT);
                this.updateBrowserFilterData();
                break;
            case ListExplorerConstants.ActionTypes.BROWSER_FILTER_DATASETTYPE:
                this.updateFilterDataSetType(data.dataSetType);
                this.emit(CHANGE_EVENT);
                this.updateBrowserFilterData();
                break;
            case ListExplorerConstants.ActionTypes.BROWSER_FILTER_COUNTRY_REGION:
                this.getCountryRegionData();
                break;
            case ResizeConstants.ActionTypes.UPDATE_BROWSER_FILTER_WIDTHS:
                _currentAction = ResizeConstants.ActionTypes.UPDATE_BROWSER_FILTER_WIDTHS;
                this.updateCheck();
                this.emit(CHANGE_EVENT);
                break;
                case ListExplorerConstants.ActionTypes.RESET_COUNTRYBADGE_SET:
                this.resetCountryBadge();
                this.emit(CHANGE_EVENT);
                break;
            default:
                return;

        }
    }
}

const browserFilterStore = new BrowserFilterStore();
export default browserFilterStore;
