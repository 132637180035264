import AppDispatcher from "AppDispatcher";
import { MenuDialogConstants } from "../Constants/MenuDialogConstants.js";

class MenuCommonDialogActionsClass {
    openMenuCommonDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN,
            data: dialogData
        });
    }

    openAccountDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.ACCOUNT,
            data: dialogData
        });
    }

    openHelpDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.HELP,
            data: dialogData
        });
    }

    openWhatsNewDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_WHATS_NEW_DIALOG,
            data: dialogData
        });
    }

    openApperanceDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.APPEARANCE,
            data: dialogData
        });
    }

    openNotificationDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.NOTIFICATION,
            data: dialogData
        });
    }

    openIndexLineDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDEXLINE_DIALOG,
            data: dialogData
        });
    }
    openCEFIndexLineDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_CEF_INDEXLINE_DIALOG,
            data: dialogData
        });
    }
    openIndexIndexLineDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDEX_INDEXLINE_DIALOG,
            data: dialogData
        });
    }
    openETFIndexLineDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_ETF_INDEXLINE_DIALOG,
            data: dialogData
        });
    }
    openFundsIndexLineDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_FUNDS_INDEXLINE_DIALOG,
            data: dialogData
        });
    }
    openIndustryGroupIndexLineDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDUSTRYGROUP_INDEXLINE_DIALOG,
            data: dialogData
        });
    }
    openIndustryGroupRS1LineDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDUSTRYGROUP_RS1_DIALOG,
            data: dialogData
        });
    }
    openRS1Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_RS1_DIALOG,
            data: dialogData
        });
    }
    openRS2Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_RS2_DIALOG,
            data: dialogData
        });
    }
    openCEFRS1Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_CEF_RS1_DIALOG,
            data: dialogData
        });
    }
    openIndexRS1Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDEX_RS1_DIALOG,
            data: dialogData
        });
    }
    openCEFRS2Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_CEF_RS2_DIALOG,
            data: dialogData
        });
    }
    openIndexRS2Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDEX_RS2_DIALOG,
            data: dialogData
        });
    }
    openETFRS1Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_ETF_RS1_DIALOG,
            data: dialogData
        });
    }
    openETFRS2Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_ETF_RS2_DIALOG,
            data: dialogData
        });
    }
    openFundRS1Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_FUND_RS1_DIALOG,
            data: dialogData
        });
    }
    openFundRS2Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_FUND_RS2_DIALOG,
            data: dialogData
        });
    }

    openIndustryRS2Dialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDUSTRYGROUP_RS2_DIALOG,
            data: dialogData
        });
    }
    openPRDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_PR_DIALOG,
            data: dialogData
        });
    }
    openStreetTargetPriceDialog(dialogData){
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_STREET_TARGET_PRICE_DIALOG,
            data: dialogData
        });
    }
    openPRCEFDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_CEF_PR_DIALOG,
            data: dialogData
        });
    }
    openPRIndexDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDEX_PR_DIALOG,
            data: dialogData
        });
    }
    openPRETFDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_ETF_PR_DIALOG,
            data: dialogData
        });
    }
    openAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }
    openCEFAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_CEF_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }
    openIndexAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDEX_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }

    openETFAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_ETF_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }

    openIndustryGroupAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_INDUSTRYGROUP_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }
    openCurrencyAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_CURRENCY_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }

    openCryptoCurrencyAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_CRYPTO_CURRENCY_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }

    openFundsAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_FUNDS_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }
    getQuoteService(quoteServiceData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.QUOTE_SERVICE,
            data: quoteServiceData
        });
    }

    setSelectQuoteServiceOption(data) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.SET_SELECTQUOTESERVICEOPTION,
            data: data
        });
    }

    openReportedEarningsDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.REPORTED_EARNINGS,
            data: dialogData
        });
    }

    openCurrencyDialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.CURRENCY_CONVERSION,
            data: dialogData
        });
    }

    changeReportedRevenue(data) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.UPDATE_REVENUE,
            data: data
        });
    }

    changeReportedEarnings(data) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.UPDATE_EARNINGS,
            data: data
        });
    }

    openCashSpotsAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_CASHSPOTS_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }
    openFuturesAddMADialog(dialogData) {
        AppDispatcher.handleViewAction({
            actionType: MenuDialogConstants.ActionTypes.OPEN_FUTURES_MOVING_AVERAGE_DIALOG,
            data: dialogData
        });
    }
}

const MenuCommonDialogActions = new MenuCommonDialogActionsClass();
export default MenuCommonDialogActions;


