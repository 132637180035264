import React, { PureComponent } from "react";
import { connect } from 'react-redux'
import { each, find,  debounce, isEqual } from "underscore";
import PortalContainer from "PortalContainer";
import GridStore from "GridStore";
import ListStore from 'ListStore';
import BrowserUtil from "BrowserUtil";
import BaseServiceApi from "BaseServiceApi";
import { ReactHeight } from "react-height";
import ExternalGridView from "./ExternalGridView";
import { initGridLoad, onGridDimensionsChange, loadDataForVisibleRows, setSymbolColumn, setDateColumn,
    handleColumnSort , handleUpdateLoader, moveToNextState, moveToPreviousState,
    loadExternalList, updateCurrentIndex,setDateFormatDialog } from "../../Actions/ExternalDataUploaderActions";
import ScrollBar from "ScrollBar";
import LocalizationStore from "LocalizationStore";
import { Glyphicon, OverlayTrigger } from "react-bootstrap";
import ExternalDateSelectWizard  from '../../RayCustomControls/ExternalData/ExternalDateSelectWizard.jsx'
import WizardHeaderPopOver from "../PopOver/WizardHeaderPopOver.jsx";
let MetricType = BaseServiceApi.rayData["MetricType"];
let timeout = null;
let currentStartIndex = 0;
let currentScrollWheel = 0;
let stopColumnAutoScroll = true;
let left = 0.0;

class ExternalGrid extends PureComponent {
    constructor(props) {
        super(props);
        this.setViewPortSizes = this.setViewPortSizes.bind(this);
        this.generateItemOnScroll = this.generateItemOnScroll.bind(this);
        this.checkMoreData = this.checkMoreData.bind(this);
        this.handleColumnSort = this.handleColumnSort.bind(this);
        this.setScrollPos = this.setScrollPos.bind(this);
        this.handleVerticalScroll = this.handleVerticalScroll.bind(this);
        this.handleHorizontalScroll = this.handleHorizontalScroll.bind(this);
        this.handleWheelScroll = this.handleWheelScroll.bind(this);
        this.scrollHorizentalGrid = this.scrollHorizentalGrid.bind(this);
        this.scrollGrid = this.scrollGrid.bind(this);
        this.getColumnWidth = this.getColumnWidth.bind(this);
        this.handleVerticalScrollMouseUp = this.handleVerticalScrollMouseUp.bind(this);
        this.selectedView = this.props.selectedView;
        this.colSeq = null;
        this.textSize = ListStore.getTextFontSize();
        this.customWidth = undefined;
        this.updateWidthHeightGrid = this.updateWidthHeightGrid.bind(this);
        this.state = {
            viewportWidth: 0.0,
            viewPortHeight: 0.0,
            offsetWidth: "0px",
            recordsPerBody: 0,
            clientHeight: 0,
            columns: [],
            gridAreaWidth: 0.0,
            allColumns: [],
            source: [],
            selectedListId: null,
            dimensions: {
                width: 0,
                height: 0,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            },
        }
        this.allColumns = [],
            this.source = [],
            this.externalDiv = null;
        this.horizontalScroll = null;
        this.verticalScroll = null;
        this.loadingMore = false;
        this.vH = 0;
        this.cellLimit = 3;
        this.uniqueleft = 0;
        this.totalWidth=0;
        this.actualItemHeight=1;
    }

    componentWillUnmount() {
        if (BrowserUtil.getBrowserName() == 'Firefox') {
            if (this.externalDiv) this.externalDiv.removeEventListener('DOMMouseScroll', this.handleWheelScroll);
        }
        else {
            if (this.externalDiv) this.externalDiv.removeEventListener('mousewheel', this.handleWheelScroll);
        }
        document.removeEventListener('mouseup', this.handleMouseUp, false);
        window.removeEventListener('resize', this.updateWindow, 200, false);
    }

    UNSAFE_componentWillUpdate() {      
        this.setViewPortSizes(this.state, this.props);
    }

    componentDidMount() {

        window.addEventListener('resize', this.updateWindow, 200, false);

        // if (this.mainExternalDiv) this.mainExternalDiv.focus();
        this.externalDiv = document.getElementById('externalDiv');
        this.verticalScroll = document.getElementById('verticalScroll');
        this.gridVerticalScroll = document.getElementById('gridVerticalScroll');
        this.horizontalScroll = document.getElementById('horizontalScroll');
        this.gridHorizontalScroll = document.getElementById('main-grid-panel');
        this.listExplorerElm = document.getElementById('listExplorer');
        this.messureHeightElm = document.getElementById("messureHeight");
        this.vH = this.gridBlock ? this.gridBlock.getBoundingClientRect().height : 0;
        if (BrowserUtil.getBrowserName() == 'Firefox') {
            if (this.externalDiv && this.externalDiv.addEventListener) this.externalDiv.addEventListener("DOMMouseScroll", this.handleWheelScroll, false);
        }
        else {
            if (this.externalDiv && this.externalDiv.addEventListener) this.externalDiv.addEventListener("mousewheel", this.handleWheelScroll, false);
        }
        if (this.gridVerticalScroll) this.gridVerticalScroll.addEventListener("scroll", this.handleVerticalScroll, false);
        if (this.gridHorizontalScroll) this.gridHorizontalScroll.addEventListener("scroll", this.handleHorizontalScroll, false);
        {
            if (this.props.columns && this.props.columns.length > 0) {
                if (!Object.compare(this.allColumns, this.props.columns) || !Object.compare(this.source, this.props.source)) {
                    let columns = [];
                    for (let index = 0; index < this.props.columns.length; index++) {
                        let column = this.props.columns[index];
                        columns.push(column);
                    }
                    this.allColumns = this.props.columns;
                    this.source = this.props.source;
                    this.setViewPortSizes(this.state, this.props, columns);
                }
            }
            const textSize = ListStore.getTextFontSize();
            if (this.textSize && this.textSize != textSize) {
                this.textSize = textSize;
                this.setViewPortSizes(state, this.props, this.props.columns)
            }
        }
        if (this.loadingMore == false) {
            setTimeout(() => {
                if (this.props.selectedListId) this.setPageScroll(false);
                else this.setPageScroll();
            }, 0);
        }
        else {
            this.loadingMore = false;
            if (this.gridLoadMore) this.gridLoadMore.style.display = 'none';
        }
    }

    updateWindow = () => {
        setTimeout(() => {
            this.vH = this.gridBlock ? this.gridBlock.getBoundingClientRect().height : 0;
            if (this.mainExternalDiv) {
                this.setViewPortSizes(this.state, this.props);
            }
        }, 0);
    }
    
    UNSAFE_componentWillReceiveProps(props, state) {  
        if (props.columns != undefined) {
            if (props.columns.length > 0) {
                if (props && props.columns && !Object.compare(this.source, props.source)) {
                        let columns = [];
                        for (let index = 0; index < props.columns.length; index++) {
                            let column = props.columns[index];
                            columns.push(column);
                        }
                        this.allColumns = props.columns;
                        this.source = props.source;
                        this.setViewPortSizes(this.state, props, columns);
                }
            }
            const textSize = ListStore.getTextFontSize();
            if (this.textSize && this.textSize != textSize) {
                this.textSize = textSize;
                this.setViewPortSizes(state, this.props, this.props.columns)
            }
        }
        if (this.loadingMore == false) {
            setTimeout(() => {
                if (this.props.selectedListId) this.setPageScroll(false);
                else this.setPageScroll();
            }, 0);
        }
        else {
            this.loadingMore = false;
            if (this.gridLoadMore) this.gridLoadMore.style.display = 'none';
        }
    }

    componentDidUpdate() {  
        if (this.mainExternalDiv.clientWidth !== this.props.gridAreaWidth) {
            debounce(this.setViewPortSizes(this.state, this.props), 300);
        }
        let obj = GridStore.getScrollPos();
        this.generateItemOnScroll(obj.scrollTop);
    }

    setPageScroll(resizeLeftScroll = true) {
        let obj = GridStore.getScrollPos();
        this.gridVerticalScroll.scrollTop = obj.scrollTop;
        if (resizeLeftScroll) this.gridHorizontalScroll.scrollLeft = obj.scrollLeft;
    }

    getTextHeight() {
        if (this.messureHeightElm) {
            this.messureHeightElm.style.fontFamily = 'calibri';
            this.messureHeightElm.style.fontSize = this.textSize + 'px';
            const offset = 2;
            this.actualItemHeight = Math.ceil(this.messureHeightElm.getBoundingClientRect().height) + offset;
            return this.actualItemHeight;
        }
    }

    scrollGrid(step) {

        let scrollY = this.gridVerticalScroll.scrollTop;
        this.gridVerticalScroll.scrollTop = (scrollY + step);
        if (!this.dragScrollStop) {
            window.setTimeout(() => { this.scrollGrid(step) }, 20);
        }
    }

    getColumnWidth() {
        let colWidth = 200;
        const minWidth=100;
        const { gridAreaWidth, columns } = this.props;
        if (gridAreaWidth > 0) {
            let width = gridAreaWidth / columns.length;
            colWidth = width > colWidth ? colWidth : width < minWidth? minWidth :width;
        }
        return colWidth;
    }
    setViewPortSizes(state, props, columns) {

        let colWidths = 0.0;
        let itemsCount = props.itemsCount + 1;
        let actualItemHeight = this.getTextHeight();
        let itemHeight = actualItemHeight ? actualItemHeight : props.itemHeight;
        const viewPortHeight = (itemHeight * itemsCount);
        let visibleCount = Math.floor(this.gridAreaHeight / itemHeight);
        let offsetWidth = this.externalDiv ? this.externalDiv.clientWidth : 0;
        colWidths = 20;
        let gridAreaWidth = this.mainExternalDiv ? this.mainExternalDiv.clientWidth : 0;
        if (this.listExplorerElm && this.listExplorerElm.clientWidth < 23) {
            gridAreaWidth -= 24;
        }
        const gridData = {
            viewportWidth: colWidths,
            viewPortHeight: viewPortHeight,
            offsetWidth: offsetWidth,
            recordsPerBody: visibleCount,
            clientHeight: visibleCount * itemHeight,
            gridAreaWidth: gridAreaWidth
        }
        this.props.initGridLoad(gridData);
    }

    setScrollPos(e, type, selectedIndex) {
        const itemHeight = this.actualItemHeight;
        if (type == 'moveDown') {
            let scrollTop = (itemHeight * selectedIndex) - this.gridAreaHeight;
            this.gridVerticalScroll.scrollTop = scrollTop;
        }
        else {
            let scrollTop = itemHeight * (selectedIndex);
            this.gridVerticalScroll.scrollTop = scrollTop;
        }
        this.handleVerticalScrollMouseUp(e);
    }

    calculateWheelScroll(event) {
        let delta = 0;
        if (!event) event = window.event;
        if (event.wheelDelta) {
            delta = event.wheelDelta / 120;
        }
        else if (event.detail) {
            delta = -event.detail / 3;
        }
        if (event.preventDefault) event.preventDefault();
        event.returnValue = false;
        if (delta) return delta;
    }

    handleWheelScroll(e) {

        let scrollTop = parseInt(this.gridVerticalScroll.scrollTop);
        currentScrollWheel = scrollTop;
        if (this.calculateWheelScroll(e) >= 1) {
            if (scrollTop > 0) {
                currentScrollWheel = scrollTop - 100;
            }
        }
        else if (this.calculateWheelScroll(e) <= -1) {
            currentScrollWheel = scrollTop + 100;
            if (currentScrollWheel > (this.props.viewPortHeight - this.gridVerticalScroll.offsetHeight)) currentScrollWheel = this.props.viewPortHeight - this.gridVerticalScroll.offsetHeight;
        }
        if (currentScrollWheel < 0) currentScrollWheel = 0;
        this.handleVerticalScroll(e);
        this.handleVerticalScrollMouseUp(e);
    }

    handleHorizontalScroll(e) {
        let scrollLeft = e.target.scrollLeft;
        GridStore.setScrollPos(parseInt(this.gridVerticalScroll.scrollTop), parseInt(scrollLeft));
        this.externalDiv.style.left = "-" + scrollLeft + "px";
        this.handleScroll(scrollLeft);
        e.stopPropagation();
        e.preventDefault();
    }

    handleVerticalScroll(e) {   
        let scrollTop = e.target.scrollTop;
        if (e.target.id == '') {
            scrollTop = currentScrollWheel;
            if (this.props.viewPortHeight < scrollTop) scrollTop = this.props.viewPortHeight - this.gridVerticalScroll.offsetHeight;
            this.gridVerticalScroll.scrollTop = scrollTop;
            GridStore.setScrollPos(parseInt(scrollTop), parseInt(this.gridHorizontalScroll.scrollLeft));
        }
        if (e.target.scrollTop !== this.previousScrollTop) {
            GridStore.setScrollPos(parseInt(scrollTop), parseInt(this.gridHorizontalScroll.scrollLeft));
            this.generateItemOnScroll(scrollTop);
            this.checkMoreData(scrollTop);
        }
        this.previousScrollTop = scrollTop;

        e.stopPropagation();
        e.preventDefault();
    }

    handleVerticalScrollMouseUp() {
        let scrollTop = this.gridVerticalScroll.scrollTop;
        this.checkMoreData(scrollTop);
        // if (this.checkMoreData(scrollTop)) {
            // setTimeout(() => {
            //     this.props.loadExternalList(this.props.selectedListId)
            // }, 0);
        // }

    }

    async generateItemOnScroll(scrollTop) {
        const itemHeight = this.actualItemHeight;
        const firstIndex = Math.floor(scrollTop / itemHeight);
        const lastIndex = Math.min(this.props.itemsCount - 1, firstIndex + this.props.recordsPerBody);
        const displayStart = firstIndex;
        const displayEnd = lastIndex + 1;
            if (timeout) clearTimeout(timeout);
            const rows = await this.getRowsForNewData(this.props.source, displayStart, displayEnd);
            timeout = setTimeout(() => {
                this.loadRowsOnNewData(rows);
            }, 0);      
        }  

    getRowsForNewData = async (source, displayStart, displayEnd) =>
        new Promise((resolve) => {

            setTimeout(() => {
                let rows = [];
                let actuallRowIndexStart = displayStart;
                let visibleIndex = 0;
                const len = source.length;
                if (len > 0) {
                    let index = displayStart;
                    while (index < displayEnd) {
                        if (source && index > -1) {
                            rows.push({
                                visibleIndex: visibleIndex,
                                actuallRowIndexStart: actuallRowIndexStart,
                                rowData: source[index].rowData,
                                rowNumber:source[index].rowNumber
                            });
                        }
                        else {
                            rows.push({
                                visibleIndex: visibleIndex,
                                actuallRowIndexStart: actuallRowIndexStart,
                                rowData: undefined,
                                rowNumber:undefined
                            });

                        }

                        actuallRowIndexStart++;
                        visibleIndex++;
                        index++;
                    }
                }
                resolve(rows);
            }, 0);
        });


        checkMoreData(scrollTop) {
            const itemHeight = this.actualItemHeight;
            const firstIndex = Math.floor(scrollTop / itemHeight);
            let sequenceNo = firstIndex;
            let loadMoreData = false;
            const extraPage = 2;
            if (this.props.source.length >= this.props.pageSize - 1 || this.loadingMore) return false;
            else if (this.props.source.length < this.props.pageSize - 1) {
                if (sequenceNo + this.props.recordsPerBody > this.props.source.length - extraPage) {
                    sequenceNo = this.props.source.length;
                    loadMoreData = true;
                }
                if (loadMoreData) {
                    this.loadingMore = true;
                    if (this.gridLoadMore) this.gridLoadMore.removeAttribute('style');
                    setTimeout(() => {
                        this.props.loadExternalList(this.props.selectedListId)
                    }, 0);
                }
                currentStartIndex = sequenceNo;
                this.props.updateCurrentIndex(sequenceNo);
                return this.loadingMore;
            }
    
            currentStartIndex = sequenceNo;
            this.props.updateCurrentIndex(sequenceNo);
            return this.loadingMore;
    
        }

    getPos(ele) {
        let pos = this.offset(ele);
        let lefts = pos.left;
        return { left: lefts, top: pos.top };
    }

    offset(el) {
        let rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    scrollHorizentalGrid(step) {

        let scrollX = this.gridHorizontalScroll.scrollLeft;
        this.gridHorizontalScroll.scrollLeft = parseInt(scrollX + step);
        GridStore.setScrollPos(parseInt(this.gridVerticalScroll.scrollTop), parseInt(this.gridHorizontalScroll.scrollLeft));
        this.externalDiv.style.left = "-" + this.gridHorizontalScroll.scrollLeft + "px";
        this.handleScroll(this.gridHorizontalScroll.scrollLeft);
        if (!stopColumnAutoScroll) {
            setTimeout(() => {
                this.scrollHorizentalGrid(step)
            }, 20);
        }
    }

    handleScroll(scroll) {
        if (this.main) this.main.scrollLeft = scroll;
    }

    handleColumnSort(e, colData) {
        GridStore.setScrollPos(0);
        this.props.handleUpdateLoader(true);
        this.props.handleColumnSort(e, colData);
        e.stopPropagation();
    }

    loadRowsOnNewData(rows) {
        if(this.extGridView){
            // let trackRows = this.extGridView.getWrappedInstance();
            let trackRows = this.extGridView;
            if (!Object.compare(trackRows.props.visibleRow, rows)) {
                this.props.loadDataForVisibleRows(rows)
            }
        }
    }


    getGridRowViewContent() {
        this.rowHeight = this.getTextHeight();
        let externalView;

        externalView = <ExternalGridView rowHeight={this.rowHeight} ref={ref => this.extGridView = ref}/>

        return externalView;
    }

    updateWidthHeightGrid(contentRect) {
        if (!isEqual(this.props.dimensions, contentRect)) {
            let itemsCount = this.props.itemsCount + 1;
            let itemHeight = this.actualItemHeight;
            if (GridStore.isAddSymbolEnableList()) itemsCount += 1;
            const viewPortHeight = (itemHeight * itemsCount);
            const visibleCount = Math.floor((contentRect.height - contentRect.top) / itemHeight);
            let offsetWidth = this.gridDiv ? this.gridDiv.clientWidth : contentRect.width;
            let gridAreaWidth = contentRect.width;
            const gridData = {
                viewportWidth: this.props.viewportWidth,
                viewPortHeight: viewPortHeight,
                offsetWidth: offsetWidth,
                recordsPerBody: visibleCount,
                clientHeight: visibleCount * itemHeight,
                gridAreaWidth: gridAreaWidth
            }
            this.props.initGridLoad(gridData);
            this.props.onGridDimensionsChange(contentRect);

        }
    };

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getColumnHeadersCell(left, data) {
        const colWidth = this.getColumnWidth();
        let style = {
            width: colWidth, minWidth: 100, left: left
        }
        const displayName = data.displayName;        
        let draggedClass = "edu-cell";
        const sortInfoData = find(this.props.sortInfo, (item) => item.esField === data.esFieldName);
        let sortInfo = '';
        if (sortInfoData) {
            let sortingTypeClassName = '';
            if (sortInfoData.sortAsc) sortingTypeClassName = 'triangle-top';
            else sortingTypeClassName = 'triangle-bottom';
            sortInfo = <div className="edu-sorts"><span className="edu-sorts-sequence">{sortInfoData.sortOrder}</span><span className="sorting-arrow"><Glyphicon glyph={sortingTypeClassName} /></span></div>;
            draggedClass += " edu-sorter";
        }
        return <div className={draggedClass}
            id={"ColHeader" + data.ordinal}
            ref={(r) => this.ColHeader = r}
            draggable="false"
            data-itemid={data.ordinal}
            key={data.ordinal}
            style={style}
            onClick={(e) => this.handleColumnSort(e, data)}
        >
            {this.capitalizeFirstLetter(displayName)}
            {sortInfo}
        </div>
    }

    getColumnHeadersWizard(left, data) {
        const colWidth = this.getColumnWidth();
        const {selectSymbolCol, selectDateCol, fileUploadResponse, identifiedSymbolCol} = this.props;
        const displayName = data.displayName;
        let draggedClass = 'edu-cell';
        let title = '';
        if((data.isSymbolColumn && selectSymbolCol) || (data.isDateColumn && selectDateCol)){
            draggedClass = 'edu-cell wizard-select';
        }
        if(data.isSymbolColumn && selectSymbolCol)
        {
            title = fileUploadResponse && fileUploadResponse.distinctSymbolsCount && data.esFieldName == identifiedSymbolCol ? fileUploadResponse.distinctSymbolsCount 
                    + " Unique Security Identifiers matched with this column." : "" ;
            this.uniqueleft=left;
        }
        if(data.isDateColumn && selectDateCol)
        {
            this.uniqueleft=left;
        }
        let cursorState = 'not-allowed';
        let selectable = false;
        if(this.props.selectSymbolCol){
            if(MetricType.Date != data.type){
                cursorState = 'pointer';
                selectable = true;
            }
        }
        if(this.props.selectDateCol){
            if(MetricType.Date == data.type){
                cursorState = 'pointer';
                selectable = true;
            }
        }
        let style = {
            width:colWidth, minWidth:100, left:left, cursor:cursorState
        }
        const popoverFocus = (
            // id="tooltip-trigger-focus"
            <WizardHeaderPopOver className="tooltip-ext-uploader">
              {title}
            </WizardHeaderPopOver>
          );
        return (<OverlayTrigger placement="bottom"  overlay={popoverFocus}  key={data.ordinal}>
            <div className={draggedClass}
                onMouseDown={(selectable) ? selectSymbolCol ? () => this.props.onSymbolSet(data) :  () => this.props.onDateSet(data) : null}
                ref={(r) => this.ColHeader = r} id={"ColHeader" + data.ordinal} draggable="false"
                data-itemid={data.ordinal}
                key={data.ordinal} style={style}
                >
                {this.capitalizeFirstLetter(displayName)}
            </div>
        </OverlayTrigger>);
    }

    getColumnsHeaders() {
        let columns = [];
        if (this.props.columns) {

            let columnData = this.props.columns;

            left = 0.0;
            const { showStartWizard } = this.props; //fileUploadResponse
            let colWidth = this.getColumnWidth();
            if (!showStartWizard) {
                each(columnData, (colData) => {
                    columns.push(this.getColumnHeadersCell(left, colData));
                    left += colWidth;
                }, this);
            } else {
                each(columnData, (colData) => {
                    columns.push(this.getColumnHeadersWizard(left, colData));
                    left += colWidth;
                }, this);
            }
            this.totalWidth = left;
        }
        return columns;
    }

    render() {

        this.vH = this.listExplorerElm ? this.listExplorerElm.getBoundingClientRect().height : 0;

        const { selectSymbolCol, selectDateCol,showDateSelect, showFinishButton,showStartWizard } = this.props;
        const { height, top } = this.props.dimensions;
        this.gridAreaHeight = parseInt(height - top + 12);
        const gridColumns = this.getColumnsHeaders();
        const gridRowViewContent = this.getGridRowViewContent();
        let width = this.props.viewportWidth;
        
        return (
            <div className="edu-container" ref={(ref) => this.gridBlock = ref} >
                <ReactHeight className="edu-containing" onHeightReady={(height) => {
                    const rect = this.mainExternalDiv.getBoundingClientRect();
                    let dimensions = {
                        top:rect.top,
                        right:rect.right,
                        bottom:rect.bottom,
                        left:rect.left,
                        width:rect.width,
                        height:height
                    };
                    this.updateWidthHeightGrid(dimensions);
                }}>
                     <div className={"ext-wizard-backdrop " + (showStartWizard ? 'show' : 'hidden')}></div>
                     
                    <div className="edu-wizard">
                        <div className="ext-wizard">
                            {!showStartWizard &&
                                <div ref={(r) => this.customFilter = r} className="ext-wizard-placeholder"></div>
                            }
                            {
                                showStartWizard && <div className="ext-wizarding">
                                {((selectSymbolCol) && !showFinishButton) && <div className="ext-wizard-btn">
                                    <span className="btn-box">
                                        {selectDateCol && <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={() => this.props.moveToPrevious()}>Back</button>}
                                        <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={() => this.props.moveToNext()}>Next</button>
                                    </span>
                                </div>
                                }
                                {(showFinishButton || selectDateCol) && <div className="ext-wizard-btn">
                                    <span className="btn-box">
                                        {selectDateCol && <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={() => this.props.moveToPrevious()}>Back</button>}
                                        <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={() => this.props.moveToDateSelect()}>Next</button>
                                    </span>
                                </div>
                                }
                                {(selectSymbolCol || selectDateCol) && <div className="ext-wizard-msg">
                                    {selectSymbolCol &&
                                        <div className="ext-wizard-text">
                                            <h6>Tell us how to link your data to ours. Select the unique security identifier.</h6>
                                            <p>The unique security identifier is typically a symbol column. We recognize Symbols (O'Neil, Refinitiv&trade;), SEDOL and ISIN.</p>
                                        </div>
                                    }
                                    {selectDateCol &&
                                        <div className="ext-wizard-text">
                                            <h6>Tell us how to link your data to ours. Select the primary date column.</h6>
                                            <p>Only one date per data set can be used to display your data.</p>
                                        </div>
                                    }
                                    </div>
                                }
                            </div>
                            }
                        </div>
                    </div>
                    {/* {showStartWizard && <div className="edu-identifier">
                        <div className="ext-action-tips">
                            {selectSymbolCol && <span className="ext-action-txt" style={{ left: this.uniqueleft }}>Unique Security Identifier </span>}
                            {selectDateCol && <span className="ext-action-txt" style={{ left: this.uniqueleft }}>Use This Date </span>}
                        </div>
                    </div>} */}
                    <div className="edu-grid-listing">
                    {/*  style={{ height: `${this.vH - 80}px` }} */}
                        <div className="edu-grid-list-holder" ref={(div) => { this.mainExternalDiv = div }} id="main-grid-panel" tabIndex={1}>
                            <div className="edu-grid external-data-uploader-head" style={{ width: this.totalWidth }}>
                                {showStartWizard && <div className="edu-identifier">
                                    <div className="ext-action-tips">
                                        {selectSymbolCol && <span className="ext-action-txt" style={{ left: this.uniqueleft }}>Unique Security Identifier </span>}
                                        {selectDateCol && <span className="ext-action-txt" style={{ left: this.uniqueleft }}>Use This Date </span>}
                                    </div>
                                </div>}
                                <div className="edu-grid-header" ref={(r) => this.main = r} style={{ width: this.totalWidth}}>                                
                                    <div className="edu-row">
                                        {gridColumns}
                                    </div>
                                </div>
                            </div>

                            <div className="edu-grid external-data-uploader-body " style={{ width: this.totalWidth }}>
                                <div className={"edu-grid-body " + this.props.fontSizeClass} id="externalDiv" style={{ width: this.totalWidth, height: `${this.gridAreaHeight}px`}}>                                
                                    {gridRowViewContent}
                                </div>
                                <div className="grid-vertical-scrollbar" id='verticalScroll' style={{ height: `${this.gridAreaHeight}px` }}>
                                    <div id='gridVerticalScroll' style={{ overflow: 'hidden' }}>
                                        <div style={{ width: "100%", height: this.props.viewPortHeight }}></div>
                                    </div>
                                </div>
                                <ScrollBar scrollId="gridVerticalScroll" vScroll={true} mouseUp={this.handleVerticalScrollMouseUp} />
                                {!this.props.loading ? <div id="grid-load-more" ref={(div) => { this.gridLoadMore = div }} className='grid-body-loading' style={{ display: 'none' }}><div className="list-bottom-loading x-small-bold">{LocalizationStore.getTranslatedData("ri_loading", "Loading")}</div></div> : ''}
                            </div>
                        </div>
                        <ScrollBar scrollId='main-grid-panel' hScroll={true} vScroll={false} />
                    </div>
                </ReactHeight>

                <div id="grid-drag-ghost-image"></div>
                <PortalContainer><div id="grid-row-tooltip" className="grid-row-tooltip"></div></PortalContainer>
                {showDateSelect && <ExternalDateSelectWizard />}
                {/* <div id="arrow11" className="icn-up-down-arrow"></div> */}
                <table className="measure-height" id="messureHeight" style={{ visibility: 'hidden' }}><tbody><tr className="grid-row grid-row-even"><td className="grid-cell"><span className="customFont" id="textSpan">Sample Google</span></td></tr></tbody></table>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    initGridLoad: (value) => dispatch(initGridLoad(value)),
    onGridDimensionsChange: (dim) => dispatch(onGridDimensionsChange(dim)),
    loadDataForVisibleRows: (dim) => dispatch(loadDataForVisibleRows(dim)),
    onSymbolSet: (data) => dispatch(setSymbolColumn(data)),
    onDateSet: (data) => dispatch(setDateColumn(data)),
    handleColumnSort: (e, value) => dispatch(handleColumnSort(e, value)),
    handleUpdateLoader:(value)=> dispatch(handleUpdateLoader(value)),
    moveToNext: () => dispatch(moveToNextState()),
    moveToPrevious: () => dispatch(moveToPreviousState()),
    loadExternalList: (listId, pageNum) => dispatch(loadExternalList(listId, pageNum)),
    updateCurrentIndex:(lastIndex)=>dispatch(updateCurrentIndex(lastIndex)),
    moveToDateSelect: () => dispatch(setDateFormatDialog())

})
const mapStateToProps = ({ externalDataUploader }) => {
    const {
        isNewList, loading, source, viewPortHeight, viewportWidth, dimensions, gridAreaWidth,
        recordsPerBody,showStartWizard, selectSymbolCol, selectDateCol,symbolColChanged,dateColChanged,
        showDateSelect,showFinishButton, rowSource, identifiedSymbolCol,explorerWidth
    } = externalDataUploader.ExternalDataListViewReducer;
    const {  fileUploadResponse } = externalDataUploader.ExternalDataUploaderReducer;
    const { headers, listId, sortInfo, count } = source; 
    return ({
        source: rowSource, columns: headers, selectedListId: listId, isNewList, loading, viewPortHeight,
        viewportWidth, dimensions, gridAreaWidth, recordsPerBody, showStartWizard,
        fileUploadResponse, selectSymbolCol, selectDateCol,symbolColChanged,dateColChanged, showDateSelect,
        showFinishButton, sortInfo, pageSize: count, identifiedSymbolCol,explorerWidth
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExternalGrid);