import keyMirror from "keymirror";

export const LoginConstants = {
  ActionTypes: keyMirror({
    LOGIN: null,
    NONE: null,
    CHANGE_PASSWORD:null,
    GET_AUTHROUTE: null,
    GET_USERINIT:null,
    ON_SSO_LOGIN_FAILED:null
  })
};
