import React from "react";
import ListManagerTabType from "ListManagerTabType";
import ListExplorerStore from "ListExplorerStore";
import LocalizationStore from 'LocalizationStore';
import UserInfoUtil from 'UserInfoUtil'
import BaseServiceApi from 'BaseServiceApi'

export default class ExplorerTop extends React.Component { 

  render() {
    const tabCollection = this.props.tabCollection;
    const selectedTabKey = this.props.selectedTabKey;
    const onTabSelectionChanged = this.props.onTabSelectionChanged;
    const self = this;
    return (
      <div className="tab-view-left-top-wrap">
        <div className="tab-view-left-top">          
          <div className="title">{LocalizationStore.getTranslatedData('sys_database', "Database")}</div>
          <div className="tab-switch listExplorerDbClass">
            <ul>
              {tabCollection.map((tabitem) => {
                let blockType = "", tabLink = "", active = "", selected = "", entry, selectedNode = {};

                switch (tabitem.TabType) {
                  case (ListManagerTabType.Oneil):
                    entry = ListExplorerStore.findOneilOwnershipNodeById(-1);
                    blockType = "globe-block"; tabLink = "icn-globe-tab";
                    if (selectedTabKey === ListManagerTabType.Oneil) active = "active";
                    if (Number(self.props.nodeId) === -1) selected = "selected";
                    break;

                  case (ListManagerTabType.Ownership):
                    entry = ListExplorerStore.findOneilOwnershipNodeById(-2);
                    blockType = "ownership-block"; tabLink = "icn-ownership-tab";
                    if (selectedTabKey === ListManagerTabType.Ownership) active = "active";
                    if (Number(self.props.nodeId) === -2) selected = "selected";
                    break;
                  case (ListManagerTabType.External):
                    entry = ListExplorerStore.findOneilOwnershipNodeById(-3);
                    blockType = "external-block"; tabLink = "icn-external-tab";
                    if(selectedTabKey == ListManagerTabType.External)  active = "active";
                    break;
                  default:
                    break;
                }
                if (entry) {
                  selectedNode.nodeId = entry.nodeId.low;
                  selectedNode.parentNodeId = entry.parentNodeId;
                  selectedNode.rootParentNodeId = null;
                  selectedNode.sourceListId = null;
                  selectedNode.categoryType = entry.categoryType;
                  selectedNode.name = entry.name;
                  selectedNode.listType = entry.listType;
                  selectedNode.className = null;
                  selectedNode.listCategory = entry.listCategory;
                  selectedNode.listTreeItemLevel = entry.listTreeItemLevel;
                  selectedNode.sourceCategoryType = entry.sourceCategoryType;
                  selectedNode = JSON.stringify(selectedNode);
                }

                if (tabitem.Header == ListManagerTabType.Oneil) {
                  return (
                    <li key={tabitem.Header} className={blockType + ' ' + active + ' ' + selected} data-tabkey={tabitem.Header} data-node-id="-1" onClick={!this.props.loading ? onTabSelectionChanged : null}>
                      <a draggable={false} data-node-id="-1" data-selectednode={selectedNode}>
                        <i className={tabLink} data-tabkey={tabitem.Header} data-node-id="-1" data-selectednode={selectedNode}></i>
                        <span className="tab-text-change" data-tabkey={tabitem.Header} data-node-id="-1" data-selectednode={selectedNode}>{LocalizationStore.getTranslatedData('lp_oneil', "O'NEIL")}</span>
                      </a>                      
                    </li>
                  );
                } 
                else if (tabitem.Header == ListManagerTabType.Ownership) {
                  return (
                    <li key={tabitem.Header} className={blockType + ' ' + active + ' ' + selected}  data-tabkey={tabitem.Header} data-node-id="-2" onClick={onTabSelectionChanged}>
                      <a draggable={false} data-node-id="-2" data-selectednode={selectedNode}>
                        <i className={tabLink} data-tabkey={tabitem.Header} data-node-id="-2" data-selectednode={selectedNode}></i>
                        <span className="tab-text-change" data-tabkey={tabitem.Header} data-node-id="-2" data-selectednode={selectedNode}>{LocalizationStore.getTranslatedData('lp_ownership', tabitem.Header)}</span>
                      </a>                      
                    </li>                   
                  );
                } 
                else {
                  let EntitlementType = BaseServiceApi.rayData["EntitlementType"];
                  let externalUploadedEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement);
                  if(externalUploadedEntitlement){
                  return (
                    <li key={tabitem.Header} className={blockType + ' ' + active + ' '+ selected}>
                      <a data-tabkey = {tabitem.Header} data-node-id="-3" data-selectednode={selectedNode}>
                        <i className={tabLink} data-tabkey = {tabitem.Header} data-node-id="-3" onClick={ListExplorerStore.getSelectedTabType() != ListManagerTabType.External ? onTabSelectionChanged : undefined}></i>
                        <span className="tab-text-change" data-tabkey = {tabitem.Header} data-node-id="-3" onClick={ListExplorerStore.getSelectedTabType() != ListManagerTabType.External ? onTabSelectionChanged : undefined}>{LocalizationStore.getTranslatedData('lp_external', tabitem.Header)}</span>
                      </a>                      
                    </li>
                  );
                }
                }
              })}
            </ul>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    )
  }
}
