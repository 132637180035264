import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleLiqFilter } from '../../../../../Actions/ComparisonActions';
import LiquidityRatingsFilter from "RayCustomControls/RotationGraph/LiquidityRatingsFilter.jsx"

class LiqRatingsFilterView extends Component {

    closeLiqFilterWindow = () => {
        this.props.toggleLiqFilter(false);
    }

    render() {
        const { openLiquidityRatings } = this.props.liqFilterData;

        return (
            <div className="compare-Liquidity-ratings">
                {openLiquidityRatings && <LiquidityRatingsFilter closeLiqFilterWindow={this.closeLiqFilterWindow} openLiquidityRatingsFilterSetting={openLiquidityRatings} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liqFilterData: state.comparisonGraph.liqFilter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLiqFilter: (isOpen) => dispatch(toggleLiqFilter(isOpen))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiqRatingsFilterView);