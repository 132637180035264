import PropTypes from 'prop-types';
import React, { Component } from "react";
import Measure from "react-measure";
import ThemeHelper from "ThemeHelper";
import GridLineUtil from "GridLineUtil";
import SettingsStore from "SettingsStore";
import stringUtil from '../../Utils/StringUtil';
import LabelText from './Timeline/LabelText.jsx';
import { PrintMode } from "../../print/printmode.js";
import ConsoleStore from "ConsoleStore";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";
import { SettingsConstants } from '../../Constants/SettingsConstants';
import { connect } from "react-redux";

let shouldHandleResize = true;
class RayChart extends Component {
  constructor(props) {
    super(props);
    this.trackpriceReqArgs = props.trackpriceReqArgs
    this.handleResize = this.handleResize.bind(this);
    this.onContextMenuClick = this.onContextMenuClick.bind(this);
    // this.test = this.test.bind(this);

    this.state = {
      nodeWidth: props.nodeWidth,
      leftScaleWidth: props.leftScaleWidth,
      rightScaleWidth: props.righscaleWidth,
      timeLine: props.TimeLine,
      scale: props.scale,
      height: props.height ? props.height : 0.0,
      width: props.width ? props.width : 0.0,
      showEps: props.showEps,
      showHorizontalGrid: props.showHorizontalGrid !== undefined ? props.showHorizontalGrid : true,
      showVerticalGrid: props.showVerticalGrid !== undefined ? props.showVerticalGrid : true,
      showLabels: props.showLabels !== undefined ? props.showLabels : true,
      isIntraday: props.isIntraday,
      isTI: props.isTI,
      isKpi: props.isKpi,
      refresh: true
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const refresh = !this.state.refresh;
    this.setState({
      timeLine: nextProps.TimeLine, scale: nextProps.scale,
      leftScaleWidth: nextProps.leftScaleWidth,
      showEps: nextProps.showEps,
      isIntraday: nextProps.isIntraday,
      isTI: nextProps.isTI,
      isKpi: nextProps.isKpi,
      showHorizontalGrid: nextProps.showHorizontalGrid !== undefined ? nextProps.showHorizontalGrid : true,
      showVerticalGrid: nextProps.showVerticalGrid !== undefined ? nextProps.showVerticalGrid : true,
      showLabels: nextProps.showLabels !== undefined ? nextProps.showLabels : true, 
      refresh: refresh
    });
  }

  UNSAFE_componentWillMount = () => {
    if (PrintMode.printing) {
      ConsoleStore.addChangeListener(this.ConsoleStateChange);
      SettingsStore.addChangeListener(this.SettingsStoreChange);
    }
  }

  componentWillUnmount() {
    if (PrintMode.printing) {
      ConsoleStore.removeChangeListener(this.ConsoleStateChange);
      SettingsStore.removeChangeListener(this.SettingsStoreChange);
      shouldHandleResize = true;
    }
  }

  ConsoleStateChange() {
    const currentAction = ConsoleStore.getCurrentAction();
    if (currentAction === ConsoleConstants.ActionTypes.SET_CHART_LOADED) {
      shouldHandleResize = false;
    }
  }

  SettingsStoreChange() {
    const currentAction = SettingsStore.getCurrentAction();
    if (currentAction === SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT) {
      shouldHandleResize = true;
    }
  }

  handleResize(dimensions) {
    if (shouldHandleResize) {
      let height = dimensions.height;
      let width = PrintMode.printing ? PrintMode.width : dimensions.width;
      let nodeCount = this.getNumberOfChartNodes(width);
      let resizeObj = { ...dimensions, nodeCount };
      if (this.props.onChartResize !== undefined) { this.props.onChartResize(resizeObj); }
      this.setState({ height: height, width: width });
    }
  }

  getNumberOfChartNodes(width) {
    var nodeCount = width / this.state.nodeWidth;
    return Math.ceil(nodeCount);
  }


  drawGrid() {
    if (!this.gridCanvas || this.state.height === 0.0 || this.state.width === 0.0) {
      return;
    }
    let ctx = this.gridCanvas.getContext("2d");
    ctx.canvas.height = this.state.height;
    ctx.canvas.width = this.state.width;
    ctx.clearRect(0, 0, this.state.width, this.state.height);
    ctx.lineWidth = 1;
    ctx.translate(0.5, 0.5);
    ctx.imageSmoothingEnabled = true;
    ctx.save();
    if (this.state.showVerticalGrid) {
      this.drawverticalLine(ctx);
    }
    if (this.state.showHorizontalGrid) { this.drawHorizontalLine(ctx); }
  }
  drawHorizontalLine(ctx) {
    if (!this.state.scale) {
      return;
    }
    let mhLines = this.state.scale.mhLines;
    if (!mhLines || mhLines.length === 0) {
      return;
    }
    let width = this.state.width;
    ctx.beginPath();
    ctx.strokeStyle = GridLineUtil.getGridLineColor();
    ctx.setLineDash([1, 3]);
    let max = mhLines.length;
    for (let i = 0; i < max; i++) {
      let label = mhLines[i];

      if (label.Type === 0) {
        continue;
      }
      ctx.moveTo(0, label.YAxis);
      ctx.lineTo(width, label.YAxis);

    }
    ctx.stroke();
    ctx.closePath();

    if (!this.state.scale.solidLine) { return; }
    ctx.beginPath();
    ctx.strokeStyle = GridLineUtil.getGridLineColor();
    ctx.setLineDash([]);
    let solidLine = this.state.scale.solidLine;
    max = solidLine.length;
    for (let i = 0; i < max; i++) {
      let label = solidLine[i];
      ctx.moveTo(0, label.YAxis);
      ctx.lineTo(width, label.YAxis);
    }
    ctx.stroke();
    ctx.closePath();
  }
  drawverticalLine(ctx) {
    let timeLine = this.state.timeLine;
    if (!timeLine || timeLine.length === 0) {
      return;
    }

    let max = timeLine.length;
    let width = this.state.width ? this.state.width : 0;
    let nodeWidth = this.props.nodeWidth;
    ctx.beginPath();
    ctx.strokeStyle = GridLineUtil.getGridLineColor();
    ctx.setLineDash([1, 3]);
    for (let i = 0; i < max; i++) {
      var timelineItem = timeLine[i];
      if (!timelineItem) { break; }
      let xAxis = width - (timelineItem.XAxis * nodeWidth);
      if (timelineItem.XLine !== 1 && timelineItem.XLine !== 2 && timelineItem.XLine !== 3 && timelineItem.XLine !== 4 && timelineItem.XLine !== 5) {
        continue;
      }
      if (xAxis < 0.0) {
        break;
      }
      if (timelineItem.XLine !== 6) {
        if (timelineItem.XLine !== 1 && timelineItem.XLine !== 3 && timelineItem.XLine !== 4) {
          continue;
        }
        ctx.moveTo(xAxis, 0);
        ctx.lineTo(xAxis, this.state.height);
      }
    }
    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.strokeStyle = GridLineUtil.getGridLineColor();
    ctx.setLineDash([]);
    for (let i = 0; i < max; i++) {
      timelineItem = timeLine[i];
      if (!timelineItem) { break; }
      let xAxis = width - (timelineItem.XAxis * nodeWidth);
      if (timelineItem.XLine !== 1 && timelineItem.XLine !== 2 && timelineItem.XLine !== 3 && timelineItem.XLine !== 4 && timelineItem.XLine !== 5) {
        continue;
      }
      if (xAxis < 0.0) {
        break;
      }
      if (timelineItem.XLine !== 6) {
        if (timelineItem.XLine === 1 || timelineItem.XLine === 3 || timelineItem.XLine === 4) {
          continue;
        }
        ctx.moveTo(xAxis, 0);
        ctx.lineTo(xAxis, this.state.height);
      }
    }
    ctx.stroke();
    ctx.closePath();
  }

  getBoundingClientRect() {
    return this.chartContainer.getBoundingClientRect();
  }

  drawRightScale() {
      if (!this.leftScale || !this.state.showLabels ||
          !this.rightScale ||
          !this.state.scale || stringUtil.isEmpty(this.state.scale)) {
          return(<div></div>);
      }
      const source = this.state.scale.mhLines;
      const consoleSettings = SettingsStore.getConsoleSettings();
      const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
      const videoMode = tabDataGraphSettings.videoMode;
      const textColor = ThemeHelper.getThemedBrush("scaleText");
      let leftMargin = videoMode === true ? 106 : 38;
      const fSize = videoMode === true ? '32pt' : '9pt';
      if (this.state.isTI)
          leftMargin = 30;
      if(this.state.isKpi){
        leftMargin = 40;
      }
      return (
          <svg className="svg price-right-scale">
              {source && source.map((itm, i) => !stringUtil.isEmpty(itm.Label) && !isNaN(itm.YAxis) &&
                  <LabelText
                      key={i}
                      textAnchor="end"
                      isHighlighted={true}
                      textValue={itm.Label.toString()}
                      style={{
                            font: PrintMode.printing ? 'calibrib' : 'calibri',
                            fontSize: PrintMode.printing ? '7pt' : fSize,
                            fill: textColor
                        }}
                      dx="0"
                      dy="0"
                      textPosX={leftMargin}
                      textPosY={itm.YAxis + 3} />)}
          </svg>
      );
  }
  drawLeftScale() {
      if (!this.leftScale || !this.state.showLabels ||
          !this.rightScale ||
          !this.state.scale || stringUtil.isEmpty(this.state.scale)) {
          return (<div></div>);
      }
      const source = this.state.scale.mhLines;
      const textColor = ThemeHelper.getThemedBrush("scaleText");
      if (!this.state.isIntraday) {
          switch (this.state.showEps) {
          case "FFO":
          case "EPS":
              return (
                  <svg className="svg price-left-scale">
                      {source && source.map((itm, i) => itm.eLabel &&
                          <LabelText
                              key={i}
                              textAnchor="end"
                              isHighlighted={true}
                              textValue={itm.eLabel}
                              style={{
                                    font: PrintMode.printing ? 'calibrib' : 'calibri',
                                    fontSize: PrintMode.printing ? '7pt' : '12px',
                                    fill: textColor
                                }}
                              dx="0"
                              dy="0"
                              
                              textPosX={PrintMode.printing ? 30 : 36}
                              textPosY={itm.YAxis + 3} />)}
                  </svg>
              );
          case "RPS":
              return (
                  <svg className="svg price-left-scale">
                      {source && source.map((itm, i) => itm.rLabel &&
                          <LabelText
                              key={i}
                              textAnchor="end"
                              isHighlighted={true}
                              textValue={itm.rLabel}
                              style={{
                                  font: PrintMode.printing ? 'calibrib' : 'calibri',
                                  fontSize: PrintMode.printing ? '7pt' : '12px',
                                  fill: textColor
                              }}
                              dx="0"
                              dy="0"
                              textPosX={PrintMode.printing ? 30 : 36}
                              textPosY={itm.YAxis + 3} />)}
                  </svg>
              );
          case "BV":
              return (
                  <svg className="svg price-left-scale">
                      {source && source.map((itm, i) => itm.tLabel &&
                          <LabelText
                              key={i}
                              textAnchor="end"
                              isHighlighted={true}
                              textValue={itm.tLabel}
                              style={{
                                  font: PrintMode.printing ? 'calibrib' : 'calibri',
                                  fontSize: PrintMode.printing ? '7pt' : '12px',
                                  fill: textColor
                              }}
                              dx="0"
                              dy="0"
                              textPosX={PrintMode.printing ? 30 : 36}
                              textPosY={itm.YAxis + 3} />)}
                  </svg>
              );
          case "CFS":
              return (
                  <svg className="svg price-left-scale">
                      {source && source.map((itm, i) => itm.t1Label &&
                          <LabelText
                              key={i}
                              textAnchor="end"
                              isHighlighted={true}
                              textValue={itm.t1Label}
                              style={{
                                  font: PrintMode.printing ? 'calibrib' : 'calibri',
                                  fontSize: PrintMode.printing ? '7pt' : '12px',
                                  fill: textColor
                              }}
                              dx="0"
                              dy="0"
                              textPosX={PrintMode.printing ? 30 : 36}
                              textPosY={itm.YAxis + 3} />)}
                  </svg>
              );
          case "DIV":
              return (
                  <svg className="svg price-left-scale">
                      {source && source.map((itm, i) => itm.t2Label &&
                          <LabelText
                              key={i}
                              textAnchor="end"
                              isHighlighted={true}
                              textValue={itm.t2Label}
                              style={{
                                  font: PrintMode.printing ? 'calibrib' : 'calibri',
                                  fontSize: PrintMode.printing ? '7pt' : '12px',
                                  fill: textColor
                              }}
                              dx="0"
                              dy="0"
                              textPosX={PrintMode.printing ? 30 : 36}
                              textPosY={itm.YAxis + 3} />)}
                  </svg>
              );
          case "FCF":
              return (
                  <svg className="svg price-left-scale">
                      {source && source.map((itm, i) => itm.t3Label &&
                          <LabelText
                              key={i}
                              textAnchor="end"
                              isHighlighted={true}
                              textValue={itm.t3Label}
                              style={{
                                  font: PrintMode.printing ? 'calibrib' : 'calibri',
                                  fontSize: PrintMode.printing ? '7pt' : '12px',
                                  fill: textColor
                              }}
                              dx="0"
                              dy="0"
                              textPosX={PrintMode.printing ? 30 : 36}
                              textPosY={itm.YAxis + 3} />)}
                  </svg>
              );
          case "EXD":
              return (
                  <svg className="svg price-left-scale">
                      {source && source.map((itm, i) => itm.t4Label &&
                          <LabelText
                              key={i}
                              textAnchor="end"
                              isHighlighted={true}
                              textValue={itm.t4Label}
                              style={{
                                  font: PrintMode.printing ? 'calibrib' : 'calibri',
                                  fontSize: PrintMode.printing ? '7pt' : '12px',
                                  fill: textColor
                              }}
                              dx="0"
                              dy="0"
                              textPosX={PrintMode.printing ? 30 : 36}
                              textPosY={itm.YAxis + 3} />)}
                  </svg>
              );
          default:
              break;
          }
      }
  }

  clearScale() {
      if (!this.leftScale ||
          !this.rightScale ||
          !this.state.scale || stringUtil.isEmpty(this.state.scale)) {
          return;
      }

      let leftRect = this.leftScale.getBoundingClientRect();
      let leftCTx = this.leftScale.getContext("2d");
      leftCTx.canvas.height = leftRect.height;
      leftCTx.canvas.width = leftRect.width;
      leftCTx.clearRect(0, 0, leftRect.width, leftRect.height);

      if (!this.state.showHorizontalGrid) {
          return;
      }

      let rightRect = this.rightScale.getBoundingClientRect();
      let rightCTx = this.rightScale.getContext("2d");
      rightCTx.canvas.height = rightRect.height;
      rightCTx.canvas.width = rightRect.width;
      rightCTx.clearRect(0, 0, rightRect.width, rightRect.height);
  }
  
  drawScale() {
    if (!this.leftScale ||
      !this.rightScale ||
      !this.state.scale || stringUtil.isEmpty(this.state.scale)) {
      return;
    }

    let fontColor = ThemeHelper.getThemedBrush("scaleText");
    let leftRect = this.leftScale.getBoundingClientRect();
    let leftCTx = this.leftScale.getContext("2d");
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    let videoMode = tabDataGraphSettings.videoMode;
    leftCTx.canvas.height = leftRect.height;
    leftCTx.canvas.width = leftRect.width;
    leftCTx.clearRect(0, 0, leftRect.width, leftRect.height);

    if (!this.state.showLabels) {
      return;
    }

    let rightRect = this.rightScale.getBoundingClientRect();
    let rightCTx = this.rightScale.getContext("2d");
    rightCTx.canvas.height = rightRect.height;
    rightCTx.canvas.width = rightRect.width;
    rightCTx.clearRect(0, 0, rightRect.width, rightRect.height);
    leftCTx.font = "9pt Calibri";
    rightCTx.font = videoMode === true ? "32pt Calibri" : "9pt Calibri";
    leftCTx.fillStyle = fontColor;
    rightCTx.fillStyle = fontColor;
    leftCTx.textAlign = "right";
    rightCTx.textAlign = "right";
    let length = this.state.scale.mhLines.length;
    for (let i = 0; i < length; i++) {
      let scaleItem = this.state.scale.mhLines[i];
      if (!this.state.isIntraday) {
        switch (this.state.showEps) {
          case "FFO":
          case "EPS":
            if (scaleItem.eLabel && !(scaleItem.eLabel === " ")) {
              leftCTx.fillText(scaleItem.eLabel, 36, scaleItem.YAxis + 3);
            }
            break;
          case "RPS":
            if (scaleItem.rLabel && !(scaleItem.rLabel === " ")) {
              leftCTx.fillText(scaleItem.rLabel, 36, scaleItem.YAxis + 3);
            }
            break;
          case "BV":
            if (scaleItem.tLabel && !(scaleItem.tLabel === " ")) {
              leftCTx.fillText(scaleItem.tLabel, 36, scaleItem.YAxis + 3);
            }
            break;
          case "CFS":
            if (scaleItem.t1Label && !(scaleItem.t1Label === " ")) {
              leftCTx.fillText(scaleItem.t1Label, 36, scaleItem.YAxis + 3);
            }
            break;
          case "DIV":
            if (scaleItem.t2Label && !(scaleItem.t2Label === " ")) {
              leftCTx.fillText(scaleItem.t2Label, 36, scaleItem.YAxis + 3);
            }
            break;
          case "FCF":
            if (scaleItem.t3Label && !(scaleItem.t3Label === " ")) {
              leftCTx.fillText(scaleItem.t3Label, 36, scaleItem.YAxis + 3);
            }
          case "EXD":
            if (scaleItem.exdLabel && !(scaleItem.exdLabel === " ")) {
              leftCTx.fillText(scaleItem.exdLabel, 36, scaleItem.YAxis + 3);
            }
            break;
          default:
            break;
        }
      }
      if (!(scaleItem.Label === " ")) {
        let leftMargin = videoMode === true ? 106 : 38;
        if (this.state.isTI)
          leftMargin = 30;
        if(this.state.isKpi){
          leftMargin = 40;
        }
        rightCTx.fillText(scaleItem.Label, leftMargin, scaleItem.YAxis + 3);
      }
    }
  }

  onContextMenuClick(e) {
    e.preventDefault();
    return false;
  }
  render() {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const videoMode = tabDataGraphSettings.videoMode;
    const useCanvas = tabDataGraphSettings.useCanvas;
    if (useCanvas) {
      this.drawScale();
    } else {
      this.clearScale();
    }
    this.drawGrid();

    if (this.props.gridChanged) {
      this.drawGrid()
    }
    try {

    }
    catch (e) {
      console.error(e);
    }

    const displayClass = videoMode === true
      ? "ray-video-container rayVideoContainer"
      : "ray-chart-container rayChartContainer";

    const refresh = this.state.refresh;
    return (
      <div ref={(ref) => this.chartContainer = ref} className={displayClass} /*style={{zIndex: '1061'}}*/ id="rayChartContainer">
        <div className="chart-panel-left" onContextMenu={this.onContextMenuClick} style={{ width: this.state.leftScaleWidth }}>
          <canvas
            className="ray-canvas"
            ref={(ref) => this.leftScale = ref}
            data-disable-track-price="true"
            style={{ fitPosition: "fill" }}>
          </canvas>
          {!useCanvas && refresh === true && this.drawLeftScale()}
          {!useCanvas && refresh === false && this.drawLeftScale()}
        </div>
        <Measure bounds onResize={(contentRect) => { this.handleResize(contentRect.bounds) }} >
          {({ measureRef }) =>
            <div className="chart-panel-center" onContextMenu={this.onContextMenuClick} ref={measureRef} style={{  }}>
              <canvas
                className="ray-canvas"
                onContextMenu={this.onContextMenuClick}
                ref={(ref) => this.gridCanvas = ref}
                style={{ fitPosition: "fill" }}>
              </canvas>
              {this.props.children}
            </div>
          }
        </Measure>
        <div className="chart-panel-right" onContextMenu={this.onContextMenuClick} style={{ width: this.state.rightScaleWidth }}>
          <canvas
            className="ray-canvas"
            ref={(ref) => this.rightScale = ref}
            style={{ fitPosition: "fill" }}>
          </canvas>
          {!useCanvas && refresh === true && this.drawRightScale()}
          {!useCanvas && refresh === false && this.drawRightScale()}
        </div>
      </div>
    );
  }
}

RayChart.defaultProps = {
    isPrintMode: PrintMode.printing // For testing set this to true
}

RayChart.propTypes = {
  leftScaleWidth: PropTypes.number,
  rightScaleWidth: PropTypes.number,
  nodeWidth: PropTypes.number,
  onChartResize: PropTypes.func,
  scale: PropTypes.object.isRequired,
  //TimeLine: PropTypes.array.isRequired,
  showEps: PropTypes.string,
  showVerticalGrid: PropTypes.bool,
  showHorizontalGrid: PropTypes.bool,
  showLabels: PropTypes.bool,
  isIntraday: PropTypes.bool,
  isTI: PropTypes.bool,
  isKpi: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  trackpriceReqArgs: PropTypes.object
};

const mapStateToProps = ({appColor}) => {
  const { isThemeChanged, gridChanged } = appColor;
  return { isThemeChanged, gridChanged };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(RayChart);
