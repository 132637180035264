import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetCreateTreeNodeRequest extends BaseServiceRequest {
    constructor(dbType, parentNodeId, name, nodeType, categoryType, sourceListId) {
        super();
        super.addRequestUri("addtreenode.pb");
        super.addRequestParameter("databaseid", dbType.toString());
        if (parentNodeId) super.addRequestParameter("parentnodeid", parentNodeId.toString());
        super.addRequestParameter("name", name);
        if (nodeType) super.addRequestParameter("nodetypeid", nodeType.toString());
        if (categoryType) super.addRequestParameter("nodecategoryid", categoryType.toString());

        if (sourceListId != null && sourceListId != "") {
            super.addRequestParameter("sourcelistid", sourceListId.toString());
        }
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListExplorerNodeResponse";
    }

}
export default GetCreateTreeNodeRequest;