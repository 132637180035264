import keyMirror from "keymirror";

export const ManagerLayoutConstants = {
  ActionTypes: keyMirror({
    ON_REORDER_COLUMNSET_CALLBACK: null,
    ON_COLUMNSET_ITEMS_CHANGE: null
  })
};


