import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AddColumnSetWidthRequest extends BaseServiceRequest {

    constructor(listId, colSeq, isQuoteboard = false) {
        super();
        super.addRequestUri("width.pb");
        super.addPathParameter(listId.toString());
        super.addRequestParameter("col", colSeq);
        super.addRequestParameter("iqb", isQuoteboard ? "1" : "0");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default AddColumnSetWidthRequest;
