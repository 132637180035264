
import React, { PureComponent } from 'react';
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import { map } from "underscore";
import { transGeographyName } from '../../../../../Sagas/NavCompare/MenuPanel/GroupsPanelSaga';
import { setGeographyByCountryCodeAndCountryName, setOpenGeographyStatus, updateGeographySelection } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { connect } from 'react-redux';
import ConsoleStore from "ConsoleStore";
import LocalizationStore from 'LocalizationStore';
import onClickOutside from "react-onclickoutside";
import CompareTabType from "CompareTabType";

class GeographyListView extends PureComponent {

  componentDidUpdate() {
    if (!StringUtil.isEmpty(this.props.selectedGeography)) {
      if (this.props.openGeography)
        this.setScrollPosition();
    }
  }


  handleClickOutside() {
    try {
      if (this.props.openGeography)
        this.props.setOpenGeographyStatus(false);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  handleClick(e) {
    let countryCode = parseInt(e.target.getAttribute('data-countrycode'));
    if (countryCode !== this.props.countryCode) {
      this.props.updateGeographySelection(true);
      this.props.setGeographyByCountryCodeAndCountryName(countryCode);
      ConsoleStore.trackUsage('GroupSelectGeographyChanged');
    }
  }



  renderGeographyList() {
    if (StringUtil.isEmpty(this.props.geographyDataList.length > 0)) return;

    return (<ul className="list-group">
      {
        map(this.props.geographyDataList, (item, key) => {
          let strRegionName = transGeographyName(item);
          if (item.isHeader) {
            item.displayName = LocalizationStore.getTranslatedData("RotGraph_" + item.countryName, item.countryName)
            return (
              <li key={key} className="list-group-item">
                <div style={{ fontWeight: "bolder" }}>{item.displayName}</div>
              </li>
            );
          }
          else {
            let activeCountry = null;
            item.displayName = StringUtil.isEmpty(strRegionName) ? item.regionName : strRegionName;
            if (!StringUtil.isEmpty(this.props.selectedGeography)) {
              activeCountry = ((!StringUtil.isEmpty(this.props.selectedCountryCode) ? this.props.selectedCountryCode : this.props.selectedGeography.countryCode) == item.countryCode) ? <span className="icn-right-icon" data-countrycode={item.countryCode}></span> : '';
            }

            return (
              <li key={key} className="list-group-item" onMouseUp={() => this.handleClick(event)} data-countrycode={item.countryCode}>
                {activeCountry}
                <div data-countrycode={item.countryCode}>{item.displayName}</div>
              </li>
            );
          }
        })
      }
    </ul>);
  }

  // Set Scroll position by element in Geography List
  setScrollPosition() {
    let elm = null;
    let selectedGeography = this.props.selectedGeography;
    if (selectedGeography) {
      let scrollPositionVal = null;
      let geographyScrollBar = document.getElementById("geographyListVerticalScroll");

      if (!StringUtil.isEmpty(geographyScrollBar))
        elm = geographyScrollBar.querySelector("li[data-countrycode='" + selectedGeography.countryCode + "']");

      if (!StringUtil.isEmpty(elm)) {
        if (elm.offsetTop > 0) {
          scrollPositionVal = parseInt(elm.offsetTop) - 160;
        }
      }

      if (!StringUtil.isEmpty(scrollPositionVal))
        window.setTimeout(() => { geographyScrollBar.scrollTop = scrollPositionVal; }, 0);
    }
  }

  render() {
    let showGeographyList = this.props.openGeography ? 'block' : 'none';
    let geographyList = this.renderGeographyList();

    let loading = false;
    return (
      <div className='geo-graphy-list' ref={(ref) => this.geographyListRef = ref} style={{ display: showGeographyList }}>
        <div className="custom-panel-block recommended-block">
          <div className="panel panel-default">
            <div className="panel-heading">
              <span className="panel-title cap-header">{LocalizationStore.getTranslatedData("RotFil_title", "SELECT GEOGRAPHY")}</span>
            </div>
            <div className="panel-body">
              <div className="inner-panel-body custom-scroll-light">
                <div className="custom-scroll">
                  <div className="geography-list-vertical-scroll" id='geographyListVerticalScroll'>
                    {(loading ? <div className="group-box"><div className="loading loading-light"></div></div> : geographyList)}
                    <ScrollBar scrollId="geographyListVerticalScroll" vScroll={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const { GroupsPanelReducer } = state.comparisonAndRotationMenuPanel;
  const { selectedGeography, openGeography, countryCode, selectedCountryCode } = selectedTab === CompareTabType.ComparisonGraph ? GroupsPanelReducer.ComparisonMenuStates : GroupsPanelReducer.RotationMenuStates;
  const { geographyDataList } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer;
  return { selectedGeography, openGeography, countryCode, selectedCountryCode, geographyDataList };
};

const mapDispatchToProps = (dispatch) => ({
  setGroupsMenuCurrentAction: (currentAction) => dispatch(setGroupsMenuCurrentAction(currentAction)),
  setCompareGroupsState: () => dispatch(setCompareGroupsState()),
  setGeographyByCountryCodeAndCountryName: (countryCode) => dispatch(setGeographyByCountryCodeAndCountryName(countryCode)),
  setOpenGeographyStatus: (status) => dispatch(setOpenGeographyStatus(status)),
  updateGeographySelection: (isSelected) => dispatch(updateGeographySelection(isSelected))
});

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(GeographyListView));

