
import { AnnotationConstant } from "../../Constants/AnnotationConstants";
const { ActionTypes } = AnnotationConstant;
import { FibonacciInfo } from "../../Models/AnnotationModels/FibonacciModelInfo";

const fibonacciLines = []

const fibonacciLineReducer = (state = fibonacciLines, action) => {

    switch (action.type) {
        case ActionTypes.INIT_FIBONACCI_LINE_COLLECTION:
            return action.fibonacciLinesCollection;
        case ActionTypes.HANDLE_FIBONACCI_LINE_CLICK_SUCCESS:
            return [...state, action.fibonacciLine]
        case ActionTypes.HANDLE_FIBONACCI_LINE_DRAW_SUCCESS:
            return state.map((item) => (item.id === action.fibonacciLine.id) ? action.fibonacciLine : item)
        case ActionTypes.HANDLE_FIBONACCI_LINE_SAVE_ID_SUCCESS:
            return action.fibonacciLines
        case ActionTypes.HANDLE_FIBONACCI_LINE_TRANSFORM_SUCCESS:
            return state.map((item) => (item.id === action.fibonacciLine.id) ? action.fibonacciLine : item)
        case ActionTypes.HANDLE_REMOVE_FIBONACCI_LINE_DRAW:
            return state.filter((item) => item.id !== action.annotSelectedId)
        case ActionTypes.REMOVE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new FibonacciInfo, { ...item, isDeleted: true }) : item)
        case ActionTypes.RESTORE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new FibonacciInfo, { ...item, isDeleted: false }) : item)
        case ActionTypes.CLEAR_ANNOTATIONS:
            return [];
        default:
            return state
    }
}

export default fibonacciLineReducer;