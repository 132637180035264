import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from 'BaseServiceApi';

class DeleteOwnerInstrumentsRequest extends BaseServiceRequest {

    constructor(listId, symbols, spIds) {
        super();
        super.addRequestUri("deleteowner.pb");
        super.addPathParameter(listId.toString());
        const ReqProto = BaseServiceApi.rayData["ListInstrumentsRequestData"];
        const listInstrumentsRequestData = new ReqProto();
        const InfoProto = BaseServiceApi.rayData["ListInstrumentInfoData"];
        const listInstrumentInfoData = new InfoProto();
        listInstrumentInfoData.symbols = "";
        listInstrumentInfoData.msIds = spIds ? spIds : '';
        listInstrumentInfoData.snapshotDts = "";
        listInstrumentsRequestData.instrumentsInfo = listInstrumentInfoData;
        const bulkInsertData = listInstrumentsRequestData.encode();
        super.setContent(bulkInsertData);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "OwnershipAddRemoveResponse";
    }
}

export default DeleteOwnerInstrumentsRequest;
