import AlertWindow from "../Annotation/AnnotationLeftPanel/Presentational/AlertWindow";
import BlockMenu from "./BlockMenu";
import BlockType from "../../../../Constants/BlockType";
import CommonAboutDialog from "./CommonAboutDialog";
import { connect } from "react-redux";
import CustomTIModule from '../ReduxModules/CustomTICharts/Containers/CustomTIModule';
import FinancialBlock from './FinancialBlock/FinancialBlock';
import Header from './PDR/Header';
import Indicators from './Indicators/Indicators';
import InsiderChart from './InsiderChart/InsiderChart';
import LayoutFactor from "LayoutFactor";
import OwnerShipBlock from './OwnerShipBlock/OwnerShipBlock';
import PopUpWindow from "../Annotation/AnnotationLeftPanel/Presentational/PopUpWindow";
import PriceChart from './PriceChart/PriceChart';
import SmartViewType from "../../../../Constants/SmartViewType";
import SummaryBlock from './SummaryBlock/SummaryBlock';
import TimeLine from './TimeLine/TimeLine';
import TrackPrice from "./TrackPrice/TrackPrice";
import { TranslateHelper } from '../../../../Utils/TranslateHelper';
import { updateDataGraphTabHeight } from "../../../../Actions/DatagraphActions";
import { updateIndicatorHeight } from "../../../../Actions/PricePanelActions";
import VolumeChart from './VolumeChart/VolumeChart';
import MetricDefinition from "../../../../RayCustomControls/CommonDialog/MetricDefinition.jsx";
import React, { useLayoutEffect, useRef, useState } from 'react';

const TabDataGraph = (props) => {
    // const cls = this.state.isHistoric ? " custom-main-panel" : "";
    const cls = '';//;" custom-main-panel";
    const symolViewRef = useRef(null);
    const timeLineViewRef = useRef(null);
    const headerViewRef = useRef(null);
    useLayoutEffect(() => {
        const height = timeLineViewRef.current ? timeLineViewRef.current.clientHeight : props.videoMode ? 60 : 20;
        const headerHeight = headerViewRef.current ? headerViewRef.current.clientHeight : 25;
        props.updateDataGraphTabHeight(symolViewRef.current.clientHeight - height - headerHeight);
        if (props.SymbolType === SmartViewType.FUND) {
            props.updateIndicatorHeight()
        }
    }, [])
    const [cursor, setCursor] = useState("auto");
    return (
        <div className="mainBlockWrap">
            <div id="tabMainBlock" >
                <div className={`mainPanel${cls}`}>
                    {/* {ConsoleStore.getState().serviceCallCount >= 1 && <div id={this.getId()}></div>} */}
                    <BlockMenu />
                    {props.priceDataAvailable ? <div className="content symbol-view-content" ref={symolViewRef} style={{ cursor: cursor, marginLeft: `-42px` }}>
                        <div className="info dot symbol-header" ref={headerViewRef} >
                            <Header />
                        </div>
                        <SummaryBlock />
                        <Indicators />
                        <div className="symbol-chart-stack symbol-price-stack" key="priceChart" style={{ flex: LayoutFactor["PriceChart"] }}>
                            <PriceChart />
                        </div>
                        {props.isInsiderOpen && <InsiderChart />}
                        {props.isVolumeOpen && <VolumeChart />}
                        {props.charts[BlockType.Pricechart] && <TrackPrice setCursor={setCursor} key="trackPrice" />}
                        {!props.isFinancialOpen && !props.isOwnershipOpen &&
                            <div className="time-line-panel" ref={timeLineViewRef} style={{ height: props.videoMode ? "60px" : "20px" }} key="TimeLine">
                                <TimeLine />
                            </div>
                        }
                        {props.isFinancialOpen && <FinancialBlock />}
                        {props.isOwnershipOpen && <OwnerShipBlock />}
                    </div> :
                        <div className="content symbol-view-content" ref={symolViewRef} style={{ cursor: cursor, marginLeft: `-42px` }}>
                            {(props.SymbolType === SmartViewType.INDEX || props.SymbolType === SmartViewType.CEF ||
                                props.SymbolType === SmartViewType.ETF || props.SymbolType === SmartViewType.INDUSTRYGROUP) &&
                                <div className="info dot symbol-header" ref={headerViewRef} >
                                    <Header />
                                </div>}
                            {(props.SymbolType === SmartViewType.INDEX || props.SymbolType === SmartViewType.CEF ||
                                props.SymbolType === SmartViewType.ETF) && <SummaryBlock />}
                            <div className="dataGraph-message">{TranslateHelper.Data_Not_Available}</div>
                        </div>}
                </div>
                <CustomTIModule />
                <PopUpWindow />
                <CommonAboutDialog />
                <AlertWindow />
                {
                    props.showMetricDef &&
                    <MetricDefinition
                        definition={props.definition}
                        industryName={props.industryName}
                        x={200}
                        y={200}
                    />
                }
            </div>
        </div>
    );
};
const mapStateToProps = ({ DatagraphReducers }) => {
    const { charts } = DatagraphReducers.PriceChartReducer;
    const { priceDataAvailable, SymbolType, videoMode } = DatagraphReducers.DataGraphReducer;
    const { isVolumeOpen } = DatagraphReducers.volumeChartReducer;
    const { isInsiderOpen } = DatagraphReducers.insiderChartReducer;
    const { isOwnershipOpen } = DatagraphReducers.ownershipBlockReducer;
    const { isFinancialOpen } = DatagraphReducers.financialBlockReducer;
    const { showMetricDef, industryName, definition } = DatagraphReducers.HeaderReducer;
    return {
        priceDataAvailable, charts, isVolumeOpen, isInsiderOpen, isOwnershipOpen, isFinancialOpen, SymbolType,
        videoMode, showMetricDef, industryName, definition
    };
}

const mapDispatchToProps = (dispatch) => ({
    updateDataGraphTabHeight: (height) => dispatch(updateDataGraphTabHeight(height)),
    updateIndicatorHeight: () => dispatch(updateIndicatorHeight())
})

export default connect(mapStateToProps, mapDispatchToProps)(TabDataGraph);