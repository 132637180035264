import { connect } from "react-redux";
import LabelText from "./LabelText.jsx"
import { PrintMode } from "../../../../../print/printmode";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import React, { Component } from "react";

class TimeLine extends Component {
  componentDidMount() {
    this.setState({});
  }
  Qtr(month) {
    return Math.floor((month - 1) / 3.0);
  }
  getTimeLineLabel(TimeLineLabels) {
    try {
      if (!this.gridCanvas || !TimeLineLabels || TimeLineLabels.length === 0) {
        this.canvasFailed = true;
        return;
      }
      const rect = this.gridCanvas.getBoundingClientRect();
      const chartAreaWidth = rect.width;
      const height = rect.height;
      const ctx = this.gridCanvas.getContext("2d");
      ctx.canvas.height = rect.height;
      ctx.canvas.width = rect.width;
      ctx.clearRect(0, 0, rect.width, rect.height);
      ctx.lineWidth = this.props.videoMode ? 3 : 1;
      ctx.translate(0.5, 0.5);
      ctx.imageSmoothingEnabled = false;
      ctx.save();
      ctx.fillStyle = ThemeHelper.getThemedBrush("dateText");
      ctx.fillTextWithPos = ThemeHelper.getThemedBrush("dateText");
      ctx.background = "Transparent";
      if (this.props.videoMode) {
        ctx.font = "600 32pt Calibri";
      } else {
        ctx.font = "normal 8pt Arial";
      }

      if (this.props.videoMode) {
        ctx.strokeStyle = ThemeHelper.getThemedBrush("dateText");
      } else {
        ctx.strokeStyle = ThemeHelper.getThemedBrush("financialDividerLines");
      }
      ctx.textAlign = this.props.align;
      let xMargin = this.props.align === "begin" ? 4 : -4;
      if (this.props.videoMode) {
        xMargin = this.props.align === "begin" ? 8 : -8;
      }
      const max = TimeLineLabels.length;
      ctx.beginPath();
      for (let index = 0; index < max; index++) {
        const timeLineLabel = TimeLineLabels[index];
        if (!timeLineLabel) { break };
        const xAxis = chartAreaWidth - (timeLineLabel.XAxis * this.props.nodeWidth);
        if (xAxis < 0.0) { continue }
        ctx.moveTo(xAxis, 0);
        ctx.lineTo(xAxis, height);
        if (this.props.useCanvas) {
          if (this.props.videoMode) {
            ctx.fillText(timeLineLabel.XLabel, xAxis + xMargin, 16 + height / 2);
          } else {
            ctx.fillText(timeLineLabel.XLabel, xAxis + xMargin, 3 + height / 2);
          }
        }
      }
      ctx.stroke();
      ctx.closePath();
      this.canvasFailed = false;
    }
    catch (e) {
      console.error(e);
    }
  }
  getTimeLineLabel2(TimeLineLabels) {
    if (!this.gridCanvas || !TimeLineLabels || TimeLineLabels.length === 0) {
      return (<div></div>);
    }
    const rect = this.gridCanvas.getBoundingClientRect();
    const textColor = ThemeHelper.getThemedBrush("scaleText");
    const chartAreaWidth = rect.width;
    const height = rect.height;
    let sFont;
    let dY;
    if (this.props.videoMode) {
      sFont = "32pt";
      dY = "10";
    } else {
      sFont = "9pt";
      dY = "-0.5";
    }
    const font =  this.props.align === "end" && PrintMode.printing ?  'calibrib' : 'calibri';
    const fontSize =  this.props.align === "end" && PrintMode.printing ? '8pt' : sFont;
    const dx = this.props.align === "end" ? "-4" : "4";
    return (
      <svg className="svg financial-scale">
        {TimeLineLabels.map((itm, i) => itm.XLabel &&
          <LabelText
            key={i}
            textAnchor={this.props.align}
            isHighlighted={true}
            textValue={itm.XLabel.toString()}
            dx={dx}
            dy={dY}
            style={{ font, fontSize, fill: textColor }}
            textColor={textColor}
            textPosX={chartAreaWidth - (itm.XAxis * this.props.nodeWidth)}
            textPosY={4 + height / 2}
            height={height} />)}
      </svg>
    );
  }

  onContextMenuClick(e) {
    e.preventDefault();
    return false;
  }

  render() {
    let style = { paddingRight: this.props.rightScaleWidth };
    if (this.props.videoMode) {
      style = { paddingRight: this.props.rightScaleWidth, borderTop: "1px solid gray" };
    }
    const TimeLineLabels = (this.props.isCalender || this.props.isOwnership) ? this.props.CalenderTimeLineLabels : this.props.TimeLineLabels;
    TimeTrackingWindow.trackChartLoadingTime();
    return (
        <div className="time-line-holder" style={style}>
          <div className="time-liner" id="timeLineMain">
            <canvas
              className="canvas-time-liner"
              onContextMenu={this.onContextMenuClick}
              ref={(ref) => { this.gridCanvas = ref }}
              style={{ fitPosition: "fill" }} />
            {!this.props.useCanvas && this.getTimeLineLabel2(TimeLineLabels)}
            {this.props.useCanvas && this.getTimeLineLabel(TimeLineLabels)}
          </div>
        </div>
    );
  }
}

const mapStateToProps = ({ DatagraphReducers, appColor }) => {
  const { align, TimeLineLabels, isCalender, CalenderTimeLineLabels } = DatagraphReducers.TimeLineReducer;
  //chartWidth for is for updateing component when width of priceChart change.
  const { chartWidth, chartHeight } = DatagraphReducers.PriceChartReducer;
  const { isThemeChanged } = appColor;
  const { nodeWidth, periodicity, videoMode, leftScaleWidth, rightScaleWidth, useCanvas } = DatagraphReducers.DataGraphReducer;
  return { nodeWidth, TimeLineLabels, periodicity, videoMode, leftScaleWidth, rightScaleWidth, align, useCanvas, 
    isThemeChanged, chartWidth, isCalender, CalenderTimeLineLabels, chartHeight }
}

export default connect(mapStateToProps)(TimeLine)