class Scale {
  constructor(wonFixed = false) {
    this.Factor = 0;
    this.Lowpt = 0;
    this.YLowpt = 0;
    this.Hipt = 0;
    this.prInc = 0;
    this.scaleStart = 0;
    this.mhLines = [];
    this.mlPriceIncrement = 0;
    this.GraphType = 0;
    this.minPrice = Number.POSITIVE_INFINITY;
    this.maxPrice = Number.NEGATIVE_INFINITY;
		this.height = 0;
	  this.wonFixed = wonFixed;
  }
  log10(x) {
    return Math.log(x) / Math.log(10);
  }
  getFactor() {
    return this.Factor;
  }
}

export default Scale;
