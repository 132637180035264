import { takeLatest, call, put, fork } from 'redux-saga/effects';
import { RiPanelConstants } from '../../../Constants/RiPanelConstants';
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import GraphApi from "ServiceApi/Apis/GraphApi";
import { clone, each } from "underscore";
import moment from "moment";
import MiniListHelper from "MiniListHelper";
import StringUtil from '../../../Utils/StringUtil';

const { ActionTypes } = RiPanelConstants;

function getDateStringWithTimeZome(date) {
    const TimeZoneFormated = moment(date).locale('en').format('llll');
    return `${TimeZoneFormated} ${moment.tz(moment.tz.guess()).zoneAbbr()}`;
}

function* getDisplayString(date) {
    try {
        const seconds = date.toNumber();
        const unixTime = moment(seconds);
        const date2 = unixTime.utc().locale('en').format('llll');
        const diffTime = moment.duration(moment().locale('en').diff(date2));
        const hours = Math.max(Math.floor(diffTime / 1000 / 60 / 60), 0);
        const hoursText = hours === 1 ? " hr ago" : " hrs ago";
        if (hours < 1) {
            const minutes = diffTime.minutes() > 0 ? diffTime.minutes() : 0;
            const minutesText = minutes === 1 ? " min ago" : " mins ago";
            return minutes + minutesText;
        }
        else if (hours < 24) {
            return hours + hoursText;
        }
        else {
            return yield call(getDateStringWithTimeZome, seconds);
        }
    } catch (error) {
        console.log(`%cError occurs in RiPanelBrokerReports.js, getDisplayString ${error}`, 'color:#508F4E;background-color:#ffff00');
    }
}

function* setDefaultState() {
    yield put({
        type: ActionTypes.UPDATE_BROKER_REPORTS,
        totalCount: 0,
        report: {},
        nodata: false,
        isLoading: true
    });
}

function* populateBrokerReports({ symbolInfo }) {
    try {
        yield fork(setDefaultState);
        if (symbolInfo) {
            const lastTradeDate = symbolInfo.LastTradeDate;
            const endDate = moment(lastTradeDate).format('YYYYMMDD');
            const copiedDate = new Date(lastTradeDate.getTime());
            const startDate = moment(copiedDate).subtract(1, 'years').format('YYYYMMDD');
            const dState = DatagraphStore.getState();
            const symbol = symbolInfo.Symbol;

            const result = yield GraphApi.getBrokerReportRequest(symbol, startDate, endDate);


            const isMiniListPlay = dState.IsMiniListPlay;
            const visitedHeadlines = JSON.parse(localStorage.getItem("brokerReportVisitedHeadlines"));

            if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay) && result) {
                let report = {};
                let totalCount = 0;
                if (!StringUtil.isEmpty(result.reports)) {
                    report = clone(result.reports.reports);
                    for (let item of report) {
                        item.strReportDt = yield call(getDisplayString, item.reportDt);
                        item.isVisited = false;
                        item.osid = symbolInfo.Osid;
                        if (!StringUtil.isEmpty(visitedHeadlines)) {
                            each(visitedHeadlines, (i) => {
                                if (i.id === item.id && i.osid === symbolInfo.Osid)
                                    item.isVisited = true;
                            })
                        }
                    }
                    totalCount = result.reports.totalCount;
                }
                yield put({
                    type: ActionTypes.UPDATE_BROKER_REPORTS,
                    totalCount: totalCount,
                    report: report,
                    nodata: StringUtil.isEmpty(report),
                    isLoading: false
                });
            }
        }
    } catch (error) {
        console.log(`%cError occurs in RiPanelBrokerReports.js, populateBrokerReports ${error}`, 'color:#508F4E;background-color:#ffff00');
    }

}
export function* watchPopulateBrokerReports() {
    yield takeLatest(ActionTypes.GET_BROKER_REPORTS, populateBrokerReports);
}