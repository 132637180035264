import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BlockType from "../../../../Constants/BlockType";
import  { DatagraphConst } from "../../../../Utils/DatagraphHelper";
import DatagraphDataType from "../../../../Constants/DatagraphDataType";
import GraphType from "GraphType";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "SettingsStore";
import StringUtil from "StringUtil";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getIndicatorStates } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { indicatorCommonConst, IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants.js";
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper";
import { updateIndicatorData, updateIndicatorGraphData, updateIndicatorSettings } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";


const { ActionTypes } = IndicatorsConstants;

function getAccDistChart(hsfResults, lastNode, slow = true, padding, startXPoint, scale) {
    const prcLength = hsfResults.length;
    const chartData = [];

    for (let j = padding; j < prcLength; j++) {
        if (startXPoint < 0) {
            break;
        }
        const val = slow ? hsfResults[j].SK_Line : hsfResults[j].SR_Line;
        if (isNaN(val)) {
            startXPoint -= DatagraphConst.nodeWidth;
            continue;
        }
        const yPrice = scale.ComputeY(val);
        const info =
        {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: startXPoint,
            yValue: val
        };
        startXPoint -= DatagraphConst.nodeWidth;
        chartData.push(info);
    }

    return chartData;
}
function contains(a, obj) {
    let i = a.length;
    while (i--) {
        if (a[i].Date === obj) {
            return i;
        }
    }
    return -1;
}
function IsIndicatorSymbol(sSymbol) {

    if (sSymbol.includes(".")) { //international issue
        return false;
    }
    if ((sSymbol.length >= 5) &&
        (sSymbol[0] > '0' && sSymbol[0] <= '9' &&
            sSymbol[4] >= '0' && sSymbol[4] <= '9')) {
        // It is osid
        return false;
    }
    if ((sSymbol.length >= 1) && (sSymbol[0] === '.')) {
        // Begins with '.'
        return true;
    }
    if ((sSymbol.length >= 1) && (sSymbol[0] === '0')) {
        // Begins with '0'
        return true;
    }
    if (!isNaN(parseInt(sSymbol[0], 10), 10)) {
        // Can not determine probably old issues
        return false;
    }
    if ((sSymbol.length >= 5) && !isNaN(parseInt(sSymbol[4], 10), 10) && !isNaN(parseInt(sSymbol[3], 10), 10)) {
        // 5 chars, ends with number.
        return true;
    }
    if ((sSymbol.length > 6) && !sSymbol.includes(".")) {
        // 5 chars, ends with number.
        return true;
    }
    return false;
}
function IsGroupSymbol(sSymbol) {
    if (sSymbol.length < 2 || sSymbol.length > 6) {
        return false;
    }

    return IsIndicatorSymbol(sSymbol);
}
function UpdatePseudoAverageValue(dPseudoAverage, dNewValue, dNewValueWeight) {
    dPseudoAverage *= (1 - dNewValueWeight);
    dPseudoAverage += (dNewValue * dNewValueWeight);

    return dPseudoAverage;
}
function EnsureValueInRange(iValue, iMin, iMax) {
    if (iValue < iMin) {
        iValue = iMin;
    }
    else
        if (iValue > iMax) {
            iValue = iMax;
        }

    return iValue;
}
function CalculateAccDist(stockQuotes, stockHeader) {
    const count = stockQuotes.length;
    const v = [];

    let lastPrice = count - 1;

    stockQuotes.forEach((node) => {
        if (node.IsVisible) {
            v.push(node);
        }
    }, this);

    v.sort((a, b) => {
        if (a.Date < b.Date) {
            return -1;
        }
        if (a.Date > b.Date) {
            return 1;
        }
        if (a.Date === b.Date) {
            return 0;
        }
        return null;
    });

    lastPrice = v.length - 1;
    let startPoint = 3;
    if (lastPrice < startPoint) {
        return;
    }
    while (v[startPoint]._volume <= 0 && startPoint < lastPrice) {
        startPoint++;
    }
    const accDisStart = 44;

    // Starting position is:
    // - find SECOND non zero price
    // - at least 3 (huh?)
    // - at least 44 points from the end (cRec->AccDisStart)
    // - first non-zero volume after

    let i;
    if (lastPrice - startPoint < accDisStart) {
        for (i = 0; i <= lastPrice; i++) {
            if (stockQuotes[i] !== null) {
                stockQuotes[i].SK_Line = 0;
                stockQuotes[i].SR_Line = 0;
            }
        }
        return;
    }

    let dPseudoAveragePrice = v[startPoint].Close;
    let dPercentChangeVesusPseudoAverage = 0;
    let dPseudoAverageAbsPercentPriceChange = 5;
    let dPseudoAverageVolume = v[startPoint]._volume;
    let suAvg = 0;
    let sdAvg = 0;
    let anPerk = 0;
    let sYmd = v[startPoint].Date;
    let captlVal = (stockHeader === null) || (stockHeader.Symbol === null) || (stockHeader.Shares === null) ? v[lastPrice]._volume * 4 : StringUtil.convertFromLongValueToInt(stockHeader.Shares); //local value of the capitalization number so the crec value is not modified 

    for (i = startPoint; i <= lastPrice; i++) {
        if ((i === startPoint) && (v[i].Close < 0.00001)) {
            return;
        }

        if (v[i].Close < 0.00001) {
            break;
        }
        const dPricePseudoAverageFactor = 0.05;

        dPseudoAveragePrice = UpdatePseudoAverageValue(dPseudoAveragePrice, v[i].Close, dPricePseudoAverageFactor);
        if (dPseudoAveragePrice !== 0) {
            dPercentChangeVesusPseudoAverage = ((v[i].Close - dPseudoAveragePrice) / dPseudoAveragePrice) * 100.0;
        }

        const dPriceChangeVersusLastPoint = v[i].Close - v[i - 1].Close;

        let tone;
        if (dPriceChangeVersusLastPoint >= 0) {
            tone = 1;
        }
        else if (dPercentChangeVesusPseudoAverage <= 5) {
            tone = 1;
        }
        else if (dPercentChangeVesusPseudoAverage >= 30.0) {
            tone = 0.4;
        }
        else {
            tone = 1 - ((dPercentChangeVesusPseudoAverage - 5.0) * 0.024);
        }

        // Assuming that once an issue has a price, it always has a price, this should be true.
        let dAbsPercentChangeVersusLastPrice = 0;

        if (v[i - 1].Close !== 0) {
            dAbsPercentChangeVersusLastPrice = Math.abs((dPriceChangeVersusLastPoint) / v[i - 1].Close * 100.0);
        }

        if (dAbsPercentChangeVersusLastPrice > 0) {
            const dPseudoAverageAbsPercentPriceChangeFactor = 0.15;
            if (dAbsPercentChangeVersusLastPrice > (dPseudoAverageAbsPercentPriceChange * 1.6)) {
                dPseudoAverageAbsPercentPriceChange = UpdatePseudoAverageValue(
                    dPseudoAverageAbsPercentPriceChange,
                    (dPseudoAverageAbsPercentPriceChange * 1.6),
                    dPseudoAverageAbsPercentPriceChangeFactor);
            }
            else {
                dPseudoAverageAbsPercentPriceChange = UpdatePseudoAverageValue(
                    dPseudoAverageAbsPercentPriceChange,
                    dAbsPercentChangeVersusLastPrice,
                    dPseudoAverageAbsPercentPriceChangeFactor);
            }

            let dMaxPercentChangeAllowed;
            if ((v[i - 1].Close > dPseudoAveragePrice && dPseudoAveragePrice > v[i].Close) ||
                (v[i - 1].Close < dPseudoAveragePrice && dPseudoAveragePrice < v[i].Close)) {
                dMaxPercentChangeAllowed = dPseudoAverageAbsPercentPriceChange * 2;
            }
            else {
                dMaxPercentChangeAllowed = dPseudoAverageAbsPercentPriceChange * 1.4;
            }
            if (dAbsPercentChangeVersusLastPrice > dMaxPercentChangeAllowed) {
                dAbsPercentChangeVersusLastPrice = dMaxPercentChangeAllowed;
            }
        }

        let action;
        if (v[i]._volume >= dPseudoAverageVolume) {
            if (dPriceChangeVersusLastPoint > 0) {
                action = (v[i].Close > v[i - 1].High && v[i].Low > v[i - 1].Close) ? 1.15 : 1;
            }
            else if (dPriceChangeVersusLastPoint < 0) {
                action = (v[i - 1].Close > v[i].High && v[i - 1].Low > v[i].Close) ? -1.15 : -1;
            }
            else {
                dAbsPercentChangeVersusLastPrice = 0.5;

                if (v[i - 1].Close < v[i - 2].Close && v[i - 2].Close < v[i - 3].Close) {
                    action = 1;
                }
                else {
                    if (v[i - 1].Close > v[i - 2].Close && v[i - 2].Close > v[i - 3].Close) {
                        action = -1;
                    }
                    else {
                        action = 0;
                    }
                }
            }
        }
        else {
            let volRatio = v[i]._volume / dPseudoAverageVolume;
            if (volRatio < 0.1) {
                volRatio = 0.1;
            }
            if (v[i].Close > dPseudoAveragePrice) {
                if (dPriceChangeVersusLastPoint > 0) {
                    action = -0.05 / volRatio;
                }
                else if (dPriceChangeVersusLastPoint < 0) {
                    action = 0.1 / volRatio;
                }
                else {
                    action = 0;
                }
            }
            else {
                if (dPriceChangeVersusLastPoint > 0) {
                    action = -0.1 / volRatio;
                }
                else if (dPriceChangeVersusLastPoint < 0) {
                    action = 0.05 / volRatio;
                }
                else {
                    action = 0.0;
                }
            }
        }

        const midPoint = (v[i].High + v[i].Low) / 2.0;	// Midpoint of high and low price
        const span = v[i].High - midPoint;		// Half of range from high to low.
        let perAct;		// Percent from midpoint (of high - low) to high (neg for below midpoint)
        if (span === 0) {
            perAct = 0;
        }
        else {
            perAct = (v[i].Close - midPoint) / span;
        }

        if (action > 0) {
            action = (action * 0.9) + ((1 + perAct) * 0.1);
        }
        else {
            if (action < 0) {
                action = (action * 0.9) - ((1 - perAct) * 0.1);
            }
        }

        sYmd = v[i].Date;
        if (v[i]._volume < 1) {
            action = 0;
        }
        else
            if (sYmd.getMonth() + 1 === 12 && sYmd.getDate() >= 22) {
                // For some reason, we don't update average volume during the last 9 days of the year.

            }
            else {
                let dConstrainedVolume = v[i]._volume;
                dConstrainedVolume = EnsureValueInRange(dConstrainedVolume, dPseudoAverageVolume * 0.4, dPseudoAverageVolume * 1.6);

                const dVolumePseudoAverageFactor = 0.2;

                dPseudoAverageVolume = UpdatePseudoAverageValue(dPseudoAverageVolume, dConstrainedVolume, dVolumePseudoAverageFactor);
            }

        if (((stockHeader !== null && (stockHeader.Symbol !== null) &&
            (stockHeader.Symbol[0] === '0' || IsGroupSymbol(stockHeader.Symbol))) ||
            stockHeader.useDefaultCaptl === 1) && captlVal === 0)	// artificially adjust Captl for indices
        {
            captlVal = 10000000;
        }

        let dSupplyDemand;
        if (captlVal !== 0) {
            if (v[i]._volume >= (dPseudoAverageVolume * 1.6)) {
                let volFact;
                if (v[i]._volume >= (dPseudoAverageVolume * 2.5)) {
                    volFact = 2;
                }
                else {
                    volFact = 1.6 + ((v[i]._volume / dPseudoAverageVolume) - 1.6) / 0.9 * 0.4;
                }

                dSupplyDemand =
                    (dAbsPercentChangeVersusLastPrice * action * (dPseudoAverageVolume * volFact) * tone) /
                    (captlVal * 1000);
            }
            else {
                dSupplyDemand =
                    (dAbsPercentChangeVersusLastPrice * action * v[i]._volume * tone) /
                    (captlVal * 1000);
            }
        }
        else {
            dSupplyDemand = 0;
        }

        const dAbsSupplyDemand = Math.abs(dSupplyDemand);
        let su;
        let sd;
        if (dSupplyDemand > 0) {
            su = dAbsSupplyDemand;
            sd = 0;
        }
        else {
            su = 0;
            sd = dAbsSupplyDemand;
        }

        const dSupplyDemandPseudoMaFactor = 0.0333;

        suAvg = UpdatePseudoAverageValue(suAvg, su, dSupplyDemandPseudoMaFactor);
        sdAvg = UpdatePseudoAverageValue(sdAvg, sd, dSupplyDemandPseudoMaFactor);

        let nPerk;
        if ((suAvg + sdAvg) > 0) {
            nPerk = (suAvg - sdAvg) * 100.0 / (suAvg + sdAvg);
        }
        else {
            nPerk = 0;
        }

        const dSupplyDemandFinalPseudoMaFactor = 0.1;

        anPerk = UpdatePseudoAverageValue(anPerk, nPerk, dSupplyDemandFinalPseudoMaFactor);

        if (count > lastPrice - i) {
            v[i].SK_Line = nPerk;
            v[i].SR_Line = anPerk;

            const xPosition = contains(stockQuotes, v[i].Date);
            if (xPosition > -1) {
                stockQuotes[xPosition].SK_Line = nPerk;
                stockQuotes[xPosition].SR_Line = anPerk;
            }
        }
    }

    return stockQuotes;
}

function initAccDistScale(graphData, lastNode, graphType, indicatorsHeight, startXPoint) {
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;

    const gLength = graphData.length;
    for (let minMaxIndex = lastNode; minMaxIndex < gLength; minMaxIndex++) {
        if (!graphData[minMaxIndex].IsVisible) {
            continue;
        }
        if (graphData[minMaxIndex] === null) {
            break;
        }

        if (minValue > graphData[minMaxIndex].SK_Line) {
            minValue = graphData[minMaxIndex].SK_Line;
        }
        if (maxValue < graphData[minMaxIndex].SK_Line) {
            maxValue = graphData[minMaxIndex].SK_Line;
        }
        if (minValue > graphData[minMaxIndex].SR_Line) {
            minValue = graphData[minMaxIndex].SR_Line;
        }
        if (maxValue < graphData[minMaxIndex].SR_Line) {
            maxValue = graphData[minMaxIndex].SR_Line;
        }

        startXPoint -= DatagraphConst.nodeWidth;
        if (startXPoint < 0) {
            break;
        }
    }
    const scale = new ArithmaticScale();
    scale.InitScale(minValue, maxValue, indicatorsHeight, graphType, 2, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true);
    return scale;
}

function* PrepareData({  chartType }) {
    try {
        const {  indicatorsHeight, headerData, startXPoint,  padding } = yield select(getDatagraphStates);
        const { SettingDict, GraphData } = yield select(getIndicatorStates);

        yield call(processData, GraphData[chartType], indicatorsHeight, chartType, headerData, SettingDict[chartType], startXPoint,  padding);
    }
    catch (error) {
        console.error("Error occurs in ADIndicatorSaga.js, PrepareData", error);
    }
}

function* processData(graphData, indicatorsHeight, chartType, StockHeader, SettingDict, startXPoint, padding) {
    if (graphData) {
        const lastNode = 0;
        let DataSource;
        const indicatorVisuals = { [chartType]: [] };
        const indicatorLabels = { [chartType]: { labels: [] } };
        let signalColor, signalWeight, movingAverageColor, movingAverageWeight;
        SettingDict.SignalColor.forEach((item) => {
            signalColor = ThemeHelper.getThemedBrush(item.color);
            signalWeight = item.weight;
        });
        SettingDict.MovingAverageColor.forEach((item) => {
            movingAverageColor = ThemeHelper.getThemedBrush(item.color);
            movingAverageWeight = item.weight;
        });
        const scale =initAccDistScale(graphData, lastNode, GraphType.Weekly, indicatorsHeight, startXPoint);
        DataSource = getAccDistChart(graphData, lastNode, true, padding, startXPoint, scale);
        indicatorVisuals[chartType].push({ key: "Line1", DataSource, LineColor: signalColor, LineThickness: signalWeight, Draggable: false, lineID: IndicatorLabelTranslateHelper[BlockType.AccDist] });
        DataSource = getAccDistChart(graphData, lastNode, false, padding, startXPoint, scale);
        indicatorVisuals[chartType].push({ key: "Line2", DataSource, LineColor: movingAverageColor, LineThickness: movingAverageWeight, Draggable: false, lineID: "20 EMA" });
        DataSource = scale.ComputeY(0);
        indicatorVisuals[chartType].push({ isHorizontalLine: true, key: "Line3", x2: startXPoint, y1: DataSource, y2: DataSource, strokeWidth: 1, dashArray: [5, 2], lineColor: "#868686" });
        if(StockHeader.sdRank)
            indicatorLabels[chartType].isTechRating = true;
        indicatorLabels[chartType].LabelMenu = { label: IndicatorLabelTranslateHelper[BlockType.AccDist], isTICustomModal: true, isShowSettingsDialog: false, isShowAboutDialog: true }
        indicatorLabels[chartType].techText = IndicatorCommonTranslateHelper[indicatorCommonConst.SDRating];
        indicatorLabels[chartType].rank = StockHeader.sdRank;
        yield put(updateIndicatorData(indicatorVisuals, indicatorLabels, chartType, scale));
    }
}

function* updateHeight({ height }) {
    try{
        const state = yield select(getIndicatorStates);
        if(state.SettingDict[BlockType.AccDist] && state.SettingDict[BlockType.AccDist].IsAvailable && state.SettingDict[BlockType.AccDist].Height !== height){
            state.SettingDict[BlockType.AccDist].Height = height;
            SettingsStore.saveSettings();
        }
    }
    catch(error){
        console.error("Error occurs in ADIndicatorSaga.js, updateHeight", error);
    }
}


function* initIndicator() {
    try {
        const { pricePanelData, viewsSettings, majorPeriodicity, indicatorsHeight, headerData, startXPoint,  padding} = yield select(getDatagraphStates);
        const AccDistChartSettings = viewsSettings.AccDistChartSettings;
        const SettingDict = {}
        SettingDict[BlockType.AccDist] = AccDistChartSettings ? AccDistChartSettings[majorPeriodicity] : {};
        yield put(updateIndicatorSettings(SettingDict));
        if (AccDistChartSettings && SettingDict[BlockType.AccDist].IsAvailable) {
            const graphData = CalculateAccDist(pricePanelData.HsfData.HSFResults, headerData);
            yield put(updateIndicatorGraphData(graphData, BlockType.AccDist));
            yield call(processData, graphData, indicatorsHeight, BlockType.AccDist, headerData, SettingDict[BlockType.AccDist], startXPoint,  padding);
        }
        TimeTrackingWindow.endTechnicalIndicatorsLoadEndTimeTracker();
    }
    catch (error) {
        TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.TechnicalIndicators);
        TimeTrackingWindow.endTechnicalIndicatorsLoadEndTimeTracker();
        console.error("Error occurs in ADIndicatorSaga.js, initIndicator", error);
    }
}
//===================================================

export function* watchInitADIndicator() {
    yield takeLatest(ActionTypes.PROCESS_INDICATOR, initIndicator)
}

export function* watchPrepareADIndicatorData() {
    yield takeLatest(ActionTypes.PREPARE_AD_INDICATOR_DATA, PrepareData);
}

export function* watchToggleADChart() {
    // yield takeLatest(ActionTypes.TOGGLE_YTD_CHART, togglePTOECHart)
}

export function* watchUpdateADIndicatorHeight() {
    yield takeLatest(PriceChartConstants.ActionTypes.INDICATOR_RESIZE, initIndicator)
}