import _ from "underscore";
import DateHelper from "DateHelper";
import LocalizationStore from 'LocalizationStore';
import StringUtil from 'StringUtil';
import UserInfoUtil from "../../../../../Utils/UserInfoUtil";

const symbolText = LocalizationStore.getTranslatedData("sb_wklylabel", "SYMBOL");
const topRSRatingText = LocalizationStore.getTranslatedData("sb_wklylabel", "TOP STOCKS BY RS RATING")

export default class TopRatingData {
    constructor(topSymbols, rsRatingData, rsRatingSymbols, stockInfo) {
        this.labelWidth = 46;
        this.stockInfo = stockInfo;
        this.cellItemConut = 10;
        this.setTopRatingData(topSymbols, rsRatingData, rsRatingSymbols);
    }

    setTopRatingData(topSymbols, rsRatingData, rsRatingSymbols) {
        this.rowSource = [];
        const uniqueDates = rsRatingData.map((item)=> item.date ? StringUtil.convertFromLongValueToInt(item.date) : "").filter((value, index, array)=> array.indexOf(value) === index);
        for (let i = 0; i <= topSymbols.length; i++) {
            let rsRatingsDataForSymbol = [];
            let rsRatingSymbolDetails;

            if (i > 0) {
                rsRatingsDataForSymbol = rsRatingData.filter((x) => x.symbol === topSymbols[i - 1].symbol)
                rsRatingSymbolDetails = _.find(rsRatingSymbols, {
                    symbol: topSymbols[i - 1].symbol
                });
            }

            if ((i === 0) || (rsRatingsDataForSymbol && rsRatingsDataForSymbol.length > 0)) {
                const row = {
                    cells: []
                };
                for (let j = 0; j < this.cellItemConut + 1; j++) {
                    let cell = {};

                    if (i === 0 && j === 0) {
                        cell.value = symbolText;
                        cell.IsLabelCell = true;
                        cell.textAlign = "center";
                    } else if (i === 0 && j === 1) {
                        cell.value = topRSRatingText;
                        cell.IsLabelCell = true;
                        cell.textAlign = "center";
                    } else if (i === 0 && j > 1) {
                        const isAvailable = this.getCellDateOnRating(rsRatingData, i, j, topSymbols);
                        if (!isAvailable) {
                            cell.value = "";
                            cell.textAlign = "center";
                        }
                        else {
                            cell.value =  DateHelper.getMonthAndDay(DateHelper.parseJsonDate(new Date(uniqueDates[this.cellItemConut - j])));
                            cell.textAlign = "center";
                        }
                    }
                    else if (i > 0 && j === 0) {
                        cell.value = UserInfoUtil.hasLocalSymbol() && !StringUtil.isEmpty(rsRatingSymbolDetails.localSymbol) ? rsRatingSymbolDetails.localSymbol : topSymbols[i - 1].symbol;
                        cell.IsLabelCell = true;
                        cell.textAlign = "left";
                        cell.isSymbol = true;
                    } else if (i > 0 && j === 1) {
                        if (rsRatingSymbolDetails) {
                            cell.value = rsRatingSymbolDetails.companyName;
                        } else {
                            cell.value = "";
                        }
                        cell.IsLabelCell = true;
                        cell.textAlign = "left";
                        cell.marginLeft = "5px";
                    } else {
                        cell = this.getDataCell(rsRatingsDataForSymbol, i, j);
                    }

                    row.cells.push({
                        cell
                    });
                }
                this.rowSource.push(row);
            }
        }
    }

    getDataCell(rsRatingData, row, col) {
        const extraItems = rsRatingData.length - this.cellItemConut;
        const item = rsRatingData[rsRatingData.length - (col + extraItems)];

        const cell = {};
        cell.textAlign = "right";
        cell.value = "";
        if (item) {
            if (item.relativeStrength || item.relativeStrength === 0) {
                cell.value = item.relativeStrength;
            }
        }

        return cell;
    }

    getCellDateOnRating(rsRatingData, row, col, symbols) {
        let isValidData = false;
        for (let s = 0; s <= symbols.length - 1; s++) {
            const ratingsDataSymbol = rsRatingData.filter((x) => x.symbol === symbols[s].symbol);
            const cell = this.getDataCell(ratingsDataSymbol, row, col);
            if (cell.value) {
                isValidData = true;
            }
        }
        return isValidData;
    }

}