import React from 'react';
import { UpdateSharedData } from "../../../Actions/ShareAction"
import LocalizationStore from 'LocalizationStore';
import { ShareAccessType } from '../../../Constants/ShareConstants';
import { find } from "underscore";

const Permission = ({ isShared, recipientid, sharedData }) => {
    const handleChangePermission = (event) => {
        if (recipientid === 0) {
            sharedData.map((rowData) => {
                if(!rowData.isShared) rowData.accessLvl = ShareAccessType.SHARE_READONLY;
                rowData.isShared = !isShared;
                rowData.isModified = isShared
                                    ? rowData.isAdded ? false : true
                                    : rowData.isAdded ? true : rowData.isModified
            });
        }
        else {
            const updatedRow = find(sharedData, { recipientId: recipientid });

            if(!isShared) updatedRow.accessLvl = ShareAccessType.SHARE_READONLY;
            updatedRow.isShared = !isShared;
            updatedRow.isModified = isShared
                                    ? updatedRow.isAdded ? false : true
                                    : updatedRow.isAdded ? true : updatedRow.isModified
        }
        UpdateSharedData(sharedData);
    }
    const shareText = sharedData.length === 0
        ? LocalizationStore.getTranslatedData("ri_id41","Unshare")
        :
        isShared ? LocalizationStore.getTranslatedData("ri_id41","Unshare") : LocalizationStore.getTranslatedData("ri_id42","Share");

    if (isShared || sharedData.length !== 0) {
        return (
            <span className="actions permission" data-test-id="main-div">
                <a className="link" onClick={handleChangePermission}>
                    {shareText}
                </a>
            </span>
        )
    }
    else {
        return (
            <span className="actions permission" data-test-id="main-div">
                <span className='read-only'>{shareText}</span>
            </span>
        )
    }
}

export default Permission;