import { connect } from 'react-redux';
import LocalizationStore from "LocalizationStore";
import React from 'react';
import ThemeHelper from "ThemeHelper";
function Pivot(props) {
  let textColor = props.priceChangeValue >= 0 ? props.posDTColor : props.negDTColor;
  const sign = props.priceChangeValue < 0 ? "" : "+";
  const fontSize = props.videoMode ? "48px" : "12px";
  let pivotDisp = "";
  if (props.baseStatus > 0) {
    if (props.ticks !== null) {
      pivotDisp = props.ticks < 1 ? '' : LocalizationStore.getTranslatedData("ch_pivot", "{0}% from Pivot in {1} {2}", `${sign}${props.priceChangeValue}`, props.ticks, props.units);
    }
    else {
      // pivot date missing.. must be a still forming base
      // for still forming bases, we display the pivot-message without days/weeks
      pivotDisp = props.from ?
        LocalizationStore.getTranslatedData("ch_from_Pivot", "{0}% from Pivot", `${sign}${Math.abs(props.priceChangeValue)}`) :
        LocalizationStore.getTranslatedData("ch_to_pivot", "{0}% to Pivot", `${sign}${Math.abs(props.priceChangeValue)}`);
      if (props.color){
        textColor = ThemeHelper.getThemedBrush("grayCmLb");
      }
    }
  }
  return (props.IsVisible && <div className="pivot-percent" id='pivot' key='4'>
    <div className="pivot-percent-info" id="m-info">
      <div className="pivot-percent-text" style={{ color: textColor, fontSize: fontSize }} data-disable-track-price="true">{pivotDisp}</div>
    </div>
  </div>);
}
const mapStateToProps = ({ DatagraphReducers, appColor })=>{
  const { baseStatus, color, from, priceChangeValue, ticks, units, IsVisible } = DatagraphReducers.PRReducer;
  const {  videoMode } = DatagraphReducers.DataGraphReducer;
  const { posDTColor, negDTColor  } = appColor;
  return { baseStatus, color, from, priceChangeValue, ticks, units, videoMode, posDTColor, negDTColor, IsVisible }
}

export default connect(mapStateToProps, )(Pivot);