import React from "react";
import TabFundamentalsHeaderStore from 'Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsHeaderStore.js';
import IndustryGroupHeader from "../../../../RayCustomControls/MenuControl/IndustryGroupHeader.jsx";
import SmartViewType from "SmartViewType";
import LocalizationStore from 'Stores/Localization/LocalizationStore.js'
import { setGroupsMainData } from '../../../../Actions/RotationGraphActions';
import {getNavCompareState} from '../../../../Reducers/NavCompare/MenuPanelReducers/selectors';

import { connect } from 'react-redux';

import StringUtil from "StringUtil";

 class FundamentalsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = TabFundamentalsHeaderStore.getState();
    this.state = { ...this.state };
    this.changeEPSFormat = this.changeEPSFormat.bind(this);
    this.changeIPOFormat = this.changeIPOFormat.bind(this);
    this.getFundamentalCompanyInfo = this.getFundamentalCompanyInfo.bind(this);
    this.headerStoreStateChanged = this.headerStoreStateChanged.bind(this);
  }

  UNSAFE_componentWillMount = () => {
    if (StringUtil.isEmpty(this.props.groupsMainData)) {
      this.props.setGroupsMainData();
    }
  }

  componentDidMount() {
    TabFundamentalsHeaderStore.addChangeListener(this.headerStoreStateChanged);
  }

  componentWillUnmount() {
    TabFundamentalsHeaderStore.removeChangeListener(this.headerStoreStateChanged);
  }

  headerStoreStateChanged() {
    const state = TabFundamentalsHeaderStore.getState();
    this.setState(state);
  }

  changeEPSFormat() {
    TabFundamentalsHeaderStore.changeEPSFormat();
  }

  changeIPOFormat() {
    TabFundamentalsHeaderStore.changeIPOFormat();
  }

  getFundamentalCompanyInfo() {
    const companyInfo = this.state.companyInfo;
    const dynamicHeaderInfo = [];
    const info = this.props.SymbolInfo;
    const infoData = {
      industry197Symbol: info.Industry197Symbol,
      msid: info.MsId,
      CountryCode: info.CountryCode,
      Osid: info.Osid,
      IndCode: info.IndCode,
      Industry197Name: info.Industry197Name,
      Sector11Symbol: info.Sector11Symbol,
      // CountryISO: info.CountryISO,
      SymTypeEnum: info.SymTypeEnum
    }
    if (companyInfo !== undefined) {
      if (companyInfo.ipo && companyInfo.ipo !== " ") {
        dynamicHeaderInfo.push(<span key="2" className="value-blue medium-normal pull-left"><span data-toggle-state={this.props.getToggleStateId} onClick={this.changeIPOFormat}>{companyInfo.ipo}</span></span>)
        dynamicHeaderInfo.push(<span key="3" className="divider pull-left mart5"></span>)
      }
      if (companyInfo.type && companyInfo.type !== " ") {
        const arr = companyInfo.type.split(" ");
        let str = "";
        if (arr.length > 1) {
          //var newArr = arr.pop();
          str = arr.join(" ");
        } else {
          str = companyInfo.type;
        }

        const companyInfoForHeader = TabFundamentalsHeaderStore.getReportCompanyInfo();
        const gicsIndustryGroupName = companyInfoForHeader && companyInfoForHeader.headerData
          ? `GICS: ${LocalizationStore.getTranslatedData(`GICS_Industry_${companyInfoForHeader.headerData.GicsOsid}`, companyInfoForHeader.headerData.GicsIndustryGroupName)}`
          : `GICS: ${LocalizationStore.getTranslatedData(`FilterBrowser_IndustryGroup_${str.replace(/ /g, "").toUpperCase()}`, companyInfoForHeader.type)}`
        const isGICS = this.state.isGICSSelected_Fundamentals;

        !StringUtil.isEmpty(this.props.groupsMainData) && dynamicHeaderInfo.push(
          <IndustryGroupHeader
            industryGroup={
              isGICS
                ? gicsIndustryGroupName
                : LocalizationStore.getTranslatedData(`FilterBrowser_IndustryGroup_${str.replace(/ /g, "").toUpperCase()}`, companyInfo.type)
            }
            infoData={infoData}
            isGICS={isGICS}
            isStock={true}
            isConstituent={false}
            view={SmartViewType.FUNDAMENTALS}
            industryListData={this.props.groupsMainData["IndustryList"]}
            key="4"
          />
        );
        /* check merge issue */
        //dynamicHeaderInfo.push(<span key="4" className="info-dot medium-normal"><span>{LocalizationStore.getTranslatedData("FilterBrowser_IndustryGroup_" + str.replace(/ /g, "").toUpperCase(), companyInfo.type)}</span></span>)
        dynamicHeaderInfo.push(<span key="5" className="divider"></span>)
      }
      if (companyInfo.mktCap && companyInfo.mktCap !== " ") {
        dynamicHeaderInfo.push(<span key="6" className="value medium-normal">{companyInfo.mktCap}</span>)
        dynamicHeaderInfo.push(<span key="7" className="divider"></span>)
      }
      if (companyInfo.epsDue && companyInfo.epsDue !== " ") {
        dynamicHeaderInfo.push(<span key="8" className={companyInfo.IsWithinTwoWeeks ? "value-less-then medium-normal" : "value-blue medium-normal"} ><span onClick={this.changeEPSFormat}>{companyInfo.epsDue}</span></span>)
        dynamicHeaderInfo.push(<span key="9" className="divider"></span>)
      }
      dynamicHeaderInfo.length > 1 && dynamicHeaderInfo.pop();
    }
    return dynamicHeaderInfo;
  }
  render() {
    return (this.getFundamentalCompanyInfo());
  }
 }
  const mapDispatchToProps = (dispatch) => ({
    setGroupsMainData:() => dispatch(setGroupsMainData())
  })
  
  const mapStateToProps = (state) => {
    const { SymbolInfo } = state.DatagraphReducers.DataGraphReducer;
    return {
      SymbolInfo,
      groupsMainData : getNavCompareState(state).groupsMainData
    } 
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(FundamentalsHeader);