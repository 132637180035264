import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class ExportOwnershipListRequest extends BaseServiceRequest {
  constructor(listId,exportType,exportAll,selectedMsids,currentDataBase,hasGicsEntitlement,actualListId,searchText,
    sortCols,needColumnsInfo,useColFilters,saveColFilters,isMinilist,isUserList,customerOrder,pageIndex,size,colsetId,addlCatalogRequests,lang,isNotImportedList = false) {
    super();
    super.addRequestUri("exportlist.pb");
    super.addPathParameter(listId.toString());
    super.addRequestParameter("type",exportType.toString());
    super.addRequestParameter("isall",exportAll);
    super.addRequestParameter("msids",selectedMsids);
    super.addRequestParameter("db",currentDataBase);
    super.addRequestParameter("gicsent",hasGicsEntitlement);
    
    //super.addRequestParameter("rts","")//((int)WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.IntradaySourceType).ToString()
    super.addRequestParameter("aid",actualListId.toString())
    if (searchText) super.addRequestParameter("srch", searchText); 
    if (sortCols != null && sortCols.length > 0) {
      let sortParams = '';
      for (let i = 0; i < sortCols.length; i++) {
        let asc = sortCols[i].sortAsc ? 1 : 0;
        sortParams += sortCols[i].dataItemId + ',' + asc + ';';
      }
      super.addRequestParameter("sort", sortParams);
    }
    super.addRequestParameter("rn", "1");
    super.addRequestParameter("c", parseInt(needColumnsInfo).toString());
    super.addRequestParameter("ucf", parseInt(useColFilters).toString());
    super.addRequestParameter("scf", parseInt(saveColFilters).toString());
    super.addRequestParameter("iml", parseInt(isMinilist).toString());
    super.addRequestParameter("iul", parseInt(isUserList).toString());
    super.addRequestParameter("icf", "1");
    super.addRequestParameter("so", parseInt(customerOrder).toString());
    super.addRequestParameter("cfv", "2");
    super.addRequestParameter("pgi",pageIndex.toString())
    super.addRequestParameter("size",size.toString())
    super.addRequestParameter("lang",lang.toString())
    const isNotImportedListValue = isNotImportedList ? "1" : "0";
    super.addRequestParameter("nImpList", isNotImportedListValue);//1
    if (colsetId != -1) super.addRequestParameter("csid", colsetId.toString());

    if (addlCatalogRequests != null) {
      super.setContent(addlCatalogRequests);
    }

    this.methodType = HttpMethodType.POST;
  }

  getMethod() {
    return this.methodType;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "blob";
  }
}

export default ExportOwnershipListRequest;