import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetOneilResearchAnalystRequest extends BaseServiceRequest {
    constructor(osid) {
        super();
        super.addRequestUri("analystInfo.pb");
        super.addRequestParameter("osid", osid);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "AnalystInfoResponseModel";
    }
}

export default GetOneilResearchAnalystRequest;