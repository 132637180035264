import { connect } from 'react-redux';
import { PriceChartConst } from '../../../../../../Constants/PriceChartConstants';
import QuarterlyLine from "QuarterlyLine";
import React from 'react';
import { showContextMenu } from '../../../../../../Actions/PricePanelActions';

function FundamentalLines(props){
    const { processedLineData, fundamentalLineSettings, showPointer, lineType, pointerVisibility, lastQtrData, lastQtrY } = props;
    return  ((fundamentalLineSettings && fundamentalLineSettings.IsVisible) ? 
                <QuarterlyLine isAnyDrag={props.isDragging} isToolTip={props.showToolTip}
                showPointer={showPointer}
                DataSource={processedLineData} LineColor={fundamentalLineSettings.lineColor}
                frontColor={"white"} LineThickness={fundamentalLineSettings.lineThickness}
                pointerVisibility={pointerVisibility}
                Draggable={true} BoxNodes={lineType === PriceChartConst.RPS} type={lineType}
                isPlaying={lineType === PriceChartConst.EPS && props.isPlaying}
                SurpriseNodes={lineType === PriceChartConst.EPS && props.fundamentalLineSettings.showEarningSurprise}
                Scale={props.scaleLabel}
                onOpenFundamentalLineMenu= {(event)=> props.showContextMenu(event, props.dimension.top)}
                pointerContent = {lastQtrData}
                pointerYValue = {lastQtrY}
                /> : null)
}

const mapStateToProps = (state, props) => {
    const FundamentalLineReducer =  state.DatagraphReducers.FundamentalLineReducer;
    const  PriceChartReducer = state.DatagraphReducers.PriceChartReducer;
    const states = {};
    states.isThemeChanged = state.appColor.isThemeChanged;
    states.processedLineData = FundamentalLineReducer.processedLineData[props.lineType];
    states.fundamentalLineSettings = FundamentalLineReducer.fundamentalLineSettings[props.lineType];
    states.IsVisible = states.fundamentalLineSettings && states.fundamentalLineSettings.IsVisible;
    states.showPointer = FundamentalLineReducer.showPointer[props.lineType];
    // state.selectedPerShare = FundamentalLineReducer.selectedPerShare;
    states.scaleLabel = PriceChartReducer.scaleLabel;
    states.dimension = PriceChartReducer.dimension;
    states.pointerVisibility = FundamentalLineReducer.selectedPerShare === props.lineType ? "visible" : "collapse";
    states.lastQtrData = FundamentalLineReducer.lastQtrData[props.lineType];
    states.lastQtrY = FundamentalLineReducer.lastQtrY[props.lineType]-10;
    states.isPlaying = FundamentalLineReducer.isPlaying;
    states.showToolTip = state.DatagraphReducers.DataGraphReducer.showToolTip;
    return states;
}
const mapDispatchToProps = (dispatch, props)=>({
    showContextMenu: (event, top)=> dispatch(showContextMenu(event, top, props.lineType))
})
export default connect(mapStateToProps, mapDispatchToProps)(FundamentalLines);