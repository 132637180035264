import React from "react";
import { Table } from "react-bootstrap";
import StringUtil from "StringUtil";
import ListActions from "ListActions";
import ONeilViewStore from "ONeilViewStore";
import ListStore from 'ListStore';
import OwnershipViewStore from "OwnershipViewStore";
import ListManagerTabType from "ListManagerTabType";
import ListExplorerStore from "ListExplorerStore";
import BrowserUtil from "BrowserUtil";
import ScrollBar from "ScrollBar";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import FormatterUtil from "FormatterUtil";
import {saveScreenedResults} from "../../Actions/OwnerShipActions";

export default class ScreenSummary extends React.Component {
    constructor(props) {
        super(props);
        this.outsideClickDocument = this.outsideClickDocument.bind(this);
        this.saveResultsAsDulicateItem = this.saveResultsAsDulicateItem.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);

        this.state = {
        }
        this.mouseDownOnModal = false;
        this.mouseUpOnModal = false;
    }
    componentDidMount() {
        document.addEventListener('mouseup', this.outsideClickDocument, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.outsideClickDocument, false);
    }

    saveResultsAsDulicateItem() {
        BrowserUtil.disableEvents();
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        switch (tabType) {
            case ListManagerTabType.Oneil:
                ListActions.saveScreenedResults();
                break;
            case ListManagerTabType.Ownership:
                saveScreenedResults();
                break;
        }

    }

    outsideClickDocument(e) {
        let target = e.target || e.srcElement;
        let wasDown = this.mouseDownOnModal;
        let wasUp = this.mouseUpOnModal;
        this.mouseDownOnModal = false;
        this.mouseUpOnModal = false;
        if (!wasDown && !wasUp) {
            if (target.id != "screenButton") {
                this.props.handleClick(false);
            }
        }
        e.stopPropagation();
    }

    handleMouseDown() {
        this.mouseDownOnModal = true;
    }

    handleMouseUp() {
        this.mouseUpOnModal = true;
    }

    getTr() {
        let tableRows = [];
        let length = this.props.screenSummaryDetails.length;
        let defaultValue = 5;
        let valToShow = defaultValue - length;
        let summaryTitle = '';
        this.props.screenSummaryDetails.map((value, i) => {
            summaryTitle = value.criteria.slice(1);
            if(value.criteria.includes("-")){
                let splitCriteria = value.criteria.split("-");
                if(splitCriteria.length == 2 && !isNaN(splitCriteria[0]))
                    summaryTitle = value.criteria;
            }
            tableRows.push(
                <tr key={i}>
                    <td className="small-normal"><span className="screen-symmary-data-info" title={LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + value.id, value.type)}>{LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + value.id, value.type)}</span></td>
                    <td className="small-normal"><span className="screen-symmary-data-info" title={summaryTitle}>{value.criteria}</span></td>
                </tr>
            );
        });
        for (let i = 0; i < valToShow; i++) {
            tableRows.push(
                <tr key={length + i}>
                    <td className="small-normal">&nbsp;</td>
                    <td className="small-normal">&nbsp;</td>
                </tr>
            );
        }
        return tableRows;
    }

    render() {
        let openScreenSummary = (this.props.openScreenSummary ? 'block' : 'none');
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        let isSaveResults;
        switch (tabType) {
            case ListManagerTabType.Oneil:
                isSaveResults = ONeilViewStore.getSaveScreenedResultsVisibilty();
                break;
            case ListManagerTabType.Ownership:
                isSaveResults = OwnershipViewStore.getSaveScreenedResultsVisibilty();
                break;
        }
        const isCryptoCurrencyList = ListExplorerStore.getIsCryptoCurrencyList();
        const isNotImportedList = ListExplorerStore.isNotImportedList();
        let tableRows = this.getTr();
        const filterCount = FormatterUtil.formatNumber(this.props.filterCount?this.props.filterCount:0);
        const totalCount = FormatterUtil.formatNumber(this.props.totalCount);
        return (
            <div className='pos-relative' onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp} style={{ display: openScreenSummary }}>
                <div className="custom-panel-block screen-summary-block">
                    <div className="panel panel-default">
                        <div className="panel-heading medium-bold">
                            <span className="panel-title cap-header">{LocalizationStore.getTranslatedData("LM_tp_ScreenSumm", "SCREEN SUMMARY")}</span>
                            <button type="button" className="btn btn-default btn-secondary btn-closing" data-effect="fadeOut" onClick={this.props.handleClick}><span className="icn-closing"></span></button>
                        </div>
                        <div className="panel-body">
                            <div className="inner-panel-body summery dialog-content">
                                <div>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th className="small-normal">{LocalizationStore.getTranslatedData("LM_tp_ScreenMetric", "Metric")}</th>
                                                <th className="small-normal">{LocalizationStore.getTranslatedData("LM_tp_ScreenCriteria", "Criteria")}</th>
                                            </tr>
                                        </thead>
                                    </Table>

                                    <div className="tbl-data-summary">
                                        <div className="custom-scroll custom-scroll-light">
                                            <div id='list-screen-summary-VerticalScroll'>
                                                <Table bordered>
                                                    <tbody>
                                                        {tableRows}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <ScrollBar scrollId="list-screen-summary-VerticalScroll" vScroll={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="screen-result">
                                    <ul>
                                        <li className="small-normal"><span>{LocalizationStore.getTranslatedData("LM_tp_Screencf", "Column Filters : ")} {this.props.screenSummaryDetails.length}</span>
                                            <span className={(this.props.screenSummaryDetails.length == 0 || ONeilViewStore.getIsSharedColumnSet()) ? "clear-all-disable" : "clear-all-enable"} onClick={this.props.screenSummaryDetails.length != 0 && (!ONeilViewStore.getIsSharedColumnSet()) ? this.props.onClearAllFilter : null}> &nbsp; {LocalizationStore.getTranslatedData("LM_tp_ScreenClearAll", "CLEAR ALL")}</span>


                                            {/*<button type="button" className=" model-action btn btn-xs btn-default btn-secondary pull-left small-bold" onClick={this.props.closeModal}>Cancel</button>
           <button type="button" className=" model-action btn btn-xs btn-default btn-secondary small-bold" onClick={this.handleDelete}>Yes</button>*/}


                                        </li>
                                        {
                                             <li className="small-normal"><span>{LocalizationStore.getTranslatedData("LM_tp_ScreenNum", "Number of Results : ")}</span> <span> {StringUtil.stringFormat(LocalizationStore.getTranslatedData("LM_tp_ScreenNumResult", "{0} out of {1}"), filterCount, totalCount)}</span></li>
                                        }

                                    </ul>
                                </div>
                            </div>
                            <div className="align-center">
                                <div className={!isSaveResults || isCryptoCurrencyList || isNotImportedList ? "btn-secondary" : "btn-secondary-enable"} onClick={isSaveResults && !isCryptoCurrencyList && !isNotImportedList ? this.saveResultsAsDulicateItem : null}>{LocalizationStore.getTranslatedData("LM_tp_ScreenSave", "Save Results")}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
