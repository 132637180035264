import React, { Component } from 'react';
import ChartRefresh from '../../ToggleMenuPanel/Containers/ChartRefresh';
import Scales from './Scales.jsx';
import ComparisonChart from './ComparisonChart.jsx';
import ComparisonCalendar from "./ComparisonCalendar.jsx";
import { Alert } from "react-bootstrap";
import IntervalDropdown from "./IntervalDropdown.jsx";
import ScaleSection from './ScaleSection.jsx';
import TimeLineCalenderView from './TimeLineCalenderView';
import ErrorBoundary from 'ErrorBoundary';
import CompIndexLineProgressBar from './CompIndexLineProgressBar.jsx';
import GeographyListView from '../../ToggleMenuPanel/Presentational/GeographyListView';
import { showAddListAlertMessage, setListAddAlertMessage } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { connect } from 'react-redux';
import Loading from '../../ToggleMenuPanel/Loading';
import { LiqAndRatingFilterDialog } from '../../ToggleMenuPanel/Containers/index.js';
import { PrintMode } from '../../../../../print/printmode';
import { AddListModal } from '../../ToggleMenuPanel/Presentational';
import CompareTabType from "CompareTabType";

class ComparisonGraph extends Component {

    constructor() {
        super();
    }

    closeAlertPopup() {
        this.props.setListAddAlertMessageStatus(false);
        this.props.setListAddAlertMessage(null);
    }

    hideAlert(showAddListAlertMessage) {
        if (showAddListAlertMessage) {
            setTimeout(() => {
                this.props.setListAddAlertMessageStatus(false);
                this.props.setListAddAlertMessage(null);
            }, 5000);
        }
    }

    render() {
        const paddingStyle = PrintMode.printing ? { padding: '0 0 55px' } : {};
        const { alertMessage, showAddListAlertMessage } = this.props;

        this.hideAlert(showAddListAlertMessage);
        return (
            <div className="COLS">
                <LiqAndRatingFilterDialog />
                <div className="CELL _FLEX panaray-container">
                    <div id="mainBlock" className="comparison-graph">
                        <div id="page-content-wrapper">
                            <div className="page-content" data-spy="scroll" data-target="#spy">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div id="compare_container">
                                            <div className="mainBlockWrap" >
                                                <div id="tabMainBlock" >
                                                    <Loading />
                                                    <div className="info-tabMain comparison-holder">
                                                        <GeographyListView />
                                                        <ErrorBoundary><AddListModal /></ErrorBoundary>
                                                        <div className="header-rotation" style={{ height: PrintMode.printing ? '30px' : '65px' }}>
                                                            {!PrintMode.printing && <div id="head-main-rotation">
                                                                <ErrorBoundary><ChartRefresh isRotationGraph={false} /></ErrorBoundary>
                                                            </div>}
                                                            <div className="alert-wrap">
                                                                {showAddListAlertMessage == true && alertMessage != null ? <Alert style={{ display: 'inline-block', position: 'relative', left: '150px', top: '5px' }} className="custom-alert" bsStyle="warning" onDismiss={() => this.closeAlertPopup()}>
                                                                    <span >{alertMessage}</span>
                                                                </Alert> : ''}
                                                            </div>
                                                            <ErrorBoundary><Scales /></ErrorBoundary>
                                                        </div>
                                                        <div className="comparison-state">
                                                            <div className="center-rotation">
                                                                <ErrorBoundary><ComparisonChart /></ErrorBoundary>
                                                                <ErrorBoundary><CompIndexLineProgressBar /></ErrorBoundary>

                                                                <div className="details-of-chart">
                                                                    <ErrorBoundary><TimeLineCalenderView /></ErrorBoundary>
                                                                </div>
                                                            </div>

                                                            <ErrorBoundary><ScaleSection /></ErrorBoundary>
                                                        </div>
                                                        {!PrintMode.printing && <div className="footer-rotation">
                                                            <ErrorBoundary><IntervalDropdown /></ErrorBoundary>
                                                            <ErrorBoundary><ComparisonCalendar /></ErrorBoundary>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { selectedTab } = state.navCompareReducer;
    const symbolsMenu = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
    const { alertMessage, showAddListAlertMessage } = selectedTab === CompareTabType.ComparisonGraph ? symbolsMenu.ComparisonMenuStates : symbolsMenu.RotationMenuStates;

    return { alertMessage, showAddListAlertMessage };
};

const mapDispatchToProps = (dispatch) => ({
    setListAddAlertMessageStatus: (status) => dispatch(showAddListAlertMessage(status)),
    setListAddAlertMessage: (message) => dispatch(setListAddAlertMessage(message))
})
export default connect(mapStateToProps, mapDispatchToProps)(ComparisonGraph);
