class MathUtil {

    // Floating-point addition operation
    /**
     * @return {number}
     */
    FloatAdd(arg1, arg2) {
        var r1, r2, m;
        try {
            r1 = arg1.toString().split(".")[1].length
        }
        catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        }
        catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    }

    //Floating-point subtraction
    /**
     * @return {string}
     */
    FloatSub(arg1, arg2) {
        var r1, r2, m, n;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2));
        //Dynamic control precision length
        n = (r1 >= r2) ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    }

    //Floating-point multiplication
    /**
     * @return {number}
     */
    FloatMul(arg1, arg2) {
        var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length;
        } catch (e) {
        }
        try {
            m += s2.split(".")[1].length;
        } catch (e) {
        }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
    }

    // Floating-point division operation
    /**
     * @return {number}
     */
    FloatDiv(arg1, arg2) {
        var t1 = 0, t2 = 0, r1, r2;
        try {
            t1 = arg1.toString().split(".")[1].length;
        }
        catch
        (e) {
        }
        try {
            t2 = arg2.toString().split(".")[1].length;
        }
        catch
        (e) {
        }

        r1 = Number(arg1.toString().replace(".", ""));
        r2 = Number(arg2.toString().replace(".", ""));
        return (r1 / r2) * Math.pow(10, t2 - t1);

    }

    /**
     * formatMoney(n, x)
     * @param integer s: format data
     * @param integer n: length of decimal
     * @param integer x: length of sections
     */
    formatMoney(s, n, x) {
        var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
        return s.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
    }

}
const mathUtil = new MathUtil();
export default mathUtil;
