import Serializable from "Serializable";

class RiPanelSimilarFundSettings extends Serializable {
    constructor() {
        super();
        this.IsRelativeYTD = false;
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.IsRelativeYTD = serializedValue.IsRelativeYTD ? serializedValue.IsRelativeYTD : false;
    }

    getObjectMapKey() {
        return "RiPanelSimilarFundSettings";
    }

    getDataObject() {
        return {
            IsRelativeYTD: this.IsRelativeYTD
        };
    }
}

export default RiPanelSimilarFundSettings;
