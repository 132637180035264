import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class DeleteAllListAlertsRequest extends BaseServiceRequest {
  constructor(listId,conditionTypeId) {
    super();

    super.addRequestUri("listalertsdelete.pb");

    super.addRequestParameter("lid", listId.toString());
    super.addRequestParameter("cid", conditionTypeId);
  }

  getMethod() {
    return HttpMethodType.DELETE;
  }

  getSubSystem() {
    return SubSystemType.ALERTS;
  }

  getMessageName() {
    return "Response";
  }

}