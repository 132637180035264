import BaseServiceRequestIIS from 'BaseServiceRequestIIS';
import SubSystemType from 'SubSystemType';

class GetDefaultFinancialBlockHeadersRequest extends BaseServiceRequestIIS {
    constructor() {
        super();
    }

    getServiceMethod() {
        return "GetDefaultFinancialBlockHeaders";
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "FinancialBlockHeader";
    }
}

export default GetDefaultFinancialBlockHeadersRequest;