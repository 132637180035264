import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { getCenterRotationState } from '../../../../../Reducers/NavCompare/RotationGraphReducers/selectors'
import { getRotationGraphMenuData } from '../../../../../Reducers/NavCompare/RotationGraphReducers/reselectorsdata';
import { RotationMenuType } from "Constants/RotationMenuType";
import { CompRotationHelper } from '../../../../../Models/NavCompare/ComparisonAndRotationMenuModels/MenuStates';
import pointInSvgPolygon from "../../../../../RayCustomControls/RotationGraph/PointInSVG";
import { find, map, each, isEmpty, every } from "underscore";
import StringUtil from "StringUtil";
import TimeTrackingWindow from "TimeTrackingWindow";
import { listItemHighlightSelected } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { CompareMenuType } from "CompareTabType";
import UserInfoUtil from "UserInfoUtil";

const cellWidth = require("text-width");
let _zoomValue = 1;

class TadPoleContainer extends Component {

    setItem(item) {
        const { listItemType, listItems } = this.props;

        if (listItemType == RotationMenuType.Sector) {
            if (listItems != null && listItems.some((c) => c != null && c.symbol.toUpperCase() === item.sectorLabel.toUpperCase())) {
                const firstOrDefault = listItems.filter((c) => c != null && c.symbol.toUpperCase() === item.sectorLabel.toUpperCase());
                if (firstOrDefault !== null)
                    this.item = firstOrDefault[0];
            }
        }
        else if (listItemType === RotationMenuType.Industry) {
            this.item = find(listItems, (listItem) => listItem.osid === item.id.low);
        }
        else if (listItemType === RotationMenuType.Stocks) {
            this.item = find(listItems, (listItem) => listItem.osid === item.id.low);
        }
    };

    // source http://stackoverflow.com/questions/9043805/test-if-two-lines-intersect-javascript-function (10)
    CCW(p1, p2, p3) {
        const a = p1.x;
        const b = p1.y;
        const c = p2.x;
        const d = p2.y;
        const e = p3.x;
        const f = p3.y;
        return (f - b) * (c - a) > (d - b) * (e - a);
    };

    isIntersect(p1, p2, p3, p4) {
        return (this.CCW(p1, p3, p4) !== this.CCW(p2, p3, p4)) && (this.CCW(p1, p2, p3) !== this.CCW(p1, p2, p4))
    };

    checkPointInPath(vs, checkPt) {
        const verticies = vs;/*var verticies = [
            { x: 100, y: 100 },
            { x: 100, y: 200 },
              {x: 100, y:100}
          ]*/

        const bBox = (function () {
            let maxX = Number.MIN_VALUE;
            let maxY = Number.MIN_VALUE;
            let minX = Number.MAX_VALUE;
            let minY = Number.MAX_VALUE;

            each(verticies, (d) => {
                if (d.x > maxX) maxX = d.x;
                if (d.y > maxY) maxY = d.y;
                if (d.x < minX) minX = d.x;
                if (d.y < minY) minY = d.y;
            })
            return {
                ul: { x: minX, y: minY },
                ur: { x: maxX, y: minY },
                lr: { x: maxX, y: maxY },
                ll: { x: minX, y: maxY },
                width: maxX - minX,
                height: maxY - minY,
                midX: (maxX - minX) / 2,
                midY: (maxY - minY) / 2
            };
        })();

        // determine initial location of test point
        const point = { x: ~~bBox.midX, y: ~~bBox.midY };
        let endPoint;
        point.x = checkPt.X;//{ x: 100, y: 100.05 }
        point.y = checkPt.Y;
        const segments = map(verticies, (d, i, a) => {
            const p1 = a[i];
            const p2 = (i == a.length - 1) ? a[0] : a[i + 1];
            return [p1, p2];
        });

        const horiz = point.x < bBox.midX ? bBox.lr.x + 30 : bBox.ul.x - 30;
        const vert = point.y < bBox.midY ? bBox.lr.y + 30 : bBox.ul.y - 30;

        // set ray's end point
        endPoint = { x: horiz, y: vert }
        let count = 0;

        each(segments, (d) => {
            const intersect = this.isIntersect(d[0], d[1], point, endPoint);
            if (intersect) {
                count++;
            }
        });

        return (count % 2) ? true : false;
    };

    isPointInsidePath(pathData, pt) {
        if (pathData) {
            const pathString = pathData.slice(0) + "Z";
            return pointInSvgPolygon.isInside([Number(pt.X), Number(pt.Y)], pathString);
        }
        return false;
    }

    hitTestForLabels(pt) {
        if (this.labelPolesData.length > 0) {
            const resultValue = every(this.labelPolesData, (label) => {
                //if (label) return !this.isPointInsidePath(label.labelRect, pt);
                if (label) return !this.checkPointInPath(label.labelRect, pt);
            });
            return !resultValue;
        }
        return false;
    };

    hitTestForLine(pt) {
        if (this.linesData.length > 0) {
            const resultValue = every(this.linesData, (line) => {
                //if (line) return !this.isPointInsidePath(line.linePathData, pt);
                if (line) return !this.checkPointInPath(line.linePathData, pt);
            });
            return !resultValue;
        }
        return false;
    };

    isHitTestforLabel(pt, boolintersectionpoints) {
        if (boolintersectionpoints === null) {
            return false;
        }
        let hitTestResult = null, hitTestResult1 = null;

        if (pt.X < 0 || pt.Y < 0 || pt.X >= this.props.scaleHeight || pt.Y >= this.props.scaleHeight) {
            hitTestResult = true;
            hitTestResult1 = true;
        }
        else {
            hitTestResult = this.hitTestForLine(pt);
            hitTestResult1 = this.hitTestForLabels(pt);
        }

        if (hitTestResult === true || hitTestResult1 === true) {
            boolintersectionpoints.push(true);
            return true;
        }
        return false;
    };

    getTestLines(valueDictionary, ind, testline1, testline2, testline3, testline4, formattedText) {
        const const10 = 10;
        let zoomvalueincrment = const10 - _zoomValue;
        zoomvalueincrment = zoomvalueincrment <= 0 ? 1 : zoomvalueincrment;

        if (formattedText !== null && !StringUtil.isEmpty(valueDictionary)) {
            const width = Number(formattedText.Width) + zoomvalueincrment;
            const height = Number(formattedText.Height) + zoomvalueincrment;
            const xScale = this.props.xScale;
            const yScale = this.props.yScale;

            if (ind === 0) {
                if (testline1 !== null) {
                    testline1.X1 = (xScale.computeX(valueDictionary[0].xValue) - width / 2);
                    testline1.Y1 = (this.props.scaleHeight - (yScale.computeY(valueDictionary[0].yValue) + height));
                    testline1.X2 = (xScale.computeX(valueDictionary[0].xValue) + width / 2);
                    testline1.Y2 = (this.props.scaleHeight - (yScale.computeY(valueDictionary[0].yValue) + height));
                    if (testline2 !== null) {
                        testline2.X1 = testline1.X2;
                        testline2.Y1 = testline1.Y2;
                        testline2.X2 = testline1.X2;
                        testline2.Y2 = testline1.Y2 + height;
                    }
                }

                if (testline3 !== null) {
                    if (testline2 !== null) {
                        testline3.X1 = testline2.X2;
                        testline3.Y1 = testline2.Y2 - 1;
                        testline3.X2 = testline2.X2 - width;
                        testline3.Y2 = testline2.Y2 - 1;
                    }

                    if (testline4 !== null) {
                        testline4.X1 = testline3.X2;
                        testline4.Y1 = testline3.Y2;
                        testline4.X2 = testline3.X2;
                        testline4.Y2 = testline3.Y2 - height;
                    }
                }
            }
            if (ind === 1) {
                if (testline1 != null) {
                    testline1.X1 = (xScale.computeX(valueDictionary[0].xValue) + width / 2);
                    testline1.Y1 = (this.props.scaleHeight - (yScale.computeY(valueDictionary[0].yValue) + height / 2));
                    testline1.X2 = (xScale.computeX(valueDictionary[0].xValue) + width);
                    testline1.Y2 = (this.props.scaleHeight - (yScale.computeY(valueDictionary[0].yValue) + height / 2));

                    if (testline2 !== null) {
                        testline2.X1 = testline1.X2;
                        testline2.Y1 = testline1.Y2;
                        testline2.X2 = testline1.X2;
                        testline2.Y2 = testline1.Y2 + height;
                    }
                }

                if (testline3 !== null) {
                    if (testline2 != null) {
                        testline3.X1 = testline2.X2;
                        testline3.Y1 = testline2.Y2;
                        testline3.X2 = testline2.X2 - width;
                        testline3.Y2 = testline2.Y2;
                    }

                    if (testline4 !== null) {
                        testline4.X1 = testline3.X2;
                        testline4.Y1 = testline3.Y2;
                        testline4.X2 = testline3.X2;
                        testline4.Y2 = testline3.Y2 - height;
                    }
                }
            }
            if (ind === 2) {
                if (testline1 !== null) {
                    testline1.X1 = (xScale.computeX(valueDictionary[0].xValue) - width / 2);
                    testline1.Y1 = (this.props.scaleHeight - (yScale.computeY(valueDictionary[0].yValue) - 1));
                    testline1.X2 = ((xScale.computeX(valueDictionary[0].xValue) + width / 2));
                    testline1.Y2 = (this.props.scaleHeight - (yScale.computeY(valueDictionary[0].yValue) - 1));
                }
                if (testline2 !== null) {
                    if (testline1 !== null) {
                        testline2.X1 = testline1.X2;
                        testline2.Y1 = testline1.Y2;
                        testline2.X2 = testline1.X2;
                        testline2.Y2 = testline1.Y2 + height;
                    }

                    if (testline3 !== null) {
                        testline3.X1 = testline2.X2;
                        testline3.Y1 = testline2.Y2;
                        testline3.X2 = testline2.X2 - width;
                        testline3.Y2 = testline2.Y2;
                    }
                }

                if (testline4 !== null) {
                    if (testline3 !== null) {
                        testline4.X1 = testline3.X2;
                        testline4.Y1 = testline3.Y2;
                        testline4.X2 = testline3.X2;
                        testline4.Y2 = testline3.Y2 - height;
                    }
                }
            }
            if (ind === 3) {
                if (testline1 !== null) {
                    testline1.X1 = (xScale.computeX(valueDictionary[0].xValue) - width - 1);
                    testline1.Y1 = (this.props.scaleHeight - (yScale.computeY(valueDictionary[0].yValue) + height / 2));
                    testline1.X2 = (xScale.computeX(valueDictionary[0].xValue) - 1);
                    testline1.Y2 = (this.props.scaleHeight - (yScale.computeY(valueDictionary[0].yValue) + height / 2));
                    if (testline2 !== null) {
                        testline2.X1 = testline1.X2 - 1;
                        testline2.Y1 = testline1.Y2;
                        testline2.X2 = testline1.X2 - 1;
                        testline2.Y2 = testline1.Y2 + height;
                    }
                }

                if (testline2 !== null) {
                    if (testline3 !== null) {
                        testline3.X1 = testline2.X2 - 1;
                        testline3.Y1 = testline2.Y2;
                        testline3.X2 = testline2.X2 - width - 1;
                        testline3.Y2 = testline2.Y2;
                    }
                }

                if (testline3 !== null) {
                    if (testline4 !== null) {
                        testline4.X1 = testline3.X2 - 1;
                        testline4.Y1 = testline3.Y2;
                        testline4.X2 = testline3.X2 - 1;
                        testline4.Y2 = testline3.Y2 - height;
                    }
                }
            }
        }
    };

    getSlopeIndex(slope, ind) {
        let index = 0;
        if (slope > 45 && slope <= 135) {
            return index = ind;
        }
        else if (slope > 135 && slope <= 225) {
            switch (ind) {
                case 0:
                    return index = 3;
                case 1:
                    return index = 2;
                case 2:
                    return index = 1;
                case 3:
                    return index = 0;
                default:
                    return index;
            }
        }
        else if (slope > 225 && slope <= 315) {
            switch (ind) {
                case 0:
                    return index = 2;
                case 1:
                    return index = 1;
                case 2:
                    return index = 0;
                case 3:
                    return index = 3;
                default:
                    return index;
            }
        }
        else if ((slope > 315 && slope <= 360) || (slope >= 0 && slope <= 45)) {
            switch (ind) {
                case 0:
                    return index = 1;
                case 1:
                    return index = 0;
                case 2:
                    return index = 3;
                case 3:
                    return index = 2;
                default:
                    return index;
            }
        }
    };

    angle(px1, py1, px2, py2) {
        // Negate X and Y values
        const pxRes = px2 - px1;
        const pyRes = py2 - py1;
        let angle;
        // Calculate the angle
        if (Math.abs(pxRes - 0.0) < Number.EPSILON) {
            if (pyRes > 0.0) angle = Math.PI / 2.0;
            else
                angle = Math.PI * 3.0 / 2.0;
        }
        else if (Math.abs(pyRes - 0.0) < Number.EPSILON) {
            angle = pxRes > 0.0 ? 0.0 : Math.PI;
        }
        else {
            if (pxRes < 0.0)
                angle = (Math.atan(pyRes / pxRes) + Math.PI);
            else if (pyRes < 0.0) angle = Math.atan(pyRes / pxRes) + (2 * Math.PI);
            else
                angle = Math.atan(pyRes / pxRes);
        }
        // Convert to degrees
        angle = angle * 180 / Math.PI;
        if (Math.abs(pxRes - 0.0) < Number.EPSILON) {
            if (py2 > py1) {
                angle = 90;
            }
            else if (py1 > py2) {
                angle = 270;
            }
        }

        if (Math.abs(pyRes - 0.0) < Number.EPSILON) {
            if (px2 > px1) {
                angle = 0.0;
            }
            else if (px1 > px2) {
                angle = 180;
            }
        }

        return angle;
    };

    getSlope(valueDictionary) {
        if (StringUtil.isEmpty(valueDictionary) || valueDictionary.length < 2) {
            return 0;
        }
        const firstpoint = valueDictionary[1];
        const secondpoint = valueDictionary[0];
        return this.angle(firstpoint.xValue, firstpoint.yValue, secondpoint.xValue, secondpoint.yValue);
    };

    doAddHitTest(valueDictionary, sectorFullName, emSize, index, labelpixellength, id, isLabelHighlighted) {
        const slope = this.getSlope(valueDictionary);
        const testline1 = { X1: 0, Y1: 0, X2: 0, Y2: 0 }, testline2 = { X1: 0, Y1: 0, X2: 0, Y2: 0 }, testline3 = { X1: 0, Y1: 0, X2: 0, Y2: 0 }, testline4 = { X1: 0, Y1: 0, X2: 0, Y2: 0 };
        if (sectorFullName !== null) {
            const textWidth = cellWidth(sectorFullName, {
                family: "calibri",
                size: emSize
            });


            const formattedText = {
                Width: Number(textWidth.toFixed(2)),
                Height: Number(emSize * 1.33 - 1)
            }

            const boolintersectionpoints = [];

            const slopeindex = this.getSlopeIndex(slope, index);
            if (!StringUtil.isEmpty(valueDictionary))
                this.getTestLines(valueDictionary, slopeindex, testline1, testline2, testline3, testline4, formattedText);
            const line1X1 = Number(testline1.X1);
            const line1Y1 = Number(testline1.Y1);

            // labelrect.Location = new Point(line1X1, line1Y1);
            // labelrect.Size = new Size(formattedText.Width, formattedText.Height);

            const labelrect = {
                TopLeft: {
                    X: line1X1,
                    Y: line1Y1
                },
                BottomLeft: {
                    X: line1X1,
                    Y: (line1Y1 + formattedText.Height)
                },
                TopRight: {
                    X: (line1X1 + formattedText.Width),
                    Y: line1Y1
                },
                BottomRight: {
                    X: (line1X1 + formattedText.Width),
                    Y: (line1Y1 + formattedText.Height)
                }
            };
            let cnt = labelrect.TopRight.X - labelrect.TopLeft.X;
            let i = 0;
            while (i <= cnt) {
                if (this.isHitTestforLabel({ X: (labelrect.TopLeft.X + i), Y: labelrect.TopLeft.Y }, boolintersectionpoints, sectorFullName)) break;
                i += labelpixellength;
            }

            if (boolintersectionpoints.length > 0) {
                return false;
            }
            cnt = labelrect.BottomLeft.Y - labelrect.TopLeft.Y;
            i = 0;
            while (i <= cnt) {
                if (this.isHitTestforLabel({ X: labelrect.TopLeft.X, Y: (labelrect.TopLeft.Y + i) }, boolintersectionpoints, sectorFullName)) break;
                i += labelpixellength;
            }
            if (boolintersectionpoints.length > 0) {
                return false;
            }

            cnt = labelrect.BottomRight.Y - labelrect.TopRight.Y;
            i = 0;
            while (i <= cnt) {
                if (this.isHitTestforLabel({ X: labelrect.TopRight.X, Y: (labelrect.TopRight.Y + i) }, boolintersectionpoints, sectorFullName)) break;
                i += labelpixellength;
            }
            if (boolintersectionpoints.length > 0) {
                return false;
            }

            cnt = labelrect.BottomRight.X - labelrect.BottomLeft.X;
            i = 0;
            while (i <= cnt) {
                if (this.isHitTestforLabel({ X: (labelrect.TopLeft.X + i), Y: labelrect.BottomLeft.Y }, boolintersectionpoints, sectorFullName)) break;
                i += labelpixellength;
            }
            if (boolintersectionpoints.length > 0) {
                return false;
            }

            if (sectorFullName) {
                let labelPathData = [{ "x": labelrect.TopLeft.X, "y": labelrect.TopLeft.Y }, { "x": labelrect.BottomLeft.X, "y": labelrect.BottomLeft.Y }, { "x": labelrect.BottomRight.X, "y": labelrect.BottomRight.Y }, { "x": labelrect.TopRight.X, "y": labelrect.TopRight.Y }]
                this.labelPoleData = {
                    id: id,
                    labelRect: labelPathData,
                    fontSize: emSize,
                    x: testline1.X1,
                    y: testline1.Y1,
                    dy: formattedText.Height,
                    fill: isLabelHighlighted ? "label-rotation" : "label-rotation-grey",
                    sectorFullName: sectorFullName
                }
            }
        }
        return true;
    };

    addLabel(valueDictionary, sectorFullName, id, isLabelHighlighted) {
        if (StringUtil.isEmpty(valueDictionary))
            return false;

        const { listItems } = this.props;
        const helper = new CompRotationHelper();
        const emSize = helper.fontSizeDictionary[_zoomValue];
        if (sectorFullName != null) {
            if (listItems.length > 500) {
                this.doAddHitTest(valueDictionary, sectorFullName, emSize, ind, 10, id, isLabelHighlighted) //Desktop 100
                return true;
            }
            let ind = 4;
            while (--ind >= 0) {
                if (this.doAddHitTest(valueDictionary, sectorFullName, emSize, (3 - ind), 1, id, isLabelHighlighted)) //Desktop 10
                    break;
            }
        }
        return true;
    };

    renderLabels(pointDictionary, item) {
        try {
            this.setItem(item);
            if (pointDictionary != null) {
                if (!isEmpty(this.item) && this.item.isVisible && !pointDictionary.some((c) => { Math.abs(c.xValue - 0.0) < Number.EPSILON && Math.abs(c.yValue - 0.0) < Number.EPSILON })) {
                    this.labelPoleData = null;
                    const item1 = item;
                    if (this.addLabel(pointDictionary, item1.sectorLabel, item1.id.low, this.item.isLabelHighlighted)) {
                        if (this.labelPoleData) {
                            this.labelPolesData.push(this.labelPoleData);
                            return <TadPoleLabel id={this.labelPoleData.id} key={"label" + this.labelPoleData.id} fontSize={this.labelPoleData.fontSize} x={this.labelPoleData.x} y={this.labelPoleData.y} dy={this.labelPoleData.dy} fill={this.labelPoleData.fill} sectorFullName={UserInfoUtil.hasLocalSymbol() && this.item.localSymbol !== null ? this.item.localSymbol : this.labelPoleData.sectorFullName} />
                        }
                    }
                }
            }
        } catch (error) {
            console.log('renderLabels...', error)
        }

    };

    listItemHighlightSelected = (key, item) => {
        this.props.listItemHighlightSelected(key, item);
    }

    drawLines(valueDictionary, item, lineStrokeThickness = 2.5, isWhite = false) {
        let lineData = null;
        let color = null;

        this.setItem(item);

        let listItem = this.item;
        if (isWhite) {
            color = 'white'
        }
        else {
            if (listItem)
                color = listItem.itemColor;
        }
        if (color == null) {
            return;
        }

        if (listItem && listItem.isVisible) {
            _zoomValue = Number(this.props.zoomValue / 100);
            lineStrokeThickness = _zoomValue > 1.5 ? 3 : 2;
            lineStrokeThickness = Number(lineStrokeThickness / _zoomValue);
            // let lineStrokeWidth = _zoomValue > 1.5 ? 2.5 : 2;
            // let cirCleStrokeWidth = _zoomValue > 1.25 ? "2" : "0";
            const lineClasses = listItem.isHighlighted ? "highlighted-pole" : "unhighlighted-pole";
            const styleLine = { stroke: color, strokeWidth: lineStrokeThickness, strokeLinecap: "square", fill: "none" }

            const styleEllipse = { stroke: color, fill: color }
            if (StringUtil.isEmpty(valueDictionary)) return;

            //let label = null;
            let pathData = "";
            const circle = [];
            const lineVerices = [];


            map(valueDictionary, (itemValue, i) => {
                const x1 = this.props.xScale.computeX(itemValue.xValue);
                const y1 = this.props.scaleHeight - this.props.yScale.computeY(itemValue.yValue);
                const callinestrkthicknss = i == 0 ? 1.8 * lineStrokeThickness : 1.2 * lineStrokeThickness;
                if (i !== valueDictionary.length - 1) {
                    const d = `M ${x1},${y1}
                                m ${-callinestrkthicknss}, 0
                                a ${callinestrkthicknss},${callinestrkthicknss} 0 1,1 ${callinestrkthicknss * 2},0
                                a ${callinestrkthicknss},${callinestrkthicknss} 0 1,1 ${-callinestrkthicknss * 2},0`;
                    circle.push(<path key={i}
                        d={d} style={styleEllipse} />)
                }

                if (i === 0) {
                    pathData += `M${x1},${y1}`;
                }
                else {
                    pathData += ` L${x1},${y1}`;
                }
                lineVerices.push({ "x": x1, "y": y1 });
            });

            lineData = {
                id: item.id.low,
                linePathData: lineVerices
            }
            this.linesData.push(lineData);

            return <TadPole key={item.id.low} listItemHighlightSelected={this.listItemHighlightSelected} lineClasses={lineClasses} listItem={listItem} item={item} onContextMenuClick={this.props.onContextMenuClick}>
                <path key={"path-" + item.id.low} ref={"path-" + item.id.low} d={pathData} style={styleLine} />{circle}</TadPole>
        }
    }

    renderLinesView() {
        this.linesData = [];
        return map(this.props.quadGraphResults.quadGraphItemsData2, (item, i) => {
            if (item.points !== null && item.isVisible) {
                return this.drawLines(item.points, item);
            }
        });

    };

    renderLabelsView() {
        this.labelPolesData = [];
        return map(this.props.quadGraphResults.quadGraphItemsData2, (item, i) => {
            if (item.points != null && item.isVisible) {
                return this.renderLabels(item.points, item, i)
            }
        }).filter((i) => i);

    };

    render() {
        const lines = this.renderLinesView();
        const labels = this.renderLabelsView();

        TimeTrackingWindow.trackChartLoadingTime();

        return <g>{labels}{lines}</g>;
    }
}


class TadPole extends PureComponent {

    listItemHighlightSelected = (e) => {
        const item = this.props.listItem;
        if (item) {
            if (e.ctrlKey || e.metaKey) {
                this.props.listItemHighlightSelected('Ctrl', item);
            }
            else if (!e.shiftKey) {
                this.props.listItemHighlightSelected('Click', item);
            }
        }
    }


    onContextMenuClick = (e) => {
        const item = this.props.listItem;
        const id = this.props.item.id.low;
        if (item && item.isHighlighted) {
            this.props.onContextMenuClick(e, id, item);
            e.stopPropagation();
            e.preventDefault();
        }
    }

    render() {
        return (
            <g className={this.props.lineClasses} onContextMenu={this.onContextMenuClick} onClick={this.listItemHighlightSelected}>
                {this.props.children}
            </g>
        );
    }
}

class TadPoleLabel extends PureComponent {

    render() {
        return (
            <text id={"text-" + this.props.id} fontFamily="Calibri" pointerEvents="none" fontSize={this.props.fontSize} x={this.props.x} y={this.props.y} dy={this.props.dy} className={this.props.fill}>{this.props.sectorFullName}</text>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    if (selectedMenuTab === CompareMenuType.Symbols) {
        const { listItemType, listItems, isHighLighted } = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer.RotationMenuStates;
        return { listItemType, listItems, isHighLighted, ...ownProps };
    }
    else {
        const { listItemType, listItems, isHighLighted } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.RotationMenuStates;

        return { listItemType, listItems, isHighLighted, ...ownProps };
    }
}

const mapDispatchToProps = (dispatch) => ({
    listItemHighlightSelected: (key, item) => dispatch(listItemHighlightSelected(key, item))
});

export default connect(mapStateToProps, mapDispatchToProps)(TadPoleContainer);