import PropTypes from 'prop-types';
import React from 'react';
import ClickMenu from "ClickMenu";
import ThemeHelper from "ThemeHelper";

export default class MultiplierMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            priceMenu: props.priceMenu,
            show: false,
            visibility: 'collapse',
            defaultCursor: props.defaultCursor,
            shouldDisplayMenu: props.shouldDisplayMenu ? true : false
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            label: nextProps.label,
            priceMenu: nextProps.priceMenu,
            show: nextProps.show,
            menuTitle: nextProps.menuTitle,
            defaultCursor: nextProps.defaultCursor,
            shouldDisplayMenu: nextProps.shouldDisplayMenu
        });
    }

    handleClickMenu(item) {
        this.props.onClick(item);
    }

    render() {
        const useCursor = this.state.defaultCursor ? 'default' : 'pointer'
        return (
                <div className="chart-multiple-custom-dropdown" id="symbol-multiplier-custom-dropdown">
                    {this.state.shouldDisplayMenu && <ClickMenu data-disable-track-price="true"
                        menuTitle={this.state.menuTitle}
                        items={this.state.priceMenu}
                        button="left"
                        alignment="left"
                        onselect={(item) => this.handleClickMenu(item)}
                    />}
                <div className='blue-txt' id='multiplier' key='3' style={{ visibility: this.state.show, cursor: useCursor}} data-disable-track-price="true">
                        {this.state.label}
                    </div>
                </div >
            );
    }
}
MultiplierMenu.proptypes = {
    label: PropTypes.string.isRequired,
    priceMenu: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    show: PropTypes.bool,
    onSelect: PropTypes.func,
    menuTitle: PropTypes.string
};