class WPFCanvasUtil{
    constructor() {}

    convertToSvg(data, color){
        var node = getCanvasNode(data);
        if (node) {
            calculateNodeSize(node);
            return convertNodeToSvg(node, true, color);
        }
        else {
            return;
        }
    }
    
    setScale(canvas, ctx, width, height) {
        let devicePixelRatio = window.devicePixelRatio || 1,
        backingStoreRatio = ctx.webkitBackingStorePixelRatio ||
                        ctx.mozBackingStorePixelRatio ||
                        ctx.msBackingStorePixelRatio ||
                        ctx.oBackingStorePixelRatio ||
                        ctx.backingStorePixelRatio || 0.9,

        ratio = devicePixelRatio / backingStoreRatio;

        if (devicePixelRatio !== backingStoreRatio)
        {
            canvas.width = width * ratio;
            canvas.height = height * ratio;

            canvas.style.width = width + 'px';
            canvas.style.height = height + 'px';
            ctx.scale(ratio, ratio);
        }
    }
}

function calculateNodeSize(node){
    let width = Number(node.width || 0);
    let height = Number(node.height || 0);

    if(node.nodes){
        for(let n of node.nodes){
            let size = calculateNodeSize(n);
            width = Math.max(size.width + Number(n.x || 0), width, Number(n.actualwidth || 0));
            height = Math.max(size.height + Number(n.y || 0), height, Number(n.actualheight || 0));
        }
    }
    node.width = width;
    node.height = height;
    return { width: width, height: height };
}

function convertNodeToSvg(node, isRoot, color){
    var result = "";
    if(node.tagName === "canvas"){
        if(isRoot){
            result = `<svg${getPropText(node, "height") + getPropText(node, "width")} xmlns="http://www.w3.org/2000/svg" fill="${color}">`;
        }
        else{
            result = `<svg${getPropText(node, "height") + getPropText(node, "width") + getPropText(node, "x") + getPropText(node, "y")}>`;
        }
        if(node.nodes){
            for(let n of node.nodes){
                result += convertNodeToSvg(n);
            }
        }
        result += "</svg>";
        return result;
    }
    if(node.tagName === "path"){
        result = `<g transform="translate(${node["x"]}, ${node["y"]})"${getPropText(node, "height") + getPropText(node, "width")}>`;
        result += `<path${getPropText(node, "d")}></path>`;
        result += "</g>";
        return result;
    }
    return "";
}

function getPropText(node, prop, newProp){
    newProp = newProp || prop;
    if(node[prop])
        return ` ${newProp}="${node[prop]}"`;
    return "";
}

function getCanvasNode(data){
    var reg = /<([^<>]*)>/ig;
    var r;
    var root;
    var nodes = [];
    var tempNode;
    while((r = reg.exec(data)) != null){
        var props = getProps(r[1]);
        var node = { tagName: getTagName(r[1]).toLowerCase() };
        if(node.tagName[0] === "/"){
            nodes.pop();
            tempNode = nodes[nodes.length - 1];
            continue;
        }
        
        node.width = props["width"];
        node.height = props["height"];
        node.x = props["canvas.left"];
        node.y = props["canvas.top"];
        if(node.tagName === 'path'){
            node.d = props["data"].replace("F1", "");
            if(!!props["stretch"]){
                // fill, need to caculate the x, y
                var size = getActualSize(node.d);
                // node.scalex = node.width / size.width;
                // node.scaley = node.height / size.height;
                node.x = node.x - size.minW;
                node.y = node.y - size.minH;
                node.actualwidth = size.maxW + node.x;
                node.actualheight = size.maxH + node.y;
            }
        }

        if(!root){
            root = node;
        }

        if(tempNode){
            tempNode.nodes = tempNode.nodes || [];
            tempNode.nodes.push(node);
        }

        if(r[1][r[1].length - 1] !== '/'){
            nodes.push(node);
            tempNode = node;
        }
    }
    return root;
}

function getActualSize(data){
    var reg = /(\d*.?\d*),(\d*.?\d*)/ig;
    var r;
    var minW = 99999;
    var minH = 99999;
    var maxW = 0;
    var maxH = 0;
    while((r = reg.exec(data)) != null){
        minW = Math.min(r[1], minW);
        minH = Math.min(r[2], minH);
        maxW = Math.max(r[1], maxW);
        maxH = Math.max(r[2], maxH);
    }
    return { minW: minW, minH: minH, maxW: maxW, maxH: maxH };
}

function getTagName(text){
    var reg = /^(\S*)/ig;
    var r = reg.exec(text);
    if(r != null)
        return r[1];
    return "";
}

function getProps(text){
    var result = {};
    var reg = /(\S*)=['"]?([^'"]+)['"]?/ig;
    var r;
    while((r = reg.exec(text)) != null){
        result[r[1].toLowerCase()] = r[2];
    }
    return result;
}

const wpfCanvasUtil = new WPFCanvasUtil();
export default wpfCanvasUtil;