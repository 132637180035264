
import { AnnotationConstant } from "../../Constants/AnnotationConstants";
const { ActionTypes } = AnnotationConstant;
import { RectangleInfo } from "../../Models/AnnotationModels/RectangleModelInfo";

const rectangles = []

const rectangleReducer = (state = rectangles, action) => {

    switch (action.type) {
        case ActionTypes.INIT_RECTANGLE_COLLECTION:
            return action.RectangleCollection;
        case ActionTypes.HANDLE_RECTANGLE_CLICK_SUCCESS:
            return [...state, action.rectangle]
        case ActionTypes.HANDLE_RECTANGLE_DRAW_SUCCESS:
            return state.map((item) => (item.id === action.rectangle.id) ? action.rectangle : item)
        case ActionTypes.HANDLE_RECTANGLE_SAVE_ID_SUCCESS:
            return action.rectangles
        // return [...state, action.rectangle]
        case ActionTypes.HANDLE_RECTANGLE_TRANSFORM_SUCCESS:
            return state.map((item) => (item.id === action.rectangle.id) ? action.rectangle : item)
        case ActionTypes.HANDLE_REMOVE_RECTANGLE_DRAW:
            return state.filter((item) => item.id !== action.annotSelectedId)
        case ActionTypes.REMOVE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new RectangleInfo, { ...item, isDeleted: true }) : item)
        case ActionTypes.RESTORE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new RectangleInfo, { ...item, isDeleted: false }) : item)
        case ActionTypes.CLEAR_ANNOTATIONS:
            return [];
        default:
            return state
    }
}

export default rectangleReducer;