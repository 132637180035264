import React, { Component } from "react";
import { connect } from 'react-redux'
import ExternalDataGridView from "../ListManager/ExternalDataGridView";
import ExternalDataUploadView from "../ListManager/ExternalDataUploadView";


class ExternalDataView extends Component {

    constructor(props) {
        super(props);
    }
    componentDidUpdate() {        
        if(this.props.loading || this.props.disableDelete){
            this.props.setLoading(true);
        }
        else{
            this.props.setLoading(false);
        }
     }
     componentWillUnmount() {
        if(!this.props.loading){
        this.props.setLoading(false);
        }          
      }
    render() {
        const isNewList = this.props.isNewList;
        const rowData=this.props.rowSource;
        return (
            <div className="main-list-view medium-normal">
                {this.props.loading ?
                    <span className="grid-loading"><div className="group-box "><div className="loading loading-spinner"></div></div></span>
                    : null}
                {!isNewList && !this.props.loading &&
                    < ExternalDataGridView  fontSizeClass={this.props.fontSizeClass} itemsCount={rowData.length} itemHeight={this.props.itemHeight} />
                }
                {isNewList && !this.props.loading &&
                    < ExternalDataUploadView  />
                }
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({

})
const mapStateToProps = ({ externalDataUploader }) => {
    const { ExternalDataListViewReducer } = externalDataUploader;
    const { source, isNewList, loading,rowSource,disableDelete } = ExternalDataListViewReducer;
    return ({ source, isNewList, loading,rowSource,disableDelete });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataView);
