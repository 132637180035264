import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LiqFilterButton } from "../Presentational";
import { toggleLiqFilter } from '../../../../../Actions/ComparisonActions';

class LiqFilterHeader extends Component {

    openLiquidityRatings = () => {
        if (!this.props.liqFilterData.openLiquidityRatings) {
            this.props.toggleLiqFilter(true);
        }
    }

    render() {
        const { isLiqFilterOn } = this.props.liqFilterData;
        const liqFilterIcnClaass = isLiqFilterOn ? "icn-compear-filter active" : "icn-compear-filter";
        return (
            <LiqFilterButton liqFilterIcnClaass = { liqFilterIcnClaass } onClick = { this.openLiquidityRatings }/>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        liqFilterData: state.comparisonGraph.liqFilter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLiqFilter: (isOpen) => dispatch(toggleLiqFilter(isOpen))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiqFilterHeader);