import Serializable from "Serializable";
import BaseServiceApi from 'BaseServiceApi';
import UserInfoUtil from "../../../../../../Utils/UserInfoUtil";
import { union, difference } from "underscore";

const IdeaType = BaseServiceApi.rayData["IdeaType"];
const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

class RiPanelOneilListSettings extends Serializable {
    constructor() {
        super();
        this.EntitledIdeaTypes = this.getDefaultRIPanelIdealistTypesSettings();
        this.AllEntitlements = this.getDefaultRIPanelIdealistTypesSettings();
        this.notSelectedEntitlements = [];
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.EntitledIdeaTypes = serializedValue.EntitledIdeaTypes ? serializedValue.EntitledIdeaTypes : this.getDefaultRIPanelIdealistTypesSettings();
        this.notSelectedEntitlements = serializedValue.notSelectedEntitlements ? serializedValue.notSelectedEntitlements : [];

        this.AllEntitlements = this.getDefaultRIPanelIdealistTypesSettings();
        if (this.EntitledIdeaTypes && this.EntitledIdeaTypes.length !== this.AllEntitlements.length) {
            const newEntitlements = difference(this.AllEntitlements, union(this.EntitledIdeaTypes, this.notSelectedEntitlements));
            if (newEntitlements.length > 0) {
                this.EntitledIdeaTypes = union(this.EntitledIdeaTypes, newEntitlements);
            }

            const removedEntitlements = difference(union(this.EntitledIdeaTypes, this.notSelectedEntitlements), this.AllEntitlements);
            if (removedEntitlements.length > 0) {
                this.EntitledIdeaTypes = this.EntitledIdeaTypes.filter((ent) => !removedEntitlements.includes(ent));
                this.notSelectedEntitlements = this.notSelectedEntitlements.filter((ent) => !removedEntitlements.includes(ent));
            }
        }
    }

    getObjectMapKey() {
        return "RiPanelOneilListSettings";
    }

    getDataObject() {
        return {
            EntitledIdeaTypes: this.EntitledIdeaTypes,
            AllEntitlements: this.AllEntitlements,
            notSelectedEntitlements: this.notSelectedEntitlements
        };
    }

    getDefaultRIPanelIdealistTypesSettings() {
        let entitlements = UserInfoUtil.getUserEntitlements();
        let result = [];
        if (entitlements.includes(EntitlementType.IDEAS_USA_LONG_Entitlement)) {
            result.push(IdeaType.USA_LONG_Idea);
        }
        if (entitlements.includes(EntitlementType.IDEAS_USA_SHORT_Entitlement)) {
            result.push(IdeaType.USA_SHORT_Idea);
        }
        /** This is has been discontinued */
        // if (entitlements.includes(EntitlementType.IDEAS_ALLCAPS_LEADER_Entitlement)) {
        //     result.push(IdeaType.ALLCAPS_LEADER_Idea);
        // }
        // if (entitlements.includes(EntitlementType.IDEAS_HISTORY_ALLCAPS_LAGGARD_Entitlement)) {
        //     result.push(IdeaType.ALLCAPS_LAGGARD_Idea);
        // }
        // if (entitlements.includes(EntitlementType.IDEAS_LARGECAP_LAGGARD_Entitlement)) {
        //     result.push(IdeaType.LARGECAP_LAGGARD_Idea);
        // }
        // if (entitlements.includes(EntitlementType.IDEAS_LARGECAP_LEADER_Entitlement)) {
        //     result.push(IdeaType.LARGECAP_LEADER_Idea);
        // }
        if (entitlements.includes(EntitlementType.IDEAS_DEVELOPED_LONG_Entitlement)) {
            result.push(IdeaType.DEVELOPED_LONG_Idea);
        }
        if (entitlements.includes(EntitlementType.IDEAS_DEVELOPED_SHORT_Entitlement)) {
            result.push(IdeaType.DEVELOPED_SHORT_Idea);
        }
        if (entitlements.includes(EntitlementType.IDEAS_EMERGING_LONG_Entitlement)) {
            result.push(IdeaType.EMERGING_LONG_Idea);
        }
        if (entitlements.includes(EntitlementType.IDEAS_EMERGING_SHORT_Entitlement)) {
            result.push(IdeaType.EMERGING_SHORT_Idea);
        }
        if (entitlements.includes(EntitlementType.IDEAS_FRONTIER_LONG_Entitlement)) {
            result.push(IdeaType.FRONTIER_LONG_Idea);
        }
        if (entitlements.includes(EntitlementType.IDEAS_FRONTIER_SHORT_Entitlement)) {
            result.push(IdeaType.FRONTIER_SHORT_Idea);
        }
        if (entitlements.includes(EntitlementType.GFL_EUROPE_LONG_Entitlement)) {
            result.push(IdeaType.GFL_EUROPE_LONG_Idea);
        }
        if (entitlements.includes(EntitlementType.GFL_EUROPE_SHORT_Entitlement)) {
            result.push(IdeaType.GFL_EUROPE_SHORT_Idea);
        }
        return result;
    }

    getIdeaTypes() {
        return this.EntitledIdeaTypes;
    }

    setIdeaTypes(data) {
        this.EntitledIdeaTypes = [];
        if (!data) return;
        for (let item of data) {
            this.EntitledIdeaTypes.push(item);
        }
    }

    setEntitlementList(entitlementList) {
        this.EntitledIdeaTypes = [], this.notSelectedEntitlements = [];
        if (entitlementList) {
            for (let item of entitlementList) {
                if (item.IsCheckedLeaderFlag) {
                    this.EntitledIdeaTypes.push(item.LeaderIdeaType);
                }
                if (item.IsCheckedLaggardFlag) {
                    this.EntitledIdeaTypes.push(item.LaggardIdeaType);
                }
            }

            this.notSelectedEntitlements = difference(this.AllEntitlements, this.EntitledIdeaTypes)
        }
    }

}

export default RiPanelOneilListSettings;
