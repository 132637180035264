import IndexLineSettings from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/IndexLineSettings.js";
import { IndexNameTranslateHelper } from "../../../../Utils/TranslateHelper.js";
import IndustryTypeConstants from "../../../../Constants/IndustryTypeConstants.js";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore.js";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";


const { ActionTypes } = PriceChartConstants;
const dialogIntialState = {
    regionCountries: {},
    countries: [],
    selectedCountry: '',
    countryCode: 1,
    isOriginalCountryCodeIso: true,
    regions: [],
    selectedRegion: '',
    countryIndex: '0S&P5',
    IndexName: IndexNameTranslateHelper[IndustryTypeConstants.S_P_500_Index],
    industryGroups: [],
    selectedGroup: '',
    symbolName: '',
    valueType: 'default',
    isIndexLineDialogOpen: false,
    rsLineHeaderText: LocalizationStore.getTranslatedData("ch_rs_line", "RS Line"),
    country: '',
    lineColor: "P34FF33006600",
    lineThickness: 1,
    hasSymbolError: false,
    isSymbolGroup: false,
    isSymbolIndex: false,
    isFund: false,
    isUpdateDialog: true,
    showAlert: false,
    errorMessage: "Error: Only Numbers Allowed",
}
const initialState = {
    defaultValueType: "default",
    indexText: '',
    indexLineSettings: new IndexLineSettings(true),
    groups: [],
    ...dialogIntialState
}
const IndexLineReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.INDEX_LINE_DATA_READY:
            return {
                ...state,
                indexLineSettings: action.indexLineSettingsByPeriodicity,
                indexLineData: action.indexLineData,
                indexText: action.indexText,
                nodeWidth: action.nodeWidth,
                periodicity: action.periodicity,
                groups: action.groups,
            };
        case ActionTypes.INDEX_LINE_SETTINGS_UPDATE:
            return {
                ...state,
                indexLineSettings: action.indexSettingsByPeriodicity
            }
        case ActionTypes.CLEAR_INDEX_LINE:
            return{
                ...initialState
            }
        case ActionTypes.UPDATE_INDEXLINE_DIALOG_STATES:
            state.isIndexLineDialogOpen = action.isIndexLineDialogOpen;
            state.regionCountries = action.regionCountries;
            state.regions = action.regions;
            state.selectedRegion = action.selectedRegion;
            state.countries = action.countries;
            state.selectedCountry = action.selectedCountry;
            state.selectedRSLine = action.selectedRSLine;
            state.countryIndex = action.countryIndex;
            state.lineColor = action.lineColor;
            state.lineThickness = action.lineThickness;
            state.selectedGroup = action.selectedGroup;
            state.symbolName = action.symbolName;
            state.valueType = action.valueType;
            state.isSymbolGroup = action.isSymbolGroup;
            state.isSymbolIndex = action.isSymbolIndex;
            state.isFund = action.isFund;
            state.IndexName = action.IndexName;
            state.countryCode = action.countryCode;
            return { ...state };
        case ActionTypes.CANCEL_INDEX_DIALOG_SETTING:
            return{ ...state, ...dialogIntialState, showAlert: false};
        case ActionTypes.UPDATE_INDEX_DIALOG_SHOW_ALERT:
            state.showAlert = true
            return{ ...state,};
        case ActionTypes.HANDLE_INDEX_DIALOG_REGION_CHANGE:
            state.selectedRegion = action.selectedRegion;
            state.countries = state.regionCountries[state.selectedRegion]
            state.selectedCountry = state.countries[0].name;
            state.IndexName = state.countries[0].indexName;
            state.countryCode = state.countries[0].countryCode;
            state.hasSymbolError = false;
            state.valueType = "default";
            state.symbolName = '';
            state.countryIndex = state.countries[0].indexSymbol;
            state.isOriginalCountryCodeIso = state.countries[0].countryCode === state.countryCode;
            return{ ...state};
        case ActionTypes.HANDLE_INDEX_DIALOG_COUNTRY_CHANGE:
            state.selectedCountry = action.selectedCountry;
            state.valueType = "default";
            state.hasSymbolError = false;
            state.symbolName = '';
            state.regionCountries[state.selectedRegion].map((item)=>{
                if(item.name === state.selectedCountry){
                    state.countryIndex = item.indexSymbol;
                    state.IndexName = item.indexName;
                    state.countryCode = item.countryCode;
                    state.isOriginalCountryCodeIso = item.countryCode === state.countryCode;
                    return -1;
                }
                return item;
            })
            return{ ...state};
        case ActionTypes.HANDLE_INDEX_DIALOG_GROUP_CHANGE:
            state.selectedGroup = action.selectedGroup
            return { ...state };
        case ActionTypes.HANDLE_INDEX_VALUE_TYPE_CHANGE:
            state.symbolName = '';
            if(action.valueType !=='symbol')
            {
                state.hasSymbolError = false;
            }
            state.valueType = action.valueType;
            return { ...state };
        case ActionTypes.HANDLE_INDEX_SYMBOL_CHANGE:
            state.symbolName = action.symbolName;
            return { ...state };
        case ActionTypes.UPDATE_INDEX_LINE_COLOR:
            state.hasSymbolError = false;
            state.lineColor = action.lineColor
            return { ...state };
        case ActionTypes.UPDATE_INDEX_LINE_THICKNESS:
            state.hasSymbolError = false;
            state.lineThickness = action.lineThickness
            return { ...state };
        case ActionTypes.UPDATE_INDEX_SYMBOL_ERROR:
            state.hasSymbolError = true;
            state.errorMessage = action.errorMessage;
            state.symbolName = '';
            return { ...state };
        case ActionTypes.UPDATE_SYMBOL_ERROR:
            state.hasSymbolError = false;
            return { ...state };
        default:
            return state;
    }

}
export default IndexLineReducer;