import React from "react";

export default class BottomBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span>
        <div className="view-grid-system"></div>
      </span>);
  }
}