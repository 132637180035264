import AddAnchor from '../Presentational/CustomTransformerControl.jsx';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { Line, Group, Image } from 'react-konva';
import React, { Component } from 'react'

class ChannelLineControl extends Component {

    handleAnchorDragEnd = () => {
        if (this.props.activeAnchor !== '') {
            this.props.handleAnchorDragEnd();
        }
    }

    render() {
        const { isDragging, selectedAnnotId, index, draggable, lockImage, isApiReqResolved } = this.props;
        const { id, color, stroke, weight, opacity, x, y, startMouseX, startMouseY, currentMouseX, currentMouseY,
            endMouseX, endMouseY, midX, midY, spreadMiddle, startExtendPoint, endExtendPoint, startExtendChecked,
            endExtendChecked, } = this.props.channelLine;
        const channelLineColor = ThemeHelper.getThemedBrush(color);
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            <Group>
                {/* normal line from exact cursor start and end position */}
                <Line
                    id={id}
                    shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                    index={index}
                    x={x}
                    y={y}
                    points={[startMouseX, startMouseY, currentMouseX, currentMouseY]}
                    stroke={channelLineColor}
                    strokeWidth={weight}
                    opacity={opacity}
                    dash={stroke}
                    draggable={draggable && isApiReqResolved}
                    onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                />

                {/* shadow line - which activates the cursor upon reaching the shadow point. Shadow is required only for thin lines (ex: width < 6)  */}
                {weight < 6 &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        points={[startMouseX, startMouseY, currentMouseX, currentMouseY]}
                        stroke={channelLineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* spread line */}
                <Line
                    id={id}
                    shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                    index={index}
                    x={x}
                    y={y}
                    points={[startMouseX, startMouseY - spreadMiddle, currentMouseX, currentMouseY - spreadMiddle]}
                    stroke={channelLineColor}
                    strokeWidth={weight}
                    opacity={opacity}
                    dash={stroke}
                    draggable={draggable && isApiReqResolved}
                    onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                />

                {/* shadow for spread line */}
                {weight < 6 &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        points={[startMouseX, startMouseY - spreadMiddle, currentMouseX, currentMouseY - spreadMiddle]}
                        stroke={channelLineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        offsetX={2}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* Satrt Extend 1st line */}
                {startExtendChecked && startExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        points={[startMouseX, startMouseY, startExtendPoint.x - x, startExtendPoint.y - y]}
                        stroke={channelLineColor}
                        strokeWidth={weight}
                        opacity={opacity}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* Satrt Extend spread line */}
                {startExtendChecked && startExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        points={[startMouseX, startMouseY - spreadMiddle, startExtendPoint.x - x, startExtendPoint.y - y - spreadMiddle]}
                        stroke={channelLineColor}
                        strokeWidth={weight}
                        opacity={opacity}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* Shadow for start extend */}
                {weight < 6 && startExtendChecked && startExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        points={[startMouseX, startMouseY, startExtendPoint.x - x, startExtendPoint.y - y]}
                        stroke={channelLineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* Shadow for start extend spread line */}
                {weight < 6 && startExtendChecked && startExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={x}
                        y={y}
                        points={[startMouseX, startMouseY - spreadMiddle, startExtendPoint.x - x, startExtendPoint.y - y - spreadMiddle]}
                        stroke={channelLineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* End extend */}
                {endExtendChecked && endExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={endMouseX}
                        y={endMouseY}
                        points={[startMouseX, startMouseY, endExtendPoint.x - endMouseX, endExtendPoint.y - endMouseY]}
                        stroke={channelLineColor}
                        strokeWidth={weight}
                        opacity={opacity}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e, true)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* Shadow for End extend */}
                {weight < 6 && endExtendChecked && endExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={endMouseX}
                        y={endMouseY}
                        points={[startMouseX, startMouseY, endExtendPoint.x - endMouseX, endExtendPoint.y - endMouseY]}
                        stroke={channelLineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e, true)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* End extend spread line */}
                {endExtendChecked && endExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={endMouseX}
                        y={endMouseY}
                        points={[startMouseX, startMouseY - spreadMiddle, endExtendPoint.x - endMouseX, endExtendPoint.y - endMouseY - spreadMiddle]}
                        stroke={channelLineColor}
                        strokeWidth={weight}
                        opacity={opacity}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e, true)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {/* Shadow for End extend spread line */}
                {endExtendChecked && endExtendPoint &&
                    <Line
                        id={id}
                        shape={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                        index={index}
                        x={endMouseX}
                        y={endMouseY}
                        points={[startMouseX, startMouseY - spreadMiddle, endExtendPoint.x - endMouseX, endExtendPoint.y - endMouseY - spreadMiddle]}
                        stroke={channelLineColor}
                        strokeWidth={6}
                        opacity={0}
                        dash={stroke}
                        draggable={draggable && isApiReqResolved}
                        onDragStart={() => this.props.handleDragStart(this.props.channelLine)}
                        onDragMove={(e) => this.props.handleDragMove(e, true)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.PARALLEL_LINES_AnnotationType) : undefined}
                    />}

                {selectedAnnotId.includes(id) && !isDragging &&
                    <>
                        <AddAnchor x={x} y={y} annotSelected={id}
                            handleAnchorDragStart={this.props.handleAnchorDragStart} type={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'start'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={x - 3} y={y - 3} />}
                        <AddAnchor x={midX} y={midY} annotSelected={id} type={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                            handleAnchorDragStart={this.props.handleAnchorDragStart}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'mid'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={midX - 3} y={midY - 3} />}
                        <AddAnchor x={endMouseX} y={endMouseY} annotSelected={id} type={RayAnnotationType.PARALLEL_LINES_AnnotationType}
                            handleAnchorDragStart={this.props.handleAnchorDragStart}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'end'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - 3} y={endMouseY - 3} />}
                    </>
                }
            </Group>
        );
    }
}

export default ChannelLineControl;