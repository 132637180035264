import AppDispatcher from "AppDispatcher";
import { MenuDialogConstants } from "../../Constants/MenuDialogConstants.js";
import { EventEmitter } from "events";
import LocalizationStore from "LocalizationStore";

const CHANGE_EVENT = "change";

let _states = {
    url: "",
    isOpen: false,
};
class WhatsNewStore extends EventEmitter{
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getState() {
        return _states;
    }

    setDialogState(isOpen) {
        _states.isOpen = isOpen;
    }

    getServerName() {
        let serverName;
        if (process.env.BRANCH_ENV) {
            let env = process.env.BRANCH_ENV;
            switch (env && env.toUpperCase()) {
                case "DEV":
                    serverName = "dev.panaray.com";
                    break;
                case "STAGE":
                    serverName = "stage.panaray.com";
                    break;
                case "PROD":
                    serverName = "panaray.com";
                    break;
                default:
                    break;
            }
        }
        return serverName == null ? serverName = "panaray.com" : serverName;
    }

    dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.data;
        switch (action.actionType) {
            case MenuDialogConstants.ActionTypes.OPEN_WHATS_NEW_DIALOG:
                const version = LocalizationStore.getTranslatedData("_Version", "").replace(/[^\d.]/g,"");
                let result = [];
                result = version.split(".");
                let serverName = "";
                serverName = this.getServerName();
                let pdfURL = "https://{0}/Assets/WhatsNew_Web-{1}.{2}.pdf";
                _states.url = pdfURL.replace("{0}",serverName).replace("{1}", result[0]).replace("{2}", result[1]);
                _states.isOpen = true;
                this.emit(CHANGE_EVENT);
                break;
            default:
                break;
        }
    }
}

const whatsNewStore = new WhatsNewStore();
export default whatsNewStore;
