import DefaultDataGraphSetting from "./DefaultDataGraphSettings.js";
import Serializable from "../../../../../Serialization/Serializable.js";
import SmartViewType from "SmartViewType";
export default class StockViewSettings extends Serializable {

  onSeralisedInfoRecieved(info) {
    this.SummaryBlockSettings = info.SummaryBlockSettings ? info.SummaryBlockSettings : DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    this.FinancialBlockSettings = info.FinancialBlockSettings ? info.FinancialBlockSettings : DefaultDataGraphSetting.getDefaultFinancialBlockSetting();
    this.OwnershipBlockSettings = info.OwnershipBlockSettings ? info.OwnershipBlockSettings : DefaultDataGraphSetting.getDefaultOwnershipBlockSettings();
    this.VolumeBlockSettings = info.VolumeBlockSettings ? info.VolumeBlockSettings : DefaultDataGraphSetting.getDefaultVolumeBlockSettings(false);
    this.InsiderBlockSettings = info.InsiderBlockSettings ? info.InsiderBlockSettings : DefaultDataGraphSetting.getDefaultInsiderBlockSettings();
    this.EarningLineSetting = info.EarningLineSetting ? info.EarningLineSetting : DefaultDataGraphSetting.getEstimateSettings(true);
    this.RevenueLineSetting = info.RevenueLineSetting ? info.RevenueLineSetting : DefaultDataGraphSetting.getEstimateSettings(false);
    this.T4Q0LineSetting = info.T4Q0LineSetting ? info.T4Q0LineSetting : DefaultDataGraphSetting.getEstimateSettings(false, 0);
    this.T4Q1LineSetting = info.T4Q1LineSetting ? info.T4Q1LineSetting : DefaultDataGraphSetting.getEstimateSettings(false, 1);
    this.T4Q2LineSetting = info.T4Q2LineSetting ? info.T4Q2LineSetting : DefaultDataGraphSetting.getEstimateSettings(false, 2);
    this.T4Q3LineSetting = info.T4Q3LineSetting ? info.T4Q3LineSetting : DefaultDataGraphSetting.getEstimateSettings(false, 3);
    this.RsiChartSettings = info.RsiChartSettings ? info.RsiChartSettings : DefaultDataGraphSetting.getRSiChartSetting();
    this.TechChartSettings = info.TechChartSettings ? info.TechChartSettings : DefaultDataGraphSetting.getTechChartSetting();
    this.StochasticsChartSettings = info.StochasticsChartSettings ? info.StochasticsChartSettings : DefaultDataGraphSetting.getStochasticsChartSetting();
    this.WonStochasticsChartSettings = info.WonStochasticsChartSettings ? info.WonStochasticsChartSettings : DefaultDataGraphSetting.getWonStochasticsChartSetting();
    this.WonStochasticsUsageSettings = info.WonStochasticsUsageSettings ? info.WonStochasticsUsageSettings : DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    this.MacdChartSettings = info.MacdChartSettings ? info.MacdChartSettings : DefaultDataGraphSetting.getMacdChartSetting();
    this.AccDistChartSettings = info.AccDistChartSettings ? info.AccDistChartSettings : DefaultDataGraphSetting.getAccDistChartSetting();
    this.PTOEChartSettings = info.PTOEChartSettings ? info.PTOEChartSettings : DefaultDataGraphSetting.getPTOEChartSetting();
    this.PTOSChartSettings = info.PTOSChartSettings ? info.PTOSChartSettings : DefaultDataGraphSetting.getPTOSChartSetting();
    this.EPSRChartSettings = info.EPSRChartSettings ? info.EPSRChartSettings : DefaultDataGraphSetting.getEPSRChartSetting();
    this.ScaleLabelSettings = info.ScaleLabelSettings ? info.ScaleLabelSettings : DefaultDataGraphSetting.getScaleLabelSettings();
    this.EPSLabelSettings = info.EPSLabelSettings ? info.EPSLabelSettings : DefaultDataGraphSetting.getEPSLabelSettings();
    this.CorporateEventsSettings = info.CorporateEventsSettings ? info.CorporateEventsSettings : DefaultDataGraphSetting.getCorporateEventsSetting(false);
    this.PatternRecognitionSettings = info.PatternRecognitionSettings ? info.PatternRecognitionSettings : DefaultDataGraphSetting.getPatternRecognitionSetting();
    this.FactorResearchSettings = info.FactorResearchSettings? info.FactorResearchSettings: DefaultDataGraphSetting.getFactorResearchSetting();
    this.HiLoPriceSettings = info.HiLoPriceSettings ? info.HiLoPriceSettings : DefaultDataGraphSetting.getHiLoPriceSetting();
    this.HiLoPctSettings = info.HiLoPctSettings ? info.HiLoPctSettings : DefaultDataGraphSetting.getHiLoPctSetting();
    this.RelativeStrenghtLine1Settings = info.RelativeStrenghtLine1Settings && info.RelativeStrenghtLine1Settings[1] ? info.RelativeStrenghtLine1Settings : DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1);
    this.RelativeStrenghtLine2Settings = info.RelativeStrenghtLine2Settings && info.RelativeStrenghtLine2Settings[1] ? info.RelativeStrenghtLine2Settings : DefaultDataGraphSetting.getRelativeStrenghtLineSetting(2);
    this.RelativeStrenghtLine1SettingsByPeriodicity = info.RelativeStrenghtLine1SettingsByPeriodicity ? info.RelativeStrenghtLine1SettingsByPeriodicity : DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1);
    this.RelativeStrenghtLine2SettingsByPeriodicity = info.RelativeStrenghtLine2SettingsByPeriodicity ? info.RelativeStrenghtLine2SettingsByPeriodicity : DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(2);
    this.IndexLineSettings = info.IndexLineSettings && info.IndexLineSettings[1] ? info.IndexLineSettings : DefaultDataGraphSetting.getIndexLineSetting();
    this.IndexLineSettingsByPeriodicity = info.IndexLineSettingsByPeriodicity ? info.IndexLineSettingsByPeriodicity : DefaultDataGraphSetting.getIndexLineSettingByPeriodicity();
    this.EPSMultiplierSettings = info.EPSMultiplierSettings ? info.EPSMultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting();
    this.RPSMultiplierSettings = info.RPSMultiplierSettings ? info.RPSMultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting();
    this.T4Q0MultiplierSettings = info.T4Q0MultiplierSettings ? info.T4Q0MultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.T4Q1MultiplierSettings = info.T4Q1MultiplierSettings ? info.T4Q1MultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.T4Q2MultiplierSettings = info.T4Q2MultiplierSettings ? info.T4Q2MultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.T4Q3MultiplierSettings = info.T4Q3MultiplierSettings ? info.T4Q3MultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.EPSAnimationSettings = info.EPSAnimationSettings ? info.EPSAnimationSettings : DefaultDataGraphSetting.getEPSAnimationSetting();
    this.EDMultiplierSettings = info.EDMultiplierSettings ? info.EDMultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.MASettings = info.MASettings ? info.MASettings : DefaultDataGraphSetting.getMovingAverageSetting();
    this.LLRSettings = info.LLRSettings ? info.LLRSettings : DefaultDataGraphSetting.getLLRSetting();
    this.TiMenuSettings = info.TiMenuSettings ? info.TiMenuSettings : DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.STOCK);
    this.StreetTargetPriceSettings = info.StreetTargetPriceSettings? info.StreetTargetPriceSettings : DefaultDataGraphSetting.getStreetTargetPriceSetting();
    this.OffHighTag = info.OffHighTag ? info.OffHighTag : "OH";
    this.isGICS = info.isGICS ? info.isGICS : false;
    this.KpiSettings = info.KpiSettings ? info.KpiSettings : DefaultDataGraphSetting.getKpiSetting();
    this.ExternalDataSettings =  info.ExternalDataSettings ? info.ExternalDataSettings : DefaultDataGraphSetting.getExternalDataSettings();
    this.ExternalDataRiPanelSettings = info.ExternalDataRiPanelSettings ? info.ExternalDataRiPanelSettings : DefaultDataGraphSetting.getExternalDataRiPanelSettings();
  }
  getDataObject() {
    return {
      VolumeBlockSettings: this.VolumeBlockSettings,
      SummaryBlockSettings: this.SummaryBlockSettings,
      RsiChartSettings: this.RsiChartSettings,
      TechChartSettings: this.TechChartSettings,
      FinancialBlockSettings: this.FinancialBlockSettings,
      OwnershipBlockSettings: this.OwnershipBlockSettings,
      InsiderBlockSettings: this.InsiderBlockSettings,
      EarningLineSetting: this.EarningLineSetting,
      StochasticsChartSettings: this.StochasticsChartSettings,
      WonStochasticsChartSettings: this.WonStochasticsChartSettings,
      WonStochasticsUsageSettings: this.WonStochasticsUsageSettings,
      MacdChartSettings: this.MacdChartSettings,
      AccDistChartSettings: this.AccDistChartSettings,
      PTOEChartSettings: this.PTOEChartSettings,
      PTOSChartSettings: this.PTOSChartSettings,
      EPSRChartSettings: this.EPSRChartSettings,
      ScaleLabelSettings: this.ScaleLabelSettings,
      EPSLabelSettings: this.EPSLabelSettings,
      CorporateEventsSettings: this.CorporateEventsSettings,
      PatternRecognitionSettings: this.PatternRecognitionSettings,
      FactorResearchSettings: this.FactorResearchSettings,
      HiLoPriceSettings: this.HiLoPriceSettings,
      HiLoPctSettings: this.HiLoPctSettings,
      RevenueLineSetting: this.RevenueLineSetting,
      T4Q0LineSetting: this.T4Q0LineSetting,
      T4Q1LineSetting: this.T4Q1LineSetting,
      T4Q2LineSetting: this.T4Q2LineSetting,
      T4Q3LineSetting: this.T4Q3LineSetting,
      IndexLineSettings: this.IndexLineSettings,
      IndexLineSettingsByPeriodicity: this.IndexLineSettingsByPeriodicity,
      LLRSettings: this.LLRSettings,
      EPSMultiplierSettings: this.EPSMultiplierSettings,
      RPSMultiplierSettings: this.RPSMultiplierSettings,
      T4Q0MultiplierSettings: this.T4Q0MultiplierSettings,
      T4Q1MultiplierSettings: this.T4Q1MultiplierSettings,
      T4Q2MultiplierSettings: this.T4Q2MultiplierSettings,
      T4Q3MultiplierSettings: this.T4Q3MultiplierSettings,
      RelativeStrenghtLine1Settings: this.RelativeStrenghtLine1Settings,
      RelativeStrenghtLine2Settings: this.RelativeStrenghtLine2Settings,
      RelativeStrenghtLine1SettingsByPeriodicity: this.RelativeStrenghtLine1SettingsByPeriodicity,
      RelativeStrenghtLine2SettingsByPeriodicity: this.RelativeStrenghtLine2SettingsByPeriodicity,
      EPSAnimationSettings: this.EPSAnimationSettings,
      MASettings: this.MASettings,
      TiMenuSettings: this.TiMenuSettings,
      OffHighTag: this.OffHighTag,
      isGICS: this.isGICS,
      KpiSettings: this.KpiSettings,
      ExternalDataSettings: this.ExternalDataSettings,
      ExternalDataRiPanelSettings: this.ExternalDataRiPanelSettings,
      StreetTargetPriceSettings: this.StreetTargetPriceSettings,
    };
  }

  getObjectMapKey() {
    return "StockViewSettings";
  }

  getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness) {
    return DefaultDataGraphSetting.getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness);
  }
  getDefaultDataGraphSetting() {
    return DefaultDataGraphSetting.getCorporateEventsSetting(false);
  }
  getDefaultEstimateSettings(isEPS, t4q = -1) {
    return DefaultDataGraphSetting.getEstimateSettings(isEPS, t4q);
  }
  getdefaultEPSAnimationSetting() {
    return DefaultDataGraphSetting.getEPSAnimationSetting();
  }
  getDefaultStreetTargetPriceSettings(){
    return DefaultDataGraphSetting.getStreetTargetPriceSetting();
  }
}
