import Serializable from "Serializable";
import LangType from "../../../../../Constants/LangType";
import ThemeType from "../../../../../Constants/ThemeTypeConstants";
import * as ColorConstants from "../../../../../Constants/AppColorConstants";
import AppColorSettingHelper from "./AppColorSettingHelper";
import UserInfoUtil from "UserInfoUtil";
import SettingsStore from "SettingsStore";

class AppColorSettings extends Serializable{
    isVideoMode() {
        if (!SettingsStore) {
            return false;
        }
        const consoleSettings = SettingsStore.getConsoleSettings();
        if (!consoleSettings) {
            return false;
        }
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        return tabDataGraphSettings.videoMode;
    }
    constructor(){
        super();
        const videoMode = this.isVideoMode();

        //Show color settings on basis of entitlement other than the displaying language.
        const lang = UserInfoUtil.hasChinaEntitlement() ? LangType.ZH_CN : LangType.RAY_DEFAULT;
        const themeType = videoMode ? ThemeType.Video : ThemeType.Dark;

        this.lightPosColor = AppColorSettingHelper.getDefaultPosColor(false,lang,ColorConstants.ColorCatalog.DataGraph,ThemeType.Light);
        this.lightNegColor = AppColorSettingHelper.getDefaultNegColor(false,lang,ColorConstants.ColorCatalog.DataGraph,ThemeType.Light);
        this.darkPosColor = AppColorSettingHelper.getDefaultPosColor(false, lang, ColorConstants.ColorCatalog.DataGraph,themeType);
        this.darkNegColor = AppColorSettingHelper.getDefaultNegColor(false,lang);
    
        this.lightPosHoverColor = AppColorSettingHelper.getDefaultPosColor(true,lang);
        this.lightNegHoverColor = AppColorSettingHelper.getDefaultNegColor(true,lang);
        this.darkPosHoverColor = AppColorSettingHelper.getDefaultPosColor(true,lang);
        this.darkNegHoverColor = AppColorSettingHelper.getDefaultNegColor(true,lang);
    
        this.lightPosDTColor = AppColorSettingHelper.getDefaultPosColor(false, lang, ColorConstants.ColorCatalog.DataTable);
        this.lightNegDTColor = AppColorSettingHelper.getDefaultNegColor(false, lang, ColorConstants.ColorCatalog.DataTable);
        this.darkPostDTColor = AppColorSettingHelper.getDefaultPosColor(false, lang, ColorConstants.ColorCatalog.DataTable);
        this.darkNegDTColor = AppColorSettingHelper.getDefaultNegColor(false, lang, ColorConstants.ColorCatalog.DataTable);
    
        this.lightPosDTHoverColor = AppColorSettingHelper.getDefaultPosColor(true, lang, ColorConstants.ColorCatalog.DataTable);
        this.lightNegDTHoverColor = AppColorSettingHelper.getDefaultNegColor(true, lang, ColorConstants.ColorCatalog.DataTable);
        this.darkPosDTHoverColor = AppColorSettingHelper.getDefaultPosColor(true, lang, ColorConstants.ColorCatalog.DataTable);
        this.darkNegDTHoverColor = AppColorSettingHelper.getDefaultNegColor(true, lang, ColorConstants.ColorCatalog.DataTable);

        this.isDefaultDarkPosColor = ColorConstants.UseDefaultColor.Yes;
        this.isDefaultLightPosColor = ColorConstants.UseDefaultColor.Yes;
        this.isDefaultDarkNegColor = ColorConstants.UseDefaultColor.Yes;
        this.isDefaultLightNegColor = ColorConstants.UseDefaultColor.Yes;
    
    }

    onSeralisedInfoRecieved(serializedValue) {

        //Show color settings on basis of entitlement other than the displaying language.
        let lang = UserInfoUtil.hasChinaEntitlement()? LangType.ZH_CN : LangType.RAY_DEFAULT;
        
        this.lightPosColor = serializedValue.lightPosColor ? serializedValue.lightPosColor : AppColorSettingHelper.getDefaultPosColor(false, lang,ColorConstants.ColorCatalog.DataGraph,ThemeType.Light);
        this.lightNegColor = serializedValue.lightNegColor ? serializedValue.lightNegColor : AppColorSettingHelper.getDefaultNegColor(false, lang,ColorConstants.ColorCatalog.DataGraph,ThemeType.Light);
        this.darkPosColor = serializedValue.darkPosColor ? serializedValue.darkPosColor :  AppColorSettingHelper.getDefaultPosColor(false, lang);
        this.darkNegColor = serializedValue.darkNegColor ? serializedValue.darkNegColor : AppColorSettingHelper.getDefaultNegColor(false, lang);
    
        this.lightPosHoverColor = serializedValue.lightPosHoverColor ? serializedValue.lightPosHoverColor : AppColorSettingHelper.getDefaultPosColor(true, lang);
        this.lightNegHoverColor = serializedValue.lightNegHoverColor ? serializedValue.lightNegHoverColor : AppColorSettingHelper.getDefaultNegColor(true, lang);
        this.darkPosHoverColor = serializedValue.darkPosHoverColor ? serializedValue.darkPosHoverColor : AppColorSettingHelper.getDefaultPosColor(true, lang);
        this.darkNegHoverColor = serializedValue.darkNegHoverColor ? serializedValue.darkNegHoverColor : AppColorSettingHelper.getDefaultNegColor(true, lang);
    
        this.lightPosDTColor = serializedValue.lightPosDTColor ? serializedValue.lightPosDTColor : AppColorSettingHelper.getDefaultPosColor(false, lang, ColorConstants.ColorCatalog.DataTable);
        this.lightNegDTColor = serializedValue.lightNegDTColor ? serializedValue.lightNegDTColor : AppColorSettingHelper.getDefaultNegColor(false, lang), ColorConstants.ColorCatalog.DataTable;
        this.darkPosDTColor = serializedValue.darkPosDTColor ? serializedValue.darkPosDTColor :  AppColorSettingHelper.getDefaultPosColor(false, lang, ColorConstants.ColorCatalog.DataTable);
        this.darkNegDTColor = serializedValue.darkNegDTColor ? serializedValue.darkNegDTColor : AppColorSettingHelper.getDefaultNegColor(false, lang, ColorConstants.ColorCatalog.DataTable);
    
        this.lightPosDTHoverColor = serializedValue.lightPosDTHoverColor ? serializedValue.lightPosDTHoverColor : AppColorSettingHelper.getDefaultPosColor(true, lang, ColorConstants.ColorCatalog.DataTable);
        this.lightNegDTHoverColor = serializedValue.lightNegDTHoverColor ? serializedValue.lightNegDTHoverColor : AppColorSettingHelper.getDefaultNegColor(true, lang, ColorConstants.ColorCatalog.DataTable);
        this.darkPosDTHoverColor = serializedValue.darkPosDTHoverColor ? serializedValue.darkPosDTHoverColor : AppColorSettingHelper.getDefaultPosColor(true, lang, ColorConstants.ColorCatalog.DataTable);
        this.darkNegDTHoverColor = serializedValue.darkNegDTHoverColor ? serializedValue.darkNegDTHoverColor : AppColorSettingHelper.getDefaultNegColor(true, lang, ColorConstants.ColorCatalog.DataTable);
    
        this.isDefaultDarkPosColor =  serializedValue.isDefaultDarkPosColor ? serializedValue.isDefaultDarkPosColor : ColorConstants.UseDefaultColor.Yes;
        this.isDefaultLightPosColor = serializedValue.isDefaultLightPosColor ? serializedValue.isDefaultLightPosColor : ColorConstants.UseDefaultColor.Yes;
        this.isDefaultDarkNegColor =  serializedValue.isDefaultDarkNegColor ? serializedValue.isDefaultDarkNegColor : ColorConstants.UseDefaultColor.Yes;
        this.isDefaultLightNegColor =  serializedValue.isDefaultLightNegColor ? serializedValue.isDefaultLightNegColor : ColorConstants.UseDefaultColor.Yes;

      }

      getDataObject() {
        return {
          lightPosColor: this.lightPosColor,
          lightNegColor: this.lightNegColor,
          lightPosHoverColor: this.lightPosHoverColor,
          lightNegHoverColor: this.lightNegHoverColor,
          darkPosColor: this.darkPosColor,
          darkNegColor: this.darkNegColor,
          darkPosHoverColor: this.darkPosHoverColor,
          darkNegHoverColor: this.darkNegHoverColor,
    
          lightPosDTColor: this.lightPosDTColor,
          lightNegDTColor: this.lightNegDTColor,
          lightPosDTHoverColor: this.lightPosDTHoverColor,
          lightNegDTHoverColor: this.lightNegDTHoverColor,
          darkPosDTColor: this.darkPosDTColor,
          darkNegDTColor: this.darkNegDTColor,
          darkPosDTHoverColor: this.darkPosDTHoverColor,
          darkNegDTHoverColor: this.darkNegDTHoverColor,

          isDefaultDarkPosColor: this.isDefaultDarkPosColor,
          isDefaultLightPosColor: this.isDefaultLightPosColor,
          isDefaultDarkNegColor: this.isDefaultDarkNegColor,
          isDefaultLightNegColor: this.isDefaultLightNegColor,
        };
      }
    
      getObjectMapKey() {
        return "ApplicationColorSettings";
      }
    
      getDefaultApplicationColorSettings() {
        return this;
      }
}

export default AppColorSettings;
