import { RayAnnotationType } from 'Constants/EAnnotationType';

export class FibonacciInfo {
    constructor() {
        this.AnnotationType = RayAnnotationType.FIB_AnnotationType;
            this.ObjectBrushHex = "#FF0066CC";
            //this.OriginalObjectBrushHex = ObjectBrushHex;
            this.RetracementBrushHex = "#FF000000";
            //this.OriginalRetracementBrushHex = RetracementBrushHex;
            this.ExtensionBrushHex = "#FF999999";
            //this.OriginalExtensionBrushHex = ExtensionBrushHex;
            this.IsExtensionEnabled = true;
            this.ExtensionLineWidth = 2;
            this.LineWidth = 2;
            
            this.StartExtendChecked = false;
            this.EndExtendChecked = false;

            this.HasLevelLabel = true;
            this.HasPriceLabel = true;
    }
}