import React, { Component } from "react";
import { ButtonGroup, Button} from "react-bootstrap";
import SettingsAction from "SettingsAction";
import SettingsStore from "SettingsStore";
import ThemeType from "ThemeType";
import light from "!less-vars-loader!../../Asset/css/lessvars/colors-light.less";
import dark from "!less-vars-loader!../../Asset/css/lessvars/colors-dark.less";
import LessUtil from "LessUtil";
import Slider from "rc-slider";
import ConsoleStore from "ConsoleStore";
import LocalizationStore from 'LocalizationStore';

import ColorSelection from "../../RayCustomControls/AppColor/ColorSelection";
import ColorPalette from "../../RayCustomControls/AppColor/ColorPalette";
import AppColorAction, { onToggleTheme } from "../../Actions/AppColorActions";
import UserInfoUtil from "UserInfoUtil";
import { dispatch } from "../../Redux/dispatch";
import { processIndicator } from "../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";
import { onGridLineBrightnessChange } from "../../Actions/AppColorActions";
import { connect } from "react-redux";
class Appearance extends Component {
  constructor(props) {
    super(props);
    this.changeTheme = this.changeTheme.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeResolutionToogle = this.changeResolutionToogle.bind(this);
    let settings = SettingsStore.getConsoleSettings();
    this.state = {
      theme: settings.currentTheme,
      lightGridValue: settings.gridLineLight,
      darkGridValue: settings.gridLineDark,
      useCanvasStatus:settings.NavDatagraphSettings.TabDataGraphSettings.useCanvas
    };

  }

  
  changeResolutionToogle(event) {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    let previousStatus =  tabDataGraphSettings.useCanvas;
    let currentStatus = event.target.innerText==="OFF"?true:false;
    
    if(previousStatus!=currentStatus){
      tabDataGraphSettings.useCanvas = currentStatus;
      SettingsStore.saveSettings();
     setTimeout(() => { location.reload(); }, 3000);
    this.setState({useCanvasStatus:currentStatus});
}
}


  changeTheme(switchTheme) {
    let consoleSetting = SettingsStore.getConsoleSettings();
    if (consoleSetting.currentTheme === switchTheme) {
      return;
    }
    if (consoleSetting.currentTheme === ThemeType.Dark) {
      LessUtil.modifyVars(light);
    }
    else {
      LessUtil.modifyVars(dark);
    }
    let newTheme = consoleSetting.currentTheme === ThemeType.Dark ? ThemeType.Light : ThemeType.Dark;
    consoleSetting.currentTheme = newTheme;
    SettingsAction.onThemeChanged(newTheme);
    //update Theme change to appColor reducer
    dispatch(onToggleTheme());
    dispatch(processIndicator())
    AppColorAction.themeChanged();
  }

  handleChange(theme) {
    this.setState({ theme: theme });
    //Log Usage
    ConsoleStore.trackUsage('ThemeChanged', theme);
  }

  setGridLineColor(theme) {
    let consoleSetting = SettingsStore.getConsoleSettings();
    if (theme === ThemeType.Dark) {
      consoleSetting.gridLineDark = this.state.darkGridValue;
    }
    else {
      consoleSetting.gridLineLight = this.state.lightGridValue;
    }
    if (theme === consoleSetting.currentTheme) {
      this.props.onGridLineBrightnessChange()
    }
    SettingsStore.saveSettings();
  }
  resetGridColor(theme) {
    let consoleSetting = SettingsStore.getConsoleSettings();
    if (theme === ThemeType.Dark) {
      consoleSetting.gridLineDark = 38;
      this.setState({ darkGridValue: 38 });
      AppColorAction.restoreDarkColor();
    }
    else {
      consoleSetting.gridLineLight = 205;
      this.setState({ lightGridValue: 205 });
      AppColorAction.restoreLightColor();
    }
    if (theme === consoleSetting.currentTheme) {
      SettingsAction.onGridLineColorChanged();
    }

    SettingsStore.saveSettings();
  }

  handleLightSliderChange(value) {
    this.setState({ lightGridValue: value });
  }

  handleDarkSliderChange(value) {
    this.setState({ darkGridValue: value });
  }

  render() {
    let colorContainerStyle = { "display": "block" }
    let customSepHeight = {}
    if (UserInfoUtil.hasChinaEntitlement()) {
      colorContainerStyle = { "display": "none" }
      customSepHeight = { "height": "210" }
    }


    const onButtonClassName = this.state.useCanvasStatus ? "btn-off": "btn-on";
    const offButtonClassName = "btn-off";

    return (
      <div className="appearance-service">
          <ColorPalette />
          {/* <p className="small-normal">{LocalizationStore.getTranslatedData('set_pre_aprn_sct', 'Select a color theme to apply to the workspace.')}</p> */}
          <div className="theme-selection">
            <h4>
              <span className="text">High Resolution Mode</span>
              <span className="switch-on-off">
                <ButtonGroup bsSize="xsmall">
                 <Button className={offButtonClassName} onClick ={this.changeResolutionToogle}>{LocalizationStore.getTranslatedData("OFF", "OFF")}</Button>
                 <Button className={onButtonClassName} onClick ={this.changeResolutionToogle}>{LocalizationStore.getTranslatedData("ON", "ON")}</Button>
                </ButtonGroup>
              </span>
            </h4>
            <p>Turning the setting ON will improve visual display but may reduce performance.</p>
            <h4>Theme</h4>
            <div className="theme-block">
              {/* <div className="theme-title medium-bold">{LocalizationStore.getTranslatedData('set_pre_aprn_lt', 'Light Theme')}</div> */}
              <div className="select-theme">
                <h5>{LocalizationStore.getTranslatedData('set_pre_aprn_lt', 'Light')}</h5>
                <div className="radio-btn">
                  <div className="pull-left">
                    <input id="radio1" type="radio" name="radio" checked={this.state.theme === ThemeType.Light ? true : false} onChange={this.handleChange.bind(this, ThemeType.Light)} onClick={() => this.changeTheme(ThemeType.Light)} />
                    <label className="light-theam-radio" htmlFor="radio1"></label>
                  </div>
                  <div className="image-light-theme" onMouseDown={this.handleChange.bind(this, ThemeType.Light)} onClick={() => this.changeTheme(ThemeType.Light)}></div>
                </div>
              </div>
              <div className="gridline">{LocalizationStore.getTranslatedData('set_pre_aprn_gb', 'Gridline brightness')}</div>
              <div className="gridLineSlider">
                <Slider min={0} max={255} step={5}
                  onAfterChange={() => this.setGridLineColor(ThemeType.Light)}
                  onChange={(value) => this.handleLightSliderChange(value)}
                  value={this.state.lightGridValue} />
              </div>
              <div className="select-color" style={colorContainerStyle}>
                <ColorSelection theme={ThemeType.Light} />
              </div>
              <div className="gridLineSlider dialog-link" onMouseDown={() => this.resetGridColor(ThemeType.Light)}>{LocalizationStore.getTranslatedData('resdef', 'Restore Defaults')}</div>
            </div>
            <div className="custom-sep" style={customSepHeight}></div>
            <div className="theme-block">
              {/* <div className="theme-title medium-bold">{LocalizationStore.getTranslatedData('set_pre_aprn_dt', 'Dark Theme')}</div> */}
              <div className="select-theme">
                <h5>{LocalizationStore.getTranslatedData('set_pre_aprn_dt', 'Dark')}</h5>
                <div className="radio-btn small-normal">
                  <div className="pull-left">
                    <input id="radio2" type="radio" name="radio" checked={this.state.theme === ThemeType.Dark ? true : false} onChange={this.handleChange.bind(this, ThemeType.Dark)} onClick={() => this.changeTheme(ThemeType.Dark)} />
                    <label className="light-theam-radio" htmlFor="radio2"></label>
                  </div>
                  <div className="image-dark-theme" onMouseDown={this.handleChange.bind(this, ThemeType.Dark)} onClick={() => this.changeTheme(ThemeType.Dark)}></div>

                </div>
              </div>
              <div className="gridline"></div>
              <div className="gridLineSlider">
                <Slider min={0} max={255} step={5}
                  onAfterChange={() => this.setGridLineColor(ThemeType.Dark)}
                  onChange={(value) => this.handleDarkSliderChange(value)}
                  value={this.state.darkGridValue} />
              </div>
              <div className="select-color" style={colorContainerStyle}>
                <ColorSelection theme={ThemeType.Dark} />
              </div>
              <div className="gridLineSlider dialog-link" onMouseDown={() => this.resetGridColor(ThemeType.Dark)}>{LocalizationStore.getTranslatedData('resdef', 'Restore Defaults')}</div>
            </div>
          </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onGridLineBrightnessChange: () => dispatch(onGridLineBrightnessChange())
})
export default connect(null, mapDispatchToProps)(Appearance)