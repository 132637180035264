import React, { PureComponent } from 'react';
import ReactDOM from "react-dom";
import ExplorerTop from "../Explorer/ExplorerTop.jsx";
import ExplorerExternalList from "../Explorer/ExplorerExternalList.jsx";
import ExplorerBottom from "../Explorer/ExplorerBottom.jsx";
import ResizableWindow from "RayCustomControls/ResizableWindow/ResizableWindow.jsx";
import ResizeActions from "Actions/ResizeActions.js";
import { ListExplorerConstants } from "ListExplorerConstants";
import StringUtil from "StringUtil";
import { dispatch } from "../../../../Redux/dispatch.js";
import ListExplorerStore from "ListExplorerStore";
import ListStore from "ListStore";
import ExternalViewStore from "../../../../Stores/NavModules/NavList/TabExternal/ExternalViewStore.js";
import ListProperty from "../../../../RayCustomControls/ListExplorer/ListProperty.jsx";
import ExternalDataView from "./ExternalDataView";
import {
  isUpdated, updateViewPort
} from '../../../../Actions/ExternalDataUploaderActions';
import ListManagerTabType from "ListManagerTabType";
import listStore from '../../../../Stores/NavModules/NavList/ListStore';
import store from "../../../../Redux/store.js";

export default class TabExternalData extends PureComponent {

  constructor(props) {
    super(props);    
    this.addNewItem = this.addNewItem.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onStopResize = this.onStopResize.bind(this);
    this.showExplorer = this.showExplorer.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.onContextMenuClick = this.onContextMenuClick.bind(this);
    this.externalViewStoreStateChange = this.externalViewStoreStateChange.bind(this);
    this.state = {
      addNewItemToList: false,
      showHelp: false,
      listId: this.props.listId,
      actualListId: this.props.actualListId,
      nodeId: this.props.actualListId,
      showContextMenu: false,
      deleteEnable: false,
      isDelete: false,
      deleteNode: null,
      isLoading: false,
      listExplorerWidth:ListStore.getListExplorerSettings().Width,
      resizeHandle: false,
      IsRollBack: true,
      activeExternalNode: ListStore.activeExternalNode
    }
    this.rootParentId = null;
    this.selectedNodeId = null;
    this.categoryType = null;
    this.isinIt = false;
    this.listType = null;
    this.listExplorerSettings = ListStore.getListExplorerSettings();
    this.listStoreStateChange = this.listStoreStateChange.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (!this.isinIt ) {
      dispatch(isUpdated(true));      
      this.isinIt  = true;
    }
    ListStore.addChangeListener(this.listStoreStateChange);
    ExternalViewStore.addChangeListener(this.externalViewStoreStateChange);
  }  
  componentWillUnmount() {
    ListStore.removeChangeListener(this.listStoreStateChange);
    ExternalViewStore.removeChangeListener(this.externalViewStoreStateChange);
  }
  setLoading(isLoading){    
     this.setState({isLoading:isLoading});
  }
  listStoreStateChange() {        
    const currentAction = ListStore.getCurrentAction();
    const state = ListStore.getState();
     if (currentAction === ListExplorerConstants.ActionTypes.UPDATE_LIST_EXPLORER_WIDTH) {
      if (state.SelectedTabKey === ListManagerTabType.External) {
        this.setState({ listExplorerWidth: ListStore.getListExplorerWidthForMetricLibrary() });
      }
    }
    else if(currentAction === ListExplorerConstants.ActionTypes.SET_CURRENT_EXTERNAL_LIST){
      this.setState({ activeExternalNode: ListStore.activeExternalNode });      
    }
   else if (currentAction === ListExplorerConstants.ActionTypes.EXTERNAL_HEADER_CHANGED) {
      if (state.SelectedTabKey === ListManagerTabType.External) {
        this.forceUpdate();
      }
      
    }    
  }  
  externalViewStoreStateChange(){    
    const currentAction = ExternalViewStore.getCurrentAction();    
    if(currentAction == ListExplorerConstants.ActionTypes.LIST_ROLL_BACK_SUCCESS) {
      this.setState({IsRollBack: false});
    }else if(currentAction ==ListExplorerConstants.ActionTypes.LIST_RE_UPLOAD_SUCCESS){
      this.setState({IsRollBack: true});
    }
    else{
      this.setState({IsRollBack: true});
    }
  }
  addNewItem(type, nodeId) {
    this.setState({ addNewItem: type, nodeId: nodeId })
    return this.props.addNewItem
  }

  onResize(width) {        
    this.resizeHandle = true;
    this.setState({ listExplorerWidth: width, resizeHandle: true });
    dispatch(updateViewPort(width));
  }

  onStopResize() {    
    let width = this.state.listExplorerWidth;
    ListStore.updateListExplorerPreviousWidth(ListStore.getListExplorerSettings().Width);
    if (width < this.listExplorerSettings.MinWidth) width = 0;
    ListStore.updateListExplorerWidth(width);
    ResizeActions.updateBrowserFilterWidths();
    this.setState({ resizeHandle: false });
  }

  showExplorer() {
    this.setState({
      listExplorerWidth: ListStore.getListExplorerSettings().PreviousWidth,
      resizeHandle: false
    });
    dispatch(updateViewPort(ListStore.getListExplorerSettings().PreviousWidth));
    ListStore.updateListExplorerPreviousWidth(ListStore.getListExplorerSettings().PreviousWidth);
    ListStore.updateListExplorerWidth(ListStore.getListExplorerSettings().PreviousWidth);

    window.setTimeout(() => {     
      if (StringUtil.isEmpty(ListExplorerStore.currentScrollPositionExternal))         
        ResizeActions.updateBrowserFilterWidths();
    }, 0);
  }

  toggleHelp() {
    listStore.listPropertyStateChange();
    this.setState({ showHelp: !this.state.showHelp });
  }

  onContextMenuClick(e) {
    e.preventDefault();
    this.rootParentId = ReactDOM.findDOMNode(e.target).getAttribute('data-rootParentId');
    this.categoryType = ReactDOM.findDOMNode(e.target).getAttribute('data-nodeType');
    this.listType = ReactDOM.findDOMNode(e.target).getAttribute('data-listtype');
    this.selectedNodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');
    this.nodeName = ReactDOM.findDOMNode(e.target).getAttribute('data-nodeName');
    this.props.onContextMenuClick(this.rootParentId, this.categoryType, this.listType, this.selectedNodeId, this.nodeName, e.clientX, e.clientY);
  }

  render() {
    let listHeightClass = this.state.showHelp ? 'middel-inner w50p-h' : 'middel-inner';
    this.showExplorerWindow = this.state.listExplorerWidth <= this.listExplorerSettings.MinWidth && !this.state.resizeHandle ? false : true;
    return (
      <span> {this.showExplorerWindow ?
        <div className="left-list-view" id="listExplorer" style={{ width: this.state.listExplorerWidth + 2 }}>
        <ResizableWindow className="list-explorer-resize" minWidth={23} maxWidth={this.listExplorerSettings.MaxWidth} width={this.state.listExplorerWidth} height={'100%'} direction="Horizontal" onStartResize={this.onResize} onStopResize={this.onStopResize} pointerEvents="inherit">
          <ExplorerTop tabCollection = {this.props.tabCollection} selectedTabKey = {this.props.selectedTabKey} onTabSelectionChanged = {this.props.onTabSelectionChanged}/>
          <div className="tab-view-left-middel">
            <div className={listHeightClass}>
              <ExplorerExternalList activeListData={this.state.activeExternalNode} 
                                    setLoading={this.setLoading}
                                    undoActionObj = {this.props.undoActionObj}
                                    fontSizeClass={this.props.fontSizeClass}
              />
            </div>
            {this.state.showHelp && <ListProperty showHelp={this.state.showHelp}
                                                  activeExternalNode={this.state.activeExternalNode}
                                                  selectedTabKey={this.props.selectedTabKey}
                                                  selectedExternalNodes={this.props.selectedExternalNodes}
                                                  IsRollBack={this.state.IsRollBack}

            />}
          </div>
          <ExplorerBottom addNewItem = {this.addNewItem} 
                          toggleHelp = {this.toggleHelp} 
                          renderRenameNode = {this.renderRenameNode} 
                          selectedTabKey = {this.props.selectedTabKey} 
                          isLoading = {this.state.isLoading} 
                          undoActionObj = {this.props.undoActionObj}
                          fontSizeClass={this.props.fontSizeClass}
                          store={store}
          />
          </ResizableWindow>
        </div>
        : 
        <div className="left-list-view add-side-bar padl8" id="listExplorer" role="button" tabIndex="-1" onClick={this.showExplorer}>
          <div className="dot-slide-bar"></div>
          <div className="dot-slide-bar1"></div>
        </div>}
        <ExternalDataView fontSizeClass={this.props.fontSizeClass} setLoading={this.setLoading} itemHeight="26"/>
      </span>
    );
  }
}