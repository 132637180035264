import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetUpdateListNodeRequest extends BaseServiceRequest {

    constructor(nodeId, nodeName, parentNodeId) {
        super();
        super.addRequestUri("updatelistnode.pb");
        super.addPathParameter(nodeId.toString());
        super.addRequestParameter("nodename", nodeName.toString());
        super.addRequestParameter("parentnodeid", parentNodeId.toString());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListExplorerNodeResponse";
    }
}

export default GetUpdateListNodeRequest;