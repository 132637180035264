import ChangePasswordRequest from '../Requests/ChangePasswordRequest'
import BaseServiceApi from 'BaseServiceApi'

class ChangePasswordApi {

  getChangePasswordData(currentPassword, newPassword, loginErrType){
    const req = new ChangePasswordRequest(currentPassword, newPassword, loginErrType);
    return BaseServiceApi.processServiceCall(req);
  }
}

var changePasswordApi= new ChangePasswordApi();
export default changePasswordApi;