import BaseServiceApiWCF from "BaseServiceApiWCF";
import BaseServiceApi from "BaseServiceApi";
import GetStockPriceAndInfoRequest from "../Requests/GetStockPriceAndInfoRequest";
import FindStockInfoRequest from "../Requests/FindStockInfo.js";
import FindGetDatagraphRequest from "../Requests/FindGetDatagraph.js";
import FindGetSnapShotRequest from "../Requests/FindGetSnapshotRequest.js";
import FindGetSnapShotRequestNew from '../Requests/FindGetSnapshotRequestNew';
import FindGetBlocksRequest from "../Requests/FindGetBlocksRequest.js";
import FindGetTechInd from "../Requests/FindGetTechInd.js";
import GetStockHeaderRequest from "../Requests/GetStockHeader.js";
import GetStockPricesRequest from "../Requests/GetStockPrices.js";
import GetCfbMetricsRequest from "../Requests/GetCfbMetricsRequest.js";
import GetDefaultFinancialBlockHeadersRequest from "../Requests/GetDefaultFinancialBlockHeadersRequest.js";
import dateHelper from "DateHelper";
import dataGraphStore from "../../Stores/NavModules/NavDataGraph/DataGraphStore";
import GraphType from "GraphType";
import StringUtil from "StringUtil";
import UserInfoUtil from "UserInfoUtil";
import GetStreetTargetPriceData from "../Requests/GetStreetTargetPriceData.js";
//const Promise = window.Promise || require("bluebird");
import GetDatagraphRequest from '../Requests/getdatagraph.js';
import GetDataGraphDetailsRequest from '../Requests/getdatagraphdetails.js';
import GetPricePanelDataRequest from '../Requests/GetPricePanelDataRequest.js'
import ByteBuffer from 'bytebuffer';
import FinancialBlockRequest from "../Requests/FinancialBlockRequest";
import FinancialBlockESRequest from "../Requests/FinancialBlockESRequest";
import FinancialBlockReqInfoObect from '../RequestHelper/FinancialBlockReqInfo';
import GetTechIndicatorRequest from "../Requests/GetTechIndicatorRequest";
import GetSummaryBlockDataRequest from '../Requests/GetSummaryBlockDataRequest';

class ChartApi {

    constructor() {
        this.builder = BaseServiceApiWCF.getBuilder();
        this.rayData = this.builder.build("RayChartCommon");
    }
    
async getDataGraphDetailsRequest(snapShot,blockReq,msid,ignoreLlr,startDate,endDate,periodicity,osid,userhasxfl,targetCurrency,isQuarterly,symbol){
    const req = new GetDataGraphDetailsRequest(snapShot,blockReq,msid,ignoreLlr,startDate,endDate,periodicity,osid,userhasxfl,targetCurrency,isQuarterly,symbol);
    return new Promise(function (resolve, reject) {
        BaseServiceApi.processServiceCall(req).then((graphData) => {
            let responseData = {};
            let builder = BaseServiceApiWCF.getBuilder();
            let rayData = builder.build("RayChartCommon");
            
            if(graphData){
                const blockResponseProto = rayData["FindGetBlocksReply2"];
                const bufferBlockData = ByteBuffer.fromBase64(graphData.FindGetBlocks2ResponseProto); // Uint8Array.from(atob(data), c => c.charCodeAt(0));
                let blockResult = blockResponseProto.decode(bufferBlockData);
                if (blockResult) {
                    if (blockResult.CustomFinancialBlockData2) {
                        blockResult.CustomFinancialBlockData2.EndDate = dateHelper.getUtcTicksfromTimeStamp(blockResult.CustomFinancialBlockData2.EndDate);//, pacificOffset);
                        blockResult.CustomFinancialBlockData2.StartDate = dateHelper.getUtcTicksfromTimeStamp(blockResult.CustomFinancialBlockData2.StartDate);//, pacificOffset);
                        const rowLength = blockResult.CustomFinancialBlockData2.ArrayRowData.length;
                        const arrRow = blockResult.CustomFinancialBlockData2.ArrayRowData;
                        for (var i = 0; i < rowLength; i++) {
                            const row = arrRow[i];
                            const cellLenth = row.CellData.length;
                            const arrCell = row.CellData;
                            for (var cellIndex = 0; cellIndex < cellLenth; cellIndex++) {
                                const cellData = arrCell[cellIndex];
                                cellData.FiscalDate = dateHelper.getUtcTicksfromTimeStamp(cellData.FiscalDate);//, pacificOffset);
                                cellData.Reporteddate = dateHelper.getUtcTicksfromTimeStamp(cellData.Reporteddate);//, pacificOffset);
                            }

                        }
                    }
                
              //  const snapShotResponseProto = rayData["FindGetSnapShotReply"];
               // const bufferSnapShotData = ByteBuffer.fromBase64(graphData.FindGetSnapShotResponseProto); // Uint8Array.from(atob(data), c => c.charCodeAt(0));
                // let SnapShotResult = snapShotResponseProto.decode(bufferSnapShotData);
                // SnapShotResult.StockHeader.Prevolume = SnapShotResult.StockHeader.Prevolume ? SnapShotResult.StockHeader.Prevolume.toNumber() : 0;
                // if (SnapShotResult) {
                //     if (SnapShotResult.StockHeader) {
                //         SnapShotResult.StockHeader.EpsDueDate = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.StockHeader.EpsDueDate);//, pacificOffset);
                //         SnapShotResult.StockHeader.IpoDate = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.StockHeader.IpoDate);//, pacificOffset);
                //         SnapShotResult.StockHeader.CreateTime = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.StockHeader.CreateTime);//, pacificOffset);
                //         SnapShotResult.StockHeader.LastUpdateTime = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.StockHeader.LastUpdateTime);//, pacificOffset);
                //         SnapShotResult.StockHeader.ExpiryDate = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.StockHeader.ExpiryDate);//, pacificOffset);
                //     }
                //     if (SnapShotResult.SummaryData) {
                //         if (SnapShotResult.SummaryData.AnnualData.length > 0)
                //             SnapShotResult.SummaryData.AnnualData = dataGraphStore.processAnnualDates(SnapShotResult.SummaryData.AnnualData);//, pacificOffset);
                //         if (SnapShotResult.SummaryData.WeekData.length > 0)
                //             SnapShotResult.SummaryData.WeekData = dataGraphStore.processWeekDates(SnapShotResult.SummaryData.WeekData);//, pacificOffset);
                //         SnapShotResult.SummaryData.XDate = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.SummaryData.XDate);//, pacificOffset);
                //         SnapShotResult.SummaryData.GrowthEarnDt = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.SummaryData.GrowthEarnDt);//, pacificOffset);
                //         SnapShotResult.SummaryData.NavDate = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.SummaryData.NavDate);//, pacificOffset);
                //         SnapShotResult.SummaryData.CreateTime = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.SummaryData.CreateTime);//, pacificOffset);
                //         SnapShotResult.SummaryData.LastUpdateTime = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.SummaryData.LastUpdateTime);//, pacificOffset);
                //         SnapShotResult.SummaryData.CurAeDate = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.SummaryData.CurAeDate);//, pacificOffset);
                //         SnapShotResult.SummaryData.NxtAeDate = dateHelper.getUtcTicksfromTimeStamp(SnapShotResult.SummaryData.NxtAeDate);//, pacificOffset);
                //     }
                //     if (SnapShotResult.SummaryData && SnapShotResult.SummaryData.StockInfo) {
                //         SnapShotResult.SummaryData.StockInfo.Osid = SnapShotResult.SummaryData.StockInfo.Osid.toNumber();
                //         dataGraphStore.processStockInfoDates(SnapShotResult.SummaryData.StockInfo);
                //     }
                //     if (SnapShotResult.EpsData) {
                //         dataGraphStore.processFinancialDates(SnapShotResult.EpsData);//, pacificOffset);
                //     }
                //     if (SnapShotResult.RpsResults) {
                //         dataGraphStore.processFinancialDates(SnapShotResult.RpsResults);//, pacificOffset);
                //     }
                //     if (SnapShotResult.T4QResults && SnapShotResult.T4QResults.length>0) {
                //         dataGraphStore.processFinancialDates(SnapShotResult.T4QResults[0].Results);//, pacificOffset);
                //         dataGraphStore.processFinancialDates(SnapShotResult.T4QResults[1].Results);//, pacificOffset);
                //         dataGraphStore.processFinancialDates(SnapShotResult.T4QResults[2].Results);//, pacificOffset);
                //         dataGraphStore.processFinancialDates(SnapShotResult.T4QResults[3].Results);//, pacificOffset);
                //     }

                    
                  //  responseData.snapshotData = SnapShotResult;
                    responseData.financialData = blockResult;
                    responseData.graphData = graphData.GraphModel;
                    responseData.ideasData = graphData.AllIdeasData
                    responseData.cfbMetricData = graphData.CFBMetricLibraryResponse
                    responseData.streetTargetPriceData = graphData.StreetTargetPrice

                resolve(responseData);
            } else {
                reject();
            }
        }
        });
    });
}
    async getDatagraphRequest(findGetDatagraphRequest,Osid,IsNASDAQBasic,isHistoric,protoReq){
        const req = new GetDatagraphRequest(findGetDatagraphRequest,Osid,IsNASDAQBasic,isHistoric,protoReq);
        return new Promise(function (resolve, reject) {
             BaseServiceApi.processServiceCall(req).then((graphData) => {
                let builder = BaseServiceApiWCF.getBuilder();
                let rayData = builder.build("RayChartCommon");
                const responseProto = rayData["FindGetDataGraphReply"];
                const buffer = ByteBuffer.fromBase64(graphData.FindGetDataGraphReplyProto); // Uint8Array.from(atob(data), c => c.charCodeAt(0));
                const result = responseProto.decode(buffer);
                let responseData = {};
                if (result) {
                    if (!StringUtil.isEmpty(result.HsfData))
                        result.HsfData.LastVolume = result.HsfData.LastVolume.toNumber();
                    if (result.StockInfo) {
                        result.StockInfo.Osid = result.StockInfo.Osid.toNumber();
                        dataGraphStore.processStockInfoDates(result.StockInfo);//, pacificOffset);
                    }
                    if (result.HsfData && result.HsfData.HSFResults) {
                        result.HsfData.HSFResults = dataGraphStore.processHSFResultsDates(result.HsfData.HSFResults);//, pacificOffset);
                        dataGraphStore.processHsfDataDates(result.HsfData);
                        if (result.HsfData.HSFRS1Results && result.HsfData.HSFRS1Results.length > 0)
                            result.HsfData.HSFRS1Results = dataGraphStore.processHSFResultsDates(result.HsfData.HSFRS1Results);//, pacificOffset);
                        if (result.HsfData.MAHSFResults && result.HsfData.MAHSFResults.length > 0)
                            result.HsfData.MAHSFResults = dataGraphStore.processHSFResultsDates(result.HsfData.MAHSFResults);//, pacificOffset);
                        if (result.HsfData.YTDResults && result.HsfData.YTDResults.length > 0)
                            result.HsfData.YTDResults = dataGraphStore.processHSFResultsDates(result.HsfData.YTDResults);//, pacificOffset);
                        if (result.HsfData.YTD1Results && result.HsfData.YTD1Results.length > 0)
                            result.HsfData.YTD1Results = dataGraphStore.processHSFResultsDates(result.HsfData.YTD1Results);//, pacificOffset);
                        if (result.HsfData.YTD3Results && result.HsfData.YTD3Results.length > 0)
                            result.HsfData.YTD3Results = dataGraphStore.processHSFResultsDates(result.HsfData.YTD3Results);//, pacificOffset);
                        if (result.HsfData.YTD5Results && result.HsfData.YTD5Results.length > 0)
                            result.HsfData.YTD5Results = dataGraphStore.processHSFResultsDates(result.HsfData.YTD5Results);//, pacificOffset);
                        if (result.HsfData.YTD10Results && result.HsfData.YTD10Results.length > 0)
                            result.HsfData.YTD10Results = dataGraphStore.processHSFResultsDates(result.HsfData.YTD10Results);//, pacificOffset);
                        if (result.HsfData.EndDateNode && result.HsfData.EndDateNode.DateTicks) {
                            result.HsfData.EndDateNode.Date = dateHelper.getUtcTicksfromTimeStamp(result.HsfData.EndDateNode.Date);//, pacificOffset);
                        }
                        if (result.HsfData.DDEDateNode && result.HsfData.DDEDateNode.DateTicks)
                            result.HsfData.DDEDateNode.Date = dateHelper.getUtcTicksfromTimeStamp(result.HsfData.DDEDateNode.Date);//, pacificOffset);
                        if (result.HsfData.BatsPrices && result.HsfData.BatsPrices.DateTicks)
                            result.HsfData.BatsPrices.Date = dateHelper.getUtcTicksfromTimeStamp(result.HsfData.BatsPrices.Date);//, pacificOffset);
                    }
                    if (result.HsfData && result.HsfData.EpsResults) {
                        dataGraphStore.processFinancialDates(result.HsfData.EpsResults);//, pacificOffset);
                    }
                    if (result.CorporateEvents) {
                        result.CorporateEvents.EquityEvents = dataGraphStore.processCorpEventsDates(result.CorporateEvents.EquityEvents);//, pacificOffset);
                        result.CorporateEvents.InsiderBuy = dataGraphStore.processCorpEventsDates(result.CorporateEvents.InsiderBuy);//, pacificOffset);
                        result.CorporateEvents.InsiderSell = dataGraphStore.processCorpEventsDates(result.CorporateEvents.InsiderSell);//, pacificOffset);
                        result.CorporateEvents.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.CorporateEvents.StartDate);//, pacificOffset);
                        result.CorporateEvents.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.CorporateEvents.EndDate);//, pacificOffset);
                        result.CorporateEvents.CreateDate = dateHelper.getUtcTicksfromTimeStamp(result.CorporateEvents.CreateDate);//, pacificOffset);

                    }
                    if (result.SplitFactors && result.SplitFactors.SplitItems)
                        result.SplitFactors.SplitItems = dataGraphStore.processSplitItems(result.SplitFactors.SplitItems);//, pacificOffset);
                    if (result.BlkOutDatesDict && result.BlkOutDatesDict.BlackOutDict)
                        dataGraphStore.processBlockOutItems(result.BlkOutDatesDict.BlackOutDict);//, pacificOffset);
                    if (result.EquityEvents && result.EquityEvents.EventDateItems) {
                        dataGraphStore.processEquityEvents(result.EquityEvents.EventDateItems);//, pacificOffset);                        
                    }
                    if (result.IndexClosingResults && result.IndexClosingResults.CloseResults && result.IndexClosingResults.CloseResults.length > 0) {
                        result.IndexClosingResults.CloseResults = dataGraphStore.processCloseResultDates(result.IndexClosingResults.CloseResults);//, pacificOffset);
                        result.IndexClosingResults.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.IndexClosingResults.StartDate);//, pacificOffset);
                        result.IndexClosingResults.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.IndexClosingResults.EndDate);//, pacificOffset);
                    }
                    if (result.Rs1ClosingResults && result.Rs1ClosingResults.CloseResults && result.Rs1ClosingResults.CloseResults.length > 0) {
                        result.Rs1ClosingResults.CloseResults = dataGraphStore.processCloseResultDates(result.Rs1ClosingResults.CloseResults);//, pacificOffset);
                        result.Rs1ClosingResults.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.Rs1ClosingResults.StartDate);//, pacificOffset);
                        result.Rs1ClosingResults.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.Rs1ClosingResults.EndDate);//, pacificOffset);
                    }
                    if (result.Rs2ClosingResults && result.Rs2ClosingResults.CloseResults && result.Rs2ClosingResults.CloseResults.length > 0) {
                        result.Rs2ClosingResults.CloseResults = dataGraphStore.processCloseResultDates(result.Rs2ClosingResults.CloseResults);//, pacificOffset);
                        result.Rs2ClosingResults.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.Rs2ClosingResults.StartDate);//, pacificOffset);
                        result.Rs2ClosingResults.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.Rs2ClosingResults.EndDate);//, pacificOffset);
                    }
                    responseData.StockData = result;
                    responseData.volumePriceData = graphData.volumePriceData;
                    resolve(responseData);
                } else {
                    reject();
                }

            });
        });
    }
 
    findStockInfo(inSymbol) {
        const request = new FindStockInfoRequest();
        const symbol = inSymbol.replace("&", "%26");
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindStock_WCF"));
        request.addParam("symbol", symbol);
        request.addParam("realTimeOn", false);
        request.addParam("productVersion", 43);
        request.addParam("hasLocalSymbol", UserInfoUtil.hasLocalSymbol());
        request.addParam("currentLang", UserInfoUtil.getCurrentLang());
        return BaseServiceApiWCF.processServiceCallNew(request);
    }

    getStockPrices(osid, graphType, symbolType, rt = 0) {
        const request = new GetStockPricesRequest();
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindStock_WCF"));
        request.addParam("osid", osid);
        request.addParam("graphType", graphType);
        request.addParam("symbolType", symbolType);
        request.addParam("rt", rt);
        return BaseServiceApiWCF.processServiceCallNew(request);
    }

    getStockPriceAndInfo(inSymbol, graphType,isNasdaqOn) {
        const request = new GetStockPriceAndInfoRequest();
        const symbol = inSymbol.replace("&", "%26");
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindStock_WCF"));
        request.addParam("symbol", symbol);
        request.addParam("gType", graphType);
        request.addParam("realTimeOn", isNasdaqOn);
        request.addParam("productVersion", 43);
        request.addParam("hasLocalSymbol", UserInfoUtil.hasLocalSymbol());
        request.addParam("currentLang", UserInfoUtil.getCurrentLang());
        return BaseServiceApiWCF.processServiceCallNew(request);
    }

    getTechnicalIndicatorData(findGetTechIndRequest){
        let request = new GetTechIndicatorRequest(findGetTechIndRequest);
        return new Promise((resolve, reject) => {
            BaseServiceApi.processServiceCall(request).then((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject();
                }
            });
        });
    }

    GetBlockDataRequest(findGetBlocksRequest) {
        var request = new FindGetBlocksRequest();
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindGetBlocks_WCF"));
        const ProtoRequest = this.rayData["FindGetBlocksRequest"];
        const protoReq = new ProtoRequest();
        if (findGetBlocksRequest.Periodicity === GraphType.Intraday ||
            findGetBlocksRequest.Periodicity === GraphType.Quarterly ||
            findGetBlocksRequest.Periodicity === GraphType.Annual)
            return null;
        protoReq.Symbol = findGetBlocksRequest.Symbol;
        protoReq.Osid = findGetBlocksRequest.Osid;
        protoReq.SymbolType = findGetBlocksRequest.SymbolType;
        protoReq.Periodicity = findGetBlocksRequest.Periodicity;
        protoReq.EndDate = findGetBlocksRequest.EndDate;
        protoReq.StartDate = findGetBlocksRequest.StartDate;
        protoReq.EndDateTicks = findGetBlocksRequest.EndDate.getTime();
        protoReq.StartDateTicks = findGetBlocksRequest.StartDate.getTime();
        protoReq.EndDateString = dateHelper.getDateString(findGetBlocksRequest.EndDate) + " " + dateHelper.getTimeString(findGetBlocksRequest.EndDate);
        protoReq.StartDateString = dateHelper.getDateString(findGetBlocksRequest.StartDate) + " " + dateHelper.getTimeString(findGetBlocksRequest.StartDate);
        protoReq.GetFinancial = findGetBlocksRequest.GetFinancial;
        protoReq.GetCustomFinancialBlock = findGetBlocksRequest.GetCustomFinancialBlock;
        protoReq.CustomFinancialBlockRequest = findGetBlocksRequest.CustomFinancialBlockRequest;
        protoReq.GetOwnership = findGetBlocksRequest.GetOwnership;
        protoReq.FcQuarters = findGetBlocksRequest.FcQuarters;
        protoReq.WonOnly = findGetBlocksRequest.wonOnly === "0" ? 0 : 1;
        protoReq.RevWonOnly = findGetBlocksRequest.revWonOnly === "0" ? 0 : 1;
        protoReq.FbRequest = findGetBlocksRequest.FbRequest;
        protoReq.ProductVersion = findGetBlocksRequest.ProductVersion;
        protoReq.IsHistoric = findGetBlocksRequest.IsHistoric;
        return protoReq;
    }
    GetBlockRequest(findGetBlocksRequest) {
        var request = new FindGetBlocksRequest();
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindGetBlocks_WCF"));
        const ProtoRequest = this.rayData["FindGetBlocksRequest"];
        const protoReq = new ProtoRequest();
        if (findGetBlocksRequest.Periodicity === GraphType.Intraday ||
            findGetBlocksRequest.Periodicity === GraphType.Quarterly ||
            findGetBlocksRequest.Periodicity === GraphType.Annual)
            return null;
        protoReq.Symbol = findGetBlocksRequest.Symbol;
        protoReq.Osid = findGetBlocksRequest.Osid;
        protoReq.SymbolType = findGetBlocksRequest.SymbolType;
        protoReq.Periodicity = findGetBlocksRequest.Periodicity;
        protoReq.EndDate = findGetBlocksRequest.EndDate;
        protoReq.StartDate = findGetBlocksRequest.StartDate;
        protoReq.EndDateTicks = findGetBlocksRequest.EndDate.getTime();
        protoReq.StartDateTicks = findGetBlocksRequest.StartDate.getTime();
        protoReq.EndDateString = dateHelper.getDateString(findGetBlocksRequest.EndDate) + " " + dateHelper.getTimeString(findGetBlocksRequest.EndDate);
        protoReq.StartDateString = dateHelper.getDateString(findGetBlocksRequest.StartDate) + " " + dateHelper.getTimeString(findGetBlocksRequest.StartDate);
        protoReq.GetFinancial = findGetBlocksRequest.GetFinancial;
        protoReq.GetCustomFinancialBlock = findGetBlocksRequest.GetCustomFinancialBlock;
        protoReq.CustomFinancialBlockRequest = findGetBlocksRequest.CustomFinancialBlockRequest;
        protoReq.GetOwnership = findGetBlocksRequest.GetOwnership;
        protoReq.FcQuarters = findGetBlocksRequest.FcQuarters;
        protoReq.WonOnly = findGetBlocksRequest.wonOnly === "0" ? 0 : 1;
        protoReq.RevWonOnly = findGetBlocksRequest.revWonOnly === "0" ? 0 : 1;
        protoReq.FbRequest = findGetBlocksRequest.FbRequest;
        protoReq.ProductVersion = findGetBlocksRequest.ProductVersion;
        protoReq.IsHistoric = findGetBlocksRequest.IsHistoric;
        request.addParam("proto", protoReq.encode64());
        //const pacificOffset = dateHelper.getPacificOffset(findGetBlocksRequest.EndDate.getTime());
        /* eslint-disable*/
        return new Promise(function (resolve, reject) {
            /* eslint-enable */
            //    return new Promise((resolve, reject) => {
            BaseServiceApiWCF.processServiceCallNew(request).then((result) => {
                if (result) {
                    if (result.CustomFinancialBlockData2) {
                        result.CustomFinancialBlockData2.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.CustomFinancialBlockData2.EndDate);//, pacificOffset);
                        result.CustomFinancialBlockData2.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.CustomFinancialBlockData2.StartDate);//, pacificOffset);
                        const rowLength = result.CustomFinancialBlockData2.ArrayRowData.length;
                        const arrRow = result.CustomFinancialBlockData2.ArrayRowData;
                        for (var i = 0; i < rowLength; i++) {
                            const row = arrRow[i];
                            const cellLenth = row.CellData.length;
                            const arrCell = row.CellData;
                            for (var cellIndex = 0; cellIndex < cellLenth; cellIndex++) {
                                const cellData = arrCell[cellIndex];
                                cellData.FiscalDate = dateHelper.getUtcTicksfromTimeStamp(cellData.FiscalDate);//, pacificOffset);
                                cellData.Reporteddate = dateHelper.getUtcTicksfromTimeStamp(cellData.Reporteddate);//, pacificOffset);
                            }

                        }
                    }
                    resolve(result);
                } else {
                    reject();
                }
            });
        });
    }

    GetOwnershipBlockRequest(findGetBlocksRequest) {
        const request = new FindGetBlocksRequest();
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindGetBlocks_WCF"));
        const ProtoRequest = this.rayData["FindGetBlocksRequest"];
        const protoReq = new ProtoRequest();
         protoReq.Symbol = findGetBlocksRequest.Symbol;
         protoReq.Osid = findGetBlocksRequest.Osid;
         protoReq.SymbolType = findGetBlocksRequest.SymbolType;
         protoReq.Periodicity = findGetBlocksRequest.Periodicity;
         protoReq.EndDate = findGetBlocksRequest.EndDate;
         protoReq.StartDate = findGetBlocksRequest.StartDate;
         protoReq.EndDateTicks = findGetBlocksRequest.EndDate.getTime();
         protoReq.StartDateTicks = findGetBlocksRequest.StartDate.getTime();
         protoReq.EndDateString = `${dateHelper.getDateString(findGetBlocksRequest.EndDate)} ${dateHelper.getTimeString(findGetBlocksRequest.EndDate)}`;
         protoReq.StartDateString = `${dateHelper.getDateString(findGetBlocksRequest.StartDate)} ${dateHelper.getTimeString(findGetBlocksRequest.StartDate)}`;
         protoReq.GetFinancial = findGetBlocksRequest.GetFinancial;
         protoReq.GetCustomFinancialBlock = findGetBlocksRequest.GetCustomFinancialBlock;
         protoReq.CustomFinancialBlockRequest = findGetBlocksRequest.CustomFinancialBlockRequest;
         protoReq.GetOwnership = findGetBlocksRequest.GetOwnership;
         protoReq.FcQuarters = findGetBlocksRequest.FcQuarters;
         protoReq.WonOnly = findGetBlocksRequest.wonOnly === "0" ? 0 : 1;
         protoReq.RevWonOnly = findGetBlocksRequest.revWonOnly === "0" ? 0 : 1;
         protoReq.FbRequest = findGetBlocksRequest.FbRequest;
         protoReq.GetEventBlock = findGetBlocksRequest.GetEventBlock;
         protoReq.IsHistoric = findGetBlocksRequest.IsHistoric;
         protoReq.ObRequest = findGetBlocksRequest.ObRequest;         
         protoReq.OwnerShipListId = findGetBlocksRequest.OwnerShipListId;
         protoReq.OwnerSponsorIds = findGetBlocksRequest.OwnerSponsorIds;
         protoReq.ProductVersion = findGetBlocksRequest.ProductVersion;
         request.addParam("proto", protoReq.encode64());
        //const pacificOffset = dateHelper.getPacificOffset(findGetBlocksRequest.EndDate.getTime());
        /* eslint-disable*/
        return new Promise(function (resolve, reject) {
            /* eslint-enable */
            //    return new Promise((resolve, reject) => {
            BaseServiceApiWCF.processServiceCallNew(request).then((result) => {
                if (result && result.OwnerBlockResult) {                          
                    resolve(result.OwnerBlockResult);
                } else {
                    reject();
                }
            });
        });
    }

    GetFinancialBlockData(data){
        // const protoReq = FinancialBlockReqInfoObect.getFinancialBlockRequestData(data);
        const request = new FinancialBlockRequest(data);
        return BaseServiceApi.processServiceCall(request);
    }

    GetESFinancialBlockData(data){
        const request = new FinancialBlockESRequest(data);
        return BaseServiceApi.processServiceCall(request);
    }

    GetStockHeader(findGetSnapShotRequest) {
        var request = new GetStockHeaderRequest();
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("GetStockHeader_WCF"));
        const ProtoRequest = this.rayData["HSFDataRequest"];
        const protoReq = new ProtoRequest();

        protoReq.Symbol = findGetSnapShotRequest.Symbol;
        protoReq.EndDateTicks = findGetSnapShotRequest.endDate.getTime();
        protoReq.StartDateTicks = findGetSnapShotRequest.beginDate.getTime();
        protoReq.EndDateString = dateHelper.getDateString(findGetSnapShotRequest.endDate) + " " + dateHelper.getTimeString(findGetSnapShotRequest.endDate);
        protoReq.StartDateString = dateHelper.getDateString(findGetSnapShotRequest.beginDate) + " " + dateHelper.getTimeString(findGetSnapShotRequest.beginDate);
        protoReq.Osid = findGetSnapShotRequest.Osid;
        protoReq.SymbolType = findGetSnapShotRequest.SymbolType;
        protoReq.Periodicity = findGetSnapShotRequest.Periodicity;
        protoReq.IsWonOnly = findGetSnapShotRequest.IsWonOnly === "0" ? 0 : 1;
        protoReq.IsRevWonOnly = findGetSnapShotRequest.IsRevWonOnly === "0" ? 0 : 1;
        protoReq.EndDate = findGetSnapShotRequest.endDate;
        protoReq.StartDate = findGetSnapShotRequest.beginDate;
        protoReq.FcQuarters = findGetSnapShotRequest.FcQuarters;
        protoReq.TargetCurrencyCode = null;
        //protoReq.FinTargetCurrencyCode = null;
        protoReq.GetSummaryBlock = true; //always set this to true because visibility keeps getting toggled from all of the mouse down/up events
        protoReq.ProductVersion = 46;
        protoReq.GetEarningLine = findGetSnapShotRequest.GetEarningLine;
        protoReq.GetRevenueLine = findGetSnapShotRequest.GetRevenueLine;
        request.addParam("proto", protoReq.encode64());
        //const pacificOffset = dateHelper.getPacificOffset(findGetSnapShotRequest.EndDate.getTime());
        return new Promise((resolve, reject) => {
            BaseServiceApiWCF.processServiceCallNew(request).then((result) => {
                if (result) {
                    result.EpsDueDate = dateHelper.getUtcTicksfromTimeStamp(result.EpsDueDate);//, pacificOffset);
                    result.IpoDate = dateHelper.getUtcTicksfromTimeStamp(result.IpoDate);//, pacificOffset);
                    result.CreateTime = dateHelper.getUtcTicksfromTimeStamp(result.CreateTime);//, pacificOffset);
                    result.LastUpdateTime = dateHelper.getUtcTicksfromTimeStamp(result.LastUpdateTime);//, pacificOffset);
                    result.ExpiryDate = dateHelper.getUtcTicksfromTimeStamp(result.ExpiryDate);//, pacificOffset);
                    resolve(result);
                } else {
                    reject();
                }
            });
        });
    }

    GetSummaryBlockData(findGetSnapShotRequest) {
        const request = new FindGetSnapShotRequestNew(findGetSnapShotRequest);
        return BaseServiceApi.processServiceCall(request);
        // var request = new FindGetSnapShotRequest();
        // request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindGetSnapShot_WCF"));
        // const ProtoRequest = this.rayData["FindGetSnapShotRequest"];
        // const protoReq = new ProtoRequest();

        // protoReq.Symbol = findGetSnapShotRequest.Symbol;
        // protoReq.EndDateTicks = findGetSnapShotRequest.endDate.getTime();
        // protoReq.StartDateTicks = findGetSnapShotRequest.beginDate.getTime();
        // protoReq.EndDateString = dateHelper.getDateString(findGetSnapShotRequest.endDate) + " " + dateHelper.getTimeString(findGetSnapShotRequest.endDate);
        // protoReq.StartDateString = dateHelper.getDateString(findGetSnapShotRequest.beginDate) + " " + dateHelper.getTimeString(findGetSnapShotRequest.beginDate);
        // protoReq.Osid = findGetSnapShotRequest.Osid;
        // protoReq.SymbolType = findGetSnapShotRequest.SymbolType;
        // protoReq.Periodicity = findGetSnapShotRequest.Periodicity;
        // protoReq.IsWonOnly = findGetSnapShotRequest.IsWonOnly === "0" ? 0 : 1;
        // protoReq.IsRevWonOnly = findGetSnapShotRequest.IsRevWonOnly === "0" ? 0 : 1;
        // protoReq.EndDate = findGetSnapShotRequest.endDate;
        // protoReq.StartDate = findGetSnapShotRequest.beginDate;
        // protoReq.FcQuarters = findGetSnapShotRequest.FcQuarters;
        // protoReq.TargetCurrencyCode = null;
        // protoReq.FinTargetCurrencyCode = null;
        // if(findGetSnapShotRequest.TargetCurrencyCode){
        //     protoReq.FinTargetCurrencyCode = findGetSnapShotRequest.TargetCurrencyCode === "GBP" ? "GBX" : (findGetSnapShotRequest.TargetCurrencyCode === "ZAR" ? "ZAX" : findGetSnapShotRequest.TargetCurrencyCode);
        // }
        // protoReq.GetSummaryBlock = true; //always set this to true because visibility keeps getting toggled from all of the mouse down/up events
        // protoReq.ProductVersion = 46;
        // protoReq.GetEarningLine = findGetSnapShotRequest.GetEarningLine;
        // protoReq.GetRevenueLine = findGetSnapShotRequest.GetRevenueLine;
        // return protoReq;
    }

    GetESSummaryBlockData(getSummaryBlockDataRequest) {
        const request = new GetSummaryBlockDataRequest(getSummaryBlockDataRequest);
        return BaseServiceApi.processServiceCall(request);
    }

    GetSnapshotData(findGetSnapShotRequest) {
        var request = new FindGetSnapShotRequest();
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindGetSnapShot_WCF"));
        const ProtoRequest = this.rayData["FindGetSnapShotRequest"];
        const protoReq = new ProtoRequest();

        protoReq.Symbol = findGetSnapShotRequest.Symbol;
        protoReq.EndDateTicks = findGetSnapShotRequest.endDate.getTime();
        protoReq.StartDateTicks = findGetSnapShotRequest.beginDate.getTime();
        protoReq.EndDateString = dateHelper.getDateString(findGetSnapShotRequest.endDate) + " " + dateHelper.getTimeString(findGetSnapShotRequest.endDate);
        protoReq.StartDateString = dateHelper.getDateString(findGetSnapShotRequest.beginDate) + " " + dateHelper.getTimeString(findGetSnapShotRequest.beginDate);
        protoReq.Osid = findGetSnapShotRequest.Osid;
        protoReq.SymbolType = findGetSnapShotRequest.SymbolType;
        protoReq.Periodicity = findGetSnapShotRequest.Periodicity;
        protoReq.IsWonOnly = findGetSnapShotRequest.IsWonOnly === "0" ? 0 : 1;
        protoReq.IsRevWonOnly = findGetSnapShotRequest.IsRevWonOnly === "0" ? 0 : 1;
        protoReq.EndDate = findGetSnapShotRequest.endDate;
        protoReq.StartDate = findGetSnapShotRequest.beginDate;
        protoReq.FcQuarters = findGetSnapShotRequest.FcQuarters;
        protoReq.TargetCurrencyCode = null;
        protoReq.FinTargetCurrencyCode = null;
        const targetCurrencyCode = findGetSnapShotRequest.TargetCurrencyCode;
        if (targetCurrencyCode) {
            if (targetCurrencyCode instanceof String) {
                protoReq.FinTargetCurrencyCode = findGetSnapShotRequest.TargetCurrencyCode === "GBP" ? "GBX" : (findGetSnapShotRequest.TargetCurrencyCode === "ZAR" ? "ZAX" : findGetSnapShotRequest.TargetCurrencyCode);
            }
            else if (targetCurrencyCode instanceof Object) {
                protoReq.FinTargetCurrencyCode = findGetSnapShotRequest.TargetCurrencyCode.Code === "GBP" ? "GBX" : (findGetSnapShotRequest.TargetCurrencyCode.Code === "ZAR" ? "ZAX" : findGetSnapShotRequest.TargetCurrencyCode.Code);
            }
        }
        protoReq.GetSummaryBlock = true; //always set this to true because visibility keeps getting toggled from all of the mouse down/up events
        protoReq.ProductVersion = 46;
        protoReq.GetEarningLine = findGetSnapShotRequest.GetEarningLine;
        protoReq.GetRevenueLine = findGetSnapShotRequest.GetRevenueLine;
        request.addParam("proto", protoReq.encode64());
        //const pacificOffset = dateHelper.getPacificOffset(findGetSnapShotRequest.EndDate.getTime());
        /* eslint-disable */
        return new Promise(function (resolve, reject) {
            /* eslint-enable */
            //    return new Promise((resolve, reject) => {
            BaseServiceApiWCF.processServiceCallNew(request).then((result) => {
                result.StockHeader.Prevolume = result.StockHeader.Prevolume ? result.StockHeader.Prevolume.toNumber() : 0;
                if (result) {
                    if (result.StockHeader) {
                        result.StockHeader.EpsDueDate = dateHelper.getUtcTicksfromTimeStamp(result.StockHeader.EpsDueDate);//, pacificOffset);
                        result.StockHeader.IpoDate = dateHelper.getUtcTicksfromTimeStamp(result.StockHeader.IpoDate);//, pacificOffset);
                        result.StockHeader.CreateTime = dateHelper.getUtcTicksfromTimeStamp(result.StockHeader.CreateTime);//, pacificOffset);
                        result.StockHeader.LastUpdateTime = dateHelper.getUtcTicksfromTimeStamp(result.StockHeader.LastUpdateTime);//, pacificOffset);
                        result.StockHeader.ExpiryDate = dateHelper.getUtcTicksfromTimeStamp(result.StockHeader.ExpiryDate);//, pacificOffset);
                    }
                    if (result.SummaryData) {
                        if (result.SummaryData.AnnualData.length > 0)
                            result.SummaryData.AnnualData = dataGraphStore.processAnnualDates(result.SummaryData.AnnualData);//, pacificOffset);
                        if (result.SummaryData.WeekData.length > 0)
                            result.SummaryData.WeekData = dataGraphStore.processWeekDates(result.SummaryData.WeekData);//, pacificOffset);
                        result.SummaryData.XDate = dateHelper.getUtcTicksfromTimeStamp(result.SummaryData.XDate);//, pacificOffset);
                        result.SummaryData.GrowthEarnDt = dateHelper.getUtcTicksfromTimeStamp(result.SummaryData.GrowthEarnDt);//, pacificOffset);
                        result.SummaryData.NavDate = dateHelper.getUtcTicksfromTimeStamp(result.SummaryData.NavDate);//, pacificOffset);
                        result.SummaryData.CreateTime = dateHelper.getUtcTicksfromTimeStamp(result.SummaryData.CreateTime);//, pacificOffset);
                        result.SummaryData.LastUpdateTime = dateHelper.getUtcTicksfromTimeStamp(result.SummaryData.LastUpdateTime);//, pacificOffset);
                        result.SummaryData.CurAeDate = dateHelper.getUtcTicksfromTimeStamp(result.SummaryData.CurAeDate);//, pacificOffset);
                        result.SummaryData.NxtAeDate = dateHelper.getUtcTicksfromTimeStamp(result.SummaryData.NxtAeDate);//, pacificOffset);
                    }
                    if (result.SummaryData && result.SummaryData.StockInfo) {
                        result.SummaryData.StockInfo.Osid = result.SummaryData.StockInfo.Osid.toNumber();
                        dataGraphStore.processStockInfoDates(result.SummaryData.StockInfo);
                    }
                    if (result.EpsData) {
                        dataGraphStore.processFinancialDates(result.EpsData);//, pacificOffset);
                    }
                    if (result.RpsResults) {
                        dataGraphStore.processFinancialDates(result.RpsResults);//, pacificOffset);
                    }
                    if (result.T4QResults && result.T4QResults.length>0) {
                        dataGraphStore.processFinancialDates(result.T4QResults[0].Results);//, pacificOffset);
                        dataGraphStore.processFinancialDates(result.T4QResults[1].Results);//, pacificOffset);
                        dataGraphStore.processFinancialDates(result.T4QResults[2].Results);//, pacificOffset);
                        dataGraphStore.processFinancialDates(result.T4QResults[3].Results);//, pacificOffset);
                    }
                    resolve(result);
                } else {
                    reject();
                }
            });
        });
    }
    FindGetDataGraph(findGetDatagraphRequest) {
        const request = new FindGetDatagraphRequest();
        request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("FindGetDataGraph_WCF"));
        var ProtoRequest = this.rayData["FindGetDataGraphRequest"];
        const protoReq = new ProtoRequest();
        protoReq.Symbol = findGetDatagraphRequest.Symbol;
        protoReq.EndDateTicks = findGetDatagraphRequest.endDate.getTime();
        protoReq.StartDateTicks = findGetDatagraphRequest.beginDate.getTime();
        if (findGetDatagraphRequest.MaxMASize){
            protoReq.MaxMASize = findGetDatagraphRequest.MaxMASize;   
        }
        protoReq.EndDateString = dateHelper.getDateString(findGetDatagraphRequest.endDate) + " " + dateHelper.getTimeString(findGetDatagraphRequest.endDate);
        protoReq.StartDateString = dateHelper.getDateString(findGetDatagraphRequest.beginDate) + " " + dateHelper.getTimeString(findGetDatagraphRequest.beginDate);
        protoReq.Periodicity = findGetDatagraphRequest.Periodicity;
        protoReq.GetStockHistory = findGetDatagraphRequest.GetStockHistory;
        protoReq.GetEPSHistory = findGetDatagraphRequest.GetEPSHistory;
        protoReq.GetBlackOutDates = findGetDatagraphRequest.GetBlackOutDates;
        protoReq.GetCorporateEvents = findGetDatagraphRequest.GetCorporateEvents;
        protoReq.GetEquityEvents = findGetDatagraphRequest.GetEquityEvents;
        protoReq.GetClosingIndex = findGetDatagraphRequest.GetClosingIndex;
        protoReq.GetOneMonthDaily = findGetDatagraphRequest.GetOneMonthDaily;
        protoReq.IndexSymbol = findGetDatagraphRequest.IndexSymbol;
        protoReq.Rs1Symbol = findGetDatagraphRequest.Rs1Symbol;
        protoReq.Rs2Symbol = findGetDatagraphRequest.Rs2Symbol;
        protoReq.GetRs1Symbol = findGetDatagraphRequest.Rs1Symbol !== findGetDatagraphRequest.IndexSymbol;
        protoReq.GetRs2Symbol = findGetDatagraphRequest.Rs2Symbol !== findGetDatagraphRequest.IndexSymbol;
        protoReq.FcQuartersEstimates = findGetDatagraphRequest.FcQuartersEstimates;
        protoReq.GetRTVolume = findGetDatagraphRequest.GetRTVolume;
        protoReq.GetRTHistory = findGetDatagraphRequest.GetRTHistory;
        protoReq.GetYTDPerformance = findGetDatagraphRequest.GetYTDPerformance ? true : false;
        protoReq.Get1YRPerformace = findGetDatagraphRequest.Get1YRPerformace ? true : false;
        protoReq.Get3YRPerformace = findGetDatagraphRequest.Get3YRPerformace ? true : false;
        protoReq.Get5YRPerformace = findGetDatagraphRequest.Get5YRPerformace ? true : false;
        protoReq.Get10YRPerformace = findGetDatagraphRequest.Get10YRPerformace ? true : false;
        protoReq.PerformanceToggle = findGetDatagraphRequest.GetYTDPerformanceValue ? findGetDatagraphRequest.GetYTDPerformanceValue : 0;
        protoReq.PerformanceToggle1 = findGetDatagraphRequest.Get1YRPerformanceValue ? findGetDatagraphRequest.Get1YRPerformanceValue : 0;
        protoReq.PerformanceToggle3 = findGetDatagraphRequest.Get3YRPerformanceValue ? findGetDatagraphRequest.Get3YRPerformanceValue : 0;
        protoReq.PerformanceToggle5 = findGetDatagraphRequest.Get5YRPerformanceValue ? findGetDatagraphRequest.Get5YRPerformanceValue : 0;
        protoReq.PerformanceToggle10 = findGetDatagraphRequest.Get10YRPerformanceValue ? findGetDatagraphRequest.Get10YRPerformanceValue : 0;
        //const pacificOffset = dateHelper.getPacificOffset(findGetDatagraphRequest.endDate.getTime());
        request.addParam("proto", protoReq.encode64());
        /* eslint-disable */
        return new Promise(function (resolve, reject) {
            /* eslint-enable */
            //    return new Promise((resolve, reject) => {
            BaseServiceApiWCF.processServiceCallNew(request).then((result) => {
                if (result) {
                    if (!StringUtil.isEmpty(result.HsfData))
                        result.HsfData.LastVolume = result.HsfData.LastVolume.toNumber();
                    if (result.StockInfo) {
                        result.StockInfo.Osid = result.StockInfo.Osid.toNumber();
                        dataGraphStore.processStockInfoDates(result.StockInfo);//, pacificOffset);
                    }
                    if (result.HsfData && result.HsfData.HSFResults) {
                        result.HsfData.HSFResults = dataGraphStore.processHSFResultsDates(result.HsfData.HSFResults);//, pacificOffset);
                        dataGraphStore.processHsfDataDates(result.HsfData);
                        if (result.HsfData.HSFRS1Results && result.HsfData.HSFRS1Results.length > 0)
                            result.HsfData.HSFRS1Results = dataGraphStore.processHSFResultsDates(result.HsfData.HSFRS1Results);//, pacificOffset);
                        if (result.HsfData.MAHSFResults && result.HsfData.MAHSFResults.length > 0)
                            result.HsfData.MAHSFResults = dataGraphStore.processHSFResultsDates(result.HsfData.MAHSFResults);//, pacificOffset);
                        if (result.HsfData.YTDResults && result.HsfData.YTDResults.length > 0)
                            result.HsfData.YTDResults = dataGraphStore.processHSFResultsDates(result.HsfData.YTDResults);//, pacificOffset);
                        if (result.HsfData.YTD1Results && result.HsfData.YTD1Results.length > 0)
                            result.HsfData.YTD1Results = dataGraphStore.processHSFResultsDates(result.HsfData.YTD1Results);//, pacificOffset);
                        if (result.HsfData.YTD3Results && result.HsfData.YTD3Results.length > 0)
                            result.HsfData.YTD3Results = dataGraphStore.processHSFResultsDates(result.HsfData.YTD3Results);//, pacificOffset);
                        if (result.HsfData.YTD5Results && result.HsfData.YTD5Results.length > 0)
                            result.HsfData.YTD5Results = dataGraphStore.processHSFResultsDates(result.HsfData.YTD5Results);//, pacificOffset);
                        if (result.HsfData.YTD10Results && result.HsfData.YTD10Results.length > 0)
                            result.HsfData.YTD10Results = dataGraphStore.processHSFResultsDates(result.HsfData.YTD10Results);//, pacificOffset);
                        if (result.HsfData.EndDateNode && result.HsfData.EndDateNode.DateTicks) {
                            result.HsfData.EndDateNode.Date = dateHelper.getUtcTicksfromTimeStamp(result.HsfData.EndDateNode.Date);//, pacificOffset);
                        }
                        if (result.HsfData.DDEDateNode && result.HsfData.DDEDateNode.DateTicks)
                            result.HsfData.DDEDateNode.Date = dateHelper.getUtcTicksfromTimeStamp(result.HsfData.DDEDateNode.Date);//, pacificOffset);
                        if (result.HsfData.BatsPrices && result.HsfData.BatsPrices.DateTicks)
                            result.HsfData.BatsPrices.Date = dateHelper.getUtcTicksfromTimeStamp(result.HsfData.BatsPrices.Date);//, pacificOffset);
                    }
                    if (result.HsfData && result.HsfData.EpsResults) {
                        dataGraphStore.processFinancialDates(result.HsfData.EpsResults);//, pacificOffset);
                    }
                    if (result.CorporateEvents) {
                        result.CorporateEvents.EquityEvents = dataGraphStore.processCorpEventsDates(result.CorporateEvents.EquityEvents);//, pacificOffset);
                        result.CorporateEvents.InsiderBuy = dataGraphStore.processCorpEventsDates(result.CorporateEvents.InsiderBuy);//, pacificOffset);
                        result.CorporateEvents.InsiderSell = dataGraphStore.processCorpEventsDates(result.CorporateEvents.InsiderSell);//, pacificOffset);
                        result.CorporateEvents.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.CorporateEvents.StartDate);//, pacificOffset);
                        result.CorporateEvents.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.CorporateEvents.EndDate);//, pacificOffset);
                        result.CorporateEvents.CreateDate = dateHelper.getUtcTicksfromTimeStamp(result.CorporateEvents.CreateDate);//, pacificOffset);

                    }
                    if (result.SplitFactors && result.SplitFactors.SplitItems)
                        result.SplitFactors.SplitItems = dataGraphStore.processSplitItems(result.SplitFactors.SplitItems);//, pacificOffset);
                    if (result.BlkOutDatesDict && result.BlkOutDatesDict.BlackOutDict)
                        dataGraphStore.processBlockOutItems(result.BlkOutDatesDict.BlackOutDict);//, pacificOffset);
                    if (result.EquityEvents && result.EquityEvents.EventDateItems) {
                        dataGraphStore.processEquityEvents(result.EquityEvents.EventDateItems);//, pacificOffset);                        
                    }
                    if (result.IndexClosingResults && result.IndexClosingResults.CloseResults && result.IndexClosingResults.CloseResults.length > 0) {
                        result.IndexClosingResults.CloseResults = dataGraphStore.processCloseResultDates(result.IndexClosingResults.CloseResults);//, pacificOffset);
                        result.IndexClosingResults.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.IndexClosingResults.StartDate);//, pacificOffset);
                        result.IndexClosingResults.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.IndexClosingResults.EndDate);//, pacificOffset);
                    }
                    if (result.Rs1ClosingResults && result.Rs1ClosingResults.CloseResults && result.Rs1ClosingResults.CloseResults.length > 0) {
                        result.Rs1ClosingResults.CloseResults = dataGraphStore.processCloseResultDates(result.Rs1ClosingResults.CloseResults);//, pacificOffset);
                        result.Rs1ClosingResults.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.Rs1ClosingResults.StartDate);//, pacificOffset);
                        result.Rs1ClosingResults.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.Rs1ClosingResults.EndDate);//, pacificOffset);
                    }
                    if (result.Rs2ClosingResults && result.Rs2ClosingResults.CloseResults && result.Rs2ClosingResults.CloseResults.length > 0) {
                        result.Rs2ClosingResults.CloseResults = dataGraphStore.processCloseResultDates(result.Rs2ClosingResults.CloseResults);//, pacificOffset);
                        result.Rs2ClosingResults.StartDate = dateHelper.getUtcTicksfromTimeStamp(result.Rs2ClosingResults.StartDate);//, pacificOffset);
                        result.Rs2ClosingResults.EndDate = dateHelper.getUtcTicksfromTimeStamp(result.Rs2ClosingResults.EndDate);//, pacificOffset);
                    }
                    resolve(result);
                } else {
                    reject();
                }

            });
        });
    }
    GetFinancialBlockPickListMetric(isQuarterly) {
        const request = new GetCfbMetricsRequest(isQuarterly);
        return BaseServiceApi.processServiceCall(request);
    }
    GetDefaultFinancialBlockHeaders(stockInfo, periodicity) {
        try
        {
            let request = new GetDefaultFinancialBlockHeadersRequest();
            request.addVerifyHeaders(BaseServiceApiWCF.getNewRequestId("GetDefaultFinancialBlockHeaders_WCF"));
    
            const ProtoRequest = this.rayData["StockInformation"];
            const protoReq = new ProtoRequest();
            protoReq.DefaultCurrency = stockInfo.DefaultCurrency;
    
            const ProtoRequestPeriodicity = this.rayData["Periodicity"];
            const protoReqPeriodicity = new ProtoRequestPeriodicity();
            protoReqPeriodicity.Periodicity = periodicity
    
            request.addParam("proto", protoReq.encode64());
            request.addParam("proto", protoReqPeriodicity.encode64());
    
            return new Promise((resolve, reject) => {
                BaseServiceApiWCF.processServiceCallNew(request).then((result) => {
                    if(result){
                        const headersResult = result.map((header) => {
                            const digitsAfterDecimal = header.DigitsAfterDecimal ? Number(header.DigitsAfterDecimal) : 0;
                            const toggleHeaderID = header.ToggleHeaderID ? Number(header.ToggleHeaderID) : undefined;
                            const headerObj = {
                                HeaderId: Number(header.HeaderId),
                                DisplayName: header.DisplayName,
                                CurrConvertedName: header.CurrConvertedName,
                                ReitsDisplayName: header.ReitsDisplayName,
                                NavDisplayName: header.NavDisplayName,
                                ToggleHeaderID: toggleHeaderID,
                                IsPrimary: Boolean(header.IsPrimary),
                                IsCalc: Boolean(header.IsCalc),
                                IsPerc: Boolean(header.IsPerc),
                                DigitsAfterDecimal: digitsAfterDecimal,
                                Dailyweeklysort: Number(header.Dailyweeklysort),
                                Monthlysort: Number(header.Monthlysort),
                                PickListName: header.PickListName,
                                HeaderScaleLabel: header.HeaderScaleLabel,
                                HasEstimateZone: Boolean(header.HasEstimateZone),
                                IsPeRow: Boolean(header.IsPeRow),
                                IsDataCurrencyConverted: Boolean(header.IsDataCurrencyConverted)
                            };
                            
                            return headerObj;
                        });

                        resolve(headersResult);
                    }   
                    else {
                        reject();
                    }
                });
            });
        } catch(ex) {
            console.error("Exception occurs in GetDefaultFinancialBlockHeaders.", ex);
        }
    }
    getStreetTargetPriceData(osid, currency){
        const targetCurrencyCode = currency === 'GBP'? 'GBX' : (currency === 'ZAR' ? 'ZAX' : currency);
        const request = new GetStreetTargetPriceData(osid, targetCurrencyCode);
        return BaseServiceApi.processServiceCall(request);
    }

    getPricePanelData(symbol, startdate, enddate, GraphType, RTVOlume, MaxMASize = 200, lineSettings = null, wononly, revwononly, selectCurrencyCode = null, isMatchFinancials = null, nodeCount = 0){
        const request = new GetPricePanelDataRequest(symbol, startdate, enddate, GraphType, RTVOlume, MaxMASize, lineSettings, wononly, revwononly, selectCurrencyCode, isMatchFinancials, nodeCount);
        return BaseServiceApi.processServiceCall(request);
    }
}

var chartApi = new ChartApi();
export default chartApi;
