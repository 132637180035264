import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { PriceChartConst } from "../../../../../../../Constants/PriceChartConstants";
import React from "react";
import { EPSAnimationMessageDialogTranslateHelper, TranslateHelper } from "../../../../../../../Utils/TranslateHelper";
import { openEPSAnimationDialog, toggleEpsAnimationMessageDialog, toggleEpsAnimationMessageDialogAvailable } from "../../../../../../../Actions/PricePanelActions";



function EarningsLineAnimationMessageDialog (props){
    return (
            <div id="EarningsLineMessageDialog">
                <Modal className="modal-popup earnings-line-message" show={props.isAnimationMessageDialogOpen}>
                    <Modal.Header className="medium-bold">
                        <Modal.Title className="medium-bold">
                            <div>
                                <span className="cap-header" style={{ textAlign: 'center' }}>{EPSAnimationMessageDialogTranslateHelper.EARNING_LINE_ANIMATION}</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="medium-normal">
                        <div className="inner-block" style={{ height: "auto", textAlign: 'center' }}>
                            <div className="inner-white-block">
                                <div className="w100p" style={{ textAlign: 'center', fontSize: '12px' }}>
                                    <div>{EPSAnimationMessageDialogTranslateHelper.CH_ER_ELA_MSG}</div>
                                    <div>{EPSAnimationMessageDialogTranslateHelper.CH_ER_ELA_MSG1}</div>
                                    <div>{EPSAnimationMessageDialogTranslateHelper.CH_ER_ELA_MSG2}</div>
                                </div>
                                <span><button type="button" className="view-setting-btn" onClick={props.onOpenEPSAnimationDialog}>{EPSAnimationMessageDialogTranslateHelper.VIEW_SETTINGS}</button></span>
                                <div>
                                    <input type='checkbox' name='EarningLinesDialogAvailable' checked={props.isAnimationMessageDialogAvailable}
                                        onChange={() => props.toggleEpsAnimationMessageDialogAvailable()}
                                    />
                                    <label style={{ fontSize: "12px" }}>{EPSAnimationMessageDialogTranslateHelper.DO_NOT_SHOW_AGAIN}</label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <center><button type="button" className="model-action btn btn-xs btn-default btn-secondary small-bold" onClick={props.closeAnimationMessageDialog}>{TranslateHelper.Close}</button></center>
                    </Modal.Footer>
                </Modal>
            </div>
        )
}

const mapStateToProps = ({ DatagraphReducers })=>{
    const { isAnimationMessageDialogAvailable, isAnimationMessageDialogOpen } = DatagraphReducers.FundamentalLineReducer;
    return { isAnimationMessageDialogAvailable, isAnimationMessageDialogOpen };
}

const mapDispatchToProps = (dispatch)=>({
    closeAnimationMessageDialog: ()=> dispatch(toggleEpsAnimationMessageDialog(false)),
    onOpenEPSAnimationDialog: ()=> dispatch(openEPSAnimationDialog({ lineType: PriceChartConst.EPS_ANI })),
    toggleEpsAnimationMessageDialogAvailable: ()=> dispatch(toggleEpsAnimationMessageDialogAvailable()),
})
export default connect(mapStateToProps, mapDispatchToProps)(EarningsLineAnimationMessageDialog);

