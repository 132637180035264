import BaseServiceApi from 'BaseServiceApi';
import DatagraphHelper from "../../../Utils/DatagraphHelper";
import GraphType from "../../../Constants/GraphType";
import { PriceChartConstants } from '../../../Constants/PriceChartConstants'
const { ActionTypes } = PriceChartConstants;
import SettingsStore from "SettingsStore";

const IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];
const initialState = {
    leftScaleWidth: 0,
    rightScaleWidth: 0,
    nodeWidth: 4,
    videoMode: false,
    periodicity: "",
    scale: {},
    nodeCount: 500,
    chartHeight: "",
    chartWidth: "",
    HiLowPoints: {},
    shadeData: [],
    isHistoric: false,
    TimeLine: [],
    corpeventsData: [],
    corpEvents: {},
    corpEventsDialogData: {},
    epsAnimationDialogData: {},
    earningsAnimationResults: [],
    epsAnimationSettings: "",
    indexDialogClose: false,
    corpEventsSettings: "",
    maLineData: [],
    streetTargetPricePointData: [],
    EpsLineData: [],
    RpsLineData: [],
    rpsData: [],
    T4QData: [],
    T4QLineData: [],
    EPsRPsDialogData: {},
    isReceivedQuote: false,
    StockInformation: {},
    showEps: "",
    multiplierUpdated: false,
    menuUpdated: false,
    newEpsMultiplier: 0,
    epsMultiplier: 20.0,
    rpsMultiplier: 5.0,
    isIntraday: false,
    prcPct: ' ',
    volPct: ' ',
    EPSAdded: false,
    PRData: {},
    FRData: [],
    baseStatus: 0,
    priceChange: 0,
    ticks: 0,
    units: ' ',
    OHtype: "OH",
    ShowVolume: true,
    EpsSwitch: false,
    refreshIdeas: false,
    scale1: {},
    scale2: {},
    priceAtLocation: 0,
    common: false,
    ExdTimeSeriesData: {},
    ExdEventSeriesData: {},
    isHoLoPriceVisible: false,
    isHoLoPctVisible: false,
    streetTargetPriceDialogData: {},
    isEXDReqInitiated: false,
    scaleLabel: "LOG (Fixed)",
    pricePanelData: {},
    endDate: '',
    endDateLabel: null,
    headerData: {},
    Symbol: '',
    padding: 0,
    majorPeriodicity: '',
    charts: {},
    startDate: new Date(),
    priceDataNotAvailable: false,
    priceDataReady: false,
    LastDdeData: {
        High: 0,
        Low: 0,
        Price: 0,
        Volume: 0,
        PrevVolume: 0,
        AverageVolume: 0,
        Symbol: "",
        IsESignal: false,
        DataAvailable: false,
        LiveData: false,
        HideVolume: false,
        RTVolRate: -101,
        RTWVolRate: -101,
        Periodicity: GraphType.NotAvailable,
        DailyVolume: 0,
        PrevDailyVolume: 0,
        Date: new Date()
    },
    QuoteSymbol: '',
    IdPushDown: false,
    IntradayBusy: false,
    firstTrade: false,
    PrevHigh: 0,
    PrevLow: 0,
    PrevVolume: 0,
    LastIdVolume: 0,
    CumVolume: 0,
    LastQuoteTime: undefined,
    LastTradeDate: undefined,
    TradeOpen: undefined,
    TradeClose: undefined,
    pauseRT: false,
    Restart: false,
    BlackOutDates: [],
    PrevDay: new Date(1826, 12, 31, 0, 0, 0),
    PickNewLH: false,
    InitialBufferSize: 50,
    IntradaySourceType: IntradaySourceType.IntradaySource_NASDAQ,
    selectIdeaType: {},
    showBox: false,
    dimension: { height: 0, width: 0, top: 0, left: 0, right: 0, bottom: 0, nodeCount: 0 }
}

const PriceChartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PRICE_DATA_READY: {
            const consoleSettings = SettingsStore.getConsoleSettings();
            const instSettings = DatagraphHelper.getSettingsObject(consoleSettings);
            const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
            return {
                ...state,
                leftScaleWidth: tabDataGraphSettings.LeftScaleWidth,
                rightScaleWidth: tabDataGraphSettings.RightScaleWidth,
                nodeWidth: tabDataGraphSettings.NodeWidth,
                videoMode: tabDataGraphSettings.videoMode,
                periodicity: action.periodicity,
                majorPeriodicity: action.majorPeriodicity,
                OHtype: instSettings.OffHighTag,
                oValue: instSettings.OffHighTag,
                HiLowPoints: action.hoLowData,
                chartHeight: action.chartHeight,
                chartWidth: action.chartWidth,
                pricePanelData: action.pricePanelData,
                isHoLoPriceVisible: instSettings.HiLoPriceSettings[action.majorPeriodicity].IsVisible,
                isHoLoPctVisible: instSettings.HiLoPctSettings[action.majorPeriodicity].IsVisible,
                endDate: action.endDate,
                endDateLabel: action.endDateLabel,
                headerData: action.headerData,
                isLineChart: action.isLineChart,
                Symbol: action.Symbol,
                startDate: action.startDate,
                scale: action.scale,
                scaleLabel: action.scaleLabel,
                oHigh: action.oHigh,
                oLow: action.oLow,
                isPriceDataReady: action.isPriceDataReady,
                TradeClose: action.TradeClose,
                TradeOpen: action.TradeOpen,
            };
        }
        case ActionTypes.SET_PRICE_SCALE:
            // state.scale = action.scale
            return {
                ...state,
                scale: action.scale,
            }
        case ActionTypes.SET_OH_OL_TYPE:
            return {
                ...state,
                OHtype: action.OHtype
            }
        case ActionTypes.CHART_RESIZE:
            return {
                ...state,
                dimension: action.dimension
            }
        case ActionTypes.HI_LO_SETTINGS_UPDATE:
            return {
                ...state,
                isHoLoPriceVisible: action.isHoLoPriceVisible
            }
        case ActionTypes.HI_LO_PCT_SETTINGS_UPDATE:
            return {
                ...state,
                isHoLoPctVisible: action.isHoLoPctVisible
            }
        case ActionTypes.UPDATE_PRICE_PANE_TIME_SERIES_DATA_TO_STORE:
            return {
                ...state,
                ExdTimeSeriesData: action.data
            }
        case ActionTypes.UPDATE_PRICE_PANE_EVENT_SERIES_DATA_TO_STORE:
            return {
                ...state,
                ExdEventSeriesData: action.data,
                isEXDReqInitiated: action.clearsEXDReqInitiated
            }
        case ActionTypes.UPDATE_EXTERNAL_DATA_REQ_INITIATED:
            return {
                ...state,
                isEXDReqInitiated: action.isEXDReqInitiated
            }
        case ActionTypes.SET_HSFDATA:
            return {
                ...state,
                HsfData: action.HsfData,
                isIntraday: action.isIntraday,
                nodeCount: action.nodeCount
            }
        case ActionTypes.UPDATE_CHARTS_REF:

            return {
                ...state,
                charts: { ...state.charts, [action.chartType]: action.chart }
            }
        case ActionTypes.UPATE_NODE_COUNT:
            return {
                ...state,
                nodeCount: action.nodeCount
            }
        case ActionTypes.UPDATE_IS_PRICE_DATA_READY:
            return {
                ...state,
                isPriceDataReady: action.isPriceDataReady,
            }
        
        default:
            return state;
    }
}
export default PriceChartReducer;