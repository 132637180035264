import Scale from "./Scale.js";
import PrefPeriodScaleConstants from "PrefPeriodScaleConstants";
import ExtremeDataValue from "ExtremeDataValue";
import GraphType from "GraphType";

export default class NewSLogPriceScale extends Scale {
    constructor(mStockInfo = null) {
        super();
        this.PsNewTargetInfo = null;
        this._mlPriceIncrement = 0.0;
        this.scaleStart = 0;
        this.Mnodes = 900;
        this.MaxScales = 30;

        this.PrScl0 = [];
        this.PrScl1 = [];
        this.PrScl2 = [];
        if (mStockInfo) {
            this.InitializedScale(mStockInfo);
        }
    }

    InitializedScale(stockinfo) {
        this.PsNewTargetInfo = stockinfo;
        this.PsNewTargetInfo.ScaleType = PrefPeriodScaleConstants.Auto;
        this.InitScale();
        this.SetHorizontalGrid();
    }

    ComputePrice(y) {
        const dGraphDistance = this.PsNewTargetInfo.VpHeight - y;
        let dPriceDiffFromBottom = dGraphDistance / this.Factor;
        dPriceDiffFromBottom += Math.log10(this.Lowpt);
        return Math.pow(10, dPriceDiffFromBottom);
    }


    ComputeY(dPrice) {
        let dPriceDiffFromBottom

        if (this.PsNewTargetInfo == null)
            return 0;

        if (dPrice <= 0) {
            // LogInfo of a negative or zero value would be bad...
            // dPriceDiffFromBottom = 0;
            return this.PsNewTargetInfo.BoxHeight + 100;
        }
        else {
            dPriceDiffFromBottom = Math.log10(dPrice) - Math.log10(this.Lowpt);
        }

        //SF: High dPrice somehow resulted in a double overflow
        if (!Number.isFinite(dPriceDiffFromBottom))
            return 0;

        return this.PsNewTargetInfo.VpHeight - dPriceDiffFromBottom * this.Factor;

    }

    ComputeYL(dPrice, dLow) {
        let dPriceDiffFromBottom

        if (this.PsNewTargetInfo == null)
            return 0;

        if (dPrice <= 0) {
            // LogInfo of a negative or zero value would be bad...
            // dPriceDiffFromBottom = 0;
            return this.PsNewTargetInfo.BoxHeight + 100;
        }
        else {
            dPriceDiffFromBottom = Math.log10(dPrice) - Math.log10(dLow);
        }

        //SF: High dPrice somehow resulted in a double overflow
        if (!Number.isFinite(dPriceDiffFromBottom))
            return 0;

        return this.PsNewTargetInfo.VpHeight - dPriceDiffFromBottom * this.Factor;
    }
    /*eslint-disable */
    Compute3Y(d1Value, d2Value, d3Value, d1Out, d2Out, d3Out) {
        const dPriceDiffFromBottom1 = Math.log10(d1Value) - Math.log10(this.Lowpt);
        d1Out = this.PsNewTargetInfo.VpHeight - dPriceDiffFromBottom1 * this.Factor;

        const dPriceDiffFromBottom2 = Math.log10(d2Value) - Math.log10(this.Lowpt);
        d2Out = this.PsNewTargetInfo.VpHeight - dPriceDiffFromBottom2 * this.Factor;

        const dPriceDiffFromBottom3 = Math.log10(d3Value) - Math.log10(this.Lowpt);
        d3Out = this.PsNewTargetInfo.VpHeight - dPriceDiffFromBottom3 * this.Factor;

    }
    /*eslint-enable */

    InitScale() {
        let mDif = this.PsNewTargetInfo.MaxPrice - this.PsNewTargetInfo.MinPrice;
        if ((this.PsNewTargetInfo.GraphType == GraphType.Weekly ||
            this.PsNewTargetInfo.GraphType == GraphType.Monthly ||
            this.PsNewTargetInfo.GraphType == GraphType.Quarterly ||
            this.PsNewTargetInfo.GraphType == GraphType.Annual) && mDif > 2) {
            this.InitMScale();
        }
        else {
            this.InitIScale();
        }
    }
    SetHorizontalGrid() {
        let mDif = this.PsNewTargetInfo.MaxPrice - this.PsNewTargetInfo.MinPrice;
        if ((this.PsNewTargetInfo.GraphType == GraphType.Weekly ||
            this.PsNewTargetInfo.GraphType == GraphType.Monthly ||
            this.PsNewTargetInfo.GraphType == GraphType.Quarterly ||
            this.PsNewTargetInfo.GraphType == GraphType.Annual) && mDif > 2) {
            this.SetHorizontalMGrid();
        }
        else {
            this.SetHorizontalIGrid();
        }
    }

    InitMScale() {
        let hiprc = this.PsNewTargetInfo.MaxPrice;
        let lowprc = this.PsNewTargetInfo.MinPrice;

        this.Mnodes = 1600;
        this.MaxScales = 40;
        this.Hipt = hiprc;
        this.Lowpt = lowprc;

        // Test the conversion Factor.

        let prDiff = Math.log10(this.Hipt) - Math.log10(this.Lowpt);

        if (prDiff < 0.1) {
            prDiff = 0.1;
        }

        this.Factor = parseFloat((this.PsNewTargetInfo.VpHeight) / prDiff);

        let yPr1 = this.ComputePrice(-60);
        let yPr2 = this.ComputePrice(this.PsNewTargetInfo.VpHeight + 60);

        this.Hipt = yPr1;// this.Hipt + (yPr1 - this.Hipt);
        this.Lowpt = yPr2;// this.Lowpt - (this.Lowpt - yPr2);

        // Calculate the factor with top and bottom margins
        this.Factor = parseFloat((this.PsNewTargetInfo.VpHeight) / (Math.log10(this.Hipt) - Math.log10(this.Lowpt)));

        let sp = [];
        let k = 0;
        let x = 10;

        this.PrScl0 = [];
        this.PrScl1 = [];
        this.PrScl2 = [];
        sp[0] = x;

        sp[0] = x;
        for (let i = 1; i <= this.MaxScales; i++) {
            if (x < 20)
                x = x + 1;
            else
                if (x < 50)
                    x = x + 2;
                else
                    if (x < 100)
                        x = x + 5;
            sp[i] = x;
        }
        for (let i = 1; i < this.MaxScales; i++) {
            let xl = parseFloat(Math.pow(10.0, (i - 5)));
            for (let j = 0; j <= this.MaxScales; j++) {
                this.PrScl0[k] = xl * sp[j];
                this.PrScl1[k] = parseFloat(Math.log10(this.PrScl0[k]));
                this.PrScl2[k] = 1;
                k++;
            }
        }
    }

    SetHorizontalMGrid() {
        this.MHLines = [];
        this.MHLabel = [];

        let bottomOfGraph = parseInt(this.PsNewTargetInfo.BoxHeight);
        //const topOfGraph = 10;

        let i = 0;
        if (!Number.isFinite(this.Lowpt)) return;
        while (i < this.Mnodes - 1 && this.PrScl0[i] < this.Lowpt) {
            i++;
        }
        let mLowpt = Math.max(i, 1);
        let pricePtr = mLowpt;
        let mlLowpt = this.PrScl1[mLowpt] - this.PrScl1[1];
        let YLowpt = parseFloat(mlLowpt * this.Factor);
        let py = this.ComputeY(this.PrScl0[pricePtr - 1]);
        pricePtr++;

        let phLabel = "";
        let pelabel = "";
        let prlabel = "";

        for (; pricePtr < this.Mnodes; pricePtr++) {
            if (this.PrScl2[pricePtr] != 1) {
                // No line at this point.
                continue;
            }

            let y;
            if (pricePtr == mLowpt)
                y = parseInt(bottomOfGraph + YLowpt);
            else
                y = parseInt(this.ComputeY(this.PrScl0[pricePtr]));

            if (Math.abs(py - y) < 10 || y < 25 || y + 10 > this.PsNewTargetInfo.VpHeight) continue;

            if (!this.MHLines.some((itm) => itm.y === y)) {
                let price = parseFloat(this.PrScl0[pricePtr]);
                let hLabel = "";
                let elabel = "";
                let rlabel = "";
                let T4QLabels = [];

                if (price < Number.MAX_VALUE) {
                    hLabel = price < 0 ? ""
                        : price > 999999 ? ExtremeDataValue.abbreviateValueFormat(price, 0, false)
                            : (price > 99999 ? ExtremeDataValue.abbreviateValueFormat(price, 0, false)
                                : (price < 0.01 ? ExtremeDataValue.abbreviateValueFormat(price, 4, false)
                                    : (price < 0.1 ? ExtremeDataValue.abbreviateValueFormat(price, 3, false)
                                        : Number(price.toFixed(2)).toString())));
                    //hLabel = price > 999999 ? ExtremeValueData.AbbreviateValue(price, false, false, 10000000, 1000000, 100000) :
                    //        (price > 4.9 ? extremeValueDataH.AbbreviatePriceScaleValueNoDup(price)
                    //      : (price > 0.99 ? price.ToString("#0.0#")
                    //      : price.ToString("#0.0##")));

                    if (this.PsNewTargetInfo.EpsMultiplier != null) {
                        let ePrice = Math.trunc((price * 1000.0 / this.PsNewTargetInfo.EpsMultiplier)) / 1000.0;
                        // Need to handle smaller numbers fixed (11/12/2010)
                        elabel = ePrice > 999999 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false) :
                            ePrice > 4.9 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false)
                                : (ePrice > 0.1 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 1, false)
                                    : Number(ePrice.toFixed(2)).toString());
                    }
                    if (this.PsNewTargetInfo.RpsMultiplier != null) {
                        let ePrice = Math.trunc((price * 1000.0 / this.PsNewTargetInfo.RpsMultiplier)) / 1000.0;
                        // Need to handle smaller numbers fixed (11/12/2010)
                        rlabel = ePrice > 999999 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false) :
                            ePrice > 4.9 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false)
                                : (ePrice > 0.1 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 1, false)
                                    : Number(ePrice.toFixed(2)).toString());
                    }
                    if (this.PsNewTargetInfo.T4QMultipliers != null && this.PsNewTargetInfo.T4QMultipliers.length < Number.MAX_VALUE) {
                        for (let item of this.PsNewTargetInfo.T4QMultipliers) {
                            let ePrice = Math.trunc((price * 1000.0 / item.Value)) / 1000.0;
                            // Need to handle smaller numbers fixed (11/12/2010)
                            if (Number.isFinite(ePrice)) {
                                let labelstring = ePrice > 999999
                                    ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false)
                                    : ePrice > 4.9
                                        ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false)
                                        : (ePrice > 0.1
                                            ? ExtremeDataValue.abbreviateValueFormat(ePrice, 1, false)
                                            : ExtremeDataValue.abbreviateValueFormat(ePrice, 2, false));
                                if (T4QLabels.some((itm) => itm.key === item.Key))
                                    T4QLabels = T4QLabels.filter((itm) => itm.key !== item.Key)
                                T4QLabels.push({ key: item.Key, labelstring: labelstring });
                            }
                            else {
                                if (T4QLabels.some((itm) => itm.key === item.Key))
                                    T4QLabels = T4QLabels.filter((itm) => itm.key !== item.Key)
                                T4QLabels.push({ key: item.Key, labelstring: "" });
                            }
                        }
                    }
                }
                else {
                    pricePtr = this.Mnodes + 1;
                }

                this.MHLines.push({
                    y: y,
                    LType: 1,
                    Label: hLabel != phLabel ? hLabel : " ",
                    eLabel: elabel != pelabel ? elabel : " ",
                    rLabel: rlabel != prlabel ? rlabel : " ",
                    YAxis: y,
                    YGap: py - y,
                    T4QLabels: T4QLabels
                });

                phLabel = hLabel;
                pelabel = elabel;
                prlabel = rlabel;
            }
            py = y;
        }
    }


    InitIScale() {
        //const adjt = 0.90;
        const iNumberOfIncrements = 10;
        if (this.PsNewTargetInfo.MaxPrice == this.PsNewTargetInfo.MinPrice) {
            this.PsNewTargetInfo.MaxPrice = this.PsNewTargetInfo.MaxPrice + this.PsNewTargetInfo.MaxPrice / 2;
            this.PsNewTargetInfo.MinPrice = this.PsNewTargetInfo.MinPrice - this.PsNewTargetInfo.MinPrice / 2;
        }

        let prInc = (this.PsNewTargetInfo.MaxPrice - this.PsNewTargetInfo.MinPrice) / iNumberOfIncrements;
        //let margin = prInc;

        if (prInc < 1 && prInc > 0.49)
            prInc = 0.50;
        else
            if (prInc < 0.50 && prInc > 0.24)
                prInc = 0.25;
            else
                if (prInc < 0.25 && prInc > 0.09)
                    prInc = 0.10;
                else
                    if (prInc < 0.1 && prInc > 0.009)
                        prInc = 0.05;
                    else
                        if (prInc < 0.01)
                            prInc = 0.005;
                        else
                            prInc = prInc - prInc % Math.pow(10, parseInt(Math.log10(prInc)));

        let hiprc = this.PsNewTargetInfo.MaxPrice;
        let lowprc = this.PsNewTargetInfo.MinPrice;

        this.Hipt = hiprc;
        this.Lowpt = lowprc;

        // Test the conversion Factor.
        this.Factor = parseFloat((this.PsNewTargetInfo.VpHeight) / (Math.log10(this.Hipt) - Math.log10(this.Lowpt)));

        let yPr1 = this.ComputePrice(-60);
        let yPr2 = this.ComputePrice(this.PsNewTargetInfo.VpHeight + 60);

        this.Hipt = yPr1;// this.Hipt + (yPr1 - this.Hipt);
        this.Lowpt = yPr2;// this.Lowpt - (this.Lowpt - yPr2);

        // Calculate the factor with top and bottom margins
        this.Factor = parseFloat((this.PsNewTargetInfo.VpHeight) / (Math.log10(this.Hipt) - Math.log10(this.Lowpt)));
        //this.Hipt = Math.Round(hiprc + margin, 4);
        //this.Lowpt = Math.Max(0.0001,Math.Round(lowprc - margin, 4));

        //// Compute the conversion Factor.
        //this.Factor = (float)((this.PsNewTargetInfo.VpHeight) / (Math.log10(this.Hipt) - Math.log10(this.Lowpt)));

        this.Hipt = Math.round((hiprc + prInc) / prInc) * prInc;


        //this.Hipt = Math.Ceiling(hiprc + prInc);
        this._mlPriceIncrement = prInc;
    }

    SetHorizontalIGrid() {
        this.MHLines = [];
        let isIntraday = this.PsNewTargetInfo.GraphType == GraphType.Intraday1Min ||
            this.PsNewTargetInfo.GraphType == GraphType.Intraday5Min ||
            this.PsNewTargetInfo.GraphType == GraphType.Intraday10Min ||
            this.PsNewTargetInfo.GraphType == GraphType.Intraday15Min ||
            this.PsNewTargetInfo.GraphType == GraphType.Intraday30Min ||
            this.PsNewTargetInfo.GraphType == GraphType.Intraday60Min;
        let py = 0;
        if (!Number.isFinite(this.Lowpt)) return;
        for (let pricePtr = this.Hipt; pricePtr > this.Lowpt; pricePtr -= this._mlPriceIncrement) {
            let y = this.ComputeY(pricePtr);

            if (this.MHLines.some((itm) => itm.y === y) ||
                Math.abs(py - y) < 10 || y < 25 || y + 10 > this.PsNewTargetInfo.VpHeight) continue;

            let hLabel = "";
            let elabel = "";
            let rlabel = "";
            let T4QLabels = [];


            hLabel = pricePtr > 999999
                ? ExtremeDataValue.abbreviateValueFormat(pricePtr, 0, false)
                : ExtremeDataValue.abbreviateValueFormat(pricePtr, 0, false);
            //(pricePtr > 4.9 ? extremeValueDataH.AbbreviatePriceScaleValueNoDup(pricePtr)
            //: (pricePtr > 0.99 ? pricePtr.ToString("#0.0#")
            //: pricePtr.ToString("#0.0##")));//);

            if (this.PsNewTargetInfo.EpsMultiplier != null && !isIntraday) {
                let ePrice = Math.trunc((pricePtr * 1000.0 / this.PsNewTargetInfo.EpsMultiplier)) / 1000.0;
                //let ePrice = (float)(pricePtr / this.PsNewTargetInfo.EpsMultiplier);
                // Need to handle smaller numbers fixed (11/12/2010)
                elabel = ePrice > 999999 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false) :
                    ePrice > 4.9 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false)
                        : (ePrice > 0.1 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 1, false)
                            : ExtremeDataValue.abbreviateValueFormat(ePrice, 2, false));
            }
            if (this.PsNewTargetInfo.RpsMultiplier != null && !isIntraday) {
                let ePrice = Math.trunc((pricePtr * 1000.0 / this.PsNewTargetInfo.RpsMultiplier)) / 1000.0;
                //let ePrice = (float)(pricePtr / this.PsNewTargetInfo.RpsMultiplier);
                // Need to handle smaller numbers fixed (11/12/2010)

                rlabel = ePrice > 999999 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false) :
                    ePrice > 4.9 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false)
                        : (ePrice > 0.1 ? ExtremeDataValue.abbreviateValueFormat(ePrice, 2, false)
                            : ExtremeDataValue.abbreviateValueFormat(ePrice, 3, false));
            }
            if (this.PsNewTargetInfo.T4QMultipliers != null && !isIntraday) {


                for (let item of this.PsNewTargetInfo.T4QMultipliers) {
                    let ePrice = Math.trunc((pricePtr * 1000.0 / item.Value)) / 1000.0;
                    // Need to handle smaller numbers fixed (11/12/2010)
                    if (Number.isFinite(ePrice)) {
                        let labelstring = ePrice > 999999
                            ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false)
                            : ePrice > 4.9
                                ? ExtremeDataValue.abbreviateValueFormat(ePrice, 0, false)
                                : (ePrice > 0.1
                                    ? ExtremeDataValue.abbreviateValueFormat(ePrice, 2, false)
                                    : ExtremeDataValue.abbreviateValueFormat(ePrice, 3, false));
                        if (T4QLabels.some((itm) => itm.key === item.Key))
                            T4QLabels = T4QLabels.filter((itm) => itm.key !== item.Key)
                        T4QLabels.push({ key: item.Key, labelstring: labelstring });
                    }
                    else {
                        if (T4QLabels.some((itm) => itm.key === item.Key))
                            T4QLabels = T4QLabels.filter((itm) => itm.key !== item.Key)
                        T4QLabels.push({ key: item.Key, labelstring: "" });
                    }
                }



            }

            this.MHLines.push({
                y: y,
                LType: 1,
                Label: hLabel,
                eLabel: elabel,
                rLabel: rlabel,
                YAxis: y,
                YGap: py - y,
                T4QLabels: T4QLabels
            });

            py = y;
        }
    }


    SetGrid() {
        this.SetHorizontalGrid();
    }
}


