import { DataGraphConstants } from "../Constants/DataGraphConstants";
const { ActionTypes } = DataGraphConstants;

export const changeEPSFormat = () => ({
    type: ActionTypes.CHANGE_EPS_FORMAT
});
export const changeIPOFormat = () => ({
    type: ActionTypes.CHANGE_IPO_FORMAT
});

export const isGICSSelected = (data) => ({
    type: ActionTypes.GICS_SELECTED,
    data
});

export const MetricActionAlert = (data,definationSymbol="") => ({
    type: ActionTypes.VIEW_METRIC_DEF,
    data,
    definationSymbol
});

export const updateHeaderStates = (states)=>({
    type: ActionTypes.UPDATE_HEADER_STATES,
    states
});

export const SwitchexpiryDate = ()=>({
    type: ActionTypes.SWITCH_FUTURE_EXPITY_DATE
});