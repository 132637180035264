import Scale from "./Scale.js";

class LogVolumeScale extends Scale {
    getObjectMapKey() {
        return "LogVolumeScale";
    }
    //constructor(Factor, MLBaseVolumeForPlot)
    constructor(Factor) {
        super(Factor);
        this.MLBaseVolumeForPlot = 0;
    }
    GetVolInc(price) {
        var pr2 = Math.floor(price);
        var pr0 = Math.floor(this.log10(pr2));
        var pr1 = Math.floor(Math.pow(10, pr0));
        var pr3 = pr2 % pr1;
        var inc = pr2 - pr3;
        return inc;
    }
    SetHorizontalGrid(mhLines, minVolume, maxVolume, avolume, height) {
        if (mhLines.Length > 0)
            mhLines.Clear();
        if (maxVolume <= 0) {
            maxVolume = 100;
            minVolume = 10;
        }
        this.Height = height;
        var maxVol = Math.max(maxVolume, 80);
        var mva = avolume;
        var boxSp = height / 8;
        var hBottom = height - 25;

        if (maxVolume == minVolume)
            minVolume = maxVolume * 0.1;

        this.MLBaseVolumeForPlot = mva > 100 ? this.log10(minVolume * 6 / 7) : 0;
        this.Factor = maxVolume <= 0 ? 1 : hBottom / (this.log10(maxVol) - this.MLBaseVolumeForPlot);

        var lct = height - boxSp;
        var prc = this.ComputeVolume(lct, height);
        prc = this.GetVolInc(prc);//prc - prc % Math.pow(10, log10(prc));
        var prv = prc * 10;

        var y;
        var yp = height + 10;

        if(!isFinite(lct)) return;
        while (lct > 0) {
            lct -= boxSp;
            prc = this.ComputeVolume(lct, height);
            prc = this.GetVolInc(prc);//prc - prc % Math.pow(10, log10(prc));
            y = this.ComputeY(prc, height);

            var label = prc;

            if (prc > 999 && prc < 1000000) {
                label = (prc / 1000) + "K";
            }
            else
                if (prc > 999999 && prc < 1000000000) {
                    label = (prc / 1000000) + "M";
                }
                else
                    if (prc > 999999999) {
                        label = (prc / 1000000000) + "B";
                    }

            if (prv == label) continue;
            prv = label;

            if (yp - y < 10 || y < 25) continue;

            var eLine =
            {
                Type: 2,
                Label: label,
                eLabel: " ",
                YAxis: y
            };

            mhLines.push(eLine);
            yp = y;
        }
    }
    ComputeY(lVolume) {
        var dVolumeAdjusted = lVolume;

        var dLogVolume;

        if (dVolumeAdjusted < 1.0)
            dLogVolume = 0;
        else
            dLogVolume = this.log10(dVolumeAdjusted) - this.MLBaseVolumeForPlot;

        if (dLogVolume < 0) {
            dLogVolume = 0.05;
        }
        if (dLogVolume <= 0.02 && dLogVolume > 0) {
            dLogVolume = 0.1;
        }

        var dVolumeHeight = dLogVolume * this.Factor;

        var iYCoord = Math.round(this.Height - (lVolume < 0 ? 0 : dVolumeHeight), 2);
        return Math.max(1, iYCoord);
    }
    ComputeVolume(y) {
        var dGraphDistance = this.Height - y;

        var dPriceDiffFromBottom = dGraphDistance / this.Factor;
        dPriceDiffFromBottom += this.MLBaseVolumeForPlot;

        return Math.pow(10, dPriceDiffFromBottom);
    }
    ComputePrice(y) {
        var dGraphDistance = this.Height - y;

        var dPriceDiffFromBottom = dGraphDistance / this.Factor;
        dPriceDiffFromBottom += this.MLBaseVolumeForPlot;

        return Math.pow(10, dPriceDiffFromBottom);
    }
}

export default LogVolumeScale;
