import React from "react";
import PeriodicityControl from "PeriodicityControl";
import PeriodicityHelper from "PeriodicityHelper";
import TabType from "Constants/TabType.js";
import ErrorBoundary from "ErrorBoundary";
import { updateNupSinglePeriodicity, updateNupStore, updatePeriodicityButtonState, processNupListData } from "../../../../Actions/NupViewActions.js";
import { connect } from "react-redux";
import ONeilViewStore from "ONeilViewStore";
import ListManagerTabType from "ListManagerTabType";
import ListStore from "ListStore";
import GridStore from "GridStore";
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";

class BottomBar extends React.Component {
  constructor(props) {
    super(props);
    this.listStoreChange = this.listStoreChange.bind(this)
    this.state = {
      isOneilList: ListStore.getNavListManagerSettings().SelectedTabKey === ListManagerTabType.Oneil
    }
    this.periodicityBtnClicked = false;
  }
  componentDidMount(){
    ListStore.addChangeListener(this.listStoreChange);
  }

  componentWillUnmount(){
    ListStore.removeChangeListener(this.listStoreChange);
  }

  listStoreChange(){
    const _currentAction = ListStore.getCurrentAction()
    if(_currentAction === SettingsConstants.ActionTypes.LIST_EXPLORER_UPDATE_TAB){
           this.setState({
             isOneilList: ListStore.getselectedTabKey() === ListManagerTabType.Oneil
           })
    }
  }
  onPeriodicityChange(periodicity) {
    GridStore.resetSelectedIndexes();
    this.props.updatePeriodicityButtonState(true);
    this.periodicityBtnClicked = true;
    let NumValue = PeriodicityHelper.getGraphTypeFromPeriodicity(periodicity);
    this.props.updateNupSinglePeriodicity(NumValue);
    const symbolList = ONeilViewStore.getSourceData();
    const selectedSymbol = ListStore.getSelectedSymbol()
    this.props.processNupListData(symbolList, true, selectedSymbol)
  }

  getPeriodicityControl() {
    let periodicityControl = undefined;
    if(this.props.showChart){
      periodicityControl = <ErrorBoundary>
          <PeriodicityControl
            buttonSource={this.props.PeriodicityOptions}
            periodicityBtnClicked={this.periodicityBtnClicked}
            disablePeriodicityButton={this.props.disablePeriodicityButton}
            onPeriodicityChange={(periodicity) => this.onPeriodicityChange(periodicity)}>
          </PeriodicityControl>
        </ErrorBoundary>;
    }
    // switch (state.SelectedTabKey) {
    //   case TabType.NavList:
    //     periodicityControl = <ErrorBoundary>
    //       <PeriodicityControl
    //         buttonSource={this.props.state.PeriodicityOptions}
    //         periodicityBtnClicked={this.periodicityBtnClicked}
    //         disablePeriodicityButton={this.props.state.disablePeriodicityButton}
    //         onPeriodicityChange={(periodicity) => this.onPeriodicityChange(periodicity)}>
    //       </PeriodicityControl>
    //     </ErrorBoundary>;
    //     break;
    //   default:
    // }
    return periodicityControl;
  }

  render() {
    const periodicityControl = this.getPeriodicityControl();
    const selectedLayout = this.props.nupCharts.filter((t) => t.sel === "icn-select")[0];
    const showButtons = selectedLayout.per.length < 1 && this.props.showChart && this.state.isOneilList && !this.props.isNonImported;

      if (!showButtons) {
          return (<div />);
      }
      else {
          return (
              <div className="actionButtonWrap">
                  {periodicityControl}
              </div>);
      }
  }
}
const mapStateToProps = ({ listViewReducer }) => {
  const { NupViewReducer } = listViewReducer;

  return ({nupCharts: NupViewReducer.nupCharts, showChart: NupViewReducer.showChart, PeriodicityOptions: NupViewReducer.PeriodicityOptions, disablePeriodicityButton: NupViewReducer.disablePeriodicityButton, isNonImported: NupViewReducer.isNonImported})
}

const mapDispactchToProps = (dispatch) =>({
  updatePeriodicityButtonState: (disablePeriodicityButton) => dispatch(updatePeriodicityButtonState(disablePeriodicityButton)),
  updateNupSinglePeriodicity: (periodicity) => dispatch(updateNupSinglePeriodicity(periodicity)),
  updateNupStore: ()=> dispatch(updateNupStore()),
  processNupListData: (symbolList, isInitChart, selectedSymbol)=> dispatch(processNupListData(symbolList, isInitChart, selectedSymbol))
})

export default connect(mapStateToProps, mapDispactchToProps)(BottomBar);