import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class HubGetSalesRepsRequest extends BaseServiceRequest {
    constructor(email, osid, quarterenddate) {
        super();
        super.addRequestUri("getsalesreps.pb");
        super.addRequestParameter("email", email)
        super.addRequestParameter("osid", osid)
        super.addRequestParameter("quarterenddate", quarterenddate);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.HUB;
    }

    getMessageName() {
        return "HubSalesRepResponse"
    }
}

export default HubGetSalesRepsRequest