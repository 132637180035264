import { each, indexOf } from 'underscore';
import AlertConditionType from '../../Constants/AlertConditionType';
import { AlertConstants } from '../../Constants/AlertConstants';
import AlertSourceType from '../../Constants/AlertSourceType';
import ContextMenuConstants from '../../Constants/ContextMenuConstants';
import StringUtil from '../../Utils/StringUtil';

const dialogInitialState = {
    isAndAlertDialogOpen: false,
    isPriceAlertDialogOpen: false,
    isOpenAlertCriteria: false,
    isGeographyFilterOpen: false,
    isColumnFilterOpen: false,

    msId: '',
    instrumentId: '',
    symbol: '',
    sourceTypeId: AlertSourceType.Stock,
    conditionTypeId: AlertConditionType.Or,
    showLoader: true,
    isTriggered: false,
    alertId: '',
    currencyCode: 'USD',
    instrumentType: null,

    alertObj: null,
    isEditing: false,
    price: '',
    xPos: 0,
    yPos: 0
}
const initialState = {
    ...dialogInitialState,
    alertDataObj: {
        alertTitle: "",
        alertSubTitle: "",
        sourceId: null,
        sourceTypeId: AlertSourceType.Stock,
        alerts: {},
        countryCollection: [],
        selectedFilterItems: [],
        browserFilterText: '',
        browserFilterSelectedIDs: '',
        columnsetCollection: [],
        selectedColumnSet: [],
        selectedColSetString: '',
        columnsetChildItems: [],
        columnsetTreeNodes: [],
        parentColumnSetName: '',
        listAlertSettings: {},
        priceVolumeAlertable: true,
        addRemoveAlertable: false,
        corporateEventAlertable: false,
        priceAlert: null,
        messageCenterState: false,
        listName: '',
        isActiveAlerts: {},
    },
    browserFilterText: '',
    listAlertSettings: {},
    columnsetCollection: [],
    selectedColumnSet: [],
    columnsetChildItems: [],
    parentColumnSetName: '',
    priceAlert: undefined,
    selectedColSetString: '',
    priceVolumeAlertable: false,
    addRemoveAlertable: false,
    corporateEventAlertable: false,
    listAlertSubTitle: '',
    TargetPrice: '',
    TargetPriceSelected: '',
    listName: '',
    alertMessage: '',
    isActiveAlerts: {},
    showLoader: true,
    alertTitle: '',
}

const AlertDialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case AlertConstants.ActionTypes.OPEN_AND_ALERT_DIALOG:
            return {
                ...state,
                isAndAlertDialogOpen: true,
                msId: action.msId,
                instrumentId: action.instrumentId,
                symbol: action.symbol,
                sourceTypeId: action.sourceTypeId,
                conditionTypeId: action.conditionTypeId,
                alertId: action.alertId,
                currencyCode: action.currencyCode,
                showLoader: true,
                isTriggered: action.isTriggered,
                instrumentType: action.instrumentType
            }
        case AlertConstants.ActionTypes.OPEN_OR_ALERT_DIALOG:
            return {
                ...state,
                isOpenAlertCriteria: true,
                msId: action.msId,
                instrumentId: action.instrumentId,
                symbol: action.symbol,
                sourceTypeId: action.sourceTypeId,
                conditionTypeId: action.conditionTypeId,
                alertId: action.alertId,
                currencyCode: action.currencyCode,
                showLoader: true,
                isTriggered: action.isTriggered,
                instrumentType: action.instrumentType
            }
        case AlertConstants.ActionTypes.CLOSE_ALERT_DIALOG:
            return {
                ...state,
                ...dialogInitialState,
            }
        case AlertConstants.ActionTypes.OPEN_PRICE_ALERT_DIALOG:
            return {
                ...state,
                isPriceAlertDialogOpen: true,
                alertObj: action.alertObj,
                isEditing: action.isEditing,
                price: action.price,
                xPos: action.xPos,
                yPos: action.yPos
            }
        case AlertConstants.ActionTypes.OPEN_CRITERIA_DIALOG:
            return {
                ...state,
                isOpenAlertCriteria: true,
                alertCriteriaListSettings: { ...state.listAlertSettings }
            }
        case AlertConstants.ActionTypes.CANCEL_CRITERIA_DIALOG:
            return {
                ...state,
                isOpenAlertCriteria: false
            }
        case AlertConstants.ActionTypes.CANCEL_CONDITION_ALERT_DELETE:
            return {
                ...state,
                showCommonDialog: false,
                alertMessage: '',
            }
        case AlertConstants.ActionTypes.CONDITION_ALERT_DELETE:
            return {
                ...state,
                showCommonDialog: true,
                alertMessage: action.item === ContextMenuConstants.AND_CONDITION_ALERTS ? 'Do you want to delete All And-condition alerts?' : 'Do you want to delete All Or-condition alerts?',
                conditionTypeId: action.item === ContextMenuConstants.AND_CONDITION_ALERTS ? AlertConditionType.And : AlertConditionType.Or,
            }
        case AlertConstants.ActionTypes.SAVE_CRITERIA_DIALOG:
            return {
                ...state,
                listAlertSettings: { ...action.listAlertSettings },
                priceAlert: action.priceAlert,
                TargetPrice:action.priceAlert!==null?action.priceAlert.targetPrice:null,
                TargetPriceSelected:action.priceAlert !== null ? true : false,
                isOpenAlertCriteria: false
            }
        case AlertConstants.ActionTypes.UPDATE_ALERT_DATA_OBJECT:
            return {
                ...state,
                alertDataObj: action.alertDataObj,
                browserFilterText: action.alertDataObj.browserFilterText,
                listAlertSettings: action.alertDataObj.listAlertSettings,
                alertCriteriaListSettings: { ...action.alertDataObj.listAlertSettings },
                priceAlert: action.alertDataObj.priceAlert,
                selectedColSetString: action.alertDataObj.selectedColSetString,
                priceVolumeAlertable: action.alertDataObj.priceVolumeAlertable,
                addRemoveAlertable: action.alertDataObj.addRemoveAlertable,
                corporateEventAlertable: action.alertDataObj.corporateEventAlertable,
                listAlertSubTitle: action.alertDataObj.listAlertSubTitle,
                alertTitle: action.alertDataObj.alertTitle,
                listName: action.alertDataObj.listName,
                isActiveAlerts: action.alertDataObj.isActiveAlerts,
                countryCollection: action.alertDataObj.countryCollection,
                columnsetCollection: action.alertDataObj.columnsetCollection,
                selectedColumnSet: action.alertDataObj.selectedColumnSet,
                parentColumnSetName: action.alertDataObj.parentColumnSetName,
                columnsetChildItems: action.alertDataObj.columnsetChildItems,
                TargetPrice: state.conditionTypeId === AlertConditionType.And && action.alertDataObj.priceAlert && state.sourceTypeId === AlertSourceType.Stock ? action.alertDataObj.priceAlert.targetPrice : null,
                TargetPriceSelected: state.conditionTypeId === AlertConditionType.And && action.alertDataObj.priceAlert && state.sourceTypeId === AlertSourceType.Stock ? true : false,
                showLoader: action.showLoader,
            }
        case AlertConstants.ActionTypes.OPEN_GEOGRAPHY_FILTER:
            return {
                ...state,
                filterXPos: action.filterXPos,
                filterYPos: action.filterYPos,
                isGeographyFilterOpen: true
            }
        case AlertConstants.ActionTypes.OPEN_COLUMN_FILTER:
            return {
                ...state,
                filterXPos: action.filterXPos,
                filterYPos: action.filterYPos,
                isColumnFilterOpen: true
            }
        case AlertConstants.ActionTypes.CLOSE_FILTER_DIALOG:
            return {
                ...state,
                isColumnFilterOpen: false,
                isGeographyFilterOpen: false
            }
        case AlertConstants.ActionTypes.SELECT_FILTER_BROWSER: {
            const selectedIDsArr = action.nodeFilterId.split(',');

            each(state.alertDataObj.countryCollection, (item) => {
                if (indexOf(selectedIDsArr, item.itemId.toString()) !== -1) {
                    item.isSelected = true;
                }
                else {
                    item.isSelected = false;
                }
            });

            state.alertDataObj.browserFilterText = action.filterName;
            state.alertDataObj.browserFilterSelectedIDs = action.nodeFilterId;
            return {
                ...state,
                browserFilterText: action.filterName,
            }
        }
        case AlertConstants.ActionTypes.SELECT_CLOSEST_CHILDREN: {
            state.alertDataObj.columnsetChildItems = [];
            state.alertDataObj.columnsetTreeNodes.forEach((item) => {
                if (item.nodeId.low === action.columnSetDetails.colsetId) {
                    item.childNodes.forEach((itm) => {
                        const colsetdata = { colsetId: itm.nodeId.low, colsetName: itm.name };
                        if (colsetdata.colsetId === state.alertDataObj.selectedColumnSet.colsetId) {
                            colsetdata.isSelected = true;
                        }
                        state.alertDataObj.columnsetChildItems.push(colsetdata);
                    });
                }
            });
            return {
                ...state,
                columnsetChildItems: [...state.alertDataObj.columnsetChildItems],
                parentColumnSetName: action.columnSetDetails.colsetName
            }
        }
        case AlertConstants.ActionTypes.SELECT_ALERT_COLUMN_SET: {
            if (!StringUtil.isEmpty(state.alertDataObj.columnsetCollection)) {
                state.alertDataObj.columnsetCollection.forEach((colset) => {
                    colset.isSelected = false;
                });
            }

            if (!StringUtil.isEmpty(state.alertDataObj.columnsetChildItems)) {
                state.alertDataObj.columnsetChildItems.forEach((colset) => {
                    colset.isSelected = false;
                });
            }

            if (action.columnSetDetails !== null && action.columnSetDetails.ColsetId !== -1) {
                action.columnSetDetails.isSelected = true;
                state.alertDataObj.selectedColumnSet = action.columnSetDetails;
                state.alertDataObj.selectedColSetString = action.columnSetDetails.colsetName;
                state.alertDataObj.parentColumnSetName = action.parentColSetName;
            }
            return {
                ...state,
                selectedColSetString: action.columnSetDetails.colsetName,
                parentColumnSetName: action.parentColSetName
            }
        }
        default:
            return state;
    }
}
export default AlertDialogReducer;