var TechnicalIndicator = {
                  NotAvailable: "NotAvailable",
                  Datagraph: "Datagraph",
                  Rs3Months: "Rs3Months",
                  Rs6Months: "Rs6Months",
                  Rs12Months: "Rs12Months",
                  GrpRs3Months: "GrpRs3Months",
                  GrpRs6Months: "GrpRs6Months",
                  InstSupDem: "InstSupDem",
                  SMR: "SMR",
                  Sponsorship: "Sponsorship",
                  Composite: "Composite",
                  EpsRank: "EpsRank",
                  Settings: "Settings",
                  Appearance: "Appearance",
                  About: "About"
                }
export default TechnicalIndicator;