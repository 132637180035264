import { DgHoldingsActionConstants } from "../../Constants/DgHoldingsActionConstants";

const { ActionTypes } = DgHoldingsActionConstants;

const initialState = {
    osid: -1,
    symbol: '',
    headerData: [],
    reportExtData: [],
    searchText: '',
    textSize: "xx-small-normal",
    limitData: true,
    isColumnRsChanged: true,
    isColumnPortChanged: true,
    isColumnSharesChanged: true,
    isLoading: true,
    orderSeqArr: [{ columnName: "PctOfPort", order: "desc" }],
    mainState: { currentTab: 0, currentSubTab: 0, orderString: "PctOfPort desc" },
    subCategory: { ByTopHoldingsActive: "active", ByPositionTypeActive: "" },
    limitDataCompleted : false,
    msid:-1,
    resizeCounter: 1
}

export default function DgHoldingsReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.DG_HOLDINGS_GET_REPORT_DATA_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData.holdingReportHeader, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid, symbol: action.result.symbol, searchText: '', msid: action.result.msid, limitDataCompleted : action.result.limitDataCopy };
        case ActionTypes.DG_HOLDINGS_CLEAR_DATA:
            return { ...state, reportExtData: [], headerData: [], limitData: true, 
                               subCategory: { ByTopHoldingsActive: "active", ByPositionTypeActive: "" }, 
                               isLoading: true, orderSeqArr: [{ columnName: "PctOfPort", order: "desc" }], osid: -1, 
                               symbol: '', searchText: '', msid: -1, limitDataCompleted : false };
        case ActionTypes.DG_HOLDINGS_REPORT_CHANGE_TEXT_SUCCESS:
            return { ...state, textSize: action.result };
        case ActionTypes.DG_HOLDINGS_TAB_RESIZE_SUCCESS:
            return { ...state, resizeCounter: state.resizeCounter + 1 };
        case ActionTypes.DG_HOLDINGS_COLUMN_RS_CHANGED:
            return { ...state, isColumnRsChanged: action.result };
        case ActionTypes.DG_HOLDINGS_SEARCH_TEXT_CHANGE:
            return { ...state, searchText: action.result };
        case ActionTypes.DG_HOLDINGS_COLUMN_PORT_CHANGED:
            return { ...state, isColumnPortChanged: action.result };
        case ActionTypes.DG_HOLDINGS_COLUMN_SHARES_CHANGED:
            return { ...state, isColumnSharesChanged: action.result };
        case ActionTypes.DG_HOLDINGS_SHOW_LOADER:
            return { ...state, isLoading: action.result }
        case ActionTypes.DG_HOLDINGS_UPDATE_FLAG_SUCCESS:            
            return { ...state, reportExtData: action.result.resultData };
            //return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid,symbol:action.result.symbol, searchText: action.result.limitData ? '' : state.searchText }
        case ActionTypes.DG_HOLDINGS_LOAD_FLAG_INFO_SUCCESS:            
            return { ...state, reportExtData:action.result.resultData, limitData: action.result.limitData, limitDataCompleted : true };
        case ActionTypes.DG_HOLDINGS_GET_REPORT_MORE_DATA_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, limitData: action.result.limitData, limitDataCompleted : true }
        case ActionTypes.DG_HOLDINGS_COLUMN_SORT_CHANGED_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData.holdingReportHeader, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid, symbol: action.result.symbol, msid: action.result.msid, limitDataCompleted : action.result.limitDataCopy }
        case ActionTypes.DG_HOLDINGS_LIMIT_CHANGE_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData.holdingReportHeader, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid, symbol: action.result.symbol, searchText: '', msid: action.result.msid, limitDataCompleted : false }
        case ActionTypes.DG_HOLDINGS_TAB_CHANGE_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData.holdingReportHeader, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid, symbol: action.result.symbol, searchText: '', msid: action.result.msid, limitDataCompleted : action.result.limitDataCopy }
        case ActionTypes.DG_HOLDINGS_SUB_TAB_CHANGE_SUCCESS:
            return { ...state, reportExtData: action.result.resultData, headerData: action.result.headerData.holdingReportHeader, limitData: action.result.limitData, mainState: action.result.mainState, subCategory: action.result.subCategory, isLoading: false, orderSeqArr: action.result.orderSeqArr, osid: action.result.osid, symbol: action.result.symbol, searchText: '', msid: action.result.msid, limitDataCompleted : action.result.limitDataCopy }
            
        default:
            return state;
    }
}
