import React from "react";
export const ToolTip = (props) => {
  let {
    style,
    children
  } = props;

  return (
    <div id="overload-bottom" className="fade in tooltip bottom" style={{...style}}>
      <div className="tooltip-arrow"/>
      <div className="tooltip-inner">
        {children}
      </div>
    </div>
  );
};