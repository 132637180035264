import { all, call, takeLatest, select, put } from 'redux-saga/effects';
import { each, chain, extend, map, find, flatten, min, max, isEmpty, clone, filter } from "underscore";
import { RotationGraphConstants, ComparisonAndRotationMenuConstants } from "Constants/RotationGraphConstants";
const { ActionTypes } = RotationGraphConstants;
extend(ActionTypes, ComparisonAndRotationMenuConstants.ActionTypes);
import { getGlobalTrendData } from '../NavCompareSaga';
import { handleLoading, updatePointData } from '../RotationGraph/RotationMenuPanelSaga'
import { getRotationGraphMenuData, getRotationGraphActiveMenuName } from '../../../Reducers/NavCompare/RotationGraphReducers/reselectorsdata';
import StringUtil from 'StringUtil';
import moment from "moment";
import { getBenchMarkState, getGlobalCountDataList, getSelectedMenuTab } from '../../../Reducers/NavCompare/RotationGraphReducers/selectors';
import CompareApi from "CompareApi";
import DateHelper from "DateHelper";
import { CompRotationHelper } from '../../../Models/NavCompare/ComparisonAndRotationMenuModels/MenuStates';
import { RotationMenuType, BenchMarkOptions } from "Constants/RotationMenuType";
import { CompareMenuType } from '../../../Constants/CompareTabType';
import BaseServiceApi from 'BaseServiceApi';

const GroupType = BaseServiceApi.rayData["GroupType"];

let prevSelectedSliderValue = null;

function* getListItemVisibility(osid) {
  const { listItems } = yield select(getRotationGraphMenuData);
  const listItem = find(listItems, (item) => item.osid == osid);
  if (!StringUtil.isEmpty(listItem))
    return listItem.isVisible;
  return false;
}

function* preparePointstoRender({ selectedSliderValue = null, isInit = true }) {
  try {
    const { listItemType, groupResult, listItems } = yield select(getRotationGraphMenuData);
    const { groupsTimelineDateDictionary } = yield select(getBenchMarkState);
    const menuData = yield select(getRotationGraphMenuData);
    const menuTab = yield select(getSelectedMenuTab);
    let quadGraphResults = clone(menuData.quadGraphResults);

    if (!StringUtil.isEmpty(groupResult)) {
      const helper = new CompRotationHelper();
      let newQuadGraphItemsData2 = [];

      if (isInit) {
        newQuadGraphItemsData2 = yield helper.preparePointstoRender(groupResult, groupsTimelineDateDictionary, selectedSliderValue, listItemType, listItems);
      }
      else {
        for (let key in groupResult) {
          const item = groupResult[key];
          if (!StringUtil.isEmpty(item)) {
            const points = [];
            if (!StringUtil.isEmpty(item) && item.length >= ((groupsTimelineDateDictionary.length - 1) - selectedSliderValue + 6)) {
              map(item, (entry, i) => {
                if ((i >= (groupsTimelineDateDictionary.length - 1) - selectedSliderValue) && i < (groupsTimelineDateDictionary.length - 1) - selectedSliderValue + 6) {
                  points.push({
                    xValue: entry.Xval.toFixed(4), yValue: entry.Yval.toFixed(4)
                  });
                }
              });
            }

            newQuadGraphItemsData2.push({
              points: (!StringUtil.isEmpty(points) ? points : null),
              sectorFullName: item[0].SectorName,
              sectorName: helper.getSectorLabel(item[0].SectorLabl),
              sectorLabel: listItemType == RotationMenuType.Sector ? helper.getSectorLabel(item[0].SectorLabl.substring(0, 5)) : item[0].SectorLabl,
              id: item[0].SectorOsid,
              isVisible: yield call(getListItemVisibility, item[0].SectorOsid.low)
            });
          }
        }
      }

      extend(quadGraphResults, {
        quadGraphItemsData2: newQuadGraphItemsData2
      });

      yield put({
        type: menuTab == CompareMenuType.Symbols ? ActionTypes.UPDATE_SYMBOLS_QUAD_GRAPH_RESULT : ActionTypes.UPDATE_GROUPS_QUAD_GRAPH_RESULT,
        quadGraphResults: Object.assign({}, quadGraphResults)
      });
    }
  }
  catch (error) {
    console.log(`Error occurs in  CenterRotationSaga.js, preparePointstoRender ${error}`);
  }
}

function* postInitializeTimeLineBasedOnDataWorker() {
  const { selectedSliderValue } = yield select(getBenchMarkState);
  yield call(preparePointstoRender, { selectedSliderValue });
  // this.emitChangeOnMenuType(rotationMenuType);
}

async function initiateWorker(obj, groupsTimelineDateDictionary, groupsCountDataList, lastAvailableDate, selectedSliderValue, newList = false) {
  const helper = new CompRotationHelper();
  return await helper.initializeTimeLineWorker(obj, groupsTimelineDateDictionary, groupsCountDataList, lastAvailableDate, selectedSliderValue, newList);
}

function* startinitializeTimeLineBasedOnDataWorker(newList = false) {
  try {
    const menuState = yield select(getRotationGraphMenuData);
    const { groupsTimelineDateDictionary, lastAvailableDate, selectedSliderValue, benchMarkData, groupsCountDataList } = yield select(getBenchMarkState);
    const { quadGraphYearList, stocksTotalList } = menuState;
    let globalCountDataList = yield select(getGlobalCountDataList);

    if (isEmpty(globalCountDataList)) {
      globalCountDataList = yield call(getGlobalTrendData);

      yield put({
        type: ActionTypes.UPDATE_GLOBAL_TREND_DATA,
        globalCountDataList: globalCountDataList
      });
    }


    const menuName = yield select(getRotationGraphActiveMenuName);
    const obj = JSON.parse(JSON.stringify({ benchMarkData, quadGraphYearList, globalCountDataList }));

    const data = yield call(initiateWorker, obj, groupsTimelineDateDictionary, groupsCountDataList, lastAvailableDate, selectedSliderValue, newList);

    if (data) {

      yield all([
        put({
          type: ActionTypes.UPDATE_GROUPS_TIMELINE,
          groupsTimelineDateDictionary: data.groupsTimelineDateDictionary
        }),
        put({
          type: ActionTypes.SET_SLIDER_VALUE,
          selectedSliderValue: data.selectedSliderValue
        }),
        put({
          type: ActionTypes.UPDATE_BENCHMARK_DATA,
          result: data.benchMarkData
        }),
        put({
          type: ActionTypes.UPDATE_GROUPS_COUNT_DATA_LIST,
          groupsCountDataList: data.groupsCountDataList
        }),
        put({
          type: ActionTypes.UPDATE_BENCHMARKDATA_LOADING_STATUS,
          isBenchMarkLoading: false
        })
      ]);


      const groupResult = yield call(sortQuadData, data.quadGraphYearList);

      yield put({
        type: menuName === CompareMenuType.Symbols ? ActionTypes.SET_SYMBOLS_QUAD_DATA : ActionTypes.SET_GROUPS_QUAD_DATA,
        stocksTotalList: stocksTotalList,
        quadGraphYearList: quadGraphYearList,
        groupResult: groupResult
      });
    }

    yield put({
      type: ActionTypes.UPDATE_GEOGRAPHY_SELECTION,
      isGeographySelected: false
    });
  }
  catch (error) {
    console.log(`Error occurs in  CenterRotationSaga.js, startinitializeTimeLineBasedOnDataWorker ${error}`);
  }
}

function* setGroupsTimelineDateDictionary(LastAvailableDateLong, benchMarkState) {
  const { selectedSliderValue, groupsTimelineDateDictionary } = benchMarkState;

  const timelineDateDictionary = [];
  const lastAvailableDate = moment.utc(StringUtil.convertFromLongValueToInt(LastAvailableDateLong)).add(35, 'days').toDate();
  if (!StringUtil.isEmpty(groupsTimelineDateDictionary))
    each(groupsTimelineDateDictionary, (item) => {
      if (moment(item).isSameOrAfter(moment(lastAvailableDate))) {
        timelineDateDictionary.push(item);
      }
    });

  benchMarkState.groupsTimelineDateDictionary = timelineDateDictionary;

  if (selectedSliderValue > benchMarkState.groupsTimelineDateDictionary.length) {
    benchMarkState.selectedSliderValue = benchMarkState.groupsTimelineDateDictionary.length;
  }
}

function* setQuadGraphResults(result, rotationMenuType, menuState, newList = false) {
  const { quadGraphResults, isGeographySelected } = yield select(getRotationGraphMenuData);
  const benchMarkState = yield select(getBenchMarkState);

  try {
    extend(quadGraphResults, {
      xMax: result.XScaleMax,
      xMin: result.XScaleMin,
      yMax: result.YScaleMax,
      yMin: result.YScaleMin,
      circleRad: result.CircleRad,
      lastAvailableDate: result.LastAvailableDate
    });

    benchMarkState.lastAvailableDate = moment.utc(StringUtil.convertFromLongValueToInt(result.LastAvailableDate)).toDate();

    // yield call(setGroupsTimelineDateDictionary, result.LastAvailableDate, benchMarkState);

    if (isGeographySelected) {
      yield call(startinitializeTimeLineBasedOnDataWorker, newList);
      yield call(postInitializeTimeLineBasedOnDataWorker, rotationMenuType, menuState);
    }
    else {
      yield call(postInitializeTimeLineBasedOnDataWorker, rotationMenuType);
    }
  }
  catch (error) {
    console.log(`Error occurs in  CenterRotationSaga.js, setQuadGraphResults ${error}`);
  }
}

function sortQuadData(data) {
  //Group by 'SectorOsid', sort by 'LongDate' in descending order and sort by by 'SectorName'\
  return chain(data)
    .sortBy((d) => StringUtil.convertFromLongValueToInt(d.LongDate)).reverse()
    .sortBy((c) => c.SectorName)
    .groupBy((g) => StringUtil.convertFromLongValueToInt(g.SectorOsid)).value();

  /*let sectorOsidList = _.groupBy(data,(d) => StringUtil.convertFromLongValueToInt(d.SectorOsid));
  let sectorGrpSortedList = [];
  _.each(sectorOsidList, (item) => {
      sectorGrpSortedList.push(_.sortBy(item, (s) => {
          return StringUtil.convertFromLongValueToInt(s.LongDate);
      }).reverse());
  });
  return _.sortBy(sectorGrpSortedList, (list, key) => {
      if (!StringUtil.isEmpty(list))
          return list[0].SectorName;
  });*/
}

function* setQuadData(quadData) {
  const selectedMenuTab = yield select(getRotationGraphActiveMenuName);
  if (selectedMenuTab === CompareMenuType.Symbols) {
    yield put({
      type: ActionTypes.SET_SYMBOLS_QUAD_DATA,
      ...quadData
    })
  } else {
    yield put({
      type: ActionTypes.SET_GROUPS_QUAD_DATA,
      ...quadData
    })
  }
}

export function* processQuadData(result, groupType, newList = false) {
  const menuState = yield select(getRotationGraphMenuData);

  const groupResult = yield call(sortQuadData, result.QuadGraphItemList);
  menuState.stocksTotalList.push(result.QuadGraphItemList);
  const QuadGraphItemList = filter(flatten(menuState.stocksTotalList), (item) => item.SectorOsid.low);
  const quadData = {
    stocksTotalList: QuadGraphItemList,
    quadGraphYearList: QuadGraphItemList,
    groupResult: extend(menuState.groupResult, groupResult)
  }

  yield call(setQuadData, quadData);
  yield call(setQuadGraphResults, result, groupType, menuState, newList);

  yield call(handleLoading);
}

function* getQuadData({ groupType }) {
  const { selectedOsid, countryCode } = yield select(getRotationGraphMenuData);
  const { groupsTimelineDateDictionary } = yield select(getBenchMarkState);

  try {
    if (!StringUtil.isEmpty(selectedOsid) && !StringUtil.isEmpty(groupsTimelineDateDictionary) && !StringUtil.isEmpty(countryCode)) {
      const fromDate = moment.utc(groupsTimelineDateDictionary[0]).add(-35, 'days').toDate();

      let osidArray = selectedOsid.split(';');
      const quadRequestData = [];
      for(let i = 0; i < osidArray.length; i = i+200) {
        quadRequestData.push({
          osid: osidArray.slice(i, i+200).join(';'),
          fromdate: DateHelper.yyyymmdd(fromDate),
          //enddate: DateHelper.yyyymmdd(DateHelper.returnTimeZoneDate(new Date())), 
          enddate: DateHelper.yyyymmdd(new Date()), //Panaray Desktop 5.0 sending system date 
          code: countryCode.toString(),
          type: groupType.toString(),
          includeFilterData: false
        });
      }

      const response = yield all(
        map(quadRequestData, (quadRequestData) =>
          CompareApi.getQuadData(quadRequestData)
        )
      );

      if (!response[0].responseHeader.error) {
        const QuadGraphItemList = [];
        each(response, (item) => QuadGraphItemList.push(item.QuadGraphItemList));

        const result = {
          CircleRad: response[0].CircleRad,
          LastAvailableDate: response[0].LastAvailableDate,
          XScaleMax: max(response, (item) => item.XScaleMax).XScaleMax,
          XScaleMin: max(response, (item) => item.XScaleMax).XScaleMin,
          YScaleMax: max(response, (item) => item.XScaleMax).YScaleMax,
          YScaleMin: max(response, (item) => item.XScaleMax).YScaleMin,
          QuadGraphItemList: flatten(QuadGraphItemList)
        };

        yield call(processQuadData, result, groupType.toString());
      }
    }
  }
  catch (error) {
    console.log(`Error occurs in  CenterRotationSaga.js, getQuadData ${error}`);
  }
}

export function* getQuadDataForSymbol(osidList, countryCodeList, regionCodeList) {
  try {
    let { benchMarkOption } = yield select(getBenchMarkState);
    let code = countryCodeList;
    if (benchMarkOption === BenchMarkOptions.Regional) {
      code = regionCodeList;
    }
    else if (benchMarkOption === BenchMarkOptions.Global) {
      code = "1000";
    }
  
    const fromDate = moment.utc().set({ 'year': 2010, 'month': 0, 'date': 1 }).add(-35, 'days').toDate();
    const quadRequestData = {
      osid: osidList,
      fromdate: DateHelper.yyyymmdd(fromDate),
      enddate: DateHelper.yyyymmdd(new Date()),
      code: code,
      type: GroupType.STOCKS.toString(),
      includeFilterData: true
    };

    const quadData = yield CompareApi.getQuadData(quadRequestData);
    return quadData;
  }
  catch (error) {
    console.log(`Error occurs in  CenterRotationSaga.js, getQuadDataForSymbol ${error}`);
  }
}

export function* filterQuadData(filteredStockList) {
  const benchMarkData = yield select(getBenchMarkState);
  const menuData = yield select(getRotationGraphMenuData);
  let quadGraphItemList = null;

  try {
    if (!StringUtil.isEmpty(filteredStockList)) {
      if (!StringUtil.isEmpty(menuData.stocksTotalList)) {
        quadGraphItemList = _.filter(menuData.stocksTotalList, (item) => _.find(filteredStockList, (s) => s.instrumentId === item.SectorOsid.low));
        menuData.groupResult = yield call(sortQuadData, quadGraphItemList);
      }
      if (!StringUtil.isEmpty(quadGraphItemList)) {
        let xScaleMax = _.max(quadGraphItemList, _.property('XScaleMax')).XScaleMax;
        let xScaleMin = _.min(quadGraphItemList, _.property('XScaleMin')).XScaleMin;
        let yScaleMax = _.max(quadGraphItemList, _.property('YScaleMax')).YScaleMax;
        let yScaleMin = _.min(quadGraphItemList, _.property('YScaleMin')).YScaleMin;
        xScaleMax = Math.max(xScaleMax, Math.abs(xScaleMin));
        yScaleMax = Math.max(yScaleMax, Math.abs(yScaleMin));
        xScaleMin = -xScaleMax;
        yScaleMin = -yScaleMax;
        xScaleMax = Math.max(xScaleMax, yScaleMax);
        yScaleMax = xScaleMax;
        xScaleMin = Math.min(xScaleMin, yScaleMin);
        yScaleMin = xScaleMin;
        yScaleMin = parseFloat(yScaleMin * 0.85);
        yScaleMax = parseFloat(yScaleMax * 0.85);
        xScaleMax = parseFloat(xScaleMax + (xScaleMax * 0.08));
        xScaleMin = parseFloat(xScaleMin + (xScaleMin * 0.08));

        _.extend(menuData.quadGraphResults, {
          xMax: xScaleMax,
          xMin: xScaleMin,
          yMax: yScaleMax,
          yMin: yScaleMin
        })
      }

      /* Issue with centerRotation graph => apply filter -> refresh -> remove filter -> filtered tadpoles will not plot back since quadGraphItemsData2 will be filtered off during initial loading. */
      // if (prevSelectedSliderValue != benchMarkData.selectedSliderValue) {
      //   prevSelectedSliderValue = benchMarkData.selectedSliderValue;
      //   yield call(preparePointstoRender, { selectedSliderValue: benchMarkData.selectedSliderValue });
      // }
      // else
      yield call(updatePointData);

    }
  }
  catch (error) {
    console.log(`Error occurs in  CenterRotationSaga.js, filterQuadData ${error}`);
  }
}

export function* watchGetQuadData() {
  yield takeLatest(ActionTypes.GET_QUAD_DATA, getQuadData);
}

export function* watchPreparePoints() {
  yield takeLatest(ActionTypes.PREPARE_POINTS_TO_RENDER, preparePointstoRender)
}