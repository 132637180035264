import React, { Component } from 'react';
import { connect } from "react-redux";
import ONeilViewStore from "ONeilViewStore";
import ListStore from "ListStore";
import GridStore from "GridStore";
import ListActions from "ListActions";
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { processNupListData, updateSelection } from "../../../../Actions/NupViewActions.js";

class SelectionDropup extends Component {
    
  returnPeriodicity(item) {
    let returnValues = "";
    let first = true;
    item.forEach((t) => {
        let comma = first ? "":":"
        switch (t) {
            case 1:
                returnValues += comma + "D";
                break;
            case 2:
                returnValues += comma + "W";
                break;
            case 3:
                returnValues += comma + "M";
                break;
            case 4:
                returnValues += comma + "A";
                break;
            case 5:
                returnValues += comma + "1";
                break;
            case 6:
                returnValues += comma + "5";
                break;
            case 7:
                returnValues += comma + "10";
                break;
            case 8:
                returnValues += comma + "15";
                break;
            case 9:
                returnValues += comma + "30";
                break;
            case 10:
                returnValues += comma + "60";
                break;
            case 11:
                returnValues += comma + "D";
                break;
            case 12:
                returnValues += comma + "Q";
                break;
            default:
                break;
        }
        first = false;
    })
    return returnValues;
    }
updateSel(item) {
    let selectedIndex = []
    if(!ONeilViewStore.showChart){
        selectedIndex = GridStore.getSelectedIndexes()
        ONeilViewStore.showChart = true;
    }
    const symbolList = ONeilViewStore.getSourceData();
    this.props.updateSelection(item);
    let selectedSymbol = null;
    if(selectedIndex.length > 0){
        selectedSymbol = symbolList[selectedIndex.pop()].symbol;
        ListActions.updateMiniList(selectedSymbol);
    }
    else{
        selectedSymbol = ListStore.getSelectedSymbol()
    }
    this.props.processNupListData(symbolList, true, selectedSymbol);
}
  render() {
      const { nupCharts } = this.props;
    return (
        <DropdownButton className="" id="icn-menu" noCaret title={<span><i className="icn-list-multi-chart-dd"></i></span>}>
            <MenuItem><span className="ratio-figure" onClick={() => this.updateSel(0)}><u className={nupCharts[0].sel}></u>{nupCharts[0].lyOut}</span><span className="ratio-periodicity" onClick={() => this.updateSel(1)}><u className={nupCharts[1].sel}></u>{nupCharts[1].lyOut+" ("+ this.returnPeriodicity(nupCharts[1].per) + ")"}</span></MenuItem>
            <MenuItem><span className="ratio-figure" onClick={() => this.updateSel(2)}><u className={nupCharts[2].sel}></u>{nupCharts[2].lyOut}</span><span className="ratio-periodicity" onClick={() => this.updateSel(3)}><u className={nupCharts[3].sel}></u>{nupCharts[3].lyOut + " (" + this.returnPeriodicity(nupCharts[3].per) + ")"}</span></MenuItem>
            <MenuItem><span className="ratio-figure" onClick={() => this.updateSel(4)}><u className={nupCharts[4].sel}></u>{nupCharts[4].lyOut}</span><span className="ratio-periodicity" onClick={() => this.updateSel(5)}><u className={nupCharts[5].sel}></u>{nupCharts[5].lyOut + " (" + this.returnPeriodicity(nupCharts[5].per) + ")"}</span></MenuItem>
            <MenuItem><span className="ratio-figure" onClick={() => this.updateSel(6)}><u className={nupCharts[6].sel}></u>{nupCharts[6].lyOut}</span><span className="ratio-periodicity" onClick={() => this.updateSel(7)}><u className={nupCharts[7].sel}></u>{nupCharts[7].lyOut + " (" + this.returnPeriodicity(nupCharts[7].per) + ")"}</span></MenuItem>
            <MenuItem><span className="ratio-figure" onClick={() => this.updateSel(8)}><u className={nupCharts[8].sel}></u>{nupCharts[8].lyOut}</span><span className="ratio-periodicity" onClick={() => this.updateSel(9)}><u className={nupCharts[9].sel}></u>{nupCharts[9].lyOut + " (" + this.returnPeriodicity(nupCharts[9].per) + ")"}</span></MenuItem>
        </DropdownButton>
    )
  }
}
const mapStateToProps = ({ listViewReducer }) => {
    const { NupViewReducer } = listViewReducer;
  
    return ({ nupCharts: NupViewReducer.nupCharts })
  }

const mapDispactchToProps = (dispatch) =>({
    updateSelection: (item)=> dispatch(updateSelection(item)),
    processNupListData: (symbolList, initChart, selectedSymbol)=> dispatch(processNupListData(symbolList, initChart, selectedSymbol))
})
  
  export default connect(mapStateToProps, mapDispactchToProps)(SelectionDropup);