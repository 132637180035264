
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { SettingsConstants } from "../../../Constants/SettingsConstants";
import LocalizationStore from "../../../Stores/Localization/LocalizationStore";

const { ActionTypes } = RiPanelConstants;

const initState = {
    checkListHeight: 200,
    isShowDetailDailog: false,
    loading: true,
    activeScreen: LocalizationStore.getTranslatedData("ri_id71", "SELECT A SCREEN"),
    listItems: [],
    activeListItems: [],
    activeColSetId: 0,
    activeSourceColSetId: 0,
    hideWithoutThreshold: true,
    columnSetInfo: {
        columnName: '',
        isModified: false,
        nodeId: 0,
        sourceColumnSetId: 0,
        screenMetricList: [],
        score: 0.0
    }
};

const RiPanelCheckListInfo = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_LIST_ITEMS:
            return {
                ...state,
                listItems: action.listItems,
                activeListItems: action.activeListItems,
                isShowDetailDailog: action.isShowDetailDailog,
                activeScreen: action.activeScreen
            };
        case ActionTypes.UPDATE_COLUMN_SET_INFO:
            return {
                ...state,
                activeColSetId: action.activeColSetId,
                activeSourceColSetId: action.activeSourceColSetId,
                columnSetInfo: action.columnSetInfo,
                loading: false
            };
        case ActionTypes.ON_CHECKLIST_RESIZE:
            return {
                ...state,
                checkListHeight: action.height
            };
        case ActionTypes.SET_CHECKLIST_ACTIVE_ITEMS:
            return {
                ...state,
                isShowDetailDailog: action.isShowDetailDailog,
                activeScreen: action.screenName,
                activeListItems: action.activeListItems
            };
        case ActionTypes.UPDATE_THRESHOLD_FLAG:
            return {
                ...state,
                hideWithoutThreshold: action.isHide
            };
        // case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
        //     return {
        //         ...initState,
        //     };
        case ActionTypes.GET_CHECKLIST_DATA:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};

export default RiPanelCheckListInfo;
