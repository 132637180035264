import { connect } from 'react-redux';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import RIPanelAnnotationTool from '../Presentational/RIPanelAnnotationTool';
import { onAnnotationToolChange, handleDeleteAnnotationDrawing, updateRiPanelInfoFromUserSettings, updateTempToolSelect } from 'Actions/AnnotationActions';
import React, { PureComponent } from 'react';

const annotateTools = [
    { icnClass: 'icn-ann-selection', annotationType: 0 },
    { icnClass: 'icn-ann-line', annotationType: RayAnnotationType.LINE_AnnotationType },
    { icnClass: 'icn-ann-text', annotationType: RayAnnotationType.TEXT_AnnotationType },
    { icnClass: 'icn-ann-shape', annotationType: RayAnnotationType.RECT_AnnotationType }, // get last selected shape from user settings
    { icnClass: 'icn-ann-channel', annotationType: RayAnnotationType.PARALLEL_LINES_AnnotationType },
    { icnClass: 'icn-ann-fibonacci', annotationType: RayAnnotationType.FIB_AnnotationType },
    { icnClass: 'icn-ann-measure', annotationType: RayAnnotationType.MEASUREMENT_AnnotationType }
];

class RIPanelAnnotationToolList extends PureComponent {

    handleClick = (selectedToolType) => {
        // If the tool is changed while in the middle of drawing, delete that particular annotation before moving on to the selected tool
        if (this.props.isDrawing && this.props.annotSelected) {
            this.props.deleteAnnotDraw();
        }

        const { onAnnotationToolChange, currentShape, updateRiPanelInfoFromUserSettings, updateTempTool } = this.props;
        if (selectedToolType === RayAnnotationType.RECT_AnnotationType) {
            selectedToolType = currentShape;
        }
        onAnnotationToolChange(selectedToolType, false, true);
        updateRiPanelInfoFromUserSettings();
        updateTempTool(selectedToolType);
    }

    render() {
        const { selectedToolType, tempToolType } = this.props;

        return (
            <div className="annotation-tools">
                <ul>
                    {annotateTools.map((tool) => <RIPanelAnnotationTool key={tool.annotationType} icnClass={tool.icnClass} isActive={tool.annotationType === RayAnnotationType.RECT_AnnotationType ? (tempToolType === RayAnnotationType.RECT_AnnotationType || tempToolType === RayAnnotationType.TRIANGLE_AnnotationType || tempToolType === RayAnnotationType.ELLIPSE_AnnotationType) : tempToolType === tool.annotationType} annotationType={tool.annotationType} onClick={this.handleClick} />)}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { selectedToolType, tempToolType } = annotationReducers.annotationMenuReducer;
    const { isDrawing, annotSelected } = annotationReducers.annotationChartModelReducer;
    const currentShape = annotationReducers.annotationMenuReducer.ShapesModelInfo.selectedType;
    return ({ selectedToolType, currentShape, isDrawing, annotSelected, tempToolType });
}

const mapDispatchToProps = (dispatch) => ({
    onAnnotationToolChange: (selectedToolType, isCtrl, isRipanel) => dispatch(onAnnotationToolChange(selectedToolType, isCtrl, isRipanel)),
    deleteAnnotDraw: () => dispatch(handleDeleteAnnotationDrawing()),
    updateRiPanelInfoFromUserSettings: () => dispatch(updateRiPanelInfoFromUserSettings()),
    updateTempTool: (selectedToolType) => dispatch(updateTempToolSelect(selectedToolType))
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelAnnotationToolList);
