const { ActionTypes } = RiPanelConstants;
import GraphApi from "../../../../ServiceApi/Apis/GraphApi.js";
import MiniListHelper from "MiniListHelper";
import moment from "moment";
import { RiPanelConstants } from '../../../../Constants/RiPanelConstants';
import SymbolType from "../../../../Constants/SymbolType.js";
import UserInfoUtil from "UserInfoUtil";
import { getRiPanelOwnershipInfoState, getRiHubPanelState } from '../../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors';
import { ListType, HubUserRole } from "../../../../Constants/HubRIPanelConstants.js";
import { put, takeLatest, select, call } from 'redux-saga/effects';

let symbolData;
function* getHubPanel({ symbolInfo }) {
    try {
        symbolData = symbolInfo;
        const { listType } = yield select(getRiPanelOwnershipInfoState);
        yield call(setDefaultHubState);
        if (listType === ListType.HubListView) {
            yield call(getRoleDataSource, symbolData)
        }
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, getHubPanel ${error}`);
    }
}
function* setDefaultHubState() {
    try {
        yield put({
            type: ActionTypes.OWNERSHIP_INFO_HUB_PANEL_SET_DEFAULT_STATE,
            newState: {
                HubDatasource: [],
                loading: true,
                Initialized: undefined,
                expanded: false,
                indexItems: [],
                callService: true,
            }
        })
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, setDefaultHubState ${error}`);
    }
}
function getUserEmail() {
    try {
        const user = UserInfoUtil.getUserInfo();
        const userEmail = user && user.userEmail ? user.userEmail : ""
        return userEmail;
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, getUserEmail ${error}`);
    }
}
function getEndDateOfQuarter(symbolInfo) {
    try {
        let quarter = moment(symbolInfo.LastTradeDate).quarter();
        let year = moment(symbolInfo.LastTradeDate).year();
        if (symbolInfo.isHistoric) {
            const endDate = symbolInfo.endDate;
            if (Boolean(endDate)) {
                quarter = moment(endDate).quarter();
                year = moment(endDate).year();
            }
        }
        const endMonth = 3 * parseInt(quarter);
        const month = new Date(`${year}-${endMonth < 10 ? `0${endMonth.toString()}` : endMonth.toString()}`).toUTCString();
        const endMonthDays = moment(month).utc().daysInMonth();
        const date = new Date(`${year}-${endMonth < 10 ? `0${endMonth.toString()}` : endMonth.toString()}-${endMonthDays}`).toUTCString();
        const endDateOfQuarter = moment(date).utc().format('YYYY-MM-DD 00:00:00.000');
        return endDateOfQuarter;
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, getEndDateOfQuarter ${error}`);
    }
}
function* setHubList(source, symbolInfo) {
    try {
        const HubDatasource = source;
        const loading = false;
        const Initialized = true;
        const callService = true;
        const expanded = false;
        if (Boolean(source)) {
            yield call(getIndexes, symbolInfo);
        }
        yield put({
            type: ActionTypes.OWNERSHIP_INFO_SET_HUB_PANEL_DATA,
            newState: { HubDatasource, loading, Initialized, callService, expanded }
        })
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, setHubList ${error}`);
    }
}
function* getSalesRepsDataSource(email, symbolInfo) {
    try {
        const qtrEndDate = yield call(getEndDateOfQuarter, symbolInfo);
        const state = yield select(getRiHubPanelState)

        if (state.callService) {
            state.callService = false;
            const symbol = symbolInfo.Symbol;
            const isMiniListPlay = symbolInfo.IsMiniListPlay;
            try {
                const result = yield call(GraphApi.getContactRequest, email, symbolInfo.Osid, qtrEndDate);
                if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)) {
                    const processedResult = _.map(result.hubContacts, (contact) => {
                        let height = 40;
                        if (Boolean(contact.hubFunds)) {
                            height = contact.hubFunds.length * 45 - 5;

                            if (Boolean(contact.recommendation)) {
                                height += contact.hubFunds.length * 20;
                            }
                        }

                        return {
                            ...contact,
                            height: 50,
                            expanded: false,
                            expandedHeight: height + 70 + 12,
                            Symbol: symbolInfo.Symbol,
                            FocusStockName: symbolInfo.CompanyName,
                            Osid: symbolInfo.Osid,
                            QtrEndDate: qtrEndDate
                        }
                    })

                    yield call(setHubList, processedResult, symbolInfo)
                }
            } catch (error) {
                console.log(`%cError occurs while api fetch in RiHubPanel.js, getSalesRepsDataSource ${error}`)
            }
        } else {
            yield put({
                type: ActionTypes.OWNERSHIP_INFO_HUB_PANEL_SET_DEFAULT_STATE
            })
        }
    } catch (error) {
        console.log(`%cError occurs in RiHubPanel.js, getSalesRepsDataSource ${error}`)
    }
}
function* getManagerDataSource(email, symbolInfo) {
    try {
        let parameterHeight = 0.8;
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Chrome") > -1) {
            parameterHeight = 0
        }
        const qtrEndDate = yield call(getEndDateOfQuarter, symbolInfo);
        const state = yield select(getRiHubPanelState);

        if (state.callService) {
            state.callService = false;
            const symbol = symbolInfo.Symbol;
            try {
                const result = yield call(GraphApi.getSalesRepsRequest, email, symbolInfo.Osid, qtrEndDate);
                if (MiniListHelper.ActiveSymbolCheck(symbol)) {
                    const processedResult = _.map(result.hubSalesReps, (salesRep) => {
                        const { hubContacts } = salesRep;
                        // Sales Rep 
                        let expandedHeight = 50;
                        _.each(hubContacts, (contact) => {
                            let height = 40;
                            if (Boolean(contact.hubFunds)) {
                                //40 height of each contact item plus fund list.
                                const fundsHeight = (contact.hubFunds.length * 45) - parameterHeight;
                                height += fundsHeight;

                                //20 height of recommendation line
                                if (Boolean(contact.recommendation)) {
                                    height += contact.hubFunds.length * 20;
                                }
                            }
                            expandedHeight += height;
                        })

                        //50 height of each SalesRep item.
                        return {
                            ...salesRep,
                            hubContacts: hubContacts,
                            expanded: false,
                            height: 50,
                            expandedHeight: expandedHeight,
                            Symbol: symbolInfo.Symbol,
                            FocusStockName: symbolInfo.CompanyName,
                            Osid: symbolInfo.Osid,
                            QtrEndDate: qtrEndDate
                        }
                    })

                    yield call(setHubList, processedResult, symbolInfo)
                }
            } catch (error) {
                console.log(`%cError occurs while fetching api RiHubPanel.js, getManagerDataSource ${error}`)
            }
        } else {
            yield put({
                type: ActionTypes.OWNERSHIP_INFO_HUB_PANEL_SET_DEFAULT_STATE
            })
        }
    } catch (error) {
        console.log(`%cError occurs in RiHubPanel.js, getManagerDataSource ${error}`)
    }
}
function* getRoleDataSource(symbolInfo) {
    try {
        const { hubUserRole } = yield select(getRiPanelOwnershipInfoState);
        const email = yield call(getUserEmail);

        if (hubUserRole) {
            switch (hubUserRole) {
                case HubUserRole.SalesRep:
                    yield call(getSalesRepsDataSource, email, symbolInfo);
                    break;
                case HubUserRole.Manager:
                    yield call(getManagerDataSource, email, symbolInfo);
                    break;
                default:
                    break;
            }
        }
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, getRoleDataSource ${error}`);
    }
}
function internationalStock(info) {
    try {
        return (info &&
            info.SymTypeEnum &&
            info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK
        );
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, internationalStock ${error}`);
    }
}
function* getIndexes(symbolInfo) {
    try {
        const info = symbolInfo;
        const international = info ? internationalStock(info) : false;
        let indexItems;

        if (Boolean(info)) {
            if (Boolean(international)) {
                const result = yield call(GraphApi.GetConstituentsDataRequest, info.Symbol, 0);
                indexItems = result.data.sort((a, b) => -1 * (a.marketValOrWeight - b.marketValOrWeight));
            }
            else if (!Boolean(international)) {
                const result = yield call(GraphApi.getIndexes, info.Symbol);
                indexItems = result.data.sort((a, b) => -1 * (a.marketValOrWeight - b.marketValOrWeight));
            }
        }
        yield put({
            type: ActionTypes.OWNERSHIP_INFO_HUB_PANEL_SET_INDEXES,
            indexItems
        })
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, getIndexes ${error}`);
    }
}
function checkIfChangeStateExpanded(itemExpanded, state) {
    try {
        let same = true;
        const { HubDatasource } = state;
        _.each(HubDatasource, (i) => {
            if (i.expanded !== itemExpanded) {
                same = false;
                return false;
            }
        })
        if (Boolean(same)) {
            return itemExpanded;
        }
        return state.expanded;
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, checkIfChangeStateExpanded ${error}`);
    }
}
function* toggleSalesRepItem({ index }) {
    try {
        const state = yield select(getRiHubPanelState);
        const { HubDatasource } = state;
        let itemExpanded = state.expanded;
        if (Boolean(HubDatasource) && HubDatasource.length > 0) {
            const item = HubDatasource[index];
            if (Boolean(item)) {
                item.expanded = !item.expanded;
                itemExpanded = checkIfChangeStateExpanded(item.expanded, state);
            }
        }
        yield put({
            type: ActionTypes.OWNERSHIP_INFO_HUB_PANEL_SET_TOGGLE,
            HubDatasource, itemExpanded
        })
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, toggleSalesRepItem ${error}`);
    }
}
function* changeDispView({ view }) {
    try {
        const state = yield select(getRiHubPanelState);
        if (!Boolean(state.Initialized)) {
            if (view === ListType.HubListView) {
                yield call(getRoleDataSource, symbolData);
            }
        }
    } catch (error) {
        console.log(`%cError occurs in RiPanelHub.js, changeDispView ${error}`);
    }
}
/*************** Watchers ***************/
export function* watcherGetOwnerhsipHubPanel() {
    yield takeLatest(ActionTypes.ONWERSHIP_INFO_GET_HUB_PANEL, getHubPanel)
}
export function* watcherToggleSalesRep() {
    yield takeLatest(ActionTypes.OWNERSHIP_INFO_TOGGLE_SALES_REP_HUB_PANEL, toggleSalesRepItem)
}
export function* watcherChangeDispView() {
    yield takeLatest(ActionTypes.OWNERSHIP_INFO_CHANGE_DISPALY_VIEW, changeDispView)
}