import AnnotationPreview from './AnnotationPreview.jsx';
import AnnotationsCheckboxSelectors from './AnnotationsCheckboxSelectors';
import AnnotationsColorPicker from './AnnotationsColorPicker.jsx';
import AnnotationsStrokeSettings from './AnnotationsStrokeSettings';
import AnnotationsWeightSettings from './AnnotationsWeightSettings';
import BaseServiceApi from 'BaseServiceApi';
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import ScrollBar from "ScrollBar";
import React, { PureComponent } from 'react'
// import ThemeHelper from "../../Utils/Theme/ThemeHelper";
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

class RIPanelChannelInfo extends PureComponent {

  componentDidMount() {
    document.body.classList.add('riPanel');
    document.addEventListener("keydown", this.onDocumentKeyPress, false);
  }

  componentWillUnmount() {
    document.body.classList.remove('riPanel');
    document.removeEventListener("keydown", this.onDocumentKeyPress, false);
  }
  onDocumentKeyPress(e) {
    if ((document.activeElement && document.activeElement.id === "SymbolEntryInput") || (document.activeElement && document.activeElement.id.includes("annot-dropdown"))) {
      return;
    }

    const pointerLayerScroll = document.getElementById("annotateIntrumentsChannelLIne");

    if (e.keyCode === KeyCodes.UP_ARROW) {

      if (pointerLayerScroll) {
        window.setTimeout(() => { pointerLayerScroll.scrollTop -= 30; }, 0);
      }
    }
    else if (e.keyCode === KeyCodes.DOWN_ARROW) {

      if (pointerLayerScroll) {
        window.setTimeout(() => { pointerLayerScroll.scrollTop += 30; }, 0);
      }
    }

  }
  hideDropDownMenu = () => {
    this.colorPicker && this.colorPicker.hideDropDownMenu();
    this.weightDropDown && this.weightDropDown.hideDropDownMenu();
    this.strokeDropDown && this.strokeDropDown.hideDropDownMenu();
  }

  render() {
    return (
      <div className="annotation-box annotate-channel-line">
        <div className="annotation-hold custom-scroll" onScroll={this.hideDropDownMenu} onClickCapture={this.hideDropDownMenu}>
          <div className="annotation-overlape" style={{ display: ((!this.props.annotSelected && this.props.selectedLayer && this.props.selectedLayer.shareAccess === ShareAccessType.SHARE_READONLY) || (this.props.annotSelected && this.props.annotSelected.isReadOnlyAnnotation)) ? '' : 'none' }}></div>
          <div className="annotation-intruments" id="annotateIntrumentsChannelLIne">

            <AnnotationPreview label={`CHANNEL LINE SETTINGS`} />

            <div className="annotation-properties">
              <div className="swatches-dropdown">
                <div className="col-xs-4"><AnnotationsColorPicker type="line" label={true} ref={(r) => { this.colorPicker = r; }} /></div>
                <div className="col-xs-4"><AnnotationsWeightSettings type="line" label={true} ref={(r) => { this.weightDropDown = r; }} /></div>
                <div className="col-xs-4"><AnnotationsStrokeSettings type="line" label={true} ref={(r) => { this.strokeDropDown = r; }} /></div>
              </div>
            </div>

            <div className="annotation-properties">
              <div className="swatches-checks">
                <div className="col-xs-6">
                  <h6>START</h6>
                  <ul>
                    <AnnotationsCheckboxSelectors type="ExtendStart" />
                  </ul>
                </div>
                <div className="col-xs-6">
                  <h6>END</h6>
                  <ul>
                    <AnnotationsCheckboxSelectors type="ExtendEnd" />
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <ScrollBar scrollId="annotateIntrumentsChannelLIne" vScroll={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ annotationReducers }) => {
  const { selectedLayer } = annotationReducers.annotationLayerManagementReducer;
  const { annotSelected } = annotationReducers.annotationChartModelReducer;
  const { riPanelToggle } = annotationReducers.annotationMenuReducer;
  return ({ selectedLayer, annotSelected, riPanelToggle });
}

export default connect(mapStateToProps)(RIPanelChannelInfo);