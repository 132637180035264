import AppDispatcher from "AppDispatcher";
import { ListExplorerConstants } from "ListExplorerConstants";
import { OwnershipViewConstants } from "../Constants/OwnershipConstants";
import ListApi, { GetTempList } from "ListApi";
import ChartApi from "../ServiceApi/Apis/ChartApi.js";
import ConsoleActions from "Actions/ConsoleActions";
import PanarayDefault from "ServiceApi/RequestHelper/PanarayDefault";
import BasicEsInfo from 'ServiceApi/RequestHelper/BasicEsInfo';
import SmartViewType from "SmartViewType";
import BaseServiceApi from 'BaseServiceApi';
import ListExplorerStore from "ListExplorerStore";
import ONeilViewStore from "../Stores/NavModules/NavList/TabONeil/ONeilViewStore";
import moment from "moment"
import TimeTrackingWindow from "../RayCustomControls/TimeTrackingWindow";
import DatagraphDataType from "../Constants/DatagraphDataType";
import NavType from "../Constants/NavType";
import SettingsStore from "SettingsStore";
import BrowserFilterStore from "../Stores/NavModules/NavList/TabONeil/BrowserFilterStore";
import { dispatch } from "../Redux/dispatch";
import { updateNupIsNonImported } from "./NupViewActions";
import oNeilViewStore from "../Stores/NavModules/NavList/TabONeil/ONeilViewStore";
import { GridConstants } from "../Constants/GridConstants";
import ErrorCodes from "../Constants/ErrorCodes";
import BrowserUtil from "BrowserUtil";

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const TemporaryListType = BaseServiceApi.rayData["TemporaryListType"];
let cancelablePromise = null;
let isDefaultSymbolCallRequired = true;
class ListActionsClass {

  getListExplorer() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER,
      data: {}
    });
  }

  getDefaultSymbolData() {
    return PanarayDefault.getDefaultSymbolData();
  }

  cancelPromise(){
    if (cancelablePromise) { cancelablePromise.cancel()}
    oNeilViewStore.cancelPromise();
  }
  getEsBasicData(BasicEsInfoData, listCatalog) {
    this.cancelPromise()
    TimeTrackingWindow.beginListContextApiTimeTracker();
    const isUserList = ListExplorerStore.isUserListOrFavoriteUserList();
    // let resetReorderNumAllColumnsStatus = oNeilViewStore.resetOrderNumAllColumns();
    // if(resetReorderNumAllColumnsStatus) oNeilViewStore.getFullListMsids(ListExplorerStore.activeNode,BasicEsInfoData);
    cancelablePromise = ListApi.getListCatalogEsData(BasicEsInfoData, false, isUserList);
    if (!cancelablePromise) {
      cancelablePromise = null;
      return;
    }
    cancelablePromise.promise.then((esBasicResult) => {
      try {
        TimeTrackingWindow.endListContextApiTimeTracker();
        if (!esBasicResult.responseHeader.error) {
          AppDispatcher.handleServerAction({
            actionType: ListExplorerConstants.ActionTypes.GET_LIST_CATALOG,
            data: { esBasicCatalogData: esBasicResult.catalog, basicEsInfo: BasicEsInfoData, listId: listCatalog.listId, actualListId: listCatalog.actualListId, listName: listCatalog.listName }
          });
          cancelablePromise = null;
        }
        else {
          if(esBasicResult?.responseHeader?.errorCode === ErrorCodes.LIST_NOT_EXISTED){
            AppDispatcher.handleServerAction({
                actionType: GridConstants.ActionTypes.REMOVE_UNSHARED_LIST,
                data: esBasicResult.responseHeader
              });
          }
          cancelablePromise = null;
          TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, true, NavType.NavLists);
          //ConsoleActions.exceptionHandle(esBasicResult.responseHeader);
        }
      }
      catch (e) {
        console.log(e);
        cancelablePromise = null;
        TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, true, NavType.NavLists);
        ConsoleActions.exceptionHandle();
      }
    })
      .catch(() => console.log('isCanceled'));
  }

  getListCatalog(listCatalog, isInit = false) {
    let BasicEsInfoData = null;
    if(!isInit){
      TimeTrackingWindow.resetTimeTracker(true, false, NavType.NavLists);
      TimeTrackingWindow.initTimeTracker();
      TimeTrackingWindow.beginTimeTracker(NavType.NavLists);
    }
    if (isDefaultSymbolCallRequired && !PanarayDefault.loginCalled) {
      this.getDefaultSymbolData().then(() => {
        BasicEsInfoData = BasicEsInfo.setBasicEsInfoFromListCatalogData(listCatalog);
        this.getEsBasicData(BasicEsInfoData, listCatalog);
        isDefaultSymbolCallRequired = false;
      });
    }
    else {
      BasicEsInfoData = BasicEsInfo.setBasicEsInfoFromListCatalogData(listCatalog);
      this.getEsBasicData(BasicEsInfoData, listCatalog);
    }

    //cancelablePromise = ListApi.getListCatalog(listCatalog, pageListRows);
    // cancelablePromise.promise.then((result) => {
    //  })
  }

  resetListCatelogData() {
    let ucf = (ONeilViewStore.getScreenOnOffValue()) ? 1 : 0;
    let filterSavedData = BrowserFilterStore.getState();
    let needBrowserCounts = filterSavedData.needBrowserCounts ? filterSavedData.needBrowserCounts : 0;
    if (BrowserFilterStore.getFilterBadge() == '') {
      ONeilViewStore.listCatalog = {
        listId: null,
        actualListId: null,
        searchText: '',
        sortCols: '',
        initialNumRows: 90000,
        needColumnsInfo: 1,
        needBrowserCounts: needBrowserCounts,
        browserFilterText: '-1;-1;-1;',
        addlCatalogRequests: null,
        useColFilters: ucf,
        saveColFilters: 0,
        customerOrder: 0,
        colsetId: '-1',
        igOneilbrowserFilters: '-1;-1;-1;',
        igGicsbrowserFilters: '-1;-1;-1;-1;',
        isOneilselected: filterSavedData.isOneilselected,
        isGicsselected: filterSavedData.isGicsselected,
        isNupview: 0,
        pageIndex: 0,
        pageSize: 250,
        isQuoteboard: 0,
        msId: null,
        fundId: "",
        indGrpCode: "",
        isExport: false,
        cacheId: -1
      }
    }
    else {
      ONeilViewStore.listCatalog = {
        listId: null,
        actualListId: null,
        searchText: '',
        sortCols: '',
        initialNumRows: 90000,
        needColumnsInfo: 1,
        needBrowserCounts: needBrowserCounts,
        browserFilterText: filterSavedData.browserFilterText ? filterSavedData.browserFilterText : '-1;-1;-1;',
        addlCatalogRequests: null,
        useColFilters: ucf,
        saveColFilters: 0,
        customerOrder: 0,
        colsetId: '-1',
        igOneilbrowserFilters: filterSavedData.igOneilbrowserFilters,
        igGicsbrowserFilters: filterSavedData.igGicsbrowserFilters,
        isOneilselected: filterSavedData.isOneilselected,
        isGicsselected: filterSavedData.isGicsselected,
        isNupview: 0,
        pageIndex: 0,
        pageSize: 250,
        isQuoteboard: 0,
        msId: null,
        fundId: "",
        indGrpCode: "",
        isExport: false,
        cacheId: -1
      }
    }

  }

  fetchListCataglogData(listId, actualListId, listName, pageIndex = 0, cacheId = -1, isInit = false) {
    if (listId && actualListId) {
      this.resetListCatelogData();
      let listCatalog = ONeilViewStore.listCatalog;
      
      if(!listCatalog){
        listCatalog = {
        listId: null,
        actualListId: null,
        searchText: "",
        sortCols: "",
        initialNumRows: 90000,
        needColumnsInfo: 1,
        needBrowserCounts: 0,
        browserFilterText: UserInfoUtil.hasChinaEntitlement() ? "-1;4,102;-1" : "-1;-1;-1;",
        addlCatalogRequests: null,
        useColFilters: 1,
        saveColFilters: 0,
        customerOrder: 0,
        colsetId: "-1",
        igOneilbrowserFilters: "-1;-1;-1;",
        igGicsbrowserFilters: "-1;-1;-1;-1;",
        isOneilselected: 1,
        isGicsselected: 0,
        isNupview: 0,
        pageIndex: 0,
        pageSize: 250,
        isQuoteboard: 0,
        msId: null,
        fundId: "",
        indGrpCode: "",
        isExport: false,
        cacheId: -1
        };
        

        SettingsStore

        const settings = SettingsStore.getConsoleSettings();
        const navListManagerSettings = settings && settings.NavListManagerSettings;
        const tabONeilSettings = navListManagerSettings && navListManagerSettings.TabONeilSettings;
        if (tabONeilSettings) {
          listCatalog.listId = tabONeilSettings.SelectedListInfo.SelectedListId;
          listCatalog.actualListId = tabONeilSettings.SelectedListInfo.SelectedActualListId;
          listCatalog.useColFilters = tabONeilSettings.ScreenOnOff == ListManager.ScreenOnOff ? 1 : 0;
          listCatalog.needBrowserCounts = tabONeilSettings.BrowserFilter.needBrowserCounts;
          listCatalog.browserFilterText = tabONeilSettings.BrowserFilter.browserFilterText;
          listCatalog.igOneilbrowserFilters = tabONeilSettings.BrowserFilter.igOneilbrowserFilters;
          listCatalog.igGicsbrowserFilters = tabONeilSettings.BrowserFilter.igGicsbrowserFilters;
          listCatalog.isOneilselected = tabONeilSettings.BrowserFilter.isOneilselected;
          listCatalog.isGicsselected = tabONeilSettings.BrowserFilter.isGicsselected;
        }
      }
      if (listCatalog.listId != listId) {
        listCatalog.searchText = '';
      }
      ONeilViewStore.clearSourecData();
      let listChangeStatus = ListExplorerStore.getListChangedStatus();
      if(listChangeStatus){
        ONeilViewStore.setLoadFilterDataStatus(true);
      }

      listCatalog.listId = listId;
      listCatalog.actualListId = actualListId;
      listCatalog.listName = listName;
      listCatalog.pageIndex = pageIndex;
      listCatalog.cacheId = cacheId != -1 ? cacheId : 0;
      if (ListExplorerStore.activeNode && ListExplorerStore.activeNode.categoryType == 9) {
        if(ListExplorerStore.activeSybol.msId === undefined || ListExplorerStore.activeSybol.symbol === undefined) {
          ListExplorerStore.activeSybol.msId = ListExplorerStore.activeNode.msid;
          ListExplorerStore.activeSybol.symbol = ListExplorerStore.activeNode.symbol;
        }
        listCatalog.msId = ListExplorerStore.activeSybol.msId;
        listCatalog.indGrpCode = ListExplorerStore.activeSybol.symbol;
        listCatalog.fundId = ListExplorerStore.activeNode.spId;
        if (ListExplorerStore.activeNode.temporaryListType)
          listCatalog.tmpListType = ListExplorerStore.activeNode.temporaryListType;
      }
      else {
        ListExplorerStore.activeSybol.symbol = undefined;
        ListExplorerStore.activeSybol.msId = undefined;
      }
      ONeilViewStore.initialCall = false;

      // Clearing browser filter for Crypto currency List
      if (ListExplorerStore.getIsCryptoCurrencyList() || ListExplorerStore.isFactorList() || ListExplorerStore.getIsFuturesCashSpotsList() || ListExplorerStore.isTempList()) {
        listCatalog.needBrowserCounts = 1;
        listCatalog.browserFilterText = "-1;-1;-1;";
        listCatalog.igOneilbrowserFilters = "-1;-1;-1;";
        listCatalog.igGicsbrowserFilters = "-1;-1;-1;-1;";
      }
      if (listCatalog.listId === 1601 || listCatalog.listId === 1602 || ListExplorerStore.isFactorList()) {
        listCatalog.pageSize = 999999
      }
      ONeilViewStore.listCatalog = listCatalog;
      this.getListCatalog(listCatalog, isInit);
    }
  }

  fetchMoreDataOnScroll(listCatalog) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL,
      data: { listCatalog: listCatalog }
    });
  }

  reloadListCatalog() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG,
      data: {}
    })
  }

  getListCatalogForSearchText(searchText) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.GET_SEARCH_TEXT,
      data: searchText
    });
  }

  ClearSearchText(searchText) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.CLEAR_SEARCH_TEXT,
      data: searchText
    });
  }

  getAddItemsToList(itemCopyToList, nodeTitle) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST,
      data: { itemCopyToList: itemCopyToList, nodeTitle: nodeTitle }
    })
  }

  getDuplicateList(duplicateList, parentNodeId, sourceCategoryType, currentAction) {
    let action = null;
    switch (currentAction) {
      case ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST:
        action = ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST;
        break;
      case ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES:
        action = ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES;
        break;
      case ListExplorerConstants.ActionTypes.COPY:
        action = ListExplorerConstants.ActionTypes.COPY
        break;
      case ListExplorerConstants.ActionTypes.COPY_PASTE:
        action = ListExplorerConstants.ActionTypes.COPY_PASTE
        break;
      default:
        break;
    }
    AppDispatcher.handleServerAction({
      actionType: action,
      data: { duplicateList: duplicateList, parentNodeId: parentNodeId, sourceCategoryType: sourceCategoryType }
    });
  }

  getCreateTreeNode(myList, parentNodeId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_CREATE_TREE_NODE,
      data: { myList: myList, parentNodeId: parentNodeId }
    });
  }

  getUpdateNodeDescription(updatedDecriptionList) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_UPDATE_DESCRIPTION,
      data: { updatedDecriptionList: updatedDecriptionList }
    });
  }

  getDeleteTreeNode(nodeIds, nodeName, currentAction) {
    let action = null;
    switch (currentAction) {
      case ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE:
        action = ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE;
        break;
      case ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES:
        action = ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES;
        break;
      default:
        break;
    }
    
    AppDispatcher.handleServerAction({
      actionType: action,
      data: { nodeIds: nodeIds, nodeName: nodeName }
    });
  }

  getUpdateListNode(nodeId, nodeName, parentNodeId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_UPDATE_LIST_NODE,
      data: { nodeId: nodeId, nodeName: nodeName, parentNodeId: parentNodeId }
    });
  }

  getRefreshedView() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.GET_REFRESHED_VIEW,
      data: {}
    })
  }
  listRename(listName) {    
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.LIST_RENAME_OWNERSHIP,
      data: { listName: listName }
    })
  }
  listRenameOnOneilTab(listName) {    
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.LIST_RENAME_OENIL,
      data: { listName: listName }
    })
  }
  getColumnSet(activeListId, SelectedTabKey) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_COLUMN_SET,
      data: { activeListId: activeListId, SelectedTabKey: SelectedTabKey }
    });
  }

  addNewColumnSet(colSetName, activeListId, parentId, dbtype) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET,
      data: { colSetName: colSetName, activeListId: activeListId, parentId: parentId, dbtype: dbtype }
    });
  }

  renameColumnSet(newColSetName, colsetId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.RENAME_COLUMN_SET,
      data: { newColSetName: newColSetName, colsetId: colsetId }
    });
  }

  addRenameColumnSetFolder(newColSetFolderName, consoleID, folderID, dbtype) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.ADD_RENAME_COLUMN_SET_FOLDER,
      data: { newColSetFolderName: newColSetFolderName, consoleID: consoleID, folderID: folderID, dbtype: dbtype }
    });
  }

  removeColumnSet(columnsetId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET,
      data: { columnsetId: columnsetId }
    });
  }

  removeColumnSetFolder(columnsetId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET_FOLDER,
      data: { columnsetId: columnsetId }
    });
  }

  resetColumnSet(columnsetId, activeListId, columnSetName) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.RESET_COLUMN_SET,
      data: { columnsetId: columnsetId, activeListId: activeListId, columnSetName: columnSetName }
    });
  }

  duplicateColumnSet(duplicateColumnSetName, activeListId, srcColSetId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.DUPLICATE_COLUMN_SET,
      data: { duplicateColumnSetName: duplicateColumnSetName, activeListId: activeListId, srcColSetId: srcColSetId }
    });
  }

  getNodeDetails(nodeId, nodeType = 1) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_NODE_DETAILS,
      data: { nodeId: nodeId, nodeType: nodeType }
    });
  }

  changeColumnSetLocation(parentNodeID, columnsetId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.CHANGE_COLUMN_SET_LOCATION,
      data: { parentNodeID: parentNodeID, columnsetId: columnsetId }
    });
  }

  restoreTreeNode(nodeIds) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.RESTORE_TREE_NODE,
      data: { nodeIds: nodeIds }
    });
  }

  selectColumnSet(searchText) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.SELECT_COLUMN_SET,
      data: searchText
    });
  }

  changeOnclickFilterSet(parentName, nodeFilterId, filterName) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.BROWSER_FILTER,
      data: { parentName: parentName, nodeFilterId: nodeFilterId, filterName: filterName }
    });
  }

  updateFilterDataSetType(dataSetType) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.BROWSER_FILTER_DATASETTYPE,
      data: { dataSetType: dataSetType }
    });
  }

  getCountryRegionData() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.BROWSER_FILTER_COUNTRY_REGION,
      data: {}
    });
  }

  resetFilterSet(type) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.BROWSER_FILTER_RESET,
      data: { type: type }
    });
  }

  getMetricLibrary() {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_METRIC_LIBRARY,
      data: {}
    });
  }

  removeItemsFromUserList(removeItemsFromUserList) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.REMOVE_ITEMS_FROM_USER_LIST,
      data: removeItemsFromUserList
    });
  }

  getMetricSearchData(data, keyword, isColumnFilter) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.GET_METRIC_SEARCH_TEXT,
      data: { data: data, keyword: keyword, isColumnFilter: isColumnFilter }
    });
  }

  dataLoadingComplete(listState) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.ON_DATA_LOADING_COMPLETE,
      data: {listState: listState}
    });
  }

  addSymbolComplete(source, listState) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.ON_ADD_SYMBOL_COMPLETE,
      data: { source: source, listState: listState}
    });
  }

  getListColumnPickList(columnId) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.GET_COLUMN_PICKLIST,
      data: { columnId: columnId }
    });
  }

  showOriginal() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.SHOW_ORIGINAL,
      data: {}
    });
  }

  showMetricLibraryFromColumnFilter() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.SHOW_METRIC_LIBRARY_COLUMN_FILTER,
      data: {}
    });
  }
  updateColumnNameinFilter(itemdata) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.UPDATE_METRIC_COLUMN_FILTER,
      data: itemdata
    });
  }

  toggleMetricLibrary() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.TOGGLE_METRIC_LIBRARY,
      data: {}
    });
  }

  browseMetricLibrary() {
    this.updateListExploreronMetricLibraryVisibility(null, true);
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.BROWSE_METRIC_LIBRARY,
      data: {}
    });
  }
  closeBrowseMetrics() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.CLOSE_CUSTOM_METRICS,
      data: {}
    });
  }
  openCustomMetric(isShow) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.OPEN_CUSTOM_METRICS_CLICK,
      data: isShow
    });
  }
  customMetricUpdate(isCatLoad = false, dataItemId = 0, closeDialog = false, nextAvailabDetail = null) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.CUSTOM_METRIC_CHANGED,
      data: { isCatLoad: isCatLoad, dataItemId: dataItemId, closeDialog: closeDialog, nextAvailabDetail: nextAvailabDetail }
    });
  }
  openMetricLibrary(isShow) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.OPEN_CUSTOM_METRICS,
      data: isShow
    });
  }

  setMetricLibraryState(isVisible) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.METRIC_LIBRARY_VISIBILITY,
      data: isVisible
    });
  }

  updateListExploreronMetricLibraryVisibility(width, isMetricLibraryOpen) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.UPDATE_LIST_EXPLORER_WIDTH,
      data: { width: width, isMetricLibraryOpen: isMetricLibraryOpen }
    });
  }

  toggleScreenOnOff(isOnOrOff) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.SCREEN_ON_OFF,
      data: isOnOrOff
    });
  }

  getListCatalogForScreenOff(listCatalog) {
    let esBasic = null;
    const isUserList = ListExplorerStore.isUserListOrFavoriteUserList();
    TimeTrackingWindow.resetTimeTracker(true, false, NavType.NavLists);
    TimeTrackingWindow.initTimeTracker();
    TimeTrackingWindow.beginTimeTracker(NavType.NavLists);
    if (isDefaultSymbolCallRequired && !PanarayDefault.loginCalled) {
      this.getDefaultSymbolData().then(() => {
        TimeTrackingWindow.beginListContextApiTimeTracker();
        let BasicEsInfoData = BasicEsInfo.setBasicEsInfoFromListCatalogData(listCatalog);
        esBasic = ListApi.getListCatalogEsData(BasicEsInfoData, false, isUserList);
        isDefaultSymbolCallRequired = false;
      });
    }
    else {
      TimeTrackingWindow.beginListContextApiTimeTracker();
      const BasicEsInfoData = BasicEsInfo.setBasicEsInfoFromListCatalogData(listCatalog);
      esBasic = ListApi.getListCatalogEsData(BasicEsInfoData, false, isUserList);
    }
    BrowserUtil.disableEvents()
    esBasic.promise.then((result) => {
      BrowserUtil.enableEvents()
      TimeTrackingWindow.endListContextApiTimeTracker();
      if (!result || !result.responseHeader || result.responseHeader.error) {
        TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, true, NavType.NavLists);
      }
      try {
        AppDispatcher.handleServerAction({
          actionType: ListExplorerConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG,
          data: { result: result }
        })
      }
      catch (e) {
        console.log(e);
        TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, true, NavType.NavLists);
        ConsoleActions.exceptionHandle();
      }
    })
  }

  addSymbolsToList(targetListId, targetNodeName, currentNodeId, msids = null) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.ADD_SYMBOLS_TO_LIST,
      data: { targetListId: targetListId, targetNodeName: targetNodeName, currentNodeId: currentNodeId, msids: msids }
    });
  }

  changeTextSize(textSize) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.CHANGE_TEXT_SIZE,
      data: { textSize: textSize }
    });
  }

  moveListItems(itemToMove, newParent) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.MOVE_LIST_ITEMS,
      data: { itemToMove: itemToMove, newParent: newParent }
    });
  }

  saveScreenedResults() {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.SAVE_SCREENED_RESULTS
    });
  }

  updateMiniList(sym) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.UPDATE_MINI_LIST,
      data: { symbol: sym }
    });
  }

  toggleAlertDialog100Columns(isOpen) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.TOGGLE_DIALOG_100_COLUMNS,
      data: { isOpen: isOpen }
    });
  }

  resetCountryBadge() {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.RESET_COUNTRYBADGE_SET,
    });
  }

  setMiniListAction(isMiniListItemClicked) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.SET_MINILIST_ACTION,
      data: { isMiniListItemClicked: isMiniListItemClicked }
    });
  }
  setSelectedExternalList(result) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.SET_CURRENT_EXTERNAL_LIST,
      data: { selectedExternalList: result }
    })
  }
  setActiveListName(listName) {    
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.SET_ACTIVE_LIST_NAME_EXTERNAL,
      data: { listName: listName }
    })
  }
  
  setInitialExternalList(listId,listName) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.INIT_LOAD_EXTERNAL_LIST,
      data: { listid: listId, listname: listName }
    })
  }

  setSelectedExternalListInfo(listId,listName,rowCount,totalCount,lastUploadDateTime) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.SET_LOADED_EXTERNAL_LIST_DETAILS,
      data: { listid: listId, listname: listName, rowcount: rowCount, totalcount: totalCount, lastUploadDateTime: lastUploadDateTime }
    })
  }

  setTempListName(result) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.SET_TEMP_LIST,
      data: { tempListName: result }
    })
  }

  setExternalList(result) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.SET_EXTERNAL_LIST,
      data: { externalList: result }
    })
  }

  addExternalList(data) {
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.ADD_NEW_EXTERNAL_LIST,
      data: { newExternalList: data }
    })
  }
  deleteExternalList(externalListId,listName) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.REMOVE_EXTERNAL_LIST,
      data: { externalListId : externalListId,  name : listName }
    });
  }
  
  setSelectedExternalNodes(selectedNodes) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.SET_EXTERNAL_SELECTED_NODES,
      data: { selectedNodes : selectedNodes  }
    });
  }
  showAlertExternalDuplicate(listName){
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.SHOW_ALERT_DUPLICATE_EXTERNAL,
      data: { listName : listName }
    });
  }
  isDeleteUndoExistExternal(){    
    AppDispatcher.handleViewAction({
      actionType: ListExplorerConstants.ActionTypes.IS_DELETE_UNDO_EXIST_EXTERNAL,
      data: {}
    });
  }
  initiateListRollBack(listId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.ROLL_BACK_ACTION,
      data: { listId : listId }
    });
  }

  removeDeletedList(deletedListIds,listType) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.REMOVE_DELETED_NODES,
      data: { deleteIds : deletedListIds, listType: listType }
    });
  }

  showRollBackSuccess(listId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.LIST_ROLL_BACK_SUCCESS,
      data: { rollBackId : listId }
    });
  }

  reUploadSuccess(listId) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.LIST_RE_UPLOAD_SUCCESS,
      data: { listId : listId }
    });
  }
  restoreExternalNodes(nodeIds) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.RESTORE_EXTERNAL_NODE,
      data: { nodeIds: nodeIds }
    });
  }


  sortedList(sortedList) {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.SORT_EXTERNAL_DATA,
      data: { sortedData: sortedList }
    });
  }
  externalHeaderModified() {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.EXTERNAL_HEADER_CHANGED
    });
  }

  getNotImportedListData() {
    AppDispatcher.handleServerAction({
      actionType: ListExplorerConstants.ActionTypes.GET_NOT_IMPORTED_LIST_DATA
    });
  }

}

const ListActions = new ListActionsClass();

export default ListActions;

export const createNotImportedList = (activeSybol) => {
  const createDate = moment().format("YYYYMMDDHHmm");
  ListApi.getNotImportList(activeSybol.listName, activeSybol.exceptionSymbols, createDate, activeSybol.databaseSourceID, activeSybol.bulkData).then((result) => {
    if (!result.data) {
      AppDispatcher.handleViewAction({
        actionType: ListExplorerConstants.ActionTypes.GET_TEMP_LIST,
        data: null
      });
    }
    else {
      const node = result.data.node;
      const symbol = '';
      const msId = '';
      const view = activeSybol.selectedTab;
      const sponsorId = '';
      dispatch(updateNupIsNonImported(true));
      AppDispatcher.handleViewAction({
        actionType: ListExplorerConstants.ActionTypes.GET_TEMP_LIST,
        data: {
          symbol: symbol,
          node: node,
          msId: msId,
          view: view,
          spId: sponsorId
        }
      });
    }
  });
}

export const CreateTemplisttoExplorer = (activeSybol) => {
  let type = 1;

  switch (activeSybol.view) {
    case SmartViewType.SECTOR:
    case SmartViewType.CONSTITUENTS:
      type = 2;
      break;
    case SmartViewType.MAJORINDUSTRY:
      type = 3;
      break;
    case SmartViewType.INDEX:
      type = 5;
      break;
    case SmartViewType.OWNERSHIP:
    case SmartViewType.HOLDINGS:
      type = 4;
      break;
    default:
      type = 1;
      break;
  }

  const symbol = activeSybol.isGlobal
    ? `Global.${activeSybol.symbol}`
    : activeSybol.symbol
  const sponsorId = activeSybol.sponsorId ? activeSybol.sponsorId : '';
  GetTempList(symbol, activeSybol.msId, type, sponsorId).then((result) => {
    if (!result.data) {
      AppDispatcher.handleViewAction({
        actionType: ListExplorerConstants.ActionTypes.GET_TEMP_LIST,
        data: null
      });
    }
    else {
      const node = result.data.node;
      const msId = activeSybol.msId;
      const view = activeSybol.view;

      switch (view) {
        case SmartViewType.SECTOR:
        case SmartViewType.CONSTITUENTS:
        case SmartViewType.MAJORINDUSTRY: 
        case SmartViewType.INDUSTRYGROUP:
        case SmartViewType.INDEX:
          if (activeSybol.isGlobal) {
            node.name = `Global ${node.name}`;
          }
          node.description = `This is a temporary list that contains constituents of ${node.name.split(':')[1]}`;
          if (view === SmartViewType.SECTOR || view === SmartViewType.CONSTITUENTS) {
            node.temporaryListType = TemporaryListType.SECTORCONSTITUENTS_TEMPORARYLIST;
          }
          else if (view === SmartViewType.MAJORINDUSTRY) {
            node.temporaryListType = TemporaryListType.MAJORINDUSTRYCONSTITUENTS_TEMPORARYLIST;
          }
          else if (view === SmartViewType.INDUSTRYGROUP) {
            node.temporaryListType = TemporaryListType.IGCONSTITUENTS_TEMPORARYLIST;
          }
          else if (view === SmartViewType.INDEX) {
            node.temporaryListType = TemporaryListType.INDEXCONSTITUENTS_TEMPORARYLIST;
          } else {
            node.temporaryListType = TemporaryListType.UNKNOWN_TEMPORARYLIST;
          }
          break;
        case SmartViewType.OWNERSHIP:
        case SmartViewType.HOLDINGS:
          node.description = `This is a temporary list that contains holdings of ${node.name.split(':')[1]}`;
          node.temporaryListType = TemporaryListType.FUNDHOLDINGS_TEMPORARYLIST;
          break;
        default:
          if (activeSybol.isGlobal) {
            node.name = node.name.replace("Industry Group", 'Global IG')
          }
          else {
            node.name = node.name.replace("Industry Group", 'IG');
          }
          break;

      }
      dispatch(updateNupIsNonImported(false));
      AppDispatcher.handleViewAction({
        actionType: ListExplorerConstants.ActionTypes.GET_TEMP_LIST,
        data: {
          symbol: symbol,
          node: node,
          msId: msId,
          view: view,
          spId: sponsorId
        }
      });
    }
  });
}

export const UpdateTemplisttoExplorer = (activeSybol) => {
  if (!activeSybol.msId) {
    ChartApi.findStockInfo(activeSybol.symbol).then((result) => {
      activeSybol.msId = result.MsId;
      CreateTemplisttoExplorer(activeSybol);
    });
  }
  else {
    CreateTemplisttoExplorer(activeSybol);
  }
}

export const GetTempListData = (activeTempNode) => {

  AppDispatcher.handleViewAction({
    actionType: ListExplorerConstants.ActionTypes.GET_TEMP_LIST_DATA,
    data: activeTempNode
  });

}

export const isTemplListVisible = (symbol, msID, isTemplistVisible) => {
  AppDispatcher.handleViewAction({
    actionType: ListExplorerConstants.ActionTypes.IS_TEMP_LIST_VISIBLE,
    data: {
      symbol: symbol,
      msID: msID,
      isTemplistVisible: isTemplistVisible
    }
  });

}

export const UpdateActiveSymbol = (activeSymbol) => {

  AppDispatcher.handleViewAction({
    actionType: ListExplorerConstants.ActionTypes.UPDATE_ACTIVE_SYMBOL,
    data: activeSymbol
  });

}

export const updateSelectedListItem = (listId, actualListId, listName, categoryType, parentNodeId, parentCategoryType, parentCategoryName, parentListType, sourceCategoryType, sourceParentCategoryName) => {
  AppDispatcher.handleViewAction({
    actionType: ListExplorerConstants.ActionTypes.UPDATE_SELECTED_LIST_ITEM,
    data: {
      listId: listId,
      actualListId: actualListId,
      listName: listName,
      categoryType: categoryType,
      parentNodeId: parentNodeId,
      parentCategoryType: parentCategoryType,
      parentCategoryName: parentCategoryName,
      parentListType: parentListType,
      sourceCategoryType: sourceCategoryType,
      sourceParentCategoryName: sourceParentCategoryName
    }
  });

}

export const IsDeleteEnable = (source, activeNode) => {
  let isDelete = false;
  if (source) {
    if (activeNode && (activeNode.categoryType === (ListCategoryType.USER_ListCategory) || activeNode.categoryType === ListCategoryType.FAVORITE_ListCategory || activeNode.categoryType === ListCategoryType.SHAREDLIST_ListCategory || activeNode.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory || activeNode.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory)) {
      isDelete = true;
    }
  }
  AppDispatcher.handleViewAction({
    actionType: OwnershipViewConstants.ActionTypes.ISDELETE_ENABLE,
    data: isDelete
  });

}

export const LoadConstituents = (activeData) => {
  if (activeData.length > 0) {
    //activeData.map((rowData) => {
    for (let i = 0; i < activeData.length; i++) {
      const rowData = activeData[i];
      UpdateTemplisttoExplorer(
        {
          symbol: rowData.symbol,
          msId: rowData.msId,
          view: SmartViewType.OWNERSHIP,
          isGlobal: false,
          sponsorId: rowData.sponsorId
        }
      )
    }
    //});
  }
}
