import BaseServiceApi from 'BaseServiceApi'
import GetListExplorerRequest from '../Requests/GetListExplorerRequest'
import GetListCatalogRequest from '../Requests/GetListCatalogRequest'
import GetPageListRowsRequest from '../Requests/GetPageListRowsRequest'
import GetDuplicateListRequest from '../Requests/GetDuplicateListRequest'
import GetAddItemsToListRequest from '../Requests/GetAddItemsToListRequest'
import GetCreateTreeNodeRequest from '../Requests/GetCreateTreeNodeRequest'
import UpdateNodeDescriptionRequest from '../Requests/UpdateNodeDescriptionRequest'
import GetDeleteTreeNodeRequest from '../Requests/GetDeleteTreeNodeRequest'
import GetUpdateListNodeRequest from '../Requests/GetUpdateListNodeRequest'
import AddColumnSetWidthRequest from '../Requests/AddColumnSetWidthRequest'
import AddColumnToListRequest from '../Requests/AddColumnToListRequest'
import ReorderColumnSetItemsRequest from '../Requests/ReorderColumnSetItemsRequest'
import RemoveColumnToListRequest from '../Requests/RemoveColumnToListRequest'
import GetColumnSetRequest from '../Requests/GetColumnSetRequest'
import AddNewColumnSetRequest from '../Requests/AddNewColumnSetRequest'
import RenameColumnSetRequest from '../Requests/RenameColumnSetRequest'
import AddRenameColumnSetFolderRequest from '../Requests/AddRenameColumnSetFolderRequest'
import RemoveColumnSetRequest from '../Requests/RemoveColumnSetRequest'
import RemoveColumnSetFolderRequest from '../Requests/RemoveColumnSetFolderRequest'
import ResetColumnSetRequest from '../Requests/ResetColumnSetRequest'
import DuplicateColumnSetRequest from '../Requests/DuplicateColumnSetRequest'
import GetNodeDetailsRequest from '../Requests/GetNodeDetailsRequest'
import ChangeColumnSetLocationRequest from '../Requests/ChangeColumnSetLocationRequest'
import GetListBrowserRequest from '../Requests/GetListBrowserRequest'
import GetListIgOneilBrowserRequest from '../Requests/GetListIgOneilBrowserRequest'
import GetListIgGicsBrowserRequest from '../Requests/GetListIgGicsBrowserRequest'
import GetMetricsLibraryRequest from '../Requests/GetMetricsLibraryRequest'
import RestoreTreeNodeRequest from '../Requests/RestoreTreeNodeRequest'
import GetCountryRegionListRequest from '../Requests/GetCountryRegionListRequest'
import GetListColumnPickListRequest from '../Requests/GetListColumnPickListRequest'
import UpdateGlobalFlagAllRequest from '../Requests/UpdateGlobalFlagAllRequest'
import UpdateGlobalFlagRequest from '../Requests/UpdateGlobalFlagRequest'
import RemoveItemsFromUserListRequest from '../Requests/RemoveItemsFromUserListRequest'
import AddInstrumentsRequest from '../Requests/AddInstrumentsRequest'
import AddOwnerInstrumentsRequest from '../Requests/AddOwnerInstrumentsRequest'
import DeleteOwnerInstrumentsRequest from '../Requests/DeleteOwnerInstrumentsRequest'
import ReorderItemsInUserListRequest from '../Requests/ReorderItemsInUserListRequest'
import ChangeVisualizationSettingRequest from '../Requests/ChangeVisualizationSettingRequest'
import GetEligibleListsRequest from '../Requests/GetEligibleListsRequest'
import GetListCatalogRequestES from '../Requests/GetListCatalogRequestES'
import ExportListRequest from '../Requests/ExportListRequest'
import ExportOwnershipListListRequest from '../Requests/ExportOwnershipListRequest'
import GetDefaultSymbolRequest from '../Requests/GetDefaultSymbolRequest'
import GetMsidFlagRequest from '../Requests/GetMsidFlagRequest'
import GetOwnershipFlagRequest from '../Requests/GetOwnershipFlagRequest'
import GetDuplicateListRequestES from '../Requests/GetDuplicateListRequestES'
import GetDuplicateDataSetRequest from '../Requests/GetDuplicateDataSetRequest'
import UpdateOrdinalNumberRequest from '../Requests/UpdateOrdinalNumberRequest'
import GetListBenchmarkStatsRequest from '../Requests/GetListBenchmarkStatsRequest'
import GetListSummaryStatsRequest from '../Requests/GetListSummaryStatsRequest'
import GetListColumnDistributionRequest from '../Requests/GetListColumnDistributionRequest';
import GetTempListRequest from '../Requests/GetTempListRequest'
import GetListDefaultGicsBrowserRequest from '../Requests/GetListDefaultGicsBrowserRequest';
import GetListDefaultOneilBrowserRequest from '../Requests/GetListDefaultOneilBrowserRequest';
import GetScrnSchBlockedListsRequest from '../Requests/GetScrnSchBlockedListsRequest';
import GetScreenScheduleListRequest from '../Requests/ScreenSchedule/GetScreenScheduleListRequest';
import GetScreenScheduleRequest from '../Requests/ScreenSchedule/GetScreenScheduleRequest';
import DeleteScreenScheduleRequest from '../Requests/ScreenSchedule/DeleteScreenScheduleRequest';
import UpsertScreenScheduleRequest from '../Requests/ScreenSchedule/UpsertScreenScheduleRequest';
import GetOwnerSponsorsBlkRequest from '../Requests/GetOwnerSponsorsBlkRequest';
import GetOwnershipListCatalogRequest from '../Requests/GetOwnershipListCatalogRequest';
import DuplicateCustomMetric from '../Requests/DuplicateCustomMetric';
import DeleteCustomMetric from '../Requests/DeleteCustomMetric';
import CustomColumnGetRequest from '../Requests/CustomColumnGetRequest';
import RenameCustomMetric from '../Requests/RenameCustomMetric';
import CreateCustomMetrics from '../Requests/CreateCustomMetrics';
import GetCustColUnits from '../Requests/GetCustColUnits';
import ListAlertStatusRequest from '../Requests/ListAlertStatusRequest';
import GetColumnSetItemsRequest from '../Requests/GetColumnSetItemsRequest';
import GetRollBackListRquest from '../Requests/GetRollBackListRequest';
import GetNotImportListRequest from '../Requests/GetNotImportListRequest'
import DatagraphDataType from '../../Constants/DatagraphDataType'
import NavType from '../../Constants/NavType'
import CheckGICSColumnRequest from '../Requests/CheckGICSColumnRequest'
import GetNupRequest from '../Requests/GetNupRequest.js'
import GetRecentListsRequest from '../Requests/GetRecentListsRequest'


const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

class ListApi {

  getListExplorer(dbId, consoleId, isUserOnly, isSharedLists, parent) {
    const req = new GetListExplorerRequest(dbId, consoleId, isUserOnly, isSharedLists, parent);
    return BaseServiceApi.processServiceCall(req);
  }

  getMetricLibrary(selectedTab) {
    const req = new GetMetricsLibraryRequest(selectedTab);
    return BaseServiceApi.processServiceCall(req);
  }

  getDefaultSymbolData(entId) {
    const req = new GetDefaultSymbolRequest(entId)
    return BaseServiceApi.processServiceCall(req);
  }

  getListCatalogEsData(BasicEsInfoData, isNupVieworMiniList = false, isUserList = false, activeNodeId) {
    try {
      if (isUserList && isNupVieworMiniList == false) {
        this.setCustomOrder(activeNodeId ? activeNodeId : BasicEsInfoData.listId, true);
      }
      if (BasicEsInfoData.listId === 1601 || BasicEsInfoData.listId === 1602) {
        BasicEsInfoData.browserFilter = '-1;-1;-1'
        BasicEsInfoData.gicsBrowserFilter = '-1;-1;-1;-1'
        BasicEsInfoData.oneilBrowserFilter = '-1;-1;-1'
      }
      const req = new GetListCatalogRequestES(BasicEsInfoData, isNupVieworMiniList);
      const cancelablePromise = makeCancelable(BaseServiceApi.processServiceCall(req));
      return cancelablePromise;
    }
    catch (e) {
      console.log(e);
      TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListContent, true, NavType.NavLists);
    }
  }

  checkGICSColumn(listId, consoleID){
    const req = new CheckGICSColumnRequest(listId, consoleID)
    return BaseServiceApi.processServiceCall(req);
  }

  exportList(BasicEsInfoData, exportType, exportAll, selectedMsids, currentDataBase, hasGicsEntitlement, lang, isCryptoCurrencyList) {
    try {
      if (BasicEsInfoData.isUserList) {
        this.setCustomOrder(BasicEsInfoData.listId, true);
      }
      const req = new ExportListRequest(BasicEsInfoData, exportType, exportAll, selectedMsids, currentDataBase, hasGicsEntitlement, lang, isCryptoCurrencyList);
      const cancelablePromise = makeCancelable(BaseServiceApi.processServiceCall(req));
      return cancelablePromise;
    }
    catch (e) {
      console.log(e);
    }
  }

  exportOwnershipList(listId,exportType,exportAll,selectedMsids,currentDataBase,hasGicsEntitlement,actualListId,searchText,
    sortCols,needColumnsInfo,useColFilters,saveColFilters,isMinilist,isUserList,customerOrder,pageIndex,size,colsetId,addlCatalogRequests,lang, isNotImportedList) {
    try {
      const req = new ExportOwnershipListListRequest(listId,exportType,exportAll,selectedMsids,currentDataBase,hasGicsEntitlement,actualListId,searchText,
        sortCols,needColumnsInfo,useColFilters,saveColFilters,isMinilist,isUserList,customerOrder,pageIndex,size,colsetId,addlCatalogRequests,lang, isNotImportedList);
      const cancelablePromise = makeCancelable(BaseServiceApi.processServiceCall(req));
      return cancelablePromise;
    }
    catch (e) {
      console.log(e);
    }
  }

  getOwnersFlagRequest(listId, spIds){
    //let response = ListApi.getOwnersFlagRequest(785976, "68150;63313");
    try {
      const req = new GetOwnershipFlagRequest(listId, spIds)
      return BaseServiceApi.processServiceCall(req);
    }
    catch (e) {
      console.log(e);
    }
  }

  getMsidFlagRequest(listId, msIds) {
    try {
      const req = new GetMsidFlagRequest(listId, msIds)
      return BaseServiceApi.processServiceCall(req);
    }
    catch (e) {
      console.log(e);
    }
  }
  //deleted
  getListCatalog(listCatalog) {
    const req = new GetListCatalogRequest(listCatalog.listId, listCatalog.actualListId, listCatalog.searchText, listCatalog.sortCols, listCatalog.initialNumRows, listCatalog.needColumnsInfo, listCatalog.needBrowserCounts, listCatalog.browserFilterText, listCatalog.addlCatalogRequests, listCatalog.useColFilters, listCatalog.saveColFilters, listCatalog.customerOrder, listCatalog.colsetId, listCatalog.igOneilbrowserFilters, listCatalog.igGicsbrowserFilters, listCatalog.isOneilselected, listCatalog.isGicsselected, listCatalog.isNupview);
    const cancelablePromise = makeCancelable(BaseServiceApi.processServiceCall(req));
    return cancelablePromise;
  }
  //deleted
  getPageListRows(pageListRows) {
    const req = new GetPageListRowsRequest(pageListRows.listId, pageListRows.cacheId, pageListRows.seqNumber, pageListRows.pageSize, pageListRows.isQuoteboard, pageListRows.fundMsid, pageListRows.spId);
    const cancelablePromise = makeCancelable(BaseServiceApi.processServiceCall(req));
    return cancelablePromise;
  }

  getDuplicateDataSet(name,listId){
    const req = new GetDuplicateDataSetRequest(name,listId);
    return BaseServiceApi.processServiceCall(req);
  }

  getDuplicateList(duplicateList, isSaveScreenedResults, basicEsInfo, searchText=null) {
    let req;
    if (!isSaveScreenedResults && isSaveScreenedResults != undefined) {
      req = new GetDuplicateListRequest(duplicateList.symCode, duplicateList.parentNodeId, duplicateList.name, duplicateList.sourceListId, duplicateList.destListId, duplicateList.cacheId, duplicateList.sortCols, duplicateList.isFav, duplicateList.isFlagList, duplicateList.spId,searchText)
    }
    else {
      let basicinfo;
      basicinfo = _.clone(basicEsInfo);
      const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

      if (ListCategoryType.FAVORITE_ListCategory === ListCategoryType.FAVORITE_ListCategory) {
        basicinfo.listId = basicEsInfo.actualListId, basicinfo.actualListId = basicEsInfo.listId;
      }
      if (duplicateList.symCode && duplicateList.symCode !== "" && basicEsInfo.isTempList) {
        basicinfo.listId = duplicateList.sourceListId;
        basicinfo.actualListId = duplicateList.sourceListId;
      }
      let isDupFav = false;
      if(duplicateList.isDupFav){
        isDupFav = true;
      }
      req = new GetDuplicateListRequestES(basicinfo, duplicateList.symCode, duplicateList.parentNodeId, duplicateList.name, duplicateList.sourceListId, duplicateList.destListId, duplicateList.cacheId, duplicateList.sortCols, duplicateList.isFav, duplicateList.isFlagList, duplicateList.spId,isDupFav)
   }
    return BaseServiceApi.processServiceCall(req);
  }

  getAddItemsToList(itemCopyToList) {
    const req = new GetAddItemsToListRequest(itemCopyToList.targetListId, itemCopyToList.consoleId, itemCopyToList.sourceId, itemCopyToList.saveScreenFilter, itemCopyToList.undoAdd);
    return BaseServiceApi.processServiceCall(req);
  }

  getCreateTreeNode(myList) {
    const req = new GetCreateTreeNodeRequest(myList.dbType, myList.parentNodeId, myList.name, myList.nodeType, myList.categoryType, myList.sourceListId);
    return BaseServiceApi.processServiceCall(req);
  }

  getUpdateNodeDescription(updatedDecriptionList) {
    const req = new UpdateNodeDescriptionRequest(updatedDecriptionList.nodeId, updatedDecriptionList.nodeType, updatedDecriptionList.newDescription);
    return BaseServiceApi.processServiceCall(req);
  }

  getDeleteTreeNode(nodeIds) {
    const req = new GetDeleteTreeNodeRequest(nodeIds);
    return BaseServiceApi.processServiceCall(req);
  }

  getUpdateListNode(nodeId, nodeName, parentNodeId) {
    const req = new GetUpdateListNodeRequest(nodeId, nodeName, parentNodeId);
    return BaseServiceApi.processServiceCall(req);
  }

  addColumnSetWidth(listId, colSeq, isQuoteboard) {
    const req = new AddColumnSetWidthRequest(listId, colSeq, isQuoteboard);
    return BaseServiceApi.processServiceCall(req);
  }

  addColumnToList(listId, dataItemId, lastColumnDataItemId, isFrozen, isQuoteboard) {
    const req = new AddColumnToListRequest(listId, dataItemId, lastColumnDataItemId, isFrozen, isQuoteboard);
    return BaseServiceApi.processServiceCall(req);
  }

  reorderColumnSetItems(listId, columnSetID, dataItemIds, removeItemIds, isQuoteboard) {
    const req = new ReorderColumnSetItemsRequest(listId, columnSetID, dataItemIds, removeItemIds, isQuoteboard);
    return BaseServiceApi.processServiceCall(req);
  }

  removeColumnToList(listId, dataItemId, isQuoteboard) {
    const req = new RemoveColumnToListRequest(listId, dataItemId, isQuoteboard);
    return BaseServiceApi.processServiceCall(req);
  }

  getColumnSet(activeListId, SelectedTabKey) {
    const req = new GetColumnSetRequest(activeListId, SelectedTabKey);
    return BaseServiceApi.processServiceCall(req);
  }

  addNewColumnSet(colSetName, activeListId, parentId, dbtype) {
    const req = new AddNewColumnSetRequest(colSetName, activeListId, parentId, dbtype);
    return BaseServiceApi.processServiceCall(req);
  }

  renameColumnSet(newColSetName, colsetId) {
    const req = new RenameColumnSetRequest(newColSetName, colsetId);
    return BaseServiceApi.processServiceCall(req);
  }

  addRenameColumnSetFolder(newColSetFolderName, consoleID, folderID, dbtype) {
    const req = new AddRenameColumnSetFolderRequest(newColSetFolderName, consoleID, folderID, dbtype);
    return BaseServiceApi.processServiceCall(req);
  }

  removeColumnSet(columnsetId) {
    const req = new RemoveColumnSetRequest(columnsetId);
    return BaseServiceApi.processServiceCall(req);
  }

  removeColumnSetFolder(columnsetId) {
    const req = new RemoveColumnSetFolderRequest(columnsetId);
    return BaseServiceApi.processServiceCall(req);
  }

  resetColumnSet(columnsetId, activeListId) {
    const req = new ResetColumnSetRequest(columnsetId, activeListId);
    return BaseServiceApi.processServiceCall(req);
  }

  duplicateColumnSet(duplicateColumnSetName, activeListId, srcColSetId) {
    const req = new DuplicateColumnSetRequest(duplicateColumnSetName, activeListId, srcColSetId);
    return BaseServiceApi.processServiceCall(req);
  }

  getNodeDetails(nodeId, nodeType) {
    const req = new GetNodeDetailsRequest(nodeId, nodeType);
    return BaseServiceApi.processServiceCall(req);
  }

  changeColumnSetLocation(parentNodeID, columnsetId) {
    const req = new ChangeColumnSetLocationRequest(parentNodeID, columnsetId);
    return BaseServiceApi.processServiceCall(req);
  }

  getListBrowserData(listBrowser) {
    const req = new GetListBrowserRequest(listBrowser);
    return BaseServiceApi.processServiceCall(req);
  }
  getListBrowserData_Cancelable(listBrowser){
    const req = new GetListBrowserRequest(listBrowser);
    return makeCancelable(BaseServiceApi.processServiceCall(req));
  }

  getListIgOneilBrowser(listBrowser) {
    const req = new GetListIgOneilBrowserRequest(listBrowser.listId, listBrowser.cacheId, listBrowser.isColumnFilterEnabled);
    return BaseServiceApi.processServiceCall(req);
  }

  getListIgGicsBrowser(listBrowser) {
    const req = new GetListIgGicsBrowserRequest(listBrowser.listId, listBrowser.cacheId, listBrowser.isColumnFilterEnabled);
    return BaseServiceApi.processServiceCall(req);
  }

  getListDefaultOneilBrowser() {
    const req = new GetListDefaultOneilBrowserRequest();
    return BaseServiceApi.processServiceCall(req);
  }

  getListDefaultGicsBrowser() {
    const req = new GetListDefaultGicsBrowserRequest();
    return BaseServiceApi.processServiceCall(req);
  }

  getScrnSchBlockedLists() {
    const req = new GetScrnSchBlockedListsRequest();
    return BaseServiceApi.processServiceCall(req);
  }

  getScreenScheduleList() {
    const req = new GetScreenScheduleListRequest();
    return BaseServiceApi.processServiceCall(req);
  }

  getScreenSchedule(screenid) {
    const req = new GetScreenScheduleRequest(screenid);
    return BaseServiceApi.processServiceCall(req);
  }

  deleteScreenSchedule(screenid) {
    const req = new DeleteScreenScheduleRequest(screenid);
    return BaseServiceApi.processServiceCall(req);
  }

  upsertScreenSchedule(screenId, listId, colsetId, geoFilterString, oneilFilterString, gicsFilterString, scheduleId, freqType,
    schTime, notifyByEmail, isEmbedinEmail, isExcelAttach, isCreateList, screenName, timeZone, nextRunTime, isNicknameSet, displayName2) {
    const req = new UpsertScreenScheduleRequest(screenId, listId, colsetId, geoFilterString, oneilFilterString, gicsFilterString, scheduleId, freqType,
      schTime, notifyByEmail, isEmbedinEmail, isExcelAttach, isCreateList, screenName, timeZone, nextRunTime, isNicknameSet, displayName2);
    return BaseServiceApi.processServiceCall(req);
  }

  getCountryRegionList() {
    const req = new GetCountryRegionListRequest();
    return BaseServiceApi.processServiceCall(req);
  }

  restoreTreeNode(nodeIds) {
    const req = new RestoreTreeNodeRequest(nodeIds);
    return BaseServiceApi.processServiceCall(req);
  }

  getListColumnPickList(consoleId, listId, cacheId, columnId, categoryId = 1) {
    const req = new GetListColumnPickListRequest(consoleId, listId, cacheId, columnId, categoryId);
    return BaseServiceApi.processServiceCall(req);
  }

  updateGlobalFlagAll(listId, cacheId, isDelete, dbType) {
    const req = new UpdateGlobalFlagAllRequest(listId, cacheId, isDelete, dbType);
    return BaseServiceApi.processServiceCall(req);
  }

  updateGlobalFlag(msids, snapshotdates, isDelete, cacheId, dbType) {
    const req = new UpdateGlobalFlagRequest(msids, snapshotdates, isDelete, cacheId, dbType);
    return BaseServiceApi.processServiceCall(req);
  }

  removeItemsFromUserList(listId, cacheId, msids, snapshotdates) {
    const req = new RemoveItemsFromUserListRequest(listId, cacheId, msids, snapshotdates);
    return BaseServiceApi.processServiceCall(req);
  }

  addInstrumentsRequest(listId, cacheId, consoleId, symbols, msids, snapshotdates, insertSeqNum, includeDetails, bulkInsertData, undoAdd, BrowserSelectedFilters = "-1,-1,-1", addType = 1) {
    const req = new AddInstrumentsRequest(listId, cacheId, consoleId, symbols, msids, snapshotdates, insertSeqNum, includeDetails, bulkInsertData, undoAdd, BrowserSelectedFilters, addType);
    return BaseServiceApi.processServiceCall(req);
  }

  AddOwnerInstrumentsRequest(listId, symbols, spIds){
    const req = new AddOwnerInstrumentsRequest(listId, symbols, spIds);
    return BaseServiceApi.processServiceCall(req);
  }

  deleteOwnerInstrumentsRequest(listId, symbols, spIds){
    const req = new DeleteOwnerInstrumentsRequest(listId, symbols, spIds);
    return BaseServiceApi.processServiceCall(req);
  }

  reorderOrdinalNumbers(listId, cacheId, consoleId, ranges, afterSeqNum, esbasicinfo) {
    const req = new ReorderItemsInUserListRequest(listId, cacheId, consoleId, ranges, afterSeqNum, esbasicinfo);
    return BaseServiceApi.processServiceCall(req);
  }

  changeVisualizationSettingRequest(listId, colSeq, isQuoteboard) {
    const req = new ChangeVisualizationSettingRequest(listId, colSeq, isQuoteboard);
    return BaseServiceApi.processServiceCall(req);
  }

  getEligibleLists() {
    const req = new GetEligibleListsRequest();
    return BaseServiceApi.processServiceCall(req);
  }

  setCustomOrder(activeListId, isReset) {
    const req = new UpdateOrdinalNumberRequest(activeListId, isReset);
    return BaseServiceApi.processServiceCall(req);
  }

  getSummaryStats(basicInfo, listId) {
    try {
      const req = new GetListSummaryStatsRequest(basicInfo, listId);
      return BaseServiceApi.processServiceCall(req);
    } 
    catch (e) {
      console.error(e);
      TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.SummaryStats_Data, true, NavType.NavLists);
    }
  }

  getListColumnDistribution(basicInfo, columnId) {
    try {
      const req = new GetListColumnDistributionRequest(basicInfo, columnId);
      return BaseServiceApi.processServiceCall(req);
    }
    catch (e) {
      console.error(e);
      TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.SummaryStats_Histogram, true, NavType.NavLists);
    }
  }

  getListBenchmarkStats(listId, cacheId, weightType) {
    const req = new GetListBenchmarkStatsRequest(listId, cacheId, weightType);
    return BaseServiceApi.processServiceCall(req);
  }

  getOwnerSponsorsBlkRequest(listId, isflaggedlist) {
    const req = new GetOwnerSponsorsBlkRequest(listId, isflaggedlist);
    return BaseServiceApi.processServiceCall(req);
  }

  getListAlertStatus(listId){
    const req = new ListAlertStatusRequest(listId);
    return BaseServiceApi.processServiceCall(req);
  }

  duplicateCustomMetric(metricId, name) {
    const req = new DuplicateCustomMetric(metricId, name);
    return BaseServiceApi.processServiceCall(req);
  }
  deleteCustomMetric(metricId) {
    const req = new DeleteCustomMetric(metricId);
    return BaseServiceApi.processServiceCall(req);
  }
  getCustomColumn(metricId) {
    const req = new CustomColumnGetRequest(metricId);
    return BaseServiceApi.processServiceCall(req);
  }
  renameCustomMetric(metricId, name){
    const req = new RenameCustomMetric(metricId,name);
    return BaseServiceApi.processServiceCall(req);
  }
  getCustColUnits() {
    const req = new GetCustColUnits();
    return BaseServiceApi.processServiceCall(req);
  }
  createCustomColumn(data) {
    const req = new CreateCustomMetrics(data);
    return BaseServiceApi.processServiceCall(req);
  }

  GetOwnerShipData(OwnershiplistCatalog) {
    const isNotImportedList = OwnershiplistCatalog.isNotImportedList === undefined ? false : OwnershiplistCatalog.isNotImportedList;
    const req = new GetOwnershipListCatalogRequest(OwnershiplistCatalog.listId, OwnershiplistCatalog.actualListId, OwnershiplistCatalog.searchText, OwnershiplistCatalog.sortCols, OwnershiplistCatalog.needColumnsInfo, OwnershiplistCatalog.addlCatalogRequests, OwnershiplistCatalog.useColFilters, OwnershiplistCatalog.saveColFilters, OwnershiplistCatalog.customerOrder, OwnershiplistCatalog.colsetId, OwnershiplistCatalog.reorderIds, OwnershiplistCatalog.pageIndex, OwnershiplistCatalog.size, OwnershiplistCatalog.isUserList, OwnershiplistCatalog.isMinilist, isNotImportedList);
    return BaseServiceApi.processServiceCall(req);
  }
   callRollBack(data) {
    const req = new GetRollBackListRquest(data);
    return BaseServiceApi.processServiceCall(req);
  }

  getColumnSetItems(consoleId, listId, columnSetId, isScreensch, isScreenSchEmbed) {
    const req = new GetColumnSetItemsRequest(consoleId, listId, columnSetId, isScreensch, isScreenSchEmbed);
    return BaseServiceApi.processServiceCall(req);
  }

  getNotImportList(listName, symbols, createDate, databaseSourceID, bulkInsertData) {
    const req = new GetNotImportListRequest(listName, symbols, createDate, databaseSourceID, bulkInsertData);
    return BaseServiceApi.processServiceCall(req);
  }

  getNupData(nupDataRequest) {
      const request = new GetNupRequest(nupDataRequest);
      return BaseServiceApi.processServiceCall(request);
  }

  getRecentLists(dbId) {
    const req = new GetRecentListsRequest(dbId);
    return BaseServiceApi.processServiceCall(req);
  }
}
const listApi = new ListApi();
export default listApi;

export const GetTempList = (industryCode, msid, type, spId) => {
  const req = new GetTempListRequest(industryCode, msid, type, spId);
  return BaseServiceApi.processServiceCall(req);
}
