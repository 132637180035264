import GetLangResourceByCodeRequest from '../Requests/GetLangResourceByCodeRequest'
import BaseServiceApi from 'BaseServiceApi'

class LocalizationApi {
  getLangResourceData(langCode) {
    const req = new GetLangResourceByCodeRequest(langCode);
    return BaseServiceApi.processServiceCall(req);
  }
}

var localizationApi = new LocalizationApi();
export default localizationApi;