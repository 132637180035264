import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
const { ActionTypes } = RiPanelConstants;
import { clone, map } from "underscore";

const initState = {
    isLoading: true,
    totalCount: 0,
    report: {},
    activeIndex: -1,
    nodata: true,
    style: {}
};

const RiPanelBrokerReports = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_BROKER_REPORTS:
            return {
                ...state,
                isLoading: action.isLoading,
                totalCount: action.totalCount,
                report: action.report,
                nodata: action.nodata
            }
        case ActionTypes.SET_VISITED_ITEMS_GREY:
            const visitedHeadlines = JSON.parse(localStorage.getItem("brokerReportVisitedHeadlines"));
            const item = {
                id: action.id,
                osid: action.osid
            }
            visitedHeadlines.push(item);
            localStorage.setItem("brokerReportVisitedHeadlines", JSON.stringify(visitedHeadlines));

            const data = clone(state.report);
            map(data, (item) => {
                if (item.id === action.id && item.osid === action.osid) {
                    item.isVisited = true;
                }
            });
            return {
                ...state,
                report: data
            }
        case ActionTypes.UPDATE_TOOLTIP_STYLE:
            return {
                ...state,
                style: action.style
            }
        default:
            return state;
    }
}
export default RiPanelBrokerReports;