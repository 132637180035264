import { fork, all } from 'redux-saga/effects';
import { map } from 'underscore';

import {
  watcherGetOwnershipData,
  watcheronOwnerShipDataReceived,
  watcherOnOwnerShipAumToggle,
  watcherOnOwnerShipFundToggle,
  watcherOnOwnerShipViewToggle,
  watcherOnOwnerShipSizeChanged,
  watcherPrepareHeaderMenu,
  watcherOnOwnerShipListChanged
} from './NavDataGraph/TabDataGraph/OwnerShipBlock'
import {
  watcherOne,
} from './NavDataGraph/TabDataGraph/SummaryBlock/SummaryBlock';

import {
  watchInsiderData,
  watchUpdateInsiderDimension
}
from './NavDataGraph/TabDataGraph/InsiderChart';

import {
  watchPrepareData,
  watchVolScaleChnage,
  watchUpdateVolumeDimension,
} from './NavDataGraph/TabDataGraph/VolumeChart';

import {
  watcherPrepareFinancialApiData,
  watcherPrepareFinancialData,
  watcherToggleHeader,
  watcherOnPageChange,
  watcherFinancialPeToggle,
  watcherSeletedMenuItem,
  watcherEpsRpsScaleChanged,
  watcherFundamentalLineChanged
  // watcherPeriodicityChange
} from './NavDataGraph/TabDataGraph/FinancialBlock/FinancialBlock.js';

import {
  watcherOpenFinancialDialogBox,
  watcheronSearchTextChange,
  watcheronSelectedMetricChanged,
  watcheronRestoreDefaultHeaders,
  watcheronSubmitChanges,
  watcherCallRestore
} from './NavDataGraph/TabDataGraph/FinancialBlock/FinancialBlockDialogBox';

import {
  watchGetExternalDataSubMenu,
  watchGetCurrentListData,
  watchRedrawOnChartResize,
  watchHideModalDialog,
  watchInitExternalData,
  watchClearStateData,
  watchUpdateToSettings,
  watchGetRiPanelData,
  watchHandleInputChange,
  watchSaveLabel,
  watchOverWriteSettingsOnReUpload,
  watchProcessListData
} from './NavDataGraph/TabDataGraph/PriceChart/externalData';

import {
  watchGetOneilRiPanelData,
  watchHandleEntlitment,
  watchRecieveQuote,
  watchGetFocusListData
} from './NavDataGraph/RelatedInfoPanel/RIPanelOneilData';

import {
  watchUpdateSelectedTab,
  watchInitData,
  watchSetScrollPosition,
  watchRefreshRotationGraph,
  watchInitGroupsMainData
} from './NavCompare/NavCompareSaga';

import {
  watchToggleSort,
  watchContextMenu,
  watchContextMenuOperation,
  watchHandleRenameValueUpdate,
  watchHandleRemoveListItems,
  watchHandleSearchSymbolFromAPI,
  watchHandleAddSymbolList,
  watchHandleAddSymbolListWithIndex,
  watchHandleAddListSymbols,
  watchAddSymbolOnEnter,
  watchHandleOnClickListAdd,
  watchLiqFilterChange,
  watchHandleLiquidityRatingFilter,
  watchHandleUpdateSelectedRatingFilterType,
  watchHandleInitLiqViewModel,
  watchHandleLiqFilterOnOff,
  watchHandleUpdateDataFilterRange,
  watchHandleOnKeyPressUpdateSlider,
  watchHandleSaveLiquidityFilterValueToSettings,
  watchHandleUpdateRatingFilterTitle,
  watchPrepareGeographyData,
  watchInitCompSymbolsMenu,
  watchInitCompGroupsMenu,
  watchInitRotationGroupsMenu,
  watchInitRotationSymbolsMenu,
  watchPopulateListItem,
  watchHandleBreadCrumClick,
  watchGroupsListItemClick,
  watchHandleClearAll,
  watchNavCompareSaveSettings,
  watchListItemHighlightSelected,
  watchSetGeographyByCountryCodeAndCountryName
} from './NavCompare/MenuPanel';

import {
  watchComparisonListItemHighlightSelected,
  watchResetCalendar,
  watchOnChangeInputValue,
  watchToggleCalendar,
  watchInitCompChart,
  watchSetLatestTradingDate,
  watchOnDimensionChange,
  watchScaleTypeChange,
  watchScaleBaseChange,
  watchHandleRefresh,
  watchHandleAddToList,
  watchAddIndexLine,
  watchUpdateUserStartDate,
  watchUpdateUserEndDate,
  watchHandleToggleVisible,
  watchProcessDataAllSymbol,
  watchRedrawChart,
  watchHandleClearAllWithoutConfirm,
  watchCompGroupsChartContextMenu,
  watchCompGroupsChartContextMenuOperation
} from './NavCompare/ComparisionGraph';

import {
  watchInitializeTimeLine,
  watchGetBenchMarkData,
  watchToggleBenchMark,
  watchGetQuadData,
  watchPreparePoints,
  watchRotationListItemHighlightSelected,
  watchUpdateFlag,
  watchUpdateFlaggedListItems,
  watchUpdateListItemVisibility,
  watchRouteToRotationGraph
} from './NavCompare/RotationGraph';

import {
  watchInitCustomTIModule,
  watchInitCustomKPIModule,
  watchHandleModalHide,
  watchHandleUpdateItemStatus,
  watchOnInputValChange,
  watchHandleInputValidation,
  watchRestoreDefaults,
  watchOnPeriodicityTabChange,
  watchOnApplyToAllIntervals
} from './CustomTIPanel/CustomTIModule';

import {
  watchHandleBrowseMetrics,
  watchHandleAddMetrics,
  watchHandleOpenDefinition,
  watchHandleCustomMetricDuplicate,
  watchHandleCustomMetricDelete,
  watchHandleCustomFromatChange,
  watchHandleManageMetrics,
  watchHandleRenameSave,
  watchHandleCreateMetrics,
  watchHandleAddToColumn,
  watchHandleNameChange,
  watchHandleNameSearch,
  watchHandleInterval,
  watchHandleLookBack,
  watchHandleRename,
  watchHandleSaveEditFormat,
  watchHandleClearEditFormat,
  watchHandleFormulaChange,
  watchHandleSymbolChange,
  watchHandleDefinitionSelected
} from './CustomMetrics'
import {
  watchProcessKpiIndicator,
  watchGetKpiIndicatorData
} from './NavDataGraph/TabDataGraph/Indicators/KPIIndicatorSaga'

import {
  watchHandleReportExtdata,
  watchHandleChangeTextSize,
  watchTabSelectionChanged,
  watchSubTabSelectionChanged,
  watchColumnSortSeqChanged,
  watchPassiveFilterChanged,
  watchLimitChanged,
  watchHandleResizeOwnershipTab
} from './Ownership/ownership'
import {
  watchHandleGetExternalLists,
  watchHandleFormUpload,
  watchHandleUploadDialogState,
  watchHandleToggleRecurring,
  watchHandleSaveLocalFile,
  watchHandleSaveUploadTime,
  watchHandleNodeSelection,
  watchCallSelectedNodes,
  watchCallExternalSourceList,
  watchCallInitGridLoad,
  watchOnGridDimensionChange,
  watchSetVisibleRowsData,
  watchHandleColumnSort,
  watchCreateEmptyList,
  watchCallSetRenameNode,
  watchCallSetDuplicateNode,
  watchCallSaveDuplicateNode,
  watchCallSaveRenameNode,
  watchLoadExtListOnAccept,
  watchCallRollBack,
  watchRemoveDeletedLists,
  watchLoadStartWizard,
  watchNewSymbolColumn,
  watchNewDateColumn,
  watchHandleLoader,
  watchSaveHeaders,
  watchHandleLastPageIndex,
  watchSortExternalData,
  watchundoExternalData,
  watchUploadOnDrop,
  watchHandleSaveDateFormat,
  watchUploadRepeat,
  watchReuploadRollback,
  watchIsUpdated,
  watchLoadStartWizardDate,
  watchLoadStartWizardFateFormat,
  watchFtpUserNameChange,
  watchFtpPasswordChange,
  watchFileFormatException,
  watchEnabledCreatedList,
  watchNextAvailability,
  watchSortExternal
} from './ExternalDataUpload'
import {
  watchDgHoldingsHandleReportExtdata,
  watchDgHoldingsHandleChangeTextSize,
  watchDgHoldingsTabSelectionChanged,
  watchDgHoldingsSubTabSelectionChanged,
  watchDgHoldingsColumnSortSeqChanged,
  watchDgHoldingsLimitChanged,
  watchDgHoldingsUpdateflag,
  watchDgHoldingsResizeTab
} from './DgHoldings/DgHoldingsSaga';

import {
  watchNupSymbolChange,
  watchProcessList,
  watchUpdateNupSelection,
  watchUpdateChatAppearance,
  watchUpdateDimension,
  watchNupColumnPeriodicityChange,
  watchNupSinglePeriodicityChange,
  watchUpdateNupStore,
  watchUpdateStory,
  watchInitNupChart,
  watchProcessNupListData,
  watchUpdateChartSelection,
  watchUpdateNupListFlages,
  watchUpdateGlobalFlag,
  watchUpdateAllGlobalFlag,
  watchSelectAll,
  watchUpdateNupFlag,
  watchNupUpdateEpsRps,
  watchUpdateNasdaqBasic,
  watchResetNupState,
  watchImportListSelection
} from './NavList/ListView/NupViewSaga';

import {
  watchPopulateHeader,
  watchPrepareHeader,
  watchPrepareMetricDef
} from './NavDataGraph/TabDataGraph/HeaderSaga'
import {
  watchPopulatePricePanel,
  watchUpdateChartOnStreaming,
  watchToggleOhOL,
  watchScaleChange,
  watchReDraw_External_Data,
  watchChartResize,
} from './NavDataGraph/TabDataGraph/PriceChartSaga'
import {
  watcherStopDatagraphStreaming,
} from './NavDataGraph/TabDataGraph/DataGraphStreamingSaga'
import {
  watchPriceContextMenuClick,
  watchPopulateContextMenuItems,
  watchPrepareContextMenu
} from './NavDataGraph/TabDataGraph/ContextMenuSaga'
import {
  watchInitFundamentalLines,
  watchInitEarningsAnimation,
  watchUpdateFundamentalLines,
  watchSwitchLabels,
  watchShowFundamentalLineDialog,
  watchSaveFundamentalLineDialog,
  watchRestoreFundamentalSetting,
  watchUpdateFundamentalLineMultiplier,
  watchUpdateFundamentalQTR,
  watchSaveFundamentalLable,
  watchGetEarningsAnimationSetFromController,
  watchOpenEPSAnimationDialog,
  watchSaveEPSAnimationPopup,
  watchRestoreEPSAnimationSetting,
  watchProcessEPSAnimation
} from './NavDataGraph/PriceMenu/FundamentalLineSaga'

import { riPanelWatchers } from './NavDataGraph/RelatedInfoPanel';
import { PriceMenuWatchers } from './NavDataGraph/PriceMenu';

import {
  watchCreateTimeLine,
  watchInitTimeLine
} from './NavDataGraph/TabDataGraph/TimeLineSaga';
import {DatagraphWatchers} from './NavDataGraph/TabDataGraph'
import {
  watchInitCalenderControl,
  watchSetSelectedDate,
  watchHandleDateChange,
  watchSetMostRecentDates,
  watchStepForwardInTime,
  watchStepBackInTime,
  watchHandleEventDateChange
} from './CalenderControl/CalenderControlSaga'

import {
  watchInitFundmamentalIndicator,
  watchProcessFundmamentalIndicator,
  watchPrepareFundmamentalIndicatorData,
  watchToggleFundmamentalChart,
  watchToggleFundmamentalLine
} from './NavDataGraph/TabDataGraph/Indicators/FundamentalIndicatorSaga';
import { watchGetAllIndicatorsData } from './NavDataGraph/TabDataGraph/Indicators/IndicatorSaga';

import {
  watchInitYTDIndicator,
  watchPrepareYTDIndicatorData,
  watchProcessYTDIndicator,
  watchUpdateYTDIndicatorHeight
} from './NavDataGraph/TabDataGraph/Indicators/YTDIndicatorSaga';

import {
  watchInitMACDIndicator,
  watchPrepareMACDIndicatorData,
  watchToggleMACDChart,
  watchUpdateMACDIndicatorHeight
} from './NavDataGraph/TabDataGraph/Indicators/MacdIndicatorSaga';

import {
  watchInitRSIIndicator,
  watchPrepareRSIIndicatorData,
  watchToggleRSIChart,
  watchUpdateRSIIndicatorHeight
} from './NavDataGraph/TabDataGraph/Indicators/RSIIndicatorSaga';

import {
  watchInitSTATSIndicator,
  watchPrepareSTATSIndicatorData,
  watchToggleSTATSChart,
  watchUpdateSTATSIndicatorHeight
} from './NavDataGraph/TabDataGraph/Indicators/StochasticsIndicatorSaga';

import {
  watchInitORatingIndicator,
  watchPrepareORatingIndicatorData,
  watchProcessORatingIndicator,
  watchToggleORatingLine
} from './NavDataGraph/TabDataGraph/Indicators/OneilRatingSaga';

import {
  watchInitADIndicator,
  watchPrepareADIndicatorData,
  watchToggleADChart,
  watchUpdateADIndicatorHeight
} from './NavDataGraph/TabDataGraph/Indicators/ADIndicatorSaga';

import { 
  watchOpenCollapseBlock, 
  watchPrepareIndicatorMenu, 
  watchReprocessIndicatorMenu, 
  watchSaveIndicatorSectionVisibility,
  watchSortIndicator,
  watchSortIndicatorSection
} from './NavDataGraph/TabDataGraph/Indicators/IndicatorFlyoutMenuSaga';

import { 
  watchInitFRData,
  watchFRChartResize,
  watchNewFactorClick
} from './NavDataGraph/TabDataGraph/FactorResearchSaga';

import {
  watchUpdateOneilIdeasData,
  watchCalculateIdeasData,
  watchIdeasMouseDown,
  watchIdeasMouseOver,
  watchIdeasMouseLeave,
  watchUpdateOneilLeaderFlag,
  watchUpdateOneilHistoricFlag
} from './NavDataGraph/TabDataGraph/IdeasSaga';
import { AlertWatcher } from './Alert';

export default function* rootSaga() {
  yield all([

    /* Related Info Panel */
    ...map(riPanelWatchers, (w) => fork(w)),
    /* PriceMenu watchers */
    ...map(PriceMenuWatchers, (w) => fork(w)),
    /* Datagraph watchers */
    ...map(DatagraphWatchers, (w) => fork(w)),
    ...map(AlertWatcher, (w) => fork(w)),
    
    /*Nav Compare*/
    fork(watchUpdateSelectedTab),
    fork(watchInitData),
    fork(watchSetScrollPosition),
    fork(watchRefreshRotationGraph),
    fork(watchInitGroupsMainData),
    /* Nav Compare - Menu */
    fork(watchHandleRemoveListItems),
    fork(watchContextMenu),
    fork(watchContextMenuOperation),
    fork(watchHandleRenameValueUpdate),
    fork(watchToggleSort),
    fork(watchPrepareGeographyData),
    fork(watchInitRotationGroupsMenu),
    fork(watchInitRotationSymbolsMenu),
    fork(watchInitCompSymbolsMenu),
    fork(watchPopulateListItem),
    fork(watchHandleBreadCrumClick),
    fork(watchGroupsListItemClick),
    fork(watchSetGeographyByCountryCodeAndCountryName),
    fork(watchHandleClearAll),
    fork(watchHandleSearchSymbolFromAPI),
    fork(watchHandleAddSymbolList),
    fork(watchHandleAddSymbolListWithIndex),
    fork(watchHandleAddListSymbols),
    fork(watchAddSymbolOnEnter),
    fork(watchNavCompareSaveSettings),
    fork(watchListItemHighlightSelected),

    /* Liquidity & Rating Filter */
    fork(watchHandleLiquidityRatingFilter),
    fork(watchHandleUpdateSelectedRatingFilterType),
    fork(watchHandleInitLiqViewModel),
    fork(watchHandleLiqFilterOnOff),
    fork(watchHandleUpdateDataFilterRange),
    fork(watchHandleOnKeyPressUpdateSlider),
    fork(watchHandleSaveLiquidityFilterValueToSettings),
    fork(watchHandleUpdateRatingFilterTitle),
    fork(watchLiqFilterChange),


    /*Comparison Graph*/
    fork(watchHandleOnClickListAdd),
    fork(watchInitCompGroupsMenu),
    fork(watchHandleClearAllWithoutConfirm),
    fork(watchCompGroupsChartContextMenu),
    fork(watchCompGroupsChartContextMenuOperation),
    fork(watchComparisonListItemHighlightSelected),
    fork(watchResetCalendar),
    fork(watchOnChangeInputValue),
    fork(watchToggleCalendar),
    fork(watchInitCompChart),
    fork(watchSetLatestTradingDate),
    fork(watchOnDimensionChange),
    fork(watchScaleTypeChange),
    fork(watchScaleBaseChange),
    fork(watchHandleRefresh),
    fork(watchHandleAddToList),
    fork(watchAddIndexLine),
    fork(watchUpdateUserStartDate),
    fork(watchUpdateUserEndDate),
    fork(watchHandleToggleVisible),
    fork(watchProcessDataAllSymbol),
    fork(watchRedrawChart),
  
    /* Custom TI Charts*/
    fork(watchInitCustomTIModule),
    fork(watchInitCustomKPIModule),
    fork(watchHandleModalHide),
    fork(watchHandleUpdateItemStatus),
    fork(watchOnInputValChange),
    fork(watchHandleInputValidation),
    fork(watchRestoreDefaults),
    fork(watchOnPeriodicityTabChange),
    fork(watchOnApplyToAllIntervals),

    /* Rotation Graph */
    fork(watchInitializeTimeLine),
    fork(watchGetBenchMarkData),
    fork(watchToggleBenchMark),
    fork(watchGetQuadData),
    fork(watchPreparePoints),
    fork(watchRotationListItemHighlightSelected),
    fork(watchUpdateFlag),
    fork(watchUpdateFlaggedListItems),
    fork(watchUpdateListItemVisibility),
    fork(watchRouteToRotationGraph),

    /*Custom Metric*/
    fork(watchHandleBrowseMetrics),
    fork(watchHandleAddMetrics),
    fork(watchHandleOpenDefinition),
    fork(watchHandleCustomMetricDuplicate),
    fork(watchHandleCustomMetricDelete),
    fork(watchHandleCustomFromatChange),
    fork(watchHandleManageMetrics),
    fork(watchHandleRenameSave),
    fork(watchHandleCreateMetrics),
    fork(watchHandleAddToColumn),
    fork(watchHandleNameChange),
    fork(watchHandleNameSearch),
    fork(watchHandleInterval),
    fork(watchHandleLookBack),
    fork(watchHandleRename),
    fork(watchHandleSaveEditFormat),
    fork(watchHandleClearEditFormat),
    fork(watchHandleFormulaChange),
    fork(watchHandleSymbolChange),
    fork(watchHandleDefinitionSelected),
    /* KPI panel  */
    fork(watchProcessKpiIndicator),
    fork(watchGetKpiIndicatorData),
    fork(watchHandleReportExtdata),
    fork(watchHandleChangeTextSize),
    fork(watchTabSelectionChanged),
    fork(watchSubTabSelectionChanged),
    fork(watchColumnSortSeqChanged),
    fork(watchPassiveFilterChanged),
    fork(watchLimitChanged),
    fork(watchHandleResizeOwnershipTab),

    /*External Data Uploader */
    fork(watchHandleGetExternalLists),
    fork(watchHandleFormUpload),
    fork(watchHandleUploadDialogState),
    fork(watchHandleToggleRecurring),
    fork(watchHandleSaveLocalFile),
    fork(watchHandleSaveUploadTime),
    fork(watchGetExternalDataSubMenu),
    fork(watchGetCurrentListData),
    fork(watchRedrawOnChartResize),
    fork(watchHideModalDialog),
    fork(watchInitExternalData),
    fork(watchHandleNodeSelection),
    fork(watchCallSelectedNodes),
    fork(watchCallExternalSourceList),
    fork(watchCreateEmptyList),
    fork(watchCallSetRenameNode),
    fork(watchCallSetDuplicateNode),
    fork(watchCallSaveDuplicateNode),
    fork(watchCallSaveRenameNode),
    fork(watchLoadExtListOnAccept),
    fork(watchCallRollBack),
    fork(watchRemoveDeletedLists),
    fork(watchCallInitGridLoad),
    fork(watchOnGridDimensionChange),
    fork(watchSetVisibleRowsData),
    fork(watchHandleColumnSort),
    fork(watchLoadStartWizard),
    fork(watchNewSymbolColumn),
    fork(watchNewDateColumn),
    fork(watchHandleLoader),
    fork(watchSaveHeaders),
    fork(watchHandleLastPageIndex),
    fork(watchSortExternalData),
    fork(watchundoExternalData),
    fork(watchUploadOnDrop),
    fork(watchHandleSaveDateFormat),
    fork(watchClearStateData),
    fork(watchUpdateToSettings),
    fork(watchUploadRepeat),
    fork(watchReuploadRollback),
    fork(watchIsUpdated),
    fork(watchLoadStartWizardDate),
    fork(watchLoadStartWizardFateFormat),
    fork(watchFtpUserNameChange),
    fork(watchFtpPasswordChange),
    fork(watchFileFormatException),
    fork(watchGetRiPanelData),
    fork(watchHandleInputChange),
    fork(watchSaveLabel),
    fork(watchOverWriteSettingsOnReUpload),
    fork(watchProcessListData),
    fork(watchEnabledCreatedList),
    fork(watchNextAvailability),
    fork(watchSortExternal),
    /*Holdings Tab */
    fork(watchDgHoldingsHandleReportExtdata),
    fork(watchDgHoldingsHandleChangeTextSize),
    fork(watchDgHoldingsTabSelectionChanged),
    fork(watchDgHoldingsSubTabSelectionChanged),
    fork(watchDgHoldingsColumnSortSeqChanged),
    fork(watchDgHoldingsLimitChanged),
    fork(watchDgHoldingsUpdateflag),
    fork(watchDgHoldingsResizeTab),
    fork(watchHandleResizeOwnershipTab),
    fork(watchGetOneilRiPanelData),
    fork(watchHandleEntlitment),
    fork(watchGetFocusListData),

    fork(watchRecieveQuote),
    // NupView
    fork(watchNupSymbolChange),
    fork(watchProcessList),
    fork(watchUpdateNupSelection),
    fork(watchUpdateChatAppearance),
    fork(watchUpdateDimension),
    fork(watchNupColumnPeriodicityChange),
    fork(watchNupSinglePeriodicityChange),
    fork(watchUpdateNupStore),
    fork(watchUpdateStory),
    fork(watchInitNupChart),
    fork(watchProcessNupListData),
    fork(watchUpdateChartSelection),
    fork(watchUpdateNupListFlages),
    fork(watchUpdateGlobalFlag),
    fork(watchUpdateAllGlobalFlag),
    fork(watchSelectAll),
    fork(watchUpdateNupFlag),
    fork(watchNupUpdateEpsRps),
    fork(watchUpdateNasdaqBasic),
    fork(watchResetNupState),
    fork(watchImportListSelection),

    /*SummaryBlock*/
    fork(watcherOne),
    
    // fork(watchinitChart)
    /*Header - datagraph*/
    fork(watchPopulateHeader),
    fork(watchPrepareHeader),
    fork(watchPrepareMetricDef),
    /*PriceChart - datagraph*/
    fork(watchPopulatePricePanel),
    fork(watchUpdateChartOnStreaming),
    fork(watchToggleOhOL),
    fork(watchScaleChange),
    fork(watchReDraw_External_Data),
    fork(watchChartResize),
    fork(watcherStopDatagraphStreaming),

    /*ContextMenu - priceChart*/
    fork(watchPriceContextMenuClick),
    fork(watchPopulateContextMenuItems),
    fork(watchPrepareContextMenu),

    fork(watchInitFundamentalLines),
    fork(watchInitEarningsAnimation),
    fork(watchSwitchLabels),
    fork(watchUpdateFundamentalLines),
    fork(watchShowFundamentalLineDialog),
    fork(watchSaveFundamentalLineDialog),
    fork(watchRestoreFundamentalSetting),
    fork(watchUpdateFundamentalLineMultiplier),
    fork(watchUpdateFundamentalQTR),
    fork(watchSaveFundamentalLable),
    fork(watchGetEarningsAnimationSetFromController),
    fork(watchOpenEPSAnimationDialog),
    fork(watchSaveEPSAnimationPopup),
    fork(watchRestoreEPSAnimationSetting),
    fork(watchProcessEPSAnimation),
    
    //Timeline- datagraph
    fork(watchCreateTimeLine),
    fork(watchInitTimeLine),

    //Calender control- datagraph
    fork(watchInitCalenderControl),
    fork(watchSetSelectedDate),
    fork(watchHandleDateChange),
    fork(watchSetMostRecentDates),
    fork(watchStepForwardInTime),
    fork(watchStepBackInTime),
    fork(watchHandleEventDateChange),
    
    //Indicators- datagraph
    fork(watchPrepareIndicatorMenu),
    fork(watchReprocessIndicatorMenu),
    fork(watchOpenCollapseBlock),
    fork(watchSaveIndicatorSectionVisibility),
    fork(watchSortIndicatorSection),
    fork(watchSortIndicator),

    fork(watchGetAllIndicatorsData),
    fork(watchInitFundmamentalIndicator),
    fork(watchProcessFundmamentalIndicator),
    fork(watchPrepareFundmamentalIndicatorData),
    fork(watchToggleFundmamentalChart),
    fork(watchToggleFundmamentalLine),

    fork(watchInitYTDIndicator),
    fork(watchPrepareYTDIndicatorData),
    fork(watchProcessYTDIndicator),
    fork(watchUpdateYTDIndicatorHeight),

    fork(watchInitMACDIndicator),
    fork(watchPrepareMACDIndicatorData),
    fork(watchToggleMACDChart),
    fork(watchUpdateMACDIndicatorHeight),

    fork(watchInitRSIIndicator),
    fork(watchPrepareRSIIndicatorData),
    fork(watchToggleRSIChart),
    fork(watchUpdateRSIIndicatorHeight),

    fork(watchInitSTATSIndicator),
    fork(watchPrepareSTATSIndicatorData),
    fork(watchToggleSTATSChart),
    fork(watchUpdateSTATSIndicatorHeight),

    fork(watchInitORatingIndicator),
    fork(watchPrepareORatingIndicatorData),
    fork(watchProcessORatingIndicator),
    fork(watchToggleORatingLine),
    
    fork(watchInitADIndicator),
    fork(watchPrepareADIndicatorData),
    fork(watchToggleADChart),
    fork(watchUpdateADIndicatorHeight),

    /* for volumeChart */
    fork(watchPrepareData),
    fork(watchVolScaleChnage),
    fork(watchUpdateVolumeDimension),

    /* for InsiderChart */
    fork(watchInsiderData),
    fork(watchUpdateInsiderDimension),
    
    /*factor research*/
    fork(watchInitFRData),
    fork(watchFRChartResize),
    fork(watchNewFactorClick),

    fork(watchUpdateOneilIdeasData),
    fork(watchCalculateIdeasData),
    fork(watchUpdateOneilLeaderFlag),
    fork(watchUpdateOneilHistoricFlag),
    /* for financialBlock */
    fork(watcherPrepareFinancialApiData),
    fork(watcherPrepareFinancialData),
    fork(watcherToggleHeader),
    fork(watcherOnPageChange),
    fork(watcherFinancialPeToggle),
    fork(watcherSeletedMenuItem),
    fork(watcherEpsRpsScaleChanged),
    fork(watcherFundamentalLineChanged),
    // fork(watcherPeriodicityChange),
    /* for financialDialogBox */
    fork(watcherOpenFinancialDialogBox),
    fork(watcheronSearchTextChange),
    fork(watcheronSelectedMetricChanged),
    fork(watcheronRestoreDefaultHeaders),
    fork(watcheronSubmitChanges),
    fork(watcherCallRestore),

    /* OwnershipBlockData */
    fork(watcherGetOwnershipData),
    fork(watcheronOwnerShipDataReceived),
    fork(watcherOnOwnerShipAumToggle),
    fork(watcherOnOwnerShipFundToggle),
    fork(watcherOnOwnerShipViewToggle),
    fork(watcherOnOwnerShipSizeChanged),
    fork(watcherPrepareHeaderMenu),
    fork(watcherOnOwnerShipListChanged),
    fork(watchIdeasMouseDown),
    fork(watchIdeasMouseOver),
    fork(watchIdeasMouseLeave)
  ])
}