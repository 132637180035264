import React from "react";
import { Modal, FormGroup, FormControl, Form, Col, Checkbox, Radio, ButtonToolbar, DropdownButton, MenuItem } from "react-bootstrap";
// import CalendarEvents from '../CalenderControl/EventCalendar.jsx';
import TimeSelector from "../../RayCustomControls/ScheduledLists/TimeSelector";
import { connect } from 'react-redux';
import { 
    toggleRecurringUpload,saveFileToTemp,uploadFormData,setUploadTime,setUploadFrequency,appendFileClick,overwriteFileClick, 
    saveUserName,savePassword, uploadDataOnClose, showTimeDialog, hideTimeDialog, toggleFrequencyDialog, changeUploadFrequency, showFormatException,
    uploadFormDataOnDrop
 } from '../../Actions/ExternalDataUploaderActions';
 import { CSSTransition } from 'react-transition-group';
 import DragAndDrop from "../../Components/NavModules/NavList/ListManager/DragAndDrop";
 import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
class ExternalDataUploadDialog extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.recurringCheckboxChange = this.recurringCheckboxChange.bind(this);
    }
    componentDidMount = () => {
        document.addEventListener('mousedown', this.dismissPanel, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.dismissPanel, false);
    }
    
    dismissPanel = (e) => {
        //close this panel if it is out of this component is clicked. 
        if (this.nodes && !this.nodes.contains(e.target)) {
                this.props.toggleFrequencySelect();
        }
    }
    showTimeChangeDialog = () => {
        this.props.showTimeChange();
    }
    showFrequencyChangeDialog = () => {
        this.props.toggleFrequencySelect();
    }
    ftpUsernameChange = (event) => {
        this.props.saveFtpUsername(event.target.value);
    }
    ftpPasswordChange = (event) => {
        this.props.saveFtpPassword(event.target.value);
    }
    uploadOnClose = () => {
        this.props.uploadDataOnClose();
    }
    overwriteClick = () => {
        this.props.overwriteDataClick();
    } 
    appendClick = () => {
        this.props.appendDataClick();
    }
    recurringCheckboxChange() {
        this.props.recurringUploadClick();
    }
    onSelected = (popupTypes, selValues) =>{
       if(popupTypes == "TimeSelector"){
            this.props.updateSelectedTime(selValues);
       }
    }
    onHideTimeSelect = () => {
        this.props.hideTimeSelect();
    }
    onHideFrequencySelect = () => {
        this.props.toggleFrequencySelect();
    }
    handleFormSubmit = () => {
        this.props.formsubmit();
    }
    handleFilePath = (event) => {
        this.props.saveTempFile(event.target.value);
    }
    handleUploadFile = (event) => {
        let file = event.target.files[0];
        let validExts = new Array(".xlsx", ".xls", ".csv", ".txt");
        if(file){
            let fileExt = file.name;
            let extenstion = fileExt.substring(fileExt.lastIndexOf('.'));
            if (validExts.indexOf(extenstion) < 0) {
                this.props.showFormatException();
                return false;
            }
            this.props.saveTempFile(file);
        }else{
            return;
        }
    };
    handleDropFile = (file) => {
        if(!file) return;
        
        let isReUpload = false;
        let form_data = new FormData();
        const {source,appendExixtingData,reUploadListId} = this.props;
        let listId = source.listId;
        let validExts = new Array(".xlsx", ".xls", ".csv", ".txt");
        let fileExt = file[0].name
        let extenstion = fileExt.substring(fileExt.lastIndexOf('.'));
        if (validExts.indexOf(extenstion) < 0) {
          this.props.showFormatException();
          return false;
        }
        if(this.props.reUploadListId){
            isReUpload = true;
            listId = reUploadListId;
        }
        form_data.append('file', file[0]);
        form_data.append('listName', source.listName);
        form_data.append('listId', listId);
        form_data.append('isOverrite', appendExixtingData?0:1);
        this.props.dragDropUpload(form_data,listId,isReUpload);
    }
    render() {
        //let lgClose = () => this.setState({ lgShow: false });
        let {isrecurringchecked,isExternalUrl,isFtpUrl,isSFtpUrl,tempLocalFileName,scheduleTime,
            appendExixtingData, overwriteExistingData, ftpUser, ftpPass, timeZone, showTimeChanger,showFrequencyChanger,
            show, frequencyArray, frequencyType, saveEnabled, uploadFileOnClose, reUploadListId} = this.props;

        let timeSelectClass = (isFtpUrl || isSFtpUrl) ? "ftp-time" : "gsheet-time";
        let frequencySelectClass = (isFtpUrl || isSFtpUrl) ? "ftp-frequency" : "gsheet-frequency";
        let splitTZ =timeZone &&  timeZone.trim().split(' ');
        let tZone = '';
            for(let i=0; i < splitTZ.length; i++ ){
                tZone += splitTZ[i].charAt(0).toUpperCase();
            }
            return (

            <div id="ExternalDataUploadDialog">
                {//<ExceptionReport show={this.state.lgShow} onHide={lgClose} />
                }

                <Modal className="modal-popup ext-dialog external-data-upload-dialog" show={show}>
                    <Modal.Header>
                        <Modal.Title>
                                <span className="cap-header">UPLOAD SETTINGS</span>
                                <button  onClick={this.props.onHide} type="button" className="btn btn-xxs btn-secondary btn-close" data-effect="fadeOut" ><span className="icn-close"></span></button>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="medium-normal">
                        <div className="modal-region">
                            <div className="row">
                                <div className="container-fluid ext-update-setting">
                                <DragAndDrop handleDrop={this.handleDropFile} className="test"></DragAndDrop>
                                    <div className="row locationwise">
                                        <div className="col-xs-12">
                                            <div className="title"><h6>Data Location</h6></div>
                                            <div className="upload-field">
                                                <div className="ext-get-file">
                                                    <button className="btn btn-sm btn-default">Browse</button>
                                                    <input type="file" name="myfile" onChange={this.handleUploadFile} />
                                                </div>
                                                <div className="ext-get-path">
                                                    <FormControl className="input-get-path" value={tempLocalFileName ? tempLocalFileName:''} placeholder="Browse file explorer and select file" autoComplete="off" onChange={this.handleFilePath}  type="text" name="url-text" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    {(isFtpUrl || isSFtpUrl) && <div className="row">
                                        <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                                            <div className="recurring-form ftp-details">
                                                <div className="title">{isSFtpUrl ? 'SFTP' : 'FTP'} Server Information</div>
                                                <div className="recurring-login">
                                                    {(isFtpUrl || isSFtpUrl) && <Form horizontal>
                                                        <FormGroup controlId="formHorizontalEmail">
                                                            <Col sm={3}>User Name:</Col>
                                                            <Col sm={9}><FormControl value={ftpUser?ftpUser:''} autoComplete="off" type="email" onChange={this.ftpUsernameChange} placeholder="Username" /></Col>
                                                        </FormGroup>

                                                        <FormGroup controlId="formHorizontalPassword">
                                                            <Col sm={3}>Password:</Col>
                                                            <Col sm={9}><FormControl value={ftpPass?ftpPass:''} autoComplete="off" type="password" onChange={this.ftpPasswordChange} placeholder="Password" /></Col>
                                                        </FormGroup>
                                                    </Form>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {isExternalUrl && <div className="row recurring-upload">
                                        <div className="col-xs-12">
                                            <Checkbox onChange={this.recurringCheckboxChange} checked={isrecurringchecked}>Make this a recurring upload</Checkbox>
                                        </div>
                                        <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                                            <div className={isrecurringchecked ? "recurring-form" : "recurring-form hide"}>
                                                {/* {isFtpUrl && <div className="title">FTP Server Information</div>} */}
                                                <div className="recurring-login">
                                                    <Form horizontal>
                                                        {/* {isFtpUrl && <div>
                                                            <FormGroup controlId="formHorizontalEmail">
                                                                <Col sm={3}>User Name:</Col>
                                                                <Col sm={9}><FormControl value={ftpUser?ftpUser:''} autoComplete="off" type="email" onChange={this.ftpUsernameChange} placeholder="Email" /></Col>
                                                            </FormGroup>

                                                            <FormGroup controlId="formHorizontalPassword">
                                                                <Col sm={3}>Password:</Col>
                                                                <Col sm={9}><FormControl value={ftpPass?ftpPass:''} autoComplete="off" type="password" onChange={this.ftpPasswordChange} placeholder="Password" /></Col>
                                                            </FormGroup>
                                                        </div>} */}
                                                        <FormGroup controlId="formHorizontalEmail">
                                                            <Col sm={3}>Frequency:</Col>
                                                            <Col sm={9}>
                                                                <div className="recurring-frequency">
                                                                    <button type="button" className="btn btn-xs btn-secondary" data-effect="fadeOut" onClick={this.showFrequencyChangeDialog}>{frequencyArray[frequencyType - 1]}</button>
                                                                    <CSSTransition
                                                                        in={showFrequencyChanger}
                                                                        classNames="toggle"
                                                                        timeout={{ enter:300, exit:0 }}
                                                                        unmountOnExit>
                                                                        {showFrequencyChanger ? <div className={"popover-time-select " + frequencySelectClass} >
                                                                        <div className="scheduled-lists frequency" ref={(node) => this.nodes = node}>
                                                                            <div className="title">{LocalizationStore.getTranslatedData("ss_selectfrequency","Select a Frequency:")}</div>
                                                                            <div className="content">
                                                                            <ul className="frequencyList">
                                                                            {frequencyArray.map((itm,key) =>
                                                                                <li key={itm} >
                                                                                    <a onClick={() => { this.props.changeFrequency(key + 1) }}>
                                                                                        {key == frequencyType - 1 ? <span className="icn-right-icon"></span> : ''}{itm}
                                                                                    </a>
                                                                                </li>)
                                                                            }
                                                                            </ul>
                                                                            </div>
                                                                        </div>
                                                                        </div>:<div></div>}
                                                                    </CSSTransition>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup controlId="formHorizontalEmail">
                                                            <Col sm={3}>Upload time:</Col>
                                                            <Col sm={9}>
                                                                <div className="recurring-calendar">
                                                                    <button type="button" className="btn btn-xs btn-secondary" data-effect="fadeOut"onClick={this.showTimeChangeDialog}>{scheduleTime.displayName + " "}</button>
                                                                    <CSSTransition
                                                                        in={showTimeChanger}
                                                                        classNames="toggle"
                                                                        timeout={{ enter:300, exit:0 }}
                                                                        unmountOnExit>
                                                                        {showTimeChanger ? <div className={"popover-time-select " + timeSelectClass} >
                                                                            <TimeSelector
                                                                                onCancel={this.onHideTimeSelect}
                                                                                onSelected={this.onSelected}
                                                                                hour={+scheduleTime.id.hour}
                                                                                minute={+scheduleTime.id.minute}
                                                                                key="select-timer-container" />
                                                                        </div>:<div></div>}
                                                                    </CSSTransition>
                                                                </div>
                                                                <span className="recurring-zone">{tZone}</span>
                                                            </Col>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Col sm={12}>
                                                                <div className="close-window-upload">
                                                                <Checkbox checked={uploadFileOnClose} onChange={this.uploadOnClose}>Upload data on close of this window</Checkbox>
                                                                </div>
                                                            </Col>
                                                        </FormGroup>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="col-xs-12">
                                        {(isExternalUrl || reUploadListId) && <div className="data-overwrite-append">
                                            <FormGroup>
                                                <Radio name="radioGroup" onChange={this.overwriteClick} checked={overwriteExistingData?true:false}> Over-write existing data </Radio>
                                                <Radio name="radioGroup" onChange={this.appendClick} checked={appendExixtingData?true:false}> Append to existing data </Radio>
                                            </FormGroup>
                                        </div>}
                                    </div>
                                </div>
                                <div className="container-fluid ext-update-setting">
                                    <hr className="ext-hr" />
                                    <div className="upload-information">
                                        <FormControl.Static>
                                            If you would like to set up a recurring upload to our server, we would be happy to help you set that up. Please call Institutional Client Services at 800.419.6632 (outside the U.S. at +1.310.448.6199)
                                        </FormControl.Static>
                                        <p><a className="link-update-help" onContextMenu = {(e) => e.preventDefault()} href={`${window.location.origin}/assets/ExternalDataHelp_1.0.pdf`} target="_blank">Help</a></p>
                                        {/* <div className="help-text large-margin-left" onClick={() => this.setState({ lgShow: true })}>Exception Reports</div> */}
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="btn-box righter">
                            <button type="button" className="btn btn-xs btn-secondary" data-effect="fadeOut" onClick={this.props.onHide}>Cancel</button>
                            <button type="button" disabled={!saveEnabled ? true: false} className="btn btn-xs btn-secondary" data-effect="fadeOut" onClick={this.handleFormSubmit}>Ok</button>
                        </div>
                    </Modal.Footer>
                    
                    
                </Modal>
                
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    recurringUploadClick : () => dispatch(toggleRecurringUpload()),
    saveTempFile: (file) => dispatch(saveFileToTemp(file)),
    formsubmit : () => dispatch(uploadFormData()),
    dragDropUpload : (data,listId,isReUpload) => dispatch(uploadFormDataOnDrop(data,listId,isReUpload)),
    updateSelectedTime: (data) => dispatch(setUploadTime(data)),
    overwriteDataClick:  () => dispatch(overwriteFileClick()),
    appendDataClick: () => dispatch(appendFileClick()),
    uploadDataOnClose: () => dispatch(uploadDataOnClose()),
    saveFtpUsername: (uname) => dispatch(saveUserName(uname)),
    saveFtpPassword: (pass) => dispatch(savePassword(pass)),
    showTimeChange: () => dispatch(showTimeDialog()),
    hideTimeSelect: () => dispatch(hideTimeDialog()),
    toggleFrequencySelect: () => dispatch(toggleFrequencyDialog()),
    changeFrequency: (freqId) => dispatch(changeUploadFrequency(freqId)),
    showFormatException: () => dispatch(showFormatException())
})
const mapStateToProps = ({ externalDataUploader }) => {
    const { 
        scheduleTime, isExternalUrl, isFtpUrl, isSFtpUrl, isGoogleUrl, isrecurringchecked, tempLocalFileName,
        appendExixtingData, overwriteExistingData ,ftpUser, ftpPass, timeZone, showTimeChanger,showFrequencyChanger,
        frequencyArray, frequencyType, saveEnabled, uploadFileOnClose, reUploadListId
    } = externalDataUploader.ExternalDataUploaderReducer;
    const { source } = externalDataUploader.ExternalDataListViewReducer;
    return ({ 
        scheduleTime, isExternalUrl, isFtpUrl, isSFtpUrl, isGoogleUrl, isrecurringchecked, tempLocalFileName,
        appendExixtingData, overwriteExistingData, ftpUser, ftpPass, timeZone, showTimeChanger,showFrequencyChanger,
        frequencyArray, frequencyType, saveEnabled, uploadFileOnClose, reUploadListId, source
    });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataUploadDialog);