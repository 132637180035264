import AppDispatcher from "AppDispatcher";
import { ResizeConstants } from "../Constants/ResizeConstants.js";

class ResizeActionsClass {

  updateMetricLibraryWidth(width) {
    AppDispatcher.handleViewAction({
      actionType: ResizeConstants.ActionTypes.UPDATE_METRIC_LIBRARY_WIDTH,
      data: width
    });
  }

  updateBrowserFilterWidths() {
    AppDispatcher.handleViewAction({
      actionType: ResizeConstants.ActionTypes.UPDATE_BROWSER_FILTER_WIDTHS,
      data: {}
    });
  }
}

const ResizeActions = new ResizeActionsClass();

export default ResizeActions;