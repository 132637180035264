import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetDuplicateDataSetRequest extends BaseServiceRequest {
    constructor(name,listId) {
        super();
        super.addRequestUri("duplicateed.pb");
        super.addPathParameter(listId.toString()); 
        super.addRequestParameter("name", name);
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListExplorerNodeResponse";
    }
}

export default GetDuplicateDataSetRequest;
