import {CompareMenuType} from "CompareTabType";

export const getHeaderPaneState = (state) => state.rotationGraph.HeaderPaneReducer.selectedMenuTab == CompareMenuType.Symbols
                                            ? state.rotationGraph.HeaderPaneReducer.SymbolsData
                                            : state.rotationGraph.HeaderPaneReducer.GroupsData;
export const getCenterRotationState = (state) => state.rotationGraph.CenterRotationReducer.selectedMenuTab == CompareMenuType.Symbols
                                                ? state.rotationGraph.CenterRotationReducer.SymbolsData
                                                : state.rotationGraph.CenterRotationReducer.GroupsData
export const getCenterRotationCommonState = (state) => state.rotationGraph.CenterRotationReducer

export const getBenchMarkState = (state) => state.rotationGraph.BenchMarkReducer.selectedMenuTab === CompareMenuType.Symbols
                                          ? state.rotationGraph.BenchMarkReducer.SymbolsData
                                          : state.rotationGraph.BenchMarkReducer.GroupsData

export const getGlobalCountDataList = (state) => state.rotationGraph.BenchMarkReducer.globalCountDataList;

export const getSelectedMenuTab = (state) => state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;