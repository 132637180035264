import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import UserInfoUtil from "UserInfoUtil";
import ThemeType from "ThemeType";
import { SettingsConstants } from "../../../Constants/SettingsConstants.js";
const CHANGE_EVENT = "change";
let _states = { url: "" , tabUrl:""};
class ResearchStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    return _states;
  }

  updateRipanelUrl(url, doEmitChange) {
    _states.tabUrl = url; 
    
    if(doEmitChange)
      this.emit(CHANGE_EVENT);
  }

  updateUrl(url) {
    let usremail = UserInfoUtil.getUserInfo().userEmail;
    let encriptEmail = this.encriptEmail(usremail);
    let currentTheme = SettingsStore.getConsoleSettings().currentTheme;
    let theme = currentTheme === ThemeType.Dark ? "d" : "l";
    _states.researchURL = url;
    _states.url = url + "/?e=" + encriptEmail + "&t=" + theme;
    this.emit(CHANGE_EVENT);
  }


  encriptEmail(email) {

    if (!email) {
      return;
    }
    const max = 4;
    const min = 1;
    const cst = 64;
    let length = email.length;
    let char = [];
    char.length = length + 1;
    let rnd = min + Math.floor(Math.random() * ((max - min) + 1));
    for (let ipos = 0; ipos < length; ipos++) {
      char[ipos] = String.fromCharCode(email[ipos].charCodeAt() + rnd);
    }
    char[length] = String.fromCharCode(rnd + cst);
    return encodeURI(char.join(""));
  }

  dispatcherCallback(payload) {
    AppDispatcher.waitFor([SettingsStore.dispatchToken]);
    const action = payload.action;
    switch (action.actionType) {
      case SettingsConstants.ActionTypes.THEME_CHANGED:
        this.updateUrl(_states.researchURL);
        break;
      default:
        return;
    }
  }
}

const researchStore = new ResearchStore();
export default researchStore;
