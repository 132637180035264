import PropTypes from 'prop-types';
import React from "react";
import PeriodicityHelper from "../../Utils/PeriodicityHelper.js";
import ClickMenu from "ClickMenu";
import GraphType from "../../Constants/GraphType.js";
import LocalizationStore from 'LocalizationStore';
import { isEqual } from 'underscore';

export default class PeriodicityControl extends React.Component {
  constructor(props) {
    super(props);
    this.periodicityHeaders = PeriodicityHelper.getValidPeriodicitiesHeader();
    this.state = {
      buttonSource: props.buttonSource,
      disablePeriodicityButton: props.disablePeriodicityButton
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.periodicityBtnClicked) {
      this.setState({
        buttonSource: newProps.buttonSource
      });
    }
    if (this.state.disablePeriodicityButton !== newProps.disablePeriodicityButton) {
      this.setPeriodicityButtonsState(newProps.disablePeriodicityButton);
      this.setState({
        disablePeriodicityButton: newProps.disablePeriodicityButton
      });
    }
  }
  getOptions(buttonobject) {
    const menu = [];
    const periodicityHeaders = this.periodicityHeaders;
    for (let header in periodicityHeaders) {
      if (periodicityHeaders.hasOwnProperty(header)) {
        const isActive = header === buttonobject.Periodicity;
        menu.push({
          header: this.translateButtonHeader(header),
          key: header,
          isActive: isActive,
          buttonObject: buttonobject
        });
      }
    }
    return menu;
  }
  setPeriodicityOption(item) {

    const key = item.key;
    const buttonobject = item.buttonObject;
    buttonobject.Periodicity = key;
    buttonobject.Header = this.periodicityHeaders[key];
    const selectedKey = this.getSelectedPeriodicityKey();
    if (!buttonobject.IsSelected) {
      buttonobject.IsSelected = true;
      if (this.state.buttonSource[selectedKey] !== undefined) {
        this.state.buttonSource[selectedKey].IsSelected = false;
      }
    }
    this.setState({});
    if (buttonobject.IsSelected) {
      this.props.onPeriodicityChange(buttonobject.Periodicity);
    }
  }

  setPeriodicityButtonsState(state) {
    Object.keys(this.state.buttonSource).map((buttonkey) => {
      this.state.buttonSource[buttonkey].disabled = state;
      return this.generateButton(this.state.buttonSource[buttonkey], buttonkey);
    });
  }

  setPeriodicity(e, buttonobject, key) {
    if (buttonobject.disabled) {
      return;
    }
    if (e.button !== 2) {
      const selectedKey = this.getSelectedPeriodicityKey();
      if (key !== selectedKey) {
        this.state.buttonSource[key].IsSelected = true;
        if (this.state.buttonSource[selectedKey] !== undefined) {
          this.state.buttonSource[selectedKey].IsSelected = false;
        }
      }

      this.setPeriodicityButtonsState(true);
      this.props.onPeriodicityChange(buttonobject.Periodicity);
    }
  }

  getSelectedPeriodicityKey() {
    for (var key in this.state.buttonSource) {
      if (this.state.buttonSource[key].IsSelected) {
        return key;
      }
    }
  }

  translateButtonHeader(key) {
    let header;
    switch (key) {
      case GraphType.Daily: header = LocalizationStore.getTranslatedData('tp_per_dly', 'Daily'); break;
      case GraphType.Weekly: header = LocalizationStore.getTranslatedData('tp_per_wkly', 'Weekly'); break;
      case GraphType.Monthly: header = LocalizationStore.getTranslatedData('tp_per_mnly', 'Monthly'); break;
      case GraphType.Quarterly: header = LocalizationStore.getTranslatedData('tp_per_qly', 'Quarterly'); break;
      case GraphType.Annual: header = LocalizationStore.getTranslatedData('tp_per_aly', 'Annual'); break;
      case GraphType.Intraday1Min: header = LocalizationStore.getTranslatedData('tp_per_1min', '1 Min'); break;
      case GraphType.Intraday5Min: header = LocalizationStore.getTranslatedData('tp_per_5min', '5 Min'); break;
      case GraphType.Intraday10Min: header = LocalizationStore.getTranslatedData('tp_per_10min', '10 Min'); break;
      case GraphType.Intraday15Min: header = LocalizationStore.getTranslatedData('tp_per_15min', '15 Min'); break;
      case GraphType.Intraday30Min: header = LocalizationStore.getTranslatedData('tp_per_30min', '30 Min'); break;
      case GraphType.Intraday60Min: header = LocalizationStore.getTranslatedData('tp_per_60min', '60 Min'); break;
      default:
        break;
    }
    return header;
  }

  generateButton(buttonobject, key) {
    let btnClass;
    //let btnClass = buttonobject.disabled ? "btn btn-sm btn-default disabled" : buttonobject.IsSelected ? "btn btn-sm btn-default active" : "btn btn-sm btn-default";
    if (buttonobject.disabled) {
      btnClass = "btn btn-sm btn-default disabled";
      if (buttonobject.IsSelected) {
        btnClass = "btn btn-sm btn-default active"
      }
    }
    else {
      btnClass = "btn btn-sm btn-default";
      if (buttonobject.IsSelected) {
        btnClass = "btn btn-sm btn-default active"
      }

    }
    var item = (
      <div key={key} className={'periodicty-item ' + btnClass} onClick={(e) => this.setPeriodicity(e, buttonobject, key)} id="dropdown-size-extra-small">
        <span className="periodicity-text" rel={this.translateButtonHeader(buttonobject.Periodicity)}>{this.translateButtonHeader(buttonobject.Periodicity)}</span>
        <ClickMenu data-disable-track-price="true" items={buttonobject.options} position="top" onselect={(option) => this.setPeriodicityOption(option)} />
      </div>);
    return item;
  }

  render() {
    return (
      <div className="actionButton">
        {Object.keys(this.state.buttonSource).map((buttonkey) => {
          let buttonSource = this.state.buttonSource[buttonkey];
          buttonSource.options = this.getOptions(buttonSource);
          return this.generateButton(this.state.buttonSource[buttonkey], buttonkey);
        })}
      </div>
    );
  }
}
PeriodicityControl.propTypes = {
  buttonSource: PropTypes.object.isRequired,
  onPeriodicityChange: PropTypes.func.isRequired

};
