import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetListIgGicsBrowserRequest extends BaseServiceRequest {
    constructor(listId, cacheId, isColumnFilterEnabled) {
        super();
        super.addRequestUri("igfilters.pb");
        super.addPathParameter(listId.toString());
        super.addRequestParameter("cache", cacheId.toString());
        super.addRequestParameter("cfv", "2"); //((int)ColumnFilterVersionType.MvM).ToString());
        super.addRequestParameter("icf", isColumnFilterEnabled ? "1" : "0");

    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListIGBrowserResponse";
    }
}

export default GetListIgGicsBrowserRequest;
