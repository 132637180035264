import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import UserInfoUtil from "UserInfoUtil";
import InputName from './InputName.jsx';
import TimeTrackingWindow from "TimeTrackingWindow";
import { RouteToDataGraph } from '../../../../../Actions/ConsoleActions';
import CompareTabType, { CompareMenuType } from "CompareTabType";
import { toggleToolTip } from '../../../../../Actions/ComparisonActions';
import { groupsListItemClick, updateFlag, updateListItemVisibilty, handleHighlight, toggleVisible, showContextMenu, handleRename, listItemHighlightSelected } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { RotationMenuType } from '../../../../../Constants/RotationMenuType';
import { getSortedData } from '../../../../../Reducers/NavCompare/ComparisonGraphReducers/reselectorsdata';
import ErrorBoundary from 'ErrorBoundary';
import NavType from "NavType";
import { extend } from 'underscore';
import { PrintMode } from '../../../../../print/printmode.js';

class ListItem extends PureComponent {
    constructor(props) {
        super(props);
        this.notHighlight = false;
    }
componentDidUpdate(){
    
    this.props.loadingStatus === false ? this.props.updateParent(true) : this.props.updateParent(false);

}
    callWithStopPropagation = (e, funcName, listItem, isComparisonGraph) => {
        const { isEditMode } = listItem;
        if (isEditMode) return;
        e.stopPropagation();
        funcName(e, listItem, isComparisonGraph);
    }

    listItemHighlightSelected = (e, item, isComparisonGraph) => {

        if (!this.notHighlight) {
            if (e.shiftKey) {
                this.props.listItemHighlightSelected('Shift', item);
            }
            else if (e.ctrlKey || e.metaKey) {
                this.props.listItemHighlightSelected('Ctrl', item);
            }
            else {
                this.props.listItemHighlightSelected('Click', item);
            }
        } else {
            this.notHighlight = false;
        }

    }


    toggleVisible = (e, listItem) => {
        this.props.toggleVisible(listItem.Osid);
    }


    handleRename = (e, listItem) => {
        this.props.handleRename(listItem, e.target.value.trim());
    }


    handleContextMenu = (e, menuTabName, listItem) => {
        if (menuTabName == CompareMenuType.Symbols) {
            e.stopPropagation();
            e.preventDefault();
            this.props.showContextMenu({ x: e.clientX, y: e.clientY }, listItem);
        }
        else
            return;
    }

    setToolTip(e, item) {
        if (this.isEllipsisActive(e.target))
            this.showTooltip(e, item.displayName);
    }

    setToolTipForSymbol = (e, item) => {
        if (this.isEllipsisActive(e.target)) {
            let displayValue = item.symbol;
            if (UserInfoUtil.hasLocalSymbol() && item && item.localSymbol) {
                displayValue = item.localSymbol;
            }
            this.showTooltip(e, displayValue);
        }
    }

    showTooltip(e, displayValue) {
        let toolTip = document.getElementById('rotation-tooltip');
        toolTip.style.top = (e.clientY + 20) + 'px';
        toolTip.style.left = (e.clientX + 0) + 'px';
        toolTip.style.display = 'block';
        toolTip.style.position = 'fixed';
        toolTip.innerHTML = displayValue;
    }

    clearToolTip() {
        let toolTip = document.getElementById('rotation-tooltip');
        toolTip.innerHTML = '';
        toolTip.style.display = 'none';
        toolTip.style.top = '0px';
        toolTip.style.left = '0px';
    }

    isEllipsisActive = (e) => {
        return (e.offsetWidth <= e.scrollWidth);
    }

    onMouseOver = (e) => {
        if (this.isEllipsisActive(e.target))
            this.onMouseHover(e, e.target.innerHTML, true);
    }


    onMouseOut = (e) => {
        if (this.isEllipsisActive(e.target))
            this.onMouseHover(e, e.target.innerHTML, false);
    }


    handleRedirect = (e, listItem) => {
        const { selectedNavTab } = this.props
        if (selectedNavTab === CompareTabType.ComparisonGraph) {
            const { isUserCreated, label } = listItem;
            if (!isUserCreated && label) {
                RouteToDataGraph(label.trim(), NavType.NavSymbol);
                e.preventDefault();
                e.stopPropagation();
            }
        } else {
            RouteToDataGraph(listItem.trim(), NavType.NavSymbol);
            e.preventDefault();
            e.stopPropagation();
        }
    }

    onMouseHover = (e, text, isShow = false) => {
        const { onMouseHover } = this.props;
        onMouseHover({
            isShowToolTip: isShow,
            toolTipText: text,
            pos: {
                x: e.clientX,
                y: e.clientY + 18
            }
        })
    }


    inputOnChange() {
        return;
    }

    listItemVisibleAction(item) {
        this.props.updateListItemVisibilty(item);
    }

    updateFlag(item) {
        this.props.updateFlag(item);
    }


    getIsFlagVisible = (item, menuTabName) => {
        return (item.itemType == RotationMenuType.Stocks || menuTabName == CompareMenuType.Symbols) ? 'block' : 'none';
    }

    getIsSymbolActionVisible = (item, menuTabName) => {
        return (this.getIsFlagVisible(item, menuTabName) == 'none') ? 'block' : 'none';
    }

    getIsHighlighted = (item) => {
        return item.isHighlighted ? null : 'item-fade';
    }

    getIsFlagged = (item) => {
        return item.isFlagged ? 'rotation-menu-flag icn-flag active' : 'rotation-menu-flag icn-flag';
    }

    getHasLocalSymbol = (item) => {
        return UserInfoUtil.hasLocalSymbol() && item && item.localSymbol ? true : false
    }

    clickGroupListItem = (listItem) => {
        const { menuTabName } = this.props;
        this.notHighlight = true;
        this.props.groupsListItemClick(this.isSymbolsMenu(menuTabName) ? listItem.label : listItem.symbol);
    }


    isSymbolsMenu(menuTabName) {
        return menuTabName == CompareMenuType.Symbols;
    }

    setGroupMenuSymbolName(sortedCompListItem, menuTabName) {
        const isStocksRotationMenuType = (this.props.rotationMenuType === RotationMenuType.Stocks);
        if (!this.isSymbolsMenu(menuTabName)) {
            let groupMenuItems;
            if (!isStocksRotationMenuType)
                groupMenuItems = this.props.listItems
            else
                groupMenuItems = this.props.stockList;

            sortedCompListItem.map((listItem) => {
                groupMenuItems.map((item) => {
                    if (item.underlyingSymbol == listItem.actualSymbol) {
                        extend(listItem, { symbol: isStocksRotationMenuType ? item.localSymbol !== "" ? item.localSymbol : item.symbol : item.symbol });
                    }
                })
            })
        }
    }

    ComparisonGraphMenu() {
        const { menuTabName, sortedCompListItem } = this.props;
        this.setGroupMenuSymbolName(sortedCompListItem, menuTabName);
        return (
            sortedCompListItem.length > 0 && sortedCompListItem.map((listItem) => listItem.label !== "" &&
                <li key={listItem.Osid} data-osid={listItem.Osid} onClick={(e) => this.callWithStopPropagation(e, this.listItemHighlightSelected, listItem, true)} className={listItem.isHighlighted ? "" : "item-fade"} onContextMenu={(event) => this.handleContextMenu(event, menuTabName, listItem)}>
                    <div className="comp-grid-cell">
                        {/* <a className="symbol-view-eye"> */}
                        <div className="toggle-button">
                            <input id="toggleButton1" type="checkbox" defaultChecked={!listItem.isVisible} onClick={(e) => this.callWithStopPropagation(e, this.toggleVisible, listItem)} />
                            <div className="icn-eye-n-check"></div>
                        </div>
                        {/* </a> */}
                    </div>
                    <div className="comp-grid-cell">
                        <div style={{ backgroundColor: PrintMode.printing ? listItem.isVisible ? listItem.color : "#d3d3d3" : listItem.color, opacity: PrintMode.printing ? listItem.isHighlighted ? 1 : 0.3 : 1 }} className="rotation-menu-rect"></div>
                        <div className="compare-symbol-block"><a className={listItem.isUserCreated ? "symbol-name ellipsis" : "symbol"} onClick={(event) => this.handleRedirect(event, listItem)} onMouseMove={this.onMouseOver} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>{this.isSymbolsMenu(menuTabName) ? listItem.label : listItem.symbol}</a></div>
                    </div>
                    <div className="comp-grid-cell">
                        {
                            listItem.isUserCreated && listItem.isEditMode
                                ? <div className="comp-name-edit"><ErrorBoundary><InputName handleRename={(event) => this.handleRename(event, listItem)} textValue={listItem.name} /></ErrorBoundary></div>
                                : <a className="symbol-name" onMouseMove={this.onMouseOver} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>{listItem.name}</a>
                        }
                    </div>
                    <div className="comp-grid-cell comp-percentile" onMouseOver={this.onMouseOver} onMouseMove={this.onMouseOver} onMouseOut={this.onMouseOut}>{listItem.perCHG}</div>
                    {menuTabName == CompareMenuType.Groups && this.props.rotationMenuType != RotationMenuType.Stocks ?
                        <div className="symbol-action icn-symbol-action" onClick={(e) => this.clickGroupListItem(listItem)}></div> : ''}
                </li>

            ));
    }

    RotationGraphMenu() {
        const { menuTabName, listItems } = this.props;
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            listItems != null && listItems.length > 0 && listItems.map((item) =>
                <li className={this.getIsHighlighted(item)} key={item.osid} data-osid={item.osid} onContextMenu={(event) => this.handleContextMenu(event, menuTabName, item)}>
                    <div className="comp-grid-cell">
                        {/* <a className="symbol-view-eye"> */}
                        <div className="toggle-button" onClick={() => this.listItemVisibleAction(item)}>
                            <input id="toggleButton1" type="checkbox" checked={!item.isVisible} onChange={this.inputOnChange} />
                            <div className="icn-eye-n-check"></div>
                        </div>
                        {/* </a> */}
                    </div>
                    <div className="comp-grid-cell">
                        <div style={{ backgroundColor: PrintMode.printing ? item.isVisible ? item.itemColor : "#d3d3d3" : item.itemColor, opacity: PrintMode.printing ? item.isHighlighted ? 1 : 0.3 : 1 }} className="rotation-menu-rect" onClick={(event) => this.listItemHighlightSelected(event, item, false)}></div>
                        <div className="compare-symbol-block"><a className="symbol" onClick={(event) => this.handleRedirect(event, item.underlyingSymbol)} onFocus={(e) => this.setToolTipForSymbol(e, item)} onMouseMove={(e) => this.setToolTipForSymbol(e, item)} onBlur={this.clearToolTip} onMouseOut={this.clearToolTip}>{this.getHasLocalSymbol(item) ? item.localSymbol : item.symbol}</a></div>
                    </div>
                    <div className="comp-grid-cell"><a className="symbol-name" onClick={(event) => this.listItemHighlightSelected(event, item, false)} onFocus={(e) => this.setToolTip(e, item)} onMouseMove={(e) => this.setToolTip(e, item)} onBlur={this.clearToolTip} onMouseOut={this.clearToolTip}>{item.displayName}</a></div>
                    <div className="symbol-action icn-symbol-action" style={{ display: this.getIsSymbolActionVisible(item, menuTabName) }} onClick={() => this.props.groupsListItemClick(item.symbol)}></div>
                    <div style={{ display: this.getIsFlagVisible(item, menuTabName) }} className={this.getIsFlagged(item, menuTabName)} onClick={() => this.updateFlag(item)}></div>
                </li>));
    }


    render() {
        TimeTrackingWindow.trackChartLoadingTime();
        const { selectedNavTab } = this.props
        let menu = (selectedNavTab === CompareTabType.ComparisonGraph) ? this.ComparisonGraphMenu() :
            this.props.loadingStatus === false ? this.RotationGraphMenu() : '';
        return menu;
    }
}

const mapDispatchToProps = (dispatch) => ({
    listItemHighlightSelected: (key, item) => dispatch(listItemHighlightSelected(key, item)),
    toggleVisible: (id) => dispatch(toggleVisible(id)),
    showContextMenu: (pos, item) => dispatch(showContextMenu(pos, item)),
    handleRename: (item, itemValue) => dispatch(handleRename(item, itemValue)),
    onMouseHover: (toolTip) => dispatch(toggleToolTip(toolTip)),
    groupsListItemClick: (label) => dispatch(groupsListItemClick(label)),
    updateFlag: (item) => dispatch(updateFlag(item)),
    updateListItemVisibilty: (item) => dispatch(updateListItemVisibilty(item))
})

const mapStateToProps = (state) => {
    const menuState = state.comparisonAndRotationMenuPanel.MenuTabReducer;
    let selectedMenuTab = menuState.ComparisonMenuTab;
    let loadingSection = menuState.isLoading.ComparisonGraph;
    const { SymbolsPanelReducer } = state.comparisonAndRotationMenuPanel;
    const { GroupsPanelReducer } = state.comparisonAndRotationMenuPanel;
    let menuPanelState = selectedMenuTab === CompareMenuType.Symbols ? SymbolsPanelReducer.ComparisonMenuStates : GroupsPanelReducer.ComparisonMenuStates;

    if (menuState.selectedTab === CompareTabType.RotationGraph) {
        selectedMenuTab = menuState.RotationMenuTab;
        loadingSection = menuState.isLoading.RotationGraph;
        menuPanelState = selectedMenuTab === CompareMenuType.Symbols ? SymbolsPanelReducer.RotationMenuStates : GroupsPanelReducer.RotationMenuStates;
    }

    const loadingStatus = selectedMenuTab === CompareMenuType.Symbols ? loadingSection.SymbolsLoading : loadingSection.GroupsLoading;
    const sortedCompListItem = getSortedData(state);
    const { rotationMenuType, listItems, stockList } = menuPanelState;

    return { loadingStatus, sortedCompListItem, menuTabName: selectedMenuTab, rotationMenuType, listItems, stockList, selectedNavTab: menuState.selectedTab };
}



export default connect(mapStateToProps, mapDispatchToProps)(ListItem);
