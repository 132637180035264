import React from "react";
import { Switch, Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import Footer from "./Footer.jsx";
import ThemeHelper from "ThemeHelper";
import SettingsAction from "../../Actions/SettingsActions";
import ConsoleStore from "../../Stores/ConsoleWindow/ConsoleStore.js";
import LoginStore from "LoginStore";
import PreferenceDialog from "../../Components/Dialogs/PreferenceDialog";
import SettingsStore from "SettingsStore";
import ListActions from "ListActions";
import { OwnerShipActions } from "../../Actions/OwnerShipActions";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListStore from "ListStore";
import ListManagerTabType from "ListManagerTabType";
import NavType from "NavType";
import BrowserUtil from "BrowserUtil";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";
import ExceptionWindow from "../../RayCustomControls/ExceptionWindow/ExceptionWindow.jsx";
import AccountDetailsDialog from "../../RayCustomControls/AccountDetailsDialog/AccountDetailsDialog.jsx";
import HelpDialog from "../../RayCustomControls/HelpDialog/HelpDialog.jsx";
import SupportDialog from "../../RayCustomControls/Support/SupportDialog.jsx";
import LiveSupportDialog from "../../RayCustomControls/Contacts/LiveSupportDialog.jsx";
import WhatsNewDialog from "../../RayCustomControls/HelpDialog/WhatsNewDialog.jsx";
//import MessageCenterDialog from "../../RayCustomControls/MessageCenter/MessageCenterDialog.jsx";
import ListManager from "Constants/ListManager.js";
import Alerts from "Alerts";
import AlertStore from "AlertStore";
import { AlertConstants } from "AlertConstants";
import StringUtil from "StringUtil";
import ConsoleActions, { GetMetricDefinitionAction } from "Actions/ConsoleActions";
import { isEmpty } from "underscore";
import UserInfoUtil from 'UserInfoUtil';
import AppColorStore from "../../Stores/AppColor/AppColorStore";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import CompareTabType from "CompareTabType";
import PerformanceWindow from "../../RayCustomControls/PerformanceWindow";
import { PrintMode } from "../../print/printmode.js";
import NavDataGraph from "../../Components/NavModules/NavDataGraph/NavDataGraph.jsx";
import { setActiveNode } from "../../Actions/ListViewActions.js";
import { dispatch } from "../../Redux/dispatch.js";
import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions";
import PanarayDefault from "ServiceApi/RequestHelper/PanarayDefault";
import NavCompare from '../../Components/NavModules/NavCompare/NavCompare.jsx';
import { SettingsConstants } from "../../Constants/SettingsConstants.js";
import BaseServiceApi from "../../ServiceApi/BaseServiceApi.js";
import { onPlotSymbol } from "../../Actions/DatagraphActions.js";
import { connect } from "react-redux";
import { showSaveAlertPopUp } from "../../Actions/AlertActions.js";

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const Loading = () => <div>Loading...</div>;

// const NavDataGraph = Loadable({
//   loader: () => import('../../Components/NavModules/NavDataGraph/NavDataGraph.jsx'),
//   loading: Loading
// });

const NavListManager = Loadable({
  loader: () => import('../../Components/NavModules/NavList/NavListManager.jsx'),
  loading: Loading
});


const NavResearch = Loadable({
  loader: () => import('../../Components/NavModules/NavResearch/NavResearch.jsx'),
  loading: Loading
});


// const NavCompare = Loadable({
//   loader: () => import('../../Components/NavModules/NavCompare/NavCompare.jsx'),
//   loading: Loading
// });

const NavNews = Loadable({
  loader: () => import('../../Components/NavModules/NavNews/NavNewsManager.jsx'),
  loading: Loading
});

class PanarayConsole extends React.Component {

  constructor(props) {
    super(props);
    this.ConsoleStateChange = this.ConsoleStateChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showExceptionWindow = this.showExceptionWindow.bind(this);
    this.initMiniList = this.initMiniList.bind(this);
    this.getEmailLinkSymbol = this.getEmailLinkSymbol.bind(this);
    this._onMessageRecieved = this._onMessageRecieved.bind(this);
    this.state = {
      showErrorModal: false,
      activeErrorModal: null,
      isInit: false,
      showAlert: false
    };
    ConsoleStore.getState();
    //pageIndex = sequence Number
    this.listCatalog = {
      listId: null,
      actualListId: null,
      searchText: "",
      sortCols: "",
      initialNumRows: 90000,
      needColumnsInfo: 1,
      needBrowserCounts: 0,
      browserFilterText: UserInfoUtil.hasChinaEntitlement() ? "-1;4,102;-1" : "-1;-1;-1;",
      addlCatalogRequests: null,
      useColFilters: 1,
      saveColFilters: 0,
      customerOrder: 0,
      colsetId: "-1",
      igOneilbrowserFilters: "-1;-1;-1;",
      igGicsbrowserFilters: "-1;-1;-1;-1;",
      isOneilselected: 1,
      isGicsselected: 0,
      isNupview: 0,
      pageIndex: 0,
      pageSize: 250,
      isQuoteboard: 0,
      msId: null,
      fundId: "",
      indGrpCode: "",
      isExport: false,
      cacheId: -1
    };
    this.alertMessage = null;
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("dragover",function(e){
      e = e || event;
      e.preventDefault();
    },false);
    window.addEventListener("drop",function(e){
      e = e || event;
      e.preventDefault();
    },false);
    ConsoleStore.addChangeListener(this.ConsoleStateChange);
    if (!LoginStore.isUserLoggedIn()) {
      return;
    }
    this.ConsoleStateChange();
    GetMetricDefinitionAction();
  }

  componentDidMount() {
    if (this.isDevOrStageEnv()) {
      window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
        ConsoleActions.exceptionHandle("Error: " + errorMsg + " Script: " + url + " Line: " + lineNumber
          + " Column: " + column + " StackTrace: " + errorObj, false);
      };
    }
    else {
      window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
        ConsoleActions.exceptionHandle("Error: " + errorMsg + " Script: " + url + " Line: " + lineNumber
          + " Column: " + column + " StackTrace: " + errorObj, true);
      };
    }
    window.addEventListener("storage", (storageEvent) => {
      if (storageEvent.key == "_pUserLocal") {
        if (sessionStorage.getItem("_pUser")) {
          if (storageEvent.oldValue != storageEvent.newValue) {
            sessionStorage.removeItem("_pUser");
            window.location = "/";
          }
        }

        else {
          window.location = "/";
        }
      }
    }, false);

    AppColorStore.addChangeListener(this.colorChanged);
    AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
    AlertStore.addChangeListener(this.onDeleteAllAlerts, AlertConstants.ActionTypes.DELETE_ALL_ALERTS_BY_MSIDS);
    window.addEventListener('message', this._onMessageRecieved, false);
    if (!this.isEntitlementEnable()) {
      this.routeToSymbol();
    }
  }

  _onMessageRecieved(event) {
    if (event.data.id == 'onSymbolQuiver') {
      const { symbol } = event.data;
      dispatch(onPlotSymbol(symbol));
    }
  }
  componentWillUnmount() {
    ConsoleStore.removeChangeListener(this.ConsoleStateChange);
    AppColorStore.removeChangeListener(this.colorChanged);
    AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
    AlertStore.removeChangeListener(this.onDeleteAllAlerts, AlertConstants.ActionTypes.DELETE_ALL_ALERTS_BY_MSIDS);
    window.removeEventListener('message', this._onMessageRecieved, false);
    if (this.alertMessageInterval)
      clearTimeout(this.alertMessageInterval);
  }

  isDevOrStageEnv() {
    const env = process.env.BRANCH_ENV;
    return (env && (env.toUpperCase() == "PROD")) ? false : true;
  }

  colorChanged = () => {
    this.forceUpdate();
  }

  getSymbol(loc) {
    if (loc.pathname && /^\/Symbol(\/{1})\w*/i.test(loc.pathname)) {
      return ConsoleStore.getSymbolFromPath(loc.pathname);
    }
    else {
      return ConsoleStore.getSettings().NavDatagraphSettings.symbol;
    }
  }

  alertStoreStateChange = () => {
    this.alertMessage = LocalizationStore.getTranslatedData("alertmessage_ahbs", "Alerts have been saved");
    this.setState({ showAlert: true })
    this.refs.alert.handleAlertShow();

    this.hideAlertMessage();
  }

  onDeleteAllAlerts = () => {
    this.alertMessage = ONeilViewStore.getState().alertMessage;
    this.setState({ showAlert: true });
    this.refs.alert.handleAlertShow();
    this.hideAlertMessage();
  }

  hideAlertMessage() {
    this.alertMessageInterval = setTimeout(() => {
      this.props.showSaveAlertPopUp(false)
      this.alertMessage = null;
      if (this.isMount){
        this.props.showSaveAlertPopUp(false)
      }
      if (!StringUtil.isEmpty(this.refs.alert)){
        this.props.showSaveAlertPopUp(false);
        this.refs.alert.handleAlertDismiss();
      }
    }, 10000);
  }

  initNavSymbol() {
    const settings = ConsoleStore.getSettings();
    let symbol = settings.NavDatagraphSettings.symbol;
    if (this.getEmailLinkSymbol()) {
      const pathname = this.getEmailLinkSymbol();
      symbol = ConsoleStore.getSymbolFromPath(pathname);
      ConsoleStore.getSettings().NavDatagraphSettings.symbol = symbol;
      localStorage.setItem("_activeNav", NavType.NavSymbol);
      ConsoleStore.getSettings().ActiveNav = NavType.NavSymbol;
      window.localStorage.removeItem("emailLinkedSym");
    }
    else {
      symbol = this.getSymbol(this.props.location)
    }
    //   this.props.history.replace("/" + ConsoleStore.getSymbolUrl(symbol));
    this.initMiniList();
    setTimeout(() => {
      dispatch(onPlotSymbol(symbol));
    }, 0);
  }


  async initMiniList() {
    // Make list manager basic call
    if (!this.state.isInit) {
      const settings = SettingsStore.getConsoleSettings();
      if (settings) {
        ListStore.setState();
        const navListManagerSettings = settings.NavListManagerSettings;
        
        if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Oneil || navListManagerSettings.SelectedTabKeyMiniList == ListManagerTabType.Oneil) {
          const tabONeilSettings = navListManagerSettings.TabONeilSettings;
          this.listCatalog.listId = tabONeilSettings.SelectedListInfo.SelectedListId;
          this.listCatalog.actualListId = tabONeilSettings.SelectedListInfo.SelectedActualListId;
          this.listCatalog.useColFilters = tabONeilSettings.ScreenOnOff == ListManager.ScreenOnOff ? 1 : 0;
          this.listCatalog.needBrowserCounts = tabONeilSettings.BrowserFilter.needBrowserCounts,
            this.listCatalog.browserFilterText = tabONeilSettings.BrowserFilter.browserFilterText,
            this.listCatalog.igOneilbrowserFilters = tabONeilSettings.BrowserFilter.igOneilbrowserFilters,
            this.listCatalog.igGicsbrowserFilters = tabONeilSettings.BrowserFilter.igGicsbrowserFilters,
            this.listCatalog.isOneilselected = tabONeilSettings.BrowserFilter.isOneilselected,
            this.listCatalog.isGicsselected = tabONeilSettings.BrowserFilter.isGicsselected;
            if (ConsoleStore.getSettings().ActiveNav !== NavType.NavLists || (navListManagerSettings.SelectedTabKey == ListManagerTabType.External && navListManagerSettings.SelectedTabKeyMiniList == ListManagerTabType.Oneil)){
              ONeilViewStore.listCatalog = this.listCatalog;
              ListActions.getListCatalog(this.listCatalog, ConsoleStore.getIsLogin());
            }
            ONeilViewStore.initialCall = false;
        }
        else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership || navListManagerSettings.SelectedTabKeyMiniList == ListManagerTabType.Ownership) {
          this.listCatalog = {
            listId: null,
            actualListId: null,
            searchText: '',
            sortCols: '',            
            needColumnsInfo: 1,            
            addlCatalogRequests: null,
            useColFilters: 1,
            saveColFilters: 0,
            customerOrder: 0,
            colsetId: '-1',
            pageIndex: 0,
            size: 250,
            isQuoteboard: 0,
            msId: null,            
            cacheId: -1,
            reorderIds: 0,
            isUserList: 0,
            isMinilist: 0

          }
          await PanarayDefault.getDefaultSymbolData();
          const tabOwnershipSettings = navListManagerSettings.TabOwnershipSettings;
          this.listCatalog.listId = tabOwnershipSettings.SelectedListInfo.SelectedListId !== null && tabOwnershipSettings.SelectedListInfo.SelectedCategoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory ? tabOwnershipSettings.SelectedListInfo.SelectedListId : tabOwnershipSettings.getDefaultTabOwnershipSettings().SelectedListInfo.SelectedListId;
          this.listCatalog.actualListId = tabOwnershipSettings.SelectedListInfo.SelectedActualListId !== null && tabOwnershipSettings.SelectedListInfo.SelectedCategoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory ? tabOwnershipSettings.SelectedListInfo.SelectedActualListId : tabOwnershipSettings.getDefaultTabOwnershipSettings().SelectedListInfo.SelectedActualListId;
          this.listCatalog.useColFilters = tabOwnershipSettings.ScreenOnOff === ListManager.ScreenOnOff ? 1 : 0;
          this.listCatalog.isUserList = tabOwnershipSettings.SelectedListInfo.SelectedCategoryType === ListCategoryType.USER_ListCategory ? 1 : 0;
          if (ConsoleStore.getSettings().ActiveNav !== NavType.NavLists || (navListManagerSettings.SelectedTabKey == ListManagerTabType.External && navListManagerSettings.SelectedTabKeyMiniList == ListManagerTabType.Ownership)){
            OwnershipViewStore.listCatalog = this.listCatalog;
            OwnerShipActions(this.listCatalog)
          }
          dispatch(setActiveNode(this.listCatalog.listId));
          OwnershipViewStore.initialCall = false;

        }
      }
    }
  }

  getEmailLinkSymbol() {
    return window.localStorage.getItem("emailLinkedSym");
  }

  ConsoleStateChange() {
    let currentAction = ConsoleStore.getCurrentAction();

    if (LoginStore.isUserLoggedIn() && (currentAction === SettingsConstants.ActionTypes.GET_USER_SETTINGS || currentAction === ConsoleConstants.ActionTypes.ROUT_TO_GRAPH)) {
      let states = ConsoleStore.getStates();
      states.isRoutToGraph = currentAction === ConsoleConstants.ActionTypes.ROUT_TO_GRAPH;
      ConsoleStore.clearCurrentAction();
      ThemeHelper.loadUserSelectedTheme();
      const loginState = LoginStore.getState();
      const settings = SettingsStore.getConsoleSettings();

      if (this.getEmailLinkSymbol()) {
        states.ActiveNav = NavType.NavSymbol;
      }
      if ((states.IsActiveNavChanged || !this.state.isInit) && states.ActiveNav) {
        if (!this.state.isInit && loginState.returnUrl && loginState.returnUrl !== this.props.history.location.pathname) {
          let activePath = '';
          if (/^\/Symbol(\/{1})\w*/i.test(loginState.returnUrl)) {
            activePath = NavType.NavSymbol;
          }

          const returnUrlContent = loginState.returnUrl.split('/');
          const path = returnUrlContent[1] && returnUrlContent[1].toUpperCase();

          switch (path) {
            case 'LISTS': activePath = NavType.NavLists;
              break;
            case 'SYMBOL':
              const symbol = ConsoleStore.getSymbolFromPath(loginState.returnUrl);
              settings.NavDatagraphSettings.symbol = symbol;
              setTimeout(() => {
                dispatch(onPlotSymbol(symbol));
                this.initMiniList();
              }, 0);
              window.localStorage.removeItem("emailLinkedSym");
              break;
            case 'RESEARCH': activePath = NavType.NavResearch;
              break;
            case 'COMPARE':
              activePath = NavType.NavCompare;
              settings.NavCompareSettings.SelectedTabKey = this.getCompareTabKey(returnUrlContent[2], settings);
              break;
            case 'NAVNEWS': activePath = NavType.NavNews;
              break;
            default: break;
          }
          if (activePath) {
            if(activePath === NavType.NavSymbol) {
              this.props.history.replace(`${NavType.NavSymbol}/${settings.NavDatagraphSettings.symbol}`);
            }
            else {
              this.props.history.replace(activePath);
            }
            localStorage.setItem("_activeNav", activePath);
            settings.ActiveNav = activePath;
            setTimeout(() => {
              if (!this.state.isInit) {
                this.setState({ isInit: true });
              }
            });
            return;
          }
        }
        if (states.ActiveNav.indexOf(NavType.NavSymbol) != -1 || states.ActiveNav == NavType.NavSymbol) {
          this.initNavSymbol();
        }
        else if (states.ActiveNav) {
          let path = states.ActiveNav;
          
          if (PrintMode.printing) {
            console.log("PRINT:--Tracker--NavType from settngs -", settings.ActiveNav, ",,, Symbol -", settings.NavDatagraphSettings.symbol);
            console.log("PRINT:--Tracker--NavType from URL -", PrintMode.ActiveNav, ",,, Symbol -", PrintMode.symbol);
            path = `${path}?print=true&workspaceId=${PrintMode.workspaceId}`;
          }

          const self = this;
          // setTimeout(() => {
          self.initMiniList();
          // }, 0);
          this.props.history.replace(`/${path}`);
        }
      // setTimeout(() => {
           if(!this.state.isInit) {
           this.setState({ isInit: true });
           }
    //    });
      }
      else {
        setTimeout(() => {
          if(!this.state.isInit) {
          this.setState({ isInit: true });
          }
        });
      }
    }

    if (currentAction == ConsoleConstants.ActionTypes.HANDLE_EXCEPTION) {
      if (!PrintMode.printing)
        this.showExceptionWindow();
      ConsoleStore.clearCurrentAction();
    }
  }

  getCompareTabKey = (key, settings) => {
    let tabKey = settings.NavCompareSettings.SelectedTabKey;
    if (key) {
      switch (key.toUpperCase()) {
        case "ROTATION":
          tabKey = CompareTabType.RotationGraph;
          break;
        case "COMPARISION":
          tabKey = CompareTabType.ComparisonGraph;
          break;
        default: break;
      }
    }
    return tabKey;
  }

  showExceptionWindow() {
    let activeErrorModal = null;
    const { headerText,
      exceptionHandleMsg,
      //errorReport,
      isCustomError,
      exceptionSessionExpired,
      isDisableWarningSymbolClick,
      isProd
    } = ConsoleStore.getExceptionErrorObject();

    activeErrorModal = !isProd && <ExceptionWindow showModal={!this.state.showErrorModal}
      headerText={headerText}
      closeModal={this.closeModal}
      errorMsg={exceptionHandleMsg}
      sessionExpired={exceptionSessionExpired}
      isDisableWarningSymbolClick={isDisableWarningSymbolClick}
      isCustomError={isCustomError} />;
    this.setState({ activeErrorModal: activeErrorModal });
  }

  closeModal() {
    this.setState({ activeErrorModal: null, showErrorModal: false });
    ConsoleStore.clearCurrentAction();
    BrowserUtil.enableEvents();
    const sessionExpired = ConsoleStore.getExceptionSessionExpired();
    if (sessionExpired) {
      this.props.history.replace("/Logout");
    }
  }

  getContent() {
    const states = ConsoleStore.getStates();
    if(!this.state.isInit) {
      const version = LocalizationStore.getTranslatedData("_Version", "").replace(/[^\d.]/g,"");
      if (states && states.currentVersion && states.currentVersion < version) {
        setTimeout(() => {
          MenuCommonDialogActions.openWhatsNewDialog();
        });
      }
    }
    if (!isEmpty(states)) {
      let lastActiveNav = states.ActiveNav;
      //let isEmailLinkSymbol = false;

      try {
        if (this.state.isInit) {
          const sessionExpired = ConsoleStore.getExceptionSessionExpired();
          if (sessionExpired && this.state.activeErrorModal) {
            return (
              <div id="content">
                {this.state.activeErrorModal}
              </div>);
          }
          else {
            return (
              <div id="content">
                <div>
                  {this.state.activeErrorModal}
                  <PreferenceDialog />
                  <AccountDetailsDialog />
                  <HelpDialog />
                  <SupportDialog />
                  <LiveSupportDialog /> 
                  <WhatsNewDialog />
                  <Alerts ref="alert" alertMessage={this.alertMessage} />
                  { /* ShowUserDetails */}
                </div>
                <div>
                  <Switch>
                    <Route path={`${this.props.match.url}${NavType.NavSymbol}/:pathParam1?`} component={NavDataGraph} />
                    <Route path={`${this.props.match.url}${NavType.NavLists}`} component={NavListManager} />
                    <Route path={`${this.props.match.url}${NavType.NavCompare}`} component={NavCompare} />
                    <Route path={`${this.props.match.url}${NavType.NavResearch}`} component={NavResearch} />
                    <Route path={`${this.props.match.url}${NavType.NavNews}`} component={NavNews} />
                    <Redirect to={"/" + lastActiveNav} />
                  </Switch>
                </div>
                <Footer {...this.props} />
                <PerformanceWindow {...this.props} />
              </div>);
          }
        }
        else {
          return (
            <div>

            </div>);
        }
      }
      catch (error) {
        console.log(error);
      }
    }
  }

routeToSymbol() {
    const symbol = this.getSymbol(this.props.location);
    ConsoleStore.UpdateRout(symbol, NavType.NavSymbol);
  }

  isEntitlementEnable() {
    let isEnable = true;
    const states = ConsoleStore.getStates();
    switch (states.ActiveNav) {
      case NavType.NavResearch:
        if (!states.IsResearchEnabled) {
          isEnable = false;
        }
        break;
      case NavType.NavNews:
        if (!states.IsNewsEnabled) {
          isEnable = false;
        }
        break;
      default:
        break;
    }
    return isEnable;
  }

  showAlertPopUp() {
    this.alertMessage = LocalizationStore.getTranslatedData("alertmessage_ahbs", "Alerts have been saved");
    this.refs.alert.handleAlertShow();
    this.hideAlertMessage();
  }

  
  render() {
    if(this.props.isSaveAlertsPopup){ this.showAlertPopUp(); }
    return this.getContent();
  }
}

const mapStateToProps = ({ alertReducer }) => {
  const {isSaveAlertsPopup} = alertReducer.DataGraphAlertReducer
  return { isSaveAlertsPopup }
}
const mapDispatchToProps = (dispatch) => ({
 showSaveAlertPopUp: (value) => dispatch(showSaveAlertPopUp(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(PanarayConsole)
