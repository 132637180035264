import { ComparisonAndRotationMenuConstants,RotationGraphConstants } from "RotationGraphConstants";
import { ComparisonGraph } from "../Constants/ComparisonGraph";
import extend from 'underscore';
const { ActionTypes } = ComparisonAndRotationMenuConstants;
extend(ActionTypes,ComparisonGraph,RotationGraphConstants);

export const initializeData = (isLogin = false) => ({
    type: ActionTypes.INITIALIZE_DATA,
    isLogin: isLogin
});

export const initRIPanelSettings = (settings) =>({
    type:ActionTypes.INIT_RI_PANEL_SETTINGS,
    settings:settings
});

export const toggleRIPanel = () => ({
    type: ActionTypes.TOGGLE_RI_PANEL
})

export const updateMenuId = (menuId) => ({
    type: ActionTypes.UPDATE_MENU_ID,
    menuId: menuId
});

export const setScrollPosition = (osid, isHighlighted = false) => ({
    type: ActionTypes.SET_SCROLL_POSITION,
    osid: osid,
    isHighlighted: isHighlighted
});

export const handleBreadCrumClick = (label) => ({
    type: ActionTypes.HANDLE_BREADCRUM_CLICK,
    label
})

export const groupsListItemClick = (label) => ({
    type: ActionTypes.GROUPS_LIST_ITEM_CLICK,
    label
})

export const updateRatingFilterTitle=()=>({
    type:ActionTypes.UPDATE_RATING_FILTER_TITLE
})

export const setIsGlobalRatingSelected=(value)=>( {
    type:ActionTypes.SET_IS_GLOBAL_RATING_SELECTED,
    value
});

export const setIsCountryRatingSelected = (value)=>( {
    type:ActionTypes.SET_IS_COUNTRY_RATING_SELECTED,
    value
})

export const saveLiqFilterValue = () =>({
type:ActionTypes.SAVE_LIQ_FILTER_VALUE
})

export const setSelectedRatingsOption = (selectedOptions) =>({
type:ActionTypes.SET_SELECTED_RATINGS_OPTION,
selectedOptions
});

export const toggleFilterOnOff = () =>({
    type:ActionTypes.TOGGLE_LIQ_ON_OFF
});

export const onKeyPressUpdateSlider = (keycode) =>({
type:ActionTypes.ON_KEYPRESS_UPDATE_SLIDER,
keycode
});


export const initLiqViewModel = () =>({
    type:ActionTypes.INIT_LIQ_VIEW_MODEL,
});

export const updateSelectedRatingFilterType = (ratingType,id) =>({
type:ActionTypes.UPDATE_SELECTED_RATING_FILTER_TYPE,
ratingType,id
});


export const updateDataFilterRange = (value, dataValueLow, dataValueHigh, id) =>({
type:ActionTypes.UPDATE_DATA_FILTERRANGE,
value, dataValueLow, dataValueHigh, id
});

export const updateActiveSlider = (data) =>({
    type:ActionTypes.UPDATE_ACTIVESLIDER,
    data
})


export const clearAllWithoutConfirm = () => ({
    type: ActionTypes.CLEAR_ALL_WITHOUT_CONFIRM,
});

export const removeListItems = (removeItems) =>({
    type:ActionTypes.REMOVE_LIST_ITEMS,
    removeItems
});

export const addListSymbols = (symbols) =>({
    type:ActionTypes.ADD_LIST_SYMBOLS,
    symbols
});

export const setCompareMenuTab = (menuTab) =>({
    type:ActionTypes.SET_COMPARE_MENUTAB,
    menuTab
});

export const getCompareGroupsState = () =>({
    type:ActionTypes.GET_COMPGROUPS_STATE
});

export const initCompSymbolsMenu = () =>({
    type:ActionTypes.INIT_COMP_SYMBOLS_MENU
});

export const initRotationSymbolsMenu = (isRefresh = false) =>({
    type:ActionTypes.INIT_ROTATION_SYMBOLS_MENU,
    isRefresh: isRefresh
})

export const initCompGroupsMenu =() =>({
    type:ActionTypes.INIT_COMP_GROUPS_MENU
})

export const initRotationGroupsMenu = () =>({
    type:ActionTypes.INIT_ROTATION_GROUPS_MENU
})

export const toggleVisible = (id) => ({
    type: ActionTypes.TOGGLE_VISIBLE,
    id
});

export const handleHighlight = (typeOfClick, item) => ({
    type: ActionTypes.HANDLE_HIGHLIGHT,
    typeOfClick,
    item
});

export const updateFlag = (item) => ({
    type:ActionTypes.UPDATE_FLAG,
    item
})

export const updateListItemVisibilty = (item) =>({
    type:ActionTypes.UPDATE_LIST_ITEM_VISIBILITY,
    item
})


export const listItemHighlightSelected = (key,item) =>({
    type:ActionTypes.LIST_ITEM_HIGHLIGHT_SELECTED,
    key,
    item
})

export const setListAddAlertMessage = (message) =>({
    type:ActionTypes.SET_LISTALERT_MSG,
    alertMessage:message
 });

 export const showAddListAlertMessage = (status) =>({
         type:ActionTypes.SHOW_LISTALERT_MSG,
         showAddListAlertMessage:status
     });

export const setAddListName = (lName = "") =>({
        type:ActionTypes.SET_ADDLIST_LISTNAME,
        listName:lName
    });

export const setListActions = (lActions)=>({
    type:ActionTypes.SET_LIST_ACTIONS,
    listActions:lActions
})

export const onClickListAdd = (item) =>({
    type:ActionTypes.ONCLICK_LIST_ADD,
    item
})
 
 export const onClickClearAll = () => ({
    type: ActionTypes.CLEAR_ALL
});

export const fetchResults = (text) => ({
    type: ActionTypes.SEARCH_SYMBOL,
    text
});
export const addSymbolfetchResults = (text) => ({
    type: ActionTypes.SEARCH_SYMBOL_API,
    text
});
export const addSymbolOnEnter = (symbol) => ({
    type: ActionTypes.ADD_SYMBOL_ON_ENTER,
    symbol
});

export const showContextMenu = (pos, item) => ({
    type: ActionTypes.SHOW_CONTEXT_MENU,
    pos,
    item
});

export const showCompGroupsChartContextMenu = (pos, item) => ({
    type: ActionTypes.SHOW_COMP_GROUPS_CHART_CONTEXT_MENU,
    pos,
    item
});

export const hideError = () => ({
    type: ActionTypes.HIDE_ERROR_MSG
});

export const handleRename = (item, itemValue) => ({
    type: ActionTypes.ON_RENAME,
    item,
    itemValue
});

export const toggleSort = (sortType) => ({
    type: ActionTypes.TOGGLE_SORT,
    sortType
});

export const closeContextMenu = () => ({
    type: ActionTypes.HIDE_CONTEXT_MENU
});

export const contextItemClick = (ItemType) => ({
    type: ActionTypes.CONTEXT_MENU_ITEM_CLICK,
    ItemType
});

export const compGroupsChartContextItemClick = (ItemType) => ({
    type: ActionTypes.COMP_GROUPS_CHART_CONTEXT_MENU_ITEM_CLICK,
    ItemType
});

export const hideArrowPress = (index) => ({
    type: ActionTypes.HANDLE_ARROW_KEYS,
    index
});

export const hideDropdown = () => ({
    type: ActionTypes.HIDE_DROPDOWN
});

export const onSelectAddSymbol = (symbol) => ({
    type: ActionTypes.ADD_SYMBOL,
    symbol
});

export const addToList = (request) => ({
    type: ActionTypes.ADD_TO_LIST,
    request
});

export const toggleClearAllDialog = () => ({
    type: ActionTypes.TOGGLE_CLEAR_ALL_DIALOG
});

export const handleEnterPress = (index) => ({
    type: ActionTypes.ADD_SYMBOL_BY_INDEX,
    index
});

export const addIndexLine = () => ({
    type: ActionTypes.ADD_INDEX_LINE,
    isInit: false
});

export const toggleLiqFilter = (isOpen) => ({
    type: ActionTypes.TOGGLE_LIQ_FILTER,
    isOpen
});

export const liqFilterValueChanged = (liqFilterState) => ({
    type: ActionTypes.LIQ_FILTER_CHANGE,
    liqFilterState
});

export const initLiqFilterData = (isComp = false) => ({
    type: ActionTypes.INIT_LIQ_FILTER_DATA,
    isComp
});

export const setAddListModalShowStatus = (status) =>({
    type:ActionTypes.SET_ADDLIST_MODAL_SHOW_STATUS,
    status
})

export const setOpenGeographyStatus = (status) => ({
    type: ActionTypes.SET_OPEN_GEOGRAPHY_STATUS,
    status
})
export const setGeographyByCountryCodeAndCountryName = (countryCode) => ({
    type: ActionTypes.SET_GEOGRAPHY_BY_COUNTRY_CODE_AND_COUNTRY_NAME,
    countryCode
})

export const setCompareTab = (selectedTab) => ({
    type:ActionTypes.SET_COMPARE_TAB,
    selectedTab
})

export const setLiqShowSettingWindow = (showSettingsWindow) =>({
    type:ActionTypes.LIQ_SHOW_SETTING_WINDOW,
    showSettingsWindow
});

export const updateGeographySelection = (isSelected = false) => ({
    type: ActionTypes.UPDATE_GEOGRAPHY_SELECTION,
    isGeographySelected: isSelected
});

export const setListTitlePosition = (posx,posy,titleShow) => ({
    type: ActionTypes.HANDLE_TITLE_MOUSEOVER,
    posx,posy,titleShow
})