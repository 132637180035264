import SubSystemType from 'SubSystemType';
import HttpMethodType from 'HttpMethodType';
import BaseServiceRequest from "BaseServiceRequest";

class GetSummaryBlockDataRequest extends BaseServiceRequest {
    constructor(request) {
        super();
        const { endDate, IsRevWonOnly, isWon, GraphType, Symbol, selectCurrencyCode, isMatchFinancials } = request;
        super.addRequestUri("getsummaryblockdataes.pb");
        // super.addRequestParameter("osid", Osid);
        if (endDate !== null)
            super.addRequestParameter("enddate", endDate);
        // super.addRequestParameter("ishist",isHistoric);
        super.addRequestParameter("isrevwon", IsRevWonOnly);
        super.addRequestParameter("iswon", isWon);
        if (selectCurrencyCode !== null) {
            super.addRequestParameter("scc", selectCurrencyCode);
        }
        if (isMatchFinancials !== null){
            super.addRequestParameter("ismf", isMatchFinancials);
        }
        // super.addRequestParameter("dcc", DefaultCurrencyCode);
        // super.addRequestParameter("wscc", DefaultCurrencyCode);
        // super.addRequestParameter("st", SymbolType);
        super.addRequestParameter("gtype",GraphType);
        super.addRequestParameter("sym",Symbol);
        // super.addRequestParameter("msid",Msid);
    }

    getMethod() {
        return HttpMethodType.GET;
      }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return 'GetSummaryBlkDataResponse';
    }
}

export default GetSummaryBlockDataRequest;