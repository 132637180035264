import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetMetricsLibraryRequest extends BaseServiceRequest {

  constructor(selectedTab) {
    super();
    super.addRequestUri("metrics25.pb");
    if (selectedTab == "OWNERSHIP") 
    super.addRequestParameter("db", 2);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "MetricLibraryResponse25";
  }
}

export default GetMetricsLibraryRequest;
