import React, { PureComponent } from 'react';
import { Button } from "react-bootstrap";
import LocalizationStore from 'LocalizationStore';

class ImageScaling extends PureComponent {

    render() {
        const { zoomPercentageValue } = this.props;

        return (
            <div className="head-right-rotation">
                <Button className=" zoom-reset" bsSize="small" id="cg-reset-btn" data-rb={zoomPercentageValue} >{LocalizationStore.getTranslatedData("CRG_" + zoomPercentageValue, zoomPercentageValue)}</Button>
                <Button className=" zoom-out" bsSize="small" id="cg-zoomout-btn"><span className="icn-zoom-out"></span></Button>
                <Button className=" zoom-in" bsSize="small" id="cg-zoomin-btn"><span className="icn-zoom-in"></span></Button>
            </div>
        );
    }
}

export default ImageScaling;