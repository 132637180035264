import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CommonDialog from "../../../../../RayCustomControls/CommonDialog/CommonDialog";
import { toggleClearAllDialog, onClickClearAll } from 'Actions/ComparisonActions';
import LocalizationStore from 'LocalizationStore';
import { CompareMenuType } from "CompareTabType";

class CompClearAllDialog extends PureComponent {
    render() {
        return (
            this.props.isClearAllDialogOpen && <CommonDialog preClass={"delete-all"}
                isOpen={this.props.isClearAllDialogOpen}
                title={LocalizationStore.getTranslatedData("CCG_DELETEALL", 'DELETE ALL')}
                backButtonShow={false}
                cancelButtonShow={true}
                submitButtonShow={true}
                submitHandler={this.props.onClickClearAll}
                hideModal={this.props.toggleClearAllDialog}
                submitButtonText={LocalizationStore.getTranslatedData('CCG_REMOVE', 'REMOVE')}
                submitButtonOnRight="true"
            >
                <div className="delete-all-region">
                    <p>{LocalizationStore.getTranslatedData("CCG_DeleteAllMsg", "Are you sure you want to delete all contents?")}</p><div className="clearfix"></div>
                </div>
            </CommonDialog>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.compareChart;
    const { isClearAllDialogOpen } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

    return { isClearAllDialogOpen };
}

const mapDispatchToProps = (dispatch) => ({
    toggleClearAllDialog: () => dispatch(toggleClearAllDialog()),
    onClickClearAll: () => dispatch(onClickClearAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompClearAllDialog);