import AppDispatcher from "AppDispatcher";
import { MenuDialogConstants } from "../../Constants/MenuDialogConstants.js";
import { LoginConstants } from "../../Constants/LoginConstants.js";
import { EventEmitter } from "events";
import BaseServiceApi from 'BaseServiceApi';
import changePasswordApi from "../../ServiceApi/Apis/ChangePasswordApi";
import StringUtil from "StringUtil";
import LocalizationStore from 'LocalizationStore';
let ChangePasswordErrorType = BaseServiceApi.rayData["ChangePasswordErrorType"];

const CHANGE_EVENT = "change";
let _currentAction = null;
let _states = {
  isOpen: false,
  eventKey: '',
  results: null
};

let _passChangesState = {
  currentPasswordErrorVisibility: false,
  newPasswordErrorVisibility: false,
  verifyPasswordErrorVisibility: false,
  currentPasswordErrorText: 'Password not recognized',
  newPasswordErrorText: '',
  verifyPasswordErrorText: 'Does not match',
  success: false,
  currentPassword:''
}

class AccountStoreClass extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    return _states;
  }

  isOpenDialog(data) {
    let eventKey = data.eventKey;
    _states = { isOpen: true, eventKey: eventKey };
  }

  getCurrentAction() {
    return _currentAction;
  }

  getPassChangesState() {
    return _passChangesState;
  }

  isWhitespaceOrEmpty(text) {
    return !/[^\s]/.test(text);
  }

  isHavingQuotes(text) {
    return /['"]/.test(text);
  }


  validatePassChanges(currentPassword, newPassword, verifyPassword, loginErrType) {
    let returnOnSuccess = true;

    _passChangesState.currentPasswordErrorVisibility = false;
    _passChangesState.newPasswordErrorVisibility = false;
    _passChangesState.verifyPasswordErrorVisibility = false;

    if ( loginErrType !== "7" && (StringUtil.isEmpty(currentPassword) || this.isWhitespaceOrEmpty(currentPassword))) {
      _passChangesState.currentPasswordErrorVisibility = true;
      returnOnSuccess = false;
    }

    if (!StringUtil.isEmpty(verifyPassword) && !this.isWhitespaceOrEmpty(verifyPassword) && !this.isHavingQuotes(verifyPassword)) {
      let validateVerifiedPassword = this.validateVerifiedPassword(newPassword, verifyPassword);
      if (!validateVerifiedPassword) {
        _passChangesState.verifyPasswordErrorVisibility = true;
        returnOnSuccess = false;
      }
    }
    else if (StringUtil.isEmpty(verifyPassword) || this.isWhitespaceOrEmpty(verifyPassword) || this.isHavingQuotes(verifyPassword)) {
      _passChangesState.verifyPasswordErrorVisibility = true;
      returnOnSuccess = false;
    }

    if (!returnOnSuccess) return false;
    else return true;
  }

  validateVerifiedPassword(newPassword, verifyPassword) {
    if (newPassword === verifyPassword) {
      return true;
    }
    else {
      return false;
    }
  }

  changePassword(data) {
    if (this.validatePassChanges(data.currentPassword, data.newPassword, data.verifyPassword, data.loginErrType)) {
      changePasswordApi.getChangePasswordData(data.currentPassword, data.newPassword, data.loginErrType).then((result) => {
        let changePasswordResponse = result;
        _passChangesState.success = result.success;
        if (changePasswordResponse.passwordErrorType) {
          switch (changePasswordResponse.passwordErrorType) {
            case ChangePasswordErrorType.NO_ERROR:
              _passChangesState.currentPassword = data.newPassword;
              break;
            case ChangePasswordErrorType.PREV_PASSWORD_INVALID:
              _passChangesState.currentPasswordErrorVisibility = true;
              break;
            case ChangePasswordErrorType.NEW_PASSWORD_NOT_STRONG_ENOUGH:
              _passChangesState.newPasswordErrorVisibility = true;
              _passChangesState.newPasswordErrorText = LocalizationStore.getTranslatedData('acc_pnl', "Password is not strong enough");
              break;
            case ChangePasswordErrorType.NEW_PASSWORD_REUSED_HISTORICAL_PASSWORD:
              _passChangesState.newPasswordErrorVisibility = true;
              _passChangesState.newPasswordErrorText = "Password has been used in the past";
              break;
            default:
              break;
          }
        }
        this.emit(CHANGE_EVENT);
      });
    }
    else {
      _passChangesState.success = false;
      this.emit(CHANGE_EVENT);
    }
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      case MenuDialogConstants.ActionTypes.ACCOUNT:
        _currentAction = MenuDialogConstants.ActionTypes.ACCOUNT;
        this.isOpenDialog(data);
        this.emit(CHANGE_EVENT);
        break;
      case LoginConstants.ActionTypes.CHANGE_PASSWORD:
        _currentAction = LoginConstants.ActionTypes.CHANGE_PASSWORD;
        this.changePassword(data);
      default:
        return true;
    }
    return true;
  }
}


const AccountStore = new AccountStoreClass();
export default AccountStore;
