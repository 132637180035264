import React from "react";
import DateHelper from "DateHelper";
import FundamentalTabPeriodicityType from 'Constants/FundamentalTabPeriodicityType.js';
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js'
import FundamentalTabSortOrderType from 'Constants/FundamentalTabSortOrderType.js';
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import LocalizationStore from 'LocalizationStore';

let annualHeaderElements = [];

export default class FundamentalsAnnualHeader extends React.Component {
    constructor() {
        super();
    }

    setHeaderData(headerData, settings) {
        switch (settings.FundamentalReportType) {
            case FundamentalTabReportType.Income:
            case FundamentalTabReportType.Balancesheet:
            case FundamentalTabReportType.Cashflow:
                return this.getReportHeaderData(headerData, settings);
            case FundamentalTabReportType.Ratios:
                return this.setRatiosHeaderData(headerData.FundamentalsRatiosYearList);
            default:
                break;
        }
    }

    getReportHeaderData(data, settings) {
        if (settings.FundamentalPeriodicity === FundamentalTabPeriodicityType.FundamentalsSemiAnnual) {
            let yearLabels = [];
            for (let i = 0; i < data.yearLabels.length; i++) {
                if (data.yearLabels[i] != "") {
                    yearLabels.push(data.yearLabels[i].substring(7, data.yearLabels[i].length));
                }
            }
            return yearLabels;
        } else {
            return data.yearLabels;
        }
    }

    setRatiosHeaderData(data) {
        let yearsList = [];
        if (data != undefined) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].year > 0)
                    yearsList.push(data[i].year.toString());
            }
        }
        return yearsList;
    }

    getYearEnding() {
        if (DatagraphStore.getState().SymbolInfo.FiscalMonthEnd) {
            let month = DateHelper.getMonthbyNumber(DatagraphStore.getState().SymbolInfo.FiscalMonthEnd);
            month = LocalizationStore.getTranslatedData('cal_' + month, month);
            return LocalizationStore.getTranslatedData("fr_ForyearEnding", "For Year ending in {0}", month);
        } else { return ""; }
    }

    getClassNameForSortButton() {
        let className = "icn-sort-fundamental";
        if (this.props.settings.FundamentalSortOrder == FundamentalTabSortOrderType.Descending) className += "-reverse";
        return className;
    }

    render() {
        let annualHeaders = [""];
        let settings = this.props.settings;
        let headerData = this.props.headerData;
        //let handleSortOrderFunc = this.props.handleSortOrderChange;
        //let yearEnding = this.getYearEnding(settings);
        if (headerData != undefined && (headerData.yearLabels || headerData.FundamentalsRatiosYearList)) {
            annualHeaders = this.setHeaderData(headerData, settings);
        }

        if (annualHeaders != undefined) {
            annualHeaderElements = annualHeaders.map((annualHeader, index) =>
                (<td className="head-table" key={index}>{annualHeader}</td>)
            );
        }
        return (<tr>
            {annualHeaderElements}
        </tr>
        );
    }
}
