import React from "react";
import { each, reject, find, findWhere, isEmpty } from "underscore";
import VirtualList from "VirtualList";
import MiniListStore from "MiniListStore";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import SettingsStore from "SettingsStore";
import ListStore from "ListStore";
import ListManagerTabType from "ListManagerTabType";
import SettingsAction from "../../../../Actions/SettingsActions";
import { OverlayTrigger } from "react-bootstrap";
import MiniListListNamePopOver from "../../../../RayCustomControls/PopOver/MiniListListNamePopOver.jsx";
import { ListExplorerConstants } from "ListExplorerConstants";
import { OwnershipViewConstants, OwnerShipFetchDetails} from "../../../../Constants/OwnershipConstants";
import { OwnerShipActions, fetchMoreDataOnScroll } from "../../../../Actions/OwnerShipActions"
import { GridConstants } from "GridConstants";
import KeyCodes from "KeyCodes";
import ListActions from "ListActions";
import UserInfoUtil from "UserInfoUtil";
import StringUtil from "StringUtil";
import LocalizationStore from "LocalizationStore";
import ErrorBoundary from "ErrorBoundary";
import MiniListContextMenu from "./MiniListContextMenu";
import PortalContainer from "PortalContainer";
import GridStore from "../../../../Stores/NavModules/NavList/Grid/GridStore";
import BrowserUtil from "../../../../Utils/BrowserUtil";
import Alerts from "Alerts";
import RecentList from './RecentList';
import GridActions from "GridActions";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import ThemeHelper from "ThemeHelper";
import { MiniListConstants } from "MiniListConstants";
import SymbolType from "SymbolType";
import gridStore from "../../../../Stores/NavModules/NavList/Grid/GridStore";
import TabDataGraphStore from "TabDataGraphStore"
import ConsoleStore from "../../../../Stores/ConsoleWindow/ConsoleStore";
import ListApi from "../../../../ServiceApi/Apis/ListApi";
import BaseServiceApi from 'BaseServiceApi';
import { dispatch } from "../../../../Redux/dispatch";
import { onPlotSymbol } from "../../../../Actions/DatagraphActions";

const ListDatabaseType = BaseServiceApi.rayData["ListDatabaseType"];

let isUpdateScroll = false;
let miniListPlayTimeout = null;
let playTimeout = null;
let _isGetRecentListsCalled = false;
export default class MiniList extends React.Component {
  constructor(props) {
    super(props);
    this.MiniListStoreStateChanged = this.MiniListStoreStateChanged.bind(this);
    // this.oNeilViewStoreStateChange = this.oNeilViewStoreStateChange.bind(this);
    // this.ownershipViewStoreStateChange = this.ownershipViewStoreStateChange.bind(this);
    this.miniListOnScroll = this.miniListOnScroll.bind(this);
    this.state = MiniListStore.getState();
    this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
    this.state = { ...this.state, isPlaying: false, hover: false, addSymbol: false, removeSymbol: false };
    this.removeSymbol = false;
    this.addSymbol = false;
    this.alertMessage = null;
    this.undoActionObj = null;
    this.alertMessageInterval = null;
    this.isMount = false;
    this.toggleRecentListView = this.toggleRecentListView.bind(this);
    this.onHideRecentList = this.onHideRecentList.bind(this);
    this.activeSelectIndex = -1;
    this.currentSelectIndex = undefined;
    this.playIndex = 0;
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.OnWindowSizeChange = this.OnWindowSizeChange.bind(this);
    this.onDocumentKeyUp = this.onDocumentKeyUp.bind(this);
    this.gridStoreStateChange = this.gridStoreStateChange.bind(this);
    this.hideContextMenu = this.hideContextMenu.bind(this);
    this.clearAlertMessageInterval = this.clearAlertMessageInterval.bind(this);
    this.renderTargetItem = this.renderTargetItem.bind(this);
    this.renderNextItem = this.renderNextItem.bind(this);
    this.miniListPlayTick = this.miniListPlayTick.bind(this);
    this.RemoveSymbol = this.RemoveSymbol.bind(this);
    this.AddSymbol = this.AddSymbol.bind(this);
    this.isRenderNextAvailableItem = this.isRenderNextAvailableItem.bind(this);
    this.stopMiniListPlay = this.stopMiniListPlay.bind(this);
    this.IsPlaying = this.IsPlaying.bind(this);
    this.getItemIDByIndexs = this.getItemIDByIndexs.bind(this);
    this.getSelectedListItemIndexs = this.getSelectedListItemIndexs.bind(this);
    this.OnVisibilityChange = this.OnVisibilityChange.bind(this);
    this.increasePlayInterval = this.increasePlayInterval.bind(this);
    this.decreasePlayInterval = this.decreasePlayInterval.bind(this);
    this.handleAlertShow = this.handleAlertShow.bind(this);
    this.isActivePlaying = false;
    this.onMouseDown = this.onMouseDown.bind(this);
  }

  componentDidMount() {
    const self = this;
    this.isMount = true;
    MiniListStore.addChangeListener(this.MiniListStoreStateChanged);
    // ONeilViewStore.addChangeListener(this.oNeilViewStoreStateChange);
    // OwnershipViewStore.addChangeListener(this.ownershipViewStoreStateChange);
    GridStore.addChangeListener(this.gridStoreStateChange);
    const state = MiniListStore.getState();
    this.setState(state);
    document.addEventListener("keydown", this.onDocumentKeyPress, false);
    document.addEventListener("keyup", this.onDocumentKeyUp, false);
    document.addEventListener("mousedown", this.onMouseDown, false);
    window.addEventListener("resize", this.OnWindowSizeChange, false);
    window.addEventListener("visibilitychange", this.OnVisibilityChange, false);
   
    window.setTimeout(() => {
      self.state.activeItem = MiniListStore.getActiveItem();
      self.state = { ...self.state, activeItem: MiniListStore.getActiveItem(), selectedIndex: state.selectedIndex };
      if (self.state && self.state.activeItem) {
        self.state = { ...self.state, activeItem: { ...self.state.activeItem, isActive: true } };
        if (self.state.listItems && self.state.listItems.length > 0) {
          const index = this.state.listItems.map((item) => item.msId).indexOf(self.state.activeItem.msId);
          self.virtualList && self.virtualList.scrollToItemIndex(index);
        }
      }
      else {
        self.virtualList && self.virtualList.scrollToItemIndex(-1);
      }
    }, 0);
  }

  componentWillUnmount() {
    MiniListStore.removeChangeListener(this.MiniListStoreStateChanged);
    // ONeilViewStore.removeChangeListener(this.oNeilViewStoreStateChange);
    // OwnershipViewStore.removeChangeListener(this.ownershipViewStoreStateChange);
    GridStore.removeChangeListener(this.gridStoreStateChange);
    document.removeEventListener("keydown", this.onDocumentKeyPress, false);
    document.removeEventListener("keyup", this.onDocumentKeyUp, false);
    document.removeEventListener("mousedown", this.onMouseDown, false);
    window.removeEventListener("resize", this.OnWindowSizeChange, false);
    window.removeEventListener("visibilitychange", this.OnVisibilityChange, false);
    //MiniListStore.setPreviouseIndex(this.state.listName, this.state.previousIndex);
    MiniListStore.setPreviouseIndex(this.state.listName, this.state.previousIndex, this.state.activeItem);
    this.isMount = false;
    this.clearAlertMessageInterval();
    this.clearMiniListPlayInterval();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(!TabDataGraphStore.isThemeSettingChanged() && this.state == nextState){
      return false;
    }
    return true;
  }

  onMouseDown(e) {
    if(e){
      if(BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape" || BrowserUtil.getBrowserName() == "EDGE" ){
        if(e.srcElement && e.srcElement.id === "miniListPalyBtn"){
          return;
        }
      }
      else if(e.toElement && e.toElement.id === "miniListPalyBtn"){
        return;
      }
    }
    this.stopMiniListPlay();
  }

  OnWindowSizeChange(){
    this.stopMiniListPlay();
  }

  OnVisibilityChange(){
    if(document.hidden && document.visibilityState === "hidden"){
      this.stopMiniListPlay();
    }
  }

  handleMouseMove() {
    this.setState({ hover: true });
  }

  handleMouseLeave(){
    this.setState({ hover: false });
  }  

  MiniListStoreStateChanged() {
    const storeAction = MiniListStore.getCurrentAction();
    if(storeAction === MiniListConstants.ActionTypes.ON_MINILIST_PLAYING){
      return;
    }
    const state = MiniListStore.getState();
    const listItem = this.state.listItems[this.playIndex];
    switch(storeAction){
      case MiniListConstants.ActionTypes.SET_MINILIST_NEXTINTERVAL:
        if(this.state.isPlaying && this.isActivePlaying && this.playIndex < this.state.listItems.length - 1){
          // const storeState = MiniListStore.getState();
          // const currentDate = new Date();
          // let currentInterval = (currentDate - storeState.ActiveSymbolStartTime) / 1000;
          // if(currentInterval >=  this.state.playInterval){
          //   this.miniListPlayTick();
          // }
          // else{
            // let waitInterVal = this.state.playInterval;// - currentInterval
            this.clearMiniListPlayTimeout();
            clearTimeout(playTimeout);
            playTimeout = setTimeout(() => {
              clearTimeout(playTimeout);
              this.miniListPlayTick();
            }, this.state.playInterval * 1000);
          // }
          //this.miniListStorePlayTimer = setInterval(this.miniListPlayTick, this.state.playInterval * 1000);
        }
        MiniListStore.clearCurrentAction();
        break;
      case MiniListConstants.ActionTypes.ON_UPDATE_UPDATEHISTORIC:
      case MiniListConstants.ActionTypes.LIST_NAME_UPDATE:
        this.setState(state);
        MiniListStore.clearCurrentAction();
        break;
      case MiniListConstants.ActionTypes.CLEAR_MINILISTPLAY_TIMEOUT:
        this.clearMiniListPlayTimeout();
        break;
      default:
        this.setState(state);
        const self = this;
        const activeItem = MiniListStore.getActiveItem();
        if (isUpdateScroll && self.prevIndex) {
          setTimeout(() => {
            self.virtualList && self.virtualList.scrollToItemIndex(this.prevIndex);

            self.prevIndex = null;
            isUpdateScroll = false;
          }, 100);
        }
        if (activeItem && !isUpdateScroll) {
          let isActiveItemChange = false;
          if (this.state.activeItem) {
            if(activeItem.symbol !== this.state.activeItem.symbol){
              //this.state = { ...this.state, activeItem: { ...this.state.activeItem, isActive: false } };
              this.setState({ activeItem: { ...this.state.activeItem, isActive: false } });
              isActiveItemChange = true;
              //this.state.activeItem.isActive = false;
            }
          }
          else{
            isActiveItemChange = true;
          }
          if(storeAction === MiniListConstants.ActionTypes.ON_ADD_SYMBOL_COMPLETE){
            isActiveItemChange = false;
          }
          
          if(isActiveItemChange){
            activeItem.isActive = true;
            if (this.state && this.state.listItems && this.state.listItems.length > 0) {
               const index = this.state.listItems.indexOf(activeItem);
               this.activeSelectIndex = index;
              // window.setTimeout(() => {
              //      self.state = { ...self.state, activeItem: activeItem };
              //      self.virtualList && self.virtualList.scrollToItemIndex(index);
              // }, 0);
              /* commenting this action would stop rerendering the datagraph.*/
              //SettingsAction.onPlotSymbol(activeItem.symbol); // Reloads the symbol that is already plotted and recalls all the Apis.
              //MiniListStore.clearActiveItem();
              this.updateActiveItem(activeItem);
              if(storeAction === MiniListConstants.ActionTypes.ON_INTERNAL_CHANGE){
                MiniListStore.updateActiveItem(activeItem);
              }
              window.setTimeout(() => {
                self.setState({...self.state, activeItem: activeItem, previousIndex: [] });
                if(storeAction === MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE){
                   self.virtualList && self.virtualList.scrollToItemIndex(index);
                }
              }, 0);
              if(!state.isMiniListItemClicked){
                this.renderTargetItem(activeItem);
              }
            }
          }
        }
        break;
    }
  }


  onDocumentKeyUp(e) {
    if (document.body.classList.contains("changePassWordOpened") || document.getElementsByClassName("dialog-open").length > 0 || (e.srcElement.tagName === 'INPUT') || (e.srcElement.tagName === 'TEXTAREA') || (e.srcElement.isContentEditable) || document.body.classList.contains("datagraphCalendarOpened") || (document.activeElement && document.activeElement.id === "SymbolEntryInput") || 
    (document.activeElement && (document.activeElement.id === "txtOwnershipReportFind" || document.activeElement.classList.contains("disableMiniListKeyDown"))) || document.body.classList.contains("annotation-active") || document.body.classList.contains("view-console-multi-chart")) {
      return;
    }
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode === KeyCodes.SPACE) {
      if (this.state.listItems && this.state.listItems.length > 0 && !document.body.classList.contains("modal-open")) {
        let activeItem;
        if (this.state.activeItem) {
            activeItem = this.state.activeItem;
        } else {
            activeItem = findWhere(this.state.listItems, { "isActive": true });
            // if (activeItem === undefined || activeItem === null) {
            //     activeItem = this.state.listItems[0];
            // }
        }
        let index = -1;
        if(activeItem){
          index = this.state.listItems.map((item) => item.msId).indexOf(activeItem.msId);
          if (index === this.state.listItems.length - 1) {
            index = -1;
          }
        }
        const item = this.state.listItems[index + 1];
        this.virtualList.scrollToItemIndex(index + 1);

        item.isActive = true;
        this.updateActiveItem(item);
        MiniListStore.updateActiveItem(item);
        this.setState({ activeItem: item, previousIndex: [], selectedIndex: [] }, () => {
          MiniListStore.updateSelectedRowIndexs([]);
          ListActions.setMiniListAction(true);
          e.preventDefault();
          e.stopPropagation();

        const symbol = this.state.activeItem.symbol;
        setTimeout(() => {
          if (this.state.activeItem && this.state.activeItem.symbol === symbol) {
            const nodeCount = ConsoleStore.getState().nodeCount;
            dispatch(onPlotSymbol(this.state.activeItem.symbol, true, nodeCount, false, true));
          }
        }, 400);
      });
    }
  }
}

  onDocumentKeyPress(e) {
    const isRiPanelOpen = DatagraphStore.getNavDataGraphSettings().isRIPanelOpen ;
    if (document.body.classList.contains("changePassWordOpened") || document.getElementsByClassName("dialog-open").length > 0 || e.target.classList.contains("disableMiniListKeyDown") || document.body.classList.contains("datagraphCalendarOpened") || (document.activeElement && document.activeElement.id === "SymbolEntryInput") || (document.activeElement && document.activeElement.id === "formControlEmailBody") || (document.activeElement && document.activeElement.id === "formControlEmailSubject") || (document.activeElement && document.activeElement.id === "formControlsEmail") || (document.activeElement && document.activeElement.id === "txtOwnershipReportFind") || (document.activeElement && document.activeElement.id.includes("annot-dropdown")) || document.getElementById("annotateIntrumentsSelectScroll") || (!isRiPanelOpen && document.body.classList.contains("riPanel")) || (document.body.classList.contains("view-console-multi-chart")) ) {
      return;
    }

    const charCode = (e.which) ? e.which : e.keyCode;
    // if (charCode === KeyCodes.SPACE) {
    //   if (this.state.listItems && this.state.listItems.length > 0 && !document.body.classList.contains("modal-open")) {
    //     let activeItem;
    //     if (this.state.activeItem)
    //       activeItem = this.state.activeItem;
    //     else
    //       activeItem = findWhere(this.state.listItems, { "isActive": true });
    //     let index = this.state.listItems.indexOf(activeItem);
    //     if (index === this.state.listItems.length - 1) {
    //       index = -1;
    //     }
    //     const item = this.state.listItems[index + 1];
    //     this.virtualList.scrollToItemIndex(index + 1);
    //     if (this.state.activeItem) {
    //       //this.state = { ...this.state, activeItem: { ...this.state.activeItem, isActive: false } };
    //       this.setState({ activeItem: { ...this.state.activeItem, isActive: false } });
    //       //this.state.activeItem.isActive = false;
    //     }

    //     item.isActive = true;
    //     this.updateActiveItem(item);
    //     MiniListStore.updateActiveItem(item);
    //     this.setState({ activeItem: item, previousIndex: [], selectedIndex: [] }, MiniListStore.updateSelectedRowIndexs([]));
    //   }
    //   ListActions.setMiniListAction(true);
    //   e.preventDefault();
    //   e.stopPropagation();
    // }

    if (charCode === KeyCodes.UP_ARROW || charCode === KeyCodes.DOWN_ARROW) {
      if (document.activeElement && (document.activeElement.id === "SymbolEntryInput" || document.activeElement.id === "temp-input")) {
        return;
      }
      if (this.state.listItems && this.state.listItems.length > 0) {
        let index = -1;
        if (this.state.previousIndex.length > 0) {
          index = this.state.previousIndex[0];
        }
        if (index < 0 && this.state.activeItem) {
          index = this.state.listItems.indexOf(this.state.activeItem);
        }
        if (charCode === KeyCodes.UP_ARROW && index >= 1) {
          index -= 1;
        }
        else if (charCode === KeyCodes.DOWN_ARROW && index < this.state.listItems.length - 1) {
          index += 1;
        }

        this.virtualList.scrollToItemIndex(index);
        this.setState({ previousIndex: [index], selectedIndex: [index] }, MiniListStore.updateSelectedRowIndexs([index]));
      }
      // e.preventDefault();
      // e.stopPropagation();
    }
    else if (charCode === KeyCodes.ENTER) {
      if (document.activeElement && document.activeElement.id === "SymbolEntryInput") {
        return;
      }
      if (this.state.selectedIndex.length > 0) {
        const item = this.state.listItems[this.state.selectedIndex[0]];
        if (!item) return;
        if (this.state.activeItem !== item) {
          if (this.state.activeItem) {
            //this.state = { ...this.state, activeItem: { ...this.state.activeItem, isActive: false } };
            this.setState({ activeItem: { ...this.state.activeItem, isActive: false } });
            //this.state.activeItem.isActive = false;
          }
          item.isActive = true;
          this.updateActiveItem(item);
          MiniListStore.updateActiveItem(item);
          const nodeCount = ConsoleStore.getState().nodeCount;
          dispatch(onPlotSymbol(item.symbol, true, nodeCount, false, true));
          this.setState({ activeItem: item, previousIndex: [], selectedIndex: [] }, MiniListStore.updateSelectedRowIndexs([]));
        }
      }
      ListActions.setMiniListAction(true);
    }
  }

  // oNeilViewStoreStateChange () {
  //   const settings = SettingsStore.getConsoleSettings();
  //   const navListManagerSettings = settings.NavListManagerSettings;
  //   if (navListManagerSettings.SelectedTabKey !== ListManagerTabType.Oneil) {
  //     return;
  //   }
  //   const currentAction = ONeilViewStore.getCurrentAction();
  //   if (currentAction === ListExplorerConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL) {
  //     const source = ONeilViewStore.getSourceData();
  //     const listState = ListStore.getSelectedListItem();
  //     //const listName = listState.SelectedListName;
  //     MiniListStore.setCurrentAction(MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE);
  //     MiniListStore.setMiniList(source, listState);
  //   }
  // }

  // ownershipViewStoreStateChange() {
  //   const currentAction = OwnershipViewStore.getCurrentAction();
  //   if (currentAction === OwnershipViewConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL_OWNERSHIP) {
  //     const source = OwnershipViewStore.getSourceData();
  //     const listState = ListStore.getSelectedListItem();
  //     //const listName = listState.SelectedListName;
  //     MiniListStore.setCurrentAction(MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE);
  //     MiniListStore.setMiniList(source, listState);
  //   }
  // }

  handleAlertShow(){
    if(this.alert){
      this.alert.handleAlertShow();
    }
  }

  gridStoreStateChange() {
    if(this.isMount){
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    let source;
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
      source = ONeilViewStore.getSourceData();
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      source = OwnershipViewStore.getSourceData();
    }
    const gridAction = GridStore.getGridBodyAction();
    const listState = ListStore.getSelectedListItem();
    switch(gridAction){
      case GridConstants.ActionTypes.REMOVE_GRID_ROWS:
          MiniListStore.updateNextActiveItem();
          MiniListStore.setCurrentAction(MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE);
          MiniListStore.setMiniList(source, listState);
          BrowserUtil.enableEvents();
          this.alertMessage = GridStore.getAlertMessage();
          if (this.alertMessage) {
            this.undoActionObj = {};
            this.undoActionObj.action = GridStore.getGridBodyAction();
            this.removeSymbol = false;
            this.setState({
              showAlert: true
              // ,
              // removeSymbol: false
            });
            this.handleAlertShow();
          }
          GridStore.clearGridBodyAction();
          GridStore.clearCurrentAction();
          this.hideAlertMessage();
          break;
      case GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO:
          MiniListStore.setCurrentAction(MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE);
          MiniListStore.setMiniList(source, listState, true);
          BrowserUtil.enableEvents();
          this.clearAlertMessageInterval();
          this.setState({
            showAlert: false
          });
          break;
      case GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER:
          BrowserUtil.enableEvents();
          this.alertMessage = GridStore.getAlertMessage();
          if (this.alertMessage) {
            if (GridStore.isUndo()) {
              this.undoActionObj = {};
              this.undoActionObj.action = GridStore.getGridBodyAction();
            }else{
              this.undoActionObj = null;
            }
            this.addSymbol = false;
            this.setState({
              showAlert: true
              // ,
              // addSymbol: false
            });
            this.handleAlertShow();
          }
          GridStore.clearGridBodyAction();
          GridStore.clearCurrentAction();
          this.hideAlertMessage();
        break;
      case GridConstants.ActionTypes.PASTE_GRID_ROWS_UNDO:
            BrowserUtil.enableEvents();
            const selectedTab = ListStore.getState().SelectedTabKey;
            const tabType = ListStore.getTabType(selectedTab);
            switch (tabType) {
              case ListManagerTabType.Oneil:
                ListActions.getListCatalog(ONeilViewStore.listCatalog);
                break;
              case ListManagerTabType.Ownership:
                OwnerShipActions(OwnershipViewStore.listCatalog);
                break;
              default:
                break;
            }
            GridStore.clearGridBodyAction();
            GridStore.clearCurrentAction();
            this.clearAlertMessageInterval();
            this.setState({
              showAlert: false
            });
        break;
      default:
        break;
    }
  }
  }
  
  setItemActive(e, item, index) {
    let selectedRowIndexes = this.state.selectedIndex;
    let lastSelectedIndex = 0;
    if (e && e.shiftKey) {
      // if (selectedRowIndexes.length > 0) {
      //   lastSelectedIndex = selectedRowIndexes[selectedRowIndexes.length - 1];
      // }
      if (this.activeSelectIndex > 0) {
        lastSelectedIndex = this.activeSelectIndex;
      }
      else if(this.state.activeItem){
        const index = this.state.listItems.map((item) => item.msId).indexOf(this.state.activeItem.msId);
        lastSelectedIndex = index ? index : 0;
      }
      else 
      if(this.currentSelectIndex){
        lastSelectedIndex = this.currentSelectIndex;
      }
      selectedRowIndexes = [];
      const indexes = [index, lastSelectedIndex];
      indexes.sort((a, b) => (a - b));

      for (let i = indexes[0]; i <= indexes[1]; i++) {
        selectedRowIndexes.push(i);
      }
      this.setState({ selectedIndex: selectedRowIndexes, previousIndex: [] }, MiniListStore.updateSelectedRowIndexs(selectedRowIndexes));
    }
    else if (e && (e.ctrlKey || e.metaKey)) {
      this.activeSelectIndex = index
      if (!selectedRowIndexes.includes(index)) {
        selectedRowIndexes.push(index);
      }
      else {
        const indexOfElm = selectedRowIndexes.indexOf(index);
        if (indexOfElm !== -1) {
          selectedRowIndexes.splice(indexOfElm, 1);
        }
      }
      this.setState({ selectedIndex: selectedRowIndexes, previousIndex: [] }, MiniListStore.updateSelectedRowIndexs(selectedRowIndexes));
    }
    else {
      // const activeItem = this.state.activeItem ? this.state.activeItem : findWhere(this.state.listItems, { "isActive": true });
      // if (activeItem) {
      //   activeItem.isActive = false;
      // }
      item.isActive = true;
      this.updateActiveItem(item);
      MiniListStore.updateActiveItem(item);
      this.setState({ activeItem: item, selectedIndex: index, previousIndex: [] }, MiniListStore.updateSelectedRowIndexs([index]));
      setTimeout(() => {
        if (item.symbol === this.state.activeItem.symbol){
          const nodeCount = ConsoleStore.getState().nodeCount;
          dispatch(onPlotSymbol(this.state.activeItem.symbol, true, nodeCount, false, true));
        }
      }, 300);
    }
    ListActions.setMiniListAction(true);
  }

  renderTargetItem(item){
    if(item){
      setTimeout(() => {
        SettingsAction.onMiniListChart(item.symbol, false);
      }, 50);

      item.isActive = true;
      this.updateActiveItem(item);      
      MiniListStore.updateActiveItem(item);
      this.setState({ activeItem: item, selectedIndex: [], previousIndex: [] }, MiniListStore.updateSelectedRowIndexs([]));
    }
  }

  renderNextItem(){
    let isRenderNextItem = false;
    if(this.playIndex < this.state.listItems.length){
      isRenderNextItem = true;
      const listItem = this.state.listItems[this.playIndex];

      MiniListStore.updateActivePlaySymbol(listItem.symbol);
      console.log("MiniListPlayTrack renderNextItem ", listItem.symbol, new Date());
      SettingsAction.onMiniListPlay(listItem.symbol, true);

      miniListPlayTimeout = setTimeout(() => {
        this.clearMiniListPlayTimeout();
        if(this.state.isPlaying && this.isActivePlaying){
          this.miniListPlayTick();
        }
      }, this.state.playInterval * 1000);

      listItem.isActive = true;
      this.updateActiveItem(listItem);      
      MiniListStore.updateActiveItem(listItem);
      this.setState({ activeItem: listItem, selectedIndex: [], previousIndex: [] }, MiniListStore.updateSelectedRowIndexs([]));

 
      this.virtualList && this.virtualList.scrollToItemIndex(this.playIndex);
    }
    return isRenderNextItem;
  }

  clearMiniListPlayTimeout(){
    clearTimeout(miniListPlayTimeout);
  }

  getNextAvailableItem (selectedRowIndexes)  {
    let nextItem = null;
    let availableItems = null;
    { /*eslint-disable*/ }
    if (selectedRowIndexes.length > 0) {
      availableItems = reject(this.state.listItems, (item, k) => selectedRowIndexes.includes(k));
    }
    else {
      availableItems = reject(this.state.listItems, (item, k) => selectedRowIndexes === k);
    }
    { /*eslint-enable*/ }

    if (this.state.listItems.length > 0 && availableItems.length > 0) {
      const index = this.state.listItems.indexOf(this.state.activeItem) + 1;
      for (let i = index; i < this.state.listItems.length; i++) {
        nextItem = find(availableItems, (item) => this.state.listItems[i] === item);
        if (nextItem) {
          return nextItem;
        }
      }
      nextItem = availableItems[0]
      return nextItem;
    }
    return nextItem;
  }

  updateActiveItem(activeItem) {
    const index = this.state.listItems.indexOf(activeItem);
    this.activeSelectIndex = index;
    if (index !== -1) {
      // this.state = {
      //   ...this.state,
      //   listItems: this.state.listItems.map((itm, k) => {
      //     if (k === index) {
      //       itm.isActive = true;
      //     }
      //     else {
      //       itm.isActive = false;
      //     }
      //   })
      // }
      this.setState({
        listItems: this.state.listItems.map((itm, k) => {
          if (k === index) {
            itm.isActive = true;
          }
          else {
            itm.isActive = false;
          }
          return itm;
        })
      })
    }
  }

  getItemRenderer(item, top, index) {
    if (item) {
      const isActive = item.isActive;
      let itemClasses = 'item';
      if (this.state.selectedIndex.includes(index) && isActive) {
        this.currentSelectIndex = index;
        this.playIndex = index;
        itemClasses += ' active-selected';
      }
      else if (isActive) {
        this.currentSelectIndex = index;
        this.playIndex = index;
        itemClasses += ' active';
      }
      else {
        itemClasses += this.state.selectedIndex.includes(index) ? ' selected' : '';
      }


      itemClasses += ' medium-normal';
      let displayName = null;
      let textOverflow = null;
      if (UserInfoUtil.hasChinaEntitlement()) {
        displayName = item.coName;
        textOverflow = "ellipsis";
      } else {
        displayName = item.symbol;
        textOverflow = "clip";
      }
      return (
        <div key={index} onContextMenu={(e) => this.onContextMenuClick(e, item, index)} onClick={(e) => this.setItemActive(e, item, index)} className={itemClasses} style={{ top: top, overflow: "hidden", textOverflow: textOverflow }} title={displayName}>
          {displayName}
        </div>
      );
    }
  }

  toggleRecentListView() {
    if (_isGetRecentListsCalled) {
      return;
    }
    _isGetRecentListsCalled = true;
    try {
      const dbId = ListDatabaseType.ONEIL_ListDatabase;
      ListApi.getRecentLists(dbId).then((result) => {
        _isGetRecentListsCalled = false;
        if (!result.responseHeader.error) {
          const recentNodes = result.data && result.data.explorerNodes ? result.data.explorerNodes : [];
          const listState = ListStore.getSelectedListItem();
          let recentList = [];
          if (recentNodes.length > 0) {
            const selectListId = typeof listState.SelectedListId === "object" ? listState.SelectedListId.low : listState.SelectedListId
            let recentListCount = 0;
            for (let index = 0; index < recentNodes.length; index++) {
              const item = recentNodes[index];
              const itemNodeId = typeof item.nodeId === "object" ? item.nodeId.low : item.nodeId;
              if (itemNodeId == selectListId || recentListCount === 20) {
                continue
              }
              if (item) {
                recentList.push(item);
                recentListCount++;
              }
            }
          }
          this.setState({ toggleRecentList: !this.state.toggleRecentList, recentLists: recentList, recentListsSource: recentNodes, ListCategoryType: listState.SelectedCategoryType });
        }
      });
    } catch (error) {
      _isGetRecentListsCalled = false;
      console.error(error);
    }
  }

  onHideRecentList() {
    this.setState({ toggleRecentList: false });
  }

  getListName() {
    return LocalizationStore.getTranslatedData(`TreeExplorer_LST_${  this.state.listName.replace(/ /ig, "")}`, this.state.listName) !== this.state.listName ? LocalizationStore.getTranslatedData(`TreeExplorer_LST_${  this.state.listName.replace(/ /ig, "")}`, this.state.listName) : LocalizationStore.getTranslatedData("LM_oneilDB", this.state.listName);
  }

  miniListOnScroll(index, visibleCount) {
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil && ONeilViewStore && ONeilViewStore.getState().basic && this.state.listItems && this.state.listItems.length < ONeilViewStore.getState().basic.count) {
      if (index > this.state.listItems.length - visibleCount && index <= ONeilViewStore.getSourceData().length && !isUpdateScroll) {
        const listCatalog = {};
        isUpdateScroll = true;
        listCatalog.pageIndex = ONeilViewStore.getSourceData().length;
        listCatalog.isMiniLisOnScroll = true;
        this.prevIndex = ONeilViewStore.getSourceData().length-(window.innerHeight/66.6);
        ListActions.fetchMoreDataOnScroll(listCatalog);
        return true;
      }
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {      
      if(!StringUtil.isEmpty(this.state.listItems)){
       if (OwnershipViewStore && OwnershipViewStore.getState().basic && this.state.listItems.length < OwnershipViewStore.getState().basic.count) {
         if (index > this.state.listItems.length - visibleCount && index <= OwnershipViewStore.getSourceData().length && !isUpdateScroll) {
            const listCatalog = {};
            isUpdateScroll = true;
            listCatalog.pageIndex = OwnershipViewStore.getSourceData().length;
            this.prevIndex = OwnershipViewStore.getSourceData().length-(window.innerHeight/66.6);
            fetchMoreDataOnScroll(listCatalog);
            return true;
          }
        }
      }
    }
    return false;
  }

  hideContextMenu() {
    this.setState({ showContextMenu: false });
  }

  getSelectedListItemIndexs(itemIndex){
    let selectedIndexs = []
    if(this.state.selectedIndex.length > 0){
       selectedIndexs = Object.assign(selectedIndexs, this.state.selectedIndex);
    }
    let currentSelectItem = this.state.listItems[this.currentSelectIndex];
    if(currentSelectItem && currentSelectItem.isActive && !selectedIndexs.includes(this.currentSelectIndex)){
       selectedIndexs.push(this.currentSelectIndex);
    }
    if(itemIndex && !selectedIndexs.includes(itemIndex)){
       selectedIndexs.push(itemIndex);
    }
    return selectedIndexs;
  }

  getSelectedListItemIndexsForDeletion(itemIndex){
    let selectedIndexs = []
    if(this.state.selectedIndex.length > 0){
       selectedIndexs = Object.assign(selectedIndexs, this.state.selectedIndex);
       return selectedIndexs.includes(itemIndex) ? selectedIndexs : [itemIndex];
    }
    else{
      return [itemIndex];
    }
  }

  getItemIDByIndexs(indexs){
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    let tempMsId = [];
    if (indexs && indexs.length > 0) {
      if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil){
        each(this.state.listItems, (item, k) => {
          if (indexs.includes(k)) {
            tempMsId.push(item.msId);
          }
        });
      }
      else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership){
        each(this.state.listItems, (item, k) => {
          if (indexs.includes(k)) {
            tempMsId.push(item.sponsorId);
          }
        });
      }
    }
    return tempMsId;
  }

  onContextMenuClick (e, item, index) {    

    const selectIndexs = this.getSelectedListItemIndexsForDeletion(index);
    const tempMsId = this.getItemIDByIndexs(selectIndexs);
    let msId = tempMsId.join(";");
    const listState = ListStore.getSelectedListItem();
    const listId = listState.SelectedActualListId;
    const cacheId = ONeilViewStore.getState().cacheId;
    if (MiniListStore.isContextMenuEnable()) {
      const isRenderNextAvailableItem = this.isRenderNextAvailableItem(selectIndexs);
      if(isRenderNextAvailableItem){
          const nextAvailableItem = this.getNextAvailableItem(this.state.selectedIndex);
          MiniListStore.updateTempNextActiveItem(nextAvailableItem, this.state.activeItem);
      }
      this.setState({ showContextMenu: true });
      this.contextMenuObj = {
        xPos: e.clientX,
        yPos: e.clientY,
        selectedIndexes: selectIndexs,
        msIDS: msId,
        listId: listId,
        cacheId: cacheId
      }
    }

    e.preventDefault();
    e.stopPropagation();
  }

  renderAlert() {
    return (
    <PortalContainer>
      <Alerts ref={(r) => { this.alert = r; }} alertMessage={this.alertMessage} undoActionObj={this.undoActionObj} />
    </PortalContainer>
    );
  }    

  hideAlertMessage() {
    this.alertMessageInterval = setTimeout(() => {
      this.alertMessage = null;
      this.undoActionObj = {};
      if (this.isMount)
        this.setState({ showAlert: false })
      if (this.alert)
        this.alert.handleAlertDismiss();
    }, 10000);
  }

  clearAlertMessageInterval() {
    clearTimeout(this.alertMessageInterval);
  }

  IsPlaying(){
    const isPlaying = !this.state.isPlaying;
    this.setState({ isPlaying: isPlaying },()=>{
      this.isActivePlaying = isPlaying;
      MiniListStore.setIsMiniListPaly(this.isActivePlaying);
      const listItemsLastIndex = this.state.listItems.length - 1;
      if((this.currentSelectIndex && this.currentSelectIndex === listItemsLastIndex)
       ||(!this.currentSelectIndex && this.currentSelectIndex !== 0)
       ||(this.currentSelectIndex > listItemsLastIndex)){
        this.playIndex = 0;
      }
      else if (this.playIndex < listItemsLastIndex){
        this.playIndex  = this.playIndex + 1;
      }
      if(isPlaying){
        this.renderNextItem();
        if(this.playIndex === listItemsLastIndex){
          this.stopMiniListPlay();
        }
      }
      else{
        this.stopMiniListPlay();
      }
    });
   
  }

  clearMiniListPlayInterval(){
    clearInterval(this.miniListPlayTimer);
    clearInterval(this.miniListStorePlayTimer);
  }

  stopMiniListPlay(){
    this.clearMiniListPlayInterval();
    this.isActivePlaying = false;
    if(this.state.isPlaying){
      this.setState({ isPlaying: false });
    }
    this.clearMiniListPlayTimeout();
    clearTimeout(playTimeout);
    MiniListStore.setIsMiniListPaly(false);
  }
 
  AddSymbol(){
    const viewSetting = DatagraphStore.getNavDataGraphSettings();
    const symbol = viewSetting.symbol;
    const listState = ListStore.getSelectedListItem();
    const listId = listState.SelectedActualListId;
    const listCount = this.state.listItems.length;
    const listName = listState.SelectedListName;
    console.log( `MiniList add Symbol the symbol is ${symbol}`);

    GridActions.addSymbolInListManager(symbol, listId, null, listCount + 1, listName);
    // this.setState({ addSymbol: true });
    this.addSymbol = true;
  }

  isRenderNextAvailableItem(selectIndexs){
    let isRenderNextAvailableItem = false;
    if (selectIndexs && selectIndexs.length > 0) {
      for (let i = 0; i < selectIndexs.length; i++) {
        const itemIndex = selectIndexs[i];
        const item = this.state.listItems[itemIndex];
        if(itemIndex < (this.state.listItems.length -1) && item && item.isActive){
          isRenderNextAvailableItem = true;
          break;
        }
      }
    }
    return isRenderNextAvailableItem;
  }

  RemoveSymbol(){
    const selectIndexs = this.getSelectedListItemIndexs();
    const isRenderNextAvailableItem = this.isRenderNextAvailableItem(selectIndexs);
    if(isRenderNextAvailableItem){
      const nextAvailableItem = this.getNextAvailableItem(this.state.selectedIndex);
      MiniListStore.updateTempNextActiveItem(nextAvailableItem, this.state.activeItem);
    }
    const listState = ListStore.getSelectedListItem();
    const listId = listState.SelectedActualListId;
    const cacheId = ONeilViewStore.getState().cacheId;
    const tempMsId = this.getItemIDByIndexs(selectIndexs);
    if(tempMsId.length > 0 && this.removeSymbol === false){
      BrowserUtil.disableEvents();
      const msIDS = tempMsId.join(";");
      const snapshotDates = ONeilViewStore.getSnapshotDateFromMsIds(msIDS.split(';'));
      GridActions.removeGridRows(listId, cacheId, msIDS, snapshotDates, true);
      this.clearAlertMessageInterval();
      // this.setState({ removeSymbol: true });
      this.removeSymbol = true;
    }
  }

  miniListPlayTick(){
    // this.clearMiniListPlayInterval();
    if(!this.state.isPlaying){
      return;
    }

    let isLast = this.playIndex >= this.state.listItems.length - 1;
    if(!isLast){
      this.playIndex = this.playIndex + 1;
      isLast = this.playIndex === this.state.listItems.length - 1;
      this.renderNextItem();
    }

    if(isLast){
      this.stopMiniListPlay();
    }
  }

  increasePlayInterval(){
    const playInterval = this.state.playInterval + 1 ;
    if(playInterval < 21){
      MiniListStore.updatePlayInterval(playInterval);
      this.setState({playInterval : playInterval});
    }
  }

  decreasePlayInterval(){
    const playInterval = this.state.playInterval - 1 ;
    if(playInterval > 4){
      MiniListStore.updatePlayInterval(playInterval);
      this.setState({playInterval : playInterval})
    }
  }

  render() {
    const listName = LocalizationStore.getTranslatedData(`TreeExplorer_LST_${  this.state.listName.replace(/ /ig, "")}`, this.state.listName);
    const renderAlerts = this.renderAlert();
    let isAddSymbolEnable = MiniListStore.isAddSymbolEnable();
    let isRemoveSymbolEnable = MiniListStore.isRemoveSymbolEnable();
    
    const symbolInfo = DatagraphStore.getSymbolInfo();
    switch(symbolInfo && symbolInfo.SymTypeEnum){
       case SymbolType.FUTURE:
       case SymbolType.CASHSPOTS:
       case SymbolType.FUTURECONTRACTS:
         isAddSymbolEnable = false;
         isRemoveSymbolEnable = false;
         break;
       default:
         break;
    }
    const playInterval = this.state.playInterval? this.state.playInterval : 5;
    const miniListPanelBg = ThemeHelper.getThemedBrush('miniListPanelBg');
    const miniListRepeatArrowColorA = this.state.hover ? miniListPanelBg : ThemeHelper.getThemedBrush('miniListRepeatArrowColorA');
    const miniListRepeatArrowColorB = this.state.hover ? miniListPanelBg : ThemeHelper.getThemedBrush('miniListRepeatArrowColorB');
    const disableAddClass = !isAddSymbolEnable ? "add-disable" :  this.addSymbol ? "add-alert" : "add-enable";
    const disableRemoveClass = !isAddSymbolEnable ? "remove-disable" : this.removeSymbol ? "remove-alert" : "remove-enable";

    const popoverFocus = (
      <ErrorBoundary><MiniListListNamePopOver className="medium-normal" title={listName} /></ErrorBoundary>
    );
    const listLength = this.state.listItems && this.state.listItems.length;
    const isPlayButtonEnable = listLength > 0;
    let playerClass = '';
    if(isPlayButtonEnable){
      playerClass = this.state.isPlaying ? "play-n-pause icn-pause" : "play-n-pause icn-play";
    }
    else{
      playerClass = "play-n-pause icn-play button-disable";
    }

    let listState = ONeilViewStore.getState();
    let listId = listState.listId;
    let searchKeyword = listState.searchKeyword;
    if(ListStore.getState().SelectedTabKey === ListManagerTabType.Ownership) {
      listState = OwnershipViewStore.getState();
      listId = listState.listId;
      searchKeyword = listState.searchKeyword;
    }
    
    return (
      <div id="wrapLeft" className="wrapLeft">
        <div id="sidebar-wrapper-left">
          <div className="panel-container">
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="CELL">
                  <nav id="spy" >
                    <div className="mini-list-Panel">
                      {this.state.showAlert && renderAlerts}
                      {this.state.showContextMenu && <PortalContainer>
                        <MiniListContextMenu showContextMenu={this.state.showContextMenu}
                          contextMenuObj={this.contextMenuObj}
                          hideContextMenu={this.hideContextMenu}
                          clearAlertMessageInterval={this.clearAlertMessageInterval} />
                      </PortalContainer>}
                      <OverlayTrigger placement="right" overlay={popoverFocus} >
                      <div id="listNameDiv" className="list-name medium-bold" title={listName} onClick={this.toggleRecentListView}>
                          {listName}
                        </div>
                      </OverlayTrigger>
                      <RecentList ListCategoryType={this.state.ListCategoryType} searchKeyword={searchKeyword} listId={listId} listItems={this.state.listItems} listName={listName} showRecentList={this.state.toggleRecentList} onHide={this.onHideRecentList} outsideClickIgnoreClass='list-name' 
                                  recentLists = {this.state.recentLists}/>
                      {/* <RecentList searchKeyword={searchKeyword} listId={listId} listItems={this.state.listItems} listName={listName} showRecentList={this.state.toggleRecentList} onHide={this.onHideRecentList} outsideClickIgnoreClass='list-name' /> */}
                      <div id="listMiniList" className="list">
                        {(listLength) > 0 &&
                          <ErrorBoundary>
                            <VirtualList id="showscrollOnHover" miniListOnScroll={this.miniListOnScroll} ref={(ref) => { this.virtualList = ref; }} items={this.state.listItems}
                              itemHeight={24} itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                              showScrollOnHover={true}
                            />
                          </ErrorBoundary>
                        }
                      </div>
                      <div className = "miniListPlay">
                        {/* <div className="divider-line"></div> */}
                        
                        <div className="plus-n-minus">
                          {/* <span className="DayPicker"></span> */}
                          <button className={"btn btn-default " + disableAddClass} disabled = {!isAddSymbolEnable} onClick={this.AddSymbol} ><i className="icn-plus"></i></button> 
                          {/* <span className="DayPicker"></span> */}
                          <button className={"btn btn-default " + disableRemoveClass} disabled = {!isRemoveSymbolEnable} onClick={this.RemoveSymbol}><i className="icn-minus"></i></button>
                          
                        </div>
                        <span id="miniListPalyBtn" className={playerClass} onClick={this.IsPlaying} />
                        <div className="player-interval">
                          <span className="title">{playInterval} secs</span>
                          <div className="up-n-down" onMouseMove={this.handleMouseMove} onMouseLeave={this.handleMouseLeave}>
                            <svg width="8px" height="7px" className="icn-up" onClick={this.increasePlayInterval} viewBox="0 0 8 7">
                              <defs>
                              <linearGradient y2="100%" x2="0%" y1="0%" x1="0%" id="ArrowSourceBGTop">
                                <stop stopColor={miniListRepeatArrowColorA} offset="0%"/>
                                <stop stopColor={miniListRepeatArrowColorB} offset="100%"/>
                              </linearGradient>
                              </defs>
                              <path fill="url(#ArrowSourceBGTop)" d="M0,4 L4,0 L8,4Z"/>
                            </svg>
                            <svg width="8px" height="7px" viewBox="0 0 8 7" className="icn-down" onClick={this.decreasePlayInterval}>
                              <defs>
                              <linearGradient y2="100%" x2="0%" y1="0%" x1="0%" id="ArrowSourceBGBottom">
                                <stop stopColor={miniListRepeatArrowColorA} offset="0%"/>
                                <stop stopColor={miniListRepeatArrowColorB} offset="100%"/>
                              </linearGradient>
                              </defs>
                              <path fill="url(#ArrowSourceBGBottom)" d="M0,3L8,3L4,7Z"/>
                            </svg>
                          </div>
                        </div>                         
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div id="menu-toggle-left" onClick={this.props.toggleLeft}>
            <div className=" CELL _25PX SideBar leftBar">
              <div className="dot-slide-bar"></div>
              <div className="dot-slide-bar1"></div>
            </div>
            <div className="COLS">

            </div>
          </div>
        </div>
      </div>
    );
  }
}
