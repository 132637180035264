import Serializable from "Serializable";
export default class ExternalDataSettings extends Serializable {
    constructor() {
        super();
        this.IsAvailable = true;
        this.IsVisibleInPriceMenu = false;
        this.IsVisibleInIndicatorMenu = false;
    }

    // getSerizableObject(obj) {
    //     return Object.assign(new Serializable, { ...obj });
    // }

    onSeralisedInfoRecieved(serializedValue) {
        this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : true;
        this.IsVisibleInPriceMenu = serializedValue.IsVisibleInPriceMenu ? serializedValue.IsVisibleInPriceMenu : false;
        this.IsVisibleInIndicatorMenu = serializedValue.IsVisibleInIndicatorMenu ? serializedValue.IsVisibleInIndicatorMenu : false;
    }

    getDataObject() {
        return {
            IsAvailable: this.IsAvailable,
            IsVisibleInPriceMenu: this.IsVisibleInPriceMenu,
            IsVisibleInIndicatorMenu: this.IsVisibleInIndicatorMenu,
        }
    }

    getDefaultExternalDataSetting() {
        this.IsAvailable = true;
        this.IsVisibleInPriceMenu = false;
        this.IsVisibleInIndicatorMenu = false;
        return this;
    }

    getObjectMapKey() {
        return "ExternalDataSettings";
    }
}
