import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class AnnotationsChangeLayerRequest extends BaseServiceRequest {
    constructor(layerId, annotations) {
        super();
        super.addRequestUri("annotations.pb");
        super.addRequestParameter("lid", layerId.toString());
        super.setContent(annotations.encode());
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "Response";
    }

}
