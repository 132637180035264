import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class AnnotationsDeleteRequest extends BaseServiceRequest {
    constructor(msid,annotIds) {
        super();
        super.addRequestUri("annotations.pb");
        const listProto = BaseServiceApi.rayData["AnnotationListData"];
        const listWrapProto = new listProto();

        let createAnnotData = undefined;
        const annotProto = BaseServiceApi.rayData["AnnotationData"];

        annotIds.map((id) => {
            createAnnotData = new annotProto();
            createAnnotData.annotationID = id;
            createAnnotData.msid = msid;
            listWrapProto.annotationData.push(createAnnotData);
        });
        
        const reqcontent = listWrapProto.encode();
        super.setContent(reqcontent);
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "Response";
    }

}
