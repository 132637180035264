import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetDgHoldingsReportHeaderRequest extends BaseServiceRequest {
    constructor(osid, symbol, currentTab = 0) {
        super();
        super.addRequestUri("holdingsheadertext.pb");//holdingsheadertext.pb
        super.addRequestParameter("fundsosid", osid ? osid.toString() : "13717");
        super.addRequestParameter("symbol", symbol ? symbol.toString() : "ISORX");
        super.addRequestParameter("sector", currentTab ? currentTab.toString() : "0");
        

    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "HoldingReportHeaderResponseExt";
    }
}

export default GetDgHoldingsReportHeaderRequest;
