import StringUtil from "StringUtil";
import ProdServerURL from "ProdServerURL";
import ProtoBuf from 'protobufjs';
import ByteBuffer from 'bytebuffer';
import ConsoleActions from "Actions/ConsoleActions";
import parser from '../Utils/fast-xml-parser.min.js';
import LocalizationStore from "../Stores/Localization/LocalizationStore.js";
import GraphType from "GraphType";
//const Promise = window.Promise || require("bluebird");
let _requestCount = {};
let _builder = null;

class ApiWCF {
    constructor() {
        const serverName = process.env.SERVER_NAME2;
        const ssl = process.env.SERVER_SSL2;
        this.serverName = !StringUtil.isEmpty(serverName) ? serverName.trim() : "";
        this.serverSSL = !StringUtil.isEmpty(ssl) ? ssl.trim() : "https";
        this.builder = this.getBuilder();
        this.rayData = this.builder.build("RayChartCommon");
    }

    getProtocol() {
        const env = process.env.BRANCH_ENV;
        if (env === undefined) {
            return "http";
        }
        else {
            return this.serverSSL;
        }
    }

    getRequestURL(request) {
        const protocol = this.getProtocol();
        const subsytem = this.getSubSystemPath(request.getSubSystem());
        const servername = this.getServerName();// "localhost";//
        const app_name = "PanarayWeb.svc";
        const uri = request.getUri();
        return StringUtil.format("{0}://{1}/{2}/{3}/{4}", [protocol, servername, subsytem, app_name, uri]);
    }

    getDomain() {
        return StringUtil.format("{0}://{1}", [this.serverSSL, this.getServerName()]);
    }

    getBuilder(){  
        if(_builder === null){
            _builder =  ProtoBuf.loadProtoFile("wcfproto.proto");
        }
        return _builder;
    }

    getNewRequestId(url) {
        if (_requestCount.hasOwnProperty(url)) {
            _requestCount[url]++;
        }
        else {
            _requestCount[url] = 0;
        }
        return `${url}:${_requestCount[url]}`;
    }

    getMomentZoneIDFromServer(countryCode, periodicity) {
        const serverName = this.getServerName();
        if (serverName === "wonalipansstg2" &&
            countryCode === 1 &&
            periodicity === GraphType.Intraday) {
            return "Hongkong";
        }
        return "US/Pacific";
    }

    getUrl() {
        const protocol = this.getProtocol();
        const subsytem = "RayService5";
        const servername = this.getServerName();// "localhost";//
        //TODO : Need to remove all isPrintServiceCall checks and has to go to the same IIS server
        const app_name = "DbAppService.svc";
        return StringUtil.format("{0}://{1}/{2}/{3}", [protocol, servername, subsytem, app_name]);
    }

    generateCurrencyImageWebRequest(request) {
        const protocol = this.getProtocol();
        const serverName = this.getServerName(false);
        const app_name = "RayService5";
        const uri = request.getUri();
        const parameterString = request.getParameterString();
        return StringUtil.format("{0}://{1}/{2}/{3}?{4}", [protocol, serverName, app_name, uri, parameterString]);
    }

    getServerName() {
        let serverName;

        if (this.serverName !== "") {
            return this.serverName;
        }

        if (process.env.BRANCH_ENV) {
            let env = process.env.BRANCH_ENV;
            switch (env && env.toUpperCase()) {
                case "DEV":
                {
                    serverName = "iis.dev.panaray.com";
                    break;
                }
                case "STAGE":
                {
                    serverName = "iis.stage.panaray.com";
                    break;
                }
                case "PROD":
                {
                    serverName = this.getProdServerURL();
                    break;
                }
                default:
                    break;
            }
        }
        return serverName == null ? serverName = "iis.stage.panaray.com" : serverName;
    }

    getSoapRequest(request) {
        const soapRequest = `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
                                <s:Header>
                                    ${request.headers.map((header) => {
                const key = Object.keys(header)[0];
                return '<' + key + '>' + header[key] + '</' + key + '>';
            })}
                                </s:Header>
                                <s:Body>
                                    <${request.getServiceMethod()} xmlns="http://tempuri.org/">
                                        ${request.params.map((param) => {
                const key = Object.keys(param)[0];
                return '<' + key + '>' + param[key] + '</' + key + '>';
            })}
                                    </${request.getServiceMethod()}>
                                </s:Body>
                             </s:Envelope>`;
        return soapRequest.replace(new RegExp(">,<", "g"), "><");
    }

    getSoapRequestWithoutHeader(request) {
        const soapRequest = `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
                                <s:Body><${request.getServiceMethod()} xmlns="http://tempuri.org/">
                                ${request.params.map((param) => {
                const key = Object.keys(param)[0];
                if (key === 'requests') return '<' + key + ' xmlns:i="http://www.w3.org/2001/XMLSchema-instance">' + param[key] + '</' + key + '>';
                return '<' + key + '>' + param[key] + '</' + key + '>';
            })}</${request.getServiceMethod()}></s:Body>
                             </s:Envelope>`;
        return soapRequest.replace(new RegExp(">,<", "g"), "><");
    }

    logAPICalls(uriName, xhr) {
        const serviceCallList = ['getpricepaneldata.pb', 'getsummaryblockdata.pb', 'findgetblocks2.pb', 'GetComparisonDataForSymbols', 'globalcounts2.pb', 'benchmarkdata2.pb', 'quaddata.pb',
                'explorer.pb', 'esbasic.pb', 'FindInstrumentDB2', 'liqfilters.pb', 'GetLatestTradingDate', 'geography.pb', 'groupsmain.pb', 'liqfilters.pb']

        for (var i=0; i<serviceCallList.length; i++) {
        if (serviceCallList[i] === uriName) {
            console.log("PRINT:-------CALL: ",uriName,"--------URL: ", xhr.responseURL, "----------- STATUS:",xhr.status)
        }
        }
    }

    processServiceCallNew(request) {
        let self = this;
        return new Promise((resolve, reject) => {
            const url = this.getUrl();
            //const xsr = this.getSoapRequest(request);
            const xsr = request.headers && request.headers.length > 0 ? this.getSoapRequest(request) : this.getSoapRequestWithoutHeader(request);
            const messageName = request.getMessageName();
            const responseProto = this.rayData[messageName];
            const uriName = request.getServiceMethod();
            
            const serverName = this.getServerName();
            const xhr = new XMLHttpRequest();
            xhr.open("POST", url, true);
            xhr.setRequestHeader("Content-Type", "text/xml; charset=utf-8");
            xhr.setRequestHeader("SOAPAction", `"http://tempuri.org/IDBAppService/${request.getServiceMethod()}"`);
            xhr.onload = function () {
                self.logAPICalls(uriName, xhr)
                if (this.status >= 200 && this.status < 300) {
                    const regex = this.response.match(/^.*\<proto\>(.*)\<\/proto\>.*$/);
                    if (regex !== null) {
                        const data = regex[1];
                        const buffer = ByteBuffer.fromBase64(data); // Uint8Array.from(atob(data), c => c.charCodeAt(0));
                        const result = responseProto.decode(buffer);
                        resolve(result);
                    }
                    else {
                        const result = parser.parse(this.response, { parseNodeValue: false });
                        resolve(findObjectByKey(result, messageName));

                        // reject({
                        //     status: this.status,
                        //     statusText: xhr.statusText
                        // });
                        // self.exceptionHandle((xhr.statusText + JSON.stringify(request)), serverName, uriName);
                    }
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                    self.exceptionHandle((xhr.statusText + JSON.stringify(request)), serverName, uriName);
                }
            };
            //Remove unused function with console.log
            //xhr.onreadystatechange = function () {
            //    console.log(this.readyState)  
            //};
            xhr.onerror = function () {
                self.logAPICalls(uriName, xhr)
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
                self.exceptionHandle((xhr.statusText + JSON.stringify(request)), serverName, uriName);
            };
            xhr.send(xsr);
        });
    }

    exceptionHandle(statusText, serverName, uriName) {
        let errorObject = {
            errorData: statusText,
            server: serverName,
            serviceUriName: uriName,
            isWCFService: true,
            isTomcatService: false,
            isClientSideError: false,
        }
        if (this.status == 0) {
            ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_01", "Network connection is not available."));
        }
        else if (this.status == 500) {
            ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_15", "Internal server error."));
        }
        else if (this.status == 504) {
            ConsoleActions.exceptionHandle(null, false, LocalizationStore.getTranslatedData("er_report_13", "The service has reached its max timeout of 3600 seconds."));
        }
        else {
            ConsoleActions.exceptionHandle(errorObject);
        }
    }

    ProcessServiceCall(request) {
        const self = this;
        /* eslint-disable */
        return new Promise(function (resolve, reject) {
            /* eslint-enable */
            //    return new Promise((resolve, reject) => {
            const uriName = request.getRequestUri();
            const serverName = this.getServerName();
            const url = this.getRequestURL(request);
            const method = request.getMethod();
            const xhr = new XMLHttpRequest();
            xhr.open(method, url, true);
            //  xhr.withCredentials = true;
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve(JSON.parse(xhr.response));
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                    self.exceptionHandle((xhr.statusText + JSON.stringify(request)), serverName, uriName);
                }
            };

            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
                self.exceptionHandle((xhr.statusText + JSON.stringify(request)), serverName, uriName);
            };
            xhr.send();
        }.bind(this));
    }

    getSubSystemPath(subsystemtype) {
        let type;
        switch (subsystemtype) {

            case "GRAPH":
                type = "graphservice";
                break;

            case "FUNDAMENTAL":
                type = "f";
                break;

            case "ALERTS":
                type = "a";
                break;
            case "NOTIFICATION":
                type = "n";
                break;
            case "SHARE":
                type = "shr";
                break;
            default:
                break;
        }

        return type;
    }

    getProdServerURL() {
        let prodServerURL = ProdServerURL[window.location.hostname];
        return `iis.${StringUtil.isEmpty(prodServerURL) ? "services.panaray.com": prodServerURL}`;
    }

}

const apiWCF = new ApiWCF();
export default apiWCF;

function findObjectByKey(obj, key) {
    let result = null;
    for (let k in obj) {
        if (k === key) {
            result = obj[key];
            break;
        }
        else if (obj[k] instanceof Object || obj[k] instanceof Array) {
            let r = findObjectByKey(obj[k], key);
            if (r) return r;
        }
    }
    return result;
}