import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { map, each } from "underscore";
import SummaryStatStore from "./SummaryStatStore";
import { GridConstants } from "GridConstants";
import SummaryStateBody from "./SummaryStatBody.jsx";
import SummaryBarGraph from "./SummaryBarGraph.jsx";
import ONeilViewStore from "ONeilViewStore";
import LocalizationStore from "LocalizationStore";
import ListExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore";

export default class SummaryStat extends Component {
    constructor(props) {
        super(props);
        const storeState = SummaryStatStore.getState();
        this.state = {
            height: this.props.maxHeightOfSummaryState >= this.props.height ? this.props.height : this.props.maxHeightOfSummaryState, storeState: storeState
        }

        this.summaryStatStoreChanged = this.summaryStatStoreChanged.bind(this);
        this.openCloseSummaryStat = this.openCloseSummaryStat.bind(this);
        this.getSummaryFrozenData = this.getSummaryFrozenData.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.loadHistData = this.loadHistData.bind(this);
        this.applyColumnFilter = this.applyColumnFilter.bind(this);

        this.highlightBottomBorder = this.highlightBottomBorder.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.subscribeEvents = this.subscribeEvents.bind(this);
        this.unsubscribeEvents = this.unsubscribeEvents.bind(this);
        this.onResize = this.onResize.bind(this);
        this.getColumnData = this.getColumnData.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        SummaryStatStore.addChangeListener(this.summaryStatStoreChanged);
        this.hGridScrolElm = document.getElementById('gridHorizontalScroll');
        setTimeout(() => {
            this.sStat && this.props.adjustHeight(this.sStat.getBoundingClientRect());
        }, 0)
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        SummaryStatStore.removeChangeListener(this.summaryStatStoreChanged);
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        nextProps.height != this.props.height ?
            this.setState({
                height: nextProps.maxHeightOfSummaryState >= nextProps.height ? nextProps.height : nextProps.maxHeightOfSummaryState,
                storeState: SummaryStatStore.getState()
            })
            :
            this.setState({
                storeState: SummaryStatStore.getState()
            });
    }

    componentDidUpdate() {
        if (this.nonFrozenStatsArea && this.nonFrozenStatsArea.scrollLeft !== this.hGridScrolElm.scrollLeft && this.props.summaryStatOpen) {
            this.nonFrozenStatsArea.scrollLeft = this.hGridScrolElm.scrollLeft;
        }  
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (nextProps.height <= 0 || nextState.height <= 0) return false;
    //     return true;
    // }

    summaryStatStoreChanged () {
        let stateData = SummaryStatStore.getState();
        switch (stateData.SummaryStatCurrentAction) {
            case GridConstants.ActionTypes.LOAD_HIST_DATA:
            case GridConstants.ActionTypes.REORDER_SUMMMARY_STAT_ROW:
                this.setState(stateData);
                SummaryStatStore.clearSummaryStatCurrentAction();
                break;
            default:
                break;
        }
    }

    handleClick(e) {
        if (this.nonFrozenStatsArea) {
            const nonFrozenStatsArea = this.nonFrozenStatsArea.getBoundingClientRect()
            if (e.x <= nonFrozenStatsArea.right && e.x >= nonFrozenStatsArea.left && e.y <= nonFrozenStatsArea.bottom && e.y >= nonFrozenStatsArea.top) {
                document.addEventListener('keydown', this.handleKeyPress, false);
            } else {
                document.removeEventListener('keydown', this.handleKeyPress, false);
            }
        } else {
            document.removeEventListener('keydown', this.handleKeyPress, false);
        }
    }

    handleKeyPress(e) {
        if (e.keyCode === 37 || e.keyCode === 39) {
            e.returnValue = false;
        }
    }

    openCloseSummaryStat () {
        this.props.summaryStatToggle();
    }

    getSummaryFrozenData () {
        let headers = SummaryStatStore.headers;
        if (headers) {
            return map(headers, (item) => {
                let itemVal = null;
                switch (item) {
                    case 'Count':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_Count', 'Count');
                        break;
                    case 'Count (Not Blank)':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_CouNotBlk', 'Count (Not Blank)');
                        break;
                    case 'Average':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_Average', 'Average');
                        break;
                    case 'Std. Dev':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_StdDev', 'Std. Dev');
                        break;
                    case 'Mode':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_Mode', 'Mode');
                        break;
                    case 'Maximum':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_Max', 'Maximum');
                        break;
                    case '90th %':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_90', '90th %');
                        break;
                    case '75th %':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_75', '75th %');
                        break;
                    case '50th % (Median)':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_50', '50th % (Median)');
                        break;
                    case '25th %':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_25', '25th %');
                        break;
                    case '10th %':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_10', '10th %');
                        break;
                    case 'Minimum':
                        itemVal = LocalizationStore.getTranslatedData('LM_SLV_Min', 'Minimum');
                        break;
                    default:
                        break;
                }
                return {
                    dataRow: [{ displayValue: itemVal }]
                }
            })
        }
        else {
            return null;
        }
    }

    handleScroll (scroll) {
        if (this.props.summaryStatOpen)
            this.nonFrozenStatsArea.scrollLeft = scroll;
    }

    loadHistData (col) {
        SummaryStatStore.loadHistData(col);
    }

    applyColumnFilter (minVal, maxVal) {
        const addlRequestData = SummaryStatStore.getColumnFilterData(minVal, maxVal);
        addlRequestData && this.props.applyColumnFilter(addlRequestData);
        return;
    }
    highlightBottomBorder (rowRef, highlight = false) {
        try {
            findDOMNode(this.frozenStat.refs[rowRef]).style.boxShadow = (highlight ? "inset 0 -1px 0 grey" : "");
            findDOMNode(this.nonFrozenStat.refs[rowRef]).style.boxShadow = (highlight ? "inset 0 -1px 0 grey" : "");
        }
        catch (e) {
            console.log(e);
        }
    }

    handleMouseDown (e) {
        const startY = e.clientY;
        const height = this.listsummarystatdiv.clientHeight;
        document.body.classList.add('selectTextDisble');
        this.setState((prevState) => ({
            isResizing: true,
            startHeight: height,
            height: prevState.height,
            startY: startY
        }));

        this.subscribeEvents();
        e.preventDefault();
        e.stopPropagation();
    }

    handleMouseMove (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.isResizing) {
            let newHeight = (this.state.startHeight - (e.clientY - this.state.startY));
            this.props.maxHeightOfSummaryState >= newHeight ? this.onResize(newHeight) : this.onResize(this.props.maxHeightOfSummaryState);
        }
    }

    handleMouseUp (e) {
        document.body.classList.remove('selectTextDisble');
        let newHeight = (this.state.startHeight - (e.clientY - this.state.startY));
        this.props.maxHeightOfSummaryState >= newHeight ? this.onResize(newHeight) : this.onResize(this.props.maxHeightOfSummaryState);
        this.setState({
            isResizing: false
        });
        setTimeout(() => {
            this.sStat && this.props.adjustHeight(this.sStat.getBoundingClientRect())
            ONeilViewStore.updateSummaryStateSettings()
            this.unsubscribeEvents()
        }, 0);

    }

    subscribeEvents () {
        document.addEventListener("mousemove", this.handleMouseMove, false);
        document.addEventListener("mouseup", this.handleMouseUp, false);
    }

    unsubscribeEvents () {
        document.removeEventListener("mousemove", this.handleMouseMove, false);
        document.removeEventListener("mouseup", this.handleMouseUp, false);
    }

    onResize (height) {
        if (this.props.maxHeightOfSummaryState >= height && height > 0) {
            this.props.adjustHeight({ height: height }, true);
            setTimeout(() => {
                SummaryStatStore.state.summaryStatHeight = height;
            }, 0);
        }
    }

    getColumnData (curColumn) {
        let columnData = this.props.allColumns;
        let curColumnData = {};

        each(columnData, (colData) => {
            if (colData.displayName === curColumn) {
                curColumnData['colName'] = curColumn;
                curColumnData['dataItemId'] = colData.dataItemId;
            }
        }, this);
        return curColumnData;
    }

    render() {
        if (!this.state) return null;
        const frozenData = this.getSummaryFrozenData();
        const { activeColName, xAxisLabels, summaryDistBars, summaryListData } = this.state.storeState;
        const frozenViewportSize = 140;
        const histColNameData = this.getColumnData(activeColName);
        const isNotImportedList = ListExplorerStore.isNotImportedList();
        const isActive = isNotImportedList ? "toggle-icon-list icn-toggle-icon-list invalid" : this.props.summaryStatOpen ? "toggle-icon-list icn-toggle-icon-list active" : "toggle-icon-list icn-toggle-icon-list";
        return (
            <div className="list-summary-block">
                <div ref={(ref) => this.sStat = ref} style={{ maxHeight: `${this.props.maxHeightOfSummaryState}px`, height: `${this.state.height}px` }}>
                    <div className="list-summary-inner" ref={(d) => this.listsummarystatdiv = d} style={{ maxHeight: `${this.props.maxHeightOfSummaryState}px`, height: `${this.state.height}px` }}>
                        <a className={isActive} onClick={!isNotImportedList ? this.openCloseSummaryStat : null}>
                            <span className="list-summary-block-title">{LocalizationStore.getTranslatedData('LM_SumSta', 'Summary Stats')}</span>
                        </a>
                        {this.props.summaryStatOpen && <div className="list-resize" onMouseDown={this.handleMouseDown}><span className="icon-handal-h"></span></div>}
                        { this.props.summaryStatOpen &&
                            <div className="data-block-summary">
                                <div className="price-graph" style={{ width: `${this.props.frozenAreaWidth}px` }}>
                                    <div className="graph-area" ref={(d) => this.graphArea = d} style={{ width: `${this.props.frozenAreaWidth - frozenViewportSize - 2}px` }}>
                                        {/*   <img className="img-responsive-graph" src="Asset/images/graph-img.png"/>
                                         <img className="img-responsive-graph" src="Asset/images/list-bar-summary-block.svg"/>*/}
                                        <table className="center-in-table">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="chart-wrapper">
                                                            {this.props.frozenAreaWidth - frozenViewportSize - 1 > 280 && this.state.height > 90 ?
                                                                <SummaryBarGraph
                                                                    graphArea={this.graphArea}
                                                                    xAxisLabels={xAxisLabels}
                                                                    isResizing={this.state.isResizing}
                                                                    histData={summaryDistBars}
                                                                    histColName={histColNameData}
                                                                    applyColumnFilter={this.applyColumnFilter}
                                                                    height={this.state.height} />
                                                                :
                                                                null}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="graph-value-area">
                                        <div className="">
                                            {!frozenData || !this.props.rowHeight ? null :
                                                <SummaryStateBody
                                                    textSize={this.props.textSize}
                                                    rowHeight={this.props.rowHeight}
                                                    viewportWidth={frozenViewportSize}
                                                    frozenAreaWidth={this.props.frozenAreaWidth}
                                                    rowData={frozenData}
                                                    ref={(r) => this.frozenStat = r}
                                                    nonFrozen={false}
                                                    allColumns={this.props.allColumns}
                                                    getMouseOverColumnIndex={this.props.getMouseOverColumnIndex}
                                                    highlightBottomBorder={this.highlightBottomBorder}
                                                    isResizing={this.state.isResizing}
                                                    tooltip = { this.props.tooltip }
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="list-data-summary" style={{ width: `${this.props.nonFrozenAreaWidth - 1}px` }}>
                                    <div className="table-responsive" ref={(div) => this.nonFrozenStatsArea = div}>
                                        {
                                            (!this.props.rowHeight || !this.props.nonFrozenColumns || !summaryListData) ? null :
                                                <SummaryStateBody
                                                    textSize={this.props.textSize}
                                                    rowHeight={this.props.rowHeight}
                                                    viewportWidth={this.props.viewportWidth}
                                                    frozenAreaWidth={this.props.frozenAreaWidth}
                                                    rowData={summaryListData}
                                                    ref={(r) => this.nonFrozenStat = r}
                                                    columns={this.props.nonFrozenColumns}
                                                    allColumns={this.props.allColumns}
                                                    nonFrozen={true}
                                                    getMouseOverColumnIndex={this.props.getMouseOverColumnIndex}
                                                    loadHistData={this.loadHistData}
                                                    highlightBottomBorder={this.highlightBottomBorder}
                                                    isResizing={this.state.isResizing}
                                                    tooltip = { this.props.tooltip }
                                                />
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}


