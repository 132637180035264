import React, { Component } from "react";
import MenuCommonDialogAction from "../../Actions/MenuCommonDialogActions";
import ReportedEarningsStore from "../../Stores/Preference/ReportedEarningsStore.js";
import LocalizationStore from 'LocalizationStore';

export default class ReportedEarningsContent extends Component {
    constructor(props) {
        super(props);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.state = this.getMyState();
    }

    getMyState(){
        return ReportedEarningsStore.getState();
    }

    handleStateChange(){
        this.setState(this.getMyState());
    }

    componentDidMount() {
        ReportedEarningsStore.addChangeListener(this.handleStateChange);
    }

    componentWillUnmount() {
        ReportedEarningsStore.removeChangeListener(this.handleStateChange);
    }

    handleRevenueChange(e){
        let value = parseInt(e.target.value);
        MenuCommonDialogAction.changeReportedRevenue(value);
    }

    handleEarningsChange(e){
        let value = parseInt(e.target.value);
        MenuCommonDialogAction.changeReportedEarnings(value);
    }

    render() {
        return (
            <div className="revenue-n-earnings">
                <p>{LocalizationStore.getTranslatedData('set_pre_re_hdr', "Select the data provider you prefer for both reported revenue and earnings.  All estimate data provided by FactSet.")}</p>
                <div className="revenue-earn">
                    <div className="report-revenue">
                        <h6>{LocalizationStore.getTranslatedData('set_pre_re_rr', "Reported Revenue")}</h6>
                        <div className="report-options">
                            <span className="option">
                                <input id="radiorevenue1" type="radio" value={1} name="revenue" onChange={this.handleRevenueChange} checked={this.state.revenue == 1}/>
                                <label htmlFor="radiorevenue1">{LocalizationStore.getTranslatedData('set_pre_re_won', "William O'Neil")}</label>
                            </span>
                            <span className="option">
                                <input id="radiorevenue2" type="radio" value={0} name="revenue" onChange={this.handleRevenueChange} checked={this.state.revenue == 0}/>
                                <label htmlFor="radiorevenue2">{LocalizationStore.getTranslatedData('set_pre_re_tr', "FactSet")}</label>
                            </span>
                        </div>
                    </div>
                    <div className="report-earn">
                        <h6>{LocalizationStore.getTranslatedData('set_pre_re_re', "Reported Earnings")}</h6>
                        <div className="report-options">
                            <span className="option">
                                <input id="radioearnings1" type="radio" value={1} name="earnings" onChange={this.handleEarningsChange} checked={this.state.earnings == 1}/>
                                <label htmlFor="radioearnings1">{LocalizationStore.getTranslatedData('set_pre_re_won', "William O'Neil")}</label>
                            </span>
                            <span className="option">
                                <input id="radioearnings2" type="radio" value={0} name="earnings" onChange={this.handleEarningsChange} checked={this.state.earnings == 0}/>
                                <label htmlFor="radioearnings2">{LocalizationStore.getTranslatedData('set_pre_re_tr', "FactSet")}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}