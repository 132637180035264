import { RayAnnotationType } from 'Constants/EAnnotationType';

export class ChannelLineModelInfo {
    constructor() {
        this.AnnotationType = RayAnnotationType.PARALLEL_LINES_AnnotationType;

        // Set all defaults
        this.ObjectBrushHex = "#FF0066CC";
        //this.OriginalObjectBrushHex = ObjectBrushHex;
        //this.AnnotationDashType = AnnotationDashes.Solid;
        this.LineWidth = 2;
        this.Opacity = 100;

        this.StartArrowChecked = false;
        this.EndArrowChecked = false;
        this.StartExtendChecked = false;
        this.EndExtendChecked = false;

        this.IsTemporary = false;
        this.IsSnappingEnabled = false;
        this.IsAlert = false;
    }
}
