import DocUtil from "./DocUtil.js";
import DayOfWeek from "DayOfWeek";
import GraphType from "GraphType";

class StockMarketUtil {
    GetMBeginDate(cDate) {
        var year = cDate.getFullYear();
        var month = cDate.getMonth();

        cDate = new Date(year, month, 1);
        if (cDate.getDay() == DayOfWeek.Saturday)
            cDate.setDate(cDate.getDate() + 2);
        if (cDate.getDay() == DayOfWeek.Sunday)
            cDate.setDate(cDate.getDate() + 1);
        return cDate;
    }

    GetMEndDate(cDate) {
        var year = cDate.getFullYear();
        var month = cDate.getMonth() + 1;
        if (month > 12) {
            month = 0;
            year++;
        }

        cDate = new Date(year, month, 1);
        cDate.setDate(cDate.getDate() - 1);
        if (cDate.getDay() == DayOfWeek.Saturday)
            cDate.setDate(cDate.getDate() - 1);
        if (cDate.getDay() == DayOfWeek.Sunday)
            cDate.setDate(cDate.getDate() - 2);
        return cDate;
    }

    GetQBeginDate(cDate) {
        var year = cDate.getFullYear();
        var month = cDate.getMonth() + 1;
        var qtr = this.truncate((month - 1) / 3.0);
        month = qtr == 0 ? 1 : qtr == 1 ? 4 : qtr == 2 ? 7 : 10;


        cDate = new Date(year, month - 1, 1);
        if (cDate.getDay() == DayOfWeek.Saturday)
            cDate.setDate(cDate.getDate() + 2);
        if (cDate.getDay() == DayOfWeek.Sunday)
            cDate.setDate(cDate.getDate() + 1);
        return cDate;
    }

    GetQEndDate(cDate) {
        var year = cDate.getFullYear();
        var aMonth = cDate.getMonth() + 1;
        var month = aMonth + (2 - (aMonth - 1) % 3) + 1;
        if (month > 12) {
            month = 1;
            year++;
        }

        cDate = new Date(year, month - 1, 1);
        cDate.setDate(cDate.getDate() - 1);
        if (cDate.getDay() == DayOfWeek.Saturday)
            cDate.setDate(cDate.getDate() - 1);
        if (cDate.getDay() == DayOfWeek.Sunday)
            cDate.setDate(cDate.getDate() - 2);
        return cDate;
    }

    GetABeginDate(cDate) {
        var year = cDate.getFullYear();
        var month = 0;

        cDate = new Date(year, month, 1);
        if (cDate.getDay() == DayOfWeek.Saturday)
            cDate.setDate(cDate.getDate() + 2);
        if (cDate.getDay() == DayOfWeek.Sunday)
            cDate.setDate(cDate.getDate() + 1);
        return cDate;
    }

    GetAEndDate(cDate) {
        var year = cDate.getFullYear() + 1;
        var month = 0;

        cDate = new Date(year, month, 1);
        cDate.setDate(cDate.getDate() - 1);
        if (cDate.getDay() == DayOfWeek.Saturday)
            cDate.setDate(cDate.getDate() - 1);
        if (cDate.getDay() == DayOfWeek.Sunday)
            cDate.setDate(cDate.getDate() - 2);
        return cDate;
    }
    getMinutes(start, end) {
        var diffMs = (start - end);
        return Math.round(diffMs / 60000); // minutes
    }

    /**
     * @return {number}
     */
    GetPercentageIntoTradingDay(tradeDate, tradeOpen, tradeClose) {
        var dPercentIntoTradingDay = 1.0;

        if(tradeDate && tradeOpen && tradeClose) {
        if (tradeDate >= tradeClose || tradeDate <= tradeOpen)
            return dPercentIntoTradingDay;

        var getDiff1 = this.getMinutes(tradeDate, tradeOpen);
        var getDiff2 = this.getMinutes(tradeClose, tradeOpen);

        let totalMinutes = getDiff2;
        let totalToday = Math.max(0, getDiff1);

        if (totalMinutes != 0)
            dPercentIntoTradingDay = totalToday / totalMinutes;

        if (dPercentIntoTradingDay < 0 || totalMinutes == 0) {
            // Day hasn't started... return 1.
            dPercentIntoTradingDay = 1.0;
        }
        else if (dPercentIntoTradingDay > 1.0) {
            // After close... return 1.
            dPercentIntoTradingDay = 1.0;
        }
        return dPercentIntoTradingDay;
    }
}

    /**
     * @return {number}
     */
    GetPercentageIntoTradingWeek(tradeDate, tradeOpen, tradeClose) {
        let dPercentIntoTradingDay = 1.0;

        if(tradeDate && tradeOpen && tradeClose) {
        var getDiff1 = this.getMinutes(tradeDate, tradeOpen);
        var getDiff2 = this.getMinutes(tradeClose, tradeOpen);

        if (tradeDate > tradeClose) {
            getDiff1 = getDiff2;
        }

        let dTotalTradingTimeToday = getDiff2;
        let totalToday = Math.max(0, getDiff1);

        var dow = tradeDate.getDay();
        var dTradingSoFar = tradeDate.getDay() == DayOfWeek.Monday ? 0 : dTotalTradingTimeToday * (dow - 1);
        let dTotalTradingTimeWeekly = dTotalTradingTimeToday * 5;

        dTradingSoFar += totalToday;

        dPercentIntoTradingDay = dTradingSoFar / dTotalTradingTimeWeekly;

        if (dPercentIntoTradingDay < 0) {
            // Day hasn't started... return 1.
            dPercentIntoTradingDay = 1.0;
        }
        else if (dPercentIntoTradingDay > 1.0) {
            // After close... return 1.
            dPercentIntoTradingDay = 1.0;
        }
        return dPercentIntoTradingDay;
    }
}

    /**
     * @return {number}
     */
    GetPercentageIntoTradingMonth(tradeDate, tradeOpen, tradeClose) {
        var dPercentIntoTradingDay = 1.0;

        if(tradeDate && tradeOpen && tradeClose) {
        var getDiff1 = tradeDate.getTime() - tradeOpen.getTime();
        var getDiff2 = tradeClose.getTime() - tradeOpen.getTime();

        if (tradeDate > tradeClose) {
            getDiff1 = getDiff2;
        }

        let dTotalTradingTimeToday = getDiff2 / (1000 * 60);
        let totalToday = Math.max(0, (getDiff1 / (1000 * 60)));

        var monthStart = this.GetMBeginDate(tradeDate);
        var monthEnd = this.GetMEndDate(tradeDate);

        //var monthStart = new DateTime(tradeDate.Year,tradeDate.Month,1);
        //var monthEnd = monthStart.AddMonths(1).AddDays(-1);


        var startTdc = DocUtil.ConvertDateTimeToTdc(monthStart);
        //if (monthEnd.DayOfWeek == DayOfWeek.Sunday)
        //    monthEnd = monthEnd.AddDays(-2);
        //if (monthEnd.DayOfWeek == DayOfWeek.Saturday)
        //    monthEnd = monthEnd.AddDays(-1);

        var endTdc = DocUtil.ConvertDateTimeToTdc(monthEnd);
        var curTdc = DocUtil.ConvertDateTimeToTdc(tradeDate);
    
        var tradingDays = (endTdc - startTdc) + 1;

        var dTradingSoFar = curTdc < startTdc ? 0 : dTotalTradingTimeToday * (curTdc - startTdc);

        let dTotalTradingTimeMonthly = dTotalTradingTimeToday * tradingDays;

        dTradingSoFar += totalToday;

        dPercentIntoTradingDay = dTradingSoFar / dTotalTradingTimeMonthly;

        if (dPercentIntoTradingDay < 0) {
            //Day hasn't started... return 1.
            dPercentIntoTradingDay = 1.0;
        }
        else if (dPercentIntoTradingDay > 1.0) {
            //After close... return 1.
            dPercentIntoTradingDay = 1.0;
        }

        return dPercentIntoTradingDay;
    }
}

    /**
     * @return {number}
     */
    GetPercentageIntoTradingQuarter(tradeDate, tradeOpen, tradeClose) {
        var dPercentIntoTradingDay = 1.0;
        if(tradeDate && tradeOpen && tradeClose) {
        var getDiff1 = tradeDate.getTime() - tradeOpen.getTime();
        var getDiff2 = tradeClose.getTime() - tradeOpen.getTime();

        if (tradeDate > tradeClose) {
            getDiff1 = getDiff2;
        }

        let dTotalTradingTimeToday = getDiff2 / (1000 * 60);
        let totalToday = Math.max(0, (getDiff1 / (1000 * 60)));

        var monthStart = this.GetQBeginDate(tradeDate);
        var monthEnd = this.GetQEndDate(tradeDate);
        //var monthStart = new DateTime(tradeDate.Year, tradeDate.Month, 1);
        //var monthEnd = monthStart.AddMonths(1).AddDays(-1);

        var startTdc = DocUtil.ConvertDateTimeToTdc(monthStart);
        //if (monthEnd.DayOfWeek == DayOfWeek.Sunday)
        //    monthEnd = monthEnd.AddDays(-2);
        //if (monthEnd.DayOfWeek == DayOfWeek.Saturday)
        //    monthEnd = monthEnd.AddDays(-1);

        var endTdc = DocUtil.ConvertDateTimeToTdc(monthEnd);
        var curTdc = DocUtil.ConvertDateTimeToTdc(tradeDate);

        var tradingDays = (endTdc - startTdc) + 1;

        var dTradingSoFar = curTdc < startTdc ? 0 : dTotalTradingTimeToday * (curTdc - startTdc);

        let dTotalTradingTimeMonthly = dTotalTradingTimeToday * tradingDays;

        dTradingSoFar += totalToday;

        dPercentIntoTradingDay = dTradingSoFar / dTotalTradingTimeMonthly;

        if (dPercentIntoTradingDay < 0) {
            //Day hasn't started... return 1.
            dPercentIntoTradingDay = 1.0;
        }
        else if (dPercentIntoTradingDay > 1.0) {
            //After close... return 1.
            dPercentIntoTradingDay = 1.0;
        }

        return dPercentIntoTradingDay;
    }
    }
    /**
     * @return {number}
     */
    GetPercentageIntoTradingYear(tradeDate, tradeOpen, tradeClose) {
        var dPercentIntoTradingDay = 1.0;
        if(tradeDate && tradeOpen && tradeClose) {
        var getDiff1 = tradeDate.getTime() - tradeOpen.getTime();
        var getDiff2 = tradeClose.getTime() - tradeOpen.getTime();

        if (tradeDate > tradeClose) {
            getDiff1 = getDiff2;
        }

        let dTotalTradingTimeToday = getDiff2 / (1000 * 60);
        let totalToday = Math.max(0, (getDiff1 / (1000 * 60)));

        var monthStart = this.GetABeginDate(tradeDate);
        var monthEnd = this.GetAEndDate(tradeDate);
        //var monthStart = new DateTime(tradeDate.Year, tradeDate.Month, 1);
        //var monthEnd = monthStart.AddMonths(1).AddDays(-1);

        var startTdc = DocUtil.ConvertDateTimeToTdc(monthStart);
        //if (monthEnd.DayOfWeek == DayOfWeek.Sunday)
        //    monthEnd = monthEnd.AddDays(-2);
        //if (monthEnd.DayOfWeek == DayOfWeek.Saturday)
        //    monthEnd = monthEnd.AddDays(-1);

        var endTdc = DocUtil.ConvertDateTimeToTdc(monthEnd);
        var curTdc = DocUtil.ConvertDateTimeToTdc(tradeDate);

        var tradingDays = (endTdc - startTdc) + 1;

        var dTradingSoFar = curTdc < startTdc ? 0 : dTotalTradingTimeToday * (curTdc - startTdc);

        let dTotalTradingTimeMonthly = dTotalTradingTimeToday * tradingDays;

        dTradingSoFar += totalToday;

        dPercentIntoTradingDay = dTradingSoFar / dTotalTradingTimeMonthly;

        if (dPercentIntoTradingDay < 0) {
            //Day hasn't started... return 1.
            dPercentIntoTradingDay = 1.0;
        }
        else if (dPercentIntoTradingDay > 1.0) {
            //After close... return 1.
            dPercentIntoTradingDay = 1.0;
        }

        return dPercentIntoTradingDay;
    }
    }
    /**
     * @return {string}
     */
    CalculateEpsPercentageChg(prevEPS, currEPS, NegPosOn, isPerRequired = false) {
        if (prevEPS != 0) {
            prevEPS = Math.round(prevEPS, 2);
            currEPS = Math.round(currEPS, 2);
            let adjustedPrevEPS = NegPosOn ? Math.abs(prevEPS) : prevEPS;

            if (!NegPosOn && (prevEPS < 0 || currEPS < 0)) {
                return "";
            }
            var calculatedPctChange = ((Math.round(currEPS, 2) - Math.round(prevEPS, 2)) / Math.round(adjustedPrevEPS, 2)) * 100.00;
            //var p1 = (int)Math.Ceiling(calculatedPctChange);
            var perstring = isPerRequired ? "%" : "";
            var p1 = Math.round(calculatedPctChange);
            if (p1 > 0 && !isFinite(p1)) {
                return "+" + p1 + perstring;
            }
            else if (isNaN(p1) || p1 === Number.POSITIVE_INFINITY || p1 === Number.NEGATIVE_INFINITY || isFinite(p1)) {
                return "";
            }
            else {
                return p1.toString() + perstring;
            }
        }

        return "";
    }

    /**
     * @return {string}
     */
    CalculateBookEpsPercentageChg(prevEPS, currEPS, NegPosOn) {
        if (prevEPS != 0) {
            let adjustedPrevEps = NegPosOn ? Math.abs(prevEPS) : prevEPS;

            if (!NegPosOn && (prevEPS < 0 || currEPS < 0)) {
                return "";
            }

            let p1 = ((currEPS - prevEPS) * 100.00 / adjustedPrevEps).toFixed(1) + "%";
            return p1.toString();
        }

        return "";
    }

    /**
     * @return {string}
     */
    CalculateBookQuartlyEpsPercentageChg(prevEPS, currEPS, NegPosOn) {
        if (prevEPS != 0) {
            let adjustedPrevEPS = NegPosOn ? Math.abs(prevEPS) : prevEPS;

            if (!NegPosOn && (prevEPS < 0 || currEPS < 0)) {
                return "";
            }

            var p1 = parseInt(Math.round(((currEPS - prevEPS) * 100.00 / adjustedPrevEPS)));
            if (p1 > 0) {
                return "+" + p1.toString() + "%";
            }
            else {
                return p1.toString() + "%";
            }
        }

        return "";
    }

    //GraphTypeEnum periodicity, DateTime firstDate, out int interval//
    GetNextDate(periodicity, firstDate) {
        var mod;
        switch (periodicity) {
            case GraphType.Intraday5Min:
                mod = firstDate.getMinutes() % 5;
                if (mod != 0)
                    firstDate.setMinutes(firstDate.getMinutes() + 5 - mod);
                break;

            case GraphType.Intraday10Min:
                mod = firstDate.getMinutes() % 10;
                if (mod != 0)
                    firstDate.setMinutes(firstDate.getMinutes() + 10 - mod);
                break;

            case GraphType.Intraday15Min:
                mod = firstDate.getMinutes() % 15;
                if (mod != 0)
                    firstDate.setMinutes(firstDate.getMinutes() + 15 - mod);
                break;

            case GraphType.Intraday30Min:
                mod = firstDate.getMinutes() % 30;
                if (mod != 0)
                    firstDate.setMinutes(firstDate.getMinutes() + 30 - mod);
                break;

            case GraphType.Intraday60Min:
                mod = firstDate.getMinutes() % 60;
                if (mod != 0)
                    firstDate.setMinutes(firstDate.getMinutes() + 60 - mod);
                break;
            default:
                break;
        }
        return firstDate;
    }

    truncate(value) {

        if (value < 0) {
            return Math.ceil(value);
        }
        else {
            return Math.floor(value);
        }

    }
}

const stockMarketUtil = new StockMarketUtil();
export default stockMarketUtil;