import {
    watchInitRipanel,
    watchRipanelTabChange
} from './RiPanelSaga';

import {
    watchGetNewsInfo,
    watcherGetDefaultNews,
    watcherSubmitChanges
} from './RiPanelNewsInfo';

import {
    watchPopulateBrokerReports
} from './RiPanelBrokerReports';
import {
    watchInitContituentsData
} from './RiPanelConstituents';
import {
    watchInitHoldingsData
} from './RiPanelHoldings';
import {
    watchInitSimilarFundsData
} from './RiPanelSimilarFunds';

import {
    watchInitCheckListInfo,
    watchGetCheckListData
} from './RiPanelCheckListInfo';

import {
    watcherGetAllocationInfo
} from './RiPanelAllocationInfo'

import { annotationWatchers } from './Annotations';

import {
    watcherGetManagementData,
    watcherGetFundList
} from "./RiPanelManagementInfo"
import {
    watcherGetOwnershipInfo,
    watcherGetUsersHubRole
} from './RiPanelOwnership/RiPanelOwnershipInfo';

import {
    watcherGetOwnerhsipHubPanel,
    watcherToggleSalesRep,
    watcherChangeDispView
} from './RiPanelOwnership/RiHubPanel';

export const riPanelWatchers = {
    ...annotationWatchers,
    watchInitRipanel,
    watchRipanelTabChange,

    /* News */
    watchGetNewsInfo,
    watcherGetDefaultNews,
    watcherSubmitChanges,

    /* BrokerReports */
    watchPopulateBrokerReports,

    /* Check List */
    watchInitCheckListInfo,
    watchGetCheckListData,

    /* Ownership Info */
    watcherGetOwnershipInfo,
    watcherGetUsersHubRole,
    watcherGetOwnerhsipHubPanel,
    watcherToggleSalesRep,
    watcherChangeDispView,

    /* Allocation Info */
    watcherGetAllocationInfo,

    /* Holdings */
    watchInitHoldingsData,

    /* Similar Funds */

    watchInitSimilarFundsData,
    /* Holdings */
    watchInitContituentsData,

    /* Management Info */
    watcherGetManagementData,
    watcherGetFundList
};