import React from "react";
import { Modal, Checkbox } from "react-bootstrap";
import Exceptions from "./Exceptions";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import ScrollBar from "ScrollBar";

export default class ExceptionReportWindow extends React.Component {
    constructor(props) {
        super(props);
        this.closeViewReport = this.closeViewReport.bind(this);
        this.showViewReport = this.showViewReport.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            isShowViewReport: false
        }
    }

    handleClick() {
        window.open(`mailto:${Exceptions.getMailAddress()}?subject=${LocalizationStore.getTranslatedData("PANARAYErrorReport", "PANARAY Error Report")}&body=${Exceptions.getEmailBody()}`, '_blank');   
    }

    isDevOrStageEnv() {
        const env = process.env.BRANCH_ENV;
        return (env && (env.toUpperCase() == "PROD")) ? false : true;
    }

    showViewReport() {
        this.setState({ isShowViewReport: true });
    }

    closeViewReport() {
        const { isShowViewReport } = this.state;
        isShowViewReport && this.setState({
            isShowViewReport: false,
        });
    }

    render() {
        let isDevOrStageEnv = this.isDevOrStageEnv();
        return (
            <div >
                <Modal show={this.props.showErrorModal} className="modal-popup exceptionWindow exception-report">
                    <Modal.Header>
                        <Modal.Title><span className="cap-header">{LocalizationStore.getTranslatedData("er_report", "Error Report")}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-material">
                            <div className="row">
                                <div className="col-xs-12">
                                    <p>{LocalizationStore.getTranslatedData("misc_id10", "PANARAY encountered an error, however, you may continue.")}</p>
                                    <p>{LocalizationStore.getTranslatedData("misc_id11", "Please submit an Error Report so that we can determine the cause and prevent it from happening again. ")}</p>
                                </div>
                                <div className="col-xs-12">
                                    <div className="reportview-link">
                                        <Checkbox className="pull-left" onClick={this.handleClick}>{LocalizationStore.getTranslatedData("misc_id12", "Send Error Report")}</Checkbox>
                                        {isDevOrStageEnv ? <button className="pull-right" onClick={this.showViewReport}>{LocalizationStore.getTranslatedData("misc_id13", "View Report")}</button> : ""}
                                    </div>
                                </div>
                                <div className="col-xs-12">
                                    <p className="italic">{LocalizationStore.getTranslatedData("misc_id14", "Error reports do not contain personal information such as watch lists or ticker history.")}</p>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" rel="Close Window" onClick={this.props.closeReportModal}>{LocalizationStore.getTranslatedData("tp_pt_cw", "Close Window")}</button>
                        </span>
                    </Modal.Footer>
                    {this.state.isShowViewReport && <ViewErrorReport toolTipData={decodeURIComponent(Exceptions.getEmailBody())} closeViewReport={this.closeViewReport} />}
                </Modal>
            </div>
        )
    }
}


const ViewErrorReport = (props) => (
    <div id="errorReport" className="error-report">
        <h4>
            {LocalizationStore.getTranslatedData("er_report", "Error Report")}
            <button type="button" className="btn btn-default btn-secondary btn-closing" data-effect="fadeOut" onClick={props.closeViewReport}><span className="icn-closing"></span></button>
        </h4>
        <div className="error-stuff">
            <div className="error-hold">
                <div id="exception-scroll">
                    <div className="error-content">{props.toolTipData}</div>
                </div>
                <ScrollBar scrollId="exception-scroll" vScroll={true} scrollOnHover={true} />
            </div>
            <div className="btn-box center">
                <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={props.closeViewReport}>{LocalizationStore.getTranslatedData("tp_pt_cw", "Close Window")}</button>
            </div>
        </div>
    </div>
)