
import { connect } from "react-redux";
import FlexibleModal from "../../../../RayCustomControls/FlexibleModal";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import NotificationContentContainer from "../../../../RayCustomControls/Alerts/NotificationContentContainer";
import ReactDOM from "react-dom";
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import { AlertTranslateHelper, TranslateHelper } from "../../../../Utils/TranslateHelper";
import { closeAlertDialog, createPriceAlert, updatePriceAlert } from "../../../../Actions/AlertActions";
import { Modal, ControlLabel, FormGroup, FormControl, Form } from "react-bootstrap";
import React, { Component, Fragment } from "react";

class PriceAlert extends Component {
    constructor(props) {
        super(props);

        this.state = {
            price: props.price ? props.price : props.alertObj && props.alertObj.priceAlertData ? props.alertObj.priceAlertData.targetPrice : '0.000',
            note: props.note ? props.note : props.alertObj && props.alertObj.priceAlertData ? props.alertObj.priceAlertData.note : '',
            editing: props.editing ? props.editing : false,
            alertObj: props.alertObj ? props.alertObj : null,
            isNCOpen: false,
            noteTextArea: "Optional note…"
        };

        this.createPriceAlert = this.createPriceAlert.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleNotificationChange = this.handleNotificationChange.bind(this);
        this.handleNoteChange = this.handleNoteChange.bind(this);
        this.handleNoteFocus = this.handleNoteFocus.bind(this);
        this.handNoteBlur = this.handNoteBlur.bind(this);
        this.getXPosition = this.getXPosition.bind(this);
        this.getYPosition = this.getYPosition.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
    }
    componentDidMount() {
        if(!this.state.isNCOpen){
            setTimeout(() => {
                ReactDOM.findDOMNode(this.priceBox).focus();
                ReactDOM.findDOMNode(this.priceBox).select();
            }, );
        }
    }

    getXPosition () {
        let x = 0;
        const w = 280;

        if (this.props.xPos) {
            x = this.props.xPos - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2;
        }

        if (x < 0){
            x = 0;
        }

        return x;
    }

    getYPosition () {
        let y = 0;
        const h = 260;

        if (this.props.yPos) {
            y = this.props.yPos - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2;
        }

        if (y < 0){
            y = 0;
        }

        return y;
    }

    createPriceAlert () {
        if (StringUtil.isEmpty(this.state.price)) {
            this.setState({ price: this.props.price ? this.props.price : this.props.alertObj ? this.props.alertObj.priceAlertData.targetPrice : "0.000" });
            ReactDOM.findDOMNode(this.priceBox).focus();
            ReactDOM.findDOMNode(this.priceBox).select();
            return
        }
        else {
            document.getElementById("btnSavePriceAlert").disabled = true;
            if (this.props.isEditing === false) {
                this.props.createPriceAlert(this.props.SymbolInfo.MsId, parseFloat(this.state.price), this.props.currencyStr, this.state.note)
            }
            else {
                this.props.alertObj.targetPrice = parseFloat(this.state.price);
                this.props.alertObj.priceAlertData.targetPrice = parseFloat(this.state.price);
                this.props.alertObj.note = this.state.note;
                this.props.alertObj.priceAlertData.note = this.state.note;
                this.props.updatePriceAlert(this.props.alertObj)
            }
            this.props.closeAlertDialog()
        }
    }

    handlePriceChange(e) {
        const patt = new RegExp("[0-9]*");
        if (e.target.value === '' || (patt.test(e.target.value) && e.target.value.length < 20 && e.target.value < 99999999999999999999 && e.target.value >= 0)){
            this.setState({ price: e.target.value });
        }
    }

    handleNoteChange(e) {
        this.setState({ note: e.target.value });
    }

    handleNotificationChange () {
        this.setState({ isNCOpen: true });
    }
    hideModal () {
        this.setState({ isNCOpen: false });
    }
    handleKeydown(event) {
        const code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            this.createPriceAlert();
            event.preventDefault();
        }
    }
    handleFocus (e) {
        e.target.select();
    }
    handleNoteFocus(){
        this.setState({noteTextArea:""});
    }
    handNoteBlur(){
        this.setState({noteTextArea:"Optional note…"});
    }
    render() {
        return (
            <section id="priceAlert">
                <FlexibleModal left={this.getXPosition()} top={this.getYPosition()} open={this.props.isPriceAlertDialogOpen}
                    draggable="false" isDefaultDragEnabled={false} className="modal-popup price-alert">
                    <Modal.Header className="enable-drag">
                        <Modal.Title className="enable-drag">
                            <div className="enable-drag">
                                <span className="cap-header enable-drag">{AlertTranslateHelper.PRICE_ALERT_HEADER}</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-region">
                            <Form inline>
                                <FormGroup controlId="formInlineName">
                                    <ControlLabel>
                                        <Fragment>{AlertTranslateHelper.PRICE}</Fragment>
                                        <span className="currency">{this.props.currencyStr}</span>
                                    </ControlLabel>
                                    <FormControl className="disableMiniListKeyDown disableDefaultSymbolInput" ref={(ref) => (this.priceBox = ref)} onFocus={this.handleFocus} onChange={this.handlePriceChange} onKeyPress={this.handleKeydown} value={this.state.price} placeholder="0.000" />
                                </FormGroup>
                                <FormGroup controlId="formControlsTextareaNote">
                                    <ControlLabel>{AlertTranslateHelper.NOTE}</ControlLabel>
                                    <div className="noteScroll custom-scroll-light">
                                        <FormControl className="disableMiniListKeyDown disableDefaultSymbolInput" componentClass="textarea" resize='none' placeholder={LocalizationStore.getTranslatedData("al_placeholder",this.state.noteTextArea)} onFocus={this.handleNoteFocus} onBlur={this.handNoteBlur} onChange={this.handleNoteChange} value={this.state.note}/>
                                        <ScrollBar scrollId="formControlsTextareaNote" vScroll={true}/>
                                    </div>
                                </FormGroup>
                                <p onClick={this.handleNotificationChange} className="notify-alert blue-text"> {AlertTranslateHelper.NOTIFICATION_PREFERENCE} </p>
                            </Form>
                            <div className="clearfix"></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box righter">
                            <button type="button" className="active model-action btn btn-xs btn-default btn-secondary" data-effect="fadeOut" id="btnSavePriceAlert" onClick={this.createPriceAlert} >{AlertTranslateHelper.SAVE}</button>
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.closeAlertDialog}>{TranslateHelper.Cancel}</button>
                        </span>
                    </Modal.Footer>
                    {/* </Modal> */}
                </FlexibleModal>
                <NotificationContentContainer open={this.state.isNCOpen} hideModal={this.hideModal} showBackButton={true} />
            </section>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers, alertReducer }) => {
    const { alertObj, isEditing, price, xPos, yPos, isPriceAlertDialogOpen } = alertReducer.AlertDialogReducer;
    const { SymbolInfo, currencyStr } = DatagraphReducers.DataGraphReducer;
    return { alertObj, isEditing, price, xPos, yPos, SymbolInfo, isPriceAlertDialogOpen, currencyStr }
}

const mapDispatchToProps = (dispatch) => ({
    closeAlertDialog: () => dispatch(closeAlertDialog()),
    createPriceAlert: (msid, targetPrice, currencyCode, note) => dispatch(createPriceAlert(msid, targetPrice, currencyCode, note)),
    updatePriceAlert: (data) => dispatch(updatePriceAlert(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceAlert)