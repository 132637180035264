import BaseServiceApi from 'BaseServiceApi';
import AnnotationsGetRequest from '../Requests/AnnotationsGetRequest';
import AnnotationUpsertRequest from '../Requests/AnnotationUpsertRequest';
import AnnotationsUndoRequest from '../Requests/AnnotationsUndoRequest';
import AnnotationsDeleteRequest from '../Requests/AnnotationsDeleteRequest';
import AnnotationsChangeLayerRequest from '../Requests/AnnotationsChangeLayerRequest';
import AnnotationLayersGetRequest from '../Requests/AnnotationLayersGetRequest';
import AnnotationLayerUpsertRequest from '../Requests/AnnotationLayerUpsertRequest';
import AnnotationLayerUndoRequest from '../Requests/AnnotationLayerUndoRequest';
import AnnotationUtil from '../../Utils/AnnotationUtil';

class AnnotationsApi {

    GetAnnotations(msId, periodicity, scale, startDt, endDt, chartDate, targetcurrencycode, symbol = '') {
        const req = new AnnotationsGetRequest(msId, periodicity, scale, startDt, endDt, chartDate, targetcurrencycode, symbol);
        return BaseServiceApi.processServiceCall(req);
    }

    UpsertAnnotation(annotations) {
        AnnotationUtil.setAnnotationsModified(true);
        const req = new AnnotationUpsertRequest(annotations);
        return BaseServiceApi.processServiceCall(req);
    }

    DeleteAnnotations(msid,annotId){
        AnnotationUtil.setAnnotationsModified(true);
        const req = new AnnotationsDeleteRequest(msid,annotId);
        return BaseServiceApi.processServiceCall(req);
    }

    UndoDeleteAnnotations(annotations){
        const req = new AnnotationsUndoRequest(annotations);
        return BaseServiceApi.processServiceCall(req);
    }

    GetAnnotationLayers() {
        const req = new AnnotationLayersGetRequest();
        return BaseServiceApi.processServiceCall(req);
    }

    UpsertAnnotationLayer(layerId, layerName, isDeleted, isDuplicate, layerNamePrefix = "Layer ", layerNameSuffix=" Copy") {
        const req = new AnnotationLayerUpsertRequest(layerId, layerName, isDeleted, isDuplicate, layerNamePrefix, layerNameSuffix);
        return BaseServiceApi.processServiceCall(req);
    }
    
    UndoAnnotationLayer(layerId) {
        const req = new AnnotationLayerUndoRequest(layerId);
        return BaseServiceApi.processServiceCall(req);
    }
    
    ChangeLayerAnnotations(layerId, annotations){
        const req = new AnnotationsChangeLayerRequest(layerId, annotations);
        return BaseServiceApi.processServiceCall(req);
    }

}

const annotationsApi = new AnnotationsApi();
export default annotationsApi;