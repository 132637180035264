import React from 'react'
import BaseServiceApi from 'BaseServiceApi';
import {
    getListInFavorite,getVisibleNode,
    getListByCategory, getListMenu,
    getParentNodeId, getNextNode, validateListName,
    findNodeById, getParentId,folderLimitReached,
    findlastFolderlevel,findFolderCount,getNodeIdByListCategoryType
} from "../../Utils/OwnerShipUtil.js";
import ClickMenu from "ClickMenu";
import ListExplorerUtil from "ListExplorerUtil";
import { find, clone, findIndex } from "underscore";
import StringUtil from "StringUtil";
import { IsDeleteEnable, getDefaultOwnerShipSourceData } from "../../Actions/ListActions";
import BrowserUtil from "BrowserUtil";
import OwnershipViewStore from "OwnershipViewStore";
import { setActiveNodeToOwnershipStore, addSymbolsToListByDrag, showAlert, addListToList } from '../../Actions/OwnerShipActions';
import ExportExcelAction from "../../Actions/ExportExcelAction";
import ListManagerListCategory from "../../Constants/ListManagerListCategory";
import KeyCodes from "KeyCodes";
import {
    sourceList, setRenameNode, saveRenameNode, saveDeleteNode, saveDuplicateNode,
    setActiveNode, setSelectedNode, setDuplicateNode, setDragNode, setFolderActiveNode, setDeleteFolder, updateOwnership
} from '../../Actions/ListViewActions';
import store from "../../Redux/store.js";
import gridStore from '../../Stores/NavModules/NavList/Grid/GridStore.js';
import settingStore from '../../Stores/ConsoleWindow/Settings/SettingsStore.js';


const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const ListType = BaseServiceApi.rayData["ListType"];
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

const TreeListNode = (
    {
        list,
        rowslist,
        nodeToRename,
        activeNode,
        //containerClassName,
        selectedNode,
        duplicateNode,
        // ownershipTableData,
        activeFolderID,
        dragNodeID,
        // handleClick,
        fontSizeClass,
        // activeNodeChanged,
        showValidationWindow,
        updateScrollBar,
        dispatch
    }
) => {
    let keyCodePressed = null;
    let ieFocusTag = 1;
    let arrow, folder, fdName, olist = null;
    const listNode = (rowslist.listType === ListType.LIST_List ? true : false);
    const collapsed = !rowslist.expandNode;
    const rootNode = (rowslist.listType === ListType.CATEGORY_List ? true : false);
    const folderNode = (rowslist.listType === ListType.FOLDER_List ? true : false);
    const favListClass = (rowslist.listCategory === ListManagerListCategory.FAVORITE_ListCategory && rowslist.listType !== ListType.CATEGORY_List) ? true : false;
    const isActiveNode = activeNode === rowslist.nodeId.low;
    const isSelectedNode = selectedNode.includes(`${rowslist.nodeId.low}`);
    const isActionOnActiveNode = rowslist.nodeId.low === activeNode;
    const isActiveFolder = (rowslist.nodeId.low === activeFolderID && !rootNode) ? true : false;
    const IsVisible = rowslist.isVisible;
    const isClickMenuVisible = rowslist.parentNodeId && rowslist.parentNodeId.low || rowslist.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory ? true : false
    // let treeItemLevel = rowslist.listTreeItemLevel;
    let arrowClassName = (rootNode ? 'icn-tree-view_arrow' : '');
    let folderClassName = (folderNode ? 'fa icn-folder-view-open' : '');
    let dragDiv = null;

    const removeRenameNode = (evt) => {                
        const newNodeName = StringUtil.trim(evt.target.value);          
        let nodeName = !StringUtil.isEmpty(duplicateNode) && duplicateNode !== newNodeName ?
            newNodeName : StringUtil.isEmpty(newNodeName) ?
                rowslist.name : newNodeName !== rowslist.name ?
                    newNodeName : rowslist.name;
        if (StringUtil.isEmpty(duplicateNode) && !StringUtil.isEmpty(newNodeName) && newNodeName !== rowslist.name) {
            nodeName = validateListName(nodeName, rowslist.parentNodeId.low, list, rowslist.listType);
        }
        if(!StringUtil.isEmpty(duplicateNode)){
            if(StringUtil.isEmpty(newNodeName)){
                dispatch(saveRenameNode(rowslist.nodeId.low, rowslist.name, rowslist.parentNodeId.low, rowslist));                
            }
            else{
                dispatch(saveRenameNode(rowslist.nodeId.low, nodeName, rowslist.parentNodeId.low, rowslist));
                rowslist.name = nodeName;  
            }
            dispatch(setDuplicateNode(''));
        }
        else if(!StringUtil.isEmpty(nodeToRename) && rowslist.name !== newNodeName && !StringUtil.isEmpty(newNodeName) && StringUtil.isEmpty(duplicateNode)){
            dispatch(saveRenameNode(rowslist.nodeId.low, nodeName, rowslist.parentNodeId.low, rowslist)); 
            rowslist.name = nodeName;           
        }
        else{
            dispatch(setRenameNode(''));
        }
        
    }

    // const getParentId = (nodeId) => {
    //     let flatternList = [];
    //     flatternDataSource(list, flatternList);
    //     const filterList = filter(flatternList, rowList => rowList.nodeId.low === nodeId && rowList.isVisible === true);
    //     return filterList[0];
    // }
    // const oncontextmenu = (e) => {
    //     if (!isSelectedNode) {
    //         dispatch(setSelectedNode(""));
    //     }

    // }
    const handleNodeSelection = (e) => {
        dispatch(updateOwnership(false));
        dispatch(setFolderActiveNode(''));
        dispatch(setRenameNode(''));
        OwnershipViewStore.setListCatalogSearchKeyword('');
        const currentNodeId = rowslist.nodeId.low;
        const activeParent = getVisibleNode(activeNode, list);
        const selectedNodeParent = getVisibleNode(currentNodeId, list);
        const selectedDataSource = find(list, (item) => (item.categoryType === parseInt(rowslist.categoryType)));
        if (e.ctrlKey && activeParent !== undefined) {
            let selectedNodeParentId = selectedNodeParent.parentNodeId ? selectedNodeParent.parentNodeId.low : undefined;
            let activeParentNodeId = activeParent.parentNodeId ? activeParent.parentNodeId.low : undefined;
            let isParentNodeIdSame = false;
            if(selectedNodeParentId && activeParentNodeId){
                isParentNodeIdSame = selectedNodeParentId == activeParentNodeId;
            }
            if(rowslist.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory && selectedNodeParent.categoryType == activeParent.categoryType){
                // let listtIndex = findIndex(list, (item) => item.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && item.nodeId.low === currentNodeId);
                // const cloneNode = clone(list[listtIndex]);
                // if (cloneNode.isVisible) {
                //     selectedNode = `${selectedNode},${cloneNode.nodeId.low}`;
                // }
                const isExisit = selectedNode.includes(`${currentNodeId}`);
                if (isExisit) {
                    selectedNode = selectedNode.replace(`,${currentNodeId}`, '');
                }
                else {
                    if (!isActiveNode) {
                        selectedNode = `${selectedNode},${currentNodeId}`;
                    }
                }
            }
            else
            if (rowslist.categoryType !== ListCategoryType.FAVORITE_ListCategory && isParentNodeIdSame) {
                const isExisit = selectedNode.includes(`${currentNodeId}`);
                if (isExisit) {
                    selectedNode = selectedNode.replace(`,${currentNodeId}`, '');
                }
                else {
                    if (!isActiveNode) {
                        selectedNode = `${selectedNode},${currentNodeId}`;
                    }
                }
            }
            dispatch(setSelectedNode(selectedNode));
        }
        else if (e.shiftKey && activeParent !== undefined) {
            selectedNode = "";
            let selectedNodeParentId = selectedNodeParent.parentNodeId ? selectedNodeParent.parentNodeId.low : undefined;
            let activeParentNodeId = activeParent.parentNodeId ? activeParent.parentNodeId.low : undefined;
            let isParentNodeIdSame = false;
            if(selectedNodeParentId && activeParentNodeId){
                isParentNodeIdSame = selectedNodeParentId == activeParentNodeId;
            }
            if (rowslist.categoryType !== ListCategoryType.FAVORITE_ListCategory && ((rowslist.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory && selectedNodeParent.categoryType == activeParent.categoryType) || isParentNodeIdSame)) {
                let selectdIndex;
                let activIndex;
                if(rowslist.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory){
                    // let listtIndex = findIndex(list, (item) => item.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && item.nodeId.low === currentNodeId);
                    // const cloneNode = clone(list[listtIndex]);
                    // if (cloneNode.isVisible) {
                    //     selectedNode = `${selectedNode},${cloneNode.nodeId.low}`;
                    // }
                    const cloneNodes = clone(list);
                    for (let chditem = 0; chditem < cloneNodes.length; chditem++) {
                        if (cloneNodes[chditem].nodeId.low === currentNodeId) {
                            selectdIndex = chditem;
                        }
                        if (cloneNodes[chditem].nodeId.low === activeNode) {
                            activIndex = chditem;
                        }
                    }
                    if (selectdIndex < activIndex) {
                        for (let actItem = selectdIndex; actItem < activIndex; actItem++) {
                            if (cloneNodes[actItem].isVisible) {
                                selectedNode = `${selectedNode},${cloneNodes[actItem].nodeId.low}`;
                            }
                        }
                    }
                    if (selectdIndex > activIndex) {
                        for (let selItem = activIndex + 1; selItem <= selectdIndex; selItem++) {
                            if (cloneNodes[selItem].isVisible) {
                                selectedNode = `${selectedNode},${cloneNodes[selItem].nodeId.low}`;
                            }
                        }
                    }
                }
                else{
                    let listtIndex = findIndex(list, (item) => item.name === activeParent.parentCategoryName);
                    if (!StringUtil.isEmpty(listtIndex) && listtIndex > 0) {
                        const cloneNodes = clone(list[listtIndex].childNodes);
                        for (let chditem = 0; chditem < cloneNodes.length; chditem++) {
                            if (cloneNodes[chditem].nodeId.low === currentNodeId) {
                                selectdIndex = chditem;
                            }
                            if (cloneNodes[chditem].nodeId.low === activeNode) {
                                activIndex = chditem;
                            }
                        }
                        if (selectdIndex < activIndex) {
                            for (let actItem = selectdIndex; actItem < activIndex; actItem++) {
                                if (cloneNodes[actItem].isVisible) {
                                    selectedNode = `${selectedNode},${cloneNodes[actItem].nodeId.low}`;
                                }
                            }
                        }
                        if (selectdIndex > activIndex) {
                            for (let selItem = activIndex + 1; selItem <= selectdIndex; selItem++) {
                                if (cloneNodes[selItem].isVisible) {
                                    selectedNode = `${selectedNode},${cloneNodes[selItem].nodeId.low}`;
                                }
                            }
                        }
                    }
                    else {
                        const nodeToSelect = findNodeById(activeParent.parentNodeId, selectedDataSource);
                        const childList = nodeToSelect.childNodes;
                        for (let chditem = 0; chditem < childList.length; chditem++) {
                            if (childList[chditem].name === activeParent.name) {
                                listtIndex = chditem;
                            }
                            if (childList[chditem].nodeId.low === currentNodeId) {
                                selectdIndex = chditem;
                            }
                        }
                        activIndex = listtIndex;
                        if (selectdIndex < activIndex) {
                            for (let actItem = selectdIndex; actItem < activIndex; actItem++) {
                                selectedNode = `${selectedNode},${childList[actItem].nodeId.low}`;
                            }
                        }
                        if (selectdIndex > activIndex) {
                            for (let selItem = activIndex + 1; selItem <= selectdIndex; selItem++) {
                                selectedNode = `${selectedNode},${childList[selItem].nodeId.low}`;
                            }
                        }
                    }
                }
                dispatch(setSelectedNode(selectedNode));
            }
        }
        else {
            dispatch(setSelectedNode(""));
            setActiveNodeToOwnershipStore(rowslist);
            dispatch(setActiveNode(currentNodeId));
        }
        IsDeleteEnable(list, rowslist);
        // ownershipTableData(rowslist);
        //handleClick(rowslist.nodeId.low);
    }

    const setDragMessage = (elementDragged, targetElement) => {
        let dragMsg = null;
        let action = null;
        const elementsdragged = clone(selectedNode);
        const draggedArray = elementsdragged.split(',');
        if (elementDragged && targetElement) {

            if (targetElement.categoryType === ListCategoryType.FAVORITE_ListCategory) {
                if (targetElement.listType === ListType.FOLDER_List || targetElement.listType === ListType.CATEGORY_List) {
                    action = 'Add ';
                } else {
                    if (targetElement.categoryType !== elementDragged.categoryType && elementDragged.categoryType !== ListCategoryType.USER_ListCategory)
                        action = 'Copy ';
                    else
                        action = 'Move ';
                }
                dragMsg = `${action  } lists to Favorites`;
                (draggedArray.length > 1) ? dragMsg = `${action  }+${  draggedArray.length  } lists to Favorites` : dragMsg = dragMsg;
            } else {
                action = (targetElement.categoryType === ListCategoryType.USER_ListCategory && elementDragged.categoryType === ListCategoryType.USER_ListCategory) ? 'Move ' : 'Copy ';
                dragMsg = `${action  } lists`;
                (draggedArray.length > 1) ? dragMsg = `${action  }+${  draggedArray.length  } lists` : dragMsg = dragMsg;
            }
            if (elementDragged.listType === ListType.LIST_List) {
                if (targetElement.categoryType === ListCategoryType.FAVORITE_ListCategory) {
                    action = (targetElement.categoryType === ListCategoryType.FAVORITE_ListCategory && elementDragged.categoryType === ListCategoryType.FAVORITE_ListCategory) ? 'Move ' : 'Add ';
                    dragMsg = `${action + elementDragged.name  } lists to favorites`;
                    (draggedArray.length > 1) ? dragMsg = `${action  }+${  draggedArray.length  } lists to Favorites` : dragMsg = dragMsg;
                }
                else {
                    action = (targetElement.categoryType !== elementDragged.categoryType) ? 'Copy ' : 'Move ';
                    dragMsg = `${action + elementDragged.name  } lists`;
                    (draggedArray.length > 1) ? dragMsg = `${action  }+${  draggedArray.length  } lists` : dragMsg = dragMsg;
                }
            }
            else {
                dragMsg = `Move ${  elementDragged.name}`;
                (draggedArray.length > 1) ? dragMsg = `Move +${  draggedArray.length}` : dragMsg = dragMsg;
            }
        }
        return dragMsg;
    }

    const setDragDiv = (event, dragMessage, dragIconClass) => {
        dragDiv = null;
        dragDiv = document.getElementById('list-drag-ghost-image');
        const newSpan = document.createElement('span');
        newSpan.style.marginLeft = '7px';
        newSpan.style.display = 'inline-block';
        const newI = document.createElement('i');
        newI.className = `${dragIconClass  } marr5`;
        newSpan.appendChild(newI);
        const newChildSpan = document.createElement('span');
        newChildSpan.textContent = dragMessage;
        newSpan.appendChild(newChildSpan);
        dragDiv.appendChild(newSpan);
        dragDiv.className = 'dragDiv';
        dragDiv.style.top = `${event.clientY + 5  }px`, dragDiv.style.left = `${event.clientX + 0  }px`;
    }

    const dragevent = (entry) => (event) => {
        OwnershipViewStore.sourceType = 'List';
        const img = document.getElementById('ghostImage');
        if (BrowserUtil.isIEBrowser()) {
            event.dataTransfer.setData("text", entry.name);
        } else {
            event.dataTransfer.setDragImage(img, 0, 0);
            event.dataTransfer.setData("text/plain", entry.name);
        }
        dispatch(setDragNode(entry));
        const element = document.getElementsByClassName('drop-effect');
        if (element.length !== 0) {
            const elm = element[0];
            elm.classList.remove("drop-effect");
        }
    }

    const getListClassName = (rowslist) => {
        let listClassName;
        if (rowslist.listType === ListType.LIST_List) {
            switch (rowslist.categoryType) {
                case ListCategoryType.FAVORITE_ListCategory:
                    listClassName = 'fa icn-fa-file-text-fav';
                    break;

                case ListCategoryType.USER_ListCategory:
                    listClassName = 'fa icn-fa-file-text-userlist';
                    break;

                case ListCategoryType.APLIST_ListCategory:
                    listClassName = 'fa icn-fa-file-text-ap';
                    break;

                case ListCategoryType.SHAREDLIST_ListCategory:
                    if (!StringUtil.isEmpty(rowslist.shareAccess))
                        listClassName = rowslist.shareAccess === ShareAccessType.SHARE_READANDEDIT ? 'icn-editable' : 'fa icn-fa-file-text-userlist'
                    break;
                case ListCategoryType.NOTIMPORTEDLIST_ListCategory:
                    listClassName = 'fa icn-fa-file-text-temp-list';
                    break;
                default:
                    listClassName = 'fa icn-fa-file-text-p';
                    break;
            }

        }
        if (rowslist.listType === ListType.FOLDER_List) {
            listClassName = 'fa icn-folder-view';

        }
        return listClassName;

    }

    const dragoverFolder = (entry) => (event) => {
        if (OwnershipViewStore.sourceType === 'Grid') {
            setBorderOnDrag('');
        }
        if (OwnershipViewStore.sourceType === 'List') {
            setBorderOnDrag('');

            const elementDragged = { entry: entry, e: event.target };
            const nodeDragged = getParentId(parseInt(dragNodeID.nodeId.low), list);
            let dragMsg = null;
            const elementDraggednew = [];
            clearDragMessage();
            dragMsg = setDragMessage(nodeDragged, entry);
            //Get drag Icon ClassName
            // let currentelement = dragNodeID.nodeId.low;
            elementDraggednew.push(elementDragged.entry);
            setDragDiv(event, dragMsg, getListClassName(nodeDragged));

            if (elementDragged.entry.listCategory === ListManagerListCategory.FAVORITE_ListCategory) {
                if (nodeDragged.categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory && ((nodeDragged.listType === ListType.FOLDER_List && nodeDragged.listCategory === ListManagerListCategory.FAVORITE_ListCategory && elementDragged.entry.nodeId.low !== nodeDragged.nodeId.low) || (nodeDragged.listType === ListType.LIST_List && elementDragged.entry.nodeId.low !== nodeDragged.nodeId.low))) {
                    event.preventDefault(); setBorderOnDrag(entry);
                } else { false; }
            }

            if (elementDragged.entry.listCategory === ListManagerListCategory.USER_ListCategory) {
                if (nodeDragged.categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory && ((nodeDragged.listType === ListType.FOLDER_List && nodeDragged.listCategory === ListManagerListCategory.USER_ListCategory && elementDragged.entry.nodeId.low !== nodeDragged.nodeId.low) || (nodeDragged.listType === ListType.LIST_List && nodeDragged.listCategory !== ListManagerListCategory.FAVORITE_ListCategory && elementDragged.entry.nodeId.low !== nodeDragged.nodeId.low))) {
                    event.preventDefault(); setBorderOnDrag(entry);
                } else { false; }
            }
        }
    }

    const ondragoverevent = (entry) => (event) => {        
        const selectedNodes = selectedNode.split(",");
        clearDragMessage();
        setBorderOnDrag('');
        if (OwnershipViewStore.sourceType === 'List') {
            setOpacityonDrag(entry, false);
            if(entry.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory || dragNodeID.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
                return false;
            }

            if (selectedNodes.length > 1 && dragNodeID.listType === ListType.LIST_List && entry.listType === ListType.LIST_List) {
                return false;
            }

            if(entry.listType === ListType.LIST_List && entry.categoryType === ListCategoryType.USER_ListCategory && dragNodeID.categoryType !== ListCategoryType.FAVORITE_ListCategory && entry.nodeId.low !== dragNodeID.nodeId.low){
                event.preventDefault();
             }     
             else if(entry.listType !== ListType.LIST_List && (entry.categoryType === ListCategoryType.USER_ListCategory && (dragNodeID.categoryType !== ListCategoryType.FAVORITE_ListCategory) || entry.categoryType === ListCategoryType.FAVORITE_ListCategory)){
                event.preventDefault();
             }
             else return false;
        }
        if (OwnershipViewStore.sourceType === 'Grid') {
            if (entry.listType === ListType.FOLDER_List) {
                setBorderOnDrag('');
                return false;
            }
            else {
                if (entry.categoryType === ListCategoryType.FAVORITE_ListCategory) {
                    if (entry.sourceCategoryType === ListCategoryType.USER_ListCategory) {
                        setBorderOnDrag(entry);
                        event.preventDefault();
                    }
                }
                else if ((entry.categoryType === ListCategoryType.USER_ListCategory) && entry.listType === ListType.LIST_List) {
                    setBorderOnDrag(entry);
                    event.preventDefault();
                }
                else {
                    setBorderOnDrag('');
                }
            }
        }
        else {

            const elementDragged = { entry: entry, e: event.target };
            const nodeDragged = getParentId(parseInt(dragNodeID.nodeId.low), list);
            let dragMsg = null;
            const elementDraggednew = [];
            dragMsg = setDragMessage(nodeDragged, entry);
            elementDraggednew.push(elementDragged.entry);
            setDragDiv(event, dragMsg, getListClassName(nodeDragged));

            if (elementDragged.entry.listCategory === ListManagerListCategory.FAVORITE_ListCategory) {
                if ((nodeDragged.listType === ListType.FOLDER_List && nodeDragged.listCategory === ListManagerListCategory.FAVORITE_ListCategory && elementDragged.entry.listType !== ListType.LIST_List && elementDragged.entry.nodeId.low !== nodeDragged.nodeId.low) || (nodeDragged.listType === ListType.LIST_List && elementDragged.entry.listType === ListType.CATEGORY_List)) {
                    event.preventDefault(); setBorderOnDrag(entry);
                } else { false; }
            }

            if (elementDragged.entry.listCategory === ListManagerListCategory.USER_ListCategory) {
                if ((nodeDragged.listType === ListType.FOLDER_List && nodeDragged.listCategory === ListManagerListCategory.USER_ListCategory
                     && elementDragged.entry.listType === ListType.CATEGORY_List) 
                || (nodeDragged.listType === ListType.LIST_List && nodeDragged.listCategory !== ListManagerListCategory.FAVORITE_ListCategory
                     && elementDragged.entry.nodeId.low !== nodeDragged.nodeId.low
                     && (selectedNodes.length === 1 ||  elementDragged.entry.listType === ListType.CATEGORY_List))) {                     
                    event.preventDefault(); setBorderOnDrag(entry);
                } else { false; }
            }
        }
    }

    const setOpacityonDrag = (entry, isClearOpacity = false) => {
        const opacityElement = document.querySelector(`div[data-node-id='${  dragNodeID.nodeId.low  }']`);
        if (opacityElement)
            opacityElement.style.opacity = isClearOpacity ? null : '0.5';
    }

    const setBorderOnDrag = (currentElement) => {
        const borderStyle = document.getElementById("list-border");
        if (borderStyle) {
            borderStyle.remove();
        }
        let spanNodeId = null;
        // let elm = null;
        if (!StringUtil.isEmpty(currentElement)) {
            spanNodeId = (currentElement.listType === ListType.LIST_List) ? `span[data-node-id='${  currentElement.nodeId.low  }']` : `span[id='${  currentElement.nodeId.low  }']`;
            const elm = document.querySelector(spanNodeId);
            if (elm) {
                if (!(document.getElementById(`list-border-${  currentElement.nodeId.low}`))) {
                    const borderElm = document.createElement('div');
                    const borderMarginLeft = window.getComputedStyle(elm.firstChild).getPropertyValue('margin-left').match(/\d+/);
                    borderElm.setAttribute("id", "list-border");
                    borderElm.setAttribute("class", "listBorder");
                    borderElm.style.marginLeft = `${borderMarginLeft - 15  }px`;
                    borderElm.style.pointerEvents = 'none';
                    if (currentElement.listType === ListType.CATEGORY_List) {
                        borderElm.style.paddingTop = (currentElement.listType === ListType.LIST_List) ? (`${elm.children[0].offsetHeight - 4  }px`) : (`${(elm.children[0].offsetHeight) - 2  }px`);
                    } else {
                        borderElm.style.paddingTop = (currentElement.listType === ListType.LIST_List) ? (`${elm.children[0].offsetHeight - 4  }px`) : (`${(elm.children[0].offsetHeight) + 3  }px`);
                    }
                    if (!StringUtil.isEmpty(elm.children[0])) {
                        if (!StringUtil.isEmpty(elm.children[1]))
                            if (currentElement.listCategory === ListManagerListCategory.USER_ListCategory && currentElement.listType === ListType.CATEGORY_List) {
                                borderElm.style.width = `${elm.children[0].offsetWidth + elm.children[1].offsetWidth - 75  }px`;
                            } else if (currentElement.listCategory === ListManagerListCategory.FAVORITE_ListCategory && currentElement.listType === ListType.CATEGORY_List) {
                                borderElm.style.width = `${elm.children[0].offsetWidth + elm.children[1].offsetWidth - 50  }px`;
                            } else {
                                borderElm.style.width = `${elm.children[0].offsetWidth + elm.children[1].offsetWidth + 33  }px`;
                            }
                    }
                    elm.insertBefore(borderElm, elm.firstChild);
                }
            }
        } else {
            const borderStyle = document.getElementById("list-border");
            if (borderStyle) {
                borderStyle.remove();
            }
        }
    }

    const clearDragMessage = () => {
        if (!StringUtil.isEmpty(dragDiv)) {
            dragDiv.innerHTML = '';
            dragDiv.style.zIndex = '-1';
            dragDiv.removeAttribute('style');
        } else {
            const ghostImageDiv = document.getElementById('list-drag-ghost-image');
            if (ghostImageDiv) {
                ghostImageDiv.innerHTML = '';
                ghostImageDiv.style.zIndex = '-1';
                ghostImageDiv.removeAttribute('style');
            }
        }
    }

    const onDragEndevent = (/*entry*/) => (event) => {
        if (OwnershipViewStore.sourceType === 'List')
            setOpacityonDrag(event, true);
        setBorderOnDrag('');
        clearDragMessage();
    }

    const ondropevent = (entry) => (/*event*/) => {       
        if(dragNodeID == "" || entry.nodeId.low !== dragNodeID.parentNodeId.low){
        const element = `span[blink-target='${  entry.nodeId.low  }']`;
        const elm = document.querySelector(element);
        elm.classList.add("drop-effect");
        setBorderOnDrag('');
        clearDragMessage();
        if (OwnershipViewStore.sourceType === 'List')
            setOpacityonDrag(entry, true);
        const targetNodeName = entry.name;
        let targetListId = null;
        let alertMesage = '';

        if (entry.categoryType === ListCategoryType.FAVORITE_ListCategory && !StringUtil.isEmpty(entry.sourceListId)) {
            if ((entry.sourceCategoryType === ListCategoryType.USER_ListCategory)) {
                targetListId = entry.sourceListId.low;
            }
        }
        else {
            targetListId = entry.nodeId.low;
        }
        if (entry.listType === ListType.LIST_List && dragNodeID.listType === ListType.LIST_List) {
            BrowserUtil.disableEvents();
            addListToList(dragNodeID, targetListId, targetNodeName, activeNode);

        } else {

            if (OwnershipViewStore.sourceType === 'Grid') {
                gridStore.verifySelectedSymbolsExceed();
                BrowserUtil.disableEvents();
                let selectedIndexes = gridStore.getSelectedIndexes()
                if (selectedIndexes.length > 250) {
                    selectedIndexes = [...selectedIndexes.slice(0, 250)]
                }
                addSymbolsToListByDrag(targetListId, targetNodeName, activeNode,OwnershipViewStore.getMsIds(selectedIndexes), 250);
            }
            if (OwnershipViewStore.sourceType === 'List') {
                const selectedNodes = selectedNode.split(",");
                // if (selectedNodes.length > 1) {
                const ndsSelected = [];
                let favrNode, nodeIddToMove;
                const source = entry.listCategory === ListManagerListCategory.FAVORITE_ListCategory && dragNodeID.listType === ListType.LIST_List ? 'ADDFAVORITE' : 'DR-DP';
                const draggedNode = selectedNodes.length > 1 ? activeNode : dragNodeID.nodeId.low;

                if (entry.categoryType === 3) {
                    const activeNodeChek = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, draggedNode);
                    if (!activeNodeChek) {
                        nodeIddToMove = getVisibleNode(parseInt(draggedNode), list);
                        ndsSelected.push(nodeIddToMove);
                    }
                }
                else {
                    nodeIddToMove = getVisibleNode(parseInt(draggedNode), list);
                    ndsSelected.push(nodeIddToMove);
                }
                if (selectedNodes.length > 1) {
                    for (let x = 1; x < selectedNodes.length; x++) {
                        if (entry.categoryType === 3) {
                            favrNode = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, parseInt(selectedNodes[x]));
                            if (!favrNode) {
                                nodeIddToMove = getVisibleNode(parseInt(selectedNodes[x]), list);
                                ndsSelected.push(nodeIddToMove);
                            }
                        }
                        else {
                            nodeIddToMove = getVisibleNode(parseInt(selectedNodes[x]), list);
                            ndsSelected.push(nodeIddToMove);
                        }
                    }
                }
                if (entry.categoryType === 3 && selectedNodes.length !== 0) {
                    if (ndsSelected.length !== selectedNodes.length)
                        if (ndsSelected.length === 0) {
                            alertMesage = `${selectedNodes.length} list(s) already exisit`;
                        }
                        else {
                            alertMesage = `${ndsSelected.length} list(s) were added to FAVORITES, ${selectedNodes.length - ndsSelected.length} list(s) already exisit`;
                        }
                }
                if (ndsSelected.length === 0 && alertMesage) {
                    showAlert(alertMesage)
                }
                else {                    
                    let lastfolderitemLevel;                                        
                        if (dragNodeID.listType === ListType.FOLDER_List && folderLimitReached(entry)) {
                            return showValidationWindow();
                        }
                        BrowserUtil.disableEvents();
                        if(dragNodeID.listType === ListType.FOLDER_List ){
                            lastfolderitemLevel = findlastFolderlevel(dragNodeID,dragNodeID.listTreeItemLevel,false);                            
                            const folderCountInDraggedNode = findFolderCount(lastfolderitemLevel,dragNodeID.listTreeItemLevel);                            
                            if(folderCountInDraggedNode + entry.listTreeItemLevel > 5)
                            {                                
                                return showValidationWindow();
                            }
                        }                        
                        dispatch(saveDuplicateNode(dragNodeID, entry.nodeId.low, list, source, alertMesage, ndsSelected));
                }
            }

        }
       }
    }

    const drageventFolder = (entry) => (event) => {
        OwnershipViewStore.sourceType = 'List';
        const img = document.getElementById('ghostImage');
        if (BrowserUtil.isIEBrowser()) {
            event.dataTransfer.setData("text", entry.name);
        } else {
            event.dataTransfer.setDragImage(img, 0, 0);
            event.dataTransfer.setData("text/plain", entry.name);
        }
        dispatch(setDragNode(entry));
        const element = document.getElementsByClassName('drop-effect');
        if (element.length !== 0) {
            const elm = element[0];
            elm.classList.remove("drop-effect");
        }

    }

    const onBlockMenuItemLeftClicked = (option) => {
        let nodeIdToMove = '';
        let nextnode;
        switch (option.key) {
            case 'RENAME':
                dispatch(setRenameNode(rowslist.nodeId.low));
                break;
            case 'DELETE':
                selectedNode = `${selectedNode},${activeNode}`;
                selectedNode = selectedNode.split(",");
                let selectedNodes,nodeIddToMove;

                let favArray=[], favArrayIDs=[], finalNodes=null
                if (selectedNode.length > 2) {
                    selectedNode.map((item) => {
                        if (!StringUtil.isEmpty(item)) {
                            selectedNodes = `${item}; ${selectedNodes}`;
                            nodeIddToMove = getVisibleNode(parseInt(item), list);
                            nodeIddToMove.isVisible = false;
                        }
                        return item;
                    });
                    selectedNodes = selectedNodes.substring(0, selectedNodes.length - 11);

                    if (selectedNodes.includes(";")) {
                        const allNodes = selectedNodes.split(";");
                        let i = 0;
                        while (i < allNodes.length) {
                            const indiNode = +allNodes[i].trim();      
                            nextnode = getNextNode(isActionOnActiveNode, rowslist.nodeId.low, rowslist, list, activeNode);                                                
                            const FavList = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, indiNode);
                            // if (FavList) {
                            //     FavList.isVisible = false;
                            //     dispatch(saveDeleteNode(nextnode, FavList.nodeId.low, list));
                            // }

                            // PANWEB-6619
                            if(FavList){
                            FavList.isVisible = false;
                            favArray.push(FavList)
                            favArrayIDs.push(` ${FavList?.nodeId?.low}`)
                            }

                            i++;
                        }
                        if(favArrayIDs?.length){
                            finalNodes=selectedNodes?.split(";");
                            finalNodes=[...finalNodes , ...favArrayIDs]
                            finalNodes=finalNodes?.join(";")
                            selectedNodes=finalNodes
                        }
                        
                    }
                    dispatch(saveDeleteNode(false, selectedNodes, list));
                    dispatch(setSelectedNode(""));

                }
                else {
                    if (rowslist.listType === 2) {
                        dispatch(setDeleteFolder({ isShowPopup: true, nodeIDtoDelete: rowslist.nodeId.low }));
                    }
                    else {
                        rowslist.isVisible = false;
                        nextnode = getNextNode(isActionOnActiveNode, rowslist.nodeId.low, rowslist, list, activeNode);
                        const FavList = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, rowslist.nodeId.low);
                        if (FavList) {
                            const currentNode = getParentId(activeNode, list);
                            FavList.isVisible = false;
                            if(currentNode && currentNode.sourceListId){
                                nextnode = getNextNode(currentNode.sourceListId.low === rowslist.nodeId.low, currentNode.nodeId.low, currentNode, list, activeNode);
                            }
                            else{
                                nextnode = getNextNode(isActionOnActiveNode, currentNode.nodeId.low, currentNode, list, activeNode);
                            }

                            dispatch(saveDeleteNode(nextnode, `${FavList.nodeId.low};${rowslist.nodeId.low}`, list));
                        }
                        else {
                            dispatch(saveDeleteNode(nextnode, rowslist.nodeId.low, list));
                        }
                    }
                }
                break;
            case 'DUPLICATE':
                BrowserUtil.disableEvents();
                nodeIdToMove = getParentNodeId(ListCategoryType.USER_ListCategory, list, rowslist);
                dispatch(saveDuplicateNode(rowslist, nodeIdToMove, list, option.key));
                break;
            case 'ADDFAVORITE':
                {
                BrowserUtil.disableEvents();
                const favRootNodeId = getNodeIdByListCategoryType(ListCategoryType.FAVORITE_ListCategory);
                let x;
                //let selectedNodes;
                const selectedNodes = selectedNode.split(",");
                let favrNode;
                let activeNodeChek;
                const ndsSelected = [];
                
                if (selectedNodes.length > 1) {
                    activeNodeChek = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, activeNode);
                    if (!activeNodeChek) {
                        nodeIdToMove = getVisibleNode(parseInt(activeNode), list);
                        ndsSelected.push(nodeIdToMove);
                    }
                    for (x = 1; x < selectedNodes.length; x++) {
                        favrNode = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, parseInt(selectedNodes[x]));
                        if (!favrNode) {
                           nodeIdToMove = getVisibleNode(parseInt(selectedNodes[x]), list);
                            ndsSelected.push(nodeIdToMove);
                        }
                    }
                    dispatch(saveDuplicateNode(rowslist, favRootNodeId, list, option.key, '', ndsSelected));

                }
                else {
                  nodeIdToMove = getVisibleNode(parseInt(activeNode), list)
                dispatch(saveDuplicateNode(rowslist, favRootNodeId, list, option.key));
                }
                break;
            }
            case 'DELETEFAVORITE':
                rowslist.isVisible = false;
                nextnode = getNextNode(isActionOnActiveNode, rowslist.nodeId.low, rowslist, list, activeNode);
                dispatch(saveDeleteNode(nextnode, rowslist.nodeId.low, list));
                break;
            case 'REMOVEFROMFAVORITE':
                {
                const favNode = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, rowslist.nodeId.low)
                favNode.isVisible = false;
                const isAcive = favNode.nodeId.low === activeNode;
                nextnode = getNextNode(isAcive, favNode.nodeId.low, rowslist, list, activeNode);
                dispatch(saveDeleteNode(nextnode, favNode.nodeId.low, list));
                break;
                }
            case 'SHOWORIGINAL':
                {
                let scrollPositionVal;
                const scrollPosition = null;
                const ownershipScrollBar = document.getElementById("ownershipListScroll");
                const selectedNodeElm = document.querySelector(`div[data-node-id='${  rowslist.sourceListId.low  }']`);
                if (!StringUtil.isEmpty(selectedNodeElm)) {
                    scrollPositionVal = parseInt(selectedNodeElm.offsetTop) - 200;
                }
                else if (!StringUtil.isEmpty(scrollPosition)) {
                    scrollPositionVal = scrollPosition;
                }
                window.setTimeout(() => { ownershipScrollBar.scrollTop = scrollPositionVal; }, 0);
                const originalNode = OwnershipViewStore.getCurrentNode(rowslist.sourceListId.low);
                setActiveNodeToOwnershipStore(originalNode);
                dispatch(setActiveNode(rowslist.sourceListId.low));
                break;
            }
            case 'SAVESCREEN':
                BrowserUtil.disableEvents();
                dispatch(saveDuplicateNode(null, null, null, 'SAVESCREEN'));
                break;
            case 'EXPORT':
                const settings = settingStore.getConsoleSettings();
                const navListManagerSettings = settings && settings.NavListManagerSettings;
                const exportListSettings = navListManagerSettings && navListManagerSettings.ExportListSettings;
              if(exportListSettings.IsShowExportTypeDialog) {
                  ExportExcelAction.showExportDialog(rowslist.categoryType, rowslist.nodeId.low, rowslist.sourceListId, rowslist.name);
                }
                else {
                    ExportExcelAction.exportData(exportListSettings.ExportType, rowslist.categoryType, rowslist.nodeId.low, rowslist.sourceListId, rowslist.name);
                }
                break;
            default:
                break;
        }
    }

    const onselect = () => (input) => {
        if (input)
            input.select();
    }

    const handleBlur = (e) => {
        if (keyCodePressed !== 13 && e.type === 'blur') {
            if (BrowserUtil.isIEBrowser()) {
                if ((ieFocusTag % 2) === 1) {
                    e.target.focus();
                } else {
                    removeRenameNode(e);
                }
                ieFocusTag += 1;
            } else {
                removeRenameNode(e);
            }
        }
        if (keyCodePressed) {
            keyCodePressed = null;
        }
    }
    const handleOnKeyup = (e) => {
        if (KeyCodes.ENTER === e.keyCode) {
            keyCodePressed = e.keyCode;
            removeRenameNode(e);
        }
    }
    const handleOnKeyDown = (e) => {
        const self = e.target;
        self.size = (self.value.length > 1) ? self.value.length : 1;
    }
    const getRenameNode = (isFolder) => {
        const maxWidthCss = ListExplorerUtil.getListExplorerWindowWidth(true, rowslist.nodeId.low, isFolder, rowslist.listTreeItemLevel);
        const renamenode = <span>
            <input
                style={{ height: "21px", maxWidth: `${maxWidthCss}`, minWidth: "6px" }}
                className={`renameClass clearButton ${  fontSizeClass}`}
                maxLength="150"
                type="text"
                size={rowslist.name.length}
                autoFocus
                defaultValue={rowslist.name}
                onDragStart={dragevent(list)}
                onBlur={handleBlur}
                onKeyUp={handleOnKeyup}
                onKeyDown={handleOnKeyDown}
                ref={onselect()}

            />
        </span>
        return renamenode;
    }


    const OpenArrow = () => (/*event*/) => {   
        dispatch(setSelectedNode(""));
        if (rowslist.listType !== ListType.CATEGORY_List) {
            dispatch(setFolderActiveNode(rowslist.nodeId));
        }
        rowslist.expandNode = !rowslist.expandNode;
        dispatch(sourceList(list));
        if (rowslist.listType === ListType.CATEGORY_List) {
            dispatch(updateOwnership(false));
            return updateScrollBar();
        }
       
    }

    const leftMenu = getListMenu(rowslist.categoryType, rowslist.listType, list, rowslist.nodeId.low, selectedNode, activeNode, selectedNode);
    
    if (IsVisible) {
        if (rowslist.listType === ListType.CATEGORY_List) {
            rowslist.name = rowslist.name.toUpperCase();           
        }
        if (collapsed) {
            if (arrowClassName !== '') {
                arrowClassName += ' icn-tree-view_arrow-collapsed';
            }
            //containerClassName += ' tree-view_children-collapsed folder-level-2';
            if (folderClassName !== '')
                folderClassName += 'fa icn-folder-view';
        }
        if (folderClassName !== '') {
            folder = <div className={folderClassName} id={rowslist.nodeId.low} ></div>
            fdName = <span data-node-id={rowslist.nodeId.low} >
                <span blink-target={rowslist.nodeId.low} className={(rowslist.listType !== ListType.CATEGORY_List) ? "tree-node categoryClass list-no-whiteSpace " : "tree-node categoryClass list-no-whiteSpace "}
                    data-node-id={rowslist.nodeId.low}>
                    {rowslist.name}
                </span>
            </span>
        }
        if (arrowClassName !== '') {
            arrow = <div className={arrowClassName} id={rowslist.nodeId.low}  >
            </div>
            fdName = <span data-node-id={rowslist.nodeId.low}>
                <span blink-target={rowslist.nodeId.low} className={(rowslist.listType !== ListType.CATEGORY_List) ? "tree-node categoryClass list-no-whiteSpace " : "tree-node categoryClass list-no-whiteSpace "}
                    data-node-id={rowslist.nodeId.low} >
                    {rowslist.name}
                </span>

            </span>
        }
        let listClassName = 'userList ';
        let favClassName = '';
        (favListClass) ? favClassName = 'fav-row' : favClassName = '';
        if (rowslist.listType === ListType.LIST_List) {
            listClassName += getListClassName(rowslist);
            olist = <span style={{ position: 'relative' }} className='listCategoryClass list-no-whiteSpace list-no-Border ' data-node-id={rowslist.nodeId.low} >
                <i className={listClassName} data-node-id={rowslist.nodeId.low}></i>
                <span className={favClassName} blink-target={rowslist.nodeId.low} style={{ verticalAlign: 'middle' }} data-node-id={rowslist.nodeId.low} >{rowslist.name}</span>
            </span>
        }

        const className = rowslist.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory ? 
        (isActiveNode ?`h24 child-hover active child-item ${  fontSizeClass}` :  
            isSelectedNode ?
                `child-hover-selected active child-item ${  fontSizeClass}` : `h24  child-hover child-item ${  fontSizeClass}`) :
        isActiveNode ?
            `tree-view_item h24 child-hover active child-item ${  fontSizeClass}` :
            isSelectedNode ?
                `child-hover-selected active child-item ${  fontSizeClass}` :
                isActiveFolder ?
                    'tree-view_item h24  child-hover-selected active' :
                    listNode ?
                        `tree-view_item h24  child-hover child-item ${  fontSizeClass}` :
                        'tree-view_item h24  child-hover child-item';
        let dragStart, dragOver, dragDrop, clickEvent = null;
        (folderNode) ? dragStart = drageventFolder(rowslist) : dragStart = dragevent(rowslist);
        (folderNode) ? dragOver = dragoverFolder(rowslist, listClassName) : dragOver = ondragoverevent(rowslist, listClassName);
        (folderNode) ? dragDrop = ondropevent(rowslist) : dragDrop = ondropevent(rowslist);
        (rootNode || folderNode) ? clickEvent = OpenArrow(rowslist) : clickEvent = handleNodeSelection;
        listNode && nodeToRename === rowslist.nodeId.low ? clickEvent = null : clickEvent = clickEvent;
        return (
            <div className='listExplorerClass'>
                <div data-node-id={rowslist.nodeId.low} className={className} draggable={!rootNode} onDragStart={dragStart} onDragOver={dragOver}
                    onDragEnd={onDragEndevent(rowslist)} onDrop={dragDrop} style={{ pointerEvent: "none !important" }} onClick={clickEvent}>
                    {arrow}
                    <span style={{ display: "inline-block", height: "21px", border: "1px solid transparent" }} id={rowslist.nodeId.low} >{folder}
                        {
                            folderNode &&
                                nodeToRename === rowslist.nodeId.low ?
                                <div className='child-item'>
                                    <span data-node-id={rowslist.nodeId.low}>
                                        <span className={(rowslist.listType !== ListType.CATEGORY_List) ? "tree-node categoryClass list-no-whiteSpace " : "tree-node categoryClass list-no-whiteSpace "}
                                            data-node-id={rowslist.nodeId.low}>
                                            {getRenameNode(true)}
                                        </span>
                                    </span>
                                </div>
                                :
                                <div >
                                    {fdName}
                                </div>
                        }
                        {
                            listNode &&
                                nodeToRename === rowslist.nodeId.low ?
                                <div className='child-item' >
                                    <span style={{ position: 'relative' }} className='listCategoryClass list-no-whiteSpace list-no-Border ' data-node-id={rowslist.nodeId.low}>
                                        <i className={listClassName} data-node-id={rowslist.nodeId.low}></i>
                                        <span style={{ verticalAlign: 'middle' }} data-node-id={rowslist.nodeId.low} >
                                            {getRenameNode(false)}
                                        </span>
                                    </span>
                                </div>
                                :
                                <div className='child-item'>
                                    {olist}
                                </div>
                        }
                    </span>
                    {
                        isClickMenuVisible &&
                        <ClickMenu data-disable-track-price="true" items={leftMenu} ownershipMenu="ownershipMenu" position="bottom" onselect={(option) => onBlockMenuItemLeftClicked(option)} />

                    }
                </div>
            </div>

        )
    }
}
export default TreeListNode;

