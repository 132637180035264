var ModelStyleConstants = {
  PreferenceBackdropStyles: {
      base: {
          background: 'rgba(0, 0, 0, 0)',
          opacity: 0,
          visibility: 'hidden',
          transition: 'all 0.4s',
          overflowX: 'hidden',
          overflowY: 'auto'
      },
      open: {
          opacity: 1,
          visibility: 'visible',
          zIndex: 40000
      }
  },
  PreferenceDialogStyles: {
      base: {
          top: 0,
          display: 'none',
          transition: 'display 0.2s'
      },
      open: {
          top: 0,
          display: 'block',
          width: 552
      }
  },
  MenuCommonDialogBackdropStyles:{
      base: {
        background: 'rgba(0, 0, 0,0)',
        opacity: 0,
        visibility: 'hidden',
        transition: 'all 0.4s',
        overflowX: 'hidden',
        overflowY: 'auto'
      },
      open: {
        opacity: 1,
        visibility: 'visible',
        //zIndex:30000
      }
     },
   MenuCommonDialogDialogStyles:{
      base: {
        top: 0,
        display: 'none',
        transition: 'top 0.2s'
      },
      open: {
        top: 0,
        display: 'block'
        //, width:520
      }
    }
}
export default ModelStyleConstants;