var NupLayoutType = {
  SingleLayout2X2: "2 x 2",
  SingleLayout2X3: "2 x 3",
  SingleLayout3X2: "3 x 2",
  SingleLayout3X3: "3 x 3",
  SingleLayout4X4: "4 x 4",

  MultipleLayout2X2: "2 x 2 (D:W)",
  MultipleLayout2X3: "2 x 3 (D:W)",
  MultipleLayout3X2: "3 x 2 (D:W:M)",
  MultipleLayout3X3: "3 x 3 (D:W:M)",
  MultipleLayout4X4: "4 x 4 (D:W:M:Q)"
};

export default NupLayoutType;
