import React from "react";
import { Modal } from "react-bootstrap";
import ExceptionReportWindow from "./ExceptionReportWindow.jsx";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import DatagraphStore from "../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import { PrintMode } from '../../print/printmode';

export default class ExceptionWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isErrorReport: false
    }
    this.openCloseErrorReport = this.openCloseErrorReport.bind(this);
  }

  openCloseErrorReport () {
    this.setState({ isErrorReport: !this.state.isErrorReport });
  }

  render() {
    if (!PrintMode.printing) {
      return (
        <div id="exectionWindow">
          <Modal className="modal-popup exceptionWindow" show={this.props.showModal}>
            <Modal.Header><h4>{this.props.headerText}</h4></Modal.Header>
            <Modal.Body>
              <div className="modal-material">
                {!this.props.isCustomError ?
                  <p>
                    <span className="icn-warning" onClick={this.openCloseErrorReport}></span>
                    <span>{LocalizationStore.getTranslatedData("er_report1", "PANARAY encountered an error")}</span>
                  </p> 
                  : 
                  ''
                }
                <p>{this.props.errorMsg}</p>
                <div id={DatagraphStore.getState().Symbol}></div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <span className="btn-box center">
                <button type="button" className="btn btn-xs btn-default btn-secondary" rel="Close Window" onClick={this.props.closeModal}>{this.props.sessionExpired ? LocalizationStore.getTranslatedData("ok", "OK") : LocalizationStore.getTranslatedData("tp_pt_cw", "Close Window")}</button>
              </span>
            </Modal.Footer>
            {!this.props.isDisableWarningSymbolClick && this.state.isErrorReport && this.props.showModal && <ExceptionReportWindow showErrorModal={this.state.isErrorReport} closeReportModal={this.openCloseErrorReport} />}
          </Modal>
        </div>
      )
    }
    else {
      if (this.props.showModal)
        document.body.classList.add('error-pop-up');

      return null;
    }
  }
}