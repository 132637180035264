import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import SettingsStore from "SettingsStore";
import ListManagerTabType from "ListManagerTabType";
import ListExplorerStore from '../../Stores/NavModules/NavList/Explorer/ListExplorerStore';
import ONeilViewStore from "ONeilViewStore";
import BasicEsInfo from '../RequestHelper/BasicEsInfo';
import OwnershipViewStore from "OwnershipViewStore";

class GetPrintStartRequest extends BaseServiceRequest {
    constructor(symbol, printType, periodicity, listId = null, searchkeyword = null) {
        super();
        super.addRequestUri("startprint.json");
        super.addRequestParameter('type', printType);
        if (printType !== 'BATCH') {
            super.addRequestParameter('symbol', symbol);
        }
        else {
            const listSettings = SettingsStore.getConsoleSettings().NavListManagerSettings;
            let screenToggle = 0;
            let listtype = null;
            if ((listSettings.SelectedTabKey === ListManagerTabType.Oneil || listSettings.SelectedTabKey === ListManagerTabType.External) && ONeilViewStore.listCatalog) {
                const browserFilter = listSettings.TabONeilSettings.BrowserFilter;
                super.addRequestParameter('isoneil', browserFilter.isOneilselected);
                super.addRequestParameter('geofilter', browserFilter.browserFilterText);
                super.addRequestParameter('oneilfilter', browserFilter.igOneilbrowserFilters);
                super.addRequestParameter('gicsfilter', browserFilter.igGicsbrowserFilters);

                if (ListExplorerStore.activeNode.msid && ListExplorerStore.activeNode.symbol) { // For temp list
                    super.addRequestParameter('msid', ListExplorerStore.activeNode.msid);
                    super.addRequestParameter('igsymbol', ListExplorerStore.activeNode.symbol);
                }
                
                let state = ONeilViewStore.getState();
                listId = state.listId;
                screenToggle = listSettings.TabONeilSettings.ScreenOnOff;
                listtype = ListManagerTabType.Oneil;

                if (ONeilViewStore.listCatalog) {
                    const basicInfoObj = BasicEsInfo.setBasicEsInfoFromListCatalogData(ONeilViewStore.listCatalog);
                    basicInfoObj.listId = listId;
                    basicInfoObj.actualListId = state.actualListId;
                    super.setContent(basicInfoObj.encode());
                }
            }
            else {
                listId = OwnershipViewStore.getState().listId;
                screenToggle = listSettings.TabOwnershipSettings.ScreenOnOff;
                listtype = ListManagerTabType.Ownership;
            }
            super.addRequestParameter('listtype', listtype.toLowerCase());

            super.addRequestParameter('listId', listId);
            super.addRequestParameter('searchkeyword', searchkeyword);
            super.addRequestParameter('isscrnresults', screenToggle === "On" ? 1 : 0);
        }
        super.addRequestParameter('periodicity', periodicity);

        if (SettingsStore.workSpaceId) {
            super.addRequestParameter('workspaceid', SettingsStore.workSpaceId);
        }
        //Add the request parameters for key
        let key = process.env.BRANCH_ENV ? process.env.BRANCH_ENV.toUpperCase() : 'STAGE';
        super.addRequestParameter('key', key)
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return 'PrintStartResponse';
    }
}

export default GetPrintStartRequest;