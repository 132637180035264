import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AddNewColumnSetRequest extends BaseServiceRequest {

    constructor(colSetName, activeListId, parentId=0,dbtype=null) {
        super();
        super.addRequestUri("columnsets.pb");
        super.addRequestParameter("n", colSetName);
        super.addRequestParameter("l", activeListId.toString());
        if (parentId != 0) super.addRequestParameter("p", parentId.toString());
        if (dbtype) super.addRequestParameter("db", dbtype.toString());

    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default AddNewColumnSetRequest;
