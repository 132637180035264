import AnnotationUtil from '../../../../../../Utils/AnnotationUtil';
import { StrokeStyleType } from 'Constants/EAnnotationType';
import ThemeHelper from "ThemeHelper";
import React, { Component } from 'react';
// import { triangleUpdate, activeShape, lineUpdate, activeLine, drawModeActive, TriangleAdd, TextAdd } from "../Actions/AnnotationAction";

class EditableTextInput extends Component {

    componentDidMount() {
        // this.inp.focus();
        // this.inp.select();
        const { text } = this.props.text;
        setTimeout(() => {
            if (document.getElementById("temp-input")) {
                document.getElementById("temp-input").focus();
                document.getElementById("temp-input").selectionStart = text.length
            }
        }, 0);
        if (!document.body.classList.contains('annotation-active')) {
            document.body.classList.add('annotation-active');
        }
        // else {
        //     document.body.classList.remove('annotation-active');
        // }
    }

    componentWillUnmount() {
        document.body.classList.remove('annotation-active');
    }

    handleKeyPress = (e) => {
        const keyCode = e.which || e.keyCode;
        if (keyCode === 27) {
            this.props.handleRename(e, true);
            e.preventDefault();
            e.stopPropagation();
        }
    }
    handleChange = (e) => {
        this.props.handleRename(e);
        e.preventDefault();
        e.stopPropagation();
    }
    handleBlur(e) {
        this.props.handleRename(e, true);
        e.preventDefault();
        e.stopPropagation();
    }
    autosize = (el) => {
        const evt = el.target;
        setTimeout(function () {

            evt.style.height = '';
            evt.style.height = `${evt.scrollHeight}px`;
            if (evt.value.length === 0) {
                evt.style.height = `${this.props.fontSize}px`;
            }
        }, 0);
    }
    render() {
        const { width, fontSize, textAlign, text, id, isBold, isItalic, fontStyle, textHeight, borderWeight, isBorderChecked, borderColor, color, bgColor, opacity, isUnderline, strokeStyle } = this.props.text;
        const align = textAlign === 2 ? 'right' : textAlign === 3 ? 'center' : 'left';
        const font = isItalic ? "italic" : '';
        const weight = isBold ? "bold" : '';
        const borderHeight = isBorderChecked ? borderWeight * 2 : 0;
        const inputHeight = textHeight ? textHeight + 4 + borderHeight : fontSize + 4 + borderHeight;
        const stroke = strokeStyle === StrokeStyleType.SOLID_StrokeStyleType ? 'solid' : strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? 'dashed' : 'dotted';
        let textBgColor = ThemeHelper.getThemedBrush(bgColor);
        textBgColor = AnnotationUtil.hexToRGBA(textBgColor, opacity);

        return (
            <textarea ref={(r) => (this.inp = r)}
                id="temp-input"
                data-annotid={id}
                style={{
                    overflow: 'hidden',
                    width: width,
                    height: inputHeight,
                    fontSize: fontSize,
                    fontFamily: fontStyle,
                    // borderRadius: '3px',
                    border: isBorderChecked ? `${borderWeight}px ${stroke} ${ThemeHelper.getThemedBrush(borderColor)}` : '1px solid #666',
                    resize: 'none',
                    // transition: 'height .1s linear .1s',
                    textAlign: align,
                    fontStyle: font,
                    fontWeight: weight,
                    lineHeight: 1,
                    color: ThemeHelper.getThemedBrush(color),
                    background: textBgColor,
                    textDecoration: isUnderline ? 'underline' : ''
                    // position: "absolute"            
                }}
                autoFocus
                onChange={this.handleChange}
                // onKeyPress={this.handleKeyPress} 
                onKeyDown={this.handleKeyPress}
                placeholder={''}
                onBlur={(e) => this.handleBlur(e)}
                value={text}
            />
        );
    }
}

export default EditableTextInput;
