import { PriceChartConstants } from '../../../../../Constants/PriceChartConstants';
import React from 'react';
import { ScaleLabelTranslateHelper } from '../../../../../Utils/TranslateHelper';
import { useDispatch, useSelector } from 'react-redux';
const { ActionTypes } = PriceChartConstants;
function ScaleLabel() {
    const scaleLabel = useSelector((state) => state.DatagraphReducers.PriceChartReducer.scaleLabel);
    const dispatch = useDispatch();
    return (
        <div className="log-n-lin fixed-line"
            style={{ cursor: 'pointer', pointerEvents: 'all' }}
            id="scale" key="2" data-disable-track-price="true"
            onClick={() => dispatch({ type: ActionTypes.CHANGE_CHART_SCALE })} >
            {ScaleLabelTranslateHelper[scaleLabel]}
        </div>);
}
export default ScaleLabel;