import AddAnchor from '../Presentational/CustomTransformerControl.jsx';
import PropTypes from 'prop-types';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { Line, Text, Group, Image } from 'react-konva';
import React, { Component } from 'react'

class FibonacciLineControl extends Component {

    handleAnchorDragEnd = () => {
        if (this.props.activeAnchor !== '') {
            this.props.handleAnchorDragEnd();
        }
    }

    getTextPos(weight) {
        let textPos = 1;
        switch (weight) {
            case 2:
                textPos = 2;
                break;
            case 4:
                textPos = 3;
                break;
            case 6:
                textPos = 4;
                break;
            case 8:
                textPos = 5;
                break;
            case 16:
                textPos = 8;
                break;
            default:
                break;
        }
        return textPos + 12;
    }

    render() {
        const { id, color, extensionLineColor, weight, extensionLineWeight, x, y, startMouseX, geometry, endMouseX, endMouseY, startExtendPoint, endExtendPoint, startExtendChecked, endExtendChecked, isLevelLabelsChecked, isPriceLabelsChecked } = this.props.fibonacciLine;
        const { isDragging, index, selectedAnnotId, annotationDimension, draggable, lockImage, isApiReqResolved } = this.props;
        const fibonacciColor = ThemeHelper.getThemedBrush(color);
        const fibonacciExtensionLineColor = ThemeHelper.getThemedBrush(extensionLineColor)
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            <Group>
                {/* level and price labels */}
                {geometry && (isLevelLabelsChecked || isPriceLabelsChecked) && geometry.map((item, key) => {
                    if (item.lineInfo && item.textInfo) {
                        const textInfo = item.textInfo;
                        const line = item.lineInfo;
                        const textPos = line.isExtensions ? this.getTextPos(extensionLineWeight) : this.getTextPos(weight);

                        return (
                            <Text
                                key={key}
                                id={id}
                                shape={RayAnnotationType.FIB_AnnotationType}
                                index={index}
                                x={textInfo.x}
                                y={line.y - textPos}
                                fibLevel={`${key}-${textPos}`}
                                text={textInfo.text}
                                stroke={line.isExtensions ? fibonacciExtensionLineColor : fibonacciColor}
                                fill={line.isExtensions ? fibonacciExtensionLineColor : fibonacciColor}
                                strokeWidth={1}
                                fontSize={12}
                                fontFamily={'Calibri'}
                                width={textInfo.width}
                                height={20}
                                draggable={draggable && isApiReqResolved}
                                onDragStart={() => this.props.handleDragStart(this.props.fibonacciLine)}
                                onDragMove={(e) => this.props.handleDragMove(e, false, false, false, false, true)}
                                onDragEnd={() => isDragging && this.props.handleDragEnd()}
                                onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.FIB_AnnotationType) : undefined}
                            />
                        )
                    }
                })
                }

                {geometry && geometry.map((item, key) => {
                    if (item.lineInfo && item.textInfo) {
                        const line = item.lineInfo;
                        return (
                            <Line
                                key={key}
                                id={id}
                                shape={RayAnnotationType.FIB_AnnotationType}
                                index={index}
                                x={x}
                                y={y}
                                offsetY={y}
                                stroke={line.isExtensions ? fibonacciExtensionLineColor : fibonacciColor}
                                strokeWidth={line.isExtensions ? extensionLineWeight : weight}
                                dash={line.isDotted ? line.isExtensions ? [extensionLineWeight * 2.5, extensionLineWeight] : [weight * 2.5, weight] : []}
                                points={[startMouseX, line.y, line.x, line.y]}
                                draggable={draggable && isApiReqResolved}
                                onDragStart={() => this.props.handleDragStart(this.props.fibonacciLine)}
                                onDragMove={(e) => this.props.handleDragMove(e)}
                                onDragEnd={() => isDragging && this.props.handleDragEnd()}
                                onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.FIB_AnnotationType) : undefined}
                            />
                        )
                    }
                })
                }

                {/* shadow lines */}
                {geometry && geometry.map((item, key) => {
                    if (item.lineInfo && key <= 6 ? weight < 6 : extensionLineWeight < 6) {
                        const line = item.lineInfo;
                        return (
                            <Line
                                key={key}
                                id={id}
                                shape={RayAnnotationType.FIB_AnnotationType}
                                index={index}
                                x={x}
                                y={y}
                                offsetY={y}
                                stroke={line.isExtensions ? fibonacciExtensionLineColor : fibonacciColor}
                                opacity={0}
                                strokeWidth={6}
                                dash={line.isDotted ? line.isExtensions ? [extensionLineWeight * 2.5, extensionLineWeight] : [weight * 2.5, weight] : []}
                                points={[startMouseX, line.y, line.x, line.y]}
                                draggable={draggable && isApiReqResolved}
                                onDragStart={() => this.props.handleDragStart(this.props.fibonacciLine)}
                                onDragMove={(e) => this.props.handleDragMove(e)}
                                onDragEnd={() => isDragging && this.props.handleDragEnd()}
                                onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.FIB_AnnotationType) : undefined}
                            />
                        )
                    }
                })
                }

                {geometry && startExtendChecked && startExtendPoint && geometry.map((item, key) => {
                    if (item.lineInfo) {
                        const line = item.lineInfo;
                        return (
                            <Line
                                key={key}
                                id={id}
                                shape={RayAnnotationType.FIB_AnnotationType}
                                index={index}
                                x={startExtendPoint.x}
                                y={startExtendPoint.y}
                                offsetY={y}
                                stroke={line.isExtensions ? fibonacciExtensionLineColor : fibonacciColor}
                                strokeWidth={line.isExtensions ? extensionLineWeight : weight}
                                dash={line.isDotted ? line.isExtensions ? [extensionLineWeight * 2.5, extensionLineWeight] : [weight * 2.5, weight] : []}
                                points={[startMouseX, line.y, 0 - startExtendPoint.x, line.y]}
                                draggable={draggable && isApiReqResolved}
                                onDragStart={() => this.props.handleDragStart(this.props.fibonacciLine)}
                                onDragMove={(e) => this.props.handleDragMove(e, false, true)}
                                onDragEnd={() => isDragging && this.props.handleDragEnd()}
                                onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.FIB_AnnotationType) : undefined}
                            />
                        )
                    }
                })
                }

                {/* shadow lines */}
                {geometry && startExtendChecked && startExtendPoint && geometry.map((item, key) => {
                    if (item.lineInfo && key <= 6 ? weight < 6 : extensionLineWeight < 6) {
                        const line = item.lineInfo;
                        return (
                            <Line
                                key={key}
                                id={id}
                                shape={RayAnnotationType.FIB_AnnotationType}
                                index={index}
                                x={startExtendPoint.x}
                                y={startExtendPoint.y}
                                offsetY={y}
                                stroke={line.isExtensions ? fibonacciExtensionLineColor : fibonacciColor}
                                opacity={0}
                                strokeWidth={6}
                                dash={line.isDotted ? line.isExtensions ? [extensionLineWeight * 2.5, extensionLineWeight] : [weight * 2.5, weight] : []}
                                points={[startMouseX, line.y, 0 - startExtendPoint.x, line.y]}
                                draggable={draggable && isApiReqResolved}
                                onDragStart={() => this.props.handleDragStart(this.props.fibonacciLine)}
                                onDragMove={(e) => this.props.handleDragMove(e, false, true)}
                                onDragEnd={() => isDragging && this.props.handleDragEnd()}
                                onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.FIB_AnnotationType) : undefined}
                            />
                        )
                    }
                })
                }

                {geometry && endExtendChecked && endExtendPoint && geometry.map((item, key) => {
                    if (item.lineInfo) {
                        const line = item.lineInfo;
                        return (
                            <Line
                                key={key}
                                id={id}
                                shape={RayAnnotationType.FIB_AnnotationType}
                                index={index}
                                x={endExtendPoint.x}
                                y={endExtendPoint.y}
                                offsetY={y}
                                stroke={line.isExtensions ? fibonacciExtensionLineColor : fibonacciColor}
                                strokeWidth={line.isExtensions ? extensionLineWeight : weight}
                                dash={line.isDotted ? line.isExtensions ? [extensionLineWeight * 2.5, extensionLineWeight] : [weight * 2.5, weight] : []}
                                points={[startMouseX, line.y, annotationDimension.width - 6 - endExtendPoint.x, line.y]}
                                draggable={draggable && isApiReqResolved}
                                onDragStart={() => this.props.handleDragStart(this.props.fibonacciLine)}
                                onDragMove={(e) => this.props.handleDragMove(e, true)}
                                onDragEnd={() => isDragging && this.props.handleDragEnd()}
                                onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.FIB_AnnotationType) : undefined}
                            />
                        )
                    }
                })
                }

                {/* shadow lines */}
                {geometry && endExtendChecked && endExtendPoint && geometry.map((item, key) => {
                    if (item.lineInfo && key <= 6 ? weight < 6 : extensionLineWeight < 6) {
                        const line = item.lineInfo;
                        return (
                            <Line
                                key={key}
                                id={id}
                                shape={RayAnnotationType.FIB_AnnotationType}
                                index={index}
                                x={endExtendPoint.x}
                                y={endExtendPoint.y}
                                offsetY={y}
                                stroke={line.isExtensions ? fibonacciExtensionLineColor : fibonacciColor}
                                opacity={0}
                                strokeWidth={6}
                                dash={line.isDotted ? line.isExtensions ? [extensionLineWeight * 2.5, extensionLineWeight] : [weight * 2.5, weight] : []}
                                points={[startMouseX, line.y, annotationDimension.width - 6 - endExtendPoint.x, line.y]}
                                draggable={draggable && isApiReqResolved}
                                onDragStart={() => this.props.handleDragStart(this.props.fibonacciLine)}
                                onDragMove={(e) => this.props.handleDragMove(e, true)}
                                onDragEnd={() => isDragging && this.props.handleDragEnd()}
                                onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.FIB_AnnotationType) : undefined}
                            />
                        )
                    }
                })
                }

                {selectedAnnotId.includes(id) && !isDragging &&
                    <>
                        <AddAnchor x={x} y={y} annotSelected={id} type={RayAnnotationType.FIB_AnnotationType}
                            handleAnchorDragStart={this.props.handleAnchorDragStart}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'start'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={x - 3} y={y - 3} />}
                        <AddAnchor x={endMouseX} y={endMouseY} annotSelected={id} type={RayAnnotationType.FIB_AnnotationType}
                            handleAnchorDragStart={this.props.handleAnchorDragStart}
                            handleAnchorDragMove={this.props.handleAnchorDragMove}
                            handleAnchorDragEnd={this.handleAnchorDragEnd}
                            anchorPos={'end'}
                            draggable={draggable}
                            onContextMenu={this.props.handleDeleteAnnotationDrawing}
                        />
                        {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - 3} y={endMouseY - 3} />}
                    </>
                }
            </Group>
        );
    }
}

FibonacciLineControl.propTypes = {
    index: PropTypes.number.isRequired,
    fibonacciLine: PropTypes.object.isRequired,
    handleDragEnd: PropTypes.func.isRequired,
}

export default FibonacciLineControl;