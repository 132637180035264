import CalcMALine from "../../../Utils/Calcs/PriceMA.js";
import DateHelper from "DateHelper";
import { maLineTranslateHelper } from "../../../Utils/TranslateHelper.js";
import MovingAverageSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/MovingAverageSettings.js";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants.js";
import PriceMenuHelper from "./PriceMenuHelper";
import SettingsStore from "SettingsStore";
import { cancelMALineSettingDialog, changeMALineLength, toggleMAValidationDialog } from "../../../Actions/PricePanelActions.js";
import { getDatagraphStates, getMALineStates, priceChartReducerselect} from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { fork, put, select, takeLatest } from 'redux-saga/effects';

const { ActionTypes } = PriceChartConstants;
function getStockQuotes(hSFResults, split = 1) {
    try {
        const stockQuotes = [];
        for (const hSFResult of hSFResults) {
            const stockQuote = {
                Date: DateHelper.getPSTFromLong(hSFResult.Date),
                High: hSFResult.High * split,
                Low: hSFResult.Low * split,
                Close: hSFResult.Close * split,
                _volume: hSFResult.Volume / split,
                ShowVol: hSFResult.ShowVol,
                UpTick: hSFResult.UpTick,
                insiderBuySell: 0,
                insiderBuy: 0,
                insiderSell: 0,
                IsVisible: true
            };
            stockQuotes.push(stockQuote);
        }
        return stockQuotes;
    }
    catch (error) {
        console.log(`Error occurs in MALineSaga.js, getStockQuotes ${error}`);
    }
}
function PriceAverageL(scale, hsfResults, startXPoint, nodeWidth) {
    try {

        if (hsfResults === undefined) {
            return [];
        }
        const prcLength = hsfResults.length;
        const chartData = new Array(prcLength);
        let x = 0;
        for (let j = 0; j < prcLength; j++) {
            if (startXPoint < 0) { break };
            const yPrice = scale.ComputeY(hsfResults[j].Close);
            const info = {
                Date: hsfResults[j].Date,
                yPrice: yPrice,
                xAxis: startXPoint,
                yValue: hsfResults[j].Close
            };
            startXPoint -= nodeWidth;
            chartData[x] = info;
            x++;
        }
        return chartData;
    }
    catch (error) {
        console.log(`Error occurs in MALineSaga.js, PriceAverageL ${error}`);
    }
}
function PriceAverageA(scale, hsfResults, RSLine, startXPoint, nodeWidth) {
    try {
        if (hsfResults === undefined) {
            return [];
        }
        const prcLength = hsfResults.length;
        const chartData = new Array(prcLength);
        let x = 0;
        for (let j = 0; j < prcLength; j++) {
            if (startXPoint < 0) { break; }
            const yPrice = scale.ComputeY(hsfResults[j].Close);
            const info = {
                Date: hsfResults[j].Date,
                yPrice: yPrice,
                xAxis: startXPoint,
                yValue: hsfResults[j].Close
            };
            startXPoint -= nodeWidth;
            chartData[x] = info;
            x++;
        }
        return chartData;
    }
    catch (error) {
        console.log(`Error occurs in MALineSaga.js, PriceAverageA ${error}`);
    }
}
function PriceAverage(scale, hsfResults, RSLine, scaleLabel, startXPoint, nodeWidth) {
    try {
        if (scaleLabel === "LIN" && RSLine > 0) {
            const priceAverage = PriceAverageA(scale, hsfResults, RSLine, startXPoint, nodeWidth)
            return priceAverage;
        } else {
            const priceAverage = PriceAverageL(scale, hsfResults, startXPoint, nodeWidth)
            return priceAverage;
        }
    }
    catch (error) {
        console.log(`Error occurs in MALineSaga.js, PriceAverage ${error}`);
    }
}
function getPriceMA(hsfData, maPeriod, lastNode, maType, RSLine = 0, scale, scaleLabel, startXPoint, nodeWidth) {
    try {
        let vMAValues = [];
        const calcPriceMA = new CalcMALine();

        vMAValues = maType && maType === "SMA" ?
            calcPriceMA.CalculatePriceMA(hsfData, maPeriod, lastNode) :
            calcPriceMA.CalculateExponentialMovingAverage(hsfData, maPeriod, lastNode);
        if (vMAValues && vMAValues.length < 1) { return; }
        const priceAverage = PriceAverage(scale, vMAValues, RSLine, scaleLabel, startXPoint, nodeWidth)
        return priceAverage;

    }
    catch (error) {
        console.log(`Error occurs in MALineSaga.js, getPriceMA ${error}`);
    }
}
function* processMALines() {
    try {
        const maLineData = [];
        const {pricePanelData, startXPoint, nodeWidth, viewsSettings, majorPeriodicity} = yield select(getDatagraphStates);
        const maSettings = viewsSettings.MASettings[majorPeriodicity];
        const { scale, scaleLabel } = yield select(priceChartReducerselect);
        if (maSettings) {
            const split = 1;
            const mergingStockQuotes = getStockQuotes(pricePanelData.HsfData.MAHSFResults, split);
            const hsfData = pricePanelData.HsfData.HSFResults.concat(mergingStockQuotes);
            let i = 0;
            const lng = maSettings.length;
            for (; i < lng; i++) {
                const item = maSettings[i];
                if (item.ma > 0) { //item.IsVisible && optPriceChartVisibility
                    const lastNode = 0;
                    maLineData[i] = getPriceMA(hsfData, item.ma, lastNode, item.maType, 0, scale, scaleLabel, startXPoint, nodeWidth);
                }
            }
        }
        yield put({
            type: ActionTypes.MA_DATA_READY,
            maLineData,
            maSettings,
            majorPeriodicity
        })
    }
    catch (error) {
        console.log(`Error occurs in MALineSaga.js, processMALines ${error}`);
    }
}

function* OpenMALineDialog({ item }){
    try{
        const selectedPeriodicity = PriceMenuHelper.getMajorPeriodicity();
        const maLine = PriceMenuHelper.getInstSettings().MASettings[selectedPeriodicity][item.itemNo];
    
        yield put({
            type: ActionTypes.UPDATE_MA_LINE_STATES,
            isMADialogOpen: true,
            maLineHeaderText: maLineTranslateHelper[selectedPeriodicity],
            lineColor: maLine.lineColor,
            lineThickness: maLine.lineThickness,
            maType: maLine.maType,
            maLength: maLine.ma,
            selectedMALine: item.itemNo,
    
        })
    }
    catch(error){
        console.error("Error occured in OpenMALineDialog of MALineSaga.js", error);
    }
}

function* deleteMALine({ item }){
    try{
        const selectedPeriodicity = PriceMenuHelper.getMajorPeriodicity();
        const maLines = PriceMenuHelper.getInstSettings().MASettings[selectedPeriodicity];
        maLines.splice(item.itemNo, 1);
        yield put({
            type: ActionTypes.PREPARE_PRICE_MENU
        });
        yield fork(processMALines);
        SettingsStore.saveSettings();
    }
    catch(error){
        console.error("Error occured in deleteMALine of MALineSaga.js", error);
    }
}

function* SaveMALineDialog(){
    try{
        const state = yield select(getMALineStates)
        if((state.maLength > 200 || state.maLength < 1)){
            yield put(changeMALineLength(50));
            return;
        }
        const selectedPeriodicity = PriceMenuHelper.getMajorPeriodicity();
        if(state.selectedMALine === -1){
            for(const item of PriceMenuHelper.getInstSettings().MASettings[selectedPeriodicity]){
                if(item.maType === state.maType && item.ma === state.maLength){
                    yield put(toggleMAValidationDialog(true))
                    return;
                }
            }
            PriceMenuHelper.getInstSettings().MASettings[selectedPeriodicity].push( new MovingAverageSettings(state.maLength && state.maLength <= 200 && state.maLength > 0 ? state.maLength : 50,  
                                                                                                                                    state.maType, true, state.lineColor, state.lineThickness));
        }
        else{
            const maLine = PriceMenuHelper.getInstSettings().MASettings[selectedPeriodicity][state.selectedMALine];
            maLine.lineColor = state.lineColor;
            maLine.lineThickness = state.lineThickness;
            maLine.maType = state.maType;
            maLine.ma = state.maLength && state.maLength <= 200 && state.maLength > 0 ? state.maLength : 50;
        }
        yield put({
            type: ActionTypes.PREPARE_PRICE_MENU
        });
        yield put({ type: ActionTypes.PREPARE_CONTEXTMENU })
        yield fork(processMALines)
        yield put(cancelMALineSettingDialog());
        SettingsStore.saveSettings();
    }
    catch(error){
        console.error("Error occured in SaveMALineDialog of MALineSaga.js", error);
    }
}

/*****************watchers*****************/
export function* watchInitMALines() {
    yield takeLatest(ActionTypes.PRICE_DATA_READY, processMALines)
}

export function* watchOpenMALineDialog(){
    yield takeLatest(ActionTypes.OPEN_MA_LINE_EDIT_DIALOG, OpenMALineDialog)
}
export function* watchDeleteMALine(){
    yield takeLatest(ActionTypes.DELETE_MA_LINE, deleteMALine)
}
export function* watchSaveMALineDialog(){
    yield takeLatest(ActionTypes.SAVE_MA_LINE_EDIT_DIALOG, SaveMALineDialog)
}