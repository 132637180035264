import AddAnchor from '../Presentational/CustomTransformerControl.jsx';
import AnnotationUtil from '../../../../../../Utils/AnnotationUtil';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { RayAnnotationType, StrokeStyleType } from 'Constants/EAnnotationType';
import React, { Component } from 'react'
import { Rect, Group, Image } from 'react-konva';

class RectangleControl extends Component {


    handleAnchorDragEnd = () => {
        if (this.props.activeAnchor !== '') {
            this.props.handleAnchorDragEnd();
        }
    }

    render() {
        const { id, strokeStyle, width, height, borderColor, borderWeight,
            bgColor, opacity, isBorderChecked } = this.props.rectangle;
        let { x, y, endMouseX, endMouseY } = this.props.rectangle;
        const { isDragging, selectedAnnotId, draggable, lockImage, isApiReqResolved } = this.props;
        const recX = x;
        const recY = y;
        if (endMouseX < x && width !== 0) {
            x = endMouseX;
            endMouseX = recX;
        }
        if (endMouseY < y && width !== 0) {
            y = endMouseY;
            endMouseY = recY;
        }

        let borderStroke = [];
        if (strokeStyle === 3) {
            borderStroke = [borderWeight, borderWeight];
        }
        if (strokeStyle === 2) {
            borderStroke = [borderWeight * 2.5, borderWeight];
        }
        const shapeBgColor = ThemeHelper.getThemedBrush(bgColor);
        const fillOp = AnnotationUtil.hexToRGBA(shapeBgColor, opacity);
        const shapeBorderColor = ThemeHelper.getThemedBrush(borderColor)
        const isDotted = strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType;
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            <Group>
                <Rect
                    id={id}
                    shape={RayAnnotationType.RECT_AnnotationType}
                    index={this.props.index}
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    stroke={shapeBorderColor}
                    strokeWidth={isBorderChecked ? borderWeight : 0}
                    fill={fillOp}
                    dash={borderStroke}
                    closed={true}
                    onDragEnd={this.handleDragEnd}
                    dashEnabled={true}
                    draggable={draggable && isApiReqResolved}
                    onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.RECT_AnnotationType) : undefined}
                    onDragStart={() => this.props.handleDragStart(this.props.rectangle)}
                    onDragMove={(e) => this.props.handleDragMove(e)}
                    onDragEnd={() => isDragging && this.props.handleDragEnd()}
                />

                {(width < 3 || height < 3) &&
                    <Rect
                        id={id}
                        shape={RayAnnotationType.RECT_AnnotationType}
                        index={this.props.index}
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        opacity={0}
                        stroke={shapeBorderColor}
                        strokeWidth={5}
                        fill={fillOp}
                        dash={borderStroke}
                        closed={true}
                        onDragEnd={this.handleDragEnd}
                        dashEnabled={true}
                        draggable={draggable && isApiReqResolved}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.RECT_AnnotationType) : undefined}
                        onDragStart={() => this.props.handleDragStart(this.props.rectangle)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                    />
                }
                {selectedAnnotId.includes(id) && !isDragging && <React.Fragment>
                    <AddAnchor x={x} y={y} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'top-left'}
                        type={RayAnnotationType.RECT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={x - 3} y={y - 3} />}
                    <AddAnchor x={x + width / 2} y={y} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'top-middle'}
                        type={RayAnnotationType.RECT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={x + width / 2 - 3} y={y - 3} />}
                    <AddAnchor x={x + width} y={y} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'top-right'}
                        type={RayAnnotationType.RECT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={x + width - 3} y={y - 3} />}
                    <AddAnchor x={x} y={y + height / 2} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'middle-left'}
                        type={RayAnnotationType.RECT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={x - 3} y={y + height / 2 - 3} />}
                    <AddAnchor x={endMouseX} y={endMouseY - height / 2} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'middle-right'}
                        type={RayAnnotationType.RECT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - 3} y={endMouseY - height / 2 - 3} />}
                    <AddAnchor x={endMouseX - width} y={endMouseY} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'bottom-left'}
                        type={RayAnnotationType.RECT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - width - 3} y={endMouseY - 3} />}
                    <AddAnchor x={endMouseX - width / 2} y={endMouseY} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'bottom-middle'}
                        type={RayAnnotationType.RECT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - width / 2 - 3} y={endMouseY - 3} />}
                    <AddAnchor x={endMouseX} y={endMouseY} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'bottom-right'}
                        type={RayAnnotationType.RECT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - 3} y={endMouseY - 3} />}
                </React.Fragment>}
            </Group>
        );
    }
}



export default RectangleControl;