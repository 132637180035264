export default class GraphicPoints {
    constructor() {

        this.px1 = 0,
            this.px2 = 0,
            this.px3 = 0,
            this.px4 = 0,
            this.py1 = 0,
            this.py2 = 0,
            this.py3 = 0,
            this.py4 = 0

    }
}