export const customTIModalDialogConstants = {
    AccDistLine: "Accum/Dist Line",
    CurrentFiscalYear: "Current Fiscal Year",
    CurrentFiscalQuarter: "Current Fiscal Quarter",
    FastLength: "Fast Length",
    MovingAverage: "Moving Average",
    MovingAverageLine: "Moving Average (% D)",
    MACDLine: "MACD Line",
    NextFiscalYear: "Next Fiscal Year",
    PTOEHLEstimates: "Display Stock's High/Low P/E Estimates",
    PTOSHLEstimates: "Display Stock's High/Low P/S Estimates",
    PTOEIndexLine: "Index P/E",
    PTOSIndexLine: "Index P/S",
    RSILine: "RSI Line",
    SlowLength: "Slow Length",
    StochasticsLine: "Stochastics (% K)",
    Lines: "Lines",
    Apply_To_All: "Apply_To_All",

    Rs3Months: "3-month RS Rating",
    Rs6Months: "6-month RS Rating",
    Rs12Months: "12-month RS Rating",
    GrpRs3Months: "3-month Group RS Rating",
    GrpRs6Months: "6-month Group RS Rating",
    InstSupDem: "Supply/Demand",
    SMR: "SMR Rating",
    Composite: "Composite Rating",
    DataGraph: "DataGraph Rating",
    EpsRank: "EPS Rank",
    Sponsorship: "Sponsorship Rank",
}

export const CustomTiDialogConstant = {
    CurrentFiscalYear: "CurrentFiscalYear",
    CurrentFiscalYearColor: "CurrentFiscalYearColor",
    CurrentFiscalQuarter: "CurrentFiscalQuarter",
    CurrentFiscalQuarterColor: "CurrentFiscalQuarterColor",
    EMA: "EMA",
    Fast: "Fast",
    FastLength: "FastLength",
    HLEstimates: "HLEstimates",
    PTOEHLEstimates: "PTOEHLEstimates",
    PTOSHLEstimates: "PTOSHLEstimates",
    IndexColor: "IndexColor",
    Length: "Length",
    MACDLineColor: "MACDLineColor",
    MovingAverage: "MovingAverage",
    MovingAverageColor: "MovingAverageColor",
    StochasticsMovingAverageColor: "StochasticsMovingAverageColor",
    NextFiscalYear: "NextFiscalYear",
    NextFiscalYearColor: "NextFiscalYearColor",
    Overbought: "Overbought",
    Oversold: "Oversold",
    OverboughtColor: "OverboughtColor",
    OversoldColor: "OversoldColor",
    PTOEIndexLine: "PTOEIndexLine",
    PTOSIndexLine: "PTOSIndexLine",
    RSILineColor: "RSILineColor",
    SlowLength: "SlowLength",
    StochasticsColor: "StochasticsColor",
    Slow: "Slow",
    StockColor: "StockColor",
    SdLength: "SdLength",
    SignalColor: "SignalColor",

    Rs3Months: "Rs3Months",
    Rs6Months: "Rs6Months",
    Rs12Months: "Rs12Months",
    GrpRs3Months: "GrpRs3Months",
    GrpRs6Months: "GrpRs6Months",
    InstSupDem: "InstSupDem",
    SMR: "SMR",
    Composite: "Composite",
    DataGraph: "DataGraph",
    EpsRank: "EpsRank",
    Sponsorship: "Sponsorship",
    DataGraphColor: "DataGraphColor",
    Rs3MonthsColor: "Rs3MonthsColor",
    Rs6MonthsColor: "Rs6MonthsColor",
    Rs12MonthsColor: "Rs12MonthsColor",
    GrpRs3MonthsColor: "GrpRs3MonthsColor",
    GrpRs6MonthsColor: "GrpRs6MonthsColor",
    InstSupDemColor: "InstSupDemColor",
    SMRColor: "SMRColor",
    SponsorshipColor: "SponsorshipColor",
    CompositeColor: "CompositeColor",
    EpsRankColor: "EpsRankColor",
}
