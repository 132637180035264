import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AnnotationsGetRequest extends BaseServiceRequest {

  constructor(msId, periodicity, scale, startDt, endDt, chartDate, targetcurrencycode, symbol) {
    super();
    super.addRequestUri("annotations.pb");

    if(symbol) {
      super.addRequestParameter("symbol", symbol);
    }
    else {
      super.addRequestParameter("msid", msId.toString());
    }
    
    super.addRequestParameter("per", parseInt(periodicity).toString());
    super.addRequestParameter("scale", scale.toString());
    super.addRequestParameter("start", startDt);
    super.addRequestParameter("end", endDt);
    super.addRequestParameter("chartDate", chartDate);
    super.addRequestParameter("cur", targetcurrencycode);
    
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return "AnnotationsGetResponse";
  }
}

export default AnnotationsGetRequest;
