import React, { Component } from 'react'
import Measure from "react-measure";
import PropTypes from 'prop-types'
import DataRow from "./DataRow.jsx";

export default class FundamentalsTabularData extends Component {
    constructor(props) {
        super(props);
        this.onMouseClick = this.onMouseClick.bind(this);
        this.state = {
            reportDetails: props.reportDetails,
            settings: props.settings,
            dimensions: { width: 0, height: 0 },
        };
        this._dataRow = new Map();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.reportDetails !== undefined) {
            this.setState({ reportDetails: nextProps.reportDetails });
        }

    }
    SetDimensions(dimensions) {
        this.setState({ dimensions: dimensions });
    }

    onHover(index, hover) {
        const row = this._dataRow.get(index);
        if (row) {
            row.onHover(hover);
        }
    }

    syncHover(index, hover) {
        this.props.syncHover(index, hover);
    }

    onMouseClick() {
        this.props.onMouseClick()
    }

    render() {
        return (
            <Measure bounds onResize={(contentRect) => { this.SetDimensions(contentRect.bounds) }}>
                {({ measureRef }) =>
                    <table className="fundamental-main-table" id="fundamental-table" style={{ position: "relative" }} ref={measureRef}>
                        <tbody id="t_body">
                            {this.state.reportDetails.map((report, index) => {
                                const key = `datarow${index}`;
                                return <DataRow
                                    ref={(ref) => this._dataRow.set(index, ref)}
                                    report={report}
                                    key={key}
                                    rowIndex={index}
                                    settings={this.props.settings}
                                    syncHover={(index, hover) => this.syncHover(index, hover)}
                                    onMouseClick={this.onMouseClick}
                                    width={this.state.dimensions.width}
                                />;
                            })}
                        </tbody>
                    </table>
                }
            </Measure>
        );
    }
}
FundamentalsTabularData.propTypes = {
    reportDetails: PropTypes.array,
    syncHover: PropTypes.func.isRequired,
};