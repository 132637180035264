import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class RIPanelAnnotationTool extends PureComponent {
    static propTypes = {
        icnClass: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
        annotationType: PropTypes.number,
        onClick: PropTypes.func
    }

    handleClick = () => {
        const { annotationType, onClick } = this.props;
        onClick(annotationType);
    }

    render() {
        const { icnClass, isActive } = this.props;
        const activeClass = isActive ? 'selective' : '';

        return (
            <li className={activeClass} onDragStart={(e) => e.preventDefault()}><a onClick={this.handleClick}><i className={icnClass}></i></a></li>
        )
    }
}
