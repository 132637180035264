import {FinancialBlockDialogConstants} from "../Constants/FinancialBlockDialogConstants.js"
const {ActionTypes} = FinancialBlockDialogConstants

export const openFinancialDialogBox = () => ({
    type: ActionTypes.OPEN_FINANCIALBLOCK_DIALOG
})
export const onSearchTextClear = () => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_CLEAR_SEARCHTEXT
})
export const onGoBack = () => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_BACK
}) 
export const setFinancialBlockDialogState = (data) => ({
    type: ActionTypes.FINANCIAL_BLOCK_UPDATE_STATE,
    data
})
export const onRowItemSelectedChngd = (rowItem) => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_SELECT_ROWITEM_CHANGED,
    rowItem
})
export const onMouseOverMetricItem = (headerId, isMouseOver) => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_METRICITEM_MOUSEOVER,
    headerId, isMouseOver
})
export const onReorderRowItem = (rowItems) => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_REORDER_ROWITEM,
    rowItems
})
export const onSearchBoxTextChange = (searchText) => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_SEARCHTEXT_CHANGE,
    searchText
})
export const onSelectMetricChange = (detailHeader) => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_SELECTED_METRIC_CHANGE,
    detailHeader
})
export const onSubmitChanges = () => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_SUBMIT_CHANGE
})
export const onDefaultHeadersRestored = () => ({
    type: ActionTypes.FINANCIALBLOCK_DIALOG_RESTORE
})