import { ActionTypes } from "Constants/AppColorConstants";
import AppColorStore from "Stores/AppColor/AppColorStore";
import GraphicsChannelLine from './GraphicsChannelLine';
import GraphicsEllipse from './GraphicsEllipse';
import GraphicsFibonacci from './GraphicsFibonacci';
import GraphicsLine from './GraphicsLine';
import GraphicsMeasurement from './GraphicsMeasurement';
import GraphicsRectangle from './GraphicsRectangle';
import GraphicsText from './GraphicsText';
import GraphicsTriangle from './GraphicsTriangle';
import { Group } from 'react-konva';
import SettingsStore from "SettingsStore";
import ThemeType from "ThemeType";
import React, { Component } from 'react'

const defaultImage = new window.Image();
const hoverImage = new window.Image();
const lockImage = new window.Image();

class AnnotationLayers extends Component {
    componentDidMount() {
        AppColorStore.addChangeListener(this.AppColorStoreStateChanged);
        this.updateAlertIcon();
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.AppColorStoreStateChanged);
    }

    AppColorStoreStateChanged = () => {
        const currentAction = AppColorStore.getAction();
        if (currentAction === ActionTypes.CUSTOM_THEME_CHANGED) {
            this.updateAlertIcon();
            this.setState({});
        }
    }

    updateAlertIcon() {
        if (SettingsStore.getConsoleSettings().currentTheme === ThemeType.Dark) {
            defaultImage.src = '../../../../../../Asset/images/svg/icon-alert-default-dk.svg';
            hoverImage.src = '../../../../../../Asset/images/svg/icon-alert-hover-dk.svg';
            lockImage.src = '../../../../../../Asset/images/svg/lock-dark.svg';
        }
        else {
            defaultImage.src = '../../../../../../Asset/images/svg/icon-alert-default-lt.svg';
            hoverImage.src = '../../../../../../Asset/images/svg/icon-alert-hover-lt.svg';
            lockImage.src = '../../../../../../Asset/images/svg/lock-light.svg';
        }
    }
    render() {
        const { groupedAnnots, layer, isApiReqResolved } = this.props;

        return (
            <Group>
                {groupedAnnots.hasOwnProperty(layer.layerID) && groupedAnnots[layer.layerID].map((item, index) => {
                    if (item.isDeleted) {
                        return null;
                    }

                    switch (item.type) {
                        case 1:
                            return (<GraphicsLine key={`Line${index}`} line={item} index={layer.layerID + index} onContextMenu={this.props.onContextMenu} draggable={this.props.draggable} isApiReqResolved={isApiReqResolved} defaultImage={defaultImage} hoverImage={hoverImage} lockImage={lockImage} />)

                        case 2:
                            return (<GraphicsChannelLine key={`Channel${index}`} channelLine={item} index={layer.layerID + index} onContextMenu={this.props.onContextMenu} draggable={this.props.draggable} isApiReqResolved={isApiReqResolved} lockImage={lockImage} />)

                        case 5:
                            return (<GraphicsRectangle key={`Rect${index}`} rectangle={item} index={layer.layerID + index} onContextMenu={this.props.onContextMenu} draggable={this.props.draggable} isApiReqResolved={isApiReqResolved} lockImage={lockImage} />)

                        case 6:
                            return (<GraphicsEllipse key={`Ellipse${index}`} ellipse={item} index={layer.layerID + index} onContextMenu={this.props.onContextMenu} draggable={this.props.draggable} isApiReqResolved={isApiReqResolved} lockImage={lockImage} />)

                        case 7:
                            return (<GraphicsTriangle key={`Tri${index}`} triangle={item} index={layer.layerID + index} onContextMenu={this.props.onContextMenu} draggable={this.props.draggable} isApiReqResolved={isApiReqResolved} lockImage={lockImage} />)

                        case 8:
                            return (<GraphicsText key={`Text${index}`} text={item} index={layer.layerID + index} onContextMenu={this.props.onContextMenu} draggable={this.props.draggable} isApiReqResolved={isApiReqResolved} lockImage={lockImage} />)

                        case 9:
                            return (<GraphicsFibonacci key={`Fib${index}`} fibonacciLine={item} index={layer.layerID + index} onContextMenu={this.props.onContextMenu} draggable={this.props.draggable} isApiReqResolved={isApiReqResolved} lockImage={lockImage} />)

                        case 13:
                            return (<GraphicsMeasurement key={`Measure${index}`} line={item} index={layer.layerID + index} onContextMenu={this.props.onContextMenu} draggable={this.props.draggable} isApiReqResolved={isApiReqResolved} lockImage={lockImage} />)

                        default: return null;
                    }
                }
                )}
            </Group>

        );
    }
}

export default AnnotationLayers;
