import { FinancialBlockConstants } from '../Constants/FinancialBlockConstants';
const { ActionTypes } = FinancialBlockConstants;

export const getFinancialBlockData = (userSettings, symbol, beginDate, endDate, graphType, periodicity, isPeriodicityChanged, isSymbolChange) => ({
    type: ActionTypes.FINANCIAL_BLOCK_GET_API_DATA,
    userSettings,
    symbol,
    beginDate,
    endDate,
    graphType,
    periodicity,
    isPeriodicityChanged,
    isSymbolChange
})

export const headerToggle = (header, index) => ({
    type: ActionTypes.TOGGLE_HEADER,
    header, index
})

export const hideShowSideGraph = (index, isShowGraph, isEnableGraph = false) => ({
    type: ActionTypes.PAGINATED_GRAPH_VIEW,
    index, isShowGraph, isEnableGraph
})

export const onPageChanged = (pageNumber) => ({
    type: ActionTypes.FINANCIAL_PAGE_CHANGED,
    pageNumber
})

export const onFinancialBlockPeToggle = () => ({
    type: ActionTypes.FINANCIAL_PE_TOGGLE
})

export const selectMenuItem = (item) => ({
    type: ActionTypes.SELECT_MENU_ITEM,
    item
})
export const collapseFinancialBlock = (blockType, isopen) => ({
    type: ActionTypes.FINANCIAL_BLOCK_COLLAPSE,
    blockType, isopen
})
export const updatePaginatedTablerendered = () => ({	
    type: ActionTypes.UPDATE_PAGINATED_TABLE_RENDER	
    	
})