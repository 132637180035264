import BlockType from '../../../../../Constants/BlockType';
import { connect } from 'react-redux';
import ErrorBoundary from "ErrorBoundary";
import ExternalDataIndicator from './ExternalIndicator/ExternalDataIndicator';
import FundamentalIndicator from './FundamentalIndicator/FundamentalIndicators';
import KpiIndicator from './KpiIndicators/KpiIndicator';
import LayoutFactor from "LayoutFactor";
import React from 'react';
import SortableSectionList from '../../../../../RayCustomControls/Cluster/SortableSectionList';
import { sortIndicator, sortIndicatorSection } from "../../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";

const containerStyle = { flex: LayoutFactor["TechnicalIndicator"] };
class Indicators extends React.Component {
  getTechnicalIndicators() {
    const cluster = []
    let visibleIndicator = 0;
    if (this.props.indicatorMenu !== undefined) {
      this.props.indicatorMenu.forEach((item) => {
        if (!item.isDisabled && item.IsVisible) {
          const section = { Name: item.Name, Indicators: [], IsVisible: item.IsVisible };
          item.info.forEach((value) => {
            if(value.ischecked){
              switch (value.blockType) {
                case BlockType.ExternalData:
                  section.Indicators.push({
                    Name: value.Header, Visible: value.ischecked, Component: (<div className="symbol-chart-stack" key={value.blockType} style={{ ...containerStyle, minHeight: `${this.props.indicatorsHeight}px` }}> <ErrorBoundary>
                      <ExternalDataIndicator
                        menu={value.menu}
                        header={<div className='symbol-chart-label'>{value.Header}</div>}
                        listId={value.listId}
                        periodicity={value.periodicity} />
                    </ErrorBoundary></div>)
                  })
                  break;
                case BlockType.KeyPerfomanceIndicator:
                  section.Indicators.push({
                    Name: value.Header, Visible: value.ischecked, Component: (<div className="symbol-chart-stack" key={value.KpiId} style={{ ...containerStyle, minHeight: `${this.props.indicatorsHeight}px` }}> <ErrorBoundary>
                      <KpiIndicator
                        kpiId={value.KpiId}
                        menuName={value.Header}
                        item={value}
                      />
                    </ErrorBoundary></div>)
                  })
                  break;
                default:
                  section.Indicators.push({ Name: value.Header, Visible: value.ischecked, Component: (<div className="symbol-chart-stack" key={value.blockType} style={{ ...containerStyle, minHeight: `${this.props.indicatorsHeight}px` }}> <ErrorBoundary><FundamentalIndicator type={value.blockType} pType={item.blockType} /></ErrorBoundary></div>) })
              }
            }
          })
          cluster.push(section);
          if(section.Indicators.length > 0){
            visibleIndicator++;
          }
        }
      })
    }
    return [cluster, visibleIndicator];
  }

  render() {
    const [indicators, visibleIndicator] = this.getTechnicalIndicators();
    return (<>
      <SortableSectionList
        useDragHandle
        indicators={indicators}
        lockAxis="y"
        indexInc={0}
        lockOffset={['0%', '100%']}
        lockToContainerEdges={true}
        visibleSectionsLength={visibleIndicator}
        onSortEnd={this.props.sortIndicator}
        onSectionSortEnd={this.props.sortIndicatorSection}
      />
    </>
    );
  }
};

const mapStateToProps = ({ DatagraphReducers }) => {
  const { indicatorMenu } = DatagraphReducers.IndicatorReducer;
  const { indicatorsHeight } = DatagraphReducers.DataGraphReducer;
  return { indicatorMenu, indicatorsHeight };
}

const mapDispatchToProps = (dispatch) => ({
  sortIndicatorSection: (item) => dispatch(sortIndicatorSection(item)),
  sortIndicator: (item) => dispatch(sortIndicator(item))
})
export default connect(mapStateToProps, mapDispatchToProps)(Indicators)
