import annotationChartModelReducer from './annotationChartModelReducer';
import annotationLayerManagementReducer from './annotationLayerManagementReducer';
import annotationMenuReducer from './annotationMenuReducer';
import channelLineReducer from './channelLineReducer';
import { combineReducers } from 'redux';
import ellipseReducer from './ellipseReducer';
import fibonacciLineReducer from './fibonacciLineReducer';
import lineReducer from './lineReducer';
import measurementReducer from './measurementReducer';
import rectangleReducer from './rectangleReducer';
import textReducer from './textReducer';
import triangleReducer from './triangleReducer';

const annotationReducers = combineReducers({
    annotationChartModelReducer,
    annotationLayerManagementReducer,
    annotationMenuReducer,
    lineReducer,
    textReducer,
    rectangleReducer,
    ellipseReducer,
    triangleReducer,
    channelLineReducer,
    fibonacciLineReducer,
    measurementReducer
});


export default annotationReducers;