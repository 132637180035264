import React, {Component} from "react";
import ListStore from "ListStore";
import ListExplorerStore from "ListExplorerStore";
import OwnershipViewStore from 'OwnershipViewStore';
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import ExportExcelStore from "../../Stores/NavModules/NavList/ExportExcel/ExportExcelStore";
import { ExportExcelConstants,ExportTypes } from "../../Constants/ExportExcelConstants.js";
import {saveAs} from 'file-saver';
import BaseServiceApi from 'BaseServiceApi';

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

export default class ExportProgressBar extends Component {
    constructor(props){
        super(props);
        this.state = {
            isInProgress: false
        }
      this.onCallback = this.onCallback.bind(this);
    }

    componentDidMount () {
        ExportExcelStore.addChangeListener(this.onCallback);
    }

    componentWillUnmount () {
        ExportExcelStore.removeChangeListener(this.onCallback);
    }

    onCallback(){
        let state = ExportExcelStore.getState();
        let action = ExportExcelStore.getCurrentAction();
        //if current is exporting, then show the progress bar.
        if(action === ExportExcelConstants.ActionTypes.EXPORT_EXCEL_START || action === ExportExcelConstants.ActionTypes.EXPORT_EXCEL_END){
            this.setState({
                isInProgress: state.inProgress
            })
        }

        if (action === ExportExcelConstants.ActionTypes.EXPORT_EXCEL_END) {
            let selectedTab = ListExplorerStore.getSelectedTabType();
            let state = ExportExcelStore.getState();
            let action = ExportExcelStore.getCurrentAction();
            let curractiveList = ListExplorerStore.activeNode
            const curractiveListId = typeof curractiveList.nodeId === 'object' ? curractiveList.nodeId.low : curractiveList.nodeId;
            const isCurrTempList = curractiveList? curractiveList.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory : false;
            if ((!isCurrTempList || (isCurrTempList && curractiveListId !== ListStore.getSelectedListItem().SelectedListId)) &&
                Boolean(ListStore.getSelectedListItem().SelectedListId) &&
                Boolean(ListExplorerStore.getActiveNode(ListStore.getSelectedListItem().SelectedListId))) {
                curractiveList = ListExplorerStore.getActiveNode(ListStore.getSelectedListItem().SelectedListId)
            }
            let selectedNodeId = state.nodeId;
            let listNameString = '';
            if(Boolean(selectedNodeId)) {
                curractiveList = ListExplorerStore.getActiveNode(selectedNodeId);
            }
            state.nodeId = "";
            if (selectedTab == 'ONEIL') {
                listNameString =curractiveList.name;
            } else {
                curractiveList = OwnershipViewStore.activeNode;
                listNameString = curractiveList.name;                
                if(Boolean(state.fileName)) {
                    listNameString = state.fileName;
                    state.fileName = "";
                }
            }
            let date = new Date();
            let mon = date.getMonth() + 1;
            let day = date.getDate();
            let currentDate = date.getFullYear() + "-" + mon + "-" + day;
            let listName = LocalizationStore.getTranslatedData("TreeExplorer_LST_" + listNameString.replace(" ", ""), listNameString) 
            let fileName = listName.replace(/[<>?:|*]/g, "-") + " " + currentDate;

                let result = ExportExcelStore.getData();
                var type = { type: "application/ms-excel;charset=utf-8" }
                var suffix = "xlsx";               
                if (state.exportType === ExportTypes.Csv) {
                    type = { type: "text/comma-separated-values;charset=utf-8" }
                    suffix = "csv"
                }
                else if (state.exportType === ExportTypes.TabDelimited) {
                    type = { type: "text/plain;charset=utf-8" }
                    suffix = "txt"
                }
                var blob2 = new Blob([result], type);
                saveAs(blob2, fileName + "." + suffix);
                //  function 1
                //  var elink = document.createElement('a');
                //  elink.download = "test.xlsx";
                //  elink.style.display = 'none';
                //  var blob = new Blob([result])
                //  elink.href = URL.createObjectURL(blob);
                //  document.body.appendChild(elink);
                //  elink.click();
                //  document.body.removeChild(elink);
        }


    }

    // componentDidCatch(error, info) {
    //     // Display fallback UI
    //     this.setState({ hasError: true });
    //     // You can also log the error to an error reporting service
    //   //  logErrorToMyService(error, info);
    //   }

    render () {
        let style={position:"absolute", zIndex:10, marginLeft:"30%"}
        return this.state.isInProgress ? <div style={style}>
             <span> {LocalizationStore.getTranslatedData("lp_exporting","EXPORTING")} </span>
             <img alt="" src="/Asset/images/loading.gif" />
            </div> : "";
    }


}