import { createSelector } from 'reselect';
import {CompareMenuType} from "CompareTabType";
const getCompChartListItem = (state) => state.comparisonGraph.compChartListItem;
const getCompareMenu = (state) => state.comparisonGraph.compareMenu;
const getCalendarData = (state) => state.comparisonGraph.calendarControl;

export const getVisibleLines = createSelector(
    getCompChartListItem,
    (compChartListItem) => {
        let data = compChartListItem.selectedMenuTab == CompareMenuType.Symbols ? compChartListItem.SymbolsData : compChartListItem.GroupsData;
        const filterData = data.filter((line) => line.trueVisibilty).sort((a, b) => ((a.isHighlighted === b.isHighlighted) ? 0 : a.isHighlighted ? -1 : 1) || a.label.localeCompare(b.label, undefined, { numeric: true })).reverse();
        return filterData;
    }
);


export const getSortedData = createSelector(
    [getCompChartListItem, getCompareMenu],
    (compChartListItem, compMenu) => {
        let data = compChartListItem.selectedMenuTab == CompareMenuType.Symbols ? compChartListItem.SymbolsData : compChartListItem.GroupsData;
        const compareMenu = compMenu.selectedMenuTab == CompareMenuType.Symbols ? compMenu.SymbolsData : compMenu.GroupsData
        const filterData = data.filter((itm) => !itm.isFilter);
        return filterData.sort((a, b) => {
            if (parseFloat(a.percValue) > parseFloat(b.percValue))
                return compareMenu.sortType === 'dsc' ? -1 : 1;
            if (isNaN(parseFloat(a.percValue)))
                return compareMenu.sortType === 'dsc' ? 1 : -1;
            if (parseFloat(b.percValue) > parseFloat(a.percValue))
                return compareMenu.sortType === 'dsc' ? 1 : -1;
            if (isNaN(parseFloat(b.percValue)))
                return compareMenu.sortType === 'dsc' ? -1 : 1;
            return a.label.localeCompare(b.label, undefined, { numeric: true });
        });
    }
);

export const getActiveTabCompChartListItemData = createSelector([getCompChartListItem],(compChartListItem)=>{
        return compChartListItem.selectedMenuTab == CompareMenuType.Symbols ? compChartListItem.SymbolsData : compChartListItem.GroupsData;
});

export const getActiveTabCalendarData = createSelector([getCalendarData],(calendarData)=>{
    return calendarData.selectedMenuTab == CompareMenuType.Symbols ? calendarData.SymbolsData : calendarData.GroupsData;
});


