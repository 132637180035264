import React, { Component, PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { CompareMenuType } from "CompareTabType";

class CompTimeLineView extends Component {
    render() {
        const { mhLines, verticalGridData, gridLineColor } = this.props;
        return (
            <svg
                className="svg-graph-line-grid">
                <HorizentalGridLines gridLineColor={gridLineColor} mhLabels={mhLines} />
                <VerticalGridLines gridLineColor={gridLineColor} verticalGridData={verticalGridData} />
            </svg>

        );
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData, gridLineColor } = state.comparisonGraph.compareChart;
    const { mhLines, verticalGridData } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

    return ({ mhLines: mhLines, verticalGridData: verticalGridData, gridLineColor: gridLineColor });
}

export default connect(mapStateToProps)(CompTimeLineView);


class HorizentalGridLines extends PureComponent {
    render() {
        const { mhLabels, gridLineColor } = this.props;
        return (
            <Fragment>
                {mhLabels.map((itm, i) => <GridLine key={i} color={gridLineColor} isDash={true} yStart={itm.y} yEnd={itm.y} />)}
            </Fragment>
        )
    }
}

class VerticalGridLines extends PureComponent {
    render() {
        const { verticalGridData, gridLineColor } = this.props;
        return (
            <Fragment>
                {verticalGridData.map((itm, i) => <GridLine key={i} color={gridLineColor} isDash={itm.isDash} xStart={itm.xAxis} xEnd={itm.xAxis} />)}
            </Fragment>
        )
    }
}


class GridLine extends PureComponent {
    render() {
        const { xStart, xEnd, yStart, yEnd, color, isDash, strokeDasharray, strokeWidth } = this.props;
        const lineArg = {
            x1: xStart ? xStart : 0,
            y1: yStart ? yStart : 0,
            x2: xEnd ? xEnd : "100%",
            y2: yEnd ? yEnd : "100%",
            strokeWidth: strokeWidth ? strokeWidth : "1",
            strokeDasharray: isDash ? strokeDasharray ? strokeDasharray : "1 3" : undefined
        }
        return (
            <line stroke={color} {...lineArg} />
        );
    }
}
