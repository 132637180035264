import UserInitRequest from '../Requests/UserInitRequest'
import BaseServiceApi from 'BaseServiceApi'
import GetIsInSupportTimeRequest from '../Requests/GetIsInSupportTimeRequest'
import GetUserContactsRequest from '../Requests/GetUserContactsRequest'
import GetAuthRouteRequest from '../Requests/GetAuthRouteRequest'

class UserApi {

  UserInit() {
    const req = new UserInitRequest();
    return BaseServiceApi.processServiceCall(req);
  }

  checkIsInSupportTime(userId) {
    const req = new GetIsInSupportTimeRequest(userId);
    return BaseServiceApi.processServiceCall(req);
  }

  getUserContactsRequest(userId) {
    const req = new GetUserContactsRequest(userId);
    return BaseServiceApi.processServiceCall(req);
  }

  getAuthRouteRequest(userEmail, companyName = null) {
    const req = new GetAuthRouteRequest(userEmail, companyName);
    return BaseServiceApi.processServiceCall(req);
  }

}

var userApi = new UserApi();
export default userApi;
