import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import { DataGraphConstants } from "../../../../Constants/DataGraphConstants";
import DatagraphStore from "../../../../Stores/NavModules/NavDataGraph/DataGraphStore";
import DefaultDataGraphSetting from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/DefaultDataGraphSettings";
import EPSAnimationSettings from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/EPSAnimationSettings";
import GraphType from "../../../../Constants/GraphType";
import SettingsStore from "SettingsStore";
import { PriceChartConst, PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import { cloneDeep } from "lodash";

const { ActionTypes } = PriceChartConstants;
const fundamentalLines = [PriceChartConst.EPS, PriceChartConst.RPS, PriceChartConst.BV, PriceChartConst.CFS, PriceChartConst.DIV, PriceChartConst.FCF];
function getFundamentalLinesArray(){
    const lines = {}
    fundamentalLines.map((item)=> (lines[item] = []))
    return lines;
}
function getFundamentalLinesObject(){
    const lines = {}
    fundamentalLines.map((item)=> (lines[item] = {}))
    return lines;
}

function getFundamentalLinesValue(value){
    const lines = {}
    fundamentalLines.map((item)=>( lines[item] = value))
    return lines;
}
const fundamentalLinesStates = {
    multiplier:{
        [PriceChartConst.EPS]: 20,
        [PriceChartConst.RPS]: 5,
        [PriceChartConst.BV]: 1,
        [PriceChartConst.CFS]: 20,
        [PriceChartConst.DIV]: 20,
        [PriceChartConst.FCF]: 13
    },
    fundamentalLineData: getFundamentalLinesArray(),
    rawLineData: getFundamentalLinesArray(),
    processedLineData: getFundamentalLinesArray(),
    lastQtrData: getFundamentalLinesValue(undefined),
    lastQtrY: getFundamentalLinesValue(undefined),
    showPointer: getFundamentalLinesValue(false),
    multiplierPriceMenu: getFundamentalLinesValue(undefined),
    nodeWidth: 4,
    selectedPerShare: '',
    scale: new ArithmaticScale(),
}
const initialState = {
    ...fundamentalLinesStates,
    defaultSettings: {
        [PriceChartConst.EPS]: DefaultDataGraphSetting.getEstimateSettings(true),
        [PriceChartConst.RPS]: DefaultDataGraphSetting.getEstimateSettings(false),
        [PriceChartConst.BV]: DefaultDataGraphSetting.getEstimateSettings(false, 0),
        [PriceChartConst.CFS]: DefaultDataGraphSetting.getEstimateSettings(false, 1),
        [PriceChartConst.DIV]: DefaultDataGraphSetting.getEstimateSettings(false, 2),
        [PriceChartConst.FCF]: DefaultDataGraphSetting.getEstimateSettings(false, 3)
    },
    fundamentalLineSettings: getFundamentalLinesObject(),
    fundamentalLineMultipierSettings: getFundamentalLinesObject(),
    fundamentalLinesSettingData: [],
    selectedFundamentalLine: '',
    showSettingDialog: false,
    selectedPeriodicity: GraphType.Weekly,
    settingDialogHeader: "Eps Header",
    lastnodeIndex: 500,
    minPrice: Number.POSITIVE_INFINITY, 
    maxPrice: Number.NEGATIVE_INFINITY,
    isExternalDataVisible: false,
    isPlaying: false,
    onAnimationChartLoad: false,
    currentGraphValueIndex: 0,
    epsAnimationSettings: new EPSAnimationSettings(),
    isHistoricEpsLine: false,
    earningsAnimationResults: [],
    isEPSChartLoaded: false,
    snapshots: [],
    isEPSAnimationDataReady: false,
    epsEndDate: '',
    epsTimeLine: [],
    lastStartGraphValueIndex: 0,
    lastVisibleGraphValueIndex: 0,
    request: {},
    IsEarningsAnimationDocked: true,
    IsAnimateWithChartLoad: false,
    AnimationSpeedEnum: 0,
    epsFormateDate: null,
    isAnimationMessageDialogOpen: false,
    isAnimationDialogOpen: false,
    isAnimationMessageDialogAvailable: false,
    epsAnimationSettingsData: [],
    epsAnimationDefaultSettingsData: DefaultDataGraphSetting.getEPSAnimationSetting(),
    epsScrubberVisiblity: false,
    IsEarningsAnimationDisplayed: false,
}
const FundamentalLineReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FUNDAMENTAL_LINE_DATA_READY:
            state.multiplier = {...state.multiplier, ...action.multiplier};
            state.rawLineData = {...state.rawLineData, ...action.rawLineData};
            state.processedLineData = {...state.processedLineData, ...action.processedLineData};
            state.lastQtrData = {...state.lastQtrData, ...action.lastQtrData};
            state.lastQtrY = {...state.lastQtrY, ...action.lastQtrY};
            state.showPointer = {...state.showPointer, ...action.showPointer};
            state.scale = action.scale;
            state.selectedPerShare = action.selectedPerShare;
            state.multiplierPriceMenu = {...state.multiplierPriceMenu, ...action.multiplierPriceMenu};
            state.fundamentalLineSettings = {...state.fundamentalLineSettings, ...action.fundamentalLineSettings};
            state.fundamentalLineMultipierSettings = {...state.fundamentalLineMultipierSettings, ...action.fundamentalLineMultipierSettings};
            state.lastnodeIndex = action.lastnodeIndex;
            state.minPrice = action.minPrice,
            state.maxPrice = action.maxPrice
            return { ...state };
        case ActionTypes.UPDATE_FUNDAMENTAL_LINE_DATA:
            state.multiplier[action.LineType] = action.multiplier;
            state.rawLineData[action.LineType] = action.rawLineData;
            state.processedLineData[action.LineType] = action.processedLineData;
            state.lastQtrData[action.LineType] = action.lastQtrData;
            state.lastQtrY[action.LineType] = action.lastQtrY;
            return { ...state };

        case ActionTypes.UPDATE_SELECT_PER_SHARE:
            state.selectedPerShare = action.selectedPerShare;
            return { ...state };
        case ActionTypes.FUNDAMENTAL_LINE_SETTING_UPDATE:
            state.fundamentalLineSettings[action.lineType] = cloneDeep(action.settings);
            state.fundamentalLineMultipierSettings[action.lineType] = action.multiplierSettings;
            return { ...state };
        case ActionTypes.UPDATE_FUNDAMENTAL_LINE_DIALOG_SETTING:
            state.showSettingDialog = action.showSettingDialog;
            state.selectedPeriodicity = action.selectedPeriodicity;
            state.fundamentalLinesSettingData = action.fundamentalLinesSettingData;
            state.selectedFundamentalLine = action.selectedFundamentalLine;
            return { ...state };
        case ActionTypes.CANCEL_FUNDAMENTAL_EDIT_DIALOG:
            state.showSettingDialog = false;
            state.fundamentalLinesSettingData = [];
            return { ...state };
        case ActionTypes.CHANGE_FUNDAMENTAL_LINE_EARNING:
            state.fundamentalLinesSettingData = state.fundamentalLinesSettingData.map((item)=>{
                if(item.periodicity === state.selectedPeriodicity){
                    item.showEarningSurprise = !item.showEarningSurprise;
                }
                return item;
            });
            return { ...state };
        case ActionTypes.CHANGE_FUNDAMENTAL_LINE_ESTIMATE:
            state.fundamentalLinesSettingData = state.fundamentalLinesSettingData.map((item)=>{
                if(item.periodicity === state.selectedPeriodicity){
                    item.showHighLowEstimate = !item.showHighLowEstimate;
                }
                return item;
            });
            return { ...state };
        case ActionTypes.CHANGE_FUNDAMENTAL_LINE_WEIGHT:
            state.fundamentalLinesSettingData = state.fundamentalLinesSettingData.map((item)=>{
                if(item.periodicity === state.selectedPeriodicity){
                    item.lineThickness = action.lineThickness;
                }
                return item;
            });
            return { ...state };
        case ActionTypes.CHANGE_FUNDAMENTAL_LINE_COLOR:
            state.fundamentalLinesSettingData = state.fundamentalLinesSettingData.map((item)=>{
                if(item.periodicity === state.selectedPeriodicity){
                    item.lineColor = action.lineColor;
                }
                return item;
            });
            return { ...state };
        case ActionTypes.CHANGE_FUNDAMENTAL_EDIT_DIALOG_TAB:
            state.selectedPeriodicity = action.selectedPeriodicity;
            return { ...state };
        case ActionTypes.UPDATE_EXTERNAL_DATA_TOGGLE_BUTTON:
            state.isExternalDataVisible = action.isExternalDataVisible;
            return { ...state };
        case ActionTypes.RESET_FUNDAMENTAL_lINE_STATE:
            return { ...state, ...fundamentalLinesStates };
        
        case ActionTypes.UPDATE_FUNDAMENTAL_LINE_STATES:
            return { ...state, ...action.states };
        case ActionTypes.UPDATE_IS_EPS_ANIMATION_DOCKED:
            state.epsAnimationSettings.IsEarningsAnimationDocked = action.IsEarningsAnimationDocked
            SettingsStore.saveSettings();
            return { ...state, IsEarningsAnimationDocked: action.IsEarningsAnimationDocked, isPlaying: false };
        case ActionTypes.UPDATE_IS_HISTORIC_EPS_LINE:
            return { ...state, isHistoricEpsLine: action.isHistoricEpsLine };
        case ActionTypes.UPDATE_IS_EPS_CHART_LOADED:
            return { ...state, isEPSChartLoaded: action.isEPSChartLoaded };

        case ActionTypes.TOGGLE_EPS_ANIMAITON_DISPLAYED:{
            if(action.item.isActive || state.isAnimationMessageDialogAvailable){
                state.epsAnimationSettings.IsEarningsAnimationDisplayed = !action.item.isActive;
                SettingsStore.saveSettings();
            }
            action.item.isActive = !action.item.isActive;
            return { ...state, IsEarningsAnimationDisplayed: state.epsAnimationSettings.IsEarningsAnimationDisplayed, isAnimationMessageDialogOpen: action.item.isActive && !state.isAnimationMessageDialogAvailable };
        }

        case ActionTypes.TOGGLE_EPS_ANIMAITON_MESSAGE_DIALOG:{
            state.epsAnimationSettings.IsEarningsAnimationDisplayed = !action.isAnimationMessageDialogOpen;
            DatagraphStore.getNavDataGraphSettings().isAnimationMessageDialogAvailable = state.isAnimationMessageDialogAvailable;
            SettingsStore.saveSettings();
            return { ...state, IsEarningsAnimationDisplayed: state.epsAnimationSettings.IsEarningsAnimationDisplayed, isAnimationMessageDialogOpen: action.isAnimationMessageDialogOpen};
        }
        case ActionTypes.TOGGLE_EPS_ANIMAITON_MESSAGE_DIALOG_AVAILABLE:
            return { ...state, isAnimationMessageDialogAvailable: !state.isAnimationMessageDialogAvailable};
        case ActionTypes.CANCEL_EPS_ANIMAITON_DIALOG:
            return { ...state, isAnimationDialogOpen: false};
        case ActionTypes.CHANGE_EPS_ANIMATION_DIALOG_PROPERTY:
            state.epsAnimationSettingsData = state.epsAnimationSettingsData.map((tab)=> {
                if(state.selectedPeriodicity === tab.periodicity){
                    tab[action.property] = action.value;
                }
                return tab;
            });
            return { ...state};
        case ActionTypes.UPDATE_EPS_ANIMATION_DIALOG_SETTING:
            state.isAnimationDialogOpen = action.isAnimationDialogOpen;
            state.epsAnimationSettingsData = action.epsAnimationSettingsData;
            state.selectedPeriodicity = action.selectedPeriodicity;
            state.selectedFundamentalLine = action.selectedFundamentalLine;
            return { ...state };
        case ActionTypes.TOGGLE_EPS_ANIMAITON_IS_PLAYING:
            return { ...state, isPlaying: action.isPlaying };

        case DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY:
            return { ...initialState };
        
        default:
            return state
    }
}
export default FundamentalLineReducer;