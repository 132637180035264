import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

export default class GetListSummaryStatsRequest extends BaseServiceRequest {
    constructor(basicInfo, listId) { 
        super();
        super.addRequestUri("esstats.pb");
        super.addPathParameter(listId.toString());
        super.setContent(basicInfo.encode());
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListSummaryStatsResponse2";
    }
}