import keyMirror from "keymirror";

export const SettingsConstants = {
  ActionTypes: keyMirror({
    SET_USER_SETTINGS: null,
    GET_USER_SETTINGS: null,
    DATAGRAPH_UPDATE_TAB: null,
    DATAGRAPH_PRICE_CHANGE: null,
    STOCK_SYMBOL_CHANGED: null,
    SET_DATAGRAPH_SYMBOL_HEADER:null,
    MINILIST_SYMBOL_CHANGED: null,
    ON_MINILIST_SYMBOL_CHANGED: null,
    CURRENCY_SYMBOL_CHANGED: null,
    CRYPTO_CURRENCY_SYMBOL_CHANGED: null,
    ETF_SYMBOL_CHANGED: null,
    CEF_SYMBOL_CHANGED: null,
    FUND_SYMBOL_CHANGED: null,
    FUTURES_SYMBOL_CHANGED: null,
    CASHSPOTS_SYMBOL_CHANGED: null,
    INDUSTRYGROUP_SYMBOL_CHANGED: null,
    INDEX_SYMBOL_CHANGED: null,
    LIST_EXPLORER_UPDATE_TAB: null,
    DDE_STATUS_CHANGED: null,
    THEME_CHANGED: null,
    GET_SYMBOL_PRICE_DATA: null,
    GET_RT_PRICE_DATA:null,
    RESET_SETTINGS:null,
    SAVE_USER_SETTTINGS: null,
    SET_SELECTQUOTESERVICEOPTION: null,
    GRID_COLOR_CHANGED:null,
    NONE: null,
    COMPARE_UPDATE_TAB: null,
    COMPARE_UPDATE_TAB_TO_STATE: null,
    DATAGRAPH_STOCK_CURRENCY_CHANGED: null,
    ENABLE_CURRENCY_CONVERSION: null,
    TOGGLE_OWNERSHIP_TAB:null,
    SYMBOL_CONVERTED_DIALOG_AVAILABLE_CHANGE: null,
    ANIMATION_MESSAGE_DIALOG_AVAILABLE_CHANGE: null,
    SYMBOL_CHANGE_EVENT: null,
    GET_CURRENT_APPVERSION: null
  }),
  KeyDownActiveZoneType: keyMirror({
    FUNDAMENTAL_TAB: null,
    RIPANEL_NEWS: null,
  })
};
