const { ActionTypes } = customTIModalConstants;
import BlockType from "../../Constants/BlockType";
import ConsoleStore from 'ConsoleStore';
import { CustomTiDialogConstant } from "../../Constants/CustomTiDialogConstants";
import { customTIModalConstants } from "../../Constants/TabDataGraphConstants";
import { dispatch } from "../../Redux/dispatch";
import FeatureModuleType from "../../Constants/FeatureModuleType";
import { getTiModuleState } from '../../Reducers/CustomTiChartReducer/selectors';
import GraphType from "GraphType";
import { kpiAppearanceChanges } from "../../Actions/KpiGraphActions";
import KpiIndicatorTabDO from "../../Utils/KpiIndicatorTabDO";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import { MajorPeriodicityConst } from "../../Constants/GraphType";
import PeriodicityHelper from "PeriodicityHelper";
import { priceChartReducerselect } from '../../Reducers/NavDataGraph/TabDataGraph/selectors';
import Serializable from "Serializable";
import SettingsStore from "SettingsStore";
import SmartViewType from "../../Constants/SmartViewType";
import StringUtil from "StringUtil";
import TabDataGraphAction from "../../Actions/TabDataGraphAction";
import TabDataGraphStore from "../../Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore";
import TechIndicatorTabDO from "../../Utils/TechIndicatorTabDO";
import TechnicalIndicators from "TechnicalIndicator";
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper, MajorPeriodicityTranslateHelper, ORatingTranslateHelper } from "../../Utils/TranslateHelper";
import { map, each, find, clone, extend, isMatch } from "underscore";
import { prepapreADIndicatorData, prepapreFundamentalIndicatorData, prepapreMACDIndicatorData, prepapreORatingIndicatorData, prepapreRSIIndicatorData, prepapreSTATSIndicatorData } from "../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";
import { takeLatest, call, put, select } from 'redux-saga/effects';

function* updateClassName(className) {
    yield put({
        type: ActionTypes.UPDATE_CLASSNAME,
        className: className
    });
}

function getModuleType(selectedTiTab) {
    switch (selectedTiTab) {
        case IndicatorLabelTranslateHelper[BlockType.PTOE]:
            return "PTOE";
        case IndicatorLabelTranslateHelper[BlockType.PTOS]:
            return "PTOS";
        case IndicatorLabelTranslateHelper[BlockType.YTD]:
            return "YTD";
        case `1-${IndicatorCommonTranslateHelper["Year"]}`:
            return "TIYRP1";
        case `3-${IndicatorCommonTranslateHelper["Year"]}`:
            return "TIYRP3";
        case `5-${IndicatorCommonTranslateHelper["Year"]}`:
            return "TIYRP5";
        case `10-${IndicatorCommonTranslateHelper["Year"]}`:
            return "TIYRP1";
        default:
            return "";
    }
}

function* getAboutDialogInfo(selectedTiTab, modalState) {
    if (modalState.isAboutDialog) {
        const moduleType = yield call(getModuleType, selectedTiTab)
        if (moduleType) {
            const info = find(ConsoleStore.infoList, (item) => {
                if (item.featureCode === FeatureModuleType[moduleType]) {
                    return item;
                }
            });
            return { title: info.name, url: info.url };
        }
    }
}

function updateModalStatus(selectedOption) {
    let isSettingsDialog, isAppearanceDialog, isAboutDialog;
    switch (selectedOption) {
        case IndicatorCommonTranslateHelper[TechnicalIndicators.Settings]:
            isSettingsDialog = true;
            isAppearanceDialog = false;
            isAboutDialog = false;
            break;
        case IndicatorCommonTranslateHelper[TechnicalIndicators.Appearance]:
            isSettingsDialog = false;
            isAppearanceDialog = true;
            isAboutDialog = false;
            break;
        case IndicatorCommonTranslateHelper[TechnicalIndicators.About]:
            isSettingsDialog = false;
            isAppearanceDialog = false;
            isAboutDialog = true;
            break;
        default:
            break;
    }
    return { isSettingsDialog, isAppearanceDialog, isAboutDialog };
}

function getPeriodicityHeaders(selectedTiTab) {
    const obj = [];
    let Intraday, Daily, Weekly, Monthly, Quarterly, Annual;
    switch (selectedTiTab) {
        case IndicatorLabelTranslateHelper[BlockType.EPSR]:
            Daily = new TechIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
            Weekly = new TechIndicatorTabDO(GraphType.Weekly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY]);
            Monthly = new TechIndicatorTabDO(GraphType.Monthly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY]);
            obj.push(Daily, Weekly, Monthly);
            break;
        case IndicatorLabelTranslateHelper[BlockType.TechIndicator]:
            Daily = new TechIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
            Weekly = new TechIndicatorTabDO(GraphType.Weekly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY]);
            Monthly = new TechIndicatorTabDO(GraphType.Monthly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY]);
            Quarterly = new TechIndicatorTabDO(GraphType.Quarterly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY]);
            Annual = new TechIndicatorTabDO(GraphType.Annual, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL]);
            obj.push(Daily, Weekly, Monthly, Quarterly, Annual);
            break;
        case IndicatorLabelTranslateHelper[BlockType.MACD]:
            Intraday = new TechIndicatorTabDO(GraphType.Intraday, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.INTRADAY]);
            Daily = new TechIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
            Weekly = new TechIndicatorTabDO(GraphType.Weekly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY]);
            Monthly = new TechIndicatorTabDO(GraphType.Monthly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY]);
            Quarterly = new TechIndicatorTabDO(GraphType.Quarterly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY]);
            Annual = new TechIndicatorTabDO(GraphType.Annual, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL]);
            obj.push(Intraday, Daily, Weekly, Monthly, Quarterly, Annual);
            break;
        case IndicatorLabelTranslateHelper[BlockType.RSI]:
            Intraday = new TechIndicatorTabDO(GraphType.Intraday, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.INTRADAY]);
            Daily = new TechIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
            Weekly = new TechIndicatorTabDO(GraphType.Weekly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY]);
            Monthly = new TechIndicatorTabDO(GraphType.Monthly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY]);
            Quarterly = new TechIndicatorTabDO(GraphType.Quarterly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY]);
            Annual = new TechIndicatorTabDO(GraphType.Annual, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL]);
            obj.push(Intraday, Daily, Weekly, Monthly, Quarterly, Annual);
            break;
        case IndicatorLabelTranslateHelper[BlockType.Stochastics]:
        case IndicatorLabelTranslateHelper[BlockType.WonStochastics]:
            Intraday = new TechIndicatorTabDO(GraphType.Intraday, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.INTRADAY]);
            Daily = new TechIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
            Weekly = new TechIndicatorTabDO(GraphType.Weekly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY]);
            Monthly = new TechIndicatorTabDO(GraphType.Monthly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY]);
            Quarterly = new TechIndicatorTabDO(GraphType.Quarterly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY]);
            Annual = new TechIndicatorTabDO(GraphType.Annual, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL]);
            obj.push(Intraday, Daily, Weekly, Monthly, Quarterly, Annual);
            break;
        case IndicatorLabelTranslateHelper[BlockType.PTOE]:
            Daily = new TechIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
            Weekly = new TechIndicatorTabDO(GraphType.Weekly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY]);
            Monthly = new TechIndicatorTabDO(GraphType.Monthly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY]);
            Quarterly = new TechIndicatorTabDO(GraphType.Quarterly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY]);
            Annual = new TechIndicatorTabDO(GraphType.Annual, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL]);
            obj.push(Daily, Weekly, Monthly, Quarterly, Annual);
            break;
        case IndicatorLabelTranslateHelper[BlockType.PTOS]:
            Daily = new TechIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
            Weekly = new TechIndicatorTabDO(GraphType.Weekly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY]);
            Monthly = new TechIndicatorTabDO(GraphType.Monthly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY]);
            Quarterly = new TechIndicatorTabDO(GraphType.Quarterly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY]);
            Annual = new TechIndicatorTabDO(GraphType.Annual, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL]);
            obj.push(Daily, Weekly, Monthly, Quarterly, Annual);
            break;
        case IndicatorLabelTranslateHelper[BlockType.AccDist]:
            Daily = new TechIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
            obj.push(Daily);
            break;
        default:
            break;
    }
    return obj;
}

function getKpiPeriodicityHeaders() {
    const kpiObj = [];
    const Intraday = new KpiIndicatorTabDO(GraphType.Intraday, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.INTRADAY]);
    const Daily = new KpiIndicatorTabDO(GraphType.Daily, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.DAILY]);
    const Weekly = new KpiIndicatorTabDO(GraphType.Weekly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.WEEKLY]);
    const Monthly = new KpiIndicatorTabDO(GraphType.Monthly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.MONTHLY]);
    const Quarterly = new KpiIndicatorTabDO(GraphType.Quarterly, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.QTRLY]);
    const Annual = new KpiIndicatorTabDO(GraphType.Annual, MajorPeriodicityTranslateHelper[MajorPeriodicityConst.ANNUAL]);
    kpiObj.push(Intraday, Daily, Weekly, Monthly, Quarterly, Annual);
    return kpiObj;
}

function bindMenuObj(menu, obj, activePeriodicityTab) {
    map(menu, (periodicity) => {
        if (activePeriodicityTab.TabType === periodicity.TabType) {
            periodicity.menuObj = obj;
        }
    });
    return menu;
}

function* getESTRevisionsMenu(symbolType, modalType, EPSRChartSettings, majorPeriodicity, isRestoring) {
    const EPSRSetting = EPSRChartSettings[majorPeriodicity];
    const menuItem = [];

    switch (symbolType) {
        case SmartViewType.ETF:
        case SmartViewType.CEF:
        case SmartViewType.INDEX:
        case SmartViewType.FUND:
        case SmartViewType.INDUSTRYGROUP:
            break;
        case SmartViewType.STOCK:
            {
                if (modalType.isSettingsDialog) {
                    yield call(updateClassName, "estimate-revisions-settings");

                    for (const index in EPSRSetting) {
                        let itemValue, header = "";

                        switch (index) {
                            case CustomTiDialogConstant.CurrentFiscalYear:
                                itemValue = !isRestoring ? EPSRSetting[index] : EPSRSetting.getDefaultCurrentFiscalYear();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalYear];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            case CustomTiDialogConstant.NextFiscalYear:
                                itemValue = !isRestoring ? EPSRSetting[index] : EPSRSetting.getDefaultNextFiscalYear();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.NextFiscalYear];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            case CustomTiDialogConstant.CurrentFiscalQuarter:
                                itemValue = !isRestoring ? EPSRSetting[index] : EPSRSetting.getDefaultCurrentFiscalQuarter();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalQuarter];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            default:
                                break;
                        }
                    }
                }
                else if (modalType.isAppearanceDialog) {
                    yield call(updateClassName, "estimate-revisions-appearance");

                    for (const index in EPSRSetting) {
                        let itemValue, header = "";

                        switch (index) {
                            case CustomTiDialogConstant.CurrentFiscalYearColor:
                                itemValue = !isRestoring ? EPSRSetting[index] : EPSRSetting.getDefaultCurrentFiscalYearObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalYear];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.NextFiscalYearColor:
                                itemValue = !isRestoring ? EPSRSetting[index] : EPSRSetting.getDefaultNextFiscalYearObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.NextFiscalYear];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.CurrentFiscalQuarterColor:
                                itemValue = !isRestoring ? EPSRSetting[index] : EPSRSetting.getDefaultCurrentFiscalQuarterObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.CurrentFiscalQuarter];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            break;
        default:
            break;
    }

    return { menuItem };
}

function* getOneilRtgsMenu(symbolType, modalType, TechChartSettings, majorPeriodicity, isRestoring) {
    const TechSettings = TechChartSettings[majorPeriodicity];
    const menuItem = [];
    let defaultDataGraph, defaultRs3Months, defaultRs12Months, isApplyToAllIntervals;

    if (modalType.isSettingsDialog) {
        yield call(updateClassName, "oneil-ratings-settings");

        if (isRestoring) {
            if (symbolType === SmartViewType.STOCK) {
                defaultDataGraph = true;
            }
            else {
                defaultDataGraph = TechSettings.getDefaultDataGraph();
            }
            if (symbolType === SmartViewType.ETF || symbolType === SmartViewType.CEF) {
                defaultRs3Months = true;
                defaultRs12Months = true;
            }
            else {
                defaultRs3Months = TechSettings.getDefaultRs3Months();
                defaultRs12Months = TechSettings.getDefaultRs12Months();
            }
        }

        for (const index in TechSettings) {
            let itemValue, header = "";

            switch (index) {
                case CustomTiDialogConstant.Rs3Months:
                    itemValue = !isRestoring ? TechSettings[index] : defaultRs3Months;
                    header = ORatingTranslateHelper[CustomTiDialogConstant.Rs3Months];
                    menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    break;
                case CustomTiDialogConstant.Rs6Months:
                    itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultRs6Months();
                    header = ORatingTranslateHelper[CustomTiDialogConstant.Rs6Months];
                    menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    break;
                case CustomTiDialogConstant.Rs12Months:
                    itemValue = !isRestoring ? TechSettings[index] : defaultRs12Months;
                    header = ORatingTranslateHelper[CustomTiDialogConstant.Rs12Months];
                    menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    break;
                case CustomTiDialogConstant.GrpRs3Months:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultGrpRs3Months();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.GrpRs3Months];
                        menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    }
                    break;
                case CustomTiDialogConstant.GrpRs6Months:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultGrpRs6Months();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.GrpRs6Months];
                        menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    }
                    break;
                case CustomTiDialogConstant.Composite:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultComposite();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.Composite];
                        menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    }
                    break;
                case CustomTiDialogConstant.DataGraph:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : defaultDataGraph;
                        header = ORatingTranslateHelper[CustomTiDialogConstant.DataGraph];
                        menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    }
                    break;
                case CustomTiDialogConstant.EpsRank:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultEpsRank();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.EpsRank];
                        menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    }
                    break;
                case CustomTiDialogConstant.SMR:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultSMR();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.SMR];
                        menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    }
                    break;
                case CustomTiDialogConstant.Sponsorship:
                    itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultSponsorship();
                    header = ORatingTranslateHelper[CustomTiDialogConstant.Sponsorship];
                    menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    break;
                case CustomTiDialogConstant.InstSupDem:
                    itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultInstSupDem();
                    header = ORatingTranslateHelper[CustomTiDialogConstant.InstSupDem];
                    menuItem.push({ header: header, isActive: itemValue, itemName: index });
                    break;
                case "isApplyToAllIntervals":
                    isApplyToAllIntervals = TechSettings[index];
                    break;
                default:
                    break;
            }
        }
    }
    else if (modalType.isAppearanceDialog) {
        yield call(updateClassName, "oneil-ratings-appearance");

        for (const index in TechSettings) {
            let itemValue, header = "";

            switch (index) {
                case CustomTiDialogConstant.Rs3MonthsColor:
                    itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultRs3MonthsColorObj();
                    header = ORatingTranslateHelper[CustomTiDialogConstant.Rs3Months];
                    itemValue.forEach((item) => {
                        menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                    });
                    break;
                case CustomTiDialogConstant.Rs6MonthsColor:
                    itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultRs6MonthsColorObj();
                    header = ORatingTranslateHelper[CustomTiDialogConstant.Rs6Months];
                    itemValue.forEach((item) => {
                        menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                    });
                    break;
                case CustomTiDialogConstant.Rs12MonthsColor:
                    itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultRs12MonthsColorObj();
                    header = ORatingTranslateHelper[CustomTiDialogConstant.Rs12Months];
                    itemValue.forEach((item) => {
                        menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                    });
                    break;
                case CustomTiDialogConstant.GrpRs3MonthsColor:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultGrpRs3MonthsColorObj();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.GrpRs3Months];
                        itemValue.forEach((item) => {
                            menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                        });
                    }
                    break;
                case CustomTiDialogConstant.GrpRs6MonthsColor:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultGrpRs6MonthsColorObj();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.GrpRs6Months];
                        itemValue.forEach((item) => {
                            menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                        });
                    }
                    break;
                case CustomTiDialogConstant.CompositeColor:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultCompositeColorObj();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.Composite];
                        itemValue.forEach((item) => {
                            menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                        });
                    }
                    break;
                case CustomTiDialogConstant.DataGraphColor:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultDataGraphColorObj();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.DataGraph];
                        itemValue.forEach((item) => {
                            menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                        });
                    }
                    break;
                case CustomTiDialogConstant.EpsRankColor:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultEpsRankColorObj();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.EpsRank];
                        itemValue.forEach((item) => {
                            menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                        });
                    }
                    break;
                case CustomTiDialogConstant.SMRColor:
                    if (symbolType === SmartViewType.STOCK) {
                        itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultSMRColorObj();
                        header = ORatingTranslateHelper[CustomTiDialogConstant.SMR];
                        itemValue.forEach((item) => {
                            menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                        });
                    }
                    break;
                case CustomTiDialogConstant.SponsorshipColor:
                    itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultSponsorshipColorObj();
                    header = ORatingTranslateHelper[CustomTiDialogConstant.Sponsorship];
                    itemValue.forEach((item) => {
                        menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                    });
                    break;
                case CustomTiDialogConstant.InstSupDemColor:
                    itemValue = !isRestoring ? TechSettings[index] : TechSettings.getDefaultInstSupDemColorObj();
                    header = ORatingTranslateHelper[CustomTiDialogConstant.InstSupDem];
                    itemValue.forEach((item) => {
                        menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                    });
                    break;
                default:
                    break;
            }
        }
    }

    return { menuItem, isApplyToAllIntervals };
}

function* getMacdMenu(symbolType, modalType, MacdChartSettings, majorPeriodicity, isRestoring) {
    const MacdSetting = MacdChartSettings[majorPeriodicity];
    const menuItem = [];
    let isApplyToAllIntervals;

    switch (symbolType) {
        case SmartViewType.ETF:
        case SmartViewType.CEF:
        case SmartViewType.INDEX:
        case SmartViewType.STOCK:
        case SmartViewType.FUND:
        case SmartViewType.INDUSTRYGROUP:
            {
                if (modalType.isSettingsDialog) {
                    yield call(updateClassName, "macd-settings");

                    for (const index in MacdSetting) {
                        let itemValue, header = "", range;

                        switch (index) {
                            case CustomTiDialogConstant.FastLength:
                                itemValue = !isRestoring ? MacdSetting[index] : MacdSetting.getDefaultFastLength();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.FastLength];
                                range = "(1-200)";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case CustomTiDialogConstant.SlowLength:
                                itemValue = !isRestoring ? MacdSetting[index] : MacdSetting.getDefaultSlowLength();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.SlowLength];
                                range = "(1-200)";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case CustomTiDialogConstant.EMA:
                                itemValue = !isRestoring ? MacdSetting[index] : MacdSetting.getDefaultEMA();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.EMA];
                                range = "(0-200)";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case "isApplyToAllIntervals":
                                isApplyToAllIntervals = MacdSetting[index];
                                break;
                            default:
                                break;
                        }
                    }
                }
                else if (modalType.isAppearanceDialog) {
                    yield call(updateClassName, "macd-appearance");

                    for (const index in MacdSetting) {
                        let itemValue, header = "";

                        switch (index) {
                            case CustomTiDialogConstant.MACDLineColor:
                                itemValue = !isRestoring ? MacdSetting[index] : MacdSetting.getDefaultMACDLineObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.MACDLineColor];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.MovingAverageColor:
                                itemValue = !isRestoring ? MacdSetting[index] : MacdSetting.getDefaultMovingAverageObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.MovingAverageColor];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            break;
        default:
            break;
    }

    return { menuItem, isApplyToAllIntervals };
}

function* getRsiMenu(symbolType, modalType, RsiChartSettings, majorPeriodicity, isRestoring) {
    const RsiSetting = RsiChartSettings[majorPeriodicity];
    const menuItem = [];
    let isApplyToAllIntervals;

    switch (symbolType) {
        case SmartViewType.ETF:
        case SmartViewType.CEF:
        case SmartViewType.INDEX:
        case SmartViewType.STOCK:
        case SmartViewType.FUND:
        case SmartViewType.INDUSTRYGROUP:
            {
                if (modalType.isSettingsDialog) {
                    yield call(updateClassName, "relative-strength-indicator-settings");

                    for (const index in RsiSetting) {
                        let itemValue, header = "", range = "";

                        switch (index) {
                            case CustomTiDialogConstant.Length:
                                itemValue = !isRestoring ? RsiSetting[index] : RsiSetting.getDefaultLength();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Length];
                                range = "(1-200)";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case CustomTiDialogConstant.Overbought:
                                itemValue = !isRestoring ? RsiSetting[index] : RsiSetting.getDefaultOverbought();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought];
                                range = "";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case CustomTiDialogConstant.Oversold:
                                itemValue = !isRestoring ? RsiSetting[index] : RsiSetting.getDefaultOversold();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold];
                                range = "";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case "isApplyToAllIntervals":
                                isApplyToAllIntervals = RsiSetting[index];
                                break;
                            default:
                                break;
                        }
                    }
                }
                else if (modalType.isAppearanceDialog) {
                    yield call(updateClassName, "relative-strength-indicator-appearance");

                    for (const index in RsiSetting) {
                        let itemValue, header = "";

                        switch (index) {
                            case CustomTiDialogConstant.RSILineColor:
                                itemValue = !isRestoring ? RsiSetting[index] : RsiSetting.getDefaultRSILineObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.RSILineColor];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                })

                                break;
                            case CustomTiDialogConstant.OverboughtColor:
                                itemValue = !isRestoring ? RsiSetting[index] : RsiSetting.getDefaultOverboughtObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                })
                                break;
                            case CustomTiDialogConstant.OversoldColor:
                                itemValue = !isRestoring ? RsiSetting[index] : RsiSetting.getDefaultOversoldObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                })
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            break;
        default:
            break;
    }

    return { menuItem, isApplyToAllIntervals };
}
function* getKpiMenu(symbolType, modalType, KpiChartSettings, majorPeriodicity, isRestoring = false, header = '') {
    const KpiSetting = KpiChartSettings[majorPeriodicity];
    const menuItem = [];
    switch (symbolType) {
        case SmartViewType.STOCK:
            {
                if (modalType.isAppearanceDialog) {
                    yield call(updateClassName, "kpi-appearance");
                    for (const index in KpiSetting) {
                        let itemValue = "";
                        switch (index) {
                            case "KpiColor":
                                itemValue = !isRestoring ? KpiSetting[index] : KpiSetting.getDefaultKpiLineObj();
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header ? header : modalType.title, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            break;
        default:
            break;
    }

    return { menuItem };
}

function* getStochasticsMenu(symbolType, modalType, StochasticsChartSettings, majorPeriodicity, isRestoring) {
    const StochasticsSetting = StochasticsChartSettings[majorPeriodicity];
    const menuItem = [];
    let isApplyToAllIntervals;

    switch (symbolType) {
        case SmartViewType.ETF:
        case SmartViewType.CEF:
        case SmartViewType.INDEX:
        case SmartViewType.STOCK:
        case SmartViewType.FUND:
        case SmartViewType.INDUSTRYGROUP:
            {
                if (modalType.isSettingsDialog) {
                    yield call(updateClassName, "stochastics-settings");

                    for (const index in StochasticsSetting) {
                        let itemValue, header = "", range = "";

                        switch (index) {
                            case CustomTiDialogConstant.Length:
                                itemValue = !isRestoring ? StochasticsSetting[index] : StochasticsSetting.getDefaultLength();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Length];
                                range = "(1-200)";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case CustomTiDialogConstant.MovingAverage:
                                itemValue = !isRestoring ? StochasticsSetting[index] : StochasticsSetting.getDefaultMovingAverage();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.MovingAverage];
                                range = "(0-200)";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break
                            case CustomTiDialogConstant.Overbought:
                                itemValue = !isRestoring ? StochasticsSetting[index] : StochasticsSetting.getDefaultOverbought();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought];
                                range = "";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case CustomTiDialogConstant.Oversold:
                                itemValue = !isRestoring ? StochasticsSetting[index] : StochasticsSetting.getDefaultOversold();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold];
                                range = "";
                                menuItem.push({ header: header, value: itemValue.toString(), itemName: index, inputRange: range });
                                break;
                            case CustomTiDialogConstant.Slow:
                                itemValue = !isRestoring ? StochasticsSetting[index] : false;
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            case CustomTiDialogConstant.Fast:
                                itemValue = !isRestoring ? StochasticsSetting[index] : true;
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            case "isApplyToAllIntervals":
                                isApplyToAllIntervals = StochasticsSetting[index];
                                break;
                            default:
                                break;
                        }
                    }
                }
                else if (modalType.isAppearanceDialog) {
                    yield call(updateClassName, "stochastics-appearance");

                    for (const index in StochasticsSetting) {
                        let itemValue, header = "";

                        switch (index) {
                            case CustomTiDialogConstant.StochasticsColor:
                                itemValue = !isRestoring ? StochasticsSetting[index] : StochasticsSetting.getDefaultStochasticsLineObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.StochasticsColor];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.MovingAverageColor:
                                itemValue = !isRestoring ? StochasticsSetting[index] : StochasticsSetting.getDefaultMovingAverageObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.StochasticsMovingAverageColor];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.OverboughtColor:
                                itemValue = !isRestoring ? StochasticsSetting[index] : StochasticsSetting.getDefaultOverboughtObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.OversoldColor:
                                itemValue = !isRestoring ? StochasticsSetting[index] : StochasticsSetting.getDefaultOversoldObj();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            break;
        default:
            break;
    }

    return { menuItem, isApplyToAllIntervals };
}

function* getPEMenu(symbolType, modalType, PTOEChartSettings, majorPeriodicity, isRestoring) {
    const PTOESetting = PTOEChartSettings[majorPeriodicity];
    const menuItem = [];

    switch (symbolType) {
        case SmartViewType.ETF:
        case SmartViewType.CEF:
        case SmartViewType.INDEX:
        case SmartViewType.FUND:
        case SmartViewType.INDUSTRYGROUP:
        case SmartViewType.STOCK:
            {
                if (modalType.isSettingsDialog) {
                    yield call(updateClassName, "price-to-earnings-settings");

                    for (const index in PTOESetting) {
                        let itemValue, header = "";
                        switch (index) {
                            case CustomTiDialogConstant.PTOEIndexLine:
                                itemValue = !isRestoring ? PTOESetting[index] : PTOESetting.getDefaultPTOEIndexLine();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOEIndexLine];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            case CustomTiDialogConstant.HLEstimates:
                                itemValue = !isRestoring ? PTOESetting[index] : PTOESetting.getDefaultHLEstimates();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOEHLEstimates];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            default:
                                break;
                        }
                    }
                }
                else if (modalType.isAppearanceDialog) {
                    yield call(updateClassName, "price-to-earnings-appearance");

                    for (const index in PTOESetting) {
                        let itemValue;
                        switch (index) {
                            case CustomTiDialogConstant.StockColor:
                                itemValue = !isRestoring ? PTOESetting[index] : PTOESetting.getDefaultStockColor();
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: IndicatorCommonTranslateHelper["SymbolType_STOCK"], isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.IndexColor:
                                itemValue = !isRestoring ? PTOESetting[index] : PTOESetting.getDefaultIndexColor();
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: IndicatorCommonTranslateHelper["SymbolType_INDEX"], isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            break;
        default:
            break;
    }

    return { menuItem };
}

function* getPSMenu(symbolType, modalType, PTOSChartSettings, majorPeriodicity, isRestoring) {
    const PTOSSetting = PTOSChartSettings[majorPeriodicity];
    const menuItem = [];

    switch (symbolType) {
        case SmartViewType.ETF:
        case SmartViewType.CEF:
        case SmartViewType.INDEX:
        case SmartViewType.FUND:
        case SmartViewType.INDUSTRYGROUP:
        case SmartViewType.STOCK:
            {
                if (modalType.isSettingsDialog) {
                    yield call(updateClassName, "price-to-sales-settings");

                    for (const index in PTOSSetting) {
                        let itemValue, header = "";

                        switch (index) {
                            case CustomTiDialogConstant.PTOSIndexLine:
                                itemValue = !isRestoring ? PTOSSetting[index] : PTOSSetting.getDefaultPTOSIndexLine();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOSIndexLine];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            case CustomTiDialogConstant.HLEstimates:
                                itemValue = !isRestoring ? PTOSSetting[index] : PTOSSetting.getDefaultHLEstimates();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.PTOSHLEstimates];
                                menuItem.push({ header: header, isActive: itemValue, itemName: index });
                                break;
                            default:
                                break;
                        }
                    }
                }
                else if (modalType.isAppearanceDialog) {
                    yield call(updateClassName, "price-to-sales-appearance");

                    for (const index in PTOSSetting) {
                        let itemValue;
                        switch (index) {
                            case CustomTiDialogConstant.StockColor:
                                itemValue = !isRestoring ? PTOSSetting[index] : PTOSSetting.getDefaultStockColor();
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: IndicatorCommonTranslateHelper["SymbolType_STOCK"], isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.IndexColor:
                                itemValue = !isRestoring ? PTOSSetting[index] : PTOSSetting.getDefaultIndexColor();
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: IndicatorCommonTranslateHelper["SymbolType_INDEX"], isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            break;
        default:
            break;
    }
    return { menuItem };
}

function* getAccDistMenu(symbolType, modalType, AccDistChartSettings, majorPeriodicity, isRestoring) {
    const AccDistSetting = AccDistChartSettings[majorPeriodicity];
    const menuItem = [];

    switch (symbolType) {
        case SmartViewType.FUND:
            break;
        case SmartViewType.ETF:
        case SmartViewType.CEF:
        case SmartViewType.INDEX:
        case SmartViewType.INDUSTRYGROUP:
        case SmartViewType.STOCK:
            {
                if (modalType.isSettingsDialog) {
                    return;
                }
                else if (modalType.isAppearanceDialog) {
                    yield call(updateClassName, "accumulation-distribution-appearance");

                    for (const index in AccDistSetting) {
                        let itemValue, header = "";

                        switch (index) {
                            case CustomTiDialogConstant.SignalColor:
                                itemValue = !isRestoring ? AccDistSetting[index] : AccDistSetting.getDefaultSignalColor();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.SignalColor];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            case CustomTiDialogConstant.MovingAverageColor:
                                itemValue = !isRestoring ? AccDistSetting[index] : AccDistSetting.getDefaultMovingAverageColor();
                                header = IndicatorCommonTranslateHelper[CustomTiDialogConstant.MovingAverageColor];
                                itemValue.forEach((item) => {
                                    menuItem.push({ header: header, isActive: item.isActive, itemName: index, color: item.color, weight: item.weight });
                                });
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            break;
        default:
            break;
    }
    return { menuItem };
}

function getSttingsType(tabDataGraphSettings, symbolType) {
    let settings;
    switch (symbolType) {
        case SmartViewType.ETF:
            settings = tabDataGraphSettings.ETFViewSettings;
            break;
        case SmartViewType.CEF:
            settings = tabDataGraphSettings.CEFViewSettings;
            break;
        case SmartViewType.INDEX:
            settings = tabDataGraphSettings.IndexViewSettings;
            break;
        case SmartViewType.STOCK:
            settings = tabDataGraphSettings.StockViewSettings
            break;
        case SmartViewType.FUND:
            settings = tabDataGraphSettings.FUNDViewSettings;
            break;
        case SmartViewType.INDUSTRYGROUP:
            settings = tabDataGraphSettings.IndustryGroupViewSettings;
            break;
        default:
            break;
    }
    return settings;
}

function* getKpiMenuObj(selectedKpiTab, modalType, isRestoring = false) {
    const state = TabDataGraphStore.getState();
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);

    const settings = yield call(getSttingsType, tabDataGraphSettings, state.SymbolType);
    const obj = yield call(getKpiMenu, state.SymbolType, modalType, settings.KpiSettings[selectedKpiTab.KpiId], majorPeriodicity, isRestoring, selectedKpiTab.Header);

    return Object.assign({}, obj);
}
function* getMenuObj(selectedTiTab, modalType, periodicity, isRestoring = false) {
    const state = TabDataGraphStore.getState();
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const majorPeriodicity = periodicity ? PeriodicityHelper.mapMajorPeriodicities(periodicity.TabType) : PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
    let obj;

    const settings = yield call(getSttingsType, tabDataGraphSettings, state.SymbolType);

    if (!isNaN(selectedTiTab)) {
        obj = yield call(getKpiMenu, state.SymbolType, modalType, settings.KpiSettings[selectedTiTab], majorPeriodicity, isRestoring);
    }

    switch (selectedTiTab) {
        case IndicatorLabelTranslateHelper[BlockType.EPSR]:
            obj = yield call(getESTRevisionsMenu, state.SymbolType, modalType, settings.EPSRChartSettings, majorPeriodicity, isRestoring);
            break;
        case IndicatorLabelTranslateHelper[BlockType.TechIndicator]:
            obj = yield call(getOneilRtgsMenu, state.SymbolType, modalType, settings.TechChartSettings, majorPeriodicity, isRestoring);
            break;
        case IndicatorLabelTranslateHelper[BlockType.MACD]:
            obj = yield call(getMacdMenu, state.SymbolType, modalType, settings.MacdChartSettings, majorPeriodicity, isRestoring);
            break;
        case IndicatorLabelTranslateHelper[BlockType.RSI]:
            obj = yield call(getRsiMenu, state.SymbolType, modalType, settings.RsiChartSettings, majorPeriodicity, isRestoring);
            break;
        case IndicatorLabelTranslateHelper[BlockType.Stochastics]:
            obj = yield call(getStochasticsMenu, state.SymbolType, modalType, settings.StochasticsChartSettings, majorPeriodicity, isRestoring);
            break;
        case IndicatorLabelTranslateHelper[BlockType.WonStochastics]:
            obj = yield call(getStochasticsMenu, state.SymbolType, modalType, settings.WonStochasticsChartSettings, majorPeriodicity, isRestoring);
            break;
        case IndicatorLabelTranslateHelper[BlockType.PTOE]:
            obj = yield call(getPEMenu, state.SymbolType, modalType, settings.PTOEChartSettings, majorPeriodicity, isRestoring);
            break;
        case IndicatorLabelTranslateHelper[BlockType.PTOS]:
            obj = yield call(getPSMenu, state.SymbolType, modalType, settings.PTOSChartSettings, majorPeriodicity, isRestoring);
            break;
        case IndicatorLabelTranslateHelper[BlockType.AccDist]:
            obj = yield call(getAccDistMenu, state.SymbolType, modalType, settings.AccDistChartSettings, majorPeriodicity, isRestoring);
            break;
        default:
            break;
    }
    return Object.assign({}, obj);
}

function getActivePeriodicityTab(menu) {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;

    const activeTab = find(menu, (key) =>
        key.TabType === PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity));
    return activeTab;
}

function* getUpdatedObject(isActive, item, color, weight) {
    const TiModuleState = yield select(getTiModuleState);
    let menu = clone(TiModuleState.menu);
    const activeTabMenu = TiModuleState.activePeriodicityTab.menuObj;

    TiModuleState.isSettingsDialog ?
        map(activeTabMenu, (menuItem) => {
            if (menuItem.header === item.header) {
                menuItem.isActive = isActive;
            }
            else if (item.header === IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow] && menuItem.header === IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast]) {
                menuItem.isActive = false;
            }
            else if (item.header === IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast] && menuItem.header === IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow]) {
                menuItem.isActive = false;
            }
        })
        :
        map(activeTabMenu, (menuItem) => {
            if (menuItem.header === item.header) {
                menuItem.isActive = isActive;
                if (color) {
                    menuItem.color = color;
                }
                if (weight) {
                    menuItem.weight = weight;
                }
            }
            else {
                menuItem.isActive = false;
            }
        });

    if (TiModuleState.isSettingsDialog && TiModuleState.activePeriodicityTab.isApplyToAllIntervals) {
        for (const periodicityTab of menu) {
            const updatedObj = yield call(updateAllIntervals, periodicityTab.menuObj, activeTabMenu);
            extend(periodicityTab, { menuObj: updatedObj });
        }
        return menu;
    }
    return (menu = yield call(bindMenuObj, menu, activeTabMenu, TiModuleState.activePeriodicityTab));
}

function* updateToSettings(activeTabMenu, settingsObj, isSettingsDialog) {
    const TiModuleState = yield select(getTiModuleState);
    each(activeTabMenu.menuObj, (item) => {
        map(settingsObj, (value, index) => {
            if (index === item.itemName) {
                if (isSettingsDialog) {
                    settingsObj[index] = item.value ? Number(item.value) : item.isActive;
                }
                else {
                    settingsObj[index].forEach((settingsItem) => {
                        item.value ? settingsItem.value = Number(item.value) : settingsItem.isActive = item.isActive;
                        settingsItem.color = item.color;
                        settingsItem.weight = item.weight;
                    });
                    settingsObj[index].map((item) => Object.assign(new Serializable, { ...item }));
                }
            }
            else if (isSettingsDialog && index === "isApplyToAllIntervals") {
                settingsObj[index] = activeTabMenu.isApplyToAllIntervals;
            }
        });
    });

    if (TiModuleState.isRestoring) {
        if (settingsObj.IsRelative !== undefined) {
            settingsObj.IsRelative = false;
        }
    }
    SettingsStore.saveSettings();
}

function* reRenderChanges(selectedTiTab) {
    if (!isNaN(selectedTiTab)) {
        dispatch(kpiAppearanceChanges());
    }
    switch (selectedTiTab) {
        case IndicatorLabelTranslateHelper[BlockType.EPSR]:
            yield put(prepapreFundamentalIndicatorData(null, BlockType.EPSR));
            TabDataGraphAction.onTechChanged(BlockType.EPSR);
            break;
        case IndicatorLabelTranslateHelper[BlockType.TechIndicator]:
            yield put(prepapreORatingIndicatorData(null, BlockType.TechIndicator));
            TabDataGraphAction.onTechChanged(BlockType.TechIndicator);
            break;
        case IndicatorLabelTranslateHelper[BlockType.MACD]:
            yield put(prepapreMACDIndicatorData(null, BlockType.MACD));
            TabDataGraphAction.onMacdChartChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.RSI]:
            yield put(prepapreRSIIndicatorData(null, BlockType.RSI));
            TabDataGraphAction.onRsiChartChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.Stochastics]:
            yield put(prepapreSTATSIndicatorData(null, BlockType.Stochastics));
            TabDataGraphAction.onStochasticsChartChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.WonStochastics]:
            yield put(prepapreSTATSIndicatorData(null, BlockType.WonStochastics));
            TabDataGraphAction.onStochasticsChartChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.PTOE]:
            yield put(prepapreFundamentalIndicatorData(null, BlockType.PTOE))
            TabDataGraphAction.onPTOEChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.PTOS]:
            yield put(prepapreFundamentalIndicatorData(null, BlockType.PTOS));
            TabDataGraphAction.onPTOSChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.AccDist]:
            yield put(prepapreADIndicatorData(null, BlockType.AccDist));
            TabDataGraphAction.onAccDistChanged();
        default:
            break;
    }
}
// function* updateKpiSettings(KpiSetting,majorPeriodicity,activeTabMenu, KpiId) {
//     const currSettings = clone(KpiSetting[KpiId][majorPeriodicity].KpiColor);
//     currSettings[0].color = activeTabMenu.menuObj[0].color;
//     currSettings[0].weight = activeTabMenu.menuObj[0].weight;
//     currSettings[0].isActive = activeTabMenu.menuObj[0].isActive;
//     KpiSetting[KpiId][majorPeriodicity].KpiColor = Object.assign(new Serializable, { ...currSettings });
// }
function* saveObject(selectedTiTab, activePeriodicityTab, isSettingsDialog) {
    const state = TabDataGraphStore.getState();
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(activePeriodicityTab.TabType);
    const activeTabMenu = activePeriodicityTab;
    let settingsObj;

    const settings = yield call(getSttingsType, tabDataGraphSettings, state.SymbolType);
    if (!isNaN(selectedTiTab)) {
        const currentPeriodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
        if (activePeriodicityTab.TabType === currentPeriodicity) {
            settingsObj = clone(settings.KpiSettings[selectedTiTab][majorPeriodicity]);
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
        }
    }
    switch (selectedTiTab) {
        case IndicatorLabelTranslateHelper[BlockType.EPSR]:
            settingsObj = settings.EPSRChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
            //TabDataGraphAction.onTechChanged(null);
            break;
        case IndicatorLabelTranslateHelper[BlockType.TechIndicator]:
            settingsObj = settings.TechChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
            //TabDataGraphAction.onTechChanged(null);
            break;
        case IndicatorLabelTranslateHelper[BlockType.MACD]:
            settingsObj = settings.MacdChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
            //TabDataGraphAction.onMacdChartChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.RSI]:
            settingsObj = settings.RsiChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
            //TabDataGraphAction.onRsiChartChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.Stochastics]:
            settingsObj = settings.StochasticsChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
            //TabDataGraphAction.onStochasticsChartChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.WonStochastics]:
            settingsObj = settings.WonStochasticsChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
            //TabDataGraphAction.onStochasticsChartChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.PTOE]:
            settingsObj = settings.PTOEChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
            //TabDataGraphAction.onPTOEChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.PTOS]:
            settingsObj = settings.PTOSChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
            //TabDataGraphAction.onPTOSChanged();
            break;
        case IndicatorLabelTranslateHelper[BlockType.AccDist]:
            settingsObj = settings.AccDistChartSettings[majorPeriodicity];
            yield call(updateToSettings, activeTabMenu, settingsObj, isSettingsDialog);
        //TabDataGraphAction.onAccDistChanged();
        default:
            break;
    }
}

function* unSaveObject(TiModuleState) {
    const { menuItem } = yield call(getMenuObj, TiModuleState.selectedTiTab, TiModuleState);
    let menu = clone(TiModuleState.menu);

    menu = yield call(bindMenuObj, menu, menuItem, TiModuleState.activePeriodicityTab);

    yield put({
        type: ActionTypes.UPDATE_MENU_OBJECT,
        obj: menu
    });

    yield put({
        type: ActionTypes.VALIDATION_ERROR,
        isInvalidInput: false,
        errorMessage: ""
    });
}

function validateInputValRange(value, item, activeTabMenu) {
    let lowRange = 0, highRange = 0;
    let textRestricted = false;
    value = value ? parseInt(value) : item.value;

    if (item.inputRange) {
        let range = item.inputRange.replace("(", "");
        range = range.replace(")", "");
        range = range.split('-');

        lowRange = parseInt(range[0]);
        highRange = parseInt(range[1]);
    }
    else if (item.itemName === CustomTiDialogConstant.Overbought || item.itemName === CustomTiDialogConstant.Overbought) {
        const overSold = find(activeTabMenu, (element) => element.itemName === CustomTiDialogConstant.Oversold);
        lowRange = parseInt(overSold.value); highRange = 100;
    }
    else if (item.itemName === CustomTiDialogConstant.Oversold || item.itemName === CustomTiDialogConstant.Oversold) {
        const overBought = find(activeTabMenu, (element) => element.itemName === CustomTiDialogConstant.Overbought);
        lowRange = 0; highRange = parseInt(overBought.value);
    }

    if (value < lowRange) {
        textRestricted = true;
    }

    if (value > highRange) {
        textRestricted = true;
    }
    return textRestricted;
}

function* updateInputVal(e, item) {
    const TiModuleState = yield select(getTiModuleState);
    const { pricePanelData, periodicity } = yield select(priceChartReducerselect);
    const menu = clone(TiModuleState.menu);
    const value = e.target.value;
    const isApplyToAllIntervals = TiModuleState.activePeriodicityTab.isApplyToAllIntervals;
    const hsfData = pricePanelData.HsfData.HSFResults;
    const hsfResults = hsfData.filter((t) => t._volume > -1 && t.Close !== undefined);
    let tot = Math.floor(hsfResults.length / 2) + 1;
    if (TiModuleState.activePeriodicityTab.TabType !== periodicity || tot > 200) {
        tot = 200;
    }

    if (item.itemName === CustomTiDialogConstant.Overbought || item.itemName === CustomTiDialogConstant.Oversold && value >= 100) {
        tot = 100;
    }

    if (value > tot) {
        each(menu, (periodicityTab) => {
            if (isMatch(periodicityTab, TiModuleState.activePeriodicityTab)) {
                map(periodicityTab.menuObj, (menuItem) => {
                    if (menuItem.header === item.header) {
                        menuItem.value = tot;
                    }
                });
            }
        });

        yield put({
            type: ActionTypes.VALIDATION_ERROR,
            inputId: parseInt(e.target.id),
            isInvalidInput: true,
            errorMessage: LocalizationStore.getTranslatedData("ch_err2", `Field accepts inputs upto ${tot} only`)
        });
    }
    else if (value && !(/^[0-9]*$/.test(value))) {
        each(menu, (periodicityTab) => {
            if (isMatch(periodicityTab, TiModuleState.activePeriodicityTab)) {
                map(periodicityTab.menuObj, (menuItem) => {
                    if (menuItem.header === item.header) {
                        menuItem.value = '';
                    }
                });
            }
        });

        yield put({
            type: ActionTypes.VALIDATION_ERROR,
            inputId: parseInt(e.target.id),
            isInvalidInput: true,
            errorMessage: IndicatorCommonTranslateHelper["Numeric_Input_Only"]
        });
    }
    else {
        if (isApplyToAllIntervals) {
            each(menu, (periodicityTab) => {
                map(periodicityTab.menuObj, (tempItem) => {
                    if (tempItem.header === item.header) {
                        tempItem.value = value;
                    }
                });
            });
        }
        else {
            each(menu, (periodicityTab) => {
                if (isMatch(periodicityTab, TiModuleState.activePeriodicityTab)) {
                    map(periodicityTab.menuObj, (menuItem) => {
                        if (menuItem.header === item.header) {
                            menuItem.value = value;
                        }
                    });
                }
            });
        }

        yield put({
            type: ActionTypes.VALIDATION_ERROR,
            isInvalidInput: false,
            errorMessage: ""
        });
    }

    yield put({
        type: ActionTypes.ON_INPUT_VALIDATION_SUCCESS,
        isTextRestricted: false,
        restrictedItem: e.target
    });

    return menu;
}

function* validateAllInputs(TiModuleState) {
    let menu = clone(TiModuleState.menu);
    const activeTabMenu = TiModuleState.activePeriodicityTab.menuObj;
    let isTextRestricted = false;
    let restrictedItem = "";

    for (const periodicityTab of menu) {
        for (const item of periodicityTab.menuObj) {
            isTextRestricted = yield call(validateInputValRange, null, item, periodicityTab.menuObj);
            if (isTextRestricted) {
                restrictedItem = item;
                break;
            }
        }
        if (isTextRestricted) {
            break;
        }
    }
    if (isTextRestricted) {
        if (TiModuleState.activePeriodicityTab.isApplyToAllIntervals) {
            for (const periodicityTab of menu) {
                const { menuItem } = yield call(getMenuObj, TiModuleState.selectedTiTab, TiModuleState, periodicityTab);
                const prevObj = menuItem;

                map(periodicityTab.menuObj, (menuItem) => {
                    if (menuItem.header === restrictedItem.header) {
                        map(prevObj, (prevMenuItem) => {
                            if (menuItem.header === prevMenuItem.header) {
                                menuItem.value = prevMenuItem.value;
                            }
                        });
                    }
                });
            }
        }
        else {
            const { menuItem } = yield call(getMenuObj, TiModuleState.selectedTiTab, TiModuleState, TiModuleState.activePeriodicityTab);
            const prevObj = menuItem;

            map(activeTabMenu, (menuItem) => {
                if (menuItem.header === restrictedItem.header) {
                    map(prevObj, (prevMenuItem) => {
                        if (menuItem.header === prevMenuItem.header) {
                            menuItem.value = prevMenuItem.value;
                        }
                    });
                }
            });
            menu = yield call(bindMenuObj, menu, activeTabMenu, TiModuleState.activePeriodicityTab);
        }
    }
    return menu;
}

function updateAllIntervals(tempMenuObj, activeMenuObj) {
    each(activeMenuObj, (activeItem) => {
        map(tempMenuObj, (tempItem) => {
            if (tempItem.header === activeItem.header) {
                if (tempItem.value !== undefined) {
                    tempItem.value = activeItem.value;
                }
                else if (tempItem.isActive !== undefined) {
                    tempItem.isActive = activeItem.isActive;
                }
            }
        });
    });
    return tempMenuObj;
}

function getDialogTitle(selectedTiTab, modalState) {
    switch (selectedTiTab) {
        case IndicatorLabelTranslateHelper[BlockType.EPSR]:
            return "ESTIMATE REVISIONS"
        case IndicatorLabelTranslateHelper[BlockType.TechIndicator]:
            return "O'NEIL RATINGS";
        case IndicatorLabelTranslateHelper[BlockType.MACD]:
            return "MACD";
        case IndicatorLabelTranslateHelper[BlockType.RSI]:
            if (modalState.isSettingsDialog) {
                return "RELATIVE STRENGTH INDICATOR(RSI)";
            }
            else {
                return "RSI";
            }
        case IndicatorLabelTranslateHelper[BlockType.Stochastics]:
            return "STOCHASTICS";
        case IndicatorLabelTranslateHelper[BlockType.WonStochastics]:
            return "WON STOCHASTICS";
        case IndicatorLabelTranslateHelper[BlockType.PTOE]:
            return "PRICE-TO-EARNIGS";
        case IndicatorLabelTranslateHelper[BlockType.PTOS]:
            return "PRICE-TO-SALES";
        case IndicatorLabelTranslateHelper[BlockType.AccDist]:
            return "ACCUM/DIST";
        default:
            return;
    }
}
function* initKpiComponent({ data }) {
    try {
        document.body.classList.add('customIndicatorModalOpened');
        if (data.isShowModal) {
            const modalState = yield call(updateModalStatus, "Appearance");

            const menu = yield call(getKpiPeriodicityHeaders, data);
            const activePeriodicityTab = yield call(getActivePeriodicityTab, menu);

            // const { menuItem } = yield call(getKpiMenuObj, data.selectedItem, modalState);
            // extend(periodicityTab, { menuObj: menuItem });

            for (const periodicityTab of menu) {
                const { menuItem } = yield call(getKpiMenuObj, data.selectedItem, modalState);
                extend(periodicityTab, { menuObj: menuItem });
            }

            yield put({
                type: ActionTypes.OPEN_CLOSE_MODAL,
                isSettingsDialog: modalState.isSettingsDialog,
                isAppearanceDialog: modalState.isAppearanceDialog,
                isAboutDialog: modalState.isAboutDialog
            });
            yield put({
                type: ActionTypes.ON_TAB_SELECTION_CHANGE_SUCCESS,
                activePeriodicityTab: activePeriodicityTab
            });
            yield put({
                type: ActionTypes.UPDATE_TITLE,
                title: data.selectedItem.Header,
                selectedTiTab: data.selectedItem.KpiId
            });
            yield put({
                type: ActionTypes.UPDATE_MENU_OBJECT,
                obj: menu
            });
        }
    } catch (error) {
        console.log(`Error occurs in CustomTiModule.js, initKpiComponent, ${error}`);
    }

}
function* initComponent({ data }) {
    try {
        document.body.classList.add('customIndicatorModalOpened');

        if (data.isShowModal) {
            const modalState = yield call(updateModalStatus, data.selectedOption);
            const menu = yield call(getPeriodicityHeaders, data.selectedTiTab);
            const activePeriodicityTab = yield call(getActivePeriodicityTab, menu);
            //const title = yield call(getDialogTitle, data.selectedTiTab, modalState);
            const aboutInfo = yield call(getAboutDialogInfo, data.selectedTiTab, modalState);

            for (const periodicityTab of menu) {
                const { menuItem, isApplyToAllIntervals } = yield call(getMenuObj, data.selectedTiTab, modalState, periodicityTab);
                extend(periodicityTab, { menuObj: menuItem, isApplyToAllIntervals: isApplyToAllIntervals });
            }

            yield put({
                type: ActionTypes.OPEN_CLOSE_MODAL,
                isSettingsDialog: modalState.isSettingsDialog,
                isAppearanceDialog: modalState.isAppearanceDialog,
                isAboutDialog: modalState.isAboutDialog
            });

            // yield put({
            //     type: ActionTypes.UPDATE_PERIODICITY_HEADER,
            //     periodicityHeader: periodicityHeaders
            // });

            yield put({
                type: ActionTypes.ON_TAB_SELECTION_CHANGE_SUCCESS,
                activePeriodicityTab: activePeriodicityTab
            });

            yield put({
                type: ActionTypes.UPDATE_TITLE,
                title: data.selectedTiTab,
                selectedTiTab: data.selectedTiTab
            });

            yield put({
                type: ActionTypes.UPDATE_MENU_OBJECT,
                obj: menu
            });

            if (aboutInfo) {
                yield put({
                    type: ActionTypes.UPDATE_ABOUT_DIALOG_URL,
                    url: aboutInfo.url,
                    aboutDialogTitle: aboutInfo.title
                });
            }
        }
    } catch (error) {
        console.log(`Error occurs in CustomTiModule.js, initComponent, ${error}`);
    }

}

function* handleModalHide({ isSaveObject, isRemainOpen }) {
    try {
        const TiModuleState = yield select(getTiModuleState);
        let isSettingsDialog = TiModuleState.isSettingsDialog, isAppearanceDialog = TiModuleState.isAppearanceDialog, isAboutDialog = TiModuleState.isAboutDialog;
        let menu = clone(TiModuleState.menu);

        if (isRemainOpen && TiModuleState.restrictedItem) {
            TiModuleState.restrictedItem.select();

            yield put({
                type: ActionTypes.OPEN_CLOSE_MODAL,
                isSettingsDialog: true,
                isAppearanceDialog: false,
                isAboutDialog: false
            });
            return;
        }

        if (TiModuleState.isTextRestricted) {
            return;
        }

        if (isSettingsDialog) {
            menu = yield call(validateAllInputs, TiModuleState);
        }

        if (isSaveObject && !TiModuleState.isInvalidInput) {
            for (const periodicityTab of menu) {
                yield call(saveObject, TiModuleState.selectedTiTab, periodicityTab, isSettingsDialog);
            }
            yield call(reRenderChanges, TiModuleState.selectedTiTab);
        }
        else {
            if (!isAboutDialog) {
                yield call(unSaveObject, TiModuleState);
            }
        }

        if (TiModuleState.isSettingsDialog) {
            isSettingsDialog = false;
        }
        else if (TiModuleState.isAppearanceDialog) {
            isAppearanceDialog = false;
        }
        else if (TiModuleState.isAboutDialog) {
            isAboutDialog = false;
        }

        yield put({
            type: ActionTypes.OPEN_CLOSE_MODAL,
            isSettingsDialog: isSettingsDialog,
            isAppearanceDialog: isAppearanceDialog,
            isAboutDialog: isAboutDialog
        });
        document.body.classList.remove('customIndicatorModalOpened');
    } catch (error) {
        console.log(`Error occurs in CustomTiModule.js, handleModalHide, ${error}`);
    }

}

function* updateItemStatus({ isActive, item, color, weight }) {
    try {
        const updatedObj = yield call(getUpdatedObject, isActive, item, color, weight);

        yield put({
            type: ActionTypes.UPDATE_MENU_OBJECT,
            obj: updatedObj
        });
    } catch (error) {
        console.log(`Error occurs in CustomTiModule.js, updateItemStatus, ${error}`)
    }

}

function* handleInputValChange({ event, item }) {
    try {
        const updatedMenu = yield call(updateInputVal, event, item)

        yield put({
            type: ActionTypes.UPDATE_MENU_OBJECT,
            obj: updatedMenu
        });
    } catch (error) {
        console.log(`Error occurs in CustomTiModule.js, handleInputValChange, ${error}`)
    }

}

function* handleInputValidation({ event, item }) {
    try {
        if (event.target) {
            const TiModuleState = yield select(getTiModuleState);
            const value = event.target.value;
            let menu = clone(TiModuleState.menu);
            const activeTabMenu = TiModuleState.activePeriodicityTab.menuObj;

            const isTextRestricted = yield call(validateInputValRange, value, item, activeTabMenu);
            if (isTextRestricted || TiModuleState.isInvalidInput || StringUtil.isEmpty(value)) {
                const { menuItem } = yield call(getMenuObj, TiModuleState.selectedTiTab, TiModuleState, TiModuleState.activePeriodicityTab);
                const prevObj = menuItem;

                map(activeTabMenu, (menuItem) => {
                    if (menuItem.header === item.header) {
                        map(prevObj, (prevMenuItem) => {
                            if (menuItem.header === prevMenuItem.header) {
                                menuItem.value = prevMenuItem.value;
                            }
                        });
                    }
                });

                if (TiModuleState.activePeriodicityTab.isApplyToAllIntervals) {
                    for (const periodicityTab of menu) {
                        const updatedObj = yield call(updateAllIntervals, periodicityTab.menuObj, activeTabMenu);
                        extend(periodicityTab, { menuObj: updatedObj });
                    }
                }
                else {
                    menu = yield call(bindMenuObj, menu, activeTabMenu, TiModuleState.activePeriodicityTab);
                }

                yield put({
                    type: ActionTypes.UPDATE_MENU_OBJECT,
                    obj: menu
                });
            }
            // else if (TiModuleState.isInvalidInput) {
            //     yield put({
            //         type: ActionTypes.UPDATE_MENU_OBJECT,
            //         obj: TiModuleState.instanceOfMenuOj
            //     });
            // }

            yield put({
                type: ActionTypes.ON_INPUT_VALIDATION_SUCCESS,
                isTextRestricted: isTextRestricted,
                restrictedItem: event.target
            });

            yield put({
                type: ActionTypes.VALIDATION_ERROR,
                isInvalidInput: false,
                errorMessage: ""
            });
        }
    } catch (error) {
        console.log(`Error occurs in CustomTiModule.js, handleInputValidation, ${error}`);
    }

}

function* handlePeriodicityTabChange({ selectedPeriodicityTabKey }) {
    try {
        const TiModuleState = yield select(getTiModuleState);
        const menu = clone(TiModuleState.menu);

        yield put({
            type: ActionTypes.UPDATE_MENU_OBJECT,
            obj: menu
        });

        yield put({
            type: ActionTypes.ON_TAB_SELECTION_CHANGE_SUCCESS,
            activePeriodicityTab: selectedPeriodicityTabKey
        });
    } catch (error) {
        console.log(`Error occurs in CustomTiMoldule.js, handlePeriodicityTabChange, ${error}`);
    }

}

function* restoreDefaults() {
    try {
        const TiModuleState = yield select(getTiModuleState);
        let menu = clone(TiModuleState.menu);
        const isRestoring = true;

        if (TiModuleState.activePeriodicityTab.isApplyToAllIntervals) {
            for (const periodicityTab of menu) {
                const { menuItem } = yield call(getMenuObj, TiModuleState.selectedTiTab, TiModuleState, periodicityTab, isRestoring);
                menu = yield call(bindMenuObj, menu, menuItem, periodicityTab);
            }
        }
        else {
            const { menuItem } = yield call(getMenuObj, TiModuleState.selectedTiTab, TiModuleState, TiModuleState.activePeriodicityTab, isRestoring)
            menu = yield call(bindMenuObj, menu, menuItem, TiModuleState.activePeriodicityTab);
        }

        yield put({
            type: ActionTypes.UPDATE_MENU_OBJECT,
            obj: menu
        });

        yield put({
            type: ActionTypes.UPDATE_RESTORING,
            isRestoring: isRestoring
        });

        yield put({
            type: ActionTypes.VALIDATION_ERROR,
            isInvalidInput: false,
            errorMessage: ""
        });
    } catch (error) {
        console.log(`Error occurs in CustomTiModule.js, restoreDefaults, ${error}`);
    }

}

function* handleApplyToAllIntervals({ event }) {
    try {
        const TiModuleState = yield select(getTiModuleState);
        const menu = clone(TiModuleState.menu);

        for (const periodicityTab of menu) {
            const updatedObj = yield call(updateAllIntervals, periodicityTab.menuObj, TiModuleState.activePeriodicityTab.menuObj);
            extend(periodicityTab, { menuObj: updatedObj, isApplyToAllIntervals: event.target.checked });
        }

        yield put({
            type: ActionTypes.UPDATE_MENU_OBJECT,
            obj: menu
        });
    } catch (error) {
        console.log(`Error occurs in CustomTiModule.js, handleApplyToAllIntervals, ${error}`)
    }

}

export function* watchInitCustomTIModule() {
    yield takeLatest(ActionTypes.INIT_TI_MODULE, initComponent);
};

export function* watchInitCustomKPIModule() {
    yield takeLatest(ActionTypes.INIT_KPI_APPEARANCE, initKpiComponent);
};

export function* watchHandleModalHide() {
    yield takeLatest(ActionTypes.HANDLE_MODAL_HIDE, handleModalHide);
};

export function* watchHandleUpdateItemStatus() {
    yield takeLatest(ActionTypes.UPDATE_ITEM_STATUS, updateItemStatus);
};

export function* watchOnInputValChange() {
    yield takeLatest(ActionTypes.ON_INPUT_VALUE_CHANGE, handleInputValChange)
};

export function* watchHandleInputValidation() {
    yield takeLatest(ActionTypes.ON_INPUT_FOCUS_LOST, handleInputValidation)
}

export function* watchRestoreDefaults() {
    yield takeLatest(ActionTypes.RESTORE_DEFAULTS, restoreDefaults)
}

export function* watchOnPeriodicityTabChange() {
    yield takeLatest(ActionTypes.ON_TAB_SELECTION_CHANGED, handlePeriodicityTabChange)
}

export function* watchOnApplyToAllIntervals() {
    yield takeLatest(ActionTypes.ON_APPLY_TO_ALL_INTERVALS, handleApplyToAllIntervals)
}
