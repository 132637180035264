import Serializable from "Serializable";
import RatingFilterType from "RatingFilterType";
import panarayDefault from "../../../../../ServiceApi/RequestHelper/PanarayDefault";

class RotationSymbolsMenuSettings extends Serializable {
    constructor() {
        super();
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.osidList = serializedValue.osidList ? serializedValue.osidList : '';
        this.countryCodeList = serializedValue.countryCodeList ? serializedValue.countryCodeList : '';
        this.regionCodeList = serializedValue.regionCodeList ? serializedValue.regionCodeList : '';
        this.countryCode = serializedValue.countryCode ? serializedValue.countryCode : '';
        this.regionCode = serializedValue.regionCode ? serializedValue.regionCode : '';
        this.countryAggArray = serializedValue.countryAggArray ? serializedValue.countryAggArray : [];
        this.regionAggArray = serializedValue.regionAggArray ? serializedValue.regionAggArray : [];
        this.lastHighlightedOsids = serializedValue.lastHighlightedOsids ? serializedValue.lastHighlightedOsids : this.getDefaultLastHighlightedOsids();
        this.symbolsList = serializedValue.symbolsList && serializedValue.symbolsList.length > 0 ? serializedValue.symbolsList : [panarayDefault.Symbol];
        this.stockList = serializedValue.stockList ? serializedValue.stockList : [];
        this.isTimeLinePlaying = serializedValue.isTimeLinePlaying != null || serializedValue.isTimeLinePlaying != undefined ? serializedValue.isTimeLinePlaying : this.getDefaultTimelinePlayState();
        this.isUptrendVisible = serializedValue.isUptrendVisible != null || serializedValue.isUptrendVisible != undefined ? serializedValue.isUptrendVisible : this.getDefaultUptrendVisibility();
        this.isDowntrendVisible = serializedValue.isDowntrendVisible != null || serializedValue.isDowntrendVisible != undefined ? serializedValue.isDowntrendVisible : this.getDefaultDownTrendVisibility();
        this.timeLineIndex_country = serializedValue.timeLineIndex_country != undefined && serializedValue.timeLineIndex_country != null ? serializedValue.timeLineIndex_country : this.getDefaultTimeLineIndex();
        this.timeLineIndex_regional = serializedValue.timeLineIndex_regional != undefined && serializedValue.timeLineIndex_regional != null ? serializedValue.timeLineIndex_regional : this.getDefaultTimeLineIndex();
        this.timeLineIndex_global = serializedValue.timeLineIndex_global != undefined && serializedValue.timeLineIndex_global != null ? serializedValue.timeLineIndex_global : this.getDefaultTimeLineIndex();
        this.liqFilter = serializedValue.liqFilter ? serializedValue.liqFilter : this.getDefaultLiqFilterStates();
        this.notVisibleOsids = serializedValue.notVisibleOsids ? serializedValue.notVisibleOsids : this.getDefaultNotVisibleOsids();
        this.rotationGraphZoomValues = serializedValue.rotationGraphZoomValues ? serializedValue.rotationGraphZoomValues : this.getDefaultRotationGraphZoomValues();
        this.listName = serializedValue.listName ? typeof serializedValue.listName === 'object' ? null : serializedValue.listName : null;
        this.benchMarkOption = serializedValue.benchMarkOption ? serializedValue.benchMarkOption : this.getDefaultBenchMarkOption();
    }

    getDataObject() {
        return {
            osidList: this.osidList,
            countryCodeList: this.countryCodeList,
            regionCodeList: this.regionCodeList,
            countryCode: this.countryCode,
            regionCode: this.regionCode,
            countryAggArray: this.countryAggArray,
            regionAggArray: this.regionAggArray,
            symbolsList: this.symbolsList,
            stockList: this.stockList,
            lastHighlightedOsids: this.lastHighlightedOsids,
            isTimeLinePlaying: this.isTimeLinePlaying,
            isUptrendVisible: this.isUptrendVisible,
            isDowntrendVisible: this.isDowntrendVisible,
            timeLineIndex_country: this.timeLineIndex_country,
            timeLineIndex_regional: this.timeLineIndex_regional,
            timeLineIndex_global: this.timeLineIndex_global,
            liqFilter: this.liqFilter,
            notVisibleOsids: this.notVisibleOsids,
            rotationGraphZoomValues: this.rotationGraphZoomValues,
            itemName: this.rotationGraphZoomValues,
            listName: this.listName,
            benchMarkOption: this.benchMarkOption
        };
    }

    getObjectMapKey() {
        return "RotationSymbolsMenuSettings";
    }

    getDefaultRotationSymbolsMenuSettings() {
        this.osidList = '';
        this.countryCodeList = '';
        this.regionCodeList = '';
        this.countryCode = '';
        this.regionCode = '';
        this.countryAggArray = [];
        this.regionAggArray = [];
        this.symbolsList = [panarayDefault.Symbol];
        this.stockList = [];
        this.lastHighlightedOsids = this.getDefaultLastHighlightedOsids();
        this.isTimeLinePlaying = this.getDefaultTimelinePlayState();
        this.isUptrendVisible = this.getDefaultUptrendVisibility();
        this.isDowntrendVisible = this.getDefaultDownTrendVisibility();
        this.timeLineIndex_country = this.getDefaultTimeLineIndex();
        this.timeLineIndex_regional = this.getDefaultTimeLineIndex();
        this.timeLineIndex_global = this.getDefaultTimeLineIndex();
        this.liqFilter = this.getDefaultLiqFilterStates();
        this.notVisibleOsids = this.getDefaultNotVisibleOsids();
        this.rotationGraphZoomValues = this.getDefaultRotationGraphZoomValues();
        this.itemName = null;
        this.listName = null;
        this.benchMarkOption = this.getDefaultBenchMarkOption();
        return this;
    }

    getDefaultLastHighlightedOsids() {
        return [];
    }

    getDefaultTimelinePlayState() {
        return false;
    }

    getDefaultUptrendVisibility() {
        return true;
    }

    getDefaultDownTrendVisibility() {
        return true;
    }

    getDefaultTimeLineIndex() {
        return null;
    }

    getDefaultLiqFilterStates() {
        let liqFilter = new Serializable();
        liqFilter.MktCapLowSliderRangeValueIndex = -1;
        liqFilter.MktCapHighSliderRangeValueIndex = -1;

        liqFilter.AvolFilterLowSliderRangeValueIndex = -1;
        liqFilter.AvolFilterHighSliderRangeValueIndex = -1;


        liqFilter.RatingFilter1LowSliderRangeValueIndex = -1;
        liqFilter.RatingFilter1HighSliderRangeValueIndex = -1;

        liqFilter.RatingFilter2LowSliderRangeValueIndex = -1;
        liqFilter.RatingFilter2HighSliderRangeValueIndex = -1;

        liqFilter.RatingFilterType2 = RatingFilterType.EPS;

        liqFilter.RatingFilterType1 = RatingFilterType.Datagraph;

        liqFilter.IsGlobalRatingSelected = true;
        liqFilter.IsCountryRatingSelected = false;
        liqFilter.IsFilterOn = true;
        liqFilter.IsFilterManualOff = true;
        return liqFilter;
    }

    getDefaultNotVisibleOsids() {
        return [];
    }

    getDefaultRotationGraphZoomValues() {
        let rotationGraphZoomValues = new Serializable();
        rotationGraphZoomValues.zoomValue = "AUTO";
        rotationGraphZoomValues.matrix = "matrix(" + 1 + "," + 0 + "," + 0 + "," + 1 + "," + 0 + "," + 0 + ")";
        rotationGraphZoomValues.isAutoChecked = true;
        return rotationGraphZoomValues;
    }

    getDefaultBenchMarkOption() {
        return "Country";
    }
}

export default RotationSymbolsMenuSettings;
