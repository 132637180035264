const SmartViewType =
    {
        STOCK:"STOCK",
        FUND:"FUND",
        CEF:"CEF",
        ETF:"ETF",
        CURRENCY:"CURRENCY",
        INDUSTRYGROUP:"INDUSTRYGROUP",
        SECTOR: "SECTOR",
        FUNDAMENTALS: "FUNDAMENTALS",
        CONSTITUENTS: "CONSTITUENTS",
        MAJORINDUSTRY: "MAJOR INDUSTRY",
        INDEX:"INDEX",
        FUTURES: "FUTURES",
        CASHSPOTS: "CASHSPOTS",
        CRYPTOCURRENCY: "CRYPTOCURRENCY",
        OWNERSHIP:"OWNERSHIP",
        HOLDINGS:"HOLDINGS"
    }

export  default SmartViewType;
