import React from "react";
import _ from 'underscore';
import ListExplorerStore from "ListExplorerStore";
import GridStore from "GridStore";
import StringUtil from "StringUtil";
import ContextMenuConstants from "ContextMenuConstants";
import onClickOutside from "react-onclickoutside";
import AlertConditionType from "../../Constants/AlertConditionType";
import LocalizationStore from "LocalizationStore";

class ContextMenu extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.listViewArea = document.getElementsByClassName("list-view-area")[0];
        this.listManagerBGElm = document.getElementById('listManagerBG');
    }

    componentWillUnmount() {
        this.listViewArea = null;
        this.listManagerBGElm = null;
    }

    handleClickOutside() {
        try {
            this.props.hideContextMenu();
        }
        catch (ex) {
            console.log(ex);
        }
    }

    createExplorerContextMenu() {
        let rootParentId = 0;
        if(this.props.contextMenuObj.rootParentId){
            rootParentId =this.props.contextMenuObj.rootParentId;
        }
        else{
            if(typeof (this.props.contextMenuObj.parentId) === "object"){
                rootParentId =this.props.contextMenuObj.parentId.low;
            }
            else{
                rootParentId =this.props.contextMenuObj.parentId;
            }
        }
        let contextMenu = ListExplorerStore.getContextMenu(this.props.contextMenuObj.categoryType, this.props.contextMenuObj.listType, rootParentId, this.props.contextMenuObj.selectedNodeId, this.props.contextMenuObj.nodeName, this.props.contextMenuObj.sourceListId, this.props.contextMenuObj.parentId,null, this.props.contextMenuObj.spId);
        let existAlerts = false;
        if (!StringUtil.isEmpty(contextMenu)) {
            return contextMenu.map((entry, i) => {
                if (entry !== undefined && entry.contextName != ContextMenuConstants.DELETE_LIST_ALERTS) {
                    if((entry.contextName == LocalizationStore.getTranslatedData("ch_msa_sup_oca", ContextMenuConstants.ALERT) ||  entry.contextName == LocalizationStore.getTranslatedData("ch_msa_sup_aca",ContextMenuConstants.AND_CONDITION_ALERTS)) &&  contextMenu[i+1].contextName == ContextMenuConstants.DELETE_LIST_ALERTS){
                        return <li key={i}>
                        <span className="default anchor-replace">
                            <span style={{display: 'inline-block'}} className={entry.className + " anchor pull-left cursor-pointer"} href="javascript:void(0);" onClick={this.onExplorerContextMenuItemClick.bind(this, entry.action, this.props.contextMenuObj.categoryType, entry.rootParentId, entry.nodeId, entry.nodeName, entry.listType, entry.sourceListId, entry.parentId,this.props.contextMenuObj.alertStatusType, entry.className, !existAlerts,entry.contextName)}>{entry.contextName}
                            </span>
                            <span  style={{display: 'inline-block'}} className={contextMenu[i+1].className + " anchor right-text context-action context-alert"} href="javascript:void(0);" onClick={this.onExplorerContextMenuItemClick.bind(this, contextMenu[i+1].action, this.props.contextMenuObj.categoryType, contextMenu[i+1].rootParentId, contextMenu[i+1].nodeId, contextMenu[i+1].nodeName, contextMenu[i+1].listType, entry.sourceListId, contextMenu[i+1].parentId,this.props.contextMenuObj.alertStatusType, entry.className, !existAlerts, entry.contextName)}>{"Delete"}</span>
                        </span>
                        </li>;
                    }
                    else {
                        return <li key={i}><a className={entry.className} onClick={entry.className === "contextMenuItemDisabled" ? undefined : this.onExplorerContextMenuItemClick.bind(this, entry.action, this.props.contextMenuObj.categoryType, entry.rootParentId, entry.nodeId, entry.nodeName, entry.listType, entry.sourceListId, entry.parentId,this.props.contextMenuObj.alertStatusType, entry.className, existAlerts,entry.contextName, entry.isDupFav?entry.isDupFav:false, entry.spId)}>{entry.contextName}</a></li>;
                    }
                }
            });
        }
    }

    onExplorerContextMenuItemClick(categoryType, action, rootParentId, nodeId, nodeName, listType, sourceListId, parentId,alertStatusType, className = null, existAlerts = false,contextName, isDupFav = false,spId) {
        if(className === 'contextMenuItemDisabled') {
            return null;
        }
        let conditionTypeId;
        if (action) {
            if(contextName == ContextMenuConstants.AND_CONDITION_ALERTS){
                conditionTypeId = AlertConditionType.And;
            }
            else{
                conditionTypeId = AlertConditionType.Or
            }
            return this.props.onExplorerContextMenuItemClick(action, categoryType, rootParentId, nodeId, nodeName, listType, sourceListId, parentId,alertStatusType, existAlerts, isDupFav,spId,conditionTypeId);
        }
    }

    createGridColumnsContextMenu() {
        let contextMenu = GridStore.getColumnsHeaderContextMenu(this.props.contextMenuObj.dataItemId);
        return contextMenu.map((entry, i) => {
            if (entry !== undefined) {
                if (entry.menuEnabled !== undefined) {
                    if (entry.menuEnabled) {
                        return <li key={i}><a onClick={this.onGridColumnsContextMenuItemClick.bind(this, entry.action)}>{(this.props.contextMenuObj.isVisualizationOn && entry.contextName == ContextMenuConstants.COLUMN_VISUALIZATION) ? <span className="icn-right-icon"></span> : ''} {entry.contextName}</a></li>;
                    } else {
                        return <li style={{ pointerEvents: 'none', opacity: 0.4 }} key={i}><a onClick={this.onGridColumnsContextMenuItemClick.bind(this, entry.action)}>{(this.props.contextMenuObj.isVisualizationOn && entry.contextName == ContextMenuConstants.COLUMN_VISUALIZATION) ? <span className="icn-right-icon"></span> : ''} {entry.contextName}</a></li>;
                    }
                } else {
                    return <li key={i}><a onClick={this.onGridColumnsContextMenuItemClick.bind(this, entry.action)}>{(this.props.contextMenuObj.isVisualizationOn && entry.contextName == ContextMenuConstants.COLUMN_VISUALIZATION) ? <span className="icn-right-icon"></span> : ''} {entry.contextName}</a></li>;
                }
            }
        });
    }

    onGridColumnsContextMenuItemClick(action) {
        if (action) {
            return this.props.onGridColumnsContextMenuItemClick(action, this.props.contextMenuObj.listId, this.props.contextMenuObj.dataItemId, this.props.contextMenuObj.displayName, this.props.contextMenuObj.colSeq, this.props.contextMenuObj.quoteBoard, this.props.contextMenuObj.isFrozen, this.props.contextMenuObj.previousColumnDataItemId);
        }
    }

    createGridCustomFilterContextMenu() {
        let contextMenu = GridStore.getCustomFilterContextMenu();
        return contextMenu.map((entry, i) => {
            if (entry !== undefined) {
                return <li key={i}><a onClick={this.onGridCustomFilterContextMenuItemClick.bind(this, entry.action)}>{entry.contextName}</a></li>;
            }
        });
    }

    onGridCustomFilterContextMenuItemClick(action) {
        if (action) {
            return this.props.onGridCustomFilterContextMenuItemClick(action);
        }
    }

    createGridRowContextMenu() {
        let contextMenu = GridStore.getRowContextMenu(this.props.contextMenuObj.selectedIndexes, this.props.contextMenuObj.instrumentType);
        return contextMenu.map((entry, i) => {
            if (entry !== undefined) {
                return <li key={i}><a className={entry.className} onClick={this.onGridRowContextMenuItemClick.bind(this, entry.action, entry.className)}>{entry.contextName}</a></li>;
            }
        });
    }

    onGridRowContextMenuItemClick(action, className = null) {
        if(className === 'contextMenuItemDisabled') {
            return null;
        }
        if (action) {
            return this.props.onGridRowContextMenuItemClick(action, this.props.contextMenuObj);
        }
    }

    getMaxWidth(contextMenu, font, maxWidth = 0) {
        _.each(contextMenu, (item) => {
            let width = item.contextName.getTextWidth(font);
            if (maxWidth <= width)
                maxWidth = width;
        });
        return maxWidth;
    }

    createDivStyle() {
        let listViewAreaBottom = this.listViewArea.getBoundingClientRect().bottom - 12;
        let listViewAreaRight = this.listViewArea.getBoundingClientRect().right - 12;
        let topCal = this.props.contextMenuObj.yPos;
        let leftCal = this.props.contextMenuObj.xPos;
        let font = "14 'calibri'";
        let maxWidth = 0;
        let lrPadding = 60;
        let contextMenuList = null;
        let contextMenuTextList = null;
        if (this.props.contextMenuObj.contextMenuType == 'gridColumnHeader') {
            contextMenuList = this.createGridColumnsContextMenu();
            contextMenuTextList = GridStore.getColumnsHeaderContextMenu(this.props.contextMenuObj.dataItemId);

        }
        else if (this.props.contextMenuObj.contextMenuType == 'gridCustomFilter') {
            contextMenuList = this.createGridCustomFilterContextMenu();
            contextMenuTextList = GridStore.getCustomFilterContextMenu();
        }
        if (this.props.contextMenuObj.contextMenuType == 'gridRow') {
            contextMenuList = this.createGridRowContextMenu();
            contextMenuTextList = GridStore.getRowContextMenu(this.props.contextMenuObj.selectedIndexes, this.props.contextMenuObj.instrumentType);
        }
        else if (this.props.contextMenuObj.contextMenuType == 'explorer') {
            contextMenuList = this.createExplorerContextMenu();
        }
        if (contextMenuList != null) {
            let ulHeight = (contextMenuList.length * 24) + 24;
            if (listViewAreaBottom <= (this.props.contextMenuObj.yPos + ulHeight))
                topCal = topCal - ulHeight;
            if (contextMenuTextList != null) {
                maxWidth = this.getMaxWidth(contextMenuTextList, font);
                if (maxWidth > 0) {
                    maxWidth += lrPadding;
                    if (listViewAreaRight <= (this.props.contextMenuObj.xPos + maxWidth)) {
                        leftCal = leftCal - maxWidth;
                    }
                }
            }
        }
        return {
            position: "absolute",
            left: leftCal,
            top: topCal,
            zIndex: "999",
            marginTop: "-25px"
        };
    }

    setScroll(enableScroll = true) {
        if (this.listManagerBGElm) this.listManagerBGElm.className = enableScroll ? 'listManagerBG displayNone' : this.listManagerBGElm.className.replace('displayNone', '');
    }

    render() {
        if (this.props.showContextMenu) {
            let contextMenuList = null;
            if (this.props.contextMenuObj.contextMenuType == 'gridColumnHeader')
                contextMenuList = this.createGridColumnsContextMenu();
            else if (this.props.contextMenuObj.contextMenuType == 'gridCustomFilter')
                contextMenuList = this.createGridCustomFilterContextMenu();
            else if (this.props.contextMenuObj.contextMenuType == 'gridRow')
                contextMenuList = this.createGridRowContextMenu();
            else
                contextMenuList = this.createExplorerContextMenu();
            return (
                <div id='contextMenu' className="context-menu dropdown" style={contextMenuList ? this.createDivStyle() : {display : "none"}}>
                    <ul className=" context-menu-inner list-contex small-normal" aria-labelledby="contextMenu" style={{ bottom: "150px", top: "inherit" }}>{contextMenuList}</ul>
                </div>
            );
        }
        return (
            null
        );
    }
}


export default onClickOutside(ContextMenu);
