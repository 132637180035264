import Serializable from "Serializable";
import { RayAnnotationType, StrokeStyleType, TextAlignmentType } from 'Constants/EAnnotationType';

class AnnotationSettings extends Serializable {
  constructor() {
    super();
    this.selectedToolType = 0;
    this.selectedLayerID = 1;
    this.LayerSettings = new Serializable();
    this.LineSettings = this.getDefaultLineSettings();
    this.TextSettings = this.getDefaultTextSettings();
    this.ShapeSettings = this.getDefaultShapeSettings();
    this.ChannelLineSettings = this.getDefaultChannelLineSettings();
    this.FibonacciSettings = this.getDefaultFibonacciSettings();
    this.MeasurementSettings = this.getDefaultMeasurementSettings();
  }

  getDefaultLineSettings() {
    const LineSettings = new Serializable();
    LineSettings.color = 'P0066CC3399FF';
    LineSettings.weight = 2;
    LineSettings.opacity = 1;
    LineSettings.strokeStyle = StrokeStyleType.SOLID_StrokeStyleType
    LineSettings.stroke = [];
    LineSettings.startArrowChecked = false;
    LineSettings.endArrowChecked = false;
    LineSettings.startExtendChecked = false;
    LineSettings.endExtendChecked = false;
    LineSettings.isSnappingChecked = false;
    LineSettings.isAlertChecked = false;
    LineSettings.isTemporaryChecked = false;

    return LineSettings;
  }

  getDefaultTextSettings() {
    const TextSettings = new Serializable();
    TextSettings.color = 'P0066CC3399FF';
    TextSettings.borderColor = 'P0066CC3399FF';
    TextSettings.bgColor = "P000000FFFFFF";
    TextSettings.borderWeight = 1;
    TextSettings.borderStroke = 0;
    TextSettings.strokeStyle = StrokeStyleType.SOLID_StrokeStyleType;
    TextSettings.stroke = [];
    TextSettings.opacity = 1;
    TextSettings.isBorderChecked = true;
    TextSettings.fontStyle = 'Calibri';
    TextSettings.fontSize = 14;
    TextSettings.isBold = true;
    TextSettings.isItalic = false;
    TextSettings.isUnderline = false;
    TextSettings.textAlign = TextAlignmentType.CENTER;

    return TextSettings;
  }

  getDefaultShapeSettings() {
    const ShapeSettings = new Serializable();
    ShapeSettings.bgColor = 'P000000FFFFFF';
    ShapeSettings.borderColor = 'P0066CC3399FF';
    ShapeSettings.borderWeight = 1;
    ShapeSettings.borderStroke = [];
    ShapeSettings.strokeStyle = StrokeStyleType.SOLID_StrokeStyleType;
    ShapeSettings.opacity = 0;
    ShapeSettings.isBorderChecked = true;
    ShapeSettings.selectedType = RayAnnotationType.RECT_AnnotationType;

    return ShapeSettings;
  }

  getDefaultChannelLineSettings() {
    const ChannelLineSettings = new Serializable();
    ChannelLineSettings.color = 'P0066CC3399FF';
    ChannelLineSettings.weight = 2;
    ChannelLineSettings.stroke = [];
    ChannelLineSettings.strokeStyle = StrokeStyleType.SOLID_StrokeStyleType;
    ChannelLineSettings.startExtendChecked = false;
    ChannelLineSettings.endExtendChecked = false;

    return ChannelLineSettings;
  }

  getDefaultFibonacciSettings() {
    const FibonacciSettings = new Serializable();
    FibonacciSettings.color = 'P0066CC3399FF';
    FibonacciSettings.weight = 2;
    FibonacciSettings.isLevelLabelsChecked = true;
    FibonacciSettings.isPriceLabelsChecked = true
    FibonacciSettings.startExtendChecked = false;
    FibonacciSettings.endExtendChecked = false;
    FibonacciSettings.isExtensionsChecked = true;
    FibonacciSettings.extensionLineColor = 'P9999994C4C4C';
    FibonacciSettings.extensionLineWeight = 2;

    return FibonacciSettings;
  }

  getDefaultMeasurementSettings() {
    const MeasurementSettings = new Serializable();
    MeasurementSettings.color = 'P0066CC3399FF';
    MeasurementSettings.isLeaderlinesChecked = true;

    return MeasurementSettings;
  }

  onSeralisedInfoRecieved(serializedValue) {
    this.selectedToolType = serializedValue.selectedToolType ? serializedValue.selectedToolType : 0;
    this.selectedLayerID = serializedValue.selectedLayerID ? serializedValue.selectedLayerID : '';
    this.LayerSettings = serializedValue.LayerSettings ? serializedValue.LayerSettings : new Serializable();
    this.LineSettings = serializedValue.LineSettings ? serializedValue.LineSettings : this.getDefaultLineSettings();
    this.TextSettings = serializedValue.TextSettings ? serializedValue.TextSettings : this.getDefaultTextSettings();
    this.ShapeSettings = serializedValue.ShapeSettings ? serializedValue.ShapeSettings : this.getDefaultShapeSettings();
    this.ChannelLineSettings = serializedValue.ChannelLineSettings ? serializedValue.ChannelLineSettings : this.getDefaultChannelLineSettings();
    this.FibonacciSettings = serializedValue.FibonacciSettings ? serializedValue.FibonacciSettings : this.getDefaultFibonacciSettings();
    this.MeasurementSettings = serializedValue.MeasurementSettings ? serializedValue.MeasurementSettings : this.getDefaultMeasurementSettings();
  }

  getDataObject() {
    return {
      selectedToolType: this.selectedToolType,
      selectedLayerID: this.selectedLayerID,
      LayerSettings: this.LayerSettings,
      LineSettings: this.LineSettings,
      TextSettings: this.TextSettings,
      ShapeSettings: this.ShapeSettings,
      ChannelLineSettings: this.ChannelLineSettings,
      FuturesViewSettings: this.FuturesViewSettings,
      FibonacciSettings: this.FibonacciSettings,
      MeasurementSettings: this.MeasurementSettings
    };
  }

  getObjectMapKey() {
    return "AnnotationSettings";
  }

}

export default AnnotationSettings;
