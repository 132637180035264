import React from "react";

export default class SSOLoading extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        sessionStorage.setItem("_sso", 1);
    }

    render() {
        return (
            <div className="loading left100" ><img alt="" src="/Asset/images/loading.gif" /></div>
        );
    }
};