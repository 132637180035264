import { PrintMode } from '../../../../print/printmode';
import { safe } from '../../../ErrorModel';
import { ShareAccessType } from '../../../../Constants/ShareConstants';
import { StrokeStyleType } from 'Constants/EAnnotationType';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { AnnotationConstant, TemporaryId } from "Constants/AnnotationConstants.js";
import {
    getAnnotationIsDrawing, getAnnotationChartDraw, getAnnotationSelectedId, getChannelLine, getAnnotationMenu, getTargetLayer, getActiveAnchor,
    getLayersList, getAnnotationDimension, getAnnotationSelected
} from '../../../../Reducers/AnnotationReducers/selectors';
import { takeLatest, select, put, call, takeEvery } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;
/********************** Model Logic *************************/

function* handleClick({ e }) {
    try {
        let channelLine = null;
        const annotationMenu = yield select(getAnnotationMenu);
        let isDrawing = yield select(getAnnotationIsDrawing);
        let annotSelectedId = yield select(getAnnotationSelectedId);
        const targetLayer = yield select(getTargetLayer);
        const channelLines = yield select(getChannelLine);

        if (isDrawing) {
            isDrawing = !isDrawing
            channelLine = channelLines.find((itm) => itm.id === annotSelectedId);
            yield call(updatechannelLine, isDrawing, channelLine);
            yield safe(call(saveChannelLine), "GraphicsChannelLineSaga.js", "saveChannelLine");
            return;
        }

        if (e.target !== e.target.getStage()) {
            annotSelectedId = e.target.id();
            channelLine = channelLines.find((itm) => itm.id === annotSelectedId);
            yield call(updatechannelLine, isDrawing, channelLine, annotSelectedId);
            return;
        }

        const lineStart = { x: e.evt.layerX, y: e.evt.layerY };
        const lineEnd = { x: e.evt.layerX + 1, y: e.evt.layerY + 1 };
        const midPoint = yield safe(call(calculateMidPoint, lineStart, lineEnd, 50), "GraphicsChannelLineSaga.js", "calculateMidPoint");

        const annotId = TemporaryId.Id + channelLines.length;
        channelLine = {
            ...annotationMenu.ChannelLineModelInfo,
            id: annotId,
            layerID: targetLayer.layerID,
            x: e.evt.layerX,
            y: e.evt.layerY,
            startMouseX: 0,
            startMouseY: 0,
            currentMouseX: 0,
            currentMouseY: 0,
            endMouseX: 0,
            endMouseY: 0,
            midX: midPoint.x,
            midY: midPoint.y,
            spreadMiddle: 50,
            isReadOnlyAnnotation: false
        }
        isDrawing = true;
        yield call(addchannelLine, isDrawing, channelLine);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsChannelLineSaga.js, handleClick ${error}`);
    }
}

function* handleMouseMove({ e, isEndExtend, reComputeOnSelection, isAnchorDrag }) {
    try {
        let channelLine = null;
        const { isDrawing, isDragging } = yield select(getAnnotationChartDraw);
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const channelLines = yield select(getChannelLine);
        const annotationMenu = yield select(getAnnotationMenu);
        const activeAnchor = yield select(getActiveAnchor);
        const chartDimension = yield select(getAnnotationDimension);

        const { startExtendChecked, endExtendChecked } = annotationMenu.ChannelLineModelInfo;

        if ((isDrawing || isDragging || reComputeOnSelection || isAnchorDrag) && channelLines.length > 0) {

            const curLine = channelLines.find((itm) => itm.id === annotSelectedId);
            let lineStart = {}, lineEnd = {}, currMouse = {}, spreadMiddle = curLine.spreadMiddle;

            if (isDragging) {
                const dragPos = {
                    x: e.currentTarget.x(), y: e.currentTarget.y()
                }

                // Fix for PANWEB-6190
                if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                    if (isEndExtend) {
                        e.currentTarget.attrs.x = curLine.endMouseX;
                        e.currentTarget.attrs.y = curLine.endMouseY;
                    }
                    else {
                        e.currentTarget.attrs.x = curLine.x;
                        e.currentTarget.attrs.y = curLine.y;
                    }
                    return;
                }
                else if (isEndExtend) { // dragged element is end extended line
                    const diff = { x: dragPos.x - curLine.endMouseX, y: dragPos.y - curLine.endMouseY };
                    lineStart = {
                        x: curLine.x + diff.x, y: curLine.y + diff.y
                    }
                    lineEnd = {
                        x: dragPos.x, y: dragPos.y
                    }
                }
                else {
                    // normal line and extended line have same x & y origin values.
                    const diff = { x: dragPos.x - curLine.x, y: dragPos.y - curLine.y };
                    lineStart = {
                        x: dragPos.x, y: dragPos.y
                    }
                    lineEnd = {
                        x: curLine.endMouseX + diff.x, y: curLine.endMouseY + diff.y
                    }
                }
            }
            else if (reComputeOnSelection) {
                lineStart = {
                    x: curLine.x, y: curLine.y
                }
                lineEnd = {
                    x: curLine.endMouseX, y: curLine.endMouseY
                }
            }
            else if (isAnchorDrag) {
                // Fix for PANWEB-6207
                if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                    return;
                }

                if (e.currentTarget !== undefined && e.currentTarget !== null) {
                    e.currentTarget.getStage().container().style.cursor = 'all-scroll';
                }

                if (activeAnchor === "start") {
                    lineStart = {
                        x: e.evt.layerX,
                        y: e.evt.layerY
                    }
                    lineEnd = {
                        x: curLine.endMouseX,
                        y: curLine.endMouseY
                    }
                }
                else if (activeAnchor === "mid") {
                    // start and end of line position remain the same. Only change is spread height which will allow to drag only spread line in a vertical direction
                    spreadMiddle = yield safe(call(makeSpreadFromPoints, { x: curLine.x, y: curLine.y }, { x: curLine.endMouseX, y: curLine.endMouseY }, { x: e.evt.layerX, y: e.evt.layerY }), "GraphicsChannelLineSaga.js", "makeSpreadFromPoints");
                    lineStart = {
                        x: curLine.x, y: curLine.y
                    }
                    lineEnd = {
                        x: curLine.endMouseX, y: curLine.endMouseY
                    }
                }
                else if (activeAnchor === "end") {
                    lineStart = {
                        x: curLine.x,
                        y: curLine.y
                    }
                    lineEnd = {
                        x: e.evt.layerX,
                        y: e.evt.layerY
                    }
                }
            }
            else {
                lineStart = {
                    x: curLine.x, y: curLine.y
                }
                lineEnd = {
                    x: e.evt.layerX, y: e.evt.layerY
                }
            }

            currMouse = {
                x: lineEnd.x - lineStart.x,
                y: lineEnd.y - lineStart.y
            }

            let isAtEnd, startExtendPoint = {}, endExtendPoint = {};

            if (startExtendChecked) {
                isAtEnd = false;
                startExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);
            }
            if (endExtendChecked) {
                isAtEnd = true;
                endExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);
            }

            const midPoint = yield safe(call(calculateMidPoint, lineStart, lineEnd, spreadMiddle), "GraphicsChannelLineSaga.js", "calculateMidPoint");

            channelLine = Object.assign(curLine, {
                x: lineStart.x,
                y: lineStart.y,
                currentMouseX: currMouse.x,
                currentMouseY: currMouse.y,
                endMouseX: lineEnd.x,
                endMouseY: lineEnd.y,
                startExtendPoint: startExtendPoint,
                endExtendPoint: endExtendPoint,
                midX: midPoint.x,
                midY: midPoint.y,
                spreadMiddle: spreadMiddle
            });
            yield call(updatechannelLine, isDrawing, channelLine);
        }
        else {
            yield call(updatechannelLine, isDrawing, channelLines);
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsChannelLineSaga.js, handleMouseMove ${error}`);
    }
}

function* calculateSlope(isAtEnd, lineStart, lineEnd) {
    try {
        const chartDimension = yield select(getAnnotationDimension);
        let x1, y1, x2, y2, slope = 0, returnPoint = { x: 0, y: 0 }, newX, newY;
        const extendedWidth = chartDimension.width - 6, extendedHeight = chartDimension.height + 50;

        if (isAtEnd) {
            x1 = lineStart.x;
            y1 = lineStart.y;
            x2 = lineEnd.x;
            y2 = lineEnd.y;
        }
        else {
            x1 = lineEnd.x;
            y1 = lineEnd.y;
            x2 = lineStart.x;
            y2 = lineStart.y;
        }

        const deltaX = x2 - x1;
        const deltaY = y2 - y1;

        const xIsPositive = deltaX > 0;
        const yIsPositive = deltaY > 0;

        const xIsZero = Math.abs(deltaX - 0) < Number.EPSILON;
        const yIsZero = Math.abs(deltaY - 0) < Number.EPSILON;

        const yEquation = lineEnd.y - lineStart.y;
        const xEquation = lineEnd.x - lineStart.x;

        // Check for Quads
        const slope1 = (0 - y1) / (0 - x1);
        const slope2 = (0 - y1) / (extendedWidth - x1);
        const slope3 = (extendedHeight - y1) / (extendedWidth - x1);
        const slope4 = (extendedHeight - y1) / (0 - x1);

        if (!xIsZero) {
            slope = yEquation / xEquation;
        }

        if (isAtEnd) {
            // Extension for Y-Axis
            if (yIsZero) {
                returnPoint = lineEnd.x > lineStart.x ? { x: extendedWidth, y: lineEnd.y } : { x: 0, y: lineEnd.y };
            }
            // Extension for X-Axis
            else if (xIsZero) {
                returnPoint = lineEnd.y < lineStart.y ? { x: lineEnd.x, y: 0 } : { x: lineEnd.x, y: extendedHeight };
            }
            else if (!xIsPositive && !yIsPositive) {
                if (slope > slope1) {
                    newX = 0 / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: 0 };
                }
                else {
                    newY = slope * (0 - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: 0, y: newY };
                }
            }
            else if (xIsPositive && !yIsPositive) {
                if (slope < slope2) {
                    newX = 0 / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: 0 };
                }
                else {
                    newY = slope * (extendedWidth - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: extendedWidth, y: newY };
                }
            }
            else if (xIsPositive) {
                if (slope < slope3) {
                    newY = slope * (extendedWidth - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: extendedWidth, y: newY };
                }
                else {
                    newX = extendedHeight / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: extendedHeight };
                }
            }
            else {
                if (slope < slope4) {
                    newX = extendedHeight / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: extendedHeight };
                }
                else {
                    newY = slope * (0 - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: 0, y: newY };
                }
            }
        }
        else {
            // Extension for Y-Axis
            if (yIsZero) {
                returnPoint = lineEnd.x > lineStart.x ? { x: 0, y: lineStart.y } : { x: extendedWidth, y: lineStart.y };
            }
            // Extension for X-Axis
            else if (xIsZero) {
                returnPoint = lineEnd.y > lineStart.y ? { x: lineStart.x, y: 0 } : { x: lineStart.x, y: extendedHeight };
            }
            else if (!xIsPositive && !yIsPositive) {
                if (slope > slope1) {
                    newX = 0 / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: 0 };
                }
                else {
                    newY = slope * (0 - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: 0, y: newY };
                }
            }
            else if (xIsPositive && !yIsPositive) {
                if (slope < slope2) {
                    newX = 0 / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: 0 };
                }
                else {
                    newY = slope * (extendedWidth - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: extendedWidth, y: newY };
                }
            }
            else if (xIsPositive) {
                if (slope < slope3) {
                    newY = slope * (extendedWidth - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: extendedWidth, y: newY };
                }
                else {
                    newX = extendedHeight / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: extendedHeight };
                }
            }
            else {
                if (slope < slope4) {
                    newX = extendedHeight / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: extendedHeight };
                }
                else {
                    newY = slope * (0 - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: 0, y: newY };
                }
            }
        }
        return returnPoint;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsChannelLineSaga.js, calculateSlope ${error}`);
    }
}

function* updatechannelLine(isDrawing, channelLine) {
    try {
        yield put({
            type: ActionTypes.HANDLE_CHANNEL_LINE_DRAW_SUCCESS,
            channelLine: channelLine
        });
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: channelLine
        });

        let layersList = yield select(getLayersList);
        layersList = JSON.parse(JSON.stringify(layersList));
        const selectedAnnot = yield select(getAnnotationSelected);
        for (const x in layersList) {
            layersList[x].layerID === selectedAnnot.layerID ? layersList.push(layersList.splice(x, 1)[0]) : 0;
        }
        yield put({
            type: ActionTypes.UPDATE_LAYERS_LIST,
            LayersList: layersList
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsChannelLineSaga.js, updatechannelLine ${error}`);
    }
}

function* addchannelLine(isDrawing, channelLine) {
    try {
        yield put({
            type: ActionTypes.HANDLE_CHANNEL_LINE_CLICK_SUCCESS,
            channelLine: channelLine,
        });
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: channelLine
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsChannelLineSaga.js, addchannelLine ${error}`);
    }
}

function* saveChannelLine() {
    yield put({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    });
}

function calculateMidPoint(lineStart, lineEnd, spreadMiddle) {
    const midPos = { x: 0, y: 0 }
    midPos.x = lineEnd.x === 0 ? lineStart.x : (lineEnd.x + lineStart.x) / 2;
    midPos.y = lineEnd.y === 0 ? lineStart.y : (lineEnd.y + lineStart.y - (spreadMiddle * 2)) / 2;
    return midPos;
}

function makeSpreadFromPoints(startOfLine, endOfLine, spreadOfLine) {
    // const deltaX = endOfLine.x - startOfLine.x;
    // const halfX = (deltaX / 2) + startOfLine.x;
    const deltaY = endOfLine.y - startOfLine.y;
    const halfY = (deltaY / 2) + startOfLine.y;
    const finalY = halfY - spreadOfLine.y;
    return finalY;
}

function* processChannelLineData({ data }) {
    try {
        const lineStart = {
            x: data.startPoint.x,
            y: data.startPoint.y
        }
        const lineEnd = {
            x: data.endPoint.x,
            y: data.endPoint.y
        }
        const currMouse = {
            x: lineEnd.x - lineStart.x,
            y: lineEnd.y - lineStart.y
        }

        const spreadMiddle = yield safe(call(makeSpreadFromPoints, data.startPoint, data.endPoint, data.spreadPoint), "GraphicsChannelLineSaga.js", "makeSpreadFromPoints");
        let isAtEnd, startExtendPoint = {}, endExtendPoint = {};

        if (data.isStartExtend) {
            isAtEnd = false;
            startExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);
        }
        if (data.isEndExtend) {
            isAtEnd = true;
            endExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);
        }

        const midPoint = yield safe(call(calculateMidPoint, lineStart, lineEnd, spreadMiddle), "GraphicsChannelLineSaga.js", "calculateMidPoint");
        const stroke = data.strokeType === StrokeStyleType.SOLID_StrokeStyleType ? [] : data.strokeType === StrokeStyleType.DASHED_StrokeStyleType ? [data.penSize * 2.5, data.penSize] : [data.penSize, data.penSize];
        const convertedForegroundColor = ThemeHelper.convertArgbToHex(data.foregroundColor);
        const layersList = yield select(getLayersList);
        const isReadOnlyAnnotation = layersList.filter((i) => i.layerID === data.layerID)[0].shareAccess === ShareAccessType.SHARE_READONLY;

        const channelLine = {
            id: data.annotationID,
            layerID: data.layerID,
            type: data.annotationType,
            x: lineStart.x,
            y: lineStart.y,
            startMouseX: 0,
            startMouseY: 0,
            currentMouseX: currMouse.x,
            currentMouseY: currMouse.y,
            endMouseX: lineEnd.x,
            endMouseY: lineEnd.y,
            midX: midPoint.x,
            midY: midPoint.y,
            spreadMiddle: spreadMiddle,
            color: `P${convertedForegroundColor.color.substring(1)}`,
            weight: data.penSize,
            stroke: stroke,
            strokeStyle: data.strokeType,
            startExtendChecked: data.isStartExtend,
            endExtendChecked: data.isEndExtend,
            lineX1: data.isStartExtend ? 0 : 50,
            lineX2: data.isEndExtend ? 266 : 220,
            startExtendPoint: startExtendPoint,
            endExtendPoint: endExtendPoint,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            isSelected: false,
            isDeleted: false,
            isReadOnlyAnnotation: isReadOnlyAnnotation
        }

        yield put({
            type: ActionTypes.HANDLE_CHANNEL_LINE_CLICK_SUCCESS,
            channelLine: channelLine
        });
        yield put({
            type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
            annotation: channelLine.id
        });

        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsChannelLineSaga.js, processChannelLineData ${error}`);
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        PrintMode.printing && console.log("PRINT:--Tracker--*** Data processing Error in GraphicsChannelLineSaga *** for -", TimeTrackingWindow.currentSymbol);
    }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchHandleChannelLineClick() {
    yield takeLatest(ActionTypes.HANDLE_CHANNEL_LINE_CLICK, handleClick);
};

export function* watchHandleChannelLineDraw() {
    yield takeLatest(ActionTypes.HANDLE_CHANNEL_LINE_DRAW, handleMouseMove);
};

export function* watchProcessChannelLineData() {
    yield takeEvery(ActionTypes.PROCESS_CHANNEL_LINE_DATA, processChannelLineData);
};