import {
  CurrencyConversion
} from "../../Constants/CurrencyConversion";
import { DataGraphConstants } from "../../Constants/DataGraphConstants";
import SettingsStore from "SettingsStore";
import SymbolType from "../../Constants/SymbolType";

const {
  ActionTypes
} = CurrencyConversion;

const initialState = {
  currencyData: [],
  esCurrencyData: [],
  defaultCurrency: "",
  defaultCurrencySign: "",
  defaultWSCurrency: "",
  isDualCurrency: false,
  isCurrencyConversionChecked: false,
  isMatchFinancials: true,
  selectedCurrencyCode: "USD"
}
const currencyConversionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENCY_DATA:
      return {
        ...state,
        currencyData: action.currencyData
      }
      case ActionTypes.SET_ES_CURRENCY_DATA:
      return {
        ...state,
        esCurrencyData: action.esCurrencyData
      }
    case ActionTypes.SET_DEFAULT_CURRENCY_SIGN: {
        const viewSetting = SettingsStore.getConsoleSettings();
        viewSetting.isMatchFinancials = action.isMatchFinancials;
        SettingsStore.saveSettings();
      let defaultCurrencySign = '';
      if (action.isDualCurrency && action.isMatchFinancials) {
        defaultCurrencySign = "→";
      } else if (action.isDualCurrency && !action.isMatchFinancials) {
        defaultCurrencySign = "/";
      } else {
        defaultCurrencySign = '';
      }
      return {
        ...state,
        defaultCurrencySign,
        isMatchFinancials: action.isMatchFinancials
      }
    }

    case ActionTypes.SET_IS_CURRENCY_CONVERSION_CHECKED: {
      const viewSetting = SettingsStore.getConsoleSettings();
      viewSetting.isCurrencyConversionChecked = action.isCurrencyConversionChecked;
      SettingsStore.saveSettings();
      return {
        ...state,
        isCurrencyConversionChecked: action.isCurrencyConversionChecked
      }
    }

    case ActionTypes.SET_INIT_CURRENCY_SETTING:
      return {
        ...state,
        defaultCurrency: action.setting.defaultCurrency,
        isDualCurrency: action.setting.isDualCurrency,
        isCurrencyConversionChecked: action.setting.isCurrencyConversionChecked,
        isMatchFinancials: action.setting.isMatchFinancials,
        selectedCurrencyCode: action.setting.selectedCurrencyCode
      }

    case ActionTypes.SET_SELECTED_CURRENCY_CODE: {
      const viewSetting = SettingsStore.getConsoleSettings();
      viewSetting.selectedCurrencyCode = action.selectedCurrencyCode;
      SettingsStore.saveSettings();
      return {
        ...state,
        selectedCurrencyCode: action.selectedCurrencyCode
      }
    }
    case DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY: {
      const SymbolInfo = action.pricePanelData.SymbolInfo;
      let defaultCurrency = SymbolInfo.DefaultCurrency && SymbolInfo.DefaultCurrency.Code ? SymbolInfo.DefaultCurrency.Code : SymbolInfo.DefaultCurrency;
      let defaultWSCurrency = SymbolInfo.WSCurrency || null;
      let isDualCurrency = false;

      if (defaultCurrency === null) {
        isDualCurrency = false;
      }
      else if (SymbolInfo.SymTypeEnum === SymbolType.FUTURECONTRACTS || SymbolInfo.SymTypeEnum === SymbolType.CASHSPOTS) {
        isDualCurrency = false;
      } else if (defaultCurrency === 'GBP' || defaultCurrency === 'ZAR') {
        isDualCurrency = true;
      } else {
        isDualCurrency = defaultWSCurrency !== null && defaultCurrency !== defaultWSCurrency;
      }
      if (isDualCurrency && defaultCurrency) {
        if (defaultCurrency === 'GBP') {
          defaultCurrency = "GBX";
        } else if (defaultCurrency === 'ZAR') {
          defaultCurrency = "ZAX";
        }
      } else {
        defaultCurrency = defaultCurrency === null ? '' : defaultCurrency;
      }
      const viewSetting = SettingsStore.getConsoleSettings();
      viewSetting.defaultCurrency = defaultCurrency;
      viewSetting.isDualCurrency = isDualCurrency;
      SettingsStore.saveSettings();

      if (!isDualCurrency) {
        defaultWSCurrency = null
      }
      let defaultCurrencySign = '';
      if (isDualCurrency && state.isMatchFinancials) {
        defaultCurrencySign = "→";
      } else if (isDualCurrency && !state.isMatchFinancials) {
        defaultCurrencySign = "/";
      } else {
        defaultCurrencySign = '';
      }
      return {
        ...state,
        defaultCurrency,
        defaultWSCurrency,
        isDualCurrency,
        defaultCurrencySign
      }
    }

    default:
      return state
  }
}
export default currencyConversionReducer;
