import AlertActions from "../../Actions/AlertActions";
import { dispatch } from "../../Redux/dispatch";
// import ConsoleStore from 'ConsoleStore';
import NavType from "NavType";
import PeriodicityHelper from "../../Utils/PeriodicityHelper";
import ReactHtmlParser from "react-html-parser";
import { RouteToDataGraph } from "../../Actions/ConsoleActions";
import SettingsAction from "../../Actions/SettingsActions";
import SettingsStore from 'SettingsStore';
import { initPeriodicityButton, onPlotSymbol } from "../../Actions/DatagraphActions";
import React, { Component } from "react";

export default class NotificationItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }
    componentWillUnmount() {
        this.cancelRequest = true;
    }

    removeNotificationItem(index) {
        this.props.removeNotificationItem(index, true)
    }

    onSymbolClick(e, symbol, periodicity) {
        periodicity = PeriodicityHelper.convertToGraphType(periodicity);
        const userSettings = SettingsStore.getConsoleSettings();
        dispatch(initPeriodicityButton(userSettings))

        const curSymbol = symbol;

        setTimeout(() => {
            if (userSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity == periodicity
                && curSymbol == symbol) {
                SettingsAction.updateTabSelection("DATAGRAPH");

                if (userSettings.ActiveNav !== NavType.NavSymbol)
                    RouteToDataGraph(symbol.trim(), NavType.NavSymbol);
                else
                dispatch(onPlotSymbol(symbol));

                // TabDataGraphActionClass.onPeriodicityChange(periodicity, SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings);
            }
        }, 500);

        AlertActions.removeNotificationItem(this.props.index, true);
    }
 
    render() {
        return (
            <tr className={'unread list-notify-inner'}>
                <td className="list-notify-inner-child text-ellipsis">
                    <a className="close x-large-normal" onClick={() => this.removeNotificationItem(this.props.index, true)} >&times;</a>
                    <span className="highlight-stock-name medium-bold" onClick={(e) => this.onSymbolClick(e, this.props.symbol, this.props.periodicity)}>{this.props.localSymbol} </span>
                    <span className="medium-normal highlight-stock-details"> {ReactHtmlParser(this.props.message + ((this.props.note && this.props.note.length > 0) ? ` | Note: ${this.props.note}` : ''))} </span>
                </td>
            </tr>
        );
    }
}



