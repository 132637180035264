import AlertsApi from "../ServiceApi/Apis/AlertsApi.js";
import AlertStore from 'AlertStore';
import AppDispatcher from "AppDispatcher";
import Base64Serialization from "Base64Serialization";
import ChartApi from "../ServiceApi/Apis/ChartApi.js";
import {  clearHoldingData } from "./DgHoldingsAction.js";
import { clearOwnerShipReportData } from "./OwnershipActions.js";
import { clearRIPanelData } from "./RIPanelOneilActions";
import ConsoleStore from "../Stores/ConsoleWindow/ConsoleStore.js";
import DatagraphSettings from "../Stores/ConsoleWindow/Settings/Modules/DataGraph/DatagraphSettings.js";
import dataGraphStore from "../Stores/NavModules/NavDataGraph/DataGraphStore";
import { dispatch } from "../Redux/dispatch.js";
import { FundamentalsConstants } from "../Constants/FundamentalsConstants.js";
import { getHubUserRole } from "./RelatedInfoPanelActions/RiPanelActions";
import { gridLineColorChange } from "./ComparisonActions.js";
import LocalizationStore from "LocalizationStore";
import LoginStore from "../Stores/Login/LoginStore.js";
import MiniListStore from "MiniListStore";
import NavType from "NavType";
import { OnClearKPIData } from './KpiGraphActions';
import { onPlotSymbol } from "./DatagraphActions.js";
import PeriodicityHelper from "../Utils/PeriodicityHelper.js";
import { setInitCurrencySetting } from "./CurrencyActions.js";
import { SettingsConstants } from "../Constants/SettingsConstants.js";
import SettingsStore from "SettingsStore";
import store from '../Redux/store.js';
import StringUtil from "../Utils/StringUtil.js";
import SymbolType from "SymbolType";
import TabType from "../Constants/TabType.js";
import TimeTrackingWindow from "TimeTrackingWindow";
import { updateNupTheme } from "./NupViewActions.js";
import UserInfoUtil from 'UserInfoUtil'
import { clearExternalData, themeChange } from "./ExternalDataUploaderActions";
import DefaultSettings from "../Stores/ConsoleWindow/Settings/DefaultSettings/DefaultSettings.js";
import BaseServiceApi from "BaseServiceApi";
var EntitlementType = BaseServiceApi.rayData["EntitlementType"];
import TabONeilSettings from "../Stores/ConsoleWindow/Settings/Modules/ListManager/TabONeilSettings.js";
import panarayDefault from "../ServiceApi/RequestHelper/PanarayDefault.js";

let isDgApiCallsInitiated = false;
const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
class SettingsActionClass {
  constructor() {
    this.annotationsResponse = { isAnnotationApiCallInitiated: false, isAnnotationLoadInitiated: false };
  }
  setUserSettings(data) {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.SET_USER_SETTINGS,
      data: data
    });
  }

  getAlertSettings() {
    AlertsApi.notificationSettings().then((result) => {
      AlertStore.getAlertNotificationSettings(result);
    });
  }

  async getUserSettings(result, isLogin = false) {
    let settings = "", appVersion = "";
    try {
      this.getAlertSettings();
      if (result) {
        const serialization = new Base64Serialization();
        settings = serialization.deserialize(result.binaryBytes);
        appVersion = result.appVersion;

        // Checking entitlement for selected list.
        const selectedListInfo = settings?.NavListManagerSettings?.TabONeilSettings?.SelectedListInfo
        const currentListID = selectedListInfo.SelectedParentCategoryType === ListCategoryType.FAVORITE_ListCategory ? selectedListInfo.SelectedActualListId : selectedListInfo?.SelectedListId;

        if (currentListID && panarayDefault.listEntData[currentListID] && !UserInfoUtil.IsUserEntitled(panarayDefault.listEntData[currentListID])) {
          const tabONeilSettings = new TabONeilSettings();
          settings.NavListManagerSettings.TabONeilSettings.SelectedListInfo = tabONeilSettings.getDefaultSelectedListInfo();
        }
      }

      const localActiveNav = localStorage.getItem("_activeNav");
      const isResetCalled = JSON.parse(localStorage.getItem("isResetCalled"));

      if (isResetCalled === true) {
        settings = DefaultSettings.getDefaultSettings();
      }
      localStorage.setItem("isResetCalled", false)        

      let isLastActiveNav = true;
      if (settings && localActiveNav) {
        isLastActiveNav = settings.ActiveNav.indexOf(localActiveNav) !== -1 || settings.ActiveNav === localActiveNav;
      }
      if (!isLastActiveNav) {
        settings.ActiveNav = localActiveNav;
      }
      settings.isLogin = isLogin;

      /* Dispatching this method to set hubUser role for the new user login */
      dispatch(getHubUserRole(isLogin))

      AppDispatcher.handleServerAction({
        actionType: SettingsConstants.ActionTypes.GET_USER_SETTINGS,
        data: settings
      });

      AppDispatcher.handleServerAction({
        actionType: SettingsConstants.ActionTypes.GET_CURRENT_APPVERSION,
        data: appVersion
      });

      settings = SettingsStore.getConsoleSettings();

      if (settings) {
        dispatch(setInitCurrencySetting(settings));
      }

      // TODO - returnUrl
      this.isNavSymbol = settings.ActiveNav.indexOf(NavType.NavSymbol) !== -1 || settings.ActiveNav === NavType.NavSymbol;
      if (isLogin && !StringUtil.isEmpty(settings) && !LoginStore.getState().returnUrl && (settings.ActiveNav.indexOf(NavType.NavSymbol) !== -1 || settings.ActiveNav === NavType.NavSymbol)) {
        TimeTrackingWindow.resetTimeTracker(true);
        TimeTrackingWindow.initTimeTracker();
        TimeTrackingWindow.beginTimeTracker();
        TimeTrackingWindow.isLogin = true;
        if (this.pricePanelResponse && this.pricePanelResponse.responseHeader.error && this.pricePanelResponse.responseHeader.errorCode === 404) {
          isDgApiCallsInitiated = false;
          TimeTrackingWindow.onSymbolChartFailed();
          if (LocalizationStore.getLang() !== UserInfoUtil.getCurrentLang()) {
            LocalizationStore.setLang(UserInfoUtil.getCurrentLang());
          }
          let dataGraphSettings = new DatagraphSettings();
          let defaultSymbol = dataGraphSettings.getDefaultDatagraphSettings().symbol;
          dispatch(onPlotSymbol(defaultSymbol));
        }
        ConsoleStore.getState().isDGApiCompleted = true;
      }
      else if (isLogin && !StringUtil.isEmpty(settings)) {
        TimeTrackingWindow.resetTimeTracker(true, false, NavType.NavLists);
        TimeTrackingWindow.initTimeTracker();
        TimeTrackingWindow.beginTimeTracker(NavType.NavLists);
        TimeTrackingWindow.isLogin = true;
      }
      LoginStore.emitChange();
    }
    catch (e) {
      AppDispatcher.handleServerAction({
        actionType: SettingsConstants.ActionTypes.GET_USER_SETTINGS,
        data: settings
      });
    }
  }

  setFundamentalTabHeader(pricePanelData) {
    const symbolInfo = pricePanelData.PricePanelData.SymbolInfo
    const instrumentsIncludeArray = [SymbolType.USSTOCK, SymbolType.HISTORICSTOCK, SymbolType.INTERNATIONALSTOCK]
    if (instrumentsIncludeArray.includes(symbolInfo.SymTypeEnum)) {
      AppDispatcher.handleViewAction({
        actionType: FundamentalsConstants.ActionTypes.GET_FUNDAMENTAL_HEADER_DATA,
        headerdata: pricePanelData.headers,
        symbolInfo
      })
    }
  }

  setDDEToolTip(data) {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.DDE_STATUS_CHANGED,
      data: data
    });
  }

  updateTabSelection(tabKey) {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.DATAGRAPH_UPDATE_TAB,
      data: tabKey
    });

  }

  // toggleOwnershipTab() {
  //   AppDispatcher.handleViewAction({
  //     actionType: SettingsConstants.ActionTypes.TOGGLE_OWNERSHIP_TAB,
  //     data: null
  //   });
  // }

  saveUserSettings() {
    setTimeout(() => {
      AppDispatcher.handleViewAction({
        actionType: SettingsConstants.ActionTypes.SAVE_USER_SETTTINGS,
        data: null
      });
    }, 0);

  }

  getSymbolPriceData(syminfo, periodicity) {
    //ConsoleApi.getSymbolPriceData(syminfo.Osid, periodicity, syminfo.SymTypeEnum, 0).then((pricedata) => {
    ChartApi.getStockPrices(syminfo.Osid, PeriodicityHelper.getPeriodicityValue(periodicity), syminfo.SymTypeEnum, 0).then((pricedata) => {
      AppDispatcher.handleServerAction({
        actionType: SettingsConstants.ActionTypes.GET_SYMBOL_PRICE_DATA,
        data: pricedata
      });
    });
  }


  onResetSettings() {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.RESET_SETTINGS,
      data: null
    });
  }

  onMiniListChart(sym, isMiniListPlay) {
    let self = this;
    ChartApi.findStockInfo(sym).then((result) => {
      result.Osid = result.Osid.toNumber();
      result.IsMiniListPlay = isMiniListPlay;
      dataGraphStore.processStockInfoDates(result);
      MiniListStore.setSymbolInfo(result);
      self.onSymbolChanged(result);

    });
  }

  onMiniListPlay(sym, isMiniListPlay) {
    if (isMiniListPlay) {
      dispatch(clearExternalData(true));

      AppDispatcher.handleViewAction({
        actionType: SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED,
        data: sym
      });
      store.dispatch(clearRIPanelData());
      store.dispatch(OnClearKPIData());

      let dataGraphStoreState = dataGraphStore.getState();
      dataGraphStoreState = dataGraphStoreState ? dataGraphStoreState : {};
      const selectedTabKey = dataGraphStoreState.SelectedTabKey;
      if (selectedTabKey === TabType.Ownership) {
        store.dispatch(clearOwnerShipReportData(true))
      }
      else if (selectedTabKey === TabType.Holdings) {
        dataGraphStoreState.SymbolInfo = null;
        store.dispatch(clearHoldingData());
      }
      dataGraphStore.updateSymbol(sym);
      MiniListStore.onMiniListPlaying();
    }
    const nodeCount = ConsoleStore.getState().nodeCount;
    dispatch(onPlotSymbol(sym, true, nodeCount, isMiniListPlay));
  }


  onGridLineColorChanged() {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.GRID_COLOR_CHANGED,
      data: null
    });
    //comapre graph redux dispatch
    dispatch(gridLineColorChange());
    dispatch(updateNupTheme())
  }
  onThemeChanged(theme) {
    AppDispatcher.handleServerAction({
      actionType: SettingsConstants.ActionTypes.THEME_CHANGED,
      data: theme
    });
    //comapre graph redux dispatch
    dispatch(gridLineColorChange());

    // external data redux dispatch
    dispatch(themeChange(theme));
    this.saveUserSettings();
  }

  updateListExplorerTabSelection(tabKey) {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.LIST_EXPLORER_UPDATE_TAB,
      data: tabKey
    });
  }

  setSelectQuoteServiceOption(data) {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.SET_SELECTQUOTESERVICEOPTION,
      data: data
    });
  }

  symbolConvertedDialogAvailableChange(isSymbolConvertedDialogAvailable) {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.SYMBOL_CONVERTED_DIALOG_AVAILABLE_CHANGE,
      data: isSymbolConvertedDialogAvailable
    })
  }
  animationMessageDialogAvailableChange(isAnimationMessageDialogAvailable) {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.ANIMATION_MESSAGE_DIALOG_AVAILABLE_CHANGE,
      data: isAnimationMessageDialogAvailable
    });
  }
  updateCompareTabSelection(tabKey) {
    AppDispatcher.handleViewAction({
      actionType: SettingsConstants.ActionTypes.COMPARE_UPDATE_TAB,
      data: tabKey
    });
  }
}

const SettingsActions = new SettingsActionClass();
export default SettingsActions;

export const updateComparisonTabSelection = (tabKey) => ({
  type: SettingsConstants.ActionTypes.COMPARE_UPDATE_TAB,
  tabKey: tabKey
});
