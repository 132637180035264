import { ExternalDataConstants } from "../../Constants/ExternalDataUploader";
const { ActionTypes } = ExternalDataConstants;
import BaseServiceApi from 'BaseServiceApi';

const initState = {
  explorerListItems: [{
    listType: BaseServiceApi.rayData["ListType"].EVENTSERIES_List,
    name: "EVENTS",
    childNodes: [],
    isFolder: true,
    expandNode: false
  },{
    listType: BaseServiceApi.rayData["ListType"].TIMESERIES_List,
    name: "TIME SERIES",
    childNodes: [],
    isFolder: true,
    expandNode: false
  }],
  uploadDialogShow: false,
  isrecurringchecked: false,
  tempLocalFile: null,
  isExternalUrl: false,
  isFtpUrl: false,
  isSFtpUrl: false,
  isWonSftpPath: false,
  isGoogleUrl: false,
  tempLocalFileName: '',
  scheduleTime: { displayName: "08:30 PM", id: { hour: "20", minute: "30" } },
  appendExixtingData: false,
  overwriteExistingData: true,
  uploadFileOnClose: false,
  ftpUser: '',
  ftpPass: '',
  timeZone: "Eastern Standard Time",
  showTimeChanger: false,
  showFrequencyChanger: false,
  showListLoader: true,
  selectedNode: null,
  selectedNodes: [],
  // currentListData: [],
  // listIsEmpty: true,
  newListName: "Untitled Data Set",
  lastUploadedListId: '',
  fileUploadResponse: [],
  wizardDialogShow: false,
  deletedListItems:[],
  frequencyArray: [
    'Everyday','Every Monday','Every Tuesday',
    'Every Wednesday','Every Thursday','Every Friday',
    'Every Saturday','Every Sunday'
  ],
  frequencyType: 6,
  saveEnabled: false,
  isUpdated: false,
  reUploadListId: null,
  reuploadedListId: null,
  showWizardDialogError: null,
  exceptionDialogShow: false,
  exceptionMessage: null,
  fileExceptionDialogShow: false,
  fileExceptionMessage: false,
  fileUploadDialogShow: false,
  isFileFormatError: false,
  isCreateList: false,        
  duplicateNode: 0,
  selectedListType: 0,
  nodeToRename: 0,
  isApiComplete: false
}

const ExternalDataUploaderReducer = (state = initState, action) => {
  switch (action.type) {
    
    case ActionTypes.INIT_EXTERNAL_LISTS_SUCCESS:
      return {
        ...state,
        explorerListItems: action.dataItems,
        showListLoader: false,
        newListName: "Untitled Data Set",
        isApiComplete: true,
        selectedNodes: []
      }
    case ActionTypes.EXTERNAL_SELECTED_NODE_SUCCESSS:
      return {
        ...state,        
        selectedNode: action.listId
      }
    case ActionTypes.OPEN_CLOSE_MODAL:
      return {
        ...state,
        isSettingsDialog: action.data
      }
    case ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_TOGGLE:
      return {
        ...state,
        uploadDialogShow: action.showDialog,
        timeZone: action.winTimeZone,
        lastUploadedListId: action.lastUploadedList,
        reUploadListId: action.uploadListId
      }
    case ActionTypes.TOGGLE_RECURRING_UPLOAD_CHANGE:
    return {
        ...state,
        isrecurringchecked: action.showRecurring
    }
    case ActionTypes.SAVE_UPLOADED_FILE_TEMP: 
    return {
      ...state,
      tempLocalFile: action.file,
      isExternalUrl: action.isExternalUrl,
      isFtpUrl: action.isFtpUrl,
      isSFtpUrl: action.isSFtpUrl,
      isWonSftpPath: action.isWonSftpPath,
      isGoogleUrl: action.isGoogleUrl,
      tempLocalFileName: action.tempLocalFileName,
      scheduleTime: action.scheduledTiming,
      saveEnabled: action.enableSave,
      ftpUser: action.ftpUsername,
      ftpPass: action.ftppassword
    }
    case ActionTypes.SAVE_UPLOADED_TIME_CHANGE:
      return {
        ...state,
        scheduleTime: { displayName: action.displayName, id: { hour: action.hour, minute: action.minute } }
      }
    case ActionTypes.APPEND_FILE_CLICK:
      return {
        ...state,
        appendExixtingData: true,
        overwriteExistingData: false
      }
    case ActionTypes.OVERWRITE_FILE_CLICK:
      return {
        ...state,
        appendExixtingData: false,
        overwriteExistingData: true
      }
    case ActionTypes.UPLOAD_DATA_ONCLOSE:
      return {
        ...state,
        uploadFileOnClose: !state.uploadFileOnClose
      }
    case ActionTypes.FTP_USERNAME_CHANGE_SUCCESS:
      return {
        ...state,
        ftpUser: action.uname,
        saveEnabled: action.enableSave
      }
    case ActionTypes.FTP_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        ftpPass: action.pass,
        saveEnabled: action.enableSave
      }
    case ActionTypes.TOGGLE_TIME_CHANGER:
      return {
        ...state,
        showTimeChanger: !state.showTimeChanger
      }
    case ActionTypes.TOGGLE_FREQUENCY_CHANGER:
    return {
      ...state,
      showFrequencyChanger: !state.showFrequencyChanger
    }
    case ActionTypes.SET_SELECTED_NODES_SUCCESS:
      return {
        ...state,
        selectedNodes: action.selectedNodes
      }
      case ActionTypes.SET_RENAME_NODE_SUCCESS:
      return {
        ...state,        
        nodeToRename: action.nodeToRename,
        selectedListType: action.selectedListType ? action.selectedListType : state.selectedListType,
        isUpdated: action.isUpdate
      }
    case ActionTypes.IS_CREATE_LIST_SUCCESS:      
          return {
            ...state,        
            isCreateList: action.isCreateList
          }
    case ActionTypes.UNLOAD_EXTERNAL_STATE_DATA:
      return {
        ...state,
        uploadDialogShow: false,
        isrecurringchecked: false,
        tempLocalFile: null,
        isExternalUrl: false,
        isFtpUrl: false,
        isSFtpUrl: false,
        isGoogleUrl: false,
        tempLocalFileName: '',
        scheduleTime: { displayName: "08:30 PM", id: { hour: "20", minute: "30" } },
        appendExixtingData: false,
        overwriteExistingData: true,
        uploadFileOnClose: false,
        ftpUser: '',
        ftpPass: '',
        timeZone: '',
        showTimeChanger: false,
        showFrequencyChanger: false,
        showListLoader: true,
        saveEnabled: false,
        reUploadListId: '',
        frequencyType: 6
      }
      case ActionTypes.SET_DUPLICATE_NODE_SUCCESS:
      return {
        ...state,        
        duplicateNode: action.duplicateNode,
        selectedListType: action.selectedListType
      }
      // case ActionTypes.EMPTY_LIST_DATA:
      // return {
      //   ...state,     
      //   currentListData: action.currentListData,
      //   listIsEmpty: action.isEmptyList
      // }
     case ActionTypes.SET_LIST_NAME_ONCHANGE:
      return {
        ...state,     
        newListName: action.listName
      }
      case ActionTypes.PUSH_NEW_LIST_NODE:
      action.newList.orderNum = -1;
      return {
        ...state, 
        explorerListItems: state.explorerListItems.map(
            (content) => content.listType === action.newList.listType ? {...content,expandNode: true, childNodes: content.childNodes.concat(action.newList)}
                                    : content
        ),
        wizardDialogShow: false,
        newListName: "Untitled Data Set",
        selectedNode: action.newList
        }
    case ActionTypes.SHOW_WIZARD_DIALOG:
    return {
      ...state,
      fileUploadResponse: action.fileUploadRes,
      wizardDialogShow: action.showWizardDialog,
      exceptionMessage: action.exceptionMessage,
      exceptionDialogShow: action.exceptionDialogShow,
      uploadDialogShow: false,
      isFileFormatError: action.isFileFormatError ? true : false
    }
    case ActionTypes.EXTERNAL_FILE_RECURRING_UPLOAD:
    return {
      ...state,
      explorerListItems: state.explorerListItems.map(
                          (content) => content.listType === action.listType ? {...content,expandNode: true, 
                                                                childNodes: content.childNodes.filter((item) => {
                                                                    if (item.nodeId.low === action.listId) {
                                                                        item.isReccuring = action.isRecurring;
                                                                    }
                                                                    if (item.nodeId.low === action.listId) {
                                                                      item.isEmpty = action.setEmpty;
                                                                  }return item;
                                                                })}
                                : content         
    )
    }
    case ActionTypes.CLOSE_UPLOAD_WIZARD:
    return {
      ...state,
      wizardDialogShow: false
    }
    case ActionTypes.CLOSE_FILE_UPLOAD_WIZARD:
    return {
      ...state,
      fileExceptionDialogShow: false,
      tempLocalFileName: ''
    }
    case ActionTypes.REMOVE_LISTS_FROM_SOURCE:
    //   let sourceListItems = state.explorerListItems;
    //   let prevDeletedLists =[];
    //   const deleteLists = action.deleteLists;
    //   for (let k = 0; k < sourceListItems.length; k++) {
    //      for(let index=0; index< deleteLists.length;index++) {
    //         if(sourceListItems[k].nodeId.low == deleteLists[index]) {
    //           prevDeletedLists.push(sourceListItems[k]);
    //         }
    //      }
    //   }
    //  for (let k = 0; k < deleteLists.length; k++) {
    //     sourceListItems = sourceListItems.filter((item) => item.nodeId.low  != deleteLists[k]);
    //   }
    return {
      ...state,
      explorerListItems: action.sourceLists,
      deletedListItems:action.deletedLists,
      showListLoader:false,
      newListName: "Untitled Data Set"
    }
    case ActionTypes.CHANGE_LIST_NAME_IN_SOURCE:
      const matchList = state.explorerListItems.findIndex(itm => itm.listType == action.listNodeToRename.listType);
      return {
        ...state,
        explorerListItems: state.explorerListItems.map(
          (content, i) => i === matchList ? {...content,childNodes: content.childNodes.filter((item) => {
          if (item.nodeId.low === action.listNodeToRename.nodeId.low) {          
              item.name = action.listNodeToRename.name;
          }return item;
          })
        } : content
      )
    }

    case ActionTypes.RESTORE_DELETED_LISTS:
      return {
        ...state,
        explorerListItems: action.restoredData
      }

    case ActionTypes.SORT_SOURCE_LIST:
      return {
        ...state,
        explorerListItems: action.sortedData
      }
    case ActionTypes.CHANGE_UPLOAD_FREQUENCY:
      return{
        ...state,
        frequencyType: action.freqId,
        showFrequencyChanger: false
      }
    
    case ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_REPEAT_TOGGLE:
      return {
        ...state,
        uploadDialogShow: action.showDialog,
        reUploadListId: action.uploadListId,        
        isrecurringchecked: action.isrecurring,
        isExternalUrl: action.isExternalUrl,
        isFtpUrl: action.isFtpUrl,
        isSFtpUrl: action.isSFtpUrl,
        isGoogleUrl: action.isGoogleUrl,
        tempLocalFileName: action.filePath,
        tempLocalFile: action.filePath,
        scheduleTime: action.schdeuleTime,
        appendExixtingData: action.isoverwrite ? false : true,
        overwriteExistingData: action.isoverwrite ? true : false,
        uploadFileOnClose: false,
        ftpUser: action.ftpusername,
        ftpPass: action.ftppassword,
        timeZone: action.winTimeZone,
        saveEnabled: true,
        lastUploadedListId: action.lastUploadedList,
        frequencyType: action.uploadFreq
      }
      case ActionTypes.EXTERNAL_FILE_RE_UPLOADED: 
        return{
          ...state,
          reuploadedListId: action.reuploadedListId,
          fileUploadResponse: action.fileUploadRes,
          showWizardDialogError: action.showWizardDialogError,
          wizardDialogShow: action.showWizardDialogError,
          exceptionDialogShow: action.exceptionDialogShow,
          exceptionMessage: action.exceptionMessage,
          uploadDialogShow: false
        }
      case ActionTypes.IS_UPDATED:
          return{
                ...state,
                isUpdated: action.isUpdated
              }
      case ActionTypes.SHOW_EXCEPTION_DIALOG:
      return{
            ...state,
            exceptionDialogShow: action.showExceptionDialog,
            exceptionMessage: action.exceptionMessage,
            uploadDialogShow: false
          }
      case ActionTypes.SHOW_FILE_EXCEPTION_DIALOG:
        return{
          ...state,
          fileExceptionDialogShow: action.showExceptionDialog,
          fileExceptionMessage: action.exceptionMessage,
          uploadDialogShow: false
        }
      case ActionTypes.CLOSE_EXCEPTION_WINDOW:
      return{
            ...state,
            exceptionDialogShow: false,
            exceptionMessage: ''
          }
      case ActionTypes.TOGGLE_FOLDER_VIEW:
          return{
            ...state, 
            explorerListItems: state.explorerListItems.map(
                (content) => content.listType === action.folderId ? {...content, expandNode: action.status}
                                      : content
            ),
            isUpdated: false
          }
          
      case ActionTypes.SET_EMPTY_NEW_USER:
          return {
            ...state, 
            explorerListItems: state.explorerListItems.map(
                (content) => content.listType === BaseServiceApi.rayData["ListType"].EVENTSERIES_List ? {...content,expandNode: true}
                                        : content
            )
          }
      default:
      return state
  }
}

export default ExternalDataUploaderReducer;