import React from "react";
import { useSelector } from "react-redux";

function GrowthRateControl() {
  const positiveBrushText = useSelector((state) => state.appColor.posDTColor)
  const negativeBrushText = useSelector((state) => state.appColor.negDTColor);
  const data = useSelector((state) => state.summaryReducer.growthData)

  const renderGrthRt3YrsEarning = () => {
    if (data && data.grthRt3YrsEarning && !data.IsRealEstateInvestmentTrust) {
      const color = data.isGrthRt3YrsEarningPositive ? positiveBrushText
        : negativeBrushText

      return <span className="sb-growth" style={{ color: color }}>{data.grthRt3YrsEarning}</span>;
    }
    return <span className="blank-text">--</span>;
  }

  const renderGrthRt5YrsEarning = () => {
    if (data && data.grthRt3YrsEarning && !data.IsRealEstateInvestmentTrust) {
      const color = data.isGrthRt5YrsEarningPositive ? positiveBrushText
        : negativeBrushText
      return <span className="sb-growth" style={{ color: color }}>{data.grthRt5YrsEarning}</span>;
    }
    return <span className="blank-text">--</span>;
  }

  return (<div className="growthRate-info-block">
    <div className="info-row">
      <div className="header-cell">
        <span className="earns">{data ? data.earningHeader : ""}</span>
      </div>
      <div className="header-cell">
        <span className="sales">{data ? data.salesHeader : ""}</span>
      </div>
    </div>
    <div className="info-row">
      <div className="data-cell">
        <span className="sb-year">{data ? data.grthRt3YrsDisplay : ""}</span>
        {renderGrthRt3YrsEarning()}
        <span className="sb-stability">{data && !data.IsRealEstateInvestmentTrust ? data.grthRt3YrsStabilty : ""}</span>
      </div>
      <div className="data-cell">
        <span style={{
          color: (data && data.isGrthRt3YrsSalesPositive)
            ? positiveBrushText
            : negativeBrushText
        }}>{data ? data.grthRt3YrsSales : ""}</span>
      </div>
    </div>
    <div className="info-row bor-top-trans">
      <div className="data-cell">
        <span className="sb-year">{data ? data.grthRt5YrsDisplay : ""}</span>
        {renderGrthRt5YrsEarning()}
        <span className="sb-stability">{data && !data.IsRealEstateInvestmentTrust ? data.grthRt5YrsStabilty : ""}</span>
      </div>
      <div className="data-cell">
        <span style={{
          color: (data && data.isGrthRt5YrsScalesPositive)
            ? positiveBrushText
            : negativeBrushText
        }}>{data ? data.grthRt5YrsSales : ""}</span>
      </div>
    </div>
  </div>);
}

export default GrowthRateControl