import React, { Component } from "react";
import AlertActions, { startAlertDataWorker } from "../../Actions/AlertActions";
import { notificationsWorker } from "WebWorker";
import StringUtil from 'StringUtil';
import { AlertConstants } from "../../Constants/AlertConstants";
import AlertStore from 'AlertStore';
import NotificationItem from "./NotificationItem.jsx";
import GroupNotificationItem from "./GroupNotificationItem.jsx";
import ErrorBoundary from "ErrorBoundary";
import UserInfoUtil from "UserInfoUtil";
import { dispatch } from "../../Redux/dispatch";
let notificationsWorkerObj;

export default class NotificationWindow extends Component {

    constructor(props) {
        super(props);
        // this.state = {
        //     notificationItems: AlertStore.newNotifications
        // };
        this.counter = 0;
        this.cancelRequest = false;
        this.timer;

        this.startNotificationsDataWorker = this.startNotificationsDataWorker.bind(this);
        this.postNotificationsDataWorker = this.postNotificationsDataWorker.bind(this);
        this.renderNotificationItems = this.renderNotificationItems.bind(this);
        this.removeNotificationItem = this.removeNotificationItem.bind(this);
        this.runTimer = this.runTimer.bind(this);
        this.startNotificationTimer = this.startNotificationTimer.bind(this);
        this.alertStoreStateChangeNewNotificationReceived = this.alertStoreStateChangeNewNotificationReceived.bind(this);
        this.alertStoreStateChangeRemoveNotification = this.alertStoreStateChangeRemoveNotification.bind(this);
        this.openMessageCenter = this.openMessageCenter.bind(this);
    }

    componentDidMount() {
        notificationsWorkerObj = new notificationsWorker();

        setTimeout(() => {
            this.startNotificationsDataWorker();
        }, 5000);


        // setTimeout(() => {
        //     AlertActions.getAllNotifications();
        // }, 10000);


        AlertStore.addChangeListener(this.alertStoreStateChangeNewNotificationReceived, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
        AlertStore.addChangeListener(this.alertStoreStateChangeRemoveNotification, AlertConstants.ActionTypes.REMOVE_NOTIFICATION);
        this.startNotificationTimer();
    }
    componentWillUnmount() {
        this.cancelRequest = true;
        AlertStore.removeChangeListener(this.alertStoreStateChangeNewNotificationReceived, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
        AlertStore.removeChangeListener(this.alertStoreStateChangeRemoveNotification, AlertConstants.ActionTypes.REMOVE_NOTIFICATION);
        clearTimeout(this.timer);
    }

    alertStoreStateChangeNewNotificationReceived() {
        this.setState({});
        this.startNotificationsDataWorker();

        setTimeout(() => {
            dispatch(startAlertDataWorker())
        }, 0);
    }

    alertStoreStateChangeRemoveNotification () {
        this.setState({});
        //this.startNotificationsDataWorker();
    }

    //Worker call to get alerts data
    async startNotificationsDataWorker() {
        // return new Promise((resolve, reject) => {

        const self = this;

        clearInterval(this.timeoutObj);

        //notificationsWorkerObj = notificationsWorkerObj ? notificationsWorkerObj : new notificationsWorker();
        notificationsWorkerObj.postMessage({ isAleartNotification: true });

        notificationsWorkerObj.onmessage = (e) => {

            if (e.data == "Error") {
                let TIMEOUT = 10000;
                // let lastTime = (new Date()).getTime();

                self.timeoutObj = setInterval(() => {

                    // var currentTime = (new Date()).getTime();
                    // if (currentTime > (lastTime + TIMEOUT + 1000)) {
                    self.startNotificationsDataWorker();
                    // }
                    // lastTime = currentTime;
                }, TIMEOUT);
            }
            else {
                self.postNotificationsDataWorker(e.data);
            }
        }
        notificationsWorkerObj.onerror = () => {
            self.startNotificationsDataWorker();
        }

        // });
    }

    postNotificationsDataWorker(data) {

        if (!StringUtil.isEmpty(data.notifications)) {

            AlertActions.handleNewNotificationsReceived(data.notifications);
        }
        else if (!this.cancelRequest) {
            setTimeout(() => {
                this.startNotificationsDataWorker();
            }, 5000);
        }
    }

    openMessageCenter () {
        AlertActions.openMessageCenter();
    }

    startNotificationTimer() {
        this.timer = setTimeout(() => {
            this.runTimer();
            this.startNotificationTimer();
        }, 1000);
    }

    runTimer() {

        let removeItemIndex = [];

        let notifications = AlertStore.getNotifications();

        for (let i = 0; i < notifications.length; i++) {

            if (notifications[i].type != 'blankNotificationItemData') {
                if (notifications[i].timer && notifications[i].timer > 0) {
                    notifications[i].timer--;
                }
            }

            if (notifications[i].timer <= 0) {
                removeItemIndex.push(i);
            }
        }

        for (let i = 0; i < removeItemIndex.length; i++) {
            this.removeNotificationItem(removeItemIndex[i], false);
        }

        this.setState({});
    }

    removeNotificationItem(index, markAsRead) {
        AlertActions.removeNotificationItem(index, markAsRead);
    }

    renderNotificationItems() {
        let newNotifications = AlertStore.getNotifications();

        let renderC = [];

        for (let i = 0; i < newNotifications.length; i++) {
            if (newNotifications[i].type != 'blankNotificationItemData') {
                if (newNotifications[i].isGroupNotification) {
                    let message = newNotifications[i].length;
                    renderC.push(<GroupNotificationItem key={i} index={i}
                        message={message} openMessageCenter={this.openMessageCenter} />);
                } else {
                    renderC.push(<NotificationItem key={i} index={i} periodicity = {newNotifications[i].periodicity} symbol = {newNotifications[i].symbol} localSymbol={(UserInfoUtil.hasLocalSymbol() && newNotifications[i].localSymbol) ? newNotifications[i].localSymbol : newNotifications[i].symbol}
                        message={newNotifications[i].message} note={newNotifications[i].note} removeNotificationItem={this.removeNotificationItem}
                    />);
                }
            }
        }

        return <table className="notify-bottom"><tbody>{renderC}</tbody></table>;
    }

    render() {
        return (
            <ErrorBoundary>
                <div id="notificationWindow" className="notification-window">
                    <div className="notification-box">
                        {this.renderNotificationItems()}
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}


