import React from "react";
import ReactDom from "react-dom";
import ColumnCustomFilterControl from "./ColumnCustomFilterControl.jsx";
import { ButtonToolbar, DropdownButton, MenuItem, FormGroup, FormControl, Overlay } from "react-bootstrap";
import { ToolTip } from "./ToolTip.jsx";
import BaseServiceApi from 'BaseServiceApi';
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
let ValueOperatorType = BaseServiceApi.rayData["ValueOperatorType"];
let ListColumnDataType = BaseServiceApi.rayData["ListColumnDataType"];
export default class NonEditableFilter extends React.Component {
    constructor(props) {
        super(props);
        this.applyFilter = this.applyFilter.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.clearField = this.clearField.bind(this);
        this.getDiv = this.getDiv.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.getMenuOperator = this.getMenuOperator.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.valueOperator = ColumnCustomFilterControl.getSupportedValueOperators(this.props.colData.dataType, this.props.colData.dataItemId);
        this.state = {
            title: this.valueOperator[0].operatorString,
            operator: this.valueOperator[0].valueOperatorType,
            inputValue: "",
            isValue: false,
            inputHasValue: false,
            previouseValue: "",
            previouseOperator: this.valueOperator[0].valueOperatorType,
            show: false
        }
    }

    UNSAFE_componentWillMount() {
        let inputValue = this.getValue();
        let operator = this.getOperator();
        let title = this.getOperatorText(operator);
        if (inputValue.length > 0) {
            this.setState({
                inputValue: inputValue,
                previouseValue: inputValue,
                operator: operator,
                previouseOperator: operator,
                title: title,
                inputHasValue: true,
                isValue: true,
                show: false
            });
        }
        else {
            this.setState({
                inputHasValue: false,
                previouseValue: "",
                previouseOperator: ValueOperatorType.EQ_ValueOperator,
                show: false
            });
        }

    }

    componentDidMount() {
        ReactDom.findDOMNode(this.inputText).select();
    }

    getOperatorText(operator) {
        return ColumnCustomFilterControl.toDisplayTextOperator(parseInt(operator)).toString();
    }

    getOperator() {
        if (this.props.activeColumn == null) {
            return ValueOperatorType.EQ_ValueOperator;
        }
        if (this.props.colData.dataItemId == this.props.activeColumn.dataItemId) {
            return this.props.activeColumn.valueOperator.toString();
        }
    }

    hasValue() {
        return this.state.hasValue;
    }

    getValue() {
        if (this.props.activeColumn == null) {
            return "";
        }
        if (this.props.colData.dataItemId == this.props.activeColumn.dataItemId) {
            return this.props.activeColumn.values.toString();
        }
    }

    applyFilter() {
        let operator = this.state.operator;
        let value = this.state.inputValue;
        let previouseValue = this.state.previouseValue;
        let previouseOperator = this.state.previouseOperator;
        if (value != previouseValue) {
            if (value != previouseValue || operator != previouseOperator) {
                this.props.applyFilter(operator, value);
            }
            else {
                this.props.applyFilter(operator, value, false, true);
            }
        }
        else {
            if (operator != previouseOperator && value !== "") {
                this.props.applyFilter(operator, value);
            }
            else {
                this.props.applyFilter(operator, value, false, true);
            }
        }

    }

    handleOnChange(e) {
        if (e.target.value.length == 0) {
            this.setState({ inputValue: e.target.value, isValue: false, show: false });
        }
        else {
            if (!this.validateInput(e.target.value)) {
                return false;
            }
            this.setState({ inputValue: e.target.value, isValue: true, show: false });
        }
    }
    clearField() {
        this.setState({ isValue: false, inputValue: "", show: false });
    }

    getDiv() {
        return this.boxDiv;
    }

    validateInput (value) {
        if (value.length > 0) {
            if (this.props.colData.dataType == ListColumnDataType.BOOL_ListColumnData) {
                let isValid = (value == 1 || value == 0) ? true : false;
                if (!isValid) {
                    this.setState({ inputValue: "", errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage6", "Acceptable input is boolean"), show: true });
                    return false;
                }
            }
        }
        return true;
    }

    getMenuOperator() {
        return this.valueOperator.map((menuItem) =>
        <MenuItem key={menuItem.valueOperatorType} eventKey={menuItem.valueOperatorType} className={(this.state.operator == menuItem.valueOperatorType) ? "active" : ""}>{LocalizationStore.getTranslatedData("LM_" + (menuItem.operatorString).replace(/ /g, ""), menuItem.operatorString)}</MenuItem>
       );
    }

    onSubmit(e) {
        this.applyFilter();
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onSelect(eventKey, event) {
        this.setState({
            title: event.target.textContent,
            operator: eventKey
        }, this.props.setCurrentFilterWidthHeight)
    }

    render() {
        let clearButton = this.state.isValue == true ?

            <span id="clear" className="icn-circle-custom1" onClick={this.clearField} ></span>

            : null;
        const sharedProps = {
            show: this.state.show,
            container: this.formgroup,
            target: () => ReactDom.findDOMNode(this.inputText)
        };
        return (
            <div className="list-filter-header">
                <div className="arrow_box" ref={(div) => { this.boxDiv = div; }}>
                    <div className="info-arrow-box">
                        <div className="head ellipsis">{LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + this.props.colData.dataItemId, this.props.colData.displayName)}</div>
                        <div className="inner-arrow-box">
                            <form className="form-inline" ref={(form) => { this.form = form; }} onSubmit={this.onSubmit} >
                                <FormGroup className="customListDropDown pull-left">
                                    <ButtonToolbar >
                                        <DropdownButton bsSize="xsmall" title={this.state.title} noCaret id="dd-arrow" onSelect={(eventKey, event) => {this.onSelect(eventKey, event)}}>
                                            {this.getMenuOperator()}

                                        </DropdownButton>
                                    </ButtonToolbar>
                                </FormGroup>
                                <FormGroup className="pos-relative1 " ref={(formgroup) => { this.formgroup = formgroup; }}>
                                    <div className="overflow-h display-b mart-2" >
                                        <FormControl className="small-normal clearButton" ref={(input) => { this.inputText = input; }} value={this.state.inputValue} type="text" placeholder="" onChange={this.handleOnChange} />
                                    </div>
                                    {clearButton}
                                    {this.state.errorMessage && this.state.errorMessage != '' ?
                                    <Overlay {...sharedProps} placement="bottom">
                                        <ToolTip>{this.state.errorMessage}</ToolTip>
                                    </Overlay>
                                    : null}
                                </FormGroup>
                            </form>
                            <div className="add-row">
                                <button type="button" className="add-row-apply" onClick={this.applyFilter}>{LocalizationStore.getTranslatedData("LM_Apply", "Apply")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
