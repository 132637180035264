import { DataGraphConstants } from '../../../Constants/DataGraphConstants'
const { ActionTypes } = DataGraphConstants;
const initialState = {
}

const SymbolViewReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}
export default SymbolViewReducer;