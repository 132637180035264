import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
export default class GetUserSettingRequest extends BaseServiceRequest {
  constructor() {
    super();
    super.addRequestUri("getworkspace.pb");
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.USER;
  }

  getMessageName() {
    return "WorkspaceResponse";
  }
}
