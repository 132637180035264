import NupLayoutType from "../../../../../Constants/NupLayoutType.js";

class LayoutSettings {

  getDefaultLayoutSettings() {
    let LayoutSettings = {};

    LayoutSettings.Single = {};
    LayoutSettings.Single.Layout2X2 = this.getDefaultSingleLayout2X2();
    LayoutSettings.Single.Layout2X3 = this.getDefaultSingleLayout2X3();
    LayoutSettings.Single.Layout3X2 = this.getDefaultSingleLayout3X2();
    LayoutSettings.Single.Layout3X3 = this.getDefaultSingleLayout3X3();
    LayoutSettings.Single.Layout4X4 = this.getDefaultSingleLayout4X4();

    LayoutSettings.Multiple = {};
    LayoutSettings.Multiple.Layout2X2 = this.getDefaultMultipleLayout2X2();
    LayoutSettings.Multiple.Layout2X3 = this.getDefaultMultipleLayout2X3();
    LayoutSettings.Multiple.Layout3X2 = this.getDefaultMultipleLayout3X2();
    LayoutSettings.Multiple.Layout3X3 = this.getDefaultMultipleLayout3X3();
    LayoutSettings.Multiple.Layout4X4 = this.getDefaultMultipleLayout4X4();

    return LayoutSettings;
  }

  getDefaultSingleLayout2X2() {
    let getDefaultSingleLayout2X2 = {};
    getDefaultSingleLayout2X2.Header = NupLayoutType.SingleLayout2X2;
    getDefaultSingleLayout2X2.IsPeriodicity = true;
    return getDefaultSingleLayout2X2;
  }

  getDefaultSingleLayout2X3() {
    let getDefaultSingleLayout2X3 = {};
    getDefaultSingleLayout2X3.Header = NupLayoutType.SingleLayout2X3;
    getDefaultSingleLayout2X3.isPeriodicity = true;
    return getDefaultSingleLayout2X3;
  }

  getDefaultSingleLayout3X2() {
    let getDefaultSingleLayout3X2 = {};
    getDefaultSingleLayout3X2.Header = NupLayoutType.SingleLayout3X2;
    getDefaultSingleLayout3X2.isPeriodicity = true;
    return getDefaultSingleLayout3X2;
  }

  getDefaultSingleLayout3X3() {
    let getDefaultSingleLayout3X3 = {};
    getDefaultSingleLayout3X3.Header = NupLayoutType.SingleLayout3X3;
    getDefaultSingleLayout3X3.isPeriodicity = true;
    return getDefaultSingleLayout3X3;
  }

  getDefaultSingleLayout4X4() {
    let getDefaultSingleLayout4X4 = {};
    getDefaultSingleLayout4X4.Header = NupLayoutType.SingleLayout4X4;
    getDefaultSingleLayout4X4.isPeriodicity = true;
    return getDefaultSingleLayout4X4;
  }



  getDefaultMultipleLayout2X2() {
    let getDefaultMultipleLayout2X2 = {};
    getDefaultMultipleLayout2X2.Header = NupLayoutType.MultipleLayout2X2;
    getDefaultMultipleLayout2X2.IsPeriodicity = false;
    return getDefaultMultipleLayout2X2;
  }

  getDefaultMultipleLayout2X3() {
    let getDefaultMultipleLayout2X3 = {};
    getDefaultMultipleLayout2X3.Header = NupLayoutType.MultipleLayout2X3;
    getDefaultMultipleLayout2X3.isPeriodicity = false;
    return getDefaultMultipleLayout2X3;
  }

  getDefaultMultipleLayout3X2() {
    let getDefaultMultipleLayout3X2 = {};
    getDefaultMultipleLayout3X2.Header = NupLayoutType.MultipleLayout3X2;
    getDefaultMultipleLayout3X2.isPeriodicity = false;
    return getDefaultMultipleLayout3X2;
  }

  getDefaultMultipleLayout3X3() {
    let getDefaultMultipleLayout3X3 = {};
    getDefaultMultipleLayout3X3.Header = NupLayoutType.MultipleLayout3X3;
    getDefaultMultipleLayout3X3.isPeriodicity = false;
    return getDefaultMultipleLayout3X3;
  }

  getDefaultMultipleLayout4X4() {
    let getDefaultMultipleLayout4X4 = {};
    getDefaultMultipleLayout4X4.Header = NupLayoutType.MultipleLayout4X4;
    getDefaultMultipleLayout4X4.isPeriodicity = false;
    return getDefaultMultipleLayout4X4;
  }
}
const layoutSettings = new LayoutSettings();
export default layoutSettings;
