import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class MarkNotificationAsReadRequest extends BaseServiceRequest {
  constructor(userNotificationId) {
    super();

    super.addRequestUri("read.pb");

    super.addRequestParameter("nid", userNotificationId.toString());
  }

  getMethod() {
    return HttpMethodType.PUT;
  }

  getSubSystem() {
    return SubSystemType.NOTIFICATION;
  }

  getMessageName() {
    return "Response";
  }

}