import { DgHoldingsActionConstants } from "../Constants/DgHoldingsActionConstants";
const { ActionTypes } = DgHoldingsActionConstants;

export const getDgHoldingsReportExtdata = (osid, symbol, msid) => ({
    type: ActionTypes.GET_DG_HOLDINGS_REPORT_DATA,
    osid,
    symbol,
    msid
})

export const changeTextSize = (textSize) => ({
    type: ActionTypes.DG_HOLDINGS_REPORT_CHANGE_TEXT,
    textSize
})

export const columnRsChanged = (isColumnRsChanged) => ({
    type: ActionTypes.DG_HOLDINGS_COLUMN_RS_CHANGED,
    result: isColumnRsChanged
})

export const columnPortChanged = (isColumnPortChanged) => ({
    type: ActionTypes.DG_HOLDINGS_COLUMN_PORT_CHANGED,
    result: isColumnPortChanged
})

export const columnSharesChanged = (isColumnSharesChanged) => ({
    type: ActionTypes.DG_HOLDINGS_COLUMN_SHARES_CHANGED,
    result: isColumnSharesChanged
})

export const showHoldingsLoader = (isLoading) => ({
    type: ActionTypes.DG_HOLDINGS_SHOW_LOADER,
    result: isLoading
})

export const tabSelectionChanged = (tabIndex) => ({
    type: ActionTypes.DG_HOLDINGS_TAB_CHANGE,
    tabIndex
})
export const subTabSelectionChanged = (subTabIndex) => ({
    type: ActionTypes.DG_HOLDINGS_SUB_TAB_CHANGE,
    subTabIndex
})
export const columnSortSeqChanged = (orderString, orderSeqArr) => ({
    type: ActionTypes.DG_HOLDINGS_COLUMN_SORT_CHANGED,
    orderString,
    orderSeqArr
})

export const limitChange = (limitData) => ({
    type: ActionTypes.DG_HOLDINGS_LIMIT_CHANGE,
    limitData
})

export const onSearchTextChange = (searchText) => ({
    type: ActionTypes.DG_HOLDINGS_SEARCH_TEXT_CHANGE,
    result: searchText
})

export const updateFlag = (msid, isFlagged) => ({
    type: ActionTypes.DG_HOLDINGS_UPDATE_FLAG, msid, isFlagged
})

export const resizeDgHoldingsTab = () => ({
    type: ActionTypes.DG_HOLDINGS_TAB_RESIZE,
    result: 1
})

export const clearHoldingData = () => ({
    type: ActionTypes.DG_HOLDINGS_CLEAR_DATA
});
