import AnnotationPreview from './AnnotationPreview';
import AnnotationsCheckboxSelectors from './AnnotationsCheckboxSelectors.jsx';
import AnnotationsColorPicker from './AnnotationsColorPicker.jsx';
import AnnotationsOpacitySettings from './AnnotationsOpacitySettings.jsx';
import AnnotationsStrokeSettings from './AnnotationsStrokeSettings.jsx';
import AnnotationsWeightSettings from './AnnotationsWeightSettings.jsx';
import BaseServiceApi from 'BaseServiceApi';
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import { RayAnnotationType } from '../../../../../../Constants/EAnnotationType';
import ScrollBar from "ScrollBar";
import { onAnnotationShapeCHange, onAnnotationToolChange } from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent } from 'react';


const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

class RIPanelShapeInfo extends PureComponent {

    componentDidMount() {
        document.body.classList.add('riPanel');
        document.addEventListener("keydown", this.onDocumentKeyPress, false);
    }

    componentWillUnmount() {
        document.body.classList.remove('riPanel');
        document.removeEventListener("keydown", this.onDocumentKeyPress, false);
    }
    onDocumentKeyPress(e) {
        if ((document.activeElement && document.activeElement.id === "SymbolEntryInput") || (document.activeElement && document.activeElement.id.includes("annot-dropdown"))) {
            return;
        }

        const pointerLayerScroll = document.getElementById("annotateIntrumentsShape");

        if (e.keyCode === KeyCodes.UP_ARROW) {

            if (pointerLayerScroll) {
                window.setTimeout(() => { pointerLayerScroll.scrollTop -= 30; }, 0);
            }
        }
        else if (e.keyCode === KeyCodes.DOWN_ARROW) {

            if (pointerLayerScroll) {
                window.setTimeout(() => { pointerLayerScroll.scrollTop += 30; }, 0);
            }
        }

    }
    renderShape = (option) => {

        this.props.onAnnotationShapeCHange(option);
        this.props.onAnnotationToolChange(option);

    }

    hexToRgbA(hex) {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = `0x${c.join('')}`;
            return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
        }
        throw new Error('Bad Hex');
    }

    sliderStyle() {
        const color = this.hexToRgbA(this.props.ShapesModuleInfo.bgColor)
        return ({
            background: `-webkit-linear-gradient(left, rgba(" + ${color} + ",0) 1%, rgba(" + ${color} + ",1) 100%)`,
            WebkitAppearance: "none",
            borderRadius: "6px",
            border: "1px solid #b7b7b7",
            height: "10px"
        })
    }

    hideDropDownMenu = (e) => {
        if (e.target.parentElement && e.target.parentElement.className === "ssb_v") {
            return;
        }

        this.colorPicker_bg && this.colorPicker_bg.hideDropDownMenu();
        this.colorPicker_br && this.colorPicker_br.hideDropDownMenu();
        this.colorPicker_weg && this.colorPicker_weg.hideDropDownMenu();
        this.colorPicker_stk && this.colorPicker_stk.hideDropDownMenu();
    }

    render() {
        const { isDrawing } = this.props;
        return (
            <div className="annotation-box annotate-shape">
                <div className="annotation-hold custom-scroll" onScroll={this.hideDropDownMenu} onClickCapture={this.hideDropDownMenu}>
                    <div className="annotation-overlape" style={{ display: ((!this.props.annotSelected && this.props.selectedLayer && this.props.selectedLayer.shareAccess === ShareAccessType.SHARE_READONLY) || (this.props.annotSelected && this.props.annotSelected.isReadOnlyAnnotation)) ? '' : 'none' }}></div>
                    <div className="annotation-intruments" id="annotateIntrumentsShape">

                        <AnnotationPreview label={`SHAPE SETTINGS`} />

                        <div className="annotation-properties">
                            <div className="swatches-shapes">
                                <div className="col-xs-12">
                                    <div className="swatches-field btn-sequence">
                                        <Button disabled={isDrawing} onClick={!isDrawing ? () => this.renderShape(RayAnnotationType.RECT_AnnotationType) : ''} className={this.props.shape === RayAnnotationType.RECT_AnnotationType ? "selective" : ''}><acronym className="icn-shape-square"></acronym></Button>
                                        <Button disabled={isDrawing} onClick={!isDrawing ? () => this.renderShape(RayAnnotationType.ELLIPSE_AnnotationType) : ''} className={this.props.shape === RayAnnotationType.ELLIPSE_AnnotationType ? "selective" : ''}><acronym className="icn-shape-circle"><div id="circle"></div></acronym></Button>
                                        <Button disabled={isDrawing} onClick={!isDrawing ? () => this.renderShape(RayAnnotationType.TRIANGLE_AnnotationType) : ''} className={this.props.shape === RayAnnotationType.TRIANGLE_AnnotationType ? "selective" : ''}><acronym className="icn-shape-triangle"></acronym></Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="annotation-properties">
                            <div className="title"><h4>BACKGROUND</h4></div>
                            <div className="swatches-range-slider">
                                <div className="col-xs-3">
                                    <div className="swatches-field color">
                                        <AnnotationsColorPicker type="background" ref={(r) => { this.colorPicker_bg = r; }} />
                                    </div>
                                </div>
                                <div className="col-xs-9">
                                    <AnnotationsOpacitySettings />
                                </div>
                            </div>
                        </div>

                        <div className="annotation-properties">
                            <div className="title">
                                <ul>
                                    <AnnotationsCheckboxSelectors type="Border" />
                                </ul>
                            </div>
                            <div className="swatches-dropdown">
                                <div className="col-xs-4"><AnnotationsColorPicker type="border" ref={(r) => { this.colorPicker_br = r; }} /></div>
                                <div className="col-xs-4"><AnnotationsWeightSettings type="border" ref={(r) => { this.colorPicker_weg = r; }} /></div>
                                <div className="col-xs-4"><AnnotationsStrokeSettings type="border" ref={(r) => { this.colorPicker_stk = r; }} /></div>
                            </div>
                        </div>

                    </div>
                    <ScrollBar scrollId="annotateIntrumentsShape" vScroll={true} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ annotationReducers }) => ({
    selectedToolType: annotationReducers.annotationMenuReducer.selectedToolType,
    selectedLayer: annotationReducers.annotationLayerManagementReducer.selectedLayer,
    annotSelected: annotationReducers.annotationChartModelReducer.annotSelected,
    isDrawing: annotationReducers.annotationChartModelReducer.isDrawing,
    shape: annotationReducers.annotationMenuReducer.ShapesModelInfo.selectedType,
    riPanelToggle: annotationReducers.annotationMenuReducer.riPanelToggle
})

const mapDispatchToProps = (dispatch) => ({
    onAnnotationShapeCHange: (value) => dispatch(onAnnotationShapeCHange(value)),
    onAnnotationToolChange: (value) => dispatch(onAnnotationToolChange(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelShapeInfo);



