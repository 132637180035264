import React from "react";
import ReactDOM from "react-dom";
import RowItem from "./RowItem";
import KeyTypes from "../../Constants/KeyType";
import GridStore from "GridStore";
import { GridConstants } from "GridConstants";
import { find, map } from "underscore";
import BrowserUtil from "BrowserUtil";
import GridActions from "GridActions";
import ListManagerTabType from "ListManagerTabType";
import ListStore from "ListStore";
import { SettingsConstants } from "../../Constants/SettingsConstants.js";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import ListExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import ONeilViewStore from "../../Stores/NavModules/NavList/TabONeil/ONeilViewStore";

let _isGetMsidCall = false;
export default class GridBody extends React.Component {

    constructor(props) {
        super(props);
        this.getRowRef = this.getRowRef.bind(this);
        this.syncSingleSelection = this.syncSingleSelection.bind(this);
        this.handleSingleModeSelection = this.handleSingleModeSelection.bind(this);
        this.handleShiftClearSelection = this.handleShiftClearSelection.bind(this);
        this.isContiguousArray = this.isContiguousArray.bind(this);
        this.scrollTop = this.scrollTop.bind(this);
        //  this.getRowsForNewData = this.getRowsForNewData.bind(this);
        this.syncHighLighting = this.syncHighLighting.bind(this);
        this.loadRowsOnNewData = this.loadRowsOnNewData.bind(this);
        this.onColumnDrop = this.onColumnDrop.bind(this);
        this.setSelectedIndexes = this.setSelectedIndexes.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.gridStoreStateChange = this.gridStoreStateChange.bind(this);
        this.ListStoreStateChange = this.ListStoreStateChange.bind(this);
        this.onGridRowContextMenuClick = this.onGridRowContextMenuClick.bind(this);
        this.onKeyUpDownSelection = this.onKeyUpDownSelection.bind(this);
        this.getEditableRow = this.getEditableRow.bind(this);
        this.addSymbol = this.addSymbol.bind(this);
        this.handleInputSymbol = this.handleInputSymbol.bind(this);
        this.getCells = this.getCells.bind(this);
        this.dragStart = this.dragStart.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
        this.dragEnter = this.dragEnter.bind(this);
        this.dragLeave = this.dragLeave.bind(this);
        this.dragMove = this.dragMove.bind(this);
        this.dragDrop = this.dragDrop.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleDocumentDragover = this.handleDocumentDragover.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this);
        this.handleInputLeave = this.handleInputLeave.bind(this);
        this.handleInputEnter = this.handleInputEnter.bind(this);
        this.handleKeyDown =  this.handleKeyDown.bind(this);
        this.state = {
            shouldUpdate: true,
            visibleRow: [],
            displayHeight: props.displayHeight,
            viewportWidth: props.viewportWidth,
            selectedIndexes: GridStore.getSelectedIndexes(),
            inputSymbol: '',
            scrollTop: 0
        }
        this.textFromPaste = null;
        this.componentMounted = false;
        this.scrolling = false;
        this.lastRowIndex = 0;
        this.cellLimit = (this.props.selectedListId == -1 || this.props.selectedListId == -2) ? 2 : 3;
        this.staticColsWidth = 0;
        this.dragMousePos = { cX: 0, cY: 0 };
        this.shouldUpdateStreamingData = true;
        this.isEdit = false;
        this.mouseDown =  this.mouseDown.bind(this);
        this.mouseOutside =  this.mouseOutside.bind(this);
    }

    UNSAFE_componentWillMount() {
        GridStore.addChangeListener(this.gridStoreStateChange);
    }

    componentDidUpdate() {
        if (GridStore.isPasteEvent || this.isEdit) {
            if (this.input){ 
                this.input.focus();
                this.props.setScrollPos(null, 'moveDown', this.props.source ? this.props.source.length + 2 : 1);
                const gridPanel = document.getElementById("main-grid-panel");
                gridPanel.scrollTop = 0;
            }
            return;
        }
        if (this.input) this.input.blur();
    }

    componentDidMount() {
        ListStore.addChangeListener(this.ListStoreStateChange);
        ReactDOM.findDOMNode(this.Panel).focus();
        this.dragDivGhostImage = document.getElementById('grid-drag-ghost-image');
        this.gridVerticalScrollElm = document.getElementById('gridVerticalScroll');
        document.addEventListener('dragover', this.handleDocumentDragover, false);
        document.addEventListener('click', this.handleKeyDown);
        this.componentMounted = true;
    }

    componentWillUnmount() {
        GridStore.removeChangeListener(this.gridStoreStateChange);
        ListStore.removeChangeListener(this.ListStoreStateChange);
        this.dragDivGhostImage = null;
        this.gridVerticalScrollElm = null;
        document.removeEventListener('dragover', this.handleDocumentDragover, false);
        document.removeEventListener('click', this.handleKeyDown);
        this.dragMousePos = {};
        this.componentMounted = false;
    }


    handleKeyDown() {
        GridStore.isPasteEvent = false;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if(!nextProps.shouldUpdateStreamingData) {
            this.shouldUpdateStreamingData = false;
        }
        else {
            this.shouldUpdateStreamingData = true;
        }
        if (nextProps.isUpdateNotRequired || !nextProps.shouldUpdateStreamingData) {
            return false;
        }
        return nextState.shouldUpdate;
    }

    handleDocumentDragover (e) {
        this.dragMousePos.cY = e.clientY;
        this.dragMousePos.cX = e.clientX;
        e.stopPropagation();
    }

    ListStoreStateChange() {
        if (ListStore.getCurrentAction() == SettingsConstants.ActionTypes.THEME_CHANGED) {
            let state = ListStore.getState();
            if (state.themeChanged) {
                this.setState({ shouldUpdate: true });
                this.forceUpdate();
            }
        }
    }

    dragStart(e, rowIndex, visibleIndex, isSelected) {
        const target = e.srcElement || e.target;
        const mousePos = {
            top: `${e.clientY + 5}px`,
            left: `${e.clientX}px`
        }
        let selectedItems = this.state.selectedIndexes;
        if (!isSelected) {
            selectedItems = [];
            selectedItems.push(rowIndex);
            this.clearSelection();
            this.syncSingleSelection(rowIndex, visibleIndex);
            this.setSelectedIndexes(selectedItems);
        }
        if (selectedItems) {
            this.dragged = selectedItems;
            e.dataTransfer.effectAllowed = 'move';

            // Firefox requires calling dataTransfer.setData
            // for the drag to properly work


            GridStore.setDragInfo(this.dragged);
            GridStore.setDragDestInfo(null, null, false);

            let windowDiv = document.querySelector("#frozenDiv");
            this.bRectOfWindow = windowDiv.getBoundingClientRect();

            this.dragDivGhostImage.style.visibility = 'visible';
            if (BrowserUtil.isIEBrowser()) {
                const cloneNode = target.cloneNode(true);
                cloneNode.style.display = "table-row";
                if (!cloneNode.classList.contains("grid-row-selected"))
                    cloneNode.classList.add("grid-row-selected");
                target.style.display = "none";
                target.parentNode.insertBefore(cloneNode, target);
                setTimeout(() => {
                    target.style.display = "table-row";
                    target.parentNode.removeChild(cloneNode);
                }, 5);
                e.dataTransfer.setData('text', 'selectedItem');
            }
            else {
                this.dragDivGhostImage.style.position = 'absolute';
                e.dataTransfer.setData('text/html', 'selectedItem');
            }
            if (selectedItems.length > 1) {
                this.dragDivGhostImage.innerHTML = LocalizationStore.getTranslatedData("list_Selected", "{0} Items selected", `${selectedItems.length}`);
                if (BrowserUtil.isIEBrowser()) {
                    this.dragDivGhostImage.style.top = mousePos.top;
                    this.dragDivGhostImage.style.left = mousePos.left;
                    this.dragDivGhostImage.style.position = 'fixed';
                    this.dragDivGhostImage.style.zIndex = '99';
                }
            }
            else {
                GridStore.getSymbolAndName(selectedItems).then((rst) => {
                    if (BrowserUtil.isIEBrowser()) {
                        this.dragDivGhostImage.style.top = mousePos.top;
                        this.dragDivGhostImage.style.left = mousePos.left;
                        this.dragDivGhostImage.style.position = 'fixed';
                        this.dragDivGhostImage.style.zIndex = '99';
                    }
                    this.dragDivGhostImage.innerHTML = rst;
                })
            }

            if (e.dataTransfer.setDragImage) {
                e.dataTransfer.setDragImage(this.dragDivGhostImage, -50, 0);
            }
        }
    }

    dragDrop(e) {
        let self = this;
        this.props.setDragGridScroll(true);
        this.scrolling = false;
        // Prevent firfox redirection
        if (!BrowserUtil.isIEBrowser()) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (GridStore.isUserList() && self.dragged) {
            let destIndex = [];
            if (self.dragged.length > 0) {
                self.clearSelection();
                let rowIndex = self.over.getAttribute('data-id');
                destIndex.push(rowIndex)
                if (this.props.source[rowIndex].recordDatas.length != this.props.allColumns.length) {
                    GridStore.setDragDestInfo(self.dragged, destIndex);
                }
                else {
                    BrowserUtil.disableEvents();
                    GridActions.dropInListManager(self.dragged, destIndex);
                }
            }
            let key = "row" + self.over.getAttribute('data-id');
            self.props.highlightBottomBorder(key);
        }
        if (self.dragDivGhostImage) {
            self.dragDivGhostImage.innerHTML = '';
            self.dragDivGhostImage.style.zIndex = '-1';
            self.dragDivGhostImage.removeAttribute('style');
        }
    }

    dragEnd(e) {
        let self = this;
        this.props.setDragGridScroll(true);
        this.scrolling = false;
        self.dragDivGhostImage.innerHTML = '';
        self.dragDivGhostImage.style.zIndex = '-1';
        self.dragDivGhostImage.removeAttribute('style');
        if (self.over) {
            let key = "row" + self.over.getAttribute('data-id');
            self.props.highlightBottomBorder(key);
        }
        this.props.handleVerticalScrollMouseUp(e);
    }

    dragOver(e) {
        if (GridStore.isUserList()) {
            this.over = (e.currentTarget) ? e.currentTarget : e.srcElement;
            e.preventDefault();
        }
        else {
            return false;
        }
    }

    dragMove() {
        if (BrowserUtil.isIEBrowser()) {
            this.dragDivGhostImage.style.top = `${this.dragMousePos.cY + 15}px`,
                this.dragDivGhostImage.style.left = `${this.dragMousePos.cX + 15}px`;
        }
        this.props.setDragGridScroll(true);
        let scrollSpeed = 2;
        this.scrolling = false;
        if (this.dragMousePos.cY < this.bRectOfWindow.top && this.dragMousePos.cX > this.bRectOfWindow.left) {
            this.scrolling = true;
            this.props.setDragGridScroll(false);
            this.props.scrollGrid(-scrollSpeed);
        }
        if (this.dragMousePos.cY > this.bRectOfWindow.bottom && this.dragMousePos.cX > this.bRectOfWindow.left) {
            this.scrolling = true;
            this.props.setDragGridScroll(false);
            this.props.scrollGrid(scrollSpeed);
        }

        if (this.scrolling && this.over) {
            let key = "row" + this.over.getAttribute('data-id');
            this.props.highlightBottomBorder(key);
        }
    }

    dragEnter(e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        this.enterElement = target;
        let key = "row" + target.getAttribute('data-id');
        this.props.highlightBottomBorder(key, true);
    }

    dragLeave(e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        if (this.enterElement && this.enterElement != target) {
            let key = "row" + target.getAttribute('data-id');
            this.props.highlightBottomBorder(key);
        }
        else {
            let key = "row" + this.enterElement.getAttribute('data-id');
            this.props.highlightBottomBorder(key);
        }
    }

    onKeyUpDownSelection(e, targetSelectedIndex) {    
        let self = this, rowIndex, visibleIndex;
        let selectedIndex = targetSelectedIndex ? targetSelectedIndex : this.state.selectedIndexes[this.state.selectedIndexes.length - 1];
        let keyNavigation = true;

        if (selectedIndex != undefined) {

            if (e.keyCode == 38) {
                if (selectedIndex > 0) {
                    rowIndex = selectedIndex - 1;
                    visibleIndex = this.getVisibleIndex(selectedIndex);
                    if (visibleIndex <= 0) {
                        this.props.setScrollPos(e, 'moveUp', selectedIndex - 1);
                    }
                }
                else keyNavigation = false;
            }

            else if (e.keyCode == 40) {
                const visibleRows = Math.floor(this.props.displayHeight / this.props.rowHeight)
                if (selectedIndex <= this.props.source.length) {
                    rowIndex = selectedIndex + 1;
                    visibleIndex = self.getVisibleIndex(selectedIndex) + 1;
                    if (visibleIndex == -1) keyNavigation = false;

                    if (visibleIndex >= visibleRows - 2) {
                        self.props.setScrollPos(e, 'moveDown', selectedIndex + 3);
                    }
                }
                else keyNavigation = false;
            }

            else keyNavigation = false;

            if (keyNavigation) {
                if (rowIndex >= 0 && rowIndex < self.props.source.length) {
                    setTimeout(() => {
                        self.clearSelection();
                        let rowRef = self.getRowRef(rowIndex);
                        if (rowRef != undefined) {
                            rowRef.setSelectedState(true);
                        }
                        let selectedItems = [];
                        selectedItems.push(rowIndex);
                        self.setSelectedIndexes(selectedItems);
                    }, 0);
                }
            }
            return selectedIndex;
        }
    }
    //PANWEB-6841 FIX
    // highlightActiveRow(e) {
    //     let self = this;
    //     this.currentActiveRow = document.getElementsByClassName('grid-row-active');
    //     if (this.currentActiveRow.length > 0) {
    //         let activeRowKey = parseInt(self.currentActiveRow[0].getAttribute('data-id'));
    //         let rowRef = this.getVisibleIndex(activeRowKey);
    //         /*eslint-disable*/
    //         if (rowRef >= this.state.visibleRow.length - 11) {
    //             let t = this.props.setScrollPos(e, 'moveDown', activeRowKey + 12);
    //         }

    //         for (let i in self.currentActiveRow) if (self.currentActiveRow[0]) self.currentActiveRow[0].classList.remove('grid-row-active');
    //         /*eslint-enable*/
    //         setTimeout(() => {
    //             let row = self.props.source[activeRowKey + 1];
    //             if (row) {
    //                 let nextActiveRow = document.querySelectorAll('[data-id="' + (activeRowKey + 1) + '"]');
    //                 for (let i = 0; i < nextActiveRow.length; i++) {
    //                     nextActiveRow[i].classList.add('grid-row-active');
    //                 }
    //                 setTimeout(() => {
    //                     self.props.onPlotSymbol(row.symbol);
    //                 }, 0);
    //             }
    //         }, 500);
    //     }
    //     e.preventDefault();
    //     e.stopPropagation();
    // }

    getVisibleIndex(rowIndex) {
        let items = find(this.state.visibleRow, (item) => item.actuallRowIndexStart.toString() == rowIndex.toString());
        if (items) return items.visibleIndex; else return -2;
    }

    setSelectedIndexes(indexes) {
        const uniqueItems = [...new Set(indexes)]; //undescore uniq have issue with memory #fixes PANWEB-2484
        GridStore.setSelectedIndexes(uniqueItems);
        if(this.componentMounted){
        this.setState({
            selectedIndexes: uniqueItems,
            shouldUpdate: false
        });
    }
    }

    UNSAFE_componentWillReceiveProps(props) {
        let updateObj = { shouldUpdate: true };
        if (props.displayHeight !== this.state.displayHeight) {
            updateObj = {
                ...updateObj,
                displayHeight: props.displayHeight,
            };
        }
        if (props.viewportWidth >= .0) {
            updateObj = {
                ...updateObj,
                viewportWidth: props.viewportWidth

            };
        }
        if (props.loading) {
            updateObj = {
                ...updateObj,
                inputSymbol: ''
            };
        }
        if (GridStore.getCurrentAction() == null) {
            updateObj = {
                ...updateObj,
                selectedIndexes: GridStore.getSelectedIndexes()
            };
        }
        if(props.visibleRow.length > 0) {
            updateObj = {
                ...updateObj,
            visibleRow: props.visibleRow
            };
        }
        this.setState((state) => ({ ...state, ...updateObj }));

    }

    gridStoreStateChange() {
        if(this.componentMounted){
        let currentAction = GridStore.getCurrentAction();
        if (currentAction == GridConstants.ActionTypes.GET_HIGHLIGHTED_ROW) {
            let state = GridStore.getHighlightedRow();
            this.syncHighLighting(state.index, state.highlight);
        }
        else if (currentAction == GridConstants.ActionTypes.GET_SELECTED_ROW) {
            let state = GridStore.getSelectedRow();
            this.handleSingleModeSelection(state.rowIndex, state.visibleIndex, state.keyType);
        }
        else if (currentAction == GridConstants.ActionTypes.SHIFT_DOWN_SELECTION) {
            let state = GridStore.getSelectedRow();
            this.handleSingleModeSelection(state.rowIndex, state.visibleIndex, GridConstants.ActionTypes.SHIFT_DOWN_SELECTION);
        }
        else if (currentAction == GridConstants.ActionTypes.SHIFT_UP_SELECTION) {
            let state = GridStore.getSelectedRow();
            this.handleSingleModeSelection(state.rowIndex, state.visibleIndex, GridConstants.ActionTypes.SHIFT_UP_SELECTION);
        }
        else if (currentAction == GridConstants.ActionTypes.SELECT_ALL_ROWS) {
            const selectedItems = [...Array(this.props.pageSize).keys()];
            let rowRef;
            const pSize = this.props.pageSize;
            for (let i = 0; i < pSize; i++) {
                if (this.props.source && i > this.props.source.length) break;
                rowRef = this.getRowRef(i);
                if (rowRef != undefined) {
                    rowRef.setSelectedState(true);
                }
            }
            this.setSelectedIndexes(selectedItems);
            BrowserUtil.disableEvents();
            if (!_isGetMsidCall) {
                _isGetMsidCall = true;
                ONeilViewStore.getMsIds(selectedItems).then(() => {
                    _isGetMsidCall = false;
                    BrowserUtil.enableEvents();
                });
            }
        }

        if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO || GridStore.getGridBodyAction() == GridConstants.ActionTypes.DROP_IN_LIST_MANAGER || GridStore.getGridBodyAction() == GridConstants.ActionTypes.REMOVE_GRID_ROWS) {
            let selectedItems = [];
            if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO) {
                selectedItems = GridStore.getSelectedIndexes();
            }
            else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.REMOVE_GRID_ROWS) {
                selectedItems = [];
            }
            else {
                selectedItems = GridStore.getIndexs();
            }

            for (let i = 0; i < selectedItems.length; i++) {
                let rowRef = this.getRowRef(selectedItems[i]);
                if (rowRef != undefined) {
                    rowRef.setSelectedState(true);
                }
            }
            BrowserUtil.enableEvents();
            this.setSelectedIndexes(selectedItems);
            setTimeout(() => {
                GridStore.clearGridBodyAction();
                GridStore.clearCurrentAction();
            }, 0);
        }
        else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER) {
            BrowserUtil.enableEvents();
            this.setState({
                inputSymbol: ''
            })
            setTimeout(() => {
                GridStore.clearGridBodyAction();
                GridStore.clearCurrentAction();
            }, 0);

        }
        else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.PASTE_GRID_ROWS_UNDO) {
            GridStore.isPasteEvent = true;
        }
        else if(GridStore.getGridBodyAction() == GridConstants.ActionTypes.CLIPBOARD_DATA_ACCESS_DENIED){
            setTimeout(() => {
                GridStore.clearGridBodyAction();
                GridStore.clearCurrentAction();
            }, 0);
        }
        else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.PASTE_GRID_ROWS) {            
            BrowserUtil.enableEvents();
            this.setState({
                inputSymbol: ''
            })
            GridStore.isPasteEvent = true;
            setTimeout(() => {
                GridStore.clearGridBodyAction();
                GridStore.clearCurrentAction();
            }, 0);
    }
}
}

    onGridRowContextMenuClick(e, rowIndex, visibleIndex, isSelected, instrumentType) {
        e.stopPropagation();
        e.preventDefault();
        let selectedItems = this.state.selectedIndexes;
        if (!isSelected) {
            selectedItems = [];
            selectedItems.push(rowIndex);
            this.clearSelection();
            this.syncSingleSelection(rowIndex, visibleIndex);
            this.setSelectedIndexes(selectedItems);
        }
        this.props.onGridRowContextMenuClick(e, selectedItems, instrumentType);
    }

    handleShiftClearSelection(rowIndex) {
        let lastSelectedIndex = 0;
        if (this.selectedItems.length > 0) {
            lastSelectedIndex = this.state.selectedIndexes[0];
        }

        this.selectedItems = [];
        let indexes = [rowIndex, lastSelectedIndex];
        if(rowIndex<lastSelectedIndex){
            for (let i = indexes[1]; i >= indexes[0]; i--) {
                this.selectedItems.push(i);
                let rowRef = this.getRowRef(i);
                if (rowRef != undefined) rowRef.setSelectedState(true);
        }
        }else{
                indexes.sort((a, b) => (a - b));
            for (let i = indexes[0]; i <= indexes[1]; i++) {
                this.selectedItems.push(i);
                let rowRef = this.getRowRef(i);
                if (rowRef != undefined) rowRef.setSelectedState(true);
        }
        }
       
    }

    isContiguousArray(selectedIndexes) {
        for (let i = 1; i < selectedIndexes.length; i++) {
            let res = selectedIndexes[i] - selectedIndexes[i - 1];
            if (Math.abs(res) > 1)
                return false;
        }
        return true;
    }

    handleSingleModeSelection(rowIndex, visibleIndex, keyType) {
        this.selectedItems = [];
        this.selectedItems = this.state.selectedIndexes.slice(0);

        let selectedIndex = this.state.selectedIndexes[this.state.selectedIndexes.length - 1];
        if (keyType === KeyTypes.None) {
            this.clearSelection();
        }
        if (keyType === KeyTypes.shiftKey) {
            this.clearSelection();
            this.handleShiftClearSelection(rowIndex);
        }
        else if (keyType === KeyTypes.ctrlKey || keyType === KeyTypes.metaKey) {

            if (!this.selectedItems.includes(rowIndex)) {
                this.selectedItems.unshift(rowIndex); // last one selected with ctrl key should be the first one selected
                let key = "row" + rowIndex;           // after switching from ctrl key to shift key
                let rowRef = this.refs[key];
                if (rowRef != undefined) rowRef.setSelectedState(true);
            }
            else {
                let indexOfElm = this.selectedItems.indexOf(rowIndex);
                if (indexOfElm != -1) {
                    let key = "row" + rowIndex;
                    let rowRef = this.refs[key];
                    if (rowRef != undefined) rowRef.setSelectedState(false);
                    this.selectedItems.splice(indexOfElm, 1);
                }
            }
        }
        else if (keyType === GridConstants.ActionTypes.SHIFT_DOWN_SELECTION) {
            let isContiguous = this.isContiguousArray(this.selectedItems);
            let lastIndex = this.selectedItems[this.selectedItems.length - 1];// Getting Last Index from Selected Indexes Array
            if (isContiguous === false) {
                this.clearSelection();
                this.handleShiftClearSelection(rowIndex);
            }
            rowIndex = lastIndex + 1;// Increment by 1 to Select a next row
            let key = "row" + rowIndex;
            let rowRef = this.refs[key];

            if (!this.selectedItems.includes(rowIndex) && (this.refs[key]) != undefined) {
                this.selectedItems.push(rowIndex);
                rowRef.setSelectedState(true);

                if (selectedIndex <= this.props.source.length) {
                    visibleIndex = this.getVisibleIndex(selectedIndex) + 1;
                    if (visibleIndex >= this.state.visibleRow.length - 2) {
                        this.props.setScrollPos(lastIndex, 'moveDown', selectedIndex + 4);
                    }
                }
            }
            else {
                if (selectedIndex <= this.props.source.length) {
                    visibleIndex = this.getVisibleIndex(selectedIndex) + 1;
                    if (visibleIndex >= this.state.visibleRow.length - 2) {
                        this.props.setScrollPos(lastIndex, 'moveDown', selectedIndex + 4);
                    }
                }
                if (rowIndex !== undefined) {
                    this.selectedItems.pop(); // Removing Latest RowIndex from SelectedIndexes array to Deselect
                }
            }
        }
        else if (keyType === GridConstants.ActionTypes.SHIFT_UP_SELECTION) {
            let isContiguous = this.isContiguousArray(this.selectedItems);
            let lastIndex = this.selectedItems[this.selectedItems.length - 1];// Getting Last Index from Selected Indexes Array
            if (isContiguous === false) {
                this.clearSelection();
                this.handleShiftClearSelection(rowIndex);
            }
            rowIndex = lastIndex - 1; // Decrement by 1 to Select a next row
            if (!this.selectedItems.includes(rowIndex) && rowIndex >= 0) {
                this.selectedItems.push(rowIndex);
                let key = "row" + rowIndex;
                let rowRef = this.refs[key];

                if (rowRef != undefined) rowRef.setSelectedState(true);
                if (selectedIndex > 0) {
                    visibleIndex = this.getVisibleIndex(selectedIndex);
                    if (visibleIndex <= 0) {
                        this.props.setScrollPos(lastIndex, 'moveUp', selectedIndex - 2);
                    }
                }
            }
            else {
                if (selectedIndex > 0) {
                    visibleIndex = this.getVisibleIndex(selectedIndex);
                    if (visibleIndex <= 0) {
                        this.props.setScrollPos(lastIndex, 'moveUp', selectedIndex - 2);
                    }
                }
                if (rowIndex >= 0) {
                    this.selectedItems.pop(); // Removing Latest RowIndex from SelectedIndexes array to Deselect
                }

            }
        }
        else {
            this.selectedItems = [];
            this.selectedItems.push(rowIndex);
            this.syncSingleSelection(rowIndex, visibleIndex);
        }

        this.setSelectedIndexes(this.selectedItems);
    }

    getCurrentSelection(i) {
        let frozenRef = this.getRowRef(i);
        if (frozenRef != undefined) {
            rowRef.setSelectedState(true);
        }
    }

    clearSelection() {
        if (this.state.selectedIndexes.length > 0) {
            for (let index = 0; index < this.state.selectedIndexes.length; index++) {
                let selectedIndex = this.state.selectedIndexes[index];
                let rowRef = this.getRowRef(selectedIndex);
                if (rowRef != undefined) {
                    rowRef.setSelectedState(false);
                }
            }
        }
    }

    onColumnDrop(viewportWidth) {
        this.setState({
            viewportWidth: viewportWidth,
            shouldUpdate: false
        });
    }

    loadRowsOnNewData(rows) {
        if (rows === undefined || rows === null || !this.shouldUpdateStreamingData) {
            return;
        }
        // setTimeout(() => {
        if (!Object.compare(this.state.visibleRow, rows)) {
            this.setState({ visibleRow: rows, shouldUpdate: true });
        }
        // }, 0);
    }

    syncHighLighting(visibleIndex, isHighLighted) {
        let rowItem = this.state.visibleRow[visibleIndex];
        if(rowItem != undefined){
        let key = "row" + rowItem.actuallRowIndexStart;
        let rowRef = this.refs[key];
        if (rowRef !== undefined) {
            let refIsHighLighted = rowRef.getIsHighLighted();
            if (refIsHighLighted !== isHighLighted) {
                rowRef.setHighLightState(isHighLighted);
            }
        }}
    }

    /*getRowsForNewData(source, scrolledPageStart, displayStart, displayEnd) {
        let rows = [];
        let actuallRowIndexStart = displayStart;
        let visibleIndex = 0;
    
        if (source.length > 0) {
            for (let index = displayStart; index < displayEnd; index++) {
                if (source && index > -1) {
                    let rowData = source[index];
                    map(rowData, function (recordDatas, k) {
                        if (k == 'recordDatas') rowData[k] = map(recordDatas, function (o) { return pick(o, ['displayValue', 'className', 'textWidth', 'isVisualizationOn', 'isNegative', 'dataType', 'dataItemId', 'alignment']); });
                    });
    
                    rows.push({
                        visibleIndex: visibleIndex,
                        actuallRowIndexStart: actuallRowIndexStart,
                        rowData: rowData
                    });
                }
                else {
                    rows.push({
                        visibleIndex: visibleIndex,
                        actuallRowIndexStart: actuallRowIndexStart,
                        rowData: undefined
                    });
    
                }
                actuallRowIndexStart++;
                visibleIndex++;
            }
        }
        return rows;
    }*/

    scrollTop(value) {
        this.Panel.scrollTop = value;
    }

    syncSingleSelection(rowIndex) {
        let key = "row" + rowIndex;
        let rowRef = this.refs[key];
        if (rowRef != undefined) rowRef.setSelectedState(true);
    }

    getRowRef(rowIndex) {
        let items = this.state.visibleRow.filter((item) => item.actuallRowIndexStart === rowIndex);
        if (items.length > 0) {
            let rowObject = items[0];
            let key = "row" + rowObject.actuallRowIndexStart;
            let rowRef = this.refs[key];
            return rowRef;
        }
        else {
            return undefined;
        }
    }

    handlePaste(e){
        let pastedText = undefined;
		if (window.clipboardData && window.clipboardData.getData) { // IE
    		pastedText = window.clipboardData.getData('Text');
  		} else {
    		pastedText = e.nativeEvent.clipboardData.getData('Text');
  		}
        pastedText = GridStore.removeNullEntry(pastedText.match(/[^\r\n]+/g));
        this.textFromPaste = pastedText.length > 0 ? pastedText[0] : '';
    }

    handleInputSymbol(e) {
        let value = '';
        let evt = e || window.event;
        let symValue = evt.srcElement || evt.target;
        let input = this.textFromPaste !== null ? this.textFromPaste : symValue.value;
        value = input.split(",");
        this.setState({
            inputSymbol: value[0] ? (value[0].includes(';') ? value[0].split(';')[1] : value[0]) : '',
            shouldUpdate: true
        });
        this.textFromPaste = null;
    }

    addSymbol(e) {
        GridStore.isPasteEvent = true;
        let evt = e || window.event;
        if (evt.keyCode == 13) {
            this.isEdit = false;
            this.props.addSymbol(this.state.inputSymbol.toString().toUpperCase().trim());
        }
    }

    getEditableRow() {        
        let editableRow = null;
        let style = { width: this.state.viewportWidth }
        let classNameEditable = 'grid-row-odd';
        if (GridStore.isAddSymbolEnableList()) {
            this.lastRowIndex++;
            if (this.lastRowIndex % 2 == 0) classNameEditable = 'grid-row-even';
            editableRow = <tr className={'grid-row ' + classNameEditable} style={style}>{this.getCells(true)}</tr>;
        }    
        return editableRow;
    }

    getBlankRow() {
        let blankRow = null;
        let style = { width: this.state.viewportWidth }
        let classNameEditable = 'grid-row-odd';
        this.lastRowIndex++;
        if (this.lastRowIndex % 2 == 0) classNameEditable = 'grid-row-even';

        blankRow = <tr className={'grid-row ' + classNameEditable} style={style}>{this.getCells()}</tr>;
        return blankRow;
    }
    mouseDown(){
        this.isEdit = true;
    }
    mouseOutside(){
        this.isEdit = false;
    }
    getCells(editableRow = false) {
        let cellWidth = 0, colitem, cellValue;
        let style = { width: this.state.viewportWidth, textAlign: 'left' }
        if (this.props.columns.length > 0) {
            if (editableRow && this.props.columns[0].isFrozen) {

                colitem = this.props.columns[0];
                cellWidth = this.props.getColumnWidth(colitem);

                colitem = this.props.columns[1];
                cellWidth += this.props.getColumnWidth(colitem);

                if (this.props.columns[2]){
                    colitem = this.props.columns[2];
                }
                if (colitem && Math.ceil(colitem.textWidth) != 0 && this.state.visibleRow && this.state.visibleRow.length > 0)
                    cellWidth += ((this.props.getColumnWidth(colitem) - Math.ceil(colitem.textWidth)) / 2) + 4;
                else
                    cellWidth += ((this.props.getColumnWidth(colitem) - 70) / 2) + 4;

                    cellValue = <span className='customFont symbol-add-input' style={{ marginLeft: cellWidth + 'px' }}>
                        <input className="xx-small-normal" 
                        onMouseDown={this.mouseDown}
                        onBlur={this.mouseOutside}
                        onPaste={this.handlePaste}
                        onDragEnter={this.handleInputEnter} 
                        onDragLeave={this.handleInputLeave} 
                        style={{ margin: "0px", textTransform: 'uppercase' }} 
                        type="text" ref={(ref) => this.input = ref} 
                        onKeyDown={this.addSymbol} value={this.state.inputSymbol} 
                        onChange={this.handleInputSymbol} 
                        onContextMenu={this.onContextMenu} />
                        </span>
            }
            else {
                cellValue = <span>&nbsp;</span>
            }
        }
        return (<td colSpan={this.props.columns.length} className='grid-cell' style={style}>{cellValue}</td>);
    }

    onContextMenu(e) {
        GridStore.isPasteEvent = true;
        e.stopPropagation();
    }

    /*getCells(editableRow = false) {
        let cells = [];
        let cellIndex = 0;
        let self = this;
        this.staticColsWidth = 0;
        if (this.props.selectedListId == -1)  this.cellLimit = 2;
        else this.cellLimit = 3;
        for (let cellIndex = 0; cellIndex < this.props.columns.length; cellIndex++) {
            let cell = null;
            cell = this.setCellContent(cellIndex, self.props, editableRow);
            cells.push(cell);
        }
        return cells;
    }
    
    setCellContent(cellIndex, props, editableRow = false) {
        let colitem = props.columns[cellIndex];
        let cellData = '';
        let displayValue = '';
        let className = '';
        let colWidth = this.props.getColumnWidth(colitem);
        let paddingLeft = 10;
        let textWidth = colitem.textWidth + paddingLeft;
    
        if (colitem.dataItemId == -5 && editableRow) { // Symbol
            cellData = <input className="xx-small-normal" type="text" onKeyDown={this.addSymbol} value={this.state.inputSymbol} onChange={this.handleInputSymbol} />
            className = 'symbol';
        }
        displayValue = cellData;
        if (colWidth < textWidth) {
            textWidth = colWidth;
        }
    
        let cellStyle = {
            width: colWidth + "px",
            minWidth: colitem.minWidth + "px",
        }
    
        if (this.props.gridTypeTable) {
            return (<td key={"custom" + cellIndex} className='grid-cell' style={cellStyle}><span className='customFont symbol-add-input'>{displayValue}</span></td>)
        }
        else if (this.props.columns[0].isFrozen) {
            if (cellIndex < this.cellLimit) {
                this.staticColsWidth += colWidth;
                return (<td key={"custom" + cellIndex} className='grid-cell' style={cellStyle}><span className='customFont symbol-add-input'>{displayValue}</span></td>)
            }
            else if (cellIndex == this.cellLimit) {
                return (<td key={"custom" + cellIndex}><canvas width={this.state.viewportWidth - this.staticColsWidth} height={this.props.rowHeight}></canvas></td>)
            }
        }
        else {
            if (cellIndex == 0) return (<td key={"custom" + cellIndex}><canvas width={this.state.viewportWidth} height={this.props.rowHeight}></canvas></td>)
        }
    } 
    
    */

    onPlotSymbol(sym) {
        this.props.onPlotSymbol(sym);
    }

    handleScroll(scroll) {
        this.Panel.scrollLeft = scroll;
    }

    handleInputEnter(e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        target.setAttribute("disabled", "disabled");
    }

    handleInputLeave(e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        target.removeAttribute("disabled");
    }

    render() {
        const isEditable = GridStore.isUserList();
        let addSymbolEnable = GridStore.isAddSymbolEnableList();        
        const editableRow = this.getEditableRow();
        const blankRow = this.getBlankRow();
        const isNotImportList = ListExplorerStore.isNotImportedList();
        //const zIndex = (this.state.dragLeft > 0) ? 99999 : -1;
        let gridBodyClasses = 'grid-body selectTextDisble disbleDragImage';
        if (!this.props.gridTypeTable) {
            gridBodyClasses += ' grid-body-canvas';
        }
        return (
            <div style={{ height: "100%" }}>
                <div ref={(r) => this.Panel = r} style={{ height: "100%", width: this.state.viewportWidth }} className={gridBodyClasses}>
                    <table>
                        <tbody>
                            {this.props.columns && this.props.columns.length > 0 ? map(this.state.visibleRow, (item) => {
                                const isSelected = this.state.selectedIndexes.indexOf(item.actuallRowIndexStart) >= 0;
                                this.lastRowIndex = item.actuallRowIndexStart;
                                let className = 'grid-row-odd';
                                if (this.lastRowIndex % 2 == 0) className = 'grid-row-even';
                                if (item.rowData.isActive && !isNotImportList) className += ' grid-row-active';
                                return (
                                    <RowItem
                                        onPlotSymbol={(sym) => this.onPlotSymbol(sym)}
                                        dataId={item.actuallRowIndexStart}
                                        dragDrop={this.dragDrop}
                                        dragEnd={this.dragEnd}
                                        dragStart={this.dragStart}
                                        dragOver={this.dragOver}
                                        dragEnter={this.dragEnter}
                                        dragLeave={this.dragLeave}
                                        dragMove={this.dragMove}
                                        isEditable={isEditable}
                                        visibleIndex={item.visibleIndex}
                                        key={"row" + item.visibleIndex}
                                        rowIndex={item.actuallRowIndexStart}
                                        width={this.state.viewportWidth}
                                        rowHeight={this.props.rowHeight}
                                        ref={"row" + item.actuallRowIndexStart}
                                        handleSingleModeSelection={this.handleSingleModeSelection}
                                        isSelected={isSelected}
                                        columns={this.props.columns}
                                        data={item.rowData}
                                        className={className}
                                        updateGlobalFlag={this.props.updateGlobalFlag}
                                        onGridRowContextMenuClick={this.onGridRowContextMenuClick}
                                        textSize={this.props.textSize}
                                        allColumns={this.props.allColumns}
                                        gridTypeTable={this.props.gridTypeTable}
                                        getColumnWidth={this.props.getColumnWidth}
                                        frozenAreaWidth={this.props.frozenAreaWidth}
                                        getMouseOverColumnIndex={this.props.getMouseOverColumnIndex}
                                        selectedListId={this.props.selectedListId}
                                        instrumentType = {item.rowData.instrumentType}
                                        tooltip = {this.props.tooltip}
                                    />
                                )
                            }) : null}
                            {(addSymbolEnable == true) ? (this.props.source.length == 0 || this.lastRowIndex == this.props.source.length - 1) ? editableRow : null : null}
                            {(this.lastRowIndex == this.props.source.length - 1) ?
                                blankRow : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
