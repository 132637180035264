import BaseServiceApi from "BaseServiceApi";
import BrowserUtil from "BrowserUtil";
import ChartVisual from "../../../../../RayCustomControls/Chart/Visuals/ChartVisual.jsx";
import { connect } from "react-redux";
import ContextMenuConstants from "ContextMenuConstants";
import ErrorBoundary from "ErrorBoundary";
import LangType from "../../../../../Constants/LangType";
import LocalizationStore from "../../../../../Stores/Localization/LocalizationStore";
import moment from "moment";
import Pointer from "Pointer";
import React from "react";
import StringUtil from "StringUtil";
import TabDataGraphAction from "../../../../../Actions/TabDataGraphAction";
import textWidth from "text-width";
import ThemeHelper from "ThemeHelper";
import ThemeType from "ThemeType";
import { AlertTranslateHelper, PeriodicityTranslateHelper, PeriodicityTypeTranslateHelper } from '../../../../../Utils/TranslateHelper'
import { closeContextMenu, showContextMenu, updateContextHit } from "../../../../../Actions/PricePanelActions.js";
import { handleHighlightAlert, startDragginAlert, toggleAlertStatus, updateCurrentEditingAlert, updatePriceAlert, updateShownAlertIds } from "../../../../../Actions/AlertActions";

const MovingAverageType = BaseServiceApi.rayData["MovingAverageType"];
const MAType = {
    [MovingAverageType.SMA]: "SMA",
    [MovingAverageType.EMA]: "EMA"
}
const contextMenuLineId = {
    true: {
        "1": ContextMenuConstants.PRICE_ACTIVE_TRIGGERED_MENU,
        "0": ContextMenuConstants.PRICE_IN_ACTIVE_TRIGGERED_MENU
    },
    false: {
        "1": ContextMenuConstants.MA_ACTIVE_TRIGGERED_MENU,
        "0": ContextMenuConstants.MA_IN_ACTIVE_TRIGGERED_MENU
    }
}
class AlertIcon extends ChartVisual {
    constructor(props) {
        super(props);
        this.state = {
            shownAlertIds: [],
        }
        this.timeout = true;
        this.prepareMAAlertIcon = this.prepareMAAlertIcon.bind(this);
        this.getAlertIconName = this.getAlertIconName.bind(this);
        this.preparePriceAlertIcon = this.preparePriceAlertIcon.bind(this);
        this.hidePrice = this.hidePrice.bind(this);
        this.highlightMALine = this.highlightMALine.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.showContextMenu = this.showContextMenu.bind(this);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.getTextWidth = this.getTextWidth.bind(this);
    }

    subscribeEvents() {
        document.addEventListener("mousemove", this.handleMouseMove.bind(this), false);
        document.addEventListener("mouseup", this.handleMouseUp.bind(this), false);
    }
    unsubscribeEvents() {
        document.removeEventListener("mousemove", this.handleMouseMove.bind(this), false);
        document.removeEventListener("mouseup", this.handleMouseUp.bind(this), false);
    }

    prepareMAAlertIcon() {
        const alertArr = [];
        let i = 0;
        const currentPeriodicity = PeriodicityTranslateHelper[this.props.periodicity];
        if (this.props.maSettings) {
            const maAlerts = this.props.datagraphAlertsList.filter((item) => ((item.isActive === 1 || item.isActive === 0 && item.isTriggered === 1) && item.MAAlertData && item.msid === this.props.SymbolInfo.MsId));
            maAlerts.forEach((alert) => {
                if (alert.isActive === 0) {
                    this.state.shownAlertIds.push(alert.alertId);
                    this.props.updateShownAlertIds(this.state.shownAlertIds);
                }
                let maData;
                for (let j = 0; j < this.props.maSettings.length; j++) {
                    const item = this.props.maSettings[j];
                    if (item.IsVisible === true && item.ma === alert.MAAlertData.length &&
                        item.maType === (alert.maTypeDescription) && currentPeriodicity === PeriodicityTypeTranslateHelper[alert.MAAlertData.periodicity]) {
                        maData = this.props.maLineData[j];
                        break;
                    }
                }
                if (maData && Object.keys(maData).length > 0) {
                    const yPos = maData[Object.keys(maData).length - 1].yPrice - 5;
                    const xPos = maData[Object.keys(maData).length - 1].xAxis;
                    if (BrowserUtil.getBrowserName() !== 'IE' && BrowserUtil.getBrowserName() !== 'Netscape') {
                        alertArr.push(<foreignObject x={xPos} y={yPos - 3} width="16" height="16">
                            <span className={`ma-alert-img ${this.getAlertIconName(alert)}`}
                                onMouseEnter={(e) => this.highlightMALine(e, alert)}
                                onMouseLeave={(e) => this.highlightMALine(e, null)}
                                onContextMenu={(e) => this.showContextMenu(e, alert, false)}
                                id={`imgprice${alert.targetPrice}`}></span></foreignObject>);
                        alertArr.push(<svg className="svg ma-alert"
                            key={`maAlert${i}`} height="100%" width="100%"
                            data-disable-trackprice={true}
                        >
                        </svg >);
                    } else {
                        alertArr.push(<svg className="svg ma-alert"
                            key={`maAlert${i}`} height="100%" width="100%"
                            data-disable-trackprice={true}>
                            <image className="ma-alert-img" title="MA Alert"
                                onMouseEnter={(e) => this.highlightMALine(e, alert)}
                                onMouseLeave={(e) => this.highlightMALine(e, null)}
                                onContextMenu={(e) => this.showContextMenu(e, alert, false)}
                                height="12" width="12"
                                id={`imgmaAlert${yPos}`}
                                xlinkHref={`../Asset/images/${this.getAlertIconNameIE(alert)}`}
                                x={xPos} y={yPos} />
                        </svg >);
                    }
                }
                i = i + 1;
            });
        }
        return alertArr;
    }

    getAlertIconName(alert) {
        if (this.props.theme === ThemeType.Dark) {
            return alert.isActive === 0 ? this.props.highlightedAlertObj.alertId === alert.alertId ? 'icn-price-alert-trigger-hover' : 'icn-price-alert-trigger' : this.props.highlightedAlertObj.alertId === alert.alertId ? 'icn-price-alert-Hover' : 'icn-price-alert-default';
        }
        else {
            return alert.isActive === 0 ? this.props.highlightedAlertObj.alertId === alert.alertId ? 'icn-price-alert-trigger-hover' : 'icn-price-alert-trigger' : this.props.highlightedAlertObj.alertId === alert.alertId ? 'icn-price-alert-Hover' : 'icn-price-alert-default';
        }
    }
    getAlertIconNameIE(alert) {
        if (this.props.theme === ThemeType.Dark) {
            return alert.isActive === 0 ? this.props.highlightedAlertObj.alertId === alert.alertId ? 'icon-alert-triggered-hover.svg' : 'icon-alert-triggered.svg' : this.props.highlightedAlertObj.alertId === alert.alertId ? 'icon-alert-hover-dk.svg' : 'icon-alert-default-dk.svg';
        }
        else {
            return alert.isActive === 0 ? this.props.highlightedAlertObj.alertId === alert.alertId ? 'icon-alert-triggered-hover.svg' : 'icon-alert-triggered.svg' : this.props.highlightedAlertObj.alertId === alert.alertId ? 'icon-alert-hover-lt.svg' : 'icon-alert-default-lt.svg';
        }
    }
    preparePriceAlertIcon() {
        const alertArr = [];
        const color = this.props.theme === ThemeType.Dark ? "#A9A9A9" : "black";
        const xPos = this.props.dimension.width - 15;
        const pointXValue = parseInt(xPos)
        const priceAlerts = this.props.datagraphAlertsList.filter((item) => ((item.isActive === 1 && item.MAAlertData === null || item.isActive === 0 && item.isTriggered === 1) && item.TLAlertData === null && item.patternRecAlertData === null && item.StockAlertData === null && item.msid === this.props.SymbolInfo.MsId));
        if ((this.props.scale.Height > 0 || this.props.scale.height > 0)) {
            priceAlerts.forEach((alert) => {
                if (!(alert.isActive === 0 && alert.isTriggered === 0)) {
                    if (alert.isActive === 0) {
                        this.state.shownAlertIds.push(alert.alertId);
                        this.props.updateShownAlertIds(this.state.shownAlertIds);
                    }
                    const yPos = this.props.scale.ComputeY(alert.targetPrice) - 5;
                    const pointYValue = parseInt(yPos)

                    if (25 < yPos && this.props.dimension.height - 15 > yPos) {
                        if (BrowserUtil.getBrowserName() !== 'IE' && BrowserUtil.getBrowserName() !== 'Netscape') {
                            alertArr.push(<foreignObject key={`price-bell${alert.alertId}`} x={xPos - 8} y={yPos - 3} width="16" height="16">
                                <span className={`price-alert-img ${this.getAlertIconName(alert)}`}
                                    //style={{height:16, width:16}}
                                    onMouseEnter={() => this.highlightPrice(alert, xPos + 15, yPos - 5)}
                                    onMouseLeave={this.hidePrice}
                                    onMouseDown={(e) => this.handleMouseDown(e, alert)}
                                    onContextMenu={(e) => this.showContextMenu(e, alert, true)}
                                    id={`imgprice${alert.targetPrice}`}></span></foreignObject>);
                            alertArr.push(<g className="price-alert-g"
                                key={`price${alert.alertId}`} height="100%" width="100%"
                                data-disable-trackprice={true}
                                onMouseEnter={() => this.highlightPrice(alert, xPos + 15, yPos - 5)}
                                onMouseLeave={this.hidePrice}
                                onMouseDown={(e) => this.handleMouseDown(e, alert)}
                                onContextMenu={(e) => this.showContextMenu(e, alert, true)}
                            >
                                <polygon
                                    points={`${pointXValue + 12},${pointYValue + 6} ${pointXValue + 9},${pointYValue + 8} ${pointXValue + 9},${pointYValue + 4}`}
                                    style={{ stroke: color, fill: color }} />
                            </g >);
                        } else {
                            alertArr.push(<g className="price-alert-g"
                                key={`price${alert.alertId}`} height="100%" width="100%"
                                data-disable-trackprice={true}
                                onMouseEnter={() => this.highlightPrice(alert, xPos + 15, yPos - 5)}
                                onMouseLeave={this.hidePrice}
                                onMouseDown={(e) => this.handleMouseDown(e, alert)}
                                onContextMenu={(e) => this.showContextMenu(e, alert, true)}
                            >
                                <image className="price-alert-img"
                                    height="12" width="12"
                                    id={`imgprice${alert.targetPrice}`}
                                    xlinkHref={`../Asset/images/${this.getAlertIconNameIE(alert)}`}
                                    x={xPos - 4} y={yPos} />
                                <polygon
                                    points={`${pointXValue + 12},${pointYValue + 6} ${pointXValue + 9},${pointYValue + 8} ${pointXValue + 9},${pointYValue + 4}`}
                                    style={{ stroke: color, fill: color }} />
                            </g >);
                        }
                    }

                    // }

                }
            });
        }

        return alertArr;
    }

    highlightPrice(alert) {
        if (!this.props.isDragging && this.props.highlightedAlertObj.alertId !== alert.alertId) {
            const yPos = this.props.scale.ComputeY(alert.targetPrice)
            this.props.handleHighlightAlert(alert, this.props.dimension.width, yPos)
        }
    }

    hidePrice() {
        if (this.props.highlightedAlertObj.alertId !== 0 && !this.props.isDragging) {
            this.props.handleHighlightAlert({ alertId: 0 }, 0, 0);
        }
    }

    highlightMALine(e, maObj) {
        if (!maObj || PeriodicityTranslateHelper[this.props.periodicity] === PeriodicityTypeTranslateHelper[maObj.MAAlertData.periodicity]) {
            TabDataGraphAction.onMAAlertHover(maObj);
            if (maObj && this.props.highlightedAlertObj.alertId !== maObj.alertId) {
                const yPos = this.props.scale.ComputeY(maObj.targetPrice)
                this.props.handleHighlightAlert(maObj, this.props.dimension.width, yPos);
            }
            else if (!maObj) {
                this.props.handleHighlightAlert({ alertId: 0 }, 0, 0);
            }
        }
    }

    handleMouseDown(e, alert) {
        if (e.button !== 2 && alert.isActive > 0) {
            this.subscribeEvents();
            this.props.startDragginAlert(true, alert, this.props.dimension.width, e.clientY - this.props.dimension.top);
        }

    }

    showContextMenu(e, alert, isPriceAlert) {
        this.props.updateCurrentEditingAlert(alert);
        this.props.updateContextHit({lineID: contextMenuLineId[isPriceAlert][alert.isActive]})
        this.props.showContextMenu(e, this.props.dimension.top);
        e.preventDefault();
    }

    handleMouseUp(e) {
        if (e.button !== 2) {
            if (this.props.isDragging) {
                this.unsubscribeEvents();
                let newPrice = this.props.scale.ComputePrice(e.clientY - this.props.dimension.top)
                newPrice = newPrice > 1 ? newPrice.toFixed(2) : newPrice.toFixed(3);
                this.props.highlightedAlertObj.targetPrice = parseFloat(newPrice);
                this.props.updatePriceAlert(this.props.highlightedAlertObj);
                this.props.startDragginAlert(false, { alertId: 0 }, 0, 0);
            }
        }
    }

    handleMouseMove(e) {
        if (this.props.isDragging && this.timeout) {
            this.timeout = false
            setTimeout(() => {
                let newPrice = this.props.scale.ComputePrice(e.clientY - this.props.dimension.top)
                newPrice = newPrice > 1 ? newPrice.toFixed(2) : newPrice.toFixed(3);
                if (25 < e.clientY - (this.props.dimension.top + 5) && this.props.dimension.height - 15 > e.clientY - this.props.dimension.top) {
                    this.props.highlightedAlertObj.targetPrice = parseFloat(newPrice);
                    this.props.handleHighlightAlert(this.props.highlightedAlertObj, this.props.dimension.width, e.clientY - this.props.dimension.top);
                }
                e.preventDefault();
                e.stopPropagation();
                this.timeout = true;
            }, 10);
        }
    }

    getTextWidth(text) {
        const width = textWidth(text, {
            family: "calibrib",
            size: 14
        });
        return width;
    }

    showInfoBox() {
        const backgroundColor = ThemeHelper.getThemedBrush("E1E20FEFEF0");
        const xLeft = `${this.props.pointerXPos - 200}px`;
        const ytop = `${this.props.pointerYPos}px`;
        const height = "70px";
        let width = "135px";
        const paddingLeftRight = 10;
        const border = 1;
        if (this.props.highlightedAlertObj) {
            const arr = []
            const textA = this.props.highlightedAlertObj.MAAlertData ? `${AlertTranslateHelper.MA_ALERT + this.props.highlightedAlertObj.MAAlertData.length} ${MAType[this.props.highlightedAlertObj.MAAlertData.maType] || ""}` : AlertTranslateHelper.PRICE_ALERT + this.props.highlightedAlertObj.targetPrice;
            const widthA = this.getTextWidth(textA);
            arr.push(widthA);
            const widthB = this.getTextWidth(AlertTranslateHelper.LAST_TRIGGERED_ON);
            arr.push(widthB);
            const textC = this.formatDate(this.props.highlightedAlertObj.dateLastTriggered);
            const widthC = this.getTextWidth(textC);
            arr.push(widthC);
            const maxValue = Math.max.apply(null, arr);
            if (maxValue) {
                width = maxValue + paddingLeftRight * 2 + border * 2;
            }
            if (BrowserUtil.isIEBrowser() || BrowserUtil.getBrowserName() === "Safari") {
                width = width + border * 2;
            }
        }

        const textStyle = {
            backgroundColor: backgroundColor,
            position: "absolute",
            left: xLeft,
            top: ytop,
            height: height,
            width: width,
            zIndex: 1200
        };
        return (
            this.props.highlightedAlertObj &&
            <div className="alert-trigger-popover" style={textStyle} key={"trig"} >
                <div className="alert-trigger-popover-box">
                    {/* style={{ textAlign: "Center", fontFamily: "calibri" }} */}
                    <span className="al-Pricing">
                        {this.props.highlightedAlertObj.MAAlertData ? `${AlertTranslateHelper.MA_ALERT + this.props.highlightedAlertObj.MAAlertData.length} ${MAType[this.props.highlightedAlertObj.MAAlertData.maType] || ""}` : AlertTranslateHelper.PRICE_ALERT + this.props.highlightedAlertObj.targetPrice}
                    </span>
                    <span className="al-triggring">
                        {AlertTranslateHelper.LAST_TRIGGERED_ON}
                    </span>
                    <span className="al-dating">
                        {this.formatDate(this.props.highlightedAlertObj.dateLastTriggered)}
                    </span>
                    {this.props.highlightedAlertObj.priceAlertData && this.props.highlightedAlertObj.priceAlertData.note &&
                        <div style={{ backgroundColor: backgroundColor, width: width }} className="alert-trigger-popover al-note">
                            Note:&nbsp;{this.props.highlightedAlertObj.priceAlertData.note}
                        </div>}
                </div>
            </div>);
    }

    formatDate(date) {
        if (LocalizationStore.getLang() === LangType.ZH_CN) {
            return LocalizationStore.getTranslatedData(`cal_${moment(date).local().format("MMM")}`, moment(date).local().format("MMM")) + LocalizationStore.getTranslatedData("sb_day", "{0}").replace("{0}", moment(date).local().format("D")) + LocalizationStore.getTranslatedData(`ss_${moment(date).local().format("a")}`, moment(date).local().format("a")) + moment(date).local().format("hh:mm");
        }
        else {
            return `${moment(date).local().format("D")} ${moment(date).local().format("MMM")} at ${moment(date).local().format("hh:mm")}${moment(date).local().format("a")}`;
        }
    }

    render() {
        this.state.shownAlertIds = [];
        const arrowColor = ThemeHelper.getThemedBrush("fff666");
        const textColor = ThemeHelper.getThemedBrush("000fff");
        let contentR = "";
        if (this.props.highlightedAlertObj) {
            const changeInfo = (this.props.highlightedAlertObj.targetPrice - (this.props.currPrice)) * 100 / this.props.currPrice;
            const sign = changeInfo > 0 ? "+" : "";
            contentR = `(${sign}${changeInfo.toFixed(0)}%)`;
        }
        return (
            <ErrorBoundary>
                <div style={{ width: "100%", height: "100%" }} id="alerts">
                    {!StringUtil.isEmpty(this.props.datagraphAlertsList) && this.props.IsShowAlertsIcon &&
                        <svg className="svg price-alert" height="100%" width="100%">
                            {this.preparePriceAlertIcon()}
                            {this.prepareMAAlertIcon()}
                            {this.props.isDragging &&
                                <line strokeDasharray="2, 3" x1="0"
                                    y1={this.props.pointerYPos + 10}
                                    x2={this.props.pointerXPos - 15}
                                    y2={this.props.pointerYPos + 10}
                                    style={{ stroke: "#323433", strokeWidth: "2" }} />}
                        </svg>}
                    {this.props.IsShowAlertsIcon && this.props.pointerXPos > 0 && this.props.pointerYPos < this.props.dimension.height-15 && this.props.highlightedAlertObj && !this.props.highlightedAlertObj.MAAlertData &&
                        <Pointer key={"PricePointer"}
                            contentR={contentR}
                            contentL={this.props.highlightedAlertObj.targetPrice?.toString()}
                            contentB={this.props.highlightedAlertObj.note}
                            isActive={this.props.highlightedAlertObj.isActive}
                            x={this.props.pointerXPos}
                            y={this.props.pointerYPos}
                            height={20}
                            width={100}
                            stroke="black"
                            fill={arrowColor}
                            lcolor={textColor}
                            rcolor={textColor} />}

                    {this.props.showAlertBox && this.showInfoBox()}
                </div>
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = ({ DatagraphReducers, alertReducer, appColor }) => {
    const { periodicity, SymbolInfo, rightScaleWidth, currPrice } = DatagraphReducers.DataGraphReducer;
    const { scale, dimension } = DatagraphReducers.PriceChartReducer
    const { maSettings, maLineData } = DatagraphReducers.MAReducer;
    const { theme } = appColor;
    const { highlightedAlertObj, datagraphAlertsList, IsShowAlertsIcon, pointerXPos, pointerYPos, isDragging, showAlertBox } = alertReducer.DataGraphAlertReducer;
    return {
        periodicity, SymbolInfo, highlightedAlertObj, rightScaleWidth, currPrice, datagraphAlertsList, scale, maSettings, maLineData, IsShowAlertsIcon, dimension,
        theme, pointerXPos, pointerYPos, isDragging, showAlertBox
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleAlertStatus: (alertObj) => dispatch(toggleAlertStatus(alertObj)),
    handleHighlightAlert: (highlightedAlertObj, pointerXPos, pointerYPos) => dispatch(handleHighlightAlert(highlightedAlertObj, pointerXPos, pointerYPos)),
    showContextMenu: (event, top, lineID) => dispatch(showContextMenu(event, top, lineID)),
    updateContextHit: (contextMenuObj) => dispatch(updateContextHit(contextMenuObj)),
    closeContextMenu: () => dispatch(closeContextMenu()),
    updateCurrentEditingAlert: (currentEditingAlert) => dispatch(updateCurrentEditingAlert(currentEditingAlert)),
    updateShownAlertIds: (shownAlertIds) => dispatch(updateShownAlertIds(shownAlertIds)),
    updatePriceAlert: (data) => dispatch(updatePriceAlert(data)),
    startDragginAlert: (isDragging, highlightedAlertObj, pointerXPos, pointerYPos) => dispatch(startDragginAlert(isDragging, highlightedAlertObj, pointerXPos, pointerYPos))
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertIcon)