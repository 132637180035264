import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class DismissAlertRequest extends BaseServiceRequest {
    constructor(alertID) {
        super();

        super.addRequestUri("dismiss.pb");
        super.addPathParameter(alertID.toString());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "Response";
    }

}