import StockMarketUtil from "../../Utils/StockMarketUtil";
import moment from "moment";
import GraphType from "GraphType";
import DateHelper from "DateHelper";
import BaseServiceApi from 'BaseServiceApi';
import CurrencyHelper from "CurrencyHelper";
import LocalizationStore from 'LocalizationStore';
import ExtremeDataValue from "ExtremeDataValue";
import DocUtil from "DocUtil";

let PatternInfoType = BaseServiceApi.rayData["PatternInfoType"];
let PatternStatusType = BaseServiceApi.rayData["PatternStatusType"];
let PatternType = BaseServiceApi.rayData["PatternType"];

class CalcPatternRecognition {
    constructor(_state) {
        this.state = _state;
    }
    NDateNode(nDate, absolute = false) {
        let _state = this.state;
        let periodicity = _state.periodicity;
        let dayOfWeek = nDate.getDay();
        if (dayOfWeek == 6)
            nDate.setDate(nDate.getDate() - 1);
        if (dayOfWeek == 0)
            nDate.setDate(nDate.getDate() - 2);

        switch (periodicity) {
            case GraphType.Weekly:
                if (dayOfWeek != 5) {
                    var dys = 5 - dayOfWeek;
                    nDate.setDate(nDate.getDate() + dys);
                    if (nDate > _state.endDate && !absolute) nDate = _state.endDate;
                }
                break;
            case GraphType.Monthly:
                nDate = StockMarketUtil.GetMEndDate(nDate);
                if (nDate > _state.endDate && !absolute)
                    nDate = _state.endDate;
                break;
            case GraphType.Annual:
                {
                    nDate = StockMarketUtil.GetAEndDate(nDate);
                    if (nDate > _state.endDate && !absolute) nDate = _state.endDate;
                }
                break;
            case GraphType.Quarterly:
                {
                    var year = nDate.getFullYear();
                    var add = nDate.getMonth() % 3;
                    var month = (nDate.getMonth() + (add == 1 ? 2 : add == 2 ? 1 : 0)) + 1;
                    if (month > 12) {
                        month = 1;
                        year++;
                    }

                    nDate = new Date(year, month - 1, 1);
                    nDate.setDate(nDate.getDate() - 1);
                    nDate = StockMarketUtil.GetMEndDate(nDate);
                    if (nDate > _state.endDate && !absolute) nDate = _state.endDate;
                }
                break;
            default:
                break;
        }
        // if ((MtDates != null) && (!MtDates.ContainsKey(nDate))) {
        //   nDate = MtDates.FindClosestDate(nDate);
        // }
        return nDate;
    }
    SetTimelineDate(xDate) {
        var date = this.NDateNode(xDate);
        return date;
    }
    FindTimeIndex(date) {
        let _state = this.state;
        var index = 0;
        var stockData = _state.TimeLine;
        var lng = _state.TimeLine.length;
        if (stockData[lng - 1] && date.getTime() < stockData[lng - 1].Date.getTime())
            return -1;
        for (; index < lng; index++)
            if (date.getTime() === stockData[index].Date.getTime())
                break;
        return index < lng ? index : -1;
    }
    FindStockIndex(date) {
        let _state = this.state;
        var index = 0;
        var stockData = _state.HiLowPoints.allPoints;
        var lng = _state.HiLowPoints.allPoints.length;
        if (stockData[lng - 1] && date.getTime() < stockData[lng - 1].Date.getTime())
            return -1;
        for (; index < lng; index++)
            if (date.getTime() === stockData[index].Date.getTime())
                break;
        return index < lng ? index : -1;
    }
    keyPriceRanges(node, pivotPriceIn, patternRecognitionSettings) {
        let _state = this.state;
        var periotLength = 13 * 4;   //13 weeks
        if (_state.periodicity == GraphType.Daily || _state.isIntraday)
            periotLength = 40 * 4; // 8 weeks
        if (_state.isIntraday)
            periotLength = 2 * 390 * 4; // 8 weeks

        var pivotHigh = _state.HiLowPoints.allPoints[pivotPriceIn].graphData.High;
        var pivotPriceMin = (patternRecognitionSettings.pivotStart + 100.0) / 100.0;
        var pivotPriceMax = (patternRecognitionSettings.pivotEnd + 100.0) / 100.0;
        node.pivotY0 = _state.scale.ComputeY(pivotHigh * pivotPriceMin);
        var pivotY1 = _state.scale.ComputeY(pivotHigh * pivotPriceMax);
        node.pivotH = node.pivotPriceY;
        node.pivotH1 = Math.abs(node.pivotY0 - pivotY1);
        node.periotLength = periotLength;

        var profitPriceMin = (patternRecognitionSettings.profitStart + 100.0) / 100.0;
        var profitPriceMax = (patternRecognitionSettings.profitEnd + 100.0) / 100.0;
        node.pivotY2 = _state.scale.ComputeY(pivotHigh * profitPriceMin);
        var pivotY3 = _state.scale.ComputeY(pivotHigh * profitPriceMax);
        node.pivotH2 = Math.abs(node.pivotY2 - pivotY3);

        var lossPriceMin = (patternRecognitionSettings.lossStart * -1) / 100;
        var lossPriceMax = (patternRecognitionSettings.lossEnd * -1) / 100;
        var pivotY4 = _state.scale.ComputeY(pivotHigh * (1 - lossPriceMax));
        node.pivotY5 = _state.scale.ComputeY(pivotHigh * (1 - lossPriceMin));
        node.pivotH3 = Math.abs(pivotY4 - node.pivotY5);

    }
    getValue(infos, type) {
        let lng = infos.length;
        for (var i = 0; i < lng; i++) {
            if (infos[i].infoType == type) {
                return infos[i].infoValue;
            }
        }
        return undefined;
    }
    SetPowerFromPivot(itemsource, pivotPriceNode, baseEndIn, priceNode, pivotDate, node, state) {
        const graphNodes = itemsource.filter((t) => t.graphData.Volume.low > -1 && t.Date >= pivotDate);

        if(graphNodes.length == 0)
        return;
        
        const nodesFromPivot = state.periodicity === GraphType.Daily ? 15 : 3;
        const grNodesLength = Math.max(graphNodes.length, nodesFromPivot);
        const maxDate = graphNodes[grNodesLength - nodesFromPivot].Date;
        const mynodes = graphNodes.filter((t) => t.Date >= pivotDate && t.Date <= maxDate);

        if (mynodes.length < 1)
            return;

        const maxPrice = Math.max.apply(Math, mynodes.map((t) => t.graphData.High));
        const flagNode = mynodes.filter((t) => t.graphData.High === maxPrice);

        if (flagNode.length < 1)
            return;

        const priceChange = pivotPriceNode.graphData.High < 0.001 ? 0 : 100 * (flagNode[0].graphData.High - pivotPriceNode.graphData.High) / pivotPriceNode.graphData.High;

        if (priceChange < 20) return;
        node.flagNode = flagNode[0];
        node.flagNode.yAxis = state.scale.ComputeY(flagNode[0].graphData.High)-5;
        if (node.flagNode.yAxis < 50) {
            node.flagNode.yAxis = state.scale.ComputeY(flagNode[0].graphData.Low)+5;
            node.flagNode.Down = true;
        }
        node.flagNode.priceChange = priceChange;
    }
    CalculatePRData(prData, patternRecognitionSettings) {
        let _state = this.state;
        _state.PRData = {};
        _state.priceChangeValue = 0;
        _state.priceChange = '';
        _state.ticks = 0;
        _state.units = '';
        if (prData == null) {
            return;
        }
        var prDataList = {
            FlatPatternNode: [],
            ConsolidationPatternNode: [],
            CupPatternNode: [],
            DoubleBottomsNode: [],
            ChannelLinesNode: [],
            ClimaxTopsNode: [],
            IPOBasePatternNode: [],
            AscendingBasePatternNode: [],
            TightAreaPatternNode: []
        };
        var saveKPR = [];
        // var saveData = {
        //     dateKey: {},
        //     type: 0,
        //     node: {}
        // };
        if (patternRecognitionSettings.length < 1) {
            _state.PRData = prDataList;
            return;
        }
        // if (!patternRecognitionSettings.IsVisible) {
        //     _state.PRData = prDataList;
        //     return;
        // }

        var endDate = _state.endDate;
        //var hiLoPoints = _state.HiLowPoints;
        if (_state.HiLowPoints.allPoints === undefined || _state.HiLowPoints.allPoints[0] === undefined) return;
        var priceNode = _state.HiLowPoints.allPoints[0].graphData;
        var pivotDisplayed = false;
        var lsNodeIndex = _state.HiLowPoints.allPoints.length - 1;
        if (!_state.HiLowPoints.allPoints[lsNodeIndex]) {
            var flength = _state.HiLowPoints.allPoints.length;
            lsNodeIndex = 0;
            for (; lsNodeIndex < flength && _state.HiLowPoints.allPoints[lsNodeIndex]; lsNodeIndex++) { }
            lsNodeIndex--;
        }
        var lastNode = _state.HiLowPoints.allPoints[lsNodeIndex];
        let currency = CurrencyHelper.get(_state.StockInformation.DefaultCurrency);

        var daysFromPivot;
        var ticks;
        if (prData.flats != null && patternRecognitionSettings.FlatPattern) {
            let lng = prData.flats.length;
            for (let i = lng - 1; i > -1; i--) {
                let node = prData.flats[i];
                if (node == null) continue;
                dateKey = moment(new Date(node.baseEndDt.toNumber())).format("M/D/YYYY");
                theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                if (_state.TimeLine == null) return;
                node.BaseEndDt = this.SetTimelineDate(new Date(dateKey));
                baseEndIn = this.FindStockIndex(node.BaseEndDt);
                dateKey = moment(new Date(node.baseStartDt.toNumber())).format("M/D/YYYY");
                node.BaseStartDt = this.SetTimelineDate(new Date(dateKey));
                if (node.BaseStartDt < lastNode.Date) continue;
                baseStartIn = this.FindStockIndex(node.BaseStartDt);
                dateKey = moment(new Date(node.pivotPriceDt.toNumber())).format("M/D/YYYY");
                node.PivotPriceDt = this.SetTimelineDate(new Date(dateKey));
                if (node.pivotDt) {
                    dateKey = moment(new Date(node.pivotDt.toNumber())).format("M/D/YYYY");
                    node.PivotDt = this.SetTimelineDate(new Date(dateKey));
                }
                else
                    node.PivotDt = priceNode.Date;
                pivotPriceIn = this.FindStockIndex(node.PivotPriceDt);
                node.Title = [];
                node.Title.length = 4;
                if (patternRecognitionSettings.AdvancePattern) {
                    node.Title.length = 8;
                    if (_state.periodicity === GraphType.Weekly && node.PatternType != PatternType.HIGHTIGHTFLAG_Pattern)
                        node.Title.length = 9;
                }
                if (baseEndIn > -1 && baseStartIn > -1 && pivotPriceIn > -1) {
                    var stockData = _state.HiLowPoints.allPoints;
                    let maxY = 99999;
                    let minY = 0;
                    for (var mPt = baseEndIn; mPt <= baseStartIn; mPt++) {
                        if (stockData[mPt].yLow > minY)
                            minY = stockData[mPt].yLow;
                        if (stockData[mPt].yHigh < maxY)
                            maxY = stockData[mPt].yHigh;
                    }
                    node.minY = minY;
                    node.maxY = maxY;
                    node.startIn = _state.HiLowPoints.allPoints[baseStartIn].xAxis;
                    node.endIn = _state.HiLowPoints.allPoints[baseEndIn].xAxis;
                    var pivotPriceNode = _state.HiLowPoints.allPoints[pivotPriceIn];
                    node.pivotIn = pivotPriceNode.xAxis;
                    node.pivotHighPt = pivotPriceNode.yHigh;
                    node.pivotPriceY = pivotPriceNode.yHigh;
                    node.PatternName = "Flat Base";
                    let info = node.baseStage + (node.baseNumber > 1 ? " (" + node.baseNumber + ")" : "");
                    node.Title[0] = { title: LocalizationStore.getTranslatedData("stage", "Stage:"), info: info };
                    let price = pivotPriceNode.graphData.High < 1 ? pivotPriceNode.graphData.High.toFixed(2) : ExtremeDataValue.showPrice(pivotPriceNode.graphData.High);
                    info = currency + price;
                    node.Title[1] = { title: LocalizationStore.getTranslatedData("pivot", "Pivot:"), info: info };
                    // var closeY = _state.scale.ComputeY(priceNode.Close);
                    // var pivotTDC = DocUtil.ConvertDateTimeToTdc(pivotPriceNode.graphData.Date);
                    // var currTDC = DocUtil.ConvertDateTimeToTdc(_state.HiLowPoints.allPoints[baseEndIn].Date);
                    // var dfromPivot = currTDC - pivotTDC;
                    daysFromPivot = DateHelper.DaysDiff(pivotPriceNode.graphData.Date, _state.HiLowPoints.allPoints[baseEndIn].Date).Days;
                    ticks = node.baseLength;
                    var units = LocalizationStore.getTranslatedData("ch_pivot_dlys", "days");
                    if (_state.periodicity === GraphType.Weekly) {
                        units = LocalizationStore.getTranslatedData("pr_wks", "wks");
                    }
                    info = ticks + " " + units;
                    if (node.baseStatus === PatternStatusType.MAXEDOUT_PatternStatus) {
                        info += " ("+ LocalizationStore.getTranslatedData("ch_er_ela_view_dlbmax","max") + ")";
                    }
                    node.Title[2] = { title: LocalizationStore.getTranslatedData("pt_length", "Length:"), info: info };
                    let baseScore = this.getValue(node.infos, PatternInfoType.BaseDepth_PatternInfo);
                    info = (baseScore * 100).toFixed(0) + "%";
                    node.Title[3] = { title: LocalizationStore.getTranslatedData("depth", "Depth:"), info: info };
                    if (patternRecognitionSettings.AdvancePattern) {
                        let blueAvg = this.getValue(node.infos, PatternInfoType.BlueBars_PatternInfo);
                        let redAvg = this.getValue(node.infos, PatternInfoType.RedBars_PatternInfo);
                        let redSupport = this.getValue(node.infos, PatternInfoType.SupportBars_PatternInfo);
                        let blueStall = this.getValue(node.infos, PatternInfoType.StallBars_PatternInfo);
                        node.Title[4] = { title: LocalizationStore.getTranslatedData("pt_blueVolAvg", "Blue, Vol>Avg:"), info: blueAvg };
                        node.Title[5] = { title: LocalizationStore.getTranslatedData("pt_redVolAvg", "Red, Vol>Avg:"), info: redAvg };
                        node.Title[6] = { title: LocalizationStore.getTranslatedData("pt_redSupport", "Red Support:"), info: redSupport };
                        node.Title[7] = { title: LocalizationStore.getTranslatedData("pt_blueStall", "Blue Stall:"), info: blueStall };
                        if (_state.periodicity === GraphType.Weekly && node.PatternType != PatternType.HIGHTIGHTFLAG_Pattern) {
                            node.Title[8] = { title: LocalizationStore.getTranslatedData("pt_baseScore", "Base Score:"), info: node.baseScore };
                        }
                    }
                    if (node.baseStatus === PatternStatusType.COMPLETED_PatternStatus && node.pivotDt && patternRecognitionSettings.PowerFromPivot) {
                        this.SetPowerFromPivot(_state.HiLowPoints.allPoints, pivotPriceNode, baseEndIn, priceNode, node.PivotDt, node, _state);
                    }
                    saveKPR.push({ dateKey: node.BaseEndDt, type: 0, node: node });
                }
                prDataList.FlatPatternNode.push(node);
            }
        }

        if (prData.consolidations != null && patternRecognitionSettings.ConsolidationPattern) {
            let lng = prData.consolidations.length;
            for (let i = lng - 1; i > -1; i--) {
                let node = prData.consolidations[i];
                if (node == null) continue;
                dateKey = moment(new Date(node.baseEndDt.toNumber())).format("M/D/YYYY");
                theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                node.BaseEndDt = this.SetTimelineDate(new Date(dateKey));
                baseEndIn = this.FindStockIndex(node.BaseEndDt);
                dateKey = moment(new Date(node.baseStartDt.toNumber())).format("M/D/YYYY");
                node.BaseStartDt = this.SetTimelineDate(new Date(dateKey));
                if (node.BaseStartDt < lastNode.Date) continue;
                baseStartIn = this.FindStockIndex(node.BaseStartDt);
                dateKey = moment(new Date(node.pivotPriceDt.toNumber())).format("M/D/YYYY");
                node.PivotPriceDt = this.SetTimelineDate(new Date(dateKey));
                if (node.pivotDt) {
                    dateKey = moment(new Date(node.pivotDt.toNumber())).format("M/D/YYYY");
                    node.PivotDt = this.SetTimelineDate(new Date(dateKey));
                }
                else
                    node.PivotDt = priceNode.Date;
                pivotPriceIn = this.FindStockIndex(node.PivotPriceDt);
                node.Title = [];
                node.Title.length = 4;
                if (patternRecognitionSettings.AdvancePattern) {
                    node.Title.length = 8;
                    if (_state.periodicity === GraphType.Weekly && node.PatternType != PatternType.HIGHTIGHTFLAG_Pattern)
                        node.Title.length = 9;
                }
                if (baseEndIn > -1 && baseStartIn > -1 && pivotPriceIn > -1) {
                    stockData = _state.HiLowPoints.allPoints;
                    let maxY = 99999;
                    let minY = 0;
                    for (mPt = baseEndIn; mPt <= baseStartIn; mPt++) {
                        if (stockData[mPt].yLow > minY)
                            minY = stockData[mPt].yLow;
                        if (stockData[mPt].yHigh < maxY)
                            maxY = stockData[mPt].yHigh;
                    }
                    node.minY = minY;
                    node.maxY = maxY;
                    node.startIn = _state.HiLowPoints.allPoints[baseStartIn].xAxis;
                    node.endIn = _state.HiLowPoints.allPoints[baseEndIn].xAxis;
                    pivotPriceNode = _state.HiLowPoints.allPoints[pivotPriceIn];
                    node.pivotIn = pivotPriceNode.xAxis;
                    node.pivotHighPt = pivotPriceNode.yHigh;
                    node.pivotPriceY = pivotPriceNode.yHigh;
                    node.PatternName = "Consolidation";
                    // var closeY = _state.scale.ComputeY(priceNode.Close);
                    // var pivotTDC = DocUtil.ConvertDateTimeToTdc(pivotPriceNode.graphData.Date);
                    // var currTDC = DocUtil.ConvertDateTimeToTdc(_state.HiLowPoints.allPoints[baseEndIn].Date);
                    // var dfromPivot = currTDC - pivotTDC;
                    daysFromPivot = DateHelper.DaysDiff(pivotPriceNode.graphData.Date, _state.HiLowPoints.allPoints[baseEndIn].Date).Days;
                    let info = node.baseStage + (node.baseNumber > 1 || node.baseStage !== "1" ? " (" + node.baseNumber + ")" : "");
                    node.Title[0] = { title: LocalizationStore.getTranslatedData("stage", "Stage:"), info: info };
                    let price = pivotPriceNode.graphData.High < 1 ? pivotPriceNode.graphData.High.toFixed(2) : ExtremeDataValue.showPrice(pivotPriceNode.graphData.High);
                    info = currency + price;
                    node.Title[1] = { title: LocalizationStore.getTranslatedData("pivot", "Pivot:"), info: info };
                    ticks = node.baseLength;
                    let units = LocalizationStore.getTranslatedData("pt_days", "Days");
                    if (_state.periodicity === GraphType.Weekly) {
                        units = LocalizationStore.getTranslatedData("pt_wks", "Wks");
                    }
                    info = ticks + " " + units;
                    if (node.baseStatus === PatternStatusType.MAXEDOUT_PatternStatus) {
                        info += " ("+ LocalizationStore.getTranslatedData("ch_er_ela_view_dlbmax","max") + ")";
                    }
                    node.Title[2] = { title: LocalizationStore.getTranslatedData("pt_length", "Length:"), info: info };
                    let baseScore = this.getValue(node.infos, PatternInfoType.BaseDepth_PatternInfo);
                    info = (baseScore * 100).toFixed(0) + "%";
                    node.Title[3] = { title: LocalizationStore.getTranslatedData("depth", "Depth:"), info: info };
                    if (patternRecognitionSettings.AdvancePattern) {
                        let blueAvg = this.getValue(node.infos, PatternInfoType.BlueBars_PatternInfo);
                        let redAvg = this.getValue(node.infos, PatternInfoType.RedBars_PatternInfo);
                        let redSupport = this.getValue(node.infos, PatternInfoType.SupportBars_PatternInfo);
                        let blueStall = this.getValue(node.infos, PatternInfoType.StallBars_PatternInfo);
                        node.Title[4] = { title: LocalizationStore.getTranslatedData("pt_blueVolAvg", "Blue, Vol>Avg:"), info: blueAvg };
                        node.Title[5] = { title: LocalizationStore.getTranslatedData("pt_redVolAvg", "Red, Vol>Avg:"), info: redAvg };
                        node.Title[6] = { title: LocalizationStore.getTranslatedData("pt_redSupport", "Red Support:"), info: redSupport };
                        node.Title[7] = { title: LocalizationStore.getTranslatedData("pt_blueStall", "Blue Stall:"), info: blueStall };
                        if (_state.periodicity === GraphType.Weekly && node.PatternType != PatternType.HIGHTIGHTFLAG_Pattern) {
                            node.Title[8] = { title: LocalizationStore.getTranslatedData("pt_baseScore", "Base Score:"), info: node.baseScore };
                        }
                    }
                    if (node.baseStatus === PatternStatusType.COMPLETED_PatternStatus && node.pivotDt && patternRecognitionSettings.PowerFromPivot) {
                        this.SetPowerFromPivot(_state.HiLowPoints.allPoints, pivotPriceNode, baseEndIn, priceNode, node.PivotDt, node, _state);
                    }
                    saveKPR.push({ dateKey: node.BaseEndDt, type: 1, node: node });
                }
                prDataList.ConsolidationPatternNode.push(node);
            }
        }

        if (prData.cups != null && patternRecognitionSettings.CupPattern) {
            let lng = prData.cups.length;
            for (let i = lng - 1; i > -1; i--) {
                let node = prData.cups[i];
                if (node == null) continue;
                dateKey = moment(new Date(node.baseEndDt.toNumber())).format("M/D/YYYY");
                theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                node.BaseEndDt = this.SetTimelineDate(new Date(dateKey));
                baseEndIn = this.FindStockIndex(node.BaseEndDt);
                dateKey = moment(new Date(node.baseStartDt.toNumber())).format("M/D/YYYY");
                node.BaseStartDt = this.SetTimelineDate(new Date(dateKey));
                //if (node.BaseStartDt < lastNode.Date) continue;
                var baseStartIn = this.FindStockIndex(node.BaseStartDt);
                var lng2 = _state.HiLowPoints.allPoints.length;
                if (baseStartIn < 0) baseStartIn = lng2 - 1;//this.FindTimeIndex(node.BaseStartDt);;
                dateKey = moment(new Date(node.pivotPriceDt.toNumber())).format("M/D/YYYY");
                node.PivotPriceDt = this.SetTimelineDate(new Date(dateKey));
                if (node.pivotDt) {
                    dateKey = moment(new Date(node.pivotDt.toNumber())).format("M/D/YYYY");
                    node.PivotDt = this.SetTimelineDate(new Date(dateKey));
                }
                else
                    node.PivotDt = priceNode.Date;
                pivotPriceIn = this.FindStockIndex(node.PivotPriceDt);
                dateKey = moment(new Date(node.cupEndDt.toNumber())).format("M/D/YYYY");
                node.CupEndDt = this.SetTimelineDate(new Date(dateKey));
                var cupEndIn = this.FindStockIndex(node.CupEndDt);
                dateKey = moment(new Date(node.baseBottomDt.toNumber())).format("M/D/YYYY");
                node.BaseBottomDt = this.SetTimelineDate(new Date(dateKey));
                if (node.patternType === PatternType.CUP_Pattern) {
                    node.PatternName = "Cup";
                }
                if (node.patternType === PatternType.SAUCER_Pattern) {
                    node.PatternName = "Saucer";
                }
                if (node.patternType === PatternType.CUPHANDLE_Pattern) {
                    node.PatternName = "Cup with Handle";
                }
                if (node.patternType === PatternType.SAUCERHANDLE_Pattern) {
                    node.PatternName = "Saucer with Handle";
                }
                var baseBottomIn = this.FindStockIndex(node.BaseBottomDt);
                var handleBottomIn = -1;
                node.Title = [];
                node.Title.length = 5;
                if (patternRecognitionSettings.AdvancePattern) {
                    node.Title.length = 9;
                    if (_state.periodicity === GraphType.Weekly && node.PatternType != PatternType.HIGHTIGHTFLAG_Pattern)
                        node.Title.length = 10;
                }
                if (node.handleBottomDt) {
                    dateKey = moment(new Date(node.handleBottomDt.toNumber())).format("M/D/YYYY");
                    node.HandleBottomDt = this.SetTimelineDate(new Date(dateKey));
                    handleBottomIn = this.FindStockIndex(node.HandleBottomDt);
                    let handle = this.getValue(node.infos, PatternInfoType.HandleDepth_PatternInfo);
                    var info5 = (handle * 100).toFixed(0) + "%";
                    node.Title[4] = { title: LocalizationStore.getTranslatedData("handle", "Handle:"), info: info5 };
                }

                if (baseEndIn > -1 && baseStartIn > -1 &&
                    baseBottomIn > -1 && cupEndIn > -1 && pivotPriceIn > -1) {
                    pivotPriceNode = _state.HiLowPoints.allPoints[pivotPriceIn];
                    // var pivotTDC = DocUtil.ConvertDateTimeToTdc(pivotPriceNode.graphData.Date);
                    // var currTDC = DocUtil.ConvertDateTimeToTdc(_state.HiLowPoints.allPoints[baseEndIn].Date);
                    // var dfromPivot = currTDC - pivotTDC;
                    daysFromPivot = DateHelper.DaysDiff(pivotPriceNode.graphData.Date, _state.HiLowPoints.allPoints[baseEndIn].Date).Days;
                    let info = node.baseStage + (node.baseNumber > 0 ? " (" + node.baseNumber + ")" : "");
                    node.Title[0] = { title: LocalizationStore.getTranslatedData("stage", "Stage:"), info: info };
                    let price = pivotPriceNode.graphData.High < 1 ? pivotPriceNode.graphData.High.toFixed(2) : ExtremeDataValue.showPrice(pivotPriceNode.graphData.High);
                    info = currency + price;
                    node.Title[1] = { title: LocalizationStore.getTranslatedData("pivot", "Pivot:"), info: info };
                    ticks = node.baseLength;
                    let units = LocalizationStore.getTranslatedData("pt_days", "Days");
                    if (_state.periodicity === GraphType.Weekly) {
                        units = LocalizationStore.getTranslatedData("pt_wks", "Wks");
                    }
                    info = ticks + " " + units;
                    if (node.baseStatus === PatternStatusType.MAXEDOUT_PatternStatus) {
                        info += " ("+ LocalizationStore.getTranslatedData("ch_er_ela_view_dlbmax","max") + ")";
                    }
                    node.Title[2] = { title: LocalizationStore.getTranslatedData("pt_length", "Length:"), info: info };
                    let baseScore = this.getValue(node.infos, PatternInfoType.BaseDepth_PatternInfo);
                    info = (baseScore * 100).toFixed(0) + "%";
                    node.Title[3] = { title: LocalizationStore.getTranslatedData("depth", "Depth:"), info: info };
                    if (patternRecognitionSettings.AdvancePattern) {
                        let blueAvg = this.getValue(node.infos, PatternInfoType.BlueBars_PatternInfo);
                        let redAvg = this.getValue(node.infos, PatternInfoType.RedBars_PatternInfo);
                        let redSupport = this.getValue(node.infos, PatternInfoType.SupportBars_PatternInfo);
                        let blueStall = this.getValue(node.infos, PatternInfoType.StallBars_PatternInfo);
                        node.Title[5] = { title: LocalizationStore.getTranslatedData("pt_blueVolAvg", "Blue, Vol>Avg:"), info: blueAvg };
                        node.Title[6] = { title: LocalizationStore.getTranslatedData("pt_redVolAvg", "Red, Vol>Avg:"), info: redAvg };
                        node.Title[7] = { title: LocalizationStore.getTranslatedData("pt_redSupport", "Red Support:"), info: redSupport };
                        node.Title[8] = { title: LocalizationStore.getTranslatedData("pt_blueStall", "Blue Stall:"), info: blueStall };
                        if (_state.periodicity === GraphType.Weekly && node.PatternType != PatternType.HIGHTIGHTFLAG_Pattern) {
                            node.Title[9] = { title: LocalizationStore.getTranslatedData("pt_baseScore", "Base Score:"), info: node.baseScore };
                        }
                    }
                    stockData = _state.HiLowPoints.allPoints;
                    let minY = 0;

                    for (let mPt = baseEndIn; mPt <= baseStartIn && stockData[mPt]; mPt++) {
                        if (stockData[mPt].yLow > minY)
                            minY = stockData[mPt].yLow;
                    }
                    node.minY = minY;
                    node.endIn = _state.HiLowPoints.allPoints[baseEndIn].xAxis;
                    node.endY = _state.HiLowPoints.allPoints[baseEndIn].yLow;
                    node.startIn = _state.HiLowPoints.allPoints[baseStartIn].xAxis;
                    node.startY = _state.HiLowPoints.allPoints[baseStartIn].yLow;
                    node.startHighY = _state.HiLowPoints.allPoints[baseStartIn].yHigh;
                    node.bottomIn = _state.HiLowPoints.allPoints[baseBottomIn].xAxis;
                    node.bottomY = _state.HiLowPoints.allPoints[baseBottomIn].yLow;
                    node.cupEndIn = _state.HiLowPoints.allPoints[cupEndIn].xAxis;
                    node.cupEndY = _state.HiLowPoints.allPoints[cupEndIn].yLow;
                    node.pivotPriceIn = pivotPriceNode.xAxis;
                    node.pivotPriceY = pivotPriceNode.yHigh;
                    //closeY = _state.scale.ComputeY(priceNode.Close);
                    if (node.baseStatus === PatternStatusType.COMPLETED_PatternStatus && node.pivotDt && patternRecognitionSettings.PowerFromPivot) {
                        this.SetPowerFromPivot(_state.HiLowPoints.allPoints, pivotPriceNode, baseEndIn, priceNode, node.PivotDt, node, _state);
                    }
                    saveKPR.push({ dateKey: node.BaseEndDt, type: 2, node: node });
                    if (handleBottomIn > -1) {
                        node.handleBottomY = _state.HiLowPoints.allPoints[handleBottomIn].yLow;
                        node.handleBottomIn = node.cupEndIn + (_state.HiLowPoints.allPoints[handleBottomIn].xAxis - node.cupEndIn);
                        if (node.handleBottomIn === node.cupEndIn)
                            node.handleBottomIn = node.cupEndIn + 4;
                        if (node.cupEndY > node.handleBottomY)
                            node.handleBottomY = node.cupEndY;
                    }
                    prDataList.CupPatternNode.push(node);
                }
            }
        }

        var firstBottomIn;
        var secondBottomIn;
        if (prData.doubleBottoms != null && patternRecognitionSettings.DoubleBottoms) {
            let lng = prData.doubleBottoms.length;
            for (let i = lng-1; i > -1; i--) {
                let node = prData.doubleBottoms[i];
                dateKey = moment(new Date(node.baseEndDt.toNumber())).format("M/D/YYYY");
                theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                node.BaseEndDt = this.SetTimelineDate(new Date(dateKey));
                baseEndIn = this.FindStockIndex(node.BaseEndDt);
                dateKey = moment(new Date(node.baseStartDt.toNumber())).format("M/D/YYYY");
                node.BaseStartDt = this.SetTimelineDate(new Date(dateKey));
                if (node.BaseStartDt < lastNode.Date) continue;
                baseStartIn = this.FindStockIndex(node.BaseStartDt);
                dateKey = moment(new Date(node.pivotPriceDt.toNumber())).format("M/D/YYYY");
                node.PivotPriceDt = this.SetTimelineDate(new Date(dateKey));
                if (node.pivotDt) {
                    dateKey = moment(new Date(node.pivotDt.toNumber())).format("M/D/YYYY");
                    node.PivotDt = this.SetTimelineDate(new Date(dateKey));
                }
                else
                    node.PivotDt = priceNode.Date;
                pivotPriceIn = this.FindStockIndex(node.PivotPriceDt);
                node.PatternName = "Double Bottom";
                node.Title = [];
                node.Title.length = 4;
                if (patternRecognitionSettings.AdvancePattern) {
                    node.Title.length = 8;
                    if (_state.periodicity === GraphType.Weekly && node.PatternType != PatternType.HIGHTIGHTFLAG_Pattern)
                        node.Title.length = 9;
                }
                if (baseEndIn > -1 && baseStartIn > -1 && pivotPriceIn > -1) {
                    pivotPriceNode = _state.HiLowPoints.allPoints[pivotPriceIn];
                    // var pivotTDC = DocUtil.ConvertDateTimeToTdc(pivotPriceNode.graphData.Date);
                    // var currTDC = DocUtil.ConvertDateTimeToTdc(_state.HiLowPoints.allPoints[baseEndIn].Date);
                    // var dfromPivot = currTDC - pivotTDC;
                    daysFromPivot = DateHelper.DaysDiff(pivotPriceNode.graphData.Date, _state.HiLowPoints.allPoints[baseEndIn].Date).Days;
                    let info = node.baseStage + (node.baseNumber > 1 ? " (" + node.baseNumber + ")" : "");
                    node.Title[0] = { title: LocalizationStore.getTranslatedData("stage", "Stage:"), info: info };
                    let price = pivotPriceNode.graphData.High < 1 ? pivotPriceNode.graphData.High.toFixed(2) : ExtremeDataValue.showPrice(pivotPriceNode.graphData.High);
                    info = currency + price;
                    node.Title[1] = { title: LocalizationStore.getTranslatedData("pivot", "Pivot:"), info: info };
                    ticks = node.baseLength;
                    let units = LocalizationStore.getTranslatedData("pt_days", "Days")
                    if (_state.periodicity === GraphType.Weekly) {
                        units = LocalizationStore.getTranslatedData("pt_wks", "Wks");
                    }
                    info = ticks + " " + units;
                    if (node.baseStatus === PatternStatusType.MAXEDOUT_PatternStatus) {
                        info += " ("+ LocalizationStore.getTranslatedData("ch_er_ela_view_dlbmax","max") + ")";
                    }
                    node.Title[2] = { title: LocalizationStore.getTranslatedData("pt_length", "Length:"), info: info };
                    let baseScore = this.getValue(node.infos, PatternInfoType.BaseDepth_PatternInfo);
                    info = (baseScore * 100).toFixed(0) + "%";
                    node.Title[3] = { title: LocalizationStore.getTranslatedData("depth", "Depth:"), info: info };
                    if (patternRecognitionSettings.AdvancePattern) {
                        let blueAvg = this.getValue(node.infos, PatternInfoType.BlueBars_PatternInfo);
                        let redAvg = this.getValue(node.infos, PatternInfoType.RedBars_PatternInfo);
                        let redSupport = this.getValue(node.infos, PatternInfoType.SupportBars_PatternInfo);
                        let blueStall = this.getValue(node.infos, PatternInfoType.StallBars_PatternInfo);
                        node.Title[4] = { title: LocalizationStore.getTranslatedData("pt_blueVolAvg", "Blue, Vol>Avg:"), info: blueAvg };
                        node.Title[5] = { title: LocalizationStore.getTranslatedData("pt_redVolAvg", "Red, Vol>Avg:"), info: redAvg };
                        node.Title[6] = { title: LocalizationStore.getTranslatedData("pt_redSupport", "Red Support:"), info: redSupport };
                        node.Title[7] = { title: LocalizationStore.getTranslatedData("pt_blueStall", "Blue Stall:"), info: blueStall };
                        if (_state.periodicity === GraphType.Weekly && node.PatternType != PatternType.HIGHTIGHTFLAG_Pattern) {
                            node.Title[8] = { title: LocalizationStore.getTranslatedData("pt_baseScore", "Base Score:"), info: node.baseScore };
                        }
                    }
                    stockData = _state.HiLowPoints.allPoints;
                    let minY = 0;

                    for (let mPt = baseEndIn; mPt <= baseStartIn; mPt++) {
                        if (stockData[mPt].yLow > minY)
                            minY = stockData[mPt].yLow;
                    }
                    node.minY = minY;
                    node.endIn = _state.HiLowPoints.allPoints[baseEndIn].xAxis;
                    node.endY = _state.HiLowPoints.allPoints[baseEndIn].yLow;
                    node.startIn = _state.HiLowPoints.allPoints[baseStartIn].xAxis;
                    node.startY = _state.HiLowPoints.allPoints[baseStartIn].yLow;
                    node.pivotPriceIn = _state.HiLowPoints.allPoints[pivotPriceIn].xAxis;
                    node.pivotPriceY = _state.HiLowPoints.allPoints[pivotPriceIn].yHigh;
                    dateKey = moment(new Date(node.firstBottomDt.toNumber())).format("M/D/YYYY");
                    node.FirstBottomDt = this.SetTimelineDate(new Date(dateKey));
                    firstBottomIn = this.FindStockIndex(node.FirstBottomDt);
                    node.firstBottomIn = _state.HiLowPoints.allPoints[firstBottomIn].xAxis;
                    node.firstBottomY = _state.HiLowPoints.allPoints[firstBottomIn].yLow;
                    dateKey = moment(new Date(node.middlePeakDt.toNumber())).format("M/D/YYYY");
                    node.MiddlePeakDt = this.SetTimelineDate(new Date(dateKey));
                    var middlePeakIn = this.FindStockIndex(node.MiddlePeakDt);
                    node.middlePeakIn = _state.HiLowPoints.allPoints[middlePeakIn].xAxis;
                    node.middlePeakY = _state.HiLowPoints.allPoints[middlePeakIn].yLow;
                    dateKey = moment(new Date(node.secondBottomDt.toNumber())).format("M/D/YYYY");
                    node.SecondBottomDt = this.SetTimelineDate(new Date(dateKey));
                    secondBottomIn = this.FindStockIndex(node.SecondBottomDt);
                    if (secondBottomIn >= 0) {
                        node.secondBottomIn = _state.HiLowPoints.allPoints[secondBottomIn].xAxis;
                        node.secondBottomY = _state.HiLowPoints.allPoints[secondBottomIn].yLow;
                    }
                    if (node.baseStatus === PatternStatusType.COMPLETED_PatternStatus && node.pivotDt && patternRecognitionSettings.PowerFromPivot) {
                        this.SetPowerFromPivot(_state.HiLowPoints.allPoints, pivotPriceNode, baseEndIn, priceNode, node.PivotDt, node, _state);
                    }
                    saveKPR.push({ dateKey: node.BaseEndDt, type: 3, node: node });
                }
                prDataList.DoubleBottomsNode.push(node);
            }
        }
        if (prData.channelLines != null && patternRecognitionSettings.ChannelLines) {
            let lng = prData.channelLines.length;
            for (let i = lng - 1; i > -1; i--) {
                let node = prData.channelLines[i];
                node.Title = [];
                node.Title.length = 0;
                var dateKey = moment(new Date(node.firstPeakDt.toNumber())).format("M/D/YYYY");
                var theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                node.FirstPeakDt = this.SetTimelineDate(new Date(dateKey));
                node.firstPeakIn = this.FindStockIndex(node.FirstPeakDt);
                node.firstPeakDtX = _state.HiLowPoints.allPoints[node.firstPeakIn].xAxis;
                node.firstPeakDtY = _state.HiLowPoints.allPoints[node.firstPeakIn].yHigh;
                dateKey = moment(new Date(node.startDt.toNumber())).format("M/D/YYYY");
                node.StartDt = this.SetTimelineDate(new Date(dateKey));
                node.startIn = this.FindStockIndex(node.StartDt);
                dateKey = moment(new Date(node.secondPeakDt.toNumber())).format("M/D/YYYY");
                node.SecondPeakDt = this.SetTimelineDate(new Date(dateKey));
                node.secondPeakIn = this.FindStockIndex(node.SecondPeakDt);
                node.secondPeakDtX = _state.HiLowPoints.allPoints[node.secondPeakIn].xAxis;
                node.secondPeakDtY = _state.HiLowPoints.allPoints[node.secondPeakIn].yHigh;
                dateKey = moment(new Date(node.thirdPeakDt.toNumber())).format("M/D/YYYY");
                node.ThirdPeakDt = this.SetTimelineDate(new Date(dateKey));
                node.thirdPeakIn = this.FindStockIndex(node.ThirdPeakDt);
                dateKey = moment(new Date(node.endDt.toNumber())).format("M/D/YYYY");
                node.EndDt = this.SetTimelineDate(new Date(dateKey));
                node.endIn = this.FindStockIndex(node.EndDt);
                node.endDateX = _state.HiLowPoints.allPoints[node.endIn].xAxis;
                node.endDateY = node.secondPeakDtY -
                    (node.secondPeakDtX - node.endDateX) * (node.secondPeakDtY - node.firstPeakDtY) /
                    (node.secondPeakDtX - node.firstPeakDtX);
                let minY = 0;

                for (let mPt = node.endIn; mPt <= node.firstPeakIn; mPt++) {
                    if (_state.HiLowPoints.allPoints[mPt].yLow > minY)
                        minY = _state.HiLowPoints.allPoints[mPt].yLow;
                }
                node.minY = minY;
                node.PatternName = "Channel Lines";
                prDataList.ChannelLinesNode.push(node);
            }
        }
        if (prData.climaxTops != null && patternRecognitionSettings.ClimaxTop) {
            let lng = prData.climaxTops.length;
            for (let i = lng - 1; i > -1; i--) {
                let node = prData.climaxTops[i];
                node.Title = [];
                node.Title.length = 4;
                dateKey = moment(new Date(node.topEndDt.toNumber())).format("M/D/YYYY");
                theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                node.TopEndDt = this.SetTimelineDate(new Date(dateKey));
                node.topEndIn = this.FindStockIndex(node.TopEndDt);
                node.PatternName = "Climax Top";
                node.Title[0] = { title: "Length:", info: "" };
                node.Title[1] = { title: "Length:", info: "" };
                node.Title[2] = { title: "Length:", info: "" };
                node.Title[3] = { title: "Length:", info: "" };
                node.flagNode = _state.HiLowPoints.allPoints[node.topEndIn];
                node.flagNode.yAxis = node.flagNode.yHigh-5;
                if (node.flagNode.yAxis < 50) {
                    node.flagNode.yAxis = node.flagNode.yLow+5;
                    node.flagNode.Down = true;
                }
                //node.flagNode.priceChange = node.numChecksPassed;
                prDataList.ClimaxTopsNode.push(node);
            }
        }
        if (prData.ipoBases != null && patternRecognitionSettings.IPOBasePattern) {
            let lng = prData.ipoBases.length;
            for (let i = lng - 1; i > -1; i--) {
                let node = prData.ipoBases[i];
                node.Title = [];
                node.Title.length = 4;
                dateKey = moment(new Date(node.baseEndDt.toNumber())).format("M/D/YYYY");
                theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                node.BaseEndDt = this.SetTimelineDate(new Date(dateKey));
                baseEndIn = this.FindStockIndex(node.BaseEndDt);
                dateKey = moment(new Date(node.baseStartDt.toNumber())).format("M/D/YYYY");
                node.BaseStartDt = this.SetTimelineDate(new Date(dateKey));
                if (node.BaseStartDt < lastNode.Date) continue;
                baseStartIn = this.FindStockIndex(node.BaseStartDt);
                dateKey = moment(new Date(node.pivotPriceDt.toNumber())).format("M/D/YYYY");
                node.PivotPriceDt = this.SetTimelineDate(new Date(dateKey));
                if (node.pivotDt) {
                    dateKey = moment(new Date(node.pivotDt.toNumber())).format("M/D/YYYY");
                    node.PivotDt = this.SetTimelineDate(new Date(dateKey));
                }
                else
                    node.PivotDt = priceNode.Date;
                var pivotPriceIn = this.FindStockIndex(node.PivotPriceDt);
                let pivotPriceNode = _state.HiLowPoints.allPoints[pivotPriceIn];
                if (pivotPriceNode === undefined) {
                    break;
                }
                node.PatternName = "IPO Base";
                let price = pivotPriceNode.graphData.High < 1 ? pivotPriceNode.graphData.High.toFixed(2) : ExtremeDataValue.showPrice(pivotPriceNode.graphData.High);
                let info = currency + price;
                node.Title[0] = { title: LocalizationStore.getTranslatedData("pivot", "Pivot:"), info: info };
                ticks = node.baseLength;
                let units = LocalizationStore.getTranslatedData("pt_days", "Days")
                if (_state.periodicity === GraphType.Weekly) {
                    units = LocalizationStore.getTranslatedData("pt_wks", "Wks");
                }
                info = ticks + " " + units;
                if (node.baseStatus === PatternStatusType.MAXEDOUT_PatternStatus) {
                    info += " ("+ LocalizationStore.getTranslatedData("ch_er_ela_view_dlbmax","max") + ")";
                }
                node.Title[1] = { title: LocalizationStore.getTranslatedData("pt_length", "Length:"), info: info };
                let baseScore = this.getValue(node.infos, PatternInfoType.BaseDepth_PatternInfo);
                info = (baseScore * 100).toFixed(0) + "%";
                node.Title[2] = { title: LocalizationStore.getTranslatedData("depth", "Depth:"), info: info };
                stockData = _state.HiLowPoints.allPoints;
                node.Title[3] = { title: "Length:", info: "" };
                if (baseEndIn > -1 && baseStartIn > -1 && pivotPriceIn > -1) {
                    stockData = _state.HiLowPoints.allPoints;
                    let maxY = 99999;
                    let minY = 0;
                    for (let mPt = baseEndIn; mPt <= baseStartIn; mPt++) {
                        if (stockData[mPt].yLow > minY)
                            minY = stockData[mPt].yLow;
                        if (stockData[mPt].yHigh < maxY)
                            maxY = stockData[mPt].yHigh;
                    }
                    node.minY = minY;
                    node.maxY = maxY;
                    node.endIn = _state.HiLowPoints.allPoints[baseEndIn].xAxis;
                    node.endY = _state.HiLowPoints.allPoints[baseEndIn].yLow;
                    node.startIn = _state.HiLowPoints.allPoints[baseStartIn].xAxis;
                    node.startY = _state.HiLowPoints.allPoints[baseStartIn].yLow;
                    node.pivotPriceIn = _state.HiLowPoints.allPoints[pivotPriceIn].xAxis;
                    node.pivotPriceY = _state.HiLowPoints.allPoints[pivotPriceIn].yHigh;
                    if (node.baseStatus === PatternStatusType.COMPLETED_PatternStatus && node.pivotDt && patternRecognitionSettings.PowerFromPivot) {
                        this.SetPowerFromPivot(_state.HiLowPoints.allPoints, pivotPriceNode, baseEndIn, priceNode, node.PivotDt, node, _state);
                    }
                    saveKPR.push({ dateKey: node.BaseEndDt, type: 4, node: node });
                }
                prDataList.IPOBasePatternNode.push(node);
            }
        }
        if (prData.ascendingBases != null && patternRecognitionSettings.AscendingBasePattern) {
            let lng = prData.ascendingBases.length;
            for (let i = lng - 1; i > -1; i--) {
                let node = prData.ascendingBases[i];
                node.Title = [];
                node.Title.length = 4;
                dateKey = moment(new Date(node.baseEndDt.toNumber())).format("M/D/YYYY");
                theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                node.BaseEndDt = this.SetTimelineDate(new Date(dateKey));
                baseEndIn = this.FindStockIndex(node.BaseEndDt);
                dateKey = moment(new Date(node.baseStartDt.toNumber())).format("M/D/YYYY");
                node.BaseStartDt = this.SetTimelineDate(new Date(dateKey));
                if (node.BaseStartDt < lastNode.Date) continue;
                baseStartIn = this.FindStockIndex(node.BaseStartDt);
                dateKey = moment(new Date(node.pivotPriceDt.toNumber())).format("M/D/YYYY");
                node.PivotPriceDt = this.SetTimelineDate(new Date(dateKey));
                if (node.pivotDt) {
                    dateKey = moment(new Date(node.pivotDt.toNumber())).format("M/D/YYYY");
                    node.PivotDt = this.SetTimelineDate(new Date(dateKey));
                }
                else
                    node.PivotDt = priceNode.Date;
                let pivotPriceIn = this.FindStockIndex(node.PivotPriceDt);
                let pivotPriceNode = _state.HiLowPoints.allPoints[pivotPriceIn];
                node.PatternName = "Ascending Base";
                let info = node.baseStage + (node.baseNumber > 1 ? " (" + node.baseNumber + ")" : "");
                node.Title[0] = { title: LocalizationStore.getTranslatedData("stage", "Stage:"), info: info };
                let price = pivotPriceNode.graphData.High < 1 ? pivotPriceNode.graphData.High.toFixed(2) : ExtremeDataValue.showPrice(pivotPriceNode.graphData.High);
                info = currency + price;
                node.Title[1] = { title: LocalizationStore.getTranslatedData("pivot", "Pivot:"), info: info };
                ticks = node.baseLength;
                let units = LocalizationStore.getTranslatedData("pt_days", "Days")
                if (_state.periodicity === GraphType.Weekly) {
                    units = LocalizationStore.getTranslatedData("pt_wks", "Wks");
                }
                info = ticks + " " + units;
                node.Title[2] = { title: LocalizationStore.getTranslatedData("pt_length", "Length:"), info: info };

                let PullBack1 = (this.getValue(node.infos, PatternInfoType.PullBack1Depth_PatternInfo) * 100).toFixed(0);
                let PullBack2 = (this.getValue(node.infos, PatternInfoType.PullBack2Depth_PatternInfo) * 100).toFixed(0);
                let PullBack3 = (this.getValue(node.infos, PatternInfoType.PullBack3Depth_PatternInfo) * 100).toFixed(0);

                info = PullBack1 + "%," + PullBack2 + "%," + PullBack3 + "% ";
                node.Title[3] = { title: LocalizationStore.getTranslatedData("pullbacks", "Pullbacks 1,2,3:"), info: info };

                if (baseEndIn > -1 && baseStartIn > -1 && pivotPriceIn > -1) {
                    let stockData = _state.HiLowPoints.allPoints;
                    let maxY = 99999;
                    let minY = 0;
                    for (let mPt = baseEndIn; mPt <= baseStartIn; mPt++) {
                        if (stockData[mPt].yLow > minY)
                            minY = stockData[mPt].yLow;
                        if (stockData[mPt].yHigh < maxY)
                            maxY = stockData[mPt].yHigh;
                    }
                    node.minY = minY;
                    node.maxY = maxY;
                    node.endIn = _state.HiLowPoints.allPoints[baseEndIn].xAxis;
                    node.endY = _state.HiLowPoints.allPoints[baseEndIn].yLow;
                    node.startIn = _state.HiLowPoints.allPoints[baseStartIn].xAxis;
                    node.startY = _state.HiLowPoints.allPoints[baseStartIn].yLow;
                    node.pivotPriceIn = _state.HiLowPoints.allPoints[pivotPriceIn].xAxis;
                    node.pivotPriceY = _state.HiLowPoints.allPoints[pivotPriceIn].yHigh;
                    dateKey = moment(new Date(node.firstBottomDt.toNumber())).format("M/D/YYYY");
                    node.FirstBottomDt = this.SetTimelineDate(new Date(dateKey));
                    firstBottomIn = this.FindStockIndex(node.FirstBottomDt);
                    node.firstBottomIn = _state.HiLowPoints.allPoints[firstBottomIn].xAxis;
                    node.firstBottomY = _state.HiLowPoints.allPoints[firstBottomIn].yLow;
                    dateKey = moment(new Date(node.secondAscendingHighDt.toNumber())).format("M/D/YYYY");
                    node.SecondAscendingHighDt = this.SetTimelineDate(new Date(dateKey));
                    var secondAscendingHighIn = this.FindStockIndex(node.SecondAscendingHighDt);
                    node.secondAscendingHighIn = _state.HiLowPoints.allPoints[secondAscendingHighIn].xAxis;
                    node.secondAscendingHighY = _state.HiLowPoints.allPoints[secondAscendingHighIn].yLow;
                    dateKey = moment(new Date(node.secondBottomDt.toNumber())).format("M/D/YYYY");
                    node.SecondBottomDt = this.SetTimelineDate(new Date(dateKey));
                    secondBottomIn = this.FindStockIndex(node.SecondBottomDt);
                    node.secondBottomIn = _state.HiLowPoints.allPoints[secondBottomIn].xAxis;
                    node.secondBottomY = _state.HiLowPoints.allPoints[secondBottomIn].yLow;
                    dateKey = moment(new Date(node.thirdAscendingHighDt.toNumber())).format("M/D/YYYY");
                    node.ThirdAscendingHighDt = this.SetTimelineDate(new Date(dateKey));
                    var thirdAscendingHighIn = this.FindStockIndex(node.ThirdAscendingHighDt);
                    node.thirdAscendingHighIn = _state.HiLowPoints.allPoints[thirdAscendingHighIn].xAxis;
                    node.thirdAscendingHighY = _state.HiLowPoints.allPoints[thirdAscendingHighIn].yLow;
                    dateKey = moment(new Date(node.thirdBottomDt.toNumber())).format("M/D/YYYY");
                    node.ThirdBottomDt = this.SetTimelineDate(new Date(dateKey));
                    var thirdBottomIn = this.FindStockIndex(node.ThirdBottomDt);
                    node.thirdBottomIn = _state.HiLowPoints.allPoints[thirdBottomIn].xAxis;
                    node.thirdBottomY = _state.HiLowPoints.allPoints[thirdBottomIn].yLow;
                    if (node.baseStatus === PatternStatusType.COMPLETED_PatternStatus && node.pivotDt && patternRecognitionSettings.PowerFromPivot) {
                        this.SetPowerFromPivot(_state.HiLowPoints.allPoints, pivotPriceNode, baseEndIn, priceNode, node.PivotDt, node, _state);
                    }
                    saveKPR.push({ dateKey: node.BaseEndDt, type: 5, node: node });
                }
                prDataList.AscendingBasePatternNode.push(node);
            }
        }
        if (prData.tightAreas != null && patternRecognitionSettings.TightAreaPattern) {
            let lng = prData.tightAreas.length;
            for (let i = lng - 1; i > -1; i--) {
                let node = prData.tightAreas[i];
                node.Title = [];
                node.Title.length = 4;
                dateKey = moment(new Date(node.endDt.toNumber())).format("M/D/YYYY");
                theDate = new Date(dateKey);
                if (theDate > endDate) continue;
                if (theDate < lastNode.Date) continue;
                node.EndDt = this.SetTimelineDate(new Date(dateKey));
                var endIn = this.FindStockIndex(node.EndDt);
                dateKey = moment(new Date(node.startDt.toNumber())).format("M/D/YYYY");
                node.StartDt = this.SetTimelineDate(new Date(dateKey));
                node.PatternName = "Tight Area";
                let info = node.length + " " + LocalizationStore.getTranslatedData("pr_wks", "wks");
                node.Title[0] = { title: LocalizationStore.getTranslatedData("pt_length", "Length:"), info: info };
                node.Title[1] = { title: "Length:", info: "" };
                node.Title[2] = { title: "Length:", info: "" };
                node.Title[3] = { title: "Length:", info: "" };
                var startIn = this.FindStockIndex(node.StartDt);
                if (endIn > -1 && startIn > -1) {
                    stockData = _state.HiLowPoints.allPoints;
                    var tolerance = 1.4;
                    let maxY = 99999;
                    let minY = 0;
                    for (let mPt = endIn; mPt <= startIn; mPt++) {
                        if (stockData[mPt].yPrice > minY)
                            minY = stockData[mPt].yPrice;
                        if (stockData[mPt].yPrice < maxY)
                            maxY = stockData[mPt].yPrice;
                    }
                    node.minY = minY + tolerance;
                    node.maxY = maxY - tolerance;
                    node.startIn = _state.HiLowPoints.allPoints[startIn].xAxis;
                    node.endIn = _state.HiLowPoints.allPoints[endIn].xAxis;
                    prDataList.TightAreaPatternNode.push(node);
                }
            }
        }

        //saveKPR.sort(function (a, b) { return (a.dateKey < b.dateKey) ? 1 : ((b.dateKey < a.dateKey) ? -1 : 0); });
        saveKPR.sort((a, b) => (a.dateKey < b.dateKey) ? 1 : ((b.dateKey < a.dateKey) ? -1 : 0));
        let lng = saveKPR.length;
        for (let i = 0; i < lng; i++) {
            let node = {};
            if (saveKPR[i].type === 0) {
                node = prDataList.FlatPatternNode[0];
            }
            if (saveKPR[i].type === 1) {
                node = prDataList.ConsolidationPatternNode[0];
            }
            if (saveKPR[i].type === 2) {
                node = prDataList.CupPatternNode[0];
            }
            if (saveKPR[i].type === 3) {
                node = prDataList.DoubleBottomsNode[0];
            }
            if (saveKPR[i].type === 4) {
                node = prDataList.IPOBasePatternNode[0];
            }
            if (saveKPR[i].type === 5) {
                node = prDataList.AscendingBasePatternNode[0];
            }
            let pivotPriceIn = this.FindStockIndex(node.PivotPriceDt);
            let pivotPriceNode = _state.HiLowPoints.allPoints[pivotPriceIn];
            let closeY = _state.scale.ComputeY(priceNode.Close);
            var baseEndIn = this.FindStockIndex(node.BaseEndDt);
            let currTdc = DocUtil.ConvertDateTimeToTdc(priceNode.Date);
            let pivtTdc = DocUtil.ConvertDateTimeToTdc(node.PivotDt);
            daysFromPivot = currTdc - pivtTdc;
            if (!pivotDisplayed) {
                var priceChange = Math.round(100 * (priceNode.Close - pivotPriceNode.graphData.High) / pivotPriceNode.graphData.High, 0);
                _state.priceChangeValue = priceChange;
                _state.priceChange = (priceChange >= 0 ? '+' : '') + priceChange;
                let vals = _state.HiLowPoints.allPoints.filter((t) => t != null &&
                    t.Date <= priceNode.Date &&
                    t.Date >= node.PivotDt &&
                    t.graphData._volume >= 0);
                ticks = vals.length;
                _state.baseStatus = 0;
                _state.color = undefined;
                if (node.baseStatus !== PatternStatusType.FAILED_PatternStatus && closeY < node.minY && priceChange > -50) {
                    _state.baseStatus = 1;
                    if (node.pivotDt != null) {
                        _state.ticks = ticks;
                        _state.units = _state.periodicity === GraphType.Weekly ? (daysFromPivot < 2 ? LocalizationStore.getTranslatedData("ch_pivot_wky", "Week") : LocalizationStore.getTranslatedData("ch_pivot_wkys", "Weeks")) : (daysFromPivot < 2 ? LocalizationStore.getTranslatedData("ch_pivot_dly", "Day") : LocalizationStore.getTranslatedData("ch_pivot_dlys", "Days"));
                    }
                    else {
                        if (priceNode.High > pivotPriceNode.graphData.High && priceNode.Close > pivotPriceNode.graphData.High)
                            _state.from = true;
                        else {
                            _state.color = "Gray";
                            if (priceNode.High > pivotPriceNode.graphData.High)
                                _state.from = true;
                            else
                                _state.from = false;
                        }
                        _state.ticks = null;
                        _state.units = null;
                    }
                }

                pivotDisplayed = true;

                var c1 = priceNode.Close <= pivotPriceNode.graphData.High * 0.95;
                var c2 = priceNode.Close >= pivotPriceNode.graphData.High * 1.4;
                var c3 = closeY > node.minY;
                var c4 = daysFromPivot > (260 / 2);
                var c5 = node.baseStatus === PatternStatusType.FAILED_PatternStatus;
                var notShow = (c1 || c2 || c3 || c4 || c5);
                if (!notShow && node.minY > 0 && patternRecognitionSettings.showKPR) {
                    this.keyPriceRanges(node, pivotPriceIn, patternRecognitionSettings);
                }
            }
        }

        _state.PRData = prDataList;
        return _state;
    }
}
export default CalcPatternRecognition; 
