
export default class Serializable {
  constructor() {
    let key = this.getObjectMapKey();
    if (!Serializable.Types.hasOwnProperty(key)) {
      Serializable.Types[key] = Object.getPrototypeOf(this);
    }
  }
  onSeralisedInfoRecieved(info) {
    Object.assign(this, info);
  }
  getJsonObject() {
    let dataObject = this.getDataObject();
    dataObject["type"] = this.getObjectMapKey();
    return dataObject;
  }

  getType() {
    return this.getObjectMapKey();
  }
  getObjectMapKey() {
    return "Serializable";
  }

  getDataObject() {
    let dataObject = Object.assign({}, this);
    return dataObject;
  }

  toJSON() {
    return this.getJsonObject();
  }
}

Serializable.Types = {};
