import React, { Component } from "react";
import { Modal, FormGroup, FormControl } from "react-bootstrap";
import AccountStore from "../../Stores/Account/AccountStore";
import LoginActions from "../../Actions/LoginActions.js";
import { LoginConstants } from "../../Constants/LoginConstants.js";
import LocalizationStore from 'LocalizationStore';
import UserInfoUtil from "UserInfoUtil";
import BaseServiceApi from "BaseServiceApi";
const entitlementType = BaseServiceApi.rayData["EntitlementType"];

export default class ChangePassDialog extends Component {
    constructor(props) {
        super(props);
        this.hideDialog = this.hideDialog.bind(this);
        this.getPassChangesForm = this.getPassChangesForm.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.accountStoreStateChanges = this.accountStoreStateChanges.bind(this);
        this.state = {
            currentPasswordErrorVisibility: false,
            newPasswordErrorVisibility: false,
            verifyPasswordErrorVisibility: false,
            currentPasswordErrorText: 'Password not recognized',
            newPasswordErrorText: '',
            verifyPasswordErrorText: 'Does not match',
            success: false,
            passwordLengthInfo: UserInfoUtil.IsUserEntitled(entitlementType.ADVANCED_PASSWORD_SECURITY_Entitlement) ? `12` : `6-20`
        }
    }

    componentDidMount() {
        AccountStore.addChangeListener(this.accountStoreStateChanges);
    }

    componentWillUnmount() {
        AccountStore.removeChangeListener(this.accountStoreStateChanges);
    }

    accountStoreStateChanges() {
        let passChagesState = AccountStore.getPassChangesState();
        let currentAction = AccountStore.getCurrentAction();
        if (currentAction == LoginConstants.ActionTypes.CHANGE_PASSWORD) {
            this.setState({
                success: passChagesState.success,
                currentPasswordErrorVisibility: passChagesState.currentPasswordErrorVisibility,
                newPasswordErrorVisibility: passChagesState.newPasswordErrorVisibility,
                verifyPasswordErrorVisibility: passChagesState.verifyPasswordErrorVisibility,
                currentPasswordErrorText: passChagesState.currentPasswordErrorText,
                newPasswordErrorText: passChagesState.newPasswordErrorText,
                verifyPasswordErrorText: passChagesState.verifyPasswordErrorText
            });
            if (this.state.success) {
                if(this.props.forcePasswordChange && passChagesState.currentPassword!=='') {
                    this.props.saveChangePassDialog(passChagesState.currentPassword);
                }
                this.hideDialog();
            }
        }
    }

    hideDialog() {
        this.setState({
            currentPasswordErrorVisibility: false,
            newPasswordErrorVisibility: false,
            verifyPasswordErrorVisibility: false
        });
        this.props.hideChangePassDialog();
    }

    changePassword() {
        let currentPassword = '';
        let newPassword = this.newPassword.value;
        let verifyPassword = this.verifyPassword.value;
        this.setState({
            currentPasswordErrorVisibility: false,
            newPasswordErrorVisibility: false,
            verifyPasswordErrorVisibility: false
        });
        if(this.props.forcePasswordChange)
            currentPassword= this.props.currentPassword;
        else
            currentPassword= this.currentPassword.value;
        if (currentPassword != '' || this.props.loginErrType === "7") {
            LoginActions.changePassword(currentPassword, newPassword, verifyPassword, this.props.loginErrType);
        }
        else {
            this.setState({
                currentPasswordErrorVisibility: true
            });
        }
    }

    getPassChangesForm() {
        return (
            <form>
                <table>
                    <tbody>
                        {!this.props.forcePasswordChange && <tr>
                            <td>{LocalizationStore.getTranslatedData('set_acc_pw_cp', 'Current Password:')}</td>
                            <td>
                                <FormGroup controlId="currentPassword">
                                    <FormControl type="password" autoComplete="off" inputRef={(ref) => { this.currentPassword = ref; }} />
                                    {this.state.currentPasswordErrorVisibility ?
                                        <span className="error-block">{LocalizationStore.getTranslatedData('set_acc_pw_errnrec', this.state.currentPasswordErrorText)}</span> : <span className="error-block"></span>}
                                </FormGroup>
                            </td>
                        </tr>}
                        <tr>
                            <td>{LocalizationStore.getTranslatedData('set_acc_pw_np', 'New Password:')}</td>
                            <td>
                                <FormGroup controlId="newPassword" >
                                    <FormControl type="password" autoComplete="off" inputRef={(ref) => { this.newPassword = ref; }} />
                                    {this.state.newPasswordErrorVisibility ?
                                        <span className="error-block">{this.state.newPasswordErrorText}</span> : <span className="error-block"></span>}
                                </FormGroup>
                            </td>
                        </tr>
                        <tr>
                            <td>{LocalizationStore.getTranslatedData('set_acc_pw_v', 'Verify:')}</td>
                            <td>
                                <FormGroup controlId="verifyPassword" >
                                    <FormControl type="password" autoComplete="off" inputRef={(ref) => { this.verifyPassword = ref; }} />
                                    {this.state.verifyPasswordErrorVisibility ?
                                        <span className="error-block">{LocalizationStore.getTranslatedData('set_acc_pw_errnmat', this.state.verifyPasswordErrorText)}</span> : <span className="error-block"></span>}
                                </FormGroup>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        );
    }
    render() {
        return (
            <div id="changePassDialog">
                <Modal className="modal-popup change-pass-dialog" show={this.props.open} backdrop="static" onHide={this.hideDialog}>
                    <Modal.Body>
                        {this.props.forcePasswordChange ?
                            <Modal.Header>
                                <Modal.Title className="cap-header">{LocalizationStore.getTranslatedData("Password","PASSWORD")}</Modal.Title>
                            </Modal.Header> :
                            ''}
                        <div className="modal-material">
                            {this.props.forcePasswordChange && this.props.loginErrType !== "7" ? <h5>{LocalizationStore.getTranslatedData("set_acc_pw_expired","Your Password has expired, enter a new Password")}</h5> :  this.props.forcePasswordChange && this.props.loginErrType === "7" ? <h5>{LocalizationStore.getTranslatedData("set_acc_pw_change","CHANGE PASSWORD")}</h5> :  <h5>{LocalizationStore.getTranslatedData('set_acc_pw_lp', 'LOGIN PASSWORD')}</h5>}
                            {this.getPassChangesForm()}
                            <p>
                            {
                                UserInfoUtil.IsUserEntitled(entitlementType.ADVANCED_PASSWORD_SECURITY_Entitlement) ?
                                    LocalizationStore.getTranslatedData('set_acc_pw_msg', `Passwords must be at least {0} characters, and cannot contain a space, single quote, or double quote.  Contact Client Services for Advanced Password Security restrictions, if you are entitled.`, this.state.passwordLengthInfo)
                                :
                                    LocalizationStore.getTranslatedData('set_acc_pw_msg_adv', `Passwords must be {0} characters, and cannot contain a space, single quote, or double quote.  Contact Client Services for Advanced Password Security restrictions, if you are entitled.`, this.state.passwordLengthInfo)
                            }
                            </p>
                            <div className="clearfix"></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box">
                            { this.props.loginErrType !== "7" ? <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.hideDialog}>{LocalizationStore.getTranslatedData('cancel', 'Cancel')}</button> : ""}
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.changePassword}>{LocalizationStore.getTranslatedData('save', 'Save')}</button>
                        </span>
                    </Modal.Footer>

                </Modal>
            </div>
        );
    }
}
