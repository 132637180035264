import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from 'react-redux';
import { closeFileUploadWizard } from '../../Actions/ExternalDataUploaderActions';

class ExternalDataFileError extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        let {fileExceptionDialogShow,fileExceptionMessage} = this.props;
            return (
            <div id="ExternalDataUploadWizard">
                <Modal className="modal-message upload-diversion" show={fileExceptionDialogShow}>
                    <Modal.Body>
                        <div className="inner-block modal-region upload-setting-wrap">
                            <div className="success-failed">
                                <div className="success-failed-box">
                                    <h3>File Upload Failed:</h3>
                                    <ul>
                                        <li>
                                            {fileExceptionMessage}
                                        </li>
                                    </ul>
                                    <p>Please check your file and try again.</p>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default" data-effect="fadeOut" onClick={this.props.closeWizard}>CLOSE</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    closeWizard: () => dispatch(closeFileUploadWizard())
})
const mapStateToProps = ({ externalDataUploader }) => {
    const { fileExceptionDialogShow,fileExceptionMessage } = externalDataUploader.ExternalDataUploaderReducer;
    return ({ fileExceptionDialogShow,fileExceptionMessage });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataFileError);