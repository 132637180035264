import BaseServiceApi from 'BaseServiceApi';

import GetSharedUsersRequest from '../Requests/GetSharedUsersRequest';
import SaveSharedDataRequest from '../Requests/SaveSharedDataRequest';
import GetAllSharedNodesRequest from '../Requests/GetAllSharedNodesRequest';
import GetAllRecipientsShareDataRequest from '../Requests/GetAllRecipientsShareDataRequest';
import GetDetailRecipientStatusRequest from '../Requests/GetDetailRecipientStatusRequest';

export const InsertSharedData = (savedSharedData, nodeIds, nodeType, sharedAction) => {
  const req = new SaveSharedDataRequest(savedSharedData, nodeIds, nodeType, sharedAction);
  return BaseServiceApi.processServiceCall(req);
}

export const GetSharedUsers = (ntid, nids, ia) => {
  const req = new GetSharedUsersRequest(ntid, nids, ia);
  return BaseServiceApi.processServiceCall(req);
}

export const GetAllShareNode = (ntid) => {
  const req = new GetAllSharedNodesRequest(ntid);
  return BaseServiceApi.processServiceCall(req);
}

export const GetAllRecipientsShareData = () => {
  const req = new GetAllRecipientsShareDataRequest();
  return BaseServiceApi.processServiceCall(req);
}

export const GetDetailRecipientStatus = (recipientId) => {
  const req = new GetDetailRecipientStatusRequest(recipientId);
  return BaseServiceApi.processServiceCall(req);
}

