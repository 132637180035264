import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from "react-bootstrap";
import LocalizationStore from "../../../../../Stores/Localization/LocalizationStore.js";

class LiqFilterButton extends PureComponent {
    render() {
        const { liqFilterIcnClaass, onClick } = this.props;
        return (
            <Button bsSize="small" className="xx-small-normal liq-filter-btn" onClick={onClick}>
                <span className={liqFilterIcnClaass}></span>
                <span className="pos-relative">{LocalizationStore.getTranslatedData("CCG_Lqf_1", "LIQUIDITY & RATINGS FILTERS")}</span></Button>
        );
    }
}

LiqFilterButton.propTypes = {
    liqFilterIcnClaass: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}

export default LiqFilterButton;