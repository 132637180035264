import React from "react";
import PortalContainer from 'PortalContainer';
import onClickOutside from "react-onclickoutside";
import ConsoleActions from "Actions/ConsoleActions";
import LocalizationStore from "LocalizationStore";
import ListActions from "../../../../Actions/ListActions";
import ListStore from "../../../../Stores/NavModules/NavList/ListStore";
import ListExplorerStore from "../../../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import { getParentId } from "../../../../Utils/OwnerShipUtil.js";
import { setActiveNodeToOwnershipStore, fetchListCataglogData } from '../../../../Actions/OwnerShipActions';
import ConsoleStore from "../../../../Stores/ConsoleWindow/ConsoleStore";
import BaseServiceApi from 'BaseServiceApi';
import SettingsAction from "SettingsAction";
import UserInfoUtil from "UserInfoUtil";

const ListDatabaseType = BaseServiceApi.rayData["ListDatabaseType"];
const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
import SettingsStore from "SettingsStore";
import ListManagerTabType from "ListManagerTabType";
import MiniListStore from "MiniListStore";

class RecentList extends React.Component {
  constructor(props){
    super(props);
    this.onBatchPrint = this.onBatchPrint.bind(this);
  }

  handleClickOutside() {
    this.props.onHide();
  }

  onBatchPrint() {
    this.handleClickOutside();
    const listName = this.props.listName;
    const settings = SettingsStore.getConsoleSettings();
    const states = ConsoleStore.getStates();
    ConsoleActions.ShowPrintPopup();
    const existingJob = states.PrintJobs.find((job) => job.Symbol == listName);
    if (existingJob && (existingJob.Status == 0 || existingJob.Status == 1)) {
      return;
    }

    const periodicity = settings.NavDatagraphSettings.TabDataGraphSettings.Periodicity;
    ConsoleActions.startPrint([], listName, '', '', 'BATCH', `${listName}-${periodicity}`, this.props.listId, this.props.searchKeyword);
  }

  onListSelect(item) {
    this.props.onHide();
    if (item) {
      const listId = typeof (item.nodeId) === 'object' ? item.nodeId.low : item.nodeId;
      const listName = item.name;
      const categoryType = item.categoryType;
      const parentNodeId = item.parentNodeId ? item.parentNodeId.low : null;
      const explorerState = ListExplorerStore.getState();
      if (explorerState && explorerState.listExplorer) {
        let targetNode = undefined;
        if (item.databaseSourceType === ListDatabaseType.ONEIL_ListDatabase) {
          const explorerData = explorerState.listExplorer.ONeil ? explorerState.listExplorer.ONeil : [];
          const userInfo = UserInfoUtil.getUserInfo();
          const currentUserId = parseInt(userInfo.userId);
          const itemOwnerUserId = item.ownerUserId ? typeof (item.ownerUserId) === 'object' ?  item.ownerUserId.low : item.ownerUserId : -1;
          if (itemOwnerUserId !== -1 && currentUserId !== itemOwnerUserId) {
            const sharedListNode = explorerData.find((item) => item.categoryType === ListCategoryType.SHAREDLIST_ListCategory);
            if (sharedListNode) {
              targetNode = ListExplorerStore.findNodeById(listId, sharedListNode);
            }
          }
          else if (!parentNodeId) {
            let ParentListTreeItem = [];
            ListExplorerStore.findParentNode(listId, ParentListTreeItem, explorerData);
            targetNode = ParentListTreeItem.find((item) => item.childNodes.length == 0);
          }
          else{
            let parentNode = undefined;
            for (let index = 0; index < explorerData.length; index++) {
              const sourceItem = explorerData[index];
              parentNode = ListExplorerStore.findNodeById(parentNodeId, sourceItem);
              if (parentNode) {
                targetNode = ListExplorerStore.findNodeById(listId, parentNode);
                break;
              }
            }
          }
          
          if (targetNode) {
            SettingsAction.updateListExplorerTabSelection('ONEIL');

            ListExplorerStore.activeNode = targetNode;
            
            const value = (ListExplorerStore.activeNode.categoryType == ListCategoryType.USER_ListCategory || ListExplorerStore.activeNode.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory) ? 'MY LISTS' : 'non - MY LISTS';
            ConsoleStore.trackUsage('ListIsSelected', value, [ListExplorerStore.activeNode.nodeId.toString()]);
            ListStore.updateSelectedListItem(listId, listId, listName,
              targetNode.categoryType,
              targetNode.parentNodeId,
              targetNode.parentCategoryType,
              escape(targetNode.parentCategoryName),
              targetNode.parentListType,
              targetNode.sourceCategoryType,
              targetNode.sourceParentCategoryName);
          }
        }
        else if (item.databaseSourceType === ListDatabaseType.OWNERSHIP_ListDatabase) {
          const explorerData = explorerState.listExplorer.Ownership ? explorerState.listExplorer.Ownership : [];
          targetNode = getParentId(listId, explorerData);
          if (targetNode) {
            ListExplorerStore.activeNode = targetNode;
            SettingsAction.updateListExplorerTabSelection('OWNERSHIP'); 
            setActiveNodeToOwnershipStore(targetNode);
            ListStore.updateSelectedListItem(listId, listId, listName,
              targetNode.categoryType,
              targetNode.parentNodeId,
              targetNode.parentCategoryType,
              escape(targetNode.parentCategoryName),
              targetNode.parentListType,
              targetNode.sourceCategoryType,
              targetNode.sourceParentCategoryName);
          }
        }
      }

      if (item.databaseSourceType === ListDatabaseType.ONEIL_ListDatabase) {
        ListActions.fetchListCataglogData(listId, listId, listName, 0, -1, false);
      }
      else if (item.databaseSourceType === ListDatabaseType.OWNERSHIP_ListDatabase) {
        let isUserList = 0;
        if (categoryType === ListCategoryType.USER_ListCategory)
          isUserList = 1;
        fetchListCataglogData(listId, listId, listName, 0, -1, isUserList);
      }
    }
  }
  
  render() {
    const listId = this.props.listId;
    console.log(this.props.listName + listId);

    if (this.props.showRecentList)
      return (
        <PortalContainer>
          <div id="printWrap" className=" print context-menu dropdown dialog-open" >
            <ul className=" context-menu-inner list-contex small-normal ">
              <li className="heading-minlist">{LocalizationStore.getTranslatedData("lp_rl", "RECENT LISTS")}</li>

              <li className="heading-select">
                <a href="javascript:void(0)">
                  <span className="icn-right-icon"></span>
                  <span className="pull-left"><span className="print-list-name">{this.props.listName}</span></span>
                  {this.props.ListCategoryType != ListCategoryType.NOTIMPORTEDLIST_ListCategory && MiniListStore.listItemsLength > 0
                    && <span className="print-action" onClick={() => this.onBatchPrint()}>{LocalizationStore.getTranslatedData("lp_print", "Print")}</span>
                  }
                  {/* <span className="pull-right print-action" onClick={() => this.onBatchPrint()}>{LocalizationStore.getTranslatedData("lp_print", "Print")}</span> */}
                </a>
              </li>
              {this.props.recentLists.map((item, i) =>
                <li key={`recentListNode${i}`} onClick={()=>this.onListSelect(item)}>
                  <a>
                    <span className="recent-list-name">{item.name}</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        </PortalContainer>);
    else
      return null;
  }
}

const RecentListView = onClickOutside(RecentList);

export default RecentListView;