import { AnnotationConstant } from "Constants/AnnotationConstants";
import AppColorStore from "Stores/AppColor/AppColorStore";
import { connect } from 'react-redux';
import { getSelectedAnnotationInfo } from '../../../../../../Reducers/AnnotationReducers/reselectorsdata';
import { RayAnnotationType } from '../../../../../../Constants/EAnnotationType';
const { ActionTypes } = AnnotationConstant;
import ThemeHelper from "ThemeHelper";
import { onOpacityChange, updateTargetAnnotation } from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent } from 'react';

class AnnotationsOpacitySettings extends PureComponent {

    componentDidMount() {
        AppColorStore.addChangeListener(this.colorChanged);
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.colorChanged);
    }

    colorChanged = () => {
        this.forceUpdate();
    }

    onOpacityChange = (value, shouldSave = false) => {
        this.props.onOpacityChange(value, shouldSave);

        if (this.props.annotSelected && (this.props.annotSelected.type === this.props.annotationType || this.props.annotSelected.type === this.props.selectedShapeType)) {
            this.props.updateTargetAnnotation(ActionTypes.HANDLE_OPACITY_CHANGE, value, null, shouldSave);
        }
    }

    hexToRgbA(hex) {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
        }
        throw new Error('Bad Hex');
    }

    sliderStyle() {
        const color = this.hexToRgbA(this.props.annotationType === RayAnnotationType.LINE_AnnotationType || this.props.annotationType === RayAnnotationType.PARALLEL_LINES_AnnotationType ? ThemeHelper.getThemedBrush(this.props.modelInfo.color) : ThemeHelper.getThemedBrush(this.props.modelInfo.bgColor));
        return ({
            background: `-webkit-linear-gradient(left, rgba(${color},0) 1%, rgba(${color},1) 100%)`,
            background: `-moz-linear-gradient(left, rgba(${color},0) 1%, rgba(${color},1) 100%)`,
            background: `linear-gradient(to right, rgba(${color},0) 1%, rgba(${color},1) 100%)`
            // ,
            // WebkitAppearance: "none",
            // borderRadius: "6px",
            // border: "1px solid #b7b7b7",
            // height: "10px"
        })
    }

    render() {
        const { opacity } = this.props.modelInfo;
        return (
            <>
                <div className="heading">Opacity</div>
                <div className="sliding">
                    <input className="sliding-input icn-input-trigger" type="range" min="0" max="1" value={opacity} onChange={(e) => this.onOpacityChange(e.target.value)} step={0.05} style={this.sliderStyle()} onMouseUp={(e) => this.onOpacityChange(e.target.value, true)} />
                    <span className="sliding-percent">{(opacity * 100).toFixed(0)}%</span>
                </div>
            </>
        )
    }
}

AnnotationsOpacitySettings.propTypes = {

};

const mapStateToProps = (state) => ({
    modelInfo: getSelectedAnnotationInfo(state),
    annotationType: state.annotationReducers.annotationMenuReducer.selectedToolType,
    annotSelected: state.annotationReducers.annotationChartModelReducer.annotSelected,
    selectedShapeType: state.annotationReducers.annotationMenuReducer.ShapesModelInfo.selectedType
})


const mapDispatchToProps = (dispatch) => ({
    onOpacityChange: (value, shouldSave) => dispatch(onOpacityChange(value, shouldSave)),
    updateTargetAnnotation: (action, data, computationAction, shouldSave) => updateTargetAnnotation(action, data, computationAction, shouldSave)
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationsOpacitySettings);
