import ConsoleStore from 'ConsoleStore';
import CurrencyHelper from "CurrencyHelper";
import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import DatagraphStore from "../../../Stores/NavModules/NavDataGraph/DataGraphStore";
import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import { HeaderTranslateHelper } from '../../../Utils/TranslateHelper';
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import { PriceChartConstants } from '../../../Constants/PriceChartConstants';
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../Constants/SmartViewType.js";
import store from "../../../Redux/store.js";
import StringUtil from "StringUtil";
import SymbolTypes from "SymbolType";
import { epsDateShow, getDatagraphStates, ipodateShow, stockInfo } from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { takeLatest, put, call, select } from 'redux-saga/effects';

const { ActionTypes } = DataGraphConstants;


function currencyConverseIG(currencyCode) {
  try {
    let currency = "";
    if (currencyCode) {
      const curren = Object.assign({}, { CurrencyCode: currencyCode.substring(currencyCode.length - 3) });
      currency = CurrencyHelper.get(curren);
    }
    const inPence = (currencyCode === "GBP" || currencyCode === "ZAR");
    if (inPence) {
      currency = 'p';
    }
    return currency;
  }
  catch (error) {
    console.log(`Error occurs in HeaderSaga.js, currencyConverseIG ${error}`);
  }
}
function* processHeaderData(action) {
  try {
    //clearing existing headers
    if(!action?.isStreamingProc){
      yield put({
        type: ActionTypes.CLEAR_HEADER_STATE
      });
    }
    const { SymbolInfo, headerData, isHistoric, SymbolType, endDate } = yield select(getDatagraphStates);
    //return if there is no data from API for SymbolInfo
    let hideHeaderText = false;

    // for historic dates of sector and industry group symbols hide the PDR header text
    if (isHistoric && (SymbolInfo.SymTypeEnum === SymbolTypes.WONINDUSTRYGROUP197 || SymbolInfo.SymTypeEnum === SymbolTypes.WONMAJORINDUSTRY89 ||
      SymbolInfo.SymTypeEnum === SymbolTypes.WONSECTOR11 || SymbolInfo.SymTypeEnum === SymbolTypes.WONSUBSECTOR33 || SymbolInfo.SymTypeEnum === SymbolTypes.INDEX
      || SymbolInfo.SymTypeEnum === SymbolTypes.INTERNATIONALINDEX || SymbolInfo.SymTypeEnum === SymbolTypes.INDEXNOINTRADAYVOL || SymbolInfo.SymTypeEnum ===
      SymbolTypes.INDEXNOINTRADAYDATA || SymbolInfo.SymTypeEnum === SymbolTypes.INDEXNOINTRADAYVOLDATA || SymbolInfo.SymTypeEnum === SymbolTypes.INTERNATIONALINDEX)) {
      hideHeaderText = true
    }
    if (!SymbolInfo) {
      return;
    }

    const lastTradeDate = new Date(SymbolInfo.LastTradeDate);

    const rsValue = headerData.rsRank > 0 ? headerData.rsRank : "";
    const SdRank = headerData.sdRank;
    const acdcValue = headerData.AccDisRating;
    const companyName = headerData.companyName && headerData.companyName.trim();
    const symbol = headerData.Symbol;
    const ipoDate = headerData.IPODate ? DateHelper.parseJsonDate(new Date(DateHelper.getUtcFromLong(headerData.IPODate)), SymbolInfo.MomentZoneID) : "";
    const Isin = SymbolType === SmartViewType.STOCK ? SymbolInfo.Isin ? SymbolInfo.Isin : '' : '';
    let ipeDateText = DateHelper.IpoDateFormat(ipoDate);
    const showIpoDateState = yield select(ipodateShow);
    if (!showIpoDateState) {
      ipeDateText = DateHelper.IPOCountDownFormat(ipoDate, endDate);
    }
    const ipo = ipeDateText;
    const exchange = headerData.exchangeCode;
    const excode = headerData.exchangeCode;
    const indSymbol = headerData.indSymbol;
    const industryGroup = SymbolType !== SmartViewType.FUND && SymbolType !== SmartViewType.CURRENCY ? LocalizationStore.getTranslatedData(`Index_Industry_${headerData.indSymbol}`, headerData.IndustryGroupName) : '';

    const groupRank = headerData.IndustryGroupRank ? `Group Rank  ${headerData.IndustryGroupRank}` : "";
    const mktCapUSD = headerData.MarketCap;
    const constituents = (headerData.constituents !== null && headerData.constituents > 0) ? headerData.constituents.toString() : "";
    const sector = headerData.sector ? headerData.sector : "";
    const sectorCode = headerData.sectorCode;
    const yrHigh = headerData.yrHi;
    const yrLow = headerData.yrLo;
    const aVol = (headerData.AvgDailyVol !== null && headerData.AvgDailyVol > 0) ? headerData.AvgDailyVol : 0;

    const exchangeName = headerData.exchangeCode;
    const wonGrouping = headerData.IndustryGroupName;
    const openInterest = SymbolType === SmartViewType.FUTURES ? headerData.openInterest : null;
    const expiryDate = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(headerData.expiryDate), SymbolInfo.MomentZoneID);

    const consoleSettings = SettingsStore.getConsoleSettings();
    const futuresViewSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings.FuturesViewSettings;
    const ExpiryState = futuresViewSettings.expiryDateFormat;

    let mktCap = "", multiplier = '', currency = '';
    const targetCurrencyCode = DatagraphStore.targetCurrencyCode();
    const finTargetCurrencyCode = DatagraphStore.finTargetCurrencyCode(SymbolInfo);
    if (SmartViewType.INDUSTRYGROUP === SymbolType) {
      currency = yield call(currencyConverseIG, headerData.CurrencyISOCode);
      multiplier = 1000000;
      if (headerData.MarketCap && headerData.MarketCap > 0) {
        const abrivatedValue = ExtremeDataValue.abbreviateValue((headerData.MarketCap * multiplier), 2);
        mktCap = `${HeaderTranslateHelper.MARKET_CAP} ${currency} ${abrivatedValue}`;
      }
    } else {
      let toCurr;
      const {
        isMatchFinancials
      } = store.getState().currency;
      const settings = SettingsStore.getConsoleSettings();
      if (StringUtil.isEmpty(targetCurrencyCode)) {
        if (isMatchFinancials && !StringUtil.isEmpty(finTargetCurrencyCode)) {
          toCurr = finTargetCurrencyCode;
        } else {
          const defaultCurrency = !settings.isMatchFinancials && SymbolInfo.WSCurrency ? SymbolInfo.WSCurrency : SymbolInfo.DefaultCurrency.Code ? SymbolInfo.DefaultCurrency.Code : SymbolInfo.DefaultCurrency;
          toCurr = defaultCurrency === "GBP" ? "GBX" : defaultCurrency === "ZAR" ? "ZAX" : defaultCurrency;
        }
      } else {
        toCurr = !settings.isMatchFinancials ? SymbolInfo.WSCurrency || targetCurrencyCode : targetCurrencyCode;
      }
      currency = CurrencyHelper.get({ Code: toCurr }, true);
      multiplier = 1;
      const marketConversionRate = 1.0;
      if (headerData.MarketCap && headerData.MarketCap > 0) {
        const abrivatedValue = ExtremeDataValue.abbreviateValue((headerData.MarketCap * marketConversionRate * multiplier), 2);
        mktCap = `${HeaderTranslateHelper.MARKET_CAP} ${currency}${abrivatedValue}`;
      }
      if (headerData.marketCap2 && headerData.marketCap2 > 0 && headerData.shares2 && headerData.shares2 > 0) {
        mktCap += ` (${currency}${ExtremeDataValue.abbreviateValue(headerData.marketCap2, 2)})`;
      }
    }
    const sales = isNaN(headerData.Sales) || headerData.Sales <= 0.0 ? "" : (SymbolInfo.SymTypeEnum === 25 ? `${HeaderTranslateHelper.REVENUE} ` : `${HeaderTranslateHelper.SALES} `) + currency + ExtremeDataValue.abbreviateValue(headerData.Sales * multiplier, 2);
    const salesValue = isNaN(headerData.Sales) || headerData.Sales <= 0.0 ? -1 : headerData.Sales;
    let shares = '';
    if (headerData.Shares && headerData.Shares > 0) {
      shares = headerData.Shares > 1000000000
        ? ExtremeDataValue.abbreviateValue(headerData.Shares, 3)
        : ExtremeDataValue.abbreviateValue(headerData.Shares);
      shares = `${HeaderTranslateHelper.SHARES} ${shares}`;
    }
    if (headerData.shares2 && headerData.shares2 > 0) {
      const shares2 = headerData.shares2 > 1000000000 ? ExtremeDataValue.abbreviateValue(headerData.shares2, 3) : ExtremeDataValue.abbreviateValue(headerData.shares2);
      if (!shares2.includes(shares.replace(`${HeaderTranslateHelper.SHARES} `, ""))) {
        shares += ` (${shares2})`;
      }
    }
    const floatVal = headerData.floatVal > 1000000000 ? ExtremeDataValue.abbreviateValue(headerData.floatVal, 3) : ExtremeDataValue.abbreviateValue(headerData.floatVal);
    const float = isNaN(headerData.floatVal) || headerData.floatVal < 1 || SymbolType === SmartViewType.CEF || SymbolType === SmartViewType.ETF
      ? ""
      : `${HeaderTranslateHelper.FLOAT} ${floatVal}`;
    const epsDueDate = headerData.EpsDueDate ? DateHelper.parseJsonDate(new Date(DateHelper.getUtcFromLong(headerData.EpsDueDate)), SymbolInfo.MomentZoneID) : "";
    let getDate = new Date();
    if (endDate != null && isHistoric){
      getDate = endDate;
    }    
    const theDate = new Date(getDate.getFullYear(), getDate.getMonth(), getDate.getDate());
    //const currDate = DateHelper.returnTimeZoneDate(theDate, stockInfo.MomentZoneID);
    const data = DateHelper.GetData(theDate, SymbolInfo.SymTypeEnum, epsDueDate);
    const IsWithinTwoWeeks = data.IsWithinTwoWeeks;
    let epsDateText = DateHelper.EpsDueDateFormat(epsDueDate, SymbolInfo.SymTypeEnum);
    const showEPSDateState = yield select(epsDateShow);
    if (!showEPSDateState && epsDueDate !== "") {
      epsDateText = data.DisplayText;
    }
    const epsDue = epsDateText;
    const YrHi = headerData.yrHi;
    const YrLo = headerData.yrLo;
    const oHigh = 0;
    const oLow = 0;
    let infoData;
    if (SymbolInfo) {
      infoData = {
        industry197Symbol: SymbolInfo.Industry197Symbol,
        msid: SymbolInfo.MsId,
        CountryCode: SymbolInfo.CountryCode,
        Osid: SymbolInfo.Osid,
        IndCode: SymbolInfo.IndCode,
        Industry197Name: SymbolInfo.Industry197Name,
        Sector11Symbol: SymbolInfo.Sector11Symbol,
        // CountryISO: SymbolInfo.CountryISO,
        SymTypeEnum: SymbolInfo.SymTypeEnum
      }
    }

    const navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
    const isGICS = navDataGraphSettings.TabDataGraphSettings.StockViewSettings.isGICS;
    yield put({
      type: ActionTypes.SET_HEADER_RESPONSE,
      rsValue, SdRank, acdcValue, companyName, symbol, isHistoric, endDate, Isin, ipo, exchange, excode, indSymbol,
      industryGroup, groupRank, targetCurrencyCode, finTargetCurrencyCode, sales, salesValue, shares, float, epsDueDate, theDate,
      IsWithinTwoWeeks, epsDue, YrHi, YrLo, oHigh, oLow, mktCap, infoData, isGICS, mktCapUSD, constituents, sector, sectorCode,
      yrHigh, yrLow, aVol, lastTradeDate, exchangeName, wonGrouping, openInterest, expiryDate, ExpiryState, ipoDate, hideHeaderText
    })
  }
  catch (error) {
    console.log(`Error occurs in HeaderSaga.js, processHeaderData ${error}`);
  }
}
function* processMetricDef({ data, definationSymbol }) {
  try {
    const metricDefinitions = ConsoleStore.getMetricDefinition();
    const restMetricDefinitions = ConsoleStore.getRestMetricDefinition();
    const definitions = metricDefinitions && metricDefinitions.industryDefinitionData;
    const restDefination = restMetricDefinitions && restMetricDefinitions.itemData;
    const definationMapKeys = metricDefinitions && metricDefinitions.metricMappingData;
    const { headerData } = yield select(getDatagraphStates);
    const indSymbol = headerData.indSymbol;
    let definition = null;
    if (definationSymbol) {
      const mapkey = definationMapKeys && definationMapKeys.find((item) => (item.MappingKey === ("ch_header_" + metricActionAlert.definationSymbol) && item.ParentId === -1))
      definition = restDefination && restDefination.find((item) => item.MsItemID === mapkey.MSItemID);
    }
    else {
      definition = definitions && definitions.find((item) => item.IndustrySymbol === indSymbol);
    }
    yield put({
      type: ActionTypes.VIEW_METRIC_DEF_READY,
      data: data,
      definition: definition
    });
  }
  catch (error) {
    console.log(`Error occurs in HeaderSaga.js, processMetricDef ${error}`);
  }
}
/****************watchers***********************/
export function* watchPopulateHeader() {
  yield takeLatest(ActionTypes.PRICE_PANEL_RESPONSE_READY, processHeaderData);
}
export function* watchPrepareHeader() {
  yield takeLatest(PriceChartConstants.ActionTypes.PREPARE_PDR_DATA, processHeaderData);
}
export function* watchPrepareMetricDef() {
  yield takeLatest(ActionTypes.VIEW_METRIC_DEF, processMetricDef);
}
