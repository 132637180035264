import { connect } from 'react-redux';
import EventUtil from '../../../../Utils/EventUtil.js';
import Measure from "react-measure";
import { onPlotSymbol } from '../../../../Actions/DatagraphActions.js';
import React from "react";
import { RiPanelHoldingViewTranslateHelper } from '../../../../Utils/TranslateHelper.js';
import ScrollBar from "ScrollBar";
import SmartViewType from '../../../../Constants/SmartViewType.js';
import { UpdateTemplisttoExplorer } from '../../../../Actions/ListActions.js';


class RIPanelHoldingsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollHeight: 0,
            width: 0
        }
    }
    ExecViewInListManagerCommand() {
        UpdateTemplisttoExplorer(
            {
                symbol: this.props.SymbolInfo.Symbol,
                msId: this.props.SymbolInfo.Msid,
                view: SmartViewType.HOLDINGS,
                isGlobal: false
            }
        );
    }

    getItemRenderer(item, top, index) {
        if (item) {
            return (
                <div className="holdingsDataList-item" key={index} style={{ top: top }}>
                    <table>
                        <tbody>
                            <tr>
                                <th colSpan="2" className="holding-tag">{item.tag}</th>
                                <th className="holding-amount">{RiPanelHoldingViewTranslateHelper.AMT} {RiPanelHoldingViewTranslateHelper.MIL}</th>
                                <th className="holding-pctOfChange">{RiPanelHoldingViewTranslateHelper.CHG}</th>
                                <th className="holding-pctOfPort">{RiPanelHoldingViewTranslateHelper.OF} {RiPanelHoldingViewTranslateHelper.PORT}</th>
                            </tr>
                            {item.holdingData.map((holding, index) =>
                                <tr key={index}>
                                    <td className="holding-symbol" onClick={() => this.props.onPlotSymbol(holding.symbol)}>{holding.symbol}</td>
                                    <td>
                                        <div className="holding-companyName" onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, holding.companyName)} onFocus={(e) => EventUtil.mouseOverShowTitle(e, holding.companyName)}>{holding.companyName}</div>
                                    </td>
                                    <td className="holding-amount">{holding.amountString}</td>
                                    <td className="holding-pctOfChange">{holding.pctOfChangeString}</td>
                                    <td className="holding-pctOfPort">{holding.pctOfPortString}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )
        }
    }

    setDimensions(dim) {
        this.setState({ scrollHeight: dim.height, width: dim.width });
    }

    render() {
        return (
            <div className="holdingsInfo">
                {this.props.loading ?
                    <div className="news-message large-normal"><p>{RiPanelHoldingViewTranslateHelper.LOADING}</p></div> : this.props.isNoDataAvailable ?
                        <div className="holdings-content">
                            <div className="news-message large-normal"><p>{RiPanelHoldingViewTranslateHelper.NO_DATA_AVAILABLE}</p></div>
                        </div> : <div className="holdings-content">
                            <div className="custom-scroll">
                                <Measure
                                    bounds
                                    onResize={(contentRect) =>
                                        this.setDimensions(contentRect.bounds)
                                    }>
                                    {({ measureRef }) =>
                                        <div className="virtualList showscrollOnHover"
                                            ref={measureRef}
                                            style={{ width: "100%", height: "100%" }}>
                                            <div id={'symbol-RIPanelHoldingsInfo-VerticalScroll'}>
                                                <div style={{ height: this.state.scrollHeight }}>
                                                    {this.props.holdingsDataList.map((item, index) =>
                                                        this.getItemRenderer(item, 160 * index, index)
                                                    )};
                                                    <div className="holdingsDataList-footer medium-normal"
                                                        style={{ top: this.props.holdingsDataList.length * 160 }}>
                                                        <span className="holdings-viewInListManager" onClick={(e) => this.ExecViewInListManagerCommand(e)}>View in List Manager</span>
                                                        <span>{this.props.quarterEnd13FDate}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </Measure>
                                <ScrollBar scrollId={'symbol-RIPanelHoldingsInfo-VerticalScroll'} vScroll={true} scrollOnHover={true} />
                            </div>
                        </div>
                }
            </div>
        )
    }
}
const mapStateToProps = ({ RelatedInfoPanelReducers, DatagraphReducers }) => {
    const { loading, holdingsDataList, quarterEnd13FDate, isNoDataAvailable } = RelatedInfoPanelReducers.RiPanelHoldingsReducer;
    const { SymbolInfo } = DatagraphReducers.DataGraphReducer;
    return { loading, holdingsDataList, quarterEnd13FDate, isNoDataAvailable, SymbolInfo };
}
const mapDispatchToProps = (dispatch) => ({
    onPlotSymbol: (symbol) => dispatch(onPlotSymbol(symbol))
})
export default connect(mapStateToProps, mapDispatchToProps)(RIPanelHoldingsInfo)