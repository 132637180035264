const cellWidth = require("text-width");
import DogEar from "./DogEar.jsx";
import extremeDataValue from "../../../../../Utils/ExtremeDataValue.js";
import LocalizationStore from "LocalizationStore";
import numeral from "numeral";
import PortalContainer from 'PortalContainer';
import PropTypes from 'prop-types';
import StringUtil from "StringUtil";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import UserInfoUtil from "UserInfoUtil";
import React, { Component } from "react";

export default class DataRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: props.row,
      rowHeight: props.rowHeight,
      nodeWidth: props.nodeWidth,
      width: props.width,
      hover: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.width !== undefined) {
      this.setState({ width: nextProps.width });
    }
    if (nextProps.row !== undefined) {
      this.setState({ row: nextProps.row, dogEar: undefined });
    }
  }
  getCellValues() {

    const row = this.state.row;
    if (row === undefined || row === null || this.state === undefined || this.state.width === 0.0) {
      return null;
    }
    const cells = [];
    const width = this.state.width;
    const cellItems = row.cellItems;
    const length = cellItems.length;
    for (let cellIndex = 0; cellIndex < length; cellIndex++) {
      const cellItem = cellItems[cellIndex];
      const xAxis = (width - (cellItem.XPoint * this.state.nodeWidth) - 3);
      if (xAxis < 0) {
        continue;
      }
      const nextCell = cellItems[cellIndex + 1];
      const nextXAxis = nextCell ? (width - (nextCell.XPoint * this.state.nodeWidth)) : width;
      const xpoint = nextXAxis + 1;
      let paddingText = 3;
      const cWidth = (xAxis - nextXAxis) + paddingText;
      if (cellItem.DogEarContent) {
        const CarryOverText = this.CarryOverTextTranslation(cellItem.DogEarContent);
        const isWithIndex = cellIndex + 1 < cellItems.length;
        if (isWithIndex) {
          const cHeight = this.state.rowHeight - 2;
          paddingText = 5;
          cells.push(<DogEar onShowDogEar={(dogEar) => this.showDogEar(dogEar)} height={cHeight} text={CarryOverText} width={cWidth} x={xpoint} key={`dogEar${cellIndex}`} />);
        }
      }
      if (cellItem.Value && cellItem.Value !== "") {
        let textWidth = cellWidth(cellItem.Value, {
          family: "calibri",
          size: 12
        });

        let cellValue = cellItem.Value;

        if (cellItem.header.IsPerc) {
          const Value = cellItem.ActualValue > 0 ? `+${cellValue}%` : `${cellValue}%`;
          textWidth = cellWidth(Value, {
            family: "calibri",
            size: 12
          });
        }

        const textW = Math.round(textWidth);
        if (textW + paddingText >= cWidth) {
          if (cellItem.IsActual) {
            if (cellItem.header.IsPerc) {
              cellValue = `${numeral(cellValue.replace(/%/, "")).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase()}%`;
              cellValue = cellItem.ActualValue > 0 ? `+${cellValue}` : cellValue;
            }
            else {
              if (cellItem.isPE) {
                cellValue = `${extremeDataValue.abbreviateValue(cellItem.LowValue, 0,"",true)}-
                ${extremeDataValue.abbreviateValue(cellItem.HighValue, 0,"",true) }`
              } else {
                cellValue = numeral(cellValue).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase();
              }
            }
          }
          else if (!cellItem.IsActual) {
            if (cellItem.header.IsPerc) {
              cellValue = `${numeral(cellValue.replace(/%/, "")).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase()}%e`;
              cellValue = cellItem.ActualValue > 0 ? `+${cellValue}` : cellValue;
            }
            else {
              if(cellItem.isPE) {
                cellValue = `${extremeDataValue.abbreviateValue(cellItem.LowValue, 0,"",true)}e-
                ${extremeDataValue.abbreviateValue(cellItem.HighValue, 0,"",true) }e`
              }else{
                cellValue = `${numeral(cellValue).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)  }a`,"",true).toUpperCase()  }e`;
              }
            }
          }
        }
        let tempColor = "";
        //IsPositive can be undefined when it indicates normal value.
        if (cellItem.IsPositive) {
          tempColor = ThemeHelper.getThemedBrush("positiveDataText");
        }
        else if (cellItem.IsPositive === false) {
          tempColor = ThemeHelper.getThemedBrush("negativeDataText")
        }

        cells.push(<text key={cellIndex} className={cellItem.className} style={{ textAnchor: "end", pointerEvents: "none", fill: tempColor }} x={xAxis} y={(this.state.rowHeight) - 3}>{cellValue}</text>);
      }

    }
    return cells;
  }

  CarryOverTextTranslation(DogEarContent) {
    if (UserInfoUtil.getCurrentLangCode() === "zh-cn") {
      let carryOverText = "";
      const contents = DogEarContent.split('\n');
      for (let i = 0; i < contents.length; i++) {
        const content = contents[i];
        if (StringUtil.isEmpty(content)) { continue; }
        if (i > 1) {
          carryOverText += `\n \n${this.DogEarContentTranslation(content)}`;
        } else {
          carryOverText += this.DogEarContentTranslation(content);
        }
      }
      return carryOverText;
    } else {
      return DogEarContent;
    }
  }

  DogEarContentTranslation(content) {
    const dogEarContentTranslateObj = {
      ["Pretax non-reoccurring items are included"]: LocalizationStore.getTranslatedData("fb_hoverpretax", content),
      ["Calculation based on a negative number in the latest period or same quarter of previous year"]: LocalizationStore.getTranslatedData("fb_hover", content),
      ["Indicates Semi-Annual Data"]: LocalizationStore.getTranslatedData("fb_indicates_1", content), ["Indicates Annual Data"]:
        LocalizationStore.getTranslatedData("fb_indicates_2", content)
    }
    const dogEarContent = dogEarContentTranslateObj[content] ? dogEarContentTranslateObj[content] : content;
    return dogEarContent;
  }

  getDecimalPlaces(decimal) {
    let returnValue = "0.";
    for (let index = 0; index < decimal; index++) {
      returnValue += "0";
    }
    return returnValue;
  }

  onHover(hover) {
    this.setState({ hover: hover });
  }

  syncHover(index, hover) {
    this.props.syncHover(index, hover);
  }
  reDraw() {
    this.setState({});
  }
  showDogEar(dogEarObj) {
    this.setState({ dogEar: dogEarObj });
  }

  getDogEarContent() {
    const deContentText = this.state.dogEar.text.length < 30 ? "deContent deContent-Singleline" : "deContent";
    return (
      <PortalContainer>
        <div className="dogEarMain financial-dogear" style={{ left: window.event.clientX, bottom: window.innerHeight - window.event.clientY }}>
          <div className="deBorder">
            <div className={deContentText}>
              {this.state.dogEar.text}
            </div>
          </div>
        </div>
      </PortalContainer>
    )
    // return (<div className="dogEarMain" style={{ left: this.state.dogEar.x }}>
    //   <div className="deBorder">
    //     <div className="deContent">
    //       {this.state.dogEar.text}
    //     </div>
    //   </div>
    // </div>);
  }

  render() {
    const cells = this.getCellValues();
    TimeTrackingWindow.trackChartLoadingTime();
    const clsHover = this.state.hover ? "financial-block-row hover-on-row borderBottom" : "financial-block-row ";
    return (
      <div className="financial-block-row"
        style={{ height: this.state.rowHeight }}
        onMouseOver={() => this.syncHover(this.state.row.index, true)}
        onFocus={() => this.syncHover(this.state.row.index, true)}
        onMouseLeave={() => this.syncHover(this.state.row.index, false)}>
        <div className={clsHover} style={{ height: this.state.rowHeight + 1 }}></div>
        <svg className="financial-block-row-item" id="row">{cells}</svg>
        {this.state.dogEar && this.getDogEarContent()}
      </div>
    );
  }
}
DataRow.propTypes = {
  row: PropTypes.object.isRequired,
  rowHeight: PropTypes.number.isRequired,
  nodeWidth: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  syncHover: PropTypes.func.isRequired
};