import AppDispatcher from "AppDispatcher";
import { GridConstants } from "GridConstants";

class SummaryStatActionsClass {

  setHighlightedRowAndColumn(rowIndex, columnId, highlight) {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.SET_SUMMARYSTATE_ROW_COL_HIGHLIGHTED,
      data: { rowIndex: rowIndex, columnId: columnId, highlight: highlight }
    });
  }

}

const SummaryStatActions = new SummaryStatActionsClass();
export default SummaryStatActions;
