import React from 'react';
import LocalizationStore from 'LocalizationStore';
import { ShareActionConstants } from "../../../Constants/ShareConstants";
import SortHeader from "./SortHeader.jsx";
import { updateStorewithSharedData, setToolTipFlag } from "../../../Actions/ShareAction";
import RecipientData from "./RecipientData.jsx";
import ScrollBar from "ScrollBar";
import { Table } from "react-bootstrap";
import PortalContainer from "PortalContainer";
import ShareStore from "../../../Stores/Share/ShareStore.js";
import RowData from './RowData.jsx'
import ExternalData from './ExternalData';


const ManagedData = ({ managedData, sharedAction, sort, sortReversed, showInProgress }) => {   

    const compareNameString =
        sharedAction === ShareActionConstants.SHARE_RECIPIENTS
            ?
            sortReversed
                ? (a, b) => String(a.recipientName).localeCompare(b.recipientName)
                : (a, b) => String(b.recipientName).localeCompare(a.recipientName)
            :
            sortReversed
                ? (a, b) => String(a.name).localeCompare(b.name)
                : (a, b) => String(b.name).localeCompare(a.name)


    const compareNumString = sortReversed
        ? (a, b) => a.numPeople - b.numPeople
        : (a, b) => b.numPeople - a.numPeople

    const compareSumString = sortReversed
        ? (a, b) => (a.numLists + a.numColSets + a.numAnnoLayers) - (b.numLists + b.numColSets + b.numAnnoLayers)
        : (a, b) => (b.numLists + b.numColSets + b.numAnnoLayers) - (a.numLists + a.numColSets + a.numAnnoLayers)

    const sorts = {
        name: compareNameString,
        numPeople: compareNumString,
        recipientName: compareNameString,
        totalNodes: compareSumString,
    }

    const sortedData = managedData && [...managedData].sort(sorts[sort])

    const firstHeader =
        {
            [ShareActionConstants.SHARE_LIST]: LocalizationStore.getTranslatedData("set_shar_ln", 'List Name'),
            [ShareActionConstants.SHARE_COLUMN_SET]: LocalizationStore.getTranslatedData("set_shar_cn", 'Column Set Name'),
            [ShareActionConstants.SHARE_ANNOTATIONS]: LocalizationStore.getTranslatedData("set_shar_an", 'Annotation Name'),
            [ShareActionConstants.SHARE_RECIPIENTS]: LocalizationStore.getTranslatedData("set_shar_n", 'Name'),
            [ShareActionConstants.SHARE_EXTERNAL_DATA]: 'Data Set Name'
        }[sharedAction]

    const headers = [
        {
            sortName:
                sharedAction === ShareActionConstants.SHARE_RECIPIENTS
                    ? 'recipientName'
                    : 'name',
            displayName: firstHeader
        },
        {
            sortName:
                sharedAction === ShareActionConstants.SHARE_RECIPIENTS
                    ? 'totalNodes'
                    : 'numPeople',
            displayName:
                sharedAction === ShareActionConstants.SHARE_RECIPIENTS
                    ? LocalizationStore.getTranslatedData("set_shar_si", 'Shared Items')
                    : LocalizationStore.getTranslatedData("set_shar_sw", 'Shared With')
        }
    ]
    const showSort = sharedAction === ShareActionConstants.SHARE_EXTERNAL_DATA ? false : true;
    return (
        <div className="share-grid-holder">
            <div className="manage-share-scoller">
                <div className="share-table-scroll custom-scroll-light" data-test-id="manage-data">
                    <div className="custom-scroll">
                        <div className="share-scroller" id="shareVerticalScroll">
                            <Table className="share-manage-grid">
                                <thead>
                                    <tr>
                                        {
                                            headers.map((header, index) =>
                                                <th key={index}>
                                                    <SortHeader
                                                        sortName={header.sortName}
                                                        displayName={header.displayName}
                                                        currentSortName={sort}
                                                        isSortReversed={sortReversed}
                                                        showSort={showSort}
                                                    />
                                                </th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                {sortedData && sortedData.length > 0 &&
                                    <tbody>
                                        {
                                            showInProgress
                                            ?
                                                <tr>
                                                    <td>
                                                        <div className="loading loading-spinner"></div>
                                                    </td>
                                                </tr>
                                            :
                                                sharedAction === ShareActionConstants.SHARE_RECIPIENTS
                                                ?
                                                sortedData.map((rowData, index) =>
                                                    <RecipientData
                                                        rowData={rowData}
                                                        index={index}
                                                        sharedAction={sharedAction}
                                                        key={index}
                                                    />
                                                )
                                                :
                                                sharedAction === ShareActionConstants.SHARE_EXTERNAL_DATA
                                                ? <ExternalData
                                                    data={sortedData}
                                                    sharedAction={sharedAction}/>
                                                :
                                                sortedData.map((rowData, index) =>
                                                    <RowData 
                                                    key={index}                                                    
                                                    rowData={rowData}                                                    
                                                    sharedAction={sharedAction}/>                                                    
                                                )
                                        }
                                    </tbody>
                                }
                            </Table>
                        </div>
                    </div>
                    <ScrollBar scrollId="shareVerticalScroll" vScroll={true} />
                </div>
            </div>
        </div>
    );
}

export default ManagedData;