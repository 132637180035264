import GetOwnershipReportHeaderRequest from  'ServiceApi/Requests/GetOwnershipReportHeaderRequest'
import GetOwnershipReportRequestExt from 'ServiceApi/Requests/GetOwnershipReportRequestExt'
import BaseServiceApi from 'BaseServiceApi';


class OwnerShipApi {
    getHeaderData(osid, ownertype, currentTab, currentSubTab, showIndex) {     
         const  request = new GetOwnershipReportHeaderRequest(osid, ownertype, currentTab, currentSubTab, showIndex);       
         return BaseServiceApi.processServiceCall(request);
      }
    
       
     getReportExtdata(osid, ownerType, orderString, showIndex, seqNumber, PageSize, isTopOwner, isByOwner, resultCount) { 
        const req = new GetOwnershipReportRequestExt(osid, ownerType, orderString, showIndex, seqNumber, PageSize, isTopOwner, isByOwner, resultCount);
        return BaseServiceApi.processServiceCall(req);
      }
  
      
}

var OwnershipApi = new OwnerShipApi();

export default OwnershipApi;