import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'

class ScaleButton extends PureComponent {
    handleOnClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { posScale, onClick } = this.props;
        if(onClick) onClick(posScale);
    }

    render() {
        const { value, posScale } = this.props;
        let classes = posScale === "left" ? "link " : "link ";
        //classes += posScale === "left" ? "pull-left" : "pull-right";
        return (
            <span className={classes} onClick={this.handleOnClick}>{value}</span>
        );
    }
}

ScaleButton.propTypes = {
    value: PropTypes.string.isRequired,
    posScale: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

export default ScaleButton;