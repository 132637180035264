import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ThemeHelper from "ThemeHelper";

class LabelText extends PureComponent {
    render() {
        let { textValue, textPosX, textPosY, textAnchor, dx, dy, isHighlighted, style } = this.props;
        let textColor = ThemeHelper.getThemedBrush("scaleText");
        if (this.props.style === undefined) {
            style = { font: 'calibri', fontSize: '12px', fill: textColor };
        }
        let multiText = " ";
        if (this.props.multiLine !== undefined) {
            multiText = textValue.split(" ");
            if (multiText.length > 1 && textValue.length * 8 < this.props.multiLine) {
                multiText[0] = textValue;
                multiText[1] = undefined;
            }
        }
        if (this.props.multiLine === undefined) {
            return (
                <text
                    textAnchor={textAnchor ? textAnchor : "start"}
                    fillOpacity={isHighlighted ? "1" : "0.3"}
                    style={style}
                    dx={dx}
                    dy={dy}
                    x={textPosX}
                    y={textPosY}>{textValue}
                </text>
            );
        } else {
            let offset = 0;
            if (multiText.length > 1 && multiText[1] !== undefined) {
                offset = 10;
            }
            return (
                <text
                    textAnchor={textAnchor ? textAnchor : "start"}
                    fillOpacity={isHighlighted ? "1" : "0.3"}
                    style={style}
                    x={textPosX}
                    y={textPosY}>
                    <tspan x={dx} dy={dy - offset}>{multiText[0]}</tspan>
                    <tspan x={dx} dy={dy + 14}>{multiText[1]}</tspan>
                </text>
            );
        }
    }
}

LabelText.propTypes = {
    textValue: PropTypes.string.isRequired,
    textPosX: PropTypes.number.isRequired,
    textPosY: PropTypes.number.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    textAnchor: PropTypes.string,
    style: PropTypes.object,
    multiLine: PropTypes.number
}

export default LabelText;
