import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetColumnSetItemsRequest extends BaseServiceRequest {
    constructor(consoleId, listId, columnSetId, isScreensch, isScreenSchEmbed) {
        super();
        super.addRequestUri("columnsetitemget.pb");

        super.addRequestParameter("c", consoleId);
        super.addRequestParameter("l", listId.toString());

        super.addRequestParameter("cid", columnSetId.toString());

        super.addRequestParameter("isscreensch", isScreensch.toString());
        super.addRequestParameter("isscreenschembed", isScreenSchEmbed.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default GetColumnSetItemsRequest;
