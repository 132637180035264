import React, { PureComponent } from "react";
import { connect } from 'react-redux';
import LocalizationStore from 'LocalizationStore';
import CompareTabType from "CompareTabType";

class ListCount extends PureComponent {


  render() {
    const { count, total } = this.props;
    return (
      <span className="page-count">{LocalizationStore.getTranslatedData("CCG_Showing", "{0} of {1}", count, total)}</span>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const symbolsMenu = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
  const compChartListItem = state.comparisonGraph.compChartListItem;
  const compareChartViewModel = state.comparisonGraph.compareChartViewModel;
  const count = selectedTab == CompareTabType.ComparisonGraph ? compChartListItem.SymbolsData.filter((c) => !c.isFilter && c.label !=="").length : symbolsMenu.RotationMenuStates.listItems.length;
  const total = selectedTab == CompareTabType.ComparisonGraph ? (compareChartViewModel.SymbolsData.symbolRequest.length + compareChartViewModel.SymbolsData.indexRequest.length) : symbolsMenu.RotationMenuStates.stockList.length;

  return { count, total };
};

export default connect(mapStateToProps)(ListCount);

