import {CompareMenuType} from "CompareTabType";


export const getSymbolPanel = (state) => state.comparisonAndRotationMenuPanel.SymbolsPanelReducer
export const getMenuTab = (state) => state.comparisonAndRotationMenuPanel.MenuTabReducer

export const getComparisonGraphState = (state) => state.comparisonGraph;
export const getCompChartListItem = (state) => state.comparisonGraph.compChartListItem.selectedMenuTab == CompareMenuType.Symbols ?
                                            state.comparisonGraph.compChartListItem.SymbolsData:state.comparisonGraph.compChartListItem.GroupsData
export const getCompareChart = (state) => state.comparisonGraph.compareChart.selectedMenuTab == CompareMenuType.Symbols ?
                                          state.comparisonGraph.compareChart.SymbolsData : state.comparisonGraph.compareChart.GroupsData
export const getCompareMenu = (state) => state.comparisonGraph.compareMenu.selectedMenuTab == CompareMenuType.Symbols ?
                                         state.comparisonGraph.compareMenu.SymbolsData : state.comparisonGraph.compareMenu.GroupsData
export const getCompareChartViewModel = (state) => state.comparisonGraph.compareChartViewModel.selectedMenuTab == CompareMenuType.Symbols ?
                                                    state.comparisonGraph.compareChartViewModel.SymbolsData : 
                                                    state.comparisonGraph.compareChartViewModel.GroupsData
export const getCalendarData = (state) => state.comparisonGraph.calendarControl.selectedMenuTab == CompareMenuType.Symbols ?
                                          state.comparisonGraph.calendarControl.SymbolsData:state.comparisonGraph.calendarControl.GroupsData;
export const getSortType = (state) => getCompareMenu(state).sortType;
export const getDuration = (state) => getCalendarData(state).duration;
export const getPeriodicity = (state) => getCalendarData(state).periodicity;
export const getStartDate = (state) => getCalendarData(state).startDate;
export const getEndDate = (state) => getCalendarData(state).endDate;
export const getNodeWidth = (state) => state.comparisonGraph.compareChartViewModel.nodeWidth;
export const getNumOfChartNodes = (state) => getCompareChartViewModel(state).numOfChartNodes;
export const getChartMinPrice = (state) => getCompareChartViewModel(state).chartMinPrice;
export const getChartMaxPrice = (state) => getCompareChartViewModel(state).chartMaxPrice;

export const getScaleType = (state) => getCompareChartViewModel(state).scaleType;
export const getScale = (state) => getScaleType(state) === "Lin" ? getCompareChartViewModel(state).priceScale : 
                                                                getCompareChartViewModel(state).priceLogScale;
export const getChartHeight = (state) =>  state.comparisonGraph.compareChartViewModel.chartHeight;
export const getChartWidth = (state) =>  state.comparisonGraph.compareChartViewModel.chartWidth;
export const getLiqFilterData = (state) => state.comparisonGraph.liqFilter;