import BaseServiceApi from 'BaseServiceApi';
import StringUtil from 'StringUtil';
import UserInfoUtil from 'UserInfoUtil';
import { isEmpty, clone } from "underscore";
import ListExplorerStore from "ListExplorerStore";
import ListStore from "ListStore";
import PanarayDefault from "./PanarayDefault";
import ConsoleStore from 'ConsoleStore';
import moment from "moment";
import SettingsStore from "SettingsStore";
import ListManagerTabType from "ListManagerTabType";

class BasicEsInfo {
    constructor() {
        const esbasicinfoProto = BaseServiceApi.rayData["EsBasicListInfoData"];
        this.esbasicinfo = new esbasicinfoProto();
        this.EntitlementType = BaseServiceApi.rayData["EntitlementType"];
        this.DeviceType = BaseServiceApi.rayData["DeviceType"];
        this.TemporaryListType = BaseServiceApi.rayData["TemporaryListType"];
        this.ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
        this.ColumnFilterVersionType = BaseServiceApi.rayData["ColumnFilterVersionType"];
        this.esbasicinfoEncoded = null;
        this.setDefaultValue();
    }

    getActiveNode() {
        const activeNodeData = ListStore.getSelectedListItem();
        return {
            nodeId: activeNodeData.SelectedListId,
            sourceNodeId: activeNodeData.SelectedActualListId,
            name: activeNodeData.SelectedListName,
            categoryType: activeNodeData.SelectedCategoryType,
            parentCategoryName: activeNodeData.SelectedParentCategoryName,
            parentCategoryType: activeNodeData.SelectedParentCategoryType,
            parentListType: activeNodeData.SelectedParentListType,
            parentNodeId: activeNodeData.SelectedParentNodeId,
            sourceCategoryType: activeNodeData.SelectedSourceCategoryType
        }

    }
    setDefaultValue() {

        this.esbasicinfo.infoisModelList = false;
        this.esbasicinfo.isMorgueList = false;
        this.esbasicinfo.isPortfolioList = false;
        this.esbasicinfo.isOneilSel = true;
        this.esbasicinfo.isGicsSel = false;
        this.esbasicinfo.isBrowserCount = false;

        this.esbasicinfo.browserFilter = "";
        this.esbasicinfo.oneilBrowserFilter = "";
        this.esbasicinfo.gicsBrowserFilter = "";

        this.esbasicinfo.userId = 0;
        this.esbasicinfo.listId = 0;
        this.esbasicinfo.actualListId = 0;

        this.esbasicinfo.consoleId = "0";
        this.esbasicinfo.sortParam = "";
        this.esbasicinfo.pageSize = 250;
        this.esbasicinfo.pageIndex = 0;
        this.esbasicinfo.colSetId = 0;
        this.esbasicinfo.SearchTxt = "";

        this.esbasicinfo.colVerType = this.ColumnFilterVersionType.MvM;

        this.esbasicinfo.devType = 5;

        this.esbasicinfo.spId = 0;
        this.esbasicinfo.needColInfo = false;
        this.esbasicinfo.needColFilter = false;

        this.esbasicinfo.saveColFilter = false;
        this.esbasicinfo.isMiniList = false;
        this.esbasicinfo.isNupView = false;

        this.esbasicinfo.rtSource = 0;
        this.esbasicinfo.saveOrdNum = false;
        this.esbasicinfo.includeColSet = false;
        this.esbasicinfo.useColFilter = false;
        this.esbasicinfo.msid = 0;
        this.esbasicinfo.listCataReqData = null;
        this.esbasicinfo.isUserList = false;
        this.esbasicinfo.isSnapshotList = false;
        this.esbasicinfo.domMorgueId = 0;
        this.esbasicinfo.intMorgueId = 0;
        this.esbasicinfo.igSymbol = "";
        this.esbasicinfo.isFuturesList = false;
        this.esbasicinfo.langEntId = 0;
        this.esbasicinfo.cacheId = 0;
        this.esbasicinfo.isFlaggedList = false;
        this.esbasicinfo.isFactorList = false;
        this.esbasicinfo.msidSortList = "";
        this.esbasicinfo.isTempList = false;
        this.esbasicinfo.isApXflList = false;
        this.esbasicinfo.isAnnotationList = false;
        this.esbasicinfo.isTempFundType = false;
        this.esbasicinfo.isRotation = false;
        this.esbasicinfo.isComparison = false;
        this.esbasicinfo.isNotImportedList = false;
        this.esbasicinfo.userTimezone = moment.tz.guess();
    }

    // listId":-100012,"actualListId":-100012,"searchText":"","sortCols":"","initialNumRows":90000,"needColumnsInfo":1,"needBrowserCounts":0,"browserFilterText":"-1;-1;-1;","addlCatalogRequests":null,"useColFilters":1,"saveColFilters":0,"customerOrder":0,"colsetId":"-1","igOneilbrowserFilters":"-1;-1;-1;","igGicsbrowserFilters":"-1;-1;-1;-1;","isOneilselected":1,"isGicsselected":0,"isNupview":0}

    setBasicEsInfoFromListCatalogData = (listCatalog) => {
        this.setDefaultValue();
        if (isEmpty(ListExplorerStore.activeNode)) {
            ListExplorerStore.activeNode = this.getActiveNode();
        }
        const nodeDetails = this.getActiveNode();
        let listId = nodeDetails.nodeId, actualListId = nodeDetails.sourceNodeId;
        if (nodeDetails.categoryType === this.ListCategoryType.FAVORITE_ListCategory) {
            listId = nodeDetails.sourceNodeId, actualListId = nodeDetails.nodeId;
        }
        const settings = SettingsStore.getConsoleSettings();
        const navListManagerSettings = settings.NavListManagerSettings;
        if (navListManagerSettings.SelectedTabKey == ListManagerTabType.External && navListManagerSettings.SelectedTabKeyMiniList == ListManagerTabType.Oneil) {
            listId = navListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedListId;
            actualListId = navListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedActualListId;
        }else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.External && navListManagerSettings.SelectedTabKeyMiniList == ListManagerTabType.Ownership) {
            listId = navListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedListId;
            actualListId = navListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedActualListId;
        }
        const { searchText, sortCols, needColumnsInfo, useColFilters, saveColFilters, customerOrder, addlCatalogRequests, colsetId, needBrowserCounts, isOneilselected, isGicsselected, browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, pageIndex, pageSize, msId, fundId, indGrpCode, isExport, cacheId, tmpListType } = listCatalog;

        let info = this.setBasicEsInfo(listId, actualListId, searchText, sortCols, pageIndex, pageSize, Boolean(needColumnsInfo), Boolean(useColFilters), Boolean(saveColFilters), Boolean(customerOrder), addlCatalogRequests, colsetId, Boolean(needBrowserCounts), Boolean(isOneilselected), Boolean(isGicsselected), browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, cacheId, indGrpCode, msId, fundId, isExport, tmpListType);
        return info;
    }

    // setBasicEsInfo(listId, actualListId,searchText, sortCols, pageIndex, pageSize, needColumnsInfo, useColFilters,saveColFilters, customerOrder, addlCatalogRequests, colsetId, needBrowserCounts, isOneilselected, isGicsselected, browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, indGrpCode = "", msid=null, fundId = null, isExport = false, tmpListType = this.TemporaryListType.IGCONSTITUENTS_TEMPORARYLIST) {

    //     this.esbasicinfo.devType = Number(this.DeviceType.DEVICE_WEB);
    //     this.esbasicinfo.actualListId = Number(actualListId);
    //     this.esbasicinfo.listId = Number(listId);
    //     this.esbasicinfo.userId = Number(UserInfoUtil.getUserInfo().userId);//(UserController.GetInstance().GetUserInfo().UserId);
    //     this.esbasicinfoEncoded = this.esbasicinfo.encode();

    // }





    setBasicEsInfo(listId, actualListId, searchText, sortCols, pageIndex, pageSize, needColumnsInfo, useColFilters, saveColFilters, customerOrder, addlCatalogRequests, colsetId, needBrowserCounts, isOneilselected, isGicsselected, browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, cacheId, indGrpCode = "", msid = null, fundId = null, isExport = false, tmpListType = this.TemporaryListType.IGCONSTITUENTS_TEMPORARYLIST) {
        this.esbasicinfo.devType = Number(this.DeviceType.DEVICE_WEB);
        this.esbasicinfo.actualListId = Number(actualListId);
        this.esbasicinfo.listId = Number(listId);
        this.esbasicinfo.rtSource = ConsoleStore.getState() ? ConsoleStore.getState().SelectQuoteServiceOption : 0;
        //this.esbasicinfo.rtSource = WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.IntradaySourceType;
        this.esbasicinfo.isUserList = ListExplorerStore.isUserListOrFavoriteUserList();//---> IsUserListOrFavoriteUserList;
        this.esbasicinfo.SearchTxt = "";
        if (!StringUtil.isEmpty(searchText) && searchText != '') {
            this.esbasicinfo.SearchTxt = searchText.trim();
        }
        this.esbasicinfo.sortParam = "";
        if (sortCols != null && sortCols.length > 0) {
            let sortParams = "";
            /*let sortColumnData = sortCols.map(kvp => new { DataItemId = kvp.Key, kvp.Value.SortOrdinal, kvp.Value.SortDirection }).sortBy(c => c.SortOrdinal);
            sortParams = sortColumnData.reduce(sortParams, (current, columnData) => current + (columnData.DataItemId + "," + (columnData.SortDirection == ListMgrSortDirectionEnum.Descending ? 0 : 1) + ";"));*/
            for (let i = 0, length = sortCols.length; i < length; i++) {
                let asc = sortCols[i].sortAsc ? 1 : 0;
                sortParams += sortCols[i].dataItemId + ',' + asc + ';';
            }

            this.esbasicinfo.sortParam = sortParams;
        }
        this.esbasicinfo.isMiniList = false; //Hard Coded
        this.esbasicinfo.isNupView = false; //Hard Coded
        if (cacheId != -1) {
            this.esbasicinfo.cacheId = cacheId;

            //this.esbasicinfo.SetCacheId(_es_cacheId);
        }
        this.esbasicinfo.isApList = ListExplorerStore.isApList();
        this.esbasicinfo.isTempList = ListExplorerStore.isTempList();//States.CurrentActiveListTreeItem.ListCategory == ListMgrListCategoryEnum.Temp;
        this.esbasicinfo.isApXflList = ListExplorerStore.isApList() || ListExplorerStore.isXflList();
        this.esbasicinfo.isAnnotationList = ListExplorerStore.isAnnoationList();
        if (ListExplorerStore.activeNode && ListExplorerStore.activeNode.categoryType == this.ListCategoryType.FAVORITE_ListCategory && this.esbasicinfo.isApXflList == false) {
            this.esbasicinfo.isApXflList = ListExplorerStore.activeNode.sourceCategoryType == this.ListCategoryType.APLIST_ListCategory;
            // var item = TreeItems.FirstOrDefault(d => d.NodeId == States.CurrentActiveListTreeItem.ListContentId);
            // if (item != null)
            // {
            this.esbasicinfo.isApXflList = ListExplorerStore.isApList() || ListExplorerStore.isXflList();
            // }
        }
        if (ListExplorerStore.activeNode && ListExplorerStore.activeNode.categoryType == this.ListCategoryType.FAVORITE_ListCategory && this.esbasicinfo.isAnnotationList == false) {
            // var item = TreeItems.FirstOrDefault(d => d.NodeId == States.CurrentActiveListTreeItem.ListContentId);
            // if (item != null)
            // {

            // if(ListExplorerStore.activeNode.sourceCategoryType && ListExplorerStore.activeNode.sourceCategoryType === ListCategoryType.ANN)
            this.esbasicinfo.isAnnotationList = ListExplorerStore.isAnnoationList();
            //}
        }
        this.esbasicinfo.pageIndex = pageIndex;
        if (isExport && pageSize > 10000) {
            pageSize = 10000;
        }
        // if (States.CurrentListMgrView == ViewType.NupView)
        // {
        //     pageSize = 100000;
        // }

        this.esbasicinfo.pageSize = pageSize;
        this.esbasicinfo.needColInfo = needColumnsInfo;
        this.esbasicinfo.isModelList = ListExplorerStore.isModelList();// States.IsModelList;

        this.esbasicinfo.isMorgueList = ListExplorerStore.isMorgueList();//States.IsMorgueList;
        this.esbasicinfo.domMorgueId = PanarayDefault.DomesticMorgueListId ? PanarayDefault.DomesticMorgueListId : null;//779464;//PanarayDefaults.GetInstance.DomesticMorgueListId;
        this.esbasicinfo.intMorgueId = PanarayDefault.IntlMorgueListId ? PanarayDefault.IntlMorgueListId : null;//779465;//PanarayDefaults.GetInstance.IntlMorgueListId;
        this.esbasicinfo.isFuturesList = ListExplorerStore.isFutureListorFavoriteUserList();//IsFutureListorFavoriteUserList;
        this.esbasicinfo.isFlaggedList = ListExplorerStore.isFlaggedList();//IsFlaggedList;
        this.esbasicinfo.isFactorList = ListExplorerStore.isFactorList();   //isFactorList;
        this.esbasicinfo.isNotImportedList = ListExplorerStore.isNotImportedList(); //isNotImportedList;
        this.esbasicinfo.isBrowserCount = needBrowserCounts;
        this.esbasicinfo.useColFilter = useColFilters;
        this.esbasicinfo.saveColFilter = saveColFilters;

        this.esbasicinfo.isOneilSel = isOneilselected;
        this.esbasicinfo.isGicsSel = isGicsselected;
        this.esbasicinfo.isPortfolioList = ListExplorerStore.isPortfolioList() || !StringUtil.isEmpty(ListExplorerStore.activeNode) && (ListExplorerStore.activeNode.sourceCategoryType == this.ListCategoryType.PORTFOLIOLIST_ListCategory || ListExplorerStore.activeNode.sourceCategoryType == this.ListCategoryType.POSITIONHISTORYLIST_ListCategory);
        this.esbasicinfo.needColFilter = true;
        this.esbasicinfo.saveOrdNum = customerOrder;
        this.esbasicinfo.colVerType = Number(this.ColumnFilterVersionType.MvM);
        this.esbasicinfo.isSnapshotList = ListExplorerStore.isSnapshotList();
        if (colsetId != -1) {
            this.esbasicinfo.colSetId = Number(colsetId);
        }
        if (addlCatalogRequests != null) {
            this.esbasicinfo.listCataReqData = addlCatalogRequests;
        }
        if (!StringUtil.isEmpty(browserFilterText)) {
            this.esbasicinfo.browserFilter = browserFilterText;
        }
        if (!StringUtil.isEmpty(igOneilbrowserFilters)) {
            this.esbasicinfo.oneilBrowserFilter = igOneilbrowserFilters;
        }
        if (!StringUtil.isEmpty(igGicsbrowserFilters)) {
            this.esbasicinfo.gicsBrowserFilter = igGicsbrowserFilters;
        }

        if (!StringUtil.isEmpty(indGrpCode)) {
            this.esbasicinfo.igSymbol = indGrpCode;
        }
        if (!StringUtil.isEmpty(msid)) {
            this.esbasicinfo.msid = Number(msid); //(msid.Value);
        }
        if (!StringUtil.isEmpty(fundId)) {
            this.esbasicinfo.spId = Number(fundId);//fundId.Value;
        }
        if (tmpListType == this.TemporaryListType.FUNDHOLDINGS_TEMPORARYLIST) {
            this.esbasicinfo.isTempFundType = true;
        }
        this.esbasicinfo.userId = Number(UserInfoUtil.getUserInfo().userId);//(UserController.GetInstance().GetUserInfo().UserId);

        if (UserInfoUtil.IsUserEntitled(this.EntitlementType.RAY_CHINA_Entitlement)) {
            this.esbasicinfo.langEntId = Number(this.EntitlementType.RAY_CHINA_Entitlement);
        }
        return this.esbasicinfo;
        // this.esbasicinfoEncoded = this.esbasicinfo.encode();
    }


    getBasicEsInfoforCopy(listId, actualListId, colsetId, isOneilselected, isGicsselected, browserFilterText, igOneilbrowserFilters, igGicsbrowserFilters, destItem, isColumnFilterEnabled) {
        this.esbasicinfo.actualListId = Number(actualListId);
        this.esbasicinfo.listId = Number(listId);
        let esbasicinfo = clone(this.esbasicinfo);
        esbasicinfo.devType = Number(this.DeviceType.DEVICE_WEB);
        this.esbasicinfo.rtSource = ConsoleStore.getState() ? ConsoleStore.getState().SelectQuoteServiceOption : 0;
        this.esbasicinfo.isUserList = ListExplorerStore.isUserListOrFavoriteUserList(destItem);
        esbasicinfo.isUserList=this.esbasicinfo.isUserList;
        esbasicinfo.needColInfo = true;
        esbasicinfo.needColFilter = true;
        esbasicinfo.pageIndex = 0;
        esbasicinfo.pageSize = 10000;
        if (listId === 1601 || listId === 1602 || ListExplorerStore.isFactorList()) {
            esbasicinfo.pageSize = 999999
          }
        esbasicinfo.isFlaggedList = ListExplorerStore.isFlaggedList(destItem);
        esbasicinfo.useColFilter = isColumnFilterEnabled;
        esbasicinfo.isOneilSel = isOneilselected;
        esbasicinfo.isGicsSel = isGicsselected;
        this.esbasicinfo.isPortfolioList = ListExplorerStore.isPortfolioList() || !StringUtil.isEmpty(ListExplorerStore.activeNode) && (ListExplorerStore.activeNode.sourceCategoryType == this.ListCategoryType.PORTFOLIOLIST_ListCategory || ListExplorerStore.activeNode.sourceCategoryType == this.ListCategoryType.POSITIONHISTORYLIST_ListCategory);
        this.esbasicinfo.colVerType = Number(this.ColumnFilterVersionType.MvM);
        esbasicinfo.colSetId = 0;
        if (colsetId != -1) {
            esbasicinfo.colSetId = parseInt(colsetId);
        }

        if (!StringUtil.isEmpty(browserFilterText)) {
            esbasicinfo.browserFilter = browserFilterText;
        }
        if (!StringUtil.isEmpty(igOneilbrowserFilters)) {
            esbasicinfo.oneilBrowserFilter = igOneilbrowserFilters;
        }
        if (!StringUtil.isEmpty(igGicsbrowserFilters)) {
            esbasicinfo.gicsBrowserFilter = igGicsbrowserFilters;
        }

        this.esbasicinfo.userId = Number(UserInfoUtil.getUserInfo().userId);//(UserController.GetInstance().GetUserInfo().UserId);

        if (UserInfoUtil.IsUserEntitled(this.EntitlementType.RAY_CHINA_Entitlement)) {
            this.esbasicinfo.langEntId = Number(this.EntitlementType.RAY_CHINA_Entitlement);
        }

        return esbasicinfo;
    }

}

const basicEsInfo = new BasicEsInfo();
export default basicEsInfo
