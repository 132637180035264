import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetFilterListValidationRequest extends BaseServiceRequest {
    constructor(lists) {
        super();
        super.addRequestUri("filterlistvalidation.pb");
        super.addRequestParameter("lists", lists);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.NEWS;
    }

    getMessageName() {
        return "NewsFilterListsValidationResponse";
    }
}


export default GetFilterListValidationRequest;
