import GraphComponent from "../../../../Constants/GraphComponent";
import IndustryTypeConstants from "../../../../Constants/IndustryTypeConstants";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import RelativeStrenghtLineSettings from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelativeStrengthLineSettings.js";
import { IndexNameTranslateHelper } from "../../../../Utils/TranslateHelper";

const { ActionTypes } = PriceChartConstants;

const dialogIntialState = {
    rsLineNum: 1,
    selectedRSLine: GraphComponent.RSLine1,
    regionCountries: {},
    countries: [],
    selectedCountry: '',
    CountryCode: 1,
    IndexName: IndexNameTranslateHelper[IndustryTypeConstants.S_P_500_Index],
    regions: [],
    selectedRegion: '',
    countryIndex: '0S&P5',
    industryGroups: [],
    selectedGroup: '',
    symbolName: '',
    valueType: 'default',
    isRSLineDialogOpen: false,
    country: '',
    showRank: true,
    showGrade: true,
    showMALine: false,
    maLineType: 'SMA',
    isFund: false,
    lineColor: {
        RS: "P34FF33006600",
        MA: "P34FF33006600"
    },
    lineThickness: {
        RS: 1,
        MA: 1
    },
    maLineLength: 20,
    hasLengthError: false,
    hasSymbolError: false,
    isSymbolGroup: false,
    isSymbolIndex: false,
    errorMessage: "Error: Only Numbers Allowed",
}
const initialState = {
    rsLine1Settings: new RelativeStrenghtLineSettings(1, false),
    rsLine2Settings: new RelativeStrenghtLineSettings(2, false),
    groups: [],
    rs1LineData: [],
    rs2LineData: [],
    ...dialogIntialState
}
const RSLineReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.RS1_LINE_DATA_READY:
            return {
                ...state,
                errorMessage: action.RS1LineSettings.errorMessage,
                groups: action.RS1LineSettings.groups,
                isRSLine1DialogOpen: action.RS1LineSettings.isRSLine1DialogOpen,
                periodicity: action.RS1LineSettings.periodicity,
                rsLine1Settings: action.RS1LineSettings.rs1SettingsByPeriodicity,
                rsLineData: action.RS1LineSettings.rsLineData,
                rsLineMAData: action.RS1LineSettings.rsLineMAData,
                scale: action.RS1LineSettings.scale,
                rs1LineData: action.RS1LineSettings.rsLineData,
                rs1MALineData: action.RS1LineSettings.rsLineMAData,
                rs1Text: action.RS1LineSettings.rsText,
                rs1MAText: action.RS1LineSettings.rsMAText,
                chartHeight: action.RS1LineSettings.chartHeight
            };
        case ActionTypes.RS2_LINE_DATA_READY:
            return {
                ...state,
                errorMessage: action.RS2LineSettings.errorMessage,
                groups: action.RS2LineSettings.groups,
                isRSLine2DialogOpen: action.RS2LineSettings.isRSLine2DialogOpen,
                periodicity: action.RS2LineSettings.periodicity,
                rsLine2Settings: action.RS2LineSettings.rs2SettingsByPeriodicity,
                rsLineData: action.RS2LineSettings.rsLineData,
                rsLineMAData: action.RS2LineSettings.rsLineMAData,
                scale: action.RS2LineSettings.scale,
                rs2LineData: action.RS2LineSettings.rsLineData,
                rs2MALineData: action.RS2LineSettings.rsLineMAData,
                rs2Text: action.RS2LineSettings.rsText,
                rs2MAText: action.RS2LineSettings.rsMAText,
                chartHeight: action.RS2LineSettings.chartHeight
            };
        case ActionTypes.RS1_LINE_SETTINGS_UPDATE:{
            const rs1Settings = action.rs1SettingsByPeriodicity;
            return {
                ...state,
                rsLine1Settings: { ...state.rsLine1Settings, ...rs1Settings }
            };
        }
        case ActionTypes.RS2_LINE_SETTINGS_UPDATE:{
            const rs2Settings = action.rs2SettingsByPeriodicity;
            return {
                ...state,
                rsLine2Settings: { ...state.rsLine2Settings, ...rs2Settings }
            };
        }
        case ActionTypes.CLEAR_RS1_LINE_DATA:
            return {
                ...state,
                rs1LineData: [],
                rsLine1Settings: new RelativeStrenghtLineSettings(1, false)
            };
        case ActionTypes.CLEAR_RS2_LINE_DATA:
            return {
                ...state,
                rs2LineData: [],
                rsLine2Settings: new RelativeStrenghtLineSettings(2, false)
            };
        case ActionTypes.UPDATE_RSLINE_DIALOG_STATES:
            state.isRSLineDialogOpen = action.isRSLineDialogOpen;
            state.regionCountries = action.regionCountries;
            state.regions = action.regions;
            state.selectedRegion = action.selectedRegion;
            state.countries = action.countries;
            state.selectedCountry = action.selectedCountry;
            state.selectedRSLine = action.selectedRSLine;
            state.countryIndex = action.countryIndex;
            state.lineColor.RS = action.lineColor;
            state.lineThickness.RS = action.lineThickness;
            state.lineColor.MA = action.maLineColor;
            state.maLineLength= action.maLineLength;
            state.lineThickness.MA = action.maLineThickness;
            state.maLineType = action.maLineType;
            state.rsLineNum = action.rsLineNum;
            state.selectedGroup = action.selectedGroup;
            state.showGrade = action.showGrade;
            state.showMALine = action.showMaLine;
            state.showRank = action.showRank;
            state.symbolName = action.symbolName;
            state.valueType = action.valueType;
            state.isSymbolGroup = action.isSymbolGroup;
            state.isSymbolIndex = action.isSymbolIndex;
            state.isFund = action.isFund;
            state.IndexName = action.IndexName;
            state.CountryCode = action.CountryCode;
            return { ...state };
        case ActionTypes.CANCEL_RS_DIALOG_SETTING:
            return{ ...state, ...dialogIntialState};
        case ActionTypes.HANDLE_RS_DIALOG_REGION_CHANGE:
            state.selectedRegion = action.selectedRegion;
            state.countries = state.regionCountries[state.selectedRegion]
            state.selectedCountry = state.countries[0].name;
            state.IndexName = state.countries[0].indexName;
            state.CountryCode = state.countries[0].countryCode;
            state.valueType = "default";
            state.hasSymbolError = false;
            state.symbolName = '';
            state.countryIndex = state.countries[0].indexSymbol;
            return{ ...state};
        case ActionTypes.HANDLE_RS_DIALOG_COUNTRY_CHANGE:
            state.selectedCountry = action.selectedCountry;
            state.valueType = "default";
            state.hasSymbolError = false;
            state.symbolName = '';
            state.regionCountries[state.selectedRegion].map((item)=>{
                if(item.name === state.selectedCountry){
                    state.countryIndex = item.indexSymbol;
                    state.IndexName = item.indexName;
                    state.CountryCode = item.countryCode;
                    return -1;
                }
                return item;
            })
            return{ ...state};
        case ActionTypes.HANDLE_RS_DIALOG_GROUP_CHANGE:
            state.selectedGroup = action.selectedGroup
            return { ...state };
        case ActionTypes.HANDLE_RS_VALUE_TYPE_CHANGE:
            state.symbolName = '';
            state.valueType = action.valueType;
            if(action.valueType !=='symbol')
            {
                state.hasSymbolError = false;
            }
            return { ...state };
        case ActionTypes.HANDLE_RS_SYMBOL_CHANGE:
            state.symbolName = action.symbolName;
            return { ...state };
        case ActionTypes.TOGGLE_SHOW_RS_RANK:
            state.hasSymbolError = false;
            state.showRank = !state.showRank
            return { ...state };
        case ActionTypes.TOGGLE_SHOW_RS_GRADE:
            state.hasSymbolError = false;
            state.showGrade = !state.showGrade
            return { ...state };
        case ActionTypes.TOGGLE_SHOW_RS_MOVING_AVERAGE:
            state.hasSymbolError = false;
            state.showMALine = !state.showMALine
            return { ...state };
        case ActionTypes.TOGGLE_RS_MOVING_AVERAGE_LINE_TYPE:
            state.hasSymbolError = false;
            state.maLineType = action.maLineType
            return { ...state };
        case ActionTypes.UPDATE_RS_LINE_COLOR:
            state.lineColor[action.lineType] = action.lineColor
            state.hasSymbolError = false;
            return { ...state };
        case ActionTypes.UPDATE_RS_LINE_THICKNESS:
            state.hasSymbolError = false;
            state.lineThickness[action.lineType] = action.lineThickness
            return { ...state };
        case ActionTypes.UPDATE_RS_LENGTH:
            state.hasLengthError = false;
            state.maLineLength = action.maLineLength
            return { ...state };
        case ActionTypes.UPDATE_RS_LENGTH_ERROR:
            state.hasLengthError = true;
            state.errorMessage = action.errorMessage;
            return { ...state };
        case ActionTypes.UPDATE_RS_SYMBOL_ERROR:
            state.hasSymbolError = true;
            state.errorMessage = action.errorMessage;
            state.symbolName = '';
            return { ...state };
        case ActionTypes.UPDATE_SYMBOL_ERROR:
            state.hasSymbolError = false;
            return { ...state };
        default:
            return state
    }
}
export default RSLineReducer;