import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
const { ActionTypes } = RiPanelConstants;
import { SettingsConstants } from "../../../Constants/SettingsConstants.js";
import SettingsStore from "../../../Stores/ConsoleWindow/Settings/SettingsStore";

const initialState = {
    ManagementData: [],
    loading: true,
    Initialized: undefined,
    isFundViewEntitled: false,
    IsDetailPage: false,
    IsRelativeYTD: true,
    JumpDetailPage: false,
    activeIndex: -1,
    ManagerDetailData: [],
    loadingDetailPage: true,
    symbolInfo: {},
    ManagementRelatedFundList: [],
    ManagementSettings: {}
}

const RiPanelManagementInfo = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.MANAGEMENT_INFO_SET_DATA: {
            return { ...state, ...action.newState }
        }

        case ActionTypes.MANAGEMENT_INFO_SET_LIST_DATA: {
            state.ManagementSettings.IsDetailPage = action.IsDetailPage;
            SettingsStore.saveSettings();
            return { ...state, ...action.newState }
        }

        case ActionTypes.MANAGEMENT_INFO_SET_YTD_STATUS: {
            state.ManagementSettings.IsRelativeYTD = action.isRelativeYTD;
            SettingsStore.saveSettings();
            return { ...state,
                IsRelativeYTD: action.IsRelativeYTD
             }
        }

        case ActionTypes.MANAGEMENT_INFO_SET_ACTIVE_INDEX: {
            state.activeIndex = action.activeIndex;
            return { ...state }
        }

        case ActionTypes.MANAGEMENT_INFO_DETAIL_PAGE: {
            state.IsDetailPage = action.isDetail;
            SettingsStore.saveSettings();
            return { ...state }
        }

        case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT: {
            return { ...initialState }
        }

        default: return state
    }
}

export default RiPanelManagementInfo