import AnnotationUtil from 'Utils/AnnotationUtil';
import GraphicPoints from '../../../../Models/AnnotationModels/GraphicsPoints';
import { PrintMode } from '../../../../print/printmode';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import { safe } from '../../../ErrorModel';
import { ShareAccessType } from '../../../../Constants/ShareConstants';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { AnnotationConstant, TemporaryId } from "Constants/AnnotationConstants.js";
import {
    getAnnotationIsDrawing, getAnnotationSelectedId, getEllipse, getAnnotationMenu,
    getTargetLayer, getAnnotationChartDraw, getActiveAnchor, getLayersList, getAnnotationDimension, getAnnotationSelected
} from '../../../../Reducers/AnnotationReducers/selectors';
import { takeLatest, select, put, call, takeEvery } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;


/********************** Model Logic *************************/

function* handleClick({ e }) {
    try {
        let ellipse = null, points = null;
        const annotationMenu = yield select(getAnnotationMenu);
        let isDrawing = yield select(getAnnotationIsDrawing);
        let annotSelectedId = yield select(getAnnotationSelectedId);
        const ellipses = yield select(getEllipse);
        const targetLayer = yield select(getTargetLayer);

        if (isDrawing) {
            isDrawing = !isDrawing
            ellipse = ellipses.find((itm) => itm.id === annotSelectedId);
            let dir = '';
            if (ellipse.x < ellipse.endMouseX && ellipse.y > ellipse.endMouseY) {
                dir = 'NE';
            } else if (ellipse.x > ellipse.endMouseX && ellipse.y < ellipse.endMouseY) {
                dir = 'SW';
            } else if (ellipse.x > ellipse.endMouseX && ellipse.y > ellipse.endMouseY) {
                dir = 'NW';
            } else {
                dir = 'SE';
            }
            ellipse = Object.assign(ellipse, {
                direction: dir
            })

            yield call(updateEllipse, isDrawing, ellipse);
            yield safe(call(saveEllipse), "GraphicsEllipse.js", "saveEllipse");
            return;
        }

        if (e.target !== e.target.getStage()) {
            annotSelectedId = e.target.id();
            ellipse = ellipses.find((itm) => itm.id === annotSelectedId);
            yield call(updateEllipse, isDrawing, ellipse);
            return;
        }

        points = new GraphicPoints();
        const annotId = TemporaryId.Id;
        ellipse = {
            ...annotationMenu.ShapesModelInfo,
            id: annotId,
            layerID: targetLayer.layerID,
            type: RayAnnotationType.ELLIPSE_AnnotationType,
            x: e.evt.layerX,
            y: e.evt.layerY,
            points: points,
            width: 0,
            height: 0,
            isEditable: false,
            endMouseX: 0,
            endMouseY: 0,
            isReadOnlyAnnotation: false
        }
        isDrawing = true;

        yield call(addEllipse, isDrawing, ellipse);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsEllipse.js, handleClick ${error}`);
    }
}

function* handleMouseMove({ e }) {
    try {
        let ellipse = null, newWidth = 0, newHeight = 0;
        const ellipses = yield select(getEllipse);
        const isDrawing = yield select(getAnnotationIsDrawing);
        const annotSelectedId = yield select(getAnnotationSelectedId);

        if (isDrawing && ellipses.length > 0) {
            const ellipShape = ellipses.find((itm) => itm.id === annotSelectedId);
            newWidth = e.evt.layerX - ellipShape.x;
            newHeight = e.evt.layerY - ellipShape.y;
            let points = ellipShape.points;
            points = AnnotationUtil.getAnchorPoints(Math.abs(newWidth), Math.abs(newHeight), RayAnnotationType.ELLIPSE_AnnotationType, points);

            ellipse = Object.assign(ellipShape, {
                x: ellipShape.x,
                y: ellipShape.y,
                points: points,
                width: Math.abs(newWidth),
                height: Math.abs(newHeight),
                endMouseX: e.evt.layerX,
                endMouseY: e.evt.layerY,
            });
        }
        yield call(updateEllipse, isDrawing, ellipse);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsEllipse.js, handleMouseMove ${error}`);
    }
}

function* addEllipse(isDrawing, ellipse) {
    try {
        yield put({
            type: ActionTypes.HANDLE_ELLIPSE_CLICK_SUCCESS,
            ellipse: ellipse,
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: ellipse
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsEllipse.js, addEllipse ${error}`);
    }
}

function* updateEllipse(isDrawing, ellipse) {
    try {
        yield put({
            type: ActionTypes.HANDLE_ELLIPSE_DRAW_SUCCESS,
            ellipse: ellipse,
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: ellipse
        })

        let layersList = yield select(getLayersList);
        layersList = JSON.parse(JSON.stringify(layersList));
        const selectedAnnot = yield select(getAnnotationSelected);
        for (const x in layersList) {
            layersList[x].layerID === selectedAnnot.layerID ? layersList.push(layersList.splice(x, 1)[0]) : 0;
        }
        yield put({
            type: ActionTypes.UPDATE_LAYERS_LIST,
            LayersList: layersList
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsEllipse.js, updateEllipse ${error}`);
    }
}

function* saveEllipse() {
    yield put({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    });
}

function* updateEllipseFromApi({ ellipse }) {
    try {
        yield put({
            type: ActionTypes.HANDLE_ELLIPSE_DRAW_SUCCESS,
            ellipse: ellipse
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsEllipse.js, updateEllipseFromApi ${error}`);
    }
}

function* processEllipseData({ data }) {
    try {
        let newWidth = data.endPoint.x - data.startPoint.x;
        if (data.endPoint.x <= data.startPoint.x) {
            newWidth = data.startPoint.x - data.endPoint.x;
        }
        let newHeight = data.endPoint.y - data.startPoint.y;
        if (data.endPoint.y <= data.startPoint.y) {
            newHeight = data.startPoint.y - data.endPoint.y;
        }
        let points = new GraphicPoints();
        points = AnnotationUtil.getAnchorPoints(newWidth, newHeight, data.annotationType, points);
        const convertedBackgroundColor = ThemeHelper.convertArgbToHex(data.backgroundColor);
        const convertedForegroundColor = ThemeHelper.convertArgbToHex(data.foregroundColor);

        let dir = '';
        if (data.startPoint.x < data.endPoint.x && data.startPoint.y > data.endPoint.y) {
            dir = 'NE';
        } else if (data.startPoint.x > data.endPoint.x && data.startPoint.y < data.endPoint.y) {
            dir = 'SW';
        } else if (data.startPoint.x > data.endPoint.x && data.startPoint.y > data.endPoint.y) {
            dir = 'NW';
        } else {
            dir = 'SE';
        }
        const layersList = yield select(getLayersList);
        const isReadOnlyAnnotation = layersList.filter((i) => i.layerID === data.layerID)[0].shareAccess === ShareAccessType.SHARE_READONLY;

        const ellipse = {
            id: data.annotationID,
            layerID: data.layerID,
            type: data.annotationType,
            x: data.startPoint.x,
            y: data.startPoint.y,
            width: newWidth,
            height: newHeight,
            points: points,
            borderColor: `P${convertedForegroundColor.color.substring(1)}`,
            borderWeight: data.penSize,
            bgColor: `P${convertedBackgroundColor.color.substring(1)}`,
            opacity: convertedBackgroundColor.opacity,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            isDeleted: false,
            strokeStyle: data.strokeType,
            endMouseX: data.endPoint.x,
            endMouseY: data.endPoint.y,
            isBorderChecked: data.isBorderVisible,
            direction: dir,
            isReadOnlyAnnotation: isReadOnlyAnnotation
        }

        yield put({
            type: ActionTypes.HANDLE_ELLIPSE_CLICK_SUCCESS,
            ellipse: ellipse
        });
        yield put({
            type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
            annotation: ellipse.id
        });

        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsEllipse.js, processEllipseData ${error}`);
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        PrintMode.printing && console.log("PRINT:--Tracker--*** Data processing Error in GraphicsEllipse *** for -", TimeTrackingWindow.currentSymbol);
    }
}

function* handleEllipseDrag({ e }) {
    try {
        const { isDragging } = yield select(getAnnotationChartDraw);
        const chartDimension = yield select(getAnnotationDimension);
        let ellip = null;
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const ellipse = yield select(getEllipse);
        const curEllipse = ellipse.find((itm) => itm.id === annotSelectedId); // current ellipse to be modified
        let ellipseStart = {}, ellipseEnd = {};
        const dragPos = {
            x: e.currentTarget.x(), y: e.currentTarget.y()
        }
        if (isDragging) {
            // Fix for PANWEB-6190
            if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                e.currentTarget.attrs.x = curEllipse.x;
                e.currentTarget.attrs.y = curEllipse.y;
                return;
            }
            else if (curEllipse.x < curEllipse.endMouseX && curEllipse.y < curEllipse.endMouseY) {
                ellipseStart = {
                    x: dragPos.x, y: dragPos.y
                }
                ellipseEnd = {
                    x: dragPos.x + curEllipse.width, y: dragPos.y + curEllipse.height
                }
            } else if (curEllipse.x < curEllipse.endMouseX && curEllipse.y > curEllipse.endMouseY) {
                ellipseStart = {
                    x: dragPos.x, y: dragPos.y + curEllipse.height
                }
                ellipseEnd = {
                    x: dragPos.x + curEllipse.width, y: dragPos.y
                }
            } else if (curEllipse.x > curEllipse.endMouseX && curEllipse.y < curEllipse.endMouseY) {
                ellipseStart = {
                    x: dragPos.x + curEllipse.width, y: dragPos.y
                }
                ellipseEnd = {
                    x: dragPos.x, y: dragPos.y + curEllipse.height
                }
            } else {
                ellipseStart = {
                    x: dragPos.x + curEllipse.width, y: dragPos.y + curEllipse.height
                }
                ellipseEnd = {
                    x: dragPos.x, y: dragPos.y
                }
            }
        }

        ellip = Object.assign(curEllipse, {
            x: ellipseStart.x,
            y: ellipseStart.y,
            endMouseX: ellipseEnd.x,
            endMouseY: ellipseEnd.y
        });
        yield call(updateEllipse, false, ellip);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsEllipse.js, handleEllipseDrag ${error}`);
    }
}
function* handleEllipAnchorDrag({ e }) {
    try {
        const chartDimension = yield select(getAnnotationDimension);

        // Fix for PANWEB-6207
        if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
            return;
        }

        const annotSelectedId = yield select(getAnnotationSelectedId);
        const ellipses = yield select(getEllipse);
        const activeAnchor = yield select(getActiveAnchor);
        let ellip = null;
        let ellipEnd = null;
        let ellipStart = null;
        const curEllip = ellipses.find((itm) => itm.id === annotSelectedId);
        if (activeAnchor === "top-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
            }
            switch (curEllip.direction) {
                case 'NW':
                    ellipStart = { x: e.evt.layerX, y: curEllip.y }
                    ellipEnd = { x: curEllip.endMouseX, y: e.evt.layerY }
                    break;
                case 'NE':
                    ellipStart = { x: curEllip.x, y: curEllip.y }
                    ellipEnd = { x: e.evt.layerX, y: e.evt.layerY }
                    break;
                case 'SW':
                    ellipStart = { x: e.evt.layerX, y: e.evt.layerY }
                    ellipEnd = { x: curEllip.endMouseX, y: curEllip.endMouseY }
                    break;
                case 'SE':
                    ellipStart = { x: curEllip.x, y: e.evt.layerY }
                    ellipEnd = { x: e.evt.layerX, y: curEllip.endMouseY }
                    break;

                default:
                    break;
            }
        }
        if (activeAnchor === "top-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
            }
            switch (curEllip.direction) {
                case 'NW':
                    ellipStart = { x: curEllip.x, y: curEllip.y }
                    ellipEnd = { x: e.evt.layerX, y: e.evt.layerY }
                    break;
                case 'NE':
                    ellipStart = { x: e.evt.layerX, y: curEllip.y }
                    ellipEnd = { x: curEllip.endMouseX, y: e.evt.layerY }
                    break;
                case 'SW':
                    ellipStart = { x: curEllip.x, y: e.evt.layerY }
                    ellipEnd = { x: e.evt.layerX, y: curEllip.endMouseY }
                    break;
                case 'SE':
                    ellipStart = { x: e.evt.layerX, y: e.evt.layerY }
                    ellipEnd = { x: curEllip.endMouseX, y: curEllip.endMouseY }
                    break;

                default:
                    break;
            }
        }
        if (activeAnchor === "bottom-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
            }
            switch (curEllip.direction) {
                case 'NW':
                    ellipStart = { x: curEllip.x, y: e.evt.layerY }
                    ellipEnd = { x: e.evt.layerX, y: curEllip.endMouseY }
                    break;
                case 'NE':
                    ellipStart = { x: e.evt.layerX, y: e.evt.layerY }
                    ellipEnd = { x: curEllip.endMouseX, y: curEllip.endMouseY }
                    break;
                case 'SW':
                    ellipStart = { x: curEllip.x, y: curEllip.y }
                    ellipEnd = { x: e.evt.layerX, y: e.evt.layerY }
                    break;
                case 'SE':
                    ellipStart = { x: e.evt.layerX, y: curEllip.y }
                    ellipEnd = { x: curEllip.endMouseX, y: e.evt.layerY }
                    break;

                default:
                    break;
            }
        }
        if (activeAnchor === "bottom-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
            }
            switch (curEllip.direction) {
                case 'NW':
                    ellipStart = { x: e.evt.layerX, y: e.evt.layerY }
                    ellipEnd = { x: curEllip.endMouseX, y: curEllip.endMouseY }
                    break;
                case 'NE':
                    ellipStart = { x: curEllip.x, y: e.evt.layerY }
                    ellipEnd = { x: e.evt.layerX, y: curEllip.endMouseY }
                    break;
                case 'SW':
                    ellipStart = { x: e.evt.layerX, y: curEllip.y }
                    ellipEnd = { x: curEllip.endMouseX, y: e.evt.layerY }
                    break;
                case 'SE':
                    ellipStart = { x: curEllip.x, y: curEllip.y }
                    ellipEnd = { x: e.evt.layerX, y: e.evt.layerY }
                    break;

                default:
                    break;
            }
        }
        if (activeAnchor === "top-middle") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ns-resize';
            }
            switch (curEllip.direction) {
                case 'NW':
                case 'NE':
                    ellipStart = { x: curEllip.x, y: curEllip.y }
                    ellipEnd = { x: curEllip.endMouseX, y: e.evt.layerY }
                    break;
                case 'SW':
                case 'SE':
                    ellipStart = { x: curEllip.x, y: e.evt.layerY }
                    ellipEnd = { x: curEllip.endMouseX, y: curEllip.endMouseY }
                    break;
                default:
                    break;
            }
        }
        if (activeAnchor === "bottom-middle") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ns-resize';
            }
            switch (curEllip.direction) {
                case 'NW':
                case 'NE':
                    ellipStart = { x: curEllip.x, y: e.evt.layerY }
                    ellipEnd = { x: curEllip.endMouseX, y: curEllip.endMouseY }
                    break;
                case 'SW':
                case 'SE':
                    ellipStart = { x: curEllip.x, y: curEllip.y }
                    ellipEnd = { x: curEllip.endMouseX, y: e.evt.layerY }
                    break;
                default:
                    break;
            }
        }
        if (activeAnchor === "middle-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ew-resize';
            }
            switch (curEllip.direction) {
                case 'NW':
                case 'SW':
                    ellipStart = { x: curEllip.x, y: curEllip.y }
                    ellipEnd = { x: e.evt.layerX, y: curEllip.endMouseY }
                    break;
                case 'NE':
                case 'SE':
                    ellipStart = { x: e.evt.layerX, y: curEllip.y }
                    ellipEnd = { x: curEllip.endMouseX, y: curEllip.endMouseY }
                    break;
                default:
                    break;
            }
        }
        if (activeAnchor === "middle-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ew-resize';
            }
            switch (curEllip.direction) {
                case 'NW':
                case 'SW':
                    ellipStart = { x: e.evt.layerX, y: curEllip.y }
                    ellipEnd = { x: curEllip.endMouseX, y: curEllip.endMouseY }
                    break;
                case 'NE':
                case 'SE':
                    ellipStart = { x: curEllip.x, y: curEllip.y }
                    ellipEnd = { x: e.evt.layerX, y: curEllip.endMouseY }
                    break;
                default:
                    break;
            }
        }
        let newWidth = ellipEnd.x - ellipStart.x;
        if (ellipEnd.x <= ellipStart.x) {
            newWidth = ellipStart.x - ellipEnd.x;
        }
        let newHeight = ellipEnd.y - ellipStart.y;
        if (ellipEnd.y <= ellipStart.y) {
            newHeight = ellipStart.y - ellipEnd.y;
        }
        let points = curEllip.points;
        points = AnnotationUtil.getAnchorPoints(Math.abs(newWidth), Math.abs(newHeight), RayAnnotationType.ELLIPSE_AnnotationType, points);

        ellip = Object.assign(curEllip, {
            x: ellipStart.x,
            y: ellipStart.y,
            endMouseX: ellipEnd.x,
            endMouseY: ellipEnd.y,
            id: curEllip.id,
            width: Math.abs(newWidth),
            height: Math.abs(newHeight),
            points: points
        });
        yield call(updateEllipse, false, ellip);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsEllipse.js, handleEllipAnchorDrag ${error}`);
    }
}
function* handleAnchorLeave() {
    try {
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const ellipses = yield select(getEllipse);
        let ellipse = null;
        ellipse = ellipses.find((itm) => itm.id === annotSelectedId);
        let dir = '';
        if (ellipse.x < ellipse.endMouseX && ellipse.y > ellipse.endMouseY) {
            dir = 'NE';
        } else if (ellipse.x > ellipse.endMouseX && ellipse.y < ellipse.endMouseY) {
            dir = 'SW';
        } else if (ellipse.x > ellipse.endMouseX && ellipse.y > ellipse.endMouseY) {
            dir = 'NW';
        } else {
            dir = 'SE';
        }
        ellipse = Object.assign(ellipse, {
            direction: dir
        })
        yield call(updateEllipse, false, ellipse);
        yield safe(call(saveEllipse), "GraphicsElipse.js", "saveEllipse");
    }
    catch (error) {
        console.log(`Error occurs in GraphicsEllipse.js, handleAnchorLeave ${error}`);
    }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/


export function* watchHandleEllipseClick() {
    yield takeLatest(ActionTypes.HANDLE_ELLIPSE_CLICK, handleClick);
};

export function* watchHandleEllipseDraw() {
    yield takeLatest(ActionTypes.HANDLE_ELLIPSE_DRAW, handleMouseMove);
};

export function* watchProcessEllipseData() {
    yield takeEvery(ActionTypes.PROCESS_ELLIPSE_DATA, processEllipseData);
};

export function* watchEllipseDrag() {
    yield takeLatest(ActionTypes.HANDLE_ELLIPSE_DRAG, handleEllipseDrag)
}

export function* watchEllipAnchordrag() {
    yield takeLatest(ActionTypes.HANDLE_ELLIP_ANCHOR_DRAG, handleEllipAnchorDrag)
}

export function* watchEllipAnchorLeave() {
    yield takeLatest(ActionTypes.HANDLE_ELLIP_ANCHOR_LEAVE, handleAnchorLeave);
};

/******************************************************************************/