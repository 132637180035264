import BaseServiceApi from 'BaseServiceApi'
import SaveNotificationSettingsRequest from '../Requests/SaveNotificationSettingsRequest'
import UpdateNotificationSubscribeRequest from '../Requests/UpdateNotificationSubscribeRequest'
import NotificationSettingsRequest from '../Requests/NotificationSettingsRequest'
import ListAlertsRetrieveRequest from '../Requests/ListAlertsRetrieveRequest'
import ListAlertsUpsertRequest from '../Requests/ListAlertsUpsertRequest'
import AndAlertUpsertRequest from '../Requests/AndAlertUpsertRequest'
import CreateAlertRequest from '../Requests/CreateAlertRequest'
import GetAlertsByMsidRequest from '../Requests/GetAlertsByMsidRequest'
import UpdateAlertRequest from '../Requests/UpdateAlertRequest'
import DeleteAlertRequest from '../Requests/DeleteAlertRequest'
import GetAllNotificationsRequest from '../Requests/GetAllNotificationsRequest'
import MarkNotificationAsReadBySourceTypeRequest from '../Requests/MarkNotificationAsReadBySourceTypeRequest'
import MarkNotificationAsReadRequest from '../Requests/MarkNotificationAsReadRequest'
import DismissStockAlertRequest from '../Requests/DismissStockAlertRequest'
import DismissAlertRequest from '../Requests/DismissAlertRequest'
import ReactivateStockAlertRequest from '../Requests/ReactivateStockAlertRequest'
import ReactivateAlertRequest from '../Requests/ReactivateAlertRequest'
import DeleteAlertRequest2 from '../Requests/DeleteAlertRequest2'
import MovingAverageAlertUpsertRequest from '../Requests/MovingAverageAlertUpsertRequest'
import DeleteAllListAlertsRequest from '../Requests/DeleteAllListAlertsRequest'
import GetNewNotificationsRequest from '../Requests/GetNewNotificationsRequest'
import ListAlertTriggeredInstrumentRequest from '../Requests/ListAlertTriggeredInstrumentRequest'
import DeleteAllAlertsByMSIDsRequest from "../Requests/DeleteAllAlertsByMSIDsRequest"
import AndAlertRetrieveRequest from "../Requests/AndAlertRetrieveRequest"
import GetActiveStockAlertsRequest from "../Requests/GetActiveStockAlertsRequest"
import DeleteAllActiveByMsidRequest from "../Requests/DeleteAllActiveByMsidRequest"

class AlertsApi {
    saveSettings(data) {
        const req = new SaveNotificationSettingsRequest(data);
        return BaseServiceApi.processServiceCall(req);
    }

    notificationSettings() {
        const req = new NotificationSettingsRequest();
        return BaseServiceApi.processServiceCall(req);
    }

    listAlertsRetrieve(sourceId, sourcetypeid) {
        const req = new ListAlertsRetrieveRequest(sourceId, sourcetypeid);
        return BaseServiceApi.processServiceCall(req);
    }

    andAlertRetrieve(sourceId, sourceTypeId,alertId,msId,currencyCode){
        const req = new AndAlertRetrieveRequest(sourceId, sourceTypeId,alertId,msId,currencyCode);
        return BaseServiceApi.processServiceCall(req);
    }

    saveNotificationSubscription(endpoint, alertType, methodType, validate) {
        const req = new UpdateNotificationSubscribeRequest(endpoint, alertType, methodType, validate);
        return BaseServiceApi.processServiceCall(req);
    }

    saveAlertData(data, colsetId, browserfilterstringIds, sourcetypeId, conditionTypeId, alertId, priceAlertObj,isTriggered) {
        let req;

        if(conditionTypeId == 1 )
            req = new ListAlertsUpsertRequest(data, colsetId, browserfilterstringIds, sourcetypeId);
        else 
            req = new AndAlertUpsertRequest(data, colsetId, browserfilterstringIds, sourcetypeId, alertId, priceAlertObj, isTriggered);

        return BaseServiceApi.processServiceCall(req);
    }

    createPriceAlert(msid, targetPrice, currencyCode, note) {
        const req = new CreateAlertRequest(msid, targetPrice, currencyCode, note);
        return BaseServiceApi.processServiceCall(req);
    }

    getAlertsByMsid(msid, currencyCode, symbol) {
        const req = new GetAlertsByMsidRequest(msid, currencyCode, symbol);
        return BaseServiceApi.processServiceCall(req);
    }

    updateAlertRequest(data) {
        const req = new UpdateAlertRequest(data);
        return BaseServiceApi.processServiceCall(req);
    }

    deleteAlert(alertID) {
        const req = new DeleteAlertRequest(alertID);
        return BaseServiceApi.processServiceCall(req);
    }

    getAllNotifications() {
        const req = new GetAllNotificationsRequest();
        return BaseServiceApi.processServiceCall(req);
    }

    markAsReadBySourceType(sourceType) {
        const req = new MarkNotificationAsReadBySourceTypeRequest(sourceType);
        return BaseServiceApi.processServiceCall(req);
    }

    markAsRead(userNotificationId) {
        const req = new MarkNotificationAsReadRequest(userNotificationId);
        return BaseServiceApi.processServiceCall(req);
    }

    dismissStockAlert(alertId, listAlertTypeId) {
        const req = new DismissStockAlertRequest(alertId, listAlertTypeId);
        return BaseServiceApi.processServiceCall(req);
    }

    dismissAlert(alertId) {
        const req = new DismissAlertRequest(alertId);
        return BaseServiceApi.processServiceCall(req);
    }

    reactivateStockAlert(alertId, listAlertTypeId) {
        const req = new ReactivateStockAlertRequest(alertId, listAlertTypeId);
        return BaseServiceApi.processServiceCall(req);
    }

    reactivate(alertId) {
        const req = new ReactivateAlertRequest(alertId);
        return BaseServiceApi.processServiceCall(req);
    }

    deleteStockAlert(alertID, alertTypeID) {
        const req = new DeleteAlertRequest2(alertID, alertTypeID);
        return BaseServiceApi.processServiceCall(req);
    }

    deleteAllAlertsByMsIds(msids,cacheId) {
        const req = new DeleteAllAlertsByMSIDsRequest(msids,cacheId);
        return BaseServiceApi.processServiceCall(req);
    }

    movingAverageAlertUpsert(data) {
        const req = new MovingAverageAlertUpsertRequest(data);
        return BaseServiceApi.processServiceCall(req);
    }

    deleteAllListAlerts(listId,conditionTypeId) {
        const req = new DeleteAllListAlertsRequest(listId,conditionTypeId);
        return BaseServiceApi.processServiceCall(req);
    }

    getNewNotifications() {
        const req = new GetNewNotificationsRequest();
        return BaseServiceApi.processServiceCall(req);
    }

    getListAlertTriggeredInstruments(listId) {
        const req = new ListAlertTriggeredInstrumentRequest(listId);
        return BaseServiceApi.processServiceCall(req);
    }

    getActiveStockAlerts(msID){
        const req = new GetActiveStockAlertsRequest(msID);
        return BaseServiceApi.processServiceCall(req);
    }

    deleteAllActiveByMsid(msID, conditionTypeId){
        const req = new DeleteAllActiveByMsidRequest(msID, conditionTypeId);
        return BaseServiceApi.processServiceCall(req);
    }
    
}

var alertsApi = new AlertsApi();
export default alertsApi;
