export const RotationMenuType = {
    Sector: 1,
    MajorIndustry: 2,
    Industry: 3,
    Stocks: 4,
    Geography: 5
};

export const BenchMarkOptions = {
    Country: "Country",
    Regional: "Regional",
    Global: "Global"
}

