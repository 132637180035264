import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class CheckGICSColumnRequest extends BaseServiceRequest {
  constructor(listId, consoleID) {
    super();
    super.addRequestUri("checkgicscolumn.pb");
    super.addRequestParameter("l", listId.toString());
    super.addRequestParameter("c", consoleID);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "CheckGICSResponse";
  }
}