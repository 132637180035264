import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class UpdateExternalHeaders extends BaseServiceRequest {
  constructor(headers, listId) {
    super();
    super.addRequestUri("updateextheaders.pb");
    const ReqData = BaseServiceApi.rayData["UpdateExtHeadersRequestModel"];
    const ReqProtoData = BaseServiceApi.rayData["Header"];
    let headersArr = new ReqData();

    headers.map((rowData) => {
      let headerData = new ReqProtoData();
      headerData = rowData;
      headersArr.headers.push(headerData);
    });
    super.addPathParameter(listId.toString());
    const reqcontent = headersArr.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ExternalFileUploaderResponse";
  }

}
