import React, { Component } from "react";
import { updateStorewithSharedData } from "../../../Actions/ShareAction";
import LocalizationStore from 'LocalizationStore';
import PortalContainer from "PortalContainer";

export default class RowData extends Component {
    constructor(props) {
        super(props);
        this.showSharedData = this.showSharedData.bind(this);
        this.handleMouseIn = this.handleMouseIn.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);

        this.state = {
            showToolTip: false,
            rowData: this.props.rowData,
            mouseX:0,
            mouseY:0
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.rowData !== this.state.rowData){
            this.setState({rowData: nextProps.rowData});
        }
    }

     getOffset(evt) {
        let el = evt.target,
            x = 0,
            y = 0;
      
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
          x += el.offsetLeft - el.scrollLeft;
          y += el.offsetTop - el.scrollTop;
          el = el.offsetParent;
        }
      
        x = evt.clientX - x;
        y = evt.clientY - y;
      
        return { x: x, y: y };
      }

    handleMouseIn(entry) {       
        let mousedivL= this.getOffset(entry);
        let mouseXdiv = mousedivL.x ;
        let mouseYdiv = mousedivL.y ;
        let mouseXPos = (entry.clientX - mouseXdiv)+"px";
        let mouseYPos = (entry.clientY - mouseYdiv)+"px";
        
        const toolText = entry.currentTarget.innerText;
        if (toolText.length > 40) {
            this.setState({ showToolTip: true,mouseX : mouseXPos, mouseY : mouseYPos });
        }
    }

    handleMouseOut(entry) {           
        this.setState({ showToolTip: false,mouseX : 0,mouseY : 0 });
    }

    showSharedData(){
        const { rowData } = this.state; 
        const sharedData = {
            showSharedDialog: true,
            id: rowData.nodeId,
            name: rowData.name,
            action: this.props.sharedAction,
            IncludeAllUsers: 0,
            ntid: rowData.nodeTypeId
        }
        updateStorewithSharedData(sharedData);
    }

    render() {
        const { rowData } = this.state;        
        return (
            <tr onClick={this.showSharedData}>
                <td onMouseOver={this.handleMouseIn} onMouseOut={this.handleMouseOut}>
                    <span className="value-name">{rowData.name}</span>
                </td>
                {
                    this.state.showToolTip &&
                    <PortalContainer><div style={{ left: this.state.mouseX, top: this.state.mouseY }} className="manage-title-tooltip">{rowData.name}</div></PortalContainer>
                }
                <td>
                    <span className="value-count">
                        {
                            rowData.isSharedWithICS
                                ?
                                rowData.numPeople > 1
                                    ? `${rowData.numPeople} recipients + O'Neil`
                                    : `${rowData.numPeople} recipient + O'Neil`
                                :
                                rowData.numPeople > 1
                                    ? `${rowData.numPeople} ${LocalizationStore.getTranslatedData("LM_TP_recipients", "recipients")}`
                                    : `${rowData.numPeople} ${LocalizationStore.getTranslatedData("LM_TP_recipient", "recipient")}`
                        }
                    </span>
                </td>
            </tr>
        );
    }
}