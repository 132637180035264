import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from "BaseServiceApi";
import DateHelper from "../../Utils/TimeLineHelper/Datehelper.js"

export default class GetDataGraphDetailsRequest extends BaseServiceRequest {
    constructor(blockReq,msid,ignoreLlr,startDate,endDate,periodicity,osid,userhasxfl,targetCurrency,isQuarterly,symbol) {
        super();
        super.addRequestUri("getdatagraphdetails.pb");
        const ReqProto = BaseServiceApi.rayData["GetDataGraphDetailsRequest"];
        const createDataGraphReq = new ReqProto();
        createDataGraphReq.FindGetBlocks2RequestProto = blockReq.encode64();
      //  createDataGraphReq.FindGetSnapShotRequestProto = snapShot.encode64();
        createDataGraphReq.msid = msid;
        createDataGraphReq.ignoreLlr = ignoreLlr;
        createDataGraphReq.startDate = DateHelper.yyyymmdd(startDate);
        createDataGraphReq.endDate = DateHelper.yyyymmdd(endDate);
        createDataGraphReq.periodicity = periodicity;
        createDataGraphReq.osid = osid;
        createDataGraphReq.userhasxfl = userhasxfl;
        createDataGraphReq.targetCurrency = targetCurrency;
        createDataGraphReq.isQuarterly = isQuarterly;
        createDataGraphReq.symbol = symbol;
        const reqcontent = createDataGraphReq.encode();
        super.setContent(reqcontent);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "GetDataGraphDetailsResponse";
    }
}
