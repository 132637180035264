import AlertConditionType from "../Constants/AlertConditionType";
import { AlertConstants } from "../Constants/AlertConstants";
import AlertSourceType from "../Constants/AlertSourceType";
import AppDispatcher from "AppDispatcher";
import { dispatch } from "../Redux/dispatch.js";

class AlertActionsClass {

  openAlert(msids, instrumentId, symbol, sourceTypeId, existAlerts) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.OPEN_ALERT,
      data: { sourceId: msids, sourceTypeId: sourceTypeId, instrumentId: instrumentId, symbol: symbol, existAlerts: existAlerts }
    });
  }

  openAndAlert(msids, instrumentId, symbol, sourceTypeId, alertId,currencyCode, existAlerts) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.OPEN_AND_ALERT,
      data: { sourceId: msids, sourceTypeId: sourceTypeId, instrumentId: instrumentId, symbol: symbol, alertId: alertId, currencyCode : currencyCode, existAlerts : existAlerts }
    });
  }

  openNotificationSettings() {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.OPEN_NOTIFICATION_PREFERENCE
    });
  }
  saveNotificationSettings(notificationSettings, userNotificationSettings) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.SAVE_NOTIFICATION_PREFERENCE,
      data: { notificationSettings: notificationSettings, userNotificationSettings: userNotificationSettings }
    });
  }
  saveNotificationSubscription(endpoint, alertType, methodType, validate) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.SAVE_NOTIFICATION_SUBSCRIPTION,
      data: { endpoint: endpoint, alertType: alertType, methodType: methodType, validate: validate }
    });
  }
  saveAlertData(listAlertSettings, conditionTypeId, alertId, priceAlertObj, isTriggered) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.SAVE_ALERT_DATA,
      data: { listAlertSettings: listAlertSettings, conditionTypeId: conditionTypeId, alertId: alertId, priceAlertObj: priceAlertObj, isTriggered : isTriggered }
    });
  }
  selectColumnSet(columnSetDetails, parentColSetName) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.SELECT_ALERT_COLUMN_SET,
      data: { columnSetDetails: columnSetDetails, parentColSetName: parentColSetName }
    });
  }
  getColumnSet() {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.GET_COLUMN_SET,
      data: null
    });
  }
  onclickFilterSet(nodeFilterId, filterName) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.SELECT_FILTER_BROWSER,
      data: { nodeFilterId: nodeFilterId, filterName: filterName }
    });
  }
  createPriceAlert(msid, targetPrice, currencyCode, note) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.CREATE_PRICE_ALERT,
      data: { msid: msid, targetPrice: targetPrice, currencyCode: currencyCode, note: note }
    });
  }
  updatePriceAlert(data) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.UPDATE_PRICE_ALERT,
      data: data
    });
  }
  deleteAlert(alertObj) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.DELETE_ALERT,
      data: alertObj

    });
  }
  deleteAllAlertsByMsId(msids, cacheId) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.DELETE_ALL_ALERTS_BY_MSIDS,
      data: { msids: msids, cacheId: cacheId }
    });
  }
  openMessageCenter() {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.OPEN_MESSAGE_CENTER,
      data: null
    });
  }
  getAllNotifications() {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.GET_ALL_NOTIFICATIONS,
      data: null
    });
  }
  toggleAlertStatus(alertObj) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.TOGGLE_ALERT_STATUS,
      data: alertObj
    });
  }
  resetUnreadNotificationMessages(messageCenterWindowType) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.NW_RESET_UNREAD_MESSAGES,
      data: { messageCenterWindowType: messageCenterWindowType }
    });
  }
  toggleNotificationStatus(item) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.NW_TOGGLE_STATUS,
      data: { notificationObj: item }
    });
  }
  loadAlertsInRIPanel(showActive) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.LOAD_RIPANEL_ALERTS,
      data: { showActive: showActive }
    });
    
  }
  movingAverageAlert(menuItem) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.CREATE_MOVING_AVERAGE_ALERT,
      data: { menuItem: menuItem }
    });
  }
  deleteAllListAlerts(listId,conditionTypeId) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.DELETE_LIST_ALERT,
      data: { listId: listId, conditionTypeId :conditionTypeId }
    });
  }
  toggleShowAlerts(showAlerts) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.TOGGLE_SHOW_ALERTS,
      data: { showAlerts: showAlerts }
    });
  }
  handleHighlightAlert(id) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.HIGHLIGHT_ALERT,
      data: { id: id }
    });
    dispatch(handleHighlightAlert(id));
  }
  handleNewNotificationsReceived(notifications) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED,
      data: { notifications: notifications }
    });
  }
  removeNotificationItem(index, markAsRead) {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.REMOVE_NOTIFICATION,
      data: { index: index, markAsRead: markAsRead }
    });
  }
  handleAlertWorkerComplete() {
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.ALERT_WORKER_COMPLETED
    });
  }

  setMessageAsRead(userNotificationID){
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.SET_MESSAGE_AS_READ,
      data: { userNotificationID: userNotificationID}
    })
  }

  editAlert(symbol){
    AppDispatcher.handleViewAction({
      actionType: AlertConstants.ActionTypes.EDIT_ALERT,
      data: { symbol: symbol}
    })
  }
  deleteAllActiveAlerts(conditionTypeId) {
    AppDispatcher.handleServerAction({
      actionType: AlertConstants.ActionTypes.DELETE_ALL_ACTIVE_ALERTS,
      data: {conditionTypeId: conditionTypeId }
    });
  }
}

export const getAlertsData = (symbol)=> ({
  type: AlertConstants.ActionTypes.GET_ALERT_DATA,
  symbol
})

export const createPriceAlert = (msid, targetPrice, currencyCode, note)=> ({
  type: AlertConstants.ActionTypes.CREATE_PRICE_ALERT,
  msid, targetPrice, currencyCode, note
})

export const updatePriceAlertResponse = (priceAlertResponseData, emitWorkerAction)=> ({
  type: AlertConstants.ActionTypes.UPDATE_PRICE_ALERT_RESPONSE,
  priceAlertResponseData, emitWorkerAction
})
export const updateDataGraphAlertStates = (states)=> ({
  type: AlertConstants.ActionTypes.UPDATE_DATA_GRAPH_ALERT_STATES,
  states
})
export const updatePriceAlert = (data)=> ({
  type: AlertConstants.ActionTypes.UPDATE_PRICE_ALERT,
  data
})

export const handleHighlightAlert = (highlightedAlertObj, pointerXPos, pointerYPos)=> ({
  type: AlertConstants.ActionTypes.HIGHLIGHT_ALERT,
  highlightedAlertObj, pointerXPos, pointerYPos
})
export const removeDataGraphAlert = (alert)=> ({
  type: AlertConstants.ActionTypes.REMOVE_DATA_GRAPH_ALERT,
  alert
})
export const updateShownAlertIds = (shownAlertIds)=> ({
  type: AlertConstants.ActionTypes.UPDATE_SHOWN_ALERT_IDS,
  shownAlertIds
})
export const deleteAlert = (alertObj)=> ({
  type: AlertConstants.ActionTypes.DELETE_ALERT,
  alertObj
})
export const toggleShowActive = (showActive)=> ({
  type: AlertConstants.ActionTypes.TOGGLE_SHOW_ACTIVE,
  showActive
})
export const toggleAlertStatus = (alertObj)=> ({
  type: AlertConstants.ActionTypes.TOGGLE_ALERT_STATUS,
  alertObj
})
export const updateTriggeredAlertsList = (notifications)=> ({
  type: AlertConstants.ActionTypes.UPDATE_TRIGGERED_ALERT_LIST,
  notifications
})
export const saveAlertData = (listAlertSettings, conditionTypeId, alertId, priceAlertObj, isTriggered)=> ({
  type: AlertConstants.ActionTypes.SAVE_ALERT_DATA,
  listAlertSettings, conditionTypeId, alertId, priceAlertObj, isTriggered
})

export const updateCurrentEditingAlert = (currentEditingAlert)=> ({
  type: AlertConstants.ActionTypes.UPDATE_CURRENT_EDITING_ALERT,
  currentEditingAlert
})
export const startAlertDataWorker = ()=> ({
  type: AlertConstants.ActionTypes.START_ALERTS_DATA_WORKER,
})
export const postAlertsDataWorkerAction = ()=> ({
  type: AlertConstants.ActionTypes.POST_ALERT_DATA_WORKER,
})

//Dialogs actions
export const openAndAlertDialog = (msId, instrumentId, symbol,currencyCode= null, sourceTypeId =  AlertSourceType.Stock, conditionTypeId = AlertConditionType.And, alertId = 0, existAlerts = null, isTriggered = false, instrumentType = null)=>({
  type: AlertConstants.ActionTypes.OPEN_AND_ALERT_DIALOG,
  msId, instrumentId, symbol, sourceTypeId, conditionTypeId, alertId, currencyCode, existAlerts, isTriggered, instrumentType
})
export const openORAlertDialog = (msId, instrumentId, symbol, currencyCode= null, sourceTypeId = AlertSourceType.Stock, conditionTypeId = AlertConditionType.Or, alertId = 0, existAlerts = null, isTriggered = false, instrumentType = null)=>({
  type: AlertConstants.ActionTypes.OPEN_OR_ALERT_DIALOG,
  conditionTypeId, msId, instrumentId, symbol, sourceTypeId, alertId, currencyCode, existAlerts, isTriggered, instrumentType
})
export const openPriceAlertDialog = (alertObj, isEditing, price= null, xPos= 0, yPos= 0)=>({
  type: AlertConstants.ActionTypes.OPEN_PRICE_ALERT_DIALOG,
  alertObj, isEditing, price, xPos, yPos 
})
export const closeAlertDialog = ()=>({
  type: AlertConstants.ActionTypes.CLOSE_ALERT_DIALOG,
})
export const openCriteriaDialog = ()=>({
  type: AlertConstants.ActionTypes.OPEN_CRITERIA_DIALOG,
})
export const cancelCriteriaDialog = ()=>({
  type: AlertConstants.ActionTypes.CANCEL_CRITERIA_DIALOG,
})
export const saveCriteriaDialog = (listAlertSettings, priceAlert)=>({
  type: AlertConstants.ActionTypes.SAVE_CRITERIA_DIALOG,
  listAlertSettings, priceAlert
})
export const createMovingAverageAlert = (menuItem)=>({
  type: AlertConstants.ActionTypes.CREATE_MOVING_AVERAGE_ALERT,
  menuItem
})
export const toggleShowAlerts = ()=>({
  type: AlertConstants.ActionTypes.TOGGLE_SHOW_ALERTS,
})

export const updateAlertDataObject = (alertDataObj, showLoader)=>({
  type: AlertConstants.ActionTypes.UPDATE_ALERT_DATA_OBJECT,
  alertDataObj, showLoader, 
})
export const conditionAlertDelete = (item)=>({
  type: AlertConstants.ActionTypes.CONDITION_ALERT_DELETE,
  item
})
export const cancelConditionAlertDelete = ()=>({
  type: AlertConstants.ActionTypes.CANCEL_CONDITION_ALERT_DELETE,
})
export const deleteAllActiveAlerts = ()=>({
  type: AlertConstants.ActionTypes.DELETE_ALL_ACTIVE_ALERTS,
})
export const openGeographyFilter = ()=>({
  type: AlertConstants.ActionTypes.OPEN_GEOGRAPHY_FILTER,
})
export const openColumnSetFilter = ()=>({
  type: AlertConstants.ActionTypes.OPEN_COLUMN_FILTER,
})
export const closeFilterDialog = ()=>({
  type: AlertConstants.ActionTypes.CLOSE_FILTER_DIALOG,
})
export const updateBrowserFilter = (nodeFilterId, filterName)=>({
  type: AlertConstants.ActionTypes.SELECT_FILTER_BROWSER,
  nodeFilterId, filterName
})
export const selectClosestChildren = (columnSetDetails)=>({
  type: AlertConstants.ActionTypes.SELECT_CLOSEST_CHILDREN,
  columnSetDetails
})
export const selectColumnSet = (columnSetDetails, parentColSetName)=>({
  type: AlertConstants.ActionTypes.SELECT_ALERT_COLUMN_SET,
  columnSetDetails, parentColSetName
})
export const startDragginAlert = (isDragging, highlightedAlertObj, pointerXPos, pointerYPos)=>({
  type: AlertConstants.ActionTypes.START_DRAGGING_ALERT,
  isDragging, highlightedAlertObj, pointerXPos, pointerYPos
})
export const showSaveAlertPopUp = (value) =>({
  type: AlertConstants.ActionTypes.SHOW_SAVE_ALERT_POPUP,
  isSaveAlertsPopup: value
})
const AlertActions = new AlertActionsClass();
export default AlertActions;
