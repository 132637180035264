import keyMirror from "keymirror";

export const AnnotationConstant = {
    ActionTypes: keyMirror({
        HANDLE_EXCEPTION: null,
        ERROR: null,
        INIT_ANNOTATION_MODEL: null,
        PROCESS_ANNOTATION_LAYER_DATA: null,
        PROCESS_ANNOTATION_DATA: null,
        CLEAR_ANNOTATIONS: null,
        CLEAR_ANNOTATION_LAYERS: null,
        REDRAW_ANNOTATIONS_ON_CHART_RESIZE: null,
        GET_DEFAULT_DRAWING_MODE: null,
        UPDATE_LAYER_INFO_SUCCESS: null,
        ON_FOOTER_ACTION_TRIGGERED: null,
        RELOCATE_LAYER: null,
        TOGGLE_VISIBILITY: null,
        ON_ANNOTATION_DIMENSION_CHANGE: null,
        IS_SELECTED_ANNOTATION: null,
        IS_SELECTED_ANNOTATION_SUCCESS: null,
        HANDLE_CLICK: null,
        HANDLE_MOUSE_MOVE: null,
        HANDLE_POINTER_CLICK: null,
        HANDLE_LINE_CLICK: null,
        HANDLE_LINE_CLICK_SUCCESS: null,
        HANDLE_CHANNEL_LINE_CLICK: null,
        HANDLE_TEXT_CLICK: null,
        HANDLE_RECTANGLE_CLICK: null,
        HANDLE_RECTANGLE_CLICK_SUCCESS: null,
        HANDLE_ELLIPSE_CLICK: null,
        HANDLE_ELLIPSE_CLICK_SUCCESS: null,
        HANDLE_TRIANGLE_CLICK: null,
        HANDLE_TRIANGLE_CLICK_SUCCESS: null,
        HANDLE_FIBONACCI_CLICK: null,
        HANDLE_MEASUREMENT_CLICK: null,
        HANDLE_CLICK_SUCCESS: null,
        HANDLE_LINE_DRAW: null,
        HANDLE_LINE_DRAW_SUCCESS: null,
        HANDLE_LINE_SAVE_ID_SUCCESS: null,
        HANDLE_CHANNEL_LINE_SAVE_ID_SUCCESS: null,
        HANDLE_CHANNEL_LINE_DRAW: null,
        HANDLE_RECTANGLE_DRAW: null,
        HANDLE_RECTANGLE_DRAW_SUCCESS: null,
        HANDLE_RECTANGLE_SAVE_ID_SUCCESS: null,
        HANDLE_ELLIPSE_DRAW: null,
        HANDLE_ELLIPSE_DRAW_SUCCESS: null,
        HANDLE_ELLIPSE_SAVE_ID_SUCCESS: null,
        HANDLE_TRIANGLE_DRAW: null,
        HANDLE_TRIANGLE_DRAW_SUCCESS: null,
        HANDLE_TRIANGLE_SAVE_ID_SUCCESS: null,
        HANDLE_FIBONACCI_DRAW: null,
        HANDLE_MEASUREMENT_DRAW: null,
        HANDLE_MOUSE_MOVE_SUCCESS: null,
        HANDLE_MOUSE_DOWN: null,
        HANDLE_MOUSE_ENTER: null,
        HANDLE_MOUSE_LEAVE: null,
        INIT_RECTANGLE_COLLECTION: null,
        PROCESS_LINE_DATA: null,
        PROCESS_RECTANGLE_DATA: null,
        PROCESS_ELLIPSE_DATA: null,
        PROCESS_TRIANGLE_DATA: null,
        PROCESS_CHANNEL_LINE_DATA: null,
        PROCESS_FIBONACCI_DATA: null,
        PROCESS_MEASUREMENT_DATA: null,
        HANDLE_TOOL_CHANGE: null,
        HANDLE_SAVE_ANNOTATION: null,
        HANDLE_CHANNEL_LINE_CLICK_SUCCESS: null,
        HANDLE_CHANNEL_LINE_DRAW_SUCCESS: null,
        HANDLE_ANNOTATION_ERROR: null,
        HANDLE_TEXT_CLICK_SUCCESS: null,
        HANDLE_TEXT_DRAW: null,
        HANDLE_TEXT_DRAW_SUCCESS: null,
        HANDLE_TEXT_SAVE_ID_SUCCESS: null,
        HANDLE_RE_WRITE_TEXT_SUCCESS: null,
        HANDLE_TEXT_MODIFIED: null,
        UPDATE_PRICE_CHART_NODE_VALUES: null,
        HANDLE_COLOR_CHANGE: null,
        HANDLE_BORDER_COLOR_CHANGE: null,
        HANDLE_WEIGHT_CHANGE: null,
        HANDLE_BORDER_WEIGHT_CHANGE: null,
        HANDLE_STROKE_CHANGE: null,
        HANDLE_BORDER_STROKE_CHANGE: null,
        HANDLE_START_ARROW_CHANGE: null,
        HANDLE_END_ARROW_CHANGE: null,
        HANDLE_START_EXTEND_CHANGE: null,
        HANDLE_END_EXTEND_CHANGE: null,
        HANDLE_CHECKBOX_CHANGE: null,
        HANDLE_OPACITY_CHANGE: null,
        HANDLE_BG_COLOR_CHANGE: null,
        HANDLE_EXTENSION_COLOR_CHANGE: null,
        HANDLE_EXTENSION_WEIGHT_CHANGE: null,
        ADD_TRENDLINE_ALERT: null,
        INIT_MEASUREMENT_DATA_COLLECTION: null,
        HANDLE_MEASUREMENT_LINE_SAVE_ID_SUCCESS: null,
        HANDLE_MEASUREMENT_DRAW_SUCCESS: null,
        HANDLE_MEASUREMENT_CLICK_SUCCESS: null,
        HANDLE_LEADER_LINE_CHANGE: null,
        HANDLE_FIBONACCI_LINE_CLICK: null,
        HANDLE_FIBONACCI_LINE_CLICK_SUCCESS: null,
        HANDLE_FIBONACCI_LINE_DRAW: null,
        HANDLE_FIBONACCI_LINE_DRAW_SUCCESS: null,
        UPDATE_FIBONACCI_LINE: null,
        HANDLE_ANNOTATION_CONTEXT_MENU_TOGGLE: null,
        ANNOTATION_CONTEXT_MENU_TOGGLE: null,
        REMOVE_DRAWING_ANNOTATION: null,
        HANDLE_REMOVE_LINE_DRAW: null,
        HANDLE_REMOVE_CHANNEL_LINE_DRAW: null,
        HANDLE_REMOVE_TEXT_DRAW: null,
        HANDLE_REMOVE_RECTANGLE_DRAW: null,
        HANDLE_REMOVE_ELLIPSE_DRAW: null,
        HANDLE_REMOVE_TRIANGLE_DRAW: null,
        HANDLE_REMOVE_FIBONACCI_LINE_DRAW: null,
        HANDLE_REMOVE_MEASUREMENT_DRAW: null,
        REMOVE_DRAWING_ANNOTATION_AFTER_KEY: null,
        HANDLE_FIBONACCI_LINE_SAVE_ID_SUCCESS: null,
        REMOVE_ALL_ANNOTATION: null,
        HANDLE_SHAPE_CHANGE: null,
        HANDLE_TEXT_EFFECTS_CHANGE: null,
        STORE_ANNOTATION_IDS: null,
        REMOVE_DELETED_ANNOTATIONS: null,
        HANDLE_CONTROL_CLICK: null,
        UPDATE_SELECTED_ANNOTATIONS_ADD: null,
        UPDATE_SELECTED_ANNOTATIONS_REMOVE: null,
        STORE_NEW_ANNOTATION_IDS: null,
        HANDLE_TOGGLE_DELETE_POPUP: null,
        REMOVE_ALL_ANNOTATION_CONFIRM: null,
        HANDLE_DRAG_MOVE: null,
        UPDATE_DRAG_STATUS: null,
        HANDLE_LINE_ANCHOR_CLICK: null,
        SET_ACTIVE_LAYER: null,
        ON_LAYER_ITEM_RENAMED: null,
        HANDLE_SAVE_CLICKED_ANCHOR_POSITION: null,
        REMOVE_TRENDLINE_ALERT: null,
        HANDLE_ALERT_CHANGE: null,
        HANDLE_TOGGLE_LAYER_DELETE_POPUP: null,
        HANDLE_TL_ALERT_HOVER_TOGGLE: null,
        SHOW_UNDO_ALERT: null,
        UNDO_DELETED_ANNOTATIONS: null,
        RESTORE_DELETED_ANNOTATIONS: null,
        SHOW_UNDO_ALERT_LAYER: null,
        SAVE_LAYER_FOR_UNDO: null,
        REVOKE_LAYER_ON_UNDO: null,
        MOVE_ANNOTATIONS_TO_FRONT: null,
        MOVE_ANNOTATIONS_TO_BACK: null,
        REMOVE_VISIBLE_ANNOTATION_IDS: null,
        ADD_VISIBLE_ANNOTATION_IDS: null,
        REVOKE_LAYER_ON_UNDO_SUCCESS: null,
        UPDATE_RI_PANEL_INFO: null,
        PROCESS_TEXT_DATA: null,
        UPDATE_STATE_FROM_SETTINGS: null,
        REMOVE_TEMP_TEXT_ANNOTATION: null,
        HANDLE_TEXT_ANCHOR_DRAG: null,
        HANDLE_TEXT_ANCHOR_LEAVE: null,
        UPDATE_TARGET_ANNOTATION: null,
        HANDLE_TEXT_DOUBLE_CLICK: null,
        HANDLE_TOGGLE_LAYER_VISIBILTY_POPUP: null,
        HANDLE_TOGGLE_LAYER_READONLY_POPUP: null,
        UPDATE_TARGET_TEXT_ANNOTATION: null,
        ADD_TEXT_BORDER: null,
        REMOVE_TEXT_BORDER: null,
        UPDATE_TEXT_HEIGHT: null,
        REMOVE_LINE_ALERT_ICON: null,
        HANDLE_TEXT_DRAG: null,
        HANDLE_ESC_AFTER_EDIT: null,
        UPDATE_API_CALL_STATUS: null,
        HANDLE_RECTANGLE_DRAG: null,
        HANDLE_ELLIPSE_DRAG: null,
        HANDLE_TRIANGLE_DRAG: null,
        HANDLE_RECT_ANCHOR_LEAVE: null,
        HANDLE_RECT_ANCHOR_DRAG: null,
        HANDLE_ELLIP_ANCHOR_LEAVE: null,
        HANDLE_ELLIP_ANCHOR_DRAG: null,
        HANDLE_TRI_ANCHOR_LEAVE: null,
        HANDLE_TRI_ANCHOR_DRAG: null,
        ADD_TO_ANNOTATION_COLLECTION: null,
        REPLACE_ANNOTATION_COLLECTION: null,
        REMOVE_TEMP_LINE_ANNOTATIONS: null,
        REMOVE_SELECTED_TEMP_LINE_ANNOTATIONS: null,
        CLEAR_SELECTED_ANNOTATIONS: null,
        REMOVE_ALERT_SELECTION_SAVE: null,
        STORE_ALERT_DELETE_STATUS: null,
        TOGGLE_LINE_ALERT_ICON: null,
        UPDATE_LAYERS_LIST: null,
        UPDATE_RI_PANEL_STATUS: null,
        HANDLE_ALERT_END_EXTEND_CHANGE: null,
        UPDATE_TEMP_TOOL_SELECTION: null,
        UPDATE_STAGE_CLICK_CAPTURED_FLAG: null,
        DETECT_MOUSE_MOVE: null,
        HANDLE_ORIGINAL_IDS_TEMP: null,
        TOGGLE_DG_RI_PANEL: null,
        STORE_TEMP_ANNOTATION_IDS: null,
        REMOVE_TEMP_ANNOTATION_IDS: null,
        REMOVE_TEMP_IDS_ARRAY: null
    })
}
export const AnnotationDashes =
{
    Solid: 0,
    Dot: 1,
    Dash: 2
}
export const AnnotationType = {
    "LINE_AnnotationType": 1,
    "PARALLEL_LINES_AnnotationType": 2,
    "POLY_LINE_AnnotationType": 3,
    "CIRCLE_AnnotationType": 4,
    "RECT_AnnotationType": 5,
    "ELLIPSE_AnnotationType": 6,
    "TRIANGLE_AnnotationType": 7,
    "TEXT_AnnotationType": 8,
    "FIB_AnnotationType": 9,
    "CUP_AnnotationType": 10,
    "CUP_AND_HANDLE_AnnotationType": 11,
    "DOUBLE_BOTTOM_AnnotationType": 12,
    "MEASUREMENT_AnnotationType": 13
}

export const TemporaryId = {
    Id: "tempId"
};
