import React from "react";
import { each, find } from "underscore";
import light from "!less-vars-loader!../../Asset/css/lessvars/colors-light.less";
import dark from "!less-vars-loader!../../Asset/css/lessvars/colors-dark.less";
import ListStore from "ListStore";
import ONeilViewStore from "ONeilViewStore";
import SummaryStatStore from "./SummaryStatStore"
import ThemeType from "ThemeType";
import BrowserUtil from "BrowserUtil";
import WPFCanvasUtil from "../../Utils/WPFCanvasUtil";
import SummaryStatActions from "SummaryStatActions";
import ThemeHelper from "ThemeHelper";

let left = 0.0;
let positiveDataText = null;
let negativeDataText = null;
let positiveDataTextHover = null;
let negativeDataTextHover = null;
let gridRowOddEvenColor = null;
let gridRowOddEvenColorHover = null;
let gridColumColorEvenHover = null;
let gridColumColorOddHover = null;
//let cellLimit = 3;
export default class SummaryStatRowItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMouseDown: false
        }
        this.canvas = null;
        this.ctx = null;

        this.removeHighlightRowCol = this.removeHighlightRowCol.bind(this);
        this.highLightRow = this.highLightRow.bind(this);
        this.showTooltip = this.props.tooltip.showTooltip.bind(this);
        this.clearCellTitle = this.props.tooltip.hideTooltip.bind(this);
        this.getCanvasCells = this.getCanvasCells.bind(this);
        this.getColumnFromPos = this.getColumnFromPos.bind(this);
        this.showCanvasToolTip = this.showCanvasToolTip.bind(this);
        this.setCanvasCellContent = this.setCanvasCellContent.bind(this);
        this.isEllipses = this.isEllipses.bind(this);
        this.getWrappedContent = this.getWrappedContent.bind(this);
        this.getBottomBaseLinePos = this.getBottomBaseLinePos.bind(this);
        this.getHighLightedRowClass = this.getHighLightedRowClass.bind(this);
        this.getHighLightedColClass = this.getHighLightedColClass.bind(this);
        this.loadHistData = this.loadHistData.bind(this);
        this.dragStart = this.dragStart.bind(this);
        this.removeHighlight = this.removeHighlight.bind(this);
    }

    componentDidMount() {
        this.listExplorerElm = document.getElementById('listExplorer');
        this.horizontalScrollElm = document.getElementById('gridHorizontalScroll');
        // this.toolTrip = document.getElementById('grid-row-tooltip');
        // if (!this.props.gridTypeTable) {
        let id = "myCanvas_F_SS" + this.props.rowIndex;
        //if (this.props.columns[0].isFrozen) id = "myCanvas_N_" + this.props.rowIndex;
        this.canvas = this.refs[id];
        this.getCanvasCells();
        // }
    }

    componentDidUpdate() {
        this.getCanvasCells();
    }


    removeHighlightRowCol () {
        if (!BrowserUtil.isDisableEventsOn()) {
            SummaryStatActions.setHighlightedRowAndColumn(null, null, false);
        }
    }

    highLightRow (e) {
        if (!BrowserUtil.isDisableEventsOn()) {
            let column = this.getColumnFromPos(e);
            const cId = column ? column.dataItemId : null;
            SummaryStatActions.setHighlightedRowAndColumn(this.props.rowIndex, cId, true);
        }
    }


    // showTooltip (e, displayValue) {
    //     this.toolTrip.style.top = (e.clientY + 20) + 'px';
    //     this.toolTrip.style.left = (e.clientX + 0) + 'px';
    //     this.toolTrip.style.display = 'block';
    //     this.toolTrip.style.position = 'fixed';
    //     this.toolTrip.innerHTML = displayValue;
    // }

    // clearCellTitle () {
    //     this.toolTrip.innerHTML = '';
    //     this.toolTrip.style.display = 'none';
    //     this.toolTrip.style.top = '0px';
    //     this.toolTrip.style.left = '0px';
    // }



    getCanvasCells () {
        try {
            if (this.props.data == undefined) return "Loading";
            // let cells = [];
            left = 0.0;

            if (this.canvas) {
                let selectedTheme = ListStore.getSelectedTheme();

                this.ctx = this.canvas.getContext("2d");
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

                // To solve hardware acceleration issue of chrome
                /*if(BrowserUtil.getBrowserName() == 'Chrome') {
                  this.ctx.rect(0, 1, this.canvas.width, this.canvas.height);
    
                  if (selectedTheme == ThemeType.Dark) {
                    if (this.props.className == 'grid-row-odd') this.ctx.fillStyle = dark.gridRowOddBg;
                    else this.ctx.fillStyle = dark.gridRowEvenBg;
    
                    if(this.state.isSelected) this.ctx.fillStyle = dark.gridRowSelected;
                  }
                  else if (selectedTheme == ThemeType.Light) {
                    if (this.props.className == 'grid-row-odd') this.ctx.fillStyle = light.gridRowOddBg;
                    else this.ctx.fillStyle = light.gridRowEvenBg;
    
                    if(this.state.isSelected) this.ctx.fillStyle = light.gridRowSelected;
                  }
                  this.ctx.fill();
                }*/

                //if (this.props.columns[0].isFrozen) WPFCanvasUtil.setScale(this.canvas, this.ctx, this.props.width - this.staticColsWidth, this.props.rowHeight);
                WPFCanvasUtil.setScale(this.canvas, this.ctx, this.props.width, this.props.rowHeight);

                this.ctx.font = this.props.textSize + "px 'calibri'";
                this.ctx.textBaseline = 'middle';
                this.ctx.imageSmoothingEnabled = true;

                // if (selectedTheme == ThemeType.Dark) {
                //     positiveDataText = dark.positiveDataText;
                //     negativeDataText = dark.negativeDataText;
                //     positiveDataTextHover = dark.positiveDataTextHover;
                //     negativeDataTextHover = dark.negativeDataTextHover;
                //     gridRowOddEvenColor = dark.gridRowOddEvenColor;
                //     gridRowOddEvenColorHover = dark.fff000;
                // }
                // else if (selectedTheme == ThemeType.Light) {
                //     positiveDataText = light.positiveDataText;
                //     negativeDataText = light.negativeDataText;
                //     positiveDataTextHover = light.positiveDataTextHover;
                //     negativeDataTextHover = light.negativeDataTextHover;
                //     gridRowOddEvenColor = light.gridRowOddEvenColor;
                //     gridRowOddEvenColorHover = light.fff000;
                // }

                positiveDataText = ThemeHelper.getThemedBrush('positiveDataText');
                negativeDataText = ThemeHelper.getThemedBrush('negativeDataText');
                positiveDataTextHover = ThemeHelper.getThemedBrush('positiveDataTextHover');
                negativeDataTextHover = ThemeHelper.getThemedBrush('negativeDataTextHover');

                if (selectedTheme == ThemeType.Dark) {
                    gridRowOddEvenColor = dark.gridRowOddEvenColor;
                    gridRowOddEvenColorHover = dark.fff000;
                }
                else if (selectedTheme == ThemeType.Light) {
                    gridRowOddEvenColor = light.gridRowOddEvenColor;
                    gridRowOddEvenColorHover = light.fff000;
                }

            }
            //  let cellIndex = 0;
            if (this.props.nonFrozen) {
                if (this.props.columns) {
                    each(this.props.columns, (col) => {
                        let cellData =
                            find(this.props.data, (cellItems) => col.dataItemId == cellItems.column.dataItemId);
                        if (cellData) {
                            cellData.columnWidth = SummaryStatStore.getColumnWidth(col)
                            this.setCanvasCellContent(cellData);
                        }
                        else {
                            cellData = {
                                displayValue: '',
                                column: col,
                                fontWeight: "Normal",
                                hoverFontWeight: "Normal",
                                alignment: ONeilViewStore.getAlignment(col),
                                textWidth: ONeilViewStore.getCellTextWidth(col, '', null),
                                columnWidth: SummaryStatStore.getColumnWidth(col)
                            }
                            this.setCanvasCellContent(cellData);
                        }

                        // cells.push(cell);
                    })
                }
            }
            else {
                each(this.props.data, (cellItems) => {
                    let col = {
                        textWidth: this.props.width
                    }
                    let cellData = {
                        displayValue: cellItems.displayValue,
                        column: col,
                        fontWeight: "Normal",
                        hoverFontWeight: "Normal",
                        alignment: 'left',
                        textWidth: ONeilViewStore.getCellTextWidth(null, cellItems.displayValue, null),
                        columnWidth: this.props.width
                    }

                    this.setCanvasCellContent(cellData)
                });
            }

        }
        catch (e) {
            console.log(e);
        }
        // return cells;
    }

    getColumnFromPos (e) {
        let scrollX = this.horizontalScrollElm.scrollLeft;
        let mouseMovePos;
        let rectListExplorerInfo = this.listExplorerElm.getBoundingClientRect();
        if (e.clientX <= this.props.frozenAreaWidth + this.listExplorerElm.offsetWidth) {
            mouseMovePos = (e.clientX - rectListExplorerInfo.width - rectListExplorerInfo.left);
        }
        else {
            mouseMovePos = (e.clientX + scrollX) - (rectListExplorerInfo.width + rectListExplorerInfo.left);
        }
        let colPos = null;
        if (this.props.nonFrozen) {
            colPos = this.props.getMouseOverColumnIndex(mouseMovePos);
        }
        if (colPos) {
            return this.props.allColumns[colPos];
        }
        return null;
    }

    showCanvasToolTip (e) {
        let column = this.getColumnFromPos(e);
        this.highLightRow(e);
        if (column) {
            let cellData = find(this.props.data, (cellItems) => column.dataItemId == cellItems.column.dataItemId);
            if (cellData && cellData.displayValue != '') {
                let colWidth = cellData.columnWidth;
                let cellContent = cellData.displayValue;
                let paddingLeft = 10;
                if (cellContent) {
                    let ellipses = false;
                    if (this.ctx && cellData.alignment == 'left') {
                        this.ctx.textAlign = "start";
                        ellipses = this.isEllipses(this.ctx, cellContent, colWidth - paddingLeft);
                    }
                    else if (this.ctx && cellData.alignment == 'right') {
                        this.ctx.textAlign = "end";
                        ellipses = this.isEllipses(this.ctx, cellContent, colWidth - (paddingLeft / 2));
                    }
                    else if (this.ctx && cellData.alignment == 'center') {
                        this.ctx.textAlign = "center";
                        ellipses = this.isEllipses(this.ctx, cellContent, colWidth);
                    }
                    if (ellipses) {
                        this.showTooltip(e, cellContent);
                    }
                    else this.clearCellTitle(e);
                }
            }
        }
    }

    setCanvasCellContent (cellData) {
        let colitem = cellData.column;
        let colWidth = cellData.columnWidth;
        let paddingLeft = 10;
        let textWidth = Math.ceil(colitem.textWidth);
        let marginLeft = 5;

        // if (this.props.columns[0].isFrozen && cellIndex < this.cellLimit) {
        //     return;
        // }

        if (colWidth < textWidth + paddingLeft) {
            textWidth = colWidth;
        }

        let textPosY = this.getBottomBaseLinePos();

        if (this.canvas && cellData) {
            let className = cellData.className;
            let displayValue = cellData.displayValue;
            let w = parseInt((colWidth - textWidth) / 2);
            this.ctx.fillStyle = gridRowOddEvenColor;
            if (colitem.dataItemId && this.getHighLightedColClass(colitem.dataItemId)) {
                let selectedTheme = ListStore.getSelectedTheme();
                if (selectedTheme == ThemeType.Dark) {
                    gridColumColorEvenHover = dark.listSummaryStatsDataEven;
                    gridColumColorOddHover = dark.listSummaryStatsDataOdd;
                }
                else if (selectedTheme == ThemeType.Light) {
                    gridColumColorEvenHover = light.listSummaryStatsDataEven;
                    gridColumColorOddHover = light.listSummaryStatsDataOdd;
                }
                this.ctx.fillStyle = this.props.isEven ? gridColumColorEvenHover : gridColumColorOddHover;
                this.ctx.fillRect(left, 0, colWidth, this.props.rowHeight);
                this.ctx.fillStyle = gridRowOddEvenColor;
            }
            else {
                this.ctx.fillStyle = gridRowOddEvenColor;
            }

            const isHighLighted = this.getHighLightedRowClass();
            if (className && !isHighLighted) {
                if (className == 'grid-cell-positive') this.ctx.fillStyle = positiveDataText;
                else if (className == 'grid-cell-negative') this.ctx.fillStyle = negativeDataText;
            }
            else if (className && isHighLighted) {
                if (className == 'grid-cell-positive') this.ctx.fillStyle = positiveDataTextHover;
                else if (className == 'grid-cell-negative') this.ctx.fillStyle = negativeDataTextHover;
            }
            else if (!className && isHighLighted) this.ctx.fillStyle = gridRowOddEvenColorHover;

            this.ctx.fill();

            // if (className && !this.state.isHighLighted) {
            //     if (className == 'grid-cell-positive') this.ctx.fillStyle = positiveDataText;
            //     else if (className == 'grid-cell-negative') this.ctx.fillStyle = negativeDataText;
            // }
            // else if (!className && !this.state.isHighLighted) this.ctx.fillStyle = gridRowOddEvenColor;
            // //hover
            // else if (className && this.state.isHighLighted) {
            //     if (className == 'grid-cell-positive') this.ctx.fillStyle = positiveDataTextHover;
            //     else if (className == 'grid-cell-negative') this.ctx.fillStyle = negativeDataTextHover;
            // }
            // else if (!className && this.state.isHighLighted) this.ctx.fillStyle = gridRowOddEvenColorHover;

            if (this.ctx && cellData.alignment == 'left') {
                this.ctx.textAlign = "start";
                let text = this.getWrappedContent(this.ctx, displayValue, colWidth - paddingLeft);
                if (w > 0) {
                    this.ctx.fillText(text, left + w + paddingLeft - marginLeft, textPosY);
                }
                else {
                    this.ctx.fillText(text, left + w + paddingLeft, textPosY);
                }
                left += colWidth;
            }
            else if (this.ctx && cellData.alignment == 'right') {
                this.ctx.textAlign = "end";
                left += colWidth;
                let text = this.getWrappedContent(this.ctx, displayValue, (colWidth - (paddingLeft / 2)));
                if (w > 0) {
                    this.ctx.fillText(text, left - w + marginLeft, textPosY);
                }
                else {
                    this.ctx.fillText(text, left - w, textPosY);
                }
            }
            else if (this.ctx && cellData.alignment == 'center') {
                this.ctx.textAlign = "center";
                left += colWidth;
                let text = this.getWrappedContent(this.ctx, displayValue, colWidth);
                let leftPos = Math.ceil(left - (colWidth - w - textWidth / 2));
                if (left > 0 && leftPos >= (left - colWidth)) {
                    this.ctx.fillText(text, leftPos, textPosY);
                }
                else {
                    this.ctx.fillText(text, (left - (colWidth / 2)), textPosY);
                }
            }
            else if (this.ctx && cellData.alignment == 'center') {
                this.ctx.textAlign = "center";
                left += colWidth;
                let text = ' ';
                this.ctx.fillText(text, (left - (colWidth / 2)), textPosY);
            }

        }
        return;
    }

    isEllipses  (c, str, maxWidth) {
        let width = c.measureText(str).width;
        let ellipsis = "...";
        let ellipsisWidth = c.measureText(ellipsis).width;
        if (width <= maxWidth || width <= ellipsisWidth) {
            return false;
        } else {
            let len = str.length;
            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
                str = str.substring(0, len);
                width = c.measureText(str).width;
            }
            return true;
        }
    }

    getWrappedContent  (c, str, maxWidth) {
        let width = c.measureText(str).width;
        let ellipsis = "...";
        let ellipsisWidth = c.measureText(ellipsis).width;
        if (width <= maxWidth || width <= ellipsisWidth) {
            return str;
        } else {
            let len = str.length;
            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
                str = str.substring(0, len);
                width = c.measureText(str).width;
            }

            if (str[str.length - 1] == ".") {
                let ellipsisDot = ellipsis.substring(0, 2);
                return str + ellipsisDot;
            }
            else {
                return str + ellipsis;
            }
        }
    }

    getBottomBaseLinePos  () {
        if (BrowserUtil.getBrowserName() == "Firefox") {
            return Math.ceil(this.props.rowHeight / 2) + 1;
        }
        else if ((BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape") && this.props.textSize == 16) {
            return Math.ceil(this.props.rowHeight / 2) + 1;
        }
        else {
            return Math.ceil(this.props.rowHeight / 2);
        }
    }

    getHighLightedRowClass  () {
        if (this.props.highlightedRowCol && this.props.highlightedRowCol.rowIndex && this.props.highlightedRowCol.rowIndex == this.props.rowIndex) {
            return true;
        }
        return false;
    }

    getHighLightedColClass (columnId) {
        if (this.props.highlightedRowCol && this.props.highlightedRowCol.columnId && this.props.highlightedRowCol.columnId == columnId) {
            return true;
        }
        return false;
    }

    loadHistData (e) {
        if (this.props.nonFrozen) {
            let column = this.getColumnFromPos(e);
            this.props.loadHistData && this.props.loadHistData(column);
        }
        e.preventDefault();
        e.stopPropagation();
    }

    dragStart (e) {
        this.removeHighlight(e);
        this.props.dragStart(e, this.props.dataId);
    }

    removeHighlight (e) {
        this.clearCellTitle(e);
        this.removeHighlightRowCol();
    }


    render() {
        //let background = '';
        let id = "myCanvas_F_SS" + this.props.rowIndex;
        return (
            <tr
                className={`grid-row ${this.props.className}`}
                style={{ width: this.props.width }}
                data-id={this.props.dataId}
                draggable={true}
                onDrop={this.props.dragDrop}
                onDragEnd={this.props.dragEnd}
                onDragStart={this.dragStart}
                onDragEnter={this.props.dragEnter}
                onDragLeave={this.props.dragLeave}
                onDragOver={this.props.dragOver}
                onDrag={this.props.dragMove}
                onClick={this.loadHistData}
            >
                <td width={this.props.width}>
                    <canvas ref={id} data-id={this.props.dataId} onMouseMove={this.showCanvasToolTip} onMouseLeave={this.removeHighlight} width={this.props.width} height={this.props.rowHeight} ></canvas>
                </td>
            </tr>
        )
    }
}
