import React, { Component } from "react";
import PropTypes from 'prop-types';
import UserDetailofContact from './UserDetailofContact';
import _ from "underscore";
import ScrollBar from "ScrollBar";

export default class UserListofContact extends Component {
    constructor(props) {
        super(props);

        this.getUserDetails = this.getUserDetails.bind(this);
    }

    getUserDetails(data)
    {
        var arrayDetail = [];
        //console.log("Account Manager or Sales representative or Sales Trader details", data);
        _.each(data, (item, index) => {
            if(!Boolean(item.photo))
            {
                item.photo = "/Asset/images/Male.png";
            }

            item.phoneNumber = "";
            
            if(item.phone.length === 10)
            {
                item.phoneNumber = item.phone.substring(0, 3) + "-" + item.phone.substring(3, 6) + "-" + item.phone.substring(6, 11);
            }
            else if(item.phone.length === 12)
            {
                item.phoneNumber = "+" + item.phone.substring(0, 2) + " " + item.phone.substring(2, 6) + "-" + item.phone.substring(6, 12);
            }  
            else if(item.phone.indexOf("-") > -1 || item.phone.indexOf("(") > -1)
            {
                item.phoneNumber = item.phone;
            } 
            else if(item.phone.indexOf("+") > -1)
            {
                item.phoneNumber = "+" + item.phone.substring(1, 3) + " " + item.phone.substring(3, 7) + "-" + item.phone.substring(7, 13);
            }  

            arrayDetail.push(<UserDetailofContact key={index} detail={item}/>)
        })

        return arrayDetail;
    }

    render() {
        const {dataSource} = this.props;
        let classContact = "custom-scroll";
        //console.log("dataSource", Boolean(dataSource) && dataSource.length > 0);
        if(dataSource.length == 1){
            classContact = "custom-scroll item-1"
        }
        if(dataSource.length == 2){
            classContact = "custom-scroll item-2"
        }
        if(dataSource.length == 3){
            classContact = "custom-scroll item-3"
        }
        if(dataSource.length >= 4){
            classContact = "custom-scroll items"
        }
        return (
                Boolean(dataSource) && dataSource.length > 0 ?
                <div className="support-panel person-contact custom-scroll-light">
                    <div className={classContact}>
                        <div className="support-contact-scroll" id="support-contact-scroll">
                            <div className="support-box-collection">
                                {this.getUserDetails(dataSource)}
                            </div>
                        </div>
                        <ScrollBar scrollId="support-contact-scroll" vScroll={true} />
                    </div>
                </div>
                :
                <div></div>
        )
    }
}

UserListofContact.propTypes = {
    dataSource: PropTypes.array.isRequired,
}

UserListofContact.defaultProps = {
    dataSource: []
}