import { EventEmitter } from "events";
import AppDispatcher from "AppDispatcher";
import { FundamentalsConstants } from "../../../../Constants/FundamentalsConstants.js";
import DatagraphStore from "../DataGraphStore.js";
import DateHelper from "DateHelper";
import CurrencyHelper from "CurrencyHelper";
import ExtremeDataValue from "ExtremeDataValue";
import SmartViewType from "SmartViewType";
import SymbolType from "SymbolType";
import ReportedEarningsStore from "../../../Preference/ReportedEarningsStore";
import LocalizationStore from 'LocalizationStore';
import TabFundamentalsStore from "./TabFundamentalsStore.js";
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";
import MiniListHelper from "MiniListHelper";
import StringUtil from "../../../../Utils/StringUtil.js";
import store from "../../../../Redux/store.js";
import { dispatch } from "../../../../Redux/dispatch.js";
import { updateHeaderStates, updateShowEpsDate } from "../../../../Actions/HeaderAction.js";

const CHANGE_EVENT = "change";
let _state = undefined;

class TabFundamentalsHeaderStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
      }
    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    // getCompanyInfo() {
    //     const datagraphSettings = DatagraphStore.getState();
    //     const tabDatagraphSettings = TabDataGraphStore.getState();
    //     const endDate = DateHelper.parseJsonDate(datagraphSettings.SymbolInfo.LastTradeDate, datagraphSettings.SymbolInfo.MomentZoneID);
    //     const nodeCount = datagraphSettings.nodeCount ? datagraphSettings.nodeCount : 500;
    //     const initialBufferSize = this.InitialBufferSize ? this.InitialBufferSize : 50;
    //     const beginDate = DateHelper.calculateBeginDate(endDate, tabDatagraphSettings.periodicity, (nodeCount + initialBufferSize));
    //     const wonOnly = ReportedEarningsStore.getEarningsSettings();
    //     const revWonOnly = ReportedEarningsStore.getRevenueSettings();
    //     const fcQuarters = 0;
    //     const currency = PriceChartStore.getCurrecyCode(datagraphSettings.SymbolInfo.DefaultCurrency);

    //     const findGetSnapShotRequest = {
    //         Symbol: datagraphSettings.Symbol,
    //         beginDate: beginDate,
    //         endDate: endDate,
    //         Osid: datagraphSettings.SymbolInfo.Osid,
    //         SymbolType: datagraphSettings.SymbolInfo.SymTypeEnum,
    //         Periodicity: tabDatagraphSettings.periodicity,
    //         IsWonOnly: wonOnly,
    //         IsRevWonOnly: revWonOnly,
    //         EndDate: endDate,
    //         StartDate: beginDate,
    //         FcQuarters: fcQuarters,
    //         TargetCurrencyCode: currency,
    //         GetEarningLine: false,
    //         GetRevenueLine: false
    //     };
    //     const symbol =  datagraphSettings.SymbolInfo.Symbol;
    //     const isMiniListPlay = datagraphSettings.IsMiniListPlay;
    //     ChartApi.GetSnapshotData(findGetSnapShotRequest).then((result) => {
    //         if(MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)){
    //             AppDispatcher.handleServerAction({
    //                 actionType: FundamentalsConstants.ActionTypes.GET_SNAPSHOT_DATA,
    //                 data: result
    //             });
    //         }
    //     });
    // }

    setCompanyInfo(headerData, SymbolInfo) {
        const ipoState = this.getIPOStateforStockType();
        let epsState = this.getEPSStateforStockType();
        if (headerData && headerData.companyName) {
            const stockInfo = SymbolInfo;
            _state.companyInfo.companyName = headerData.companyName.trim();
            _state.companyInfo.headerData = headerData;
            _state.companyInfo.endDate = DateHelper.parseJsonDate(stockInfo.LastTradeDate);
            /*fixes PANWEB-1482 */
            _state.companyInfo.type = headerData.IndustryGroupName; //stockInfo.Industry197Name;

            // Market Capital Value
            let toCurr;
            const {
                isMatchFinancials
            } = store.getState().currency;
            const targetCurrencyCode = DatagraphStore.targetCurrencyCode();
            const finTargetCurrencyCode = DatagraphStore.finTargetCurrencyCode(SymbolInfo);
            if (StringUtil.isEmpty(targetCurrencyCode)) {
                if (isMatchFinancials && !StringUtil.isEmpty(finTargetCurrencyCode)) {
                    toCurr = finTargetCurrencyCode;
                } else {
                    const defaultCurrency = stockInfo.DefaultCurrency.Code ? stockInfo.DefaultCurrency.Code : stockInfo.DefaultCurrency;
                    toCurr = defaultCurrency == "GBP" ? "GBX" : defaultCurrency == "ZAR" ? "ZAX" : defaultCurrency;
                }
            } else {
                toCurr = targetCurrencyCode;
            }
            let currency = CurrencyHelper.get({ Code: toCurr }, true);
            const marketConversionRate = 1.0;
            const abrivatedValue = ExtremeDataValue.abbreviateValue((headerData.MarketCap * marketConversionRate), 2);
            if (isNaN(headerData.MarketCap) == false && headerData.MarketCap > 0) {
                _state.companyInfo.mktCap = LocalizationStore.getTranslatedData("sym_mktcap", "MktCap ") + currency + abrivatedValue;
                if (headerData.marketCap2 && headerData.marketCap2 > 0)
                    _state.companyInfo.mktCap += " (" + currency + ExtremeDataValue.abbreviateValue(headerData.marketCap2, 2) + ")";
            } else {
                _state.companyInfo.mktCap = "";
            }
            // IPO Date
            _state.companyInfo.ipoDate = DateHelper.parseJsonDate(headerData.IPODate);
            let ipeDateText = DateHelper.IpoDateFormat(_state.companyInfo.ipoDate);
            if (!_state.companyInfo[ipoState]) {
                ipeDateText = DateHelper.IPOCountDownFormat(_state.companyInfo.ipoDate, _state.companyInfo.endDate);
            }
            _state.companyInfo.ipo = ipeDateText;
            // EPS Date
            _state.companyInfo.epsDueDate = DateHelper.parseJsonDate(headerData.EpsDueDate);
            let epsDateText = DateHelper.EpsDueDateFormat(_state.companyInfo.epsDueDate, SymbolType.USSTOCK);
            if (!_state.companyInfo[epsState] && _state.companyInfo.epsDueDate != "") {
                let getDate = new Date();
                if (_state.companyInfo.endDate != null && _state.isHistoric){
                  getDate = _state.companyInfo.endDate;
                }
                let endDate = new Date(getDate.getFullYear(), getDate.getMonth(), getDate.getDate());
                let data = DateHelper.GetData(endDate, SymbolType.USSTOCK, _state.companyInfo.epsDueDate);
                epsDateText = data.DisplayText;
                _state.companyInfo.IsWithinTwoWeeks = data.IsWithinTwoWeeks;
            }
            _state.companyInfo.epsDue = epsDateText;
        }
        
        this.emit(CHANGE_EVENT);
    }
    updateEPSformat(epsformat) {
        if (_state) {
            let epsState = this.getEPSStateforStockType();
            _state.companyInfo[epsState] = epsformat;
            this.emit(CHANGE_EVENT);
        }
    }

    updateIPOformat(ipoformat) {
        if (_state) {
            let ipoState = this.getIPOStateforStockType();
            _state.companyInfo[ipoState] = ipoformat;
            this.emit(CHANGE_EVENT);
        }
    }
    changeEPSFormat() {
        
        let epsState = this.getEPSStateforStockType();
        _state.companyInfo[epsState] = !_state.companyInfo[epsState];
        let epsDateText = DateHelper.EpsDueDateFormat(_state.companyInfo.epsDueDate, SymbolType.USSTOCK);
        if (!_state.companyInfo[epsState]) {
            let getDate = new Date();
            if (_state.companyInfo.endDate != null && _state.isHistoric){
              getDate = _state.companyInfo.endDate;
            }
            let endDate = new Date(getDate.getFullYear(), getDate.getMonth(), getDate.getDate());
            let data = DateHelper.GetData(endDate, SymbolType.USSTOCK, _state.companyInfo.epsDueDate);
            epsDateText = data.DisplayText;
            _state.companyInfo.IsWithinTwoWeeks = data.IsWithinTwoWeeks;
        }
        _state.companyInfo.epsDue = epsDateText;
        dispatch(updateHeaderStates({ showEPSDate: _state.companyInfo[epsState] }))
        this.emit(CHANGE_EVENT);
    }
    changeIPOFormat() {
        
        let ipoState = this.getIPOStateforStockType();
        _state.companyInfo[ipoState] = !_state.companyInfo[ipoState];
        let ipeDateText = DateHelper.IpoDateFormat(_state.companyInfo.ipoDate);
        if (!_state.companyInfo[ipoState]) {
            ipeDateText = DateHelper.IPOCountDownFormat(_state.companyInfo.ipoDate, _state.companyInfo.endDate);
        }
        _state.companyInfo.ipo = ipeDateText;
        dispatch(updateHeaderStates({ showIPODate: _state.companyInfo[ipoState] }))
        this.emit(CHANGE_EVENT);
        TabFundamentalsStore.saveFundamentalSettings('EPSToggleState', _state.companyInfo[ipoState]);
    }
    getEPSStateforStockType() {
        let datagraphState = DatagraphStore.getState();
        switch (datagraphState.SymbolType) {
            case SmartViewType.STOCK:
                return "showEPSDateStock";
            case SmartViewType.CEF:
                return "showEPSDateCEF";
            case SmartViewType.ETF:
                return "showEPSDateETF";
            default:
                break;
        }
    }

    getIPOStateforStockType() {
        let datagraphState = DatagraphStore.getState();
        switch (datagraphState.SymbolType) {
            case SmartViewType.STOCK:
                return "showIPODateStock";
            case SmartViewType.CEF:
                return "showIPODateCEF";
            case SmartViewType.ETF:
                return "showIPODateETF";
            default:
                break;
        }
    }
    getState() {
        if (_state === undefined) {
            this.setDefaultState()
        }
        return _state;
      }

    setDefaultState() {
        let navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
        let fundamentalStockViewSettings = navDataGraphSettings.TabFundamentalsSettings.StockViewSettings;
        _state = {
            companyInfo: {
                companyName: "",
                ipo: "",
                mktCap: "",
                showEPSDateStock: false,
                showIPODateStock: false,
                showEPSDateCEF: false,
                showIPODateCEF: false,
                showIPODateETF: false,
                showEPSDateETF: false,
                epsDue: "",
                type: null,
                IsWithinTwoWeeks: false,
                headerData: null
            },
            isGICSSelected_Fundamentals: fundamentalStockViewSettings.isGICSFlag,
        };
    }
    ClearStateData(){
        if(_state){
            let navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
            let fundamentalStockViewSettings = navDataGraphSettings.TabFundamentalsSettings.StockViewSettings;
            _state["companyInfo"] = {
                companyName: "",
                ipo: "",
                mktCap: "",
                showEPSDateStock: false,
                showIPODateStock: false,
                showEPSDateCEF: false,
                showIPODateCEF: false,
                showIPODateETF: false,
                showEPSDateETF: false,
                epsDue: "",
                type: null,
                IsWithinTwoWeeks: false,
                headerData: null
            };
            _state["isGICSSelected_Fundamentals"] = fundamentalStockViewSettings.isGICSFlag;
        }
    }

    getReportCompanyInfo() {
        return _state.companyInfo;
    }
    getGICSSelected() {
        return _state.isGICSSelected_Fundamentals;
      }
    dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.headerdata;
        const { symbolInfo } = action;
        switch (action.actionType) {
            case FundamentalsConstants.ActionTypes.GET_FUNDAMENTAL_HEADER_DATA:
                this.setDefaultState();
                this.setCompanyInfo(data, symbolInfo);
                break;
            case FundamentalsConstants.ActionTypes.GICSSELECTED_FUNDAMENTALS:
                _state.isGICSSelected_Fundamentals = action.data;
                TabFundamentalsStore.saveFundamentalSettings(action.actionType, action.data);
                this.emit(CHANGE_EVENT);
                break;
            case SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED:
                this.ClearStateData();
                this.emit(CHANGE_EVENT);
                break;
            default:
                break;
        }
    }
}
const tabfundamentalsheaderstore = new TabFundamentalsHeaderStore();
export default tabfundamentalsheaderstore;