import BaseServiceApi from 'BaseServiceApi';
import UserInfoUtil from 'UserInfoUtil';
import AnnotationUtil from '../../Utils/AnnotationUtil';
import DateHelper from '../../Utils/TimeLineHelper/Datehelper';
import ThemeHelper from "ThemeHelper";
import { RayAnnotationType } from '../../Constants/EAnnotationType';

class AnnotationData {
    constructor() {
        const annotationDataProto = BaseServiceApi.rayData["AnnotationData"];
        this.annotationData = new annotationDataProto();
        this.annotationDataProto = BaseServiceApi.rayData["AnnotationPointData"];

        this.setDefaultValue();
    }


    setDefaultValue() {
        this.annotationDatapointData = null;
        this.annotationData.annotationID = 0;
        this.annotationData.userID = 0;
        this.annotationData.annotationType = null;
        this.annotationData.msid = 0;
        this.annotationData.periodicity = null;
        this.annotationData.scale = 0;
        this.annotationData.text = "";
        this.annotationData.textSize = 0;
        this.annotationData.textColor = ""; //#ARGB, stroke color
        this.annotationData.font = "";
        this.annotationData.linkURL = "";
        this.annotationData.foregroundColor = ""; //#ARGB, stroke color
        this.annotationData.backgroundColor = "";
        this.annotationData.penSize = 0;
        this.annotationData.isStartArrow = false;
        this.annotationData.isEndArrow = false;
        this.annotationData.strokeType = null;
        this.annotationData.isStartExtend = false;
        this.annotationData.isEndExtend = false;
        this.annotationData.isBold = false;
        this.annotationData.isItalic = false;
        this.annotationData.isUnderline = false;
        this.annotationData.textAlignment = null;
        this.annotationData.isBorderVisible = false;
        this.annotationData.currencyCode = "";
        this.annotationData.chartDate = 0;
        this.annotationData.isAlert = false;
        this.annotationData.alertID = 0;
        this.annotationData.isSnap = false;
        this.annotationData.createdDate = 0;
        this.annotationData.updatedDate = 0;
        this.annotationData.hasLevelLabel = false;
        this.annotationData.hasPriceLabel = false;
        this.annotationData.layerID = 0;
    }


    // listId":-100012,"actualListId":-100012,"searchText":"","sortCols":"","initialNumRows":90000,"needColumnsInfo":1,"needBrowserCounts":0,"browserFilterText":"-1;-1;-1;","addlCatalogRequests":null,"useColFilters":1,"saveColFilters":0,"customerOrder":0,"colsetId":"-1","igOneilbrowserFilters":"-1;-1;-1;","igGicsbrowserFilters":"-1;-1;-1;-1;","isOneilselected":1,"isGicsselected":0,"isNupview":0}

    setAnnotationData = (annotationData) => {
        this.setDefaultValue();
        return this.prepareAnnotationData(annotationData);
    }

    getAnnotationDataPoints = (data) => {
        const pointsArr = [{ x: data.x, y: data.y }, { x: data.endMouseX, y: data.endMouseY }];

        if (data.type === RayAnnotationType.PARALLEL_LINES_AnnotationType) {
            pointsArr.push({ x: data.midX, y: data.midY })
        }

        if (pointsArr) {
            return pointsArr.map((item, key) => {
                const annotationData = new this.annotationDataProto();
                annotationData.xDate = DateHelper.getUTCTicks(AnnotationUtil.getDateFromXcord(item.x));
                annotationData.yValue = AnnotationUtil.getPriceFromYCord(item.y);
                console.log("annot", key, " - ", AnnotationUtil.getDateFromXcord(item.x), (annotationData.yValue).toFixed(2))
                return annotationData;
            })
        }
    }

    prepareAnnotationData(data) {
        this.annotationData.pointData = this.getAnnotationDataPoints(data);
        this.annotationData.annotationID = data.id && !isNaN(data.id) ? data.id : 0;
        this.annotationData.userID = Number(UserInfoUtil.getUserInfo().userId);
        this.annotationData.annotationType = data.type;
        this.annotationData.msid = AnnotationUtil.getMsid();
        this.annotationData.periodicity = AnnotationUtil.getPeriodicityType();
        this.annotationData.scale = AnnotationUtil.getScaleType();
        this.annotationData.text = data.text ? data.text : '';
        this.annotationData.textSize = data.fontSize ? parseInt(data.fontSize) : data.extensionLineWeight ? data.extensionLineWeight : 0;
        this.annotationData.textColor = data.color ? ThemeHelper.convertHexToArgb(data.color, data.opacity) : "";
        this.annotationData.font = data.fontStyle ? data.fontStyle : '';
        this.annotationData.linkURL = "";
        this.annotationData.foregroundColor = data.borderColor ? ThemeHelper.convertHexToArgb(data.borderColor) : data.color ? ThemeHelper.convertHexToArgb(data.color, data.opacity) : "";
        this.annotationData.backgroundColor = data.bgColor ? ThemeHelper.convertHexToArgb(data.bgColor, data.opacity) : data.extensionLineColor ? ThemeHelper.convertHexToArgb(data.extensionLineColor, data.opacity) : "";
        this.annotationData.penSize = data.weight ? data.weight : data.borderWeight ? data.borderWeight : 1;
        this.annotationData.isStartArrow = data.startArrowChecked ? data.startArrowChecked : false;
        this.annotationData.isEndArrow = data.endArrowChecked ? data.endArrowChecked : false;
        this.annotationData.strokeType = data.strokeStyle ? data.strokeStyle : 1;
        this.annotationData.isStartExtend = data.startExtendChecked ? data.startExtendChecked : false;
        this.annotationData.isEndExtend = data.endExtendChecked ? data.endExtendChecked : false;
        this.annotationData.isBold = data.isBold ? data.isBold : false;
        this.annotationData.isItalic = data.isItalic ? data.isItalic : false;
        this.annotationData.isUnderline = data.isUnderline ? data.isUnderline : false;
        this.annotationData.textAlignment = data.textAlign ? data.textAlign : null;
        this.annotationData.isBorderVisible = data.isBorderChecked ? data.isBorderChecked : data.isExtensionsChecked ? data.isExtensionsChecked : data.isLeaderlinesChecked ? data.isLeaderlinesChecked : false;
        this.annotationData.currencyCode = AnnotationUtil.getTargetCurrencyCode();
        this.annotationData.chartDate = DateHelper.getUTCTicks(AnnotationUtil.endDate);
        this.annotationData.isAlert = data.isAlertChecked ? data.isAlertChecked : false;
        this.annotationData.alertID = 0;
        this.annotationData.isSnap = data.isSnappingChecked ? data.isSnappingChecked : false;
        this.annotationData.createdDate = DateHelper.getUTCTicks(data.createdDate);
        this.annotationData.updatedDate = DateHelper.getUTCTicks(data.updatedDate);
        this.annotationData.hasLevelLabel = data.isLevelLabelsChecked ? data.isLevelLabelsChecked : false;
        this.annotationData.hasPriceLabel = data.isPriceLabelsChecked ? data.isPriceLabelsChecked : false;
        this.annotationData.layerID = data.layerID;
        return this.annotationData;
    }






}

const annotationData = new AnnotationData();
export default annotationData