import ArithmaticScale from "../../../Utils/Scales/BasicArithScale.js";
import DatagraphHelper from "../../../Utils/DatagraphHelper.js";
import DateHelper from "../../../Utils/TimeLineHelper/Datehelper.js";
import { getDatagraphStates } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import GraphType from "GraphType";
import SettingsStore from "SettingsStore";
import SymbolType from 'SymbolType';
import { VolumeChartConstants } from '../../../Constants/VolumeChartConstants';
import { call, put, takeLatest, select } from 'redux-saga/effects';

const { ActionTypes } = VolumeChartConstants;

function* prepareData() {
    try {
        const { endDate, periodicity, pricePanelData, SymbolInfo, nodeWidth, padding, nodeCount, firstNodeIndex, viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const init = periodicity === GraphType.Daily || periodicity === GraphType.Weekly ? 1 : 0;
        const startXPoint = nodeWidth * (nodeCount - init);

        if (!viewsSettings.InsiderBlockSettings || !pricePanelData.CorpEventsData || checkSymbolType(SymbolInfo)) {
            yield put({
                type: VolumeChartConstants.ActionTypes.CLEAR_INSIDER_DATA
            })
        }
        else {
            const scale = new ArithmaticScale();
            const dimension = getDimension();
            const updatedCorpEvents = getUpdatedCorpEvents(pricePanelData.CorpEventsData, SymbolInfo, endDate, periodicity);
            const updatedGraphData = yield call(initInsiderScale, pricePanelData.HsfData.HSFResults, 0, updatedCorpEvents, scale, GraphType.Weekly, SymbolInfo, nodeCount, dimension.height || 1, nodeWidth);
            const InsiderData = getInsiderBars(updatedGraphData, startXPoint, padding, nodeCount, firstNodeIndex, dimension.height || 1, scale, nodeWidth);
            yield put({
                type: ActionTypes.SET_INSIDER_CHART_DATA,
                newState: {
                    Height: dimension.height || 1,
                    InsiderData,
                    Width: dimension.width || 1,
                    scale,
                    isInsiderOpen: viewsSettings.InsiderBlockSettings[majorPeriodicity].IsVisible,
                    InsiderBlockSettings: viewsSettings.InsiderBlockSettings[majorPeriodicity]
                }
            })
        }
    }
    catch (error) {
        console.log(`Error occurs in  prepareData - InsiderChart.js ${error}`);
    }
}

function getUpdatedCorpEvents(corporateEvents, SymbolInfo, endDate, periodicity) {
    try {
        if (corporateEvents !== undefined) {
            corporateEvents.InsiderBuy.forEach((element) => {
                const CorpActionDate = DateHelper.getPSTFromLong(element.CorpActionDate);
                const GraphDate = DateHelper.getPSTFromLong(element.CorpActionDate);
                if (element.CorpActionDate.getFullYear) {
                    element.CorpActionDate = DatagraphHelper.NDateNode(CorpActionDate, false, endDate, periodicity);
                    element.GraphDate = DatagraphHelper.NDateNode(GraphDate, false, endDate, periodicity);
                } else {
                    element.CorpActionDate = DatagraphHelper.NDateNode(DateHelper.parseJsonDate(CorpActionDate, SymbolInfo.MomentZoneID), false, endDate, periodicity);
                    element.GraphDate = DatagraphHelper.NDateNode(DateHelper.parseJsonDate(GraphDate, SymbolInfo.MomentZoneID), false, endDate, periodicity);
                }
            });

            corporateEvents.InsiderSell.forEach((element) => {
                const CorpActionDate = DateHelper.getPSTFromLong(element.CorpActionDate);
                const GraphDate = DateHelper.getPSTFromLong(element.CorpActionDate);
                if (element.CorpActionDate.getFullYear) {
                    element.CorpActionDate = DatagraphHelper.NDateNode(CorpActionDate, false, endDate, periodicity);
                    element.GraphDate = DatagraphHelper.NDateNode(GraphDate, false, endDate, periodicity);
                } else {
                    element.CorpActionDate = DatagraphHelper.NDateNode(DateHelper.parseJsonDate(CorpActionDate, SymbolInfo.MomentZoneID), false, endDate, periodicity);
                    element.GraphDate = DatagraphHelper.NDateNode(DateHelper.parseJsonDate(GraphDate, SymbolInfo.MomentZoneID), false, endDate, periodicity);
                }
            });
        }
        return corporateEvents;

    } catch (error) {
        console.log(`Error occurs in  getUpdatedCorpEvents - InsiderChart.js ${error}`)
    }
}

function getDimension() {
    const tabDatagraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
    return tabDatagraphSettings.insiderChartDimension;
}

function initInsiderScale(graphData, lastNode, corporateEvents, scale, graphType, SymbolInfo, nodeCount, Height, nodeWidth) {
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;
    const insiderBuy = corporateEvents.InsiderBuy;
    const insiderSell = corporateEvents.InsiderSell;
    let bCount = 0;
    let sCount = 0;
    let xAxis = nodeWidth * (nodeCount + 1);
    const length = graphData.length;
    for (let minMaxIndex = lastNode; minMaxIndex < length; minMaxIndex++) {

        if (graphData[minMaxIndex] === null) {
            break;
        }

        const eDate = graphData[minMaxIndex].Date;

        if (eDate.getTime === undefined) {
            return;
        }

        while (insiderBuy.length > bCount && insiderBuy[bCount].GraphDate && insiderBuy[bCount].GraphDate.getTime && insiderBuy.length > bCount && insiderBuy[bCount].GraphDate.getTime() > eDate.getTime()) {
            bCount++;
        }

        if (insiderBuy.length > bCount && insiderBuy[bCount].GraphDate && insiderBuy[bCount].GraphDate.getTime && insiderBuy[bCount].GraphDate.getTime() === eDate.getTime()) {
            const inData = insiderBuy[bCount].CorpActionDisplayValue.split(":");

            graphData[minMaxIndex].insiderBuySell = 1;
            graphData[minMaxIndex].insiderBuyCount = inData[0];
            graphData[minMaxIndex].insiderBuy = inData[1];

            const value = parseInt(inData[1]);

            if (minValue > value) {
                minValue = value;
            }

            if (maxValue < value) {
                maxValue = value;
            }

            bCount++;
        }

        while (insiderSell.length > sCount && insiderSell[sCount].GraphDate && insiderSell[sCount].GraphDate.getTime && insiderSell[sCount].GraphDate.getTime() > eDate.getTime()) {
            sCount++;
        }

        if (insiderSell.length > sCount && insiderSell[sCount].GraphDate && insiderSell[sCount].GraphDate.getTime && insiderSell[sCount].GraphDate.getTime() === eDate.getTime()) {
            const inData = insiderSell[sCount].CorpActionDisplayValue.split(":");

            graphData[minMaxIndex].insiderBuySell = 2;
            graphData[minMaxIndex].insiderSellCount = inData[0];
            graphData[minMaxIndex].insiderSell = inData[1];

            const value = parseInt(inData[1]);

            if (minValue > value) {
                minValue = value;
            }

            if (maxValue < value) {
                maxValue = value;
            }

            sCount++;
        }
        xAxis -= nodeWidth;
        if (xAxis < 0) {
            break;
        }
    }
    //const newMax = Math.sqrt(maxValue);
    //const newMin = Math.sqrt(minValue);
    scale.InitScale(0, Math.sqrt(maxValue), Height / 2, graphType);
    return graphData;
}

function getInsiderBars(hsfResults, xAxis, padding, nodeCount, firstNodeIndex, Height, scale, nodeWidth) {
    const lastnodeIndex = nodeCount;

    let yAxis;
    const chartData = { highPoints: [], lowPoints: [] };
    const lastDataNode = Math.min(hsfResults.length - 1, lastnodeIndex);
    if ((firstNodeIndex + padding) <= lastDataNode) {
        chartData.allPoints = new Array(lastDataNode - (firstNodeIndex + padding));
    }
    else {
        chartData.allPoints = new Array(lastDataNode + 1);
    }
    let index = 0;


    for (let j = firstNodeIndex; j <= lastDataNode; j++) {
        if (!hsfResults[j].IsVisible) {
            xAxis -= nodeWidth;
            continue;
        }
        if (xAxis < 0) {
            break;
        }
        const info = [];

        if (hsfResults[j].insiderBuy) {
            yAxis = scale.ComputeY(Math.sqrt(parseInt(hsfResults[j].insiderBuy)));
            const xinfo =
            {
                Date: hsfResults[j].Date,
                yHigh: yAxis,
                yLow: Height / 2,
                xAxis: xAxis,
                IsVisible: !(hsfResults[j].insiderBuy === undefined),
                UpTick: true,
                InsiderVal: hsfResults[j].insiderBuy,
                InsiderCount: hsfResults[j].insiderBuyCount
            };
            chartData.highPoints.push(xinfo);
            info.push(xinfo);
        }
        if (hsfResults[j].insiderSell) {
            yAxis = scale.ComputeY(Math.sqrt(parseInt(hsfResults[j].insiderSell)));
            const xinfo =
            {
                Date: hsfResults[j].Date,
                yHigh: Height / 2,
                yLow: Height - yAxis,
                xAxis: xAxis,
                IsVisible: !(hsfResults[j].insiderSell === undefined),
                UpTick: false,
                InsiderVal: hsfResults[j].insiderSell,
                InsiderCount: hsfResults[j].insiderSellCount
            };
            chartData.lowPoints.push(xinfo);
            info.push(xinfo);
        }
        xAxis -= nodeWidth;

        chartData.allPoints[index] = info;
        index++;
    }
    return chartData;
}

function* updateInsiderDimension({ dimension }) {
    try {
        const tabDatagraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
        if (tabDatagraphSettings.insiderChartDimension.height !== dimension.height || tabDatagraphSettings.insiderChartDimension.width !== dimension.width) {
            tabDatagraphSettings.insiderChartDimension.height = dimension.height;
            tabDatagraphSettings.insiderChartDimension.width = dimension.width;
            yield call(prepareData)
        }
    }
    catch (error) {
        console.log(`Error occurs in InsiderChart.js, updateInsiderDimension, ${error}`);
    }
}

function checkSymbolType(SymbolInfo) {
    let isEnable = false
    switch (SymbolInfo.SymTypeEnum) {
        case SymbolType.WONINDUSTRYGROUP197:
        case SymbolType.INTERNATIONALINDEX:
        case SymbolType.WONSECTOR11:
        case SymbolType.INDEX:
        case SymbolType.WONMAJORINDUSTRY89:
        case SymbolType.ECONOMICINDICATOR:
        case SymbolType.FUND:
        case SymbolType.FUTURE:
        case SymbolType.COMMODITY:
        case SymbolType.INDEXNOINTRADAYVOL:
        case SymbolType.INDEXNOINTRADAYDATA:
        case SymbolType.INDEXNOINTRADAYVOLDATA:
        case SymbolType.HISTORICINDEX:
        case SymbolType.CURRENCY:
        case SymbolType.EXCHANGERATE:
        case SymbolType.GIC:
        case SymbolType.WONSUBSECTOR33:
        case SymbolType.FUTURECONTRACTS:
        case SymbolType.CASHSPOTS:
        case SymbolType.CRYPTOCURRENCY:
        case SymbolType.CRYPTOCURRENCY:
        case SymbolType.ETF:
        case SymbolType.INTERNATIONALSTOCK:
            isEnable = true;
            break;
        default:
            break;
    }
    return isEnable;
}

/********** WATCHERS **********/
export function* watchInsiderData() {
    yield takeLatest(ActionTypes.PREPARE_VOLUME_INSIDER_DATA, prepareData)
}
export function* watchUpdateInsiderDimension() {
    yield takeLatest(ActionTypes.UPDATE_INSIDER_CHART_DIMENSIONS, updateInsiderDimension)
}