import { dispatch } from '../../../../../../Redux/dispatch';
import { onPlotSymbol } from '../../../../../../Actions/DatagraphActions';
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import ThemeHelper from "ThemeHelper";
import { useSelector } from "react-redux";
import WPFCanvasUtil from "../../../../../../Utils/WPFCanvasUtil";

function SymbolContentControl() {
    const colorText = ThemeHelper.getThemedBrush("fff000");
    const height = 52;
    const data = useSelector((state) => state.summaryReducer.symbolContentData)

    const getSignSymbolHtml = (sign) => {
        const color = colorText;
        return WPFCanvasUtil.convertToSvg(sign, color);
    }
    const onSymbolClick = () => {
        dispatch(onPlotSymbol(data.inverseSymbol))
    }

    const symbolHtml = data && data.currencySignSymbol ? getSignSymbolHtml(data.currencySignSymbol) : SummaryTransLateHelper.SUMMARY_BLOCK_NONE_TEXT;

    return (
        <div className="summary-block-log-hold" style={{ height: height }}>
            <div className="sb-table">
                <div className="sb-table-body">
                    <div className="sb-table-row">
                        <div className="sb-table-cell">{SummaryTransLateHelper.SYMBOLS} <span className="currencySymbols">{ReactHtmlParser(symbolHtml)}</span></div>
                        <div className="sb-table-cell">{SummaryTransLateHelper.CENTRAL_BANK_TEXT} <a className="link" href={data.centralBankWebSite} rel="noreferrer" target="_blank">{data.centralBank}</a></div>
                        <div className="sb-table-cell distance-right-50">{SummaryTransLateHelper.INVERSE_SYMBOL} <a className="link" onClick={onSymbolClick}>{data.inverseSymbol}</a></div>
                    </div>
                </div>
            </div>
            <div className="sb-table">
                <div className="sb-table-body">
                    <div className="sb-table-row">
                        <div className="sb-table-cell">{SummaryTransLateHelper.CURRENCY_NICKNAME} <span>{data.nickNames ? data.nickNames : SummaryTransLateHelper.SUMMARY_BLOCK_NONE_TEXT}</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SymbolContentControl