import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class NotificationSettingsRequest extends BaseServiceRequest {

    constructor() { //osid, fromdate, enddate, code, type
        super();
        super.addRequestUri("setting.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.NOTIFICATION;
    }

    getMessageName() {
        return "NotificationSettingsResponseData";
    }
}

export default NotificationSettingsRequest;
