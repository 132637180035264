import GraphType from "GraphType";
import { NupViewConstant } from "../../../Constants/NupViewConstants";
import BaseServiceApi from 'BaseServiceApi';
let IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];
const { ActionTypes } = NupViewConstant;
const initState = {
    updateTheme: true,
    isResizing: false,
    loading: false,
    listData: [],
    listId: 0,
    redraw: false,
    visibleItems: [],
    settings: null,
    dimension: [{ height: 180, width: 728},
                { height: 174, width: 728},
                { height: 98, width: 728},
                { height: 91, width: 728},
                { height: 223, width: 728},
                { height: 213, width: 728},
                { height: 137, width: 728},
                { height: 130, width: 728},
                { height: 93, width: 728},
                { height: 88, width: 728}],
    chartHeight: 180,
    chartWidth: 728,
    nodeWidth: 4,
    nodeCount:250,
    symbolList: [],
    graphTimer: null,
    IsNASDAQBasic: false,
    selectedIndex: [],
    globalSelectedIndex: -1,
    isScrollAvailable: false,
    RTVolume: false,
    totalCount: 8,
    qouteSymbol: [],
    graphType: 6,
    showChart: false,
    showStory: true,
    isNonImported: false,
    rows: 2,
    cols: 2,
    shouldUpdateComponent: true,
    selected: 1,
    epsSelected: {
        Intraday: true,
        Daily: true,
        Weekly: true,
        Monthly: true,
        Quarterly: true,
        Annual: true
    },
    PeriodicityOptions: {},
    disablePeriodicityButton: true,
    nupCharts: [
        { lyOut: "2 x 2", sel: "icn", per: [], lyUse: "nvc-2X2" },
        { lyOut: "2 x 2", sel: "icn-select", per: [1,2], lyUse: "nvc-2X2" },
        { lyOut: "2 x 3", sel: "icn", per: [], lyUse: "nvc-2X3" },
        { lyOut: "2 x 3", sel: "icn", per: [1,2], lyUse: "nvc-2X3" },
        { lyOut: "3 x 2", sel: "icn", per: [], lyUse: "nvc-3X2" },
        { lyOut: "3 x 2", sel: "icn", per: [1,2,3], lyUse: "nvc-3X2" },
        { lyOut: "3 x 3", sel: "icn", per: [], lyUse: "nvc-3X3" },
        { lyOut: "3 x 3", sel: "icn", per: [1,2,3], lyUse: "nvc-3X3" },
        { lyOut: "4 x 4", sel: "icn", per: [], lyUse: "nvc-4X4" },
        { lyOut: "4 x 4", sel: "icn", per: [1,2,3,12], lyUse: "nvc-4X4" }
    ],
    scaleByPeriodicity: [
        { periodicity: GraphType.Daily, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
        { periodicity: GraphType.Weekly, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
        { periodicity: GraphType.Monthly, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
        { periodicity: GraphType.Quarterly, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
        { periodicity: GraphType.Annual, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
        { periodicity: GraphType.Intraday, scale: "LogScale", scaleLabel: "LOG (A)" }
    ]
};

const NupViewReducer = (state = initState, action)=>{

    switch(action.type){
        case ActionTypes.UPDATE_SHOW_CHART:
            state.showChart = action.showChart;
            return { ...state };
        case ActionTypes.UPDATE_NUP_SETTINGS:
            state.settings = action.settings;
            return state;
        case ActionTypes.UPDATE_SHOW_STORY:
            state.showStory = action.showStory;
            return { ...state };
        case ActionTypes.UPDATE_NUP_TOTAL_COUNT:
            state.totalCount = action.totalCount;
            return { ...state }
        case ActionTypes.UPDATE_NUP_CHART:
            state.nupCharts = [...action.nupCharts];
            return { ...state };
        case ActionTypes.UPDATE_NUP_SCALE_PERIODICY:
            state.scaleByPeriodicity = action.scaleByPeriodicity ;
            return { ...state };
        case ActionTypes.UPDATE_NUP_SELECTED:
            state.selected = action.selected;
            return { ...state };
        case ActionTypes.UPDATE_NUP_SYMBOL_LIST:
            state.symbolList = action.symbolList;
            return { ...state};
        case ActionTypes.UPDATE_NUP_CHART_DIMENSION:
            state.dimension = action.dimension;
            return state;
        case ActionTypes.UPDATE_NUP_CHART_LOADING:
            state.loading = action.loading;
            return { ...state }
        case ActionTypes.NUP_PERIODICITY_CHANGE:
            state.graphType = action.periodicity;
            return { ...state }
        case ActionTypes.NUP_EPS_RPS:
            state.epsSelected = action.epsSelected;
            return { ...state }
        case ActionTypes.UPDATE_PERIODICITY_BUTTON_STATE:
            state.disablePeriodicityButton = action.disablePeriodicityButton;
            return { ...state }
        case ActionTypes.UPDATE_PERIODICITY_OPTIONS:
            state.PeriodicityOptions = action.PeriodicityOptions;
            return { ...state };
        case ActionTypes.UPDATE_COLS_ROWS:
            state.cols = action.rowsCols[0];
            state.rows = action.rowsCols[1];
            return { ...state };
        case ActionTypes.UPDATE_NUP_LIST_DATA:
            state.listData = action.listData;
            if(action.listId)
                state.listId = action.listId;
            return { ...state };
        case ActionTypes.UPDATE_NUP_REDRAW:
            state.redraw = action.redraw;
            return { ...state };
        case ActionTypes.UPDATE_NUP_VISIBLE_ITEM:
            state.visibleItems = action.visibleItems;
            return { ...state };
        case ActionTypes.UPDATE_NUP_ISNASDAQBASIC:
            state.IsNASDAQBasic = action.IsNASDAQBasic === IntradaySourceType.IntradaySource_NASDAQ;
            return { ...state };
        case ActionTypes.UPDATE_SELECTED_INDEX:
            state.selectedIndex = action.selectedIndex;
            return { ...state };
        case ActionTypes.UPDATE_NUP_QUOTE_SYMBOL:
            state.qouteSymbol = action.quoteSymbol;
            return { ...state };
        case ActionTypes.SHOULD_UPDATE_NUP_COMPONENT:
            state.shouldUpdateComponent = action.shouldUpdateComponent;
            return { ...state };
        case ActionTypes.UPDATE_NUP_GLOBAL_SELECTED_INDEX:
            state.globalSelectedIndex = action.globalSelectedIndex;
            return { ...state };
        case ActionTypes.UPDATE_NUP_IS_SCROLL_AVAILABLE:
            state.isScrollAvailable = action.isScrollAvailable;
            return { ...state };
        case ActionTypes.NUP_IS_NON_IMPORTED:
            state.isNonImported = action.isNonImported;
            return { ...state };
        case ActionTypes.UPDATE_NUP_THEME:
            state.updateTheme = !state.updateTheme;
            return { ...state };
        case ActionTypes.UPDATE_NUP_RESIZING:
            state.isResizing = !state.isResizing;
            return { ...state };
        case ActionTypes.UPDATE_NUP_RT_VOLUME: 
            state.RTVolume = action.RTVolume;
            return { ...state }
        case ActionTypes.UPDATE_NUP_NODE_COUNT:
            state.nodeCount = action.nodeCount;
            return { ...state }
        default:
            return state;
    }
};

export default NupViewReducer;