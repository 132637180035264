import PropTypes from 'prop-types';
import React from 'react';
import GraphType from "GraphType";
import PeriodicityHelper from "../../Utils/PeriodicityHelper.js";
import ClickMenu from "ClickMenu";
import LocalizationStore from 'LocalizationStore';
import PortalContainer from 'PortalContainer';

export default class LabelMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            priceMenu: props.priceMenu,
            isTICustomModal: props.isTICustomModal,
            isShowSettingsDialog: props.isShowSettingsDialog ? props.isShowSettingsDialog : false,
            isShowAboutDialog: props.isShowAboutDialog ? props.isShowAboutDialog : false,
            isShowAppearanceDialog: props.isShowAppearanceDialog !== undefined ? props.isShowAppearanceDialog : true,
            listId: props.listId !== undefined ? props.listId : null,
            epsSettings: [],
            visibility: 'collapse',
            y: 0,
            x: 0,
            showToolTip: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            priceMenu: nextProps.priceMenu,
            epsSettings: nextProps.epsSettings
        });
    }

    onSelect(obj) {
        this.props.onSelect(obj);
    }

    handleClickMenu(item) {
        this.props.onClick(item);
    }

    addEPSMenu(epsSettings) {
        //     if (check) {
        let dailySettings = [3];
        dailySettings[0] = 'menuItem';
        dailySettings[1] = 'menuItem';
        dailySettings[2] = 'menuItem';
        let weeklySettings = [4];
        weeklySettings[0] = 'menuItem';
        weeklySettings[1] = 'menuItem';
        weeklySettings[2] = 'menuItem';
        weeklySettings[3] = 'menuItem';
        let monthlySettings = [2];
        monthlySettings[0] = 'menuItem';
        monthlySettings[1] = 'menuItem';
        let quarterlySettings = [2];
        quarterlySettings[0] = 'menuItem';
        quarterlySettings[1] = 'menuItem';
        let annualSettings = [2];
        annualSettings[0] = 'menuItem';
        annualSettings[1] = 'menuItem';


        for (let periodicity in GraphType) {
            let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
            if (epsSettings.hasOwnProperty(majorPeriodicity)) {
                switch (majorPeriodicity) {
                    case GraphType.Daily:
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 1)
                            dailySettings[0] = 'menuItem active';
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 2)
                            dailySettings[1] = 'menuItem active';
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 4)
                            dailySettings[2] = 'menuItem active';
                        break;
                    case GraphType.Weekly:
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 1)
                            weeklySettings[0] = 'menuItem active';
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 2)
                            weeklySettings[1] = 'menuItem active';
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 4)
                            weeklySettings[2] = 'menuItem active';
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 8)
                            weeklySettings[3] = 'menuItem active';
                        break;
                    case GraphType.Monthly:
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 4)
                            monthlySettings[0] = 'menuItem active';
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 8)
                            monthlySettings[1] = 'menuItem active';
                        break;
                    case GraphType.Quarterly:
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 4)
                            quarterlySettings[0] = 'menuItem active';
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 8)
                            quarterlySettings[1] = 'menuItem active';
                        break;
                    case GraphType.Annual:
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 4)
                            annualSettings[0] = 'menuItem active';
                        if (epsSettings[majorPeriodicity].NumOfQuarters == 8)
                            annualSettings[1] = 'menuItem active';
                        break;
                    default:
                        break;
                }
            }
        }
        return (
            <div className="estimate-length">
                <h5 className="head">{LocalizationStore.getTranslatedData("ch_estlength", "Estimate Length")}</h5>
                <h6 className="sub-head">{LocalizationStore.getTranslatedData("ch_epsrps", "(For EPS and RPS)")}</h6>
                <div className="fundamental-Lines-Menu">
                    <div className="header">{LocalizationStore.getTranslatedData("tp_per_dly", "Daily")}</div>
                    <div className="itemsDiv">
                        <ul className="fundamental-Lines-Options">
                            <li className={dailySettings[0]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Daily', qtrs: 1 })}>1</div>
                            </li>
                            <li className={dailySettings[1]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Daily', qtrs: 2 })}>2</div>
                            </li>
                            <li className={dailySettings[2]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Daily', qtrs: 4 })}>4</div>
                            </li>
                        </ul>
                    </div>
                    <div className="quantity">{LocalizationStore.getTranslatedData("ch_qtrs", "qtrs")}</div>
                </div>
                <div className="fundamental-Lines-Menu">
                    <div className="header">{LocalizationStore.getTranslatedData("tp_per_wkly", "Weekly")}</div>
                    <div className="itemsDiv">
                        <ul className="fundamental-Lines-Options">
                            <li className={weeklySettings[0]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Weekly', qtrs: 1 })}>1</div>
                            </li>
                            <li className={weeklySettings[1]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Weekly', qtrs: 2 })}>2</div>
                            </li>
                            <li className={weeklySettings[2]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Weekly', qtrs: 4 })}>4</div>
                            </li>
                            <li className={weeklySettings[3]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Weekly', qtrs: 8 })}>8</div>
                            </li>
                        </ul>
                    </div>
                    <div className="quantity">{LocalizationStore.getTranslatedData("ch_qtrs", "qtrs")}</div>
                </div>
                <div className="fundamental-Lines-Menu">
                    <div className="header">{LocalizationStore.getTranslatedData("tp_per_mnly", "Monthly")}</div>
                    <div className="itemsDiv">
                        <ul className="fundamental-Lines-Options">
                            <li className={monthlySettings[0]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Monthly', qtrs: 4 })}>1</div>
                            </li>
                            <li className={monthlySettings[1]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Monthly', qtrs: 8 })}>2</div>
                            </li>
                        </ul>
                    </div>
                    <div className="quantity">{LocalizationStore.getTranslatedData("ch_yrs", "years")}</div>
                </div>
                <div className="fundamental-Lines-Menu">
                    <div className="header">{LocalizationStore.getTranslatedData("tp_per_qly", "Quarterly")}</div>
                    <div className="itemsDiv">
                        <ul className="fundamental-Lines-Options">
                            <li className={quarterlySettings[0]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Quarterly', qtrs: 4 })}>1</div>
                            </li>
                            <li className={quarterlySettings[1]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Quarterly', qtrs: 8 })}>2</div>
                            </li>
                        </ul>
                    </div>
                    <div className="quantity">{LocalizationStore.getTranslatedData("ch_yrs", "years")}</div>
                </div>
                <div className="fundamental-Lines-Menu">
                    <div className="header">{LocalizationStore.getTranslatedData("tp_per_aly", "Annual")}</div>
                    <div className="itemsDiv">
                        <ul className="fundamental-Lines-Options">
                            <li className={annualSettings[0]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Annual', qtrs: 4 })}>1</div>
                            </li>
                            <li className={annualSettings[1]}>
                                <div onClick={() => this.onSelect({ periodicity: 'Annual', qtrs: 8 })}>2</div>
                            </li>
                        </ul>
                    </div>
                    <div className="quantity">{LocalizationStore.getTranslatedData("ch_yrs", "years")}</div>
                </div>
                <div className="underline-on-hover" onClick={() => this.onSelect({ periodicity: 'All', qtrs: 0 })}>{LocalizationStore.getTranslatedData("resdef", "Restore Defaults")}</div>
                <div className="clearfix"></div>
            </div>);
    }
    onClick() {
        this.setState({
            visibility: 'visible'
        });
    }
    getSubMenuRender(item) {
        if (item.isDefault) {
            item.onSelect = this.props.onClick;
            return;
        }
        return (this.addEPSMenu(item))
    }
    handleMouesOver(e) {
        e.preventDefault();
        if (e.nativeEvent.which === 3) {
            let clientX = e.clientX;
            let clientY = e.clientY;
            this.contextMenuXPos = clientX;
            this.contextMenuYPos = clientY;
            this.props.contextMenuClick(this.props.kpiId);
        }
    }
    mouseEnter(e) {
        this.setState({
            x: window.innerWidth - e.pageX,
            y: e.pageY + 25,
            showToolTip: true
        });
    }
    mouesLeave() {
        this.setState({
            x: 0,
            y: 0,
            showToolTip: false
        });
    }
    render() {
        const isKpi = this.props.isKpiMenu ? this.props.isKpiMenu : false;
        const kpiItem = this.props.item ? this.props.item : null;
        const title = (isKpi && kpiItem.definition) ? kpiItem.definition : '';
        const toolStyle = { right: this.state.x, top: this.state.y };
        return (
            <div id='price' key='1' className={isKpi ? "symbol-chart-title kpi-title" : "symbol-chart-title"}
                onMouseEnter={(e) => { e.target.closest(".symbol-stack-flyout") != null ? e.target.closest(".symbol-stack-flyout").classList.add("open-panel-strip") : '' }}
                onMouseLeave={(e) => { e.target.closest(".symbol-stack-flyout") != null ? e.target.closest(".symbol-stack-flyout").classList.remove("open-panel-strip") : '' }}
                onClick={(e) => { e.target.closest(".symbol-stack-flyout") != null ? e.target.closest(".symbol-stack-flyout").classList.remove("open-panel-strip") : '' }}
                data-disable-track-price="true">
                <pre className="text" onMouseEnter={(e) => { this.mouseEnter(e) }} onMouseLeave={() => { this.mouesLeave() }}>{this.props.label}
                    {((this.state.label.length > 13 || isKpi) && this.state.showToolTip) ? <PortalContainer><span style={toolStyle} className="tooltip tipsy yellow">{isKpi ? title : this.props.label}</span></PortalContainer> : ""}
                    {/* {this.state.priceMenu && this.state.priceMenu.length > 0 && */}
                    <ClickMenu hideOnItemClick={false}
                        data-disable-track-price="true"
                        items={this.state.priceMenu}
                        isTICustomModal={this.state.isTICustomModal}
                        isShowSettingsDialog={this.state.isShowSettingsDialog}
                        isShowAboutDialog={this.state.isShowAboutDialog}
                        selectedTIChart={this.state.label}
                        isShowAppearanceDialog={this.state.isShowAppearanceDialog}
                        listId={this.state.listId}
                        button="left"
                        subMenuItemRender={(item) => this.getSubMenuRender(item)}
                        alignment="right"
                        onselect={(item) => this.handleClickMenu(item)}
                        isKpiMenu={isKpi}
                        item={kpiItem}
                    />
                    {/* } */}
                </pre>
            </div>
        );
    }
}
LabelMenu.proptypes = {
    label: PropTypes.string.isRequired,
    priceMenu: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    epsSettings: PropTypes.array,
    onSelect: PropTypes.func,
};