const ErrorCodes = {
  SESSION_INVALID_ERROR: 102,
  PASSWORD_CHANGE_ERROR: 10401,
  SQL_TIMED_OUT: 10201,
  SOCKET_EXCEPTION_ERR_DB: 10202,
  SOCKET_EXCEPTION_ERR_SVC: 10301,
  INVALID_PARAMETER: 10101,
  EXPIRED_CACHE_ID: 30111,
  LIST_NOT_EXISTED: 30102,
  SHARED_PERMISSION_REMOVED: 40104,
  SHARED_PERMISSION_CHANGEDTO_READONLY: 40103,
  MAX_ALERTS_REACHED: 50001,
  ALERTS_EXCEPTION: 50002,
  INSTRUMENT_ALERTS_REACHED: 50003,
  COLSET_NOT_EXISTED: 30201,
  CUSTOMCOL_DELETE: 30400,
  LIST_CUSTOMCALCUPSERT_FAILED: 70000,
  LIST_CUSTOMCALC_FAILED: 70001,
};
export default ErrorCodes;
