import GraphType from "../../../../../Constants/GraphType.js";
import Serializable from "Serializable";
class CorporateEventsSettings extends Serializable {

    onSeralisedInfoRecieved(info) {
        Object.assign(this, info);
    }
    getDataObject() {
        return Object.assign({}, this);
    }

    getObjectMapKey() {
        return "CorporateEventsSettings";
    }
    constructor(periodicity, IsETForCEF = false) {
        super();
        this.IsAvailable = false;
        this.IsVisible = true;
        this.Height = 50;
        switch (periodicity) {
            case GraphType.Daily:
                this.IsAvailable = true;
                this.Split = true;
                this.Earnings = !IsETForCEF;
                this.Announcement = true;
                this.Ceo = !IsETForCEF;
                this.CoCeo = !IsETForCEF;
                this.CorporateRepurchase = true;
                this.Dividend = true;
                this.MajorArticles = !IsETForCEF;
                this.AddedToIndex = !IsETForCEF;
                this.SecurityAnalystMeeting = !IsETForCEF;
                // this.ExchangeListing = true;
                this.InsiderBuy = true;
                this.InsiderSell = true;
                this.RepurchaseAnnouncement = true;
                this.IsCollapsed = false;
                break;
            case GraphType.Weekly:
                this.IsAvailable = true;
                this.Split = true;
                this.Earnings = false;
                this.Announcement = true;
                this.Ceo = !IsETForCEF;
                this.CoCeo = !IsETForCEF;
                this.CorporateRepurchase = true;
                this.Dividend = true;
                this.MajorArticles = !IsETForCEF;
                this.AddedToIndex = !IsETForCEF;
                this.SecurityAnalystMeeting = !IsETForCEF;
                // this.ExchangeListing = true;
                this.InsiderBuy = true;
                this.InsiderSell = true;
                this.RepurchaseAnnouncement = true;
                this.IsCollapsed = false;
                break;
            case GraphType.Monthly:
                this.IsAvailable = true;
                this.Split = true;
                this.Earnings = false;
                this.Announcement = true;
                this.Ceo = !IsETForCEF;
                this.CoCeo = !IsETForCEF;
                this.CorporateRepurchase = IsETForCEF;
                this.Dividend = IsETForCEF;
                this.MajorArticles = false;
                this.AddedToIndex = true;
                this.SecurityAnalystMeeting = !IsETForCEF;
                // this.ExchangeListing = true;
                this.InsiderBuy = true;
                this.InsiderSell = true;
                this.RepurchaseAnnouncement = IsETForCEF;
                this.IsCollapsed = false;
                break;
            case GraphType.Annual:
                this.IsAvailable = true;
                this.Split = true;
                this.Earnings = false;
                this.Announcement = true;
                this.Ceo = !IsETForCEF;
                this.CoCeo = !IsETForCEF;
                this.CorporateRepurchase = IsETForCEF;
                this.Dividend = IsETForCEF;
                this.MajorArticles = false;
                this.AddedToIndex = false;
                this.SecurityAnalystMeeting = false;
                // this.ExchangeListing = IsETForCEF;
                this.InsiderBuy = false;
                this.InsiderSell = false;
                this.RepurchaseAnnouncement = IsETForCEF;
                this.IsCollapsed = false;
                break;
            case GraphType.Quarterly:
                this.IsAvailable = true;
                this.Split = true;
                this.Earnings = false;
                this.Announcement = true;
                this.Ceo = !IsETForCEF;
                this.CoCeo = !IsETForCEF;
                this.CorporateRepurchase = IsETForCEF;
                this.Dividend = IsETForCEF;
                this.MajorArticles = false;
                this.AddedToIndex = false;
                this.SecurityAnalystMeeting = false;
                // this.ExchangeListing = IsETForCEF;
                this.InsiderBuy = false;
                this.InsiderSell = false;
                this.RepurchaseAnnouncement = IsETForCEF;
                this.IsCollapsed = false;
                break;
            default:
                this.IsAvailable = false;
                this.IsCollapsed = false;
        }
    }
}

export default CorporateEventsSettings;
