import React from "react";
import { find, findIndex } from "underscore";
import StringUtil from "StringUtil";
import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";

export default class TabOwneshipHeaderRow extends React.Component {

    onColumnHeaderClicked(event, index) {
        this.props.onColumnHeaderClicked(event, index);
    }

    renderHtmlForColumnSortOrder(columnName) {
        const obj = find(this.props.orderSeqArr, (obj) => obj.columnName === columnName);
        const index = findIndex(this.props.orderSeqArr, { columnName: columnName });
        let str = "";

        if (StringUtil.isEmpty(obj))
            str = "";
        else if (obj.order === "asc")
            str = <div className="order-box"><span className='order-icon glyphicon glyphicon-triangle-top'></span><span className='order-counter'>{index + 1}</span></div>;
        else
            str = <div className="order-box"><span className='order-icon glyphicon glyphicon-triangle-bottom'></span><span className='order-counter'>{index + 1}</span></div>;

        return str;
    }


    render() {
        const { props } = this;
            const c1SortText = props.isColumnRankChanged === true ? this.renderHtmlForColumnSortOrder("FundPerformanceRating") : this.renderHtmlForColumnSortOrder("PercentageChgYTD");
            const c2SortText = this.renderHtmlForColumnSortOrder("SponsorNameAbbr");
            const c3SortText = props.isColumnPortChanged === true ? this.renderHtmlForColumnSortOrder("PctOfPort") : this.renderHtmlForColumnSortOrder("RatingRank");
            const c4SortText = props.isColumnSharesChanged === true ? this.renderHtmlForColumnSortOrder("SharesHeld") : this.renderHtmlForColumnSortOrder("SharesHeld - Shares1QAgo");
            const c5SortText = props.isColumnSharesChanged === true ? this.renderHtmlForColumnSortOrder("Shares1QAgo") : this.renderHtmlForColumnSortOrder("Shares1QAgo - Shares2QAgo");
            const c6SortText = props.isColumnSharesChanged === true ? this.renderHtmlForColumnSortOrder("Shares2QAgo") : this.renderHtmlForColumnSortOrder("Shares2QAgo - Shares3QAgo");
            return (
                <div key={`header_${props.i}`} className="report-sector">
                    <div className="report-heading">
                        {props.currentTab === 4 && props.currentSubTab === 0 ? props.value.reportName : props.value.reportName + ExtremeDataValue.numberWithCommas(props.value.count)}
                    </div>
                    <div className="report-table">
                        <div className="report-header-group">
                            <div className="report-row">
                                <div className={`report-head ownership-rank${c1SortText === '' ? '' : ' sorted'}`} onClick={(event) => this.onColumnHeaderClicked(event, 1)}>{props.isColumnRankChanged === true ? "Rank" : "YTD"}
                                    {c1SortText}
                                </div>
                                <div className={`report-head ownership-name${c2SortText === '' ? '' : ' sorted'}`} onClick={(event) => this.onColumnHeaderClicked(event, 2)}><div className="report-naminghead">Name</div>
                                    {c2SortText}
                                </div>
                                <div className={`report-head ownership-port${c3SortText === '' ? '' : ' sorted'}`} onClick={(event) => this.onColumnHeaderClicked(event, 3)}>{props.isColumnPortChanged === true ? "% of Port" : "Holdings Rank"}
                                    {c3SortText}
                                </div>
                                <div className={`report-head partation ownership-recent${c4SortText === '' ? '' : ' sorted'}`} onClick={(event) => this.onColumnHeaderClicked(event, 4)}>Recent
                                {c4SortText}
                                </div>
                                <div className={`report-head ownership-shares${c5SortText === '' ? '' : ' sorted'}`} onClick={(event) => this.onColumnHeaderClicked(event, 5)}>
                                    <div>{props.isColumnSharesChanged === true ? LocalizationStore.getTranslatedData("ri_id104_02", "Shares (000)") : LocalizationStore.getTranslatedData("OS_Shares3", "∆Shares (000)")} </div>
                                    <div> 1 Qtr Ago</div>
                                    {c5SortText}
                                </div>
                                <div className={`report-head ownership-qtr${c6SortText === '' ? '' : ' sorted'}`} onClick={(event) => this.onColumnHeaderClicked(event, 6)}>2 Qtrs Ago
                                    {c6SortText}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ); 
    }
}