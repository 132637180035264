import BarVisual from "BarVisual";
import BlockType from "../../../../../../Constants/BlockType.js";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { DatagraphConst } from "../../../../../../Utils/DatagraphHelper.js";
import HorizontalLineVisual from "HorizontalLineVisual";
import LabelMenu from "LabelMenu";
import LineQtrsVisual from "LineQtrsVisual";
import LineVisual from "LineVisual";
import RayChart from "RayChart";
import ThemeHelper from "ThemeHelper";
import {  TranslateHelper } from "../../../../../../Utils/TranslateHelper.js";
import { openCloseIndicatorBlock, prepapreYTDIndicatorData, toggleIndicatorChart } from "../../../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions.js";
import React, { useEffect, useRef } from "react";
import { updateChartRefs, updateContextHit } from "../../../../../../Actions/PricePanelActions.js";

function FundamentalIndicator (props) {
  const MainChart = useRef(null);
  useEffect(() => {
    props.updateChartRefs(MainChart, props.type);
  }, []);

  function updateContextHit(lineKey){
    if(props.type === BlockType.TechIndicator || 
        props.type === BlockType.EPSR || 
        (props.type === BlockType.PTOE || props.type === BlockType.PTOS) && 
        lineKey === `${props.type}IndexLine`){
        
      props.updateContextHit({ lineID: props.type, lineKey })
    }
    else{
      props.updateContextHit({ lineID: props.type })
    }
  }
  const backgroundColor = ThemeHelper.getThemedBrush("000fff");
    return (
      <div className="chartWrapper symbol-chart-wrap">
        <RayChart ref={MainChart} scale={props.scale}
          nodeWidth={DatagraphConst.nodeWidth} isTI={true}
          leftScaleWidth={DatagraphConst.leftScaleWidth}
          righscaleWidth={DatagraphConst.rightScaleWidth}
          TimeLine={props.TimeLine} 
          trackpriceReqArgs= {{chartType: props.type}}>
          {props.nothingToDisplay &&
            <div className="content" style={{ zIndex: "0", background: backgroundColor }}>
              <div className="dataGraph-message">{TranslateHelper["Data_Not_Available"]}
              </div>
            </div>
          }
          {
            props.indicatorVisuals.map((item) => {
              if (item.isQtrVisual) {
                return <LineQtrsVisual key={item.key} {...item} />
              }
              else if (item.isBarVisual) {
                return <BarVisual key={item.key} {...item} />
              }
              else if (item.isHorizontalLine) {
                return <HorizontalLineVisual key={item.key} {...item} isToolTip={props.showToolTip}  updateContextHit={()=> updateContextHit(item.key)} />
              }
              else {
                return <LineVisual key={item.key} {...item}  isToolTip={props.showToolTip} updateContextHit={()=> updateContextHit(item.key)} />
              }
            })
          }
        </RayChart>
        <Button data-disable-track-price="true"
          onClick={props.onCollapseBlock.bind(this, props.pType, props.type, false)}
          bsSize="xsmall" className="collapse-block">
          <div className="collapse-btn"></div>
        </Button>
        <div className="chart-panel-right-content">
          <LabelMenu {...props.indicatorLabels.LabelMenu} />
          {props.indicatorLabels.isType && <div className={props.indicatorLabels.class}>
            <span className="text" id='type' key='2' data-disable-track-price="true" onClick={props.toggleFundamentalChart} style={{
              cursor: props.indicatorLabels.cursor,
              pointerEvents: props.indicatorLabels.pointerEvents,
              borderBottom: props.indicatorLabels.borderBottom
            }}>{props.indicatorLabels.scaleText}</span>
          </div>}
          {props.indicatorLabels.isScale && <div className={props.indicatorLabels.class}><span className="text" onClick={props.toggleYTDChart} style={{
              cursor: props.indicatorLabels.cursor,
              pointerEvents: props.indicatorLabels.pointerEvents,
              borderBottom: props.indicatorLabels.borderBottom
            }}>{props.indicatorLabels.scaleText}</span></div>}
          {props.indicatorLabels.isTechRating &&
            <div className="tech-rating">
              <span className="tech-rating-row">{props.indicatorLabels.techText}</span>
              <span className="tech-rating-row rating-digit">{props.indicatorLabels.rank}</span>
              <div className="clearfix"></div>
            </div>
          }
          {props.indicatorLabels.labels.length > 0 && <div className="chart-legend">
            <ul>
              {
                props.indicatorLabels.labels.map((item) => (
                  <li key={item.key}>
                    <span className="chart-legend-rectangle" style={{ backgroundColor: item.backgroundColor, color: item.color }}>o</span>
                    <span className="chart-legend-label">{item.value}</span>
                  </li>
                ))
              }
            </ul>
          </div>}
        </div>
      </div>
    );
}
const mapStateToProps = ({ DatagraphReducers }, props) => {
  const state = {}
  const FReducer = DatagraphReducers.IndicatorReducer;
  const { showToolTip } = DatagraphReducers.DataGraphReducer;
  const TReducer = DatagraphReducers.TimeLineReducer;
  state.scale = FReducer.scale[props.type];
  state.SettingDict = FReducer.SettingDict[props.type];
  state.nothingToDisplay = FReducer.indicatorVisuals[props.type].length === 0;
  state.TimeLine = TReducer.TimeLineData.dates;
  state.indicatorVisuals = FReducer.indicatorVisuals[props.type];
  state.indicatorLabels = FReducer.indicatorLabels[props.type];
  return { ...state, showToolTip };
}

const mapDispatchToProps = (dispatch, props) => ({
  toggleFundamentalChart: () => dispatch(toggleIndicatorChart(props.type)),
  toggleYTDChart: () => dispatch(prepapreYTDIndicatorData(props.type)),
  onCollapseBlock: (pblockType, blockType, isOpen, item, listId) => dispatch(openCloseIndicatorBlock(pblockType, blockType, isOpen, item, listId)),
  updateChartRefs: (chart, chartType)=> dispatch(updateChartRefs(chart, chartType)),
  updateContextHit: (contextMenuObj)=> dispatch(updateContextHit(contextMenuObj)),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef : true})(FundamentalIndicator)