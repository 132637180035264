import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";

import { ShareDialogConstants, ShareActionConstants } from "../../Constants/ShareConstants"

const CHANGE_EVENT = "change";
let  managedAllData_Initial= {
    externalMD:{
        nodeData: undefined,
        sort: 'name',
        sortReversed: true
    },
    listMD:{
        nodeData: undefined,
        sort: 'name',
        sortReversed: true
    },
    columnSetMD:{
        nodeData: undefined,
        sort: 'name',
        sortReversed: true
    },
    annotationsMD:{
        nodeData: undefined,
        sort: 'name',
        sortReversed: true
    },
    recipientsMD:{
        nodeData: undefined,
        sort: 'recipientName',
        sortReversed: true
    }
}
let managedAllData=JSON.parse(JSON.stringify(managedAllData_Initial))
let _state = {
    sharedData: [],
    managedData: {
        nodeData: undefined,
        sort: 'name',
        sortReversed: true
    },
    targetData: undefined,
    userList: undefined,
    showSharedDialog: false,
    dialog_Type: 'Shared',
    sharedAction: undefined,
    showUsers: false,
    nameFilter: '',
    listType: undefined,
    savedSharedData: undefined,
    showConfirmation: false,
    showInProgress: false
};

class ShareStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getState() {
        return _state;
    }

    dispatcherCallback(payload) {
        const action = payload.action;

        switch (action.actionType) {
            case ShareDialogConstants.SHOW_SHARED_DIALOG:
                const shareData = action.data.data;
                _state.showSharedDialog = shareData.showSharedDialog;
                _state.sharedData = action.data.sharedUsers;
                switch (shareData.action) {
                    case ShareActionConstants.SHARE_LIST:
                        _state.targetData = {
                            id: shareData.id,
                            name: shareData.name,
                            ntid: shareData.ntid,
                            listType: 1
                        };
                        _state.listType = 1;
                        break;
                    case ShareActionConstants.SHARE_COLUMN_SET:
                        _state.targetData = {
                            id: shareData.id,
                            name: shareData.name,
                            ntid: shareData.ntid,
                            listType: 4
                        };
                        _state.listType = 4;
                        break;
                    case ShareActionConstants.SHARE_ANNOTATIONS:
                        _state.targetData = {
                            id: shareData.id,
                            name: shareData.name,
                            ntid: shareData.ntid,
                            listType: 5
                        };
                        _state.listType = 5;
                        break;
                    case ShareActionConstants.SHARE_RECIPIENTS:
                        _state.targetData = {
                            id: shareData.id,
                            name: shareData.name,
                            ntid: '',
                            listType: 3
                        };
                        _state.listType = 3;
                        break;
                    case ShareActionConstants.SHARE_EXTERNAL_DATA:
                        _state.targetData = {
                            id: shareData.id,
                            name: shareData.name,
                            ntid: shareData.ntid,
                            listType: shareData.ntid
                        };
                        _state.listType = 1;
                        break;
                    default:
                        break;
                };
                _state.sharedAction = shareData.action;
                _state.savedSharedData = action.data.sharedUsers;
                _state.dialog_Type = 'Shared';
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.UPDATE_DIALOG_TYPE:
                _state.dialog_Type = action.data.dialogType;
                switch(action.data.sharedAction){
                    case ShareActionConstants.SHARE_EXTERNAL_DATA:
                        _state.managedData = managedAllData.externalMD;
                        break;
                    case ShareActionConstants.SHARE_LIST:
                        _state.managedData = managedAllData.listMD;
                        break;
                    case ShareActionConstants.SHARE_COLUMN_SET:
                        _state.managedData = managedAllData.columnSetMD;
                        break;
                    case ShareActionConstants.SHARE_ANNOTATIONS:
                        _state.managedData = managedAllData.annotationsMD;
                        break;
                    case ShareActionConstants.SHARE_RECIPIENTS:
                        _state.managedData = managedAllData.recipientsMD;
                        break;
                    default:
                        break;
                }
                _state.managedData.nodeData = action.data.sharedData;
                _state.sharedAction = action.data.sharedAction;
                _state.showConfirmation = false;
                _state.showInProgress = false;
                _state.showSharedDialog = true;
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.UPDATE_USERS:
                _state.userList = action.data;
                _state.showUsers = true;
                _state.nameFilter = '';
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.UPDATE_SHOW_USER_FLAG:
                _state.showUsers = action.data.isShowUsers;
                _state.savedSharedData = action.data.sharedData;
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.UPDATE_NAME:
                _state.nameFilter = action.data;
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.UPDATE_SHARED_DATA:
                _state.sharedData = action.data;
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.CLEAR_SHARE_DATA:
                _state.sharedData = [];
                managedAllData=JSON.parse(JSON.stringify(managedAllData_Initial))
                _state.targetData = undefined;
                _state.userList = undefined;
                _state.showSharedDialog = false;
                _state.dialog_Type = 'Shared';
                _state.sharedAction = undefined;
                _state.showUsers = false;
                _state.nameFilter = '';
                _state.listType = undefined;
                _state.savedSharedData = undefined;
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.SORT_MANAGED_DATA:
                _state.managedData.sort = action.data.sortName;
                _state.managedData.sortReversed = action.data.isSortReversed;
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.UPDAT_CONFIRMATION_FLAG:
                _state.showConfirmation = action.data;
                this.emit(CHANGE_EVENT);
                break;
            case ShareDialogConstants.SHOW_IN_PROGRESS:
                _state.showInProgress = action.data;
                this.emit(CHANGE_EVENT);
                break;            
            default:
                break;
        }
        return true;
    }
}

const shareStore = new ShareStore();
export default shareStore;