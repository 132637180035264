import BlockType from "../../../../../../Constants/BlockType.js";
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import DGContextMenu from "../../../../../../RayCustomControls/ContextMenu/DGContextMenu.jsx";
import { dispatch } from "../../../../../../Redux/dispatch";
import KpiInfoName from "./KpiInfoName";
import LabelMenu from "LabelMenu";
import Measure from "react-measure";
import { openCloseIndicatorBlock } from "../../../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions.js";
import { openCustomKPIModal } from "../../../../../../Actions/CustomTIModalActions";
import PortalContainer from 'PortalContainer';
import RayChart from "RayChart";
import React from "react";
import TabDataGraphStore from "TabDataGraphStore";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { updateChartRefs } from "../../../../../../Actions/PricePanelActions.js";
import { OnCloseContextMenu, onContextMenuClick, onLineMouseLeave, onLineMouseOver } from '../../../../../../Actions/KpiGraphActions';

class KpiIndicator extends React.Component {
    constructor() {
        super();
        this.MainChart = React.createRef();
        this.closeContextMenu = this.closeContextMenu.bind(this);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
    }
    componentDidMount() {
        if (this.MainChart) {
            this.props.updateChartRefs(this.MainChart, `${BlockType.KPI}-${this.props.kpiId}`);
        }
    }

    collapseBlock(item) {
        this.props.onCollapseBlock(BlockType.KPI, BlockType.KeyPerfomanceIndicator, false, item);
        //TabDataGraphActionClass.onCollapseKpiBlock(item);
    }
    closeContextMenu = () => {
        this.props.onContextMenuClose();
    }
    onContextMenuClick = () => {
        dispatch(openCustomKPIModal({ isShowModal: true, selectedItem: this.props.item }));
        this.props.onContextMenuClose();
    }
    prepareLabels() {
        return (
            <div className="chart-panel-right-content">
                <LabelMenu label={this.props.item.displayName} isKpiMenu={true} item={this.props.item} isTICustomModal={true} position="initial" commonStock='false' />
            </div>
        )
    }
    handleMouseOver(e) {
        this.props.onLineMouseOver(this.props.kpiId);
        e.preventDefault();
        if (!this.main) {
            return;
        }
        const dimensions = this.main.getBoundingClientRect();
        const chartWidth = dimensions.right;
        const chartHeight = dimensions.bottom;
        const xAxis = (chartWidth - e.clientX) < 90 ? e.clientX - 90 : e.clientX;
        const yAxis = (chartHeight - e.clientY) < 20 ? e.clientY - 40 : e.clientY;
        this.lineTitle = this.props.menuName;
        this.screenX = xAxis - dimensions.left + 10;
        this.screenY = yAxis - dimensions.top + 10;
        this.props.onLineMouseOver(this.props.kpiId, this.screenX, this.screenY);
    }
    handleMouseLeave(e) {
        this.props.onLineMouseLeave();
        e.preventDefault();
    }
    handleClick(e) {
        e.preventDefault();
        if (e.nativeEvent.which === 3) {
            const clientX = e.clientX;
            const clientY = e.clientY;
            this.contextMenuXPos = clientX;
            this.contextMenuYPos = clientY;
            this.props.contextMenuClick(this.props.kpiId);
            // dispatch(openCustomKPIModal({ isShowModal: true, selectedItem: this.props.item }));
        }
    }
    showInfoBox(showText, screenX, screenY, backgroundColor) {
        backgroundColor = ThemeHelper.getThemedBrush(backgroundColor);
        const xLeft = `${screenX}px`;
        const ytop = `${screenY}px`;
        const height = "20px";
        const width = "auto";
        const lineFontColor = ThemeHelper.getFontColor(backgroundColor);
        const textStyle = {
            backgroundColor: backgroundColor,
            borderColor: "#333333",
            borderStyle: "solid",
            borderWidth: "1px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            fontFamily: "calibri",
            color: lineFontColor,
            position: "absolute",
            left: xLeft,
            top: ytop,
            height: height,
            width: width,
            zIndex: 20
        };
        return (
            <div style={textStyle} key={screenX}>
                <div style={{
                    textAlign: "Center",
                    fontFamily: "calibri",
                    color: lineFontColor,
                    marginLeft: '6px',
                    marginRight: '6px',
                }}>
                    {showText}
                </div>
            </div>
        );
    }
    getMainSvg(style) {
        const lineStyle = {
            fitPosition: "fill",
            position: "absolute",
            left: "0px",
            top: "0px",
            zIndex: 999,
            pointerEvents: "none",
            opacity: 1,
            strokeWidth: style.weight
        };

        const colorInfo = ThemeHelper.getThemedBrush(style.color);
        if (this.props.dataPoints.solidPathData !== '') {
            return (<svg style={lineStyle} id="RSLayer" ref={(ref) => (this.main = ref)}
                height='100%'
                width='100%'>
                {this.getPath(this.props.dataPoints.solidPathData, undefined, colorInfo)}
                {this.getPath(this.props.dataPoints.points, lineStyle, colorInfo)}
            </svg>);
        } else {
            return (<div>Data not in View</div>);
        }
    }
    getPath(pathData, dashValue = 0, fill = "none") {
        return (<g>
            <path d={pathData} data-disable-track-price="true"
                ref={(ref) => (this.line = ref)}
                stroke={fill}
                strokeDasharray={dashValue}
                fill={fill}
                cursor="default"
                pointerEvents="none"
            />
            <path d={pathData} data-disable-track-price="true"
                id={`kpi${this.props.KpiId}`}
                strokeWidth="5px"
                stroke="transparent"
                onMouseOver={this.handleMouseOver.bind(this)}
                onFocus={this.handleMouseOver.bind(this)}
                onMouseLeave={this.handleMouseLeave.bind(this)}
                onBlur={this.handleMouseLeave.bind(this)}
                onContextMenu={this.handleClick.bind(this)}
                fill="none"
                cursor="default"
                pointerEvents="visible"
            >
            </path>
        </g>);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!TabDataGraphStore.isThemeSettingChanged() && (nextProps.kpiId === this.props.kpiId && nextProps.TimeLine[0] !== this.props.TimeLine[0]) && nextState === this.state && nextProps.item === this.props.item && nextProps.kpiId === this.props.kpiId && nextProps.menuName === this.props.menuName) {
            return false;
        }
        return true;
    }
    render() {
        TimeTrackingWindow.trackChartLoadingTime();
        const backgroundColor = ThemeHelper.getThemedBrush("000fff");
        const contextMenu = [];
        if (!this.props.isKpiMenuAvailable || !this.props.dataPoints) {
            return (<div className="chartWrapper symbol-chart-wrap">
                <div className="ray-stuff-container">
                    <div className="ray-stuff-holder">
                        <div className="message">
                            {this.props.isKpiMenuAvailable}{this.props.dataPoints}Loading...
                        </div>
                    </div>
                </div>
            </div>);
        }
        contextMenu.push({
            headerText: "Appearance",
            isEnabled: true
        });
        const colorInfo = this.props.Kpisettings.KpiColor[0].color;
        return (
            <div className="chartWrapper symbol-chart-wrap">
                <RayChart ref={this.MainChart} scale={this.props.dataPoints.scale}
                    nodeWidth={this.props.nodeWidth} isTI={true} isKpi={true}
                    leftScaleWidth={this.props.leftScaleWidth}
                    righscaleWidth={this.props.rightScaleWidth}
                    TimeLine={this.props.TimeLine}
                    trackpriceReqArgs={{ chartType: BlockType.KPI, kpiId: this.props.kpiId, header: this.props.menuName }}
                >
                    {this.props.dataPoints.solidPathData !== '' && <Measure>
                        {({ measureRef }) =>
                            <div ref={measureRef}>
                                {this.getMainSvg(this.props.Kpisettings.KpiColor[0])}
                            </div>
                        }
                    </Measure>}
                    {this.props.dataPoints.solidPathData === '' && <div className="content" style={{ zIndex: "0", background: backgroundColor }}>
                        <div className="dataGraph-message">
                            Data not in View
                        </div>
                    </div>}
                </RayChart>
                {<KpiInfoName kpiId={this.props.kpiId} showText={this.props.menuName} colorInfo={colorInfo} />}
                {/* {!this.props.showContextMenu && (this.props.mouseOverId == this.props.kpiId) &&  this.props.showBox && this.showInfoBox(this.props.menuName, this.screenX, this.screenY, colorInfo)} */}
                <Button onClick={() => this.collapseBlock(this.props.item)} data-disable-track-price="true" bsSize="xsmall" className="collapse-block"><div className="collapse-btn"></div></Button>
                {this.prepareLabels()}
                {(this.props.contextMenuId === this.props.kpiId) && this.props.showContextMenu && <PortalContainer>
                    <DGContextMenu contextMenu={contextMenu}
                        contextMenuXPos={this.contextMenuXPos}
                        contextMenuYPos={this.contextMenuYPos}
                        closeContextMenu={() => this.closeContextMenu()}
                        onContextMenuClick={(item) => this.onContextMenuClick(item)}
                    />
                </PortalContainer>}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onLineMouseOver: (kpiId, x, y) => dispatch(onLineMouseOver(kpiId, x, y)),
    onLineMouseLeave: () => dispatch(onLineMouseLeave()),
    contextMenuClick: (kpiId) => dispatch(onContextMenuClick(kpiId)),
    onContextMenuClose: () => dispatch(OnCloseContextMenu()),
    onCollapseBlock: (pblockType, blockType, isOpen, item, listId) => dispatch(openCloseIndicatorBlock(pblockType, blockType, isOpen, item, listId)),
    updateChartRefs: (chart, chartType) => dispatch(updateChartRefs(chart, chartType))
})
const mapStateToProps = ({ kpiMenu, DatagraphReducers, appColor }, props) => {
    const { contextMenuId, showContextMenu, isUpdated, resLength, kpiSettingsDict, isKpiMenuAvailable, processedKpiResults, initialScale } = kpiMenu.KpiModuleReducer;
    const TimeLine = DatagraphReducers.TimeLineReducer.TimeLineData.dates;
    const { isThemeChanged } = appColor;
    const { leftScaleWidth, rightScaleWidth, nodeWidth, majorPeriodicity } = DatagraphReducers.DataGraphReducer;
    return ({ dataPoints: processedKpiResults[props.kpiId], TimeLine, contextMenuId, showContextMenu, isUpdated, 
        resLength, Kpisettings: kpiSettingsDict[props.kpiId], isKpiMenuAvailable, leftScaleWidth, rightScaleWidth, 
        nodeWidth, initialScale, majorPeriodicity, isThemeChanged });
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(KpiIndicator);