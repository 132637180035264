import DefaultDataGraphSetting from "./DefaultDataGraphSettings.js";
import Serializable from "../../../../../Serialization/Serializable.js";
import SmartViewType from "SmartViewType";

export default class IndexViewSettings extends Serializable {

  onSeralisedInfoRecieved(info) {
    this.SummaryBlockSettings = info.SummaryBlockSettings ? info.SummaryBlockSettings : DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    this.FinancialBlockSettings = info.FinancialBlockSettings ? info.FinancialBlockSettings : DefaultDataGraphSetting.getDefaultFinancialBlockSetting();
    this.VolumeBlockSettings = info.VolumeBlockSettings ? info.VolumeBlockSettings : DefaultDataGraphSetting.getDefaultVolumeBlockSettings();
    this.InsiderBlockSettings = info.InsiderBlockSettings ? info.InsiderBlockSettings : DefaultDataGraphSetting.getDefaultInsiderBlockSettings();
    this.EarningLineSetting = info.EarningLineSetting ? info.EarningLineSetting : DefaultDataGraphSetting.getEstimateSettings(true);
    this.RsiChartSettings = info.RsiChartSettings ? info.RsiChartSettings : DefaultDataGraphSetting.getRSiChartSetting();
    this.StochasticsChartSettings = info.StochasticsChartSettings ? info.StochasticsChartSettings : DefaultDataGraphSetting.getStochasticsChartSetting();
    this.WonStochasticsChartSettings = info.WonStochasticsChartSettings ? info.WonStochasticsChartSettings : DefaultDataGraphSetting.getWonStochasticsChartSetting();
    this.WonStochasticsUsageSettings = info.WonStochasticsUsageSettings ? info.WonStochasticsUsageSettings : DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    this.MacdChartSettings = info.MacdChartSettings ? info.MacdChartSettings : DefaultDataGraphSetting.getMacdChartSetting();
    this.AccDistChartSettings = info.AccDistChartSettings ? info.AccDistChartSettings : DefaultDataGraphSetting.getAccDistChartSetting();
    this.PTOEChartSettings = info.PTOEChartSettings ? info.PTOEChartSettings : DefaultDataGraphSetting.getPTOEChartSetting();
    this.PTOSChartSettings = info.PTOSChartSettings ? info.PTOSChartSettings : DefaultDataGraphSetting.getPTOSChartSetting();
    this.EPSRChartSettings = info.EPSRChartSettings ? info.EPSRChartSettings : DefaultDataGraphSetting.getEPSRChartSetting();
    this.ScaleLabelSettings = info.ScaleLabelSettings ? info.ScaleLabelSettings : DefaultDataGraphSetting.getScaleLabelSettings();
    this.EPSLabelSettings = info.EPSLabelSettings ? info.EPSLabelSettings : DefaultDataGraphSetting.getEPSLabelSettings();
    this.HiLoPriceSettings = info.HiLoPriceSettings ? info.HiLoPriceSettings : DefaultDataGraphSetting.getHiLoPriceSetting();
    this.HiLoPctSettings = info.HiLoPctSettings ? info.HiLoPctSettings : DefaultDataGraphSetting.getHiLoPctSetting(false, true);
    this.RelativeStrenghtLine1Settings = info.RelativeStrenghtLine1Settings && info.RelativeStrenghtLine1Settings[1] ? info.RelativeStrenghtLine1Settings : DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1);
    this.RelativeStrenghtLine1SettingsByPeriodicity = info.RelativeStrenghtLine1SettingsByPeriodicity ? info.RelativeStrenghtLine1SettingsByPeriodicity : DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1);
    this.IndexLineSettings = info.IndexLineSettings && info.IndexLineSettings[1] ? info.IndexLineSettings : DefaultDataGraphSetting.getIndexLineSetting();
    this.IndexLineSettingsByPeriodicity = info.IndexLineSettingsByPeriodicity ? info.IndexLineSettingsByPeriodicity : DefaultDataGraphSetting.getIndexLineSettingByPeriodicity();
    this.EPSMultiplierSettings = info.EPSMultiplierSettings ? info.EPSMultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting();
    this.RPSMultiplierSettings = info.RPSMultiplierSettings ? info.RPSMultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting();
    this.T4Q0MultiplierSettings = info.T4Q0MultiplierSettings ? info.T4Q0MultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.T4Q1MultiplierSettings = info.T4Q1MultiplierSettings ? info.T4Q1MultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.T4Q2MultiplierSettings = info.T4Q2MultiplierSettings ? info.T4Q2MultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.T4Q3MultiplierSettings = info.T4Q3MultiplierSettings ? info.T4Q3MultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.EPSAnimationSettings = info.EPSAnimationSettings ? info.EPSAnimationSettings : DefaultDataGraphSetting.getEPSAnimationSetting();
    this.MASettings = info.MASettings ? info.MASettings : DefaultDataGraphSetting.getMovingAverageSetting();
    this.LLRSettings = info.LLRSettings ? info.LLRSettings : DefaultDataGraphSetting.getLLRSetting();
    this.OffHighTag = info.OffHighTag ? info.OffHighTag : "OH";
    this.isGICS = info.isGICS ? info.isGICS : false;
    this.YTDChartSettings = info.YTDChartSettings ? info.YTDChartSettings : DefaultDataGraphSetting.getYTDChartSetting();
    this.YTD1ChartSettings = info.YTD1ChartSettings ? info.YTD1ChartSettings : DefaultDataGraphSetting.getYTD1ChartSetting();
    this.YTD3ChartSettings = info.YTD3ChartSettings ? info.YTD3ChartSettings : DefaultDataGraphSetting.getYTD3ChartSetting();
    this.YTD5ChartSettings = info.YTD5ChartSettings ? info.YTD5ChartSettings : DefaultDataGraphSetting.getYTD5ChartSetting();
    this.YTD10ChartSettings = info.YTD10ChartSettings ? info.YTD10ChartSettings : DefaultDataGraphSetting.getYTD10ChartSetting();
    this.TiMenuSettings = info.TiMenuSettings ? info.TiMenuSettings : DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.INDEX);
    this.ExternalDataSettings =  info.ExternalDataSettings ? info.ExternalDataSettings : DefaultDataGraphSetting.getExternalDataSettings();
    this.EDMultiplierSettings = info.EDMultiplierSettings ? info.EDMultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.ExternalDataRiPanelSettings = info.ExternalDataRiPanelSettings ? info.ExternalDataRiPanelSettings : DefaultDataGraphSetting.getExternalDataRiPanelSettings();
  }
  getDataObject() {
    return {
      VolumeBlockSettings: this.VolumeBlockSettings,
      SummaryBlockSettings: this.SummaryBlockSettings,
      RsiChartSettings: this.RsiChartSettings,
      FinancialBlockSettings: this.FinancialBlockSettings,
      InsiderBlockSettings: this.InsiderBlockSettings,
      EarningLineSetting: this.EarningLineSetting,
      StochasticsChartSettings: this.StochasticsChartSettings,
      WonStochasticsChartSettings: this.WonStochasticsChartSettings,
      WonStochasticsUsageSettings: this.WonStochasticsUsageSettings,
      MacdChartSettings: this.MacdChartSettings,
      AccDistChartSettings: this.AccDistChartSettings,
      PTOEChartSettings: this.PTOEChartSettings,
      PTOSChartSettings: this.PTOSChartSettings,
      EPSRChartSettings: this.EPSRChartSettings,
      ScaleLabelSettings: this.ScaleLabelSettings,
      EPSLabelSettings: this.EPSLabelSettings,
      HiLoPriceSettings: this.HiLoPriceSettings,
      HiLoPctSettings: this.HiLoPctSettings,
      IndexLineSettings: this.IndexLineSettings,
      IndexLineSettingsByPeriodicity: this.IndexLineSettingsByPeriodicity,
      LLRSettings: this.LLRSettings,
      EPSMultiplierSettings: this.EPSMultiplierSettings,
      RPSMultiplierSettings: this.RPSMultiplierSettings,
      T4Q0MultiplierSettings: this.T4Q0MultiplierSettings,
      T4Q1MultiplierSettings: this.T4Q1MultiplierSettings,
      T4Q2MultiplierSettings: this.T4Q2MultiplierSettings,
      T4Q3MultiplierSettings: this.T4Q3MultiplierSettings,
      YTDChartSettings: this.YTDChartSettings,
      YTD1ChartSettings: this.YTD1ChartSettings,
      YTD3ChartSettings: this.YTD3ChartSettings,
      YTD5ChartSettings: this.YTD5ChartSettings,
      YTD10ChartSettings: this.YTD10ChartSettings,
      RelativeStrenghtLine1Settings: this.RelativeStrenghtLine1Settings,
      RelativeStrenghtLine1SettingsByPeriodicity: this.RelativeStrenghtLine1SettingsByPeriodicity,
      EPSAnimationSettings: this.EPSAnimationSettings,
      MASettings: this.MASettings,
      OffHighTag: this.OffHighTag,
      TiMenuSettings: this.TiMenuSettings,
      isGICS: this.isGICS,
      ExternalDataSettings: this.ExternalDataSettings,
      ExternalDataRiPanelSettings: this.ExternalDataRiPanelSettings
    };
  }

  getObjectMapKey() {
    return "IndexViewSettings";
  }

  getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness) {
    return DefaultDataGraphSetting.getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness);
  }
  getDefaultDataGraphSetting() {
    return DefaultDataGraphSetting.getCorporateEventsSetting(false);
  }
  getDefaultEstimateSettings(isEPS, t4q = -1) {
    return DefaultDataGraphSetting.getEstimateSettings(isEPS, t4q);
  }
  getdefaultEPSAnimationSetting() {
    return DefaultDataGraphSetting.getEPSAnimationSetting();
  }
}
