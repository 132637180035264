var AdobeConstants = {
  TAB_FUNDAMENTALS_PAGE_VIEW: "fundamentalsPageView",
  TAB_GRAPH_VIEW: "datagraphView",
  TOP_LEFT_NAVCLICK: "topLeftNavClick",
  TOP_LEFT_NAVIGATION: "symbolsTopLeftNavClick",
  BOTTOM_NAV_CLICK: "bottomLeftGlobalNavClick",
  LIST_VIEW_CLICK: "listViewPV",
  LIST_SELECTED: "listSelected",
  LIST_REFRESH: "listRefreshClicks",
  COMPARE_VIEW_LOAD: "compareViewPV",
  NEWS_LOAD: "newsPV",
  NEWS_ARTICLE_CLICK: "newsArticleClick",
  NEWS_ADD_FEED: "addNewsFeedClick",
  LOGIN_PAGE_VIEW: "loginPageVew",
  LOGIN_SUCCESS: "loginSuccess",
  LOGIN_FAILURE: "loginError",
  LOGIN_CLICK: "loginClick"
};

export default AdobeConstants;
