import GraphType from "GraphType";
import SymbolType from "SymbolType";
import moment from "moment-timezone";
import DocUtil from "../DocUtil.js";
import StringUtil from "StringUtil";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import Long from "long";

class DateHelper {

  constructor() {
    this.months = this.setMonths();
  }

  setMonths() {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  }

  getNextDate(date) {
    const nextDate = new Date(date.getTime());
    nextDate.setDate(nextDate.getDate() + 1);
    if (nextDate.getDay() === 6)
      nextDate.setDate(nextDate.getDate() + 2);
    return nextDate;
  }

  getPrevDate(date) {
    const prevDate = new Date(date.getTime());
    prevDate.setDate(prevDate.getDate() - 1);
    if (prevDate.getDay() === 0)
      prevDate.setDate(prevDate.getDate() - 2);
    return prevDate;
  }

  calculateBeginDate(enddate, periodicity, nodeCount) {
    let beginDate = new Date(enddate.getTime());
    switch (periodicity) {
      case GraphType.Weekly:
        beginDate = DocUtil.ConvertTdcToDateTime(DocUtil.ConvertDateTimeToTdc(enddate) - nodeCount * 5);
        //beginDate.setDate(enddate.getDate() - (nodeCount * 2 * 7));
        break;
      case GraphType.Daily:
        beginDate.setDate(enddate.getDate() - ((nodeCount * 2) + 200));// cover 200 MA
        break;
      case GraphType.Monthly:
        beginDate.setMonth(enddate.getMonth() - (nodeCount * 2));
        break;
      case GraphType.Quarterly:
        beginDate.setFullYear(enddate.getFullYear() - (nodeCount / 2));
        break;
      case GraphType.Annual:
        beginDate.setFullYear(1800);
        break;
      case GraphType.Intraday1Min:
        beginDate.setDate(enddate.getDate() - 2);
        if (beginDate.getDay() === 0)
          beginDate.setDate(enddate.getDate() - 5);
        if (beginDate.getDay() === 6)
          beginDate.setDate(enddate.getDate() - 4);
        break;
      case GraphType.Intraday5Min:
        beginDate.setDate(enddate.getDate() - 10);
        break;
      case GraphType.Intraday10Min:
        beginDate.setDate(enddate.getDate() - 15);
        break;
      case GraphType.Intraday15Min:
        beginDate.setDate(enddate.getDate() - 20);
        break;
      case GraphType.Intraday30Min:
        beginDate.setDate(enddate.getDate() - 30);
        break;
      case GraphType.Intraday60Min:
        beginDate.setDate(enddate.getDate() - 60);
        break;
      default:

    }
    return beginDate;
  }
  getPacificOffset(utcTicks) {
    const momentZoneId = "US/Pacific";
    const cDate = moment(utcTicks).tz(momentZoneId);
    const offset4 = cDate._offset;
    return offset4;
  }
  returnFromUTCtoPacificTime(utcTicks, momentZoneID = "US/Pacific") {
    const offset4 = this.getPacificOffset(utcTicks);
    const test4DateMl = utcTicks - (offset4 * 60000);
    return this.returnPacificServerTime(test4DateMl, momentZoneID);
  }
  returnPacificServerTime(pacificTicks, momentZoneID = "US/Pacific") {
    const date = new Date(pacificTicks);
    const offset4 = date.getTimezoneOffset();
    const test3DateML = date.getTime();
    const test4DateML = test3DateML + offset4 * 60000;
    const test4Date = moment(test4DateML).tz(momentZoneID);
    const offset5 = test4Date._offset;
    const test5DateML = test4DateML + offset5 * 60000;
    const test5Date = new Date(test5DateML);
    return test5Date;
  }
  //returnFromUTCtoPacificTime(utcTicks, offset4) {
  //    //if (offset4 === undefined)
  //        offset4 = this.getPacificOffset(utcTicks);
  //    const test4DateMl = utcTicks - (offset4 * 60000);
  //    return this.returnPacificServerTime(test4DateMl, offset4);
  //}
  //returnPacificServerTime(pacificTicks, offset5) {
  //    const momentZoneID = "US/Pacific";
  //    const date = new Date(pacificTicks);
  //    const offset4 = date.getTimezoneOffset();
  //    const test3DateML = date.getTime();
  //    const test4DateML = test3DateML + offset4 * 60000;
  //    const test4Date = moment(test4DateML).tz(momentZoneID);
  //    offset5 = test4Date._offset;
  //    const test5DateML = test4DateML + offset5 * 60000;
  //    const test5Date = new Date(test5DateML);
  //    return test5Date;
  //}

  returnTimeZoneDate(date, momentZoneID = "US/Pacific") {
    if (momentZoneID === null || momentZoneID === undefined)
      momentZoneID = "US/Pacific";
    const offset4 = date.getTimezoneOffset();
    const test3DateML = date.getTime();
    const test4DateML = test3DateML + offset4 * 60000;
    const test4Date = moment(test4DateML).tz(momentZoneID);// new Date(test4DateML);
    const offset5 = test4Date._offset;
    const test5DateML = test4DateML + offset5 * 60000;
    const test5Date = new Date(test5DateML);
    return test5Date;
  }
  parseJsonDate(detaObject, timezone = '') {
    if (detaObject) {
      if (detaObject.getFullYear) {
        return detaObject;
      }
        if (detaObject.substr) {
            const dateValue = new Date(parseInt(detaObject.substr(6)));
            if (timezone !== "") {
                const test3DateML = parseInt(detaObject.substr(6));
                const test3Date = new Date(test3DateML);
                return this.returnTimeZoneDate(test3Date);
            }
            return dateValue;
        } else {
            return this.parseJsonDate(this.getPSTFromLong(detaObject), timezone);
        }
    }
    return "";
  }
  getMonth(month, format, isUppercase = true) {
    if (!format) {
      if (isUppercase) {
        return this.months[month].substr(0, 3).toUpperCase();
      }
      return this.months[month].substr(0, 3);
    }
    return this.months[month].toUpperCase();

  }
  getDateString(date) {
    if (date)
      return (date.getMonth() + 1) + "/" + (date.getDate()) + "/" + Math.max(date.getFullYear(), 1800);
    return "";
  }
  getDateString2(date) {
    if (date)
      return  Math.max(date.getFullYear(), 1800) + "/" + (date.getMonth() + 1) + "/" + (date.getDate());
    return "";
  }
  getYearMonth(date){
    if (date) {
      let mnth = date.getMonth();
      let year = date.getFullYear();
      return `${year}${mnth}`;
    }

    return "";
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  getTimeString(date) {
    if (date)
      return (this.addZero(date.getHours()) + ":" + this.addZero(date.getMinutes()));
    return "";
  }
  getTPTimeString(date) {
    if (date) {
      let hours = date.getHours();
      let pm = (hours > 11) ? " PM" : " AM";
      if (hours > 12) {
        hours -= 12;
      }
      return (hours + ":" + this.addZero(date.getMinutes()) + pm);
    }
    return "";
  }
  getMonthbyNumber(monthNumber) {
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthNames[monthNumber - 1];
  }

  getMonthAndDay(date) {
    if (date) {
      let mnth = date.getMonth();
      let mnthLabel = this.getMonth(mnth, "", false);
      let day = date.getDate();
      return LocalizationStore.getTranslatedData("Month_Number_" + mnthLabel, mnthLabel) + LocalizationStore.getTranslatedData("Month_Separator_Web", " ") + day;
    }

    return "";
  }

  getMonthDayYear(date) {
    if (date) {
      let mnth = date.getMonth();
      let mnthLabel = this.getMonth(mnth, "", false);
      let day = date.getDate();
      let year = date.getFullYear();

      return mnthLabel + " " + day + "," + year;
    }

    return "";
  }

  yyyymmdd(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    return [date.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd
    ].join('');
  }

  mmddyyyy(date) {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
    return [
      (mm > 9 ? '' : '0') + mm,
      (dd > 9 ? '' : '0') + dd,
      date.getFullYear()

    ].join('/');
  }
  GetData(chartDate, symbolType, epsDate) {
    if (!epsDate || chartDate.getFullYear == undefined || chartDate.getFullYear() < 1971) {
      return " ";
    }

    const countDown = this.DaysDiff(epsDate, chartDate);
    const data = {
      DaysInterval: countDown.Days,
      IsDayOf: (countDown.Days === 0),
      IsPastDue: (countDown.Ticks > 0),
      IsWithinTwoWeeks: (countDown.Days >= -14 && countDown.Days <= 14)
    };

    const acronym = this.GetSymbolDuePrefix(symbolType);
    // const due = data.IsPastDue ? "Past Due " : "Due ";
    if (data.IsPastDue) {
      if (Math.abs(data.DaysInterval) === 1) {
        data.DisplayText = LocalizationStore.getTranslatedData("sym_er_3", "{0} Past Due 1 Day", acronym);
      } else if (Math.abs(data.DaysInterval) > 1) {
        data.DisplayText = LocalizationStore.getTranslatedData("sym_er_4", "{1} Past Due {0} Days (e)", Math.abs(data.DaysInterval), acronym);
      } else {
        data.DisplayText = LocalizationStore.getTranslatedData("sym_er_1", "{0} Past Due Today (e)", acronym);
      }

    } else {
      if (Math.abs(data.DaysInterval) === 1) {
        data.DisplayText = LocalizationStore.getTranslatedData("sym_er_2", "{0} Due in 1 Day", acronym);
      } else if (Math.abs(data.DaysInterval) > 1) {
        data.DisplayText = LocalizationStore.getTranslatedData("sym_er", "{1} Due in {0} Days (e)", Math.abs(data.DaysInterval), acronym);
      } else {
        data.DisplayText = LocalizationStore.getTranslatedData("sym_er_4_2", "{0} Due Today (e)", acronym);
      }

    }
    // let interval = "Today";
    // if (data.DaysInterval == 1) interval = "1 Day";
    // if (Math.abs(data.DaysInterval) === 1) {
    //   interval = data.IsPastDue ? "1 Day" : "in 1 Day";
    // }
    // else if ((Math.abs(data.DaysInterval) > 1) && data.IsPastDue) interval = Math.abs(data.DaysInterval) + " Days";
    // else if ((Math.abs(data.DaysInterval) > 1) && !data.IsPastDue) interval = "in " + Math.abs(data.DaysInterval) + " Days";
    // data.DisplayText = acronym + due + interval + " (e)"
    return data;
  }
  IPOCountDownFormat(ipoDate, selectedDate) {
    const result = " ";
    if (!ipoDate)
      return result;
    //Return empty string if  ipodate is set to 1900
    //1900 is a signal that DB does not have a valid ipoDuedate
    if (ipoDate.getFullYear() === 1900) {
      return result;
    }

    const ipoDuration = this.DaysDiff(ipoDate, selectedDate);
    const numDay = ipoDuration.Days;
    if (numDay > 0) {
      if (numDay == 0) {
        return LocalizationStore.getTranslatedData("sym_ipo_1", "IPO {0} Day Ago", "IPO today !");
      }

      if (numDay == 1) {
        return LocalizationStore.getTranslatedData("sym_ipo_2", "IPO {0} Day Ago", "1");
      }

      if (numDay < 365) {
        return LocalizationStore.getTranslatedData("sym_ipo_3", "IPO {0} Days Ago", numDay);
      }

      if (numDay < 401) {
        return LocalizationStore.getTranslatedData("sym_ipo_4", "IPO {0} Year Ago", "1");
      }

      if (numDay >= 401 && numDay < 437) {
        return LocalizationStore.getTranslatedData("sym_ipo", "IPO {0} Years Ago", "1.1");
      }

      let years = numDay / 365;
      return LocalizationStore.getTranslatedData("sym_ipo", "IPO {0} Years Ago", years.toFixed(1));
    }

    return LocalizationStore.getTranslatedData("sym_ipo_5", "IPO in {0}Days (e)", -1 * numDay);

  }
  DaysDiff(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const ticks = date2.getTime() - date1.getTime();
    const days = Math.round(ticks / oneDay);

    return { Days: days, Ticks: ticks };
  }
  IpoDateFormat(ipoDate) {
    if (!ipoDate) {
      return " ";
    }
    return LocalizationStore.getTranslatedData("sym_ipo_6", `IPO ${this.getDateString(ipoDate)}`, this.getDateString(ipoDate));
  }
  EpsDueDateFormat(epsDate, symbolType) {
    if (!epsDate) {
      return " ";
    }
    let prefix = this.GetSymbolDuePrefix(symbolType);
    return LocalizationStore.getTranslatedData("sym_date", prefix + "Due " + this.getDateString(epsDate) + "e", this.getDateString(epsDate), prefix);
  }
  GetSymbolDuePrefix(symbolType) {
    switch (symbolType) {
      case SymbolType.ETF:
        return "NAV ";
      case SymbolType.REIT:
        return "FFO ";
      default:
        return "EPS ";
    }
  }
  getTicks(timestamp) {
    if (timestamp === undefined || timestamp === null || timestamp.seconds === undefined || timestamp.seconds === null)
      return null;
    let multiplier = 1;
    let ticks = 0;
    const days = timestamp.seconds.high;
    const minutes = timestamp.seconds.low;
    const seconds = timestamp.seconds.toNumber();
    const type = timestamp.nanos - 1;
    const diviser = Math.pow(10, type);

    if (type === 0) {
      multiplier = 1000;
      ticks = Math.round(seconds / 2) * 3600;
      if (days === 0 && minutes % 2 !== 0) ticks = -ticks;
    }
    else
      if (type === 1) {
        multiplier = 1000;
        ticks = Math.round(seconds / 2) * 60;
        if (days === 0 && minutes % 2 !== 0) ticks = -ticks;
      }
      else
        if (days !== 0 && minutes !== 0 && type > 1) {
          ticks = Math.trunc((seconds / (2 * diviser)));
        }
        else {
          multiplier = 1000;
          ticks = (((seconds / 2) * 3600) * 24);
          if (days === 0 && minutes % 2 !== 0) ticks = -ticks;
        }
    return ticks * multiplier;
  }

  //This function will return elapsed time up to 24 hours, & date prior to 24 hours time difference.
  //It takes date as an moment JS parameter.
  getElapsedTime(date) {

    var curr = moment(new Date());
    let returnDate;

    if (Math.abs(curr.diff(date, 'hours')) < 24) {
      if (Math.abs(curr.diff(date, 'minutes')) < 1) {
        let ts = parseInt(curr.diff(date, 'seconds')) < 1 ? 1 : parseInt(curr.diff(date, 'seconds'));
        returnDate = ts + " " + ((ts == 1) ? LocalizationStore.getTranslatedData("MC_SECOND_AGO", "second ago") : LocalizationStore.getTranslatedData("MC_SECONDS_AGO", "seconds ago"));
      }
      else if (Math.abs(date.diff(curr, 'minutes')) < 60) {
        let tm = parseInt(curr.diff(date, 'minutes'))
        returnDate = tm + " " + ((tm == 1) ? LocalizationStore.getTranslatedData("MC_MINUTE_AGO", "minute ago") : LocalizationStore.getTranslatedData("MC_MINUTES_AGO", "minutes ago"));
      }
      else {
        let tr = parseInt(curr.diff(date, 'hours'))
        returnDate = tr + " " + ((tr == 1) ? LocalizationStore.getTranslatedData("MC_HOUR_AGO", "hour ago") : LocalizationStore.getTranslatedData("MC_HOURS_AGO", "hours ago"));
      }
    }
    else {
      returnDate = this.getDateString(date._d) + ' ' + this.getTimeString(date._d);
    }

    return returnDate;
  }
  getUtcTicksfromTimeStamp(timestamp, momentZoneID = "US/Pacific") {
    if (StringUtil.isEmpty(timestamp)) return null;
    const ticks = this.getTicks(timestamp);
    return this.returnFromUTCtoPacificTime(ticks, momentZoneID);
  }
  getMonthDay(dt) {
    if (dt) {
      let fday = '';
      let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      let cdate = dt.getDate();
      let mnth = dt.getMonth() + 1;
      let mnthLabel = this.getMonthbyNumber(mnth);
      let year = dt.getFullYear();
      let day = days[dt.getDay()];
      fday = day + ', ' + mnthLabel + ' ' + cdate + ', ' + year;
      return fday;
    }

    return "";
  }
  getTZoneAbbreviation(dateVal) {
    const timeZone = moment.tz.guess();
    let zoneText = moment().tz(timeZone).format('z');
    if (/\d/.test(zoneText)) {
      const dateArray = dateVal.toString().split("(");
      const dateArrayStrng = dateArray[1];
      const matches = dateArrayStrng.match(/\b(\w)/g);
      const acronym = matches.join('');
      zoneText = acronym;
    }
    return zoneText;

  }

  getUtcFromLong(date) {
    const convertedDate = new Date(parseInt(date)).toUTCString();
    const utcDate = moment.utc(convertedDate).format("MM-DD-YYYY HH:mm:ss");
    return moment(utcDate, "MM-DD-YYYY HH:mm:ss", true);
    }

  getPSTFromLong(date, symbolInfo, isIntraDay, isIntradayDataSource) {
    if (Long.isLong(date)) {
      // in db we don't have timezone info so we used to give dates in PST but in ES the date is represented in local timezone format.
      if (isIntraDay && isIntradayDataSource && symbolInfo.CountryCode === 104) {
        return new Date(moment(date.toNumber()).tz(symbolInfo.MomentZoneID).format("YYYY/MM/DD HH:mm:ss"));
      }
      else
        return new Date(moment(date.toNumber()).tz('US/Pacific').format("YYYY/MM/DD HH:mm:ss"));
    }
    else return date;
  }

  getUTCTicks(date) {
    return new Date(date).valueOf() - (new Date(date).getTimezoneOffset() / 60) * 1000 * 60 * 60;
  }
}


const dateHelper = new DateHelper();
export default dateHelper;
