import AlertActions from "Actions/AlertActions";
import AlertStore from "../../../../Stores/Alerts/AlertStore"
import annotationData from '../../../../ServiceApi/RequestHelper/AnnotationData';
import annotationsApi from '../../../../ServiceApi/Apis/AnnotationsApi';
import AnnotationUtil from "Utils/AnnotationUtil";
import BaseServiceApi from 'BaseServiceApi';
import BrowserUtil from '../../../../Utils/BrowserUtil';
import ConsoleStore from "ConsoleStore";
import DatagraphHelper from "../../../../Utils/DatagraphHelper";
import { findWhere } from "underscore";
import KeyCodes from "../../../../Constants/KeyCodes";
import { RayAnnotationType } from 'Constants/EAnnotationType';
import { safe } from '../../../ErrorModel';
import SettingsStore from "SettingsStore";
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
import { AnnotationConstant, TemporaryId } from "../../../../Constants/AnnotationConstants.js";
import {
    getAnnotationMenu, getAnnotationSelected, getRectangle, getEllipse, getTriangle, getLine,
    getChannelLine, getCurToolType, getMeasurementData, getFibonacciLine, getAnnotationChartDraw,
    getAnnotationIsDrawing, getVisibleAnnotIds, getSelectedAnnotId, getIsAlertUpdate, getDeletedAnnotId,
    getIsLayerDelete, getIsRiPanelDelete, getText, getTargetLayer, getLayersList, getcombinedAnnotations,
    getIsDeleteAlert, getAlertDeleteAnnot, getIsAlertDelete, getTepmToolType, getMouseMoved, getTempIdsArray,
    getTempAnnotId,
    getReadOnlyAnnotIds
}
    from '../../../../Reducers/AnnotationReducers/selectors';
import { put, takeLatest, select, call, delay } from 'redux-saga/effects';
import { startAlertDataWorker } from "../../../../Actions/AlertActions";

const { ActionTypes } = AnnotationConstant;

function* updateRiPanelInfo({ attrs, isUpdateSelectedAnnotation = false, isUpdateRiPanelSettingsView = true }) {
    try {
        const annotationMenu = yield select(getAnnotationMenu);
        let newStateProperties = {};
        let model = null;
        let currentTarget = null;

        if (!attrs) {
            return;
        }

        if (!attrs.shape) {
            attrs.shape = attrs.type;
        }

        //TODO: Update selected shape under shapes model - rectangle, triangle, ellipse
        switch (attrs.shape) {
            case RayAnnotationType.LINE_AnnotationType: {
                const lineArray = yield select(getLine);
                currentTarget = findWhere(lineArray, { id: attrs.id });
                newStateProperties = { ...annotationMenu.LineModelInfo, ...currentTarget };
                model = "LineModelInfo";
                break;
            }
            case RayAnnotationType.TEXT_AnnotationType: {
                const textArray = yield select(getText);
                currentTarget = findWhere(textArray, { id: attrs.id });
                newStateProperties = { ...annotationMenu.TextModelInfo, ...currentTarget };
                model = "TextModelInfo";
                break;
            }
            case RayAnnotationType.RECT_AnnotationType: {
                const rectangleArray = yield select(getRectangle);
                currentTarget = findWhere(rectangleArray, { id: attrs.id });
                newStateProperties = { ...annotationMenu.ShapesModelInfo, ...currentTarget };
                newStateProperties.selectedType = currentTarget.type;
                model = "ShapesModelInfo";
                break;
            }
            case RayAnnotationType.ELLIPSE_AnnotationType: {
                const ellipseArray = yield select(getEllipse);
                currentTarget = findWhere(ellipseArray, { id: attrs.id });
                newStateProperties = { ...annotationMenu.ShapesModelInfo, ...currentTarget };
                newStateProperties.selectedType = currentTarget.type;
                model = "ShapesModelInfo";
                break;
            }
            case RayAnnotationType.TRIANGLE_AnnotationType: {
                const triangleArray = yield select(getTriangle);
                currentTarget = findWhere(triangleArray, { id: attrs.id });
                newStateProperties = { ...annotationMenu.ShapesModelInfo, ...currentTarget };
                newStateProperties.selectedType = currentTarget.type;
                model = "ShapesModelInfo";
                break;
            }
            case RayAnnotationType.PARALLEL_LINES_AnnotationType: {
                const channelLineArray = yield select(getChannelLine);
                currentTarget = findWhere(channelLineArray, { id: attrs.id });
                newStateProperties = { ...annotationMenu.ChannelLineModelInfo, ...currentTarget };
                model = "ChannelLineModelInfo";
                break;
            }
            case RayAnnotationType.FIB_AnnotationType: {
                const fibnocciArray = yield select(getFibonacciLine);
                currentTarget = findWhere(fibnocciArray, { id: attrs.id });
                newStateProperties = { ...annotationMenu.FibonacciModelInfo, ...currentTarget };
                model = "FibonacciModelInfo";
                break;
            }
            case RayAnnotationType.MEASUREMENT_AnnotationType: {
                const measurementArray = yield select(getMeasurementData);
                currentTarget = findWhere(measurementArray, { id: attrs.id });
                newStateProperties = { ...annotationMenu.MeasurementModelInfo, ...currentTarget };
                model = "MeasurementModelInfo";
                break;
            }
            default:
                break;
        }

        if (model !== null && isUpdateRiPanelSettingsView) {
            yield put({
                type: ActionTypes.UPDATE_RI_PANEL_INFO,
                model,
                newStateProperties
            });

            yield put({
                type: ActionTypes.HANDLE_TOOL_CHANGE,
                selectedToolType: attrs.shape,
                riPanelClick: false
            });
        }

        if (isUpdateSelectedAnnotation) {
            yield put({
                type: ActionTypes.HANDLE_CLICK_SUCCESS,
                isDrawing: false,
                annotSelected: currentTarget
            });
        }
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, updateRiPanelInfo ${error}`);
    }
}
function* updateRiPanelInfoFromUserSettings(ctrlPressed) {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const annotationSettings = DatagraphHelper.getSettingsObject(consoleSettings, consoleSettings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;

    yield put({
        type: ActionTypes.UPDATE_STATE_FROM_SETTINGS,
        selectedToolType: annotationSettings.selectedToolType,
        LineSettings: annotationSettings.LineSettings,
        TextSettings: annotationSettings.TextSettings,
        ShapeSettings: annotationSettings.ShapeSettings,
        ChannelLineSettings: annotationSettings.ChannelLineSettings,
        FibonacciSettings: annotationSettings.FibonacciSettings,
        MeasurementSettings: annotationSettings.MeasurementSettings,
        isCtrl: ctrlPressed
    });
}

function* handleClick({ e }) {
    try {
        let selectedTool = yield select(getCurToolType);
        const selectedTempTool = yield select(getTepmToolType);
        const targetLayer = yield select(getTargetLayer);
        const isDrawing = yield select(getAnnotationIsDrawing);
        const texts = yield select(getText);
        const isEditingText = texts.find((itm) => itm.isEditable === true);
        const isMouseMoved = yield select(getMouseMoved);
        if (!isMouseMoved && isDrawing) {
            yield put({
                type: ActionTypes.DETECT_MOUSE_MOVE,
                mouseMoved: false
            })
            return;
        }
        if (!isDrawing && !isEditingText && selectedTool !== 0 && (e.currentTarget.targetShape === undefined || e.currentTarget.targetShape === null)) {
            yield put({
                type: ActionTypes.GET_DEFAULT_DRAWING_MODE
            });
            if (!targetLayer.isVisible) {
                yield put({
                    type: ActionTypes.HANDLE_TOGGLE_LAYER_VISIBILTY_POPUP,
                    showDialog: true
                });
                return;
            }
            if (targetLayer.shareAccess === ShareAccessType.SHARE_READONLY) {
                yield put({
                    type: ActionTypes.HANDLE_TOGGLE_LAYER_READONLY_POPUP,
                    showDialog: true
                });
                return;
            }
        }

        if (!AnnotationUtil.isCtrlKeyMode) {
            if (e.target === e.target.getStage() && !isDrawing && !isEditingText) {
                yield put({
                    type: ActionTypes.HANDLE_TOOL_CHANGE,
                    selectedToolType: selectedTempTool,
                    riPanelClick: false
                });
                selectedTool = selectedTempTool;
            }

            yield put({
                type: ActionTypes.UPDATE_TEMP_TOOL_SELECTION,
                tempTool: selectedTempTool
            });
        }
        if (e.target && e.target.attrs && e.target.attrs.shape && !isDrawing && !isEditingText) {
            yield safe(call(updateRiPanelInfo, { attrs: e.target.attrs }), "AnnotationLayerSaga.js", "updateRiPanelInfo");
        }
        else if (!e.target.attrs.id || e.target.attrs.id !== "anchor") {
            yield safe(call(updateRiPanelInfoFromUserSettings, AnnotationUtil.isCtrlKeyMode), "AnnotationLayerSaga.js", "updateRiPanelInfoFromUserSettings");
        }

        const annotationMenu = yield select(getAnnotationMenu);
        const target = e.target ? e.target : e.currentTarget ? e.currentTarget : null;
        const tool = (target && target.attrs.shape) ? e.target.attrs.shape : selectedTool;

        // Save/remove current text annotation when clicking on other annotation
        if (tool !== RayAnnotationType.TEXT_AnnotationType && selectedTool === RayAnnotationType.TEXT_AnnotationType) {
            yield put({
                type: ActionTypes.HANDLE_TEXT_CLICK
            })
        }

        switch (tool) {
            case 0:
                yield put({
                    type: ActionTypes.HANDLE_POINTER_CLICK,
                    e
                })
                break;
            case RayAnnotationType.LINE_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_LINE_CLICK,
                    e
                })
                break;
            case RayAnnotationType.PARALLEL_LINES_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_CHANNEL_LINE_CLICK,
                    e
                })
                break;
            case RayAnnotationType.TEXT_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_TEXT_CLICK,
                    e
                })
                break;
            case RayAnnotationType.RECT_AnnotationType:
            case RayAnnotationType.ELLIPSE_AnnotationType:
            case RayAnnotationType.TRIANGLE_AnnotationType:
                switch (annotationMenu.ShapesModelInfo.selectedType) {
                    case RayAnnotationType.RECT_AnnotationType:
                        yield put({
                            type: ActionTypes.HANDLE_RECTANGLE_CLICK,
                            e,
                            rectangleInfo: annotationMenu.rectangleInfo
                        })
                        break;
                    case RayAnnotationType.ELLIPSE_AnnotationType:
                        yield put({
                            type: ActionTypes.HANDLE_ELLIPSE_CLICK,
                            e,
                            ellipseInfo: annotationMenu.ellipseInfo
                        })
                        break;
                    case RayAnnotationType.TRIANGLE_AnnotationType:
                        yield put({
                            type: ActionTypes.HANDLE_TRIANGLE_CLICK,
                            e,
                            triangleInfo: annotationMenu.triangleInfo
                        })
                        break;
                    default: break;
                }
                break;
            case RayAnnotationType.FIB_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_FIBONACCI_LINE_CLICK,
                    e
                });
                break;
            case RayAnnotationType.MEASUREMENT_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_MEASUREMENT_CLICK,
                    e
                })
                break;
            default:
                break;
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleClick ${error}`);
    }
}

function* handleDeleteAllAnnotation({ isRiPanelDelete }) {
    try {
        yield put({
            type: ActionTypes.HANDLE_TOGGLE_DELETE_POPUP,
            showDialog: true,
            isDeleteAll: true,
            isRiPanelDelete: isRiPanelDelete
        })
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerSaga.js, handleDeleteAllAnnotation ${error}`);
    }
}

function* handleDeleteAllAnnotationConfirm() {
    try {
        const annotSelectedId = yield select(getVisibleAnnotIds);
        const isRiPanelDelete = yield select(getIsRiPanelDelete);
        const layersList = yield select(getLayersList);
        const filteredResult = [];
        const readOnlyAnnotation = yield select(getReadOnlyAnnotIds);
        const readOnlyAnnotationIds = readOnlyAnnotation.map((item) => item.annotId);
        layersList.forEach((items) => {
            if (items.isVisible) {
                annotSelectedId.forEach((item) => {
                    if (item.layerId === items.layerID) {
                        filteredResult.push(item);
                    }
                });
            }
        });
        let deletableIds = [];
        const annotLayers = [];
        filteredResult.forEach((items) => {
            if (!items.deleted && !readOnlyAnnotationIds.includes(items.annotId)) {
                deletableIds.push(items.annotId);
                annotLayers.push(items.layerId);
            }
        })
        deletableIds = deletableIds.reduce((a, b) => {
            if (a.indexOf(b) < 0) {
                a.push(b);
            }
            return a;
        }, []);
        const msId = AnnotationUtil.getMsid();
        let respDelete = {};
        let alertMsg = '';
        yield put({
            type: ActionTypes.REMOVE_VISIBLE_ANNOTATION_IDS,
            deletedIds: deletableIds
        });
        yield put({
            type: ActionTypes.HANDLE_TOGGLE_DELETE_POPUP,
            showDialog: false,
            isDeleteAll: false,
            isRiPanelDelete: false
        });
        yield put({
            type: ActionTypes.UPDATE_API_CALL_STATUS,
            isApiReqResolved: false
        });
        const deletables = yield call(removeTempLines, deletableIds);
        if (deletables.length > 0) {
            respDelete = yield call(annotationsApi.DeleteAnnotations, msId, deletables);
        }
        if (!respDelete.error) {
            yield put({
                type: ActionTypes.UPDATE_API_CALL_STATUS,
                isApiReqResolved: true
            });
            yield call(checkAlertExists, deletableIds, true);
            yield put({
                type: ActionTypes.REMOVE_DELETED_ANNOTATIONS,
                annotDeletedId: deletableIds
            });
            yield put({
                type: ActionTypes.REMOVE_TEMP_LINE_ANNOTATIONS
            })
            const noOfLayers = annotLayers.filter((item, i, ar) => ar.indexOf(item) === i);
            alertMsg = `${deletableIds.length}  Annotation${deletableIds.length > 1 ? 's' : ''} from ${noOfLayers.length} ${noOfLayers.length > 1 ? "Layers" : "Layer"} deleted`;
            // if (isRiPanelDelete || layersList.length == 1) {
            if (layersList.length === 1) {
                const settings = ConsoleStore.getSettings();
                const symbol = settings.NavDatagraphSettings.symbol;
                alertMsg = `Annotations for ${symbol} have been deleted`;
            }
            yield put({
                type: ActionTypes.SHOW_UNDO_ALERT,
                showAlert: true,
                deletedId: deletableIds,
                alertMsg: alertMsg,
                isLayerDelete: false
            });

            yield delay(10000);
            yield put({
                type: ActionTypes.SHOW_UNDO_ALERT,
                showAlert: false,
                deletedId: '',
                alertMsg: '',
                isLayerDelete: false
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleDeleteAllAnnotationConfirm ${error}`);
    }
}

function* removeTempLines(deletableIds) {
    try {
        const removableIds = [];
        const tempIds = [];
        deletableIds.forEach((item) => {
            if (isNaN(item)) {
                tempIds.push(item)
            } else {
                removableIds.push(item)
            }
        });
        yield put({
            type: ActionTypes.REMOVE_SELECTED_TEMP_LINE_ANNOTATIONS,
            annotDeletedId: tempIds
        })
        return removableIds;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, removeTempLines ${error}`);
    }
}
function* handleRemoveDrawing({ charCode }) {
    try {
        const tool = yield select(getCurToolType);
        let annotSelectedId = yield select(getSelectedAnnotId);
        const readOnlyAnnotation = yield select(getReadOnlyAnnotIds);
        const msId = AnnotationUtil.getMsid();
        const isDrawing = yield select(getAnnotationIsDrawing);
        const annotationSelected = yield select(getAnnotationSelected);
        let showUndo = false;
        const readOnlyAnnotationIds = readOnlyAnnotation.map((item) => item.annotId);
        if (annotSelectedId.length > 1) {
            yield put({
                type: ActionTypes.HANDLE_TOGGLE_DELETE_POPUP,
                showDialog: false,
                isDeleteAll: false,
                isRiPanelDelete: false
            })
        }
        annotSelectedId = annotSelectedId.filter((item) => !readOnlyAnnotationIds.includes(item));
        if (charCode === KeyCodes.DELETE && !isDrawing) {
            const deletables = yield call(removeTempLines, annotSelectedId);
            if (deletables.length > 0) {
                const deleted = yield call(annotationsApi.DeleteAnnotations, msId, deletables);
                if (!deleted.responseHeader.error) {
                    yield call(checkAlertExists, annotSelectedId, true);
                }
            }

            const allAnnotIds = yield select(getVisibleAnnotIds);
            const layerCount = [];
            annotSelectedId.forEach((annotId) => {
                const matchId = allAnnotIds.find((item) => item.annotId === annotId)
                layerCount.push(matchId.layerId);
            });
            const noOfLayers = layerCount.filter((item, i, ar) => ar.indexOf(item) === i);
            let alertMsg = `${annotSelectedId.length} Annotation Deleted`;
            if (annotSelectedId.length > 1) {
                alertMsg = `${annotSelectedId.length}  Annotation${annotSelectedId.length > 1 ? 's' : ''} from ${noOfLayers.length} ${noOfLayers.length > 1 ? "Layers" : "Layer"} deleted`;
            }
            yield put({
                type: ActionTypes.SHOW_UNDO_ALERT,
                showAlert: true,
                deletedId: annotSelectedId,
                alertMsg: alertMsg,
                isLayerDelete: false
            });
            showUndo = true;

        }
        if (charCode === KeyCodes.ESCAPE && !isDrawing) {
            yield put({
                type: ActionTypes.GET_DEFAULT_DRAWING_MODE
            });
            return;
        }
        if (charCode = KeyCodes.ESCAPE && isDrawing) {
            switch (annotationSelected.type) {
                case RayAnnotationType.LINE_AnnotationType:
                    yield put({
                        type: ActionTypes.HANDLE_REMOVE_LINE_DRAW,
                        annotSelectedId
                    })
                    break;
                case RayAnnotationType.PARALLEL_LINES_AnnotationType:
                    yield put({
                        type: ActionTypes.HANDLE_REMOVE_CHANNEL_LINE_DRAW,
                        annotSelectedId
                    })
                    break;
                case RayAnnotationType.TEXT_AnnotationType:
                    yield put({
                        type: ActionTypes.HANDLE_REMOVE_TEXT_DRAW,
                        annotSelectedId
                    })
                    break;
                case RayAnnotationType.RECT_AnnotationType:
                    yield put({
                        type: ActionTypes.HANDLE_REMOVE_RECTANGLE_DRAW,
                        annotSelectedId
                    })
                    break;
                case RayAnnotationType.ELLIPSE_AnnotationType:
                    yield put({
                        type: ActionTypes.HANDLE_REMOVE_ELLIPSE_DRAW,
                        annotSelectedId
                    })
                    break;
                case RayAnnotationType.TRIANGLE_AnnotationType:
                    yield put({
                        type: ActionTypes.HANDLE_REMOVE_TRIANGLE_DRAW,
                        annotSelectedId
                    })
                    break;
                case RayAnnotationType.FIB_AnnotationType:
                    yield put({
                        type: ActionTypes.HANDLE_REMOVE_FIBONACCI_LINE_DRAW,
                        annotSelectedId
                    })
                    break;
                case RayAnnotationType.MEASUREMENT_AnnotationType:
                    yield put({
                        type: ActionTypes.HANDLE_REMOVE_MEASUREMENT_DRAW,
                        annotSelectedId
                    })
                    break;
                default:
                    break;
            }
            yield put({
                type: ActionTypes.GET_DEFAULT_DRAWING_MODE
            });
            return;
        }
        yield put({
            type: ActionTypes.REMOVE_DRAWING_ANNOTATION_AFTER_KEY
        })
        yield put({
            type: ActionTypes.REMOVE_VISIBLE_ANNOTATION_IDS,
            deletedIds: annotSelectedId
        })
        yield put({
            type: ActionTypes.REMOVE_DELETED_ANNOTATIONS,
            annotDeletedId: annotSelectedId,
            isDeleteAll: false
        })
        if (showUndo) {
            yield delay(10000);
            yield put({
                type: ActionTypes.SHOW_UNDO_ALERT,
                showAlert: false,
                deletedId: '',
                alertMsg: '',
                isLayerDelete: false
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleRemoveDrawing ${error}`);
    }
}

function* handleMouseMove({ e }) {
    try {
        const selectedTool = yield select(getCurToolType);
        const target = e.target ? e.target : e.currentTarget ? e.currentTarget : null;
        const tool = (target && target.attrs.shape) ? e.target.attrs.shape : selectedTool;
        yield put({
            type: ActionTypes.DETECT_MOUSE_MOVE,
            mouseMoved: true
        })
        switch (tool) {
            case RayAnnotationType.LINE_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_LINE_DRAW,
                    e
                })
                break;
            case RayAnnotationType.PARALLEL_LINES_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_CHANNEL_LINE_DRAW,
                    e
                })
                break;
            case RayAnnotationType.TEXT_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_TEXT_DRAW,
                    e
                })
                break;
            case RayAnnotationType.RECT_AnnotationType:
            case RayAnnotationType.ELLIPSE_AnnotationType:
            case RayAnnotationType.TRIANGLE_AnnotationType: {
                const annotationMenu = yield select(getAnnotationMenu);
                switch (annotationMenu.ShapesModelInfo.selectedType) {
                    case RayAnnotationType.RECT_AnnotationType:
                        yield put({
                            type: ActionTypes.HANDLE_RECTANGLE_DRAW,
                            e
                        })
                        break;
                    case RayAnnotationType.ELLIPSE_AnnotationType:
                        yield put({
                            type: ActionTypes.HANDLE_ELLIPSE_DRAW,
                            e
                        })
                        break;
                    case RayAnnotationType.TRIANGLE_AnnotationType:
                        yield put({
                            type: ActionTypes.HANDLE_TRIANGLE_DRAW,
                            e
                        })
                        break;
                    default: break;
                }
                break;
            }
            case RayAnnotationType.FIB_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_FIBONACCI_LINE_DRAW,
                    e
                })
                break;
            case RayAnnotationType.MEASUREMENT_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_MEASUREMENT_DRAW,
                    e
                })
                break;
            default:
                break;
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleMouseMove ${error}`);
    }
}

function* handleDrag({ e, isEndExtend, isStartExtend, isStartArrow, isEndArrow, isLabel }) {
    try {
        const shape = e.target.attrs.shape;
        const { isApiReqResolved } = yield select(getAnnotationChartDraw);

        if (!isApiReqResolved) {
            return;
        }

        switch (shape) {
            case RayAnnotationType.LINE_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_LINE_DRAW,
                    e, isEndExtend, isStartArrow, isEndArrow
                });
                break;
            case RayAnnotationType.TEXT_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_TEXT_DRAG,
                    e
                });
                break;
            case RayAnnotationType.RECT_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_RECTANGLE_DRAG,
                    e
                });
                break;
            case RayAnnotationType.ELLIPSE_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_ELLIPSE_DRAG,
                    e
                });
                break;
            case RayAnnotationType.TRIANGLE_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_TRIANGLE_DRAG,
                    e
                });
                break;
            case RayAnnotationType.PARALLEL_LINES_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_CHANNEL_LINE_DRAW,
                    e, isEndExtend
                });
                break;
            case RayAnnotationType.FIB_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_FIBONACCI_LINE_DRAW,
                    e, isEndExtend, isStartExtend, isLabel
                });
                break;
            case RayAnnotationType.MEASUREMENT_AnnotationType:
                yield put({
                    type: ActionTypes.HANDLE_MEASUREMENT_DRAW,
                    e
                });
                break;
            default:
                break;
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleDrag ${error}`);
    }
}

function* handleMouseDown({ }) {
    // const annotationLeftReducerState = yield select(getAnnotationMenu);
    // const annotations = annotationLeftReducerState.annotations;
    // const shape = e.target.attrs.shape;
    // let annItem;

    // switch (shape) {
    //     // case RayAnnotationType.LINE_AnnotationType:
    //     //     annItem = yield call(updateSelectedComponent, annotations.lines, index);
    //     //     annotations.lines = annItem;
    //     //     break;
    //     // case RayAnnotationType.PARALLEL_LINES_AnnotationType:
    //     //     annItem = yield call(updateSelectedComponent, annotations.channelLines, index);
    //     //     annotations.channelLines = annItem;
    //     //     break;
    //     // case RayAnnotationType.TEXT_AnnotationType:
    //     //     annItem = yield call(updateSelectedComponent, annotations.texts, index);
    //     //     annotations.texts = annItem;
    //     //     break;
    //     case RayAnnotationType.RECT_AnnotationType:
    //         const id = e.target.attrs.id;
    //         const rectangles = yield select(getRectangle);
    //         annItem = yield call(updateSelectedComponent, id, rectangles, index);
    //         annotations.rectangles = annItem;
    //         break;
    //     // case RayAnnotationType.ELLIPSE_AnnotationType:
    //     //     annItem = yield call(updateSelectedComponent, annotations.ellipses, index);
    //     //     annotations.ellipses = annItem;
    //     //     break;
    //     // case RayAnnotationType.TRIANGLE_AnnotationType:
    //     //     annItem = yield call(updateSelectedComponent, annotations.triangles, index);
    //     //     annotations.triangles = annItem;
    //     //     break;
    //     // case RayAnnotationType.FIB_AnnotationType:
    //     //     annItem = yield call(updateSelectedComponent, annotations.fibonacci, index);
    //     //     annotations.fibonacci = annItem;
    //     //     break;
    //     // case RayAnnotationType.MEASUREMENT_AnnotationType:
    //     //     annItem = yield call(updateSelectedComponent, annotations.measurements, index);
    //     //     annotations.measurements = annItem;
    //     //     break;
    //     default:
    //         break;
    // }

    // yield put({
    //     type: ActionTypes.HANDLE_CLICK_SUCCESS,
    //     annotations: annotations,
    //     isDrawing: true
    // });
}

{ /* Avoid drawing new shape when current api req is still pending. */ }
function* updateApiCallStatus(isResolved) {
    { /* Disabled scale toggle */ }
    try {
        const scale = document.getElementById('scale');
        if (isResolved) {
            scale.style.pointerEvents = 'all';
        }
        else {
            scale.style.pointerEvents = 'none';
        }
        yield put({
            type: ActionTypes.UPDATE_API_CALL_STATUS,
            isApiReqResolved: isResolved
        });
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerSaga.js, updateApiCallStatus ${error}`);
    }
}
function getTempName() {
    // let lines = yield select(getLine);
    // const count = lines.filter((itm) => isNaN(itm.id));
    return TemporaryId.Id + Date.now();
}
function* handleSaveAnnotation() {
    try {
        const isDrawing = yield select(getAnnotationIsDrawing);
        const allAnnotId = yield select(getVisibleAnnotIds);
        if (isDrawing) {
            return;
        }

        let annotationSelected = yield select(getAnnotationSelected);

        annotationSelected.createdDate = annotationSelected.createdDate ? annotationSelected.createdDate : new Date();
        annotationSelected.updatedDate = new Date();

        const isDeleteAlert = yield select(getIsDeleteAlert);
        const alertDeleteAnnot = yield select(getAlertDeleteAnnot);
        if (isDeleteAlert) {
            annotationSelected = alertDeleteAnnot;
            yield put({
                type: ActionTypes.REMOVE_ALERT_SELECTION_SAVE,
                isRemoveAlertSelection: false,
                line: null
            });
        }

        BrowserUtil.disableEvents();
        yield call(updateApiCallStatus, false);
        const tempIdsArray = yield select(getTempIdsArray);

        if (annotationSelected.isTemporaryChecked) {
            const annotSelectedId = yield select(getSelectedAnnotId);
            yield call(checkAlertExists, annotSelectedId);
            const msId = AnnotationUtil.getMsid();

            if (!isNaN(annotSelectedId)) {
                yield call(annotationsApi.DeleteAnnotations, msId, annotSelectedId);
                const annotId = yield call(getTempName);
                yield safe(call(handleUpdateShape, annotationSelected, annotId), "AnnotationLayerSaga.js", "handleUpdateShape");

                /* update the id of current line in annotIds when temp is checked */
                findWhere(allAnnotId, { annotId: annotationSelected.id }).annotId = annotId;
                yield put({
                    type: ActionTypes.STORE_ANNOTATION_IDS,
                    allAnnotId: [...allAnnotId]
                });

                tempIdsArray[annotId] = annotSelectedId;
                yield put({
                    type: ActionTypes.HANDLE_ORIGINAL_IDS_TEMP,
                    tempIdsArray
                })
            }
            else {
                if (!findWhere(allAnnotId, { annotId: annotationSelected.id })) {
                    yield put({
                        type: ActionTypes.STORE_NEW_ANNOTATION_IDS,
                        newAnnotId: { 'annotId': annotationSelected.id, 'layerId': annotationSelected.layerID, deleted: false, createdDate: annotationSelected.createdDate, updatedDate: annotationSelected.updatedDate }
                    });
                    yield put({
                        type: ActionTypes.STORE_TEMP_ANNOTATION_IDS,
                        newAnnotId: annotationSelected.id
                    });
                }
            }

            yield call(updateApiCallStatus, true);
            BrowserUtil.enableEvents();
            return;
        }

        if (tempIdsArray && tempIdsArray[annotationSelected.id] && annotationSelected.type === RayAnnotationType.LINE_AnnotationType) {
            // replace the tempId with actual annotation Id
            const annot = findWhere(allAnnotId, { annotId: annotationSelected.id });
            if (annot) {
                annot.annotId = tempIdsArray[annotationSelected.id][0];
                annot.updatedDate = new Date();

                yield put({
                    type: ActionTypes.STORE_ANNOTATION_IDS,
                    allAnnotId: [...allAnnotId]
                });
            }

            const saveTemp = annotationSelected.id;

            yield safe(call(handleUpdateShape, annotationSelected, tempIdsArray[annotationSelected.id][0]), "AnnotationLayerSaga.js", "handleUpdateShape");
            annotationSelected.id = tempIdsArray[saveTemp][0];

            yield put({
                type: ActionTypes.REMOVE_TEMP_IDS_ARRAY,
                deletedId: saveTemp
            });
        }


        const isAlertUpdateCall = yield select(getIsAlertUpdate);
        const tempCheckedArray = yield select(getTempAnnotId);

        const annotationdataProto = yield call(annotationData.setAnnotationData, annotationSelected);

        const resp = yield call(annotationsApi.UpsertAnnotation, annotationdataProto);
        if (annotationdataProto.annotationID === 0 && !resp.responseHeader.error) {
            if (tempCheckedArray.includes(annotationSelected.id)) {
                yield put({
                    type: ActionTypes.REMOVE_TEMP_ANNOTATION_IDS,
                    deletedId: annotationSelected.id
                });
            }
        }
        //Update alerts tab when alert is added/removed
        if (isAlertUpdateCall && !resp.responseHeader.error || annotationdataProto.isAlert && !resp.responseHeader.error) {
            //to save alert updated in reducer
            yield put({
                type: ActionTypes.HANDLE_ALERT_CHANGE,
                isAlertUpdate: false
            })
            yield put(startAlertDataWorker())
        }

        if (!isDeleteAlert) {
            yield safe(call(handleUpdateShape, annotationSelected, resp.annotationId), "AnnotationLayerSaga.js", "handleUpdateShape");
        }

        if (annotationdataProto.annotationID === 0) {
            yield put({
                type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
                annotation: resp.annotationId
            });

            if (resp.annotationId) {
                yield put({
                    type: ActionTypes.STORE_NEW_ANNOTATION_IDS,
                    newAnnotId: { 'annotId': resp.annotationId, 'layerId': annotationdataProto.layerID, deleted: false, createdDate: annotationSelected.createdDate, updatedDate: annotationSelected.updatedDate }
                });
            }
        }
        else {
            // re-update the date/time of already drawn annotation on edit - color/stroke change, drag/resize
            const annot = findWhere(allAnnotId, { annotId: resp.annotationId });
            if (annot) {
                annot.updatedDate = new Date();

                yield put({
                    type: ActionTypes.STORE_ANNOTATION_IDS,
                    allAnnotId: [...allAnnotId]
                });
            }
        }

        yield call(updateApiCallStatus, true);
        BrowserUtil.enableEvents();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleSaveAnnotation ${error}`);
    }
}

//check alert is avalable and render Alerts tab in ri panel
function* checkAlertExists(deletables, isDelete = false) {
    try {
        const alertList = AlertStore.getTLAlertList();
        alertList.filter((item) => deletables.includes(item.annotationid));
        if (alertList.length > 0) {
            if (isDelete) {
                yield put({
                    type: ActionTypes.STORE_ALERT_DELETE_STATUS,
                    isAlertDelete: true
                });
            }
            yield put(startAlertDataWorker());
        } else {
            yield put({
                type: ActionTypes.STORE_ALERT_DELETE_STATUS,
                isAlertDelete: false
            });
        }
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerSaga.js, checkAlertExists ${error}`);
    }
}

function* handleUpdateShape(annotation, annotResId) {
    const type = annotation.type;

    switch (type) {
        case RayAnnotationType.LINE_AnnotationType: {
            let lines = yield select(getLine);
            lines = yield call(updateAnnotationId, lines, annotation.id, annotResId);
            yield put({
                type: ActionTypes.HANDLE_LINE_SAVE_ID_SUCCESS,
                lines: [...lines],
            });

            yield call(updateTargetAnnotation, lines, annotResId);
            break;
        }
        case RayAnnotationType.TEXT_AnnotationType: {
            let texts = yield select(getText);
            texts = yield call(updateAnnotationId, texts, annotation.id, annotResId);
            yield put({
                type: ActionTypes.HANDLE_TEXT_SAVE_ID_SUCCESS,
                texts: [...texts],
            });

            yield call(updateTargetAnnotation, texts, annotResId);
            break;
        }
        case RayAnnotationType.RECT_AnnotationType: {
            let rectangles = yield select(getRectangle);
            rectangles = yield call(updateAnnotationId, rectangles, annotation.id, annotResId);

            yield put({
                type: ActionTypes.HANDLE_RECTANGLE_SAVE_ID_SUCCESS,
                rectangles: [...rectangles],
            });

            yield call(updateTargetAnnotation, rectangles, annotResId);
            break;
        }
        case RayAnnotationType.ELLIPSE_AnnotationType: {
            let ellipses = yield select(getEllipse);
            ellipses = yield call(updateAnnotationId, ellipses, annotation.id, annotResId);

            yield put({
                type: ActionTypes.HANDLE_ELLIPSE_SAVE_ID_SUCCESS,
                ellipses: [...ellipses],
            });

            yield call(updateTargetAnnotation, ellipses, annotResId);
            break;
        }
        case RayAnnotationType.TRIANGLE_AnnotationType: {
            let triangles = yield select(getTriangle);
            triangles = yield call(updateAnnotationId, triangles, annotation.id, annotResId);

            yield put({
                type: ActionTypes.HANDLE_TRIANGLE_SAVE_ID_SUCCESS,
                triangles: [...triangles],
            });

            yield call(updateTargetAnnotation, triangles, annotResId);
            break;
        }
        case RayAnnotationType.PARALLEL_LINES_AnnotationType: {
            let channelLines = yield select(getChannelLine);
            channelLines = yield call(updateAnnotationId, channelLines, annotation.id, annotResId);
            yield put({
                type: ActionTypes.HANDLE_CHANNEL_LINE_SAVE_ID_SUCCESS,
                channelLines: [...channelLines],
            });

            yield call(updateTargetAnnotation, channelLines, annotResId);
            break;
        }
        case RayAnnotationType.FIB_AnnotationType: {
            let fibonacciLines = yield select(getFibonacciLine);
            fibonacciLines = yield call(updateAnnotationId, fibonacciLines, annotation.id, annotResId);
            yield put({
                type: ActionTypes.HANDLE_FIBONACCI_LINE_SAVE_ID_SUCCESS,
                fibonacciLines: [...fibonacciLines],
            });

            yield call(updateTargetAnnotation, fibonacciLines, annotResId);
            break;
        }
        case RayAnnotationType.MEASUREMENT_AnnotationType: {
            let measurementData = yield select(getMeasurementData);
            measurementData = yield call(updateAnnotationId, measurementData, annotation.id, annotResId);
            yield put({
                type: ActionTypes.HANDLE_MEASUREMENT_LINE_SAVE_ID_SUCCESS,
                measurementData: [...measurementData],
            });

            yield call(updateTargetAnnotation, measurementData, annotResId);
            break;
        }
        default:
            break;
    }
}

function* updateAnnotationId(annotation, tempId, annotResId) {
    try {
        annotation.forEach((item) => {
            if (item.id === tempId) {
                item.id = annotResId;
            }
        });
        return annotation;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, updateAnnotationId ${error}`);
    }
}

function* updateTargetAnnotation(annotation, annotResId) {
    try {
        if (!AnnotationUtil.isAnnotationsTabActive()) {
            return;
        }

        // let tempIdsArray = yield select(getTempIdsArray);
        // if(tempIdsArray && tempIdsArray[annotResId]){ 
        //     annotResId = tempIdsArray[annotResId][0];
        // }

        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: false,
            annotSelected: annotation.find((item) => item.id === annotResId)
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, updateTargetAnnotation ${error}`);
    }
}

function* handleControlSelect({ e }) {
    try {
        const selectedAnnotId = e.target.attrs.id;
        const selectedIds = yield select(getSelectedAnnotId);
        if (!selectedIds.includes(selectedAnnotId)) {
            yield put({
                type: ActionTypes.UPDATE_SELECTED_ANNOTATIONS_ADD,
                id: e.target.attrs.id
            });
        } else {
            yield put({
                type: ActionTypes.UPDATE_SELECTED_ANNOTATIONS_REMOVE,
                id: e.target.attrs.id
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleControlSelect ${error}`);
    }
}

function* handleUndoDeletedAnnotations() {
    try {
        const layerDeleted = yield select(getIsLayerDelete);
        const deletedIds = yield select(getDeletedAnnotId);
        const isAlertUndo = yield select(getIsAlertDelete);
        if (!layerDeleted) {
            if (deletedIds.length > 0) {

                const deletables = yield call(removeTempLines, deletedIds);
                if (deletables.length > 0) {
                    const undoRequest = yield call(annotationsApi.UndoDeleteAnnotations, deletables);
                    if (isAlertUndo && !undoRequest.responseHeader.error) {
                        yield put(startAlertDataWorker());
                    }
                }

                yield put({
                    type: ActionTypes.RESTORE_DELETED_ANNOTATIONS,
                    annotDeletedId: deletedIds
                });
                yield put({
                    type: ActionTypes.ADD_VISIBLE_ANNOTATION_IDS,
                    revokedIds: deletedIds
                });
            }
        } else {

            yield call(annotationsApi.UndoAnnotationLayer, deletedIds);

            yield put({
                type: ActionTypes.REVOKE_LAYER_ON_UNDO
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleUndoDeletedAnnotations ${error}`);
    }
}
function* updateIndexFront(annotations) {
    try {
        const annotSelectedId = yield select(getSelectedAnnotId);
        let lineSelected = '';
        annotSelectedId.forEach((item) => {
            lineSelected = annotations.find((obj) => obj === item)
            if (lineSelected) {
                annotations.forEach((line, i) => {
                    if (item === line) {
                        annotations.splice(i, 1);
                        annotations.push(line);
                    }
                });
            }
        });
        return annotations;
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerSaga.js, updateIndexFront ${error}`);
    }
}
function* updateIndexBack(annotations) {
    try {
        const annotSelectedId = yield select(getSelectedAnnotId);
        let lineSelected = '';
        annotSelectedId.forEach((item) => {
            lineSelected = annotations.find((obj) => obj === item)
            if (lineSelected) {
                annotations.forEach((line, i) => {
                    if (item === line) {
                        annotations.splice(i, 1);
                        annotations.unshift(line);
                    }
                });
            }
        });
        return annotations;
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerSaga.js, updateIndexBack ${error}`);
    }
}
function* handleMoveFrontAnnotations() {
    try {
        const combinedAnnotations = yield select(getcombinedAnnotations);
        const modifiedIndex = yield call(updateIndexFront, combinedAnnotations);
        yield put({
            type: ActionTypes.REPLACE_ANNOTATION_COLLECTION,
            annotation: modifiedIndex
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleMoveFrontAnnotations ${error}`);
    }
}
function* handleMoveBackAnnotations() {
    try {
        const combinedAnnotations = yield select(getcombinedAnnotations);
        const modifiedIndex = yield call(updateIndexBack, combinedAnnotations);
        yield put({
            type: ActionTypes.REPLACE_ANNOTATION_COLLECTION,
            annotation: modifiedIndex
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationLayerSaga.js, handleMoveBackAnnotations ${error}`);
    }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/


export function* watchHandleClick() {
    yield takeLatest(ActionTypes.HANDLE_CLICK, handleClick);
};

export function* watchHandleMouseMove() {
    yield takeLatest(ActionTypes.HANDLE_MOUSE_MOVE, handleMouseMove);
};

export function* watchHandleDrag() {
    yield takeLatest(ActionTypes.HANDLE_DRAG_MOVE, handleDrag);
};

export function* watchHandleMouseDown() {
    yield takeLatest(ActionTypes.HANDLE_MOUSE_DOWN, handleMouseDown);
};

export function* watchSaveAnnotation() {
    yield takeLatest(ActionTypes.HANDLE_SAVE_ANNOTATION, handleSaveAnnotation);
};

export function* watchRemoveDrawing() {
    yield takeLatest(ActionTypes.REMOVE_DRAWING_ANNOTATION, handleRemoveDrawing);
};

export function* watchDeleteAllAnnotations() {
    yield takeLatest(ActionTypes.REMOVE_ALL_ANNOTATION, handleDeleteAllAnnotation);
};

export function* watchControlSelect() {
    yield takeLatest(ActionTypes.HANDLE_CONTROL_CLICK, handleControlSelect);
};

export function* watchConfirmDeleteAll() {
    yield takeLatest(ActionTypes.REMOVE_ALL_ANNOTATION_CONFIRM, handleDeleteAllAnnotationConfirm);
};

export function* watchUndoDelete() {
    yield takeLatest(ActionTypes.UNDO_DELETED_ANNOTATIONS, handleUndoDeletedAnnotations);
};

export function* watchoveFrontAnnotations() {
    yield takeLatest(ActionTypes.MOVE_ANNOTATIONS_TO_FRONT, handleMoveFrontAnnotations);
};

export function* watchMoveBackAnnotations() {
    yield takeLatest(ActionTypes.MOVE_ANNOTATIONS_TO_BACK, handleMoveBackAnnotations);
};

export function* watchUpdateRiPanelInfo() {
    yield takeLatest(ActionTypes.UPDATE_RI_PANEL_STATUS, updateRiPanelInfo);
}