import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetAlertsByMsidRequest extends BaseServiceRequest {

    constructor(msid, currencyCode, symbol) {
        super();
        super.addRequestUri("getbymsid2.pb");
        super.addPathParameter(msid.toString());
        super.addRequestParameter("cur", currencyCode);
        super.addRequestParameter("symbol", symbol);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "AlertsResponseData2";
    }
}

export default GetAlertsByMsidRequest;
