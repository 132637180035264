import React from 'react';

// import { isGICSSelected, MetricActionAlert } from "../../Actions/TabDataGraphAction";
import { isGICSSelected, MetricActionAlert } from "../../Actions/HeaderAction.js";
import { isGICSSelected_Fundamentals, MetricActionAlert_Fundamentals } from "../../Actions/FundamentalsActions";
import { RouteToRotationGraph } from "RotationGraphActions";
import { UpdateTemplisttoExplorer } from "../../Actions/ListActions";
import SettingsAction from "../../Actions/SettingsActions";
import { MenuItem, Dropdown } from "react-bootstrap";
import NavType from "NavType";
import CompareTabType from "CompareTabType";
import SmartViewType from "SmartViewType";
import LocalizationStore from "LocalizationStore";
import DatagraphStore from '../../Stores/NavModules/NavDataGraph/DataGraphStore';
import TabType from "Constants/TabType.js";
import SettingsStore from "SettingsStore";
import SymbolType from "SymbolType";
import store from '../../Redux/store';
import { dispatch } from "../../Redux/dispatch.js";
import { onPlotSymbol } from '../../Actions/DatagraphActions.js';

const IndustryGroupHeader = (
  { industryGroup,
    infoData,
    isGICS,
    isStock,
    isConstituent,
    view,
    industryListData
  }) => {
  const industryItem = industryListData && industryListData.filter((industry) => industry.WonIndustryCode === infoData.industry197Symbol);
  const loadWithRotaionGraph = industryItem.length > 0;
  const textIndGrouptyle = { textDecoration: 'none', cursor: 'pointer', pointerEvents: 'all', marginLeft: '0px' };
  const isMajorIndusrtry = view === SmartViewType.MAJORINDUSTRY;
  const leftMenuItemWON = [
    { header: LocalizationStore.getTranslatedData('sym_ingrp_won', 'Switch to WON Industry Group'), key: 'SWI' }
  ];
  const rightMenuItem = [
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vc', 'View Chart'), key: 'VC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vgc', 'View Global Constituents'), key: 'VGC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vrg', 'View Rotation Graph'), key: 'VRG' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_gics', 'Switch to GICS Industry'), key: 'SGI' },
    { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
  ];
  const rightMenuItem_withoutGraph = [
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vc', 'View Chart'), key: 'VC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vgc', 'View Global Constituents'), key: 'VGC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_gics', 'Switch to GICS Industry'), key: 'SGI' },
    { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
  ];
  const rightMenuItem_withoutChart = [
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vgc', 'View Global Constituents'), key: 'VGC' }
  ];
  const leftMenuItem_withoutGlobal = [
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' }
  ];
  const rightMenuItem_withoutGraphandGICS = [
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vc', 'View Chart'), key: 'VC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vlc', 'View Local Constituents'), key: 'VLC' },
    { header: LocalizationStore.getTranslatedData('sym_ingrp_vgc', 'View Global Constituents'), key: 'VGC' },
    // { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
  ];
  if(view != SmartViewType.SECTOR){
    rightMenuItem_withoutGraphandGICS.push(
      { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
    )
  }
  if (industryListData && industryListData.length > 0) {
    let objectArray = leftMenuItem_withoutGlobal.filter((item) => item.key === 'VMD');

    objectArray.length === 0 && leftMenuItem_withoutGlobal.push(
      { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
    )

    objectArray = rightMenuItem_withoutChart.filter((item) => item.key === 'VMD');

    objectArray.length === 0 && rightMenuItem_withoutChart.push(
      { header: LocalizationStore.getTranslatedData('MetDef_Menu', 'View Metric Definition'), key: 'VMD' }
    )
  }
  const onBlockMenuItemLeftClicked = (id) => () => {
    let viewSetting = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings.StockViewSettings;
    let symbol;
    switch (id) {
      case 'VC':
        /*Industry group setting update from Fundamental to Datagraph*/
        // let state = DatagraphStore.getState();
        // if (state && TabType.Fundamental === state.SelectedTabKey) {
        //   SettingsAction.updateTabSelection("DATAGRAPH");
        // }
        dispatch(onPlotSymbol(infoData.industry197Symbol,true,500,false,false,true));
        break;
      case 'VLC':
        symbol =  infoData.industry197Symbol;
        UpdateTemplisttoExplorer(
          {
            symbol: symbol,
            msId: infoData.msid,
            view: view,
            isGlobal: false
          }
        );
        break;
      case 'VGC':
        symbol = infoData.SymTypeEnum === SymbolType.INTERNATIONALSTOCK
          ? infoData.industry197Symbol.split('I')[0]
          : infoData.industry197Symbol;
        UpdateTemplisttoExplorer(
          {
            symbol: symbol,
            msId: infoData.msid,
            view: view,
            isGlobal: true
          }
        );
        break;
      case 'VRG':
        SettingsAction.updateCompareTabSelection(CompareTabType.RotationGraph);
        store.dispatch(RouteToRotationGraph(infoData, NavType.NavCompare));
        break;

      case 'SWI':
        if (view === SmartViewType.FUNDAMENTALS) {
          isGICSSelected_Fundamentals(false);
          // setisGICSFlag(false);
        }
        else {
          dispatch(isGICSSelected(false));
          viewSetting.isGICS = false;
          SettingsStore.saveSettings();
        }

        break;
      case 'SGI':
        if (view === SmartViewType.FUNDAMENTALS) {
          isGICSSelected_Fundamentals(true);
          // setisGICSFlag(true);
        }
        else {
          dispatch(isGICSSelected(true));
          viewSetting.isGICS = true;
          SettingsStore.saveSettings();
        }

        break;
      case 'VMD':
        if (view === SmartViewType.FUNDAMENTALS) {
          MetricActionAlert_Fundamentals(true);
        }
        else {
          dispatch(MetricActionAlert(true, ''));
        }

        break;
      default:
        break;
    }
  }

  return (
    +industryGroup === 0 ? 
    <div className="" id="div2" >{industryGroup}</div> :
      isStock
        ? isGICS
          ? <div className="graph-contex-menu shadow-none-btn">

            <Dropdown id="dropdown-custom-theme-contex">
              <Dropdown.Toggle className="noBgMenu">
                <div className="info-dot medium-normal" id="div2" style={textIndGrouptyle}>{industryGroup}</div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="list-contex">
                {
                  leftMenuItemWON.map((item) =>
                    <MenuItem key={item.key} className="small-normal" eventKey={item.key} onClick={onBlockMenuItemLeftClicked(item.key)}>{item.header}</MenuItem>
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
            {/* <ClickMenu data-disable-track-price="true" items={leftMenuItemWON} position="bottom" onselect={(option) => onBlockMenuItemRightClicked(option)} /> */}
          </div>

          : <div className="graph-contex-menu shadow-none-btn">
            <Dropdown id="dropdown-custom-theme-contex">
              <Dropdown.Toggle className="noBgMenu">
                <div className="info-dot medium-normal" id="div2" style={textIndGrouptyle}>{LocalizationStore.getTranslatedData("FilterBrowser_IndustryGroup_" + industryGroup.replace(/ /ig, "").toUpperCase(), industryGroup)}</div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="list-contex">
                {
                  loadWithRotaionGraph ?
                    rightMenuItem.map((item) =>
                      <MenuItem key={item.key} className="small-normal" eventKey={item.key} onClick={onBlockMenuItemLeftClicked(item.key)}>{item.header}</MenuItem>
                    )
                    :
                    rightMenuItem_withoutGraph.map((item) =>
                      <MenuItem key={item.key} className="small-normal" eventKey={item.key} onClick={onBlockMenuItemLeftClicked(item.key)}>{item.header}</MenuItem>
                    )
                }
              </Dropdown.Menu>
            </Dropdown>
            {/* <ClickMenu data-disable-track-price="true" items={leftMenuItemGICS} position="bottom" onselect={(option) => onBlockMenuItemRightClicked(option)} /> */}
          </div>
        : <div className="graph-contex-menu shadow-none-btn">
          <Dropdown id="dropdown-custom-theme-contex">
            <Dropdown.Toggle className="noBgMenu">
              <div className="info-dot medium-normal" id="div2" style={textIndGrouptyle}>{LocalizationStore.getTranslatedData("FilterBrowser_IndustryGroup_" + industryGroup.replace(/ /ig, "").toUpperCase(), industryGroup)}</div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="list-contex">
              {
                loadWithRotaionGraph ?
                  rightMenuItem.map((item) =>
                    <MenuItem key={item.key} className="small-normal" eventKey={item.key} onClick={onBlockMenuItemLeftClicked(item.key)}>{item.header}</MenuItem>
                  )
                  :
                  isConstituent
                    ?
                    isMajorIndusrtry
                      ?
                      leftMenuItem_withoutGlobal.map((item) =>
                        <MenuItem key={item.key} className="small-normal" eventKey={item.key} onClick={onBlockMenuItemLeftClicked(item.key)}>{item.header}</MenuItem>
                      )
                      :
                      rightMenuItem_withoutChart.map((item) =>
                        <MenuItem key={item.key} className="small-normal" eventKey={item.key} onClick={onBlockMenuItemLeftClicked(item.key)}>{item.header}</MenuItem>
                      )
                    :
                    rightMenuItem_withoutGraphandGICS.map((item) =>
                      <MenuItem key={item.key} className="small-normal" eventKey={item.key} onClick={onBlockMenuItemLeftClicked(item.key)}>{item.header}</MenuItem>
                    )
              }
            </Dropdown.Menu>
          </Dropdown>
          {/* {
          industryListData.length > 0 &&
          <ClickMenu data-disable-track-price="true" items={leftMenuItemNonStock} position="bottom" onselect={(option) => onBlockMenuItemRightClicked(option)} />
        } */}
        </div>
  )
}


export default IndustryGroupHeader;
