import GraphType from "../../../../../Constants/GraphType.js";
import Serializable from "Serializable";
import AnimationSpeedEnum from "../../../../../Constants/AnimationSpeedEnum.js";
import AnimationLookbackSpan from "../../../../../Constants/AnimationLookbackSpan.js";
class EPSAnimationSettings extends Serializable {

    constructor(periodicity) {
        super();
        switch (periodicity) {
            case GraphType.Daily:
                this.IsEarningsAnimationDisplayed = false;
                this.IsAnimateWithChartLoad = true;
                this.IsLoopbackEnabled = false; 
                this.IsStartAnimationAtVisibleDate = false;
                this.IsEarningsAnimationDocked = false;
                this.AnimationSpeedEnum = AnimationSpeedEnum.Fast;
                this.AnimationLookbackSpan = AnimationLookbackSpan.SixMonths;
                break;
            case GraphType.Weekly:
                this.IsEarningsAnimationDisplayed = true;
                this.IsAnimateWithChartLoad = true;
                this.IsLoopbackEnabled = false; 
                this.IsStartAnimationAtVisibleDate = false;
                this.IsEarningsAnimationDocked = true;
                this.AnimationSpeedEnum = AnimationSpeedEnum.Fast;
                this.AnimationLookbackSpan = AnimationLookbackSpan.ThreeYears;
                break;
            case GraphType.Monthly:
                this.IsEarningsAnimationDisplayed = false;
                this.IsAnimateWithChartLoad = true;
                this.IsLoopbackEnabled = false; 
                this.IsStartAnimationAtVisibleDate = false;
                this.IsEarningsAnimationDocked = false;
                this.AnimationSpeedEnum = AnimationSpeedEnum.Fast;
                this.AnimationLookbackSpan = AnimationLookbackSpan.ThreeYears;
                break;
            default:

        }
    }
    onSeralisedInfoRecieved(info) {
        Object.assign(this, info);
    }
    getDataObject() {
        return Object.assign({}, this);
    }

    getObjectMapKey() {
        return "EPSAnimationSettings";
    }

}

export default EPSAnimationSettings;
