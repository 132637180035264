import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GroupsInstrumentDataRequest extends BaseServiceRequest {

    constructor(indCode, countryCode) {
        super();
        super.addRequestUri("grpsinstruments2.pb");
        super.addRequestParameter("IndCode", indCode.toString());
        super.addRequestParameter("CountryCode", countryCode.toString());
        super.addRequestParameter("rts", '0');
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GROUPS;
    }

    getMessageName() {
        return "GrpInstResponse";
    }
}

export default GroupsInstrumentDataRequest;
