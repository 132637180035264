import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import DatagraphStore from "../DataGraphStore.js";
import { TabDataGraphConstants } from "../../../../Constants/TabDataGraphConstants.js";
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";
import SmartViewType from "../../../../Constants/SmartViewType.js";
import RiSubCategoryType from "RiSubCategoryType";
import SettingsStore from "SettingsStore";
import NavType from "NavType";
import { ActionTypes } from "../../../../Constants/AppColorConstants.js";
import { dispatch } from "../../../../Redux/dispatch.js";
import { updateSymbolEntryLoading } from "../../../../Actions/DatagraphActions.js";
import { updateCalenderControlProperties } from "../../../../Actions/CalenderControl/CalenderControlAction.js";

const CHANGE_EVENT = "change";
let _state;
let _currentAction = null;
class TabDataGraphStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        this.setMaxListeners(0);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getState() {
        if (_state === undefined) {
            _state = DatagraphStore.getState();
            var navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
            var periodicityOptions = navDataGraphSettings.TabDataGraphSettings.PeriodicityOptions;
            _state.LeftScaleWidth = navDataGraphSettings.TabDataGraphSettings.LeftScaleWidth;
            _state.RightScaleWidth = navDataGraphSettings.TabDataGraphSettings.RightScaleWidth;
            _state.periodicity = navDataGraphSettings.TabDataGraphSettings.Periodicity;
            _state.PeriodicityOptions = periodicityOptions;
            _state.nodeWidth = navDataGraphSettings.TabDataGraphSettings.NodeWidth;
            _state.hasSymbolTypeChanged = false;
            _state.isGICS = navDataGraphSettings.TabDataGraphSettings.StockViewSettings.isGICS;
            _state.metricActionAlert = false;
            _state.metricActionChanged = false;
            _state.isGICSChanged = false;
            _state.isAnimationMessageDialogAvailable = navDataGraphSettings.isAnimationMessageDialogAvailable;
            _state.disablePeriodicityButton = true;
            _state.isSymbolInputLoading = true;
            _state.closeAllMenu = false;
        }
        return _state;
    }

    getSymbolInfo() {
        return DatagraphStore.getSymbolInfo();
    }

    getTabDatagraphSettings() {
        var navDataGraphSettings = DatagraphStore.getNavDataGraphSettings();
        return navDataGraphSettings.TabDataGraphSettings;
    }

    getActiveStockType() {
        return _state ? _state.SymbolType : undefined;
    }

    isValidAction() {
        let settings = SettingsStore.getConsoleSettings();
        if (settings && settings.ActiveNav && settings.ActiveNav !== NavType.NavSymbol) {
            return false;
        }
        if (_state === null) {
            return false;
        }
        return true;
    }

    getRICategoryName(categoryType) {
        let RICategoryName = null;
        switch (categoryType) {
            case RiSubCategoryType.None:
                RICategoryName = 'Node';
                break;
            case RiSubCategoryType.News:
                RICategoryName = 'News';
                break;
            case RiSubCategoryType.BrokerReports:
                RICategoryName = 'BrokerReports';
                break;
            case RiSubCategoryType.Sponsorship:
                RICategoryName = 'Sponsorship';
                break;
            case RiSubCategoryType.MeasurementTools:
                RICategoryName = 'MeasurementTools';
                break;
            case RiSubCategoryType.Annotations:
                RICategoryName = 'Annotations';
                break;
            case RiSubCategoryType.Summary:
                RICategoryName = 'Summary';
                break;
            case RiSubCategoryType.FindModels:
                RICategoryName = 'FindModels';
                break;
            case RiSubCategoryType.FindNotes:
                RICategoryName = 'FindNotes';
                break;
            case RiSubCategoryType.LlrView:
                RICategoryName = 'LlrView';
                break;
            case RiSubCategoryType.Twitter:
                RICategoryName = 'Twitter';
                break;
            case RiSubCategoryType.Transactions:
                RICategoryName = 'Transactions';
                break;
            case RiSubCategoryType.Alerts:
                RICategoryName = 'Alerts';
                break;
            case RiSubCategoryType.Ownership:
                RICategoryName = 'Ownership';
                break;
            case RiSubCategoryType.CheckList:
                RICategoryName = 'CheckList';
                break;
            case RiSubCategoryType.Holdings:
                RICategoryName = 'Holdings';
                break;
            case RiSubCategoryType.SimilarFunds:
                RICategoryName = 'SimilarFunds';
                break;
            case RiSubCategoryType.Management:
                RICategoryName = 'Management';
                break;
            case RiSubCategoryType.Allocation:
                RICategoryName = 'Allocation';
                break;
            default:
                break;
        }
        return RICategoryName;
    }

    updateSymbolInputState(isLoading = false) {
        _state.isSymbolInputLoading = isLoading;
        _currentAction = TabDataGraphConstants.ActionTypes.UPDATE_SYMBOL_INPUT_STATE;
        this.emit(CHANGE_EVENT);
    }

    updatePeriodicityButtonState(isDisabled = false, isLoading) {
        if(!_state) _state = this.getState();
        
        dispatch(updateSymbolEntryLoading(isDisabled, isLoading))
        dispatch(updateCalenderControlProperties({disableDateTimeField: isDisabled}));
        _state.disablePeriodicityButton = isDisabled;
        if (isLoading !== undefined) {
            this.updateSymbolInputState(isLoading);
        }
        _currentAction = TabDataGraphConstants.ActionTypes.UPDATE_PERIODICITY_BUTTON_STATE;
        this.emit(CHANGE_EVENT);
    }

    getCurrentAction() {
        return _currentAction;
    }

    isThemeSettingChanged() {
        return _state && (_state.themeChanged || _state.gridLineChanged);
    }

    clearCurrentAction() {
        _currentAction = null;
    }

    dispatcherCallback(payload) {
        AppDispatcher.waitFor([SettingsStore.dispatchToken]);
        const action = payload.action;
        if (!this.isValidAction()) {
            return;
        }

        switch (action.actionType) {
            case TabDataGraphConstants.ActionTypes.SET_DEFAULT_STATE:
            case TabDataGraphConstants.ActionTypes.PERIODICITY_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.periodicity = this.getTabDatagraphSettings().Periodicity;
                _currentAction = TabDataGraphConstants.ActionTypes.PERIODICITY_CHANGED;
                this.emit(CHANGE_EVENT);
                break;
            case TabDataGraphConstants.ActionTypes.DAY_CHANGE:
                if (!_state) {
                    _state = this.getState();
                }
                _currentAction = TabDataGraphConstants.ActionTypes.DAY_CHANGE;
                this.emit(CHANGE_EVENT);
                break;
            case SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.STOCK;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.CURRENCY_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.CURRENCY;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.CRYPTO_CURRENCY_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.CRYPTOCURRENCY;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.ETF_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.ETF;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.CEF_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.CEF;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.INDEX_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.INDEX;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.FUND_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.FUND;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.FUTURES_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.FUTURES;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.CASHSPOTS_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.CASHSPOTS;
                _state.SymbolInfo = action.data;
                break;
            case SettingsConstants.ActionTypes.INDUSTRYGROUP_SYMBOL_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.Symbol = action.data.Symbol;
                _state.SymbolType = SmartViewType.INDUSTRYGROUP;
                _state.SymbolInfo = action.data;
                break;
            case ActionTypes.RESTORE_DARK_COLOR:
            case ActionTypes.RESTORE_LIGHT_COLOR:    
            case ActionTypes.CUSTOM_COLOR_CHANGED:
            case SettingsConstants.ActionTypes.THEME_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.themeChanged = true;
                this.clearCurrentAction();
                this.emit(CHANGE_EVENT);
                setTimeout(() => {
                    _state.themeChanged = false;
                }, 0);
                break;
            case SettingsConstants.ActionTypes.GRID_COLOR_CHANGED:
                if (!_state) {
                    _state = this.getState();
                }
                _state.gridLineChanged = true;
                this.clearCurrentAction();
                this.emit(CHANGE_EVENT);
                setTimeout(() => {
                    _state.gridLineChanged = false;
                }, 0);
                break;
            case TabDataGraphConstants.ActionTypes.GICSSELECTED:
                if (_state.isGICS !== action.data) {
                    _state.isGICS = action.data;
                    _state.isGICSChanged = true;
                }
                this.clearCurrentAction();
                this.emit(CHANGE_EVENT);
                break;
            case TabDataGraphConstants.ActionTypes.SET_METRIC_ACTION_ALRET:
                if (_state.metricActionAlert !== action.data) {
                    _state.metricActionAlert = action.data.data;
                    _state.metricActionChanged = true;
                }
                this.clearCurrentAction();
                this.emit(CHANGE_EVENT);
                break;
            case TabDataGraphConstants.ActionTypes.CHANGE_ISGICS_STATUS:
                _state.isGICSChanged = false;
                break;
            case TabDataGraphConstants.ActionTypes.CHANGE_METRIC_ACTION_STATUS:
                _state.metricActionChanged = false;
                break;
            case SettingsConstants.ActionTypes.ANIMATION_MESSAGE_DIALOG_AVAILABLE_CHANGE:
                if (!_state) {
                    _state = this.getState();
                }
                _state.isAnimationMessageDialogAvailable = action.data;
                let navDataGraphSetting = DatagraphStore.getNavDataGraphSettings();
                navDataGraphSetting.isAnimationMessageDialogAvailable = action.data;
                SettingsStore.saveSettings();
            case TabDataGraphConstants.ActionTypes.HIDE_OTHER_MENU:
                _state.closeAllMenu = true;
                _currentAction = TabDataGraphConstants.ActionTypes.HIDE_OTHER_MENU;
                this.emit(CHANGE_EVENT);
                break;
            default:
                break;
        }
    }
}

const tabDatagraphStore = new TabDataGraphStore();
export default tabDatagraphStore;
