import keyMirror from "keymirror"

export const ExportTypes = {
    Excel:"1",
    Csv:"2",
    TabDelimited:"3"
}

export const ExportExcelConstants = {
    ActionTypes: keyMirror({
        EXPORT_EXCEL_START: null,
        EXPORT_EXCEL_END: null,
        EXPORT_EXCEL_SHOW_DIALOG:"EXPORT_EXCEL_SHOW_DIALOG"
    })

}