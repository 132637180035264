import TimeLine from './TimeLine.js';
import StockMarketUtil from "../../../Utils/StockMarketUtil"

class AnnualTimeline extends TimeLine {
    Padding(quarters, xDate, endDate) {
        let curDate;
        if (quarters === 0) {
            curDate = new Date(super.GetMDate(xDate));
            if (this.padding) {
                for (let i = 0; i < 4; i++) {
                    curDate.setMonth(curDate.getMonth() + 1);
                    if (curDate.getDay() == 6)
                        curDate.setDate(curDate.getDate() - 1);
                    if (curDate.getDay() == 0)
                        curDate.setDate(curDate.getDate() - 2);
                }
            }
            curDate = StockMarketUtil.GetMEndDate(curDate);
        }
        else {
            curDate = new Date(super.GetMDate(xDate));
            curDate = new Date(new Date(curDate).setMonth(curDate.getMonth() + (3 * quarters)));
            curDate = StockMarketUtil.GetAEndDate(curDate);
            if (endDate > curDate)
                curDate = new Date(endDate);
        }
        return curDate;
    }
    SetMCVerticalGrid(startTime, endDate, mtDates, earnReportDate, fcQtrEstCount) {
        let x = 0;
        let quarters = 8;
        let padDate = new Date(endDate);
        if (quarters > 0 && fcQtrEstCount > 0 && earnReportDate.getFullYear() > 1900)
            padDate = new Date(earnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 4*365);
        }
        padDate = new Date(padDate.getFullYear() + 1, 0, 1);
        let pDate = new Date(this.Padding(quarters, padDate, endDate));
        pDate.setDate(pDate.getDate() + 4*365);
        let cDate = new Date(pDate);

        while (cDate >= startTime) {
            const xDate = new Date(cDate);

            const info = {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };
            mtDates[x] = info;
            const cMonth = cDate.getMonth();
            const aMonth = cMonth + 1;
            const year = cDate.getFullYear();
            if (pDate.getFullYear() > year && year % 50 === 0) {
                mtDates[x].XLabel = aMonth === 12 ? year : "";
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].IsFiscalLabel1 = true;
                mtDates[x].IsFiscalLabel2 = true;
                mtDates[x].XLine = 3;
                mtDates[x].IsCalendarLine = true;
                pDate = xDate;
            }
            x++;
            cDate = new Date(year, 0, 1);
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === 6)
                cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === 0)
                cDate.setDate(cDate.getDate() - 2);
        }
    }
    SetVerticalGrid(startTime, endDate, timeLineData, earnReportDate, quarters = 8) {
        let mtDates = timeLineData.dates;
        var x = 0;
        var padDate = new Date(endDate);
        if (quarters > 0 && earnReportDate.getFullYear() > 1900)
            padDate = new Date(earnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 4*365);
        }
        padDate = new Date(padDate.getFullYear() + 1, 0, 1);
        var pDate = new Date(this.Padding(quarters, padDate, endDate));
        pDate.setDate(pDate.getDate() + 4*365);
        var cDate = new Date(pDate);
        var pushNext = false;
        var prevYear = new Date(pDate.getFullYear() + 1, 0, 1).getFullYear();

        while (cDate >= startTime) {
            var xDate = new Date(cDate);
            
            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };
            mtDates[x] = info;
            var cMonth = cDate.getMonth();
            var aMonth = cMonth + 1;
            var year = cDate.getFullYear();
            if (pDate.getFullYear() > year && year % 10 === 0 && aMonth === 12) {
                pushNext = true;
                //prevYear = year; =====>>>>>>>> index issue in Compare graph removing 'year < prevYear'
            }
            if (pushNext && aMonth === 12 && year < prevYear) { 
                pushNext = false;
                prevYear = year;
                mtDates[x].XLabel = year;
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].IsFiscalLabel1 = true;
                mtDates[x].IsFiscalLabel2 = true;
                mtDates[x].XLine = 1;
                mtDates[x].IsCalendarLine = true;
                pDate = xDate;
                if (timeLineData.FirstLabelIndex === -1 && mtDates[x].XLabel !== "") {
                    timeLineData.FirstLabelIndex = x;
                }
                if (mtDates[x].XLabel !== "") {
                    timeLineData.TimeLineLabel.push(mtDates[x]);
                }
            }
            x++;
            cDate = new Date(year, 0, 1);
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === 6)
                cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === 0)
                cDate.setDate(cDate.getDate() - 2);
        }
    }
}
const annualTimeline = new AnnualTimeline();
export default annualTimeline;
