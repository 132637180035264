import KeyMirror from "keymirror";

export const CalenderControlConstants = {
    ActionTypes: KeyMirror({
        CLOSE_DATE_PICKER: null,
        UPDATE_CALENDER_MONTH: null,
        ADD_SUBTRACT_YEAR: null,
        UPDATE_SELECTED_DATE: null,
        UPDATE_INPUT_DATE: null,
        OPEN_DATE_PICKER: null,
        UPDATE_CALENDER_YEAR: null,
        UPDATE_EVENT_DATE: null,
        UPDATE_CALENDER_IS_PINNED: null,
        UPDATE_INPUT_DATE_VALUE: null,
        UPDATE_SHOW_DATE_PICKER: null,
        UPDATE_CALENDER_CONTROL_PROPERTIES: null,
        STEP_FORWARD_IN_TIME: null,
        STEP_BACKWARD_IN_TIME: null,
        SET_MOST_RECENT_DATE: null,
        UPDATE_LANGUAGE: null,
    })
}

export const CalenderEventConstant = {
    KEY_DATE_FOR: "KEY_DATE_FOR",
    Reorganization: "Reorganization",
    Corporate_Events: "Corporate Events",
    Earnings: "Earnings",
    Major_News: "Major News"
}