import { DataGraphConstants } from "../../../../Constants/DataGraphConstants";
import defaultDataGraphSetting from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/DefaultDataGraphSettings";
import GraphType from "../../../../Constants/GraphType";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "SettingsStore";

const { ActionTypes } = PriceChartConstants;
const initialDialogState = {
    isCorpEventDialogOpen: false,
    corpEventSettings: [],
    selectedPeriodicity: GraphType.Weekly,
}
const initialState = {
    corpeventsData: [],
    EPSAdded: false,
    CorpEventHeaderText: LocalizationStore.getTranslatedData("ch_ce", "CORPORATE EVENTS"),
    corporateEventsSettings: '',
    isCorpEventVisible: true,
    defaultCorpEventSettings: defaultDataGraphSetting.getCorporateEventsSetting(),
    ...initialDialogState
}
const CorpEventsReducers = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.CORP_EVENTS_DATA_READY:
            return {
                ...state,
                corpeventsData: action.corpEvents ? action.corpEvents : [],
                corporateEventsSettings: action.corporateEventsSettings,
                IsCollapsed: action.corporateEventsSettings.IsCollapsed,
                isCorpEventVisible: action.isCorpEventVisible
            }
        case ActionTypes.SAVE_CORP_EVENTS_STATE_DATA:
            return {
                ...state,
                EPSAdded: action.EPSAdded
            }
        case ActionTypes.CORP_EVENTS_SETTINGS_UPDATE:
            if(!state.corpEventSettings){
                return state;
            }
            return {
                ...state,
                isCorpEventVisible: action.isCorpEventVisible
            }
        case ActionTypes.CLEAR_CORP_EVENTS_DATA:
            return {
                ...initialState,
                isCorpEventVisible: action.isCorpEventVisible
            }
        case ActionTypes.UPDATE_CORP_EVENT_DIALOG_STATES:
            return {
                ...state,
                isCorpEventDialogOpen: action.isCorpEventDialogOpen,
                corpEventSettings: action.corpEventSettings,
                selectedPeriodicity: action.selectedPeriodicity
            }
        case ActionTypes.CANCEL_CORP_EVENT_DIALOG:
            return {
                ...state,
                ...initialDialogState
            }
        case ActionTypes.CHANGE_CORP_EVENT_SELECTED_PERIODICITY:
            return {
                ...state,
                selectedPeriodicity: action.selectedPeriodicity
            }
        case ActionTypes.TOGGLE_CORP_EVENT_PROPERTY:
            state.corpEventSettings = state.corpEventSettings.map((item)=> {
                if(item.periodicity === state.selectedPeriodicity){
                    item.subItems.map((subItem)=>{
                        if(subItem.property === action.property){
                            subItem.value = !subItem.value
                        }
                        return subItem;
                    })
                }
                return item;
            })
            return {
                ...state,
            }
        case DataGraphConstants.ActionTypes.TOGGLE_CORP_EVENTS:
            state.corporateEventsSettings.IsCollapsed = !state.IsCollapsed
            SettingsStore.saveSettings();
            return {
                ...state,
                IsCollapsed: !state.IsCollapsed,
            }
        default:
            return state;
    }
}
export default CorpEventsReducers;