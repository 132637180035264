import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import {ExportTypes, ExportExcelConstants} from "../../../../Constants/ExportExcelConstants.js";

const CHANGE_EVENT = "change";
let _currentAction = null;
let _data = null;

let _state = {
    inProgress: false,
    exportType: ExportTypes.Excel,
    categoryType: 0, 
    nodeId: 0, 
    sourceListId: 0,
    fileName: ''
}

class ExportExcelStore extends EventEmitter{
    constructor(){
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    startExport(){
        _state.inProgress = true;
        this.emit(CHANGE_EVENT);
    }

    endExport(){
        _state.inProgress = false;
        this.emit(CHANGE_EVENT);
    }

    getState(){
        return _state;
    }

    getCurrentAction(){
        return _currentAction;
    }

    getData(){
        return _data;
    }

    dispatcherCallback(payload){
        const action = payload.action;
        const data = action.data;
        switch(action.actionType){
            case ExportExcelConstants.ActionTypes.EXPORT_EXCEL_START:
                  _currentAction = ExportExcelConstants.ActionTypes.EXPORT_EXCEL_START;
                  _state.exportType = payload.action.data.exportType ? payload.action.data.exportType :_state.exportType,//set the export type
                  _state.categoryType = payload.action.data.categoryType ? payload.action.data.categoryType :_state.categoryType,
                  _state.nodeId = payload.action.data.nodeId ? payload.action.data.nodeId :_state.nodeId,
                  _state.sourceListId = payload.action.data.sourceListId ? payload.action.data.sourceListId :_state.sourceListId,
                  _state.fileName = payload.action.data.fileName ? payload.action.data.fileName :_state.fileName
                this.startExport();
            break;
            case ExportExcelConstants.ActionTypes.EXPORT_EXCEL_END:
                _currentAction = ExportExcelConstants.ActionTypes.EXPORT_EXCEL_END;
                _data = data;
               this.endExport();
            break;
            case ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG:
                _currentAction = ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG;
                _state.categoryType = payload.action.data.categoryType,
                _state.nodeId = payload.action.data.nodeId,
                _state.sourceListId = payload.action.data.sourceListId,
                _state.fileName = payload.action.data.fileName,
                this.emit(CHANGE_EVENT);
                //TODO Assign export parameters to state.
            default:
            break;
        }
    }
}

const exportExcelStore = new ExportExcelStore();
export default exportExcelStore;
