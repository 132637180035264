import DatagraphSettings from "../Modules/DataGraph/DatagraphSettings.js";
import ListManagerSettings from "../Modules/ListManager/ListManagerSettings.js";
import CompareSettings from "../Modules/Compare/CompareSettings.js";
import NewsSettings from "../Modules/News/NewsSettings.js";
import NotificationSettings from "../Modules/Notification/NotificationSettings.js";
import ConsoleSetting from "./ConsoleSetting";
import NavType from "NavType";
import ThemeType from "ThemeType";
import AppColorSettings from "../Modules/AppColor/AppColorSettings";

class DefaultSettings {
  getDefaultSettings() {   
    let settings = new ConsoleSetting();
    settings.ActiveNav = NavType.NavSymbol;
    settings.isCurrencyConversionChecked = false;
    settings.isMatchFinancials = true;
    settings.selectedCurrencyCode = 'USD';
    settings.isDualCurrency = false;
    settings.defaultCurrency = 'USD';

    let dataGraphSettings = new DatagraphSettings();
    settings.NavDatagraphSettings = dataGraphSettings.getDefaultDatagraphSettings();

    let listManagerSettings = new ListManagerSettings();
    settings.NavListManagerSettings = listManagerSettings.getDefaultListManagerSettings();

    let compareSettings = new CompareSettings();
    settings.NavCompareSettings = compareSettings.getDefaultCompareSettings();

    let newsSettings = new NewsSettings();
    settings.NavNewsSettings = newsSettings.getDefaultNewsSettings();

    let userNotificationSettings = new NotificationSettings();
    settings.UserNotificationSettings = userNotificationSettings.getDefaultUserNotificationSettings();
    let appColorSettings = new AppColorSettings();
    settings.AppColorSettings = appColorSettings;

    settings.printSettings = settings.getDefaultPrintSettings();

    settings.currentTheme = ThemeType.Dark;
    settings.originalTheme = ThemeType.Dark;
    settings.gridLineDark = 38;
    settings.gridLineLight = 205;
    settings.isUploadedExternalData = false;
    return settings;
  }
}
const defaultSetting = new DefaultSettings();
export default defaultSetting;
