import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetScreenScheduleRequest extends BaseServiceRequest {
    constructor(screenId) {
        super();
        super.addRequestUri("getscreenschedule.pb");
        super.addRequestParameter("screenid", screenId.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "SchedulerScreenResponse";
    }
}
export default GetScreenScheduleRequest;