import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import dot_pattern from '../../../../../Asset/images/dot.png';
import numeral from "numeral";
import React from "react";
import RotationArithmeticScale from "Utils/Scales/RotationArithmeticScale.js";
import ScalesHelper from "Utils/Scales/ScalesHelper.js";
import SettingsStore from "SettingsStore";
import StringUtil from "StringUtil";
import TabDatagraphStore from "../../../../../Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
const cellWidth = require("text-width");
import { each, max, min, isObject } from "underscore";
import extremeDataValue from "../../../../../Utils/ExtremeDataValue.js";

class PaginatedGraphView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // nodeWidth: props.nodeWidth,
            height: props.Height,
            width: 0,
            imgLoaded: false,
        };
        this.scale = new RotationArithmeticScale();
        this.textProperty = {
            family: 'calibri',
            size: "11px"
        };
        this.initScale = this.initScale.bind(this);
        this.initZeroLine = this.initZeroLine.bind(this);
        this.drawBarGraph = this.drawBarGraph.bind(this);
        this.drawBar = this.drawBar.bind(this);
        this.addTextValues = this.addTextValues.bind(this);
        this.getTextWidth = this.getTextWidth.bind(this);
        this.fillTextWithPos = this.fillTextWithPos.bind(this);
        this.setBars = this.setBars.bind(this);
        this.drawOnlyText = this.drawOnlyText.bind(this);
    }

    initScale(graphedRow, height, width) {
        if (graphedRow.Row.Header.HeaderId === 7) {
            const highValues = [];
            const lowValues = [];
            each(graphedRow.cellItems, (c) => {
                const xAxis = (width - (c.XPoint * this.props.nodeWidth));
                if (xAxis > 0 && (!StringUtil.isEmpty(c.HighValue) || !StringUtil.isEmpty(c.LowValue))) {
                    highValues.push(c.HighValue);
                    lowValues.push(c.LowValue);
                }
            });

            if (StringUtil.isEmpty(highValues) || StringUtil.isEmpty(lowValues)) {
                return false;
            }

            const maxValue = max(highValues);
            const minValue = min(lowValues);
            if (Math.abs(minValue - maxValue) < Number.EPSILON) {
                return false;
            }
            const interval = ScalesHelper.getInterval(minValue, maxValue, height - 30, 10);
            this.scale.initYScale(maxValue, minValue, interval, height - 30);
            return true;
        }
        else {
            const values = [];
            each(graphedRow.cellItems, (c) => {
                const xAxis = (width - (c.XPoint * this.props.nodeWidth));
                if (xAxis > 0 && !StringUtil.isEmpty(c.ActualValue)) {
                    values.push(c.ActualValue);
                }
            });


            let maxValue = Math.max.apply(null, values);
            let minValue = Math.min.apply(null, values);
            if (maxValue < 0) {
                maxValue = 0.0;
            }
            if (minValue > 0.0) {
                minValue = 0.0;
            }
            if (Math.abs(minValue - maxValue) < Number.EPSILON) {
                return false;
            }

            const interval = ScalesHelper.getInterval(minValue, maxValue, height - 30, 10);
            this.scale.initYScale(maxValue, minValue, interval, height - 30);
            return true;
        }
    }

    initZeroLine(width, height) {
        const zeroValue = (height + 15) - (30 + this.scale.computeY(0.0));
        const Y1 = zeroValue, Y2 = zeroValue, X1 = 0.0, X2 = width;
        this.ctx.clearRect(0, 0, width, height);
        this.ctx.beginPath();
        this.ctx.moveTo(X1, Y1);
        this.ctx.lineTo(X2, Y2);
        this.ctx.strokeStyle = this.zeroLineBrush;
        this.ctx.stroke();
        return zeroValue;
    }

    drawSVGTextItems(cellItem, xAxis, barWidth, yValue, originPoint, isPostive, isRangeChart, key) {
        const txtObj = {
            txt: "",
            x: 0,
            y: 0,
            key: 0
        };
        const initXCordCal = (xAxis - ((Math.abs(barWidth + 1) / 2)));
        const displayName = cellItem.header.HeaderId;
        const retVal = [];
        if (!isRangeChart) {
            const textWidth = this.getTextWidth(cellItem.Value);
            txtObj.txt = cellItem.Value;
            txtObj.x = (initXCordCal - (textWidth / 2));
            txtObj.y = isPostive ? yValue - 14 : yValue - 1;
            txtObj.key = displayName + key;
            retVal.push(this.fillSVGTextWithPos(txtObj));
        }
        else {
            if (!StringUtil.isEmpty(cellItem.LowValue)) {
                const textWidthLow = this.getTextWidth(cellItem.LowValue);
                const textXCord = initXCordCal - (textWidthLow / 2);
                txtObj.txt = cellItem.LowValue.toString();
                txtObj.x = textXCord;
                txtObj.y = yValue - 1;
                txtObj.key = `${displayName + key}Low`;
                retVal.push(this.fillSVGTextWithPos(txtObj));
            }
            if (!StringUtil.isEmpty(cellItem.HighValue)) {
                const textWidthHigh = this.getTextWidth(cellItem.HighValue);
                const textXCord = initXCordCal - (textWidthHigh / 2);
                txtObj.txt = cellItem.HighValue.toString();
                txtObj.x = textXCord;
                txtObj.y = originPoint - 14;
                txtObj.key = `${displayName + key}High`;
                retVal.push(this.fillSVGTextWithPos(txtObj));
            }
        }
        return retVal;
    }

    fillSVGTextWithPos = (txtObj) => {
        this.textBrush = ThemeHelper.getThemedBrush("fff000");
        return (
            <div key={txtObj.key}
                style={{
                    fontFamily: this.textProperty.family,
                    fontSize: this.textProperty.size,
                    color: this.textBrush,
                    position: "absolute",
                    height: '100%',
                    width: '100%',
                    zIndex: '10',
                    left: `${txtObj.x}px`,
                    top: `${txtObj.y}px`
                }}>{txtObj.txt}</div>
        );
    }

    drawSVGGraphData() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        const useCanvas = tabDataGraphSettings.useCanvas;
        const rowItems = this.props.RowItems;
        const headerIndex = this.props.graphData.isActive ? this.props.activeIndex : this.props.graphData.showGraphIndex;
        const graphedRow = rowItems.find((row) => row.index === headerIndex);
        if (graphedRow === undefined || graphedRow === null || useCanvas) {
            return "";
        }
        // Get the collection of FinancialBlockTableDivs (paginated-table divs)	
        const FinancialBlockTableDivs = document.getElementsByClassName("fin-block-table-divs-class");
        let widthTableDiv;

        // Iterate through the collection using a for loop	
        for (let i = 0; i < FinancialBlockTableDivs?.length; i++) {
            const element = FinancialBlockTableDivs[i];
            if (element?.offsetWidth) { widthTableDiv = element.offsetWidth }
        }

        const contentElement = document.getElementById("graphArea");

        if (contentElement !== null && contentElement !== undefined) {
            this.state.height = contentElement.offsetHeight;
            this.state.width = contentElement.offsetWidth;
        }
        else if (FinancialBlockTableDivs) {
            this.state.height = contentElement ? contentElement.offsetHeight : rowItems.length * 16;
            this.state.width = contentElement ? contentElement.offsetWidth : widthTableDiv;
        }
        else {
            this.state.height = rowItems.length * 16;
            this.state.width = document.body.clientWidth - 440;
        }

        const height = this.state.height;
        const width = this.state.width;
        const zeroValue = useCanvas ? this.initZeroLine(width, height) : 0;
        this.initSVGScale(height, width);

        let previousCellItem = null;
        let isPositive = false;
        let isRangeChart = false;
        const retVal = [];
        const cellItems = [];
        each(graphedRow.cellItems, (cellItem)=> {
            cellItems.push({...cellItem})
        })

        each(cellItems, (cellItem, key) => {
            if ((key + 1) < cellItems.length) {
                previousCellItem = cellItems[key + 1];
            }
            const xAxis = (width - (cellItem.XPoint * this.props.nodeWidth));
            if (xAxis < 0) {
                return retVal;
            }
            const previousCellXAxis = (width - (previousCellItem.XPoint * this.props.nodeWidth));
            const barWidth = previousCellXAxis - xAxis;
            // Formatting numerical higher values in respective abbrevation format
            const paddingText = 3
            let textWidth = cellWidth(cellItem.Value, {
                family: "calibri",
                size: 12
            });
      
            let cellValue = cellItem.Value;
      
            if (cellItem.header.IsPerc) {
                const Value = cellItem.ActualValue > 0 ? `+${cellValue}%` : `${cellValue}%`;
                textWidth = cellWidth(Value, {
                  family: "calibri",
                  size: 12
                });
            }
      
            const textW = Math.round(textWidth);
            if (textW + paddingText >= Math.abs(barWidth)) {
                if (cellItem.IsActual) {
                    if (cellItem.header.IsPerc) {
                        if (this.checkForAbbrevations(cellItem?.Value)) {
                            cellItem.Value = `${numeral(cellItem.Value.replace(/%/, "")).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase()}%`;
                            cellItem.Value = cellItem.ActualValue > 0 ? `+${cellItem.Value}` : cellItem.Value;
                        }
                    }
                    else {
                        if (cellItem.isPE) {
                            cellItem.Value = `${extremeDataValue.abbreviateValue(cellItem.LowValue, 0, "", true)}-
                        ${extremeDataValue.abbreviateValue(cellItem.HighValue, 0, "", true)}`
                        } else {
                            if (this.checkForAbbrevations(cellItem?.Value))
                                cellItem.Value = numeral(cellItem.Value).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase();
                        }
                    }
                }
                else {
                    if (cellItem.header.IsPerc) {
                        if (this.checkForAbbrevations(cellItem?.Value)) {
                            cellItem.Value = `${numeral(cellItem.Value?.replace(/%/, ""))?.format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`)?.toUpperCase()}%e`;
                            cellItem.Value = cellItem.ActualValue > 0 ? `+${cellItem.Value}` : cellItem.Value;
                        }
                    }
                    else {
                        if (cellItem.isPE) {
                            cellItem.Value = `${extremeDataValue.abbreviateValue(cellItem.LowValue, 0, "", true)}e-
                        ${extremeDataValue.abbreviateValue(cellItem.HighValue, 0, "", true)}e`
                        } else {
                            if (this.checkForAbbrevations(cellItem?.Value))
                                cellItem.Value = `${numeral(cellItem.Value).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`, "", true).toUpperCase()}e`;
                        }
                    }
                }
            }

            if (cellItem.IsValueDouble && !StringUtil.isEmpty(cellItem.ActualValue)) {
                const actualValue = this.scale.computeY(cellItem.ActualValue);
                const yValue = (height + 15) - (30 + actualValue);
                if (cellItem.IsActual) {
                    retVal.push(this.drawSVGTextItems(cellItem, xAxis, barWidth, yValue, zeroValue, isPositive = cellItem.ActualValue > 0, isRangeChart = false, key));
                }
                else {
                    retVal.push(this.drawSVGTextItems(cellItem, xAxis, barWidth, yValue, zeroValue, isPositive = cellItem.ActualValue > 0, isRangeChart = false, key));
                }
            }
            else if (!StringUtil.isEmpty(cellItem.HighValue) && !StringUtil.isEmpty(cellItem.LowValue)) {
                const lowPoint = (height + 15) - (30 + this.scale.computeY(cellItem.HighValue));
                let highPoint = (height + 15) - (30 + this.scale.computeY(cellItem.LowValue));
                if (Math.abs(lowPoint - highPoint) < Number.EPSILON) {
                    highPoint = highPoint - 1;
                }
                retVal.push(this.drawSVGTextItems(cellItem, xAxis, barWidth, highPoint, lowPoint, isPositive = true, isRangeChart = true, key));
            }
        });
        return retVal;
    }

    checkForAbbrevations(value) {
        const charactersToCheck = ['M', 'K', 'B', 'T'];
        for (let char of charactersToCheck) {
            if (value == null || value == "" || value?.indexOf(char) != -1) {
                return false;
            }
        }
        return true;
    }

    drawBarGraph(graphedRow, zeroValue, height) {
        let previousCellItem = null;
        let isPositive = false;
        let isRangeChart = false;
        let isCurrentValueDrawn = false;
        let showCurrentValue = false;

        each(graphedRow.cellItems, (cellItem, key) => {
            if ((key + 1) < graphedRow.cellItems.length) {
                previousCellItem = graphedRow.cellItems[key + 1];
            }

            if (cellItem.IsValueDouble && !StringUtil.isEmpty(cellItem.ActualValue)) {
                const actualValue = this.scale.computeY(cellItem.ActualValue);
                const yValue = (height + 15) - (30 + actualValue);
                if (cellItem.IsActual) {
                    this.drawBar(zeroValue, cellItem, graphedRow, yValue,
                        cellItem.ActualValue > 0 ? this.positiveBrush : this.negativeBrush,
                        isPositive = cellItem.ActualValue > 0, isRangeChart = false, previousCellItem, showCurrentValue, height);
                }
                else {
                    this.drawBar(zeroValue, cellItem, graphedRow, yValue, this.estimateDataTextBrush,
                        isPositive = cellItem.ActualValue > 0, isRangeChart = false, previousCellItem, showCurrentValue, height);
                }
                isCurrentValueDrawn = true;
            }
            else if (!StringUtil.isEmpty(cellItem.HighValue) && !StringUtil.isEmpty(cellItem.LowValue)) {
                const lowPoint = (height + 15) - (30 + this.scale.computeY(cellItem.HighValue));
                let highPoint = (height + 15) - (30 + this.scale.computeY(cellItem.LowValue));
                if (Math.abs(lowPoint - highPoint) < Number.EPSILON) {
                    highPoint = highPoint - 1;
                }
                showCurrentValue = this.props.PePsData.currentValue !== null && !isCurrentValueDrawn;
                this.drawBar(lowPoint, cellItem, graphedRow, highPoint, this.positiveBrush, isPositive = true, isRangeChart = true, previousCellItem, showCurrentValue, height);
                isCurrentValueDrawn = true;
            }
        });
    }

    drawBar(originPoint, cellItem, graphedRow, yValue, fillBrush, isPostive, isRangeChart, previousCellItem, showCurrentValue = false, height) {
        const actualWidth = this.rectBox.width;
        const currentCellXAxis = (actualWidth - (cellItem.XPoint * this.props.nodeWidth));
        const previousCellXAxis = (actualWidth - (previousCellItem.XPoint * this.props.nodeWidth));
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        const useCanvas = tabDataGraphSettings.useCanvas;

        if (!StringUtil.isEmpty(previousCellItem) && cellItem.IsVisible && this.state.imgLoaded) {
            const barWidth = previousCellXAxis - currentCellXAxis;
            setTimeout(()=> {
                this.ctx.beginPath();
                this.ctx.globalAlpha = 1;
                this.ctx.rect(currentCellXAxis, originPoint, barWidth + 1, -(originPoint - yValue));
                this.ctx.fillStyle = fillBrush;
                this.ctx.fill();
                const pattern = this.ctx.createPattern(this.img, 'repeat');
                this.ctx.fillStyle = pattern;
                this.ctx.rect(currentCellXAxis, originPoint, barWidth + 1, -(originPoint - yValue));
                this.ctx.fill();

                if (showCurrentValue) {
                    if (this.props.PePsData.currentValue !== null) {
                        const currentValueYAxis = (height + 15) - (30 + this.scale.computeY(this.props.PePsData.currentValue));
                        this.ctx.beginPath();
                        this.ctx.moveTo(previousCellXAxis + 1, currentValueYAxis);
                        this.ctx.lineTo(currentCellXAxis, currentValueYAxis);
                        this.ctx.strokeStyle = this.textBrush;
                        this.ctx.lineWidth = 1;
                        this.ctx.stroke();
                    }
                }
            }, 0)
            
            if (useCanvas) {
                this.addTextValues(cellItem, currentCellXAxis, barWidth, yValue, originPoint, isPostive, isRangeChart);
            }
        }
    }

    addTextValues(cellItem, XAxis, barWidth, yValue, originPoint, isPostive, isRangeChart) {
        const txtObj = {
            txt: '',
            x: 0,
            y: 0
        }
        const initXCordCal = (XAxis - ((Math.abs(barWidth + 1) / 2)));

        if (!isRangeChart) {
            const textWidth = this.getTextWidth(cellItem.Value);
            txtObj.txt = cellItem.Value;
            txtObj.x = (initXCordCal - (textWidth / 2));
            txtObj.y = isPostive ? yValue - 4 : yValue + 12;
            this.fillTextWithPos(txtObj);
        }
        else {
            if (!StringUtil.isEmpty(cellItem.LowValue)) {
                const textWidthLow = this.getTextWidth(cellItem.LowValue);
                const textXCord = initXCordCal - (textWidthLow / 2);
                txtObj.txt = cellItem.LowValue;
                txtObj.x = textXCord;
                txtObj.y = yValue + 12;
                this.fillTextWithPos(txtObj);
            }
            if (!StringUtil.isEmpty(cellItem.HighValue)) {
                const textWidthHigh = this.getTextWidth(cellItem.HighValue);
                const textXCord = initXCordCal - (textWidthHigh / 2);
                txtObj.txt = cellItem.HighValue;
                txtObj.x = textXCord;
                txtObj.y = originPoint - 4;
                this.fillTextWithPos(txtObj);
            }
        }
    }

    getTextWidth(txt) {
        return cellWidth(txt, this.textProperty);
    }

    fillTextWithPos(txtObj) {
        this.ctx.font = `${this.textProperty.size} ${this.textProperty.family}`;
        this.ctx.fillStyle = this.textBrush;
        this.ctx.fillText(txtObj.txt, txtObj.x, txtObj.y);
    }

    getDecimalPlaces(decimal) {
        let returnValue = "0.";
        for (let index = 0; index < decimal; index++) {
            returnValue += "0";
        }
        return returnValue;
    }

    initSVGScale(height, width) {
        const rowItems = this.props.RowItems;
        const headerIndex = this.props.graphData.isActive ? this.props.activeIndex : this.props.graphData.showGraphIndex;
        const graphedRow = rowItems.find((row) => row.index === headerIndex);
        this.initScale(graphedRow, height, width);
    }

    setBars() {
        if (!this.graphCanvas) {
            return;
        }
        const rowItems = this.props.RowItems;
        const headerIndex = this.props.graphData.isActive ? this.props.activeIndex : this.props.graphData.showGraphIndex;
        const width = this.rectBox.width;
        const height = this.rectBox.height;
        this.ctx.canvas.width = width;
        this.ctx.canvas.height = height;
        this.ctx.clear();
        this.ctx.imageSmoothingEnabled = true;
        this.positiveBrush = this.props.posDTColor;
        this.negativeBrush = this.props.negDTColor;
        this.estimateDataTextBrush = ThemeHelper.getThemedBrush("estimateDataText");
        this.textBrush = ThemeHelper.getThemedBrush("fff000");
        this.zeroLineBrush = ThemeHelper.getThemedBrush("fff666");

        const graphedRow = rowItems.find((row) => row.index === headerIndex);
        if (graphedRow && isObject(graphedRow)) {
            const cellItems = graphedRow.cellItems;
            for (let cellIndex = 0; cellIndex < cellItems.length; cellIndex++) {
                const cellItem = cellItems[cellIndex];
                const xAxis = (width - (cellItem.XPoint * this.props.nodeWidth) - 3);
                const nextCell = cellItems[cellIndex + 1];
                const nextXAxis = nextCell ? (width - (nextCell.XPoint * this.props.nodeWidth)) : width;
                let textWidth;
                let paddingText = 3;
                const cWidth = (xAxis - nextXAxis) + paddingText;
                if (cellItem.DogEarContent) {
                    const isWithIndex = cellIndex + 1 < cellItems.length;
                    if (isWithIndex) {
                        paddingText = 5;
                    }
                }

                if (cellItem.Value && cellItem.Value !== "") {
                    textWidth = cellWidth(cellItem.Value, {
                        family: "calibri",
                        size: 12
                    });

                    if (cellItem.header.IsPerc) {
                        const Value = cellItem.ActualValue > 0 ? `+${cellItem.Value}%` : `${cellItem.Value}%`;
                        textWidth = cellWidth(Value, {
                            family: "calibri",
                            size: 12
                        });
                    }
                }

                if (textWidth + paddingText >= cWidth) {
                    if (cellItem.IsActual) {
                        if (cellItem.header.IsPerc) {
                            cellItem.Value = `${numeral(cellItem.Value.replace(/%/, "")).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase()}%`;
                            cellItem.Value = cellItem.ActualValue > 0 ? `+${cellItem.Value}` : cellItem.Value;
                        }
                        else {
                            cellItem.Value = numeral(cellItem.Value).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase();
                        }
                    }
                    else if (!cellItem.IsActual) {
                        if (cellItem.header.IsPerc) {
                            cellItem.Value = `${numeral(cellItem.Value.replace(/%/, "")).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase()}%e`;
                            cellItem.Value = cellItem.ActualValue > 0 ? `+${cellItem.Value}` : cellItem.Value;
                        }
                        else {
                            cellItem.Value = `${numeral(cellItem.Value).format(`${this.getDecimalPlaces(cellItem.header.DigitAfterDecimals)}a`).toUpperCase()}e`;
                        }
                    }
                }

                if (xAxis < 0) {
                    cellItem.IsVisible = false;
                }
                else {
                    cellItem.IsVisible = true;
                }
            }

            if (this.initScale(graphedRow, height, width)) {
                const zeroValue = this.initZeroLine(width, height);
                this.drawBarGraph(graphedRow, zeroValue, height);
            }
            else {
                const yValue = height / 2;
                this.ctx.clearRect(0, 0, width, height);
                this.ctx.beginPath();
                this.ctx.moveTo(0, yValue);
                this.ctx.lineTo(width, yValue);
                this.ctx.strokeStyle = this.zeroLineBrush;
                this.ctx.stroke();
                this.drawOnlyText(graphedRow, yValue + 14);
            }
        }
    }

    drawOnlyText(graphedRow, yValue) {
        let previousCellItem = null;
        const actualWidth = this.rectBox.width;

        each(graphedRow.cellItems, (cellItem, key) => {
            if ((key + 1) < graphedRow.cellItems.length) {
                previousCellItem = graphedRow.cellItems[key + 1];
            }

            if (cellItem.IsValueDouble && cellItem.IsActual && !StringUtil.isEmpty(previousCellItem) && !StringUtil.isEmpty(cellItem.ActualValue)) {
                const currentCellXAxis = (actualWidth - (cellItem.XPoint * this.props.nodeWidth));
                const previousCellXAxis = (actualWidth - (previousCellItem.XPoint * this.props.nodeWidth));
                const barWidth = previousCellXAxis - currentCellXAxis;
                const originPoint = 0.0;
                const isPositive = true;
                const isRangeChart = false;
                this.addTextValues(cellItem, currentCellXAxis, barWidth, yValue, originPoint, isPositive, isRangeChart);
            }
        });
    }

    componentDidMount() {
        this.img = new Image();
        this.img.src = dot_pattern;
        this.img.onload = ()=> {
            this.state.imgLoaded = true
            if (this.props.isShowGraph) {
                setTimeout(()=> {
                    this.setBars();
                }, 100)
            }
        }
        setTimeout(() => {
            this.graphCanvas = document.getElementById('pagination-view-graph');
            if (this.graphCanvas) {
                this.ctx = this.graphCanvas.getContext("2d");
                this.rectBox = this.graphCanvas.getBoundingClientRect();
            }
            const contentElement = document.getElementById("graphArea");
            if (contentElement !== null && contentElement !== undefined) {
                this.state.height = contentElement.offsetHeight;
                this.state.width = contentElement.offsetWidth;
            }
        }, 100);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const isThemeSettingChanged = TabDatagraphStore.isThemeSettingChanged();
        if (!isThemeSettingChanged && nextProps === this.props && this.state === nextState) {
            return false;
        }
        return true;
    }

    componentDidUpdate() {
        this.img = new Image();
        this.img.src = dot_pattern;
        this.img.onload = ()=> {
            this.state.imgLoaded = true
            if (this.props.isShowGraph) {
                setTimeout(()=> {
                    this.setBars();
                }, 100)
            }
        }
        setTimeout(() => {
            this.graphCanvas = document.getElementById('pagination-view-graph');
            if (this.graphCanvas) {
                this.ctx = this.graphCanvas.getContext("2d");
                this.rectBox = this.graphCanvas.getBoundingClientRect();
            }
            const contentElement = document.getElementById("graphArea");
            if (contentElement !== null && contentElement !== undefined) {
                this.state.height = contentElement.offsetHeight;
                this.state.width = contentElement.offsetWidth;
            }
        }, 100);
    }

    render() {
        TimeTrackingWindow.trackChartLoadingTime();
        const labels = this.drawSVGGraphData();
        return (
            <CSSTransition
                in={this.props.isShowGraph}
                timeout={{ enter: 500, exit: 100 }}
                classNames="fade"
                mountOnEnter
                unmountOnExit
            >
                <div className="financial-graph-view" id="graphArea">
                    <canvas
                        className="paginate-view-graph"
                        id="pagination-view-graph"
                        ref={(d) => { this.graphCanvas = d }}>
                    </canvas>
                    {labels}
                </div>
            </CSSTransition>
        );
    }
}

const mapStateToProps = (state) => {
    const { RowItems, PePsData, graphData, nodeWidth, CurrentPage, priceChartWidth } = state.DatagraphReducers.financialBlockReducer;
    const isShowGraph = graphData.isShowGraph || graphData.isActive
    const { isMiniListOpen } = state.DatagraphReducers.DataGraphReducer;
    const { isRIPanelOpen } = state.RelatedInfoPanelReducers.RiPanelReducer;
    const activeIndex = graphData.activeIndex
    const { posDTColor, negDTColor } = state.appColor;
    return { RowItems, PePsData, graphData, nodeWidth, isShowGraph, CurrentPage, isMiniListOpen, isRIPanelOpen, posDTColor, negDTColor, activeIndex, priceChartWidth } // getting "dimension" as prop of price-chart-dimension to update component when price chart dimension changes
}
export default connect(mapStateToProps, null, null)(PaginatedGraphView)