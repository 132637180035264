import HubVirtualListBody from './HubVirtualListBody';
import Measure from "react-measure";
import ScrollBar from "ScrollBar";
import React, { Component } from "react";

export default class HubVirtualList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
      items: props.items,
      itemHeight: props.itemHeight ? props.itemHeight : 0,
      shouldUpdate: true
    };
    this.showScrollOnHover = true;
    this.firstVisibleIndex = -1;
    this.lastVisibleIndex = -1;
    this.visibleItems = [];
    this.setVisibleItem = this.setVisibleItem.bind(this);
    this.getItemRenderer = this.getItemRenderer.bind(this);
  }

  componentDidMount() {
    this.setVisibleItem();
  }

  shouldComponentUpdate(props, state) {
    return state.shouldUpdate;
  }

  setVisibleIndex(items, scrollTop, clientHeight) {
    //console.log(`scrollTop: ${scrollTop}, clientHeight: ${clientHeight}`);
    this.firstVisibleIndex = -1;
    this.lastVisibleIndex = -1;

    let visibleCount = 0;
    let hideHeight = 0;
    let displayHeight = -1;
    _.each(items, (item, index) => {
      const currentItemHeight = Boolean(item.expanded) ? item.expandedHeight : item.height;
      if (scrollTop < hideHeight + currentItemHeight && this.firstVisibleIndex < 0) {
        this.firstVisibleIndex = index;
        displayHeight = hideHeight + currentItemHeight - scrollTop;
      }
      else if (displayHeight > 0) {
        displayHeight += currentItemHeight;
      }
      else {
        hideHeight += currentItemHeight;
      }

      if (displayHeight > -1) {
        if (displayHeight >= clientHeight && this.lastVisibleIndex < 0) {
          this.lastVisibleIndex = index;
          visibleCount = this.lastVisibleIndex - this.firstVisibleIndex;
          this.lastVisibleIndex = Math.min(items.length, this.firstVisibleIndex + visibleCount + 2);
        }
        else if (items.length - 1 === index && displayHeight < clientHeight) {
          this.lastVisibleIndex = items.length;
        }
      }
    })

    //console.log(`firstVisibleIndex: ${this.firstVisibleIndex}, lastVisibleIndex: ${this.lastVisibleIndex}`)
  }

  getScrollHeight(items) {
    const scrollHeight = _.reduce(items, (result, item) => {
      const height = item.expanded ? item.expandedHeight : item.height;
      result += height;
      return result;
    }, 0);

    return scrollHeight;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.items && props.items.length > 0 && this.state.itemHeight) {
      //add the visibleItems to set the fist render the virtual list.
      if (this.panel) {
        const { scrollTop } = this.scrollPanel;
        const clientHeight = document.getElementsByClassName('hub-list')[0].clientHeight;
        this.setVisibleIndex(props.items, scrollTop, clientHeight);
        this.visibleItems = props.items.slice(this.firstVisibleIndex, this.lastVisibleIndex);

        this.panel.updateItems(this.visibleItems);
        //console.log(this.visibleItems);
      }
      const newState = {
        items: props.items,
        shouldUpdate: true
      };
      Object.assign(this.state, newState);
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.scrollBar) {
        this.scrollBar.refresh();
      }
    }, 200);
  }

  setVisibleItem() {
    if (!this.panel || !this.scrollPanel) {
      return;
    }

    //console.log("this.props.items",this.props.items)
    const { scrollTop } = this.scrollPanel;
    const clientHeight = document.getElementsByClassName('hub-list')[0].clientHeight;
    this.setVisibleIndex(this.props.items, scrollTop, clientHeight);

    if (this.firstVisibleIndex !== this.state.firstVisibleIndex || this.lastVisibleIndex !== this.state.lastVisibleIndex) {
      this.visibleItems = this.state.items.slice(this.firstVisibleIndex, this.lastVisibleIndex);

      this.panel.updateItems(this.visibleItems);
      const newState = { shouldUpdate: true };
      Object.assign(this.state, newState);
    }
  }

  setDimensions(dim) {
    this.setState({ height: dim.height, width: dim.width, shouldUpdate: false }, this.setVisibleItem());
  }

  scrollToItemIndex(index) {
    let scrollTop = index * this.state.itemHeight;
    scrollTop -= ((this.scrollPanel.clientHeight / 2) - this.state.itemHeight);
    this.scrollPanel.scrollTop = scrollTop;
  }

  getTop(items, itemIndex) {
    let top = 0;
    _.each(items, (item, index) => {
      if (index < itemIndex) {
        const height = Boolean(item.expanded) ? item.expandedHeight : item.height;
        top += height;
      }
    });
    return top;
  }

  getItemRenderer(item, index) {
    if (this.props.itemRenderer) {
      const itemIndex = this.firstVisibleIndex + index;
      const top = this.getTop(this.props.items, itemIndex);
      const elm = this.props.itemRenderer(item, top, itemIndex);
      return elm;
    }
    return null;
  }

  render() {
    const scrollHeight = `${this.getScrollHeight(this.state.items)}px`;
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.setDimensions(contentRect.bounds)
        }}
      >
        {({ measureRef }) =>
          <div ref={measureRef} className={this.showScrollOnHover ? "virtualList showscrollOnHover" : "virtualList"}
            onScroll={this.setVisibleItem} style={{ width: "100%", height: "100%" }}>
            <div className={(this.props.id === 'symbolEntry') ? "custom-scroll custom-scroll-light" : "custom-scroll"}>
              <div id={`symbol-${this.props.id}-VerticalScroll`} ref={(ref) => { this.scrollPanel = ref }}>
                <HubVirtualListBody ref={(ref) => { this.panel = ref }} showScrollOnHover={this.showScrollOnHover} scrollHeight={scrollHeight} getItemRenderer={this.getItemRenderer} />
              </div>
              <ScrollBar scrollId={`symbol-${this.props.id}-VerticalScroll`} itemHeight={this.state.itemHeight} vScroll={true} scrollOnHover={true} ref={(ref) => { this.scrollBar = ref }} />
            </div>
          </div>
        }
      </Measure>
    );
  }
}
