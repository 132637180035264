import 'core-js/shim';
import 'regenerator-runtime/runtime';
import 'core-js/fn/promise';
import 'raf/polyfill';
import 'whatwg-fetch';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App/App.jsx';
import './Asset/css/main-style.less';
import './Asset/css/theme.less';
ReactDOM.render( <App />, document.getElementById('app'));

if (module.hot) {
    module.hot.accept();
}
