import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';

class GetLatestTradingDateRequest extends BaseServiceRequestIIS {
    constructor() {
        super();
    }

    getServiceMethod() {
        return 'GetLatestTradingDate';
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return 'ComparisionTimeLineDates';
    }
}

export default GetLatestTradingDateRequest;