import React from "react";

export default class ColumnDragAdorner extends React.Component {

  constructor(props) {
    super(props);
    this.createColumnGhostImage = this.createColumnGhostImage.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.state = {
      ghostImage: undefined
    }
  }

  handleMouseUp(e) {
    if (this.state.ghostImage != undefined) {
      this.props.onAdornerMouseUp();
      this.setState({ ghostImage: undefined });
    }
    e.stopPropagation();
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleMouseUp, false);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('mouseup', this.handleMouseUp, false);
  }

  createColumnGhostImage(column, left, dragColumnTitle) {
    let colWidth = this.props.getColumnWidth(column);
    let style = { width: colWidth - 1, left: left };
    let ghostImage = <div key="temp" className="ghost-image" style={style}><div className="target-Object small-normal">{dragColumnTitle}</div></div>;
    this.setState({
      ghostImage: ghostImage
    })
  }

  render() {
    return (
      <div className="column-adorner">
        {this.state.ghostImage}
      </div>
    )
  }
}
