const { ActionTypes } = VolumeChartConstants;
import InsiderBlockSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/InsiderBlockSettings.js";
import SettingsStore from "SettingsStore";
import { VolumeChartConstants } from "../../../Constants/VolumeChartConstants";

const initialState = {
    Height: 0,
    InsiderData: {},
    TimeLine: [],
    Width: 0,
    scale: {},
    isInsiderOpen: false,
    InsiderBlockSettings: new InsiderBlockSettings()
}

const insiderChartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_INSIDER_CHART_DATA: {
            return { ...state, ...action.newState }
        }

        case ActionTypes.INSIDER_CHART_COLLAPSE_BLOCK: {
            if (action.blockType === "Insider") {
                state.InsiderBlockSettings.IsVisible = action.isopen;
                SettingsStore.saveSettings();
            }
            return { ...state, isInsiderOpen: action.isopen }
        }

        case ActionTypes.CLEAR_INSIDER_DATA: {
            return { ...initialState }
        }

        default:
            return state
    }
}

export default insiderChartReducer