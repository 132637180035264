import BaseServiceRequest from 'BaseServiceRequest';
import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';

class ReorderItemsInUserListRequest extends BaseServiceRequest {

    constructor(listId, cacheId, consoleId, ranges, afterSeqNum, basicInfo) {
        super();
        super.addRequestUri("reorderitemsES.pb");
        super.addRequestParameter("listId", listId.toString());
        //super.addRequestParameter("cacheId", cacheId.toString());
        super.addRequestParameter("consoleId", consoleId);
        super.addRequestParameter("ranges", ranges);
        super.addRequestParameter("afterSeqNum", afterSeqNum.toString());
        super.setContent(basicInfo.encode());
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}

export default ReorderItemsInUserListRequest;