import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetDownloadCurrencyImageWebRequest extends BaseServiceRequest {
    constructor(id, osid) {
        super();
        super.addRequestUri("currencyimage.axd");
        super.addRequestParameter("id", id);
        super.addRequestParameter("osid", osid);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "";
    }
}

export default GetDownloadCurrencyImageWebRequest;
