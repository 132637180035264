import AppDispatcher from "AppDispatcher";

import {
    InsertSharedData,
    GetSharedUsers,
    GetAllShareNode,
    GetAllRecipientsShareData,
    GetDetailRecipientStatus
} from "../ServiceApi/Apis/ShareApi.js";
import { ShareDialogConstants, ShareActionConstants } from "../Constants/ShareConstants"

import { map, extend, filter } from "underscore";
import { initAnnotationModel } from "./AnnotationActions.js";
import { dispatch } from "../Redux/dispatch.js";

export const SaveSharedData = (callFrom, savedSharedData, targetData, sharedAction) => {
    const nodeIds = String(targetData.id).split(';');

    const modifiedData = filter(savedSharedData, (rowData) => rowData.isModified);

    InsertSharedData(modifiedData, nodeIds, targetData.listType, sharedAction).then((result) => {
        if (result.responseHeader.error) {
            alert('Error')
        }
        else {
            if (callFrom === 'shared')
                AppDispatcher.handleViewAction({
                    actionType: ShareDialogConstants.CLEAR_SHARE_DATA,
                    data: []
                });
            else {
                UpdateDialogType('managed', targetData.ntid, sharedAction);
            }
            if (sharedAction === ShareActionConstants.SHARE_ANNOTATIONS) {
                dispatch(initAnnotationModel(true));
            }
        }

    });
}

export const getLength = (filterStr) => {
    let len = 0;
    for (let codePoint of filterStr) {
        if (codePoint.charCodeAt() <= 128 && codePoint.charCodeAt() >= 0) {
            len += 1;
        } else {
            len += 2;
        }
    } 
    return len;
} 

export const updateStorewithSharedData = (data) => {
    data.showSharedDialog ?
        data.action === ShareActionConstants.SHARE_RECIPIENTS ?
            GetDetailRecipientStatus(data.id).then((result) => {
                const summary = map(result.summary, (sharedata) => extend(sharedata, { isShared: true, isModified: false, isAdded: false }));

                AppDispatcher.handleViewAction({
                    actionType: ShareDialogConstants.SHOW_SHARED_DIALOG,
                    data: {
                        data: data,
                        sharedUsers: summary
                    }
                });
            })
            :
            GetSharedUsers(data.ntid, data.id, data.IncludeAllUsers).then((result) => {
                const sharedUsers = map(result.sharedUsers, (sharedata) => extend(sharedata, { isModified: false, isAdded: false }));

                AppDispatcher.handleViewAction({
                    actionType: ShareDialogConstants.SHOW_SHARED_DIALOG,
                    data: {
                        data: data,
                        sharedUsers: sharedUsers
                    }
                });
            })
        :
        AppDispatcher.handleViewAction({
            actionType: ShareDialogConstants.CLEAR_SHARE_DATA,
            data: []
        });
}

export const UpdateDialogType = (dialogType, ntid, sharedAction) => {
    AppDispatcher.handleViewAction({
        actionType: ShareDialogConstants.SHOW_IN_PROGRESS,
        data: true
    });
    if (sharedAction === ShareActionConstants.SHARE_RECIPIENTS) {
        GetAllRecipientsShareData().then((result) => {
            AppDispatcher.handleViewAction({
                actionType: ShareDialogConstants.UPDATE_DIALOG_TYPE,
                data: {
                    dialogType: dialogType,
                    sharedData: result.summary,
                    sharedAction: sharedAction
                }
            });
        });
    }
    else {
        GetAllShareNode(ntid).then((result) => {
            AppDispatcher.handleViewAction({
                actionType: ShareDialogConstants.UPDATE_DIALOG_TYPE,
                data: {
                    dialogType: dialogType,
                    sharedData: result.summary,
                    sharedAction: sharedAction
                }
            });
        });
    }
}

export const ShowUsers = (ntid, id, ia) => {
    GetSharedUsers(ntid, id, ia).then((result) => {
        AppDispatcher.handleViewAction({
            actionType: ShareDialogConstants.UPDATE_USERS,
            data: result
        });
    });
}

export const UpdateshowUsersFlag = (data) => {
    AppDispatcher.handleViewAction({
        actionType: ShareDialogConstants.UPDATE_SHOW_USER_FLAG,
        data: data
    });
}

export const setNameFilter = (data) => {
    AppDispatcher.handleViewAction({
        actionType: ShareDialogConstants.UPDATE_NAME,
        data: data
    });
}

export const UpdateSharedData = (data) => {
    AppDispatcher.handleViewAction({
        actionType: ShareDialogConstants.UPDATE_SHARED_DATA,
        data: data
    });
}

export const setSort = (currentSortName, sortName, isSortReversed) => {
    const sortReversed = currentSortName === sortName ? !isSortReversed : false;

    AppDispatcher.handleViewAction({
        actionType: ShareDialogConstants.SORT_MANAGED_DATA,
        data: {
            sortName: sortName,
            isSortReversed: sortReversed
        }
    });
}

export const setConfirmationFlag = (savedSharedData, ntid, sharedAction) => {
    const modifiedData = filter(savedSharedData, (rowData) => rowData.isModified);

    if (modifiedData.length === 0)
        UpdateDialogType('managed', ntid, sharedAction);
    else {
        AppDispatcher.handleViewAction({
            actionType: ShareDialogConstants.UPDAT_CONFIRMATION_FLAG,
            data: true
        });
    }
}