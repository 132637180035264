import React from "react";
import LocalizationStore from "LocalizationStore";
let headerElements = undefined;

export default class FundamentalsNonAnnualHeader extends React.Component {
    constructor() {
        super();
    }

    render() {
        let headerDetails = this.props.headerdata;
        //  let data = this.props.headerData;
        //  let handleSortOrderFunc = this.props.handleSortOrderChange;

        if (headerDetails != undefined) {
            headerElements = headerDetails.map((headerData, index) => {
                var blockData = headerDetails[index];
                if (blockData[0] !== 'Fiscal 0') {
                    return (<td className="head-table" key={index + 1}>
                        <div className="title center">{headerData[0]}</div>
                        <div className="quaterly-block">
                            <div className="row-block medium-normal ">
                                {blockData.map((data, cellIndex) => {
                                    if(!data) return;
                                    var str = 'cal_' + (data.split('(')[0].replace(/(\s*$)/g, ""));
                                    var dataName = LocalizationStore.getTranslatedData(str, data.split('(')[0].replace(/(\s*$)/g, "")) + '(' + data.split('(')[1];
                                    if (cellIndex == 0) return null;
                                    return (<span className="col-block" key={cellIndex + 1}> {dataName}</span>)
                                })}
                            </div>
                        </div>
                    </td>);
                }
            });
        }

        return (
            <tr className="row-table quarterlyHeader">
                {headerElements}
            </tr>
        );
    }
}
