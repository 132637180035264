
import { Modal } from "react-bootstrap";
import React from "react";
import { TranslateHelper } from "../../../../../../../Utils/TranslateHelper";
import UpdateAlertDialog from "./UpdateAlertDialog";

export function DialogHOC (WrappedComponent) {
    return function HOC(props) {
        const openDialogClass = props.showSettingDialog ? "dialog-open" : ""
        return <div id="EPsRPsDialog">
                <Modal className={`modal-popup ${props.headerClass} ${openDialogClass}`} show={props.showSettingDialog} >
                    <Modal.Header>
                        <Modal.Title>
                                <span className="cap-header">{props.HeaderText}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <WrappedComponent {...props}/>
                    <Modal.Footer>
                        <span className="btn-box righter">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={props.cancelLineDialog}>{TranslateHelper.CANCEL}</button>
                            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={props.saveLineDialog}>{TranslateHelper.OK}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
                {props.isUpdateDialog &&  <UpdateAlertDialog
                    isShowModal={props.showAlert}
                    handleClick={props.saveLineDialog}>                    
                </UpdateAlertDialog> }
            </div>
    }
}