import Serializable from "Serializable";

class RiPanelNewSettings extends Serializable {
    constructor() {
        super();
        this.DefaultChosenIds = this.getDefaultNewsChosenIds();
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.DefaultChosenIds = serializedValue.DefaultChosenIds ? serializedValue.DefaultChosenIds : this.getDefaultNewsChosenIds();
        this.ActiveNewsChosenIds = serializedValue.ActiveNewsChosenIds ? serializedValue.ActiveNewsChosenIds : null;
    }

    getObjectMapKey() {
        return "RiPanelNewSettings";
    }

    getDataObject() {
        return {
            DefaultChosenIds: this.DefaultChosenIds,
            ActiveNewsChosenIds: this.ActiveNewsChosenIds
        };
    }

    getDefaultNewsChosenIds() {
        return [2, 3];
    }

}

export default RiPanelNewSettings;
