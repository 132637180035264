import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetOwnershipReportHeaderRequest extends BaseServiceRequest {
    constructor(osId, ownerType = "106,401,402", currentTab =0,currentSubTab = 0, showIndex = 0) {
        super();
        super.addRequestUri("ownershipreportheader.pb");
        super.addRequestParameter("osid", osId ? osId.toString() : "13717");
            super.addRequestParameter("curtab", currentTab);


            super.addRequestParameter("otype", ownerType);

            super.addRequestParameter("cursubtab", currentSubTab);

        if (showIndex == 0 || showIndex == 1)
        {
            super.addRequestParameter("sindex", showIndex.toString());
        }       
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "OwnershipReportHeaderResponseExt";
    }
}

export default GetOwnershipReportHeaderRequest;
