import AlertColumnSet from './AlertColumnSet';
import { AlertTranslateHelper } from '../../../../Utils/TranslateHelper';
import AndAlertsDialog from './AndAlertsDialog';
import CommonDialog from '../../../../RayCustomControls/CommonDialog/CommonDialog';
import { connect } from 'react-redux';
import GeographyAlertDialog from './GeographyAlertDialog';
import LocalizationStore from '../../../../Stores/Localization/LocalizationStore';
import OrAlertsDialog from './OrAlertsDialog';
import PriceAlert from './PriceAlert';
import { cancelConditionAlertDelete, deleteAllActiveAlerts } from '../../../../Actions/AlertActions';
import React, { Fragment } from 'react';

function AlertsDialog(props) {
    return (<>
        {props.isAndAlertDialogOpen && <AndAlertsDialog />}
        {props.isOpenAlertCriteria && <OrAlertsDialog />}
        {props.isPriceAlertDialogOpen && <PriceAlert />}
        {props.isGeographyFilterOpen && <GeographyAlertDialog />}
        {props.isColumnFilterOpen && <AlertColumnSet />}
        {props.showCommonDialog && <CommonDialog preClass='msg-modal'
            isOpen={props.showCommonDialog}
            title={AlertTranslateHelper["DELETE_ALERT"]}
            hideModal={props.cancelConditionAlertDelete}
            backButtonShow={false}
            cancelButtonShow={true}
            submitButtonShow={true}
            submitHandler={props.deleteAllActiveAlerts}
            submitButtonText={AlertTranslateHelper["DELETE"]}
        >
            <Fragment>
                <div className="alert-stuff">
                    <p>{LocalizationStore.getTranslatedData("list_DelAlt", props.alertMessage)}</p>
                </div>
                <div className="clearfix"></div>
            </Fragment>
        </CommonDialog>}
    </>
    );
}
const mapStateToProps = ({ alertReducer }) => {

    const { isAndAlertDialogOpen, isOpenAlertCriteria, isPriceAlertDialogOpen, conditionTypeId, alertMessage, showCommonDialog, isGeographyFilterOpen, isColumnFilterOpen } = alertReducer.AlertDialogReducer;

    return { isAndAlertDialogOpen, isOpenAlertCriteria, isPriceAlertDialogOpen, conditionTypeId, alertMessage, showCommonDialog, isGeographyFilterOpen, isColumnFilterOpen }
}

const mapDispatchToProps = (dispatch) => ({
    deleteAllActiveAlerts: () => dispatch(deleteAllActiveAlerts()),
    cancelConditionAlertDelete: () => dispatch(cancelConditionAlertDelete())
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertsDialog);