import ExtremeDataValue from "ExtremeDataValue";
export default class StockData {
    constructor(data) {
        this.setStockData(data);
    }

    setStockData(data) {
        const newListData = data.holdings;
        this.dataSource = [];
        newListData.map((newAsListItem) =>
            this.dataSource.push({
                symbol: newAsListItem.symbol,
                companyName: newAsListItem.coName,
                percChange: `${ExtremeDataValue.decimalFormat(newAsListItem.pctOfPort, 1)}%`,
                doubleValue: newAsListItem.pctOfPort,
            })
        )
    }
}