var FeatureModuleType = {
    RIPanel: 100,
    MiniList: 101,
    ListMonitor: 102,
    ListAlert: 103,
    ColumnFilter: 104,
    RotationGraph: 105,
    GlobalOutlook: 106,
    Commentary: 107,
    RI_ALERT: 108,
    RI_ANNOTATIONS: 109,
    RI_BROKER_REPORT: 110,
    RI_CHECKLIST: 111,
    RI_IDEA_LIST: 112,
    RI_NEWS: 113,
    RI_OWNERSHIP: 114,
    RI_TRANSACTIONS: 115,
    RI_TWITTER: 116,
    Annotations_Sharing: 117,
    Technical_Indicator: 118,
    Bottom_Data_Panels: 119,
    Calendar_Control: 120,
    LM_Browser: 121,
    List_Analytics: 122,
    Multi_Chart_View: 123,
    Quote_Board: 124,
    YTD: 125,
    TIYRP1: 126,
    TIYRP3: 127,
    TIYRP5: 128,
    SB_ALPHASCOPE: 129,
    SB_IDEA_LIST_OVERLAP: 130,
    PTOE: 131,
    PTOS: 132,
    EQUATIONS: 133,
    LM_PortoflioBenchmarkStats: 134
}
export default FeatureModuleType;