import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetNotImportListRequest extends BaseServiceRequest {

    constructor(listName, symbols, createDate, databaseSourceID, bulkInsertData = null) {
        super();
        super.addRequestUri("notimportlistcreate.pb");
        super.addRequestParameter("listName", listName.toString());
        if (bulkInsertData != null) super.setContent(bulkInsertData);
        else {
            super.addRequestParameter("symbols", symbols.toString());
        }
        super.addRequestParameter("datecreate", createDate);
        super.addRequestParameter("DBId", databaseSourceID);
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "TemporaryListResponse";
    }
}

export default GetNotImportListRequest;
