import { changePeriodicityButtonPeriodicity } from "../../../../Actions/DatagraphActions.js";
import ClickMenu from "ClickMenu";
import { connect } from "react-redux";
import { PeriodicityTranslateHelper } from '../../../../Utils/TranslateHelper.js';
import React from "react";

class PeriodicityControl extends React.Component {
    setPeriodicityOption(item, button) {
      this.props.changePeriodicityButtonPeriodicity(item, button)
    }
  
    setPeriodicity(e, button) {
      if(this.props.disablePeriodicityButton){
        return;
      }
      if (e.button !== 2) {
        this.props.changePeriodicityButtonPeriodicity({ key: button.Periodicity, header: button.Header }, button);
      }
    }
  
    render() {
      return (
        <div className="actionButton">
          {this.props.periodicityButtons.map((button) => {
            const btnClass = button.IsSelected ? "btn btn-sm btn-default active" : this.props.disablePeriodicityButton ? "btn btn-sm btn-default disabled" : "btn btn-sm btn-default";
            
            return ( <div key={button.key} className={`periodicty-item ${btnClass}`} onClick={(e) => this.setPeriodicity(e, button)} id="dropdown-size-extra-small">
                        <span className="periodicity-text" rel={PeriodicityTranslateHelper[button.Periodicity]}>{PeriodicityTranslateHelper[button.Periodicity]}</span>
                        <ClickMenu data-disable-track-price="true" items={button.options} position="top" onselect={(option) => this.setPeriodicityOption(option, button)} />
                      </div>);
          })}
        </div>
      );
    }
}
const mapStateToProps = ({ DatagraphReducers }) =>{
  const { periodicityButtons, disablePeriodicityButton } = DatagraphReducers.DataGraphReducer

  return { periodicityButtons, disablePeriodicityButton };
}

const mapDispatchToProps = (dispatch)=> ({
  changePeriodicityButtonPeriodicity: (item, button)=> dispatch(changePeriodicityButtonPeriodicity(item, button))
})

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicityControl);