import React from "react";
import { Modal, Form, FormGroup, ControlLabel } from "react-bootstrap";
import FlexibleModal from "../../../FlexibleModal.jsx";
import RecepientsData from "./RecepientsData.jsx";
import SearchBox from "./SearchBox.jsx"
import LocalizationStore from 'LocalizationStore';
import { UpdateshowUsersFlag } from "../../../../Actions/ShareAction";

const RecepientsPage = ({ sharedData, userList, sharedAction, nameFilter, savedSharedData, x_parent, y_parent }) => {

    const closeUserList = () => {
        UpdateshowUsersFlag({ isShowUsers: false, sharedData: sharedData });
    }
    const selectedRecepientCount = sharedData.length - savedSharedData.length;

    const getXPosition = () => {
        let x = 0;
        let w = 600;

        if (x_parent) {
            x = x_parent - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2;
        }

        if (x < 0)
            x = 0;

        return x;
    }

    const getYPosition = () => {
        let y = 0;
        let h = 480;

        if (y_parent) {
            y = y_parent - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2;
        }

        if (y < 0)
            y = 0;

        return y;
    }

    return (
        <FlexibleModal left={getXPosition()} top={getYPosition()} open={true}
            draggable="false" className="modal-popup share-dialog-wrap-recipients">

            <div className="recipients-page">
                {/* <Modal.Header className="medium-bold"></Modal.Header> */}
                <Modal.Body className="disable-drag">
                    <div className="modal-area disable-drag">
                        <Form inline>
                            <h5>{LocalizationStore.getTranslatedData("ri_id44","Select Recipients")}</h5>
                            <FormGroup className="disable-drag" controlId="formInlineSearch" >
                                <ControlLabel>{LocalizationStore.getTranslatedData("ri_id45","Search")}</ControlLabel>
                                <SearchBox name={nameFilter} />
                            </FormGroup>
                        </Form>

                        <div className="recipients-box">
                            <div className="disable-drag" id="formTbl1 ">
                                <RecepientsData
                                    sharedData={sharedData}
                                    userData={userList}
                                    nameFilter={nameFilter}
                                    savedSharedData={savedSharedData}
                                />
                            </div>
                        </div>
                        
                        <div className="selected-recipients">
                            <p>{`${selectedRecepientCount} ${selectedRecepientCount > 1 ? LocalizationStore.getTranslatedData("ri_id48","recipients selected"): LocalizationStore.getTranslatedData("ri_id47","recipient selected")}`}</p>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn-box righter">
                        <button type="button" className="btn btn-xs btn-default btn-secondary disable-drag" data-effect="fadeOut" onClick={closeUserList}>{LocalizationStore.getTranslatedData("done","DONE")}</button>
                    </div>
                </Modal.Footer>
            </div>
        </FlexibleModal>
    );
}
export default RecepientsPage;