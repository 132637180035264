import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetPrintStatusRequest extends BaseServiceRequest {
    constructor(requestId) {
        super();
        super.addRequestUri("getprintstatus.json");
        super.addRequestParameter('requestid', requestId);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return 'PrintStatusResponse';
    }
}

export default GetPrintStatusRequest;