import AppDispatcher from "AppDispatcher";
import { GridConstants, ScrollPosition } from "GridConstants";

class GridActionsClass {

  getHighlightedRow(index, highlight) {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.GET_HIGHLIGHTED_ROW,
      data: { index: index, highlight: highlight }
    });
  }

  handleSingleModeSelection(rowIndex, visibleIndex, keyType) {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.GET_SELECTED_ROW,
      data: { rowIndex: rowIndex, visibleIndex: visibleIndex, keyType: keyType }
    });
  }

  handleSelectAllRows() {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.SELECT_ALL_ROWS,
      data: {}
    });
  }

  handleShiftDown() {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.SHIFT_DOWN_SELECTION,
      data: {}
    });
  }

  handleShiftUp() {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.SHIFT_UP_SELECTION,
      data: {}
    });
  }

  addColumnSetWidth(listId, colSeq, quoteBoard) {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.UPDATE_COL_SET_WIDTH,
      data: { listId: listId, colSeq: colSeq, quoteBoard: quoteBoard }
    });
  }

  addColumnToList(action, listId, dataItemId, previousColumnDataItemId, isFrozen, isQuoteboard, isColumnReorder) {
    AppDispatcher.handleServerAction({
      actionType: action,
      data: { action: action, listId: listId, dataItemId: dataItemId, previousColumnDataItemId: previousColumnDataItemId, isFrozen: isFrozen, isQuoteboard: isQuoteboard, isColumnReorder: isColumnReorder }
    });
  }

  removeColumnToList(listId, dataItemId, isQuoteboard, isFrozen, previousColumnDataItemId) {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST,
      data: { listId: listId, dataItemId: dataItemId, isQuoteboard: isQuoteboard, isFrozen: isFrozen, previousColumnDataItemId: previousColumnDataItemId }
    });
  }

  updateGlobalFlagAll(listId, cacheId, isDelete, dbType) {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG_ALL,
      data: { listId: listId, cacheId: cacheId, isDelete: isDelete, dbType: dbType }
    });
  }

  updateGlobalFlag(msids, snapshotdates="", isDelete, cacheId, dbType, selectedIndexes) {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG,
      data: { msids: msids, snapshotdates: snapshotdates, isDelete: isDelete, cacheId: cacheId, dbType: dbType, selectedIndexes: selectedIndexes }
    });
  }

  autoSizeAllColumnsWidth() {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.AUTO_SIZE_ALL_COLUMNS_WIDTH,
      data: {}
    });
  }

  splitFrozenColumns(isSplit) {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.SPLIT_FROZEN_COLUMNS,
      data: { isSplit: isSplit }
    });
  }

  removeGridRows(listId, cacheId, msIDS, snapshotDates="") {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.REMOVE_GRID_ROWS,
      data: { listId: listId, cacheId: cacheId, msIDS: msIDS, snapshotDates: snapshotDates }
    });
  }

  removeGridRowsUndo() {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO,
      data: {}
    });
  }

  copyGridRows(listId, msIDS, snapshotDates="") {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.COPY_GRID_ROWS,
      data: { listId: listId, msIDS: msIDS, snapshotDates: snapshotDates }
    });
  }

  pasteGridRows(listId, cacheId, content) {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.PASTE_GRID_ROWS,
      data: { listId: listId, cacheId: cacheId, content: content }
    });
  }

  pasteGridRowsUndo() {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.PASTE_GRID_ROWS_UNDO,
      data: {}
    });
  }

  dropInListManager(sourceIndexs, destIndex) {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.DROP_IN_LIST_MANAGER,
      data: { sourceIndexs: sourceIndexs, destIndex: destIndex }
    });
  }

  addSymbolInListManager(symbol, listId, cacheId, listCount, listName) {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER,
      data: { symbol: symbol, listId: listId, cacheId: cacheId, listCount: listCount, listName: listName }
    });
  }

  handleColumnVisualization(listId, colSeq, quoteBoard) {
    AppDispatcher.handleServerAction({
      actionType: GridConstants.ActionTypes.VISUALIZE_GRID_COLUMN,
      data: { listId: listId, colSeq: colSeq, quoteBoard: quoteBoard }
    });
  }
  
  editCustomMetricList(action) {
    AppDispatcher.handleServerAction({
      actionType: action,
      data: {}
    });
  }

  CloseSymbolsSelectExceedWarningDialog() {
    AppDispatcher.handleViewAction({
      actionType: GridConstants.ActionTypes.CLOSE_SYMBOLS_SELECT_LIMIT_EXCEED_MODAL
    });
  }
}

const GridActions = new GridActionsClass();
export default GridActions;

export const setScrollPos = (scrollTop, scrollLeft = null) => {
  AppDispatcher.handleViewAction({
    actionType: ScrollPosition.SET_SCROLL_POSITION,
    data: {
      scrollTop: scrollTop,
      scrollLeft: scrollLeft
    }
  });
}
