import { connect } from 'react-redux';
import keymap from "../../../../NavList/keymap";
import PropTypes from 'prop-types';
import { ShortcutManager } from "react-shortcuts";
import { initCustomTIModal, handleModalHide, updateItemStatus, onTabSelectionChanged, onInputValChange, onInputFocusLost, restoreDefaults, onApplyToAllIntervals } from "../../../../../../Actions/CustomTIModalActions";
import React, { Component } from 'react';
import { SettingsDialog, AppearanceDialog, AboutDialog } from "../Presentational";

const shortcutManager = new ShortcutManager(keymap);

class CustomTIModule extends Component {

    getChildContext() {
        return { shortcuts: shortcutManager }
    }

    handleClick = (isSaveObject, isRemainOpen = false) => {
        this.props.handleModalHide(isSaveObject, isRemainOpen);
    }

    updateItemStatus = (isActive, item) => {
        this.props.updateItemStatus(isActive, item);
    }

    onTabSelectionChanged = (selectedPeriodicityTabKey) => {
        this.props.onTabSelectionChanged(selectedPeriodicityTabKey);
    }

    onInputValChange = (e, item) => {
        this.props.onInputValChange(e, item);
    }

    onInputFocusLost = (e, item) => {
        this.props.onInputFocusLost(e, item);
    }

    handleChangeAppearance = (isActive, item, color, weight) => {
        this.props.updateItemStatus(isActive, item, color, weight);
    }

    restoreDefaults = () => {
        this.props.restoreDefaults();
    }

    onApplyToAllIntervals = (e) => {
        this.props.onApplyToAllIntervals(e);
    }

    render() {
        const { isSettingsDialog, isAppearanceDialog, isAboutDialog, menu, title, inputId,
            periodicityHeader, activePeriodicityTab, selectedTiTab,
            className, isInvalidInput, isTextRestricted, errorMessage, url, aboutDialogTitle } = this.props.customTIData;

        return (
            <div>
                {isSettingsDialog && <SettingsDialog
                    isShowModal={isSettingsDialog}
                    menu={menu}
                    title={title}
                    handleClick={this.handleClick}
                    periodicityHeader={periodicityHeader}
                    updateItemStatus={this.updateItemStatus}
                    selectedTiTab={selectedTiTab}
                    onTabSelectionChanged={this.onTabSelectionChanged}
                    activePeriodicityTab={activePeriodicityTab}
                    onInputValChange={this.onInputValChange}
                    onFocusLost={this.onInputFocusLost}
                    isTextRestricted={isTextRestricted}
                    className={className}
                    isInvalidInput={isInvalidInput}
                    errorMessage={errorMessage}
                    restoreDefaults={this.restoreDefaults}
                    onApplyToAllIntervals={this.onApplyToAllIntervals}
                    inputId={inputId}
                />}
                {isAppearanceDialog && <AppearanceDialog
                    isShowModal={isAppearanceDialog}
                    menu={menu}
                    activePeriodicityTab={activePeriodicityTab}
                    title={title}
                    handleClick={this.handleClick}
                    updateItemStatus={this.updateItemStatus}
                    handleChangeAppearance={this.handleChangeAppearance}
                    className={className}
                    restoreDefaults={this.restoreDefaults}
                />}
                {isAboutDialog && <AboutDialog
                    isShowModal={isAboutDialog}
                    url={url}
                    aboutDialogTitle={aboutDialogTitle}
                    handleClick={this.handleClick}
                    selectedTiTab={selectedTiTab}
                />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    customTIData: state.CustomTIModuleReducer
})

const mapDispatchToProps = (dispatch) => ({
    initCustomTIModal: () => dispatch(initCustomTIModal()),
    handleModalHide: (isSaveObject, isRemainOpen) => dispatch(handleModalHide(isSaveObject, isRemainOpen)),
    updateItemStatus: (isActive, item, color, weight) => dispatch(updateItemStatus(isActive, item, color, weight)),
    onTabSelectionChanged: (selectedPeriodicityTabKey) => dispatch(onTabSelectionChanged(selectedPeriodicityTabKey)),
    onInputValChange: (e, item) => dispatch(onInputValChange(e, item)),
    restoreDefaults: () => dispatch(restoreDefaults()),
    onApplyToAllIntervals: (e) => dispatch(onApplyToAllIntervals(e)),
    onInputFocusLost: (e, item) => dispatch(onInputFocusLost(e, item))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomTIModule);

CustomTIModule.childContextTypes = {
    shortcuts: PropTypes.object.isRequired
}