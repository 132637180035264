import BaseServiceApi from 'BaseServiceApi';
import BaseServiceApiWCF from 'BaseServiceApiWCF';
import GraphTypeEnum from 'GraphType';
import GroupsQuadDataRequest from '../Requests/GroupsQuadDataRequest';
import GroupsBenchMarkDataRequest from '../Requests/GroupsBenchMarkDataRequest';
import GroupsGeographyDataRequest from '../Requests/GroupsGeographyDataRequest';
import GlobalOutlookTrendCountDataRequest from '../Requests/GlobalOutlookTrendCountDataRequest';
import GroupsInstrumentDataRequest from '../Requests/GroupsInstrumentDataRequest';
import GroupsMainDataRequest from '../Requests/GroupsMainDataRequest';
import GroupsLiqFilterRequest from '../Requests/GroupsLiqFilterRequest';
import GetComparisionChartDataRequest from '../Requests/GetComparisionChartDataRequest';
import GetLatestTradingDateRequest from '../Requests/GetLatestTradingDateRequest';
import GetCompareChartScaleMaxMinDataRequest from '../Requests/GetCompareChartScaleMaxMinDataRequest'
import LocalizationStore from 'LocalizationStore';



class CompareApi {
    constructor() {
        this.builder = BaseServiceApiWCF.getBuilder();
        this.rayData = this.builder.build("RayChartCommon");
    }

    getQuadData(quadRequestData) {
        const req = new GroupsQuadDataRequest(quadRequestData.osid, quadRequestData.fromdate, quadRequestData.enddate, quadRequestData.code, quadRequestData.type, quadRequestData.includeFilterData);
        return BaseServiceApi.processServiceCall(req);
    }

    getBenchMarkData(benchMarkRequestData) {
        const req = new GroupsBenchMarkDataRequest(benchMarkRequestData.code, benchMarkRequestData.date);
        return BaseServiceApi.processServiceCall(req);
    }

    getGeographyData() {
        const req = new GroupsGeographyDataRequest();
        return BaseServiceApi.processServiceCall(req);
    }

    getGlobalTrendCountData(globalTrendRequestData) {
        const req = new GlobalOutlookTrendCountDataRequest(globalTrendRequestData.fromdate, globalTrendRequestData.enddate);
        return BaseServiceApi.processServiceCall(req);
    }

    getGrpsInstrumentData(grpsInstrumentRequestData) {
        const req = new GroupsInstrumentDataRequest(grpsInstrumentRequestData.indCode, grpsInstrumentRequestData.countryCode, grpsInstrumentRequestData.itemName, grpsInstrumentRequestData.displayName);
        return BaseServiceApi.processServiceCall(req);
    }

    getGroupsMainData() {
        const req = new GroupsMainDataRequest();
        return BaseServiceApi.processServiceCall(req);
    }

    getLiqFilterData() {
        const req = new GroupsLiqFilterRequest();
        return BaseServiceApi.processServiceCall(req);
    }

    getCompareChartMaxMinData(requestData){
        const req = new GetCompareChartScaleMaxMinDataRequest(requestData);
        return BaseServiceApi.processServiceCall(req);
    }

    async getComparisionChartData(request, periodicity, endDate, startDate) {
        if (periodicity != GraphTypeEnum.Annual &&
            periodicity != GraphTypeEnum.Quarterly &&
            periodicity != GraphTypeEnum.Monthly &&
            periodicity != GraphTypeEnum.Weekly &&
            periodicity != GraphTypeEnum.Daily) {
            const errorMsg = "Invalid periodicity for GetComparisionChartData";
            console.error(errorMsg);
            return null;
        }
        try {
            const req = new GetComparisionChartDataRequest("GetComparisonDataForSymbol");
            const ProtoRequest = this.rayData["ComparisonRequest"];
            const protoReq = new ProtoRequest();
            const { Osid, SymTypeEnum, Symbol, LocalSymbol, CoName } = request;
            protoReq.Osid = Osid; // 32691
            protoReq.SymTypeEnum = SymTypeEnum; //SymbolTypeEnum.INDEX
            protoReq.Symbol = Symbol;
            protoReq.LocalSymbol = LocalSymbol;
            protoReq.CoName = CoName;
            const ProtoRequestPeriodicity = this.rayData["Periodicity"];
            const protoReqPeriodicity = new ProtoRequestPeriodicity();
            const lang = LocalizationStore.getLang();
            protoReqPeriodicity.Periodicity = periodicity;
            req.addParam("proto", protoReq.encode64());
            req.addParam("proto", protoReqPeriodicity.encode64());
            req.addParam("endDate", endDate);
            req.addParam("startDate", startDate);
            req.addParam("LanguageKey", lang);
            const result = await BaseServiceApiWCF.processServiceCallNew(req);//appServiceClient.AppService.GetComparisonDataForSymbol(request, periodicity, endDate, startDate, 0)
            
            return result;
        }
        catch (ex) {
            console.error("Exception occurs in GetComparisionChartData.", ex);
        }
        return null;
    }

    async getComparisionDataforSymbols(requests, periodicity, endDate, startDate) {
        if (!requests || requests.length <= 0) {
            const errorMsg = "Invalid data for GetComparisionDataforSymbols";
            console.error(errorMsg);
            return null;
        }

        if (periodicity != GraphTypeEnum.Annual &&
            periodicity != GraphTypeEnum.Quarterly &&
            periodicity != GraphTypeEnum.Monthly &&
            periodicity != GraphTypeEnum.Weekly &&
            periodicity != GraphTypeEnum.Daily) {
            const errorMsg = "Invalid periodicity for GetComparisionDataforSymbols";
            console.error(errorMsg);
            return null;
        }
        try {
            const req = new GetComparisionChartDataRequest("GetComparisonDataForSymbols");
            const reqProto1 = requests.map((request) => {
                let values = "";
                for (const [key, value] of Object.entries(request)) {
                    if (value)
                        values += json2xml({ [key]: value })
                }
                return json2xml({ "ComparisonRequest": { "@xmlns": "", "#text": values } })
            });
            const ProtoRequestPeriodicity = this.rayData["Periodicity"];
            const protoReqPeriodicity = new ProtoRequestPeriodicity();
            protoReqPeriodicity.Periodicity = periodicity;
            const lang = LocalizationStore.getLang();
            req.addParam('requests', reqProto1);
            req.addParam("proto", protoReqPeriodicity.encode64());
            req.addParam("endDate", endDate);
            req.addParam("startDate", startDate);
            req.addParam("LanguageKey", lang);
            const results = await BaseServiceApiWCF.processServiceCallNew(req);
            if (!results) return results;
            let arrayOfResults = [];
            if(!Array.isArray(results)) {
                arrayOfResults = [results];
            }
            else {
                arrayOfResults = results;
            }
            let conResults = arrayOfResults.map((result) => {
                if(!result.Symbol){
                    return null;
                }
                const comResult = result.ComparisionResults;
                const comaprisonResultsArray = comResult ? comResult.ComparisionResult ? Array.isArray(comResult.ComparisionResult) ? comResult.ComparisionResult : [comResult.ComparisionResult] : null : null;
                const ComparisionResults = comaprisonResultsArray ? comaprisonResultsArray.map((c) => ({
                    Date: c.Date,
                    Value: Number(c.Value),
                    Close: Number(c.Close)
                })) : null;
                const conObj = {
                    GraphType: result.GraphType,
                    Symbol: result.Symbol,
                    Osid: Number(result.Osid),
                    StartDate: result.StartDate,
                    EndDate: result.EndDate,
                    ComparisionResults: {
                        ComparisionResult: ComparisionResults
                    },
                    Coname: result.Coname,
                    Accdis: Number(result.Accdis),
                    DataGraphRating: Number(result.DataGraphRating),
                    RelativeStrength: Number(result.RelativeStrength),
                    EpsRank: Number(result.EpsRank),
                    GlobalEpsRank: Number(result.GlobalEpsRank),
                    GlobalDgRating: Number(result.GlobalDgRating),
                    GlobalRsRating: Number(result.GlobalRsRating),
                    MktCap: Number(result.MktCap),
                    AvDolV: Number(result.AvDolV),
                    SymTypeEnum: result.SymTypeEnum,
                    LocalSymbol: result.LocalSymbol
                };
                return conObj;
            });
            conResults = conResults.filter((item) => item != null);
            //appServiceClient.AppService.GetComparisonDataForSymbols(requests, periodicity, endDate, startDate, (int)LocalizationHelper.CurrentLang);
            return conResults;
        }
        catch (ex) {
            console.log("Exception occurs in GetComparisionDataforSymbols.", ex);
        }
        return null;
    }

    async getLatestTradingDate() {
        try {
            const req = new GetLatestTradingDateRequest();
            const result = await BaseServiceApiWCF.processServiceCallNew(req);
            return result;
        }
        catch (ex) {
            console.log("Exception occurs in GetLatestTradingDate.", ex);
        }
        return null;
    }
}

var compareApi = new CompareApi();
export default compareApi;

/*eslint-disable */
function json2xml(o) {
    if (typeof o == 'object' && o.constructor == Object && len(o) == 1) {
        for (var a in o) {
            return toXML(a, o[a]);
        }
    } else {
    }
    function len(o) {
        var n = 0;
        for (var a in o) {
            n++;
        }
        return n;
    }
    function toXML(tag, o) {
        var doc = '<' + tag;
        if (typeof o === 'undefined' || o === null) {
            doc += '/>';
            return doc;
        }
        if (typeof o !== 'object') {
            doc += '>' + safeXMLValue(o) + '</' + tag + '>';
            return doc;
        }
        if (o.constructor == Object) {
            for (var a in o) {
                if (a.charAt(0) == '@') {
                    if (typeof o[a] !== 'object') {
                        doc += ' ' + a.substring(1) + '="' + o[a] + '"';
                        delete o[a];
                    } else {
                        throw new Error((typeof o[a])
                            + ' being attribute is not supported.');
                    }
                }
            }
            if (len(o) === 0) {
                doc += '/>';
                return doc;
            } else {
                doc += '>';
            }
            if (typeof o['#text'] !== 'undefined') {
                if (typeof o['#text'] !== 'object') {
                    doc += o['#text'];
                    delete o['#text'];
                } else {
                    throw new Error((typeof o['#text'])
                        + ' being #text is not supported.');
                }
            }
            for (var b in o) {
                if (o[b].constructor == Array) {
                    for (var i = 0; i < o[b].length; i++) {
                        if (typeof o[b][i] !== 'object'
                            || o[b][i].constructor == Object) {
                            doc += toXML(b, o[b][i]);
                        } else {
                            throw new Error((typeof o[b][i])
                                + ' is not supported.');
                        }
                    }
                } else if (o[b].constructor == Object
                    || typeof o[b] !== 'object') {
                    doc += toXML(b, o[b]);
                } else {
                    throw new Error((typeof o[b]) + ' is not supported.');
                }
            }
            doc += '</' + tag + '>'
            return doc;
        }
    }
    function safeXMLValue(value) {
        var s = value.toString();
        s = s.replace('/\&/g', '&amp;');
        s = s.replace('/\"/g', '&quot;');
        s = s.replace('/</g', '&lt;');
        s = s.replace('/>/g', '&gt;');
        return s;
    }
};