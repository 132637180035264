import BrowserUtil from "./BrowserUtil";

class FormatterUtil {

    constructor() {
        this.supportedLanguages = ["en", "en-US", "en-IN", "en-UK"];
    }

    formatNumber = (num, decimalDigit = 0, max = false) => {
        if (max) {
            return Intl.NumberFormat(this.getLanguage(), { minimumFractionDigits: 0, maximumFractionDigits: decimalDigit }).format(num);
        }
        return Intl.NumberFormat(this.getLanguage(), { maximumFractionDigits: decimalDigit, minimumFractionDigits: decimalDigit }).format(num);
    }

    unformatNumber = (str) => str ? str.replace(/[^0-9.]/g,"") : 0;

    getLanguage = () => {
        if (BrowserUtil.isIEBrowser()) {
            if (this.supportedLanguages.includes(navigator.userLanguage))
                return navigator.userLanguage;
            return this.supportedLanguages[1];
        }
        else if (navigator.languages && navigator.languages.length > 0) {
            if (this.supportedLanguages.includes(navigator.languages[0]))
                return navigator.languages[0];
            return this.supportedLanguages[1];
        }
        else {
            if (this.supportedLanguages.includes(navigator.language))
                return navigator.language;
        }
    }

    formatDate = (date) => new Intl.DateTimeFormat(this.getLanguage()).format(date);

    isNegetiveZeroValue = (cellValue) => (cellValue !== null && cellValue !== undefined && cellValue !== "" && cellValue.includes("-") && cellValue[0] === "-" && parseFloat(cellValue) == 0);

    removeNegetiveSign(value){
        return this.isNegetiveZeroValue(value) ? value.replace("-", "") : value;
    }

    getMonthValue(format) {
        return format.match(/(\bm+\b)/ig);
    }

    getDayValue(format) {
        return format.match(/(\bd+\b)/ig);
    }

    getDateFormatAsLocale(format) {
        try {
            if (format) {
                let monthValues = this.getMonthValue(format);
                let dayValues = this.getDayValue(format);
                let monthValue;
                if (monthValues) {
                    monthValue = monthValues[0];
                }
                let dayValue;
                if (monthValue) {
                    dayValue = dayValues[0];
                }
                let indexValueMonth = format.indexOf(monthValue);
                let indexValueDay = format.indexOf(dayValue);
                switch (this.getLanguage()) {
                    case "en-IN":
                    case "en-UK":
                        if (indexValueDay != -1 && indexValueMonth != -1 && indexValueDay > indexValueMonth) {
                            return format.replace(monthValue, "#").replace(dayValue, "$").replace("$", monthValue).replace("#", dayValue);
                        }
                        break;
                    case "en-US":
                    case "en":
                        if (indexValueDay != -1 && indexValueMonth != -1 && indexValueDay < indexValueMonth) {
                            return format.replace(monthValue, "#").replace(dayValue, "$").replace("$", monthValue).replace("#", dayValue);
                        }
                        break;
                    default:
                        break;
                }

                return format;
            }
        }
        catch (e) {
            return format;
        }

    }

    convertToNan() {
        const covInfinity = Number.NaN + ''
        return covInfinity;
    }

}

const formatterUtil = new FormatterUtil();
export default formatterUtil;