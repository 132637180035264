import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizationStore from "Stores/Localization/LocalizationStore";
import Measure from "react-measure";
import Quadrants from '../Presentational/Quadrants';
import { updateDimensionChange, updateZoomPercentage, updateContextMenuVisibility, stopISPlaying, prepareContextMenuView, autoFitToggle_OnClick } from 'Actions/RotationGraphActions';
import { isEmpty } from "underscore";
import SvgZoomPan from './SvgZoomPan';
import ErrorBoundary from 'ErrorBoundary';
import { CompareMenuType } from "CompareTabType";
import { RotationMenuType } from "Constants/RotationMenuType";
import { PrintMode } from "../../../../../print/printmode.js";
import ConsoleStore from "ConsoleStore";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";

let shouldHandleResize = true;
class CenterRotation extends Component {

    UNSAFE_componentWillMount = () => {
        if (PrintMode.printing) {
            ConsoleStore.addChangeListener(this.ConsoleStateChange);
        }
    }

    componentWillUnmount() {
        if (PrintMode.printing) {
            ConsoleStore.removeChangeListener(this.ConsoleStateChange);
            shouldHandleResize = true;
        }
    }

    ConsoleStateChange() {
        const currentAction = ConsoleStore.getCurrentAction();
        if (currentAction === ConsoleConstants.ActionTypes.SET_CHART_LOADED) {
            shouldHandleResize = false;
        }
    }

    stopISPlaying = () => {
        this.props.isPlaying && this.props.stopISPlaying();
    }

    prepareContextMenuView = (contextMenuObj) => {
        let listItemType = RotationMenuType.Stocks;
        if (this.props.selectedMenuTab === CompareMenuType.Groups) {
            listItemType = this.props.listItemType;
        }
        this.props.prepareContextMenuView(contextMenuObj, listItemType);
    }

    updateZoomPercentage = (zoomPercentage, zoomMatrix) => {
        this.props.updateZoomPercentage(zoomPercentage, zoomMatrix);
    }

    updateContextMenuVisibility = (isVisible) => {
        this.props.updateContextMenuVisibility(isVisible);
    }

    onDimensionsChange = (contentRect) => {
        const dim = contentRect.bounds;
        const { dimensions } = this.props;
        if ((dimensions.width != dim.width || dimensions.height != dim.height) && shouldHandleResize)
            this.props.updateDimensionChange(dim);
    };

    render() {
        const { circleRad } = this.props;
        const { zoomPercentageValue, zoomMatrix } = this.props;
        const { width, height } = this.props.dimensions;

        return (
            <div id="main-rotation">
                <div className="head-center-rotation">
                    {LocalizationStore.getTranslatedData("CRG_LongTermRelativePerformance", "LONG-TERM RELATIVE PERFORMANCE - 26 WEEKS")}
                </div>
                <Measure
                    bounds
                    onResize={this.onDimensionsChange}
                >
                    {({ measureRef }) =>
                        <div className="center-rotation" ref={measureRef}>
                            {this.props.isMenuLoading ?
                                <div id="thediv"><div className="loading loading-spinner"></div></div>
                                :
                                <div id="thediv">
                                    <ErrorBoundary><Quadrants /></ErrorBoundary>
                                    {width > 0 && height > 0 && !isEmpty(this.props.quadGraphResults) ?
                                        <ErrorBoundary><SvgZoomPan quadGraphResults={this.props.quadGraphResults} width={width} height={height} zoomMatrix={zoomMatrix} circleRad={circleRad} zoomPercentageValue={zoomPercentageValue} updateZoomPercentage={this.updateZoomPercentage} updateContextMenuVisibility={this.updateContextMenuVisibility} prepareContextMenuView={this.prepareContextMenuView} stopISPlaying={this.stopISPlaying} autoFitToggle_OnClick={autoFitToggle_OnClick} /></ErrorBoundary> : null}
                                </div>
                            }
                        </div>
                    }
                </Measure>
                <div className="left-rotation"><div className="left-rotation-title">{LocalizationStore.getTranslatedData("CRG_MOMENTUM4wks", "MOMENTUM - 4 WEEKS")}</div></div>
                <div className="right-rotation"></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    const { dimensions } = state.rotationGraph.CenterRotationReducer;

    if (selectedMenuTab === CompareMenuType.Symbols) {
        const { circleRad } = state.rotationGraph.CenterRotationReducer.SymbolsData;
        const { isPlaying } = state.rotationGraph.BenchMarkReducer.SymbolsData;
        const isMenuLoading = state.comparisonAndRotationMenuPanel.MenuTabReducer.isLoading.RotationGraph.SymbolsLoading;
        const { zoomPercentageValue, zoomMatrix } = state.rotationGraph.HeaderPaneReducer.SymbolsData;
        const { quadGraphResults } = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer.RotationMenuStates;

        return { selectedMenuTab, circleRad, isPlaying, dimensions, isMenuLoading, zoomPercentageValue, zoomMatrix, quadGraphResults };
    }
    else {
        const { circleRad } = state.rotationGraph.CenterRotationReducer.GroupsData;
        const { isPlaying } = state.rotationGraph.BenchMarkReducer.GroupsData;
        const isMenuLoading = state.comparisonAndRotationMenuPanel.MenuTabReducer.isLoading.RotationGraph.GroupsLoading;
        const { zoomPercentageValue, zoomMatrix } = state.rotationGraph.HeaderPaneReducer.GroupsData;
        const { quadGraphResults } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.RotationMenuStates;
        const { listItemType } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.RotationMenuStates;

        return { selectedMenuTab, circleRad, isPlaying, dimensions, isMenuLoading, zoomPercentageValue, zoomMatrix, quadGraphResults, listItemType };
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateDimensionChange: (dimensions) => dispatch(updateDimensionChange(dimensions)),
    updateZoomPercentage: (zoomPercentage, zoomMatrix) => dispatch(updateZoomPercentage(zoomPercentage, zoomMatrix)),
    updateContextMenuVisibility: (isVisible) => dispatch(updateContextMenuVisibility(isVisible)),
    stopISPlaying: () => stopISPlaying(),
    autoFitToggle_OnClick: (value) => dispatch(autoFitToggle_OnClick(value)),
    prepareContextMenuView: (contextMenuObj, listItemType) => dispatch(prepareContextMenuView(contextMenuObj, listItemType))
});

export default connect(mapStateToProps, mapDispatchToProps)(CenterRotation);