import GraphType from "../Constants/GraphType.js";
import BaseServiceApi from "BaseServiceApi";
import Serializable from "Serializable";
var PeriodicityType = BaseServiceApi.rayData.PeriodicityType;
var GraphTypeValue = BaseServiceApi.rayData.GraphTypeEnum;

class PeriodicityHelper {
  mapMajorPeriodicities(periodicity) {
    let result = GraphType.Weekly;
    switch (periodicity) {
      case GraphType.Weekly:
        result = GraphType.Weekly;
        break;
      case GraphType.NotAvailable:
      case GraphType.Daily:
        result = GraphType.Daily;
        break;
      case GraphType.Intraday:
      case GraphType.Intraday10Min:
      case GraphType.Intraday5Min:
      case GraphType.Intraday15Min:
      case GraphType.Intraday30Min:
      case GraphType.Intraday60Min:
      case GraphType.Intraday1Min:
        result = GraphType.Intraday;
        break;
      case GraphType.Annual:
        result = GraphType.Annual;
        break;
      case GraphType.Monthly:
        result = GraphType.Monthly;
        break;
      case GraphType.Quarterly:
        result = GraphType.Quarterly;
        break;

      default:
        break;

    }

    return result;
  }

  mapFundamentalTabPeriodicities(periodicity) {
    let result = GraphType.FundamentalsAnnual;
    switch (periodicity) {
      case GraphType.FundamentalsAnnual:
        result = GraphType.FundamentalsAnnual;
        break;
      case GraphType.FundamentalsSemiAnnual:
        result = GraphType.FundamentalsSemiAnnual;
        break;
      case GraphType.FundamentalsQuarterly:
        result = GraphType.FundamentalsQuarterly;
        break;
      default:
        break;
    }
    return result;
  }

  getDefaultPeriodcityButtonOptions() {
      const options = new Serializable();
      const mnthly = new Serializable();
      mnthly.Header = "Monthly";
      mnthly.Periodicity = GraphType.Monthly;
      mnthly.IsSelected = false;
      mnthly.disabled = false;
      options.Button4 = mnthly;

      const weekly = new Serializable();
      weekly.Header = "Weekly";
      weekly.Periodicity = GraphType.Weekly;
      weekly.IsSelected = true;
      weekly.disabled = false;
      options.Button3 = weekly;

      const daily = new Serializable();
      daily.Header = "Daily";
      daily.Periodicity = GraphType.Daily;
      daily.IsSelected = false;
      daily.disabled = false;
      options.Button2 = daily;

      const min = new Serializable();
      min.Header = "5 Min";
      min.Periodicity = GraphType.Intraday5Min;
      min.IsSelected = false;
      min.disabled = false;
      options.Button1 = min;

      return options;
  }
  getDefaultPeriodcityButtons() {
      return Object.assign(new Serializable, { Button4: GraphType.Monthly, Button3: GraphType.Weekly, Button2: GraphType.Daily, Button1: GraphType.Intraday5Min});
  }

  getValidPeriodicitiesHeader() {
    var headers = {};
    for (let periodicity in GraphType) {

      switch (periodicity) {
        case GraphType.Intraday1Min:
          headers[periodicity] = "1 Min";
          break;
        case GraphType.Intraday5Min:
          headers[periodicity] = "5 Min";
          break;
        case GraphType.Intraday10Min:
          headers[periodicity] = "10 Min";
          break;
        case GraphType.Intraday15Min:
          headers[periodicity] = "15 Min";
          break;
        case GraphType.Intraday30Min:
          headers[periodicity] = "30 Min";
          break;
        case GraphType.Intraday60Min:
          headers[periodicity] = "60 Min";
          break;
        case GraphType.Intraday:
        case GraphType.NotAvailable:
        case GraphType.FundamentalsAnnual:
        case GraphType.FundamentalsSemiAnnual:
        case GraphType.FundamentalsQuarterly:
          break;
        default:
          headers[periodicity] = periodicity;
      }


    }
    return headers;
  }

  convertToPeriodicity(graphType) {
    let periodicity = PeriodicityType.DAILY_Periodicity;
    switch (graphType) {
      case GraphType.Daily:
        periodicity = PeriodicityType.DAILY_Periodicity;
        break;
      case GraphType.Weekly:
        periodicity = PeriodicityType.WEEKLY_Periodicity;
        break;
      case GraphType.Monthly:
        periodicity = PeriodicityType.MONTHLY_Periodicity;
        break;
      case GraphType.Quarterly:
        periodicity = PeriodicityType.QUARTERLY_Periodicity;
        break;
      case GraphType.Annual:
        periodicity = PeriodicityType.ANNUAL_Periodicity;
        break;
      case GraphType.Intraday1Min:
        periodicity = PeriodicityType.INTRADAY1MIN_Periodicity;
        break;
      case GraphType.Intraday5Min:
        periodicity = PeriodicityType.INTRADAY5MIN_Periodicity;
        break;
      case GraphType.Intraday10Min:
        periodicity = PeriodicityType.INTRADAY10MIN_Periodicity;
        break;
      case GraphType.Intraday15Min:
        periodicity = PeriodicityType.INTRADAY15MIN_Periodicity;
        break;
      case GraphType.Intraday30Min:
        periodicity = PeriodicityType.INTRADAY30MIN_Periodicity;
        break;
      case GraphType.Intraday60Min:
        periodicity = PeriodicityType.INTRADAY60MIN_Periodicity;
        break;
      default:
    }
    return periodicity;
  }

  convertToGraphType(periodicityValue) {
    let graphType = GraphType.Weekly;
    switch(periodicityValue) {
      case PeriodicityType.DAILY_Periodicity:
        graphType = GraphType.Daily;
        break;
      case PeriodicityType.WEEKLY_Periodicity:
        graphType = GraphType.Weekly;
        break;
      case PeriodicityType.MONTHLY_Periodicity:
        graphType = GraphType.Monthly;
        break;
      case PeriodicityType.QUARTERLY_Periodicity:
        graphType = GraphType.Quarterly;
        break;
      case PeriodicityType.ANNUAL_Periodicity:
        graphType = GraphType.Annual;
        break;
      case PeriodicityType.INTRADAY1MIN_Periodicity:
        graphType = GraphType.Intraday1Min;
        break;
      case PeriodicityType.INTRADAY5MIN_Periodicity:
        graphType = GraphType.Intraday5Min;
        break;
      case PeriodicityType.INTRADAY10MIN_Periodicity:
        graphType = GraphType.Intraday10Min;
        break;
      case PeriodicityType.INTRADAY15MIN_Periodicity:
        graphType = GraphType.Intraday15Min;
        break;
      case PeriodicityType.INTRADAY30MIN_Periodicity:
        graphType = GraphType.Intraday30Min;
        break;
      case PeriodicityType.INTRADAY60MIN_Periodicity:
        graphType = GraphType.Intraday60Min;
        break;
      default:
        break;
    }

    return graphType;
  }

  getGraphTypeFromPeriodicity(periodicity) {
    let graphType = GraphTypeValue.Daily_Graph;
    switch (periodicity) {
      case GraphType.NotAvailable:
        graphType = GraphTypeValue.NOTAVAILABLE;
        break;
      case GraphType.Daily:
        graphType = GraphTypeValue.DAILY_GRAPH;
        break;
      case GraphType.Weekly:
        graphType = GraphTypeValue.WEEKLY_GRAPH;
        break;
      case GraphType.Monthly:
        graphType = GraphTypeValue.MONTHLY_GRAPH;
        break;
      case GraphType.Annual:
        graphType = GraphTypeValue.ANNUAL_GRAPH;
        break;
      case GraphType.Quarterly:
        graphType = GraphTypeValue.QUARTERLY_GRAPH;
        break;
      case GraphType.Intraday:
        graphType = GraphTypeValue.INTRADAY_GRAPH;
        break;
      case GraphType.Intraday1Min:
        graphType = GraphTypeValue.INTRADAY1MIN_GRAPH;
        break;
      case GraphType.Intraday5Min:
        graphType = GraphTypeValue.INTRADAY5MIN_GRAPH;
        break;
      case GraphType.Intraday10Min:
        graphType = GraphTypeValue.INTRADAY10MIN_GRAPH;
        break;
      case GraphType.Intraday15Min:
        graphType = GraphTypeValue.INTRADAY15MIN_GRAPH;
        break;
      case GraphType.Intraday30Min:
        graphType = GraphTypeValue.INTRADAY30MIN_GRAPH;
        break;
      case GraphType.Intraday60Min:
        graphType = GraphTypeValue.INTRADAY60MIN_GRAPH;
        break;
      case GraphType.FundamentalsAnnual:
        graphType = GraphTypeValue.FUNDAMENTALSANNUAL_GRAPH;
        break;
      case GraphType.FundamentalsSemiAnnual:
        graphType = GraphTypeValue.FUNDAMENTALSSEMIANNUAL_GRAPH;
        break;
      case GraphType.FundamentalsQuarterly:
        graphType = GraphTypeValue.FUNDAMENTALSQUARTERLY_GRAPH;
        break;
      default:
    }
    return graphType;
  }

  getPeriodicityValue(graphType) {
      switch (graphType) {
          case GraphType.Daily:
              return 1;
          case GraphType.Weekly:
              return 2;
          case GraphType.Monthly:
              return 3;
          case GraphType.Annual:
              return 4;
          case GraphType.Intraday1Min:
              return 5;
          case GraphType.Intraday5Min:
              return 6;
          case GraphType.Intraday10Min:
              return 7;
          case GraphType.Intraday15Min:
              return 8;
          case GraphType.Intraday30Min:
              return 9;
          case GraphType.Intraday60Min:
              return 10;
          case GraphType.Quarterly:
              return 12;
          default:
              return 1;
      }
  }
  getPeriodicityString(graphValue) {
      switch (graphValue) {
          case 1:
              return GraphType.Daily;
          case 2:
              return GraphType.Weekly;
          case 3:
              return GraphType.Monthly;
          case 4:
              return GraphType.Annual;
          case 5:
              return GraphType.Intraday1Min;
          case 6:
              return GraphType.Intraday5Min;
          case 7:
              return GraphType.Intraday10Min;
          case 8:
              return GraphType.Intraday15Min;
          case 9:
              return GraphType.Intraday30Min;
          case 10:
              return GraphType.Intraday60Min;
          case 12:
              return GraphType.Quarterly;
          default:
              return GraphType.Daily;
      }
  }
}

const periodicityHelper = new PeriodicityHelper();
export default periodicityHelper;
