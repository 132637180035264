import React from "react";
import { Modal, ControlLabel, FormGroup, Form } from "react-bootstrap";
import { MetricActionAlert } from "../../Actions/TabDataGraphAction.js";
import { MetricActionAlert_Fundamentals } from "../../Actions/FundamentalsActions";
import FlexibleModal from "../FlexibleModal";
import LocalizationStore from "LocalizationStore";
import { dispatch } from "../../Redux/dispatch";
import { DataGraphConstants } from "../../Constants/DataGraphConstants";
const { ActionTypes } = DataGraphConstants;

const MetricDefinition = ({ definition, industryName, showModal, closeModal, caller, x_parent, y_parent }) => {

    const hideDialog = () => {
        if (caller === 'Fundamentals') {
            MetricActionAlert_Fundamentals(false);
        }
        else if (caller === 'Ownership') {
            closeModal();
        }
        else {
            dispatch({
                type: ActionTypes.CLOSE_METRIC_DEF,
                data: false
            });
            MetricActionAlert(false, "");
        }
    }

    const getXPosition = () => {
        let x = 0;
        const w = 280;

        if (x_parent) {
            x = x_parent - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2;
        }

        if (x < 0)
            x = 0;

        return x;
    }

    const getYPosition = () => {
        let y = 0;
        const h = 260;

        if (y_parent) {
            y = y_parent - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2;
        }

        if (y < 0)
            y = 0;

        return y;
    }

    return (
        <FlexibleModal left={getXPosition()} top={getYPosition()} open={showModal ? showModal : true} close={closeModal}
            draggable="true" className="modal-popup metric-definition">
            <Modal.Header>
                <Modal.Title>
                    <span className="cap-header">{LocalizationStore.getTranslatedData("MetDef_Title", "Metric Definition")}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="disable-drag">
                <div className="modal-material disable-drag">
                    <div inline className="metric-definition-content">
                        {caller && caller === 'Ownership' ?
                            <h6 className="metric-head disable-drag"> {industryName} </h6> :
                            <h6 className="metric-head disable-drag"> {LocalizationStore.getTranslatedData(`Index_Industry_${definition.IndustrySymbol}`, industryName)} </h6>
                        }
                        {caller && caller === 'Ownership' ? <p className="metric-description disable-drag"> {definition} </p>
                            :
                            <p className="metric-description disable-drag"> {LocalizationStore.getTranslatedData(`Industry_Definition_${definition.IndustrySymbol}`, definition && definition.Definition)} </p>
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn-box center">
                    <button type="button" className="btn btn-xs btn-default btn-secondary disable-drag" data-effect="fadeOut" onClick={hideDialog}>{LocalizationStore.getTranslatedData('Close', 'Close')}</button>
                </span>
            </Modal.Footer>
        </FlexibleModal>

    );
}

export default MetricDefinition;
