import PropTypes from 'prop-types';
import React, { Component } from "react";
import LocalizationStore from 'LocalizationStore';

export default class RestoreWarning extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.state = {
      isopen: true
    };
  }

  handleReset() {
    this.props.onReset();
  }

  onCancel() {
    if (this.props.onCancel) {
      setTimeout(()=> {
        this.props.onCancel();
      }, 0);
    }
  }
  render() {
    
    return (
            <div className="modal-body">
              <div className="preference">
                <p>{LocalizationStore.getTranslatedData('resdef_msg1', 'Clicking Reset will restore all default Settings')}</p>
                <p>{LocalizationStore.getTranslatedData('resdef_msg2', ' throughout the entire application.')}</p>
                <div className="btn-box">
                    <button onMouseDown={this.handleReset} className="btn btn-xs btn-default btn-secondary " onClick={this.props.hideModal}>{LocalizationStore.getTranslatedData('set_hp_hw_reset', 'RESET')}</button>
                    <button onMouseDown={this.onCancel} className="btn btn-xs btn-default btn-secondary" onClick={this.props.hideModal}>{LocalizationStore.getTranslatedData('cancel', 'CANCEL')}</button>
                </div>
              </div>
            </div>
          );
        }
      }

RestoreWarning.propTypes = {
  onCancel: PropTypes.func,
  onReset: PropTypes.func
};
