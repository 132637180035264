import { blockMenuTypes } from '../../../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants';
import BlockType from "BlockType";
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import LabelMenu from "LabelMenu";
import LineVisual from '../../../../../../RayCustomControls/Chart/Visuals/LineVisual';
import { map } from "underscore";
import { openCloseIndicatorBlock } from '../../../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions';
import PropTypes from 'prop-types';
import RayChart from '../../../../../../RayCustomControls/Chart/RayChart';
import SinglePointVisual from '../../../../../../RayCustomControls/Chart/Visuals/SinglePointVisual';
import SettingsStore from "SettingsStore";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { updateHeight } from "../../../../../../Actions/ExternalDataUploaderActions";
import React, { Component } from 'react';
import { updateChartRefs, updateContextHit } from '../../../../../../Actions/PricePanelActions';

class ExternalDataIndicator extends Component {
  constructor(props) {
    super(props);
    this.MainChart = React.createRef();
  }
  componentDidMount() {
    if (this.MainChart) {
      this.props.updateChartRefs(this.MainChart, `${BlockType.ExternalData}-${this.props.listId}`)
    }
  }

  onChartResize = (e) => {
    this.props.updateHeight(e.height);
  }

  renderLabels = (item, index) => {
    const color = ThemeHelper.getThemedBrush(item.Color);
    if (item.IsVisible) {
      return (
        <li key={index}>
          <span className="chart-legend-rectangle" style={{ background: color, color: color }}>o</span>
          <span className="chart-legend-label">{item.Name}</span>
        </li>
      )
    }
  }

  prepareLabels = () => {
    const { menu, header, listId } = this.props;
    const children = menu.children;

    return (
      <div className="chart-panel-right-content">
        <LabelMenu label={header} isTICustomModal={true} isShowSettingsDialog={true} isShowAppearanceDialog={false} position="initial" commonStock='false' listId={listId} />
        <div className="chart-legend">
          <ul>
            {children && map(children, (item, index) =>
              this.renderLabels(item, index)
            )}
          </ul>
        </div>
      </div>
    )
  }

  collapseBlock(menu) {
    this.props.onCollapseBlock(blockMenuTypes.ED, BlockType.ExternalData, false, menu, this.props.listId);
  }

  // PANWEB-6648
  getSourceData = (listId, key) => {
    let data = this.props.timeSeriesData_indicatorPane[listId][key];
    data === undefined || Object.keys(data).length === 0 ? data = [] : data;
    return data
  }

  render() {
    TimeTrackingWindow.trackChartLoadingTime();
    const { menu, listId, header } = this.props;
    const children = menu.children;

    return (
      <div className="chartWrapper symbol-chart-wrap" listId={this.props.listId}>
        <RayChart ref={this.MainChart} scale={this.props.timeSeriesData_indicatorPane && this.props.timeSeriesData_indicatorPane[listId] ? this.props.timeSeriesData_indicatorPane[listId].scale : {}}
          nodeWidth={this.props.nodeWidth} isTI={true}
          leftScaleWidth={this.props.leftScaleWidth}
          righscaleWidth={this.props.rightScaleWidth}
          onChartResize={this.onChartResize}
          TimeLine={this.props.TimeLine}
          trackpriceReqArgs={{ listId, chartType: BlockType.ExternalData, menu, header }}
        >
          {this.props.timeSeriesData_indicatorPane && this.props.timeSeriesData_indicatorPane[listId] && children && map(children, (item, key) =>
            item && item.IsVisible ? this.getSourceData(listId,key).length === 1 ? <SinglePointVisual key={key} isToolTip={this.props.showToolTip} ref={(ref) => { this.Line = ref; }} DataSource={this.getSourceData(listId,key)}
            LineColor={ThemeHelper.getThemedBrush(item.Color)} LineThickness={2} Draggable={false} lineID={item.Name} useCanvas={this.props.useCanvas} /> :
            <LineVisual key={key} isToolTip={this.props.showToolTip} ref={(ref) => { this.Line = ref; }} DataSource={this.getSourceData(listId,key)}
              LineColor={ThemeHelper.getThemedBrush(item.Color)} LineThickness={2} Draggable={false} lineID={item.Name} updateContextHit={()=> this.props.updateContextHit(header, listId)} useCanvas={this.props.useCanvas} /> : null
          )}
        </RayChart>
        <Button data-disable-track-price="true" onClick={() => this.collapseBlock(menu)} bsSize="xsmall" className="collapse-block"><div className="collapse-btn"></div></Button>
        {this.prepareLabels()}
      </div>
    );
  }
}

const mapStateToProps = ({ DatagraphReducers, externalDataUploader, appColor}, props) =>{ 
  const TimeLine = DatagraphReducers.TimeLineReducer.TimeLineData.dates;
  const { timeSeriesData_indicatorPane, indicatorListId } = externalDataUploader.externalDataUploadSymbolReducer;
  const { leftScaleWidth, rightScaleWidth, nodeWidth, showToolTip, useCanvas } = DatagraphReducers.DataGraphReducer;
  const isSettingChanged = indicatorListId === props.listId
  const { isThemeChanged } = appColor
  return { TimeLine, timeSeriesData_indicatorPane, leftScaleWidth, rightScaleWidth, nodeWidth, showToolTip, 
    isSettingChanged, isThemeChanged, useCanvas }
}

const mapDispatchToProps = (dispatch) => ({
  updateHeight: (height) => dispatch(updateHeight(height)),
  onCollapseBlock: (pblockType, blockType, isOpen, item, listId,) => dispatch(openCloseIndicatorBlock(pblockType, blockType, isOpen, item, listId)),
  updateChartRefs: (chart, chartType) => dispatch(updateChartRefs(chart, chartType)),
  updateContextHit: (header, listId)=> dispatch(updateContextHit({lineID: BlockType.ExternalData, header, listId})),
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ExternalDataIndicator);

ExternalDataIndicator.propTypes = {
  menu: PropTypes.object.isRequired,
  listId: PropTypes.string.isRequired,
  header: PropTypes.string,
  updateHeight: PropTypes.func,
  rightMargin: PropTypes.number,
  periodicity: PropTypes.string,
  ViewStore: PropTypes.object,
  PriceChartStore: PropTypes.object,
  VolumeChartStore: PropTypes.object
}