import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

import { ShareAccessType, ShareActionConstants } from "../../Constants/ShareConstants"

export default class SaveSharedDataRequest extends BaseServiceRequest {
    constructor(savedSharedData, nodeIds, nodeType, sharedAction) {
        super();

        let shareRequest = undefined;

        if (sharedAction === ShareActionConstants.SHARE_RECIPIENTS) {
            super.addRequestUri("sharenode.pb");

            const ReqProto = BaseServiceApi.rayData["SharedNodesData"];
            shareRequest = new ReqProto();

            const ReqProtoData = BaseServiceApi.rayData["SharedNodeData"];

            savedSharedData.map((rowData) => {
                const sharedData = new ReqProtoData();

                sharedData.recipientId = rowData.recipientId;
                sharedData.nodeId = rowData.nodeId;
                sharedData.nodeName = rowData.nodeName;
                sharedData.nodeTypeId = rowData.nodeType;
                sharedData.isShared = rowData.isShared;
                sharedData.accessLvl = sharedData.isShared
                    ?
                    rowData.accessLvl
                    :
                    ShareAccessType.SHARE_NOACCESS

                shareRequest.sharedNodes.push(sharedData);
            });

        }
        else {
            super.addRequestUri("sharenodes.pb");

            const ReqProto = BaseServiceApi.rayData["ShareTypeUsersRequestData"];
            shareRequest = new ReqProto();
            shareRequest.nodeIds = nodeIds;
            shareRequest.nodeType = nodeType;

            const ReqProtoData = BaseServiceApi.rayData["ShareUserPermData"];

            savedSharedData.map((rowData) => {
                const sharedData = new ReqProtoData();
                sharedData.recipientId = rowData.recipientId;
                sharedData.accessLvl = rowData.isShared
                    ?
                    rowData.accessLvl
                    :
                    ShareAccessType.SHARE_NOACCESS
                shareRequest.userPerms.push(sharedData);
            });
        }

        const reqcontent = shareRequest.encode();
        super.setContent(reqcontent);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.SHARE;
    }

    getMessageName() {
        return "Response";
    }

}
