import { put, call, select, takeLatest } from 'redux-saga/effects';
import { ListManagerCustomMetrics } from "../../Constants/ListManagerCustomMetrics";
import listApi from '../../ServiceApi/Apis/ListApi';
const { ActionTypes } = ListManagerCustomMetrics;
import {
  addCustomMetricUnits, unit, decimalsSelect, exampleFormatIdSelect, renameObj, deleteCustId,
  selectedMetric, custDescription, saveDataformat, customMetricNames, results, custUnits, tempmetricName,
  isCustomColEditSelect, uniteditStart, decimaleditStart, metricItemSymbol, metricCategories, exampleFormat,
  selectedDecimalId, selectedUnitId, unitNameSelect, stateSaved, editFormatOpen,currentCustomMetricId
} from '../../Reducers/CustomMetricsReducers/selectors';
import UserInfoUtil from "UserInfoUtil";
import ListStore from "../../Stores/NavModules/NavList/ListStore";
import GridActions from "../../Actions/GridActions"
import { map } from "underscore";
import StringUtil from "StringUtil";
import ListActions from '../../Actions/ListActions';
import { GridConstants } from "GridConstants";
import BaseServiceApi from 'BaseServiceApi';
let Parser = require('expr-eval').Parser;
let ListColumnDataType = BaseServiceApi.rayData["ListColumnDataType"];
const customCalcInfoData = BaseServiceApi.rayData["customCalcInfoData"];
let saveData = new customCalcInfoData();
import ONeilViewStore from "ONeilViewStore";
import LocalizationStore from 'LocalizationStore';

/***************************** Subroutines ************************************/
function* handleBrowseMetrics() {
  yield put({
    type: ActionTypes.BROWSE_METRICS_CLICK,
    isMetricLibraryOpen: true,
    loadingMetricLibrary: true,
    isColumnFilter: true
  });
}

function* GetuserCodeforMetricName() {
  let userInfo, name = '', namesplits, firstletter, secondletter;
  try {
    userInfo = UserInfoUtil.getUserInfo().name;
    namesplits = userInfo.trim().split(' ');
    if (namesplits) {
      firstletter = namesplits[0].charAt(0).toUpperCase();
      if (namesplits.length >= 2) {
        if (namesplits[0].length > 0) {
          secondletter = namesplits[namesplits.length - 1].charAt(0).toUpperCase();
        }
      }
      else if (namesplits.length == 1) {
        secondletter = namesplits[0].charAt(1).toUpperCase();
      }
    }
    name = " (" + firstletter + secondletter + ")";
    return name;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, GetuserCodeforMetricName ${error}`
    })
  }
}

function* findDuplicateList(nodesToSearch, nodeName) {
  let searchData = '';
  try {
    if (!StringUtil.isEmpty(nodesToSearch) && nodesToSearch.length > 0)
      searchData = nodesToSearch.find((item) => item.CategoryName == nodeName);
    return searchData;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, findDuplicateList ${error}`
    })
  }
}

function* handleDuplicateMetricName(nodeName) {

  let username, newName, count = 0, nodesToSearch, duplicateNamed, trimNodeName;
  try {
    username = yield call(GetuserCodeforMetricName);
    if (username === undefined) {
      username = "Guest";
    }
    newName = nodeName;
    nodesToSearch = yield call(getCustomMetricLists);
    duplicateNamed = yield call(findDuplicateList, nodesToSearch, nodeName);
    trimNodeName = nodeName.replace(username.trim(), "");//.trim();
    while (duplicateNamed && duplicateNamed !== undefined) {
      newName = trimNodeName.concat(" (" + ++count + ")" + username);
      duplicateNamed = yield call(findDuplicateList, nodesToSearch, newName);
    };
    if (!newName.includes(username)) {
      newName = newName + username;
    }
    return newName;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, handleDuplicateMetricName ${error}`
    })
  }
}

export async function getMetricLibrary() {
  const result = await listApi.getMetricLibrary();
  return result;
}

function* getCustomMetricLists(isApi = false) {

  let custmetricobj, metricLib, metricResponseData, results = [];
  try {
    if (isApi) {
      metricResponseData = yield call(getMetricLibrary);
      metricLib = ListStore.getMetricLibraryData(metricResponseData);
    } else {
      metricLib = ListStore.getState().metricData;
    }
    custmetricobj = metricLib.itemData.filter((item) => item.isCustomColumn);

    custmetricobj.map((itemDetail) => {
      let obj = { CategoryName: itemDetail.ColumnDisplayName, CategoryId: itemDetail.MsItemID, Description: itemDetail.Description };
      results = [...results, obj]
    });

    return results;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, getCustomMetricLists ${error}`
    })
  }
}

function* getDecimals(value = 0) {

  let unitDecimal = '';
  if (value == 0) { unitDecimal = ""; }
  else if (value == 1) { unitDecimal = ".0"; }
  else if (value == 2) { unitDecimal = ".00"; }
  else if (value == 3) { unitDecimal = ".000"; }
  else if (value == 4) { unitDecimal = ".0000"; }

  return unitDecimal;
}

async function getCustomColDetails(MsItemID) {
  const result = await listApi.getCustomColumn(MsItemID);
  return result;
}

function* handleAddMetrics({ data }) {

  let categoryLists, selectedMetrics, unitsNameString, unitsEx, decimalsEx, userInitial, custUnits, customMetricId,
    customMetricName = initData.CustomMetricName, exampleFormat = initData.Init_Format, custDescriptions = '';
  let editData = {}, returnData = {}, saveData = {}, metricLib = [];
  let existingColumn = true;
  let isMetricSaved = false;
  let sourceData = {};
  let isCustomColEdit = yield select(isCustomColEditSelect);
  let selectedUnitId = 0, selecteddecimalsId = 0, exampleFormatId = ListColumnDataType.DOUBLE_ListColumnData;
  try {
    categoryLists = data.categoryLists ? data.categoryLists : '';
    selectedMetrics = data.selectedMetrics ? data.selectedMetrics : '';
    let lookBack = selectedMetrics.periodicity === 0 && selectedMetrics.timeback === 0 ? false : true;
    userInitial = yield call(GetuserCodeforMetricName);
    custUnits = ListStore.getCustomUnits();
    metricLib = ListStore.getState().metricData;

    if (selectedMetrics.isCustomColumn && !data.metricsOpen) {
      let custColumnData = yield call(getCustomColDetails, selectedMetrics.MsItemID)
      let customData = custColumnData.customCalcInfoData;
      isCustomColEdit = true;
      sourceData = {
        sourceMetricId: selectedMetrics.MsItemID,
        sourceMetricName: selectedMetrics.ColumnDisplayName
      }
      returnData = {
        ColumnDisplayName: "",
        Definition: customData.definition ? customData.definition.trim() : "",
        itemSymbol: "",
        isLookBackAvailable: false,
        Formula: yield call(GetFormulaText, customData.calcFormula),
        metricName: customData.customMetricName ? customData.customMetricName.trim() : "",
        sourceMetricData: sourceData
      };

      custDescriptions = customData.description;

      unitsEx = StringUtil.getunits(customData.formatter);
      decimalsEx = yield call(getDecimals, customData.decimals);
      if (customData.formatter != '') {
        exampleFormat = initData.Init_Format + decimalsEx + unitsEx;
        exampleFormatId = ListColumnDataType.DOUBLE_ListColumnData;
      } else {
        exampleFormat = initData.Init_Text;
        exampleFormatId = ListColumnDataType.STRING_ListColumnData;
      }
      let unitK = StringUtil.getunits(customData.formatter);
      if (unitK == 'K')
        selectedUnitId = 1
      else
        selectedUnitId = customData.units - 1;

      selecteddecimalsId = customData.decimals;
      unitsNameString = StringUtil.getunitsName(unitK);
      isMetricSaved = false;
      saveData = {
        decimal: decimalsEx,
        unit: unitsEx,
        decimalID: customData.decimals,
        unitName: unitsNameString,
        unitId: selectedUnitId,
        foramtData: exampleFormat,
        formatId: exampleFormatId
      }
      customMetricName = selectedMetrics.MetricDisplayName;

    } else {
      if (!data.metricsOpen) {
        saveData = {
          decimal: '',
          unit: '',
          decimalID: 0,
          unitId: 0,
          unitName: '',
          exampleFormat: initData.Init_Format,
          formatId: exampleFormatId
        }
      } else {
        saveData = yield select(saveDataformat);
        selectedUnitId = saveData.unitId;
        selecteddecimalsId = saveData.decimalID;
        exampleFormat = saveData.foramtData ? saveData.foramtData: saveData.exampleFormat;
      }

      let stateResult = yield select(results);
      let FormulaGet = '';
      if (data.fromMetricLib) {
        FormulaGet = stateResult.Formula ? stateResult.Formula : '';
      }
      let sourceData = {};
      if (stateResult.sourceMetricData) {
        sourceData = stateResult.sourceMetricData;
      } else {
        sourceData = {
          sourceMetricId: selectedMetrics.MsItemID,
          sourceMetricName: selectedMetrics.ColumnDisplayName
        }
      }
      returnData = {
        ColumnDisplayName: LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + selectedMetrics.MsItemID, selectedMetrics.ColumnDisplayName ? selectedMetrics.ColumnDisplayName.trim() : ""),
        Definition: selectedMetrics.Definition ? selectedMetrics.Definition.trim() : "",
        itemSymbol: (selectedMetrics.itemSymbol && !selectedMetrics.isCustomColumn) ? selectedMetrics.itemSymbol.trim() : "",
        isLookBackAvailable: lookBack,
        Formula: FormulaGet,
        saveData: saveData,
        metricName: selectedMetrics.ColumnDisplayName ? selectedMetrics.ColumnDisplayName.trim() : "",
        sourceMetricData: sourceData,
        isCustomColumn: selectedMetrics.isCustomColumn
      };
      isMetricSaved = yield select(stateSaved);
    }
    if (sourceData.sourceMetricId) {
      let findCol = ONeilViewStore.findColumn(sourceData.sourceMetricId);
      if (findCol == -1) {
        existingColumn = false;
      }
      editData = {
        MsItemID: selectedMetrics.MsItemID,
        MetricDisplayName: selectedMetrics.MetricDisplayName
      }
      customMetricId = selectedMetrics.MsItemID;
    }
    if (!isCustomColEdit)
      customMetricName = yield call(handleDuplicateMetricName, LocalizationStore.getTranslatedData("cm_untitle",customMetricName) + userInitial);
    if (data.fromMetricLib) {
      customMetricName = yield select(customMetricNames);
    }
    yield put({
      type: ActionTypes.ADD_TO_METRIC_DIALOG,
      returnData: returnData,
      selectedMetricData: selectedMetrics,
      categoryData: categoryLists,
      userInitial: userInitial,
      customMetricName: customMetricName,
      isCustomColEdit: isCustomColEdit,
      custColEditData: editData,
      listId: data.listId ? data.listId : '',
      customMetricId: customMetricId,
      selecteddecimalsId: selecteddecimalsId,
      selectedUnitId: selectedUnitId,
      exampleFormat: exampleFormat,
      saveData: saveData,
      custUnits: custUnits,
      itemArray: metricLib,
      custDescription: custDescriptions,
      existingColumn: existingColumn,
      isSearchOpen: false,
      metricSaved: false,
      isMetricManageOpen: false,
      alreadySaved: isMetricSaved,
      isNameEdit: false
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, handleAddMetrics ${error}`
    })
  }
}

function* handleOpenDefinition() {
  yield put({
    type: ActionTypes.OPEN_METRICS_DEFINITION,
    returnData: ''
  });
}

function* handleCustomMetricDuplicate(data) {
  try {
    yield put({
      type: ActionTypes.HANDLE_CLICK_LOADER,
      isLoading: true
    });

    let newName = yield call(handleDuplicateMetricName, data.nodeName);
    yield call(listApi.duplicateCustomMetric, data.nodeid, newName);

    yield call(handleManageMetricsChanges);

    // Update metric store
    ListActions.customMetricUpdate();
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, handleCustomMetricDuplicate ${error}`
    })
  }
}

function* handleCustomMetricDelete() {
  try {
    yield put({
      type: ActionTypes.HANDLE_CLICK_LOADER,
      isLoading: true
    });

    let deleteId = yield select(deleteCustId);

    let currentSelectId = ListStore.getCurrentSelectedDetailId();
    let custMetrics = yield call(getCustomMetricLists); 
    let nextAvailabDetail = yield call(getNextAvailabDetail, deleteId, currentSelectId, custMetrics);
    const deleteRes = yield call(listApi.deleteCustomMetric, deleteId);
    
    if(!deleteRes.responseHeader.error){
        yield put({
          type: ActionTypes.HANDLE_DELETE_METRIC_SUCCESS
        });
    }else{
      yield put({
        type: ActionTypes.HANDLE_VALIDATION_ERROR,
        hasError: true,
        errorMsg: `Unable to delete Custom metric`
      });
      return null;
    }

    const selectedresult = yield select(results);
    let closeDialog = false;
    if (selectedresult && selectedresult.sourceMetricData.sourceMetricId == deleteId) {
      closeDialog = true;
    }

    yield call(handleManageMetricsChanges);

    // Update metric store
    ListActions.customMetricUpdate(true, deleteId, closeDialog, nextAvailabDetail);
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, handleCustomMetricDelete ${error}`
    })
  }
}

function* getNextAvailabDetail(deleteId, currentSelectId, custMetrics) {
  try {
    const currentSelect = custMetrics.filter((i) => i.CategoryId === currentSelectId);
    if (currentSelect.length === 0) return null;
    if (deleteId !== currentSelect[0].CategoryId) {
      return currentSelect[0];
    } else if (deleteId === currentSelect[0].CategoryId) {
      if (custMetrics.length > 1) {
        let nextIndex = custMetrics.findIndex((i) => i.CategoryId === currentSelect[0].CategoryId) + 1;
        nextIndex = nextIndex > custMetrics.length - 1 ? 0 : nextIndex;
        return custMetrics[nextIndex];
      } else {
        return {showMetricDetails: false};
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
}

function* handleRenamesave(data) {
  let newName, username, selectedItem, custMetrics, count = 0, apiCall = true, trimNodeName, duplicateNamed, matchedItem;
  try {
    username = yield call(GetuserCodeforMetricName);
    const renameObjSelect = yield select(renameObj);

    selectedItem = renameObjSelect.find((element) => element.renameId == data.saveId);
    newName = selectedItem.renameName;

    if (!selectedItem.renameName.includes(username)) {
      newName = selectedItem.renameName + username;
    }

    if (newName != data.prevName) {

      yield put({
        type: ActionTypes.HANDLE_CLICK_LOADER,
        isLoading: true
      });

      custMetrics = yield call(getCustomMetricLists);
      trimNodeName = selectedItem.renameName;
      duplicateNamed = yield call(findDuplicateList, custMetrics, newName);

      while (duplicateNamed !== null && duplicateNamed !== undefined) {
        newName = trimNodeName.concat(" (" + ++count + ")" + username);
        matchedItem = custMetrics.find((element) => element.CategoryName == newName);
        if (matchedItem && matchedItem.CategoryId == data.saveId) {
          apiCall = false;
          break;
        }
        duplicateNamed = yield call(findDuplicateList, custMetrics, newName);
      };

      if (apiCall) {
        yield call(listApi.renameCustomMetric, selectedItem.renameId, newName);
      }
      yield call(handleManageMetricsChanges);
      ListActions.customMetricUpdate(true, selectedItem.renameId);
    }
    // Update metric store
    yield put({
      type: ActionTypes.HANDLE_RENAME_SAVE_CHANGE_SUCCESS,
      id: data.saveId,
      nameChanged: newName
    });
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, handleRenamesave ${error}`
    })
  }
}

function* formatSearch(nameKey, data) {

  return data.find((item) => item.defaultId == nameKey + 1);

}

function* handleCustomFormatChange(data) {
  try {
    let exFormat = '', exampleFormatFinal = '';
    let savedData = yield select(saveDataformat);
    let units = yield select(unit);
    let uniteditStarted = yield select(uniteditStart);
    units = units ? units : uniteditStarted ? "" : savedData.unit;
    let decimals = yield select(decimalsSelect);
    let decimaleditStarted = yield select(decimaleditStart);
    decimals = decimals ? decimals : decimaleditStarted ? "" : savedData.decimal;
    let exampleFormatId = yield select(exampleFormatIdSelect);
    if (exampleFormatId == ListColumnDataType.STRING_ListColumnData) {
      exFormat = initData.Init_Text;
    } else {
      exFormat = initData.Init_Format;
    }
    switch (data.field) {

      case 'unit':
        const customMetricUnits = yield select(addCustomMetricUnits);
        const selectedItem = yield call(formatSearch, data.value, customMetricUnits);

        let unitSelName = selectedItem.defaultId == 1 ? selectedItem.defaultName.replace(/ /ig, "") : selectedItem.defaultName.split(' ');
        let unitSymbol = selectedItem.defaultId == 1 ? "" : unitSelName[1].replace(/[\(\)']+/g, '');
        let unitName = selectedItem.defaultId == 1 ? unitSelName : unitSelName[0];
        exampleFormatFinal = exFormat + decimals + unitSymbol;

        yield put({
          type: ActionTypes.HANDLE_RESULT_UNITS_CHANGED,
          exampleFormat: exampleFormatFinal,
          unitSymbolId: data.value,
          unitSymbol: unitSymbol,
          unitName: unitName
        });
        break;

      case 'decimals':
        let unitDecimal = '';
        if (data.value == 0) { unitDecimal = ""; }
        else if (data.value == 1) { unitDecimal = ".0"; }
        else if (data.value == 2) { unitDecimal = ".00"; }
        else if (data.value == 3) { unitDecimal = ".000"; }
        else if (data.value == 4) { unitDecimal = ".0000"; }

        exampleFormatFinal = exFormat + unitDecimal + units;

        yield put({
          type: ActionTypes.HANDLE_RESULT_DECIMALS_CHANGED,
          exampleFormat: exampleFormatFinal,
          unitDecimalId: data.value,
          unitDecimal: unitDecimal,
        });
        break;

      case 'format':
        if (data.value == ListColumnDataType.DOUBLE_ListColumnData) {
          exampleFormatFinal = '1,000' + decimals + units;
        } else {
          exampleFormatFinal = initData.Init_Text;
        }

        yield put({
          type: ActionTypes.HANDLE_RESULT_FORMAT_CHANGED,
          exampleFormat: exampleFormatFinal,
          exampleFormatId: data.value
        });
        break;

      default:
        break;
    }
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, handleCustomFormatChange ${error}`
    })
  }
}

function* handleManageMetrics(data) {
  let custmetricobj = [];
  try {
    if (data.data)
      custmetricobj = yield call(getCustomMetricLists);

    yield put({
      type: ActionTypes.OPEN_MANAGE_METRICS_TRUE,
      custmetricobj: custmetricobj,
      manageMetricState: data.data
    })
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, handleManageMetrics ${error}`
    })
  }
}

function* handleManageMetricsChanges() {
  try {
    let custmetricobj = yield call(getCustomMetricLists, true);
    yield put({
      type: ActionTypes.OPEN_MANAGE_METRICS_TRUE,
      custmetricobj: custmetricobj,
      manageMetricState: true
    })
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, handleManageMetricsChanges ${error}`
    })
  }
}

function* createCustomMetric(data) {

  let error = '', savedId = 0, itemData = 0;
  let existingColumn = false;
  let definition = initData.Init_Definition;
  try {
    yield put({
      type: ActionTypes.HANDLE_EDIT_NAME_OUTSIDE_CLICK
    });

    const metricselected = yield select(selectedMetric);
    const metricDescription = yield select(custDescription);
    let datFormat = yield select(saveDataformat);
    let custMetricName = yield select(tempmetricName);
    const colEditSelect = yield select(isCustomColEditSelect);
    const selectedresult = yield select(results);
    const userInitial = yield call(GetuserCodeforMetricName);
    const editFormat = yield select(editFormatOpen);
    const customMetricId = yield select(currentCustomMetricId);

  if(editFormat){
    const format = yield select(exampleFormat);
    const units = yield select(unit);
    const unitDecimal = yield select(decimalsSelect);
    const unitName = yield select(unitNameSelect);
    const selectDecimalId = yield select(selectedDecimalId);
    const selectUnitId = yield select(selectedUnitId);
    const exampleFormatId = yield select(exampleFormatIdSelect);
    datFormat = { foramtData: format, decimal: unitDecimal, unit: units, unitName: unitName, decimalID: selectDecimalId, unitId: selectUnitId, formatId: exampleFormatId };
  }


    if (custMetricName.length > 50) {
      error = LocalizationStore.getTranslatedData("cm_cmncbgtfc","Custom metric name can't be greater than 50 characters")
    }
    if (custMetricName == userInitial || custMetricName == '') {
      error = LocalizationStore.getTranslatedData("cm_peavnfcm","Please enter a valid name for custom metric.")
    }
    if (!custMetricName.includes(userInitial)) {
      custMetricName = custMetricName + userInitial;
    }
    if(selectedresult.metricName != custMetricName)
          custMetricName = yield call(handleDuplicateMetricName, custMetricName);
    if (error) {
      yield put({
        type: ActionTypes.HANDLE_VALIDATION_ERROR,
        hasError: true,
        errorMsg: error
      });
      return null;
    }

    let decimals = datFormat.decimalID;
    const calcSelectVal = yield call(calcSelectData, data.formula.trim());
    if (calcSelectVal == null) {
      return null;
    }

    const decimalsFormatter = decimals ? decimals : 0;
    const unitFormatter = datFormat.unit ? datFormat.unit : '';

    const columnType = datFormat.formatId == ListColumnDataType.DOUBLE_ListColumnData && datFormat.decimalID == 0 ? ColumnDataType.INT_ListColumnData : datFormat.formatId == ListColumnDataType.STRING_ListColumnData ? ColumnDataType.STRING_ListColumnData : ColumnDataType.DOUBLE_ListColumnData


    if (colEditSelect) {
      itemData = selectedresult.sourceMetricData.sourceMetricId;
    } else {
      if (customMetricId && customMetricId > 0){
        itemData = customMetricId;
      }
    }
    let formatData = '';
    if (datFormat.formatId == ListColumnDataType.DOUBLE_ListColumnData) {
      formatData = "{0:n" + decimalsFormatter + "}" + unitFormatter;
    } else {
      decimals = 0;
      datFormat.unitName = '';
    }

    const savedata = {
      itemData: itemData, periodicity: metricselected.periodicity, timeback: metricselected.timeback, definition: definition, metricDescription: metricDescription,
      decimalID: datFormat.decimalID, unitName: datFormat.unitName, formatData: formatData, custMetricName: custMetricName.trim(), formula: data.formula.trim(), calcSelectVal: calcSelectVal, columnType: columnType
    }

    const info = yield call(prepareSaveData, savedata);

    const callCreate = yield call(listApi.createCustomColumn, info);
    if(callCreate.customCalcInfoData == null){
      error = callCreate.responseHeader.errorShortDesc;
        yield put({
          type: ActionTypes.HANDLE_VALIDATION_ERROR,
          hasError: true,
          errorMsg: error
        });
        return null;
    }
    savedId = callCreate.customCalcInfoData.dataItemId.low;

    if (itemData > 0 && colEditSelect) {

      yield put({
        type: ActionTypes.HANDLE_CLICK_LOADER,
        isLoading: true
      });

      yield call(EditCustomMetric)
    }

    let findCol = ONeilViewStore.findColumn(itemData);
    if (findCol != -1) {
      existingColumn = true;
    }
    yield put({
      type: ActionTypes.HANDLE_CREATE_CUSTOM_METRICS_SUCCESS,
      customMetricId: savedId,
      custMetricName: custMetricName.trim(), 
      closeMetricDialog: itemData > 0 && colEditSelect ? true : false,
      alreadySaved: savedId > 0 ? true : false,
      metricSaved: itemData > 0 ? false : true,
      openDefinition: false,
      existingColumn: existingColumn
    });

    ListActions.customMetricUpdate();

  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, createCustomMetric ${error}`
    })
  }
}

function* prepareSaveData(data) {

  saveData.dataItemId = data.itemData;
  saveData.shortHand = '';
  saveData.interval = data.periodicity ? data.periodicity : 0;
  saveData.lookBack = data.timeback ? data.timeback : 0;
  saveData.definition = data.definition;
  saveData.description = data.metricDescription;
  saveData.decimals = data.decimalID ? data.decimalID : 0;
  saveData.units = data.unitName ? data.unitName : 'NoScaling';
  saveData.formatter = data.formatData;
  saveData.customMetricName = data.custMetricName;
  saveData.previewSymbol = '';
  saveData.previewMsid = 0;
  saveData.isCalcValid = true;
  saveData.calcFormula = yield call(GetCalcFormula, data.formula);
  saveData.calcSelect = data.calcSelectVal;
  saveData.listId = 0;
  saveData.databasesourceId = 1;
  saveData.columnsetviewTypeId = 1;
  saveData.deviceTypeId = 5;
  saveData.coldataType = data.columnType;
  return saveData;
}

function* addColumnToList(data) {
  try {
    yield put({
      type: ActionTypes.HANDLE_CLICK_LOADER,
      isLoading: true
    });
    
    GridActions.addColumnToList(GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_FROM_METRIC, data.listId, data.metricId, null, null, false)

    yield put({
      type: ActionTypes.HANDLE_ADD_TO_COLUMN_SUCCESS,
      closeMetricDialog: true
    })
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: `Error occurs in customMetrics.js, addColumnToList ${error}`
    })
  }
}

function* calcSelectData(formulaText) {

  let itemSymbolMatches = '',cmitemSymbolMatches = '', sourceMetricName = '', matchedmetricitem;
  const greatequalMatches = CALCUS_FORMULA.GreatOrEqualRegex.test(formulaText);
  const lessequalMatches = CALCUS_FORMULA.LessOrEqualRegex.test(formulaText);
  const equalMatches = CALCUS_FORMULA.equalMatchesRegex.test(formulaText);
  const notequalMatches = CALCUS_FORMULA.notequalMatchesRegex.test(formulaText);

  try {
    const lib = ListStore.getState().metricData;
    const selectedresult = yield select(results);
    const datFormat = yield select(saveDataformat);
    const customUnits = yield select(custUnits);
    const allFx = customUnits.defaultFunctionData;
    if (!greatequalMatches && !lessequalMatches && !equalMatches && !notequalMatches && formulaText.includes("=")) {
      throw (CALCUS_FORMULA.NotAValidFormula);
    }
    if (formulaText.includes("<>")) {
      throw (CALCUS_FORMULA.NotAValidFormula);
    }
    if (formulaText.includes(">")) {
      let matches = CALCUS_FORMULA.greaterRegex.exec(formulaText)
      let firstMatch = matches[1];
      let lastMatch = matches[2];
      if(!firstMatch || !lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    if (formulaText.includes("<")) {
      let matches = CALCUS_FORMULA.lesserRegex.exec(formulaText)
      let firstMatch = matches[1];
      let lastMatch = matches[2];
      if(!firstMatch || !lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    if (formulaText.includes("+")) {
      let matches = CALCUS_FORMULA.addRegex.exec(formulaText)
      let firstMatch = matches[1];
      let lastMatch = matches[2];
      if(!firstMatch || !lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    if (formulaText.includes("-")) {
      let matches = CALCUS_FORMULA.subRegex.exec(formulaText)
      let lastMatch = matches[2];
      if(!lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    if (formulaText.includes("*")) {
      let matches = CALCUS_FORMULA.mulRegex.exec(formulaText)
      let firstMatch = matches[1];
      let lastMatch = matches[2];
      if(!firstMatch || !lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    if (formulaText.includes("/")) {
      let matches = CALCUS_FORMULA.divRegex.exec(formulaText)
      let firstMatch = matches[1];
      let lastMatch = matches[2];
      if(!firstMatch || !lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    if (formulaText.includes("!=")) {
      let matches = CALCUS_FORMULA.notequalRegex.exec(formulaText)
      let firstMatch = matches[1];
      let lastMatch = matches[2];
      if(!firstMatch || !lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    if (formulaText.includes(">=")) {
      let matches = CALCUS_FORMULA.gtequalRegex.exec(formulaText)
      let firstMatch = matches[1];
      let lastMatch = matches[2];
      if(!firstMatch || !lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    if (formulaText.includes("<=")) {
      let matches = CALCUS_FORMULA.ltequalRegex.exec(formulaText)
      let firstMatch = matches[1];
      let lastMatch = matches[2];
      if(!firstMatch || !lastMatch){
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }
    const isifelsefucntion = (formulaText.toLowerCase().includes(CALCUS_FORMULA.Ifstring.toLowerCase()) || formulaText.toLowerCase().includes(CALCUS_FORMULA.Elsestring.toLowerCase()));

    if (!isifelsefucntion) {
      formulaText = formulaText.trim();
    } else {
      if (datFormat.unitName == CustomCalc_UnitType.Percent) {
        throw (CALCUS_FORMULA.NotAValidFormula);
      }


      formulaText = formulaText.replace(CALCUS_FORMULA.IfstringRegex, CALCUS_FORMULA.Ifstring.toLowerCase());
      formulaText = formulaText.replace(CALCUS_FORMULA.ElsestringRegex, CALCUS_FORMULA.Elsestring.toLowerCase());

    }

    //replace avg formula
    if (formulaText != null && formulaText.toLowerCase().includes("avg(")) {

      const prevFormText = formulaText;
      formulaText = yield call(RepalceAvgFormulaforEs, prevFormText);
      if (prevFormText == formulaText) {
        throw (CALCUS_FORMULA.NotAValidFormula);
      }
    }

    //replace max formula
    // if (formulaText != null && formulaText.toLowerCase().includes("max("))
    //   formulaText = yield call(ReplaceMaxandMinFormulaforEs, formulaText, "MAX");

    // //replace min formula
    // if (formulaText != null && formulaText.toLowerCase().includes("min("))
    //   formulaText = yield call(ReplaceMaxandMinFormulaforEs, formulaText, "MIN");

    // //replace ISNULL
    if (formulaText != null && formulaText.toLowerCase().includes("isnull{")) {
      formulaText = yield call(ReplaceIsNullFormulaforEs, formulaText, datFormat.unitName);
    }

    if (formulaText == null || formulaText == '') {
      return null;
    }

    const msIdRegexp = formulaText;
    let msitemidFormula = formulaText;
    const msIdRegexpForm = /\[(.*?)\]/ig;
    while ((itemSymbolMatches = msIdRegexpForm.exec(msIdRegexp)) !== null) {
      let itemsymbol = itemSymbolMatches[1];

      if (lib != null && (lib.itemData != null)) {
        if (itemsymbol.includes("#")) {
          const itemsymbolnohashtags = itemsymbol.replace("#", "");
          msitemidFormula = msitemidFormula.replace("[" + itemsymbol + "]", "#" + itemsymbolnohashtags.toLowerCase() + "#").trim();
        }
        else {

          matchedmetricitem = lib.itemData.find((x) => (x.itemSymbol.toUpperCase() || x.MetricDisplayName.toUpperCase()) == itemsymbol.toUpperCase());;
          if (selectedresult && selectedresult.sourceMetricData) {
            if (selectedresult.sourceMetricData.sourceMetricName) {
              sourceMetricName = selectedresult.sourceMetricData.sourceMetricName;
            }
          }
          if (matchedmetricitem && !matchedmetricitem.isCustomColumn) {
            if (matchedmetricitem.MsItemID < 0) // assuming user metric
            {
              throw (LocalizationStore.getTranslatedData("cm_catcm","Cannot add [{0}] to custom metric.", itemsymbol));
            }
            else if ((sourceMetricName == matchedmetricitem.MetricDisplayName) && matchedmetricitem.isCustomColumn) {
              throw (LocalizationStore.getTranslatedData("cm_navfcasm","Not a valid formula, Cannot add same metric"));
            } else {

            }
            let msitemid = matchedmetricitem.MsItemID;
            msitemidFormula = msitemidFormula.replace("[" + itemsymbol + "]",
              "#" +
              msitemid.toString() +
              "#").trim();

          }
          else {
            let custommetricItems = [];
            map(lib.itemData, (itemDetail) => {
              if (itemDetail.isCustomColumn) {
                custommetricItems = [...custommetricItems, itemDetail];
              }
            });
            if (custommetricItems) {

              matchedmetricitem = custommetricItems.find((x) => itemsymbol && x.MetricDisplayName == itemsymbol);
              if (matchedmetricitem) {
                if (sourceMetricName == matchedmetricitem.MetricDisplayName) {
                  throw (LocalizationStore.getTranslatedData("cm_navfcasm","Not a valid formula, Cannot add same metric"));
                }
                msitemidFormula = msitemidFormula.replace("[" + itemsymbol + "]", "@" + matchedmetricitem.MsItemID + "@");
              }
              else {
                const cusomMetricRegx = /\[((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*)\]/ig;
                while ((cmitemSymbolMatches = cusomMetricRegx.exec(msitemidFormula)) !== null) {
                  matchedmetricitem = custommetricItems.find((x) => cmitemSymbolMatches[1] && x.MetricDisplayName == cmitemSymbolMatches[1]);
                  if (matchedmetricitem) {
                    if (sourceMetricName == matchedmetricitem.MetricDisplayName) {
                      throw (LocalizationStore.getTranslatedData("cm_navfcasm","Not a valid formula, Cannot add same metric"));
                    }
                    msitemidFormula = msitemidFormula.replace("[" + cmitemSymbolMatches[1] + "]", "@" + matchedmetricitem.MsItemID + "@");
                  }
                }
                // else{
                //   throw (LocalizationStore.getTranslatedData("cm_inavm","[{0}] is not a valid metric.", itemsymbol));
                // }
              }
            }
            else {
              throw (LocalizationStore.getTranslatedData("cm_inavm","[{0}] is not a valid metric.", itemsymbol));

            }
          }
        }
      }
    }

    // // Change functiona names to lower case
    if (allFx && !isifelsefucntion && msitemidFormula) {
      map(allFx, (item) => {
        msitemidFormula = msitemidFormula.replace(item.defaultName.toUpperCase() + "(", item.defaultName.toLowerCase() + "(");
      });
    }
    // Change if else to lower case and Change AND /OR NOT

    if (msitemidFormula) {
      msitemidFormula = msitemidFormula.replace(CALCUS_FORMULA.SingleQuote, '"');
      const strData = String(msitemidFormula).toLowerCase();
      let sAnd = strData.indexOf("and");
      let sOr = strData.indexOf("or");
      let sNot = strData.indexOf("not");

      var indexD = msitemidFormula.indexOf('"');

      if (indexD < 0) {
            if (sAnd > -1) {
                msitemidFormula = msitemidFormula.replace(CALCUS_FORMULA.Andstring, " && ");
            }
            if (sOr > -1) {
                msitemidFormula = msitemidFormula.replace(CALCUS_FORMULA.Orstring, " || ");
            }
            if (sNot > -1) {
                msitemidFormula = msitemidFormula.replace(CALCUS_FORMULA.Notstring, "!");
            }
      } else {
          var strQ = [20];
          var indexC = 0;
          var indexL = 0;
          var newString = "";
          var strLen = msitemidFormula.length;
          if (sAnd > -1 || sOr > -1 || sNot > -1) {
              while (indexD > -1) {
                  strQ[indexC] = indexD;
                  if ((indexC+1) % 2 === 0) {
                      if (sNot > -1 && sNot < strQ[indexC - 1]) {
                          if (newString.length > sNot) {
                              newString = newString.replace(CALCUS_FORMULA.Notstring, "!");
                          } else {
                              newString = newString +
                                  msitemidFormula.substring(indexL, strQ[indexC - 1])
                                  .replace(CALCUS_FORMULA.Notstring, "!");
                              indexL = strQ[indexC - 1];
                          }
                      } else {
                          newString = newString +
                              msitemidFormula.substring(indexL, strQ[indexC]);
                          indexL = strQ[indexC];
                      }
                      if (sAnd > -1 && sAnd < strQ[indexC - 1]) {
                          if (newString.length > sAnd) {
                              newString = newString.replace(CALCUS_FORMULA.Andstring, " && ");
                          } else {
                              newString = newString +
                                  msitemidFormula.substring(indexL, strQ[indexC - 1])
                                  .replace(CALCUS_FORMULA.Andstring, " && ");
                              indexL = strQ[indexC - 1];
                          }
                      } else {
                          newString = newString +
                              msitemidFormula.substring(indexL, strQ[indexC]);
                          indexL = strQ[indexC];
                      }
                      if (sOr > -1 && sOr < strQ[indexC - 1]) {
                          if (newString.length > sOr) {
                              newString = newString.replace(CALCUS_FORMULA.Orstring, " || ");
                          } else {
                              newString = newString +
                                  msitemidFormula.substring(indexL, strQ[indexC - 1])
                                  .replace(CALCUS_FORMULA.Orstring, " || ");
                              indexL = strQ[indexC - 1];
                          }
                      } else {
                          newString = newString +
                              msitemidFormula.substring(indexL, strQ[indexC]);
                          indexL = strQ[indexC];
                      }
                      sAnd = strData.indexOf("and", newString.length);
                      sOr = strData.indexOf("or", newString.length);
                      sNot = strData.indexOf("not", newString.length);
                  }
                  indexD = msitemidFormula.indexOf('"', indexD + 1);
                  indexC++;
              }
              if (newString !== "") {
                  msitemidFormula = String(newString + msitemidFormula.substring(indexL, strLen));
              }
          }
      }
    }

    //Adding Date factor
    if (msitemidFormula && msitemidFormula.toLowerCase().includes("/d")) {
      msitemidFormula = msitemidFormula.toLowerCase().replace(/\/d/ig, "/(1000.0 * 3600 * 24)");
    }
    else if (msitemidFormula != null && msitemidFormula.toLowerCase().includes("/w")) {
      msitemidFormula = msitemidFormula.toLowerCase().replace(/\/w/ig, "/(1000.0 * 3600 * 24 * 7)");
    }
    else if (msitemidFormula != null && msitemidFormula.toLowerCase().includes("/m")) {
      msitemidFormula = msitemidFormula.toLowerCase().replace(/\/m/ig, "/(1000.0 * 3600 * 24 * 30.5)");
    }
    else if (msitemidFormula != null && msitemidFormula.toLowerCase().includes("/y")) {
      msitemidFormula = msitemidFormula.toLowerCase().replace(/\/y/ig, "/(1000.0 * 3600 * 24 * 365)");
    }
    //Today

    if (msitemidFormula && msitemidFormula.toLowerCase().includes("today")) {
      msitemidFormula = msitemidFormula.replace(CALCUS_FORMULA.todaystringRegex, 'DateTime.now().millis');
    }

    if (datFormat.unitName == CustomCalc_UnitType.Percent) {
      msitemidFormula = "(" + msitemidFormula + ") * 100";
    }
    //replace denominators
    msitemidFormula = yield call(ReplaceDenominatorsforEs, msitemidFormula);

    //if else statement
    if (isifelsefucntion) {
      const isValidIfElse = yield call(ValidIfElseExpression, formulaText);
      if (!isValidIfElse) {
        return null;
      }
    }

    return msitemidFormula;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
  }
}

function* ValidIfElseExpression(formulaText) {
  // const Ifstring = "IF";
  try {
    if (formulaText.trim().toLowerCase().startsWith(CALCUS_FORMULA.Ifstring.toLowerCase())) {
      if (!CALCUS_FORMULA.valIfAndElseRegex.test(formulaText.trim().toLowerCase())) {
        throw (CALCUS_FORMULA.ExpressionInValid + CALCUS_FORMULA.IfAndElseInValid);
      }
    }
    else {
      //Validation using NCalc
      let valisNcalc = yield call(ValidUseNcalc, formulaText);
      if (!valisNcalc) {
        return false;
      }
    }
    return true;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
    return false;
  }

}
function* GetFormulaText(calcFormula) {
  let msitemidFormula = calcFormula;
  const lib = ListStore.getState().metricData;
  let itemSymbolMatches = '';
  let matchedmetricitem = '';
  try {
    if (msitemidFormula != null) {
      const custmsIdRegexpForm = /\@(.*?)\@/ig;
      let custommetricItems = [];
      map(lib.itemData, (itemDetail) => {
        if (itemDetail.isCustomColumn) {
          custommetricItems = [...custommetricItems, itemDetail];
        }
      });
      while ((itemSymbolMatches = custmsIdRegexpForm.exec(msitemidFormula)) !== null) {
        let itemsymbol = itemSymbolMatches[1];
        if (custommetricItems) {
          matchedmetricitem = custommetricItems.find((x) => itemsymbol && x.MsItemID == itemsymbol);
          if (matchedmetricitem) {
            msitemidFormula = msitemidFormula.replace("@" + itemsymbol + "@", "[" + matchedmetricitem.MetricDisplayName + "]");
          }
        }
      }
    }
    return msitemidFormula;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
    return false;
  }
}
function* GetCalcFormula(formulaText) {
  let msitemidFormula = formulaText;
  let itemSymbolMatches = '';
  let matchedmetricitem = '';
  try {
  const lib = ListStore.getState().metricData;
  const msIdRegexpForm = /\[(.*?)\]/ig;
  let custommetricItems = [];
  map(lib.itemData, (itemDetail) => {
    if (itemDetail.isCustomColumn) {
      custommetricItems = [...custommetricItems, itemDetail];
    }
  });
  while ((itemSymbolMatches = msIdRegexpForm.exec(msitemidFormula)) !== null) {
    let itemsymbol = itemSymbolMatches[1];
    if (custommetricItems) {
      matchedmetricitem = custommetricItems.find((x) => itemsymbol && x.MetricDisplayName == itemsymbol);
      if (matchedmetricitem) {
        msitemidFormula = msitemidFormula.replace("[" + itemsymbol + "]", "@" + matchedmetricitem.MsItemID + "@");
      }
    }
  }
  return msitemidFormula;
} catch (error) {
  yield put({
    type: ActionTypes.HANDLE_VALIDATION_ERROR,
    hasError: true,
    errorMsg: error
  });
  return false;
}
}
function* ValidUseNcalc(formulaText) {
  try {
    Parser.evaluate(formulaText);
    if (Parser.evaluate(formulaText)) {
      return true;
    }
  }
  catch (e) {
    let error = CALCUS_FORMULA.ExpressionInValid + e;
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
    return false;
  }

  return false;
}

function* ReplaceDenominatorsforEs(formulaText) {
  try {
    let denominatorStringMatches = '';
    let formula = formulaText;
    while ((denominatorStringMatches = CALCUS_FORMULA.denominatorRegex.exec(formula)) !== null) {
      if (denominatorStringMatches) {
        let fraction = denominatorStringMatches[1];
        if (fraction && fraction.split('/').length > 1) {
          const numerator = fraction.split('/')[0];
          const denominator = fraction.split('/')[1];
          // if (numerator == '') continue;
          const isNumeric = isNaN(denominator)
          if (!isNumeric) {
            const newfraction = numerator + "/" + denominator + "d";
            formulaText = formulaText.replace(fraction, newfraction);
          }
        }
      }
    }
    return formulaText;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
  }
}

function* ReplaceIsNullFormulaforEs(formulaText, customCalcUnitType) {
  try {

    let isNullStringMatches = '';
    let index = 0;
    formulaText = formulaText.replace(new RegExp("\isnull", "ig"), "ISNULL");
    while ((isNullStringMatches = CALCUS_FORMULA.IsNullRegex.exec(formulaText)) !== null) {
    if (isNullStringMatches != null) {
      if (customCalcUnitType == CustomCalc_UnitType.Percent) {
        return;
      }
      let item = isNullStringMatches[1];
      // map(isNullStringMatches, (item, i) => {
      // for (let index = 0; index < isNullStringMatches.length - 1; index++) {
        if (item) {
          const isnullItems = item.split(',');
          if (isnullItems.length > 1) {
            const nameLet = "var" + isnullItems[0].replace("(", "").replace(")", "").replace(" ", "").replace("[", "").replace("]", "") + index;
            const ifelsestring = "if (" + isnullItems[0] + " == 0) { " + nameLet + " = " + isnullItems[1] + "} else { " + nameLet + " = " + isnullItems[0] + " };";
            formulaText = ifelsestring + formulaText;
            formulaText = formulaText.replace("ISNULL{" + isnullItems[0] + "," + isnullItems[1] + "}", nameLet);
          }
        }
      // }
      // });
      index++;
    }
  }
    return formulaText;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    })
  }
}

function* ReplaceMaxandMinFormulaforEs(formulaText, formulaIdentifier) {

  let regexstring = '';
  let firstmatchedstringComma = '';
  try {
    if (formulaIdentifier == 'MAX') {
      regexstring = /MAX\(([^)]*)\)/ig;
    } else {
      regexstring = /MIN\(([^)]*)\)/ig;
    }
    let finalformula = null;
    let lastvariableItem = null;
    let item2 = null;
    let maxItemsString = regexstring.exec(formulaText);
    if (maxItemsString) {
      let firstmatchedstring = maxItemsString[1];
      firstmatchedstringComma = firstmatchedstring.replace(firstmatchedstring, firstmatchedstring.replace(new RegExp("\\+", "g"), ","))
      formulaText = formulaText.replace(firstmatchedstring, firstmatchedstringComma);
      firstmatchedstring = firstmatchedstring.replace(new RegExp("\\+", "g"), ",");
      let maxItems = firstmatchedstring.split(',');
      if (maxItems.length > 2) {
        for (let index = 0; index <= maxItems.length - 1; index = index + 1) {
          if (index == 1) {
            continue;
          }
          let item1 = maxItems[index];
          if (index == 0) {
            item2 = maxItems[index + 1];
          }
          if (lastvariableItem == null && index == 0) {
            if (item2 != null)
              finalformula = "varmaxitem" + index + " = " + formulaIdentifier.toLowerCase() + "(" + item1.toLowerCase() + "," + item2.toLowerCase() + ");";
          }
          else {
            finalformula = finalformula + " varmaxitem" + index + " =" + formulaIdentifier.toLowerCase() + "(" + lastvariableItem + "," + item1.toLowerCase() + ");";
          }
          lastvariableItem = "varmaxitem" + index;

        }
      }
      else {
        let formulaIdentifierRegex = '/' + formulaIdentifier + '/i';
        finalformula = formulaText.replace(formulaIdentifierRegex, formulaIdentifier.toLowerCase());
      }
    }

    return finalformula;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
  }
}

function* RepalceAvgFormulaforEs(formulaText) {
  let avgString = "";
  let avgformulaMatches = '';
  let myRegexp = /avg\(([^)]*)\)/ig;
  try {
    while ((avgformulaMatches = myRegexp.exec(formulaText)) !== null) {

      if (avgformulaMatches.length > 0) {
        let splitavgitems = avgformulaMatches[1].split(',');
        if (splitavgitems.length > 1) {
          avgString = avgString + "(";
        }
        for (let index = 0; index < splitavgitems.length; index++) {
          let item = splitavgitems[index];
          avgString = index == splitavgitems.length - 1 ? avgString + item : avgString + item + "+";
        }
        avgString = avgString + ")/" + splitavgitems.length;
      }
      formulaText = formulaText.toUpperCase().replace("AVG(" + avgformulaMatches[1].toUpperCase() + ")", avgString);
    }
    return formulaText;
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
  }
}

function* handleNameChange(data) {

  try {
    yield put({
      type: ActionTypes.HANDLE_NAME_CHANGE_SUCCESS,
      text: data.text,
      isNameEdit: true,
      alertShown: false,
    });

  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
  }
}


function* handleNameSearch(data) {
  let results = [], metricList = [];
  const itemSymbol = yield select(metricItemSymbol);
  metricList = ListStore.getSearchData(data.text);
  const firstIndex = 0;

  map(metricList, (itemDetail) => {
    const childNode = itemDetail.childNode;
    const firstItem = Object.keys(childNode)[firstIndex];
    const selectedItemData = childNode[firstItem][firstIndex];
    results = [...results, selectedItemData];
  });

  yield put({
    type: ActionTypes.HANDLE_NAME_SEARCH_SUCCESS,
    searchResults: results,
    metricListResults: metricList,
    isSearchOpen: results.length > 0 ? true : false,
    text: data.text,
    itemSymbol: data.text ? itemSymbol : ''
  });
}

function* EditCustomMetric() {
  GridActions.editCustomMetricList(GridConstants.ActionTypes.EDIT_CUSTOM_METRICS);
}

function* handleIntervalChange(data) {

  const categories = yield select(metricCategories);
  const selMetric = categories.childNode[data.data][0]
  yield put({
    type: ActionTypes.HANDLE_INTERVAL_CHANGE_SUCCESS,
    data: selMetric,
    isLookBackAvailable: true
  });
}

function* handleLookBackChange(data) {

  const categories = yield select(metricCategories);
  const catList = categories.childNode[data.period];
  const selMetric = catList[data.lookBack];
  yield put({
    type: ActionTypes.HANDLE_LOOKBACK_CHANGE_SUCCESS,
    data: selMetric,
    isLookBackAvailable: true
  });
}

function* handleRenameClick(data) {
  const states = yield select(renameObj);
  const modData = { renameId: data.nodeid, renameName: data.name };
  const renameData = [...states, modData];

  yield put({
    type: ActionTypes.HANDLE_RENAME_CLICK_SUCCESS,
    renameSelected: renameData
  });
}

function* handleSaveEditFormat() {

  const format = yield select(exampleFormat);
  const units = yield select(unit);
  const unitDecimal = yield select(decimalsSelect);
  const unitName = yield select(unitNameSelect);
  const selectDecimalId = yield select(selectedDecimalId);
  const selectUnitId = yield select(selectedUnitId);
  const exampleFormatId = yield select(exampleFormatIdSelect);
  const saveData = { foramtData: format, decimal: unitDecimal, unit: units, unitName: unitName, decimalID: selectDecimalId, unitId: selectUnitId, formatId: exampleFormatId };

  yield put({
    type: ActionTypes.SAVE_METRICS_EDITFORMAT_SUCCESS,
    saveData: saveData,
    openEditFormat: false
  });
}
function* handleClearEditFormat() {

  const states = yield select(saveDataformat);

  yield put({
    type: ActionTypes.CLEAR_METRICS_EDITFORMAT_SUCCESS,
    exampleFormatId: states.formatId,
    selectedUnitId: states.unitId,
    selecteddecimalsId: states.decimalID,
    exampleFormat: states.foramtData ? states.foramtData : initData.Init_Format,
    openEditFormat: false,
  });
}

function* handleFormulaChange(data) {

  yield put({
    type: ActionTypes.HANDLE_FORMULA_CHANGE_SUCCESS,
    alreadySaved: false,
    isNameEdit: false,
    data: data.data
  });
}

function* handleSymbolChange(data) {

  yield put({
    type: ActionTypes.HANDLE_SYMBOL_CHANGE_SUCCESS,
    data: data.data
  });
}

function* handleDefinitionSelect(data) {

  try {
    const metricState = yield select(results);
    const metricNames = metricState.ColumnDisplayName;
    const shortHand = metricState.itemSymbol;
    const metrics = ListStore.getState().metricData;

    if (!StringUtil.isEmpty(shortHand))
      return;

    const itemSymbol = data.data;
    let def = '', defHeader = '';
    let matchdata = [];

    if (!StringUtil.isEmpty(itemSymbol) && StringUtil.isEmpty(metricNames)) {
      const mdata = [...metrics.itemData];
      matchdata = mdata.find((itm) => itm.itemSymbol.toUpperCase() == itemSymbol.toUpperCase())
      if (matchdata) {
        def = LocalizationStore.getTranslatedData("DataItemExt_Definition_" + matchdata.MsItemID,matchdata.Definition);
        defHeader = LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + matchdata.MsItemID,matchdata.ColumnDisplayName)
      }
    }

    if (StringUtil.isEmpty(metricNames) && StringUtil.isEmpty(itemSymbol)) {
      def = LocalizationStore.getTranslatedData("cm_aohd",CALCUS_FORMULA.EnterDefinitonOptional);
    }
    else if (!matchdata) {
      def = LocalizationStore.getTranslatedData("cm_invalidshorthand",CALCUS_FORMULA.InvalidShortHand);
    }
    yield put({
      type: ActionTypes.HANDLE_DEFINITION_SELECTED_SUCCESS,
      Definition: def,
      metricName: defHeader
    });

  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
  }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchHandleBrowseMetrics() {
  yield takeLatest(ActionTypes.BROWSE_METRICS, handleBrowseMetrics);
}

export function* watchHandleAddMetrics() {
  yield takeLatest(ActionTypes.ADD_METRICS_CLICK, handleAddMetrics);
}

export function* watchHandleOpenDefinition() {
  yield takeLatest(ActionTypes.BROWSE_METRICS, handleOpenDefinition);
}

export function* watchHandleCustomMetricDuplicate() {
  yield takeLatest(ActionTypes.HANDLE_DUPLICATE_METRIC, handleCustomMetricDuplicate);
}

export function* watchHandleCustomMetricDelete() {
  yield takeLatest(ActionTypes.HANDLE_DELETE_METRIC_SUBMIT, handleCustomMetricDelete);
}

export function* watchHandleCustomFromatChange() {
  yield takeLatest(ActionTypes.HANDLE_RESULT_FORMAT_CHANGE, handleCustomFormatChange);
}

export function* watchHandleManageMetrics() {
  yield takeLatest(ActionTypes.OPEN_MANAGE_METRICS, handleManageMetrics);
}

export function* watchHandleRenameSave() {
  yield takeLatest(ActionTypes.HANDLE_RENAME_SAVE_CHANGE, handleRenamesave);
}

export function* watchHandleCreateMetrics() {
  yield takeLatest(ActionTypes.HANDLE_CREATE_CUSTOM_METRICS, createCustomMetric);
}

export function* watchHandleAddToColumn() {
  yield takeLatest(ActionTypes.HANDLE_ADD_TO_COLUMN, addColumnToList);
}

export function* watchHandleNameChange() {
  yield takeLatest(ActionTypes.HANDLE_NAME_CHANGE, handleNameChange);
}

export function* watchHandleNameSearch() {
  yield takeLatest(ActionTypes.HANDLE_NAME_SEARCH, handleNameSearch);
}

export function* watchHandleInterval() {
  yield takeLatest(ActionTypes.HANDLE_INTERVAL_CHANGE, handleIntervalChange);
}

export function* watchHandleLookBack() {
  yield takeLatest(ActionTypes.HANDLE_LOOKBACK_CHANGE, handleLookBackChange);
}

export function* watchHandleRename() {
  yield takeLatest(ActionTypes.HANDLE_RENAME_CLICK, handleRenameClick);
}

export function* watchHandleSaveEditFormat() {
  yield takeLatest(ActionTypes.SAVE_METRICS_EDITFORMAT, handleSaveEditFormat);
}

export function* watchHandleClearEditFormat() {
  yield takeLatest(ActionTypes.CLEAR_METRICS_EDITFORMAT, handleClearEditFormat);
}

export function* watchHandleFormulaChange() {
  yield takeLatest(ActionTypes.HANDLE_FORMULA_CHANGE, handleFormulaChange);
}

export function* watchHandleSymbolChange() {
  yield takeLatest(ActionTypes.HANDLE_SYMBOL_CHANGE, handleSymbolChange);
}

export function* watchHandleDefinitionSelected() {
  yield takeLatest(ActionTypes.HANDLE_DEFINITION_SELECTED, handleDefinitionSelect);
}


const ColumnDataType = {
  BOOL_ListColumnData: 'BOOL_ListColumnData',
  INT_ListColumnData: 'INT_ListColumnData',
  STRING_ListColumnData: 'STRING_ListColumnData',
  DATE_ListColumnData: 'DATE_ListColumnData',
  DOUBLE_ListColumnData: 'DOUBLE_ListColumnData'
}
const initData = {
  CustomMetricName: 'Untitled Custom Metric',
  Init_Format: '1,000',
  Init_Text: 'Text',
  Init_Definition: 'Custom metric descriptions',
}

const CALCUS_FORMULA = {
  IfstringRegex: new RegExp(/IF/ig),
  ElsestringRegex: new RegExp(/ELSE/ig),
  Ifstring: "IF",
  Elsestring: "ELSE",
  Andstring: new RegExp(/ AND /ig),
  Orstring: new RegExp(/ OR /ig),
  Notstring: new RegExp(/NOT/ig),
  SingleQuote: new RegExp(/'/ig),
  Returnstring: "return",
  Percentstring: "%",
  GreatOrEqual: ">=",
  LessOrEqual: "<=",
  GreatOrEqualRegex: new RegExp("()?\\>\\s*="),
  LessOrEqualRegex: new RegExp("()?\\<\\s*="),
  equalMatchesRegex: new RegExp("()?=="),
  notequalMatchesRegex: new RegExp("()?!="),
  msIdRegexpForm: new RegExp(/\[(.*?)\]/ig),
  todaystringRegex: new RegExp(/today/ig),
  denominatorRegex: new RegExp(/(([^\s]+)\/([^\s]+))/g),
  valIfAndElseRegex: new RegExp(/^if\s*\((.*?)\)\s*{(.*?)}\s*else\s*{(.*?)}/i),
  IsNullRegex: new RegExp("ISNULL\{([^}]*)\}"),
  NotAValidFormula: "Not a valid formula.",
  IfAndElseInValid: 'if else statement is invalid',
  ExpressionInValid: 'Expression is invalid.\n ',
  EnterDefinitonOptional: "Add or highlight a metric to view its definition",
  InvalidShortHand: 'Invalid metric shorthand',
  greaterRegex: new RegExp(/(.*)?>(.*)/),
  lesserRegex: new RegExp(/(.*)?<(.*)/),
  addRegex: new RegExp(/(.*)?\+(.*)/),
  subRegex: new RegExp(/(.*)?\-(.*)/),
  mulRegex: new RegExp(/(.*)?\*(.*)/),
  divRegex: new RegExp(/(.*)?\/(.*)/),
  notequalRegex: new RegExp(/(.*)?\!\=(.*)/),
  gtequalRegex: new RegExp(/(.*)?\>=(.*)/),
  ltequalRegex: new RegExp(/(.*)?\<=(.*)/),
}
const CustomCalc_UnitType = {
  NoScaling: 'NoScaling',
  Thousands: 'Thousands',
  Millions: 'Millions',
  Billions: 'Billions',
  Percent: 'Percent',
}