import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PrintMode } from '../../../../../print/printmode';
import ImageScaling from '../Presentational/ImageScaling';
import { showAddListAlertMessage, setListAddAlertMessage } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { Alert } from "react-bootstrap";
import ChartRefresh from '../../ToggleMenuPanel/Containers/ChartRefresh';
import ErrorBoundary from 'ErrorBoundary';
import { CompareMenuType } from "CompareTabType";

class Header extends Component {

    closeAlertPopup() {
        this.props.setListAddAlertMessageStatus(false);
        this.props.setListAddAlertMessage(null);
    }

    hideAlert(showAddListAlertMessage) {
        if (showAddListAlertMessage) {
            setTimeout(() => {
                this.props.setListAddAlertMessageStatus(false);
                this.props.setListAddAlertMessage(null);
            }, 5000);
        }
    }

    render() {
        const { zoomPercentageValue } = this.props;
        const { alertMessage, showAddListAlertMessage } = this.props;

        this.hideAlert(showAddListAlertMessage);
        return (
            <div className="header-rotation" style={{ height: PrintMode.printing ? '30px' : '36px ' }}>
                <div id="head-main-rotation">
                    <ErrorBoundary><ChartRefresh isRotationGraph={true} /></ErrorBoundary>
                    <div className="head-right-rotation">
                        <ErrorBoundary><ImageScaling zoomPercentageValue={zoomPercentageValue} /></ErrorBoundary>
                    </div>
                    <div className="alert-wrap">
                        {showAddListAlertMessage == true && alertMessage != null ?
                            <ErrorBoundary>
                                <Alert style={{ display: 'inline-block', position: 'relative', left: '150px', top: '5px' }} className="custom-alert" bsStyle="warning" onDismiss={() => this.closeAlertPopup()}>
                                    <span >{alertMessage}</span>
                                </Alert>
                            </ErrorBoundary>
                            :
                            ''}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    if (selectedMenuTab === CompareMenuType.Symbols) {
        const { zoomPercentageValue, isRefresh } = state.rotationGraph.HeaderPaneReducer.SymbolsData;
        const { alertMessage, showAddListAlertMessage } = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer.RotationMenuStates

        return { zoomPercentageValue, isRefresh, alertMessage, showAddListAlertMessage }
    }
    else {
        const { zoomPercentageValue, isRefresh } = state.rotationGraph.HeaderPaneReducer.GroupsData;
        const { alertMessage, showAddListAlertMessage } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.RotationMenuStates

        return { zoomPercentageValue, isRefresh, alertMessage, showAddListAlertMessage }
    }
}

const mapDispatchToProps = (dispatch) => ({
    setListAddAlertMessageStatus: (status) => dispatch(showAddListAlertMessage(status)),
    setListAddAlertMessage: (message) => dispatch(setListAddAlertMessage(message))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);