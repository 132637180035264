import PrefPeriodScaleConstants from "PrefPeriodScaleConstants";
export default class NewTargetInfo {
    constructor() {
        this.FixedRange = false;
        this.MinPrice = 0;
        this.MaxPrice = 100;
        this.IsIntegerScale = false;
        this.BoxHeight = 0;
        this.VpHeight = 0;
        this.Name;
        this.MMinPrice = 0;
        this.MMaxPrice = 0;
        this.MaxVolume = 0;
        this.MinVolume = 0;
        this.VolAveMax;
        this.Avolume;
        this.VolWidth = 0.0;
        this.VolHeight = 0.0;
        this.EpsMultiplier = 20;
        this.RpsMultiplier = 20;
        this.T4QMultipliers = [];
        this.NodesPerInch = 28; // 28 Nodes with timeGrid.StepSize
        this.DPI = 0;
        this.SetLineOne;

        this.ScaleType = PrefPeriodScaleConstants.Lin;

        this.TradeDate;
        this.StartDate;
        this.OpenTime;
        this.CloseTime;
        this.GraphType;
        this.SymbolType;

    }
}
