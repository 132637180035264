import { EventEmitter } from "events";

class ScrollBarStore extends EventEmitter {
  constructor() {
    super();
    this.scroll_ids = [];
  }
}
const scrollBarStore = new ScrollBarStore();
export default scrollBarStore;
