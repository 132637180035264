import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class ResetColumnSetRequest extends BaseServiceRequest {

    constructor(columnsetId, activeListid) {
        super();
        super.addRequestUri("columnsetreset.pb");
        super.addRequestParameter("cs", columnsetId.toString());
        super.addRequestParameter("l", activeListid.toString());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default ResetColumnSetRequest;
