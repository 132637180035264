import React, { Component } from 'react';
import ConsoleActions from "Actions/ConsoleActions";
import { PrintMode } from '../../print/printmode';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    if(this.isDevOrStageEnv()) {
    this.setState({ hasError: true });
    ConsoleActions.exceptionHandle(error + '   ' + info.componentStack);
  }
}

  isDevOrStageEnv() {
    const env = process.env.BRANCH_ENV;
    console.log('env', env);
    return (env && (env.toUpperCase() == "PROD")) ? false : true;
  } 

  render() {
    if (this.state.hasError && !PrintMode.printing) {
      // You can render any custom fallback UI
      return <div>Something went wrong.</div>;
    }
    else {
      if (this.state.hasError) {
        document.body.classList.add('error-pop-up');
      }
    }
    return this.props.children;
  }
}