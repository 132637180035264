import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContextMenu from '../../ComparisonGraph/Presentational/ContextMenu';
import LocalizationStore from 'LocalizationStore';
import { each } from "underscore";
import { onContextMenuItemClick, updateContextMenuVisibility } from 'Actions/RotationGraphActions';
import ErrorBoundary from 'ErrorBoundary';
import { CompareMenuType } from "CompareTabType";
import { RotationMenuType } from "Constants/RotationMenuType";

class RotationGraphContextMenu extends Component {

    onContextMenuItemClick(action, contextMenuObj) {
        if (action && !contextMenuObj.disable) {
            if (contextMenuObj.selectedGraphItem !== "svgElement") {
                this.updateContextMenuVisibility();

                let listItemType = RotationMenuType.Stocks;
                if (this.props.selectedMenuTab === CompareMenuType.Groups) {
                    listItemType = this.props.listItemType;
                }
                onContextMenuItemClick(action, contextMenuObj.item, listItemType);
            }
            else {
                this.props.backToCenter(contextMenuObj.evt, true);
            }
        }
        else {
            this.updateContextMenuVisibility();
        }
    }

    updateContextMenuVisibility = (e, isVisible = false) => {
        this.props.updateContextMenuVisibility(isVisible);
    }

    getMaxWidth(contextMenu, font, maxWidth = 0) {
        each(contextMenu, (item) => {
            const width = item.contextName.getTextWidth(font);
            if (maxWidth <= width)
                maxWidth = width;
        });
        return maxWidth;
    }

    createDivStyle() {
        let topCal = this.props.contextMenuObj.yPos;
        let leftCal = this.props.contextMenuObj.xPos + 5;
        const { contextMenuList } = this.props;
        const font = "14 'calibri'";
        let maxWidth = 0;
        const lrPadding = 45;
        this.rotationGraphArea = document.getElementById("thediv");
        const bBox = this.rotationGraphArea.getBoundingClientRect();
        if (contextMenuList !== null) {
            let ulHeight = (contextMenuList.length * 24) + 24;
            if ((bBox.bottom - bBox.top) <= (topCal + ulHeight))
                topCal = topCal - ulHeight;
            if (contextMenuList.length > 0) {
                maxWidth = this.getMaxWidth(contextMenuList, font);
                if (maxWidth > 0) {
                    maxWidth += lrPadding;
                    if ((bBox.right - bBox.left) <= (leftCal + maxWidth)) {
                        leftCal = leftCal - maxWidth;
                    }
                }
            }
        }
        return {
            position: "absolute",
            left: leftCal,
            top: topCal,
            zIndex: "999",
            textAlign: "left"
        };
    }

    render() {
        const { contextMenuList, contextMenuObj, isContextMenuVisible } = this.props;

        return (
            isContextMenuVisible &&
            <ErrorBoundary>
                <ContextMenu style={this.createDivStyle()} component={'rotationgraph'} outsideClickDocument={this.updateContextMenuVisibility}>
                    <div className="context-menu dropdown">
                        <ul className=" context-menu-inner list-contex  small-normal" aria-labelledby="contextMenu">
                            {contextMenuList.map((item, i) => {
                                if (item !== undefined) {
                                    const resKey = item.contextName === 'Reset to 100%' ? 'CRG_Reset100' : `CRG_${item.contextName.replace(/ /, '')}`;

                                    return (
                                        <li key={i} className={contextMenuObj.disable ? "disable" : ""} onClick={() => this.onContextMenuItemClick(item.action, contextMenuObj)}>
                                            <a>{LocalizationStore.getTranslatedData(resKey, item.contextName)}</a>
                                        </li>
                                    )
                                }
                            })
                            }
                        </ul>
                    </div>
                </ContextMenu>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    if (selectedMenuTab === CompareMenuType.Symbols) {
        const centerRotationState = state.rotationGraph.CenterRotationReducer.SymbolsData;

        return { ...centerRotationState, selectedMenuTab };
    }
    else {
        const centerRotationState = state.rotationGraph.CenterRotationReducer.GroupsData;
        const { listItemType } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.RotationMenuStates;

        return { ...centerRotationState, selectedMenuTab, listItemType };
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateContextMenuVisibility: (isVisible) => dispatch(updateContextMenuVisibility(isVisible))
});


export default connect(mapStateToProps, mapDispatchToProps)(RotationGraphContextMenu);