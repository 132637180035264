import AnnotationUtil from 'Utils/AnnotationUtil';
import ExtremeDataValue from '../../../../Utils/ExtremeDataValue';
import GraphType from "GraphType";
import { lookUpXAndYAxis } from './AnnotationInitModel';
import moment from "moment";
import { PrintMode } from '../../../../print/printmode';
import { safe } from '../../../ErrorModel';
import { ShareAccessType } from '../../../../Constants/ShareConstants';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { AnnotationConstant, TemporaryId } from "Constants/AnnotationConstants.js";
import { each, find, first, last, max, min } from "underscore";
import {
    getAnnotationIsDrawing, getAnnotationChartDraw, getAnnotationSelectedId, getMeasurementData,
    getAnnotationMenu, getTargetLayer, getActiveAnchor, getAnnotationDimension, getLayersList, getAnnotationSelected
} from '../../../../Reducers/AnnotationReducers/selectors';
import { takeLatest, select, put, call, takeEvery } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;
let previousStartDate = null, previousEndDate = null, previousStartIndex = null, previousEndIndex = null;

function* handleClick({ e }) {
    try {
        let measurementLine = null;
        const annotationMenu = yield select(getAnnotationMenu);
        let isDrawing = yield select(getAnnotationIsDrawing);
        let annotSelectedId = yield select(getAnnotationSelectedId);
        const targetLayer = yield select(getTargetLayer);
        const measurementData = yield select(getMeasurementData);

        if (isDrawing) {
            isDrawing = !isDrawing
            measurementLine = find(measurementData, (itm) => itm.id === annotSelectedId);
            yield call(updateMeasurementData, isDrawing, measurementLine);
            yield safe(call(saveMeasurementData), "GraphicsMeasurementSaga.js", "saveMeasurementData");
            previousStartDate = null, previousEndDate = null, previousStartIndex = null, previousEndIndex = null;
            return;
        }

        if (e.target !== e.target.getStage()) {
            annotSelectedId = e.target.id();
            measurementLine = find(measurementData, (itm) => itm.id === annotSelectedId);
            yield call(updateMeasurementData, isDrawing, measurementLine);
            return;
        }

        const annotId = TemporaryId.Id + measurementData.length;

        let x = e.evt.layerX;

        const snapStartData = yield safe(call(getSnapPosition, { x: e.evt.layerX, y: e.evt.layerY }, snapStartPosition), "GraphicsMeasurementSaga.js", "getSnapPosition");
        const snapStartPosition = snapStartData.position;
        x = snapStartPosition.x;
        previousStartDate = snapStartData.date ? snapStartData.date : previousStartDate;
        previousStartIndex = snapStartData.index !== null ? snapStartData.index : previousStartIndex;

        const duration = yield safe(call(setDuration, 0, AnnotationUtil.getPeriodicity()), 'GraphicsMeasurementSaga.js', 'setDuration');

        measurementLine = {
            ...annotationMenu.MeasurementModelInfo,
            id: annotId,
            layerID: targetLayer.layerID,
            x: x,
            y: e.evt.layerY,
            startMouseX: 0,
            startMouseY: 0,
            currentMouseX: 0,
            currentMouseY: 0,
            endMouseX: 0,
            endMouseY: 0,
            snapStartPosition: snapStartPosition,
            snapEndPosition: null,
            textInfo: {
                titleHeader: `0.0% (${duration})`,
                gain: `+0.0%`,
                decline: `0.0%`,
                clientX: e.evt.clientX,
                clientY: e.evt.clientY
            },
            isReadOnlyAnnotation: false
        }
        isDrawing = true;
        yield call(addMeasurementLine, isDrawing, measurementLine);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`%cError occurs in GraphicsMeasurementSaga.js, handleClick ${error}`, 'color:#508F4E;background-color:#ffff00');
    }
}

function* handleMouseMove({ e, reComputeOnSelection, isAnchorDrag }) {
    try {
        let measurementLine = null;
        const annotationDimension = yield select(getAnnotationDimension);
        const { isDrawing, isDragging } = yield select(getAnnotationChartDraw);
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const measurementData = yield select(getMeasurementData);
        const activeAnchor = yield select(getActiveAnchor);
        const chartDimension = yield select(getAnnotationDimension);

        if ((isDrawing || isDragging || reComputeOnSelection || isAnchorDrag) && measurementData.length > 0) {

            const curLine = find(measurementData, (itm) => itm.id === annotSelectedId);
            if (curLine.isReadOnlyAnnotation) {
                return;
            }
            let lineStart = {}, lineEnd = {}, currMouse = {}, snapStartPosition = curLine.snapStartPosition, snapEndPosition = curLine.snapEndPosition, snapStartData;

            if (isDragging) {
                const dragPos = {
                    x: e.currentTarget.x(), y: e.currentTarget.y()
                }

                // Fix for PANWEB-6190
                if (!(e.currentTarget.attrs.type === "start" || e.currentTarget.attrs.type === "end")) {
                    if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                        e.currentTarget.attrs.x = curLine.x;
                        e.currentTarget.attrs.y = curLine.y;
                        return;
                    }
                }

                if (e.currentTarget.attrs.type === "end") {
                    const diff = { x: dragPos.x - curLine.endMouseX, y: dragPos.y - curLine.endMouseY };
                    lineStart = {
                        x: curLine.x + diff.x, y: curLine.y + diff.y
                    }
                    lineEnd = {
                        x: dragPos.x, y: dragPos.y
                    }
                }
                else {
                    const diff = { x: dragPos.x - curLine.x, y: dragPos.y - curLine.y };
                    lineStart = {
                        x: dragPos.x, y: dragPos.y
                    }
                    lineEnd = {
                        x: curLine.endMouseX + diff.x, y: curLine.endMouseY + diff.y
                    }
                }
            }
            else if (reComputeOnSelection) {
                lineStart = {
                    x: curLine.x, y: curLine.y
                }
                lineEnd = {
                    x: curLine.endMouseX, y: curLine.endMouseY
                }
            }
            else if (isAnchorDrag) {
                // Fix for PANWEB-6207
                if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                    return;
                }

                if (e.currentTarget !== undefined && e.currentTarget !== null) {
                    e.currentTarget.getStage().container().style.cursor = 'all-scroll';
                }

                if (activeAnchor === "start") {
                    lineStart = {
                        x: e.evt.layerX,
                        y: e.evt.layerY
                    }
                    lineEnd = {
                        x: curLine.endMouseX,
                        y: e.evt.layerY
                    }
                }
                else if (activeAnchor === "end") {
                    lineStart = {
                        x: curLine.x,
                        y: e.evt.layerY
                    }
                    lineEnd = {
                        x: e.evt.layerX,
                        y: e.evt.layerY
                    }
                }
            }
            else {
                lineStart = {
                    x: curLine.x, y: e.evt.layerY
                }
                lineEnd = {
                    x: e.evt.layerX, y: e.evt.layerY
                }
            }

            // Restricted 30px space on top and bottom areas of price chart to fit the measurement text box inside the price chart area.
            if (!(e.currentTarget.attrs.type === "start" || e.currentTarget.attrs.type === "end")) {
                if (e.currentTarget.y() !== 0 && !isAnchorDrag) {
                    if (e.currentTarget.y() < 30 || e.currentTarget.y() + 30 > annotationDimension.height) {
                        if (e.target) {
                            e.target.attrs.y = curLine.y;
                        }
                        lineStart.y = curLine.y;
                        lineEnd.y = curLine.y;
                    }
                }
                else {
                    if (e.evt.layerY < 30 || e.evt.layerY + 30 > annotationDimension.height) {
                        if (e.target) {
                            e.target.attrs.y = curLine.y;
                        }
                        lineStart.y = curLine.y;
                        lineEnd.y = curLine.y;
                    }
                }
            }

            // Improve performance by - Calculate snapStart points only during further modifications 
            if (isDragging || reComputeOnSelection || isAnchorDrag) {
                snapStartData = yield safe(call(getSnapPosition, lineStart, snapStartPosition), "GraphicsMeasurementSaga.js", "getSnapPosition");
                previousStartDate = snapStartData.date ? snapStartData.date : previousStartDate;
                previousStartIndex = snapStartData.index !== null ? snapStartData.index : previousStartIndex;
                snapStartPosition = snapStartData.position;
                lineStart.x = snapStartPosition.x;
            }
            // Fix for PANWEB-6205
            else if (lineStart.x > AnnotationUtil.max_x_Axis || lineStart.x < AnnotationUtil.min_x_Axis) {
                snapStartPosition = { x: lineStart.x, y: lineStart.y - 25 };
            }

            const snapEndData = yield safe(call(getSnapPosition, lineEnd, snapEndPosition), "GraphicsMeasurementSaga.js", "getSnapPosition");
            previousEndDate = snapEndData.date ? snapEndData.date : previousEndDate;
            previousEndIndex = snapEndData.index !== null ? snapEndData.index : previousEndIndex;
            snapEndPosition = snapEndData.position;
            lineEnd.x = snapEndPosition.x;

            currMouse = {
                x: lineEnd.x - lineStart.x,
                y: lineEnd.y - lineStart.y
            }

            let startDate = null, endDate = null, startIndex = null, endIndex = null;
            if (snapStartData) {
                startDate = snapStartData.date;
                startIndex = snapStartData.index;
            }
            if (snapEndData) {
                endDate = snapEndData.date;
                endIndex = snapEndData.index;
            }

            const measurementDataInfo = yield safe(call(calculateMeasurementData, startDate, endDate, startIndex, endIndex, lineStart, lineEnd), 'GraphicsMeasurementSaga.js', 'calculateMeasurementData');
            const textInfo = yield safe(call(setData, measurementDataInfo), 'GraphicsMeasurementSaga.js', 'getTextData');
            textInfo.clientX = e.evt.clientX;
            textInfo.clientY = e.evt.clientY;

            measurementLine = Object.assign(curLine, {
                x: lineStart.x,
                y: lineStart.y,
                currentMouseX: currMouse.x,
                currentMouseY: currMouse.y,
                endMouseX: lineEnd.x,
                endMouseY: lineEnd.y,
                snapStartPosition: snapStartPosition,
                snapEndPosition: snapEndPosition,
                textInfo: textInfo,
                measurementDataInfo: measurementDataInfo
            })
            yield call(updateMeasurementData, isDrawing, measurementLine);
        }
        else {
            yield call(updateMeasurementData, isDrawing, measurementData);
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`%cError occurs in GraphicsMeasurementSaga.js, handleMouseMove ${error}`, 'color:#508F4E;background-color:#ffff00');
    }
}

function getSnapPosition(position, prevSnapPosition, isFromApi = false) {
    const mouseY = position.y;
    const chartNodes = AnnotationUtil.HiLowPoints.allPoints;

    // When the cursor moves beyond price nodes either towards left or right
    if (position.x > AnnotationUtil.max_x_Axis) {
        return { position: { x: position.x, y: position.y - 25 }, date: chartNodes[0].Date, index: 0 };
    }
    if (position.x < AnnotationUtil.min_x_Axis) {
        return { position: { x: position.x, y: position.y - 25 }, date: chartNodes[chartNodes.length - 1].Date, index: chartNodes.length - 1 };
    }

    let node, index = null;

    if (isFromApi) {
        find(chartNodes, (item, key) => {
            if (item.xAxis === position.x) {
                node = item;
                index = key;
            }
        });
    }

    if (!node) {
        const TimeLineList = AnnotationUtil.getTimeLine();
        const posFromRight = AnnotationUtil.getInitialNode() - position.x;
        const curIndex = Math.round(posFromRight / AnnotationUtil.nodeWidth);
        let timeLineNode = null;
        if (TimeLineList[curIndex]) {
            timeLineNode = TimeLineList[curIndex];
        }

        find(chartNodes, (item, key) => {
            if (item && moment(item.Date).isSame(timeLineNode.Date)) {
                node = item;
                index = key;
            }
        });
    }

    if (node) {
        const high = node.yHigh;
        const low = node.yLow;

        if (mouseY <= high) {
            position = { x: position.x, y: high };
        }
        else if (mouseY >= low) {
            position = { x: position.x, y: low };
        }
    }
    else if (prevSnapPosition) {
        position = { x: position.x, y: prevSnapPosition.y };
    }
    else {
        let newPosition = position.x + 1;
        while (node === undefined && newPosition < AnnotationUtil.max_x_Axis) {
            newPosition -= 1;
            find(chartNodes, (item, key) => {
                if (item.xAxis === newPosition) {
                    node = item;
                    index = key;
                }
            });
        }
        if (node) {
            const high = node.yHigh;
            const low = node.yLow;

            if (mouseY <= high) {
                position = { x: position.x, y: high };
            }
            else if (mouseY >= low) {
                position = { x: position.x, y: low };
            }
        }
    }
    return { position: position, date: node ? node.Date : null, index: index };
}

function* calculateMeasurementData(startDate, endDate, startIndex, endIndex, lineStart, lineEnd) {
    let runStartX, runEndX, pullBackStartX, pullBackEndX, difference, measurementData;
    const chartNodes = AnnotationUtil.HiLowPoints.allPoints;
    const customNodes = [];
    if (!startDate || !startIndex) {
        if (previousStartDate && previousStartIndex !== null) {
            startDate = previousStartDate;
            startIndex = previousStartIndex;
        }
        else {
            let node, newPosition = lineStart.x + 1;
            while (node === undefined) {
                newPosition -= 1;

                // When the cursor moves beyond price nodes either towards left or right
                if (newPosition < chartNodes[chartNodes.length - 1].xAxis) {
                    node = chartNodes[chartNodes.length - 1];
                    startDate = chartNodes[chartNodes.length - 1].Date;
                    startIndex = chartNodes.length - 1;
                    break;
                }
                else if (newPosition > chartNodes[0].xAxis) {
                    node = chartNodes[0];
                    startDate = chartNodes[0].Date;
                    startIndex = 0;
                    break;
                }

                each(chartNodes, (item, key) => {
                    if (item.xAxis === newPosition) {
                        node = item;
                        startDate = item.Date;
                        startIndex = key;
                    }
                });
            }
        }
    }
    if (!endDate || !endIndex) {
        if (previousEndDate && previousEndIndex !== null) {
            endDate = previousEndDate;
            endIndex = previousEndIndex;
        }
        else {
            let node, newPosition = lineEnd.x + 1;
            while (node === undefined) {
                newPosition -= 1;

                // When the cursor moves beyond price nodes either towards left or right
                if (newPosition < chartNodes[chartNodes.length - 1].xAxis) {
                    node = chartNodes[chartNodes.length - 1];
                    endDate = chartNodes[chartNodes.length - 1].Date;
                    endIndex = chartNodes.length - 1;
                    break;
                }
                else if (newPosition > chartNodes[0].xAxis) {
                    node = chartNodes[0];
                    endDate = chartNodes[0].Date;
                    endIndex = 0;
                    break;
                }

                each(chartNodes, (item, key) => {
                    if (item.xAxis === newPosition) {
                        node = item;
                        endDate = item.Date;
                        endIndex = key;
                    }
                });
            }
        }
    }

    const chartNodesRange = yield safe(call(getChartNodeRange, startDate, endDate, startIndex, endIndex, chartNodes), 'GraphicsMeasurementSaga.js', 'getChartNodeRange');

    if (chartNodesRange && chartNodesRange.length > 0) {
        const node = chartNodesRange[0];
        if (node !== null) {
            const decline = (node.graphData.High - node.graphData.High) / node.graphData.High;
            const incline = (node.graphData.Low - node.graphData.Low) / node.graphData.Low;
            const customNode = { Date: node.Date, High: node.graphData.High, Low: node.graphData.Low, Price: node.graphData.Close, PriorMax: node.graphData.High, PriorMin: node.graphData.Low, PullBack: decline, Run: incline };
            customNodes.push(customNode);
        }
    }

    if (chartNodesRange.length > 1) {
        for (let index = 1; index < chartNodesRange.length; index++) {
            const nextNode = chartNodesRange[index];

            if (nextNode === null || !nextNode.graphData.IsVisible) {
                continue;
            }
            const range = first(chartNodesRange, index);
            const maxVal = max(range, (item) => item !== null ? item.graphData.High : 0).graphData.High;
            const minVal = min(range, (item) => item !== null ? item.graphData.Low : 0).graphData.Low;

            const pullBack = (nextNode.graphData.Low - maxVal) / maxVal;
            const run = (nextNode.graphData.High - minVal) / minVal;

            const customNode = { Date: nextNode.Date, High: nextNode.graphData.High, Low: nextNode.graphData.Low, Price: nextNode.graphData.Close, PriorMax: maxVal, PriorMin: minVal, PullBack: pullBack, Run: run };
            customNodes.push(customNode);
        }
    }

    if (customNodes && customNodes.length > 0) {
        const firstPrice = ExtremeDataValue.round(first(customNodes).Price, 2);
        const lastPrice = ExtremeDataValue.round(last(customNodes).Price, 2);
        difference = ((lastPrice - firstPrice) / firstPrice) * 100;

        const largestRun = max(customNodes, (item) => item.Run).Run;
        const largestPullBack = min(customNodes, (item) => item.PullBack).PullBack;

        let largestRunEndCustomNode = null, largestPullBackEndCustomNode = null;
        each(customNodes, (item) => {
            if (largestRunEndCustomNode === null && Math.abs(item.Run - largestRun) < Number.EPSILON) {
                largestRunEndCustomNode = item;
            }
            if (largestPullBackEndCustomNode === null && Math.abs(item.PullBack - largestPullBack) < Number.EPSILON) {
                largestPullBackEndCustomNode = item;
            }
        });

        const runEndDate = largestRunEndCustomNode.Date;
        const priorMin = largestRunEndCustomNode.PriorMin;
        const pullBackEndDate = largestPullBackEndCustomNode.Date;
        const priorMax = largestPullBackEndCustomNode.PriorMax;

        const largestRunStartCustomNode = find(customNodes, (item) => Math.abs(item.Low - priorMin) < Number.EPSILON);
        const runStartDate = largestRunStartCustomNode.Date;

        const largestPullBackStartCustomNode = find(customNodes, (item) => Math.abs(item.High - priorMax) < Number.EPSILON);
        const pullBackStartDate = largestPullBackStartCustomNode.Date;

        const annotationDimension = yield select(getAnnotationDimension);
        let co_ordinates = yield call(lookUpXAndYAxis, { dateTime: runStartDate, price: 0 }, annotationDimension);
        runStartX = co_ordinates.x;
        co_ordinates = yield call(lookUpXAndYAxis, { dateTime: runEndDate, price: 0 }, annotationDimension);
        runEndX = co_ordinates.x;
        co_ordinates = yield call(lookUpXAndYAxis, { dateTime: pullBackStartDate, price: 0 }, annotationDimension);
        pullBackStartX = co_ordinates.x;
        co_ordinates = yield call(lookUpXAndYAxis, { dateTime: pullBackEndDate, price: 0 }, annotationDimension);
        pullBackEndX = co_ordinates.x;

        measurementData =
        {
            Count: chartNodesRange.length,
            LargestRunValue: largestRun * 100,
            LargestPullbackValue: largestPullBack * 100,
            LargestRunStartX: runStartX,
            LargestRunEndX: runEndX,
            LargestPullbackStartX: pullBackStartX,
            LargestPullbackEndX: pullBackEndX,
            RunValue: difference,
            chartDimension: annotationDimension
        };
    }

    return measurementData;
}

function* setData(measurementDataInfo) {
    const { Count, RunValue, LargestRunValue, LargestPullbackValue, } = measurementDataInfo;

    const duration = yield safe(call(setDuration, Count, AnnotationUtil.getPeriodicity()), 'GraphicsMeasurementSaga.js', 'setDuration');

    return {
        titleHeader: isNaN(RunValue) ? `0.0% (0 ${duration})` : `${(RunValue).toFixed(1)}% (${duration})`,
        gain: isNaN(LargestRunValue) ? `+0.0%` : `+${(LargestRunValue).toFixed(1)}%`,
        decline: isNaN(LargestPullbackValue) ? `0.0%` : `${(LargestPullbackValue).toFixed(1)}%`
    };
}

function setDuration(count, periodicity) {
    let result;
    count = count > 0 ? count - 1 : 0; //becuase between yesterday and today the number of days count should be 1 not 2
    switch (periodicity) {
        case GraphType.Daily:
            result = `${count} days`;
            break;
        case GraphType.Weekly:
            result = `${count} wks`;
            break;
        case GraphType.Monthly:
            result = `${count} mths`;
            break;
        case GraphType.Quarterly:
            result = `${count} qtrs`;
            break;
        case GraphType.Annual:
            result = `${count} yrs`;
            break;
        default:
            result = `${count} bars`;
            break;
    }

    if (count === 1) {
        result = result.replace("s", '');
    }
    return result;
}

function getChartNodeRange(startDate, endDate, startIndex, endIndex, chartNodes) {
    if (endIndex > startIndex) {
        [startIndex, endIndex] = [endIndex, startIndex];
    }

    const nodes = chartNodes.slice(endIndex, startIndex + 1).reverse();
    return nodes;
}

function* updateMeasurementData(isDrawing, measurementLine) {
    try {
        yield put({
            type: ActionTypes.HANDLE_MEASUREMENT_DRAW_SUCCESS,
            measurementLine: measurementLine
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: measurementLine
        })

        let layersList = yield select(getLayersList);
        layersList = JSON.parse(JSON.stringify(layersList));
        const selectedAnnot = yield select(getAnnotationSelected);
        for (const x in layersList) {
            layersList[x].layerID === selectedAnnot.layerID ? layersList.push(layersList.splice(x, 1)[0]) : 0;
        }
        yield put({
            type: ActionTypes.UPDATE_LAYERS_LIST,
            LayersList: layersList
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`%cError occurs in GraphicsMeasurementSaga.js, updateMeasurementData ${error}`, 'color:#508F4E;background-color:#ffff00');
    }
}

function* addMeasurementLine(isDrawing, measurementLine) {
    try {
        yield put({
            type: ActionTypes.HANDLE_MEASUREMENT_CLICK_SUCCESS,
            measurementLine: measurementLine
        });
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: measurementLine  //annotSelected - currently selected line
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`%cError occurs in GraphicsMeasurementSaga.js, addMeasurementLine ${error}`, 'color:#508F4E;background-color:#ffff00');
    }
}

function* saveMeasurementData() {
    yield put({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    });
}

function* processMeasurementData({ data }) {
    try {
        const lineStart = {
            x: data.startPoint.x + 1,
            y: data.startPoint.y
        }
        const lineEnd = {
            x: data.endPoint.x + 1,
            y: data.startPoint.y
        }

        let snapStartPosition = {}, snapEndPosition = {};

        const snapStartData = yield safe(call(getSnapPosition, lineStart, snapStartPosition, true), "GraphicsMeasurementSaga.js", "getSnapPosition");
        snapStartPosition = snapStartData.position;
        lineStart.x = snapStartPosition.x;

        const snapEndData = yield safe(call(getSnapPosition, lineEnd, snapEndPosition, true), "GraphicsMeasurementSaga.js", "getSnapPosition");
        snapEndPosition = snapEndData.position;
        lineEnd.x = snapEndPosition.x;

        const currMouse = {
            x: lineEnd.x - lineStart.x,
            y: lineEnd.y - lineStart.y
        }

        let startDate = null, endDate = null, startIndex = null, endIndex = null;
        if (snapStartData) {
            startDate = snapStartData.date;
            startIndex = snapStartData.index;
        }
        if (snapEndData) {
            endDate = snapEndData.date;
            endIndex = snapEndData.index;
        }

        const measurementDataInfo = yield safe(call(calculateMeasurementData, startDate, endDate, startIndex, endIndex, lineStart, lineEnd), 'GraphicsMeasurementSaga.js', 'calculateMeasurementData');
        const textInfo = yield safe(call(setData, measurementDataInfo), 'GraphicsMeasurementSaga.js', 'getTextData');

        const convertedForegroundColor = ThemeHelper.convertArgbToHex(data.foregroundColor);
        const layersList = yield select(getLayersList);
        const isReadOnlyAnnotation = layersList.filter((i) => (i.layerID === data.layerID))[0].shareAccess === ShareAccessType.SHARE_READONLY;

        const measurementData = {
            id: data.annotationID,
            layerID: data.layerID,
            type: data.annotationType,
            x: lineStart.x,
            y: lineStart.y,
            startMouseX: 0,
            startMouseY: 0,
            currentMouseX: currMouse.x,
            currentMouseY: currMouse.y,
            endMouseX: lineEnd.x,
            endMouseY: lineEnd.y,
            isLeaderlinesChecked: data.isBorderVisible,
            snapStartPosition: snapStartPosition,
            snapEndPosition: snapEndPosition,
            textInfo: textInfo,
            measurementDataInfo: measurementDataInfo,
            color: `P${convertedForegroundColor.color.substring(1)}`,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            isSelected: false,
            isDeleted: false,
            isReadOnlyAnnotation: isReadOnlyAnnotation
        }

        yield put({
            type: ActionTypes.HANDLE_MEASUREMENT_CLICK_SUCCESS,
            measurementLine: measurementData
        });
        yield put({
            type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
            annotation: measurementData.id
        });

        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`%cError occurs in GraphicsMeasurementSaga.js, processMeasurementData ${error}`, 'color:#508F4E;background-color:#ffff00');
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        PrintMode.printing && console.log("PRINT:--Tracker--*** Data processing Error in GraphicsMeasurementSaga *** for -", TimeTrackingWindow.currentSymbol);
    }
}

export function* watchHandleMeasurementClick() {
    yield takeLatest(ActionTypes.HANDLE_MEASUREMENT_CLICK, handleClick);
};

export function* watchHandleMeasurementDraw() {
    yield takeLatest(ActionTypes.HANDLE_MEASUREMENT_DRAW, handleMouseMove);
};

export function* watchProcessMeasurementData() {
    yield takeEvery(ActionTypes.PROCESS_MEASUREMENT_DATA, processMeasurementData);
};