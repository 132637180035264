import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetAllRecipientsShareDataRequest extends BaseServiceRequest {
    constructor() {
        super();
        super.addRequestUri("recipients.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.SHARE;
    }

    getMessageName() {
        return "ShareRecipientInfoSummaryResponse";
    }
}

export default GetAllRecipientsShareDataRequest;
