class ScalesHelper {
    constructor() {
    }

    static getInterval(visibleMinimum, visibleMaximum, pixels, minTicks) {
        let num = Math.min(minTicks, parseInt(pixels / 7.0));
        if (num <= 0) return 0.0;
        let num2 = this.niceNum(visibleMaximum - visibleMinimum, false);
        return this.niceNum(num2 / (num - 1), true);
    }

    static niceNum(x, round) {
        let num = parseInt(Math.floor(this.log10(x)));
        let num2 = x / Math.pow(10.0, num);
        if (round) {

            let num3 = (num2 < 1.5) ? 1.0 : ((num2 < 3.0) ? 2.0 : ((num2 < 7.0) ? 5.0 : 10.0));
            return (num3 * Math.pow(10.0, num));
        }
        let num4 = (num2 <= 1.0) ? 1.0 : ((num2 <= 2.0) ? 2.0 : ((num2 <= 5.0) ? 5.0 : 10.0));
        return (num4 * Math.pow(10.0, num));
    }

    static log10(x) {
        return Math.log(x) / Math.log(10);
    }
}

export default ScalesHelper;