import AlphaScopeControl from './AlphaScopeControl.jsx';
import FundDescriptionControl from '../FundDescriptionControl.jsx';
import FundDetailsControl from './FundDetailsControl.jsx';
import IdeaListControl from '../IdeaListControl.jsx';
import { Panel } from "react-bootstrap";
import React from 'react';

function FundsPanelBody() {
    return (
        <>
            <Panel>
                <Panel.Body>
                    <div className="summary-block-chronicle for-mutual-fund">
                        <div className="summary-block-panel-left">
                            <FundDetailsControl />
                        </div>
                        <div className="summary-block-panel-right">
                            <div className="noborderbottom inside-left">
                                <FundDescriptionControl id="fundsView" height={322} width={220} showDescriptionHeading={true} />
                            </div>
                            <div className="inside-right">
                                <AlphaScopeControl />
                                <IdeaListControl />
                            </div>
                        </div>
                    </div>
                </Panel.Body>
            </Panel>
        </>
    )
}
export default FundsPanelBody