import React from "react";

export default class BottomBar extends React.Component {
    constructor(props) {
      super(props);
      this.onClick = this.onClick.bind(this);
    }

    onClick() {
      e.stopPropagation();
      e.preventDefault();
    }

    render() {
      return(
        <span>
          <div className="view-grid-system" data-test-id="main-div">
            <a className="standard-list-view active" onClick={this.onClick}></a>
            <a className="multi-chart-view no-gap"  onClick={this.onClick}></a>
            <a className="multi-chart-view-size"  onClick={this.onClick}></a>
            <a className="list-analytice-view" onClick={this.onClick}></a>
            <a className="quote-board-view" onClick={this.onClick}></a>
          </div>
      </span>)
    }
}
