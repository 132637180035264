class StorageUtil {

  constructor() {
    this.type = 'localStorage';
  }

  set(key, value) {
    switch (this.type) {
      case 'localStorage':
        localStorage.setItem(key, JSON.stringify(value));
        break;

      case 'sessionStorage':
        sessionStorage.setItem(key, JSON.stringify(value));
        break;

      default:
        break;
    }
  }

  get(key) {
    switch (this.type) {
      case 'localStorage':
        return JSON.parse(localStorage.getItem(key));

      case 'sessionStorage':
        return JSON.parse(sessionStorage.getItem(key));

      default:
        return null;
    }
  }
}

export default StorageUtil;
