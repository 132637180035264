import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GlobalOutlookTrendCountDataRequest extends BaseServiceRequest {

    constructor(fromdate, enddate) {
        super();
        super.addRequestUri("globalcounts2.pb");
        super.addRequestParameter("Fromdate", fromdate);
        super.addRequestParameter("Enddate", enddate);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GROUPS;
    }

    getMessageName() {
        return "GlobalCountDataList";
    }
}

export default GlobalOutlookTrendCountDataRequest;
