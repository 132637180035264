import StringUtil from 'StringUtil';
class BaseIISServiceRequest {

  constructor() {
    this._requestCommandParams = [];
    this._postParamValuesDict = [];
    this._requestURI = "";
    this._requestContent;
    this.params = [];
    this.headers = [];
  }
  getMethod() {
    return undefined;
  }

  getSubSystem() {
    return undefined;
  }

  getRequestUri() {
    return this._requestURI;
  }

  addParam(key, value) {
    this.params.push({ [key]: value });
  }

  getUserSession() {
    if (!this.userSession) {
      this.userSession = sessionStorage.getItem("_pUser");
    }

    return this.userSession;
  }
  async addVerifyHeaders(requestId) {
    const res = this.getUserSession();
    const userId = res.split('+');
    this.headers.push({ UserID: userId[0] });
    this.headers.push({ UserSession: res });
    this.headers.push({ RequestID: requestId });
  }


  getLoginSourceUrl() {
    return this.getEnvironmentProtocol() + `://${config.DEV_SERVER_TOMCAT}`;
  }

  //getUserSessionInfo() {
  //    return new Promise((resolve, reject) => {
  //        if (!this.userSession) {
  //            AsyncStorage.getItem(this.getLoginSourceUrl(), (err, res) => {
  //                this.userSession = res;
  //                resolve(this.userSession);
  //            });
  //        }
  //        else {
  //            resolve(this.userSession);
  //        }
  //    });
  //}

  getRequireSecured() {
    return False;
  }

  addRequestParameter(paramName, value) {
    if (paramName) {
      this._postParamValuesDict.push({ key: paramName, value: value });
    }
  }
  addPathParameter(paramName, value) {
    this._requestCommandParams.push({ key: paramName, value: value });
  }
  addRequestUri(Uri) {
    this._requestURI = Uri;
  }


  getUri() {
    let retValue = this._requestURI;
    const reqParams = this._requestCommandParams.join("/");
    if (reqParams.length > 0) {
      retValue += "?";
      for (let i = 0; i < this._requestCommandParams.length; i++) {
        const key = this._requestCommandParams[i].key;
        const value = this._requestCommandParams[i].value;
        if (i > 0) {
          retValue += "&";
        }
        retValue += key + "=" + value;
      }
    }
    else {
      retValue = this._requestURI;
    }

    return retValue;
  }

  getParameterString() {
    const parameters = [];
    for (let i = 0; i < this._postParamValuesDict.length; i++) {
      parameters.push(StringUtil.format("{0}={1}", [this._postParamValuesDict[i].key, this._postParamValuesDict[i].value]));
    }
    return parameters.join("&");
  }



}

export default BaseIISServiceRequest;
