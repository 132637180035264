import { AnnotationConstant } from "Constants/AnnotationConstants";
import { Checkbox } from "react-bootstrap";
import { connect } from 'react-redux';
import { getSelectedAnnotationInfo } from '../../../../../../Reducers/AnnotationReducers/reselectorsdata';
import { RayAnnotationType } from '../../../../../../Constants/EAnnotationType';
import SettingsStore from "SettingsStore";
import { onStartArrowChange, onStartExtendChange, onEndArrowChange, onEndExtendChange, onCheckBoxChange, updateTargetAnnotation } from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent } from 'react';
const { ActionTypes } = AnnotationConstant;

class AnnotationsCheckboxSelectors extends PureComponent {

  onStartArrowChange = () => {
    let { startArrowChecked, lineX1, startPolygonPoints } = this.props.modelInfo;

    if (startArrowChecked) {
      startArrowChecked = !startArrowChecked;
      startPolygonPoints = null;
    }
    else {
      startArrowChecked = !startArrowChecked;
      if (lineX1 === 50) {
        startPolygonPoints = '50,35 50,25 42,30';
      }
      else {
        startPolygonPoints = '12,35 12,25 0,30';
      }
    }
    this.props.onStartArrowChange(startArrowChecked, lineX1, startPolygonPoints);

    if (this.props.annotSelected && this.props.annotSelected.type === this.props.annotationType) {
      this.props.annotSelected.startArrowChecked = startArrowChecked;
      this.props.annotSelected.startPolygonPoints = startPolygonPoints;

      this.props.updateTargetAnnotation(null, null, ActionTypes.HANDLE_LINE_DRAW);
    }
  }

  onEndArrowChange = () => {
    let { endArrowChecked, lineX2, endPolygonPoints } = this.props.modelInfo;

    if (endArrowChecked) {
      endArrowChecked = !endArrowChecked;
      endPolygonPoints = null;
    }
    else {
      endArrowChecked = !endArrowChecked;
      if (lineX2 === 220) {
        endPolygonPoints = '220,35 220,25 228,30';
      }
      else {
        endPolygonPoints = '244,35 244,25 256,30';
      }
    }
    this.props.onEndArrowChange(endArrowChecked, lineX2, endPolygonPoints);

    if (this.props.annotSelected && this.props.annotSelected.type === this.props.annotationType) {
      this.props.annotSelected.endArrowChecked = endArrowChecked;
      this.props.annotSelected.endPolygonPoints = endPolygonPoints;

      this.props.updateTargetAnnotation(null, null, ActionTypes.HANDLE_LINE_DRAW);
    }
  }

  onStartExtendChange = () => {
    let { startArrowChecked, startExtendChecked, lineX1, startPolygonPoints, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1 } = this.props.modelInfo;
    let action = null;

    if (this.props.annotationType === RayAnnotationType.FIB_AnnotationType) {
      if (startExtendChecked) {
        startExtendChecked = !startExtendChecked;
        upperLineX1 = 40;
        lowerLineX1 = 40;
        upperRetracementLineX1 = 40;
        lowerRetracementLineX1 = 40;
      }
      else {
        startExtendChecked = !startExtendChecked;
        upperLineX1 = 0;
        lowerLineX1 = 0;
        upperRetracementLineX1 = 0;
        lowerRetracementLineX1 = 0;
      }
      action = ActionTypes.HANDLE_FIBONACCI_LINE_DRAW;
    } else {
      if (startExtendChecked) {
        startExtendChecked = !startExtendChecked;
        lineX1 = 50;
        if (startArrowChecked) {
          startPolygonPoints = '50,35 50,25 42,30';
        }
      }
      else {
        startExtendChecked = !startExtendChecked;
        lineX1 = 0;
        if (startArrowChecked) {
          startPolygonPoints = '12,35 12,25 0,30';
        }
      }
      action = this.props.annotationType === RayAnnotationType.LINE_AnnotationType ? ActionTypes.HANDLE_LINE_DRAW : ActionTypes.HANDLE_CHANNEL_LINE_DRAW;
    }
    this.props.onStartExtendChange(startExtendChecked, lineX1, startPolygonPoints, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1);

    if (this.props.annotSelected && this.props.annotSelected.type === this.props.annotationType) {
      this.props.annotSelected.startExtendChecked = startExtendChecked;

      if (this.props.annotSelected.type === RayAnnotationType.FIB_AnnotationType) {
        this.props.annotSelected.upperLineX1 = upperLineX1;
        this.props.annotSelected.lowerLineX1 = lowerLineX1;
        this.props.annotSelected.upperRetracementLineX1 = upperRetracementLineX1;
        this.props.annotSelected.lowerRetracementLineX1 = lowerRetracementLineX1;
      }
      else {
        this.props.annotSelected.lineX1 = lineX1;
        this.props.annotSelected.startPolygonPoints = startPolygonPoints;
      }

      this.props.updateTargetAnnotation(null, null, action);
    }
  }

  onEndExtendChange = () => {
    let { endArrowChecked, lineX2, endPolygonPoints, endExtendChecked, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2 } = this.props.modelInfo;
    let action = null;

    if (this.props.annotationType === RayAnnotationType.FIB_AnnotationType) {
      if (endExtendChecked) {
        endExtendChecked = !endExtendChecked;
        upperLineX2 = 220;
        lowerLineX2 = 220;
        upperRetracementLineX2 = 220;
        lowerRetracementLineX2 = 220;
      }
      else {
        endExtendChecked = !endExtendChecked;
        upperLineX2 = 266;
        lowerLineX2 = 266;
        upperRetracementLineX2 = 266;
        lowerRetracementLineX2 = 266;
      }
      action = ActionTypes.HANDLE_FIBONACCI_LINE_DRAW;
    } else {
      if (endExtendChecked) {
        endExtendChecked = !endExtendChecked;
        lineX2 = 220;
        if (endArrowChecked) {
          endPolygonPoints = '220,35 220,25 228,30';
        }
      }
      else {
        endExtendChecked = !endExtendChecked;
        lineX2 = 266;
        if (endArrowChecked) {
          endPolygonPoints = '244,35 244,25 256,30';
        }
      }
      action = this.props.annotationType === RayAnnotationType.LINE_AnnotationType ? ActionTypes.HANDLE_LINE_DRAW : ActionTypes.HANDLE_CHANNEL_LINE_DRAW;
    }
    this.props.onEndExtendChange(endExtendChecked, lineX2, endPolygonPoints, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2);

    if (this.props.annotSelected && this.props.annotSelected.type === this.props.annotationType) {
      this.props.annotSelected.endExtendChecked = endExtendChecked;

      if (this.props.annotSelected.type === RayAnnotationType.FIB_AnnotationType) {
        this.props.annotSelected.upperLineX2 = upperLineX2;
        this.props.annotSelected.lowerLineX2 = lowerLineX2;
        this.props.annotSelected.upperRetracementLineX2 = upperRetracementLineX2;
        this.props.annotSelected.lowerRetracementLineX2 = lowerRetracementLineX2;
      }
      else {
        this.props.annotSelected.lineX2 = lineX2;
        this.props.annotSelected.endPolygonPoints = endPolygonPoints;
      }

      this.props.updateTargetAnnotation(null, null, action);
    }
  }

  onCheckBoxChange = (event, property, action, shouldSave = true) => {
    const newState = !this.props.modelInfo[property];
    const selectedAnnotation = this.props.annotSelected;
    this.props.onCheckBoxChange(event, property, newState, selectedAnnotation);

    if (this.props.annotSelected && (this.props.annotSelected.type === this.props.annotationType || this.props.annotSelected.type === this.props.selectedShapeType)) {
      this.props.annotSelected[property] = newState;
      this.props.updateTargetAnnotation(null, null, action, shouldSave);
    }
  }

  getCheckBoxProperties(type) {
    const { startArrowChecked, startExtendChecked, endArrowChecked, endExtendChecked, isAlertChecked, isSnappingChecked, isTemporaryChecked, isBorderChecked, isLeaderlinesChecked, isExtensionsChecked, isLevelLabelsChecked, isPriceLabelsChecked } = this.props.modelInfo;
    switch (type) {
      case "ArrowStart": return {
        id: "checkBox1", name: "Arrow", defaultChecked: startArrowChecked, onClick: this.onStartArrowChange
      }

      case "ExtendStart": return {
        id: "checkBox2", name: "Extend", defaultChecked: startExtendChecked, onClick: this.onStartExtendChange
      }

      case "ArrowEnd": return {
        id: "checkBox3", name: "Arrow", defaultChecked: endArrowChecked, onClick: this.onEndArrowChange
      }

      case "ExtendEnd": return {
        id: "checkBox4", name: "Extend", defaultChecked: endExtendChecked, onClick: this.onEndExtendChange
      }

      case "Alert": return {
        id: "checkBox5", name: "Alert", defaultChecked: isAlertChecked, onClick: () => this.onCheckBoxChange('alert', 'isAlertChecked', isAlertChecked ? ActionTypes.REMOVE_TRENDLINE_ALERT : ActionTypes.ADD_TRENDLINE_ALERT, false)
      }

      case "Snap": return {
        id: "checkBox6", name: "Snap", defaultChecked: isSnappingChecked, onClick: () => this.onCheckBoxChange('snap', 'isSnappingChecked')
      }

      case "Temporary": return {
        id: "checkBox7", name: "Temporary", defaultChecked: isTemporaryChecked, onClick: () => this.onCheckBoxChange('temporary', 'isTemporaryChecked')
      }

      case "Border": return {
        id: "checkBox", name: "BORDER", defaultChecked: isBorderChecked, onClick: () => this.onCheckBoxChange('border', 'isBorderChecked', isBorderChecked ? ActionTypes.REMOVE_TEXT_BORDER : ActionTypes.ADD_TEXT_BORDER, true)
      }

      case "LevelLabels": return {
        id: "checkbox3", name: "Level Labels", defaultChecked: isLevelLabelsChecked, onClick: () => this.onCheckBoxChange('levelLabels', 'isLevelLabelsChecked', ActionTypes.HANDLE_FIBONACCI_LINE_DRAW, true)
      }

      case "PriceLabels": return {
        id: "checkbox4", name: "Price Labels", defaultChecked: isPriceLabelsChecked, onClick: () => this.onCheckBoxChange('priceLabels', 'isPriceLabelsChecked', ActionTypes.HANDLE_FIBONACCI_LINE_DRAW, true)
      }

      case "Extensions": return {
        id: "checkbox", name: "EXTENSIONS", defaultChecked: isExtensionsChecked, onClick: () => this.onCheckBoxChange('extensions', 'isExtensionsChecked', ActionTypes.HANDLE_FIBONACCI_LINE_DRAW, true)
      }

      case "Leaderlines": return {
        id: "checkbox1", name: "LEADER LINES", defaultChecked: isLeaderlinesChecked, onClick: () => this.onCheckBoxChange('leaderlines', 'isLeaderlinesChecked')
      }

      default: return;
    }
  }

  render() {
    const type = this.props.type;
    const checkboxProperties = this.getCheckBoxProperties(type);
    const symbolType = SettingsStore.getConsoleSettings().NavDatagraphSettings.symbolTypeEnum;
    let disabled = false;
    if ((!this.props.isApiReqResolved || symbolType === 9 || symbolType === 18 || symbolType === 28 || symbolType === 29) && type === "Alert") {
      disabled = true;
    }
    return (
      <li>
        <Checkbox disabled={disabled} className="custom-check" type='checkbox' id={checkboxProperties.id} onChange={(e) => { }} name='' checked={checkboxProperties.defaultChecked} onClick={!disabled ? checkboxProperties.onClick : undefined}>
          <label htmlFor={checkboxProperties.id}>{checkboxProperties.name}</label>
        </Checkbox>
      </li>
    );
  }
}

function mapStateToProps(state) {
  const annotationState = state.annotationReducers;
  return {
    modelInfo: getSelectedAnnotationInfo(state),
    annotationType: annotationState.annotationMenuReducer.selectedToolType,
    annotSelected: annotationState.annotationChartModelReducer.annotSelected,
    isApiReqResolved: annotationState.annotationChartModelReducer.isApiReqResolved,
    selectedShapeType: state.annotationReducers.annotationMenuReducer.ShapesModelInfo.selectedType
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onStartArrowChange: (startArrowChecked, lineX1, startPolygonPoints) => dispatch(onStartArrowChange(startArrowChecked, lineX1, startPolygonPoints)),
    onStartExtendChange: (startExtendChecked, lineX1, startPolygonPoints, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1) =>
      dispatch(onStartExtendChange(startExtendChecked, lineX1, startPolygonPoints, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1)),
    onEndArrowChange: (endArrowChecked, lineX2, endPolygonPoints) => dispatch(onEndArrowChange(endArrowChecked, lineX2, endPolygonPoints)),
    onEndExtendChange: (endExtendChecked, lineX2, endPolygonPoints, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2) =>
      dispatch(onEndExtendChange(endExtendChecked, lineX2, endPolygonPoints, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2)),
    onCheckBoxChange: (event, property, newState, selectedAnnotation) => dispatch(onCheckBoxChange(event, property, newState, selectedAnnotation)),
    updateTargetAnnotation: (action, data, computationAction, shouldSave) => updateTargetAnnotation(action, data, computationAction, shouldSave)
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(AnnotationsCheckboxSelectors);


