import React from "react";
import { useSelector } from "react-redux";
import { RiPanelConstituentsTranslateHelper, SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";

function SymbolContentControl() {
    const data = useSelector((state) => state.summaryReducer.symbolContentData)
    const height = 25;
    let isNoData = false;
    if (data && (!data.coverage && !data.type && !data.exchange)) {
        isNoData = true;
    }

    return (
        <div className="summary-block-log">
            <div className="summary-block-log-hold" style={{ height: height }}>
                {
                    isNoData === false ?
                        <div className="sb-table">
                            <div className="sb-table-body">
                                <div className="sb-table-row">
                                    <div className="sb-table-cell for-index-cell">
                                        {data.coverage ? <div> {SummaryTransLateHelper.COVERAGE} <span className="coverage"> {data.coverage} </span> </div> : <div className="lockSize" />}
                                    </div>
                                    <div className="sb-table-cell for-index-cell">
                                        {data.type ? <div> {SummaryTransLateHelper.TYPE} <span className="type"> {data.type} </span> </div> : <div className="lockSize" />}
                                    </div>
                                    <div className="sb-table-cell for-index-cell" style={{ paddingRight: "20px" }}>
                                        {data.exchange ?
                                            <div>
                                                {SummaryTransLateHelper.EXCHANGE_TYPE}
                                                <a className="link" href={data.exchangeLink} rel="noreferrer" target="_blank">{data.exchange}</a>
                                            </div>
                                            :
                                            <div className="lockSize" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="messageSummaryBlock news-message message-info"><p>{RiPanelConstituentsTranslateHelper.NOT_AVAILABLE}</p></div>
                }
            </div>
        </div>
    );
}
export default SymbolContentControl