import { RayAnnotationType } from 'Constants/EAnnotationType';

export class RectangleInfo {
    constructor() {
        this.AnnotationType = RayAnnotationType.RECT_AnnotationType;

        // Set all defaults
        this.ObjectBrushHex = "#FF0066CC";
        //this.OriginalObjectBrushHex = ObjectBrushHex;
        //this.AnnotationDashType = AnnotationDashes.Solid;
        this.LineWidth = 2;
        this.Opacity = 100;
    }
}
