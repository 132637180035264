import DateHelper from "../../../../../Utils/TimeLineHelper/Datehelper.js";
import LocalizationStore from 'LocalizationStore';

const weeklyText = LocalizationStore.getTranslatedData("sb_wklylabel", "WEEKLY");

export default class IndustryWeeklyData {
    constructor(weeklyData, headers, stockInfo) {
        this.labelWidth = 46;
        this.stockInfo = stockInfo;
        this.cellItemConut = 11;
        this.setWeeklyData(weeklyData, headers);
    }

    setWeeklyData(weeklyData, headers) {
        this.rowSource = [];
        for (let i = 0; i <= headers.length; i++) {
            const row = {
                cells: []
            };
            for (let j = 0; j < this.cellItemConut + 1; j++) {
                let cell = {};

                if (i === 0 && j === 0) {
                    cell.value = weeklyText;
                    cell.IsLabelCell = true;
                    cell.textAlign = "left";
                } else if (i === 0 && j > 0) {
                    const weeklyDataExtraItems = weeklyData.length - this.cellItemConut;
                    const weekData = weeklyData[weeklyData.length - (j + weeklyDataExtraItems)];
                    let date = "";
                    if (weekData) {
                        if (weekData.date) {
                            date = weekData.date ? DateHelper.getMonthAndDay(new Date(weekData.date.toNumber())) : "";
                        }
                    }
                    cell.value = date;
                    cell.textAlign = "center";
                } else if (j === 0) {
                    cell.value = headers[i - 1];
                    cell.IsLabelCell = true;
                    cell.textAlign = "left";
                } else {
                    cell = this.getDataCell(weeklyData, i, j);
                }
                row.cells.push({
                    cell
                });
            }
            this.rowSource.push(row);
        }
    }

    getDataCell(weeklyData, row, cell) {
        const weeklyDataExtraItems = weeklyData.length - this.cellItemConut;
        const item = weeklyData[weeklyData.length - (cell + weeklyDataExtraItems)];

        if (row === 1) {
            return this.getRelativeStrength(item);
        }
        if (row === 2) {
            return this.getGroupRank(item);
        }
        if (row === 3) {
            return this.getNewHighs(item);
        }
        if (row === 4) {
            return this.getNewLows(item);
        }
        return {
            cell: {}
        };
    }

    getRelativeStrength(item) {
        const cell = {};
        cell.textAlign = "right";
        cell.value = "";
        if (item) {
            if (item.relativeStrength || item.relativeStrength === 0) {
                cell.value = item.relativeStrength;
            }
        }
        return cell;
    }

    getGroupRank(item) {
        const cell = {};
        cell.textAlign = "right";
        cell.value = "";
        if (item) {
            if (item.grpRank || item.grpRank === 0) {
                cell.value = item.grpRank;
            }
        }
        return cell;
    }

    getNewHighs(item) {
        const cell = {};
        cell.textAlign = "right";
        cell.value = "";
        if (item) {
            if (item.newHighCount || item.newHighCount === 0) {
                cell.value = item.newHighCount;
            }
        }
        return cell;
    }

    getNewLows(item) {
        const cell = {};
        cell.textAlign = "right";
        cell.value = "";
        if (item) {
            if (item.newLowCount || item.newLowCount === 0) {
                cell.value = item.newLowCount;
            }
        }
        return cell;
    }

}