class PriceMA {
    CalculatePriceMANode(chartNodes, period, lastNode) {
        let totalPoints = 0;
        const dataNodes = chartNodes.filter((t) => t != null && t._volume > -1);
        const dataNodesLength = dataNodes.length;
        let movingTotalPrice = 0;

        if (dataNodesLength > period) {
            for (let i = lastNode; totalPoints < period && i < period; i++) {
                movingTotalPrice += dataNodes[i].Close;
                totalPoints++;
            }
        }

        return movingTotalPrice / totalPoints;
    }

    CalculatePriceEMANode(chartNodes, period, fCurrentEMA) {
        const dataNodes = chartNodes.filter((t) => t != null && t._volume > -1);
        const dataNodesLength = dataNodes.length;
        let fSmoothingConstant = this.GetSmoothingConstant(period);
        if (dataNodesLength > period) {
            return this.getCurrentEMA(fSmoothingConstant, dataNodes[0].Close, fCurrentEMA);
        }
    }

    CalculatePriceMA(chartNodes, period, lastNode) {
        var prcLength = chartNodes.length;
        var firstRound = false;
        var movingTotalPrice = 0;
        var totalPoints = 0;
        var xPosition = 0;
        var mxPosition = 0;
        //var setPrev = false;
        var MAValues = [];
        if (prcLength < 1) return;

        var currentPricePoint = lastNode;

        let dataNodes = chartNodes.filter((t) => t != null && t._volume > -1);
        let dataNodesLength = dataNodes.length;

        if (dataNodesLength < period) {
            return MAValues;
        }

        for (; currentPricePoint < dataNodesLength - period; currentPricePoint++) {
            if (dataNodes[currentPricePoint].IsVisible)
                break;
        }

        for (; xPosition < prcLength; xPosition++) {
            if (chartNodes[xPosition].IsVisible)
                break;
        }

        for (; xPosition < prcLength; xPosition++) {
            if (currentPricePoint > dataNodesLength - period) {
                break;
            }
            //var tempval = 0.0;

            var info =
                {
                    Date: chartNodes[xPosition].Date,
                    Close: 0
                };

            MAValues.push(info);
            //let node = chartNodes[xPosition];
            if (chartNodes[xPosition]._volume < 0) {
                MAValues[mxPosition].Close = -1;
                //    xPosition++;
                mxPosition++;
                continue;
            }


            if (!firstRound) {
                var i;

                if (dataNodesLength > period) {
                    for (i = currentPricePoint; totalPoints < period && i < period; i++) {
                        movingTotalPrice += dataNodes[i].Close;
                        totalPoints++;
                    }
                }
                firstRound = true;
            }
            else {
                movingTotalPrice = movingTotalPrice - dataNodes[(currentPricePoint - 1)].Close;
                if ((currentPricePoint + period - 1) < dataNodesLength)
                    movingTotalPrice = movingTotalPrice + dataNodes[(currentPricePoint + period - 1)].Close;
            }
            MAValues[mxPosition].Close = movingTotalPrice / totalPoints;
            if (MAValues[mxPosition - 1] != null)
                for (var n = mxPosition - 1; n > 0 && MAValues[n] != null && MAValues[n].Close < 0; n--) {
                    if (n >= 0 && MAValues[n] != null && MAValues[n + 1] != null && MAValues[n].Close < 0)
                        MAValues[n].Close = MAValues[n + 1].Close;
                }

            currentPricePoint++;
            mxPosition++;
        }

        return MAValues;
    }

    CalculateExponentialMovingAverage(chartNodes, period, lastNode, isCurrency = false) {
        let dataNodes = chartNodes.filter((t) => t !== null && t._volume > -1);
        let totalNodes = dataNodes.length;
        let lastDate = chartNodes[chartNodes.length-1].Date;
        let firstDate = dataNodes[lastNode].Date;
        let fSmoothingConstant = this.GetSmoothingConstant(period);
        let fCurrentEMA = 0.0;
        let nCurrentPeriod = 1;
        let firstNode = false;
        let xPosition = 0;
        let mxPosition = 0;
        let lastPt = 0;
        let EMAValues = [];
        for(let index = 0; index < chartNodes.length; index ++){
            if (chartNodes[index].Date.getTime() === lastDate.getTime()){
                xPosition = index;
                break;
            }
        }
        if(!isCurrency) {
            while (lastPt < totalNodes && dataNodes[lastPt].Close < 0.000001) {
                lastPt++;
            }
        }
        let index = totalNodes - 1;
        for (; index >= lastPt; index--) {
            if (xPosition < 0 || chartNodes[xPosition] === null) {
                break;
            }
            let fPrevEMA = fCurrentEMA;
            if (nCurrentPeriod < period) {
                fCurrentEMA = 0;
            } else {
                if (nCurrentPeriod === period) {
                    fCurrentEMA = this.getSimpleMA(index, period, dataNodes);
                    firstNode = true;
                } else {
                    fCurrentEMA = this.getCurrentEMA(fSmoothingConstant, dataNodes[index].Close, fCurrentEMA);
                }
            }
            nCurrentPeriod++;
            if (dataNodes[index].Date.getTime() < lastDate.getTime() || dataNodes[index].Date.getTime() > firstDate.getTime()){
                continue;
            }
            if (fCurrentEMA === 0.0 || firstNode) {
                xPosition--;
                while (chartNodes[xPosition] && chartNodes[xPosition]._volume < 0)   // if no trade/holiday/disaster/etc
                {
                    if (xPosition < 1) break;
                    xPosition--;
                }
                firstNode = false;
                continue;
            }
            let info = {
                Date: chartNodes[xPosition].Date,
                Close: 0
            };
            EMAValues.push(info);
            while (chartNodes[xPosition] && chartNodes[xPosition]._volume < 0)   // if no trade/holiday/disaster/etc
            {
                EMAValues[mxPosition].Close = fPrevEMA;
                if (xPosition < 1) break;
                mxPosition++;
                xPosition--;
                info = {
                    Date: chartNodes[xPosition].Date,
                    Close: fCurrentEMA
                };
                EMAValues.push(info);
            }

            if (chartNodes[xPosition] && chartNodes[xPosition].Close == 0) // no price data
            {
                xPosition--;
                continue; // starts the next iteration cycle
            }
            EMAValues[mxPosition].Close = fCurrentEMA;
            mxPosition++;
            xPosition--;
        }
        EMAValues = EMAValues.reverse();
        return EMAValues;
    }

    GetSmoothingConstant(lPeriod) {
        return 2 / (1 + lPeriod);
    }

    getSimpleMA(nPricePtr, lPeriod, stockQuotes) {
        let fSumOfPeriods = 0.0;
        if (lPeriod === 0) {
            return 0;
        }
        let totalNodes = stockQuotes.filter((t) => t !== null && t.Close > 0).length;
        if ((totalNodes - nPricePtr) >= lPeriod) {
            for (let i = nPricePtr; i < (nPricePtr + lPeriod); i++) {
                fSumOfPeriods = fSumOfPeriods + stockQuotes[i].Close;
            }
        }
        return fSumOfPeriods / lPeriod;
    }

    getCurrentEMA(fSmoothingConstant, fCurrentPrice, fPreviousPeriodEMA) {
        return (fSmoothingConstant * (fCurrentPrice - fPreviousPeriodEMA)) + fPreviousPeriodEMA;
    }
}

export default PriceMA;   
