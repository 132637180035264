import React from 'react';
// import { Long } from 'protobufjs';
import StringUtil from 'StringUtil';
import ListActions from "ListActions";
import BaseServiceApi from 'BaseServiceApi';
import { Shortcuts } from 'react-shortcuts';
import KeyCodes from "KeyCodes";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListStore from "ListStore";
import BrowserUtil from "BrowserUtil";
import ListExplorerStore from "ListExplorerStore";
import LocalizationStore from 'LocalizationStore';
import ListManagerTabType from "ListManagerTabType";
import { ListExplorerConstants } from "ListExplorerConstants";
import ContextMenuConstants from "ContextMenuConstants";
const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
import ScrollBar from "ScrollBar";
import { rollBackList,loadStartWizard,loadStartWizardDate,loadStartWizardDateFormat } from "../../Actions/ExternalDataUploaderActions";
import { dispatch } from "../../Redux/dispatch.js";
import { each} from "underscore";
let ListType = BaseServiceApi.rayData["ListType"];
const TemporaryListType = BaseServiceApi.rayData["TemporaryListType"];

let LISTPROPERTY_CHANGE = "LISTPROPERTY_CHANGE";
export default class ListProperty extends React.Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
    this.showOriginalClick = this.showOriginalClick.bind(this);
    this.handleShortcuts = this.handleShortcuts.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleRollBack = this.handleRollBack.bind(this);
    this.updateListProperty = this.updateListProperty.bind(this);
    this.startWizarSymbol = this.startWizarSymbol.bind(this);
    this.startWizarDate = this.startWizarDate.bind(this);
    this.startWizarDateFormat = this.startWizarDateFormat.bind(this);
    this.node = null;
    this.keyCodePressed = null;
    this.maxTextLength = BrowserUtil.isIEBrowser() ? 150 : 100;
    this.isEditable = true;
    this.symbolName=null;
    this.dateColumn=null;
    this.dateFormat=null;
    this.lastUploadDate=null; 
    this.previousUploadDateTime = null;   
    this.state = {
      listDescription:this.getDescription(this.props.listId, this.props.dataSource, this.props.selectedTabKey, this.props.columnSetProperties),
      gics_present:this.setGicsDescription()
    }
    this.oNeilViewStoreStateChange = this.oNeilViewStoreStateChange.bind(this);
    this.getColumnsInList = this.getColumnsInList.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false);
    ListExplorerStore.removeChangeListener(this.updateListProperty,LISTPROPERTY_CHANGE);
    ONeilViewStore.removeChangeListener(this.oNeilViewStoreStateChange);
  }

  UNSAFE_componentWillMount() {
    ListExplorerStore.addChangeListener(this.updateListProperty,LISTPROPERTY_CHANGE);
    ONeilViewStore.addChangeListener(this.oNeilViewStoreStateChange);

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let val=this.state?.gics_present;
    if (nextProps.listId !== this.props.listId) {
      val=false
    }
      // this.setState({listDescription:this.getDescription(nextProps.listId, nextProps.dataSource, nextProps.selectedTabKey, nextProps.columnSetProperties)});
      this.setState({listDescription:this.getDescription(nextProps.listId, nextProps.dataSource, nextProps.selectedTabKey, nextProps.columnSetProperties),gics_present:val});
  }

  updateListProperty()
{
  this.setState({listDescription:this.getDescription(this.props.listId, this.props.dataSource, this.props.selectedTabKey, this.props.columnSetProperties)});
}

  componentDidUpdate() {
    const elm = document.getElementById('descEdit');
    if (!StringUtil.isEmpty(elm)) {
      if (!StringUtil.isEmpty(elm.textContent))
        this.ellipsizeDiv("descEdit");
    }
  }

  handleDocumentClick(evt) {
    try {
      if (!StringUtil.isEmpty(this.node))
        this.updateDesc(this.node);
    }
    catch (ex) {

    }
    evt.stopPropagation();
  }

  handleEdit() {

    if (!StringUtil.isEmpty(this.props.dataSource) && !StringUtil.isEmpty(this.props.listId)) {
      this.node = collapsbleArray(this.props.dataSource, this.props.listId);
      const descEdit = document.getElementById('descEdit');
      descEdit.setAttribute('contentEditable', true);
      const className = BrowserUtil.isIEBrowser() ? 'editDesc wordwrap IEHeight' : 'editDesc wordwrap';
      descEdit.setAttribute('class', className);
      descEdit.innerHTML = this.node.description ? this.node.description : '';
      descEdit.focus();
      document.execCommand("selectall", null, false);

      const elmEditLink = document.getElementById('editLink');
      if (!StringUtil.isEmpty(elmEditLink))
        elmEditLink.className = 'displayNone';
    }
  }

  updateDesc(node) {
    const descEdit = document.getElementById('descEdit');
    const newDescription = descEdit.textContent;

    if (newDescription !== (!node.description ? '' : node.description)) {
      const updatedDecriptionList = {
        nodeId: node.nodeId.low,
        nodeType: ListExplorerStore.isAnnoationList(node) ? ListType.ANNOTATIONLAYER_List : node.listType,
        newDescription: newDescription
      };
      BrowserUtil.disableEvents();
      ListActions.getUpdateNodeDescription(updatedDecriptionList);
    }
    descEdit.setAttribute('contentEditable', false);
    var myButtonClasses = document.getElementById("descEdit").classList;

    if (myButtonClasses.contains("divDesc")) {
      myButtonClasses.remove("divDesc");
    } else {
      myButtonClasses.add("divDesc");
    }
    if (myButtonClasses.contains("editDesc")) {
      myButtonClasses.remove("editDesc");
    } else {
      myButtonClasses.add("editDesc");
    }

    const elm = document.getElementById('descEdit');
    if (!StringUtil.isEmpty(elm)) {
      if (elm.className == 'wordwrap divDesc' && !StringUtil.isEmpty(elm.textContent)) {
        this.ellipsizeDiv("descEdit");
      }
    }

    const elmEditLink = document.getElementById('editLink');
    if (!StringUtil.isEmpty(elmEditLink)) {
      const isDescriptionEmpty = StringUtil.isEmpty(descEdit.textContent) || descEdit.textContent.charCodeAt(0) == 10;

      if (descEdit.textContent.charCodeAt(0) == 10) {
        descEdit.innerHTML[(descEdit.textContent.length) - 1].replace('');
      }

      elmEditLink.className = '';
      elmEditLink.innerHTML = isDescriptionEmpty ? LocalizationStore.getTranslatedData('LM_TP_AddDesc', 'Add Description') : LocalizationStore.getTranslatedData('edit', 'Edit');
      elmEditLink.parentNode.style.marginTop = isDescriptionEmpty ? '-23px' : '0px';
    }
  }

  showOriginalClick(e) {    
    if(this.props.selectedTabKey === ListManagerTabType.Ownership)
    return this.props.showOriginalClick(this.node);
    else
    return this.props.showOriginalClick(e);
  }

  ellipsizeDiv(id) {    
    const el = document.getElementById(id);
    this.node = collapsbleArray(this.props.dataSource, this.props.listId);
    
    if (!StringUtil.isEmpty(el) && !StringUtil.isEmpty(this.node.description))
      StringUtil.ellipsisContentDiv(el, this.node.description);
  }

  handleBlur(e) {
    if (this.keyCodePressed != 13 && e.type == 'blur') {
      this.updateDesc(this.node);
    }
    this.keyCodePressed = null;
  }

  handleShortcuts(key, event) {
    if (KeyCodes.ENTER == event.keyCode) {
      this.keyCodePressed = event.keyCode;
      this.updateDesc(this.node);
    }
  }

  handleKeyPress(e) {
    if (e.target.textContent.length >= this.maxTextLength) {
      e.preventDefault();
      return false;
    }
  }

  isSmartList() {
    const actualListId = ListStore.getSelectedListItem().SelectedActualListId;
    let currentNode = ONeilViewStore.getCurrentNode(actualListId);
    if(this.props.selectedTabKey == ListManagerTabType.Ownership)
    currentNode = OwnershipViewStore.getCurrentNode(actualListId);
    if (currentNode.length > 0 && currentNode[0].categoryType == ListCategoryType.SMARTLIST_ListCategory) {
      return true;
    }
    return false;
  }

  saveTempList(nodeDetails) {
    this.props.duplicateItem(nodeDetails.nodeId.low, nodeDetails.name, nodeDetails.rootParentNodeId, nodeDetails.categoryType, ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST, nodeDetails.description, false, '');
  }

  removeTempList(nodeDetails) {
    ListExplorerStore.contextNode = {};
    const action = ContextMenuConstants.DELETE;
    ListExplorerStore.contextNode.rootParentNodeId = nodeDetails.rootParentNodeId;
    ListExplorerStore.contextNode.parentNodeId = nodeDetails.parentNodeId
    ListExplorerStore.contextNode.categoryType = nodeDetails.categoryType;
    ListExplorerStore.contextNode.nodeId = nodeDetails.nodeId;
    ListExplorerStore.contextNode.name = nodeDetails.name;
    ListExplorerStore.contextNode.listType = nodeDetails.listType;
    ListExplorerStore.contextNode.symbol = nodeDetails.symbol && nodeDetails.symbol;
    ListExplorerStore.contextNode.msid = nodeDetails.msid && nodeDetails.msid;
    ListExplorerStore.contextNode.action = action;
    return this.props.onExplorerContextMenuClick(action);
  }

  getSharingStatus(SharedNumber) {
    return SharedNumber <= 0 ? LocalizationStore.getTranslatedData("list_Noshared", "Not Shared")
      : (SharedNumber == 1 ? LocalizationStore.getTranslatedData("LM_ShareMsg_2", "Shared with 1 person")
        : LocalizationStore.getTranslatedData("LM_ShareMsg_1", "Shared with {0} people", SharedNumber));
  }
  getDescription(listId, dataSource,selectedTabKey,columnSetProperties) {
    if (dataSource != null) {
      this.node = collapsbleArray(dataSource, listId);
      if (ListExplorerStore.activeNode !== null && ListExplorerStore.activeNode.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && ListExplorerStore.activeNode.temporaryListType === TemporaryListType.FUNDHOLDINGS_TEMPORARYLIST) {
        this.node = ListExplorerStore.findNodeBySymbol(ListExplorerStore.activeNode.symbol, dataSource);
      }
      let isUserList;
      if(selectedTabKey === ListManagerTabType.Oneil){
        isUserList = ONeilViewStore.isUserList()
      }
      else if(selectedTabKey === ListManagerTabType.Ownership)
        isUserList = OwnershipViewStore.isUserList()

      if(!this.node) return;
      const sourceListId = this.node.sourceListId !== null && this.node.sourceListId.low ? this.node.sourceListId.low : this.node.nodeId.low;
      const listContentId = (this.node !== null && sourceListId !== 0) ? sourceListId : listId;
      const listDescription = LocalizationStore.getTranslatedData(`ListPropertyDesc_${  listContentId}`, ((this.node.description) === null ? '' : this.node.description));
      if (this.node !== null) {
        if (!ListExplorerStore.isAnnoationList(this.node) && this.node.categoryType !== ListCategoryType.FAVORITE_ListCategory && this.node.categoryType !== ListCategoryType.PORTFOLIOLIST_ListCategory && this.node.categoryType !== ListCategoryType.SHAREDLIST_ListCategory && this.node.categoryType !== ListCategoryType.USER_ListCategory && this.node.categoryType !== ListCategoryType.APLIST_ListCategory && this.node.categoryType !== ListCategoryType.TEMPORARYLIST_ListCategory && this.node.categoryType !== ListCategoryType.SCHEDULESCREENLIST_ListCategory && this.node.categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
          this.isEditable = false;
          if (this.isSmartList()) {
            return null;
          }
          else {
            return listDescription;
          }
        }
        else if (ListExplorerStore.isAnnoationList(this.node)) {
          const updateDate = StringUtil.formatDate(this.node.updateDt, 'MMM D, YYYY h:mm A', true);
          const createdDate = StringUtil.formatDate(this.node.createDt, 'MMM D, YYYY h:mm A', true); 
          const editLinkMargin = StringUtil.isEmpty(this.node.description) ? '-23px' : '0px';         
          const myListContent =
            (<table>
              <tbody>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_upd_time', 'Updated on: ')}</td><td> {`${updateDate  } `}</td></tr>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_crt_time', 'Created on: ')}</td><td> {`${createdDate  } `}</td></tr>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_des', 'Description ')}</td><td><div id="descEdit" onBlur={this.handleBlur} onKeyPress={this.handleKeyPress} className="divDesc">{listDescription}</div></td></tr>
                {!this.node.ownerUserName && <tr><td></td><td style={{ marginTop: editLinkMargin }}><a id='editLink' onClick={this.handleEdit}>{StringUtil.isEmpty(this.node.description) ? LocalizationStore.getTranslatedData('LM_TP_AddDesc', 'Add Description') : LocalizationStore.getTranslatedData('edit', 'Edit')}</a></td></tr>}
                </tbody>
            </table >);
          return myListContent;
        }
        else if (isUserList || this.node.categoryType === ListCategoryType.SCHEDULESCREENLIST_ListCategory) {
          this.isEditable = true;
          const updateDate = StringUtil.formatDate(this.node.updateDt, 'MMM D, YYYY h:mm A', true);
          const createdDate = StringUtil.formatDate(this.node.createDt, 'MMM D, YYYY h:mm A', true);

          let shareStatus = this.node.shareAccess === null ? LocalizationStore.getTranslatedData('list_Noshared', 'Not Shared') : this.node.shareAccess;
          if (columnSetProperties != null) shareStatus = this.getSharingStatus(columnSetProperties.sharedNum);
      
          const editLinkMargin = StringUtil.isEmpty(this.node.description) ? '-23px' : '0px';
          const myListContent =
            (<table>
              <tbody>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_upd_time', 'Updated on: ')}</td><td> {`${updateDate  } `}</td></tr>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_crt_time', 'Created on: ')}</td><td> {`${createdDate  } `}</td></tr>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_sharing_staus', 'Sharing Status: ')}</td><td> {shareStatus}</td></tr>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_des', 'Description ')}</td><td><div id="descEdit" onBlur={this.handleBlur} onKeyPress={this.handleKeyPress} className="divDesc">{listDescription}</div></td></tr>
                {(this.node.categoryType === ListCategoryType.USER_ListCategory || this.node.categoryType === ListCategoryType.SCHEDULESCREENLIST_ListCategory) ? <tr><td></td><td style={{ marginTop: editLinkMargin }}><a id='editLink' onClick={this.handleEdit}>{StringUtil.isEmpty(this.node.description) ? LocalizationStore.getTranslatedData('LM_TP_AddDesc', 'Add Description') : LocalizationStore.getTranslatedData('edit', 'Edit')}</a></td></tr> : null}
              </tbody>
            </table >);
          let favoriteContent = null;
          if (this.node.categoryType === ListCategoryType.FAVORITE_ListCategory) {
            favoriteContent = (<div className="show-originals">
              <span className='x-small-bold'>{LocalizationStore.getTranslatedData('list_aliasMsg', 'This list is an alias. ')}</span>
              <a className="link-blue" data-sourcelistid={sourceListId} onClick={this.showOriginalClick}>{LocalizationStore.getTranslatedData('lp_so', 'Show Original ')}</a>
            </div>);
          }
          return (
            <div>
              {myListContent}{favoriteContent}
            </div>
          );
        }
        else if (this.node.categoryType === ListCategoryType.SHAREDLIST_ListCategory || this.node.categoryType === ListCategoryType.PORTFOLIOLIST_ListCategory) {
          const updateDate = StringUtil.formatDate(this.node.updateDt, 'MMM D, YYYY h:mm A');
          const createdDate = StringUtil.formatDate(this.node.createDt, 'MMM D, YYYY h:mm A');          
          const myListContent =
            (<table>
              <tbody>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_upd_time', 'Updated on: ')}</td><td> {`${updateDate  } `}</td></tr>
                <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_crt_time', 'Created on: ')}</td><td> {`${createdDate  } `}</td></tr>
                {this.node.categoryType !== ListCategoryType.PORTFOLIOLIST_ListCategory ? <tr><td className='bold'>{LocalizationStore.getTranslatedData('list_des', 'Description ')}</td><td><div className="divDesc">{listDescription}</div></td></tr> : null}
              </tbody>
            </table >);
          return myListContent;
        }
        else {
          // const editLinkMargin = StringUtil.isEmpty(this.node.description) ? '-23px' : '0px';
          this.isEditable = true;
          if (this.node.categoryType === ListCategoryType.FAVORITE_ListCategory) {
            let favDescription = null;
            if (!this.isSmartList()) {
              let favSourceNode = collapsbleArray(dataSource, sourceListId);
               favDescription = LocalizationStore.getTranslatedData(`ListPropertyDesc_${  listContentId}`, ((favSourceNode.description) === null ? '' : favSourceNode.description));
            }
            return (
              <div>
                <p className='small-bold' >{favDescription}</p>
                <span className='x-small-bold'>{LocalizationStore.getTranslatedData('list_aliasMsg', 'This list is an alias.')}</span> <a data-sourcelistid={sourceListId} onClick={this.showOriginalClick}>{LocalizationStore.getTranslatedData('lp_so', ' Show Original ')}</a>
              </div>
            );
          }
          if (this.node.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
            const listDescription = LocalizationStore.getTranslatedData(`ListPropertyDesc_${  listContentId}`, ((this.node.description) === null ? '' : this.node.description));
            const myListContent =
            (<table>
              <tbody>
                <tr><td><u><a onClick={() => this.saveTempList(this.node)}>{LocalizationStore.getTranslatedData('lp_so', ' Save to My Lists')}</a></u></td></tr>
                <tr><td><u><a  onClick={() => this.removeTempList(this.node)}>{LocalizationStore.getTranslatedData('lp_so', ' Remove ')}</a></u></td></tr>
              </tbody>
            </table >);
              return (
                <div>
                  {listDescription}{myListContent}
                </div>
              );          
          }
          if (this.node.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
            const listDescription = LocalizationStore.getTranslatedData(`ListPropertyDesc_${  listContentId}`, ((this.node.description) === null ? '' : this.node.description));
            const myListContent =
            (<table>
              <tbody>
                <tr><td><u><a  onClick={() => this.removeTempList(this.node)}>{LocalizationStore.getTranslatedData('lp_so', ' Remove ')}</a></u></td></tr>
              </tbody>
            </table >);
              return (
                <div>
                  {listDescription}{myListContent}
                </div>
              );          
          }
          return '';
        }
      }
    }
  }

  getDataSetProperties(activeExternalNode) {
    if(activeExternalNode && activeExternalNode.lastUploadDateTime) { 
      this.lastUploadDate =StringUtil.formatDate(activeExternalNode.lastUploadDateTime, 'MM/DD/YYYY', true)   ;
    }
    if(activeExternalNode && activeExternalNode.previousUploadDateTime) { 
      this.previousUploadDateTime = " (" + StringUtil.formatDate(activeExternalNode.previousUploadDateTime, 'MM/DD/YYYY', true) +")";
    }
    if(activeExternalNode.headers) {
      let symboldetails = activeExternalNode.headers.filter((item) => item.isSymbolColumn);
      let dateColumn = activeExternalNode.headers.filter((item) => item.isDateColumn);
      this.symbolName=symboldetails[0].displayName;
      this.dateColumn=dateColumn[0].displayName;
      this.dateFormat=dateColumn[0].dateFormat;      
    }        
  }

  handleRollBack() { 
    dispatch(rollBackList(ListStore.activeExternalNode.listId));    
  }
  startWizarSymbol ()   {
    dispatch(loadStartWizard(ListStore.activeExternalNode.listId,true));
  }
  startWizarDate  ()   {
    dispatch(loadStartWizardDate(ListStore.activeExternalNode.listId,true));
  }
  startWizarDateFormat  ()   {
    dispatch(loadStartWizardDateFormat(ListStore.activeExternalNode.listId,true));
  }
  getColumnsInList(listState) {
    let columns = [];
    if (listState) {
        each(listState.columns, (columnInfo) => columns.push(columnInfo?.displayName))
    }
    return columns;
  }

  setGicsDescription(){
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let columnsList=[]
    let listState = ONeilViewStore.getState();
    columnsList=this.getColumnsInList(listState)
    const  GICS_TEXT="gics";

    if(tabType==ListManagerTabType.Oneil && columnsList?.length){
      // checking GICS metrics present in column 
      let gicsColumnsPresent=columnsList.filter((column)=>column?.toLowerCase()?.includes(GICS_TEXT))
      if(gicsColumnsPresent?.length)
          return true
      else
          return false
    } 
  }

  oNeilViewStoreStateChange(){
    let currentAction = ONeilViewStore.getCurrentAction();
    if(currentAction==ListExplorerConstants.ActionTypes.GET_LIST_CATALOG){
      this.setState({gics_present:this.setGicsDescription()})
    }
  }

  showGiCSDescription(){
    return <p>GICS&reg; was developed by and is the exclusive property of MSCI Inc. and Standard Poor's.</p>
  }

  render() {
    let show = this.props.showHelp;    
    let listDescriptionClass = this.isEditable ? "list-properties-details" : "list-properties-details bold";
    if (show === true && this.props.selectedTabKey !== ListManagerTabType.External) {
      return (
        <div id='listProperty' className="list-properties w50p-h">
          <div className="list-properties-head bold">{LocalizationStore.getTranslatedData('list_pro', 'LIST PROPERTIES')}</div>
          {<div className={`${listDescriptionClass  } x-small-normal`} ><>{this.state.listDescription}</>{this.state?.gics_present?this.showGiCSDescription():""}</div>}
          <Shortcuts
            name='NAV_LIST_MANAGER'
            handler={this.handleShortcuts}
            targetNodeSelector='#listProperty'
            isolate={true}>
          </Shortcuts>
        </div>
      );
    } else if (show === true && this.props.selectedTabKey === ListManagerTabType.External) {
      this.getDataSetProperties(this.props.activeExternalNode);
      if (this.props.activeExternalNode && this.props.activeExternalNode.headers) {
        let {isRollbackEnabled,isUpdateHeadersEnabled} = this.props.activeExternalNode 
        // temporarily Disabling on click for update headers from properties view
        isUpdateHeadersEnabled = false;
        return (
          <div id='listProperty' className="list-properties w50p-h extrnal_data">
            <div className="list-properties-head bold">1 Data Set Properties</div>
            <div className="list-properties-head bold text-right padr15">Last Upload - {this.lastUploadDate}</div>
            <div className="list-properties-head bold">Configuration</div>


            <div className="custom-scroll">
              <div id='listDescriptionID'>
                <ul>
                  <li>
                    <div className={listDescriptionClass + " x-small-normal"} >Unique Security Identifier - <span className={(isUpdateHeadersEnabled ? 'link-anchore':'grey-link')}  onClick={isUpdateHeadersEnabled ? this.startWizarSymbol : undefined}>{this.symbolName}</span></div>
                  </li>
                  <li>
                    <div className={listDescriptionClass + " x-small-normal"} >Date Column - <span className={(isUpdateHeadersEnabled ? 'link-anchore':'grey-link')} onClick={isUpdateHeadersEnabled ? this.startWizarDate : undefined}>{this.dateColumn}</span></div>
                  </li>
                  <li>
                    <div className={listDescriptionClass + " x-small-normal"} >Date Format - <span className={(isUpdateHeadersEnabled ? 'link-anchore':'grey-link')} onClick={isUpdateHeadersEnabled ? this.startWizarDateFormat : undefined}>{this.dateFormat}</span></div>
                  </li>
                </ul>
                {isRollbackEnabled && this.props.IsRollBack && <div className={listDescriptionClass + " x-small-normal"}> <span className="inner-head-external"><a id='editLink' onClick={this.handleRollBack}>Roll Back</a></span> to previous Upload<span>{this.previousUploadDateTime}</span></div>}
              </div>
              <ScrollBar scrollId="listDescriptionID" vScroll={true} />
            </div>
          </div>
        );
      } else {
        return (

          <div id='listProperty' className="list-properties w50p-h extrnal_data">
            <div className="list-properties-head bold">No Data Set Properties for selected list</div>
          </div>
        );

      }
    } else {
      return null;
    }
  }
}

// Search Node by nodeId
function findNode(id, currentNode) {
  let i, currentChild, result;
  if (id == currentNode.nodeId || id == currentNode.nodeId.low) {
    return currentNode;
  } else {
    for (i = 0; i < currentNode.childNodes.length; i += 1) {
      currentChild = currentNode.childNodes[i];
      result = findNode(id, currentChild);
      if (result !== false) {
        return result;
      }
    }
    return false;
  }
}

//collapsbleArrayData
function collapsbleArray(data, id) {
  const currentNode = data;
  for (var i = 0; i < currentNode.length; i += 1) {
    const searchValue = findNode(id, currentNode[i]);
    if (searchValue !== false) {
      return searchValue;
    }
  }
  return null;
}
//Helper Method
// var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
// function formatDate(d) {
//   var hr = d.getHours();
//   var hrs = hr;
//   if (hr > 12) {
//     hr = hr % 12;
//     if (hr < 10) {
//       hr = `0${  hr}`;
//     }
//   }
//   var min = d.getMinutes();
//   if (min < 10) {
//     min = `0${  min}`;
//   }
//   var ampm = hrs < 12 ? "AM" : "PM";
//   var date = d.getDate();
//   var month = months[d.getMonth()];
//   var year = d.getFullYear();
//   return (`${date  } ${  month  } ${  year  } ${  ampm  } ${  hr  }:${  min}`);
// }
