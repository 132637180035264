import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import StringUtil from "StringUtil";
import validator from 'validator';
import LocalizationStore from 'LocalizationStore';
import ConsoleStore from "../../Stores/ConsoleWindow/ConsoleStore";
import DatagraphStore from "../../Stores/NavModules/NavDataGraph/DataGraphStore";
import ConsoleActions from "../../Actions/ConsoleActions";

export default class EmailRequest extends Component {
    constructor(props) {
        super(props);
        let settings = ConsoleStore.getSettings();
        let symbol = settings.NavDatagraphSettings.symbol;
        this.state = {
            email : '',
            subject : symbol,
            body : '',
            subjectSelStart : 0,
            bodySelStart : 0,
            validationClass : 'displayNone'
        }
        this.hideDialog = this.hideDialog.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.submitForEmail = this.submitForEmail.bind(this);
    }

    hideDialog() {
        this.props.hideEmailDialog();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.open != nextProps.open && nextProps.open == true) {
            this.setState({
                email: nextProps.email,
                validationClass: 'displayNone'
            });
        }
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            this.submitForEmail();
            e.preventDefault();
        }
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        });
    }

    handleSubjectChange(event) {
        this.setState({
            subject: event.target.value
        });
    }

    handleBodyChange(event) {
        this.setState({
            body: event.target.value
        });
    }

    handleKeyUp(event) {
        if (event.keyCode == 32) {
            let value = event.target.value;
            if (value && event.target.selectionStart >= value.length)
                value = value + event.key;
            else
                value = value.slice(0, event.target.selectionStart) + event.key + value.slice(event.target.selectionEnd);

            if (event.target.id.indexOf('Subject') > 0) {
                this.setState({
                    subject: value,
                    subjectSelStart : event.target.selectionStart
                }); 
            }
            else if (event.target.id.indexOf('Body') > 0)
                this.setState({
                    body: value,
                    bodySelStart : event.target.selectionStart
                });
        }
        event.preventDefault();
    }

    submitForEmail() {
        let value = this.state.email;
        let subject = this.state.subject;
        let body = this.state.body;
        if (!StringUtil.isEmpty(value)) {
            let isValid = true;
            let emailAddresses = value.split(';');
            emailAddresses.forEach((email) => {
                if (!validator.isEmail(email)) {
                    this.setState({ validationClass: '' });
                    isValid = false;
                }
            });

            if (isValid) {
                const state = DatagraphStore.getState();
                let settings = ConsoleStore.getSettings();
                let symbol = settings.NavDatagraphSettings.symbol;
                console.log("Starting email request");
                ConsoleActions.startPrint([symbol], symbol, state.SymbolType, { EmailAddresses : value, Subject : subject, Body : body});

                this.hideDialog();
            }
                
        }
        else {
            this.setState({ validationClass: '' });
            return false;
        }
    }

    render() {
        return (
            <div id="printEmailDialog">
                <Modal className="modal-popup email-request" show={this.props.open}>
                    <Modal.Body>
                        <div className="modal-region">
                        <form className="email-form">
                            <div className="form-group">
                                <label htmlFor="formControlsEmail">{LocalizationStore.getTranslatedData("sb_email","Email")}</label>
                                <input  className="form-control disableDefaultSymbolInput"
                                        id="formControlsEmail"
                                        type="email"
                                        value={this.state.email}
                                        onChange={this.handleEmailChange.bind(this)}
                                        placeholder={LocalizationStore.getTranslatedData('tp_pt_emails','To (separate mutiple emails with ;)')}
                                        spellCheck="false"
                                /> 
                                <small className={this.state.validationClass + ' form-text text-danger text-left'}> {LocalizationStore.getTranslatedData("sb_enterEmail","Please enter valid email addresses")} </small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="formControlEmailSubject">{LocalizationStore.getTranslatedData("sb_subject","Subject")}</label>
                                <input  className="form-control disableDefaultSymbolInput"
                                        id="formControlEmailSubject"
                                        type="text"
                                        value={this.state.subject}
                                        onChange={this.handleSubjectChange.bind(this)}
                                        onKeyUp={this.handleKeyUp.bind(this)}
                                        placeholder={LocalizationStore.getTranslatedData("sb_subject","Subject")}
                                        spellCheck="true"
                                        selectionStart = {this.state.subjectSelStart}
                                />
                            </div>
                            <div className="form-group">
                            <label htmlFor="formControlEmailBody">{LocalizationStore.getTranslatedData("sb_message","Message")}</label>
                                <textarea className="form-control disableDefaultSymbolInput" 
                                            id="formControlEmailBody" 
                                            value={this.state.body} 
                                            onChange={this.handleBodyChange.bind(this)}
                                            onKeyUp={this.handleKeyUp.bind(this)}
                                            rows="5" cols="40" 
                                            placeholder={LocalizationStore.getTranslatedData("tp_pt_recipients","type message for recipients")}
                                            selectionStart = {this.state.bodySelStart}
                                />
                            </div>
                        </form>
                        <div className="clearfix"></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box righter">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.hideDialog}>{LocalizationStore.getTranslatedData('set_cancel', 'Cancel')}</button>
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.submitForEmail}>{LocalizationStore.getTranslatedData('sb_send', 'Send')}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}