import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class GetExternalListSettings extends BaseServiceRequest {
  constructor(listId) {
    super();
    super.addRequestUri("extscheduledetails.pb");
    super.addPathParameter(listId.toString());
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ExternalScheduleDetailsModel";
  }

}
