import { connect } from "react-redux";
import ConsoleStore from "ConsoleStore";
import DatagraphStore from "../../../../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import { EditBoxTranslateHelper } from "../../../../../Utils/TranslateHelper.js";
import KeyCodes from "KeyCodes";
import { MiniListConstants } from "MiniListConstants";
import MiniListStore from '../../../../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore.js';
import onClickOutside from "react-onclickoutside";
import PropTypes from 'prop-types';
import VirtualList from "../../../../../RayCustomControls/VirtualList/VirtualList.jsx";
import { closeSymbolEntryErrorDialog, handleSymbolEntryChange, handleSymbolEntrySymbolChange } from "../../../../../Actions/DatagraphActions.js";
import React, { Component } from "react";



class DropDown extends Component {
  constructor(props) {
    super(props);
    this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.MiniListStoreStateChanged = this.MiniListStoreStateChanged.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isOpen: false,
      activeIndex: -1,
      activeSymbol: undefined,
      itemHeight: 20,
      menuHeight: 180,
      scrollBarWidth: 18,
      marginWidth: 25,
      isFocused: false,
      isMiniListItemClicked: false,
      loadAnimation: false
    };
      this.isMount = true;
  }

  componentDidMount() {
    this.isMount = true;
    document.addEventListener("keydown", this.onDocumentKeyPress, false);
    MiniListStore.addChangeListener(this.MiniListStoreStateChanged);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onDocumentKeyPress, false);
    MiniListStore.removeChangeListener(this.MiniListStoreStateChanged);
    this.isMount = false;
    clearTimeout(this.searchTimeOut);
    clearTimeout(this.menuOpenTimeOut);
  }

  MiniListStoreStateChanged() {
    const state = MiniListStore.getState();
    const action = MiniListStore.getCurrentAction();
    if(action === MiniListConstants.ActionTypes.ON_MINILIST_PLAYING
     ||action === MiniListConstants.ActionTypes.ON_UPDATE_UPDATEHISTORIC){
      return;
    }
    if (state.isMiniListItemClicked){
      clearTimeout(this.searchTimeOut);
    }
    this.setState({ isMiniListItemClicked: state.isMiniListItemClicked });
  }
  

  handleClickOutside() {
    if (this.state.isMiniListItemClicked) {
      /* Fix for PANWEB-3134 */
      return;
    }
    this.input.style.backgroundColor = "#FFFFFF";
    clearTimeout(this.searchTimeOut);
    const symInfo = DatagraphStore.getSymbolInfo();
    if (symInfo && symInfo.Symbol) {
      if (this.props.symbol !== symInfo.Symbol) {
        this.searchTimeOut = setTimeout(() => {
          this.props.handleSymbolEntryChange(symInfo.Symbol, true);
        }, 10 * 1000);
      }
    }
    this.setState({ isOpen: false, activeIndex: -1, isFocused: false });
    document.body.classList.remove("symbol-entry-focused");
  }

  onDocumentKeyPress(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    
    if (e.altKey || e.ctrlKey || document.body.classList.contains("changePassWordOpened") || document.body.classList.contains("model-open") ||
      document.body.classList.contains("customIndicatorModalOpened") || document.body.classList.contains("datagraphCalendarOpened") || 
      (document.activeElement && document.activeElement.id === "formControlEmailBody") || (document.activeElement && document.activeElement.id
         === "formControlEmailSubject") || (document.activeElement && document.activeElement.id === "formControlsEmail") || 
         document.body.classList.contains('ExternalDataEditDialogOpened') || (document.activeElement && document.activeElement.id === "formControlsMobile")
        || document.body.classList.contains("annotation-active")){
      return;
    }
    const excludeKeyCodes = [
      KeyCodes.TAB,
      KeyCodes.SHIFT,
      KeyCodes.CTRL,
      KeyCodes.ALT,
      KeyCodes.LEFT_META,
      KeyCodes.CAPS_LOCK,
      KeyCodes.PAGE_UP,
      KeyCodes.PAGE_DOWN,
      KeyCodes.LEFT_ARROW,
      KeyCodes.UP_ARROW,
      KeyCodes.RIGHT_ARROW,
      KeyCodes.DOWN_ARROW,
      KeyCodes.SPACE,
      KeyCodes.ENTER,
      KeyCodes.ESCAPE
    ];
    if((document.body.classList.contains("annotation-selected") || document.body.classList.contains("temp-input"))  && (charCode === KeyCodes.DELETE || charCode === KeyCodes.ENTER)){
      return;
    }
    
    if(charCode === KeyCodes.DELETE){ 
       return;
    }
    if(document.activeElement.tagName === "INPUT" && document.activeElement.id !== "SymbolEntryInput"){
      return;
    }
    if (!document.body.classList.contains("modal-open") &&
      !e.target.classList.contains('disableDefaultSymbolInput') && 
      document.activeElement &&
      document.activeElement.id !== 'SymbolEntryInput' &&
      document.activeElement.id !== "MAlengthInput" &&
      excludeKeyCodes.indexOf(charCode) === -1 &&
      (charCode < 112 || charCode > 130)) {
        this.props.handleSymbolEntryChange("", true);
      this.setState({ isMiniListItemClicked: false });
      this.input.focus();
      this.input.style.backgroundColor = "#B4EB99";
      document.body.classList.add("symbol-entry-focused");
    }
    const list = this.props.menuItems;
    const index = this.state.activeIndex;
    if (document.activeElement.id === 'SymbolEntryInput') {
      let selectedIndex = -1;
      let activeSymbol = "";
      if (charCode === KeyCodes.DOWN_ARROW) {
        if ('selectionStart' in this.input) {
          this.input.selectionEnd = this.input.selectionStart;
        }
        if (list && list.length > 0 && index < list.length - 1) {
          selectedIndex = index + 1;
          activeSymbol = list[selectedIndex].symbol;
          this.menuItemList.scrollToItemIndex(selectedIndex);
        }
        else if (index === list.length - 1) {
          selectedIndex = list.length - 1;
          activeSymbol = list[selectedIndex].symbol;
        }
        this.setState({
          activeIndex: selectedIndex,
          activeSymbol: activeSymbol
        });
        e.preventDefault();
        e.stopPropagation();
      }
      else if (charCode === KeyCodes.UP_ARROW) {
        if ('selectionStart' in this.input) {
          this.input.selectionEnd = this.input.selectionStart;
        }
        if (list && list.length > 0 && index > 0) {
          selectedIndex = index - 1;
          activeSymbol = list[selectedIndex].symbol;
          this.menuItemList.scrollToItemIndex(selectedIndex);
        }
        else if (index === 0) {
          selectedIndex = -1;
          activeSymbol = this.props.symbol;
          this.input.select();
        }
        this.setState({
          activeIndex: selectedIndex,
          activeSymbol: activeSymbol
        });
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  onItemSelected(e, item) {
    this.input.style.backgroundColor = "#FFFFFF";
    this.props.handleSymbolEntrySymbolChange(item.symbol);
    this.setState({ isOpen: false, isFocused: false });
    this.input.blur();
    document.body.classList.remove("symbol-entry-focused");
    e.preventDefault();
  }

  getItemRenderer(item, top, index) {
    if (item && this && this.props && this.props.columnWidths) {
      const localSymbol = item.localSymbol;
      const isActive = this.state.activeIndex === index;
      const columnWidths = this.props.columnWidths;
      return (
        <div className="menuItem"
          key={index}
          style={{ top: top, width: this.props.searchedSymbolsWidth + this.state.marginWidth }}
          onMouseDown={(e) => {
            this.onItemSelected(e, item);
          }}>
          <div className={isActive ? "menuItem-content active" : "menuItem-content"}>
            <span style={{ width: localSymbol ? columnWidths.localSymbol ? columnWidths.localSymbol : 73 : 0 }}>{localSymbol}</span>
            <span style={{ width: columnWidths.symbol ? columnWidths.symbol : 73 }}>{item.symbol}</span>
            <span style={{ minWidth: columnWidths.companyName ? columnWidths.companyName : 100, flex: 1 }}>{item.companyName}</span>
            <span style={{ width: columnWidths.exgCode ? columnWidths.exgCode : 83 }}>{item.exgCode}</span>
          </div>
        </div>
      );
    }
  }

  setIsOpenState() {
    if (this.state.isOpen && this.props.menuItems) {
      const menuItemCount = this.props.menuItems.length;
      const menuHeight = menuItemCount >= 9 ? this.state.menuHeight : menuItemCount * this.state.itemHeight;
      return (
        <div className="menuPanel" style={{ flexFlow: "column", width: this.props.searchedSymbolsWidth + this.state.marginWidth + this.state.scrollBarWidth, right: this.props.rightPosition }}>
          <div id="menuHeader" className="header small-bold ">
            {this.props.menuHeader}
          </div>
          <div className="menuItems small-bold" style={{ flex: 1, overflow: "hidden", height: menuHeight, display: "flex" }}>
            <VirtualList id="symbolEntry" ref={(ref) => (this.menuItemList = ref)} items={this.props.menuItems}
              itemHeight={this.state.itemHeight}
              itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
              showScrollOnHover={false}
            />
          </div>
        </div>
      );
    }
  }

  handleChange() {
    if (this.props.handleSymbolEntryChange) {
      this.props.handleSymbolEntryChange(this.input.value);
    }
  }

  handleMouseDown(e) {
    this.input.style.backgroundColor = "#B4EB99";
    if (this.props.handleSymbolEntryMouseDown) {
      this.props.handleSymbolEntryMouseDown(e);
    }
    if (e.target !== document.activeElement) {
      this.setState({ isOpen: true, isFocused: true, isMiniListItemClicked: false });
      this.input.select();
      document.body.classList.add("symbol-entry-focused");
      e.preventDefault();
      e.stopPropagation();
    } else {
      this.setState({ ...this.state, isOpen: true, isMiniListItemClicked: false });
      this.props.handleSymbolEntryMouseDown();
    }
    ConsoleStore.trackUsage('SearchDropdownIsRevealed');
  }

  handleKeyDown(e) {
    clearTimeout(this.menuOpenTimeOut);
    clearTimeout(this.searchTimeOut);
    
    if (e.keyCode === KeyCodes.ENTER) {
      if (this.props.handleSymbolEntrySymbolChange) {
        if (this.state.activeSymbol !== undefined) {
          this.props.handleSymbolEntrySymbolChange(this.state.activeSymbol);
          this.setState({ isOpen: false, activeIndex: -1, activeSymbol: undefined, isFocused: false, });
        } else {
          this.props.handleSymbolEntrySymbolChange(this.props.symbol.replace(/^\s+|\s+$/g, ''));
          this.setState({ isOpen: false, activeIndex: -1, activeSymbol: undefined, isFocused: false });
        }
        this.input.blur();
        this.input.style.backgroundColor = "#FFFFFF";
        document.body.classList.remove("symbol-entry-focused");
      }
    }
    else if(this.state.isOpen && this.state.isFocused) {
      this.setState({ ...this.state, isOpen: true });
    }
    else if (!this.state.isOpen && this.state.isFocused) {
        this.setState({ ...this.state, isOpen: true });
        this.props.handleSymbolEntryMouseDown();
    }
    else {
      this.props.handleSymbolEntryChange(this.input.value);
      this.setState({ isOpen: true });
    }
    e.preventDefault();
    e.stopPropagation();
  }

  getErrorMsg() {
    return (
      <div className="auto-search-error">
        {EditBoxTranslateHelper.INVALID_SYMBOL}
        <span className="icn-close" onClick={this.props.closeSymbolEntryErrorDialog}></span>
      </div>
    );
  }

  render() {
    const menu = this.setIsOpenState();

      return <div className="editboxwrap">
                  {this.props.hasError && this.getErrorMsg()}
                  <div className={this.props.isLoading ? "editBox load-animation" : "editBox" } style={this.props.videoMode ? { height: "110px", width: "320px" } : {}}>
                      <input style={ this.props.videoMode ? { fontSize: "100px", height: "100%", width: "100%" } : {}}
                             id="SymbolEntryInput" autoComplete="off" ref={(ref) => (this.input = ref)}
                             value={this.props.symbol}
                             onMouseDown={this.handleMouseDown}
                             onKeyUp={this.handleKeyDown}
                             onChange={this.handleChange}>
                      </input>
                  </div>
                  {menu}
              </div>
  }
}
const EditBox = onClickOutside(DropDown);
EditBox.propTypes = {
  menuHeader: PropTypes.string.isRequired,
  menuItems: PropTypes.array,
};

const mapStateToProps = ({DatagraphReducers})=>{
  const { searchedSymbolsWidth, columnWidths, hasError, symbol, showRecentSymbol, recentSymbols, searchedSymbols } = DatagraphReducers.SymbolEntryReducer;
  const { videoMode, isLoading } = DatagraphReducers.DataGraphReducer;
  const menuHeader = showRecentSymbol ? EditBoxTranslateHelper.RECENT_SYMBOLS : EditBoxTranslateHelper.SEARCH_RESULTS;
  const menuItems = showRecentSymbol ? recentSymbols : searchedSymbols
  return { searchedSymbolsWidth, columnWidths, hasError, symbol, menuHeader, menuItems, videoMode, isLoading };
}

const mapDispatchToProps = (dispatch)=>({
  handleSymbolEntrySymbolChange: (symbol)=> dispatch(handleSymbolEntrySymbolChange(symbol)),
  handleSymbolEntryChange: (symbol, isOutsideClick)=> dispatch(handleSymbolEntryChange(false, symbol, isOutsideClick)),
  handleSymbolEntryMouseDown: ()=> dispatch(handleSymbolEntryChange(true)),
  closeSymbolEntryErrorDialog:()=> dispatch(closeSymbolEntryErrorDialog()),
})
export default connect(mapStateToProps, mapDispatchToProps)(EditBox);