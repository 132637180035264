import keyMirror from "keymirror";

export const ConsoleConstants = {
  ActionTypes: keyMirror({
    HANDLE_EXCEPTION: null,
    TOGGLE_SUPPORT_DIALOG: null, 
    TOGGLE_LIVE_SUPPORT_DIALOG: null,
    CHECK_SUPPORT_HOURS: null,  
    GET_METRIC_DEFINITION:null,
    ROUT_TO_GRAPH: null,
    GET_REST_METRIC_DEFINITION: null,
    POPULATE_CURRENCY: null,
    UPDATE_CURRENCY: null,
    CURRENCY_CONVERSION: null,
    START_PRINT: null,
    CANCEL_PRINT: null,
    CLOSE_PRINT_STATUS: null,
    TOGGLE_POPUP_ALERT : null,
    SET_ENTITLEMENTS_INFO: null,
    SET_CHART_LOADED: null,
    HANDLE_PRINT_ERROR_PAGE: null,
    SHOW_PRINT_POPUP: null
  })
};
