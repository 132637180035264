import { TabDataGraphKpiConstants } from "../Constants/TabDataGraphKpiConstants";
const { ActionTypes } = TabDataGraphKpiConstants
/*Actions */
export const processKPIIndicator = (isNotResize) => ({
    type: ActionTypes.PROCESS_KPI_INDICATOR_DATA,
    isNotResize
});
export const getKpiIndicatorData = (symbol, periodicity, targetcur) => ({
    type: ActionTypes.GET_KPI_INDICATOR_DATA,
    symbol, periodicity, targetcur
});
export const kpiAppearanceChanges = () => ({
    type: ActionTypes.KPI_APPEARANCE_CHANGED
});
export const onLineMouseOver = (kpiId, x, y) => ({
    type: ActionTypes.KPI_LINE_OVER,
    kpiId: kpiId, x, y
});
export const onLineMouseLeave = () => ({
    type: ActionTypes.KPI_LINE_LEAVE
});
export const onContextMenuClick = (kpiId) => ({
    type: ActionTypes.KPI_LINE_CONTEXT_CLICK,
    kpiId: kpiId
});
export const OnCloseContextMenu = () => ({
    type: ActionTypes.KPI_LINE_CONTEXT_CLOSE
});
export const OnClearKPIData = () => ({
    type: ActionTypes.KPI_CLEAR_STATE
});
