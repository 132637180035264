import { NupViewConstant } from "../Constants/NupViewConstants";
const { ActionTypes } = NupViewConstant;

export const handleSymbolChange = (item)=>({
    type: ActionTypes.NUP_SYMBOL_CHANGE,
    item
});

export const processList = () =>({
    type: ActionTypes.PROCESS_LIST
});

export const initNupChart = (firstVisibleIndex, lastVisibleIndex) =>({
    type: ActionTypes.INIT_NUP_CHART,
    firstVisibleIndex, lastVisibleIndex
});

export const updateSymbolList = (symbolList) =>({
    type: ActionTypes.UPDATE_NUP_SYMBOL_LIST,
    symbolList
});

export const updateSelection = (newSelection) =>({
    type: ActionTypes.UPDATE_NUP_SELECTION,
    newSelection
});

export const setScaleByPeriodicity = (periodicity, newScale) =>({
    type: ActionTypes.UPDATE_NUP_CHART_APPEARANCE,
    periodicity, newScale
});

export const updateShowStory = (showStory)=>({
    type: ActionTypes.UPDATE_SHOW_STORY,
    showStory
})

export const updateNupStory = (showStory)=>({
    type: ActionTypes.UPDATE_NUP_STORY,
    showStory
})

export const updateDimension = (dimension)=>({
    type: ActionTypes.UPDATE_CHART_DIMENSION,
    dimension
})

export const updateNupSinglePeriodicity = (periodicity) =>({
    type: ActionTypes.NUP_SINGLE_PERIODICITY_CHANGE,
    periodicity
})

export const updatePeriodicityButtonState = (disablePeriodicityButton) =>({
    type: ActionTypes.UPDATE_PERIODICITY_BUTTON_STATE,
    disablePeriodicityButton
})

export const updateNupPeriodicity = (periodicity) =>({
    type: ActionTypes.NUP_PERIODICITY_CHANGE,
    periodicity
})

export const updateNupColumnPeriodicityChange = (periodicityObj) =>({
    type: ActionTypes.NUP_COLUMN_PERIODICITY_CHANGE,
    periodicityObj
})

export const updateShowChart = (showChart) =>({
    type:ActionTypes.UPDATE_SHOW_CHART,
    showChart
}) 

export const updateNupSettings = (settings) =>({
    type:ActionTypes.UPDATE_NUP_SETTINGS,
    settings
}) 

export const updateEpsRps = (epsSelected) =>({
    type:ActionTypes.NUP_EPS_RPS,
    epsSelected
}) 

export const updateEpsRpsWithPeriodicity = (epsRps, periodicity) =>({
    type:ActionTypes.UPDATE_EPS_RPS_WITH_PERIODICITY,
    epsRps, periodicity
}) 

export const updateNupCharts = (nupCharts) =>({
    type:ActionTypes.UPDATE_NUP_CHART,
    nupCharts
}) 

export const updateNupChartSelected = (selected) =>({
    type:ActionTypes.UPDATE_NUP_SELECTED,
    selected
}) 


export const updatePeriodicityOptions = (PeriodicityOptions) =>({
    type:ActionTypes.UPDATE_PERIODICITY_OPTIONS,
    PeriodicityOptions
}) 

export const updateNupStore = () =>({
    type:ActionTypes.UPDATE_NUP_STORE
}) 

export const updateColsRows = (rowsCols) =>({
    type:ActionTypes.UPDATE_COLS_ROWS,
    rowsCols
}) 

export const updateNupListData = (listData, listId) =>({
    type:ActionTypes.UPDATE_NUP_LIST_DATA,
    listData, listId
})

export const updateRedraw = (redraw) =>({
    type:ActionTypes.UPDATE_NUP_REDRAW,
    redraw
})

export const updateNupVisibleItem = (visibleItems) =>({
    type:ActionTypes.UPDATE_NUP_VISIBLE_ITEM,
    visibleItems
})

export const processNupListData = (symbolList = null, isInitChart = true, selectedSymbol) =>({
    type:ActionTypes.PROCESS_NUP_LIST_DATA,
    symbolList, isInitChart, selectedSymbol
});

export const updateScaleByPeriodicity = (scaleByPeriodicity) =>({
    type:ActionTypes.UPDATE_NUP_SCALE_PERIODICY,
    scaleByPeriodicity
});

export const updateNasdaqBasic = (IsNASDAQBasic) =>({
    type: ActionTypes.UPDATE_NUP_ISNASDAQBASIC,
    IsNASDAQBasic
})

export const updateChartSelection = (selectedIndex, keyType) =>({
    type: ActionTypes.UPDATE_CHART_SELECTION,
    selectedIndex, keyType
})

export const updateSelectedIndex = (selectedIndex) =>({
    type: ActionTypes.UPDATE_SELECTED_INDEX,
    selectedIndex
})

export const updateNupListFlages = (symbolList, properties= [] ) =>({
    type: ActionTypes.UPDATE_NUP_LIST_FLAGES,
    symbolList, properties 
})

export const updateGlobalFlag = ( value = false ) =>({
    type: ActionTypes.UPDATE_GLOBAL_FLAG,
    value 
})

export const updateAllGlobalFlag = ( value = false ) =>({
    type: ActionTypes.UPDATE_ALL_GLOBAL_FLAG,
    value 
})

export const updateSelectAll = ( ) =>({
    type: ActionTypes.UPDATE_SELECT_ALL 
})

export const updateFlag = ( selectedIndex, value) =>({
    type: ActionTypes.UPDATE_NUP_FLAG,
    selectedIndex, value
})

export const shouldUpdateNupComponent = (shouldUpdateComponent = false) =>({
    type: ActionTypes.SHOULD_UPDATE_NUP_COMPONENT,
    shouldUpdateComponent
})

export const updateNupQuoteSymbol = (quoteSymbol) =>({
    type: ActionTypes.UPDATE_NUP_QUOTE_SYMBOL,
    quoteSymbol
})

export const resetNupState = () => ({
    type: ActionTypes.RESET_NUP_STATE
})

export const updateGlobalSelectedIndex = (globalSelectedIndex) => ({
    type: ActionTypes.UPDATE_NUP_GLOBAL_SELECTED_INDEX,
    globalSelectedIndex
})

export const updateIsScrollAvailable = (isScrollAvailable) => ({
    type: ActionTypes.UPDATE_NUP_IS_SCROLL_AVAILABLE,
    isScrollAvailable
})

export const updateNupIsNonImported = (isNonImported) =>({
    type: ActionTypes.NUP_IS_NON_IMPORTED,
    isNonImported
})

export const updateNupTheme = () =>({
    type: ActionTypes.UPDATE_NUP_THEME
})

export const updateIsResizing = (isResizing) =>({
    type: ActionTypes.UPDATE_NUP_RESIZING,
    isResizing
})

export const updateNupRTVolume = (RTVolume) =>({
    type: ActionTypes.UPDATE_NUP_RT_VOLUME,
    RTVolume
})

export const updateNupNodeCount = (nodeCount) =>({
    type: ActionTypes.UPDATE_NUP_NODE_COUNT,
    nodeCount
})