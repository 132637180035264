import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetLiveStreamUrlRequest extends BaseServiceRequest {

    constructor(livestreamdataid) {
        super();
        super.addRequestUri("livestreamrequesturl.pb");
        super.addRequestParameter("livestreamdataid", livestreamdataid.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "LiveStreamServerRequestResponse";
    }
}

export default GetLiveStreamUrlRequest;
