import React from "react";
import { connect } from 'react-redux';
import { Button } from "react-bootstrap";
import StringUtil from "StringUtil";
import { map } from "underscore";
import { setOpenGeographyStatus, setScrollPosition, handleBreadCrumClick } from 'Actions/ComparisonAndRotationMenuActions';
import CompareTabType from "CompareTabType";
import textWidth from "text-width";

export class BreadCrumContent extends React.Component {

  constructor() {
    super();
    this.splitString = this.splitString.bind(this);
    this.getTextWidth = this.getTextWidth.bind(this);
  }

  splitString(breadCrumList) {
    let badge = [], w = 260, breadCrumString = null;
    this.breadCrumListElement = document.getElementById('rotation_geography_list_breadcrumb');
    if (this.breadCrumListElement) w = this.breadCrumListElement.getBoundingClientRect().width;
    if (!StringUtil.isEmpty(breadCrumList)) {
      map(breadCrumList, (s, i) => {
        let spanClass = (breadCrumList.length == (i + 1)) ? null : 'filterBadgeElement';

        let onClickEvent = (event) => (breadCrumList.length == 1) ? null : (breadCrumList.length == (i + 1)) ? null : this.props.handleBreadCrumClick(event.target.innerHTML);
        if (s != null) {
          if (i == 0) breadCrumString = s.itemName;
          else breadCrumString = breadCrumString + ' :: ' + s.itemName;

          let len = this.getTextWidth(breadCrumString);
          badge.push(
            <span className="rotation-menu-title" key={i}> {((len > w) && i != 0) ? <br /> : ''} {(i != 0) ? ':: ' : ''}
              <span className={spanClass} onClick={() => onClickEvent(event)} key={i}>{s.displayName}</span>
            </span>)
        }
      }, this)
    }
    return badge;
  }
  getTextWidth (text) {
    let width = textWidth(text, {
      family: "14 'calibri'"
    });
    return width;
  }
  render() {
    const { breadCrumbItems } = this.props;

    return (
      <React.Fragment>
        <Button bsSize="small" onClick={() => this.props.setOpenGeographyStatus(true)}><span className="icn-globe"></span></Button>
        <div id="rotation_geography_list_breadcrumb" className="data-title-browser-filter">{this.splitString(breadCrumbItems)}</div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setOpenGeographyStatus: (status) => dispatch(setOpenGeographyStatus(status)),
  handleBreadCrumClick: (label) => dispatch(handleBreadCrumClick(label))
})

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const { GroupsPanelReducer } = state.comparisonAndRotationMenuPanel;
  const { breadCrumbItems } = selectedTab === CompareTabType.ComparisonGraph ? GroupsPanelReducer.ComparisonMenuStates : GroupsPanelReducer.RotationMenuStates;

  return { breadCrumbItems };
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumContent);

