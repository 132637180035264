import React, { Component } from "react";
import RestoreWarning from "./RestoreWarning.jsx";
import SettingAction from "../../Actions/SettingsActions.js";
import SettingStore from "SettingsStore";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import ConsoleStore from "ConsoleStore";
import LocalizationStore from 'LocalizationStore';
import DatagraphStore from "../../Stores/NavModules/NavDataGraph/DataGraphStore.js";

export default class Preference extends Component {
  constructor(props) {
    super(props);
    this.onRestoreDeafultClick = this.onRestoreDeafultClick.bind(this);
    this.state = {
      restoreWarning: false
    };
  }

  componentDidMount() {
    SettingStore.addChangeListener(this.settingStoreStateChange);
  }

  componentWillUnmount() {
    SettingStore.removeChangeListener(this.settingStoreStateChange);
  }

  settingStoreStateChange() {
    if (SettingStore.getCurrentAction() == SettingsConstants.ActionTypes.RESET_SETTINGS) {
      let settings = SettingStore.getConsoleSettings();
      let symbol = settings.NavDatagraphSettings.symbol;
      let newUrl = '#/' + ConsoleStore.getSymbolUrl(symbol);
      window.history.pushState(null, null, newUrl);
      // setTimeout(() => { location.reload(true); }, 100);
      location.reload(true);
    }
  }

  onRestoreDeafultClick() {
    this.setState({ restoreWarning: !this.state.restoreWarning });
  }
  cancelReset() {
    this.setState({ restoreWarning: false });
  }

  onReset() {
    SettingAction.onResetSettings();
  }

  getWarningContent() {
    let warning = this.state.restoreWarning ? <RestoreWarning key="warning" onCancel={() => this.cancelReset()}
      onReset={() => this.onReset()} /> : null;
    return warning;
  }

  render() {
    const consoleSettings = SettingStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const useCanvas = tabDataGraphSettings.useCanvas;
    const displayMessage = useCanvas ? "Use SVG" : "Use Canvas";
    const isCurrencyEntitled = DatagraphStore.isCurrencyEntitled();

    return (
      <React.Fragment>
        <div className="preference">
          <h6 className="cap">{LocalizationStore.getTranslatedData('set_pre_data', 'DATA')}</h6>
          <ul>
            <li>
              <a id="reportedEarnings" onClick={this.props.handleReportedEarningsChange}>
                <span className="icn-revenue"></span>
                <span className="text">{LocalizationStore.getTranslatedData('set_pre_re', 'Revenue & Earnings')}</span>
              </a>
            </li>
            <li>
              <a id="quoteService" onClick={this.props.handleChange}>
                <span className="icn-quote-service"></span>
                <span className="text">{LocalizationStore.getTranslatedData('set_pre_qs', 'Quote Service (DDE)')}</span>
              </a>
            </li>
          </ul>
          <div className="clearfix"></div>
        </div>
        <div className="preference">
          <h6 className="cap">{LocalizationStore.getTranslatedData('set_pre_gen', 'GENERAL')}</h6>
          <ul>
            <li>
              <a id="appearance" onClick={this.props.handleAppearanceChange}>
                <span className="icn-appearance"></span>
                <span className="text">{LocalizationStore.getTranslatedData('set_pre_aprn', 'Appearance')}</span>
              </a>
            </li>
            <li>
              <a id="notification" onClick={this.props.handleNotificationChange}>
                <span className="icn-notification"></span>
                <span className="text">{LocalizationStore.getTranslatedData('set_pre_na', 'Notifications and Alerts')}</span>
              </a>
            </li>
            {isCurrencyEntitled && 
            <li>
              <a id="currency" onClick={this.props.handleCurrencyChange}>
                <span className="icn-currency"></span>
                <span className="text">Currency Conversion</span>
              </a>
            </li>
            }
          </ul>
          <div className="clearfix"></div>
        </div>
        <div className="preference restore">
          <span onMouseDown={this.onRestoreDeafultClick} className="dialog-link">{LocalizationStore.getTranslatedData('resdef', 'Restore Defaults')}</span>
          <div className="clearfix"></div>
        </div>

        <div className="restore-warning">
          {this.getWarningContent()}
        </div>
      </React.Fragment>

    );
  }
}
