import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetCheckListSetsRequest extends BaseServiceRequest {

    constructor(ignoreFilterSet) {
        super();

        super.addRequestUri("checklistsets.pb");
        super.addRequestParameter("is", "1");
        super.addRequestParameter("ifs", ignoreFilterSet ? "1" : "0");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "CheckListTreeResponse";
    }
}

export default GetCheckListSetsRequest;