import LabelMenu from "LabelMenu";
import { PriceChartConstants } from "../../../../../Constants/PriceChartConstants";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { ActionTypes } = PriceChartConstants;

const PriceMenu = () => {
  const dispatch = useDispatch();
  const onBlockMenuItemClicked = (item) => {
    if (item) {
      dispatch({ type: ActionTypes.PRICE_MENU_ITEM_CLICK, item });
    }
  }

  const onMenuItemClicked = (item) =>{
    if(item){
      dispatch({ type: ActionTypes.UPDATE_FUNDAMENTAL_QTR, item})
    }
  }
  const { priceMenu, label } = useSelector((state) => state.DatagraphReducers.PriceMenuReducer);
  const isPricePanelDataReady = useSelector((state) => state.DatagraphReducers.DataGraphReducer.isPricePanelDataReady);
  return (isPricePanelDataReady ? <LabelMenu
    label={label}
    priceMenu={isPricePanelDataReady ? priceMenu : null}
    onClick={(item) => onBlockMenuItemClicked(item)}
  // commonStock={showMenu}
  onSelect={(item) => onMenuItemClicked(item)}
  // epsSettings={settings.EarningLineSetting} 
  /> : 
  <div className="symbol-chart-title">
    <pre className="text">
      {label}
    </pre>
  </div>);
}
export default PriceMenu;