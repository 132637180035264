import AppDispatcher from "AppDispatcher";
import { MenuDialogConstants } from "../../Constants/MenuDialogConstants.js";
import { EventEmitter } from "events";

const CHANGE_EVENT = "change";

let _states = {
  isOpen: false,
  eventKey: '',
  results: null
};

class HelpStoreClass extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    return _states;
  }

  isOpenDialog(data) {
    let eventKey = data.eventKey;
    _states = { isOpen: true, eventKey: eventKey };
  }


  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      case MenuDialogConstants.ActionTypes.HELP:
        this.isOpenDialog(data);
        this.emit(CHANGE_EVENT);
        break;
      default:
        return true;
    }
    return true;
  }
}


const HelpStore = new HelpStoreClass();
export default HelpStore;
