import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class AnnotationLayerUpsertRequest extends BaseServiceRequest {
    constructor(layerId, layerName, isDeleted, isDuplicate, layerNamePrefix, layerNameSuffix) {
        super();
        super.addRequestUri("annotationlayer.pb");
        super.addRequestParameter("lid", layerId);
        super.addRequestParameter("ln", layerName);
        super.addRequestParameter("ide", isDeleted ? "1" : "0");
        super.addRequestParameter("idu", isDuplicate ? "1" : "0");
        super.addRequestParameter("lnp", layerNamePrefix);
        super.addRequestParameter("lns", layerNameSuffix);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "AnnotationLayerUpsertResponse";
    }

}
