import AlertIcon from './AlertIcon';
import AnimationPlayPauseControl from './PriceMenu/AnimationPlayPauseControl';
import AnnotationPanel from '../../Annotation/AnnotationLeftPanel/AnnotationPanel';
import { connect } from 'react-redux';
import CorpEvents from "CorpEvents"
import ExternalDataEvents from '../../ReduxModules/TabDataGraph/PriceChart/ExternalDataEvents';
import ExternalDataLine from '../../ReduxModules/TabDataGraph/PriceChart/ExternalDataLine';
import FundamentalLines from './PriceMenu/FundamentalLines';
import IndexLine from './PriceMenu/IndexLine';
import LLRVisual from '../../../../../RayCustomControls/Chart/Visuals/LLRVisual';
import MALines from './PriceMenu/MALines';
import PatternRecognition from "PatternRecognition";
import { PriceChartConst } from "../../../../../Constants/PriceChartConstants.js";
import React from 'react';
import RSLines from './PriceMenu/RSLines';
import { showContextMenu } from '../../../../../Actions/PricePanelActions';
import SmartViewType from '../../../../../Constants/SmartViewType';
import StreetTargetPrice from './PriceMenu/StreetTargetPrice';
import StringUtil from '../../../../../Utils/StringUtil';
import { cancelConditionAlertDelete, deleteAllActiveAlerts } from '../../../../../Actions/AlertActions';

function PriceMenuLines(props) {
  const { IsExternalEventAvailable, IsExternalTimeAvailable, majorPeriodicity } = props;
  return (<>
    <IndexLine />
    <RSLines />
    <PatternRecognition />
    <CorpEvents />
    <MALines/>
    <StreetTargetPrice />
    <FundamentalLines lineType={PriceChartConst.EPS} />
    <FundamentalLines lineType={PriceChartConst.RPS} />
    <FundamentalLines lineType={PriceChartConst.BV} />
    <FundamentalLines lineType={PriceChartConst.CFS} />
    <FundamentalLines lineType={PriceChartConst.DIV} />
    <FundamentalLines lineType={PriceChartConst.FCF} />
    {IsExternalEventAvailable && <ExternalDataEvents periodocity={majorPeriodicity} />}
    {IsExternalTimeAvailable && <ExternalDataLine />}
    <AlertIcon />
    <LLRVisual />
    {props.TimeLine && props.TimeLine.length > 0 && props.SymbolType !== SmartViewType.FUND && <AnnotationPanel onContextMenu={(event)=> props.showContextMenu(event, props.dimension.top)}/>}
    <div id="dummyDiv" onContextMenu={(event) => props.showContextMenu(event, props.dimension.top)} style={{ left: "0", height: '100%', width: '100%', position: 'absolute' }}></div>
    {props.epsScrubberVisiblity && 
      props.IsEarningsAnimationDisplayed && 
      !props.IsEarningsAnimationDocked &&
      props.epsLineSettings.IsVisible && 
      <AnimationPlayPauseControl />}
  </>
  );
}
const mapStateToProps = ({ alertReducer, externalDataUploader, DatagraphReducers }) => {
  const { timeSeriesData_pricePane, eventSeriesData } = externalDataUploader.externalDataUploadSymbolReducer;
  const { IsEarningsAnimationDocked, epsScrubberVisiblity, IsEarningsAnimationDisplayed, fundamentalLineSettings } = DatagraphReducers.FundamentalLineReducer;
  const { majorPeriodicity, viewsSettings, SymbolType } = DatagraphReducers.DataGraphReducer;
  const IsExternalEventAvailable = eventSeriesData && !StringUtil.isEmpty(eventSeriesData) && viewsSettings.ExternalDataSettings;
  const IsExternalTimeAvailable = timeSeriesData_pricePane && !StringUtil.isEmpty(timeSeriesData_pricePane) && viewsSettings.ExternalDataSettings;

  const { isAndAlertDialogOpen, isOpenAlertCriteria, isPriceAlertDialogOpen, conditionTypeId, alertMessage, showCommonDialog } = alertReducer.AlertDialogReducer;
  const TimeLine = DatagraphReducers.TimeLineReducer.TimeLineData.dates;
  const { dimension } = DatagraphReducers.PriceChartReducer;
  return {
    IsExternalEventAvailable, IsExternalTimeAvailable, majorPeriodicity, IsEarningsAnimationDocked, epsScrubberVisiblity, 
    IsEarningsAnimationDisplayed,epsLineSettings: fundamentalLineSettings[PriceChartConst.EPS], isAndAlertDialogOpen, isOpenAlertCriteria, isPriceAlertDialogOpen, 
    conditionTypeId, alertMessage, showCommonDialog, TimeLine, SymbolType, dimension
  }
}

const mapDispatchToProps = (dispatch)=>({
  deleteAllActiveAlerts: ()=> dispatch(deleteAllActiveAlerts()),
  cancelConditionAlertDelete: ()=> dispatch(cancelConditionAlertDelete()),
  showContextMenu: (event, top) => dispatch(showContextMenu(event, top)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceMenuLines);