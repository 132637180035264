import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';
//import SymbolTypeEnum from 'SymbolType';

class GetComparisionChartDataRequest extends BaseServiceRequestIIS {
    constructor(serviceName) {
        super();
        this.serviceName = serviceName;
    }

    getServiceMethod() {
        return this.serviceName;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return 'ComparisonData';
    }
}

export default GetComparisionChartDataRequest;