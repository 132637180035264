import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetOwnershipReportRequestExt extends BaseServiceRequest {
    constructor(osId, ownerType = 0, orderString = "", showIndex = 0, seqNumber = 0, pageSize = 100, isTopOwner = false, isByOwnerType = false, resultCount = 100) {
        super();
        super.addRequestUri("ownershipreportext.pb");
        super.addRequestParameter("osid", osId ? osId.toString() : "13717");
        if (ownerType) {
            super.addRequestParameter("otype", ownerType);
        }
        if (orderString) {
            super.addRequestParameter("orderstr", orderString);
        }
        if (showIndex == 0 || showIndex == 1)
        {
            super.addRequestParameter("sindex", showIndex.toString());
        }
        if (seqNumber == 0 || seqNumber == 1)
        {
            super.addRequestParameter("seqnum", seqNumber.toString());
        }
        if (pageSize) {
            super.addRequestParameter("psize", pageSize.toString());
        }
        super.addRequestParameter("topowner", ((isTopOwner) ? 1 : 0));
        super.addRequestParameter("byotype", ((isByOwnerType) ? 1 : 0));
        if (resultCount) {
            super.addRequestParameter("rescount", resultCount.toString());
        }
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "OwnershipReportResponseExt";
    }
}

export default GetOwnershipReportRequestExt;
