import DatagraphHelper from "../../../Utils/DatagraphHelper";
import GraphApi from "../../../ServiceApi/Apis/GraphApi";
import LocalizationStore from "../../../Stores/Localization/LocalizationStore";
import moment from "moment";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import SettingsStore from "../../../Stores/ConsoleWindow/Settings/SettingsStore";
import UserInfoUtil from "../../../Utils/UserInfoUtil";
import { each, find } from 'underscore';
import { getChosenIds, newsState } from "../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors";
import { put, select, call, takeLatest } from "redux-saga/effects";

const { ActionTypes } = RiPanelConstants;

const sourceNameText = {
    "Financial Content": LocalizationStore.getTranslatedData("news_id1", "Financial Content"),
    "Investor's Business Daily": LocalizationStore.getTranslatedData("news_id5", "Investor's Business Daily™"),
    "Yahoo! Finance": LocalizationStore.getTranslatedData("news_id2", "Yahoo! Finance"),
    "Google News": LocalizationStore.getTranslatedData("news_id3", "Google News"),
    "Benzinga": LocalizationStore.getTranslatedData("news_id4", "Benzinga")
};

function* getNewsInfo({ symbolInfo }) {
    try {
        if (!symbolInfo || !symbolInfo.Symbol) {
            yield call(setNewsList);
            return;
        }

        let chosenIds = yield select(getChosenIds);

        if (chosenIds.length === 0) {
            const activeChosenIds = getActiveChosenIds();

            if (activeChosenIds) {
                chosenIds = checkChosenIds(activeChosenIds);
            }
            else {
                const defaultChosenIds = getDefaultChosenIds();
                chosenIds = checkChosenIds(defaultChosenIds);
            }
        }

        const strSourceIds = chosenIds.join(',');
        const result = yield GraphApi.GetNewsRequest(symbolInfo.Symbol, strSourceIds, symbolInfo.ExchangeCode);

        for (const item of result.news) {
            item.strPublishDt = yield call(getDisplayString, new Date(item.publishDt.toNumber()));
            item.sourceName = sourceNameText[item.sourceName];
        }

        yield call(setNewsList, result.news, chosenIds, symbolInfo);

    } catch (error) {
        console.log(`Error occured in RiPanelNewsInfo.js, getNewsInfo ${error}`)
    }
}

function* setNewsList(source = [], chosenIds = [], symbolInfo) {
    try {
        yield put({
            type: ActionTypes.SET_NEWS_INFO,
            newState: { listItems: source, chosenIds: chosenIds, isLoading: false, symbolInfo }
        });
    } catch (error) {
        console.log(`Error occured in RiPanelNewsInfo.js, setNewsList ${error}`)
    }
}

function getActiveChosenIds() {
    const riPanelNewSettings = getNewsSettings();

    if (typeof (riPanelNewSettings.ActiveNewsChosenIds) === 'string') {
        if (riPanelNewSettings.ActiveNewsChosenIds) {
            let activeChosenIds = riPanelNewSettings.ActiveNewsChosenIds.split(",");
            activeChosenIds = activeChosenIds.map((item) => parseInt(item));
            return activeChosenIds;
        } else {
            return null;
        }
    } else {
        return riPanelNewSettings.ActiveNewsChosenIds
    }
}

function getDefaultChosenIds() {
    const riPanelNewSettings = getNewsSettings();

    if (typeof (riPanelNewSettings.DefaultChosenIds) === 'string') {
        let defaultChosenIds = riPanelNewSettings.DefaultChosenIds.split(",");//.slice();
        defaultChosenIds = defaultChosenIds.map((item) => parseInt(item));
        return defaultChosenIds
    } else {
        return riPanelNewSettings.DefaultChosenIds;
    }
}

function checkChosenIds(chosenIds) {
    const chosenIdList = [];
    const userInfo = UserInfoUtil.getUserInfo();
    const newsSources = userInfo ? userInfo.newsSources : null;

    if (newsSources) {
        each(chosenIds, (item) => {
            if (find(newsSources, (newsItem) => newsItem.id === item)) {
                chosenIdList.push(item);
            }
        });
    }
    return chosenIdList;
}

function* getDisplayString(date) {
    const diffTime = new Date().getTime() - date.getTime();
    const hours = Math.max(diffTime / 1000 / 60 / 60, 0);

    if (hours < 1) {
        const minutes = parseInt(hours * 60, 10);
        const minutesText = minutes === 1 ? " min ago" : " mins ago";
        return minutes + minutesText;
    }
    else if (hours < 24) {
        const hoursText = parseFloat(hours) === 1 ? " hr ago" : " hrs ago";
        return parseInt(hours) + hoursText;
    }
    else {
        return yield call(getDateStringWithTimeZome, date);
    }
}

function getDateStringWithTimeZome(date) {
    const d = date.toString();
    const TimeZoneFormated = moment(date).format('llll');

    if (d.indexOf("(") > 0 && d.indexOf(")") > 0) {
        let timeZoneString = '';
        const timeZoneLetters = d.substr(d.indexOf("(") + 1, d.indexOf(")")).split(" ");

        each(timeZoneLetters, (letter) => {
            timeZoneString += letter.substring(0, 1);
        });

        return `${TimeZoneFormated} ${timeZoneString}`;
    }
    else {
        return TimeZoneFormated;
    }
}

function getNewsSettings() {
    const settings = SettingsStore.getConsoleSettings();
    let riPanelSettings = settings.NavDatagraphSettings.RelatedInformationSettings;

    riPanelSettings = DatagraphHelper.getSettingsObject(settings, riPanelSettings)

    return riPanelSettings.NewSettings;
}

function* getDefaultNews() {
    try {
        const defaultChosenIds = getDefaultChosenIds();
        yield put({
            type: ActionTypes.NEWS_INFO_SET_LOADING,
            isLoading: true
        })
        yield put({
            type: ActionTypes.NEWS_INFO_SET_DEFAULT_NEWS,
            defaultChosenIds
        })
    } catch (error) {
        console.log(`Error occured in RiPanelNewsInfo.js, getDefaultNews ${error}`)
    }
}

function* submitChanges({ chosenIds }) {
    try {
        const riPanelNewSettings = getNewsSettings();
        riPanelNewSettings.ActiveNewsChosenIds = chosenIds;
        const { symbolInfo } = yield select(newsState);
        yield put({
            type: ActionTypes.NEWS_INFO_SET_LOADING,
            isLoading: true
        })
        yield call(getNewsInfo, { symbolInfo });
    } catch (error) {
        console.log(`Error occured in RiPanelNewsInfo.js, submitChanges ${error}`)
    }
}

/*** watchers ***/

export function* watchGetNewsInfo() {
    yield takeLatest(ActionTypes.GET_NEWS_INFO, getNewsInfo)
}
export function* watcherGetDefaultNews() {
    yield takeLatest(ActionTypes.NEW_INFO_RESTORE_DEFAULT_NEWS, getDefaultNews)
}
export function* watcherSubmitChanges() {
    yield takeLatest(ActionTypes.NEWS_INFO_CHANGE_RI_NEWS, submitChanges)
}