import AppColorStore from "Stores/AppColor/AppColorStore";
import { connect } from 'react-redux';
import { TextControl } from '../Presentational/index';
import {
    handleDragEnd, handleAnchorDragStart, handleTextAnchorUp, handleTextAnchorDrag, handleDoubleClick,
    handleDragStart, handleDragMove, handleDeleteAnnotationDrawing, handleToggleContextMenu
} from '../../../../../../Actions/AnnotationActions';
import React, { Component } from 'react';

class GraphicsText extends Component {

    componentDidMount() {
        AppColorStore.addChangeListener(this.AppColorStoreStateChanged);
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.AppColorStoreStateChanged);
    }

    AppColorStoreStateChanged = () => {
        this.forceUpdate();
    }

    handleDragEnd = (e) => {
        this.props.handleDragEnd(e);
    }

    handleDoubleClick = (e) => {
        this.props.handleDoubleClick(e);
    }

    render() {
        const { text, index, lockImage, isApiReqResolved } = this.props;
        const { isDragging, activeAnchor, selectedAnnotId } = this.props.annotationChartModelReducer;
        return (

            <TextControl
                onContextMenu={this.props.onContextMenu}
                key={index}
                index={index}
                text={text}
                isDragging={isDragging}
                activeAnchor={activeAnchor}
                handleDragStart={this.props.handleDragStart}
                handleDragMove={this.props.handleDragMove}
                handleDragEnd={this.props.handleDragEnd}
                selectedAnnotId={selectedAnnotId}
                handleAnchorDragStart={this.props.handleAnchorDragStart}
                handleAnchorDragEnd={this.props.handleAnchorDragEnd}
                handleAnchorDragMove={this.props.handleAnchorDragMove}
                handleDoubleClick={this.handleDoubleClick}
                draggable={this.props.draggable}
                lockImage={lockImage}
                isApiReqResolved={isApiReqResolved}
                handleDeleteAnnotationDrawing={this.props.handleDeleteAnnotationDrawing}
            />
        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { textReducer, annotationChartModelReducer } = annotationReducers;
    const { TextModelInfo } = annotationReducers.annotationMenuReducer;
    return ({ textReducer, annotationChartModelReducer, TextModelInfo });
}

const mapDispatchToProps = (dispatch) => ({
    handleDragStart: (text) => dispatch(handleDragStart(text)),
    handleDragMove: (e) => dispatch(handleDragMove(e)),
    handleDragEnd: () => handleDragEnd(),
    handleAnchorDragStart: (anchor) => dispatch(handleAnchorDragStart(anchor)),
    handleAnchorDragEnd: () => dispatch(handleTextAnchorUp()),
    handleAnchorDragMove: (e) => dispatch(handleTextAnchorDrag(e)),
    handleDoubleClick: (e) => dispatch(handleDoubleClick(e)),
    handleDeleteAnnotationDrawing: (charCode) => { dispatch(handleDeleteAnnotationDrawing(charCode)), dispatch(handleToggleContextMenu(false, 0, 0, '')) }
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphicsText);