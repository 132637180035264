import { AnnotationConstant } from "../../Constants/AnnotationConstants";
import DatagraphHelper from "../../Utils/DatagraphHelper";
// import ConsoleActions from "../../Actions/ConsoleActions";
const { ActionTypes } = AnnotationConstant;
import { each } from 'underscore';
import SettingsStore from "SettingsStore";

const initialState = {
    LayersList: [],
    selectedLayer: null,
    showDeleteLayerConfirm: false,
    layerVisibilityConfirm: false,
    readOnlyLayerSelectConfirm: false,
    deletedLayer: {},
    modifiedLayers: [],
    isLayerOrderChange: false
}

const annotationLayerManagementReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.UPDATE_LAYER_INFO_SUCCESS:
            return {
                ...state,
                LayersList: action.LayersList,
                selectedLayer: action.selectedLayer,
                modifiedLayers: action.LayersList
            }
        case ActionTypes.SET_ACTIVE_LAYER: {
            const settings = SettingsStore.getConsoleSettings();
            const annotationSettings = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;
            annotationSettings.selectedLayerID = action.layer.layerID;
            SettingsStore.saveSettings();

            each(state.LayersList, (item) => {
                if (item.isSelected) {
                    item.isSelected = false;
                }
                if (item.layerID === action.layer.layerID) {
                    item.isSelected = true;
                }
            });
            const layersList = JSON.parse(JSON.stringify(state.LayersList));
            for (const x in layersList) {
                layersList[x].isSelected === true ? layersList.push(layersList.splice(x, 1)[0]) : 0;
            }
            return {
                ...state,
                selectedLayer: action.layer,
                modifiedLayers: layersList
            }
        }
        case ActionTypes.TOGGLE_VISIBILITY:
            {
                const isVisible = !action.layer.isVisible;
                if (state.selectedLayer.layerID === action.layer.layerID) {
                    state.selectedLayer.isVisible = isVisible;
                }

                each(state.LayersList, (item) => {
                    if (item.layerID === action.layer.layerID) {
                        item.isVisible = isVisible;
                    }
                });

                const settings = SettingsStore.getConsoleSettings();
                const LayersDict = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings.LayerSettings;
                each(LayersDict, (item, key) => {
                    if (parseInt(key) === action.layer.layerID) {
                        item.isVisible = isVisible;
                    }
                });
                SettingsStore.saveSettings();

                return {
                    ...state,
                    LayersList: [...state.LayersList],
                    modifiedLayers: [...state.LayersList]
                }
            }
        case ActionTypes.HANDLE_TOGGLE_LAYER_DELETE_POPUP:
            return {
                ...state,
                showDeleteLayerConfirm: action.showDialog
            }

        case ActionTypes.HANDLE_TOGGLE_LAYER_VISIBILTY_POPUP:
            return {
                ...state,
                layerVisibilityConfirm: action.showDialog
            }

        case ActionTypes.HANDLE_TOGGLE_LAYER_READONLY_POPUP:
            return {
                ...state,
                readOnlyLayerSelectConfirm: action.showDialog
            }

        case ActionTypes.HANDLE_ANNOTATION_ERROR:
            // const env = process.env.BRANCH_ENV;
            // if (env && (env.toUpperCase() !== "PROD"))
            //     ConsoleActions.exceptionHandle(`Error: ${action.errorMsg}`)
            return state;
        case ActionTypes.SAVE_LAYER_FOR_UNDO:
            return {
                ...state,
                deletedLayer: action.deletedLayer
            }
        case ActionTypes.REVOKE_LAYER_ON_UNDO_SUCCESS:
            return {
                ...state,
                LayersList: action.LayersList,
                selectedLayer: action.selectedLayer,
                modifiedLayers: action.LayersList
            }
        case ActionTypes.CLEAR_ANNOTATION_LAYERS:
            return {
                ...state,
                LayersList: [],
                selectedLayer: null,
                modifiedLayers: []
            };
        case ActionTypes.UPDATE_LAYERS_LIST:
            return {
                ...state,
                modifiedLayers: action.LayersList,
                isLayerOrderChange: true
            };
        default:
            return state
    }
}

export default annotationLayerManagementReducer;