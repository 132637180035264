import { connect } from 'react-redux';
import { initTimeLine } from '../../../../../Actions/TabDataGraphAction';
import RiPanelFactorDetail from './RiPanelFactorDetail';
import { ButtonGroup, Button, Checkbox } from "react-bootstrap";
import { FactorTranslateHelper, TranslateHelper } from '../../../../../Utils/TranslateHelper';
import React, { Component } from 'react';
import { switchFRVisibility, lastOnlyVisibility } from "../../../../../Actions/RelatedInfoPanelActions/RiPanelActions";

class RiPanelFactorInfo extends Component {
    constructor(props){
        super(props);
        this.switchOffVisibility = this.switchVisibility.bind(this, false);
        this.switchOnVisibility = this.switchVisibility.bind(this, true);
    }
    switchVisibility(isVisible){
        if(this.props.visibility !== isVisible){
            this.props.switchVisibility(isVisible);            
            if(isVisible && (this.props.factorsResultAvailable === null || this.props.factorsResultAvailable)){
                this.props.initTimeLine(this.props.factorsEndDate, this.props.nodeCount, this.props.InitialBufferSize, false, false, false);
            }
            else{
                this.props.initTimeLine(this.props.latestNodeDate, this.props.nodeCount, this.props.InitialBufferSize, false, false, false);
            }
        }
    }
    render() {
        const { visibility, lastOnly } = this.props;
        return (
            <div className="factorInfo">
                <div className="setting-bar">
                    <div className="setting-visibility">
                        <div>
                            {FactorTranslateHelper["VISIBILITY"]}
                        </div>
                        <ButtonGroup bsSize="xsmall">
                            <Button className={visibility ? '' : 'btn-off'} onClick={this.switchOffVisibility}>{TranslateHelper["OFF"]}</Button>
                            <Button className={visibility ? 'btn-on' : ''} onClick={this.switchOnVisibility}>{TranslateHelper["ON"]}</Button>
                        </ButtonGroup>
                    </div>
                    <div className="setting-history">
                        <Checkbox type="checkbox" id="factor-history" checked={lastOnly} onChange={() => this.props.switchLastOnly()}>
                            <label htmlFor="factor-history">{FactorTranslateHelper["SHOW_ACTIVE_EVENT"]}</label>
                        </Checkbox>
                    </div>
                </div>
                <div className="factor-detail">

                    <RiPanelFactorDetail />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ DatagraphReducers }) => {
    const { visibility, lastOnly, factorsResultAvailable } = DatagraphReducers.FactorResearchReducer;
    const { videoMode, latestNodeDate, nodeCount, InitialBufferSize, factorsEndDate } = DatagraphReducers.DataGraphReducer;
    return { visibility, lastOnly, videoMode, latestNodeDate, nodeCount, InitialBufferSize, factorsEndDate, factorsResultAvailable };
};

const mapDispatchToProps = (dispatch) => ({
    switchVisibility: (visible) => dispatch(switchFRVisibility(visible)),
    switchLastOnly: () => dispatch(lastOnlyVisibility()),
    initTimeLine: (date, nodeCount, InitialBufferSize, isMountainChart, isComparisionChart, isInitial) => dispatch(initTimeLine(date, nodeCount, InitialBufferSize, isMountainChart, isComparisionChart, isInitial))
});

export default connect(mapStateToProps, mapDispatchToProps)(RiPanelFactorInfo);