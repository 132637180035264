import PropTypes from 'prop-types';
import React, { Component } from "react";
import ThemeHelper from "ThemeHelper";
import SettingsStore from "SettingsStore";
import ThemeType from "ThemeType";

const colors = [
    'P000000FFFFFF', 'P00333399FFFF', 'P00003399CCFF', 'P340033CDCCFF', 'P330000FDBCBC', 'P5B1B00FFCC99', 'P333300FFFF9A', 'P00330099FF99',
    'P262626CCCCCC', 'P00666633FFFF', 'P013CB366CCFF', 'P3400669866FF', 'P670000FF0000', 'PAE3E00FDA57D', 'P676700FFFF00', 'P00660034FF33',
    'P4C4C4C999999', 'P00999900CCCC', 'P0066CC3399FF', 'P6600996734CC', 'P990000CC0100', 'PFC6901FD8548', 'P999900CBCB01', 'P08980009CB01',
    'P9999994C4C4C', 'P00CCCC009999', 'P3399FF0066CC', 'P6734CC660099', 'PCC0100990000', 'PFD8548FC6901', 'PCBCB01999900', 'P09CB01089800',
    'PCCCCCC262626', 'P33FFFF006666', 'P66CCFF013CB3', 'P9866FF340066', 'PFF0000670000', 'PFDA57DAE3E00', 'PFFFF00676700', 'P34FF33006600',
    'PFFFFFF000000', 'P99FFFF003333', 'P99CCFF000033', 'PCDCCFF340033', 'PFDBCBC330000', 'PFFCC995B1B00', 'PFFFF9A333300', 'P99FF99003300'];

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.onChangeComplete = this.onChangeComplete.bind(this);
        const { width } = props;
        this.state = {
            itemWidth: 12,
            itemHeight: 12,
            width: width ? width : 100,
            color: this.getColor(props)
            //colors : [
            //    '#000000', '#003333', '#000033', '#340033', '#330000', '#5B1B00', '#333300', '#003300',
            //    '#262626', '#006666', '#013CB3', '#340066', '#670000', '#AE3E00', '#676700', '#006600',
            //    '#4C4C4C', '#009999', '#0066CC', '#660099', '#990000', '#FC6901', '#999900', '#089800',
            //    '#999999', '#00CCCC', '#3399FF', '#6734CC', '#CC0100', '#FD8548', '#CBCB01', '#09CB01',
            //    '#CCCCCC', '#33FFFF', '#66CCFF', '#9866FF', '#FF0000', '#FDA57D', '#FFFF00', '#34FF33',
            //    '#FFFFFF', '#99FFFF', '#99CCFF', '#CDCCFF', '#FDBCBC', '#FFCC99', '#FFFF9A', '#99FF99']

        };
    }

    getColor(props) {
        const { color, defaultColor } = props;
        if(color == "defaultKPIcolor"){
            let consoleSetting = SettingsStore.getConsoleSettings();
            if (consoleSetting.currentTheme == ThemeType.Dark)
                return 'P9999994C4C4C';
            else
                return 'P4C4C4C999999';
        }
        if (color && colors.some((x) => x === color))
            return color;
        if (defaultColor)
            return defaultColor;
        return 'P34FF33006600';
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            color: this.getColor(props),
            shouldUpdate: true
        });
    }

    onChangeComplete(e) {
        if (this.props.onChangeComplete) {
            this.props.onChangeComplete(e.target.getAttribute("color"));
        }
    }

    getItemRenderer(item) {
        return (<div key={item}
            className={this.state.color === item ? "color-picker-item color-picker-item-active" : "color-picker-item"}
            style={{ width: this.state.itemWidth, height: this.state.itemHeight, backgroundColor: ThemeHelper.getThemedBrush(item), borderColor: "gray" }} color={item}
            onClick={this.onChangeComplete}>
        </div>)
    }

    render() {
        return (
            <div className="color-picker" style={{ width: this.state.width }}>
                {colors.map((item) => this.getItemRenderer(item))}
            </div>
        );
    }
}

ColorPicker.proptypes = {
    colors: PropTypes.array.isRequired,
    color: PropTypes.string.isRequired,
    itemHeight: PropTypes.number,
    itemWidth: PropTypes.string,
    width: PropTypes.string.isRequired,
    onChangeComplete: PropTypes.func.isRequired,
    defaultColor: PropTypes.string
};
