var TimeZoneMaps = [{
    "MicrosoftTimeZoneID": "Dateline Standard Time",
    "NodaTimeZoneID": "Etc/GMT+12"
}, {
    "MicrosoftTimeZoneID": "UTC-11",
    "NodaTimeZoneID": "Etc/GMT+11"
}, {
    "MicrosoftTimeZoneID": "Hawaiian Standard Time",
    "NodaTimeZoneID": "Pacific/Honolulu"
}, {
    "MicrosoftTimeZoneID": "Alaskan Standard Time",
    "NodaTimeZoneID": "America/Anchorage"
}, {
    "MicrosoftTimeZoneID": "Pacific Standard Time (Mexico)",
    "NodaTimeZoneID": null
}, {
    "MicrosoftTimeZoneID": "Pacific Standard Time",
    "NodaTimeZoneID": "America/Los_Angeles"
}, {
    "MicrosoftTimeZoneID": "US Mountain Standard Time",
    "NodaTimeZoneID": "America/Phoenix"
}, {
    "MicrosoftTimeZoneID": "Mountain Standard Time (Mexico)",
    "NodaTimeZoneID": "America/Chihuahua"
}, {
    "MicrosoftTimeZoneID": "Mountain Standard Time",
    "NodaTimeZoneID": "America/Denver"
}, {
    "MicrosoftTimeZoneID": "Central America Standard Time",
    "NodaTimeZoneID": "America/Guatemala"
}, {
    "MicrosoftTimeZoneID": "Central Standard Time",
    "NodaTimeZoneID": "America/Chicago"
}, {
    "MicrosoftTimeZoneID": "Central Standard Time (Mexico)",
    "NodaTimeZoneID": "America/Mexico_City"
}, {
    "MicrosoftTimeZoneID": "Canada Central Standard Time",
    "NodaTimeZoneID": "America/Regina"
}, {
    "MicrosoftTimeZoneID": "SA Pacific Standard Time",
    "NodaTimeZoneID": "America/Bogota"
}, {
    "MicrosoftTimeZoneID": "Eastern Standard Time (Mexico)",
    "NodaTimeZoneID": "America/Cancun"
}, {
    "MicrosoftTimeZoneID": "Eastern Standard Time",
    "NodaTimeZoneID": "America/New_York"
}, {
    "MicrosoftTimeZoneID": "US Eastern Standard Time",
    "NodaTimeZoneID": "America/Indiana/Indianapolis"
}, {
    "MicrosoftTimeZoneID": "Venezuela Standard Time",
    "NodaTimeZoneID": "America/Caracas"
}, {
    "MicrosoftTimeZoneID": "Paraguay Standard Time",
    "NodaTimeZoneID": "America/Asuncion"
}, {
    "MicrosoftTimeZoneID": "Atlantic Standard Time",
    "NodaTimeZoneID": "America/Halifax"
}, {
    "MicrosoftTimeZoneID": "Central Brazilian Standard Time",
    "NodaTimeZoneID": "America/Cuiaba"
}, {
    "MicrosoftTimeZoneID": "SA Western Standard Time",
    "NodaTimeZoneID": "America/La_Paz"
}, {
    "MicrosoftTimeZoneID": "Newfoundland Standard Time",
    "NodaTimeZoneID": "America/St_Johns"
}, {
    "MicrosoftTimeZoneID": "E. South America Standard Time",
    "NodaTimeZoneID": "America/Sao_Paulo"
}, {
    "MicrosoftTimeZoneID": "Argentina Standard Time",
    "NodaTimeZoneID": "America/Argentina/Buenos_Aires"
}, {
    "MicrosoftTimeZoneID": "SA Eastern Standard Time",
    "NodaTimeZoneID": "America/Cayenne"
}, {
    "MicrosoftTimeZoneID": "Greenland Standard Time",
    "NodaTimeZoneID": "America/Godthab"
}, {
    "MicrosoftTimeZoneID": "Montevideo Standard Time",
    "NodaTimeZoneID": "America/Montevideo"
}, {
    "MicrosoftTimeZoneID": "Bahia Standard Time",
    "NodaTimeZoneID": "America/Bahia"
}, {
    "MicrosoftTimeZoneID": "Pacific SA Standard Time",
    "NodaTimeZoneID": "America/Santiago"
}, {
    "MicrosoftTimeZoneID": "UTC-02",
    "NodaTimeZoneID": "Etc/GMT+2"
}, {
    "MicrosoftTimeZoneID": "Mid-Atlantic Standard Time",
    "NodaTimeZoneID": null
}, {
    "MicrosoftTimeZoneID": "Azores Standard Time",
    "NodaTimeZoneID": "Atlantic/Azores"
}, {
    "MicrosoftTimeZoneID": "Cape Verde Standard Time",
    "NodaTimeZoneID": "Atlantic/Cape_Verde"
}, {
    "MicrosoftTimeZoneID": "Morocco Standard Time",
    "NodaTimeZoneID": "Africa/Casablanca"
}, {
    "MicrosoftTimeZoneID": "UTC",
    "NodaTimeZoneID": "Etc/UTC"
}, {
    "MicrosoftTimeZoneID": "GMT Standard Time",
    "NodaTimeZoneID": "Europe/London"
}, {
    "MicrosoftTimeZoneID": "Greenwich Standard Time",
    "NodaTimeZoneID": "Atlantic/Reykjavik"
}, {
    "MicrosoftTimeZoneID": "W. Europe Standard Time",
    "NodaTimeZoneID": "Europe/Berlin"
}, {
    "MicrosoftTimeZoneID": "Central Europe Standard Time",
    "NodaTimeZoneID": "Europe/Budapest"
}, {
    "MicrosoftTimeZoneID": "Romance Standard Time",
    "NodaTimeZoneID": "Europe/Paris"
}, {
    "MicrosoftTimeZoneID": "Central European Standard Time",
    "NodaTimeZoneID": "Europe/Warsaw"
}, {
    "MicrosoftTimeZoneID": "W. Central Africa Standard Time",
    "NodaTimeZoneID": "Africa/Lagos"
}, {
    "MicrosoftTimeZoneID": "Namibia Standard Time",
    "NodaTimeZoneID": "Africa/Windhoek"
}, {
    "MicrosoftTimeZoneID": "Jordan Standard Time",
    "NodaTimeZoneID": "Asia/Amman"
}, {
    "MicrosoftTimeZoneID": "GTB Standard Time",
    "NodaTimeZoneID": "Europe/Bucharest"
}, {
    "MicrosoftTimeZoneID": "Middle East Standard Time",
    "NodaTimeZoneID": "Asia/Beirut"
}, {
    "MicrosoftTimeZoneID": "Egypt Standard Time",
    "NodaTimeZoneID": "Africa/Cairo"
}, {
    "MicrosoftTimeZoneID": "Syria Standard Time",
    "NodaTimeZoneID": "Asia/Damascus"
}, {
    "MicrosoftTimeZoneID": "E. Europe Standard Time",
    "NodaTimeZoneID": "Europe/Chisinau"
}, {
    "MicrosoftTimeZoneID": "South Africa Standard Time",
    "NodaTimeZoneID": "Africa/Johannesburg"
}, {
    "MicrosoftTimeZoneID": "FLE Standard Time",
    "NodaTimeZoneID": "Europe/Kiev"
}, {
    "MicrosoftTimeZoneID": "Turkey Standard Time",
    "NodaTimeZoneID": "Europe/Istanbul"
}, {
    "MicrosoftTimeZoneID": "Israel Standard Time",
    "NodaTimeZoneID": "Asia/Jerusalem"
}, {
    "MicrosoftTimeZoneID": "Kaliningrad Standard Time",
    "NodaTimeZoneID": "Europe/Kaliningrad"
}, {
    "MicrosoftTimeZoneID": "Libya Standard Time",
    "NodaTimeZoneID": "Africa/Tripoli"
}, {
    "MicrosoftTimeZoneID": "Arabic Standard Time",
    "NodaTimeZoneID": "Asia/Baghdad"
}, {
    "MicrosoftTimeZoneID": "Arab Standard Time",
    "NodaTimeZoneID": "Asia/Riyadh"
}, {
    "MicrosoftTimeZoneID": "Belarus Standard Time",
    "NodaTimeZoneID": "Europe/Minsk"
}, {
    "MicrosoftTimeZoneID": "Russian Standard Time",
    "NodaTimeZoneID": "Europe/Moscow"
}, {
    "MicrosoftTimeZoneID": "E. Africa Standard Time",
    "NodaTimeZoneID": "Africa/Nairobi"
}, {
    "MicrosoftTimeZoneID": "Iran Standard Time",
    "NodaTimeZoneID": "Asia/Tehran"
}, {
    "MicrosoftTimeZoneID": "Arabian Standard Time",
    "NodaTimeZoneID": "Asia/Dubai"
}, {
    "MicrosoftTimeZoneID": "Azerbaijan Standard Time",
    "NodaTimeZoneID": "Asia/Baku"
}, {
    "MicrosoftTimeZoneID": "Russia Time Zone 3",
    "NodaTimeZoneID": "Europe/Samara"
}, {
    "MicrosoftTimeZoneID": "Mauritius Standard Time",
    "NodaTimeZoneID": "Indian/Mauritius"
}, {
    "MicrosoftTimeZoneID": "Georgian Standard Time",
    "NodaTimeZoneID": "Asia/Tbilisi"
}, {
    "MicrosoftTimeZoneID": "Caucasus Standard Time",
    "NodaTimeZoneID": "Asia/Yerevan"
}, {
    "MicrosoftTimeZoneID": "Afghanistan Standard Time",
    "NodaTimeZoneID": "Asia/Kabul"
}, {
    "MicrosoftTimeZoneID": "West Asia Standard Time",
    "NodaTimeZoneID": "Asia/Tashkent"
}, {
    "MicrosoftTimeZoneID": "Ekaterinburg Standard Time",
    "NodaTimeZoneID": "Asia/Yekaterinburg"
}, {
    "MicrosoftTimeZoneID": "Pakistan Standard Time",
    "NodaTimeZoneID": "Asia/Karachi"
}, {
    "MicrosoftTimeZoneID": "India Standard Time",
    "NodaTimeZoneID": "Asia/Kolkata"
}, {
    "MicrosoftTimeZoneID": "Sri Lanka Standard Time",
    "NodaTimeZoneID": "Asia/Colombo"
}, {
    "MicrosoftTimeZoneID": "Nepal Standard Time",
    "NodaTimeZoneID": "Asia/Kathmandu"
}, {
    "MicrosoftTimeZoneID": "Central Asia Standard Time",
    "NodaTimeZoneID": "Asia/Almaty"
}, {
    "MicrosoftTimeZoneID": "Bangladesh Standard Time",
    "NodaTimeZoneID": "Asia/Dhaka"
}, {
    "MicrosoftTimeZoneID": "N. Central Asia Standard Time",
    "NodaTimeZoneID": "Asia/Novosibirsk"
}, {
    "MicrosoftTimeZoneID": "Myanmar Standard Time",
    "NodaTimeZoneID": "Asia/Rangoon"
}, {
    "MicrosoftTimeZoneID": "SE Asia Standard Time",
    "NodaTimeZoneID": "Asia/Bangkok"
}, {
    "MicrosoftTimeZoneID": "North Asia Standard Time",
    "NodaTimeZoneID": "Asia/Krasnoyarsk"
}, {
    "MicrosoftTimeZoneID": "China Standard Time",
    "NodaTimeZoneID": "Asia/Shanghai"
}, {
    "MicrosoftTimeZoneID": "North Asia East Standard Time",
    "NodaTimeZoneID": "Asia/Irkutsk"
}, {
    "MicrosoftTimeZoneID": "Singapore Standard Time",
    "NodaTimeZoneID": "Asia/Singapore"
}, {
    "MicrosoftTimeZoneID": "W. Australia Standard Time",
    "NodaTimeZoneID": "Australia/Perth"
}, {
    "MicrosoftTimeZoneID": "Taipei Standard Time",
    "NodaTimeZoneID": "Asia/Taipei"
}, {
    "MicrosoftTimeZoneID": "Ulaanbaatar Standard Time",
    "NodaTimeZoneID": "Asia/Ulaanbaatar"
}, {
    "MicrosoftTimeZoneID": "Tokyo Standard Time",
    "NodaTimeZoneID": "Asia/Tokyo"
}, {
    "MicrosoftTimeZoneID": "Korea Standard Time",
    "NodaTimeZoneID": "Asia/Seoul"
}, {
    "MicrosoftTimeZoneID": "Yakutsk Standard Time",
    "NodaTimeZoneID": "Asia/Yakutsk"
}, {
    "MicrosoftTimeZoneID": "Cen. Australia Standard Time",
    "NodaTimeZoneID": "Australia/Adelaide"
}, {
    "MicrosoftTimeZoneID": "AUS Central Standard Time",
    "NodaTimeZoneID": "Australia/Darwin"
}, {
    "MicrosoftTimeZoneID": "E. Australia Standard Time",
    "NodaTimeZoneID": "Australia/Brisbane"
}, {
    "MicrosoftTimeZoneID": "AUS Eastern Standard Time",
    "NodaTimeZoneID": "Australia/Sydney"
}, {
    "MicrosoftTimeZoneID": "West Pacific Standard Time",
    "NodaTimeZoneID": "Pacific/Port_Moresby"
}, {
    "MicrosoftTimeZoneID": "Tasmania Standard Time",
    "NodaTimeZoneID": "Australia/Hobart"
}, {
    "MicrosoftTimeZoneID": "Magadan Standard Time",
    "NodaTimeZoneID": "Asia/Magadan"
}, {
    "MicrosoftTimeZoneID": "Vladivostok Standard Time",
    "NodaTimeZoneID": "Asia/Vladivostok"
}, {
    "MicrosoftTimeZoneID": "Russia Time Zone 10",
    "NodaTimeZoneID": "Asia/Srednekolymsk"
}, {
    "MicrosoftTimeZoneID": "Central Pacific Standard Time",
    "NodaTimeZoneID": "Pacific/Guadalcanal"
}, {
    "MicrosoftTimeZoneID": "Russia Time Zone 11",
    "NodaTimeZoneID": "Asia/Kamchatka"
}, {
    "MicrosoftTimeZoneID": "New Zealand Standard Time",
    "NodaTimeZoneID": "Pacific/Auckland"
}, {
    "MicrosoftTimeZoneID": "UTC+12",
    "NodaTimeZoneID": "Etc/GMT-12"
}, {
    "MicrosoftTimeZoneID": "Fiji Standard Time",
    "NodaTimeZoneID": "Pacific/Fiji"
}, {
    "MicrosoftTimeZoneID": "Kamchatka Standard Time",
    "NodaTimeZoneID": null
}, {
    "MicrosoftTimeZoneID": "Tonga Standard Time",
    "NodaTimeZoneID": "Pacific/Tongatapu"
}, {
    "MicrosoftTimeZoneID": "Samoa Standard Time",
    "NodaTimeZoneID": "Pacific/Apia"
}, {
    "MicrosoftTimeZoneID": "Line Islands Standard Time",
    "NodaTimeZoneID": "Pacific/Kiritimati"
}];

export default TimeZoneMaps;