import React, { Component, Fragment } from "react";
import AddEmailAddress from "../Alerts/AddEmailAddress.jsx";
import AddMobileNumber from "../Alerts/AddMobileNumber.jsx";
import AlertStore from "../../Stores/Alerts/AlertStore.js";
import SettingsStore from "SettingsStore";
import AlertActions, { updateDataGraphAlertStates } from "../../Actions/AlertActions.js";
import { AlertConstants } from "AlertConstants";
import ValidationPendingDialog from "../Alerts/ValidationPendingDialog.jsx";
import _ from "underscore";
import UserInfoUtil from "UserInfoUtil"
import BaseServiceApi from "BaseServiceApi";
import StringUtil from "StringUtil";
import LocalizationStore from 'LocalizationStore';
import { dispatch } from "../../Redux/dispatch.js";


let EntitlementType = BaseServiceApi.rayData["EntitlementType"];

export default class NotificationContent extends Component {
  constructor(props) {
    super(props);
    let obj = _.clone(AlertStore.getNotificationSettings());
    let consoleSetting = SettingsStore.getConsoleSettings();

    this.state = {
      alertNotificationSettings: obj,
      userNotificationSettings: consoleSetting.UserNotificationSettings
    }

    let entitlements = UserInfoUtil.getUserInfo().entitlements;
    this.isSMSEditEntitlement = entitlements.includes(EntitlementType.ALERT_SMS_EDIT_Entitlement)
    this.isEmailEditEntitlement = entitlements.includes(EntitlementType.ALERT_EMAIL_EDIT_Entitlement)

    this.handleStateChange = this.handleStateChange.bind(this);
    this.openAddEmailDialog = this.openAddEmailDialog.bind(this);
    this.openAddMobileNumberDialog = this.openAddMobileNumberDialog.bind(this);
    this.hideAddEmailDialog = this.hideAddEmailDialog.bind(this);
    this.hideAddMobileNumberDialog = this.hideAddMobileNumberDialog.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.saveUserNotificationSettings = this.saveUserNotificationSettings.bind(this);
    this.handleChangeDailyNotificationLimit = this.handleChangeDailyNotificationLimit.bind(this);
    this.openEmailPopup = this.openEmailPopup.bind(this);
    this.openAddEmailValidationDialog = this.openAddEmailValidationDialog.bind(this);
    this.hideAddEmailValidationDialog = this.hideAddEmailValidationDialog.bind(this);
    this.OpenAddNewEmailDialog = this.OpenAddNewEmailDialog.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
  }

  handleStateChange() {
    this.setState(
      { alertNotificationSettings: _.clone(AlertStore.getNotificationSettings()) }
    );
  }

  UNSAFE_componentWillMount() {
    AlertStore.addChangeListener(this.handleStateChange, AlertConstants.ActionTypes.OPEN_NOTIFICATION_PREFERENCE);
    AlertStore.addChangeListener(this.handleStateChange, AlertConstants.ActionTypes.SAVE_NOTIFICATION_SUBSCRIPTION);

    AlertActions.openNotificationSettings();
  }

  componentWillUnmount() {
    AlertStore.removeChangeListener(this.handleStateChange, AlertConstants.ActionTypes.OPEN_NOTIFICATION_PREFERENCE);
    AlertStore.removeChangeListener(this.handleStateChange, AlertConstants.ActionTypes.SAVE_NOTIFICATION_SUBSCRIPTION);
  }

  openAddEmailDialog() {
    this.setState({
      openAddEmailDialog: true
    })
  }

  hideAddEmailDialog() {
    this.setState({
      openAddEmailDialog: false
    })
  }

  handleChangeDailyNotificationLimit(event) {
    let patt = new RegExp("[0-9]*");
    if(event.target.value == '' || (patt.test(event.target.value) && event.target.value <= 1000 && event.target.value >= 0))
    {
    let alertNotificationItem = this.state.alertNotificationSettings;
    alertNotificationItem.dailyNotificationLimit = parseInt(event.target.value);

    this.setState({
      alertNotificationSettings: alertNotificationItem
    });
    this.saveUserNotificationSettings();
  }
}
  saveUserNotificationSettings() {
    dispatch(updateDataGraphAlertStates({IsShowAlertsIcon: this.state.userNotificationSettings.IsShowAlertsIcon}))
    AlertActions.saveNotificationSettings(this.state.alertNotificationSettings, this.state.userNotificationSettings);
  }

  openAddEmailValidationDialog() {
    this.setState({
      openAddEmailValidationDialog: true
    })
  }

  hideAddEmailValidationDialog() {
    this.setState({
      openAddEmailValidationDialog: false
    })
  }


  handleCheckedChange(e) {

    if (e) {
      let alertNotificationItem = this.state.alertNotificationSettings;
      let userNotificationItem = this.state.userNotificationSettings;

      switch (e.target.name) {
        case 'rbNotifyByEmail':
          alertNotificationItem.notifyByEmail = !alertNotificationItem.notifyByEmail;
          break;
        case 'rbNotifyScheduledScreensByEmail':
          alertNotificationItem.notifyScheduledScreensByEmail = !alertNotificationItem.notifyScheduledScreensByEmail;
          break;
        case 'rbNotifyBySMS':
          alertNotificationItem.notifyBySMS = !alertNotificationItem.notifyBySMS;
          break;
        case 'rbNotifyInApp':
          alertNotificationItem.notifyInApp = !alertNotificationItem.notifyInApp;
          break;
        case 'rbIsAlertSoundOn':
          userNotificationItem.IsAlertSoundOn = !userNotificationItem.IsAlertSoundOn;
          break;
        case 'rbIsShowAlertsIcon':
          userNotificationItem.IsShowAlertsIcon = !userNotificationItem.IsShowAlertsIcon;
          break;
        default:
          break;
      }

      this.setState({
        alertNotificationSettings: alertNotificationItem,
        userNotificationSettings: userNotificationItem
      });

      this.saveUserNotificationSettings();
    }
  }

  openAddMobileNumberDialog() {
    if(this.isSMSEditEntitlement)
    {
      this.setState({
      openAddMobileNumberDialog: true
      })
    }
  }

  hideAddMobileNumberDialog() {
    this.setState({
      openAddMobileNumberDialog: false
    })
  }

  openEmailPopup() {
    if(this.isEmailEditEntitlement)
    {
      if (this.state.alertNotificationSettings.emailValid == 1)
        this.openAddEmailValidationDialog();
      else
        this.openAddEmailDialog();
    }

  }


  OpenAddNewEmailDialog() {
    this.hideAddEmailValidationDialog();

    this.openAddEmailDialog();
  }

  formatPhoneNumber(s) {
    var s2 = ("" + s).replace(/\D/g, '');
    var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
  }

  render() {
    let settingObj = this.state.alertNotificationSettings;
    let entitlements = UserInfoUtil.getUserInfo().entitlements;
    let on = LocalizationStore.getTranslatedData('ON', 'ON');
    let off = LocalizationStore.getTranslatedData('OFF', 'OFF');
    let alerts = LocalizationStore.getTranslatedData('FeatureAbout_Name_108', 'Alerts');
    return (

      <Fragment>
        {this.state.openAddEmailDialog ? <AddEmailAddress open={this.state.openAddEmailDialog} hideAddEmailDialog={this.hideAddEmailDialog} email={this.state.alertNotificationSettings.email} /> : ""}
        {this.state.openAddMobileNumberDialog ? <AddMobileNumber open={this.state.openAddMobileNumberDialog} hideAddMobileNumberDialog={this.hideAddMobileNumberDialog} phoneNumber={this.state.alertNotificationSettings.phoneNumber} phoneNumberValid={this.state.alertNotificationSettings.phoneNumberValid} /> : ""}
        {this.state.openAddEmailValidationDialog ? <ValidationPendingDialog open={this.state.openAddEmailValidationDialog} hideAddEmailValidationDialog={this.hideAddEmailValidationDialog} OpenAddNewEmailDialog={this.OpenAddNewEmailDialog} /> : ""}

        <div className="notification-content">
          <ul>
            {entitlements.includes(EntitlementType.ALERT_EMAIL_Entitlement) ?
              <li>
                <span className="text-block field-head">{LocalizationStore.getTranslatedData('set_pre_nap_ce', 'Communication email')} : </span>  <a style ={{cursor: !this.isEmailEditEntitlement ? "default" : "pointer"}} className="adding-link" onClick={this.openEmailPopup} >{settingObj.emailValid == 0 ? LocalizationStore.getTranslatedData('set_pre_nap_aea', 'Add Email Address') : (settingObj.emailValid == 1 ? LocalizationStore.getTranslatedData('set_pre_nap_vp', 'Validation Pending') : settingObj.email)}</a> 
                <div className="sub-fields">
                  <div className="sub-field-row">
                    <span className="text-block">{alerts} </span>
                    <span className="radio-on-block">
                      <input id="rbNotifyByEmailON" type="radio" disabled={settingObj.emailValid != 2} onChange={this.handleCheckedChange} checked={this.state.alertNotificationSettings.notifyByEmail == true} name="rbNotifyByEmail" />
                      <label htmlFor="rbNotifyByEmailON">{on}</label>
                    </span>
                    <span className="radio-off-block">
                      <input id="rbNotifyByEmailOFF" type="radio" disabled={settingObj.emailValid != 2} onChange={this.handleCheckedChange} checked={this.state.alertNotificationSettings.notifyByEmail == false} name="rbNotifyByEmail" />
                      <label htmlFor="rbNotifyByEmailOFF">{off}</label>
                    </span>
                  </div>
                  <div className="sub-field-row">
                    <span className="text-block">{LocalizationStore.getTranslatedData('ss_scheduledlists', 'Scheduled Lists')} </span>
                    <span className="radio-on-block">
                      <input id="rbNotifyScheduledScreensByEmailON" type="radio" disabled={settingObj.emailValid != 2} onChange={this.handleCheckedChange} checked={this.state.alertNotificationSettings.notifyScheduledScreensByEmail == true} name="rbNotifyScheduledScreensByEmail" />
                      <label htmlFor="rbNotifyScheduledScreensByEmailON">{on}</label>
                    </span>
                    <span className="radio-off-block">
                      <input id="rbNotifyScheduledScreensByEmailOFF" type="radio" disabled={settingObj.emailValid != 2} onChange={this.handleCheckedChange} checked={this.state.alertNotificationSettings.notifyScheduledScreensByEmail == false} name="rbNotifyScheduledScreensByEmail" />
                      <label htmlFor="rbNotifyScheduledScreensByEmailOFF">{off}</label>
                    </span>
                  </div>
                </div>
              </li> : ""}
            {entitlements.includes(EntitlementType.ALERT_SMS_Entitlement) ?
              <li>
                <span className="text-block field-head">{LocalizationStore.getTranslatedData("set_pre_nap_spn","SMS phone number")} : </span> <a style ={{cursor: !this.isSMSEditEntitlement ? "default" : "pointer"}} className="adding-link" onClick={this.openAddMobileNumberDialog}>{settingObj.phoneNumberValid == 0 || StringUtil.isEmpty(settingObj.phoneNumber) ? LocalizationStore.getTranslatedData("set_pre_nap_amn","Add Mobile Number") :  settingObj.phoneNumber}</a>
                  <div className="sub-fields">
                    <div className="sub-field-row">
                      <span className="text-block">{alerts} </span>
                      <span className="radio-on-block">
                        <input id="radio2ON" type="radio" onChange={this.handleCheckedChange} disabled={settingObj.phoneNumberValid == 0} name="rbNotifyBySMS" checked={this.state.alertNotificationSettings.notifyBySMS == true} />
                        <label htmlFor="radio2ON">{on}</label>
                      </span>
                      <span className="radio-off-block">
                        <input id="radio2Off" type="radio" onChange={this.handleCheckedChange} disabled={settingObj.phoneNumberValid == 0} name="rbNotifyBySMS" checked={this.state.alertNotificationSettings.notifyBySMS == false} />
                        <label htmlFor="radio2Off">{off}</label>
                      </span>
                    </div>
                  </div>
              </li> : ""}
            <li>
            <span className="text-block">{LocalizationStore.getTranslatedData('set_pre_nap_nw', 'Notification Window')}</span>
              <span className="radio-on-block">
                <input id="radio3ON" type="radio" onChange={this.handleCheckedChange} name="rbNotifyInApp" checked={this.state.alertNotificationSettings.notifyInApp == true} />
                <label htmlFor="radio3ON">{on}</label>
              </span>
              <span className="radio-off-block">
                <input id="radio3Off" type="radio" onChange={this.handleCheckedChange} name="rbNotifyInApp" checked={this.state.alertNotificationSettings.notifyInApp == false} />
                <label htmlFor="radio3Off">{off}</label>
              </span>
            </li>
            <li>
              <span className="text-block">{LocalizationStore.getTranslatedData('set_pre_nap_ps', 'Play Sounds')}</span>
              <span className="radio-on-block">
                <input id="radio4On" type="radio" onChange={this.handleCheckedChange} name="rbIsAlertSoundOn" checked={this.state.userNotificationSettings.IsAlertSoundOn == true} />
                <label htmlFor="radio4On">{on}</label>
              </span>
              <span className="radio-off-block">
                <input id="radio4Off" type="radio" onChange={this.handleCheckedChange} name="rbIsAlertSoundOn" checked={this.state.userNotificationSettings.IsAlertSoundOn == false} />
                <label htmlFor="radio4Off">{off}</label>
              </span>
            </li>

            <li>
              <span className="text-block">{LocalizationStore.getTranslatedData('set_pre_nap_ai', 'Alert Icons')}</span>
              <span className="radio-on-block">
                <input id="radio5On" type="radio" onChange={this.handleCheckedChange} name="rbIsShowAlertsIcon" checked={this.state.userNotificationSettings.IsShowAlertsIcon == true} />
                <label htmlFor="radio5On">{LocalizationStore.getTranslatedData('set_pre_nap_v', 'VISIBLE')}</label>
              </span>
              <span className="radio-off-block">
                <input id="radio5Off" type="radio" onChange={this.handleCheckedChange} name="rbIsShowAlertsIcon" checked={this.state.userNotificationSettings.IsShowAlertsIcon == false} />
                <label htmlFor="radio5Off">{LocalizationStore.getTranslatedData('set_pre_nap_h', 'HIDDEN')}</label>
              </span>
            </li>
            <li>
            <span className="text-block">{LocalizationStore.getTranslatedData('set_pre_nap_desl', 'Daily Emails/SMS Limit')}</span>
              <span className="radio-on-block"><input className="get-info-by-text-sms disableDefaultSymbolInput" type="number"
                value={this.state.alertNotificationSettings.dailyNotificationLimit}
                onChange={this.handleChangeDailyNotificationLimit} /></span>
              <span className="radio-off-block"></span>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}