import React from "react";
import TabDataGraphAction from "../../Actions/TabDataGraphAction.js";
import SettingsAction from "../../Actions/SettingsActions";
import ServerInfoUtil from "../../Utils/ServerInfoUtil.js";
import DdeStatusType from "../../Constants/DdeStatusType.js";
import RealTimeQuoteType from "../../Constants/RealTimeQuoteType.js";
import WebSyncUtil from "../../Utils/WebSyncUtil.js";
import WorkSpaceRealTimePrices from "../../Utils/RealTimeHelper/WorkSpaceRealTimePrices.js";
import BaseServiceApi from 'BaseServiceApi';
import SettingsStore from "SettingsStore";
import LocalizationStore from 'LocalizationStore';
import UserInfoUtil from "UserInfoUtil";
import { dispatch } from "../../Redux/dispatch.js";
import { updateChartOnStreaming } from "../../Actions/PricePanelActions.js";

var EntitlementType = BaseServiceApi.rayData["EntitlementType"];

var IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];

export default class DDEConversionSettingContent extends React.Component {
    constructor(props) {
        super(props);
        this.tick = this.tick.bind(this);
    }

    SetONeilServerStatus(item) {
        let ddeStatus = "",
            brushColor = "",
            dotColor = "",
            serverInfo = null,
            selectedDDEService = "",
            pop = false;

        if (item) {
            if (isNaN(item.Id)) {
                item.Id = 0;
            }
            this.props.onSelected(item.Id);
            selectedDDEService = item.Id;
            pop = true;

        }
        else {
            selectedDDEService = SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption;
        }
        let userOk = true;
        if (UserInfoUtil.getUserInfo()) {
            const entitlements = UserInfoUtil.getUserInfo().entitlements;
            if (!WebSyncUtil.IsConnected) {
                userOk = entitlements.includes(EntitlementType.NASDAQ_BASIC_WEB_Entitlement);
            }
        }

        if (WebSyncUtil.IsConnected) {
            if (WorkSpaceRealTimePrices.IsNasdaqMarketHours() && selectedDDEService === IntradaySourceType.IntradaySource_NASDAQ) {
                ddeStatus = LocalizationStore.getTranslatedData('sc_st', DdeStatusType.STREAMING);
                serverInfo = new ServerInfoUtil(RealTimeQuoteType.Nasdaq);
                dotColor = 'icn-green-dot';
                brushColor = "green";
            }
            else {
                ddeStatus = LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED);
                serverInfo = new ServerInfoUtil(RealTimeQuoteType.Delayed);
                dotColor = 'icn-green-dot';
                brushColor = "green";
            }
        }
        else {
            serverInfo = new ServerInfoUtil(RealTimeQuoteType.Delayed);
            ddeStatus = userOk ? LocalizationStore.getTranslatedData('disconnected', DdeStatusType.NOT_CONNECTED) : LocalizationStore.getTranslatedData('connected', DdeStatusType.CONNECTED);
            dotColor = userOk ? 'icn-red-dot' : 'icn-green-dot';
            brushColor = userOk ? "red" : "green";
        }
        this.setState({ DdeStatus: ddeStatus, BrushColor: brushColor, SelectedDDeConnectType: selectedDDEService });
        SettingsAction.setDDEToolTip({
            Name: serverInfo.Name,
            Desc: serverInfo.Desc,
            Status: ddeStatus,
            BrushColor: brushColor,
            QuoteStatusGrid: dotColor,
            IsPop: pop,
            SelectedDDeConnectType: selectedDDEService
        });
        dispatch(updateChartOnStreaming(ddeStatus));
        // TabDataGraphAction.onReloadChart(ddeStatus);
    }

    UNSAFE_componentWillMount() {
        this.SetONeilServerStatus();
    }

    componentDidMount() {
        //this.timer = setInterval(this.tick, 3000);
    }

    componentWillUnmount() {
        //clearInterval(this.timer);
    }

    tick() {
        if (!WebSyncUtil.IsConnected) {
            this.SetONeilServerStatus();
        }
    }

    render() {
        let selectOption = this.props.SelectedDDeConnectType;
        let panarayRtSourceList = this.props.DataSource.PanarayRtSourceList;
        if (panarayRtSourceList !== null) {
            let strResKey = "DDECategory_";
            panarayRtSourceList.forEach((item) => {
                item.CategoryName = LocalizationStore.getTranslatedData(strResKey + item.CategoryId, item.CategoryName);
                if (isNaN(item.Id)) {
                    item.Id = 0;
                }
                if (item.Id === selectOption) {
                    item.DDeInfosList.Selected = true;
                } else {
                    item.DDeInfosList.Selected = false;
                }
            });
            if (panarayRtSourceList.length > 0) {
                return (
                    <div className="quote-service">
                        <h5>
                            {LocalizationStore.getTranslatedData('set_pre_qs_st', 'STATUS: ')}
                            <span className={"connection " + this.state.BrushColor}>{this.state.DdeStatus}</span>
                        </h5>
                        <p>{LocalizationStore.getTranslatedData('set_pre_qs_ssp', 'Select a Service Provider: ')}</p>
                        <ul className="quote-source">
                            <li>
                                <a>{LocalizationStore.getTranslatedData('DDECategory_1', 'ONeil Services')}</a>
                                <ul className="info-list">
                                    {
                                        panarayRtSourceList.map((item) => {
                                            if (item.CategoryId === 1) {
                                                if (item.DDeInfosList.Selected) {
                                                    return (
                                                        <li className="selected"
                                                            key={item.Id}
                                                            onClick={this.SetONeilServerStatus.bind(this, item)}>
                                                            <i className="icn-checkmark"></i>
                                                            {item.DDeInfosList.Name}
                                                        </li>
                                                    );
                                                }
                                                else {
                                                    return (
                                                        <li className="normal"
                                                            key={item.Id}
                                                            onClick={this.SetONeilServerStatus.bind(this, item)}>
                                                            {item.DDeInfosList.Name}
                                                        </li>
                                                    );
                                                }
                                            }
                                        })
                                    }
                                </ul>
                            </li>
                        </ul>
                    </div>
                );
            }
        }
        else {
            return (
                null
            );
        }
    }
}