import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetPrintCancelRequest extends BaseServiceRequest {
    constructor(requestId) {
        super();
        super.addRequestUri("cancelprint.json");
        super.addRequestParameter('requestid', requestId);
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return 'PrintCancelResponse';
    }
}

export default GetPrintCancelRequest;