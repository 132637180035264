import keyMirror from "keymirror";

export const RotationGraphConstants = {
  ActionTypes: keyMirror({
    GET_QUAD_DATA: null,
    SET_SYMBOLS_QUAD_DATA: null,
    SET_GROUPS_QUAD_DATA: null,
    UPDATE_GLOBAL_FLAG: null,
    GET_BENCHMARK_DATA: null,
    GET_GEOGRAPHY_DATA: null,
    GET_GLOBAL_TREND_COUNT_DATA: null,
    GET_GROUPS_INSTRUMENT_DATA: null,
    GET_GROUPS_MAIN_DATA: null,
    GET_LIQ_FILTER_DATA: null,
    GET_INDUSTRY_DATA: null,
    UPDATE_SECTORLIST: null,
    ROUTE_TO_ROTATION_GRAPH: null,
    UPDATE_LIST_ITEM_VISIBILITY: null,
    ROTATION_MENU_LOADING: null,
    IS_PLAY: null,
    SET_SLIDER_VALUE: null,
    LIQ_FILTER_CHANGE: null,
    BENCHMARK_LOADING: null,
    SET_CONSOLE_ROUT: null,
    INIT_SELECTED_SLIDERVALUE: null,
    UPDATE_FROM_USER_SETTINGS_DATA: null,
    UPDATE_DIMENSION_CHANGE: null,
    UPDATE_BENCHMARK_DIMENSION_CHANGE: null,
    INITIALIZE_TIME_LINE: null,
    UPDATE_BENCHMARKDATA_LOADING_STATUS: null,
    UPDATE_BENCHMARK_DATA: null,
    TOGGLE_TREND_LINES: null,
    UPDATE_ZOOM_PERCENTAGE: null,
    UPDATE_CONTEXT_MENU_VISIBILTY: null,
    PREPARE_CONTEXT_MENU_VIEW: null,
    ON_CONTEXT_MENU_ITEM_CLICK: null,
    STOP_IS_PLAYNG: null,
    RESET_ZOOM_VALUE: null,
    INIT_GROUPS_MAIN_DATA: null,
    HANDLE_REFRESH: null,
    PREPARE_POINTS_TO_RENDER: null,
    UPDATE_TIMELINE_INDEX: null,
    UPDATE_IS_TIMELINE_PLAYING: null,
    UPDATE_IS_SLIDER_RESET: null,
    RESET_TIMELINE_PLAYING: null,
    TOGGLE_BENCHMARK_SELECTOR: null,
    TOGGLE_BENCHMARK_SELECTOR_SUCCESS: null,
    UPDATE_BENCHMARK_INDEX_CODE: null,
    UPDATE_GROUPS_TIMELINE: null,
    HIGHLIGHT_SYMBOLS_ITEM_SUCCESS: null,
    HIGHLIGHT_GROUPS_ITEM_SUCCESS: null,
    UPDATE_GROUPS_COUNT_DATA_LIST: null,
    CLEAR_SYMBOLS_QUAD_DATA: null
  })
};

export const ComparisonAndRotationMenuConstants = {
  ActionTypes: keyMirror({
    INIT_COMP_GROUPS_MENU: null,
    INIT_ROTATION_GROUPS_MENU: null,
    SET_BREADCRUM_ITEMS: null,
    SET_SYMBOLS_FILTERDATA: null,
    SET_SYMBOLS_LISTITEMS: null,
    SET_GROUPS_LISTITEMS: null,
    SET_SYMBOLS_STOCK_LISTITEMS: null,
    SET_GROUPS_STOCK_LISTITEMS: null,
    LIST_ITEM_HIGHLIGHT_SELECTED: null,
    COMPARISON_LIST_ITEM_HIGHLIGHT_SELECTED: null,
    ROTATION_LIST_ITEM_HIGHLIGHT_SELECTED: null,
    SET_COMP_STATE: null,
    SET_GEOGRAPHY_BY_COUNTRY_CODE_AND_COUNTRY_NAME: null,
    GROUPS_LIST_ITEM_CLICK: null,
    SET_ROTATION_MENU_TYPE: null,
    HANDLE_BREADCRUM_CLICK: null,
    SET_GROUPS_MENU_CURRENT_ACTION: null,
    SET_OPEN_GEOGRAPHY_STATUS: null,
    SET_GEOGRAPHY_BY_COUNTRY_CODE: null,
    TOGGLE_RI_PANEL: null,
    INITIALIZE_DATA: null,
    INIT_RI_PANEL_SETTINGS: null,
    UPDATE_GROUPS_MAIN_DATA: null,
    UPDATE_GLOBAL_TREND_DATA: null,
    UPDATE_GEOGRAPHY_DATA: null,
    UPDATE_SYMBOLS_QUAD_GRAPH_RESULT: null,
    UPDATE_GROUPS_QUAD_GRAPH_RESULT: null,
    PREPARE_GEOGRAPHY_DATA: null,
    POPULATE_LIST_ITEM: null,
    UPDATE_FLAG: null,
    UPDATE_FLAGGED_LIST_ITEMS: null,
    LOAD_GROUPS_PANEL_MENU: null,
    LOAD_SYMBOLS_PANEL_MENU: null,
    SET_SCROLL_POSITION: null,
    UPDATE_MENU_ID: null,
    INIT_LIQ_FILTER_VALUES: null,
    UPDATE_RATING_FILTER__TITLE: null,
    RESET_LIQ_DATA: null,
    UPDATE_RATING_FILTER: null,
    UPDATE_RATING_FILTER_TITLE: null,
    SAVE_LIQ_FILTER_VALUE: null,
    SAVE_LIQ_FILTER_DATA_TO_SETTINGS: null,
    SET_IS_GLOBAL_RATING_SELECTED: null,
    SET_IS_COUNTRY_RATING_SELECTED: null,
    SET_SELECTED_RATINGS_OPTION: null,
    INIT_LIQUIDITY_FILTER_DATA: null,
    ON_KEYPRESS_UPDATE_SLIDER: null,
    ON_KEY_PRESS_UPDATE_SLIDER: null,
    INIT_LIQ_VIEW_MODEL: null,
    TOGGLE_LIQ_ON_OFF: null,
    TOGGLE_LIQ_BUTTON: null,
    UPDATE_DATA_FILTER_RANGE: null,
    UPDATE_SELECTED_RATING_FILTER_TYPE: null,
    GET_LIQUIDITY_RATING_FILTER: null,
    INIT_LIQ_FILTER: null,
    INIT_LIQ_FILTER_DATA: null,
    SET_FILTER_ON: null,
    SET_FILTER_MANUAL_OFF: null,
    UPDATE_ACTIVESLIDER: null,
    UPDATE_SELECTED_RATING_FILTERTYPE: null,
    UPDATE_DATA_FILTERRANGE: null,
    UPDATE_AVOL_DATA_FILTERRANGE: null,
    UPDATE_FILETR_TYPE1_DATA_FILTERRANGE: null,
    UPDATE_FILETR_TYPE2_DATA_FILTERRANGE: null,
    LIQ_SHOW_SETTING_WINDOW: null,
    TOGGLE_LIQ_FILTER: null,
    UPDATE_LIQ_FILTER_ICON_STATE: null,
    CLEAR_ALL_WITHOUT_CONFIRM: null,
    REMOVE_LIST_ITEMS: null,
    ADD_LIST_SYMBOLS: null,
    SET_COMPARE_MENUTAB: null,
    SET_MENU_STATE: null,
    GET_COMPSYMBOLS_STATE: null,
    SET_COMPSYMBOLS_STATE: null,
    SET_COMPGROUPS_STATE: null,
    GET_COMPGROUPS_STATE: null,
    SET_COMP_SYMBOLS_STATE: null,
    SET_COMP_GROUPS_STATE: null,
    SET_LISTALERT_MSG: null,
    SHOW_LISTALERT_MSG: null,
    SET_CALENDAR_STATE: null,
    SET_COMPARECHART_STATE: null,
    SET_CHARTVIEW_STATE: null,
    CLEAR_ALL: null,
    MENU_LOADING: null,
    CLEAR_COMPARISON_SYMBOLS_DATA: null,
    CLEAR_ROTATION_SYMBOLS_DATA: null,
    CLEAR_ALL_SUCCESS: null,
    SEARCH_SYMBOL_API_SUCCESS: null,
    SEARCH_SYMBOL_API: null,
    SHOW_CONTEXT_MENU: null,
    SHOW_CONTEXT_MENU_SUCCESS: null,
    HIDE_CONTEXT_MENU: null,
    CONTEXT_MENU_ITEM_CLICK: null,
    REMOVE_LIST_ITEM: null,
    FILTERED_INDEX_REQUEST_DATA: null,
    FILTERED_SYMBOL_REQUEST_DATA: null,
    REMOVE_COMPARISON_LIST_ITEM: null,
    REMOVE_ROTATION_LIST_ITEMS: null,
    HIDE_DROPDOWN: null,
    ADD_SYMBOL: null,
    ADD_TO_LIST: null,
    CLEAR_SYMBOL_SEARCH_TEXT: null,
    TOGGLE_CLEAR_ALL_DIALOG: null,
    SEARCH_SYMBOL_API_ON_ENTER: null,
    ADD_SYMBOL_ON_ENTER: null,
    ADD_LIST_ITEM: null,
    ADD_SYMBOL_BY_INDEX: null,
    ADD_INDEX_LINE: null,
    SET_ADDLIST_MODAL_SHOW_STATUS: null,
    SET_ADDLIST_LISTNAME: null,
    ADD_SYMBOLS: null,
    SET_LIST_ACTIONS: null,
    HANDLE_HIGHLIGHT: null,
    ON_RENAME: null,
    INIT_COMP_CHART: null,
    INIT_COMP_SYMBOLS_MENU: null,
    INIT_ROTATION_SYMBOLS_MENU: null,
    SET_ERROR: null,
    HIDE_ERROR_MSG: null,
    HANDLE_ARROW_KEYS: null,
    SEARCH_SYMBOL: null,
    ONCLICK_LIST_ADD: null,
    SET_GLOBALRATING_SELECTED: null,
    SET_COMPARE_TAB: null,
    DATA_LOADED_SUCCESS: null,
    UPDATE_GEOGRAPHY_SELECTION: null,
    HANDLE_TITLE_MOUSEOVER: null
  })
};
