import ArrayUtil from "ArrayUtil";
import BlockSize from "../../../../Constants/BlockSize.js";
import CellItem from "./CellItem.js";
import ChartApi from '../../../../ServiceApi/Apis/ChartApi.js';
import DatagraphDataType from "../../../../Constants/DatagraphDataType";
import DateHelper from "DateHelper";
import { FinancialBlockConstants } from '../../../../Constants/FinancialBlockConstants';
import FinancialBlockDisplayMode from "../../../../Constants/FinanicalBlockDisplayMode.js";
import { FinancialTransLateHelper } from "../../../../Utils/TranslateHelper";
const { ActionTypes } = FinancialBlockConstants;
import GraphType from "GraphType";
import LocalizationStore from "LocalizationStore";
import PePsBlockView from "../../../../Constants/PePsBlockView.js";
import PePsDataObject from "./PePsDataObject";
import RelativeStrenghtLineSettings from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelativeStrengthLineSettings.js";
import RowItem from "./RowItem.js";
import SettingsStore from "SettingsStore";
import SymbolType from 'SymbolType';
import { TimelineConstant } from '../../../../Constants/TabDataGraphConstants';
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow";
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { getDatagraphStates, getFinancialBlockState, timeLineReducerSelect } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors.js";
import { PriceChartConstants, PriceChartConst } from "../../../../Constants/PriceChartConstants.js";

let CurrentHeaders = [], RowSource = [], RowItems = [], isReset;
const pepsObject = {
    [PePsBlockView.Pe]: PePsBlockView.Ps, [PePsBlockView.Ps]: PePsBlockView.Bvs, [PePsBlockView.Bvs]: PePsBlockView.Cfs,
    [PePsBlockView.Cfs]: PePsBlockView.Fcfs, [PePsBlockView.Fcfs]: PePsBlockView.Div, [PePsBlockView.Div]: PePsBlockView.Pe
}
const selectedShareObj = {
    [PriceChartConst.EPS]: PePsBlockView.Pe, [PriceChartConst.RPS]: PePsBlockView.Ps, [PriceChartConst.BV]: PePsBlockView.Bvs,
    [PriceChartConst.CFS]: PePsBlockView.Cfs, [PriceChartConst.DIV]: PePsBlockView.Div, [PriceChartConst.FCF]: PePsBlockView.Fcfs
}

function getHeaderIds(userSettings, periodicity) {
    let ids = "";
    const financialBlockSetting = userSettings.NavDatagraphSettings.TabDataGraphSettings.StockViewSettings.FinancialBlockSettings[periodicity];
    const CurrentHeaders = ArrayUtil.isArray(financialBlockSetting.CurrentHeaders) && financialBlockSetting.CurrentHeaders.filter((header) => !header).length === 0 ? financialBlockSetting.CurrentHeaders : [];

    if (CurrentHeaders && CurrentHeaders.length > 0) {
        for (let index = 0; index < CurrentHeaders.length; index++) {
            const header = CurrentHeaders[index];
            if (!header) {
                continue;
            }
            if (header.canToggle) {
                for (const singleHeader of header.Headers) {
                    ids += (`${singleHeader.HeaderId},`);
                }
            } else {
                ids += (`${header.HeaderId},`);
            }
        }
        ids = ids.substring(0, ids.lastIndexOf(","));
    }
    return ids;
}
function* getFinancialApiData({ userSettings, symbol, beginDate, endDate, graphType, periodicity, isPeriodicityChanged, isSymbolChange }) {
    const headerIds = yield call(getHeaderIds, userSettings, periodicity);
    const { isCurrencyConversionChecked, selectedCurrencyCode } = userSettings;

    const getFinBlockReq = {
        wonOnly: userSettings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly,
        revWonOnly: userSettings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly,
        startDate: beginDate,
        endDate: endDate,
        selectCurrencyCode: isCurrencyConversionChecked ? selectedCurrencyCode : null,
        isMatchFinancials: userSettings.isMatchFinancials ? 1 : 0,
        symbol: symbol,
        headers: headerIds,
        ownerListId: "",
        periodicity: graphType,
        getFinancial: 1,
        getOwnership: 0,
        ProductVersion: 43,
        isFlagged: ""
    }
    TimeTrackingWindow.beginFinancialOwnerShipBlockTimeTracker();
    try {
        const response = yield call(ChartApi.GetESFinancialBlockData, getFinBlockReq);
        TimeTrackingWindow.endFinancialOwnerShipBlockApiTimeTracker();
        const financialBlockResponse = response.data;
        /* Adding a log to know whether the data is pulled from DB or ES */
        const txt = response.isFinancialBlkSource ? 'ES' : 'DB';
        console.log(`%c\n*** Financial Block Data is pulled from ${txt} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');

        if (response.responseHeader.errorCode === 404 || response.responseHeader.errorLongDesc === "Invalid Symbol") {
            // If invalid symbol is searched.
            yield put({
                type: ActionTypes.FINANCIAL_BLOCK_DEFAULT_STATE
            })
        } else if (financialBlockResponse) {
            // If proper response is present.
            yield put({
                type: ActionTypes.FINANCIAL_BLOCK_RESPONSE_READY,
                financialBlockResponse
            })
            try {
                yield call(onFinancialDataReceived);
            } catch (e) {
                console.error(e);
                TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.FinancialOwnership);
                TimeTrackingWindow.endFinancialOwnerShipBlockLoadEndTimeTracker();
            }
        } else {
            // Symbol is valid but no response. ex. FCNTX
            yield put({
                type: ActionTypes.FINANCIAL_BLOCK_DEFAULT_STATE,
                payload: true
            })
        }

        if (isPeriodicityChanged || isSymbolChange) {
            try {
                const cfbMetricResponse = yield call(ChartApi.GetFinancialBlockPickListMetric, periodicity === GraphType.Daily || periodicity === GraphType.Weekly);
                if (cfbMetricResponse) {
                    yield call(populatePickListData, cfbMetricResponse, false);
                }
            } catch (error) {
                console.log(`Error occurs in FinancialBlockSaga.js, getFinancialApiData ${error}`, error)
            }
        }
    } catch (error) {
        console.error(error);
        TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.FinancialOwnership);
        TimeTrackingWindow.endFinancialOwnerShipBlockApiTimeTracker();
    }
}
function* onFinancialDataReceived() {
    try {
        const { isIntraday, nodeWidth, isPricePanelDataReady, SymbolInfo, viewsSettings, majorPeriodicity, periodicity, rightScaleWidth } = yield select(getDatagraphStates)
        const data = yield select(getFinancialBlockState);
        const financialData = data.financialBlockResponse;
        const financialBlockSetting = viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : null;
        let dataAvailable, showDataNotAvailable;

        if (!isIntraday) {
            if (isPricePanelDataReady) {
                const headerMenu = financialBlockSetting && (yield call(prepareHeaderMenu, financialBlockSetting, majorPeriodicity));

                if (viewsSettings && financialBlockSetting === undefined || !financialBlockSetting || financialBlockSetting.IsAvailable === false || checkSymbolType(SymbolInfo)) {
                    // If the FB settings is not available for symbol, then close it
                    yield put({
                        type: ActionTypes.FINANCIAL_BLOCK_DEFAULT_STATE,
                        payload: true
                    })
                }
                else if (!financialData || !SymbolInfo) {
                    // If the API response is null, then show Data Not Available
                    dataAvailable = false;
                    showDataNotAvailable = true;
                    yield put({
                        type: ActionTypes.SET_FINANCIAL_DATA,
                        newState: {
                            headerMenu,
                            showDataNotAvailable,
                            dataAvailable,
                            isFinancialOpen: viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity].IsVisible : false,
                            FinancialBlockSettings: viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : null
                        }
                    })
                } else {
                    TimeTrackingWindow.setIsFinancialBlockAvailable(financialBlockSetting.IsVisible, SymbolInfo);
                    TimeTrackingWindow.beginFinancialOwnerShipBlockRenderTimeTracker();
                    const maSettings = viewsSettings.MASettings[majorPeriodicity];
                    const DisplayMode = financialBlockSetting.DisplayMode;
                    const CurrentPage = financialBlockSetting.CurrentPage;
                    const BlockSize = financialBlockSetting.BlockSize;
                    const RowHeight = financialBlockSetting.RowHeight;
                    const Height = (financialBlockSetting.RowHeight * financialBlockSetting.PageSize) + 2;
                    const graphData = getCurrentPageData(financialBlockSetting);
                    // RowItems = [];
                    //Countrycode check
                    const country = SymbolInfo.CountryCode;

                    if (viewsSettings.RelativeStrenghtLine1Settings[country] === undefined) {
                        viewsSettings.RelativeStrenghtLine1Settings[country] = new RelativeStrenghtLineSettings(1, true);
                    }
                    if (viewsSettings.RelativeStrenghtLine2Settings[country] === undefined) {
                        viewsSettings.RelativeStrenghtLine2Settings[country] = new RelativeStrenghtLineSettings(2, false);
                    }

                    CurrentHeaders = ArrayUtil.isArray(financialBlockSetting.CurrentHeaders) && financialBlockSetting.CurrentHeaders.filter((header) => !header).length === 0 ? financialBlockSetting.CurrentHeaders : [];

                    const RS1Linesettings = viewsSettings.RelativeStrenghtLine1Settings[country];
                    const RS2Linesettings = viewsSettings.RelativeStrenghtLine2Settings[country];
                    let maxBuffer = 0;

                    maSettings.forEach((ma) => {
                        if (ma.ma > maxBuffer) {
                            maxBuffer = ma.ma;
                        }
                    });
                    if (RS1Linesettings.maLineLength > maxBuffer) {
                        maxBuffer = RS1Linesettings.maLineLength;
                    }
                    if (RS2Linesettings.maLineLength > maxBuffer) {
                        maxBuffer = RS2Linesettings.maLineLength;
                    }

                    isReset = false;

                    dataAvailable = true;
                    showDataNotAvailable = false;
                    const financialRawData = financialData;
                    const timeLineData = yield select(timeLineReducerSelect)
                    yield fork(populateCurrentData, financialData, SymbolInfo.SymTypeEnum);
                    yield fork(populateCurrentPageHeadersAndRows, financialData, SymbolInfo, timeLineData, financialBlockSetting, majorPeriodicity, viewsSettings);
                    const PePsData = new PePsDataObject();
                    PePsData.setCurrentPePsView(financialBlockSetting.PePsBlockView, financialData.PeData, periodicity, BlockSize, SymbolInfo.SymTypeEnum);

                    yield put({
                        type: ActionTypes.SET_FINANCIAL_DATA,
                        newState: {
                            BlockSize,
                            CurrentPage,
                            DisplayMode,
                            Height,
                            PePsData,
                            RowHeight,
                            RowItems,
                            graphData,
                            headerMenu,
                            isReset,
                            nodeWidth,
                            rightScaleWidth,
                            showDataNotAvailable,
                            dataAvailable,
                            periodicity,
                            FinancialBlockSettings: viewsSettings.FinancialBlockSettings[majorPeriodicity],
                            isFinancialOpen: viewsSettings.FinancialBlockSettings[majorPeriodicity].IsVisible,
                            financialRawData,
                            CurrentHeaders,
                            financialBlockSetting,
                            viewsSettings
                        }
                    })

                    TimeTrackingWindow.endFinancialOwnerShipBlockLoadEndTimeTracker();
                }
            }
        } else {
            // Closing FB for intraday periodicity
            yield put({
                type: ActionTypes.FINANCIAL_BLOCK_DEFAULT_STATE,
                payload: true
            })
        }
    } catch (error) {
        console.log(`Error occurs in FinancialBlockSaga.js, onFinancialDataReceived ${error}`, error)
    }
}
function* populateCurrentData(financialData, symbolType) {
    if (CurrentHeaders && CurrentHeaders.length === 0) {
        yield call(populateCurrentHeaders, financialData, symbolType);
    } else {
        yield call(updateCurrentRowData, financialData, symbolType);
    }
}

function* populateCurrentPageHeadersAndRows(financialData, symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity, viewsSettings) {
    yield fork(createRowDataSource, financialData, symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity, viewsSettings);
    yield fork(updateCurrentPageHeaders, financialBlockSetting);
    yield fork(updatePaginatedRowDataSources, financialBlockSetting);
}

function* populateCurrentHeaders(financialData, symbolType) {
    if (!financialData) {
        return;
    }
    RowSource = [];
    // RowData = [];
    const length = financialData.ArrayRowData.length;
    const financialDatas = financialData.ArrayRowData;
    for (let i = 0; i < length; i++) {
        const rowData = financialDatas[i];
        if (rowData.Header.ToggleHeaderID === undefined || rowData.Header.ToggleHeaderID === null || rowData.Header.ToggleHeaderID === 0) {
            const header = {};
            header.HeaderId = rowData.Header.HeaderId;
            yield call(getHeader, symbolType, header, rowData);
            header.IsPerc = rowData.Header.IsPerc;
            header.DigitAfterDecimals = rowData.Header.DigitsAfterDecimal;
            header.HasEstimateZone = rowData.Header.HasEstimateZone;
            header.IsPeRow = rowData.Header.IsPeRow;
            header.canToggle = false;
            CurrentHeaders.push(header);
            const row = {};
            row.Header = header;
            row.CellData = rowData.CellData;
            row.canToggle = false;
            RowSource.push(row);
        } else {
            if (rowData.Header.IsPrimary) {
                const toggleHeaderItem = {};
                toggleHeaderItem.Headers = [];
                const primaryHeader = {};
                primaryHeader.HeaderId = rowData.Header.HeaderId;
                yield call(getHeader, symbolType, primaryHeader, rowData);
                primaryHeader.IsPerc = rowData.Header.IsPerc;
                primaryHeader.DigitAfterDecimals = rowData.Header.DigitsAfterDecimal;
                primaryHeader.HasEstimateZone = rowData.Header.HasEstimateZone;
                toggleHeaderItem.ActiveHeader = primaryHeader;
                toggleHeaderItem.Headers.push(primaryHeader);
                toggleHeaderItem.canToggle = true;
                const toggleRowItem = {};
                toggleRowItem.ToggleRowList = [];
                const primaryRow = {};
                primaryRow.CellData = rowData.CellData;
                primaryRow.Header = primaryHeader;
                toggleRowItem.ActiveRow = primaryRow;
                toggleRowItem.canToggle = true;
                toggleRowItem.ToggleRowList.push(primaryRow);
                const toggleHeaderId = rowData.Header.ToggleHeaderID;
                const toggledataObject = financialData.ArrayRowData.filter((item) => item.Header.HeaderId === toggleHeaderId)[0];
                if (toggledataObject !== null) {
                    const toggleHeader = {};
                    toggleHeader.HeaderId = toggledataObject.Header.HeaderId;
                    yield call(getHeader, symbolType, toggleHeader, toggledataObject);
                    toggleHeader.IsPerc = toggledataObject.Header.IsPerc;
                    toggleHeader.DigitAfterDecimals = toggledataObject.Header.DigitsAfterDecimal;
                    toggleHeader.HasEstimateZone = toggledataObject.Header.HasEstimateZone;
                    toggleHeaderItem.Headers.push(toggleHeader);

                    const toggleRow = {};
                    toggleRow.CellData = toggledataObject.CellData;
                    toggleRow.Header = toggleHeader;
                    toggleRowItem.ToggleRowList.push(toggleRow);
                }
                CurrentHeaders.push(toggleHeaderItem);
                RowSource.push(toggleRowItem);

            }
        }
    }
}

function* getHeader(symbolType, singleHeader, rowData) {
    if (symbolType === SymbolType.REIT) {
        singleHeader.DisplayName = rowData.Header.ReitsDisplayName;
    } else if (symbolType === SymbolType.ETF) {
        singleHeader.DisplayName = rowData.Header.NavDisplayName;
    } else if (symbolType === SymbolType.CEF) {
        singleHeader.DisplayName = rowData.Header.NavDisplayName;
    } else if (rowData.Header.IsCurrConverted || rowData.Header.IsDataCurrencyConverted) {
        singleHeader.DisplayName = yield call(currConvertedNameTranslation, rowData);
    } else {
        singleHeader.DisplayName = LocalizationStore.getTranslatedData(`FinancialData_Display_${rowData.Header.HeaderId}`, rowData.Header.DisplayName.toUpperCase());
    }
}

function currConvertedNameTranslation(headerData) {
    if (!headerData.Header.CurrConvertedName) {
        return headerData.Header.CurrConvertedName;
    }
    const curNames = headerData.Header.CurrConvertedName.split('(');
    const mil = curNames[1].trim().split(" ");
    if (mil.length > 1) {
        let mils = mil.shift();
        mils = LocalizationStore.getTranslatedData("sb_MIL", mils);
        curNames[1] = `${mils} ${mil.join(" ")}`;
    }
    if (curNames.length > 1) {
        return `${LocalizationStore.getTranslatedData(`FinancialData_Display_${headerData.Header.HeaderId}`, headerData.Header.DisplayName.toUpperCase())} (${curNames[1]}`;
    }
    return headerData.Header.CurrConvertedName;
}

function* updateCurrentRowData(financialData, symbolType) {
    try {
        RowSource = [];
        const headerRowMap = yield call(getRowsByHeaderid, financialData);
        for (const header of CurrentHeaders) {
            if (header !== null && !header.canToggle) {
                const rowData = headerRowMap[header.HeaderId];
                const row = {};
                row.Header = header;
                if (rowData !== undefined && rowData !== null) {
                    yield call(getHeader, symbolType, header, rowData);
                    row.CellData = rowData.CellData;
                    row.canToggle = false;
                    RowSource.push(row);
                }
            } else {
                if (header !== null && header.ActiveHeader.HeaderId !== null) {
                    const toggleRowItem = {};
                    toggleRowItem.ToggleRowList = [];
                    const rowData = headerRowMap[header.ActiveHeader.HeaderId];
                    if (rowData !== null) {
                        const primaryRow = {};
                        primaryRow.CellData = rowData.CellData;
                        primaryRow.Header = header.ActiveHeader;
                        toggleRowItem.ActiveRow = primaryRow;
                        toggleRowItem.canToggle = true;
                        toggleRowItem.ToggleRowList.push(primaryRow);
                        yield call(getHeader, symbolType, header.ActiveHeader, rowData);
                    }

                    const length = header.Headers.length;
                    for (let i = 0; i < length; i++) {
                        const toggleHeader = header.Headers[i];
                        if (toggleHeader.HeaderId === null) {
                            continue;
                        }
                        // if (toggleHeader.HeaderId == header.ActiveHeader.HeaderId) continue;
                        const toggleRowData = headerRowMap[toggleHeader.HeaderId]
                        if (toggleRowData !== null) {
                            const toggleRow = {};
                            toggleRow.CellData = toggleRowData.CellData;
                            toggleRow.Header = toggleHeader;
                            toggleRowItem.ToggleRowList.push(toggleRow);
                            yield call(getHeader, symbolType, toggleHeader, toggleRowData);
                        }
                    }

                    RowSource.push(toggleRowItem);
                }
            }
        };

    } catch (error) {
        console.log(`Error occurs in FinancialBlockSaga.js, updateCurrentRowData ${error}`)
    }
}

function getRowsByHeaderid(financialData) {
    const headerDict = {};
    const rowdatas = financialData.ArrayRowData;
    const length = rowdatas.length;
    for (let i = 0; i < length; i++) {
        const row = rowdatas[i];
        if (headerDict.hasOwnProperty(row.Header.HeaderId)) {
            continue;
        } else {
            headerDict[row.Header.HeaderId] = row;
        }
    };
    return headerDict;
}

function* createRowDataSource(financialData, symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity, viewsSettings) {
    let fcqrts = 1;
    /* Fixed Bug Panweb-917: 
    in desktop fcQuarters for periodicity - 
    Monthly, Quarterly, Annual 
    is either 2 or 1 base on the selection
    */
    const earningLineSetting = viewsSettings.EarningLineSetting[majorPeriodicity];
    const fcQuarters = earningLineSetting === undefined ? 0 : earningLineSetting.NumOfQuarters;
    const qtrts = 4;
    const fcQuartersPeriodObj = {
        [GraphType.Daily]: fcQuarters, [GraphType.Weekly]: fcQuarters, [GraphType.Monthly]: fcQuarters / qtrts,
        [GraphType.Quarterly]: fcQuarters / qtrts, [GraphType.Annual]: fcQuarters / qtrts
    };

    fcqrts = fcQuartersPeriodObj[majorPeriodicity] ? fcQuartersPeriodObj[majorPeriodicity] : fcQuarters;

    yield call(getFilterRowData, financialData, fcqrts);
    const firstVisibleTimeLineIndex = financialBlockSetting.DisplayMode === FinancialBlockDisplayMode.CalendarMode ? timeLineData.CalenderTimeLineFirstLabelIndex : timeLineData.TimeLineFirstLabelIndex;
    yield call(filterDataForCurrentTimeLine, firstVisibleTimeLineIndex, timeLineData, financialBlockSetting);
    RowItems = [];
    yield call(populateCellItems, symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity);
}

function* getFilterRowData(financialData, fcQtrs) {
    if (!financialData) {
        return;
    }
    const serviceRowData = financialData.ArrayRowData;
    if (serviceRowData.length === 0) {
        return undefined;
    }
    const maxNumberOfEstimates = yield call(getMaximumNoOfEstimate, serviceRowData[0]);
    if (RowSource.length > 0) {
        const rangeObj = yield call(getRangeVariables, fcQtrs, maxNumberOfEstimates, serviceRowData[0]);
        const startIndex = rangeObj.startIndex;
        const count = rangeObj.startIndex + rangeObj.count;
        const length = RowSource.length;
        for (let i = 0; i < length; i++) {
            const rowItem = RowSource[i];
            if (!rowItem.canToggle) {
                if (rowItem.CellData !== null && count <= rowItem.CellData.length) {
                    if (rowItem.CellData.length > startIndex) {
                        rowItem.CellData = rowItem.CellData.slice(startIndex);
                    }
                }
            } else {
                rowItem.ToggleRowList.forEach((row) => {
                    if (row.CellData !== null && count <= row.CellData.length) {
                        if (row.CellData.length > startIndex) {
                            row.CellData = row.CellData.slice(startIndex);
                        }
                    }
                });
            }
        }
    }
}

function getMaximumNoOfEstimate(singleRowData) {
    if (singleRowData.CellData !== null) {
        const estimateCells = singleRowData.CellData.filter((item) => !item.IsActual);
        const period = estimateCells.reduce((a, b) => (a + b.PeriodFlag), 0);
        return period;
    }
    return 0;
}

function getRangeVariables(fcQtrs, maxNumberOfEstimates, singleRowData) {
    const rangeObj = {};
    if (singleRowData.CellData !== null) {
        if (maxNumberOfEstimates > fcQtrs) {
            let actualDataCount = singleRowData.CellData.filter((cell) => cell.IsActual !== false).length;
            const estimatedData = singleRowData.CellData.filter((cell) => !cell.IsActual === false);
            let noOfquarters = 0;
            if (fcQtrs === 1) {
                actualDataCount++;
                rangeObj.startIndex = singleRowData.CellData.length - actualDataCount;
                rangeObj.count = actualDataCount;
                return rangeObj;
            }
            const length = estimatedData.length;
            for (let index = length - 1; index >= 0; index--) {
                const data = estimatedData[index];
                if (noOfquarters + data.PeriodFlag <= fcQtrs) {
                    actualDataCount++;
                    noOfquarters += data.PeriodFlag;
                } else {
                    break;
                }
            }
            rangeObj.startIndex = singleRowData.CellData.length - actualDataCount;
            rangeObj.count = actualDataCount;

        } else {
            rangeObj.startIndex = 0;
            rangeObj.count = singleRowData.CellData.length;
        }
    }
    return rangeObj;
}

function* filterDataForCurrentTimeLine(firstVisibleTimeLineIndex, timeLineData, financialBlockSetting) {
    const indexWithinTimeLine = yield call(getFirstIndexWithinTimeLine, firstVisibleTimeLineIndex, timeLineData, financialBlockSetting);
    const length = RowSource.length;
    const source = RowSource;
    for (let i = 0; i < length; i++) {
        const rowData = source[i];
        if (!rowData.canToggle) {
            if (!rowData.CellData) {
                return;
            }
            const cols = rowData.CellData.length - indexWithinTimeLine;
            const startIndex = indexWithinTimeLine;
            const endIndex = startIndex + cols;
            rowData.CellData = rowData.CellData.slice(startIndex, endIndex);
        } else {
            rowData.ToggleRowList.forEach((row) => {
                const cols = row.CellData.length - indexWithinTimeLine;
                const startIndex = indexWithinTimeLine;
                const endIndex = startIndex + cols;
                if (row.CellData !== null && endIndex <= row.CellData.length) {
                    if (row.CellData.length > startIndex) {
                        row.CellData = row.CellData.slice(startIndex, endIndex);
                    }
                }
            });
        }
    }
}

function* getFirstIndexWithinTimeLine(firstVisibleTimeLineIndex, timeLineData, financialBlockSetting) {
    const timeLine = timeLineData.TimeLineData.dates;
    const DisplayMode = financialBlockSetting.DisplayMode;
    if (!RowSource[0]) {
        return 0;
    }
    if (DisplayMode === FinancialBlockDisplayMode.CalendarMode) {
        let celldata = [];
        if (!RowSource[0].canToggle) {
            celldata = RowSource[0].CellData;
        } else {
            celldata = RowSource[0].ActiveRow.CellData;
        }
        const count = celldata.length;
        for (let i = 0; i < count; i++) {
            const cell = celldata[i];
            const calenderDateFromCell = yield call(getDateFromLabel, cell.ActualCalendarQtr, cell.ActualCalendarYear);
            const timeLineDate = timeLine[firstVisibleTimeLineIndex].Date
            if (calenderDateFromCell <= timeLineDate) {
                return i;
            }
        }
    } else {
        let celldata = [];
        const firstTimeLineIndex = Math.max(0, firstVisibleTimeLineIndex);
        if (!RowSource[0].canToggle) {
            celldata = RowSource[0].CellData;
        } else {
            celldata = RowSource[0].ActiveRow.CellData;
        }
        if (celldata !== null) {
            const length = celldata.length;
            for (let i = 0; i < length; i++) {
                if (timeLine.length < 1) {
                    return i;
                }
                const cell = celldata[i];
                const date = new Date(DateHelper.getUtcFromLong(cell.FiscalDate));
                const cellcomparableDate = new Date(date.getYear(), date.getMonth(), 1);
                const timeLineComparabledate = new Date(timeLine[firstTimeLineIndex].Date.getYear(), timeLine[firstTimeLineIndex].Date.getMonth(), 1);
                if (cellcomparableDate <= timeLineComparabledate) {
                    return i;
                }
            }
        }
    }
}

function getDateFromLabel(quarter, year) {
    if (quarter === 0 || year === 0) {
        return new Date();
    }
    const date = new Date(year, ((quarter * 3) - 1), 1);
    return date;
}

function* populateCellItems(symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity) {
    let CurrentRows = [];
    const PageSize = financialBlockSetting.PageSize;
    const pageSize = PageSize;
    const CurrentPage = financialBlockSetting.CurrentPage;
    const currentPage = CurrentPage;
    const LargeBlockSize = financialBlockSetting.LargeBlockSize;
    const largeBlockSize = LargeBlockSize;
    let pageStartIndex = 0;
    if (currentPage > 0) {
        pageStartIndex = (currentPage * largeBlockSize);
    }
    if (pageStartIndex + pageSize < RowSource.length) {
        const startIndex = pageStartIndex;
        const endIndex = pageStartIndex + pageSize;
        CurrentRows = RowSource.slice(startIndex, endIndex);
    } else {
        CurrentRows = RowSource.slice(pageStartIndex, RowSource.length);
    }
    yield put({
        type: ActionTypes.FINANCIAL_BLOCK_UPDATE_CURRENT_ROWS,
        payload: CurrentRows
    })
    let index = 0;
    for (const row of CurrentRows) {
        let rowData = undefined;
        if (!row.canToggle) {
            rowData = row;
        } else {
            rowData = row.ActiveRow;
        }
        const rowItem = new RowItem(rowData);
        rowItem.index = index;
        index++;

        if (symbolInfo.SymTypeEnum === SymbolType.INTERNATIONALSTOCK) {
            yield fork(addCellBasedOnCellPeriod, rowItem, symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity);
        } else {
            yield fork(addCellBasedOnTimeLine, rowItem, symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity);
        }

    };
}

function* addCellBasedOnCellPeriod(rowItem, symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity) {
    let skipTimeLineEstimate = 0;
    let skipActualCell = 0;
    let cellIndex = 0;
    let skipDailyEstimate = 0;
    const periodicity = majorPeriodicity;
    const rowData = rowItem.Row;
    const firstTimeLineIndex = financialBlockSetting.DisplayMode === FinancialBlockDisplayMode.CalendarMode ? timeLineData.CalenderTimeLineFirstLabelIndex : timeLineData.TimeLineFirstLabelIndex;
    const timeLine = timeLineData.TimeLineData.dates;
    const timeLineLabels = financialBlockSetting.DisplayMode === FinancialBlockDisplayMode.CalendarMode ? timeLineData.CalenderTimeLineLabels : timeLineData.TimeLineLabels;
    const ShowEpsPercentLoss = financialBlockSetting.ShowEpsPercentLoss;
    if (periodicity === GraphType.Daily || periodicity === GraphType.Weekly) {
        if (rowData !== null && rowData.CellData.length > 0) {
            const date = yield call(getInternationalCellCalenderDate, rowData.CellData[0].CalendarQtr, rowData.CellData[0].CalendarYear);
            const length = timeLine.length;
            for (let index = firstTimeLineIndex; index < length; index++) {
                const timeLineItem = timeLine[index];
                if (timeLineItem !== undefined && (yield call(chooseLabel, timeLineItem, symbolInfo.FiscalMonthEnd, financialBlockSetting) || timeLineItem.IsMonthLabel)) {
                    if (timeLineItem.Date.getMonth() <= date.getMonth() && timeLineItem.Date.getYear() <= date.getYear()) {
                        break;
                    }
                    skipTimeLineEstimate++;
                }

            }
        }
    }
    if (periodicity === GraphType.Monthly) {
        if (rowData !== null && rowData.CellData.length > 0) {
            const date = yield call(getInternationalCellCalenderDate, rowData.CellData[0].CalendarQtr, rowData.CellData[0].CalendarYear);
            const timelineLength = timeLine.length;
            for (let index = firstTimeLineIndex; index < timelineLength; index++) {
                const timeLineItem = timeLine[index];
                if (timeLineItem !== undefined && timeLineItem.IsFiscalLabel1 || timeLineItem.IsFiscalLabel2) {
                    if (timeLineItem.Date.getYear() <= date.getYear()) {
                        break;
                    }
                    skipTimeLineEstimate++;
                }
            }
        }
    }
    const labelLength = timeLineLabels.length;
    for (let index = 0; index < labelLength; index++) {
        const label = timeLineLabels[index];
        if (!(yield call(chooseLabel, label, symbolInfo.FiscalMonthEnd, financialBlockSetting)) && periodicity === GraphType.Daily) {
            continue;
        }
        if (skipTimeLineEstimate > 0) {
            skipTimeLineEstimate--;
            continue;
        }

        //      if (label.XLine !== 2 && _state.periodicity == GraphType.Daily) {
        //        continue;
        //      }
        if (periodicity === GraphType.Daily && skipDailyEstimate > 0) {
            skipDailyEstimate--;
            continue;
        }
        if (skipActualCell > 0) {
            skipActualCell--;
            continue;
        }
        const cellData = new CellItem();
        cellData.header = rowData.Header;
        if (rowData.CellData !== null && cellIndex < rowData.CellData.length) {
            const cellValue = rowData.CellData[cellIndex];

            cellData.PeriodFlag = cellValue.PeriodFlag;
            cellData.ShowMergeValue = cellValue.ShowMergeValue;
            cellData.IsActual = cellValue.IsActual;
            cellData.IsValueDouble = cellValue.IsValueDouble;
            if (!cellData.IsValueDouble) {
                if (cellValue.StrValue) {
                    const arrValue = cellValue.StrValue.split('-');
                    if (arrValue.length >= 2) {
                        cellData.HighValue = parseFloat(arrValue[1]);
                        cellData.LowValue = parseFloat(arrValue[0]);
                        cellData.isPE = true;
                    }
                }
            }
            if (ShowEpsPercentLoss || !cellValue.EpsNegPosFlag) {
                if (cellValue.IsValueDouble) {
                    cellData.ActualValue = cellValue.Value;
                    cellData.Format(rowData.Header, cellValue);
                } else {
                    cellData.Value = cellValue.StrValue;
                }
                cellData.DogEarContent = cellValue.DogEarContent;
                cellData.SetClass();

            }

            if (!cellValue.IsActual && cellValue.PeriodFlag === 2) {
                skipTimeLineEstimate = 1;
                skipDailyEstimate = 1;
                cellData.XPoint = label.XAxis;
            } else if (!cellValue.IsActual && cellValue.PeriodFlag === 4) {
                skipTimeLineEstimate = 3;
                if (index + 3 < timeLineLabels.length) {
                    cellData.XPoint = timeLineLabels[index].XAxis;
                }
            } else {
                if (cellData.PeriodFlag === 2) {
                    if (!cellValue.ShowMergeValue) {
                        cellIndex = cellIndex + 1;
                        skipActualCell = 1;
                    }
                }
                if (cellData.PeriodFlag === 4) {
                    const mergeFactor = yield call(mergeAnnualCell, cellIndex, rowData);
                    cellIndex = cellIndex + mergeFactor;
                    skipActualCell = mergeFactor;
                }
                cellData.XPoint = timeLineLabels[index].XAxis;

            }
            cellIndex++;
        } else {
            cellData.PeriodFlag = 1;
            cellData.XPoint = timeLineLabels[index].XAxis;
        }
        rowItem.cellItems.push(cellData);
    }

    RowItems.push(rowItem);
}

function getInternationalCellCalenderDate(calenderQuarter, calenderYear) {
    return new Date(calenderYear, ((3 * calenderQuarter) - 1), 1);
}

function* chooseLabel(label, FiscalMonthEnd, financialBlockSetting) {
    const _f1Qtrs = [1, 4, 7, 10];
    const _f2Qtrs = [2, 5, 8, 11];
    const DisplayMode = financialBlockSetting.DisplayMode;
    if (label) {
        return DisplayMode === FinancialBlockDisplayMode.CalendarMode ? label.IsCalendarLabel :
            _f2Qtrs[yield call(Qtr, FiscalMonthEnd)] === FiscalMonthEnd ?
                label.IsFiscalLabel2 :
                _f1Qtrs[yield call(Qtr, FiscalMonthEnd)] === FiscalMonthEnd ?
                    label.IsFiscalLabel1 :
                    label.IsCalendarLabel;
    }
    return false;
}

function Qtr(month) {
    return Math.floor((month - 1) / 3.0);
}

function mergeAnnualCell(cellIndex, rowData) {
    let mergeFactor = 0;
    const cellData = rowData.CellData;
    for (let index = cellIndex + 1; index < cellIndex + 4; index++) {
        if (index < cellData.length && !cellData[index].ShowMergeValue) {
            mergeFactor++;
        } else if (index === cellData.length) {
            mergeFactor = 3;
            break;
        } else {
            break;
        }
    }
    return mergeFactor;
}

function* addCellBasedOnTimeLine(rowItem, symbolInfo, timeLineData, financialBlockSetting, majorPeriodicity) {
    let skipTimeLineEstimate = 0;
    const rowData = rowItem.Row;
    const firstTimeLineIndex = financialBlockSetting.DisplayMode === FinancialBlockDisplayMode.CalendarMode ? timeLineData.CalenderTimeLineFirstLabelIndex : timeLineData.TimeLineFirstLabelIndex;
    const timeLine = timeLineData.TimeLineData.dates;
    const timeLineLabels = financialBlockSetting.DisplayMode === FinancialBlockDisplayMode.CalendarMode ? timeLineData.CalenderTimeLineLabels : timeLineData.TimeLineLabels;
    const periodicity = majorPeriodicity;
    const ShowEpsPercentLoss = financialBlockSetting.ShowEpsPercentLoss;
    if (periodicity === GraphType.Daily || periodicity === GraphType.Weekly) {
        if (rowData !== null && rowData.CellData.length > 0) {
            const date = yield call(getInternationalCellCalenderDate, rowData.CellData[0].CalendarQtr, rowData.CellData[0].CalendarYear);
            const timelineLength = timeLine.length;
            for (let index = firstTimeLineIndex; index < timelineLength; index++) {
                const timeLineItem = timeLine[index];
                if (timeLineItem !== undefined && (yield call(chooseLabel, timeLineItem, symbolInfo.FiscalMonthEnd, financialBlockSetting))) {
                    if ((timeLineItem.Date.getFullYear() * 100) + timeLineItem.Date.getMonth() <= (date.getFullYear() * 100) + date.getMonth()) {
                        break;
                    }
                    skipTimeLineEstimate++;
                }
            }
        }
    }
    if (periodicity === GraphType.Monthly) {
        if (rowData !== null && rowData.CellData && rowData.CellData.length > 0) {
            const date = yield call(getInternationalCellCalenderDate, rowData.CellData[0].CalendarQtr, rowData.CellData[0].CalendarYear);
            const length = timeLine.length;
            for (let index = firstTimeLineIndex; index < length; index++) {
                const timeLineItem = timeLine[index];
                if (timeLineItem !== undefined && timeLineItem.IsFiscalLabel1 || timeLineItem.IsFiscalLabel2) {
                    if (timeLineItem.Date.getYear() <= date.getYear()) {
                        break;
                    }
                    skipTimeLineEstimate++;
                }
            }
        }
    }

    let cellIndex = 0;
    const lgth = timeLineLabels.length;
    for (let index = 0; index < lgth; index++) {
        const label = timeLineLabels[index];
        if (!(yield call(chooseLabel, label, symbolInfo.FiscalMonthEnd, financialBlockSetting)) && periodicity === GraphType.Daily) {
            continue;
        }
        if (skipTimeLineEstimate > 0) {
            skipTimeLineEstimate--;
            continue;
        }
        const cellData = new CellItem();
        cellData.header = rowData.Header;
        if (rowData.CellData !== null && cellIndex < rowData.CellData.length) {
            const cellValue = rowData.CellData[cellIndex];
            if (!cellValue.IsValueDouble) {
                if (cellValue.StrValue) {
                    const arrValue = cellValue.StrValue.split('-');
                    if (arrValue.length >= 2) {
                        cellData.HighValue = parseFloat(arrValue[1]);
                        cellData.LowValue = parseFloat(arrValue[0]);
                        cellData.isPE = true;
                    }
                }
            }
            if (ShowEpsPercentLoss || !cellValue.EpsNegPosFlag) {
                if (cellValue.IsValueDouble) {
                    cellData.ActualValue = cellValue.Value;
                    cellData.Format(rowData.Header, cellValue);

                } else {
                    cellData.Value = cellValue.StrValue;
                }
                cellData.DogEarContent = cellValue.DogEarContent;
                cellData.SetClass();
                cellData.PeriodFlag = cellValue.PeriodFlag;
                cellData.IsActual = cellValue.IsActual;
                cellData.IsValueDouble = cellValue.IsValueDouble;

            }
        }
        cellData.XPoint = label.XAxis;
        rowItem.cellItems.push(cellData);
        cellIndex++;
    }
    RowItems.push(rowItem)

}

function* updateCurrentPageHeaders(financialBlockSetting) {
    const PageSize = financialBlockSetting.PageSize;
    const pageSize = PageSize;
    const CurrentPage = financialBlockSetting.CurrentPage;
    const currentPage = CurrentPage;
    const LargeBlockSize = financialBlockSetting.LargeBlockSize;
    const largeBlockSize = LargeBlockSize;
    let pageStartIndex = 0, CurrentPageHeaders = [];
    if (currentPage > 0) {
        pageStartIndex = (currentPage * largeBlockSize);
    }
    if (pageStartIndex + pageSize < CurrentHeaders.length) {
        const startIndex = pageStartIndex;
        const endIndex = pageStartIndex + pageSize;
        CurrentPageHeaders = CurrentHeaders.slice(startIndex, endIndex);

    } else {
        const startIndex = pageStartIndex;
        const endIndex = (pageStartIndex + (CurrentHeaders.length - pageStartIndex));
        CurrentPageHeaders = CurrentHeaders.slice(startIndex, endIndex);
    }
    yield put({
        type: ActionTypes.FINANCIAL_BLOCK_UPDATE_CURRENT_PAGE_HEADERS,
        payload: CurrentPageHeaders
    })
}

function* updatePaginatedRowDataSources(financialBlockSetting) {
    const PagintedSource = [];
    const PageDataDict = yield call(populatePageDict, financialBlockSetting);
    const CurrentPage = financialBlockSetting.CurrentPage;
    const TotalPageCount = financialBlockSetting.TotalPageCount;
    const count = TotalPageCount;
    for (let i = 0; i < count; i++) {
        const paginatedData = PageDataDict[i];
        if (i === CurrentPage) {
            paginatedData.CurrentRows = RowItems;
            paginatedData.isSelected = true;
        } else {
            paginatedData.CurrentRows = [];
            paginatedData.isSelected = false;
        }
        PagintedSource.push(paginatedData)
    }
    yield put({
        type: ActionTypes.FINANCIAL_BLOCK_UPDATE_PAGINATED_SOURCE,
        payload: PagintedSource
    })
}

function populatePageDict(financialBlockSetting) {
    const TotalPageCount = financialBlockSetting.TotalPageCount;
    const count = TotalPageCount;
    const PageDataDict = {};
    for (let i = 0; i < count; i++) {
        PageDataDict[i] = {
            PageNum: i + 1
        };
    }
    return PageDataDict
}

function getCurrentPageData(financialBlockSetting) {
    const { CurrentPage } = financialBlockSetting;
    const data = financialBlockSetting.graphView.find((item, key) => {
        if (key === CurrentPage) {
            return item;
        }
    });
    return data;
}

function prepareHeaderMenu(financialBlockSetting, majorPeriodicity) {
    const menu = [];
    if (majorPeriodicity !== GraphType.Monthly) {
        const calItem = {
            header: FinancialTransLateHelper.CALENDER_YEAR_END,
            isActive: financialBlockSetting.DisplayMode === FinancialBlockDisplayMode.CalendarMode,
            Type: "DisplayMode",
            Value: FinancialBlockDisplayMode.CalendarMode
        };
        menu.push(calItem);
        const fisItem = {
            header: FinancialTransLateHelper.FISCAL_YEAR_END,
            hasDivider: true,
            isActive: financialBlockSetting.DisplayMode === FinancialBlockDisplayMode.FiscalMode,
            Type: "DisplayMode",
            Value: FinancialBlockDisplayMode.FiscalMode
        };
        menu.push(fisItem);
    }
    const sSizeItem = {
        header: FinancialTransLateHelper.SMALL_BLOCK,
        isActive: financialBlockSetting.BlockSize === BlockSize.Small,
        Type: "BlockSize",
        Value: BlockSize.Small
    };
    menu.push(sSizeItem);
    const mSizeItem = {
        header: FinancialTransLateHelper.MEDIUM_BLOCK,
        isActive: financialBlockSetting.BlockSize === BlockSize.Medium,
        Type: "BlockSize",
        Value: BlockSize.Medium
    };
    menu.push(mSizeItem);
    const lSizeItem = {
        header: FinancialTransLateHelper.LARGE_BLOCK,
        hasDivider: true,
        Type: "BlockSize",
        Value: BlockSize.Large,
        isActive: financialBlockSetting.BlockSize === BlockSize.Large
    };
    menu.push(lSizeItem);
    const showHideEps = {
        header: FinancialTransLateHelper.EPS_PERCENT_CHANGE,
        hasDivider: true,
        Type: "ShowEPSLOSS",
        isActive: financialBlockSetting.ShowEpsPercentLoss
    };
    menu.push(showHideEps);
    const hideItem = {
        header: FinancialTransLateHelper.HIDE_FINANCIAL_BLOCK,
        Type: "Visibility"
    };
    menu.push(hideItem);
    return menu;
}

function* toggleFinancialHeader({ header, index }) {
    try {
        const { SymbolInfo, viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const financialBlockSetting = viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : null;
        const timeLineData = yield select(timeLineReducerSelect);
        const { CurrentRows, RowItems, financialRawData } = yield select(getFinancialBlockState);
        const activeHeaderIndex = header.Headers.findIndex((metric) => metric.HeaderId === header.ActiveHeader.HeaderId);
        let indexToToggle = 0;
        if (activeHeaderIndex < header.Headers.length - 1) {
            indexToToggle = activeHeaderIndex + 1;
        }
        const newActiveHeader = header.Headers[indexToToggle];
        header.ActiveHeader = newActiveHeader;
        const row = CurrentRows[index];
        const total_rows = RowItems.length;

        if (row) {
            const activeNewRow = row.ToggleRowList.filter((item) => item.Header.HeaderId === newActiveHeader.HeaderId);
            if (activeNewRow && activeNewRow.length > 0) {
                row.ActiveRow = activeNewRow[0];
                const rowItem = new RowItem(activeNewRow[0]);
                rowItem.index = index;
                yield call(addCellBasedOnTimeLine, rowItem, SymbolInfo, timeLineData, financialBlockSetting, majorPeriodicity);
                RowItems[index] = rowItem;
            }
        }

        financialBlockSetting.CurrentHeaders = CurrentHeaders.map((item) => financialBlockSetting.getHeaderItemSerizableObject(item));
        SettingsStore.saveSettings();

        yield fork(populateCurrentPageHeadersAndRows, financialRawData, SymbolInfo, timeLineData, financialBlockSetting, majorPeriodicity, viewsSettings)

        yield put({
            type: ActionTypes.UPDATE_CONSOLE_SETTINGS,
            RowItems: RowItems.slice(0, total_rows),
            CurrentHeaders
        })
    } catch (error) {
        console.log(`Error occurs in FinancialBlockSaga.js, toggleFinancialHeader ${error}`)
    }
}

function* togglePePsData() {
    try {
        const { SymbolInfo, majorPeriodicity } = yield select(getDatagraphStates);
        const { financialRawData, financialBlockSetting } = yield select(getFinancialBlockState);
        const PePsData = new PePsDataObject();

        financialBlockSetting.PePsBlockView = pepsObject[financialBlockSetting.PePsBlockView]
        SettingsStore.saveSettings();

        if (financialRawData) {
            const periodicity = majorPeriodicity;
            PePsData.setCurrentPePsView(financialBlockSetting.PePsBlockView, financialRawData.PeData, periodicity, financialBlockSetting.BlockSize, SymbolInfo.SymTypeEnum)
        }
        yield put({
            type: ActionTypes.UPDATE_UI_ON_PE_TOGGLE,
            PePsData
        })
    } catch (error) {
        console.log(`Error occurs in FinancialBlockSaga.js, togglePePsData ${error}`)
    }
}

function* updatePage({ pageNumber }) {
    try {
        const { SymbolInfo, viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const { financialRawData, financialBlockSetting } = yield select(getFinancialBlockState);
        const timeLineData = yield select(timeLineReducerSelect);
        financialBlockSetting.CurrentPage = pageNumber;
        SettingsStore.saveSettings();
        yield call(populateCurrentPageHeadersAndRows, financialRawData, SymbolInfo, timeLineData, financialBlockSetting, majorPeriodicity, viewsSettings);

        yield put({
            type: ActionTypes.UPDATE_FINANCIAL_PAGE,
            pageNumber,
            RowItems
        })
    } catch (error) {
        console.log(`Error occurs in FinancialBlockSaga.js, updatePage ${error}`, error)
    }
}
function* onSelectMenuItem({ item }) {
    try {
        let newState;
        const { SymbolInfo, viewsSettings, majorPeriodicity, nodeWidth } = yield select(getDatagraphStates);
        const timeLineData = yield select(timeLineReducerSelect);
        const financialBlockSetting = viewsSettings.FinancialBlockSettings[majorPeriodicity];
        const { financialRawData } = yield select(getFinancialBlockState);
        const DisplayMode = financialBlockSetting.DisplayMode;
        if (item.Type === "BlockSize") {
            financialBlockSetting.onBlockSizeChanged(item.Value);
            SettingsStore.saveSettings();
            const BlockSize = financialBlockSetting.BlockSize;

            const RowHeight = financialBlockSetting.RowHeight;
            const Height = (financialBlockSetting.RowHeight * financialBlockSetting.PageSize) + 2;
            if (financialRawData || RowSource !== undefined) {
                yield call(populateCurrentPageHeadersAndRows, financialRawData, SymbolInfo, timeLineData, financialBlockSetting, majorPeriodicity, viewsSettings);
            }
            const headerMenu = yield call(prepareHeaderMenu, financialBlockSetting, majorPeriodicity);
            const graphData = getCurrentPageData(financialBlockSetting);
            if (graphData.activeIndex >= RowItems.length) {
                yield put({
                    type: ActionTypes.PAGINATED_GRAPH_VIEW,
                    index: graphData.activeIndex, isShowGraph: false, isEnableGraph: false
                })
            }
            newState = {
                BlockSize, DisplayMode, Height, RowHeight, RowItems, graphData,
                headerMenu, nodeWidth
            }
            yield put({
                type: ActionTypes.SET_MENU_DATA,
                newState
            })
        }
        else if (item.Type === "DisplayMode") {
            financialBlockSetting.DisplayMode = item.Value;
            yield put({ type: TimelineConstant.ActionTypes.UPDATE_IS_CALENDER, isCalender: item.Value === FinancialBlockDisplayMode.CalendarMode })
            SettingsStore.saveSettings();
            yield call(onFinancialDataReceived)
        }
        else if (item.Type === "ShowEPSLOSS") {
            financialBlockSetting.ShowEpsPercentLoss = !financialBlockSetting.ShowEpsPercentLoss;
            SettingsStore.saveSettings();
            yield fork(onFinancialDataReceived);
        }
    } catch (error) {
        console.log(`Error occurs in FinancialBlockSaga.js, onSelectMenuItem ${error}`, error)
    }
}
export function* populatePickListData(cfbMetricResponse = null, forDialogBox = false) {
    const { _pickListData } = yield select(getFinancialBlockState);
    if (cfbMetricResponse === null && forDialogBox) {
        return _pickListData
    }
    else {
        if (!cfbMetricResponse || cfbMetricResponse.categoryData.length <= 0) {
            return;
        }
        const tempPickListItems1 = [];
        const tempPickListItems2 = [];
        const tempPickListItems3 = [];
        const tempItemDataList = [];

        _pickListData.PickListItems1 = [];
        _pickListData.PickListItems2 = [];
        _pickListData.PickListItems3 = [];
        _pickListData.ItemDataList = [];


        const catergoryIds = cfbMetricResponse.categoryData.map((item) => item.CategoryId);
        const headerIds = cfbMetricResponse.itemData.map((item) => item.HeaderID);
        const rootHeaderDict = {};
        const itemDataList = cfbMetricResponse.itemData.sort((a, b) => a.Sort - b.Sort);

        for (const categoryItem of cfbMetricResponse.categoryData.filter((item) => item.ParentCategoryId === null)) {
            const item = {
                CategoryId: categoryItem.CategoryId,
                Header: categoryItem.CategoryName,
                IsRootHeader: true
            };
            tempPickListItems1.push(item);
            tempPickListItems2.push(item);
            tempPickListItems3.push(item);
            item.Details = [];
            if (!rootHeaderDict.hasOwnProperty(categoryItem.CategoryId)) { rootHeaderDict[categoryItem.CategoryId] = item; }
        }

        for (const headerData of itemDataList) {
            if (!catergoryIds.includes(headerData.CategoryId)) { continue; }

            const item = {
                HeaderId: headerData.HeaderID,
                Header: headerData.PickListName.toUpperCase(),
                HeaderEnValue: headerData.PickListName.toUpperCase(),
                Tag: headerData.Tags,
                Description: headerData.Description,
                DescriptionEnValue: headerData.Description,
                DigitsAfterDecimal: headerData.DigitsAfterDecimal,
                HasEstimateZone: headerData.HasEstimateZone,
                IsPerc: headerData.IsPerc,
                DataItemIDs: headerData.DataItemIDs,
                CanToggle: headerData.ToggleId !== undefined && headerData.ToggleId !== null,
                CategoryId: headerData.CategoryId,
                IsRootHeader: false,
                IsMouseOver: false,
                IsSelected: false
            };
            tempItemDataList.push(item);

            if (item.CanToggle && headerIds.includes(headerData.ToggleId)) {
                item.ToggleObject = itemDataList.find((item) => item.HeaderID === headerData.ToggleId);
            }
            const rootHeader = rootHeaderDict[headerData.CategoryId];
            rootHeader.Details.push(item);

            const rootHeaderDetails =
                rootHeader.Details.sort((a, b) => {
                    if (a.HeaderEnValue < b.HeaderEnValue) {
                        return -1;
                    } else if (a.HeaderEnValue > b.HeaderEnValue) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

            const rootHeaderIndex = tempPickListItems1.findIndex((item) => item.IsRootHeader === true && item.CategoryId === headerData.CategoryId);
            const insertIndex = (rootHeaderIndex + 1) + rootHeaderDetails.findIndex((d) => d.HeaderId === item.HeaderId);

            tempPickListItems1.splice(insertIndex, 0, item);
            tempPickListItems2.splice(insertIndex, 0, Object.assign({}, item));
            tempPickListItems3.splice(insertIndex, 0, Object.assign({}, item));
        }
        _pickListData.PickListItems1 = tempPickListItems1;
        _pickListData.PickListItems2 = tempPickListItems2;
        _pickListData.PickListItems3 = tempPickListItems3;
        _pickListData.ItemDataList = tempItemDataList;

        if (_pickListData) {
            yield put({
                type: ActionTypes.FINANCIAL_BLOCK_UPDATE_PICK_LIST,
                newState: _pickListData
            })
        }
    }
}
export function* updateFinancialBlock(headers) {
    try {
        CurrentHeaders = headers;
        isReset = true;
        yield call(getFinancialBlockData);

        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const financialBlockSetting = viewsSettings.FinancialBlockSettings[majorPeriodicity];
        financialBlockSetting.CurrentHeaders = headers.map((item) => financialBlockSetting?.getHeaderItemSerizableObject(item));
        SettingsStore.saveSettings();
    } catch (error) {
        console.log(`Error occurs in FinancialBlockSaga.js, updateFinancialBlock ${error}`, error)
    }
}
// function getFactorResearchSettings() {
//     return SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings.StockViewSettings.FactorResearchSettings
// }
function* getFinancialBlockData(endDt = null) {
    // Setting financial block to blank while changing periodicity/Date
    const { periodicityValue, selectedCountry, nodeCount, SymbolInfo, viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);

    const settings = SettingsStore.getConsoleSettings();
    const periodicity = settings.NavDatagraphSettings.TabDataGraphSettings.Periodicity;
    const lastTradeDate = SymbolInfo.LastTradeDate;
    const endDate = endDt === null ? lastTradeDate : endDt;
    // const earningLineSetting = viewsSettings.EarningLineSetting[majorPeriodicity];
    // const revenueLineSetting = viewsSettings.RevenueLineSetting[majorPeriodicity];
    const wonOnly = getEarningsSettings();
    const revWonOnly = getRevenueSettings();
    // const fcQuarters = earningLineSetting === undefined || (!earningLineSetting.IsVisible && !revenueLineSetting.IsVisible) ? 0 : earningLineSetting.NumOfQuarters;
    const maSettings = viewsSettings.MASettings[majorPeriodicity];
    const country = SymbolInfo.CountryCode;

    // let ownerShipListId=ownershipBlockSetting.IsFavList ? ownershipBlockSetting.ListContentId : ownershipBlockSetting.ListId;
    // if(ownerShipListId===undefined || ownerShipListId===null)
    // {
    //   ownerShipListId=PanarayDefault.DefaultOwnershipListId;
    // }
    if (viewsSettings.RelativeStrenghtLine1Settings[country] === undefined) {
        viewsSettings.RelativeStrenghtLine1Settings[country] = new RelativeStrenghtLineSettings(1, true);
    }
    if (viewsSettings.RelativeStrenghtLine2Settings[country] === undefined) {
        viewsSettings.RelativeStrenghtLine2Settings[country] = new RelativeStrenghtLineSettings(2, false);
    }

    const RS1Linesettings = viewsSettings.RelativeStrenghtLine1Settings[country];
    const RS2Linesettings = viewsSettings.RelativeStrenghtLine2Settings[country];
    let maxBuffer = 0;

    maSettings.forEach((ma) => {
        if (ma.ma > maxBuffer) {
            maxBuffer = ma.ma;
        }
    });
    if (RS1Linesettings.maLineLength > maxBuffer) {
        maxBuffer = RS1Linesettings.maLineLength;
    }
    if (RS2Linesettings.maLineLength > maxBuffer) {
        maxBuffer = RS2Linesettings.maLineLength;
    }
    const InitialBufferSize = maxBuffer;

    const beginDate = DateHelper.calculateBeginDate(endDate, majorPeriodicity, (nodeCount + InitialBufferSize));

    const getEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const getTradeDate = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate());
    let isHistoric;

    if (SymbolInfo.SymTypeEnum === SymbolType.HISTORICSTOCK ||
        SymbolInfo.SymTypeEnum === SymbolType.HISTORICINDEX) {
        isHistoric = true;
    }
    else {
        isHistoric = getEndDate.getTime() < getTradeDate.getTime();
    }

    // _state.isReset = true;
    // this.clearData();

    const getOwnership = 0;
    // if(isHistoric){
    //   getOwnership = 0;
    // }
    // StockViewStore.setTimeline(null, beginDate, endDate, SymbolInfo, fcQuarters);

    /* Convert wwhen FRData is ready in priceChartSaga */
    // const factSetting = getFactorResearchSettings();
    // const fcVisible = factSetting ? factSetting.IsVisible : false;   
    // const sState = priceChartStore.getState();
    // if (!fcVisible ||
    //     !sState.FRData ||
    //     sState.FRData.length === 0 ||
    //     sState.FRData.every((i) => i.every((factor) => factor.InView === false)) || _state.isfcQuartersChanged) {
    // _state.isfcQuartersChanged = false;
    //     StockViewStore.setTimeline(null, beginDate, endDate, SymbolInfo, fcQuarters);
    // }

    // ownershipBlockStore.setTimelineData(null,beginDate,endDate,SymbolInfo,fcQuarters);
    const headerIds = yield call(getHeaderIdsString);
    const getBlockReq = {
        wonOnly,
        revWonOnly,
        startDate: DateHelper.yyyymmdd(beginDate),
        endDate: DateHelper.yyyymmdd(endDate),
        selectCurrencyCode: selectedCountry,
        isMatchFinancials: settings.isMatchFinancials ? 1 : 0,
        symbol: SymbolInfo.Symbol,
        headers: headerIds,
        ownerListId: "",
        periodicity: periodicityValue,
        getFinancial: 1,
        getOwnership: getOwnership,
        ProductVersion: 43,
        isFlagged: ""
    }
    TimeTrackingWindow.beginFinancialOwnerShipBlockTimeTracker();

    try {
        const [cfbMetricResponse, financialBlockResponse] = yield all([call(ChartApi.GetFinancialBlockPickListMetric, periodicity === GraphType.Daily || periodicity === GraphType.Weekly),
        call(ChartApi.GetESFinancialBlockData, getBlockReq)])
        TimeTrackingWindow.endFinancialOwnerShipBlockApiTimeTracker();
        TimeTrackingWindow.beginFinancialOwnerShipBlockRenderTimeTracker();
        /* Adding a log to know whether the data is pulled from DB or ES */
        const txt = cfbMetricResponse.isFinancialBlkSource ? 'ES' : 'DB';
        console.log(`%c\n*** Financial Block Data is pulled from ${txt} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');
        if (cfbMetricResponse !== undefined) { populatePickListData(cfbMetricResponse); }
        if (financialBlockResponse.data) {
            yield put({
                type: ActionTypes.FINANCIAL_BLOCK_RESPONSE_READY,
                financialBlockResponse: financialBlockResponse.data
            })
        }
        yield call(onFinancialDataReceived);
        // ownershipBlockStore.onOwnershipBlockDataRecived(financialBlockResponse.ownershipBlockData,endDate);
        TimeTrackingWindow.endFinancialOwnerShipBlockLoadEndTimeTracker();

    } catch (e) {
        console.error(e);
        TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.FinancialOwnership);
        TimeTrackingWindow.endFinancialOwnerShipBlockLoadEndTimeTracker();
    }
}
function getEarningsSettings() {
    return SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly;
}
function getRevenueSettings() {
    return SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly;
}
function getHeaderIdsString() {
    let ids = "";
    if (CurrentHeaders && CurrentHeaders.length > 0) {
        for (let index = 0; index < CurrentHeaders.length; index++) {
            const header = CurrentHeaders[index];
            if (!header) { continue; }
            if (header.canToggle) {
                for (const singleHeader of header.Headers) {
                    ids += (`${singleHeader.HeaderId},`);
                }
            } else {
                ids += (`${header.HeaderId},`);
            }
        }

        ids = ids.substring(0, ids.lastIndexOf(","));
    }

    return ids;
}
function* epsRpsScaleChanged({ selectedPerShare, isSaveSetting }) {
    try {
        if (isSaveSetting) {
            const { SymbolInfo, periodicity } = yield select(getDatagraphStates);
            const { financialRawData, financialBlockSetting } = yield select(getFinancialBlockState);
            const PePsData = new PePsDataObject();

            if (SymbolInfo.SymTypeEnum !== SymbolType.INDEX) {
                financialBlockSetting.PePsBlockView = selectedShareObj[selectedPerShare];
                SettingsStore.saveSettings();
                if (financialRawData) {
                    PePsData.setCurrentPePsView(financialBlockSetting.PePsBlockView, financialRawData.PeData, periodicity, financialBlockSetting.BlockSize, SymbolInfo.SymTypeEnum);
                }
                yield put({
                    type: ActionTypes.UPDATE_UI_ON_PE_TOGGLE,
                    PePsData
                })
            }
        }
    } catch (error) {
        console.log(`Error occurs in FinancialBlockSaga.js, epsRpsScaleChanged ${error}`)
    }
}

function checkSymbolType(SymbolInfo) {
    let isEnable = false
    switch (SymbolInfo.SymTypeEnum) {
        case SymbolType.WONINDUSTRYGROUP197:
        case SymbolType.INTERNATIONALINDEX:
        case SymbolType.WONSECTOR11:
        case SymbolType.INDEX:
        case SymbolType.WONMAJORINDUSTRY89:
        case SymbolType.ECONOMICINDICATOR:
        case SymbolType.FUND:
        case SymbolType.FUTURE:
        case SymbolType.COMMODITY:
        case SymbolType.INDEXNOINTRADAYVOL:
        case SymbolType.INDEXNOINTRADAYDATA:
        case SymbolType.INDEXNOINTRADAYVOLDATA:
        case SymbolType.HISTORICINDEX:
        case SymbolType.CURRENCY:
        case SymbolType.EXCHANGERATE:
        case SymbolType.GIC:
        case SymbolType.WONSUBSECTOR33:
        case SymbolType.FUTURECONTRACTS:
        case SymbolType.CASHSPOTS:
        case SymbolType.CRYPTOCURRENCY:
            isEnable = true;
            break;
        default:
            break;
    }
    return isEnable;
}

/******************** WATCHER ********************/
export function* watcherPrepareFinancialApiData() {
    yield takeLatest(ActionTypes.FINANCIAL_BLOCK_GET_API_DATA, getFinancialApiData)
}
export function* watcherPrepareFinancialData() {
    yield takeLatest(TimelineConstant.ActionTypes.PRICE_PANEL_RESPONSE_READY_TIMELINE, onFinancialDataReceived)
}
export function* watcherToggleHeader() {
    yield takeLatest(ActionTypes.TOGGLE_HEADER, toggleFinancialHeader)
}
export function* watcherOnPageChange() {
    yield takeLatest(ActionTypes.FINANCIAL_PAGE_CHANGED, updatePage)
}
export function* watcherFinancialPeToggle() {
    yield takeLatest(ActionTypes.FINANCIAL_PE_TOGGLE, togglePePsData)
}
export function* watcherSeletedMenuItem() {
    yield takeLatest(ActionTypes.SELECT_MENU_ITEM, onSelectMenuItem)
}
export function* watcherEpsRpsScaleChanged() {
    yield takeLatest(PriceChartConstants.ActionTypes.SWITCH_FUNDAMENTAL_LINE_LABEL, epsRpsScaleChanged)
}
export function* watcherFundamentalLineChanged() {
    yield takeLatest(PriceChartConstants.ActionTypes.UPDATE_SELECT_PER_SHARE, epsRpsScaleChanged)
}