import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BlockType from "../../../../Constants/BlockType";
import {  CustomTiDialogConstant } from "../../../../Constants/CustomTiDialogConstants";
import { DatagraphConst } from "../../../../Utils/DatagraphHelper";
import GraphType from "GraphType";
import { IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "SettingsStore";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getIndicatorStates } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper";
import { updateIndicatorData, updateIndicatorGraphData, updateIndicatorSettings } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";

const { ActionTypes } = IndicatorsConstants;
// let lastnodeIndex = 500;
function getRsiChart(hsfResults, chartType, firstNodeIndex, startXPoint, scale) {
    let prcLength = hsfResults.length;
    const chartData = [];

    for (let j = firstNodeIndex; j < prcLength; j++) {
        if (startXPoint < 0) { break; }
        if (isNaN(hsfResults[j].RSI)) {
            if (hsfResults[j].Close && hsfResults[j].Close > 0) {
                chartData.push({
                    Date: hsfResults[j].Date,
                    yPrice: 0,
                    xAxis: startXPoint,
                    yValue: -1
                });
            }
            startXPoint -= DatagraphConst.nodeWidth;
            continue;
        }
        const yPrice = scale.ComputeY(hsfResults[j].RSI);
        const info = {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: startXPoint,
            yValue: hsfResults[j].RSI
        };
        startXPoint -= DatagraphConst.nodeWidth;
        chartData.push(info);
    }
    prcLength = chartData.length;
    for (let i = prcLength - 1; i > 0; i--) {
        if (chartData[i].yValue < 0) {
            chartData[i].yValue = chartData[i + 1] && chartData[i + 1].yValue;
            chartData[i].yPrice = chartData[i + 1] && chartData[i + 1].yPrice;
        }
    }
    return chartData;
}
// PANWEB-2900
// Fixing the issues created by PANWEB-2539. This code now matches to the Desktop
function CalculateRSI(inhsfResults, range = 14, SettingDict) {
    inhsfResults.map((x) => { if (x.RSI !== undefined) { x.RSI = undefined; } return x; });
    const hsfResults = inhsfResults.filter((t) => t._volume > -1 && t.Close !== undefined);
    let tot = hsfResults.length - (range + 1);
    if (tot < range) {
        if(hsfResults.length > 3) {
            tot = Math.floor(hsfResults.length / 2) + 1;
            SettingDict.Length = tot;
            SettingsStore.saveSettings();
        } 
        else {
            return;
        }
    }
    const xDu = [];
    const xDd = [];

    let prevValue = hsfResults[0];

    for (let count = 1; count < tot; count++) {
        if (hsfResults[count] === null) { break; }
        if (hsfResults[count].Close === 0) { continue; }

        const techCalcInValuese = hsfResults[count];

        xDu[count - 1] = 0;
        xDd[count - 1] = 0;
        if (prevValue.Close !== undefined && prevValue.Close > techCalcInValuese.Close) {
            if (techCalcInValuese.Close > 0) {
                xDu[count - 1] = (prevValue.Close - techCalcInValuese.Close) * 1000;
            }
        }
        else {
            if (prevValue.Close !== undefined && techCalcInValuese.Close > 0) {
                xDd[count - 1] = (techCalcInValuese.Close - prevValue.Close) * 1000;
            }
        }
        prevValue = techCalcInValuese;
    }

    let i = 0;
    const xDuLength = xDu.length;
    for (; i < tot; i++) {
        let nc = 0;
        let a = 0;
        let j = i;
        for (; j < xDuLength && j < (i + range) && j < tot; j++) {
            nc = nc + xDu[j];
            a = a + xDu[j] + xDd[j];
        }
        if (a > 0 && nc > 0) {
            hsfResults[i].RSI = ((nc / a) * 100);
        } else {
            hsfResults[i].RSI = 0;
        }
    }
    tot = inhsfResults.length;
    for (i = 0; i < tot; i++) {
        if (!inhsfResults[i].IsVisible) {
            continue;
        }
        if (inhsfResults[i].RSI === undefined && inhsfResults[i + 1]) {
            inhsfResults[i].RSI = inhsfResults[i + 1].RSI;
        }
    }
    return inhsfResults;
}

function initRsiScale(graphType, indicatorsHeight) {
    const scale = new ArithmaticScale();
    scale.InitScale(-20, 100, indicatorsHeight, graphType, 2, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true, true);
    return scale;
}

function* PrepareData({chartType}) {
    try {
        const { SettingDict } = yield select(getIndicatorStates);
        const { pricePanelData, indicatorsHeight, startXPoint, padding } = yield select(getDatagraphStates);
        const graphData = CalculateRSI(pricePanelData.HsfData.HSFResults, SettingDict[BlockType.RSI].Length, SettingDict[BlockType.RSI]);
        yield call(processData, graphData, indicatorsHeight, BlockType.RSI, SettingDict[chartType], startXPoint, padding);
    }
    catch (error) {
        console.error("Error occurs in RSIIndicatorSaga.js, PrepareData", error);
    }
}

function* processData(graphData, indicatorsHeight, chartType = BlockType.RSI, SettingDict, startXPoint, padding) {
    if (graphData) {
        const scale  = initRsiScale(GraphType.Weekly, indicatorsHeight);
        let DataSource;
        const indicatorVisuals = { [chartType]: [] };
        const indicatorLabels = { [chartType]: { labels: [] } };
        let rsiLineColor, rsiWeight, overBoughtLineColor, overBoughtWeight, overSoldLineColor, overSoldWeight;
        SettingDict.RSILineColor.forEach((item) => {
            rsiLineColor = ThemeHelper.getThemedBrush(item.color);
            rsiWeight = item.weight;
        });
        SettingDict.OverboughtColor.forEach((item) => {
            overBoughtLineColor = ThemeHelper.getThemedBrush(item.color);
            overBoughtWeight = item.weight;
        });
        SettingDict.OversoldColor.forEach((item) => {
            overSoldLineColor = ThemeHelper.getThemedBrush(item.color);
            overSoldWeight = item.weight;
        });
        DataSource = scale.ComputeY(SettingDict.Oversold);
        indicatorVisuals[chartType].push({ isHorizontalLine: true, key: "Line1", x2: startXPoint, y1: DataSource, y2: DataSource, strokeWidth: 1, dashArray: [5, 2], lineColor: overSoldLineColor, LineThickness: overSoldWeight, Draggable: false, lineID: IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold] });
        DataSource = scale.ComputeY(SettingDict.Overbought);
        indicatorVisuals[chartType].push({ isHorizontalLine: true, key: "Line2", x2: startXPoint, y1: DataSource, y2: DataSource, strokeWidth: 1, dashArray: [5, 2], lineColor: overBoughtLineColor, LineThickness: overBoughtWeight, Draggable: false, lineID: IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought] });
        DataSource = getRsiChart(graphData, chartType, padding, startXPoint, scale);
        indicatorVisuals[chartType].push({ key: "Line3", DataSource, LineColor: rsiLineColor, LineThickness: rsiWeight, Draggable: false, lineID: BlockType.RSI });

        indicatorLabels[chartType].isScale = true;
        indicatorLabels[chartType].class = "tech-indicators";
        indicatorLabels[chartType].LabelMenu = { label: IndicatorLabelTranslateHelper[BlockType.RSI], isTICustomModal: true, isShowSettingsDialog: true }
        indicatorLabels[chartType].scaleText = `(${SettingDict.Length})`;
        yield put(updateIndicatorData(indicatorVisuals, indicatorLabels, chartType, scale));
    }
}

function* initIndicator() {
    try {
        const { pricePanelData, viewsSettings, majorPeriodicity, indicatorsHeight, startXPoint, padding } = yield select(getDatagraphStates);
        const RsiChartSettings = viewsSettings.RsiChartSettings;
        const SettingDict = {}
        SettingDict[BlockType.RSI] = RsiChartSettings ? RsiChartSettings[majorPeriodicity] : {};
        yield put(updateIndicatorSettings(SettingDict));
        if (RsiChartSettings && SettingDict[BlockType.RSI].IsAvailable) {
            const graphData = CalculateRSI(pricePanelData.HsfData.HSFResults, SettingDict[BlockType.RSI].Length, SettingDict[BlockType.RSI]);
            yield put(updateIndicatorGraphData(graphData, BlockType.RSI));
            yield call(processData, graphData, indicatorsHeight, BlockType.RSI, SettingDict[BlockType.RSI], startXPoint, padding );
        }
    }
    catch (error) {
        console.error("Error occurs in RSIIndicatorSaga.js, initIndicator", error);
    }
}
//===================================================

export function* watchInitRSIIndicator() {
    yield takeLatest(ActionTypes.PROCESS_INDICATOR, initIndicator)
}

export function* watchPrepareRSIIndicatorData() {
    yield takeLatest(ActionTypes.PREPARE_RSI_INDICATOR_DATA, PrepareData);
}

export function* watchToggleRSIChart() {
    // yield takeLatest(ActionTypes.TOGGLE_YTD_CHART, togglePTOECHart)
}

export function* watchUpdateRSIIndicatorHeight() {
    yield takeLatest(PriceChartConstants.ActionTypes.INDICATOR_RESIZE, initIndicator)
}