import AnnotationUtil from 'Utils/AnnotationUtil';
import GraphicPoints from '../../../../Models/AnnotationModels/GraphicsPoints';
import { PrintMode } from '../../../../print/printmode';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import { safe } from '../../../ErrorModel';
import { ShareAccessType } from '../../../../Constants/ShareConstants';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { AnnotationConstant, TemporaryId } from "Constants/AnnotationConstants.js";
import {
    getAnnotationIsDrawing, getAnnotationSelectedId, getTriangle, getAnnotationMenu,
    getTargetLayer, getAnnotationChartDraw, getActiveAnchor, getLayersList, getAnnotationDimension,
    getAnnotationSelected
} from '../../../../Reducers/AnnotationReducers/selectors';
import { takeLatest, select, put, call, takeEvery } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;


/********************** Model Logic *************************/

function* handleClick({ e }) {
    try {
        let triangle = null, points = null;
        const annotationMenu = yield select(getAnnotationMenu);
        let isDrawing = yield select(getAnnotationIsDrawing);
        let annotSelectedId = yield select(getAnnotationSelectedId);
        const triangles = yield select(getTriangle);;
        const targetLayer = yield select(getTargetLayer);

        if (isDrawing) {
            isDrawing = !isDrawing
            triangle = triangles.find((itm) => itm.id === annotSelectedId);

            let dir = '';
            if (triangle.x < triangle.endMouseX && triangle.y > triangle.endMouseY) {
                dir = 'NE';
            } else if (triangle.x > triangle.endMouseX && triangle.y < triangle.endMouseY) {
                dir = 'SW';
            } else if (triangle.x > triangle.endMouseX && triangle.y > triangle.endMouseY) {
                dir = 'NW';
            } else {
                dir = 'SE';
            }
            triangle = Object.assign(triangle, {
                direction: dir
            })

            yield call(updateTriangle, isDrawing, triangle);
            yield safe(call(saveTriangle), "GraphicsTriangle.js", "saveTriangle");
            return;
        }

        if (e.target !== e.target.getStage()) {
            annotSelectedId = e.target.id();
            triangle = triangles.find((itm) => itm.id === annotSelectedId);
            yield call(updateTriangle, isDrawing, triangle);
            return;
        }

        points = new GraphicPoints();
        const annotId = TemporaryId.Id;
        triangle = {
            ...annotationMenu.ShapesModelInfo,
            id: annotId,
            layerID: targetLayer.layerID,
            type: RayAnnotationType.TRIANGLE_AnnotationType,
            x: e.evt.layerX,
            y: e.evt.layerY,
            points: points,
            width: 0,
            height: 0,
            isEditable: false,
            endMouseX: 0,
            endMouseY: 0,
            isReadOnlyAnnotation: false
        }
        isDrawing = true;

        yield call(addTriangle, isDrawing, triangle);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTriangle.js, handleClick ${error}`);
    }
}

function* handleMouseMove({ e }) {
    try {
        let triangle = null, points = null, newWidth = 0, newHeight = 0;
        const triangles = yield select(getTriangle);
        const isDrawing = yield select(getAnnotationIsDrawing);
        const annotSelectedId = yield select(getAnnotationSelectedId);

        if (isDrawing && triangles.length > 0) {
            const triShape = triangles.find((itm) => itm.id === annotSelectedId);
            newWidth = e.evt.layerX - triShape.x;
            newHeight = e.evt.layerY - triShape.y;
            points = triShape.points;
            points = AnnotationUtil.getAnchorPoints(newWidth, newHeight, RayAnnotationType.TRIANGLE_AnnotationType, points);
            triangle = Object.assign(triShape, {
                x: triShape.x,
                y: triShape.y,
                points: points,
                width: newWidth,
                height: newHeight,
                endMouseX: e.evt.layerX,
                endMouseY: e.evt.layerY,
            })
        }
        yield call(updateTriangle, isDrawing, triangle);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTriangle.js, handleMouseMove ${error}`);
    }
}

function* addTriangle(isDrawing, triangle) {
    try {
        yield put({
            type: ActionTypes.HANDLE_TRIANGLE_CLICK_SUCCESS,
            triangle: triangle,
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: triangle
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTriangle.js, addTriangle ${error}`);
    }
}

function* updateTriangle(isDrawing, triangle) {
    try {
        yield put({
            type: ActionTypes.HANDLE_TRIANGLE_DRAW_SUCCESS,
            triangle: triangle,
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: triangle
        });

        let layersList = yield select(getLayersList);
        layersList = JSON.parse(JSON.stringify(layersList));
        const selectedAnnot = yield select(getAnnotationSelected);
        for (const x in layersList) {
            layersList[x].layerID === selectedAnnot.layerID ? layersList.push(layersList.splice(x, 1)[0]) : 0;
        }
        yield put({
            type: ActionTypes.UPDATE_LAYERS_LIST,
            LayersList: layersList
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTriangle.js, updateTriangle ${error}`);
    }
}

function* saveTriangle() {
    yield put({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    })
}

function* updateTriangleFromApi({ triangle }) {
    try {
        yield put({
            type: ActionTypes.HANDLE_TRIANGLE_DRAW_SUCCESS,
            triangle: triangle
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTriangle.js, updateTriangleFromApi ${error}`);
    }
}

function* processTriangleData({ data }) {
    try {
        const newWidth = data.endPoint.x - data.startPoint.x;
        const newHeight = data.endPoint.y - data.startPoint.y;
        let points = new GraphicPoints();
        points = AnnotationUtil.getAnchorPoints(newWidth, newHeight, data.annotationType, points);
        const convertedBackgroundColor = ThemeHelper.convertArgbToHex(data.backgroundColor);
        const convertedForegroundColor = ThemeHelper.convertArgbToHex(data.foregroundColor);

        let dir = '';
        if (data.startPoint.x < data.endPoint.x && data.startPoint.y > data.endPoint.y) {
            dir = 'NE';
        } else if (data.startPoint.x > data.endPoint.x && data.startPoint.y < data.endPoint.y) {
            dir = 'SW';
        } else if (data.startPoint.x > data.endPoint.x && data.startPoint.y > data.endPoint.y) {
            dir = 'NW';
        } else {
            dir = 'SE';
        }
        const layersList = yield select(getLayersList);
        const isReadOnlyAnnotation = layersList.filter((i) => i.layerID === data.layerID)[0].shareAccess === ShareAccessType.SHARE_READONLY;

        const triangle = {
            id: data.annotationID,
            layerID: data.layerID,
            type: data.annotationType,
            x: data.startPoint.x,
            y: data.startPoint.y,
            width: newWidth,
            height: newHeight,
            points: points,
            borderColor: `P${convertedForegroundColor.color.substring(1)}`,
            borderWeight: data.penSize,
            bgColor: `P${convertedBackgroundColor.color.substring(1)}`,
            opacity: convertedBackgroundColor.opacity,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            isDeleted: false,
            strokeStyle: data.strokeType,
            endMouseX: data.endPoint.x,
            endMouseY: data.endPoint.y,
            isBorderChecked: data.isBorderVisible,
            direction: dir,
            isReadOnlyAnnotation: isReadOnlyAnnotation

        }

        yield put({
            type: ActionTypes.HANDLE_TRIANGLE_CLICK_SUCCESS,
            triangle: triangle
        });
        yield put({
            type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
            annotation: triangle.id
        });

        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTriangle.js, processTriangleData ${error}`);
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        PrintMode.printing && console.log("PRINT:--Tracker--*** Data processing Error in GraphicsTriangle *** for -", TimeTrackingWindow.currentSymbol);
    }
}

function* handleTriangleDrag({ e }) {
    try {
        const { isDragging } = yield select(getAnnotationChartDraw);
        const chartDimension = yield select(getAnnotationDimension);
        let triangle = null;
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const triangles = yield select(getTriangle);
        const curTriangle = triangles.find((itm) => itm.id === annotSelectedId); // current triangle to be modified
        let triangleStart = {}, triangleEnd = {};
        const dragPos = {
            x: e.currentTarget.x(), y: e.currentTarget.y()
        }
        if (isDragging) {
            // Fix for PANWEB-6190
            if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                e.currentTarget.attrs.x = curTriangle.x;
                e.currentTarget.attrs.y = curTriangle.y;
                return;
            }
            else {
                triangleStart = {
                    x: dragPos.x, y: dragPos.y
                }
                triangleEnd = {
                    x: dragPos.x + curTriangle.width, y: dragPos.y + curTriangle.height
                }
            }
        }

        triangle = Object.assign(curTriangle, {
            x: triangleStart.x,
            y: triangleStart.y,
            endMouseX: triangleEnd.x,
            endMouseY: triangleEnd.y
        });
        yield call(updateTriangle, false, triangle);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTriangle.js, handleTriangleDrag ${error}`);
    }
}
function* handleTriAnchorDrag({ e }) {
    try {
        const chartDimension = yield select(getAnnotationDimension);

        // Fix for PANWEB-6207
        if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
            return;
        }

        const annotSelectedId = yield select(getAnnotationSelectedId);
        const triangles = yield select(getTriangle);
        const activeAnchor = yield select(getActiveAnchor);
        let tri = null;
        let triEnd = null;
        let triStart = null;
        const curTri = triangles.find((itm) => itm.id === annotSelectedId);
        if (activeAnchor === "top-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                if (curTri.direction === 'NW' || curTri.direction === 'SE') {
                    e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
                }
                else if (curTri.direction === 'NE' || curTri.direction === 'SW') {
                    e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
                }
            }
            triStart = { x: curTri.x, y: e.evt.layerY }
            triEnd = { x: e.evt.layerX, y: curTri.endMouseY }
        }
        if (activeAnchor === "top-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                if (curTri.direction === 'NW' || curTri.direction === 'SE') {
                    e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
                }
                else if (curTri.direction === 'NE' || curTri.direction === 'SW') {
                    e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
                }
            }
            triStart = { x: e.evt.layerX, y: e.evt.layerY }
            triEnd = { x: curTri.endMouseX, y: curTri.endMouseY }
        }
        if (activeAnchor === "bottom-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                if (curTri.direction === 'NW' || curTri.direction === 'SE') {
                    e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
                }
                else if (curTri.direction === 'NE' || curTri.direction === 'SW') {
                    e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
                }
            }
            triStart = { x: e.evt.layerX, y: curTri.y }
            triEnd = { x: curTri.endMouseX, y: e.evt.layerY }
        }
        if (activeAnchor === "bottom-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                if (curTri.direction === 'NW' || curTri.direction === 'SE') {
                    e.currentTarget.getStage().container().style.cursor = 'nwse-resize';
                }
                else if (curTri.direction === 'NE' || curTri.direction === 'SW') {
                    e.currentTarget.getStage().container().style.cursor = 'nesw-resize';
                }
            }
            triStart = { x: curTri.x, y: curTri.y }
            triEnd = { x: e.evt.layerX, y: e.evt.layerY }
        }
        if (activeAnchor === "top-middle") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ns-resize';
            }
            triStart = { x: curTri.x, y: e.evt.layerY }
            triEnd = { x: curTri.endMouseX, y: curTri.endMouseY }
        }
        if (activeAnchor === "bottom-middle") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ns-resize';
            }
            triStart = { x: curTri.x, y: curTri.y }
            triEnd = { x: curTri.endMouseX, y: e.evt.layerY }
        }
        if (activeAnchor === "middle-left") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ew-resize';
            }
            triStart = { x: e.evt.layerX, y: curTri.y }
            triEnd = { x: curTri.endMouseX, y: curTri.endMouseY }
        }
        if (activeAnchor === "middle-right") {
            if (e.currentTarget !== undefined && e.currentTarget !== null) {
                e.currentTarget.getStage().container().style.cursor = 'ew-resize';
            }
            triStart = { x: curTri.x, y: curTri.y }
            triEnd = { x: e.evt.layerX, y: curTri.endMouseY }
        }
        const newWidth = triEnd.x - triStart.x;
        // if(triEnd.x <= triStart.x){
        //     newWidth = triStart.x - triEnd.x;
        // }
        const newHeight = triEnd.y - triStart.y;
        // if(triEnd.y <= triStart.y){
        //     newHeight = triStart.y - triEnd.y;
        // }

        let points = curTri.points;
        points = AnnotationUtil.getAnchorPoints(newWidth, newHeight, RayAnnotationType.TRIANGLE_AnnotationType, points);

        tri = Object.assign(curTri, {
            x: triStart.x,
            y: triStart.y,
            endMouseX: triEnd.x,
            endMouseY: triEnd.y,
            id: curTri.id,
            width: newWidth,
            height: newHeight,
            points: points
        });
        yield call(updateTriangle, false, tri);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTriangle.js, handleTriAnchorDrag ${error}`);
    }
}
function* handleAnchorLeave() {
    try {
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const triangles = yield select(getTriangle);
        let triangle = null;
        triangle = triangles.find((itm) => itm.id === annotSelectedId);
        let dir = '';
        if (triangle.x < triangle.endMouseX && triangle.y > triangle.endMouseY) {
            dir = 'NE';
        } else if (triangle.x > triangle.endMouseX && triangle.y < triangle.endMouseY) {
            dir = 'SW';
        } else if (triangle.x > triangle.endMouseX && triangle.y > triangle.endMouseY) {
            dir = 'NW';
        } else {
            dir = 'SE';
        }
        triangle = Object.assign(triangle, {
            direction: dir
        })
        yield call(updateTriangle, false, triangle);
        yield safe(call(saveTriangle), "GraphicsTriangle.js", "saveTriangle");
    }
    catch (error) {
        console.log(`Error occurs in GraphicsTriangle.js, handleAnchorLeave ${error}`);
    }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/


export function* watchHandleTriangleClick() {

    yield takeLatest(ActionTypes.HANDLE_TRIANGLE_CLICK, handleClick);
};

export function* watchHandleTriangleDraw() {

    yield takeLatest(ActionTypes.HANDLE_TRIANGLE_DRAW, handleMouseMove);
};

export function* watchProcessTriangleData() {
    yield takeEvery(ActionTypes.PROCESS_TRIANGLE_DATA, processTriangleData);
};

export function* watchTriangleDrag() {
    yield takeLatest(ActionTypes.HANDLE_TRIANGLE_DRAG, handleTriangleDrag)
}

export function* watchTriAnchordrag() {
    yield takeLatest(ActionTypes.HANDLE_TRI_ANCHOR_DRAG, handleTriAnchorDrag)
}

export function* watchTriAnchorLeave() {
    yield takeLatest(ActionTypes.HANDLE_TRI_ANCHOR_LEAVE, handleAnchorLeave);
};

/******************************************************************************/