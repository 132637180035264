import DateHelper from "DateHelper";
import graphTypeEnum from "../../Constants/GraphType.js";

class QuarterlyData {
    QuarterlyDataValues(quarterlyDataIn, hsfData, quarterlyLineData, type, xAxis, fixed, multiplier, stockInformation, isRps, nav, rptOk = true, isHistoric = false) {
        const count = quarterlyDataIn.length;
        if (count < 1) {
            return multiplier;
        }
        const hsfdataLength = hsfData.length;
        const qtrAry = [];
        const showQtr = type !== graphTypeEnum.Quarterly && type !== graphTypeEnum.Annual ? 1 : 0;
        const showRpt = type !== graphTypeEnum.Monthly && type !== graphTypeEnum.Quarterly && type !== graphTypeEnum.Annual && rptOk ? 1 : 0;
        let xPosn = 0;
        let firstValidPrice = 0;

        for (; firstValidPrice < hsfdataLength; firstValidPrice++) {
            if (hsfData[firstValidPrice].IsVisible) {
                break;
            }
        }
        xPosn = firstValidPrice;
        let switched = false;
        let pDate = DateHelper.parseJsonDate(quarterlyDataIn[0].FiscalDate, stockInformation.MomentZoneID);;

        for (let node = 0; node < count; node++) {
            if (quarterlyDataIn[node].QtrData === 0) {
                continue;
            }
            let fourQtrs = 0;
            let qtrHigh = 0;
            let qtrLow = 0;
            const actual = quarterlyDataIn[node].Type === undefined || quarterlyDataIn[node].Type < 1 ? 0 : 1;
            const xDate = DateHelper.parseJsonDate(quarterlyDataIn[node].FiscalDate, stockInformation.MomentZoneID);
            const rDate = DateHelper.parseJsonDate(quarterlyDataIn[node].ReportDate, stockInformation.MomentZoneID);
            const disp = isRps ? quarterlyDataIn[node].Display : (node > 0 ? quarterlyDataIn[node - 1].Display : 1);
            const dow = rDate.getDay();
            const suprisePctDiff = quarterlyDataIn[node].EpsSuprisePctDiff !== undefined ? quarterlyDataIn[node].EpsSuprisePctDiff : null;
            const surprise = quarterlyDataIn[node].EpsSurprise !== undefined ? quarterlyDataIn[node].EpsSurprise : null;
            if (type === graphTypeEnum.Weekly) {
                if (dow < 5) {
                    rDate.setDate(rDate.getDate() + 5 - dow);
                }
            }
            if (!nav && stockInformation.Symbol !== "0DJU" && stockInformation.Symbol !== "0DJUA") {
                if (count - node > 3) {
                    for (let xe = node; xe < node + 4; xe++) {
                        fourQtrs += isNaN(quarterlyDataIn[xe].QtrData) ? 0 : quarterlyDataIn[xe].QtrData;
                        if (!isHistoric) {
                            qtrHigh += isNaN(quarterlyDataIn[xe].QtrDataHigh) || quarterlyDataIn[xe].Type > 0
                                ? isNaN(quarterlyDataIn[xe].QtrData)
                                    ? 0
                                    : quarterlyDataIn[xe].QtrData
                                : quarterlyDataIn[xe].QtrDataHigh;
                            qtrLow += isNaN(quarterlyDataIn[xe].QtrDataLow) || quarterlyDataIn[xe].Type > 0
                                ? isNaN(quarterlyDataIn[xe].QtrData)
                                    ? 0
                                    : quarterlyDataIn[xe].QtrData
                                : quarterlyDataIn[xe].QtrDataLow;
                        }
                    }
                } else {
                    break;
                }
            } else {
                fourQtrs = isNaN(quarterlyDataIn[node].QtrData) ? 0 : quarterlyDataIn[node].QtrData;
                if (!isHistoric) {
                    qtrHigh = isNaN(quarterlyDataIn[node].QtrDataHigh) || quarterlyDataIn[node].Type > 0
                        ? isNaN(quarterlyDataIn[node].QtrData)
                            ? 0
                            : quarterlyDataIn[node].QtrData
                        : quarterlyDataIn[node].QtrDataHigh;
                    qtrLow = isNaN(quarterlyDataIn[node].QtrDataLow) || quarterlyDataIn[node].Type > 0
                        ? isNaN(quarterlyDataIn[node].QtrData)
                            ? 0
                            : quarterlyDataIn[node].QtrData
                        : quarterlyDataIn[node].QtrDataLow;
                }
            }
            const info =
            {
                Date: xDate,
                ReportDate: rDate,
                QtrData: fourQtrs,
                QtrDataHigh: qtrHigh,
                QtrDataLow: qtrLow,
                Actual: actual,
                ShowQtr: showQtr,
                ShowRpt: 0,
                Display: switched ? disp : 1,
                surprise: surprise,
                suprisePctDiff: suprisePctDiff
            };
            switched = actual === 1;
            quarterlyLineData.push(info);

            if ((type === graphTypeEnum.Daily || type === graphTypeEnum.Weekly) && actual === 1) {
                const tempDate = new Date(rDate);
                if (tempDate.getFullYear() <= 1970) {
                    info.ReportDate = new Date(xDate);
                } else {
                    info.ReportDate = tempDate;
                }
                if (showRpt &&
                    tempDate.getFullYear() > 1970 &&
                    tempDate >= xDate &&
                    tempDate <= pDate) {
                    info.ShowRpt = 1;
                } else {
                    info.ShowRpt = 0;
                }
            }
            pDate = new Date(xDate);
            let hDate = hsfData[firstValidPrice].Date;
            if (xDate <= hDate) {
                for (; xPosn < hsfdataLength; xPosn++) {
                    xAxis -= 4;
                    if (xAxis < 0){ 
                        break;
                    }
                    hDate = hsfData[xPosn].Date;
                    if (hDate <= xDate && hsfData[xPosn]._volume > 0) {
                        qtrAry.push(hsfData[xPosn].Close / fourQtrs);
                        break;
                    }
                }
            }
        }

        if (!fixed) {
            const ml = this.median(qtrAry.filter((t) => t > 0));
            if (ml && ml !== Infinity) {
                return ml > 10 ? Math.round(ml) : ml.toFixed(2);
            } else {
                return multiplier;
            }
        }
        else {
            return multiplier;
        }
    }
    median(values) {
        values.sort((a, b) => (a - b));

        const half = Math.round(values.length / 2);

        if (values.length % 2){
            return values[half];
        }
        else{
            return (values[half - 1] + values[half]) / 2.0;
        }
    }
}

export default QuarterlyData; 