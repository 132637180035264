import { combineReducers } from 'redux';
import RiPanelBrokerReports from './RiPanelBrokerReports';
import RIPanelOneilReducers from './RIPanelOneilReducers';
import { RiPanelReducer } from './RiPanelReducer';
import RiPanelCheckListInfo from './RiPanelCheckListInfo';
import RiPanelConstituentsReducer from './RiPanelContituents';
import RiPanelFactorInfo from './RiPanelFactorInfo';
import RiPanelAllocationInfo from './RiPanelAllocationInfo';
import RiPanelHoldingsReducer from './RiPanelHoldings';
import RiPanelNewsInfo from './RiPanelNewsInfo';
import RiPanelManagementInfo from './RiPanelManagementInfo';
import RiPanelSimilarFundsReducer from './RiPanelSimilarFunds';
import RiPanelOwnershipInfo from './RiPanelOwnership/RiPanelOwnershipInfo';
import RiPanelHub from './RiPanelOwnership/RiPanelHub';

const RelatedInfoPanelReducers = combineReducers({
    RiPanelReducer,
    RIPanelOneilReducers,
    RiPanelBrokerReports,
    RiPanelCheckListInfo,
    RiPanelNewsInfo,
    RiPanelFactorInfo,
    RiPanelAllocationInfo,
    RiPanelHoldingsReducer,
    RiPanelManagementInfo,
    RiPanelOwnershipInfo,
    RiPanelHub,
    RiPanelSimilarFundsReducer,
    RiPanelConstituentsReducer
});


export default RelatedInfoPanelReducers;