import React, { Component, PureComponent } from 'react';
import ReactDOM from "react-dom";
import { isEmpty, isEqual, map, isString } from "underscore";
import { Button, ButtonGroup, ButtonToolbar, Overlay, Dropdown, MenuItem } from "react-bootstrap";
import LiquidityFilterStore from "LiquidityFilterStore";
import { Range, Handle } from "rc-slider";
import numeral from "numeral";
import LocalizationStore from 'LocalizationStore';
import onClickOutside from "react-onclickoutside";
import FormatterUtil from "FormatterUtil";
import KeyCodes from '../../Constants/KeyCodes';

class LiquidityRatingsFilter extends Component {
  constructor(props) {
    super(props);
    this.liquidityFilterStoreChange = this.liquidityFilterStoreChange.bind(this);
    this.onDocumnetKeyPress = this.onDocumnetKeyPress.bind(this);
    this.handleDocumentOutsideClick = this.handleDocumentOutsideClick.bind(this);
    this.filterOn = this.filterOn.bind(this);
    this.toggleFilterOnOff = this.toggleFilterOnOff.bind(this);
    this.handleClickToggle = this.handleClickToggle.bind(this);
    this.onHide = this.onHide.bind(this);
    this.state = {
      updateCmp: true
    }
    this.state = LiquidityFilterStore.getStates();
  }

  componentDidMount() {
    LiquidityFilterStore.addChangeListener(this.liquidityFilterStoreChange);
    document.addEventListener("keyup", this.onDocumnetKeyPress, false);
  }

  componentWillUnmount() {
    this.handleClickToggle(false);
    LiquidityFilterStore.saveFilterValue();
    LiquidityFilterStore.removeChangeListener(this.liquidityFilterStoreChange);
    document.removeEventListener("keyup", this.onDocumnetKeyPress, false);
  }

  onHide() {
    this.setState({ showSettingsWindow: false });
  }

  liquidityFilterStoreChange() {
    let states = LiquidityFilterStore.getStates();
    this.setState(states);
  };

  onDocumnetKeyPress(e) {
    LiquidityFilterStore.onKeyPressUpdateSlider(e.keyCode);
  }


  handleClickOutside(e) {
    this.handleDocumentOutsideClick(e);
  }

  handleDocumentOutsideClick(e) {
    if (this.props.openLiquidityRatingsFilterSetting) {
      try {
        const target = e.target || e.srcElement;
        if (isString(target.className)) {
          if (target.className.includes("liq-filter-btn")) return;
          else {
            this.handleClickToggle(false);
            this.props.closeLiqFilterWindow();
          }
        }
        else {
          this.handleClickToggle(false);
          this.props.closeLiqFilterWindow();
        }
      }
      catch (ex) {
      }
    }
    e.preventDefault();
    e.stopPropagation();
  };

  filterOn() {
    if (
      (this.state.mktCapFilterViewModel.highRangeValue == this.state.mktCapFilterViewModel.maxValueText && this.state.mktCapFilterViewModel.minValueText == this.state.mktCapFilterViewModel.lowRangeValue) &&
      (this.state.avolFilterViewModel.highRangeValue == this.state.avolFilterViewModel.maxValueText &&
        this.state.avolFilterViewModel.minValueText == this.state.avolFilterViewModel.lowRangeValue) && (this.state.ratingFilterTypeOptions1ViewModel.highRangeValue == this.state.ratingFilterTypeOptions1ViewModel.maxValueText && this.state.ratingFilterTypeOptions1ViewModel.minValueText == this.state.ratingFilterTypeOptions1ViewModel.lowRangeValue) && (this.state.ratingFilterTypeOptions2ViewModel.highRangeValue == this.state.ratingFilterTypeOptions2ViewModel.maxValueText && this.state.ratingFilterTypeOptions2ViewModel.minValueText == this.state.ratingFilterTypeOptions2ViewModel.lowRangeValue)) {

      if (this.state.isFilterOn) {
        this.setState({
          isFilterOn: false,
          isFilterManualOff: true
        }, LiquidityFilterStore.setIsFilterOn(false));
        LiquidityFilterStore.setIsFilterManualOff(true);
      }

    }
    else {
      if (!this.state.isFilterOn) {
        this.setState({
          isFilterOn: true,
          isFilterManualOff: false
        }, LiquidityFilterStore.setIsFilterOn(true));
        LiquidityFilterStore.setIsFilterManualOff(false);
      }

    }
  };

  toggleFilterOnOff() {
    if (this.state.isFilterManualOff && this.state.isFilterOn) {
      this.setState({
        isFilterOn: false
      }, LiquidityFilterStore.setIsFilterOn(false))
    }
    else {
      this.setState({
        isFilterOn: !this.state.isFilterOn
      }, LiquidityFilterStore.setIsFilterOn(!this.state.isFilterOn))
    }

  };

  handleClickToggle(defaultValue = true) {
    if (defaultValue) {
      this.setState({ showSettingsWindow: !this.state.showSettingsWindow }, LiquidityFilterStore.setShowSettingsWindow(!this.state.showSettingsWindow));
    }
    else {
      LiquidityFilterStore.setShowSettingsWindow(false);
    }
  };

  render() {
    if (!isEmpty(this.state)) {
      const openLiquidityRatingsFilterSetting = (this.props.openLiquidityRatingsFilterSetting ? 'block' : 'none');
      const isFilterOn = this.state.isFilterOn;
      return (
        <div className='liquidity-ratings-setting' style={{ display: openLiquidityRatingsFilterSetting }}>
          <div className="custom-panel-block liquidity-filter-setting">
            <div className="panel panel-default">
              <div className="panel-heading medium-bold">
                <span className="panel-title cap-header cursor-default">{LocalizationStore.getTranslatedData("CCG_Lqf_2", "LIQUIDITY FILTERS")}</span>
              </div>
              <div className="panel-body">
                <div className="inner-panel-body">
                  <div className="data-preferrence-block dialog-content set-value-setting-block">
                    <RangeSlider key="mktCap" statesData={this.state.mktCapFilterViewModel} isFilterOn={this.state.isFilterOn} isFilterManualOff={this.state.isFilterManualOff} />
                    <RangeSlider key="avolFilter" statesData={this.state.avolFilterViewModel} isFilterOn={this.state.isFilterOn} isFilterManualOff={this.state.isFilterManualOff} />
                  </div>
                  <div className=" setting-graph-middel">
                    <span className="setting-text small-bold cursor-default">{LocalizationStore.getTranslatedData("CCG_RatingFilter", "RATING FILTERS")}</span>
                    <button className="setting-button medium-margin-right  btn btn-sm btn-secondary" ref={(t) => { this.targetElm = t }} onClick={this.handleClickToggle}><span className="icn-cong "></span></button>
                  </div>
                  <div className="data-preferrence-block dialog-content set-value-setting-block ratedfilter">
                    <Overlay
                      show={this.state.showSettingsWindow}
                      onHide={this.onHide}
                      placement="bottom"
                      container={this}
                      target={() => ReactDOM.findDOMNode(this.targetElm)}
                    >
                      <SettingsWindow handleClickToggle={this.handleClickToggle} />
                    </Overlay>
                    <RangeSlider key="ratings1" statesData={this.state.ratingFilterTypeOptions1ViewModel} isFilterOn={this.state.isFilterOn} isFilterManualOff={this.state.isFilterManualOff} />
                    <RangeSlider key="ratings2" statesData={this.state.ratingFilterTypeOptions2ViewModel} isFilterOn={this.state.isFilterOn} isFilterManualOff={this.state.isFilterManualOff} />
                  </div>
                  <div className="on-off-switch">
                    <ButtonToolbar onClick={this.state.isFilterManualOff ? null : this.toggleFilterOnOff}>
                      {isFilterOn && !this.state.isFilterManualOff ?
                        <ButtonGroup bsSize="xsmall">
                          <Button className="btn-secondary small-bold on focus-inherit ">{LocalizationStore.getTranslatedData("CCG_ON", "ON")}</Button><Button className="btn-secondary small-bold"></Button>
                        </ButtonGroup>
                        :
                        <ButtonGroup bsSize="xsmall" >
                          <Button className="btn-secondary small-bold focus-inherit"></Button><Button className="btn-secondary small-bold ">{LocalizationStore.getTranslatedData("CCG_OFF", "OFF")}</Button>
                        </ButtonGroup>
                      }
                    </ButtonToolbar>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else {
      return <div></div>;
    }
  }
}



class RangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.statesData;
    this.getClosetIndex = this.getClosetIndex.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.getFormattedNumber = this.getFormattedNumber.bind(this);
    this.getClosetIndex = this.getClosetIndex.bind(this);
    this.getTextTitle = this.getTextTitle.bind(this);
    this.updateLowRangeSlider = this.updateLowRangeSlider.bind(this);
    this.updateHighRangeSlider = this.updateHighRangeSlider.bind(this);
    this.handleClickOfHandle = this.handleClickOfHandle.bind(this);
    this.handles = this.handles.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.statesData, this.state.statesData)) {
      this.setState(nextProps.statesData);
    }
  }

  updateSelectedRatingFilterType(key, id) {
    LiquidityFilterStore.updateSelectedRatingFilterType(key, id);
  }

  updateFilter(value, dataValueLow, dataValueHigh, id) {
    LiquidityFilterStore.updateDataFilterRange(value, dataValueLow, dataValueHigh, id);
    //this.filterOn();
    return;
  };

  getFormattedNumber(numberValue) {
    return FormatterUtil.formatNumber(numberValue); 
  } 

  getClosetIndex(num) {

    let closetNumber = this.state.intervalData.reduce((prev, curr) =>
      (Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev)
    );

    return this.state.intervalData.indexOf(closetNumber) != -1 ? this.state.intervalData.indexOf(closetNumber) : 0;

  };

  getTextTitle() {
    if (this.state.filterType == "value") {
      return <p className="xx-small-normal cursor-default">{this.state.title}</p>
    }
    else {
      return <div className="rating-filter-text xx-small-normal" >
        <Dropdown id="ranting-custom-drop-down">
          <CustomToggle bsRole="toggle">
            {this.state.selectedRatingFilterTypeOption.description}
          </CustomToggle>
          <Dropdown.Menu className="xx-small-normal ranting-custom-drop-down">
            <div className="rating-filter-text small-bold cap cursor-default" style={{ padding: 0 }}>{LocalizationStore.getTranslatedData("CCG_SELECTAFILTER","SELECT A FILTER")}</div>
            <MenuItem divider />
            {map(this.state.ratingFilterTypeOptions, (option, key) => {
              let classNameMenu = "xx-small-normal ";
              classNameMenu += option.isEnabled ? "" : " disabled";
              return <MenuItem key={key} eventKey={key} className={classNameMenu} onClick={() => option.isEnabled ? this.updateSelectedRatingFilterType(key, this.state.id) : null}><span className={option.isSelected ? "icn-right-icon" : ""}></span>{option.description}</MenuItem>
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    }
  };


  updateLowRangeSlider(valText) {
    if (valText === "" || valText === "-") {
      valText = this.state.minValueText;
    }
    if (valText !== "" && valText !== "-") {
      let val = numeral(valText).value();
      val = val > this.state.highRangeValue ? this.state.highRangeValue : val < this.state.minValueText ? this.state.minValueText : val;
      let lowSliderRangeValueIndex = this.getClosetIndex(val);
      /* Fixes - PANWEB-2374 */
      if(this.state.minValueText < val && lowSliderRangeValueIndex === 0) {
        lowSliderRangeValueIndex = 1;
      }
      this.updateFilter([lowSliderRangeValueIndex, this.state.highSliderRangeValueIndex], val, this.state.highRangeValue, this.state.id)
    }
  }

  updateHighRangeSlider(valText) {
    if (valText === "" || valText === "-") {
      valText = this.state.minValueText;
    }
    if (valText !== "" && valText !== "-") {
      let val = numeral(valText).value();
      val = val < this.state.lowRangeValue ? this.state.lowRangeValue : val > this.state.maxValueText ? this.state.maxValueText : val;
      let highSliderRangeValueIndex = this.getClosetIndex(val);
      /* Fixes - PANWEB-2374 */
      if(this.state.intervalData && this.state.maxValueText > val && highSliderRangeValueIndex === (this.state.intervalData.length - 1)) {
        highSliderRangeValueIndex = highSliderRangeValueIndex - 1;
      }
      this.updateFilter([this.state.lowSliderRangeValueIndex, highSliderRangeValueIndex], this.state.lowRangeValue, val, this.state.id)
    }
  }

  handleClickOfHandle(index) {
    LiquidityFilterStore.updateActiveSlider(index, this.state.id);
  }

  handles(props) {
    const handleClick = this.handleClickOfHandle;
    let p = {...props};
      p.handleClick = handleClick;
      return handle(p);
  }

  render() {
    const rangeValue = [this.state.lowSliderRangeValueIndex, this.state.highSliderRangeValueIndex];
    let lqFilterLineSliderClass = (this.state.highSliderRangeValueIndex == this.state.maxRangeSliderValue && this.state.minRangeSliderValue == this.state.lowSliderRangeValueIndex) ? "lqFilterLineSlider lqFilterLineSliderDisable " : "lqFilterLineSlider ";

    lqFilterLineSliderClass += !this.props.isFilterOn && !this.props.isFilterManualOff && !(this.state.highSliderRangeValueIndex == this.state.maxRangeSliderValue && this.state.minRangeSliderValue == this.state.lowSliderRangeValueIndex) ? " lqFilterLineSliderTracKDisable " : " ";
    let labelDivClass = "get-updated-value w100P ";
    labelDivClass += this.state.id == "avolFilterViewModel" || this.state.id == "ratingFilterTypeOptions2ViewModel" ? " bdr0 " : "";

    const filterTitle = this.getTextTitle();

    return (
      <div>
        {filterTitle}
        <div className="set-value-setting">
          <TextInput value={this.getFormattedNumber(this.state.lowRangeValue)} onBlurEvent={this.updateLowRangeSlider} /><span className="cursor-default">-</span><TextInput value={this.getFormattedNumber(this.state.highRangeValue)} onBlurEvent={this.updateHighRangeSlider} />
        </div>
        <div className="set-value-range">
          <div className={lqFilterLineSliderClass}>
            <Range id={this.state.id} handle={this.handles} key={this.state.id} min={this.state.minRangeSliderValue} max={this.state.maxRangeSliderValue} value={rangeValue} onChange={(value) => { this.updateFilter(value, this.state.intervalData[value[0]], this.state.intervalData[value[1]], this.state.id) }} />
          </div>
          <div className={labelDivClass}>
            <label className="get-left-value pull-left xx-small-normal">{this.getFormattedNumber(this.state.minValueText)}</label>
            <label className="get-right-value pull-right xx-small-normal">{this.getFormattedNumber(this.state.maxValueText)}</label>
          </div>
        </div>
      </div>
    );
  }
}


class TextInput extends PureComponent {

  constructor(props) {
    super(props);
    this.keyPress = this.keyPress.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputChange =this.handleInputChange.bind(this);
    this.state = {
      value: props.value
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
  }

  keyPress(e) {
    if(e.keyCode === KeyCodes.ENTER){
      e.preventDefault();
      e.stopPropagation();
      if(this.inputText)
      this.inputText.blur();
    }
 }

 handleInputFocus(e) {
  e.target.select();
 }

 handleInputChange(e) {
  if (/^-?[0-9,]+$/.test(e.target.value) || e.target.value === "" || e.target.value === "-") {
    this.setState({
      value: e.target.value
    });
  }
 }

 onBlurEvent(value) {
  this.props.onBlurEvent(value);
 }

  render() {
    return (
      <input type="text" ref={(r) => this.inputText = r} value={this.state.value} onFocus={this.handleInputFocus} onChange={this.handleInputChange} onBlur={() => this.onBlurEvent(this.state.value)} onKeyDown={this.keyPress} />
    );
  }
}


class SettingsWindow extends PureComponent {
  constructor(props) {
    super(props);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.state = {
      selectedRatingsOption: LiquidityFilterStore.getIsGlobalRatingSelected() ? "global" : "country"
    }
  }

  handleOptionChange(e, valueTxt) {
    let selectedOptions = valueTxt ? valueTxt : e.target.value;
    setTimeout(() => {
      this.setState({
        selectedRatingsOption: selectedOptions 
      })
    }, 0)

    if (selectedOptions == "global") {
      LiquidityFilterStore.setIsGlobalRatingSelected(true);
      LiquidityFilterStore.setIsCountryRatingSelected(false);
    }
    else {
      LiquidityFilterStore.setIsCountryRatingSelected(true);
      LiquidityFilterStore.setIsGlobalRatingSelected(false);
    }

    LiquidityFilterStore.updateRatingFilterTitle();
  };

  render() {
    return (
      <div
        style={{
          ...this.props.style,
          position: 'relative',
          zIndex: 1001,
          width: "70%",
          padding: 8,
          left: "15%"
        }}
      >
        <div className="panel panel-default ratings-filter-settings ">
          <div className="panel-heading">
            <span className="panel-title">{LocalizationStore.getTranslatedData("CCG_RatingsSettings", "Ratings Settings")}</span>
          </div>
          <div className="panel-body">
            <div className="inner-panel-body">
              <div className="section">
                <input type="radio" id="rg-global" name="rg-ratings-filter" className="vMiddel" value="global" checked={this.state.selectedRatingsOption === 'global'} onChange={this.handleOptionChange} />
                <label onClick={(e) => this.handleOptionChange(e, "global")}>{LocalizationStore.getTranslatedData("CCG_GlobalRatings", "Global Ratings")}</label>
              </div>
              <div className="section">
                <input type="radio" id="rg-country" name="rg-ratings-filter" className="vMiddel" value="country" checked={this.state.selectedRatingsOption === 'country'} onChange={this.handleOptionChange} />
                <label onClick={(e) => this.handleOptionChange(e, "country")}>{LocalizationStore.getTranslatedData("CCG_CountryRatings", "Country Ratings")}</label>
              </div>
              <div className="section">
                <p>{LocalizationStore.getTranslatedData("CCG_SettingMsg", "This setting applies to Datagraph, EPS and Relative Strength Rating.")}</p>
              </div>
              <div className="section btn-box">
                <Button className="btn-secondary btn btn-sm " onClick={this.props.handleClickToggle}>{LocalizationStore.getTranslatedData("CCG_Close", "Close")}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CustomToggle extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    return (
      <a href="" onClick={this.handleClick}>
        {this.props.children}
      </a>
    );
  }
}

const handle = (props) => {
  const { value, dragging, index, handleClick, ...restProps } = props;
  const handleClicks = () => {
    handleClick(index);
  }
  return (
    <Handle
      key={index}
      onClick={handleClicks}
      value={value}
      {...restProps}
    />
  );
};

export default onClickOutside(LiquidityRatingsFilter);