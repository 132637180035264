import { connect } from "react-redux";
import { dispatch } from "../../../../../../Redux/dispatch";
import Measure from "react-measure";
import { onPlotSymbol } from "../../../../../../Actions/DatagraphActions";
import ReactHtmlParser from "react-html-parser";
import React, { Component } from "react";

class TabularView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 0.0,
      width: 0.0
    },
      this.source = [],
      this.headerCellWidth,
      this.secondHeaderCellWidth,
      this.cellWidth,
      this.columnCount,
      this.rowCount;
  }

  componentDidMount() {
    if (this.props.id === "weeklyData") {
      this.source = this.props.weeklyReducerData
      this.headerCellWidth = 118,
        this.secondHeaderCellWidth = 46,
        this.cellWidth = 46,
        this.columnCount = 11,
        this.rowCount = 4
    }
    else {
      this.source = this.props.ratingReducerData,
        this.headerCellWidth = 66,
        this.secondHeaderCellWidth = 144,
        this.cellWidth = 46,
        this.columnCount = 9,
        this.rowCount = 5
    }
  }

  handleResize(contentRect) {
    this.setState({ height: contentRect.bounds.height, width: contentRect.bounds.width });
  }

  renderGrid() {
    if (!this.gridCanvas ||
      this.headerCellWidth === 0.0 ||
      this.secondHeaderCellWidth === 0.0 ||
      this.cellWidth === 0.0 ||
      this.columnCount === 0 ||
      this.rowCount === 0 ||
      this.state.height === 0.0 ||
      this.state.width === 0.0
    ) {
      return;
    }
    const ctx = this.gridCanvas.getContext("2d");
    ctx.canvas.height = this.state.height;
    ctx.canvas.width = this.state.width;
    ctx.clearRect(0, 0, this.state.width, this.state.height);
    ctx.lineWidth = 1;
    ctx.translate(0.5, 0.5);
    ctx.strokeStyle = "#4B525C";
    ctx.save();
    this.drawVerticalLines(ctx);
    this.drawHorizontalLines(ctx);
  }

  drawHorizontalLines(ctx) {
    const rowheight = Math.round(this.state.height / (this.rowCount + 1));
    ctx.beginPath();
    ctx.moveTo(0.0, rowheight - 1);
    ctx.lineTo(this.state.width, rowheight - 1);
    ctx.stroke();
    const count = this.rowCount;
    for (let i = 1; i <= count; i++) {
      const yValue = ((i * rowheight) - 1);
      ctx.moveTo(0.0, yValue);
      ctx.lineTo(this.state.width, yValue);
    }
    ctx.stroke();
    ctx.closePath();
  }

  drawVerticalLines(ctx) {
    ctx.beginPath();
    ctx.moveTo(this.headerCellWidth, 0.0);
    ctx.lineTo(this.headerCellWidth, this.state.height);
    ctx.moveTo(this.headerCellWidth + this.secondHeaderCellWidth, 0.0);
    ctx.lineTo(this.headerCellWidth + this.secondHeaderCellWidth, this.state.height);
    ctx.stroke();
    const colCount = this.columnCount;
    for (let i = 1; i < colCount; i++) {
      const xValue = (i * this.cellWidth) + this.headerCellWidth + this.secondHeaderCellWidth;
      ctx.moveTo(xValue, 0.0);
      ctx.lineTo(xValue, this.state.height);
    }
    ctx.stroke();
    ctx.closePath();
  }

  getCellClassName(cellIndex, index, isSymbol = false) {
    let className = "tabularView_cell";
    //if (cellIndex == 0 && index != 0) {
    if (index !== 0) {
      className = "tabularView_cell swatch-color";
    }
    if (cellIndex === 1) {
      className = "tabularView_cell swatch-color ellipsis";
    }

    if (isSymbol) { className = "tabularView_cell link-txt"; }
    return className;
  }

  setItemActive(e, cell) {
    if (cell?.cell?.isSymbol && cell.cell?.value) {
      dispatch(onPlotSymbol(cell.cell.value));
    }
  }

  getCell(cell, cellIndex, index) {
    let width = "0px";
    let color = cell.cell ? cell.cell.color : undefined;
    //apply custom color
    if (cell.cell && cell.cell.isUseCusColor) {
      color = cell.cell.isPositive ? this.props.posDTColor
        : this.props.negDTColor;
    }

    const textAlign = cell.cell ? cell.cell.textAlign : "center";
    const value = cell.cell ? cell.cell.value ? cell.cell.value : "&nbsp;" : "&nbsp;";
    if (cellIndex > 0) {
      if (cellIndex === 1) { width = `${this.secondHeaderCellWidth}px`; }
      else { width = `${this.cellWidth}px`; }
    }
    else {
      width = `${this.headerCellWidth}px`;
    }

    let customClassName = this.getCellClassName(cellIndex, index);
    let cursorVal = "default";
    if (cell.cell && cell.cell.isSymbol) {
      customClassName = this.getCellClassName(cellIndex, index, true);
      cursorVal = "pointer";
    }

    return (
      <div className={customClassName} style={{ color: color, width: width, textAlign: textAlign, cursor: cursorVal }} key={`r${index}c${cellIndex}`} onClick={(e) => this.setItemActive(e, cell)}>{ReactHtmlParser(value)}</div>
    );
  }

  getRow(row, index) {
    if (this.state.height === 0.0) { return; }
    const rowHeight = Math.round(this.state.height / (this.rowCount + 1));
    const top = (index * rowHeight) - 1;
    return (
      <div className={this.getClassName(index)} key={index} style={{ height: rowHeight, top: top }}>
        {row.cells.map((cell, cellIndex) =>
          this.getCell(cell, cellIndex, index)
        )}
      </div>
    );
  }

  getClassName(index) {
    let className = "tabular-header";
    if (index === 0) {
      className = `${className} tabular-title head`;
    }
    return className;
  }

  render() {
    this.renderGrid();
    return (
      <Measure bounds onResize={(contentRect) => { this.handleResize(contentRect) }}>
        {({ measureRef }) =>
          <div className="tabular-box" ref={measureRef}>
            <canvas className="tabular-canvas" ref={(ref) => { this.gridCanvas = (ref) }}></canvas>
            {this.source.map((row, index) => this.getRow(row, index))}
          </div>
        }
      </Measure>
    );
  }
}

const mapStateToProps = ({ summaryReducer, appColor }) => {
  const weeklyReducerData = summaryReducer.weeklyData !== undefined ? summaryReducer.weeklyData.rowSource : [];
  const ratingReducerData = summaryReducer.ratingData !== undefined?summaryReducer.ratingData.rowSource:[];
  const { posDTColor, negDTColor } = appColor;

  return { weeklyReducerData, ratingReducerData, posDTColor, negDTColor }
}

export default connect(mapStateToProps, null)(TabularView)