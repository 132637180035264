var PrintStatus = {
    InProcess : 0,
    Queued : 1,
    Complete : 2, 
    Cancelled : 3,
    Error : 4,
    Unknown : 5,
    CompletedWithError : 6
}

export default PrintStatus;