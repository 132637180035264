import { put,call,select,takeLatest } from 'redux-saga/effects';
import { ExternalDataConstants } from "../../Constants/ExternalDataUploader";
import DataUploadsApi from '../../ServiceApi/Apis/DataUploadsApi';
import ListApi from "ListApi";
import StringUtil from 'StringUtil';
import ListStore from "ListStore";
import ListManagerTabType from "../../Constants/ListManagerTabType.js";
const { ActionTypes } = ExternalDataConstants;
import {
  recurringChecked,tempFile,isRecurring,ftpUserName,ftpPassword,scheduledTime,
  overWriteData,uploadOnSubmit, winTimeZone, SelisExternalUrl, SelisFtpUrl,SelisSFtpUrl,SelisWONSFtpUrl , storedListItems,exceptionMsg,
  currentSelectedList,lastDeletedIds, uploadFrequency,reuploadId, reuploadedListId
  } from '../../Reducers/ExternalDataUploadReducer/selectors';
import OlsonToWinTimeZone from "../../Utils/OIsonToWinTimeZone.js";
import moment from "moment";
import BrowserUtil from "BrowserUtil";
import ListActions from "ListActions";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import ListExplorerStore from "ListExplorerStore";
import BaseServiceApi from 'BaseServiceApi';
import { loadExternalList,updateCurrentIndex,handleUpdateLoader, overWriteSettingsOnReUpload } from '../../Actions/ExternalDataUploaderActions';

/***************************** Subroutines ************************************/
function* saveUploadTime(data){
  let time = data.time;
  yield put({
    type: ActionTypes.SAVE_UPLOADED_TIME_CHANGE,
    displayName: time.displayName, 
    hour: time.id.hour, 
    minute: time.id.minute
  });
}
function* saveTempFile(data){
  let isFilePathUrl = false;
  let isFtp = false;
  let isSFtp = false;
  let wonSftpPath = false;
  let isGoogleSheet = false;
  let fileName = '';
  let saveEnable = false;
  let fileUpload = false;
  let ftpUsername = '';
  let ftppassword = '';
  let wonSftpfileName = '';
  const scheduledTiming = yield select(scheduledTime);
  if(data.file){
    const isFtpPath = new RegExp(/ftp:/i).exec(data.file);
    if (isFtpPath != null) {
      isFilePathUrl = true;
      isFtp = true;
    }
    const isSFtpPath = new RegExp(/sftp:/i).exec(data.file);
    if (isSFtpPath != null) {
      isFilePathUrl = true;
      isSFtp = true;
      isFtp = false;
    }
    const isWONSftpPath = new RegExp(/(ftp|sftp:\/\/)(.*):(.*)@(wonsftp.williamoneil.com.*)/i).exec(data.file);
    if (isWONSftpPath != null) {
      isFilePathUrl = true;
      isSFtp = false;
      isFtp = false;
      wonSftpPath = true;
    }
    const isGoogleSheetPath = new RegExp(/\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/i).exec(data.file);
    const isOneSheetPath = new RegExp(/\/1drv\.ms\//i).exec(data.file);
    if (isGoogleSheetPath != null || isOneSheetPath != null) {
      isFilePathUrl = true;
      isGoogleSheet = true;
    }
    if(data.file.name){
      fileName = data.file.name;
      fileUpload = true;
    }else if(wonSftpPath){
      fileName = isWONSftpPath[1]+isWONSftpPath[4];
      ftpUsername = isWONSftpPath[2];
      ftppassword = isWONSftpPath[3];
      wonSftpfileName = data.file;
    }else{
      fileName = data.file;
    }

  }
  if(fileUpload || isFilePathUrl){
    saveEnable = true;
  }
  const ftpUser = yield select(ftpUserName);
  const ftpPass = yield select(ftpPassword);
  if((isSFtp || isFtp) && (ftpUser == '' || ftpPass == '')){
    saveEnable = false;
  }else{
    saveEnable = saveEnable;
  }
  yield put({
    type: ActionTypes.SAVE_UPLOADED_FILE_TEMP,
    file: !isFilePathUrl ? data.file : fileName,
    isExternalUrl: isFilePathUrl,
    isFtpUrl: isFtp,
    isSFtpUrl: isSFtp,
    isWonSftpPath: wonSftpPath,
    isGoogleUrl: isGoogleSheet,
    tempLocalFileName: !wonSftpPath ? fileName : wonSftpfileName,
    enableSave: saveEnable,
    scheduledTiming :scheduledTiming,
    ftpUsername: ftpUsername,
    ftppassword: ftppassword
  });
}
function* toggleRecurring(){
  let recurringState = yield select(recurringChecked);
  yield put({
    type: ActionTypes.TOGGLE_RECURRING_UPLOAD_CHANGE,
    showRecurring: !recurringState
  });
}
function* toggleUploadDialogState(data){
  let winTimeZone = yield call(getTimeZone);
  yield put({
    type: ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_TOGGLE,
    showDialog: data.showDialog,
    winTimeZone: winTimeZone,
    lastUploadedList: '',
    uploadListId: '',
  });
  if(!data.showDialog){
    yield put({
      type: ActionTypes.UNLOAD_EXTERNAL_STATE_DATA,
      showDialog: data.showDialog,
      winTimeZone: winTimeZone
    });
  }
}	
function* formUpload() {
  let listId = '';
  let showexception = false;
  const file = yield select(tempFile);
  const recurringUpload = yield select(isRecurring);
  const ftpUser = yield select(ftpUserName);
  const ftpPass = yield select(ftpPassword);
  const scheduledTiming = yield select(scheduledTime);
  const overWriteExisting = yield select(overWriteData);
  const uploadOnClose = yield select(uploadOnSubmit);
  const winTimeZoneName = yield select(winTimeZone);
  const externalUrl = yield select(SelisExternalUrl);
  const ftpUrl = yield select(SelisFtpUrl);
  const sftpUrl = yield select(SelisSFtpUrl);
  const wonsftpUrl = yield select(SelisWONSFtpUrl);
  const activeList = yield select(currentSelectedList);
  const frequency = yield select(uploadFrequency);
  const reUploadList = yield select(reuploadId);
  let isReUpload = false;
  // let googleUrl = yield select(SelisGoogleUrl);
  const listName = ListStore.externalListName ? ListStore.externalListName : "Untitled Data Set";
  let uploadData = new FormData();
  var uploadArr = {};
  if(file.size == 0){
    let exceptionMessage = "Upload failed either due to Corrupted file or file removed from selected location."
    yield put({
      type: ActionTypes.SHOW_FILE_EXCEPTION_DIALOG,
      showExceptionDialog: true,
      exceptionMessage: exceptionMessage
    });
    return;
  }
  uploadData.append('file', file);
  uploadArr['file'] = file;
  uploadData.append('listName', listName);
  uploadArr['listName'] = listName;
  if(activeList.listId && !reUploadList){
    uploadData.append('listId', activeList.listId);
    uploadArr['listId'] = activeList.listId;
    listId = activeList.listId;
  }
  if(reUploadList){
    uploadData.append('listId', reUploadList);
    uploadArr['listId'] = reUploadList;
    listId = reUploadList;
    isReUpload = true;
   }
   if(reUploadList && !externalUrl){
    uploadData.append('isOverrite', overWriteExisting?1:0);
    uploadArr['isOverrite'] = overWriteExisting?1:0;
   }
    if(externalUrl){
      let scheduledtime = `${scheduledTiming.id.hour}:${scheduledTiming.id.minute}:00`;
      uploadData.append('isRec', recurringUpload?1:0);
      uploadArr['isRec'] = recurringUpload?1:0;
      if(ftpUrl || sftpUrl || wonsftpUrl){
        uploadData.append('ftpUserName', ftpUser);
        uploadArr['ftpUserName'] = ftpUser;
        uploadData.append('ftpPassword', ftpPass);
        uploadArr['ftpPassword'] = ftpPass;
      }
      uploadData.append('freqType',frequency);
      uploadArr['freqType'] = frequency;
      uploadData.append('schTime', scheduledtime);
      uploadArr['schTime'] = scheduledtime;
      uploadData.append('isOverrite', overWriteExisting?1:0);
      uploadArr['isOverrite'] = overWriteExisting?1:0;
      uploadData.append('timeZone', winTimeZoneName);
      uploadArr['timeZone'] = winTimeZoneName;
      uploadData.append('uploadNow', uploadOnClose?1:0);
      uploadArr['uploadNow'] = uploadOnClose?1:0;
    }
  try {
    yield put({
      type: ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_TOGGLE,
      showDialog: false,
      winTimeZone: '',
      lastUploadedList: activeList.listId,
      uploadListId: '',
    });
    yield put({
      type: ActionTypes.UNLOAD_EXTERNAL_STATE_DATA,
      showDialog: false,
      winTimeZone: winTimeZoneName
    });
    BrowserUtil.disableEvents();
    let fileUploadRes = yield call(DataUploadsApi.uploadFormData, uploadData, externalUrl, uploadArr);
      yield put({
        type: ActionTypes.EXTERNAL_FILE_RECURRING_UPLOAD,
        listId: listId,
        listType: activeList.listType,
        isRecurring: externalUrl && fileUploadRes.success ? recurringUpload : false,
        setEmpty: (activeList.lastUploadDateTime || reUploadList) ? false : !fileUploadRes.success
      });

    if(fileUploadRes.success && !uploadOnClose && recurringUpload){
      yield put({
        type: ActionTypes.UNLOAD_EXTERNAL_STATE_DATA
      });
    }else{
      if(fileUploadRes.exceptionMessge != null && fileUploadRes.exceptionMessge != ""){
        showexception = true;
      }
      if(!isReUpload || (isReUpload && overWriteExisting && !recurringUpload) || (isReUpload && overWriteExisting && recurringUpload && uploadOnClose)){
        yield put({
          type: ActionTypes.SHOW_WIZARD_DIALOG,
          showWizardDialog: true,
          fileUploadRes: fileUploadRes,
          exceptionMessage: fileUploadRes.exceptionMessge,
          exceptionDialogShow: false
        });

        yield put(overWriteSettingsOnReUpload(reUploadList));
      } else {
        if((isReUpload && overWriteExisting && !recurringUpload) || (isReUpload && overWriteExisting && recurringUpload && uploadOnClose)){
          yield put({
            type: ActionTypes.SHOW_WIZARD_DIALOG,
            showWizardDialog: true,
            fileUploadRes: fileUploadRes,
            exceptionMessage: fileUploadRes.exceptionMessge,
            exceptionDialogShow: false
          });
        }else{
          if (activeList.listId == reUploadList && fileUploadRes.success) {
            yield put(loadExternalList(reUploadList, true));
            yield put(overWriteSettingsOnReUpload(reUploadList));
          }
          yield put({
            type: ActionTypes.EXTERNAL_FILE_RE_UPLOADED,
            reuploadedListId: reUploadList,
            fileUploadRes: fileUploadRes,
            showWizardDialogError: !fileUploadRes.success,
            exceptionDialogShow: showexception,
            exceptionMessage: fileUploadRes.exceptionMessge,
          })
        }
        if(fileUploadRes.success)
          ListActions.reUploadSuccess(reUploadList);
        }
    }
    BrowserUtil.enableEvents();
    // yield put(loadExternalList(fileUploadRes.listId.low));
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_VALIDATION_ERROR,
      hasError: true,
      errorMsg: error
    });
  }
}
function* loadExternalListOnAccept({listId}){
  let exception = yield select(exceptionMsg);
  let showexception = false;
  if(exception != null && exception != ""){
    showexception = true;
  }
  yield put({
    type: ActionTypes.SHOW_WIZARD_DIALOG,
    showWizardDialog: false,
    exceptionMessage: exception,
    exceptionDialogShow: showexception
  });
  BrowserUtil.disableEvents()
  yield put(loadExternalList(listId,true));
}

function* findActiveListAvailibility(listId) {
  const fullList = yield select(storedListItems);
  const arrayed = [];
  fullList.forEach(itm => arrayed.push(itm.childNodes));
  const flatList = arrayed.reduce((arr, val) => arr.concat(val), []);
  const allListIds = flatList && flatList.map((entry) => (entry.nodeId.low));
    if (!allListIds.includes(parseInt(listId))) {
      return false;
    }else{
      return true;
    }
  }

function* updateCurrentSelected(listId,listName,initCall){
  let data = [];
  let isListAvail = yield call(findActiveListAvailibility,listId);
  if(isListAvail){
    
    let selectedTab = ListStore.getselectedTabKey();
    if(selectedTab == ListManagerTabType.External){
      ListStore.updateSelectedListItem(listId,listId,listName);
    }
    ListActions.setInitialExternalList(listId,listName);   
    data['listId'] = listId;
    yield put(loadExternalList(listId,initCall));    
  }else{
      yield put({
        type: ActionTypes.LOAD_EXTERNAL_LIST_SUCCESS,
        source: '',
        isNewList: true
      });
    ListStore.updateSelectedListItem("","","");
    ListActions.setInitialExternalList("","");
  }
  
}
function* getExplorerLists({initCall}) {
  let listItems = {};
  let externalExplorerResult = '';
  let stateListItems = yield select(storedListItems);
  let selectedListItem = ListStore.getSelectedListItem();
  if(initCall){
    // yield call(updateCurrentSelected,selectedListItem.SelectedListId,selectedListItem.SelectedListName);
    ListActions.setInitialExternalList(selectedListItem.SelectedListId,selectedListItem.SelectedListName);  
    externalExplorerResult = yield call(ListApi.getListExplorer, 3, 0, 0, 1);
  }
  if(externalExplorerResult && initCall){
    const eventSeries = externalExplorerResult.data.explorerNodes.filter(itm => itm.listType==BaseServiceApi.rayData["ListType"].EVENTSERIES_List && !itm.shareAccess);
    const timeSeries = externalExplorerResult.data.explorerNodes.filter(itm => itm.listType==BaseServiceApi.rayData["ListType"].TIMESERIES_List && !itm.shareAccess);
    const sharedTime = externalExplorerResult.data.explorerNodes.filter(itm => itm.listType==BaseServiceApi.rayData["ListType"].TIMESERIES_List && itm.shareAccess); 
    const sharedEvent = externalExplorerResult.data.explorerNodes.filter(itm => itm.listType==BaseServiceApi.rayData["ListType"].EVENTSERIES_List && itm.shareAccess);
    listItems = [{
      listType: BaseServiceApi.rayData["ListType"].EVENTSERIES_List,
      name: "EVENTS",
      childNodes: eventSeries,
      isFolder: true,
      expandNode: eventSeries.find(itm => itm.nodeId.low == selectedListItem.SelectedListId) ? true : false
    },{
      listType: BaseServiceApi.rayData["ListType"].TIMESERIES_List,
      name: "TIME SERIES",
      childNodes: timeSeries,
      isFolder: true,
      expandNode: timeSeries.find(itm => itm.nodeId.low == selectedListItem.SelectedListId) ? true : false
    }];
    if(sharedEvent.length > 0){
      listItems.push({
        listType: 9,
        name: "SHARED EVENTS",
        childNodes: sharedEvent.map(itm => ({...itm,listType : 9})),
        isFolder: true,
        expandNode: sharedEvent.find(itm => itm.nodeId.low == selectedListItem.SelectedListId) ? true : false
      })
    }
    if(sharedTime.length > 0){
      listItems.push({
        listType: 8,
        name: "SHARED TIME SERIES",
        childNodes: sharedTime.map(itm => ({...itm,listType : 8})),
        isFolder: true,
        expandNode: sharedTime.find(itm => itm.nodeId.low == selectedListItem.SelectedListId) ? true : false
      })
    }
    // listItems = externalExplorerResult.data.explorerNodes;
    yield put({
      type: ActionTypes.INIT_EXTERNAL_LISTS_SUCCESS,
      dataItems: listItems,
    });
    ListActions.setSelectedExternalNodes('');
    yield put(updateCurrentIndex(0));
    //Add if external respo is empty open first fold
    if(eventSeries.length == 0 && timeSeries.length == 0 && sharedTime.length == 0 && sharedEvent.length == 0){
      yield put({
        type: ActionTypes.SET_EMPTY_NEW_USER,
        newUserList: true
      });        
      yield put(handleUpdateLoader(false));
      // yield call(updateCurrentSelected,listItems[0].childNodes[0].nodeId.low,listItems[0].name,initCall );
    }else{
      const isListAvail = yield call(findActiveListAvailibility,selectedListItem.SelectedListId);   
      if(!isListAvail){
        let listTypeOpened = '';
        if(eventSeries.length > 0){
          yield call(updateCurrentSelected,eventSeries[0].nodeId.low,eventSeries[0].name,initCall); 
          listTypeOpened = eventSeries[0].listType;
        }else if(timeSeries.length > 0){
          yield call(updateCurrentSelected,timeSeries[0].nodeId.low,timeSeries[0].name,initCall); 
          listTypeOpened = timeSeries[0].listType;
        }else if(sharedEvent.length > 0){
          yield call(updateCurrentSelected,sharedEvent[0].nodeId.low,sharedEvent[0].name,initCall); 
          listTypeOpened = sharedEvent[0].listType + 2;
        }else if(sharedTime.length > 0){
          yield call(updateCurrentSelected,sharedTime[0].nodeId.low,sharedTime[0].name,initCall); 
          listTypeOpened = sharedTime[0].listType + 2;
        }else{
          yield put(handleUpdateLoader(false));
          yield call(updateCurrentSelected,'','',initCall);
        }
        yield put({
          type: ActionTypes.TOGGLE_FOLDER_VIEW,
          folderId: listTypeOpened,
          status: true
        });
      }else{
        yield call(updateCurrentSelected,selectedListItem.SelectedListId,selectedListItem.SelectedListName,initCall); 
      }
    }
    // else{
    //   yield call(updateCurrentSelected,'','',initCall);
    //   yield put(handleUpdateLoader(false));
    // }
  }
  yield call(sortLists);

}
function* findAndOpenNextAvailable({folderId}){
    const fullList = yield select(storedListItems);
    let newListFound = false;
    let listTypeOpened = '';
    yield put({
      type: ActionTypes.TOGGLE_FOLDER_VIEW,
      folderId: folderId,
      status: true
    });
      if(folderId == 9){
        let sharedTime = fullList.find(itm => itm.listType == 8);
          if(sharedTime && sharedTime.childNodes.length > 0 && !newListFound){
              newListFound = true;
              yield call(updateCurrentSelected,sharedTime.childNodes[0].nodeId.low,sharedTime.childNodes[0].name,true); 
              listTypeOpened = 8;
          }
      }
      let eventSeries = fullList.find(itm => itm.listType == 7);
        if(eventSeries.childNodes.length > 0 && !newListFound){
            newListFound = true;
            yield call(updateCurrentSelected,eventSeries.childNodes[0].nodeId.low,eventSeries.childNodes[0].name,true); 
            listTypeOpened = 7;
        }
      let timeSeries = fullList.find(itm => itm.listType == 6);
        if(timeSeries.childNodes.length > 0 && !newListFound){
            newListFound = true;
            yield call(updateCurrentSelected,timeSeries.childNodes[0].nodeId.low,timeSeries.childNodes[0].name,true); 
            listTypeOpened = 6;
        }
      if(folderId == 8){
          let sharedEvents = fullList.find(itm => itm.listType == 9);
          if(sharedEvents && sharedEvents.childNodes.length > 0 && !newListFound){
              newListFound = true;
              yield call(updateCurrentSelected,sharedEvents.childNodes[0].nodeId.low,sharedEvents.childNodes[0].name,true); 
              listTypeOpened = 9;
          }
        }
      if(!newListFound){
        ListActions.setSelectedExternalList('')
        yield put({
          type: ActionTypes.SET_EMPTY_NEW_USER,
          newUserList: true
        });
        yield put(handleUpdateLoader(false));
      }else{
        yield put({
          type: ActionTypes.TOGGLE_FOLDER_VIEW,
          folderId: listTypeOpened,
          status: true
        });
      }
}
function* nodeSelection({listId}) {
  yield put({
    type: ActionTypes.EXTERNAL_SELECTED_NODE_SUCCESSS,
    listId: listId,
  });
}
function* createNewEmptyList({listName,nodeType}){
  const fullList = yield select(storedListItems);
  let data = [];
  data['dbType'] = 3;
  data['parentNodeId'] = -1;
  data['name'] = listName;
  data['nodeType'] = nodeType;
  data['categoryType'] = 20;
  data['sourceListId'] = "";
  BrowserUtil.disableEvents();
  let callCreateList = yield call(ListApi.getCreateTreeNode,data);
  if(callCreateList){
    BrowserUtil.enableEvents();
  }
  if(callCreateList.data){  
  yield put({
    type: ActionTypes.PUSH_NEW_LIST_NODE,
    newList: callCreateList.data,
  });
  yield call(sortLists);
  
  const arrayed = [];
  fullList.forEach(itm => arrayed.push(itm.childNodes));
  const flatList = arrayed.reduce((arr, val) => arr.concat(val), []);
  
  let selectedListItem = ListStore.getSelectedListItem();
  const isListAvail = yield call(findActiveListAvailibility,selectedListItem.SelectedListId);   

  if(flatList.length == 0 || !isListAvail){
    yield put(loadExternalList(callCreateList.data.nodeId.low));
  }
  }
}
function* sortLists(){
  let explorerList = yield select(storedListItems);
  let sortedList= ListStore.sortExternalData(explorerList);
  ListActions.setExternalList(sortedList);
  yield put({
    type: ActionTypes.SORT_SOURCE_LIST,
    sortedData: sortedList,
  }); 
}
function* setSelectedNodes({selectedNodes}) {
  try {
  
    if(selectedNodes){
      ListActions.setSelectedExternalNodes(selectedNodes);
      yield put({
        type: ActionTypes.SET_SELECTED_NODES_SUCCESS,
        selectedNodes: selectedNodes
      });
    }
  }
  catch (error) {
      yield put({
          type: ActionTypes.HANDLE_ERROR,
          hasError: true,
          errorMsg: error
      })     
  }
}

function* setRenameNode({nodeToRename, selectedListType}) {  
  try {
      yield call(sortLists);
      yield put({
        type: ActionTypes.SET_RENAME_NODE_SUCCESS,
        nodeToRename: nodeToRename,
        selectedListType: selectedListType,
        isUpdate: false
      });
  
  }
  catch (error) {
      yield put({
          type: ActionTypes.HANDLE_ERROR,
          hasError: true,
          errorMsg: error
      })     
  }
}

function* setDuplicateNode({duplicateNode,selectedListType,alertMsg}) {
  try {
    BrowserUtil.disableEvents();
    let listName = alertMsg;
    if(selectedListType){
      const explorerList = yield select(storedListItems);
      const searchNodes = explorerList.find(folds => folds.listType == selectedListType);
      listName = ListExplorerStore.validateExternalListName(searchNodes.childNodes, listName);
    }
    const saveDuplicateResponse = yield call(ListApi.getDuplicateDataSet, listName, duplicateNode);
    if(saveDuplicateResponse.data){
        yield put({
          type: ActionTypes.PUSH_NEW_LIST_NODE,
          newList: saveDuplicateResponse.data,
        });
        ListActions.showAlertExternalDuplicate(alertMsg);
        yield call(sortLists);
        yield put({
          type: ActionTypes.SET_RENAME_NODE_SUCCESS,
          nodeToRename: saveDuplicateResponse.data.nodeId.low,
          selectedListType: saveDuplicateResponse.data.listType,
          isUpdate: true
        });
      }
      // yield put({
      //   type: ActionTypes.SET_DUPLICATE_NODE_SUCCESS,
      //   duplicateNode: duplicateNode,
      //   selectedListType: selectedListType
      // });
      BrowserUtil.enableEvents();
  }
  catch (error) {
      BrowserUtil.enableEvents();
      yield put({
          type: ActionTypes.HANDLE_ERROR,
          hasError: true,
          errorMsg: error
      })      
  }
}

function* saveDuplicateNode({nodeName,duplicateNode,alertMsg}) {
  try{ 
  BrowserUtil.disableEvents(); 
  const saveDuplicateResponse = yield call(ListApi.getDuplicateDataSet, nodeName, duplicateNode);
  if(saveDuplicateResponse.data){
      yield put({
        type: ActionTypes.PUSH_NEW_LIST_NODE,
        newList: saveDuplicateResponse.data,
      });
      ListActions.showAlertExternalDuplicate(alertMsg);
    }
    yield call(sortLists);
    BrowserUtil.enableEvents();
  }
  catch (error) {
    yield put({
        type: ActionTypes.HANDLE_ERROR,
        hasError: true,
        errorMsg: error
    })    
  }
}

function* saveRenameNode({listId,listName,parentNodeId}) {  
  let externalExplorerResult ='';
  try {
    BrowserUtil.disableEvents();
    const selectedListItem = ListStore.getSelectedListItem();
  externalExplorerResult = yield call(ListApi.getUpdateListNode, listId, listName, parentNodeId);
  if(externalExplorerResult.data){
    if(selectedListItem.SelectedListId==listId)
    ListActions.setActiveListName(listName); 
    yield put({
      type: ActionTypes.CHANGE_LIST_NAME_IN_SOURCE,
      listNodeToRename: externalExplorerResult.data,
    });
    const dataRename = [];
    dataRename['nodeToRename'] = ''; 
    yield call(setRenameNode,dataRename);
    yield call(sortLists);
    BrowserUtil.enableEvents();
  }
   sortDataSource(dataSource);
  
  }
  catch (error) {
    BrowserUtil.enableEvents();
    yield put({
        type: ActionTypes.HANDLE_ERROR,
        hasError: true,
        errorMsg: error
    })    
  }
  }

function* rollBackExternalList({ listId }) {
  try {
    let externalExplorerResult = '';
    if (listId) {
      BrowserUtil.disableEvents();
      externalExplorerResult = yield call(ListApi.callRollBack, listId);
      if (!externalExplorerResult.responseHeader.error) {
        yield put(loadExternalList(listId,true));
        ListActions.showRollBackSuccess(listId);
        BrowserUtil.enableEvents();
      }
    }
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })    
  }
}

function* removeDeletedItems({ nodes,listType }) {
  try {
    if (nodes) {
      ListActions.removeDeletedList(nodes,listType);
      let sourceListItems = yield select(storedListItems);
      const selectedFolder = sourceListItems.find(itm => itm.listType == listType)
      let prevDeletedLists =[];
      const deleteLists = nodes;
      for (let sourceIndex = 0; sourceIndex < selectedFolder.childNodes.length; sourceIndex++) {
         for(let index=0; index< deleteLists.length;index++) {
            if(selectedFolder.childNodes[sourceIndex].nodeId.low == deleteLists[index]) {
              prevDeletedLists.push(selectedFolder.childNodes[sourceIndex]);
            }
         }
      }
      sourceListItems = sourceListItems.map(
        content => content.listType === listType ? {...content, childNodes: content.childNodes.filter(item => !nodes.includes(item.nodeId.low))}
                              : content
      );
      
    //  for (let index = 0; index < deleteLists.length; index++) {
    //     sourceListItems = sourceListItems.filter((item) => item.nodeId.low  != deleteLists[index]);
    //   }
      if(sourceListItems.length == 0){
        ListActions.setInitialExternalList("","");
      }
      yield put({
        type: ActionTypes.DISABLE_DELETE_ICON,
        disabled: true
      });
      yield put({
        type: ActionTypes.REMOVE_LISTS_FROM_SOURCE,
        sourceLists: sourceListItems,
        deletedLists : prevDeletedLists,
        listType: listType
      });
    }
  }
  catch (error) {
    console.log(`Error occurs - externalUpload.js, removeDeletedItems ${error}`);

    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })    
  }
}

function* sortExternalListData({ sortedList }) {
  try {

    if (sortedList) {
     ListActions.sortedList(sortedList);
      yield put({
        type: ActionTypes.SORT_SOURCE_LIST,
        sortedData: sortedList,
      });
    }
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })    
  }
}

function* undoExternalListData({  }) {
  try {
    const explorerList = yield select(storedListItems);
    const lastDeletedLists =yield select(lastDeletedIds);
      yield put({
        type: ActionTypes.RESTORE_DELETED_LISTS,
        restoredData: explorerList.map(
          (content) => content.listType === lastDeletedLists[0].listType ? {...content, childNodes: content.childNodes.concat(lastDeletedLists)}
                                  : content
      ),
      });
      yield call(sortLists);
      
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })    
  }
}


function* externalreUploadRollback() {
  try {
    const reUploadListId = yield select(reuploadedListId);
    const activeList = yield select(currentSelectedList);
    let externalExplorerResult = '';
    if (reUploadListId) {
      BrowserUtil.disableEvents();
      externalExplorerResult = yield call(ListApi.callRollBack, reUploadListId);

      if (!externalExplorerResult.responseHeader.error) {        
        if(activeList.listId == reUploadListId)
          yield put(loadExternalList(reUploadListId,true));
        ListActions.showRollBackSuccess(reUploadListId);
        BrowserUtil.enableEvents();
      }
    }
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })    
  }
}

function* uploadFormOnDropFile({formData,listId,isReUploadFile}){
    BrowserUtil.disableEvents();
    let showexception = false;
    let fileUploadRes = yield call(DataUploadsApi.uploadFormData, formData);
    if(fileUploadRes.exceptionMessge != null && fileUploadRes.exceptionMessge != ""){
      showexception = true;
    }
    const activeList = yield select(currentSelectedList);
    
    const recurringUpload = yield select(isRecurring);
    const overWriteExisting = yield select(overWriteData);
    const uploadOnClose = yield select(uploadOnSubmit);
      if((isReUploadFile && activeList.listId == listId) && fileUploadRes.success){
        if((isReUploadFile && overWriteExisting && !recurringUpload) || (isReUploadFile && overWriteExisting && recurringUpload && uploadOnClose)){
          yield put({
            type: ActionTypes.SHOW_WIZARD_DIALOG,
            showWizardDialog: true,
            fileUploadRes: fileUploadRes,
            exceptionMessage: fileUploadRes.exceptionMessge,
            exceptionDialogShow: false
          });
        }else{
          yield put({
            type: ActionTypes.EXTERNAL_FILE_RE_UPLOADED,
            reuploadedListId: listId,
            fileUploadRes: fileUploadRes,
            showWizardDialogError: !fileUploadRes.success,
            exceptionDialogShow: showexception,
            exceptionMessage: fileUploadRes.exceptionMessge,
          });
          
          if(activeList.listId == listId){
            yield put(loadExternalList(listId,true));
          }
          if(fileUploadRes.success){
            ListActions.reUploadSuccess(listId);
          }
        }
    }else if((isReUploadFile && activeList.listId != listId) && fileUploadRes.success){
      if((isReUploadFile && overWriteExisting && !recurringUpload) || (isReUploadFile && overWriteExisting && recurringUpload && uploadOnClose)){
        yield put({
          type: ActionTypes.SHOW_WIZARD_DIALOG,
          showWizardDialog: true,
          fileUploadRes: fileUploadRes,
          exceptionMessage: fileUploadRes.exceptionMessge,
          exceptionDialogShow: false
        });
      }else{
        yield put({
          type: ActionTypes.EXTERNAL_FILE_RE_UPLOADED,
          reuploadedListId: listId,
          fileUploadRes: fileUploadRes,
          showWizardDialogError: !fileUploadRes.success,
          exceptionDialogShow: showexception,
          exceptionMessage: fileUploadRes.exceptionMessge,
        });
        if(fileUploadRes.success){
          ListActions.reUploadSuccess(listId);
        }
      }
    }else{
      yield put({
        type: ActionTypes.SHOW_WIZARD_DIALOG,
        showWizardDialog: true,
        fileUploadRes: fileUploadRes,
        exceptionMessage: fileUploadRes.exceptionMessge,
        exceptionDialogShow: false
      });
      if(fileUploadRes.success){
        yield put({
          type: ActionTypes.EXTERNAL_FILE_RECURRING_UPLOAD,
          listId: listId,
          listType: activeList.listType,
          setEmpty: !fileUploadRes.success
        });
      }
    }
    BrowserUtil.enableEvents();
}
function* getTimeZone(){
  var timeZones = OlsonToWinTimeZone.getTimeZones();
  let olsonTimeZone = moment.tz.guess();
  let timeObj = _.find(timeZones, (node)=>node.Olson === olsonTimeZone);
  let winTimeZoneName = "Eastern Standard Time" //default windows time zone
  if(timeObj)
      winTimeZoneName = timeObj.Win32TimeZone

  return winTimeZoneName
}
function* uploadToExistingList({listId}){
  BrowserUtil.disableEvents();
  let listSettings = [];
  if(listId != '')
    listSettings = yield call(DataUploadsApi.getListSettings, listId);
    
  let winTimeZoneName = yield call(getTimeZone);
  if(listSettings != null){
    let isFtp = false;
    let isSFtp = false;
    let isGoogleSheet = false;
    let isFilePathUrl = false;
    const filePath    = listSettings.archivepath;
    const isFtpPath = new RegExp(/ftp:/i).exec(filePath);
    if (isFtpPath != null) {
      isFilePathUrl = true;
      isFtp = true;
    }
    
    const isSFtpPath = new RegExp(/sftp:/i).exec(filePath);
    if (isSFtpPath != null) {
      isFilePathUrl = true;
      isSFtp = true;
      isFtp = false;
    }
    
    const isGoogleSheetPath = new RegExp(/\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/i).exec(filePath);
    const isOneSheetPath = new RegExp(/\/1drv\.ms\//i).exec(filePath);
    if (isGoogleSheetPath != null || isOneSheetPath != null) {
      isFilePathUrl = true;
      isGoogleSheet = true;
    }
  const uploadFreq    = listSettings.freqtypeid;
  const ftppassword   = listSettings.ftppassword;
  const ftpusername   = listSettings.ftpusername;
  const isoverwrite   = listSettings.isoverwrite;
  const isrecurring   = listSettings.isrecurring;
  const schdeuledTime  = listSettings.schdeuleTime ? StringUtil.formatDate(listSettings.schdeuleTime,"HH:mm") : listSettings.schdeuleTime;
  if(isFilePathUrl){
    let schdeuleTime = { displayName: "08:00 PM", id: { hour: "20", minute: "00" } };
    const time = schdeuledTime ? schdeuledTime.split(":") : schdeuledTime;
    if(schdeuledTime){
    const hour = time[0];
    const minute = time[1];
    let prefix = hour >= 12 ? LocalizationStore.getTranslatedData("ss_pm","PM") : LocalizationStore.getTranslatedData("ss_am","AM");
    let formatHour = 8;
    formatHour = hour > 12 ? hour - 12 : hour
    formatHour = formatHour.toString();
    let minuteFinal = minute.toString();
    let selectedTime = `${formatHour}:${minuteFinal} ${prefix}`;
    schdeuleTime  = { displayName: selectedTime, id: { hour: hour, minute: minuteFinal } };
    winTimeZoneName = listSettings.timezone ? listSettings.timezone : winTimeZoneName;
    }
    yield put({
      type: ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_REPEAT_TOGGLE,
      showDialog: true,
      uploadListId: parseInt(listId),
      filePath: filePath,
      uploadFreq: uploadFreq,
      ftpusername: ftpusername,
      ftppassword: ftppassword,
      isoverwrite: isoverwrite,
      isrecurring: isrecurring,
      schdeuleTime: schdeuleTime,
      winTimeZone: winTimeZoneName,
      isExternalUrl: isFilePathUrl,
      isFtpUrl: isFtp,
      isSFtpUrl: isSFtp,
      isGoogleUrl: isGoogleSheet,
      lastUploadedList: ''
    });
  }else{
    yield put({
      type: ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_TOGGLE,
      showDialog: true,
      winTimeZone: winTimeZoneName,
      lastUploadedList: '',
      uploadListId: parseInt(listId),
    })
  }
}else{
  yield put({
    type: ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_TOGGLE,
    showDialog: true,
    winTimeZone: winTimeZoneName,
    lastUploadedList: '',
    uploadListId: parseInt(listId),
  })
}
  BrowserUtil.enableEvents();
}

function* isUpdatedfn({isUpdated}){
  yield put({
    type: ActionTypes.IS_UPDATED_SUCCESS,
    isUpdated: isUpdated,
  });
}
function* ftpUserNameChange({uname}){
  const ftpPass = yield select(ftpPassword);
  let saveEnable = false;
  if(uname != '' && ftpPass != ''){
    saveEnable = true;
  }
  yield put({
    type: ActionTypes.FTP_USERNAME_CHANGE_SUCCESS,
    uname: uname,
    enableSave: saveEnable
  });
}
function* ftpPasswordChange({pass}){
  const ftpUser = yield select(ftpUserName);
  let saveEnable = false;
  if(pass != '' && ftpUser != ''){
    saveEnable = true;
  }
  yield put({
    type: ActionTypes.FTP_PASSWORD_CHANGE_SUCCESS,
    pass: pass,
    enableSave: saveEnable
  });
}
function* uploadFileFormatException(){
  let validExts = [".xlsx", ".xls", ".csv", ".txt"];
  let exceptionMessage = "File type not supported, upload files in " + validExts.toString() + " types."
  yield put({
    type: ActionTypes.SHOW_FILE_EXCEPTION_DIALOG,
    showExceptionDialog: true,
    exceptionMessage: exceptionMessage
  });
}


function* enableCreateList({isCreateList}){  
  yield put({
    type: ActionTypes.IS_CREATE_LIST_SUCCESS,
    isCreateList: isCreateList,
  });
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchHandleGetExternalLists() {
  yield takeLatest(ActionTypes.INIT_EXTERNAL_LISTS, getExplorerLists);
}
export function* watchHandleUploadDialogState() {
  yield takeLatest(ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG, toggleUploadDialogState);
}
export function* watchHandleFormUpload() {
  yield takeLatest(ActionTypes.EXTERNAL_FILE_UPLOAD, formUpload);
}
export function* watchHandleToggleRecurring() {
  yield takeLatest(ActionTypes.TOGGLE_RECURRING_UPLOAD, toggleRecurring);
}
export function* watchHandleSaveLocalFile() {
  yield takeLatest(ActionTypes.SAVE_UPLOADED_FILE, saveTempFile);
}
export function* watchHandleSaveUploadTime() {
  yield takeLatest(ActionTypes.SAVE_UPLOADED_TIME, saveUploadTime);
}
export function* watchHandleNodeSelection() {
  yield takeLatest(ActionTypes.EXTERNAL_SELECTED_NODE, nodeSelection);
}
export function* watchCallSelectedNodes(){
  yield takeLatest(ActionTypes.SET_SELECTED_NODES, setSelectedNodes);
}
export function* watchCreateEmptyList(){
  yield takeLatest(ActionTypes.CREATE_EMPTY_LIST, createNewEmptyList);
}

export function* watchCallSetRenameNode(){  
  yield takeLatest(ActionTypes.SET_RENAME_NODE_EXTERNAL, setRenameNode);
}

export function* watchCallSetDuplicateNode(){
  yield takeLatest(ActionTypes.SET_DUPLICATE_NODE_EXTERNAL, setDuplicateNode);
}

export function* watchCallSaveDuplicateNode(){
  yield takeLatest(ActionTypes.DUPLICATE_NODE_EXTERNAL, saveDuplicateNode);
}

export function* watchCallSaveRenameNode(){
  yield takeLatest(ActionTypes.RENAME_NODE_EXTERNAL, saveRenameNode);
}
export function* watchLoadExtListOnAccept(){
  yield takeLatest(ActionTypes.LOAD_EXTERNAL_LIST_ON_WIZARD_ACCEPT, loadExternalListOnAccept);
}
export function* watchCallRollBack(){
  yield takeLatest(ActionTypes.ROLL_BACK_ACTION, rollBackExternalList);
}
export function* watchRemoveDeletedLists(){
  yield takeLatest(ActionTypes.REMOVE_DELETED_LISTS, removeDeletedItems);
}
export function* watchSortExternalData(){
  yield takeLatest(ActionTypes.SORT_EXTERNAL_LIST, sortExternalListData);
}

export function* watchundoExternalData(){
  yield takeLatest(ActionTypes.LIST_UNDO_ACTION, undoExternalListData);
}

export function* watchUploadOnDrop(){
  yield takeLatest(ActionTypes.EXTERNAL_FILE_UPLOAD_ON_DROP, uploadFormOnDropFile);
}

export function* watchUploadRepeat(){
  yield takeLatest(ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_REPEAT, uploadToExistingList);
}

export function* watchReuploadRollback(){
  yield takeLatest(ActionTypes.UNDO_EXTERNAL_REUPLOAD, externalreUploadRollback);
}

export function* watchIsUpdated(){
  yield takeLatest(ActionTypes.IS_UPDATED, isUpdatedfn);
}

export function* watchFtpUserNameChange(){
  yield takeLatest(ActionTypes.FTP_USERNAME_CHANGE, ftpUserNameChange);
}

export function* watchFtpPasswordChange(){
  yield takeLatest(ActionTypes.FTP_PASSWORD_CHANGE, ftpPasswordChange);
}

export function* watchFileFormatException(){
  yield takeLatest(ActionTypes.SHOW_FILE_FORMAT_EXCEPTION, uploadFileFormatException);
}

export function* watchEnabledCreatedList(){
  yield takeLatest(ActionTypes.IS_CREATE_LIST, enableCreateList);
}

export function* watchAddToList(){
  yield takeLatest(ActionTypes.IS_CREATE_LIST, enableCreateList);
}

export function* watchNextAvailability(){
  yield takeLatest(ActionTypes.FIND_NEXT_AVAILABLE_LIST, findAndOpenNextAvailable);
}

export function* watchSortExternal(){
  yield takeLatest(ActionTypes.SORT_EXTERNAL_SOURCE_LIST, sortLists)
}