import { ExternalDataConstants } from "../Constants/ExternalDataUploader";
const { ActionTypes } = ExternalDataConstants
import AppDispatcher from "AppDispatcher";
import { dispatch } from "../Redux/dispatch.js";
import { PriceChartConstants } from "../Constants/PriceChartConstants";

/*Actions */
export const showHideUploadDialog = (show) => ({
    type: ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG,
    showDialog: show
});
export const uploadListRepeat = (listId) => ({
    type: ActionTypes.EXTERNAL_FILE_UPLOAD_DIALOG_REPEAT,
    listId: listId
});

export const uploadFormData = (data) => ({
    type: ActionTypes.EXTERNAL_FILE_UPLOAD,
    formData: data
});
export const toggleRecurringUpload = () => ({
    type: ActionTypes.TOGGLE_RECURRING_UPLOAD
});
export const saveFileToTemp = (data) => ({
    type: ActionTypes.SAVE_UPLOADED_FILE,
    file: data
});
export const setUploadTime = (data) => ({
    type: ActionTypes.SAVE_UPLOADED_TIME,
    time: data
});
export const appendFileClick = () => ({
    type: ActionTypes.APPEND_FILE_CLICK
})
export const overwriteFileClick = () => ({
    type: ActionTypes.OVERWRITE_FILE_CLICK
})
export const uploadDataOnClose = () => ({
    type: ActionTypes.UPLOAD_DATA_ONCLOSE
})
export const saveUserName = (data) => ({
    type: ActionTypes.FTP_USERNAME_CHANGE,
    uname: data
})
export const savePassword = (data) => ({
    type: ActionTypes.FTP_PASSWORD_CHANGE,
    pass: data
});

export const showTimeDialog = () => ({
    type: ActionTypes.TOGGLE_TIME_CHANGER
});
export const hideTimeDialog = () => ({
    type: ActionTypes.TOGGLE_TIME_CHANGER
});
export const toggleFrequencyDialog = () => ({
    type: ActionTypes.TOGGLE_FREQUENCY_CHANGER
});
export const initExternalLists = (initCall) => ({
    type: ActionTypes.INIT_EXTERNAL_LISTS,
    initCall: initCall
});

export const getExternalDataSubMenu = (userSettings = null, isLogin = false) => ({
    type: ActionTypes.GET_EXTERNAL_DATA_SUB_MENU,
    userSettings: userSettings,
    isLogin: isLogin
});

export const getCurrentListData = (listId, lastnode = 0, timeLine = null, scale = '', minPrice = 0, maxPrice = 0, chartHeight = 0, isPriceChartView = false, epsMultiplier, rpsMultiplier, t4QMultipliers, priceHistory = null) => ({
    type: ActionTypes.GET_CURRENT_LIST_DATA,
    listId: listId,
    lastnode: lastnode,
    timeLine: timeLine,
    scale: scale,
    minPrice: minPrice,
    maxPrice: maxPrice,
    chartHeight: chartHeight,
    isPriceChartView: isPriceChartView,
    epsMultiplier: epsMultiplier,
    rpsMultiplier: rpsMultiplier,
    t4QMultipliers: t4QMultipliers,
    priceHistory: priceHistory
});

export const redrawExternalDataOnChartResize = (listId = null, lastnode = 0, timeLine = null, scale = '', minPrice = 0, maxPrice = 0, chartHeight = 0, isPriceChartView = false, epsMultiplier = NaN, rpsMultiplier = NaN, t4QMultipliers = NaN, esfields = '', priceHistory = null, initExtIndicator = false) => ({
    type: ActionTypes.REDRAW_ON_CHART_RESIZE,
    listId: listId,
    lastnode: lastnode,
    timeLine: timeLine,
    scale: scale,
    minPrice: minPrice,
    maxPrice: maxPrice,
    chartHeight: chartHeight,
    isPriceChartView: isPriceChartView,
    epsMultiplier: epsMultiplier,
    rpsMultiplier: rpsMultiplier,
    t4QMultipliers: t4QMultipliers,
    esfields: esfields,
    priceHistory: priceHistory,
    initExtIndicator: initExtIndicator
});

export const updateHeight = (height) => ({
    type: ActionTypes.UPDATE_HEIGHT,
    height: height
});

export const openExternalDataEditDialog = (item, isPriceChartSettings = false) => ({
    type: ActionTypes.INIT_EXTERNAL_DATA,
    item: item,
    isPriceChartSettings: isPriceChartSettings
});

export const handleModalHide = (isSaveObject, editDialogMenu, listId, editDialogAdvancedMenu, shouldHoldOnUserEvents = null) => ({
    type: ActionTypes.HIDE_MODAL_DIALOG,
    isSaveObject: isSaveObject,
    editDialogMenu: editDialogMenu,
    listId: listId,
    editDialogAdvancedMenu: editDialogAdvancedMenu,
    shouldHoldOnUserEvents: shouldHoldOnUserEvents
});

export const hideColorPalette = (isShowColorPalette) => ({
    type: ActionTypes.HIDE_COLOR_PALETTE,
    isShowColorPalette: isShowColorPalette
});

export const updateActiveItem = (activeItem) => ({
    type: ActionTypes.UPDATE_ACTIVE_ITEM,
    activeItem: activeItem
});

export const loadExternalList = (id, initCall = false,listType='') => ({
    type: ActionTypes.LOAD_EXTERNAL_LIST,
    listId: id,
    initCall: initCall,
    listType
})

export const nodeSelection = (id) => ({
    type: ActionTypes.EXTERNAL_SELECTED_NODE,
    listId: id
})

export const enableCreateList = (isCreateList) => ({
    type: ActionTypes.IS_CREATE_LIST,
    isCreateList: isCreateList
})

export const toggleAdvancedSettings = (isShowAdvanceSetting) => ({
    type: ActionTypes.SHOW_ADVANCE_SETTINGS,
    isShowAdvanceSetting: isShowAdvanceSetting
})

export const setSelectedNodes = (selectedNodes) => ({
    type: ActionTypes.SET_SELECTED_NODES,
    selectedNodes: selectedNodes
})
export const createListEmptyList = (listName,nodeType) => ({
    type: ActionTypes.CREATE_EMPTY_LIST,
    listName,nodeType
})


export const setRenameNode = (nodeToRename, selectedListType) => ({
    type: ActionTypes.SET_RENAME_NODE_EXTERNAL,
    nodeToRename: nodeToRename, selectedListType: selectedListType
})
export const unloadStateExternal = () => ({
    type: ActionTypes.UNLOAD_EXTERNAL_STATE_DATA
})


export const setDuplicateNode = (duplicateNode,selectedListType,alertMsg) => ({
    type: ActionTypes.SET_DUPLICATE_NODE_EXTERNAL,
    duplicateNode,selectedListType,alertMsg
})
export const changeListName = (name) => ({
    type: ActionTypes.SET_LIST_NAME_ONCHANGE,
    listName: name
})

export const saveDuplicateNode = (nodeName, duplicateNode, alertMsg) => ({
    type: ActionTypes.DUPLICATE_NODE_EXTERNAL,
    nodeName: nodeName,
    duplicateNode: duplicateNode,
    alertMsg: alertMsg
})

export const saveRenameNode = (listId, listName, parentNodeId) => ({
    type: ActionTypes.RENAME_NODE_EXTERNAL,
    listId: listId,
    listName: listName,
    parentNodeId: parentNodeId
})

export const updateTimeSeriesDataOfPricePane = (result, rawData, isShowToggleButton = false) => ({
        type: ActionTypes.UPDATE_PRICE_PANE_TIME_SERIES_DATA_TO_STORE,
        data: { result: result, rawData: rawData, isShowToggleButton: isShowToggleButton }
})

export const updateTimeSeriesDataOfIndicatorPane = () => {
    AppDispatcher.handleViewAction({
        actionType: ActionTypes.UPDATE_INDICATOR_PANE_TIME_SERIES_DATA_TO_STORE,
        data: {}
    });
}

export const updateEventSeriesDataOfPricePane = (eventSeriesData, eventSeriesData_AllPoints, clearsEXDReqInitiated = false) =>({
        type: ActionTypes.UPDATE_PRICE_PANE_EVENT_SERIES_DATA_TO_STORE,
        data: { eventSeriesData: eventSeriesData, eventSeriesAllNodes: eventSeriesData_AllPoints },
        clearsEXDReqInitiated: clearsEXDReqInitiated
    });

export const loadExternalOnAccept = (id) => ({
    type: ActionTypes.LOAD_EXTERNAL_LIST_ON_WIZARD_ACCEPT,
    listId: id
})
export const loadStartWizard = (id, listLoaded) => ({
    type: ActionTypes.LOAD_START_WIZARD_ON_ACCEPT,
    listId: id,
    isListLoaded: listLoaded
})
export const loadStartWizardDate = (id, listLoaded) => ({
    type: ActionTypes.LOAD_START_WIZARD_DATE,
    listId: id,
    isListLoaded: listLoaded
})
export const loadStartWizardDateFormat = (id, listLoaded) => ({
    type: ActionTypes.LOAD_START_WIZARD_DATE_FORMAT,
    listId: id,
    isListLoaded: listLoaded
})
export const rollBackList = (id) => ({
    type: ActionTypes.ROLL_BACK_ACTION,
    listId: id
})

export const removeDeletedLists = (nodeIds,listType) => ({
    type: ActionTypes.REMOVE_DELETED_LISTS,
    nodes: nodeIds,
    listType
})


export const initGridLoad = (data) => ({
    type: ActionTypes.INIT_GRID_LOAD,
    gridData: data
});

export const onGridDimensionsChange = (data) => ({
    type: ActionTypes.ON_GRID_DIMENSION_CHANGE,
    dimension: data
});

export const loadDataForVisibleRows = (data) =>
    ({
        type: ActionTypes.SET_VISIBLE_ROWS_DATA,
        rows: data
    });

export const drawFundamentalLines = (listId, timeSeriesData, rawTimeSeriesData, settings, scale, timeLine, multiplier, majorPeriodicity, startx, nodeWidth) => ({
    type: ActionTypes.DRAW_FUNDAMENTAL_DATA_LINES,
    listId: listId,
    timeSeriesData: timeSeriesData,
    rawTimeSeriesData: rawTimeSeriesData,
    settings: settings,
    scale: scale,
    timeLine: timeLine,
    multiplier: multiplier,
    majorPeriodicity: majorPeriodicity,
    startx: startx,
    nodeWidth: nodeWidth
});

export const setSymbolColumn = (data) =>
    ({
        type: ActionTypes.SET_SYMBOL_COLUMN,
        data: data
    });
export const setDateColumn = (data) =>
    ({
        type: ActionTypes.SET_DATE_COLUMN,
        data: data
    });

export const handleColumnSort = (evt, colData) =>
    ({
        type: ActionTypes.ON_CHANGE_COLUMN_SORT,
        evt: evt,
        colData: colData
    });

export const handleUpdateLoader = (isLoad) =>
    ({
        type: ActionTypes.ON_UPDATE_LOADER,
        isLoad: isLoad
    });
export const moveToNextState = () =>
    ({
        type: ActionTypes.MOVE_NEXT_STEP
    });
export const moveToPreviousState = () =>
    ({
        type: ActionTypes.MOVE_PREVIOUS_STEP
    });
export const closeDateFormatDialog = () => ({
    type: ActionTypes.CLOSE_DATE_FORMAT_DIALOG
})
export const finishAndSaveHeaders = () => ({
    type: ActionTypes.FINISH_AND_SAVE_HEADERS
})
export const listUndoAction = () => ({
    type: ActionTypes.LIST_UNDO_ACTION
})

export const updateCurrentIndex = (lastIndex) => ({
    type: ActionTypes.UPDATE_LAST_PAGE_INDEX,
    lastIndex
})

export const setDateFormatDialog = () => ({
    type: ActionTypes.SHOW_DATE_FORMAT_SELECT
})

export const closeUploadWizard = () => ({
    type: ActionTypes.CLOSE_UPLOAD_WIZARD
})

export const closeFileUploadWizard = () => ({
    type: ActionTypes.CLOSE_FILE_UPLOAD_WIZARD
})

export const sortExternalList = (sortedList) => ({
    type: ActionTypes.SORT_EXTERNAL_LIST,
    sortedList
})

export const showExternalInfoText = (externalInfoText) => ({
    type: ActionTypes.SHOW_EXTERNAL_INFO_TEXT,
    externalInfoText
});

export const uploadFormDataOnDrop = (data,listId,isReUpload = false) => ({
    type: ActionTypes.EXTERNAL_FILE_UPLOAD_ON_DROP,
    formData: data,
    isReUploadFile: isReUpload,
    listId: listId
})

export const showFormatException = () => ({
    type: ActionTypes.SHOW_FILE_FORMAT_EXCEPTION
})
export const changeUploadFrequency = (id) => ({
    type: ActionTypes.CHANGE_UPLOAD_FREQUENCY,
    freqId: id
})
export const setNewDateFormat = (format) => ({
    type: ActionTypes.SET_NEW_DATE_FORMAT,
    newFormat: format
})

export const clearExternalData = (isClearAll = false, listId, isShowToggleButton = false, chartHeight = 0, shouldUpdateToStore = false, isPeriodicityChanged = false) => ({
    type: ActionTypes.CLEAR_TIME_SERIES_DATA,
    isClearAll: isClearAll,
    listId: listId,
    isShowToggleButton: isShowToggleButton,
    chartHeight: chartHeight,
    shouldUpdateToStore: shouldUpdateToStore,
    isPeriodicityChanged: isPeriodicityChanged
});

export const updateToSettings = (listId, isRename = false, isDelete = false, isUpload = false, name = '', listType = '') => ({
    type: ActionTypes.UPDATE_TO_SETTINGS,
    listId: listId,
    isRename: isRename,
    isDelete: isDelete,
    isUpload: isUpload,
    name: name,
    listType: listType
});

export const updateEventSeriesData = (eventSeriesData, eventSeriesAllNodes) => ({
    type: ActionTypes.UPDATE_EVENT_SERIES_DATA,
    eventSeriesData: eventSeriesData,
    eventSeriesData_AllPoints: eventSeriesAllNodes
});
export const updateExtDataReqInitiated = (clearsEXDReqInitiated) => ({
    type: ActionTypes.UPDATE_EXTERNAL_DATA_REQ_INITIATED,
    clearsEXDReqInitiated
});

export const rollBackListreUpload = () => ({
    type: ActionTypes.UNDO_EXTERNAL_REUPLOAD
});

export const updateRiPanelSettings = (settings) => ({
    type: ActionTypes.UPDATE_CURRENT_SETTINGS_OBJECT,
    settings: settings
});

export const updatePDRToReducer = (PDR_info) => ({
    type: ActionTypes.UPDATE_PDR_TO_REDUCER,
    PDR_info: PDR_info
});
export const toggleExtDataItem = () => ({
    type: ActionTypes.TOGGLE_EXT_DATA_ITEM,
});

// export const updatePDRToStore = (PDR_info) => {
//     AppDispatcher.handleViewAction({
//         actionType: ActionTypes.UPDATE_PDR_TO_HEADER_STORE,
//         data: PDR_info
//     });
// };

export const updatePDRToStore = (PDR_info) => {
    dispatch({
        type: ActionTypes.UPDATE_PDR_TO_HEADER_STORE,
        PDR_info: PDR_info
    });
}

export const isUpdated = (isUpdated) => ({
    type: ActionTypes.IS_UPDATED,
    isUpdated: isUpdated
});
export const closeExceptionReport = () => ({
    type: ActionTypes.CLOSE_EXCEPTION_WINDOW
});

export const handleInputValueChange = (e, index, esFieldName) => ({
    type: ActionTypes.HANDLE_INPUT_VALUE_CHANGE,
    event: e,
    index: index,
    esFieldName: esFieldName
});

export const saveLabel = (newLabel, esField, listId) => ({
    type: ActionTypes.SAVE_LABEL,
    newLabel: newLabel,
    esField: esField,
    listId: listId
});

export const updatePriceAndIndicatorMenu = () => ({
        type: PriceChartConstants.ActionTypes.PREPARE_PRICE_AND_INDICATOR_MENU,
})

export const themeChange = (theme) => ({
    type: ActionTypes.EXD_THEME_CHANGED,
    theme: theme
});

export const overWriteSettingsOnReUpload = (listId) => ({
    type: ActionTypes.OVERWRITE_SETTINGS_ON_REUPLOAD,
    listId: listId
});

export const processListData = (listItems, userSettings) => ({
    type: ActionTypes.PROCESS_LIST_DATA,
    listItems: listItems,
    userSettings: userSettings
});

export const addToSourceList = (newList) => ({
    type: ActionTypes.PUSH_NEW_LIST_NODE,
    newList
});

export const renameExternalList = (nodeToRename,selectedListType) => ({
    type: ActionTypes.SET_RENAME_NODE_SUCCESS,
    nodeToRename,selectedListType,
    isUpdate: true
});

export const toggleListView = (folderId,status) => ({
    type: ActionTypes.TOGGLE_FOLDER_VIEW,
    folderId,status
});

export const findNextFolder = (folderId) => ({
    type: ActionTypes.FIND_NEXT_AVAILABLE_LIST,
    folderId
});

export const sortSourceList = () => ({
    type: ActionTypes.SORT_EXTERNAL_SOURCE_LIST
})

export const updateViewPort = (width) => ({
    type: ActionTypes.VIEWPORT_CHANGE_EXTERNAL,
    explorerWidth: width
})

export const setDeleteLoader = (disabled) => ({
        type: ActionTypes.DISABLE_DELETE_ICON,
        disabled
})