import React from "react";
import ThemeHelper from "ThemeHelper";
import DateHelper from "DateHelper";
import DocUtil from "DocUtil";
import PeriodicityHelper from "PeriodicityHelper";
import SettingsStore from "SettingsStore";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import BaseServiceApi from 'BaseServiceApi';
import LocalizationStore from 'LocalizationStore';
import GraphApi from "../../../../ServiceApi/Apis/GraphApi";
import AppColorStore from "../../../../Stores/AppColor/AppColorStore";

var  IdeaType = BaseServiceApi.rayData["IdeaType"];

export default class FundamentalsIdeaListTitle extends React.Component {
  constructor() {
    super();
    this.chgS = this.chgS.bind(this);
  }

  componentDidMount() {
    AppColorStore.addChangeListener(this.colorChanged);
  }

  componentWillUnmount() {
    AppColorStore.removeChangeListener(this.colorChanged);
  }

  colorChanged = () => {
    this.forceUpdate();
  }

  getDownloadIdeaAnalysisReportWebRequest(pdfResearchPath) {
    return GraphApi.getPdfRequestURL(pdfResearchPath);
  }

  getEntitledIdeaTypes(){
    let consoleSettings = SettingsStore.getConsoleSettings();
    let relatedInformationSettings = consoleSettings.NavDatagraphSettings.RelatedInformationSettings;
    let activeIdeaTypes = relatedInformationSettings.StockViewSettings.OneilListSettings.EntitledIdeaTypes;
    return activeIdeaTypes;
  }

  checkDisplayFlag(ideaData, activeIdeaTypes) {
    if (activeIdeaTypes.length === 0) {
      return false;
    }
    return activeIdeaTypes.includes(ideaData.ideaType);
  }

  getIdeaShortName(ideaType) {
    let IdeaName = "";
    switch (ideaType) {
      case IdeaType.ALLCAPS_LAGGARD_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_AllCapLaggard", "AC Laggard");
        break;
      case IdeaType.ALLCAPS_LEADER_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_AllCapLeader", "AC Leader");
        break;
      case IdeaType.LARGECAP_LAGGARD_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_LargeCapLaggard", "LC Laggard");
        break;
      case IdeaType.LARGECAP_LEADER_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_LargeCapLeader", "LC Leader");
        break;
      case IdeaType.DEVELOPED_LONG_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_DevelopedLong", "Developed Long");
        break;
      case IdeaType.EMERGING_LONG_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_EmergingLong", "Emerging Long");
        break;
      case IdeaType.FRONTIER_LONG_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_FrontierLong", "Frontier Long");
        break;
      case IdeaType.GFL_EUROPE_LONG_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_EuropeLong", "Europe Long");
        break;
      case IdeaType.USA_LONG_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_UnitedStatesLong", "US Long");
        break;
      case IdeaType.CHINA_ASHARE_LONG_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_ChinaASharesLong","China A Shares Long");
        break;
      case IdeaType.DEVELOPED_SHORT_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_DevelopedShort", "Developed Short");
        break;
      case IdeaType.EMERGING_SHORT_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_EmergingShort", "Emerging Short");
        break;
      case IdeaType.FRONTIER_SHORT_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_FrontierShort", "Frontier Short");
        break;
      case IdeaType.GFL_EUROPE_SHORT_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_EuropeShort", "Europe Short");
        break;
      case IdeaType.USA_SHORT_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_UnitedStatesShort", "US Short");
        break;
      case IdeaType.CHINA_ASHARE_SHORT_Idea:
        IdeaName = LocalizationStore.getTranslatedData("IdeaName_ChinaASharesShort","China A Shares Short");
        break;
      default:
        break;
    }
    return IdeaName;
  }
  getFlagsIconStyle(ideaType) {
    let style = "";
    switch (ideaType) {
      case IdeaType.USA_LONG_Idea:
        style = "icn-log1";
        break;
      case IdeaType.USA_SHORT_Idea:
        style = "icn-log-usa-short";
        break;
      case IdeaType.CHINA_ASHARE_LONG_Idea:
        style = "icn-log6";
        break;
      case IdeaType.CHINA_ASHARE_SHORT_Idea:
        style = "icn-log7";
        break;
      case IdeaType.ALLCAPS_LEADER_Idea:
        style = "icn-log-allcaps-leader";
        break;
      case IdeaType.ALLCAPS_LAGGARD_Idea:
        style = "icn-log-allcaps-laggard";
        break;
      case IdeaType.LARGECAP_LEADER_Idea:
        style = "icn-log-largecap-leader";
        break;
      case IdeaType.LARGECAP_LAGGARD_Idea:
        style = "icn-log-largecap-laggard";
        break;
      case IdeaType.DEVELOPED_LONG_Idea:
        style = "icn-log2";
        break;
      case IdeaType.DEVELOPED_SHORT_Idea:
        style = "icn-log-developed-short";
        break;
      case IdeaType.EMERGING_LONG_Idea:
        style = "icn-log3";
        break;
      case IdeaType.EMERGING_SHORT_Idea:
        style = "icn-log-emerging-short";
        break;
      case IdeaType.FRONTIER_LONG_Idea:
        style = "icn-log4";
        break;
      case IdeaType.FRONTIER_SHORT_Idea:
        style = "icn-log-frontier-short";
        break;
      case IdeaType.GFL_EUROPE_LONG_Idea:
        style = "icn-log5";
        break;
      case IdeaType.GFL_EUROPE_SHORT_Idea:
        style = "icn-log-europe-short";
        break;
      default:
        break;
    }
    return style;
  }

  getIdeaName(ideaType) {
    let IdeaName = "";
    switch (ideaType) {
      case IdeaType.ALLCAPS_LAGGARD_Idea:
        IdeaName = "All Cap Laggard";
        break;
      case IdeaType.ALLCAPS_LEADER_Idea:
        IdeaName = "All Cap Leader";
        break;
      case IdeaType.LARGECAP_LAGGARD_Idea:
        IdeaName = "Large Cap Laggard";
        break;
      case IdeaType.LARGECAP_LEADER_Idea:
        IdeaName = "Large Cap Leader";
        break;
      case IdeaType.DEVELOPED_LONG_Idea:
        IdeaName = "Developed Long";
        break;
      case IdeaType.EMERGING_LONG_Idea:
        IdeaName = "Emerging Long";
        break;
      case IdeaType.FRONTIER_LONG_Idea:
        IdeaName = "Frontier Long";
        break;
      case IdeaType.GFL_EUROPE_LONG_Idea:
        IdeaName = "Europe Long";
        break;
      case IdeaType.USA_LONG_Idea:
        IdeaName = "United States Long";
        break;
      case IdeaType.CHINA_ASHARE_LONG_Idea:
        IdeaName = "China A Shares Long";
        break;
      case IdeaType.DEVELOPED_SHORT_Idea:
        IdeaName = "Developed Short";
        break;
      case IdeaType.EMERGING_SHORT_Idea:
        IdeaName = "Emerging Short";
        break;
      case IdeaType.FRONTIER_SHORT_Idea:
        IdeaName = "Frontier Short";
        break;
      case IdeaType.GFL_EUROPE_SHORT_Idea:
        IdeaName = "Europe Short";
        break;
      case IdeaType.USA_SHORT_Idea:
        IdeaName = "United States Short";
        break;
      case IdeaType.CHINA_ASHARE_SHORT_Idea:
        IdeaName = "China A Shares Short";
        break;
      default:
        break;
    }
    return IdeaName;
  }

  chgS(chg, isPrice = true) {
    let price;
    let round;
    price = Math.abs(chg);

    round = price < 1.0 && isPrice ? 3 : 2;

    price = parseFloat(price.toFixed(round));

    var chgS = price > 999999
      ? ExtremeDataValue.abbreviateValue(price, 2)
      : (price > 99999
        ? Math.round(price)
        : (price < 1.0 && isPrice
          ? price.toFixed(3)
          : price.toFixed(2)));

    return chgS;
  }

  render() {
    let stState = this.props.stockData;
    let datagraphSettings = DatagraphStore.getState();
    let priceChange = 0;
    let consoleSettings = SettingsStore.getConsoleSettings();
    let tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);

    let activeIdeaTypes = this.getEntitledIdeaTypes();
    let tempIdeas = [];
    let sign = '';
    let idea = [];
    let pText = ThemeHelper.getThemedBrush("positiveDataText");
    let nText = ThemeHelper.getThemedBrush("negativeDataText");
    let ideaTextColor = priceChange >= 0 ? pText : nText;
    if (stState && stState.ideasData && stState.ideasData.ideas) { 
      if (stState.ideasData.ideas.xflType == 1) {
        idea.IdeaShortText = "    " + LocalizationStore.getTranslatedData("IdeaName_ExclusiveLongIdea", "Exclusive Long Idea");
        idea.researchPDFPath = stState.ideasData.ideas.pdfFilename ? stState.ideasData.ideas.pdfFilename : "";
      } else if (stState.ideasData.ideas.xflType == 2) {
        idea.IdeaShortText = "    " + LocalizationStore.getTranslatedData("IdeaName_ExclusiveShortIdea", "Exclusive Short Idea");
        idea.researchPDFPath = stState.ideasData.ideas.pdfFilename ? stState.ideasData.ideas.pdfFilename : "";
      }
      if (idea && idea.IdeaShortText && idea.IdeaShortText.length > 0) {
        tempIdeas.push(idea);
      }
      idea = [];
    }
    let ideaDisplayData = [];

    if (stState && stState.ideasData && stState.ideasData.ideas && stState.ideasData.ideas.currentIdeas.length > 0) {
      let chinaAIdea = stState.ideasData.ideas.currentIdeas.find((itm) => itm.ideaType === IdeaType.CHINA_ASHARE_LONG_Idea);
      (chinaAIdea) ? ideaDisplayData.push(chinaAIdea) : ideaDisplayData = stState.ideasData.ideas.currentIdeas;
      for (let i = 0; i < ideaDisplayData.length; i++) {
        let currentIdea = ideaDisplayData[i];
        if (this.checkDisplayFlag(currentIdea, activeIdeaTypes)) {
          let change = (100.0 * ((this.props.HiLowPoints?.allPoints[0]?.graphData?.Close / currentIdea.inPriceSplitAdj) - 1.0));
          sign = currentIdea.short ? (change < 0 ? '+' : '-') : (change >= 0 ? '+' : '-');
          if (change > 0) sign = '+';
          let inDate = currentIdea.inDt ? new Date(currentIdea.inDt.toNumber()) : DateHelper.parseJsonDate(datagraphSettings.SymbolInfo.LastTradeDate);
          let outDate = currentIdea.outDt ? new Date(currentIdea.outDt.toNumber()) : new Date(datagraphSettings.SymbolInfo.LastTradeDate.setHours(0,0,0,0));
          if (inDate !== undefined && outDate !== undefined) { 
            let days = DocUtil.CalculatDuration(inDate, outDate, majorPeriodicity, false);
            idea.IdeaShortText = "    " + this.getIdeaShortName(currentIdea.ideaType);
            idea.IdeaInfoText = " " + sign + this.chgS(Math.abs(change), false) + "% " + days;
            if (currentIdea.short)
              idea.ideaTextColor = change < 0 ? pText : nText;
            else
              idea.ideaTextColor = change >= 0 ? pText : nText;
            idea.IdeaStyle = this.getFlagsIconStyle(currentIdea.ideaType);
            idea.researchPDFPath = currentIdea.researchPDFPath ? currentIdea.researchPDFPath : "";
            idea.researchVideo = currentIdea.researchVideo ? currentIdea.researchVideo : "";
          }
        }
        if (idea && idea.IdeaShortText && idea.IdeaShortText.length > 0) {
          tempIdeas.push(idea);
        }
        idea = [];
      }
    }
    let pivotStyle = {
      position: 'absolute',
      cursor: 'pointer',
      pointerEvents: 'none',
      right: '0px',
      font: 'calibri',
      fontSize: '12px',
      top: '0px',
      fontWeight: 'bold',
      //display:'none' //fix bug PANWEB-1534
    }

    return(
      <div id='pivot' key='4' style={pivotStyle}>
        <div id="m-info" style={{ display: 'flex', alignItems: 'center' }}>
          {
            tempIdeas.map((idea, index) =>
              idea.IdeaShortText && idea.IdeaShortText.length > 0 &&
              <div className={"btn btn-xs btn-default small-normal ideas-button fund-label"} style={{ padding: '3px', cursor: 'pointer', pointerEvents: 'all' , display: 'flex', flexFlow: 'row', zIndex: '10', marginRight: '4px'}} key={5 + index}>
                <span className={idea.IdeaShortText ? idea.IdeaStyle : ""} style={{ marginLeft: '4px' , display: 'flex', flex: '1 auto', alignItems: 'center'}}></span>
                <span className="fund-label" style={{ color: ThemeHelper.getThemedBrush("summaryBlockHeaderText"), marginLeft: '0px' , display: 'flex', flex: '1 auto', alignItems: 'center'}}>{idea.IdeaShortText}</span>
                <span style={{ color: idea.ideaTextColor, marginRight: '4px' , marginLeft: '5px', display: 'flex', flex: '1 auto', alignItems: 'center'}}>{idea.IdeaInfoText}</span>
                {idea.researchPDFPath && <span className="idea-pdf-btn"><a className="small-normal" href={this.getDownloadIdeaAnalysisReportWebRequest(idea.researchPDFPath)}>PDF</a></span>}
                {idea.researchVideo && <span className="idea-video-btn"><a target="_blank" className="video-icon" href={idea.researchVideo}></a></span>}
              </div>
            )
          }
        </div>
      </div>
    );
  }
}