import BaseServiceApi from "BaseServiceApi";

class FinancialBlockReqInfo {
    constructor() {
        const financialBlockProto = BaseServiceApi.rayData["FindGetBlocksNewRequest"];
        const customFinancialBlockProto = BaseServiceApi.rayData["CustomFinancialBlockRequest"];
        this.financialBlockReqProto = new financialBlockProto();
        this.customFinancialBlockReqProto = new customFinancialBlockProto();
    }

    getFinancialBlockRequestData(request) {
        const { startDate, endDate, symbol, isHistoric, wonOnly, revWonOnly } = request;
        this.financialBlockReqProto.StartDate = startDate;
        this.financialBlockReqProto.EndDate = endDate;
        this.financialBlockReqProto.Symbol = symbol;
        this.financialBlockReqProto.WonOnly = Number(wonOnly);
        this.financialBlockReqProto.RevWonOnly = Number(revWonOnly);
        this.financialBlockReqProto.IsHistoric = isHistoric; 
        this.financialBlockReqProto.GetCustomFinancialBlock = true;
        this.financialBlockReqProto.CustomFinancialBlockRequest = this.getCustomFinancialBlockRequestData(request);
        this.financialBlockReqProto.GetOwnership = true;
        this.financialBlockReqProto.OwnerShipListId = request.ownerListId;
        this.financialBlockReqProto.Osid = request.osid;
        return this.financialBlockReqProto;
    }

    getCustomFinancialBlockRequestData(request) {
        const { headers, periodicity, finTargetCurrencyCode, symTypeEnum, osid, msid, defaultCurrency, defaultWSCurrency } = request;
        this.customFinancialBlockReqProto.Headers = headers;
        this.customFinancialBlockReqProto.Periodicity = periodicity;
        this.customFinancialBlockReqProto.FinTargetCurrencyCode = finTargetCurrencyCode;
        this.customFinancialBlockReqProto.symbolTypeEnum = symTypeEnum;
        this.customFinancialBlockReqProto.osId = Number(osid);
        this.customFinancialBlockReqProto.msid = Number(msid);
        this.customFinancialBlockReqProto.DefaultCurrency = defaultCurrency;
        this.customFinancialBlockReqProto.DefaultWSCurrency = defaultWSCurrency
        return this.customFinancialBlockReqProto;
    }
}

const financialBlockReqInfoObect = new FinancialBlockReqInfo();
export default financialBlockReqInfoObect;