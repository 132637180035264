export const ShareDialogConstants = {
  // SAVE_SHARED_DATA: 'Save shared data',
  SHOW_SHARED_DIALOG: 'show shared dialog',
  UPDATE_DIALOG_TYPE: 'update dialog type',
  UPDATE_USERS: 'update users',
  UPDATE_SHOW_USER_FLAG: "update show user flag",
  UPDATE_NAME: "update name",
  UPDATE_SHARED_DATA: "update shared data",
  UPDATE_FILTER_USER_DATA: 'update filter users flag',
  CLEAR_SHARE_DATA: 'clear share store data',
  SORT_MANAGED_DATA: 'Sort data',
  UPDAT_CONFIRMATION_FLAG: 'update confirmation flag',
  SHOW_IN_PROGRESS: 'show in progress',
  SHOW_TOOLTIP: 'show tooltip'

};

export const ShareActionConstants = {
  SHARE_LIST: 'SHARE_LIST',
  SHARE_COLUMN_SET: 'SHARE_COLUMN_SET',
  SHARE_ANNOTATIONS: 'SHARE_ANNOTATIONS',
  SHARE_RECIPIENTS: 'SHARE_RECIPIENTS',
  SHARE_EXTERNAL_DATA: 'SHARE_EXTERNAL_DATA'
};

export const NodeTypes = {
  SHARE_LIST: '1',
  COLUMNSET_List: '4',

};

export const ListType = {
  LIST_List: 1,
  FOLDER_List: 2,
  CATEGORY_List: 3,
  COLUMNSET_List: 4,
  ANNOTATIONLAYER_List: 5,
  EXTERNAL_List: 6
}

export const ShareAccessType = {
  SHARE_NOACCESS: 0,
  SHARE_READONLY: 1,
  SHARE_READANDEDIT: 2,
  SHARE_MIXEDMODE: 3
}




