import { put, call, select, takeLatest, all, fork } from 'redux-saga/effects';
import { getRotationGraphMenuData, getRotationGraphActiveMenuName } from '../../../Reducers/NavCompare/RotationGraphReducers/reselectorsdata';
import { ComparisonAndRotationMenuConstants } from '../../../Constants/RotationGraphConstants';
import { RotationGraphConstants } from '../../../Constants/RotationGraphConstants';
const { ActionTypes } = ComparisonAndRotationMenuConstants;
extend(ActionTypes, RotationGraphConstants);
import GridActions from "GridActions";
import ConsoleStore from "ConsoleStore";
import SettingsStore from "SettingsStore"
import StringUtil from 'StringUtil';
import { some, extend, find, without, map, filter, each, contains } from "underscore";
import { CompareMenuType } from "CompareTabType"
import { setScrollPosition } from 'Actions/ComparisonAndRotationMenuActions';
import { getBenchMarkData } from './BenchMarkSaga';
import DateHelper from "DateHelper";
import BrowserUtil from "BrowserUtil";
import { getNavCompareState } from '../../../Reducers/NavCompare/MenuPanelReducers/selectors';
import {getGroupsMenu} from '../../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import { transGeographyName, clearGraphData, initRotationGroupsMenu } from '../../../Sagas/NavCompare/MenuPanel/GroupsPanelSaga';
import CompareTabType from '../../../Constants/CompareTabType';
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";

export function* handleLoading(isLoading = false) {

    try {
        yield put({
            type: ActionTypes.MENU_LOADING,
            isLoading
        });

        if (!isLoading) {
            setTimeout(() => {
                ConsoleStore.setChartLoaded();
            }, 2000);
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in RotationMenuPanelSaga.js, handleLoading ${error}`);
    }
}

function* updateListItemVisibilty({ item }) {
    const menuData = yield select(getRotationGraphMenuData);

    try {
        find(menuData.listItems, (entry) => {
            if (item.osid == entry.osid) {
                if (!item.isVisible) {
                    menuData.isNotVisibleOsids = without(menuData.isNotVisibleOsids, item.osid);
                }
                else {
                    menuData.isNotVisibleOsids.push(item.osid);
                }
                extend(entry, { isVisible: !item.isVisible });
            }
        });

        find(menuData.stockList, (entry) => {
            if (item.osid == entry.osid) {
                extend(entry, { isVisible: !entry.isVisible });
            }
        });

        yield call(updatePointData);

        yield put({
            type: ActionTypes.SAVE_SETTINGS
        })
    }
    catch (error) {
        console.log(`Error occurs in  RotationMenuPanelSaga.js, updateListItemVisibilty ${error}`);
    }
    //this.updateRotationMenuSettings(null, this.isNotVisibleOsids.toString());
}


export function* LoadBenchMarkData(code) {
    const benchMarkRequestData = {
        //date: DateHelper.yyyymmdd(DateHelper.returnTimeZoneDate(new Date())),
        date: DateHelper.yyyymmdd(new Date()), //For Bug panweb-968 
        code: code
    }
    yield call(getBenchMarkData, { benchMarkRequestData: benchMarkRequestData });
}


export function* updatePointData() {
    const menuData = yield select(getRotationGraphMenuData);
    const menuName = yield select(getRotationGraphActiveMenuName);

    if (!StringUtil.isEmpty(menuData.quadGraphResults)) {
        if (!StringUtil.isEmpty(menuData.quadGraphResults.quadGraphItemsData2)) {
            map(menuData.quadGraphResults.quadGraphItemsData2, (item) => {
                item.isVisible = getListItemVisibility(item.id.low, menuData);
            })
        }
        if (menuName === CompareMenuType.Symbols) {
            yield put({
                type: ActionTypes.UPDATE_SYMBOLS_QUAD_GRAPH_RESULT,
                quadGraphResults: Object.assign({}, menuData.quadGraphResults)
            });
        } else {
            yield put({
                type: ActionTypes.UPDATE_GROUPS_QUAD_GRAPH_RESULT,
                quadGraphResults: Object.assign({}, menuData.quadGraphResults)
            });
        }


    }
}

function getListItemVisibility(osid, menuData) {
    let listItem = find(menuData.listItems, (item) => item.osid == osid);
    if (!StringUtil.isEmpty(listItem))
        return listItem.isVisible;
    return false;
}



function* updateFlag({ item }) {
    if (!StringUtil.isEmpty(item)) {
        let flagRequestData = {
            msids: item.msid,
            snapshotdates: null,
            isDelete: item.isFlagged,
            cacheId: 0,
            dbType: 1,
            selectedIndexes: []
        }
        BrowserUtil.disableEvents();
        yield call(addOrRemoveFlagging, flagRequestData);

    }
}

function* updateFlaggedListItems({ item }) {
    const menuName = yield select(getRotationGraphActiveMenuName);
    const menuData = yield select(getRotationGraphMenuData);
    const listItems = menuData.listItems;
    const stockList = menuData.stockList;
    some(listItems, (listItem) => {
        if (listItem.msid == item.msid)
            listItem.isFlagged = !item.isFlagged;
    });
    some(stockList, (stockListItem) => {
        if (stockListItem.msid == item.msid)
            stockListItem.isFlagged = !item.isFlagged;
    });

    if (menuName === CompareMenuType.Symbols) {
        yield put({
            type: ActionTypes.SET_SYMBOLS_LISTITEMS,
            listItems: listItems
        })

        yield put({
            type: ActionTypes.SET_SYMBOLS_STOCK_LISTITEMS,
            stockList: stockList
        })
    } else {
        yield put({
            type: ActionTypes.SET_GROUPS_LISTITEMS,
            listItems: listItems
        })

        yield put({
            type: ActionTypes.SET_GROUPS_STOCK_LISTITEMS,
            stockList: stockList
        })
    }
    yield put({
        type: ActionTypes.SAVE_SETTINGS
    })
    BrowserUtil.enableEvents();
}

function* addOrRemoveFlagging(flagRequestData) {
    if (flagRequestData) {
        GridActions.updateGlobalFlag(flagRequestData.msids.toString(), flagRequestData.snapshotdates, flagRequestData.isDelete, flagRequestData.cacheId, flagRequestData.dbType, flagRequestData.selectedIndexes);
    }
}

function* setListItems() {
    const menuName = yield select(getRotationGraphActiveMenuName);
    const menuData = yield select(getRotationGraphMenuData);
    const listItems = menuData.listItems;
    if (menuName === CompareMenuType.Symbols) {
        yield put({
            type: ActionTypes.SET_SYMBOLS_LISTITEMS,
            listItems: listItems
        });
    } else {
        yield put({
            type: ActionTypes.SET_GROUPS_LISTITEMS,
            listItems: listItems
        });
    }
}

function* listItemHighlightSelected({ key, item }) {
    const menuData = yield select(getRotationGraphMenuData);
    const menuName = yield select(getRotationGraphActiveMenuName);
    let selectItemsCount = filter(menuData.listItems, (s) => { if (s.isSelected) return s });

    try {
        if (!StringUtil.isEmpty(selectItemsCount)) {
            if (selectItemsCount.length == 1 && item.isSelected) {
                yield call(highlightAll, item, 'Shift');
                menuData.lastClicked = item;
                if (menuData.lastHighlightedOsids.indexOf(parseInt(selectItemsCount[0].osid)) === -1)
                    menuData.lastHighlightedOsids.push(parseInt(selectItemsCount[0].osid));
                each(menuData.listItems, (entry) => { entry.isLabelHighlighted = false; });
                yield call(updateOriginalArray);

                yield call(setListItems);

                yield put({
                    type: menuName === CompareMenuType.Symbols ? ActionTypes.HIGHLIGHT_SYMBOLS_ITEM_SUCCESS : ActionTypes.HIGHLIGHT_GROUPS_ITEM_SUCCESS
                });

                yield put({
                    type: ActionTypes.SAVE_SETTINGS
                })
                //this.updateRotationMenuSettings(null, null, null);
                return;
            }
        }

        if (StringUtil.isEmpty(menuData.lastClicked))
            menuData.lastClicked = menuData.listItems[0];

        switch (key) {
            case 'Shift':
                yield call(listItemShiftClicked, item);
                break;
            case 'Ctrl':
                yield call(listItemCtrlClicked, item);
                yield call(setScrollPosition, item.osid, item.isHighlighted);
                break;
            case 'Click':
                yield call(listItemClicked, item);
                yield call(setScrollPosition, item.osid, item.isHighlighted);
                break;
            default:
                return;
        }

        menuData.lastClicked = item;

        yield call(setListItems);

        yield put({
            type: menuName === CompareMenuType.Symbols ? ActionTypes.HIGHLIGHT_SYMBOLS_ITEM_SUCCESS : ActionTypes.HIGHLIGHT_GROUPS_ITEM_SUCCESS
        });

        yield put({
            type: ActionTypes.SAVE_SETTINGS
        });
    }
    catch (error) {
        console.log(`Error occurs in  RotationMenuPanelSaga.js, listItemHighlightSelected ${error}`);
    }
    // this.updateRotationMenuSettings(null, null, this.lastHighlightedOsids.toString());
}

function* listItemClicked(item) {
    const menuData = yield select(getRotationGraphMenuData);
    const highlightAllStatus = yield call(highlightAll, item, 'Click');
    if (!highlightAllStatus) {
        return;
    }

    try {
        menuData.lastHighlightedOsids = [];
        each(menuData.listItems, (entry) => {
            if (item.osid == entry.osid) {
                entry.isHighlighted = true;
                entry.isSelected = true;
                entry.isLabelHighlighted = true;
                if (menuData.lastHighlightedOsids.indexOf(parseInt(entry.osid)) === -1)
                    menuData.lastHighlightedOsids.push(parseInt(entry.osid));
            }
            else {
                entry.isHighlighted = false;
                entry.isSelected = false;
                entry.isLabelHighlighted = false;
            }
        });

        if (menuData.lastHighlightedOsids.length == menuData.listItems.length) {
            each(menuData.listItems, (entry) => { entry.isLabelHighlighted = false; });
        }

        yield call(updateOriginalArray);
    }
    catch (error) {
        console.log(`Error occurs in  RotationMenuPanelSaga.js, listItemClicked ${error}`);
    }
}

function* listItemCtrlClicked(item) {
    const menuData = yield select(getRotationGraphMenuData);
    const highlightAllStatus = yield call(highlightAll, item, 'Ctrl')

    if (!highlightAllStatus)
        return;

    try {
        each(menuData.listItems, (entry) => {
            if (item.osid == entry.osid) {
                if (item.isSelected) {
                    entry.isSelected = false;
                    entry.isHighlighted = false;
                    entry.isLabelHighlighted = false;
                    menuData.lastHighlightedOsids = without(menuData.lastHighlightedOsids, item.osid);
                }
                else {
                    entry.isSelected = true;
                    entry.isHighlighted = true;
                    entry.isLabelHighlighted = true;
                    if (menuData.lastHighlightedOsids.indexOf(parseInt(item.osid)) === -1)
                        menuData.lastHighlightedOsids.push(parseInt(item.osid))
                }
            }
        });

        if (menuData.lastHighlightedOsids.length == menuData.listItems.length) {
            each(menuData.listItems, (entry) => { entry.isLabelHighlighted = true; });
        }

        yield call(updateOriginalArray);
    }
    catch (error) {
        console.log(`Error occurs in  RotationMenuPanelSaga.js, listItemCtrlClicked ${error}`);
    }
}

function* listItemShiftClicked(item) {
    try {
        const menuData = yield select(getRotationGraphMenuData);
        let preSeletedItems = null;
        let listItems = null;
        let maxIndex = Math.max(menuData.listItems.indexOf(item), menuData.listItems.indexOf(menuData.lastClicked));
        let minIndex = Math.min(menuData.listItems.indexOf(item), menuData.listItems.indexOf(menuData.lastClicked));

        listItems = filter(menuData.listItems, (s) => (menuData.listItems.indexOf(s) <= maxIndex && menuData.listItems.indexOf(s) >= minIndex && s.osid != menuData.lastClicked.osid));

        preSeletedItems = filter(menuData.listItems, (s) => s.osid !== menuData.lastClicked.osid);

        if (!StringUtil.isEmpty(preSeletedItems)) {
            each(preSeletedItems, (s) => {
                s.isSelected = false;
                s.isHighlighted = false;
                s.isLabelHighlighted = false;
                unHighlightSelected(s, menuData);
                menuData.lastHighlightedOsids = without(menuData.lastHighlightedOsids, s.osid);
            })
        }

        if (!StringUtil.isEmpty(listItems)) {
            each(listItems, (s) => {
                s.isSelected = true;
                s.isHighlighted = true;
                s.isLabelHighlighted = true;
                highlightSelected(s, menuData);
                if (menuData.lastHighlightedOsids.indexOf(parseInt(s.osid)) === -1)
                    menuData.lastHighlightedOsids.push(parseInt(s.osid));
            })
        }

        if (menuData.lastHighlightedOsids.length == menuData.listItems.length) {
            each(menuData.listItems, (entry) => { entry.isLabelHighlighted = true; });
        }
        else {
            each(menuData.listItems, (entry) => {
                entry.isLabelHighlighted = contains(menuData.lastHighlightedOsids, entry.osid) ? true : false;
            });
        }

        yield call(updateOriginalArray);
    }
    catch (error) {
        console.log(`Error occurs in  RotationMenuPanelSaga.js, listItemShiftClicked ${error}`);
    }
}

function* highlightAll(item, key) {
    const menuData = yield select(getRotationGraphMenuData);

    try {
        if (key == 'Shift') {
            menuData.lastHighlightedOsids = [];
            each(menuData.listItems, (entry) => {
                entry.isSelected = true;
                entry.isHighlighted = true;
                menuData.lastHighlightedOsids.push(parseInt(entry.osid));
            });
            return;
        }
        else {
            if (menuData.lastHighlightedOsids.length == 1) {
                if (item.osid == menuData.lastHighlightedOsids[0]) {
                    each(menuData.listItems, (entry) => {
                        entry.isSelected = true;
                        entry.isHighlighted = true;

                    });
                    menuData.lastHighlightedOsids = [];
                    return false;
                }
            }
            return true;
        }
    }
    catch (error) {
        console.log(`Error occurs in  RotationMenuPanelSaga.js, highlightAll ${error}`);
    }
}

function unHighlightSelected(item, menuData) {
    each(menuData.listItems, (s) => {
        if (s.osid == item.osid) {
            s.isSelected = false;
            s.isHighlighted = false;
        }
    })
}

function highlightSelected(item, menuData) {
    each(menuData.listItems, (s) => {
        if (s.osid == item.osid) {
            s.isSelected = true;
            s.isHighlighted = true;
        }
    })
}

function* updateOriginalArray() {
    const menuData = yield select(getRotationGraphMenuData);

    each(menuData.stockList, (entry) => {
        if (contains(menuData.lastHighlightedOsids, entry.osid)) {
            entry.isSelected = true;
            entry.isHighlighted = true;
            entry.isLabelHighlighted = true;
        }
        else {
            entry.isSelected = false;
            entry.isHighlighted = false;
            entry.isLabelHighlighted = false;
        }
    });
}

function* routeToRotationGraph({navType, infoData}) {
    const navCompareState = yield select(getNavCompareState);
    const groupsMainData = navCompareState.groupsMainData;
    let industryListData = undefined;
    let majorindustrylist = undefined;
    let geographyDataItemsList = undefined;
    let sectorDataItemsList = undefined;
    let industryItem = undefined;
    let majorIndustryItem = undefined;
    let geographyItem = undefined;
    let sectorItem = undefined;

    if (!StringUtil.isEmpty(groupsMainData)) {
        industryListData = groupsMainData["IndustryList"];
        majorindustrylist = groupsMainData["MajorIndustryList"];
        geographyDataItemsList = groupsMainData["CountryList"];
        sectorDataItemsList = groupsMainData["SectorDataList"];
    }        
    industryItem = industryListData.filter((industry) => industry.WonIndustryCode === infoData.industry197Symbol);

    if (industryItem.length > 0) {
        majorIndustryItem = majorindustrylist.filter((majorIndustry) => majorIndustry.Osid.low === industryItem[0].MajorIndustryOsid.low);
        if (majorIndustryItem.length > 0) {
            sectorItem = sectorDataItemsList.filter((sector) => sector.Osid.low === majorIndustryItem[0].SectorOsid.low);
            geographyItem = geographyDataItemsList.filter((geography) => geography.CountryId.low === majorIndustryItem[0].CountryCode.low);
        }
    }

    const breadCrumbObj = [
        {
            countryCode: geographyItem[0].CountryId.low,
            indCode: null,
            itemName: geographyItem[0].CountryName,
            displayName: yield call(transGeographyName,geographyItem[0]),
            osid: null,
            rotationMenuType: 1
        },
        {
            countryCode: geographyItem[0].CountryId.low,
            indCode: null,
            itemName: sectorItem ? sectorItem[0].Description : infoData.Sector11Symbol.trim(),
            displayName: sectorItem ? sectorItem[0].Description : infoData.Sector11Symbol.trim(),
            osid: sectorItem && sectorItem[0].Osid.low,
            rotationMenuType: 3
        },
        {
            countryCode: geographyItem[0].CountryId.low,
            indCode: infoData.IndCode,//industryItem[0].WonIndustryCode.substring(1, industryItem[0].WonIndustryCode.length),
            itemName: industryItem[0].WonIndustryDesc,
            displayName: industryItem[0].WonIndustryDesc,
            osid: null,
            rotationMenuType: 4
        }
    ]

    yield all([
            put({type:ActionTypes.COMPARE_UPDATE_TAB_TO_STATE,
                selectedTab:CompareTabType.RotationGraph
            }),

            put({
                type:ActionTypes.SET_COMPARE_MENUTAB,
                menuTab:CompareMenuType.Groups
            })
        ])

    const groupsMenu = yield select(getGroupsMenu);
    yield call(clearGraphData, groupsMenu);

    yield call(updateRotationBreadCrumSettings,breadCrumbObj);

    ConsoleStore.UpdateRout(infoData.symbol, navType);

    yield call(initRotationGroupsMenu);
}

function* updateRotationBreadCrumSettings(breadCrumbObj) {
    const consoleSettings = yield call(SettingsStore.getConsoleSettings);
    let viewSetting = consoleSettings.NavCompareSettings.TabRotationGraphSettings.rotationGroupsMenuSettings;
    if (!StringUtil.isEmpty(breadCrumbObj))
    CompareSettingHelper.updateGroupsMenuBreadCrumList(viewSetting, breadCrumbObj);
    SettingsStore.saveSettings();
}



export function* watchRotationListItemHighlightSelected() {
    yield takeLatest(ActionTypes.ROTATION_LIST_ITEM_HIGHLIGHT_SELECTED, listItemHighlightSelected)
}

export function* watchUpdateFlag() {
    yield takeLatest(ActionTypes.UPDATE_FLAG, updateFlag)
}

export function* watchUpdateFlaggedListItems() {
    yield takeLatest(ActionTypes.UPDATE_FLAGGED_LIST_ITEMS, updateFlaggedListItems)
}

export function* watchUpdateListItemVisibility() {
    yield takeLatest(ActionTypes.UPDATE_LIST_ITEM_VISIBILITY, updateListItemVisibilty)
}

export function* watchRouteToRotationGraph(){
    yield takeLatest(ActionTypes.ROUTE_TO_ROTATION_GRAPH, routeToRotationGraph);
}
