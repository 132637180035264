import React, { PureComponent } from "react";
import { AddList, SymbolSearchBox } from './index';
import { ListCount, ClearAll } from '../Presentational/index';
import { connect } from 'react-redux';
import { PrintMode } from '../../../../../print/printmode';
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType from "CompareTabType";
import PortalContainer from 'PortalContainer';
import { setListTitlePosition } from 'Actions/ComparisonAndRotationMenuActions';

class SymbolsMenuHeader extends PureComponent {

    mouseEnter(e){
        this.props.setTitlePosition(e.pageX,e.pageY+10, true);
    }
    mouesLeave(){
      this.props.setTitlePosition( 0,0,false );
    }
  render() {
    const { listName,showTitle,posX,posY } = this.props;
    const toolStyle = {top: posY, left: posX};
    return (
      <React.Fragment>
        <div className="comperison-filter-box">
          <ErrorBoundary><AddList /></ErrorBoundary>
          <ErrorBoundary><SymbolSearchBox /></ErrorBoundary>
          <div id="rotation_geography_list_breadcrumb" className="comperison-filter">
            {/* <span style={{ float: "right" }}></span> */}
            {!PrintMode.printing && <span className="paginations">
              <ErrorBoundary><ListCount /></ErrorBoundary>
              <ErrorBoundary><ClearAll /></ErrorBoundary>
            </span>}
            <div className="page-list-name">
              <span className="text" onMouseEnter={(e) => {this.mouseEnter(e)}} onMouseMove={(e) => {this.mouseEnter(e)}} onMouseLeave={() => {this.mouesLeave()}}>{listName != undefined ? listName : ''}</span>
              {showTitle && <PortalContainer><div style={toolStyle} className="tooltip-box compare-list-title">{listName != undefined ? listName : ''}</div></PortalContainer>}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  setTitlePosition: (x,y,show) => dispatch(setListTitlePosition(x,y,show))
})
const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const symbolsMenu = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
  const { listName } = selectedTab === CompareTabType.ComparisonGraph ? symbolsMenu.ComparisonMenuStates : symbolsMenu.RotationMenuStates;
  const { showTitle,posX,posY } = symbolsMenu;
  return { listName,showTitle,posX,posY };
};

export default connect(mapStateToProps,mapDispatchToProps)(SymbolsMenuHeader);
