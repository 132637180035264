import AnnotationsSvgContent from '../AnnotationsSvgContent';
import AnnotationStage from './Containers/AnnotationStage.jsx';
import AnnotationUtil from '../../../../../Utils/AnnotationUtil';
import { closeContextMenu } from '../../../../../Actions/PricePanelActions';
import { connect } from 'react-redux';
import { ConsoleConstants } from "Constants/ConsoleConstants.js";
import ConsoleStore from "ConsoleStore";
import ErrorBoundary from 'ErrorBoundary';
import Measure from "react-measure";
import MeasurementTextInfo from '../AnnotationLeftPanel/Presentational/MeasurementTextInfo';
import { PrintMode } from '../../../../../print/printmode';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import { SettingsConstants } from '../../../../../Constants/SettingsConstants';
import SettingsStore from "SettingsStore";
import stringUtil from '../../../../../Utils/StringUtil';
import { onDimensionsChange, handleToggleContextMenu, updateStageClickCapturedFlag, reDrawAnnotationsOnChartResize } from '../../../../../Actions/AnnotationActions';
import React, { Component } from 'react';

let isInit = true;
let shouldHandleResize = true;

class AnnotationPanel extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

    componentWillUnmount() {
        if (PrintMode.printing) {
            ConsoleStore.removeChangeListener(this.ConsoleStateChange);
            SettingsStore.removeChangeListener(this.SettingsStoreChange);
            shouldHandleResize = true;
        }
    }

    ConsoleStateChange() {
        const currentAction = ConsoleStore.getCurrentAction();
        if (currentAction === ConsoleConstants.ActionTypes.SET_CHART_LOADED) {
            shouldHandleResize = false;
        }
    }

    SettingsStoreChange() {
        const currentAction = SettingsStore.getCurrentAction();
        if (currentAction === SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT) {
            shouldHandleResize = true;
        }
    }

    componentDidMount() {
        this.updateClass();
        if (PrintMode.printing) {
            ConsoleStore.addChangeListener(this.ConsoleStateChange);
            SettingsStore.addChangeListener(this.SettingsStoreChange);
        }
    }

    componentDidUpdate() {
        if (isInit) {
            this.updateClass();
        }
    }

    updateClass() {
        if (this.props.annotationMenuReducer.selectedToolType !== null) {
            const annotationDiv = document.getElementsByClassName('annotation-graphics')[0];
            if (stringUtil.isEmpty(annotationDiv)) {
                return;
            }

            if (AnnotationUtil.isAnnotationsTabActive() && this.props.annotationMenuReducer.selectedToolType !== 0) {
                annotationDiv.className = "annotation-graphics";
                annotationDiv.style.zIndex = 10;
            }
            else {
                annotationDiv.className += " selected-selectiontool";
                annotationDiv.style.zIndex = "";
            }

            isInit = false;
        }
    }

    static getDerivedStateFromProps(props) {
        // case when other context menu (eg: price alert, eps settings) in the price chart is accessed while annotation context menu is open 
        if (props.isOtherPriceChartContextMenuActive && props.showContextMenu) {
            props.toggleContextMenu(false, 0, 0, '');
        }
        return null; // No change to state
    }

    onContextMenu = (e) => {
        if (AnnotationUtil.isAnnotationsTabActive()) {
            if (this.props.annotationMenuReducer.selectedToolType === 0) {
                this.props.onContextMenu(e);
            }
            else {
                e.preventDefault();
                return;
            }
        }
        else {
            this.props.onContextMenu(e);
        }
    }

    onDimensionsChange = (dim) => {
        if (shouldHandleResize) {
            this.props.onDimensionsChange(dim);
            if(this.props.annotationDimension.width === 0 && this.props.annotationDimension.height === 0){
                this.props.reDrawAnnotationsOnChartResize()
            }
        }
    }

    render() {
        const { width, height } = this.props.annotationDimension;
        const { measurementReducer, isDrawing, annotSelected, activeAnchor, orderedLayersList, isDragging, isStageClickCaptured } = this.props;

        return (
            <Measure
                bounds
                onResize={(contentRect) => this.onDimensionsChange(contentRect.bounds)}
            >
                {/* {(BrowserUtil.getBrowserName() !== 'IE' || BrowserUtil.getBrowserName() !== 'Netscape') ?
                    ({ measureRef }) =>
                        <div className="annotation-graphics" style={{ left: '0' }} ref={measureRef}>
                            {
                                width !== 0 && height !== 0 &&
                                <div
                                    id="annotation-layer"
                                    height='100%'
                                    width='100%'
                                >
                                    <div height='100%' width='100%' onContextMenu={this.onContextMenu}>
                                        <ErrorBoundary><AnnotationStage width={width} height={height} closeOtherContextMenu={() => this.props.isOtherPriceChartContextMenuActive && this.props.closeContextMenu()} /></ErrorBoundary>
                                    </div>
                                </div>
                            }
                        </div>
                    : */}
                {({ measureRef }) =>
                    <>
                        {/* To display duration, gain and decline values of measurement annotations */}
                        <div id="annot-measurement-text-wrap" className="annotation-measurements" onContextMenu={this.onContextMenu}>
                            {measurementReducer.map((item, index) => !item.isDeleted &&
                                <MeasurementTextInfo key={index} data={item} activeAnchor={activeAnchor} isInDrawingMode={isDrawing}
                                    isDragging={isDragging} layerList={orderedLayersList} selectedAnnot={annotSelected}
                                    isMeasurementAnnot={annotSelected && annotSelected.type === RayAnnotationType.MEASUREMENT_AnnotationType}
                                    isStageClickCaptured={isStageClickCaptured} updateStageClickCapturedFlag={this.props.updateStageClickCapturedFlag} />)
                            }
                        </div>
                        <div className="annotation-graphics" ref={measureRef}>
                            {
                                width !== 0 && height !== 0 &&
                                <svg
                                    id="annotation-layer"
                                    height='100%'
                                    width='100%'
                                    style={{ position: 'absolute' }}
                                >
                                    {(!AnnotationUtil.isAnnotationsTabActive() || this.props.annotationMenuReducer.tempToolType === 0) && <AnnotationsSvgContent closeOtherContextMenu={() => this.props.isOtherPriceChartContextMenuActive && this.props.closeContextMenu()} />}
                                </svg>}
                            {/* <foreignObject height='100%' width='100%' onContextMenu={this.onContextMenu}> */}
                            <ErrorBoundary><AnnotationStage className="graphics-box-hold" width={width} height={height} closeOtherContextMenu={() => this.props.isOtherPriceChartContextMenuActive && this.props.closeContextMenu()} /></ErrorBoundary>
                            {/* </foreignObject> */}


                        </div>
                    </>
                }
            </Measure>
        );
    }
}

const mapStateToProps = ({ annotationReducers, DatagraphReducers }) => {
    const { annotationChartModelReducer, annotationMenuReducer, measurementReducer } = annotationReducers;
    const { annotationDimension, showContextMenu, isDrawing, activeAnchor, isDragging, annotSelected, isStageClickCaptured } = annotationChartModelReducer;
    const { LayersList, modifiedLayers, isLayerOrderChange } = annotationReducers.annotationLayerManagementReducer;
    const isOtherPriceChartContextMenuActive = DatagraphReducers.ContextMenuReducer.showContextMenu;
    let orderedLayersList = LayersList;

    if (isLayerOrderChange) {
        orderedLayersList = modifiedLayers;
    }

    return ({ annotationDimension, annotationMenuReducer, showContextMenu, measurementReducer, isDrawing, activeAnchor, isDragging, annotSelected, orderedLayersList, isStageClickCaptured, isOtherPriceChartContextMenuActive });
}

const mapDispatchToProps = (dispatch) => ({
    onDimensionsChange: (dim) => dispatch(onDimensionsChange(dim)),
    toggleContextMenu: (showContext, clientX, clientY, shape) => dispatch(handleToggleContextMenu(showContext, clientX, clientY, shape)),
    updateStageClickCapturedFlag: (isStageClickCaptured) => dispatch(updateStageClickCapturedFlag(isStageClickCaptured)),
    closeContextMenu: () => dispatch(closeContextMenu()),
    reDrawAnnotationsOnChartResize: ()=> dispatch(reDrawAnnotationsOnChartResize())
});


export default connect(mapStateToProps, mapDispatchToProps)(AnnotationPanel);