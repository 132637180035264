import { put, call, select, takeLatest, all, fork, delay } from 'redux-saga/effects';
import { getActiveCompNavTabName, getGroupsMenu, getSelectedTabMenuStateObjName } from '../../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import { ComparisonAndRotationMenuConstants } from '../../../Constants/RotationGraphConstants';
import { getNavCompareState, getGroupsPanel, getLiquidityFilter } from '../../../Reducers/NavCompare/MenuPanelReducers/selectors';
import { setRotationGraphFilter } from '../MenuPanel/LiqAndRatingFilterSaga';
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import StringUtil from 'StringUtil';
import { each, map, sortBy, find, contains, filter } from "underscore";
import SymbolType from "../../../Constants/SymbolType.js";
import SettingsStore from "SettingsStore";
import UserInfoUtil from "UserInfoUtil";
import LocalizationStore from 'LocalizationStore';
import LangType from '../../../Constants/LangType';
import {resetZoomValue, getQuadData, initializeTimelineValues, updateFromUserSettingsData } from 'Actions/RotationGraphActions';
import {LoadBenchMarkData} from '../RotationGraph/RotationMenuPanelSaga';
import { setScrollPosition } from 'Actions/ComparisonAndRotationMenuActions';
import { RotationMenuType } from "Constants/RotationMenuType";
import CompareTabType from "CompareTabType";
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";
import store from '../../../Redux/store';
import BaseServiceApi from 'BaseServiceApi';
import {getSymbolTypeEnumStringValue, calculateDataPointsForSymbols} from '../ComparisionGraph/chartViewModel';
import { SymbolDataModel } from '../../../Models/ComparisonChartModels/LineDataModel';
import { CompRotationHelper } from '../../../Models/NavCompare/ComparisonAndRotationMenuModels/MenuStates';
import CompareApi from "CompareApi";
import { getBenchMarkState } from '../../../Reducers/NavCompare/RotationGraphReducers/selectors';
let menuInit = false;
let geographyData = [];
let GroupType = BaseServiceApi.rayData["GroupType"];

function* handleLoading(isLoading = false) {

    try {
        yield put({
            type: ActionTypes.MENU_LOADING,
            isLoading
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, handleLoading ${error}`);
    }
}

function* initCompChart() {
    console.log("PRINT:--Tracker -- initCompChart loop");
    yield delay(200);
    const liqFilterState = yield select(getLiquidityFilter);

    if (liqFilterState.liqFilterValues !== null) {
        yield put({
            type: ActionTypes.INIT_COMP_CHART,
        });
    }
    else {
        yield fork(initCompChart);
    }
}

function* initCompGroupsMenu() {

    let menuSettings = SettingsStore.getConsoleSettings().NavCompareSettings.TabComparisonGraphSettings.comparisonGroupsMenuSettings;
    let selectedStock = null;

        yield put({
            type:ActionTypes.INIT_LIQ_VIEW_MODEL,
     })
     
    yield fork(initCompChart);

        const stateobjName = yield select(getSelectedTabMenuStateObjName);
        const { selectedTab } = yield select(getNavCompareState);
        const groupsMenuState = store.getState().comparisonAndRotationMenuPanel.GroupsPanelReducer[stateobjName];


            groupsMenuState.isGeographySelected = true;
            const { breadCrumbItems } = menuSettings;
            const data = yield call(setGeographyByCountryCode, menuSettings.countryCode, selectedTab, groupsMenuState);
            groupsMenuState.selectedGeography = data.selectedGeography;
            groupsMenuState.countryCode = data.countryCode;

            groupsMenuState.breadCrumbItems = breadCrumbItems;

            if (breadCrumbItems.length > 1) {
                groupsMenuState.selectedIndustry = find(breadCrumbItems, (item) => item.rotationMenuType === RotationMenuType.Industry)
                groupsMenuState.selectedIndustry.displayName = groupsMenuState.selectedIndustry.displayName ? yield call(transIndustryName, groupsMenuState.selectedIndustry) : groupsMenuState.selectedIndustry.displayName;
            }

            if (breadCrumbItems.length > 2) {
                selectedStock = find(breadCrumbItems, (item) => item.rotationMenuType === RotationMenuType.Stocks)
                selectedStock.displayName = selectedStock.displayName ? yield call(transStockName, selectedStock) : selectedStock.displayName;
            }

            
            switch (menuSettings.rotationMenuType) {
                case RotationMenuType.Sector:
                    yield call(addSectorListItems, groupsMenuState);
                    break;
                case RotationMenuType.Industry:
                    yield call(createIndustryListItem, groupsMenuState.selectedIndustry, groupsMenuState);
                    break;
                case RotationMenuType.Stocks:
                    yield call(createStockListItem, selectedStock, groupsMenuState);
                    break;
                default:
                    break;
            }

            yield put({
                type:ActionTypes.SAVE_SETTINGS
            })
}

function* setComparisonGroupsMenuData() {
    let navCompareState = yield select(getNavCompareState);
    const { listItems } = yield select(getGroupsMenu);
    let symbolIndex = 0;
    let symbolRequestArray = [];
    let newLineArray = [];
    if (navCompareState.selectedTab == CompareTabType.ComparisonGraph) {
        yield call(handleLoading, true);

        try {
            _.map(listItems, (item,key) => {
                let SymTypeEnum;
                if (item.itemType === RotationMenuType.Sector) {
                    SymTypeEnum = SymbolType.WONSECTOR11;
                } else if (item.itemType === RotationMenuType.Industry) {
                    SymTypeEnum = SymbolType.WONINDUSTRYGROUP197;
                }
                else if (item.countryCode === 1) {
                    SymTypeEnum = SymbolType.USSTOCK;
                } else {
                    SymTypeEnum = SymbolType.INTERNATIONALSTOCK;
                }
                const Osid = item.osid;
                const index = key;
                let newLine = new SymbolDataModel();
                newLine.label = null;
                newLine.index = index;
                newLine.isVisible = true;
                newLine.id = Osid.toString();
                newLine.Osid = Osid;
                newLine.name = null;
                newLine.localSymbol = null;
                newLine.actualSymbol = null;
                newLine.SymTypeEnum = getSymbolTypeEnumStringValue(SymTypeEnum);
                newLine.isFilter = true;
                newLine.reset();
                symbolRequestArray.push({ Osid: Osid, SymTypeEnum: newLine.SymTypeEnum, index: index, isVisible: true, isHighlighted: true })

                newLineArray.push(newLine);
                symbolIndex++;
            })

            yield put({
                type: ActionTypes.CLEAR_COMPARISON_SYMBOLS_DATA,
                data:[]
            });

            const symbolRequest = [...symbolRequestArray];

            yield put({
                type: ActionTypes.UPDATE_SYMBOL_REQ,
                symbolRequest
            })

            yield call(calculateDataPointsForSymbols, newLineArray,false,false);

            yield call(handleLoading, false);

        }

        catch (error) {
            yield put({
                type: ActionTypes.HANDLE_ERROR,
                hasError: true,
                errorMsg: error
            })
            console.log(`Error occurs in groupspanelsaga.js, setComparisonGroupsMenuData ${error}`);
        }
    }

}

function* getBreadCrumItem(label) {
    const stateobjName = yield select(getSelectedTabMenuStateObjName);
    const groupsMenuState = store.getState().comparisonAndRotationMenuPanel.GroupsPanelReducer[stateobjName];
    const groupsMenu = yield select(getGroupsMenu);
    const activeTabName = yield select(getActiveCompNavTabName);

    let selectedBreadCrum = null;
    if (groupsMenu.breadCrumbItems.length > 0)
        selectedBreadCrum = _.filter(groupsMenu.breadCrumbItems, (item) => item.displayName == label)[0];

    
        if(activeTabName === CompareTabType.RotationGraph){
            groupsMenuState.lastHighlightedOsids = [];
            yield call(handleLoading,true)
        }
           
    switch (selectedBreadCrum.rotationMenuType) {
        case RotationMenuType.Sector:
            yield call(addSectorListItems, groupsMenuState);
            break;
        case RotationMenuType.Industry:
            yield call(createIndustryListItem, selectedBreadCrum, groupsMenuState);
            break;
        case RotationMenuType.Stocks:
            yield call(createStockListItem, selectedBreadCrum, groupsMenuState);
            break;
        default:
            break;
    }

    if(activeTabName === CompareTabType.ComparisonGraph)
    yield call(setComparisonGroupsMenuData);

    yield put({
        type:ActionTypes.SAVE_SETTINGS
    })

}


function* handleBreadCrumClick({ label }) {
    const groupsMenu = yield select(getGroupsMenu);
    if (!StringUtil.isEmpty(groupsMenu.listItems))
        yield put(setScrollPosition(groupsMenu.listItems[0].osid, true));
    yield call(getBreadCrumItem, label)

    yield put({
        type: ActionTypes.SAVE_SETTINGS
    })
}


function* getSelectedItemDetails(label) {
    const groupsMenu = yield select(getGroupsMenu);
    if (groupsMenu.listItems.length > 0)
        return _.filter(groupsMenu.listItems, (item) => item.symbol == label)[0];

    return "";
}

function* groupsListItemClick({ label }) {
    const selectedItem = yield call(getSelectedItemDetails, label)
    yield call(addListItems, selectedItem);
}

function* addListItems(selectedItem) {
    const groupsMenuState = yield select(getGroupsMenu);
    const activeTabName = yield select(getActiveCompNavTabName);
    
    if(activeTabName === CompareTabType.RotationGraph){
        groupsMenuState.lastHighlightedOsids = [];
        yield call(handleLoading,true);
    }

    switch (groupsMenuState.rotationMenuType) {
        case RotationMenuType.Sector:
            yield call(createIndustryListItem, selectedItem, groupsMenuState);
            break;
        case RotationMenuType.Industry:
            yield call(createStockListItem, selectedItem, groupsMenuState);
            break;
        default:
            break;
    }

    if(activeTabName === CompareTabType.ComparisonGraph)
    yield call(setComparisonGroupsMenuData);

    yield put({
        type: ActionTypes.SAVE_SETTINGS
    })
}

function* setInitialScrollPosition() {
    const { listItems, lastHighlightedOsids } = yield select(getGroupsMenu);
    let scrollIndex = 0;
    if (!StringUtil.isEmpty(listItems))
        scrollIndex = (listItems.length === lastHighlightedOsids.length) ? 0 : lastHighlightedOsids.length - 1;

    yield put(setScrollPosition(lastHighlightedOsids[scrollIndex], true));
}

function* updateRotationGraphListItemsData(groupsMenuState, graphListType, graphListItems) {
    groupsMenuState.listItemType = graphListType;
    groupsMenuState.listItems = graphListItems;
}

function* setStocksBreadCrum(itemName, indCode, displayName) {
    const { selectedGeography, selectedIndustry } = yield select(getGroupsMenu);
    const breadCrumbObj = [];

    breadCrumbObj.push({
        itemName: selectedGeography.countryName,
        displayName: StringUtil.isEmpty(selectedGeography.displayName) ? selectedGeography.countryName : selectedGeography.displayName,
        rotationMenuType: RotationMenuType.Sector,
        countryCode: selectedGeography.countryCode,
        osid: null,
        indCode: null
    });

    breadCrumbObj.push({
        itemName: selectedIndustry.itemName,
        displayName: StringUtil.isEmpty(selectedIndustry.displayName) ? selectedIndustry.itemName : selectedIndustry.displayName,
        rotationMenuType: RotationMenuType.Industry,
        countryCode: selectedGeography.countryCode,
        osid: selectedIndustry.osid,
        indCode: null
    });

    breadCrumbObj.push({
        itemName: itemName,
        displayName: StringUtil.isEmpty(displayName) ? itemName : displayName,
        rotationMenuType: RotationMenuType.Stocks,
        countryCode: selectedGeography.countryCode,
        osid: null,
        indCode: parseInt(indCode)
    });

    return breadCrumbObj;
}

function* setIndustryBreadCrum(itemName, osid, displayName) {
    const { selectedGeography } = yield select(getGroupsMenu);
    const breadCrumbObj = [];

    breadCrumbObj.push({
        itemName: selectedGeography.countryName,
        displayName: StringUtil.isEmpty(selectedGeography.displayName) ? selectedGeography.countryName : selectedGeography.displayName,
        rotationMenuType: RotationMenuType.Sector,
        countryCode: selectedGeography.countryCode,
        osid: null,
        indCode: null
    });

    breadCrumbObj.push({
        itemName: itemName,
        displayName: StringUtil.isEmpty(displayName) ? itemName : displayName,
        rotationMenuType: RotationMenuType.Industry,
        countryCode: selectedGeography.countryCode,
        osid: osid,
        indCode: null
    });

    return breadCrumbObj;
}

function* setSectorBreadCrum() {
    const { selectedGeography } = yield select(getGroupsMenu);
    const breadCrumbObj = [];

    breadCrumbObj.push({
        itemName: selectedGeography.countryName,
        displayName: StringUtil.isEmpty(selectedGeography.displayName) ? selectedGeography.countryName : selectedGeography.displayName,
        rotationMenuType: RotationMenuType.Sector,
        countryCode: selectedGeography.countryCode,
        osid: null,
        indCode: null
    });

    return breadCrumbObj;
}

function* addLastHighlightedOsids(listItems) {
    const { lastHighlightedOsids } = yield select(getGroupsMenu);
    if (!StringUtil.isEmpty(listItems) && StringUtil.isEmpty(lastHighlightedOsids))
        map(listItems, (item) => {
            if (lastHighlightedOsids.indexOf(parseInt(item.osid)) === -1)
                lastHighlightedOsids.push(parseInt(item.osid));
        });
    return lastHighlightedOsids;
}

function setDisplayName(type, code, countryCode, Description) {
    const countryMark = LocalizationStore.getTranslatedData("Country_Abbr_" + countryCode, "");
    const description = LocalizationStore.getTranslatedData(type + code.substring(0, 5), Description);
    return StringUtil.stringFormat((countryCode === -1 || countryCode === 1 || countryMark === "") ? "{0}" : "{0} ({1})", description, countryMark);
}

function* addStocks(stockslist, grpsInstrumentRequestData, groupsMenuState, isInit) {
    const { selectedTab } = yield select(getNavCompareState);
    const { lastHighlightedOsids, isNotVisibleOsids } = yield select(getGroupsMenu);
    const { itemName, indCode, displayName } = grpsInstrumentRequestData;
    let selectedOsid = '';
    const listItems = [];

    if (!StringUtil.isEmpty(stockslist["GrpInstrumentList"])) {
        for (let i = 0; i < stockslist["GrpInstrumentList"].length; i++) {
            const item = stockslist["GrpInstrumentList"][i];
            listItems.push({
                itemName: item.Name,
                displayName: item.Name,
                osid: item.InstrumentId.low,
                msid: item.Msid.low,
                countryCode: item.CountryCode.low,
                itemType: RotationMenuType.Stocks,
                avol: item.Avol,
                mktCp: item.MktCp,
                symbol: item.Symbol,
                localSymbol: item.LocalSymbol,
                underlyingSymbol: item.Symbol,
                isFlagged: item.IsFlagged,
                instrumentId: item.InstrumentId.low,
                isHighlighted: isInit && lastHighlightedOsids.length > 0 ? contains(lastHighlightedOsids, item.InstrumentId.low) : true,
                isVisible: isInit && isNotVisibleOsids.length > 0 ? !contains(isNotVisibleOsids, item.InstrumentId.low) : true,
                isSelected: isInit && lastHighlightedOsids.length > 0 ? contains(lastHighlightedOsids, item.InstrumentId.low) : true,
                ratingData: find(stockslist["GrpInstrumentRatingList"], (r) => r.Osid.low == item.InstrumentId.low),
                isLabelHighlighted: isInit && lastHighlightedOsids.length > 0 ? contains(lastHighlightedOsids, item.InstrumentId.low) : false
            });
            selectedOsid = selectedOsid.concat(`${item.InstrumentId.low};`);
        }
    }
    else {
        yield call(handleLoading);
    }

    groupsMenuState.selectedOsid = selectedOsid;
    if (lastHighlightedOsids.length === listItems.length)
        each(listItems, (entry) => { entry.isLabelHighlighted = false; });

    groupsMenuState.lastHighlightedOsids = yield call(addLastHighlightedOsids, listItems);
    //Sort on itemName
    groupsMenuState.stockList = each(sortBy(listItems, 'itemName'), (item, i) => {
        item.itemColor = CompRotationHelper.prototype.getLineColor(i);
    });

    groupsMenuState.breadCrumbItems = yield call(setStocksBreadCrum, itemName, indCode, displayName);
    yield fork(updateRotationGraphListItemsData, groupsMenuState, RotationMenuType.Stocks, groupsMenuState.stockList);

    yield put({
        type: ActionTypes.SET_ROTATION_MENU_TYPE,
        rotationMenuType: RotationMenuType.Stocks
    })

    yield call(setRotationGraphFilter);


    if (selectedTab === CompareTabType.RotationGraph)
        yield put(getQuadData(GroupType.STOCKS));
}

function* addIndustryListItems(itemName, osid, displayName, groupsMenuState, isInit) {
    const { selectedTab, groupsMainData } = yield select(getNavCompareState);
    const { lastHighlightedOsids, isNotVisibleOsids } = yield select(getGroupsMenu);
    const listItems = [];
    let selectedOsid = '';

    if (!StringUtil.isEmpty(groupsMainData)) {
        const industryListData = groupsMainData["IndustryList"];
        const majorIndustryList = groupsMainData["MajorIndustryList"];

        if (!StringUtil.isEmpty(majorIndustryList)) {
            for (let i = 0; i < majorIndustryList.length; i++) {
                const item = majorIndustryList[i];
                if (item.SectorOsid.low === osid && !StringUtil.isEmpty(industryListData)) {
                    const selectedIndList = filter(industryListData, (entry) => entry.MajorIndustryOsid.low === item.Osid.low);

                    for (let j = 0; j < selectedIndList.length; j++) {
                        const indItem = selectedIndList[j];
                        if (!StringUtil.isEmpty(indItem)) {
                            listItems.push({
                                itemName: indItem.WonIndustryDesc,
                                displayName: yield call(setDisplayName, "Index_Industry_", indItem.WonIndustryCode, indItem.CountryCode.low.toString(), indItem.WonIndustryDesc),
                                osid: indItem.WonIndsutryOsid.low,
                                symbol: indItem.WonIndustryCode.length >= 5 ? indItem.WonIndustryCode.substring(0, 5) : indItem.WonIndustryCode,
                                localSymbol: null,
                                underlyingSymbol: indItem.WonIndustryCode,
                                countryCode: indItem.CountryCode.low,
                                itemType: RotationMenuType.Industry,
                                wonIndsutryOsid: indItem.WonIndsutryOsid.low,
                                indCode: indItem.WonIndustryCode.substring(1, 5),
                                itemColor: null,
                                isHighlighted: isInit && !StringUtil.isEmpty(lastHighlightedOsids) ? contains(lastHighlightedOsids, indItem.WonIndsutryOsid.low) : true,
                                isVisible: isInit && !StringUtil.isEmpty(isNotVisibleOsids) ? !contains(isNotVisibleOsids, indItem.WonIndsutryOsid.low) : true,
                                isSelected: isInit && !StringUtil.isEmpty(lastHighlightedOsids) ? contains(lastHighlightedOsids, indItem.WonIndsutryOsid.low) : true,
                                isLabelHighlighted: isInit && !StringUtil.isEmpty(lastHighlightedOsids) ? contains(lastHighlightedOsids, indItem.WonIndsutryOsid.low) : false
                            });
                            selectedOsid = selectedOsid.concat(`${indItem.WonIndsutryOsid.low};`);
                        }
                    }
                }
            }
        }
        else {
            yield call(handleLoading);
        }
    }

    groupsMenuState.selectedOsid = selectedOsid;

    if (lastHighlightedOsids.length === listItems.length)
        each(listItems, (entry) => entry.isLabelHighlighted = false);

    groupsMenuState.lastHighlightedOsids = yield call(addLastHighlightedOsids, listItems);

    //Sort on itemName
    const industryList = each(sortBy(listItems, 'itemName'), (item, i) => {
        item.itemColor = CompRotationHelper.prototype.getLineColor(i);
    });

    groupsMenuState.breadCrumbItems = yield call(setIndustryBreadCrum, itemName, osid, displayName);

    yield fork(updateRotationGraphListItemsData, groupsMenuState, RotationMenuType.Industry, industryList);

    yield put({
        type: ActionTypes.SET_ROTATION_MENU_TYPE,
        rotationMenuType: RotationMenuType.Industry
    })

    if (selectedTab === CompareTabType.RotationGraph)
        yield put(getQuadData(GroupType.INDUSTRY));
}

function* addSectorListItems(groupsMenuState, isInit) {
    try {
        const { selectedTab, groupsMainData } = yield select(getNavCompareState);
        const { lastHighlightedOsids, selectedGeography, isNotVisibleOsids } = yield select(getGroupsMenu);
        const listItems = [];
        let groupsSectorData = null, selectedOsid = '';

        if (!StringUtil.isEmpty(groupsMainData)) {
            groupsSectorData = groupsMainData["SectorDataList"];

            //  let code = self.selectedGeography.countryCode == -1 ? self.selectedGeography.regionKey : self.selectedGeography.countryCode;
            for (let i = 0; i < groupsSectorData.length; i++) {
                const item = groupsSectorData[i];
                if (!StringUtil.isEmpty(item)) {
                    const code = selectedGeography.countryCode === -1 ? selectedGeography.regionKey : selectedGeography.countryCode;
                    if (item.CountryCode.low === code) {
                        const displayName = yield call(setDisplayName, "Sector_", item.Code, item.CountryCode.low.toString(), item.Description);

                        const helper = CompRotationHelper.prototype;
                        let symbol, itemColor, sectorOrder;
                        if (item.Code.length >= 5) {
                            symbol = helper.getSectorLabel(item.Code.substring(0, 5));
                            itemColor = helper.getSectorColor(item.Code.substring(0, 5));
                            sectorOrder = helper.getSectorOrder(item.Code.substring(0, 5));
                        }
                        else {
                            symbol = helper.getSectorLabel(item.Code);
                            itemColor = helper.getSectorColor(item.Code);
                            sectorOrder = helper.getSectorOrder(item.Code);
                        }

                        listItems.push({
                            itemName: item.Description,
                            displayName: displayName,
                            osid: item.Osid.low,
                            countryCode: item.CountryCode.low,
                            itemType: RotationMenuType.Sector,
                            symbol: symbol,
                            localSymbol: null,
                            underlyingSymbol: item.Code,
                            isHighlighted: isInit && !StringUtil.isEmpty(lastHighlightedOsids) ? contains(lastHighlightedOsids, item.Osid.low) : true,
                            itemColor: itemColor,
                            isVisible: isInit && !StringUtil.isEmpty(isNotVisibleOsids) ? !(contains(isNotVisibleOsids, item.Osid.low)) : true,
                            isSelected: isInit && !StringUtil.isEmpty(lastHighlightedOsids) ? contains(lastHighlightedOsids, item.Osid.low) : true,
                            sectorOrder: sectorOrder,
                            isLabelHighlighted: isInit && !StringUtil.isEmpty(lastHighlightedOsids) ? contains(lastHighlightedOsids, item.Osid.low) : false
                        });
                        selectedOsid = selectedOsid.concat(`${item.Osid.low};`);
                    }
                }
            }
        }
        else {
            yield call(handleLoading);
        }

        groupsMenuState.selectedOsid = selectedOsid;

        if (lastHighlightedOsids.length === listItems.length)
            each(listItems, (entry) => entry.isLabelHighlighted = false);

        groupsMenuState.lastHighlightedOsids = yield call(addLastHighlightedOsids, listItems);

        groupsMenuState.breadCrumbItems = yield call(setSectorBreadCrum);

        //Sort on sector code
        const sectorList = sortBy(listItems, 'sectorOrder');
        yield fork(updateRotationGraphListItemsData, groupsMenuState, RotationMenuType.Sector, sectorList);

        yield put({
            type: ActionTypes.SET_ROTATION_MENU_TYPE,
            rotationMenuType: RotationMenuType.Sector
        })

        if (selectedTab === CompareTabType.RotationGraph)
            yield put(getQuadData(GroupType.SECTOR));
    }
    catch (error) {
        console.log(`Error occurs in GroupsPanelSaga.js, addSectorListItems ${error}`);
    }
}

function transStockName(selectedStock) {
    const formatName = selectedStock.itemName.split(" ");
    const countryMark = LocalizationStore.getTranslatedData("Country_Abbr_" + selectedStock.countryCode, "");
    const name = LocalizationStore.getTranslatedData("FilterBrowser_ONEILINDUSTRYGROU_" + selectedStock.indCode, formatName);
    return StringUtil.stringFormat((selectedStock.countryCode === -1 || selectedStock.countryCode === 1 || countryMark === "") ? "{0}" : "{0} {1}", name, countryMark).split(",").join(" ");
}

function transIndustryName(item) {
    const arr = item.displayName.split(" ");
    if (arr.length > 1) {
        arr.pop();
    }
    const name = arr.join(" ");
    const countryName = LocalizationStore.getTranslatedData("Country_Abbr_" + item.countryCode, "");
    const displayName = LocalizationStore.getTranslatedData("LM_SectorName_" + name.replace(/ /ig, "").toUpperCase(), name);
    return StringUtil.stringFormat((item.countryCode === -1 || item.countryCode === 1 || countryName === "") ? "{0}" : "{0} ({1})", displayName, countryName);
}

export function transGeographyName(item) {
    let breadcrumbname = null;
    let strRegionName = null;
    const regionName = StringUtil.isEmpty(item.regionName) ? item.regionName : item.regionName.replace(" ", "");
    if (item.regionCode === "GLOBAL") {
        breadcrumbname = LocalizationStore.getTranslatedData("RotGraph_" + regionName, regionName);
    }
    else if (item.regionCode === "AMER" || item.regionCode == "ASIA" || item.regionCode == "EMEA") {
        breadcrumbname = LocalizationStore.getTranslatedData("RotGraph_" + regionName, regionName);
    }
    else {
        breadcrumbname = item.countryName;
    }
    if (UserInfoUtil.getCurrentLang() !== LangType.RAY_DEFAULT) {
        switch (item.regionKey) {
            case 1000:
                strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_Global", "World");
                break;
            case 1001:
                strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_Americas", "Americas");
                break;
            case 1002:
                strRegionName = LocalizationStore.getTranslatedData("RotGraph_Europe,MiddleEast,Africa", "Europe, Middle East, Africa");
                break;
            case 1003:
                strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_AsiaPacific", "Asia Pacific");
                break;
            default:
                strRegionName = LocalizationStore.getTranslatedData("Country_" + item.countryCode, breadcrumbname);
                break;
        }
    } else {
        strRegionName = item.countryName;
    }
    return strRegionName
}

export function* clearGraphData(groupsMenuState) {
    const { selectedTab } = yield select(getNavCompareState);

    let viewSetting;

    if (selectedTab === CompareTabType.ComparisonGraph) {
        viewSetting = SettingsStore.getConsoleSettings().NavCompareSettings.TabComparisonGraphSettings.comparisonGroupsMenuSettings;
    }else{
        viewSetting = SettingsStore.getConsoleSettings().NavCompareSettings.TabRotationGraphSettings.rotationGroupsMenuSettings;
    }

    yield put(resetZoomValue());
    groupsMenuState.quadGraphResults = {};
    groupsMenuState.stocksTotalList = [];
    groupsMenuState.stockList = [];
    groupsMenuState.groupResult = {};
    groupsMenuState.isNotVisibleOsids = [];
    groupsMenuState.lastHighlightedOsids = [];
    groupsMenuState.quadGraphYearList = [];


    CompareSettingHelper.updateNotVisibleOsids(viewSetting, null);

    CompareSettingHelper.updateLastHighlightedOsids(viewSetting, null);
    SettingsStore.saveSettings();
}

function* setGeographyByCountryCodeAndCountryName({ countryCode }) {

    const navCompState = yield select(getNavCompareState);
    const stateobjName = yield select(getSelectedTabMenuStateObjName);
    const groupsMenuState = store.getState().comparisonAndRotationMenuPanel.GroupsPanelReducer[stateobjName];
    const activeTabName = yield select(getActiveCompNavTabName)
    try {
        const setData = yield call(setGeographyByCountryCode, countryCode, navCompState.selectedTab, groupsMenuState)
        groupsMenuState.selectedGeography = setData.selectedGeography;
        groupsMenuState.countryCode = setData.countryCode;

        if(activeTabName === CompareTabType.RotationGraph){
            groupsMenuState.lastHighlightedOsids = [];
            yield call(handleLoading,true);
        }

        yield call(addSectorListItems, groupsMenuState);

        yield put({
            type: ActionTypes.SET_OPEN_GEOGRAPHY_STATUS,
            status: false
        });

        if(activeTabName === CompareTabType.ComparisonGraph)
        yield call(setComparisonGroupsMenuData);

        yield put({
            type:ActionTypes.SAVE_SETTINGS
        })

    } catch (error) {
        console.log(error);
    }
}

function* setGeographyByCountryCode(countryCode, selectedTab, groupsMenuState) {
    const groupsMenu = yield select(getGroupsMenu);
    const { geographyDataList } = yield select(getGroupsPanel);
    let stateCountryCode = groupsMenu.countryCode;

    if (!StringUtil.isEmpty(geographyDataList) && !StringUtil.isEmpty(countryCode)) {
        if (!StringUtil.isEmpty(stateCountryCode) && stateCountryCode !== countryCode) {
            yield call(clearGraphData, groupsMenuState);
        }
        const selectedGeography = find(geographyDataList, (item) => item.countryCode === parseInt(countryCode));
        selectedGeography.displayName = yield call(transGeographyName, selectedGeography);
        if (selectedTab === CompareTabType.RotationGraph) {
            yield call(LoadBenchMarkData, countryCode);
        }

        return { selectedGeography, countryCode };
    }
}

export function* initRotationGroupsMenu() {
    try {
        const stateobjName = yield select(getSelectedTabMenuStateObjName);
        const { selectedTab } = yield select(getNavCompareState);
        const groupsMenuState = store.getState().comparisonAndRotationMenuPanel.GroupsPanelReducer[stateobjName];
        const { groupsTimelineDateDictionary } = yield select(getBenchMarkState);

        /* On restore default -> route from DG -> view rotation graph */
        if(StringUtil.isEmpty(groupsTimelineDateDictionary)) {
            yield put(initializeTimelineValues());
            yield put(updateFromUserSettingsData());
        }

        let settings = SettingsStore.getConsoleSettings().NavCompareSettings;
        let menuSettings = null, selectedStock = null;

        settings = settings.TabRotationGraphSettings;
        menuSettings = settings.rotationGroupsMenuSettings;

        if (!StringUtil.isEmpty(settings) && !StringUtil.isEmpty(menuSettings)) {
            const { breadCrumbItems, notVisibleOsids, lastHighlightedOsids } = menuSettings;

            groupsMenuState.isGeographySelected = true;

            const data = yield call(setGeographyByCountryCode, menuSettings.countryCode, selectedTab, groupsMenuState);
            groupsMenuState.selectedGeography = data.selectedGeography;
            groupsMenuState.countryCode = data.countryCode;

            groupsMenuState.breadCrumbItems = breadCrumbItems;
            

            if (!StringUtil.isEmpty(notVisibleOsids)) {
                groupsMenuState.isNotVisibleOsids = [...notVisibleOsids];
            }
            if (!StringUtil.isEmpty(lastHighlightedOsids))
                groupsMenuState.lastHighlightedOsids = [...lastHighlightedOsids];

            if (breadCrumbItems.length > 1) {
                groupsMenuState.selectedIndustry = find(breadCrumbItems, (item) => item.rotationMenuType === RotationMenuType.Industry)
                groupsMenuState.selectedIndustry.displayName = groupsMenuState.selectedIndustry.displayName ? yield call(transIndustryName, groupsMenuState.selectedIndustry) : groupsMenuState.selectedIndustry.displayName;
            }

            if (breadCrumbItems.length > 2) {
                selectedStock = find(breadCrumbItems, (item) => item.rotationMenuType === RotationMenuType.Stocks)
                selectedStock.displayName = selectedStock.displayName ? yield call(transStockName, selectedStock) : selectedStock.displayName;
            }

            yield put({
                type: ActionTypes.INIT_LIQ_VIEW_MODEL,
            })

            switch (menuSettings.rotationMenuType) {
                case RotationMenuType.Sector:
                    yield call(addSectorListItems, groupsMenuState,true);
                    break;
                case RotationMenuType.Industry:
                    yield call(createIndustryListItem, groupsMenuState.selectedIndustry, groupsMenuState,true);
                    break;
                case RotationMenuType.Stocks:
                    yield call(createStockListItem, selectedStock, groupsMenuState,true);
                    break;
                default:
                    break;
            }

            yield call(setInitialScrollPosition);

         

            yield put({
                type: ActionTypes.SAVE_SETTINGS
            })
        }
    } catch (error) {
        console.log(`Error occurs in GroupsPanelSaga.js, loadMenu ${error}`);
    }
}

function addGeographyItem(item, isHeader, headerName = null) {
    try {
        let countryCode = null;
        if (!StringUtil.isEmpty(item)) {

            if (item.RegionCode === "GLOBAL") {
                countryCode = item.RegionKey.low;
            }
            else if (item.RegionCode === "AMER" || item.RegionCode === "ASIA" || item.RegionCode === "EMEA") {
                countryCode = item.RegionKey.low;
            }
            else {
                countryCode = item.CountryCode.low;
            }
            geographyData.push({
                regionCode: item.RegionCode,
                regionName: item.regionName,
                regionKey: item.RegionKey.low,
                countryCode: countryCode,
                wonRegionId: item.WonRegionID.low,
                primaryIndexOsid: item.PrimaryIndexOsid.low,
                countryName: StringUtil.isEmpty(item.CountryName) ? item.RegionName : item.CountryName,
                isHeader: isHeader
            });
        }
        else {
            geographyData.push({
                regionCode: null,
                regionName: null,
                regionKey: null,
                countryCode: null,
                wonRegionId: null,
                primaryIndexOsid: null,
                countryName: headerName,
                isHeader: isHeader
            });
        }
    } catch (error) {
        console.log(`Error occurs in GroupsPanelSaga.js, addGeographyItem ${error}`);
    }
}

export function* prepareGeoGraphyData(result) {
    geographyData = [];
    try {
        //Add GLOBAL
        yield call(addGeographyItem, null, true, 'Global');
        yield all(result.GroupsData.map((item) => call(addGeographyItem, { ...item }, false)));

        //Add REGIONS
        yield call(addGeographyItem, null, true, 'Regions');
        let regionData = sortBy(result.RegionData, 'RegionKey');
        yield all(regionData.map((item) => call(addGeographyItem, { ...item }, false)));

        //Add COUNTRIES
        yield call(addGeographyItem, null, true, 'Countries');
        let countryData = sortBy(result.CountryData, 'CountryName');
        yield all(countryData.map((item) => call(addGeographyItem, { ...item }, false)));

        yield put({
            type: ActionTypes.UPDATE_GEOGRAPHY_DATA,
            geographyDataList: geographyData
        });

        return geographyData;
    }
    catch (error) {
        console.log(`Error occurs in GroupsPanelSaga.js, prepareGeoGraphyData ${error}`);
    }
}

function* createStockListItem(item, groupsMenuState,isInit) {
    if (!StringUtil.isEmpty(item)) {
        const grpsInstrumentRequestData = { indCode: item.indCode, countryCode: item.countryCode, itemName: item.itemName, displayName: item.displayName };

        const result = yield CompareApi.getGrpsInstrumentData(grpsInstrumentRequestData);
        if (!result.responseHeader.error && !StringUtil.isEmpty(result)) {
            yield call(addStocks, result, grpsInstrumentRequestData, groupsMenuState,isInit);
        }
    }
}

function* createIndustryListItem(item, groupsMenuState,isInit=false) {
    groupsMenuState.selectedIndustry = item;
    yield call(addIndustryListItems, item.itemName, item.osid, item.displayName, groupsMenuState,isInit);
}

function* populateListItem({ item, listItemType }) {
    const stateobjName = yield select(getSelectedTabMenuStateObjName);
    const {selectedTab} = yield select(getNavCompareState);
    const groupsMenuState = store.getState().comparisonAndRotationMenuPanel.GroupsPanelReducer[stateobjName];

    yield call(clearGraphData, groupsMenuState);

    if (listItemType === RotationMenuType.Sector)
        yield call(createIndustryListItem, item, groupsMenuState);
    else if (listItemType === RotationMenuType.Industry)
        yield call(createStockListItem, item, groupsMenuState);

        if(selectedTab === CompareTabType.ComparisonGraph)
        yield call(setComparisonGroupsMenuData);

    yield put({
        type:ActionTypes.SAVE_SETTINGS
    })

}

export function* watchInitCompGroupsMenu() {
    yield takeLatest(ActionTypes.INIT_COMP_GROUPS_MENU, initCompGroupsMenu);
}

export function* watchPrepareGeographyData() {
    yield takeLatest(ActionTypes.PREPARE_GEOGRAPHY_DATA, prepareGeoGraphyData);
}

export function* watchInitRotationGroupsMenu() {
    yield takeLatest(ActionTypes.INIT_ROTATION_GROUPS_MENU, initRotationGroupsMenu);
}

export function* watchPopulateListItem() {
    yield takeLatest(ActionTypes.POPULATE_LIST_ITEM, populateListItem);
}

export function* watchHandleBreadCrumClick() {
    yield takeLatest(ActionTypes.HANDLE_BREADCRUM_CLICK, handleBreadCrumClick)
}

export function* watchGroupsListItemClick() {
    yield takeLatest(ActionTypes.GROUPS_LIST_ITEM_CLICK, groupsListItemClick)
}

export function* watchSetGeographyByCountryCodeAndCountryName() {
    yield takeLatest(ActionTypes.SET_GEOGRAPHY_BY_COUNTRY_CODE_AND_COUNTRY_NAME, setGeographyByCountryCodeAndCountryName)
}