import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class SaveNotificationSettingsRequest extends BaseServiceRequest {
  constructor(data) {
    super();
    super.addRequestUri("setting.pb");
    const ReqProto = BaseServiceApi.rayData["NotificationSettingsData"];
    const settingData = new ReqProto();

    settingData.ownerId = data.ownerId
    settingData.email = data.email;
    settingData.emailValid = data.emailValid;
    settingData.phoneNumber = data.phoneNumber;
    settingData.phoneNumberValid = data.phoneNumberValid;
    settingData.notifyBySMS = data.notifyBySMS;
    settingData.notifyByEmail = data.notifyByEmail;
    settingData.notifyInApp = data.notifyInApp;
    settingData.serviceProviderType = data.serviceProviderType;
    settingData.dailyNotificationLimit = data.dailyNotificationLimit == '' || isNaN(data.dailyNotificationLimit) ? 0 : data.dailyNotificationLimit;
    settingData.notifyScheduledScreensByEmail = data.notifyScheduledScreensByEmail;
    const reqcontent = settingData.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.NOTIFICATION;
  }

  getMessageName() {
    return "Response";
  }

}
