import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

export default class UpdateOrdinalNumberRequest extends BaseServiceRequest {
    constructor(listId, isReset) {
        super();
        super.addRequestUri("updateordinal.pb");
        super.addPathParameter(listId.toString());
        super.addRequestParameter("ro", isReset ? "1" : "0");
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}