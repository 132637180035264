import keyMirror from "keymirror"

export const RIPanelOneilConstants = { 
    ActionTypes: keyMirror({
      UPDATE_RI_PANEL_SETTINGS_DATA:null,
      UPDATE_RI_LEADER_FLAG_STATUS:null,
      UPDATE_RI_PANEL_SETTINGS: null,
      UPDATE_PRICE_PANEL_LEADER_FLAG_STATUS: null,
      UPDATE_SHOW_HISTORICAL_FLAG: null,
      UPDATE_PRICE_PANEL_SHOW_HISTORICAL_FLAG: null,
      GET_RI_PANEL_ONEIL_INFO: null,
      GET_FOCUS_LIST_INFO: null,
      GET_HISTORICAL_LIST:null,
      GET_CURRENT_ENTITLEMENT_INFO: null,
      GET_RESEARCH_ONEIL_INFO: null,
      GET_RESEARCH_ANALYSTLIST_INFO: null,
      GET_IDEALIST_DATA: null,
      CLEAR_RI_PANEL_ONEIL_INFO: null,
      SHOW_MAIN_FLAG: null,
      ON_CLEAR_RI_PANEL_ONEIL_DATA: null,
      UPDATE_CONTACT_TYPE: null,
      UPDATE_ENTITLEMENT_DIALOG_FLAG: null,
      HANDLE_ENTITLEMENT: null,
      UPDATE_ENTITLEMENT_USER_NAME: null,
      UPDATE_ENTITLEMENT_USER_EMAIL: null,
      UPDATE_ENTITLEMENT_USER_PHONE: null,
      RECIEVE_QUOTE: null,
      UPDATE_RI_LEADER_FLAG_STATUS_SAVE: null,
      UPDATE_RI_HISTORIC_FLAG_STATUS: null,
      GET_ONEIL_FOCUS_LIST_DATA:null
    })
  }