import Serializable from "Serializable";
class HiLoPctSettings extends Serializable {

    constructor() {
        super();
        this.IsAvailable = true;
        this.IsVisible = false;
    }

    onSeralisedInfoRecieved(info) {
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : false;
        this.IsAvailable = info.IsAvailable !== undefined ? info.IsAvailable : true;
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            IsAvailable: this.IsAvailable
        };
    }

    getObjectMapKey() {
        return "HiLoPctSettings";
    }
}

export default HiLoPctSettings;
