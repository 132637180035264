import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from 'BaseServiceApi';

class GetMsidFlagRequest extends BaseServiceRequest {

  constructor(listId, msIds) {
    super();
    super.addRequestUri("msidflags.pb");
    const ReqProto = BaseServiceApi.rayData["ListInstrumentsRequestData"];
    let listInstrumentsRequestData = new ReqProto();
    const InfoProto = BaseServiceApi.rayData["ListInstrumentInfoData"];
    let listInstrumentInfoData = new InfoProto();
    listInstrumentInfoData.symbols = "";
    listInstrumentInfoData.msIds = msIds;
    listInstrumentInfoData.snapshotDts = "";

    listInstrumentsRequestData.instrumentsInfo = listInstrumentInfoData;
    const bulkInsertData = listInstrumentsRequestData.encode();

    super.setContent(bulkInsertData);
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ownershipFlagResponse";
  }
}

export default GetMsidFlagRequest;
