import BaseServiceApi from 'BaseServiceApi';
import DatagraphDataType from '../../../../Constants/DatagraphDataType';
import datagraphStore from '../../../../Stores/NavModules/NavDataGraph/DataGraphStore';
import { each } from "underscore";
import graphApi from '../../../../ServiceApi/Apis/GraphApi';
import { IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import MiniListHelper from '../../../../Utils/MiniListHelper';
import { priceChartReducerselect } from '../../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import SettingsStore from "SettingsStore";
import SymbolType from "SymbolType";
import TimeTrackingWindow from '../../../../RayCustomControls/TimeTrackingWindow';
import { call, put, select, takeLatest } from "redux-saga/effects";

const ONeilRatingType = BaseServiceApi.rayData["ONeilRatingType"];
const { ActionTypes } = IndicatorsConstants;
function* getAllIndicatorsData({isSymbolChange = false, symbol, graphType, endDate, isMiniListPlay}) {
    try{
        TimeTrackingWindow.beginTechIndicatorTimeTracker();
        const findGetTechIndRequest = {
            symbol: symbol,
            graphType: graphType,
            endDate: endDate
        };
    
        const navDatagraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings;
        const tabDataGraphSettings = navDatagraphSettings.TabDataGraphSettings;
        const periodicity = tabDataGraphSettings.Periodicity;
    
        const sratingsArray = [], cratingsArray = [], eratingsArray = [], iratingsArray = [], cytdArray = [], eytdArray = [], fytdArray = [], wytdArray = [], iytdArray = [];
    
        if (isSymbolChange) {
            getStockIndicators(sratingsArray, tabDataGraphSettings.StockViewSettings, periodicity);
            getCefIndicators(cratingsArray, cytdArray, tabDataGraphSettings.CEFViewSettings, periodicity);
            getEtfIndicators(eratingsArray, eytdArray, tabDataGraphSettings.ETFViewSettings, periodicity);
            getIndexIndicators(iytdArray, tabDataGraphSettings.IndexViewSettings, periodicity);
            getFundIndicators(fytdArray, tabDataGraphSettings.FUNDViewSettings, periodicity);
            getIgIndicators(wytdArray, tabDataGraphSettings.IndustryGroupViewSettings, periodicity);
        }
        else {
            switch (navDatagraphSettings.symbolTypeEnum) {
                case SymbolType.USSTOCK:
                    getStockIndicators(sratingsArray, tabDataGraphSettings.StockViewSettings, periodicity);
                    break;
    
                case SymbolType.FundClosedEnd:
                    getCefIndicators(cratingsArray, cytdArray, tabDataGraphSettings.CEFViewSettings, periodicity);
                    break;
    
                case SymbolType.ETF:
                    getEtfIndicators(eratingsArray, eytdArray, tabDataGraphSettings.ETFViewSettings, periodicity);
                    break;
    
                case SymbolType.FUND:
                    getFundIndicators(fytdArray, tabDataGraphSettings.FUNDViewSettings, periodicity);
                    break;
    
                case SymbolType.INDEXNOINTRADAYVOLDATA:
                case SymbolType.ECONOMICINDICATOR:
                case SymbolType.INDEXNOINTRADAYDATA:
                case SymbolType.INDEXNOINTRADAYVOL:
                case SymbolType.INTERNATIONALINDEX:
                case SymbolType.HISTORICINDEX:
                case SymbolType.INDEX:
                    getIndexIndicators(iytdArray, tabDataGraphSettings.IndexViewSettings, periodicity);
                    break;
    
                case SymbolType.WONINDUSTRYGROUP197:
                case SymbolType.WONMAJORINDUSTRY89:
                case SymbolType.WONSECTOR11:
                case SymbolType.WONSUBSECTOR33:
                    getIgIndicators(wytdArray, tabDataGraphSettings.IndustryGroupViewSettings, periodicity);
                    break;
    
                default: break;
            }
        }
    
        findGetTechIndRequest.sratings = sratingsArray.join(":");
        findGetTechIndRequest.cratings = cratingsArray.join(":");
        findGetTechIndRequest.eratings = eratingsArray.join(":");
        findGetTechIndRequest.cytd = cytdArray.join(":");
        findGetTechIndRequest.eytd = eytdArray.join(":");
        findGetTechIndRequest.fytd = fytdArray.join(":");
        findGetTechIndRequest.wytd = wytdArray.join(":");
        findGetTechIndRequest.iytd = iytdArray.join(":");
        findGetTechIndRequest.selectCurrencyCode = datagraphStore.selectCurrencyCode();
        yield put({
            type: ActionTypes.UPDATE_IS_ALL_INDICATOR_API_INITIATED,
            isAllIndicatorDataApiInitiated: true
        })
        const indicatorResponse = yield call(graphApi.getTechnicalIndicatorData, findGetTechIndRequest);
        if (!indicatorResponse.responseHeader.error) {
            yield put({
                type: ActionTypes.UPDATE_INDICATOR_RAW_DATA,
                indicatorResponse
            })
            TimeTrackingWindow.endTechIndicatorApiTimeTracker();
            const { isPriceDataReady } = yield select(priceChartReducerselect);
            if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay) && isPriceDataReady) {
              yield put({
                type: ActionTypes.INIT_INDICATOR,
            })
            }
          } else {
            console.error(`Error occured in IndicatorSaga.js, getAllIndicatorsData ${indicatorResponse.responseHeader.error}`);
            yield put({
                type: ActionTypes.UPDATE_IS_ALL_INDICATOR_API_INITIATED,
                isAllIndicatorDataApiInitiated: false
            })
            TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.TIPanel);
            TimeTrackingWindow.endTechIndicatorApiTimeTracker();
          }
        
    }
    catch(error){
        yield put({
            type: ActionTypes.UPDATE_IS_ALL_INDICATOR_API_INITIATED,
            isAllIndicatorDataApiInitiated: false
        })
        console.log("Error occured in IndicatorSaga.js, getAllIndicatorsData", error)
    }
    
}

function getStockIndicators(sratingsArray, settings, periodicity) {
    getEPSR(sratingsArray, settings, periodicity);
    getPTOE(sratingsArray, settings, periodicity);
    getPTOS(sratingsArray, settings, periodicity);
    getOneilRatings(sratingsArray, settings, periodicity);
}

function getCefIndicators(cratingsArray, cytdArray, settings, periodicity) {
    getOneilRatings(cratingsArray, settings, periodicity);
    getYTDPerformance(cytdArray, settings.YTDChartSettings[periodicity], 0);
    getYTDPerformance(cytdArray, settings.YTD1ChartSettings[periodicity], 1);
    getYTDPerformance(cytdArray, settings.YTD3ChartSettings[periodicity], 3);
    getYTDPerformance(cytdArray, settings.YTD5ChartSettings[periodicity], 5);
    getYTDPerformance(cytdArray, settings.YTD10ChartSettings[periodicity], 10);
}

function getEtfIndicators(eratingsArray, eytdArray, settings, periodicity) {
    getOneilRatings(eratingsArray, settings, periodicity);
    getYTDPerformance(eytdArray, settings.YTDChartSettings[periodicity], 0);
    getYTDPerformance(eytdArray, settings.YTD1ChartSettings[periodicity], 1);
    getYTDPerformance(eytdArray, settings.YTD3ChartSettings[periodicity], 3);
    getYTDPerformance(eytdArray, settings.YTD5ChartSettings[periodicity], 5);
    getYTDPerformance(eytdArray, settings.YTD10ChartSettings[periodicity], 10);
}

function getIndexIndicators(iytdArray, settings, periodicity) {
    getYTDPerformance(iytdArray, settings.YTDChartSettings[periodicity], 0);
    getYTDPerformance(iytdArray, settings.YTD1ChartSettings[periodicity], 1);
    getYTDPerformance(iytdArray, settings.YTD3ChartSettings[periodicity], 3);
    getYTDPerformance(iytdArray, settings.YTD5ChartSettings[periodicity], 5);
    getYTDPerformance(iytdArray, settings.YTD10ChartSettings[periodicity], 10);
}

function getFundIndicators(fytdArray, settings, periodicity) {
    getYTDPerformance(fytdArray, settings.YTDChartSettings[periodicity], 0, true);
    getYTDPerformance(fytdArray, settings.YTD1ChartSettings[periodicity], 1, true);
    getYTDPerformance(fytdArray, settings.YTD3ChartSettings[periodicity], 3, true);
    getYTDPerformance(fytdArray, settings.YTD5ChartSettings[periodicity], 5, true);
    getYTDPerformance(fytdArray, settings.YTD10ChartSettings[periodicity], 10, true);
}

function getIgIndicators(wytdArray, settings, periodicity) {
    getYTDPerformance(wytdArray, settings.YTDChartSettings[periodicity], 0);
    getYTDPerformance(wytdArray, settings.YTD1ChartSettings[periodicity], 1);
}

function getEPSR(ratingsArray, settings, periodicity) {
    const epsrChartDict = settings.EPSRChartSettings[periodicity];
    if (epsrChartDict.IsAvailable) {
        ratingsArray.push(ONeilRatingType["EPSRevisions"]);
    }
}

function getPTOE(ratingsArray, settings, periodicity) {
    const ptoeChartDict = settings.PTOEChartSettings[periodicity];
    if (ptoeChartDict.IsAvailable) {
        ratingsArray.push(`${ONeilRatingType["PTOE"]}-${ptoeChartDict.IsRelative ? 1 : 0}`);
    }
}

function getPTOS(ratingsArray, settings, periodicity) {
    const ptosChartDict = settings.PTOSChartSettings[periodicity];
    if (ptosChartDict.IsAvailable) {
        ratingsArray.push(`${ONeilRatingType["PTOS"]}-${ptosChartDict.IsRelative ? 1 : 0}`);
    }
}

function getOneilRatings(ratingsArray) {

    const ratings = {
        Rs3Months: false,
        Rs6Months: false,
        Rs12Months: false,
        GrpRs3Months: false,
        GrpRs6Months: false,
        Composite: false,
        Datagraph: false,
        EpsRank: false,
        SMR: false,
        Sponsorship: false,
        InstSupDem: false
    };

    each(ratings, (value, key) => ratingsArray.push(ONeilRatingType[key]));
}

function getYTDPerformance(ytdArray, settings, freq, isMF = false) {
    if (settings.IsAvailable) {
        isMF ?
            ytdArray.push(`${freq}-${settings.IsRelative ? 1 : 0}`)
            :
            ytdArray.push(freq);
    }
}


export function* watchGetAllIndicatorsData(){
    yield takeLatest(IndicatorsConstants.ActionTypes.GET_ALL_INDICATOR_DATA, getAllIndicatorsData);
}