import { connect } from 'react-redux';
import { PrintMode } from '../../../../print/printmode';
import ScrollBar from "ScrollBar";
import SettingsStore from "SettingsStore";
import StringUtil from 'StringUtil';
import { map, extend, findIndex, find } from "underscore";
import React, { Component } from 'react';
import { updateRiPanelSettings, updatePDRToReducer, updatePDRToStore, handleInputValueChange, saveLabel } from '../../../../Actions/ExternalDataUploaderActions'

class RIPanelExternalInfo extends Component {

    selectValue = (e) => {
        if (e.target) {
            e.target.focus();
            e.target.select();
            e.target.classList.add("editable");
        }
        e.stopPropagation();
        e.preventDefault();
        document.body.classList.add('ExternalDataEditDialogOpened');
    }

    handleLabelKeyPress = (e, esField, listId) => {
        const code = e.keyCode || e.which;
        if (e.target.value.length < 3) {
            e.target.title = '';
        }
        if (code === 13) {
            e.target.classList.remove("editable");
            document.body.classList.remove('ExternalDataEditDialogOpened');
            e.target.blur();

            // updating PDR with new label
            const { PDR_info } = this.props.externalData;
            const headerItem = find(PDR_info['headerData'], (data) => (data.esField).toString() === esField.toString() && parseFloat(data.listId) === parseFloat(listId));
            if (headerItem !== undefined) {
                headerItem.metric = e.target.value;
            }
            this.props.updatePDRToReducer(PDR_info);
            this.props.updatePDRToStore(PDR_info);
            this.props.saveLabel(e.target.value, esField, listId);
        }
    }

    handleInputValueChange = (e, index, esFieldName) => {
        this.props.handleInputValueChange(e, index, esFieldName);
    }

    handleBlur = (e, item, esField, listId) => {
        e.target.classList.remove("editable");
        document.body.classList.remove('ExternalDataEditDialogOpened');
        // updating PDR with new label
        const { PDR_info } = this.props.externalData;
        const headerItem = find(PDR_info['headerData'], (data) => (data.esField).toString() === esField.toString() && parseFloat(data.listId) === parseFloat(listId));
        if (headerItem !== undefined) {
            headerItem.metric = e.target.value;
        }
        this.props.updatePDRToReducer(PDR_info);
        this.props.updatePDRToStore(PDR_info);
        this.props.saveLabel(e.target.value, esField, listId);
    }

    handleCheckBox = (e, item, data, listId) => {
        item.isVisible = e.target.checked;
        const { PDR_info } = this.props.externalData;
        const sequenceNo = PDR_info.headerData.length > 0 ? PDR_info.headerData[PDR_info.headerData.length - 1].sequenceNo + 1 : 0;
        const headerInfo = { metric: data.metricName, value: parseFloat(parseFloat(data.value).toFixed(2)), esField: data.esFieldName, listId: listId, sequenceNo: sequenceNo };

        if (e.target.checked) {
            PDR_info['headerData'].push(headerInfo);
            extend(PDR_info, { recentRow: sequenceNo });
        }
        else {
            const deleteItem = find(PDR_info['headerData'], (item) => item.esField === data.esFieldName && parseFloat(item.listId) === parseFloat(listId));
            const index = findIndex(PDR_info['headerData'], { sequenceNo: deleteItem.sequenceNo });
            let nextIndex = index;

            PDR_info['headerData'].splice(index, 1);

            if (nextIndex > PDR_info['headerData'].length - 1) {
                nextIndex = 0;
            }
            PDR_info['headerData'].forEach((items,i)=>{
                items.sequenceNo = i;
            });

            extend(PDR_info, { recentRow: nextIndex });
        }
        this.props.updatePDRToReducer(PDR_info);
        PrintMode.updateExternalScore(PDR_info.recentRow);
        this.props.updatePDRToStore(PDR_info);
        this.props.updateRiPanelSettings(this.props.externalData.settings);
        SettingsStore.saveSettings();
    }

    renderMenu = (item, key, listId) => {
        const { riPanelData } = this.props.externalData;

        return (
            map(riPanelData, (listInfo) => {
                if (parseFloat(listId) === parseFloat(listInfo.listId.low)) {
                    return map(listInfo.metrics, (data) => {
                        if (data.esFieldName === key) {
                            return (
                                <div className="row" key={`${listId}-${key}`}>
                                    <div className="row-cell data-checkbox">
                                        <span className="check-box">
                                            <input type="checkbox" checked={item.isVisible} onChange={(e) => this.handleCheckBox(e, item, data, listId)} />
                                            <label></label>
                                        </span>
                                    </div>
                                    <div className="row-cell metric">
                                        <span className="metric-text" title={data.metricName}>{data.metricName}</span>
                                    </div>
                                    <div className="row-cell value">{parseFloat(parseFloat(data.value).toFixed(2))}</div>
                                    <div className="row-cell date">{StringUtil.formatDate(data.date, "MM-DD-YYYY")}</div>
                                </div>
                            )
                        }
                    })
                }
            })
        )
    }

    render() {
        const { settings, riPanelData, loading } = this.props.externalData;

        return (
            <div className="ri-external-stuff">
                {/* <div className="setting-bar">
                    <ul>
                        <li><Glyphicon glyph="cog" /></li>
                        <li><Glyphicon glyph="refresh" /></li>
                    </ul>
                </div> */}
                {loading ? <div className="news-message  large-normal"><p>Loading</p> </div> : StringUtil.isEmpty(riPanelData) ?
                    <div className="news-message large-normal">
                        <p>No Data Available<br></br>Upload Time series data set to view data</p>
                    </div>
                    :
                    <div className="ri-external-data">
                        <p>Checked rows appear as a toggle-able data item in the row above the Datagraph.</p>
                        <div className="ri-external-grid">
                            <div className="ri-external-heads">
                                <div className="row">
                                    <span className="heads data-checkbox">DATA ROW</span>
                                    <span className="heads metric"><span className="metric-text">METRIC</span></span>
                                    <span className="heads value">VALUE</span>
                                    <span className="heads date">DATE</span>
                                </div>
                            </div>
                            <div className="scroll-wrapper-riPanel">
                                <div className="custom-scroll">
                                    <div className="ri-external-datas" id='symbol-currency-desc-VerticalScroll'>
                                        {settings.ExternalDataRiPanelSettings && map(settings.ExternalDataRiPanelSettings, (data, listId) => {
                                            if (data) {
                                                return map(data, (item, key) => this.renderMenu(item, key, listId))
                                            }
                                        })
                                        }
                                    </div>
                                </div>
                                <ScrollBar scrollId='symbol-currency-desc-VerticalScroll' vScroll={true} scrollOnHover={false} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    externalData: state.externalDataUploader.externalDataUploadSymbolReducer
})

const mapDispatchToProps = (dispatch) => ({
    updateRiPanelSettings: (settings) => dispatch(updateRiPanelSettings(settings)),
    updatePDRToReducer: (PDR_info) => dispatch(updatePDRToReducer(PDR_info)),
    updatePDRToStore: (PDR_info) => (updatePDRToStore(PDR_info)),
    handleInputValueChange: (e, index, esFieldName) => dispatch(handleInputValueChange(e, index, esFieldName)),
    saveLabel: (newLabel, esField, listId) => dispatch(saveLabel(newLabel, esField, listId))
})

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelExternalInfo);