import GraphType from 'GraphType';
import SymbolType from "SymbolType";
import DailyTimeLine from "./PeriodicTimeLine/DailyTimeLine.js";
import WeeklyTimeLine from "./PeriodicTimeLine/WeeklyTimeLine.js";
import MonthlyTimeline from "./PeriodicTimeLine/MonthlyTimeline.js";
import IntradayTimeline from "./PeriodicTimeLine/IntradayTimeLine.js";
import QuarterlyTimeline from "./PeriodicTimeLine/QuarterlyTimeline.js";
import AnnualTimeline from "./PeriodicTimeLine/AnnualTimeline.js";
import SettingsStore from "SettingsStore";
import DateHelper from "DateHelper";

class TimeLineHelper {
    GetNumberOfDays(startDate, endDate) {
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return Math.round(Math.abs((startDate.getTime() - endDate.getTime()) / (oneDay)));
    }
    GetTimeLineData(startDate, endDate, periodicity, fCQtrEstCount, symbolInfo, isMountainChart, isComparisionChart = false, videoMode = false, hsfData = null) {
        let timeLineData = { dates: [], FirstLabelIndex: -1, TimeLineLabel: [] };
        let units;

        switch (periodicity) {
            case GraphType.Daily:
                units = this.GetNumberOfDays(startDate, endDate);
                timeLineData = { dates: new Array(units), FirstLabelIndex: -1, TimeLineLabel: [] };
                this.TimeLineSetVerticalGrid(DailyTimeLine, startDate, endDate, timeLineData, fCQtrEstCount, symbolInfo, isMountainChart, periodicity, false, isComparisionChart, videoMode);
                break;
            case GraphType.Weekly:
                units = Math.round(this.GetNumberOfDays(startDate, endDate) / 7);
                timeLineData = { dates: new Array(units), FirstLabelIndex: -1, TimeLineLabel: [] };
                this.TimeLineSetVerticalGrid(WeeklyTimeLine, startDate, endDate, timeLineData, fCQtrEstCount, symbolInfo, isMountainChart, periodicity, false, isComparisionChart, videoMode);
                break;
            case GraphType.Monthly:
                units = Math.round(this.GetNumberOfDays(startDate, endDate) / 30);
                timeLineData = { dates: new Array(units), FirstLabelIndex: -1, TimeLineLabel: [] };
                this.TimeLineSetVerticalGrid(MonthlyTimeline, startDate, endDate, timeLineData, fCQtrEstCount, symbolInfo, isMountainChart, periodicity, false, isComparisionChart, videoMode);
                break;
            case GraphType.Quarterly:
                units = Math.round(this.GetNumberOfDays(startDate, endDate) / 90);
                timeLineData = { dates: new Array(units), FirstLabelIndex: -1, TimeLineLabel: [] };
                this.TimeLineSetVerticalGrid(QuarterlyTimeline, startDate, endDate, timeLineData, fCQtrEstCount, symbolInfo, isMountainChart, periodicity, false, isComparisionChart, videoMode);
                break;
            case GraphType.Annual:
                units = Math.round(this.GetNumberOfDays(startDate, endDate) / 360);
                timeLineData = { dates: new Array(units), FirstLabelIndex: -1, TimeLineLabel: [] };
                this.TimeLineSetVerticalGrid(AnnualTimeline, startDate, endDate, timeLineData, fCQtrEstCount, symbolInfo, isMountainChart, periodicity, false, isComparisionChart, videoMode);
                break;
            default:
                timeLineData = this.TimeLineSetVerticalGrid(IntradayTimeline, startDate, endDate, timeLineData, fCQtrEstCount, symbolInfo, isMountainChart, periodicity, true, isComparisionChart, videoMode, hsfData);
                break;
        }
        return timeLineData;
    }
    Qtr(month) {
        return Math.floor((month - 1) / 3.0);
    }

    GetFirstLabelIndex(timelineData, periodicity, cMonth) {
        var calQtrs = [3, 6, 9, 12];
        var f1Qtrs = [1, 4, 7, 10];
        var f2Qtrs = [2, 5, 8, 11];

        if (periodicity === GraphType.Weekly || periodicity === GraphType.Daily) {
            if (calQtrs[this.Qtr(cMonth)] == cMonth) {
                return timelineData.FirstCalLabelIndex;
            }
            else
                if (f1Qtrs[this.Qtr(cMonth)] == cMonth) {
                    return timelineData.Firstf1LabelIndex;
                }
                else
                    if (f2Qtrs[this.Qtr(cMonth)] == cMonth) {
                        return timelineData.Firstf2LabelIndex;
                    }
        }
        else
            return timelineData.FirstLabelIndex;
    }
    GetLabels(timelineData, periodicity, cMonth) {
        var calQtrs = [3, 6, 9, 12];
        var f1Qtrs = [1, 4, 7, 10];
        var f2Qtrs = [2, 5, 8, 11];

        if (periodicity === GraphType.Weekly) {
            if (calQtrs[this.Qtr(cMonth)] == cMonth) {
                return timelineData.TimeLineLabel.filter((v) => v != undefined && v.IsCalendarLabel);
            }
            else
                if (f1Qtrs[this.Qtr(cMonth)] == cMonth) {
                    return timelineData.TimeLineLabel.filter((v) => v.IsFiscalLabel1);
                }
                else
                    if (f2Qtrs[this.Qtr(cMonth)] == cMonth) {
                        return timelineData.TimeLineLabel.filter((v) => v.IsFiscalLabel2);
                    }
        }
        else
            return timelineData.TimeLineLabel;
    }
    TimeLineSetVerticalGrid(timeLine, startDate, endDate, timeLineData, fCQtrEstCount, symbolInfo, isMountainChart, periodicity, isIntraday, isComparisionChart = false, videoMode = false, hsfData) {
        let earnReportDate = symbolInfo.Earnrptdate.getFullYear ? symbolInfo.Earnrptdate : new Date(parseInt(symbolInfo.Earnrptdate.substr(6)));
        //PANWEB-5057
        let targetDate = symbolInfo.targetDate ? symbolInfo.targetDate : symbolInfo.LastTradeDate;
        let mutualFund = symbolInfo.SymTypeEnum === SymbolType.FUND;
        if (isMountainChart) {
            if (!isIntraday)
                timeLine.SetMCVerticalGrid(startDate, endDate, timeLineData, earnReportDate, fCQtrEstCount);
        }
        else if (isComparisionChart) {
            timeLine.padding = false;

            if (symbolInfo.SymTypeEnum != SymbolType.CURRENCY &&
                symbolInfo.SymTypeEnum != SymbolType.ETF &&
                symbolInfo.SymTypeEnum != SymbolType.FundClosedEnd &&
                symbolInfo.SymTypeEnum != SymbolType.FUND) {
                timeLine.SetVerticalGrid(startDate, endDate, timeLineData, earnReportDate, fCQtrEstCount, false, videoMode);
            }
            else {
                timeLine.SetVerticalGrid(startDate, endDate, timeLineData, NaN, 0, mutualFund, videoMode);
            }

        }
        else {
            if (isIntraday) // Intraday has multiple periodicities
            {
                //  let openTime = new Date(parseInt(symbolInfo.ExchangeOpenTime.substr(6)));
                //  let closeTime = new Date(parseInt(symbolInfo.ExchangeCloseTime.substr(6)));
                let openTime = symbolInfo.ExchangeOpenTime.getFullYear ? symbolInfo.ExchangeOpenTime : DateHelper.parseJsonDate(symbolInfo.ExchangeOpenTime, symbolInfo.MomentZoneID);
                let closeTime = symbolInfo.ExchangeCloseTime.getFullYear ? symbolInfo.ExchangeCloseTime : DateHelper.parseJsonDate(symbolInfo.ExchangeCloseTime, symbolInfo.MomentZoneID);

                let tradeStart = new Date(endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate(),
                    openTime.getHours(),
                    openTime.getMinutes(), 0, 0);
                let tradeClose = new Date(endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate(),
                    closeTime.getHours(),
                    closeTime.getMinutes(), 0, 0);

                // let dateNow = new Date();
                // let endTime = new Date(dateNow + 4 * 60000);
                //let endTime = new Date(endDate.getTime());
                let endTime = new Date(endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate(),
                    endDate.getHours(),
                    endDate.getMinutes(), 0, 0);
                var startTime = new Date(startDate.getTime());
                let days = this.GetNumberOfDays(startTime, endTime);
                let tradingMinutes = (closeTime.getTime() - openTime.getTime()) / 60000;
                let totalNodes = Math.round(days * tradingMinutes);
                
                let isStartDateAvailable = false;
                if (hsfData && hsfData.length > 0) {
                    const firstPriceNode = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(hsfData[hsfData.length - 1].Date), symbolInfo.MomentZoneID);

                    if (firstPriceNode < startTime) {
                        // firstPriceNode / start date will be calculated at the graph service end
                        isStartDateAvailable = true;
                        startTime = firstPriceNode;
                        startTime.setDate(startTime.getDate() - 1); // including extra 1 day incase the system timezone is different from the symbolInfo timezone.

                        if (startTime.getDay() === 0)
                            startTime.setDate(startTime.getDate() - 3);
                        if (startTime.getDay() === 6)
                            startTime.setDate(startTime.getDate() - 2);
                    }
                }
                
                switch (periodicity) {
                    case GraphType.Intraday1Min:
                        if (!isStartDateAvailable) {
                            startTime.setDate(endTime.getDate() - 2);
                            if (startTime.getDay() === 0)
                                startTime.setDate(endTime.getDate() - 5);
                            if (startTime.getDay() === 6)
                                startTime.setDate(endTime.getDate() - 4);
                        }
                        days = this.GetNumberOfDays(startTime, endTime);
                        totalNodes = Math.round(days * tradingMinutes);
                        timeLineData = { dates: new Array(totalNodes), FirstLabelIndex: -1, TimeLineLabel: [] };
                        break;
                    case GraphType.Intraday5Min:
                        !isStartDateAvailable && startTime.setDate(endTime.getDate() - 10);
                        days = this.GetNumberOfDays(startTime, endTime);
                        totalNodes = Math.round(days * tradingMinutes / 5);
                        timeLineData = { dates: new Array(totalNodes), FirstLabelIndex: -1, TimeLineLabel: [] };
                        break;
                    case GraphType.Intraday10Min:
                        !isStartDateAvailable && startTime.setDate(endTime.getDate() - 20);
                        days = this.GetNumberOfDays(startTime, endTime);
                        totalNodes = Math.round(days * tradingMinutes / 10);
                        timeLineData = { dates: new Array(totalNodes), FirstLabelIndex: -1, TimeLineLabel: [] };
                        break;
                    case GraphType.Intraday15Min:
                        !isStartDateAvailable && startTime.setDate(endTime.getDate() - 30);
                        days = this.GetNumberOfDays(startTime, endTime);
                        totalNodes = Math.round(days * tradingMinutes / 15);
                        timeLineData = { dates: new Array(totalNodes), FirstLabelIndex: -1, TimeLineLabel: [] };
                        break;
                    case GraphType.Intraday30Min:
                        !isStartDateAvailable && startTime.setDate(endTime.getDate() - 60);
                        days = this.GetNumberOfDays(startTime, endTime);
                        totalNodes = Math.round(days * tradingMinutes / 30);
                        timeLineData = { dates: new Array(totalNodes), FirstLabelIndex: -1, TimeLineLabel: [] };
                        break;
                    case GraphType.Intraday60Min:
                        !isStartDateAvailable && startTime.setDate(endTime.getDate() - 120);
                        days = this.GetNumberOfDays(startTime, endTime);
                        totalNodes = Math.round(days * tradingMinutes / 60);
                        timeLineData = { dates: new Array(totalNodes), FirstLabelIndex: -1, TimeLineLabel: [] };
                        break;
                    default:
                        break;
                }
                timeLine.SetVerticalGrid(startTime, endTime, timeLineData, tradeStart, tradeClose, periodicity);
            }
            else {
                if (symbolInfo.SymTypeEnum != SymbolType.CURRENCY &&
                    symbolInfo.SymTypeEnum != SymbolType.ETF &&
                    symbolInfo.SymTypeEnum != SymbolType.FundClosedEnd &&
                    symbolInfo.SymTypeEnum != SymbolType.FUND) {
                    let consoleSettings = SettingsStore.getConsoleSettings();
                    let tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
                    let stockViewSettings = tabDataGraphSettings.StockViewSettings;
                    let earningLineSetting = stockViewSettings.EarningLineSetting[periodicity];
                    let revenueLineSetting = stockViewSettings.RevenueLineSetting[periodicity];
                    let fcQuarters = Math.min((earningLineSetting == undefined ? 0 : earningLineSetting.NumOfQuarters), symbolInfo.FCQtrEstCount);
                    
                    // if(periodicity == GraphType.Monthly || periodicity == GraphType.Quarterly || periodicity == GraphType.Annual)
                    // fcQuarters = Math.min((earningLineSetting == undefined ? 0 : earningLineSetting.NumOfQuarters), symbolInfo.FCAnnualEstCount);  --> once again need to update financialblock table(monthlyTimeLine) with fcqtrs = 1.
                    
                    if(periodicity == GraphType.Monthly || periodicity == GraphType.Quarterly || periodicity == GraphType.Annual)
                        fcQuarters = earningLineSetting == undefined ? 0 : earningLineSetting.NumOfQuarters;
                    if(symbolInfo.nupChart){
                        fcQuarters =fCQtrEstCount;
                    }
                    if ((!earningLineSetting.IsVisible && !revenueLineSetting.IsVisible))
                        fcQuarters = 0;
                    timeLine.SetVerticalGrid(startDate, endDate, timeLineData, earnReportDate, fcQuarters, false, videoMode, targetDate);
                } else {
                    timeLine.SetVerticalGrid(startDate, endDate, timeLineData, NaN, 0, mutualFund, videoMode);
                }
            }
        }
        return timeLineData;
    }
}

const timeLineHelper = new TimeLineHelper();
export default timeLineHelper;
