import { CalenderControlConstants } from "../../Constants/CalenderControl/CalenderControlConst";
import ConsoleStore from "ConsoleStore";
import { DataGraphConstants } from "../../Constants/DataGraphConstants";
import DatagraphStore from "../../Stores/NavModules/NavDataGraph/DataGraphStore";
import DateHelper from "../../Utils/TimeLineHelper/Datehelper";
import DayOfWeek from "../../Constants/DayOfWeek";
import GraphType from "../../Constants/GraphType";
import moment from "moment";
import SettingsStore from "SettingsStore";
import TabType from "../../Constants/TabType";
import TimeTrackingWindow from "../../RayCustomControls/TimeTrackingWindow";
import { updateCalenderControlProperties } from "../../Actions/CalenderControl/CalenderControlAction";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getCalenderControlState, getDatagraphStates, getIndicatorStates } from "../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { onPlotSymbol, updateIsHistoric, updateSymbolEntryLoading } from "../../Actions/DatagraphActions";

const { ActionTypes } = CalenderControlConstants;
const equityEventsHeader = {
    0: "CorpEvents",
    2: "CorpEvents",
    3: "Earnings",
    4: "News",
    5: "CorpEventsCeo",
    6: "CorpEventsCeo",
    8: "Reorganization",
}

const addSubtractConstants = {
    [GraphType.Intraday]: "d",
    [GraphType.Daily]: "d",
    [GraphType.Weekly]: "w",
    [GraphType.Monthly]: "M",
    [GraphType.Quarterly]: "Q",
    [GraphType.Annual]: 'y'
}
const endOfConstants = {
    [GraphType.Intraday]: "day",
    [GraphType.Daily]: "day",
    [GraphType.Weekly]: "week",
    [GraphType.Monthly]: "month",
    [GraphType.Quarterly]: "quarter",
    [GraphType.Annual]: 'year'
}

function* initCalenderControl() {
    try {
        const state = yield select(getCalenderControlState);
        const { pricePanelData, SymbolInfo, endDate, isIntraday, SymbolType, majorPeriodicity } = yield select(getDatagraphStates)
        const values = {}
        values.blockOutDates = pricePanelData.blackOutDatesDict.BlackOutDates;
        values.minDate = SymbolInfo.IPODate ? moment(SymbolInfo.IPODate) : moment("01/01/1900", ['YYYY-MM-DD']);
        values.maxDate = SymbolInfo.LastTradeDate ? moment(SymbolInfo.LastTradeDate) : moment();
        values.dateTime = moment(endDate, state.format, true);
        const equityEvents = pricePanelData.EquityEvents.eventDateItems;
        values.viewDate = moment(values.dateTime, state.format, true).startOf("month");
        values.selectedDate = moment(values.dateTime, state.format, true);
        values.inputValue = moment(values.dateTime, state.format, true).format(state.inputFormat)
        const consoleSettings = SettingsStore.getConsoleSettings();
        values.isIntraday = isIntraday;
        values.disableDateTimeField = consoleSettings.NavDatagraphSettings.SelectedTabSettings[SymbolType] === TabType.Fundamental;
        values.endOfDate = endOfConstants[majorPeriodicity];
        values.addSubtractDate = addSubtractConstants[majorPeriodicity];
        const events = [];
        equityEvents.forEach((item) => {
            item.EventDate = DateHelper.getPSTFromLong(item.EventDate)
            if (events[item.EventDate.getFullYear()]) {
                if (item.EventType === 3) {
                    const textLength = item.EventText.length;
                    if (item.EventText && textLength > textLength - 4) {
                        if (textLength > 0) {
                            events[item.EventDate.getFullYear()].push({ header: equityEventsHeader[item.EventType], content: `${item.EventText.substring(0, 3)} ${item.EventText.substring(textLength - 4, textLength)}`, eventDate: item.EventDate });
                        }
                    }
                }
                else if (item.EventType === 5 || item.EventType === 6) {
                    let indx = item.EventDescrip.indexOf('-');
                    let eventText = item.EventDescrip.substr(indx + 2);
                    indx = eventText.indexOf('-');
                    if (indx > -1) {
                        eventText = eventText.substr(indx + 2);
                    }
                    events[item.EventDate.getFullYear()].push({ header: equityEventsHeader[item.EventType], content: eventText, eventDate: item.EventDate });
                } else {
                    events[item.EventDate.getFullYear()].push({ header: equityEventsHeader[item.EventType], content: item.EventText, eventDate: item.EventDate });
                }
            } else {
                events[item.EventDate.getFullYear()] = [];
            }
        })
        values.equityEvents = events
        yield put(updateCalenderControlProperties(values))
    }
    catch (error) {
        console.log(`Error occured in initCalenderControl of CalenderControlSaga.js`, error);
    }
}

function* setSelectedDate({ event }) {
    const state = yield select(getCalenderControlState);
    if (event.target.className && !event.target.className.match(/disabled/g)) {
        let month;
        if (event.target.className.indexOf("new") >= 0) {
            month = state.viewDate.month() + 1;
        }
        else if (event.target.className.indexOf("old") >= 0) {
            month = state.viewDate.month() - 1;
        }
        else {
            month = state.viewDate.month();
        }
        const values = { selectedDate: state.viewDate.clone().month(month).date(parseInt(event.target.innerHTML)).hour(state.selectedDate.hours()).minute(state.selectedDate.minutes()) }
        if (!state.isPinned) {
            values.showPicker = false;
        }
        values.inputValue = values.selectedDate.format(state.inputFormat);
        values.dateTime = moment(values.selectedDate, state.format, true);
        const isHistoric = values.selectedDate.isBefore(state.maxDate);
        values.viewDate = moment(values.selectedDate, state.format, true).startOf("month");
        yield put(updateCalenderControlProperties(values))
        yield put(updateSymbolEntryLoading(true, true));
        yield call(dayChange, values.selectedDate, isHistoric);
    }
}
function isLastDay(date, endOfDate, blockOutDates){
    let endDate = moment(date.clone().endOf(endOfDate));
    endDate = moment({ year: endDate.year(), month: endDate.month(), day: endDate.date()})

    while(endDate.day() === DayOfWeek.Saturday || endDate.day() === DayOfWeek.Sunday || isHoliday(endDate, blockOutDates)){
        endDate.subtract(1, "days");
    }
    return endDate.date() === date.date();
}
//If friday and Monday are holiday. For that there is two holiday condition check
function* stepForwardInTime() {
    const state = yield select(getCalenderControlState);
    if(state.dateTime.isSame(state.maxDate)){
        return;
    }
    const { blockOutDates } = yield select(getDatagraphStates);
    if(state.addSubtractDate === 'd' || isLastDay(state.dateTime, state.endOfDate, blockOutDates)){
        state.dateTime.add(1, state.addSubtractDate);
    }
    state.dateTime.endOf(state.endOfDate)
    let day = state.dateTime.day()
    if(state.addSubtractDate === 'd'){
        while(day === DayOfWeek.Saturday || day === DayOfWeek.Sunday || isHoliday(state.dateTime, blockOutDates)){
            state.dateTime.add(1, "days");
            day = state.dateTime.day()
        }
    }else{
        while(day === DayOfWeek.Saturday || day === DayOfWeek.Sunday || isHoliday(state.dateTime, blockOutDates)){
            state.dateTime.subtract(1, "days");
            day = state.dateTime.day()
        }
    }
    if(state.dateTime.isAfter(state.maxDate)){
        state.dateTime = state.maxDate;
    }
    const isHistoric = state.dateTime.isBefore(state.maxDate)
    yield put(updateCalenderControlProperties({
        viewDate: moment(state.dateTime, state.format, true).startOf("month"),
        selectedDate: moment(state.dateTime, state.format, true),
        dateTime: moment(state.dateTime, state.format, true),
        inputValue: moment(state.dateTime, state.format, true).format(state.inputFormat),
        showPicker: state.isPinned,
    }));
    yield call(dayChange, state.dateTime, isHistoric);
}
//If friday and Monday are holiday. For that there is two holiday condition check
function* onStepBackInTime({  }) {
    const state = yield select(getCalenderControlState);
    if(state.dateTime.isSame(state.minDate)){
        return;
    }
    const { blockOutDates } = yield select(getDatagraphStates);
    state.dateTime.subtract(1, state.addSubtractDate);
    state.dateTime.endOf(state.endOfDate)
    let day = state.dateTime.day()
    while(day === DayOfWeek.Saturday || day === DayOfWeek.Sunday || isHoliday(state.dateTime, blockOutDates)){
        state.dateTime.subtract(1, "days");
        day = state.dateTime.day()
    }
    if(state.dateTime.isBefore(state.minDate)){
        state.dateTime = state.minDate;
    }
    const isHistoric = state.dateTime.isBefore(state.maxDate);
    yield put(updateCalenderControlProperties({
        viewDate: moment(state.dateTime, state.format, true).startOf("month"),
        selectedDate: moment(state.dateTime, state.format, true),
        dateTime: moment(state.dateTime, state.format, true),
        inputValue: moment(state.dateTime, state.format, true).format(state.inputFormat),
        showPicker: state.isPinned,
    }));
    yield call(dayChange, state.dateTime, isHistoric);
}

function* setMostRecentTradeDate() {
    const state = yield select(getCalenderControlState);
    yield put(updateCalenderControlProperties({
        viewDate: moment(state.maxDate, state.format, true).startOf("month"),
        selectedDate: moment(state.maxDate, state.format, true),
        dateTime: moment(state.maxDate, state.format, true),
        inputValue: moment(state.maxDate, state.format, true).format(state.inputFormat),
        showPicker: state.isPinned,
    }));
    yield call(dayChange, state.maxDate, false);
}
function* dayChange(date, isHistoric) {
    date = new Date(date.year(), date.month(), date.date());
    ConsoleStore.UpdateConsoleDate(date, date);
    yield put(updateIsHistoric(isHistoric));
    TimeTrackingWindow.resetTimeTracker(true);
    const state = DatagraphStore.getState();
    TimeTrackingWindow.initTimeTracker(state.SymbolInfo);
    TimeTrackingWindow.beginTimeTracker();
    TimeTrackingWindow.isDayChange = true;
    const { indicatorMenu } = yield select(getIndicatorStates);
    TimeTrackingWindow.setPerformanceTIPanelStatus(indicatorMenu, state.SymbolInfo);
    yield put({ type: DataGraphConstants.ActionTypes.ON_PLOT_SYMBOL, symbol: state.SymbolInfo.Symbol, isCalendarChanged: true, isSymbolChange: false});
}

//If friday and Monday are holiday. For that there is two holiday condition check
function* handleKeyDown({ event }) {
    const state = yield select(getCalenderControlState)
    const { blockOutDates } = yield select(getDatagraphStates);
    if (event.keyCode === 32) {
        event.preventDefault();
        event.stopPropagation();
        return;
    }
    if (event.keyCode === 13) {
        let inputDate = moment(state.inputValue, state.browserFormat);
        if (state.inputValue === "" || !inputDate.isValid()) {
            yield put(updateCalenderControlProperties({
                inputValue: moment(state.dateTime, state.inputFormat, true).format(state.inputFormat),
                showPicker: false,
                isEditMode: false
            }));
            return;
        }
        else {
            if (inputDate.isAfter(state.maxDate)) {
                inputDate = moment(state.maxDate, state.browserFormat);
            }
            else if (inputDate.isBefore(state.minDate)) {
                inputDate = moment(state.minDate, state.browserFormat);
            }
            else if (isHoliday(inputDate, blockOutDates)) {
                inputDate.subtract(1, "d");
            }

            const day = inputDate.day();
            if (day === DayOfWeek.Sunday) {
                inputDate.subtract(2, "days");
            }
            else if (day === DayOfWeek.Saturday) {
                inputDate.subtract(1, "days");
            }
            if (isHoliday(inputDate, blockOutDates)) {
                inputDate.subtract(1, "d");
            }
            const isHistoric = inputDate.isBefore(state.maxDate);
            yield put(updateCalenderControlProperties({
                selectedDate: moment(inputDate, state.inputFormat),
                viewDate: moment(inputDate, state.inputFormat).startOf("month"),
                inputValue: moment(inputDate, state.browserFormat, true).format(state.inputFormat),
                dateTime: moment(inputDate, state.formate, true),
                showPicker: false,
                isEditMode: false
            }));
            yield call(dayChange, inputDate, isHistoric);
        }
    }
    else if (event.keyCode > 64 && event.keyCode < 91) {
        event.preventDefault();
        event.stopPropagation();
    }
}
//
function isHoliday(date, blockOutDates) {
    let isHoliday = false;
    if (blockOutDates) {
        blockOutDates.find((item) => {
            if (moment(item.Date).startOf('day').isSame(moment(date).startOf('day'))) {
                isHoliday = true;
            }
            return null;
        });
    }
    return isHoliday;
}


function* handleEventDateClick({ date }) {
    const state = yield select(getCalenderControlState)
    const isHistoric = moment(date, state.format, true).isBefore(state.maxDate)
    yield put(updateCalenderControlProperties({  
        inputValue: moment(date, state.inputFormat, true).format(state.inputFormat),
        dateTime: moment(date, state.format, true),
        isEditMode: true }))
        yield call(dayChange, moment(date, state.format, true), isHistoric);
}
export function* watchInitCalenderControl() {
    yield takeLatest(DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY, initCalenderControl)
}
export function* watchSetSelectedDate() {
    yield takeLatest(ActionTypes.UPDATE_SELECTED_DATE, setSelectedDate);
}
export function* watchStepForwardInTime() {
    yield takeLatest(ActionTypes.STEP_FORWARD_IN_TIME, stepForwardInTime);
}
export function* watchStepBackInTime() {
    yield takeLatest(ActionTypes.STEP_BACKWARD_IN_TIME, onStepBackInTime);
}
export function* watchSetMostRecentDates() {
    yield takeLatest(ActionTypes.SET_MOST_RECENT_DATE, setMostRecentTradeDate);
}
export function* watchHandleDateChange() {
    yield takeLatest(ActionTypes.UPDATE_INPUT_DATE, handleKeyDown);
}
export function* watchHandleEventDateChange() {
    yield takeLatest(ActionTypes.UPDATE_EVENT_DATE, handleEventDateClick);
}
