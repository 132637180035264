import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class RenameColumnSetRequest extends BaseServiceRequest {

    constructor(newColSetName, colsetId) {
        super();
        super.addRequestUri("columnsets.pb");
        super.addRequestParameter("n", newColSetName);
        super.addRequestParameter("cs", colsetId.toString());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default RenameColumnSetRequest;
