import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AddRenameColumnSetFolderRequest extends BaseServiceRequest {

    constructor(newColSetFolderName, consoleID, folderID, dbtype = null) {
        super();
        super.addRequestUri("columnsetfolder.pb");
        super.addRequestParameter("n", newColSetFolderName);
        super.addRequestParameter("f", folderID.toString());
        super.addRequestParameter("c", consoleID);
        if (dbtype) super.addRequestParameter("db", dbtype.toString());
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}

export default AddRenameColumnSetFolderRequest;
