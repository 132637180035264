const {
    ActionTypes
} = SummaryBlockConstants;
import {
    SummaryBlockConstants
} from '../Constants/SummaryBlockConstants.js';

export const setRelativeYTD = (isYtdAbsolute, index) => ({
    type: ActionTypes.SET_RELATIVE_YTD,
    isYtdAbsolute,
    index
})

export const showMoreSummary = (showMore) => ({
    type: ActionTypes.SHOW_MORE_SUMMARY,
    showMore
})

export const openCollapse = (isOpen) => ({
    type: ActionTypes.OPEN_COLLAPSE_TRIGGERED,
    isOpen
})

export const getSummaryBlockData = (settings, endDt) => ({
    type: ActionTypes.FETCH_SUMMARY_BLOCK_DATA,
    settings,
    endDt
})