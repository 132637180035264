import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ScaleButton } from '../Presentational';
import { changeScaleType, changeScaleBase } from '../../../../../Actions/ComparisonActions';
import { CompareMenuType } from "CompareTabType";

class Scales extends Component {
    handleClick = (posScale) => {
        const { changeScaleType, changeScaleBase } = this.props;
        switch (posScale) {
            case "left":
                changeScaleBase();
                break;
            case "right":
                changeScaleType();
                break;
            default:
                break;
        }
    }
    render() {
        const { scaleBaseText, scaleTypeText } = this.props;
        return (
            <div className="base-value">
                <ScaleButton onClick={this.handleClick} value={scaleBaseText} posScale="left" />
                {/*<ScaleButton onClick={this.handleClick} value={scaleTypeText} posScale="right" /> fix - PANWEB-2342*/}
                <ScaleButton value={scaleTypeText} posScale="right" />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.compareChart;
    const { scaleBaseText, scaleTypeText } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

    return ({
        scaleBaseText: scaleBaseText,
        scaleTypeText: scaleTypeText
    });
}

const mapDispatchToProps = (dispatch) => ({
    //changeScaleType: () => dispatch(changeScaleType()), fix - PANWEB-2342
    changeScaleBase: () => dispatch(changeScaleBase())
});

export default connect(mapStateToProps, mapDispatchToProps)(Scales);