import PropTypes from 'prop-types';
import React from "react";
import ReactDOM from "react-dom";
import { FormControl, Form, ControlLabel, FormGroup, Col, Button } from "react-bootstrap";
import LoginActions from "../../Actions/LoginActions.js";
import LoginStore from "LoginStore";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js"
import LangType from "../../Constants/LangType";
import UserInfoUtil from "UserInfoUtil";
import AdobeUtil from "../../Utils/AdobeUtil";
import AdobeConstants from "../../Constants/AdobeConstants";
import BaseServiceApi from "BaseServiceApi";
import StringUtil from 'StringUtil';
import moment from "moment";
import ChangePassDialog from "../../RayCustomControls/AccountDetailsDialog/ChangePassDialog.jsx";

//import {map} from "underscore";
// import ProdServerURL from "ProdServerURL";
var EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const history = require("history").createHashHistory();
export default class LoginForm extends React.Component {
  constructor() {
    super();

    this.loginStateChange = this.loginStateChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.hideChangePassDialog = this.hideChangePassDialog.bind(this);
    this.saveChangePassDialog = this.saveChangePassDialog.bind(this);
    //this.dropDownClick = this.dropDownClick.bind(this);
    //this.handleChange = this.handleChange.bind(this);

    let lang = LocalizationStore.getLang();

    this.state = {
      isLoading: false,
      invalidUser: false,
      userSignIn: false,
      lang: lang,
      forcePasswordChange:false,
      currentPassword: '',
      passwordExpire: false,
      loginErrType: 0
      // prodServerName: ProdServerURL.PANARAY.name
      // checked: false
    };
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.loginStateChange);
    /*if (this.isProductionEnv()) {
      sessionStorage.setItem("prodServerURL", window.btoa(ProdServerURL.PANARAY.name));
      this.setState({
        prodServerName:ProdServerURL.PANARAY.name
      })
    }*/
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.loginStateChange);
  }

  loginStateChange() {
    let self = this;
    if (LoginStore.isUserLoggedIn()) {
      const entitlements = UserInfoUtil.getUserInfo().entitlements;
      if (entitlements && entitlements.includes(EntitlementType.ADVANCED_PASSWORD_SECURITY_Entitlement)) {
        const loginStoreState = LoginStore.getState();
        if(loginStoreState.loginErrType === "7"){
          AdobeUtil.DTMTrack({
            loginError: "Your temporary password need be change.",
            userType: "User"
          },
            AdobeConstants.LOGIN_FAILURE);
          self.setState({ isLoading: false, userSignIn: false, invalidUser: false, passwordExpire: false, forcePasswordChange: true, loginErrType: loginStoreState.loginErrType });
        }
        else if(UserInfoUtil.getUserInfo().userInfo && UserInfoUtil.getUserInfo().userInfo.passwordExpireDt){
          const date = moment(StringUtil.formatDate(UserInfoUtil.getUserInfo().userInfo.passwordExpireDt, "MM-DD-YYYY 00:00:00", true), "MM-DD-YYYY HH:mm:ss", true).toDate();
          if (new Date() > date) {
            AdobeUtil.DTMTrack({
              loginError: "Your password has expired.",
              userType: "User"
            },
              AdobeConstants.LOGIN_FAILURE);
              this.setState({ isLoading: false, userSignIn: false, invalidUser: false, passwordExpire: true, forcePasswordChange: true , currentPassword : UserInfoUtil.getUserInfo().userPassword});
            }
            else{
                  //Push data to DTM while if valid user
              AdobeUtil.DTMTrack({
                loginError: "",
                userType: "User",
                userID: UserInfoUtil.getUserInfo().userId
              },
                AdobeConstants.LOGIN_SUCCESS);

              LoginStore.isSignInProgress = true;
              self.setState({ userSignIn: true, invalidUser: false, passwordExpire: false, forcePasswordChange: false });
              history.push("/");
            }
        }
        else {
          //Push data to DTM while if valid user
          AdobeUtil.DTMTrack({
            loginError: "",
            userType: "User",
            userID: UserInfoUtil.getUserInfo().userId
          },
            AdobeConstants.LOGIN_SUCCESS);

          LoginStore.isSignInProgress = true;
          self.setState({ userSignIn: true, invalidUser: false, passwordExpire: false, forcePasswordChange: false });
          history.push("/");
        }
      }
      else {
        //Push data to DTM while if valid user
        AdobeUtil.DTMTrack({
          loginError: "",
          userType: "User",
          userID: UserInfoUtil.getUserInfo().userId
        },
          AdobeConstants.LOGIN_SUCCESS);

        LoginStore.isSignInProgress = true;
        self.setState({ userSignIn: true, invalidUser: false, passwordExpire: false, forcePasswordChange: false });
        history.push("/");
      }
    }
    else {
      //Push data to DTM while if invalid user
      AdobeUtil.DTMTrack({
        loginError: "Invalid User",
        userType: "User"
      },
        AdobeConstants.LOGIN_FAILURE);

      const loginStoreState = LoginStore.getState();

      this.setState({ isLoading: false, userSignIn: false, invalidUser: true , passwordExpire: false, forcePasswordChange: false
                    , isLoginHasErr: loginStoreState.isLoginHasErr, loginErrType: loginStoreState.loginErrType });
    }
  }


  handleClick() {
    //Push data to DTM while user clicks on the "SIGN IN"
    AdobeUtil.DTMTrack({
      loginError: "Invalid User",
      userType: "User"
    },
      AdobeConstants.LOGIN_CLICK);

    if (this.customValidationForm()) {
      let err = document.getElementById("Error");
      err.innerHTML = "";
      this.setState({ isLoading: true, invalidUser: false, passwordExpire: false, forcePasswordChange: false,
        loginErrType: 0 });
      let email = ReactDOM.findDOMNode(this.email).value;
      let password = ReactDOM.findDOMNode(this.password).value;
      //let rememberme = this.state.checked;
      LoginActions.validateUser({ email: email, password: password /*, rememberme: rememberme*/ });
    }
  }

  /*handleChange(e) {
    this.setState({ checked: !this.state.checked });
  }*/

  // Validation Function for Form
  customValidationForm() {
    this.setState({ invalidUser: false, passwordExpire: false, forcePasswordChange: false, loginErrType: 0 });
    let email = ReactDOM.findDOMNode(this.email).value;
    let password = ReactDOM.findDOMNode(this.password).value;
    let err = document.getElementById("Error");
    if (email == null || email == "") {
      //Push data to DTM while if invalid user
      AdobeUtil.DTMTrack({
        loginError: "Email is required field.",
        userType: "User"
      },
        AdobeConstants.LOGIN_FAILURE);

      err.innerHTML = LocalizationStore.getTranslatedData("RequiredEmail", "Email is required field.");
      return false;
    }
    else if (password == null || password == "") {
       //Push data to DTM while if invalid user
       AdobeUtil.DTMTrack({
        loginError: "Password is required field.",
        userType: "User"
      },
        AdobeConstants.LOGIN_FAILURE);

      err.innerHTML = LocalizationStore.getTranslatedData("RequiredPassword", "Password is required field.");
      return false;
    }
    else {
      return true;
    }
  }

  dropDownClick(value, e) {
    sessionStorage.setItem("prodServerURL",window.btoa(unescape(encodeURIComponent(value.trim()))));
    this.setState({
      prodServerName: e.target.innerHTML.trim()
    });
  }

  isProductionEnv() {
    let env = process.env.BRANCH_ENV;
    return (env && env.toUpperCase() == "PROD")
  }

  hideChangePassDialog() {
    this.setState({ forcePasswordChange: false });
  }

  saveChangePassDialog(currentPassword) {
    ReactDOM.findDOMNode(this.password).value= currentPassword;
    this.handleClick();
  }

  render() {
    const isLoading = this.state.isLoading;
    const invalidUser = this.state.invalidUser;
    const passwordExpire = this.state.passwordExpire;
    const forcePasswordChange = this.state.forcePasswordChange;
    const loginErrType = this.state.loginErrType;
    let errorMzsClassName = "errorMzs xx-small-normal";
    if(loginErrType === "5"){
      errorMzsClassName = "errorMzs-Lines xx-small-normal"
    }
    return (
      <div className="container">
        <div className="row">
          <div className="LoginBlock">
            <Form className={this.state.lang === LangType.ZH_CN ? "form-horizontal backgroundCN" : "form-horizontal"}>
              <div id="logo-container" className="large-margin-top medium-margin-bottom"></div>
              <FormGroup>
                <Col className={errorMzsClassName} >
                  {passwordExpire ?
                   <strong> <span> {LocalizationStore.getTranslatedData('PasswordExpired', "Your password has expired.")} </span>
                    <span> {LocalizationStore.getTranslatedData('ContactUs', "Contact us at")} <a className="email-support" href="mailto:support@williamoneil.com">support@williamoneil.com</a> </span></strong>
                  :""}
                  {invalidUser && !this.state.isLoginHasErr ?
                    <strong> {LocalizationStore.getTranslatedData("NotRecognizedAccount", "Email or Password not recognized, try again.")} </strong>
                    : ""}
                  {loginErrType === "5" ?
                    <strong> {LocalizationStore.getTranslatedData('AccountLocked', "Account locked for security purposes. To regain access, please check registered email for instructions or call (800) 419-6632 for assistance")}</strong>
                    : ""}
                  {loginErrType === "6" ?
                    <strong> {LocalizationStore.getTranslatedData('InvalidPassword', "Invalid temporary password. Please check your email and try again")}</strong>
                    :""}
                  {loginErrType === "3" ?
                    <strong> {LocalizationStore.getTranslatedData('PasswordNotStrongEnough', "Password is not strong enough.")} </strong>
                    : ""}
                  {loginErrType === "4" ?
                    <strong> {LocalizationStore.getTranslatedData('PasswordUsed', "Password has been used in the past.")}</strong>
                    : ""}
                  <strong id="Error"></strong>
                </Col>
              </FormGroup>
              <FormGroup controlId="formHorizontalEmail">
                <Col className="small-bold" componentClass={ControlLabel} sm={2}>
                  {LocalizationStore.getTranslatedData("Email", "Email")}
                </Col>
                <Col sm={10} className="small-margin-bottom">
                  <FormControl className="small-bold" type="email" placeholder={LocalizationStore.getTranslatedData("Email", "Email")} ref={(ref) => this.email = ref} required={true} />
                </Col>
              </FormGroup>

              <FormGroup controlId="formHorizontalPassword">
                <Col className="small-bold" componentClass={ControlLabel} sm={2}>
                  {LocalizationStore.getTranslatedData("Password", "Password")}
                </Col>
                <Col sm={10} className="small-margin-bottom">
                  <FormControl className="small-bold" type="password" placeholder={LocalizationStore.getTranslatedData("Password", "Password")} ref={(ref) => this.password = ref} required={true} />
                </Col>
              </FormGroup>

              {/*
                this.isProductionEnv() ?
                  <FormGroup controlId="formHorizontalSelect"  >
                    <Col className="small-bold " componentClass={ControlLabel} sm={2}>
                      {LocalizationStore.getTranslatedData("Server", "Server")}
                    </Col>
                    <Col sm={10} className="small-margin-bottom login-server-dropdown">
                       <DropdownButton className="small-bold " bsSize="xsmall" title={this.state.prodServerName} id="server-type" onSelect={(eKey, e) => this.dropDownClick(eKey, e)}>
                         {map(ProdServerURL, (itemURL, i) => {
                          return <MenuItem className={this.state.prodServerName == itemURL.name ? "login-server-list small-bold active" : "login-server-list small-bold"}  eventKey={itemURL.host} key={i}>{itemURL.name}</MenuItem>
                        })}

                      </DropdownButton>
                    </Col>
                  </FormGroup> : null
              */}

              {/*<FormGroup controlId="RememberMe" className="medium-margin-bottom">
                <Col smOffset={2} sm={10}>
                  {<input id="checkbox2" className=" custom-check small-normal" type="checkbox" name="checkbox" checked={this.state.checked} ref={(ref) => this.rememberme = ref} onChange={this.handleChange} /><label className="small-normal" htmlFor="checkbox2"><span></span>{LocalizationStore.getTranslatedData("RememberMe", "Remember Email and Password")}</label>}
                </Col>
              </FormGroup>*/}

              <FormGroup className="medium-margin-bottom">
                <Col smOffset={2} sm={10}></Col>
              </FormGroup>

              {/*<FormGroup>
                <Col className="text-center small-margin-bottom">
                  <span href="#" className={ this.state.lang === LangType.ZH_CN ? "versionCN x-small-normal" : "version x-small-normal"}>{LocalizationStore.getTranslatedData("_Version", "")} </span>
                </Col>
              </FormGroup>*/}



              {/* <FormGroup controlId="formHorizontalSelect">
              <Col componentClass={ControlLabel} sm={2}>
                Workspace
              </Col>
              <Col sm={10}>
                <FormControl componentClass="select" placeholder="select">
                  <option value="select">Default</option>
                </FormControl>
              </Col>
            </FormGroup>
              <FormGroup>
                <Col smOffset={2} sm={10}>
                  <AdvanceSetting />
                </Col>
              </FormGroup>*/}


              <hr className={this.state.lang === LangType.ZH_CN ? "hrCN" : ""}></hr>
              <FormGroup>
                <Col className="text-center  small-margin-top small-margin-bottom">
                  {!isLoading ?
                    <Button className="btnLogin medium-bold" type="submit"
                      onClick={this.handleClick}>
                      {LocalizationStore.getTranslatedData("Signin", "Sign in")}
                    </Button> :
                    <div className="heightLoader"> <img alt="" src="/Asset/images/loading.gif" /> </div>}
                </Col>
              </FormGroup>
            </Form>
          </div>
        </div>
        {passwordExpire || loginErrType === "7" ? <ChangePassDialog open ={forcePasswordChange} loginErrType = {loginErrType} hideChangePassDialog ={this.hideChangePassDialog}  saveChangePassDialog ={this.saveChangePassDialog} forcePasswordChange = {forcePasswordChange} currentPassword={this.state.currentPassword} />:""}
      </div>
    );
  }
}
LoginForm.contextTypes = {
  router: PropTypes.object
};
