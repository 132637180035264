import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import WhatsNewStore from "../../Stores/Help/WhatsNewStore.js";

export default class WhatsNewDialog extends Component {
    constructor() {
        super();
        this.state = {
            url: "",
            isOpen: false
        }
        this.state = WhatsNewStore.getState();
        this.closeLiveSupportDialog = this.closeLiveSupportDialog.bind(this);
        this.WhatsNewDialogStateChange = this.WhatsNewDialogStateChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        WhatsNewStore.addChangeListener(this.WhatsNewDialogStateChange);
    }

    UNSAFE_componentWillUnmount() {
        WhatsNewStore.removeChangeListener(this.WhatsNewDialogStateChange);
    }

    WhatsNewDialogStateChange() {
        const states = WhatsNewStore.getState();
        this.setState({
            url: states.url,
            isOpen: states.isOpen
        });
    }
    closeLiveSupportDialog() {
        this.setState({ isOpen: false });
        WhatsNewStore.setDialogState(false);
    }
    render() {
        return (
            Boolean(this.state.isOpen) ?
                <div className="whatsNew">
                    <Modal className="modal-popup whats-new" show={this.state.isOpen} onHide={this.closeLiveSupportDialog}>
                        <Modal.Header>
                            <Modal.Title>
                                <span className="whats-new-logo"></span>
                                <span className="dialog-header">PANARAY - What's New</span>
                                <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.closeLiveSupportDialog}><span className="icn-close"></span></button>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <iframe className="pdfFrame" frameBorder='no' marginWidth='0px' marginHeight='0px' ref={(ref) => this.frame = ref} src={this.state.url} />
                        </Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </div>
                :
                <div id="whatsNewDialog"></div>
        )
    }
}
