import React from 'react';
import moment from "moment";
import DateHelper from "DateHelper";
import ReactHtmlParser from "react-html-parser";
import textWidth from "text-width";

const HighlightText = ({ searchText, nameAbbr, name, quarterEndDate, carryForward, textSize, index }) => {
    // const searchText = self.props.searchText;

    let shouldHihglightText = false;

    const nameAbbrLower = nameAbbr.toLowerCase();

    const filter = new RegExp(' ', 'gi');

    searchText = searchText.replace(filter, '');

    searchText = searchText.toLowerCase().replace(' ', '');

    let indexList = [];

    if (searchText != "") {
        for (let i = 0; i < nameAbbrLower.length; i++) {
            if (nameAbbrLower[i] == searchText[0]) {
                let sIndex = i;
                let eIndex = i;
                if (searchText.length > 1) {
                    for (let j = 1; j < searchText.length; j++) {
                        while (nameAbbrLower[++i] == ' ');

                        if (nameAbbrLower[i] == searchText[j] && j != searchText.length - 1) {

                        }
                        else if (nameAbbrLower[i] == searchText[j]) {
                            eIndex = i;
                            indexList.push({ sIndex: sIndex, eIndex: eIndex });
                        }
                        else
                            break;
                    }
                }
                else {
                    indexList.push({ sIndex: sIndex, eIndex: eIndex });
                }
            }
        }

        if (indexList.length > 0) {

            let nameWidth = 0;
            let width = 0;
            let fullTextWidth = 0;
            const rightMigin = 15;
            let matchStr;
            let str1;


            if (document.getElementById("onershipName" + index)) {
                nameWidth = document.getElementById("onershipName" + index).offsetWidth - rightMigin;

                matchStr = nameAbbr.substr(indexList[0].sIndex, indexList[0].eIndex - indexList[0].sIndex + 1);

                str1 = nameAbbr.substr(0, indexList[0].sIndex) + matchStr;

                let textSizeInt;

                switch (textSize) {
                    case "xx-small-normal":
                        textSizeInt = 12;
                        break;
                    case "medium-normal":
                        textSizeInt = 16;
                        break;
                    case "large-normal":
                        textSizeInt = 20;
                        break;
                    default: textSizeInt = 12;
                        break;
                }

                width = textWidth(str1, {
                    family: "calibri",
                    size: textSizeInt
                });

                fullTextWidth = textWidth(nameAbbr, {
                    family: "calibri",
                    size: textSizeInt
                });
            }

            if (width <= nameWidth || fullTextWidth <= (nameWidth + rightMigin)) {

                for (let i = indexList.length - 1; i >= 0; i--) {

                    matchStr = nameAbbr.substr(indexList[i].sIndex, indexList[i].eIndex - indexList[i].sIndex + 1);

                    str1 = nameAbbr.substr(0, indexList[i].sIndex);
                    const str2 = nameAbbr.substr(indexList[i].sIndex)

                    nameAbbr = str1 + str2.replace(matchStr, '<span style="background-color:#BDDE7A; color:#000;">' + matchStr + '</span>');
                }
            }
            else {
                nameAbbr = '<span style="background-color:#BDDE7A; color:#000;>' + nameAbbr + '</span>';
            }

            shouldHihglightText = true;
        }
        else {
            if (carryForward != "0") {
                const d = moment("/Date(" + quarterEndDate + ")/");
                const str = DateHelper.getDateString(d._d);
                name += " as of " + str;
            }
            const nameLower = name.toLowerCase();

            for (let i = 0; i < nameLower.length; i++) {
                if (nameLower[i] == searchText[0]) {
                    let sIndex = i;
                    let eIndex = i;
                    if (searchText.length > 1) {
                        for (let j = 1; j < searchText.length; j++) {
                            while (nameLower[++i] == ' ');

                            if (nameLower[i] == searchText[j] && j != searchText.length - 1) {

                            }
                            else if (nameLower[i] == searchText[j]) {
                                eIndex = i;
                                indexList.push({ sIndex: sIndex, eIndex: eIndex });
                            }
                            else
                                break;
                        }
                    }
                    else {
                        indexList.push({ sIndex: sIndex, eIndex: eIndex });
                    }
                }
            }

            if (indexList.length > 0) {
                shouldHihglightText = true;
                nameAbbr = '<span style="background-color:#BDDE7A; color:#000;">' + nameAbbr + '</span>';
            }

        }
    }

    // if (searchText != "") {
    //     const filter = new RegExp(searchText, 'gi');
    //     nameAbbr = nameAbbr && nameAbbr.replace(filter, '<span style="color: yellow"> ' + searchText + '</span>');
    // }

    return (
        shouldHihglightText ?
            <span>{ReactHtmlParser(nameAbbr)}</span> :
            <span>{nameAbbr}</span>
    )
}

export default HighlightText;
