import {
    getNextAvailableList,
    getLatestNode,
    getParentId,
    getParentNodeId,
    sortDataSource,
    updateListExplorer,
    expandAndFindParentNode,
    getValidParentItem,
    getSelectedDBType,
    findNodeById,
    getNextNode,
    getListInFavorite,
    getDeletedNode,
    getListByCategory,
    getFavList,
    validateListName
} from "../Utils/OwnerShipUtil.js";

import { setActiveNodeToOwnershipStore, showAlert } from '../Actions/OwnerShipActions';
import { addToSourceList,renameExternalList,nodeSelection,sortSourceList } from '../Actions/ExternalDataUploaderActions';

import { createAction } from 'redux-actions';
import ListApi from "ListApi";
import ListStore from "ListStore";
import ListExplorerStore from "ListExplorerStore";
import ListActions from "ListActions";
import BrowserUtil from "BrowserUtil";
import ListExplorerUtil from "../Utils/ListExplorerUtil";
import LocalizationStore from 'LocalizationStore';

import StringUtil from "StringUtil";
import { extend, filter, clone } from "underscore";

import BaseServiceApi from 'BaseServiceApi';
const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const ListType = BaseServiceApi.rayData["ListType"];
import { IsDeleteEnable } from "./ListActions";
import OwnershipViewStore from "OwnershipViewStore";

export const SET_SELECTED_NODE = 'SET_SELECTED_NODE';
export const setSelectedNode = createAction(SET_SELECTED_NODE);

export const SET_DELETE_FOLDER = 'SET_DELETE_FOLDER';
export const setDeleteFolder = createAction(SET_DELETE_FOLDER);

export const SWITCH_LiST = 'SWITCH_LiST';
export const switchNode = createAction(SWITCH_LiST);

export const IS_UPDATED_OWNERSHIP = 'IS_UPDATED_OWNERSHIP';
export const updateOwnership = createAction(IS_UPDATED_OWNERSHIP);

export const SET_SOURCE_LIST = 'SET_SOURCE_LIST';
export const sourceList = createAction(SET_SOURCE_LIST);

export const SET_DUPLICATE_ALERT = 'SET_DUPLICATE_ALERT';
export const setDuplicateAlert = createAction(SET_DUPLICATE_ALERT);

export const SET_RENAME_NODE = 'SET_RENAME_NODE';
export const setRenameNode = createAction(SET_RENAME_NODE);

export const SET_ACTIVE_NODE = 'SET_ACTIVE_NODE';
export const setNewActiveNode = createAction(SET_ACTIVE_NODE);

export const SET_IS_ACTIVE_NODE_CHANGED = 'SET_IS_ACTIVE_NODE_CHANGED';
export const setActiveNodeChanged = createAction(SET_IS_ACTIVE_NODE_CHANGED);

export const setActiveNode = (activeNode) => (dispatch, getState) => {
    const state = getState();
    if (activeNode === "-2")
        activeNode = -2;
    const currentActiveNode = state.listView.activeNode;
    const isActiveNodeChanged = currentActiveNode !== activeNode;
    const dataSource = state.listView.dataSource.list;

    expandAndFindParentNode(activeNode, dataSource);
    dispatch(setNewActiveNode(activeNode));
    dispatch(setActiveNodeChanged(isActiveNodeChanged));
}

export const SET_FOLDER_ACTIVE_NODE = 'SET_FOLDER_ACTIVE_NODE';
export const setFolderActiveNode = createAction(SET_FOLDER_ACTIVE_NODE);

export const SET_DRAG_NODE = 'SET_DRAG_NODE';
export const setDragNode = createAction(SET_DRAG_NODE);

export const SET_SELECTED_NODE_DETAILS = 'SET_SELECTED_NODE_DETAILS';
export const setSelectedNodeDetails = createAction(SET_SELECTED_NODE_DETAILS);

export const SET_DUPLICATE_NODE = 'SET_DUPLICATE_NODE';
export const setDuplicateNode = createAction(SET_DUPLICATE_NODE);

export const RENAME_NODE = 'RENAME_NODE';
export const saveRenameNode = (nodeId, nodeName, parentNodeId) => (dispatch, getState) => {
    const state = getState();
    const dataSource = state.listView.dataSource.list;
    dispatch({
        type: RENAME_NODE,
        payload: ListApi.getUpdateListNode(nodeId, nodeName, parentNodeId).then(() => {
            const selectedListItem = ListStore.getSelectedListItem();
            if(selectedListItem.SelectedListId==nodeId)
            ListActions.listRename(nodeName); 
            dispatch(setRenameNode(''));
            sortDataSource(dataSource);
            ListExplorerStore.updateDataSource(dataSource,false);
            const favList = getFavList(nodeId,dataSource);
            extend(favList,{name: nodeName})
            dispatch(sourceList(dataSource));
        }
        )
    });
}

export const saveRenameNode1 = (nodeId, nodeName, parentNodeId, dragNodeID) => (dispatch, getState) => {    
    const state = getState();
    let dataSource = state.listView.dataSource.list;
    dispatch({
        type: RENAME_NODE,
        payload: ListApi.getUpdateListNode(nodeId, nodeName, parentNodeId).then((result) => {
            const duplicateNode = result.data;
            // if (!StringUtil.isEmpty(rowslist.sourceCategoryType))
            //     duplicateNode.sourceCategoryType = rowslist.sourceCategoryType;
            extend(duplicateNode, { sourceParentCategoryName: null });
            dataSource = updateListExplorer(dataSource, duplicateNode, parentNodeId);

            extend(dragNodeID, { listTreeItemLevel: 0, isVisible: false });
            sortDataSource(dataSource);
            ListExplorerStore.updateDataSource(dataSource,false);
            dispatch(sourceList(dataSource));
            expandAndFindParentNode(duplicateNode.nodeId.low, dataSource);
            dispatch(setRenameNode(''));
        }
        )
    });
}

export const DELETE_NODE = 'DELETE_NODE';
export const saveDeleteNode = (activeNode, nodeToDelete, list) => (dispatch, getState) => {
    let selectedNode = '';
    let NodeToFindNextNode = '';
    const deletedNode = [];

    nodeToDelete = nodeToDelete.toString();

    if (nodeToDelete.includes(";")) {
        selectedNode = nodeToDelete.split(";");
        let i = 0;
        while (i < selectedNode.length) {
            deletedNode[i] = getLatestNode(parseInt(selectedNode[i]), list)
            i++;
        }
    }
    else {
        deletedNode[0] = getLatestNode(parseInt(nodeToDelete), list);
    }

    if (!activeNode) {
        const state = getState();
        list = state.listView.dataSource.list;
        // var alertMessage = `${LocalizationStore.getTranslatedData(duplicateNode.name)}  ${LocalizationStore.getTranslatedData('list_deleted', 'was duplicated')}.`;

        if (selectedNode.length > 1) {
            NodeToFindNextNode = selectedNode[0];
            const currentNode = getParentId(parseInt(NodeToFindNextNode), list);
            activeNode = getNextAvailableList(currentNode.nodeId.low, list, currentNode);

        }
        else {
            const currentNode = getParentId(state.listView.activeNode, list);
            currentNode.isVisible = false;
            nodeToDelete = currentNode.nodeId;
            activeNode = getNextAvailableList(nodeToDelete, list, currentNode);
        }
    }    
    BrowserUtil.disableEvents();
    
    dispatch({
        type: DELETE_NODE,
        payload: ListApi.getDeleteTreeNode(nodeToDelete).then((result) => {      
        if (!result.responseHeader.error) {               
                const state = getState();
                const dataSource = state.listView.dataSource.list;
                setActiveNodeToOwnershipStore(activeNode);

                dispatch(setActiveNode(activeNode.nodeId.low));
                expandAndFindParentNode(activeNode.nodeId.low, list);
                dispatch(sourceList(list));
                ListExplorerStore.updateDataSource(dataSource,false);
                dispatch(setSelectedNodeDetails(deletedNode));
                let alertMessage = ""
                let isSourceNodeDelete = false;
                if(deletedNode.length === 2 && deletedNode[0].categoryType === ListCategoryType.FAVORITE_ListCategory 
                    && deletedNode[1].categoryType !== ListCategoryType.FAVORITE_ListCategory
                    && deletedNode[0].sourceListId && deletedNode[0].sourceListId.low === deletedNode[1].nodeId.low){
                        isSourceNodeDelete = true;
                }
                if (deletedNode.length === 1) {
                    if(deletedNode[0].categoryType === ListCategoryType.FAVORITE_ListCategory)
                    alertMessage = `${LocalizationStore.getTranslatedData(deletedNode[0].name)} was removed from FAVORITES.`;
                    else
                    alertMessage = `${LocalizationStore.getTranslatedData(deletedNode[0].name)}  ${LocalizationStore.getTranslatedData('list_deleted', 'was deleted')}.`;
                }
                else if(isSourceNodeDelete){
                    alertMessage = `${LocalizationStore.getTranslatedData(deletedNode[1].name)}  ${LocalizationStore.getTranslatedData('list_deleted', 'was deleted')}.`;
                }
                else {
                    alertMessage = `${deletedNode.length} Lists are deleted`;
                }
                if(isSourceNodeDelete){
                    const sourceDeletedNode = deletedNode.filter((item) => item != null && item.categoryType !== ListCategoryType.FAVORITE_ListCategory);
                    // showAlert(alertMessage, { node: sourceDeletedNode, dataSource: dataSource })
                    showAlert(alertMessage, { node: deletedNode, dataSource: dataSource })
                    IsDeleteEnable(list, activeNode)
                }
                else if (deletedNode[0].categoryType === ListCategoryType.FAVORITE_ListCategory && deletedNode[0].listType !== ListType.FOLDER_List) {
                    showAlert(alertMessage, { node: deletedNode, dataSource: dataSource })
                    IsDeleteEnable(list, activeNode);
                }
                else {
                    showAlert(alertMessage, { node: deletedNode, dataSource: dataSource })
                    IsDeleteEnable(list, activeNode)
                }
                BrowserUtil.enableEvents();
            }
        }
        )
    });
}

export const createNewList = (type, categoryType, selectedTab,isOwnershipBlock=false) => (dispatch, getState) => {    
    const state = getState();
    const dbType = getSelectedDBType(selectedTab);
    let dataSource = state.listView.dataSource.list;
    if(isOwnershipBlock){        
        if(dataSource.length==0)
        dataSource = ListExplorerStore.ownershipDatasource 
    }       
    const parentNode = getValidParentItem(categoryType, dataSource);    
    const currentName = LocalizationStore.getTranslatedData('LM_TP_Untitled_1', 'Untitled {0}');
    const defaultValueForInput = (type === 'NewList') ? currentName.replace('{0}', LocalizationStore.getTranslatedData('LM_TP_list', 'List')) : currentName.replace('{0}', LocalizationStore.getTranslatedData('LM_TP_Folder', 'Folder'));
    const nodeType = (type === 'NewList') ? 1 : 2;    
    let parentNodeDetails,listParentNode= '';
    (state.listView.activeFolderID !== "") ? 
     parentNodeDetails = getParentId(state.listView.activeFolderID.low, dataSource) :
     parentNodeDetails = getParentId(parentNode.nodeId.low, dataSource)
     const newNodeTreeLevel = parentNodeDetails.listTreeItemLevel+1;
     (parentNodeDetails.listCategory === categoryType && state.listView.activeFolderID !== "") ? listParentNode =state.listView.activeFolderID : listParentNode = parentNode.nodeId;
    const data = {
        dbType: dbType,
        parentNodeId: listParentNode,
        name: defaultValueForInput,
        nodeType: nodeType,
        categoryType: parentNode.categoryType,
        sourceListId: null,
        listTreeItemLevel : newNodeTreeLevel
    };
    
    dispatch({
        payload: ListApi.getCreateTreeNode(data).then((result) => {            
            const newNode = result.data;
            newNode.listTreeItemLevel = data.listTreeItemLevel;
            newNode.sourceCategoryType = ListCategoryType.USER_ListCategory;
            extend(self.newNode, { sourceParentCategoryName: null });
            dataSource = updateListExplorer(dataSource, newNode, data.parentNodeId,data.listTreeItemLevel);
            sortDataSource(dataSource);
            expandAndFindParentNode(newNode.nodeId.low, dataSource);
            dispatch(sourceList(dataSource));
            ListExplorerStore.updateDataSource(dataSource,false);
            if(isOwnershipBlock){
                ListExplorerUtil.getScrollPosition(newNode.nodeId.low);
                dispatch(updateOwnership(false));
            }            
            dispatch(setRenameNode(newNode.nodeId.low));            
        }
        )
    });

}

export const createNewExternalList = (type,selectedTab) => (dispatch, getState) => {
    const state = getState();
    const dbType = getSelectedDBType(selectedTab);
    let dataSource = state.externalDataUploader.ExternalDataUploaderReducer.explorerListItems;
    
    let currentName = LocalizationStore.getTranslatedData('LM_TP_Untitled_1', 'Untitled {0}');
    let defaultValueForInput = currentName.replace('{0}', LocalizationStore.getTranslatedData('LM_TP_list', 'Data Set'));
    let nodesToSearch = state.externalDataUploader.ExternalDataUploaderReducer.explorerListItems.find(ele => ele.listType == type);
    defaultValueForInput = defaultValueForInput ? defaultValueForInput : "Untitled Data Set";
    defaultValueForInput = nodesToSearch ? ListExplorerStore.validateExternalListName(nodesToSearch.childNodes, defaultValueForInput) : defaultValueForInput;

    const data = {
        dbType: dbType,
        name: defaultValueForInput,
        nodeType: type,
        parentNodeId: -1,
        categoryType: 20,
        sourceListId: ''
    };
    dispatch({
        payload: ListApi.getCreateTreeNode(data).then((result) => {       
            const newNode = result.data;
            // expandAndFindParentNode(newNode.nodeId.low, dataSource);
            dispatch(addToSourceList(newNode));
            dispatch(sortSourceList());
            // const NodeToRename = ListStore.activeExternalNode;
            // NodeToRename.listName = newNode.name;
            // NodeToRename.listId = newNode.nodeId.low;
            // dispatch(nodeSelection(NodeToRename));
            dispatch(renameExternalList(newNode.nodeId.low,type));
        })
    });

}
// delete selected  items

export const deleteSelectedItem = () => (dispatch, getState) => {
    const state = getState();
    let selectedNodes = "";
    let nextnode;
    // let nodeIdToMove = '';
    let nodeIddToMove;
    let selectedNode = state.listView.selectedNode;
    const activeNode = state.listView.activeNode;
    const list = state.listView.dataSource.list;
    const rowslist = getParentId(parseInt(activeNode), list);
    const isActionOnActiveNode = rowslist.nodeId.low === activeNode;
    selectedNode = `${selectedNode},${activeNode}`;
    selectedNode = selectedNode.split(",");
    let favArray=[], favArrayIDs=[], finalNodes=null
    if (rowslist.categoryType === ListCategoryType.USER_ListCategory || rowslist.categoryType === ListCategoryType.FAVORITE_ListCategory || rowslist.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        if (selectedNode.length > 2) {
            let selnodes = selectedNode.filter((item) => item != '').reverse();
            for (let index = 0; index < selnodes.length; index++) {
                nextnode = getNextNode(isActionOnActiveNode, rowslist.nodeId.low, rowslist, list, activeNode);
                const FavList = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, parseInt(selnodes[index]));
                            // if (FavList) {
                            //     FavList.isVisible = false;
                            //     dispatch(saveDeleteNode(false, FavList.nodeId.low, list));
                            // }
                            if (FavList) {
                                FavList.isVisible = false;
                                favArray.push(FavList)
                                favArrayIDs.push(` ${FavList?.nodeId?.low}`)
                            }
                               
                nodeIddToMove = getParentId(parseInt(selnodes[index]), list);
                if (nodeIddToMove) {
                    nodeIddToMove.isVisible = false;
                }
            }
            if(favArrayIDs?.length){
                finalNodes=[...selnodes]
                finalNodes=[...finalNodes , ...favArrayIDs]
                selnodes=[...finalNodes]
            } 
            selectedNodes = selnodes?.join('; ');
            dispatch(saveDeleteNode(false, selectedNodes, list));
        }
        else {
            rowslist.isVisible = false;
            nextnode = getNextNode(isActionOnActiveNode, rowslist.nodeId.low, rowslist, list, activeNode);
            const FavList = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, rowslist.nodeId.low);
                        if (FavList) {
                            const currentNode = getParentId(activeNode, list);
                            FavList.isVisible = false;
                            if(currentNode && currentNode.sourceListId){
                                nextnode = getNextNode(currentNode.sourceListId.low === rowslist.nodeId.low, currentNode.nodeId.low, currentNode, list, activeNode);
                            }
                            else{
                                nextnode = getNextNode(isActionOnActiveNode, currentNode.nodeId.low, currentNode, list, activeNode);
                            }
                            dispatch(saveDeleteNode(nextnode, `${FavList?.nodeId?.low};${rowslist?.nodeId?.low}`, list));
                        }
                        else{
                            dispatch(saveDeleteNode(nextnode, rowslist?.nodeId?.low, list));
                        }
        }
    }
}

export const DUPLICATE_NODE = 'DUPLICATE_NODE';
export const saveDuplicateNode = (rowslist, target, dataSource, source, alertMessage = '', listItemsToAdd = []) => (dispatch, getState) => {
    let basicEsInfo = OwnershipViewStore?.getState()?.basicEsInfo;
    const data = [];
    let isFlagList = false;
    let isParentDeleted = false;
    const targestNode = getParentId(parseInt(target), dataSource);

    if (target && source !== 'UNDO' && source !== 'DUPLICATE') {
        if (listItemsToAdd.length > 0) {
            if (targestNode.parentCategoryType) {
                if (listItemsToAdd[0].parentCategoryType === targestNode.parentCategoryType) {
                    isParentDeleted = true;
                }
            }
            else {
                if (listItemsToAdd[0].parentCategoryType === targestNode.categoryType) {
                    isParentDeleted = true;
                }
            }
        }
        else {
            if (targestNode.parentCategoryType) {
                if (rowslist.parentCategoryType === targestNode.parentCategoryType) {
                    isParentDeleted = true;
                }
            }
            else {
                if (rowslist.parentCategoryType === targestNode.categoryType) {
                    isParentDeleted = true;
                }
            }
        }
    }
    if (!rowslist) {
        const state = getState();
        dataSource = state.listView.dataSource.list;
        rowslist = getParentId(state.listView.activeNode, dataSource);
        target = getParentNodeId(ListCategoryType.USER_ListCategory, dataSource, rowslist);
        if (!source)
            source = 'DUPLICATE';
    }
    if ((rowslist.categoryType === ListCategoryType.SMARTLIST_ListCategory || (rowslist.categoryType === ListCategoryType.FAVORITE_ListCategory && rowslist.sourceCategoryType && rowslist.sourceCategoryType === ListCategoryType.SMARTLIST_ListCategory)) && rowslist.name === "Flagged Owners") {
        isFlagList = true;
    }
    if (listItemsToAdd.length > 1) {
        //listItemsToAdd.map((item) => {
        for (let i = 0; i < listItemsToAdd.length; i++) {
            const item = listItemsToAdd[i];
            data.push({
                symCode: "",
                parentNodeId: target,
                name: item.name,
                actualListId: item.sourceListId !==null ? item.sourceListId.low : item.nodeId.low,
                sourceListId: item.nodeId.low,
                destListId: "",
                isFlagList: isFlagList,
                cacheId: OwnershipViewStore.getScreenOnOffValue()? "1":"0" ,
                sortCols: null,
                listType: item.listType,
                isFav: source === 'ADDFAVORITE'
            });
        }
        //});
    }
    else {
        data.push({
            symCode: "",
            parentNodeId: target,
            name: rowslist.name,
            actualListId: rowslist.sourceListId !==null? rowslist.sourceListId.low : rowslist.nodeId.low,
            sourceListId: rowslist.nodeId.low,
            destListId: "",
            isFlagList: isFlagList,
            cacheId: OwnershipViewStore.getScreenOnOffValue()? "1":"0" ,
            sortCols: null,
            listType: rowslist.listType,
            isFav: source === 'ADDFAVORITE'
        });
        //sourceListId: rowslist.categoryType !== ListCategoryType.FAVORITE_ListCategory || rowslist.listType === ListType.FOLDER_List ? rowslist.nodeId.low : rowslist.sourceListId.low ,
    }

    if (!alertMessage && rowslist.categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        if (data.length > 1) {
            if (source === 'DUPLICATE' || source === 'DR-DP') {
                if (source === 'DUPLICATE') {
                    alertMessage = `${data.length} lists  ${LocalizationStore.getTranslatedData('list_duplicated', 'were duplicated')}.`;
                }
                else {
                    if (isParentDeleted)
                        alertMessage = `${data.length} list  ${LocalizationStore.getTranslatedData('list_duplicated', 'were moved to')} ${targestNode.name}.`;
                    else
                        alertMessage = `${data.length} list  ${LocalizationStore.getTranslatedData('list_duplicated', 'were copied to')} ${targestNode.name}.`;
                }
            }
            else {
                alertMessage = `${data.length} list  ${LocalizationStore.getTranslatedData('list_favorite', 'were added to')} ${targestNode.name}.`;
            }
        }
        else {
            if (source === 'DUPLICATE' || source === 'DR-DP') {
                if (source === 'DUPLICATE') {
                    alertMessage = `${LocalizationStore.getTranslatedData(data[0].name)}  ${LocalizationStore.getTranslatedData('list_duplicated', 'was duplicated')}.`;
                }
                else {
                    if (isParentDeleted)
                        alertMessage = `${LocalizationStore.getTranslatedData(data[0].name)}  ${LocalizationStore.getTranslatedData('list_duplicated', 'was moved to')} ${targestNode.name}.`;
                    else
                        alertMessage = `${LocalizationStore.getTranslatedData(data[0].name)}  ${LocalizationStore.getTranslatedData('list_duplicated', 'was copied to')} ${targestNode.name}.`;
                }
            }
            else if (source === 'SAVESCREEN') {
                alertMessage = `${LocalizationStore.getTranslatedData('list_deleted', 'Result saved as a new list')}.`;
            }
            else {
                alertMessage = `${LocalizationStore.getTranslatedData(data[0].name)}  ${LocalizationStore.getTranslatedData('list_favorite', 'was added to')} ${targestNode.name}.`;
            }
        }
    }
    let index = 0;
    BrowserUtil.disableEvents();
    data.map(async (dataitem) => {
        let duplicateNode = [];
        let result = undefined;
        if (isParentDeleted) {
            dataitem.name = validateListName(dataitem.name, dataitem.parentNodeId, dataSource, dataitem.listType)
            result = await ListApi.getUpdateListNode(dataitem.sourceListId, dataitem.name, dataitem.parentNodeId);
        }
        else {
            if (source === 'UNDO')
                result = await ListApi.restoreTreeNode(dataitem.sourceListId)
            else
              {  let item = JSON.parse(JSON.stringify(dataitem));
                item.sourceListId = item.actualListId;
                if(source === 'SAVESCREEN'){
                    result = await ListApi.getDuplicateList(item, false, null ,basicEsInfo?.searchText || null)
                }
                else{
                    result = await ListApi.getDuplicateList(item, false, null)
                }

        }
    }
        duplicateNode = result.data;
        if (!duplicateNode)
            duplicateNode = rowslist;
        if (!StringUtil.isEmpty(rowslist.sourceCategoryType))
            duplicateNode.sourceCategoryType = rowslist.sourceCategoryType;
        extend(duplicateNode, { sourceParentCategoryName: null });

        let deletedNode = '', addedNode = '';
        if (listItemsToAdd.length > 0) {
            deletedNode = listItemsToAdd[index];
            addedNode = clone(deletedNode)
        }
        else {
            deletedNode = duplicateNode;
            addedNode = clone(duplicateNode);
        }
        addedNode.parentNodeId = duplicateNode.parentNodeId;
        addedNode.name = duplicateNode.name;        
        addedNode.nodeId = duplicateNode.nodeId;
        if (source === 'UNDO') {
            if(deletedNode.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory){
                deletedNode = getDeletedNode(deletedNode.nodeId.low, deletedNode.nodeId.low, dataSource);
            }else {
                deletedNode = getDeletedNode(deletedNode.nodeId.low, deletedNode.parentNodeId.low, dataSource);
            }
            extend(deletedNode, { isVisible: true });
        }
        else if (deletedNode.listType === ListType.FOLDER_List){
            addedNode.listTreeItemLevel = targestNode.listTreeItemLevel+1;
            dataSource = updateListExplorer(dataSource, addedNode, dataitem.parentNodeId,targestNode.listTreeItemLevel+1);
        }else
            dataSource = updateListExplorer(dataSource, duplicateNode, dataitem.parentNodeId);

        index++;

        if (isParentDeleted)
            extend(deletedNode, { listTreeItemLevel: 0, isVisible: false });
            const dataSourceList = expandAndFindParentNode(duplicateNode.nodeId.low, sortDataSource(dataSource));

            if(duplicateNode.categoryType == ListCategoryType.USER_ListCategory)
            extend(duplicateNode, {sourceListId:duplicateNode.nodeId});

            if(duplicateNode.categoryType == ListCategoryType.FAVORITE_ListCategory ){
     extend(duplicateNode,{sourceListId:rowslist.sourceListId == null?duplicateNode.sourceListId:rowslist.sourceListId,
        sourceCategoryType:rowslist.sourceCategoryType == null? rowslist.categoryType:rowslist.sourceCategoryType});
        }
          
        
        if (source === 'DUPLICATE' || source === 'SAVESCREEN') { 
            dispatch(setRenameNode(duplicateNode.nodeId.low));
            dispatch(setDuplicateNode(duplicateNode.name));
        }

        if(index == data.length){
            dispatch(sourceList(dataSourceList));
            dispatch(setSelectedNode(""));

            if (source !== 'UNDO')
            showAlert(alertMessage, null, dispatch);

            BrowserUtil.enableEvents();
        }
    });
}

// Find Node by Id in category and update date and datasource
export const findNodeByIdAndUpdateDate = (nodeId, asOfDate) => (dispatch, getState) => {
    // let self = this;
    let searchValue = null;
    const state = getState();
    const dataSource = state.dataSource;
    const filteredDataSource = filter(dataSource, (item) => {
        (item.categoryType === parseInt(ListCategoryType.USER_ListCategory) || item.categoryType === parseInt(ListCategoryType.SCHEDULESCREENLIST_ListCategory) || item.categoryType === parseInt(ListCategoryType.FAVORITE_ListCategory) || parseInt(ListCategoryType.PORTFOLIOLIST_ListCategory || parseInt(ListCategoryType.SHAREDLIST_ListCategory)))
    });
    if (!StringUtil.isEmpty(filteredDataSource) && filteredDataSource.length > 0) {
        //filteredDataSource.map((item) => {
        for (i = 0; i < filteredDataSource.length; i++) {
            const item = filteredDataSource[i];
            if (item.categoryType === parseInt(ListCategoryType.USER_ListCategory) || item.categoryType === parseInt(ListCategoryType.SCHEDULESCREENLIST_ListCategory)) {
                searchValue = findNodeById(nodeId, item);
            }

            if (item.categoryType === parseInt(ListCategoryType.FAVORITE_ListCategory)) {
                searchValue = getListInFavorite(item.childNodes, nodeId);
            }

            if (searchValue !== false)
                extend(searchValue, { updateDt: asOfDate });
        }
        // });
        dispatch(sourceList(dataSource));
    }
}
