import React, { Component, Fragment } from "react";
import { Modal, Row, Col, ControlLabel, FormGroup } from "react-bootstrap";
import AlertActions from 'AlertActions';
import AlertStore from 'AlertStore';
import { AlertConstants } from 'AlertConstants'
import AlertSourceType from "../../Constants/AlertSourceType";
import AlertConditionType from "../../Constants/AlertConditionType";
import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions.js";
import MenuDialogType from "../../Constants/MenuDialogType.js";
import FlexibleModal from "../FlexibleModal.jsx";
import AlertsDialog from "./AlertsDialog.jsx";
import _ from "underscore";
import GeographyAlertDialog from "./GeographyAlertDialog.jsx";
import AlertColumnSet from "./AlertColumnSet.jsx";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import NotificationContentContainer from './NotificationContentContainer';
import { connect } from "react-redux";
import StringUtil from "StringUtil";

class AndAlertsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            msId: this.props.msId,
            instrumentId: this.props.instrumentId,
            symbol: this.props.symbol,
            sourceTypeId: this.props.sourceTypeId,
            listAlertSettings: {},
            priceAlertObj: null,
            openAlertCriteria: null,
            showLoader: true,
            columnSetString: AlertStore.getSelectedColumnSetString(),
            openColumnSet: null,
            openGeography: null,
            browserFilterText: AlertStore.getBrowserFilterText(),
            existAlerts: this.props.existAlerts,
            isTriggered : this.props.isTriggered,
            instrumentType : this.props.instrumentType,
            currencyCode : this.props.currencyCode
        }

        this.handleAlertStoreStateChangeSelectColumnSet = this.handleAlertStoreStateChangeSelectColumnSet.bind(this);
        this.handleAlertStoreStateChangeSelectFilterBrowser = this.handleAlertStoreStateChangeSelectFilterBrowser.bind(this);
        this.openGeographyWindow = this.openGeographyWindow.bind(this);
        this.openColumnSetWindow = this.openColumnSetWindow.bind(this);
        this.closeColumnSetWindow = this.closeColumnSetWindow.bind(this);
        this.closeGeographyWindow = this.closeGeographyWindow.bind(this);
        this.getXPosition = this.getXPosition.bind(this);
        this.getYPosition = this.getYPosition.bind(this);
        this.handleAlertStoreStateChangeOpenAlert = this.handleAlertStoreStateChangeOpenAlert.bind(this);
        this.handleAlertStoreStateChangeSaveAlertData = this.handleAlertStoreStateChangeSaveAlertData.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onNotificationPreferenceClick = this.onNotificationPreferenceClick.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.saveAlertData = this.saveAlertData.bind(this);
        this.getCriteriaStr = this.getCriteriaStr.bind(this);
        this.doneBtnClick = this.doneBtnClick.bind(this);
        this.openAlertCriteriaWindow = this.openAlertCriteriaWindow.bind(this);
        this.closeAlertCriteriaWindow = this.closeAlertCriteriaWindow.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.setState({
            msId: this.props.msId,
            instrumentId: this.props.instrumentId,
            sourceTypeId: this.props.sourceTypeId,
            isTriggered : this.props.isTriggered
        });

        this.editingAlertTypeId = this.props.alertTypeId;

        AlertStore.addChangeListener(this.handleAlertStoreStateChangeOpenAlert, AlertConstants.ActionTypes.OPEN_AND_ALERT);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSaveAlertData, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSelectColumnSet, AlertConstants.ActionTypes.SELECT_ALERT_COLUMN_SET);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSelectFilterBrowser, AlertConstants.ActionTypes.SELECT_FILTER_BROWSER);
        if(!StringUtil.isEmpty(this.props.SymbolInfo))
            AlertActions.openAndAlert(this.state.msId, this.state.instrumentId, this.state.symbol, this.state.sourceTypeId, this.props.alertId, this.state.currencyCode ? this.state.currencyCode : this.props.SymbolInfo.DefaultCurrency.CurrencyCode ? this.props.SymbolInfo.DefaultCurrency.CurrencyCode : this.props.SymbolInfo.DefaultCurrency.Code.Code, this.state.existAlerts);
        else
            AlertActions.openAndAlert(this.state.msId, this.state.instrumentId, this.state.symbol, this.state.sourceTypeId, this.props.alertId, null, this.state.existAlerts);
    }

    componentWillUnmount() {
        AlertStore.removeChangeListener(this.handleAlertStoreStateChangeOpenAlert, AlertConstants.ActionTypes.OPEN_AND_ALERT);
        AlertStore.removeChangeListener(this.handleAlertStoreStateChangeSaveAlertData, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSelectColumnSet, AlertConstants.ActionTypes.SELECT_ALERT_COLUMN_SET);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSelectFilterBrowser, AlertConstants.ActionTypes.SELECT_FILTER_BROWSER);
    }

    handleAlertStoreStateChangeSelectColumnSet () {
        this.setState(
            { columnSetString: AlertStore.getSelectedColumnSetString() }
        );
    }

    handleAlertStoreStateChangeSelectFilterBrowser () {
        this.setState(
            { browserFilterText: AlertStore.getBrowserFilterText() }
        );
    }
    openGeographyWindow () {
        let left = document.getElementById("modalBody").getBoundingClientRect().left + 69;
        let top = document.getElementById("modalBody").getBoundingClientRect().top + 15;

        let openGeography = <GeographyAlertDialog left={left} top={top} closeGeographyWindow={this.closeGeographyWindow} existAlerts={this.state.existAlerts}/>
        this.setState({ openGeography: openGeography });
        setTimeout(function(){
            document.getElementsByClassName("alert-geography")[0].classList.add("slide-down");
        },50)
    }

    openColumnSetWindow () {
        let left = document.getElementById("modalBody").getBoundingClientRect().left + 69;
        let top = document.getElementById("modalBody").getBoundingClientRect().top + 15;

        let openColumnSet = <AlertColumnSet left={left} top={top} closeColumnSetWindow={this.closeColumnSetWindow} />
        this.setState({ openColumnSet: openColumnSet });
        setTimeout(function(){
            console.log(document.getElementsByClassName("alert-columnset"));
            document.getElementsByClassName("alert-columnset")[0].classList.add("slide-down");
        },500)
    }

    closeColumnSetWindow () {
        let self = this;

        document.getElementsByClassName("alert-columnset")[0].classList.remove("slide-down");
        setTimeout(function(){
            self.setState({ openColumnSet: null })
        },450)
    }

    closeGeographyWindow ()  {
        let self = this;

        document.getElementsByClassName("alert-geography")[0].classList.remove("slide-down");
        setTimeout(function(){
            self.setState({ openGeography: null });
        },450)
    }

    getXPosition () {
        let x = 0;
        let w = 460;

        if (this.props.x) {
            x = this.props.x - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2
        }

        if (x < 0)
            x = 0;

        return x;
    }

    getYPosition () {
        let y = 0;
        let h = 400;

        // if (this.state.sourceTypeId == AlertSourceType.List)
        //     h = 600;

        if (this.props.y) {
            y = this.props.y - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2
        }

        if (y < 0)
            y = 0;

        return y;
    }

    //Alert Store change listener - Start

    handleAlertStoreStateChangeOpenAlert () {
        this.setState(
            {
                listAlertSettings: AlertStore.getAlertSettings(),
                priceAlertObj: AlertStore.getAlertPriceData(),
                showLoader: false,
                columnSetString: AlertStore.getSelectedColumnSetString() == "No Column Set" ? LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set") : AlertStore.getSelectedColumnSetString(),
                browserFilterText: AlertStore.getBrowserFilterText() == "All" ? LocalizationStore.getTranslatedData("FilterBrowser_Country_-1","All") : AlertStore.getBrowserFilterText()
            }
        );

        //Check if there is no condition selected then set below default condition
        //Daily price change exceeds +/- 5 %
        //Daily volume rate exceeds +/- 100 %

        let selectedCriteriaCount = 0;

        _.each(this.state.listAlertSettings, (val, key) => {
            if ((key.lastIndexOf("Selected") == key.length - 8) || (key.lastIndexOf("Checked") == key.length - 7)) {
                if (val == true)
                    selectedCriteriaCount++;
            }
        });

        if (this.state.priceAlertObj) {
            selectedCriteriaCount++;
        }

        if (selectedCriteriaCount == 0) //TO DO SK Add PriceAlert condition also
        {
            
            this.setState({ ...this.state, PriceChangeSelected: true, DailyVolumeRateSelected: true, listAlertSettings:{ ...this.state.listAlertSettings, PriceChangeSelected : true, DailyVolumeRateSelected : true}});
        }
    }

    handleAlertStoreStateChangeSaveAlertData () {
        this.closeDialog();
    }

    //Alert Store change listener - End

    closeDialog () {
        this.props.closeAndAlert();
    }

    onNotificationPreferenceClick () {
        //MenuCommonDialogActions.openNotificationDialog({ eventKey: MenuDialogType.Notification, showbackButton: false });
        this.setState({ isNCOpen: true });
    }
    hideModal () {
        this.setState({ isNCOpen: false });
    }
    saveAlertData () {
        document.getElementById("btnSaveAlert").disabled = true;
        if(this.props.isTriggered == 0){
            AlertActions.saveAlertData(this.state.listAlertSettings, 2, this.props.alertId, this.state.priceAlertObj,this.props.isTriggered);
        }else{
            AlertActions.saveAlertData(this.state.listAlertSettings, 2, 0, this.state.priceAlertObj,this.props.isTriggered);
        }
    }

    getCriteriaStr () {

        let criteria = [];
        let str = [];

        if (this.state.listAlertSettings.AdditionSelected) {
            criteria.push({ str1: "Additions", val: "", str2: "" });
        }

        if (this.state.listAlertSettings.RemovalSelected) {
            criteria.push({ str1: "Removals", val: "", str2: "" });
        }

        if (this.state.listAlertSettings.PriceChangeSelected) {
            criteria.push({ str1: "Daily price change exceeds +/- ", val: this.state.listAlertSettings.PriceChangeThreshold, str2: "%" });
        }

        if (this.state.listAlertSettings.DailyVolumeRateSelected) {
            criteria.push({ str1: "Daily volume rate exceeds ", val: this.state.listAlertSettings.DailyVolumeRateThreshold, str2: "%" });
        }

        if (this.state.listAlertSettings.AverageDlyVolSelected) {
            criteria.push({ str1: "Average daily volume crosses ", val: this.state.listAlertSettings.AverageDlyVolThreshold, str2: " (000) shares" });
        }

        if (this.state.listAlertSettings.AverageDlyDlrVolSelected) {
            criteria.push({ str1: "Average daily $ volume, USD crosses $ ", val: this.state.listAlertSettings.AverageDlyDlrVolThreshold, str2: " (000)" });
        }

        if (this.state.listAlertSettings.DailyVolumeExceeds50SMASelected) {
            criteria.push({ str1: "Daily volume exceeds 50 SMA ", val: "", str2: "" });

        }
        if (this.state.listAlertSettings.BreakingOutToAllTimeHighSelected) {
            criteria.push({ str1: "Breaking out to all-time high ", val: "", str2: "" });

        }
        if (this.state.listAlertSettings.RSReachesNewHighSelected) {
            criteria.push({ str1: "Relative Strength Line reaches new 52-week high (based on default index)", val: "", str2: "" });
        }

        if (this.state.listAlertSettings.MovingAvg50SmaSelected) {
            criteria.push({ str1: "Price crosses 50 SMA (daily)", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.MovingAvg200SmaSelected) {
            criteria.push({ str1: "Price crosses 200 SMA (daily)", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.MovingAvg50Cross200Selected) {
            criteria.push({ str1: "50 SMA - 200 SMA crossover (daily)", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.DailyBaseChecked) {
            criteria.push({ str1: "New daily base detected", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.WeeklyBaseChecked) {
            criteria.push({ str1: "New weekly base detected", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.WeeklyTightChecked) {
            criteria.push({ str1: "Tight area detected", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.DailyFailedBaseChecked) {
            criteria.push({ str1: "Failed daily base detected)", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.WeeklyFailedBaseChecked) {
            criteria.push({ str1: "Failed weekly breakout detected", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.DailyPriceRisesSelected) {
            criteria.push({ str1: "Price is within ", val: this.state.listAlertSettings.AlertPriceRises, str2: "% of the daily pivot" });
        }

        if (this.state.listAlertSettings.WeeklyPriceRisesSelected) {
            criteria.push({ str1: "Price is within ", val: this.state.listAlertSettings.AlertPriceRises, str2: "% of the weekly pivot" });
        }

        if (this.state.listAlertSettings.DailyPriceCrossesSelected) {
            criteria.push({ str1: "Breaking out of a daily base", val: "", str2: "" });
        }

        if (this.state.listAlertSettings.WeeklyPriceCrossesSelected) {
            criteria.push({ str1: "Breaking out of a weekly base", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.PullbackTo10WeekLineSelected) {
            criteria.push({ str1: "Pullback to 10-week line", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.NegativeAlter1WeekSelected) {
            criteria.push({ str1: "Negative Alert Score, 1 Week", val: this.state.listAlertSettings.NegativeAlter1Week, str2: " (000)" });
        }
        if (this.state.listAlertSettings.NegativeAlter10WeekSelected) {
            criteria.push({ str1: "Negative Alert Score, 10 Week ", val: this.state.listAlertSettings.NegativeAlter10Week, str2: " (000)" });
        }
        if (this.state.listAlertSettings.BuybackAnnouncementSelected) {
            criteria.push({ str1: "Buyback announcement", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.StockSplitSelected) {
            criteria.push({ str1: " Stock split", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.CeoChangeSelected) {
            criteria.push({ str1: "CEO change", val: "", str2: "" });
        }

        if (this.state.listAlertSettings.EarningsReleaseSelected) {
            criteria.push({ str1: "Earnings release is in ", val: this.state.listAlertSettings.EarningsReleaseDays, str2: " days" });
        }

        if (this.state.listAlertSettings.DividendChangeSelected) {
            criteria.push({ str1: "Dividend change", val: "", str2: "" });
        }
        // if (this.state.listAlertSettings.InsiderPurchasesSelected) {
        //     criteria.push({ str1: "Insider purchase", val: "", str2: "" });
        // }
        if (this.state.listAlertSettings.InsiderTransactionsBuysSelected) {
            criteria.push({ str1: "Insider transactions - Buys", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.InsiderTransactionsSellsSelected) {
            criteria.push({ str1: "Insider transactions - Sells", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.OfferingsSelected) {
            criteria.push({ str1: "Offering", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.AnnouncementsSelected) {
            criteria.push({ str1: "Announcement", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.BreakawayGapSelected) {
            criteria.push({ str1: "Breakaway gap", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.Day50BreakOnVolumeSelected) {
            criteria.push({ str1: "50 day break on volume", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.GapUpOnEarningsSelected) {
            criteria.push({ str1: "Gap up on earnings", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.GapDownOnEarningsSelected) {
            criteria.push({ str1: "Gap down on earnings", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.DailyEnterExitProfitRangeSelected) {
            criteria.push({ str1: "Enter or exit profit range - daily", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.WeeklyEnterExitProfitRangeSelected) {
            criteria.push({ str1: "Enter or exit profit range - weekly", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.DailyEnterExitPivotRangeSelected) {
            criteria.push({ str1: "Enter or exit pivot range - daily", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.WeeklyEnterExitPivotRangeSelected) {
            criteria.push({ str1: "Enter or exit pivot range - weekly", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.DailyEnterExitLossRangeSelected) {
            criteria.push({ str1: "Enter or exit loss range - daily", val: "", str2: "" });
        }
        if (this.state.listAlertSettings.WeeklyEnterExitLossRangeSelected) {
            criteria.push({ str1: "Enter or exit loss range - weekly", val: "", str2: "" });
        }
        if (this.state.priceAlertObj) {
            criteria.push({ str1: "Price " + (this.state.currencyCode ? this.state.currencyCode : this.props.SymbolInfo ? this.props.SymbolInfo.finTargetCurrencyCode : ""), val: this.state.priceAlertObj.targetPrice, str2: "" });
        }
        

        let i = 0;
        _.each(criteria, (item) => {
            str.push(<li key={i++}><i className="check-sign"></i> {item.str1 + item.val + item.str2} </li>);

            if (str.length != 0)
                str.push();
        });

        return (<ul> {str} </ul>);
    }

    doneBtnClick (listAlertSettings, priceAlertObj) {
        this.setState({
            listAlertSettings: listAlertSettings,
            priceAlertObj: priceAlertObj
        });

    }

    openAlertCriteriaWindow () {
        let left = document.getElementById("modalHeader").getBoundingClientRect().left - 2;
        let top = document.getElementById("modalHeader").getBoundingClientRect().top - 2;
        let openAlertCriteria = <AlertsDialog
            conditionTypeId={AlertConditionType.And}
            msId={(this.state.sourceTypeId == AlertSourceType.Stock  && this.props.SymbolInfo) ?  this.props.SymbolInfo.MsId : this.state.msId}
            instrumentId={(this.state.sourceTypeId == AlertSourceType.Stock  && this.props.SymbolInfo) ? this.props.SymbolInfo.MsId : this.state.instrumentId}//{instrumentId} SK TO DO confirm whether instrument id needs to be passed or not
            symbol={(this.state.sourceTypeId == AlertSourceType.Stock  && this.props.SymbolInfo) ? this.props.SymbolInfo.Symbol : this.state.symbol}
            sourceTypeId={this.state.sourceTypeId == AlertSourceType.Stock  ? AlertSourceType.Stock : AlertSourceType.List}
            open={true}
            alertId={this.props.alertId}
            closeAlert={this.closeAlertCriteriaWindow}
            doneBtnClick={this.doneBtnClick}
            listAlertSettings={_.clone(this.state.listAlertSettings)}
            priceAlertObj={_.clone(this.state.priceAlertObj)}
            x={left}
            y={top}
            paddingBottom={'21px'}
            instrumentType={this.state.instrumentType}
            currencyCode={this.state.currencyCode}
        />

        this.setState({ openAlertCriteria: openAlertCriteria });
    }

    closeAlertCriteriaWindow () {
        this.setState({ openAlertCriteria: null });
    }

    render() {
        let criteriaStr = this.getCriteriaStr();

        return (
            <section className="alert-b">
                {this.state.showLoader ? <div className="loading-box">
                    <div className="loading-backdrop"></div>
                    <div className="loading loading-spinner"></div>
                </div>
                    :
                    <section id="AlertDialog">
                        {this.state.openColumnSet}
                        {this.state.openGeography}
                        {this.state.openAlertCriteria}
                        <FlexibleModal left={this.getXPosition()} top={this.getYPosition()} open={this.props.open} ref={(modalHeader) => this.modalHeader = modalHeader} className="modal-popup alert-dialog and-condition-alert">
                            {!this.state.showLoader ?
                                <Modal.Header id="modalHeader">
                                    <Modal.Title>
                                        {this.state.sourceTypeId == AlertSourceType.List ?
                                            <span className="cap-header">{"List Alerts"}</span>
                                            :
                                            <span className="cap-header">{LocalizationStore.getTranslatedData("ch_msa_sup_aca","And-condition Alerts")}</span>
                                        }
                                        
                                    </Modal.Title>
                                </Modal.Header>
                            : null}
                            <Modal.Body id="modalBody">
                                <div className="dialog-content and-alerts">
                                    <div className="flexbox-parent">
                                        <div className="flexbox-item">
                                            {this.state.sourceTypeId == AlertSourceType.List ?
                                                <Fragment>
                                                    <div className="alert-heading">{LocalizationStore.getTranslatedData("ch_msa_sup_aca","And-condition Alerts")}</div>
                                                    <div className="alert-sub-heading">
                                                        <div className="tool-tip-box">
                                                            <span className="list-alert-name">
                                                                {LocalizationStore.getTranslatedData("alert_list_msg", 'Alert me when any symbol on {0} meets ALL of the following conditions:', AlertStore.getListName())}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                                : 
                                                <div className="alert-sub-heading">
                                                    <div className="tool-tip-box">
                                                        <span className="list-alert-name">
                                                            {LocalizationStore.getTranslatedData("alert_list_msg", 'Alert me when ALL of the following conditions are met:')}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            {criteriaStr}
                                        </div>
                                    </div>
                                    <div className="btn-box">
                                        <button type="button" id="btnChangeCriteria" className="btn btn-xs btn-secondary disable-drag" data-effect="fadeOut" onClick={this.openAlertCriteriaWindow}>Change Alert Criteria</button>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </Modal.Body>
                            {this.state.sourceTypeId == AlertSourceType.List && !this.state.showLoader ?
                            <Modal.Body>
                                <div className="modal-area">
                                    <div className="flexbox-parent">
                                        <div className="flexbox-item header">
                                            {LocalizationStore.getTranslatedData("alert_ColumnSetDes", "Use the Column Set and Geography buttons to exclude unwanted symbols from List Alerts.")}
                                        </div>
                                        <div className="model-action-grid">
                                            <Row className="show-grid">
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <ControlLabel>{LocalizationStore.getTranslatedData("LM_TP_ColumnSet", "Column Set")}</ControlLabel>
                                                    </FormGroup>
                                                    <div className="model-action-popup-btn" title={this.state.columnSetString=="No Column Set" ? LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set"): LocalizationStore.getTranslatedData("ColumnSet_"+this.state.columnSetString, this.state.columnSetString)} onClick={this.openColumnSetWindow}  >{this.state.columnSetString=="No Column Set" ? LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set"): LocalizationStore.getTranslatedData("ColumnSet_"+this.state.columnSetString, this.state.columnSetString)}</div>
                                                </Col>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <ControlLabel>{LocalizationStore.getTranslatedData("alert_Geography", "Geography")}</ControlLabel>
                                                    </FormGroup>
                                                    <div className="model-action-popup-btn" title={this.state.browserFilterText} onClick={this.openGeographyWindow} >{this.state.browserFilterText}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body> : null}
                            {!this.state.showLoader ?
                                <Modal.Footer>
                                    <span className="anchor-link disable-drag" onClick={this.onNotificationPreferenceClick}>{LocalizationStore.getTranslatedData("set_apre", "Alert Preferences")} </span>
                                    <span className="pull-right btn-box">
                                        <button type="button" className="model-action btn btn-xs btn-default btn-secondary pull-left small-bold disable-drag" data-effect="fadeOut" onClick={this.closeDialog}>{LocalizationStore.getTranslatedData("set_cancel", "Cancel")}</button>
                                        <button type="button" id="btnSaveAlert" className="model-action btn btn-xs btn-default btn-secondary pull-left small-bold disable-drag" data-effect="fadeOut" onClick={this.saveAlertData}>{LocalizationStore.getTranslatedData("set_ws_sv", "Save")}</button>

                                    </span>
                                </Modal.Footer> : null}
                        </FlexibleModal>
                    </section>
                }
                <NotificationContentContainer open={this.state.isNCOpen} hideModal={this.hideModal} showBackButton={false} />
            </section>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers })=>{
    const { SymbolInfo } = DatagraphReducers.DataGraphReducer;
    return { SymbolInfo };
}

export default connect(mapStateToProps)(AndAlertsDialog)