
import keyMirror from "keymirror";

export const TabDataGraphKpiConstants = {
  ActionTypes: keyMirror({
    KPI_MENU_LOADED: null,
    PROCESS_KPI_INDICATOR_DATA: null,
    GET_KPI_INDICATOR_DATA: null,
    KPI_APPEARANCE_CHANGED: null,
    KPI_LINE_LEAVE: null,
    KPI_LINE_OVER: null,
    KPI_LINE_CONTEXT_CLICK: null,
    KPI_LINE_CONTEXT_CLOSE: null,
    KPI_CLEAR_STATE: null,
    UPDATE_ALL_KPI_DATASET: null
  })
};