import React from "react";
import ListActions from "ListActions";
import { ResizeConstants } from "Constants/ResizeConstants.js";
import BrowserFilterStore from "Stores/NavModules/NavList/TabONeil/BrowserFilterStore";
import { Button, Form, FormGroup, Tab, Nav, NavItem, Col } from "react-bootstrap";
import { each, size, filter, map, contains, sortBy, groupBy } from "underscore";
import StringUtil from 'StringUtil';
import PropTypes from 'prop-types';
import ResizableWindow from "RayCustomControls/ResizableWindow/ResizableWindow.jsx";
import ScrollBar from "ScrollBar";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import { ListExplorerConstants } from "ListExplorerConstants";
import BaseServiceApi from 'BaseServiceApi';
import ListExplorerStore from "ListExplorerStore";
import ONeilViewStore from "../../Stores/NavModules/NavList/TabONeil/ONeilViewStore";

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

let loadBrowserFilterData;
let browserFilterIntialWidth = 900;
let browserFilterMinWidth = 615;
let browserFilterMinHeight = 200;
let minimumBoxItemWidth = 200; let GICSminimumBoxItemWidth = 149;
let isResizing = false;
let globalId = -1;

export default class BrowserFilters extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickClear = this.handleClickClear.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilterGroupRadio = this.handleFilterGroupRadio.bind(this);
        this.fetchBasicFilterData = this.fetchBasicFilterData.bind(this);
        this.onBrowserFilterStoreStageChange = this.onBrowserFilterStoreStageChange.bind(this);
        this.outsideClickDocument = this.outsideClickDocument.bind(this);
        this.browserFilterHeightResize = this.browserFilterHeightResize.bind(this);
        this.setBoxItemWidths = this.setBoxItemWidths.bind(this);
        this.getMinimumWidth = this.getMinimumWidth.bind(this);
        this.onFilterResize = this.onFilterResize.bind(this);
        this.onStopResize = this.onStopResize.bind(this);
        this.state = {
            filterType: (BrowserFilterStore.getState().isOneilselected == 1) ? 'Oneil' : 'Gics',
            groupFilterBlock: false,
            isOneilselected: BrowserFilterStore.getState().isOneilselected,
            isGicsselected: BrowserFilterStore.getState().isGicsselected,
            needBrowserCounts: BrowserFilterStore.getState().needBrowserCounts,
            browserFilterText: BrowserFilterStore.getState().browserFilterText,
            igOneilbrowserFilters: BrowserFilterStore.getState().igOneilbrowserFilters,
            igGicsbrowserFilters: BrowserFilterStore.getState().igGicsbrowserFilters,
            labelLoading: BrowserFilterStore.getState().labelLoading,
            filterBadge: BrowserFilterStore.getFilterBadge(),
            toggle: false,
            browserFilterWidth: browserFilterIntialWidth,
            browserFilterHeight: browserFilterMinHeight,
            browserWidths: BrowserFilterStore.getBrowserFilterWidths(),
            filterObject: BrowserFilterStore.getFilterObject()
        };
        this.groupRadioName = BrowserFilterStore.getState().selectedTab == 'Geography' ? false : true;
        ListActions.getCountryRegionData();
    }

    onBrowserFilterStoreStageChange() {
        if (BrowserFilterStore.getCurrentAction() === ResizeConstants.ActionTypes.UPDATE_BROWSER_FILTER_WIDTHS || BrowserFilterStore.getCurrentAction() === ListExplorerConstants.ActionTypes.BROWSER_FILTER_TAB_TYPE) {
            this.setState({ browserWidths: BrowserFilterStore.getBrowserFilterWidths() }, BrowserFilterStore.clearCurrentAction());
            this.setCurrentWidth(this.state.filterType, this.groupRadioName);
        }
        else {
            this.setState({
                isOneilselected: BrowserFilterStore.getState().isOneilselected,
                isGicsselected: BrowserFilterStore.getState().isGicsselected,
                needBrowserCounts: BrowserFilterStore.getState().needBrowserCounts,
                browserFilterText: BrowserFilterStore.getState().browserFilterText,
                igOneilbrowserFilters: BrowserFilterStore.getState().igOneilbrowserFilters,
                igGicsbrowserFilters: BrowserFilterStore.getState().igGicsbrowserFilters,
                labelLoading: BrowserFilterStore.getState().labelLoading,
                filterBadge: BrowserFilterStore.getFilterBadge(),
                filterObject: BrowserFilterStore.getFilterObject()
            }, this.fetchBasicFilterData);
        }
    }

    outsideClickDocument(e) {
        if (this.props.toggleBrowserFilters) {
            try {
                const component = this.component;
                if (isResizing == false && component && e.target) {
                    if (e.target === window) {
                        this.setState({ toggle: false }, this.props.handleBrowserFilters);
                    }
                    else if (e.target == component || component.contains(e.target) && e.target.className != "data-title-browser-filter") {
                        this.setState({ toggle: false });
                    }
                    else {
                        if (this.props.toggleBrowserFilters)
                            this.setState({ toggle: true }, this.props.handleBrowserFilters);
                    }
                }
                if (isResizing == true) isResizing = false;
            }
            catch (ex) {
            }
            e.stopPropagation();
        }
    }

    UNSAFE_componentWillMount() {
        let isExplorerResized = false;
        BrowserFilterStore.setBrowserFilterWidths(isExplorerResized);
        let browserWidths = BrowserFilterStore.getBrowserFilterWidths();
        browserFilterIntialWidth = browserWidths.browserFilterIntialWidth;
        browserFilterMinWidth = browserWidths.browserFilterMinWidth;
        browserFilterMinHeight = browserWidths.browserFilterMinHeight;
        minimumBoxItemWidth = browserWidths.minimumBoxItemWidth; GICSminimumBoxItemWidth = browserWidths.GICSminimumBoxItemWidth;
        document.addEventListener('mouseup', this.outsideClickDocument, false);
        window.addEventListener('resize', this.outsideClickDocument, false);
    }

    componentDidMount() {
        BrowserFilterStore.addChangeListener(this.onBrowserFilterStoreStageChange);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.outsideClickDocument, false);
        window.removeEventListener('resize', this.outsideClickDocument, false);
        BrowserFilterStore.removeChangeListener(this.onBrowserFilterStoreStageChange);
    }

    browserFilterHeightResize(height) {
        isResizing = true;
        this.setState({ browserFilterHeight: height });
    }
    calculateOtherValue(index, keys, sectionCurrentWidth) {
        let otherIndex = []; let otherValue = 0;
        if (size(keys) == 3) {
            let secondaryIndex = (index == 0) ? index + 2 : 0;
            otherIndex.push(secondaryIndex);
        } else {
            switch (index) { case 0: otherIndex = [2, 3]; break; case 1: otherIndex = [0, 3]; break; case 2: otherIndex = [0, 1]; break; default: break; }
        }
        each(otherIndex, (other) => {
            otherValue += sectionCurrentWidth[keys[other]];
        })
        return otherValue;
    }

    setMaxWidthForCurrentSection(previousTotalFilterWidth, sectionMaxWidths, differenceValue, index, sectionCurrentWidth) {
        let keys = Object.keys(sectionMaxWidths);
        let indexofValuetoChange = differenceValue > 0 ? index : index + 1;
        let otherValue = this.calculateOtherValue(index, keys, sectionCurrentWidth);
        let calculatedWidthofIndextoChange = 0;
        calculatedWidthofIndextoChange = this.state.browserFilterWidth - otherValue - sectionCurrentWidth[keys[index]] - 15;
        if (calculatedWidthofIndextoChange > sectionMaxWidths[keys[indexofValuetoChange]]) {
            sectionMaxWidths[keys[index]] -= (calculatedWidthofIndextoChange - sectionMaxWidths[keys[indexofValuetoChange]]);
            sectionMaxWidths[keys[indexofValuetoChange]] = calculatedWidthofIndextoChange;
        }
        return sectionMaxWidths;
    }

    onStopResize() {
        isResizing = false;
    }

    onFilterResize(fitlerWidth, boxItemInfo) {
        let widths = this.state.browserWidths;
        let width = widths.browserFilterWidths;
        let differenceValue = 0; let totalBrowserFilterWidth = 0;
        let leftMarginWidth = 15;
        let nextBoxWidth = 0;
        let previousTotalFilterWidth = this.state.browserFilterWidth;
        let allWidths = [];
        let isGroup = boxItemInfo.groupType == "Group" ? true : false;
        let widthSet = isGroup ? width[boxItemInfo.groupType][boxItemInfo.filterType] : width[boxItemInfo.groupType];
        let keys = Object.keys(widthSet);
        let index = keys.indexOf(boxItemInfo.id);
        let previousWidth = widthSet[boxItemInfo.id];
        differenceValue = fitlerWidth - previousWidth;
        let minimumBoxWidth = size(keys) == 3 ? widths.minimumBoxItemWidth : widths.GICSminimumBoxItemWidth;
        if (index < size(keys) - 1) {
            each(keys, (key) => totalBrowserFilterWidth += widthSet[key])
            each(keys, (key) => allWidths[key] = widthSet[key]);
            const nextBoxPrevWidth = widthSet[keys[index + 1]];
            if (differenceValue != 0) {
                nextBoxWidth = nextBoxPrevWidth - differenceValue;
                nextBoxWidth = nextBoxWidth < minimumBoxWidth ? minimumBoxWidth : nextBoxWidth;
                let otherValue = this.calculateOtherValue(index, keys, widthSet);
                let currentMaxvalue = previousTotalFilterWidth - nextBoxWidth - otherValue - leftMarginWidth;
                fitlerWidth = fitlerWidth > currentMaxvalue ? currentMaxvalue : fitlerWidth;
                allWidths[keys[index]] = fitlerWidth;
                allWidths[keys[index + 1]] = nextBoxWidth;
                if (totalBrowserFilterWidth <= previousTotalFilterWidth) {
                    if (!isGroup) {
                        widths.browserFilterWidths[boxItemInfo.groupType] = allWidths;
                    }
                    else {
                        widths.browserFilterWidths[boxItemInfo.groupType][boxItemInfo.filterType] = allWidths;
                    }
                }
            }
        } else {
            widths = this.setBoxItemWidths(index, keys, widths, differenceValue, boxItemInfo);
            each(keys, (key) => totalBrowserFilterWidth += widthSet[key])
        }
        if ((totalBrowserFilterWidth > widths.browserFilterMinWidth && totalBrowserFilterWidth < widths.browserFilterMaxWidth) || widths.browserFilterMaxWidth <= totalBrowserFilterWidth) {
            isResizing = true;
            this.setState({ browserWidths: widths, browserFilterWidth: totalBrowserFilterWidth + leftMarginWidth });
        }

    }
    setCurrentWidth(filtertype, isGroup) {

        let allratio = [];
        let leftMarginWidth = 15;
        let widths = this.state.browserWidths;
        let width = widths.browserFilterWidths;
        let prevWidth = this.state.browserFilterWidth;
        prevWidth = prevWidth > widths.browserFilterMaxWidth ? widths.browserFilterMaxWidth : prevWidth;
        let group = isGroup ? "Group" : "Geography";
        let totalWidth = prevWidth - leftMarginWidth;
        let currentWidth = 0;
        let totalMaxWidth = 0;
        let filter = filtertype == "Oneil" ? "Oneil" : "GICS";
        let value = 0;
        let widthSet = isGroup ? width[group][filter] : width[group];
        let keys = Object.keys(widthSet);
        let minimumBoxWidth = size(keys) == 3 ? widths.minimumBoxItemWidth : widths.GICSminimumBoxItemWidth + 1;
        each(keys, (key) => currentWidth += widthSet[key]);
        if (currentWidth != totalWidth) {
            let differenceValue = totalWidth - currentWidth;
            each(keys, (key) => { allratio[key] = differenceValue > 0 ? (currentWidth / widthSet[key]) : widthSet[key] > minimumBoxWidth ? ((currentWidth - (widths.browserFilterMinWidth - leftMarginWidth)) / (widthSet[key] - minimumBoxWidth)) : 0; });
            if (totalWidth > widths.browserFilterMinWidth && totalWidth < widths.browserFilterMaxWidth) {
                each(keys, (key) => {
                    value = differenceValue > 0 ? differenceValue / allratio[key] : widthSet[key] > minimumBoxWidth ? differenceValue / allratio[key] : 0;
                    let newValue = widthSet[key] + value;
                    if (newValue >= minimumBoxWidth) {
                        if (isGroup) {
                            widths.browserFilterWidths[group][filter][key] += value;
                        }
                        else {
                            widths.browserFilterWidths[group][key] += value;
                        }
                    }
                })
            }
        }
        each(keys, (key) => { totalMaxWidth += widthSet[key] });
        this.setState({ browserWidths: widths, browserFilterWidth: totalMaxWidth + leftMarginWidth });
        return widths;
    }

    setBoxItemWidths(index, keys, width, differenceValue, boxItemInfo) {
        let allratio = [];
        let currentwidth = 0;
        let leftMarginWidth = 15;
        let widths = width.browserFilterWidths; let value = 0;
        let previousTotalFilterWidth = this.state.browserFilterWidth;
        let prevWidths = previousTotalFilterWidth - leftMarginWidth;
        currentwidth = prevWidths + differenceValue;
        let minimumBoxWidth = size(keys) == 3 ? width.minimumBoxItemWidth : width.GICSminimumBoxItemWidth;
        let isGroup = boxItemInfo.groupType == "Group" ? true : false;
        let filter = boxItemInfo.filterType == "Oneil" ? "Oneil" : "GICS";
        let widthSet = isGroup ? widths[boxItemInfo.groupType][filter] : widths[boxItemInfo.groupType];
        each(keys, (key) => { allratio[key] = prevWidths / widthSet[key] });
        if (currentwidth > width.browserFilterMinWidth && currentwidth < width.browserFilterMaxWidth) {
            each(keys, (key) => {
                value = (differenceValue / allratio[key]);
                let newValue = widthSet[key] + value;
                if (newValue >= minimumBoxWidth && newValue < width.browserFilterMaxWidth) {
                    if (isGroup) {
                        widths[boxItemInfo.groupType][boxItemInfo.filterType][key] += value;
                    }
                    else {
                        widths[boxItemInfo.groupType][key] += value;
                    }
                }
            })
        }
        width.browserFilterWidths = widths;
        return width;
    }

    handleChange(e) {
        let dataSetType = e.target.value;
        ListActions.updateFilterDataSetType(dataSetType);
        this.setCurrentWidth(e.target.value, this.groupRadioName);
        this.setState({ filterType: e.target.value });
    }

    handleFilterGroupRadio(eventKey) {
        let tabName = "Geography";
        if (eventKey == 'first') {
            this.groupRadioName = false;
        }
        else {
            this.groupRadioName = true;
            tabName = "Group"
        }
        this.setCurrentWidth(this.state.filterType, this.groupRadioName);
        BrowserFilterStore.setTabSelection(tabName);
    }

    getMinimumWidth(group, filter) {
        let minWidth = undefined;
        if (group == "Group" && filter == "GICS") { minWidth = GICSminimumBoxItemWidth; }
        else { minWidth = minimumBoxItemWidth; }
        return minWidth;
    }

    createBoxItem(boxItem) {

        let browserWidths = this.state.browserWidths;
        let groupType = this.groupRadioName ? "Group" : "Geography";
        let fitler = this.state.isGicsselected ? "GICS" : "Oneil";
        let minimumWidth = this.getMinimumWidth(groupType, fitler);
        return <BoxItem source={boxItem} key={boxItem.filterType} loading={this.props.loading}
            labelLoading={this.props.labelLoading} width={browserWidths.browserFilterWidths} onResize={this.onFilterResize} onStopResize={this.onStopResize}
            filterType={fitler} groupType={groupType} minimumWidth={minimumWidth}
            browserFilterHeight={this.state.browserFilterHeight} browserFilterMaxHeight={browserWidths.browserFilterMaxHeight}
            browserFilterMinHeight={browserWidths.browserFilterMinHeight} setToggle={this.setToggle} />;
    }

    createBoxItems(boxItems) {
        return boxItems.map(this.createBoxItem, this);
    }

    createEmptyBox(value) {
        let browserWidths = this.state.browserWidths;
        let groupType = this.groupRadioName ? "Group" : "Geography";
        let fitler = this.state.isGicsselected ? "GICS" : "Oneil";
        let minimumWidth = this.getMinimumWidth(groupType, fitler);
        return <EmptyBox key={"empty" + fitler + value} id={value} groupType={groupType} filterType={fitler}
            width={browserWidths.browserFilterWidths}
            minimumWidth={minimumWidth}
            browserFilterHeight={this.state.browserFilterHeight} browserFilterMaxHeight={browserWidths.browserFilterMaxHeight}
            browserFilterMinHeight={browserWidths.browserFilterMinHeight} loading={true} />
    }

    createEmptyBoxs() {
        let groupType = this.groupRadioName ? "Group" : "Geography";
        let arr = groupType === "Geography"? ["Region","Country","Instrument"]:
        this.state.isGicsselected ? ["Sectors", "IndustryGroups", "Industries", "SubIndustries"] : ["Sectors", "MajorIndustries", "IndustryGroups"];
        return arr.map(this.createEmptyBox, this);
    }

    handleClickClear() {
        let type = null;
        if (this.groupRadioName) {
            if (this.state.isOneilselected == 1)
                type = 'Oneil';
            if (this.state.isGicsselected == 1)
                type = 'Gics';
        }
        else {
            type = 'Geography';
        }
        if (type != null)
            ListActions.resetFilterSet(type);
    }

    fetchBasicFilterData() {
        this.filterParameters = {
            needBrowserCounts: this.state.needBrowserCounts,
            browserFilterText: this.state.browserFilterText,
            igOneilbrowserFilters: this.state.igOneilbrowserFilters,
            igGicsbrowserFilters: this.state.igGicsbrowserFilters,
            isOneilselected: this.state.isOneilselected,
            isGicsselected: this.state.isGicsselected,
        }
        this.props.fetchBasicFilterData(this.filterParameters, this.state.labelLoading);
    }

    splitString(filterBadge) {
        let badge = [];
        let self = this;
        let splitArrayString = filterBadge.split("::");
        splitArrayString.map((s, i) => {
            (i != 0) ?
                badge.push(<span key={i}> :: <span className='filterBadgeElement' onClick={self.props.handleBrowserFilters} key={i}>{s}</span></span>)
                :
                badge.push(<span className='filterBadgeElement' onClick={self.props.handleBrowserFilters} key={i}>{s}</span>);
        })
        return badge;
    }

    getSelectedBrowserFilter() {
        let filterName = '';
        let browserFilterTextArray = this.state.browserFilterText.split(";");
        for (let i = 0; i < browserFilterTextArray.length; i++) {

            if (browserFilterTextArray[i] == "-1") { continue; }

            let browserCountryArray = browserFilterTextArray[i].split(",");
            if (browserCountryArray.length > 0) {

                let filterNameCount = (browserCountryArray.length > 0) ? browserCountryArray.length - 1 : 0;

                filterName = browserCountryArray.length > 1 && browserCountryArray[0] != '-1' ? LocalizationStore.getTranslatedData("Country_" + browserCountryArray[0]) + "[+" + filterNameCount + LocalizationStore.getTranslatedData("alert_Geography_more", "more") + "]" :
                    browserCountryArray[0] == '-1' && browserCountryArray.length > 1 ?
                        LocalizationStore.getTranslatedData("Country_" + browserCountryArray[1]) + "[+" + filterNameCount + LocalizationStore.getTranslatedData("alert_Geography_more", "more") + "]" :
                        LocalizationStore.getTranslatedData("Country_" + browserCountryArray[0]);
            }
        }
        if (filterName == "") {
            return LocalizationStore.getTranslatedData("LM_tp_Nofilters", "No Geography or Group Filters enabled");
        }
        return filterName;
    }
    render() {
        loadBrowserFilterData = this.props.loadBrowserFilterData;
        let groupFilterBlock = (this.props.toggleBrowserFilters ? 'block' : 'none');
        let groupRadioName = (this.groupRadioName ? 'inline' : 'none');
        let customResizerStyle = { left: 210, marginTop: 12 };
        let customStyles = { zIndex: 'initial' };
        const rightPaddingResizer = this.groupRadioName ? 215 : 100;
        const currentBrowserFilterHeight = this.state.browserFilterHeight > this.state.browserWidths.browserFilterMaxHeight ? this.state.browserWidths.browserFilterMaxHeight : this.state.browserFilterHeight;
        let browserData, browserIgOneilFilterData, browserIgGicsFilterData, filterData, gicsClass, selectedGics, selectedOneil;
        const sourceListCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("sourceCategoryType") ? ListExplorerStore.activeNode.sourceCategoryType : null;
        const isDisableBrowserFilter = this.props.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory || this.props.categoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || ListExplorerStore.getIsCryptoCurrencyList() || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || ListExplorerStore.isFactorList() || ListExplorerStore.isNotImportedList();

        if (this.props.toggleBrowserFilters) {
            browserData = this.props.browserData ? (this.props.browserData.filters != null) ? this.props.browserData.filters : null : null;
            browserIgOneilFilterData = this.props.browserIgOneilFilterData ? (this.props.browserIgOneilFilterData.filters != null) ? this.props.browserIgOneilFilterData.filters : null : null;
            browserIgGicsFilterData = this.props.browserIgGicsFilterData ? (this.props.browserIgGicsFilterData.filters != null) ? this.props.browserIgGicsFilterData.filters : null : null;
            filterData = (this.state.filterType == null || this.state.filterType == 'Oneil') ? browserIgOneilFilterData : browserIgGicsFilterData;
            gicsClass = (this.state.filterType == 'Gics') ? 'container-gics' : '';
            selectedGics = (this.state.isGicsselected == 1) ? 'defaultChecked' : '';
            selectedOneil = (this.state.isOneilselected == 1) ? 'defaultChecked' : '';
        }
        return (
            <div className="inner-filter-section">
                <div className="filter-block" ref={(c) => this.component = c}>
                    <div className="custom-dropdown-block">
                        <div id="groupFilter" className={isDisableBrowserFilter ? "disable-div" : undefined} >
                            <div className="icn-group-filter medium-normal" onClick={isDisableBrowserFilter ? undefined : this.props.handleBrowserFilters}></div>
                            <div className="data-title-browser-filter">{
                                (this.state.filterBadge != '' && !isDisableBrowserFilter) ?
                                    this.splitString(this.state.filterBadge) :
                                    this.state.browserFilterText == "-1;4,102;-1" && !isDisableBrowserFilter?
                                        <span onClick={isDisableBrowserFilter ? undefined : this.props.handleBrowserFilters} className="filterBadgeElement" >{this.getSelectedBrowserFilter()}</span> :
                                        <span onClick={isDisableBrowserFilter ? undefined : this.props.handleBrowserFilters} className="filterBadgeElement-default" >{LocalizationStore.getTranslatedData("LM_tp_Nofilters", "No Geography or Group Filters enabled")}</span>}</div>
                        </div>
                        {this.props.toggleBrowserFilters ?
                            <div className="pos-absolute a" style={{ display: groupFilterBlock }}>
                                <div className="custom-well">
                                    <ul className="group-list">
                                        <li className="pull-left">
                                            <div className="group-list-inner-section" style={{ width: this.state.browserFilterWidth, minWidth: browserFilterMinWidth }}>
                                                <ResizableWindow className="browser-filter-height-resize" browserFilterRSHeight="32px" width={(this.state.browserFilterWidth - rightPaddingResizer)} minHeight={browserFilterMinHeight} maxHeight={this.state.browserWidths.browserFilterMaxHeight} height={currentBrowserFilterHeight}
                                                    direction="Vertical" onStartResize={this.browserFilterHeightResize} isFilterHeight={true} customResizerStyle={customResizerStyle} customStyles={customStyles}>
                                                    <div style={{ height: this.state.browserFilterHeight, width: "100%", float: "left", background: "transparent", maxHeight: this.state.browserWidths.browserFilterMaxHeight, minHeight: browserFilterMinHeight }}></div>
                                                </ResizableWindow>
                                                <Tab.Container id="tabs-with-dropdown" activeKey={this.groupRadioName ? "second" : "first"} onSelect={this.handleFilterGroupRadio}>
                                                    <div className="clearfix">
                                                        <Col sm={12} className="container-switch">
                                                            <Tab.Content animation>
                                                                {this.groupRadioName == false ?
                                                                    <Tab.Pane eventKey="first">

                                                                       {(browserData != null) ? this.createBoxItems(browserData) : this.createEmptyBoxs()}

                                                                    </Tab.Pane> :

                                                                    <Tab.Pane eventKey="second" className={gicsClass}>

                                                                        {(filterData != null) ? this.createBoxItems(filterData) : this.createEmptyBoxs()}

                                                                    </Tab.Pane>}
                                                            </Tab.Content>
                                                        </Col>
                                                        <Col sm={12} className="container-switch-tab" >
                                                            <Nav bsStyle="tabs" className="bottom-tab-block nav nav-tabs">
                                                                <NavItem className="small-bold" eventKey="first">
                                                                    {LocalizationStore.getTranslatedData("gs_geo", "GEOGRAPHY")}
                                                                </NavItem>
                                                                <NavItem className="small-bold" eventKey="second">
                                                                    {LocalizationStore.getTranslatedData("gs_gr", "GROUPS")}
                                                                </NavItem>
                                                            </Nav>
                                                            <div className="">
                                                                <div className="filter-action">
                                                                    <Form inline>
                                                                        <FormGroup className="gicsRadioMark" style={{ display: groupRadioName }}>
                                                                            {/*<Radio inline name="filterGroupname" value="Oneil" onChange={this.handleChange} defaultChecked={(selectedOneil != '') ? true : false}>
                                                                                {"O'NIEL"}
                                                                            </Radio>*/}
                                                                            <span className="filter-span">
                                                                                <input id="oneilRadio" type="radio" style={{ cursor: 'pointer' }} name="filterGroupname" value="Oneil" onChange={this.handleChange} defaultChecked={(selectedOneil != '') ? true : false} />
                                                                                <label htmlFor="oneilRadio" className="small-bold" style={{ cursor: 'pointer' }}>{"O'NEIL"} </label>
                                                                            </span>
                                                                            <span className="filter-span">
                                                                                <input id="gicsRadio" type="radio" style={{ cursor: 'pointer' }} name="filterGroupname" value="Gics" onChange={this.handleChange} defaultChecked={(selectedGics != '') ? true : false} />
                                                                                <label htmlFor="gicsRadio" className="small-bold" style={{ cursor: 'pointer' }}>{"GICS"} </label>
                                                                            </span>
                                                                            {/*<Radio inline name="filterGroupname" value="Gics" onChange={this.handleChange} defaultChecked={(selectedGics != '') ? true : false}>
                                                                                GICS
                                                                            </Radio>*/}
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            <Button bsSize="xsmall" className="mart5 xx-small-bold" onClick={this.handleClickClear}>{LocalizationStore.getTranslatedData("LM_ClrAll", "Clear All")}</Button>
                                                                        </FormGroup>
                                                                    </Form>
                                                                </div>
                                                                <div className="inner-right overflow-h">
                                                                    {/*<img src="Asset/images/handal-icon-h.png" />*/}
                                                                    <span className="icon-handal-h"></span>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </Tab.Container>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        );
    }
}

BrowserFilters.propTypes = { handleBrowserFilters: PropTypes.func };

class BoxItem extends React.Component {
    constructor(props) {
        super(props);
        this.countryRegion = BrowserFilterStore.getState().countryRegion;
        //-------------------------
        this.getIndex = this.getIndex.bind(this);
        this.RowClick = this.RowClick.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
        this.selectRowsBetweenIndexes = this.selectRowsBetweenIndexes.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.setSelection = this.setSelection.bind(this);
        this.boxItemResize = this.boxItemResize.bind(this);
        this.boxItemStopResize = this.boxItemStopResize.bind(this);
        this.clearSelectedItems = this.clearSelectedItems.bind(this);
        this.isAllCountrySelectedInRegion = this.isAllCountrySelectedInRegion.bind(this);
        this.countryReginClick = this.countryReginClick.bind(this);
        this.filterType = this.props.filterType;
        this.groupType = this.props.groupType;
        this.lis = null;
        this.lastSelectedRow;
        this.countryRegionClick = [];
    }

    componentDidMount() {
        this.lis = [];
        if (this.props.source.name === 'Country') {
            if (this.multiFilterListCountry) {
                this.lis = this.multiFilterListCountry.querySelectorAll('li')
                this.allRows = this.multiFilterListCountry.querySelectorAll('li')
            }
        }
        else {
            if (this.multiFilterList) {
                this.lis = this.multiFilterList.getElementsByTagName('li');
                this.allRows = this.multiFilterList.getElementsByTagName('li');
            }
        }

    }
    componentDidUpdate(){
        this.lis = [];
        if (this.props.source.name === 'Country') {
            if (this.multiFilterListCountry) {
                this.lis = this.multiFilterListCountry.querySelectorAll('li')
                this.allRows = this.multiFilterListCountry.querySelectorAll('li')
            }
        }
        else {
            if (this.multiFilterList) {
                this.lis = this.multiFilterList.getElementsByTagName('li');
                this.allRows = this.multiFilterList.getElementsByTagName('li');
            }
        }
    }


    //Find Index of Li from Li List
    getIndex(array, element) {
        for (let i = 0; i < array.length; i++) {
            if (array[i] == element) return i;
        }
        return -1;
    }

    clearSelectedItems (countryItem) {
        let allId = map(countryItem, (cItem) => cItem.id);
        let filterRows = filter(this.lis, (row) => contains(allId, Number(row.getAttribute('data-nodefilterid'))))
        each(filterRows, (row) => {
            if (StringUtil.hasClass(row, 'active'))
                row.classList.remove('active')
        });
    }

    isAllCountrySelectedInRegion (countryItem) {
        let allId = map(countryItem, (cItem) => cItem.id);
        let filterRows = filter(this.lis, (row) => (contains(allId, Number(row.getAttribute('data-nodefilterid'))) && (StringUtil.hasClass(row, 'active') !== undefined && StringUtil.hasClass(row, 'active') !== null)));
        if (filterRows && allId) {
            if (filterRows.length === allId.length) {
                return true;
            }
        }
        return false;
    }

    countryReginClick (event, countryItem, key) {
        if (event.ctrlKey || event.metaKey) {
            if (this.isAllCountrySelectedInRegion(countryItem) && !(this.countryRegionClick && contains(this.countryRegionClick, key))) {
                this.clearSelectedItems(countryItem);
            }
            else if (this.isAllCountrySelectedInRegion(countryItem) && (this.countryRegionClick && contains(this.countryRegionClick, key))) {
                this.countryRegionClick = without(this.countryRegionClick, key);
                this.clearSelectedItems(countryItem);
            }
            else this.countryRegionClick.push(key);
        }
        else if (event.button === 0) {
            if (!event.ctrlKey && !event.metaKey) {
                this.clearAll();
                this.countryRegionClick = [];
                this.countryRegionClick.push(key);
            }
        }
        if (this.countryRegionClick.length === 0) {
            this.setSelection();
            return;
        }
        let allId = [];
        each(this.allCountrsData, (cData, key) => {
            if (contains(this.countryRegionClick, key)) {
                each(cData, (cItem) => allId.push(cItem.id));
            }
        });

        let filterRows = filter(this.lis, (row) => contains(allId, Number(row.getAttribute('data-nodefilterid'))))
        each(filterRows, (row) => {
            if (!StringUtil.hasClass(row, 'active'))
                row.classList.add('active')
        });
        this.setSelection();
    }

    RowClick(event, currenttr) {
        if (event.ctrlKey || event.metaKey) {
            this.toggleRow(currenttr);
        }

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey && !event.metaKey) {
                this.clearAll();
                this.toggleRow(currenttr);
            }

            if (event.shiftKey) {
                if (this.lastSelectedRow == undefined) {
                    this.setSelectedRow(null, currenttr);
                }
                this.selectRowsBetweenIndexes([this.getIndex(this.allRows, this.lastSelectedRow) + 1, this.getIndex(this.allRows, currenttr) + 1]);
            }
        }
        this.setSelection();
    }

    setSelectedRow(row, currenttr) {
        //let selectedList;
        if (this.props.source.name === 'Country') {
            //selectedList = this.multiFilterListCountry;
            if (row != null)
                this.lastSelectedRow = row;
            else {
                if (this.multiFilterListCountry) {
                    let withActive = [];
                    if (this.lis) {
                        for (let i = 0; i < this.lis.length; i++) {
                            if (StringUtil.hasClass(this.lis[i], 'active'))
                                withActive.push(i);
                        }
                    }
                    let indexCurrenttr = this.getIndex(this.allRows, currenttr);
                    if (indexCurrenttr < withActive[0])
                        this.lastSelectedRow = this.lis[withActive[0]];
                    else if (indexCurrenttr >= withActive[withActive.length - 1]) {
                        this.lastSelectedRow = this.lis[withActive[withActive.length - 1]];
                    }
                }
            }
        }
        else {
            if (row != null)
                this.lastSelectedRow = row;
            else {
                if (this.multiFilterList) {
                    let withActive = [];
                    for (let i = 0; i < this.multiFilterList.children.length; i++) {
                        if (StringUtil.hasClass(this.multiFilterList.children[i], 'active'))
                            withActive.push(i);
                    }
                    let indexCurrenttr = this.getIndex(this.allRows, currenttr);
                    if (indexCurrenttr < withActive[0])
                        this.lastSelectedRow = this.multiFilterList.children[withActive[0]];
                    else if (indexCurrenttr >= withActive[withActive.length - 1]) {
                        this.lastSelectedRow = this.multiFilterList.children[withActive[withActive.length - 1]];
                    }
                }
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.groupType = nextProps.groupType;
        this.filterType = nextProps.filterType;
    }

    setSelection() {
        this.lis[0].classList.remove('active');
        let selection = [];
        let filterNames = [];
        for (let i = 0; i < this.lis.length; i++) {
            if (this.lis[i].id == 'RegionLabel') {
                this.lis[i].className = '';
            }
            if (StringUtil.hasClass(this.lis[i], 'active') && this.lis[i].id != 'RegionLabel') {
                selection.push(this.lis[i].getAttribute('data-nodefilterid'));
                filterNames.push(this.lis[i].getAttribute('data-nodefiltername'));
            }
        }
        let parentName = '';
        let filterName = '';

        let filterNameCount = (filterNames.length > 0) ? filterNames.length - 1 : 0;

        if (this.props.source.name == "Country") {
            parentName = this.multiFilterListCountry.getAttribute('label');
            filterName = selection.length > 1 && filterNames[0] != 'All' ? filterNames[0] + "[+" + filterNameCount + LocalizationStore.getTranslatedData("alert_Geography_more", "more") + "]" : filterNames[0] == 'All' && selection.length > 1 ? filterNames[1] + "[+" + filterNameCount + LocalizationStore.getTranslatedData("alert_Geography_more", "more") + "]" : filterNames[0];
        }
        else {
            parentName = this.multiFilterList.getAttribute('label');
            filterName = selection.length > 1 && filterNames[0] != 'All' ? filterNames[0] + "[+" + filterNameCount + LocalizationStore.getTranslatedData("alert_Geography_more", "more") + "]" : filterNames[0] == 'All' && selection.length > 1 ? filterNames[1] + "[+" + filterNameCount + LocalizationStore.getTranslatedData("alert_Geography_more", "more") + "]" : filterNames[0];
        }
        let nodeFilterId = '';
        if (selection.length == 0) {
            this.lis[0].classList.add('active');
            nodeFilterId = -1;
        }
        each(selection, (selectItem, i) => {
            if (selectItem != -1) {
                if (i == 0 || nodeFilterId == '') {
                    nodeFilterId += selectItem;
                }
                else {
                    nodeFilterId += ',' + selectItem;
                }
            }
            else if (selectItem == -1 && selection.length == 1) {
                this.lis[0].classList.add('active');
                nodeFilterId = -1;
            }
            else {
                return;
            }
        });
        ListActions.changeOnclickFilterSet(parentName, nodeFilterId.toString(), filterName);
    }

    toggleRow(row) {
        row.classList.toggle('active');
        if (row.classList.contains('active'))
            this.lastSelectedRow = row;
        else
            this.lastSelectedRow = undefined;
    }

    selectRowsBetweenIndexes(indexes) {
        this.clearAll();
        indexes.sort((a, b) => a - b);

        for (let i = indexes[0]; i <= indexes[1]; i++) {
            if (i === 1) {
                this.lis[i - 1].classList.add('active');
                break;
            }
            this.lis[i - 1].classList.add('active');
        }
    }

    clearAll() {
        for (let i = 0; i < this.lis.length; i++) {
            if (this.lis[i].classList.contains('active')) {
                this.lis[i].classList.remove('active');
            }
        }
    }

    //------------------------------------
    createItem(item, i) {
        let self = this;
        return <ListItem item={item} key={i} RowClick={self.RowClick} />;
    }

    createItems(items) {
        return items.map(this.createItem, this);
    }

    createCountryItemsBox(Countrys) {
        this.allCountrsData = Countrys;
        return map(Countrys, this.createCountryBoxItem, this);
    }

    createCountryBoxItem(CountrysboxItem, key) {

        let self = this;
        return <CountryBox onRegionClick={(e) => this.countryReginClick(e, CountrysboxItem, key)} source={CountrysboxItem} region={key} key={key} RowClick={self.RowClick} />;
    }

    /* ---- Filter Data By ParentId ----------- */
    filterDataSetByParentId(filterData, parentsId) {
        let parentsIds = parentsId.split(',');
        let filteredData = filter(filterData, (filterObj) =>
            (filterObj.parentId == '0' || filterObj.id == '-1' || contains(parentsIds, filterObj.parentId.toString()))
        );
        // console.log(filteredData);
        return filteredData;
    }

    updateDataSource(filterDataSource) {
        let filterObject = BrowserFilterStore.getFilterObject();
        let filterType = filterDataSource.name.replace(/ /g, '').replace(/-/g, '') + filterDataSource.filterType;
        let updatedFilterDataSource = filterDataSource;
        let parentId = '0';
        switch (filterType) {
            case "Region1":
                updatedFilterDataSource = filterDataSource;
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("FilterBrowser_REGION_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name);
                }
                break;
            case "Country2":
                parentId = filterObject.Region1;
                if (parentId != '-1') {
                    updatedFilterDataSource.filters = this.filterDataSetByParentId(filterDataSource.filters, parentId);
                }
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("Country_" + filterDataSource.filters[i].id, LocalizationStore.getTranslatedData("FilterBrowser_Country_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name));
                }
                break;
            case "Instrument3":
                parentId = filterObject.Country2;
                if (parentId != '-1') {
                    updatedFilterDataSource.filters = this.filterDataSetByParentId(filterDataSource.filters, parentId);
                }
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("LMSymbolType_" + (filterDataSource.filters[i].name).toUpperCase(), LocalizationStore.getTranslatedData("FilterBrowser_INSTRUMENT_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name));
                }
                break;
            case "Sectors5":
                updatedFilterDataSource = filterDataSource;
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("LM_SectorName_" + (filterDataSource.filters[i].name).replace(/ /g, "").toUpperCase(), LocalizationStore.getTranslatedData("FilterBrowser_Country_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name));
                }
                break;
            case "MajorIndustries6":
                parentId = filterObject.Sectors5;
                if (parentId != '-1') {
                    updatedFilterDataSource.filters = this.filterDataSetByParentId(filterDataSource.filters, parentId);
                }
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("LM_MajorIndustryName_" + (filterDataSource.filters[i].name).replace(/ /g, "").toUpperCase(), LocalizationStore.getTranslatedData("FilterBrowser_Country_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name));
                }
                break;
            case "IndustryGroups7":
                parentId = filterObject.MajorIndustries6;
                if (parentId != '-1') {
                    updatedFilterDataSource.filters = this.filterDataSetByParentId(filterDataSource.filters, parentId);
                }
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("FilterBrowser_ONEILINDUSTRYGROU_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name);
                }
                break;
            case "Sectors1":
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("FilterBrowser_SECTOR_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name);
                }
                break;
            case "IndustryGroups2":
                parentId = filterObject.Sectors1;
                if (parentId != '-1') {
                    updatedFilterDataSource.filters = this.filterDataSetByParentId(filterDataSource.filters, parentId);
                }
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("FilterBrowser_INDUSTRYGROUP_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name);
                }
                break;
            case "Industries3":
                parentId = filterObject.IndustryGroups2;
                if (parentId != '-1') {
                    updatedFilterDataSource.filters = this.filterDataSetByParentId(filterDataSource.filters, parentId);
                }
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("FilterBrowser_INDUSTRY_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name);
                }
                break;
            case "SubIndustries4":
                parentId = filterObject.Industries3;
                if (parentId != '-1') {
                    updatedFilterDataSource.filters = this.filterDataSetByParentId(filterDataSource.filters, parentId);
                }
                for (let i = 0; i < filterDataSource.filters.length; i++) {
                    filterDataSource.filters[i].name = LocalizationStore.getTranslatedData("FilterBrowser_SUBINDUSTRY_" + filterDataSource.filters[i].id, filterDataSource.filters[i].name);
                }
                break;
            default:
                break;
        }
        return updatedFilterDataSource;
    }

    /* ---- Update Country Region ----------- */

    setCountryRegion(countryRegionData, countryFilters, filterType, filterName) {

        countryFilters = this.updateSelectedBox(countryFilters, filterType, filterName);
        each(countryFilters, (countryFilter) => {
            each(countryRegionData, (countryData) => {
                if (countryFilter.id === countryData.countryCode) {
                    countryFilter.regionName = countryData.regionName;
                }
            })

        });
        let sortedCountries = sortBy(countryFilters, 'regionName');
        let countries = groupBy(sortedCountries, 'regionName');
        each(countries, (c) => {
            let count = 0;
            for (let i = 0; i < c.length; i++) {
                count += c[i].count;
            }
            c["count"] = count;
        });
        return countries;
    }

    getFilterWidth(id) {
        let width = undefined; let widths = this.props.width;
        if (this.groupType == "Geography") { width = widths[this.groupType][id]; }
        else { width = widths[this.groupType][this.filterType][id]; }
        return width;
    }

    getMaxWidth(id) {
        let width = undefined; let widths = this.props.maxwidths;
        if (this.groupType == "Geography") { width = widths[this.groupType][id]; }
        else { width = widths[this.groupType][this.filterType][id]; }
        return width;
    }

    boxItemResize(resizerWidth) {
        let boxItemInfo = { filterType: this.filterType, groupType: this.groupType, id: this.props.source.name.replace(/ /g, '').replace(/-/g, '') }
        this.props.onResize(resizerWidth, boxItemInfo);
    }

    boxItemStopResize() {
        this.props.onStopResize();
    }

    getResizerClassName(id) {
        let className = "browser-filter-resize-";
        if (this.groupType == "Geography") { className += this.groupType + id }
        else { className = className += this.groupType + this.filterType + id; }
        return className;
    }

    updateSelectedBox(filters, filterTypeId, filterName) {

        const filterType = filterName.replace(/ /g, '').replace(/-/g, '') + filterTypeId;
        const cloneFilter = [...filters];
        const filterIds = BrowserFilterStore.getFilterObject()[filterType];
        const ids = contains(filterIds, ',') ? filterIds.split(',').map((item) => { return +item }) : [...Array(), +filterIds];
        const filterRows = filter(cloneFilter, (row) => { return contains(ids, row.id) });
        filters = this.clearSelection(cloneFilter);

        if (filterRows && filterRows.length === ids.length) {
            if (ids.length === 1) {
                filters = [...filters].map((itm) => (itm.id === +ids) ? Object.assign(itm, { selected: true }) : itm);
            }
            else {
                each(filterRows, (item) => {
                    filters = [...filters].map((itm) => (item.id === itm.id) ? Object.assign(itm, { selected: true }) : itm);
                })
            }
        }
        else {
            filters = [...filters].map((item) => (item.id === globalId) ? Object.assign(item, { selected: true }) : item);
            ListActions.resetCountryBadge();

        }
        return filters;
    }

    clearSelection(selectedData) {
        selectedData = [...selectedData].map((item) => Object.assign(item, { selected: false }));
        return selectedData;
    }

    render() {
        let filterDataSource = this.props.source;

        let updatedDataSource = this.updateDataSource(filterDataSource);
        // console.log(updatedDataSource);

        let countries = null;
        if (updatedDataSource.name === 'Country') {
            countries = this.setCountryRegion(this.countryRegion, updatedDataSource.filters, updatedDataSource.filterType, updatedDataSource.name);
        }

        let id = filterDataSource.name.replace(/ /g, '').replace(/-/g, '');

        return (
            <ResizableWindow className={this.getResizerClassName(id)} width={this.getFilterWidth(id)} minWidth={this.props.minimumWidth} minHeight={this.props.browserFilterMinHeight} maxHeight={this.props.browserFilterMaxHeight} height={this.props.browserFilterHeight} direction="Horizontal" onStartResize={this.boxItemResize} onStopResize={this.boxItemStopResize}>
                <div className="demoA">
                    <div className="demoB">
                        <div className="inner-right" style={{ height: this.props.browserFilterHeight, minHeight: this.props.browserFilterMinHeight, maxHeight: this.props.browserFilterMaxHeight }}>
                            {/*<img src="Asset/images/handal-icon-v.png" />*/}
                            <span className="icon-handal-v"></span>
                        </div>
                    </div>
                    <div className="demoC">
                        <div className="inner-filter-block" id={id} style={{ height: this.props.browserFilterHeight, minHeight: this.props.browserFilterMinHeight, maxHeight: this.props.browserFilterMaxHeight }}>
                            <div className="inner-left ">
                                <div className="panel-heading" >
                                    {this.props.source.name === 'Region' ? LocalizationStore.getTranslatedData("FilterBrowser_CLASSIFICATION", "CLASSIFICATION") : LocalizationStore.getTranslatedData("FilterBrowser_" + (this.props.source.name).replace(/ /g, "").toUpperCase(), (this.props.source.name).toUpperCase())}
                                </div>
                                {this.props.loading && contains(this.props.labelLoading, updatedDataSource.name.replace(/ /g, '').replace(/-/g, '') + updatedDataSource.filterType) ? <div className="group-box pos-relative" style={{marginBottom:2+"px"}}><div className="loading loading-spinner"></div></div> : ''}
                                <div className="group-box">
                                    {(this.props.source.name === 'Country') ?
                                        <div className="custom-scroll">
                                            <div id={'browserFilter' + updatedDataSource.filterType + 'VerticalScroll'}>
                                                <ul label={updatedDataSource.name.replace(/ /g, '') + updatedDataSource.filterType} ref={(ul) => this.multiFilterListCountry = ul}>
                                                    {this.createCountryItemsBox(countries)}
                                                </ul>
                                                <ScrollBar scrollId={"browserFilter" + updatedDataSource.filterType + "VerticalScroll"} vScroll={true} />
                                            </div>
                                        </div>
                                        :
                                        <div className="custom-scroll">
                                            <div id={'browserFilter' + updatedDataSource.filterType + 'VerticalScroll'}>
                                                <ul label={updatedDataSource.name.replace(/ /g, '') + updatedDataSource.filterType} ref={(ul) => this.multiFilterList = ul}>
                                                    {this.createItems(updatedDataSource.filters)}
                                                </ul>
                                                <ScrollBar scrollId={"browserFilter" + updatedDataSource.filterType + "VerticalScroll"} vScroll={true} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ResizableWindow>
        );
    }
}


class EmptyBox extends React.Component {
    getFilterWidth = (id) => {
        let width = undefined; let widths = this.props.width;
        if (this.props.groupType == "Geography") { width = widths[this.props.groupType][id]; }
        else { width = widths[this.props.groupType][this.props.filterType][id]; }
        return width;
    }
    // getMaxWidth = (id) => {
    //     let width = undefined; let widths = this.props.maxwidths;
    //     if (this.props.groupType == "Geography") { width = widths[this.props.groupType][id]; }
    //     else { width = widths[this.props.groupType][this.props.filterType][id]; }
    //     return width;
    // }
    render() {

        return (
            <ResizableWindow width={this.getFilterWidth(this.props.id)} minWidth={this.props.minimumWidth} minHeight={this.props.browserFilterMinHeight} maxHeight={this.props.browserFilterMaxHeight} height={this.props.browserFilterHeight} direction="Horizontal">
                <div className="demoA">
                    <div className="demoB">
                        <div className="inner-right" style={{ height: this.props.browserFilterHeight, minHeight: this.props.browserFilterMinHeight, maxHeight: this.props.browserFilterMaxHeight }}>
                            {/*<img src="Asset/images/handal-icon-v.png" />*/}
                            <span className="icon-handal-v"></span>
                        </div>
                    </div>
                    <div className="demoC">
                        <div className="inner-filter-block" style={{ height: this.props.browserFilterHeight, minHeight: this.props.browserFilterMinHeight, maxHeight: this.props.browserFilterMaxHeight }}>
                            <div className="inner-left ">
                                <div className="panel-heading" >
                                    &nbsp;
                        </div>
                                {this.props.loading ? <div className="group-box pos-relative"><div className="loading loading-spinner"></div></div> : ''}
                                <div className="group-box">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ResizableWindow>);
    }
}

class ListItem extends React.Component {

    constructor(props) {
        super(props);
        this.handleFilterListClick = this.handleFilterListClick.bind(this);
    }

    handleFilterListClick(e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        this.props.RowClick(e, target, false);
    }

    render() {
        let activeClass = (this.props.item.selected) ? 'active' : '';
        let listCountZeroClass = (this.props.item.count == 0) ? 'listCountZero' : '';
        // let name = this.getName(this.props.item.name,this.props.item.id)
        return (
            <li className={activeClass + ' ' + listCountZeroClass}
                data-nodefilterid={this.props.item.id}
                data-nodefiltername={this.props.item.name}
                onClick={this.handleFilterListClick}>
                <span className="filter-value">{this.props.item.name}</span>
                <span className="filter-count">{loadBrowserFilterData ?"...":this.props.item.count}</span>
            </li>
        );
    }
}

class CountryBox extends React.Component {

    constructor(props) {
        super(props);
        this.handleFilterListClick = this.handleFilterListClick.bind(this);
    }

    createItem(item, i) {
        return <CountryListItem item={item} key={i} RowClick={this.props.RowClick} />;
    }

    createItems(items) {
        return items.map(this.createItem, this);
    }

    handleFilterListClick (e) {
        this.props.onRegionClick(e);
        e.stopPropagation();
        e.preventDefault();
    }

    render() {
        return (
            <ul>
                {this.props.region !== 'All' ?
                    <li onClick={this.handleFilterListClick} id="RegionLabel">
                        <span className="filter-value">{LocalizationStore.getTranslatedData("Region_" + (this.props.region).replace(/ /g, ""), this.props.region)}</span>
                        <span className="filter-count">{loadBrowserFilterData ?"...":this.props.source.count}</span>
                    </li> : null}
                {this.createItems(this.props.source)}
            </ul>
        );
    }
}

class CountryListItem extends React.Component {

    constructor(props) {
        super(props);
        this.handleFilterListClick = this.handleFilterListClick.bind(this);
        this.inlineLiPadding = { paddingLeft: '24px' };
    }

    handleFilterListClick(e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        this.props.RowClick(e, target, false);
    }

    render() {
        let activeClass = (this.props.item.selected) ? 'active' : '';
        let listCountZeroClass = (this.props.item.count == 0) ? 'listCountZero' : '';
        let countryList = (this.props.item.name != 'All') ? this.inlineLiPadding : null;
        return (
            <li className={activeClass + ' ' + listCountZeroClass}
                style={countryList}
                data-nodefilterid={this.props.item.id} data-nodefiltername={this.props.item.name}
                onClick={this.handleFilterListClick}>
                <span className="filter-value">{this.props.item.name}</span>
                <span className="filter-count">{loadBrowserFilterData ?"...":this.props.item.count}</span></li>
        );
    }
}




