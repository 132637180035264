import keyMirror from "keymirror";

export const GridConstants = {
  ActionTypes: keyMirror({
    GET_HIGHLIGHTED_ROW: null,
    GET_SELECTED_ROW: null,
    UPDATE_COL_SET_WIDTH: null,
    ADD_COLUMN_TO_LIST: null,
    ADD_COLUMN_TO_LIST_FROM_METRIC: null,
    ADD_COLUMN_TO_LIST_UNDO: null,
    REMOVE_COLUMN_TO_LIST: null,
    UPDATE_GLOBAL_FLAG_ALL: null,
    UPDATE_GLOBAL_FLAG: null,
    AUTO_SIZE_ALL_COLUMNS_WIDTH: null,
    SPLIT_FROZEN_COLUMNS: null,
    SELECT_ALL_ROWS: null,
    REMOVE_GRID_ROWS: null,
    REMOVE_GRID_ROWS_UNDO: null,
    COPY_GRID_ROWS: null,
    SHOW_SYMBOLS_SELECT_LIMIT_EXCEED_MODAL:null,
    PASTE_GRID_ROWS: null,
    PASTE_GRID_ROWS_UNDO: null,
    DROP_IN_LIST_MANAGER: null,
    ADD_SYMBOL_LIST_MANAGER: null,
    VISUALIZE_GRID_COLUMN: null,
    SET_SUMMARYSTATE_ROW_COL_HIGHLIGHTED: null,
    LOAD_HIST_DATA: null,
    REORDER_SUMMMARY_STAT_ROW: null,
    VISUALIZE_GRID_COLUMN: null,
    SHIFT_DOWN_SELECTION: null,
    SHIFT_UP_SELECTION: null,
    CLIPBOARD_DATA_ACCESS_DENIED: null,
    EDIT_CUSTOM_METRICS:null,
    EDIT_CUSTOM_METRIC_SUCCESS:null,
    CLOSE_SYMBOLS_SELECT_LIMIT_EXCEED_MODAL:null,
    REMOVE_UNSHARED_LIST:null,
  })
};

export const ScrollPosition = {
  SET_SCROLL_POSITION: null
}
