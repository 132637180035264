import classnames from "classnames";
import moment from "moment";
import PropTypes from 'prop-types';
import { monthsName, MonthsTranlateHelper } from "../../Utils/TranslateHelper";
import React, { Component } from "react";

export default class DateTimePickerMonths extends Component {
  constructor(props) {
    super(props);
    this.renderMonths = this.renderMonths.bind(this);
  }

  renderMonths() {
    let classes, i;
    const minMonthYear = moment(this.props.minDate).format('YYYY-MM-DD');
    const maxMonthYear = moment(this.props.maxDate).format('YYYY-MM-DD');
    const month = this.props.selectedDate.month();
    i = 0;
    const months = [];
    while (i < 12) {
      const curMinMonthYear = moment(this.props.viewDate.startOf("month").clone().month(i + 1)).format('YYYY-MM-DD');
      const curMaxMonthYear = moment(this.props.viewDate.startOf("month").clone().month(i)).format('YYYY-MM-DD');
      classes = {
        month: true,
        disabled: !(moment(maxMonthYear).isSameOrAfter(curMaxMonthYear) && moment(minMonthYear).isSameOrBefore(curMinMonthYear)),
        "active": i === month && this.props.viewDate.year() === this.props.selectedDate.year()
      };

      if (moment(maxMonthYear).isSameOrAfter(curMaxMonthYear) && moment(minMonthYear).isSameOrBefore(curMinMonthYear)){
        months.push(<span  className={classnames(classes)} key={i} onClick={this.props.setViewMonth}>{MonthsTranlateHelper[monthsName[i]]}</span>);
      }
      else{
        months.push(<span className={classnames(classes)} key={i}>{MonthsTranlateHelper[monthsName[i]]}</span>);
      }
      i++;
    }
    return months;
  }

  render() {
    return (
      <div className="datepicker-monthsWrapper" style={{ display: "block" }}>
        <table className="table-condensed">
          <tbody>
            <tr>
              <td colSpan="7">{this.renderMonths()}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

DateTimePickerMonths.propTypes = {
  viewDate: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  setViewMonth: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object
}
