import AppDispatcher from "AppDispatcher";
import { RotationGraphConstants } from "RotationGraphConstants";
import { ComparisonAndRotationMenuConstants } from "Constants/RotationGraphConstants";
import SettingsAction from "SettingsAction";
import ConsoleStore from "ConsoleStore";
import ContextMenuConstants from "ContextMenuConstants";
import { RotationMenuType } from "Constants/RotationMenuType";
import { dispatch } from "../Redux/dispatch.js";
import { ActionTypes } from "../Constants/AppColorConstants.js";
import { onPlotSymbol } from "./DatagraphActions.js";

class RotationGraphActionsClass {

    //Rotation Graph related actions goes here..
    // getQuadData(quadRequestData) {
    //     AppDispatcher.handleServerAction({
    //         actionType: RotationGraphConstants.ActionTypes.GET_QUAD_DATA,
    //         data: { quadRequestData: quadRequestData }
    //     });
    // }

    // getBenchMarkData(benchMarkRequestData) {
    //     AppDispatcher.handleServerAction({
    //         actionType: RotationGraphConstants.ActionTypes.GET_BENCHMARK_DATA,
    //         data: { benchMarkRequestData: benchMarkRequestData }
    //     });
    // }

    getGeographyData() {
        AppDispatcher.handleServerAction({
            actionType: RotationGraphConstants.ActionTypes.GET_GEOGRAPHY_DATA,
            data: {}
        });
    }

    getGlobalTrendCountData(globalTrendRequestData) {
        AppDispatcher.handleServerAction({
            actionType: RotationGraphConstants.ActionTypes.GET_GLOBAL_TREND_COUNT_DATA,
            data: { globalTrendRequestData: globalTrendRequestData }
        });
    }

    getGrpsInstrumentData(grpsInstrumentRequestData) {
        AppDispatcher.handleServerAction({
            actionType: RotationGraphConstants.ActionTypes.GET_GROUPS_INSTRUMENT_DATA,
            data: { grpsInstrumentRequestData: grpsInstrumentRequestData }
        });
    }

    getGroupsMainData() {
        AppDispatcher.handleServerAction({
            actionType: RotationGraphConstants.ActionTypes.GET_GROUPS_MAIN_DATA,
            data: {}
        });
    }

    getLiqFilterData() {
        AppDispatcher.handleServerAction({
            actionType: RotationGraphConstants.ActionTypes.GET_LIQ_FILTER_DATA,
            data: {}
        });
    }

    liqFilterValueChanged(isEmitChange) {
        AppDispatcher.handleServerAction({
            actionType: RotationGraphConstants.ActionTypes.LIQ_FILTER_CHANGE,
            data: { isEmitChange: isEmitChange }
        });
    }

    //Comparison Graph related actions goes here..
}

const rotationGraphActions = new RotationGraphActionsClass();
export default rotationGraphActions;


export const RoutToCompareGraph = (infoData, navType) => {
    AppDispatcher.handleViewAction({
        actionType: RotationGraphConstants.ActionTypes.SET_CONSOLE_ROUT,
        data: [infoData, navType]
    });
}

export const RouteToRotationGraph = (infoData, navType) => ({
    type: RotationGraphConstants.ActionTypes.ROUTE_TO_ROTATION_GRAPH,
        infoData, navType
});


export const getGroupsMainData = () => {
    AppDispatcher.handleServerAction({
        actionType: RotationGraphConstants.ActionTypes.GET_GROUPS_DATA,
        data: {}
    });
}
export const setGroupsMainData = () => ({
        type: RotationGraphConstants.ActionTypes.INIT_GROUPS_MAIN_DATA
    });

export const updateFromUserSettingsData = () => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_FROM_USER_SETTINGS_DATA
});

export const updateDimensionChange = (dimensions) => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_DIMENSION_CHANGE,
    dimensions: dimensions
});

export const updateBenchMarkDimensionChange = (dimensions) => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_BENCHMARK_DIMENSION_CHANGE,
    dimensions: dimensions
});

export const getBenchMarkData = (benchMarkRequestData) => ({
    type: RotationGraphConstants.ActionTypes.GET_BENCHMARK_DATA,
    benchMarkRequestData: benchMarkRequestData
});

export const getQuadData = (groupType) => ({
    type: RotationGraphConstants.ActionTypes.GET_QUAD_DATA,
    groupType: groupType
});

export const initializeTimelineValues = () => ({
    type: RotationGraphConstants.ActionTypes.INITIALIZE_TIME_LINE
});

export const updateGlobalFlag = (msids, snapshotdates = "", isDelete, cacheId, dbType, selectedIndexes) => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_GLOBAL_FLAG,
    data: { msids: msids, snapshotdates: snapshotdates, isDelete: isDelete, cacheId: cacheId, dbType: dbType, selectedIndexes: selectedIndexes }
});

export const toggleTrendLines = (isUpTrend) => ({
    type: RotationGraphConstants.ActionTypes.TOGGLE_TREND_LINES,
    isUpTrend: isUpTrend
});

export const updateZoomPercentage = (zoomPercentage, zoomMatrix) => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_ZOOM_PERCENTAGE,
    zoomPercentageValue: zoomPercentage,
    zoomMatrix: zoomMatrix
});

export const resetZoomValue = () => ({
    type: RotationGraphConstants.ActionTypes.RESET_ZOOM_VALUE
});

export const updateContextMenuVisibility = (isVisible) => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_CONTEXT_MENU_VISIBILTY,
    isContextMenuVisible: isVisible
});

export const prepareContextMenuView = (contextMenuObj, listItemType) => ({
    type: RotationGraphConstants.ActionTypes.PREPARE_CONTEXT_MENU_VIEW,
    contextMenuObj: contextMenuObj,
    listItemType: listItemType
});

export const onContextMenuItemClick = (action, item, listItemType) => {
    switch (action) {
        case ContextMenuConstants.ROTATION_GRAPH_VIEW_SYMBOL:
            dispatch(onPlotSymbol(item.underlyingSymbol));
            ConsoleStore.plotSymbol(item.underlyingSymbol);
            return;
        case ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS:
            dispatch({
                type: ComparisonAndRotationMenuConstants.ActionTypes.MENU_LOADING,
                isLoading: true
            });
            dispatch({
                type: ComparisonAndRotationMenuConstants.ActionTypes.POPULATE_LIST_ITEM,
                item: item,
                listItemType: listItemType
            });
            return;
        case ContextMenuConstants.ROTATION_GRAPH_FLAG:
            if (listItemType === RotationMenuType.Stocks)
                return dispatch({
                    type: ComparisonAndRotationMenuConstants.ActionTypes.UPDATE_FLAG,
                    item: item
                });
        default:
            return;
    }
}

export const preparePointsToRender = (selectedSliderValue, isInit) => ({
    type: RotationGraphConstants.ActionTypes.PREPARE_POINTS_TO_RENDER,
    selectedSliderValue,
    isInit
});

export const setSliderValue = (selectedSliderValue) => ({
    type: RotationGraphConstants.ActionTypes.SET_SLIDER_VALUE,
    selectedSliderValue: selectedSliderValue
})

export const updateTimeLineIndex = (isSave = true, selectedSliderValue = null) => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_TIMELINE_INDEX,
    isSave: isSave,
    selectedSliderValue: selectedSliderValue
});

export const updateIsTimeLinePlaying = (isTimeLinePlaying = false) => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_IS_TIMELINE_PLAYING,
    isTimeLinePlaying: isTimeLinePlaying
});

export const updateIsSliderReset = (isSliderReset = false) => ({
    type: RotationGraphConstants.ActionTypes.UPDATE_IS_SLIDER_RESET,
    isSliderReset: isSliderReset
});

export const stopISPlaying = () => (
    dispatch({
        type: RotationGraphConstants.ActionTypes.UPDATE_IS_SLIDER_RESET,
        isSliderReset: true
    }),
    dispatch({
        type: RotationGraphConstants.ActionTypes.UPDATE_IS_TIMELINE_PLAYING,
        isTimeLinePlaying: false
    })
);

export const resetTimeLinePlaying = (isLiqFilterOpen = false) => ({
    type: RotationGraphConstants.ActionTypes.RESET_TIMELINE_PLAYING,
    isLiqFilterOpen: isLiqFilterOpen
});

export const refreshRotationGraph = () => ({
    type: RotationGraphConstants.ActionTypes.HANDLE_REFRESH
});

export const toggleBenchMarkSelection = () => ({
    type: RotationGraphConstants.ActionTypes.TOGGLE_BENCHMARK_SELECTOR
});