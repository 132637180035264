import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class SaveSettingsRequest extends BaseServiceRequest {
  constructor(data, name, version) {
    super();
    super.addRequestUri("webworkspace.pb");
    const ReqProto = BaseServiceApi.rayData["WorkspaceData"];
    const settingData = new ReqProto();
    settingData.name = name;
    settingData.binaryBytes = data;
    settingData.bytesLen = data.length;
    settingData.appVersion = version;
    const reqcontent = settingData.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.PUT;
  }

  getSubSystem() {
    return SubSystemType.USER;
  }

  getMessageName() {
    return "WorkspaceResponse";
  }

}
