const  SubSystemType = {
  LIST: 'LIST',
  GRAPH: 'GRAPH',
  USER: 'USER',
  FUNDAMENTAL: 'FUNDAMENTAL',
  GROUPS: 'GROUPS',
  ALERTS: 'ALERTS',
  NOTIFICATION: 'NOTIFICATION',
  SHARE: 'SHARE',
  NEWS: 'NEWS',
  LOCALIZATION: 'LOCALIZATION',
  METRICDEFINITION: 'METRICDEFINITION',
  HUB: 'HUB',
  SSO: 'SSO'
}

export default SubSystemType;
