import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetListColumnPickListRequest extends BaseServiceRequest {
    constructor(consoleId, listId, cacheId, columnId, categoryId = 1) {
        super();
        super.addRequestUri("picklist.pb");
        super.addPathParameter(consoleId.toString());
        super.addPathParameter(listId.toString());

        super.addRequestParameter("cache", cacheId.toString());
        super.addRequestParameter("cid", columnId.toString());
        super.addRequestParameter("categorytypeid", categoryId.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListColumnPickListResponse";
    }
}

export default GetListColumnPickListRequest;
