import { CalenderEventConstant } from '../../Constants/CalenderControl/CalenderControlConst';
import { CalenderEventTranslateHelper } from '../../Utils/TranslateHelper';
import LocalizationStore from 'LocalizationStore';
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import { map, filter } from "underscore";
import React, { Component } from "react";

export default class CalendarEvents extends Component {
	handleClick(eventDate) {
		this.props.handleClick(eventDate);
	}

	render() {
		const keyDates = this.props.keydates;
		let earningsContent = null, eventsContent = null, newsContent = null, eventsCeoContent = null, reorgContent = null;
		const self = this;

		reorgContent = map(keyDates, (item, index) => {
			if (item.header === 'Reorganization') {
				return (<a className="ellipsis-picker" key={index + 1} onClick={self.handleClick.bind(self, item.eventDate)} >{item.content}<br /></a>)
			}
		});
		reorgContent = filter(reorgContent, (item) => item !== undefined);

		earningsContent = map(keyDates, (item, index) => {
			if (item.header === 'Earnings') {
				if(item.content.indexOf(" ") === -1){ 
					return (<a className="ellipsis-picker" key={index + 1} onClick={self.handleClick.bind(self, item.eventDate)} >{item.content}<br /></a>);
				}
				const arr = item.content.split(" ");
				const year = arr[1];
				item.content = LocalizationStore.getTranslatedData("sb_date","{0} {1}",LocalizationStore.getTranslatedData(`cal_Num_${arr[0]}`, arr[0]),year);
				return (<a className="ellipsis-picker" key={index + 1} onClick={self.handleClick.bind(self, item.eventDate)} >{item.content}<br /></a>)
			}
		});
		earningsContent = filter(earningsContent, (item) => item !== undefined);

		newsContent = map(keyDates, (item, index) => {
			if (item.header === 'News') {
				return (<a className="ellipsis-picker" key={index + 1} onClick={self.handleClick.bind(self, item.eventDate)} >{LocalizationStore.getTranslatedData(`cal_${item.content.replace(/ /ig,"")}`,item.content)}<br /></a>)
			}
		});
		newsContent = filter(newsContent, (item) => item !== undefined);

		eventsContent = map(keyDates, (item, index) => {
			if (item.header === 'CorpEvents') {
				return (<a className="ellipsis-picker" key={index + 1} onClick={self.handleClick.bind(self, item.eventDate)} >{LocalizationStore.getTranslatedData(`cal_${item.content.replace(/ /ig,"")}`,item.content)}<br /></a>)
			}
		});
		eventsContent = filter(eventsContent, (item) => item !== undefined);

		eventsCeoContent = map(keyDates, (item, index) => {
			if (item.header === 'CorpEventsCeo') {
				return (<a className="ellipsis-picker" key={index + 1} onClick={self.handleClick.bind(self, item.eventDate)} >{item.content}<br /></a>)
			}
		});
		eventsCeoContent = filter(eventsCeoContent, (item) => item !== undefined);

		const isShoeReorgContent = reorgContent && !StringUtil.isEmpty(reorgContent[0]);
		const isShowEventsContent = eventsContent && !StringUtil.isEmpty(eventsContent[0]);
		const isShowEarningsContent = earningsContent && !StringUtil.isEmpty(earningsContent[0]);
		const isShowNewsContent = newsContent && !StringUtil.isEmpty(newsContent[0]);
		const isShowCeoContent = eventsCeoContent && !StringUtil.isEmpty(eventsCeoContent[0]);

		return (
			<div id="eventWrapper" className="datepicker-key-date xx-small-normal" style={this.props.style}>
				<p className="eventWrapperTitle">{`${CalenderEventTranslateHelper[CalenderEventConstant.KEY_DATE_FOR]} ${this.props.year}`} </p>
				<div className="eventWrapperContent">
					<div className="events-block-picker custom-scroll-light">
						<div className="custom-scroll">
							<div id="eventsBlockpicker">
								{isShoeReorgContent ? <span><p className="datepickerEventTitle">{CalenderEventTranslateHelper[CalenderEventConstant.Reorganization]}</p>{reorgContent}
									<div>{isShowEventsContent || isShowEarningsContent || isShowNewsContent || isShowCeoContent ? "---------------------------" : ""}</div></span> : ''}
								{isShowEventsContent ? <span><p className="datepickerEventTitle">{CalenderEventTranslateHelper[CalenderEventConstant.Corporate_Events]}</p>{eventsContent}
									<div>{isShowEarningsContent || isShowNewsContent || isShowCeoContent ? "---------------------------" : ""}</div></span> : ''}
								{isShowEarningsContent ? <span><p className="datepickerEventTitle">{CalenderEventTranslateHelper[CalenderEventConstant.Earnings]}</p>{earningsContent}<div>{isShowNewsContent || isShowCeoContent ? "---------------------------" : ""}</div></span> : ''}
								{isShowNewsContent ? <span><p className="datepickerEventTitle">{CalenderEventTranslateHelper[CalenderEventConstant.Major_News]}</p>{newsContent}<div>{isShowCeoContent ? "---------------------------" : ""}</div></span> : ''}
								{isShowCeoContent ? <span><p className="datepickerEventTitle">{CalenderEventTranslateHelper[CalenderEventConstant.Corporate_Events]}</p>{eventsCeoContent}</span> : ''}
							</div>
						</div>
						<ScrollBar scrollId='eventsBlockpicker' vScroll={true} hScroll={true} />
					</div>
				</div>
			</div>
		)
	}
}
