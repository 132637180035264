import React from "react";
import { Tabs, Tab, Modal } from "react-bootstrap";
import ErrorBoundary from "ErrorBoundary";
import RelatedInfoPanel from "./RelatedInfoPanel/RelatedInfoPanel.jsx";
import TabDataGraph from "./TabDataGraph/TabDataGraph.jsx";
import TabFundamental from "./TabFundamental/TabFundamental.jsx";
import TabOwnership from "./TabOwnership/TabOwnership.jsx";
import TabModel from "./TabModel/TabModel.jsx";
import MiniList from "./MiniList/MiniList.jsx";
import DatagraphStore from "../../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import SettingsAction from "../../../Actions/SettingsActions";
import TabType from "../../../Constants/TabType.js";
import GraphType from "GraphType";
import SymbolType from "SymbolType";
import { SettingsConstants } from "../../../Constants/SettingsConstants.js";
import LocalizationStore from 'LocalizationStore';
import ConsoleStore from "ConsoleStore";
import AdobeUtil from "../../../Utils/AdobeUtil";
import AdobeConstants from "../../../Constants/AdobeConstants";
import Alerts from "../../../RayCustomControls/Alerts/Alerts";
import QuickRefWindow from '../../../RayCustomControls/HelpDialog/QuickRefWindow';
import StringUtil from "StringUtil";
//import ConsoleActions from "Actions/ConsoleActions";
import consoleStore from "../../../Stores/ConsoleWindow/ConsoleStore";
import HelpWindowConstants from "../../../Constants/HelpWindowConstants.js";
import store from '../../../Redux/store.js';
import { resizeOwnershipTab, onSearchTextChange } from "../../../Actions/OwnershipActions.js";
import SettingsStore from "SettingsStore";
import ThemeHelper from "ThemeHelper";
import htmlToImage from 'html-to-image';
import { PrintMode } from '../../../print/printmode.js';
import MiniListStore from "MiniListStore";
import { MiniListConstants } from "MiniListConstants";
import DgTabHoldings from "./DgTabHoldings/DgTabHoldings"
import {resizeDgHoldingsTab} from "../../../Actions/DgHoldingsAction"
import PeriodicityHelper from "../../../Utils/PeriodicityHelper.js";
import moment from "moment-timezone";
import UserInfoUtil from 'UserInfoUtil';
import RiPanelNewsSettingDialog from "../NavDataGraph/RelatedInfoPanel/RiPanelNewInfo/RiPanelNewsSettingDialog";
import SymbolEntry from "./TabDataGraph/SymbolEntry/SymbolEntry.jsx";
import { onPlotSymbol, toggleMiniList } from "../../../Actions/DatagraphActions";
import { dispatch } from "../../../Redux/dispatch.js";
import { updateCalenderControlProperties } from "../../../Actions/CalenderControl/CalenderControlAction.js";
import AlertsDialog from "./Alert/AlertsDialog.jsx";
import ContextMenu from "./TabDataGraph/PriceChart/ContextMenu.js";

export default class NavDataGraph extends React.Component {
  constructor() {
    super();
    this.DatagraphStoreStateChange = this.DatagraphStoreStateChange.bind(this);
    this.toggleLeft = this.toggleLeft.bind(this);
    this.handleSymbolChange = this.handleSymbolChange.bind(this);
    this.helpAlert = this.helpAlert.bind(this);
    this.showSymbolConvertedDialog = this.showSymbolConvertedDialog.bind(this);
    this.state = DatagraphStore.getState();
    this.consolestate = ConsoleStore.getStates();
    this.SettingsStateChange = this.SettingsStateChange.bind(this);
    this.onSymbolConvertedDialogAvailableChange = this.onSymbolConvertedDialogAvailableChange.bind(this);
    this.closeSymbolConvertedDialog = this.closeSymbolConvertedDialog.bind(this);
    this.handleCloseWindow = this.handleCloseWindow.bind(this);
    this.MiniListStoreStateChange = this.MiniListStoreStateChange.bind(this);
    ConsoleStore.GetUserAboutModuleInfo();
    // this.keydownHandler = this.keydownHandler.bind(this);
    this.state = {
      ...this.state,
      isQuickRefDisplay: false,
      isShowPerformanceWindow: false
    }
    this.showAlert = false;
    this.alertMessageInterval = null;
    this.alertMessage = null;
    this.isMount = true;
  }

  handleCloseWindow() {
    this.setState({isQuickRefDisplay: false});
  }

  SettingsStateChange() {
    this.consolestate = ConsoleStore.getStates();
    if(this.consolestate.printClicked && this.consolestate.showPopUpBlockerAlert) {
      this.showAlert = this.consolestate.showPopUpBlockerAlert;
    }else{
      this.showAlert = false;
    }
  }
  helpAlert(){
    if(!this.state.isQuickRefDisplay)
      this.setState({ isQuickRefDisplay: true });
    }
  toggleLeft(e) {
    e.preventDefault();
    DatagraphStore.updateIsMiniListopen(!this.state.isMiniListOpen);
    dispatch(toggleMiniList(!this.state.isMiniListOpen))
    !this.state.isMiniListOpen ? ConsoleStore.trackUsage('MiniListIsOpened') : ConsoleStore.trackUsage('MiniListIsClosed');
    this.resizeOwnershipData();
  }

  resizeOwnershipData() {
    if (DatagraphStore.getTabType(this.state.SelectedTabKey) == TabType.Ownership) {
      store.dispatch(resizeOwnershipTab());
    }
    else if (DatagraphStore.getTabType(this.state.SelectedTabKey) == TabType.Holdings) {
      store.dispatch(resizeDgHoldingsTab());
    }
  }

  componentDidMount() {
    // TimeTrackingWindow.isMounted = true;
    // TimeTrackingWindow.isDataGraphMounted = true;
    this.isMount = true;
    ConsoleStore.addChangeListener(this.SettingsStateChange);
    document.addEventListener('keydown', this.keydownHandler);
  }
  UNSAFE_componentWillMount() {
    DatagraphStore.addChangeListener(this.DatagraphStoreStateChange);
    MiniListStore.addChangeListener(this.MiniListStoreStateChange);
    // TimeTrackingWindow.beginTimeTracker();
  }
  componentWillUnmount() {
    DatagraphStore.removeChangeListener(this.DatagraphStoreStateChange);
    MiniListStore.removeChangeListener(this.MiniListStoreStateChange);
    ConsoleStore.removeChangeListener(this.SettingsStateChange);
    this.isMount = false;
    clearTimeout(this.alertMessageInterval);
    document.removeEventListener('keydown', this.keydownHandler);
  }

  DatagraphStoreStateChange() {
    let state = DatagraphStore.getState();
    let currentAction = DatagraphStore.getCurrentAction();
    
    if (currentAction === SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED ||
      currentAction === SettingsConstants.ActionTypes.CURRENCY_SYMBOL_CHANGED ||
      currentAction === SettingsConstants.ActionTypes.CRYPTO_CURRENCY_SYMBOL_CHANGED ||
      currentAction === SettingsConstants.ActionTypes.ETF_SYMBOL_CHANGED ||
      currentAction === SettingsConstants.ActionTypes.CEF_SYMBOL_CHANGED ||
      currentAction === SettingsConstants.ActionTypes.FUND_SYMBOL_CHANGED ||
      currentAction === SettingsConstants.ActionTypes.INDEX_SYMBOL_CHANGED ||
      currentAction === SettingsConstants.ActionTypes.FUTURES_SYMBOL_CHANGED ||
      currentAction === SettingsConstants.ActionTypes.CASHSPOTS_SYMBOL_CHANGED
      || currentAction === SettingsConstants.ActionTypes.INDUSTRYGROUP_SYMBOL_CHANGED
    ) {
      setTimeout(() => {
        DatagraphStore.clearCurrentAction();
      }, 0);

      this.setState(state, () => {
          if (state.processJPEG === true) {
              this.produceChart();
          }
      });
    }
    else if (state.action === SettingsConstants.ActionTypes.ON_MINILIST_SYMBOL_CHANGED){

        this.setState(state);
    }
    else {
        this.setState(state, () => {
            if (state.processJPEG === true) {
                this.produceChart();
            }
        });
    }

  }

  MiniListStoreStateChange(){
    const action = MiniListStore.getCurrentAction();
    if(action === MiniListConstants.ActionTypes.ON_MINILIST_PLAYING){
      this.setState({ CompanyName : '' });
    }
  }

  // keydownHandler = (e) => {
  //   let navDataGraphSetting = DatagraphStore.getNavDataGraphSettings();
  //   if(e.keyCode===79 && e.ctrlKey){
  //     if(navDataGraphSetting.showOwnershipTab == true){
  //       if(this.state.SelectedTabKey != TabType.Ownership){
  //         this.onTabSelectionChanged(TabType.Ownership);
  //       }
  //     }
  //     else{
  //       SettingsAction.toggleOwnershipTab();
  //     }
  //     e.preventDefault();
  //   }
  // }
  onTabSelectionChanged(e) {
    const settings = SettingsStore.getConsoleSettings();
	  settings.KeyDownActiveZone = null;

    const { Symbol } = this.state;
    //Push data to DTM while loading login page   FUNDAMENTAL_PAGE_LOAD AdobeConstants.TOP_LEFT_NAVIGATION
    let dtm = { type: '', data: {} }
    if (TabType.Fundamental == e) {
      dtm.data = {
        selectedTab: "Fundamentals",
        symbol: Symbol,
        userIdRequired: true
      }
      dtm.type = AdobeConstants.TOP_LEFT_NAVIGATION;

      ConsoleStore.trackUsage('FundamentalTabPull');
    } else if (TabType.Datagraph == e) {
      dtm.data = {
        selectedTab: "Datagraph",
        symbol: Symbol,
        userIdRequired: true
      }
      dtm.type = AdobeConstants.TOP_LEFT_NAVCLICK;
      const storeState = DatagraphStore.getState();
      const consoleStoreState = ConsoleStore.getStates();
      consoleStoreState.onSwitchToSymbolTab = true;
      store.dispatch(updateCalenderControlProperties({disableDateTimeField: false}));
      store.dispatch(onPlotSymbol(storeState.Symbol, true, 500, false, false, false, true));
    } else { }

    if (dtm.type != '') AdobeUtil.DTMTrack(dtm.data, dtm.type);

    SettingsAction.updateTabSelection(e);
    if (TabType.Fundamental == e) {
      store.dispatch(updateCalenderControlProperties({disableDateTimeField: true}));
      ConsoleStore.trackUsage('FundamentalTabPull');
      ConsoleStore.getStates().isTabChanged = true;
    }
    else if (e == TabType.Ownership) {
      store.dispatch(onSearchTextChange(""));
      ConsoleStore.getStates().isTabChanged = true;
    }
    else if (e == TabType.Holdings) {
      store.dispatch(onSearchTextChange(""));
      ConsoleStore.getStates().isTabChanged = true;
    }
  }

  handleSymbolChange(symbolInfo) {
    SettingsAction.onSymbolChanged(symbolInfo);
  }
  getActiveTabContent() {
    let tabContent = null;
    let definationSymbol = this.props.definationSymbol;
    var tabType = DatagraphStore.getTabType(this.state.SelectedTabKey);
    const metricDefinitions = ConsoleStore.getMetricDefinition();
    const restMetricDefinitions = ConsoleStore.getRestMetricDefinition();
    const definitions = metricDefinitions && metricDefinitions.industryDefinitionData;
    const restDefination = restMetricDefinitions && restMetricDefinitions.itemData;
    const definationMapKeys = metricDefinitions && metricDefinitions.metricMappingData;

    if (tabType == undefined) {
      return tabContent;
    }
    switch (tabType) {
      case TabType.Datagraph:
        tabContent = <ErrorBoundary>
          <TabDataGraph
            symbolType={this.state.SymbolType}
            definitions={definitions}
            restDefination={restDefination}
            definationSymbol={definationSymbol}
            definationMapKeys={definationMapKeys}
          />
        </ErrorBoundary>;
        break;
      case TabType.Fundamental:
        tabContent = <ErrorBoundary>
          <TabFundamental
            definitions={definitions}
          />
        </ErrorBoundary>;
        break;
      case TabType.Ownership:
        tabContent = <ErrorBoundary><TabOwnership /></ErrorBoundary>;
        break;
      case TabType.Holdings:
        tabContent = <ErrorBoundary><DgTabHoldings /></ErrorBoundary>;
        break;
      case TabType.Model:
        tabContent = <ErrorBoundary><TabModel /></ErrorBoundary>;
        break;
      default:
    }

    return tabContent;
  }

  getSettingDialogBasedOnSymbolType() {
    let settingDialog = null;
    const symbolType = this.state.SymbolType;
    if (symbolType === undefined) {
      return settingDialog;
    }
    settingDialog = <ErrorBoundary><RiPanelNewsSettingDialog /></ErrorBoundary>;
    return settingDialog;
  }

  // closeOwnershipTab(){
  //   SettingsAction.toggleOwnershipTab();
  // }
  
  getTabTitle(tabType) {
      // let clickForOwnership = <span className="icn-closing" onClick={this.closeOwnershipTab}></span>;
    switch (tabType) {
      case TabType.Datagraph:
        return LocalizationStore.getTranslatedData('DGtm_tab', 'DATAGRAPH™');
      case TabType.Fundamental:
        return LocalizationStore.getTranslatedData('Fund_tab', 'FUNDAMENTALS');
      case TabType.Ownership:
        return LocalizationStore.getTranslatedData('OS_tab', 'OWNERSHIP');
      case TabType.Holdings:
        return LocalizationStore.getTranslatedData('ri_iid124', 'HOLDINGS');
      default:
        return tabType;
    }
  }

  showSymbolConvertedDialog() {
    if (this.state.isSymbolConvertedDialogAvailable === undefined || this.state.isSymbolConvertedDialogAvailable === false) {
      this.setState({isSymbolConvertedDialogAvailable: false, isSymbolConvertedDialogOpen: true });
    }else if (this.state.isSymbolConvertedDialogAvailable && this.state.isSymbolConvertedDialogAvailable === true){
      this.setState({isSymbolConvertedDialogOpen: false});
    }
  }

  closeSymbolConvertedDialog() {
    this.setState({ isSymbolConvertedDialogOpen: false });
  }

  onSymbolConvertedDialogAvailableChange() {
    SettingsAction.symbolConvertedDialogAvailableChange(!this.state.isSymbolConvertedDialogAvailable)
    this.setState({ isSymbolConvertedDialogAvailable: !this.state.isSymbolConvertedDialogAvailable});
  }

  getSymbolConvertedDialog() {
    return (
      <div id="symbol-converted">
        <Modal className="modal-popup exceptionWindow" show={this.state.isSymbolConvertedDialogOpen}>
          <Modal.Header>
            <Modal.Title><span className="cap-header">{LocalizationStore.getTranslatedData("srh_sc", "SYMBOL CONVERTED")}</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-material">
              <div className="row">
                <div className="col-xs-12">
                  <p>{LocalizationStore.getTranslatedData("srh_scwonmsg", "The ticker symbol you entered has been converted to O'Neil symbology.")}</p>
                </div>
                <div className="col-xs-12">
                  <span className="display-msg">
                    <input id="doNotShow" type='checkbox' name='symbolConvertedDialogAvailable' checked={this.state.isSymbolConvertedDialogAvailable} onChange={this.onSymbolConvertedDialogAvailableChange} />
                    <label htmlFor="doNotShow">{LocalizationStore.getTranslatedData("srh_noshow", "Do not show this message again")}</label>
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box center">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.closeSymbolConvertedDialog}>{LocalizationStore.getTranslatedData("tp_pt_cw", "Close Window")}</button>
            </span>
          </Modal.Footer>
        </Modal >
      </div>
    );
  }
  hideAlertMessage() {
    this.alertMessageInterval = setTimeout(() => {
      this.alertMessage = null;
      if (this.isMount)
        this.setState({ showAlert: false })
      if (!StringUtil.isEmpty(this.alert))
        this.alert.handleAlertDismiss();
      consoleStore.setPrintAlertClose();
    }, 10000);

  }
  renderAlert() {
    return (
      <Alerts ref={(r) => this.alert = r} showHelp={true} alertMessage={this.alertMessage} helpAlert={this.helpAlert} />
    );
  } 
  produceChart() {
      const node = document.getElementById("document");
      if (node !== null) {
          htmlToImage.toJpeg(node)
              .then((dataUrl) => {
                  const link = document.createElement("a");
                  link.download = "my-image-name.jpeg";
                  link.href = dataUrl;
                  link.click();
              });
      }
  }

  getPeriodicityLabel(periodicity) {
    if(PeriodicityHelper.mapMajorPeriodicities(periodicity) === GraphType.Intraday) {
      return periodicity.slice(0, 8).concat(" ", periodicity.slice(8));
    }

    return periodicity;
  }

  appendLeadingZeroes(n){
    if(n <= 9){
      return "0" + n;
    }
    return n
  }

  getRightFooterInfo(periodicity) {
    let calendarInfo = moment(new Date()).format("ddd DD MMM YYYY");
    
    const printSettings = SettingsStore.getConsoleSettings().printSettings;
    if (printSettings && printSettings.userEndDate) {
      calendarInfo = printSettings.userEndDate;
    }
    
    const symbolInfo = DatagraphStore.getSymbolInfo();
    if(symbolInfo && !symbolInfo.isHistoric && (printSettings.userEndDate == null || printSettings.userEndDate == "")){ 
      calendarInfo = moment(symbolInfo.LastTradeDate).format("ddd DD MMM YYYY")
    }
    
    calendarInfo = StringUtil.capitalizePartOfString(calendarInfo, 0, 3);
    periodicity = this.getPeriodicityLabel(periodicity);

    return calendarInfo && symbolInfo && `${symbolInfo.CompanyName} (${UserInfoUtil.hasLocalSymbol() ? symbolInfo.localSymbol : symbolInfo.Symbol}) ${periodicity} as of ${calendarInfo}`;
  }

  getLeftFooterInfo() {
    const year = ConsoleStore.getStates() && ConsoleStore.getStates().endDate ? ConsoleStore.getStates().endDate.getFullYear() : new Date().getFullYear();
    return `${year} WILLIAM O'NEIL + CO. INC`;
  }

  render() {
    let activeTabContent = this.getActiveTabContent();
    let renderAlerts = this.renderAlert();
    let tabType = DatagraphStore.getTabType(this.state.SelectedTabKey);
    let settingDialog = this.getSettingDialogBasedOnSymbolType();
    let navDataGraphSetting = DatagraphStore.getNavDataGraphSettings();
    let periodicity = tabType === TabType.Fundamental ? GraphType.Daily : navDataGraphSetting.TabDataGraphSettings.Periodicity;
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    let videoMode = tabDataGraphSettings.videoMode;
    if(this.showAlert){
      this.alert.handleAlertShow();
      this.alertMessage = LocalizationStore.getTranslatedData('set_pt_blocked', 'Print Pop-up Blocked');
      // this.hideAlertMessage();
    }
    let state = DatagraphStore.getState();
    var copyColor = ThemeHelper.getThemedBrush("fff000");
    let quoteMessage1 = "Charts powered by Nasdaq Last Sale";
    let consoleStoreState = consoleStore.getStates();

    if (consoleStoreState.showFooter === true) {
        state.processJPEG = false;
        consoleStoreState.showFooter = false;
    }

    if (state.processJPEG === true) {
        consoleStoreState.showFooter = true;
    }

    let setPadding = "0 0 0";
    if (videoMode === true) {
      setPadding = "0 0 96px";
    }
    const consoleClass = PrintMode.printing ? "panarayConsole print-mode print-datagraph":"panarayConsole";
    const rightFooterInfo = this.getRightFooterInfo(periodicity);
    const leftFooterInfo = this.getLeftFooterInfo();
 
    return (
        <div className={consoleClass} id="document">
          <div className="newsSettingDialog"> {settingDialog}</div>
          <div className="extender">
          <div className="tabWrap" style={{ opacity: PrintMode.printing ? '0.5' : '1' }}>
              {renderAlerts}
              <ErrorBoundary>
                <SymbolEntry />
                {videoMode === false && !PrintMode.printing &&
               		<Tabs onSelect={this.onTabSelectionChanged.bind(this)}
                      activeKey={this.state.SelectedTabKey} 
                      id="uncontrolled-tab-example"
                      className="tabings">
                {this.state.TabCollection.map((tabitem) =>
                  // tabitem.Header != TabType.Ownership || navDataGraphSetting.showOwnershipTab == true ? 
                  <Tab key={tabitem.Header} eventKey={tabitem.Header}
                    title={this.getTabTitle(tabitem.Header)}></Tab> 
                    // : ''
                )}
              </Tabs>}
              </ErrorBoundary>
            </div>
          <div className="COLS">
            {videoMode === false && !PrintMode.printing &&
            <div id="miniListBox" className="CELL mini-list-box">
              <div id="wrapperLeft" ref={(ref) => this.miniList = ref} className={this.state.isMiniListOpen ? "" : "active-left"}
                style={{ height: "100%", position: "relative" }}>
                <ErrorBoundary><MiniList toggleLeft={this.toggleLeft}></MiniList></ErrorBoundary>
              </div>
              </div>}
              <div className="CELL _FLEX panaray-container">
                <div id='dataGraphBG' className="dataGraphBG displayNone" />             
                  <div id="mainBlock">
                    <div id="page-content-wrapper">
                      <div className="page-content" style={{ padding: setPadding }} data-spy="scroll" data-target="#spy">
                        <div className="container-fluid">
                          <div className="row">
                            <div id="chart_container">
                              {/* <AboutDialog /> */}
                              {activeTabContent}
                              <ContextMenu />
                              <AlertsDialog />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            {videoMode === false && !PrintMode.printing &&
              <div id="relatedInfoBox" className="CELL related-info-box">
                <ErrorBoundary><RelatedInfoPanel resizeOwnershipData={this.resizeOwnershipData} /></ErrorBoundary>
              </div>}
          </div>
          </div>
        {videoMode === true && consoleStoreState.showFooter === true &&
            <div style={{
                position: "absolute",
                height: 'Auto',
                width: 'Auto',
                font: 'calibri',
                fontSize: '18px',
                fontWeight: 'Bold',
                background: "Transparent",
                opacity: '1',
                borderColor: "Transparent",
                borderStyle: "solid",
                borderWidth: "1px",
                left: '45px',
                color: copyColor, zIndex: '10'
            }}>
                <div className="copyRightSymbol"
                     onMouseDown={(e) => this.handleMouseDown(e)}
                     style={{
                     fontSize: '40px',
                     cursor: "pointer",
                     marginTop: (window.innerHeight - 60) + 'px'
                 }}>
                    <div>{quoteMessage1.toUpperCase()}
                    </div>
                </div>
            </div>}
          <div className="print-footer">
              <div className="print-copyright">{leftFooterInfo}</div>
              <div className="print-symbol-info">{rightFooterInfo}</div>
          </div>
          <div>{this.getSymbolConvertedDialog()}</div>
          {this.state.isQuickRefDisplay && <QuickRefWindow helpType={HelpWindowConstants.Print} onCloseWindow={this.handleCloseWindow} ><span></span></QuickRefWindow>}
        </div>
    );
  }
}