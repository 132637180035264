import BaseServiceApi from 'BaseServiceApi';

import GetRSSSourcesRequest from '../Requests/GetRSSSourcesRequest';
import GetNewsDetailsRequest from '../Requests/GetNewsDetailsRequest';
import GetFilterListValidationRequest from '../Requests/GetFilterListValidationRequest';

export const GetRSSSources = () => {
  const req = new GetRSSSourcesRequest();
  return BaseServiceApi.processServiceCall(req);
}

export const GetNewsDetails = (streamerList, newsState, index) => {
  const req = new GetNewsDetailsRequest(streamerList, newsState, index);
  return BaseServiceApi.processServiceCall(req);
}

export const GetFilterListValidation = (lists) => {
  const req = new GetFilterListValidationRequest(lists);
  return BaseServiceApi.processServiceCall(req);
}