import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import StringUtil from "StringUtil";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";

const weeklyText = LocalizationStore.getTranslatedData("sb_wklylabel", "WEEKLY");

export default class WeeklyData {
  constructor(weeklyData, headers, stockInfo) {
    this.labelWidth = 46;
    this.stockInfo = stockInfo;
    this.positiveBrush = ThemeHelper.getThemedBrush("positiveDataText");
    this.negativeBrush = ThemeHelper.getThemedBrush("negativeDataText");
    this.cellItemConut = 9;
    this.setWeeklyData(weeklyData, headers);
  }

  setWeeklyData(weeklyData, headers) {
    this.rowSource = [];
    let startPt = 0;
    if (!StringUtil.isEmpty(weeklyData) && weeklyData.length > 1) {
      startPt = DateHelper.getUtcTicksfromTimeStamp(weeklyData[0].CurrentWeekDate) === DateHelper.getUtcTicksfromTimeStamp(weeklyData[1].CurrentWeekDate) ? -1 : 0;
    }
    const endPt = startPt + this.cellItemConut + 1;
    for (let i = 0; i < 7; i++) {
      const row = {
        cells: []
      };
      for (let j = startPt; j < endPt; j++) {
        let cell = {};
        if (i === 0 && j === startPt) {
          cell.value = weeklyText;
          cell.IsLabelCell = true;
          cell.textAlign = "left";
        } else if (i === 0 && j > startPt) {
          const weeklyDataExtraItems = weeklyData.length - this.cellItemConut;
          const weekData = weeklyData[weeklyData.length - (j + weeklyDataExtraItems)];
          let date = "";
          if (weekData) {
            if (weekData.CurrentWeekDate) {
              const weekDate = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(weekData.CurrentWeekDate), this.stockInfo.MomentZoneID);
              date = weekDate ? DateHelper.getMonthAndDay(weekDate) : "";
            }
          }
          cell.value = date;
          cell.textAlign = "center";
        } else if (j === startPt) {
          cell.value = headers[i - 1];
          cell.IsLabelCell = true;
          cell.textAlign = "left";
        } else {
          cell = this.getDataCell(weeklyData, i, j, headers);
        }
        if (j === 0) {
          switch (i) {
            case 0:
              cell.value = LocalizationStore.getTranslatedData("sb_wklylabel", cell.value);
              break;
            case 1:
              cell.value = LocalizationStore.getTranslatedData("sb_dgrg", cell.value);
              break;
            case 2:
              cell.value = LocalizationStore.getTranslatedData("sb_avol", cell.value);
              break;
            case 3:
              cell.value = LocalizationStore.getTranslatedData("sb_udvol", cell.value);
              break;
            case 4:
              cell.value = LocalizationStore.getTranslatedData("sb_adrg", cell.value);
              break;
            case 5:
              cell.value = LocalizationStore.getTranslatedData("sb_rs", cell.value);
              break;
            case 6:
              cell.value = LocalizationStore.getTranslatedData("sb_grprnk", cell.value);
              break;
            default:
              break;
          }
        }
        row.cells.push({
          cell
        });
      }
      this.rowSource.push(row);
    }
  }

  getDataCell(weeklyData, row, cell, headers) {
    const weeklyDataExtraItems = weeklyData.length - this.cellItemConut;
    const item = weeklyData[weeklyData.length - (cell + weeklyDataExtraItems)];
    if (row === 1) {
      return this.getDataGraphRating(item);
    }
    if (row === 2) {
      return this.avgDailyVol(item, headers);
    }
    if (row === 3) {
      return this.getUpdwnVol(item);
    }
    if (row === 4) {
      return this.getAccuDistRating(item);
    }
    if (row === 5) {
      return this.getRelativeStrength(item);
    }
    if (row === 6) {
      return this.getGroupRank(item);
    }
    return {
      cell: {}
    };
  }

  avgDailyVol(item, headers) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.AvgDailyVol || item.AvgDailyVol === 0) {
        const averageVolumeDivider = headers[1].includes("000") ? 1000 : 1;
        if (this.stockInfo.SymTypeEnum === SymbolType.INTERNATIONALSTOCK) {
          item.AvgDailyVol = Math.floor(item.AvgDailyVol / averageVolumeDivider);
        }
        //  cell.value = ExtremeDataValue.toLocalValue(item.Avol, 0);
        cell.value = ExtremeDataValue.getFormattedNumber(item.AvgDailyVol, 0);
      }
    }
    return cell;
  }

  getDataGraphRating(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.DatagraphRating || item.DatagraphRating === 0) {
        cell.value = item.DatagraphRating;
      }
    }
    return cell;
  }

  getGroupRank(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.GroupRank || item.GroupRank === 0) {
        cell.value = item.GroupRank;
      }
    }
    return cell;
  }

  getRelativeStrength(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.RelStrength || item.RelStrength === 0) {
        cell.value = item.RelStrength;
      }
    }
    return cell;
  }

  getAccuDistRating(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.ADRating || item.ADRating === 0) {
        cell.color = item.ADRating < 0 ? this.negativeBrush : this.positiveBrush;
        cell.isPositive = !(item.ADRating < 0);
        cell.isUseCusColor = true;
        cell.value = item.ADRating === -100 ? "" : item.ADRating;
      }
    }
    return cell;
  }

  getUpdwnVol(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.UpDownVol || item.UpDownVol === 0) {
        cell.value = ExtremeDataValue.formatValue(item.UpDownVol, 1);
      }
    }
    return cell;
  }
}