import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { LabelText } from '../Presentational';
import { CompareMenuType } from "CompareTabType";

class TimeLineCalenderView extends Component {
    render() {
        const { calenderLabels } = this.props;
        return (
            <svg className="svg svg-month-pointer">
                {calenderLabels !== null && calenderLabels.map((itm, i) => itm.Label && <Fragment key={i}><LabelText dx="-4" dy="2" isHighlighted={true} textAnchor="end" textPosX={itm.Axis[0]} textPosY={8} textValue={itm.Label} /><line x1={itm.Axis[0]} x2={itm.Axis[0]} y1="0" y2="100%" className="svg-month-pointing" /></Fragment>)}
            </svg>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.compareChart;
    const { calenderLabels } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

    return ({ calenderLabels: calenderLabels });
}

export default connect(mapStateToProps)(TimeLineCalenderView);