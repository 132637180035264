
'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

let _extends = Object.assign || function (target) { for (let i = 1; i < arguments.length; i++) { let source = arguments[i]; for (let key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { let target = {}; for (let i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import PropTypes from 'prop-types';

import React from "react";

export default class TreeView extends React.Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      collapsed: this.props.collapsed
    }
  }

  handleClick() {
    this.setState({ collapsed: !this.state.collapsed });
    if (this.props.onClick) {
      let _props;
      (_props = this.props).onClick.apply(_props, arguments);
    }
  }

  handleDragOver(entry, event) {
    return this.props.handleDragOver(entry, event);
  }

  handleFolderDragStart(entry, event) {
    return this.props.handleFolderDragStart(entry, event);
  }

  handleFolderDrop(entry, event) {
    return this.props.handleFolderDrop(entry, event);
  }

  handleDragEnd(entry, event) {
    return this.props.handleDragEnd(entry, event);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      collapsed: nextProps.collapsed
    });
  }

  render() {
    let _props2 = this.props;
    //let _props2$collapsed = _props2.collapsed;
    //let collapsed = _props2$collapsed === undefined ? this.state.collapsed : _props2$collapsed;
    let collapsed = this.state.collapsed;
    let _props2$className = _props2.className;
    let className = _props2$className === undefined ? '' : _props2$className;
    let _props2$itemClassName = _props2.itemClassName;
    let itemClassName = _props2$itemClassName === undefined ? '' : _props2$itemClassName;
    let nodeLabel = _props2.nodeLabel;
    let children = _props2.children;
    let rootNode = _props2.rootNode;
    let folderNode = _props2.folderNode;
    let nodeId = _props2.nodeId;
    let childObj = _props2.childObj;
    let isVisible = _props2.isVisible;

    let rest = _objectWithoutProperties(_props2, ['collapsed', 'className', 'itemClassName', 'nodeLabel', 'children', 'rootNode', 'folderNode', 'nodeId', 'childObj', 'handleDragOver', 'handleFolderDragStart', 'handleFolderDrop', 'handleDragEnd', 'isVisible']);

    let arrowClassName = (rootNode ? 'icn-tree-view_arrow' : '');
    let containerClassName = (folderNode ? 'tree-view_children_folder folder-level-1' : 'tree-view_children folder-level-2');
    let folderClassName = (folderNode ? 'fa icn-folder-view-open' : '');

    if (isVisible) {
      if (collapsed) {
        if (arrowClassName != '') {
          arrowClassName += ' icn-tree-view_arrow-collapsed';
        }
        containerClassName += ' tree-view_children-collapsed folder-level-3';
        if (folderClassName != '')
          folderClassName += 'fa icn-folder-view';
      }
      let arrow = null;
      let childHoverClass = rootNode ? '' : 'child-hover';
      if (arrowClassName != '') {
        arrow = React.createElement('div', _extends({}, rest, {
          className: className + ' ' + arrowClassName,
          id: nodeId
        }));
      }
      let folder = null;
      if (folderClassName != '') {
        folder = React.createElement('div', _extends({}, rest, {
          className: className + ' ' + folderClassName,
          id: nodeId
        }));
      }

      return (React.createElement(
        'div',
        { className: 'tree-view' },
        React.createElement(
          'div',
          {
            className: 'tree-view_item ' + childHoverClass + itemClassName,
            onClick: this.handleClick,
            id: nodeId,
            draggable: !rootNode,
            onDragOver: this.handleDragOver.bind(this, childObj),
            onDragStart: this.handleFolderDragStart.bind(this, childObj),
            onDrop: this.handleFolderDrop.bind(this, childObj),
            onDragEnd: this.handleDragEnd.bind(this, childObj)
          },
          arrow,
          React.createElement(
            'span',
            {
              onClick: this.handleClick,
              style: { display: "inline-block", height: "21px", border: "1px solid transparent" },
              id: nodeId
            },
            folder,
            nodeLabel
          )
        ),
        React.createElement(
          'div',
          { className: containerClassName },

          children
        )
      ));
    }
    else
      return null;
  }
}

TreeView.propTypes = {
  collapsed: PropTypes.bool,
  nodeLabel: PropTypes.node.isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  rootNode: PropTypes.bool,
  folderNode: PropTypes.bool
}
