import React from "react";
import DGGridDataRow from "./DGGridDataRow.jsx";
import VirtualList from "VirtualList";
import SettingsStore from "SettingsStore";
import ThemeHelper from "ThemeHelper";

export default class DGGridColumn extends React.Component {
    constructor() {
        super();
        //this.getItemRenderer = this.getItemRenderer.bind(this);
        //this.settingsStoreStateChange = this.settingsStoreStateChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        SettingsStore.addChangeListener(this.settingsStoreStateChange);        
    }

    componentWillUnmount() {
        SettingsStore.removeChangeListener(this.settingsStoreStateChange);        
    }

    settingsStoreStateChange = () => {
        //this.setState({  });
        this.forceUpdate();
    }

    getStyleColor = (isColumnSharesChanged, val) => {
        if (isColumnSharesChanged === true)
            return null;
        else if (val > 0)
            return ThemeHelper.getThemedBrush("positiveDataText");
        else if (val < 0)
            return ThemeHelper.getThemedBrush("negativeDataText");
        else
            return null;
    }

    getItemRenderer = (item, top, i) => {
        const self = this;
        return (<div className="report-data-group" style={{ height: 20, top: top }} key={i} >
            {self.props.item[0].reportList.length > i ?
                <DGGridDataRow
                    key={`row1${i}`}
                    i={i}
                    value={self.props.item[0].reportList[i]}
                    searchText={self.props.searchText}
                    onPlotSymbol={self.props.onPlotSymbol}
                    isColumnPortChanged={self.props.isColumnPortChanged}
                    isColumnRankChanged={self.props.isColumnRankChanged}
                    isColumnSharesChanged={self.props.isColumnSharesChanged}
                    isColumnRsChanged={self.props.isColumnRsChanged}
                    onColumnRankChanged={self.props.onColumnRankChanged}
                    onColumnPortChanged={self.props.onColumnPortChanged}
                    onColumnSharesChanged={self.props.onColumnSharesChanged}
                    onColumnRsChanged={self.props.onColumnRsChanged}
                    onMessageMouseMove={self.props.onMessageMouseMove}
                    ShowToolTip={self.props.ShowToolTip}
                    onMessageMouseLeave={self.props.onMessageMouseLeave}
                    onHideToolTopBox={self.props.onHideToolTopBox}
                    AbbreviateSharesValueForHeader={self.props.AbbreviateSharesValueForHeader}
                    textSize={self.props.textSize}
                    onBlockMenuItemLeftClicked={self.props.onBlockMenuItemLeftClicked}
                    onBlockMenuItemRightClicked={self.props.onBlockMenuItemRightClicked}
                    sharesHeldColor={this.getStyleColor(self.props.isColumnSharesChanged, self.props.item[0].reportList[i].sharesHeld - self.props.item[0].reportList[i].shares1QAgo)}
                    shares1QAgoColor={this.getStyleColor(self.props.isColumnSharesChanged, self.props.item[0].reportList[i].shares1QAgo - self.props.item[0].reportList[i].shares2QAgo)}
                    shares2QAgoColor={this.getStyleColor(self.props.isColumnSharesChanged, self.props.item[0].reportList[i].shares2QAgo - self.props.item[0].reportList[i].shares3QAgo)}
                    activeTab={self.props.activeTab}
                /> : <div className="report-sector" />}

            {self.props.item[1].reportList.length > i ?
                <DGGridDataRow
                    key={`row2${i}`}
                    i={i}
                    value={self.props.item[1].reportList[i]}
                    searchText={self.props.searchText}
                    onPlotSymbol={self.props.onPlotSymbol}
                    isColumnPortChanged={self.props.isColumnPortChanged}
                    isColumnRankChanged={self.props.isColumnRankChanged}
                    isColumnSharesChanged={self.props.isColumnSharesChanged}
                    isColumnRsChanged={self.props.isColumnRsChanged}
                    onColumnRankChanged={self.props.onColumnRankChanged}
                    onColumnPortChanged={self.props.onColumnPortChanged}
                    onColumnSharesChanged={self.props.onColumnSharesChanged}
                    onColumnRsChanged={self.props.onColumnRsChanged}
                    onMessageMouseMove={self.props.onMessageMouseMove}
                    ShowToolTip={self.props.ShowToolTip}
                    onMessageMouseLeave={self.props.onMessageMouseLeave}
                    onHideToolTopBox={self.props.onHideToolTopBox}
                    AbbreviateSharesValueForHeader={self.props.AbbreviateSharesValueForHeader}
                    textSize={self.props.textSize}
                    onBlockMenuItemLeftClicked={self.props.onBlockMenuItemLeftClicked}
                    onBlockMenuItemRightClicked={self.props.onBlockMenuItemRightClicked}
                    sharesHeldColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[1].reportList[i].sharesHeld - self.props.item[1].reportList[i].shares1QAgo)}
                    shares1QAgoColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[1].reportList[i].shares1QAgo - self.props.item[1].reportList[i].shares2QAgo)}
                    shares2QAgoColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[1].reportList[i].shares2QAgo - self.props.item[1].reportList[i].shares3QAgo)}
                    activeTab={self.props.activeTab}
                /> : <div className="report-sector" />}

            {self.props.item[2].reportList.length > i ?
                <DGGridDataRow
                    key={`row3${i}`}
                    i={i}
                    value={self.props.item[2].reportList[i]}
                    searchText={self.props.searchText}
                    onPlotSymbol={self.props.onPlotSymbol}
                    isColumnPortChanged={self.props.isColumnPortChanged}
                    isColumnRankChanged={self.props.isColumnRankChanged}
                    isColumnSharesChanged={self.props.isColumnSharesChanged}
                    isColumnRsChanged={self.props.isColumnRsChanged}
                    onColumnRankChanged={self.props.onColumnRankChanged}
                    onColumnPortChanged={self.props.onColumnPortChanged}
                    onColumnSharesChanged={self.props.onColumnSharesChanged}
                    onColumnRsChanged={self.props.onColumnRsChanged}
                    onMessageMouseMove={self.props.onMessageMouseMove}
                    ShowToolTip={self.props.ShowToolTip}
                    onMessageMouseLeave={self.props.onMessageMouseLeave}
                    onHideToolTopBox={self.props.onHideToolTopBox}
                    AbbreviateSharesValueForHeader={self.props.AbbreviateSharesValueForHeader}
                    textSize={self.props.textSize}
                    onBlockMenuItemLeftClicked={self.props.onBlockMenuItemLeftClicked}
                    onBlockMenuItemRightClicked={self.props.onBlockMenuItemRightClicked}
                    sharesHeldColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[2].reportList[i].sharesHeld - self.props.item[2].reportList[i].shares1QAgo)}
                    shares1QAgoColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[2].reportList[i].shares1QAgo - self.props.item[2].reportList[i].shares2QAgo)}
                    shares2QAgoColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[2].reportList[i].shares2QAgo - self.props.item[2].reportList[i].shares3QAgo)}
                    activeTab={self.props.activeTab}
                /> : <div className="report-sector" />}

            {self.props.item[3].reportList.length > i ?
                <DGGridDataRow
                    key={`row4${i}`}
                    i={i}
                    value={self.props.item[3].reportList[i]}
                    searchText={self.props.searchText}
                    onPlotSymbol={self.props.onPlotSymbol}
                    isColumnPortChanged={self.props.isColumnPortChanged}
                    isColumnRankChanged={self.props.isColumnRankChanged}
                    isColumnSharesChanged={self.props.isColumnSharesChanged}
                    isColumnRsChanged={self.props.isColumnRsChanged}
                    onColumnRankChanged={self.props.onColumnRankChanged}
                    onColumnPortChanged={self.props.onColumnPortChanged}
                    onColumnSharesChanged={self.props.onColumnSharesChanged}
                    onColumnRsChanged={self.props.onColumnRsChanged}
                    onMessageMouseMove={self.props.onMessageMouseMove}
                    ShowToolTip={self.props.ShowToolTip}
                    onMessageMouseLeave={self.props.onMessageMouseLeave}
                    onHideToolTopBox={self.props.onHideToolTopBox}
                    AbbreviateSharesValueForHeader={self.props.AbbreviateSharesValueForHeader}
                    textSize={self.props.textSize}
                    onBlockMenuItemLeftClicked={self.props.onBlockMenuItemLeftClicked}
                    onBlockMenuItemRightClicked={self.props.onBlockMenuItemRightClicked}
                    sharesHeldColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[3].reportList[i].sharesHeld - self.props.item[3].reportList[i].shares1QAgo)}
                    shares1QAgoColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[3].reportList[i].shares1QAgo - self.props.item[3].reportList[i].shares2QAgo)}
                    shares2QAgoColor={this.getStyleColor(self.props.isColumnSharesChanged,self.props.item[3].reportList[i].shares2QAgo - self.props.item[3].reportList[i].shares3QAgo)}
                    activeTab={self.props.activeTab}
                /> : <div className="report-sector" />
            }
        </div>);
    }
    getMaxCountIndex() {
        let maxCountIndex = 0;
        if (this.props.item!==undefined)
        {
            for (let i = 1; i < this.props.item.length; i++) {
                if (this.props.item[maxCountIndex].reportList.length < this.props.item[i].reportList.length)
                maxCountIndex = i;
            }
        }
        return maxCountIndex;
    }
    render() {
        const self = this;
        const maxCountIndex = self.getMaxCountIndex();
        return (
            (this.props.item!==undefined && this.props.item.length>0)?
            <div className={`${self.props.textSize} report-grids`}>
                <VirtualList id={"ownershipListInfo"} ref={(ref) => { this.itemlst1 = ref }}
                    items={self.props.item[maxCountIndex].reportList}
                    itemHeight={20}
                    itemRenderer={this.getItemRenderer}
                //showScrollOnHover={true}
                />
                <div className="clearfix"></div>
            </div>
            :''
        );
    }
}