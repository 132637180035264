import { SettingsConstants } from "Constants/SettingsConstants.js";
const { ActionTypes } = SettingsConstants;
import { ComparisonAndRotationMenuConstants } from "Constants/RotationGraphConstants";
import { extend } from "underscore";
extend(ActionTypes, ComparisonAndRotationMenuConstants.ActionTypes);

const initialState = {
    selectedTab: '',
    compGroupsMenuInit: false,
    rotationSymbolsMenuInit: false,
    rotationGroupsMenuInit: false,
    groupsMainData:{},
    isMenuLoading: true,
    menuId: ''
}

let selectedTab;
const NavCompareReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
            selectedTab = action.selectedTab;
            return {
                ...state,
                selectedTab: action.selectedTab
            }
            
        case ActionTypes.UPDATE_MENU_ID:
            return {
                ...state,
                menuId: action.menuId
            }

        case ActionTypes.UPDATE_GROUPS_MAIN_DATA:
            return {
                ...state,
                groupsMainData: action.response
            }

        case ActionTypes.DATA_LOADED_SUCCESS:
                return {
                    ...state,
                    compGroupsMenuInit: action.compGroupsMenuInit,
                    rotationSymbolsMenuInit: action.rotationSymbolsMenuInit,
                    rotationGroupsMenuInit: action.rotationGroupsMenuInit
                }
        default:
            return state
    }
}

export {selectedTab};
export default NavCompareReducer;