import PropTypes from 'prop-types';
import React from "react";
import ChartVisual from "./ChartVisual.jsx";
import Pointer from "Pointer";
import ThemeHelper from "ThemeHelper";
import ExtremeDataValue from "ExtremeDataValue";
import textWidth from "text-width";
import textHeight from "text-height";
import TabDataGraphActionClass from "../../../Actions/TabDataGraphAction.js";
import LabelText from '../Timeline/LabelText.jsx';
import TimeTrackingWindow from "TimeTrackingWindow";
import SettingsStore from "SettingsStore";
import AnnotationUtil from '../../../Utils/AnnotationUtil.js';
import { dispatch } from '../../../Redux/dispatch.js';
import { PriceChartConst } from '../../../Constants/PriceChartConstants.js';
import { showContextMenu, updateContextHit } from '../../../Actions/PricePanelActions.js';

export default class LineVisual extends ChartVisual {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.RayChartContainer = undefined;
    this.labelPointsArr = [];
    this.lineWidth = props.nodeWidth ? props.nodeWidth < 12 ? 1 : ((props.nodeWidth * 4) / 16) : 1;
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
    this.onCanvasMouseLeve = this.onCanvasMouseLeve.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.state = {
      refresh:true,
      source: props.DataSource,
      source2: props.DataSource2,
      x: 0,
      y: 0,
      screenX: 0,
      screenY: 0,
      showBox: false,
      showBox2: false,
      dragging: false,
      draggable: props.Draggable,
      shouldUpdate: true,
      lineColor: props.LineColor,
      lineColor2: props.LineColor2,
      lineThickness: props.LineThickness ? props.LineThickness : this.lineWidth,
      lineThickness2: props.LineThickness2 ? props.LineThickness2 : this.lineWidth,
      pointerTextColor: props.pointerTextColor ? props.pointerTextColor : "white",
      pointerEvents: "none",
      dashArray: this.props.DashArray ? this.props.DashArray : [0, 0],
      opacity: props.Opacity,
      fill: props.Fill ? props.Fill : "none",
      chartFill: props.Opacity ? true : false,
      accDist: props.accDist,
      rsNumber: props.rsNumber,
      zIndex: props.zIndex ? props.zIndex : 0,
      priceMenu: props.PriceMenu,
      lineID: props.lineID ? props.lineID : '',
      lineID2: props.lineID2 ? props.lineID2 : '',
      Type: props.Type ? props.Type : 'undefined',
      messageColor: props.messageColor ? props.messageColor : 'black',
      showInfoPointer: false,
      maValue: props.maValue ? props.maValue : 0,
      lastPrice: props.lastPrice ? props.lastPrice : 0,
      showR: props.showR,
      ylastPrice: props.ylastPrice ? props.ylastPrice : -10000,
      chartHeight: props.chartHeight ? props.chartHeight : 4000,
      dgStore: undefined
    };
    this.clearAllTimeOuts = this.clearAllTimeOuts.bind(this);
    this.getTextWidth = this.getTextWidth.bind(this);
    this.getTextHeight = this.getTextHeight.bind(this);
    this.hitTestForLabel = this.hitTestForLabel.bind(this);
    this.march = this.march.bind(this);
  }
  //componentWillReceiveProps(nextProps) {
  //    if (nextProps.DataSource || nextProps.DataSource.length === 0) {
  //        this.clearChart();
  //    }
  //    this.lineWidth = nextProps.nodeWidth ? nextProps.nodeWidth < 12 ? 1 : 4 : 1;
  //    this.setState({ DataSource: nextProps.DataSource });
  //}

  componentDidMount() {
    if (this.main && !this.eventsAttached) {
      this.RayChartContainer = this.getClosestParent(this.main, "rayChartContainer");
      if (this.props.isPrintMode) {
        return;
      }
      this.RayChartContainer.addEventListener("mousemove", this.onCanvasMouseMove, false);
      //this.RayChartContainer.addEventListener("mousemove", this.onXMouseMove.bind(this), false);
      this.RayChartContainer.addEventListener("mouseleave", this.onCanvasMouseLeve, false);
      this.RayChartContainer.addEventListener("mousedown", this.handleRightClick, false);
      this.eventsAttached = true;
    }
  }

  UNSAFE_componentWillUpdate() {
      if (this.main && !this.eventsAttached) {
          this.RayChartContainer = this.getClosestParent(this.main, "rayChartContainer");
          if (this.props.isPrintMode) {
              return;
          }
          this.RayChartContainer.addEventListener("mousemove", this.onCanvasMouseMove, false);
          //this.RayChartContainer.addEventListener("mousemove", this.onXMouseMove.bind(this), false);
          this.RayChartContainer.addEventListener("mouseleave", this.onCanvasMouseLeve, false);
          // this.RayChartContainer.addEventListener("mousedown", this.handleRightClick, false);
          this.eventsAttached = true;
      }
  }

  componentWillUnmount() {
      if (this.RayChartContainer) {
          if (this.props.isPrintMode) {
              return;
          }
          this.RayChartContainer.removeEventListener("mousemove", this.onCanvasMouseMove, false);
          //this.RayChartContainer.removeEventListener("mousemove", this.onXMouseMove.bind(this), false);
          this.RayChartContainer.removeEventListener("mouseleave", this.onCanvasMouseLeve, false);
          this.RayChartContainer.removeEventListener("mousedown", this.handleRightClick, false);
          this.clearAllTimeOuts();
      }
  }

  clearAllTimeOuts () {
    this.timeout && clearTimeout(this.timeout);
    this.timeoutMouseLeave && clearTimeout(this.timeoutMouseLeave);
    this.marchingLines &&      clearTimeout(this.marchingLines);
    this.timeoutCanvasMouseLeve && clearTimeout(this.timeoutCanvasMouseLeve);
    this.mouseMoveTimeout && clearTimeout(this.mouseMoveTimeout);
  }

  updatePointer(contentL, contentR, yValue, boxL, boxR, numL) {
    if (this.pointer) {
      this.pointer.updatePointer(contentL, contentR, yValue, boxL, boxR, numL);
    }
  }
  getTextWidth (text) {
    let width = textWidth(text, {
      family: "calibri",
      size: 8
    });
    return width;
  }

  getTextHeight (text) {
    let height = textHeight(text, {
      family: "calibri",
      size: 8
    });
    return height.height;
  }

  hitTestForLabel (x, y) {
    let isHit = false;
    for (let i = 0; i < this.labelPointsArr.length; i++) {
      if (x >= this.labelPointsArr[i].x - ((this.labelPointsArr[i].width + 8) / 2) && x <= this.labelPointsArr[i].x + (this.labelPointsArr[i].width + 8) / 2
        && y >= this.labelPointsArr[i].y - ((this.labelPointsArr[i].height + 10) / 2) && y <= this.labelPointsArr[i].y + ((this.labelPointsArr[i].height) / 2)) {
        isHit = true;
        return isHit;
      }
    }

    return isHit;
  }

  handleRightClick(e) {

    if (!this.main || e.button !== 2) return;

    let dimensions = this.main.getBoundingClientRect();
    // let chartWidth = dimensions.right;
    // let chartHeight = dimensions.bottom;
    let xAxis = parseInt(e.clientX - dimensions.left);
    let yAxis = parseInt(e.clientY - dimensions.top);
    let isHit = this.hitTest(xAxis, yAxis);
    
    if (isHit === 1 && this.props.updateContextHit) {
      this.props.updateContextHit()
      dispatch(showContextMenu(e, dimensions.top))
      e.preventDefault();
    }
    else
    if (isHit === 2 && this.props.updateContextHit) {
      this.props.updateContextHit()
      dispatch(showContextMenu(e, dimensions.top))
      e.preventDefault();
    }
    else
    if (isHit === 0) {
      dispatch(updateContextHit({}))
      e.preventDefault();
    }
    else {
      if (this.hitTestForLabel(xAxis, yAxis)) {
        dispatch(updateContextHit({}));
        e.preventDefault();
      }
    }
  }
  getState() {
    return this.state;
  }

  onCanvasMouseLeve() {
  if (this.main && this.RayChartContainer) {
    this.timeoutCanvasMouseLeve = setTimeout(() => {
      this.setState({ showBox: false, showBox2: false, showInfoPointer: false, screenX: -1, screenY: -1 });
    }, 0);
    }
  }
  onXMouseMove(e) {
    if (this.state.dragging && this.state.draggable) {
      this.setState({
        showBox: false
      });
      return;
    }

    this.mouseMoveTimeout && clearTimeout(this.mouseMoveTimeout);
    this.mouseMoveTimeout = setTimeout(() => {
        if (!this.main) return;
        let dimensions = this.main.getBoundingClientRect();
        let chartHeight = dimensions.bottom;
        let xAxis = parseInt(e.clientX - dimensions.left);
        let yAxis = parseInt(e.clientY - dimensions.top);
        let isHit = this.hitTest(xAxis, yAxis);
        if (isHit && this.state.lineID && this.state.lineID.length > 0) {
            TabDataGraphActionClass.onLineVisualSelected();
            let len = this.state.lineID.length * 6;
            xAxis = xAxis - len;
            if (xAxis < len) xAxis = xAxis + len;
            if (chartHeight - 40 < yAxis) yAxis = yAxis - 50;
            if (yAxis < 40) yAxis = yAxis + 50;
            if (this.props.isToolTip) {
                this.setState({
                    showBox: true,
                    screenX: xAxis,
                    screenY: yAxis
                });
            }
        }
        else {
            this.setState({
                showBox: false
            });
        }
    }, 0);
  }
  handleMouseLeave() {
    this.timeoutMouseLeave = setTimeout(() => {
      this.setState({ showBox: false, showInfoPointer: false });
    }, 0);
  }

  drawHiLoPoints() {
    const source = this.state.source;
    const textColor = ThemeHelper.getThemedBrush("scaleText");
    if (!source) {
        return (<div></div>);
    }
    return (
        <>
            {this.props.isHoLoPriceVisible && source.map((itm, i) => itm.HiLoPoints &&
                itm.HiLoPoints.map((sItm, j) => sItm.showPrice && sItm.IsHigh &&
                    <LabelText
                        key={i * 10 + j}
                        textAnchor="middle"
                        isHighlighted={true}
                        textValue={ExtremeDataValue.showHiLoPrices(sItm.HighLowValue)}
                        dx="0"
                        dy="0"
                        style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                        textPosX={itm.xAxis}
                        textPosY={itm.yHigh - (sItm.showPct ? 3 + 9 : 3)} />))}
            {this.props.isHoLoPctVisible && source.map((itm, i) => itm.HiLoPoints &&
                itm.HiLoPoints.map((sItm, j) => sItm.showPct && sItm.IsHigh &&
                    <LabelText
                        key={i * 10 + j}
                        textAnchor="middle"
                        isHighlighted={true}
                        textValue={sItm.PercentChangedText}
                        dx="0"
                        dy="0"
                        style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                        textPosX={itm.xAxis}
                        textPosY={itm.yHigh - 3} />))}
            {this.props.isHoLoPriceVisible && source.map((itm, i) => itm.HiLoPoints &&
                itm.HiLoPoints.map((sItm, j) => sItm.showPrice && sItm.IsLow &&
                    <LabelText
                        key={i * 10 + j}
                        textAnchor="middle"
                        isHighlighted={true}
                        textValue={ExtremeDataValue.showHiLoPrices(sItm.HighLowValue)}
                        dx="0"
                        dy="0"
                        style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                        textPosX={itm.xAxis}
                        textPosY={itm.yLow + 12} />))}
            {this.props.isHoLoPctVisible && source.map((itm, i) => itm.HiLoPoints &&
                itm.HiLoPoints.map((sItm, j) => sItm.showPct && sItm.IsLow &&
                    <LabelText
                        key={i * 10 + j}
                        textAnchor="middle"
                        isHighlighted={true}
                        textValue={sItm.PercentChangedText}
                        dx="0"
                        dy="0"
                        style={{ font: 'calibri', fontSize: '10px', fill: textColor }}
                        textPosX={itm.xAxis}
                        textPosY={itm.yLow + (12 + 9)} />))}
        </>
    );

  }

  prepareLine(source, useCanvas) {
    let self = this;
    if (!this.mainCanvas) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => self.setState({}), 0);
      return;
    }

    let rect = this.mainCanvas.getBoundingClientRect();
    let ctx = this.mainCanvas.getContext("2d");
    ctx.id = "RSLayer";
    ctx.canvas.height = rect.height;
    ctx.canvas.width = rect.width;
    //  ctx.textAlign = "right";
    ctx.imageSmoothingEnabled = true;
    ctx.textAlign = "center";
    ctx.fillStyle = ThemeHelper.getThemedBrush("scaleText");
    ctx.fill('evenodd');
    ctx.font = "8pt Calibri";
    ctx.clearRect(0, 0, rect.width, rect.height);
    if (!source || source.length < 1) {
      return;
    }
    ctx.strokeStyle = this.state.lineColor;
    ctx.lineWidth = this.state.lineThickness;
    if (this.state.chartFill) {
      ctx.fillStyle = this.state.lineColor;
      ctx.globalAlpha = this.state.opacity;
    }
    if (this.state.lineThickness === 1) {
      ctx.translate(0.5, 0.5);
    }
    ctx.setLineDash(this.state.dashArray);
    ctx.lineDashOffset = this.state.offset;

    ctx.beginPath();
    let f = source[0];
    if (f == undefined)
      return;
    ctx.moveTo(f.xAxis, f.yPrice);
    let prevY = f.yPrice;
    let prcLength = source.length;
    if (this.state.Type === 'PTOE' && this.state.chartFill) {
      prevY = source[prcLength - 1].yPrice;
    }
    // let l = f;
    let dif = 4;
    for (var j = 1; j < prcLength; j++) {
        let linePoint = source[j];
        if (linePoint) {
            // l = linePoint; 
            if (this.state.Type === 'PTOE') {
                if (this.state.chartFill) {
                    if (linePoint.yValue > 0 && linePoint.yPrice) {
                        if (dif < 0) {
                            ctx.lineTo(linePoint.xAxis, prevY);
                        }
                        ctx.lineTo(linePoint.xAxis, linePoint.yPrice);
                        dif = 4;
                    } else {
                        ctx.lineTo(linePoint.xAxis + dif, prevY);
                        dif = -4;
                    }

                } else {
                    if (linePoint.yValue > 0 && prevY > 0) {
                        ctx.lineTo(linePoint.xAxis, linePoint.yPrice);
                    } else {
                        ctx.moveTo(linePoint.xAxis, linePoint.yPrice);
                    }
                }
            } else {
                ctx.lineTo(linePoint.xAxis, linePoint.yPrice);
            }
            if (!this.state.chartFill) {
                prevY = linePoint.yValue;
            }
            if (linePoint.HiLoPoints) {
          let ptLen = linePoint.HiLoPoints.length;
          let up = -3;
          let lo = 12;
          if (linePoint.HiLoPoints && ptLen > 0) {
            for (var x = 0; x < ptLen; x++) {
              if (linePoint.HiLoPoints[x].IsHigh) {
                if (linePoint.HiLoPoints[x].showPct) {
                  if (useCanvas && linePoint.HiLoPoints[x].PercentChangedText) {
                    ctx.fillText(linePoint.HiLoPoints[x].PercentChangedText, linePoint.xAxis, linePoint.yHigh + up);
                  }
                  this.labelPointsArr.push({
                    'x': linePoint.xAxis,
                    "y": linePoint.yHigh + up,
                    "width": this.getTextWidth(linePoint.HiLoPoints[x].PercentChangedText),
                    "height": this.getTextHeight(linePoint.HiLoPoints[x].PercentChangedText)
                  });
                  up -= 9;
                }
                if (linePoint.HiLoPoints[x].showPrice) {
                    if (useCanvas) {
                        ctx.fillText(ExtremeDataValue.showHiLoPrices(linePoint.HiLoPoints[x].HighLowValue), linePoint.xAxis, linePoint.yHigh + up);
                    }
                  this.labelPointsArr.push({
                    'x': linePoint.xAxis,
                    "y": linePoint.yHigh + up,
                    "width": this.getTextWidth(ExtremeDataValue.showHiLoPrices(linePoint.HiLoPoints[x].HighLowValue)),
                    "height": this.getTextHeight(ExtremeDataValue.showHiLoPrices(linePoint.HiLoPoints[x].HighLowValue))
                  });
                }
              }
              if (linePoint.HiLoPoints[x].IsLow) {
                if (linePoint.HiLoPoints[x].showPrice) {
                    if (useCanvas) {
                        ctx.fillText(ExtremeDataValue.showHiLoPrices(linePoint.HiLoPoints[x].HighLowValue), linePoint.xAxis, linePoint.yLow + lo);
                    }
                  this.labelPointsArr.push({
                    'x': linePoint.xAxis,
                    "y": linePoint.yHigh + lo,
                    "width": this.getTextWidth(ExtremeDataValue.showHiLoPrices(linePoint.HiLoPoints[x].HighLowValue)),
                    "height": this.getTextHeight(ExtremeDataValue.showHiLoPrices(linePoint.HiLoPoints[x].HighLowValue))
                  });
                  lo += 9;
                }
                if (linePoint.HiLoPoints[x].showPct && linePoint.HiLoPoints[x].PercentChangedText) {
                    if (useCanvas) {
                        ctx.fillText(linePoint.HiLoPoints[x].PercentChangedText, linePoint.xAxis, linePoint.yLow + lo);
                    }
                  this.labelPointsArr.push({
                    'x': linePoint.xAxis,
                    "y": linePoint.yHigh + lo,
                    "width": this.getTextWidth(linePoint.HiLoPoints[x].PercentChangedText),
                    "height": this.getTextHeight(linePoint.HiLoPoints[x].PercentChangedText)
                  });
                }
              }
            }
          }
        }
      }
      else
        break;
    }
    if (this.state.chartFill)
      ctx.fill();
    if (this.state.accDist) {
      let linePoint = source[0];
      ctx.fillText(this.state.accDist, linePoint.xAxis + 4, linePoint.yPrice + 10);
      this.labelPointsArr.push({ 'x': linePoint.xAxis + 4, "y": linePoint.yPrice + 10, "width": this.getTextWidth(this.state.accDist), "height": this.getTextHeight(this.state.accDist) })

    }
    if (this.state.rsNumber) {
      let linePoint = source[0];
      ctx.fillText(this.state.rsNumber, linePoint.xAxis + 4, linePoint.yPrice - 4);
      this.labelPointsArr.push({ 'x': linePoint.xAxis + 4, "y": linePoint.yPrice + 10, "width": this.getTextWidth(this.state.rsNumber), "height": this.getTextHeight(this.state.rsNumber) })

    }

    ctx.stroke();
    ctx.closePath();

  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.DataSource) {
      this.state.source = nextprops.DataSource;
      this.state.lineColor = nextprops.LineColor;
      this.lineWidth = nextprops.nodeWidth ? nextprops.nodeWidth < 12 ? 1 : ((nextprops.nodeWidth * 4) / 16) : 1;
      this.state.lineThickness = nextprops.LineThickness && this.lineWidth< 2 ? nextprops.LineThickness : this.lineWidth;
      this.state.lineThickness2 = nextprops.LineThickness2 && this.lineWidth < 2  ? nextprops.LineThickness2 : this.lineWidth;
      this.state.source2 = nextprops.DataSource2;
      this.state.lineColor2 = nextprops.LineColor2;
      this.state.pointerTextColor = nextprops.pointerTextColor;
      this.state.accDist = nextprops.accDist;
      this.state.rsNumber = nextprops.rsNumber;
      this.state.priceMenu = nextprops.PriceMenu;
      this.state.chartHeight = nextprops.chartHeight;
        if (nextprops.lineID) {
            this.state.lineID = nextprops.lineID;
        }
        if (nextprops.lineID2) {
            this.state.lineID2 = nextprops.lineID2;
        }
      if (nextprops.zIndex) {
        this.state.zIndex = nextprops.zIndex;
      }
      if (nextprops.Type) {
        this.state.Type = nextprops.Type;
      }
      if (nextprops.lineID) {
        this.state.lineID = nextprops.lineID;
      }
      if (nextprops.messageColor) {
        this.state.messageColor = nextprops.messageColor;
      }
      if (nextprops.DashArray) {
        this.state.dashArray = nextprops.DashArray;
      }
      if (nextprops.maValue) {
        this.state.maValue = nextprops.maValue;
      }
      if (nextprops.lastPrice) {
        this.state.lastPrice = nextprops.lastPrice;
      }
      if (nextprops.showR !== undefined) {
        this.state.showR = nextprops.showR;
      } else {
          this.state.showR = true;
      }
      if (nextprops.ylastPrice) {
        this.state.ylastPrice = nextprops.ylastPrice;
      }
      if (nextprops.Draggable) {
        this.state.draggable = nextprops.Draggable;
        if (nextprops.Draggable === 0) {
          this.state.pointerEvents = "none";
        }
        else {
          this.state.pointerEvents = "visible";
        }
      }
      if (nextprops.Opacity) {
        this.state.opacity = nextprops.Opacity;
        this.state.chartFill = true;
      }
      if (nextprops.showMarchingAnts) {
        this.state.offset = 0;
        this.march();
      }
      this.state.refresh = !this.state.refresh;
      this.setState(this.state);
    }
  }
  showInfoBox(showText, screenX, screenY, backgroundColor) {
    if (this.main === undefined) {
        return (<div/>);
    }
    const dimensions = this.main.getBoundingClientRect();
    const chartHeight = dimensions.height;
    const xLeft = screenX + "px";
    const ytop = screenY > chartHeight - 40 ? (screenY - 20) : (screenY + 20) + "px";
    const height = "20px";
    const width = "auto";
    const lineFontColor = ThemeHelper.getFontColor(backgroundColor);
    const textStyle = {
      backgroundColor: backgroundColor,
      borderColor: "#333333",
      borderStyle: "solid",
      borderWidth: "1px",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      borderBottomLeftRadius: "4px",
      fontFamily: "calibri",
      color: lineFontColor,
      position: "absolute",
      left: xLeft,
      top: ytop,
      height: height,
      width: width,
      zIndex: 100,
      whiteSpace: 'nowrap'
    };
    return (
      <div style={textStyle} key={screenX} >
        <div style={{
          textAlign: "Center",
          fontFamily: "calibri",
          color: lineFontColor,
          marginLeft: '6px',
          marginRight: '6px'
        }}>
          {showText}
        </div>
      </div>
    );
  }
  onCanvasMouseMove(e) {
      this.mouseMoveTimeout && clearTimeout(this.mouseMoveTimeout);
      if(AnnotationUtil.isAnnotationsTabActive() && !AnnotationUtil.isPointerSelected) return;
      this.mouseMoveTimeout = setTimeout(() => {
          if (this.main) {
              const dimensions = this.main.getBoundingClientRect();
              const chartHeight = dimensions.bottom;
              let xAxis = parseInt(e.clientX - dimensions.left);
              let yAxis = parseInt(e.clientY - dimensions.top);
              const isHit = this.hitTest(xAxis, yAxis);
              const state = this.getState();
              if (!e.showPointer && isHit > 0 && state.showR) {
                  if (isHit === 1 && state.lineID && this.props.isToolTip && state.lineID.length > 0) {
                      const len = state.lineID.length * 6;
                      xAxis = xAxis - len;
                      if (xAxis < len) xAxis = xAxis + len;
                      if (chartHeight - 40 < yAxis) yAxis = yAxis - 50;
                      if (yAxis < 40) yAxis = yAxis + 50;
                          this.setState({
                              showBox2: false,
                              showBox: true,
                              screenX: xAxis,
                              screenY: yAxis,
                              showInfoPointer: true
                          });
                  }
                  if (isHit === 2 && state.lineID2 && state.lineID2.length > 0) {
                      const len = state.lineID2.length * 6;
                      xAxis = xAxis - len;
                      if (xAxis < len) xAxis = xAxis + len;
                      if (chartHeight - 40 < yAxis) yAxis = yAxis - 50;
                      if (yAxis < 40) yAxis = yAxis + 50;
                          this.setState({
                              showBox2: true,
                              showBox: false,
                              screenX: xAxis,
                              screenY: yAxis,
                              showInfoPointer: true
                          });
                  }
                  //TabDataGraphActionClass.onLineVisualSelected();
                  if (this.pointer1 && state.maValue > 0) {
                      const contentL = ExtremeDataValue.showPrice(state.maValue);
                      const yValue = state.ylastPrice - 10;
                      const changeInfo = state.lastPrice > 0 ? (state.maValue - state.lastPrice) * 100 / state.lastPrice : 0;
                      const sign = changeInfo > 0 ? "+" : "";
                      const contentR = "(" + sign + changeInfo.toFixed(0) + "%)";
                      this.pointer1.updatePointer(contentL, contentR, yValue, '', '', 0);
                  }
              }
              else {
                  e.showPointer = false;
                  this.setState({ showBox: false, showBox2: false, showInfoPointer: false });
              }
          }
    }, 0);
  }

  hitTest(x, y) {
      //Fix for PANWEB - 1068 - Check if any modal open
      const openModals = document.getElementsByClassName('modal-open');
      if (openModals.length >= 1) {
          return 0;
      }
      if (this.isPointInStroke(x, y)) {
          return 1;
      }
      if (this.isPointInStroke2(x, y)) {
          return 2;
      }
  }

  isPointInStroke(x, y) {
    const prcLength = this.state.source.length;
    for (let j = 0; j < prcLength; j++) {
      const linePoint = this.state.source[j];
      if (linePoint &&
        (linePoint.xAxis >= x - 3 && linePoint.xAxis <= x + 3) &&
        (linePoint.yPrice >= y - 3 && linePoint.yPrice <= y + 3)) {
        return true;
      }
    }
    return false;
  }
  isPointInStroke2(x, y) {
        if (this.state.source2 === undefined) return false;
        const prcLength = this.state.source2.length;
        for (let j = 0; j < prcLength; j++) {
            const linePoint = this.state.source2[j];
            if (linePoint &&
                (linePoint.xAxis >= x - 3 && linePoint.xAxis <= x + 3) &&
                (linePoint.yPrice >= y - 3 && linePoint.yPrice <= y + 3)) {
                return true;
            }
        }
        return false;
  }

  onCanvasMove() {
    //console.log(e)
  }
  onContextMenuClick(e) {
    e.preventDefault();
    return false;
  }

  march () {
    
    let offset = this.state.offset + 1;

    if (offset > 50) {
      offset = 0;
    }
    this.setState({offset:offset});

    if (this.marchingLines) {
      clearTimeout(this.marchingLines);
    }

    this.marchingLines = setTimeout(this.march, 100);
  }

  render() {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const useCanvas = tabDataGraphSettings.useCanvas;    TimeTrackingWindow.trackChartLoadingTime();
    const contentL = ExtremeDataValue.showPrice(this.state.maValue);
    const yValue = this.state.ylastPrice - 10;
    const changeInfo = this.state.lastPrice > 0 ? (this.state.maValue - this.state.lastPrice) * 100 / this.state.lastPrice : 0;
    const sign = changeInfo > 0 ? "+" : "";
    const contentR = "(" + sign + changeInfo.toFixed(0) + "%)";
    if (!this.state.source || !this.state.source[0] || this.state.source.length <= 0) {
        return (<div></div>);
    }
      if (this.state.Type !== 'PTOE' && (this.state.draggable || !useCanvas) || this.props.isPrintMode) {
          var lineStyle = {
              fitPosition: "fill",
              position: "absolute",
              left: "0px",
              top: "0px",
              zIndex: this.state.zIndex,
              pointerEvents: "none",
              opacity: this.state.opacity
          }
          let linePoint = this.state.source[0];
          let textcolor = ThemeHelper.getThemedBrush("scaleText");
          let offChart = linePoint && this.state.chartHeight ? linePoint.yPrice + 5 > this.state.chartHeight || linePoint.yPrice < 10 : false;
          let showRS = (linePoint && this.state.rsNumber && this.state.rsNumber > 0 && linePoint.xAxis && !offChart) ? true : false;
          let showAC = (linePoint && this.state.accDist && this.state.accDist !== "" && linePoint.xAxis && !offChart) ? true : false;
          let lColor = ThemeHelper.getThemedBrush("buttonLabel");
          let rColor = ThemeHelper.getThemedBrush("buttonLabelGray");
          return (
          <div ref={(ref) => this.main = ref}>
          {this.state.refresh == true && this.getDraggableView(lineStyle)}
          {this.state.refresh == false && this.getDraggableView(lineStyle)} {/* fix PANWEB-2684 */}
          {showAC &&
            <label className="rslayer-growth" style={{
              // position: "absolute",
              // font: "8pt Calibri",
              // textAlign: "center",
              color: textcolor,
              left: linePoint.xAxis + 4,
              top: linePoint.yPrice - 2
            }}>{this.state.accDist}
            </label>
          }
          {showRS &&
            <label className="rslayer-growth" style={{
              // position: "absolute",
              // font: "8pt Calibri",
              // textAlign: "center",
              color: textcolor,
              left: linePoint.xAxis + 4,
              top: linePoint.yPrice - 14
            }}>{this.state.rsNumber}
            </label>
          }
          {this.props.showPointer &&
            <Pointer
              ref={(ref) => this.pointer = ref}
              hasBox={this.props.showBox}
              height={20}
              width={115}
              fill={ThemeHelper.getThemedBrush("pointerColor")}
              lcolor={lColor}
              rcolor={rColor} />
          }
          {(this.state.showBox && this.props.isToolTip) &&
              this.showInfoBox(this.state.lineID, this.state.screenX, this.state.screenY, this.state.lineColor)
          }
          {(this.state.showBox2 && this.props.isToolTip) &&
              this.showInfoBox(this.state.lineID2, this.state.screenX, this.state.screenY, this.state.lineColor2)
          }
          {this.state.showInfoPointer &&
              <Pointer
                  ref={(ref) => this.pointer1 = ref}
                  hasBox={false}
                  height={20}
                  width={115}
                  fill={this.state.lineColor}
                  lcolor={this.state.pointerTextColor}
                  rcolor={this.state.pointerTextColor}
                  contentL={contentL}
                  contentR={contentR}
                  y={yValue}              />
          }
          {/* Moving svg element from drawHiLoPoints to here because svg element is updating when trackprice is invoking due to 
            this svg element is not taking click event when trackprice is hiding */}
          <svg className="svg line-visual">
            {this.state.refresh == true && this.drawHiLoPoints()}
            {this.state.refresh == false && this.drawHiLoPoints()}
          </svg>
        </div>);
    }
    else {
      this.prepareLine(this.state.source, useCanvas);
      let colorInfo = ThemeHelper.getThemedBrush(this.state.lineColor);
      this.state.pointerTextColor = ThemeHelper.getFontColor(colorInfo);
      this.state.messageColor = this.state.pointerTextColor;
      let lColor = ThemeHelper.getThemedBrush("buttonLabel");
      let rColor = ThemeHelper.getThemedBrush("buttonLabelGray");
      return (
        <div ref={(ref) => this.main = ref} >
          <canvas onContextMenu={this.onContextMenuClick.bind(this)}
            className="chartVisual"
            id="RSLine"
            ref={(ref) => this.mainCanvas = ref}
            style={{
              fitPosition: "fill",
              width: "100%",
              height: "100%",
              position: "absolute",
              pointerEvents: "visible"
            }}>
          </canvas>
          {this.props.showPointer &&
            <Pointer
              ref={(ref) => this.pointer = ref}
              hasBox={this.props.showBox}
              height={20}
              width={115}
              fill={ThemeHelper.getThemedBrush("pointerColor")}
              lcolor={lColor}
              rcolor={rColor} />
          }
          {(this.state.showBox && this.props.isToolTip) &&
            this.showInfoBox(this.state.lineID, this.state.screenX, this.state.screenY, this.state.lineColor)
          }
          {(this.state.showBox2 && this.props.isToolTip) &&
            this.showInfoBox(this.state.lineID2, this.state.screenX, this.state.screenY, this.state.lineColor2)
          }
          {this.state.showInfoPointer &&
            <Pointer
              ref={(ref) => this.pointer1 = ref}
              hasBox={false}
              height={20}
              width={115}
              fill={this.state.lineColor}
              lcolor={this.state.pointerTextColor}
              rcolor={this.state.pointerTextColor} />
          }
        </div>
      );
    }
  }
}
LineVisual.propTypes = {
  LineColor: PropTypes.string.isRequired,
  LineThickness: PropTypes.number.isRequired,
  LineColor2: PropTypes.string,
  LineThickness2: PropTypes.number,
  pointerTextColor: PropTypes.string,
  showPointer: PropTypes.bool,
  showBox: PropTypes.bool,
  accDist: PropTypes.string,
  rsNumber: PropTypes.number,
  zIndex: PropTypes.string,
  lineID: PropTypes.string,
  lineID2: PropTypes.string,
  messageColor: PropTypes.string,
  maValue: PropTypes.number,
  lastPrice: PropTypes.number,
  ylastPrice: PropTypes.number,
  isToolTip: PropTypes.bool,
  chartHeight: PropTypes.number,
  index: PropTypes.number,
  showMarchingAnts: PropTypes.bool,
  Type: PropTypes.string,
  showR: PropTypes.bool
};

LineVisual.defaultProps = {
  showR: true
}