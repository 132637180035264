import Serializable from "Serializable";
import RiSubCategoryType from "../../../../../../Constants/RiSubCategoryType.js";
import AnnotationSettings from "../AnnotationSettings.js";
import RiPanelNewSettings from "./RiPanelNewSettings.js";
import RiPanelManagementSettings from "./RiPanelManagementSettings.js";

class RiPanelCEFViewSettings extends Serializable {

    getDefaulSettings() {
        this.ActiveSelectedSubCategory = RiSubCategoryType.News;
        this.AnnotationSettings = this.getDefaultAnnotationSettings();
        this.NewSettings = this.getDefaultNewsSettings();
        this.ManagementSettings = this.getDefaultManagementSettings();
        return this;
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.ActiveSelectedSubCategory = serializedValue.ActiveSelectedSubCategory ? serializedValue.ActiveSelectedSubCategory : RiSubCategoryType.News;
        this.AnnotationSettings = serializedValue.AnnotationSettings ? serializedValue.AnnotationSettings : this.getDefaultAnnotationSettings();
        this.NewSettings = serializedValue.NewSettings ? serializedValue.NewSettings : this.getDefaultNewsSettings();
        this.ManagementSettings = serializedValue.ManagementSettings ? serializedValue.ManagementSettings : this.getDefaultManagementSettings();
    }

    getObjectMapKey() {
        return "RiPanelCEFViewSettings";
    }

    getDataObject() {
        return {
            ActiveSelectedSubCategory: this.ActiveSelectedSubCategory,
            AnnotationSettings: this.AnnotationSettings,
            NewSettings: this.NewSettings,
            ManagementSettings: this.ManagementSettings,
        };
    }

    getDefaultAnnotationSettings() {
        const annotationSettings = new AnnotationSettings();
        return annotationSettings;
    }

    getDefaultNewsSettings() {
        const newsSettings = new RiPanelNewSettings();
        return newsSettings;
    }

    getDefaultManagementSettings() {
        const managementFundSettings = new RiPanelManagementSettings();
        return managementFundSettings;
    }

}


export default RiPanelCEFViewSettings;