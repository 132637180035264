import Serializable from "Serializable";
export default class PTOSChartSettings extends Serializable {
    constructor() {
        super();
    }

    getDefaultPTOSIndexLine() {
        return true;
    }

    getDefaultHLEstimates() {
        return true;
    }

    // getDefaultPTOSStockLine() {
    //     return true;
    // }

    // getDefaultPTOSGroupLine() {
    //     return false;
    // }

    getDefaultIndexColor() {
        const OverboughtObj = [{ isActive: false, color: "P4C4C4C999999", weight: 2 }];
        return OverboughtObj.map((item) => this.getSerizableObject(item));
    }

    getDefaultStockColor() {
        const OversoldObj = [{ isActive: true, color: "P99FF99003300", weight: 1 }];
        return OversoldObj.map((item) => this.getSerizableObject(item));
    }

    // getDefaultGroupColor() {
    //     const OversoldObj = [{ isActive: false, color: "P33FFFF006666", weight: 1 }];
    //     return OversoldObj.map((item) => this.getSerizableObject(item));
    // }

    getSerizableObject(obj) {
        return Object.assign(new Serializable, { ...obj });
    }

    getDefaultseqNumber(){
        return 3;
    }
    onSeralisedInfoRecieved(serializedValue) {
        this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
        this.Height = serializedValue.Height ? serializedValue.Height : 100;
        this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
        this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
        // this.PTOSStockLine = serializedValue.PTOSStockLine ? serializedValue.PTOSStockLine : this.getDefaultPTOSStockLine();
        // this.PTOSGroupLine = serializedValue.PTOSGroupLine ? serializedValue.PTOSGroupLine : this.getDefaultPTOSGroupLine();
        this.PTOSIndexLine = serializedValue.PTOSIndexLine !== undefined ? serializedValue.PTOSIndexLine : this.getDefaultPTOSIndexLine();
        this.HLEstimates = serializedValue.HLEstimates !== undefined ? serializedValue.HLEstimates : this.getDefaultHLEstimates();
        this.StockColor = serializedValue.StockColor ? serializedValue.StockColor : this.getDefaultStockColor();
        this.IndexColor = serializedValue.IndexColor ? serializedValue.IndexColor : this.getDefaultIndexColor();
        this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : this.getDefaultseqNumber();
        //this.GroupColor = serializedValue.GroupColor ? serializedValue.GroupColor : this.getDefaultGroupColor();
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            Height: this.Height,
            IsAvailable: this.IsAvailable,
            IsRelative: this.IsRelative,
            //  PTOSStockLine: this.PTOSStockLine,
            //  PTOSGroupLine: this.PTOSGroupLine,
            PTOSIndexLine: this.PTOSIndexLine,
            HLEstimates: this.HLEstimates,
            StockColor: this.StockColor,
            IndexColor: this.IndexColor,
            //  GroupColor: this.GroupColor
            seqNumber: this.seqNumber,
        }
    }

    getDefaultPTOSChartSettings() {
        this.IsVisible = true;
        this.Height = 100;
        this.IsAvailable = true;
        this.IsRelative = false;
        // this.PTOSStockLine = this.getDefaultPTOSStockLine();
        // this.PTOSGroupLine = this.getDefaultPTOSGroupLine();
        this.PTOSIndexLine = this.getDefaultPTOSIndexLine();
        this.HLEstimates = this.getDefaultHLEstimates();
        this.StockColor = this.getDefaultStockColor();
        this.IndexColor = this.getDefaultIndexColor();
        //this.GroupColor = this.getDefaultGroupColor();
        this.seqNumber = this.getDefaultseqNumber();
        return this;
    }

    getObjectMapKey() {
        return "PTOSChartSettings";
    }
}
