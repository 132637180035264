import { AlertConstants } from '../../Constants/AlertConstants';
import AlertStore from '../../Stores/Alerts/AlertStore';
import { AlertTranslateHelper } from '../../Utils/TranslateHelper';
import { AnnotationConstant } from '../../Constants/AnnotationConstants';
import { DataGraphConstants } from '../../Constants/DataGraphConstants';
import ExtremeDataValue from '../../Utils/ExtremeDataValue';
import NotificationSettings from '../../Stores/ConsoleWindow/Settings/Modules/Notification/NotificationSettings';
import SettingsStore from "SettingsStore";
const { ActionTypes } = AlertConstants;

const initialState = {
    alertList: [],
    showActive: true,
    loading: true,
    Initialized: false,
    alertModal: null,
    andAlertModal: null,
    emitWorkerAction: false,
    priceAlertResponseData: null,
    trendLineAlerts: [],
    datagraphAlertsList: [],
    AlertsCountMessage: '',
    riPanelAlertsList: [],
    AlertDatasource: [],
    triggeredAlertsList: [],
    highlightedAlertObj: {},
    pointerYPos: 0,
    pointerXPos: 0,
    shownAlertIds: [],
    isDragging: false,
    showAlertBox: false,

    isOpenAlertDialog: false,
    alertObj: null,
    isEditing: false,
    IsShowAlertsIcon: false,
    userNotificationSettings: new NotificationSettings(),
    currentEditingAlert: {},
    isSaveAlertsPopup: false,
}

const DataGraphAlertReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_RIPANEL_ALERTS:
            return {
                ...state,
                alertList: action.alertList,
                showActive: action.showActive,
                loading: false
            }
        case ActionTypes.UPDATE_PRICE_ALERT_RESPONSE:
            return {
                ...state,
                priceAlertResponseData: action.priceAlertResponseData,
                emitWorkerAction: action.emitWorkerAction,
            }
        case ActionTypes.UPDATE_DATA_GRAPH_ALERT_STATES:
            return {
                ...state,
                ...action.states
            }
        case ActionTypes.HIGHLIGHT_ALERT:
            return {
                ...state,
                highlightedAlertObj: action.highlightedAlertObj,
                pointerXPos: action.pointerXPos,
                pointerYPos: action.pointerYPos - 10,
                showAlertBox: action.highlightedAlertObj.isActive === 0,
            }
        case AnnotationConstant.ActionTypes.HANDLE_TL_ALERT_HOVER_TOGGLE: {
            if (action.isHovered) {
                const alertObj = state.trendLineAlerts.find((item) => item.annotationid === action.annotId)
                if (alertObj && alertObj.alert.alertId) {
                    return {
                        ...state,
                        highlightedAlertObj: alertObj.alert,
                        pointerXPos: 0,
                        pointerYPos: 0,
                    }
                }
                return state;

            }
            return {
                ...state,
                highlightedAlertObj: { alertId: 0 },
                pointerXPos: 0,
                pointerYPos: 0,
            }
        }
        case ActionTypes.START_DRAGGING_ALERT:
            return {
                ...state,
                isDragging: action.isDragging,
                highlightedAlertObj: action.highlightedAlertObj,
                pointerXPos: action.pointerXPos,
                pointerYPos: action.pointerYPos - 10,
            }
        case ActionTypes.TOGGLE_SHOW_ALERTS:
            state.userNotificationSettings.IsShowAlertsIcon = !state.IsShowAlertsIcon;
            SettingsStore.saveSettings();
            AlertStore.handleToggleShowAlerts(!state.IsShowAlertsIcon, ActionTypes.TOGGLE_SHOW_ALERTS);
            return {
                ...state,
                IsShowAlertsIcon: !state.IsShowAlertsIcon

            }
        case ActionTypes.REMOVE_DATA_GRAPH_ALERT:{
            if(action.alert.length === 2){
                state.riPanelAlertsList = state.riPanelAlertsList.filter((item) => item.AlertDataStructure.length !== 2 || item.AlertDataStructure[0].alertId !== action.alert[0].alertId)
                state.priceAlertResponseData[action.alert[0].alertType] = state.priceAlertResponseData[action.alert[0].alertType].filter((item) => item.alert.alertId !== action.alert[0].alertId)
            }
            else if (action.alert.isStockAlert) {
                state.riPanelAlertsList = state.riPanelAlertsList.filter((item) => !item.AlertDataStructure.StockAlertData || item.AlertDataStructure.StockAlertData.alertId !== action.alert.StockAlertData.alertId || item.AlertDataStructure.StockAlertData.alertTypeId !== action.alert.StockAlertData.alertTypeId)
                state.priceAlertResponseData[action.alert.alertType] = state.priceAlertResponseData[action.alert.alertType].filter((item) => item.alertId !== action.alert.StockAlertData.alertId || item.alertTypeId !== action.alert.StockAlertData.alertTypeId)
            }
            else {
                state.riPanelAlertsList = state.riPanelAlertsList.filter((item) => item.AlertDataStructure.length === 2 || item.AlertDataStructure.alertId !== action.alert.alertId)
                state.priceAlertResponseData[action.alert.alertType] = state.priceAlertResponseData[action.alert.alertType].filter((item) => item.alert.alertId !== action.alert.alertId)
            }
            const totalActAlrtStr = AlertTranslateHelper["TOTAL_ACTIVE_ALERT"];
            const remainingStr = AlertTranslateHelper["REMAINING"];
            const alertCount = ExtremeDataValue.ReplaceNumberWithCommas(--state.priceAlertResponseData.alertCount);
            const totalCount = ExtremeDataValue.ReplaceNumberWithCommas(++state.priceAlertResponseData.totalCount)
            const AlertsCountMessage = state.showActive ? `${alertCount} ${totalActAlrtStr} ${totalCount} ${remainingStr}` : '';
            return {
                ...state,
                datagraphAlertsList: state.datagraphAlertsList.filter((item) => item.alertId !== action.alert.alertId),
                AlertDataStructure: state.AlertDatasource.filter((item) => item !== action.alert),
                AlertsCountMessage
            }
        }
        case ActionTypes.UPDATE_SHOWN_ALERT_IDS:
            return {
                ...state,
                shownAlertIds: [...state.shownAlertIds, action.shownAlertIds]
            }
        case ActionTypes.TOGGLE_SHOW_ACTIVE:
            return {
                ...state,
                showActive: action.showActive
            }
        case ActionTypes.UPDATE_CURRENT_EDITING_ALERT:
            return {
                ...state,
                currentEditingAlert: action.currentEditingAlert
            }
        case ActionTypes.UPDATE_TRIGGERED_ALERT_LIST: {
            action.notifications.forEach((notification) => {
                if (state.triggeredAlertsList === null) {
                    state.triggeredAlertsList = [];
                    state.triggeredAlertsList.push({ alertId: notification.alertId });
                }
                const alertsData = state.triggeredAlertsList.find((item) => item.alertId === notification.alertId);
                if (alertsData === undefined) {
                    state.triggeredAlertsList.push({ alertId: notification.alertId, isTriggered: 1 });
                }
                else {
                    alertsData.isTriggered = 1;
                }
            })
            return {
                ...state,
            }
        }
        case DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY: {
            if (state.shownAlertIds && state.shownAlertIds.length > 0 && state.triggeredAlertsList && state.triggeredAlertsList.length > 0) {
                state.triggeredAlertsList = state.shownAlertIds.reduce((triggeredList, shownAlertId) => triggeredList.filter((item) => item.alertId !== shownAlertId), state.triggeredAlertsList)
            }
            if (!state.triggeredAlertsList || state.triggeredAlertsList.length < 1) {
                sessionStorage.removeItem("triggeredAlertsList");
            }
            else {
                sessionStorage.setItem("triggeredAlertsList", JSON.stringify(state.triggeredAlertsList))
            }
            return {
                ...state,
                datagraphAlertsList: []
            }
        }
        case ActionTypes.SHOW_SAVE_ALERT_POPUP:
            return {
                ...state,
                isSaveAlertsPopup: action?.isSaveAlertsPopup
            }
        default:
            return state;
    }
}
export default DataGraphAlertReducer;