import { Modal } from "react-bootstrap";
import React from 'react';
import { TranslateHelper, UpdateAlertDialogTranslateHelper } from '../../../../../../../Utils/TranslateHelper';

export default function UpdateAlertDialog(props) {
    return (
        <div>
            <Modal className={"update-index-line modal-popup"} show={props.isShowModal}>
                <Modal.Header>
                    <Modal.Title><span className="normal-heading">{UpdateAlertDialogTranslateHelper.UPDATE}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-region">
                        <p>{UpdateAlertDialogTranslateHelper.UPDATE_ALERT}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn-box center">
                        <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={props.handleClick}>{TranslateHelper.OK}</button>
                    </span>
                </Modal.Footer>
            </Modal>
        </div>
    )
}