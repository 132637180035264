
import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class FundamentalStockReportRequest extends BaseServiceRequest {
  constructor() { //symbol
    super();
    super.addRequestUri("getStockReport.pb");
    super.addRequestParameter("sym", "FB");
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.FUNDAMENTAL;
  }

  getMessageName() {
    return "StockReportResponse";
  }
}

export default FundamentalStockReportRequest;
