import { dispatch } from '../../../../../../Redux/dispatch.js';
import EventUtil from '../../../../../../Utils/EventUtil.js';
import { onPlotSymbol } from '../../../../../../Actions/DatagraphActions.js';
import React from "react";
import { useSelector } from "react-redux";
import { RiPanelConstituentsTranslateHelper, SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";

function TopHoldingsControl() {

    const data = useSelector((state) => state.summaryReducer.stockData.dataSource)
    const height = 241
    const width = 242

    const setItemActive = (e, symbol) => {
        dispatch(onPlotSymbol(symbol));
    }

    return (
        <div className="holdings-info-block" style={{ height: height, width: width }}>
            <div className="info-title">{SummaryTransLateHelper.TOP_HOLDINGS_EQUITY}</div>
            {data.length === 0 ? <div className="news-message"><p>{RiPanelConstituentsTranslateHelper.NOT_AVAILABLE}</p></div> : ''}
            {data.map((item, index) =>
            (<div className="info-row" key={index}>
                <div className="data-cell link-txt" onClick={(e) => setItemActive(e, item.symbol)}>{item.symbol}</div>
                <div className="data-cell"><p onFocus={(e) => EventUtil.mouseOverShowTitle(e, item.companyName)} onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, item.companyName)}>{item.companyName}</p></div>
                <div className="data-cell">{item.percChange}</div>
            </div>)
            )}
        </div>
    );
}
export default TopHoldingsControl