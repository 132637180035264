import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from 'LocalizationStore';
import SymbolType from "SymbolType";

const sbxText = LocalizationStore.getTranslatedData("sb_x", "x");

export default class RatingData {
  constructor(data) {
    this.stockinfo = data.StockInfo;
    this.setRatingData(data.AnnualMiscBlockData);
  }
  setRatingData(data) {
    if (data.CompositeRating) {
      this.compRating = ExtremeDataValue.formatValue(data.CompositeRating, 0);
    }
    if (data.Smrl) {
      this.smrRatingSpaceNum = data.Smrl.length === 1 ? 3 : (data.Smrl.length === 2 ? 2 : 0);
      this.smrRating = data.Smrl;
    }
    if (data.InvT) {
      let inventoryTurnover = data.InvT;
      if (this.stockinfo.SymTypeEnum !== SymbolType.INTERNATIONALSTOCK) {
        inventoryTurnover = Math.round(data.InvT, 2) / 10;
      }
      this.ratingTurnoverAndRankDisplayValue = `${ExtremeDataValue.abbreviateValue(inventoryTurnover, 1) + sbxText} ${ExtremeDataValue.formatValue(data.InvTo, 0)}`;
    }
  }
}