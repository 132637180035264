import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

let _methodType = HttpMethodType.GET;

class GetListCatalogRequest extends BaseServiceRequest {

  constructor(listId, actualListId, searchText, sortCols, initialNumRows, needColumnsInfo, needBrowserCounts, browserFilterText, addlCatalogRequests, useColFilters, saveColFilters, customerOrder, colsetId, igOneilbrowserFilters, igGicsbrowserFilters, isOneilselected, isGicsselected, isNupview) {
    super();
    super.addRequestUri("basic.pb");
    super.addPathParameter(actualListId.toString());
    super.addRequestParameter("rts", "0");
    //  base.AddRequestParameter("rts", ((int)WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.IntradaySourceType).ToString());
    super.addRequestParameter("aid", listId.toString());
    if (searchText) super.addRequestParameter("srch", searchText); // trim check

    if (sortCols != null && sortCols.length > 0) {
      /* string sortParams = string.Empty;
       var sortColumnData = sortCols.ToList().Select(kvp => new { DataItemId = kvp.Key, kvp.Value.SortOrdinal, kvp.Value.SortDirection }).OrderBy(c => c.SortOrdinal);
       sortParams = sortColumnData.Aggregate(sortParams, (current, columnData) => current + (columnData.DataItemId + "," + (columnData.SortDirection == ListMgrSortDirectionEnum.Descending ? 0 : 1) + ";")); */
      let sortParams = '';
      for (let i = 0; i < sortCols.length; i++) {
        let asc = sortCols[i].sortAsc ? 1 : 0;
        sortParams += sortCols[i].dataItemId + ',' + asc + ';';
      }
      super.addRequestParameter("sort", sortParams);
    }

    super.addRequestParameter("rn", "1");
    super.addRequestParameter("num", initialNumRows.toString());
    super.addRequestParameter("c", parseInt(needColumnsInfo).toString());
    super.addRequestParameter("bc", parseInt(needBrowserCounts).toString());
    if (browserFilterText) super.addRequestParameter("bf", browserFilterText);
    if (igGicsbrowserFilters) super.addRequestParameter("igf", igGicsbrowserFilters);
    if (igOneilbrowserFilters) super.addRequestParameter("igof", igOneilbrowserFilters);
    super.addRequestParameter("igc", parseInt(needBrowserCounts).toString());
    super.addRequestParameter("igoc", parseInt(needBrowserCounts).toString());
    super.addRequestParameter("ucf", parseInt(useColFilters).toString());
    super.addRequestParameter("scf", parseInt(saveColFilters).toString());
    super.addRequestParameter("uigof", parseInt(isOneilselected).toString());
    super.addRequestParameter("uigf", parseInt(isGicsselected).toString());
    super.addRequestParameter("icf", "1");
    super.addRequestParameter("so", parseInt(customerOrder).toString());
    super.addRequestParameter("cfv", "2");
    if (colsetId != -1) super.addRequestParameter("csid", colsetId.toString());

    if (addlCatalogRequests != null) {
      _methodType = HttpMethodType.POST;
      super.setContent(addlCatalogRequests);
    }
    super.addRequestParameter("ierd", isNupview.toString());
  }

  getMethod() {
    return _methodType;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ListCatalogResponse";
  }
}

export default GetListCatalogRequest;
