import { ComparisonGraph } from "Constants/ComparisonGraph";
import LocalizationStore from 'LocalizationStore';
const { ActionTypes } = ComparisonGraph;
import CompareTabType,{CompareMenuType} from "CompareTabType";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import { extend, map } from "underscore";
extend(ActionTypes, SettingsConstants.ActionTypes);

let initMenu = {
  headersMenu: [
    { headerText: "" },
    { headerText: "SYMBOL" },
    { headerText: "NAME" },
    { headerText: "% CHG", isClickable: true }
  ],
  sortType: 'dsc',
  hasError: false,
  errorMsg: ""
}

const initialState = {
  selectedMenuTab : CompareMenuType.Symbols,
  SymbolsData : {...initMenu},
  GroupsData : {...initMenu},
  selectedTab:null
};


const compareMenu = (state = initialState, action) => {

  
  const setStateData = (obj) => {

    if (state.selectedMenuTab === CompareMenuType.Symbols) {
      map(obj, (value, property) => state.SymbolsData[property] = value);
    }
    else {
      map(obj, (value, property) => state.GroupsData[property] = value);
    }

    return { ...state };
  }

switch (action.type) {
    case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
  return {
    ...state,
    selectedTab: action.selectedTab
  }

case ActionTypes.SET_COMPARE_MENUTAB:
  if (state.selectedTab === CompareTabType.ComparisonGraph) {
    return {
      ...state,
      selectedMenuTab: action.menuTab
    }
  } else {
    return { ...state }
  }
  
    case ActionTypes.INIT_FROM_SETTINGS_DATA:
      const { sortType } = action.settingsData;
      const headersMenu = [
        { headerText: "" },
        { headerText: LocalizationStore.getTranslatedData("CCG_SYMBOL", "SYMBOL") },
        { headerText: LocalizationStore.getTranslatedData("CCG_NAME", "NAME") },
        { headerText: LocalizationStore.getTranslatedData("CCG_CHG", "% CHG"), isClickable: true }
      ]
      return setStateData({
        sortType : sortType,
        headersMenu: headersMenu
      });

    case ActionTypes.TOGGLE_SORT_SUCCESS:
      return setStateData({sortType: action.sortType });

    case ActionTypes.HANDLE_ERROR:
        return setStateData({
        hasError: action.hasError,
        errorMsg: action.errorMsg
      });

    default:
      return state
  }
}

export default compareMenu;