import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class ListAlertTriggeredInstrumentRequest extends BaseServiceRequest {

    constructor(listId) {
        super();
        super.addRequestUri("triggeredinstruments2.pb");
        super.addPathParameter(listId.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "ListAlertTriggeredInstrumentResponse";
    }
}

export default ListAlertTriggeredInstrumentRequest;
