import { RayAnnotationType, StrokeStyleType, TextAlignmentType } from 'Constants/EAnnotationType';

export class AnnotationMenuStates {
    constructor() {
        this.isSelected = false;
        this.selectedToolType = null;
        this.showAlert = true;
    }
}

export class LineModelInfo {
    constructor() {
        this.type = RayAnnotationType.LINE_AnnotationType;
        this.color = 'P0066CC3399FF';
        this.weight = 2;
        this.stroke = [];
        this.strokeStyle = StrokeStyleType.SOLID_StrokeStyleType;
        this.opacity = 1;
        this.lineX1 = 50;
        this.lineX2 = 220;
        this.lineY1 = 30;
        this.lineY2 = 30;
        this.startArrowChecked = false;
        this.endArrowChecked = false;
        this.startExtendChecked = false;
        this.endExtendChecked = false;
        this.isSnappingChecked = false;
        this.isAlertChecked = false;
        this.isTemporaryChecked = false;
        this.startPolygonPoints = null;
        this.endPolygonPoints = null;
    }
}

export class TextModelInfo {
    constructor() {
        this.type = RayAnnotationType.TEXT_AnnotationType;
        this.color = 'P0066CC3399FF';
        this.borderColor = 'P0066CC3399FF';
        this.bgColor = "P000000FFFFFF";
        this.borderWeight = 1;
        this.borderStroke = 0;
        this.strokeStyle = StrokeStyleType.SOLID_StrokeStyleType;
        this.opacity = 1;
        this.isBorderChecked = true;
        this.fontStyle = 'Arial';
        this.fontSize = 12;
        this.isBold = false;
        this.isItalic = false;
        this.isUnderline = false;
        this.textAlign = TextAlignmentType.CENTER;
    }
}

export class ShapesModelInfo {
    constructor() {
        this.bgColor = 'P000000FFFFFF';
        this.borderColor = 'P0066CC3399FF';
        this.borderWeight = 1;
        this.borderStroke = [];
        this.strokeStyle = StrokeStyleType.SOLID_StrokeStyleType;
        this.opacity = 0;
        this.isBorderChecked = true;
        this.selectedType = RayAnnotationType.RECT_AnnotationType;
    }
}

export class RectModelInfo {
    constructor() {
    }
}

export class EllipseModelInfo {
    constructor() {
    }
}

export class TriangleModelInfo {
    constructor() {
    }
}

export class ChannelLineModelInfo {
    constructor() {
        this.type = RayAnnotationType.PARALLEL_LINES_AnnotationType;
        this.color = 'P0066CC3399FF';
        this.weight = 2;
        this.stroke = [];
        this.strokeStyle = StrokeStyleType.SOLID_StrokeStyleType;
        this.opacity = 1;
        this.lineX1 = 50;
        this.lineX2 = 220;
        this.lineY1 = 20;
        this.lineY2 = 20;
        this.startExtendChecked = false;
        this.endExtendChecked = false;
    }
}

export class MeasurementModelInfo {
    constructor() {
        this.type = RayAnnotationType.MEASUREMENT_AnnotationType;
        this.color = 'P0066CC3399FF';
        this.isLeaderlinesChecked = true;
    }
}

export class FibonacciModelInfo {
    constructor() {
        this.type = RayAnnotationType.FIB_AnnotationType;
        this.color = 'P0066CC3399FF';
        this.weight = 2;
        this.startExtendChecked = false;
        this.endExtendChecked = false;
        this.isLevelLabelsChecked = true;
        this.isPriceLabelsChecked = true;
        this.isExtensionsChecked = true;
        this.extensionLineColor = 'P9999994C4C4C';
        this.extensionLineWeight = 2;
        this.upperLineX1 = 40;
        this.upperRetracementLineX1 = 40;
        this.lowerRetracementLineX1 = 40;
        this.lowerLineX1 = 40;
        this.upperLineX2 = 220;
        this.upperRetracementLineX2 = 220;
        this.lowerRetracementLineX2 = 220;
        this.lowerLineX2 = 220;
        this.upperLineY1 = 15;
        this.upperRetracementLineY1 = 25;
        this.lowerRetracementLineY1 = 35;
        this.lowerLineY1 = 45;
        this.upperLineY2 = 15;
        this.upperRetracementLineY2 = 25;
        this.lowerRetracementLineY2 = 35;
        this.lowerLineY2 = 45;
        this.fibonacciLevels = [100.0, 76.4, 61.8, 50.0, 38.2, 23.6, 0.0];
        this.fibonacciExtensions = [423.6, 361.8, 261.8, 161.8];
    }
}