import { CalenderControlConstants } from "../../Constants/CalenderControl/CalenderControlConst";

const { ActionTypes } = CalenderControlConstants;
export const closeDatePicker = (isClose, event) =>({
    type: ActionTypes.CLOSE_DATE_PICKER,
    isClose, event
})
export const updateCalenderIsPinned = (isPinned) =>({
    type: ActionTypes.UPDATE_CALENDER_IS_PINNED,
    isPinned
})
export const updateInputDateValue = (inputValue) =>({
    type: ActionTypes.UPDATE_INPUT_DATE_VALUE,
    inputValue
})
export const updateCalenderViewMonth = (month) =>({
    type: ActionTypes.UPDATE_CALENDER_MONTH,
    month
})
export const addSubtractYear = (value) =>({
    type: ActionTypes.ADD_SUBTRACT_YEAR,
    value
})
export const updateCalenderViewYear = (event) =>({
    type: ActionTypes.UPDATE_CALENDER_YEAR,
    event
})
export const setSelectedDate = (event) =>({
    type: ActionTypes.UPDATE_SELECTED_DATE,
    event
})
export const updateInputDate = (event) =>({
    type: ActionTypes.UPDATE_INPUT_DATE,
    event
})
export const openDatePicker = () =>({
    type: ActionTypes.OPEN_DATE_PICKER
})

export const onStepForwardInTime = () =>({
    type: ActionTypes.STEP_FORWARD_IN_TIME
})
export const onStepwardBackInTime = () =>({
    type: ActionTypes.STEP_BACKWARD_IN_TIME
})
export const handleEventDateChange = (date) =>({
    type: ActionTypes.UPDATE_EVENT_DATE,
    date
})
export const setMostRecentTradeDate = () =>({
    type: ActionTypes.SET_MOST_RECENT_DATE
})
export const updateShowDatePicker = (showPicker)=>({
    type: ActionTypes.UPDATE_SHOW_DATE_PICKER,
    showPicker
})
export const updateCalenderControlProperties = (properties)=>({
    type: ActionTypes.UPDATE_CALENDER_CONTROL_PROPERTIES,
    properties
})
export const updateLanguage = ()=>({
    type: ActionTypes.UPDATE_LANGUAGE,
})