import { ListManagerCustomMetrics } from "../Constants/ListManagerCustomMetrics";
const { ActionTypes } = ListManagerCustomMetrics
/*Actions */
export const browseMetrics = () => ({
    type: ActionTypes.BROWSE_METRICS
});
export const addMetric = (listId, selectedMetrics, categoryLists = '', metricsOpen = false, fromMetricLib = false) => ({
    type: ActionTypes.ADD_METRICS_CLICK,
    data: { listId: listId, selectedMetrics: selectedMetrics, categoryLists: categoryLists, metricsOpen: metricsOpen, fromMetricLib: fromMetricLib }
});
export const openManageMetrics = (data) => ({
    type: ActionTypes.OPEN_MANAGE_METRICS,
    data: data
});
export const showDefinition = (data) => ({
    type: ActionTypes.OPEN_METRICS_DEFINITION,
    data: data
});
export const showDescription = (data) => ({
    type: ActionTypes.OPEN_METRICS_DESCRIPTION,
    data: data
});

export const saveDescription = (data) => ({
    type: ActionTypes.SAVE_CUSTOM_METRIC_DESCRIPTION,
    data: data
})
export const showEditFormat = (data) => ({
    type: ActionTypes.OPEN_METRICS_EDITFORMAT,
    data: data
});
export const cancelEditFormat = () => ({
    type: ActionTypes.CLEAR_METRICS_EDITFORMAT
});
export const saveEditFormat = () => ({
    type: ActionTypes.SAVE_METRICS_EDITFORMAT
});

export const onIntervalChange = (text) => ({
    type: ActionTypes.HANDLE_INTERVAL_CHANGE,
    data: text
});

export const onLookbackChange = (dval, period) => ({
    type: ActionTypes.HANDLE_LOOKBACK_CHANGE,
    lookBack: dval,
    period: period
});

export const metricNameBoxChange = (text) => ({
    type: ActionTypes.HANDLE_NAME_CHANGE,
    text: text
});

export const customMetricDuplicate = (nodeid, nodeName) => ({
    type: ActionTypes.HANDLE_DUPLICATE_METRIC,
    nodeid: nodeid,
    nodeName: nodeName
});

export const customMetricDelete = (nodeid) => ({
    type: ActionTypes.HANDLE_DELETE_METRIC,
    nodeid: nodeid
});
export const customMetricDeleteSubmit = () => ({
    type: ActionTypes.HANDLE_DELETE_METRIC_SUBMIT
});
export const customMetricDeleteCancel = () => ({
    type: ActionTypes.HANDLE_DELETE_METRIC_CANCEL
});
export const renameClicked = (name, nodeid) => ({
    type: ActionTypes.HANDLE_RENAME_CLICK,
    nodeid: nodeid,
    name: name
});
export const dataFormatChange = (field, value) => ({
    type: ActionTypes.HANDLE_RESULT_FORMAT_CHANGE,
    field: field,
    value: value
});
export const renameInList = (id, value) => ({
    type: ActionTypes.HANDLE_RENAME_EDIT_CHANGE,
    id: id,
    value: value
});
export const saveRename = (saveId, prevName) => ({
    type: ActionTypes.HANDLE_RENAME_SAVE_CHANGE,
    saveId: saveId,
    prevName: prevName,
})
export const createMetric = (saveData) => ({
    type: ActionTypes.HANDLE_CREATE_CUSTOM_METRICS,
    formula: saveData,
})
export const addToList = (listId, metricId) => ({
    type: ActionTypes.HANDLE_ADD_TO_COLUMN,
    listId: listId,
    metricId: metricId
})
export const closeErrorMsg = () => ({
    type: ActionTypes.HANDLE_CLOSE_ERROR_MESSAGE
});
export const hideDropdown = () => ({
    type: ActionTypes.HIDE_SEARCH_DROPDOWN
});
export const saveTempNameToMetricName = () => ({
    type: ActionTypes.HANDLE_EDIT_NAME_OUTSIDE_CLICK
});
export const closeCustomDialog = () => ({
    type: ActionTypes.HANDLE_CLOSE_CUSTOM_METRIC
});
export const validationError = (msg) => ({
    type: ActionTypes.HANDLE_VALIDATION_ERROR,
    hasError: true,
    errorMsg: msg
});

export const handleLoader = (data) =>
    ({
        type: ActionTypes.HANDLE_CLICK_LOADER,
        isLoading: data,
    })

export const metricNameSearch = (text) => ({
    type: ActionTypes.HANDLE_NAME_SEARCH,
    text: text
});

export const onMetricFormulaChange = (data) => ({
    type: ActionTypes.HANDLE_FORMULA_CHANGE,
    data: data,
});

export const onMetricSymbolChange = (data) => ({
    type: ActionTypes.HANDLE_SYMBOL_CHANGE,
    data: data,
});

export const handleDefinitionSelect = (data) => ({
    type: ActionTypes.HANDLE_DEFINITION_SELECTED,
    data: data
});