import keyMirror from "keymirror";

export const AlertConstants = {
  ActionTypes: keyMirror({
    OPEN_ALERT: null,
    OPEN_AND_ALERT: null,
    OPEN_NOTIFICATION_PREFERENCE: null,
    SAVE_NOTIFICATION_PREFERENCE: null,
    SAVE_NOTIFICATION_SUBSCRIPTION: null,
    SAVE_ALERT_DATA: null,
    ALERT_NOT_CHANGE: null,
    SELECT_ALERT_COLUMN_SET:null,
    GET_COLUMN_SET: null,
    SELECT_FILTER_BROWSER: null,
    UPDATE_DATA_GRAPH_ALERT_STATES:null,
    UPDATE_PRICE_ALERT:null,
    DELETE_ALERT:null,
    GET_ALL_NOTIFICATIONS: null,
    OPEN_MESSAGE_CENTER: null,
    TOGGLE_ALERT_STATUS:null,
    NW_RESET_UNREAD_MESSAGES: null,
    NW_TOGGLE_STATUS: null,
    LOAD_RIPANEL_ALERTS: null,
    CREATE_MOVING_AVERAGE_ALERT: null,
    DELETE_LIST_ALERT : null,
    TOGGLE_SHOW_ALERTS:null,
    HIGHLIGHT_ALERT:null,
    NEW_NOTIFICATIONS_RECEIVED: null,
    REMOVE_NOTIFICATION: null,
    ALERT_WORKER_COMPLETED: null,
    CREATE_PRICE_ALERT:null,
    DELETE_ALL_ALERTS_BY_MSIDS:null,
    SET_MESSAGE_AS_READ : null,
    EDIT_ALERT : null,

    //NEW ACTIONS
    DELETE_ALL_ACTIVE_ALERTS : null,
    GET_ALERT_DATA: null,
    POST_ALERT_DATA_WORKER: null,
    UPDATE_PRICE_ALERT_RESPONSE: null,
    TOGGLE_ALERT_DIALOG: null,
    REMOVE_DATA_GRAPH_ALERT: null,
    UPDATE_SHOWN_ALERT_IDS: null,
    TOGGLE_SHOW_ACTIVE: null,
    UPDATE_TRIGGERED_ALERT_LIST: null,
    UPDATE_CURRENT_EDITING_ALERT: null,
    START_ALERTS_DATA_WORKER: null,

    OPEN_AND_ALERT_DIALOG: null,
    OPEN_OR_ALERT_DIALOG: null,
    OPEN_PRICE_ALERT_DIALOG: null,
    OPEN_CRITERIA_DIALOG: null,
    CANCEL_CRITERIA_DIALOG: null,
    SAVE_CRITERIA_DIALOG: null,
    CLOSE_ALERT_DIALOG: null,
    UPDATE_ALERT_DATA_OBJECT: null,
    CONDITION_ALERT_DELETE: null,
    CANCEL_CONDITION_ALERT_DELETE: null,
    OPEN_GEOGRAPHY_FILTER: null,
    OPEN_COLUMN_FILTER: null,
    CLOSE_FILTER_DIALOG: null,
    SELECT_CLOSEST_CHILDREN: null,
    START_DRAGGING_ALERT: null,
    SHOW_SAVE_ALERT_POPUP: null,
  })
};
