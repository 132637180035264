import ColorPicker from "../../../../../../../RayCustomControls/ColorPicker/ColorPicker.jsx";
import { connect } from "react-redux";
import { DialogHOC } from "./DialogHOC.jsx";
import { Modal } from "react-bootstrap";
// import UpdateAlertDialog from "./UpdateAlertDialog";
import { cancelIndexLineDialogSetting, handleChangeIndexSymbol, handleChangeIndexValueType, handleIndexLineDialogCountryChange, handleIndexLineDialogGroupChange, handleIndexLineDialogRegionChange, saveIndexLineDialogSetting, updateIndexLineColor, updateIndexLineThickness,updateSymbolError } from "../../../../../../../Actions/PricePanelActions.js";
import { countriesTranslateHelper, IndexLineTranslateHelper, LineEditDialogTranslateHelper, regionTranslateHelper, TranslateHelper } from "../../../../../../../Utils/TranslateHelper.js";
import React, { Component } from "react";

const colorPickerWidth = 101;
const thickness = [
    { weight: "thin-line", value: 1 },
    { weight: "normal-line", value: 2 },
    {
        weight: "weight-line", value: 3
    }];
class IndexLineDialog extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            ...this.state,
            ButtonActive: true,
            showAlert: false,
        };
    }


    handleClick() {
        // this.setState({showAlert:false});
        // tabDataGraphActionClass.updateIndexLine(this.state)
       // this.closeIndexLineDialog();
    }

    render() {
        return (
            // <div id = "IndexLineDialog">
            //     <Modal className="modal-popup index-line" show={this.props.isIndexLineDialogOpen}>
            //         <Modal.Header>
            //             <Modal.Title>
            //                 <span className="cap-header">{IndexLineTranslateHelper.Index_Line}</span>
            //             </Modal.Title>
            //         </Modal.Header>
                    
            //         <Modal.Footer>
            //             <span className="btn-box right">
            //                 <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.cancelIndexLineDialogSetting}>{TranslateHelper.CANCEL}</button>
            //                 <button type="button" className="btn btn-xs btn-default btn-secondary" disabled={!this.state.ButtonActive} onClick={this.props.saveIndexLineDialogSetting}>{TranslateHelper.OK}</button>
            //             </span>
            //         </Modal.Footer>
            //     </Modal>
            //     {/* <UpdateAlertDialog
            //         isShowModal={this.state.showAlert}
            //         handleClick={this.handleClick}>                    
            //     </UpdateAlertDialog> */}
            // </div>
            <Modal.Body>
                <div className="modal-material">
                    <div className="price-lines">
                        {!this.props.isSymbolIndex &&
                            <div className="row">
                                <div className="col-sm-12">
                                    <h5>{this.props.isFund ? IndexLineTranslateHelper.Set_Index_Line_For_Fund : IndexLineTranslateHelper.Set_Index_Line_Per_Country}</h5>
                                </div>
                            </div>
                        }
                        {!this.props.isSymbolIndex && !this.props.isFund &&
                            <div className="row region-n-country">
                                <div className="col-sm-6">
                                    <span className="form-head">{LineEditDialogTranslateHelper.Region}</span>
                                    <div className="form-field">
                                        <select className="custom-select" onFocus={()=>this.props.updateSymbolError()} value = {regionTranslateHelper[this.props.selectedRegion]} onChange={this.props.handleIndexLineDialogRegionChange} onBlur={this.props.handleIndexLineDialogRegionChange}>
                                        {this.props.regions.map((item, index)=>(
                                            <option key={index}>{regionTranslateHelper[item]}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <span className="form-head">{LineEditDialogTranslateHelper.Country}</span>
                                    <div className="form-field">
                                        <select className="custom-select" onFocus={()=>this.props.updateSymbolError()} value = {countriesTranslateHelper[this.props.selectedCountry]} onChange={this.props.handleIndexLineDialogCountryChange} onBlur={this.props.handleIndexLineDialogCountryChange}>
                                        {this.props.countries.map((item, index)=>(
                                            <option key={index}>{countriesTranslateHelper[item.name]}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="row value-denoter">
                            <div className="col-sm-12">
                                <span className="form-head">{LineEditDialogTranslateHelper.Value}</span>
                                <ul className="value-denote">
                                    <li>
                                        <input type="radio" name="lineValue" value="default" id="default" checked = {this.props.valueType === 'default'} onChange = {this.props.handleChangeIndexValueType.bind(this, 'default')} />
                                        {!this.props.isSymbolIndex && !this.props.isFund && <label htmlFor="default">{LineEditDialogTranslateHelper.CountrysDefault}</label>}
                                        {this.props.isSymbolIndex && !this.props.isFund && <label htmlFor="default">{LineEditDialogTranslateHelper.Defaults}</label>}
                                        {!this.props.isFund && <label className="right-label">{this.props.countryIndex}</label>}
                                        {this.props.isFund && <label htmlFor="default">{LineEditDialogTranslateHelper.Funds_Benchmark}</label>}
                                    </li>
                                    {!this.props.isSymbolIndex && !this.props.isSymbolGroup && !this.props.isFund &&
                                        <li>
                                            <input type="radio" name="lineValue" value="group" id="group" checked = {this.props.valueType === 'group'} onChange = {this.props.handleChangeIndexValueType.bind(this, 'group')} />
                                            <label htmlFor="group">{LineEditDialogTranslateHelper.Symbols_Group}</label>
                                            <label className="right-label">
                                                <select disabled={this.props.valueType !== 'group'} className="custom-select" value={this.props.selectedGroup} onChange={this.props.handleIndexLineDialogGroupChange} onBlur={this.props.handleIndexLineDialogGroupChange}>
                                                    {this.props.groups.map((group, index) => 
                                                        <option key={index} value={group.type}>{group.name}</option>
                                                    )}
                                                    </select>
                                            </label>
                                        </li>
                                    }
                                    <li>
                                        <input type="radio" name="lineValue"  value="symbol" id="symbol" checked={this.props.valueType === 'symbol'} onChange={this.props.handleChangeIndexValueType.bind(this, 'symbol')} />
                                        <label htmlFor="symbol">{LineEditDialogTranslateHelper.Symbol} </label>
                                        <label className="right-label">
                                            <input type="text" ref={(ref) => (this.othersymbol = ref)} value={this.props.symbolName} disabled = {this.props.valueType !== 'symbol'} onChange = {this.props.handleChangeIndexSymbol}></input>
                                        </label>
                                        {this.props.hasSymbolError && <div className="length-error">
                                            {this.props.errorMessage}
                                        </div>}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr className="price-line-hr" />
                        <div className="row">
                            <div className="col-sm-12">
                                <h5>{TranslateHelper.Choose_Line_Display_Setting}</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-10 col-sm-push-1 color-n-weight">
                                <div className="col-sm-6">
                                    <span className="form-head">{TranslateHelper.Color}</span>
                                    <div className="form-field"><ColorPicker width={colorPickerWidth} color={this.props.lineColor} onChangeComplete={this.props.updateIndexLineColor} /></div>
                                </div>
                                <div className="col-sm-6">
                                    <span className="form-head">{TranslateHelper.Weight}</span>
                                    <div className="form-field">
                                        <div className="line-picker">
                                            {thickness.map((item, index) =>
                                                (<a data-disable-track-price="true" key={index}
                                                    className={item.value === this.props.lineThickness ? `${item.weight} active`: item.weight}                                                            
                                                    onClick={() => this.props.updateIndexLineThickness(item.value)}>
                                                    <span className="line">
                                                        <hr />
                                                    </span>
                                                </a>)
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                       
            </Modal.Body>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers }) =>{
    const { IndexLineReducer } = DatagraphReducers
    const state = {}
    state.showSettingDialog = IndexLineReducer.isIndexLineDialogOpen;
    state.countries = IndexLineReducer.countries;
    state.countryIndex = IndexLineReducer.countryIndex;
    state.selectedCountry = IndexLineReducer.selectedCountry;
    state.regions = IndexLineReducer.regions;
    state.valueType = IndexLineReducer.valueType;
    state.selectedRegion = IndexLineReducer.selectedRegion;
    state.industryGroups = IndexLineReducer.industryGroups,
    state.selectedGroup = IndexLineReducer.selectedGroup;
    state.symbolName = IndexLineReducer.symbolName;
    state.indexLineHeaderText = IndexLineReducer.indexLineHeaderText;
    state.groups = IndexLineReducer.groups;
    state.lineThickness = IndexLineReducer.lineThickness;
    state.lineColor = IndexLineReducer.lineColor;
    state.hasLengthError = IndexLineReducer.hasLengthError;
    state.hasSymbolError = IndexLineReducer.hasSymbolError;
    state.errorMessage = IndexLineReducer.errorMessage;
    state.isSymbolGroup = IndexLineReducer.isSymbolGroup;
    state.isSymbolIndex = IndexLineReducer.isSymbolIndex;
    state.isFund = IndexLineReducer.isFund;
    state.HeaderText = IndexLineTranslateHelper.Index_Line;
    state.isUpdateDialog = IndexLineReducer.isUpdateDialog;
    state.showAlert = IndexLineReducer.showAlert;
    state.headerClass = "index-line"
    return state;
}
const mapDispatchToProps = (dispatch) =>({
    updateIndexLineColor: (lineColor)=> dispatch(updateIndexLineColor(lineColor)),
    updateIndexLineThickness: (lineThickness)=> dispatch(updateIndexLineThickness(lineThickness)),
    cancelLineDialog: ()=> dispatch(cancelIndexLineDialogSetting()),
    saveLineDialog: ()=> dispatch(saveIndexLineDialogSetting()),
    handleIndexLineDialogCountryChange: (event)=> dispatch(handleIndexLineDialogCountryChange(event.target.value)),
    handleIndexLineDialogRegionChange: (event)=> dispatch(handleIndexLineDialogRegionChange(event.target.value)),
    handleIndexLineDialogGroupChange: (event)=> dispatch(handleIndexLineDialogGroupChange(parseInt(event.target.value))),
    handleChangeIndexValueType: (valueType)=> dispatch(handleChangeIndexValueType(valueType)),
    handleChangeIndexSymbol: (event)=> dispatch(handleChangeIndexSymbol(event.target.value)),
    updateSymbolError: ()=> dispatch(updateSymbolError()),
})
export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(IndexLineDialog));