import React, { Component } from "react";
import { PopupTypes } from "../../Constants/ScheduledListsConstants";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";

export default class Frequency extends Component {
    constructor(props) {
        super(props);
        this.getFrequency = this.getFrequency.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);

        this.state = {
            frequency: this.props.frequency ? this.props.frequency : FrequencyType.Friday
        }
    }

    onItemClicked(frequency) {
        this.setState({
            frequency: frequency,
        }, () => {
            if (this.props.onSelected) {
                let result = { displayName: this.state.frequency.text, id: this.state.frequency }; //TODO populate the name according to the rules.
                this.props.onSelected(PopupTypes.Frequency, result);
            }
        })
    }

    getFrequency() {
        let view = [];
        for (let key in FrequencyType) {
            if (FrequencyType.hasOwnProperty(key)) {
                let checkMark = <span></span>;
                if (FrequencyType[key].value === this.state.frequency.value) {
                    checkMark = <span className="icn-right-icon"></span>;
                }

                view.push(
                    <li key={FrequencyType[key].value} >
                        <a onClick={this.onItemClicked.bind(this, FrequencyType[key])}>
                            {checkMark}{FrequencyType[key].text}
                        </a>
                    </li>
                )
            }
        }

        return (
            <ul className="frequencyList">
                {view}
            </ul>
        )
    }

    static getDisplayName(id) {
        let displayName = "";
        let type = FrequencyType.Friday;

        switch (id) {
            case FrequencyType.Daily.value:
                displayName = FrequencyType.Daily.text;
                type = FrequencyType.Daily;
                break;
            case FrequencyType.Monday.value:
                displayName = FrequencyType.Monday.text;
                type = FrequencyType.Monday;
                break;
            case FrequencyType.Tuesday.value:
                displayName = FrequencyType.Tuesday.text;
                type = FrequencyType.Tuesday;
                break;
            case FrequencyType.Wednesday.value:
                displayName = FrequencyType.Wednesday.text;
                type = FrequencyType.Wednesday;
                break;
            case FrequencyType.Thursday.value:
                displayName = FrequencyType.Thursday.text;
                type = FrequencyType.Thursday;
                break;
            case FrequencyType.Friday.value:
                displayName = FrequencyType.Friday.text;
                type = FrequencyType.Friday;
                break;
            case FrequencyType.Saturday.value:
                displayName = FrequencyType.Saturday.text;
                type = FrequencyType.Saturday;
                break;
            case FrequencyType.Sunday.value:
                displayName = FrequencyType.Sunday.text;
                type = FrequencyType.Sunday;
                break;
            default:
                break;
        }

        return { displayName: displayName, id: type };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.frequency !== nextProps.frequency) {
            this.setState({ frequency: nextProps.frequency })
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.dismissPanel, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.dismissPanel, false);
    }

    dismissPanel(e) {
        //close this panel if it is out of this component is clicked. 
        if (this.nodes && !this.nodes.contains(e.target)) {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        }
    }

    render() {
        return (
            <div className="scheduled-lists frequency" ref={(node) => this.nodes = node}>
                <div className="title">{LocalizationStore.getTranslatedData("ss_selectfrequency","Select a Frequency:")}</div>
                <div className="content">
                    {this.getFrequency()}
                </div>
            </div>
        )
    }
}

export const FrequencyType = {
    Daily: { text: LocalizationStore.getTranslatedData("ss_daily","Daily"), value: 1 },
    Monday: { text: LocalizationStore.getTranslatedData("ss_weeklymonday","Weekly - Monday"), value: 2 },
    Tuesday: { text: LocalizationStore.getTranslatedData("ss_weeklytuesday","Weekly - Tuesday"), value: 3 },
    Wednesday: { text: LocalizationStore.getTranslatedData("ss_weeklywednesday","Weekly - Wednesday"), value: 4 },
    Thursday: { text: LocalizationStore.getTranslatedData("ss_weeklythursday","Weekly - Thursday"), value: 5 },
    Friday: { text: LocalizationStore.getTranslatedData("ss_weeklyfriday","Weekly - Friday"), value: 6 },
    Saturday: { text: LocalizationStore.getTranslatedData("ss_weeklysaturday","Weekly - Saturday"), value: 7 },
    Sunday: { text: LocalizationStore.getTranslatedData("ss_weeklysunday","Weekly - Sunday"), value: 8 },
}