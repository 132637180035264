import BaseServiceApi from 'BaseServiceApi';
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import PortalContainer from 'PortalContainer';
import ScrollBar from "ScrollBar";
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
import textWidth from "text-width";
import { DropdownButton, MenuItem } from "react-bootstrap";
import React, { Component } from 'react';
import { toggleVisibility, setActiveLayer } from 'Actions/AnnotationActions';

class LayerDropDown extends Component {
    constructor() {
        super();
        this.state = {
            isShowDropdown: false,
            style: {},
            toolStyle: {},
            hoveredLayerIndex: -1
        }
        this.container = React.createRef();
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.fontDropDownUpdate = false;
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onDocumentClick);
        window.addEventListener('resize', this.onResize);
        document.addEventListener("keydown", this.onDocumentKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onDocumentClick);
        window.removeEventListener('resize', this.onResize);
        document.removeEventListener("keydown", this.onDocumentKeyPress);
    }

    componentDidUpdate() {
        let layerIndex = 0;
        if (this.props.selectedLayer) {
            layerIndex = this.props.LayersList.findIndex((item) => item.layerID === this.props.selectedLayer.layerID);
        }
        const layerScroll = document.getElementById("layerSelectDropdownScroll");
        const scrollPositionVal = layerIndex * 22;
        if (layerScroll && !this.fontDropDownUpdate) {
            window.setTimeout(() => { layerScroll.scrollTop = scrollPositionVal; }, 0);
            if (this.state.hoveredLayerIndex === -1) {
                this.setState({ ...this.state, hoveredLayerIndex: layerIndex })
            }
        }
    }

    onDocumentClick = (event) => {

        if (
            (this.container.current &&
                !this.container.current.contains(event.target))
        ) {
            this.setState({
                isShowDropdown: false,
                hoveredLayerIndex: -1
            });
        }
    }

    onResize = () => {
        this.setState({
            isShowDropdown: false,
            hoveredLayerIndex: -1
        });
    }

    onDocumentKeyPress = (e) => {

        const layerScroll = document.getElementById("layerSelectDropdownScroll");
        if (e.keyCode === KeyCodes.UP_ARROW && this.state.isShowDropdown && this.state.hoveredLayerIndex > 0) {
            this.setState({ ...this.state, hoveredLayerIndex: this.state.hoveredLayerIndex - 1 })
            if (layerScroll) {
                window.setTimeout(() => { layerScroll.scrollTop = (this.state.hoveredLayerIndex * 22) - 44; }, 0);
            }
        }
        else if (e.keyCode === KeyCodes.DOWN_ARROW && this.state.isShowDropdown && this.state.hoveredLayerIndex < this.props.LayersList.length - 1) {
            this.setState({ ...this.state, hoveredLayerIndex: this.state.hoveredLayerIndex + 1 })
            if (layerScroll && this.state.hoveredLayerIndex < 3) {
                window.setTimeout(() => { layerScroll.scrollTop = 0; }, 0);
            }
            else if (layerScroll && this.state.hoveredLayerIndex >= 3) {
                window.setTimeout(() => { layerScroll.scrollTop = (this.state.hoveredLayerIndex * 22) - 44; }, 0);
            }
        }
        else if (e.keyCode === KeyCodes.ENTER && this.state.isShowDropdown && this.state.hoveredLayerIndex > -1) {
            this.props.LayersList.forEach((layer, idx) => {
                if (idx === this.state.hoveredLayerIndex) {
                    if (layer.isSharedLayer && layer.shareAccess === ShareAccessType.SHARE_READONLY) {
                        return;
                    }
                    this.props.setActiveLayer(layer);
                    this.setState({ isShowDropdown: false, hoveredLayerIndex: -1 });
                    this.annotationLayerName = null
                }
            })
            this.toggleDropdown();
        }
    }

    toggleDropdown = () => {
        const rect = this.dropDownButton.getBoundingClientRect();
        // const style = { position: 'absolute', height: '170px', width: '248px', left: rect.left, top: rect.bottom + 2 };
        const dropHeight = this.props.LayersList.length <= 7 ? this.props.LayersList.length * 25 : '';
        const style = { left: rect.left, top: rect.bottom + 2, height: dropHeight };
        this.fontDropDownUpdate = this.state.isShowDropdown;
        this.setState({ isShowDropdown: !this.state.isShowDropdown, style: style });
    }
    showTooltip = (e, layer) => {
        const width = textWidth(e.target.innerText, {
            family: "calibri",
            size: 14
        });
        let xPoint = e.clientX;
        if (xPoint + width > window.innerWidth) {
            xPoint = window.innerWidth - (width + 20);
        }
        const toolStyle = { left: xPoint, top: e.clientY + 20 };
        this.fontDropDownUpdate = true;
        this.setState({ ...this.state, toolStyle, isShowTooltip: true, tooltipText: `${layer.layerName}${layer.isSharedLayer ? `(${layer.ownerName})` : ''}` });
    }

    hideTooltip = () => {
        this.setState({ ...this.state, isShowTooltip: false, toolStyle: {}, tooltipText: "" });
    }

    onDropDownItemClick = (layer) => {
        if (layer.isSharedLayer && layer.shareAccess === ShareAccessType.SHARE_READONLY) {
            return;
        }

        this.props.setActiveLayer(layer);
        this.setState({ isShowDropdown: !this.state.isShowDropdown, hoveredLayerIndex: -1 });
        this.annotationLayerName = null;
    }

    getLayerDropDownMenuItems = (layer, idx) =>
        <MenuItem id={layer.layerID} key={layer.layerID}
            className={idx === this.state.hoveredLayerIndex ? 'key-press-hover' : ''}
            onMouseEnter={(e) => { this.showTooltip(e, layer); this.setState({ ...this.state, hoveredLayerIndex: idx }) }}
            onMouseLeave={() => this.hideTooltip()}
            eventKey={layer.layerID}
            selected="selected"
            disabled={layer.isSharedLayer && layer.shareAccess === ShareAccessType.SHARE_READONLY}
            onClick={() => this.onDropDownItemClick(layer)}>
            {layer.layerName}{layer.isSharedLayer ? ` (${layer.ownerName})` : ''}
            {this.state.isShowTooltip && <PortalContainer><div className="annotation-layer-tooltip" style={this.state.toolStyle}>{this.state.tooltipText}</div></PortalContainer>}
        </MenuItem>

    render() {
        const selectedLayerNameCls = (!this.props.annotSelected && this.props.selectedLayer && this.props.selectedLayer.isSharedLayer && this.props.selectedLayer.shareAccess === ShareAccessType.SHARE_READONLY) || (this.props.annotSelected && this.props.annotSelected.isReadOnlyAnnotation) ? '-readonly' : '';
        let annotSelectedLayer = null;
        let annotationLayerName;
        if (this.props.annotSelected) {
            this.props.LayersList.forEach((layer) => {

                if (layer.layerID === this.props.annotSelected.layerID) {
                    annotationLayerName = layer.layerName + (layer.isSharedLayer ? ` (${layer.ownerName})` : '');
                    annotSelectedLayer = layer;
                }
            })
        }
        return (
            <div className="annotation-layers">
                <ul>
                    <li>
                        <div className="layer-eye-check">
                            {this.props.LayersList.length > 0 && <input id="toggleButton2" type="checkbox" checked={annotSelectedLayer !== null ? !annotSelectedLayer.isVisible : this.props.selectedLayer ? !this.props.selectedLayer.isVisible : false} onChange={() => this.props.toggleVisibility(annotSelectedLayer !== null ? annotSelectedLayer : this.props.selectedLayer)} />}
                            <div className="icn-eye-n-check"></div>
                        </div>
                        <div className="layer-name">
                            <div className="layer-name-select" ref={(ref) => (this.dropDownButton = ref)}>
                                {this.props.LayersList.length > 0 &&
                                    <DropdownButton title={this.props.annotSelected ? annotationLayerName : this.props.selectedLayer.layerName + (this.props.selectedLayer.isSharedLayer ? ` (${this.props.selectedLayer.ownerName})` : '')} bsSize="xsmall"
                                        className={`portal-layer-dd${selectedLayerNameCls}`} onClick={this.toggleDropdown} id='annot-dropdown-layer'>
                                        {this.state.isShowDropdown &&
                                            <PortalContainer>
                                                <div className="custom-scroll portal-dropdown layer-dropdown" style={this.state.isShowDropdown ? this.state.style : {}} ref={this.container}>
                                                    <ul id="layerSelectDropdownScroll">
                                                        {this.props.LayersList.map((layer, idx) => this.getLayerDropDownMenuItems(layer, idx))}
                                                    </ul>
                                                    <ScrollBar scrollId="layerSelectDropdownScroll" vScroll={true} scrollOnHover={true} />
                                                </div>
                                            </PortalContainer>}
                                    </DropdownButton>}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { LayersList, selectedLayer } = annotationReducers.annotationLayerManagementReducer;
    const { annotSelected } = annotationReducers.annotationChartModelReducer
    return ({ LayersList, selectedLayer, annotSelected });
}
const mapDispatchToProps = (dispatch) => ({
    setActiveLayer: (layer) => dispatch(setActiveLayer(layer)),
    toggleVisibility: (layer) => dispatch(toggleVisibility(layer))
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerDropDown);