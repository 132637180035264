class HubUtil {
    getHubURL() {
        let url;
        if (process.env.BRANCH_ENV) {
          let env = process.env.BRANCH_ENV;
          switch (env && env.toUpperCase()) {
            case "DEV":
              url = "http://ecmwebdev2/Index.html";
              break;
            case "STAGE":
              url = "https://hubstage.williamoneil.com/Index.html";
              break;
            case "PROD":
              url = "https://hub.williamoneil.com/Index.html";
              break;
            default:
              break;
          }
        }
        return url == null ? url = "http://ecmwebdev2/Index.html" : url;
      }
}

const hubUtil = new HubUtil();
export default hubUtil;