import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StringUtil from "StringUtil";
import LocalizationStore from "LocalizationStore";
import moment from "moment";

class CalendarButton extends PureComponent {
    onCalendarClick = () => {
        this.inputText.select();
        this.props.onCalendarClick(this.props.position);
    }
    
    render() {
        const { value, position, onChange, onKeyDown, isUserEdit } = this.props;
        const formattedVal = !isUserEdit && value ? StringUtil.formatDate(moment(value, 'MM-DD-YYYY').format('YYYY-MM-DD'), 'MM-DD-YYYY') : value;
        const calendarTitle = position === "left" ? LocalizationStore.getTranslatedData("CCG_Start","Start:") :  LocalizationStore.getTranslatedData("CCG_End","End:");
        return (
            <span className="calendar" ><span className="custom-calendar-title">{calendarTitle}</span>
                <input className="calendarButton" ref={(input) => this.inputText = input} type="text"
                    onClick={this.onCalendarClick} value={formattedVal} onChange={onChange} maxLength="10"
                    onKeyDown={onKeyDown} onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()} onCut={(e) => e.preventDefault()}/>
            </span>
        );
    }
}

CalendarButton.propTypes = {
    value: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    onCalendarClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    isUserEdit: PropTypes.bool.isRequired
}


export default CalendarButton;