import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class GetCurrentListData extends BaseServiceRequest {
  constructor(listId, msid, beginDate, endDate, sort, pageNo, pageSize, esfields, periodicity) {
    super();
    super.addRequestUri("getexternaldata.pb");
    super.addPathParameter(listId.toString());
    if (msid) super.addRequestParameter("msid", msid);
    if (beginDate) super.addRequestParameter("sdate", beginDate);
    if (endDate) super.addRequestParameter("edate", endDate);
    if (periodicity) super.addRequestParameter("periodicity", periodicity);
    if (sort) super.addRequestParameter("sort", sort);
    if (pageNo) super.addRequestParameter("pageNo", pageNo);
    if (pageSize) super.addRequestParameter("pageSize", pageSize);
    if(esfields ) super.addRequestParameter("esfields", esfields);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "GetExternalDataResponse";
  }

}
