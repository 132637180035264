import React from "react";
import ReactDOM from "react-dom";
import { Form } from "react-bootstrap";

import TabFundamentalsStore from 'Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js';
import LocalizationStore from 'Stores/Localization/LocalizationStore.js'
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import FundamentalsActions from "Actions/FundamentalsActions.js";
import UserInfoUtil from "UserInfoUtil";

import FundamentalsExplorerPanel from "./FundamentalsTable/FundamentalsExplorerPanel.jsx";
import FundamentalsRatiosTable from "./FundamentalsTable/FundamentalsRatiosTable.jsx";
import FundamentalsReportTable from "./FundamentalsTable/FundamentalsReportTable.jsx";
import FundamentalsFilter from "./FundamentalsTable/FundamentalsFilter.jsx";
import FundamentalsTemplateFilter from "./FundamentalsTable/FundamentalsTemplateFilter.jsx";
import FundamentalsIdeaListTitle from "./FundamentalsIdeaListTitle.jsx";
import FundamentalsHeader from "./FundamentalsHeader.jsx";
import FundamentalTabFilterType from 'Constants/FundamentalTabFilterType.js';
import FundamentalTabSortOrderType from 'Constants/FundamentalTabSortOrderType.js';
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js';
import SymbolType from 'Constants/SymbolType';
import ResizableWindow from "RayCustomControls/ResizableWindow/ResizableWindow.jsx";
import { find, size } from "underscore";
import IndustryGroupHeader from "../../../../RayCustomControls/MenuControl/IndustryGroupHeader.jsx";
import MetricDefinition from "../../../../RayCustomControls/CommonDialog/MetricDefinition.jsx";
import ErrorBoundary from "ErrorBoundary";
import SmartViewType from "SmartViewType";
import TabDataGraphActionClass from "../../../../Actions/TabDataGraphAction";
import TabFundamentalsHeaderStore from "Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsHeaderStore.js"
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";
import { connect } from "react-redux";
import { setMostRecentTradeDate } from "../../../../Actions/CalenderControl/CalenderControlAction.js";

let fundamentalTemplate = undefined;
let title = undefined;

class TabFundamental extends React.Component {
    constructor() {
        super();
        this.getFundamentalsReportData = this.getFundamentalsReportData.bind(this);
        this.FundamentalsStateChange = this.FundamentalsStateChange.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onStopResize = this.onStopResize.bind(this);
        this.getReportCurrency = this.getReportCurrency.bind(this);
        this.isReportDataAvailable = this.isReportDataAvailable.bind(this);
        this.updateLoadingState = this.updateLoadingState.bind(this);
        this.state = {
            optionsData: TabFundamentalsStore.getFundamentalsOptionsData(),
            reportData: TabFundamentalsStore.getFundamentalsReportData(),
            settings: TabFundamentalsStore.getState(),
            firstCellWidth: TabFundamentalsStore.getState().FundamentalResizeSettings.Width,
            isLoading: TabFundamentalsStore.getLoadingState(),
            isResizing: false,
            nonAnnualHeaderData: TabFundamentalsStore.getNonAnnualHeaderData(),
            ideaListTitleData: TabFundamentalsStore.getIdeaListData(),
            metrciAction: TabFundamentalsStore.getMetricAction()
        };
        TabFundamentalsStore.getOptionsData();
        TabFundamentalsStore.getIdeaListTitleData();
    }

    UNSAFE_componentWillMount() {
        TabFundamentalsStore.addChangeListener(this.FundamentalsStateChange);
    }

    componentDidMount() {
        document.getElementsByClassName("top-left").width = this.state.firstCellWidth;
    }

    componentDidUpdate() {
        this.isIntialLoad = false;
    }

    componentWillUnmount() {
        TabFundamentalsStore.removeChangeListener(this.FundamentalsStateChange);
    }

    FundamentalsStateChange() {
        this.getFundamentalsReportData();
        if(TabFundamentalsStore.getCurrentAction() === SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED){
            title = "";
            TabFundamentalsStore.clearCurrentAction();
        }
    }

    getFundamentalsReportData() {
        let currentReportType = this.state.settings.FundamentalReportType;
        this.setState({
            reportData: TabFundamentalsStore.getFundamentalsReportData(currentReportType),
            optionsData: TabFundamentalsStore.getFundamentalsOptionsData(),
            isLoading: TabFundamentalsStore.getLoadingState(),
            nonAnnualHeaderData: TabFundamentalsStore.getNonAnnualHeaderData(),
            ideaListTitleData: TabFundamentalsStore.getIdeaListData(),
            metrciAction: TabFundamentalsStore.getMetricAction()
        });
    }

    onResize(width) {
        this.setState({ firstCellWidth: width, isResizing: true });
    }

    onStopResize() {
        this.setState({ isResizing: false });
    }

    updateLoadingState() {
        this.isIntialLoad = true;
    }

    handleTemplateChange(e) {
        let settings = TabFundamentalsStore.getState();
        fundamentalTemplate = ReactDOM.findDOMNode(e.target).getAttribute('data-report-templateid');
        FundamentalsActions.setReportTemplate(fundamentalTemplate);
        if (settings.FundamentalFilter == FundamentalTabFilterType.All) {
            FundamentalsActions.getAllFilterReportData(settings);
        } else { FundamentalsActions.getReportData(settings); }
    }

    handleFilterChange(index, e) {
        let settings = TabFundamentalsStore.getState();
        let filterId = ReactDOM.findDOMNode(e.target).getAttribute('data-report-filterid');
        FundamentalsActions.setReportFilter(filterId);
        if (filterId == FundamentalTabFilterType.All) {
            FundamentalsActions.getAllFilterReportData(settings);
        } else {
            FundamentalsActions.getReportData(settings);
        }
    }

    handlePeriodicityChange(index, e) {
        this.isIntialLoad = true;
        let settings = TabFundamentalsStore.getState();
        let periodicity = ReactDOM.findDOMNode(e.target).getAttribute('data-report-periodicityid');

        let curState = { ...this.state.settings };
        curState.FundamentalPeriodicity = periodicity;
        this.setState({ settings: curState });

        FundamentalsActions.setFundamentalsPeriodicity(periodicity);
        if (settings.FundamentalFilter == FundamentalTabFilterType.All) {
            FundamentalsActions.getAllFilterReportData(settings);
        } else {
            FundamentalsActions.getReportData(settings);
        }
    }

    handleSortOrderChange() {
        let sortOrder = undefined;
        let settings = TabFundamentalsStore.getState();
        if (settings.FundamentalSortOrder == FundamentalTabSortOrderType.Ascending) {
            sortOrder = FundamentalTabSortOrderType.Descending;
        } else { sortOrder = FundamentalTabSortOrderType.Ascending; }
        FundamentalsActions.setSortOrderType(sortOrder);
    }

    getfundamentalReport(data, settings) {
        let fundamentalReport = undefined;
        switch (settings.FundamentalReportType) {
            case FundamentalTabReportType.Income:
            case FundamentalTabReportType.Balancesheet:
            case FundamentalTabReportType.Cashflow: fundamentalReport = <ErrorBoundary><FundamentalsReportTable reportData={data} settings={settings} width={this.state.firstCellWidth} isLoading={this.state.isLoading} handleSortOrderChange={this.handleSortOrderChange} headerData={this.state.nonAnnualHeaderData} isResizing={this.state.isResizing} /></ErrorBoundary>; break;
            case FundamentalTabReportType.Ratios: fundamentalReport = <ErrorBoundary><FundamentalsRatiosTable ratiosData={data} settings={settings} width={this.state.firstCellWidth} isLoading={this.state.isLoading} handleSortOrderChange={this.handleSortOrderChange} headerData={this.state.nonAnnualHeaderData} isResizing={this.state.isResizing} /></ErrorBoundary>; break;
            default: break;
        }
        return fundamentalReport;
    }

    getReportCurrency() {
        let currency = "";
        if (this.isReportDataAvailable(this.state.reportData) == true) {
            let SymbolInfo = DatagraphStore.getState().SymbolInfo;
            if (SymbolInfo.CountryCode == 1) {
                let defaultCurrencyCode = 'USD';
                let defaultCurrency = 'USD United States Dollars';
                let strCurrency = LocalizationStore.getTranslatedData('Currency_' + defaultCurrencyCode, defaultCurrency);
                currency = LocalizationStore.getTranslatedData('fr_Currency', '({0})', strCurrency);
            } else {
                if (UserInfoUtil.hasChinaEntitlement()) {
                    let defaultCurrency = this.state.reportData && this.state.reportData.currencyString ? this.state.reportData.currencyString.replace(/1(\w+)/, '$1') : SymbolInfo.DefaultCurrency.Code;
                    let strCurrency = LocalizationStore.getTranslatedData("Currency_" + defaultCurrency, defaultCurrency);
                    currency = LocalizationStore.getTranslatedData("fr_Currency", "({0})", strCurrency);
                } else {
                    currency = (this.state.reportData && this.state.reportData.currency) ? `(${this.state.reportData.currency.toString()})` : '';
                }
            }
            return currency;
        }
    }

    getReportTitle() {
        let settings = DatagraphStore.getState();
        //let SymbolInfo = DatagraphStore.getState().SymbolInfo;
        let finalTitle = "";
        let titleFormat = TabFundamentalsStore.getReportUniqueTitle();
        if (this.isReportDataAvailable(this.state.reportData) == true) {
            if (titleFormat != undefined) {
                if (this.state.settings.FundamentalReportType != FundamentalTabReportType.Ratios) {
                    if (UserInfoUtil.hasChinaEntitlement()) {
                        let strReportType = TabFundamentalsStore.getReportTypeString(this.state.settings.FundamentalReportType, FundamentalTabFilterType.Dollars);
                        let strScaleFactor = this.getScaleFactorString(titleFormat);
                        finalTitle = `${strReportType.replace("{0}", settings.CompanyName)}${strScaleFactor}`;
                    } else {
                        finalTitle = titleFormat.replace("{0}", settings.CompanyName);
                    }
                }
                else {
                    finalTitle = LocalizationStore.getTranslatedData("fr_RatiosReportfor", "Ratios Report for {0}", settings.CompanyName);
                }
            }
        }
        return finalTitle;
    }


    getScaleFactorStringForInternational(scaleFactorStr) {
        let strScaleFactor = "";
        switch (scaleFactorStr) {
            case "Billions":
                strScaleFactor = LocalizationStore.getTranslatedData("fr_inBillions", ",in Billions, except per-share amounts.");
                break;
            case "Millions":
                strScaleFactor = LocalizationStore.getTranslatedData("fr_inMillions", ",in Millions, except per-share amounts.");
                break;
            case "Thousands":
                strScaleFactor = LocalizationStore.getTranslatedData("fr_inThousands", ",in Thousands, except per-share amounts.");
                break;
            default:
                break;
        }
        return strScaleFactor;
    }

    getScaleFactorString(titleFormat) {
        if (titleFormat.indexOf("in Billions") > -1)
            return this.getScaleFactorStringForInternational("Billions");
        else if (titleFormat.indexOf("in Millions") > -1)
            return this.getScaleFactorStringForInternational("Millions");
        else if (titleFormat.indexOf("in Thousands") > -1)
            return this.getScaleFactorStringForInternational("Thousands");
        return "";
    }

    getFundamentalFilter(data, settings, datagraphSettings) {
        let fundamentalFilter = <div className="fundamental-table-block-action"><div className="fundamental4">&nbsp;</div></div>;
        if (this.isReportDataAvailable(this.state.reportData) == true && datagraphSettings.SymbolInfo.SymTypeEnum == SymbolType.USSTOCK) {
            switch (settings.FundamentalReportType) {
                case FundamentalTabReportType.Income:
                case FundamentalTabReportType.Balancesheet:
                case FundamentalTabReportType.Cashflow:
                    fundamentalFilter = <div className="fundamental-table-block-action"><label className="small-normal">{LocalizationStore.getTranslatedData("fr_Show", "Show:")}</label>
                        <div className="template value-blue small-normal"><FundamentalsFilter handleFilterChange={this.handleFilterChange} settings={this.state.settings} optionsData={this.state.optionsData} /></div> </div>
                    break;
                case FundamentalTabReportType.Ratios:
                    break;
                default:
                    break;
            }
        }
        return fundamentalFilter;
    }

    getFundamentalBlockClassName() {
        let className = "fundamental-table-block";
        if (this.state.settings.FundamentalReportType == FundamentalTabReportType.Ratios) {
            className += " padB50";
        }
        return className;
    }

    isReportDataAvailable(reportData) {
        let isAvailable = true;
    
        if (this.props.isHistoric) {
            return isAvailable = false;
        }
        if (reportData != null) {
            switch (this.state.settings.FundamentalReportType) {
                case FundamentalTabReportType.Income:
                case FundamentalTabReportType.Balancesheet:
                case FundamentalTabReportType.Cashflow:
                    if (reportData.reportDetails && size(reportData.reportDetails) == 0) {
                        isAvailable = false;
                    }
                    break;
                case FundamentalTabReportType.Ratios:
                    if (reportData.FundamentalsRatiosList && size(reportData.FundamentalsRatiosList) == 0) {
                        isAvailable = false;
                    }
                    break;
                default:
                    break;
            }
        }else{
            isAvailable = false;
        }
        return isAvailable;
    }

    getUnavailablePanel(datagraphSettings) {
        if (this.props.isHistoric) {
            return <div className="fundamentals-unavailable-panel"><span>{LocalizationStore.getTranslatedData("fr_ErrorTextBeforeLink","The report is unavailable for the current end date. Click")} <a style={{textDecoration: 'underline' }} onClick={this.handleClick} >{LocalizationStore.getTranslatedData("fr_LinkText","here")}</a> {LocalizationStore.getTranslatedData("fr_ErrorTextAfterLink","to change to the latest date with data.")}</span></div>
        }
        else {
            return <div className="fundamentals-unavailable-panel"><span className="medium-normal">{LocalizationStore.getTranslatedData("fd_available", "Fundamentals data is not available for ")} {datagraphSettings.SymbolInfo.Symbol}.</span></div>;
        }
    }

    handleClick = () => {
        // TabDataGraphActionClass.setMostRecentTradeDate();
        this.props.setMostRecentTradeDate();
    }

    render() {
        let headerState = TabFundamentalsHeaderStore.getState();
        let ideaListTitleData = this.state.ideaListTitleData;
        //let locale = navigator.language;
        // let data = this.state.reportData;
        let currentReportType = this.state.settings.FundamentalReportType;
        let data = TabFundamentalsStore.getFundamentalsReportData(currentReportType);

        let settings = Object.assign({}, this.state.settings);
        let datagraphSettings = DatagraphStore.getState();
        //let tabdatagraphSettings = TabDataGraphStore.getState().SymbolInfo;
        let fundamentalReport, fundamentalFilter = null; let reports;
        let tabFundClassName = settings.FundamentalReportType == FundamentalTabReportType.Ratios ? "pad-b-ratio" : "";


        fundamentalTemplate = this.state.settings.FundamentalTemplate;
        if (data) {
            fundamentalReport = this.getfundamentalReport(data, settings);
            fundamentalFilter = this.getFundamentalFilter(data, settings, datagraphSettings);
            title = this.getReportTitle(data);
        }

        let tableContent = (<div className={"tbl-fundamantal medium-normal " + tabFundClassName} id="fundamental-scrollable-div">
            <ResizableWindow className="fundamental-resize" width={this.state.firstCellWidth} minWidth={this.state.settings.FundamentalResizeSettings.MinWidth} maxWidth={this.state.settings.FundamentalResizeSettings.MaxWidth} height={"100%"} direction="Horizontal" pointerEvents="none" onStartResize={this.onResize} onStopResize={this.onStopResize} isFundamental={true}>
                <div className="add-div" style={{ width: this.state.firstCellWidth }}></div>
            </ResizableWindow>
            {fundamentalReport}
        </div>);

        const isLoading = TabFundamentalsStore.getLoadingState();

        let availableData = isLoading == false ? data == null ? 
        <div className="fundamentals-unavailable-panel"><span className="medium-normal">{LocalizationStore.getTranslatedData("fd_available", "Fundamentals data is not available for ")} {datagraphSettings.SymbolInfo.Symbol}.</span></div>
        : tableContent : <div className="inner-panel-body">
            <div className="group-box">
                <div className="loading loading-spinner"></div>
            </div></div>;
        reports = this.isReportDataAvailable(data) ? availableData : this.getUnavailablePanel(datagraphSettings);

        const symbolInfo = DatagraphStore.getState().SymbolInfo;
        //const industryName = symbolInfo.Industry197Name;
        const headerData = headerState.companyInfo.headerData;
        // const snapshotDataReady = companyInfo.snapshotDataReady;
        const indSymbol = headerData && headerData.indSymbol;
        const definition = this.props.definitions && find(this.props.definitions, (item) => item.IndustrySymbol === indSymbol);
        let industryName = null;
        if (headerData && headerData.IndustryGroupName)
            industryName = headerData.IndustryGroupName;

        return (
            <div className="fundamentalConsole">
                <div className="extender">
                    <div className="head-list-view">
                        <div className="tool-bar-area">
                            <div className="right-tool-items">
                            </div>
                        </div>
                    </div>
                    <div className="COLS fundamentalMainBlockWrap">
                        <div className="CELL list-inner-container">
                            <div className="list-view-area">
                                <div className="info-fundamental header withDD">
                                    <FundamentalsHeader />
                                    <FundamentalsIdeaListTitle stockData={ideaListTitleData.stockData} HiLowPoints={this.props.HiLowPoints} />
                                </div>

                                <div className="left-fundamental-view">
                                    <div className="fundamental-top medium-normal">
                                        <div className="title medium-bold ">{LocalizationStore.getTranslatedData("fr_reportNavTittle", "FINANCIAL REPORTS")}</div>
                                        <div className="report-tbl">
                                            <FundamentalsExplorerPanel settings={this.state.settings} updateLoadingState={this.updateLoadingState} />
                                        </div>
                                    </div>
                                </div>
                                <div className="main-fundamental-view">
                                    <div className={this.getFundamentalBlockClassName()}> <div className="fundamental-table-block-title">
                                        {(symbolInfo.CountryCode == 1 && this.isReportDataAvailable(this.state.reportData) && settings.FundamentalReportType != FundamentalTabReportType.Ratios) ?
                                            <div className="template">
                                                <Form>
                                                    <FundamentalsTemplateFilter handleTemplateChange={this.handleTemplateChange} settings={this.state.settings} optionsData={this.state.optionsData} />
                                                </Form>
                                            </div> : ""}
                                        {title}
                                        <span className="us-title xx-small-normal marl5 ">{this.getReportCurrency()}</span>
                                    </div>
                                        <ErrorBoundary>
                                            {fundamentalFilter}
                                            {reports}
                                        </ErrorBoundary>
                                    </div>
                                </div></div>
                        </div>
                    </div>
                </div>
                {
                    this.state.metrciAction &&
                    <MetricDefinition
                        definition={definition}
                        industryName={industryName}
                        caller={'Fundamentals'}
                        x={200}
                        y={200}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = ({ DatagraphReducers, RelatedInfoPanelReducers })=>{
    const { isHistoric, isMiniListOpen } = DatagraphReducers.DataGraphReducer;
    const { HiLowPoints } = DatagraphReducers.PriceChartReducer;
    const { isRIPanelOpen } = RelatedInfoPanelReducers.RiPanelReducer;
    return { isHistoric, isRIPanelOpen, isMiniListOpen, HiLowPoints }
}
const mapDispatchToProps = (dispatch) => ({
    setMostRecentTradeDate: () => dispatch(setMostRecentTradeDate())
});

export default connect(mapStateToProps, mapDispatchToProps)(TabFundamental)
