import {combineReducers} from 'redux';
import appColor from "../RayCustomControls/AppColor/reducer";
import scheduledLists from "../RayCustomControls/ScheduledLists/reduder";
import news from '../Reducers/news';
import currency from '../Reducers/CurrencyConversionReducer/currencyConversionReducer';
import comparisonGraph from '../Reducers/NavCompare/ComparisonGraphReducers';
import rotationGraph from '../Reducers/NavCompare/RotationGraphReducers';
import comparisonAndRotationMenuPanel from '../Reducers/NavCompare/MenuPanelReducers';
import CustomTIModuleReducer from "../Reducers/CustomTiChartReducer/CustomTIModuleReducer";
import customMetrics from '../Reducers/CustomMetricsReducers';
import ownership from '../Reducers/OwnershipReport/ownership';
import listView from '../Reducers/listView';
import RelatedInfoPanelReducers from '../Reducers/NavDataGraph/RelatedInfoPanel/index';
import DgHoldingsReducer from '../Reducers/DgHoldings/DgHoldingsReducer.js';
import externalDataUploader from '../Reducers/ExternalDataUploadReducer';
import navCompareReducer from '../Reducers/NavCompare/NavCompareReducer';
import annotationReducers from '../Reducers/AnnotationReducers';
import summaryReducer from '../Reducers/NavDataGraph/TabDataGraph/SummaryBlock';
import DatagraphReducers from '../Reducers/NavDataGraph/TabDataGraph';
import kpiReducer from '../Reducers/NavDataGraph/TabDataGraph/Indicators/KPIIndicator';
import listViewReducer from '../Reducers/NavList/ListView';
import alertReducer from '../Reducers/Alert';

const reducers = combineReducers({
    appColor: appColor,
    scheduledLists: scheduledLists,
    news: news,
    currency:currency,
    navCompareReducer: navCompareReducer,
    comparisonGraph: comparisonGraph,
    rotationGraph: rotationGraph,
    comparisonAndRotationMenuPanel: comparisonAndRotationMenuPanel,
    CustomTIModuleReducer: CustomTIModuleReducer,
    customMetrics: customMetrics,
    kpiMenu: kpiReducer,
    ownership: ownership,
    listView:listView,
	externalDataUploader: externalDataUploader,
    holdings:DgHoldingsReducer,
    annotationReducers:annotationReducers,
    DatagraphReducers: DatagraphReducers,
    RelatedInfoPanelReducers: RelatedInfoPanelReducers,
    summaryReducer: summaryReducer, // Changed the order because of periodicity change action in both reducer
    listViewReducer: listViewReducer,
    alertReducer

});

export default reducers;
