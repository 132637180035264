import React from "react";
import { Modal } from "react-bootstrap";
import LoginForm from "./LoginForm.jsx";
import dark from "!less-vars-loader!../../Asset/css/lessvars/colors-dark.less";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import LangType from "../../Constants/LangType";
import AdobeUtil from "../../Utils/AdobeUtil";
import AdobeConstants from "../../Constants/AdobeConstants";
import consoleStore from "../../Stores/ConsoleWindow/ConsoleStore.js";

export default class Login extends React.Component {
  constructor(props){
    super(props);

    let lang = LangType.RAY_DEFAULT;
    let prefix = "cn.";
    let suffix = ".cn";
    let hostname = window.location.hostname;
    hostname = hostname.toLowerCase();
    let showLicense = false;
    if(hostname.startsWith(prefix) || hostname.endsWith(suffix)){
          lang = LangType.ZH_CN;
          showLicense = true;
    } else {
      let temp = navigator.language || navigator.browserLanguage;
      if(temp !== null && temp != ""){
        switch (temp.toLowerCase()){
          case "zh-cn":
            lang = LangType.ZH_CN;
            break;
          default:
            lang = LangType.RAY_DEFAULT;
            break;
        }
      }
    }

    this.state={
      lang: lang,
      showLicense: showLicense,
      updateState: false
    }

    LocalizationStore.setLang(lang);
  }

  getBgClass() {
    let result = "login-bg-default";

    switch(this.state.lang){
      case LangType.ZH_CN:
        result = "login-bg-zh-cn";
        break;
      case LangType.RAY_DEFAULT:
        result = "login-bg-default";
        break;
      default:
        result = "login-bg-default";
        break;
    }

    return result;
  }

  UNSAFE_componentWillMount() {
    document.body.style.backgroundColor = dark.applicationBody;
    //Push data to DTM while loading login page
    AdobeUtil.DTMTrack({
      pageName: "Panaray : Login",
      siteSection: "Login",
      userType: "Visitor"
    },
     AdobeConstants.LOGIN_PAGE_VIEW);
  }

  componentWillUnmount() {
    document.body.style.backgroundColor = '';
    consoleStore.removeSessionExpiredPopup();
  }

  closeSessionExpirePopup = () =>{
    consoleStore.removeSessionExpiredPopup();
    this.setState({updateState:true});
  }

  render() {
    const reDirectStatus = (this.props.location.state && this.props.location.state.from === "/") ? true : false;
    const displaySessionExpirePopup = (consoleStore.getSessionExpiredPopupStatus() === "true") && reDirectStatus;

    return (
      <div className={this.getBgClass()}>
        <div className="loginWrap">
          <LoginForm />
        </div>
        {this.state.showLicense ? <div className="license"><a href="https://beian.miit.gov.cn" target="_blank">{LocalizationStore.getTranslatedData("ICP", "")}</a> <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502013602" target="_blank">{LocalizationStore.getTranslatedData("Net", "")}</a></div> : ""}
        {displaySessionExpirePopup ? (<Modal className="modal-popup session-expired" show={displaySessionExpirePopup} backdrop="static">
          <Modal.Header><h4>Session Expired!</h4></Modal.Header>
          <Modal.Body>
            <div className="modal-material">
              <p>You have been logged out due to inactivity. Click "OK" to log in.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box center">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.closeSessionExpirePopup}>OK</button>
            </span>
          </Modal.Footer>
        </Modal>) : ""}
      </div>
    );
  }
}
