import ListManager from "../../../../../Constants/ListManager.js";
import Serializable from "Serializable";

class TabExternalSettings extends Serializable {
    constructor() {
        super();
        this.ListExplorer = {};
        this.ListExplorer.MinWidth = 100;
        this.ListExplorer.MaxWidth = 500;

        this.MetricLibrary = {};
        this.MetricLibrary.MinHeight = 100;
        this.MetricLibrary.MaxHeight = 300;
    }
    getDefaultListExplorerWidth() {
        return 300;
      }
    
    

    getObjectMapKey() {
        return "TabExternalSettings";
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.TextSize = serializedValue.TextSize ? serializedValue.TextSize : ListManager.TextSize;
        this.ScreenOnOff = serializedValue.ScreenOnOff ? serializedValue.ScreenOnOff : ListManager.ScreenOnOff;
        this.SelectedListInfo = serializedValue.SelectedListInfo ? serializedValue.SelectedListInfo : this.getDefaultSelectedListInfo();
        if(!this.ListExplorer){
            this.ListExplorer = {};
            this.ListExplorer.MinWidth = 100;
            this.ListExplorer.MaxWidth = 500;
        }
        this.ListExplorer.Width = serializedValue.ListExplorerWidth >= 0 ? serializedValue.ListExplorerWidth : this.getDefaultListExplorerWidth();
        this.ListExplorer.PreviousWidth = serializedValue.ListExplorerPreviousWidth >= 0 ? serializedValue.ListExplorerPreviousWidth : this.getDefaultListExplorerWidth();
        if(!this.MetricLibrary){
            this.MetricLibrary = {};
            this.MetricLibrary.MinWidth = 100;
            this.MetricLibrary.MaxWidth = 300;
        }
        this.MetricLibrary.IsOpen = serializedValue.IsMetricLibraryOpen ? serializedValue.IsMetricLibraryOpen : this.getDefaultMetricLibraryOpenState();
        this.MetricLibrary.Width = serializedValue.MetricLibraryWidth ? serializedValue.MetricLibraryWidth : this.getDefaultMetricLibraryWidth();
    }

    getDataObject() {
        return {
            TextSize: this.TextSize,
            ScreenOnOff: this.ScreenOnOff,
            SelectedListInfo: this.SelectedListInfo,
            ListExplorerWidth: this.ListExplorer.Width,
            SelectedView: this.SelectedView,
            ListExplorerPreviousWidth: this.ListExplorer.PreviousWidth,
            IsMetricLibraryOpen: this.MetricLibrary.IsOpen
        };
    }
    
    getDefaultTabExternalSettings() {
        this.TextSize = ListManager.TextSize;
        this.ScreenOnOff = ListManager.ScreenOnOff;
        this.SelectedListInfo = this.getDefaultSelectedListInfo();
        this.ListExplorer.Width = this.getDefaultListExplorerWidth();
        this.ListExplorer.PreviousWidth = this.getDefaultListExplorerWidth();
        this.MetricLibrary.Height = this.getDefaultMetricLibraryHeight();
        this.MetricLibrary.IsOpen = this.getDefaultMetricLibraryOpenState();
        this.MetricLibrary.Width = this.getDefaultMetricLibraryWidth();
        return this;
    }
    getDefaultMetricLibraryWidth() {
        return 300;
    }

    getDefaultMetricLibraryHeight() {
    return 200;
    }

    getDefaultMetricLibraryOpenState() {
    return false;
    }
    
    getDefaultSelectedListInfo() {
        let selectedListInfo = new Serializable();
        selectedListInfo.SelectedListId = "";
        selectedListInfo.SelectedActualListId = "";
        selectedListInfo.SelectedListName = "";
       
        return selectedListInfo;
    }
}

export default TabExternalSettings;