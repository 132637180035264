import React from "react";
import { RiPanelCheckListTransLateHelper, RiPanelConstituentsTranslateHelper } from "../../../../../Utils/TranslateHelper";
import SectorWeightingGraph from "./SectorWeightingGraph.jsx";
import { useSelector } from "react-redux";
import UserInfoUtil from "UserInfoUtil";

const RiPanelAllocationInfo = () => {

    const state = useSelector((state) => state.RelatedInfoPanelReducers.RiPanelAllocationInfo);

    const datagraph_state = useSelector((state)=>state.DatagraphReducers.DataGraphReducer);
    const Symbol = datagraph_state?.SymbolInfo?.Symbol;
    const userInfo = UserInfoUtil?.getUserInfo();
    const entitleIdxUserList = userInfo?.entitleIdxUserList;
    const result = [];
    if (entitleIdxUserList) {
      entitleIdxUserList.forEach((item) => {
        if (item && item.symbol === Symbol && item.isActive === true) {  
          result.push(item);
        }
      });
    }
    const isUserEntitled = result.length > 0;

    return (
        <>
            <div className="allocationInfo">
                {state.loading ? <div className="news-message large-normal"><p>{state.loadingMessage}</p></div> : ""}
                {isUserEntitled ? (state.isNoDataAvailable && !state.loading ?
                    <div className="news-message  large-normal"><p>{state.errorMessage}</p></div> :
                    <div className="allocation-content">
                        <div className="allocation-title">{RiPanelCheckListTransLateHelper.ALLOCATIONS_SECTOR_WEIGHTINGS}</div>
                        <SectorWeightingGraph />
                    </div>) : <div className="news-message  large-normal"><p>{RiPanelConstituentsTranslateHelper.NOT_AVAILABLE}</p></div>}
            </div>
        </>
    )
}

export default RiPanelAllocationInfo