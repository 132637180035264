import React from "react";
import BaseServiceApi from 'BaseServiceApi';
import TreeLIstNode from './TreeLIstNode';
// import { getListInFavorite, getListByCategory, getListMenu } from "../../Utils/OwnerShipUtil.js";
// import {
//     sourceList,
//     setRenameNode,
//     saveRenameNode,
//     saveDeleteNode,
//     saveDuplicateNode,
//     setActiveNode,
//     setSelectedNode
// } from '../../Actions/ListViewActions';
// import { ExpandItemLevelAndGetItem, flatternDataSource } from "../../Utils/OwnerShipUtil.js";
// import ClickMenu from "ClickMenu";
// import { reject, findIndex, filter } from "underscore";
// import StringUtil from "StringUtil";

// let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const ListType = BaseServiceApi.rayData["ListType"];
// let ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

const TreeHeader = ({
    list,
    rowslist,
    nodeToRename,
    selectedNode,
    activeNode,
    duplicateNode,
    activeFolderID,
    dragNodeID,
    // ownershipTableData,
    // activeNodeChanged,
    handleClick,
    fontSizeClass,
    showValidationWindow,
    updateScrollBar,
    dispatch
}) => {    
    const collapsed = !rowslist.expandNode;
    const IsVisible = rowslist.isVisible;
    let containerClassName = (rowslist.listType === ListType.FOLDER_List ? 'tree-view_children_folder folder-level-1' : 'tree-view_children folder-level-2');

    const isChFolder = rowslist.childNodes.length > 0 ? true : false;

    const childNodes = rowslist.childNodes;
    if (IsVisible) {
        if (collapsed) {
            containerClassName += ' tree-view_children-collapsed folder-level-3';
        }
        return (
            <div className={'tree-view'} id={rowslist.nodeId.low} >
                {
                    <div>
                        <TreeLIstNode
                            list={list}
                            rowslist={rowslist}
                            nodeToRename={nodeToRename}
                            activeNode={activeNode}
                            containerClassName={containerClassName}
                            selectedNode={selectedNode}
                            duplicateNode={duplicateNode}
                            activeFolderID={activeFolderID}
                            dragNodeID={dragNodeID}
                            // ownershipTableData={ownershipTableData}
                            // activeNodeChanged={activeNodeChanged}
                            // handleClick={handleClick}
                            showValidationWindow={showValidationWindow}
                            updateScrollBar={updateScrollBar}
                            fontSizeClass={fontSizeClass}
                            dispatch={dispatch}
                        />
                        <div className={containerClassName} >
                            {
                                isChFolder &&
                                childNodes.map((rowslist, row) =>
                                    rowslist.isVisible &&
                                    <TreeHeader
                                        list={list}
                                        rowslist={rowslist}
                                        nodeToRename={nodeToRename}
                                        activeNode={activeNode}
                                        selectedNode={selectedNode}
                                        duplicateNode={duplicateNode}
                                        activeFolderID={activeFolderID}
                                        dragNodeID={dragNodeID}
                                        // ownershipTableData={ownershipTableData}
                                        // activeNodeChanged={activeNodeChanged}
                                        handleClick={handleClick}
                                        fontSizeClass={fontSizeClass}
                                        showValidationWindow={showValidationWindow}
                                        key={row}
                                        dispatch={dispatch}
                                    />

                                )
                            }
                            {/* <img id="ghostImage" src="/Assets/images/Blank-Canvas.png" style={{ visibility: 'hidden' }} />
                            <div id="list-drag-ghost-image"></div> */}
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default TreeHeader;