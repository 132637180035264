import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubMenuPanel from './SubMenuPanel';
import Loading from './Loading';
import { PrintMode } from '../../../../print/printmode';
import { toggleRIPanel } from '../../../../Actions/ComparisonAndRotationMenuActions';
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType from "CompareTabType";

class MainMenuPanel extends Component {

    render() {
        const display = isComparisonTabSelected ? isComparisonMenuPanelOpen : isRotationMenuPanelOpen;
        let displayRI = 'block';
        if (display) {
            displayRI = 'none';
        }
        const { isComparisonTabSelected, isRotationMenuPanelOpen, isComparisonMenuPanelOpen, toggleRIPanel } = this.props;
        return (
            <div id="relatedInfoBox" className={isComparisonTabSelected ? "CELL related-info-box comp-right-panel" : "CELL related-info-box"} style={{ padding: PrintMode.printing ? '0 0 55px' : '0 0 107px' }}>
                <div id="wrapperRight" className={isComparisonTabSelected ? isComparisonMenuPanelOpen ? "active-right" : "" : isRotationMenuPanelOpen ? "active-right" : ""}>
                    <div id="wrapRight" className="wrapRight" >
                        <div id="sidebar-wrapper-right" className="compare-RIpanel">
                            <div className="panel-container" style={{ display: displayRI }}>
                                <div className="panel panel-default">
                                    <div className="panel-body graph-body">
                                        <div className="CELL compare-related-info-panel">
                                            <ErrorBoundary><Loading /></ErrorBoundary>
                                            <ErrorBoundary><SubMenuPanel /></ErrorBoundary>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {!PrintMode.printing && <div id="menu-toggle-right" onClick={toggleRIPanel}>
                                <div className="COLS">
                                    <div className="CELL _25PX SideBar rightBar">
                                        <div className="dot-slide-bar marl5"></div>
                                        <div className="dot-slide-bar1 graphs"></div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>);
    }
}

const mapStateToProps = (state) => {
    const isComparisonTabSelected = state.navCompareReducer.selectedTab === CompareTabType.ComparisonGraph;
    const isComparisonMenuPanelOpen = state.comparisonAndRotationMenuPanel.MenuTabReducer.isComparisonMenuPanelOpen;
    const isRotationMenuPanelOpen = state.comparisonAndRotationMenuPanel.MenuTabReducer.isRotationMenuPanelOpen;

    return { isComparisonTabSelected, isComparisonMenuPanelOpen, isRotationMenuPanelOpen };
}

const mapDispatchToProps = (dispatch) => ({
    toggleRIPanel: () => dispatch(toggleRIPanel())
})

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuPanel);
