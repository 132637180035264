import React, { Fragment } from 'react';

import AccessLevel from "./AccessLevel.jsx";
import Permission from "./Permission.jsx";
import { ShareActionConstants } from "../../../Constants/ShareConstants";
import RecipientData from "./RecipientData.jsx";
import AddRecepient from "./AddRecepient.jsx";
import LocalizationStore from 'LocalizationStore';
import ScrollBar from "ScrollBar";
import { Table } from "react-bootstrap";

const SharedData = ({ sharedData, sharedAction, targetData, isIcs }) => {
    return (
        <div className="share-grid-holder">
            <div className="share-table-scroll custom-scroll-light up-scroll-index" data-test-id="main-div">
                <div className="custom-scroll">
                    <div className="share-scroller" id="shareVerticalScroll">
                        <Table className="formTbl share-display-grid">
                            <thead>
                                <tr>
                                    <th className="column1">
                                        {sharedAction === ShareActionConstants.SHARE_RECIPIENTS
                                            ? LocalizationStore.getTranslatedData("syb_type",'TYPE')
                                            : LocalizationStore.getTranslatedData("LM_TP_Name",'Name')
                                        }
                                    </th>
                                    <th className="column2">
                                        {sharedAction === ShareActionConstants.SHARE_RECIPIENTS
                                            ? LocalizationStore.getTranslatedData("LM_TP_Name",'NAME')
                                            : LocalizationStore.getTranslatedData("LM_TP_Organization",'Organization')
                                        }
                                    </th>
                                    <th className="column3">{LocalizationStore.getTranslatedData("LM_TP_PermissionLevel","Permission Level")}</th>
                                </tr>
                            </thead>
                            {sharedData && sharedData.length > 0
                                ?
                                <tbody>
                                    {
                                        sharedAction === ShareActionConstants.SHARE_RECIPIENTS
                                            ?
                                            sharedData.map((sharedata, index) =>
                                                <RecipientData
                                                    shareData={sharedata}
                                                    index={index}
                                                    sharedAction={sharedAction}
                                                    sharedData={sharedData}    
                                                    isIcs={isIcs}                         
                                                    key={index}
                                                />
                                            )
                                            :
                                            sharedData.map((sharedata, index) =>

                                            
                                                <tr key={index}>
                                                    <td className="column1">{sharedata.userName}</td>
                                                    <td className="column2">{sharedata.organization}</td>
                                                    <td className="column3">
                                                        {
                                                            <Fragment>
                                                                {
                                                                    sharedata.isShared &&
                                                                    <AccessLevel
                                                                        accessLvl={sharedata.accessLvl}
                                                                        recipientid={sharedata.recipientId}
                                                                        isShared={sharedata.isShared}
                                                                        isICS={sharedata.isICS}
                                                                        sharedData={sharedData}
                                                                        sharedAction={sharedAction}
                                                                        targetDataName={targetData.name}
                                                                    />
                                                                }
                                                                <Permission
                                                                    isShared={sharedata.isShared}
                                                                    recipientid={sharedata.recipientId}
                                                                    sharedData={sharedData}
                                                                />
                                                            </Fragment>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                </tbody>
                                :
                                <tbody>
                                    <tr>
                                        <td colSpan="3"> 
                                            <span className="msg-nobody"><span className="text">{LocalizationStore.getTranslatedData("LM_TP_Nobody","Nobody")}</span></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3">
                                            <AddRecepient
                                                caller={'body'}
                                                targetData={targetData}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </Table>
                    </div>

                </div>
                <ScrollBar scrollId="shareVerticalScroll" vScroll={true} />
            </div>
        </div>
    );
}

export default SharedData;