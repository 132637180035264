import { AnnotationConstant } from "Constants/AnnotationConstants";
import { connect } from 'react-redux';
import { getSelectedAnnotationInfo } from '../../../../../../Reducers/AnnotationReducers/reselectorsdata';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import PortalContainer from 'PortalContainer';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import { DropdownButton, MenuItem } from "react-bootstrap";
import { handleWeightChange, updateTargetAnnotation } from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent, Fragment } from 'react';

const { ActionTypes } = AnnotationConstant;
class AnnotationsWeightSettings extends PureComponent {

  constructor() {
    super();
    this.state = {
      isShowDropdown: false,
      style: {},
      hoveredWeightIndex: -1
    }
    this.container = React.createRef();
    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.onDocumentClick);
    window.addEventListener('resize', this.onResize);
    document.addEventListener("keydown", this.onDocumentKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentClick);
    window.removeEventListener('resize', this.onResize);
    document.addEventListener("keydown", this.onDocumentKeyPress);
  }

  componentDidUpdate() {

    if (this.state.hoveredWeightIndex === -1) {
      let initialWeightIndex = -1
      switch (this.getWeight(this.props.type, this.props.modelInfo)) {
        case 1:
          initialWeightIndex = 0;
          break;
        case 2:
          initialWeightIndex = 1;
          break;
        case 4:
          initialWeightIndex = 2;
          break;
        case 6:
          initialWeightIndex = 3;
          break;
        case 8:
          initialWeightIndex = 4;
          break;
        case 16:
          initialWeightIndex = 5;
          break;
        default:
          break;
      }
      this.setState({ ...this.state, hoveredWeightIndex: initialWeightIndex })
    }
  }

  onDocumentClick = (event) => {
    if (
      (this.container.current &&
        !this.container.current.contains(event.target))
    ) {
      this.setState({
        isShowDropdown: false,
        hoveredWeightIndex: -1
      });
    }
  }

  onResize = () => {
    this.setState({
      isShowDropdown: false,
      hoveredWeightIndex: -1
    });
  }

  onDocumentKeyPress = (e) => {
    if (e.keyCode === KeyCodes.UP_ARROW && this.state.isShowDropdown) {
      e.preventDefault();
      if (this.state.hoveredWeightIndex > 0) {
        this.setState({ ...this.state, hoveredWeightIndex: this.state.hoveredWeightIndex - 1 })
      }
    }
    else if (e.keyCode === KeyCodes.DOWN_ARROW && this.state.isShowDropdown && this.state.hoveredWeightIndex < 5) {
      this.setState({ ...this.state, hoveredWeightIndex: this.state.hoveredWeightIndex + 1 })
    }
    else if (e.keyCode === KeyCodes.ENTER && this.state.isShowDropdown && this.state.hoveredWeightIndex > -1) {
      switch (this.state.hoveredWeightIndex) {
        case 0:
          this.onWeightChange(1, this.props.type);
          break;
        case 1:
          this.onWeightChange(2, this.props.type);
          break;
        case 2:
          this.onWeightChange(4, this.props.type);
          break;
        case 3:
          this.onWeightChange(6, this.props.type);
          break;
        case 4:
          this.onWeightChange(8, this.props.type);
          break;
        case 5:
          this.onWeightChange(16, this.props.type);
          break;
        default:
          break;
      }
      this.toggleDropdown();
    }
  }

  onWeightChange = (weight, type) => {
    weight = parseInt(weight);
    let action = '';

    if (type === "line") {
      action = ActionTypes.HANDLE_WEIGHT_CHANGE;
    }
    else if (type === "border") {
      action = ActionTypes.HANDLE_BORDER_WEIGHT_CHANGE;
    }
    else {
      action = ActionTypes.HANDLE_EXTENSION_WEIGHT_CHANGE;
    }

    this.props.handleWeightChange(action, weight);
    if (this.props.annotSelected && (this.props.annotSelected.type === this.props.selectedToolType || this.props.annotSelected.type === this.props.selectedShapeType)) {
      this.props.updateTargetAnnotation(action, weight);

      if (this.props.annotSelected.type === RayAnnotationType.LINE_AnnotationType && (this.props.annotSelected.startArrowChecked || this.props.annotSelected.endArrowChecked)) {
        this.props.updateTargetAnnotation(null, null, ActionTypes.HANDLE_LINE_DRAW);
      }
    }
    this.setState({ ...this.state, hoveredWeightIndex: - 1, isShowDropdown: false })
  }

  getWeight(type, modelInfo) {
    if (type === "line") {
      return modelInfo.weight
    }
    else if (type === "border") {
      return modelInfo.borderWeight
    }
    else {
      return modelInfo.extensionLineWeight
    }
  }

  toggleDropdown = () => {
    const rect = this.dropDownButton.getBoundingClientRect();
    const style = { position: 'absolute', left: rect.left, top: rect.bottom };
    this.setState({ isShowDropdown: !this.state.isShowDropdown, style: style });
  }

  hideDropDownMenu = () => {
    if (this.state.isShowDropdown) {
      this.setState({ isShowDropdown: false, style: {} });
    }
  }

  render() {
    const { type, label } = this.props;
    const weight = this.getWeight(type, this.props.modelInfo);
    return (
      <Fragment>
        {label && <span className="swatches-label">WEIGHT</span>}
        <div className="swatches-field weight" ref={(ref) => (this.dropDownButton = ref)}>
          <DropdownButton className="" noCaret id="annot-dropdown-weight" title={<span className="selected-weight">{weight}</span>} onClick={this.toggleDropdown}>
            {this.state.isShowDropdown &&
              <PortalContainer>
                <ul className="portal-dropdown weight-dropdown" style={this.state.isShowDropdown ? this.state.style : {}} ref={this.container}>
                  <MenuItem key={1} className={(this.state.hoveredWeightIndex === 0 && weight === 1) ? 'selectHover' : this.state.hoveredWeightIndex === 0 ? 'key-press-hover' : weight === 1 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredWeightIndex: 0 })} onSelect={() => this.onWeightChange(1, type)} ><u className="icn-select"></u><b>1 px</b><i className="weight-1"></i></MenuItem>
                  <MenuItem key={2} className={(this.state.hoveredWeightIndex === 1 && weight === 2) ? 'selectHover' : this.state.hoveredWeightIndex === 1 ? 'key-press-hover' : weight === 2 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredWeightIndex: 1 })} onSelect={() => this.onWeightChange(2, type)} ><u className="icn-select"></u><b>2 px</b><i className="weight-2"></i></MenuItem>
                  <MenuItem key={4} className={(this.state.hoveredWeightIndex === 2 && weight === 4) ? 'selectHover' : this.state.hoveredWeightIndex === 2 ? 'key-press-hover' : weight === 4 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredWeightIndex: 2 })} onSelect={() => this.onWeightChange(4, type)} ><u className="icn-select"></u><b>4 px</b><i className="weight-4"></i></MenuItem>
                  <MenuItem key={6} className={(this.state.hoveredWeightIndex === 3 && weight === 6) ? 'selectHover' : this.state.hoveredWeightIndex === 3 ? 'key-press-hover' : weight === 6 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredWeightIndex: 3 })} onSelect={() => this.onWeightChange(6, type)} ><u className="icn-select"></u><b>6 px</b><i className="weight-6"></i></MenuItem>
                  <MenuItem key={8} className={(this.state.hoveredWeightIndex === 4 && weight === 8) ? 'selectHover' : this.state.hoveredWeightIndex === 4 ? 'key-press-hover' : weight === 8 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredWeightIndex: 4 })} onSelect={() => this.onWeightChange(8, type)} ><u className="icn-select"></u><b>8 px</b><i className="weight-8"></i></MenuItem>
                  <MenuItem key={16} className={(this.state.hoveredWeightIndex === 5 && weight === 16) ? 'selectHover' : this.state.hoveredWeightIndex === 5 ? 'key-press-hover' : weight === 16 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredWeightIndex: 5 })} onSelect={() => this.onWeightChange(16, type)} ><u className="icn-select"></u><b>16 px</b><i className="weight-16"></i></MenuItem>
                </ul>
              </PortalContainer>}
          </DropdownButton>
        </div>
      </Fragment>
    );
  }
}

AnnotationsWeightSettings.propTypes = {

};

const mapStateToProps = (state) => ({
  modelInfo: getSelectedAnnotationInfo(state),
  annotSelected: state.annotationReducers.annotationChartModelReducer.annotSelected,
  selectedToolType: state.annotationReducers.annotationMenuReducer.selectedToolType,
  selectedShapeType: state.annotationReducers.annotationMenuReducer.ShapesModelInfo.selectedType
})

const mapDispatchToProps = (dispatch) => ({
  handleWeightChange: (action, weight) => dispatch(handleWeightChange(action, weight)),
  updateTargetAnnotation: (action, data, computationAction) => updateTargetAnnotation(action, data, computationAction)
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AnnotationsWeightSettings);