import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class CreateAlertRequest extends BaseServiceRequest {
  constructor(msid, targetPrice, currencyCode, note) {
    super();
    super.addRequestUri("create.pb");

    const ReqProto = BaseServiceApi.rayData["AlertData"];
    const alertData = new ReqProto();
    alertData.isActive = 1;
    alertData.isTriggered = 0;

    const ReqProtoData = BaseServiceApi.rayData["PriceAlertData"];
    const priceData = new ReqProtoData();
    priceData.AlertData = alertData;
    priceData.msid = msid;
    priceData.targetPrice = targetPrice;
    priceData.currencyCode = currencyCode;
    priceData.note = note;

    const reqcontent = priceData.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.ALERTS;
  }

  getMessageName() {
    return "Response";
  }

}
