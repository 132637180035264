import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalClose from './ModalClose';
import ModalTitle from './ModalTitle';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';


export { // without default
    Modal,ModalHeader, ModalClose, ModalTitle,ModalBody,ModalFooter
  }