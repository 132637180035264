
import CalcStreetTargetPrice from "../../../../Utils/Calcs/CalcStreetTargetPrice";
import DefaultDataGraphSetting from "../../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/DefaultDataGraphSettings";
import GraphType from "../../../../Constants/GraphType";
import { LineDialogPeriodicityTranslateHelper } from "../../../../Utils/TranslateHelper";
import { PeriodicityTabsConstants } from "../../../../Constants/PeriodicityTabsConstants";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "../../../../Stores/ConsoleWindow/Settings/SettingsStore";

const { ActionTypes } = PriceChartConstants;

const initialState = {
    streetTargetPricePointData: [],
    isStreetTargetPriceVisible: false,
    showTag: false,
    showHiLowEstimate: false,
    showTargetPriceZones: false,
    lineColor: 'P999900CBCB01',
    showAllInfoTag: false,
    tagHeight: 20,
    tagWidth: 115,
    selectTagOverFlow: false,
    showBox: false,
    STPDialogSettings: [],
    STPDialogDefaultSettings: DefaultDataGraphSetting.getStreetTargetPriceSetting(),
    StreetTargetPriceSettingsObj: DefaultDataGraphSetting.getStreetTargetPriceSetting(),
    selectedPeriodicity: GraphType.Weekly,
    periodicity: GraphType.Weekly,
    majorPeriodicity: GraphType.Weekly,
    headerClass: 'ti-indicators corporate-events 0corpEvents-dialog',
    HeaderText: 'STREET PRICE TARGET'
}
const StreetTargetPriceReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.INIT_STREET_TARGET_PRICE_STATES:{
            const calcSTP = new CalcStreetTargetPrice({ chartWidth: action.chartWidth, chartHeight: action.chartHeight, scale: action.scale, nodeWidth: action.nodeWidth});
            const StreetTargetPriceSettings = action.viewsSettings.StreetTargetPriceSettings[action.majorPeriodicity];
            return {
                ...state,
                streetTargetPricePointData: calcSTP.CalculateSTPData(action.streetTargetPriceData, action.currPrice, action.TimeLine, action.periodicity, action.endDate),
                StreetTargetPriceSettingsObj: action.viewsSettings.StreetTargetPriceSettings,
                periodicity: action.periodicity,
                majorPeriodicity: action.majorPeriodicity,
                isStreetTargetPriceVisible: StreetTargetPriceSettings.IsVisible,
                showTag: StreetTargetPriceSettings.showTag,
                showHiLowEstimate: StreetTargetPriceSettings.showHiLowEstimate,
                showTargetPriceZones: StreetTargetPriceSettings.showTargetPriceZones,
                lineColor: StreetTargetPriceSettings.lineColor,
                showAllInfoTag: false
            }
        }
        case ActionTypes.UPDATE_SPTTAG_SETTINGS:{
            state.StreetTargetPriceSettingsObj[state.majorPeriodicity].showTag = action.showTag;
            SettingsStore.saveSettings();
            return{
                ...state,
                showTag: action.showTag
            }
        }
        case ActionTypes.UPDATE_SPT_STATES:
            return{
                ...state,
                ...action.states
            }
        case ActionTypes.OPEN_STP_SETTINGS_DIALOG:
            return {
                ...state,
                isSTPDialogOpen: true,
                selectedPeriodicity: state.periodicity,
                STPDialogSettings: Object.keys(PeriodicityTabsConstants).map((periodicity)=>({
                    periodicity,
                    header: LineDialogPeriodicityTranslateHelper[periodicity],
                    lineColor: state.StreetTargetPriceSettingsObj[periodicity].lineColor,
                    showHiLowEstimate: state.StreetTargetPriceSettingsObj[periodicity].showHiLowEstimate,
                    showTargetPriceZones: state.StreetTargetPriceSettingsObj[periodicity].showTargetPriceZones
                }))
            }
        case ActionTypes.RESTORE_STP_SETTINGS_DIALOG:
            return {
                ...state,
                STPDialogSettings: state.STPDialogSettings.map((setting)=>{
                    if(setting.periodicity === state.selectedPeriodicity){
                        setting.lineColor = state.STPDialogDefaultSettings[setting.periodicity].lineColor;
                        setting.showHiLowEstimate = state.STPDialogDefaultSettings[setting.periodicity].showHiLowEstimate;
                        setting.showTargetPriceZones = state.STPDialogDefaultSettings[setting.periodicity].showTargetPriceZones;
                    }
                    return setting; 
                })
            }
        case ActionTypes.SAVE_STP_SETTINGS_DIALOG:{
            state.STPDialogSettings.forEach((setting)=> {
                state.StreetTargetPriceSettingsObj[setting.periodicity].lineColor = setting.lineColor;
                state.StreetTargetPriceSettingsObj[setting.periodicity].showHiLowEstimate = setting.showHiLowEstimate;
                state.StreetTargetPriceSettingsObj[setting.periodicity].showTargetPriceZones = setting.showTargetPriceZones;
            })
            const StreetTargetPriceSettings = { ...state.StreetTargetPriceSettingsObj[state.majorPeriodicity]};
            SettingsStore.saveSettings();
            return {
                ...state,
                STPDialogSettings: [],
                isSTPDialogOpen: false,
                showAllInfoTag:false,
                lineColor: StreetTargetPriceSettings.lineColor,
                showHiLowEstimate: StreetTargetPriceSettings.showHiLowEstimate,
                showTargetPriceZones: StreetTargetPriceSettings.showTargetPriceZones,
            }
        }
        case ActionTypes.CANCEL_STP_SETTINGS_DIALOG:
            return {
                ...state,
                isSTPDialogOpen: false,
                STPDialogSettings: []
            }
        case ActionTypes.HANDLE_STP_COLOR_CHANGE:
            return {
                ...state,
                STPDialogSettings: state.STPDialogSettings.map((setting)=>{
                    if(setting.periodicity === state.selectedPeriodicity){
                        setting.lineColor = action.color
                    }
                    return setting;
                })
            }
        case ActionTypes.CHANGE_STP_HI_LOW_STATUS:
            return {
                ...state,
                STPDialogSettings: state.STPDialogSettings.map((setting)=>{
                    if(setting.periodicity === state.selectedPeriodicity){
                        setting.showHiLowEstimate = !setting.showHiLowEstimate
                    }
                    return setting;
                })
            }
        case ActionTypes.CHANGE_STP_PRICE_STATUS:
            return {
                ...state,
                STPDialogSettings: state.STPDialogSettings.map((setting)=>{
                    if(setting.periodicity === state.selectedPeriodicity){
                        setting.showTargetPriceZones = !setting.showTargetPriceZones
                    }
                    return setting;
                })
            }
        case ActionTypes.CHANGE_STP_DIALOG_TAB:
            return {
                ...state,
                selectedPeriodicity: action.periodicity
            }
        case ActionTypes.UPDATE_STP_VISIBILTY:
            return {
                ...state,
                isStreetTargetPriceVisible: action.isStreetTargetPriceVisible,
                showAllInfoTag: false
            }
        case ActionTypes.CLEAR_STREET_TARGET_PRICE_STATES:
            return {
                ...initialState
            }
        default:
            return state;
    }
}
export default StreetTargetPriceReducer;