import { connect } from 'react-redux';
import LayerDropDown from '../Presentational/LayerDropDown';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import RIPanelChannelInfo from "../Presentational/RIPanelChannelInfo";
import RIPanelFibonacciInfo from "../Presentational/RIPanelFibonacciInfo";
import RIPanelLineInfo from "../Presentational/RIPanelLineInfo";
import RIPanelMeasurementInfo from "../Presentational/RIPanelMeasurementInfo";
import RIPanelPointerInfo from '../Presentational/RIPanelPointerInfo';
import RIPanelShapeInfo from "../Presentational/RIPanelShapeInfo";
import RIPanelTextInfo from "../Presentational/RIPanelTextInfo";
import React, { PureComponent } from 'react';

class RIPanelAnnotationToolContent extends PureComponent {
    render() {
        const { selectedToolType } = this.props;
        return (
            <div className="annotation-holder">

                {selectedToolType !== 0 && <LayerDropDown />}  {/* drop down is not displayed for pointer type */}
                {this.props.selectedAnnotId.length > 1
                    ?
                    <div className="annotation-information">
                        <p>Multiple annotations selected. Only one annotation can be edited at a time.</p>
                        <p>To edit an annotation, select it on the chart and the settings will be available here.</p>
                    </div>
                    :
                    <TabView selectedToolType={selectedToolType} />}

            </div>
        )
    }
}

const TabView = (props) => {
    switch (props.selectedToolType) {
        case RayAnnotationType.LINE_AnnotationType:
            return <RIPanelLineInfo />

        case RayAnnotationType.TEXT_AnnotationType:
            return <RIPanelTextInfo />

        case RayAnnotationType.RECT_AnnotationType:
        case RayAnnotationType.ELLIPSE_AnnotationType:
        case RayAnnotationType.TRIANGLE_AnnotationType:
            return <RIPanelShapeInfo />

        case RayAnnotationType.PARALLEL_LINES_AnnotationType:
            return <RIPanelChannelInfo />

        case RayAnnotationType.FIB_AnnotationType:
            return <RIPanelFibonacciInfo />

        case RayAnnotationType.MEASUREMENT_AnnotationType:
            return <RIPanelMeasurementInfo />

        default:
            return <RIPanelPointerInfo />;
    }

}

const mapStateToProps = ({ annotationReducers }) => {
    const { selectedToolType } = annotationReducers.annotationMenuReducer;

    const { selectedAnnotId } = annotationReducers.annotationChartModelReducer;
    return ({ selectedToolType, selectedAnnotId });
}

export default connect(mapStateToProps)(RIPanelAnnotationToolContent);