import HubSalesReps from "./HubSalesReps";
import HubToolbar from "./HubToolbar";
import { HubUserRole } from "../../../../../../Constants/HubRIPanelConstants";
import React from "react";
import { TranslateHelper } from "../../../../../../Utils/TranslateHelper";
import { useSelector } from 'react-redux';

const RiHubPanelInfo = (props) => {

    const data = useSelector((state) => state.RelatedInfoPanelReducers.RiPanelHub);

    const { hubRole } = props;
    const { loading } = data;

    if (Boolean(loading)) {
        return <div className="hub-loading-container">
            <div className="news-message large-normal"><p>{TranslateHelper["Loading"]}</p></div>
        </div>
    }

    return (
        <>
            <div className="hub-body-container">
                {Boolean(hubRole) && <HubToolbar />}
                {hubRole === HubUserRole.SalesRep && <HubSalesReps manager={false} itemHeight={45} />}
                {hubRole === HubUserRole.Manager && <HubSalesReps manager={true} itemHeight={50} />}
            </div>
        </>
    )
}

export default RiHubPanelInfo