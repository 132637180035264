import { connect } from 'react-redux'
import React from 'react';
import ThemeHelper from "ThemeHelper";
function showInfoBox(showText, screenX, screenY, colorInfo) {
  const backgroundColor = ThemeHelper.getThemedBrush(colorInfo);
  const xLeft = `${screenX}px`;
  const ytop = `${screenY}px`;
  const height = "20px";
  const width = "auto";
  const lineFontColor = ThemeHelper.getFontColor(backgroundColor);
  const textStyle = {
    backgroundColor: backgroundColor,
    borderColor: "#333333",
    borderStyle: "solid",
    borderWidth: "1px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    fontFamily: "calibri",
    color: lineFontColor,
    position: "absolute",
    left: xLeft,
    top: ytop,
    height: height,
    width: width,
    zIndex: 20
  };
  return (
    <div style={textStyle} key={screenX}>
      <div style={{
        textAlign: "Center",
        fontFamily: "calibri",
        color: lineFontColor,
        marginLeft: '6px',
        marginRight: '6px',
      }}>
        {showText}
      </div>
    </div>
  );
}
function KpiInfoName(props) {
  return (
    <div>
      {props.showBox && props.kpiId === props.mouseOverId && showInfoBox(props.showText, props.xpos, props.ypos, props.colorInfo)}
    </div>
  )
}

const mapStateToProps = ({ kpiMenu }) => {
  const { mouseOverId, showBox, xpos, ypos } = kpiMenu.KpiModuleReducer;
  return ({ mouseOverId, showBox, xpos, ypos });
}
export default connect(mapStateToProps)(KpiInfoName)