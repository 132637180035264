import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetUserAboutModuleInfoRequest extends BaseServiceRequest {

    constructor() {
        super();
        super.addRequestUri("getaboutmoduleinfo.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.USER;
    }

    getMessageName() {
        return "UserAboutModuleInfoResponse";
    }
}

export default GetUserAboutModuleInfoRequest;