import Serializable from "Serializable";
export default class AccDistChartSetting extends Serializable {
    constructor() {
        super();
    }

    getDefaultSdLength() {
        return 80;
    }

    getDefaultSignalColor() {
        const SignalObj = [{ isActive: true, color: "PCCCCCC262626", weight: 2 }];
        return SignalObj.map((item) => this.getSerizableObject(item));
    }

    getDefaultMovingAverageColor() {
        const MovingAverageObj = [{ isActive: false, color: "PFF0000670000", weight: 1 }];
        return MovingAverageObj.map((item) => this.getSerizableObject(item));
    }

    getSerizableObject(obj) {
        return Object.assign(new Serializable, { ...obj });
    }

    getDefaultseqNumber(){
        return 1;
    }
    onSeralisedInfoRecieved(serializedValue) {
        this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
        this.Height = serializedValue.Height ? serializedValue.Height : 100;
        this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
        this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
        this.SdLength = serializedValue.SdLength ? serializedValue.SdLength : this.getDefaultSdLength();
        this.SignalColor = serializedValue.SignalColor ? serializedValue.SignalColor : this.getDefaultSignalColor();
        this.MovingAverageColor = serializedValue.MovingAverageColor ? serializedValue.MovingAverageColor : this.getDefaultMovingAverageColor();
        this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : this.getDefaultseqNumber();
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            Height: this.Height,
            IsAvailable: this.IsAvailable,
            IsRelative: this.IsRelative,
            SdLength: this.SdLength,
            SignalColor: this.SignalColor,
            MovingAverageColor: this.MovingAverageColor,
            seqNumber : this.seqNumber
        }
    }

    getDefaultAccDistChartSetting() {
        this.IsVisible = true;
        this.Height = 100;
        this.IsAvailable = true;
        this.IsRelative = false;
        this.SdLength = this.getDefaultSdLength();
        this.SignalColor = this.getDefaultSignalColor();
        this.MovingAverageColor = this.getDefaultMovingAverageColor();
        this.seqNumber = this.getDefaultseqNumber();
        return this;
    }

    getObjectMapKey() {
        return "AccDistChartSetting";
    }
}
