import React from 'react';
function Ideas() {
    /* {!this.isIntraday &&
                            <div className="pivot-percent" id='pivot' key='4'>
                                <div className="pivot-percent-info" id="m-info">
                                    <div className="pivot-percent-text" style={{ color: textColor, fontSize: fontSize }} data-disable-track-price="true">{pivotDisp}</div>
                                </div>
                            </div>
                        }
                        {videoMode === false && tempIdeas.map((idea) => idea.IdeaShortText && idea.IdeaShortText.length > 0 &&
                            <div className={"btn btn-xs ideas-button btn-idea"}
                                key={idea.IdeaShortText}
                                onMouseDown={this.handleMouseDown.bind(this, idea.ideaType)}
                                onMouseOver={this.handleMouseOver.bind(this, idea.ideaType)}
                                onFocus={this.handleMouseOver.bind(this, idea.ideaType)}
                                onMouseLeave={this.handleMouseLeave}
                                data-disable-track-price="true">
                                <span className={idea.IdeaShortText ? idea.IdeaStyle + " idea-sign-flag" : "idea-sign-flag"}></span>
                                <span className="idea-text" style={{ color: ThemeHelper.getThemedBrush("summaryBlockHeaderText") }}>{idea.IdeaShortText}</span>
                                <span className="idea-figure" style={{ color: idea.ideaTextColor }}>{idea.IdeaInfoText}</span>
                                {idea.IdeaPDFPath && !PrintMode.printing && <span className="idea-pdf-btn"><a className="small-normal" href={this.getDownloadIdeaAnalysisReportWebRequest(idea.IdeaPDFPath)}>PDF</a></span>}
                                {idea.IdeaVideoPath && !PrintMode.printing && <span className="idea-video-btn"><a target="_blank" className="video-icon" href={idea.IdeaVideoPath}></a></span>}
                            </div>
                        )} */
    return(<></>);
}
export default Ideas;