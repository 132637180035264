import keyMirror from "keymirror";

const OwnershipConstants = {
    OwnershipTop: "A",
    OwnershipNew: "N",
    OwnershipInc: "I",
    OwnershipDec: "D",
    OwnershipUnchg: "E",
    OwnershipClosed: "C"
};

export default OwnershipConstants;

export const ListMenuItem_myList = [
    { header: 'Duplicate', key: 'DUPLICATE' },    
    { header: 'Rename', key: 'RENAME' },
    { header: 'Add to Favorites', key: 'ADDFAVORITE' },
    { header: 'Delete', key: 'DELETE' },
    { header: 'Export List', key: 'EXPORT' }      
      
  ];
  export const ListMenuItem_ownershipLoded_myList = [
    { header: 'Duplicate', key: 'DUPLICATE' },    
    { header: 'Rename', key: 'RENAME' },
    { header: 'Add to Favorites', key: 'ADDFAVORITE' },    
    { header: 'Export List', key: 'EXPORT' }      
      
  ];
  export const ListMenuItem_myList_MNodeSel = [   
    { header: 'Add to Favorites', key: 'ADDFAVORITE' },
    { header: 'Delete', key: 'DELETE' },
    { header: 'Export List', key: 'EXPORT' }      
      
  ];
  export const ListMenuItem_myList_Fav = [
    { header: 'Duplicate', key: 'DUPLICATE' },    
    { header: 'Rename', key: 'RENAME' },
    { header: 'Remove from Favorites', key: 'REMOVEFROMFAVORITE' },
    { header: 'Delete', key: 'DELETE' },
    { header: 'Export List', key: 'EXPORT' }      
  ];

  export const ListMenuItem_myList_Fav_MNodeSel = [    
    { header: 'Remove from Favorites', key: 'REMOVEFROMFAVORITE' },
    { header: 'Delete', key: 'DELETE' },
    { header: 'Export List', key: 'EXPORT' }      
  ];

  export const ListMenuItem_myList_Folder = [   
    { header: 'Rename', key: 'RENAME' },
    { header: 'Delete', key: 'DELETE' }      
  ];

  export const leftMenuItem_ownerList = [
    { header: 'Duplicate', key: 'DUPLICATE' },   
    { header: 'Add to Favorites', key: 'ADDFAVORITE' },
    { header: 'Export List', key: 'EXPORT' }      
  ];

  export const leftMenuItem_ownerList_MNodeSel = [       
    { header: 'Add to Favorites', key: 'ADDFAVORITE' },
    { header: 'Export List', key: 'EXPORT' }      
  ];

  export const leftMenuItem_ownerList_Fav = [
    { header: 'Duplicate', key: 'DUPLICATE' },   
    { header: 'Remove from Favorites', key: 'REMOVEFROMFAVORITE' },
    { header: 'Export List', key: 'EXPORT' }      
  ];

  export const leftMenuItem_ownerList_Fav_MNodeSel = [       
    { header: 'Remove from Favorites', key: 'REMOVEFROMFAVORITE' },
    { header: 'Export List', key: 'EXPORT' }      
  ];

  export const leftMenuItem_favorites = [
    { header: 'Show Orginal', key: 'SHOWORIGINAL' },
    { header: 'Duplicate', key: 'DUPLICATE' },    
    { header: 'Remove from Favorites', key: 'DELETEFAVORITE' },
    { header: 'Export List', key: 'EXPORT' }      
  ];

  export const ListMenuItem_notImportedList = [   
    { header: 'Remove', key: 'DELETE' },
    { header: 'Export', key: 'EXPORT' }        
  ];

export const OwnershipViewConstants = {
  ActionTypes: keyMirror({
    ISDELETE_ENABLE: null,
    SET_ACTIVE_NODE:null,
    GET_MORE_DATA_ON_SCROLL_OWNERSHIP: null,
    RELOAD_LIST_CATALOG_OWNERSHIP: null,
    GET_SEARCH_TEXT_OWNERSHIP: null,
    CLEAR_SEARCH_TEXT_OWNERSHIP: null,
    SELECT_COLUMN_SET_OWNERSHIP: null,
    GET_COLUMN_PICKLIST_OWNERSHIP: null,
    SCREEN_ON_OFF_OWNERSHIP: null,
    GET_SCREEN_OFF_LIST_CATALOG_OWNERSHIP: null,
    ADD_SYMBOLS_TO_LIST_OWNERSHIP: null,
    GET_REFRESHED_VIEW_OWNERSHIP: null,
    SAVE_SCREENED_RESULTS_OWNERSHIP: null,
    SET_ALERT: null,
    ADD_SYMBOLS_TO_LIST_BY_DRAG_OWNERSHIP: null,
    ON_DATA_LOADING_COMPLETE_OWNERSHIP: null,
    UPDATE_MINI_LIST_OWNERSHIP: null
  })
};

export const OwnerShipFetchDetails = {
  GET_OWNERSHIP_DATA: 'GET_OWNERSHIP_DATA',
  GET_OWNERSHIP_DRAG_NODE: 'GET_OWNERSHIP_DRAG_NODE',
  OWNERSHIP_DB_NAME: 'Ownership DB Results'

};
