import _ from 'lodash';
import AnnualData from './StockSummary/AnnualData.js';
import CEFData from "./CEFSummary/CEFData.js";
import CEFDetailItemData from "./CEFSummary/CEFDetailItemData.js";
import CEFWeeklyData from "./CEFSummary/CEFWeeklyData.js";
import ChartApi from '../../../../ServiceApi/Apis/ChartApi.js';
import ConsoleStore from "ConsoleStore";
import CurrencyHelper from "CurrencyHelper";
import { CustomTiDialogConstant } from "../../../../Constants/CustomTiDialogConstants";
import DatagraphDataType from '../../../../Constants/DatagraphDataType.js';
import DatagraphHelper from '../../../../Utils/DatagraphHelper.js';
import DatagraphStore from "../../../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import dateHelper from "../../../../Utils/TimeLineHelper/Datehelper.js";
import EstimateData from './StockSummary/EstimateData.js';
import FundDetailItemData from "./FundsSummary/FundDetailsItemData.js";
import GraphApi from "../../../../ServiceApi/Apis/GraphApi.js";
import GrowthRateData from './StockSummary/GrowthRateData.js';
import IdeaListData from "./FundsSummary/IdeaListData.js";
import IndustryWeeklyData from './IndustryGrpSummary/IndustryWeeklyData.js';
import MiscData from "./StockSummary/MiscData.js";
import PeriodicityHelper from "PeriodicityHelper";
import RatingData from "./StockSummary/RatingData.js";
import SettingsStore from "../../../../Stores/ConsoleWindow/Settings/SettingsStore.js";
import StockData from "./FundsSummary/StockData.js";
import StringUtil from "StringUtil";
import { SummaryBlockConstants } from '../../../../Constants/SummaryBlockConstants.js';
import SummaryBlockDescription from "./StockSummary/SummaryBlockDescription.js";
import SymbolType from "SymbolType";
import TimeTrackingWindow from '../../../../RayCustomControls/TimeTrackingWindow.js';
import TopRatingData from './IndustryGrpSummary/TopRatingData.js';
import TopSectorRatingData from "./IndustryGrpSummary/TopSectorRatingData.js";
import UserInfoUtil from "../../../../Utils/UserInfoUtil.js";
import WeeklyData from "./StockSummary/WeeklyData.js";
import { ORatingTranslateHelper, SummaryTransLateHelper, TranslateHelper } from "../../../../Utils/TranslateHelper";
import { takeLatest, call, put } from 'redux-saga/effects';

const {
    ActionTypes
} = SummaryBlockConstants;
let hasError = true;
let periodicity;
let summaryBlockSetting;
let headerData = {};

function* getSummaryBlockData({ settings, endDt }) {
    try {
        const symbol = settings.symbol ? settings.symbol : SettingsStore.getConsoleSettings().NavDatagraphSettings.symbol;
        periodicity = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings.Periodicity;
        const graphType = settings.graphType ? settings.graphType : PeriodicityHelper.getGraphTypeFromPeriodicity(periodicity);
        const endDate = endDt ? dateHelper.yyyymmdd(endDt) : endDt;
        const isRevWon = settings.NavDatagraphSettings ? settings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly :
            SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.RevWonOnly;
        const isWon = settings.NavDatagraphSettings ? settings.NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly :
            SettingsStore.getConsoleSettings().NavDatagraphSettings.PreferenceSettings.ReportedEarningsSettings.WonOnly;
        const isMatchFinancials = settings && settings.isMatchFinancials !== undefined ? settings.isMatchFinancials : SettingsStore.getConsoleSettings().isMatchFinancials;
        const { isCurrencyConversionChecked, selectedCurrencyCode } = settings;

        const findGetSnapShotRequest = {
            endDate: endDate,
            IsRevWonOnly: isRevWon,
            isWon: isWon,
            GraphType: graphType,
            Symbol: symbol,
            selectCurrencyCode: isCurrencyConversionChecked ? selectedCurrencyCode : null,
            isMatchFinancials: isMatchFinancials ? 1 : 0
        }

        TimeTrackingWindow.beginSummaryBlockTimeTracker();
        yield call(setLoadingState, true, false, true)
        yield call(getApiResponse, findGetSnapShotRequest)
    } catch (error) {
        TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.SummaryBlock);
        TimeTrackingWindow.endSummaryBlockApiTimeTracker();
        console.error(error);
    }
}

function* getApiResponse(findGetSnapShotRequest) {
    try {
        const response = yield call(ChartApi.GetESSummaryBlockData, findGetSnapShotRequest)
        const txt = response.isSummaryBlkSource ? 'ES' : 'DB';
        console.log(`%c\n*** Summary Block Data is pulled from ${txt} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');
        if (response.symbolInfoData) {
            TimeTrackingWindow.endSummaryBlockApiTimeTracker();
            yield call(plotSummary, response, response.symbolInfoData)
        } else {
            TimeTrackingWindow.endSummaryBlockApiTimeTracker();
            yield call(setLoadingState, true, false, true)
        }
    } catch (error) {
        TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.SummaryBlock);
        TimeTrackingWindow.endSummaryBlockApiTimeTracker();
        console.log(`%cError occures in SummaryBlock.js, getApiResponse ${error}`);
    }
}
function showSummaryBlock(info) {
    return (info &&
        info.SymTypeEnum &&
        (info.SymTypeEnum === SymbolType.USSTOCK ||
            info.SymTypeEnum === SymbolType.PREIPO ||
            info.SymTypeEnum === SymbolType.ADR ||
            info.SymTypeEnum === SymbolType.HISTORICSTOCK ||
            info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK ||
            info.SymTypeEnum === SymbolType.MODELSTOCK ||
            info.SymTypeEnum === SymbolType.ETF ||
            info.SymTypeEnum === SymbolType.REIT ||
            info.SymTypeEnum === SymbolType.FundClosedEnd ||
            info.SymTypeEnum === SymbolType.HISTORICALMODELSTOCK ||
            info.SymTypeEnum === SymbolType.WONMAJORINDUSTRY89 ||
            info.SymTypeEnum === SymbolType.WONINDUSTRYGROUP197 ||
            info.SymTypeEnum === SymbolType.INDEX ||
            info.SymTypeEnum === SymbolType.ECONOMICINDICATOR ||
            info.SymTypeEnum === SymbolType.INTERNATIONALINDEX ||
            info.SymTypeEnum === SymbolType.WONSECTOR11 ||
            info.SymTypeEnum === SymbolType.CURRENCY ||
            info.SymTypeEnum === SymbolType.FUND ||
            info.SymTypeEnum === SymbolType.WONSUBSECTOR33)
    );
}

function* plotSummary(data, symbolInfoData) {
    try {
        TimeTrackingWindow.beginSummaryBlockRenderTimeTracker();
        const consoleSettings = SettingsStore.getConsoleSettings();
        const symTypeEnum = data.symbolInfoData.SymTypeEnum
        consoleSettings.NavDatagraphSettings.symTypeEnum = symTypeEnum
        const datagraph = yield call(DatagraphHelper.getSettingsObject, consoleSettings, null, symTypeEnum);
        if (datagraph.SummaryBlockSettings) {
            const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
            summaryBlockSetting = datagraph.SummaryBlockSettings[majorPeriodicity];
            const videoMode = consoleSettings.NavDatagraphSettings.TabDataGraphSettings.videoMode;
            const IsSummaryBlockAvailable = videoMode === false && showSummaryBlock(symbolInfoData);
            TimeTrackingWindow.setIsSummaryBlockAvailable(IsSummaryBlockAvailable, symbolInfoData);
            TimeTrackingWindow.setIsSummaryBlockOpen(summaryBlockSetting.isOpen, symbolInfoData);
            yield put({
                type: ActionTypes.SET_SUMMARY_BLOCK_SETTINGS,
                summaryBlockSetting
            })
            yield call(prepareData, data, symbolInfoData);
        
            yield put({
                type: ActionTypes.SET_SNAPSHOT_DATA_READY,
                snapshotDataReady: true
            })
        } else {
            yield put({
                type: ActionTypes.CLOSE_SUMMARY_BLOCK
            })
        }
        TimeTrackingWindow.endSummaryBlockLoadEndTimeTracker();
    } catch (error) {
        TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.SummaryBlock);
        TimeTrackingWindow.endSummaryBlockLoadEndTimeTracker();
        console.log(`%cError occures in SummaryBlock.js, plotSummary ${error}`);
    }
}

function* prepareData(data, symbolInfo) {
    try {
        hasError = false;
        const { isHistoric } = ConsoleStore.getState();
        if (Object.keys(data).length === 0 || data === {} || data === null) {
            yield call(setLoadingState, true, true)
        } else {
            let currentSymbolData, annualdata, weeklyData, estimateData, growthData, ratingData, miscData, descriptionData,
                peggedData, countriesData, symbolContentData, currencyImages, fundDetailItems, ideaList, stockData,
                currencyCode, industryView = false, instrumentStyle = {};
            const pointerEvents = summaryBlockSetting.isOpen ? "all" : "none", zIndex = 1061;
            switch (symbolInfo.SymTypeEnum) {
                case SymbolType.USSTOCK:
                    data = data.StockSummaryData
                    data.StockInfo = symbolInfo;
                    data.StockInfo.CurrencyFormat = yield call(conversionCurrencyFormat, data.StockInfo);
                    headerData = yield call(getHeader, data.HeaderData, data);
                    annualdata = yield call(getAnnualData, data);
                    weeklyData = yield call(getWeeklyData, data);
                    estimateData = new EstimateData(data);
                    growthData = new GrowthRateData(data);
                    ratingData = new RatingData(data);
                    miscData = new MiscData(data);
                    instrumentStyle = {
                        pointerEvents,
                        zIndex: zIndex,
                        width: "790px"
                    }
                    currentSymbolData = {
                        headerData,
                        annualdata,
                        weeklyData,
                        estimateData,
                        growthData,
                        ratingData,
                        miscData,
                        symbolInfo,
                        instrumentStyle
                    }
                    break;
                case SymbolType.CURRENCY:
                    data = data.CurrencySummaryData
                    descriptionData = yield call(getDescriptionData, data);
                    peggedData = yield call(getPeggedData, data);
                    countriesData = yield call(getCountriesData, data);
                    symbolContentData = yield call(getSymbolContentData, data);
                    currencyImages = yield call(getCurrencyImage, symbolInfo.Osid);
                    instrumentStyle = {
                        pointerEvents,
                        zIndex: zIndex,
                        width: "780px"
                    }

                    currentSymbolData = {
                        descriptionData,
                        peggedData,
                        countriesData,
                        symbolContentData,
                        currencyImages,
                        symbolInfo,
                        instrumentStyle
                    }
                    break;
                case SymbolType.FUND:
                    data = data.FundsSummaryData;
                    currencyCode = DatagraphStore.getCurrencyCode(CurrencyHelper.get(symbolInfo.DefaultCurrency));
                    descriptionData = yield call(getDescriptionData, data);
                    fundDetailItems = new FundDetailItemData(data, currencyCode, summaryBlockSetting.isYtdAbsolute);
                    ideaList = new IdeaListData(data);
                    stockData = new StockData(data);
                    instrumentStyle = {
                        pointerEvents,
                        zIndex: zIndex,
                        width: "819px"
                    }

                    currentSymbolData = {
                        descriptionData,
                        fundDetailItems,
                        ideaList,
                        stockData,
                        symbolInfo,
                        instrumentStyle
                    }
                    break;

                case SymbolType.WONINDUSTRYGROUP197:
                case SymbolType.WONMAJORINDUSTRY89:
                case SymbolType.WONSECTOR11:
                case SymbolType.WONSUBSECTOR33:
                    if (isHistoric) {
                        yield call(setLoadingState, true, true)
                    } else {
                        data = data.INDGrpSummaryData ? data.INDGrpSummaryData : data.SectorsSummaryData;
                        industryView = true;
                        descriptionData = yield call(getDescriptionData, data);
                        if (symbolInfo.SymTypeEnum === SymbolType.WONINDUSTRYGROUP197) {
                            weeklyData = yield call(populateWeeklyItems, data, symbolInfo);
                            ratingData = yield call(populateTopRatingItems, data, symbolInfo);
                        } else if (symbolInfo.SymTypeEnum === SymbolType.WONSECTOR11 ||
                            symbolInfo.SymTypeEnum === SymbolType.WONSUBSECTOR33) {
                            ratingData = yield call(populateSectorTopRatingItems, data, symbolInfo);
                        }
                        if (!weeklyData && !ratingData && !descriptionData.isDescriptionAvailable && descriptionData.description === undefined) {
                            yield call(setLoadingState, true, true)
                        }
                        instrumentStyle = {
                            pointerEvents,
                            zIndex: zIndex,
                            width: "627px"
                        }

                        currentSymbolData = {
                            descriptionData,
                            weeklyData,
                            ratingData,
                            symbolInfo,
                            instrumentStyle,
                            industryView
                        }

                    }
                    break;
                case SymbolType.INDEXNOINTRADAYVOLDATA:
                case SymbolType.ECONOMICINDICATOR:
                case SymbolType.INDEXNOINTRADAYDATA:
                case SymbolType.INDEXNOINTRADAYVOL:
                case SymbolType.INTERNATIONALINDEX:
                case SymbolType.HISTORICINDEX:
                case SymbolType.INDEX:
                    data = data.IndexSummaryData;
                    descriptionData = yield call(getDescriptionData, data, false);
                    symbolContentData = yield call(getIndexSymbolContentData, data);
                    instrumentStyle = {
                        pointerEvents,
                        zIndex: zIndex,
                        width: "790px"
                    }
                    if (!symbolContentData.coverage && !symbolContentData.type && !symbolContentData.exchange && !descriptionData.isDescriptionAvailable &&
                        descriptionData.description === undefined) {
                        yield call(setLoadingState, true, true)
                    }

                    currentSymbolData = {
                        descriptionData,
                        symbolContentData,
                        symbolInfo,
                        instrumentStyle
                    }
                    break;
                case SymbolType.FundClosedEnd:
                case SymbolType.ETF:
                    if (isHistoric) {
                        yield call(setLoadingState, true, true)
                    } else {
                        data = data.ETFSummaryData;
                        currencyCode = DatagraphStore.getCurrencyCode(CurrencyHelper.get(symbolInfo.DefaultCurrency));
                        fundDetailItems = new CEFDetailItemData(data, currencyCode, summaryBlockSetting);
                        descriptionData = yield call(getDescriptionData, data, true);
                        stockData = new CEFData(data);
                        ideaList = new IdeaListData(data);
                        weeklyData = yield call(populateCEFWeeklyItems, data.weekly, symbolInfo);
                        instrumentStyle = {
                            pointerEvents,
                            zIndex: zIndex,
                            width: "790px"
                        }

                        currentSymbolData = {
                            fundDetailItems,
                            descriptionData,
                            stockData,
                            ideaList,
                            weeklyData,
                            symbolInfo,
                            instrumentStyle
                        }

                    }
                    break;

                default:
                    data = data.StockSummaryData;
                    data.StockInfo = symbolInfo;
                    data.StockInfo.CurrencyFormat = yield call(conversionCurrencyFormat, data.StockInfo);
                    headerData = yield call(getHeader, data.HeaderData, data);
                    annualdata = yield call(getAnnualData, data);
                    weeklyData = yield call(getWeeklyData, data);
                    estimateData = new EstimateData(data);
                    growthData = new GrowthRateData(data);
                    ratingData = new RatingData(data);
                    miscData = new MiscData(data);
                    instrumentStyle = {
                        pointerEvents,
                        zIndex: zIndex,
                        width: "790px"
                    }

                    currentSymbolData = {
                        headerData,
                        annualdata,
                        weeklyData,
                        estimateData,
                        growthData,
                        ratingData,
                        miscData,
                        symbolInfo,
                        instrumentStyle
                    }
                    break;

            }
            yield put({
                type: ActionTypes.UPDATE_SYMBOL_DATA,
                currentSymbolData, data
            })
            if (!hasError) {
                yield call(setLoadingState, false)
            }
        }

    } catch (error) {
        console.log(`%cError occures in SummaryBlock.js, prepareData ${error}`);
    }
}

function* getAnnualData(data) {
    if (!data || !data.AnnualBlockData || data.AnnualBlockData.length < 1) {
        yield call(setLoadingState, true, true)
    }
    return new AnnualData(data.AnnualBlockData, data.AnnualHeaders, data.StockInfo, data.Fye);
}

function* getWeeklyData(data) {
    if (!data || !data.WeeklyBlockData || data.WeeklyBlockData.length < 1) {
        yield call(setLoadingState, true, true)
        return {
            rowSource: []
        };
    }
    return new WeeklyData(data.WeeklyBlockData, data.WeeklyHeaders, data.StockInfo);
}

function* getHeader(data, summaryData) {
    headerData.phone = data.PhoneNumber;
    headerData.webUrl = `http://${data.CompanyURL}`;
    if (data.CompanyURL) {
        headerData.webText = data.CompanyURL.substring(0, 4).toUpperCase() === "WWW." ? data.CompanyURL.substring(4, data.CompanyURL.length) : data.CompanyURL;
    }

    const comma = " ";
    const MGMT = `${SummaryTransLateHelper.MGMT_OWNS} `;
    let state = data.CompanyState ? data.CompanyState.replace(/,1$/g, "") : null;
    state = state ? state + comma : "";
    headerData.hqLocAndYrInc = (data.CompanyCity ? data.CompanyCity + comma : "") + state + (data.YearInc ? `${SummaryTransLateHelper.INC_SB} ${data.YearInc}` : "");
    if (data.State !== undefined) {
        if (data.State.indexOf(",1") > 0) {
            headerData.hqLocAndYrInc = state + (data.CompanyCity ? data.CompanyCity + comma : "") + (data.YearInc ? `${SummaryTransLateHelper.INC_SB} ${data.YearInc}` : "");
        }
    }


    headerData.bankr = data.InvBanker ? (`Inv Banker ${data.InvBanker.split(" ").map((item) => item.charAt(0).toUpperCase() + item.slice(1)).join(" ").trim()}`) : "";
    headerData.mgmOwned = StringUtil.isEmpty(data.MgmOwned) ? `${MGMT}--` : (data.MgmOwned === 0 ? (`${MGMT + 0}%`) : (`${MGMT + data.MgmOwned}%`));
    headerData.epsRnk = data.EPSRank ? `${ORatingTranslateHelper[CustomTiDialogConstant.EpsRank]} ${data.EPSRank}` : " ";
    headerData.daysVolShort = data.DaysVolShort ? `${SummaryTransLateHelper.DAYS_VOL_SHORT} ${data.DaysVolShort}` : "";
    headerData.options = data.Options && (data.Options.toUpperCase() === "YES") ? SummaryTransLateHelper.SB_OPTIONS : "";
    headerData.corporatePresentationURL = summaryData.CorporatePresentationURL;

    headerData.summaryBlockDesc = new SummaryBlockDescription(UserInfoUtil.hasChinaEntitlement() ? data.Story1 : data.formattedDesc);
    headerData.showMore = summaryBlockSetting.showMoreStory
    headerData.prvName = data.prvName;

    headerData.xDivDate = yield call(getXDivDate, data, summaryData.AnnualMiscBlockData);
    return headerData;
}

function getXDivDate(data, annualMiscBlockData) {
    let annualFiscalDate = data.AnnualBlockData && data.AnnualBlockData[0] && data.AnnualBlockData[0].ConversionDate ? dateHelper.getPSTFromLong(data.AnnualBlockData[0].ConversionDate) : null;
    if (!annualFiscalDate) {
        return "";
    }

    if (!annualMiscBlockData.XDate) {
        return "";
    }
    const XDate = annualMiscBlockData.XDate.getFullYear ? annualMiscBlockData.XDate : new Date(parseInt(annualMiscBlockData.XDate.substr(6)));
    annualFiscalDate = annualFiscalDate.getFullYear ? annualFiscalDate : new Date(parseInt(annualFiscalDate.substr(6)));
    if (XDate.getYear() < annualFiscalDate.getYear()) {
        return "";
    }
    return `XDIV ${StringUtil.formatDate(moment(XDate).format('M/D/YYYY'), "M/D/YYYY")}`;
}

function conversionCurrencyFormat(stockInfo) {
    const defaultCurrency = stockInfo.DefaultCurrency.Code;
    let format = {
        unit: defaultCurrency,
        ratio: 1
    };

    switch (defaultCurrency) {
        case 'GBP':
            format = {
                unit: 'GBX',
                ratio: 100
            };
            break;
        case 'ZAR':
            format = {
                unit: 'ZAX',
                ratio: 100
            };
            break;
        default:
            break;
    }
    return format;
}

/* for currency */
function getDescriptionData(data, isInstrumentETF = false) {
    let summaryBlockDesc
    if (isInstrumentETF) {
        summaryBlockDesc = new SummaryBlockDescription(data.weekly.description);
    } else {
        summaryBlockDesc = new SummaryBlockDescription(data.description);
    }
    const descriptionData = {};
    descriptionData.description = summaryBlockDesc.longDescription;
    descriptionData.isDescriptionAvailable = !StringUtil.isEmpty(descriptionData.description);
    return descriptionData;
}

function getPeggedData(data) {
    const peggedData = {};
    peggedData.headerText = SummaryTransLateHelper.PEGGED_CURRENCIES;
    peggedData.content = data.currenciesPegged;
    if (data.currenciesPegged === "None") {
        peggedData.content = SummaryTransLateHelper.SUMMARY_BLOCK_NONE_TEXT;
    }
    return peggedData;
}

function* getCountriesData(data) {
    const countriesData = {};
    countriesData.headerText = SummaryTransLateHelper.COUNTRIES_HAVING_CURRENCY;
    countriesData.content = yield call(removeNullCountries, data.usedByCountries);
    if (data.usedByCountries === "None") {
        countriesData.content = SummaryTransLateHelper.SUMMARY_BLOCK_NONE_TEXT;
    }
    return countriesData;
}

function removeNullCountries(countries) {
    let uniqueCountries = '';
    const countryArray = countries.split(',');
    countryArray.map((country) => {
        if (country) {
            uniqueCountries = uniqueCountries ? `${uniqueCountries},${country.trim()}` : `${country.trim()}`;
        }
        return uniqueCountries;
    });
    return uniqueCountries;
}

function getSymbolContentData(data) {
    const symbolContentData = {};
    symbolContentData.inverseSymbol = data.inverseSymbol;
    symbolContentData.nickNames = data.nickNames;
    symbolContentData.centralBank = data.centralBank;
    symbolContentData.centralBankWebSite = `http://${data.centralBankWebSite}`;
    symbolContentData.sign = data.sign;
    symbolContentData.currencySignSymbol = data.sign;
    symbolContentData.currencyName = data.currencyName;
    symbolContentData.inverseGlobalSymbol = data.inverseGlobalSymbol;
    symbolContentData.inverseCurrencyOSID = data.inverseCurrencyOSID;
    return symbolContentData;
}

function getCurrencyImage(osId) {
    const currencyImages = [];
    const imageStr1 = GraphApi.GetESDownloadCurrencyImageWebRequest(1, osId);
    currencyImages.push(imageStr1);
    const imageStr2 = GraphApi.GetESDownloadCurrencyImageWebRequest(2, osId);
    currencyImages.push(imageStr2);
    const imageStr3 = GraphApi.GetESDownloadCurrencyImageWebRequest(3, osId);
    currencyImages.push(imageStr3);
    return currencyImages;
}

/* for industry group */
function* populateTopRatingItems(data, symbolInfo) {

    if (!data || !data.rsRatingsData || data.rsRatingsData.length < 1 ||
        !data.topRatedRSSymbols || data.topRatedRSSymbols.length < 1) {
        yield call(setLoadingState, true, true)
        return {
            rowSource: []
        };
    }
    const topRSSymbols = _.uniqBy(data.rsRatingsData, (x) => x.symbol);
    const topSymbols = topRSSymbols.slice(0, 5)
    return new TopRatingData(topSymbols, data.rsRatingsData, data.topRatedRSSymbols, symbolInfo);
}

function populateWeeklyItems(data, symbolInfo) {
    if (!data || !data.weeklyData || data.weeklyData.length < 1) {
        return {
            rowSource: []
        };
    }
    const headers = [
        "RELATIVE STRENGTH",
        "GROUP RANK",
        "NEW HIGHS IN GROUP",
        "NEW LOWS IN GROUP",
    ];
    return new IndustryWeeklyData(data.weeklyData, headers, symbolInfo);
}

function populateSectorTopRatingItems(data, symbolInfo) {
    if (!data || !data.wonIndGroupRankData || data.wonIndGroupRankData.length < 1 ||
        !data.topRatedRSSymbols || data.topRatedRSSymbols.length < 1) {
        return {
            rowSource: []
        };
    }

    const topRSSymbols = _.uniqBy(data.wonIndGroupRankData, (x) => x.wonIndustryCode);

    return new TopSectorRatingData(topRSSymbols, data.wonIndGroupRankData, data.topRatedRSSymbols, symbolInfo);
}

/* for index */
function getIndexSymbolContentData(data) {
    const symbolContentData = {};
    symbolContentData.exchange = data.exchange;
    symbolContentData.exchangeLink = data.exchangeLink;
    symbolContentData.type = data.type;
    symbolContentData.symbol = data.symbol;
    symbolContentData.constituentsCount = data.constituentsCount;
    symbolContentData.coverage = data.coverage;
    symbolContentData.description = data.description;
    symbolContentData.oscidID = data.oscidID;
    return symbolContentData;
}

/* for CEF/FundClosedEnd */
function* populateCEFWeeklyItems(data, symbolInfo) {
    if (data === null || data.weekly === null || data.weekly.length < 1) {
        yield call(setLoadingState, true, true)
        return {
            rowSource: []
        };
    }
    const headers = [
        SummaryTransLateHelper.AV_DAILY_VOL,
        SummaryTransLateHelper.UP_DOWN_VOL,
        SummaryTransLateHelper.ACCUM_DIST_RATING,
        SummaryTransLateHelper.RELATIVE_STRENGTH
    ];
    return new CEFWeeklyData(data.weekly, headers, symbolInfo);
}

function* setLoadingState(state, dataError = false, loadingError = false) {
    try {
        hasError = state;
        let errorMsg;
        if (hasError) {
            if (dataError) {
                errorMsg = TranslateHelper.Data_Not_Available
            } else if (loadingError) {
                errorMsg = SummaryTransLateHelper.SUMMARY_BLOCK_LOADING
            }
            yield put({
                type: ActionTypes.SET_SUMMARY_BLOCK_MSG,
                newState: {
                    hasError: true,
                    error: errorMsg
                }
            })
        } else {
            dataError = false, loadingError = false
            yield put({
                type: ActionTypes.SET_SUMMARY_BLOCK_MSG,
                newState: {
                    hasError: false
                }
            })
        }
    } catch (error) {
        console.log(`%cError occures in SummaryBlock.js, setLoadingState ${error}`);
    }

}

export function* watcherOne() {
    yield takeLatest(ActionTypes.FETCH_SUMMARY_BLOCK_DATA, getSummaryBlockData)
}