import StringUtil from "StringUtil";
import _ from 'underscore';
import ListExplorerStore from "ListExplorerStore";
import ListManagerTabType from "ListManagerTabType";
import SettingsStore from "SettingsStore";

class ListExplorerUtil {

    // get Scroll position by nodeid in list Explorer/Manager
    getScrollPosition(nodeId) {          
        const settings = SettingsStore.getConsoleSettings();
        const navListManagerSettings = settings.NavListManagerSettings; 
        if(navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
        let explorerView = document.getElementsByClassName("listExplorerClass")[0];  
        let query = "div[data-node-id='" + nodeId + "']";
        let elm = undefined;
        if(explorerView){
             elm = explorerView.querySelector(query);
        }
        if (!StringUtil.isEmpty(elm)) this.setScrollPosition(elm);
        }else{
            let elm = this.getElementByQuery("div[data-node-id='" + nodeId + "']");
            if (!StringUtil.isEmpty(elm)) this.setScrollPosition(elm);
        }
    }
    getScrollPositionDe(nodeId) {        
        let explorerView = document.getElementsByClassName("listExplorerClass")[0];  
        let query = "div[data-node-id='" + nodeId + "']";
        let elm = undefined;
        if(explorerView){
             elm = explorerView.querySelector(query);
        }
        if (!StringUtil.isEmpty(elm)) this.setScrollPositionDeDuplicate(elm);
    }
    setScrollPositionDeDuplicate(selectedNodeElm, scrollPosition = null) {
        let scrollPositionVal = null;
        let listExplorereScrollBar = document.getElementById("dataExternalVerticalScroll");

        if (!StringUtil.isEmpty(selectedNodeElm)) {
             scrollPositionVal = parseInt(selectedNodeElm.offsetTop) - 200;
           
        }
        else if (!StringUtil.isEmpty(scrollPosition)) {
            scrollPositionVal = scrollPosition;
        }
        window.setTimeout(() => { listExplorereScrollBar.scrollTop = scrollPositionVal; }, 0);
    }
    // get Scroll position by nodeid in list Explorer/Manager
    setScrollPositionBySymbol(symbol) {
        let elm = this.getElementByQuery("div[data-symbol='" + symbol + "']");
        if (!StringUtil.isEmpty(elm)) this.setScrollPosition(elm);
    }

    // Set Scroll position by element in list Explorer/Manager
    setScrollPosition(selectedNodeElm, scrollPosition = null) {        
        let scrollPositionVal = null;
        const settings = SettingsStore.getConsoleSettings();
        const navListManagerSettings = settings.NavListManagerSettings; 
        let listExplorereScrollBar = document.getElementById("explorerVerticalScroll");
        if(navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership)
        listExplorereScrollBar = document.getElementById("ownershipListScroll");
        if (!StringUtil.isEmpty(selectedNodeElm)) {
            scrollPositionVal = parseInt(selectedNodeElm.offsetTop) - 200;
        }
        else if (!StringUtil.isEmpty(scrollPosition)) {
            scrollPositionVal = scrollPosition;
        }

        window.setTimeout(() => { listExplorereScrollBar.scrollTop = scrollPositionVal; }, 0);
    }
    setScrollPositionDe(selectedNodeElm, scrollPosition = null) {
        let scrollPositionVal = null;
        let listExplorereScrollBar = document.getElementById("dataExternalVerticalScroll");

        if (!StringUtil.isEmpty(selectedNodeElm)) {            
            scrollPositionVal = parseInt(selectedNodeElm.offsetTop) - 200;
        }
        else if (!StringUtil.isEmpty(scrollPosition)) {
            scrollPositionVal = scrollPosition;
        }

        if(!StringUtil.isEmpty(listExplorereScrollBar)){
            window.setTimeout(() => { listExplorereScrollBar.scrollTop = scrollPositionVal; }, 0);
        }  
    }
    // Get DOM element by query within List Explorer Class
    getElementByQuery(query) {
        if (StringUtil.isEmpty(ListExplorerStore.explorerView) && StringUtil.isEmpty(query)) return;
        let listExplorer = ListExplorerStore.explorerView;
        return listExplorer.querySelector(query);
    }

    // Get List explorer resizable window width to set input box width while adding/renaming List/Folder.
     getListExplorerWindowWidth(isRenameNode = false, nodeId = null, isFolder = false, listTreeItemLevel = 0) {
        let listExplorerElm = document.getElementById("listExplorer");
        let resizeElement = listExplorerElm.getElementsByClassName('list-explorer-resize');
        let width = null;
        let parentElm = null;

        if (isRenameNode) {
            if (isFolder) {
                parentElm = this.getparentElement(listExplorerElm, nodeId);
            }
            else {
                let userListCategoryElm = listExplorerElm.getElementsByClassName('userList');
                if (!StringUtil.isEmpty(userListCategoryElm))
                    parentElm = _.find(userListCategoryElm, (item) => { if (item.dataset.nodeid == nodeId) return item; });
            }
        }
        else {
            parentElm = this.getparentElement(listExplorerElm, nodeId);
        }

        if (!StringUtil.isEmpty(parentElm)) {
            if (isRenameNode)
                width = (listTreeItemLevel > 0) ? parentElm.offsetLeft + 50 : 80;
            else
                width = (listTreeItemLevel > 0) ? parentElm.offsetLeft + 82 : 80;
        }
        else
            width = 80;

        return resizeElement[0].clientWidth - width + 'px';
    }

    getparentElement(listExplorerElm, nodeId) {
        let elm = null;
        let parentElm = null;
        let parenteElementList = listExplorerElm.querySelector("div[id='" + nodeId + "']");

        elm = parenteElementList.getElementsByClassName('icn-folder-view-open');
        if (StringUtil.isEmpty(elm))
            elm = parenteElementList.getElementsByClassName('icn-folder-view-openfa icn-folder-view');

        if (!StringUtil.isEmpty(elm)) {
            if (elm.length > 0)
                parentElm = elm[0];
        }
        return parentElm;
    }

}
const listExplorerUtil = new ListExplorerUtil();
export default listExplorerUtil; 