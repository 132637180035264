import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ThemeHelper from "ThemeHelper";
import FundamentalTableCssType from 'Constants/FundamentalTableCssType.js';
import FundamentalTabSortOrderType from 'Constants/FundamentalTabSortOrderType.js';
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js';
import FundamentalTabPeriodicityType from 'Constants/FundamentalTabPeriodicityType.js';
import TabFundamentalsStore from 'Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js';
import FormatterUtil from "FormatterUtil";
import { isString } from "underscore";

export default class DataRow extends Component {
    constructor(props) {
        super(props);
        this.onMouseClick = this.onMouseClick.bind(this);
        this.state = {
            hover: false,
            width: props.width,
            rowIndex: props.rowIndex
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.width !== undefined) {
            this.setState({ width: nextProps.width });
        }
    }
    
    onMouseClick() {
        this.props.onMouseClick();
    }
    

    onHover(hover) {
        this.setState({ hover: hover });
    }

    syncHover(index, hover) {
        this.props.syncHover(index, hover);
    }

    getRowCellsClassName(cellIndex, index, data, report) {
        let className = "cell-table brdTop";
        if (report.cssType !== FundamentalTableCssType.PrimarytotalCssClass && report.cssType !== FundamentalTableCssType.TotalCssClass && report.cssType !== FundamentalTableCssType.SubtotalCssClass && report.cssType !== FundamentalTableCssType &&
            report.cssType !== FundamentalTableCssType.GrandtotalCssClass && report.cssType !== FundamentalTableCssType.CfnetCssClass && report.cssType !== FundamentalTableCssType.DoubleindentoverlineCssClass) {
            className = "cell-table";
        }
        if (report.cssType === FundamentalTableCssType.GrandtotalCssClass || report.cssType === FundamentalTableCssType.InitialtotalCssClass) {
            className += " brdBottom";
        }
        if (data.indexOf("(") >= 0) {
            className += " cell-table-negative";
        }
        if (report.cellIsNegative[cellIndex] === true) {
            className = `${className} cell-table-negative`;
        }
        if (report.bold === true || report.cssType === FundamentalTableCssType.PrimarytotalCssClass ||
            report.cssType === FundamentalTableCssType.GrandtotalCssClass || report.cssType === FundamentalTableCssType.InitialtotalCssClass ||
            report.cssType === FundamentalTableCssType.CfnetCssClass || report.cssType === FundamentalTableCssType.CfnetaltCssClass ||
            report.cssType === FundamentalTableCssType.Cfnetalt2CssClass || report.cssType === FundamentalTableCssType.TotalCssClass) {
            className += " medium-bold";
        }
        if (report.cssClass === "headerLevel") {
            className += " cell-table-section-header";
        }
        const isRightBorder =
            this.props.settings.FundamentalSortOrder === FundamentalTabSortOrderType.Ascending
                ? report.cellsRightLineVisibilityDesc[cellIndex]
                : report.cellsRightLineVisibilityDesc[cellIndex + 1];
        if (isRightBorder) {
            className += " cell-table-right-border";
        }
        return className;
    }

    getRowCellsClassNameForRatios(cellIndex, rowData, styleString) {
        let className = "cell-table";

        if (rowData[cellIndex] !== "") {
            if (rowData[cellIndex].charAt(0) === "-" && Number.isInteger(Number(rowData[cellIndex].charAt(1)))) {
                className = "cell-table-negative";
            }
        }
        if (styleString.includes(`,${cellIndex.toString()},`)) {
            className += " cell-table-right-border";
        }
        return className;
    }

    removeParanthesis(rowData) {
        let cellValue = rowData;
        if (cellValue.indexOf("(") > -1 && cellValue.indexOf(")") > 1) {
            const replacedValue = cellValue.replace("(", "-");
            cellValue = replacedValue.replace(")", "");
        }
        return cellValue;
    }

    setNumberFormatter(value) {
        if (value && !isNaN(parseFloat(value))) {
            value = value.replace(/,/g, "");
            if (value.includes("%")) {
                value = `${FormatterUtil.formatNumber(Number(parseFloat(value)), this.calculateNDigits(value))}%`;
            }
            else {
                value = FormatterUtil.formatNumber(Number(parseFloat(value)), this.calculateNDigits(value));
            }
        }
        const cellValue = FormatterUtil.isNegetiveZeroValue(value) ? value.replace("-", "") : value;
        return cellValue;
    }

    calculateNDigits(value) {
        let nDigit = 1;
        if (!isString(value)) {
            value = value.toString();
        }
        const values = value.split('.');

        if (values && values.length > 1) {
            if (values[1].length > 1 && (!values[1].includes(')')) && (!values[1].includes('%')))
                nDigit = values[1].length;
            else if (values[1].length > 2 && values[1].includes(')') && (!values[1].includes('%')))
                nDigit = values[1].length;
        }

        return nDigit;
    }

    isZero(val){
        if (val.includes("%") && Number(val.replace('%', '')) === 0) {
            return true;
        }
        else if (Number(val) === 0) {
            return true;
        }
        else {
            return false;
        }
    }

    render() {
        const report = this.props.report;
        const clsHover = this.state.hover ? "fundamental-left-hover" : " ";
        const index = this.state.rowIndex;
        const settings = this.props.settings;
        const fundamentalReportType = settings.FundamentalReportType;
        let styleint = 0;
        let styleString = ',';
        let headerData = TabFundamentalsStore.getNonAnnualHeaderData();
        if (fundamentalReportType === FundamentalTabReportType.Ratios) {
            if (settings.FundamentalPeriodicity !== FundamentalTabPeriodicityType.FundamentalsAnnual) {
                if (headerData && headerData.length > 0) {
                    for (let i = 0; i < headerData.length; i++) {
                        styleint = styleint + headerData[i].length - 1;
                        styleString = `${styleString}${styleint.toString()},`;
                    }
                }
            }
        }
        return (fundamentalReportType !== FundamentalTabReportType.Ratios ?
            <tr className={clsHover}
                onMouseOver={() => {this.syncHover(index, true)}}
                onFocus={() => {this.syncHover(index, true)}}
                onMouseLeave={() => {this.syncHover(index, false)}}
                onMouseDown={this.onMouseClick}>
                {report.cells.map((rowData, cellIndex) => {
                    let cellValue = this.removeParanthesis(rowData);
                    cellValue = this.setNumberFormatter(cellValue);
                    if (cellIndex === 0) return null;
                    let cellElem;
                    if (cellValue === "" || cellValue === "--") {
                        cellElem = <td className={this.getRowCellsClassName(cellIndex, index, cellValue, report)} key={cellIndex + 1}>&nbsp;</td>;
                    } else {
                        let tempStyle = {};
                        if (this.getRowCellsClassName(cellIndex, index, cellValue, report).indexOf("negative") !== -1) {
                            tempStyle = { color: ThemeHelper.getThemedBrush("negativeDataText") };
                        }
                        cellElem = <td className={this.getRowCellsClassName(cellIndex, index, cellValue, report)} style={tempStyle} key={cellIndex + 1}>{cellValue}</td>;
                    }
                    return (cellElem)
                })}
            </tr>
            :
            <tr key={index + 1} className={clsHover}
                onMouseOver={() => {this.syncHover(index, true)}}
                onFocus={() => {this.syncHover(index, true)}}
                onMouseLeave={() => {this.syncHover(index, false)}}
                onMouseDown={this.onMouseClick}>
                {report.map((data, cellIndex) => {
                    if (cellIndex === 0) return null;
                    let cellElem;
                    if (data === "") {
                        cellElem = <td className={this.getRowCellsClassNameForRatios(cellIndex, report, styleString)} key={cellIndex + 1}> &nbsp; </td>
                    }
                    else {
                        let tempStyle = {};
                        if (this.getRowCellsClassNameForRatios(cellIndex, report, styleString).indexOf("negative") !== -1) {
                            tempStyle = { color: ThemeHelper.getThemedBrush("negativeDataText") };
                        }
                        cellElem = <td className={this.getRowCellsClassNameForRatios(cellIndex, report, styleString)} style={tempStyle} key={cellIndex + 1}> {this.isZero(data) ? data.replace('-', '') : data}</td>;
                    }
                    return (cellElem)
                })}
            </tr>
        )
    }
}
DataRow.propTypes = {
    rowIndex: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    syncHover: PropTypes.func.isRequired,
};