import { connect } from 'react-redux';
import CurrencyList from '../../RayCustomControls/Currency/CurrencyList';
import { groupBy } from "underscore";
import { onPlotSymbol } from '../../Actions/DatagraphActions';
import ScrollBar from "ScrollBar";
import { setDefaultCurrencySign } from '../../Actions/CurrencyActions';
import SettingsActions from '../../Actions/SettingsActions';
import StringUtil from '../../Utils/StringUtil';
import React, { Component } from 'react'

class CurrencyContent extends Component {
    constructor(props) {
        super(props);
        this.handleOperatorChange = this.handleOperatorChange.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    handleOperatorChange(event) {
        const isMatchFin = JSON.parse(event.target.value);
        const { isDualCurrency, isCurrencyConversionChecked } = this.props.Currency;
        this.props.setDefaultCurrencySign(isDualCurrency, isMatchFin);
        if (!isCurrencyConversionChecked) {
            this.props.onPlotSymbol(this.props.Symbol, this.props.nodeCount);
        }   
    }

    componentDidMount() {
        const { selectedCurrencyCode } = this.props.Currency;
        const currencyListScrollBar = document.getElementById("CurrencyList-scroll");
        let scrollPositionVal = null;
        const selectcCurrencyElm = document.getElementsByClassName(`data-currency-${selectedCurrencyCode}`)[0];
        const currencyListElm = document.getElementsByClassName("CurrencyListBlock")[0];
        if (!StringUtil.isEmpty(selectcCurrencyElm) && !StringUtil.isEmpty(currencyListElm)) {
            const height = currencyListElm.getBoundingClientRect().height - selectcCurrencyElm.getBoundingClientRect().height;
            scrollPositionVal = parseInt(selectcCurrencyElm.offsetTop) - height;
        }
        window.setTimeout(() => { currencyListScrollBar.scrollTop = scrollPositionVal < 0 ? 0 : scrollPositionVal; }, 0);
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.setState({
            isSizeChange: false,
        });
    }

    render() {
        const { selectedCurrencyCode, currencyData, isMatchFinancials } = this.props.Currency;
        const sortedData = currencyData && currencyData.sort((a, b) => String(a.name).localeCompare(b.name));
        const groupingList = groupBy(sortedData, (x)=> x.firstCurrCharacter);
        let currencyListBlockHeight = 500;
        if(document.body.clientHeight < 937){
            currencyListBlockHeight = 350;
        }

        return (
            <div className="w100p" data-test-id="main-div">
                <div className="pull-left w100p" data-test-id="main-div" style={{ height: '50px' }}>
                    <span className="small-bold pull-left" >Match Financials to Chart Currency</span>
                    <span className="small-bold pull-right">
                        <input id="radio1"
                            type="radio"
                            name="radio-button"
                            onChange={this.handleOperatorChange}
                            data-report-templateid="1"
                            checked={isMatchFinancials}
                            value="true"
                        />
                        <label htmlFor="radio1" style={{ marginRight: '30px' }}>ON</label>
                        <input id="radio2"
                            type="radio"
                            name="radio-button"
                            onChange={this.handleOperatorChange}
                            data-report-templateid="2"
                            checked={!isMatchFinancials}
                            value="false"
                        />
                        <label htmlFor="radio2">OFF</label>
                    </span>
                </div>
                <div className="w100p text-left">
                    <span className="small-bold italic">Select a Preferred Currency: </span>
                </div>
                <div className="CurrencyListBlock custom-scroll-light"  style={{ height: currencyListBlockHeight }}>
                    <div className="custom-scroll">
                        <div id='CurrencyList-scroll'>
                            {
                                groupingList &&
                                Object.keys(groupingList).map((symbolrow, row) =>
                                    <CurrencyList key={row}
                                        currencyrow={groupingList[symbolrow]}
                                        symbolName={symbolrow}
                                        selectedCurrencyCode={selectedCurrencyCode}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <ScrollBar scrollId='CurrencyList-scroll' vScroll={true} hScroll={true} />
                </div>
            </div>
        );
    }
}

function mapStateToProps({ DatagraphReducers, currency }) {
    const { nodeCount, Symbol } = DatagraphReducers.DataGraphReducer;
    return { Currency: currency, nodeCount, Symbol }
}

const mapDispatchToProps = (dispatch) => ({
    setDefaultCurrencySign: (isDualCurrency, isMatchFinancials)=> dispatch(setDefaultCurrencySign(isDualCurrency, isMatchFinancials)),
    onPlotSymbol: (Symbol, nodeCount)=> dispatch(onPlotSymbol(Symbol, true, nodeCount))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyContent);
