import { TabDataGraphConstants, TimelineConstant } from "../Constants/TabDataGraphConstants.js";
import AppDispatcher from "AppDispatcher";
import { TabDataGraphKPIConstants } from "../Constants/TabDataGraphKpiConstants.js";
import { DataGraphConstants } from "../Constants/DataGraphConstants.js";
import { dispatch } from "../Redux/dispatch";
// import { getRiPanelData } from "./ExternalDataUploaderActions";
// import { periodicityChange } from './SummaryBlockActions.js'
// import { dgPeriodicityChange } from './DatagraphActions'

class TabDataGraphActionClass {
  resetStoreState(){
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.SET_DEFAULT_STATE,
      data: null
    });
  }

  onPeriodicityChange(periodicity, setting) {
    setting.NavDatagraphSettings.TabDataGraphSettings.Periodicity = periodicity;
    // AppDispatcher.handleViewAction({
    //   actionType: TabDataGraphConstants.ActionTypes.PERIODICITY_CHANGED,
    //   data: null
    // });
    // dispatch(getRiPanelData(setting));
    // dispatch(periodicityChange());
    // dispatch(dgPeriodicityChange());
  }
  hideShowGraph(index, isShowGraph, isEnableGraph = false) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.PAGINATED_GRAPH_VIEW,
      data: { index: index, isShowGraph: isShowGraph, isEnableGraph: isEnableGraph }
    });
  }
  onChartResize(resizeObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.CHART_RESIZE,
      data: resizeObj
    });
    dispatch({
      type: TabDataGraphConstants.ActionTypes.CHART_RESIZE
    })
  }
  onReloadChart(status) {
      AppDispatcher.handleServerAction({
          actionType: TabDataGraphConstants.ActionTypes.RELOAD_CHART,
          data: {status:status, nodeCount:0}
      });
  }
  onOHtypeChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OHTYPE_CHANGED,
      data: scaleObj
    });
  }
  onTimeLineChanged(timeLineObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.TIMELINE_CHANGED,
      data: timeLineObject
    });
  }
  onChartScaleChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.SCALE_CHANGED,
      data: scaleObj
    });
  }
  onLLRChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.LLR_CHANGED,
      data: scaleObj
    });
  }
  onTechChanged(blockType) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.TECH_CHANGED,
      blockType: blockType
    });
  }
  onKpiChanged() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphKPIConstants.ActionTypes.KPI_CHANGED,
    });
  }
  onMacdChartChanged() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.MACD_CHART_CHANGED
    });
  }
  onRsiChartChanged() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.RSI_CHART_CHANGED
    });
  }
  onStochasticsChartChanged() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.STOCHASTICS_CHART_CHANGED
    });
  }
  onYTDScaleChanged(frequency) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.YTD_SCALE_CHANGED,
      frequency: frequency
    });
  }
  onPTOEChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.PTOE_CHANGED,
      data: scaleObj
    });
  }
  onPTOSChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.PTOS_CHANGED,
      data: scaleObj
    });
  }
  onAccDistChanged() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ACC_DIST_CHANGED
    });
  }
  onVolumeScaleChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.VOLUME_SCALE_CHANGED,
      data: scaleObj
    });
  }
  onEPSRPSChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EPSRPS_CHANGED,
      data: scaleObj
    });
  }
  onComponentChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.COMPONENT_CHANGED,
      data: scaleObj
    });
  }
  onRecievedChartData(dataObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.CHARTDATA_RECIVED,
      data: dataObject
    });
  }
  onFinancialBlockPageChanged(page) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.FINANCIAL_PAGE_CHANGED,
      data: page
    });
  }
  onFinancialBlockSizeChanged(size) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.FINANCIAL_SIZE_CHANGED,
      data: size
    });
  }
  onOpenEPSlineDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_EPSLINE_DIALOG,
      data: dialogData
    });
  }
  onOpenRPSlineDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_RPSLINE_DIALOG,
      data: dialogData
    });
  }
  onOpenT4Q0lineDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_T4Q0LINE_DIALOG,
      data: dialogData
    });
  }
  onOpenT4Q1lineDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_T4Q1LINE_DIALOG,
      data: dialogData
    });
  }
  onOpenT4Q2lineDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_T4Q2LINE_DIALOG,
      data: dialogData
    });
  }
  onOpenT4Q3lineDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_T4Q3LINE_DIALOG,
      data: dialogData
    });
  }

  onSTPLineSettingsChanged(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_SPTLINE_SETTINGS,
      data: dialogData
    });
  }

  onSTPPriceTagSettingsChanged(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_SPTTAG_SETTINGS,
      data: dialogData
    });
  }

  onEPSLineSettingsChanged(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_EPSLINE_SETTINGS,
      data: dialogData
    });
  }
  onRPSLineSettingsChanged(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_RPSLINE_SETTINGS,
      data: dialogData
    });
  }
  onT4Q0LineSettingsChanged(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_T4Q0LINE_SETTINGS,
      data: dialogData
    });
  }
  onT4Q1LineSettingsChanged(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_T4Q1LINE_SETTINGS,
      data: dialogData
    });
  }
  onT4Q2LineSettingsChanged(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_T4Q2LINE_SETTINGS,
      data: dialogData
    });
  }
  onT4Q3LineSettingsChanged(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_T4Q3LINE_SETTINGS,
      data: dialogData
    });
  }
  onOpenEPSAnimationDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_EPS_ANIMATION_DIALOG,
      data: dialogData
    });
  }
  onEPSAnimationSettingsChanged(dataObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_EPS_ANIMATION_SETTINGS,
      data: dataObject
    });
  }
  onOpenIndexEPSAnimationDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_INDEX_EPS_ANIMATION_DIALOG,
      data: dialogData
    });
  }
  onIndexEPSAnimationSettingsChanged(dataObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_INDEX_EPS_ANIMATION_SETTINGS,
      data: dataObject
    });
  }
  onOpenCorpEventsDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_CORPEVENTS_DIALOG,
      data: dialogData
    });
  }
  onOpenETFCorpEventsDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_ETF_CORPEVENTS_DIALOG,
      data: dialogData
    });
  }
  onOpenCEFCorpEventsDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_CEF_CORPEVENTS_DIALOG,
      data: dialogData
    });
  }
  onOpenCurrencyCorpEventsDialog(dialogData) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_CURRENCY_CORPEVENTS_DIALOG,
      data: dialogData
    });
  }
  onCorpEventsSettingsChanged(dataObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_CORPEVENTS_SETTINGS,
      data: dataObject
    });
  }
  onCEFCorpEventsSettingsChanged(dataObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_CEF_CORPEVENTS_SETTINGS,
      data: dataObject
    });
  }
  onETFCorpEventsSettingsChanged(dataObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_ETF_CORPEVENTS_SETTINGS,
      data: dataObject
    });
  }
  onCurrencyCorpEventsSettingsChanged(dataObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_CURRENCY_CORPEVENTS_SETTINGS,
      data: dataObject
    });
  }
  onCryptoCurrencyCorpEventsSettingsChanged(dataObject) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_CRYPTO_CURRENCY_CORPEVENTS_SETTINGS,
      data: dataObject
    });
  }
  onMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onPatternRecognitionChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.PATTERN_RECOGNITION,
      data: scaleObj
    });
  }

  onCEFMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_CEF_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onIndexMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_INDEX_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onETFMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_ETF_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onIGMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_INDUSTRYGROUP_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onCurrencyMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_CURRENCY_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onCryptoCurrencyMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_CRYPTO_CURRENCY_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onFundsMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_FUNDS_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onStepBackInTime(isHoliday) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.STEP_BACK_IN_TIME,
      data: isHoliday
    });
  }

  onFullScreen(isFullScreen) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.FULL_SCREEN,
      data: isFullScreen
    });
  }

  onStepForwardInTime(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.STEP_FORWARD_IN_TIME,
      data: scaleObj
    });
  }

  ondayChange(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.DAY_CHANGE,
      data: scaleObj
    });
  }

  setMostRecentTradeDate() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.SET_MOST_RECENT_TRADE_DATE
    });
  }

  removeMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeCEFMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_CEF_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeIndexMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_INDEX_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeETFMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_ETF_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeCurrencyMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_CURRENCY_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeCryptoCurrencyMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_CRYPTO_CURRENCY_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeFundsMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_FUNDS_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeIndustryGroupMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_INDUSTRYGROUP_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateIndexLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_INDEX_LINE,
      data: scaleObj
    });
  }
  updateCEFIndexLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_CEF_INDEX_LINE,
      data: scaleObj
    });
  }
  updateIndexIndexLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_INDEX_INDEX_LINE,
      data: scaleObj
    });
  }
  updateETFIndexLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_ETF_INDEX_LINE,
      data: scaleObj
    });
  }
  updateFundsIndexLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_FUNDS_INDEX_LINE,
      data: scaleObj
    });
  }
  updateIndustryGroupIndexLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_INDUSTRYGROUP_INDEX_LINE,
      data: scaleObj
    });
  }
  updateIndustryGroupRsLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_INDUSTRYGROUP_RS_LINE,
      data: scaleObj
    });
  }
  updateRsLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_RS_LINE,
      data: scaleObj
    });
  }
  updateCEFRsLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_CEF_RS_LINE,
      data: scaleObj
    });
  }
  updateIndexRsLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_INDEX_RS_LINE,
      data: scaleObj
    });
  }
  updateETFRsLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_ETF_RS_LINE,
      data: scaleObj
    });
  }
  updateFundRsLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_FUND_RS_LINE,
      data: scaleObj
    });
  }
  updateIndustryRsLine(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_INDUSTRYGROUP_RS_LINE,
      data: scaleObj
    });
  }
  updatePatternRecognition(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_PATTERN_RECOGNITION,
      data: scaleObj
    });
  }
  updateETFPatternRecognition(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_ETF_PATTERN_RECOGNITION,
      data: scaleObj
    });
  }
  updateCEFPatternRecognition(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_CEF_PATTERN_RECOGNITION,
      data: scaleObj
    });
  }

  updateCEFMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_CEF_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateIndexMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_INDEX_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateETFMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_ETF_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateIGMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_INDUSTRYGROUP_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateCurrencyMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_CURRENCY_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateCryptoCurrencyMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_CRYPTO_CURRENCY_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateFundsMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_FUNDS_MOVING_AVERAGE,
      data: scaleObj
    });
  }
  movingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onCEFMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.CEF_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onIndexMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.INDEX_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onETFMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ETF_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onIGMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.INDUSTRYGROUP_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onCurrencyMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.CURRENCY_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onCryptoCurrencyMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.CRYPTO_CURRENCY_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onFundsMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.FUNDS_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onCashSpotsMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.CASHSPOTS_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  onCollapseBlock(blockType, item = null, listId = null) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.COLLAPSE_BLOCK,
      blockType: blockType,
      item: item,
      listId: listId
    });
  }

  onOpenBlock(blockType, item = null) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_BLOCK,
      blockType: blockType,
      item: item
    });
  }
  onCollapseKpiBlock(item) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.COLLAPSE_KPI_BLOCK,
      data: item
    });
  }

  onOpenKpiBlock(item) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_KPI_BLOCK,
      data: item
    });
  }

  setIdeasStick(stick) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_STICK,
      data: stick
    });
  }

  getRIPanelFactorInfo (eventKey) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.GET_RI_FACTOR,
      data: eventKey
    })
  }

  onDisplayModeChanged(mode) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.DISPLAY_MODE_CHANGED,
      data: mode
    });
  }

  onShowEpsPercentLoss() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.SHOW_EPSPERC_LOS,
      data: null
    });
  }

  onEpsRpsScaleChanged(eString) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EPS_RPS_SCALECHANGED,
      data: eString
    });
  }

  onEpsRpsSwitch(eString) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EPS_RPS_SWITCHED,
      data: eString
    });
  }

  onEpsRpsSelected() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EPS_RPS_SELECTED,
      data: null
    });
  }

  onEpsIndexRpsScaleChanged(eString) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EPS_INDEX_RPS_SCALECHANGED,
      data: eString
    });
  }

  onEpsIndexRpsSwitch(eString) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EPS_INDEX_RPS_SWICHED,
      data: eString
    });
  }

  onEpsIndexRpsSelected() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EPS_INDEX_RPS_SELECTED,
      data: null
    });
  }

  onMAAlertHover(maObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.HIGHLIGHT_MA_LINE,
      data: maObj
    });
  }

  onLineVisualSelected() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.LINE_VISUAL_SELECTED,
      data: null
    });
  }

  processJpeg() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.PROCESS_JPEG,
      data: null
    });
  }
  onCashSpotsMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.CASHSPOTS_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }
  onCashSpotsMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_CASHSPOTS_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateCashSpotsMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_CASHSPOTS_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeCashSpotsMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_CASHSPOTS_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onFuturesMovingAverageChanged(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ADD_FUTURES_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  removeFuturesMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.REMOVE_FUTURES_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  updateFuturesMovingAverage(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EDIT_FUTURES_MOVING_AVERAGE,
      data: scaleObj
    });
  }

  onFuturesMovingAverageAlert(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.FUTURES_MOVING_AVERAGE_ALERT,
      data: scaleObj
    });
  }

  updateVSSDialogChange(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_VOLUME_SCALE_SETTINGS,
      data: scaleObj
    });
  }

  onAnimationChartLoad() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ON_ANIMATION_CHART_LOAD,
      data: null
    });
  }

  onEpsScrubberMove(epsSnapshot) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ON_EPS_SCRUBBER_MOVE,
      data: epsSnapshot
    });
  }

  updateEpsLineComponent(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_EPS_LINE,
      data: scaleObj
    });
  }

  updateIndexEpsLineComponent(scaleObj) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.UPDATE_INDEX_EPS_LINE,
      data: scaleObj
    });
  }

  onIndexEpsScrubberMove(epsSnapshot) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.ON_INDEX_EPS_SCRUBBER_MOVE,
      data: epsSnapshot
    });
  }

  epsAnimationHighLightNode(highLight, index) {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.EPSANIMATION_HIGHLIGHT_NODE,
      data: { highLight: highLight, index: index }
    });
  }

  onOpenFinancialBlockDialog() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.OPEN_FINANCIALBLOCK_DIALOG,
      data: null
    });
  }
  hideOtherMenu() {
    AppDispatcher.handleViewAction({
      actionType: TabDataGraphConstants.ActionTypes.HIDE_OTHER_MENU
    });
  }
}

const tabDataGraphActionClass = new TabDataGraphActionClass();
export default tabDataGraphActionClass;

export const isGICSSelected = (data) => {
  AppDispatcher.handleViewAction({
    actionType: TabDataGraphConstants.ActionTypes.GICSSELECTED,
    data: data
  });
}

export const MetricActionAlert = (data, definationSymbol = "") => {
  AppDispatcher.handleViewAction({
    actionType: TabDataGraphConstants.ActionTypes.SET_METRIC_ACTION_ALRET,
    data: { data: data, definationSymbol: definationSymbol }
  });
}

export const ChangesGICSStatus = (data) => {
  AppDispatcher.handleViewAction({
    actionType: TabDataGraphConstants.ActionTypes.CHANGE_ISGICS_STATUS,
    data: data
  });
}

export const ChangeMetricActionStatus = (data) => {
  AppDispatcher.handleViewAction({
    actionType: TabDataGraphConstants.ActionTypes.CHANGE_METRIC_ACTION_STATUS,
    data: data
  });
}


const { ActionTypes } = TimelineConstant;

export const createTimeline = (startDate, endDate, periodicity, fCQtrEstCount, symbolInfo, isMountainChart, isComparisionChart = false, videoMode = false) => ({
  type: ActionTypes.CREATE_TIMELINE,
  startDate, endDate, periodicity, fCQtrEstCount, symbolInfo, isMountainChart, isComparisionChart, videoMode
})

export const initTimeLineState = (timeLine, TimeLineState) => ({
  type: ActionTypes.PRICE_PANEL_RESPONSE_READY_TIMELINE,
  timeLine, TimeLineState
})

export const initTimeLine = (endDate, nodeCount, InitialBufferSize, isMountainChart, isComparisionChart, isInitial = true) => ({
  type: ActionTypes.INIT_TIMELINE,
  endDate, nodeCount, InitialBufferSize, isMountainChart, isComparisionChart, isInitial
})

export const getFactorData = (symbol, periodicity, endDate) =>({
  type: DataGraphConstants.ActionTypes.INIT_FACTOR_API,
  symbol, periodicity, endDate
});