import GraphType from "GraphType";
import Serializable from "Serializable";
import UserInfoUtil from 'UserInfoUtil';
import RatingFilterType from "RatingFilterType";
import panarayDefault from "../../../../../ServiceApi/RequestHelper/PanarayDefault";
import SymbolType from "../../../../../Constants/SymbolType";
import { invert } from "underscore";

const symbolTypeEnumValues = invert(SymbolType);
class ComparisonSymbolsMenuSettings extends Serializable {
    constructor() {
        super();
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.msids = serializedValue.msids && serializedValue.msids.length > 0 ? serializedValue.msids : this.getDefaultMsids();
        this.scaleBased = serializedValue.scaleBased !== undefined ? serializedValue.scaleBased : this.getDefaultScaleBased();
        this.userEndDate = serializedValue.userEndDate ? serializedValue.userEndDate : this.getDefaultUserEndDate();
        this.userStartDate = serializedValue.userStartDate ? serializedValue.userStartDate : this.getDefaultUserStartDate();
        this.scaleType = serializedValue.scaleType ? serializedValue.scaleType : this.getDefaultScaleType();
        this.periodicity = serializedValue.periodicity ? serializedValue.periodicity : this.getDefaultPeriodicity();
        this.symbolRequest = serializedValue.symbolRequest && serializedValue.symbolRequest.length > 0 ? serializedValue.symbolRequest : this.getDefaultSymbolRequestForSymbolsMenu();
        this.indexRequest = serializedValue.indexRequest ? serializedValue.indexRequest : this.getDefaultIndexRequest();
        this.sortType = serializedValue.sortType ? serializedValue.sortType : this.getDefaultSortType();
        this.liqFilter = serializedValue.liqFilter ? serializedValue.liqFilter : this.getDefaultLiqFilterStates();
        this.listName = serializedValue.listName ? serializedValue.listName : null;
    }

    getDataObject() {
        return {
            msids: this.msids,
            scaleBased: this.scaleBased,
            userEndDate: this.userEndDate,
            userStartDate: this.userStartDate,
            scaleType: this.scaleType,
            periodicity: this.periodicity,
            symbolRequest: this.symbolRequest,
            indexRequest: this.indexRequest,
            sortType: this.sortType,
            liqFilter: this.liqFilter,
            listName: this.listName
        };
    }

    getObjectMapKey() {
        return "ComparisonSymbolsMenuSettings";
    }

    getDefaultComparisonSymbolsMenuSettings() {
        this.msids = this.getDefaultMsids();
        this.scaleBased = this.getDefaultScaleBased();
        this.userEndDate = this.getDefaultUserEndDate();
        this.userStartDate = this.getDefaultUserStartDate();
        this.scaleType = this.getDefaultScaleType();
        this.periodicity = this.getDefaultPeriodicity();
        this.symbolRequest = this.getDefaultSymbolRequestForSymbolsMenu();
        this.indexRequest = this.getDefaultIndexRequest();
        this.sortType = this.getDefaultSortType();
        this.liqFilter = this.getDefaultLiqFilterStates();
        this.listName = null;
        return this;
    }

    getDefaultMsids() {
        return [panarayDefault.Msid, panarayDefault.IndexMsid];
    }

    getDefaultScaleBased() {
        return 100;
    }

    getDefaultUserEndDate() {
        return "";
    }

    getDefaultUserStartDate() {
        return "";
    }

    getDefaultScaleType() {
        return "Lin";
    }

    getDefaultPeriodicity() {
        return GraphType.Daily;
    }

    getDefaultSymbolRequestForSymbolsMenu() {
        const sIndex = UserInfoUtil.hasChinaEntitlement() ? 2 : 0;
        const symbolsData = [{
            Osid: panarayDefault.Osid,
            SymTypeEnum: symbolTypeEnumValues[panarayDefault.SymbolTypeEnum],
            msid: panarayDefault.Msid,
            index: sIndex,
            isVisible: true, 
            isHighlighted: true
        },{
            Osid: panarayDefault.IndexOsid,
            SymTypeEnum: symbolTypeEnumValues[panarayDefault.IndexSymbolTypeEnum],
            msid: panarayDefault.IndexMsid,
            index: sIndex + 1,
            isVisible: true, 
            isHighlighted: true
        }]

        const symbolRequest = symbolsData.map((symbol) => this.getSerizableObject(symbol));
        return symbolRequest;
    }

    getSerizableObject(obj) {
        return Object.assign(new Serializable, {...obj});
      }

    getDefaultIndexRequest() {
        return [];
    }

    getDefaultSortType() {
        return "dsc";
    }

    getDefaultLiqFilterStates() {
        let liqFilter = new Serializable();
        liqFilter.MktCapLowSliderRangeValueIndex = -1;
        liqFilter.MktCapHighSliderRangeValueIndex = -1;

        liqFilter.AvolFilterLowSliderRangeValueIndex = -1;
        liqFilter.AvolFilterHighSliderRangeValueIndex = -1;


        liqFilter.RatingFilter1LowSliderRangeValueIndex = -1;
        liqFilter.RatingFilter1HighSliderRangeValueIndex = -1;

        liqFilter.RatingFilter2LowSliderRangeValueIndex = -1;
        liqFilter.RatingFilter2HighSliderRangeValueIndex = -1;

        liqFilter.RatingFilterType2 = RatingFilterType.EPS;

        liqFilter.RatingFilterType1 = RatingFilterType.Datagraph;

        liqFilter.IsGlobalRatingSelected = true;
        liqFilter.IsCountryRatingSelected = false;
        liqFilter.IsFilterOn = true;
        liqFilter.IsFilterManualOff = true;
        return liqFilter;
    }
}

export default ComparisonSymbolsMenuSettings;
