import React, { PureComponent } from 'react';
import ExternalGrid from "../../../../RayCustomControls/Grid/ExternalGrid";
import ErrorBoundary from "ErrorBoundary";
export default class ExternalDataGridView extends PureComponent {
    constructor(props) {
        super(props)
    }
    render() {
        return (<div className="external-data-uploader-content" ref={(ref) => this.main = ref}>
                    <ErrorBoundary>
                        <ExternalGrid
                            itemsCount={this.props.itemsCount}
                            fontSizeClass={this.props.fontSizeClass}
                            loading={this.props.loading}
                            itemHeight={this.props.itemHeight}
                        />
                    </ErrorBoundary>
                </div>);
            }
        }
