import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

export default class GetListColumnDistributionRequest extends BaseServiceRequest {
    constructor(basicInfo, columnId) {
        super();
        super.addRequestUri("esdist.pb");
        super.setContent(basicInfo.encode());
        super.addPathParameter(basicInfo.actualListId.toString());
        super.addRequestParameter("cid", columnId.toString());
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListColumnDistributionResponse";
    }
}