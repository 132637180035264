import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class ExportListRequest extends BaseServiceRequest {
  constructor(basicInfoObj,exportType,exportAll,selectedMsids,currentDataBase,hasGicsEntitlement,lang,isCryptoCurrencyList) {
    super();
    super.addRequestUri("exportlist.pb");
    super.addPathParameter(basicInfoObj.listId.toString());
    super.addRequestParameter("type",exportType.toString());
    super.addRequestParameter("isall",exportAll);
    super.addRequestParameter("msids",selectedMsids);
    super.addRequestParameter("db",currentDataBase);
    super.addRequestParameter("gicsent",hasGicsEntitlement);
    super.addRequestParameter("lang",lang.toString())
    super.addRequestParameter("iscclist",isCryptoCurrencyList)

    this.methodType = HttpMethodType.POST;
    super.setContent(basicInfoObj.encode());
  }

  getMethod() {
    return this.methodType;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "blob";
  }
}

export default ExportListRequest;