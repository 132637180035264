import BaseServiceApi from "../../../../ServiceApi/BaseServiceApi";
import { connect } from 'react-redux';
import { FormGroup } from "react-bootstrap";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import React from "react";
import ReactDOM from "react-dom";
import ResizableWindow from "RayCustomControls/ResizableWindow/ResizableWindow.jsx";
import ScrollBar from "ScrollBar";
import ThemeHelper from "ThemeHelper";
import VirtualList from "VirtualList";
import { find, map } from 'underscore';
import { onCheckListResize, setChecklistActiveItems, getChecklistData, onThresholdCheckboxChanged } from '../../../../Actions/RelatedInfoPanelActions/RiPanelActions';
import { RiPanelCheckListTransLateHelper, TranslateHelper } from "../../../../Utils/TranslateHelper";

const ListType = BaseServiceApi.rayData["ListType"];

let screenName = LocalizationStore.getTranslatedData("ri_id71", "SELECT A SCREEN");
class RiPanelCheckListInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.toggleDetailDailog = this.toggleDetailDailog.bind(this);
        this.onColumnSetSelected = this.onColumnSetSelected.bind(this);
        this.onColumnSetFolderChange = this.onColumnSetFolderChange.bind(this);
        this.onCheckBoxChanged = this.onCheckBoxChanged.bind(this);
        this.setCellTitle = this.setCellTitle.bind(this);
        this.clearCellTitle = this.clearCellTitle.bind(this);

    }
    componentDidMount(){
        this.setState({})
    }

    setCellTitle(e, displayValue) {
        const el = ReactDOM.findDOMNode(e.target);
        if (el.offsetWidth < el.scrollWidth && !el.hasAttribute('title')) {
            this.showTooltip(e, displayValue);
        }
    }

    showTooltip(e, displayValue) {
        this.toolTip.style.display = 'block';
        this.toolTip.style.position = 'absolute';
        this.toolTip.style.wordBreak = 'break-word';
        this.toolTip.innerHTML = displayValue;

        const height = this.toolTip.offsetParent.clientHeight;
        if ((this.toolTip.offsetHeight + e.clientY - 30) > height) {
            this.toolTip.style.top = `${e.clientY - this.toolTip.offsetHeight - 80}px`;
        } else {
            this.toolTip.style.top = `${e.clientY - 30}px`;
        }
        this.toolTip.style.left = '2px';
    }

    clearCellTitle() {
        this.toolTip.innerHTML = '';
        this.toolTip.style.display = 'none';
        this.toolTip.style.top = '0px';
        this.toolTip.style.left = '0px';
    }

    onCheckBoxChanged(e) {
        this.props.onThresholdCheckboxChanged(e.currentTarget.checked);
        this.props.getChecklistData(this.props.activeColSetId, this.props.activeSourceColSetId);
    }

    onColumnSetSelected(item) {
        const colSetId = item.nodeId.low, sourceColSetId = item.sourceColumnSetID;
        this.props.getChecklistData(colSetId, sourceColSetId);
    }

    onColumnSetFolderChange(item) {
        const colSetId = item.nodeId.low;
        const columnSetDetails = find(this.props.listItems, (item) =>
            parseInt(colSetId) === parseInt(item.nodeId.low)
        );

        const screenName = LocalizationStore.getTranslatedData(`ColumnSet_${columnSetDetails.name}`, columnSetDetails.name.toUpperCase()); // screen name is in block letters
        this.props.setChecklistActiveItems(true, screenName, columnSetDetails.childNodes);
    }

    toggleDetailDailog() {
        this.props.setChecklistActiveItems(false, screenName, this.props.listItems);
    }

    getMetricView(screenMetric) {
        return (
            <div className="list-details" style={{ color: "#808080" }}>
                {map(screenMetric.filterList, (filter, key) =>
                    <div className=" small-normal pull-left w100p" key={key}>
                        <span className="pull-left idea-name">{filter.filterDO.operator + filter.filterDO.filterValue}</span>
                    </div>
                )
                }
            </div>
        )
    }

    getPickListView(screenMetric) {
        return (
            <div className="list-details" style={{ color: "#808080" }}>
                {(screenMetric.pickListOperatorVisibility ? <span className="pull-left idea-name">{screenMetric.pickListOperator}</span> : <div />)}
                {map(screenMetric.filterList, (filter, key) =>
                    <div className=" small-normal pull-left w100p" key={key}>{(filter.filterDO.pickListTagVisibility ? <span className="pull-left idea-name">-</span> : '')}{filter.filterDO.filterValue}</div>
                )
                }
            </div>
        )
    }

    getItemRendererForScreenMetric(screenMetricList) {
        const yellowDataText = ThemeHelper.getThemedBrush("fff333");
        return (
            <div className="checklist-col-info-block">
                {
                    map(screenMetricList, (item, key) => {
                        const fontColor = item.screenMetric.isNegFontColor ? this.props.negDTColor : yellowDataText;
                        return (
                            <div key={key} className="col-info-block-list">
                                <div className="list-header">
                                    <span className="list-header-title medium-normal pull-left text-left"
                                        onMouseMove={(e) => this.setCellTitle(e, item.screenMetric.metricName)}
                                        onMouseOut={this.clearCellTitle}
                                        onBlur={this.clearCellTitle}
                                    >
                                        {item.screenMetric && item.screenMetric.metricName ? item.screenMetric.metricName : ''}
                                    </span>
                                    <span className="list-header-title medium-normal pull-right text-right"
                                        onMouseMove={(e) => this.setCellTitle(e, item.screenMetric.symbolValue)}
                                        onMouseOut={this.clearCellTitle}
                                        onBlur={this.clearCellTitle}
                                        style={{ color: fontColor }}>
                                        {
                                            (item.screenMetric && item.screenMetric.symbolValue) || item.screenMetric.symbolValue !== '0'
                                                ? item.screenMetric.symbolValue
                                                : '0'
                                        }
                                    </span>
                                </div>
                                {item.screenMetric.isPickList ?
                                    this.getPickListView(item.screenMetric) :
                                    this.getMetricView(item.screenMetric)}
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    getDetailView(detailsHeight) {
        const screenMetricList = this.props.columnSetInfo.screenMetricList;
        if (screenMetricList.length > 0) {
            return (
                <div className="setcol-checklist" style={{ width: '291px' }}>{this.getItemRendererForScreenMetric(screenMetricList)}</div>
            );
        }
        else {
            let noFilterHeight = "120px";
            if (detailsHeight >= 20) { noFilterHeight = `${detailsHeight - 5}px`; }
            return (<div className="news-message large-normal" style={{ height: noFilterHeight }}><p>{RiPanelCheckListTransLateHelper.NO_FILTER}</p></div>);
        }
    }

    getDetailsHeight() {
        let finalHeight = 0;
        let detailHeaderHeight = 0;
        let thresholdHeight = 0;

        const checklistInfo = document.getElementsByClassName("inner-infoContainer checkList-block");

        if (checklistInfo && checklistInfo[0]) {
            const totalHeight = checklistInfo[0].clientHeight;

            const detailHeaderElm = document.getElementById("checklistDetailHeader");
            if (detailHeaderElm) { detailHeaderHeight = detailHeaderElm.offsetHeight; }

            const isThresholdElm = document.getElementById("riCheckListIsThreshold");
            if (isThresholdElm) { thresholdHeight = isThresholdElm.offsetHeight; }

            finalHeight = totalHeight - this.props.checkListHeight - detailHeaderHeight - thresholdHeight;
        }

        return finalHeight;
    }

    getItemRenderer(item, top, index) {
        if (item) {
            let displayName = LocalizationStore.getTranslatedData(`ColumnSet_${item.name}`, item.name);
            //Folder
            if (item.listType === ListType.FOLDER_List) {
                return (
                    <div className="checklist-item  medium-normal" key={index} style={{ top: top }}>
                        <span className="icn-folder-view"></span>
                        <div className="checklist-item-column column-1"
                            onMouseMove={(e) => this.setCellTitle(e, displayName)}
                            onMouseOut={this.clearCellTitle}
                            onBlur={this.clearCellTitle}
                            data-node-id={item.nodeId.low}
                            onClick={() => this.onColumnSetFolderChange(item)}>
                            {`${displayName} (${item.childNodes.length})`}
                        </div>
                    </div>
                )
            }
            else {
                let colSetClasss = "checklist-item  medium-normal";
                let marginLeft = '0px'
                let paddingLeft = '10px'
                if (item.parentNodeId === null) {
                    marginLeft = '0';
                    paddingLeft = '35px'
                }

                if (item.nodeId.low === this.props.activeColSetId) {
                    colSetClasss += " active";
                }
                if (item?.parentNodeId && parseInt(item.parentNodeId) === 2 && item.ownerName) {
                    displayName = `${displayName} (${item.ownerName})`
                }

                return (
                    <div className={colSetClasss} key={index} style={{ top: top, marginLeft: marginLeft, paddingLeft: paddingLeft }}>
                        <div className="checklist-item-column"
                            data-node-id={item.nodeId.low} data-itemname={item.name}
                            data-source-columnsetid={item.sourceColumnSetID}
                            onMouseMove={(e) => this.setCellTitle(e, displayName)}
                            onMouseOut={this.clearCellTitle}
                            onBlur={this.clearCellTitle}
                            onClick={() => this.onColumnSetSelected(item)}>
                            {displayName}
                        </div>
                    </div>
                )
            }
        }
    }

    render() {
        const { checkListHeight, onResize, isShowDetailDailog, activeScreen, activeListItems, columnSetInfo, loading, hideWithoutThreshold } = this.props;

        const detailsHeight = this.getDetailsHeight();
        const detailView = this.getDetailView(detailsHeight);
        const checkDetailHeader = LocalizationStore.getTranslatedData(`ColumnSet_${columnSetInfo.columnName}`, columnSetInfo.columnName);

        return (
            <div>
                <ResizableWindow className="resize-metric-library-detail" minHeight={150} maxHeight={330} width={300} height={checkListHeight} direction="Vertical" onStartResize={onResize}>
                    <div className="checklistInfo" style={{ height: checkListHeight }}>
                        <div className="small-bold checklist-head-main">
                            {(isShowDetailDailog) ?
                                <div className="checklist-head-inner">
                                    <span className="flag-area">{activeScreen}</span>
                                    <span className="icn-back-btn" id="backButton" data-effect="fadeOut" onClick={this.toggleDetailDailog}>{TranslateHelper["Back"]}</span>
                                </div>
                                :
                                <span className="flag-area">{activeScreen}</span>
                            }
                        </div>
                        {
                            (activeListItems.length > 0) ?
                                <div className="checklist-content">
                                    <VirtualList id="RIPanelChecklistInfo" ref={(ref) => { this.CheckListSets = ref }}
                                        items={activeListItems}
                                        itemHeight={32}
                                        itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                                        showScrollOnHover={true}
                                    />
                                </div>
                                :
                                isShowDetailDailog ?
                                    <span className="flag-area title" style={{ marginTop: 50, marginLeft: 30 }}>{RiPanelCheckListTransLateHelper.CHECKLIST_NO_SCREEN}</span>
                                    :
                                    <div />
                        }
                    </div>

                    <div className="list-matric-resize"><span className="icon-handal-h"></span></div>

                    <div className="">
                        <div id='checklistDetailHeader' className="btn-collap small-normal">
                            <div className="checklistInfo-main-contant" style={{ height: "53px" }}>
                                <div className="checklist-block-tab">
                                    <div className=" checklist-block-tab-inner "
                                    onMouseMove={(e) => this.setCellTitle(e, checkDetailHeader)}
                                    onMouseOut={this.clearCellTitle}
                                    onBlur={this.clearCellTitle}
                                    >
                                        {RiPanelCheckListTransLateHelper.CHECKLIST_SCORE}
                                        {checkDetailHeader}
                                    </div>
                                    <div className="checklist-block-tab-inner  ">{columnSetInfo.totalFilter}</div>
                                </div>
                                <div className="checklist-block-persentage">
                                    <div className=" pull-right">{columnSetInfo.totalScore}</div>
                                </div>
                            </div>
                        </div>

                        {loading ?
                            <div className="news-message large-normal" style={{ height: detailsHeight }}><p>{TranslateHelper["Loading"]}</p></div>
                            :
                            <div className="holdingsInfo virtualList showscrollOnHover">
                                <div className="custom-scroll" style={{ height: detailsHeight }}>
                                    <div id='RICheckListDetailsVerticalScroll' className="main-col-details">
                                        {detailView}
                                    </div>
                                    <ScrollBar scrollId="RICheckListDetailsVerticalScroll" vScroll={true} scrollOnHover={true} />
                                </div>
                            </div>
                        }
                    </div>
                </ResizableWindow>

                <div className="idealistInfo checklistBottomBlock">
                    <div id="riCheckListIsThreshold" className="checkbox-area" ref={(ref) => { this.checkboxPanel = ref }}
                        style={{ position: "absolute", bottom: "0", left: "0" }}>
                        <FormGroup>
                            <input className="cbShowHistorical" type="checkbox" id="hideMetricsThresholds" onChange={this.onCheckBoxChanged} checked={hideWithoutThreshold} />
                            <label className="spShowHistorical" htmlFor="hideMetricsThresholds">{RiPanelCheckListTransLateHelper.HIDE_METRICS_WITHOUT_THRESHOLED}</label>
                        </FormGroup>
                    </div>
                </div>
                <div id="checklist-tooltip" ref={(r) => { this.toolTip = r }}></div>
            </div>
        );
    }
}

const mapStateToProps = ({ RelatedInfoPanelReducers, appColor }) => {
    const { checkListHeight, isShowDetailDailog, activeScreen, loading, listItems, activeListItems, activeColSetId, activeSourceColSetId, hideWithoutThreshold, columnSetInfo } = RelatedInfoPanelReducers.RiPanelCheckListInfo;
    const { isThemeChanged, negDTColor } = appColor;

    return { isThemeChanged, negDTColor, checkListHeight, isShowDetailDailog, activeScreen, loading, listItems, activeListItems, activeColSetId, activeSourceColSetId, hideWithoutThreshold, columnSetInfo };
};

const mapDispatchToProps = (dispatch) => ({
    onResize: (height) => dispatch(onCheckListResize(height)),
    setChecklistActiveItems: (isShowDetailDailog, screenName, activeListItems) => dispatch(setChecklistActiveItems(isShowDetailDailog, screenName, activeListItems)),
    getChecklistData: (colSetId, sourceColSetId) => dispatch(getChecklistData(colSetId, sourceColSetId)),
    onThresholdCheckboxChanged: (isHide) => dispatch(onThresholdCheckboxChanged(isHide))
});

export default connect(mapStateToProps, mapDispatchToProps)(RiPanelCheckListInfo);