import AumData from "./AumData.jsx";
import BlockTitleOwnership from "./BlockTitleOwnership.jsx";
import BlockType from "../../../../../Constants/BlockType";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import ErrorBoundary from "ErrorBoundary";
import HeaderCell from "./HeaderCell.jsx";
import HeaderCell2 from "./HeaderCell2.jsx";
import ListExplorerStore from "ListExplorerStore";
import ListStore from "ListStore";
import NavType from "NavType";
import OwnershipBlockMenuType from "../../../../../Constants/OwnershipBlockMenuType.js";
import { setActiveNodeToOwnershipStore } from '../../../../../Actions/OwnerShipActions';
import SettingsAction from "SettingsAction";
import SettingsStore from "SettingsStore";
import TabularData from "./TabularData.jsx";
import TimeLine from "../TimeLine/TimeLine.jsx";
import { TranslateHelper } from "../../../../../Utils/TranslateHelper";
import { expandAndFindParentNode, getParentId, sortDataSource } from "../../../../../Utils/OwnerShipUtil.js";
import { openCollapseOwnerShip, onOwnershipAumToggle, onOwnershipBlockToggleView, onOwnerShipListChanged, onOwnerShipSizeChanged } from "../../../../../Actions/OwnerShipBlockActions";
import React, { useCallback, useRef } from 'react';

const history = require("history").createHashHistory();
const blockSizeChangeObj = { 1: 'ownership-small', 2: 'ownership-medium', 3: 'ownership-large' }

const OwnerShipBlock = (props) => {

  const { isThemeChanged, dataAvailable, Height, showDataNotAvailable, HeaderList2, rightScaleWidth, nodeWidth,
    SponsorRanks3, AUMData2, BlockSize, AumIsVisible } = props;

  const tabularDataTable = useRef();
  const _headerCell = new Map();
  const _headerCell3 = new Map();
  const _headerCell2 = new Map();

  const onMenuItemSelect = useCallback((item, isEditButton = false) => {
    switch (item.Type) {
      case OwnershipBlockMenuType.Hide:
        props.openCollapseOwnerShip(BlockType.Ownership, false)
        break;
      case OwnershipBlockMenuType.DataType:
        props.onOwnershipBlockToggleView(item);
        break;
      case OwnershipBlockMenuType.PanarayOwnerLists:
      case OwnershipBlockMenuType.FavoriteOwnerLists:
        if (item.Type === OwnershipBlockMenuType.FavoriteOwnerLists && isEditButton) {
          SettingsAction.updateListExplorerTabSelection('OWNERSHIP');
          ListStore.updateSelectedListItem(item.sourceListId, item.sourceListId, item.header, null, item.parentId, null, null, null, null, null);
          ListExplorerStore.getListExplorerData();
          ListExplorerStore.getEligibleLists();
          //ConsoleStore.UpdateRout("", NavType.NavLists);          
          history.replace(`/${NavType.NavLists}`);
        }
        else {
          props.onOwnerShipListChanged(item);
        }

        break;
      case OwnershipBlockMenuType.CreateOwnerLists: {
        SettingsAction.updateListExplorerTabSelection('OWNERSHIP');
        const ownershipSettings = SettingsStore.getConsoleSettings().NavListManagerSettings;
        const dataSource = ListExplorerStore.ownershipDatasource;
        const newActiveNode = getParentId(ownershipSettings.TabOwnershipSettings.SelectedListInfo.SelectedListId, dataSource)
        // store.dispatch(setActiveNode(ownershipSettings.TabOwnershipSettings.SelectedListInfo.SelectedListId));
        expandAndFindParentNode(ownershipSettings.TabOwnershipSettings.SelectedListInfo.SelectedListId, sortDataSource(dataSource))
        setActiveNodeToOwnershipStore(newActiveNode);
        history.replace(`/${NavType.NavLists}`);
        ListExplorerStore.setCreateNewMyOwnerListStatus(true);
        //ConsoleStore.UpdateRout("", NavType.NavLists);        
        break;
      }
      case "BlockSize":
        props.onOwnerShipSizeChanged(item.Value);
        break;
      case "AUM":
        props.onOwnershipAumToggle();
        break;
      default:
        break;
    }
  }, [props])

  const syncHover = (index, hover) => {
    const header = _headerCell.get(index);
    const header2 = _headerCell2.get(index);
    const header3 = _headerCell3.get(index);
    tabularDataTable.current.onHover(index, hover);
    if (header) {
      header.onHover(hover);
    }
    if (header2) {
      header2.onHover(hover);
    }
    if (header3) {
      header3.onHover(hover);
    }
  }

  const maindivStyle = {
    height: `${(Height + 22)}px`,
    //,
    //paddingLeft: leftScaleWidth,
  };

  const togglerHeight = {
    width: `${(Height + 22)}px`,
    //,
    //backgroundSize:(Height + 22) + "px 16px"
  };

  const aumDivDispStyle = (AumIsVisible && (AumIsVisible === true)) ? "block" : "none";

  const blockCls = blockSizeChangeObj[BlockSize] || 'ownership-medium';

  let xpoint = 1;
  if (SponsorRanks3 && SponsorRanks3[0] && SponsorRanks3[0][0] && SponsorRanks3[0][0].XPoint) {
    xpoint = SponsorRanks3[0][0].XPoint;
  }
  const LeftColumnWidth = "220px";

  return (
    <div className="symbol-chart-stack symbol-ownership-stack" key="ownershipBlock" data-disable-track-price="true">
      <ErrorBoundary key='StockOwnershipBlock'>
        <div className={`symbol-chart-wrap ownership-block-grid-chart ${blockCls}`} style={maindivStyle}>
          <div className="ownership-stuff">
            <div className="ownership-block-headers">
              <TimeLine isOwnership={true} />

              <BlockTitleOwnership
                onClick={(item, isEditButton) => onMenuItemSelect(item, isEditButton)} />
              {dataAvailable && <div className="ownership-right-heading"></div>}
            </div>

            <div data-disable-track-price="true" className="ownership-block-data">
              <div id="HeaderDiv" className="ownership-block-left-panel header-block" style={{ width: LeftColumnWidth }}>
                <div className="ownership-block-statics">
                  {HeaderList2.length !== 0 && HeaderList2.map((header, index) => {
                    const headerRef = `header${index}`;
                    return <HeaderCell
                      syncHover={syncHover}
                      key={headerRef} index={index}
                      header={header}
                      ref={(c) => _headerCell.set(index, c)} />;
                  })
                  }
                  <div className="ownership-grids-adjuster"></div>
                </div>

                {dataAvailable &&
                  <div className="owners-toggler-state">
                    {
                      <div className="owners-toggler-region">
                        <div className="owners-toggler-box" style={{ display: aumDivDispStyle }}>
                          <div className="owners-toggler-row heads">
                            <span className="owners-toggler-cell">Aumu$B</span>
                            <span className="owners-toggler-cell">T/O%</span>
                            <span className="owners-toggler-cell">#stk</span>
                          </div>
                          {AUMData2.map((header, index) => {
                            const headerRef = `header${index}`;
                            return <AumData
                              syncHover={syncHover}
                              key={headerRef} index={index}
                              header={header}
                              ref={(c) => _headerCell3.set(index, c)} />;
                          })}
                          <div className="ownership-grids-adjuster"></div>
                        </div>
                        <a role="link" tabIndex="0" className="icn-loft right-side" onClick={() => props.onOwnershipAumToggle()} style={togglerHeight}></a>
                      </div>
                    }
                  </div>
                }
              </div>

              <div id="ownershipBlockData" className="ownership-block" style={{ height: Height }} onMouseDown={() => props.onOwnershipBlockToggleView()}>
                {SponsorRanks3.length !== 0 && <TabularData
                  syncHover={syncHover}
                  ref={tabularDataTable}
                />}
              </div>
              <div className="ownership-grids-adjuster2" style={{ width: `${xpoint * nodeWidth}px` }}></div>
              <div id="HeaderDiv2" className="ownership-block-right-panel" style={{ width: rightScaleWidth }}>
                {HeaderList2 && HeaderList2.map((header, index) => {
                  const headerRef = `header${index}`;
                  return <HeaderCell2
                    syncHover={syncHover}
                    key={headerRef} index={index}
                    header={header}
                    ref={(c) => _headerCell2.set(index, c)} />;
                })}
                <div className="ownership-grids-adjuster"></div>
              </div>
              {showDataNotAvailable &&
                <div className="data_not_available">
                  <span>{TranslateHelper.Data_Not_Available}</span>
                </div>
              }
            </div>

          </div>
          <Button bsSize="xsmall" onClick={() => props.openCollapseOwnerShip(BlockType.Ownership, false)} data-disable-track-price="true" className="collapse-block">
            <div className="collapse-btn"></div>
          </Button>
        </div>
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { LeftClient, dataAvailable, Height, showDataNotAvailable, RowHeight, RightClient, HeaderList2, rightScaleWidth,
    nodeWidth, SponsorRanks3, OwnershipViewType, AUMData2, BlockSize, AumIsVisible } = state.DatagraphReducers.ownershipBlockReducer;

  const isDogEarClicked = state.DatagraphReducers.ownershipBlockReducer.isDogEarClicked;

  const { isThemeChanged } = state.appColor

  return {
    isDogEarClicked, LeftClient, dataAvailable, Height, showDataNotAvailable, RowHeight, RightClient, HeaderList2, rightScaleWidth,
    nodeWidth, SponsorRanks3, OwnershipViewType, AUMData2, BlockSize, AumIsVisible, isThemeChanged
  }
}
const mapDispatchToProps = (dispatch) => ({
  openCollapseOwnerShip: () => dispatch(openCollapseOwnerShip(BlockType.Ownership, false)),
  onOwnershipBlockToggleView: (item) => dispatch(onOwnershipBlockToggleView(item)),
  onOwnerShipListChanged: (item) => dispatch(onOwnerShipListChanged(item)),
  onOwnerShipSizeChanged: (size) => dispatch(onOwnerShipSizeChanged(size)),
  onOwnershipAumToggle: () => dispatch(onOwnershipAumToggle())
})
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OwnerShipBlock);

