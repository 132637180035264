import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetSharedUsersRequest extends BaseServiceRequest {
    constructor(ntid, nids, ia) {
        super();
        super.addRequestUri("sharenoderecipients.pb");
        super.addRequestParameter("ntid", ntid);
        super.addRequestParameter("nids", nids);
        super.addRequestParameter("ia", ia.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.SHARE;
    }

    getMessageName() {
        return "SharedRecipientUserResponse";
    }
}


export default GetSharedUsersRequest;
