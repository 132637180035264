import Serializable from "Serializable";
import GraphType from "../../../../../Constants/GraphType.js";
class EpsRpsQuartersSettings extends Serializable {

  constructor(periodicity, isEPS = true, t4Q) {
    super();
    this.IsAvailable = true;
    this.IsVisible = t4Q < 0;
    this.NumOfQuarters = 4;
    this.lineColor = t4Q < 0
        ? (isEPS ? 'PFC6901FD8548' : 'P08980009CB01')
        : t4Q === 0
        ? 'P00CCCC009999'
        : t4Q === 1
        ? 'PFF0000670000'
        : t4Q === 2
        ? 'PFFFF00676700'
        : t4Q === 3
        ? 'P6600996734CC'
        : 'P08980009CB01';
    this.lineThickness = 1;
    switch (periodicity) {
      case GraphType.Daily:
      case GraphType.Weekly:
        if (isEPS) {
          this.showEarningSurprise = false;
        }
      case GraphType.Monthly:
      case GraphType.Quarterly:
      case GraphType.Annual:
        this.showHighLowEstimate = true;
      default:
        break;
    }
  }

  onSeralisedInfoRecieved(info) {
    Object.assign(this, info);
  }

  getDataObject() {
    return Object.assign({}, this);
  }

  getObjectMapKey() {
    return "EpsRpsQuartersSettings";
  }
}

export default EpsRpsQuartersSettings;
