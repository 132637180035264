import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

export default class GetLangResourceByCodeRequest extends BaseServiceRequest {
    constructor(langCode) {
        super();
        super.addRequestUri("getlangdict.pb");
        super.addRequestParameter("lcd", langCode);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LOCALIZATION;
    }

    getMessageName() {
        return "LangDictResponse";
    }
};
