import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GroupsMainDataRequest extends BaseServiceRequest {
    constructor() {
        super();
        super.addRequestUri("groupsmain.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GROUPS;
    }

    getMessageName() {
        return "GroupsMainData";
    }
}

export default GroupsMainDataRequest;
