class OlsonToWinTimeZone {
    getTimeZones() {
        let timeZones = []
        timeZones.push({Olson:"Etc/GMT+12", Win32TimeZone:"Dateline Standard Time"})
        timeZones.push({Olson:"Etc/GMT+11", Win32TimeZone:"UTC-11"})
        timeZones.push({Olson:"Pacific/Pago_Pago", Win32TimeZone:"UTC-11"})
        timeZones.push({Olson:"Pacific/Niue", Win32TimeZone:"UTC-11"})
        timeZones.push({Olson:"Pacific/Midway", Win32TimeZone:"UTC-11"})
        timeZones.push({Olson:"America/Adak", Win32TimeZone:"Aleutian Standard Time"})
        timeZones.push({Olson:"Pacific/Honolulu", Win32TimeZone:"Hawaiian Standard Time"})
        timeZones.push({Olson:"Pacific/Rarotonga", Win32TimeZone:"Hawaiian Standard Time"})
        timeZones.push({Olson:"Pacific/Tahiti", Win32TimeZone:"Hawaiian Standard Time"})
        timeZones.push({Olson:"Pacific/Johnston", Win32TimeZone:"Hawaiian Standard Time"})
        timeZones.push({Olson:"Etc/GMT+10", Win32TimeZone:"Hawaiian Standard Time"})
        timeZones.push({Olson:"Pacific/Marquesas", Win32TimeZone:"Marquesas Standard Time"})
        timeZones.push({Olson:"America/Anchorage", Win32TimeZone:"Alaskan Standard Time"})
        timeZones.push({Olson:"America/Anchorage America/Juneau America/Metlakatla America/Nome America/Sitka America/Yakutat", Win32TimeZone:"Alaskan Standard Time"})
        timeZones.push({Olson:"Etc/GMT+9", Win32TimeZone:"UTC-09"})
        timeZones.push({Olson:"Pacific/Gambier", Win32TimeZone:"UTC-09"})
        timeZones.push({Olson:"America/Tijuana", Win32TimeZone:"Pacific Standard Time (Mexico)"})
        timeZones.push({Olson:"America/Tijuana America/Santa_Isabel", Win32TimeZone:"Pacific Standard Time (Mexico)"})
        timeZones.push({Olson:"Etc/GMT+8", Win32TimeZone:"UTC-08"})
        timeZones.push({Olson:"Pacific/Pitcairn", Win32TimeZone:"UTC-08"})
        timeZones.push({Olson:"America/Los_Angeles", Win32TimeZone:"Pacific Standard Time"})
        timeZones.push({Olson:"America/Vancouver America/Dawson America/Whitehorse", Win32TimeZone:"Pacific Standard Time"})
        timeZones.push({Olson:"PST8PDT", Win32TimeZone:"Pacific Standard Time"})
        timeZones.push({Olson:"America/Phoenix", Win32TimeZone:"US Mountain Standard Time"})
        timeZones.push({Olson:"America/Dawson_Creek America/Creston America/Fort_Nelson", Win32TimeZone:"US Mountain Standard Time"})
        timeZones.push({Olson:"America/Hermosillo", Win32TimeZone:"US Mountain Standard Time"})
        timeZones.push({Olson:"Etc/GMT+7", Win32TimeZone:"US Mountain Standard Time"})
        timeZones.push({Olson:"America/Chihuahua", Win32TimeZone:"Mountain Standard Time (Mexico)"})
        timeZones.push({Olson:"America/Chihuahua America/Mazatlan", Win32TimeZone:"Mountain Standard Time (Mexico)"})
        timeZones.push({Olson:"America/Denver", Win32TimeZone:"Mountain Standard Time"})
        timeZones.push({Olson:"America/Edmonton America/Cambridge_Bay America/Inuvik America/Yellowknife", Win32TimeZone:"Mountain Standard Time"})
        timeZones.push({Olson:"America/Ojinaga", Win32TimeZone:"Mountain Standard Time"})
        timeZones.push({Olson:"America/Denver America/Boise", Win32TimeZone:"Mountain Standard Time"})
        timeZones.push({Olson:"MST7MDT", Win32TimeZone:"Mountain Standard Time"})
        timeZones.push({Olson:"America/Guatemala", Win32TimeZone:"Central America Standard Time"})
        timeZones.push({Olson:"America/Belize", Win32TimeZone:"Central America Standard Time"})
        timeZones.push({Olson:"America/Costa_Rica", Win32TimeZone:"Central America Standard Time"})
        timeZones.push({Olson:"Pacific/Galapagos", Win32TimeZone:"Central America Standard Time"})
        timeZones.push({Olson:"America/Tegucigalpa", Win32TimeZone:"Central America Standard Time"})
        timeZones.push({Olson:"America/Managua", Win32TimeZone:"Central America Standard Time"})
        timeZones.push({Olson:"America/El_Salvador", Win32TimeZone:"Central America Standard Time"})
        timeZones.push({Olson:"Etc/GMT+6", Win32TimeZone:"Central America Standard Time"})
        timeZones.push({Olson:"America/Chicago", Win32TimeZone:"Central Standard Time"})
        timeZones.push({Olson:"America/Winnipeg America/Rainy_River America/Rankin_Inlet America/Resolute", Win32TimeZone:"Central Standard Time"})
        timeZones.push({Olson:"America/Matamoros", Win32TimeZone:"Central Standard Time"})
        timeZones.push({Olson:"America/Chicago America/Indiana/Knox America/Indiana/Tell_City America/Menominee America/North_Dakota/Beulah America/North_Dakota/Center America/North_Dakota/New_Salem", Win32TimeZone:"Central Standard Time"})
        timeZones.push({Olson:"CST6CDT", Win32TimeZone:"Central Standard Time"})
        timeZones.push({Olson:"Pacific/Easter", Win32TimeZone:"Easter Island Standard Time"})
        timeZones.push({Olson:"America/Mexico_City", Win32TimeZone:"Central Standard Time (Mexico)"})
        timeZones.push({Olson:"America/Mexico_City America/Bahia_Banderas America/Merida America/Monterrey", Win32TimeZone:"Central Standard Time (Mexico)"})
        timeZones.push({Olson:"America/Regina", Win32TimeZone:"Canada Central Standard Time"})
        timeZones.push({Olson:"America/Regina America/Swift_Current", Win32TimeZone:"Canada Central Standard Time"})
        timeZones.push({Olson:"America/Bogota", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"America/Rio_Branco America/Eirunepe", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"America/Coral_Harbour", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"America/Guayaquil", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"America/Jamaica", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"America/Cayman", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"America/Panama", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"America/Lima", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"Etc/GMT+5", Win32TimeZone:"SA Pacific Standard Time"})
        timeZones.push({Olson:"America/Cancun", Win32TimeZone:"Eastern Standard Time (Mexico)"})
        timeZones.push({Olson:"America/New_York", Win32TimeZone:"Eastern Standard Time"})
        timeZones.push({Olson:"America/Nassau", Win32TimeZone:"Eastern Standard Time"})
        timeZones.push({Olson:"America/Toronto America/Iqaluit America/Montreal America/Nipigon America/Pangnirtung America/Thunder_Bay", Win32TimeZone:"Eastern Standard Time"})
        timeZones.push({Olson:"America/New_York America/Detroit America/Indiana/Petersburg America/Indiana/Vincennes America/Indiana/Winamac America/Kentucky/Monticello America/Louisville", Win32TimeZone:"Eastern Standard Time"})
        timeZones.push({Olson:"EST5EDT", Win32TimeZone:"Eastern Standard Time"})
        timeZones.push({Olson:"America/Port-au-Prince", Win32TimeZone:"Haiti Standard Time"})
        timeZones.push({Olson:"America/Havana", Win32TimeZone:"Cuba Standard Time"})
        timeZones.push({Olson:"America/Indianapolis", Win32TimeZone:"US Eastern Standard Time"})
        timeZones.push({Olson:"America/Indianapolis America/Indiana/Marengo America/Indiana/Vevay", Win32TimeZone:"US Eastern Standard Time"})
        timeZones.push({Olson:"America/Asuncion", Win32TimeZone:"Paraguay Standard Time"})
        timeZones.push({Olson:"America/Halifax", Win32TimeZone:"Atlantic Standard Time"})
        timeZones.push({Olson:"Atlantic/Bermuda", Win32TimeZone:"Atlantic Standard Time"})
        timeZones.push({Olson:"America/Halifax America/Glace_Bay America/Goose_Bay America/Moncton", Win32TimeZone:"Atlantic Standard Time"})
        timeZones.push({Olson:"America/Thule", Win32TimeZone:"Atlantic Standard Time"})
        timeZones.push({Olson:"America/Caracas", Win32TimeZone:"Venezuela Standard Time"})
        timeZones.push({Olson:"America/Cuiaba", Win32TimeZone:"Central Brazilian Standard Time"})
        timeZones.push({Olson:"America/Cuiaba America/Campo_Grande", Win32TimeZone:"Central Brazilian Standard Time"})
        timeZones.push({Olson:"America/La_Paz", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Antigua", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Anguilla", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Aruba", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Barbados", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/St_Barthelemy", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Kralendijk", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Manaus America/Boa_Vista America/Porto_Velho", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Blanc-Sablon", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Curacao", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Dominica", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Santo_Domingo", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Grenada", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Guadeloupe", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Guyana", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/St_Kitts", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/St_Lucia", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Marigot", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Martinique", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Montserrat", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Puerto_Rico", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Lower_Princes", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Port_of_Spain", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/St_Vincent", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Tortola", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/St_Thomas", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"Etc/GMT+4", Win32TimeZone:"SA Western Standard Time"})
        timeZones.push({Olson:"America/Santiago", Win32TimeZone:"Pacific SA Standard Time"})
        timeZones.push({Olson:"America/Grand_Turk", Win32TimeZone:"Turks And Caicos Standard Time"})
        timeZones.push({Olson:"America/St_Johns", Win32TimeZone:"Newfoundland Standard Time"})
        timeZones.push({Olson:"America/Araguaina", Win32TimeZone:"Tocantins Standard Time"})
        timeZones.push({Olson:"America/Sao_Paulo", Win32TimeZone:"E. South America Standard Time"})
        timeZones.push({Olson:"America/Cayenne", Win32TimeZone:"SA Eastern Standard Time"})
        timeZones.push({Olson:"Antarctica/Rothera", Win32TimeZone:"SA Eastern Standard Time"})
        timeZones.push({Olson:"America/Fortaleza America/Belem America/Maceio America/Recife America/Santarem", Win32TimeZone:"SA Eastern Standard Time"})
        timeZones.push({Olson:"Atlantic/Stanley", Win32TimeZone:"SA Eastern Standard Time"})
        timeZones.push({Olson:"America/Paramaribo", Win32TimeZone:"SA Eastern Standard Time"})
        timeZones.push({Olson:"Etc/GMT+3", Win32TimeZone:"SA Eastern Standard Time"})
        timeZones.push({Olson:"America/Buenos_Aires", Win32TimeZone:"Argentina Standard Time"})
        timeZones.push({Olson:"America/Buenos_Aires America/Argentina/La_Rioja America/Argentina/Rio_Gallegos America/Argentina/Salta America/Argentina/San_Juan America/Argentina/San_Luis America/Argentina/Tucuman America/Argentina/Ushuaia America/Catamarca America/Cordoba America/Jujuy America/Mendoza", Win32TimeZone:"Argentina Standard Time"})
        timeZones.push({Olson:"America/Godthab", Win32TimeZone:"Greenland Standard Time"})
        timeZones.push({Olson:"America/Montevideo", Win32TimeZone:"Montevideo Standard Time"})
        timeZones.push({Olson:"America/Punta_Arenas", Win32TimeZone:"Magallanes Standard Time"})
        timeZones.push({Olson:"Antarctica/Palmer", Win32TimeZone:"Magallanes Standard Time"})
        timeZones.push({Olson:"America/Miquelon", Win32TimeZone:"Saint Pierre Standard Time"})
        timeZones.push({Olson:"America/Bahia", Win32TimeZone:"Bahia Standard Time"})
        timeZones.push({Olson:"Etc/GMT+2", Win32TimeZone:"UTC-02"})
        timeZones.push({Olson:"America/Noronha", Win32TimeZone:"UTC-02"})
        timeZones.push({Olson:"Atlantic/South_Georgia", Win32TimeZone:"UTC-02"})
        timeZones.push({Olson:"Atlantic/Azores", Win32TimeZone:"Azores Standard Time"})
        timeZones.push({Olson:"America/Scoresbysund", Win32TimeZone:"Azores Standard Time"})
        timeZones.push({Olson:"Atlantic/Cape_Verde", Win32TimeZone:"Cape Verde Standard Time"})
        timeZones.push({Olson:"Etc/GMT+1", Win32TimeZone:"Cape Verde Standard Time"})
        timeZones.push({Olson:"Etc/GMT", Win32TimeZone:"UTC"})
        timeZones.push({Olson:"America/Danmarkshavn", Win32TimeZone:"UTC"})
        timeZones.push({Olson:"Etc/GMT Etc/UTC", Win32TimeZone:"UTC"})
        timeZones.push({Olson:"Africa/Casablanca", Win32TimeZone:"Morocco Standard Time"})
        timeZones.push({Olson:"Africa/El_Aaiun", Win32TimeZone:"Morocco Standard Time"})
        timeZones.push({Olson:"Europe/London", Win32TimeZone:"GMT Standard Time"})
        timeZones.push({Olson:"Atlantic/Canary", Win32TimeZone:"GMT Standard Time"})
        timeZones.push({Olson:"Atlantic/Faeroe", Win32TimeZone:"GMT Standard Time"})
        timeZones.push({Olson:"Europe/Guernsey", Win32TimeZone:"GMT Standard Time"})
        timeZones.push({Olson:"Europe/Dublin", Win32TimeZone:"GMT Standard Time"})
        timeZones.push({Olson:"Europe/Isle_of_Man", Win32TimeZone:"GMT Standard Time"})
        timeZones.push({Olson:"Europe/Jersey", Win32TimeZone:"GMT Standard Time"})
        timeZones.push({Olson:"Europe/Lisbon Atlantic/Madeira", Win32TimeZone:"GMT Standard Time"})
        timeZones.push({Olson:"Atlantic/Reykjavik", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Ouagadougou", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Abidjan", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Accra", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Banjul", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Conakry", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Bissau", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Monrovia", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Bamako", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Nouakchott", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Atlantic/St_Helena", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Freetown", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Dakar", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Africa/Lome", Win32TimeZone:"Greenwich Standard Time"})
        timeZones.push({Olson:"Europe/Berlin", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Andorra", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Vienna", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Zurich", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Berlin Europe/Busingen", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Gibraltar", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Rome", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Vaduz", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Luxembourg", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Monaco", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Malta", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Amsterdam", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Oslo", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Stockholm", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Arctic/Longyearbyen", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/San_Marino", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Vatican", Win32TimeZone:"W. Europe Standard Time"})
        timeZones.push({Olson:"Europe/Budapest", Win32TimeZone:"Central Europe Standard Time"})
        timeZones.push({Olson:"Europe/Tirane", Win32TimeZone:"Central Europe Standard Time"})
        timeZones.push({Olson:"Europe/Prague", Win32TimeZone:"Central Europe Standard Time"})
        timeZones.push({Olson:"Europe/Podgorica", Win32TimeZone:"Central Europe Standard Time"})
        timeZones.push({Olson:"Europe/Belgrade", Win32TimeZone:"Central Europe Standard Time"})
        timeZones.push({Olson:"Europe/Ljubljana", Win32TimeZone:"Central Europe Standard Time"})
        timeZones.push({Olson:"Europe/Bratislava", Win32TimeZone:"Central Europe Standard Time"})
        timeZones.push({Olson:"Europe/Paris", Win32TimeZone:"Romance Standard Time"})
        timeZones.push({Olson:"Europe/Brussels", Win32TimeZone:"Romance Standard Time"})
        timeZones.push({Olson:"Europe/Copenhagen", Win32TimeZone:"Romance Standard Time"})
        timeZones.push({Olson:"Europe/Madrid Africa/Ceuta", Win32TimeZone:"Romance Standard Time"})
        timeZones.push({Olson:"Africa/Sao_Tome", Win32TimeZone:"Sao Tome Standard Time"})
        timeZones.push({Olson:"Europe/Warsaw", Win32TimeZone:"Central European Standard Time"})
        timeZones.push({Olson:"Europe/Sarajevo", Win32TimeZone:"Central European Standard Time"})
        timeZones.push({Olson:"Europe/Zagreb", Win32TimeZone:"Central European Standard Time"})
        timeZones.push({Olson:"Europe/Skopje", Win32TimeZone:"Central European Standard Time"})
        timeZones.push({Olson:"Africa/Lagos", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Luanda", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Porto-Novo", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Kinshasa", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Bangui", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Brazzaville", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Douala", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Algiers", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Libreville", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Malabo", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Niamey", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Ndjamena", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Africa/Tunis", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Etc/GMT-1", Win32TimeZone:"W. Central Africa Standard Time"})
        timeZones.push({Olson:"Asia/Amman", Win32TimeZone:"Jordan Standard Time"})
        timeZones.push({Olson:"Europe/Bucharest", Win32TimeZone:"GTB Standard Time"})
        timeZones.push({Olson:"Asia/Famagusta Asia/Nicosia", Win32TimeZone:"GTB Standard Time"})
        timeZones.push({Olson:"Europe/Athens", Win32TimeZone:"GTB Standard Time"})
        timeZones.push({Olson:"Asia/Beirut", Win32TimeZone:"Middle East Standard Time"})
        timeZones.push({Olson:"Africa/Cairo", Win32TimeZone:"Egypt Standard Time"})
        timeZones.push({Olson:"Europe/Chisinau", Win32TimeZone:"E. Europe Standard Time"})
        timeZones.push({Olson:"Asia/Damascus", Win32TimeZone:"Syria Standard Time"})
        timeZones.push({Olson:"Asia/Hebron", Win32TimeZone:"West Bank Standard Time"})
        timeZones.push({Olson:"Asia/Hebron Asia/Gaza", Win32TimeZone:"West Bank Standard Time"})
        timeZones.push({Olson:"Africa/Johannesburg", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Bujumbura", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Gaborone", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Lubumbashi", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Maseru", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Blantyre", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Maputo", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Kigali", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Mbabane", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Lusaka", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Africa/Harare", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Etc/GMT-2", Win32TimeZone:"South Africa Standard Time"})
        timeZones.push({Olson:"Europe/Kiev", Win32TimeZone:"FLE Standard Time"})
        timeZones.push({Olson:"Europe/Mariehamn", Win32TimeZone:"FLE Standard Time"})
        timeZones.push({Olson:"Europe/Sofia", Win32TimeZone:"FLE Standard Time"})
        timeZones.push({Olson:"Europe/Tallinn", Win32TimeZone:"FLE Standard Time"})
        timeZones.push({Olson:"Europe/Helsinki", Win32TimeZone:"FLE Standard Time"})
        timeZones.push({Olson:"Europe/Vilnius", Win32TimeZone:"FLE Standard Time"})
        timeZones.push({Olson:"Europe/Riga", Win32TimeZone:"FLE Standard Time"})
        timeZones.push({Olson:"Europe/Kiev Europe/Uzhgorod Europe/Zaporozhye", Win32TimeZone:"FLE Standard Time"})
        timeZones.push({Olson:"Asia/Jerusalem", Win32TimeZone:"Israel Standard Time"})
        timeZones.push({Olson:"Europe/Kaliningrad", Win32TimeZone:"Kaliningrad Standard Time"})
        timeZones.push({Olson:"Africa/Khartoum", Win32TimeZone:"Sudan Standard Time"})
        timeZones.push({Olson:"Africa/Tripoli", Win32TimeZone:"Libya Standard Time"})
        timeZones.push({Olson:"Africa/Windhoek", Win32TimeZone:"Namibia Standard Time"})
        timeZones.push({Olson:"Asia/Baghdad", Win32TimeZone:"Arabic Standard Time"})
        timeZones.push({Olson:"Europe/Istanbul", Win32TimeZone:"Turkey Standard Time"})
        timeZones.push({Olson:"Asia/Riyadh", Win32TimeZone:"Arab Standard Time"})
        timeZones.push({Olson:"Asia/Bahrain", Win32TimeZone:"Arab Standard Time"})
        timeZones.push({Olson:"Asia/Kuwait", Win32TimeZone:"Arab Standard Time"})
        timeZones.push({Olson:"Asia/Qatar", Win32TimeZone:"Arab Standard Time"})
        timeZones.push({Olson:"Asia/Aden", Win32TimeZone:"Arab Standard Time"})
        timeZones.push({Olson:"Europe/Minsk", Win32TimeZone:"Belarus Standard Time"})
        timeZones.push({Olson:"Europe/Moscow", Win32TimeZone:"Russian Standard Time"})
        timeZones.push({Olson:"Europe/Moscow Europe/Kirov Europe/Volgograd", Win32TimeZone:"Russian Standard Time"})
        timeZones.push({Olson:"Europe/Simferopol", Win32TimeZone:"Russian Standard Time"})
        timeZones.push({Olson:"Africa/Nairobi", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Antarctica/Syowa", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Africa/Djibouti", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Africa/Asmera", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Africa/Addis_Ababa", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Indian/Comoro", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Indian/Antananarivo", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Africa/Mogadishu", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Africa/Juba", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Africa/Dar_es_Salaam", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Africa/Kampala", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Indian/Mayotte", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Etc/GMT-3", Win32TimeZone:"E. Africa Standard Time"})
        timeZones.push({Olson:"Asia/Tehran", Win32TimeZone:"Iran Standard Time"})
        timeZones.push({Olson:"Asia/Dubai", Win32TimeZone:"Arabian Standard Time"})
        timeZones.push({Olson:"Asia/Muscat", Win32TimeZone:"Arabian Standard Time"})
        timeZones.push({Olson:"Etc/GMT-4", Win32TimeZone:"Arabian Standard Time"})
        timeZones.push({Olson:"Europe/Astrakhan", Win32TimeZone:"Astrakhan Standard Time"})
        timeZones.push({Olson:"Europe/Astrakhan Europe/Ulyanovsk", Win32TimeZone:"Astrakhan Standard Time"})
        timeZones.push({Olson:"Asia/Baku", Win32TimeZone:"Azerbaijan Standard Time"})
        timeZones.push({Olson:"Europe/Samara", Win32TimeZone:"Russia Time Zone 3"})
        timeZones.push({Olson:"Indian/Mauritius", Win32TimeZone:"Mauritius Standard Time"})
        timeZones.push({Olson:"Indian/Reunion", Win32TimeZone:"Mauritius Standard Time"})
        timeZones.push({Olson:"Indian/Mahe", Win32TimeZone:"Mauritius Standard Time"})
        timeZones.push({Olson:"Europe/Saratov", Win32TimeZone:"Saratov Standard Time"})
        timeZones.push({Olson:"Asia/Tbilisi", Win32TimeZone:"Georgian Standard Time"})
        timeZones.push({Olson:"Asia/Yerevan", Win32TimeZone:"Caucasus Standard Time"})
        timeZones.push({Olson:"Asia/Kabul", Win32TimeZone:"Afghanistan Standard Time"})
        timeZones.push({Olson:"Asia/Tashkent", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Antarctica/Mawson", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Asia/Oral Asia/Aqtau Asia/Aqtobe Asia/Atyrau", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Indian/Maldives", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Indian/Kerguelen", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Asia/Dushanbe", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Asia/Ashgabat", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Asia/Tashkent Asia/Samarkand", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Etc/GMT-5", Win32TimeZone:"West Asia Standard Time"})
        timeZones.push({Olson:"Asia/Yekaterinburg", Win32TimeZone:"Ekaterinburg Standard Time"})
        timeZones.push({Olson:"Asia/Karachi", Win32TimeZone:"Pakistan Standard Time"})
        timeZones.push({Olson:"Asia/Calcutta", Win32TimeZone:"India Standard Time"})
        timeZones.push({Olson:"Asia/Colombo", Win32TimeZone:"Sri Lanka Standard Time"})
        timeZones.push({Olson:"Asia/Katmandu", Win32TimeZone:"Nepal Standard Time"})
        timeZones.push({Olson:"Asia/Almaty", Win32TimeZone:"Central Asia Standard Time"})
        timeZones.push({Olson:"Antarctica/Vostok", Win32TimeZone:"Central Asia Standard Time"})
        timeZones.push({Olson:"Asia/Urumqi", Win32TimeZone:"Central Asia Standard Time"})
        timeZones.push({Olson:"Indian/Chagos", Win32TimeZone:"Central Asia Standard Time"})
        timeZones.push({Olson:"Asia/Bishkek", Win32TimeZone:"Central Asia Standard Time"})
        timeZones.push({Olson:"Asia/Almaty Asia/Qyzylorda", Win32TimeZone:"Central Asia Standard Time"})
        timeZones.push({Olson:"Etc/GMT-6", Win32TimeZone:"Central Asia Standard Time"})
        timeZones.push({Olson:"Asia/Dhaka", Win32TimeZone:"Bangladesh Standard Time"})
        timeZones.push({Olson:"Asia/Thimphu", Win32TimeZone:"Bangladesh Standard Time"})
        timeZones.push({Olson:"Asia/Omsk", Win32TimeZone:"Omsk Standard Time"})
        timeZones.push({Olson:"Asia/Rangoon", Win32TimeZone:"Myanmar Standard Time"})
        timeZones.push({Olson:"Indian/Cocos", Win32TimeZone:"Myanmar Standard Time"})
        timeZones.push({Olson:"Asia/Bangkok", Win32TimeZone:"SE Asia Standard Time"})
        timeZones.push({Olson:"Antarctica/Davis", Win32TimeZone:"SE Asia Standard Time"})
        timeZones.push({Olson:"Indian/Christmas", Win32TimeZone:"SE Asia Standard Time"})
        timeZones.push({Olson:"Asia/Jakarta Asia/Pontianak", Win32TimeZone:"SE Asia Standard Time"})
        timeZones.push({Olson:"Asia/Phnom_Penh", Win32TimeZone:"SE Asia Standard Time"})
        timeZones.push({Olson:"Asia/Vientiane", Win32TimeZone:"SE Asia Standard Time"})
        timeZones.push({Olson:"Asia/Saigon", Win32TimeZone:"SE Asia Standard Time"})
        timeZones.push({Olson:"Etc/GMT-7", Win32TimeZone:"SE Asia Standard Time"})
        timeZones.push({Olson:"Asia/Barnaul", Win32TimeZone:"Altai Standard Time"})
        timeZones.push({Olson:"Asia/Hovd", Win32TimeZone:"W. Mongolia Standard Time"})
        timeZones.push({Olson:"Asia/Krasnoyarsk", Win32TimeZone:"North Asia Standard Time"})
        timeZones.push({Olson:"Asia/Krasnoyarsk Asia/Novokuznetsk", Win32TimeZone:"North Asia Standard Time"})
        timeZones.push({Olson:"Asia/Novosibirsk", Win32TimeZone:"N. Central Asia Standard Time"})
        timeZones.push({Olson:"Asia/Tomsk", Win32TimeZone:"Tomsk Standard Time"})
        timeZones.push({Olson:"Asia/Shanghai", Win32TimeZone:"China Standard Time"})
        timeZones.push({Olson:"Asia/Hong_Kong", Win32TimeZone:"China Standard Time"})
        timeZones.push({Olson:"Asia/Macau", Win32TimeZone:"China Standard Time"})
        timeZones.push({Olson:"Asia/Irkutsk", Win32TimeZone:"North Asia East Standard Time"})
        timeZones.push({Olson:"Asia/Singapore", Win32TimeZone:"Singapore Standard Time"})
        timeZones.push({Olson:"Asia/Brunei", Win32TimeZone:"Singapore Standard Time"})
        timeZones.push({Olson:"Asia/Makassar", Win32TimeZone:"Singapore Standard Time"})
        timeZones.push({Olson:"Asia/Kuala_Lumpur Asia/Kuching", Win32TimeZone:"Singapore Standard Time"})
        timeZones.push({Olson:"Asia/Manila", Win32TimeZone:"Singapore Standard Time"})
        timeZones.push({Olson:"Etc/GMT-8", Win32TimeZone:"Singapore Standard Time"})
        timeZones.push({Olson:"Australia/Perth", Win32TimeZone:"W. Australia Standard Time"})
        timeZones.push({Olson:"Antarctica/Casey", Win32TimeZone:"W. Australia Standard Time"})
        timeZones.push({Olson:"Asia/Taipei", Win32TimeZone:"Taipei Standard Time"})
        timeZones.push({Olson:"Asia/Ulaanbaatar", Win32TimeZone:"Ulaanbaatar Standard Time"})
        timeZones.push({Olson:"Asia/Ulaanbaatar Asia/Choibalsan", Win32TimeZone:"Ulaanbaatar Standard Time"})
        timeZones.push({Olson:"Australia/Eucla", Win32TimeZone:"Aus Central W. Standard Time"})
        timeZones.push({Olson:"Asia/Chita", Win32TimeZone:"Transbaikal Standard Time"})
        timeZones.push({Olson:"Asia/Tokyo", Win32TimeZone:"Tokyo Standard Time"})
        timeZones.push({Olson:"Asia/Jayapura", Win32TimeZone:"Tokyo Standard Time"})
        timeZones.push({Olson:"Pacific/Palau", Win32TimeZone:"Tokyo Standard Time"})
        timeZones.push({Olson:"Asia/Dili", Win32TimeZone:"Tokyo Standard Time"})
        timeZones.push({Olson:"Etc/GMT-9", Win32TimeZone:"Tokyo Standard Time"})
        timeZones.push({Olson:"Asia/Pyongyang", Win32TimeZone:"North Korea Standard Time"})
        timeZones.push({Olson:"Asia/Seoul", Win32TimeZone:"Korea Standard Time"})
        timeZones.push({Olson:"Asia/Yakutsk", Win32TimeZone:"Yakutsk Standard Time"})
        timeZones.push({Olson:"Asia/Yakutsk Asia/Khandyga", Win32TimeZone:"Yakutsk Standard Time"})
        timeZones.push({Olson:"Australia/Adelaide", Win32TimeZone:"Cen. Australia Standard Time"})
        timeZones.push({Olson:"Australia/Adelaide Australia/Broken_Hill", Win32TimeZone:"Cen. Australia Standard Time"})
        timeZones.push({Olson:"Australia/Darwin", Win32TimeZone:"AUS Central Standard Time"})
        timeZones.push({Olson:"Australia/Brisbane", Win32TimeZone:"E. Australia Standard Time"})
        timeZones.push({Olson:"Australia/Brisbane Australia/Lindeman", Win32TimeZone:"E. Australia Standard Time"})
        timeZones.push({Olson:"Australia/Sydney", Win32TimeZone:"AUS Eastern Standard Time"})
        timeZones.push({Olson:"Australia/Sydney Australia/Melbourne", Win32TimeZone:"AUS Eastern Standard Time"})
        timeZones.push({Olson:"Pacific/Port_Moresby", Win32TimeZone:"West Pacific Standard Time"})
        timeZones.push({Olson:"Antarctica/DumontDUrville", Win32TimeZone:"West Pacific Standard Time"})
        timeZones.push({Olson:"Pacific/Truk", Win32TimeZone:"West Pacific Standard Time"})
        timeZones.push({Olson:"Pacific/Guam", Win32TimeZone:"West Pacific Standard Time"})
        timeZones.push({Olson:"Pacific/Saipan", Win32TimeZone:"West Pacific Standard Time"})
        timeZones.push({Olson:"Etc/GMT-10", Win32TimeZone:"West Pacific Standard Time"})
        timeZones.push({Olson:"Australia/Hobart", Win32TimeZone:"Tasmania Standard Time"})
        timeZones.push({Olson:"Australia/Hobart Australia/Currie", Win32TimeZone:"Tasmania Standard Time"})
        timeZones.push({Olson:"Asia/Vladivostok", Win32TimeZone:"Vladivostok Standard Time"})
        timeZones.push({Olson:"Asia/Vladivostok Asia/Ust-Nera", Win32TimeZone:"Vladivostok Standard Time"})
        timeZones.push({Olson:"Australia/Lord_Howe", Win32TimeZone:"Lord Howe Standard Time"})
        timeZones.push({Olson:"Pacific/Bougainville", Win32TimeZone:"Bougainville Standard Time"})
        timeZones.push({Olson:"Asia/Srednekolymsk", Win32TimeZone:"Russia Time Zone 10"})
        timeZones.push({Olson:"Asia/Magadan", Win32TimeZone:"Magadan Standard Time"})
        timeZones.push({Olson:"Pacific/Norfolk", Win32TimeZone:"Norfolk Standard Time"})
        timeZones.push({Olson:"Asia/Sakhalin", Win32TimeZone:"Sakhalin Standard Time"})
        timeZones.push({Olson:"Pacific/Guadalcanal", Win32TimeZone:"Central Pacific Standard Time"})
        timeZones.push({Olson:"Antarctica/Macquarie", Win32TimeZone:"Central Pacific Standard Time"})
        timeZones.push({Olson:"Pacific/Ponape Pacific/Kosrae", Win32TimeZone:"Central Pacific Standard Time"})
        timeZones.push({Olson:"Pacific/Noumea", Win32TimeZone:"Central Pacific Standard Time"})
        timeZones.push({Olson:"Pacific/Efate", Win32TimeZone:"Central Pacific Standard Time"})
        timeZones.push({Olson:"Etc/GMT-11", Win32TimeZone:"Central Pacific Standard Time"})
        timeZones.push({Olson:"Asia/Kamchatka", Win32TimeZone:"Russia Time Zone 11"})
        timeZones.push({Olson:"Asia/Kamchatka Asia/Anadyr", Win32TimeZone:"Russia Time Zone 11"})
        timeZones.push({Olson:"Pacific/Auckland", Win32TimeZone:"New Zealand Standard Time"})
        timeZones.push({Olson:"Antarctica/McMurdo", Win32TimeZone:"New Zealand Standard Time"})
        timeZones.push({Olson:"Etc/GMT-12", Win32TimeZone:"UTC+12"})
        timeZones.push({Olson:"Pacific/Tarawa", Win32TimeZone:"UTC+12"})
        timeZones.push({Olson:"Pacific/Majuro Pacific/Kwajalein", Win32TimeZone:"UTC+12"})
        timeZones.push({Olson:"Pacific/Nauru", Win32TimeZone:"UTC+12"})
        timeZones.push({Olson:"Pacific/Funafuti", Win32TimeZone:"UTC+12"})
        timeZones.push({Olson:"Pacific/Wake", Win32TimeZone:"UTC+12"})
        timeZones.push({Olson:"Pacific/Wallis", Win32TimeZone:"UTC+12"})
        timeZones.push({Olson:"Pacific/Fiji", Win32TimeZone:"Fiji Standard Time"})
        timeZones.push({Olson:"Pacific/Chatham", Win32TimeZone:"Chatham Islands Standard Time"})
        timeZones.push({Olson:"Etc/GMT-13", Win32TimeZone:"UTC+13"})
        timeZones.push({Olson:"Pacific/Enderbury", Win32TimeZone:"UTC+13"})
        timeZones.push({Olson:"Pacific/Fakaofo", Win32TimeZone:"UTC+13"})
        timeZones.push({Olson:"Pacific/Tongatapu", Win32TimeZone:"Tonga Standard Time"})
        timeZones.push({Olson:"Pacific/Apia", Win32TimeZone:"Samoa Standard Time"})
        timeZones.push({Olson:"Pacific/Kiritimati", Win32TimeZone:"Line Islands Standard Time"})
        timeZones.push({Olson:"Etc/GMT-14", Win32TimeZone:"Line Islands Standard Time"})
        timeZones.push({Olson:"America/Argentina/San_Juan", Win32TimeZone:"Argentina Standard Time"})
        timeZones.push({Olson:"America/Fortaleza", Win32TimeZone:"SA Eastern Standard Time"})
        timeZones.push({Olson:"America/Indiana/Indianapolis", Win32TimeZone:"US Eastern Standard Time"})
        timeZones.push({Olson:"America/Santa_Isabel", Win32TimeZone:"Pacific Standard Time (Mexico)"})
        timeZones.push({Olson:"Asia/Kolkata", Win32TimeZone:"India Standard Time"})
        timeZones.push({Olson:"Asia/Kuala_Lumpur", Win32TimeZone:"Singapore Standard Time"})
        timeZones.push({Olson:"Asia/Oral", Win32TimeZone:"West Asia Standard Time"})
        return timeZones;
    }
}

const olsonToWinTimeZone = new OlsonToWinTimeZone();
export default olsonToWinTimeZone;