const { ActionTypes } = FinancialBlockConstants;
import ConsoleStore from "ConsoleStore";
import { DataGraphConstants } from "../../../../Constants/DataGraphConstants";
import { FinancialBlockConstants } from "../../../../Constants/FinancialBlockConstants";
import { SettingsConstants } from "../../../../Constants/SettingsConstants.js";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "SettingsStore";

const PriceChartAction = PriceChartConstants.ActionTypes

const initialState = {
    BlockSize: 0,
    CurrentPageHeaders: [],
    CurrentPage: 0,
    DisplayMode: "",
    Height: 224,
    PagintedSource: [],
    PePsData: {},
    RowHeight: 16,
    RowItems: [],
    graphData: {},
    headerMenu: [],
    isReset: false,
    nodeWidth: 4,
    rightScaleWidth: 100,
    showDataNotAvailable: false,
    dataAvailable: false,
    periodicity: "",
    financialBlockResponse: null,
    FinancialBlockSettings: null,
    isFinancialOpen: false,
    financialRawData: null,
    CurrentRows: null,
    _pickListData: {
        PickListItems1: [],
        PickListItems2: [],
        PickListItems3: [],
        ItemDataList: []
    },
    CurrentHeaders: [],
    financialBlockSetting: {},
    onHover: false,
    indexSelected: 0,
    viewsSettings:{},
    priceChartWidth: 0,
    paginatedTableRendered: false,
}

const financialBlockReducer = (state = initialState, action) => {

    switch (action.type) {

        case ActionTypes.SET_FINANCIAL_DATA: {
            return { ...state, ...action.newState, paginatedTableRendered: false }
        }

        case ActionTypes.UPDATE_CONSOLE_SETTINGS: {
            return {
                ...state,
                RowItems: [...action.RowItems],
                CurrentHeaders: [...action.CurrentHeaders]
            }
        }
        case ActionTypes.PAGINATED_GRAPH_VIEW: {
            const financialBlockSettings = state.viewsSettings.FinancialBlockSettings ? state.viewsSettings.FinancialBlockSettings[state.periodicity] : null;
            financialBlockSettings?.graphView?.forEach((item, key) => {
                if (key === state.CurrentPage) {
                    item.isActive = action.isEnableGraph;
                    item.showGraphIndex = action.index;
                    item.isShowGraph = action.isShowGraph;
                    item.activeIndex = item.isActive ? action.index : null;
                }
            })
            SettingsStore.saveSettings();
            return {
                ...state,
                financialBlockSetting: financialBlockSettings,
                graphData: financialBlockSettings.graphView[state.CurrentPage]
            }
        }

        case ActionTypes.UPDATE_FINANCIAL_PAGE: {
            return {
                ...state,
                CurrentPage: action.pageNumber,
                RowItems: action.RowItems,
                graphData: state.financialBlockSetting.graphView[action.pageNumber]
            }
        }


        case ActionTypes.UPDATE_UI_ON_PE_TOGGLE:
            ConsoleStore.trackUsage('FinancialBlockPEChanged', '', ['Manual']);
            return {
                ...state,
                PePsData: action.PePsData
            }

        case ActionTypes.SET_MENU_DATA: {
            return { ...state, ...action.newState }
        }

        case SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED: {
            return { ...initialState }
        }

        case ActionTypes.FINANCIAL_BLOCK_RESPONSE_READY: {
            return { ...state, financialBlockResponse: action.financialBlockResponse }
        }

        case ActionTypes.FINANCIAL_BLOCK_COLLAPSE: {
            if (action.blockType === "Financial") {
                state.FinancialBlockSettings.IsVisible = action.isopen;
                SettingsStore.saveSettings();
            }
            return { ...state, isFinancialOpen: action.isopen, paginatedTableRendered: false }
        }

        case ActionTypes.FINANCIAL_BLOCK_DEFAULT_STATE: {
            state.financialBlockResponse = null
            if (action.payload) {
                return { ...state, isFinancialOpen: false, paginatedTableRendered: false }
            }
            return { ...state }
        }

        case ActionTypes.FINANCIAL_BLOCK_UPDATE_CURRENT_ROWS: {
            return { ...state, CurrentRows: action.payload }
        }

        case ActionTypes.FINANCIAL_BLOCK_UPDATE_PAGINATED_SOURCE: {
            return { ...state, PagintedSource: action.payload }
        }

        case ActionTypes.FINANCIAL_BLOCK_UPDATE_CURRENT_PAGE_HEADERS: {
            return { ...state, CurrentPageHeaders: action.payload }
        }

        case ActionTypes.FINANCIAL_BLOCK_UPDATE_PICK_LIST: {
            return { ...state, ...action.newState }
        }

        case DataGraphConstants.ActionTypes.ON_PLOT_SYMBOL: {
            if (action.isViewChart) {
                return { ...state, isFinancialOpen: false }
            }
        }
        case PriceChartAction.CHART_RESIZE: // Listening chart-resize so that financial block can update
            return {
                ...state,
                priceChartWidth: action?.dimension?.width
            }
        case ActionTypes.UPDATE_PAGINATED_TABLE_RENDER:	
            return {...state, paginatedTableRendered: true}
        default: return state
    }

}

export default financialBlockReducer