import { put, call, select, takeLatest, all, fork } from 'redux-saga/effects';
import { each, map, sortBy, find, contains, filter, some, extend } from "underscore";
import { getCompareChartViewModel, getPeriodicity, getStartDate, getEndDate, getSortType } from '../../../Reducers/NavCompare/ComparisonGraphReducers/selectors';
import { getSelectedTabActiveMenuName, getGroupsMenu, getActiveCompNavTabName, getRotationGraphMenuData, getSymbolsMenu, getCountableCountTotal } from '../../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import SettingsStore from "SettingsStore"
import CompareTabType, { CompareMenuType } from "CompareTabType";
import { ComparisonGraph } from '../../../Constants/ComparisonGraph';
import { ComparisonAndRotationMenuConstants } from '../../../Constants/RotationGraphConstants';
import { getBenchMarkState } from '../../../Reducers/NavCompare/RotationGraphReducers/selectors';
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";

const { ActionTypes } = ComparisonAndRotationMenuConstants;
extend(ActionTypes, ComparisonGraph.ActionTypes);

export function* handleLoading(isLoading = false) {

    try {
        yield put({
            type: ActionTypes.MENU_LOADING,
            isLoading
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in SymbolsGroupsCommonSaga.js, handleLoading ${error}`);
    }
}

export function* handleSymbolsInputLimit(input,isList = false) {
    const { total } = yield select(getCountableCountTotal);
    if (input + total > 100) {
        yield call(handleLoading, false);

        yield put({
            type: ActionTypes.CLEAR_SYMBOL_SEARCH_TEXT
        })

        yield put({
            type: ActionTypes.HIDE_DROPDOWN
        })
      
        yield put({
            type: ActionTypes.SET_LISTALERT_MSG,
            alertMessage: isList ? "No Symbols Added - List contains more than 100 symbols" : "Symbol not Added - Max 100 Symbols can be Added"
        })

        yield put({
            type: ActionTypes.SHOW_LISTALERT_MSG,
            showAddListAlertMessage: true
        })
        return true;
    }
    else
        return false;
}


function* navCompareSaveSettings() {
    let selectedTab = yield select(getActiveCompNavTabName);
    const symbolsMenuStates = yield select(getSymbolsMenu);
    try {
        if (selectedTab === CompareTabType.ComparisonGraph) {
            let currentTab = yield select(getSelectedTabActiveMenuName);
            const states = yield select(getCompareChartViewModel);
            const startDate = yield select(getStartDate);
            const endDate = yield select(getEndDate);
            const periodicity = yield select(getPeriodicity);
            const sortType = yield select(getSortType);
            const groupsMenuState = yield select(getGroupsMenu);
            const consoleSettings = yield call(SettingsStore.getConsoleSettings);

            consoleSettings.printSettings.windowWidth = window.screen.width;
            consoleSettings.printSettings.windowHeight = window.screen.height;

            if (currentTab == CompareMenuType.Symbols) {
                const symbolsMenusettings = {
                    symbolRequest: states.symbolRequest,
                    indexRequest: states.indexRequest,
                    userStartDate: startDate,
                    userEndDate: endDate,
                    periodicity: periodicity,
                    sortType: sortType,
                    scaleBased: states.scaleBasedValue,
                    scaleType: states.scaleType,
                    msids: states.msids,
                    listName: symbolsMenuStates.listName
                };
                CompareSettingHelper.updateComparisionSymbolsMenuSettings(consoleSettings.NavCompareSettings.TabComparisonGraphSettings.comparisonSymbolsMenuSettings, symbolsMenusettings);

            } else {
                const groupsMenuSettings = {
                    listItems: groupsMenuState.listItems,
                    symbolRequest: states.symbolRequest,
                    indexRequest: states.indexRequest,
                    userStartDate: startDate,
                    userEndDate: endDate,
                    periodicity: periodicity,
                    sortType: sortType,
                    scaleBased: states.scaleBasedValue,
                    scaleType: states.scaleType
                }
                if (groupsMenuSettings.symbolRequest.length > 0) {
                    CompareSettingHelper.updateGroupsMenuBreadCrumList(consoleSettings.NavCompareSettings.TabComparisonGraphSettings.comparisonGroupsMenuSettings, groupsMenuState.breadCrumbItems);
                    CompareSettingHelper.updateComparisionGroupsMenuSettings(consoleSettings.NavCompareSettings.TabComparisonGraphSettings.comparisonGroupsMenuSettings, groupsMenuSettings);
                }
            }
            SettingsStore.saveSettings();
        }
        else {
            let currentTab = yield select(getSelectedTabActiveMenuName);
            const consoleSettings = yield call(SettingsStore.getConsoleSettings);
            const states = yield select(getRotationGraphMenuData);
            const benchMarkState = yield select(getBenchMarkState);
            if (currentTab === CompareMenuType.Symbols) { 
                const symbolsMenuSettings = {
                    symbolsList: states.symbolsList,
                    stockList: states.stockList,
                    isTimeLinePlaying: benchMarkState.isPlaying,
                    isUptrendVisible: benchMarkState.isUptrendVisible,
                    isDowntrendVisible: benchMarkState.isDowntrendVisible,
                    // timeLineIndex: benchMarkState.selectedSliderValue,
                    lastHighlightedOsids: states.lastHighlightedOsids,
                    notVisibleOsids: states.isNotVisibleOsids,
                    osidList: states.selectedOsid,
                    countryCodeList: states.countryCodeList,
                    regionCodeList: states.regionCodeList,
                    countryCode: states.countryCode,
                    regionCode: states.regionCode,
                    countryAggArray: states.countryAggArray,
                    regionAggArray: states.regionAggArray,
                    listName: symbolsMenuStates.listName,
                    benchMarkOption: benchMarkState.benchMarkOption
                }
                CompareSettingHelper.updateSymbolsMenuRotationSettings(consoleSettings.NavCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings, symbolsMenuSettings);
                consoleSettings.NavCompareSettings.TabComparisonGraphSettings.rotationMenuTab = CompareMenuType.Symbols;
            } else {
                const groupsMenuSettings = {
                    isTimeLinePlaying: benchMarkState["isPlaying"],
                    isUptrendVisible: benchMarkState["isUptrendVisible"],
                    isDowntrendVisible: benchMarkState["isDowntrendVisible"],
                    timeLineIndex: benchMarkState["selectedSliderValue"],
                    lastHighlightedOsids: states.lastHighlightedOsids,
                    notVisibleOsids: states.isNotVisibleOsids
                }

                CompareSettingHelper.updateGroupsMenuBreadCrumList(consoleSettings.NavCompareSettings.TabRotationGraphSettings.rotationGroupsMenuSettings, states.breadCrumbItems);
                CompareSettingHelper.updateGroupsMenuRotationSettings(consoleSettings.NavCompareSettings.TabRotationGraphSettings.rotationGroupsMenuSettings, groupsMenuSettings);
            }
            SettingsStore.saveSettings();
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in SymbolsGroupsCommonSaga.js, navCompareSaveSettings ${error}`);
    }
}

function* listItemHighlightSelected({ key, item }) {
    let selectedTab = yield select(getActiveCompNavTabName);
    if (selectedTab === CompareTabType.ComparisonGraph) {
        yield put({
            type: ActionTypes.COMPARISON_LIST_ITEM_HIGHLIGHT_SELECTED,
            key,
            item
        })
    } else {
        yield put({
            type: ActionTypes.ROTATION_LIST_ITEM_HIGHLIGHT_SELECTED,
            key,
            item
        })
    }
}


export function* watchNavCompareSaveSettings() {
    yield takeLatest(ActionTypes.SAVE_SETTINGS, navCompareSaveSettings);
}

export function* watchListItemHighlightSelected() {
    yield takeLatest(ActionTypes.LIST_ITEM_HIGHLIGHT_SELECTED, listItemHighlightSelected);
}