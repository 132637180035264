import CEFPanelBody from "./CEFPanelBody/PanelBody";
import CurrencyPanelBody from "./CurrencyPanelBody/PanelBody";
import FundsPanelBody from "./FundsPanelBody/PanelBody";
import IndexPanelBody from "./IndexPanelBody/PanelBody";
import IndustryPanelBody from './IndustryGroupPanelBody/PanelBody';
import { openCollapse } from "../../../../../Actions/SummaryBlockActions.js";
import { Panel } from "react-bootstrap";
import PanelBody from "./StocksPanelBody/PanelBody";
import PortalContainer from 'PortalContainer';
import React from "react";
import SettingsAction from "SettingsAction";
import { SummaryTransLateHelper } from "../../../../../Utils/TranslateHelper";
import SymbolType from "../../../../../Constants/SymbolType.js";
import { useSelector, useDispatch } from "react-redux";

const SummaryBlock = () => {
    const data = useSelector((state) => state.summaryReducer);
    const dispatch = useDispatch();

    const industryView = data.industryView ? data.industryView : "false";
    const symbolType = data.symbolInfo ? data.symbolInfo.SymTypeEnum : "1";
    const currentStyle = data.instrumentStyle;
    let toolTip;

    const getRequiredPanelBody = () => {
        switch (symbolType) {
            case SymbolType.USSTOCK:
                return <PanelBody />

            case SymbolType.CURRENCY:
                return <CurrencyPanelBody />

            case SymbolType.FUND:
                return <FundsPanelBody />

            case SymbolType.FundClosedEnd:
            case SymbolType.ETF:
                return <CEFPanelBody />

            case SymbolType.WONINDUSTRYGROUP197:
            case SymbolType.WONMAJORINDUSTRY89:
            case SymbolType.WONSECTOR11:
            case SymbolType.WONSUBSECTOR33:
                return <IndustryPanelBody symbolType={symbolType} />

            case SymbolType.INDEXNOINTRADAYVOLDATA:
            case SymbolType.ECONOMICINDICATOR:
            case SymbolType.INDEXNOINTRADAYDATA:
            case SymbolType.INDEXNOINTRADAYVOL:
            case SymbolType.INTERNATIONALINDEX:
            case SymbolType.HISTORICINDEX:
            case SymbolType.INDEX:
                return <IndexPanelBody />

            default:
                return <PanelBody />
        }
    }

    const openCloseSummaryBlock = (e) => {
        const isOpen = !data.isOpen;
        dispatch(openCollapse(isOpen))
        SettingsAction.saveUserSettings();
        clearCellTitle(e);
        e.stopPropagation();
        e.preventDefault();
    }

    const getOpenClosedCSSClasses = () => {
        if (!data.isOpen) {
            return "collapsable collapse";
        }
        return "collapsable expand";
    }

    const setCellTitle = (e, displayValue) => {
        showTooltip(e, displayValue);
    }

    const showTooltip = (e, displayValue) => {
        toolTip.style.top = `${e.clientY + 20}px`;
        toolTip.style.left = `${e.clientX + 5}px`;
        toolTip.style.display = 'block';
        toolTip.style.position = 'absolute';
        toolTip.style.zIndex = 99;
        toolTip.innerHTML = displayValue;
    }

    const clearCellTitle = () => {
        toolTip.innerHTML = '';
        toolTip.style.display = 'none';
        toolTip.style.top = '0px';
        toolTip.style.left = '0px';
    }

    return (
        <>
            <div className={industryView === true ?
                "summary-block for-industry-group"
                : "summary-block"}
                style={currentStyle}
                data-disable-track-price="true">
                <div className={getOpenClosedCSSClasses()}>
                    {data.hasError !== false ?
                        <Panel className="summary-block-message-panel">
                            <Panel.Body>
                                <div className="summary-block-message">{data.error ? data.error : "Loading"}</div>
                            </Panel.Body>
                        </Panel> :
                        <>
                            {getRequiredPanelBody()}
                        </>
                    }
                    {data.settingsAvailable &&
                        <span className="icn-summary-block-loft"
                            onMouseMove={(e) => setCellTitle(e, SummaryTransLateHelper.OPEN_CLOSE_SUMMARY_BLOCK)}
                            onMouseOut={clearCellTitle}
                            onBlur={clearCellTitle}
                            onClick={openCloseSummaryBlock}>
                        </span>
                    }
                </div>
                <PortalContainer>
                    <div className="datagraph-tooltip" ref={(r) => { toolTip = r }}></div>
                </PortalContainer>

            </div>
        </>
    )
}

export default SummaryBlock