import React from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import { each } from 'underscore';
import moment from "moment";
import DateHelper from "DateHelper";
// import BaseServiceApi from 'BaseServiceApi'
// import UserInfoUtil from 'UserInfoUtil'

export default class DGGridSettings extends React.Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSearchTextChange = this.onSearchTextChange.bind(this);
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
    this.clearSearchOwnershipText = this.clearSearchOwnershipText.bind(this);
    // this.showCountLoader = false;

    this.state = {
      showModal: false,
      activeModal: null,
      count: null,
      searchText: this.props.searchText,
      showCountLoader: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.searchText !== nextProps.searchText) {
        this.setState({ showCountLoader: false, searchText: nextProps.searchText, count: null });
    }
    else if (this.props.limitDataCompleted !== nextProps.limitDataCompleted && nextProps.limitDataCompleted && this.state.showCountLoader) {
      this.countSearchResult(nextProps.reportData);
    }
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ activeModal: null, showModal: false });
  }

  handleCheckedChange() {
    this.props.onLimitChange(!this.props.limitData);
  }

  changeTextSize(e) {
    let textSize = "Medium";
    const iconSeleced = e.target.classList[0];
    switch (iconSeleced) {
      case 'icn-1a':
        textSize = "xxx-small-normal";
        break;
      case 'icn-2a':
        textSize = "xx-small-normal";
        break;
      case 'icn-3a':
        textSize = "small-normal";
        break;
      default:
        textSize = "xx-small-normal";
        break;

    }

    this.props.changeTextSize(textSize);
  }

  onSearchTextChange() {
    this.setState({ searchText: this.input.value });

    setTimeout(() => {
      if (this.state.searchText === this.input.value) {
        this.props.onSearchTextChange(this.input.value);
        if (this.props.limitDataCompleted) {
          this.countSearchResult(this.props.reportData);
        }
        else {
          this.setState({showCountLoader : true});
        }
      }
    }, 100);

  }

  clearSearchOwnershipText() {
    this.setState({ searchText: '' }, () => this.onSearchTextChange());
  }

  countSearchResult(reportData) {
    let count = 0;
    this.setState({showCountLoader : false});

    if (this.input.value !== "") {
      each(reportData, (value) => {
        each(value.reportList, (item) => {
          let name = item.name.toLowerCase();

          if (item.carryForward !== "0") {
            const d = moment(`/Date(${  item.quarterEndDate  })/`);
            const str = DateHelper.getDateString(d._d);
            name += ` as of ${  str}`;
          }

          const filter = new RegExp(" ", 'gi');
          const nameAbbr = item.nameAbbr && item.nameAbbr.replace(filter, '');
          name = name && name.replace(filter, '');
          let inputText = this.input.value;
          inputText = inputText.replace(filter, '');

          if (nameAbbr.toLowerCase().indexOf(inputText.toLowerCase()) > -1
            || name.indexOf(inputText.toLowerCase()) > -1)
            count++;
        });
      });
    }
    else
      count = null;

    this.setState({ count: count });
  }

  render() {
    let textSize = this.props.textSize;    
    let style = { opacity: '0.5' };
    const self = this;
    const classNameselected = "selected ";

    //let EntitlementType = BaseServiceApi.rayData["EntitlementType"];
    // let hasSchlistEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.SCREENSCHEDULE_Entitlement);
    // let isVisible = hasSchlistEntitlement ? "block" : "none"
    return (

      <div className="advance-text-palate"> 
        {this.state.showCountLoader ? 
          <div className="advance-text-loader">
            <div className="loading loading-spinner"></div>
          </div>
          : 
          null
        }
        {this.props.limitData ? null 
          : 
          <div className="advance-text-field">
            <div className="advance-text-result">{this.state.count > 0? `${this.state.count  } results  ` : this.state.count === 0 ? 'No result' : ''}</div>
            <input type="text" value={self.state.searchText} autoComplete="off" ref={(ref) => { this.input = ref }} id="txtOwnershipReportFind" onChange={this.onSearchTextChange} className="report-search disableDefaultSymbolInput" placeholder="Find"></input>
            {self.state.searchText && (
              <span
                className="icn-rmove ownership-tab"
                role="button"
                tabIndex="-1"
                onClick={this.clearSearchOwnershipText}
              />
            )}
          </div>}
          <Dropdown id="dropdown-custom-1" pullRight >
            <Dropdown.Toggle bsSize="small">
              <span className="icn-cong"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="list-contex">
              <MenuItem eventKey="4">Text Size</MenuItem>
              <MenuItem className="no-hover" eventKey="4">
                <div  className={textSize === "xxx-small-normal" ? `icn-1a ${classNameselected   }` :"icn-1a"} style={textSize == "xxx-small-normal" ? style : null} onClick={(e) => { this.changeTextSize(e) }}></div>
                <div  className={textSize === "xx-small-normal" ? `icn-2a ${classNameselected  }`: "icn-2a"} style={textSize == "xx-small-normal" ? style : null} onClick={(e) => { this.changeTextSize(e) }}></div>
                <div className={textSize === "small-normal" ? `icn-3a ${classNameselected }` : "icn-3a"} style={textSize == "small-normal" ? style : null} onClick={(e) => { this.changeTextSize(e) }}></div>
              </MenuItem>
              <MenuItem eventKey="2">Limit Report to 100 rows</MenuItem>
              <MenuItem eventKey="5" >
                <span className="radio-on-block">
                  <input id="radio4On" type="radio" onChange={this.handleCheckedChange} name="rbIsAlertSoundOn" checked={this.props.limitData === true} />
                  <label htmlFor="radio4On">ON</label>
                </span>
                <span className="radio-off-block">
                  <input id="radio4Off" type="radio" onChange={this.handleCheckedChange} name="rbIsAlertSoundOn" checked={this.props.limitData === false} />
                  <label htmlFor="radio4Off">OFF</label>
                </span>
              </MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        {this.state.activeModal}
      </div>
    );
  }
}