import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetColumnSetRequest extends BaseServiceRequest {

    constructor(activeListId) {
        super();
        super.addRequestUri("columnsets.pb");
        super.addRequestParameter("l", activeListId.toString());
        super.addRequestParameter("is", "1");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default GetColumnSetRequest;
