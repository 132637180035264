import BlockSize from "../../../../Constants/BlockSize.js";
import ExtremeDataValue from "ExtremeDataValue";
import GraphType from "GraphType";
import PePsBlockView from "../../../../Constants/PePsBlockView.js";
import SymbolType from 'SymbolType';

class PePsDataObject {
    constructor() {
        this.currentHeaderString = "";
        this.blockItems = [];
        this.currentValue = "";
    }
    getCurrentHeaderString(pePsBlockView, symType) {
        let currentHeaderString = "";
        switch (pePsBlockView) {
            case PePsBlockView.Pe:
                if (symType === SymbolType.REIT) { currentHeaderString = "P/F"; }
                else { currentHeaderString = "P/E"; }
                break;
            case PePsBlockView.Ps:
                currentHeaderString = "P/S";
                break;
            case PePsBlockView.Bvs:
                currentHeaderString = "BV";
                break;
            case PePsBlockView.Cfs:
                currentHeaderString = "CFS";
                break;
            case PePsBlockView.Fcfs:
                currentHeaderString = "FCF";
                break;
            case PePsBlockView.Div:
                currentHeaderString = "DIV";
                break;
            default:
                break;
        }
        return currentHeaderString;
    }

    setCurrentPePsView(pePsCategory, pePsdata, periodicity, blocksize, symType) {
        this.currentHeaderString = this.getCurrentHeaderString(pePsCategory, symType);
        this.currentValue = this.populateCurrentValue(pePsCategory, pePsdata);
        this.blockItems = this.populateBlockItems(pePsCategory, pePsdata, periodicity, blocksize);
    }

    populateBlockItems(pePsCategory, pePsdata, periodicity, blocksize) {
        let items = [];
        if (pePsdata === undefined || pePsdata === null) { return; }
        const categoryItems = pePsdata.filter((item) => (item.PeCategory === pePsCategory || (pePsCategory === undefined && item.PeCategory === 0)));
        if (categoryItems && categoryItems.length > 0) {

            items = categoryItems.slice(1, categoryItems.length);
        }
        if (!pePsCategory && blocksize === BlockSize.Small) {
            this.setPePsItemsOnPeriodicity(items, periodicity);
        }
        items.forEach((item) => {
            if (pePsCategory) {
                item.Value = this.getFormatValue(item.Value, pePsCategory);
            } else {
                if (item.Label.indexOf("Est.") >= 0) {
                    if (item.Value) { item.Value = ExtremeDataValue.formatValue(Number(item.Value), 1); }
                }
                if (item.Label.indexOf("-yr") >= 0) {
                    const num = Number(item.Value);
                    if (Math.abs(num) > 1000) {
                        item.Value = ExtremeDataValue.abbreviateFinancialValue(num, 0);
                    }
                }
            }
        });
        return items;
    }

    setPePsItemsOnPeriodicity(items, periodicity) {
        if (periodicity === GraphType.Daily) {
            items.splice(1, 1);
        }
        else {
            items.splice(2, items.length)
        }

    }
    populateCurrentValue(pePsCategory, pePsData) {
        let currentValue = "";
        if (pePsData === undefined || pePsData === null) { return; }
        const categoryItems = pePsData.filter((item) => (item.PeCategory === pePsCategory || (pePsCategory === undefined && item.PeCategory === 0)));
        if (categoryItems && categoryItems.length > 0) {
            currentValue = categoryItems[0].Value;
        }
        return this.getFormatValue(currentValue, pePsCategory);
    }

    isShowDecimal(categoryType) {
        if (!categoryType) {
            return false;
        }
        return categoryType === PePsBlockView.Bvs || categoryType === PePsBlockView.Cfs ||
            categoryType === PePsBlockView.Div || categoryType === PePsBlockView.Fcfs || categoryType === PePsBlockView.Ps;
    }

    getFormatValue(value, pePsCategory) {
        const isNumber = !isNaN(Number(value)) && value !== "";
        const num = isNumber ? Number(value) : 0;
        const showDecimal = this.isShowDecimal(pePsCategory);
        const numberFormat = showDecimal ? (pePsCategory === PePsBlockView.Ps ? 1 : 2) : 0;
        if (num < 1000) {
            return isNumber ? ExtremeDataValue.formatValue(num, numberFormat) : value;
        } else {
            return ExtremeDataValue.abbreviateFinancialValue(num, numberFormat);
        }
    }

}
export default PePsDataObject;
