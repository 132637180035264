import { DataGraphConstants } from '../../../Constants/DataGraphConstants'
const { ActionTypes } = DataGraphConstants;
import DateHelper from '../../../Utils/TimeLineHelper/Datehelper';
import SettingsStore from "SettingsStore"
import { TabDataGraphConstants } from '../../../Constants/TabDataGraphConstants'
import TabFundamentalsHeaderStore from '../../../Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsHeaderStore';
import FutureContractsHeaderExpiryStateEnum from "../../../Constants/FutureContractsHeaderExpiryStateEnum";


const initialState = {
    rsValue: "",
    acdcValue: "",
    companyName: "",
    symbol: "",
    ipo: "",
    exchange: "",
    excode: "",
    indSymbol: "",
    industryGroup: "",
    mktCap: "",
    showEPSDate: false,
    showIPODate: false,
    sales: "",
    shares: "",
    float: "",
    epsDue: "",
    Isin: "",
    tiMenu: [],
    ipoDate: "",
    //Future
    exchangeName: "",
    wonGrouping: "",
    openInterest: "",
    expiryDate: "",
    endDate: "",
    lastTradeDate: "",
    ExpiryState: '',//FutureContractsHeaderExpiryStateEnum.CountDown,
    //indgroup
    groupRank: "",
    mktCapUSD: "",
    constituents: "",
    sector: "",
    sectorCode: "",
    yrHigh: "",
    yrLow: "",
    aVol: "",
    sectorHeader: "Sector ",
    constituentsHeader: "Constituents ",
    //extras
    infoData: '',
    isGICS: false,
    gicsIndustryGroupName: '',
    updatesize: false,
    symbolType: '',
    showMetricDef: false,
    industryName: "",
    definition: "",
    hideHeaderText: false
}

const HeaderDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_HEADER_RESPONSE:
            return {
                ...state,
                rsValue: action.rsValue,
                SdRank: action.SdRank,
                acdcValue: action.acdcValue,
                companyName: action.companyName,
                symbol: action.symbol,
                isHistoric: action.isHistoric,
                endDate: action.endDate,
                Isin: action.Isin,
                ipo: action.ipo,
                exchange: action.exchange,
                excode: action.excode,
                indSymbol: action.indSymbol,
                industryGroup: action.industryGroup,
                groupRank: action.groupRank,
                targetCurrencyCode: action.targetCurrencyCode,
                finTargetCurrencyCode: action.finTargetCurrencyCode,
                sales: action.sales,
                salesValue: action.salesValue,
                shares: action.shares,
                float: action.float,
                epsDueDate: action.epsDueDate,
                IsWithinTwoWeeks: action.IsWithinTwoWeeks,
                epsDue: action.epsDue,
                YrHi: action.YrHi,
                YrLo: action.YrLo,
                oHigh: action.oHigh,
                oLow: action.oLow,
                mktCap: action.mktCap,
                infoData: action.infoData,
                isGICS: action.isGICS,
                mktCapUSD: action.mktCapUSD,
                constituents: action.constituents,
                sector: action.sector,
                sectorCode: action.sectorCode,
                yrHigh: action.yrHigh,
                yrLow: action.yrLow,
                aVol: action.aVol,
                lastTradeDate: action.lastTradeDate,
                exchangeName: action.exchangeName,
                wonGrouping: action.wonGrouping,
                openInterest: action.openInterest,
                expiryDate: action.expiryDate,
                ExpiryState: action.ExpiryState,
                ipoDate: action.ipoDate,
                gicsIndustryGroupName: action.industryGroup,
                hideHeaderText: action.hideHeaderText
            }
        case ActionTypes.CLEAR_HEADER_STATE:
            return { ...initialState }
        case ActionTypes.SET_HISTORIC_HEADER_RESPONSE:
            return {
                ...state,
                isHistoric: action.isHistoric,
                endDate: action.endDate
            }
        case ActionTypes.CHANGE_EPS_FORMAT: {
            const settings = SettingsStore.getConsoleSettings();
            let IsWithinTwoWeeks = false;
            const showEPSDate = !state.showEPSDate;
            let epsDue = "";
            if (!showEPSDate) {
                let getDate = new Date();
                if (state.endDate != null && state.isHistoric){
                    getDate = state.endDate;
                } 
                const theDate = new Date(getDate.getFullYear(), getDate.getMonth(), getDate.getDate());
                const data = DateHelper.GetData(theDate, state.infoData.SymTypeEnum, state.epsDueDate);
                epsDue = data.DisplayText;
                IsWithinTwoWeeks = data.IsWithinTwoWeeks;
            }else{
                epsDue = DateHelper.EpsDueDateFormat(state.epsDueDate, state.infoData.SymTypeEnum);
            }
            TabFundamentalsHeaderStore.updateEPSformat(showEPSDate);
            settings.printSettings.showEPSDate = showEPSDate;
            SettingsStore.saveSettings();
            return {
                ...state,
                epsDue,
                IsWithinTwoWeeks,
                showEPSDate
            }
        }
        case ActionTypes.CHANGE_IPO_FORMAT:
            const settings = SettingsStore.getConsoleSettings();
            let ipeDateText = DateHelper.IpoDateFormat(state.ipoDate);
            if (state.showIPODate) {
                ipeDateText = DateHelper.IPOCountDownFormat(state.ipoDate, state.endDate);
            }
            TabFundamentalsHeaderStore.updateIPOformat(!state.showIPODate);
            settings.printSettings.showIPODate = !state.showIPODate;
            SettingsStore.saveSettings();
            return {
                ...state,
                ipo: ipeDateText,
                showIPODate: !state.showIPODate
            }
        case ActionTypes.VIEW_METRIC_DEF_READY:
            return {
                ...state,
                showMetricDef: action.data,
                industryName: action.definition.IndustryName,
                definition: action.definition
            }
        case ActionTypes.CLOSE_METRIC_DEF:
            return {
                ...state,
                showMetricDef: action.data
            }
        case ActionTypes.GICS_SELECTED:
            return {
                ...state,
                isGICS: action.data
            }
        case ActionTypes.UPDATE_TI_MENU:
            return {
                ...state,
                tiMenu: action.tiMenu
            }
        case TabDataGraphConstants.ActionTypes.CHART_RESIZE:
            return {
                ...state,
                updatesize: !state.updatesize
            }
        case ActionTypes.UPDATE_HEADER_STATES:
            return {
                ...state,
                ...action.states
            }
        case ActionTypes.SWITCH_FUTURE_EXPITY_DATE:
            const consoleSettings = SettingsStore.getConsoleSettings();
            let ExpiryState = state.ExpiryState;
            const futuresViewSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings.FuturesViewSettings;
            if (ExpiryState === FutureContractsHeaderExpiryStateEnum.CountDown) {
                ExpiryState = FutureContractsHeaderExpiryStateEnum.DueDate;
                futuresViewSettings.expiryDateFormat = FutureContractsHeaderExpiryStateEnum.DueDate;
            }
            else {
                ExpiryState = FutureContractsHeaderExpiryStateEnum.CountDown;
                futuresViewSettings.expiryDateFormat = FutureContractsHeaderExpiryStateEnum.CountDown;
            }
            SettingsStore.saveSettings();
            return {
                ...state,
                ExpiryState: ExpiryState
            }
        default:
            return state;
    }
}
export default HeaderDataReducer;