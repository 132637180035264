import classnames from "classnames";
import DateHelper from '../../Utils/TimeLineHelper/Datehelper.js';
import DateTimePickerMonths from "./DateTimePickerMonths.jsx";
import { DaysTranlateHelper } from "../../Utils/TranslateHelper.js";
import moment from "moment";
import NavType from "NavType";
import PropTypes from 'prop-types';
import SettingsStore from "SettingsStore";
import UserInfoUtil from "UserInfoUtil";
import React, { Component } from "react";
export default class DateTimePickerDays extends Component {
  constructor(props) {
    super(props);
    this.renderDays = this.renderDays.bind(this);
    this.UNSAFE_componentWillReceiveProps = this.UNSAFE_componentWillReceiveProps.bind(this);
    this.changeInputYear = this.changeInputYear.bind(this);
    this.handlePin = this.handlePin.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onClick = this.onClick.bind(this);
    this.state = {
      inputYear: this.props.viewDate.year(),
      isPinned: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    return this.setState({ inputYear: nextProps.viewDate.year() })
  }

  isNumberKey(e) {
    const charCode = (e.which) ? e.which : e.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)){
      e.preventDefault();
    }
  }

  renderDays() {
    let cells, classes, row;
    const year = this.props.viewDate.year();
    const month = this.props.viewDate.month();
    const prevMonth = this.props.viewDate.clone().subtract(1, "months");
    const days = prevMonth.daysInMonth();
    prevMonth.date(days).startOf("week");
    const nextMonth = moment(prevMonth).clone().add(42, "d");
    const minDate = this.props.minDate ? this.props.minDate.clone().subtract(0, "days") : this.props.minDate;
    const maxDate = this.props.maxDate ? this.props.maxDate.clone() : this.props.maxDate;
    const html = [];
    cells = [];
    let setNextActive = false;
    while (prevMonth.isBefore(nextMonth)) {
      classes = {
        day: true
      };
      if (prevMonth.year() < year || (prevMonth.year() === year && prevMonth.month() < month)) {
        classes.old = true;
      } else if (prevMonth.year() > year || (prevMonth.year() === year && prevMonth.month() > month)) {
        classes.new = true;
      }

      if (setNextActive) {
        classes.active = true;
        setNextActive = false;
      }

      if (prevMonth.isSame(moment({
        y: this.props.selectedDate.year(),
        M: this.props.selectedDate.month(),
        d: this.props.selectedDate.date()
      }))) {
        classes.active = true;
      }
      if (this.props.showToday) {
        if (prevMonth.isSame(moment(), "day")) {
          classes.today = true;
        }
      }

      if (this.props.daysOfWeekDisabled.length > 0) {
        if (this.props.daysOfWeekDisabled.indexOf(prevMonth.day()) !== -1) {
          if (classes.active) {
            setNextActive = true;
            classes.disabled = true;
          }
          else{
            classes.disabled = true;
          }
        }
      }

      if ((minDate && prevMonth.isBefore(minDate)) || (maxDate && prevMonth.isAfter(maxDate))) {
        classes.disabled = true;
      }

      if (this.props.blockOutDates) {
        this.props.blockOutDates.map((item) => {
          item.Date = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(item));
          if (moment(item.Date).year() === prevMonth.year() && moment(item.Date).month() === prevMonth.month() && moment(item.Date).date() === prevMonth.date()) {
            if (classes.active) {
              setNextActive = true;
              classes.disabled = true;
            }
            else{
              classes.disabled = true;
            }
          }
          return null;
        })
      }

      cells.push(<td className={classnames(classes)} key={`${prevMonth.month()}-${prevMonth.date()}`} onClick={this.props.setSelectedDate}>{prevMonth.date()}</td>);
      if (prevMonth.weekday() === moment().endOf("week").weekday()) {
        row = <tr key={`${prevMonth.month()}-${prevMonth.date()}`}>{cells}</tr>;
        html.push(row);
        cells = [];
      }
      prevMonth.add(1, "d");
    }
    return html;
  }

  changeInputYear(e) {
    this.setState({ inputYear: e.target.value });
  }

  handlePin () {
    this.setState({ isPinned: !this.state.isPinned },
      function () { this.props.handlePinState() });
  }

  handleClose () {
    const isClose = true
    this.props.closePicker(isClose);
  }

  onClick () {
    document.body.classList.add('datagraphCalendarOpened');
  }

  render() {
    const cls = this.state.isPinned ? " clicked" : "";
    const settings = SettingsStore.getConsoleSettings();
    const isNavSymbolTab = settings && settings.ActiveNav && settings.ActiveNav === NavType.NavSymbol;
    const nextYear = this.props.viewDate.clone().year() + 1;
    const maxDateYear = this.props.maxDate.clone().year();
    let nextYearStyle = null;
    if (nextYear > maxDateYear){ 
      nextYearStyle = { cursor: 'pointer', color: 'grey', pointerEvents: 'none' }
  }

    const prevYear = this.props.viewDate.clone().year() - 1;
    const minDateYear = this.props.minDate.clone().year();
    let prevYearStyle = null;
    if (prevYear < minDateYear){ 
      prevYearStyle = { cursor: 'pointer', color: '#787878' }
    }

    return (
      <div className="datepicker-days p-day-picker" style={{ display: "block" }}>
        <table className="table-condensed">
          <thead>
            <tr>
              <th className="blankSpace b-left" colSpan="1" style={{ cursor: "default" }}></th>
              <th className="prev small-normal" colSpan="1" style={{ cursor: "default" }}>
                <span />
              </th>


              <th className="switch" colSpan="3">
                <span className="prev small-normal" style={prevYearStyle} colSpan="1" onClick={this.props.subtractYear}>
                  <span className="glyphicon glyphicon-triangle-left" />
                </span>
                <input className="dateInput p-dateInput" type="text" onKeyPress={this.isNumberKey} maxLength="4" onKeyDown={this.props.handleYearChange} value={this.state.inputYear} onChange={this.changeInputYear} onClick={this.onClick} />
                <span className="next small-normal" style={nextYearStyle} colSpan="1" onClick={this.props.addYear}>
                  <span className="glyphicon glyphicon-triangle-right" />
                </span>
              </th>


              <th className="next small-normal" colSpan="2" style={{ cursor: "default" }}>
                <span />
              </th>

              {isNavSymbolTab && <th colSpan="" className="blankSpace action-image b-right">

                <span className={`pinImg${cls}`} onClick={this.handlePin}>
                  <span className="icn-pinImgPin" />
                </span>

                <span className="closeImg" onClick={this.handleClose}>
                  <span className="icn-closeImgClose" />
                </span>
              </th>}

            </tr>
            <tr>
              <th className="switch" colSpan="7"> <DateTimePickerMonths
                selectedDate={this.props.selectedDate}
                viewDate={this.props.viewDate}
                setViewMonth={this.props.setViewMonth}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
              />
              </th>
            </tr>
            {
              UserInfoUtil.hasChinaEntitlement() ? <tr>
                <th className="dow">{DaysTranlateHelper["Mo"]}</th>
                <th className="dow">{DaysTranlateHelper["Tu"]}</th>
                <th className="dow">{DaysTranlateHelper["We"]}</th>
                <th className="dow">{DaysTranlateHelper["Th"]}</th>
                <th className="dow">{DaysTranlateHelper["Fr"]}</th>
                <th className="dow">{DaysTranlateHelper["Sa"]}</th>
                <th className="dow">{DaysTranlateHelper["Su"]}</th>
              </tr> : <tr>
                  <th className="dow">{DaysTranlateHelper["Su"]}</th>
                  <th className="dow">{DaysTranlateHelper["Mo"]}</th>
                  <th className="dow">{DaysTranlateHelper["Tu"]}</th>
                  <th className="dow">{DaysTranlateHelper["We"]}</th>
                  <th className="dow">{DaysTranlateHelper["Th"]}</th>
                  <th className="dow">{DaysTranlateHelper["Fr"]}</th>
                  <th className="dow">{DaysTranlateHelper["Sa"]}</th>
                </tr>
            }
          </thead>
          <tbody>
            {this.renderDays()}
          </tbody>
        </table>
      </div>
    );
  }
}

DateTimePickerDays.propTypes = {
  viewDate: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  showToday: PropTypes.bool,
  daysOfWeekDisabled: PropTypes.array,
  setSelectedDate: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  addYear: PropTypes.func.isRequired,
  subtractYear: PropTypes.func.isRequired
}

DateTimePickerDays.defaultProps = {
  showToday: true
}