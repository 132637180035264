class TabFundamentalSettingHelper {

  constructor() {
  }

  updateReportPeriodicity(viewSettings, periodicity){
     viewSettings.StockViewSettings.Periodicity = periodicity;
  }

  updateReportType(viewSettings, reportType){
     viewSettings.StockViewSettings.ReportType = reportType;
  }

  updateReportSortOrder(viewSettings, sortOrder){
     viewSettings.StockViewSettings.SortOrder = sortOrder;
  }

  updateISGICSFlag(viewSettings, isGICSFlag){
    viewSettings.StockViewSettings.isGICSFlag = isGICSFlag;
 }

  updateReportFilter(viewSettings, filter){
     viewSettings.StockViewSettings.Filter = filter;
  }

  updateReportTemplate(viewSettings, template){
     viewSettings.StockViewSettings.Template = template;
  }

  updateEPSToggleState(viewSettings, ePSToggleState){
     viewSettings.StockViewSettings.EPSToggleState = ePSToggleState;
  }
}

const tabFundamentalSettingHelper = new TabFundamentalSettingHelper();
export default tabFundamentalSettingHelper;
