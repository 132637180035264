import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import validator from "validator";

class GetDuplicateListRequestES extends BaseServiceRequest {
    constructor(basicEsInfo, symCode, parentNodeId, name, sourceListId, destListId, cacheId, sortCols, isFav = false, isFlagList = false, spId = null, isDupFav= false) {
        super();
        super.addRequestUri("duplicateES.pb");
        if (isDupFav) {
            super.addPathParameter(basicEsInfo.actualListId.toString());
        }else{
            super.addPathParameter(sourceListId.toString());
        }
            if (symCode != '' && symCode != null) {
            basicEsInfo.igSymbol = symCode;
            super.addRequestParameter("sym", symCode);
        }
        super.addRequestParameter("name", name);
        super.addRequestParameter("parentnodeid", parentNodeId.toString());

        if (isDupFav) {
            basicEsInfo.actualListId = sourceListId;
            basicEsInfo.listId = sourceListId;
        }
        if (isFav) {
            super.addRequestParameter("cn", "1");
        }
        if (isFlagList) {
            super.addRequestParameter("flagSrc", "1");
        }
        if (sortCols != null && sortCols.length > 0) {
            sortParams = "";
            for (aDataItemId in Object.keys(sortCols)) {
                // sort params are in the format dataitem1,direction1;dateitem2,direction2
                // sample sort format: 410,0;411,-1;
                sortParams += aDataItemId + "," + (sortCols[aDataItemId] ? 0 : 1) + ";";
            }

            super.addRequestParameter("sort", sortParams);
        }

        if (!validator.isEmpty(destListId.toString())) {
            super.addRequestParameter("destinationlistid", destListId.toString());
        }

        super.addRequestParameter("isRemove", "0"); //drive it from undo boolean
        if (spId != "" && spId != null) {
            super.addRequestParameter("sid", spId.toString());
        }
        super.setContent(basicEsInfo.encode());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListExplorerNodeResponse";
    }
}

export default GetDuplicateListRequestES;
