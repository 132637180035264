import keyMirror from "keymirror";

export const CurrencyConversion = {
    ActionTypes: keyMirror({
        HANDLE_CURRENCY_DATA: null,
        HANDLE_DEFAULT_CURRENCY_INFO: null,
        HANDLE_IS_MATCH_FINANCIALS: null,
        HANDLE_IS_CURRENCY_CONVERSION_CHECKED: null,
        HANDLE_INIT_CURRENCY_SETTING: null,
        HANDLE_SELECTED_CURRENCY_CODE: null,

        SET_CURRENCY_DATA: null,
        SET_ES_CURRENCY_DATA: null,
        SET_DEFAULT_CURRENCY_SIGN: null,
        SET_INIT_CURRENCY_SETTING: null,
        SET_IS_CURRENCY_CONVERSION_CHECKED: null,
        SET_SELECTED_CURRENCY_CODE: null
    })
};