import Serializable from "Serializable";
export default class PTOEChartSettings extends Serializable {
    constructor() {
        super();
    }

    getDefaultPTOEIndexLine() {
        return true;
    }

    getDefaultHLEstimates() {
        return true;
    }

    // getDefaultPTOEStockLine() {
    //     return true;
    // }

    // getDefaultPTOEGroupLine() {
    //     return false;
    // }
    getDefaultseqNumber(){
        return 2;
    }
    getDefaultIndexColor() {
        const OverboughtObj = [{ isActive: false, color: "P4C4C4C999999", weight: 2 }];
        return OverboughtObj.map((item) => this.getSerizableObject(item));
    }

    getDefaultStockColor() {
        const OversoldObj = [{ isActive: true, color: "PFFCC995B1B00", weight: 1 }];
        return OversoldObj.map((item) => this.getSerizableObject(item));
    }

    // getDefaultGroupColor() {
    //     const OversoldObj = [{ isActive: false, color: "P33FFFF006666", weight: 1 }];
    //     return OversoldObj.map((item) => this.getSerizableObject(item));
    // }

    getSerizableObject(obj) {
        return Object.assign(new Serializable, { ...obj });
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
        this.Height = serializedValue.Height ? serializedValue.Height : 100;
        this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
        this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
        // this.PTOEStockLine = serializedValue.PTOEStockLine ? serializedValue.PTOEStockLine : this.getDefaultPTOEStockLine();
        // this.PTOEGroupLine = serializedValue.PTOEGroupLine ? serializedValue.PTOEGroupLine : this.getDefaultPTOEGroupLine();
        this.PTOEIndexLine = serializedValue.PTOEIndexLine !== undefined ? serializedValue.PTOEIndexLine : this.getDefaultPTOEIndexLine();
        this.HLEstimates = serializedValue.HLEstimates !== undefined ? serializedValue.HLEstimates : this.getDefaultHLEstimates();
        this.StockColor = serializedValue.StockColor ? serializedValue.StockColor : this.getDefaultStockColor();
        this.IndexColor = serializedValue.IndexColor ? serializedValue.IndexColor : this.getDefaultIndexColor();
        this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : this.getDefaultseqNumber();
        //this.GroupColor = serializedValue.GroupColor ? serializedValue.GroupColor : this.getDefaultGroupColor();
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            Height: this.Height,
            IsAvailable: this.IsAvailable,
            IsRelative: this.IsRelative,
            //  PTOEStockLine: this.PTOEStockLine,
            //  PTOEGroupLine: this.PTOEGroupLine,
            PTOEIndexLine: this.PTOEIndexLine,
            HLEstimates: this.HLEstimates,
            StockColor: this.StockColor,
            IndexColor: this.IndexColor,
            seqNumber: this.seqNumber
            //  GroupColor: this.GroupColor
        }
    }

    getDefaultPTOEChartSettings() {
        this.IsVisible = true;
        this.Height = 100;
        this.IsAvailable = true;
        this.IsRelative = false;
        // this.PTOEStockLine = this.getDefaultPTOEStockLine();
        // this.PTOEGroupLine = this.getDefaultPTOEGroupLine();
        this.PTOEIndexLine = this.getDefaultPTOEIndexLine();
        this.HLEstimates = this.getDefaultHLEstimates();
        this.StockColor = this.getDefaultStockColor();
        this.IndexColor = this.getDefaultIndexColor();
        //this.GroupColor = this.getDefaultGroupColor();
        this.seqNumber = this.getDefaultseqNumber();
        return this;
    }

    getObjectMapKey() {
        return "PTOEChartSettings";
    }
}
