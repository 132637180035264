import { connect } from 'react-redux';
import EventUtil from '../../../../Utils/EventUtil.js';
import KeyCodes from "KeyCodes";
import { onPlotSymbol } from '../../../../Actions/DatagraphActions.js';
import React from "react";
import VirtualList from "VirtualList";
import { getFundDetail, changeRelativeYTD, setActiveIndex, isDetailPage } from "../../../../Actions/RelatedInfoPanelActions/RiPanelActions";
import { IndicatorCommonTranslateHelper, RiPanelMangementInfoTransLateHelper, TranslateHelper } from "../../../../Utils/TranslateHelper";


class RiPanelManagementInfo extends React.Component {
    constructor(props) {
        super(props)
        this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
        this.changeRelative = this.changeRelative.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyPress, false);
    }

    onDocumentKeyPress(e) {
        if (document.body.classList.contains("changePassWordOpened") || (document.activeElement && document.activeElement.id === "SymbolEntryInput") || (document.activeElement && document.activeElement.id === "txtOwnershipReportFind")) {
            return;
        }
        const charCode = (e.which) ? e.which : e.keyCode;
        const list = this.props.ManagementData;
        let index = this.props.activeIndex;
        let item = null;
        if (!this.RIPanelManagementTeamList) {
            return;
        }
        if (charCode === KeyCodes.DOWN_ARROW) {
            if (list && list.length > 0) {
                if (index === list.length - 1) {
                    index = -1;
                }
                item = list[index + 1];
                this.RIPanelManagementTeamList.scrollToItemIndex(index + 1);
                this.props.getFundDetail(item, index + 1)
            }
            e.preventDefault();
            e.stopPropagation();
        }
        if (charCode === KeyCodes.UP_ARROW) {
            if (list && list.length > 0) {
                if (index === 0) {
                    index = list.length;
                }
                item = list[index - 1];
                this.RIPanelManagementTeamList.scrollToItemIndex(index - 1);
                this.props.getFundDetail(item, index - 1)
            }
            e.preventDefault();
            e.stopPropagation();
        }
    }

    getPhoto(item) {
        let photoView = <img alt="" />;
        if (item.photo) {
            photoView = <img alt="" src={item.photo} />
        } else {
            if (item.sex === "M") {
                photoView = <img alt="" src="/Asset/images/Male.png" />
            } else {
                photoView = <img alt="" src="/Asset/images/Female.png" />
            }
        }
        return photoView;
    }

    getPhotoIndicatorView(item) {
        let IndicatorView = <div className="management-item-photoIndicator"></div>;
        if (item.clientStatus === "Client") {
            if (item.totalOverDueDays === "-1") {
                IndicatorView = <div className="management-item-photoIndicator IndicatorBrush1"></div>
            } else {
                IndicatorView = <div className="management-item-photoIndicator IndicatorBrush2"></div>
            }

        } else {
            IndicatorView = <div className="management-item-photoIndicator IndicatorBrush3"></div>
        }
        return IndicatorView;
    }

    onClickBack() {
        this.props.isDetailPage(false)
    }

    changeRelative() {
        const changeIsRelative = this.props.IsRelativeYTD;
        this.props.changeRelativeYTD(!changeIsRelative);
    }

    setDetailItemActive(e, item) {
        if (!item.isActiveFund) {
            this.props.onPlotSymbol(item.fundTickerSymbol);
        }
    }

    getDetailItemRenderer(item, top, index) {
        if (item) {
            const positiveColor = {color: this.props.posDTColor}
            const negativeColor = {color: this.props.negDTColor}

            const tempStyleS = item.percentageChgYTD === 0 ? {} : item.percentageChgYTD > 0 ? positiveColor : negativeColor;
            const tempStyleR = item.relativeYTD === 0 ? {} : item.relativeYTD > 0 ? positiveColor : negativeColor;
            return (
                <div
                    className={item.isActiveFund ? "relatedFundList-item active medium-normal" : "relatedFundList-item medium-normal"}
                    key={index}
                    style={{ top: top }}>
                    <div className="relatedFundList-sponsorName" onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, item.sponsorName)}
                        onFocus={(e) => EventUtil.mouseOverShowTitle(e, item.sponsorName)}
                        onMouseDown={(e) => this.setDetailItemActive(e, item, index)}>{item.sponsorName}</div>
                    <div
                        className={this.props.IsRelativeYTD ? `relatedFundList-YTD ${item.YTDForegroundR}` : `relatedFundList-YTD ${item.YTDForegroundS}`}>
                        <div style={this.props.IsRelativeYTD ? tempStyleR : tempStyleS}>{this.props.IsRelativeYTD ? item.relativeYTDString : item.percentageChgYTDString}</div>
                    </div>
                    {item.isPassive && (item.isPassive ? <span style={{ color: '#CDCDCD', fontSize: '14px' }}>Passive</span> : "")}
                </div>
            )
        }
    }

    getManagerDetailView(managerDetailData) {
        if (managerDetailData) {
            const photoView = this.getPhoto(managerDetailData);
            const indicatorView = this.getPhotoIndicatorView(managerDetailData);
            const managementRelatedFundList = this.props.ManagementRelatedFundList ? this.props.ManagementRelatedFundList : [];
            return (
                <div className="managementInfo-right">
                    <div className="management-title">
                        {this.props.JumpDetailPage ? "" :
                            <span className="icn-back-button" onClick={this.onClickBack}>{TranslateHelper.Back}</span>}
                        {this.props.JumpDetailPage ? RiPanelMangementInfoTransLateHelper.MANAGER : <span className="title medium-normal">{RiPanelMangementInfoTransLateHelper.MANAGER}</span>}
                    </div>
                    <div className="manager-item">
                        <div className="management-item-photo">
                            {this.props.isFundViewEntitled && indicatorView ? indicatorView : ""}
                            {photoView}
                        </div>
                        <div className="management-item-rightPanel">
                            <div
                                className="white-text management-item-managerName medium-normal">{managerDetailData.managerName}</div>
                            <div className="management-item-jobTitle">{managerDetailData.jobTitle}</div>
                            <div className="management-item-companyName"
                                onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, managerDetailData.companyName)}
                                onFocus={(e) => EventUtil.mouseOverShowTitle(e, managerDetailData.companyName)}>{managerDetailData.companyName}</div>
                            <div
                                className="manager-item-bindingAgeString">{this.props.isFundViewEntitled ? managerDetailData.bindingAgeString : ""}</div>
                        </div>
                    </div>
                    <div className="management-title text-align-left">{RiPanelMangementInfoTransLateHelper.EDUCATION}</div>
                    <div className="manager-item"
                        onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, managerDetailData.biography)}
                        onFocus={(e) => EventUtil.mouseOverShowTitle(e, managerDetailData.biography)}>{managerDetailData.biography}</div>
                    <div className="management-title text-align-left">{RiPanelMangementInfoTransLateHelper.FUNDS_MANAGED}
                        <span className="YTDText xx-small-normal">YTD</span><span
                            className="fundRelativeLink xx-small-normal"
                            onClick={this.changeRelative}>{!this.props.IsRelativeYTD ? IndicatorCommonTranslateHelper.Absolute : IndicatorCommonTranslateHelper.Relative}</span>
                    </div>
                    <div className="management-content">
                        {this.props.loadingDetailPage ?
                            <div className="news-message large-normal"><p>{TranslateHelper["Loading"]}</p></div> : ""}
                        <VirtualList id="RIPanelManagementFundInfo" ref={(ref) => { this.RIPanelManagementTeamRelatedFundList = ref }}
                            items={managementRelatedFundList}
                            itemHeight={55}
                            itemRenderer={(item, top, index) => this.getDetailItemRenderer(item, top, index)}
                            showScrollOnHover={true}
                        />
                    </div>
                </div>
            )
        }
    }

    setItemActive(e, item, index) {
        this.props.setActiveIndex(index)
    }

    jumpDetailPage(item, index) {
        this.props.getFundDetail(item, index)
    }

    getItemRenderer(item, top, index) {
        const photoView = this.getPhoto(item);
        const IndicatorView = this.getPhotoIndicatorView(item);
        if (item) {
            return (
                <div className="managementTeam-item" onMouseDown={(e) => this.setItemActive(e, item, index)} key={index}
                    style={{ top: top }}>
                    <div className="management-item-photo">
                        {this.props.isFundViewEntitled ? IndicatorView : ""}
                        {photoView}
                    </div>
                    <div className="management-item-rightPanel">
                        <div className="management-item-managerName medium-normal"
                            onClick={this.jumpDetailPage.bind(this, item, index)}>{item.managerName}</div>
                        <div className="management-item-jobTitle">{item.jobTitle}</div>
                        <div className="management-item-companyName"
                            onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, this.props.isFundViewEntitled ? item.companyName : "")}
                            onFocus={(e) => EventUtil.mouseOverShowTitle(e, this.props.isFundViewEntitled ? item.companyName : "")}>{this.props.isFundViewEntitled ? item.companyName : ""}</div>
                        <div
                            className="management-item-bindingAgeString">{this.props.isFundViewEntitled ? item.bindingAgeString : ""}</div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const managementData = this.props.ManagementData;
        const managerDetailData = this.props.ManagerDetailData ? this.props.ManagerDetailData : null;
        const getManagerDetailView = this.getManagerDetailView(managerDetailData);
        return (
            <div className={this.props.IsDetailPage ? "managementInfo closed" : "managementInfo"}>
                    <div className="managementInfo-left">
                        {this.props.loading ?
                            <div className="news-message large-normal"><p>{TranslateHelper["Loading"]}</p></div> : managementData.length > 0 ?
                                <div className="management-title">{RiPanelMangementInfoTransLateHelper.MANAGEMENT_TEAM}</div> : ""}
                        {!this.props.loading ?
                            managementData.length > 0 ?
                                <div className="management-content">
                                    <VirtualList id="RIPanelManagementTeamInfo" ref={(ref) => { this.RIPanelManagementTeamList = ref }}
                                        items={managementData}
                                        itemHeight={90}
                                        itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                                        showScrollOnHover={true}
                                    />
                                </div>
                                :
                                <div className="management-content">
                                    <div className="news-message large-normal"><p>{TranslateHelper["No_Data_Available"]}</p></div>
                                </div> : ""}
                    </div>
                    {getManagerDetailView}
                </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { ManagementRelatedFundList, ManagementData, loading, Initialized, isFundViewEntitled, IsDetailPage, IsRelativeYTD,
        JumpDetailPage, activeIndex, ManagerDetailData, loadingDetailPage } = state.RelatedInfoPanelReducers.RiPanelManagementInfo;

    const { posDTColor, negDTColor } = state.appColor;
    return {
        ManagementData, loading, Initialized, isFundViewEntitled, IsDetailPage, IsRelativeYTD, JumpDetailPage, activeIndex,
        ManagerDetailData, loadingDetailPage, ManagementRelatedFundList, posDTColor, negDTColor

    }
}

const mapDispatchToProps = (dispatch) => ({
    getFundDetail: (item, index) => dispatch(getFundDetail(item, index)),
    changeRelativeYTD: (IsRelativeYTD) => dispatch(changeRelativeYTD(IsRelativeYTD)),
    setActiveIndex: (index) => dispatch(setActiveIndex(index)),
    isDetailPage: (isDetail) => dispatch(isDetailPage(isDetail)),
    onPlotSymbol: (symbol) => dispatch(onPlotSymbol(symbol))
})

export default connect(mapStateToProps, mapDispatchToProps)(RiPanelManagementInfo);