
export class LineDataModel {
    constructor() {
        this._index = 0;
        this.color = "";
        this.isVisible = true;
        this.label = "";
        this.localSymbol = "";
        this.id = "";
        this.isHighlighted = true;
        this.isFilter = false;
        this.isEditMode = false;
        this.indexLine = false;
        this.isUserCreated = false;
        this.dataPoints = [];
        this.isEyeIconVisible = true;
        this._trueVisibilty = this.isVisible && !this.isFilter;
        this.maxValue = Number.MAX_VALUE;
        this.minValue = Number.MIN_VALUE;
        this.lineSource = [];
        this.labelPos = { x: 0, y: 0 };
        this.perCHG = "";
        this.percValue = "";
        this.percentage = Number.MIN_VALUE;
        this.isSelected = true;

    }
    get index() {
        return this._index;
    }

    set index(value) {
        this._index = value;
        this.setLineColor(value);
    }

    setLineColor(value) {
        this.color = this.getLineColor(value);
    }

    getLineColor(count) {
        let lineColor = "";
        if (count >= 48) {
            count = count % 48;
        }
        switch (count + 1) {
            case 1:
                lineColor = "#1682EC";
                break;
            case 2:
                lineColor = "#028B6C";
                break;
            case 3:
                lineColor = "#06A000";
                break;
            case 4:
                lineColor = "#87D900";
                break;
            case 5:
                lineColor = "#E3AB00";
                break;
            case 6:
                lineColor = "#EA4602";
                break;
            case 7:
                lineColor = "#EC7702";
                break;
            case 8:
                lineColor = "#EF001B";
                break;
            case 9:
                lineColor = "#F00478";
                break;
            case 10:
                lineColor = "#E400EC";
                break;
            case 11:
                lineColor = "#6617F0";
                break;
            case 12:
                lineColor = "#783C00";
                break;
            case 13:
                lineColor = "#7DB8F6";
                break;
            case 14:
                lineColor = "#69BAA7";
                break;
            case 15:
                lineColor = "#6AC868";
                break;
            case 16:
                lineColor = "#EF9068";
                break;
            case 17:
                lineColor = "#F4AC62";
                break;
            case 18:
                lineColor = "#F67683";
                break;
            case 19:
                lineColor = "#F563AB";
                break;
            case 20:
                lineColor = "#EE68F4";
                break;
            case 21:
                lineColor = "#A679F4";
                break;
            case 22:
                lineColor = "#AE8B67";
                break;
            case 23:
                lineColor = "#114F90";
                break;
            case 24:
                lineColor = "#015441";
                break;
            case 25:
                lineColor = "#006100";
                break;
            case 26:
                lineColor = "#548300";
                break;
            case 27:
                lineColor = "#8F9100";
                break;
            case 28:
                lineColor = "#836300";
                break;
            case 29:
                lineColor = "#902A00";
                break;
            case 30:
                lineColor = "#904900";
                break;
            case 31:
                lineColor = "#83000E";
                break;
            case 32:
                lineColor = "#8B0045";
                break;
            case 33:
                lineColor = "#89008E";
                break;
            case 34:
                lineColor = "#400D90";
                break;
            case 35:
                lineColor = "#472301";
                break;
            case 36:
                lineColor = "#5785B4";
                break;
            case 37:
                lineColor = "#508B7D";
                break;
            case 38:
                lineColor = "#4C914A";
                break;
            case 39:
                lineColor = "#84AA4D";
                break;
            case 40:
                lineColor = "#B1B24E";
                break;
            case 41:
                lineColor = "#B19850";
                break;
            case 42:
                lineColor = "#B57051";
                break;
            case 43:
                lineColor = "#B78451";
                break;
            case 44:
                lineColor = "#B34E59";
                break;
            case 45:
                lineColor = "#B14D7D";
                break;
            case 46:
                lineColor = "#B255B6";
                break;
            case 47:
                lineColor = "#805EB6";
                break;
            case 48:
                lineColor = "#846D54";
                break;
            default:
                lineColor = "#000"
        }
        return lineColor;
    }

    set trueVisibilty(value = this.isVisible && !this.isFilter) {
        this._trueVisibilty = value;
    }

    get trueVisibilty() {
        this._trueVisibilty = this.isVisible && !this.isFilter;
        return this._trueVisibilty;
    }

    updatePercValues(scaleBasedValue = null) {
        if(this.dataPoints.length === 0) {
            this.percValue = Number.MIN_VALUE;
            this.percentage = "";
            this.perCHG = "";
            return;
        }
        const datapoint = this.dataPoints[0].Value;
        this.percentage = `${(datapoint - scaleBasedValue).toFixed(2)}%`;
        this.perCHG = `${(datapoint - scaleBasedValue).toFixed(2)}%`;
        this.percValue = datapoint - scaleBasedValue;
    }

    reset() {
        this.dataPoints = [];
        this.maxValue = Number.MIN_VALUE;
        this.minValue = Number.MAX_VALUE;
        this.lineSource = [];
    }
}

export class SymbolDataModel extends LineDataModel {

    constructor() {
        super();
        this.name = "";
        this.CloseResults = null;
        this.SymTypeEnum;
        this.GlobalDgRating;
        this.GlobalEpsRank;
        this.GlobalRsRating
        this.Accdis;
        this.DataGraphRating;
        this.EpsRank;
        this.RelativeStrength;
        this.MktCap;
        this.AvDolV;
        this.Osid;
    }
}

export class DataPoint {
    constructor() {
        this.Date = new Date();
        this.Value = 0;
        this.IsVisible = true;
    }
}
