import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class LogoutRequest extends BaseServiceRequest {

  constructor(userEmail) {
    super();
    super.addRequestUri("signout.pb");
    super.addRequestParameter("dt", "web");
    super.addRequestParameter("email", userEmail);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.USER;
  }

  getMessageName() {
    return "Response";
  }
}

export default LogoutRequest;
