import { ActionTypes } from "../Constants/AppColorConstants";
import AppDispatcher from "AppDispatcher";

export function onInitDarkThemeColor(posColor, negColor, posHoverColor, negHoverColor,
    posDTColor, negDTColor, posDTHoverColor, negDTHoverColor,
    theme, lang) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INIT_DARK_APP_COLOR,
            darkPosColor: posColor,
            darkNegColor: negColor,
            darkPosHoverColor: posHoverColor,
            darkNegHoverColor: negHoverColor,
            darkPosDTColor: posDTColor,
            darkNegDTColor: negDTColor,
            darkPosDTHoverColor: posDTHoverColor,
            darkNegDTHoverColor: negDTHoverColor,
            theme: theme,
            lang: lang,
            isOpen: false,
        })
    }
}

export function onInitLightThemeColor(posColor, negColor, posHoverColor, negHoverColor,
    posDTColor, negDTColor, posDTHoverColor, negDTHoverColor,
    theme, lang) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INIT_LIGHT_APP_COLOR,
            lightPosColor: posColor,
            lightNegColor: negColor,
            lightPosHoverColor: posHoverColor,
            lightNegHoverColor: negHoverColor,
            lightPosDTColor: posDTColor,
            lightNegDTColor: negDTColor,
            lightPosDTHoverColor: posDTHoverColor,
            lightNegDTHoverColor: negDTHoverColor,
            theme: theme,
            lang: lang,
            isOpen: false,
        })
    }
}

export function onDarkPosColorChanged(color, hoverColor, isOpen = false, isDefault = false) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CHANGE_DARK_POSITIVE_COLOR,
            darkPosColor: color,
            darkPosHoverColor: hoverColor,
            isOpen: isOpen,
            isDefault: isDefault,
        })
    };
}

export function onDarkNegColorChanged(color, hoverColor, isOpen = false, isDefault = false) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CHANGE_DARK_NEGATIVE_COLOR,
            darkNegColor: color,
            darkNegHoverColor: hoverColor,
            isOpen: isOpen,
            isDefault: isDefault
        })
    };
}


export function onLightPosColorChanged(color, hoverColor, isOpen = false, isDefault = false) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CHANGE_LIGHT_POSITIVE_COLOR,
            lightPosColor: color,
            lightPosHoverColor: hoverColor,
            isOpen: isOpen,
            isDefault: isDefault,
        })
    };
}

export function onLightNegColorChanged(color, hoverColor, isOpen = false, isDefault = false) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CHANGE_LIGHT_NEGATIVE_COLOR,
            lightNegColor: color,
            lightNegHoverColor: hoverColor,
            isOpen: isOpen,
            isDefault: isDefault
        })
    };
}

export function onToggleColorPalette(isOpen = false, posOrNeg, theme, lang, selectedColor) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOGGLE_COLOR_PALETTE,
            isOpen: isOpen,
            theme: theme,
            lang: lang,
            selectedColor: selectedColor,
            posOrNeg: posOrNeg,
        })
    };
}

export function onRestoreDarkColor(posColor, posHoverColor, negColor, negHoverColor, isDefault = true) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.RESTORE_DARK_COLOR,
            darkPosColor: posColor,
            darkNegColor: negColor,
            darkPosHoverColor: posHoverColor,
            darkNegHoverColor: negHoverColor,
            isDefault: isDefault,
        })
    }
}

export function onRestoreLightColor(posColor, posHoverColor, negColor, negHoverColor, isDefault = true) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.RESTORE_LIGHT_COLOR,
            lightPosColor: posColor,
            lightNegColor: negColor,
            lightPosHoverColor: posHoverColor,
            lightNegHoverColor: negHoverColor,
            isDefault: isDefault
        })
    }
}

export function onTogglePalettePanel(isOpen = false) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOGGLE_COLOR_PALETTE_PANEL,
            isOpen: isOpen
        });
    }
}
export const onToggleTheme = () => ({
    type: ActionTypes.CUSTOM_THEME_CHANGED,
})
export const onGridLineBrightnessChange = () => ({
    type: ActionTypes.CHANGE_GRID_LINE_COLOR
})

//this class is for notifying FLUX module. 
class AppColorActionClass {
    restoreDarkColor() {
        AppDispatcher.handleViewAction({
            actionType: ActionTypes.RESTORE_DARK_COLOR,
            data: {}
        });
    }

    restoreLightColor() {
        AppDispatcher.handleViewAction({
            actionType: ActionTypes.RESTORE_LIGHT_COLOR,
            data: {}
        });
    }

    customColorChanged = (theme, color, hoverColor, posOrNeg) => {
        AppDispatcher.handleViewAction({
            actionType: ActionTypes.CUSTOM_COLOR_CHANGED,
            data: { theme: theme, color: color, hoverColor: hoverColor, posOrNeg: posOrNeg }
        });
    }

    themeChanged = () => {
        AppDispatcher.handleViewAction({
            actionType: ActionTypes.CUSTOM_THEME_CHANGED,
            data: {}
        });

    }
}
const AppColorAction = new AppColorActionClass();
export default AppColorAction;

