import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class RemoveColumnSetRequest extends BaseServiceRequest {

    constructor(columnsetId) {
        super();
        super.addRequestUri("columnsets.pb");
        super.addRequestParameter("cid", columnsetId.toString());
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ColumnSetResponse";
    }
}

export default RemoveColumnSetRequest;
