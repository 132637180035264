import GraphType from "GraphType";
import PeriodicityHelper from "../../../../../Utils/PeriodicityHelper.js";
import CefViewSettings from "./CefViewSettings.js";
import IndexViewSettings from "./IndexViewSettings.js";
import EtfViewSettings from "./EtfViewSettings.js";
import IndustryGroupViewSettings from "./IndustryGroupViewSettings.js";
import CashSpotsViewSettings from "./CashSpotsViewSettings.js"
import FuturesViewSettings from "./FuturesViewSettings.js";
import CurrencyViewSettings from "./CurrencyViewSettings.js";
import CryptoCurrencyViewSettings from "./CryptoCurrencyViewSettings.js";
import FundsViewSettings from "./FundsViewSettings.js";
import Serializable from "Serializable";
import StockViewSettings from "./StockViewSettings.js";
import DefaultDataGraphSetting from "./DefaultDataGraphSettings.js";
import LocalizationStore from 'LocalizationStore';
import SmartViewType from "SmartViewType";
import { PrintMode } from '../../../../../print/printmode';
import BaseServiceApi from 'BaseServiceApi';
import { each } from "underscore";
import SymbolType from "../../../../../Constants/SymbolType.js";

class TabDatagraphSettings extends Serializable {

  constructor() {
    super();
    const appVideo = process.env.APP_VIDEO || "0";
    const videoMode = parseInt(appVideo) || 0;
    this.LeftScaleWidth = 42;
    this.RightScaleWidth = 115;
    this.videoMode = videoMode === 1;
    this.NodeWidth = this.videoMode ? 32 : 4;
  }
  getDefaultTabDatagraphSettings() {
    this.Periodicity = GraphType.Weekly;
    this.SelectedPeriodicityButton = 'Button3';
    this.StockViewSettings = this.getStockViewSettings();
    this.ETFViewSettings = this.getETFViewSettings();
    this.CEFViewSettings = this.getCEFViewSettings();
    this.IndexViewSettings = this.getIndexViewSettings();
    this.FUNDViewSettings = this.getFUNDViewSettings();
    this.CurrencyViewSettings = this.getCurrencyViewSettings();
    this.CryptoCurrencyViewSettings = this.getCryptoCurrencyViewSettings();
    this.IndustryGroupViewSettings = this.getIndustryGroupViewSettings();
    this.CashSpotsViewSettings = this.getCashSpotsViewSettings();
    this.FuturesViewSettings = this.getFuturesViewSettings();
    this.PeriodicityOptions = PeriodicityHelper.getDefaultPeriodcityButtons();
    this.userEndDate = null;
    this.useCanvas = false;
    this.priceChartDimension = Object.assign(new Serializable, { height: 0, width: 0});
    this.volumeChartDimension = Object.assign(new Serializable, { height: 0, width: 0});
    this.insiderChartDimension = Object.assign(new Serializable, { height: 0, width: 0});
    this.windowDimension = Object.assign(new Serializable, { height: 0, width: 0});
    return this;

  }

  onSeralisedInfoRecieved(serializedValue) {
    if(!this.LeftScaleWidth){
      this.LeftScaleWidth = 42;
    }
    if(!this.RightScaleWidth){
      this.RightScaleWidth = 115;
    }
    if(!this.videoMode){
      const appVideo = process.env.APP_VIDEO || "0";
      const videoMode = parseInt(appVideo) || 0;
      this.videoMode = videoMode === 1;
      this.NodeWidth = this.videoMode ? 32 : 4;
    }
    this.Periodicity = serializedValue.Periodicity ? serializedValue.Periodicity : GraphType.Weekly;
    this.SelectedPeriodicityButton = serializedValue.SelectedPeriodicityButton ? serializedValue.SelectedPeriodicityButton : this.getSelectedDefaultPeriodicityButton(serializedValue);
    this.PeriodicityOptions = this.getPeriodicityOptions(serializedValue)
    this.StockViewSettings = serializedValue.StockViewSettings ? serializedValue.StockViewSettings : this.getStockViewSettings();
    this.ETFViewSettings = serializedValue.ETFViewSettings ? serializedValue.ETFViewSettings : this.getETFViewSettings();
    this.CEFViewSettings = serializedValue.CEFViewSettings ? serializedValue.CEFViewSettings : this.getCEFViewSettings();
    this.IndexViewSettings = serializedValue.IndexViewSettings ? serializedValue.IndexViewSettings : this.getIndexViewSettings();
    this.FUNDViewSettings = serializedValue.FUNDViewSettings ? serializedValue.FUNDViewSettings : this.getFUNDViewSettings();
    this.CurrencyViewSettings = serializedValue.CurrencyViewSettings ? serializedValue.CurrencyViewSettings : this.getCurrencyViewSettings();
    this.CryptoCurrencyViewSettings = serializedValue.CryptoCurrencyViewSettings ? serializedValue.CryptoCurrencyViewSettings : this.getCryptoCurrencyViewSettings();
    this.IndustryGroupViewSettings = serializedValue.IndustryGroupViewSettings ? serializedValue.IndustryGroupViewSettings : this.getIndustryGroupViewSettings();
    this.CashSpotsViewSettings = serializedValue.CashSpotsViewSettings ? serializedValue.CashSpotsViewSettings : this.getCashSpotsViewSettings();
    this.FuturesViewSettings = serializedValue.FuturesViewSettings ? serializedValue.FuturesViewSettings : this.getFuturesViewSettings();
    this.userEndDate = serializedValue.userEndDate ? serializedValue.userEndDate : null;
    this.useCanvas = PrintMode.printing ? false : serializedValue.useCanvas ? serializedValue.useCanvas : false;
    // this.setOptionsForPeriodicity();
    this.priceChartDimension = serializedValue.priceChartDimension ? serializedValue.priceChartDimension : Object.assign(new Serializable, { height: 0, width: 0});
    this.volumeChartDimension = serializedValue.volumeChartDimension ? serializedValue.volumeChartDimension : Object.assign(new Serializable, { height: 0, width: 0});
    this.insiderChartDimension = serializedValue.insiderChartDimension ? serializedValue.insiderChartDimension : Object.assign(new Serializable, { height: 0, width: 0});
    this.windowDimension = serializedValue.windowDimension ? serializedValue.windowDimension : Object.assign(new Serializable, { height: 0, width: 0});
  }
  // This method is for backward compatibility
  getPeriodicityOptions(serializedValue){
    if(serializedValue.PeriodicityOptions && (typeof serializedValue.PeriodicityOptions.Button1 === 'string')){
      return serializedValue.PeriodicityOptions;
    }
    else if(serializedValue.PeriodicityOptions && (typeof serializedValue.PeriodicityOptions.Button1 === 'object')){
      return Object.assign(new Serializable, { Button4: serializedValue.PeriodicityOptions.Button4.Periodicity, Button3: serializedValue.PeriodicityOptions.Button3.Periodicity, Button2: serializedValue.PeriodicityOptions.Button2.Periodicity, Button1: serializedValue.PeriodicityOptions.Button1.Periodicity})
    }
    else{
      return PeriodicityHelper.getDefaultPeriodcityButtons();
    }
  }

  // This method is for backward compatibility
  getSelectedDefaultPeriodicityButton(serializedValue){
    let SelectedPeriodicityButton = 'Button3'
    if(serializedValue.PeriodicityOptions && (typeof serializedValue.PeriodicityOptions.Button1 === 'object')){
      Object.entries(serializedValue.PeriodicityOptions).forEach(([key, value])=>{
        if(value.IsSelected){
          SelectedPeriodicityButton = key;
        }
      })
    }
    return SelectedPeriodicityButton;
  }

  // setOptionsForPeriodicity() {
  //   this.PeriodicityOptions.Button1.options = this.getOptions(this.PeriodicityOptions.Button1);
  //   this.PeriodicityOptions.Button2.options = this.getOptions(this.PeriodicityOptions.Button2);
  //   this.PeriodicityOptions.Button3.options = this.getOptions(this.PeriodicityOptions.Button3);
  //   this.PeriodicityOptions.Button4.options = this.getOptions(this.PeriodicityOptions.Button4);
  // }

  // getOptions(buttonobject) {
  //   const menu = [];
  //   const periodicityHeaders = PeriodicityHelper.getValidPeriodicitiesHeader();;
  //   for (let header in periodicityHeaders) {
  //     if (periodicityHeaders.hasOwnProperty(header)) {
  //       const isActive = header === buttonobject.Periodicity;
  //       menu.push({
  //         header: this.translateButtonHeader(header),
  //         key: header,
  //         isActive: isActive,
  //         buttonObject: buttonobject
  //       });
  //     }
  //   }
  //   return menu;
  // }

  // translateButtonHeader(key) {
  //   let header;
  //   switch (key) {
  //     case GraphType.Daily: header = LocalizationStore.getTranslatedData('tp_per_dly', 'Daily'); break;
  //     case GraphType.Weekly: header = LocalizationStore.getTranslatedData('tp_per_wkly', 'Weekly'); break;
  //     case GraphType.Monthly: header = LocalizationStore.getTranslatedData('tp_per_mnly', 'Monthly'); break;
  //     case GraphType.Quarterly: header = LocalizationStore.getTranslatedData('tp_per_qly', 'Quarterly'); break;
  //     case GraphType.Annual: header = LocalizationStore.getTranslatedData('tp_per_aly', 'Annual'); break;
  //     case GraphType.Intraday1Min: header = LocalizationStore.getTranslatedData('tp_per_1min', '1 Min'); break;
  //     case GraphType.Intraday5Min: header = LocalizationStore.getTranslatedData('tp_per_5min', '5 Min'); break;
  //     case GraphType.Intraday10Min: header = LocalizationStore.getTranslatedData('tp_per_10min', '10 Min'); break;
  //     case GraphType.Intraday15Min: header = LocalizationStore.getTranslatedData('tp_per_15min', '15 Min'); break;
  //     case GraphType.Intraday30Min: header = LocalizationStore.getTranslatedData('tp_per_30min', '30 Min'); break;
  //     case GraphType.Intraday60Min: header = LocalizationStore.getTranslatedData('tp_per_60min', '60 Min'); break;
  //     default:
  //       break;
  //   }
  //   return header;
  // }

  getDataObject() {
    return {
      Periodicity: this.Periodicity,
      SelectedPeriodicityButton: this.SelectedPeriodicityButton,
      PeriodicityOptions: this.PeriodicityOptions,
      StockViewSettings: this.StockViewSettings,
      CEFViewSettings: this.CEFViewSettings,
      IndexViewSettings: this.IndexViewSettings,
      FUNDViewSettings: this.FUNDViewSettings,
      ETFViewSettings: this.ETFViewSettings,
      CurrencyViewSettings: this.CurrencyViewSettings,
      CryptoCurrencyViewSettings: this.CryptoCurrencyViewSettings,
      IndustryGroupViewSettings: this.IndustryGroupViewSettings,
      CashSpotsViewSettings: this.CashSpotsViewSettings,
      FuturesViewSettings: this.FuturesViewSettings,
      userEndDate: this.userEndDate,
      useCanvas: this.useCanvas,
      priceChartDimension: this.priceChartDimension,
      volumeChartDimension: this.volumeChartDimension,
      insiderChartDimension: this.insiderChartDimension,
      windowDimension: this.windowDimension
    };
  }

  getObjectMapKey() {
    return "TabDatagraphSettings";
  }

  getFUNDViewSettings() {
    var stockViewSettings = new FundsViewSettings();
    stockViewSettings.SummaryBlockSettings = DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    stockViewSettings.RsiChartSettings = DefaultDataGraphSetting.getRSiChartSetting();
    stockViewSettings.StochasticsChartSettings = DefaultDataGraphSetting.getStochasticsChartSetting();
    stockViewSettings.WonStochasticsChartSettings = DefaultDataGraphSetting.getWonStochasticsChartSetting();
    stockViewSettings.WonStochasticsUsageSettings = DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    stockViewSettings.MacdChartSettings = DefaultDataGraphSetting.getMacdChartSetting();
    stockViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getScaleLabelSettings();
    stockViewSettings.YTDChartSettings = DefaultDataGraphSetting.getYTDChartSetting();
    stockViewSettings.YTD1ChartSettings = DefaultDataGraphSetting.getYTD1ChartSetting();
    stockViewSettings.YTD3ChartSettings = DefaultDataGraphSetting.getYTD3ChartSetting();
    stockViewSettings.YTD5ChartSettings = DefaultDataGraphSetting.getYTD5ChartSetting();
    stockViewSettings.YTD10ChartSettings = DefaultDataGraphSetting.getYTD10ChartSetting();
    stockViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    stockViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting();
    stockViewSettings.RelativeStrenghtLine1Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1);
    stockViewSettings.RelativeStrenghtLine2Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(2);
    stockViewSettings.RelativeStrenghtLine1SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1);
    stockViewSettings.RelativeStrenghtLine2SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(2);
    stockViewSettings.IndexLineSettings = DefaultDataGraphSetting.getIndexLineSetting();
    stockViewSettings.IndexLineSettingsByPeriodicity = DefaultDataGraphSetting.getIndexLineSettingByPeriodicity();
    stockViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting(false);
    stockViewSettings.KpiSettings = DefaultDataGraphSetting.getKpiSetting();
    stockViewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.FUND);
    stockViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    return stockViewSettings;
  }

  getCEFViewSettings() {
    var stockViewSettings = new CefViewSettings();
    stockViewSettings.SummaryBlockSettings = DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    stockViewSettings.VolumeBlockSettings = DefaultDataGraphSetting.getDefaultVolumeBlockSettings(false);
    stockViewSettings.OwnershipBlockSettings = DefaultDataGraphSetting.getDefaultOwnershipBlockSettings();  
    stockViewSettings.RsiChartSettings = DefaultDataGraphSetting.getRSiChartSetting();
    stockViewSettings.TechChartSettings = DefaultDataGraphSetting.getTechChartSetting('CEF');
    stockViewSettings.StochasticsChartSettings = DefaultDataGraphSetting.getStochasticsChartSetting();
    stockViewSettings.WonStochasticsChartSettings = DefaultDataGraphSetting.getWonStochasticsChartSetting();
    stockViewSettings.WonStochasticsUsageSettings = DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    stockViewSettings.MacdChartSettings = DefaultDataGraphSetting.getMacdChartSetting();
    stockViewSettings.AccDistChartSettings = DefaultDataGraphSetting.getAccDistChartSetting(false);
    stockViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getScaleLabelSettings();
    stockViewSettings.YTDChartSettings = DefaultDataGraphSetting.getYTDChartSetting();
    stockViewSettings.YTD1ChartSettings = DefaultDataGraphSetting.getYTD1ChartSetting();
    stockViewSettings.YTD3ChartSettings = DefaultDataGraphSetting.getYTD3ChartSetting();
    stockViewSettings.YTD5ChartSettings = DefaultDataGraphSetting.getYTD5ChartSetting();
    stockViewSettings.YTD10ChartSettings = DefaultDataGraphSetting.getYTD10ChartSetting();
    stockViewSettings.CorporateEventsSettings = DefaultDataGraphSetting.getCorporateEventsSetting(true);
    stockViewSettings.PatternRecognitionSettings = DefaultDataGraphSetting.getPatternRecognitionSetting();
    stockViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    stockViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting();
    stockViewSettings.RelativeStrenghtLine1Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1, false);
    stockViewSettings.RelativeStrenghtLine2Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(2, false);
    stockViewSettings.RelativeStrenghtLine1SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1, false);
    stockViewSettings.RelativeStrenghtLine2SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(2, false);
    stockViewSettings.IndexLineSettings = DefaultDataGraphSetting.getIndexLineSetting(false);
    stockViewSettings.IndexLineSettingsByPeriodicity = DefaultDataGraphSetting.getIndexLineSettingByPeriodicity(false);
    stockViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    stockViewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.CEF);
    stockViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    return stockViewSettings;
  }

  getIndexViewSettings() {
    var viewSettings = new IndexViewSettings();
    viewSettings.SummaryBlockSettings = DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    viewSettings.VolumeBlockSettings = DefaultDataGraphSetting.getDefaultVolumeBlockSettings();
    viewSettings.RsiChartSettings = DefaultDataGraphSetting.getRSiChartSetting();
    viewSettings.EarningLineSetting = DefaultDataGraphSetting.getEstimateSettings();
    viewSettings.EPSLabelSettings = DefaultDataGraphSetting.getEPSLabelSettings();
    viewSettings.EPSMultiplierSettings = DefaultDataGraphSetting.getMultiplierSetting();
    viewSettings.StochasticsChartSettings = DefaultDataGraphSetting.getStochasticsChartSetting();
    viewSettings.WonStochasticsChartSettings = DefaultDataGraphSetting.getWonStochasticsChartSetting();
    viewSettings.WonStochasticsUsageSettings = DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    viewSettings.MacdChartSettings = DefaultDataGraphSetting.getMacdChartSetting();
    viewSettings.AccDistChartSettings = DefaultDataGraphSetting.getAccDistChartSetting(false);
    viewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getScaleLabelSettings();
    viewSettings.YTDChartSettings = DefaultDataGraphSetting.getYTDChartSetting();
    viewSettings.YTD1ChartSettings = DefaultDataGraphSetting.getYTD1ChartSetting();
    viewSettings.YTD3ChartSettings = DefaultDataGraphSetting.getYTD3ChartSetting();
    viewSettings.YTD5ChartSettings = DefaultDataGraphSetting.getYTD5ChartSetting();
    viewSettings.YTD10ChartSettings = DefaultDataGraphSetting.getYTD10ChartSetting();
    viewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    viewSettings.RelativeStrenghtLine1Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1);
    viewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting(false, true);
    viewSettings.RelativeStrenghtLine1Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1, false);
    viewSettings.RelativeStrenghtLine2Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(2, false);
    viewSettings.RelativeStrenghtLine1SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1, false);
    viewSettings.IndexLineSettings = DefaultDataGraphSetting.getIndexLineSetting();
    viewSettings.IndexLineSettingsByPeriodicity = DefaultDataGraphSetting.getIndexLineSettingByPeriodicity(false);
    viewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    viewSettings.EPSAnimationSettings = DefaultDataGraphSetting.getEPSAnimationSetting();
    viewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.INDEX);
    viewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    return viewSettings;
  }

  getETFViewSettings() {
    var stockViewSettings = new EtfViewSettings();
    stockViewSettings.SummaryBlockSettings = DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    stockViewSettings.VolumeBlockSettings = DefaultDataGraphSetting.getDefaultVolumeBlockSettings(false);
    stockViewSettings.OwnershipBlockSettings = DefaultDataGraphSetting.getDefaultOwnershipBlockSettings();
    stockViewSettings.RsiChartSettings = DefaultDataGraphSetting.getRSiChartSetting();
    stockViewSettings.TechChartSettings = DefaultDataGraphSetting.getTechChartSetting('ETF');
    stockViewSettings.StochasticsChartSettings = DefaultDataGraphSetting.getStochasticsChartSetting();
    stockViewSettings.WonStochasticsChartSettings = DefaultDataGraphSetting.getWonStochasticsChartSetting();
    stockViewSettings.WonStochasticsUsageSettings = DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    stockViewSettings.MacdChartSettings = DefaultDataGraphSetting.getMacdChartSetting();
    stockViewSettings.AccDistChartSettings = DefaultDataGraphSetting.getAccDistChartSetting(false);
    stockViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getScaleLabelSettings();
    stockViewSettings.YTDChartSettings = DefaultDataGraphSetting.getYTDChartSetting();
    stockViewSettings.YTD1ChartSettings = DefaultDataGraphSetting.getYTD1ChartSetting();
    stockViewSettings.YTD3ChartSettings = DefaultDataGraphSetting.getYTD3ChartSetting();
    stockViewSettings.YTD5ChartSettings = DefaultDataGraphSetting.getYTD5ChartSetting();
    stockViewSettings.YTD10ChartSettings = DefaultDataGraphSetting.getYTD10ChartSetting();
    stockViewSettings.CorporateEventsSettings = DefaultDataGraphSetting.getCorporateEventsSetting(true);
    stockViewSettings.PatternRecognitionSettings = DefaultDataGraphSetting.getPatternRecognitionSetting();
    stockViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    stockViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting();
    stockViewSettings.RelativeStrenghtLine1Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1, false);
    stockViewSettings.RelativeStrenghtLine2Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(2, false);
    stockViewSettings.RelativeStrenghtLine1SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1, false);
    stockViewSettings.RelativeStrenghtLine2SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(2, false);
    stockViewSettings.IndexLineSettings = DefaultDataGraphSetting.getIndexLineSetting(false);
    stockViewSettings.IndexLineSettingsByPeriodicity = DefaultDataGraphSetting.getIndexLineSettingByPeriodicity(false);
    stockViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    stockViewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.ETF);
    stockViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    return stockViewSettings;
  }

  getIndustryGroupViewSettings() {
    var igViewSettings = new IndustryGroupViewSettings();
    igViewSettings.SummaryBlockSettings = DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    igViewSettings.VolumeBlockSettings = DefaultDataGraphSetting.getDefaultVolumeBlockSettings();
    igViewSettings.RsiChartSettings = DefaultDataGraphSetting.getRSiChartSetting();
    igViewSettings.StochasticsChartSettings = DefaultDataGraphSetting.getStochasticsChartSetting();
    igViewSettings.WonStochasticsChartSettings = DefaultDataGraphSetting.getWonStochasticsChartSetting();
    igViewSettings.WonStochasticsUsageSettings = DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    igViewSettings.MacdChartSettings = DefaultDataGraphSetting.getMacdChartSetting();
    igViewSettings.AccDistChartSettings = DefaultDataGraphSetting.getAccDistChartSetting();
    igViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getScaleLabelSettings();
    igViewSettings.YTDChartSettings = DefaultDataGraphSetting.getYTDChartSetting();
    igViewSettings.YTD1ChartSettings = DefaultDataGraphSetting.getYTD1ChartSetting();

    igViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    igViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting(false,false,true);
    igViewSettings.RelativeStrenghtLine1Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1);
    igViewSettings.RelativeStrenghtLine2Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(2);
    igViewSettings.RelativeStrenghtLine1SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1);
    igViewSettings.RelativeStrenghtLine2SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(2);
    igViewSettings.IndexLineSettings = DefaultDataGraphSetting.getIndexLineSetting();
    igViewSettings.IndexLineSettingsByPeriodicity = DefaultDataGraphSetting.getIndexLineSettingByPeriodicity();
    igViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    igViewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.INDUSTRYGROUP);
    igViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    return igViewSettings;
  }

  getStockViewSettings(){
    var stockViewSettings = new StockViewSettings();
    stockViewSettings.SummaryBlockSettings = DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    stockViewSettings.FinancialBlockSettings = DefaultDataGraphSetting.getDefaultFinancialBlockSetting();
    stockViewSettings.OwnershipBlockSettings = DefaultDataGraphSetting.getDefaultOwnershipBlockSettings();
    stockViewSettings.VolumeBlockSettings = DefaultDataGraphSetting.getDefaultVolumeBlockSettings(false);
    stockViewSettings.InsiderBlockSettings = DefaultDataGraphSetting.getDefaultInsiderBlockSettings();
    stockViewSettings.EarningLineSetting = DefaultDataGraphSetting.getEstimateSettings(true);
    stockViewSettings.RevenueLineSetting = DefaultDataGraphSetting.getEstimateSettings(false);
    stockViewSettings.RsiChartSettings = DefaultDataGraphSetting.getRSiChartSetting();
    stockViewSettings.TechChartSettings = DefaultDataGraphSetting.getTechChartSetting();
    stockViewSettings.StochasticsChartSettings = DefaultDataGraphSetting.getStochasticsChartSetting();
    stockViewSettings.WonStochasticsChartSettings = DefaultDataGraphSetting.getWonStochasticsChartSetting();
    stockViewSettings.WonStochasticsUsageSettings = DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    stockViewSettings.MacdChartSettings = DefaultDataGraphSetting.getMacdChartSetting();
    stockViewSettings.AccDistChartSettings = DefaultDataGraphSetting.getAccDistChartSetting();
    stockViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getScaleLabelSettings();
    stockViewSettings.EPSLabelSettings = DefaultDataGraphSetting.getEPSLabelSettings();
    stockViewSettings.PTOEChartSettings = DefaultDataGraphSetting.getPTOEChartSetting();
    stockViewSettings.PTOSChartSettings = DefaultDataGraphSetting.getPTOSChartSetting();
    stockViewSettings.EPSRChartSettings = DefaultDataGraphSetting.getEPSRChartSetting();
    stockViewSettings.CorporateEventsSettings = DefaultDataGraphSetting.getCorporateEventsSetting(false);
    stockViewSettings.PatternRecognitionSettings = DefaultDataGraphSetting.getPatternRecognitionSetting();
    stockViewSettings.FactorResearchSettings = DefaultDataGraphSetting.getFactorResearchSetting()
    stockViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    stockViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting();
    stockViewSettings.RelativeStrenghtLine1Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1);
    stockViewSettings.RelativeStrenghtLine2Settings = DefaultDataGraphSetting.getRelativeStrenghtLineSetting(2);
    stockViewSettings.RelativeStrenghtLine1SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1);
    stockViewSettings.RelativeStrenghtLine2SettingsByPeriodicity = DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(2);
    stockViewSettings.IndexLineSettings = DefaultDataGraphSetting.getIndexLineSetting();
    stockViewSettings.IndexLineSettingsByPeriodicity = DefaultDataGraphSetting.getIndexLineSettingByPeriodicity();
    stockViewSettings.EPSMultiplierSettings = DefaultDataGraphSetting.getMultiplierSetting();
    stockViewSettings.RPSMultiplierSettings = DefaultDataGraphSetting.getMultiplierSetting();
    stockViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    stockViewSettings.LLRSettings = DefaultDataGraphSetting.getLLRSetting();
    stockViewSettings.T4Q0LineSetting = DefaultDataGraphSetting.getEstimateSettings(false, 0);
    stockViewSettings.T4Q1LineSetting = DefaultDataGraphSetting.getEstimateSettings(false, 1);
    stockViewSettings.T4Q2LineSetting = DefaultDataGraphSetting.getEstimateSettings(false, 2);
    stockViewSettings.T4Q3LineSetting = DefaultDataGraphSetting.getEstimateSettings(false, 3);
    stockViewSettings.T4Q0MultiplierSettings = DefaultDataGraphSetting.getMultiplierSetting(false);
    stockViewSettings.T4Q1MultiplierSettings = DefaultDataGraphSetting.getMultiplierSetting(false);
    stockViewSettings.T4Q2MultiplierSettings = DefaultDataGraphSetting.getMultiplierSetting(false);
    stockViewSettings.T4Q3MultiplierSettings = DefaultDataGraphSetting.getMultiplierSetting(false);
    stockViewSettings.KpiSettings = DefaultDataGraphSetting.getKpiSetting();
    stockViewSettings.EPSAnimationSettings = DefaultDataGraphSetting.getEPSAnimationSetting();
    stockViewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.STOCK);
    stockViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    stockViewSettings.StreetTargetPriceSettings = DefaultDataGraphSetting.getStreetTargetPriceSetting();
    return stockViewSettings;
  }
  getCurrencyViewSettings() {
    var currencyViewSettings = new CurrencyViewSettings();
    currencyViewSettings.SummaryBlockSettings = DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    currencyViewSettings.EarningLineSetting = DefaultDataGraphSetting.getEstimateSettings();
    currencyViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getCurrencyDefaultScaleLabelSettings();
    currencyViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    currencyViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting();
    currencyViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    currencyViewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings();
    currencyViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    return currencyViewSettings;
  }
  getCryptoCurrencyViewSettings() {
    var cryptoCurrencyViewSettings = new CryptoCurrencyViewSettings();
    cryptoCurrencyViewSettings.SummaryBlockSettings = DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    cryptoCurrencyViewSettings.EarningLineSetting = DefaultDataGraphSetting.getEstimateSettings();
    cryptoCurrencyViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getCurrencyDefaultScaleLabelSettings();
    cryptoCurrencyViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting(true);
    cryptoCurrencyViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting(true);
    cryptoCurrencyViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    cryptoCurrencyViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    return cryptoCurrencyViewSettings;
  }

  getCashSpotsViewSettings() {
    const cashSpotsViewSettings = new CashSpotsViewSettings();
    cashSpotsViewSettings.EarningLineSetting = DefaultDataGraphSetting.getEstimateSettings();
    cashSpotsViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getScaleLabelSettings(false,true);
    cashSpotsViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    cashSpotsViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting();
    cashSpotsViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    cashSpotsViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    cashSpotsViewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.CASHSPOTS);
    return cashSpotsViewSettings;
  }
  
  getFuturesViewSettings() {
    const futuresViewSettings = new FuturesViewSettings();
    futuresViewSettings.ScaleLabelSettings = DefaultDataGraphSetting.getScaleLabelSettings(false, true);
    futuresViewSettings.MASettings = DefaultDataGraphSetting.getMovingAverageSetting();
    futuresViewSettings.VolumeBlockSettings = DefaultDataGraphSetting.getDefaultVolumeBlockSettings(true);
    futuresViewSettings.OpenInterestSettings = DefaultDataGraphSetting.getDefaultOpenInterestSettings();
    futuresViewSettings.HiLoPriceSettings = DefaultDataGraphSetting.getHiLoPriceSetting();
    futuresViewSettings.HiLoPctSettings = DefaultDataGraphSetting.getHiLoPctSetting();
    futuresViewSettings.ExternalDataSettings = DefaultDataGraphSetting.getExternalDataSettings();
    futuresViewSettings.TiMenuSettings = DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.FUTURES);
    return futuresViewSettings;
  }

  prepareRSlineReqObj(tabDataGraphSettings) {
    const ProtoReq = BaseServiceApi.rayData["LineSettingsData"];
    const ProtoRequest = new ProtoReq();

    this.getLineSettings(tabDataGraphSettings.StockViewSettings, ProtoRequest, SymbolType.USSTOCK);
    this.getLineSettings(tabDataGraphSettings.CEFViewSettings, ProtoRequest, SymbolType.FundClosedEnd);
    this.getLineSettings(tabDataGraphSettings.ETFViewSettings, ProtoRequest, SymbolType.ETF);
    this.getLineSettings(tabDataGraphSettings.FUNDViewSettings, ProtoRequest, SymbolType.FUND);
    this.getLineSettings(tabDataGraphSettings.IndexViewSettings, ProtoRequest, SymbolType.INDEX);
    this.getLineSettings(tabDataGraphSettings.IndustryGroupViewSettings, ProtoRequest, SymbolType.WONINDUSTRYGROUP197);

    return ProtoRequest;
  }

  getLineSettings(settings, ProtoRequest, SymbolType) {
    each(settings, (data, key) => {
      let code = 0;
      
      if (key === "IndexLineSettings") {
        each(data, (linesettings, country) => {
          if (!isNaN(country) && linesettings.valueType !== "default") {
            if (linesettings.valueType === "group")
              code = this.getSelectedGroup(linesettings.selectedGroup);
              else code=0

            this.getProto(0, country, code, linesettings.IndexSymbol, ProtoRequest, SymbolType);
          }
        });
      }

      if (key === "RelativeStrenghtLine1Settings") {
        each(data, (linesettings, country) => {
          if (!isNaN(country) && linesettings.valueType !== "default") {
            if (linesettings.valueType === "group")
              code = this.getSelectedGroup(linesettings.selectedGroup);
              else code=0

            this.getProto(1, country, code, linesettings.IndexSymbol, ProtoRequest, SymbolType);
          }
        });
      }

      if (key === "RelativeStrenghtLine2Settings") {
        each(data, (linesettings, country) => {
          if (!isNaN(country) && linesettings.valueType !== "default") {
            if (linesettings.valueType === "group")
              code = this.getSelectedGroup(linesettings.selectedGroup);
              else code=0;

            this.getProto(2, country, code, linesettings.IndexSymbol, ProtoRequest, SymbolType);
          }
        });
      }

    });
  }

  getSelectedGroup(groupType) {
    switch (parseInt(groupType)) {
      case SymbolType.WONINDUSTRYGROUP197:
        return 197;
      case SymbolType.WONMAJORINDUSTRY89:
        return 89;
      case SymbolType.WONSECTOR11:
        return 11;
      default: return 0;
    }
  }

  getProto(lineType, countryCode, code, symbol, ProtoRequest, SymbolType) {
    const SubProtoReq = BaseServiceApi.rayData["LineSettings"];
    const SubProtoRequest = new SubProtoReq();

    SubProtoRequest.symbolTypeId = SymbolType;
    SubProtoRequest.lineType = lineType;
    SubProtoRequest.countryCode = parseInt(countryCode);
    SubProtoRequest.code = code;
    SubProtoRequest.symbol = symbol ? symbol : "";
    ProtoRequest.lineSettings.push(SubProtoRequest);
  }

}
export default TabDatagraphSettings;
