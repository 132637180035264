import { connect } from "react-redux";
import DateTimePicker from "./DateTimePicker.jsx";
import TimeTrackingWindow from "TimeTrackingWindow";
import { addSubtractYear, closeDatePicker, handleEventDateChange, onStepForwardInTime, onStepwardBackInTime, openDatePicker, setMostRecentTradeDate, setSelectedDate, updateCalenderControlProperties, updateCalenderIsPinned, updateCalenderViewMonth, updateCalenderViewYear, updateInputDate, updateInputDateValue, updateShowDatePicker } from "../../Actions/CalenderControl/CalenderControlAction";
import React, { Component } from "react";

class EventCalender extends Component {
  constructor(props) {
    super(props);
    this.handlePreventDefault = this.handlePreventDefault.bind(this);
  }

  handlePreventDefault(e) {
    e.preventDefault();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showPicker !== this.props.showPicker && this.dtpbutton) {
      if (this.props.showPicker) {
        this.dtpbutton.focus();
        this.dtpbutton.style.backgroundColor = "yellow";
        if (this.props.isEditMode && !this.props.isPinned) {
          this.dtpbutton.select();
        }
        this.dtpbutton.readOnly = false;
      }
      else {
        this.dtpbutton.blur();
        this.dtpbutton.style.backgroundColor = "";
        this.dtpbutton.readOnly = true;
      }
    }
    if(prevProps.showPicker === false && this.props.showPicker === true){
      document.body.classList.add('datagraphCalendarOpened');
    }
    else if(prevProps.showPicker === true && this.props.showPicker === false){
      document.body.classList.remove('datagraphCalendarOpened');
    }
  }

  render() {
    TimeTrackingWindow.trackChartLoadingTime();
    const pointerEvent = this.props.disableDateTimeField ? { pointerEvents: 'none', userSelect: 'none' } : { pointerEvents: 'all' }
    let stepForwardStyle, stepBackwardStyle;
    if (!this.props.isHistoric || this.props.isIntraday) {
      stepForwardStyle = { cursor: 'default', color: 'grey', opacity: ' 0.6', pointerEvents: 'none' };
    }

    if (this.props.isIntraday) {
      stepBackwardStyle = { cursor: 'default', color: 'grey', opacity: ' 0.6', pointerEvents: 'none' };
    }

    const isShowMostRecentDate = (this.props.isHistoric && !this.props.selectedDate.isSame(this.props.maxDate)) || this.props.selectedDate.isBefore(this.props.maxDate);

    return (
      <div className="DateTimePicker">
        {this.props.showPicker && <DateTimePicker
          addYear={this.props.addCalenderViewYear}
          daysOfWeekDisabled={this.props.daysOfWeekDisabled}
          maxDate={this.props.maxDate}
          minDate={this.props.minDate}
          ref={(r) => (this.widget = r)}
          selectedDate={this.props.selectedDate}
          setSelectedDate={this.props.setSelectedDate}
          setViewMonth={this.props.updateCalenderViewMonth}
          showDatePicker={this.props.showPicker}
          showToday={this.props.showToday}
          subtractYear={this.props.subtractCalenderViewYear}
          viewDate={this.props.viewDate}
          widgetClasses={this.props.widgetClasses}
          widgetStyle={this.props.widgetStyle}
          handleYearChange={this.props.updateCalenderViewYear}
          handlePinState={this.props.updateCalenderIsPinned}
          showPicker={this.props.showPicker}
          keydates={this.props.keyDates}
          handleClick={this.props.handleEventDateChange}
          closePicker={this.props.closeDatePicker}
          isMostRecentTradeDayTb={isShowMostRecentDate}
          setMostRecentTradeDate={this.props.setMostRecentTradeDate}
          blockOutDates={this.props.blockOutDates}
        />}

        <div className={`input-group date ${this.props.isHistoric ? "historicalBg" : ""}`} ref={(r) => (this.datetimepicker = r)} style={pointerEvent}>
          <div className="inputWrap">
            <i className="icn-leftBtn" ref={(r) => (this.leftBtn = r)} style={stepBackwardStyle} onClick={this.props.onStepwardBackInTime}></i>
            <input className="form-control customDatepicker" id="customDatepicker" onChange={this.props.updateInputDateValue} onKeyDown={this.props.updateInputDate} onMouseUp={this.props.openDatePicker} type="text" ref={(r) => (this.dtpbutton = r)} value={this.props.inputValue} {...this.props.inputProps} onPaste={this.handlePreventDefault} onCopy={this.handlePreventDefault} onCut={this.handlePreventDefault} readOnly={true} />
            <i className="icn-rightBtn" style={stepForwardStyle} onClick={this.props.onStepForwardInTime}></i>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateCalenderViewMonth: (month) => dispatch(updateCalenderViewMonth(month)),
  addCalenderViewYear: () => dispatch(addSubtractYear(1)),
  subtractCalenderViewYear: () => dispatch(addSubtractYear(-1)),
  updateCalenderViewYear: (event) => dispatch(updateCalenderViewYear(event)),
  updateCalenderIsPinned: (isPinned) => dispatch(updateCalenderIsPinned(isPinned)),
  updateInputDateValue: (event) => dispatch(updateInputDateValue(event.target.value)),
  updateShowDatePicker: (showPicker) => dispatch(updateShowDatePicker(showPicker)),
  updateCalenderControlProperties: (properties) => dispatch(updateCalenderControlProperties(properties)),
  setSelectedDate: (event) => dispatch(setSelectedDate(event)),
  openDatePicker: () => dispatch(openDatePicker()),
  updateInputDate: (event) => dispatch(updateInputDate(event)),
  closeDatePicker: (isClose, event) => dispatch(closeDatePicker(isClose, event)),
  setMostRecentTradeDate: () => dispatch(setMostRecentTradeDate()),
  onStepForwardInTime: () => dispatch(onStepForwardInTime()),
  onStepwardBackInTime: () => dispatch(onStepwardBackInTime()),
  handleEventDateChange: (date) => dispatch(handleEventDateChange(date))
})
const mapStateToProps = (state) => {
  const { isHistoric } = state.DatagraphReducers.DataGraphReducer
  const { minDate, maxDate, dateTime, format, inputFormat, daysOfWeekDisabled, equityEvents, blockOutDates, showToday, viewDate, selectedDate, isPinned, inputValue, showPicker, widgetStyle, widgetClasses, isEditMode, disableDateTimeField, isIntraday, browserFormat } = state.DatagraphReducers.CalenderControlReducer;
  const keyDates = equityEvents[viewDate.year()];
  return { minDate, maxDate, dateTime, format, inputFormat, daysOfWeekDisabled, blockOutDates, showToday, viewDate, selectedDate, keyDates, isPinned, inputValue, showPicker, widgetStyle, widgetClasses, isEditMode, isHistoric, disableDateTimeField, isIntraday, browserFormat }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventCalender)
