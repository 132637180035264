import { ConsoleConstants } from "Constants/ConsoleConstants.js";
import ConsoleStore from "ConsoleStore";
import DataRow from "./DataRow.jsx";
import Measure from "react-measure";
import { PrintMode } from "../../../../../print/printmode";
import PropTypes from 'prop-types';
import { SettingsConstants } from "Constants/SettingsConstants";
import SettingsStore from "SettingsStore";
import ThemeHelper from "ThemeHelper";
import React, { Component } from "react";

let shouldHandleResize = true;
export default class TabularData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: props.rows,
      rowHeight: props.rowHeight,
      nodeWidth: props.nodeWidth,
      dimensions: { width: 0, height: 0 }
    };
    this._dataRow = new Map();
  }

  UNSAFE_componentWillMount = () => {
    if (PrintMode.printing) {
      ConsoleStore.addChangeListener(this.ConsoleStateChange);
      SettingsStore.addChangeListener(this.SettingsStoreChange);
    }
  }

  componentWillUnmount() {
    if (PrintMode.printing) {
      ConsoleStore.removeChangeListener(this.ConsoleStateChange);
      SettingsStore.removeChangeListener(this.SettingsStoreChange);
      shouldHandleResize = true;
    }
  }

  ConsoleStateChange() {
    const currentAction = ConsoleStore.getCurrentAction();
    if (currentAction === ConsoleConstants.ActionTypes.SET_CHART_LOADED) {
      shouldHandleResize = false;
    }
  }

  SettingsStoreChange() {
    const currentAction = SettingsStore.getCurrentAction();
    if (currentAction === SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT) {
      shouldHandleResize = true;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rows !== undefined) {
      this.setState({ rows: nextProps.rows });
    }
  }
  SetDimensions(dimensions) {
    if (shouldHandleResize) {
      this.setState({ dimensions: dimensions });
    }
  }

  drawGrid() {
    const rows = this.state.rows;
    if (!this.grid || !rows || rows === null ||
      rows.length === 0 || !this.state.dimensions ||
      this.state.dimensions.height === 0.0 || this.state.dimensions.width === 0.0
    ) {
      return;
    }

    const width = this.state.dimensions.width;
    const rowHeight = this.state.rowHeight;
    const ctx = this.grid.getContext("2d");
    ctx.canvas.height = this.state.dimensions.height;
    ctx.canvas.width = this.state.dimensions.width;
    ctx.lineWidth = 1;
    ctx.imageSmoothingEnabled = true;
    ctx.translate(0.5, 0.5);
    ctx.save();
    ctx.strokeStyle = ThemeHelper.getThemedBrush("financialDividerLines");
    ctx.fillStyle = ThemeHelper.getThemedBrush("zebraStripeColor");
    ctx.beginPath();
    this.getVericalGrid(rows[0], ctx);
    const length = rows.length;
    for (let i = 0; i < length; i++) {
      ctx.moveTo(0, (i * rowHeight));
      ctx.lineTo(width, (i * rowHeight));
    }
    ctx.stroke();
    ctx.closePath();

  }

  getVericalGrid(row, ctx) {
    let mergeFactor = 0;
    const mergeItemCount = 1;
    let zebraStripeIndex = 0;
    const height = this.state.dimensions.height + 2;
    const width = this.state.dimensions.width;
    ctx.beginPath();
    const cellitems = row.cellItems;
    const length = cellitems.length;
    for (let i = 0; i < length; i++) {
      let isZebraStripe = false;
      const cellItem = cellitems[i];
      if (mergeFactor > 0) {
        mergeFactor--;
        continue;
      }
      isZebraStripe = zebraStripeIndex % 2 === 1;
      const xAxis = (width - (cellItem.XPoint * this.state.nodeWidth));
      if (isZebraStripe) {
        const isWithIndex = i - 1 >= 0;
        if (isWithIndex) {
          const previousCell = cellitems[i - mergeItemCount];
          const previousCellXAxis = (width - (previousCell.XPoint * this.state.nodeWidth));
          ctx.fillRect(xAxis, 0.0, (previousCellXAxis - xAxis), height);
        }
      }
      zebraStripeIndex++;

      ctx.moveTo(xAxis, 0);
      ctx.lineTo(xAxis, height);
    }
    ctx.stroke();
    ctx.closePath();
  }

  onHover(index, hover) {
    const row = this._dataRow.get(index);
    if (row) {
      row.onHover(hover);
    }
  }

  syncHover(index, hover) {
    this.props.syncHover(index, hover);
  }
  onToggleHeader(index) {
    const row = this._dataRow.get(index);
    if (row) {
      row.reDraw();
    }
  }
  render() {
    try {

      this.drawGrid();
      // var gridLines = grid.grid;
      // var zebraStripe = grid.zebraStripe;
    }
    catch (e) {
      console.error(e);
    }

    return (
      <Measure bounds onResize={(contentRect) => { this.SetDimensions(contentRect.bounds) }}>
        {({ measureRef }) =>
          <div className="financial-block-holder" ref={measureRef}>
            <canvas className="financial-block-data-canvas" ref={(ref) => { this.grid = ref }}></canvas>
            <div className="financial-block-data-hold" id="data">
              {this.state.rows.map((row, index) => {
                const key = `datarow${index}`;
                return <DataRow ref={(c) => this._dataRow.set(index, c)}
                  row={row}
                  key={key}
                  syncHover={(rIndex, hover) => this.syncHover(rIndex, hover)}
                  width={this.state.dimensions.width}
                  nodeWidth={this.state.nodeWidth}
                  rowHeight={this.state.rowHeight}
                />;
              })}
            </div>
          </div>
        }
      </Measure>
    );
  }
}
TabularData.propTypes = {
  rows: PropTypes.array,
  rowHeight: PropTypes.number.isRequired,
  nodeWidth: PropTypes.number.isRequired,
  syncHover: PropTypes.func.isRequired
};