import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import { MiniListConstants } from "MiniListConstants";
import ListManagerTabType from "ListManagerTabType";
import ListStore from "ListStore";
import GridStore from "GridStore";
import LocalizationStore from 'LocalizationStore';
import ContextMenuConstants from "ContextMenuConstants";
import { OwnershipViewConstants } from "../../../../Constants/OwnershipConstants";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import { map } from "underscore";
// import DatagraphStore from "../../NavDataGraph/DataGraphStore.js";
import TabDataGraphStore from "../../../../Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore.js";
const CHANGE_EVENT = "change";
let _state = {
  listItems: [], listName: "", selectedIndex: [], previousIndex: [],
  showContextMenu: false, showAlert: false, listId: 0, listCategoryType: 0,
  playInterval: 5,
  isMiniListItemClicked: false,
  ActiveSymbol:"",
  ActiveSymbolStartTime: null
};
let _tempActiveItem = null;
let _activeItem = null;
let _previousActiveItem = null;
let _currentAction = null;
class MiniListStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    if(!_state){
      _state = {
        listItems: [], 
        listName: "", 
        selectedIndex: [], 
        previousIndex: [],
        showContextMenu: false, 
        showAlert: false, 
        listId: 0, 
        listCategoryType: 0,
        playInterval: 5,
        isMiniListItemClicked: false,
        ActiveSymbol:"",
        ActiveSymbolStartTime: null
      };
    }

    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    _state.playInterval = navListManagerSettings.MiniListPlayInterval;

    if (this.getSelectedTab() === ListManagerTabType.Oneil)
      return _state;
    else {
      let state = { ..._state };
      state.previousIndex = [];
      state.selectedIndex = [];
      return state;
    }
  }

  updateActivePlaySymbol(ActiveSymbol){
    _state.isMiniListItemClicked = true;
    _state.ActiveSymbol = ActiveSymbol;
    _state.ActiveSymbolStartTime = new Date();
  }

  setIsMiniListPaly(isMiniListPlay){
    _state.isMiniListPlay = isMiniListPlay;
  }

  getMiniListPlayingStatus() {
    return _state.isMiniListPlay;
  }

  setSymbolInfo(symbolInfo){
    _state.symbolInfo = symbolInfo;
  }

  getCurrentAction(){
    return _currentAction;
  }

  setCurrentAction(action){
    _currentAction = action;
  }

  clearCurrentAction(){
    _currentAction = null;
  }

  setPreviouseIndex(listName, index, activeItem) {
    if (listName === _state.listName) {
      _state.previousIndex = index;
      _activeItem = activeItem;
    }

  }

  getSelectedTab(){
    return SettingsStore.getConsoleSettings().NavListManagerSettings.SelectedTabKey;
  }

  setSelectedRowIndexs(indexes) {
      if (indexes.length > 0 && this.getSelectedTab() === ListManagerTabType.Oneil) {
        _state.selectedIndex = indexes;
        _state.previousIndex = [indexes[0]];
      }
      }

      clearSelectedRowIndexes(){
        if (this.getSelectedTab() === ListManagerTabType.Oneil) {
          _state.selectedIndex = [];
          _state.previousIndex = [];
        }
      }

  updateTempNextActiveItem(nextAvailableItem) {
    _tempActiveItem = nextAvailableItem;
  }

  updateNextActiveItem() {
    this.updateActiveItem(_tempActiveItem);
    _tempActiveItem = null;
  }

  updateActiveItem(item) {
    if (!(_activeItem && item) || (item.msId !== _activeItem.msId)) {
      _previousActiveItem = _activeItem;
    }
    _activeItem = item;
    if (item) {
      ListStore.setSelectedSymbol(item.symbol);
    }
  }

  updateSelectedRowIndexs(indexes) {
    if(this.getSelectedTab() === ListManagerTabType.Oneil){
    _state.selectedIndex = indexes;
    _state.previousIndex = [indexes[0]];
    GridStore.setSelectedIndexes(indexes, true);
  }
  }

  setMiniList(source, listState, isUndo = false) {
    let listName = listState.SelectedListName;
    let listId = listState.SelectedListId;
    let listCategoryType = listState.SelectedCategoryType;
    _state.listId = listId;
    _state.listCategoryType = listCategoryType;
    this.clearMiniList();      
    map(source, (item) => {      
      if (item.symbol != "" && item.symbol != undefined) {
        _state.listItems.push(item);
      }
    });
    //_state.listItems = source;
    if (_state.listName != listName) {
      _state.previousIndex = [];
    }
    let symbol
    if (isUndo && _previousActiveItem) {
      symbol = _previousActiveItem.symbol;
    }else{
      symbol = _activeItem ? _activeItem.symbol : ListStore.getSelectedSymbol();
    }
    if (symbol && _currentAction !== MiniListConstants.ActionTypes.ON_ADD_SYMBOL_COMPLETE) {
      map(_state.listItems, (item) => {
        item.isActive = false;
        if (item.symbol == symbol) {
          item.isActive = true;
          this.updateActiveItem(item);
        }
      });
    }
    _state.listName = listName ? listName : this.getListNameFromSettings();
    this.emit(CHANGE_EVENT);
  }

  get listItemsLength(){
    return _state.listItems ? _state.listItems.length : 0; 
  }

  getActiveItem() {
    return _activeItem;
  }


  clearActiveItem() {
    if (_activeItem) {
        _activeItem = null;
    }
  }

  updateMiniList(sym) {
    this.clearActiveItem();
    map(_state.listItems, (item, i) => {
      item.isActive = false;
      if (item.symbol == sym) {
        _state.previousIndex = [i];
        item.isActive = true;
        this.updateActiveItem(item);
      }
    });
    this.emit(CHANGE_EVENT);
  }

  updatePlayInterval(playInterval){
    _state.playInterval = playInterval;
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    navListManagerSettings.MiniListPlayInterval = playInterval;
    SettingsStore.saveSettings();
  }

  getListNameFromSettings() {
    let settings = SettingsStore.getConsoleSettings();
    let navListManagerSettings = settings.NavListManagerSettings;
    let selectedListInfo;
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
      selectedListInfo = navListManagerSettings.TabONeilSettings.SelectedListInfo;
      return selectedListInfo.SelectedListName;
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      selectedListInfo = navListManagerSettings.TabOwnershipSettings.SelectedListInfo;
      return selectedListInfo.SelectedListName;
    }
    else return '';
  }
  getConsoleSettings() {
    return SettingsStore.getConsoleSettings();
  }

  clearMiniList(){
    _state.listItems = [];
  }

  getMiniListContextMenu() {
    let contextMenu = [];
    let settings = SettingsStore.getConsoleSettings();
    let navListManagerSettings = settings.NavListManagerSettings;    
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
      if (this.isContextMenuEnable()) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('delete', ContextMenuConstants.DELETE), action: ContextMenuConstants.DELETE });
      }
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      if (this.isContextMenuEnableOwnership()) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('delete', ContextMenuConstants.DELETE), action: ContextMenuConstants.DELETE });
      }
    }
    return contextMenu;
  }

  isContextMenuEnable() {
    if(!ONeilViewStore){
        return false;
    }

    if(ONeilViewStore.isAPLIST() || ONeilViewStore.isSharedListWriteMode()){
      return true;
    }

    let settings = SettingsStore.getConsoleSettings();
    let navListManagerSettings = settings.NavListManagerSettings;    
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
      return ONeilViewStore && ONeilViewStore.getState() && ONeilViewStore.getState().basicEsInfo && ONeilViewStore.getState().basicEsInfo.isUserList;
    }
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      if (this.isContextMenuEnableOwnership()) {
          return true;
      }
    }
    return false;
  }

  isHistoric(){
    let isHistoric = false;

    const symbolInfo = TabDataGraphStore.getSymbolInfo();
    if (symbolInfo && symbolInfo.LastTradeDate) {
      isHistoric = symbolInfo.isHistoric;
    }

    return isHistoric;
  }
  
  isAddSymbolEnable() {
    let isHistoric = this.isHistoric();
    if(isHistoric){
      return false;
    }

    return GridStore.isAddSymbolEnableList();
  }

  isRemoveSymbolEnable() {
    let isHistoric = this.isHistoric();
    if(isHistoric){
      return false;
    }

    return GridStore.isAddSymbolEnableList();
  }

  isContextMenuEnableOwnership() {
    return OwnershipViewStore && OwnershipViewStore.getState() && OwnershipViewStore.getState().basicEsInfo && OwnershipViewStore.getState().basicEsInfo.isUserList;
  }

  setMiniListNextInterval(){
    this.setCurrentAction(MiniListConstants.ActionTypes.SET_MINILIST_NEXTINTERVAL);
    this.emit(CHANGE_EVENT);
  }

  onMiniListPlaying(){
    this.setCurrentAction(MiniListConstants.ActionTypes.ON_MINILIST_PLAYING);
    this.emit(CHANGE_EVENT);
  }

  updateHistoric(){
    this.setCurrentAction(MiniListConstants.ActionTypes.ON_UPDATE_UPDATEHISTORIC);
    this.emit(CHANGE_EVENT);
  }

  clearMiniListPlayTimeout(){
    this.setCurrentAction(MiniListConstants.ActionTypes.CLEAR_MINILISTPLAY_TIMEOUT);
    this.emit(CHANGE_EVENT);
  }

  dispatcherCallback(payload) {
    AppDispatcher.waitFor([SettingsStore.dispatchToken]);
    const action = payload.action;
    switch (action.actionType) {
      case OwnershipViewConstants.ActionTypes.ON_DATA_LOADING_COMPLETE_OWNERSHIP:
      case ListExplorerConstants.ActionTypes.ON_DATA_LOADING_COMPLETE:
        this.setCurrentAction(MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE);
        const settings = SettingsStore.getConsoleSettings();
        const navListManagerSettings = settings.NavListManagerSettings;
        let listState = action.data.listState;
        if(navListManagerSettings.SelectedTabKey == ListManagerTabType.External){
          listState = ListStore.getSelectedListItem(navListManagerSettings.SelectedTabKeyMiniList)
         }
        if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil || (navListManagerSettings.SelectedTabKey == ListManagerTabType.External && navListManagerSettings.SelectedTabKeyMiniList == ListManagerTabType.Oneil)) {
          this.setMiniList(ONeilViewStore.getSourceData(), listState);
        }else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership || (navListManagerSettings.SelectedTabKey == ListManagerTabType.External && navListManagerSettings.SelectedTabKeyMiniList == ListManagerTabType.Ownership)){
          this.setMiniList(OwnershipViewStore.getSourceData(), listState);
        }
        // this.emit(CHANGE_EVENT);
        break;
      case ListExplorerConstants.ActionTypes.ON_ADD_SYMBOL_COMPLETE:
        this.setCurrentAction(MiniListConstants.ActionTypes.ON_ADD_SYMBOL_COMPLETE);
        this.setMiniList(action.data.source, action.data.listState);
        // this.emit(CHANGE_EVENT);
        break;
      case OwnershipViewConstants.ActionTypes.UPDATE_MINI_LIST_OWNERSHIP:  
      case ListExplorerConstants.ActionTypes.UPDATE_MINI_LIST:
        this.setCurrentAction(MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE);
        this.updateMiniList(action.data.symbol);
        break;
      case ListExplorerConstants.ActionTypes.SET_MINILIST_ACTION:
        _state.isMiniListItemClicked = action.data.isMiniListItemClicked;
        this.setCurrentAction(MiniListConstants.ActionTypes.ON_INTERNAL_CHANGE);
        this.emit(CHANGE_EVENT);
        break;
      case ListExplorerConstants.ActionTypes.LIST_RENAME_OWNERSHIP:
      case ListExplorerConstants.ActionTypes.LIST_RENAME_OENIL:
        const listName = action.data && action.data.listName;
        if (listName) {
          _state.listName = listName
          this.setCurrentAction(MiniListConstants.ActionTypes.LIST_NAME_UPDATE);
          this.emit(CHANGE_EVENT);
        }
        break;
      default:
        break;
    }
  }
}

const miniListStore = new MiniListStore();
export default miniListStore;
