class SplitUtil {
    DecToString(value) {
        var DecimalInc =
            [
                1.10, 1.111, 1.20, 1.25, 1.333, 1.40, 1.50, 1.60, 1.70, 1.75, 1.800,
                2.00, 2.25, 2.50, 3.00, 3.50, 4.00, 4.500, 5.00, 5.50, 6.00,
                6.50, 7.00, 7.50, 8.00, 8.50, 9.00, 9.500, 10.00, 11.00, 12.00, 99.00
            ];
        var FractionInc =
            [
                "11/10", "10/9", "6/5", "5/4", "4/3", "7/5", "3/2", "8/5", "17/10", "7/4", "9/5",
                "2/1", "9/4", "5/2", "3/1", "7/2", "4/1", "9/2", "5/1", "11/2", "6/1",
                "13/2", "7/1", "15/2", "8/1", "17/2", "9/1", "19/2", "10/1", "11/1", "12/1", "   "
            ];
        var DecimalDec =
            [
                1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6,
                6.5, 7, 7.5, 8, 8.5, 9, 10, 12, 12.5, 20
            ];
        var FractionDec =
            [
                "2/3", "1/2", "2/5", "1/3", "2/7", "1/4", "2/9", "1/5", "2/11", "1/6",
                "2/13", "1/7", "2/15", "1/8", "2/17", "1/9", "1/10", "1/12", "2/15", "1/20"
            ];

        var splitFactor = Math.floor((value + 0.0005) * 1000);
        var i;
        var retValue;

        if (value > 1) {
            for (i = 0; i < 50 && DecimalInc[i] < 99; ++i)
                if (Math.abs(value - DecimalInc[i]) < 0.004)
                    return FractionInc[i];
        }
        if (value < 1) {
            value = 1 / value;
            for (i = 0; i < 20; ++i)
                if (Math.abs(value - DecimalDec[i]) < 0.004)
                    return FractionDec[i];
        }

        if (splitFactor % 1000 == 0)
            retValue = (splitFactor / 1000).toFixed(0) + "/1";
        else
            if (splitFactor % 100 == 0)
                retValue = (splitFactor / 100).toFixed(0) + "/10";
            else
                if (splitFactor % 10 == 0)
                    retValue = (splitFactor / 10).toFixed(0) + "/100";
                else
                    retValue = (splitFactor / 1).toFixed(0) + "/1000";

        return retValue;
    }
}
const splitUtil = new SplitUtil();
export default splitUtil;