import GraphType from "GraphType";
import Serializable from "Serializable";
import UserInfoUtil from 'UserInfoUtil';
import {RotationMenuType} from "../../../../../Constants/RotationMenuType";
import RatingFilterType from "RatingFilterType";

class ComparisonGroupsMenuSettings extends Serializable {
  constructor() {
    super();
  }

  onSeralisedInfoRecieved(serializedValue) {
    this.msids = [];
    this.listItems = serializedValue.listItems ? serializedValue.listItems : [];
    this.breadCrumbItems = serializedValue.breadCrumbItems ? serializedValue.breadCrumbItems : [];
    this.itemName = serializedValue.itemName ? serializedValue.itemName : null;
    this.rotationMenuType = serializedValue.rotationMenuType ? serializedValue.rotationMenuType : RotationMenuType.Sector;
    this.countryCode = serializedValue.countryCode ? serializedValue.countryCode : UserInfoUtil.hasChinaEntitlement() ? 4 : 1;
    this.osid = serializedValue.osid ? serializedValue.osid : null;
    this.indCode = serializedValue.indCode ? serializedValue.indCode : null;
    this.displayName = serializedValue.displayName ? serializedValue.displayName : null,
    this.scaleBased = serializedValue.scaleBased !== undefined ? serializedValue.scaleBased : this.getDefaultScaleBased();
    this.userEndDate = serializedValue.userEndDate ? serializedValue.userEndDate : this.getDefaultUserEndDate();
    this.userStartDate = serializedValue.userStartDate ? serializedValue.userStartDate : this.getDefaultUserStartDate();
    this.scaleType = serializedValue.scaleType ? serializedValue.scaleType : this.getDefaultScaleType();
    this.periodicity = serializedValue.periodicity ? serializedValue.periodicity : this.getDefaultPeriodicity();
    this.symbolRequest = serializedValue.symbolRequest ? serializedValue.symbolRequest : this.getDefaultSymbolRequestForGroupsMenu();
    this.indexRequest = serializedValue.indexRequest ? serializedValue.indexRequest : this.getDefaultIndexRequest();
    this.sortType = serializedValue.sortType ? serializedValue.sortType : this.getDefaultSortType();
    this.liqFilter = serializedValue.liqFilter ? serializedValue.liqFilter : this.getDefaultLiqFilterStates();
  }

  getDataObject() {
    return {
        msids: this.msids,
        listItems: this.listItems,
        breadCrumbItems: this.breadCrumbItems,
        itemName: this.itemName,
        rotationMenuType: this.rotationMenuType,
        countryCode: this.countryCode,
        osid: this.osid,
        indCode: this.indCode,
        displayName: this.displayName,
        scaleBased: this.scaleBased,
        userEndDate: this.userEndDate,
        userStartDate: this.userStartDate,
        scaleType: this.scaleType,
        periodicity: this.periodicity,
        symbolRequest: this.symbolRequest,
        indexRequest: this.indexRequest,
        sortType: this.sortType,
        liqFilter: this.liqFilter
    };
  }

  getObjectMapKey() {
    return "ComparisonGroupsMenuSettings";
  }

  getDefaultComparisonGroupsMenuSettings() {
    this.msids = [];
    this.listItems = [];
    this.breadCrumbItems = [];
    this.itemName = null;
    this.rotationMenuType = RotationMenuType.Sector;
    this.countryCode = UserInfoUtil.hasChinaEntitlement() ? 4 : 1;
    this.osid = null;
    this.indCode = null;
    this.displayName = null,
    this.scaleBased = this.getDefaultScaleBased();
    this.userEndDate = this.getDefaultUserEndDate();
    this.userStartDate = this.getDefaultUserStartDate();
    this.scaleType = this.getDefaultScaleType();
    this.periodicity = this.getDefaultPeriodicity();
    this.symbolRequest = this.getDefaultSymbolRequestForGroupsMenu();
    this.indexRequest = this.getDefaultIndexRequest();
    this.sortType = this.getDefaultSortType();
    this.liqFilter = this.getDefaultLiqFilterStates();
    return this;
  }

  getDefaultScaleBased() {
    return 100;
  }

  getDefaultUserEndDate() {
    return "";
  }

  getDefaultUserStartDate() {
    return "";
  }

  getDefaultScaleType() {
    return "Lin";
  }

  getDefaultPeriodicity() {
    return GraphType.Daily;
  }

  getDefaultSymbolRequestForGroupsMenu(){
    const symbolsData = 
       [{ Osid: 30677, SymTypeEnum: "WONSECTOR11", index: 0, isVisible: true, isHighlighted: true },
        { Osid: 30676, SymTypeEnum: "WONSECTOR11", index: 1, isVisible: true, isHighlighted: true },
        { Osid: 30665, SymTypeEnum: "WONSECTOR11", index: 2, isVisible: true, isHighlighted: true },
        { Osid: 30673, SymTypeEnum: "WONSECTOR11", index: 3, isVisible: true, isHighlighted: true },
        { Osid: 30675, SymTypeEnum: "WONSECTOR11", index: 4, isVisible: true, isHighlighted: true },
        { Osid: 30666, SymTypeEnum: "WONSECTOR11", index: 5, isVisible: true, isHighlighted: true },
        { Osid: 30674, SymTypeEnum: "WONSECTOR11", index: 6, isVisible: true, isHighlighted: true },
        { Osid: 30672, SymTypeEnum: "WONSECTOR11", index: 7, isVisible: true, isHighlighted: true },
        { Osid: 30664, SymTypeEnum: "WONSECTOR11", index: 8, isVisible: true, isHighlighted: true },
        { Osid: 30671, SymTypeEnum: "WONSECTOR11", index: 9, isVisible: true, isHighlighted: true },
        { Osid: 30670, SymTypeEnum: "WONSECTOR11", index: 10, isVisible: true, isHighlighted: true }]
      const symbolRequest = symbolsData.map((symbol) => this.getSerizableObject(symbol));
      return symbolRequest;
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, {...obj});
  }

  getDefaultIndexRequest() {
    return [];
  }

  getDefaultSortType() {
    return "dsc";
  }

  getDefaultLiqFilterStates() {
    let liqFilter = new Serializable();
    liqFilter.MktCapLowSliderRangeValueIndex = -1;
    liqFilter.MktCapHighSliderRangeValueIndex = -1;

    liqFilter.AvolFilterLowSliderRangeValueIndex = -1;
    liqFilter.AvolFilterHighSliderRangeValueIndex = -1;


    liqFilter.RatingFilter1LowSliderRangeValueIndex = -1;
    liqFilter.RatingFilter1HighSliderRangeValueIndex = -1;

    liqFilter.RatingFilter2LowSliderRangeValueIndex = -1;
    liqFilter.RatingFilter2HighSliderRangeValueIndex = -1;

    liqFilter.RatingFilterType2 = RatingFilterType.EPS;

    liqFilter.RatingFilterType1 = RatingFilterType.Datagraph;

    liqFilter.IsGlobalRatingSelected = true;
    liqFilter.IsCountryRatingSelected = false;
    liqFilter.IsFilterOn = true;
    liqFilter.IsFilterManualOff = true;
    return liqFilter;
  }
}

export default ComparisonGroupsMenuSettings;
