import ListManagerTabType from "ListManagerTabType";

class ListManagerSettingHelper {

  constructor() {
  }

  updateTabSelection(viewSettings, header) {
    if(header == ListManagerTabType.External){
      viewSettings.SelectedTabKeyMiniList = viewSettings.SelectedTabKey;
    } else {
      viewSettings.SelectedTabKeyMiniList = header
    }
    viewSettings.SelectedTabKey = header;
  }

  updateListExplorerWidth(viewSettings, width) {
    viewSettings.ListExplorer.Width = width;
  }

  updateListExplorerPreviousWidth(viewSettings, width) {
    viewSettings.ListExplorer.PreviousWidth = width;
  }

  updateMetricLibraryWidth(viewSettings, width) {
    viewSettings.MetricLibrary.Width = width;
  }

  updateMetricLibraryIsOpen(viewSettings, isVisible) {
    viewSettings.MetricLibrary.IsOpen = isVisible;
  }

  updateIsMetricLibraryOpen(viewSettings, isVisible) {
    viewSettings.MetricLibrary.IsOpen = isVisible;
  }

  updateScreenOnOff(viewSettings, screenOnOff) {
    viewSettings.ScreenOnOff = screenOnOff;
  }

  updateTextSize(viewSettings, textSize) {
    viewSettings.TextSize = textSize;
  }

  updateSelectedListItem(viewSettings, listId, actualListId, listName, categoryType, parentNodeId, parentCategoryType, parentCategoryName, parentListType, sourceCategoryType, sourceParentCategoryName) {
    viewSettings.SelectedListInfo.SelectedListId = listId;
    viewSettings.SelectedListInfo.SelectedActualListId = actualListId;
    viewSettings.SelectedListInfo.SelectedListName = listName;
    viewSettings.SelectedListInfo.SelectedCategoryType = categoryType;
    viewSettings.SelectedListInfo.SelectedParentNodeId = parentNodeId;
    viewSettings.SelectedListInfo.SelectedParentCategoryType = parentCategoryType;
    viewSettings.SelectedListInfo.SelectedParentCategoryName = parentCategoryName;
    viewSettings.SelectedListInfo.SelectedParentListType = parentListType;
    viewSettings.SelectedListInfo.SelectedSourceCategoryType = sourceCategoryType;
    viewSettings.SelectedListInfo.SelectedSourceParentCategoryName = sourceParentCategoryName;
  }
  
  updateSelectedExternalListItem(viewSettings, listId, actualListId, listName) {
    viewSettings.SelectedListInfo.SelectedListId = listId;
    viewSettings.SelectedListInfo.SelectedActualListId = actualListId;
    viewSettings.SelectedListInfo.SelectedListName = listName;
  }
  // updateBrowserFilterSelectedTab(viewSettings, state) {
  //   viewSettings.BrowserFilter.selectedTab = state.selectedTab;
  // }

  updateBrowserFilterData(viewSettings, state, filterObject, filterObjectName) {
    viewSettings.BrowserFilter.selectedTab = state.selectedTab;
    viewSettings.BrowserFilter.isOneilselected = state.isOneilselected;
    viewSettings.BrowserFilter.isGicsselected = state.isGicsselected;
    viewSettings.BrowserFilter.needBrowserCounts = state.needBrowserCounts;
    viewSettings.BrowserFilter.browserFilterText = state.browserFilterText;
    viewSettings.BrowserFilter.igOneilbrowserFilters = state.igOneilbrowserFilters;
    viewSettings.BrowserFilter.igGicsbrowserFilters = state.igGicsbrowserFilters;
    viewSettings.BrowserFilter.filterBadge = state.filterBadge;
    viewSettings.BrowserFilter.filterObject = filterObject;
    viewSettings.BrowserFilter.filterObjectName = filterObjectName;
  }

  updateSummaryStatData(viewSettings, state) {
    viewSettings.SummaryStatSettings.isSummaryStatWindowOpen = state.isSummaryStatWindowOpen;
    viewSettings.SummaryStatSettings.summaryStatWindowHeight = state.summaryStatWindowHeight;
    viewSettings.SummaryStatSettings.summaryStatDistColumnId = state.summaryStatDistColumnId;
  }

  updateFrozenColSplitter(viewSettings, frozenColumnsSpliter) {
    viewSettings.frozenColumnsSpliter = frozenColumnsSpliter;
  }
}

const listManagerSettingHelper = new ListManagerSettingHelper();
export default listManagerSettingHelper;
