export default class IdeaListData {
    constructor(data) {
        this.setIdeaListData(data);
    }

    setIdeaListData(data) {
        const ideaDataList = data.overlapList;
        this.dataSource = [];
        ideaDataList.map((fundIdeaListOverlapData) =>
            this.dataSource.push({
                header: fundIdeaListOverlapData.name,
                buy: fundIdeaListOverlapData.buy
            })
        );
    }
}