import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetRecentListsRequest extends BaseServiceRequest {

    constructor(dbId) {
        super();
        super.addRequestUri("recents.pb");
        super.addPathParameter(dbId.toString());
        super.addRequestParameter("c", '0');
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListExplorerResponse";
    }
}

export default GetRecentListsRequest;