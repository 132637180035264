import React from "react";
import ReactDOM from "react-dom";
import ListManagerListCategory from "ListManagerListCategory";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import LocalizationStore from 'LocalizationStore';
import onClickOutside from "react-onclickoutside";
import ListManagerTabType from "ListManagerTabType";
import ListStore from "ListStore";
import BaseServiceApi from 'BaseServiceApi';

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

class DropdownMenu extends React.Component {

  constructor(props) {
    super(props);
    this.addNewItem = this.addNewItem.bind(this);
    this.saveScreenedResults = this.saveScreenedResults.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.state = { showDropdown: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      showDropdown: nextProps.showDropdown,
      addNewItem: nextProps.addNewItem
    });
  }

  handleClickOutside(e) {
    this.handleDocumentClick(e);
  }

  handleDocumentClick(e) {
    try {
      this.props.toggleDropdown(false);
    }
    catch (ex) {
      console.log(e);
    }

  }

  addNewItem(e) {
    let type = ReactDOM.findDOMNode(e.target).getAttribute('type');
    let categoryType = ReactDOM.findDOMNode(e.target).getAttribute('data-categorytype');
    this.handleDocumentClick(e);
    return this.props.addNewItem(type, categoryType);
  }
  addNewEventFile = (e) => {
    this.handleDocumentClick(e);
    this.props.addNewItem(BaseServiceApi.rayData["ListType"].EVENTSERIES_List);
  }
  addNewTimeFile = (e) => {
    this.handleDocumentClick(e);
    this.props.addNewItem(BaseServiceApi.rayData["ListType"].TIMESERIES_List);
  }
  saveScreenedResults(e) {
    this.handleDocumentClick(e);
    if (ONeilViewStore.getSaveScreenedResultsVisibilty() || OwnershipViewStore.getSaveScreenedResultsVisibilty())
      return this.props.saveScreenedResults();
  }

  render() {
    let open = (this.state.showDropdown ? 'dropdown open' : 'dropdown');
    let listStore = ListStore.getState();
    let isFactorList = listStore.TabONeilSettings.SelectedListInfo.SelectedCategoryType == ListCategoryType.FACTORLIST_ListCategory;
    let selectedTabKey = listStore.SelectedTabKey;
    const screenVisibilty = selectedTabKey === ListManagerTabType.Ownership ?  OwnershipViewStore.getSaveScreenedResultsVisibilty() : ONeilViewStore.getSaveScreenedResultsVisibilty();
    let disableSaveScreenedResults = screenVisibilty ? null : 'contextMenuItemDisabled';
    let sharedListClass;
    (selectedTabKey === ListManagerTabType.Ownership) ? sharedListClass = 'disable-div' : sharedListClass= '';
    const OneilOwnerDown =
      <div id="dropdownmenu" className={open}>
        <ul className="dropdown-menu multi-level list-contex small-normal" role="menu" aria-labelledby="dropdownMenu1" style={{ bottom: "40px", top: "inherit" }}>
          <li><a data-categorytype={ListManagerListCategory.USER_ListCategory}
            type="NewList"
            onClick={this.addNewItem}>{LocalizationStore.getTranslatedData('lp_nl', 'New List')}</a></li>
          <li className="dropdown-submenu">
            <a>{LocalizationStore.getTranslatedData('lp_nf', 'New Folder')}</a>
            <ul className="dropdown-menu list-contex  small-normal">
              <li><a data-categorytype={ListManagerListCategory.USER_ListCategory}
                type="NewFolder"
                onClick={this.addNewItem}>{LocalizationStore.getTranslatedData('lp_iml', 'In My Lists')}</a></li>

              <li><a data-categorytype={ListManagerListCategory.FAVORITE_ListCategory}
                type="NewFolder"
                onClick={this.addNewItem}>{LocalizationStore.getTranslatedData('lp_if', 'In Favorites')}</a></li>

              <li className ={sharedListClass}><a
                data-categorytype={ListManagerListCategory.SHAREDLIST_ListCategory}
                type="NewFolder"
                onClick={this.addNewItem}>{LocalizationStore.getTranslatedData('lp_isl', 'In Shared Lists')}</a></li>
            </ul>
          </li>
          <li><a className={ disableSaveScreenedResults }
                 onClick={ disableSaveScreenedResults === "contextMenuItemDisabled" ? undefined : this.saveScreenedResults.bind(this)}>{LocalizationStore.getTranslatedData('lp_ssr', 'Save Screened Results')}
            </a>
            </li>
        </ul>
      </div>;
  const externalDropDown =  <div id="dropdownmenu" className={open}>
    <ul className="dropdown-menu multi-level list-contex small-normal" role="menu" aria-labelledby="dropdownMenu1" style={{ bottom: "40px", top: "inherit" }}>
      <li><a type="NewList"
        onClick={this.addNewEventFile}>{'New Events File'}</a></li>
      <li><a type="NewList"
        onClick={this.addNewTimeFile}>{'New Time Series File'}</a></li>
    </ul>
  </div>;
  return (selectedTabKey === ListManagerTabType.External) ? externalDropDown : OneilOwnerDown;
  }
}

export default onClickOutside(DropdownMenu);