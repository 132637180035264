import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetListCatalogRequestES extends BaseServiceRequest {
  constructor(basicInfoObj, isNuporMiniList = false) {
    super();
    super.addRequestUri(isNuporMiniList ? "nupesbasic.pb" : "esbasic.pb");
    super.addPathParameter(basicInfoObj.listId.toString());
    this.methodType = HttpMethodType.POST;
    super.setContent(basicInfoObj.encode());
  }

  getMethod() {
    return this.methodType;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ListCatalogResponse";
  }
}

export default GetListCatalogRequestES;
