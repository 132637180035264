import Scale from "./Scale.js";
import PrefPeriodScaleConstants from "PrefPeriodScaleConstants";
import ExtremeDataValue from "ExtremeDataValue";

export default class NewArithPriceScale extends Scale {
    constructor(mStockInfo = null) {
        super();
        this.PsNewTargetInfo = null;
        this._mlPriceIncrement = 0.0;
        this.scaleStart = 0;
        if (mStockInfo) {
            this.InitializedScale(mStockInfo);
        }
    }

    InitializedScale(stockinfo) {
        this.PsNewTargetInfo = stockinfo;
        this.PsNewTargetInfo.ScaleType = PrefPeriodScaleConstants.Lin;
        this.InitScale();
        this.SetHorizontalGrid();
    }

    ComputePrice(y) {
        const dGraphDistance = this.PsNewTargetInfo.VpHeight - y;
        let dPriceDiffFromBottom = dGraphDistance / this.Factor;
        dPriceDiffFromBottom += this.Lowpt;
        return dPriceDiffFromBottom;
    }


    ComputeY(dPrice) {
        const dPriceDiffFromBottom = dPrice - this.Lowpt;
        if (this.PsNewTargetInfo == null || this.PsNewTargetInfo.VpHeight == 0) return 0;
        return this.PsNewTargetInfo.VpHeight - dPriceDiffFromBottom * this.Factor;
    }

    ComputeYL(dPrice, dLow) {
        const dPriceDiffFromBottom = dPrice - dLow;
        if (this.PsNewTargetInfo == null) return 0;
        return this.PsNewTargetInfo.BoxHeight - dPriceDiffFromBottom * this.Factor;
    }

    InitScale() {
        const iNumberOfIncrements = 10;

        if (this.PsNewTargetInfo.MinPrice === this.PsNewTargetInfo.MaxPrice || !Number.isFinite(this.PsNewTargetInfo.MinPrice) || !Number.isFinite(this.PsNewTargetInfo.MaxPrice)) return;

        let mult = Math.abs(this.PsNewTargetInfo.MaxPrice) < 1 ? 10 : 1;
        let mnPrc = this.PsNewTargetInfo.MinPrice * mult;
        let mxPrc = this.PsNewTargetInfo.MaxPrice * mult;

        let prInc = ((mxPrc - mnPrc) / iNumberOfIncrements) + 0.05;

        if (prInc <= 0) prInc = 1;

        let pr = Math.log10(prInc);
        let pr0 = Math.pow(10, Math.abs(parseInt(pr)) + 1);
        let pr1 = prInc < 10 ? Math.pow(10, pr0) : 1;
        let pr2 = prInc * pr1;
        pr2 = pr2 - pr2 % Math.pow(10, parseInt(Math.log10(pr2)));

        let amnPrc = Math.abs((mnPrc - 0.5) * pr1);

        amnPrc = mnPrc > 0 ? (amnPrc - amnPrc % Math.pow(10, parseInt(Math.log10(amnPrc)))) :
            mnPrc < 0 ? -(amnPrc - amnPrc % Math.pow(10, parseInt(Math.log10(amnPrc)))) : 0;


        let amxPrc = Math.abs((mxPrc + 0.5) * pr1);

        amxPrc = mxPrc > 0 ? (amxPrc - amxPrc % Math.pow(10, parseInt(Math.log10(amxPrc)))) :
            mxPrc < 0 ? -(amxPrc - amxPrc % Math.pow(10, parseInt(Math.log10(amxPrc)))) : 0;

        prInc = pr2 / pr1;

        if (prInc <= .01 && prInc > .005)
            prInc = .01;
        else
            if (prInc <= .005 && prInc > .0025)
                prInc = .005;
            else
                if (prInc <= .0025 && prInc > .0001)
                    prInc = .0025;

        if (prInc <= .1 && prInc > .05)
            prInc = .1;
        else
            if (prInc <= .05 && prInc > .025)
                prInc = .05;
            else
                if (prInc <= .025 && prInc > .01)
                    prInc = .025;

        if (prInc <= 1 && prInc > .5)
            prInc = 1;
        else
            if (prInc <= .5 && prInc > .25)
                prInc = .5;
            else
                if (prInc <= .25 && prInc > .1)
                    prInc = .25;

        if (prInc <= 10 && prInc > 5)
            prInc = 10;
        else
            if (prInc <= 5 && prInc > 2.5)
                prInc = 5;
            else
                if (prInc <= 2.5 && prInc > 1)
                    prInc = 2.5;

        if (prInc <= 100 && prInc > 50)
            prInc = 100;
        else
            if (prInc <= 50 && prInc > 25)
                prInc = 50;
            else
                if (prInc <= 25 && prInc > 10)
                    prInc = 25;

        if (prInc <= 1000 && prInc > 500)
            prInc = 1000;
        else
            if (prInc <= 500 && prInc > 250)
                prInc = 500;
            else
                if (prInc <= 250 && prInc > 100)
                    prInc = 250;

        if (prInc <= 10000 && prInc > 5000)
            prInc = 10000;
        else
            if (prInc <= 5000 && prInc > 2500)
                prInc = 5000;
            else
                if (prInc <= 2500 && prInc > 1000)
                    prInc = 2500;
                else
                    if (prInc > 10000)
                        prInc = prInc - prInc % Math.pow(10, parseInt(Math.log10(prInc)));

        mxPrc = amxPrc / pr1;
        mnPrc = amnPrc / pr1;

        let amn = mnPrc % prInc;

        this.Hipt = this.PsNewTargetInfo.MaxPrice;
        this.Lowpt = this.PsNewTargetInfo.MinPrice;
        // Actual Factor
        this.Factor = parseFloat((this.PsNewTargetInfo.BoxHeight) / (this.Hipt - this.Lowpt));

        let yPr1 = this.ComputePrice(-60);
        let yPr2 = this.ComputePrice(this.PsNewTargetInfo.VpHeight + 60);

        this.Hipt = yPr1;
        this.Lowpt = yPr2;
        // Adjusted Factor with Top/Bottom margins
        this.Factor = parseFloat((this.PsNewTargetInfo.VpHeight) / (this.Hipt - this.Lowpt));

        this.LowptforScale = yPr2;
        prInc /= mult;
        this.scaleStart = ((mnPrc - amn) / mult) - prInc;

        this.Lowpt = this.LowptforScale;

        this._mlPriceIncrement = prInc;
    }

    SetHorizontalGrid() {
        this.MHLines = [];
        this.MHLabel = [];
        let py = 0;
        if (this.Hipt == this.LowptforScale || this.PsNewTargetInfo.BoxHeight < 1 || !Number.isFinite(this.Hipt)) return;
        if (this._mlPriceIncrement === 0) return;
        for (let pricePtr = this.scaleStart; pricePtr <= this.Hipt; pricePtr += this._mlPriceIncrement) {
            let y = this.ComputeY(pricePtr);
            if ((this.MHLines.some((itm) => itm.y === y)) || (py > 0 && py - y < 10) || y < 5 || y > this.PsNewTargetInfo.BoxHeight - 4)
                continue;
            py = y;

            let eLabel = "";
            let T4QLabels = [];
            if (this.PsNewTargetInfo.EpsMultiplier != null)
                eLabel = ExtremeDataValue.abbreviateValueFormat((pricePtr / this.PsNewTargetInfo.EpsMultiplier), 0, false);

            if (this.PsNewTargetInfo.T4QMultipliers != null && this.PsNewTargetInfo.T4QMultipliers.length < Number.MAX_VALUE) {
                for (let item of this.PsNewTargetInfo.T4QMultipliers) {
                    let labelstring = ExtremeDataValue.abbreviateValueFormat((pricePtr / item.Value), 0, false);
                    if (T4QLabels.some((itm) => itm.key === item.Key))
                        T4QLabels = T4QLabels.filter((itm) => itm.key !== item.Key)
                    T4QLabels.push({ key: item.Key, labelstring: labelstring });
                }
            }

            let label = pricePtr > 999999 ? ExtremeDataValue.abbreviateValueFormat(pricePtr, 0, false) :
                pricePtr > 9999 ? ExtremeDataValue.abbreviateValueFormat(pricePtr, 0, false) :
                    Math.abs(this._mlPriceIncrement) < 0.001 ? ExtremeDataValue.abbreviateValueFormat(pricePtr, 4, false) :
                        (Math.abs(this._mlPriceIncrement) < 0.01 ? ExtremeDataValue.abbreviateValueFormat(pricePtr, 3, false) :
                            (Math.abs(this._mlPriceIncrement) < 0.5 ? ExtremeDataValue.abbreviateValueFormat(pricePtr, 2, false) :
                                (Math.abs(this._mlPriceIncrement) < 1 ? ExtremeDataValue.abbreviateValueFormat(pricePtr, 1, false) :
                                    Number(pricePtr.toFixed(2)).toString())));

            this.MHLines.push({
                y: y,
                LType: 1,
                Label: label,
                eLabel: eLabel,
                YAxis: y,
                YGap: (py - y),
                T4QLabels: T4QLabels
            });

        }
    }
    SetGrid() {
        this.SetHorizontalGrid();
    }
}


