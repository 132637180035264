import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetRollBackListRquest extends BaseServiceRequest {
    constructor(nodeIds) {
        super();
        super.addRequestUri("rollbackprevload.pb");
        super.addPathParameter(nodeIds.toString());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}
export default GetRollBackListRquest;