import GetUserRoleRequest from '../Requests/GetUserRoleRequest'
import HubGetContactRequest from '../Requests/HubGetContactsRequest'
import HubGetSalesRepsRequest from '../Requests/HubGetSalesRepsRequest'
import HubGetIndexesRequest from '../Requests/HubGetIndexesRequest'
import BaseServiceApi from 'BaseServiceApi'

class HubApi {

  getUserRole(email){
    const req = new GetUserRoleRequest(email);
    return BaseServiceApi.processServiceCall(req);
  }

  getContactRequest(email, osid, quarterenddate) {
    const req = new HubGetContactRequest(email, osid, quarterenddate);
    return BaseServiceApi.processServiceCall(req);
  }

  getSalesRepsRequest(email, osid, quarterenddate) {
    const req = new HubGetSalesRepsRequest(email, osid, quarterenddate);
    return BaseServiceApi.processServiceCall(req);
  }

  getIndexes(symbol){
    const req = new HubGetIndexesRequest(symbol);
    return BaseServiceApi.processServiceCall(req);
  }
}

var hubApi = new HubApi();
export default hubApi;
