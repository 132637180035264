import GetStockHistoryByDateRequest from "../Requests/GetStockHistoryByDateRequest.js";
import GetSnapShotRequest from "../Requests/GetSnapShotRequest.js";
import GetBlockRequest from "../Requests/GetBlockRequest.js";
import BaseServiceApiWCF from "BaseServiceApiWCF";
import GetOneilTechRequest from "../Requests/GetOneilTechRequest.js";
import BaseServiceApi from "BaseServiceApi";
import GetLiveStreamUrlRequest from "../Requests/GetLiveStreamUrlRequest.js";
import GetNewsRequest from "../Requests/GetNewsRequest.js";
import GetPatternsRequest from "../Requests/GetPatternsRequest.js";
import GetAllIdeasRequest from "../Requests/GetAllIdeasRequest.js";
import GetCheckListSetsRequest from "../Requests/GetCheckListSetsRequest.js";
import GetCheckListDataRequest from "../Requests/GetCheckListDataRequest.js";
import GetGraphDataRequest from "../Requests/GetGraphDataRequest.js";
import GetOwnershipReportRequestExt from "../Requests/GetOwnershipReportRequestExt.js";
import GetFundManagementDataRequest from "../Requests/GetFundManagementDataRequest.js";
import GetFundManagementDataDetailRequest from "../Requests/GetFundManagementDataDetailRequest.js";
import GetSimilarFundsRequest from "../Requests/GetSimilarFundsRequest.js";
import GetDownloadIdeaAnalsisReportWebRequest from "../Requests/GetDownloadIdeaAnalsisReportWebRequest.js";
import GetFundHoldingsDataRequest from "../Requests/GetFundHoldingsDataRequest.js";
import GetAllocationDataRequest from "../Requests/GetAllocationDataRequest.js";
import GetEtfViewSummaryBlockRequest from "../Requests/GetEtfViewSummaryBlockRequest.js";
import GetFundsViewSummaryBlockRequest from "../Requests/GetFundsViewSummaryBlockRequest.js";
import GetCurrencyViewSummaryBlockRequest from "../Requests/GetCurrencyViewSummaryBlockRequest.js";
import GetIndustryGroupSummaryBlockRequest from "../Requests/GetIndustryGroupSummaryBlockRequest.js";
import GetSectorGroupSummaryBlockRequest from "../Requests/GetSectorGroupSummaryBlockRequest.js";
import GetDownloadCurrencyImageWebRequest from "../Requests/GetDownloadCurrencyImageWebRequest.js";
import GetIndustryGroupHeaderRequest from "../Requests/GetIndustryGroupHeaderRequest.js";
import GetMetricDefinitionRequest from "../Requests/GetMetricDefinitionRequest.js";
import GetKpiItemsBySymbolRequest from "../Requests/GetKpiItemsBySymbolRequest.js";
import GetFactorEventDataRequest from "../Requests/GetFactorEventDataRequest"
import GetFactorEventInfoRequest from "../Requests/GetFactorEventInfoRequest"
import GetIndexViewSummaryBlockRequest from "../Requests/GetIndexViewSummaryBlockRequest.js";
import GetIndexAllocationDataRequest from "../Requests/GetIndexAllocationDataRequest.js";
import GetConstituentsDataRequest from "../Requests/GetConstituentsDataRequest.js";
import GetEPSSnapshotsRequest from "../Requests/GetEPSSnapshotsRequest.js";
import GetOneilResearchAnalystRequest from "../Requests/GetOneilResearchAnalystRequest.js";
import ListApi from "./ListApi.js";
import GetVolumeChangeRequest from "../Requests/GetVolumeChange.js";
import GetBrokerReportRequest from "../Requests/GetBrokerReportRequest.js";
import GetPdfRequestWebURL from '../Requests/GetPdfRequestWebURL.js';
import GetTechIndicatorRequest from "../Requests/GetTechIndicatorRequest.js";
import GetUserRoleRequest from '../Requests/GetUserRoleRequest'
import HubGetContactRequest from '../Requests/HubGetContactsRequest'
import HubGetSalesRepsRequest from '../Requests/HubGetSalesRepsRequest'
import HubGetIndexesRequest from '../Requests/HubGetIndexesRequest'
import GetESDownloadCurrencyImageWebRequest from "../Requests/GetESDownloadCurrencyImageWebRequest.js";

const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};
class GraphApi {

  getstockhistoryByDate(symbol, graphtype, enddate, startdate, YTD, YTD1, YTD3, YTD5, YTD10, GetRTHistory, IndexSymbol, RS1Symbol, RS2Symbol, YTDReq) {
    const req = new GetStockHistoryByDateRequest(symbol, graphtype, enddate, startdate, YTD, YTD1, YTD3, YTD5, YTD10, GetRTHistory, IndexSymbol, RS1Symbol, RS2Symbol, YTDReq);
    return BaseServiceApiWCF.ProcessServiceCall(req);
  }

  getOneilIndicatorsData(symbol, graphtype, enddate, startdate, Tech, EPS, PTOEOP, PTOSOP, TechReq) {
    const req = new GetOneilTechRequest(symbol, graphtype, enddate, startdate, Tech, EPS, PTOEOP, PTOSOP, TechReq);
    return BaseServiceApiWCF.ProcessServiceCall(req);
  }

  getSnapShotData(symbol, graphtype, enddate, startdate, wonOnly = 1, revWonOnly = 0) {
    let newSymbol = symbol.replace("&", "%26");
    const req = new GetSnapShotRequest(newSymbol, graphtype, enddate, startdate, wonOnly, revWonOnly);
    return BaseServiceApiWCF.ProcessServiceCall(req);
  }

  GetBlockRequest(symbol, graphtype, enddate, startdate, fcQtrs, isFinancialBlockVisible, isOwnershipBlockVisible, wonOnly = 1, revWonOnly = 0) {
    const req = new GetBlockRequest(symbol, graphtype, enddate, startdate, fcQtrs, isFinancialBlockVisible, isOwnershipBlockVisible, wonOnly, revWonOnly);
    return BaseServiceApiWCF.ProcessServiceCall(req);
  }

  getPatternsRequest(symbol, msId, startDt, endDt, graphtype, osid, type) {
    const req = new GetPatternsRequest(symbol, msId, startDt, endDt, graphtype, osid, type);
    if (graphtype === 1 || graphtype === 2)
      return BaseServiceApi.processServiceCall(req);
    else
      return null;
  }

  GetLiveStreamUrlRequest(livestreamdataid) {
    const req = new GetLiveStreamUrlRequest(livestreamdataid);
    return BaseServiceApi.processServiceCall(req);
  }

  GetAllIdeasRequest(symbol, msId, periodicity, endDate, startDate, userhasxfl = false, targetCurrencyCode = "",osid = '') {
    const req = new GetAllIdeasRequest(symbol, msId, periodicity, endDate, startDate, userhasxfl, targetCurrencyCode, osid);
    return BaseServiceApi.processServiceCall(req);
  }

  GetEPSSnapshotsRequest(symbol, msId, startDt, endDt, periodicity, isWON, fcQuarters) {
    const req = new GetEPSSnapshotsRequest(symbol, msId, startDt, endDt, periodicity, isWON, fcQuarters);
    return BaseServiceApi.processServiceCall(req);
  }

  GetGraphDataRequest(symbol, msId, periodicity, endDate, startDate, modelId) {
    const req = new GetGraphDataRequest(symbol, msId, periodicity, endDate, startDate, modelId);
    return BaseServiceApi.processServiceCall(req);
  }

  GetOwnershipReportRequestExt(osId, ownerType, orderString, showIndex, seqNumber, pageSize, isTopOwner, isByOwnerType, resultCount) {
    const req = new GetOwnershipReportRequestExt(osId, ownerType, orderString, showIndex, seqNumber, pageSize, isTopOwner, isByOwnerType, resultCount);
    return BaseServiceApi.processServiceCall(req);
  }

  GetFundManagementDataDetailRequest(contactId, isEntitled) {
    const req = new GetFundManagementDataDetailRequest(contactId, isEntitled);
    return BaseServiceApi.processServiceCall(req);
  }

  getPdfRequestURL(pdfPath) {
    const req = new GetDownloadIdeaAnalsisReportWebRequest(pdfPath);
    return BaseServiceApi.getRequestURL(req);
  }

  GetSimilarFundsRequest(fundticker, isEntitled) {
    const req = new GetSimilarFundsRequest(fundticker, isEntitled);
    return BaseServiceApi.processServiceCall(req);
  }

  GetAllocationDataRequest(fundticker) {
    const req = new GetAllocationDataRequest(fundticker);
    return BaseServiceApi.processServiceCall(req);
  }

  /**************** Summary Block Calls ***************/
  GetEtfViewSummaryBlockRequest(msid, symbol, endDate, targetCurrencyCode) {
    const req = new GetEtfViewSummaryBlockRequest(msid, symbol, endDate, targetCurrencyCode);
    return BaseServiceApi.processServiceCall(req);
  }
  GetIndustryGroupSummaryBlockRequest(symbol, endDate) {
    const req = new GetIndustryGroupSummaryBlockRequest(symbol, endDate);
    return BaseServiceApi.processServiceCall(req);
  }
  GetSectorGroupSummaryBlockRequest(symbol, endDate) {
    const req = new GetSectorGroupSummaryBlockRequest(symbol, endDate);
    return BaseServiceApi.processServiceCall(req);
  }
  GetFundsViewSummaryBlockRequest(msid, symbol, endDate, targetCurrencyCode) {
    const req = new GetFundsViewSummaryBlockRequest(msid, symbol, endDate, targetCurrencyCode);
    return BaseServiceApi.processServiceCall(req);
  }
  GetCurrencyViewSummaryBlockRequest(msid, osid, symbol) {
    const req = new GetCurrencyViewSummaryBlockRequest(msid, osid, symbol);
    return BaseServiceApi.processServiceCall(req);
  }
  GetDownloadCurrencyImageWebRequest(id, osid) {
    const req = new GetDownloadCurrencyImageWebRequest(id, osid);
    return BaseServiceApiWCF.generateCurrencyImageWebRequest(req);
  }
  GetIndexViewSummaryBlockRequest(msid, osid, symbol) {
    const req = new GetIndexViewSummaryBlockRequest(msid, osid, symbol);
    return BaseServiceApi.processServiceCall(req);
  }
  GetIndexAllocationDataRequest(symbol) {
    const req = new GetIndexAllocationDataRequest(symbol);
    return BaseServiceApi.processServiceCall(req);
  }
  GetIndustryGroupHeaderRequest(msid, symbol, osid, type) {
    const req = new GetIndustryGroupHeaderRequest(msid, symbol, osid, type);
    return BaseServiceApi.processServiceCall(req);
  }
  /***************************************************/

  /*******************Indicators Calls *********************/
  GetKpiItemsBySymbol = (symbol, cp, k='', targetcurr='') => {
    const req = new GetKpiItemsBySymbolRequest(symbol, cp, k, targetcurr);
    return BaseServiceApi.processServiceCall(req);
  }

  getTechnicalIndicatorData(findGetTechIndRequest){
    const request = new GetTechIndicatorRequest(findGetTechIndRequest);
    return BaseServiceApi.processServiceCall(request);
}
  /*******************Indicators Calls end *********************/
  GetConstituentsDataRequest(symbol,mode) {
    const req = new GetConstituentsDataRequest(symbol,mode);
    return BaseServiceApi.processServiceCall(req);
  }

  setFlagSymbol(msids, snapshotDates, isDelete) {
    if (msids) {
      ListApi.updateGlobalFlag(msids, snapshotDates, isDelete, 0, 1);
    }
  }

  GetFactorEventDataRequest(sym, periodicity, enddate) {
    const req = new GetFactorEventDataRequest(sym, periodicity, enddate)
    // const cancelablePromise = makeCancelable()
    return BaseServiceApi.processServiceCall(req)
  }
  // GetKpiItemsBySymbol(symbol, cp, k=''){
  //   const req = new GetKpiItemsBySymbolRequest(symbol, cp, k);
  //   return BaseServiceApi.processServiceCall(req);
  // }
  GetFactorEventInfoRequest(msid, eventId, segmentId, osid, startDate, eventDate) {
    const req = new GetFactorEventInfoRequest(msid, eventId, segmentId, osid, startDate, eventDate)
    // const cancelablePromise = makeCancelable(BaseServiceApi.processServiceCall(req))
    return BaseServiceApi.processServiceCall(req)
  }
  async GetOneilResearchAnalystRequest(osid) {
    const req = new GetOneilResearchAnalystRequest(osid);
    return await BaseServiceApi.processServiceCall(req);
  }


  getVolumeChange(findGetDatagraphRequest,Osid,IsNASDAQBasic,isHistoric){
    const req = new GetVolumeChangeRequest(findGetDatagraphRequest,Osid,IsNASDAQBasic,isHistoric);
    return BaseServiceApi.processServiceCall(req);
  }

  getPdfRequestWebURL(id) {
    const req = new GetPdfRequestWebURL(id);
    const cancelablePromise = makeCancelable(BaseServiceApi.processServiceCall(req))
    return cancelablePromise
  }

  /* Ri Panel api req */
  GetFundManagementDataRequest(fundticker, isEntitled) {
    const req = new GetFundManagementDataRequest(fundticker, isEntitled);
    return BaseServiceApi.processServiceCall(req);
  }
  
  GetFundHoldingsDataRequest(fundticker) {
    const req = new GetFundHoldingsDataRequest(fundticker);
    return BaseServiceApi.processServiceCall(req);
  }
  
  GetNewsRequest(symbol, sourceIds, exchange) {
    const req = new GetNewsRequest(symbol, sourceIds, exchange);
    return BaseServiceApi.processServiceCall(req);
  }
  
  getBrokerReportRequest(symbol, startDate, endDate) {
    const req = new GetBrokerReportRequest(symbol, startDate, endDate);
    return BaseServiceApi.processServiceCall(req);
  }

  GetCheckListSetsRequest(ignoreFilterSet = false) {
    const req = new GetCheckListSetsRequest(ignoreFilterSet);
    return BaseServiceApi.processServiceCall(req);
  }

  GetCheckListDataRequest(msid, columnSetId, sourceColumnSetId = 0, hideMetericWithoutThresholds = true) {
    const req = new GetCheckListDataRequest(msid, columnSetId, sourceColumnSetId, hideMetericWithoutThresholds);
    return BaseServiceApi.processServiceCall(req);
  }

  /* RiPanel Ownership */
  getUserRole(email) {
    const req = new GetUserRoleRequest(email);
    return BaseServiceApi.processServiceCall(req);
  }

  getContactRequest(email, osid, quarterenddate) {
    const req = new HubGetContactRequest(email, osid, quarterenddate);
    return BaseServiceApi.processServiceCall(req);
  }

  getSalesRepsRequest(email, osid, quarterenddate) {
    const req = new HubGetSalesRepsRequest(email, osid, quarterenddate);
    return BaseServiceApi.processServiceCall(req);
  }

  getIndexes(symbol) {
    const req = new HubGetIndexesRequest(symbol);
    return BaseServiceApi.processServiceCall(req);
  }

  GetESDownloadCurrencyImageWebRequest(id, osid) {
    const req = new GetESDownloadCurrencyImageWebRequest(id, osid);
    return BaseServiceApi.getRequestURL(req);
  }
}

var graphApi = new GraphApi();
export default graphApi;

export const GetMetricDefinition = () => {
  const req = new GetMetricDefinitionRequest();
  return BaseServiceApi.processServiceCall(req);
}

export const GetRestMetricDefinition = () => {
  const req = new GetRestMetricDefinitionRequest();
  return BaseServiceApi.processServiceCall(req);
}

export const GetKpiItemsBySymbol = (osid, cp, k='', targetcurr='') => {
  const req = new GetKpiItemsBySymbolRequest(osid, cp, k, targetcurr);
  return BaseServiceApi.processServiceCall(req);
}