import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import StringUtil from "StringUtil";
import ThemeHelper from "ThemeHelper";

const estimateText = LocalizationStore.getTranslatedData("sb_yrest", " Estimate");

export default class EstimateData {
  constructor(data) {
    this.stockinfo = data.StockInfo;
    this.currencyFormat = data.StockInfo.CurrencyFormat;
    this.positiveColor = ThemeHelper.getThemedBrush("positiveDataText");
    this.negativeColor = ThemeHelper.getThemedBrush("negativeDataText");
    this.setSummaryBlockEstimate(data);
  }

  setSummaryBlockEstimate(data) {
    if (!data.AnnualBlockData || data.AnnualBlockData.length <= 0) {
      return;
    }
    const latestAnnualdata = data.AnnualBlockData[0];
    const fiscalDate = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(latestAnnualdata.fiscalDate), this.stockinfo.MomentZoneID);
    if (!fiscalDate) {
      return;
    }
    this.fiscalDateCurr = fiscalDate.getFullYear() + 1 + estimateText;
    this.fiscalDateNext = fiscalDate.getFullYear() + 2 + estimateText;
    let displayCurAe, displayNxtAe = 0;
    if (data.AnnualMiscBlockData.CurAE) {
      /* Fix for PANWEB-2057 */
      this.CurAE = data.AnnualMiscBlockData.CurAE /*    * this.currencyFormat.ratio; */
      this.currEstimate = Math.abs(this.CurAE) >= 1000 ? ExtremeDataValue.numberWithCommas(ExtremeDataValue.formatValue(this.CurAE, 2)) : ExtremeDataValue.formatValue(this.CurAE, 2);
      displayCurAe = Number(ExtremeDataValue.formatValue(data.AnnualMiscBlockData.CurAE, 2));
    }
    if (data.AnnualMiscBlockData.NxtAE) {
      displayNxtAe = Number(ExtremeDataValue.formatValue(data.AnnualMiscBlockData.NxtAE, 2));
    }
    const curCode = this.stockinfo.DefaultCurrency;
    const currency = (curCode.CurrencyCode) ? curCode.CurrencyCode : curCode.Code;
    if ((data.AnnualMiscBlockData.CurAE || data.AnnualMiscBlockData.CurAE === 0) && latestAnnualdata.EPS !== 0) {
      let percChg = 0;
      let eps = 0;
      if (latestAnnualdata.EPS > 0) {
        if (currency === "KRW" || currency === "GBP" || currency === "ZAR") {
          displayCurAe = Number(ExtremeDataValue.formatValue(data.AnnualMiscBlockData.CurAE * 100, 2));
          eps = Number(ExtremeDataValue.formatValue(latestAnnualdata.EPS * 100, 2));
          percChg = ((displayCurAe - eps) * 100) / eps;
        } else {
          eps = Number(ExtremeDataValue.formatValue(latestAnnualdata.EPS, 2));
          percChg = (displayCurAe - eps) / eps * 100;
        }
      } else {
        if (currency === "KRW" || currency === "GBP" || currency === "ZAR") {
          displayCurAe = Number(ExtremeDataValue.formatValue(data.AnnualMiscBlockData.CurAE * 100, 2));
          eps = Number(ExtremeDataValue.formatValue(latestAnnualdata.EPS * 100, 2));
          percChg = ((displayCurAe - eps) * 100) / Math.abs(eps);
        } else {
          eps = Number(ExtremeDataValue.formatValue(latestAnnualdata.EPS, 2));
          percChg = (displayCurAe - eps) / Math.abs(eps) * 100;
        }
      }
      if (isNaN(percChg)) {
        this.curPercChange = " ";
      } else {
        this.currpercForground = percChg >= 0 ? this.positiveColor : this.negativeColor;
        this.curPercChange = Math.abs(percChg) >= 1000 ? `${ExtremeDataValue.getAbbrivatedString(percChg, 1, "", "#", true)}%` : `${ExtremeDataValue.formatValue(percChg, 0, "", "#", true)}%`;
        this.isCurPercPositive = Math.round(percChg) >= 0;
      }
    }
    if (data.AnnualMiscBlockData.CurRev && data.AnnualMiscBlockData.CurRev === 1) {
      this.currYearTrends = "up";
    } else if (data.AnnualMiscBlockData.CurRev && data.AnnualMiscBlockData.CurRev === 2) {
      this.currYearTrends = "down";
    }
    if (data.AnnualMiscBlockData.CurRev2 && data.AnnualMiscBlockData.CurRev2 === 1) {
      this.currYearTrends2 = "up";
    } else if (data.AnnualMiscBlockData.CurRev2 && data.AnnualMiscBlockData.CurRev2 === 2) {
      this.currYearTrends2 = "down";
    }
    if (data.AnnualMiscBlockData.CurRev3 && data.AnnualMiscBlockData.CurRev3 === 1) {
      this.currYearTrends3 = "up";
    } else if (data.AnnualMiscBlockData.CurRev3 && data.AnnualMiscBlockData.CurRev3 === 2) {
      this.currYearTrends3 = "down";
    }
    this.carryOver = (!StringUtil.isEmpty(this.CurAE) || !StringUtil.isEmpty(this.curPercChange) || !StringUtil.isEmpty(this.currYearTrends)) && data.AnnualMiscBlockData !== null && data.AnnualMiscBlockData.length > 0 && data.AnnualMiscBlockData[0].EPS !== null && data.AnnualMiscBlockData[0].EPS < 0;
    if (data.AnnualMiscBlockData.NxtAE) {
      /* Fix for PANWEB-2057 */
      const nxtAe = data.AnnualMiscBlockData.NxtAE /*   * this.currencyFormat.ratio;   */
      this.nxtEstimate = Math.abs(nxtAe) >= 1000 ? ExtremeDataValue.numberWithCommas(ExtremeDataValue.formatValue(nxtAe, 2)) : ExtremeDataValue.formatValue(nxtAe, 2);
    }
    if (data.AnnualMiscBlockData.NxtAE && data.AnnualMiscBlockData.CurAE) {
      if (currency === "KRW" || currency === "GBP" || currency === "ZAR") {
        displayCurAe = Number(ExtremeDataValue.formatValue(data.AnnualMiscBlockData.CurAE * 100, 2));
        displayNxtAe = Number(ExtremeDataValue.formatValue(data.AnnualMiscBlockData.NxtAE * 100, 2));
      }
      const perChng = ((displayNxtAe - displayCurAe) * 100) / Math.abs(displayCurAe);
      if (isNaN(perChng)) {
        this.nxtPercChange = " ";
      } else {
        this.nxtPercChange =
          perChng === Infinity ?
            '' :
            Math.abs(perChng) >= 1000 ? `${ExtremeDataValue.getAbbrivatedString(perChng, 1)}%` : `${ExtremeDataValue.formatValue(perChng, 0)}%`;
        this.nxtpercForground = perChng >= 0 ? this.positiveColor : this.negativeColor;
        this.isNxtPercPositive = Math.round(perChng) >= 0;
      }
    }
    if (data.AnnualMiscBlockData.NxtRev && data.AnnualMiscBlockData.NxtRev === 1) {
      this.nxtYearTrends = "up";
    } else if (data.AnnualMiscBlockData.NxtRev && data.AnnualMiscBlockData.NxtRev === 2) {
      this.nxtYearTrends = "down";
    }
    if (data.AnnualMiscBlockData.NxtRev2 && data.AnnualMiscBlockData.NxtRev2 === 1) {
      this.nxtYearTrends2 = "up";
    } else if (data.AnnualMiscBlockData.NxtRev2 && data.AnnualMiscBlockData.NxtRev2 === 2) {
      this.nxtYearTrends2 = "down";
    }
    if (data.AnnualMiscBlockData.NxtRev3 && data.AnnualMiscBlockData.NxtRev3 === 1) {
      this.nxtYearTrends3 = "up";
    } else if (data.AnnualMiscBlockData.NxtRev3 && data.AnnualMiscBlockData.NxtRev3 === 2) {
      this.nxtYearTrends3 = "down";
    }
    this.carryOverNextYear = data.AnnualMiscBlockData.CurAE && data.AnnualMiscBlockData.CurAE < 0 && !StringUtil.isEmpty(data.AnnualMiscBlockData.NxtAE);
  }

  tranformToDate(jsonDate) {
    return jsonDate instanceof Date ? jsonDate : DateHelper.parseJsonDate(jsonDate);
  }
}