import { connect } from 'react-redux';
import RIPanelAnnotationContent from "./Containers/RIPanelAnnotationContent";
import RIPanelAnnotationFooter from "./Containers/RIPanelAnnotationFooter";
import RIPanelAnnotationToolContent from "./Containers/RIPanelAnnotationToolContent";
import RIPanelAnnotationToolList from "./Containers/RIPanelAnnotationToolList";
import React, { PureComponent } from "react";

class RIPanelAnnotationInfo extends PureComponent {

  onTabClick(item) {
    this.setState({ selectedTab: item });
  }

  render() {
    return (
      <div className="annotation-region">
        <div className="annotation-header">
          <RIPanelAnnotationToolList />
        </div>
        <div className={this.props.selectedToolType === 0 ? "annotation-content with-foot-icon" : "annotation-content"}>
          <RIPanelAnnotationToolContent />
        </div>
        <div className="annotation-footer">
          <RIPanelAnnotationContent />
          {this.props.selectedToolType === 0 && <RIPanelAnnotationFooter />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ annotationReducers }) => {

  const { selectedToolType } = annotationReducers.annotationMenuReducer;

  return ({
    selectedToolType
  });
}

export default connect(mapStateToProps, null)(RIPanelAnnotationInfo);