import React, { PureComponent } from 'react';
import RotationArithmeticScale from "Utils/Scales/RotationArithmeticScale.js";
import StringUtil from 'StringUtil';
import { each, flatten, max, min } from "underscore";
import ThemeHelper from "ThemeHelper";
import ScalesHelper from "Utils/Scales/ScalesHelper.js";
import PlayPauseControl from './PlayPauseControl';
import ErrorBoundary from 'ErrorBoundary';

class BenchMarkGraph extends PureComponent {

    preparePointsTrendsPath(groupsCountDatasList, innerPartitionfactor, scale, ymaxValue) {
        let trendPathData = "";
        each(groupsCountDatasList, (itm, i) => {
            const y1 = scale.computeY(ymaxValue) - scale.computeY(itm.GrpsCount) + 2;
            const x1 = (groupsCountDatasList.length - i) * innerPartitionfactor;

            if (i === 0) {
                trendPathData += `M${x1},${y1} `;
                this.lastPointOfTrendLine = [x1, y1];
            }
            else trendPathData += `L${x1},${y1} `;
        })
        return trendPathData;
    }

    setScaleLabels(scale) {
        this.scaleIntervals = [];
        scale.mhLabel.forEach((item) => {
            this.scaleIntervals.push(item.hLabel);
        });
    }

    setTrendLines() {
        const scale = new RotationArithmeticScale();
        const trendLineData = this.props.trendLineData;

        if (this.props.dimensions.width <= 0) return;

        const scaleHeight = 75;

        const grpCounts = flatten(trendLineData.map((o) => o.GroupsCountDatasList.map((j) => Number(j.GrpsCount))));

        const ymaxValue = max(grpCounts);
        const yminValue = min(grpCounts);
        const totalwidthofscrubber = 75;
        scale.initYScale(ymaxValue, yminValue, 20, scaleHeight);
        this.setScaleLabels(scale);
        each(trendLineData, (grpCountData) => {
            const groupsCountDatasList = grpCountData.GroupsCountDatasList;
            const innerPartitionfactor = ((this.props.dimensions.width - 76) - totalwidthofscrubber) / (groupsCountDatasList.length - 1);
            switch (grpCountData.GroupsMkt.toUpperCase()) {
                case "C": //DownTrend
                    this.downTrendPolylinePoints = this.preparePointsTrendsPath(groupsCountDatasList, innerPartitionfactor, scale, ymaxValue);
                    break;
                default:
                    this.uptrendPolyLinePoints = this.preparePointsTrendsPath(groupsCountDatasList, innerPartitionfactor, scale, ymaxValue);
                    break;
            }
        });

    }

    setBenchMarkPolyLine() {
        if (this.props.dimensions.width <= 0) return;
        const benchMarkScale = new RotationArithmeticScale();
        const benchMarkData = this.props.benchMarkData;
        const scaleHeight = 75;

        const yminValue = Math.min.apply(Math, benchMarkData.BenchMarkDataItemList.map((o) => o.Price));
        const ymaxValue = Math.max.apply(Math, benchMarkData.BenchMarkDataItemList.map((o) => o.Price));
        const yinterval = ScalesHelper.getInterval(yminValue, ymaxValue, scaleHeight, 10);
        benchMarkScale.initYScale(ymaxValue, yminValue, yinterval, scaleHeight);
        const totalwidthofscrubber = 75;
        //let halfthewidthofscr = totalwidthofscrubber / 2;

        const innerPartitionfactor = ((this.props.dimensions.width - 76) - totalwidthofscrubber) / (benchMarkData.BenchMarkDataItemList.length - 1);
        const benchmarkDataList = benchMarkData.BenchMarkDataItemList;
        let y1 = benchMarkScale.computeY(ymaxValue);
        let x1 = 0;
        this.benchMarkPolylinePoints = `M${x1},${y1} `;
        each(benchmarkDataList, (item, i) => {
            y1 = benchMarkScale.computeY(ymaxValue) - benchMarkScale.computeY(item.Price);
            x1 = i * innerPartitionfactor;
            this.benchMarkPolylinePoints += `L${x1},${y1} `;
            if (i === benchmarkDataList.length - 1) {
                this.lastPointOfBenchMark = [x1, benchMarkScale.computeY(ymaxValue)];
                this.benchMarkPolylinePoints += `L${this.lastPointOfBenchMark[0]},${this.lastPointOfBenchMark[1]}Z`;
            }
        });
    }

    getScaleTextColor() {
        let scaleTextColorClass = "scale-text";
        if (this.props.isUptrendVisible && !this.props.isDowntrendVisible) {
            scaleTextColorClass = "scale-text-uptrend";
        }
        else if (!this.props.isUptrendVisible && this.props.isDowntrendVisible) {
            scaleTextColorClass = "scale-text-downtrend";
        }
        return scaleTextColorClass;
    }

    render() {
        const { isBenchMarkLoading, isUptrendVisible, isDowntrendVisible, tempPositiveStyle, tempNegativeStyle, timeLineData } = this.props;
        const benchMarkStrokeColor = (!isUptrendVisible && !isDowntrendVisible) ? "path behindBgChange" : "path benchmark-polyline-col";
        !StringUtil.isEmpty(this.props.trendLineData) && this.setTrendLines();
        let lastPoint = 2425;
        if (!StringUtil.isEmpty(this.props.benchMarkData)) {
            this.setBenchMarkPolyLine();
            lastPoint = this.lastPointOfBenchMark && this.lastPointOfBenchMark[0] >= 0 ? this.lastPointOfBenchMark[0] : lastPoint;
        }
        else {
            this.benchMarkPolylinePoints = "";
            lastPoint = this.lastPointOfTrendLine && this.lastPointOfTrendLine[0] >= 0 ? this.lastPointOfTrendLine[0] : lastPoint;
        }

        let scaleList = [];
        if (!StringUtil.isEmpty(this.scaleIntervals)) {
            scaleList = this.scaleIntervals.reverse().map((interval, index) => {
                let tempStyle = {}
                if (this.getScaleTextColor().indexOf("uptrend") != -1)
                    tempStyle = { color: ThemeHelper.getThemedBrush("positiveNode") };
                if (this.getScaleTextColor().indexOf("downtrend") != -1)
                    tempStyle = { color: ThemeHelper.getThemedBrush("negativeNode") };
                return (<li className={this.getScaleTextColor()} key={index + 1} style={tempStyle}>{interval}</li>);
            });
        }

        return (
            <div className="graph-bottom-area">
                <div className="graph-range">
                    {(isUptrendVisible || isDowntrendVisible) &&
                        <ul>
                            {scaleList}
                        </ul>
                    }
                </div>
                {isBenchMarkLoading ?
                    <span className="rotation-menu-loading footer-loding"><div className="group-box "><div className="loading loading-spinner"></div></div></span>
                    :
                    <div className="graph-chart-area">
                        <svg className="chart" width="100%" height='100%'>
                            <svg y="22" width="100%" height="75" viewBox={`0 0 ${lastPoint} 75`} preserveAspectRatio="none">
                                {this.benchMarkPolylinePoints != "" ?
                                    <path d={this.benchMarkPolylinePoints} className={benchMarkStrokeColor} strokeWidth={(isUptrendVisible || isDowntrendVisible) ? "0" : "1.75"} pointerEvents="none" fillRule="evenodd" strokeLinejoin="miter" strokeMiterlimit="10"> </path> : null}
                            </svg>
                            <svg y="27" width="100%" height="75" viewBox={`0 0 ${lastPoint} 75`} preserveAspectRatio="none">
                                {isDowntrendVisible ? <path d={this.downTrendPolylinePoints} className="path" style={tempNegativeStyle} strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1.75" fill="none" pointerEvents="none"></path> : ""}
                            </svg>
                            <svg y="27" width="100%" height="75" viewBox={`0 0 ${lastPoint} 75`} preserveAspectRatio="none">
                                {isUptrendVisible ? <path d={this.uptrendPolyLinePoints} className="path" style={tempPositiveStyle} strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth="1.75" fill="none" pointerEvents="none"> </path> : ""}
                            </svg>
                        </svg>

                        {!StringUtil.isEmpty(timeLineData) &&
                            <ErrorBoundary><PlayPauseControl /></ErrorBoundary>}
                    </div>
                }
            </div>
        );
    }
}

export default BenchMarkGraph;