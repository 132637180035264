import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class GetExtrecentdata extends BaseServiceRequest {
    constructor(msid) {
        super();
        super.addRequestUri("extrecentdata.pb");
        super.addPathParameter(msid.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ExtRecentDataResponse";
    }
}