import GetSymbolInfoRequest from '../Requests/GetSymbolInfoRequest.js';
import GetPriceInfoRequest from '../Requests/GetPriceInfoRequest.js';
import BaseServiceApiWCF from 'BaseServiceApiWCF';
import GetSymbolSearchRequest from '../Requests/GetSymbolSearchRequest.js';
import BaseServiceApi from 'BaseServiceApi';
import Cookies from "universal-cookie";
import GetUserAboutModuleInfoRequest from '../Requests/GetUserAboutModuleInfoRequest';
import GetDefaultKpiItemsRequest from "../Requests/GetDefaultKpiItems.js";
import ONeilViewStore from '../../Stores/NavModules/NavList/TabONeil/ONeilViewStore.js';
import GetPrintStartRequest from '../Requests/GetPrintStartRequest';
import GetPrintCancelRequest from '../Requests/GetPrintCancelRequest';
import GetPrintStatusRequest from '../Requests/GetPrintStatusRequest';
const cookies = new Cookies();

const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};


class ConsoleApi {
  constructor() {
    this.builder = BaseServiceApiWCF.getBuilder();
    this.rayData = this.builder.build("RayChartCommon");
  }

  getSymbolInfoData(symbol, cancelable = false) {
    let newSymbol = symbol.replace("&", "%26");
    const req = new GetSymbolInfoRequest(newSymbol);
    if (cancelable) {
      const cancelablePromise = makeCancelable(BaseServiceApiWCF.ProcessServiceCall(req));
      return cancelablePromise;
    }
    return BaseServiceApiWCF.ProcessServiceCall(req);
  }
  getSymbolPriceData(osid, periodicity, type, rt) {

    const req = new GetPriceInfoRequest(osid, periodicity, type, rt);
    return BaseServiceApiWCF.ProcessServiceCall(req);

  }
  getSymbolSearchRequest(symbol, count, rgview = 0, isfinal = 0) {
    //let newSymbol = symbol.replace("&", "%26"); // not needed for Tomcat
    let SearchCount = count;
    if (!count || count == '' || count == undefined) {
      SearchCount = 20;
    }
    const req = new GetSymbolSearchRequest(symbol, SearchCount, rgview, isfinal);
    return BaseServiceApi.processServiceCall(req);
  }

  GetUserAboutModuleInfo() {
    const req = new GetUserAboutModuleInfoRequest();
    return BaseServiceApi.processServiceCall(req);
  }
  GetKpiDefaultInfo = () => {
    const req = new GetDefaultKpiItemsRequest();
    return BaseServiceApi.processServiceCall(req);
  }

  startPrint(symbol, printType, periodicity, listId, searchkeyword) {
    // const printLink = ActiveNav === NavType.NavSymbol ? `http://localhost:3005/panaray/api/symbols/print?type=SINGLE&symbolId=${symbol}` : ActiveNav === NavType.NavCompare && isCompareTab ? `http://localhost:3005/panaray/api/symbols/print?type=COMPARISON` : `http://localhost:3005/panaray/api/symbols/print?type=ROTATIONAL`;
    let listCount = ONeilViewStore.getState().basic.total;;
    console.log(listCount);
    console.clear();

    const req = new GetPrintStartRequest(symbol, printType, periodicity, listId, searchkeyword);
    return BaseServiceApi.processPrintServiceCall(req);
  }

  cancelPrint(requestId) {
    //var requestUrl = 'http://localhost:3005/panaray/api/cancelRequest?requestId=' + requestId

    const req = new GetPrintCancelRequest(requestId);
    return BaseServiceApi.processPrintServiceCall(req);
  }

  getPrintStatus(requestId) {
    // var requestUrl = 'http://localhost:3005/panaray/api/queue/status?requestId=' + requestId;
    const req = new GetPrintStatusRequest(requestId);
    return BaseServiceApi.processPrintServiceCall(req);
  }
}
var consoleApi = new ConsoleApi();
export default consoleApi;
