import React, { Component } from 'react';
import { connect } from 'react-redux';
import ThemeHelper from "ThemeHelper";
import { map, isEmpty } from "underscore";
import PropTypes from 'prop-types';
// import SettingsStore from "SettingsStore";
import LineVisual from '../../../../../../RayCustomControls/Chart/Visuals/LineVisual';

class ExternalDataLine extends Component {


    isToolTipOn = () => {
        let isToolTip = false;
        if (this.Line && this.Line.isToolTip === 1){
            isToolTip = true;
        }

        return isToolTip;
    }

    render() {
        const { isToolTip, timeSeriesData_pricePane, externalDataSettings, majorPeriodicity} = this.props;
        return map(externalDataSettings, (list, index)=>{
            if(list[majorPeriodicity] && list[majorPeriodicity].IsVisibleInPriceMenu && !list[majorPeriodicity].isDateBasedEvents){
                return map(list[majorPeriodicity].priceChart.children, (item, key)=>{
                    if(list[majorPeriodicity].priceChart.children && !isEmpty(list[majorPeriodicity].priceChart.children) && !isEmpty(timeSeriesData_pricePane) && !isEmpty(timeSeriesData_pricePane[index])){
                        <LineVisual key={key} isToolTip={isToolTip} ref={(ref) => { this.Line = ref; }} DataSource={timeSeriesData_pricePane[index][key]}
                            LineColor={ThemeHelper.getThemedBrush(item.Color)} LineThickness={1} Draggable={true} lineID={item.Name} Type="EXD"/>
                    }
                    else{
                        return null;
                    }
            })
            }
        })
    }
}

const mapStateToProps = (state) => {
    const { externalDataSettings, majorPeriodicity } = state.DatagraphReducers.PriceChartReducer;
    const { timeSeriesData_pricePane } = state.externalDataUploader.externalDataUploadSymbolReducer
    return { timeSeriesData_pricePane, externalDataSettings, majorPeriodicity }
}

export default connect(mapStateToProps)(ExternalDataLine);