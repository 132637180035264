import { DataGraphConstants } from "../../Constants/DataGraphConstants";
import { ExternalDataConstants } from "../../Constants/ExternalDataUploader";
import { PrintMode } from "../../print/printmode";

const { ActionTypes } = ExternalDataConstants;

const initState = {
    settings: {},
    externalDataResults: {},
    isShowModal: false,
    isShowColorPalette: false,
    selectedMenu: '',
    editDialogMenu: {},
    editDialogAdvancedMenu: {},
    activeItem: {},
    timeSeriesData_pricePane: {},
    rawTimeSeriesData_pricePane: {},
    timeSeriesData_indicatorPane: {},
    timeSeriesData_indicatorPane_AllPoints: {},
    eventSeriesData: {},
    eventSeriesData_AllPoints: {},
    height: 0,
    isShowAdvanceSetting: false,
    isShowExternalDataPointer: false,
    externalInfoText: null,
    expandedEventBadgeData: {},
    riPanelData: {},
    loading: true,
    PDR_info: { ['headerData']: [] },
    isPriceChartSettings: false,
    theme: 'dark',
    shouldHoldOnUserEvents: false,
    settingsObjectIsUpdated: false,
    isShowToggleButton: false,
    listItems: null,
    clearsEXDReqInitiated: false,
    externalDataText: '',
    headerdatalength:0
}

const externalDataUploadSymbolReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_CURRENT_SETTINGS_OBJECT:
            return {
                ...state,
                settings: action.settings,
                settingsObjectIsUpdated: action.settingsObjectIsUpdated
            }
        case ActionTypes.GET_EXTERNAL_DATA_SUB_MENU:
            return {
                ...state,
                listItems: null,
            }
        case ActionTypes.UPDATE_EXD_LIST_EXPLORER_RAW_DATA:
            return {
                ...state,
                listItems: action.listItems,
            }
        case ActionTypes.UPDATE_EXTERNAL_DATA_API_RESULT:
            return {
                ...state,
                exdResult: action.exdResult
            }
        case ActionTypes.UPDATE_TIME_SERIES_DATA_OF_PRICE_PANE:
            return {
                ...state,
                timeSeriesData_pricePane: action.timeSeriesData_pricePane,
                rawTimeSeriesData_pricePane: action.rawTimeSeriesData_pricePane,
                isShowExternalDataPointer: action.isShowExternalDataPointer,
                isShowToggleButton: action.isShowToggleButton
            }
        case ActionTypes.UPDATE_TIME_SERIES_DATA_OF_INDICATOR_PANE:
            return {
                ...state,
                timeSeriesData_indicatorPane: {...action.timeSeriesData_indicatorPane},
                timeSeriesData_indicatorPane_AllPoints: {...action.timeSeriesData_indicatorPane_AllPoints}
            }
        case ActionTypes.UPDATE_EVENT_SERIES_DATA:
            return {
                ...state,
                eventSeriesData: action.eventSeriesData,
                eventSeriesData_AllPoints: action.eventSeriesData_AllPoints,
                clearsEXDReqInitiated: false,
            }
        case ActionTypes.UPDATE_EXTERNAL_DATA_REQ_INITIATED:
            return {
                ...state,
                clearsEXDReqInitiated: action.clearsEXDReqInitiated,
            }
        case ActionTypes.UPDATE_HEIGHT:
            return {
                ...state,
                height: action.height
            }
        case ActionTypes.OPEN_EXTERNAL_DATA_EDIT_DIALOG:
            return {
                ...state,
                isShowModal: !state.isShowModal,
                selectedMenu: action.item,
                editDialogMenu: action.editDialogMenu,
                editDialogAdvancedMenu: action.editDialogAdvancedMenu,
                isPriceChartSettings: action.isPriceChartSettings,
                indicatorListId: null,
            }
        case ActionTypes.CLOSE_EXTERNAL_DATA_EDIT_DIALOG:
            return {
                ...state,
                isShowModal: action.isShowModal
            }
        case ActionTypes.HIDE_COLOR_PALETTE:
            return {
                ...state,
                isShowColorPalette: action.isShowColorPalette,
                activeItem: action.activeItem
            }
        case ActionTypes.UPDATE_ACTIVE_ITEM:
            return {
                ...state,
                activeItem: action.activeItem
            }
        case ActionTypes.SHOW_ADVANCE_SETTINGS:
            return {
                ...state,
                isShowAdvanceSetting: action.isShowAdvanceSetting
            }
        case ActionTypes.SHOW_EXTERNAL_INFO_TEXT:
            return {
                ...state,
                externalInfoText: action.externalInfoText
            }
        case ActionTypes.UPDATE_EXPANDED_EVENT_BADGE_DATA:
            return {
                ...state,
                expandedEventBadgeData: action.expandedEventBadgeData
            }
        case ActionTypes.UPDATE_RI_PANEL_DATA:
            return {
                ...state,
                riPanelData: action.riPanelData,
                loading: action.loading
            }
        case ActionTypes.UPDATE_PDR_TO_REDUCER:{
            let externalDataText = '';
            if (action.PDR_info.headerData.length > 0) {
            const recentRow = action.PDR_info.recentRow;
            const recentRowData = action.PDR_info.headerData.find((item) => item.sequenceNo === recentRow);
            const metric = recentRowData.metric;
            externalDataText = `${metric}: ${recentRowData.value}`;
            } else {
            externalDataText = '';
            }
            return {
                ...state,
                PDR_info: action.PDR_info,
                externalDataText,
                headerdatalength:action.PDR_info.headerData.length 
            }
        }
        case ActionTypes.TOGGLE_EXT_DATA_ITEM:{
            let externalDataText = '';
            if (state.PDR_info.headerData.length > 0) {
            const recentRow = (state.PDR_info.recentRow + 1) % state.PDR_info.headerData.length;
            const recentRowData = state.PDR_info.headerData.find((item) => item.sequenceNo === recentRow);
            const metric = recentRowData.metric;
            externalDataText = `${metric}: ${recentRowData.value}`;
            state.PDR_info.recentRow = recentRow;
            PrintMode.updateExternalScore(recentRow);
            } else {
            externalDataText = '';
            }
            
            return {
                ...state,
                externalDataText
            }
        }
        
        case ActionTypes.EXD_THEME_CHANGED:
                return {
                    ...state,
                    theme: action.theme
                }
        case ActionTypes.HOLD_ON_USER_EVENTS:
            return {
                ...state,
                shouldHoldOnUserEvents: action.shouldHoldOnUserEvents
            }
        case DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY:
            return {
                ...state,
                externalDataResults: {},
            }
        case ActionTypes.UPDATE_EXTERNAL_INDICATOR:
            return {
                ...state,
                indicatorListId: action.indicatorListId,
            }
        default:
            return state
    }
}

export default externalDataUploadSymbolReducer;