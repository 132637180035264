import Serializable from "Serializable";
export default class EPSRChartSettings extends Serializable {
  constructor() {
    super();
  }

  getDefaultCurrentFiscalYear() {
    return true;
  }

  getDefaultNextFiscalYear() {
    return true;
  }

  getDefaultCurrentFiscalQuarter() {
    return true;
  }

  getDefaultCurrentFiscalYearObj() {
    const RSILine = [{ isActive: true, color: "P6600996734CC", weight: 1 }];
    return RSILine.map((item) => this.getSerizableObject(item));
  }

  getDefaultNextFiscalYearObj() {
    const OverboughtObj = [{ isActive: false, color: "P990000CC0100", weight: 1 }];
    return OverboughtObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultCurrentFiscalQuarterObj() {
    const OversoldObj = [{ isActive: false, color: "P9866FF340066", weight: 1 }];
    return OversoldObj.map((item) => this.getSerizableObject(item));
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

  getDefaultseqNumber(){
    return 1;
  }
  onSeralisedInfoRecieved(serializedValue) {
    this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
    this.Height = serializedValue.Height ? serializedValue.Height : 100;
    this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
    this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
    this.CurrentFiscalYear = serializedValue.CurrentFiscalYear !== undefined ? serializedValue.CurrentFiscalYear : this.getDefaultCurrentFiscalYear();
    this.NextFiscalYear = serializedValue.NextFiscalYear !== undefined ? serializedValue.NextFiscalYear : this.getDefaultNextFiscalYear();
    this.CurrentFiscalQuarter = serializedValue.CurrentFiscalQuarter !== undefined ? serializedValue.CurrentFiscalQuarter : this.getDefaultCurrentFiscalQuarter();
    this.CurrentFiscalYearColor = serializedValue.CurrentFiscalYearColor ? serializedValue.CurrentFiscalYearColor : this.getDefaultCurrentFiscalYearObj();
    this.NextFiscalYearColor = serializedValue.NextFiscalYearColor ? serializedValue.NextFiscalYearColor : this.getDefaultNextFiscalYearObj();
    this.CurrentFiscalQuarterColor = serializedValue.CurrentFiscalQuarterColor ? serializedValue.CurrentFiscalQuarterColor : this.getDefaultCurrentFiscalQuarterObj();
    this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : this.getDefaultseqNumber();
  }

  getDataObject() {
    return {
      IsVisible: this.IsVisible,
      Height: this.Height,
      IsAvailable: this.IsAvailable,
      IsRelative: this.IsRelative,
      CurrentFiscalYear: this.CurrentFiscalYear,
      NextFiscalYear: this.NextFiscalYear,
      CurrentFiscalQuarter: this.CurrentFiscalQuarter,
      CurrentFiscalYearColor: this.CurrentFiscalYearColor,
      NextFiscalYearColor: this.NextFiscalYearColor,
      CurrentFiscalQuarterColor: this.CurrentFiscalQuarterColor,
      seqNumber: this.seqNumber
    }
  }

  getDefaultEPSRChartSettings() {
    this.IsVisible = true;
    this.Height = 100;
    this.IsAvailable = true;
    this.IsRelative = false;
    this.CurrentFiscalYear = this.getDefaultCurrentFiscalYear();
    this.NextFiscalYear = this.getDefaultNextFiscalYear();
    this.CurrentFiscalQuarter = this.getDefaultCurrentFiscalQuarter();
    this.CurrentFiscalYearColor = this.getDefaultCurrentFiscalYearObj();
    this.NextFiscalYearColor = this.getDefaultNextFiscalYearObj();
    this.CurrentFiscalQuarterColor = this.getDefaultCurrentFiscalQuarterObj();
    this.seqNumber = this.getDefaultseqNumber();
    return this;
  }

  getObjectMapKey() {
    return "EPSRChartSettings";
  }
}
