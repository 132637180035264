import { RiPanelConstants } from "../../Constants/RiPanelConstants";
const { ActionTypes } = RiPanelConstants;

export const toggleRiPanel = () => ({
    type: ActionTypes.TOGGLE_RI_PANEL
});

export const handleTabChange = (newTab) => ({
    type: ActionTypes.HANDLE_TAB_CHANGE,
    newTab
});

export const openAlertAboutDialog = (isAlertAboutOpen) => ({
    type: ActionTypes.TOGGLE_ALERT_ABOUT_DIALOG,
    isAlertAboutOpen
});

export const updateRiPanelSettingsData=(isResearchAnalystOpen, isFoucsPanelOpen, isResearchPanelOpen) =>({
    type: ActionTypes.UPDATE_RI_PANEL_SETTINGS_DATA,
    isResearchAnalystOpen, isFoucsPanelOpen, isResearchPanelOpen
});


/* Broker Reports */

export const setVisitedItemsGrey = (id, osid) => ({
    type: ActionTypes.SET_VISITED_ITEMS_GREY,
    id,
    osid
});

export const updateTooltipStyle = (style) => ({
    type: ActionTypes.UPDATE_TOOLTIP_STYLE,
    style
});
export const switchFRVisibility = (visible) => ({
    type: ActionTypes.TOGGLE_FR_VISIBILITY,
    visible
});
export const lastOnlyVisibility = () => ({
    type: ActionTypes.TOGGLE_FR_LAST_ONLY
});


/* CheckList */

export const onCheckListResize = (height) => ({
    type: ActionTypes.ON_CHECKLIST_RESIZE,
    height
});

export const setChecklistActiveItems = (isShowDetailDailog, screenName, activeListItems) => ({
    type: ActionTypes.SET_CHECKLIST_ACTIVE_ITEMS,
    isShowDetailDailog, screenName, activeListItems
});

export const getChecklistData = (colSetId, sourceColSetId) => ({
    type: ActionTypes.GET_CHECKLIST_DATA,
    colSetId, sourceColSetId
});

export const onThresholdCheckboxChanged = (isHide) => ({
    type: ActionTypes.UPDATE_THRESHOLD_FLAG,
    isHide
});
export const updateSimilarFundsRelative = () => ({
    type: ActionTypes.UPDATE_SIMILAR_FUNDS_RELATIVE,
});

/* Ownership */
export const toggleExpandAll = () => ({
    type: ActionTypes.OWNERSHIP_INFO_EXPAND_HUB_PANEL
})
export const toggleSalesRep = (index) => ({
    type: ActionTypes.OWNERSHIP_INFO_TOGGLE_SALES_REP_HUB_PANEL,
    index
})
export const changeDispView = (view) => ({
    type: ActionTypes.OWNERSHIP_INFO_CHANGE_DISPALY_VIEW,
    view
})
export const updateHubRole = () => ({
    type: ActionTypes.OWNERSHIP_INFO_UPDATE_HUB_ROLE,
})
export const getHubUserRole = (isLogin) => ({
    type: ActionTypes.OWNERSHIP_INFO_SET_HUB_USER_ROLE,
    isLogin
})

/* Allocation Info */
export const changeDimensions = (dim) => ({
    type: ActionTypes.ALLOCATION_INFO_CHANGE_DIMENSIONS,
    dim
})

/* Management Info */
export const getFundDetail = (item, index) => ({
    type: ActionTypes.MANAGEMENT_INFO_GET_RELATED_FUND_LIST,
    item, index
})
export const changeRelativeYTD = (IsRelativeYTD) => ({
    type: ActionTypes.MANAGEMENT_INFO_SET_YTD_STATUS,
    IsRelativeYTD
})
export const setActiveIndex = (activeIndex) => ({
    type: ActionTypes.MANAGEMENT_INFO_SET_ACTIVE_INDEX,
    activeIndex
})
export const isDetailPage = (isDetail) => ({
    type: ActionTypes.MANAGEMENT_INFO_DETAIL_PAGE,
    isDetail
})

/* News Info */
export const onOpenNewsSettingDialog = (isOpen, defaultIds = undefined) => ({
    type: ActionTypes.NEWS_INFO_OPEN_SETTINGS,
    isOpen, defaultIds
})
export const changeSource = (chosenIds) => ({
    type: ActionTypes.NEWS_INFO_CHANGE_SOURCE,
    chosenIds
})
export const restoreDefaultNews = () => ({
    type: ActionTypes.NEW_INFO_RESTORE_DEFAULT_NEWS
})
export const submitNewsChanges = (chosenIds) => ({
    type: ActionTypes.NEWS_INFO_CHANGE_RI_NEWS,
    chosenIds
})
export const reloadRiNews = (symbolInfo) => ({
    type: ActionTypes.GET_NEWS_INFO,
    symbolInfo
})
export const setLoadingState = (isLoading) => ({
    type: ActionTypes.NEWS_INFO_SET_LOADING,
    isLoading
})
export const setActiveNewsItem = (index) => ({
    type: ActionTypes.NEWS_INFO_SET_ACTIVE_ITEM,
    index
})