import React, { PureComponent } from 'react';
import {LabelText} from '../Presentational';

class ScaleLabels extends PureComponent {
    render() {
        const { mhLabels } = this.props;
        return (
            <svg className="svg-scale scale-labels">
                {mhLabels && mhLabels.map((itm, i) => itm.Label && <LabelText key={i} textAnchor="end" isHighlighted={true} textValue={itm.Label} dx="4" dy="4" textPosX = {32} textPosY = {itm.y} />)}
            </svg>
        );
    }
}

export default ScaleLabels;

