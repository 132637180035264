import React from 'react';

import { Glyphicon } from "react-bootstrap";
import { setSort } from "../../../Actions/ShareAction";

const SortHeader = ({
  sortName,
  displayName,
  currentSortName,
  isSortReversed,
  showSort
  }) => {

  const handleSetSort = () =>
    setSort(currentSortName, sortName, isSortReversed);

  return (
    <div className="sorter" data-test-id="sort-header">
      <a onClick={handleSetSort}>{displayName}</a>
      {
        currentSortName === sortName &&
        <span className=
          { showSort ? 
            isSortReversed
              ?
              "arrow-up"
              :
              "arrow-down"
            : ''
          }
        >
        </span>
      }
    </div>
  )
}

export default SortHeader;