import AppDispatcher from 'AppDispatcher';
import { LocalizationConstants } from '../../Constants/LocalizationConstants.js';
import { EventEmitter } from 'events';
import Localization from "../../Utils/TranslateUtil";
import LangType from "../../Constants/LangType";

const CHANGE_EVENT = 'change';


class LocalizationStoreClass extends EventEmitter {
  constructor(){
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    // first time, we use default language setting depands on browser language
    let locale = navigator.language || navigator.browserLanguage;
    if(locale && locale.toLowerCase() === 'zh-cn'){
      this.defaultDic = require('json-loader!yaml-loader!Asset/localization/zh-CN.yml');
      this.lang = LangType.ZH_CN;
    } else {
      this.defaultDic = require('json-loader!yaml-loader!Asset/localization/en-US.yml');
      this.lang = LangType.RAY_DEFAULT;
    }

    this.setTranslateDic();
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  setTranslateDic(dic){
    Localization.setTexts({...this.defaultDic, ...dic});
  }

  getTranslatedData (key, defaultText, ...args) {
    let options = { notFound: defaultText };
    if(args && args.length){
      args.forEach((x, i) => {
        options['' + i] = x;
      });
    }
    return Localization.translate(key, options);
  }

  setLang(lang){
    this.lang = lang;
    switch (this.lang){
      case LangType.ZH_CN:
        this.defaultDic = require('json-loader!yaml-loader!Asset/localization/zh-CN.yml');
        break;
      default:
        this.defaultDic = require('json-loader!yaml-loader!Asset/localization/en-US.yml');
    }

    this.setTranslateDic();
  }

  getLang(){
    return this.lang;
  }


  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
        case LocalizationConstants.ActionTypes.SET_TRANSLATE:
          this.setTranslateDic(data);
          this.emit(CHANGE_EVENT);
          break;
        default:
            return true;
    }
    return true;
    }
}

const LocalizationStore = new LocalizationStoreClass();
export default LocalizationStore;
