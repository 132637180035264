import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FundamentalTableCssType from 'Constants/FundamentalTableCssType.js';
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js';
import LocalizationStore from 'LocalizationStore';
import TabFundamentalsStore from 'Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js';


export default class FundamentalsFrozonCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };
    }
    onHover(hover) {
        this.setState({ hover: hover });
    }
    getRowHeaderClassName(data) {
        let className = "";
        switch (data.cssType) {
            case FundamentalTableCssType.EmptyCssClass: className = "head-table head-table-gray-txt"; break;
            case FundamentalTableCssType.BoldonlyCssClass: className = "head-table fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.ToplevelCssClass: className = "head-table head-table-bold head-table-color head-table-green-txt medium-normal "; break;
            case FundamentalTableCssType.DoubleindentCssClass: className = "head-table head-table-Indent-1"; break;
            case FundamentalTableCssType.TotalCssClass: className = "head-table head-table-Indent-2 head-table-bold  fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.TripleindentCssClass: className = "head-table head-table-Indent-2"; break;
            case FundamentalTableCssType.CfnetCssClass: className = "head-table head-table-Indent-2 head-table-bold"; break;
            case FundamentalTableCssType.TripleindentoverlineCssClass: className = "head-table head-table-Indent-2"; break;
            case FundamentalTableCssType.SubtotalCssClass: className = "head-table head-table-Indent-1 fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.GrandtotalCssClass: className = "head-table head-table-bold head-table-Indent-2 medium-normal fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.TripleindenttotalCssClass: className = "head-table head-table-Indent-2 fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.InitialtotalCssClass: className = "head-table head-table-bold medium-normal fundamental-frozen-bdrTop head-table-green-txt"; break;
            case FundamentalTableCssType.PosttotalCssClass:
            case FundamentalTableCssType.DoubleindentoverlineCssClass: className = "head-table head-table-Indent-1 fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.Cfnetalt2CssClass: className = "head-table head-table-Indent-2 head-table-bold medium-normal fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.PrimarytotalCssClass: className = "head-table head-table-Indent-1 head-table-bold medium-normal fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.CfnetaltCssClass: className = "head-table head-table-Indent-2 head-table-bold medium-normal fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.FourindentsCssClass: className = "head-table head-table-Indent-3 fundamental-frozen-bdrTop"; break;
            case FundamentalTableCssType.AllFilterSectionHeaderCssClass: className = "head-table head-table-section-header fundamental-frozen-bdrTop"; break;
            default: break;
        }
        return className;
    }

    getCategoryTypeClassName(categoryType) {
        let className = "head-table";
        if (categoryType === LocalizationStore.getTranslatedData("Ratis_VALUATION", "VALUATION")
            || categoryType === LocalizationStore.getTranslatedData("Ratis_LIQUIDITY", "LIQUIDITY")
            || categoryType === LocalizationStore.getTranslatedData("Ratis_ACTIVITY", "ACTIVITY")
            || categoryType === LocalizationStore.getTranslatedData("Ratis_LEVERAGE", "LEVERAGE")
            || categoryType === LocalizationStore.getTranslatedData("Ratis_PROFITABILITY", "PROFITABILITY")
            || categoryType === LocalizationStore.getTranslatedData("Ratis_OTHER", "OTHER")) {
            className += " head-table-color";
        }
        return className;
    }

    getFrozenRowsTranslatedData(report) {
        const settings = this.props.settings;
        if (report.cssType === FundamentalTableCssType.AllFilterSectionHeaderCssClass) {
            return TabFundamentalsStore.getReportTypeString(settings.FundamentalReportType, report.filterType).replace('{0}', settings.SymbolInfo.CompanyName);
        } else {
            let strPrefix = "";
            if (settings.FundamentalReportType === FundamentalTabReportType.Balancesheet)
                strPrefix = "BS_";
            else if (settings.FundamentalReportType === FundamentalTabReportType.Income)
                strPrefix = "IS_";
            else if (settings.FundamentalReportType === FundamentalTabReportType.Cashflow)
                strPrefix = "CF_";
            return LocalizationStore.getTranslatedData(strPrefix + report.cells[0].replace(/\s/g, '').toUpperCase(), report.cells[0]);
        }
    }


    render() {
        const report = this.props.report;
        const fundamentalReportType = this.props.settings.FundamentalReportType;
        const clsHover = this.state.hover ? "fundamental-left-hover" : " ";
        
        return (fundamentalReportType !== FundamentalTabReportType.Ratios ?
            <tr className={clsHover}
                onMouseOver={() => { this.props.syncHover(this.props.index, true) }}
                onFocus={() => this.props.syncHover(this.props.index, true)}
                onMouseLeave={() => { this.props.syncHover(this.props.index, false) }}
                onMouseDown={() => this.props.onMouseClick()}>
                <td className={this.getRowHeaderClassName(report)} style={{ width: this.props.width }} ref={(ref) => { this.frozenName = ref }}>
                    {this.getFrozenRowsTranslatedData(report)}
                </td>
            </tr> 
            :
            <tr className={clsHover}
                onMouseOver={() => { this.props.syncHover(this.props.index, true) }}
                onFocus={() => this.props.syncHover(this.props.index, true)}
                onMouseLeave={() => { this.props.syncHover(this.props.index, false) }}
                onMouseDown={() => this.props.onMouseClick()}>
                <td className={this.getCategoryTypeClassName(report[0])} style={{ width: this.props.width }} ref={(ref) => { this.frozenName = ref }}>
                    {LocalizationStore.getTranslatedData(`RATIO_${report[0].replace(/\s/g, '').toUpperCase()}`, report[0])}
                </td>
            </tr>
        );
    }
}

FundamentalsFrozonCell.propTypes = {
    index: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    syncHover: PropTypes.func.isRequired,
  };