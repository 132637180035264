const { ActionTypes } = VolumeChartConstants;
import AvolScale from "../../../Utils/Scales/AVolumeScale.js";
import CalcVolumeMA from "../../../Utils/Calcs/VolumeMA.js";
import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import GraphComponent from "../../../Constants/GraphComponent.js";
import GraphType from "../../../Constants/GraphType.js";
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../Constants/SmartViewType.js";
import StringUtil from "../../../Utils/StringUtil.js";
import SymbolType from "SymbolType";
import { TabDataGraphConstants } from "../../../Constants/TabDataGraphConstants";
import VolScale from "../../../Utils/Scales/LogVolumeScale.js";
import { VolumeChartConstants } from "../../../Constants/VolumeChartConstants";
import WorkSpaceRealTimePrices from "../../../Utils/RealTimeHelper/WorkSpaceRealTimePrices.js"
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getDatagraphStates, getVolumeChartStates, priceChartReducerselect } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";

function* prepareVolumeData() {
    const dataGraphStates = yield select(getDatagraphStates);
    const HasVolume = dataGraphStates.pricePanelData.HsfData.HasVolume;
    if (HasVolume) {
        const Height =  getHeight();
        yield call(processVolumeData, 0, HasVolume, dataGraphStates, Height || 1);
        // if (!dataGraphStates.isReceivedQuote) {
        // } else {
        //     yield call(lastVolumeBar, dataGraphStates, Height || 1)
        // }
    } else {
        yield put({
            type: VolumeChartConstants.ActionTypes.CLEAR_VOLUME_DATA
        })
    }
}

function* processVolumeData( lastNode, HasVolume, dataGraphStates, Height) {
    try {
        const { nodeWidth, pricePanelData, startXPoint, isIntraday, videoMode, padding, periodicity, viewsSettings, leftScaleWidth, rightScaleWidth, nodeCount, majorPeriodicity, firstNodeIndex, SymbolInfo, IsNASDAQBasic} = dataGraphStates;
        const HSFResults = pricePanelData.HsfData.HSFResults;
        if (!HSFResults || !viewsSettings.VolumeBlockSettings) {
            yield put({type: ActionTypes.CLEAR_VOLUME_DATA});
            return;
        }
        let xAxis = startXPoint;
        const lastnodeIndex = nodeCount;
        let nodes = 0;
        const mhLines = [];
        let lastDataNode = Math.min(HSFResults.length - 1, lastnodeIndex);
        let minVolume = Number.POSITIVE_INFINITY;
        let maxVolume = Number.NEGATIVE_INFINITY;
        let avolume = 0;
        let i = lastNode;

        /* for FUTURES */
        const OpenInterestSettings = viewsSettings.OpenInterestSettings ? viewsSettings.OpenInterestSettings[majorPeriodicity] : {}
        const isInterestVisible = OpenInterestSettings.IsVisible;
        const priceMenu = [{ header: 'Open Interest', isActive: OpenInterestSettings.IsVisible || false, graphComponent: GraphComponent.OpenInterest }];
        const lineColor = OpenInterestSettings.lineColor || 'P4C4C4C999999'
        const lineThickness = OpenInterestSettings.lineThickness
        for (; i <= lastDataNode; i++) {
            if (xAxis > 0 && HSFResults[i]._volume !== undefined && HSFResults[i].IsVisible) {
                break;
            }
        }

        let skipFirst = majorPeriodicity === GraphType.Intraday ||  majorPeriodicity === GraphType.Daily || HSFResults[i]._volume < 100;

        for (; i <= lastDataNode; i++) {
            if (xAxis > 0 && HSFResults[i]._volume !== undefined && HSFResults[i].IsVisible) {
                if (!skipFirst) {
                    if (maxVolume < HSFResults[i]._volume) {
                        maxVolume = HSFResults[i]._volume;
                    }
                    if (minVolume > HSFResults[i]._volume && HSFResults[i]._volume > 1) {
                        minVolume = HSFResults[i]._volume;
                    }
                    avolume += HSFResults[i]._volume;
                    nodes++;
                }
                skipFirst = false;
            }
            if (isInterestVisible !== null) {
                xAxis -= 4;
            } else {
                xAxis -= nodeWidth;
            }
        }

        if (isInterestVisible) {
            lastDataNode = Math.min(lastDataNode, HSFResults.length - 1);
            let n = lastNode;
            let xAxis2 = startXPoint;
            for (; n <= lastDataNode; n++) {
                if (xAxis2 > 0 && HSFResults[n].OpenInterest !== undefined && HSFResults[n].IsVisible) {
                    if (!skipFirst) {
                        if (maxVolume < HSFResults[n].OpenInterest) {
                            maxVolume = HSFResults[n].OpenInterest;
                        }
                        if (minVolume > HSFResults[n].OpenInterest && HSFResults[n].OpenInterest > 1) {
                            minVolume = HSFResults[n].OpenInterest;
                        }
                    }
                    skipFirst = false;
                }
                xAxis2 -= 4;
            }
        }

        avolume = avolume / nodes;
        const VolumeBlockSettings = viewsSettings.VolumeBlockSettings[majorPeriodicity];
        const scaleText = VolumeBlockSettings.IsLogScale ? "LOG" : "LIN";
        const scale = VolumeBlockSettings.IsLogScale ? new VolScale() : new AvolScale();
        scale.SetHorizontalGrid(mhLines, minVolume, maxVolume, avolume, Height);
        scale.mhLines = mhLines;
        const volumeData = volumeBars(startXPoint, HSFResults, Height, HasVolume, nodeWidth, nodeCount, firstNodeIndex, padding, scale);

        const OpenInterestLineData = volumeOpenInterest(startXPoint, padding, HSFResults, scale)
        const openTime = DateHelper.parseJsonDate(SymbolInfo.ExchangeOpenTime, SymbolInfo.MomentZoneID);
        const closeTime = DateHelper.parseJsonDate(SymbolInfo.ExchangeCloseTime, SymbolInfo.MomentZoneID);
        const LastTradeDate = DateHelper.parseJsonDate(SymbolInfo.LastTradeDate, SymbolInfo.MomentZoneID);
        const TradeOpen = new Date(LastTradeDate.getFullYear(), LastTradeDate.getMonth(), LastTradeDate.getDate(), openTime.getHours(), openTime.getMinutes());
        const TradeClose = new Date(LastTradeDate.getFullYear(), LastTradeDate.getMonth(), LastTradeDate.getDate(), closeTime.getHours(), closeTime.getMinutes());
        
        const prState = yield select(priceChartReducerselect);
        let lineData = [];
        const index = getIndex(SymbolInfo);
        const pctIntoTrading = pctIntoTradings(index, SymbolInfo, periodicity, dataGraphStates.isHistoric, dataGraphStates.endDate, IsNASDAQBasic, TradeOpen, TradeClose);
        if (majorPeriodicity !== GraphType.Intraday) {
            lineData = volumeMA(pctIntoTrading, startXPoint, HSFResults, periodicity, lastNode, scale,  nodeWidth, dataGraphStates.isHistoric);
        }
        const volMALineData = lineData;
        const isTrade = WorkSpaceRealTimePrices.TradingHours({ SymbolInfo, TradeClose, TradeOpen})
        const Width = getWidth();
        const showPointer = !isIntraday && videoMode === false && HSFResults[padding]._volume > 0;
        const notDelayed = pricePanelData.HsfData.NotDelayed;
        const { lastNodeVolume, volumePercentChange, volumePercentChangeStr, pointerYValue } = getPointer( dataGraphStates.isHistoric ? 1 : pctIntoTrading, index , volMALineData, volumeData, Height, prState, dataGraphStates, TradeOpen);

        yield put({
            type: ActionTypes.SET_VOLUME_CHART_DATA,
            newState: { volumeData,
                        volMALineData,
                        leftScaleWidth,
                        rightScaleWidth,
                        scale,
                        Height,
                        Width: Width || 1,
                        nodeWidth,
                        priceMenu,
                        OpenInterestLineData,
                        OpenInterestSettings,
                        isTrade,
                        showPointer,
                        notDelayed,
                        scaleText,
                        lastNodeVolume,
                        volumePercentChange,
                        volumePercentChangeStr,
                        pointerYValue,
                        isInterestVisible,
                        lineColor,
                        lineThickness,
                        VolumeBlockSettings
                    }
        })
    } catch (error) {
        console.log(`Error occurs in  processVolumeData - VolumeChartSaga.js ${error}`)
    }
}

function volumeOpenInterest(xAxis, padding, hsfResults, scale) {
    try {
        const prcLength = hsfResults.length;
        let yPrice = scale.ComputeY(hsfResults[0].OpenInterest);
        const openInterestData = new Array(prcLength);
        let x = 0;
        for (let j = padding; j < prcLength; j++) {
            xAxis -= 4;
            if (xAxis < 0) { break; }
            yPrice = scale.ComputeY(hsfResults[j].OpenInterest);
            const info =
            {
                yPrice: yPrice,
                xAxis: xAxis,
            };
            openInterestData[x] = info;
            x++;
        }
        return openInterestData;
    } catch (error) {
        console.log(`Error occurs in  volumeOpenInterest - VolumeChartSaga.js ${error}`)
    }
}

function getHeight() {
    const tabDatagraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
    return tabDatagraphSettings.volumeChartDimension.height;
}

function getWidth() {
    const tabDatagraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
    return tabDatagraphSettings.volumeChartDimension.width;
}

function volumeBars(startXPoint, graphData, Height, HasVolume, nodeWidth, nodeCount, firstNodeIndex, padding, scale) {
    try {
        let xAxis = startXPoint;
        const lastDataNode = Math.min(graphData.length - 1, nodeCount);

        const chartData = {
            highPoints: [],
            lowPoints: []
        };
        if ((firstNodeIndex + padding) <= lastDataNode) {
            chartData.allPoints = new Array(lastDataNode - (firstNodeIndex + padding));
        } else {
            chartData.allPoints = new Array(lastDataNode + 1);
        }
        let index = 0;
        let graphPointer = padding;
        let yVol;

        for (let j = padding; j <= lastDataNode; j++) {
            if (!graphData[graphPointer].IsVisible) {
                xAxis -= nodeWidth;
                graphPointer++;
                continue;
            }
            if (xAxis < 0) {
                break;
            }
            yVol = scale.ComputeY(graphData[graphPointer]._volume);
            if ((yVol - scale.Height) < 2) {
                yVol = yVol - 2;
            }
            const info = {
                Date: graphData[graphPointer].Date,
                yHigh: yVol,
                yLow: Height,
                xAxis: xAxis,
                IsVisible: (graphData[graphPointer]._volume && graphData[graphPointer]._volume > 0) || !HasVolume,
                UpTick: graphData[graphPointer].UpTick
            };
            xAxis -= nodeWidth;
            if (graphData[graphPointer].UpTick) {
                chartData.highPoints.push(info);
            } else {
                chartData.lowPoints.push(info);
            }
            chartData.allPoints[index] = info;
            index++;
            graphPointer++;
        }

        return chartData;
    } catch (error) {
        console.log(`Error occurs in  volumeBars - VolumeChartSaga.js ${error}`)
    }
}

function volumeMA(pctIntoTrading, startx, hsfData, periodicity, lastNode, scale, nodeWidth, isHistoric) {
    try {
        let vMAValues = [];
        let maPeriod = 6;
        const calcVolumeMA = new CalcVolumeMA();
        if (periodicity === GraphType.Daily) {
            maPeriod = 50;
        }
        if (periodicity === GraphType.Weekly) {
            maPeriod = 10;
        }

        vMAValues = calcVolumeMA.CalculateVolumeMA(hsfData, maPeriod, lastNode);
        if (vMAValues.length < 1) {
            return [];
        }
        const dataNodes = hsfData.filter((t) => t._volume > -1);
        if (isHistoric && dataNodes && dataNodes.length >= maPeriod) {
            const lastDataNode = dataNodes[0];
            const target = vMAValues[0];
            let fSumOfPeriods = lastDataNode._volume / pctIntoTrading;
            for (let i = 1; i < maPeriod; i++) {
                fSumOfPeriods = fSumOfPeriods + dataNodes[i]._volume;
            }
            target.Close = fSumOfPeriods / maPeriod;
        }
        return volumeAverage(startx, vMAValues, nodeWidth, scale);

    } catch (error) {
        console.log(`Error occurs in  volumeMA - VolumeChartSaga.js ${error}`)
    }
}

function pctIntoTradings(delayed = false, SymbolInfo, periodicity, isHistoric, endDate, IsNASDAQBasic, TradeOpen, TradeClose) {
    let TradeDate, DelayedTradeDate;

    let currTime = new Date();
    const timeZone = SymbolInfo ? SymbolInfo.MomentZoneID !== null ? SymbolInfo.MomentZoneID : "US/Eastern" : "US/Eastern";
    currTime = DateHelper.returnTimeZoneDate(currTime, timeZone);
    if (currTime > TradeOpen && currTime < TradeClose) {
        TradeDate = currTime;
        const delay = SymbolInfo && SymbolInfo.TimeDelay ? SymbolInfo.TimeDelay : 20;
        DelayedTradeDate = new Date(currTime - delay * 60000);
    }
    else {
        TradeDate = TradeClose;
        DelayedTradeDate = TradeClose;
    }
    const Delay = delayed ? delayed : !IsNASDAQBasic;

    const state = {
        SymbolInfo,
        periodicity,
        TradeOpen,
        TradeClose,
        isHistoric,
        DelayedTradeDate,
        TradeDate,
        endDate
    }
    return WorkSpaceRealTimePrices.PctIntoTrading(state, Delay);
}

function getIndex(info) {
    return (info &&
        info.SymTypeEnum &&
        (
            info.SymTypeEnum === SymbolType.WONMAJORINDUSTRY89 ||
            info.SymTypeEnum === SymbolType.WONINDUSTRYGROUP197 ||
            info.SymTypeEnum === SymbolType.INDEX ||
            info.SymTypeEnum === SymbolType.ECONOMICINDICATOR ||
            info.SymTypeEnum === SymbolType.INTERNATIONALINDEX ||
            info.SymTypeEnum === SymbolType.WONSECTOR11
        )
    );
}
function volumeAverage(xAxis, hsfResults, nodeWidth, scale) {
    const prcLength = hsfResults.length;
    let yPrice = scale.ComputeY(hsfResults[0].Close);
    const chartData = new Array(prcLength);
    let x = 0;

    for (let j = 0; j < prcLength; j++) {
        if (xAxis < 0) {
            break;
        }
        yPrice = scale.ComputeY(hsfResults[j].Close);
        const info = {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: xAxis,
            maValue: hsfResults[j].Close,
            Volume: hsfResults[j].Volume
        };
        xAxis -= nodeWidth;
        chartData[x] = info;
        x++;
    }
    return chartData;
}

export function* lastVolumeBar(dataGraphStates, Height) {
    const states = yield select(getVolumeChartStates);
    const { nodeCount, pricePanelData, startXPoint, nodeWidth, padding} = dataGraphStates;
    const HasVolume = pricePanelData.HsfData.HasVolume;
    const graphData = pricePanelData.HsfData.HSFResults
    let xAxis = startXPoint;
    const lastnodeIndex = nodeCount;
    const lastDataNode = Math.min(graphData.length - 1, lastnodeIndex);
    let graphPointer = padding;

    // for (let j = padding; j <= lastDataNode; j++) {
    //     if (!graphData[graphPointer].IsVisible) {
    //         xAxis -= nodeWidth;
    //         graphPointer++;
    //         continue;
    //     }
    //     break;
    // }
    const yVol = states.scale.ComputeY(graphData[padding]._volume);
    const upTick = (graphData[padding + 1] && graphData[padding + 1].Close) ? graphData[padding].Close > graphData[padding + 1].Close : true;
    const info = {
        Date: graphData[padding].Date,
        yHigh: yVol,
        yLow: Height,
        xAxis: xAxis,
        IsVisible: (graphData[padding]._volume && graphData[padding]._volume > 0) || !HasVolume,
        UpTick: upTick
    };
    xAxis -= nodeWidth;

    if (!states.volumeData.allPoints || !states.volumeData.allPoints[0]) {
        return;
    }
    if (upTick) {
        if (states.volumeData.highPoints.length > 0 && states.volumeData.highPoints[0].Date.getTime() === info.Date.getTime()) {
            states.volumeData.highPoints[0] = info;
        } else {
            states.volumeData.highPoints.unshift(info);
        }
        if (states.volumeData.lowPoints.length > 0 && states.volumeData.lowPoints[0].Date.getTime() === info.Date.getTime()) {
            states.volumeData.lowPoints.splice(0, 1);
        }
    } else {
        if (states.volumeData.lowPoints.length > 0 && states.volumeData.lowPoints[0].Date.getTime() === info.Date.getTime()) {
            states.volumeData.lowPoints[0] = info;
        } else {
            states.volumeData.lowPoints.unshift(info);
        }
        if (states.volumeData.highPoints.length > 0 && states.volumeData.highPoints[0].Date.getTime() === info.Date.getTime()) {
            states.volumeData.highPoints.splice(0, 1);
        }
    }
    if (states.volumeData.allPoints.length > 0 && states.volumeData.allPoints[0].Date.getTime() === info.Date.getTime()) {
        states.volumeData.allPoints[0] = info;
    } else {
        states.volumeData.allPoints.unshift(info);
    }
}

function internationalStock(info) {
    return (info &&
        info.SymTypeEnum &&
        info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK
    );
}

function getPointer(pctIntoTrading, index, volMALineData, volumeBarData, Height, prState, dataGraphStates, TradeOpen) {
    try {
        const { SymbolInfo, SymbolType, pricePanelData, isIntraday, IsNASDAQBasic, isReceivedQuote, periodicity} = dataGraphStates;
        const priceData = SymbolInfo.priceData;
        const volumeData = pricePanelData.volumePriceData;
        let volDisplay = '';
        let yDisp = '';
        let volChg = '';
        let pctChange = -101;
        if (pricePanelData) {
            const dVolRate = pricePanelData.HsfData.DVolRate;
            const dwVolRate = pricePanelData.HsfData.DWVolRate;
            const rtVolRate = pricePanelData.HsfData.RTVolRate;
            const rtwVolRate = pricePanelData.HsfData.RTWVolRate;
            if (!dataGraphStates.isHistoric) {
                if (IsNASDAQBasic) {
                    if ((periodicity === GraphType.Daily || isIntraday) && rtVolRate !== -101) {
                        pctChange = rtVolRate;
                    }
                    if (periodicity === GraphType.Weekly && rtwVolRate !== -101) {
                        pctChange = rtwVolRate;
                    }
                } else {
                    if ((periodicity === GraphType.Daily || isIntraday) && dVolRate !== -101) {
                        pctChange = dVolRate;
                    }
                    if (periodicity === GraphType.Weekly && dwVolRate !== -101) {
                        pctChange = dwVolRate;
                    }
                }
            }
        }
        let curVol = 0;
        const international = SymbolInfo ? (internationalStock(SymbolInfo)) : false;
        const volMultip = international ? 1 : 100;
        const prevAVol = volMALineData.length > 0 && volMALineData[1] ? volMALineData[1].maValue : 0;
        const curAVol = volMALineData.length > 0 && volMALineData[0] ? volMALineData[0].maValue : 0;
        let maValue = prevAVol;
        const stockHeaderUpToDate = true;
        if ((periodicity === GraphType.Daily || isIntraday) && priceData && priceData.Avol > 0 && priceData.Avol > maValue) {
            maValue = priceData.Avol;
        }
        if (SymbolType !== SmartViewType.FUTURES) {
            if ((periodicity === GraphType.Daily || isIntraday) && maValue < 0.001) {
                if (stockHeaderUpToDate && dataGraphStates.headerData.AvgDailyVol) {
                    maValue = dataGraphStates.headerData.AvgDailyVol * volMultip;
                }
            }
        }
        if (dataGraphStates.isHistoric) {
            curVol = prState.HiLowPoints.allPoints ? prState.HiLowPoints.allPoints[0].graphData._volume : 0;
            if (curVol !== undefined && curAVol > 0.0) {
                pctChange = 100 * ((curVol / curAVol) - 1.0);
            }
            else if (curAVol <= 0.0) {
                pctChange = -101;
            }
        }
        else {
            if (SymbolType === SmartViewType.FUTURES) {
                if ((periodicity === GraphType.Daily || isIntraday) && maValue < 0.001) {
                    if (stockHeaderUpToDate && prState.snapShotdata.StockHeader.Avol) {
                        maValue = prState.snapShotdata.StockHeader.Avol * volMultip;
                    }
                }
            }
            if (periodicity !== GraphType.Daily && periodicity !== GraphType.Weekly && !isIntraday) {
                curVol = prState.HiLowPoints.allPoints ? prState.HiLowPoints.allPoints[0].graphData._volume : 0;
                if (pctChange === -101 && curVol !== undefined && maValue > 0.0) {
                    pctChange = 100 * ((curVol / (maValue * pctIntoTrading)) - 1);
                } else {
                    if (maValue <= 0.0) {
                        pctChange = -101;
                    }
                }
            } else {
                if (!index) {
                    if (isIntraday) {
                        if (IsNASDAQBasic && pctIntoTrading < 1 && prState.CumVolume && prState.CumVolume > 0) {
                            curVol = Math.floor(prState.CumVolume / 100) * 100;
                        }
                        else
                            if (stockHeaderUpToDate && dataGraphStates.headerData.Volume) {
                                curVol = dataGraphStates.headerData.Volume * volMultip;
                            }
                            else 
                                if (pricePanelData && pricePanelData.DailyHsfResults && pricePanelData.DailyHsfResults.HSFResults[0]) {
                                    const dailyData = pricePanelData.DailyHsfResults.HSFResults;
                                    curVol = StringUtil.convertFromLongValueToInt(pricePanelData.DailyHsfResults.HSFResults[0].Volume);
                                    if (priceData && priceData.Avol > 0) {
                                        maValue = priceData.Avol;
                                    }
                                    else
                                        if (dailyData) {
                                            const ma50Day = dailyData.filter((t) => {
                                                t.Volume = StringUtil.convertFromLongValueToInt(t.Volume);
                                                return t.Volume > -1;
                                            });
                                            let sum = 0;
                                            for (let i = 1; i < 51 && ma50Day[i]; i++) {
                                                sum += ma50Day[i].Volume;
                                            }
                                            maValue = sum / 50;
                                        }
                                }
                    }
                    else
                        if (prState.HiLowPoints.allPoints) {
                            curVol = prState.HiLowPoints.allPoints[0].graphData._volume;
                        }
                    if (pctChange === -101 && curVol !== undefined && maValue > 0.0) {
                        pctChange = 100 * ((curVol / (maValue * pctIntoTrading)) - 1);
                    } else {
                        if (SymbolType === SmartViewType.FUTURES) {
                            if (maValue <= 0.0) {
                                pctChange = -101;
                            }
                        }
                    }
                } else
                    if (!isIntraday) {
                        if (pricePanelData.SymbolInfo.type === SymbolType.INDEXNOINTRADAYVOLDATA && pricePanelData.SymbolInfo.Osid<1000000 && periodicity === GraphType.Daily) {
                            curVol = dataGraphStates.headerData.VolumeCurrent;
                            pctChange = 100 * ((curVol / (dataGraphStates.headerData.AvgDailyVol * pctIntoTrading)) - 1);
                        }
                        else {
                            curVol = prState.HiLowPoints.allPoints ? prState.HiLowPoints.allPoints[0].graphData._volume : 0;
                            if (curVol !== undefined && maValue > 0 && maValue > 0.0) {
                                pctChange = 100 * ((curVol / (maValue * pctIntoTrading)) - 1);
                            } else {
                                if (maValue <= 0.0) {
                                    pctChange = -101;
                                }
                            }
                        }
                    }
            }
        }


        if (isIntraday) {
            if (index && pricePanelData?.DailyHsfResults?.HSFResults && pricePanelData.DailyHsfResults.HSFResults[0]) {
                curVol = StringUtil.convertFromLongValueToInt(pricePanelData.DailyHsfResults.HSFResults[0].Volume);
                if (stockHeaderUpToDate && prState?.snapShotdata?.StockHeader?.Avol && prState?.snapShotdata?.StockHeader?.Avol > 0.0) {
                    pctChange = 100 *
                        ((prState.snapShotdata.StockHeader?.Volume / (prState.snapShotdata.StockHeader.Avol * pctIntoTrading)) -
                            1);
                } else {
                    const dailyData = pricePanelData.DailyHsfResults.HSFResults;
                    if (dailyData) {
                        const ma50Day = dailyData.filter((t) => {
                            t.Volume = StringUtil.convertFromLongValueToInt(t.Volume);
                            return t.Volume > -1;
                        });
                        let sum = 0;
                        for (let i = 0; i < 50 && ma50Day[i]; i++) {
                            sum += ma50Day[i].Volume;
                        }
                        maValue = sum / 50;
                        if (curVol !== undefined && maValue > 0.0) {
                            pctChange = 100 * ((curVol / (maValue * pctIntoTrading)) - 1);
                        }
                    }
                }
            }
            else
                if (pricePanelData.HsfData.CumVolume && pricePanelData.HsfData.CumVolume > 0 && curVol < 1) { curVol = pricePanelData.HsfData.CumVolume; }
        }
        if (SymbolType !== SmartViewType.FUTURES) {
            if ((!IsNASDAQBasic || !isReceivedQuote) && volumeData !== undefined && pctChange === -101) {
                if (volumeData.volumeRate === 0) {
                    pctChange = -100;
                } else {
                    pctChange = volumeData.volumeRate;
                }
            }
        }
        let sign = pctChange < 0 ? "" : "+";
        volChg = pctChange === -101 ? '' : (`${sign + (pctChange > 999 ? ExtremeDataValue.abbreviateFinancialValue(pctChange, 1) : pctChange ? pctChange.toFixed(0) : 0) }%`);
        if (SymbolType !== SmartViewType.FUTURES) {
            if (curVol < 1) {
                
                const totalCount = pricePanelData.HsfData.HSFResults.length;
                for (let ct = 0; ct < totalCount; ct++) {
                    if (pricePanelData.HsfData.HSFResults[ct].IsVisible && pricePanelData.HsfData.HSFResults[ct].Date >= TradeOpen) {
                        curVol += pricePanelData.HsfData.HSFResults[ct]._volume;
                    }
                }
            }
        }

        volDisplay = curVol > 999 ? ExtremeDataValue.abbreviateFinancialValue(curVol, 1): curVol ? curVol.toFixed(0) : 0;
        const yValue = volumeBarData.allPoints && volumeBarData.allPoints[0] !== undefined ? volumeBarData.allPoints[0].yHigh  : 0;
        yDisp = Height - 10 < yValue ? yValue - 10 : yValue < 30 ? 30 : yValue;

        if (volMALineData.length < 1) { 
            return { lastNodeVolume: volDisplay, volumePercentChange: pctChange, volumePercentChangeStr: volChg, pointerYValue: yDisp - 10,};
        }
        if (isNaN(pctChange) || pctChange === -101) {
            pctChange = 100 * ((curVol / (prevAVol * pctIntoTrading)) - 1);
            sign = pctChange < 0 ? "" : "+";
            volChg = (`${sign}${pctChange > 999 ? ExtremeDataValue.abbreviateFinancialValue(pctChange, 1) : pctChange ? pctChange.toFixed(0) : 0}%`);
        }

        return { lastNodeVolume: volDisplay, volumePercentChange: pctChange, volumePercentChangeStr: volChg, pointerYValue: yDisp - 10, }

    } catch (error) {
        console.log(`Error occurs in  getPointer - VolumeChartSaga.js ${error}`)
    }
}

function* updateVolume() {
    try {
        const dataGraphStates = yield select(getDatagraphStates);
        //If the scaleText will be "LOG" then changed scaleText will be "LIN"
        dataGraphStates.viewsSettings.VolumeBlockSettings[dataGraphStates.majorPeriodicity].IsLogScale = !dataGraphStates.viewsSettings.VolumeBlockSettings[dataGraphStates.majorPeriodicity].IsLogScale;
        SettingsStore.saveSettings();
        const Height =  getHeight();
        yield call(processVolumeData, 0, true, dataGraphStates, Height)
    } catch (error) {
        console.log(`Error occurs in  updateVolume - VolumeChartSaga.js ${error}`)
    }
}

function* updateVolumeDimensions({ dimension }){ 
    try{
        const tabDatagraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
        if(dimension.height !== tabDatagraphSettings.volumeChartDimension.height || tabDatagraphSettings.volumeChartDimension.width !== dimension.width){
            tabDatagraphSettings.volumeChartDimension.height = dimension.height;
            tabDatagraphSettings.volumeChartDimension.width = dimension.width;
            SettingsStore.saveSettings()
            const dataGraphStates = yield select(getDatagraphStates);
            yield call(processVolumeData, 0, true, dataGraphStates, dimension.height || 1);
        }
    }
    catch(error){
        console.log(`Error occurs in VolumeChart.js, updateVolumeDimension, ${error}`);
    }
}
/**************WATCHERS**************/
export function* watchPrepareData() {
    yield takeLatest(ActionTypes.PREPARE_VOLUME_INSIDER_DATA, prepareVolumeData)
}

export function* watchVolScaleChnage() {
    yield takeLatest(TabDataGraphConstants.ActionTypes.VOLUME_SCALE_CHANGED, updateVolume)
}
export function* watchUpdateVolumeDimension() {
    yield takeLatest(ActionTypes.UPDATE_VOLUME_CHART_DIMENSIONS, updateVolumeDimensions)
}