import React, { Component } from 'react';

export default class HubVirtualListBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    }
    this.updateItems = this.updateItems.bind(this);
  }

  updateItems(items) {
    this.setState({ items: items })
  }

  render() {
    return (
      <div style={{ height: this.props.scrollHeight, width: "100%" }}>
        <div style={{ height: 'inherit' }}>
          {this.state.items.map((item, index) =>
            this.props.getItemRenderer(item, index)
          )}
        </div>
      </div>
    )
  }
}