import {
    watchPeriodicityChange,
    watchCallPriceChartApi,
    watchonPlotSymbol,
    watchSymbolEntrySymbolChange,
    watchSymbolEntryChange,
    watchRedrawLine
} from './DatagraphSaga';
export const DatagraphWatchers = {
    watchPeriodicityChange,
    watchCallPriceChartApi,
    watchonPlotSymbol,
    watchSymbolEntrySymbolChange,
    watchSymbolEntryChange,
    watchRedrawLine
};