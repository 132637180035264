import PeriodicityHelper from "../../../../../Utils/PeriodicityHelper.js";
import GraphType from "GraphType";
import FundamentalTabReportType from "Constants/FundamentalTabReportType.js";
import FundamentalTabFilterType from "Constants/FundamentalTabFilterType.js";
import FundamentalTabSortOrderType from "Constants/FundamentalTabSortOrderType.js";
import FundamentalTabTemplateType from "Constants/FundamentalTabTemplateType.js";
import FundamentalTabPeriodicitySetting from "./FundamentalTabPeriodicitySetting.js";
import FundamentalTabEPSToggleState from 'Constants/FundamentalTabEPSToggleState.js';
import Serializable from "Serializable";

class TabFundamentalsSettings extends Serializable {
    constructor() {
      super();
      this.ResizeSettings = {};
      this.ResizeSettings.MinWidth = 270;
      this.ResizeSettings.MaxWidth = 500;
    }

    onSeralisedInfoRecieved(serializedValue) {
      this.StockViewSettings = serializedValue.StockViewSettings ? serializedValue.StockViewSettings : this.getDefaultStockViewSettings();
      this.PeriodicityOptions = serializedValue.PeriodicityOptions ? serializedValue.PeriodicityOptions : this.getDefaultFundamentalTabPeriodicitySetting();
      if(!this.ResizeSettings){
        this.ResizeSettings = {};
        this.ResizeSettings.MinWidth = 270;
        this.ResizeSettings.MaxWidth = 500;
      }
      this.ResizeSettings.Width = serializedValue.ResizeSettingsWidth ? serializedValue.ResizeSettingsWidth : this.getDefaultTabFundamentalResizeWidth();
    }

    getDataObject() {
      return {
        StockViewSettings: this.StockViewSettings
      };
    }

    getObjectMapKey() {
      return "TabFundamentalSettings";
    }

    getDefaultTabFundamentalsSettings () {
        this.StockViewSettings = this.getDefaultStockViewSettings();
        this.PeriodicityOptions = this.getDefaultFundamentalTabPeriodicitySetting();
        this.ResizeSettings.Width = this.getDefaultTabFundamentalResizeWidth();
        return this;
    }

    getDefaultStockViewSettings () {
        let stockViewSettings = new Serializable();
        stockViewSettings.Periodicity = GraphType.FundamentalsAnnual;
        stockViewSettings.ReportType = FundamentalTabReportType.Income;
        stockViewSettings.SortOrder = FundamentalTabSortOrderType.Ascending;
        stockViewSettings.Filter = FundamentalTabFilterType.All;
        stockViewSettings.Template = FundamentalTabTemplateType.Standard;
        stockViewSettings.EPSToggleState = FundamentalTabEPSToggleState.Date;
        stockViewSettings.isGICSFlag = false;
        return stockViewSettings;
    }

   getDefaultFundamentalTabPeriodicitySetting(){
    let financialBlockSettingObject = new Serializable();
    for (let periodicity in GraphType) {
      let majorPeriodicity = PeriodicityHelper.mapFundamentalTabPeriodicities(periodicity);
      if (!financialBlockSettingObject.hasOwnProperty(majorPeriodicity)) {
        financialBlockSettingObject[majorPeriodicity] = new FundamentalTabPeriodicitySetting(majorPeriodicity);
      }
    }
    return financialBlockSettingObject;
  }

  getDefaultTabFundamentalResizeWidth() {
    return 406;
  }

}

export default TabFundamentalsSettings;
