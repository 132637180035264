import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import { ListExplorerConstants } from "ListExplorerConstants";
import ListStore from "ListStore";
import BrowserUtil from "BrowserUtil";
import { dispatch } from "../../../../Redux/dispatch.js";
import { updateToSettings,setDeleteLoader } from '../../../../Actions/ExternalDataUploaderActions';
import ListApi from "ListApi";
const CHANGE_EVENT = "change";
class ExternalViewStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    //Bug fixes - Max Limit error 
    this.setMaxListeners(0);
    this.initialCall = true;
    this._state = {
      basic: {},
      listId: null,
      actualListId: null,
      alertMessage : null
    };
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    return this._state;
  }

  getCurrentAction() {
    return this._currentAction;
  }

  clearCurrentAction() {
     this._currentAction = null;
  }

  getSourceData() {
    let rows = reject(this._source, (row) => {
      if (!row.isRowVisible) return true;
    });
    return rows;
  }

  deleteExternalList(data){
    BrowserUtil.disableEvents(); 
    const selectedNodes = ListStore.externalDataSelectedNodes;
    let listsToDelete = data.externalListId;    
     if(selectedNodes.length > 1){   
     listsToDelete = selectedNodes.join("; ");
     }    
    ListApi.getDeleteTreeNode(listsToDelete).then((result) => {
      if (!result.responseHeader.error) {
        if(selectedNodes.length > 1){  
          const array = []; 
          selectedNodes.map((item) => {
            array.push(item);
          });
          dispatch(updateToSettings(array, false, true));
          }
          else{
            dispatch(updateToSettings(data.externalListId, false, true));
          } 
        dispatch(setDeleteLoader(false));
        BrowserUtil.enableEvents(); 
        // removing the deleted list from settings which will be reflected in the NavSymbol - price context menu
        this._currentAction =  ListExplorerConstants.ActionTypes.REMOVE_EXTERNAL_LIST;        
        (selectedNodes.length) ?
          this._state.alertMessage = `${selectedNodes.length} lists were deleted` :  this._state.alertMessage = `${data.name} was deleted`;
          this.alerTimeout();
          this.emit(CHANGE_EVENT);
       
      }else{
        dispatch(setDeleteLoader(false));
      }
    });
  }

  alerTimeout() {
    this.change = setTimeout(() => {
      this._state.alertMessage = '';
    }, 1000);
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {

      case ListExplorerConstants.ActionTypes.INIT_LOAD_EXTERNAL_LIST:
      this._currentAction = ListExplorerConstants.ActionTypes.INIT_LOAD_EXTERNAL_LIST;
        this._state.listId = data.listid;
        this._state.listName = data.listname;
        this.emit(CHANGE_EVENT);
        break;

      case ListExplorerConstants.ActionTypes.SET_LOADED_EXTERNAL_LIST_DETAILS:
        this._currentAction = ListExplorerConstants.ActionTypes.SET_LOAD_EXTERNAL_LIST_DETAILS;
        this._state.listId = data.listid;
        this._state.listName = data.listname;
        this._state.rowcount = data.rowcount;
        this._state.totalcount = data.totalcount;
        this._state.uploadedTime = data.lastUploadDateTime;
        this.emit(CHANGE_EVENT);
        break;

      case ListExplorerConstants.ActionTypes.REMOVE_EXTERNAL_LIST:
          this.deleteExternalList(data);
        break;

      case ListExplorerConstants.ActionTypes.SHOW_ALERT_DUPLICATE_EXTERNAL:              
        this._currentAction =  ListExplorerConstants.ActionTypes.SHOW_ALERT_DUPLICATE_EXTERNAL;
        this._state.alertMessage = `${data.listName} was duplicated`;
        this.alerTimeout();
        this.emit(CHANGE_EVENT);        
        break;

      case ListExplorerConstants.ActionTypes.IS_DELETE_UNDO_EXIST_EXTERNAL:        
          this._currentAction = ListExplorerConstants.ActionTypes.IS_DELETE_UNDO_EXIST_EXTERNAL;
          this.emit(CHANGE_EVENT);        
          break;

      case ListExplorerConstants.ActionTypes.LIST_ROLL_BACK_SUCCESS:
          const  lisId= data.rollBackId;
          const externalLists =ListStore.getExternalDataSetList;
          const arrayed = [];
          externalLists.forEach(itm => arrayed.push(itm.childNodes));
          const flatList = arrayed.reduce((arr, val) => arr.concat(val), []);
          const uploadedList = flatList.find((item) => item.nodeId.low  == lisId);
          this._state.alertMessage = `Roll Back Success for ${ uploadedList.name}`;
          this._currentAction =  ListExplorerConstants.ActionTypes.LIST_ROLL_BACK_SUCCESS;
          this.alerTimeout();
          this.emit(CHANGE_EVENT); 
          break;

      case ListExplorerConstants.ActionTypes.LIST_RE_UPLOAD_SUCCESS:
          // const reListId = data.listId;
          // let externalListsExp =ListStore.getExternalDataSetList;
          // externalListsExp = externalListsExp.filter((item) => item.nodeId.low  == reListId);
          this._state.alertMessage = `Data Set has been updated`;
          this._currentAction =  ListExplorerConstants.ActionTypes.LIST_RE_UPLOAD_SUCCESS;
          this.alerTimeout();
          this.emit(CHANGE_EVENT); 
          break;
          
      default:
        break;
    }
  }
}

const externalViewStore = new ExternalViewStore();
export default externalViewStore;
