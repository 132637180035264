import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";
import LocalizationStore from 'LocalizationStore';
import UserInfoUtil from "UserInfoUtil";

class AboutDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.handleModalHide = this.handleModalHide.bind(this);
    }

    handleModalHide() {
        this.props.handleClick(false, false);
    }

    render() {
        const { isShowModal, aboutDialogTitle, url, selectedTiTab } = this.props;
        const isAccDist = selectedTiTab === LocalizationStore.getTranslatedData("pt_ad","ACCUM/DIST") ? true : false;
        const title = isAccDist ? LocalizationStore.getTranslatedData("ipc_ad_abt_title","ACCUMULATION/DISTRIBUTION" ): aboutDialogTitle;
        const aboutClass = isAccDist ? "accumulation-distribution-about-us" : "indicator-about-us";
        const aboutUrl = UserInfoUtil.hasChinaEntitlement() ? url.replace(/PanarayFeatureAbout/, 'PanarayFeatureAbout_China') : url
        const openDialogClass = isShowModal ? " dialog-open" : "";
        return (
            <div>
                <Modal className={"modal-popup " + aboutClass + openDialogClass} show={isShowModal}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="cap-header">
                                <span className="indi-about">{LocalizationStore.getTranslatedData("ipc_abt","About")}</span>
                                <span className="indica-about">{LocalizationStore.getTranslatedData("ri_"+title.replace(/ /ig,""),title)}</span>
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!isAccDist && <div className="ps-tiindicator-box">
                            <iframe src={aboutUrl} className="about-iframe"></iframe>
                            <div className="clearfix"></div>
                        </div>}
                        {/* Accumulation/Distribution About Us Begin */}
                        {isAccDist && <div className="accdis-tiindicator-box">
                            <div className="accdis-tiindicator-hold">
                                <ul>
                                    <li>{LocalizationStore.getTranslatedData("ipc_ad_abt_msg1","The Accumulation/Distribution (A/D) line is based on a proprietary rating calculated as an exponential moving average of inter- and intra-day prices and volume statistics.")}</li>
                                    <li>{LocalizationStore.getTranslatedData("ipc_ad_abt_msg2","This indicator is available only on daily intervals.")}</li>
                                    <li>{LocalizationStore.getTranslatedData("ipc_ad_abt_msg3","Settings cannot be changed for the A/D Line.")}</li>
                                    <li>{LocalizationStore.getTranslatedData("ipc_ad_abt_msg4","The moving average is a 20-day moving average line.")}</li>
                                    <li>{LocalizationStore.getTranslatedData("ipc_ad_abt_msg5","SD Rating stands for Supply/Demand Rating and is based on the supply demand ratio (floating supply divided by the 50-day average daily volume).")}</li>
                                </ul>
                            </div>
                            <div className="clearfix"></div>
                        </div>}
                        <div className="clearfix"></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.handleModalHide}>{LocalizationStore.getTranslatedData("ipc_cls","CLOSE")}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

AboutDialog.propTypes = {
    isShowModal: PropTypes.bool.isRequired,
    aboutDialogTitle: PropTypes.string.isRequired
}

export default AboutDialog;