import TimeLine from './TimeLine.js';
import LocalizationStore from "LocalizationStore";

class WeeklyTimeLine extends TimeLine {

    Padding(quarters, xDate, endDate, targetDate = null) {
        var week = 7;
        var curDate;
        var dayAdvance;
        var weekday;
        var i;
        if (quarters == 0) {
            curDate = new Date(xDate);
            if (this.padding) {
                dayAdvance = week;
                weekday = curDate.getDay();
                dayAdvance = weekday < 5 ? (5 - weekday) - 7 : 0;
                curDate.setDate(curDate.getDate() + dayAdvance);

                for (i = 0; i < 4; i++) {
                    curDate.setDate(curDate.getDate() + week);
                    if (curDate.getDay() == 6)
                        curDate.setDate(curDate.getDate() - 1);
                    if (curDate.getDay() == 0)
                        curDate.setDate(curDate.getDate() - 2);
                }
            }
        }
        else {
            curDate = new Date(new Date(xDate).setMonth(xDate.getMonth() + (3 * quarters)));
            if (endDate > curDate)
                curDate = new Date(endDate);

            if (curDate.getTime() < endDate.getTime())
                curDate.setDate(endDate.getDate());
            if (curDate.getDay() == 6)
                curDate.setDate(curDate.getDate() - 1);
            if (curDate.getDay() == 0)
                curDate.setDate(curDate.getDate() - 2);

            curDate.setDate(curDate.getDate() + week);

            week = 7;
            dayAdvance = week;
            weekday = curDate.getDay();
            dayAdvance = weekday < 5 ? (5 - weekday) - 7 : 0;
            curDate.setDate(curDate.getDate() + dayAdvance);
            // if (endDate > curDate)
            //     curDate = new Date(endDate);
            for (i = 0; i < 4; i++) {
                curDate.setDate(curDate.getDate() + week);
                if (curDate.getDay() == 6)
                    curDate.setDate(curDate.getDate() - 1);
                if (curDate.getDay() == 0)
                    curDate.setDate(curDate.getDate() - 2);
            }
        }
        //PANWEB-5057
        return targetDate !== null && curDate.getTime() < targetDate.getTime() ? targetDate : curDate;
    }

    SetMCVerticalGrid(startTime, endDate, mtDates, EarnReportDate, FCQtrEstCount) {
        var startDate = startTime;

        var quarters = 4;
        var padDate = new Date(endDate);
        if (quarters > 0 && FCQtrEstCount > 0 && EarnReportDate.getFullYear() > 1900)
            padDate = new Date(EarnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 4*7);
        }
        var pDate = new Date(this.Padding(quarters, padDate, endDate));
        pDate.setDate(pDate.getDate() + 4 * 7);
        var cDate = new Date(pDate);

        if (cDate.getDay() != 6)


            var week = 7;
        var x = 0;

        while (cDate >= startDate) {
            var xDate = new Date(cDate);

            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };

            mtDates[x] = info;

            var cMonth = cDate.getMonth() + 1;
            var pMonth = pDate.getMonth() + 1;

            if (pMonth != cMonth) {
                if (pDate.getFullYear() != cDate.getFullYear()) {
                    mtDates[x].XLabel = cDate.getFullYear();
                    mtDates[x].XLine = 3;
                    mtDates[x].IsCalendarLabel = true;
                }
            }
            pDate = new Date(cDate);
            x++;
            cDate.setDate(cDate.getDate() - week);
        }
    }

    SetVerticalGrid(startTime, endDate, timeLineData, EarnReportDate, quarters = 4, funds = false, videoMode = false, targetDate = null) {
        this.dottedGrid = [];
        this.solidGrid = [];
        this.eighteenMnth = [];
        let mtDates = timeLineData.dates;
        var calQtrs = [3, 6, 9, 12];
        var f1Qtrs = [1, 4, 7, 10];
        var f2Qtrs = [2, 5, 8, 11];
        var monthNames = ["   ",
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct",
            "Nov", "Dec"
        ];

        var startDate = startTime;

        var padDate = new Date(endDate);
        if (EarnReportDate && EarnReportDate.getFullYear() > 1900) {
            let rpYear = EarnReportDate.getFullYear();
            if (EarnReportDate != NaN && rpYear > 1910) {
                if (quarters > 0)
                    padDate = new Date(EarnReportDate);
                else {
                    quarters = 0;
                   // padDate.setDate(padDate.getDate() + 4*7);
                }
            }
            else {
                quarters = 0;
              //  padDate.setDate(padDate.getDate() + 4*7);
            }
        }
        else {
            quarters = 0;
          //  padDate.setDate(padDate.getDate() + 4*7);
        }
        //PANWEB-5057
        if (targetDate !== null && targetDate.getTime() > endDate.getTime()) {
            let weekday = targetDate.getDay();
            let dayAdvance = weekday < 5 ? (5 - weekday) + 7 : weekday === 0 ? 5 : weekday === 6 ? 6 : 0;
            targetDate.setDate(targetDate.getDate() + dayAdvance);
        }

        var pDate = new Date(this.Padding(quarters, padDate, endDate, targetDate));
        pDate.setDate(pDate.getDate() + 4*7);
        var cDate = new Date(pDate);

        var week = 7;
        var x = 0;
        var qtrs = 0;
        var sixtQuarterLine = funds;// ? false;

        timeLineData.FirstCalLabelIndex = timeLineData.FirstLabelIndex;
        timeLineData.Firstf1LabelIndex = timeLineData.FirstLabelIndex;
        timeLineData.Firstf2LabelIndex = timeLineData.FirstLabelIndex;

        while (cDate >= startDate) {
            var xDate = new Date(cDate);

            var info =
            {
                Date: xDate,
                XAxis: x + 1,// + partialWeek + 1,
                XLine: 0,
                XLabel: "",
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };

            mtDates[x] = info;

            var cMonth = cDate.getMonth() + 1;
            var pMonth = pDate.getMonth() + 1;

            if (pMonth != cMonth) {
                var yr = cDate.getFullYear();
                var yrD = yr % 100;
                const uMonth = videoMode === true ? pMonth : cMonth;
                var yDateStr = LocalizationStore.getTranslatedData("sb_date", "{0} {1}", LocalizationStore.getTranslatedData("cal_Num_" + monthNames[uMonth], monthNames[uMonth]), (yrD < 10 ? "0" + yrD : yrD));
                if (calQtrs[super.Qtr(cMonth)] == cMonth) {
                    mtDates[x].XLabel = yDateStr;
                    if (timeLineData.FirstCalLabelIndex === -1) {
                        timeLineData.FirstCalLabelIndex = x;
                    }
                    if (timeLineData.FirstLabelIndex === -1) {
                        timeLineData.FirstLabelIndex = x
                    }
                    mtDates[x].IsCalendarLabel = true;
                    mtDates[x].XLine = 1;
                    if (cDate < endDate) qtrs++;
                    timeLineData.TimeLineLabel.push(mtDates[x])
                    this.dottedGrid.push(mtDates[x]);
                }
                if (f1Qtrs[super.Qtr(cMonth)] == cMonth) {
                    mtDates[x].XLabel = yDateStr;
                    mtDates[x].IsFiscalLabel1 = true;
                    if (timeLineData.Firstf1LabelIndex === -1) {
                        timeLineData.Firstf1LabelIndex = x;
                    }
                    timeLineData.TimeLineLabel.push(mtDates[x])
                }
                if (f2Qtrs[super.Qtr(cMonth)] == cMonth) {
                    mtDates[x].XLabel = yDateStr;
                    mtDates[x].IsFiscalLabel2 = true;
                    if (timeLineData.Firstf2LabelIndex === -1) {
                        timeLineData.Firstf2LabelIndex = x;
                    }
                    timeLineData.TimeLineLabel.push(mtDates[x])
                }
                // Month seperators in first 18 months (6 quarters)
                if (qtrs < 7 && !funds) {
                    mtDates[x].XLine = 3;
                    mtDates[x].IsCalendarLine = true;
                    this.dottedGrid.push(mtDates[x]);

                }

                if (pDate.getFullYear() != cDate.getFullYear()) {
                    mtDates[x].XLine = 2;
                    mtDates[x].IsCalendarLine = true;
                    this.solidGrid.push(mtDates[x]);
                }

                if (qtrs == 7 && !sixtQuarterLine) {
                    sixtQuarterLine = true;

                    mtDates[x].XLine = 5;
                    mtDates[x].IsCalendarLine = true;
                    this.eighteenMnth.push(mtDates[x]);
                }

                pDate = new Date(cDate);
            }

            x++;
            //let nextDate = new Date(cDate);
            //nextDate = nextDate.setDate(nextDate.getDate() - week);
            //if(endDate > nextDate && endDate < cDate){
            //    mtDates[x] = {
            //        Date: new Date(endDate),
            //        XAxis: x + 1,
            //        XLine: 0,
            //        XLabel: "",
            //        IsFiscalLabel1: false,
            //        IsFiscalLabel2: false,
            //        IsCalendarLabel: false
            //    };
            //    x++;
            //}
            cDate.setDate(cDate.getDate() - week);
        }
    }
}
const weeklyTimeLine = new WeeklyTimeLine();
export default weeklyTimeLine;
