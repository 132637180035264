var FundamentalTableCssType = {
   EmptyCssClass: 1,
   ToplevelCssClass:  2,
   DoubleindentCssClass:  3,
   TotalCssClass:  4,
   SubtotalCssClass:  5,
   GrandtotalCssClass:  6,
   TripleindenttotalCssClass:  7,
   InitialtotalCssClass:  8,
   PosttotalCssClass:  9,
   Cfnetalt2CssClass:  10,
   TripleindentCssClass:  11,
   CfnetCssClass:  12,
   PrimarytotalCssClass:  13,
   CfnetaltCssClass:  14,
   DoubleindentoverlineCssClass:  15,
   FourindentsCssClass:  16,
   TripleindentoverlineCssClass:  17,
   BoldonlyCssClass:  18,
   AllFilterSectionHeaderCssClass: 19
}
export default FundamentalTableCssType;