import FundDescriptionControl from '../FundDescriptionControl.jsx';
import { Panel } from "react-bootstrap";
import React from "react";
import SymbolContentControl from "./SymbolContentControl.jsx";

function IndexPanelBody() {
    return (
        <>
            <Panel>
                <Panel.Body>
                    <div className="summary-block-catalog for-index">
                        <SymbolContentControl key="symbolContentControl" />
                    </div>
                    <div className="summary-block-record description-index">
                        <FundDescriptionControl key="fundDescription" id="indexPanel" height={100} width={785} />
                    </div>
                </Panel.Body>
            </Panel>
        </>
    )
}
export default IndexPanelBody