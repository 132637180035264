import Serializable from "Serializable";
import UserInfoUtil from 'UserInfoUtil';
import { RotationMenuType } from "../../../../../Constants/RotationMenuType";
import RatingFilterType from "RatingFilterType";

class RotationGroupsMenuSettings extends Serializable {
    constructor() {
        super();
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.lastHighlightedOsids = serializedValue.lastHighlightedOsids ? serializedValue.lastHighlightedOsids : this.getDefaultLastHighlightedOsids();
        this.breadCrumbItems = serializedValue.breadCrumbItems ? serializedValue.breadCrumbItems : [];
        this.itemName = serializedValue.itemName ? serializedValue.itemName : null;
        this.rotationMenuType = serializedValue.rotationMenuType ? serializedValue.rotationMenuType : RotationMenuType.Sector;
        this.countryCode = serializedValue.countryCode ? serializedValue.countryCode : UserInfoUtil.hasChinaEntitlement() ? 4 : 1;
        this.isTimeLinePlaying = serializedValue.isTimeLinePlaying != null || serializedValue.isTimeLinePlaying != undefined ? serializedValue.isTimeLinePlaying : this.getDefaultTimelinePlayState();
        this.isUptrendVisible = serializedValue.isUptrendVisible != null || serializedValue.isUptrendVisible != undefined ? serializedValue.isUptrendVisible : this.getDefaultUptrendVisibility();
        this.isDowntrendVisible = serializedValue.isDowntrendVisible != null || serializedValue.isDowntrendVisible != undefined ? serializedValue.isDowntrendVisible : this.getDefaultDownTrendVisibility();
        this.timeLineIndex = serializedValue.timeLineIndex != undefined && serializedValue.timeLineIndex != null ? serializedValue.timeLineIndex : this.getDefaultTimeLineIndex();
        this.liqFilter = serializedValue.liqFilter ? serializedValue.liqFilter : this.getDefaultLiqFilterStates();
        this.notVisibleOsids = serializedValue.notVisibleOsids ? serializedValue.notVisibleOsids : this.getDefaultNotVisibleOsids();
        this.rotationGraphZoomValues = serializedValue.rotationGraphZoomValues ? serializedValue.rotationGraphZoomValues : this.getDefaultRotationGraphZoomValues();
    }

    getDataObject() {
        return {
            lastHighlightedOsids: this.lastHighlightedOsids,
            breadCrumbItems: this.breadCrumbItems,
            itemName: this.itemName,
            rotationMenuType: this.rotationMenuType,
            countryCode: this.countryCode,
            isTimeLinePlaying: this.isTimeLinePlaying,
            isUptrendVisible: this.isUptrendVisible,
            isDowntrendVisible: this.isDowntrendVisible,
            timeLineIndex: this.timeLineIndex,
            liqFilter: this.liqFilter,
            notVisibleOsids: this.notVisibleOsids,
            rotationGraphZoomValues: this.rotationGraphZoomValues
        };
    }

    getObjectMapKey() {
        return "RotationGroupsMenuSettings";
    }

    getDefaultRotationGroupsMenuSettings() {
        this.lastHighlightedOsids = this.getDefaultLastHighlightedOsids();
        this.breadCrumbItems = [];
        this.itemName = null;
        this.rotationMenuType = RotationMenuType.Sector;
        this.countryCode = UserInfoUtil.hasChinaEntitlement() ? 4 : 1;
        this.isTimeLinePlaying = this.getDefaultTimelinePlayState();
        this.isUptrendVisible = this.getDefaultUptrendVisibility();
        this.isDowntrendVisible = this.getDefaultDownTrendVisibility();
        this.timeLineIndex = this.getDefaultTimeLineIndex();
        this.liqFilter = this.getDefaultLiqFilterStates();
        this.notVisibleOsids = this.getDefaultNotVisibleOsids();
        this.rotationGraphZoomValues = this.getDefaultRotationGraphZoomValues();
        return this;
    }

    getDefaultLiqFilterStates() {
        let liqFilter = new Serializable();
        liqFilter.MktCapLowSliderRangeValueIndex = -1;
        liqFilter.MktCapHighSliderRangeValueIndex = -1;

        liqFilter.AvolFilterLowSliderRangeValueIndex = -1;
        liqFilter.AvolFilterHighSliderRangeValueIndex = -1;


        liqFilter.RatingFilter1LowSliderRangeValueIndex = -1;
        liqFilter.RatingFilter1HighSliderRangeValueIndex = -1;

        liqFilter.RatingFilter2LowSliderRangeValueIndex = -1;
        liqFilter.RatingFilter2HighSliderRangeValueIndex = -1;

        liqFilter.RatingFilterType2 = RatingFilterType.EPS;

        liqFilter.RatingFilterType1 = RatingFilterType.Datagraph;

        liqFilter.IsGlobalRatingSelected = true;
        liqFilter.IsCountryRatingSelected = false;
        liqFilter.IsFilterOn = true;
        liqFilter.IsFilterManualOff = true;
        return liqFilter;
    }

    getDefaultNotVisibleOsids() {
        return [];
    }

    getDefaultLastHighlightedOsids() {
        return [];
    }

    getDefaultRotationGraphZoomValues() {
        let rotationGraphZoomValues = new Serializable();
        rotationGraphZoomValues.zoomValue = "AUTO";
        rotationGraphZoomValues.matrix = "matrix(" + 1 + "," + 0 + "," + 0 + "," + 1 + "," + 0 + "," + 0 + ")";
        rotationGraphZoomValues.isAutoChecked = true;
        return rotationGraphZoomValues;
    }

    getDefaultTimelinePlayState() {
        return false;
    }

    getDefaultUptrendVisibility() {
        return true;
    }

    getDefaultDownTrendVisibility() {
        return true;
    }

    getDefaultTimeLineIndex() {
        return null;
    }
}

export default RotationGroupsMenuSettings;
