import { PrintMode } from '../../../../../../print/printmode';
import React from "react";
import { showMoreSummary } from "../../../../../../Actions/SummaryBlockActions.js";
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import { useSelector, useDispatch } from "react-redux";

function Header() {
    const headerData = useSelector((state) => state.summaryReducer.headerData)
    const dispatch = useDispatch()
    let needMoreBtn = true;
    if (headerData.summaryBlockDesc && headerData.summaryBlockDesc.longDescription === headerData.summaryBlockDesc.shortDescription) {
        needMoreBtn = false;
    }

    const renderPhone = () => {
        if (headerData.phone) {
            return <div className="sb-table-cell">{headerData.phone}</div>;
        }
        return "";
    }
    const renderUrl = () => {
        if (headerData.webUrl !== "http://null") {
            return <div className="sb-table-cell"><a className="link" href={headerData.webUrl} rel="noreferrer" target="_blank"> {headerData.webText}</a></div>
        }
        return "";
    }
    const renderCorporatePresentationItem = () => {
        if (headerData.corporatePresentationURL && !PrintMode.printing) {
            return (<div className="sb-table-cell cpCell">
                <a className="icn-link-cp" href={headerData.corporatePresentationURL} rel="noreferrer" target="_blank" title={headerData.corporatePresentationURL}></a>
            </div>);
        } else {
            return "";
        }
    }
    const renderXDivDate = () => {
        if (headerData.xDivDate) {
            return <div className="sb-table-cell xdivdate">{headerData.xDivDate}</div>;
        }
        return "";
    }
    const getheaderSummary = (headerData, showMore) => (headerData.summaryBlockDesc) ? (!showMore ? headerData.summaryBlockDesc.shortDescription : headerData.summaryBlockDesc.longDescription) : ""
    const showMoreStory = () => {
        story = getheaderSummary(headerData, !headerData.showMore)
        dispatch(showMoreSummary(!headerData.showMore));
    }
    let story = getheaderSummary(headerData, headerData.showMore)

    return (
        <div className="summary-block-catalog">
            <div className="summary-block-log">
                <div className="summary-block-log-hold">
                    <div className="sb-table">
                        <div className="sb-table-body">
                            <div className="sb-table-row">
                                {renderPhone()}
                                {renderUrl()}
                                <div className="sb-table-cell">{headerData.hqLocAndYrInc}</div>
                                <div className="sb-table-cell">{headerData.bankr}</div>
                                <div className="sb-table-cell">{headerData.mgmOwned}</div>
                            </div>
                        </div>
                    </div>
                    <div className="sb-table">
                        <div className="sb-table-body">
                            <div className="sb-table-row">
                                {headerData.epsRnk && headerData.epsRnk.length > 2 &&
                                    <div className="sb-table-cell">{headerData.epsRnk}</div>}
                                {renderCorporatePresentationItem()}
                                {renderXDivDate()}
                                <div className="sb-table-cell">{headerData.daysVolShort}</div>
                                <div className="sb-table-cell">{headerData.options}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="summary-block-record">
                {story ? <p>{story}{needMoreBtn && <a className="more" onClick={showMoreStory}>{!headerData.showMore ? `   ${SummaryTransLateHelper.SUMMARY_BLOCK_SHOW_MORE}` : `   ${SummaryTransLateHelper.SUMMARY_BLOCK_SHOW_LESS}`}</a>} </p> : ""}
            </div>
        </div>

    );

}

export default Header