import React, { Component } from 'react';
import Header from './Header';
import CenterRotation from './CenterRotation';
import Footer from './Footer'
import { PrintMode } from '../../../../../print/printmode';
import GeographyListView from '../../ToggleMenuPanel/Presentational/GeographyListView';
import { LiqAndRatingFilterDialog } from '../../ToggleMenuPanel/Containers/index.js';
import ErrorBoundary from 'ErrorBoundary';
import { AddListModal } from '../../ToggleMenuPanel/Presentational';

class RotationGraphLeftPanel extends Component {

    render() {
        const paddingStyle = PrintMode.printing ? { padding: '0 0 55px' } : {};

        return (
            <div className="COLS">
                <ErrorBoundary><LiqAndRatingFilterDialog /></ErrorBoundary>
                <div className="CELL _FLEX panaray-container" style={paddingStyle}>
                    <div id="mainBlock">
                        <div id="page-content-wrapper">
                            <div className="page-content" data-spy="scroll" data-target="#spy">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div id="compare_container">
                                            <div className="mainBlockWrap">
                                                <div id="tabMainBlock" >
                                                    <div className="info-tabMain">
                                                        <ErrorBoundary><GeographyListView /></ErrorBoundary>
                                                        <ErrorBoundary><AddListModal /></ErrorBoundary>
                                                        <ErrorBoundary><Header /></ErrorBoundary>
                                                        <ErrorBoundary><CenterRotation /></ErrorBoundary>
                                                        <ErrorBoundary><Footer /></ErrorBoundary>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

export default RotationGraphLeftPanel;