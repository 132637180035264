import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import ColorPicker from '../ColorPicker/ColorPicker.jsx';
import PropTypes from 'prop-types';

class ColorPaletteDialog extends PureComponent {

  handleColorChange = (color) => {
    this.props.handleColorChange(color);
  }

  onHide(isShowColorPalette, activeItem) {
    this.props.onHide(isShowColorPalette, activeItem);
  }

  render() {
    const { activeItem, show, color, isShowCloseButtons } = this.props;
    return (
      <div id="ColorPaletteDialog">
        <Modal className="modal-popup color-palette-wrap" show={show} onHide={() => this.onHide(false, activeItem)} >
          <Modal.Header>
            <Modal.Title>
                <span className="cap-header">Color Palette Dialog</span>
                {isShowCloseButtons && <button type="button" className="pull-right btn btn-xxs btn-default btn-secondary mart5 btn-close" data-effect="fadeOut" ><span className="icn-close" onClick={() => this.onHide(false, this.props.activeItem)}></span></button>}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="modal-material">
              <div className="color-panel picker">
                <div className="inner-picker">Color</div>
                <ColorPicker color={color} onChangeComplete={(color) => this.handleColorChange(color)} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isShowCloseButtons && <center><button type="button" className=" model-action btn btn-xs btn-default btn-secondary small-bold" onClick={() => this.onHide(false, activeItem)}>Close Window</button></center>}
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

ColorPaletteDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  activeItem: PropTypes.object.isRequired
}

export default ColorPaletteDialog;
