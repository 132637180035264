import { Dispatcher } from 'flux';

class DispatcherClass extends Dispatcher {

  handleViewAction(action) {
    if(!this.isDispatching()) {
      this.dispatch({
        source: 'VIEW_ACTION',
        action: action,
      });
    }
   
  }

  handleServerAction(action) {
    if(!this.isDispatching()) {
      this.dispatch({
        source: 'SERVER_ACTION',
        action: action,
      });
  }
  }
}

const AppDispatcher = new DispatcherClass();

export default AppDispatcher;
