import React from "react";
import { SummaryTransLateHelper } from "../../../../../Utils/TranslateHelper";
import { useSelector } from "react-redux";

function IdeaListControl() {
    const data = useSelector((state) => state.summaryReducer.ideaList.dataSource);

    return (
        <div className="ideaList-info-block">
            <div className="info-title">{SummaryTransLateHelper.FOCUS_LIST_OVERLAP}</div>
            {data.map((item, index) =>
            (<div className="info-row" key={index}>
                <div className="data-cell swatch-color">{item.header}</div>
                <div className="data-cell"><p>{item.buy}</p></div>
            </div>)
            )}
        </div>
    );
}

export default IdeaListControl;