import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import { TranslateHelper } from "../../../../Utils/TranslateHelper";
const { ActionTypes } = PriceChartConstants;

const initialState = {
    priceMenu: [],
    label: TranslateHelper.PRICE
}
const PriceMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PRICE_MENU_READY:
            return {
                ...state,
                priceMenu: action.blockMenu,
                label: action.label
            }
        // case ActionTypes.PRICE_MENU_SETTINGS_UPDATE:
        //     return {
        //         ...state,
        //         priceMenu: action.blockMenu
        //     }
        default:
            return state;
    }
}
export default PriceMenuReducer;
