import ChartVisual from "../../../../../../RayCustomControls/Chart/Visuals/ChartVisual.jsx";
import { connect } from "react-redux";
import Measure from "react-measure";
import PortalContainer from 'PortalContainer';
import React from "react";
import ThemeHelper from "ThemeHelper";
import { updateSTPStates, updateSTPTagSEttings } from "../../../../../../Actions/PricePanelActions.js";

class StreetTargetPrice extends ChartVisual {
    constructor(props) {
        super(props);
        this.timeout = null;
        this.handleResize = props.updateSTPStates.bind(this, { showAllInfoTag: false});
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }
    componentDidUpdate(prevProps){
        if(prevProps.theme !== this.props.theme){
            this.props.updateSTPStates({ showBox: false, showAllInfoTag: false})
        }
    }

    handleMouseLeave() {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        this.toolTopBox.style.display = 'none';
        this.props.updateSTPStates({ showBox: false });
    }
    handleMouseOver(e, key) {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        const selectTag = this.props.streetTargetPricePointData[key];
        const toolTipMessage = selectTag.toolTip;
        this.props.updateSTPStates({ showBox: true, selectTagOverFlow: selectTag.overflow });
        if(toolTipMessage) {
            this.toolTopBox.style.left = `${selectTag.screenX - 15}px`;
            this.toolTopBox.style.top = `${selectTag.screenY + 20}px`;
            this.toolTopBox.style.textAlign = "left";
            this.toolTopBox.style.width = `${140}px`;
            this.toolTopBox.style.zIndex = 110;
            this.toolTopBox.innerHTML = `<div><span>${toolTipMessage}</span></div>`;
            this.toolTopBox.style.display = '';
            this.timeout = setTimeout(() => {
                this.toolTopBox.style.display = 'none';
            }, 5000);
        } else {
            this.toolTopBox.style.display = 'none'; 
        }   
    }
    handleMouseClick (e, key) {
        const selectTag = this.props.streetTargetPricePointData[key];
        if (selectTag.overflow) {
            this.props.updateSTPStates({ showAllInfoTag: !this.props.showAllInfoTag});
        } else {
            this.props.updateSTPTagSEttings(!this.props.showTag);
        }
        this.props.updateSTPStates({ showBox: false, selectTagOverFlow: selectTag.overflow });
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        this.toolTopBox.style.display = 'none';
    }
    getStringLength(str) {
        let len = 0;
        if (str === undefined) {
            return len;
        }
        for (let i = 0; i < str.length; i++) {
            const c = str.charCodeAt(i);
            if ((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f)) {
                len++;
            }
            else {
                len += 2;
            }
        }
        return len;
    }
    getTrianglePath(pathData, key, stroke = "", strokeWidth, cursor = "pointer", pointerEvents = "visible") {
        if((this.props.showHiLowEstimate && key === 0) || (!this.props.showHiLowEstimate && key ===1)) {
            return (
                <path d={pathData} data-disable-track-price="true"
                    ref={ (ref) => (this.topTriangle = ref)}
                    key={key}
                    stroke={stroke}
                    fill={stroke}
                    strokeWidth={strokeWidth}
                    cursor={cursor}
                    onFocus={(e) => this.handleMouseOver(e, key)}
                    onClick={(e) => this.handleMouseClick(e, key)}
                    onMouseOver={(e) => this.handleMouseOver(e, key)}
                    onMouseLeave={(e) => this.handleMouseLeave(e, key)}
                    pointerEvents={pointerEvents}
                />
            );
        }
        else {
        return (
            <path d={pathData} data-disable-track-price="true"
                key={key}
                stroke={stroke}
                fill={stroke}
                strokeWidth={strokeWidth}
                cursor={cursor}
                onFocus={(e) => this.handleMouseOver(e, key)}
                onClick={(e) => this.handleMouseClick(e, key)}
                onMouseOver={(e) => this.handleMouseOver(e, key)}
                onMouseLeave={(e) => this.handleMouseLeave(e, key)}
                pointerEvents={pointerEvents}
            />
        );
    }
    }
    getTargetZonePath(pathData, stroke = "", strokeWidth, opacity, cursor = "pointer", pointerEvents = "visible") {
        return (
            <path d={pathData} data-disable-track-price="true"
                stroke={stroke}
                fill={stroke}
                strokeWidth={strokeWidth}
                opacity={opacity}
                cursor={cursor}
                pointerEvents={pointerEvents}
            />
        );
    }

    showInfoBox(showInfo) {
        const backgroundColor = ThemeHelper.getThemedBrush("000fff");   
        const borderColor = ThemeHelper.getThemedBrush("fff000");   
        const width = "110px";
        const xLeft =  `${showInfo.screenX + 10}px`
        const ytop =  `${showInfo.screenY - this.props.tagHeight/2}px`
        const textStyle = {
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderStyle: "solid",
            borderWidth: "1px",
            fontFamily: "calibri",
            position: "absolute",
            left: xLeft,
            top: ytop,
            height: this.props.tagHeight,
            width: width,
            zIndex: 102,
            whiteSpace: 'nowrap'
        };
        if (this.props.showBox) {
            return (
                <div style={textStyle}>
                    <div style={{
                        textAlign: "Center",
                        fontFamily: "calibri",
                        marginLeft: '6px',
                        marginRight: '6px',
                    }}>
                        <span style={{ float: "left", marginLeft: 2,  marginTop: '2px', fontWeight: 'bold', fontSize: '11px', textAlign: 'center' }}>{showInfo.showText}</span>
                        <span style={{ float: "right", marginRight: 2, marginTop: '2px', fontWeight: 'bold', fontSize: '11px', textAlign: 'center' }}>{showInfo.showPct}</span>
                    </div>
                </div>
            );
        }
        else {
            return (<div></div>)
        }
    }
    showTagBox(stArrow, graphColor, fontColor) {
        const mainDiv = {
            width: "115px", height: `${this.props.tagHeight}px`, position: "absolute",
            left: "100%",
            top: `${stArrow.screenY - this.props.tagHeight/2}px`, zIndex: "102", marginLeft: '0px',
            pointerEvents: 'none'
        };

        const halfHeight = this.props.tagHeight / 2;

        const blockDiv = {
            backgroundColor: graphColor,
            color: fontColor,
            height: "100%",
            width: `${(this.props.tagWidth - halfHeight)}px`,
            textAlign: "left"
        };

        const arrow = {
            borderTop: `${halfHeight}px solid transparent`,
            borderBottom: `${halfHeight}px solid transparent`,
            borderRight: `${halfHeight}px solid ${graphColor}`,
        };
        if (stArrow.showText && stArrow.showPct) {
            return (
                <div ref={(ref) => (this.mainDiv = ref)} style={mainDiv}>
                    <div style={{ display: "flex" }}>
                        <div style={arrow}></div>
                        <div style={blockDiv}>
                            <span style={{ float: "left", marginLeft: 2, color: fontColor, fontFamily: "calibri", fontWeight: 'bold', fontSize: '12px', marginTop: '2px' }} >{stArrow.showText}</span>
                            <span style={{ float: "right", marginRight: 2, color: fontColor, fontFamily: "calibri", fontWeight: 'bold', fontSize: '12px', marginTop: '2px' }} >{stArrow.showPct}</span>
                        </div>
                    </div>
                </div>
            );
        }
        else{
            return (<div></div>);
        }
    }
    showAllInfo(tagData) {
        let maxValueLength = 0;
        let maxPctLength = 0;
        let totalItems = 0;
        const backgroundColor = ThemeHelper.getThemedBrush("E1E20FEFEF0");
        for (let t = 0; t < tagData.length; t++) {
            if (tagData[t]) {
                if (this.getStringLength(tagData[t].showText) > maxValueLength){
                    maxValueLength = this.getStringLength(tagData[t].showText);
                }
                if (this.getStringLength(tagData[t].showPct) > maxPctLength){
                    maxPctLength = this.getStringLength(tagData[t].showPct);
                }
                totalItems++;
            }
        }
        if (tagData.length > 0) {
            totalItems = totalItems + 1;
        }
        const grayText = ThemeHelper.getThemedBrush("scaleText");
        const fld1 = `${maxValueLength * 8}px`;
        const fld2 = `${maxPctLength * 8}px`;
        const height = `${Math.max(1, totalItems) * 16 + 18 }px`;
        const width = `${(10 + (maxValueLength + maxPctLength) * 8)}px`;
        const textStyle = {
            backgroundColor: backgroundColor,
            borderColor: "#333333",
            borderStyle: "solid",
            borderWidth: "1px",
            fontFamily: "calibri",
            fontSize: "11px",
            color: "gray",
            position: "absolute",
            left: this.topTriangle.getBoundingClientRect().left + 10,
            top: this.topTriangle.getBoundingClientRect().top + 10,
            height: height,
            width: width,
            zIndex: 105,
            pointerEvents: "auto",
        };
        return (
            <PortalContainer>
                <div style={textStyle}>
                    <div style={{ textAlign: "Center", fontFamily: "calibri", fontWeight: "bold", color: grayText, marginBottom: "4px", marginTop: "4px" }}>
                        Street Targets
                </div>
                    {tagData.map((tagData, index) => (
                        <table style={{ marginTop: "-2px" }} key={index}>
                            {tagData && tagData.showText !== "" &&
                                <tbody>
                                    <tr>
                                        <td> <div style={{ marginLeft: "8px", color: grayText, width: fld1, textAlign: "left" }}>
                                            {tagData.showText}</div>
                                        </td>
                                        <td> <div style={{ textAlign: "left", color: grayText, width: fld2 }}>
                                            {tagData.showPct}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </table>
                    ))}
                </div>
            </PortalContainer>
        );
    }

    render() {
        if(this.props.streetTargetPricePointData.length < 3 || !this.props.isStreetTargetPriceVisible){
            return null;
        }
        const lineStyle = {
            position: "absolute",
            fitPosition: "fill",
            left: "0px",
            top: "0px",
            zIndex: "2",
            overflow: "visible"
        };
        const lineStrokeWidth = 4;
        const triangleStrokeWidth = 1;
        const opacity = 0.6;
        const streetTargetPriceData = this.props.streetTargetPricePointData;
        const graphColor = ThemeHelper.getThemedBrush(this.props.lineColor);
        const fontColor =  ThemeHelper.getFontColor(graphColor);
        const tooltipData = [];
        this.props.streetTargetPricePointData.forEach((item)=>{
            const tagData = [];
            tagData.showText = item.showText;
            tagData.showPct = item.showPct;
            tagData.screenX = item.screenX;
            tagData.screenY = item.screenY;
            tooltipData.push(tagData);
        });
        
        return (
            <Measure
                bounds
                onResize={()=> this.handleResize()}>
                {({ measureRef }) =>
                    <div ref={measureRef}>
                            {(this.props.showBox && !this.props.showTag && streetTargetPriceData[0].overflow === this.props.selectTagOverFlow) && this.props.showHiLowEstimate && 
                            (!streetTargetPriceData[0].overflow &&
                                this.showInfoBox(streetTargetPriceData[0]))
                        }
                        {(this.props.showBox && !this.props.showTag && streetTargetPriceData[1].overflow === this.props.selectTagOverFlow) &&
                            (!streetTargetPriceData[1].overflow &&
                                this.showInfoBox(streetTargetPriceData[1]))
                        }
                        {(this.props.showBox && !this.props.showTag && streetTargetPriceData[2].overflow === this.props.selectTagOverFlow) && this.props.showHiLowEstimate &&
                            (!streetTargetPriceData[2].overflow &&
                                this.showInfoBox(streetTargetPriceData[2]))
                        }
                        {((this.props.showAllInfoTag) || (this.props.showBox && this.props.selectTagOverFlow === true)) && 
                            this.showAllInfo(tooltipData, this.props.chartWidth)
                        }
                        {(this.props.showTag) && this.props.showHiLowEstimate && this.props.isStreetTargetPriceVisible &&
                            (!streetTargetPriceData[0].overflow &&
                                this.showTagBox(streetTargetPriceData[0], graphColor, fontColor))
                        }
                        {(this.props.showTag) &&
                            (!streetTargetPriceData[1].overflow && this.props.isStreetTargetPriceVisible &&
                                this.showTagBox(streetTargetPriceData[1], graphColor, fontColor))
                        }
                        {(this.props.showTag) && this.props.showHiLowEstimate && this.props.isStreetTargetPriceVisible &&
                            (!streetTargetPriceData[2].overflow &&
                                this.showTagBox(streetTargetPriceData[2], graphColor, fontColor))
                        }
                        <div id="STPInfo" ref={(ref) => (this.mainDiv = ref)} style={{ height: '100%', width: '100%', position: 'absolute', pointerEvents: "none" }}>
                            
                            <svg style={lineStyle}
                                id="LLRAreas"
                                height='100%'
                                width='100%'>
                                {this.props.showHiLowEstimate && this.props.isStreetTargetPriceVisible && this.getTrianglePath(streetTargetPriceData[0].stArrow, 0, graphColor, triangleStrokeWidth, "pointer","visible")}
                                {this.props.showHiLowEstimate && this.props.isStreetTargetPriceVisible && this.props.showTargetPriceZones && !streetTargetPriceData[0].overflow && this.getTargetZonePath(streetTargetPriceData[0].priceTargetZones, graphColor, lineStrokeWidth, opacity, "default")}

                                {this.props.isStreetTargetPriceVisible && this.getTrianglePath(streetTargetPriceData[1].stArrow, 1, graphColor, triangleStrokeWidth, "pointer", "visible")}
                                {this.props.showTargetPriceZones && this.props.isStreetTargetPriceVisible &&  !streetTargetPriceData[1].overflow && this.getTargetZonePath(streetTargetPriceData[1].priceTargetZones, graphColor, lineStrokeWidth, opacity, "default")}

                                {this.props.showHiLowEstimate && this.props.isStreetTargetPriceVisible && this.getTrianglePath(streetTargetPriceData[2].stArrow, 2, graphColor, triangleStrokeWidth, "pointer", "visible")}
                                {this.props.showHiLowEstimate && this.props.isStreetTargetPriceVisible && this.props.showTargetPriceZones && !streetTargetPriceData[2].overflow && this.getTargetZonePath(streetTargetPriceData[2].priceTargetZones, graphColor, lineStrokeWidth, opacity, "default")}
                            </svg>
                        </div>
                        <div ref={(ref) => (this.toolTopBox = ref)} id="rowTooltip" className="tooltip-box" style={{ display:'none' }}></div>
                    </div>
                }
            </Measure>
        );
    }
}
const mapStateToProps = ({ DatagraphReducers, appColor }) =>{
    const { lineColor, streetTargetPricePointData, showTag, showAllInfoTag, tagHeight, tagWidth, showBox, selectTagOverFlow, isStreetTargetPriceVisible, showHiLowEstimate, showTargetPriceZones } = DatagraphReducers.StreetTargetPriceReducer;
    const { chartWidth} = DatagraphReducers.PriceChartReducer;
    const { theme } = appColor;
    return { lineColor, streetTargetPricePointData, showTag, showAllInfoTag, theme, chartWidth, tagHeight, tagWidth, showBox, selectTagOverFlow, isStreetTargetPriceVisible, showHiLowEstimate, showTargetPriceZones };
}
const mapDispatchToProps = (dispatch)=>({
    updateSTPTagSEttings: (showTag)=> dispatch(updateSTPTagSEttings(showTag)),
    updateSTPStates: (states)=> dispatch(updateSTPStates(states)),
})
export default connect(mapStateToProps, mapDispatchToProps)(StreetTargetPrice)
