export default {
  NAV_LIST_MANAGER: {
    ENTER_APPLY: ['enter'],
    MOVE_UP: ['up'],
    MOVE_DOWN: ['down'],
    SELECT_ALL: {
      osx: 'command+a',
      windows: 'ctrl+a',
      linux: 'ctrl+a'
    },
    COPY: {
      osx: 'command+c',
      windows: 'ctrl+c',
      linux: 'ctrl+c'
    },
    PASTE: {
      osx: 'command+v',
      windows: 'ctrl+v',
      linux: 'ctrl+v'
    },
    DELETE_ROW: {
      osx: 'command+backspace',
      windows: 'del',
      linux: 'del'
    },
    ACTIVE_ROW: {
      osx: 'space',
      windows: 'space',
      linux: 'space'
    },
    RENAME: {
      osx: 'fn+f2',
      windows: 'fn+f2',
      linux: 'fn+f2'
    },
    SHIFT_DOWN:{
      windows: 'shift+down',
    },
    SHIFT_UP:{
      windows: 'shift+up',
    },
    PRINT: {
      windows: 'ctrl+p'
    }
  }
}
