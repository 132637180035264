import React, { Fragment } from 'react';

import { UpdateSharedData } from "../../../../Actions/ShareAction";

import { filter, find } from "underscore";

const ICSData = ({ ICSrow, isICSrowChecked, sharedData }) => {

    const handleSelectICS = () => (event) => {
        const rowData = {
            accessLvl: 1,
            email: ICSrow[0].email,
            isICS: true,
            isShared: true,
            organization: ICSrow[0].organization,
            recipientId: ICSrow[0].recipientId,
            userName: ICSrow[0].userName,
            isModified: true,
            isAdded: true
        }
        if (event.target.checked) {
            UpdateSharedData(sharedData.concat(rowData));
        } else {
            const filterData = sharedData.filter((item) => item.recipientId.low !== rowData.recipientId.low);
            UpdateSharedData(filterData);
        }
    }

    return (
        <Fragment>
            <span className='select-check'>
                <input type='checkbox' id="shareICS" checked={isICSrowChecked} onChange={handleSelectICS()} />
                <label htmlFor="shareICS">{ICSrow[0].userName}</label>
            </span>
            <span className='select-mail'>{ICSrow[0].email}</span>
        </Fragment>
        // <tbody>
        //     <tr>
        //         <td className='check-info'>
        //             <input
        //                 type='checkbox'
        //                 checked={isICSrowChecked}
        //                 onChange={handleSelectICS()}
        //             />
        //         </td>
        //         <td className='user-Name-info'>
        //             {ICSrow[0].userName}
        //         </td>
        //         <td className='email-info' colSpan='2'>
        //             {ICSrow[0].email}
        //         </td>
        //     </tr>
        // </tbody>
    )
}

export default ICSData;