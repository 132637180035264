import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class ChangePasswordRequest extends BaseServiceRequest {
    constructor(oldPassword, newPassword, loginErrType) {
        super();
        super.addRequestUri("password.pb");
        let str = oldPassword + "\n" + newPassword + "\n" + loginErrType;
        let blob = new Blob([str], { type: 'text/plain' });
        super.setContent(blob);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.USER;
    }

    getMessageName() {
        return "ChangePasswordResponse";
    }
}

export default ChangePasswordRequest;