const { ActionTypes } = VolumeChartConstants;
import { VolumeChartConstants } from "../Constants/VolumeChartConstants";

export const prepareVolumeData = () => ({
    type: ActionTypes.PREPARE_VOLUME_INSIDER_DATA,
})

export const volumeScaleChange = () => ({
    type: ActionTypes.VOLUME_SCALE_CHANGED,
})

export const updateDimension = (dimension) => ({
    type: ActionTypes.UPDATE_VOLUME_CHART_DIMENSIONS,
    dimension
})

export const updatesShowNQBox = () => ({
    type: ActionTypes.UPDATE_NQ_BOX,
})

export const onOpenCollapse = (blockType, isopen) => ({
    type: ActionTypes.VOLUME_CHART_COLLAPSE_BLOCK,
    blockType, isopen
})
export const updateVolumeOpenInterest = (item) => ({
    type: ActionTypes.UPDATE_VOLUME_OPEN_INTEREST,
    item
})