export const CorpEventsType =
{
    AddedToindex : { value: 0 },
    Announcement : { value: 1 },
    Ceo : { value: 2},
    CorporateRepurchase : { value : 3},
    Dividend : { value : 4},
    Earnings : { value : 5},
    InsiderBuy : { value : 6},
    InsiderSell : { value : 7},
    MajorArticles : { value : 8},
    RepurchaseAnnouncement : { value : 9},
    SecurityAnalystMeeting : { value : 10},
    Split : { value : 11},
    ExchangeListing : { value : 12},
    CoCeo : { value : 13}
};

export const CorpActionTypeEnum =
{
    ADDEDTOINDEX : 0,
    ANNOUNCEMENT : 1,
    CEO : 2,
    CORPORATEREPURCHASE : 3,
    DIVIDEND : 4,
    EARNINGS : 5,
    INSIDERBUY : 6,
    INSIDERSELL : 7,
    MAJORARTICLES : 8,
    REPURCHASEANNOUNCEMENT : 9,
    SECURITYANALYSTMEETING : 10,
    SPLIT : 11,
    EXCHANGELISTING : 12,
    COCEO : 13
};