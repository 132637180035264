import _ from 'underscore'
import ChartVisual from "../../../../../RayCustomControls/Chart/Visuals/ChartVisual";
import { connect } from "react-redux";
import LocalizationStore from '../../../../../Stores/Localization/LocalizationStore.js';
import React from "react";
import { initTimeLine } from "../../../../../Actions/TabDataGraphAction.js";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import UserInfoUtil from '../../../../../Utils/UserInfoUtil.js';
import DatagraphHelper from "../../../../../Utils/DatagraphHelper"
import stringUtil from '../../../../../Utils/StringUtil';
import { updateFactorTileClick, getRIPanelFactorInfo } from "../../../../../Actions/DatagraphActions";

class FactorResearch extends ChartVisual {
    constructor(props) {
        super(props);
        this.handleButtonMouseEnter = this.handleButtonMouseEnter.bind(this);
        this.handleButtonMouseLeave = this.handleButtonMouseLeave.bind(this);
        this.line = {};
        this.flag = {};
        this._isMounted = false;
    }

    componentDidUpdate() {
        // if (prevState.removeList.length !== this.props.removeList.length || prevState.lastOnly !== this.props.lastOnly || prevState.visibility !== this.props.visibility) {
        for (const factorKey of this.props.removeList) {
            if (factorKey) {
                const elem = this.flag[factorKey];
                const path = this.line[factorKey];
                if (path) {
                    const left = path.getBBox().x;
                    elem.style.left = `${left - 2}px`;
                    elem.style.right = "auto";
                }
            }
        }

        for (const recoverKey of this.props.recoverList) {
            if (recoverKey) {
                const elem = this.flag[recoverKey];
                if (elem) {
                    elem.style.left = "auto";
                    const eventData = this.props.FRData.find((i) => i.FactorKey === recoverKey);
                    if (eventData && eventData.flagPosition) {
                        elem.style.right = `${eventData.flagPosition.right}px`;
                    }
                }
            }
        }
        // }
    }


    handleButtonMouseEnter(e) {
        const lineColor = ThemeHelper.getThemedBrush('PFFFFFF000000');
        const color = ThemeHelper.getThemedBrush('000fff');
        e.target.style.color = color;
        e.target.style.backgroundColor = lineColor;
        e.target.style.zIndex = 10000
    }

    handleButtonMouseLeave(e) {
        const lineColor = ThemeHelper.getThemedBrush('PFFFFFF000000');
        const color = ThemeHelper.getThemedBrush('000fff');
        if (e.target.dataset.factorKey === this.props.selectedFactorKey) {
            e.target.style.color = color
            e.target.style.backgroundColor = lineColor;
        } else {
            e.target.style.color = lineColor;
            e.target.style.backgroundColor = color;
        }
        e.target.style.zIndex = 8
    }

    getPath(pathData, datasource, keyValue, dashValue = 0, fill = "none", stroke = "", opacity = "0.5", pointerEvents = "visible", strokeWidth = "2px", cursor = "pointer", strokeLinecap = "butt", visibility) {
        if (pointerEvents === "none") {
            cursor = "default";
        }
        return (
            <path
                d={pathData}
                data-disable-track-price="true"
                ref={(ref) => (this.line[keyValue] = ref)}
                fill={fill}
                key={keyValue}
                className={keyValue}
                stroke={stroke}
                strokeDasharray={dashValue}
                strokeWidth={strokeWidth}
                strokeLinecap={strokeLinecap}
                cursor={cursor}
                opacity={opacity}
                onFocus={(e) => this.handleMouseOver(datasource, e)}
                pointerEvents={pointerEvents}
                visibility={visibility}
            />
        );
    }

    handleButtonClick = (_e, factor) => {
        const { oldSelectedFactorKey, oldSelectedEvent, graphOpenList, graphOpenListHistory } = this.props;
        const currKey = factor.FactorKey;
        let newSelectedEvent, newSelectedFactorKey;
        let newGraphOpenList, newGraphOpenListHistory;
        // find the new event that should be 
        if (oldSelectedFactorKey !== currKey) {
            newSelectedFactorKey = currKey;
            newSelectedEvent = factor;
            if (_e.target.className === "factor-trigger") {
                if (graphOpenList.includes(newSelectedFactorKey)) {
                    newGraphOpenList = _.without(graphOpenList, newSelectedFactorKey);
                    newSelectedEvent = oldSelectedEvent;
                    newSelectedFactorKey = oldSelectedFactorKey;
                    newGraphOpenListHistory = _.without(graphOpenListHistory, currKey);
                } else {
                    newGraphOpenList = [...graphOpenList, newSelectedFactorKey];
                    newGraphOpenListHistory = [newSelectedFactorKey, ..._.without(graphOpenListHistory, newSelectedFactorKey)];
                }
            } else if (newSelectedFactorKey) {
                newGraphOpenList = graphOpenList;
                newGraphOpenListHistory = [newSelectedFactorKey, ..._.without(graphOpenListHistory, newSelectedFactorKey)];
            }
        } else {
            if (_e.target.className === "factor-trigger") {
                newGraphOpenListHistory = _.without(graphOpenListHistory, currKey);
                newSelectedFactorKey = graphOpenListHistory.length === 1 ? graphOpenListHistory[0] : graphOpenListHistory[1] ? graphOpenListHistory[1] : currKey;
                newSelectedEvent = newSelectedFactorKey ? this.props.FRData.flat().find((i) => i.FactorKey === newSelectedFactorKey) : null;
                newGraphOpenList = [..._.without(graphOpenList, currKey)];
            } else {
                newGraphOpenList = graphOpenList;
                newSelectedFactorKey = oldSelectedFactorKey;
                newSelectedEvent = oldSelectedEvent;
                newGraphOpenListHistory = graphOpenListHistory;
            }
        }
        this.props.updateFactorTileClick(newSelectedFactorKey, newSelectedEvent, newGraphOpenList, newGraphOpenListHistory)
        if (newSelectedFactorKey) {
            if (newSelectedEvent && newSelectedEvent.upToDate.getTime() > this.props.TimeLineData.dates[0].Date.getTime()) {
                this.props.initTimeLine(newSelectedEvent.upToDate, this.props.nodeCount, this.props.InitialBufferSize,false,false,true);
            } else if (newGraphOpenList.length > 0) {
                newGraphOpenList.sort((a, b) => {
                    const eventA = this.props.FRData.find((i) => i.FactorKey === a);
                    const eventB = this.props.FRData.find((i) => i.FactorKey === b);

                    if (eventA && eventB) {
                        if (eventA.upToDate < eventB.upToDate) {
                            return 1;
                        }

                        if (eventA.upToDate > eventB.upToDate) {
                            return -1;
                        }
                    }
                    return 0;
                });
                const longestEvent = this.props.FRData.find((i) => i.FactorKey === newGraphOpenList[0]);
                if (longestEvent && longestEvent.upToDate.getTime() >= this.props.endDate.getTime()) {
                    this.props.initTimeLine(this.props.endDate, this.props.nodeCount, this.props.InitialBufferSize,false,false,true);
                } else if (longestEvent && longestEvent.upToDate.getTime() < this.props.endDate.getTime()) {
                    this.props.initTimeLine(this.props.endDate, this.props.nodeCount, this.props.InitialBufferSize,false,false,true);
                }
            }
            else if (newGraphOpenList.length === 0) {
                this.props.initTimeLine(this.props.endDate, this.props.nodeCount, this.props.InitialBufferSize,false,false,true);
            }
        }

        if (!newSelectedFactorKey) {
            if (oldSelectedEvent && oldSelectedEvent.upToDate.getTime() > this.props.endDate.getTime()) {
                this.props.initTimeLine(this.props.endDate,0,0,false,false,true);
            }
        } else if (newSelectedEvent && newSelectedEvent.InView && newGraphOpenList.length > 0) {
            this.props.getRIPanelFactorInfo(newSelectedEvent);
        }
    };

    handleMouseEnter = (e) => {
        const target = e.target;
        target.style.transform = `rotate(45deg) scale(1.2)`;
    };

    handleMouseLeave = (e, factor) => {
        const factorKey = factor.FactorKey;
        const selector = `button.factor-trigger[data-factor-key='${factorKey}']`;

        const target = document.querySelector(selector);
        if (target) {
            target.style.transform = `rotate(45deg) scale(1)`;
        }
    };

    renderFactorFlag = () => {
        if (!this.props.FRData || this.props.FRData.length === 0) {
            return null;
        }
        return this.props.FRData.map((factorList, index) => {
            const factorKeyList = factorList.map((factor) => factor.FactorKey)
            const count = _.intersection(this.props.graphOpenList, factorKeyList).length - 1
            let unSelecteCount = 0
            return factorList.map((factor, subIndex) => {
                const next = factorList[subIndex + 1];
                if (next) {
                    const isDup = next.EventId === factor.EventId && next.SegmentId === factor.SegmentId && next.eventDate === factor.eventDate;
                    if (isDup && factor.factorEventConeData.length !== 0) {
                        return null;
                    }
                }

                if (!this.props.graphOpenList.includes(factor.FactorKey)) {
                    unSelecteCount++;
                }
                const key = factor.FactorKey;
                const isSelected = this.props.selectedFactorKey === key;
                const lineColor = ThemeHelper.getThemedBrush("PFFFFFF000000");
                const color = ThemeHelper.getThemedBrush("000fff");
                const factorLen = factorList.length
                const buttonStyle = Object.assign(
                    {
                        position: "absolute",
                        cursor: "pointer",
                        pointerEvents: "all",
                        fontSize: "12px",
                        height: "20px",
                        zIndex: 8,
                        width: "fit-content",
                        backgroundColor: isSelected ? lineColor : color,
                        color: isSelected ? color : lineColor,
                        // backgroundColor: '#fff',
                        // color: '#000',
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: lineColor,
                        padding: "2px",
                        whiteSpace: "nowrap",
                        // visibility: isSelected ? 'visible' : 'hidden'
                        visibility: this.props.graphOpenList.indexOf(key) === -1 || !factor.InView ? "hidden" : "visible",
                    },
                    _.mapObject(factor.flagPosition, (value, key) => {
                        if (key === "top" || key === "bottom") {
                            return value + (factorLen - subIndex + unSelecteCount + count - 1) * 22;
                        } else {
                            return value;
                        }
                    }),
                );
                if ((this.props.visibility && !this.props.lastOnly) || (this.props.visibility && this.props.lastOnly && key === this.props.lastEventKey)) {
                    return (
                        <button
                            className="factor-tile"
                            ref={(ref) => {
                                this.flag[key] = ref;
                            }}
                            onClick={(e) => this.handleButtonClick(e, factor)}
                            onMouseEnter={this.handleButtonMouseEnter}
                            onMouseLeave={this.handleButtonMouseLeave}
                            style={buttonStyle}
                            key={`${index}-${subIndex}`}
                            data-factor-key={key}
                            data-disable-track-price="true"
                        >
                            {LocalizationStore.getTranslatedData(`FR_eventid_${factor.eventId}`, factor.EventName)}
                        </button>
                    );
                } else {
                    return null;
                }
            });
        });
    };

    renderFactorButton() {
        if (!this.props.FRData || this.props.FRData.length === 0) {
            return null;
        }

        const bullishColor = UserInfoUtil.hasChinaEntitlement() ? "blue" : "green";
        const bearishColor = UserInfoUtil.hasChinaEntitlement() ? "orange" : "red";
        const fff000 = ThemeHelper.getThemedBrush("000fff");
        return this.props.FRData.map((factorList) =>
            factorList.map((factor, subIndex) => {
                const factorLen = factorList.length
                const { buttonPosition, eventIndicatorType } = factor;
                const key = factor.FactorKey;
                const isSelected = this.props.graphOpenList.includes(key);
                const fillColor = isSelected ? (eventIndicatorType ? bullishColor : bearishColor) : fff000;
                const strokeColor = eventIndicatorType ? bullishColor : bearishColor;
                const y = eventIndicatorType ? buttonPosition.y + (factorLen - subIndex) * 15 : buttonPosition.y - (factorLen - subIndex) * 15 - 9;
                const style = {
                    borderColor: strokeColor,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    backgroundColor: fillColor,
                    position: "absolute",
                    left: buttonPosition.x - 3.7,
                    top: eventIndicatorType ? y - 3.7 : y + 3.7,
                    cursor: "pointer",
                    pointerEvents: "all",
                    zIndex: 9,
                    width: "7px",
                    height: "7px",
                    padding: "0px",
                    transform: "rotate(45deg)",
                };

                if ((this.props.visibility && !this.props.lastOnly) || (this.props.visibility && this.props.lastOnly && key === this.props.lastEventKey)) {
                    return (
                        <button
                            style={style}
                            className="factor-trigger"
                            onClick={(e) => this.handleButtonClick(e, factor)}
                            onMouseEnter={(e) => this.handleMouseEnter(e, factor)}
                            onMouseLeave={(e) => this.handleMouseLeave(e, factor)}
                            key={key}
                            pointerEvents="visible"
                            data-disable-track-price="true"
                            data-factor-key={key}
                        />
                    );
                } else {
                    return null;
                }
            }),
        );
    }

    generateIndicator(key, x, y, text, border) {
        const positiveDataText = ThemeHelper.getThemedBrush("positiveDataText");
        const negativeDataText = ThemeHelper.getThemedBrush("negativeDataText");
        const fff000 = ThemeHelper.getThemedBrush("fff000");

        return (
            <g className="indicator" key={`indicators-${key}`}>
                <rect x={x} y={y === 10 ? 0 : y - 10} width="36px" height="13px" fill="#e8e6e9" stroke={border ? fff000 : "none"}></rect>
                <text x={x + 3} y={y} fontSize="10px" style={{ fontWeight: "bold" }} fill={text.includes("-") ? negativeDataText : positiveDataText}>
                    {text}
                </text>
            </g>
        );
    }

    generateLabel(key, x, y, winnerOrLoser, percent, fill, rectFill) {
        return (
            <g className="factor-label" key={`labels-${key}`}>
                <rect x={x} y={y === 10 ? 0 : y - 10} width="60px" height="13px" fill={rectFill}></rect>
                <text x={x} y={y} fontSize="10px" fill={fill} textLength="60px">
                    {winnerOrLoser}:{percent}
                </text>
            </g>
        );
    }

    getRevisedTargetPrice(d, revisedTargetPrice) {
        const _fff000 = ThemeHelper.getThemedBrush("000fff");

        const { price, lineData, circleData, indicator } = revisedTargetPrice;
        if (price) {
            const color = parseFloat(indicator.percent) > 0 ? this.props.posColor : this.props.negColor;
            d.push(this.getPath(lineData, {}, "revised", 2, "", color, "1", "none"));
            d.push(<circle cx={circleData.cx} cy={circleData.cy} r="3" key="revised-circle" fill={_fff000} stroke={color} strokeWidth="2" />);
            d.push(this.generateIndicator("revisedValue", indicator.x, indicator.y, indicator.percent, true));
        }
    }

    checkIfShowTileLine(factorLen, pickOutEvent, FactorKey) {
        const { graphOpenList, lastEventKey, lastOnly } = this.props;

        if (factorLen === 1 && graphOpenList.includes(FactorKey)) {
            return "visible";
        } else if (factorLen > 1 && pickOutEvent) {
            if (pickOutEvent.FactorKey !== lastEventKey) {
                return pickOutEvent.FactorKey === FactorKey ? "visible" : "hidden";
            } else {
                if (lastOnly && !graphOpenList.includes(lastEventKey)) {
                    return "hidden";
                } else {
                    return pickOutEvent.FactorKey === FactorKey ? "visible" : "hidden";
                }
            }
        }
        return 'hidden'
    }

    getTileLine(d, factor, subIndex, factorLen, pickOutEvent) {
        const { flagPosition, eventIndicatorType, startIn, startY, FactorKey } = factor;
        let moveToY;
        let endY;
        const moveFactor = (subIndex - 1 + factorLen) * 22;
        const endFactor = (factorLen + 1) * 15;

        if (eventIndicatorType) {
            moveToY = flagPosition.markerHeight + moveFactor;
            endY = startY + endFactor;
        } else {
            moveToY = flagPosition.markerHeight - moveFactor;
            endY = startY - endFactor;
        }

        const tileLineData = `M ${startIn} ${moveToY} L${startIn} ${endY}`;
        const ShowTileLine = this.checkIfShowTileLine(factorLen, pickOutEvent, FactorKey);
        const fff000 = ThemeHelper.getThemedBrush("fff000");

        d.push(this.getPath(tileLineData, {}, "tile-line", 2, "none", fff000, "1", "visible", "2px", "default", "butt", ShowTileLine));
    }

    renderFactorGraph() {
        const lineColor = ThemeHelper.getThemedBrush("outerDashLine");
        const targetLineColor = ThemeHelper.getThemedBrush("targetDashLine");
        const fff000 = ThemeHelper.getThemedBrush("fff000");
        if (!this.props.FRData || this.props.FRData.length === 0) {
            return null;
        }
        const { graphOpenList } = this.props;
        return this.props.FRData.map((factorList, index) => {
            let pickOutEvent;
            const factorLen = factorList.length;

            if (factorLen > 1) {
                factorList.forEach((i) => {
                    if (graphOpenList.includes(i.FactorKey) && !pickOutEvent) {
                        pickOutEvent = factorList[factorLen - 1];
                    }
                });
            }
            return factorList.map((factor, subIndex) => {
                const next = factorList[subIndex + 1];
                const factorLen = factorList.length
                if (next) {
                    const isDup = next.EventId === factor.EventId && next.SegmentId === factor.SegmentId && next.eventDate === factor.eventDate;
                    if (isDup && factor.factorEventConeData.length !== 0) {
                        return null;
                    }
                }
                const d = [];
                const { targetPath, conePath, targetPathData, maxPathData, FactorKey, revisedTargetPrice, indicators, labels, shadings, targetCircleData } = factor;

                d.push(this.getPath(targetPath, {}, "target-path", 3, "none", targetLineColor, "1", "storke", "1px", "", "butt"));

                // outer dash line
                d.push(this.getPath(conePath, {}, "max-path", 3, "none", lineColor, "1", "stroke", "1px", "", "butt"));

                d.push(<circle key="target-circle" cx={targetCircleData.cx} cy={targetCircleData.cy} fill={fff000} r="3" />);

                shadings.forEach((item, index) => d.push(this.getPath(item.pathData, {}, `factor-transparent-box shading${index}`, 0, item.color === "positive" ? this.props.posColor : this.props.negColor, "none", "0.15", "stroke", "", "default")));

                labels.forEach((item, index) => {
                    const labelBackground = item.isTruncated ? "#fff" : "transparent";
                    const textColor = item.isTruncated ? "#000" : fff000;
                    d.push(this.generateLabel(index, item.x, item.y, LocalizationStore.getTranslatedData(`ri_factor_${item.label.toLowerCase()}`, item.label), item.percent, textColor, labelBackground));
                });

                indicators.forEach((item, index) => d.push(this.generateIndicator(index, item.x, item.y, item.percent)));

                this.getTileLine(d, factor, factorLen - 1 - subIndex, factorLen, pickOutEvent);

                if (factor.isActive && revisedTargetPrice.price) {
                    this.getRevisedTargetPrice(d, revisedTargetPrice);
                }

                if ((this.props.visibility && !this.props.lastOnly) || (this.props.visibility && this.props.lastOnly && FactorKey === this.props.lastEventKey)) {
                    return (
                        <g
                            key={`${index}-${subIndex}`}
                            data-factor-key={FactorKey}
                            visibility={
                                graphOpenList.indexOf(FactorKey) === -1 || !factor.InView ? "hidden" : "visible"
                                // selectedFactorKey === factorKey ? 'visible' : 'hidden'
                            }
                        >
                            {d}
                        </g>
                    );
                } else {
                    return null;
                }
            });
        });
    }

    render() {
        const showFactor = DatagraphHelper.isShowPatternORFactor();
        if (this.props.videoMode === false && !stringUtil.isEmpty(this.props.FRData) && showFactor) {
            TimeTrackingWindow.trackChartLoadingTime();
            const lineStyle = {
                position: "absolute",
                fitPosition: "fill",
                left: "0px",
                top: "0px",
                zIndex: "8",
            };
            return (
                <div
                    id="FRInfo"
                    ref={(ref) => (this.mainDiv = ref)}
                    style={{
                        position: "absolute",
                        height: '100%',
                        width: '100%',
                        pointerEvents: 'none',
                        overflow: 'hidden'
                    }}>
                    <svg style={lineStyle}
                        className="svg factor-research"
                        id="PRAreas"
                        height='100%'
                        width='100%'>
                        {this.renderFactorGraph()}
                    </svg>
                    {this.renderFactorFlag()}
                    {this.renderFactorButton()}
                </div>
            );
        } else {
            return null
        }
    }
}
const mapStateToProps = ({ DatagraphReducers, appColor }) => {
    const { FRData, graphOpenList, recoverList, selectedFactorKey, visibility, lastOnly, lastEventKey, removeList,
        graphOpenListHistory, oldSelectedFactorKey, oldSelectedEvent } = DatagraphReducers.FactorResearchReducer;
    const { videoMode, endDate, nodeCount, InitialBufferSize } = DatagraphReducers.DataGraphReducer;
    const { TimeLineData } = DatagraphReducers.TimeLineReducer;
    const { isThemeChanged, negColor, posColor } = appColor;
    return ({
        FRData, recoverList, selectedFactorKey, visibility, lastOnly,  graphOpenList, lastEventKey, removeList, graphOpenListHistory,
        videoMode, endDate, oldSelectedFactorKey, oldSelectedEvent, nodeCount, InitialBufferSize, TimeLineData, isThemeChanged, negColor, posColor
    });
};
const mapDispatchToProps = (dispatch) => ({
    updatePanelTab: () => null,
    updateFactorTileClick: (newSelectedFactorKey, newSelectedEvent, newGraphOpenList, newGraphOpenListHistory) => dispatch(updateFactorTileClick(newSelectedFactorKey, newSelectedEvent, newGraphOpenList, newGraphOpenListHistory)),
    getRIPanelFactorInfo: (newSelectedEvent) => dispatch(getRIPanelFactorInfo(newSelectedEvent)),
    initTimeLine: (date, nodeCount, InitialBufferSize, isMountainChart, isComparisionChart, isInitial) => dispatch(initTimeLine(date, nodeCount, InitialBufferSize, isMountainChart, isComparisionChart, isInitial))
});
export default connect(mapStateToProps, mapDispatchToProps)(FactorResearch);
