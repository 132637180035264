import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class CustomColumnGetRequest extends BaseServiceRequest {
    constructor(metricId) {
        super();
        super.addRequestUri("getcustomcolinfo.pb");
        super.addRequestParameter("msitemid", metricId.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "customCalcInfoResponse";
    }
}

export default CustomColumnGetRequest;