import ListManagerTabType from "ListManagerTabType";
import TabONeilSettings from './TabONeilSettings.js';
import TabOwnershipSettings from './TabOwnershipSettings.js';
import TabExternalSettings from './TabExternalSettings.js';
import NupChartSettings from './NupChartSettings.js'
import Serializable from "Serializable";
import UserInfoUtil from 'UserInfoUtil'
import BaseServiceApi from 'BaseServiceApi'
import ExportListSettings from "./ExportListSettings";
                
class ListManagerSettings extends Serializable {

  onSeralisedInfoRecieved(serializedValue) {    
    let EntitlementType = BaseServiceApi.rayData["EntitlementType"];
    this.SelectedTabKey = serializedValue.SelectedTabKey ? serializedValue.SelectedTabKey : this.getDefaultSelectedTab();
    this.MiniListPlayInterval = serializedValue.MiniListPlayInterval ? serializedValue.MiniListPlayInterval : 5;
    
    this.SelectedTabKey = serializedValue.SelectedTabKey ? serializedValue.SelectedTabKey : this.getDefaultSelectedTab();    
    if(!UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement) && serializedValue.SelectedTabKey===ListManagerTabType.External){
      this.SelectedTabKey = this.getDefaultSelectedTab();
    }
    this.TabCollection = [];
    this.TabCollection.push({ Header: ListManagerTabType.Oneil, TabType: ListManagerTabType.Oneil })
    this.TabCollection.push({ Header: ListManagerTabType.Ownership, TabType: ListManagerTabType.Ownership })
    this.TabCollection.push({ Header: ListManagerTabType.External, TabType: ListManagerTabType.External });

    this.TabONeilSettings = serializedValue.TabONeilSettings ? serializedValue.TabONeilSettings : this.getDefaultTabONeilSettings();

    this.TabOwnershipSettings = serializedValue.TabOwnershipSettings ? serializedValue.TabOwnershipSettings : this.getDefaultTabOwnershipSettings();
  
    this.TabExternalSettings  = serializedValue.TabExternalSettings ? serializedValue.TabExternalSettings : this.getDefaultTabExternalSettings();
    this.SelectedTabKeyMiniList = serializedValue.SelectedTabKeyMiniList ? serializedValue.SelectedTabKeyMiniList : this.getDefaultSelectedTabMiniList();

    this.NupChartSettings = serializedValue.NupChartSettings ? serializedValue.NupChartSettings : this.getDefaultNupChartSettings();
    
    this.ExportListSettings = serializedValue.ExportListSettings ? serializedValue.ExportListSettings : this.getDefaultExportListSettings();
  }

  getDefaultListManagerSettings() {
    this.SelectedTabKey = this.getDefaultSelectedTab();
    this.MiniListPlayInterval = 5;

    this.TabCollection = [];
    this.TabCollection.push({ Header: ListManagerTabType.Oneil, TabType: ListManagerTabType.Oneil });
    this.TabCollection.push({ Header: ListManagerTabType.Ownership, TabType: ListManagerTabType.Ownership });
    this.TabCollection.push({ Header: ListManagerTabType.External, TabType: ListManagerTabType.External });

    this.TabONeilSettings = this.getDefaultTabONeilSettings();
    this.TabOwnershipSettings = this.getDefaultTabOwnershipSettings();
    this.TabExternalSettings = this.getDefaultTabExternalSettings();
    this.NupChartSettings = this.getDefaultNupChartSettings();
    this.ExportListSettings = this.getDefaultExportListSettings();
    return this;
  }

  getDataObject() {
    return {
      SelectedTabKey: this.SelectedTabKey,
      MiniListPlayInterval: this.MiniListPlayInterval,
      TabONeilSettings: this.TabONeilSettings,
      TabOwnershipSettings: this.TabOwnershipSettings,
      TabExternalSettings: this.TabExternalSettings,
      SelectedTabKeyMiniList: this.SelectedTabKeyMiniList,
      NupChartSettings: this.NupChartSettings,
      ExportListSettings: this.ExportListSettings
    };
  }

  getObjectMapKey() {
    return "listManagerSettings";
  }

  getDefaultSelectedTab() {
    return ListManagerTabType.Oneil;
  }

  getDefaultSelectedTabMiniList() {
    if(this.SelectedTabKey == ListManagerTabType.External){
      return ListManagerTabType.Oneil;
    }else{
      return this.SelectedTabKey;
    }
  }
  getDefaultNupChartSettings() {
      let nupChartSettings = new NupChartSettings();
      return nupChartSettings.getDefaultNupChartSettings();
  }

  getDefaultTabONeilSettings() {
    let tabONeilSettings = new TabONeilSettings();
    return tabONeilSettings.getDefaultTabONeilSettings();
  }

  getDefaultTabOwnershipSettings() {
    let tabOwnershipSettings = new TabOwnershipSettings();
    return tabOwnershipSettings.getDefaultTabOwnershipSettings();
  }

  getDefaultTabExternalSettings() {
    let tabExternalSettings = new TabExternalSettings();
    return tabExternalSettings.getDefaultTabExternalSettings();
  }

  getDefaultExportListSettings(){
    const exportListSettings = new ExportListSettings();
    return exportListSettings.getDefaultExportListSettings();
  }
}

export default ListManagerSettings;
