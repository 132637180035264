import GraphType from "../../../../../Constants/GraphType.js";
import BlockSize from "../../../../../Constants/BlockSize.js";
import FinancialBlockDisplayMode from "../../../../../Constants/FinanicalBlockDisplayMode.js";
import PePsBlockView from "../../../../../Constants/PePsBlockView.js";
let smallBlockPageSize = 4;
let mediumBlockPage = 7;
let largeBlockSize = 14;
import Serializable from "Serializable";
class FinancialBlockSettings extends Serializable {

  getDefaultGraphView() {
    const graphObject = [
      { isActive: false, showGraphIndex: 0, isShowGraph: false, activeIndex: null },
      { isActive: false, showGraphIndex: 0, isShowGraph: false, activeIndex: null },
      { isActive: false, showGraphIndex: 0, isShowGraph: false, activeIndex: null }
    ];
    return graphObject.map((item) => this.getSerizableObject(item));
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

  onSeralisedInfoRecieved(info) {
    this.DisplayMode = info.DisplayMode ? info.DisplayMode : FinancialBlockDisplayMode.FiscalMode;
    this.CurrentPage = info.CurrentPage ? info.CurrentPage : 0;
    this.PePsBlockView = info.PePsBlockView ? info.PePsBlockView : PePsBlockView.Pe;
    this.IsVisible = info.IsVisible ? info.IsVisible : false;
    this.BlockSize = info.BlockSize ? info.BlockSize : BlockSize.Medium;
    this.PageSize = info.PageSize ? info.PageSize : mediumBlockPage;
    this.RowHeight = 16;
    this.ShowEpsPercentLoss = info.ShowEpsPercentLoss;
    this.TotalPageCount = 3;
    this.LargeBlockSize = largeBlockSize;
    this.periodicity = info.periodicity ? info.periodicity : GraphType.Weekly;
    this.IsAvailable = info.IsAvailable ? info.IsAvailable : false;
    this.graphView = info.graphView ? info.graphView : this.getDefaultGraphView();
    this.CurrentHeaders = info.CurrentHeaders ? info.CurrentHeaders: [];
  }
  getDataObject() {
    return ({
      DisplayMode: this.DisplayMode,
      CurrentPage: this.CurrentPage,
      PePsBlockView: this.PePsBlockView,
      IsVisible: this.IsVisible,
      BlockSize: this.BlockSize,
      PageSize: this.PageSize,
      IsAvailable: this.IsAvailable,
      ShowEpsPercentLoss: this.ShowEpsPercentLoss,
      graphView: this.graphView,
      CurrentHeaders: this.CurrentHeaders
    });

  }
  getObjectMapKey() {

    return "FinancialBlockSettings";
  }
  constructor(periodicity) {
    super();
    this.RowHeight = 16;
    this.DisplayMode = FinancialBlockDisplayMode.FiscalMode;
    this.CurrentPage = 0;
    this.LargeBlockSize = largeBlockSize;
    this.ShowEpsPercentLoss = true;
    this.TotalPageCount = 3;
    this.PePsBlockView = PePsBlockView.Pe;
    this.IsVisible = true;
    this.graphView = this.getDefaultGraphView();
    this.CurrentHeaders = [];
    switch (periodicity) {
      case GraphType.Daily:
        this.IsAvailable = true;
        this.PageSize = smallBlockPageSize;
        this.BlockSize = BlockSize.Small;
        break;
      case GraphType.Weekly:
        this.IsAvailable = true;
        this.PageSize = mediumBlockPage;
        this.BlockSize = BlockSize.Medium;
        break;
      case GraphType.Monthly:
        this.IsAvailable = true;
        this.PageSize = largeBlockSize;
        this.BlockSize = BlockSize.Large;
        break;

      default:
        this.IsAvailable = false;

    }
  }

  UpdateHeaders(currentHeaders) {
    this.Headers = currentHeaders;
  }

  onBlockSizeChanged(blockSize) {
    this.BlockSize = blockSize;
    switch (this.BlockSize) {
      case BlockSize.Small:
        this.PageSize = smallBlockPageSize;
        break;
      case BlockSize.Medium:
        this.PageSize = mediumBlockPage;
        break;
      case BlockSize.Large:
        this.PageSize = largeBlockSize;
        break;
      default:
        break;

    }
  }
  
  getHeaderItemSerizableObject(item) {
    if(item.canToggle) {
      item.ActiveHeader = Object.assign(new Serializable, {...item.ActiveHeader});
      item.Headers = item.Headers.map((h) => Object.assign(new Serializable, {...h}));
      return Object.assign(new Serializable, {...item});
    } else {
      return Object.assign(new Serializable, {...item});
    }
  }
}

export default FinancialBlockSettings;
