import React from 'react';
import TreeHeader from './TreeHeader';
import { getParentId, getListInFavorite, getListByCategory, getNextNode } from "../../Utils/OwnerShipUtil.js";
import { OwnerShipFetchDetails } from "../../Constants/OwnershipConstants";
import DeleteListItem from "../../Components/NavModules/NavList/Explorer/DeleteListItem";
import BaseServiceApi from 'BaseServiceApi';
import {saveDeleteNode,setDeleteFolder} from '../../Actions/ListViewActions';
const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

const TreeParent = ({
    list,
    // switchList,
    nodeToRename,
    activeNode,
    selectedNode,
    duplicateNode,
    activeFolderID,
    dragNodeID,
    // ownershipTableData,
    // activeNodeChanged,
    handleClick,
    isFolderDelete,
    fontSizeClass,
    showValidationWindow,
    updateScrollBar,
    dispatch
}) => {

    // if (activeNodeChanged) {
    //     ownershipTableData(getParentId(activeNode, list));
    //     dispatch(setActiveNodeChanged(false));
    // }
    const selectedNodeToDelete = isFolderDelete.nodeIDtoDelete ? getParentId(isFolderDelete.nodeIDtoDelete, list) : '';

    const deleteFolder = () => {
        selectedNodeToDelete.isVisible = false;
        const nextnode = getNextNode(false, selectedNodeToDelete.nodeId.low, selectedNodeToDelete, list, activeNode);
        const FavList = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, selectedNodeToDelete.nodeId.low);
        if (FavList) {
            FavList.isVisible = false;
            dispatch(saveDeleteNode(nextnode, FavList.nodeId.low, list));
        }
        dispatch(saveDeleteNode(nextnode, selectedNodeToDelete.nodeId.low, list));
        dispatch(setDeleteFolder({ isShowPopup: false, nodeIDtoDelete: '' }));

    }

    const closeModal = () => {
        dispatch(setDeleteFolder(false));
    }

    return (
        <div>
            <div id="content" className="listExplorerClass" style={{ width: "100%" }}>

                {
                    list && list.length > 0 && list.map((rowslist, row) =>
                        rowslist.isVisible && rowslist.name !== OwnerShipFetchDetails.OWNERSHIP_DB_NAME &&
                        <div key={row}>
                            <TreeHeader
                                list={list}
                                rowslist={rowslist}
                                nodeToRename={nodeToRename}
                                selectedNode={selectedNode}
                                activeNode={activeNode}
                                duplicateNode={duplicateNode}
                                activeFolderID={activeFolderID}
                                dragNodeID={dragNodeID}
                                // ownershipTableData={ownershipTableData}
                                // activeNodeChanged={activeNodeChanged}
                                handleClick={handleClick}
                                fontSizeClass={fontSizeClass}
                                showValidationWindow={showValidationWindow}
                                updateScrollBar={updateScrollBar}
                                dispatch={dispatch}

                            />
                        </div>
                    )
                }
                <img alt="" id="ghostImage" src="/Asset/images/Blank-Canvas.png" style={{ visibility: 'hidden' }} />

                <div id="list-drag-ghost-image"></div>
            </div>
            {
                selectedNodeToDelete && isFolderDelete.isShowPopup &&
                <DeleteListItem
                    showModal={true}
                    closeModal={closeModal}
                    deleteListItem={deleteFolder}
                    nodeId={selectedNodeToDelete.nodeId.low}
                    nodeName={selectedNodeToDelete.nodeName}
                    rootParentId={selectedNodeToDelete.rootParentId}
                    parentId={selectedNodeToDelete.parentId}
                    listType={selectedNodeToDelete.listType}
                />
            }
        </div>
    );
}

export default TreeParent;
