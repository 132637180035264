import AnnotationPreview from './AnnotationPreview';
import AnnotationsCheckboxSelectors from './AnnotationsCheckboxSelectors';
import AnnotationsColorPicker from './AnnotationsColorPicker.jsx';
import AnnotationsOpacitySettings from './AnnotationsOpacitySettings.jsx';
import AnnotationsStrokeSettings from './AnnotationsStrokeSettings.jsx';
import AnnotationsWeightSettings from './AnnotationsWeightSettings.jsx';
import BaseServiceApi from 'BaseServiceApi';
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import PortalContainer from 'PortalContainer';
import ScrollBar from "ScrollBar";
// import FontPicker from "../../../../../../Utils/FontPicker";
import textWidth from "text-width";
import { Button, DropdownButton, MenuItem } from "react-bootstrap";
import { handleTextEffectsChange, updateTextAnnotation } from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent } from 'react';
import { TextAlignmentType, RayAnnotationType, fontStyles } from '../../../../../../Constants/EAnnotationType';

const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
class RIPanelTextInfo extends PureComponent {

    constructor() {
        super();
        this.state = {
            tooltipText: "",
            isShowTooltip: false,
            isShowFontStyleDropdown: false,
            isShowFontSizeDropdown: false,
            isShowFontAlignmentDropdown: false,
            style: {},
            toolTipStyle: {},
            scrollTop: 0,
            hoveredFontStyleIndex: -1,
            hoveredFontSizeIndex: -1,
            hoveredFontAlignmentIndex: -1,
            itemHeight: 22
        }

        this.fontStyleContainer = React.createRef();
        this.fontSizeContainer = React.createRef();
        this.fontAlignmentContainer = React.createRef();
        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.fontDropDownUpdate = false;
    }

    componentDidUpdate() {
        const top = fontStyles.indexOf(this.props.TextModelInfo.fontStyle);
        const fontScroll = document.getElementById("fontSelectDropdownScroll");
        const scrollPositionVal = top * 22;
        if (fontScroll && !this.fontDropDownUpdate) {
            window.setTimeout(() => { fontScroll.scrollTop = scrollPositionVal; }, 0);
            if (this.state.hoveredFontStyleIndex === -1) {
                this.setState({ ...this.state, hoveredFontStyleIndex: fontStyles.indexOf(this.props.TextModelInfo.fontStyle) })
            }
        }
        if (this.state.hoveredFontSizeIndex === -1) {
            let initialSizeIndex = -1
            switch (this.props.TextModelInfo.fontSize) {
                case 8:
                    initialSizeIndex = 0;
                    break;
                case 10:
                    initialSizeIndex = 1;
                    break;
                case 12:
                    initialSizeIndex = 2;
                    break;
                case 14:
                    initialSizeIndex = 3;
                    break;
                case 16:
                    initialSizeIndex = 4;
                    break;
                case 20:
                    initialSizeIndex = 5;
                    break;
                case 28:
                    initialSizeIndex = 6;
                    break;
                case 40:
                    initialSizeIndex = 7;
                    break;
                default:
                    break;
            }
            this.setState({ ...this.state, hoveredFontSizeIndex: initialSizeIndex })
        }

        if (this.state.hoveredFontAlignmentIndex === -1) {
            let initialAlignmentIndex = -1
            switch (this.props.TextModelInfo.textAlign) {
                case TextAlignmentType.LEFT:
                    initialAlignmentIndex = 0;
                    break;
                case TextAlignmentType.CENTER:
                    initialAlignmentIndex = 1;
                    break;
                case TextAlignmentType.RIGHT:
                    initialAlignmentIndex = 2;
                    break;
                default:
                    break;
            }
            this.setState({ ...this.state, hoveredFontAlignmentIndex: initialAlignmentIndex })
        }
    }
    componentDidMount() {
        document.addEventListener("mousedown", this.onDocumentClick);
        window.addEventListener('resize', this.onResize);
        document.addEventListener("keydown", this.onDocumentKeyPress);
        document.body.classList.add('riPanel');
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onDocumentClick);
        window.removeEventListener('resize', this.onResize);
        document.removeEventListener("keydown", this.onDocumentKeyPress);
        document.body.classList.remove('riPanel');
    }

    onDocumentKeyPress = (e) => {
        const fontScroll = document.getElementById("fontSelectDropdownScroll");
        if (e.keyCode === KeyCodes.UP_ARROW) {
            if (document.activeElement && document.activeElement.id === "temp-input") {
                return;
            }
            e.preventDefault();
            if (this.state.isShowFontStyleDropdown && this.state.hoveredFontStyleIndex > 0) {
                this.setState({ ...this.state, hoveredFontStyleIndex: this.state.hoveredFontStyleIndex - 1 })
                if (fontScroll) {
                    window.setTimeout(() => { fontScroll.scrollTop = (this.state.hoveredFontStyleIndex * this.state.itemHeight) - 2 * this.state.itemHeight; }, 0);
                }
            } else if (this.state.isShowFontSizeDropdown && this.state.hoveredFontSizeIndex > 0) {
                this.setState({ ...this.state, hoveredFontSizeIndex: this.state.hoveredFontSizeIndex - 1 })
            } else if (this.state.isShowFontAlignmentDropdown && this.state.hoveredFontAlignmentIndex > 0) {
                this.setState({ ...this.state, hoveredFontAlignmentIndex: this.state.hoveredFontAlignmentIndex - 1 })
            } else if (document.activeElement && (!document.activeElement.id.includes("annot-dropdown") && !document.activeElement.id.includes("SymbolEntryInput"))) {
                const textPanelScroll = document.getElementById("annotateIntrumentsText");
                if (textPanelScroll) {
                    window.setTimeout(() => { textPanelScroll.scrollTop -= 30; }, 0);
                }
            }
        }
        else if (e.keyCode === KeyCodes.DOWN_ARROW) {

            if (this.state.isShowFontStyleDropdown && this.state.hoveredFontStyleIndex < fontStyles.length - 1) {
                this.setState({ ...this.state, hoveredFontStyleIndex: this.state.hoveredFontStyleIndex + 1 })
                if (fontScroll && this.state.hoveredFontStyleIndex < 3) {
                    window.setTimeout(() => { fontScroll.scrollTop = 0; }, 0);
                }
                else if (fontScroll && this.state.hoveredFontStyleIndex >= 3) {
                    window.setTimeout(() => { fontScroll.scrollTop = (this.state.hoveredFontStyleIndex * this.state.itemHeight) - 2 * this.state.itemHeight; }, 0);
                }
            } else if (this.state.isShowFontSizeDropdown && this.state.hoveredFontSizeIndex < 7) {
                this.setState({ ...this.state, hoveredFontSizeIndex: this.state.hoveredFontSizeIndex + 1 })
            } else if (this.state.isShowFontAlignmentDropdown && this.state.hoveredFontAlignmentIndex < 2) {
                this.setState({ ...this.state, hoveredFontAlignmentIndex: this.state.hoveredFontAlignmentIndex + 1 })
            } else if (document.activeElement && (!document.activeElement.id.includes("annot-dropdown") && !document.activeElement.id.includes("SymbolEntryInput"))) {
                const textPanelScroll = document.getElementById("annotateIntrumentsText");
                if (textPanelScroll) {
                    window.setTimeout(() => { textPanelScroll.scrollTop += 30; }, 0);
                }
            }
        }
        else if (e.keyCode === KeyCodes.ENTER) {

            if (this.state.isShowFontStyleDropdown && this.state.hoveredFontStyleIndex < fontStyles.length - 1) {
                fontStyles.map((item, idx) => {
                    if (idx === this.state.hoveredFontStyleIndex) {
                        this.onTextEffectsChange('fontStyle', `${item}`)
                    }
                })
                this.toggleFontStyleDropdown();
            } else if (this.state.isShowFontSizeDropdown && this.state.hoveredFontSizeIndex > -1) {
                switch (this.state.hoveredFontSizeIndex) {
                    case 0:
                        this.onTextEffectsChange('fontSize', 8)
                        break;
                    case 1:
                        this.onTextEffectsChange('fontSize', 10)
                        break;
                    case 2:
                        this.onTextEffectsChange('fontSize', 12)
                        break;
                    case 3:
                        this.onTextEffectsChange('fontSize', 14)
                        break;
                    case 4:
                        this.onTextEffectsChange('fontSize', 16)
                        break;
                    case 5:
                        this.onTextEffectsChange('fontSize', 20)
                        break;
                    case 6:
                        this.onTextEffectsChange('fontSize', 28)
                        break;
                    case 7:
                        this.onTextEffectsChange('fontSize', 40)
                        break;
                    default:
                        break;
                }
                this.toggleFontSizeDropdown();
            } else if (this.state.isShowFontAlignmentDropdown && this.state.hoveredFontAlignmentIndex > -1) {
                switch (this.state.hoveredFontAlignmentIndex) {
                    case 0:
                        this.onTextEffectsChange('align', TextAlignmentType.LEFT);
                        break;
                    case 1:
                        this.onTextEffectsChange('align', TextAlignmentType.CENTER)
                        break;
                    case 2:
                        this.onTextEffectsChange('align', TextAlignmentType.RIGHT)
                        break;
                    default:
                        break;
                }
                this.toggleFontAlignmentDropdown();
            }
        }
    }

    onDocumentClick = (event) => {
        if (
            (this.fontStyleContainer.current &&
                !this.fontStyleContainer.current.contains(event.target))
        ) {
            this.setState({
                isShowFontStyleDropdown: false,
                hoveredFontStyleIndex: -1,
            });
        }

        if (
            (this.fontSizeContainer.current &&
                !this.fontSizeContainer.current.contains(event.target))
        ) {
            this.setState({
                isShowFontSizeDropdown: false,
                hoveredFontSizeIndex: -1
            });
        }

        if (
            (this.fontAlignmentContainer.current &&
                !this.fontAlignmentContainer.current.contains(event.target))
        ) {
            this.setState({
                isShowFontAlignmentDropdown: false,
                hoveredFontAlignmentIndex: -1
            });
        }
    }

    onResize = () => {
        this.setState({
            isShowFontStyleDropdown: false,
            isShowFontSizeDropdown: false,
            isShowFontAlignmentDropdown: false,
            hoveredFontStyleIndex: -1,
            hoveredFontSizeIndex: -1,
            hoveredFontAlignmentIndex: -1
        });
    }


    toggleFontStyleDropdown = () => {
        const rect = this.fontStyleDropDownButton.getBoundingClientRect();
        const style = { position: 'absolute', left: rect.left, top: rect.bottom };
        this.fontDropDownUpdate = this.state.isShowFontStyleDropdown;
        this.setState({ isShowFontStyleDropdown: !this.state.isShowFontStyleDropdown, style: style });
    }

    toggleFontSizeDropdown = () => {
        const rect = this.fontSizeDropDownButton.getBoundingClientRect();
        const style = { position: 'absolute', left: rect.left, top: rect.bottom };
        this.setState({ isShowFontSizeDropdown: !this.state.isShowFontSizeDropdown, style: style });
    }

    toggleFontAlignmentDropdown = () => {
        const rect = this.fontAlignmentDropDownButton.getBoundingClientRect();
        const style = { position: 'absolute', left: rect.left, top: rect.bottom };
        this.setState({ isShowFontAlignmentDropdown: !this.state.isShowFontAlignmentDropdown, style: style });
    }

    hideDropDownMenu = (e) => {
        if (e.target.id === "fontSelectDropdownScroll" || (e.target.parentElement && e.target.parentElement.className === "ssb_v")) {
            return;
        }
        if (this.state.isShowFontStyleDropdown || this.state.isShowFontSizeDropdown || this.state.isShowFontAlignmentDropdown) {
            this.setState({ isShowFontStyleDropdown: false, isShowFontSizeDropdown: false, isShowFontAlignmentDropdown: false, style: {} });
        }

        this.colorPicker_text && this.colorPicker_text.hideDropDownMenu();
        this.colorPicker_bg && this.colorPicker_bg.hideDropDownMenu();
        this.colorPicker_border && this.colorPicker_border.hideDropDownMenu();
        this.weightDropDown && this.weightDropDown.hideDropDownMenu();
        this.strokeDropDown && this.strokeDropDown.hideDropDownMenu();
    }

    onTextEffectsChange = (event, value = null) => {
        this.props.handleTextEffectsChange(event, value);
        if (this.props.annotSelected && this.props.annotSelected.type === RayAnnotationType.TEXT_AnnotationType) {
            this.props.updateTextAnnotation(event, value);
        }
        this.setState({ ...this.state, hoveredFontStyleIndex: - 1, hoveredFontSizeIndex: - 1, hoveredFontAlignmentIndex: - 1, isShowFontStyleDropdown: false, isShowFontSizeDropdown: false, isShowFontAlignmentDropdown: false })
    }
    showTooltip = (e) => {
        const width = textWidth(e.target.innerText, {
            family: "calibri",
            size: 14
        });
        let xPoint = e.clientX;
        if (xPoint + width > window.innerWidth) {
            xPoint = window.innerWidth - (width + 20);
        }
        const toolTipStyle = { left: xPoint, top: e.clientY + 10 };
        this.fontDropDownUpdate = true;
        this.setState({ ...this.state, toolTipStyle: toolTipStyle, isShowTooltip: true, tooltipText: `${e.target.innerText}` });
    }

    hideTooltip = () => {
        this.setState({ ...this.state, isShowTooltip: false, toolTipStyle: {}, tooltipText: "" });
    }
    render() {

        const fontStyle = this.props.TextModelInfo.fontStyle;
        const fontSize = this.props.TextModelInfo.fontSize;
        const textAlign = this.props.TextModelInfo.textAlign;
        const isItalic = this.props.TextModelInfo.isItalic;
        const isBold = this.props.TextModelInfo.isBold;
        const isUnderline = this.props.TextModelInfo.isUnderline;

        return (
            <div className="annotation-box annotate-text">
                <div className="annotation-hold custom-scroll" onScroll={this.hideDropDownMenu} onClickCapture={this.hideDropDownMenu}>
                    <div className="annotation-overlape" style={{ display: ((!this.props.annotSelected && this.props.selectedLayer && this.props.selectedLayer.shareAccess === ShareAccessType.SHARE_READONLY) || (this.props.annotSelected && this.props.annotSelected.isReadOnlyAnnotation)) ? '' : 'none' }}></div>
                    <div className="annotation-intruments" id="annotateIntrumentsText">

                        <AnnotationPreview label={`TEXT SETTINGS`} />

                        <div className="annotation-properties">
                            <div className="swatches-format">
                                <div className="col-xs-9">
                                    <div className="swatches-field" ref={(r) => { this.fontStyleDropDownButton = r; }}>
                                        <DropdownButton noCaret id="annot-dropdown-fontStyle" title={<span className="selected-font">{fontStyle}</span>} onClick={this.toggleFontStyleDropdown}>
                                            {this.state.isShowFontStyleDropdown &&
                                                <PortalContainer>
                                                    <div className="custom-scroll portal-dropdown font-dropdown" style={this.state.isShowFontStyleDropdown ? this.state.style : {}} ref={this.fontStyleContainer}>
                                                        <ul id="fontSelectDropdownScroll">
                                                            {fontStyles.map((item, idx) => <MenuItem key={`${item}`} className={(idx === this.state.hoveredFontStyleIndex && fontStyle === `${item}`) ? 'selectHover' : idx === this.state.hoveredFontStyleIndex ? 'key-press-hover' : fontStyle === `${item}` ? 'selective' : ''} style={{ fontFamily: `${item}` }} onSelect={() => this.onTextEffectsChange('fontStyle', `${item}`)}
                                                                onMouseEnter={(e) => { this.showTooltip(e); this.setState({ ...this.state, hoveredFontStyleIndex: idx }) }} onMouseOut={this.hideTooltip} onBlur={this.hideTooltip}>
                                                                <u className="icn-select"></u>
                                                                {`${item}`}
                                                                {this.state.isShowTooltip && <PortalContainer>
                                                                    <div className="annotation-font-tooltip" style={this.state.toolTipStyle}>{`${this.state.tooltipText}`}</div>
                                                                </PortalContainer>}
                                                            </MenuItem>
                                                            )}
                                                        </ul>
                                                        <ScrollBar scrollId="fontSelectDropdownScroll" vScroll={true} scrollOnHover={true} />
                                                    </div>
                                                </PortalContainer>}
                                        </DropdownButton>
                                    </div>
                                </div>
                                <div className="col-xs-3">
                                    <div className="swatches-field font-size" ref={(r) => { this.fontSizeDropDownButton = r; }}>
                                        <DropdownButton noCaret id="annot-dropdown-fontSize" title={fontSize} onClick={this.toggleFontSizeDropdown}>
                                            {this.state.isShowFontSizeDropdown &&
                                                <PortalContainer>
                                                    <ul className="portal-dropdown font-size-dropdown" style={this.state.isShowFontSizeDropdown ? this.state.style : {}} ref={this.fontSizeContainer}>
                                                        <MenuItem key={1} className={(this.state.hoveredFontSizeIndex === 0 && fontSize === 8) ? 'selectHover' : this.state.hoveredFontSizeIndex === 0 ? 'key-press-hover' : fontSize === 8 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontSizeIndex: 0 })} onSelect={() => this.onTextEffectsChange('fontSize', 8)} ><u className="icn-select"></u><b>8</b></MenuItem>
                                                        <MenuItem key={2} className={(this.state.hoveredFontSizeIndex === 1 && fontSize === 10) ? 'selectHover' : this.state.hoveredFontSizeIndex === 1 ? 'key-press-hover' : fontSize === 10 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontSizeIndex: 1 })} onSelect={() => this.onTextEffectsChange('fontSize', 10)} ><u className="icn-select"></u><b>10</b></MenuItem>
                                                        <MenuItem key={3} className={(this.state.hoveredFontSizeIndex === 2 && fontSize === 12) ? 'selectHover' : this.state.hoveredFontSizeIndex === 2 ? 'key-press-hover' : fontSize === 12 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontSizeIndex: 2 })} onSelect={() => this.onTextEffectsChange('fontSize', 12)} ><u className="icn-select"></u><b>12</b></MenuItem>
                                                        <MenuItem key={4} className={(this.state.hoveredFontSizeIndex === 3 && fontSize === 14) ? 'selectHover' : this.state.hoveredFontSizeIndex === 3 ? 'key-press-hover' : fontSize === 14 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontSizeIndex: 3 })} onSelect={() => this.onTextEffectsChange('fontSize', 14)} ><u className="icn-select"></u><b>14</b></MenuItem>
                                                        <MenuItem key={5} className={(this.state.hoveredFontSizeIndex === 4 && fontSize === 16) ? 'selectHover' : this.state.hoveredFontSizeIndex === 4 ? 'key-press-hover' : fontSize === 16 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontSizeIndex: 4 })} onSelect={() => this.onTextEffectsChange('fontSize', 16)} ><u className="icn-select"></u><b>16</b></MenuItem>
                                                        <MenuItem key={6} className={(this.state.hoveredFontSizeIndex === 5 && fontSize === 20) ? 'selectHover' : this.state.hoveredFontSizeIndex === 5 ? 'key-press-hover' : fontSize === 20 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontSizeIndex: 5 })} onSelect={() => this.onTextEffectsChange('fontSize', 20)} ><u className="icn-select"></u><b>20</b></MenuItem>
                                                        <MenuItem key={7} className={(this.state.hoveredFontSizeIndex === 6 && fontSize === 28) ? 'selectHover' : this.state.hoveredFontSizeIndex === 6 ? 'key-press-hover' : fontSize === 28 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontSizeIndex: 6 })} onSelect={() => this.onTextEffectsChange('fontSize', 28)} ><u className="icn-select"></u><b>28</b></MenuItem>
                                                        <MenuItem key={8} className={(this.state.hoveredFontSizeIndex === 7 && fontSize === 40) ? 'selectHover' : this.state.hoveredFontSizeIndex === 7 ? 'key-press-hover' : fontSize === 40 ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontSizeIndex: 7 })} onSelect={() => this.onTextEffectsChange('fontSize', 40)} ><u className="icn-select"></u><b>40</b></MenuItem>
                                                    </ul>
                                                </PortalContainer>}
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                            <div className="swatches-format">
                                <div className="col-xs-3">
                                    <AnnotationsColorPicker type="text" ref={(r) => { this.colorPicker_text = r; }} />
                                </div>
                                <div className="col-xs-6">
                                    <div className="swatches-field btn-sequence">
                                        <Button className={isBold ? "selective" : ""} onClick={() => this.onTextEffectsChange('bold')}><acronym className="icn-text-bold"></acronym></Button>
                                        <Button className={isItalic ? "selective" : ""} onClick={() => this.onTextEffectsChange('italic')}><acronym className="icn-text-italic"></acronym></Button>
                                        <Button className={isUnderline ? "selective" : ""} onClick={() => this.onTextEffectsChange('underline')}><acronym className="icn-text-underline"></acronym></Button>
                                    </div>
                                </div>
                                <div className="col-xs-3">
                                    <div className="swatches-field font-align" ref={(r) => { this.fontAlignmentDropDownButton = r; }}>
                                        {/* textAlign === TextAlignmentType.LEFT ? 'left' : textAlign === TextAlignmentType.RIGHT ? 'right' : 'center' */}
                                        <DropdownButton className="" noCaret id="annot-dropdown-fontAlign" title={<span className={textAlign === TextAlignmentType.LEFT ? 'selected-text lefter' : textAlign === TextAlignmentType.RIGHT ? 'selected-text righter' : 'selected-text center'}></span>} onClick={this.toggleFontAlignmentDropdown}>
                                            {this.state.isShowFontAlignmentDropdown &&
                                                <PortalContainer>
                                                    <ul className="portal-dropdown font-alignment-dropdown" style={this.state.isShowFontAlignmentDropdown ? this.state.style : {}} ref={this.fontAlignmentContainer}>
                                                        <MenuItem key={TextAlignmentType.LEFT} className={(this.state.hoveredFontAlignmentIndex === 0 && textAlign === TextAlignmentType.LEFT) ? 'selectHover' : this.state.hoveredFontAlignmentIndex === 0 ? 'key-press-hover' : textAlign === TextAlignmentType.LEFT ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontAlignmentIndex: 0 })} onSelect={() => this.onTextEffectsChange('align', TextAlignmentType.LEFT)} ><u className="icn-select"></u><i className="icn-align-left"></i><b>left</b></MenuItem>
                                                        <MenuItem key={TextAlignmentType.CENTER} className={(this.state.hoveredFontAlignmentIndex === 1 && textAlign === TextAlignmentType.CENTER) ? 'selectHover' : this.state.hoveredFontAlignmentIndex === 1 ? 'key-press-hover' : textAlign === TextAlignmentType.CENTER ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontAlignmentIndex: 1 })} onSelect={() => this.onTextEffectsChange('align', TextAlignmentType.CENTER)} ><u className="icn-select"></u><i className="icn-align-center"></i><b>center</b></MenuItem>
                                                        <MenuItem key={TextAlignmentType.RIGHT} className={(this.state.hoveredFontAlignmentIndex === 2 && textAlign === TextAlignmentType.RIGHT) ? 'selectHover' : this.state.hoveredFontAlignmentIndex === 2 ? 'key-press-hover' : textAlign === TextAlignmentType.RIGHT ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredFontAlignmentIndex: 2 })} onSelect={() => this.onTextEffectsChange('align', TextAlignmentType.RIGHT)} ><u className="icn-select"></u><i className="icn-align-right"></i><b>right</b></MenuItem>
                                                    </ul>
                                                </PortalContainer>}
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="annotation-properties">
                            <div className="title"><h4>BACKGROUND</h4></div>
                            <div className="swatches-range-slider">
                                <div className="col-xs-3">
                                    <div className="swatches-field color">
                                        <AnnotationsColorPicker type="background" ref={(r) => { this.colorPicker_bg = r; }} />
                                    </div>
                                </div>
                                <div className="col-xs-9">
                                    <AnnotationsOpacitySettings />
                                </div>
                            </div>
                        </div>

                        <div className="annotation-properties">
                            <div className="title">
                                <ul>
                                    <AnnotationsCheckboxSelectors type="Border" />
                                </ul>
                            </div>
                            <div className="swatches-dropdown">
                                <div className="col-xs-4"><AnnotationsColorPicker type="border" ref={(r) => { this.colorPicker_border = r; }} /></div>
                                <div className="col-xs-4"><AnnotationsWeightSettings type="border" ref={(r) => { this.weightDropDown = r; }} /></div>
                                <div className="col-xs-4"><AnnotationsStrokeSettings type="border" ref={(r) => { this.strokeDropDown = r; }} /></div>
                            </div>
                        </div>

                    </div>
                    <ScrollBar scrollId="annotateIntrumentsText" vScroll={true} />
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ annotationReducers }) => ({
    TextModelInfo: annotationReducers.annotationMenuReducer.TextModelInfo,
    selectedLayer: annotationReducers.annotationLayerManagementReducer.selectedLayer,
    annotSelected: annotationReducers.annotationChartModelReducer.annotSelected,
    riPanelToggle: annotationReducers.annotationMenuReducer.riPanelToggle
})

const mapDispatchToProps = (dispatch) => ({
    handleTextEffectsChange: (event, value) => dispatch(handleTextEffectsChange(event, value)),
    updateTextAnnotation: (event, value) => updateTextAnnotation(event, value)
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelTextInfo);
