import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetTechIndicatorRequest extends BaseServiceRequest {
    constructor(request) {
        super();
        const { symbol, endDate, graphType, sratings, cratings, eratings, cytd, eytd, fytd, iytd, wytd, selectCurrencyCode } = request;
        super.addRequestUri("gettechind.pb");
        super.addRequestParameter("sym", symbol);

        if (endDate !== null)
            super.addRequestParameter("enddate", endDate);

        super.addRequestParameter("gtype", graphType);
        super.addRequestParameter("sratings", sratings);
        super.addRequestParameter("cratings", cratings);
        super.addRequestParameter("eratings", eratings);
        super.addRequestParameter("cytd", cytd);
        super.addRequestParameter("eytd", eytd);
        super.addRequestParameter("fytd", fytd);
        super.addRequestParameter("iytd", iytd);
        super.addRequestParameter("wytd", wytd);
        if(selectCurrencyCode !== null)
            super.addRequestParameter("scc", selectCurrencyCode)
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "GetTechIndResponse";
    }
}
export default GetTechIndicatorRequest;