import { AlertConstants } from "AlertConstants";
import AlertStore from "../../../../Stores/Alerts/AlertStore.js";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import ErrorBoundary from "ErrorBoundary";
import FundamentalsActions from "Actions/FundamentalsActions.js";
import { FundamentalsConstants } from "Constants/FundamentalsConstants.js";
import FundamentalsPeriodicityControl from "Components/NavModules/NavDataGraph/TabFundamental/FundamentalsTable/FundamentalsPeriodicityControl.jsx";
import FundamentalTabFilterType from 'Constants/FundamentalTabFilterType.js';
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js';
import PeriodicityControl from "./PeriodicityControl.jsx";
import React from "react";
import ReactDOM from "react-dom";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import { TabDataGraphConstants } from "Constants/TabDataGraphConstants.js"
import TabDataGraphStore from "../../../../Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore.js";
import TabFundamentalsStore from "../../../../Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js";
import TabType from "Constants/TabType.js";

export default class BottomBar extends React.Component {
  constructor(props) {
    super(props);
    this.TabDataGraphStoreStateChange = this.TabDataGraphStoreStateChange.bind(this);
    this.DataGraphStoreStateChange = this.DataGraphStoreStateChange.bind(this);
    this.TabFundamentalsStoreChange = this.TabFundamentalsStoreChange.bind(this);
    this.handleFundamentalsPeriodicityChange = this.handleFundamentalsPeriodicityChange.bind(this);
    this.state = TabDataGraphStore.getState();
    this.state = { ...this.state, changedFundamentalPeriodicity: false };
    //this.state["changedFundamentalPeriodicity"] = false;
  }

  componentDidMount() {
    TabDataGraphStore.addChangeListener(this.TabDataGraphStoreStateChange);
    DatagraphStore.addChangeListener(this.DataGraphStoreStateChange);
    TabFundamentalsStore.addChangeListener(this.TabFundamentalsStoreChange);
    AlertStore.addChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.SET_MESSAGE_AS_READ);
  }

  componentWillUnmount() {
    TabDataGraphStore.removeChangeListener(this.TabDataGraphStoreStateChange);
    DatagraphStore.removeChangeListener(this.DataGraphStoreStateChange);
    TabFundamentalsStore.removeChangeListener(this.TabFundamentalsStoreChange);
    AlertStore.removeChangeListener(this.AlertStoreStateChange, AlertConstants.ActionTypes.SET_MESSAGE_AS_READ);
    clearTimeout(this.timeout);
  }

  DataGraphStoreStateChange() {
    const currentAction = DatagraphStore.getCurrentAction();
    if (currentAction === SettingsConstants.ActionTypes.DATAGRAPH_UPDATE_TAB) {
      this.setState({});
    }
  }

  TabDataGraphStoreStateChange() {
    if (TabDataGraphStore.getCurrentAction() === TabDataGraphConstants.ActionTypes.UPDATE_SYMBOL_INPUT_STATE) {
      return;
    }

    var state = TabDataGraphStore.getState();
    this.setState(state);
  }

  AlertStoreStateChange = () => {
    var state = TabDataGraphStore.getState();
    this.setState(state);
  }

  handleFundamentalsPeriodicityChange(index, e) {
    let settings = TabFundamentalsStore.getState();
    let periodicity = ReactDOM.findDOMNode(e.currentTarget).getAttribute('data-report-periodicityid');
    FundamentalsActions.setFundamentalsPeriodicity(periodicity);
    if (settings.FundamentalFilter == FundamentalTabFilterType.All && settings.FundamentalReportType != FundamentalTabReportType.Ratios) { FundamentalsActions.getAllFilterReportData(settings); }
    else { FundamentalsActions.getReportData(settings); }
  }

  TabFundamentalsStoreChange() {
    let currentAction = TabFundamentalsStore.getCurrentAction();
    if (currentAction == FundamentalsConstants.ActionTypes.SET_FUNDAMENTALS_PERIODICITY) {
      TabFundamentalsStore.clearCurrentAction();
      this.setState({ changedFundamentalPeriodicity: !this.state.changedFundamentalPeriodicity });
    }
  }

  getPeriodicityControl(state) {
    let periodicityControl = undefined;
    const fundamentalState = TabFundamentalsStore.getState();
    switch (state.SelectedTabKey) {
      case TabType.Datagraph:
        periodicityControl = <ErrorBoundary> <PeriodicityControl /> </ErrorBoundary>;
        break;
      case TabType.Fundamental:
        periodicityControl = <ErrorBoundary><FundamentalsPeriodicityControl handlePeriodicityChange={this.handleFundamentalsPeriodicityChange} settings={fundamentalState} /></ErrorBoundary>;
        break;
      default:
    }
    return periodicityControl;
  }

  render() {
    const state = DatagraphStore.getState();
    const periodicityControl = this.getPeriodicityControl(state);
    
    return (
      <div className="actionButtonWrap">
        {periodicityControl}
      </div>);
  }
}