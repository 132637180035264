import AnnotationUtil from "../../../Utils/AnnotationUtil.js";
import DateHelper from "../../../Utils/TimeLineHelper/Datehelper.js";
import DayOfWeek from "DayOfWeek";
import FinancialBlockDisplayMode from "../../../Constants/FinanicalBlockDisplayMode.js";
import { getDatagraphStates } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors.js";
import GraphType from "../../../Constants/GraphType.js";
import { initTimeLineState } from "../../../Actions/TabDataGraphAction.js";
import LocalizationStore from "LocalizationStore";
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../Constants/SmartViewType.js";
import SymbolType from "SymbolType";
import { TimelineConstant } from "../../../Constants/TabDataGraphConstants.js";
import { put, select, takeLatest } from "redux-saga/effects";

const { ActionTypes } = TimelineConstant;

const Factor = {
    Intraday60Min: 60,
    Intraday30Min: 30,
    Intraday15Min: 15,
    Intraday10Min: 10,
    Intraday5Min: 5,
    Intraday1Min: 1,
    Annual: 360,
    Quarterly: 90,
    Monthly: 30,
    Weekly: 7,
    Daily: 1,
}
const dottedGrid = [];
const solidGrid = [];
const eighteenMnth = [];
let padding = true;
const calQtrs = [3, 6, 9, 12];
const f1Qtrs = [1, 4, 7, 10];
const f2Qtrs = [2, 5, 8, 11];
const monthNames = [" ", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const IsFiscal1LabelConst = {
    IsFiscalLabel1: true,
    IsFiscalLabel2: true,
    IsCalendarLabel: true,
    IsCalendarLine: true,
    IsMonthLabel: false
}
const setMCVerticalGridConst = {
    [GraphType.Daily]: {
        ...IsFiscal1LabelConst,
        padDateValue: 4,
        xLine: 3,
        daysTOForward: 1
    },
    [GraphType.Weekly]: {
        padDateValue: 28,
        IsCalendarLabel: true,
        xLine: 3,
        daysTOForward: 7
    },
    [GraphType.Monthly]: {
        ...IsFiscal1LabelConst,
        padDateValue: 120,
        isMultipleOf: 5,
        xLine: 1,
        daysTOForward: 0
    },
    [GraphType.Quarterly]: {
        ...IsFiscal1LabelConst,
        padDateValue: 365,
        isMultipleOf: 15,
        xLine: 3,
        daysTOForward: 0
    },
    [GraphType.Annual]: {
        ...IsFiscal1LabelConst,
        padDateValue: 1460,
        isMultipleOf: 50,
        xLine: 3,
        daysTOForward: 0
    },
}

const setVerticalGridConst = {
    [GraphType.Daily]: {
        ...IsFiscal1LabelConst,
        xLine: 6,
        daysTOForward: 1
    },
    [GraphType.Weekly]: {
        IsCalendarLabel: true,
        xLine: 3,
        daysTOForward: 7
    },
    [GraphType.Monthly]: {
        ...IsFiscal1LabelConst,
        daysTOForward: 0,
        isMultipleOf: 1,
        xLine: 1,
        cMonthConst: 12
    },
    [GraphType.Quarterly]: {
        ...IsFiscal1LabelConst,
        daysTOForward: 0,
        isMultipleOf: 10,
        xLine: 2,
        cMonthConst: 3
    },
    [GraphType.Annual]: {
        ...IsFiscal1LabelConst,
        daysTOForward: 0,
        isMultipleOf: 10,
        xLine: 3,
        cMonthConst: 12
    },
}
//==================================================================
function GetMDate(cDate, periodicity) {
    if (periodicity === GraphType.Daily || periodicity === GraphType.Weekly) {
        return new Date(cDate);
    }
    cDate = new Date(cDate.getFullYear(), cDate.getMonth() + 1, 0);
    if (cDate.getDay() === 6) {
        cDate.setDate(cDate.getDate() - 1);
    }
    if (cDate.getDay() === 0) {
        cDate.setDate(cDate.getDate() - 2);
    }
    return cDate;
}

function Padding(quarters, xDate, endDate, periodicity) {
    let curDate = GetMDate(xDate, periodicity);
    const endIndex = periodicity === GraphType.Monthly && quarters !== 0 ? 3 : 4;
    const dayToFriday = periodicity === GraphType.Daily ? -2 : 1;
    const dayToForward = periodicity === GraphType.Weekly ? 7 : 1;
    if (quarters !== 0) {
        if (periodicity === GraphType.Annual) {
            curDate = GetMDate(xDate)
        }
        curDate = new Date(new Date(curDate).setMonth(curDate.getMonth() + (3 * quarters)));
        if (endDate > curDate) {
            curDate = new Date(endDate);
        }
        if (periodicity === GraphType.Weekly) {
            if (curDate.getTime() < endDate.getTime()) {
                curDate.setDate(endDate.getDate());
            }
            if (curDate.getDay() === DayOfWeek.Saturday) {
                curDate.setDate(curDate.getDate() - 1);
            }
            if (curDate.getDay() === DayOfWeek.Sunday) {
                curDate.setDate(curDate.getDate() - 2);
            }

            curDate.setDate(curDate.getDate() + dayToForward);
        }
    }
    if ((quarters === 0 && padding) || quarters !== 0 && periodicity !== GraphType.Annual) {
        if (periodicity === GraphType.Weekly) {
            const weekday = curDate.getDay();
            const dayAdvance = weekday < 5 ? (5 - weekday) - 7 : 0;
            curDate.setDate(curDate.getDate() + dayAdvance);
        }
        for (let i = 0; i < endIndex; i++) {
            if (periodicity === GraphType.Daily || periodicity === GraphType.Weekly) {
                curDate.setDate(curDate.getDate() + dayToForward);
            }
            else {
                curDate.setMonth(curDate.getMonth() + dayToForward);
            }
            if (curDate.getDay() === DayOfWeek.Saturday) {
                curDate.setDate((curDate.getDate() - dayToFriday));
            }
            if (curDate.getDay() === DayOfWeek.Sunday) {
                curDate.setDate((curDate.getDate() - (dayToFriday + 1)));
            }
        }
    }
    if (quarters !== 0) {
        curDate = GetMDate(curDate, periodicity);
    }
    return curDate;
}

function Qtr(month) {
    return Math.floor((month - 1) / 3.0);
}

function SetMCVerticalGrid(startTime, endDate, mtDates, EarnReportDate, FCQtrEstCount, periodicity) {
    let x = 0;
    let quarters = 8;
    let padDate = new Date(endDate);
    const MCVGridConst = setMCVerticalGridConst[periodicity];

    if (quarters > 0 && FCQtrEstCount > 0 && EarnReportDate.getFullYear() > 1900) {
        padDate = new Date(EarnReportDate);
    }
    else {
        quarters = 0;
    }
    let pDate = new Date(Padding(quarters, padDate, endDate, null, periodicity));
    pDate.setDate(pDate.getDate() + MCVGridConst.padDateValue);
    let cDate = new Date(pDate);

    while (cDate >= startTime) {
        const info =
        {
            Date: cDate,
            XAxis: x,
            XLine: 0,
            XLabel: "",
            IsFiscalLabel1: false,
            IsFiscalLabel2: false,
            IsCalendarLabel: false
        };
        mtDates[x] = info;
        let cMonth = cDate.getMonth();
        const pMonth = pDate.getMonth()
        const year = cDate.getFullYear();
        if (periodicity === GraphType.Weekly || periodicity === GraphType.Daily) {
            if (periodicity === GraphType.Weekly) {
                if (pMonth !== cMonth) {
                    if (pDate.getFullYear() !== cDate.getFullYear()) {
                        mtDates[x].XLabel = cDate.getFullYear();
                        mtDates[x].XLine = MCVGridConst.xLine;
                        mtDates[x].IsCalendarLabel = MCVGridConst.IsCalendarLabel;
                    }
                }
            } else {
                if (pMonth !== cMonth) {
                    cMonth += 1;
                    let yDateStr = monthNames[cMonth];

                    if (pDate.getFullYear() !== cDate.getFullYear()) {
                        yDateStr = `${monthNames[cMonth]} ${year}`;
                    }

                    mtDates[x].XLabel = yDateStr;

                    if (calQtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].XLine = MCVGridConst.xLine;
                        mtDates[x].IsMonthLabel = MCVGridConst.IsMonthLabel;
                        mtDates[x].IsCalendarLabel = MCVGridConst.IsCalendarLabel;
                    }
                    if (f1Qtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].IsFiscal1Label = MCVGridConst.IsFiscalLabel1;
                    }
                    if (f2Qtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].IsFiscal2Label = MCVGridConst.IsFiscalLabel2;
                    }

                    pDate = cDate;
                }
            }
            cDate = new Date(year, cMonth, cDate.getDate() - MCVGridConst.daysTOForward);
        }
        else {
            if (pDate.getFullYear() !== year) {
                if (year % MCVGridConst.isMultipleOf === 0) {
                    mtDates[x].XLabel = cMonth + 1 === 12 ? year : "";
                    mtDates[x].IsFiscalLabel1 = MCVGridConst.IsFiscalLabel1;
                    mtDates[x].IsFiscalLabel2 = MCVGridConst.IsFiscalLabel2;
                    mtDates[x].XLine = MCVGridConst.xLine;
                    mtDates[x].IsCalendarLabel = MCVGridConst.IsCalendarLabel;
                    mtDates[x].IsCalendarLine = MCVGridConst.IsCalendarLine;
                    mtDates[x].IsMonthLabel = MCVGridConst.IsMonthLabel;
                }
                pDate = cDate;
            }
            if (periodicity === GraphType.Quarterly) {
                const aRem = cMonth % 3;
                cMonth = aRem !== 0 ? cMonth - aRem : cMonth - 3;
            }
            else if (periodicity === GraphType.Annual) {
                cMonth = 0;
            }

            cDate = new Date(year, cMonth, MCVGridConst.daysTOForward);
        }
        x++;
        if (periodicity !== GraphType.Weekly) {
            if (cDate.getDay() === DayOfWeek.Saturday) {
                cDate.setDate(cDate.getDate() - 1);
            }
            if (cDate.getDay() === DayOfWeek.Sunday) {
                cDate.setDate(cDate.getDate() - 2);
            }
        }
    }
}
function SetVerticalGrid(startTime, endDate, timeLineData, EarnReportDate, quarters = 8, funds = false, videoMode = false, periodicity) {
    const mtDates = timeLineData.dates;
    let x = 0;
    const MCVGridConst = setMCVerticalGridConst[periodicity];
    const extraPadding = periodicity === GraphType.Weekly || periodicity === GraphType.Annual ? 1 : 0;
    let padDate = new Date(endDate);
    const setVGridConst = setVerticalGridConst[periodicity];
    if (quarters > 0 && EarnReportDate.getFullYear() > 1900) {
        padDate = new Date(EarnReportDate);
    }
    else {
        quarters = 0;
    }
    let pDate = new Date(Padding(quarters, padDate, endDate, periodicity));
    pDate.setDate(pDate.getDate() + MCVGridConst.padDateValue);
    let cDate = new Date(pDate);
    let qtrs = 0;
    if (periodicity === GraphType.Weekly || periodicity === GraphType.Daily) {
        timeLineData.FirstCalLabelIndex = timeLineData.FirstLabelIndex;
        timeLineData.Firstf1LabelIndex = timeLineData.FirstLabelIndex;
        timeLineData.Firstf2LabelIndex = timeLineData.FirstLabelIndex;
    }
    while (cDate >= startTime) {
        const xDate = new Date(cDate);

        const info =
        {
            Date: xDate,
            XAxis: x + extraPadding,
            XLine: 0,
            XLabel: "",
            IsFiscalLabel1: false,
            IsFiscalLabel2: false,
            IsCalendarLabel: false
        };
        mtDates[x] = info;

        let cMonth = periodicity !== GraphType.Monthly ? cDate.getMonth() + 1 : cDate.getMonth();
        let pMonth = pDate.getMonth()
        const year = cDate.getFullYear();
        if (periodicity === GraphType.Weekly || periodicity === GraphType.Daily) {
            pMonth += 1;
            if (periodicity === GraphType.Weekly) {
                if (pMonth !== cMonth) {
                    const yrD = year % 100;
                    const uMonth = videoMode === true ? pMonth : cMonth;
                    const yDateStr = LocalizationStore.getTranslatedData("sb_date", "{0} {1}", LocalizationStore.getTranslatedData(`cal_Num_${monthNames[uMonth]}`, monthNames[uMonth]), (yrD < 10 ? `0${yrD}` : yrD));
                    if (calQtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].XLabel = yDateStr;
                        if (timeLineData.FirstCalLabelIndex === -1) {
                            timeLineData.FirstCalLabelIndex = x;
                        }
                        if (timeLineData.FirstLabelIndex === -1) {
                            timeLineData.FirstLabelIndex = x
                        }
                        mtDates[x].IsCalendarLabel = true;
                        mtDates[x].XLine = 1;
                        if (cDate < endDate) {
                            qtrs++;
                        }
                        timeLineData.TimeLineLabel.push(mtDates[x])
                        dottedGrid.push(mtDates[x]);
                    }
                    if (f1Qtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].XLabel = yDateStr;
                        mtDates[x].IsFiscalLabel1 = true;
                        if (timeLineData.Firstf1LabelIndex === -1) {
                            timeLineData.Firstf1LabelIndex = x;
                        }
                        timeLineData.TimeLineLabel.push(mtDates[x])
                    }
                    if (f2Qtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].XLabel = yDateStr;
                        mtDates[x].IsFiscalLabel2 = true;
                        if (timeLineData.Firstf2LabelIndex === -1) {
                            timeLineData.Firstf2LabelIndex = x;
                        }
                        timeLineData.TimeLineLabel.push(mtDates[x])
                    }
                    // Month seperators in first 18 months (6 quarters)
                    if (qtrs < 7 && !funds) {
                        mtDates[x].XLine = 3;
                        mtDates[x].IsCalendarLine = true;
                        dottedGrid.push(mtDates[x]);

                    }

                    if (pDate.getFullYear() !== cDate.getFullYear()) {
                        mtDates[x].XLine = 2;
                        mtDates[x].IsCalendarLine = true;
                        solidGrid.push(mtDates[x]);
                    }

                    if (qtrs === 7 && !funds) {
                        funds = true;

                        mtDates[x].XLine = 5;
                        mtDates[x].IsCalendarLine = true;
                        eighteenMnth.push(mtDates[x]);
                    }

                    pDate = new Date(cDate);
                }
            }
            else {
                if (pMonth !== cMonth) {
                    mtDates[x].XLine = 6;
                    const uMonth = videoMode === true ? pMonth : cMonth;
                    let yDateStr = LocalizationStore.getTranslatedData(`cal_${monthNames[uMonth]}`, monthNames[uMonth]);

                    if (pDate.getFullYear() !== cDate.getFullYear()) {
                        yDateStr = LocalizationStore.getTranslatedData("sb_date", "{0} {1}", LocalizationStore.getTranslatedData(`cal_Num_${monthNames[uMonth]}`, monthNames[uMonth]), year);;
                    }

                    if (calQtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].XLine = 2;
                        mtDates[x].IsCalendarLine = true;
                        mtDates[x].IsCalendarLabel = true;
                        if (timeLineData.FirstCalLabelIndex === -1) {
                            timeLineData.FirstCalLabelIndex = x;
                        }
                        if (timeLineData.FirstLabelIndex === -1) {
                            timeLineData.FirstLabelIndex = x
                        }
                    }
                    if (f1Qtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].IsFiscalLabel1 = true;
                        if (timeLineData.Firstf1LabelIndex === -1) {
                            timeLineData.Firstf1LabelIndex = x;
                        }
                    }
                    if (f2Qtrs[Qtr(cMonth)] === cMonth) {
                        mtDates[x].IsFiscalLabel2 = true;
                        if (timeLineData.Firstf2LabelIndex === -1) {
                            timeLineData.Firstf2LabelIndex = x;
                        }
                    }

                    mtDates[x].XLabel = yDateStr;
                    mtDates[x].IsMonthLabel = true;
                    if (mtDates[x].XLabel !== "") {
                        timeLineData.TimeLineLabel.push(mtDates[x])
                    }
                    pDate = new Date(cDate);
                }
                if (cDate.getDay() === 5) {
                    if (mtDates[x].XLine === 6) {
                        mtDates[x].XLine = 4;
                        mtDates[x].IsCalendarLine = true;
                    }
                    if (mtDates[x].XLine === 0) {
                        mtDates[x].XLine = 1;
                        mtDates[x].IsCalendarLine = true;
                    }
                }
            }
            cDate = new Date(year, cDate.getMonth(), cDate.getDate() - setVGridConst.daysTOForward);
        }
        else {
            const uDate = periodicity === GraphType.Monthly && videoMode ? pDate : cDate;
            if (pDate.getFullYear() !== cDate.getFullYear() && cDate.getFullYear() % setVGridConst.isMultipleOf === 0 && (periodicity === GraphType.Monthly || (periodicity !== GraphType.Monthly && cMonth === setVGridConst.cMonthConst))) {
                mtDates[x].XLabel = uDate.getMonth() + 1 === setVGridConst.cMonthConst ? uDate.getFullYear() : "";
                mtDates[x].IsCalendarLabel = setVGridConst.IsCalendarLabel;
                mtDates[x].IsFiscalLabel1 = setVGridConst.IsFiscalLabel1;
                mtDates[x].IsFiscalLabel2 = setVGridConst.IsFiscalLabel2;
                mtDates[x].XLine = setVGridConst.xLine;
                mtDates[x].IsCalendarLine = setVGridConst.IsCalendarLine;
                mtDates[x].IsMonthLabel = setVGridConst.IsMonthLabel;
                pDate = new Date(xDate);
                if (timeLineData.FirstLabelIndex === -1 && mtDates[x].XLabel !== "") {
                    timeLineData.FirstLabelIndex = x
                }
                if (mtDates[x].XLabel !== "") {
                    timeLineData.TimeLineLabel.push(mtDates[x])
                }
            }
            if (periodicity === GraphType.Quarterly && pDate.getFullYear() !== year && cMonth === 3) {
                mtDates[x].XLabel = "";
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].IsFiscalLabel1 = true;
                mtDates[x].IsFiscalLabel2 = true;
                mtDates[x].XLine = 1;
                mtDates[x].IsCalendarLine = true;
                pDate = xDate;
            }

            if (periodicity === GraphType.Quarterly) {
                const aRem = cMonth % 3;
                cMonth = aRem !== 0 ? cMonth - aRem : cMonth - 3;
            }
            else if (periodicity === GraphType.Annual) {
                cMonth = 0;
            }
            cDate = new Date(year, cMonth, setVGridConst.daysTOForward);
        }
        x++;
        if (periodicity !== GraphType.Weekly) {
            if (cDate.getDay() === DayOfWeek.Saturday) {
                cDate.setDate(cDate.getDate() - 1);
            }
            if (cDate.getDay() === DayOfWeek.Sunday) {
                cDate.setDate(cDate.getDate() - 2);
            }
        }
    }
}

function IntradayPadding(cDate, openTime, closeTime, periodicity) {
    const hours = openTime.getHours();
    const minutes = openTime.getMinutes();

    if (closeTime.getHours() <= cDate.getHours() && closeTime.getMinutes() <= cDate.getMinutes()) {
        // after market close.
        cDate.setDate(cDate.getDate() + 1);
        if (cDate.getDay() === DayOfWeek.Saturday) {
            cDate.setDate(cDate.getDate() + 2);
        }
        if (cDate.getDay() === DayOfWeek.Sunday) {
            cDate.setDate(cDate.getDate() + 1);
        }
        cDate = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), hours, minutes, 0, 0);
    }
    const remainder = cDate.getMinutes() % Factor[periodicity];
    if (remainder !== 0) {
        cDate = new Date(cDate.getTime() + (Factor[periodicity] - remainder) * 60000);
    }
    cDate = cDate = new Date(cDate.getTime() + 4 * 60000 * Factor[periodicity]);
    if (closeTime.getHours() < cDate.getHours() || hours > cDate.getHours()) {
        cDate.setDate(cDate.getDate() + 1);
        if (cDate.getDay() === DayOfWeek.Saturday) {
            cDate.setDate(cDate.getDate() + 2);
        }
        if (cDate.getDay() === DayOfWeek.Sunday) {
            cDate.setDate(cDate.getDate() + 1);
        }
        cDate = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), hours, minutes, 0, 0);
    }
    return cDate;
}
function SetIntradayVerticalGrid(startDate, endDate, timeLineData, openTime, closeTime, graphType) {
    const mtDates = timeLineData.dates;
    if (endDate.Year < 1800) {
        return;
    }
    let pDate = new Date(IntradayPadding(new Date(endDate.getTime()), openTime, closeTime, graphType));
    let cDate = new Date(pDate.getTime());
    let x = 0;
    let addTimeLabel = false;
    const oMinute = openTime.getMinutes();
    const oHours = openTime.getHours();
    const cMinute = closeTime.getMinutes();
    const cHours = closeTime.getHours();
    const cTime = closeTime.getHours() * 100 + cMinute;
    const oTime = openTime.getHours() * 100 + oMinute;
    while (cDate >= startDate) {
        const xDate = new Date(cDate.getTime());

        const info =
        {
            Date: xDate,
            XAxis: x,
            XLine: 0,
            XLabel: "",
            IsMonthLabel: false
        };

        mtDates[x] = info;

        const crTime = cDate.getHours() * 100 + cDate.getMinutes();
        const addHours = oMinute === 0 ? 0 : 1;

        switch (graphType) {
            case GraphType.Intraday1Min:
                addTimeLabel = (cDate.getMinutes() % 15 === 0 && crTime < cTime);
                break;

            case GraphType.Intraday5Min:
                addTimeLabel = (cDate.getMinutes() === 0 && cDate.getHours() > (oHours + addHours) && crTime < cTime);
                break;

            case GraphType.Intraday10Min:
                addTimeLabel = (cDate.getMinutes() === 0 && cDate.getHours() % 2 === 0 && cDate.getHours() > (oHours + addHours) && crTime < cTime);
                if (cDate.getHours() === cHours && cMinute > 0 || cDate.getHours() - oHours < 2) {
                    addTimeLabel = false;
                }
                break;

            case GraphType.Intraday15Min:
                addTimeLabel = (cDate.getMinutes() === 0 && cDate.getHours() % 2 === 0 && cDate.getHours() > (oHours + addHours) && crTime < cTime);
                if (cDate.getHours() === cHours && cMinute > 0 || cHours - cDate.getHours() < 2 || cDate.getHours() - oHours <= 2) {
                    addTimeLabel = false;
                }
                break;

            default:
                break;
        }

        const okToGo = oMinute > 0 ? crTime === (oHours + 1) * 100 : crTime === oTime;
        if (crTime <= oTime || (graphType === GraphType.Intraday60Min && okToGo)) {
            if (graphType === GraphType.Intraday60Min) {
                if (cDate.getMonth() !== pDate.getMonth()) {
                    for (let c = x; c > 0; c--) {
                        if (mtDates[c].IsCalendarLabel !== undefined) {
                            mtDates[c].XLabel = monthNames[pDate.getMonth() + 1];
                            mtDates[c].XLine = 2;
                            break;
                        }
                    }
                    pDate = cDate;
                }

                mtDates[x].XLabel = cDate.getDate();
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].XLine = 1;
                mtDates[x].DarkLabel = true;
                mtDates[x].IsCalendarLine = true;
                if (mtDates[x].XLabel !== "") {
                    timeLineData.TimeLineLabel.push(mtDates[x])
                }

            }
            else {
                mtDates[x].XLabel = `${LocalizationStore.getTranslatedData("cal_" + monthNames[cDate.getMonth() + 1], monthNames[cDate.getMonth() + 1])} ${LocalizationStore.getTranslatedData("sb_day", "{0}", cDate.getDate())}`;// cDate.ToString("MMM dd").ToUpper();
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].IsCalendarLine = true;
                if (graphType === GraphType.Intraday30Min) {
                    mtDates[x].XLine = 1;
                }
                else {
                    mtDates[x].XLine = 2;
                }
                if (mtDates[x].XLabel !== "") {
                    timeLineData.TimeLineLabel.push(mtDates[x])
                }
            }

            x++;
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() === DayOfWeek.Saturday) {
                cDate.setDate(cDate.getDate() - 1);
            }
            if (cDate.getDay() === DayOfWeek.Sunday) {
                cDate.setDate(cDate.getDate() - 2);
            }

            cDate = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), closeTime.getHours(), closeTime.getMinutes(), 0);
            continue;
        }
        else {
            if (addTimeLabel) {
                const hours = cDate.getHours() > 12 ? cDate.getHours() - 12 : cDate.getHours();
                let minutes = cDate.getMinutes();
                if (minutes < 10) {
                    minutes = `0${minutes}`;
                }
                if (graphType === GraphType.Intraday15Min) {
                    mtDates[x].XLabel = hours;
                }
                else {
                    mtDates[x].XLabel = `${hours}:${minutes}`;
                }
                mtDates[x].IsCalendarLabel = true;
                mtDates[x].XLine = 1;
                mtDates[x].DarkLabel = true;
                mtDates[x].IsCalendarLine = true;
                if (mtDates[x].XLabel !== "") {
                    timeLineData.TimeLineLabel.push(mtDates[x]);
                }
            }
        }

        addTimeLabel = false;

        x++;
        const remaider = cDate.getMinutes() % Factor[graphType];
        cDate = new Date(cDate.getTime() - (remaider === 0 ? Factor[graphType] : remaider) * 60000);
    }
    //        return timeLineData;
}
function GetNumberOfDays(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.round(Math.abs((startDate.getTime() - endDate.getTime()) / (oneDay)));
}

function TimeLineSetVerticalGrid(startDate, endDate, timeLineData, fCQtrEstCount, SymbolInfo, isMountainChart, periodicity, isIntraday, isComparisionChart = false, videoMode = false, HSFResults) {
    const earnReportDate = SymbolInfo.Earnrptdate.getFullYear ? SymbolInfo.Earnrptdate : new Date(parseInt(SymbolInfo.Earnrptdate.substr(6)));
    const mutualFund = SymbolInfo.SymTypeEnum === SymbolType.FUND;
    if (isMountainChart) {
        if (!isIntraday) {
            SetMCVerticalGrid(startDate, endDate, timeLineData, earnReportDate, fCQtrEstCount, periodicity);
        }
    }
    else if (isComparisionChart) {
        padding = false;

        if (SymbolInfo.SymTypeEnum !== SymbolType.CURRENCY &&
            SymbolInfo.SymTypeEnum !== SymbolType.ETF &&
            SymbolInfo.SymTypeEnum !== SymbolType.FundClosedEnd &&
            SymbolInfo.SymTypeEnum !== SymbolType.FUND) {
            SetVerticalGrid(startDate, endDate, timeLineData, earnReportDate, fCQtrEstCount, false, videoMode, periodicity);
        }
        else {
            SetVerticalGrid(startDate, endDate, timeLineData, NaN, 0, mutualFund, videoMode, periodicity);
        }

    }
    else {
        if (isIntraday) // Intraday has multiple periodicities
        {
            const openTime = SymbolInfo.ExchangeOpenTime.getFullYear ? SymbolInfo.ExchangeOpenTime : DateHelper.parseJsonDate(SymbolInfo.ExchangeOpenTime, SymbolInfo.MomentZoneID);
            const closeTime = SymbolInfo.ExchangeCloseTime.getFullYear ? SymbolInfo.ExchangeCloseTime : DateHelper.parseJsonDate(SymbolInfo.ExchangeCloseTime, SymbolInfo.MomentZoneID);

            const tradeStart = new Date(endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate(),
                openTime.getHours(),
                openTime.getMinutes(), 0, 0);
            const tradeClose = new Date(endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate(),
                closeTime.getHours(),
                closeTime.getMinutes(), 0, 0);
            const endTime = new Date(endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate(),
                endDate.getHours(),
                endDate.getMinutes(), 0, 0);
            let startTime = new Date(startDate.getTime());
            let isStartDateAvailable = false;
            if (HSFResults && HSFResults.length > 0) {
                const firstPriceNode = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(HSFResults[HSFResults.length - 1].Date), SymbolInfo.MomentZoneID);
                if (firstPriceNode < startTime) {
                    // firstPriceNode / start date will be calculated at the graph service end
                    isStartDateAvailable = true;
                    startTime = firstPriceNode;
                    startTime.setDate(startTime.getDate() - 1); // including extra 1 day incase the system timezone is different from the symbolInfo timezone.

                    if (startTime.getDay() === DayOfWeek.Sunday){
                        startTime.setDate(startTime.getDate() - 3);
                    }
                    if (startTime.getDay() === DayOfWeek.Saturday){
                        startTime.setDate(startTime.getDate() - 2);
                    }
                }
            }
            let days = GetNumberOfDays(startTime, endTime);
            const tradingMinutes = (closeTime.getTime() - openTime.getTime()) / 60000;
            if(!isStartDateAvailable){
                startTime.setDate(endTime.getDate() - 2 * Factor[periodicity]);
                if (periodicity === GraphType.Intraday1Min) {
                    if (startTime.getDay() === DayOfWeek.Sunday) {
                        startTime.setDate(endTime.getDate() - 5);
                    }
                    if (startTime.getDay() === DayOfWeek.Saturday) {
                        startTime.setDate(endTime.getDate() - 4);
                    }
                }
            }
            days = GetNumberOfDays(startTime, endTime);
            const totalNodes = Math.round(days * tradingMinutes / Factor[periodicity]);
            timeLineData = { dates: new Array(totalNodes), FirstLabelIndex: -1, TimeLineLabel: [] };
            SetIntradayVerticalGrid(startTime, endTime, timeLineData, tradeStart, tradeClose, periodicity);
        }
        else {
            if (SymbolInfo.SymTypeEnum !== SymbolType.CURRENCY &&
                SymbolInfo.SymTypeEnum !== SymbolType.ETF &&
                SymbolInfo.SymTypeEnum !== SymbolType.FundClosedEnd &&
                SymbolInfo.SymTypeEnum !== SymbolType.FUND) {
                const consoleSettings = SettingsStore.getConsoleSettings();
                const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
                const stockViewSettings = tabDataGraphSettings.StockViewSettings;
                const earningLineSetting = stockViewSettings.EarningLineSetting[periodicity];
                const revenueLineSetting = stockViewSettings.RevenueLineSetting[periodicity];
                let fcQuarters = Math.min((earningLineSetting === undefined ? 0 : earningLineSetting.NumOfQuarters), SymbolInfo.FCQtrEstCount);

                if (periodicity === GraphType.Monthly || periodicity === GraphType.Quarterly || periodicity === GraphType.Annual) {
                    fcQuarters = earningLineSetting === undefined ? 0 : earningLineSetting.NumOfQuarters;
                }
                if (!earningLineSetting.IsVisible && !revenueLineSetting.IsVisible) {
                    fcQuarters = 0;
                }
                SetVerticalGrid(startDate, endDate, timeLineData, earnReportDate, fcQuarters, false, videoMode, periodicity);
            } else {
                SetVerticalGrid(startDate, endDate, timeLineData, NaN, 0, mutualFund, videoMode, periodicity);
            }
        }
    }
    return timeLineData;
}
//saga generator functions
function createTimeline({ startDate, endDate, periodicity, majorPeriodicity, fCQtrEstCount, SymbolInfo, isMountainChart, isComparisionChart = false, videoMode = false, HSFResults }) {
    try {
        const units = Math.round(GetNumberOfDays(startDate, endDate) / Factor[periodicity]);
        const TimeLineData = TimeLineSetVerticalGrid(startDate, endDate, { dates: new Array(units), FirstLabelIndex: -1, TimeLineLabel: [] }, fCQtrEstCount, SymbolInfo, isMountainChart, periodicity, majorPeriodicity === GraphType.Intraday, isComparisionChart, videoMode, HSFResults);
        const fiscalMonthEnd = SymbolInfo && SymbolInfo.FiscalMonthEnd ? SymbolInfo.FiscalMonthEnd : 12;
        let TimeLineFirstLabelIndex = null;
        let TimeLineLabels = null;
        let CalenderTimeLineLabels = [];
        let CalenderTimeLineFirstLabelIndex = 0;
        if (periodicity === GraphType.Weekly) {
            CalenderTimeLineLabels = TimeLineData.TimeLineLabel.filter((v) => v !== undefined && v.IsCalendarLabel);
            CalenderTimeLineFirstLabelIndex = TimeLineData.FirstCalLabelIndex;
            if (calQtrs[Qtr(fiscalMonthEnd)] === fiscalMonthEnd) {
                TimeLineLabels = CalenderTimeLineLabels;
                TimeLineFirstLabelIndex = CalenderTimeLineFirstLabelIndex;
            }
            else if (f1Qtrs[Qtr(fiscalMonthEnd)] === fiscalMonthEnd) {
                TimeLineLabels = TimeLineData.TimeLineLabel.filter((v) => v.IsFiscalLabel1);
                TimeLineFirstLabelIndex = TimeLineData.Firstf1LabelIndex;
            }
            else if (f2Qtrs[Qtr(fiscalMonthEnd)] === fiscalMonthEnd) {
                TimeLineLabels = TimeLineData.TimeLineLabel.filter((v) => v.IsFiscalLabel2);
                TimeLineFirstLabelIndex = TimeLineData.Firstf2LabelIndex;
            }
        }
        else {
            if(periodicity === GraphType.Daily){
                CalenderTimeLineFirstLabelIndex = TimeLineData.FirstCalLabelIndex;
                if (calQtrs[Qtr(fiscalMonthEnd)] === fiscalMonthEnd) {
                    TimeLineFirstLabelIndex = CalenderTimeLineFirstLabelIndex;
                }
                else if (f1Qtrs[Qtr(fiscalMonthEnd)] === fiscalMonthEnd) {
                    TimeLineFirstLabelIndex = TimeLineData.Firstf1LabelIndex;
                }
                else if (f2Qtrs[Qtr(fiscalMonthEnd)] === fiscalMonthEnd) {
                    TimeLineFirstLabelIndex = TimeLineData.Firstf2LabelIndex;
                } 
            }
            else{
                CalenderTimeLineFirstLabelIndex = TimeLineData.FirstLabelIndex;
                TimeLineFirstLabelIndex = CalenderTimeLineFirstLabelIndex;
            }
            CalenderTimeLineLabels = TimeLineData.TimeLineLabel;
            TimeLineLabels = CalenderTimeLineLabels;
        }
        
        // yield putResolve(updateTimelineData(TimeLineData, TimeLineFirstLabelIndex, TimeLineLabels));
        return [TimeLineData, TimeLineFirstLabelIndex, TimeLineLabels, CalenderTimeLineLabels, CalenderTimeLineFirstLabelIndex];
    }
    catch (error) {
        console.log(`Error occurs in createTimeline.js, initTimeLine ${error}`);
    }

}

export function* initTimeLine({ nodeCount = 0, endDate, InitialBufferSize, isMountainChart, isComparisionChart, isInitial}) {
    try {
        const state = {};
        const { viewsSettings, periodicity, majorPeriodicity, SymbolInfo, pricePanelData, isHistoric, videoMode, priceDataAvailable, SymbolType, isIntraday} = yield select(getDatagraphStates);
        if(!priceDataAvailable){
            return;
        }

        const financialBlockSetting = viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : undefined;
        state.fiscalMonthEnd = financialBlockSetting && financialBlockSetting.IsAvailable && financialBlockSetting.IsVisible && SymbolInfo.FiscalMonthEnd ? SymbolInfo.FiscalMonthEnd : 12;
        state.isCalender = financialBlockSetting ? financialBlockSetting.DisplayMode === FinancialBlockDisplayMode.CalendarMode : true;
        state.align = state.videoMode ? "begin" : "end";
        if(SymbolType === SmartViewType.FUND || SymbolType === SmartViewType.CURRENCY || SymbolType === SmartViewType.CRYPTOCURRENCY){
            state.align = isIntraday ? "begin" : "end";
        }
        else if (majorPeriodicity === GraphType.Intraday || majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual) {
            state.align = "begin";
        }
        const earningLineSetting = viewsSettings.EarningLineSetting ? viewsSettings.EarningLineSetting[periodicity] : undefined;
        const revenueLineSetting = viewsSettings.RevenueLineSetting ? viewsSettings.RevenueLineSetting[periodicity] : undefined;
        // const endDay = DateHelper.parseJsonDate(SymbolInfo.LastTradeDate, SymbolInfo.MomentZoneID);
        const startDate = DateHelper.calculateBeginDate(endDate, periodicity, (nodeCount + InitialBufferSize));
        if(periodicity === GraphType.Annual){
            startDate.setFullYear(endDate.getFullYear() - (nodeCount + InitialBufferSize))
        }
        //let fcQuarters = 0;
        state.endDate = endDate;
        if (isHistoric) {
            if (pricePanelData.EPSSnapshotData && pricePanelData.EPSSnapshotData.Results && pricePanelData.EPSSnapshotData.Results.length > 0) {
              const actualEpsData = pricePanelData.EPSSnapshotData.Results.filter((t) => t.EpsType === 2);
              if (SymbolInfo.CurrEarnrptdate === undefined) {
                SymbolInfo.CurrEarnrptdate = new Date(SymbolInfo.Earnrptdate.getTime());
              }
              if (actualEpsData[0]) {
                SymbolInfo.Earnrptdate = DateHelper.getPSTFromLong(actualEpsData[0].fiscalDt);
              }
              else {
                SymbolInfo.Earnrptdate = new Date(endDate.getTime());
                SymbolInfo.FCQtrEstCount = 0;
              }
            } else {
              SymbolInfo.Earnrptdate = new Date(endDate.getTime());
              SymbolInfo.FCQtrEstCount = 0;
            }
          } else {
            if (SymbolInfo.CurrEarnrptdate !== undefined) {
              SymbolInfo.Earnrptdate = new Date(SymbolInfo.CurrEarnrptdate.getTime());
            }
          }
          if(!pricePanelData.HsfData.HSFResultsRawData){
            pricePanelData.HsfData.HSFResultsRawData =pricePanelData. HsfData.HSFResults
        }
        const fCQtrEstCount = earningLineSetting === undefined || revenueLineSetting === undefined || (!earningLineSetting.IsVisible && !revenueLineSetting.IsVisible) ? 0 : earningLineSetting.NumOfQuarters;
        [state.TimeLineData, state.TimeLineFirstLabelIndex, state.TimeLineLabels, state.CalenderTimeLineLabels, state.CalenderTimeLineFirstLabelIndex] = createTimeline({ startDate, endDate: state.endDate, periodicity, majorPeriodicity, fCQtrEstCount, SymbolInfo, isMountainChart, isComparisionChart, videoMode, HSFResults: pricePanelData.HsfData.HSFResults });
        AnnotationUtil.setTimeLine(state.TimeLineData.dates);
        console.log(state.TimeLineData)
        if(isInitial){
            yield put(initTimeLineState(state.TimeLineData.dates, state));
        }
        else{
            yield put({
                type: ActionTypes.UPDATE_TIMELINE,
                state
            })
        }
        
    }
    catch (error) {
        console.log(`Error occurs in TimelineSaga.js, initTimeLine ${error}`);
    }
}

//saga watcher

export function* watchCreateTimeLine() {
    // yield takeLatest(ActionTypes.CREATE_TIMELINE, createTimeline)
}

export function* watchInitTimeLine() {
    yield takeLatest(ActionTypes.INIT_TIMELINE, initTimeLine);
}