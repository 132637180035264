import ArithmaticScale from "../../../Utils/Scales/ArithmaticScale.js";
import BaseServiceApi from 'BaseServiceApi';
import consoleApi from "../../../ServiceApi/Apis/ConsoleApi.js";
import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import indexLine from "../../../Utils/Calcs/IndexLineChart.js";
import IndexLineSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/IndexLineSettings.js";
import IndustryTypeConstants from "../../../Constants/IndustryTypeConstants.js";
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants";
import PriceMenuHelper from "./PriceMenuHelper";
import RelativeStrenghtLineSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelativeStrengthLineSettings.js";
import SettingsStore from "SettingsStore";
import SymbolType from "../../../Constants/SymbolType.js";
import UserInfoUtil from "../../../Utils/UserInfoUtil.js";
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { cancelIndexLineDialogSetting, updateIndexLineShowAlert, updateIndexSymbolError } from "../../../Actions/PricePanelActions.js";
import { ErrorMessageTranslateHelper, IndexNameTranslateHelper, IndustryGroupsTranslateHelper } from "../../../Utils/TranslateHelper.js";
import {
    getDatagraphStates,
    getIndexLineStates,
    paddingSelect,
    priceChartReducerselect
} from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
const { ActionTypes } = PriceChartConstants;
function internationalStock(info) {
    return (info &&
        info.SymTypeEnum &&
        info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK
    );
}
function getGroups(info) {
    try {
        const international = info ? internationalStock(info) : false;
        const groups = [];
        groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONINDUSTRYGROUP197]} (197)`, type: SymbolType.WONINDUSTRYGROUP197, symbol: info.Industry197Symbol});
        if (!international) { groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONMAJORINDUSTRY89]} (89)`, type: SymbolType.WONMAJORINDUSTRY89, symbol: info.MajorIndustrySymbol}); }
        groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONSECTOR11]} (11)`, type: SymbolType.WONSECTOR11, symbol: info.Sector11Symbol});
        return groups;
    }
    catch (error) {
        console.log(`Error occurs in LineIndexSaga.js, getGroups ${error}`);
    }
}
function getTranslatedIndexName(indexNameUsed, indexSymbol) {
    if (indexNameUsed && indexSymbol) {
        if(!IndexNameTranslateHelper[indexNameUsed]){
            IndexNameTranslateHelper[indexNameUsed] = LocalizationStore.getTranslatedData(`Index_Industry_${indexSymbol}`, indexNameUsed);
        }
        return IndexNameTranslateHelper[indexNameUsed];
    } else {
        return IndexNameTranslateHelper[IndustryTypeConstants.S_P_500_Index];
    }
}
function getIndexName(pricePanelData) {
    try {
        let indexNameUsed = undefined;
        let indexSymbol = undefined;
        if (pricePanelData.IndexClosingResults) {
            indexNameUsed = pricePanelData.IndexClosingResults.Coname;
            indexSymbol = pricePanelData.IndexClosingResults.Symbol;
        }
        return getTranslatedIndexName(indexNameUsed, indexSymbol);
    }
    catch (error) {
        console.log(`Error occurs in LineIndexSaga.js, getIndexName ${error}`);
    }
}
function getIndexChart(indexData, startXPoint, nodeWidth, height, lastNode) {
    try {
        let xAxis = startXPoint;
        const prcLength = indexData.length;
        let maxPrice = Number.NEGATIVE_INFINITY;
        let minPrice = Number.POSITIVE_INFINITY;

        // const instSettings = PriceMenuHelper.getInstSettings();
        // const periodicityM = PriceMenuHelper.getPeriodicity();
        // const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicityM);

        // const indexSettingsByPeriodicity = instSettings.IndexLineSettingsByPeriodicity[majorPeriodicity];

        // if (!indexSettingsByPeriodicity.IsVisible) return []; optPriceChartVisibility

        for (let i = lastNode; i < prcLength; i++) {
            if (xAxis > 0) {
                if (maxPrice < indexData[i].Close) { maxPrice = indexData[i].Close; }
                if (minPrice > indexData[i].Close) { minPrice = indexData[i].Close; }
            }
            xAxis -= nodeWidth;
            if (xAxis < 0) { break; }
        }

        const scale = new ArithmaticScale();

        scale.InitScale(minPrice, maxPrice, height / 3);
        return indexLine.IndexLineChart(startXPoint, indexData, height / 3, scale, lastNode, nodeWidth);
    }
    catch (error) {
        console.log(`Error occurs in LineIndexSaga.js, getIndexChart ${error}`);
    }
}
function* processIndexLine(action) {
    try {
        if(action?.isStreamingProc){
            return;
        }
        const { pricePanelData, viewsSettings, majorPeriodicity, SymbolInfo, nodeWidth, isIntraday, startXPoint} = yield select(getDatagraphStates);
        if(pricePanelData.IndexClosingResults && !isIntraday){
            if (viewsSettings.IndexLineSettingsByPeriodicity) {
                // let indexLineSettings = "";
                // if (info !== null && info !== undefined) {
                //     indexLineSettings = instSettings.IndexLineSettings[info.CountryCode];
                // }
                const groups = getGroups(SymbolInfo);
                const errorMessage = ErrorMessageTranslateHelper.ENTER_VALID_SYMBOL;
                const showUpdateAlert = false;
                const indexData = pricePanelData.IndexClosingResults
                    ? pricePanelData.IndexClosingResults.HSFResults
                    : null;
                const height = PriceMenuHelper.getHeight();
                const indexLineData = getIndexChart(indexData, startXPoint, nodeWidth, height, 0);
                const indexText = getIndexName(pricePanelData);
                const indexLineSettingsByPeriodicity = viewsSettings.IndexLineSettingsByPeriodicity[majorPeriodicity];
    
                yield put({
                    type: ActionTypes.INDEX_LINE_DATA_READY,
                    periodicity: majorPeriodicity,
                    indexLineSettingsByPeriodicity,
                    groups,
                    errorMessage,
                    showUpdateAlert,
                    indexLineData,
                    indexText,
                    nodeWidth
                });
            } else {
                yield put({
                    type: ActionTypes.CLEAR_INDEX_LINE
                });
            }
        }
        else{
            yield put({
                type: ActionTypes.CLEAR_INDEX_LINE
            });
        }
    }
    catch (error) {
        console.log(`Error occurs in LineIndexSaga.js, processIndexLine ${error}`);
    }
}


function* openIndexLineEditDialog(){
    try{
        const { pricePanelData } = yield select(priceChartReducerselect);
        const SymTypeEnum = pricePanelData.SymbolInfo.SymTypeEnum
        const isSymbolIndex = SymbolType.INDEX === SymTypeEnum;
        const isSymbolGroup = SymbolType.WONINDUSTRYGROUP197 === SymTypeEnum ||
                                SymbolType.WONMAJORINDUSTRY89 === SymTypeEnum ||
                                SymbolType.WONSECTOR11 === SymTypeEnum ||
                                SymbolType.WONSUBSECTOR33 === SymTypeEnum;
        const isFund = SymbolType.FUND === SymTypeEnum;
    
        const instSettings = PriceMenuHelper.getInstSettings();
        const majorPeriodicity = PriceMenuHelper.getMajorPeriodicity();
        const indexLineSettingsByPeriodicity = instSettings.IndexLineSettingsByPeriodicity[majorPeriodicity];
        const countries = UserInfoUtil.getUserInfo().regionCountries;
        const regionCountries = {}
        const regions = [];
        let IndexName = '';
        let defaultRegionCountry = null;
        const countryCode = pricePanelData.SymbolInfo.CountryCode;
        countries.forEach((country)=>{
            if(!regionCountries[country.region]){
                regions.push(country.region)
                regionCountries[country.region] = [];
            }
            if(country.countryCode === countryCode){
                IndexName = country.indexName;
                defaultRegionCountry = {
                    region: country.region, 
                    country: country.name,
                    countryIndex: country.indexSymbol
                }
            }
            regionCountries[country.region].push(country);
        })
        const indexLineSettings = instSettings.IndexLineSettings[countryCode];
        if(defaultRegionCountry)  {            
            indexLineSettings.region = defaultRegionCountry.region;               
            indexLineSettings.country = defaultRegionCountry.country;          
            indexLineSettings.countryIndex = defaultRegionCountry.countryIndex;       
        }
        yield put({
            type: ActionTypes.UPDATE_INDEXLINE_DIALOG_STATES,
            isIndexLineDialogOpen: true,
            regionCountries,
            regions,
            selectedRegion: indexLineSettings.region,
            countries: regionCountries[indexLineSettings.region],
            selectedCountry: indexLineSettings.country,
            IndexSymbol: indexLineSettings.IndexSymbol,
            IndexName,
            countryCode,
            countryIndex: indexLineSettings.countryIndex,
            lineColor: indexLineSettingsByPeriodicity.lineColor,
            lineThickness: indexLineSettingsByPeriodicity.lineThickness,
            selectedGroup: indexLineSettings.selectedGroup,
            symbolName: indexLineSettings.valueType === 'symbol' ? indexLineSettings.IndexSymbol : '',
            valueType: indexLineSettings.valueType,
            isSymbolIndex,
            isSymbolGroup,
            isFund
        })
    }
    catch(error){
        console.error("Error occured in openIndexLineEditDialog of IndexLineSaga.js", error);
    }
}
function Validate(iSymbol) {
    const regex = /^[A-Za-z0-9\\.\\$\\/\\&]+$/;

    return regex.test(iSymbol);
}

function symbolCheck(serviceSymbol, otherSymbol) {
    if (serviceSymbol === null) {
        return false;
    }
    if (serviceSymbol.toUpperCase() === otherSymbol.toUpperCase().trim()) {
        return true;
    }
    return false;
}

function* saveIndexLineEditDialog(){
    try{
        const state = yield select(getIndexLineStates);
        if (state.valueType === 'symbol') {
            let errorMsg = ErrorMessageTranslateHelper.ENTER_VALID_SYMBOL;
            if (state.symbolName.length === 0 || !Validate(state.symbolName)) {
                yield put(updateIndexSymbolError(errorMsg))
                return;
            }
            const isLocalSymbolEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.RAY_LOCALSYMBOLS_Entitlement);
            const count = isLocalSymbolEntitlement ? 20 : 1;
            const symbolResults = yield call(consoleApi.getSymbolSearchRequest, state.symbolName.trim(), count);
            const filteredSymbol = isLocalSymbolEntitlement ? symbolResults.results.filter((item)=>item.Symbol === state.symbolName.toUpperCase()) : symbolResults.results;
            if (!filteredSymbol ||  filteredSymbol.length === 0) {
                yield put(updateIndexSymbolError(errorMsg))
                return;
            }
            const symbolInfo = filteredSymbol[0];
            if(symbolInfo.Osid <= 0) {
                yield put(updateIndexSymbolError(errorMsg));
                return;
            }
            else if(state.symbolName.toUpperCase() !== symbolInfo.Symbol && (symbolCheck(symbolInfo.localSymbol, state.symbolName) || symbolCheck(symbolInfo.PinyinSymbol, state.symbolName))){
                errorMsg = ErrorMessageTranslateHelper.ONEIL_SYMBOL_ONLY;
                yield put(updateIndexSymbolError(errorMsg));
                return;
            }
            else {
                state.indexSymbol = state.symbolName.toUpperCase()
            }
        }

        const instSettings = PriceMenuHelper.getInstSettings();
        const majorPeriodicity = PriceMenuHelper.getMajorPeriodicity();
        if (instSettings.IndexLineSettings[state.countryCode] === undefined) {
            instSettings.IndexLineSettings[state.countryCode] = new IndexLineSettings(true);
        }
        if (instSettings.RelativeStrenghtLine1Settings && instSettings.RelativeStrenghtLine1Settings[state.countryCode] === undefined) {
            instSettings.RelativeStrenghtLine1Settings[state.countryCode] = new RelativeStrenghtLineSettings(1, true);
        }
        if (instSettings.RelativeStrenghtLine2Settings && instSettings.RelativeStrenghtLine2Settings[state.countryCode] === undefined) {
            instSettings.RelativeStrenghtLine2Settings[state.countryCode] = new RelativeStrenghtLineSettings(2, false);
        }
        const indexLineSettings = instSettings.IndexLineSettings[state.countryCode]
        const rs1Settings = instSettings.RelativeStrenghtLine1Settings[state.countryCode]
        const indexLineSettingsByPeriodicity = instSettings.IndexLineSettingsByPeriodicity[majorPeriodicity];

        const { pricePanelData } = yield select(priceChartReducerselect);
        const CountryCode = pricePanelData.SymbolInfo.CountryCode
        let isCallPricePanelApi = false;
        if(CountryCode !== state.countryCode || 
            indexLineSettings.valueType !== state.valueType ||
            (state.valueType === "group" && indexLineSettings.selectedGroup !== state.selectedGroup) ||
            (state.valueType === "symbol" && indexLineSettings.IndexSymbol !== state.symbolName)){

            isCallPricePanelApi = true;
            if ((!state.showAlert) && rs1Settings.valueType === "default") {
                yield put(updateIndexLineShowAlert());
                return;
            }
        }
        indexLineSettingsByPeriodicity.lineColor = state.lineColor;
        indexLineSettingsByPeriodicity.lineThickness = state.lineThickness;
        indexLineSettings.valueType = state.valueType;
        if(state.valueType === "default"){
            indexLineSettings.IndexSymbol = state.countryIndex;
            indexLineSettings.IndexName = state.IndexName;
            indexLineSettings.symbolName = "";
            if (rs1Settings.valueType === "default") {
                rs1Settings.IndexSymbol = state.countryIndex;
                rs1Settings.IndexName = state.IndexName;
                rs1Settings.symbolName = "";
            }
        }
        else if (state.valueType === "symbol") {
            indexLineSettings.IndexSymbol = state.symbolName.toUpperCase();
            if (rs1Settings.valueType === "default"){
                rs1Settings.IndexSymbol = state.symbolName.toUpperCase();
            }
        }
        else if (state.valueType === "group") {
            const selectedGroup = state.groups.find((item)=> state.selectedGroup === item.type)
            if(selectedGroup){
                indexLineSettings.IndexSymbol = selectedGroup.symbol;
                if (rs1Settings.valueType === "default"){
                    rs1Settings.IndexSymbol = selectedGroup.symbol;
                }
            }
            
        }

        if (state.isOriginalCountryCodeIso) {
            indexLineSettings.region = state.selectedRegion;
            indexLineSettings.country = state.selectedCountry;
            indexLineSettings.countryIndex = state.countryIndex;
        }
        indexLineSettings.selectedGroup = state.selectedGroup;
        yield put(cancelIndexLineDialogSetting());
        SettingsStore.saveSettings();
        yield put({
            type: ActionTypes.PREPARE_PRICE_MENU
        });

        if(isCallPricePanelApi){
            // redrawLines when change
            yield put({
                type: DataGraphConstants.ActionTypes.HANDLE_REDRAW_LINE
            });
        }
        else{
            yield call(processIndexLine)
        }
    }
    catch(error){
        console.error("Error occured in saveIndexLineEditDialog of IndexLineSaga.js", error);
        
    }
}
/********************watchers*************************/
export function* watchInitIndexLine() {
    yield takeLatest([ActionTypes.PRICE_DATA_READY,ActionTypes.REDRAW_INDEX_LINE], processIndexLine)
}
export function* watchOpenindexLineEditDialog() {
    yield takeLatest(ActionTypes.OPEN_INDEX_LINE_DIALOG, openIndexLineEditDialog)
}
export function* watchSaveIndexLineEditDialog() {
    yield takeLatest(ActionTypes.SAVE_INDEX_DIALOG_SETTING, saveIndexLineEditDialog)
}