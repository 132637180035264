import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { RotationGraphConstants } from "Constants/RotationGraphConstants";
const { ActionTypes } = RotationGraphConstants;
import { getBenchMarkState } from '../../../Reducers/NavCompare/RotationGraphReducers/selectors';
import StringUtil from 'StringUtil';
import CompareApi from "CompareApi";
import moment from "moment";
import store from '../../../Redux/store';
import { getQuadDataForSymbol, processQuadData } from '../RotationGraph/CenterRotationSaga';
import { BenchMarkOptions } from "Constants/RotationMenuType";
import DateHelper from "DateHelper";
import { PrintMode } from '../../../print/printmode';

export function* getBenchMarkData({ benchMarkRequestData }) {
    try {
        yield put({
            type: ActionTypes.UPDATE_BENCHMARKDATA_LOADING_STATUS,
            isBenchMarkLoading: true
        });

        const result = yield CompareApi.getBenchMarkData(benchMarkRequestData);

        yield put({
            type: ActionTypes.UPDATE_BENCHMARK_DATA,
            result: result
        });
    }
    catch (error) {
        console.log(`Error occurs in  BenchMarkSaga.js, getBenchMarkData ${error}`);
    }
}

function* initializeTimelineValues() {
    let { selectedSliderValue } = yield select(getBenchMarkState);
    const currentDate = moment.utc();
    const startDate = moment.utc().set({ 'year': 2010, 'month': 0, 'date': 1 }); // mountain chart will always start from Jan 1st 2010, Friday
    const groupsTimelineDateDictionary = [];

    try {
        while (startDate.isSameOrBefore(currentDate, 'day')) {
            groupsTimelineDateDictionary.push(startDate.toDate());
            // From Jan 1st 2010 we add every fridays date to the time line dictionary
            startDate.add(7, 'days');
        }
        if (currentDate.day() != 5) {
            groupsTimelineDateDictionary.push(currentDate.toDate());
        }

        if (!StringUtil.isEmpty(groupsTimelineDateDictionary)) {
            if (StringUtil.isEmpty(selectedSliderValue))
                selectedSliderValue = groupsTimelineDateDictionary.length - 1;

            yield all([
                put({
                    type: ActionTypes.UPDATE_GROUPS_TIMELINE,
                    groupsTimelineDateDictionary: groupsTimelineDateDictionary
                }),
                put({
                    type: ActionTypes.SET_SLIDER_VALUE,
                    selectedSliderValue: selectedSliderValue
                })
            ]);
        }
    }
    catch (error) {
        console.log(`Error occurs in  BenchMarkSaga.js, initializeTimelineValues ${error}`);
    }
}

function* toggleBenchMark() {
    const symbolsMenuState = store.getState().comparisonAndRotationMenuPanel.SymbolsPanelReducer["RotationMenuStates"];
    const { benchMarkOption } = yield select(getBenchMarkState);
    const { selectedOsid, countryCode, regionCode, countryCodeList, regionCodeList } = symbolsMenuState;
    let newBenchMarkOption = benchMarkOption;
    let code = countryCode;

    try {
        if (selectedOsid !== "" && countryCode !== null && regionCode !== null) {

            if (benchMarkOption === BenchMarkOptions.Country) {
                newBenchMarkOption = BenchMarkOptions.Regional;
                code = regionCode;
            }
            else if (benchMarkOption === BenchMarkOptions.Regional) {
                newBenchMarkOption = BenchMarkOptions.Global;
                code = 1000;
            }
            else {
                newBenchMarkOption = BenchMarkOptions.Country;
            }

            yield put({
                type: ActionTypes.TOGGLE_BENCHMARK_SELECTOR_SUCCESS,
                benchMarkOption: newBenchMarkOption
            });

            yield put({
                type: ActionTypes.SAVE_SETTINGS
            });

            const benchMarkRequestData = {
                date: DateHelper.yyyymmdd(new Date()),
                code: code
            }
            yield call(getBenchMarkData, { benchMarkRequestData });

            yield put({
                type: ActionTypes.CLEAR_SYMBOLS_QUAD_DATA
            });
            const quadDataResult = yield call(getQuadDataForSymbol, selectedOsid, countryCodeList, regionCodeList);
            const resetSliderIndex = !PrintMode.printing;
            yield call(processQuadData, quadDataResult, "3", resetSliderIndex);
        }
    }
    catch (error) {
        console.log(`Error occurs in  BenchMarkSaga.js, toggleBenchMark ${error}`);
    }
}

export function* callBenchMarkApi(prevCountryCode, prevRegionCode, countryCode, regionCode) {
    const { benchMarkOption } = yield select(getBenchMarkState);

    if (prevCountryCode !== countryCode && benchMarkOption === BenchMarkOptions.Country) {
        const benchMarkRequestData = {
            date: DateHelper.yyyymmdd(new Date()),
            code: countryCode
        }
        yield call(getBenchMarkData, { benchMarkRequestData });
    }
    else if (prevRegionCode !== regionCode && benchMarkOption === BenchMarkOptions.Regional) {
        const benchMarkRequestData = {
            date: DateHelper.yyyymmdd(new Date()),
            code: regionCode
        }
        yield call(getBenchMarkData, { benchMarkRequestData });
    }
    else if(benchMarkOption === BenchMarkOptions.Global) {
        const benchMarkRequestData = {
            date: DateHelper.yyyymmdd(new Date()),
            code: 1000
        }
        yield call(getBenchMarkData, { benchMarkRequestData });
    }

    yield put({
        type: ActionTypes.UPDATE_BENCHMARKDATA_LOADING_STATUS,
        isBenchMarkLoading: false
    });
}

export function* watchInitializeTimeLine() {
    yield takeLatest(ActionTypes.INITIALIZE_TIME_LINE, initializeTimelineValues)
}

export function* watchGetBenchMarkData() {
    yield takeLatest(ActionTypes.GET_BENCHMARK_DATA, getBenchMarkData)
}

export function* watchToggleBenchMark() {
    yield takeLatest(ActionTypes.TOGGLE_BENCHMARK_SELECTOR, toggleBenchMark)
}