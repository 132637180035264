import Serializable from "Serializable";
import AlertStore from "../../../../Alerts/AlertStore.js";

class NotificationSettings extends Serializable {

  onSeralisedInfoRecieved(serializedValue) {
    this.UserNotificationSettings = serializedValue.UserNotificationSettings ? serializedValue.UserNotificationSettings : this.getDefaultUserNotificationSettings();
  }

  getDefaultUserNotificationSettings() {
    let userNotificationSettingsObj = new Serializable();
    let setting = AlertStore.getNotificationSettings();
    userNotificationSettingsObj.IsAlertSoundOn = false;
    userNotificationSettingsObj.IsShowAlertsIcon = true;
    userNotificationSettingsObj.IsShowAlertNotifyWindow = setting.notifyInApp;

    return userNotificationSettingsObj;
  }

  getDataObject() {
    return {
      UserNotificationSettings: this.UserNotificationSettings
    };
  }

  getObjectMapKey() {
    return "notificationSettings";
  }
}

export default NotificationSettings;
