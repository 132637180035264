import AnnotationUtil from 'Utils/AnnotationUtil';
import { PrintMode } from '../../../../print/printmode';
import { safe } from '../../../ErrorModel';
import { ShareAccessType } from '../../../../Constants/ShareConstants';
import textWidth from "text-width";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { AnnotationConstant, TemporaryId } from "Constants/AnnotationConstants.js";
import {
    getAnnotationIsDrawing, getAnnotationChartDraw, getAnnotationSelectedId, getFibonacciLine, getAnnotationMenu, getTargetLayer, getActiveAnchor,
    getLayersList, getAnnotationDimension, getAnnotationSelected
} from '../../../../Reducers/AnnotationReducers/selectors';
import { takeLatest, select, put, call, takeEvery } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;
function* handleClick({ e }) {
    try {
        let fibonacciLine = null;
        const annotationMenu = yield select(getAnnotationMenu);
        let isDrawing = yield select(getAnnotationIsDrawing);
        let annotSelectedId = yield select(getAnnotationSelectedId);
        const targetLayer = yield select(getTargetLayer);
        const fibonacciLines = yield select(getFibonacciLine);

        if (isDrawing) {
            isDrawing = !isDrawing
            fibonacciLine = fibonacciLines.find((itm) => itm.id === annotSelectedId);
            yield call(updateFibonacciLine, isDrawing, fibonacciLine);
            yield safe(call(saveFibonacciLine), "GraphicsFibonacciSaga.js", "saveFibonacciLine");
            return;
        }

        if (e.target !== e.target.getStage()) {
            annotSelectedId = e.target.id();
            fibonacciLine = fibonacciLines.find((itm) => itm.id === annotSelectedId);
            yield call(updateFibonacciLine, isDrawing, fibonacciLine, annotSelectedId);
            return;
        }

        const { fibonacciLevels, fibonacciExtensions, isLevelLabelsChecked, isPriceLabelsChecked, isExtensionsChecked } = annotationMenu.FibonacciModelInfo;
        const lineStart = { x: e.evt.layerX, y: e.evt.layerY };

        const geometry = yield call(makeGeometryFromPoints, lineStart, lineStart, fibonacciLevels, fibonacciExtensions, isLevelLabelsChecked, isPriceLabelsChecked, isExtensionsChecked);

        const annotId = TemporaryId.Id + fibonacciLines.length;
        fibonacciLine = {
            ...annotationMenu.FibonacciModelInfo,
            id: annotId,
            layerID: targetLayer.layerID,
            x: lineStart.x,
            y: lineStart.y,
            startMouseX: 0,
            startMouseY: 0,
            currentMouseX: 0,
            currentMouseY: 0,
            endMouseX: 0,
            endMouseY: 0,
            startExtendPoint: null,
            endExtendPoint: null,
            geometry: geometry,
            isReadOnlyAnnotation: false
        }
        isDrawing = true;
        yield call(addFibonacciLine, isDrawing, fibonacciLine);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsFibonacciSaga.js, handleClick ${error}`);
    }
}

function* handleMouseMove({ e, isEndExtend, isStartExtend, isLabel, reComputeOnSelection, isAnchorDrag }) {
    try {
        let fibonacciLine = null;
        const { isDrawing, isDragging } = yield select(getAnnotationChartDraw);
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const fibonacciLines = yield select(getFibonacciLine);
        const annotationMenu = yield select(getAnnotationMenu);
        const activeAnchor = yield select(getActiveAnchor);
        const chartDimension = yield select(getAnnotationDimension);

        const { startExtendChecked, endExtendChecked, fibonacciLevels, fibonacciExtensions, isLevelLabelsChecked, isPriceLabelsChecked, isExtensionsChecked } = annotationMenu.FibonacciModelInfo;

        if ((isDrawing || isDragging || reComputeOnSelection || isAnchorDrag) && fibonacciLines.length > 0) {

            const curLine = fibonacciLines.find((itm) => itm.id === annotSelectedId);
            let lineStart = {}, lineEnd = {}, currMouse = {};

            if (isDragging) {
                const dragPos = {
                    x: e.currentTarget.x(), y: e.currentTarget.y()
                }

                if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                    if (isEndExtend) {
                        e.currentTarget.attrs.x = curLine.endExtendPoint.x;
                        e.currentTarget.attrs.y = curLine.endExtendPoint.y;
                    }
                    else if (isStartExtend) {
                        e.currentTarget.attrs.x = curLine.startExtendPoint.x;
                        e.currentTarget.attrs.y = curLine.startExtendPoint.y;
                    }
                    else {
                        e.currentTarget.attrs.x = curLine.x;
                        e.currentTarget.attrs.y = curLine.y;
                    }
                    return;
                }
                else if (isEndExtend) { // dragged element is end extended line
                    const diff = { x: dragPos.x - curLine.endMouseX, y: dragPos.y - curLine.y };
                    const xIsPositive = curLine.endMouseX >= curLine.x;

                    if (xIsPositive) {
                        lineStart = {
                            x: curLine.x + diff.x, y: dragPos.y
                        }
                        lineEnd = {
                            x: dragPos.x, y: curLine.endMouseY + diff.y
                        }
                    }
                    else {
                        lineStart = {
                            x: dragPos.x, y: dragPos.y
                        }
                        lineEnd = {
                            x: curLine.x + diff.x, y: curLine.endMouseY + diff.y
                        }
                    }
                }
                else if (isStartExtend) {
                    const diff = { x: dragPos.x - curLine.x, y: dragPos.y - curLine.y };
                    const xIsPositive = curLine.endMouseX >= curLine.x;

                    if (xIsPositive) {
                        lineStart = {
                            x: dragPos.x, y: dragPos.y
                        }
                        lineEnd = {
                            x: curLine.endMouseX + diff.x, y: curLine.endMouseY + diff.y
                        }
                    }
                    else {
                        lineStart = {
                            x: curLine.endMouseX + diff.x, y: dragPos.y
                        }
                        lineEnd = {
                            x: dragPos.x, y: curLine.endMouseY + diff.y
                        }
                    }
                }
                else if (isLabel) {
                    const splitData = e.currentTarget.attrs.fibLevel.split('-');
                    const level = parseInt(splitData[0]);
                    const bufferHeight = parseInt(splitData[1]);
                    const diff = { x: dragPos.x - curLine.geometry[level].textInfo.x, y: dragPos.y - curLine.geometry[level].lineInfo.y + bufferHeight };
                    lineStart = {
                        x: curLine.x + diff.x, y: curLine.y + diff.y
                    }
                    lineEnd = {
                        x: curLine.endMouseX + diff.x, y: curLine.endMouseY + diff.y
                    }
                }
                else {
                    // normal line and extended line have same x & y origin values.
                    const diff = { x: dragPos.x - curLine.x, y: dragPos.y - curLine.y };
                    lineStart = {
                        x: dragPos.x, y: dragPos.y
                    }
                    lineEnd = {
                        x: curLine.endMouseX + diff.x, y: curLine.endMouseY + diff.y
                    }
                }
            }
            else if (reComputeOnSelection) {
                lineStart = {
                    x: curLine.x, y: curLine.y
                }
                lineEnd = {
                    x: curLine.endMouseX, y: curLine.endMouseY
                }
            }
            else if (isAnchorDrag) {
                // Fix for PANWEB-6207
                if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                    return;
                }

                if (e.currentTarget !== undefined && e.currentTarget !== null) {
                    e.currentTarget.getStage().container().style.cursor = 'all-scroll';
                }

                if (activeAnchor === "start") {
                    lineStart = {
                        x: e.evt.layerX,
                        y: e.evt.layerY
                    }
                    lineEnd = {
                        x: curLine.endMouseX,
                        y: curLine.endMouseY
                    }
                }
                else if (activeAnchor === "end") {
                    lineStart = {
                        x: curLine.x,
                        y: curLine.y
                    }
                    lineEnd = {
                        x: e.evt.layerX,
                        y: e.evt.layerY
                    }
                }
            }
            else {
                lineStart = {
                    x: curLine.x, y: curLine.y
                }
                lineEnd = {
                    x: e.evt.layerX, y: e.evt.layerY
                }
            }

            currMouse = {
                x: lineEnd.x - lineStart.x,
                y: lineEnd.y - lineStart.y
            }

            let startExtendPoint = null, endExtendPoint = null;
            const xIsPositive = lineEnd.x >= lineStart.x;

            const geometry = yield call(makeGeometryFromPoints, lineStart, lineEnd, fibonacciLevels, fibonacciExtensions, isLevelLabelsChecked, isPriceLabelsChecked, isExtensionsChecked);

            if (startExtendChecked) {
                if (xIsPositive) {
                    startExtendPoint = { x: lineStart.x, y: lineStart.y };
                }
                else {
                    startExtendPoint = { x: lineEnd.x, y: lineStart.y };
                }
            }
            if (endExtendChecked) {
                if (xIsPositive) {
                    endExtendPoint = { x: lineEnd.x, y: lineStart.y };
                }
                else {
                    endExtendPoint = { x: lineStart.x, y: lineStart.y };
                }
            }

            fibonacciLine = Object.assign(curLine, {
                x: lineStart.x,
                y: lineStart.y,
                currentMouseX: currMouse.x,
                currentMouseY: currMouse.y,
                endMouseX: lineEnd.x,
                endMouseY: lineEnd.y,
                geometry: geometry,
                startExtendPoint: startExtendPoint,
                endExtendPoint: endExtendPoint,
                fibonacciLevels: fibonacciLevels,
                fibonacciExtensions: fibonacciExtensions
            });
            yield call(updateFibonacciLine, isDrawing, fibonacciLine);
        }
        else {
            yield call(updateFibonacciLine, isDrawing, fibonacciLines);
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsFibonacciSaga.js, handleMouseMove ${error}`);
    }
}

function* makeGeometryFromPoints(lineStart, lineEnd, fibonacciLevels, fibonacciExtensions, isLevelLabelsChecked, isPriceLabelsChecked, isExtensionsChecked) {
    try {
        const priceScale = AnnotationUtil.getScale();
        const data = [];
        const lineWidth = lineEnd.x - lineStart.x;

        let xPlacement = lineStart.x + 10;
        if (lineEnd.x <= lineStart.x) {
            xPlacement = lineEnd.x + 10;
        }

        for (let i = 0; i < fibonacciLevels.length; i++) {
            const customPoint = yield safe(call(calculateCustomPoint, lineStart, lineEnd, fibonacciLevels[i], priceScale), "GraphicsFibonacciSaga.js", "calculateCustomPoint");
            const price = priceScale.ComputePrice(customPoint.y);
            const formattedText = yield safe(call(createFormattedPercentageText, fibonacciLevels[i], price, isLevelLabelsChecked, isPriceLabelsChecked), "GraphicsFibonacciSaga.js", "createFormattedPercentageText");
            const lineInfo = { x: customPoint.x, y: customPoint.y, isDotted: (i !== 0 && i !== 6), isExtensions: false };
            const textInfo = { x: xPlacement, y: customPoint.y - 15 - (lineWidth / 2), text: formattedText.text, width: formattedText.width };
            data.push({ lineInfo: lineInfo, textInfo: textInfo });
        }

        if (isExtensionsChecked) {
            for (let i = 0; i < fibonacciExtensions.length; i++) {
                const customPoint = yield safe(call(calculateCustomPoint, lineStart, lineEnd, fibonacciExtensions[i], priceScale), "GraphicsFibonacciSaga.js", "calculateCustomPoint");
                const price = priceScale.ComputePrice(customPoint.y);
                const formattedText = yield safe(call(createFormattedPercentageText, fibonacciExtensions[i], price, isLevelLabelsChecked, isPriceLabelsChecked), "GraphicsFibonacciSaga.js", "createFormattedPercentageText");
                const lineInfo = { x: customPoint.x, y: customPoint.y, isDotted: true, isExtensions: true };
                const textInfo = ({ x: xPlacement, y: customPoint.y - 15 - (lineWidth / 2), text: formattedText.text, width: formattedText.width });
                data.push({ lineInfo: lineInfo, textInfo: textInfo });
            }
        }
        return data;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsFibonacciSaga.js, makeGeometryFromPoints ${error}`);
    }
}

function calculateCustomPoint(lineStart, lineEnd, percent, priceScale) {
    const top = lineStart.y;
    const bottom = lineEnd.y;

    const topPrice = priceScale ? priceScale.ComputePrice(top) : 0;
    const bottomPrice = priceScale ? priceScale.ComputePrice(bottom) : 0;

    const difference = topPrice - bottomPrice;
    const finalPrice = (difference * percent / 100) + bottomPrice;

    const final = priceScale ? priceScale.ComputeY(finalPrice) : 0;

    return { x: lineEnd.x - lineStart.x, y: final };
}

function createFormattedPercentageText(percentage, price, isLevelLabelsChecked, isPriceLabelsChecked) {
    const percentageText = isLevelLabelsChecked ? (`${percentage.toFixed(2)}%`) : "";
    const priceText = isPriceLabelsChecked ? Number(price.toFixed(2)) : "";
    let text = percentageText + priceText;
    if (isLevelLabelsChecked && isPriceLabelsChecked) {
        text = `${percentageText} (${priceText})`;
    }

    const width = textWidth(text, {
        family: "calibri",
        size: 12
    });

    return { text: text, width: width };
}

function* updateFibonacciLine(isDrawing, fibonacciLine) {
    try {
        yield put({
            type: ActionTypes.HANDLE_FIBONACCI_LINE_DRAW_SUCCESS,
            fibonacciLine: fibonacciLine
        });
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: fibonacciLine
        });

        let layersList = yield select(getLayersList);
        layersList = JSON.parse(JSON.stringify(layersList));
        const selectedAnnot = yield select(getAnnotationSelected);
        for (const x in layersList) {
            layersList[x].layerID === selectedAnnot.layerID ? layersList.push(layersList.splice(x, 1)[0]) : 0;
        }
        yield put({
            type: ActionTypes.UPDATE_LAYERS_LIST,
            LayersList: layersList
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsFibonacciSaga.js, updateFibonacciLine ${error}`);
    }
}

function* addFibonacciLine(isDrawing, fibonacciLine) {
    try {
        yield put({
            type: ActionTypes.HANDLE_FIBONACCI_LINE_CLICK_SUCCESS,
            fibonacciLine: fibonacciLine,
        });
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: fibonacciLine
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsFibonacciSaga.js, addFibonacciLine ${error}`);
    }
}

function* saveFibonacciLine() {
    yield put({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    });
}

function* processFibLineData({ data }) {
    try {
        const lineStart = {
            x: data.startPoint.x,
            y: data.startPoint.y
        }
        const lineEnd = {
            x: data.endPoint.x,
            y: data.endPoint.y
        }
        const currMouse = {
            x: lineEnd.x - lineStart.x,
            y: lineEnd.y - lineStart.y
        }

        const fibonacciLevels = [100.0, 76.4, 61.8, 50.0, 38.2, 23.6, 0.0];
        const fibonacciExtensions = [423.6, 361.8, 261.8, 161.8];
        const { foregroundColor, backgroundColor, penSize, textSize, hasLevelLabel, hasPriceLabel, isBorderVisible } = data;

        let startExtendPoint = null, endExtendPoint = null;
        const xIsPositive = lineEnd.x >= lineStart.x;

        if (data.isStartExtend) {
            if (xIsPositive) {
                startExtendPoint = { x: lineStart.x, y: lineStart.y };
            }
            else {
                startExtendPoint = { x: lineEnd.x, y: lineStart.y };
            }
        }
        if (data.isEndExtend) {
            if (xIsPositive) {
                endExtendPoint = { x: lineEnd.x, y: lineStart.y };
            }
            else {
                endExtendPoint = { x: lineStart.x, y: lineStart.y };
            }
        }

        const geometry = yield call(makeGeometryFromPoints, lineStart, lineEnd, fibonacciLevels, fibonacciExtensions, hasLevelLabel, hasPriceLabel, isBorderVisible);
        const convertedForegroundColor = ThemeHelper.convertArgbToHex(foregroundColor);
        const convertedBackgroundColor = ThemeHelper.convertArgbToHex(backgroundColor);
        const layersList = yield select(getLayersList);
        const isReadOnlyAnnotation = layersList.filter((i) => i.layerID === data.layerID)[0].shareAccess === ShareAccessType.SHARE_READONLY;

        const fibonacciLine = {
            id: data.annotationID,
            layerID: data.layerID,
            type: data.annotationType,
            x: lineStart.x,
            y: lineStart.y,
            startMouseX: 0,
            startMouseY: 0,
            currentMouseX: currMouse.x,
            currentMouseY: currMouse.y,
            endMouseX: lineEnd.x,
            endMouseY: lineEnd.y,
            color: convertedForegroundColor.color,
            weight: penSize,
            extensionLineColor: `P${convertedBackgroundColor.color.substring(1)}`,
            extensionLineWeight: textSize,
            startExtendChecked: data.isStartExtend,
            endExtendChecked: data.isEndExtend,
            upperLineX1: data.isStartExtend ? 0 : 40,
            upperRetracementLineX1: data.isStartExtend ? 0 : 40,
            lowerRetracementLineX1: data.isStartExtend ? 0 : 40,
            lowerLineX1: data.isStartExtend ? 0 : 40,
            upperLineX2: data.isEndExtend ? 266 : 220,
            upperRetracementLineX2: data.isEndExtend ? 266 : 220,
            lowerRetracementLineX2: data.isEndExtend ? 266 : 220,
            lowerLineX2: data.isEndExtend ? 266 : 220,
            fibonacciLevels: fibonacciLevels,
            fibonacciExtensions: fibonacciExtensions,
            isLevelLabelsChecked: hasLevelLabel,
            isPriceLabelsChecked: hasPriceLabel,
            isExtensionsChecked: isBorderVisible,
            geometry: geometry,
            startExtendPoint: startExtendPoint,
            endExtendPoint: endExtendPoint,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            isDeleted: false,
            isSelected: false,
            isReadOnlyAnnotation: isReadOnlyAnnotation
        }

        yield put({
            type: ActionTypes.HANDLE_FIBONACCI_LINE_CLICK_SUCCESS,
            fibonacciLine: fibonacciLine
        });
        yield put({
            type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
            annotation: fibonacciLine.id
        });

        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsFibonacciSaga.js, processFibLineData ${error}`);
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        PrintMode.printing && console.log("PRINT:--Tracker--*** Data processing Error in GraphicsFibonacciSaga *** for -", TimeTrackingWindow.currentSymbol);
    }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchHandleFibonacciLineClick() {
    yield takeLatest(ActionTypes.HANDLE_FIBONACCI_LINE_CLICK, handleClick);
};

export function* watchHandleFibonacciLineDraw() {
    yield takeLatest(ActionTypes.HANDLE_FIBONACCI_LINE_DRAW, handleMouseMove);
};

export function* watchProcessFibLineData() {
    yield takeEvery(ActionTypes.PROCESS_FIBONACCI_DATA, processFibLineData);
};