import AnnotationUtil from "../../../Utils/AnnotationUtil";
import DatagraphHelper from "../../../Utils/DatagraphHelper";
import DateHelper from "../../../Utils/TimeLineHelper/Datehelper";
import DayOfWeek from "../../../Constants/DayOfWeek";
import { eventChannel } from "redux-saga";
import { getCurrencyData, getCurrencyReducerState } from "../../../Reducers/CurrencyConversionReducer/selectors";
import GraphType from "../../../Constants/GraphType";
import { initTimeLine } from "./TimeLineSaga";
import moment from "moment";
import { prepareVolumeData } from "../../../Actions/VolumeChartAction";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { processPriceChart } from "./PriceChartSaga";
import { reDrawAnnotationsOnChartResize } from "../../../Actions/AnnotationActions";
import { safe } from "../../ErrorModel";
import StockMarketUtil from "../../../Utils/StockMarketUtil";
import StringUtil from "../../../Utils/StringUtil";
import WebSyncUtil from "../../../Utils/WebSyncUtil";
import WorkSpaceRealTimePrices from "../../../Utils/RealTimeHelper/WorkSpaceRealTimePrices";
import { call, fork, put, select, take, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, priceChartReducerselect } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";

const { ActionTypes } = PriceChartConstants;
function setDdePriceData(quote, states, dataGraphStates, workSpaceStates, useLocal = true, liveData = false) {
    let high = quote.High;
    let low = quote.Low;
    const last = quote.Last;
    //let volume = (quote.Volume.HasValue ? quote.Volume.Value : quote.TradeSize);
    let volume = quote.Volume;// ? quote.Volume : quote.TradeSize;
    const tradeDate = quote.TradeTime;
    const aveVolume = quote.AvgVol;

    if (states === null || quote.Volume === undefined || quote.Volume < 1) {
        return;
    }

    if (!states.LastDdeData.DataAvailable || quote.Symbol !== states.Symbol) {
        states.LastDdeData.LiveData = false;
        return;
    }

    if (!dataGraphStates.isIntraday) {
        states.IdPushDown = false;
    }
    else {

        if (states.IntradayBusy) {
            return;
        }
        states.IdPushDown = true;
    }

    if (volume > 0) {
        let pVolume = volume;

        if (dataGraphStates.isIntraday) {
            if (states.PrevVolume === 0) {
                states.PrevVolume = pVolume;
            }

            states.LastDdeData.PrevDailyVolume = states.LastDdeData.DailyVolume;
            states.LastDdeData.DailyVolume = quote.DailyVolume;//pVolume;

            pVolume = states.IdPushDown && states.LastIdVolume ? states.LastIdVolume : 0;

            if (states.PrevVolume > 0 && volume > 0 && volume > states.PrevVolume && states.IdPushDown) {
                pVolume += (volume - states.PrevVolume);
            }

            states.LastIdVolume = WorkSpaceRealTimePrices.TradingFirstMinute(workSpaceStates) ? volume : pVolume;
            if (volume > 0) {
                states.PrevVolume = volume;
            }

            if (!states.firstTrade) {
                states.PrevHigh = last;
                states.PrevLow = last;
            }

            high = states.IdPushDown ? Math.max(last, states.PrevHigh) : last;
            low = states.IdPushDown ? Math.min(last, states.PrevLow) : last;
            states.PrevHigh = high;
            states.PrevLow = low;
        }
        volume = pVolume;
    }

    const conversionFactor = 1.00;

    /*    if (states.ConversionData != null && states.SymbolInfo.SymTypeEnum != SymbolType.INDEX && !states.ConversionData.ToCurrency.Equals(states.ConversionData.FromCurrency)) {
     CurrencyCode
     toCurrency = states.ConversionData.ToCurrency;
     if ((!toCurrency.Equals(CurrencyCode.DEF)) && (states.ConversionData.ConversionDictionary != null) && states.Gdata.HSFResults != null) {
     let currConvDate = DateUtil.GetDateOnly(states.Gdata.HSFResults.First().Date);
     states.ConversionData.ConversionDictionary.TryGetValue(currConvDate, conversionFactor);
     if (conversionFactor == 0) {
     conversionFactor = 1.00;
     }
     }
     if (states.ConversionData.FromCurrency.Equals(CurrencyCode.GBX)) {
     conversionFactor = conversionFactor * 100;
     }

     if (states.ConversionData.FromCurrency.Equals(CurrencyCode.ZAX)) {
     conversionFactor = conversionFactor * 100;
     }
     }*/
    // DDE Graph Updates

    //states.LastDdeData.IsESignal = WorkSpaceRealTimePrices.IsESignal;
    states.LastDdeData.Price = last * conversionFactor;
    if (!states.firstTrade) {
        states.LastDdeData.High = states.LastDdeData.Price;
        states.LastDdeData.Low = states.LastDdeData.Price;
    }
    states.LastDdeData.High = !dataGraphStates.isIntraday ? (states.LastDdeData.Volume > 0 ? Math.max(high * conversionFactor, states.LastDdeData.High)
        : Math.max(high * conversionFactor, states.LastDdeData.Price))
        : high * conversionFactor;
    states.LastDdeData.Low = !dataGraphStates.isIntraday ? (states.LastDdeData.Volume > 0 && states.LastDdeData.Low > 0 ? Math.min(low * conversionFactor, states.LastDdeData.Low)
        : Math.min(low * conversionFactor, states.LastDdeData.Price))
        : low * conversionFactor;
    if (!WorkSpaceRealTimePrices.IsIndicator(states.Symbol) && volume > 0) {
        states.LastDdeData.Volume = dataGraphStates.isIntraday ? states.LastIdVolume : volume;
    }

    states.LastDdeData.Symbol = states.Symbol;
    states.LastDdeData.AverageVolume = aveVolume;
    if (quote.VolRateDaily !== null && quote.VolRateDaily !== -101){
        states.LastDdeData.RTVolRate = quote.VolRateDaily;
    }
    if (quote.VolRateWeekly !== null && quote.VolRateWeekly !== -101){
        states.LastDdeData.RTWVolRate = quote.VolRateWeekly;
    }
    states.LastDdeData.LiveData = liveData;
    states.LastDdeData.Periodicity = dataGraphStates.periodicity;
    if (dataGraphStates.pricePanelData.SymbolInfo !== null) {
        const date = useLocal ? moment().tz(dataGraphStates.pricePanelData.SymbolInfo.MomentZoneID) : tradeDate;
        states.LastDdeData.Date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
    }
}
function getCurrencyFactor(targetCurrencyCode, currencyData) {
    let curfactor = 1;
    let cur = null;
    if (currencyData !== null && !StringUtil.isEmpty(targetCurrencyCode)){
      cur = currencyData.filter((c) => c.currencyCode === targetCurrencyCode)[0];
    }
    if (!StringUtil.isEmpty(cur)){ 
        curfactor = cur.currencyFactor;
    }
    return curfactor;
}

function convertQuoteToTargetCurrency(quote, targetCurrencyCode, currencyData) {
    let convfactor = 1;
    if (targetCurrencyCode === "GBX") {
        convfactor = getCurrencyFactor("GBP", currencyData) * 100;
    } else if (targetCurrencyCode === "ZAX") {
        convfactor = getCurrencyFactor("ZAR", currencyData) * 100;
    } else {
        convfactor = getCurrencyFactor(targetCurrencyCode, currencyData);
    }
    quote.DailyHigh = quote.DailyHigh * convfactor;
    quote.DailyLow = quote.DailyLow * convfactor;
    quote.High = quote.High * convfactor;
    quote.Last = quote.Last * convfactor;
    quote.Low = quote.Low * convfactor;
    quote.Open = quote.Open * convfactor;
    quote.PrevDayClose = quote.PrevDayClose * convfactor;
    quote.YrHigh = quote.YrHigh * convfactor;
    quote.Vwap = quote.Vwap * convfactor;
}
function* updateLastPriceData(quote) {
    try{
        const state = yield select(priceChartReducerselect);
        const dataGraphStates = yield select(getDatagraphStates);
        const { SymbolInfo, pricePanelData, isHistoric} = dataGraphStates;
        const { IntradaySourceType, TradeClose, TradeOpen } = state;
        const workSpaceStates = { SymbolInfo, IntradaySourceType, TradeClose, TradeOpen}
        const { isCurrencyConversionChecked, selectedCurrencyCode, defaultCurrency, currencyData } = yield select(getCurrencyReducerState);
        const currencyStr = isCurrencyConversionChecked ? selectedCurrencyCode : defaultCurrency;
        convertQuoteToTargetCurrency(quote, currencyStr, currencyData);
        const tradeTime = moment(quote.TradeTime).tz(SymbolInfo.MomentZoneID);
        const lastTradeDate = new Date(tradeTime.format('Y'), parseInt(tradeTime.format('M')) - 1, tradeTime.format('D'), tradeTime.format('HH'), tradeTime.format('mm'), 0);
        quote.TradeTime = lastTradeDate;
        //TODO
        //state.isHistoric = false;
        if (!state || isHistoric || pricePanelData === null || state.Symbol !== quote.Symbol || state.pauseRT) {
            return;
        }
        SymbolInfo.PPrice = quote.PrevDayClose;
        SymbolInfo.CPrice = quote.Last;
        if (state.endDate.getDate() !== quote.TradeTime.getDate()) {
            if (WorkSpaceRealTimePrices.checkTradingHours(workSpaceStates, quote.TradeTime)) {
                const year = quote.TradeTime.getFullYear();
                const month = quote.TradeTime.getMonth();
                const day = quote.TradeTime.getDate();
                const endDate = new Date(year, month, day);
                const openTime = DateHelper.parseJsonDate(SymbolInfo.ExchangeOpenTime, SymbolInfo.MomentZoneID);
                const closeTime = DateHelper.parseJsonDate(SymbolInfo.ExchangeCloseTime, SymbolInfo.MomentZoneID);
                SymbolInfo.LastTradeDate = new Date(year, month, day);
                state.LastTradeDate = DateHelper.parseJsonDate(SymbolInfo.LastTradeDate, SymbolInfo.MomentZoneID);
                state.TradeOpen = new Date(state.LastTradeDate.getFullYear(), state.LastTradeDate.getMonth(), state.LastTradeDate.getDate(), openTime.getHours(), openTime.getMinutes());
                state.TradeClose = new Date(state.LastTradeDate.getFullYear(), state.LastTradeDate.getMonth(), state.LastTradeDate.getDate(), closeTime.getHours(), closeTime.getMinutes());
                state.endDate = endDate;
                yield call(processPriceChart, true)
                yield put(prepareVolumeData());
                return;
            }
        }
    
    
        if (state.UserEndDate === null && state.LastTradeDate < lastTradeDate) //new Date(quote.TradeTime)// New Date started on the Stream
        {
            SymbolInfo.LastTradeDate = lastTradeDate;// new Date(quote.TradeTime);
            if (!WorkSpaceRealTimePrices.IsTradingHours(workSpaceStates)) {
                state.firstTrade = false;
                state.LastDdeData.LiveData = false;
                return;
            }
            //RaiseRefreshChartOnNewDay(quote.TradeTime.getDate());
            return;
        }
    
        if (!WorkSpaceRealTimePrices.IsTradingHours(workSpaceStates)) {
            state.firstTrade = false;
            state.LastDdeData.LiveData = false;
            return;
        }
        if (!state.firstTrade && WorkSpaceRealTimePrices.IsTradingHours(workSpaceStates)) {
            const year = quote.TradeTime.getFullYear();
            const month = quote.TradeTime.getMonth();
            const day = quote.TradeTime.getDate();
            const endDate = new Date(year, month, day);
            state.firstTrade = true;
            dataGraphStates.endDate = endDate;
            yield call(processPriceChart, true);
            yield put(prepareVolumeData());
            return;
        }
    
        if (state.LastQuoteTime > quote.TradeTime) { // Out Of Sync
            return;
        }
    
        state.LastQuoteTime = quote.TradeTime;
    
        if (state.LastDdeData.QuoteSymbol === null || !quote.Symbol === state.LastDdeData.QuoteSymbol) {
            return;
        }
    
        //if (!state.LastDdeData.DataAvailable) // If DDE Data not set from the server (After Hours it could be empty) Set this data with receiving quote
        //{
        //state.LastDdeData.High = Math.max(quote.DailyHigh, state.LastDdeData.High);
        //state.LastDdeData.Low = Math.min(quote.DailyLow, state.LastDdeData.Low);
        state.LastDdeData.High = quote.DailyHigh;
        state.LastDdeData.Low = quote.DailyLow;
        state.LastDdeData.Price = quote.Last;
        state.LastDdeData.Volume = state.ShowVolume ? (quote.Volume !== null ? quote.Volume : 0) : 0;
        state.CumVolume = quote.Volume !== null ? quote.Volume : 0;
        if (quote.VolRateDaily !== null && quote.VolRateDaily !== -101) {
            state.LastDdeData.RTVolRate = quote.VolRateDaily;
        }
        if (quote.VolRateWeekly !== null && quote.VolRateWeekly !== -101) {
            state.LastDdeData.RTWVolRate = quote.VolRateWeekly;
        }
        state.LastDdeData.DataAvailable = true;
        state.LastDdeData.LiveData = true;
        //}
        setDdePriceData(quote, state, dataGraphStates, workSpaceStates, false, true);
        // const prState = PriceChartStore.getState();
        // DatagraphStore.updateVolumeData(prState.volPct, true, state.HasVolume);
        // DatagraphStore.onQuoteRecieved(quote);
        // PriceChartStore.CalcFrDataOnQuote()
    }
    catch(error){
        console.log(`Error occurs in PriceChartSaga.js, in updateLastPriceData ${error}`, error);
    }
}

function updateLastNode(dataGraphStates, _state) {
    const { nodeCount, pricePanelData, startXPoint, padding} = dataGraphStates;
    // let xAxis = startXPoint;
    const HsfResults = pricePanelData.HsfData.HSFResults;
    const lastDataNode = Math.min(HsfResults.length - 1, nodeCount);
    let yHigh = _state.scale.ComputeY(HsfResults[lastDataNode].High);
    let yLow = _state.scale.ComputeY(HsfResults[lastDataNode].Low);
    // let graphPointer = padding;

    if (!_state.HiLowPoints.allPoints || !_state.HiLowPoints.allPoints[0] || _state.HiLowPoints.allPoints.length < 1){ 
        return;
    }

    // for (let j = this.firstNodeIndex; j < lastDataNode; j++) {
    //     if (!HsfResults[graphPointer].IsVisible) {
    //         xAxis -= nodeWidth;
    //         graphPointer++;
    //         continue;
    //     }
    //     break;
    // }
    const yPrice = _state.scale.ComputeY(HsfResults[padding].Close);
    if (_state.periodicity === GraphType.Daily || _state.isIntraday) {
        yHigh = _state.scale.ComputeY(HsfResults[padding].High);
        yLow = _state.scale.ComputeY(HsfResults[padding].Low);
    } else {
        yHigh = Math.min(_state.scale.ComputeY(HsfResults[padding].High), _state.HiLowPoints.allPoints[0].yHigh);
        yLow = Math.max(_state.scale.ComputeY(HsfResults[padding].Low), _state.HiLowPoints.allPoints[0].yLow);
    }
    const upTick = (HsfResults[padding + 1] && HsfResults[padding + 1].Close) ? HsfResults[padding].Close > HsfResults[padding + 1].Close : true;
    const info =
    {
        Date: HsfResults[padding].Date,
        yPrice: yPrice,
        yHigh: yHigh,
        yLow: yLow,
        xAxis: startXPoint,
        UpTick: upTick,
        IsVisible: (HsfResults[padding]._volume && HsfResults[padding]._volume > 0) || !pricePanelData.HsfData.HasVolume,
        graphData: HsfResults[padding],
        corpIndex: [],
        IdeasTr: []
    };
    if (upTick) {
        if (_state.HiLowPoints.highPoints.length > 0 && _state.HiLowPoints.highPoints[0].Date.getTime() === info.Date.getTime()) {
            _state.HiLowPoints.highPoints[0] = info;
        } else {
            _state.HiLowPoints.highPoints.unshift(info);
        }
        if (_state.HiLowPoints.lowPoints.length > 0 && _state.HiLowPoints.lowPoints[0].Date.getTime() === info.Date.getTime()) {
            _state.HiLowPoints.lowPoints.splice(0, 1);
        }
    } else {
        if (_state.HiLowPoints.lowPoints.length > 0 && _state.HiLowPoints.lowPoints[0].Date.getTime() === info.Date.getTime()) {
            _state.HiLowPoints.lowPoints[0] = info;
        } else {
            _state.HiLowPoints.lowPoints.unshift(info);
        }
        if (_state.HiLowPoints.highPoints.length > 0 && _state.HiLowPoints.highPoints[0].Date.getTime() === info.Date.getTime()) {
            _state.HiLowPoints.highPoints.splice(0, 1);
        }
    }
    if (_state.HiLowPoints.allPoints.length > 0 && _state.HiLowPoints.allPoints[0].Date.getTime() === info.Date.getTime()) {
        _state.HiLowPoints.allPoints[0] = info;
    } else {
        _state.HiLowPoints.allPoints.unshift(info);
    }
}

const intervalConst = {
    [GraphType.Intraday1Min]: 1,
    [GraphType.Intraday5Min]: 5,
    [GraphType.Intraday10Min]: 10,
    [GraphType.Intraday15Min]: 15,
    [GraphType.Intraday30Min]: 30,
    [GraphType.Intraday60Min]: 60,

}
function* OnServerTickReceived() {
    try{
        const states = yield select(priceChartReducerselect);
        const dataGraphStates = yield select(getDatagraphStates)
        const { IntradaySourceType, TradeClose, TradeOpen} = states
        const { isHistoric, headerData, isIntraday, periodicity, padding, pricePanelData, SymbolInfo, InitialBufferSize, nodeCount, IsNASDAQBasic } = dataGraphStates;
        const workSpaceStates = { SymbolInfo, IntradaySourceType, TradeClose, TradeOpen}
        const commonStock = SymbolInfo ? DatagraphHelper.isCommonStock(SymbolInfo)  : true;
        const domesticStock = SymbolInfo ? DatagraphHelper.isDomesticStock(SymbolInfo) : true;
        if (!commonStock || isHistoric){ 
            return;
        }
    
        if (states.LastDdeData.Symbol !== states.Symbol){ 
            return;
        }
        if (!WorkSpaceRealTimePrices.IsTradingHours(workSpaceStates)) {
            states.firstTrade = false;
            states.LastDdeData.LiveData = false;
            states.Restart = domesticStock;
            return;
        }
        const timeZoneID = SymbolInfo.MomentZoneID !== null ? SymbolInfo.MomentZoneID : "US/Eastern";
        const currentSystemDate = WorkSpaceRealTimePrices.getCurrentTime(timeZoneID);
        const currentStreamingDate = new Date(currentSystemDate.getFullYear(), currentSystemDate.getMonth(), currentSystemDate.getDate(), 0, 0, 0);
        const blockOutDates = states.BlackOutDates;
        const blkIndex = blockOutDates && blockOutDates.length > 0 ? blockOutDates.findIndex((k) => (k.Date.getTime() === currentStreamingDate.getTime())) : -1;
    
        let lastEndDate = states.LastDdeData.Date;
    
        if (WorkSpaceRealTimePrices.IsTradingHours(workSpaceStates) &&
            states.Restart && //lastEndDate < currentStreamingDate &&
            blkIndex < 0 &&
            currentStreamingDate.getDay() > DayOfWeek.Sunday &&
            currentStreamingDate.getDay() < DayOfWeek.Saturday && 
            domesticStock) {
            states.Restart = false;
            window.location.reload(true);
        }
    
        if (states.LastDdeData.Symbol === states.Symbol) {
            if (dataGraphStates.isIntraday && SymbolInfo.zoneid.length > 1) {
                const endDate = moment().tz(SymbolInfo.MomentZoneID);
                lastEndDate = new Date(endDate.format('Y'), parseInt(endDate.format('M')) - 1, endDate.format('D'), endDate.format('HH'), endDate.format('mm'), 0);
            }
        }

        if (isIntraday) {
            if (!states.LastDdeData.DataAvailable ||
                states.LastDdeData.Price <= 0 ||
                states.LastDdeData.Periodicity !== periodicity || !(states.isPriceDataReady)) {
                return;
            }
        }
        else {
            if (!states.LastDdeData.LiveData || !states.LastDdeData.DataAvailable ||
                states.LastDdeData.Price <= 0 ||
                states.LastDdeData.Periodicity !== periodicity || !(states.isPriceDataReady)) {
                return;
            }
        }
    
        const Price = states.LastDdeData.Price;
        let High = states.LastDdeData.High;
        let Low = states.LastDdeData.Low;
        const showvol = states.ShowVolume;
        const chartNodes = pricePanelData.HsfData.HSFResults;
        const firstNode = chartNodes[padding];
        let previousNode = firstNode;
    
        if (chartNodes.length > padding){
            previousNode = chartNodes[padding + 1];
        }
        const positiveNode = previousNode ? firstNode.Close >= previousNode.Close : true;
    
        let Volume = !states.ShowVolume ? states.LastDdeData.PrevVolume : states.LastDdeData.Volume;
    
        let index = 0;
        if (!WorkSpaceRealTimePrices.IsTradingHours(workSpaceStates)) {
            states.firstTrade = false;
            return;
        }
        pricePanelData.HsfData.RTVolRate = states.LastDdeData.RTVolRate;
        pricePanelData.HsfData.RTWVolRate = states.LastDdeData.RTWVolRate;
        if (isIntraday) {
            const pushDown = IsNASDAQBasic || states.IdPushDown;
            if (!pushDown || !pricePanelData ||
                pricePanelData.HsfData.HSFResults === null ||
                SymbolInfo === null ||
                states.IntradayBusy){
                return;
            }
    
            let cDate = lastEndDate;//states.LastDdeData.Date;
            //int interval;
    
            if (periodicity !== GraphType.Intraday1Min){
                cDate = StockMarketUtil.GetNextDate(periodicity, cDate);
            }
    
            //if(lastEndDate.getTime() === firstNode.Date.getTime()) {
            //  if (firstNode && firstNode.High === states.LastDdeData.High
            //        && firstNode.Low === states.LastDdeData.Low
            //        && firstNode.Close === states.LastDdeData.Price
            //        && firstNode._volume === states.LastDdeData.Volume) {
            //      return;
            //  }
            //}
    
            const firstItem = pricePanelData.HsfData.HSFResults[padding];
            const idDate = firstItem !== null ? firstItem.Date : cDate;
            const openTime = states.TradeOpen;
            const closeTime = states.TradeClose;
            const beginTrading = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), openTime.getHours(), openTime.getMinutes(), 0);
            const endTrading = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), closeTime.getHours(), closeTime.getMinutes(), 0);
    
            if (states.PrevDay.getFullYear() < 2000){
                states.PrevDay = idDate;
            }
            if (cDate > idDate && !states.IntradayBusy &&
                pushDown && cDate <= endTrading && cDate >= beginTrading) {
                    states.IntradayBusy = true;
    
                const idData = pricePanelData.HsfData.HSFResults;
    
                if (states.PrevDay.getDate() !== states.LastDdeData.Date.getDate()) {
                    states.LastIdVolume = 0;
                    states.PrevHigh = Price;
                    states.PrevLow = Price;
                    states.PrevVolume = 0;
                    states.PrevDay = states.LastDdeData.Date;
                }
                const delayed = states !== null && SymbolInfo !== null && WorkSpaceRealTimePrices.Delayed(SymbolInfo.MomentZoneID, idData[padding].Date);
                if (!states.firstTrade) {
                    idData[padding]._volume = states.LastDdeData.Volume;
                    idData[padding].High = Price;
                    idData[padding].Low = Price;
                    idData[padding].Close = Price;
                    idData[padding].UpTick = true;
    
    
                    idData[padding].ShowVol = (showvol || !delayed) && idData[padding]._volume > 1;
    
                    if (idData.length > 1) {
                        idData[padding].UpTick = idData[padding].Close >= idData[padding + 1].Close;
                    }
                } else {
                    idData[padding].High = firstNode.High;
                    idData[padding].Low = firstNode.Low;
                    idData[padding].Close = firstNode.Close;
                    idData[padding]._volume = firstNode._volume > 0 ? firstNode._volume : idData[padding]._volume;
                    idData[padding].UpTick = true;
    
                    idData[padding].ShowVol = (showvol || !delayed) && idData[padding]._volume > 1;
    
                    if (idData.length > 1){
                        idData[padding].UpTick = idData[padding].Close >= idData[padding + 1].Close;
                    }
                }
                //Modify array with latest prices
                //if (idData[0].Volume >= 1 || states.LastIdVolume >= 0)
                //{
                //    idData[0].High = (float)Math.Max(states.PrevHigh, Price);
                //    idData[0].Low = (float)Math.Min(states.PrevLow, Price);
                //    idData[0].Close = (float)Price;
                //    idData[0].Volume = idData[0].Volume > 1 ? idData[0].Volume : states.LastIdVolume > 1 ? (float)states.LastIdVolume : -1;
                //    idData[0].UpTick = true;
    
                //    let delayed = states != null && states.SymbolInfo != null && WorkSpaceRealTimePrices.Delayed(states.SymbolInfo.ZoneID, idData[0].Date);
    
                //    idData[0].ShowVol = (showvol || !delayed) && idData[0].Volume > 1;
    
                //    if (idData.Count() > 1)
                //        idData[0].UpTick = idData[0].Close >= idData[1].Close;
                //}
    
    
                states.LastIdVolume = states.LastDdeData.PrevDailyVolume > 0 && states.LastDdeData.DailyVolume - states.LastDdeData.PrevDailyVolume > 0
                    ? states.LastDdeData.DailyVolume - states.LastDdeData.PrevDailyVolume
                    : (Price !== firstNode.Close ? 1 : 0);
                //states.LastIdVolume = 0;
    
                if (Price > 0) {
                    if (!states.firstTrade) {
                        if (states.LastDdeData.Volume < 1){ 
                            return;
                        }
                        High = Price;
                        Low = Price;
                        states.firstTrade = true;
                    }
                    states.PrevHigh = Price;
                    states.PrevLow = Price;
                }
    
                states.PrevDay = states.LastDdeData.Date;
    
                const nextNode = cDate;
                let prevNode = idData[padding].Date;
                const prevPrice = idData[padding].Close;
                prevNode = StockMarketUtil.GetNextDate(periodicity, prevNode);
    
                if (nextNode > prevNode) {
                    const upTick = Price >= prevPrice;
                    const iDay = {
                        High: Price,
                        Low: Price,
                        Close: Price,
                        _volume: states.LastIdVolume,
                        Date: nextNode,
                        UpTick: upTick,
                        ShowVol: true,
                        IsVisible: true,
                        insiderBuy: 0,
                        insiderBuySell: 0,
                        insiderSell: 0
                    };
                    const bufferDate = new Date(idData[0].Date);
                    const bufferDay = {
                        IsVisible: false,
                        Date: new Date(bufferDate.setMinutes(bufferDate.getMinutes() + intervalConst[periodicity]))
                    };
    
                    idData[padding - 1] = iDay;
                    idData.unshift(bufferDay);
                }
                else {
                    idData[padding].Date = nextNode;
                }
    
                yield call(SetupLastDdeData, states, dataGraphStates, idData, true);
    
                if (idData !== null) {
                    //     For intraday Timeline generates for every minute
                    //     if (states.TimeLine == null);
                    const endDate = idData[4].Date;
                    // yield put(initTimeLine(endDate, nodeCount, InitialBufferSize, false, false, false))
                    yield call(initTimeLine, {nodeCount, endDate, InitialBufferSize, isMountainChart: false, isComparisionChart: false, isInitial: false});
                }
    
                //Redraw price volume areas for intraday
                if (states === null){ 
                    return;
                }
                if (headerData) {
                    headerData.MarketCap = headerData.Shares * pricePanelData.HsfData.HSFResults[padding].Close;
                    if (headerData.shares2 && headerData.shares2 > 0) {
                        headerData.marketCap2 = headerData.shares2 * pricePanelData.HsfData.HSFResults[padding].Close;
                    }
    
                    if (headerData && headerData.companyName) {
                        yield put({type: ActionTypes.PREPARE_PDR_DATA, isStreamingProc: true});
                    }
                }
                states.IntradayBusy = false;
                states.PickNewLH = true;
                if(!dataGraphStates.isReceivedQuote){
                    yield put({
                        type: ActionTypes.UPDATE_IS_RECEIVED_QUOTE,
                        isReceivedQuote: true
                    });
                }
                yield call(processPriceChart);
                yield yield put(prepareVolumeData());
                
                if (!AnnotationUtil.isAnnotationApiCallInitiated){
                    yield put(reDrawAnnotationsOnChartResize());
                }
                return;
            }
        }
        else {
            if (!dataGraphStates.isReceivedQuote) {
                yield put({
                    type: ActionTypes.UPDATE_IS_RECEIVED_QUOTE,
                    isReceivedQuote: true
                });
            }
        }
        if (states.PickNewLH) {
            states.PrevHigh = Price;
            states.PrevLow = Price;
            states.PickNewLH = false;
        }
        const cLength = chartNodes.length;
    
        if (cLength <= index || chartNodes[index] === null){ 
            return;
        }
    
        for (; index < cLength - 1; index++){
            if (chartNodes[index] !== null && chartNodes[index].Close > 0){ 
                break;
            }
        }
        const data = chartNodes[index];
        if (data === null){ 
            return;
        }
        if (data._volume < 1) {
            data.Close = states.LastDdeData.Price;
            data.High = states.LastDdeData.High;
            data.Low = states.LastDdeData.Low;
        }
    
        const beginOfMonth = StockMarketUtil.GetMBeginDate(pricePanelData.HsfData.HSFResultsRawData[0].Date);
        const beginOfQuarter = StockMarketUtil.GetQBeginDate(pricePanelData.HsfData.HSFResultsRawData[0].Date);
        const beginOfYear = StockMarketUtil.GetABeginDate(pricePanelData.HsfData.HSFResultsRawData[0].Date);
    
        const FirstTradingOfPeriod =
            (periodicity === GraphType.Daily ||
                (periodicity === GraphType.Weekly && states.LastDdeData.Date.getDay() === DayOfWeek.Monday) ||
                (periodicity === GraphType.Monthly && pricePanelData.HsfData.HSFResultsRawData[0].Date === beginOfMonth) ||
                (periodicity === GraphType.Annual && pricePanelData.HsfData.HSFResultsRawData[0].Date === beginOfYear) ||
                (periodicity === GraphType.Quarterly && pricePanelData.HsfData.HSFResultsRawData[0].Date === beginOfQuarter));
    
        if (!states.firstTrade) {
            data.Close = states.LastDdeData.Price;
            states.PrevHigh = states.LastDdeData.Price;
            states.PrevLow = states.LastDdeData.Price;
            if (FirstTradingOfPeriod) {
                data.High = states.LastDdeData.Price;
                data.Low = states.LastDdeData.Price;
            }
            data._volume = states.LastDdeData.Volume;
            states.firstTrade = true;
        }
    
        if (isIntraday) {
            High = Math.max(Price, states.PrevHigh);
            Low = Math.min(Price, states.PrevLow);
        }
        else {
            High = Math.max(High, data.High);
            Low = Math.min(Low, data.Low);
        }
    
        Volume = isIntraday || FirstTradingOfPeriod ? states.LastDdeData.Volume : firstNode._volume;
    
        //if (isIntraday)
        //    Volume = firstNode._volume;
    
        if (!FirstTradingOfPeriod && !isIntraday && headerData && headerData.Prevolume > 0 &&
            headerData.Prevolume > states.LastDdeData.Volume &&  states.ShowVolume &&
            data.Date.getDate() >= states.LastDdeData.Date.getDate() && states.LastDdeData.Volume > 0) {
            Volume = headerData.Prevolume + states.LastDdeData.Volume;
        }
        //if(periodicity === GraphType.Daily) {
        //    if (firstNode && firstNode.High === states.LastDdeData.High
        //        && firstNode.Low === states.LastDdeData.Low
        //        && firstNode.Close === states.LastDdeData.Price
        //        && firstNode._volume === Volume) {
        //        return;
        //    }
        //}else{
        //    if (firstNode && firstNode.High === states.PrevHigh
        //        && firstNode.Low === states.PrevLow
        //        && firstNode.Close === states.LastDdeData.Price
        //        && firstNode._volume === Volume) {
        //        return;
        //    }
        //}
    
        if (chartNodes.length > padding &&
            chartNodes[padding] !== null) {
            firstNode.High = High;
            firstNode.Low = Low;
            firstNode.Close = Price;
            firstNode.UpTick = positiveNode;
            firstNode.IsVisible = true;
    
            if (showvol){
                firstNode._volume = Volume;
            }
            firstNode.ShowVol = (showvol || !isIntraday) && !states.LastDdeData.HideVolume;
        }
    
        if (isIntraday && states.IntradayBusy) {
            return;
        }
    
        if (headerData) {
            headerData.MarketCap = headerData.Shares * pricePanelData.HsfData.HSFResults[padding].Close;
            if (headerData.shares2 && headerData.shares2 > 0) {
                headerData.marketCap2 = headerData.shares2 * pricePanelData.HsfData.HSFResults[padding].Close;
            }
    
            if (states && headerData && headerData.companyName) {
                yield put({type: ActionTypes.PREPARE_PDR_DATA, isStreamingProc: true});
            }
        }
        yield call(processPriceChart, true);
        yield put(prepareVolumeData());
    }
    catch(error){
        console.log(`Error occurs in PriceChartSaga.js, UpdateLastPriceTask, ${error}`, error)
    }
}

function SetupLastDdeData(states, dataGraphStates, HSFResults, updatePrice = false) {
    const { SymbolInfo, pricePanelData, padding, isIntraday, isHistoric, periodicity} = dataGraphStates;
    if (!HSFResults || HSFResults.length < 1){ 
        return;
    }
    const lastHsfResult = HSFResults[padding];
    if (states.LastDdeData && !updatePrice) {
      states.LastDdeData.DataAvailable = false;
      states.LastDdeData.HideVolume = false;
      states.LastDdeData.RTVolRate = pricePanelData.HsfData.RTVolRate;
      states.LastDdeData.RTWVolRate = pricePanelData.HsfData.RTWVolRate;
      states.LastDdeData.Symbol = states.Symbol;
      states.QuoteSymbol = states.Symbol;
      states.LastDdeData.QuoteSymbol = states.Symbol;
    }
    if (SymbolInfo.Symbol !== states.Symbol){
        return;
    }
    if (!WorkSpaceRealTimePrices.TradingHours({SymbolInfo, TradeClose: states.TradeClose, TradeOpen: states.TradeOpen})){
      return;
    }
    const DDEDate = pricePanelData.HsfData.DDEDateNode.Date ? pricePanelData.HsfData.DDEDateNode.Date.getFullYear ?
    DateHelper.returnTimeZoneDate(pricePanelData.HsfData.DDEDateNode.Date, SymbolInfo.MomentZoneID) :
    DateHelper.parseJsonDate(pricePanelData.HsfData.DDEDateNode.Date, SymbolInfo.MomentZoneID) : new Date();
    // This is setting the High/Low/Price back to previous values which causes Intraday chart corruption.
    // We took this out from intraday charts AA 9/22/2017.
    if (!isIntraday && lastHsfResult && DDEDate.getTime() === lastHsfResult.Date.getTime() &&
        lastHsfResult._volume < 1 && pricePanelData.HsfData && pricePanelData.HsfData.DDEDateNode && pricePanelData.HsfData.DDEDateNode.Close > 1) {

        lastHsfResult.High = pricePanelData.HsfData.DDEDateNode.High;
        lastHsfResult.Low = pricePanelData.HsfData.DDEDateNode.Low;
        lastHsfResult.Close = pricePanelData.HsfData.DDEDateNode.Close;
        lastHsfResult._volume = pricePanelData.HsfData.DDEDateNode._volume * 100;

        if (WorkSpaceRealTimePrices.IsNASDAQBasic && !pricePanelData.HsfData.ShowVolume && !isHistoric){
            states.LastDdeData.HideVolume = true;
        }
    }
    if ((lastHsfResult && lastHsfResult._volume > 0) || isIntraday) {
      states.LastDdeData.High = lastHsfResult.High;
      states.LastDdeData.Low = lastHsfResult.Low;
      states.LastDdeData.Price = lastHsfResult.Close;
      states.LastDdeData.Volume = lastHsfResult._volume;
      if (states.LastDdeData && lastHsfResult.Date >= states.LastDdeData.Date ) // && (states.LastDdeData.Volume>0 || states.isIntraday || states.SymbolInfo.SymTypeEnum == SymbolTypeEnum.INDEX))
      {
        states.LastDdeData.Date = lastHsfResult.Date;
        states.LastDdeData.Symbol = states.Symbol;

        //if (WorkSpaceRealTimePrices.IsIndicator(symbol) && WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.DdeXref != null) {
        //  if (WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.DdeXref.ContainsKey(symbol.ToUpper()))
        //    symbol = WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.DdeXref[symbol.ToUpper()];
        //}
        states.QuoteSymbol = states.Symbol;
        //_state.LastDdeData.QuoteSymbol = GetRequestSymbol(_states);
        states.LastDdeData.QuoteSymbol = states.Symbol;
        states.LastDdeData.High = isIntraday ? (lastHsfResult._volume > 0 ? Math.max(lastHsfResult.High, states.LastDdeData.High) : states.LastDdeData.High) : lastHsfResult.High;
        states.LastDdeData.Low = states.LastDdeData.Low > 0 && !isIntraday ? (lastHsfResult._volume > 0 ? Math.min(lastHsfResult.Low, states.LastDdeData.Low) : states.LastDdeData.Low) : lastHsfResult.Low;
        if (states.LastDdeData.Price <= 0){
          states.LastDdeData.Price = lastHsfResult.Close;
        }
        states.LastDdeData.PrevVolume = lastHsfResult._volume;
        if (lastHsfResult._volume > 0){
          states.LastDdeData.Volume = lastHsfResult._volume;
        }
        if (!states.ShowVolume){
          states.LastDdeData.PrevVolume = lastHsfResult._volume;
        }
        else if (lastHsfResult !== null && lastHsfResult._volume > 0 && states.LastDdeData.Volume > 0 && periodicity !== GraphType.Daily && SymbolInfo.Osid < 2000000){
          states.LastDdeData.PrevVolume = lastHsfResult._volume - states.LastDdeData.Volume;
        }
        states.LastDdeData.DataAvailable = true;
        states.LastDdeData.Periodicity = periodicity;
      }

    }
    states.PrevHigh = states.LastDdeData.High;
    states.PrevLow = states.LastDdeData.Low;
    //    states.PrevVolume = states.LastDdeData.Volume;
}
export function* registerWebSync({ IsNASDAQBasic }){
    if (WebSyncUtil.IsConnected && IsNASDAQBasic) {
        const states = yield select(priceChartReducerselect);
        const dataGraphStates = yield select(getDatagraphStates);
        yield fork(webSyncChannel, dataGraphStates.SymbolInfo.Symbol, states.QuoteSymbol);
        states.LastIdVolume = 0;
        states.PrevVolume = StringUtil.convertFromLongValueToInt(dataGraphStates.pricePanelData.HsfData.LastVolume);
        states.CumVolume = StringUtil.convertFromLongValueToInt(dataGraphStates.pricePanelData.HsfData.LastVolume);
        yield call(SetupLastDdeData, states, dataGraphStates, dataGraphStates.pricePanelData.HsfData.HSFResults);
        yield fork(serverTickChannel);
    }
}

let webSyncChannelBuffer;
function* webSyncChannel( quoteSymbol, symbolToRemove ){
    if(webSyncChannelBuffer){
        webSyncChannelBuffer.close()
    }
    webSyncChannelBuffer =  eventChannel((emmiter) => {
        if (WebSyncUtil.IsConnected && quoteSymbol) {
            if (symbolToRemove && symbolToRemove !== quoteSymbol) {
                WebSyncUtil.UnsubscribeAsync(symbolToRemove);
            }
            WebSyncUtil.SubscribeAsync(quoteSymbol);
        }
        WebSyncUtil.addDataReceivedListener(emmiter);

        return ()=>{
            WebSyncUtil.removeDataReceivedListener(emmiter);
        }
    });

    while(true){
        const result = yield take(webSyncChannelBuffer);
        yield safe(call(updateLastPriceData, result), "PriceChartSaga.js", "updateLastPriceData");
    }
}

let serverTickChannelBuffer;
function* serverTickChannel(){
    if(serverTickChannelBuffer){
        serverTickChannelBuffer.close();
    }
    serverTickChannelBuffer = eventChannel((emmiter)=>{
        const timer = setInterval(emmiter, 1000, new Date());

        return ()=>{
                clearInterval(timer);
        }
    });
    while(true){
        yield take(serverTickChannelBuffer);
        yield safe(call(OnServerTickReceived, new Date()), "PriceChartSaga.js", "OnServerTickReceived");
    }
}

function* stopDatagraphStreaming(){
    if(webSyncChannelBuffer){
        webSyncChannelBuffer.close()
    }
}


export function* watcherStopDatagraphStreaming(){
    yield takeLatest(ActionTypes.STOP_DATAGRAPH_STREAMING, stopDatagraphStreaming)
}