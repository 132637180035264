import DayOfWeek from "DayOfWeek";
import StringUtil from "StringUtil";
import DateHelper from "DateHelper";
import moment from 'moment-timezone';
import BaseServiceApi from 'BaseServiceApi';
var IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];
import TimeZoneMaps from "TimeZoneMaps";
import StockMarketUtility from "../../Utils/StockMarketUtil"
import GraphType from "GraphType";

class WorkSpaceRealTimePrices {
    constructor() {

    }

    IsNasdaqMarketHours() {
        let dateTimeNow = new Date(moment().tz("America/New_York").format('YYYY-MM-DD HH:mm:ss').replace(" ", "T")),
            TradingStartTime = new Date(moment().tz("America/New_York").format('YYYY-MM-DD 09:30:00').replace(" ", "T")),
            TradingCloseTime = new Date(moment().tz("America/New_York").format('YYYY-MM-DD 16:00:00').replace(" ", "T"));
        return (dateTimeNow.getTime() >= TradingStartTime.getTime() && dateTimeNow.getTime() <= TradingCloseTime.getTime());
    }

    inMarketHours(date, includeIDCDelay) {
        if (date == null) return false;
        if (date.getDay() == 6 || date.getDay() == 0) return false;
        var minuteOfDay = date.getHours() * 60 + date.getMinutes();
        var startMinute = 9 * 60 + 30;
        var endMinute = 16 * 60;
        if (includeIDCDelay) {
            endMinute += 20;
        }
        return minuteOfDay >= startMinute && minuteOfDay <= endMinute;
    }
    getCurrentTime(zoneID) {
        if (zoneID === null || zoneID === undefined) {
            zoneID = "US/Eastern";
        }
        let test3Date = new Date();
        let test3DateML = test3Date.getTime();
        let offset4 = test3Date.getTimezoneOffset();
        let test4DateML = test3DateML + offset4 * 60000;
        let test4Date = moment(test4DateML).tz(zoneID);//("America/New_York");
        let offset5 = test4Date._offset;
        let test5DateML = test4DateML + offset5 * 60000;
        let test5Date = new Date(test5DateML);
        return test5Date;
    }
    StockInTradingSession(state) {
        if (state.IsHistoric)
            return false;

        if (state.SymbolInfo.MomentZoneID === null || state.SymbolInfo.MomentZoneID.length < 1) return false;

        //      var timeZoneID = this.GetNodaTimeZoneByMicrosoftTimeZoneID(state.SymbolInfo.ZoneID);
        var timeZoneID = state.SymbolInfo.MomentZoneID;
        let lastUpdateDate = this.getCurrentTime(timeZoneID); //moment().tz(timeZoneID);
        //      let lastUpdateDate = new Date(estTime.format('Y'), parseInt(estTime.format('M')) - 1, estTime.format('D'), estTime.format('HH'), estTime.format('mm'), 0);

        var session = false;
        if (lastUpdateDate > state.TradeOpen && lastUpdateDate < state.TradeClose) {
            switch (state.periodicity) {
                case GraphType.Weekly:
                    session = lastUpdateDate.getDay() <= DayOfWeek.Friday &&
                        lastUpdateDate.getDay() >= DayOfWeek.Monday;
                    break;
                case GraphType.Monthly:
                    session = lastUpdateDate.getDate() >= 1 && lastUpdateDate.getDate() <= 31;
                    break;
                default:
                    session = lastUpdateDate >= state.TradeOpen && lastUpdateDate <= state.TradeClose;
                    break;
            }
        }
        return session;
    }
    PctIntoTrading(state, delayed = false) {
        var inSession = this.StockInTradingSession(state);
        let pctIntoTrading = 1.0;

        if (inSession || (delayed && !state.isHistoric) || state.periodicity != GraphType.Daily) {
            var tradeDate = delayed && inSession ? state.DelayedTradeDate : state.TradeDate;
            let tradeOpen = state.TradeOpen;
            let tradeClose = state.TradeClose;
            if(state.isHistoric){
                const symbolInfo = state.SymbolInfo;
                tradeClose= new Date(state.endDate);
                tradeOpen= new Date(state.endDate);
                if(symbolInfo){
                    if(symbolInfo.ExchangeCloseTime){
                        tradeClose.setHours(symbolInfo.ExchangeCloseTime.getHours(),symbolInfo.ExchangeCloseTime.getMinutes(),0);
                    }
                    if(symbolInfo.ExchangeCloseTime){
                        tradeOpen.setHours(symbolInfo.ExchangeOpenTime.getHours(),symbolInfo.ExchangeOpenTime.getMinutes(),0);
                    }
                }
                tradeDate = new Date(tradeClose);
            }
            switch (state.periodicity) {
                case GraphType.Weekly:
                    pctIntoTrading =
                        StockMarketUtility.GetPercentageIntoTradingWeek(
                            tradeDate, tradeOpen, tradeClose);
                    break;
                case GraphType.Annual:
                    pctIntoTrading =
                        StockMarketUtility.GetPercentageIntoTradingYear(
                            tradeDate, tradeOpen, tradeClose);
                    break;
                case GraphType.Quarterly:
                    pctIntoTrading =
                        StockMarketUtility.GetPercentageIntoTradingQuarter(
                            tradeDate, tradeOpen, tradeClose);
                    break;
                case GraphType.Monthly:
                    pctIntoTrading =
                        StockMarketUtility.GetPercentageIntoTradingMonth(
                            tradeDate, tradeOpen, tradeClose);
                    break;
                default:
                    pctIntoTrading =
                        StockMarketUtility.GetPercentageIntoTradingDay(
                            tradeDate, tradeOpen, tradeClose);
                    break;
            }
        }
        return pctIntoTrading;
    }

    checkTradingHours(state, tradingTime) {
        let isTradingHours;
        if (state.IntradaySourceType === IntradaySourceType.IntradaySource_NASDAQ)
            isTradingHours = this.checkTradingHoursNoDelay(state, tradingTime);
        else
            isTradingHours = this.checkTradingHours(state, tradingTime);

        return isTradingHours
    }

    /**
     * @return {boolean}
     */
    checkTradingHoursNoDelay(state, tradingTime) {
        if (state == null ||
            state.SymbolInfo == null ||
            StringUtil.isEmpty(state.SymbolInfo.ZoneID)) {
            return false;
        }
        const timeZoneID = state.SymbolInfo.MomentZoneID !== null ? state.SymbolInfo.MomentZoneID : "US/Eastern";
        const estTimeDate = this.getCurrentTime(timeZoneID); 
        const year = tradingTime.getFullYear();
        const month = tradingTime.getMonth();
        const day = tradingTime.getDate();
        const openTime = DateHelper.parseJsonDate(state.SymbolInfo.ExchangeOpenTime, state.SymbolInfo.MomentZoneID);
        const closeTime = DateHelper.parseJsonDate(state.SymbolInfo.ExchangeCloseTime, state.SymbolInfo.MomentZoneID);
        const lastTradeDate = new Date(year, month, day);
        const tradeOpen = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate(), openTime.getHours(), openTime.getMinutes());
        const tradeClose = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate(), closeTime.getHours(), closeTime.getMinutes());

        return estTimeDate > tradeOpen
            && estTimeDate < tradeClose
            && estTimeDate.getDay() > DayOfWeek.Sunday
            && estTimeDate.getDay() < DayOfWeek.Saturday;
    }

    /**
     * @return {boolean}
     */
    checkTradingHours(state, tradingTime) {
        if (state == null ||
            state.SymbolInfo == null ||
            StringUtil.isEmpty(state.SymbolInfo.ZoneID) ||
            state.TradeClose == undefined) {
            return false;
        }
        const timeZoneID = state.SymbolInfo.MomentZoneID !== null ? state.SymbolInfo.MomentZoneID : "US/Eastern";
        const estTimeDate = this.getCurrentTime(timeZoneID); 
        const year = tradingTime.getFullYear();
        const month = tradingTime.getMonth();
        const day = tradingTime.getDate();
        const openTime = DateHelper.parseJsonDate(state.SymbolInfo.ExchangeOpenTime, state.SymbolInfo.MomentZoneID);
        const closeTime = DateHelper.parseJsonDate(state.SymbolInfo.ExchangeCloseTime, state.SymbolInfo.MomentZoneID);
        const lastTradeDate = new Date(year, month, day);
        const tradeOpen = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate(), openTime.getHours(), openTime.getMinutes());
        const tradeClose = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate(), closeTime.getHours(), closeTime.getMinutes());
        tradeClose.setMinutes(tradeClose.getMinutes() + 20);
        return estTimeDate > tradeOpen
            && estTimeDate < tradeClose // 20 minutes delay added
            && estTimeDate.getDay() > DayOfWeek.Sunday
            && estTimeDate.getDay() < DayOfWeek.Saturday;
    }

    IsTradingHours(state) {
        let isTradingHours;
        if (state.IntradaySourceType === IntradaySourceType.IntradaySource_NASDAQ)
            isTradingHours = this.TradingHoursNoDelay(state);
        else
            isTradingHours = this.TradingHours(state);

        return isTradingHours
    }

    /**
     * @return {boolean}
     */
    TradingHoursNoDelay(state) {
        if (state == null || state.SymbolInfo == null || StringUtil.isEmpty(state.SymbolInfo.zoneid)) {
            return false;
        }
        //      let timeZoneID = this.GetNodaTimeZoneByMicrosoftTimeZoneID(state.SymbolInfo.ZoneID);
        const timeZoneID = state.SymbolInfo.MomentZoneID;
        const estTimeDate = this.getCurrentTime(timeZoneID); //moment().tz(timeZoneID);
        //      let estTimeDate = new Date(estTime.format('Y'), parseInt(estTime.format('M')) - 1, estTime.format('D'), estTime.format('HH'), estTime.format('mm'), 0);
        let tradeOpen = state.TradeOpen;
        let tradeClose = state.TradeClose;

        if (estTimeDate.getDay() > DayOfWeek.Sunday && estTimeDate.getDay() < DayOfWeek.Saturday) {
            tradeOpen = new Date(estTimeDate.getFullYear(),
                estTimeDate.getMonth(),
                estTimeDate.getDate(),
                state.TradeOpen.getHours(),
                state.TradeOpen.getMinutes());
            tradeClose = new Date(estTimeDate.getFullYear(),
                estTimeDate.getMonth(),
                estTimeDate.getDate(),
                state.TradeClose.getHours(),
                state.TradeClose.getMinutes());
        }


        return estTimeDate > tradeOpen
            && estTimeDate < tradeClose
            && estTimeDate.getDay() > DayOfWeek.Sunday
            && estTimeDate.getDay() < DayOfWeek.Saturday;
    }

    /**
     * @return {boolean}
     */
    TradingHours(state) {
        if (state == null || state.SymbolInfo == null || StringUtil.isEmpty(state.SymbolInfo.zoneid) || state.TradeClose == undefined) {
            return false;
        }
        //        let timeZoneID = this.GetNodaTimeZoneByMicrosoftTimeZoneID(state.SymbolInfo.ZoneID);
        var timeZoneID = state.SymbolInfo.MomentZoneID !== null ? state.SymbolInfo.MomentZoneID : "US/Eastern";
        var estTimeDate = this.getCurrentTime(timeZoneID); //moment().tz(timeZoneID);
        let tradeOpen = state.TradeOpen;
        let tradeClose = new Date(state.TradeClose);
        tradeClose.setMinutes(state.TradeClose.getMinutes() + 20);
        //      let estTimeDate = new Date(estTime.format('Y'), parseInt(estTime.format('M')) - 1, estTime.format('D'), estTime.format('HH'), estTime.format('mm'), 0);
        return estTimeDate > tradeOpen
            && estTimeDate < tradeClose // 20 minutes delay added
            && estTimeDate.getDay() > DayOfWeek.Sunday
            && estTimeDate.getDay() < DayOfWeek.Saturday;
    }

    /**
     * @return {boolean}
     */
    IsIndicator(symbol) {
        return symbol.substr(0, 1) == "0";
    }

    /**
     * @return {string}
     */
    GetNodaTimeZoneByMicrosoftTimeZoneID(zoneID) {
        let timeZoneID = "";
        let TimeZoneLists = TimeZoneMaps;
        TimeZoneLists.forEach((timezone) => {
            if (timezone.MicrosoftTimeZoneID === zoneID) {
                timeZoneID = timezone.NodaTimeZoneID;
            }
        });
        return timeZoneID;
    }

    /**
     * @return {boolean}
     */
    TradingFirstMinute(States) {
        if (States == null || States.SymbolInfo == null || StringUtil.isEmpty(States.SymbolInfo.ZoneID)) {
            return false;
        }

        //      let timeZoneID = this.GetNodaTimeZoneByMicrosoftTimeZoneID(States.SymbolInfo.ZoneID);
        var timeZoneID = States.SymbolInfo.MomentZoneID;
        var estTimeDate = this.getCurrentTime(timeZoneID); //moment().tz(timeZoneID);
        //      let estTimeDate = new Date(estTime.format('Y'), parseInt(estTime.format('M')) - 1, estTime.format('D'), estTime.format('HH'), estTime.format('mm'), 0);
        let tradeOpen = new Date(States.TradeOpen);
        tradeOpen.setMinutes(States.TradeOpen.getMinutes() + 1);
        return estTimeDate < tradeOpen;

    }

    Delayed(TimeZone, NodeTime) {
        //      let timeZoneID = this.GetNodaTimeZoneByMicrosoftTimeZoneID(TimeZone);
        //        var timeZoneID = state.SymbolInfo.MomentZoneID;
        var estTimeDate = this.getCurrentTime(TimeZone); //moment().tz(timeZoneID);
        //      let estTimeDate = new Date(estTime.format('Y'), parseInt(estTime.format('M')) - 1, estTime.format('D'), estTime.format('HH'), estTime.format('mm'), 0);
        let nodeTime = new Date(NodeTime);
        nodeTime.setMinutes(NodeTime.getMinutes() + 1);
        return estTimeDate < nodeTime;
    }

}

const workSpaceRealTimePrices = new WorkSpaceRealTimePrices();
export default workSpaceRealTimePrices;
