import React, { Component } from "react";
import PropTypes from 'prop-types';
import ConsoleActions from "Actions/ConsoleActions";

export default class PanaraySupport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInSupportHours: this.props.isInSupportHours,
        }
        this.openConnectionDialog = this.openConnectionDialog.bind(this);
    }

    openConnectionDialog(){
        if(Boolean(this.state.isInSupportHours))
        {
            ConsoleActions.toggleSupportDialog(false);
            ConsoleActions.toggleLiveSupportDialog(true);
        } 
    }

    render() {
        
        return (
            <div className="support-panel" id="PanaraySupport">
                <div className="support-box">
                    <ul className="support-phone">
                        <li>
                            <span className="support-init">US</span>
                            <span className="support-cell" x-ms-format-detection="none">800-419-6632</span>
                        </li>
                        <li>
                            <span className="support-init">UK</span>
                            <span className="support-cell" x-ms-format-detection="none">0-800-969-581</span>
                        </li>
                        <li>
                            <span className="support-init">Intl</span>
                            <span className="support-cell" x-ms-format-detection="none">310-448-6199</span>
                        </li>
                    </ul>
                    <div className="support-image panaray-support-image"></div>
                    <div className="support-detail">
                        <h6>PANARAY Support</h6>
                        <div className="email-link"><a href={`mailto:${this.props.supportMail}?subject=PANARAY Support`}>Email</a></div>
                        <div className={this.state.isInSupportHours ? "live-support" : "live-support disabled"}>
                            <span onClick={this.openConnectionDialog}>Live Support</span>
                        </div>
                    </div>
                </div>
                
                
                
                {Boolean(this.state.isInSupportHours) ? <div className="support-blank"></div> : 
                <div className="support-note">NOTE: Live Support not avaliable at this time</div>}
            </div>
            
        )
    }
}

PanaraySupport.propTypes = {
    isInSupportHours: PropTypes.bool.isRequired,
    supportMail: PropTypes.string.isRequired
}

PanaraySupport.defaultProps = {
    isInSupportHours: false,
    supportMail: "PANARAYFeedback@williamoneil.com"
}