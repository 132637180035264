import { put, select, call, takeLatest } from 'redux-saga/effects';
import { getCalendarData } from '../../../Reducers/NavCompare/ComparisonGraphReducers/selectors';
import { ComparisonGraph } from "../../../Constants/ComparisonGraph";
import moment, { isMoment } from "moment";
import GraphType from "GraphType";
import { isEmpty } from "underscore";
const { ActionTypes } = ComparisonGraph;
import DateHelper from "DateHelper";
import { initCompChart } from '../../../Actions/ComparisonActions';
import LocalizationStore from 'LocalizationStore';

/********************** Model Logic *************************/
function* setSelectedDate(stateData, input) {
    let selectedDate = input ? input : stateData.selectedDate;
    let {
        startDate,
        endDate,
        viewDate
    } = stateData;
    const {
        showCalendarLeft,
        showCalendarRight,
        minDate,
        maxDate,
        duration,
        periodicity,
        isIntervalSelected
    } = stateData;

    try {
        if (showCalendarLeft) {
            if (selectedDate.isSameOrAfter(moment(maxDate, "MM-DD-YYYY", true))) {
                startDate = maxDate.clone().subtract(duration, 'years');
                startDate = yield call(updateDayBasedOnPeriodicity, periodicity, startDate.clone(), maxDate);
                startDate = startDate.format('MM-DD-YYYY');
                endDate = endDate;
            }
            else {
                startDate = yield call(updateDayBasedOnPeriodicity, periodicity, selectedDate.clone(), maxDate);
                startDate = startDate.format('MM-DD-YYYY');
                endDate = yield call(updateUserEndDate, selectedDate, endDate, periodicity, duration, maxDate, isIntervalSelected);

                if (moment(startDate, "MM-DD-YYYY", true).isSame(moment(endDate, "MM-DD-YYYY", true))) {
                    startDate = maxDate.clone().subtract(duration, 'years');
                    startDate = yield call(updateDayBasedOnPeriodicity, periodicity, startDate.clone(), maxDate);
                    startDate = startDate.format('MM-DD-YYYY');
                    endDate = endDate;
                }
            }

            viewDate = moment(startDate, "MM-DD-YYYY", true);
        }
        else if (showCalendarRight || duration) {
            if (selectedDate.isSameOrBefore(moment(minDate, "MM-DD-YYYY", true))) {
                endDate = minDate.clone().add(duration, 'years');
                endDate = endDate.format('MM-DD-YYYY');
                startDate = startDate;
            }
            else {
                endDate = selectedDate.format('MM-DD-YYYY');
                startDate = yield call(updateUserStartDate, selectedDate, startDate, periodicity, duration, minDate, isIntervalSelected);

                if (moment(endDate, "MM-DD-YYYY", true).isSame(moment(startDate, "MM-DD-YYYY", true))) {
                    endDate = minDate.clone().add(duration, 'years');
                    endDate = endDate.format('MM-DD-YYYY');
                    startDate = startDate;
                }
            }

            viewDate = moment(endDate, "MM-DD-YYYY", true);
        }

        return { startDate, endDate, selectedDate: viewDate, viewDate: viewDate, isIntervalSelected: false }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, setSelectedDate ${error}`);
    }
}

function* updateDayBasedOnPeriodicity(periodicity, tempSelectedDate, maxDate, minDate) {
    tempSelectedDate = isMoment(tempSelectedDate) ? tempSelectedDate : moment(tempSelectedDate, "MM-DD-YYYY", true)
    let day = tempSelectedDate.day();
    /* validation of day is different from desktop. Done acc to the spec */

    try {
        switch (periodicity) {
            case GraphType.Daily:
                {
                    if (day == 0) {
                        tempSelectedDate = tempSelectedDate.add(1, "days");
                    }
                    else if (day == 6) {
                        tempSelectedDate = tempSelectedDate.add(2, "days");
                    }
                }
                break;
            case GraphType.Weekly:
                {
                    if (day !== 5) {
                        day = day < 5 ? (5 - day) : (5 - day + 7);
                        tempSelectedDate = tempSelectedDate.add(day, "days");
                    }
                }
                break;
            case GraphType.Monthly:
                {
                    day = tempSelectedDate.endOf("month").day();
                    if (day == 0) {
                        tempSelectedDate = tempSelectedDate.subtract(2, "days");
                    }
                    else if (day == 6) {
                        tempSelectedDate = tempSelectedDate.subtract(1, "days");
                    }
                }
                break;
            case GraphType.Quarterly:
                {
                    const month = yield call(getMonth, tempSelectedDate);
                    tempSelectedDate = tempSelectedDate.month(month);
                    day = tempSelectedDate.endOf("month").day();
                    if (day == 0) {
                        tempSelectedDate = tempSelectedDate.subtract(2, "days");
                    }
                    else if (day == 6) {
                        tempSelectedDate = tempSelectedDate.subtract(1, "days");
                    }
                }
                break;
            case GraphType.Annual:
                day = tempSelectedDate.endOf("years").day();
                if (day == 0) {
                    tempSelectedDate = tempSelectedDate.subtract(2, "days");
                }
                else if (day == 6) {
                    tempSelectedDate = tempSelectedDate.subtract(1, "days");
                }
                break;
            default:
                break;
        }

        if (tempSelectedDate.isAfter(maxDate)) {
            tempSelectedDate = maxDate;
        }
        else if (tempSelectedDate.isBefore(minDate)) {
            tempSelectedDate = tempSelectedDate;
        }
        // else if(tempSelectedDate.isSame(minDate)) {
        //     tempSelectedDate = minDate.endOf("years");
        // }

        return tempSelectedDate;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, updateDayBasedOnPeriodicity ${error}`);
    }
}

function* setDate(selectedDate, isStartDate, isIntervalSelected, duration) {
    try {
        if (isStartDate || isIntervalSelected)
            return selectedDate.subtract(duration, "years");
        else if (!isStartDate || isIntervalSelected) {
            return selectedDate.add(duration, "years");
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, setDate ${error}`);
    }
}

function* minRangeBasedOnPeriodicity(selectedDate, startOrEndDate, periodicity, duration, isStartDate, isIntervalSelected) {
    try {
        startOrEndDate = moment(startOrEndDate, "MM-DD-YYYY", true);
        switch (periodicity) {
            case GraphType.Daily:
                {
                    if (Math.abs(selectedDate.diff(startOrEndDate, 'year')) < duration) {
                        if (isStartDate || isIntervalSelected)
                            startOrEndDate = (selectedDate <= startOrEndDate) || isIntervalSelected ? selectedDate.subtract(duration, "years") : startOrEndDate;
                        else if (!isStartDate || isIntervalSelected) {
                            startOrEndDate = (selectedDate >= startOrEndDate) || isIntervalSelected ? selectedDate.add(duration, "years") : startOrEndDate;
                        }
                    }
                    else
                        startOrEndDate = yield call(setDate, selectedDate, isStartDate, isIntervalSelected, duration);
                }
                break;
            case GraphType.Weekly:
                {
                    if (Math.abs(selectedDate.diff(startOrEndDate, 'year')) < duration) {
                        if (isStartDate) {
                            if (isIntervalSelected || selectedDate.year() < startOrEndDate.year()) {
                                startOrEndDate = selectedDate.subtract(duration, "years");
                            }
                            else if ((Math.abs(selectedDate.year() - startOrEndDate.year()) < 1)) {
                                if (selectedDate.week() <= startOrEndDate.week()) {
                                    startOrEndDate = selectedDate.subtract(duration, "years");
                                }
                            }
                        }
                        else if (!isStartDate) {
                            if (isIntervalSelected || (selectedDate.year() > startOrEndDate.year())) {
                                startOrEndDate = selectedDate.add(duration, "years");
                            }
                            else if ((Math.abs(selectedDate.year() - startOrEndDate.year()) < 1)) {
                                if (selectedDate.week() >= startOrEndDate.week()) {
                                    startOrEndDate = selectedDate.add(duration, "years");
                                }
                            }
                        }
                    }
                    else
                        startOrEndDate = yield call(setDate, selectedDate, isStartDate, isIntervalSelected, duration);
                }
                break;
            case GraphType.Monthly:
                {
                    if (Math.abs(selectedDate.diff(startOrEndDate, 'year')) < duration) {
                        if (isStartDate) {
                            if (isIntervalSelected || selectedDate.year() < startOrEndDate.year()) {
                                startOrEndDate = selectedDate.subtract(duration, "years");
                            }
                            else if ((Math.abs(selectedDate.year() - startOrEndDate.year()) < 1)) {
                                if (selectedDate.month() <= startOrEndDate.month()) {
                                    startOrEndDate = selectedDate.subtract(duration, "years");
                                }
                            }
                        }
                        else if (!isStartDate) {
                            if (isIntervalSelected || (selectedDate.year() > startOrEndDate.year())) {
                                startOrEndDate = selectedDate.add(duration, "years");
                            }
                            else if ((Math.abs(selectedDate.year() - startOrEndDate.year()) < 1)) {
                                if (selectedDate.month() >= startOrEndDate.month()) {
                                    startOrEndDate = selectedDate.add(duration, "years");
                                }
                            }
                        }
                    }
                    else
                        startOrEndDate = yield call(setDate, selectedDate, isStartDate, isIntervalSelected, duration);
                }
                break;
            case GraphType.Quarterly:
                {
                    let month;
                    if (Math.abs(selectedDate.diff(startOrEndDate, 'year')) < duration) {
                        if (isStartDate) {
                            month = yield call(getMonth, selectedDate);
                            if (isIntervalSelected || selectedDate.year() < startOrEndDate.year()) {
                                startOrEndDate = selectedDate.subtract(duration, "years").month(month);
                            }
                            else if ((Math.abs(selectedDate.year() - startOrEndDate.year()) < 1)) {
                                if (selectedDate.month() <= startOrEndDate.month()) {
                                    startOrEndDate = selectedDate.subtract(duration, "years").month(month);
                                }
                            }
                        }
                        else if (!isStartDate) {
                            month = yield call(getMonth, selectedDate);
                            if (isIntervalSelected || (selectedDate.year() > startOrEndDate.year())) {
                                startOrEndDate = selectedDate.add(duration, "years").month(month);
                            }
                            else if ((Math.abs(selectedDate.year() - startOrEndDate.year()) < 1)) {
                                if (selectedDate.month() >= startOrEndDate.month()) {
                                    startOrEndDate = selectedDate.add(duration, "years").month(month);
                                }
                            }
                        }
                    }
                    else
                        startOrEndDate = yield call(setDate, selectedDate, isStartDate, isIntervalSelected, duration);
                }
                break;
            case GraphType.Annual:
                {
                    if (Math.abs(selectedDate.diff(startOrEndDate, 'year')) < duration) {
                        if (isIntervalSelected || isStartDate && ((Math.abs(selectedDate.year() - startOrEndDate.year()) < 1) || (selectedDate.year() < startOrEndDate.year()))) {
                            startOrEndDate = selectedDate.subtract(duration, "years");
                        }
                        else if (isIntervalSelected || !isStartDate && ((Math.abs(selectedDate.year() - startOrEndDate.year()) < 1) || (selectedDate.year() > startOrEndDate.year()))) {
                            startOrEndDate = selectedDate.add(duration, "years");
                        }
                    }
                    else
                        startOrEndDate = yield call(setDate, selectedDate, isStartDate, isIntervalSelected, duration);
                }
                break;
            default:
                break;
        }
        return startOrEndDate;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, minRangeBasedOnPeriodicity ${error}`);
    }
}

function* getMonth(selectedDate) {
    try {
        const qtrs = [3, 6, 9, 12];
        let month = selectedDate.month() + 1;
        for (let i = 0; i < 3; i++) {
            if (qtrs.includes(month)) {
                break;
            }
            month++;
        }
        return (month - 1);
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, getMonth ${error}`);
    }
}

function* updateUserStartDate(selectedDate, startDate, periodicity, duration, minDate, isIntervalSelected) {
    let tempSelectedDate = selectedDate.clone();
    const isStartDate = true;

    try {
        tempSelectedDate = yield call(minRangeBasedOnPeriodicity, tempSelectedDate, startDate, periodicity, duration, isStartDate, isIntervalSelected);
        tempSelectedDate = yield call(updateDayBasedOnPeriodicity, periodicity, tempSelectedDate, "", minDate);

        if (tempSelectedDate < minDate) {
            startDate = minDate;
        }
        else if (tempSelectedDate == minDate) {
            startDate = minDate;
        }
        else {
            startDate = tempSelectedDate;
        }
        return startDate.format('MM-DD-YYYY');
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, updateUserStartDate ${error}`);
    }
}

function* updateUserEndDate(selectedDate, endDate, periodicity, duration, maxDate, isIntervalSelected) {
    let tempSelectedDate = selectedDate.clone();
    const isStartDate = false;

    try {
        tempSelectedDate = yield call(minRangeBasedOnPeriodicity, tempSelectedDate, endDate, periodicity, duration, isStartDate, isIntervalSelected);
        tempSelectedDate = yield call(updateDayBasedOnPeriodicity, periodicity, tempSelectedDate);

        if (tempSelectedDate > maxDate || tempSelectedDate === undefined) {
            endDate = maxDate;
        }
        else {
            endDate = tempSelectedDate;
        }
        return endDate.format('MM-DD-YYYY');
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, updateUserEndDate ${error}`);
    }
}

function* handleInputChange({ input, isUserEdit }) {
    const calendarData = yield select(getCalendarData);

    let { startDate, endDate } = calendarData;
    const { viewDate, minDate, maxDate, showCalendarLeft, duration, periodicity, isIntervalSelected } = calendarData;

    try {
        input = moment(input, "MM-DD-YYYY");
        if (input.isValid() && input > minDate && input < maxDate) {
            yield call(resetCalendar, input, isUserEdit);
        }
        else {
            if (showCalendarLeft) {
                startDate = viewDate.format('MM-DD-YYYY');
                endDate = yield call(updateUserEndDate, viewDate, endDate, periodicity, duration, maxDate, isIntervalSelected);
            }
            else {
                endDate = viewDate.format('MM-DD-YYYY');
                startDate = yield call(updateUserStartDate, viewDate, startDate, periodicity, duration, minDate, isIntervalSelected);
            }

            yield put({
                type: ActionTypes.UPDATE_INPUT_VALUE,
                data: { startDate, endDate, selectedDate: viewDate, viewDate: endDate, isUserEdit }
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, handleInputChange ${error}`);
    }
}

function* showCalendar(toggleData, event, isSetSelectedDateActive) {
    let showLeft, showRight, classes, styles, right, displayDate, isMostRecentTradeDayTb, start, end;
    const {
        showCalendarLeft,
        showCalendarRight,
        viewDate,
        startDate,
        endDate
    } = toggleData;

    try {
        switch (event) {
            case "left":
                {
                    displayDate = moment(startDate, "MM-DD-YYYY", true);
                    displayDate = isNaN(displayDate.date()) ? viewDate : moment(startDate, "MM-DD-YYYY", true);

                    if (showCalendarRight) {
                        showLeft = true;
                        showRight = false;
                        right = "160px";
                    }
                    else {
                        showLeft = true;
                        showRight = false;
                        right = "160px";
                    }
                }
                break;

            case "right":
                {
                    displayDate = moment(endDate, "MM-DD-YYYY", true);
                    displayDate = isNaN(displayDate.date()) ? viewDate : moment(endDate, "MM-DD-YYYY", true);
                    isMostRecentTradeDayTb = true;

                    if (showCalendarLeft) {
                        showLeft = false;
                        showRight = true;
                        right = "50px";
                    }
                    else {
                        showLeft = false;
                        showRight = true;
                        right = "50px";
                    }
                }
                break;

            case "close":
                {
                    if (!isSetSelectedDateActive) {
                        let reg = new RegExp("^[0-3]?[0-9].[0-3]?[0-9].(?:[0-9]{2})?[0-9]{2}$");
                        if (showCalendarLeft) {
                            start = reg.test(startDate) ? startDate : moment(viewDate).format('MM-DD-YYYY');
                            end = endDate;
                            displayDate = moment(start, "MM-DD-YYYY", true);
                            showLeft = false;
                        }
                        else {
                            start = startDate;
                            end = reg.test(endDate) ? endDate : moment(viewDate).format('MM-DD-YYYY');
                            displayDate = moment(end, "MM-DD-YYYY", true);
                            showRight = false;
                        }
                    }
                    else {
                        start = startDate;
                        end = endDate;
                        displayDate = moment(viewDate).format("MM-DD-YYYY");
                        showLeft = false;
                        showRight = false;
                    }

                    yield put({
                        type: ActionTypes.UPDATE_INPUT_VALUE,
                        data: { startDate: start, endDate: end, selectedDate: viewDate, viewDate: end, isUserEdit: false }
                    });
                }
                break;
            default:
                break;
        }

        classes = {
            "bootstrap-datetimepicker-widget": true,
            "dropdown-menu": true,
        };

        styles = {
            display: "block",
            bottom: "-74px",
            width: "inherit",
            padding: "0px",
            top: "inherit",
            left: "inherit",
            right: right
        };

        return {
            showCalendarLeft: showLeft,
            showCalendarRight: showRight,
            widgetClasses: classes,
            widgetStyle: styles,
            selectedDate: displayDate,
            viewDate: displayDate,
            isMostRecentTradeDayTb
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, showCalendar ${error}`);
    }
}

function* populatePeriodictyDurationDict(initialDate, lastTradeDate) {
    try {
        const duration = {
            [GraphType.Daily]: 1,
            [GraphType.Weekly]: 5,
            [GraphType.Monthly]: 20,
            [GraphType.Quarterly]: 60,
            [GraphType.Annual]: lastTradeDate.year() - initialDate.year()
        }
        return duration;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, populatePeriodictyDurationDict ${error}`);
    }
}

function* populatePeriodicityOption(periodicityDuration) {
    try {
        const intervals = {
            [GraphType.Daily]: `${LocalizationStore.getTranslatedData("CCG_DailyData", `Daily Data,Up to ${periodicityDuration.Daily} Year`)}`,
            [GraphType.Weekly]: `${LocalizationStore.getTranslatedData("CCG_WeeklyData", `Weekly Data,Up to ${periodicityDuration.Weekly} Years`)}`,
            [GraphType.Monthly]: `${LocalizationStore.getTranslatedData("CCG_MonthlyData", `Monthly Data,Up to ${periodicityDuration.Monthly} Years`)}`,
            [GraphType.Quarterly]: `${LocalizationStore.getTranslatedData("CCG_QuarterlyData", `Quarterly Data,Up to ${periodicityDuration.Quarterly} Years`)}`,
            [GraphType.Annual]: `${LocalizationStore.getTranslatedData("CCG_AnnualData", `Annual Data,Up to ${periodicityDuration.Annual} Years`)}`,
        }
        return intervals;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, populatePeriodicityOption ${error}`);
    }
}

/***************************** Subroutines ************************************/

function* resetCalendar({ input = "", isUserEdit = false, setLoading = false }) {
    const calendarData = yield select(getCalendarData);
    let stateData = {
        startDate: calendarData.startDate,
        endDate: calendarData.endDate,
        selectedDate: calendarData.selectedDate,
        viewDate: calendarData.viewDate,
        showCalendarLeft: calendarData.showCalendarLeft,
        showCalendarRight: calendarData.showCalendarRight,
        minDate: calendarData.minDate,
        maxDate: calendarData.maxDate,
        duration: calendarData.duration,
        periodicity: calendarData.periodicity,
        isIntervalSelected: calendarData.isIntervalSelected
    };

    try {
        stateData = yield call(setSelectedDate, { ...stateData }, input);

        yield put({
            type: ActionTypes.RESET_CALENDAR_SUCCESS,
            stateData,
            isUserEdit
        });
        yield put(initCompChart(true, true, setLoading));
        if (setLoading == false) {
            yield put({ type: ActionTypes.SAVE_SETTINGS });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, resetCalendar ${error}`);
    }
}

function* toggleCalendar({ event, isSetSelectedDateActive }) {
    const calendarData = yield select(getCalendarData);
    let toggleData = {
        showCalendarLeft: calendarData.showCalendarLeft,
        showCalendarRight: calendarData.showCalendarRight,
        selectedDate: calendarData.selectedDate,
        viewDate: calendarData.viewDate,
        startDate: calendarData.startDate,
        endDate: calendarData.endDate
    };

    try {
        toggleData = yield call(showCalendar, { ...toggleData }, event, isSetSelectedDateActive);

        yield put({
            type: ActionTypes.TOGGLE_CALENDAR_SUCCESS,
            toggleData
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, toggleCalendar ${error}`);
    }
}

function* setLatestTradingDate({ results }) {
    const calendarData = yield select(getCalendarData);
    const periodicity = calendarData.periodicity;

    try {
        //const pacificOffset = DateHelper.getPacificOffset(DateHelper.getTicks(results.EndDate));
        const startSecondsObj = DateHelper.getUtcTicksfromTimeStamp(results.StartDate);//, pacificOffset);
        const endSecondsObj = DateHelper.getUtcTicksfromTimeStamp(results.EndDate);//, pacificOffset);
        const initialDate = moment(startSecondsObj);
        const lastTradeDate = moment(endSecondsObj);

        yield put({
            type: ActionTypes.UPDATE_LATEST_TRADING_DATE,
            initialDate,
            lastTradeDate,
        });

        const periodicityDuration = yield call(populatePeriodictyDurationDict, initialDate, lastTradeDate, calendarData.intervals);
        yield put({
            type: ActionTypes.UPDATE_PERIODICITY_DURATION,
            periodicityDuration,
            duration: periodicityDuration[periodicity]
        });

        const periodicityDispalyLabel = yield call(populatePeriodicityOption, periodicityDuration);
        yield put({
            type: ActionTypes.UPDATE_PERIODICITY_DISPLAY_LABEL,
            periodicityDispalyLabel,
            title: periodicityDispalyLabel[periodicity]
        });

        if (isEmpty(calendarData.endDate)) {
            let startDate = calendarData.startDate;
            if (isEmpty(calendarData.startDate)) {
                startDate = lastTradeDate.clone().subtract(periodicityDuration[periodicity], 'years').format('MM-DD-YYYY')
                startDate = yield call(updateUserStartDate, lastTradeDate, startDate, periodicity, calendarData.duration, initialDate, calendarData.isIntervalSelected);
            }

            yield put({
                type: ActionTypes.UPDATE_LATEST_USER_DATES,
                endDate: lastTradeDate.format('MM-DD-YYYY'),
                startDate: startDate
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, setLatestTradingDate ${error}`);
    }
}

function* callUpdateUserStartDate({ userStartDate }) {
    const calendarData = yield select(getCalendarData);
    const { endDate, periodicity, duration, maxDate, isIntervalSelected } = calendarData;

    try {
        const userEndDate = yield call(updateUserEndDate, userStartDate, endDate, periodicity, duration, maxDate, isIntervalSelected);

        yield put({
            type: ActionTypes.UPDATE_LATEST_USER_DATES,
            startDate: userStartDate.format('MM-DD-YYYY'),
            endDate: userEndDate
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, callUpdateUserStartDate ${error}`);
    }
}

function* callUpdateUserEndDate({ userEndDate }) {
    const calendarData = yield select(getCalendarData);
    const { startDate, periodicity, duration, minDate, isIntervalSelected } = calendarData;

    try {
        const userStartDate = yield call(updateUserStartDate, userEndDate, startDate, periodicity, duration, minDate, isIntervalSelected);

        yield put({
            type: ActionTypes.UPDATE_LATEST_USER_DATES,
            startDate: userStartDate,
            endDate: userEndDate.format('MM-DD-YYYY')
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in compareCalendar.js, callUpdateUserEndDate ${error}`);
    }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchResetCalendar() {
    yield takeLatest(ActionTypes.RESET_CALENDAR, resetCalendar);
}

export function* watchOnChangeInputValue() {
    yield takeLatest(ActionTypes.HANDLE_INPUT_CHANGE, handleInputChange);
}

export function* watchToggleCalendar() {
    yield takeLatest(ActionTypes.TOGGLE_CALENDAR, toggleCalendar);
}

export function* watchSetLatestTradingDate() {
    yield takeLatest(ActionTypes.SET_LATEST_TRADING_DATE, setLatestTradingDate);
}

export function* watchUpdateUserStartDate() {
    yield takeLatest(ActionTypes.UPDATE_USER_START_DATE, callUpdateUserStartDate);
}

export function* watchUpdateUserEndDate() {
    yield takeLatest(ActionTypes.UPDATE_USER_END_DATE, callUpdateUserEndDate);
}