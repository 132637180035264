import { connect } from "react-redux";
import GraphApi from "../../../../../ServiceApi/Apis/GraphApi.js";
import { PrintMode } from "../../../../../print/printmode";
import React from "react";
import ThemeHelper from "ThemeHelper";
import {ideaMouseDown,ideaMouseOver,handleMouseLeave} from "../../../../../Actions/PricePanelActions"

const ideasButton = (props) => {
    const summaryHeaderText = ThemeHelper.getThemedBrush("summaryBlockHeaderText");
    const getDownloadIdeaAnalysisReportWebRequest = (pdfResearchPath) => GraphApi.getPdfRequestURL(pdfResearchPath);
    return (<>
        {props.videoMode === false && props.tempIdeas.map((idea) => idea.IdeaShortText && idea.IdeaShortText.length > 0 &&
            <div className={"btn btn-xs ideas-button btn-idea"}
                key={idea.IdeaShortText}
                onMouseDown={() => props.handleMouseDown(idea.ideaType)}
                onMouseOver={() => props.handleMouseOver(idea.ideaType)}
                onFocus={() => props.handleMouseOver(idea.ideaType)}
                onMouseLeave={() => props.handleMouseLeave()}
                data-disable-track-price="true">
                <span className={idea.IdeaShortText ? `${idea.IdeaStyle  } idea-sign-flag` : "idea-sign-flag"}></span>
                <span className="idea-text" style={{ color: summaryHeaderText }}>{idea.IdeaShortText}</span>
                <span className="idea-figure" style={{ color: idea.isPositive ? props.posDTColor : props.negDTColor }}>{idea.IdeaInfoText}</span>
                {idea.IdeaPDFPath && !PrintMode.printing && <span className="idea-pdf-btn"><a className="small-normal" href={getDownloadIdeaAnalysisReportWebRequest(idea.IdeaPDFPath)}>PDF</a></span>}
                {idea.IdeaVideoPath && !PrintMode.printing && <span className="idea-video-btn"><a target="_blank" className="video-icon" href={idea.IdeaVideoPath}></a></span>}
            </div>
        )}</>)
}

const mapStateToProps = ({DatagraphReducers,RelatedInfoPanelReducers, appColor}) => {
    const { videoMode } = DatagraphReducers.PriceChartReducer;
    const { tempIdeas } = RelatedInfoPanelReducers.RIPanelOneilReducers;
    const { posDTColor, negDTColor, isThemeChanged } = appColor;
    return { tempIdeas, videoMode, posDTColor, negDTColor, isThemeChanged }
}

const mapDispatchToProps = (dispatch) => ({
    handleMouseDown: (ideaType) => dispatch(ideaMouseDown(ideaType)),
    handleMouseOver: (ideaType) => dispatch(ideaMouseOver(ideaType)),
    handleMouseLeave: () => dispatch(handleMouseLeave())
});
export default connect(mapStateToProps, mapDispatchToProps)(ideasButton);