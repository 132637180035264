import { AnnotationConstant } from "Constants/AnnotationConstants";
import { connect } from 'react-redux';
import { getSelectedAnnotationInfo } from '../../../../../../Reducers/AnnotationReducers/reselectorsdata';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import PortalContainer from 'PortalContainer';
import { StrokeStyleType } from 'Constants/EAnnotationType';
import { DropdownButton, MenuItem } from "react-bootstrap";
import { handleStrokeChange, updateTargetAnnotation } from "Actions/AnnotationActions";
import React, { PureComponent } from 'react';

const { ActionTypes } = AnnotationConstant;
class AnnotationsStrokeSettings extends PureComponent {

    constructor() {
        super();
        this.state = {
            isShowDropdown: false,
            style: {},
            hoveredStrokeIndex: -1
        }
        this.container = React.createRef();
        this.onDocumentClick = this.onDocumentClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.onDocumentClick);
        window.addEventListener('resize', this.onResize);
        document.addEventListener("keydown", this.onDocumentKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.onDocumentClick);
        window.removeEventListener('resize', this.onResize);
        document.addEventListener("keydown", this.onDocumentKeyPress);
    }

    componentDidUpdate() {
        if (this.state.hoveredStrokeIndex === -1) {
            let initialStrokeIndex = -1
            switch (this.props.modelInfo.strokeStyle) {
                case StrokeStyleType.SOLID_StrokeStyleType:
                    initialStrokeIndex = 0;
                    break;
                case StrokeStyleType.DASHED_StrokeStyleType:
                    initialStrokeIndex = 1;
                    break;
                case StrokeStyleType.DOTTED_StrokeStyleType:
                    initialStrokeIndex = 2;
                    break;
                default:
                    break;
            }
            this.setState({ ...this.state, hoveredStrokeIndex: initialStrokeIndex })
        }
    }

    onDocumentClick = (event) => {

        if (
            (this.container.current &&
                !this.container.current.contains(event.target))
        ) {
            this.setState({
                isShowDropdown: false,
                hoveredStrokeIndex: -1
            });
        }
    }

    onResize = () => {
        this.setState({
            isShowDropdown: false,
            hoveredStrokeIndex: -1
        });
    }

    onDocumentKeyPress = (e) => {
        if (e.keyCode === KeyCodes.UP_ARROW && this.state.isShowDropdown) {
            e.preventDefault();
            if (this.state.hoveredStrokeIndex > 0) {
                this.setState({ ...this.state, hoveredStrokeIndex: this.state.hoveredStrokeIndex - 1 })
            }
        }
        else if (e.keyCode === KeyCodes.DOWN_ARROW && this.state.isShowDropdown && this.state.hoveredStrokeIndex < 2) {
            this.setState({ ...this.state, hoveredStrokeIndex: this.state.hoveredStrokeIndex + 1 })
        }
        else if (e.keyCode === KeyCodes.ENTER && this.state.isShowDropdown && this.state.hoveredStrokeIndex > -1) {
            switch (this.state.hoveredStrokeIndex) {
                case 0:
                    this.onStrokeChange(StrokeStyleType.SOLID_StrokeStyleType, this.props.type);
                    break;
                case 1:
                    this.onStrokeChange(StrokeStyleType.DASHED_StrokeStyleType, this.props.type);
                    break;
                case 2:
                    this.onStrokeChange(StrokeStyleType.DOTTED_StrokeStyleType, this.props.type);
                    break;
                default:
                    break;
            }
            this.toggleDropdown();
        }
    }

    onStrokeChange = (style, type) => {
        const weight = type === "line" ? this.props.modelInfo.weight : this.props.modelInfo.borderWeight;
        let stroke = [];

        switch (style) {
            case StrokeStyleType.SOLID_StrokeStyleType:
                stroke = [];
                break;
            case StrokeStyleType.DASHED_StrokeStyleType:
                stroke = [weight * 2.5, weight];
                break;
            case StrokeStyleType.DOTTED_StrokeStyleType:
                stroke = [weight, weight];
                break;
            default:
                break;
        }

        let action = '';
        if (type === "line") {
            action = ActionTypes.HANDLE_STROKE_CHANGE;
        }
        else {
            action = ActionTypes.HANDLE_BORDER_STROKE_CHANGE;
        }

        this.props.handleStrokeChange(action, stroke, style);

        if (this.props.annotSelected && (this.props.annotSelected.type === this.props.selectedToolType || this.props.annotSelected.type === this.props.selectedShapeType)) {
            this.props.updateTargetAnnotation(action, { stroke: stroke, style: style });
        }

        this.setState({ ...this.state, hoveredStrokeIndex: - 1, isShowDropdown: false })
    }

    toggleDropdown = () => {
        const rect = this.dropDownButton.getBoundingClientRect();
        const style = { position: 'absolute', left: rect.left, top: rect.bottom };
        this.setState({ isShowDropdown: !this.state.isShowDropdown, style: style });
    }

    hideDropDownMenu = () => {
        if (this.state.isShowDropdown) {
            this.setState({ isShowDropdown: false, style: {} });
        }
    }

    render() {
        const { type, label } = this.props;
        const stroke = type === "line" ? this.props.modelInfo.stroke : this.props.modelInfo.borderStroke;
        const strokeStyle = this.props.modelInfo.strokeStyle;
        const selectedLine = strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType ? "dotted" : strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? "dashed" : "lined";
        return (
            <>
                {label && <span className="swatches-label">STROKE</span>}
                <div className="swatches-field stroke" ref={(ref) => (this.dropDownButton = ref)}>
                    <DropdownButton className="" noCaret id="annot-dropdown-stroke" title={<span className={`selected-stroke ${selectedLine}`}></span>} onClick={this.toggleDropdown}>
                        {this.state.isShowDropdown &&
                            <PortalContainer>
                                <ul className="portal-dropdown stroke-dropdown" style={this.state.isShowDropdown ? this.state.style : {}} ref={this.container}>
                                    <MenuItem key={StrokeStyleType.SOLID_StrokeStyleType} className={(this.state.hoveredStrokeIndex === 0 && strokeStyle === StrokeStyleType.SOLID_StrokeStyleType) ? 'selectHover' : this.state.hoveredStrokeIndex === 0 ? 'key-press-hover' : strokeStyle === StrokeStyleType.SOLID_StrokeStyleType ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredStrokeIndex: 0 })} onSelect={() => this.onStrokeChange(StrokeStyleType.SOLID_StrokeStyleType, type)} ><u className="icn-select"></u><i className="lined"></i></MenuItem>
                                    <MenuItem key={StrokeStyleType.DASHED_StrokeStyleType} className={(this.state.hoveredStrokeIndex === 1 && strokeStyle === StrokeStyleType.DASHED_StrokeStyleType) ? 'selectHover' : this.state.hoveredStrokeIndex === 1 ? 'key-press-hover' : strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredStrokeIndex: 1 })} onSelect={() => this.onStrokeChange(StrokeStyleType.DASHED_StrokeStyleType, type)} ><u className="icn-select"></u><i className="dashed"></i></MenuItem>
                                    <MenuItem key={StrokeStyleType.DOTTED_StrokeStyleType} className={(this.state.hoveredStrokeIndex === 2 && strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType) ? 'selectHover' : this.state.hoveredStrokeIndex === 2 ? 'key-press-hover' : strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType ? 'selective' : ''} onMouseEnter={() => this.setState({ ...this.state, hoveredStrokeIndex: 2 })} onSelect={() => this.onStrokeChange(StrokeStyleType.DOTTED_StrokeStyleType, type)} ><u className="icn-select"></u><i className="dotted"></i></MenuItem>
                                </ul>
                            </PortalContainer>}
                    </DropdownButton>
                </div>
            </>
        );
    }
}

AnnotationsStrokeSettings.propTypes = {

};

const mapStateToProps = (state) => ({
    modelInfo: getSelectedAnnotationInfo(state),
    annotSelected: state.annotationReducers.annotationChartModelReducer.annotSelected,
    selectedToolType: state.annotationReducers.annotationMenuReducer.selectedToolType,
    selectedShapeType: state.annotationReducers.annotationMenuReducer.ShapesModelInfo.selectedType
})

const mapDispatchToProps = (dispatch) => ({
    handleStrokeChange: (action, stroke, strokeStyle) => dispatch(handleStrokeChange(action, stroke, strokeStyle)),
    updateTargetAnnotation: (action, data) => updateTargetAnnotation(action, data)
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AnnotationsStrokeSettings);
