export const Colors = [
    ["#000000", "#262626", "#4c4c4c", "#999999","#cccccc", "#ffffff"], //black
    ["#003333","#006666","#009999","#00cccc","#33ffff","#99ffff"], //lightBlue
    ["#000033","#013cb3","#0066cc","#3399ff","#66ccff","#99ccff"], //blue
    ["#340033","#340066","#660099","#6734cc","#9866ff","#cdccff"], //violet
    ["#330000","#670000","#990000","#cc0100","#ff0000","#fdbcbc"], //red1
    ["#5b1b00","#ae3e00","#fc6901","#fd8548","#fda57d","#ffcc99"], //red2
    ["#333300","#676700","#999900","#cbcb01","#ffff00","#ffff9a"], //green1
    ["#003300","#006600","#089800","#09cb01","#34ff33","#99ff99"], //green2
]

export const ColorsOnHover = [
    ["#595959", "#6e6e6e", "#898989", "#bfbfbf","#dfdfdf", "#dfdfdf"],  //black
    ["#5a7a79","#5e9a99","#65bfbe","#6de0de","#85ffff","#c1ffff"],  //lightBlue
    ["#595978","#5a78cf","#6096df","#7dbcff","#9cdeff","#bfdeff"], //blue
    ["#785979","#785999","#9859bf","#9867de","#bd90ff","#dfddff"],  //violet
    ["#795959","#995959","#bd595a","#de59fb","#fd595c","#fed3d5"], //red1
    ["#926859","#c87c5a","#fe9b5d","#fead8a","#fec4aa","#fedfbf"], //red2
    ["#7a7a59","#999b5a","#bebf5b","#dfe05d","#ffff60","#ffffbf"], //green1
    ["#5a7a59","#619b59","#6bc15a","#71e25c","#86ff7a","#c2ffbf"], //green2
]

export const DefaultColors = {
    Magenta:"#f0009d",
    Blue: "#0065d1",
    Green :"#089800",
    Red :"#ff0000",
    //Sync Story 76592:n-Up Price/Volume Node Color Correction
    OtherLightBlue:"#2736e9",
    OtherLightMagenta:"#de32ae",
    OtherDarkBlue:"#0066CC",
    OtherDarkMagenta: "#CC0099",
    VideoDarkBlue:"#88FFFF"
}

export const DefaultHoverColors = {
    Magenta:"#ff99ff",
    Blue: "#9999ff",
    Green :"#6bc15a",
    Red :"#fd595c",
    OtherLightBlue:"#737cf1",
    OtherLightMagenta:"#ea7aca",
    OtherDarkBlue:"#599cde",
    OtherDarkMagenta:"#de59bd",
}

export const DefaultTextColors = {
    Magenta:"#ff33cc",
    Blue: "#018fee",
    Green :"#089800",
    Red: "#ff0000",
    VideoBlue: "#88FFFF"
}

export const DefaultTextHoverColors = {
    Magenta:"#fd7ef2",
    Blue: "#77cafe",
    Green :"#6bc15a",
    Red :"#fd595c",
}

export const PosOrNeg = {
    Positive:"Positive",
    Negative:"Negative"
}

/*Color will be implemented to data table such as SummaryBlock etc.
or color will be implemented to datagraph such as price etc.*/
export const ColorCatalog = {
    DataGraph: "DataGraph",
    DataTable: "DataTable"
}

export const UseDefaultColor = {
    Yes: "Yes",
    No: "No"
}

export const ActionTypes = {
    INIT_DARK_APP_COLOR:"INIT_DARK_APP_COLOR",
    INIT_LIGHT_APP_COLOR:"INIT_LIGHT_APP_COLOR",
    CHANGE_DARK_POSITIVE_COLOR:"CHANGE_DARK_POSITIVE_COLOR",
    CHANGE_DARK_NEGATIVE_COLOR:"CHANGE_DARK_NEGATIVE_COLOR",
    CHANGE_LIGHT_POSITIVE_COLOR:"CHANGE_LIGHT_POSITIVE_COLOR",
    CHANGE_LIGHT_NEGATIVE_COLOR:"CHANGE_LIGHT_NEGATIVE_COLOR",

    CHANGE_DARK_POSITIVE_DT_COLOR:"CHANGE_DARK_POSITIVE_DT_COLOR",
    CHANGE_DARK_NEGATIVE_DT_COLOR:"CHANGE_DARK_NEGATIVE_DT_COLOR",
    CHANGE_LIGHT_POSITIVE_DT_COLOR:"CHANGE_LIGHT_POSITIVE_DT_COLOR",
    CHANGE_LIGHT_NEGATIVE_DT_COLOR:"CHANGE_LIGHT_NEGATIVE_DT_COLOR",

    RESTORE_DARK_COLOR:"RESTORE_DARK_COLOR",
    RESTORE_LIGHT_COLOR:"RESTORE_LIGHT_COLOR",
    TOGGLE_COLOR_PALETTE:"TOGGLE_COLOR_PALETTE",
    CUSTOM_COLOR_CHANGED:"CUSTOM_COLOR_CHANGED",
    TOGGLE_COLOR_PALETTE_PANEL:"TOGGLE_COLOR_PALETTE_PANEL",

    CUSTOM_THEME_CHANGED:"CUSTOM_THEME_CHANGED",

    CHANGE_GRID_LINE_COLOR: null
}
