import React from "react";
import onClickOutside from "react-onclickoutside";
import NonEditableFilter from "./NonEditableFilter.jsx";
import EditableFilter from "./EditableFilter.jsx";
import MultiSelectFilter from "./MultiSelectFilter.jsx";
import ListManagerTabType from "ListManagerTabType";
import SettingsStore from "SettingsStore";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import ColumnCustomFilterControl from "./ColumnCustomFilterControl.jsx";
import BaseServiceApi from "BaseServiceApi";
import BrowserUtil from "BrowserUtil";
let ListColumnDataType = BaseServiceApi.rayData["ListColumnDataType"];
let ListColumnFilterType = BaseServiceApi.rayData["ListColumnFilterType"];
import { OwnershipViewConstants } from "../../Constants/OwnershipConstants";

class CustomColumnFilter extends React.Component {

    constructor(props) {
        super(props);
        this.outsideClickDocument = this.outsideClickDocument.bind(this);
        this.getcustomColumnFilter = this.getcustomColumnFilter.bind(this);
        this.oNeilViewStoreStateChange = this.oNeilViewStoreStateChange.bind(this);
        this.ownershipViewStoreStateChange = this.ownershipViewStoreStateChange.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.applyFilterMultipleData = this.applyFilterMultipleData.bind(this);
        this.closeFilterWindow = this.closeFilterWindow.bind(this);
        this.onOusideClickExecApply = this.onOusideClickExecApply.bind(this);
        this.setCurrentFilterWidthHeight = this.setCurrentFilterWidthHeight.bind(this);
        this.offset = this.offset.bind(this);
        this.getPos = this.getPos.bind(this);
        this.getPickListValues = this.getPickListValues.bind(this);
        this.state = {
            pickList: this.getPickListValues(),
            width: 0,
            height: 0
        }
    }
    UNSAFE_componentWillMount() {
        // let settings = SettingsStore.getConsoleSettings();
        // let navListManagerSettings = settings.NavListManagerSettings;
        ONeilViewStore.addChangeListener(this.oNeilViewStoreStateChange);
        // if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Ownership) {
            OwnershipViewStore.addChangeListener(this.ownershipViewStoreStateChange);
        //}
    }

    componentWillUnmount() {
        // let settings = SettingsStore.getConsoleSettings();
        // let navListManagerSettings = settings.NavListManagerSettings;
        ONeilViewStore.removeChangeListener(this.oNeilViewStoreStateChange);
        // if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Ownership) {
            OwnershipViewStore.removeChangeListener(this.ownershipViewStoreStateChange);
        //}
    }

    componentDidMount() {
        this.setCurrentFilterWidthHeight();
    }


    handleClickOutside(e) {
        this.outsideClickDocument(e);
    }

    getPickListValues() {
        setTimeout(() => {
            let settings = SettingsStore.getConsoleSettings();
            let navListManagerSettings = settings.NavListManagerSettings;
            if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Oneil) {
                if (this.props.colData.dataItemId === ONeilViewStore.getState().pickListData.dataItemId) {
                    return ONeilViewStore.getState().pickListData.values;
                }
            }
            if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Ownership) {
                if (this.props.colData.dataItemId === OwnershipViewStore.getState().pickListData.dataItemId) {
                    return OwnershipViewStore.getState().pickListData.values;
                }
            }

            return null;
        }, 0);  
    }

    setCurrentFilterWidthHeight() {
        let currentDiv;
        if (this.props.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) {
            currentDiv = this.MultiSelectFilter.getDiv();
        }
        else {
            if (this.props.colData.dataType == ListColumnDataType.STRING_ListColumnData || this.props.colData.dataType == ListColumnDataType.BOOL_ListColumnData) {
                currentDiv = this.NonEditableFilter.getDiv();
            }
            else {
                currentDiv = this.EditableFilter.getDiv();
            }
        }
        let offsets = currentDiv.getBoundingClientRect();
        let width = offsets.width;
        let height = offsets.height;
        this.setState({ width: width, height: height }, this.props.columnFilterUpdate);
    }

    oNeilViewStoreStateChange() {
        let currentAction = ONeilViewStore.getCurrentAction();
        let state = ONeilViewStore.getState();
        if (currentAction == ListExplorerConstants.ActionTypes.GET_COLUMN_PICKLIST) {
            this.setState({
                pickList: state.pickListData.values
            });
        }
    }

    ownershipViewStoreStateChange() {
        let currentAction = OwnershipViewStore.getCurrentAction();
        let state = OwnershipViewStore.getState();
        if (currentAction == OwnershipViewConstants.ActionTypes.GET_COLUMN_PICKLIST_OWNERSHIP) {
            this.setState({
                pickList: state.pickListData.values
            });
        }
    }

    outsideClickDocument(e) {
        try {
            const target = e.target || e.srcElement;
            if (!ColumnCustomFilterControl.isMetricLibOpen() && !target.classList.contains('metricClose') && target.className != "add-column-button") {
                this.onOusideClickExecApply();
            }
        }
        catch (ex) {
        }
        e.stopPropagation();
    }

    onOusideClickExecApply() {
        if (this.props.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) {
            this.MultiSelectFilter.applyFilter();
        }
        else {
            if (this.props.colData.dataType == ListColumnDataType.STRING_ListColumnData || this.props.colData.dataType == ListColumnDataType.BOOL_ListColumnData) {
                this.NonEditableFilter.applyFilter();
            }
            else {
                this.EditableFilter.applyFilter();
            }
        }
    }

    applyFilter(operator, value, isPickList = false, error = false) {
        if (!error) {
            let colInfo = this.props.colData;
            let ObjerrorMessage = { errorMessage: "" };
            ColumnCustomFilterControl.setFieldTagExtListToColumn(operator, value, colInfo, isPickList, ObjerrorMessage).then(() => {
                const addlRequestData = ColumnCustomFilterControl.applyFilters();
                if (ObjerrorMessage.errorMessage == "") {
                    this.props.applyFilter(addlRequestData);
                }

            });

        }
        this.closeFilterWindow();
    }

    applyFilterMultipleData(multipleData, numberofData, isPickList = false, error = false) {
        if (!error) {
            let colInfo = this.props.colData;
            let ObjerrorMessage = { errorMessage: "" };
            ColumnCustomFilterControl.setFieldTagExtListToColumn(null, null, colInfo, isPickList, ObjerrorMessage, null, false, numberofData, multipleData).then(() => {
                const addlRequestData = ColumnCustomFilterControl.applyFilters();
                if (ObjerrorMessage.errorMessage == "") {
                    this.props.applyFilter(addlRequestData);
                }
            });
        }
        this.closeFilterWindow();
    }

    closeFilterWindow() {
        this.props.outsideColumnFilterClick();
    }

    getcustomColumnFilter() {
        let customColumnFilter = null;
        let activeColumnDetails = ColumnCustomFilterControl.getColumnValue(this.props.colData.dataItemId);
        //PickList
        if (this.props.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) {
            customColumnFilter = <MultiSelectFilter ref={(r) => this.MultiSelectFilter = r}
                applyFilter={this.applyFilter}
                pickList={this.state.pickList}
                colData={this.props.colData}
                activeColumn={activeColumnDetails}
                onOusideClickExecApply={this.onOusideClickExecApply} />;
        }
        else {
            if (this.props.colData.dataType == ListColumnDataType.STRING_ListColumnData || this.props.colData.dataType == ListColumnDataType.BOOL_ListColumnData) {
                customColumnFilter = <NonEditableFilter ref={(r) => this.NonEditableFilter = r} applyFilter={this.applyFilter} colData={this.props.colData} activeColumn={activeColumnDetails} setCurrentFilterWidthHeight={this.setCurrentFilterWidthHeight} />;
            }
            else {
                customColumnFilter = <EditableFilter ref={(r) => this.EditableFilter = r} applyFilterMultipleData={this.applyFilterMultipleData} colData={this.props.colData} activeColumn={activeColumnDetails} setCurrentFilterWidthHeight={this.setCurrentFilterWidthHeight} />;
            }
        }
        return customColumnFilter;
    }

    offset(el) {
        let rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    getPos(ele) {
        let pos = this.offset(ele);
        let tops = pos.top - (this.state.height / 2) + 15;
        let lefts = pos.left - this.state.width - 15;
        let minimumBoundary = 5;
        let top = (this.props.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) ? pos.top : tops;
        top = Math.ceil(top);
        let left = (this.props.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) ? (pos.left - parseInt(this.state.width / 3)) : lefts;
        left = Math.ceil(left);
        let lastPos = left + this.state.width;
        let shiftPos = lastPos - screen.width;
        left = (lastPos > screen.width) ? left - shiftPos - 80 : left;
        if (top < minimumBoundary) {
            top = minimumBoundary;
        }

        if (left < minimumBoundary) {
            left = minimumBoundary;
        }
        return { left: left, top: top };
    }


    render() {
        const customColumnFilter = this.getcustomColumnFilter();
        let pos = null;
        if (this.state.width != 0 && this.state.height != 0)
            pos = this.getPos(this.props.targets);
        let posType = 'fixed';
        if (BrowserUtil.getBrowserName() == 'IE' || BrowserUtil.getBrowserName() == 'Netscape') {
            posType = '-ms-page';
        }
        let styled = (pos != null) ? { left: pos.left, position: posType, top: pos.top, zIndex: '99' } : null;
        return (
            <div id="columnFilterComponent" style={styled}>
                {customColumnFilter}
            </div>
        );
    }
}

export default onClickOutside(CustomColumnFilter);
