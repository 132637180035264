import Serializable from "Serializable";
class ReportedEarningsSettings extends Serializable {
    constructor() {
        super();
        this.RevWonOnly = "0";
        this.WonOnly = "1";
    }

    onSeralisedInfoRecieved(info) {
        this.RevWonOnly = typeof info.RevWonOnly !== "undefined" ? info.RevWonOnly : "0";
        this.WonOnly = typeof info.WonOnly !== "undefined" ? info.WonOnly : "1";
    }

    getDataObject() {
        return {
            RevWonOnly: this.RevWonOnly,
            WonOnly: this.WonOnly
        };
    }

    getObjectMapKey() {
        return "ReportedEarningsSettings";
    }
}

export default ReportedEarningsSettings;
