import React from "react";
import { Modal } from "react-bootstrap";
import LocalizationStore from "LocalizationStore";

export default class ValidationWindow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="validationWindow">
        <Modal className="modal-popup folder-limit-reached" show={this.props.showModal} onHide={this.props.closeModal}>
          <Modal.Header>
            <Modal.Title>
              <span className="cap-header">{'FOLDER LIMIT REACHED'}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-region">
                {
                  !this.props.sessionExpired ?
                    <p>
                      <span className="icn-warning"></span>
                      <span className="">{LocalizationStore.getTranslatedData("LM_FolderLimitMsg", "The maximum number of nested folders is limited to five")}.</span>
                    </p>
                  :
                    ''
                }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <span className="btn-box center">
              <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.props.closeModal}>{"Close Window"}</button>
            </span>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}