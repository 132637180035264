//import GraphType from "../../../../../Constants/GraphType.js";
import Serializable from "Serializable";
class StreetTargetPriceSettings extends Serializable {
    constructor() {
        super();
        this.lineColor = 'P999900CBCB01';
        this.IsVisible = true;
        this.IsAvailable = true;
        this.showHiLowEstimate = true;
        this.showTargetPriceZones = false;
        this.showTag = false;
    }
    onSeralisedInfoRecieved(info) {
        this.lineColor = info.lineColor !== undefined ? info.lineColor : 'P999900CBCB01';
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
        this.IsAvailable = info.IsAvailable !== undefined ? info.IsAvailable : true;
        this.showHiLowEstimate = info.showHiLowEstimate !== undefined ? info.showHiLowEstimate : true;
        this.showTargetPriceZones = info.showTargetPriceZones !== undefined ? info.showTargetPriceZones : true;
        this.showTag = info.showTag !== undefined ? info.showTag : false;
    }
    getDataObject() {
        return {
            lineColor: this.lineColor,
            IsVisible: this.IsVisible,
            IsAvailable: this.IsAvailable,
            showHiLowEstimate :this.showHiLowEstimate,
            showTargetPriceZones :this.showTargetPriceZones,
            showTag: this.showTag
        };
    }
    getObjectMapKey() {
        return "StreetTargetPriceSettings";
    }
}

export default StreetTargetPriceSettings;
