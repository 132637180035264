import { EventEmitter } from "events";
import { indexBy, map, max, clone, keys, each, values, find } from "underscore";
import numberFormatter from "number-formatter";
import AppDispatcher from "AppDispatcher";
import StringUtil from "StringUtil";
import ONeilViewStore from "ONeilViewStore";
import { GridConstants } from "GridConstants";
import BaseServiceApi from "BaseServiceApi";
import ColumnCustomFilterControl from "../ListViewCustomFilter/ColumnCustomFilterControl.jsx";
import ThemeHelper from "ThemeHelper";
import LocalizationStore from 'LocalizationStore';
import FormatterUtil from "FormatterUtil";
import DatagraphDataType from "../../Constants/DatagraphDataType.js";
import NavType from "../../Constants/NavType.js";

const CHANGE_EVENT = "change";

let ValueOperatorType = BaseServiceApi.rayData["ValueOperatorType"];
class SummaryStatStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        this.state = this.getState();
        this.AggregateStatsHeaderDictionary = this.getDefaultAggregateStatsRows();
        this.DistColNum = 40;
        this.DistChartMinColnum = 5;
        this.DistChartWidth = 236;
        this.HistogramcutoffWidth = 450;
        this.HistogramcutoffgHeight = 175;
        this.MaxYAvailableForGraph = 110;
        this.headers = values(this.AggregateStatsHeaderDictionary);
        this.dataItems = {
            k1: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator

            },
            k2: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator

            },
            k3: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator
            },
            k4: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator

            },
            k5: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator

            }
        }

        this.getState = this.getState.bind(this);
        this.setDefaultState = this.setDefaultState.bind(this);
        this.openCollapseSummaryStat = this.openCollapseSummaryStat.bind(this);
        this.clearStateData = this.clearStateData.bind(this);
        this.setDefaultState = this.setDefaultState.bind(this);
        this.getSummaryData = this.getSummaryData.bind(this);
        this.getSummaryHistData = this.getSummaryHistData.bind(this);
        this.getActiveColumnName = this.getActiveColumnName.bind(this);
        this.isBenchmarkTabVisible = this.isBenchmarkTabVisible.bind(this);
        this.getDefaultAggregateStatsRows = this.getDefaultAggregateStatsRows.bind(this);
        this.replaceNonBlnkCntRow = this.replaceNonBlnkCntRow.bind(this);
        this.loadHistData = this.loadHistData.bind(this);
        this.setDistData = this.setDistData.bind(this);
        this.clearCurrentAction = this.clearCurrentAction.bind(this);
        this.clearSummaryStatCurrentAction = this.clearSummaryStatCurrentAction.bind(this);
        this.getDragedGhostName = this.getDragedGhostName.bind(this);
        this.reOrder = this.reOrder.bind(this);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getState () {
        if (!this.state) {
            this.setDefaultState();
        }
        return this.state;
    }

    setDefaultState () {
        if (ONeilViewStore) {
            const SummaryStatSettings = ONeilViewStore.getSettingsObject().SummaryStatSettings;
            this.state = {
                ...this.state,
                isDragging: false,
                isResized: false,
                currentAction: null,
                SummaryStatCurrentAction: null,
                summaryStatIsOpen: SummaryStatSettings ? SummaryStatSettings.isSummaryStatWindowOpen : false,
                summaryStatHeight: SummaryStatSettings ? SummaryStatSettings.summaryStatWindowHeight : null
            };

        }
    }


    openCollapseSummaryStat (isOpen) {
        this.state.isOpen = isOpen;
        this.state.isDragging = false;
    }

    clearStateData () {
        this.state = {};
    }

    getCellForItem(colInfo, value, isCount = false) {
        let hoverFontWeight = "Normal";
        if (colInfo.displayName == "SYMBOL") {
            hoverFontWeight = "Bold";
        }

        let txt = (value !== null && value !== undefined && value !== '') ? value.toString().trim() : '';
        let isNegative = txt != '' ? (Number(txt).toFixed(2) < 0 ? true : false) : false;
        let strVal = "";
        let colDataSet = {};
        colDataSet.isNegative = isNegative;
        txt= Number.POSITIVE_INFINITY == txt || Number.NEGATIVE_INFINITY == txt?FormatterUtil.convertToNan():txt
        if (txt != "" && !isCount) {
            strVal = ONeilViewStore.getCellStrVal(colInfo, txt, colDataSet);
        }
        else {
            strVal = txt;
        }
        let cellInfo = {
            displayValue: strVal != '' ? strVal : '',
            column: colInfo,
            fontWeight: "Normal",
            hoverFontWeight: hoverFontWeight,
            alignment: ONeilViewStore.getAlignment(colInfo),
            textWidth: ONeilViewStore.getCellTextWidth(colInfo, txt, null),
            columnWidth: this.getColumnWidth(colInfo),
            className: !isCount ? this.getClassName(colInfo, isNegative) : ""
        }
        return cellInfo;
    }

    getClassName(columnInfo, isNegative = false) {
        let className = "";
        if (columnInfo.usePosNegColoring && !isNegative)
            className += 'grid-cell-positive';
        else if (columnInfo.usePosNegColoring && isNegative)
            className += 'grid-cell-negative';
        return className;
    }

    getColumnWidth(column) {
        let colWidth = column.minWidth;
        if (column.customWidth) colWidth = column.customWidth;
        else if (column.width) colWidth = column.width;

        if (colWidth > 500) colWidth = 500;
        return colWidth;
    }

    async setDataSourceItems() {
        let headerDictionary = this.AggregateStatsHeaderDictionary;
        let columnList = ONeilViewStore.getColumnInfo();
        const count = ONeilViewStore._state.basic.count;
        if (!columnList)
            return;

        let _itemsSource = [];

        try {
            each(this.headers, (col) => {
                _itemsSource.push({
                    columnName: col,
                    dataRow: []
                });
            })
        }
        catch (exc) {
            console.log('err', exc);
            TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.SummaryStats_Data, false, NavType.NavLists);
            return;
        }

        let summryData = ONeilViewStore._state.summaryListData.stats;
        let tmpSrc = indexBy(_itemsSource, 'columnName');
        if (summryData) {
            for (let colItem of summryData.columnStats) {
                if (colItem == null) return;

                if (columnList.length == 0) return;

                let column = find(columnList, (c) => c.dataItemId == colItem.dataItemId);
                if (!column || column.isFrozen) continue;
                try {
                    tmpSrc[headerDictionary.StatsRowCount].dataRow.push(this.getCellForItem(column, count, true));
                    let nonBlankCount = count;
                    if (colItem.nonBlankCount != null || colItem.nonBlankCount != undefined || colItem.nonBlankCount != '') {
                        if (parseInt(colItem.nonBlankCount) >= 0) {
                            nonBlankCount = parseInt(count) - parseInt(colItem.nonBlankCount);
                        }
                        tmpSrc[headerDictionary.StatsRowNonblnk].dataRow.push(this.getCellForItem(column, nonBlankCount, true));
                    }
                    else {
                        tmpSrc[headerDictionary.StatsRowNonblnk].dataRow.push(this.getCellForItem(column, ""));
                    }
                    //let doubleValue;

                    let value = this.getCellForItem(column, colItem.average);
                    //doubleValue = Double.TryParse(colItem.Average, out doubleValue) ? doubleValue : 0;
                    tmpSrc[headerDictionary.StatsRowAvg].dataRow.push(value);

                    value = this.getCellForItem(column, colItem.maximum);
                    tmpSrc[headerDictionary.StatsRowMax].dataRow.push(value);
                    //doubleValue = Double.TryParse(colItem.maximum, out doubleValue) ? doubleValue : 0;
                    //_itemsSource[_maxidx].Add(colItem.HasMaximum ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });

                    value = this.getCellForItem(column, colItem.minimum);
                    tmpSrc[headerDictionary.StatsRowMin].dataRow.push(value);
                    //doubleValue = Double.TryParse(colItem.minimum, out doubleValue) ? doubleValue : 0;
                    //_itemsSource[_minidx].Add(colItem.HasMinimum ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });

                    value = this.getCellForItem(column, colItem.stdDev);
                    tmpSrc[headerDictionary.StatsRowStddev].dataRow.push(value);

                    // value = GetStringFromCell(colItem.StdDev, column);
                    // doubleValue = Double.TryParse(colItem.StdDev, out doubleValue) ? doubleValue : 0;
                    // _itemsSource[_stDevidx].Add(colItem.HasStdDev ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });
                    value = this.getCellForItem(column, colItem.mode);
                    tmpSrc[headerDictionary.StatsRowMode].dataRow.push(value);


                    // value = GetStringFromCell(colItem.Mode, column);
                    // doubleValue = Double.TryParse(colItem.Mode, out doubleValue) ? doubleValue : 0;
                    // _itemsSource[_modidx].Add(colItem.HasMode ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });
                    value = this.getCellForItem(column, colItem.perct10);
                    tmpSrc[headerDictionary.StatsRow10Perc].dataRow.push(value);

                    // value = GetStringFromCell(colItem.Perct10, column);
                    // doubleValue = Double.TryParse(colItem.Perct10, out doubleValue) ? doubleValue : 0;
                    // _itemsSource[_pert10].Add(colItem.HasPerct10 ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });

                    value = this.getCellForItem(column, colItem.perct25);
                    tmpSrc[headerDictionary.StatsRow25Perc].dataRow.push(value);

                    // value = GetStringFromCell(colItem.Perct25, column);
                    // doubleValue = Double.TryParse(colItem.Perct25, out doubleValue) ? doubleValue : 0;
                    // _itemsSource[_perc25].Add(colItem.HasPerct25 ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });

                    value = this.getCellForItem(column, colItem.perct50);
                    tmpSrc[headerDictionary.StatsRow50Perc].dataRow.push(value);

                    // value = GetStringFromCell(colItem.Perct50, column);
                    // doubleValue = Double.TryParse(colItem.Perct50, out doubleValue) ? doubleValue : 0;
                    // _itemsSource[_perc50].Add(colItem.HasPerct50 ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });

                    value = this.getCellForItem(column, colItem.perct75);
                    tmpSrc[headerDictionary.StatsRow75Perc].dataRow.push(value);

                    // value = GetStringFromCell(colItem.Perct75, column);
                    // doubleValue = Double.TryParse(colItem.Perct75, out doubleValue) ? doubleValue : 0;
                    // _itemsSource[_perc75].Add(colItem.HasPerct75 ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });

                    value = this.getCellForItem(column, colItem.perct90);
                    tmpSrc[headerDictionary.StatsRow90Perc].dataRow.push(value);

                    // value = GetStringFromCell(colItem.Perct90, column);
                    // doubleValue = Double.TryParse(colItem.Perct90, out doubleValue) ? doubleValue : 0;
                    // _itemsSource[_perc90].Add(colItem.HasPerct90 ? CellGenerator.GetCellForItem(column, doubleValue, value) : new CellItem { Column = column, Text = string.Empty });


                } catch (e) {
                    console.log('error', e);
                    TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.SummaryStats_Data, false, NavType.NavLists);
                }

                //}
                // catch (exc)
                // {
                //     console.log('err', exc);
                // }
                //     })

                //     }
                // }
                // Dispatcher.BeginInvoke((Action)(() =>
                //     {
                //         DataGridColumns = new DataGridColumns(columnList.Where(c => !c.IsFrozen));
                //         SummaryListItems = _itemsSource;
                //         if (StatTabSelectedIndex == 0)
                //             IsAggrSummLoadingMessageDisplayed = false;
                //         ResetSummaryListItemOddRowFlg();
                //     }));
            }
        }

        this.state.summaryListData = clone(tmpSrc);
        return true;


    }

    getSummaryData () {
        return this.state.summaryListData;
    }

    getSummaryHistData () {
        return this.state.summaryDistBars;
    }

    getActiveColumnName () {
        return this.state.activeColName;
    }

    isBenchmarkTabVisible () {
        this.state.isBenchmarkTabVisible = ONeilViewStore.isBenchmarkTabVisible();
        return this.state.isBenchmarkTabVisible;
    }


    getBarDistData(barHeight, barWidth, rangeMin, rangeMax, count, barColor, index, rangeMinVal, rangeMaxVal, maxCount) {
        return {
            barHeight: barHeight,
            barWidth: barWidth,
            barColor: barColor,
            rangeMinimum: rangeMin,
            rangeMaximum: rangeMax,
            rangeMinValue: rangeMinVal,
            rangeMaxValue: rangeMaxVal,
            count: count,
            index: index,
            xPos: (barWidth * index),
            yPos: 0,
            maxCount: maxCount
        }
    }

    getDefaultAggregateStatsRows () {
        let dictionary = {
            StatsRowCount: "Count",
            StatsRowNonblnk: "Count (Not Blank)",
            StatsRowAvg: "Average",
            StatsRowStddev: "Std. Dev",
            StatsRowMode: "Mode",
            StatsRowMax: "Maximum",
            StatsRow90Perc: "90th %",
            StatsRow75Perc: "75th %",
            StatsRow50Perc: "50th % (Median)",
            StatsRow25Perc: "25th %",
            StatsRow10Perc: "10th %",
            StatsRowMin: "Minimum"
        };
        return dictionary;
    }

    replaceNonBlnkCntRow () {
        if (this.AggregateStatsHeaderDictionary.ContainsKey("Non Blank Ct.")) {
            var value = AggregateStatsHeaderDictionary["Non Blank Ct."];
            AggregateStatsHeaderDictionary.Remove("Non Blank Ct.");
            AggregateStatsHeaderDictionary.Add("Count (Not Blank)", value);

        }
    }

    getFormattedValue(cellValueObject, formatString, useSuffixFormatting = false, isCustomColumn = false) {
        formatString = StringUtil.isEmpty(formatString) && cellValueObject > 1000 ? "#,##0" : formatString;
        if (!StringUtil.isEmpty(formatString)) {
            let newStringFormat = formatString;//CultureHelper.GetNumberFormatByCulture(formatString); 
            if (newStringFormat.includes("%") && !isCustomColumn) {
                //cellValueObject = cellValueObject / 100;
                cellValueObject = cellValueObject;
            }


            if (useSuffixFormatting && cellValueObject > 10000 && !isCustomColumn) {

                if (cellValueObject > 1000000000000)
                    return this.getFormateValue(newStringFormat, cellValueObject / 1000000000000).trim() + " T";

                if (cellValueObject > 1000000000)
                    return this.getFormateValue(newStringFormat, cellValueObject / 1000000000).trim() + " B";

                if (cellValueObject > 1000000)
                    return this.getFormateValue(newStringFormat, cellValueObject / 1000000).trim() + " M";

                if (cellValueObject > 10000)
                    return this.getFormateValue(newStringFormat, cellValueObject / 1000).trim() + " K";

            }
            else if (isCustomColumn) {
                let formatStringCM = StringUtil.formatter(formatString);
                if (cellValueObject > 1000000000000)
                    return this.getFormateValue(formatStringCM, cellValueObject / 1000000000000).trim() + " T";

                if (cellValueObject > 1000000000)
                    return this.getFormateValue(formatStringCM, cellValueObject / 1000000000).trim() + " B";

                if (cellValueObject > 1000000)
                    return this.getFormateValue(formatStringCM, cellValueObject / 1000000).trim() + " M";

                if (cellValueObject > 10000)
                    return this.getFormateValue(formatStringCM, cellValueObject / 1000).trim() + " K";
            }

            let strResut;
            if (isCustomColumn) {
                let formatStringCM = StringUtil.formatter(formatString);
                strResut = this.getFormateValue(formatStringCM, cellValueObject).trim();
            }
            else {
                strResut = this.getFormateValue(newStringFormat, cellValueObject).trim();
            }

            return StringUtil.isEmpty(strResut) ? "0" : strResut;
        }

        return cellValueObject.toString().trim();

    }

    getFormateValue(formatter, doubleValue) {
        let cellValue = '';
        switch (formatter) {
            case '#,##0%':
                cellValue = Number(doubleValue).toFixed(0) + '%';
                break;

            case '#,##0':
                cellValue = new Intl.NumberFormat().format(Number(doubleValue).toFixed(0));
                break;
            case '(#,##0)':
                cellValue = numberFormatter("(#,##)", Math.abs(doubleValue));
                break;

            case '#,##0.00':
            case '#,##0.0%':
                cellValue = numberFormatter(formatter, doubleValue);
                break;

            default:
                cellValue = numberFormatter(formatter, doubleValue);
        }
        return cellValue;
    }

    async loadHistData (col)  {
        const cId = col.dataItemId;
        /*eslint-disable */
        let t = await ONeilViewStore.loadHistStat(cId);
        /*eslint-enable */
        this.state.SummaryStatCurrentAction = GridConstants.ActionTypes.LOAD_HIST_DATA;
        this.emit(CHANGE_EVENT);
    }

    setDistData () {
        const distributionData = ONeilViewStore._state.distribData ? ONeilViewStore._state.distribData.distributions : null;
        const columnList = ONeilViewStore._columnInfo;

        if (!columnList) return;
        let column = columnList.find((c) => c.dataItemId == ONeilViewStore._state.distributiontChartSelectedColumnId);
        if (!column || !distributionData) {
            this.state.summaryDistBars = null;
            this.state.xAxisLabels = [];
            this.state.activeColumn = null;
            this.state.activeColName = '';
            return;
        }

        const distributionDataClone = clone(distributionData);

        let maxYAvailable = this.MaxYAvailableForGraph - 10;
        let xAxisLabels = [];
        let distributedColNum = distributionData.length;
        if (distributedColNum > this.DistChartMinColnum) {
            for (let i = 0; i < 5; i++) {
                if (i == 0) {
                    xAxisLabels.push(this.getFormattedValue(distributionData[0].minimum.toFixed(2), column.formatter, true, column.isCustomColumn))
                }
                else if (i < 4) {
                    xAxisLabels.push(this.getFormattedValue(distributionData[parseInt(i * distributedColNum / 4 - 1)].minimum.toFixed(2), column.formatter, true, column.isCustomColumn))
                }
                else {
                    xAxisLabels.push(this.getFormattedValue(distributionData[parseInt(distributedColNum - 1)].maximum.toFixed(2), column.formatter, true, column.isCustomColumn))
                }

            }
            //     xAxisLabels.push(ListManagerHelper.GetFormattedValue(distributionData.DistributionsList[0].minimum, column.FormatString, true,column.IsCustomColumn));
            //     xAxisLabels.push(ListManagerHelper.GetFormattedValue(distributionData.DistributionsList[(distributedColNum / 4 - 1)].minimum, column.FormatString, true, column.IsCustomColumn));
            //     xAxisLabels.push(ListManagerHelper.GetFormattedValue(distributionData.DistributionsList[(2 * distributedColNum / 4 - 1)].minimum, column.FormatString, true, column.IsCustomColumn));
            //     xAxisLabels.push(ListManagerHelper.GetFormattedValue(distributionData.DistributionsList[(3 * distributedColNum / 4 - 1)].minimum, column.FormatString, true, column.IsCustomColumn));
            //     xAxisLabels.push(ListManagerHelper.GetFormattedValue(distributionData.DistributionsList[(distributedColNum - 1)].maximum, column.FormatString, true, column.IsCustomColumn));
        }
        else {
            xAxisLabels = ["", "", "", "", ""];
        }

        if (distributedColNum > 0) {
            const maxItem = max(distributionDataClone, (x) => x.count);// distributionData.DistributionsList.Max(x => x.Count);
            const brushColor = ThemeHelper.getThemedBrush("#4693C2");
            const barWidth = this.DistChartWidth / distributedColNum;
            let bars = map(distributionDataClone, (item, i) =>
                this.getBarDistData(((item.count * maxYAvailable) / maxItem.count), barWidth,
                    Math.abs(item.minimum - 0.0) > Number.EPSILON
                        ? item.minimum.toString()
                        : "0",
                    Math.abs(item.maximum - 0.0) > Number.EPSILON
                        ? item.maximum.toString()
                        : "0",
                    item.count,
                    brushColor,
                    i,
                    item.minimum.toFixed(2),
                    item.maximum.toFixed(2),
                    maxItem.count)
            );
            this.state.summaryDistBars = bars;
            this.state.xAxisLabels = xAxisLabels;
            this.state.activeColumn = column;
            this.state.activeColName = column.displayName;
        }
        else {
            this.state.summaryDistBars = null;
            this.state.xAxisLabels = [];
            this.state.activeColumn = null;
            this.state.activeColName = '';
        }

    }

    clearCurrentAction ()  { this.state.currentAction = null }

    clearSummaryStatCurrentAction () { this.state.SummaryStatCurrentAction = null }

    getDragedGhostName (draggedIndex)  {
        var itemVal = this.headers[parseInt(draggedIndex)];
        switch (itemVal) {
            case 'Count':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_Count', 'Count');
                break;
            case 'Count (Not Blank)':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_CouNotBlk', 'Count (Not Blank)');
                break;
            case 'Average':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_Average', 'Average');
                break;
            case 'Std. Dev':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_StdDev', 'Std. Dev');
                break;
            case 'Mode':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_Mode', 'Mode');
                break;
            case 'Maximum':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_Max', 'Maximum');
                break;
            case '90th %':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_90', '90th %');
                break;
            case '75th %':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_75', '75th %');
                break;
            case '50th % (Median)':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_50', '50th % (Median)');
                break;
            case '25th %':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_25', '25th %');
                break;
            case '10th %':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_10', '10th %');
                break;
            case 'Minimum':
                itemVal = LocalizationStore.getTranslatedData('LM_SLV_Min', 'Minimum');
                break;
            default:
                break;
        }
        return itemVal;
    }

    reOrder (draggedIndex, dropIndex)  {
        let c = clone(this.getSummaryData());
        let _currentListItems = map(c, (item) => item);
        const droppedItems = [_currentListItems[parseInt(draggedIndex[0])]];
        const dropElem = [_currentListItems[parseInt(dropIndex[0]) - 1]];
        _currentListItems.splice(draggedIndex, 1);
        let insertIndex = _currentListItems.indexOf(dropElem[0]) + 1;

        for (let index = draggedIndex.length - 1; index >= 0; index--) {
            this.insertItemIntoCurrentList(_currentListItems, index, droppedItems, insertIndex);
        }

        this.state.summaryListData = indexBy(_currentListItems, 'columnName');
        this.headers = keys(this.state.summaryListData);
        this.state.isDragging = false;
        this.state.SummaryStatCurrentAction = GridConstants.ActionTypes.REORDER_SUMMMARY_STAT_ROW;
        this.emit(CHANGE_EVENT);
    }

    insertItemIntoCurrentList(_currentListItems, index, droppedItems, insertIndex) {
        _currentListItems.insert(insertIndex, droppedItems[index]);
    }

    setDataFilter(operator, value, key, initialValue = false, isDataItemValue = false, metricItemName = null) {
        if (initialValue) {
            this.dataItems[key] = {
                operator: operator,
                inputValue: value,
                isDataItemValue: isDataItemValue,
                metricItemName: metricItemName,
                previouseValue: value,
                previouseOperator: operator
            };
        }
        else {
            this.dataItems[key].operator = operator;
            this.dataItems[key].inputValue = value;
            this.dataItems[key].isDataItemValue = isDataItemValue;
            this.dataItems[key].metricItemName = metricItemName;
        }
    }

    getValue(aCol) {
        if (aCol.valueOperator == ValueOperatorType.BETWEEN_ValueOperator) {
            return aCol.values[0].toString() + "-" + aCol.values[1].toString();
        }
        return aCol.values.toString();
    }

    getColumnFilterData(minRangeVal, maxRangeVal) {
        const colInfo = this.state.activeColumn;
        let activeColumnDetails = ColumnCustomFilterControl.getColumnValue(colInfo.dataItemId);
        let numOfFilter = 0;
        each(activeColumnDetails, (aCol, i) => {
            if (aCol.dataItemValue) {
                this.setDataFilter(aCol.valueOperator.toString(), this.getValue(aCol), "k" + parseInt(i + 1), true, aCol.dataItemValue);
            }
            else {
                this.setDataFilter(aCol.valueOperator.toString(), this.getValue(aCol), "k" + parseInt(i + 1), true);
            }
            numOfFilter++;
        });
        let startPoint = numOfFilter >= 3 ? 3 : numOfFilter;
        let maxPoint = startPoint + 2;
        this.setDataFilter(ValueOperatorType.GE_ValueOperator.toString(), minRangeVal.toString(), "k" + parseInt(startPoint + 1), true);
        this.setDataFilter(ValueOperatorType.LT_ValueOperator.toString(), maxRangeVal.toString(), "k" + parseInt(startPoint + 2), true);
        const numberofData = maxPoint;
        const ObjerrorMessage = { errorMessage: "" };
        ColumnCustomFilterControl.setFieldTagExtListToColumn(null, null, colInfo, false, ObjerrorMessage, null, false, numberofData, this.dataItems);
        let addlRequestData = ColumnCustomFilterControl.applyFilters();
        if (ObjerrorMessage.errorMessage == "") {
            return addlRequestData;
        }
    }

    getSummaryHeight() {
        return this.state.summaryStatHeight;
    }

    clearSummaryStatData() {
        if (!this.state) {
            this.setDefaultState();
        }
        this.state.summaryListData = [];
        this.state.summaryDistBars = [];
    }

    emitChange() {
        this.state.SummaryStatCurrentAction = GridConstants.ActionTypes.LOAD_HIST_DATA;
        this.emit(CHANGE_EVENT);
    }

    dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.data;
        switch (action.actionType) {
            case GridConstants.ActionTypes.SET_SUMMARYSTATE_ROW_COL_HIGHLIGHTED:
                this.state.currentAction = GridConstants.ActionTypes.SET_SUMMARYSTATE_ROW_COL_HIGHLIGHTED;
                this.state.highlightedRowCol = {
                    rowIndex: data.rowIndex,
                    columnId: data.columnId,
                    highlight: data.highlight
                }
                this.emit(CHANGE_EVENT);
            default:
                break;
        }
    }
}

const summaryStatStore = new SummaryStatStore();
export default summaryStatStore;
