import AnnotationUtil from "../../../Utils/AnnotationUtil.js";
import BaseServiceApi from 'BaseServiceApi';
import { calculateIdeaPriceChart } from "../../../Actions/PricePanelActions.js";
import ConsoleStore from "ConsoleStore";
import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import DatagraphDataType from "../../../Constants/DatagraphDataType.js";
import DatagraphHelper from "../../../Utils/DatagraphHelper";
import DateHelper from "DateHelper";
import { eventChannel } from 'redux-saga';
import { externalDataUploadSymbolReducer } from '../../../Reducers/ExternalDataUploadReducer/selectors.js';
import ExtremeDataValue from "ExtremeDataValue";
import { getCurrencyReducerState } from "../../../Reducers/CurrencyConversionReducer/selectors.js";
import GraphType from "GraphType";
import HighLow from "../../../Utils/Calcs/HighLow.js";
import PeriodicityHelper from "../../../Utils/PeriodicityHelper.js";
import { prepareVolumeData } from "../../../Actions/VolumeChartAction.js";
import PriceMenuHelper from '../PriceMenu/PriceMenuHelper.js';
import { PrintMode } from "../../../print/printmode.js";
import { processIndicator } from "../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions.js";
import { processKPIIndicator } from "../../../Actions/KpiGraphActions.js";
import { reDrawAnnotationsOnChartResize } from '../../../Actions/AnnotationActions.js';
import { registerWebSync } from "./DataGraphStreamingSaga.js";
import { safe } from '../../ErrorModel.js';
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../Constants/SmartViewType.js";
import StringUtil from "StringUtil";
import SymbolType from "../../../Constants/SymbolType.js";
import tabDatagraphStore from "../../../Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore.js";
import { TimelineConstant } from "../../../Constants/TabDataGraphConstants.js";
import TimeTrackingWindow from "../../../RayCustomControls/TimeTrackingWindow.js";
import UserInfoUtil from 'UserInfoUtil';
import WorkSpaceRealTimePrices from "../../../Utils/RealTimeHelper/WorkSpaceRealTimePrices.js";
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { OhOlTypeSelect, scaleLabelSelect, timeLineSelect, priceChartReducerselect, getFundamentalLineStates, getDatagraphStates, getTimeLine } from '../../../Reducers/NavDataGraph/TabDataGraph/selectors'
import { PriceChartConst, PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { PriceScaleLabelConst, getPriceChartScale } from "../../../Utils/Scales/PriceScaleConst.js";
import { redrawExternalDataOnChartResize, updateExtDataReqInitiated } from '../../../Actions/ExternalDataUploaderActions.js';
import { getFocusListData } from "../../../Actions/RIPanelOneilActions.js";
var IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
const { ActionTypes } = PriceChartConstants;

function IsLineGraph(pricePanelData, hsfResults, HiLowPoints, majorPeriodicity, symbolType) {

    const info = pricePanelData.SymbolInfo;
    const isDailyLineInst = info.SymTypeEnum === SymbolType.FUND || info.SymTypeEnum === SymbolType.CASHSPOTS || info.SymTypeEnum === SymbolType.CURRENCY;

    if (isDailyLineInst && majorPeriodicity === GraphType.Daily && HiLowPoints.allPoints && HiLowPoints.allPoints.length > 0) {
        return true;
    }

    if ((hsfResults === null) || (hsfResults.length < 1)) { return false; }

    if (symbolType === SmartViewType.STOCK) {
        return false;
    }
    else {
        let xLine = 0;

        if (hsfResults.length < xLine || hsfResults[xLine] === null) { return false; }
        let currentPriceLine = 1;
        while (!hsfResults[xLine].Close || hsfResults[xLine].Close <= 0.0) {
            xLine++;
            if (hsfResults[xLine] === null) { return false; }
        }

         if (hsfResults.length > 10) {
             for (;
                 currentPriceLine < 11 && hsfResults[xLine]._volume < 2 &&
                 hsfResults[xLine].High === hsfResults[xLine].Low;
                 xLine++ , currentPriceLine++) {
             }
         }
        return currentPriceLine > 9;
    }
}
function getHighLowPoints(pricePanelData, padding, scale, isIndex, startXPoint, nodeWidth) {
    const graphData = pricePanelData.HsfData.HSFResults;
    const hasVolume = pricePanelData.HsfData.HasVolume;
    const lastDataNode = graphData.length - 1;
    let xAxis = startXPoint;
    const chartData = { highPoints: [], lowPoints: [] };
    const firstNodeIndex = 0;
    if ((firstNodeIndex + padding) <= lastDataNode) {
        chartData.allPoints = new Array(lastDataNode - (firstNodeIndex + padding));
    } else {
        chartData.allPoints = new Array(lastDataNode + 1);
    }
    let index = 0;
    for (let j = padding; j <= lastDataNode; j++) {
        if (!graphData[j].IsVisible) {
            xAxis -= nodeWidth;
            continue;
        }
        const yPrice = scale.ComputeY(graphData[j].Close);
        const yHigh = scale.ComputeY(graphData[j].High);
        const yLow = scale.ComputeY(graphData[j].Low);
        graphData[j].UpTick = j < lastDataNode ? graphData[j].Close >= (graphData[j + 1] ? graphData[j + 1].Close : 0) : graphData[j].UpTick;
        const info = {
            Date: graphData[j].Date,
            yPrice: yPrice,
            yHigh: yHigh,
            yLow: yLow,
            xAxis: xAxis,
            UpTick: graphData[j].UpTick,
            IsVisible: hasVolume && ((graphData[j] && graphData[j]._volume > 0) || (isIndex && graphData[j] && graphData[j]._volume > -1)) || !hasVolume,
            graphData: graphData[j],
            corpIndex: [],
            IdeasTr: []
        };
        xAxis -= nodeWidth;
        if (graphData[j].UpTick) {
            chartData.highPoints.push(info);
        }
        else {
            chartData.lowPoints.push(info);
        }
        chartData.allPoints[index] = info;
        index++;
    }
    return chartData;
}
function getPriceScale(pricePanelData, graphData, periodicity, majorPeriodicity, chartHeight, nodeWidth, viewsSettings, isIntraday, SymTypeEnum, multiplier) {
    let maxPrice = Number.NEGATIVE_INFINITY;
    let minPrice = Number.POSITIVE_INFINITY;

    let lastnodeIndex = graphData.length;
    lastnodeIndex = ConsoleStore.getState().nodeCount ? ConsoleStore.getState().nodeCount : lastnodeIndex

    const firstNodeIndex = 0;
    const lastNode = Math.min(graphData.length, lastnodeIndex);
    const firstNode = firstNodeIndex;
    for (let i = firstNode; i < lastNode; i++) {
        if (!graphData[i].IsVisible) { continue; }
        if (maxPrice < graphData[i].High) {
            maxPrice = graphData[i].High;
        }
        if (minPrice > graphData[i].Low && graphData[i].Low > 0) {
            minPrice = graphData[i].Low;
        }
    }

    let scaleLabel = viewsSettings.ScaleLabelSettings[majorPeriodicity];
    const wonLog = UserInfoUtil.IsUserEntitled(EntitlementType.SCALE_WON_LOG_Entitlement);
    if (scaleLabel === PriceScaleLabelConst.LOG_WON && !wonLog) {
        viewsSettings.ScaleLabelSettings[majorPeriodicity] = PriceScaleLabelConst.LOG_FIXED;
        scaleLabel = PriceScaleLabelConst.LOG_FIXED;
    }
    const scale = getPriceChartScale(scaleLabel, isIntraday, SymTypeEnum)

    // switch (scaleLabel) {
    //     case "LIN":
    //         if (majorPeriodicity === GraphType.Intraday || (pricePanelData.SymbolInfo && pricePanelData.SymbolInfo.SymTypeEnum === SymbolType.WONSECTOR11)) {
    //             scale = new ArithmaticScale();
    //         }
    //         else {
    //             scale = new WArithmaticScale();
    //         }
    //         break;
    //     case "LOG (Fixed)":
    //         scale = new FixedLogScale();
    //         break;
    //     case "LOG (WON)":
    //         scale = new FixedLogScale(true);
    //         break;
    //     default:
    //         scale = new LogScale();
    //         break;
    // }
    const t4QMultipliers = getT4QMultipliers(multiplier);
    scale.InitScale(minPrice, maxPrice, chartHeight, periodicity, 1, 28 * nodeWidth, SymbolType.USSTOCK, multiplier[PriceChartConst.EPS], multiplier[PriceChartConst.RPS], t4QMultipliers, NaN);
    return {scale, scaleLabel};
}

function getT4QMultipliers(_state) {
    const multipliers = [];
    multipliers.push(_state[PriceChartConst.BV] ? _state[PriceChartConst.BV] : NaN);
    multipliers.push(_state[PriceChartConst.CFS] ? _state[PriceChartConst.CFS] : NaN);
    multipliers.push(_state[PriceChartConst.DIV] ? _state[PriceChartConst.DIV] : NaN);
    multipliers.push(_state[PriceChartConst.FCF] ? _state[PriceChartConst.FCF] : NaN);
    return multipliers;
}

let resumeExternalDataChannelBuffer;
function* resumeExternalDataChannel(isService) {
    if (resumeExternalDataChannelBuffer) {
        resumeExternalDataChannelBuffer.close();
    }
    resumeExternalDataChannelBuffer = eventChannel((emmiter) => {
        const timer = setInterval(emmiter, 100, isService);

        return () => {
            clearInterval(timer);
        }
    });
    while (true) {
        const value = yield take(resumeExternalDataChannelBuffer);
        yield safe(call(resumeExternalDataPopulation, value), "PriceChartSaga.js", "resumeExternalDataPopulation");
    }
}

function* resumeExternalDataPopulation(isService = false) {
    const state = yield select(externalDataUploadSymbolReducer)
    if (state.settingsObjectIsUpdated) {
        if(resumeExternalDataChannelBuffer){
            resumeExternalDataChannelBuffer.close();
        }
       yield call(redrawExternalData, {isRepositioning: false, uncheckedListId: '', isService});
       yield put(updateExtDataReqInitiated(true));
    }
}

function* redrawExternalData({ isRepositioning = false, uncheckedListId = '', isService = false }) {
    const { HiLowPoints, scale, chartHeight } = yield select(priceChartReducerselect);
    const { viewsSettings, nodeCount, majorPeriodicity } = yield select(getDatagraphStates)
    const { multiplier, minPrice, maxPrice } = yield select(getFundamentalLineStates);
    const timeLine = yield select(timeLineSelect);
    if (majorPeriodicity !== GraphType.Intraday && timeLine.dates && HiLowPoints.allPoints) {
        for (const listId in viewsSettings.ExternalDataSettings) {
            const listItem = viewsSettings.ExternalDataSettings[listId][majorPeriodicity];

            if (listItem.isLastUploaded) {
                listItem.isLastUploaded = false;
            }

            if (!StringUtil.isEmpty(listItem) && !listItem.isDisabledForCurrentSymbol) {
                if (isRepositioning) {
                    if (listItem.IsVisibleInPriceMenu && listItem.isDateBasedEvents && uncheckedListId !== '' && parseFloat(listId) !== parseFloat(uncheckedListId)) {
                        yield put(redrawExternalDataOnChartResize(listId, nodeCount, timeLine.dates, scale, minPrice, maxPrice, chartHeight, true, NaN, NaN, NaN, '', HiLowPoints.allPoints)); // redrawing price chart
                    }
                }
                else {
                    if (listItem.IsVisibleInPriceMenu) {
                        if (listItem.isDateBasedEvents) {
                            let esfields = '';
                            if (!StringUtil.isEmpty(listItem.AdvancedData)) {
                                Object.keys(listItem.AdvancedData).forEach((key) => {
                                    if (listItem.AdvancedData[key].IsVisible) {
                                        esfields += `${key};`;
                                    }
                                })
                            }
                            yield put(redrawExternalDataOnChartResize(listId, nodeCount, timeLine.dates, scale, minPrice, maxPrice, chartHeight, true, NaN, NaN, NaN, esfields, HiLowPoints.allPoints)); // redrawing price chart
                        }
                        else {
                            const t4QMultipliers = [multiplier[PriceChartConst.BV], multiplier[PriceChartConst.CFS], multiplier[PriceChartConst.DIV], multiplier[PriceChartConst.FCF]];
                            yield put(redrawExternalDataOnChartResize(listId, nodeCount, true, multiplier[PriceChartConst.EPS], multiplier[PriceChartConst.RPS], t4QMultipliers, '', null)); // redrawing price chart
                        }
                    }
                    if (listItem.IsVisibleInIndicatorMenu) {
                        const initExtIndicator = isService;
                        yield put(redrawExternalDataOnChartResize(listId, nodeCount, timeLine.dates, '', 0, 0, 0, false, NaN, NaN, NaN, '', null, initExtIndicator)); // redrawing indicator chart
                    }
                }
            }
        }
    }
}
export function* processPriceChart(isStreamingProc = false) {
    try{

        const { pricePanelData, SymbolInfo, headerData, isIntraday, periodicity, majorPeriodicity, nodeWidth, SymbolType, padding, startXPoint, viewsSettings,
            isHistoric, isReceivedQuote, InitialBufferSize, SymTypeEnum} = yield select(getDatagraphStates);
        const chartHeight = PriceMenuHelper.getHeight();
        const chartWidth = PriceMenuHelper.getWidth();
        let endDate = ConsoleStore.getUserEndDate();
        const TimeLine = yield select(getTimeLine)
        const { multiplier } = yield select(getFundamentalLineStates);
    
        const {scale, scaleLabel} = getPriceScale(pricePanelData, pricePanelData.HsfData.HSFResults, periodicity, majorPeriodicity, chartHeight, nodeWidth, viewsSettings, isIntraday, SymTypeEnum, multiplier);
    
        const hoLowData = getHighLowPoints(pricePanelData, padding, scale, SymbolType === SmartViewType.INDEX, startXPoint, nodeWidth);
        const highLow = new HighLow();
        highLow.CalculateHighLowNodes(hoLowData, isIntraday, true, true);
    
        const lastTradeDate = DateHelper.parseJsonDate(SymbolInfo.LastTradeDate, SymbolInfo.MomentZoneID);
        const IPODate = DateHelper.parseJsonDate(SymbolInfo.IPODate, SymbolInfo.MomentZoneID);
        endDate = DateHelper.parseJsonDate(endDate, SymbolInfo.MomentZoneID);
        endDate = endDate && endDate <= lastTradeDate ? endDate : lastTradeDate;
        let endDateLabel = '';

        const settings = tabDatagraphStore.getTabDatagraphSettings();
        let gEndDate = endDate;
        if ((!gEndDate) && isHistoric && settings.userEndDate && new Date(settings.userEndDate) <= lastTradeDate && new Date(settings.userEndDate) >= IPODate) {
            endDate = new Date(settings.userEndDate);
        }
        else if ((!gEndDate) || lastTradeDate < gEndDate) {
            endDate = lastTradeDate;
        }
        if (endDate.getTime() === lastTradeDate.getTime()) {
        gEndDate = undefined;
        }
        // Fix for PANWEB-3761 (Undoing this change since it breaks the user date control)
        

        if (isIntraday) {
            const data = pricePanelData.HsfData.HSFResults;
            let firstNode = padding;
            const allNodes = pricePanelData.HsfData.HSFResults.length;
            for (; firstNode < allNodes; firstNode++) {
                if (data[firstNode].IsVisible) {
                    endDate = data[firstNode].Date;
                    break;
                }
            }
        }
        if (endDate) {
            endDateLabel = DateHelper.mmddyyyy(endDate);
        }
        ConsoleStore.UpdateConsoleDate(endDate, gEndDate);
        const isLineChart = !isIntraday && IsLineGraph(pricePanelData, pricePanelData.HsfData.HSFResults, hoLowData, majorPeriodicity, SymbolType);
        const nodeCount = ConsoleStore.getState().nodeCount ? ConsoleStore.getState().nodeCount : pricePanelData.HsfData.HSFResults.length;
        const startDate =  DateHelper.calculateBeginDate(endDate, periodicity, (nodeCount + InitialBufferSize)) //InitialBuffersize needs to be addded
        const Symbol = headerData.Symbol;
        ConsoleStore.UpdateBeginDate(startDate);
        // OH/OL calculation
        let yrHigh = SymbolInfo && SymbolInfo.oHigh && SymbolInfo.oHigh !== null ? SymbolInfo.oHigh : SymbolInfo.YrHi !== null ? SymbolInfo.YrHi : null;
        let yrLow = SymbolInfo && SymbolInfo.oLow && SymbolInfo.oLow !== null ? SymbolInfo.oLow : SymbolInfo.YrLo !== null ? SymbolInfo.YrLo : null;
        const curr =  hoLowData.allPoints[0] ? hoLowData.allPoints[0].graphData.Close : 0;
        if (hoLowData.allPoints[0]) {
            if (yrHigh !== null && hoLowData.allPoints[0].graphData.High > yrHigh) { yrHigh = hoLowData.allPoints[0].graphData.High; }
            if (yrLow !== null && hoLowData.allPoints[0].graphData.Low < yrLow) { yrLow = hoLowData.allPoints[0].graphData.Low; }
        }
        const oHigh = yrHigh !== null && yrHigh > 0 ? ((curr / yrHigh) - 1) * 100 : null;
        const oLow = yrLow !== null && yrLow > 0 ? ((curr / yrLow) - 1) * 100 : null;
        // end of OH/OL calculation

        const openTime = DateHelper.parseJsonDate(SymbolInfo.ExchangeOpenTime, SymbolInfo.MomentZoneID);
        const closeTime = DateHelper.parseJsonDate(SymbolInfo.ExchangeCloseTime, SymbolInfo.MomentZoneID);
        const LastTradeDate = DateHelper.parseJsonDate(SymbolInfo.LastTradeDate, SymbolInfo.MomentZoneID);
        const TradeOpen = new Date(LastTradeDate.getFullYear(), LastTradeDate.getMonth(), LastTradeDate.getDate(), openTime.getHours(), openTime.getMinutes());
        const TradeClose = new Date(LastTradeDate.getFullYear(), LastTradeDate.getMonth(), LastTradeDate.getDate(), closeTime.getHours(), closeTime.getMinutes());
    
        yield put({
            type: ActionTypes.PRICE_DATA_READY,
            hoLowData,
            chartHeight,
            chartWidth,
            pricePanelData,
            endDate,
            endDateLabel,
            headerData,
            isLineChart,
            Symbol,
            periodicity,
            majorPeriodicity,
            scale,
            scaleLabel,
            oHigh,
            oLow,
            startDate,
            isPriceDataReady: true,
            TradeOpen,
            TradeClose,
            isStreamingProc
        });
        AnnotationUtil.setPriceChartStates({scale, scaleLabel, endDate, HiLowPoints: hoLowData});
        AnnotationUtil.setMinMaxAxis()
        const isSTPEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.STREET_TARGET_PRICE_Entitlement);
        if( !viewsSettings.StreetTargetPriceSettings || !pricePanelData.targetPrice || (pricePanelData.targetPrice && pricePanelData.targetPrice === 0) || !isSTPEntitled || isHistoric || (pricePanelData.targetPrice && pricePanelData.targetPrice.Mean <= 0) || isIntraday || pricePanelData.HsfData.HSFResults && pricePanelData.HsfData.HSFResults[0] === null){
            yield put({
                type: ActionTypes.CLEAR_STREET_TARGET_PRICE_STATES
            })
        }
        else{
            yield put({
                type: ActionTypes.INIT_STREET_TARGET_PRICE_STATES,
                streetTargetPriceData: pricePanelData.targetPrice,majorPeriodicity, periodicity, endDate, viewsSettings, chartWidth, chartHeight, nodeWidth, scale, TimeLine,
                currPrice: hoLowData.allPoints[0].graphData.Close })
        }
        // getting Idea List Data here while streaming
        if(isStreamingProc){
            yield put(getFocusListData())
        }

        const workSpaceStates = { SymbolInfo, IntradaySourceType, TradeClose, TradeOpen }
        const isTrading = WorkSpaceRealTimePrices.IsTradingHours(workSpaceStates) && SymbolInfo.CountryCode == 1;
        if (hoLowData.allPoints.length > 0) {
            //calculate OhOl values
            const receivedQuotes = isTrading && isReceivedQuote;
            yield fork(calculatePointer, pricePanelData, SymbolInfo, headerData, isHistoric, hoLowData, majorPeriodicity, receivedQuotes, SymbolType);
        }
        if(AnnotationUtil.layerData && !isStreamingProc){
            yield put(reDrawAnnotationsOnChartResize())
        }
    }
    catch(error){
        console.log(`Error occured in PriceChart.js, processPriceChart ${error}`, error);
    }
}

export function expandHsfData(hsfData, timeLine, SymbolInfo, isIntraday, isIntradayDataSource, SymbolType, periodicity, IsNASDAQBasic) {
    let hsfPt = 0;
    let tlPt = 0;
    const mxData = timeLine.length;
    const hsLng = hsfData.length;
    let newData = [tlPt];
    let padding = 0;
    let stDate = hsfData[0] ? DateHelper.parseJsonDate(DateHelper.getPSTFromLong(hsfData[0].Date, SymbolInfo, isIntraday, isIntradayDataSource), SymbolInfo.MomentZoneID) : new Date;
    
    //if (hsfData[0].Date.getFullYear) return hsfData;
    
    while (tlPt < mxData && timeLine[tlPt]) {
        if (timeLine[tlPt].Date > stDate) {
            newData[tlPt] = { IsVisible: false, Date: timeLine[tlPt].Date };
            padding++;
            tlPt++;
        } else { // exclude partial nodes PANWEB-5345
            if (tlPt > 0 && timeLine[tlPt].Date < stDate &&
                periodicity !== GraphType.Weekly &&
                periodicity !== GraphType.Monthly &&
                periodicity !== GraphType.Quarterly &&
                periodicity !== GraphType.Annual) {
                padding--;
                tlPt--;
            }
            break;
        }
    }

    while (hsfPt < hsLng && tlPt < mxData && timeLine[tlPt] && timeLine[tlPt + 1] &&  hsfData[hsfPt]) {
        hsfData[hsfPt].Date = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(hsfData[hsfPt].Date, SymbolInfo, isIntraday, isIntradayDataSource), SymbolInfo.MomentZoneID);
        if (hsfData[hsfPt].Close <= 0) {
            if (hsfData[hsfPt].Low > 0) {
                hsfData[hsfPt].Close = hsfData[hsfPt].Low;
            } else {
                if (hsfData[hsfPt].High > 0) {
                    hsfData[hsfPt].Close = hsfData[hsfPt].High;
                    if (hsfData.length > 1) {
                        const p1 = hsfData[hsfPt].Close.toFixed(4);
                        const p2 = hsfData[hsfPt + 1].Close.toFixed(4);
                        hsfData[hsfPt].UpTick = p1 >= p2;
                    }
                }
            }
        }
        if (tlPt + 1 >= mxData) {
            break;
        }
        const partialNode = timeLine.length >= tlPt + 1 && hsfData.length >= tlPt ? timeLine[tlPt].Date > hsfData[hsfPt].Date &&
            timeLine[tlPt + 1].Date < hsfData[hsfPt].Date : false;
        if (timeLine[tlPt].Date > hsfData[hsfPt].Date &&
            !partialNode) {

            const gDate = JSON.parse(JSON.stringify(hsfData[hsfPt]));
            gDate.Date = timeLine[tlPt].Date;
            if(SymbolType === SmartViewType.INDEX){
                gDate._volume = -3;
            }else{
                gDate._volume = 0;
            }
            gDate.IsVisible = true;
            //gDate.ShowVol = false;
            newData[tlPt] = gDate;
            tlPt++;
            continue;
        }
        hsfData[hsfPt].insiderBuySell = 0;
        hsfData[hsfPt].insiderBuy = 0;
        hsfData[hsfPt].insiderSell = 0;
        hsfData[hsfPt].IsVisible = hsfData[hsfPt].Close && hsfData[hsfPt].Close >= 0 ? true: false;
        hsfData[hsfPt]._volume = hsfData[hsfPt].Volume ? StringUtil.convertFromLongValueToInt(hsfData[hsfPt].Volume) : 0;
        newData[tlPt] = hsfData[hsfPt];
        tlPt++;
        hsfPt++;
    }
    if (!IsNASDAQBasic && isIntraday) {
        const timeZoneID = SymbolInfo.MomentZoneID;//"US/Eastern";//state.SymbolInfo.MomentZoneID;
        const lastUpdateDate = WorkSpaceRealTimePrices.getCurrentTime(timeZoneID); //moment().tz(timeZoneID);
        const totalTime = lastUpdateDate.getTime();
        const delay = SymbolInfo?.TimeDelay ? SymbolInfo.TimeDelay : 20;
        const delayedTime = totalTime - (delay * 60 * 1000);
        const delayedLastUpdateDate = new Date(delayedTime);
        /* Fix for PANWEB-1786 */
        const opentime = DateHelper.parseJsonDate(SymbolInfo.ExchangeOpenTime, SymbolInfo.MomentZoneID);
        const isPushDown = stDate >= opentime && delayedLastUpdateDate < opentime;
        if (isPushDown) {
            newData = newData.filter((t) => t.Date >= opentime || t.Date < delayedLastUpdateDate);
        }
      }
    return [newData, padding];
}

function* initPriceChart(){
    try{
        TimeTrackingWindow.beginPricePanelRenderTimeTracker();
        const { pricePanelData, SymbolInfo, nodeCount, periodicity, nodeWidth, priceDataAvailable, isIntraday, SymbolType, IsNASDAQBasic } = yield select(getDatagraphStates);
        const timeLine = yield select(getTimeLine);
        let padding = 0
        TimeTrackingWindow.setPriceDataNotAvailable(!priceDataAvailable);
        if (priceDataAvailable) {
            const isIntradayDataSource = pricePanelData.HsfData.isIntradayDataSource;
            [pricePanelData.HsfData.HSFResults, padding] = yield call(expandHsfData, pricePanelData.HsfData.HSFResultsRawData, timeLine, SymbolInfo, isIntraday, isIntradayDataSource, SymbolType, periodicity, IsNASDAQBasic);
            const init = periodicity === GraphType.Daily || periodicity === GraphType.Weekly ? 1 : 0;
            let lastnodeIndex = pricePanelData.HsfData.HSFResults.length;
            lastnodeIndex = nodeCount || lastnodeIndex;
            const startXPoint = nodeWidth * (lastnodeIndex - (padding + init));
            const initialNode = nodeWidth * (lastnodeIndex - init - 1)
            AnnotationUtil.setPriceChartStates({initialNode, padding});
            yield put({
                type: DataGraphConstants.ActionTypes.STORE_PADDING_VALUE,
                padding, startXPoint, nodeCount
            });
            yield call(processPriceChart);
            yield put(prepareVolumeData());
            yield put(processIndicator());
            yield put(processKPIIndicator(true))
            yield fork(resumeExternalDataChannel, true);
            yield fork(registerWebSync, { IsNASDAQBasic });
            yield put({
                type: PriceChartConstants.ActionTypes.PRICE_FR_DATA_READY,
                resize: false
            });
            // getting idea list data here
            yield put(getFocusListData())

            TimeTrackingWindow.endPricePanelLoadEndTimeTracker();
        }
        else{
            TimeTrackingWindow.trackChartLoadingTime();
        }
    }
    catch(error){
        console.log(`Error occured in PricechartSaga.js, InitPriceChart ${error}`);
        TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.PricePanelAndFinancial)   
        TimeTrackingWindow.endPricePanelLoadEndTimeTracker();
    }
    // TimeTrackingWindow.setDatagraphRenderReady();
}
function* toggelOhOl() {
    try{
        let OHtype = yield select(OhOlTypeSelect);
        if (OHtype === 'OH') {
            OHtype = 'OL';
        } else {
            OHtype = 'OH';
        }
        yield put({
            type: ActionTypes.SET_OH_OL_TYPE,
            OHtype
        })
        const instSettings = PriceMenuHelper.getInstSettings();
        instSettings.OffHighTag = OHtype;
        SettingsStore.saveSettings();
    }
    catch(error){
        console.log(`Error occured in PriceChartSaga.js, toggleOhOl ${error}`);
    }
}
function* scaleChange() {
    try{
        const periodicity = PriceMenuHelper.getPeriodicity();
        const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicity);
        const scaleLabel = yield select(scaleLabelSelect);
    
        const wonLog = UserInfoUtil.IsUserEntitled(EntitlementType.SCALE_WON_LOG_Entitlement);
        let newScale = "";
        if (majorPeriodicity === GraphType.Weekly && wonLog) {
            if (scaleLabel === "LOG (Fixed)") { newScale = "LOG (WON)"; }
            else
                if (scaleLabel === "LOG (WON)") { newScale = "LIN"; }
                else
                    if (scaleLabel === "LIN") { newScale = "LOG (Auto)"; }
                    else { newScale = "LOG (Fixed)"; }
        } else {
            if (scaleLabel === "LOG (Fixed)") { newScale = "LOG (Auto)"; }
            else
                if (scaleLabel === "LOG (Auto)") { newScale = "LIN"; }
                else { newScale = "LOG (Fixed)"; }
        }
    
        const instSettings = PriceMenuHelper.getInstSettings();
        instSettings.ScaleLabelSettings[majorPeriodicity] = newScale;
        SettingsStore.saveSettings();
    
        yield call(processPriceChart);
        yield put({
            type: PriceChartConstants.ActionTypes.PRICE_FR_DATA_READY,
            resize: true
        });
        yield put(calculateIdeaPriceChart());
        yield call(redrawExternalData, {isRepositioning: true});
    }
    catch(error){
        console.log(`Error occured in PriceChartSaga.js, scaleChange ${error}`);
    }
}
const ShowCurrencyConst = [SmartViewType.FUTURES, SmartViewType.CASHSPOTS, SmartViewType.FUND, SmartViewType.ETF, SmartViewType.CEF, SmartViewType.STOCK];
function* calculatePointer(pricePanelData, SymbolInfo, headerData, isHistoric, hiLowPoints, majorPeriodicity, isReceivedQuote, SymbolType) {
    const nodeData = hiLowPoints.allPoints[0];
    if (!nodeData){ 
        return;
    }
    const prevNodeData = hiLowPoints.allPoints[1];
    const currPrice = nodeData.graphData.Close;
    const prevPrice = prevNodeData ? prevNodeData.graphData.Close : currPrice;

    const priceVal = ExtremeDataValue.showPrice(currPrice);
    const { isCurrencyConversionChecked, selectedCurrencyCode, defaultCurrency } = yield select(getCurrencyReducerState);
    let currencyStr = isCurrencyConversionChecked ? selectedCurrencyCode : defaultCurrency;
    const commonStock = DatagraphHelper.isCommonStock(SymbolInfo);
    if (!ShowCurrencyConst.includes(SymbolType)) { currencyStr = ''; }
    let pPrice = isHistoric ? prevPrice : headerData.price ? headerData.price : SymbolInfo.PPrice ? SymbolInfo.PPrice : currPrice;

    if (pPrice) {
        if ((SymbolInfo.DefaultCurrency.Code === "GBP" || SymbolInfo.DefaultCurrency.Code === "ZAR") && !isHistoric) {
            pPrice *= 100;
        }
        if (majorPeriodicity !== GraphType.Daily && majorPeriodicity !== GraphType.Intraday || !commonStock) {
            pPrice = prevPrice;
        }
    } else {
        if (majorPeriodicity !== GraphType.Intraday) { 
            pPrice = prevPrice; 
        }
        else {
            pPrice = pricePanelData.DailyHsfResults && pricePanelData.DailyHsfResults.HSFResults[1].Close;
        }
    }
    let npriceChange = currPrice > 0 ? currPrice - pPrice : pPrice;
    let pricePctChange = currPrice > 0 ? (currPrice - pPrice) * 100 / pPrice : 0;
    if (!isReceivedQuote && SymbolType !== SmartViewType.CURRENCY && (pricePanelData.volumePriceData !== null || pricePanelData.volumePriceData !== undefined)) {
        npriceChange = pricePanelData.volumePriceData.priceChange;
        pricePctChange = pricePanelData.volumePriceData.pricePercentageChange;
    }
    const priceChange = ExtremeDataValue.showPrice(npriceChange);
    const sign = npriceChange < 0 ? '' : '+';
    const pricePct = ExtremeDataValue.showPrice(pricePctChange, false);
    
    yield put({
        type: ActionTypes.POINTER_DATA_READY,
        CPrice: priceVal, 
        currencyStr, 
        currPrice,
        yValue: nodeData.yPrice - 10, 
        priceChangeStr: sign + priceChange, 
        pricePercentChange: pricePctChange, 
        pricePercentChangeStr: `${sign + pricePct}%`, 
        priceChange: npriceChange
    })
}
function* chartResize({ dimension }){
    try{
        if(dimension.height !== PriceMenuHelper.getHeight() || dimension.width !== PriceMenuHelper.getWidth()){
            let initCall = false, isProcessIndicators = false;
            PrintMode.printing && TimeTrackingWindow.setDatagraphRenderReady(false);
            const { nodeWidth, pricePanelData, periodicity, padding} = yield select(getDatagraphStates);
            
            if((ConsoleStore.getStates().nodeCount) <= pricePanelData.HsfData.HSFResults.length && dimension.nodeCount > pricePanelData.HsfData.HSFResults.length){
                initCall = true;
            }
            if(dimension.width !== PriceMenuHelper.getWidth()){
                isProcessIndicators = true
            }
            ConsoleStore.updateNodeCount(dimension.nodeCount);
            PriceMenuHelper.setHeight(dimension.height);
            PriceMenuHelper.setWidth(dimension.width);
            if(initCall){
                pricePanelData.HsfData.HSFResultsRawData = null;
                yield put({
                    type: DataGraphConstants.ActionTypes.CALL_PRICE_CHART_API
                })
            }else{
                const init = periodicity === GraphType.Daily || periodicity === GraphType.Weekly ? 1 : 0;
                let lastnodeIndex = pricePanelData.HsfData.HSFResults.length;
                lastnodeIndex = dimension.nodeCount || lastnodeIndex;
                const startXPoint = nodeWidth * (lastnodeIndex - (padding + init));
                const initialNode = nodeWidth * (lastnodeIndex - init - 1)
                AnnotationUtil.setPriceChartStates({initialNode});
                yield put({
                    type: DataGraphConstants.ActionTypes.STORE_PADDING_VALUE,
                    padding, startXPoint, nodeCount: dimension.nodeCount
                });
                yield call(processPriceChart);
                yield call(redrawExternalData, {});
                yield put({
                    type: PriceChartConstants.ActionTypes.PRICE_FR_DATA_READY,
                    resize: true
                });
                yield put(calculateIdeaPriceChart());
                if(isProcessIndicators){
                    yield put(processIndicator())
                    yield put(processKPIIndicator(false))
                }
            }
            // yield put(updateIndicatorHeight(80));
            SettingsStore.saveSettings();
            PrintMode.printing && TimeTrackingWindow.setDatagraphRenderReady();
        }
    }
    catch(error){
        console.log(`Error occurs in PriceChartSaga.js, chartResize, ${error}`)
    }
}
/********************************************************** */
export function* watchPopulatePricePanel() {
    yield takeLatest(TimelineConstant.ActionTypes.PRICE_PANEL_RESPONSE_READY_TIMELINE, initPriceChart);
}
export function* watchUpdateChartOnStreaming() {
    yield takeLatest(ActionTypes.UPDATE_CHART_ON_STREAMING, initPriceChart);
}
export function* watchToggleOhOL() {
    yield takeLatest(ActionTypes.TOGGEL_OH_OL, toggelOhOl);
}
export function* watchScaleChange() {
    yield takeLatest(ActionTypes.CHANGE_CHART_SCALE, scaleChange)
}
export function* watchReDraw_External_Data() {
    yield takeLatest(ActionTypes.RE_DRAW_EXTERNAL_DATA, redrawExternalData);
}
export function* watchChartResize() {
    yield takeLatest(ActionTypes.CHART_RESIZE, chartResize);
}
