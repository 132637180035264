// import chartApi from "../ServiceApi/Apis/ChartApi";
import ConsoleStore from "../Stores/ConsoleWindow/ConsoleStore";
import DatagraphHelper from "./DatagraphHelper";
import datagraphStore from "../Stores/NavModules/NavDataGraph/DataGraphStore";
import GraphType from "GraphType";
import moment from "moment";
import PeriodicityHelper from "./PeriodicityHelper";
import { RayAnnotationType } from "../Constants/EAnnotationType";
import RiSubCategoryType from "Constants/RiSubCategoryType.js";
import SettingsStore from "SettingsStore"
import { max, min } from "underscore";

class AnnotationUtil {

    constructor() {
        this.TimeLine = null;
        this.isPointerSelected = false;
        this.isCtrlKeyMode = false;
        this.beginToDraw = false;
        this.isAnnotationApiCallInitiated = false;
        this.isAnnotationLoadInitiated = false;
        this.annotations = null;
        this.layerData = null;
        this.initialNode = 0;
        this.padding = 0;
        this.HiLowPoints = {
            allPoints: [],
            highPoints: [], lowPoints: []
        };
        this.periodicity = GraphType.Weekly;
        this.nodeWidth = 4;
        this.pricePanelData = {};
        this.scale = '';
        this.scaleLabel = 'LOG (Fixed)';
        this.SymbolInfo = null;
        this.endDate = new Date();
    }

    setPriceChartStates(states){
        Object.entries(states).forEach(([key, value])=> {
            this[key] = value;
        })
    }

    setAnnotationsModified(isModified) {
        this.beginToDraw = isModified;
        if (isModified) {
            this.annotations = null;
        }
    }

    setMinMaxAxis() {
        this.max_x_Axis = max(this.HiLowPoints.allPoints, (item) => item && item.xAxis).xAxis;
        this.min_x_Axis = min(this.HiLowPoints.allPoints, (item) => item && item.xAxis).xAxis;
    }

    setTimeLine(timeLine){
        this.TimeLine = timeLine;
    }

    getNodeIndex(clientX) {
        const chartWidth = this.getInitialNode();
        const posFromRight = chartWidth - clientX;
        return Math.round(posFromRight / this.nodeWidth);
    }

    getDateFromXcord(xVal) {
        const TimeLineList = this.getTimeLine();
        let nodeIndex = this.getNodeIndex(xVal);
        let stockDate = TimeLineList[0].Date;

        if (nodeIndex < 0) {
            nodeIndex = -nodeIndex;
            stockDate = this.findDate(nodeIndex, stockDate);
        }
        else {
            stockDate = TimeLineList[nodeIndex].Date;
        }
        return stockDate;
        // let _state = this.priceChartState;
        // if (!_state || !_state.HiLowPoints || !_state.HiLowPoints.allPoints) return null;
        // if (nodeIndex >= _state.HiLowPoints.allPoints.length) return null;
        // if (!_state.HiLowPoints.allPoints[nodeIndex]) {
        //     return null;
        // }   
        // if (!_state.HiLowPoints.allPoints[nodeIndex].graphData) {
        //     return null;
        // }
        // return _state.HiLowPoints.allPoints[nodeIndex].graphData.Date;
    }

    findDate(nodeIndex, timeLineDate) {
        let finalDate = moment(timeLineDate);
        timeLineDate = moment(timeLineDate);

        switch (this.getPeriodicity()) {
            case GraphType.Daily: {
                while (timeLineDate.day() === 5) {
                    nodeIndex--;
                    timeLineDate.add(1, 'days');
                }

                const weekEnds = Math.floor(nodeIndex / 5);
                const businessDays = nodeIndex + (weekEnds * 2);
                finalDate = timeLineDate.add(businessDays, 'days');
                break;
            }

            case GraphType.Weekly: {
                const daysDiff = nodeIndex * 7;
                finalDate = timeLineDate.add(daysDiff, 'days');
                break;
            }

            // TODO: Monthly not working properly
            case GraphType.Monthly: {
                const monthDiff = Math.floor(nodeIndex / 4) + 1;
                finalDate = timeLineDate.add(monthDiff, 'months');
                break;
            }

            // TODO: Quarterly not working properly
            case GraphType.Quarterly:
                {
                    const monthDiff = Math.floor((nodeIndex / 4) * 3) + 1;
                    finalDate = timeLineDate.add(monthDiff, 'months');
                    break;
                }

            case GraphType.Annual: {
                const yearDiff = Math.floor(nodeIndex / 4) + 1;
                finalDate = timeLineDate.add(yearDiff, 'years');
                break;
            }

            default:
                break;
        }

        if (finalDate.day() === 0) {
            finalDate.subtract(1, 'days');
        }
        if (finalDate.day() === 6) {
            finalDate.subtract(1, 'days');
        }

        return new Date(finalDate);
    }

    getPriceFromYCord(yVal) {
        if (this.scale) {
            return this.scale.ComputePrice(yVal);
        }
    }

    getPeriodicityType(periodicity = '') {
        return PeriodicityHelper.convertToPeriodicity(periodicity ? periodicity : this.periodicity);
    }

    getPeriodicity() {
        return this.periodicity;
    }

    getScale() {
        return this.scale;
    }

    getScaleText() {
        return this.scaleLabel;
    }

    getNodeWidth() {
        return this.nodeWidth;
    }

    getTimeLine() {
        return this.TimeLine ? this.TimeLine : null;
    }

    getStartDate() {
        return this.getDateFormat(ConsoleStore.getState().beginDate);
    }

    getEndDate() {
        return this.getDateFormat(ConsoleStore.getState().endDate ? ConsoleStore.getState().endDate : new Date());
    }

    getGraphData() {
        return  this.pricePanelData;
    }

    getRightMargin() {
        return this.padding * this.nodeWidth;
    }

    getScaleType() {
        switch (this.scaleLabel) {
            case "LIN":
                return 0;
            case "LOG (Fixed)":
                return 1;
            case "LOG (Auto)":
                return 2;
            case "LOG (WON)":
                return 3;
            default:
                break;
        }
    }

    getMsid() {
        return this.SymbolInfo ? this.SymbolInfo.MsId : "";
    }

    getTargetCurrencyCode() {
        const defaultCurrency = this.SymbolInfo ? this.SymbolInfo.DefaultCurrency ? this.SymbolInfo.DefaultCurrency.Code : '' : '';
        if (datagraphStore.isCurrencyEntitled()) {
            const selectedCurrencyCode = datagraphStore.getCurrencyCode(defaultCurrency);
            return selectedCurrencyCode;
        }
        return defaultCurrency;
    }

    getActiveTabType() {
        const state = datagraphStore.getState();
        return state.SelectedTabKey;
    }

    getActiveSymbolInfo() {
        return this.SymbolInfo;
    }

    getCursorPosition(e) {
        const x = e.evt.layerX + document.body.scrollLeft;
        const y = e.evt.layerY + document.body.scrollTop;

        return { X: x, Y: y };
    }
    getAnchorPoints(width, height, shapeType, points) {

        points.px1 = (width / 2);
        points.py1 = 0;
        switch (shapeType) {
            case RayAnnotationType.RECT_AnnotationType:
                points.px1 = 0;
                points.px2 = width;
                points.py2 = 0;
                points.px3 = width;
                points.py3 = height;
                points.px4 = 0;
                points.py4 = height;
                break;
            case RayAnnotationType.TRIANGLE_AnnotationType:
                points.px2 = width;
                points.px3 = 0;
                points.py2 = height;
                points.py3 = height;
                break;
            case RayAnnotationType.ELLIPSE_AnnotationType:
                points.px2 = width;
                points.py2 = height / 2;
                points.px3 = width / 2;
                points.py3 = height;
                points.px4 = 0;
                points.py4 = height / 2;
                break;
            case RayAnnotationType.TEXT_AnnotationType:
                points.px1 = 0;
                points.px2 = width;
                points.py2 = 0;
                break;
            default:
        }
        return points;
    }

    getDateFormat(date) {
        return moment(date).format('YYYYMMDD');
    }

    getActualPosInStage = (e) => {
        const { layerPos } = this.state;
        const stageBox = this.stage.getStage().getContainer().getBoundingClientRect();
        const mousePos = {
            x: e.evt.clientX,
            y: e.evt.clientY,
        }
        const x = Math.abs(mousePos.x - stageBox.left - layerPos.x);
        const y = Math.abs(mousePos.y - stageBox.top - layerPos.y);
        return { x, y }
    }

    getInitialNode() {
        //const bufferNode = DatagraphHelper.getBufferNode(); //Needs to make changes here.
        return this.initialNode;
    }
    

    getHighLowPoints() {
        return this.HiLowPoints;
    }

    isAnnotationsTabActive() {
        const consoleSettings = SettingsStore.getConsoleSettings();
        const activeTab = DatagraphHelper.getSettingsObject(consoleSettings, consoleSettings.NavDatagraphSettings.RelatedInformationSettings).ActiveSelectedSubCategory
        return activeTab === RiSubCategoryType.Annotations;
    }

    hexToRGBA(hex, opacity) {
        return `rgba(${(hex = hex.replace('#', '')).match(new RegExp(`(.{${hex.length / 3}})`, 'g')).map((l) => parseInt(hex.length % 2 ? l + l : l, 16)).concat(isFinite(opacity) ? opacity : 1).join(',')})`
    }

    setIsPointerToolActive(isPointerSelected) {
        this.isPointerSelected = isPointerSelected;
    }
}
const annotationUtil = new AnnotationUtil();
export default annotationUtil;