import keyMirror from "keymirror";

export const ListManagerCustomMetrics = {
  ActionTypes: keyMirror({
    BROWSE_METRICS: null,
    BROWSE_METRICS_CLICK: null,
    ADD_METRICS_CLICK: null,
    ADD_TO_METRIC_DIALOG: null,
    OPEN_METRICS_DEFINITION: null,
    OPEN_MANAGE_METRICS: null,
    OPEN_MANAGE_METRICS_TRUE: null,
    HANDLE_INTERVAL_CHANGE: null,
    HANDLE_INTERVAL_CHANGE_SUCCESS: null,
    HANDLE_LOOKBACK_CHANGE: null,
    HANDLE_LOOKBACK_CHANGE_SUCCESS: null,
    HANDLE_NAME_CHANGE: null,
    HANDLE_DUPLICATE_METRIC: null,
    HANDLE_DUPLICATE_METRIC_SUCCESS: null,
    HANDLE_DELETE_METRIC: null,
    HANDLE_DELETE_METRIC_SUBMIT: null,
    HANDLE_DELETE_METRIC_CANCEL: null,
    HANDLE_DELETE_METRIC_SUCCESS: null,
    HANDLE_RENAME_CLICK: null,
    HANDLE_RENAME_CLICK_SUCCESS: null,
    OPEN_METRICS_DESCRIPTION: null,
    OPEN_METRICS_EDITFORMAT: null,
    HANDLE_RESULT_FORMAT_CHANGE: null,
    HANDLE_RESULT_UNITS_CHANGED: null,
    HANDLE_RESULT_DECIMALS_CHANGED: null,
    HANDLE_RESULT_FORMAT_CHANGED: null,
    CLEAR_METRICS_EDITFORMAT: null,
    CLEAR_METRICS_EDITFORMAT_SUCCESS: null,
    SAVE_METRICS_EDITFORMAT: null,
    SAVE_METRICS_EDITFORMAT_SUCCESS: null,
    SAVE_CUSTOM_METRIC_DESCRIPTION: null,
    HANDLE_RENAME_EDIT_CHANGE: null,
    HANDLE_RENAME_SAVE_CHANGE: null,
    HANDLE_RENAME_SAVE_CHANGE_SUCCESS: null,
    HANDLE_CREATE_CUSTOM_METRICS: null,
    HANDLE_CREATE_CUSTOM_METRICS_SUCCESS: null,
    HANDLE_ADD_TO_COLUMN: null,
    HANDLE_ADD_TO_COLUMN_SUCCESS: null,
    HANDLE_VALIDATION_ERROR: null,
    HANDLE_CLOSE_ERROR_MESSAGE: null,
    HANDLE_NAME_CHANGE_SUCCESS: null,
    HANDLE_NAME_SEARCH: null,
    HIDE_SEARCH_DROPDOWN: null,
    HANDLE_EDIT_NAME_OUTSIDE_CLICK: null,
    HANDLE_CLOSE_CUSTOM_METRIC: null,
    HANDLE_CLICK_LOADER: null,
    HANDLE_NAME_SEARCH_SUCCESS: null,
    HANDLE_FORMULA_CHANGE: null,
    HANDLE_FORMULA_CHANGE_SUCCESS: null,
    HANDLE_SYMBOL_CHANGE: null,
    HANDLE_SYMBOL_CHANGE_SUCCESS: null,
    HANDLE_DEFINITION_SELECTED: null,
    HANDLE_DEFINITION_SELECTED_SUCCESS: null
  })
};