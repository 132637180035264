import AppDispatcher from "AppDispatcher";
import store from "../Redux/store.js";
import { setActiveNodeChanged, setActiveNode } from "../Actions/ListViewActions";
import { OwnershipActionConstants } from "../Constants/OwnershipActionConstants";
import ListApi from "../ServiceApi/Apis/ListApi.js";
import { OwnerShipFetchDetails, OwnershipViewConstants } from "../Constants/OwnershipConstants";
import OwnershipViewStore from "../Stores/NavModules/NavList/TabOwnership/OwnershipViewStore.js";
import SettingsStore from "SettingsStore";
import BaseServiceApi from 'BaseServiceApi';
import ListManager from "../Constants/ListManager.js";
import ListExplorerStore from "../Stores/NavModules/NavList/Explorer/ListExplorerStore.js";
import BrowserUtil from "BrowserUtil";


const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

const { ActionTypes } = OwnershipActionConstants;

export const getReportExtdata = (osid) => ({

    type: ActionTypes.GET_OWNERSHIP_REPORT_DATA,
    osid
})

export const changeTextSize = (textSize) => ({
    type: ActionTypes.OWNERSHIP_REPORT_CHANGE_TEXT,
    textSize
})

export const columnRankChanged = (isColumnRankChanged) => ({
    type: ActionTypes.OWNERSHIP_COLUMN_RANK_CHANGED,
    result: isColumnRankChanged
})

export const columnPortChanged = (isColumnPortChanged) => ({
    type: ActionTypes.OWNERSHIP_COLUMN_PORT_CHANGED,
    result: isColumnPortChanged
})

export const columnSharesChanged = (isColumnSharesChanged) => ({
    type: ActionTypes.OWNERSHIP_COLUMN_SHARES_CHANGED,
    result: isColumnSharesChanged
})

export const showLoader = (isLoading) => ({
    type: ActionTypes.OWNERSHIP_SHOW_LOADER,
    result: isLoading
})

export const tabSelectionChanged = (tabIndex) => ({
    type: ActionTypes.OWNERSHIP_TAB_CHANGE,
    tabIndex
})
export const subTabSelectionChanged = (subTabIndex) => ({
    type: ActionTypes.OWNERSHIP_SUB_TAB_CHANGE,
    subTabIndex
})
export const columnSortSeqChanged = (orderString, orderSeqArr) => ({
    type: ActionTypes.OWNERSHIP_COLUMN_SORT_CHANGED,
    orderString,
    orderSeqArr
})
export const passiveFilterChange = (passiveType) => ({
    type: ActionTypes.OWNERSHIP_PASSIVE_OWNER_FILTER_CHANGE,
    passiveType
})

export const limitChange = (limitData) => ({
    type: ActionTypes.OWNERSHIP_LIMIT_CHANGE,
    limitData
})

export const onSearchTextChange = (searchText) => ({
    type: ActionTypes.OWNERSHIP_SEARCH_TEXT_CHANGE,
    result: searchText
})

export const resizeOwnershipTab = () => ({
    type: ActionTypes.OWNERSHIP_TAB_RESIZE,
    result: 1
})

export const OwnerShipActions = (nodeID) => {
    BrowserUtil.disableEvents()
    ListApi.GetOwnerShipData(nodeID).then((OwnershipBasicResult) => {
        BrowserUtil.enableEvents()
        AppDispatcher.handleServerAction({
            actionType: OwnerShipFetchDetails.GET_OWNERSHIP_DATA,
            data: { esBasicCatalogData: OwnershipBasicResult.catalog, basicEsInfo: nodeID, listId: nodeID.listId, actualListId: nodeID.actualListId, listName: nodeID.listName }
        });
    });
}

export const setShowOriginalActiveNode = (nodeID) => {
    store.dispatch(setActiveNode(nodeID));
}

export const OwnerShipActionsNode = (nodeID, targetListId, targetNodeName, activeNode) => {
    ListApi.GetOwnerShipData(nodeID).then((OwnershipBasicResult) => {
        const catalogData = OwnershipBasicResult.catalog;
        const listGridData = JSON.parse(catalogData.ownershipRecordInfo);
        const msids = [];
        if (listGridData) {
            for (const record of listGridData) {
                msids.push(record["-4000008"]);
            }
        }
        addSymbolsToList(targetListId, targetNodeName, activeNode, msids);
    });
}

export const setActiveNodeToOwnershipStore = (activeNode) => {
    AppDispatcher.handleServerAction({
        actionType: OwnershipViewConstants.ActionTypes.SET_ACTIVE_NODE,
        data: activeNode
    });
}

export const getListColumnPickList = (columnId) => {
    AppDispatcher.handleViewAction({
        actionType: OwnershipViewConstants.ActionTypes.GET_COLUMN_PICKLIST_OWNERSHIP,
        data: { columnId: columnId }
    });
}

export const toggleScreenOnOff = (isOnOrOff) => {
    AppDispatcher.handleViewAction({
        actionType: OwnershipViewConstants.ActionTypes.SCREEN_ON_OFF_OWNERSHIP,
        data: isOnOrOff
    });
}

export const showAlert = (message, undo) => {
    AppDispatcher.handleViewAction({
        actionType: OwnershipViewConstants.ActionTypes.SET_ALERT,
        data: { message: message, undo: undo }
    });
}

export const reloadListCatalog = () => {
    AppDispatcher.handleViewAction({
        actionType: OwnershipViewConstants.ActionTypes.RELOAD_LIST_CATALOG_OWNERSHIP,
        data: {}
    })
}

export const selectColumnSet = (searchText) => {
    AppDispatcher.handleViewAction({
        actionType: OwnershipViewConstants.ActionTypes.SELECT_COLUMN_SET_OWNERSHIP,
        data: searchText
    });
}

export const addSymbolsToList = (targetListId, targetNodeName, currentNodeId, msids = null) => {
    AppDispatcher.handleServerAction({
        actionType: OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_OWNERSHIP,
        data: { targetListId: targetListId, targetNodeName: targetNodeName, currentNodeId: currentNodeId, msids: msids }
    });
}

export const addSymbolsToListByDrag = (targetListId, targetNodeName, currentNodeId, msids = null, maxCopySymbols) => {
    AppDispatcher.handleServerAction({
        actionType: OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_BY_DRAG_OWNERSHIP,
        data: { targetListId: targetListId, targetNodeName: targetNodeName, currentNodeId: currentNodeId, msids: msids, maxCopySymbols: maxCopySymbols }
    });
}

export const saveScreenedResults = () => {
    AppDispatcher.handleServerAction({
        actionType: OwnershipViewConstants.ActionTypes.SAVE_SCREENED_RESULTS_OWNERSHIP,
        data: {}
    });
}

export const getRefreshedView = () => {
    AppDispatcher.handleViewAction({
        actionType: OwnershipViewConstants.ActionTypes.GET_REFRESHED_VIEW_OWNERSHIP,
        data: {}
    })
}

export const fetchMoreDataOnScroll = (listCatalog) => {
    AppDispatcher.handleServerAction({
        actionType: OwnershipViewConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL_OWNERSHIP,
        data: { listCatalog: listCatalog }
    });
}

export const getListCatalogForSearchText = (searchText) => {
    AppDispatcher.handleViewAction({
        actionType: OwnershipViewConstants.ActionTypes.GET_SEARCH_TEXT_OWNERSHIP,
        data: searchText
    });
}

export const clearSearchText = (searchText) => {
    AppDispatcher.handleViewAction({
        actionType: OwnershipViewConstants.ActionTypes.CLEAR_SEARCH_TEXT_OWNERSHIP,
        data: searchText
    });
}

export const addListToList = (dragNodeID, targetListId, targetNodeName, activeNode) => {

    const listCatalog = {
        listId: dragNodeID.nodeId.low,
        actualListId: dragNodeID.nodeId.low,
        searchText: '',
        sortCols: '',
        needColumnsInfo: 1,
        addlCatalogRequests: null,
        useColFilters: 1,
        saveColFilters: 0,
        customerOrder: 0,
        colsetId: '-1',
        pageIndex: 0,
        size: 10000,
        isQuoteboard: 0,
        msId: null,
        cacheId: -1,
        reorderIds: 0,
        isUserList: 0,
        isMinilist: 0

    }
    OwnerShipActionsNode(listCatalog, targetListId, targetNodeName, activeNode);
}

export const dataLoadingComplete = (listState) => {
    AppDispatcher.handleViewAction({
      actionType: OwnershipViewConstants.ActionTypes.ON_DATA_LOADING_COMPLETE_OWNERSHIP,
      data: { listState: listState }
    });
  }
  
export const updateMiniList = (sym) => {
    AppDispatcher.handleServerAction({
        actionType: OwnershipViewConstants.ActionTypes.UPDATE_MINI_LIST_OWNERSHIP,
        data: { symbol: sym }
    });
}

export const setActiveNodeFromOwnership = (data) => {
    store.dispatch(setActiveNodeChanged(data));
}

export const clearOwnerShipReportData = (isLoading) => ({
    type: ActionTypes.CLEAR_OWNERSHIP_REPORT_DATA,
    result: isLoading
});

export const fetchListCataglogData = (listId, actualListId, listName, pageIndex = 0, cacheId = -1, isUserList = 0) => {
    if (listId && actualListId) {
        let listCatalog = OwnershipViewStore.listCatalog;
        
        if (!listCatalog) {
            listCatalog = {
                listId: null,
                actualListId: null,
                searchText: '',
                sortCols: '',
                needColumnsInfo: 1,
                addlCatalogRequests: null,
                useColFilters: 1,
                saveColFilters: 0,
                customerOrder: 0,
                colsetId: '-1',
                pageIndex: 0,
                size: 250,
                isQuoteboard: 0,
                msId: null,
                cacheId: -1,
                reorderIds: 0,
                isUserList: 0,
                isMinilist: 0
            }
            const settings = SettingsStore.getConsoleSettings();
            const navListManagerSettings = settings && settings.NavListManagerSettings;
            const tabOwnershipSettings = navListManagerSettings.TabOwnershipSettings;
            if (tabOwnershipSettings) {
                listCatalog.listId = tabOwnershipSettings.SelectedListInfo.SelectedListId !== null && tabOwnershipSettings.SelectedListInfo.SelectedCategoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory ? tabOwnershipSettings.SelectedListInfo.SelectedListId : tabOwnershipSettings.getDefaultTabOwnershipSettings().SelectedListInfo.SelectedListId;
                listCatalog.actualListId = tabOwnershipSettings.SelectedListInfo.SelectedActualListId !== null && tabOwnershipSettings.SelectedListInfo.SelectedCategoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory ? tabOwnershipSettings.SelectedListInfo.SelectedActualListId : tabOwnershipSettings.getDefaultTabOwnershipSettings().SelectedListInfo.SelectedActualListId;
                listCatalog.useColFilters = tabOwnershipSettings.ScreenOnOff === ListManager.ScreenOnOff ? 1 : 0;
                listCatalog.isUserList = tabOwnershipSettings.SelectedListInfo.SelectedCategoryType === ListCategoryType.USER_ListCategory ? 1 : 0;
            }
        }

        if (listCatalog && listCatalog.listId !== listId) {
          listCatalog.searchText = '';
          OwnershipViewStore.clearSourecData();
        }                
        const isNotImportedList = OwnershipViewStore.activeNode && OwnershipViewStore.activeNode.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory ? true : false;         
        listCatalog = {
          ...listCatalog, listId: listId, actualListId: actualListId, isUserList: isUserList,
          listName: listName, pageIndex: pageIndex, cacheId: cacheId !== -1 ? cacheId : 0, isNotImportedList: isNotImportedList
        }
        
        ListExplorerStore.activeSybol.symbol = undefined;
        ListExplorerStore.activeSybol.msId = undefined;
        OwnershipViewStore.listCatalog = listCatalog;
        OwnershipViewStore.initialCall = false;
        OwnerShipActions(listCatalog)
    }
}