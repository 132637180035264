class OneilApi {
    GetOneilLoginApi(encryptedEmail, researchPageUrl) {
      return new Promise((resolve, reject) => {
        let url = researchPageUrl+ "wp-json/rv/v1/login";
        var params = "email=" + encryptedEmail;
        const xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.onload = function () {
          if (this.status == 0) {
            console.log(xhr.response)
          }
          if (this.status >= 200 && this.status < 300) {
            resolve(xhr.response);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
        };
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        };
        xhr.send(params);

      })
    }
    GetResearchData(symbol, oneilAuthorization, researchPageUrl){
      return new Promise((resolve, reject) => {
          let url = researchPageUrl+"wp-json/rv/v1/search";
          var params = "key=&symbol="+symbol+"&type=&analyst=&time=&start_time=&end_time=&sort=desc&is_filter=&category=&search=&currentpage=1"
          const xhr = new XMLHttpRequest();
          xhr.open("POST", url, true);
          xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
          xhr.setRequestHeader("Authorization", oneilAuthorization);
          xhr.onload = function () {
              if(this.status == 0){
                  console.log(xhr.response)
              }
              if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
              } else {
                reject({
                  status: this.status,
                  statusText: xhr.statusText
                });
              }
          };
          xhr.onerror = function () {
              reject({
                status: this.status,
                statusText: xhr.statusText
              });
            };
            xhr.send(params);

      })
  }
  SendEntlitmentRequest(obj,researchPageUrl){
    return new Promise((resolve, reject) => {
      let url = researchPageUrl+"wp-json/rv/v1/getentitled";
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader('Content-Type',  'application/json' );
      xhr.onload = function () {
          if(this.status == 0){
              console.log(xhr.response)
          }
          if (this.status >= 200 && this.status < 300) {
            resolve(xhr.response);
          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }
      };
      xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        };
        xhr.send(JSON.stringify(obj));
  });
  }
}
var oneilApi = new OneilApi();
export default oneilApi;