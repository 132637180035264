import { fork, call, all, put, select, takeLatest, cancel } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import { unescape, max, min, map, invert, extend } from 'underscore';
import moment, { isMoment } from 'moment';
import DateHelper from 'DateHelper';
import GraphTypeEnum from 'GraphType';
import chartApi from '../../../ServiceApi/Apis/ChartApi';
import compareApi from '../../../ServiceApi/Apis/CompareApi';
import TimeLineHelper from "Stores/TimeLine/TimeLineHelper";
import { getCompChartListItem, getCompareChartViewModel, getChartWidth, getNodeWidth, getPeriodicity, getStartDate, getEndDate, getChartMaxPrice, getChartMinPrice, getScaleType, getChartHeight, getScale, getCompareChart } from '../../../Reducers/NavCompare/ComparisonGraphReducers/selectors';
import { getMenuTab } from '../../../Reducers/NavCompare/MenuPanelReducers/selectors';
import { getSelectedTabActiveMenuName, getIsComparisonTabSelected } from '../../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import { SymbolDataModel, DataPoint, LineDataModel } from '../../../Models/ComparisonChartModels/LineDataModel';
import { ScaleType } from '../../../Models/ComparisonChartModels/GraphPanelModelStates';
import SymbolType from '../../../Constants/SymbolType';
import SettingsStore from "SettingsStore"
import { getFilterSymbol } from '../MenuPanel/LiqAndRatingFilterSaga';
import UserInfoUtil from "UserInfoUtil";
import { initCompChart } from 'Actions/ComparisonActions';
import LocalizationStore from 'Stores/Localization/LocalizationStore';
import { ComparisonAndRotationMenuConstants } from '../../../Constants/RotationGraphConstants';
import { ComparisonGraph } from '../../../Constants/ComparisonGraph';
import { CompareMenuType } from "../../../Constants/CompareTabType";
import CompareReqInfo from '../../../ServiceApi/RequestHelper/CompareReqInfo';
import ConsoleStore from '../../../Stores/ConsoleWindow/ConsoleStore';

const { ActionTypes } = ComparisonAndRotationMenuConstants;
extend(ActionTypes, ComparisonGraph.ActionTypes);

const symbolTypeEnumValues = invert(SymbolType);
let ComparisonRequest = { Osid: null, SymTypeEnum: null, Symbol: null, LocalSymbol: null, CoName: null }
let stockInfo = null;
let isInit = false;
let menuInit = { symbolsMenuInit: false, groupsMenuInit: false };
let symbolService = null;
let timeOutFunc = null;

export function getSymbolTypeEnumStringValue(SymbolType) {
    return symbolTypeEnumValues[Number(SymbolType)];
}

function* getLiqFilterData() {
    try {
        yield put({
            type: ActionTypes.INIT_LIQ_VIEW_MODEL,
        })

    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getLiqFilterData ${error}`);
    }

}

function* initializeChart({ reDraw = false }) {
    let menuTab = yield select(getMenuTab);
    if (menuTab.ComparisonMenuTab == CompareMenuType.Symbols && menuInit.symbolsMenuInit == false) {
        isInit = menuInit.symbolsMenuInit
        menuInit.symbolsMenuInit = true;
    } else if (menuTab.ComparisonMenuTab == CompareMenuType.Groups && menuInit.groupsMenuInit == false) {
        isInit = menuInit.groupsMenuInit
        menuInit.groupsMenuInit = true;
    }
    try {
        console.log("PRINT:--Tracker -- initialize comp chart");
        yield call(handleLoading, true);
        if (!isInit) {
            yield call(setValueFromSettings);
            yield call(setLatestTradingDate);
            isInit = true;
        }
        yield call(setNumOfChartNodes);
        const states = yield select(getCompareChartViewModel);
        const isTimeLine = yield call(setTimeLineData);

        if (isTimeLine)
            yield call(populateTimeLine);


        if (reDraw) {

            yield put({
                type: ActionTypes.CLEAR_ALL_SUCCESS,
                data: []
            })

        }

        const lines = yield call(setInitLineCollection, states.symbolRequest);

        const preLinePlotRequired = states.msids.length > 100;
        if (preLinePlotRequired) {
            yield call(getCompareChartScaleDataAndSetScale, states.msids);
        }

        yield call(calculateDataPointsForSymbols, lines, preLinePlotRequired, false, true, true);

        yield call(handleAllSavedIndexLines);

        yield call(handleLoading, false);
        console.log("PRINT:--Tracker -- comp chart process is complete");
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, initializeChart ${error}`);
    }

}

function* handleLoading(isLoading = false) {
    try {
        yield put({
            type: ActionTypes.MENU_LOADING,
            isLoading
        });

        if (!isLoading) {
            setTimeout(() => {
                ConsoleStore.setChartLoaded();
                console.log("PRINT:--Tracker -- div is set");
            }, 2000)
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, handleLoading ${error}`);
    }
}

function* setInitLineCollection(symbolRequest, isInitData = true) {
    if (!isInitData) return true;
    let LinesCollection = [];
    try {
        symbolRequest.forEach((symbolData) => {
            const { Osid,msid, SymTypeEnum,isVisible,isHighlighted,index } = symbolData
            let lineDataModel = new SymbolDataModel();
            lineDataModel.label = null;
            lineDataModel.index = index;
            lineDataModel.isVisible = isVisible;
            lineDataModel.isHighlighted = isHighlighted;
            lineDataModel.id = Osid.toString();
            lineDataModel.Osid = Osid;
            lineDataModel.msid = msid;
            lineDataModel.name = null;
            lineDataModel.localSymbol =  null;
            lineDataModel.actualSymbol = null;
            lineDataModel.SymTypeEnum = SymTypeEnum;
            lineDataModel.isFilter = true;
            lineDataModel.reset();
            LinesCollection = [...LinesCollection, lineDataModel]
        });
        return LinesCollection;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in addSymbol.js, setInitLineCollection ${error}`);
    }

}

function* callSymbolServiceAsync(symbolRequest) {
    try {
        if (symbolRequest && symbolRequest.length === 0) return null;
        const results = yield call(getSymbolClosingResults, symbolRequest);
        return results;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, callSymbolServiceAsync ${error}`);
    }

}

export function* processData(skipScaleSettings = false) {
    try {
        if (!skipScaleSettings)
            yield call(setChartSettingsForLineProcessing);

        const nodeContainerWidth = yield select(getNodeWidth);
        const scale = yield select(getScale);
        const States = yield select(getCompareChartViewModel);
        const LinesCollection = yield select(getCompChartListItem);
        yield call(calculateLinepoints, LinesCollection, nodeContainerWidth, scale, States, false)
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, processData ${error}`);
    }
}

export function* setChartSettingsForLineProcessing(ignoreCalculation = false,maxValue = null,minValue = null){
    yield call(resetPriceScaleInfo);
    yield call(setChartMaxMinValue,ignoreCalculation,maxValue,minValue);
    yield call(setScaleInfo);
    yield call(initializedScale);
}


function* calculateLinepoints(lineDataModels, nodeContainerWidth, scale, States, filterData = true) {

    try {
        console.log("PRINT:--Tracker -- calculateLinepoints");
        let newLineDataModelArray = [];

        if (!Array.isArray(lineDataModels)) {
            lineDataModels = [lineDataModels]
        }
        for (let i = 0; i < lineDataModels.length; i++) {
            const lineDataModel = lineDataModels[i];
            if (lineDataModel.dataPoints.length > 0) {
                let drawIndex = States.numOfChartNodes - 1;
                let lineSource = [];
                lineDataModel.dataPoints.forEach((dataPoint, i) => {
                    if (drawIndex >= 0) {
                        const x = drawIndex * nodeContainerWidth;
                        if (dataPoint != null) {
                            const y = dataPoint.Value !== undefined && dataPoint.Value !== null ? scale.ComputeY(dataPoint.Value) : lineSource[(drawIndex - 1) * nodeContainerWidth];
                            if (Number.isFinite(y)) {
                                lineSource = [...lineSource, [x, y]];
                            }
                            else {
                                if (lineSource.length > 0) lineSource = [...lineSource, [x, lineSource[i - 1][1]]];
                            }
                            drawIndex--;
                        }
                    }
                });
                if (lineSource.length <= 0) {
                    newLineDataModelArray.push(lineDataModel);
                } else {
                    const newLineDataModel = Object.assign(new SymbolDataModel(), { ...lineDataModel, lineSource: lineSource, labelPos: { x: lineSource[0][0], y: lineSource[0][1] } });
                    newLineDataModelArray.push(newLineDataModel);
                }
            } else {
                newLineDataModelArray.push(lineDataModel);
            }
        }

        if (newLineDataModelArray.length <= 0)
            return;

        yield call(updateLineData, newLineDataModelArray, filterData);
        console.log("PRINT:--Tracker -- calculateLinepoints complete");
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, calculateLinepoints ${error}`);
    }

}

function* calculateDataPointsAsync() {
    const LinesCollection = yield select(getCompChartListItem);
    try {
        let lineDataModelArray = [];
        LinesCollection.map((lineDataModel)=>{
            lineDataModel.reset();
            lineDataModelArray.push(lineDataModel);
        })
     
        yield put({
            type:ActionTypes.CLEAR_ALL_SUCCESS,
            data:[]
        })

         yield call(calculateDataPointsForSymbols, lineDataModelArray,true,false,false);

    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, calculateDataPointsAsync ${error}`);
    }

}

export function* calculateDataPointsForSymbols(lineDataModelArrayRequest,skipLinePointsCalcFunc,inputFromAddSymbol,needRefresh = true, isInitializeChart = false) {
    let nodeContainerWidth = yield select(getNodeWidth);
    let scale = yield select(getScale);
    let States = yield select(getCompareChartViewModel);
    try {
        console.log("PRINT:--Tracker -- calculateDataPointsForSymbols");
        if (!Array.isArray(lineDataModelArrayRequest)) {
            lineDataModelArrayRequest = [lineDataModelArrayRequest];
        }

        const requestArrayLength = lineDataModelArrayRequest.length;
        const limit = 100;
        const chunkSize = requestArrayLength % limit === 0 ? Math.floor(requestArrayLength / limit) : (Math.floor(requestArrayLength / limit)) + 1;
        for (let i = 0; i < chunkSize; i++) {
            let endSize = chunkSize - 1 !== i ? (i + 1) * limit : requestArrayLength
            let lineDataModels = lineDataModelArrayRequest.slice(i * limit, endSize);

            let lineDataModelArray = [];
            let requestDataArray = [];
            let closeResults = [];

        for (let i = 0; i < lineDataModels.length; i++) {
            let requestData = Object.assign(new SymbolDataModel(), lineDataModels[i]);
            requestDataArray.push({ Osid: requestData.Osid, SymTypeEnum: requestData.SymTypeEnum })
        }
        if (needRefresh) {
            console.log("PRINT:--Tracker -- GetComparisonDataForSymbol");
            closeResults = yield call(callSymbolServiceAsync, requestDataArray);
            console.log("PRINT:--Tracker -- GetComparisonDataForSymbol complete");
            if (!closeResults[0]) return;
            else if(isInitializeChart && closeResults.length > 0){
                if((closeResults.length < limit || endSize == requestArrayLength) && closeResults.length < States.symbolRequest.length){
                    let newSymbolRequest = [];
                    for (let i = 0; i < States.symbolRequest.length; i++) {
                        let symbolItem = States.symbolRequest[i];
                        let resultItem = closeResults.find((itm) => itm.Osid == symbolItem.Osid);
                        if(resultItem){
                            newSymbolRequest.push(symbolItem);
                        }
                    }
                    yield put({
                        type: ActionTypes.UPDATE_SYMBOL_REQ,
                        symbolRequest : newSymbolRequest
                    })
                }
                
            }
        }
        else {
            lineDataModelArray = [...lineDataModels];
        }
        for (let i = 0; i < closeResults.length; i++) {
            let symbolData = Object.assign(new SymbolDataModel(), lineDataModels[i]);
            symbolData.CloseResults = closeResults[i];
            if (symbolData.CloseResults.Symbol && symbolData.label !== unescape(symbolData.CloseResults.Symbol)) {
                symbolData.label = UserInfoUtil.hasLocalSymbol() ? unescape(symbolData.CloseResults.LocalSymbol) : unescape(symbolData.CloseResults.Symbol);
            }
            symbolData.localSymbol = unescape(symbolData.CloseResults.LocalSymbol);
            symbolData.actualSymbol = unescape(symbolData.CloseResults.Symbol);
            symbolData.name = unescape(symbolData.CloseResults.Coname);
            symbolData.GlobalDgRating = symbolData.CloseResults.GlobalDgRating;
            symbolData.GlobalEpsRank = symbolData.CloseResults.GlobalEpsRank;
            symbolData.GlobalRsRating = symbolData.CloseResults.GlobalRsRating;
            symbolData.Accdis = symbolData.CloseResults.Accdis;
            symbolData.DataGraphRating = symbolData.CloseResults.DataGraphRating;
            symbolData.EpsRank = symbolData.CloseResults.EpsRank;
            symbolData.RelativeStrength = symbolData.CloseResults.RelativeStrength;
            symbolData.MktCap = symbolData.CloseResults.MktCap;
            symbolData.AvDolV = symbolData.CloseResults.AvDolV;
            symbolData.SymTypeEnum = symbolData.CloseResults.SymTypeEnum;
            symbolData.Osid = symbolData.CloseResults.Osid;
            if (needRefresh) {
                symbolData.isFilter = false;
            }
            symbolData = yield call(getFilterSymbol, symbolData);
            if (!symbolData.CloseResults.ComparisionResults ||
                (symbolData.CloseResults.ComparisionResults && symbolData.CloseResults.ComparisionResults.length <= 0)) {
                symbolData.updatePercValues();
                symbolData.dataPoints.length = 0;
                yield call(updateLineData, symbolData);
                return;
            }
            lineDataModelArray.push(symbolData);
        }
        
        const lineDataModelArrayResponse = yield call(calculateLineDataPoint, lineDataModelArray, nodeContainerWidth, scale,skipLinePointsCalcFunc);

            yield put({
                type:ActionTypes.ADD_LIST_ITEM,
                listItem:lineDataModelArrayResponse
            })
        
        if(!skipLinePointsCalcFunc){
            
            if(inputFromAddSymbol){
                if(lineDataModelArrayResponse[0].maxValue > States.chartMaxPrice || lineDataModelArrayResponse[0].minValue < States.chartMinPrice)
                {
                   yield call(processData)
                }
                else{
                    scale = yield select(getScale);
                    States = yield select(getCompareChartViewModel);
                    nodeContainerWidth = yield select(getNodeWidth);
                    yield call(calculateLinepoints, lineDataModelArrayResponse, nodeContainerWidth, scale, States,true);
                }
            }
            else{
               yield call(processData);
                
            }
        }
    }
    console.log("PRINT:--Tracker -- calculateDataPointsForSymbols complete");
}
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log("PRINT:--Tracker -- calculateDataPointsForSymbols error", error);
        console.log(`Error occurs in chartViewModel.js, calculateDataPointsForSymbols ${error}`);
    }
}

function* getPriceLineIndexForName() {
    let index = 1;
    const prefix = `${LocalizationStore.getTranslatedData("CCG_Index", "INDEX")} `;
    try {
        const LinesCollection = yield select(getCompChartListItem);
        const prevLineOfIndexes = LinesCollection.filter((itm) => itm.isUserCreated);
        if (prevLineOfIndexes) {
            const IndexLines = prevLineOfIndexes.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
            for (const key in IndexLines) {
                if (IndexLines.hasOwnProperty(key)) {
                    const priceIndexLineModel = IndexLines[key];
                    const currName = prefix + index;
                    // if (priceIndexLineModel.label !== currName) break;
                    if (priceIndexLineModel.label.localeCompare(currName, undefined, { numeric: true }) !== 0) break;
                    else index++;
                }
            }
        }
        return index;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getPriceLineIndexForName ${error}`);
    }

}


function* addPriceIndexLine(index, init = true, isSavedData = false, indexLine = null) {
    try {
        let priceIndexLine = new SymbolDataModel();
        if (!isSavedData && !indexLine) {
            const indexLineCount = yield call(getPriceLineIndexForName);
            priceIndexLine.label = `${LocalizationStore.getTranslatedData("CCG_Index", "INDEX")} ${indexLineCount}`;
            priceIndexLine.localSymbol = `${LocalizationStore.getTranslatedData("CCG_Index", "INDEX")} ${indexLineCount}`;
            priceIndexLine.index = index;
            priceIndexLine.isVisible = true;
            priceIndexLine.id = `INDEX ${indexLineCount}`;
            priceIndexLine.Osid = `INDEX ${indexLineCount}`;
            priceIndexLine.name = `${LocalizationStore.getTranslatedData("CCG_PriWeiInd", "Price Weighted Index")} ${indexLineCount}`;
            priceIndexLine.isEditMode = init ? false : true;
            priceIndexLine.isUserCreated = true;
            priceIndexLine.reset();
            // yield put({
            //     type: ActionTypes.ADD_LIST_ITEM,
            //     listItem: priceIndexLine
            // });
        }
        else {
            const LinesCollection = yield select(getCompChartListItem);
            const lineData = LinesCollection.find((line) => indexLine.Osid === line.Osid)
            if (!lineData) {
                priceIndexLine.label = indexLine.label;
                priceIndexLine.localSymbol = indexLine.label;
                priceIndexLine.index = index;
                priceIndexLine.isVisible = indexLine.isVisible;
                priceIndexLine.id = indexLine.Osid;
                priceIndexLine.Osid = indexLine.Osid;
                priceIndexLine.name = indexLine.name;
                priceIndexLine.isEditMode = false;
                priceIndexLine.isUserCreated = true;
                priceIndexLine.isHighlighted = indexLine.isHighlighted;
                priceIndexLine.reset();
                // yield put({
                //     type: ActionTypes.ADD_LIST_ITEM,
                //     listItem: priceIndexLine
                // });
            }
            else {
                lineData.reset();
                priceIndexLine = lineData;
            }
        }


        return priceIndexLine;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, addPriceIndexLine ${error}`);
    }

}

function* calculatePriceIndexLine(symbolRequest) {
    const symbols = symbolRequest;
    let comparisionResults = [];
    let symbolsNotInCurrentView = [];
    let LinesCollection;

    try {
        LinesCollection = yield select(getCompChartListItem);
        symbols.forEach((symbol) => {
            let symBolModel = LinesCollection.find((itm) => itm.Osid === symbol.Osid)
            if (symBolModel && symBolModel.CloseResults) {
                const comparisionItem = symBolModel.CloseResults;
                comparisionResults = [...comparisionResults, comparisionItem];
            }
            else {
                symbolsNotInCurrentView = [...symbolsNotInCurrentView, symbol];
            }
        });

        if (symbolsNotInCurrentView.length > 0) {
            const notInViewResults = yield call(getSymbolClosingResults, symbolsNotInCurrentView);
            comparisionResults = [...comparisionResults, ...notInViewResults];
        }
        return comparisionResults;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, calculatePriceIndexLine ${error}`);
    }

}

function* addindexLines(symbolRequest, index = 1, init = true, isSavedData = false, indexLine = null, numOfChartNodes, totalTime) {
    try {
        const [comparisionResults, priceIndexLine] = yield all([
            call(calculatePriceIndexLine, symbolRequest),
            call(addPriceIndexLine, index, init, isSavedData, indexLine)
        ]);
        yield call(setIndexLines, comparisionResults, priceIndexLine, isSavedData, numOfChartNodes, totalTime)
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, addindexLines ${error}`);
    }
}

function* getAverageOfSymbol(comparisionResults) {
    try {

        let comparisonResultsMaxLength = [];
        const d = map(comparisionResults, (symbolList) =>{
            comparisonResultsMaxLength.push(symbolList.ComparisionResults.ComparisionResult.length);
            return map(symbolList.ComparisionResults.ComparisionResult, (j) => {
            if (j.Close !== undefined) return j.Close;
            else return 0;
        })});

        comparisonResultsMaxLength = Math.max(...comparisonResultsMaxLength);
        let comparisonData = [];

        for (let i = 0; i < comparisonResultsMaxLength; i++) {
            let value = 0;
            for (let j = 0; j < d.length; j++) {
                value = value + (d[j][i] != undefined ? d[j][i] : 0);
            }
            comparisonData.push((value/d.length));
        }
      //  return [...d].reduce((r, a) => r.map((b, i) => (a[i] + b) / d.length));
      return comparisonData;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getAverageOfSymbol ${error}`);
    }

}

function* updatePercentage(percentage) {
    try {
        yield put({
            type: ActionTypes.UPDATE_PROGRESS_BAR_PERCENTAGE,
            per: percentage * 100
        });

        if (Math.round(percentage * 100) == 100) {
            yield put({
                type: ActionTypes.DISPLAY_PROGRESS_BAR,
                isShowProgressBar: false
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, updatePercentage ${error}`);
    }
}

function* getMinLengthOfSymbols(comparisionResults) {
    try {
        let min = Number.MAX_SAFE_INTEGER;
        comparisionResults.forEach((symbol) => {
            if (symbol && symbol.ComparisionResults && symbol.ComparisionResults.ComparisionResult) {
                if (min > symbol.ComparisionResults.ComparisionResult.length) {
                    min = symbol.ComparisionResults.ComparisionResult.length;
                }
            }
            else {
                min = 0;
            }
        })
        return min;
    }
    catch (e) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: e
        })
        console.log(`Error occurs in chartViewModel.js, getMinLengthOfSymbols${e}`);
        return 0;
    }
}

function* setIndexLines(comparisionResults, priceIndexLine, isSavedData, numOfChartNodes, totalTime) {
    let LinesCollection = yield select(getCompChartListItem);
    const { per } = yield select(getCompareChart);
    try {
        let lineDataModel = priceIndexLine; //LinesCollection.find((itm) => itm.Osid === priceIndexLine.Osid);
        lineDataModel.dataPoints.length = 0;
        let States = yield select(getCompareChartViewModel);
        const [allAvgValues, minLength] = yield all([call(getAverageOfSymbol, comparisionResults), call(getMinLengthOfSymbols, comparisionResults)]);
        const firstNode = allAvgValues[minLength - 1];
        for (let i = 0; i < States.numOfChartNodes; i++) {
            if (allAvgValues.length <= 0) {
                lineDataModel.updatePercValues();
                break;
            }
            if (i >= minLength) break;
            const closeResult = ((allAvgValues[i] / firstNode) - 1) * 100;
            let dataPoint = new DataPoint();
            if (i == (States.numOfChartNodes) - 1) {
                dataPoint.Value = States.scaleBasedValue
                //datapoint.Date = 
                dataPoint.IsVisible = true;
                lineDataModel.dataPoints = [...lineDataModel.dataPoints, dataPoint];
                if (lineDataModel.maxValue < States.scaleBasedValue) {
                    lineDataModel.maxValue = States.scaleBasedValue;
                }
                if (lineDataModel.minValue > States.scaleBasedValue) {
                    lineDataModel.minValue = States.scaleBasedValue;
                }
                continue;
            }
            let datapointValue = States.scaleBasedValue;
            if (Number.isFinite(closeResult))
                datapointValue += closeResult;
            if (lineDataModel.maxValue < datapointValue) {
                lineDataModel.maxValue = datapointValue;
            }
            if (lineDataModel.minValue > datapointValue) {
                lineDataModel.minValue = datapointValue;
            }
            dataPoint.Value = datapointValue;
            //datapoint.Date = TimeLineList[i + States.StartChartIndex].Key.Date,
            dataPoint.IsVisible = true;
            lineDataModel.dataPoints = [...lineDataModel.dataPoints, dataPoint];
            if (i == 0) {
                lineDataModel.updatePercValues(States.scaleBasedValue);
            }
        }
        if (minLength === 0) {
            lineDataModel.updatePercValues();
        }
        const percentage = per && per !== 0 ? (per / 100) + (numOfChartNodes / totalTime) : (numOfChartNodes / totalTime);
        yield call(updatePercentage, percentage);

        if (!isSavedData) {
            yield fork(addIndexLineForSave, lineDataModel);
        }

        yield put({
            type:ActionTypes.ADD_LIST_ITEM,
            listItem:lineDataModel
        })

     //   yield call(updateLineData, lineDataModel);
       // if (!isSavedData) {
           LinesCollection = yield select(getCompChartListItem);
           lineDataModel = LinesCollection.find((itm) => itm.Osid === priceIndexLine.Osid);
           yield call(processData);
      //  }
        yield put({ type: ActionTypes.SAVE_SETTINGS });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setIndexLines ${error}`);
    }
}

function* addIndexLineForSave(lineDataModel) {
    try {
        const { Osid, index, isVisible, isHighlighted, name, label } = lineDataModel;
        const states = yield select(getCompareChartViewModel);
        const symbolRequestData = [...states.symbolRequest];
        const indexSymbolRequest = symbolRequestData.map((s) => {
            const newSymbol = { Osid: s.Osid, SymTypeEnum: s.SymTypeEnum };
            return newSymbol;
        });
        const symbol = { Osid: Osid, label: label, index: index, isVisible: isVisible, isHighlighted: isHighlighted, name: name, indexSymbolRequest: indexSymbolRequest };
        const indexRequestData = [...states.indexRequest, symbol];
        yield put({
            type: ActionTypes.UPDATE_INDEX_REQ,
            indexRequestData
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, addIndexLineForSave ${error}`);
    }
}

function* calculateLineDataPoint(symbolDataArray, nodeContainerWidth, scale, skipLinePointsCalcFunc) {
    let symbolDataUpdatedArray = [];
    try {
        console.log("PRINT:--Tracker -- calculateLineDataPoint");
        for (let index = 0; index < symbolDataArray.length; index++) {
            let symbolData = symbolDataArray[index];
            let closeResults = symbolData.CloseResults ? symbolData.CloseResults.ComparisionResults.ComparisionResult : null;
            if (closeResults != null) {
                symbolData.dataPoints.length = 0;
                const States = yield select(getCompareChartViewModel);
                let drawIndex = States.numOfChartNodes;
                let lineSource = [];
                if (Array.isArray(closeResults)) {
                    for (let i = 0; i < States.numOfChartNodes; i++) {
                        if (i >= closeResults.length) break; // + autoNodes) break;
                        const closeResult = closeResults[i];
                        let dataPoint = new DataPoint();
                        if (i == (States.numOfChartNodes) - 1) {
                            dataPoint.Value = States.scaleBasedValue
                            //datapoint.Date = 
                            dataPoint.IsVisible = true;
                            symbolData.dataPoints = [...symbolData.dataPoints, dataPoint];
                            if (skipLinePointsCalcFunc) {
                                const x = drawIndex * nodeContainerWidth;
                                if (dataPoint != null) {
                                    const y = dataPoint.Value !== undefined && dataPoint.Value !== null ? scale.ComputeY(dataPoint.Value) : lineSource[(drawIndex - 1) * nodeContainerWidth];
                                    if (Number.isFinite(y)) {
                                        lineSource = [...lineSource, [x, y]];
                                    }
                                    else {
                                        if (lineSource.length > 0) lineSource = [...lineSource, [x, lineSource[i - 1][1]]];
                                    }
                                    drawIndex--;
                                }
                            }



                            if (symbolData.maxValue < States.scaleBasedValue) {
                                symbolData.maxValue = States.scaleBasedValue;
                            }
                            if (symbolData.minValue > States.scaleBasedValue) {
                                symbolData.minValue = States.scaleBasedValue;
                            }
                            continue;
                        }
                        let datapointValue = States.scaleBasedValue;
                        if (closeResult && closeResult.Value)
                            datapointValue += closeResult.Value;
                        if (symbolData.maxValue < datapointValue) {
                            symbolData.maxValue = datapointValue;
                        }
                        if (symbolData.minValue > datapointValue) {
                            symbolData.minValue = datapointValue;
                        }
                        dataPoint.Value = datapointValue;
                        //datapoint.Date = TimeLineList[i + States.StartChartIndex].Key.Date,
                        dataPoint.IsVisible = true;
                        symbolData.dataPoints = [...symbolData.dataPoints, dataPoint];

                        if (i == 0) {
                            symbolData.updatePercValues(States.scaleBasedValue);
                        }
                        if (skipLinePointsCalcFunc) {
                            const x = drawIndex * nodeContainerWidth;
                            if (dataPoint != null) {
                                const y = dataPoint.Value !== undefined && dataPoint.Value !== null ? scale.ComputeY(dataPoint.Value) : lineSource[(drawIndex - 1) * nodeContainerWidth];
                                if (Number.isFinite(y)) {
                                    lineSource = [...lineSource, [x, y]];
                                }
                                else {
                                    if (lineSource.length > 0) lineSource = [...lineSource, [x, lineSource[i - 1][1]]];
                                }
                                drawIndex--;
                            }

                            if (lineSource.length <= 0) {
                                return;
                            }


                        }
                    }
                    if (skipLinePointsCalcFunc) {
                        const newLineDataModel = Object.assign(new SymbolDataModel(), { ...symbolData, lineSource: lineSource, labelPos: { x: lineSource[0][0], y: lineSource[0][1] } });
                        symbolDataUpdatedArray.push(newLineDataModel);

                    } else {
                        symbolDataUpdatedArray.push(symbolData);
                    }

                }
            } else {
                symbolDataUpdatedArray.push(symbolData);
            }
        }
        console.log("PRINT:--Tracker -- calculateLineDataPoint complete");
            return symbolDataUpdatedArray;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, calculateLineDataPoint ${error}`);
    }

}

function* updateLineData(lineData, filterData = true) {

    const LinesCollection = yield select(getCompChartListItem);

    if(!Array.isArray(lineData)){
        lineData = [lineData];
    }
  
    if(filterData){
        LinesCollection.map((item) => {
            for(let i=0;i<lineData.length;i++){
                if(item.Osid === lineData[i].Osid){
                    extend(item,lineData[i]);
                }
            }
            
        });
    }
       
    yield put({
        type: ActionTypes.COMP_UPDATE_LINE,
        lineData: filterData ?  LinesCollection : lineData
    });
}

async function getSymbolClosingResultApiData(symbols, periodicity, endDate, startDate, isMultiple = false) {
    const result = isMultiple ? await compareApi.getComparisionDataforSymbols(symbols, periodicity, endDate, startDate) :
        compareApi.getComparisionChartData(symbols, periodicity, endDate, startDate);
    return result;
}

function* getEndDateWithISOFormat(inJSStyle = false) {
    try {
        let endDate = yield select(getEndDate);
        if (!endDate) endDate = moment();

        if (isMoment(endDate)) {
            endDate.set({
                hour: 0,
                minutes: 0,
                second: 0
            });
            return inJSStyle ? endDate.toDate() : endDate.format("YYYY-MM-DDTHH:mm:ss");
        }
        else if (endDate) {
            endDate = moment(endDate, "MM-DD-YYYY", true);
            endDate.set({
                hour: 0,
                minutes: 0,
                second: 0
            });
            return inJSStyle ? endDate.toDate() : endDate.format("YYYY-MM-DDTHH:mm:ss");
        }
        return inJSStyle ? new Date() : (new Date).toISOString();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getEndDateWithISOFormat ${error}`);
    }

}

function* getStartDateWithISOFormat(inJSStyle = false) {
    try {
        let startDate = yield select(getStartDate);
        if (!startDate) startDate = moment();

        if (isMoment(startDate)) {
            startDate.set({
                hour: 0,
                minutes: 0,
                second: 0
            });

            return inJSStyle ? startDate.toDate() : startDate.format("YYYY-MM-DDTHH:mm:ss");
        }
        else if (startDate) {
            startDate = moment(startDate, "MM-DD-YYYY", true);
            startDate.set({
                hour: 0,
                minutes: 0,
                second: 0
            });
            return inJSStyle ? startDate.toDate() : startDate.format("YYYY-MM-DDTHH:mm:ss");
        }
        return inJSStyle ? new Date() : (new Date).toISOString();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getStartDateWithISOFormat ${error}`);
    }

}

function* getSymbolClosingResults(symbols) {
    try {
        const periodicity = yield select(getPeriodicity), endDate = yield call(getEndDateWithISOFormat), startDate = yield call(getStartDateWithISOFormat);
        if (endDate == null || startDate == null) return null;
        const ComparisonRequestData = [...symbols].map((symbol) => ({ ...ComparisonRequest, Osid: symbol.Osid, SymTypeEnum: symbol.SymTypeEnum }));
        const results = yield call(getSymbolClosingResultApiData, ComparisonRequestData, periodicity, endDate, startDate, true);
        if (Array.isArray(results))
            return results;
        else {
            return [results];
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getSymbolClosingResults ${error}`);
    }

}

function* getSymbolClosingResult(symbols) {
    try {
        const periodicity = yield select(getPeriodicity), endDate = yield call(getEndDateWithISOFormat), startDate = yield call(getStartDateWithISOFormat);
        if (endDate == null || startDate == null) return null;
        const ComparisonRequestData = { ...ComparisonRequest, Osid: symbols.Osid, SymTypeEnum: symbols.SymTypeEnum };
        const results = yield call(getSymbolClosingResultApiData, ComparisonRequestData, periodicity, endDate, startDate, false);
        return results;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getSymbolClosingResult ${error}`);
    }

}

async function processStockInfoDates(stockInfo) {
    //let pacificOffset = DateHelper.getPacificOffset(DateHelper.getTicks(stockInfo.LastTradeDate));
    stockInfo.LastTradeDate = DateHelper.getUtcTicksfromTimeStamp(stockInfo.LastTradeDate);//, pacificOffset);
    stockInfo.ExchangeOpenTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.ExchangeOpenTime);//, pacificOffset);
    stockInfo.ExchangeCloseTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.ExchangeCloseTime);//, pacificOffset);
    stockInfo.CreateTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.CreateTime);//, pacificOffset);
    stockInfo.ScrollToDate = DateHelper.getUtcTicksfromTimeStamp(stockInfo.ScrollToDate);//, pacificOffset);
    stockInfo.IPODate = DateHelper.getUtcTicksfromTimeStamp(stockInfo.IPODate);//, pacificOffset);
    stockInfo.LastUpdateTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.LastUpdateTime);//, pacificOffset);
    stockInfo.Earnrptdate = DateHelper.getUtcTicksfromTimeStamp(stockInfo.Earnrptdate);//, pacificOffset);
    stockInfo.ExchangeTime = DateHelper.getUtcTicksfromTimeStamp(stockInfo.ExchangeTime);//, pacificOffset);
    stockInfo.LastCompletedTradeDay = DateHelper.getUtcTicksfromTimeStamp(stockInfo.LastCompletedTradeDay);//, pacificOffset);
}

export async function getStockInfo(symbol) {
    let result = await chartApi.findStockInfo(symbol);
    /* eslint-disable */
    const t = await processStockInfoDates(result);
    /* eslint-enable */
    return result;
}

function* setLatestTradingDate() {
    try {
        const results = yield call(compareApi.getLatestTradingDate);
        yield put({
            type: ActionTypes.SET_LATEST_TRADING_DATE,
            results
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setLatestTradingDate ${error}`);
    }

}


function* getMaxMinValue() {
    try {
        const LinesCollection = yield select(getCompChartListItem);
        const visibleSymbolLines = LinesCollection.filter((d) => d.trueVisibilty);
        const haveSymbols = visibleSymbolLines.length > 0;
        const scaleType = yield select(getScaleType);
        let maxValue = Number.MAX_VALUE;
        let minValue = -Number.MAX_VALUE;
        if (haveSymbols) {
            maxValue = max(visibleSymbolLines, (c) => c.maxValue).maxValue;
            minValue = min(visibleSymbolLines, (c) => c.minValue).minValue;
        }

        let isRedraw = true;
        if (Math.abs(maxValue - Number.MAX_VALUE) > Number.EPSILON && Math.abs(minValue - (-Number.MAX_VALUE)) > Number.EPSILON) {
            if (scaleType === "LogAuto") {
                minValue = minValue <= 0 ? 1 : minValue;
                maxValue = maxValue <= 0 ? 100 : maxValue;
            }
            if (minValue === maxValue) {
                maxValue = (scaleType === "Lin") ? minValue + 100 : 100;
                minValue = (scaleType === "Lin") ? minValue : 1;
            }

        }
        else {
            maxValue = (scaleType === "Lin") ? 200 : 100;
            minValue = (scaleType === "Lin") ? 100 : 1;
            isRedraw = false;
        }

        return {
            maxMinValue: { maxValue, minValue }, isRedraw
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getMaxMinValue ${error}`);
    }

}

function* setChartMaxMinValue(ignoreCalculation,maxValue,minValue) {
    try {

        if (!ignoreCalculation) {
            const { maxMinValue } = yield call(getMaxMinValue);
            maxValue = maxMinValue.maxValue;
            minValue = maxMinValue.minValue
        }

        yield put({
            type: ActionTypes.SET_MAX_MIN_CHART_VALUE,
            maxMinValue: { maxValue, minValue }
        }
        );
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setChartMaxMinValue ${error}`);
    }

}

function* setVerticalLines(timeLineData) {
    if (!timeLineData || timeLineData.length === 0) {
        return;
    }

    try {
        const states = yield select(getCompareChartViewModel);
        const chartWidth = yield select(getChartWidth);
        const max = timeLineData.length;
        const nodeContainerWidth = yield select(getNodeWidth);
        let verticalGridData = [];
        const StartingIndex = 0;
        let drawIndex = states.numOfChartNodes;
        let index = StartingIndex;
        for (let itemIndex = StartingIndex; itemIndex < max; itemIndex++) {
            if (index >= (states.numOfChartNodes + StartingIndex) || index >= max || drawIndex <= 0) break;
            let itemDataContext = timeLineData[itemIndex];
            if (itemDataContext && (itemDataContext.XLine > 0 && (itemDataContext.IsCalendarLine || itemDataContext.IsCalendarLabel))) {
                let x = drawIndex * nodeContainerWidth + nodeContainerWidth;
                if (x < chartWidth) {
                    if (itemDataContext.XLine == 1 || itemDataContext.XLine == 3 || itemDataContext.XLine == 4) {
                        verticalGridData = [...verticalGridData, {
                            xAxis: x,
                            timelineItem: itemDataContext,
                            isDash: true
                        }];
                    }
                    else if (itemDataContext.XLine == 5) {
                        verticalGridData = [...verticalGridData, {
                            xAxis: x,
                            timelineItem: itemDataContext,
                            isDash: false,
                            is18Month: true
                        }];
                    }
                    else {
                        verticalGridData = [...verticalGridData, {
                            xAxis: x,
                            timelineItem: itemDataContext,
                            isDash: false
                        }];

                    }
                }
            }

            index++;
            drawIndex--;
        }
        yield put({
            type: ActionTypes.SET_VERTICAL_LINES,
            verticalGridData
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setVerticalLines ${error}`);
    }

}

function* setTimeLineCalenderLabels(timeLineData) {
    try {
        const States = yield select(getCompareChartViewModel);
        const nodeContainerWidth = yield select(getNodeWidth);
        let calenderLabels = [];
        let timeLineCount = timeLineData.length;
        let startingIndex = 0;
        let drawIndex = States.numOfChartNodes;
        for (let index = startingIndex; index < timeLineCount; index++) {
            const label = timeLineData[index];
            const x = drawIndex * nodeContainerWidth + nodeContainerWidth;
            if (index >= (States.numOfChartNodes + startingIndex) || index >= timeLineCount || drawIndex <= 0) break;

            if (label != null && label.XLabel && label.XLabel !== "" && (label.IsCalendarLabel || label.IsMonthLabel)) {
                const xlabels = { Label: label.XLabel.toString(), Axis: [x, 16], IsQuarterLabel: label.IsCalendarLabel, timeLineDate: label.Date };
                calenderLabels = [...calenderLabels, xlabels];
            }
            drawIndex--;
        }

        yield put({
            type: ActionTypes.SET_CALENDAR_LABELS,
            calenderLabels
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setTimeLineCalenderLabels ${error}`);
    }

}

function* populateTimeLine() {
    try {
        const { timeLineData } = yield select(getCompareChartViewModel);
        if (timeLineData && timeLineData.length > 0) {
            yield fork(setTimeLineCalenderLabels, timeLineData);
            yield fork(setVerticalLines, timeLineData);
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, populateTimeLine ${error}`);
    }

}

function* getStartDateOfTimeLine(startDate, graphType) {
    try {
        let sDate = moment(startDate);
        switch (graphType) {
            case GraphTypeEnum.NotAvailable:
                break;
            case GraphTypeEnum.Daily:
                sDate.subtract(14, 'd');
                break;
            case GraphTypeEnum.Weekly:
                sDate.subtract(28, 'd');
                break;
            case GraphTypeEnum.Monthly:
                sDate.subtract(2, 'M');
                break;
            case GraphTypeEnum.Quarterly:
                sDate.subtract(2, 'y');
                break;
            case GraphTypeEnum.Annual:
                sDate.subtract(2, 'y');
                break;
            default:
                break;
        }
        return sDate.toDate();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getStartDateOfTimeLine ${error}`);
    }

}

function* setTimeLineData() {
    try {
        const periodicity = yield select(getPeriodicity), endDate = yield call(getEndDateWithISOFormat, true), startDate = yield call(getStartDateWithISOFormat, true), fcQuarters = 0;
        const startDateBaseOnperiodicity = yield call(getStartDateOfTimeLine, startDate, periodicity);
        let timeLineData = TimeLineHelper.GetTimeLineData(startDateBaseOnperiodicity, endDate, periodicity, fcQuarters, stockInfo, false, true);
        if (timeLineData)
            timeLineData = timeLineData.dates.filter((itm) => itm);
        if (timeLineData && timeLineData.length > 0) {
            yield put({
                type: ActionTypes.SET_TIME_LINE_DATA,
                timeLineData
            });
            return true;
        }
        return true;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setTimeLineData ${error}`);
    }

}

function* updateNodeWidth() {
    try {
        yield put({
            type: ActionTypes.UPDATE_NODE_WIDTH,
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, updateNodeWidth ${error}`);
    }
}

export function* getCompareChartScaleDataAndSetScale(msids){
    try{
    const states = yield select(getCompareChartViewModel);
    const periodicity = yield select(getPeriodicity);
    const startDate = yield select(getStartDate);
    const endDate = yield select(getEndDate);
    const startDateFormatted = startDate.split("-");
    const endDateFormatted = endDate.split("-");
    let endate = endDateFormatted[2].toString().concat(endDateFormatted[0].toString()).concat(endDateFormatted[1].toString());
    let stdate = startDateFormatted[2].toString().concat(startDateFormatted[0].toString()).concat(startDateFormatted[1].toString());
    const request = CompareReqInfo.getCompareScaleRequestData(msids,periodicity,stdate,endate);
    const result = yield compareApi.getCompareChartMaxMinData(request);

    const maxValue = states.scaleBasedValue + result.max.low ;
    const minValue = states.scaleBasedValue + result.min.low ; 

    yield call(setChartSettingsForLineProcessing,true,maxValue,minValue);
     } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, getCompareChartScaleDataAndSetScale ${error}`);
    }
}

function* updateDate(startDate, endDate) {
    try {
        let EndDate = yield select(getEndDate);
        if (!EndDate) EndDate = moment();
        let StartDate = yield select(getStartDate);
        if (!StartDate) StartDate = moment();

        if (moment.isMoment(endDate) && moment.isMoment(EndDate) && endDate.format("MM-DD-YYYY") != EndDate.format("MM-DD-YYYY")) {
            yield put({
                type: ActionTypes.UPDATE_USER_END_DATE,
                userEndDate: endDate
            });
        }
        else if (moment.isMoment(startDate) && moment.isMoment(StartDate) && startDate.format("MM-DD-YYYY") != StartDate.format("MM-DD-YYYY")) {
            yield put({
                type: ActionTypes.UPDATE_USER_START_DATE,
                userStartDate: startDate
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, updateDate ${error}`);
    }

}

function* setNumOfChartNodes() {
    try {
        const McSymbol = "0DJIA";
        stockInfo = yield call(getStockInfo, McSymbol);
        if (!stockInfo) return;
        const ComparisonRequestData = { ...ComparisonRequest, Osid: stockInfo.Osid, SymTypeEnum: stockInfo.SymTypeEnum }
        const closeResults = yield call(getSymbolClosingResult, ComparisonRequestData);
        const numOfChartNodes = closeResults ? closeResults.ComparisionResults.length : 0;
        //const pacificOffset = DateHelper.getPacificOffset(DateHelper.getTicks(closeResults.ComparisionResults[0].Date));
        const endDate = moment(DateHelper.getUtcTicksfromTimeStamp(closeResults.ComparisionResults[0].Date));//, pacificOffset));
        const startDate = moment(DateHelper.getUtcTicksfromTimeStamp(closeResults.ComparisionResults[numOfChartNodes - 1].Date));//, pacificOffset));
        yield call(updateDate, startDate, endDate);
        yield put({
            type: ActionTypes.UPDATE_NUM_CHART_NODES,
            numOfChartNodes
        })
        yield call(updateNodeWidth);
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setNumOfChartNodes ${error}`);
    }
}

function* initializedScale() {
    try {
        const scale = yield select(getScale);
        const state = yield select(getCompareChartViewModel);
        yield scale.InitializedScale(state.priceScaleInfo);
        yield put({
            type: ActionTypes.SET_MH_LINES,
            mhLines: scale.MHLines
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, initializedScale ${error}`);
    }

}

function* setScaleInfo() {
    try {
        const minValue = yield select(getChartMinPrice);
        const maxValue = yield select(getChartMaxPrice);
        const chartHeight = yield select(getChartHeight)
        const priceScaleInfo = {
            VpHeight: chartHeight,
            BoxHeight: chartHeight,
            MaxPrice: maxValue,
            MMaxPrice: maxValue,
            MinPrice: minValue,
            MMinPrice: minValue

        }
        yield put({
            type: ActionTypes.UPDATE_PRICE_SCALEINFO,
            priceScaleInfo
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setScaleInfo ${error}`);
    }

}

function* resetPriceScaleInfo() {
    try {
        const periodicity = yield select(getPeriodicity);
        const endDate = yield select(getEndDate);
        const nodeWidth = yield select(getNodeWidth);
        const priceScaleInfo = {
            EpsMultiplier: 20,
            RpsMultiplier: 5,
            TradeDate: endDate,
            GraphType: periodicity,
            MinPrice: 0,
            MaxPrice: 0,
            MMinPrice: 0,
            MMaxPrice: 0,
            DPI: 28 * nodeWidth,
        }
        yield put({
            type: ActionTypes.UPDATE_PRICE_SCALEINFO,
            priceScaleInfo
        });
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, resetPriceScaleInfo ${error}`);
    }

}

function* reSetOnDimensionChange({ dimension }) {
    try {
        yield put({
            type: ActionTypes.ON_DIMENSION_CHANGE_SUCSESS,
            dimension
        });
        if (isInit) {
            yield all([call(populateTimeLine), call(processData)]);
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, reSetOnDimensionChange ${error}`);
    }

}


function* execScaleChanged() {
    try {
        const states = yield select(getCompareChartViewModel);
        const scaleType = states.scaleType === ScaleType.Lin ? ScaleType.LogAuto : ScaleType.Lin;
        yield fork(changeScaleTypeText, scaleType);
        if (scaleType == ScaleType.LogAuto) {
            if (Math.abs(states.scaleBasedValue - 0) < Number.EPSILON) {
                const scaleBasedValue = 100;
                yield call(changeScaleBaseText, scaleBasedValue);
            }
        }
        yield put({
            type: ActionTypes.CHART_RE_DRAW
        });
        yield put({ type: ActionTypes.SAVE_SETTINGS });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, execScaleChanged ${error}`);
    }

}

function* execScaleBaseChanged() {
    try {
        let states = yield select(getCompareChartViewModel);
        let scaleBasedValue = Math.abs(states.scaleBasedValue - 100) < Number.EPSILON ? 0 : 100;
        yield call(changeScaleBaseText, scaleBasedValue);
        if (Math.abs(scaleBasedValue - 0) < Number.EPSILON) {
            if (states.scaleType === ScaleType.LogAuto) {
                yield fork(execScaleChanged);
                return;
            }
        }

        states = yield select(getCompareChartViewModel);
        const maxValue = states.scaleBasedValue === 0 ? states.chartMaxPrice - 100 : states.chartMaxPrice + 100;
        const minValue = states.scaleBasedValue === 0 ? states.chartMinPrice - 100 : states.chartMinPrice + 100;

        yield call(setChartSettingsForLineProcessing, true, maxValue, minValue)


        yield put({
            type: ActionTypes.CHART_RE_DRAW,
            calculateDataPoint: true
        });

        yield put({ type: ActionTypes.SAVE_SETTINGS });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, execScaleBaseChanged ${error}`);
    }

}


function* changeScaleTypeText(scaleType) {
    try {
        yield put({
            type: ActionTypes.CHANGE_SCALE_TYPE_VALUE_SUCCESS,
            scaleType: scaleType
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, changeScaleTypeText ${error}`);
    }
}

function* changeScaleBaseText(scaleBasedValue) {
    try {
        yield put({
            type: ActionTypes.CHANGE_SCALE_BASE_VALUE_SUCCESS,
            scaleBasedValue
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, changeScaleBaseText ${error}`);
    }
}

export function* addSymbolRequest(Osid, msid, SymTypeEnum, index) {
    try {
        const symbol = { Osid: Osid, SymTypeEnum: SymTypeEnum, msid:msid, index: index, isVisible: true, isHighlighted: true };
        const states = yield select(getCompareChartViewModel);
        const symbolRequest = [...states.symbolRequest, symbol];
        yield put({
            type: ActionTypes.UPDATE_SYMBOL_REQ,
            symbolRequest
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, addSymbolRequest ${error}`);
    }
}

function* addLine({ request }) {
    const states = yield select(getCompareChartViewModel);
    let newLineArray = [];
    try {
        let symbolRequest = [];
        if (!Array.isArray(request)) {
            symbolRequest.push(request);
        } else {
            symbolRequest = request;
        }


        let LinesCollection;

        for (let i = 0; i < symbolRequest.length; i++) {
            LinesCollection = yield select(getCompChartListItem);
            const index = LinesCollection.length;
            const { Symbol, Osid, CoName, LocalSymbol, SymTypeEnum } = symbolRequest[i].result;
            const {MsId} = symbolRequest[i].apiResponse;
            let newLine = new SymbolDataModel();
            newLine.label = UserInfoUtil.hasLocalSymbol() ? LocalSymbol : Symbol;
            newLine.index = index;
            newLine.isVisible = true;
            newLine.id = Osid.toString();
            newLine.Osid = Osid;
            newLine.msid = MsId.toString();
            newLine.name = CoName;
            newLine.localSymbol = LocalSymbol === "" ? Symbol : LocalSymbol;
            newLine.actualSymbol = Symbol;
            newLine.SymTypeEnum = getSymbolTypeEnumStringValue(SymTypeEnum);
            newLine.isFilter = true;
            newLine.reset();
            /* prepare array of newLine and then call yield fork and put */
            yield fork(addSymbolRequest, newLine.Osid, newLine.msid, newLine.SymTypeEnum, index);
            newLineArray.push(newLine);
            states.msids.push(newLine.msid);
            // yield put({
            //     type: ActionTypes.ADD_LIST_ITEM,
            //     listItem: newLine
            // });
        }

        yield put({
            type:ActionTypes.SET_MSIDS,
            msids:states.msids
        })

        yield call(calculateDataPointsForSymbols, newLineArray, false,true);

        yield call(handleLoading, false);

        let menuTab = yield select(getSelectedTabActiveMenuName);
        if (menuTab == CompareMenuType.Symbols) {
            yield put({
                type: ActionTypes.SHOW_LISTALERT_MSG,
                showAddListAlertMessage: true
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, addLine ${error}`);
    }
}

function* setProgressBar() {
    try {
        yield put({
            type: ActionTypes.UPDATE_PROGRESS_BAR_PERCENTAGE,
            per: 0
        });
        yield delay(10000);
        yield put({
            type: ActionTypes.DISPLAY_PROGRESS_BAR,
            isShowProgressBar: true
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setProgressBar ${error}`);
    }
}

function* handleIndexLines({ isInit = true }) {
    try {
        timeOutFunc = yield fork(setProgressBar);
        const states = yield select(getCompareChartViewModel);
        const LinesCollection = yield select(getCompChartListItem);
        const index = LinesCollection.length;

        const numOfChartNodes = states.numOfChartNodes;
        const totalTime = numOfChartNodes * 1;

        yield call(addindexLines, states.symbolRequest, index, isInit, false, null, numOfChartNodes, totalTime)
        if (timeOutFunc) {
            yield put({
                type: ActionTypes.DISPLAY_PROGRESS_BAR,
                isShowProgressBar: false
            });
            yield cancel(timeOutFunc);
            timeOutFunc = null;
            yield put({
                type: ActionTypes.UPDATE_PROGRESS_BAR_PERCENTAGE,
                per: 0
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, handleIndexLines ${error}`);
    }

}

function* handleAllSavedIndexLines(isInit = true) {
    try {
        timeOutFunc = yield fork(setProgressBar);
        const states = yield select(getCompareChartViewModel);
        if (states.indexRequest && states.indexRequest.length > 0) {
            const numOfChartNodes = states.numOfChartNodes;
            const totalTime = numOfChartNodes * states.indexRequest.length;
            for (const indexLine of states.indexRequest) {
                if (indexLine && indexLine.label) {
                    yield call(addindexLines, indexLine.indexSymbolRequest, indexLine.index, isInit, true, indexLine, numOfChartNodes, totalTime);
                }
            }
        }
        if (timeOutFunc) {
            yield put({
                type: ActionTypes.DISPLAY_PROGRESS_BAR,
                isShowProgressBar: false
            });
            yield cancel(timeOutFunc);
            timeOutFunc = null;
            yield put({
                type: ActionTypes.UPDATE_PROGRESS_BAR_PERCENTAGE,
                per: 0
            });
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, handleAllSavedIndexLines ${error}`);
    }

}

function* setValueFromSettings() {
    let menuTab = yield select(getMenuTab);
    try {
        const consoleSettings = yield call(SettingsStore.getConsoleSettings);
        let settingsData = menuTab.ComparisonMenuTab == CompareMenuType.Symbols ?
            consoleSettings.NavCompareSettings.TabComparisonGraphSettings.comparisonSymbolsMenuSettings
            : consoleSettings.NavCompareSettings.TabComparisonGraphSettings.comparisonGroupsMenuSettings;

        yield put({
            type: ActionTypes.INIT_FROM_SETTINGS_DATA,
            settingsData: settingsData
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, setValueFromSettings ${error}`);
    }

}

function* handleRefresh() {
    const isComparisonTabSelected = yield select(getIsComparisonTabSelected);

    if (isComparisonTabSelected)
        yield put(initCompChart(true));
}

function* redrawProcess({ calculateDataPoint = false }) {

    if (calculateDataPoint) {
        yield call(calculateDataPointsAsync);
        yield call(handleAllSavedIndexLines);
    }
    else
        yield call(processData);
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchInitCompChart() {
    yield takeLatest(ActionTypes.INIT_COMP_CHART, initializeChart);
}

export function* watchOnDimensionChange() {
    yield takeLatest(ActionTypes.ON_DIMENSION_CHANGE, reSetOnDimensionChange);
}

export function* watchScaleTypeChange() {
    yield takeLatest(ActionTypes.CHANGE_SCALE_TYPE_VALUE, execScaleChanged);
}

export function* watchScaleBaseChange() {
    yield takeLatest(ActionTypes.CHANGE_SCALE_BASE_VALUE, execScaleBaseChanged);
}

export function* watchHandleRefresh() {
    yield takeLatest(ActionTypes.HANDLE_REFRESH, handleRefresh);
}

export function* watchHandleAddToList() {
    yield takeLatest(ActionTypes.ADD_TO_LIST, addLine);
}

export function* watchAddIndexLine() {
    yield takeLatest(ActionTypes.ADD_INDEX_LINE, handleIndexLines);
}

export function* watchProcessDataAllSymbol() {
    yield takeLatest(ActionTypes.PROCESS_ALL_DATA, processData);
}

export function* watchRedrawChart() {
    yield takeLatest(ActionTypes.CHART_RE_DRAW, redrawProcess);
}