import moment from "moment";
import Long from "long";
import { isString, isObject, isEmpty } from "underscore";
import FormatterUtil from "FormatterUtil";
import textWidth from "text-width";
import GraphType from "../Constants/GraphType";

class StringUtil {

  stringReplace(str) {
    return str.replace(new RegExp(" ", "g"), "").replace(new RegExp("-", "g"), "");
  }
  format(str, obj) {
    return str.replace(/\{\s*([^}\s]+)\s*\}/g, (m, p1) => obj[p1]);
  }
  formatter(str){
    let regexpForm = /\{\s*([^}\s]+)\s*\}(.*)/ig;
    let symbolMatches = regexpForm.exec(str);
    let firstMatch = symbolMatches[1];
    let secondMatch = symbolMatches[2]?symbolMatches[2]:"";
    let decimalPos = firstMatch.split(':');
    let regexDecimal = /(\d)/g;
    let matches = regexDecimal.exec(decimalPos[1]);
    let zeros = "";
    let i=0;
  for(i=0; i < matches[1]; i++){
  	zeros = zeros+"0";
  }
  let formatter = "#,##0."+zeros+secondMatch;
  return formatter;
  }
  getDecimals(str){
    let regexpForm = /\{\s*([^}\s]+)\s*\}(.*)/ig;
    let symbolMatches = regexpForm.exec(str);
    let firstMatch = symbolMatches[1];
    // let secondMatch = symbolMatches[2]?symbolMatches[2]:"";
    let decimalPos = firstMatch.split(':');
    let regexDecimal = /(\d)/g;
    let matches = regexDecimal.exec(decimalPos[1]);
    return  matches[1];
  }
  getunits(str){
    if(str != ''){
      let regexpForm = /\{\s*([^}\s]+)\s*\}(.*)/ig;
      let symbolMatches = regexpForm.exec(str);
      str=  symbolMatches[2]?symbolMatches[2]:'';
    }
    return str;
  }
  getunitsName(unitText){
    let unitName = '';
          if (unitText == "") { unitName = "NoScaling"; }
          else if (unitText == "K") {  unitName = "Thousands"; }
          else if (unitText == "M") { unitName = "Millions"; }
          else if (unitText == "B") { unitName = "Billions"; }
          else if (unitText == "%") { unitName = "Percent"; }
          return unitName;
  }
  stripHTML(str) {
    if (str != null && (typeof (str) == "string")) {
      //str = str.replace(/ /g, "+");
      return encodeURIComponent(str);
    } else {
      return str;
    }
  }

  // Long to Integer
  convertFromLongValueToInt(obj) {
    try {
      if (!isString(obj)) {
        let objData = Long.fromValue(obj);
        if (Long.isLong(objData)) {
          return Number(objData.toString());
        }
        else {
          if (isString(objData)) {
            return Number(objData);
          }
          return Number(objData);
        }
      }
      else {
        return obj;
      }
    } catch (error) { console.log('Date Conversion Error.....', error) }
  }
  // Long to Date
  convertFromLongValueToDate(obj) {
    try {
      if (!isString(obj)) {
        let objData = Long.fromValue(obj);
        if (Long.isLong(objData)) {
          return new Date(Number(objData.toString()));
        }
        else {
          if (isString(objData)) {
            return new Date(objData);
          }
          return new Date(objData);
        }
      }
      else {
        return obj;
      }
    } catch (error) { console.log('Date Conversion Error.....', error) }
  }

  formatDate(longObj, format = "M/D/YYYY h:mm A", localTimeZone = false) {
    if (format == "MM/DD/YYYY HH:MM") {
      format = "M/D/YYYY h:mm A";
    }
    let timeZone = "US/Pacific";
    if (localTimeZone) {
      timeZone = moment.tz.guess();
    }
    const dateObj = moment.tz(this.convertFromLongValueToInt(longObj?longObj:''), timeZone);

    // let dateObj = moment.tz(this.convertFromLongValueToInt(longObj), timeZone);
    //Default date 01/01/0001 check 
    if (dateObj.get('year') < 2) {
      return "";
    }
    format = FormatterUtil.getDateFormatAsLocale(format);
    return dateObj.format(format);
  }

  getLongToMoment(date){
    const longdate = date.toNumber();
    const dateConverted = moment(longdate);
    return dateConverted;
  }
  getDataPointsDate(date,periodicity){
    const longdate = date.toNumber();
    let dateConverted = moment(longdate);
    if(periodicity == GraphType.Monthly){
      dateConverted = dateConverted.endOf('month');
      let day = dateConverted.day();
      if (day == 0) {
        dateConverted = dateConverted.subtract(2, "days");
      }
      else if (day == 6) {
        dateConverted = dateConverted.subtract(1, "days");
      }
    }
    return dateConverted;
  }
  isEmpty(val) {
    if (isObject(val)) {
      return isEmpty(val);
    }
    return (val === undefined || val == null || val.length <= 0 || val === false) ? true : false;
  }

  // find the index for KeyUP and Down selection
  getIndexForKeyUpDown(e, parentElement) {
    if (parentElement) {
      let withActive = [];
      for (let i = 0; i < parentElement.children.length; i++) {
        if (this.hasClass(parentElement.children[i], "active")) {
          withActive.push(i);
        }
      }
      let index = 0;
      switch (e.keyCode) {
        case 38: // if the UP key is pressed
          e.preventDefault();
          if (withActive[0] == 0) {
            index = parentElement.children.length - 1;
          }
          else {
            index = withActive[0] - 1;
          }
          break;
        case 40: // if the DOWN key is pressed
          e.preventDefault();
          if (withActive[withActive.length - 1] == parentElement.children.length - 1) {
            index = 0;
          }
          else {
            index = withActive[withActive.length - 1] + 1;
          }
          break;
        case 13: // if the ENTER key is pressed
          return "submit";
        default: return;
      }
      return index;
    }
  }

  hasClass(el, cls) {
    let regexp = new RegExp("(\\s|^)" + cls + "(\\s|$)"),
      target = (typeof el.className === "undefined") ? window.event.srcElement : el;
    return target.className.match(regexp);
  }

  ellipsisFill(e, html) {
    let x = function (a, i) {
      return a.slice(0, i).join(' ');
    };

    let d = '...',
      h = e.offsetHeight,
      w = html.split(' '),
      i = 0,
      l = w.length;

    if (w.length > 1) {
      e.innerHTML = '';
      while (h + 1 >= e.scrollHeight && i <= l) {
        e.innerHTML = x(w, ++i) + d;
      }

      if (i > l) { e.innerHTML = x(w, i); }
      else { e.innerHTML = x(w, --i) + d; }
    }
  };

  ellipsisContentDiv(e, html) {
    let d = '...',
      maxWidth = e.offsetWidth * 6;
    let htm = html.replace(/\s+/g, " ");
    let dwidth = this.getTextWidth(d, "calibri", 13);
    let width = 0;
    for (let index = 0; index < htm.length; index++) {
      let codePoint = htm[index];
      width += this.getTextWidth(codePoint, "calibri", 13);
      if ((width + dwidth) > maxWidth) {
        let innerStr = htm.substring(0, index-1) + d;
        e.innerHTML = innerStr;
        break;
      }
      let rowW = Math.ceil(width % e.offsetWidth);
      let nextStr = htm[index -1];
      let nextWidth = this.getTextWidth(nextStr, "calibri", 13);
      if ((e.offsetWidth - rowW) < nextWidth) {
        width += (e.offsetWidth - rowW);
      }
    }
  };
  ellipsisContentTextArea(html, offsetWidth, textSize) {
    let d = '...',
      maxWidth = offsetWidth * 3;
    let htm = html.replace(/\s+/g, " ");
    let dwidth = this.getTextWidth(d, "calibri", textSize);
    let width = 0;
    let innerStr = htm;
    for (let index = 0; index < htm.length; index++) {
      let codePoint = htm[index];
      width += this.getTextWidth(codePoint, "calibri", textSize);
      if ((width + dwidth) > maxWidth) {
        innerStr = htm.substring(0, index-1) + d;
        break;
      }
      let rowW = Math.ceil(width % offsetWidth);
      let nextStr = htm[index + 1];
      let nextWidth = this.getTextWidth(nextStr, "calibri", textSize);
      if ((offsetWidth - rowW) < nextWidth) {
        width += (offsetWidth - rowW);
      }
    }
    return innerStr;
  };
  getTextWidth(str, family, size) {
    let strWidth = textWidth(str, {
      family: family,
      size: size
    });
    return strWidth;
  }
  trim(str) {
    str = str.replace(/^\s+/, '');
    for (let i = str.length - 1; i >= 0; i--) {
      if (/\S/.test(str.charAt(i))) {
        str = str.substring(0, i + 1);
        break;
      }
    }
    return str;
  }
  stringFormat() {
    if (arguments.length == 0)
      return null;
    let str = arguments[0];
    for (let i = 1; i < arguments.length; i++) {
      let re = new RegExp('\\{' + (i - 1) + '\\}', 'gm');
      str = str.replace(re, arguments[i]);
    }
    return str;
  }

  getGuid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  compare(origin, target) {

    if (origin === target) {
        return true;
    }
    
    if (target == null || typeof target !== "object") {
      if ((typeof origin === 'function' && typeof target === 'function') ||
        (origin instanceof Date && target instanceof Date) ||
        (origin instanceof RegExp && target instanceof RegExp) ||
        (origin instanceof String && target instanceof String) ||
        (origin instanceof Number && target instanceof Number)) {
        return origin.toString() === target.toString();
      }
      else{
        return origin === target;
      }
    }
    if (typeof origin !== "object" || (Object.keys(target).length === 0 && Object.keys(origin).length > 0)) {
      return false;
    }
    for (let key of Object.keys(target)) {
      if (!this.compare(origin[key], target[key])) {
        return false;
      }
    }
    return true;
  }

  capitalizePartOfString(string, start, end){
    return string.slice(start, end).toUpperCase().concat(string.slice(end));
  }

}

const stringUtil = new StringUtil();
export default stringUtil;
