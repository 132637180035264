import React, { Component } from "react";
import LocalizationStore from 'LocalizationStore';

export default class GroupNotificationItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }
    componentWillUnmount() {
    }

    render() {
        let ViewIn = LocalizationStore.getTranslatedData("MC_VIEW_IN", "View in");
        let MsgCenter = LocalizationStore.getTranslatedData("MC_MESSAGE_CENTER", "Message Center");
        let More = LocalizationStore.getTranslatedData("MC_REST_COUNT", "more.");
        return (
            <tr className={'unread group-notify-inner'}>
                <td className="group-notify-inner-child">
                    <span className="medium-normal highlight-stock-details pull-left"> <b>{this.props.message} {More} </b> {ViewIn} </span> 
                    <span className="medium-bold  highlight-stock-name pull-left marl5" onClick={this.props.openMessageCenter}> {MsgCenter} </span>
                </td>
            </tr>
        );
    }
}