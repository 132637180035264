import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import DatagraphDataType from "../../../Constants/DatagraphDataType";
import NavType from "../../../Constants/NavType";
import { PerformanceWindowConstants } from "../../../Constants/PerformanceWindowConstants";
import TimeTrackingWindow from "../../../RayCustomControls/TimeTrackingWindow";
import PeriodicityHelper from "../../../Utils/PeriodicityHelper";
import StringUtil from "../../../Utils/StringUtil";
import DataGraphStore from "./DataGraphStore";
import FormatterUtil from "FormatterUtil";
import StorageUtil from "StorageUtil";

const CHANGE_EVENT = "change";

let _state = {
  shouldUpdateTimeTrack: true,
  average: '',
  isPricePanelLoading: false,
  trackDataList : [],
  trackDataSource : {},
  listManagerTrackDataList : [],
  listManagerTrackDataSource : {},
  listManagerTrackStatsDataSource : {}
};
let _currentAcation = null;
let _currentTrackDataKey = null;

class PerformanceWindowStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    
    this.storageUtil = new StorageUtil();
    this.storageUtil.type = 'sessionStorage';
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState(){
    if(!_state){
      _state = {
        average: '',
        trackDataList : [],
        trackDataSource : {},
        listManagerTrackDataList : [],
        listManagerTrackDataSource : {},
        listManagerTrackStatsDataSource : {}
      }; 
    }
    return _state;
  }

  setShouldUpdate(shouldUpdate = true){
    if((TimeTrackingWindow.isAllDataReady(NavType.NavSymbol) && !shouldUpdate) || shouldUpdate){
      _state.shouldUpdateTimeTrack = shouldUpdate;
    }
  }

  getCurrentAction(){
    return _currentAcation;
  }

  updateTimeTrack(action){
    if(_state.shouldUpdateTimeTrack){
      _currentAcation = action;
      this.emit(CHANGE_EVENT);
    } 
  }

  restTimeTracker(isMoreDataOnScroll = false, nav = NavType.NavSymbol){
    if(nav === NavType.NavSymbol){
      _state.average = '';
      _state.trackDataList = []; 
      _state.trackDataSource = {}; 
      this.updateTimeTrack(PerformanceWindowConstants.ActionTypes.ON_PERFORMANCE_DATA_CLEAR);
    }
    else if(nav === NavType.NavLists){
      if(!isMoreDataOnScroll){
        _state.listManagerTrackDataList = []; 
        _state.listManagerTrackDataSource = {}; 
        _state.listManagerTrackStatsDataSource = {};
      }
      this.updateTimeTrack(PerformanceWindowConstants.ActionTypes.ON_PERFORMANCE_DATA_CLEAR);
    }
  }

  getTrackDataByTrackType(timeTrackingType, nav = NavType.NavSymbol) {
    const trackDataSource = this.getTrackDataSourceByNavType(nav);

    let trackData = trackDataSource[timeTrackingType];
    if (!trackData) {
        const title = this.getTrackTitleByType(timeTrackingType);
        trackData = { title: title, DataType: timeTrackingType, IsShow: true };
        trackDataSource[timeTrackingType] = trackData;
    }
    return trackData;
  }

  getTrackStatsDataSource(){
    const state = this.getState();
    if (!state.listManagerTrackStatsDataSource) {
      state.listManagerTrackStatsDataSource = {};
    }
    return state.listManagerTrackStatsDataSource;
  }

  getTrackStatsDataByTrackType(timeTrackingType){
    const trackStatsDataSource = this.getTrackStatsDataSource();
    if(!trackStatsDataSource[timeTrackingType]){
      trackStatsDataSource[timeTrackingType] = {};
    }
    return trackStatsDataSource[timeTrackingType];
  }

  getOverallEndTrackDataByNavType(nav = NavType.NavSymbol) {
    let trackType = DatagraphDataType.DatagraphRender;
    switch (nav) {
      case NavType.NavSymbol:
        trackType = DatagraphDataType.DatagraphRender;
        break;
      case NavType.NavLists:
        trackType = DatagraphDataType.ListManagerOverallEnd;
        break;
      default:
        break;
    }
    return this.getTrackDataByTrackType(trackType, nav);
  }

  getTrackTitleByType(trackType) {
    switch (trackType) {
      case DatagraphDataType.FinancialOwnership:
        return "Financial/Ownership Block";
      case DatagraphDataType.SummaryBlock:
        return "Summary Block";
      case DatagraphDataType.ExternalDataTIPanel:
        return "External Data TI Panel";
      case DatagraphDataType.PricePanelAndFinancial:
        return "Price Panel";
      case DatagraphDataType.Factor:
        return "Factor Data";
      case DatagraphDataType.OwnershipReport:
        return "OwnershipReport";
      case DatagraphDataType.FinancialBlock:
        return "FinancialBlock";
      case DatagraphDataType.OwnershipBlock:
        return "OwnershipBlock";
      case DatagraphDataType.DatagraphRender:
        return this.isLogin ? "Login - Datagraph" : "Overall end to end time";
      case DatagraphDataType.TIPanel:
        return "TI Metrics/O’Neil Ratings";//"TI Panel";
      case DatagraphDataType.TechnicalIndicators:
        return "Technical Indicators";
      case DatagraphDataType.KPIPanel:
        return "KPI Panel";
      case DatagraphDataType.Annotation:
        return "Annotation";
      case DatagraphDataType.ListContent:
        return "List Content";
      case DatagraphDataType.ListExplorer:
        return "List Explorer";
      case DatagraphDataType.SummaryStats_Histogram:
        return "SummaryStats Histogram";
      case DatagraphDataType.SummaryStats_Data:
        return "SummaryStats Data";
      case DatagraphDataType.ListManagerOverallEnd:
        return this.isLogin ? "Login - ListManager" : "Overall end to end time";
      default:
        break;
    }
    return "";
  }
  
  updateApiTrackDataByTrackType(trackType, value, nav = NavType.NavSymbol){
    const trackData = this.getTrackDataByTrackType(trackType, nav);
    trackData.ApiTime = value;
  }

  updateComponentTrackDataByTrackType(trackType, value, nav = NavType.NavSymbol){
    const trackData = this.getTrackDataByTrackType(trackType, nav);
    trackData.ComponentTime = value;
  }

  getPerformanceWindowRecord(trackData) {
    let record = {};
    record = JSON.parse(sessionStorage.getItem("_performanceWindow"));
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity).toString();
    const DGState = DataGraphStore.getState();
    const symbolType = DGState.SymbolType.toString();
    const dataType = trackData.DataType.toString()
    if (StringUtil.isEmpty(record)) {
      record = {};
    }
    if (StringUtil.isEmpty(record[majorPeriodicity])) {
      record[majorPeriodicity] = {};
    }
    if (StringUtil.isEmpty(record[majorPeriodicity][symbolType])) {
      record[majorPeriodicity][symbolType] = {};
    }
    if (StringUtil.isEmpty(record[majorPeriodicity][symbolType][dataType])){
      record[majorPeriodicity][symbolType][dataType] = [];
    }
    if (parseFloat(trackData.LoadTime).toString() == 'NaN' || !trackData.IsShow || trackData.isRenderErrorTimeTrack || trackData.isApiErrorTimeTrack) {
      const timeList1 = record[majorPeriodicity][symbolType][dataType];
      if (trackData.DataType == DatagraphDataType.DatagraphRender) {
        _state.average = (timeList1.length > 0 ? FormatterUtil.formatNumber(timeList1.reduce((a, b) => a + b) / timeList1.length, 3) : 0) + " s";
      } else {
        trackData.average = (timeList1.length > 0 ? FormatterUtil.formatNumber(timeList1.reduce((a, b) => a + b) / timeList1.length, 3) : 0) + " s";
      }
      return JSON.stringify(record);
    }
    if (TimeTrackingWindow.isLogin && trackData.DataType == DatagraphDataType.DatagraphRender) {
      return JSON.stringify(record);
    }
    if(trackData.isResetTimeTrack !== undefined && trackData.isResetTimeTrack == true){
      record[majorPeriodicity][symbolType][dataType].push(parseFloat(trackData.LoadTime));
      TimeTrackingWindow.setIsResetTimeTrack(trackData.DataType);
    }
    else {
      record[majorPeriodicity][symbolType][dataType].splice(-1, 1, parseFloat(trackData.LoadTime));
    }

    const timeList = record[majorPeriodicity][symbolType][dataType];
    if (trackData.DataType == DatagraphDataType.DatagraphRender){
      _state.average = FormatterUtil.formatNumber(timeList.reduce((a, b) => a + b) / timeList.length, 3) + " s";
    } else{
      trackData.average = FormatterUtil.formatNumber(timeList.reduce((a, b) => a + b) / timeList.length, 3) + " s";
    }
    return JSON.stringify(record);
  }

  updateLoadTrackDataByTrackData(value, nav = NavType.NavSymbol) {
    if(!value || !_state.shouldUpdateTimeTrack){
      return;
    }
    const trackType = value.DataType;
    if (nav === NavType.NavSymbol) { 
      const trackData = this.getTrackDataByTrackType(trackType, nav);
      trackData.ApiTime = value.ApiTimeDisplay;
      trackData.LoadTime = value.LoadTimeDisplay;
      trackData.IsShow = value.IsShow;
      trackData.LoginTime = value.LoginTimeDisplay;
      trackData.isResetTimeTrack = value.isResetTimeTrack;
      trackData.isRenderErrorTimeTrack = value.isRenderErrorTimeTrack;
      trackData.isApiErrorTimeTrack = value.isApiErrorTimeTrack;
      trackData.orderNum = value.orderNum;
      const performanceWindowRecord = this.getPerformanceWindowRecord(trackData)
      this.updateTrackDataList(trackData, nav);
      switch (trackType) {
        case DatagraphDataType.FinancialOwnership:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          _currentAcation = PerformanceWindowConstants.ActionTypes.FINANCIALOWNERSHIP_BLOCK_DATA_LOAD_END;
          break;
        case DatagraphDataType.SummaryBlock:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          _currentAcation = PerformanceWindowConstants.ActionTypes.SUMMARY_BLOCK_DATA_LOAD_END;
          break;
        case DatagraphDataType.ExternalDataTIPanel:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          break;
        case DatagraphDataType.PricePanelAndFinancial:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          _currentAcation = PerformanceWindowConstants.ActionTypes.PRICE_PANEL_DATA_LOAD_END;
          break;
        case DatagraphDataType.Factor:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          break;
        case DatagraphDataType.OwnershipReport:
          break;
        case DatagraphDataType.FinancialBlock:
          break;
        case DatagraphDataType.OwnershipBlock:
          break;
        case DatagraphDataType.TIPanel:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          _currentAcation = PerformanceWindowConstants.ActionTypes.TI_PANEL_DATA_LOAD_END;
          break;
        case DatagraphDataType.TechnicalIndicators:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          break;
        case DatagraphDataType.DatagraphRender:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          _currentAcation = PerformanceWindowConstants.ActionTypes.TRACK_DATA_UPDATE;
          this.emitChange(NavType.NavSymbol);
          break;
        case DatagraphDataType.KPIPanel:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          break;
        case DatagraphDataType.Annotation:
          sessionStorage.setItem("_performanceWindow", performanceWindowRecord);
          break;
        default:
          break;
      }
    }
    else if(nav === NavType.NavLists){
      const trackData = this.getTrackDataByTrackType(trackType, nav);
      trackData.ApiTime = value.ApiTimeDisplay;
      trackData.LoadTime = value.LoadTimeDisplay;
      trackData.IsShow = value.IsShow;
      if(TimeTrackingWindow.isLogin && trackType === DatagraphDataType.ListManagerOverallEnd){
        trackData.LoginTime = value.LoadTimeDisplay;
      }
      else{
        trackData.LoginTime = value.LoginTimeDisplay;
      }
      trackData.isResetTimeTrack = value.isResetTimeTrack;
      trackData.isRenderErrorTimeTrack = value.isRenderErrorTimeTrack;
      trackData.isApiErrorTimeTrack = value.isApiErrorTimeTrack;
      trackData.isLogin = value.isLogin;
      this.updateTrackDataList(trackData, nav);
      const trackStatsData = this.getTrackStatsDataByTrackType(trackType);
      trackStatsData.ApiTime = value.ApiTime;
      trackStatsData.ComponentTime = value.ComponentTime;
      trackStatsData.LoadTime = value.LoadTime;
      trackStatsData.LoginTime = value.LoginTime;
      trackStatsData.isRenderErrorTimeTrack = value.isRenderErrorTimeTrack;
      trackStatsData.IsShow = value.IsShow;
      trackStatsData.TrackDataIndexKey = value.TrackDataIndexKey;
      if(trackType === DatagraphDataType.ListManagerOverallEnd){
        _currentAcation = PerformanceWindowConstants.ActionTypes.TRACK_DATA_UPDATE;
        const trackStatsDataSource = this.getTrackStatsDataSource();
        this.setTrackStatsData(trackStatsDataSource);
        this.calculatTrackDataAvg();
        this.emitChange(NavType.NavLists);
      }
    }
  }

  updateTrackDataList(trackData, nav = NavType.NavSymbol){
    if(!trackData){
        return;
    }
    const trackType = trackData.DataType;
    if(nav === NavType.NavSymbol){
      if(trackType === DatagraphDataType.DatagraphRender){
        return;
      }
    }
    else if(nav === NavType.NavLists){
      if(trackType === DatagraphDataType.ListManagerOverallEnd){
        return;
      }
    }
    const trackDataList = this.getTrackDataListByNavType(nav);
    let orgTrackData = trackDataList.filter((item) => item.DataType === trackData.DataType);
    if(orgTrackData.length === 0){
        trackDataList.push(trackData);
    }
    else{
        let targetTrackData = orgTrackData[0];
        targetTrackData.ApiTime = trackData.ApiTime;
        targetTrackData.ComponentTime = trackData.ComponentTime;
        targetTrackData.LoadTime = trackData.LoadTime;
    }
    switch (trackType) {
        case DatagraphDataType.FinancialOwnership:
          break;
        case DatagraphDataType.SummaryBlock:
          break;
        case DatagraphDataType.ExternalDataTIPanel:
          break;
        case DatagraphDataType.PricePanelAndFinancial:
          break;
        case DatagraphDataType.Factor:
          break;
        case DatagraphDataType.OwnershipReport:
          break;
        case DatagraphDataType.FinancialBlock:
          break;
        case DatagraphDataType.OwnershipBlock:
          break;
        case DatagraphDataType.DatagraphRender:
          break;
        case DatagraphDataType.TIPanel:
          break;
        default:
          break;
    }
  }

  getTrackDataListByNavType(nav = NavType.NavSymbol){
    let trackDataList = [];
    const state = this.getState();
    switch (nav) {
      case NavType.NavSymbol:
        if (!state.trackDataList) {
          state.trackDataList = [];
        }
        trackDataList = state.trackDataList;
        break;
      case NavType.NavLists:
        if (!state.listManagerTrackDataList) {
          state.listManagerTrackDataList = [];
        }
        trackDataList = state.listManagerTrackDataList;
        break;
      default:
        break;
    }
    return trackDataList;
  }

  getTrackDataSourceByNavType(nav = NavType.NavSymbol){
    let trackDataSource = {};
    const state = this.getState();
    switch (nav) {
      case NavType.NavSymbol:
        if (!state.trackDataSource) {
          state.trackDataSource = {};
        }
        trackDataSource = state.trackDataSource;
        break;
      case NavType.NavLists:
        if (!state.listManagerTrackDataSource) {
          state.listManagerTrackDataSource = {};
          state.listManagerTrackStatsDataSource = {};
        }
        trackDataSource = state.listManagerTrackDataSource;
        break;
      default:
        break;
    }
    return trackDataSource;
  }

  setCurrentTrackDataKey(value){
    _currentTrackDataKey = value;
  }

  getCurrentTrackDataKey(){
    return _currentTrackDataKey;
  }

  getTrackDataIndexKey(){
    const trackStatsDataList = this.getTrackStatsDataList();
    if(trackStatsDataList && trackStatsDataList.length > 0){
        return trackStatsDataList.length + 1;
    }
    else{
        return 1;
    }
  }

  getTrackStatsDataList(){
    let trackStatsDataList = this.storageUtil.get("_LMTrackDataList");
    if(!trackStatsDataList){
      trackStatsDataList = [];
    }
    return trackStatsDataList;
  }

  setTrackStatsData(trackStatsDataSource){
    let trackStatsDataList = this.getTrackStatsDataList();
    const currentTrackDataKey = this.getCurrentTrackDataKey();
    if(trackStatsDataList.length >= currentTrackDataKey){
      trackStatsDataList[currentTrackDataKey - 1] = trackStatsDataSource;
    }
    else{
      trackStatsDataList.push(trackStatsDataSource);
    }
    this.storageUtil.set("_LMTrackDataList", trackStatsDataList);
  }

  onInitTimeTracker(){
    const trackDataIndexKey = this.getTrackDataIndexKey();
    this.setCurrentTrackDataKey(trackDataIndexKey);
  }

  calculatTrackDataAvg(){
    const trackStatsDataList = this.getTrackStatsDataList();
    if(trackStatsDataList.length === 0){
      return;
    }
    let listContentTrackStatsData = { Count: 0, TrackDataList: [] };
    let listExplorerTrackStatsData = { Count: 0, TrackDataList: [] };
    let summaryStats_HistogramTrackStatsData = { Count: 0, TrackDataList: [] };
    let summaryStats_DataTrackStatsData = { Count: 0, TrackDataList: [] };
    let listManagerOverallEndTrackStatsData = { Count: 0, TrackDataList: [] };
    trackStatsDataList.forEach(item => {
      let itemTrackData = item[DatagraphDataType.ListContent];
      if(itemTrackData && itemTrackData.IsShow){
        listContentTrackStatsData.Count = listContentTrackStatsData.Count + 1;
        listContentTrackStatsData.TrackDataList.push(itemTrackData);
      }

      itemTrackData = item[DatagraphDataType.ListExplorer];
      if(itemTrackData && itemTrackData.IsShow){
        listExplorerTrackStatsData.Count = listExplorerTrackStatsData.Count + 1;
        listExplorerTrackStatsData.TrackDataList.push(itemTrackData);
      }

      itemTrackData = item[DatagraphDataType.SummaryStats_Histogram];
      if(itemTrackData && itemTrackData.IsShow){
        summaryStats_HistogramTrackStatsData.Count = summaryStats_HistogramTrackStatsData.Count + 1;
        summaryStats_HistogramTrackStatsData.TrackDataList.push(itemTrackData);
      }

      itemTrackData = item[DatagraphDataType.SummaryStats_Data];
      if(itemTrackData && itemTrackData.IsShow){
        summaryStats_DataTrackStatsData.Count = summaryStats_DataTrackStatsData.Count + 1;
        summaryStats_DataTrackStatsData.TrackDataList.push(itemTrackData);
      }

      itemTrackData = item[DatagraphDataType.ListManagerOverallEnd];
      if(itemTrackData && itemTrackData.IsShow){
        listManagerOverallEndTrackStatsData.Count = listManagerOverallEndTrackStatsData.Count + 1;
        listManagerOverallEndTrackStatsData.TrackDataList.push(itemTrackData);
      }
    });

    const trackDataList = this.getTrackDataListByNavType(NavType.NavLists);
    trackDataList.forEach(item => {
      switch (item.DataType) {
        case DatagraphDataType.ListContent:
          item.average = this.calculatDataAvg(listContentTrackStatsData.TrackDataList);
          break;
        case DatagraphDataType.ListExplorer:
          item.average = this.calculatDataAvg(listExplorerTrackStatsData.TrackDataList);
          break;
        case DatagraphDataType.SummaryStats_Histogram:
          item.average = this.calculatDataAvg(summaryStats_HistogramTrackStatsData.TrackDataList);
          break;
        case DatagraphDataType.SummaryStats_Data:
          item.average = this.calculatDataAvg(summaryStats_DataTrackStatsData.TrackDataList);
          break;
        case DatagraphDataType.ListManagerOverallEnd:
        default:
          break;
      }
    });
    let listManagerOverallEndData = this.getOverallEndTrackDataByNavType(NavType.NavLists);
    listManagerOverallEndData.average = this.calculatDataAvg(listManagerOverallEndTrackStatsData.TrackDataList);
  }

  calculatDataAvg(statsData) {
    let sumTime = 0;
    let actualCount = 0;
    statsData.forEach(statDataItem => {
      if (statDataItem.IsShow && !statDataItem.isRenderErrorTimeTrack) {
        let overallTime = statDataItem.LoadTime;
        if(overallTime >= 0){
          sumTime = sumTime + overallTime;
          actualCount = actualCount + 1;
        }
      }
    })

    let avgValue = sumTime / actualCount;
    if (avgValue >= 0) {
      avgValue = avgValue / 1000;
      return `${avgValue.toFixed(3)} s`;
    }
    else {
      return '0 s';
    }
  }

  emitChange(nav = NavType.NavSymbol){
    if(TimeTrackingWindow.isAllDataReady(nav)){
      this.emit(CHANGE_EVENT);
    }
  }

  dispatcherCallback(payload) {
    AppDispatcher.waitFor([SettingsStore.dispatchToken]);
    const action = payload.action;
    switch (action.actionType) {
      default:
        break;
    }
  }
}

const performanceWindowStore = new PerformanceWindowStore();
export default performanceWindowStore;
