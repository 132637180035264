import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class UpdateLableRequest extends BaseServiceRequest {
    constructor(listId, headers) {
        super();
        super.addRequestUri("updatelabel.pb");
        super.addPathParameter(listId.toString());
        if (headers) super.addRequestParameter("headers", headers);
        this.methodType = HttpMethodType.PUT;
    }

    getMethod() {
        return this.methodType;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }

}
