
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga'
import combineReducers from './combine-reducers';
import promiseMiddleware from 'redux-promise-middleware'
import rootSaga from '../Sagas'


const sagaMiddleware = createSagaMiddleware()
/* eslint-disable */
const store = createStore(combineReducers, composeWithDevTools(
    applyMiddleware(
        thunk,
        sagaMiddleware,
        promiseMiddleware()
    )
)
);
/* eslint-enable */

sagaMiddleware.run(rootSaga);

export default store;
