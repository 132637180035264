import ThemeType from "ThemeType";
class GridLineHelper {

  updateTheme(setting) {
    this.setting = setting;
  }

  getGridLineColor() {

    if (this.setting) {
      var liker = this.setting.currentTheme === ThemeType.Dark ? this.setting.gridLineDark : this.setting.gridLineLight;
      return "rgba(" + liker + "," + liker + "," + liker + ",255)";
    }
    return "";
  }

}

const gridHelper = new GridLineHelper();
export default gridHelper;
