import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BaseServiceApi from 'BaseServiceApi';
import ConsoleStore from 'ConsoleStore';
import { DataGraphConstants } from '../../../../Constants/DataGraphConstants';
import DatagraphDataType from '../../../../Constants/DatagraphDataType';
import DataGraphStore from '../../../../Stores/NavModules/NavDataGraph/DataGraphStore';
import DateHelper from '../../../../Utils/TimeLineHelper/Datehelper';
import { ExternalDataConstants } from "../../../../Constants/ExternalDataUploader";
import ExternalDataUploadApi from "../../../../ServiceApi/Apis/DataUploadsApi";
import GraphType from "GraphType";
import ListApi from "ListApi";
import MiniListHelper from "MiniListHelper";
import moment from "moment";
import PeriodicityType from 'PeriodicityType';
import { PrintMode } from '../../../../print/printmode';
import Serializable from "Serializable";
import SettingsActions from '../../../../Actions/SettingsActions';
import SettingsStore from "SettingsStore";
import StringUtil from 'StringUtil';
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from '../../../../RayCustomControls/TimeTrackingWindow';
import { updateExternalToggleButton } from '../../../../Actions/PricePanelActions';
import UserInfoUtil from 'UserInfoUtil';
import { getDatagraphStates, priceChartReducerselect, timeLineSelect } from '../../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { getHeight, getTimeSeriesData_pricePane, getRawTimeSeriesData_pricePane, getTimeSeriesData_indicatorPane, getEventSeriesData, /* getIsShowExternalDataPointer, */ getEventSeriesData_AllPoints, getPDRinfo, getExternalDataResults, getExpandedEventBadgeData, getRiPanelData, getSettingsWindowType, getTimeSeriesData_indicatorPane_AllPoints, getExdListResult } from "../../../../Reducers/ExternalDataUploadReducer/selectors";
import { map, clone, extend, min, max, each, isObject, isArray, find, isEqual, size, flatten } from "underscore";
import { takeLatest, put, call, select, takeEvery, fork } from 'redux-saga/effects';
import { updateTimeSeriesDataOfPricePane, updateTimeSeriesDataOfIndicatorPane, updatePriceAndIndicatorMenu, updateEventSeriesData } from "../../../../Actions/ExternalDataUploaderActions";

const { ActionTypes } = ExternalDataConstants;
const colors = [
    'PCCCCCC262626', 'P33FFFF006666', 'P66CCFF013CB3', 'P9866FF340066', 'PFF0000670000', 'PFDA57DAE3E00', 'PFFFF00676700', 'P34FF33006600', // fifth Row
    'P4C4C4C999999', 'P00999900CCCC', 'P0066CC3399FF', 'P6600996734CC', 'P990000CC0100', 'PFC6901FD8548', 'P999900CBCB01', 'P08980009CB01', // Third Row
    'P000000FFFFFF', 'P00333399FFFF', 'P00003399CCFF', 'P340033CDCCFF', 'P330000FDBCBC', 'P5B1B00FFCC99', 'P333300FFFF9A', 'P00330099FF99', // First Row
    'PFFFFFF000000', 'P99FFFF003333', 'P99CCFF000033', 'PCDCCFF340033', 'PFDBCBC330000', 'PFFCC995B1B00', 'PFFFF9A333300', 'P99FF99003300', // Sixth Row
    'P9999994C4C4C', 'P00CCCC009999', 'P3399FF0066CC', 'P6734CC660099', 'PCC0100990000', 'PFD8548FC6901', 'PCBCB01999900', 'P09CB01089800', // Fourth Row
    'P262626CCCCCC', 'P00666633FFFF', 'P013CB366CCFF', 'P3400669866FF', 'P670000FF0000', 'PAE3E00FDA57D', 'P676700FFFF00', 'P00660034FF33', // Second Row
];

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
let hasDataBeenOverwritten = false

function* getExternalDataSubMenu({ userSettings, isLogin }) {
    
    try {
        if (UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement)) {
            const result = yield ListApi.getListExplorer(3, 0, 0, 1);
            const listItems = result.data.explorerNodes;
            yield put({
                type: ActionTypes.UPDATE_EXD_LIST_EXPLORER_RAW_DATA,
                listItems
            })
            SettingsActions.ExdListExplorerResult = listItems;
            userSettings.isUploadedExternalData = listItems.length > 0;  // Checking if the user has never uploaded an external data set
            yield fork(processListData, isLogin);   
        }
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, getExternalDataSubMenu ${error}`);
    }
}

function* processListData() {
    try {
        const dataGraphStates = yield select(getDatagraphStates);
        const listItems = yield select(getExdListResult)
        const settings = dataGraphStates.viewsSettings;
        //Checking listItems length for the symbol has external data or not
        //Checking isPricePanelDataReady to confirm whether pricePanel api is completed or not
        if (listItems && settings && dataGraphStates.pricePanelData && dataGraphStates.isPricePanelDataReady) {
            const defaultSettings = clone(settings.ExternalDataSettings.default);

            const allListIds = [];
            map(listItems, (list) => {
                const listId = list.nodeId.low;
                const isDateBasedEvents = list.listType === BaseServiceApi.rayData["ListType"].EVENTSERIES_List;
                allListIds.push(listId);

                if (!settings.ExternalDataSettings[listId] && !list.isEmpty) {
                    settings.ExternalDataSettings = Object.assign(new Serializable(), {
                        ...settings.ExternalDataSettings,
                        [listId]: Object.assign(new Serializable(), defaultSettings)
                    });

                    map(settings.ExternalDataSettings[listId], (data, periodicity) => {
                        settings.ExternalDataSettings[listId][periodicity] = Object.assign(new Serializable(), {
                            ...settings.ExternalDataSettings[listId][periodicity],
                            IsVisibleInPriceMenu: list.orderNum === 1 && isDateBasedEvents,
                            IsVisibleInIndicatorMenu: list.orderNum === 1 && !isDateBasedEvents,
                            Header: list.name,
                            shareAccess: list.shareAccess,
                            listType: list.listType,
                            isDateBasedEvents: isDateBasedEvents,
                            isDisabledForCurrentSymbol: false,
                            isLastUploaded: list.orderNum === 1,
                            priceChart: Object.assign(new Serializable(), {
                                ...settings.ExternalDataSettings[listId][periodicity].priceChart,
                                children: '',
                                colorIndex: 0
                            }),
                            indicator: Object.assign(new Serializable(), {
                                ...settings.ExternalDataSettings[listId][periodicity].indicator,
                                children: '',
                                colorIndex: 0
                            })
                        })
                    })
                }
                // avoided displaying last uploaded exd data set if a stock is not part of any external data set
                else if (settings.ExternalDataSettings[listId] && !list.isEmpty) {
                    map(settings.ExternalDataSettings[listId], (data) => {
                        if (data.isLastUploaded) {
                            data.IsVisibleInPriceMenu = list.orderNum === 1 && isDateBasedEvents;
                            data.IsVisibleInIndicatorMenu = list.orderNum === 1 && !isDateBasedEvents;
                        }
                        data.Header = list.name;
                        data.shareAccess = list.shareAccess;
                    });
                }
            });

            yield call(updateDeletedListIds, allListIds, settings.ExternalDataSettings);

            yield put({
                type: ActionTypes.UPDATE_CURRENT_SETTINGS_OBJECT,
                settings: settings,
                settingsObjectIsUpdated: false
            });

            // populating latest data for ri panel and also hiding last uploaded list in indicator and price pane if the uploaded list is not part of current stock
            yield call(populateRiPanelData);
        }
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, processListData ${error}`);
    }
}

function updateDeletedListIds(allListIds, ExternalDataSettings) {
    map(ExternalDataSettings, (item, listId) => {
        if (listId !== "default" && allListIds.indexOf(parseFloat(listId)) === -1) {
            delete ExternalDataSettings[listId];
        }
    });
}

function getScale(data, nodeWidth, indicatorsHeight) {
    try {
        const scale = new ArithmaticScale();
        let maxValue = 0, minValue = 0;

        for (let i = 0; i < data.length; i++) {
            const minNumber = min(data[i].graphData, (item) => item.nValue);
            const maxNumber = max(data[i].graphData, (item) => item.nValue);
            if (i === 0) {
                minValue = minNumber.nValue;
            }
            maxValue = maxNumber.nValue > maxValue ? maxNumber.nValue : maxValue;
            minValue = minNumber.nValue < minValue ? minNumber.nValue : minValue;
        }
        if (isEqual(minValue, maxValue)) {
            maxValue += minValue;
        }

        scale.InitScale(minValue, maxValue, indicatorsHeight !== 0 ? indicatorsHeight : 78, "Weekly", 2, 28 * nodeWidth, SymbolType.USSTOCK, NaN, NaN, true);
        return scale;
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, getScale ${error}`);
    }
}

function getFormatEventSeriesData(result) {
    const data = [];
    for (let i = 0; i < result.length; i++) {
        const list = result[i];
        const length = list.cData.length;
        const allData = [];
        let count = 0;

        if (length > 1) {
            for (let j = 0; j < length; j++) {
                const cData = list.cData[j];
                const info = {
                    category: cData.category,
                    date: DateHelper.getUtcFromLong(cData.date)
                }
                allData.push(info);
                count++;
            }
            data.push({
                count: count,
                allData: allData,
                rollUpDate: DateHelper.getUtcFromLong(list.date)
            });
        }
        else {
            const cData = list.cData[0];
            const info = {
                category: cData.category,
                date: DateHelper.getUtcFromLong(cData.date),
                rollUpDate: DateHelper.getUtcFromLong(list.date),
                count: count
            }
            data.push(info);
        }
    }
    return data.sort((a, b) => b.rollUpDate - a.rollUpDate);
}

function getFormatExpandedBadgeData(result) {
    const expandedData = [];

    for (let i = 0; i < result.length; i++) {
        const data = {};
        const list = result[i];
        if (list.cData.length === 1) {
            const category = list.cData[0].category;
            const date = DateHelper.getUtcFromLong(list.date);
            extend(data, { [category]: {}, date: date });

            for (let j = 0; j < list.cData[0].extRecordData.length; j++) {
                const node = list.cData[0].extRecordData[j];
                extend(data[category], { [node.esFieldName]: node.stringValue });
            }
            expandedData.push(data);
        }
        else {
            for (let k = 0; k < list.cData.length; k++) {
                const category = list.cData[k].category;
                const date = DateHelper.getUtcFromLong(list.date);
                extend(data, { [category]: {}, date: date });

                for (let j = 0; j < list.cData[k].extRecordData.length; j++) {
                    const node = list.cData[k].extRecordData[j];
                    extend(data[category], { [node.esFieldName]: node.stringValue });
                }
                expandedData.push(data);
            }
        }
    }
    return expandedData;
}

function getDifferenceInFirstNode(periodicity, endDate) {
    endDate = new Date(endDate);
    const isFriday = endDate.getDay() === 5;
    //const isEndOfMonth = StockMarketUtil.GetMEndDate(endDate) === endDate;
    //const isEndOfQuarter = StockMarketUtil.GetQEndDate(endDate) === endDate;
    //const isEndOfYear = StockMarketUtil.GetAEndDate(endDate) === endDate;
    let diff = 0;

    switch (periodicity) {
        case GraphType.Daily:
            diff = 0;
            break;
        case GraphType.Weekly:
            diff = isFriday ? 0 : 1;
            break;
        case GraphType.Monthly:
            diff = 0;
            break;
        case GraphType.Quarterly:
            diff = 0;
            break;
        case GraphType.Annual:
            diff = 0;
            break;
        default:
            diff = 0;
            break;
    }
    return diff;
}
function* updateEventsPosition({eventSeriesData, eventSeriesData_AllPoints}) {
    // combining external data events with corporate events.
    try {
        let events = [];
        const lastFewPositioning = [];
        if (size(eventSeriesData) > 0) {
            each(eventSeriesData, (data) => {
                events.push(data);
            });
            events = flatten(events);
            events = events.sort((a, b) => b.GraphDate - a.GraphDate);
            const length = events.length;

            for (let index = 0; index < length; index++) {
                    const event = events[index];
                    const newYAxis = getNewPosition(event.yAxis, event.xAxis, lastFewPositioning);
                    lastFewPositioning.push({ x: event.xAxis, y: newYAxis });
                    events[index].yAxis = newYAxis;
            }
        }

        yield put(updateEventSeriesData(eventSeriesData, eventSeriesData_AllPoints));
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - priceChartStore.js, combineEXDEventsWithCorpEvents ${error}`);
    }
}

function getNewPosition(yAxis, xAxis, lastFewPositioning) {
    const length = lastFewPositioning.length;
    let j = 0, yPosition = yAxis;

    for (; j < length; j++) {
        const point = lastFewPositioning[j];

        if (yAxis === point.y && xAxis === point.x) {
            const y_overlap = Math.max(yAxis, point.y);
            yPosition = y_overlap + 22;
            break;
        }
        else {
            const rect1 = { left: xAxis - 21, top: yAxis - 16, right: xAxis + 3, bottom: yAxis + 14 };
            const rect2 = { left: point.x - 21, top: point.y - 16, right: point.x + 3, bottom: point.y + 14 };

            if (intersectRect(rect1, rect2)) {
                const y_overlap = Math.max(rect1.bottom, rect2.bottom); //Math.max(yAxis, point.y);
                yPosition = y_overlap - 12;
                break;
            }
        }
    }

    if (j >= 0 && j < length){
        return getNewPosition(yPosition, xAxis, Array.from(lastFewPositioning));
    }

    return yPosition;
}

function intersectRect(r1, r2) {
    const intersect = !(r2.left > r1.right ||
        r2.right < r1.left ||
        r2.top > r1.bottom ||
        r2.bottom < r1.top);
    return intersect;
}

function* populateEventSeriesDataSource(result, priceHistory, majorPeriodicity, nodeWidth, padding, hsfResults) {
    try {
        const listId = result.externalData.listId;
        const eventSeriesData = yield select(getEventSeriesData);
        const eventSeriesData_AllPoints = yield select(getEventSeriesData_AllPoints);
        const { HiLowPoints } = yield select(priceChartReducerselect);
        priceHistory = HiLowPoints.allPoints;
        extend(eventSeriesData, { [listId]: [] });
        extend(eventSeriesData_AllPoints, { [listId]: [] });

        if (!result || StringUtil.isEmpty(priceHistory) || StringUtil.isEmpty(result.externalData.graphData)) {
            yield call(updateEventsPosition, { eventSeriesData, eventSeriesData_AllPoints });
            return;
        }

        const data = yield call(getFormatEventSeriesData, result.externalData.graphData[0].graphData);
        // const diff = yield call(getDifferenceInFirstNode, majorPeriodicity, endDate);

        // let x_position = nodeWidth * (lastnode - diff);
        let x_position = priceHistory[0].xAxis;
        let i = 0;
        let count = 1;

        let info = {
            Date: new Date(),
            yAxis: 0,
            xAxis: x_position + padding * nodeWidth,
            yValue: "",
            category: "",
            listId: listId
        };

        for (let p = 0; p < hsfResults.length - 1; p++) {
            x_position -= nodeWidth;
            let cData = data[i];
            let prevNode = p > 0 ? data[i - 1] : null;
            if (hsfResults[0].Date < cData.rollUpDate) {
                i++;
                continue;
            }
            // if user entered date is greater than first price node date/end date then skip that date
            while (moment(cData.rollUpDate).isAfter(moment(hsfResults[p].Date)) && (cData.allData ? !moment(cData.allData[0].date).isSame(moment(hsfResults[p].Date)) : !moment(cData.date).isSame(moment(hsfResults[p].Date)))) {
                i++;
                if (i > data.length - 1){ 
                    break;
                }
                cData = data[i];
                prevNode = p > 0 ? data[i - 1] : null;
            }

            // if all data is skipped inside while loop, then we need to break the for loop which runs unnecessary data.
            if (i > data.length - 1){ 
                break;
            }

            if (hsfResults[p].Date > cData.rollUpDate) {
                info = {
                    Date: hsfResults[p].Date,
                    yAxis: 0,
                    xAxis: x_position,
                    yValue: "",
                    category: "",
                    listId: listId,
                    DisplayValue: "EXD",
                };
                eventSeriesData_AllPoints[listId].push(info);
                continue;
            }

            //Handling duplicate dates 
            if (prevNode && moment(cData.rollUpDate).isSame(moment(prevNode.rollUpDate))) {
                p--;
                x_position += nodeWidth;
                count = prevNode.count > 0 ? prevNode.count : cData.count > 0 ? cData.count : count;
                count++;
                eventSeriesData[listId].splice(eventSeriesData[listId].length - 1);
                eventSeriesData_AllPoints[listId].splice(eventSeriesData_AllPoints[listId].length - 1);
            }
            else {
                count = 1;
            }
            const index = p - padding > 0 ? p - padding : 0;
            const yAxis = getPositionRelativeToPriceNode(index, priceHistory);
            const xAxis = index > 0 ? priceHistory[index].xAxis : priceHistory[index].xAxis + (padding - p)* nodeWidth;
            if (cData.count > 0) {
                if (majorPeriodicity === GraphType.Daily) {
                    const allInfo = [];
                    for (let i = 0; i < cData.allData.length; i++) {
                        const data = cData.allData[i];
                        info = {
                            Date: cData.rollUpDate,
                            yAxis: yAxis,
                            xAxis,
                            yValue: data.value,
                            category: data.category,
                            count: 0,
                            DisplayValue: "EXD",
                            listId: listId,
                            color: ThemeHelper.getThemedBrush("P99FF99003300")
                        };
                        eventSeriesData[listId].push(info);
                        allInfo.push(info);
                    }
                    eventSeriesData_AllPoints[listId].push(allInfo);
                }
                info = {
                    Date: cData.rollUpDate,
                    yAxis: yAxis,
                    xAxis,
                    yValue: 0,
                    category: '',
                    count: cData.count,
                    DisplayValue: "EXD",
                    listId: listId,
                    color: ThemeHelper.getThemedBrush("P99FF99003300")
                };
            }
            else {
                info = {
                    Date: cData.rollUpDate,
                    yAxis: yAxis,
                    xAxis,
                    yValue: cData.value,
                    category: cData.category,
                    count: count,
                    DisplayValue: "EXD",
                    listId: listId
                };
            }

            if (cData.count > 0 && majorPeriodicity === GraphType.Daily) {
            }
            else {
                eventSeriesData[listId].push(info);
                eventSeriesData_AllPoints[listId].push(info);
            }
            i++;

            // normal case - break the loop when all events data have been checked
            if (i > data.length - 1){ 
                break;
            }
        }

        yield call(updateEventsPosition, { eventSeriesData, eventSeriesData_AllPoints });

       

    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, populateEventSeriesDataSource ${error}`, error);
    }
}

function getPositionRelativeToPriceNode(index, priceHistory) {
    let yAxis = priceHistory[index].yLow + 30;
    for (let i = index - 2; i < index + 7; i++) {
        if (priceHistory[i] && priceHistory[i].yLow > yAxis - 15) {
            yAxis = priceHistory[i].yLow + 30;
        }
    }
    return yAxis;
}

function* populatePDRinfo(ripanelData, viewsSettings, SymbolInfo) {
    try {
        const PDR_info = yield select(getPDRinfo);
        let sequenceNo = 0;
        each(ripanelData, (list) => {
            if (StringUtil.isEmpty(viewsSettings.ExternalDataRiPanelSettings) || !viewsSettings.ExternalDataRiPanelSettings[list.listId.low]) {
                viewsSettings.ExternalDataRiPanelSettings = Object.assign(new Serializable(), {
                    ...viewsSettings.ExternalDataRiPanelSettings,
                    [list.listId.low]: ''
                });
            }
            each(list.metrics, (item) => {
                if (!viewsSettings.ExternalDataRiPanelSettings[list.listId.low][item.esFieldName]) {
                    viewsSettings.ExternalDataRiPanelSettings[list.listId.low] = Object.assign(new Serializable(), {
                        ...viewsSettings.ExternalDataRiPanelSettings[list.listId.low],
                        [item.esFieldName]: Object.assign(new Serializable(), {
                            ...viewsSettings.ExternalDataRiPanelSettings[item.esFieldName],
                            isVisible: false,
                            label: item.label,
                            metricName: item.metricName
                        })
                    })
                }

                if (viewsSettings.ExternalDataRiPanelSettings[list.listId.low][item.esFieldName].isVisible) {
                    const isPresent = PDR_info.headerData && find(PDR_info.headerData, (info) => {
                        if (info.esField === item.esFieldName) {
                            if (parseFloat(info.listId) === parseFloat(list.listId.low)) {
                                return true;
                            }
                        }
                    });
                    // Avoiding repeated entries.
                    if (!isPresent) {
                        const info = { metric: item.metricName, value: parseFloat(parseFloat(item.value).toFixed(2)), esField: item.esFieldName, listId: list.listId.low, sequenceNo: sequenceNo };
                        PDR_info['headerData'].push(info);
                        sequenceNo++;
                    }
                }

            })
        });
        (!PrintMode.printing) && PDR_info.headerData.length > 0 && PrintMode.updateExternalScore(PDR_info.headerData[0].sequenceNo);
        if(PDR_info.headerData.length > 0){
            const userSettings = SettingsStore.getConsoleSettings();
            extend(PDR_info, { recentRow: userSettings.printSettings.externalScore[`${SymbolInfo.MsId}`] });
        }
        

        yield put({
            type: ActionTypes.UPDATE_PDR_TO_REDUCER,
            PDR_info: PDR_info
        });
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, populatePDRinfo ${error}`);
    }
}

function* populateRiPanelData() {
    try {
        const state = DataGraphStore.getState();
        
        const { viewsSettings, SymbolInfo, Symbol} = yield select(getDatagraphStates);
        const isMiniListPlay = state.IsMiniListPlay;

        const result = yield ExternalDataUploadApi.getExtrecentdata(SymbolInfo.MsId);

        if (!StringUtil.isEmpty(result) && !StringUtil.isEmpty(result.timeSeriesLists) && MiniListHelper.ActiveSymbolCheck(Symbol, isMiniListPlay)) {
            yield fork(populatePDRinfo, result.timeSeriesLists, viewsSettings, SymbolInfo);
        }
        else {
            yield put({
                type: ActionTypes.UPDATE_PDR_TO_REDUCER,
                PDR_info: { ['headerData']: [] }
            });

        }
        yield put({
            type: ActionTypes.UPDATE_RI_PANEL_DATA,
            riPanelData: result.timeSeriesLists,
            loading: false
        });

        yield call(disableListItemsBasedOnSymbol, result, viewsSettings);
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, populateRiPanelData ${error}`);
    }
}

function* disableListItemsBasedOnSymbol(result, viewsSettings) {
    try {
        const listIDs = [];
        each(result.eventSeriesLists, (item) => {
            listIDs.push(item.listId.low);
        });
        each(result.timeSeriesLists, (item) => {
            listIDs.push(item.listId.low);
        });

        each(viewsSettings.ExternalDataSettings, (listInfo, listId) => {
            let isDisabledForCurrentSymbol = false;
            if (listIDs.indexOf(parseFloat(listId)) === -1) {
                isDisabledForCurrentSymbol = true;
            }
            map(listInfo, (item) => {
                item.isDisabledForCurrentSymbol = isDisabledForCurrentSymbol;
            });
        });

        yield put(updatePriceAndIndicatorMenu());

        yield put({
            type: ActionTypes.UPDATE_CURRENT_SETTINGS_OBJECT,
            settings: viewsSettings,
            settingsObjectIsUpdated: true
        });
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, disableListItemsBasedOnSymbol ${error}`);
    }
}

function* drawFundamentalLines({ timeSeriesData, rawTimeSeriesData, settings, scale, timeLine, multiplier, majorPeriodicity, x_position, nodeWidth }) {
    try {
        const endDate = moment(ConsoleStore.getStates().endDate);
        let isShowToggleButton = false;

        let info = {
            Date: new Date(),
            yPrice: "",
            xAxis: x_position,
            yValue: ""
        };

        for (const listId in rawTimeSeriesData) {
            if (isObject(timeSeriesData[listId])) {
                const data = rawTimeSeriesData[listId];

                if (settings.ExternalDataSettings[listId][majorPeriodicity].IsVisibleInPriceMenu){ 
                    isShowToggleButton = true;
                }

                let i = 0;
                for (let t = 0; t < timeLine.length - 1 && x_position > 0; t++) {
                    x_position -= nodeWidth;
                    let node = data[i];
                    const prevNode = data[i - 1];

                    if (!timeSeriesData[listId][node.metric]){
                        extend(timeSeriesData[listId], { [node.metric]: [] });
                    }

                    if (i > 0 && node.metric !== prevNode.metric) {
                        if (moment(node.date).isSame(moment(prevNode.date))) {
                            t--;
                            x_position += nodeWidth;
                        }
                    }

                    // if user entered date is greater than the time line date then skip that date
                    while (moment(node.date).isAfter(moment(timeLine[t].Date))) {
                        i++;
                        node = data[i];
                    }

                    // plotting will begin from end date and data later the end date will be skipped
                    if (moment(timeLine[t].Date).isAfter(moment(endDate))) {
                        // }
                        continue;
                    }

                    // finding first node - skip the timline array untill first date entered by user is found.
                    if (moment(timeLine[t].Date).isAfter(moment(node.date))) {
                        continue;
                    }

                    info = {
                        Date: timeLine[t].Date,
                        yPrice: scale.ComputeY(node.value * multiplier),
                        xAxis: x_position,
                        yValue: node.value
                    };
                    timeSeriesData[listId][node.metric].push(info);
                    i++;

                    if (i > data.length - 1){ 
                        break;
                    }
                }
            }
        }

        // dispatching an action to respective store which renders the view component with latest data
        yield put(updateTimeSeriesDataOfPricePane(timeSeriesData, rawTimeSeriesData, isShowToggleButton));
        yield put(updateExternalToggleButton(isShowToggleButton))
        yield put({
            type: ActionTypes.UPDATE_TIME_SERIES_DATA_OF_PRICE_PANE,
            timeSeriesData_pricePane: timeSeriesData,
            rawTimeSeriesData_pricePane: rawTimeSeriesData,
            isShowExternalDataPointer: false,
            isShowToggleButton
        });
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, drawFundamentalLines ${error}`);
    }
}

// function median(values) {
//     values.sort((a, b) => (a - b));

//     var half = Math.round(values.length / 2);

//     if (values.length % 2)
//         return Number(values[half]).toFixed(3);
//     else
//         return Number((values[half - 1] + values[half]) / 2).toFixed(3);
// }

function getTimeSeriesData(result, isIndicatorPane) {
    const timeSeriesData = [];
    if (!result){ 
        return;
    }

    if (isIndicatorPane) {
        for (let i = 0; i < result.length; i++) {
            const arr = result[i];
            const fieldName = arr.esFieldName;

            for (let j = 0; j < arr.graphData.length; j++) {
                const node = arr.graphData[j];
                const info = {
                    metric: fieldName,
                    date: DateHelper.getUtcFromLong(node.date),
                    value: node.nValue
                }
                timeSeriesData.push(info);
            }
        }
        timeSeriesData.sort((a, b) => b.date - a.date);
    }
    else {
        each(result, (data) => {
            timeSeriesData.push({
                ReportDate: DateHelper.getUtcFromLong(data.date),
                FiscalDate: DateHelper.getUtcFromLong(data.date),
                QtrData: data.nValue,
                QtrDataHigh: '',
                QtrDataLow: '',
                Type: 1,
                Display: 1
            });
        });

        timeSeriesData.sort((a, b) => b.ReportDate - a.ReportDate);
    }
    return timeSeriesData;
}

function* populateTimeSeriesDataSource(result, listId, lastnode, timeLine, scale, minPrice, maxPrice, chartHeight, isPriceChartView, endDate, epsMultiplier, rpsMultiplier, t4QMultipliers) {
    try {
        const { viewsSettings, majorPeriodicity, nodeWidth, indicatorsHeight } = yield select(getDatagraphStates);
        if (isPriceChartView) {
            const timeSeriesData_pricePane = yield select(getTimeSeriesData_pricePane);
            const rawTimeSeriesData = yield select(getRawTimeSeriesData_pricePane);
            const record = result.externalData.graphData;
            const diff = yield call(getDifferenceInFirstNode, majorPeriodicity, endDate);
            const startx = nodeWidth * (lastnode - diff);

            if (record && !StringUtil.isEmpty(record)) {
                extend(timeSeriesData_pricePane, { [listId]: {} });
                extend(rawTimeSeriesData, { [listId]: {} });
                rawTimeSeriesData[listId] = yield call(getTimeSeriesData, record, true);

                let edM = 1;
                const maxValue = max(rawTimeSeriesData[listId], (item) => item.value).value;
                const minValue = min(rawTimeSeriesData[listId], (item) => item.value).value;
                const maxMultiplier = (maxPrice / maxValue).toFixed(2);
                const minMultiplier = (minPrice / minValue).toFixed(2);
                edM = min([maxMultiplier, minMultiplier]);

                // let edM = (maxPrice / maxValue).toFixed(2);
                const multipliers = [];
                multipliers.push(Number(edM));
                if (viewsSettings.EDMultiplierSettings.multiplier && !isNaN(viewsSettings.EDMultiplierSettings.multiplier)) {
                    multipliers.push(Number(viewsSettings.EDMultiplierSettings.multiplier));
                    // edM = yield call(median, multipliers.filter((t) => t > 0));
                    edM = min(multipliers);
                }
                extend(timeSeriesData_pricePane, { ["multiplier"]: edM });
                viewsSettings.EDMultiplierSettings.multiplier = Number(edM);

                scale.InitScale(minPrice, maxPrice, chartHeight, majorPeriodicity, 1, 28 * nodeWidth,
                    SymbolType.USSTOCK, epsMultiplier, rpsMultiplier, t4QMultipliers, edM);

                yield call(drawFundamentalLines, {
                    timeSeriesData: timeSeriesData_pricePane,
                    rawTimeSeriesData: rawTimeSeriesData,
                    settings: viewsSettings,
                    scale: scale,
                    timeLine: timeLine,
                    multiplier: Number(edM),
                    majorPeriodicity: majorPeriodicity,
                    x_position: startx,
                    nodeWidth: nodeWidth
                });
            }
        }
        else {
            const timeSeriesData_indicatorPane = yield select(getTimeSeriesData_indicatorPane);
            const timeSeriesData_indicatorPane_AllPoints = yield select(getTimeSeriesData_indicatorPane_AllPoints);
            const record = result.externalData.graphData;

            const diff = yield call(getDifferenceInFirstNode, majorPeriodicity, endDate);
            let x_position = nodeWidth * (lastnode - diff);

            if (!StringUtil.isEmpty(record)) {
                extend(timeSeriesData_indicatorPane, { [listId]: {} });
                extend(timeSeriesData_indicatorPane_AllPoints, { [listId]: [] });

                scale = yield call(getScale, record, nodeWidth, indicatorsHeight);
                extend(timeSeriesData_indicatorPane[listId], { scale: scale });

                const data = yield call(getTimeSeriesData, record, true);
                let i = 0;
                let info = {
                    Date: new Date(),
                    yPrice: "",
                    xAxis: x_position,
                    yValue: ""
                };

                for (let t = 0; t < timeLine.length - 1 && x_position > 0; t++) {
                    x_position -= nodeWidth;
                    let node = data[i];
                    const prevNode = data[i - 1];
                    let isRepeatedDate = false;

                    if (!timeSeriesData_indicatorPane[listId][node.metric]){
                        extend(timeSeriesData_indicatorPane[listId], { [node.metric]: [] });
                    }

                    if (i > 0 && node.metric !== prevNode.metric) {
                        if (moment(node.date).isSame(moment(prevNode.date))) {
                            t--;
                            x_position += nodeWidth;
                            isRepeatedDate = true;
                        }
                    }

                    // if user entered date is greater than the time line date then skip that date
                    while (moment(node.date).isAfter(moment(timeLine[t].Date))) {
                        i++;
                        node = data[i];
                    }

                    // finding first node - skip the timline array untill first date entered by user is found.
                    if (moment(timeLine[t].Date).isAfter(moment(node.date))) {
                        if (!moment(timeLine[t].Date).isAfter(moment(endDate))) {
                            info = {
                                Date: timeLine[t].Date,
                                yPrice: "",
                                xAxis: x_position,
                                yValue: ""
                            };
                            timeSeriesData_indicatorPane_AllPoints[listId].push(info);
                        }
                        continue;
                    }

                    info = {
                        Date: timeLine[t].Date,
                        yPrice: scale.ComputeY(node.value),
                        xAxis: x_position,
                        yValue: node.value
                    };
                    timeSeriesData_indicatorPane[listId][node.metric].push(info);
                    if (!isRepeatedDate) {
                        timeSeriesData_indicatorPane_AllPoints[listId].push(info);
                    }
                    i++;

                    if (i > data.length - 1){ 
                        break;
                    }
                }
            }
            else {
                for (const key in timeSeriesData_indicatorPane[listId]) {
                    if (key !== "scale") {
                        timeSeriesData_indicatorPane[listId][key] = [];
                    }
                };
                timeSeriesData_indicatorPane_AllPoints[listId] = [];
            }
            //dispatching an action to respective store which renders the view component with latest data
            updateTimeSeriesDataOfIndicatorPane();

            yield put({
                type: ActionTypes.UPDATE_TIME_SERIES_DATA_OF_INDICATOR_PANE,
                timeSeriesData_indicatorPane: timeSeriesData_indicatorPane,
                timeSeriesData_indicatorPane_AllPoints: timeSeriesData_indicatorPane_AllPoints
            });
        }
    }

    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, populateTimeSeriesDataSource ${error}`);
    }
}

function filterSettings(settings, isDateBasedEvents, eventSeriesSettingsWindowMenu, header) {
    try {
        if (isDateBasedEvents) {
            const existingCategories = [];
            const newCategories = [];
            const existingAdvancedData = [];
            const newAdvancedData = [];
            settings && each(settings["Weekly"].priceChart.children, (item) => existingCategories.push(item.Name));

            eventSeriesSettingsWindowMenu && eventSeriesSettingsWindowMenu.categories && each(eventSeriesSettingsWindowMenu.categories, (item) => newCategories.push(item.category));

            for (let i = 0; i < existingCategories.length; i++) {
                if (newCategories.indexOf(existingCategories[i]) === -1) {
                    map(settings, (data, periodicity) => {
                        delete settings[periodicity].priceChart.children[existingCategories[i]];
                    });
                }
            }

            settings["Weekly"].AdvancedData && each(settings["Weekly"].AdvancedData, (item, esFieldName) => existingAdvancedData.push({ displayName: item.displayName, esFieldName: esFieldName }));

            each(header, (item) => newAdvancedData.push(item.displayName));

            for (let i = 0; i < existingAdvancedData.length; i++) {
                if (newAdvancedData.indexOf(existingAdvancedData[i].displayName) === -1) {
                    map(settings, (data, periodicity) => {
                        delete settings[periodicity].AdvancedData[existingAdvancedData[i].esFieldName];
                    });
                }
            }
        }
        else {
            const existingHeaders = [];
            const newHeaders = [];

            settings && each(settings["Weekly"].indicator.children, (item, esFieldName) => existingHeaders.push({ displayName: item.Name, esFieldName: esFieldName }));

            each(header, (item) => {
                if (item.type === 20){
                    newHeaders.push(item.displayName);
                }
            });

            for (let i = 0; i < existingHeaders.length; i++) {
                if (newHeaders.indexOf(existingHeaders[i].displayName) === -1) {
                    map(settings, (data, periodicity) => {
                        delete settings[periodicity].indicator.children[existingHeaders[i].esFieldName];
                    });
                }
            }
        }
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, filterSettings ${error}`);
    }
}

function* populateSettings(listId, result, isDateBasedEvents, eventSeriesSettingsWindowMenu, viewsSettings) {
    try {
        const ExternalDataSettings = viewsSettings.ExternalDataSettings;
        if (result && result.externalData && result.externalData.headers) {
            yield call(filterSettings, ExternalDataSettings[listId], isDateBasedEvents, eventSeriesSettingsWindowMenu, result.externalData.headers);

            map(ExternalDataSettings[listId], (data, periodicity) => {
                if (isDateBasedEvents && eventSeriesSettingsWindowMenu && eventSeriesSettingsWindowMenu.categories) {
                    let colorIndex = ExternalDataSettings[listId][periodicity]["priceChart"].colorIndex;

                    map(eventSeriesSettingsWindowMenu.categories, (item) => {
                        if (!ExternalDataSettings[listId][periodicity]["priceChart"].children[item.category]) {

                            const fontColor = ThemeHelper.getFontColor(colors[colorIndex]);

                            ExternalDataSettings[listId][periodicity]["priceChart"].children = Object.assign(new Serializable(), {
                                ...ExternalDataSettings[listId][periodicity].priceChart.children,
                                [item.category]: Object.assign(new Serializable(), {
                                    Name: item.category,
                                    Label: item.label,
                                    Color: colors[colorIndex],
                                    FontColor: fontColor,
                                    IsVisible: true
                                })
                            })
                            colorIndex++;
                            ExternalDataSettings[listId][periodicity]["priceChart"].colorIndex = colorIndex;
                        }
                        // Reassigning latest label on each render to avoid label mismatch in recurring uploads - not retaining label in user settings. 
                        ExternalDataSettings[listId][periodicity]["priceChart"].children[item.category].Label = item.label;
                    })

                    // populating advanced settings data
                    if (!ExternalDataSettings[listId][periodicity]["AdvancedData"]) {
                        map(result.externalData.headers, (item) => {
                            ExternalDataSettings[listId][periodicity] = Object.assign(new Serializable(), {
                                ...ExternalDataSettings[listId][periodicity],
                                AdvancedData: Object.assign(new Serializable(), {
                                    ...ExternalDataSettings[listId][periodicity].AdvancedData,
                                    [item.esFieldName]: Object.assign(new Serializable(), {
                                        IsVisible: false,
                                        displayName: item.displayName
                                    })
                                })
                            })
                        })
                    }
                    else {
                        const existingAdvancedData = [];
                        const newAdvancedData = [];
                        each(ExternalDataSettings[listId][periodicity].AdvancedData, (item) => existingAdvancedData.push({ displayName: item.displayName }));

                        each(result.externalData.headers, (item) => newAdvancedData.push({ displayName: item.displayName, esFieldName: item.esFieldName }));

                        for (let i = 0; i < newAdvancedData.length; i++) {
                            if (existingAdvancedData.indexOf(newAdvancedData[i].displayName) === -1 && (!ExternalDataSettings[listId][periodicity].AdvancedData[newAdvancedData[i].esFieldName] || ExternalDataSettings[listId][periodicity].AdvancedData[newAdvancedData[i].esFieldName].displayName !== newAdvancedData[i].displayName)) {
                                ExternalDataSettings[listId][periodicity] = Object.assign(new Serializable(), {
                                    ...ExternalDataSettings[listId][periodicity],
                                    AdvancedData: Object.assign(new Serializable(), {
                                        ...ExternalDataSettings[listId][periodicity].AdvancedData,
                                        [newAdvancedData[i].esFieldName]: Object.assign(new Serializable(), {
                                            IsVisible: false,
                                            displayName: newAdvancedData[i].displayName
                                        })
                                    })
                                })
                            }
                        }
                    }
                }
                else {
                    let colorIndex = ExternalDataSettings[listId][periodicity]["priceChart"].colorIndex;
                    map(result.externalData.headers, (item) => {
                        if (!ExternalDataSettings[listId][periodicity]["priceChart"].children[item.esFieldName] || !ExternalDataSettings[listId][periodicity]["indicator"].children[item.esFieldName]) {
                            if (!item.isSymbolColumn && !item.isDateColumn && item.type === 20) {
                                ExternalDataSettings[listId][periodicity]["indicator"].children = Object.assign(new Serializable(), {
                                    ...ExternalDataSettings[listId][periodicity].indicator.children,
                                    [item.esFieldName]: Object.assign(new Serializable(), {
                                        Name: item.displayName,
                                        //Label: item.label,
                                        Color: colors[colorIndex],
                                        IsVisible: true
                                    })
                                });

                                ExternalDataSettings[listId][periodicity]["priceChart"].children = Object.assign(new Serializable(), {
                                    ...ExternalDataSettings[listId][periodicity].priceChart.children,
                                    [item.esFieldName]: Object.assign(new Serializable(), {
                                        Name: item.displayName,
                                        //Label: item.label,
                                        Color: colors[colorIndex],
                                        IsVisible: true
                                    })
                                })
                                colorIndex++;
                                ExternalDataSettings[listId][periodicity]["priceChart"].colorIndex = colorIndex;
                            }
                        }
                    });
                }
            });
        }
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, populateSettings ${error}`);
    }
}

async function getDataFromApi(listId, msId, beginDate, endDate, sort = '', pageNo = '', pageSize = '', esfields = '', periodicity, initExtIndicator = false) {
    try {
    TimeTrackingWindow.beginExternalPanelTimeTracker();
    const result = await ExternalDataUploadApi.getCurrentListData(listId, msId, beginDate, endDate, sort, pageNo, pageSize, esfields, periodicity);
    TimeTrackingWindow.endExternalPanelApiTimeTracker();
    return result;
    } catch (error) {
        console.error(error);
        if (initExtIndicator){
            TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.ExternalDataTIPanel);
            TimeTrackingWindow.endExternalPanelApiTimeTracker();
        }
    }
}

function* getCurrentListData({ listId, lastnode, timeLine, scale, minPrice, maxPrice, chartHeight, isPriceChartView, epsMultiplier, rpsMultiplier, t4QMultipliers, isCalRequired = true, esfields = '', priceHistory = null, initExtIndicator = false }) {
    const { viewsSettings, majorPeriodicity, nodeWidth, padding, pricePanelData } = yield select(getDatagraphStates);
    const periodicity = PeriodicityType[majorPeriodicity];
    const isDateBasedEvents = viewsSettings.ExternalDataSettings[listId][majorPeriodicity].isDateBasedEvents;
    const endDate = timeLine ? moment(timeLine[0].Date).format('YYYYMMDD') : moment(ConsoleStore.getStates().endDate).format('YYYYMMDD');
    const { isRIPanelOpen } = DataGraphStore.getState();
    const beginDate = periodicity == 2 && !isRIPanelOpen
    ? moment(ConsoleStore.getStates().beginDate).subtract(1, 'years').format('YYYYMMDD')
    :moment(ConsoleStore.getStates().beginDate).format('YYYYMMDD') ;
    const msId = DataGraphStore.getState().SymbolInfo.MsId;
    let eventSeriesSettingsWindowMenu = null;
    const externalDataResults = yield select(getExternalDataResults);
    let result = null;
    const isEXDReqInitiated = !externalDataResults[listId];
    let localEsFields = ''
    const listItem = viewsSettings.ExternalDataSettings[listId][majorPeriodicity];
    if (StringUtil.isEmpty(esfields)) {
        if (listItem.isDateBasedEvents && !StringUtil.isEmpty(listItem.AdvancedData)) {
            Object.keys(listItem.AdvancedData).forEach((key) => {
                if (listItem.AdvancedData[key].IsVisible) {
                    localEsFields += `${key};`;
                }
            })
        }
    } else {
        localEsFields = esfields
    }
    try {
        if (isEXDReqInitiated || hasDataBeenOverwritten) {
            result = yield call(getDataFromApi, listId, msId, beginDate, endDate, '', '', '', localEsFields, periodicity, initExtIndicator);
            extend(externalDataResults, { [listId]: result });
        }

        if (externalDataResults[listId] && externalDataResults[listId].externalData) {
            if (isDateBasedEvents && isEXDReqInitiated) {
                try {
                    TimeTrackingWindow.beginExternalEventsTimeTracker();

                    eventSeriesSettingsWindowMenu = yield call(ExternalDataUploadApi.getExtCategories, listId);

                    TimeTrackingWindow.endExternalEventsApiTimeTracker();
                } catch (e) {
                    console.error(e);
                        TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.ExternalDataEvents);
                        TimeTrackingWindow.endExternalEventsApiTimeTracker();
                }
            }

            if(isEXDReqInitiated){
                yield call(populateSettings, listId, externalDataResults[listId], isDateBasedEvents, eventSeriesSettingsWindowMenu, viewsSettings);
            }

            if (isCalRequired) {
                if (isDateBasedEvents) {
                    try {
                        TimeTrackingWindow.beginExternalEventsRenderTimeTracker();
                        yield call(populateEventSeriesDataSource, externalDataResults[listId], priceHistory, majorPeriodicity, nodeWidth, padding, pricePanelData.HsfData.HSFResults);

                        TimeTrackingWindow.endExternalEventsLoadEndTimeTracker();
                    } catch (e) {
                        console.error(e);
                        TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.ExternalDataEvents);
                        TimeTrackingWindow.endExternalEventsLoadEndTimeTracker();
                    }
                }
                else {
                    try {
                    initExtIndicator && TimeTrackingWindow.beginExternalPanelRenderTimeTracker();
                    yield call(populateTimeSeriesDataSource, externalDataResults[listId], listId, lastnode, timeLine, scale, minPrice, maxPrice, chartHeight, isPriceChartView, moment(ConsoleStore.getStates().endDate), epsMultiplier, rpsMultiplier, t4QMultipliers);
                    initExtIndicator && TimeTrackingWindow.endExternalPanelLoadEndTimeTracker();
                    } catch (e){
                        console.error(e);
                        if(initExtIndicator) {
                            TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.ExternalDataTIPanel);
                            TimeTrackingWindow.endExternalPanelLoadEndTimeTracker();
                        }
                        
                    }
                }
            }

            if (!StringUtil.isEmpty(localEsFields)) {
                yield call(populateAdvancedData, externalDataResults[listId], listId);
            }

            yield put({
                type: ActionTypes.UPDATE_EXTERNAL_DATA_API_RESULT,
                externalDataResults: externalDataResults,
                isEXDReqInitiated: true,
            });
        }
        else {
            yield call(clearData, { isClearAll: false, listId: listId, shouldUpdateToStore: true });
        }
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, getCurrentListData ${error}`);
    }
}

function* reDrawChart({ listId, lastnode, timeLine, scale, minPrice, maxPrice, chartHeight, isPriceChartView, epsMultiplier, rpsMultiplier, t4QMultipliers, esfields, priceHistory, initExtIndicator = false }) {
    try {
        yield call(getCurrentListData, {
            listId: listId,
            lastnode: lastnode,
            timeLine: timeLine,
            scale: scale,
            minPrice: minPrice,
            maxPrice: maxPrice,
            chartHeight: chartHeight,
            isPriceChartView: isPriceChartView,
            epsMultiplier: epsMultiplier,
            rpsMultiplier: rpsMultiplier,
            t4QMultipliers: t4QMultipliers,
            isCalRequired: true,
            esfields: esfields,
            priceHistory: priceHistory,
            initExtIndicator: initExtIndicator
        });
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, reDrawChart ${error}`);
    }
}

function* populateAdvancedData(result, listId) {
    const expandedEventBadgeData = yield select(getExpandedEventBadgeData);

    if (result.externalData.graphData[0] && result.externalData.graphData[0].graphData) {
        const data = yield call(getFormatExpandedBadgeData, result.externalData.graphData[0].graphData);

        extend(expandedEventBadgeData, { [listId]: data });
        yield put({
            type: ActionTypes.UPDATE_EXPANDED_EVENT_BADGE_DATA,
            expandedEventBadgeData: expandedEventBadgeData,
        });
    }
}

function* saveToSettings(editDialogMenu, listId, editDialogAdvancedMenu) {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const settingsAdvancedMenu = viewsSettings.ExternalDataSettings[listId][majorPeriodicity].AdvancedData;
        const isPriceChartSettings = yield select(getSettingsWindowType);
        const timeLine = yield select(timeLineSelect);

        if (!StringUtil.isEmpty(editDialogMenu)) {

            let headers = '';
            const data = editDialogMenu[majorPeriodicity].children;
            map(data, (item, key) => {
                headers += `${key}:${item.Label};`
            })

            if (viewsSettings.ExternalDataSettings[listId][majorPeriodicity].isDateBasedEvents) {
                yield call(ExternalDataUploadApi.getExtCategories, listId, headers);  // event series - request to save edited label
            }
            else {
                yield fork(updateRipanelWithNewLabel, listId, data);
                yield call(ExternalDataUploadApi.updatelabel, listId, headers);    // time series - request to save edited label 
            }

            // updating the settings by periodicity specific.
            each(editDialogMenu, (data, periodicity) => {
                const settingsChildrenMenu = isPriceChartSettings ? viewsSettings.ExternalDataSettings[listId][periodicity].priceChart.children : viewsSettings.ExternalDataSettings[listId][periodicity].indicator.children;
                each(data.children, (obj, key) => {
                    map(settingsChildrenMenu, (sObj, skey) => {
                        if (skey === key) {
                            sObj.IsVisible = obj.IsVisible;
                        }
                    })
                })
            })

            // updating edited label same across the periodicities.            
            const latestData = editDialogMenu[majorPeriodicity].children;
            const listInfo = viewsSettings.ExternalDataSettings[listId];
            each(latestData, (obj, esField) => {
                each(listInfo, (data) => {
                    // updating price chart settings
                    map(data.priceChart.children, (item, key) => {
                        if (esField === key) {
                            item.Label = obj.Label;
                            item.Color = obj.Color;
                        }
                    });
                    // updating indicator pane settings
                    map(data.indicator.children, (item, key) => {
                        if (esField === key) {
                            item.Label = obj.Label;
                            item.Color = obj.Color;
                        }
                    });
                });
            });
        }

        if (!StringUtil.isEmpty(editDialogAdvancedMenu)) {
            let esfields = '';
            each(editDialogAdvancedMenu, (obj, key) => {
                map(settingsAdvancedMenu, (sObj, skey) => {
                    if (key === skey) {
                        sObj.IsVisible = obj.IsVisible;
                        if (obj.IsVisible) {
                            esfields += `${key};`;
                        }
                    }
                })
            })

            // request to get selected advanced settings data
            const endDate = timeLine?.dates ? moment(timeLine.dates[0].Date).format('YYYYMMDD') : moment(ConsoleStore.getStates().endDate).format('YYYYMMDD');
            const beginDate = moment(ConsoleStore.getStates().beginDate).format('YYYYMMDD');
            const msId = DataGraphStore.getState().SymbolInfo.MsId;
            const periodicity = PeriodicityType[majorPeriodicity];
            const result = yield call(getDataFromApi, listId, msId, beginDate, endDate, '', '', '', esfields, periodicity);

            if (result){
                yield fork(populateAdvancedData, result, listId);
            }

        }
        if(!isPriceChartSettings){
            yield put({
                type: ActionTypes.UPDATE_EXTERNAL_INDICATOR,
                indicatorListId: listId
            })
        }
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, saveToSettings ${error}`);
    }
}

function* updateRipanelWithNewLabel(listId, modifiedData) {
    try {
        const riPanelData = yield select(getRiPanelData);
        let modifiedRowInRiPanel = null;

        map(riPanelData, (item) => {
            if (parseFloat(item.listId.low) === parseFloat(listId)) {
                map(item.metrics, (info) => {
                    each(modifiedData, (data, key) => {
                        if (info.esFieldName === key) {
                            info.label = data.Label;
                            info.metricName = data.Name;
                        }
                    });
                });
                modifiedRowInRiPanel = item.metrics;
            }
        });

        yield fork(updatePDRwithNewLabel, modifiedRowInRiPanel, listId);

        yield put({
            type: ActionTypes.UPDATE_RI_PANEL_DATA,
            riPanelData: riPanelData,
            loading: false
        });
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, updateRipanelWithNewLabel ${error}`);
    }
}

function* updatePDRwithNewLabel(modifiedRowInRiPanel, listId) {
    try {
        const PDR_info = yield select(getPDRinfo);

        each(modifiedRowInRiPanel, (item) => {
            map(PDR_info.headerData, (info) => {
                if (parseFloat(info.listId) === parseFloat(listId) && info.esField === item.esFieldName) {
                    info.metric = item.metricName;
                }
            });
        });

        yield put({
            type: ActionTypes.UPDATE_PDR_TO_REDUCER,
            PDR_info: PDR_info
        });
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, updatePDRwithNewLabel ${error}`);
    }
}

function* handleHideModal({ isSaveObject, editDialogMenu, listId, editDialogAdvancedMenu, shouldHoldOnUserEvents }) {
    try {
        if (shouldHoldOnUserEvents !== null) {
            // disabled user activities and adding loading icon
            yield put({
                type: ActionTypes.HOLD_ON_USER_EVENTS,
                shouldHoldOnUserEvents: shouldHoldOnUserEvents
            });
        }

        if (isSaveObject) {
            yield call(saveToSettings, editDialogMenu, listId, editDialogAdvancedMenu);

            // enabled user activities and removing loading icon
            yield put({
                type: ActionTypes.HOLD_ON_USER_EVENTS,
                shouldHoldOnUserEvents: !shouldHoldOnUserEvents
            });
        }

        yield put({
            type: ActionTypes.CLOSE_EXTERNAL_DATA_EDIT_DIALOG,
            isShowModal: false
        });
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, handleHideModal ${error}`);
    }
}

function* initExternalData({ item, isPriceChartSettings }) {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const timeLine = yield select(timeLineSelect);
        const ExternalDataSettings = viewsSettings.ExternalDataSettings;
        // seeking updated data (overwritten data) on each time edit window is opened
        yield call(getCurrentListData, {
            listId: item.listId,
            lastnode: 0,
            timeLine: timeLine.dates,
            scale: '',
            minPrice: 0,
            maxPrice: 0,
            chartHeight: 0,
            padding: 0,
            isPriceChartView: false,
            isCalRequired: false
        });

        const deserializedAdvancedData = {};
        const advancedData = clone(ExternalDataSettings[item.listId][majorPeriodicity].AdvancedData);
        map(advancedData, (value, esField) => {
            extend(deserializedAdvancedData, { [esField]: clone(value) });
        });

        const periodicityData = clone(ExternalDataSettings[item.listId]);
        const deserializedEditMenu = {};
        map(periodicityData, (data, periodicity) => {
            const childrenData = {};
            const info = isPriceChartSettings ? data.priceChart : data.indicator;
            map(info, (item, key) => {
                if (key === 'children') {
                    map(item, (value, child) => {
                        extend(childrenData, { [child]: clone(value) });
                    });
                }
            })
            extend(deserializedEditMenu, {
                [periodicity]: { ['children']: childrenData }
            });
        });

        yield put({
            type: ActionTypes.OPEN_EXTERNAL_DATA_EDIT_DIALOG,
            item: item,
            editDialogMenu: deserializedEditMenu,
            editDialogAdvancedMenu: deserializedAdvancedData,
            isPriceChartSettings: isPriceChartSettings
        });
    }
    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, initExternalData ${error}`);
    }
}

function* clearData({ isClearAll, listId = '', /* isShowToggleButton */ shouldUpdateToStore = false, isPeriodicityChanged = false }) {
    try{
    const timeSeriesData = yield select(getTimeSeriesData_pricePane);
    const rawTimeSeriesData = yield select(getRawTimeSeriesData_pricePane);
    const eventSeriesData = yield select(getEventSeriesData);
    const eventSeriesData_AllPoints = yield select(getEventSeriesData_AllPoints);
    const { viewsSettings } = yield select(getDatagraphStates)

    if (isClearAll) {
        // clearing state data on symbol change
        yield call(updateEventsPosition, {eventSeriesData: {}, eventSeriesData_AllPoints: {}}); // Removing true, bcz get new data from api on every symbol change / periodicity switch
        //updateTimeSeriesDataOfPricePane({}, {}, false);   out of scope for current release - JAN 15

        // yield put({
        //     type: ActionTypes.UPDATE_TIME_SERIES_DATA_OF_PRICE_PANE,
        //     timeSeriesData_pricePane: {},
        //     rawTimeSeriesData_pricePane: {},
        //     isShowExternalDataPointer: false
        // });

        updateTimeSeriesDataOfIndicatorPane();

        yield put({
            type: ActionTypes.UPDATE_CURRENT_SETTINGS_OBJECT,
            settings: isPeriodicityChanged ? viewsSettings : {},
            settingsObjectIsUpdated: false
        });

        yield put({
            type: ActionTypes.UPDATE_TIME_SERIES_DATA_OF_INDICATOR_PANE,
            timeSeriesData_indicatorPane: {},
            timeSeriesData_indicatorPane_AllPoints: []
        });

        yield put({
            type: ActionTypes.UPDATE_RI_PANEL_DATA,
            riPanelData: {},
            loading: true
        });

        yield put({
            type: ActionTypes.UPDATE_PDR_TO_REDUCER,
            PDR_info: { ['headerData']: [] }
        });
    }
    else {
        if (timeSeriesData[listId] && rawTimeSeriesData[listId]) {
            delete timeSeriesData[listId];
            delete rawTimeSeriesData[listId];
        }
        else if (eventSeriesData[listId] && eventSeriesData_AllPoints[listId]) {
            delete eventSeriesData[listId];
            delete eventSeriesData_AllPoints[listId];
            if (shouldUpdateToStore) {
                yield call(updateEventsPosition, { eventSeriesData, eventSeriesData_AllPoints });
            }
        }
        // if (size(timeSeriesData) <= 1 && size(rawTimeSeriesData) < 1) {
        //     isShowToggleButton = false;
        // }
        // updateTimeSeriesDataOfPricePane(timeSeriesData, rawTimeSeriesData, isShowToggleButton);  out of scope for current release - JAN 15

        // yield put({
        //     type: ActionTypes.UPDATE_TIME_SERIES_DATA_OF_PRICE_PANE,
        //     timeSeriesData_pricePane: timeSeriesData,
        //     rawTimeSeriesData_pricePane: rawTimeSeriesData,
        //     isShowExternalDataPointer: isShowExternalDataPointer
        // });
    }
    }    catch (error) {
        console.log(`Error occurs in  Price Pane - externalData.js, clearData ${error}`);
    }
}

function* updateToSettings({ listId, isRename, isDelete, isUpload, name, listType }) {
    const settings = SettingsStore.getConsoleSettings();
    const { viewsSettings } = yield select(getDatagraphStates);
    const ExternalDataSettings = viewsSettings.ExternalDataSettings;
    const PDR_info = yield select(getPDRinfo);

    if (settings) {
        settings.isUploadedExternalData = true;
    }

    if (ExternalDataSettings && ExternalDataSettings[listId]) {
        if (isDelete) {
            delete ExternalDataSettings[listId];

            // deleting entries from PDR
            if (!StringUtil.isEmpty(PDR_info.headerData)) {
                for (let i = 0; i < PDR_info.headerData.length; i++) {
                    const info = PDR_info.headerData[i];
                    if (parseFloat(info.listId) === parseFloat(listId)) {
                        PDR_info.headerData.splice(i, 1);
                        i--;
                    }
                }

                PDR_info.headerData.length > 0 && extend(PDR_info, { recentRow: PDR_info.headerData[0].sequenceNo });

                yield put({
                    type: ActionTypes.UPDATE_PDR_TO_REDUCER,
                    PDR_info: PDR_info
                });
            }
        }
        else {
            map(ExternalDataSettings[listId], (data) => {
                if (isRename) {
                    return (data.Header = name);
                }
                else if (isUpload) {
                    data.listType = listType;
                    data.isDateBasedEvents = listType === BaseServiceApi.rayData["ListType"].EVENTSERIES_List;
                    data.isLastUploaded = true;
                    return data;
                }
            })
        }
    }
    else if (isDelete && isArray(listId)) {
        for (let index = 0; index < listId.length; index++) {
            if (ExternalDataSettings && ExternalDataSettings[listId[index]]) {
                delete ExternalDataSettings[listId[index]];

                for (let i = 0; i < PDR_info.headerData.length; i++) {
                    const info = PDR_info.headerData[i];
                    if (parseFloat(info.listId) === parseFloat(listId)) {
                        PDR_info.headerData.splice(i, 1);
                        i--;
                    }
                }
            }
        }

        PDR_info.headerData.length > 0 && extend(PDR_info, { recentRow: PDR_info.headerData[0].sequenceNo });

        yield put({
            type: ActionTypes.UPDATE_PDR_TO_REDUCER,
            PDR_info: PDR_info
        });
    }

    if(isUpload) {
        yield put({
            type: ActionTypes.UPDATE_CURRENT_SETTINGS_OBJECT,
            settings: viewsSettings,
            settingsObjectIsUpdated: false
        });
    }
}

function* handleInputValueChange({ event, index, esFieldName }) {
    const riPanelData = yield select(getRiPanelData);

    map(riPanelData[index].metrics, (info) => {
        if (info.esFieldName === esFieldName) {
            info.label = event.target.value;
        }
    });

    yield put({
        type: ActionTypes.UPDATE_RI_PANEL_DATA,
        riPanelData: riPanelData,
        loading: false
    });
}

function* handleSaveLabel({ newLabel, esField, listId }) {
    const { viewsSettings } = yield select(getDatagraphStates)
    const riPanelSettings = viewsSettings.ExternalDataRiPanelSettings[listId];
    map(riPanelSettings, (obj, key) => {
        if (esField === key) {
            obj.label = newLabel;
        }
    });

    // Updating new label to price chart and indicator pane settings
    const listInfo = viewsSettings.ExternalDataSettings[listId];

    for (const info in listInfo) {
        const data = listInfo[info];

        if (data.indicator.children === "") {
            yield call(getCurrentListData, {
                listId: listId,
                lastnode: 0,
                timeLine: null,
                scale: '',
                minPrice: 0,
                maxPrice: 0,
                chartHeight: 0,
                padding: 0,
                isPriceChartView: false,
                isCalRequired: false
            });
        }

        // updating price chart settings ------ out of scope for current release - JAN 15
        // each(data.priceChart.children, (obj, key) => {
        //     if (esField === key) {
        //         obj.Label = newLabel;
        //     }
        // });

        // updating indicator pane settings
        each(data.indicator.children, (obj, key) => {
            if (esField === key) {
                obj.Label = newLabel;
            }
        });
    }

    let headers = '';
    map(riPanelSettings, (item, key) => {
        headers += `${key}:${item.label};`
    });

    yield call(ExternalDataUploadApi.updatelabel, listId, headers);    // time series - request to save edited label 
}

function overWriteSettingsOnReUpload({ listId }) {
    const settings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
    const externalDataInStock = settings.StockViewSettings.ExternalDataSettings;
    const externalDataInETF = settings.ETFViewSettings.ExternalDataSettings;
    const externalDataInCEF = settings.CEFViewSettings.ExternalDataSettings;
    const externalDataInIndex = settings.IndexViewSettings.ExternalDataSettings;
    const externalDataInFunds = settings.FUNDViewSettings.ExternalDataSettings;
    const externalDataInCurrency = settings.CurrencyViewSettings.ExternalDataSettings;
    const externalDataInCrypto = settings.CryptoCurrencyViewSettings.ExternalDataSettings;
    const externalDataInIG = settings.IndustryGroupViewSettings.ExternalDataSettings;
    const externalDataInCS = settings.CashSpotsViewSettings.ExternalDataSettings;
    const externalDataInFutures = settings.FuturesViewSettings.ExternalDataSettings;

    if (externalDataInStock[listId]) {
        hasDataBeenOverwritten = true;
        //delete externalDataInStock[listId];
    }
    if (externalDataInETF[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInETF[listId];
    }
    if (externalDataInCEF[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInCEF[listId];
    }
    if (externalDataInIndex[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInIndex[listId];
    }
    if (externalDataInFunds[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInFunds[listId];
    }
    if (externalDataInCurrency[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInCurrency[listId];
    }
    if (externalDataInCrypto[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInCrypto[listId];
    }
    if (externalDataInIG[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInIG[listId];
    }
    if (externalDataInCS[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInCS[listId];
    }
    if (externalDataInFutures[listId]) {
        hasDataBeenOverwritten = true;
        // delete externalDataInFutures[listId];
    }
}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchGetExternalDataSubMenu() {
    yield takeEvery(ActionTypes.GET_EXTERNAL_DATA_SUB_MENU, getExternalDataSubMenu);
}

export function* watchGetCurrentListData() {
    yield takeEvery(ActionTypes.GET_CURRENT_LIST_DATA, getCurrentListData)
}

export function* watchRedrawOnChartResize() {
    yield takeEvery(ActionTypes.REDRAW_ON_CHART_RESIZE, reDrawChart)
}

export function* watchHideModalDialog() {
    yield takeLatest(ActionTypes.HIDE_MODAL_DIALOG, handleHideModal);
}

export function* watchInitExternalData() {
    yield takeLatest(ActionTypes.INIT_EXTERNAL_DATA, initExternalData)
}

export function* watchDrawFundamentalLines() {
    yield takeEvery(ActionTypes.DRAW_FUNDAMENTAL_DATA_LINES, drawFundamentalLines)
}

export function* watchClearStateData() {
    yield takeEvery(ActionTypes.CLEAR_TIME_SERIES_DATA, clearData)
}

export function* watchUpdateToSettings() {
    yield takeLatest(ActionTypes.UPDATE_TO_SETTINGS, updateToSettings)
}

export function* watchGetRiPanelData() {
    yield takeLatest(DataGraphConstants.ActionTypes.PERIODICITY_CHANGE, populateRiPanelData)
}

export function* watchHandleInputChange() {
    yield takeLatest(ActionTypes.HANDLE_INPUT_VALUE_CHANGE, handleInputValueChange)
}

export function* watchSaveLabel() {
    yield takeLatest(ActionTypes.SAVE_LABEL, handleSaveLabel)
}

export function* watchOverWriteSettingsOnReUpload() {
    yield takeLatest(ActionTypes.OVERWRITE_SETTINGS_ON_REUPLOAD, overWriteSettingsOnReUpload)
}

export function* watchProcessListData() {
    yield takeLatest(DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY, processListData)
}