import React, { Component } from 'react';
import ScrollBar from "ScrollBar";
import Universe from '../../../../../RayCustomControls/ScheduledLists/Universe';
import { connect } from 'react-redux';
import onClickOutside from "react-onclickoutside";
import listExplorerStore from '../../../../../Stores/NavModules/NavList/Explorer/ListExplorerStore';
import { onClickListAdd, setAddListModalShowStatus, setAddListName } from 'Actions/ComparisonAndRotationMenuActions';
import BaseServiceApi from 'BaseServiceApi';
const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
import CompareTabType from "CompareTabType";

class AddListModal extends Component {

  constructor(props) {
    super(props);
  }
  handleClickOutside() {
    try {
      const showStatus = this.props.addListModalShowStatus;
      if (showStatus)
        this.props.setAddListModalShowStatus(false);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  renderAddListPopup(source) {
    return (
      <React.Fragment>
        <div className="compare-add-list">
          <p>Select a list</p>
          {/* {this.props.storeSymbolsSize > 0 ?
            <div className="schedule-check">
              <input id="replaceSymbols-checkbox" type="checkbox" defaultChecked />
              <label htmlFor="replaceSymbols-checkbox">Replace Symbols</label>
            </div> : ''} */}
          <Universe type="Add List" source={source} onClickListAdd={this.props.onClickListAdd} />
        </div>
        <div className="clearfix"></div>
      </React.Fragment>
    );
  }
  filterLists(data) {
    const {selectedTab} = this.props;
    let ignoreListNames = [];
    switch (selectedTab){
      case CompareTabType.RotationGraph:
        ignoreListNames = ["Upcoming IPOs","Groups and Sectors", "O'Neil 197 Industry Groups", "Major Global Indices", "ETFs", "CryptoCurrency", "Currencies", "ETFs & ETNs", ];
        break;
      case CompareTabType.ComparisonGraph:
        ignoreListNames = ["CryptoCurrency"];
        break;
      default:
        break;
    }
    let fliteredItms = _.filter(data, (itm) => {
      if (itm.childNodes) itm.childNodes = this.filterLists(itm.childNodes);
      return !ignoreListNames.includes(itm.name);
    })
    return fliteredItms;
  }
  render() {

    const showStatus = this.props.addListModalShowStatus;

    let showAddListPopup = showStatus ? 'block' : 'none';
    let source = null;
    let loading = false;
    const ignoreListCategoryType = [ListCategoryType.MORGUELIST_ListCategory, ListCategoryType.MODELLIST_ListCategory, ListCategoryType.FAVORITE_ListCategory, ListCategoryType.SMARTLIST_ListCategory, ListCategoryType.DATABASELIST_ListCategory, ListCategoryType.SHAREDLIST_ListCategory, ListCategoryType.TEMPORARYLIST_ListCategory, ListCategoryType.NOTIMPORTEDLIST_ListCategory];
    if (listExplorerStore.getState().listExplorer.ONeil && listExplorerStore.getState().listExplorer.ONeil.length > 0) {
      let explorerLists = _.cloneDeep(listExplorerStore.getState().listExplorer.ONeil);
      // let explorerLists = JSON.parse(JSON.stringify(listExplorerStore.getState().listExplorer.ONeil));
      source = _.filter(explorerLists, (item) => !ignoreListCategoryType.includes(item.categoryType));
    }
    source = this.filterLists(source);
    let addListData = showStatus ? this.renderAddListPopup(source) : '';
    return (
      <div className='geo-graphy-list' ref={(ref) => this.symbolListRef = ref} style={{ display: showAddListPopup }}>
        <div className="custom-panel-block recommended-block">
          <div className="panel panel-default">
            <div className="panel-heading">
              <span className="panel-title cap-header">ADD LIST</span>
            </div>
            <div className="panel-body">
              <div className="inner-panel-body custom-scroll-light">
                <div className="custom-scroll">
                  <div className="geography-list-vertical-scroll" id='geographyListVerticalScroll'>
                    {(loading ? <div className="group-box"><div className="loading loading-light"></div></div> : addListData)}
                    <ScrollBar scrollId="geographyListVerticalScroll" vScroll={true} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  onClickListAdd: (item) => dispatch(onClickListAdd(item)),
  setAddListModalShowStatus: (status) => dispatch(setAddListModalShowStatus(status)),
  setAddListName: (listName) => dispatch(setAddListName(listName)),
  setOpenGeographyStatus: (status) => dispatch(setOpenGeographyStatus(status))
})

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const symbolsMenu = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
  const compChartListItem = state.comparisonGraph.compChartListItem;

  const { addListModalShowStatus } = selectedTab === CompareTabType.ComparisonGraph ? symbolsMenu.ComparisonMenuStates : symbolsMenu.RotationMenuStates;
  const storeSymbolsSize = selectedTab === CompareTabType.ComparisonGraph ? compChartListItem.SymbolsData.length : symbolsMenu.RotationMenuStates.listItems.length

  return { addListModalShowStatus, storeSymbolsSize, selectedTab };
};

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(AddListModal));