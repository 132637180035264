import Serializable from "Serializable";
import { CompareMenuType } from "CompareTabType";
import RotationGroupsMenuSettings from './RotationGroupsMenuSettings';
import RotationSymbolsMenuSettings from './RotationSymbolsMenuSettings';

class TabRotationGraphSettings extends Serializable {
  constructor() {
    super();
  }

  onSeralisedInfoRecieved(serializedValue) {
    this.rotationMenuTab = (serializedValue.rotationMenuTab != undefined && serializedValue.rotationMenuTab != null) ? serializedValue.rotationMenuTab : this.getDefaultRotationMenuTab();
    this.isRotationMenuPanelOpen = serializedValue.isRotationMenuPanelOpen !== '' ? serializedValue.isRotationMenuPanelOpen : this.getDefaultIsRotationMenuPanelOpen();
    this.rotationGroupsMenuSettings = serializedValue.rotationGroupsMenuSettings ? serializedValue.rotationGroupsMenuSettings : this.getDefaultRotationGroupsMenuSettings();
    this.rotationSymbolsMenuSettings = serializedValue.rotationSymbolsMenuSettings ? serializedValue.rotationSymbolsMenuSettings : this.getDefaultRotationSymbolsMenuSettings();
  }

  getDataObject() {
    return {
      rotationMenuTab: this.rotationMenuTab,
      isRotationMenuPanelOpen: this.isRotationMenuPanelOpen,
      rotationGroupsMenuSettings: this.rotationGroupsMenuSettings,
      rotationSymbolsMenuSettings: this.rotationSymbolsMenuSettings
    };
  }

  getObjectMapKey() {
    return "TabRotationGraphSettings";
  }

  getDefaultTabRotationGraphSettings() {
    this.rotationGroupsMenuSettings = this.getDefaultRotationGroupsMenuSettings();
    this.rotationSymbolsMenuSettings = this.getDefaultRotationSymbolsMenuSettings();
    this.isRotationMenuPanelOpen = this.getDefaultIsRotationMenuPanelOpen();
    this.rotationMenuTab = this.getDefaultRotationMenuTab();
    return this;
  }

  getDefaultRotationMenuTab() {
    return CompareMenuType.Symbols;
  }

  getDefaultRotationGroupsMenuSettings() {
    let tabRotationGroupsMenuSettings = new RotationGroupsMenuSettings();
    return tabRotationGroupsMenuSettings.getDefaultRotationGroupsMenuSettings();
  }

  getDefaultRotationSymbolsMenuSettings() {
    let tabRotationSymbolsMenuSettings = new RotationSymbolsMenuSettings();
    return tabRotationSymbolsMenuSettings.getDefaultRotationSymbolsMenuSettings();
  }

  getDefaultIsRotationMenuPanelOpen() {
    return true;
  }
  
}

export default TabRotationGraphSettings;
