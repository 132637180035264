class IndexLine {
    IndexLineChart(xAxis, hsfResults, height, scale, lastNode, nodeWidth=4) {
        const prcLength = hsfResults.length;
        const chartData = new Array(prcLength);
        if (hsfResults.length < 1) {
            return chartData;
        }
        let x = 0;

        for (let j = lastNode; j < prcLength; j++) {
            if (xAxis < 0) break;
            const yPrice = scale.ComputeY(hsfResults[j].Close, height);
            const info = {
                Date: hsfResults[j].Date,
                yPrice: yPrice,
                xAxis: xAxis,
                price: hsfResults[j].Close
            };
            xAxis -= nodeWidth;
            chartData[x] = info;
            x++;
        }

        return chartData;
    }
}

const indexLine = new IndexLine();
export default indexLine;
