import { ComparisonGraph } from "Constants/ComparisonGraph";
import { ScaleType } from "../../../Models/ComparisonChartModels/GraphPanelModelStates";
import { SettingsConstants } from "Constants/SettingsConstants.js";
const { ActionTypes } = ComparisonGraph;
import LocalizationStore from "LocalizationStore";
import GridLineUtil from "GridLineUtil";
import CompareTabType,{CompareMenuType} from "CompareTabType";
import { extend, map } from "underscore";
extend(ActionTypes, SettingsConstants.ActionTypes);

const initData = {
  isLoading: true,
  isChartContextMenuOpen: false,
  toolTip: {
    isShowToolTip: false,
    toolTipText: "",
    pos: {
      x: 0,
      y: 0
    }
  },
  lastClicked: null,
  lastHighlightedOsids: [],
  isNotVisibleOsids: [],
  contextManu: {
    isShowContextMenu: false,
    contextMenuData: [
      {
        displayText: 'Delete',
        actionType: 'delete',
        isShow: false
      },
      {
        displayText: 'Rename',
        actionType: 'rename',
        isShow: false
      }
    ],
    pos: {
      x: 0,
      y: 0
    },
    contextItem: null
  },
  scaleTypeText: LocalizationStore.getTranslatedData("ch_LIN", "LIN"),
  scaleBaseText: 'Base=100',
  isClearAllDialogOpen: false,
  verticalGridData: [],
  resqustSymbol: [],
  mhLines: [],
  calenderLabels: null,
  isShowProgressBar: false,
  per: 0
}

const initialState = {
  chartDimension: {
    width: 0,
    height: 0
  },
  gridLineColor: GridLineUtil.getGridLineColor(),
  selectedMenuTab : CompareMenuType.Symbols,
  SymbolsData : {...initData},
  GroupsData : {...initData},
  selectedTab : null
}

const compareChart = (state = initialState, action) => {

  const setStateData = (obj) => {

    if (state.selectedMenuTab === CompareMenuType.Symbols) {
      map(obj, (value, property) => state.SymbolsData[property] = value);
    }
    else {
      map(obj, (value, property) => state.GroupsData[property] = value);
    }

    return { ...state };
  }

  
  const getStateData = () => {
    if (state.selectedMenuTab === CompareMenuType.Symbols) {
       return state.SymbolsData ;
    }
    else {
      return state.GroupsData ;
    }
}

  switch (action.type) {
  case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
      return {
        ...state,
        selectedTab: action.selectedTab
      }

    case ActionTypes.SET_COMPARE_MENUTAB:
      if (state.selectedTab === CompareTabType.ComparisonGraph) {
        return {
          ...state,
          selectedMenuTab: action.menuTab
        }
      } else {
        return { ...state }
      }

    case ActionTypes.INIT_FROM_SETTINGS_DATA:
      const { scaleBased, scaleType } = action.settingsData;

      state.gridLineColor = GridLineUtil.getGridLineColor();

      return setStateData({
        contextManu: {
         ...getStateData().contextManu,
          contextMenuData: [
            {
              displayText: LocalizationStore.getTranslatedData('delete', 'Delete'),
              actionType: 'delete',
              isShow: false
            },
            {
              displayText: LocalizationStore.getTranslatedData('rename', 'Rename'),
              actionType: 'rename',
              isShow: false
            },
            {
              displayText: LocalizationStore.getTranslatedData('view symbol', 'View Symbol'),
              actionType: 'View Symbol',
              isShow: false
            },
            {
              displayText: LocalizationStore.getTranslatedData('view constituents', 'View Constituents'),
              actionType: 'View Constituents',
              isShow: false
            }
          ]
        },
        scaleBaseText: `${LocalizationStore.getTranslatedData("CCG_Base", "Base")}=${scaleBased}`,
        scaleTypeText: scaleType === ScaleType.Lin ? LocalizationStore.getTranslatedData("ch_LIN", "LIN") : LocalizationStore.getTranslatedData("ch_logauto", "LOG (Auto)"),
      });

    case ActionTypes.ON_DIMENSION_CHANGE_SUCSESS:
      return {
        ...state,
        chartDimension: action.dimension,
        nodeWidth: action.dimension.width > 0 && getStateData().numOfChartNodes > 0 ? (action.dimension.width / (getStateData().numOfChartNodes - 1)) : 0
      }
      
    case ActionTypes.TOGGLE_TOOL_TIP:
      const { toolTipText, pos, isShowToolTip } = action.toolTip;
     return setStateData({
        toolTip: {
          ...getStateData().toolTip,
          isShowToolTip: isShowToolTip,
          toolTipText: toolTipText,
          pos: pos
        }
      });

    case ActionTypes.HANDLE_HIGHLIGHT_SUCCESS:
    return setStateData({
        lastClicked: action.lastHighlightedOsids,
        lastHighlightedOsids: action.lastHighlightedOsids,
        lastClickedItem: action.lastClicked
      });

    case ActionTypes.SET_MH_LINES:
     return setStateData({
        mhLines: action.mhLines
      });

    case ActionTypes.SET_CALENDAR_LABELS:
     return setStateData({
        calenderLabels: action.calenderLabels
      });

    case ActionTypes.SET_VERTICAL_LINES:
     return setStateData({
        verticalGridData: action.verticalGridData
      });

    case ActionTypes.CHANGE_SCALE_BASE_VALUE_SUCCESS:
     return setStateData({
        scaleBaseText: `${LocalizationStore.getTranslatedData("CCG_Base", "Base")}=${action.scaleBasedValue}`
      });
      
    case ActionTypes.CHANGE_SCALE_TYPE_VALUE_SUCCESS:
      return setStateData({
        scaleTypeText: action.scaleType === ScaleType.Lin ? LocalizationStore.getTranslatedData("ch_LIN", "LIN") : LocalizationStore.getTranslatedData("ch_logauto", "LOG (Auto)")
      });

    case ActionTypes.DISPLAY_PROGRESS_BAR:
     return setStateData({
        isShowProgressBar: action.isShowProgressBar
      });

    case ActionTypes.UPDATE_PROGRESS_BAR_PERCENTAGE:
     return setStateData({
        per: action.per
      });

    case ActionTypes.GRID_LINE_COLOR_CHANGE:

     return {
       ...state,
       gridLineColor: GridLineUtil.getGridLineColor()
     }

    case ActionTypes.HANDLE_ERROR:
    return setStateData({
        isLoading: false
      });

    default:
      return state
  }
}

export default compareChart;