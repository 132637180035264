export async function LogError(URL, jsonBody) {
    if (process.env.NODE_ENV === 'production') {
        fetch(URL, {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: jsonBody,
        }).then((result) => result.json())
            .catch((error) => console.log('error on Logging error', error));
    }

}