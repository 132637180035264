import { AnnotationConstant } from "../../../../Constants/AnnotationConstants.js";
import AnnotationsApi from "../../../../ServiceApi/Apis/AnnotationsApi";
import AnnotationUtil from '../../../../Utils/AnnotationUtil.js';
import BaseServiceApi from 'BaseServiceApi';
import BrowserUtil from '../../../../Utils/BrowserUtil.js';
import DatagraphHelper from "../../../../Utils/DatagraphHelper";
import { getAnnotationApiData } from './AnnotationInitModel';
import { getLayerManagementReducerState } from "../../../../Reducers/AnnotationReducers/selectors";
import { safe } from '../../../ErrorModel';
import Serializable from "Serializable";
import SettingsStore from "SettingsStore";
import { ShareActionConstants } from '../../../../Constants/ShareConstants.js';
import StringUtil from 'StringUtil';
import { updateStorewithSharedData } from '../../../../Actions/ShareAction.js';
import { find, map, extend, each, findWhere, findIndex } from 'underscore';
import { put, takeLatest, select, call, delay } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;
const layerProto = BaseServiceApi.rayData["AnnotationLayerData"];
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

export function* processAnnotationLayerData({ layerData }) {
    try {
        let settings = SettingsStore.getConsoleSettings();
        settings = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;
        if(!settings){
            return;
        }
        const LayersList = [];
        const LayersDict = settings.LayerSettings;
        const selectedLayerID = settings.selectedLayerID;
        const dictExisted = !StringUtil.isEmpty(LayersDict);
        const activeLayerIDs = [];

        each(layerData, (layer) => {
            activeLayerIDs.push(layer.layerID);

            let newLayer = {};
            if (dictExisted && !StringUtil.isEmpty(LayersDict[layer.layerID])) {
                newLayer = LayersDict[layer.layerID];
                newLayer.isVisible = LayersDict[layer.layerID].isVisible;
                newLayer.zIndex = LayersDict[layer.layerID].zIndex;
            }
            else {
                newLayer.zIndex = -1;
                newLayer.isVisible = true;

                extend(LayersDict, { [layer.layerID]: Object.assign(new Serializable(), newLayer) });
            }

            LayersList.push({
                ...newLayer,
                isSelected: false,
                isNewLayer: false,
                layerID: layer.layerID,
                layerName: layer.layerName,
                isSharedLayer: layer.isSharedRecip,
                hasBeenShared: layer.isSharedOwn,
                shareAccess: layer.shareAccess,
                ownerName: layer.ownerName !== null ? layer.ownerName : '',
                recipients: layer.recipNames
            });
        });

        if (dictExisted) {
            // Sort layers based on zIndex
            for (let i = 0; i < LayersList.length; i++) {
                for (let j = i + 1; j < LayersList.length; j++) {
                    if (LayersList[i].zIndex > LayersList[j].zIndex) {
                        if (LayersList[i].zIndex !== -1 && LayersList[j].zIndex !== -1) {
                            const temp = LayersList[i];
                            LayersList[i] = LayersList[j];
                            LayersList[j] = temp;
                        }
                    }
                }
            }
        }
        else {
            // Sort layers based on names
            for (let i = 0; i < LayersList.length; i++) {
                for (let j = i + 1; j < LayersList.length; j++) {
                    if (LayersList[i].layerName.localeCompare(LayersList[j].layerName) > 0) {
                        const temp = LayersList[i];
                        LayersList[i] = LayersList[j];
                        LayersList[j] = temp;
                    }
                }
            }
        }

        yield call(updateZIndex, LayersList, LayersDict);

        let targetLayer = null;
        if (!dictExisted) {
            LayersList[0].isSelected = true;  // by default last created layer will be set as active.
            targetLayer = LayersList[0];
        }
        else {
            targetLayer = find(LayersList, (item) => item.layerID === selectedLayerID);

            /* If the target layer is deleted from desktop then top layer is selected as target */
            if (StringUtil.isEmpty(targetLayer)) {
                LayersList[0].isSelected = true;
                targetLayer = LayersList[0];
            }
            else {
                targetLayer.isSelected = true;
            }
        }

        yield put({
            type: ActionTypes.UPDATE_LAYER_INFO_SUCCESS,
            LayersList: [...LayersList],
            selectedLayer: targetLayer
        });

        /* update layers deleted from desktop/web */
        yield safe(call(updateDeletedLayers, activeLayerIDs, LayersDict), "AnnotationLayerManagementSaga.js", "updateDeletedLayers");

        settings.selectedLayerID = targetLayer.layerID;

        SettingsStore.saveSettings();
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, processAnnotationLayerData ${error}`);
    }
}

function updateDeletedLayers(activeLayerIDs, LayersDict) {
    map(LayersDict, (item, layerID) => {
        if (activeLayerIDs.indexOf(parseFloat(layerID)) === -1) {
            delete LayersDict[layerID];
        }
    });
}

function updateZIndex(LayersList, LayersDict) {
    try {
        each(LayersList, (item, index) => {
            LayersDict[item.layerID].zIndex = index;
            item.zIndex = index;
        });
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, updateZIndex ${error}`);
    }
}

function* processCreateNewLayer(response) {
    let settings = SettingsStore.getConsoleSettings();
    settings = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;
    const LayersDict = settings.LayerSettings;
    let { LayersList, selectedLayer } = yield select(getLayerManagementReducerState);
    const newLayer = {};

    /* unselect the last selected layer and hence to make only newly added layer as selected */
    findWhere(LayersList, { layerID: selectedLayer.layerID }).isSelected = false;

    newLayer.zIndex = -1;
    newLayer.isVisible = true;

    /* Adding new layer to settngs object */
    extend(LayersDict, { [response.layerID]: Object.assign(new Serializable(), newLayer) });
    settings.selectedLayerID = response.layerID;
    SettingsStore.saveSettings();

    newLayer.isSelected = true;  // newly added layer will become selective by default
    newLayer.isNewLayer = true;
    newLayer.layerID = response.layerID;
    newLayer.layerName = response.layerName;
    newLayer.isSharedLayer = false;
    newLayer.hasBeenShared = false;
    newLayer.shareAccess = 0;
    newLayer.ownerName = "";
    newLayer.recipients = "";

    /* Add new layer to layer list */
    if (findWhere(LayersList, { layerID: newLayer.layerID }) === undefined) {
        LayersList = [newLayer, ...LayersList];
    }

    yield call(updateZIndex, LayersList, LayersDict);

    yield put({
        type: ActionTypes.UPDATE_LAYER_INFO_SUCCESS,
        LayersList: [...LayersList],
        selectedLayer: newLayer
    });
}

function* AddNewLayer() {
    try {
        const response = yield AnnotationsApi.UpsertAnnotationLayer(-1, "", false, false, "Layer ", " Copy");

        yield safe(call(processCreateNewLayer, response), "AnnotationLayerManagementSaga.js", "processCreateNewLayer");

        yield safe(call(updateRawLayerDataResponse, response), "AnnotationLayerManagementSaga.js", "updateRawLayerDataResponse");
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, AddNewLayer ${error}`);
    }
}

function* duplicateLayer() {
    try {
        const { selectedLayer } = yield select(getLayerManagementReducerState);

        const response = yield AnnotationsApi.UpsertAnnotationLayer(selectedLayer.layerID, "", false, true, "Layer ", " Copy");

        yield safe(call(processCreateNewLayer, response), "AnnotationLayerManagementSaga.js", "processCreateNewLayer");
        yield safe(call(getAnnotationApiData), "AnnotationLayerManagementSaga.js", "getAnnotationApiData"); // invoke an api to get the annotations of duplicated layer and process it further to display on the screen

        yield safe(call(updateRawLayerDataResponse, response), "AnnotationLayerManagementSaga.js", "updateRawLayerDataResponse");
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, duplicateLayer ${error}`);
    }
}

function* shareLayer() {
    try {
        const { selectedLayer } = yield select(getLayerManagementReducerState);
        const toShare = selectedLayer;
        const sharedListData = {
            showSharedDialog: true,
            id: toShare.layerID,
            name: toShare.layerName,
            action: ShareActionConstants.SHARE_ANNOTATIONS,
            IncludeAllUsers: 0,
            ntid: "5"
        }
        updateStorewithSharedData(sharedListData);
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, shareLayer ${error}`);
    }
}

function* deleteLayer() {
    try {
        let settings = SettingsStore.getConsoleSettings();
        settings = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;
        const LayersDict = settings.LayerSettings;
        const { LayersList, selectedLayer } = yield select(getLayerManagementReducerState);

        const toDelete = selectedLayer;
        const selectedIndex = LayersList.findIndex((item) => item.layerID === toDelete.layerID);
        const nextTargetLayer = selectedIndex + 1 < LayersList.length ? LayersList[selectedIndex + 1] : LayersList[0];

        yield AnnotationsApi.UpsertAnnotationLayer(toDelete.layerID, toDelete.layerName, true);

        /* Prepare for undo */
        yield put({
            type: ActionTypes.SAVE_LAYER_FOR_UNDO,
            deletedLayer: LayersList[selectedIndex]
        });

        const alertMsg = `${toDelete.layerName}${toDelete.isSharedLayer ? ` (${toDelete.ownerName})` : ''} was deleted`;
        yield put({
            type: ActionTypes.SHOW_UNDO_ALERT,
            showAlert: true,
            deletedId: toDelete.layerID,
            alertMsg: alertMsg,
            isLayerDelete: true
        });

        /* Remove deleted layer from layerlist state */
        LayersList.splice(selectedIndex, 1);

        yield call(updateZIndex, LayersList, LayersDict);

        /* set next target layer in active mode */
        nextTargetLayer.isSelected = true;
        findWhere(LayersList, { layerID: nextTargetLayer.layerID }).isSelected = true;

        yield put({
            type: ActionTypes.UPDATE_LAYER_INFO_SUCCESS,
            LayersList: [...LayersList],
            selectedLayer: nextTargetLayer
        });

        /* Remove deleted layer from layer settings */
        if (LayersDict[toDelete.layerID]) {
            delete LayersDict[toDelete.layerID];
        }

        settings.selectedLayerID = nextTargetLayer.layerID;

        SettingsStore.saveSettings();
        BrowserUtil.enableEvents();

        yield delay(10000);
        yield put({
            type: ActionTypes.SHOW_UNDO_ALERT,
            showAlert: false,
            deletedId: '',
            alertMsg: '',
            isLayerDelete: false
        });
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, deleteLayer ${error}`);
    }
}

function* onFooterAction({ action }) {
    try {
        BrowserUtil.disableEvents();
        switch (action) {
            case 'add':
                yield call(AddNewLayer);
                break;

            case 'duplicate':
                yield call(duplicateLayer);
                break;

            case 'share':
                yield call(shareLayer);
                break;

            case 'delete':
                yield call(deleteLayer);
                break;

            default: break;
        }
        BrowserUtil.enableEvents();
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, onFooterAction ${error}`);
    }
}

function updateRawLayerDataResponse(response) {
    const annotationLayerDataProto = new layerProto();

    annotationLayerDataProto.layerID = response.layerID;
    annotationLayerDataProto.layerName = response.layerName;
    annotationLayerDataProto.listType = 5;
    annotationLayerDataProto.isSharedRecip = false;
    annotationLayerDataProto.ownerName = "";
    annotationLayerDataProto.shareAccess = ShareAccessType.SHARE_NOACCESS;
    annotationLayerDataProto.isSharedOwn = false;
    annotationLayerDataProto.recipNames = "";
    
    AnnotationUtil.layerData.push(annotationLayerDataProto);
}

function findDuplicateItem(LayersList, newName, id) {
    let duplicateNamedItem = null;

    duplicateNamedItem = find(LayersList, (item) => {
        if (item.layerID !== id && !StringUtil.isEmpty(item.layerName) && (item.layerName.localeCompare(newName) === 0)) {
            return item;
        }
    });

    if (duplicateNamedItem === undefined) {
        duplicateNamedItem = null;
    }
    return duplicateNamedItem;
}

function* onLayerItemRenamed({ newName }) {
    try {
        const { LayersList, selectedLayer } = yield select(getLayerManagementReducerState);
        newName = newName.trim();
        const copyOfNewName = newName;
        const apiResponse = AnnotationUtil.layerData;
        if (newName !== "" && selectedLayer.layerName.localeCompare(newName) !== 0) {
            let count = 0;

            let duplicateNamedItem = yield safe(call(findDuplicateItem, LayersList, newName, selectedLayer.layerID), "AnnotationLayerManagementSaga.js", "findDuplicateItem");

            while (duplicateNamedItem !== null) {
                count++;
                newName = `${copyOfNewName.trim()}(${count})`;
                duplicateNamedItem = yield safe(call(findDuplicateItem, LayersList, newName, selectedLayer.layerID), "AnnotationLayerManagementSaga.js", "findDuplicateItem");

            }

            const changedLayer = apiResponse.find((item) => item.layerID === selectedLayer.layerID);

            if (changedLayer) {
                changedLayer.layerName = newName;
            }
            // Update new name to reducer
            selectedLayer.layerName = newName;
            map(LayersList, (item) => {
                if (item.layerID === selectedLayer.layerID) {
                    item.layerName = newName;
                }
            });

            yield put({
                type: ActionTypes.UPDATE_LAYER_INFO_SUCCESS,
                LayersList: [...LayersList],
                selectedLayer: Object.assign({}, selectedLayer),
            });

            // Update new name to DB
            yield AnnotationsApi.UpsertAnnotationLayer(selectedLayer.layerID, newName);
        }
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, onLayerItemRenamed ${error}`);
    }
}

function* handleRelocateLayer({ dragId, dropId }) {
    try {
        const { LayersList, selectedLayer } = yield select(getLayerManagementReducerState);
        let settings = SettingsStore.getConsoleSettings();
        settings = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;
        const LayersDict = settings.LayerSettings;

        const dragIndex = findIndex(LayersList, { layerID: dragId });
        const dropIndex = findIndex(LayersList, { layerID: dropId });

        if (dragIndex !== -1 && dropIndex !== -1 && dragIndex !== dropIndex) {
            const dragElm = LayersList.splice(dragIndex, 1);
            LayersList.splice(dropIndex, 0, dragElm[0]);

            yield call(updateZIndex, LayersList, LayersDict);

            /* unselect the last selected layer and hence to make dragged layer as selected */
            findWhere(LayersList, { layerID: selectedLayer.layerID }).isSelected = false;
            LayersList[dropIndex].isSelected = true;

            yield put({
                type: ActionTypes.UPDATE_LAYER_INFO_SUCCESS,
                LayersList: [...LayersList],
                selectedLayer: LayersList[dropIndex],
            });

            settings.selectedLayerID = LayersList[dropIndex].layerID;
            SettingsStore.saveSettings();
        }
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, handleRelocateLayer ${error}`);
    }
}

function* handleUndoLayerDelete() {
    try {
        const { LayersList, deletedLayer } = yield select(getLayerManagementReducerState);
        let settings = SettingsStore.getConsoleSettings();
        settings = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;
        let LayersDict = settings.LayerSettings;

        /* remove active mode from currently selected layer and set undoing layer to active */
        findWhere(LayersList, { isSelected: true }).isSelected = false;

        //Pushing  deleted layer to previous position of layers list by zindex
        LayersList.splice(deletedLayer.zIndex, 0, deletedLayer);

        LayersDict = extend(LayersDict, { [deletedLayer.layerID]: Object.assign(new Serializable(), { zIndex: deletedLayer.zIndex, isVisible: deletedLayer.isVisible }) });

        yield call(updateZIndex, LayersList, LayersDict);

        yield put({
            type: ActionTypes.REVOKE_LAYER_ON_UNDO_SUCCESS,
            LayersList: [...LayersList],
            selectedLayer: deletedLayer
        });
    }
    catch (error) {
        console.log(`Error occurs in AnnotationLayerManagementSaga.js, handleUndoLayerDelete ${error}`);
    }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchProcessAnnotationLayerData() {
    yield takeLatest(ActionTypes.PROCESS_ANNOTATION_LAYER_DATA, processAnnotationLayerData);
};

export function* watchHandleOnFooterAction() {
    yield takeLatest(ActionTypes.ON_FOOTER_ACTION_TRIGGERED, onFooterAction);
};

export function* watchHandleRenameLayer() {
    yield takeLatest(ActionTypes.ON_LAYER_ITEM_RENAMED, onLayerItemRenamed);
};

export function* watchHandleRelocateLayer() {
    yield takeLatest(ActionTypes.RELOCATE_LAYER, handleRelocateLayer)
}

export function* watchUndoLayerDelete() {
    yield takeLatest(ActionTypes.REVOKE_LAYER_ON_UNDO, handleUndoLayerDelete)
}