import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import PortalContainer from 'PortalContainer';
class ToolTip extends PureComponent {
    render() {
        const { children, posX, posY, width } = this.props;
        const styles = (posX + width) > (window.innerWidth - 30) ? { position: "absolute", right: 0, top: posY } : { position: "absolute", left: posX, top: posY };
        return (
            <PortalContainer>
                <div ref={(ref) => this.tooltipCom = ref} style={styles}>
                    {children}
                </div>
            </PortalContainer>
        );
    }
}

ToolTip.propTypes = {
    posX: PropTypes.number.isRequired,
    posY: PropTypes.number.isRequired,
    width: PropTypes.number
}

export default ToolTip;