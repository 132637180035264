
import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import FundamentalTabPeriodicityType from 'Constants/FundamentalTabPeriodicityType.js';

class FundamentalRatiosRequest extends BaseServiceRequest {
  constructor(symbol, periodicity, isInternational) {
    super();
    this.periodicity = periodicity;
    super.addRequestUri(this.getRequestString(this.periodicity) + ".pb");
    super.addRequestParameter("sym", symbol.toString());
    super.addRequestParameter("isInternational", isInternational);
  }

  getRequestString(periodicity) {
    let requestString = "";
    switch (parseInt(periodicity)) {
      case FundamentalTabPeriodicityType.FundamentalsAnnual:
        requestString = "ratiosannualreport";
        break;
      case FundamentalTabPeriodicityType.FundamentalsSemiAnnual:
        requestString = "ratiossemiannualreport";
        break;
      case FundamentalTabPeriodicityType.FundamentalsQuarterly:
        requestString = "ratiosqtrlyreport";
        break;
      default:
        break;
    }
    return requestString;
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.FUNDAMENTAL;
  }

  getMessageName() {
    return "FundamentalratiosList";
  }
}

export default FundamentalRatiosRequest;
