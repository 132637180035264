import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetEligibleListsRequest extends BaseServiceRequest {

    constructor() { //sourceId, sourcetypeid
        super();
        super.addRequestUri("validlistalerts.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ValidListAlertDataResponse";
    }
}

export default GetEligibleListsRequest;
