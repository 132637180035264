import ConsoleStore from "ConsoleStore";
import DatagraphDataType from "../Constants/DatagraphDataType";
import UserInfoUtil from "../Utils/UserInfoUtil";
import SettingsStore from '../Stores/ConsoleWindow/Settings/SettingsStore'
import BaseServiceApi from 'BaseServiceApi';
import PerformanceWindowStore from "../Stores/NavModules/NavDataGraph/PerformanceWindowStore";
import { PerformanceWindowConstants } from "../Constants/PerformanceWindowConstants";
import PeriodicityHelper from "../Utils/PeriodicityHelper";
import GraphType from "../Constants/GraphType";
import SymbolType from "SymbolType";
import DataGraphStore from "../Stores/NavModules/NavDataGraph/DataGraphStore";
import NavType from "../Constants/NavType";
import SummaryStatStore from "./ListSummaryStat/SummaryStatStore";
import ONeilViewStore from "../Stores/NavModules/NavList/TabONeil/ONeilViewStore";
import { PrintMode } from '../print/printmode';
import StringUtil from "../Utils/StringUtil";
import DatagraphHelper from "../Utils/DatagraphHelper";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";

let EntitlementType = BaseServiceApi.rayData.EntitlementType;
let resumetimeOut = null;

class TimeTrackingWindow {
    constructor() {
        this.initiateLoginTimeTracker = this.initiateLoginTimeTracker.bind(this);
        this.beginTimeTracker = this.beginTimeTracker.bind(this);
        this.trackChartLoadingTime = this.trackChartLoadingTime.bind(this);
        this.endUpTimeTracker = this.endUpTimeTracker.bind(this);
        this.resetTimeTracker = this.resetTimeTracker.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.addEventListener = this.addEventListener.bind(this);
        this.removeEventListener = this.removeEventListener.bind(this);
        this.onUserLoginInitiatedTime = 0;
        this.trackTime = false;
        this.timeout = 0;
        this.delay = 2000;
        this.isMounted = false;
        this.isDataGraphMounted = false;

        this.isPeriodicityChange = false;
        this.isDayChange = false;
        this.isSymbolChanged = false;
        this.isAnnotationsLoading = true;

        this.isFactorExist = false;
        this.isSummaryBlockAvailable = PrintMode.printing ? true : false;
        this.isSummaryBlockOpen = PrintMode.printing ? true : false;
        this.isSummaryBlockImageLoading = false;
        this.isOwnershipBlcokEnabled = false;
        this.isFinancialBlockEnabled = false;
        this.isOwnershipBlcokOrFinancialBlockEnabled = false;
        this.isTIPanelEnabled = false;
        this.isTechPanelEnabled = false;
        this.isKPIPanelEnabled = false;
        this.isExternalPanelEnabled = false;
        this.isExternalDataEventsEnabled = false;
        this.isAlertImageLoading = false;

        this.isResetDGTimeTrack = true;
        this.isResetFinOrOwnTimeTrack = true;
        this.isResetSumTimeTrack = true;
        this.isResetTITimeTrack = true;
        this.isResetTechTimeTrack = true;
        this.isResetKPITimeTrack = true;
        this.isResetExtTimeTrack = true;
        this.isResetFacTimeTrack = true;
        this.isResetPriceTimeTrack = true;
        this.isResetAnnotationTimeTrack = true;

        this.isApiErrorDGTimeTrack = false;
        this.isApiErrorFinOrOwnTimeTrack = false;
        this.isApiErrorSumTimeTrack = false;
        this.isApiErrorTITimeTrack = false;
        this.isApiErrorTechTimeTrack = false;
        this.isApiErrorKPITimeTrack = false;
        this.isApiErrorExtTimeTrack = false;
        this.isApiErrorFacTimeTrack = false;
        this.isApiErrorPriceTimeTrack = false;
        this.isApiErrorAnnotationTimeTrack = false;
        this.isApiErrorExtEventsTimeTrack = false;

        this.isRenderErrorDGTimeTrack = false;
        this.isRenderErrorFinOrOwnTimeTrack = false;
        this.isRenderErrorSumTimeTrack = false;
        this.isRenderErrorTITimeTrack = false;
        this.isRenderErrorTechTimeTrack = false;
        this.isRenderErrorKPITimeTrack = false;
        this.isRenderErrorExtTimeTrack = false;
        this.isRenderErrorFacTimeTrack = false;
        this.isRenderErrorPriceTimeTrack = false;
        this.isRenderErrorAnnotationTimeTrack = false;
        this.isRenderErrorExtEventsTimeTrack = false;

        this.isLogin = true;
        this.priceDataNotAvailable = false;
        this.trackDataList = {};
        this.listManagerTrackDataList = {};
        this.currentSymbol = null;
        this.previousSymbol = null;
        this.trackDataIndexKey = null;
    }

    initiateLoginTimeTracker() {
        this.onUserLoginInitiatedTime = new Date().getTime();
        this.isMounted = true;
        this.trackTime = true;
    }

    initTimeTracker(symbolInfo = undefined, periodicity = undefined){
        this.isMounted = true;
    }

    beginTimeTracker(nav = NavType.NavSymbol) {
        this.trackTime = true;
        PerformanceWindowStore.onInitTimeTracker();
        this.trackDataIndexKey = PerformanceWindowStore.getCurrentTrackDataKey();
        switch (nav) {
            case NavType.NavSymbol:
                if (ConsoleStore.getCurrentAction() === ConsoleConstants.ActionTypes.SET_CHART_LOADED) {
                    ConsoleStore.clearCurrentAction();
                }
                this.beginTimeTrackerByTrackType(DatagraphDataType.DatagraphRender, nav);
                PerformanceWindowStore.updateTimeTrack(PerformanceWindowConstants.ActionTypes.DATAGRAPH_DATA_LOAD_START);
                break;
            case NavType.NavLists:
                this.beginTimeTrackerByTrackType(DatagraphDataType.ListManagerOverallEnd, nav);
                PerformanceWindowStore.updateTimeTrack(PerformanceWindowConstants.ActionTypes.DATAGRAPH_DATA_LOAD_START);
                break;
            default:
                break;
        }
    }

    endUpTimeTracker(nav = NavType.NavSymbol) {
        if (this.isMounted && !this.trackTime) {
            // this.trackTime = false;
            // this.removeEventListener();
            // clearTimeout(this.timeout);
            const trackData = this.computeDatagraphRenderTime(nav);
            PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, nav);
        }
    }

    resetTimeTracker(isDataGraphMounted = false, isMoreDataOnScroll = false, nav = NavType.NavSymbol) {
        clearTimeout(this.timeout);
        this.trackTime = false;
        this.timeout = 0;
        this.isMounted = false;
        this.isDataGraphMounted = isDataGraphMounted;
        this.isLogin = false;
        if(nav === NavType.NavSymbol){
            this.isFactorExist = false;
            this.trackDataList = {};
            this.isSummaryBlockOpen = PrintMode.printing ? true : false;
            this.isSummaryBlockAvailable = PrintMode.printing ? true : false;
            this.isOwnershipBlcokEnabled = false;
            this.isFinancialBlockEnabled = false;
            this.priceDataNotAvailable = false;
        }
        else if(nav === NavType.NavLists){
            if(!isMoreDataOnScroll){
                this.listManagerTrackDataList = {};
            }
        }
        this.isSummaryBlockOpen = PrintMode.printing ? true : false;
        this.isSummaryBlockAvailable = PrintMode.printing ? true : false;
        this.isOwnershipBlcokEnabled = false;
        this.isFinancialBlockEnabled = false;
        this.isTIPanelEnabled = false;
        this.isTechPanelEnabled = false;
        this.isKPIPanelEnabled = false;
        this.isExternalPanelEnabled = false;
        this.isExternalDataEventsEnabled= false;

        this.isResetDGTimeTrack = true;
        this.isResetFinOrOwnTimeTrack = true;
        this.isResetSumTimeTrack = true;
        this.isResetTITimeTrack = true;
        this.isResetTechTimeTrack = true;
        this.isResetKPITimeTrack = true;
        this.isResetExtTimeTrack = true;
        this.isResetFacTimeTrack = true;
        this.isResetPriceTimeTrack = true;
        this.isResetAnnotationTimeTrack = true;

        this.isApiErrorDGTimeTrack = false;
        this.isApiErrorFinOrOwnTimeTrack = false;
        this.isApiErrorSumTimeTrack = false;
        this.isApiErrorTITimeTrack = false;
        this.isApiErrorTechTimeTrack = false;
        this.isApiErrorKPITimeTrack = false;
        this.isApiErrorExtTimeTrack = false;
        this.isApiErrorFacTimeTrack = false;
        this.isApiErrorPriceTimeTrack = false;
        this.isApiErrorAnnotationTimeTrack = false;
        this.isApiErrorExtEventsTimeTrack = false;

        this.isRenderErrorDGTimeTrack = false;
        this.isRenderErrorFinOrOwnTimeTrack = false;
        this.isRenderErrorSumTimeTrack = false;
        this.isRenderErrorTITimeTrack = false;
        this.isRenderErrorTechTimeTrack = false;
        this.isRenderErrorKPITimeTrack = false;
        this.isRenderErrorExtTimeTrack = false;
        this.isRenderErrorFacTimeTrack = false;
        this.isRenderErrorPriceTimeTrack = false;
        this.isRenderErrorAnnotationTimeTrack = false;
        this.isRenderErrorExtEventsTimeTrack = false;

        this.isLogin = false;
        this.priceDataNotAvailable = false;
        PerformanceWindowStore.setShouldUpdate(true);
        PerformanceWindowStore.restTimeTracker(isMoreDataOnScroll, nav);
        
    }

    setCurrentSymbol(symbol){
        this.previousSymbol = this.currentSymbol;
        if(symbol){
            this.currentSymbol = symbol.toUpperCase();
        }
        else{
            this.currentSymbol = "";
        }
    }

    onSymbolChartFailed(){
        this.isMounted = false;
        this.trackTime = false;
        PerformanceWindowStore.updateTimeTrack(PerformanceWindowConstants.ActionTypes.DATAGRAPH_DATA_LOAD_FAILED);
    }

    getTrackDataListByNavType(timeTrackingType, nav = NavType.NavSymbol) {
        let trackDataList = {};
        switch (nav) {
            case NavType.NavSymbol:
                if (!this.trackDataList) {
                    this.trackDataList = {};
                }
                if (this.trackDataList[timeTrackingType]) {
                    switch (timeTrackingType) {
                        case DatagraphDataType.FinancialOwnership:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetFinOrOwnTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorFinOrOwnTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorFinOrOwnTimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 3;
                            break;
                        case DatagraphDataType.SummaryBlock:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetSumTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorSumTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorSumTimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 2;
                            break;
                        case DatagraphDataType.TIPanel:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetTITimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorTITimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorTITimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 5;
                            break;
                        case DatagraphDataType.TechnicalIndicators:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetTechTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorTechTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorTechTimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 4;
                            break;
                        case DatagraphDataType.KPIPanel:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetKPITimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorKPITimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorKPITimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 6;//5;
                            break;
                        case DatagraphDataType.ExternalDataTIPanel:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetExtTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorExtTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorExtTimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 7;//6;
                            break;
                        case DatagraphDataType.Factor:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetFacTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorFacTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorFacTimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 8;//7;
                            break;
                        case DatagraphDataType.DatagraphRender:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetDGTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorDGTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorDGTimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 10;
                            break;
                        case DatagraphDataType.Annotation:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetAnnotationTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorAnnotationTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorAnnotationTimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 9;
                            break;
                        case DatagraphDataType.PricePanelAndFinancial:
                            this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetPriceTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorPriceTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorPriceTimeTrack;
                            this.trackDataList[timeTrackingType].orderNum = 1;
                            break;
                        case DatagraphDataType.ExternalDataEvents:
                            // this.trackDataList[timeTrackingType].isResetTimeTrack = this.isResetExtEventsTimeTrack;
                            this.trackDataList[timeTrackingType].isRenderErrorTimeTrack = this.isRenderErrorExtEventsTimeTrack;
                            this.trackDataList[timeTrackingType].isApiErrorTimeTrack = this.isApiErrorExtEventsTimeTrack;
                            // this.trackDataList[timeTrackingType].orderNum = 1;
                            break;
                        default:
                            break;
                    }
                }
                trackDataList = this.trackDataList;
                break;
            case NavType.NavLists:
                if (!this.listManagerTrackDataList) {
                    this.listManagerTrackDataList = {};
                }
                trackDataList = this.listManagerTrackDataList;
                break;
            default:
                break;
        }
        return trackDataList;
    }

    getTrackDataByTrackType(timeTrackingType, nav = NavType.NavSymbol) {
        let trackDataList = this.getTrackDataListByNavType(timeTrackingType, nav);
        let trackData = trackDataList[timeTrackingType];
        if (!trackData) {
            trackData = { DataType: timeTrackingType, IsDataReady: false, IsShow: true, TrackDataIndexKey: this.trackDataIndexKey, isLogin: this.isLogin, isReadyForPrint: false };
            trackDataList[timeTrackingType] = trackData;
        }
        return trackData;
    }

    setIsResetTimeTrack(timeTrackingType){
        switch (timeTrackingType) {
            case DatagraphDataType.FinancialOwnership:
                this.isResetFinOrOwnTimeTrack = false;
                break;
            case DatagraphDataType.SummaryBlock:
                this.isResetSumTimeTrack = false;
                break;
            case DatagraphDataType.TIPanel:
                this.isResetTITimeTrack = false;
                break;
            case DatagraphDataType.TechnicalIndicators:
                this.isResetTechTimeTrack = false;
                break;
            case DatagraphDataType.KPIPanel:
                this.isResetKPITimeTrack = false;
                break;
            case DatagraphDataType.ExternalDataTIPanel:
                this.isResetExtTimeTrack = false;
                break; 
            case DatagraphDataType.Factor:
                this.isResetFacTimeTrack = false;
                break;
            case DatagraphDataType.DatagraphRender:
                this.isResetDGTimeTrack = false;
                break;
            case DatagraphDataType.PricePanelAndFinancial:
                this.isResetPriceTimeTrack = false;
                break;
            case DatagraphDataType.Annotation:
                this.isResetAnnotationTimeTrack = false;
                break;
            default:
                break;
        }
    }

    setTimeTrackRenderError(timeTrackingType) {
        switch (timeTrackingType) {
            case DatagraphDataType.FinancialOwnership:
                this.isRenderErrorFinOrOwnTimeTrack = true;
                break;
            case DatagraphDataType.SummaryBlock:
                this.isRenderErrorSumTimeTrack = true;
                break;
            case DatagraphDataType.TIPanel:
                this.isRenderErrorTITimeTrack = true;
                break;
            case DatagraphDataType.TechnicalIndicators:
                this.isRenderErrorTechTimeTrack = true;
                break;
            case DatagraphDataType.KPIPanel:
                this.isRenderErrorKPITimeTrack = true;
                break;
            case DatagraphDataType.ExternalDataTIPanel:
                this.isRenderErrorExtTimeTrack = true;
                break; 
            case DatagraphDataType.Factor:
                this.isRenderErrorFacTimeTrack = true;
                break;
            case DatagraphDataType.DatagraphRender:
                this.isRenderErrorDGTimeTrack = true;
                break;
            case DatagraphDataType.PricePanelAndFinancial:
                this.isRenderErrorPriceTimeTrack = true;
                break;
            case DatagraphDataType.Annotation:
                this.isRenderErrorAnnotationTimeTrack = true;
                break;
            case DatagraphDataType.ExternalDataEvents:
                this.isRenderErrorExtEventsTimeTrack = true;
                break; 
            default:
                break;
        }
    }

    setApiTimeTrackError(timeTrackingType) {
        switch (timeTrackingType) {
            case DatagraphDataType.FinancialOwnership:
                this.isApiErrorFinOrOwnTimeTrack = true;
            case DatagraphDataType.SummaryBlock:
                this.isApiErrorSumTimeTrack = true;
                break;
            case DatagraphDataType.TIPanel:
                this.isApiErrorTITimeTrack = true;
                break;
            case DatagraphDataType.TechnicalIndicators:
                this.isApiErrorTechTimeTrack = true;
                break;
            case DatagraphDataType.KPIPanel:
                this.isApiErrorKPITimeTrack = true;
                break;
            case DatagraphDataType.ExternalDataTIPanel:
                this.isApiErrorExtTimeTrack = true;
                break; 
            case DatagraphDataType.Factor:
                this.isApiErrorFacTimeTrack = true;
                break;
            case DatagraphDataType.DatagraphRender:
                this.isApiErrorDGTimeTrack = true;
                break;
            case DatagraphDataType.PricePanelAndFinancial:
                this.isApiErrorPriceTimeTrack = true;
                break;
            case DatagraphDataType.Annotation:
                this.isApiErrorAnnotationTimeTrack = true;
                break;
            case DatagraphDataType.ExternalDataEvents:
                this.isApiErrorExtEventsTimeTrack = true;
                break; 
            default:
                break;
        }
    }

    beginTimeTrackerByTrackType(trackType, nav = NavType.NavSymbol){
        const trackData = this.getTrackDataByTrackType(trackType, nav);
        trackData.LoadBeginTime = new Date().getTime();
        // console.log("IsseTrack beginTimeTrackerByTrackType", trackType, trackData.LoadBeginTime, new Date());
        if (trackType === DatagraphDataType.PricePanelAndFinancial){
            const tiTrackDataType = DatagraphDataType.TechnicalIndicators;
            const titrackData = this.getTrackDataByTrackType(tiTrackDataType, nav);
            titrackData.LoadBeginTime = trackData.LoadBeginTime;
        }
    }

    endApiTimeTrackerByTrackType(trackType, nav = NavType.NavSymbol){
        const trackData = this.getTrackDataByTrackType(trackType, nav);
        trackData.ApiEndTime = new Date().getTime();
        trackData.ApiTime = trackData.ApiEndTime - trackData.LoadBeginTime;
        trackData.ApiTimeDisplay = (trackData.ApiTime) / 1000 + " s";
        trackData.IsDataReady = true;

        PerformanceWindowStore.updateApiTrackDataByTrackType(trackType, trackData.ApiTimeDisplay, nav);
        this.checkAllDataReady(trackType);
        if (trackType === DatagraphDataType.PricePanelAndFinancial){
            const tiTrackDataType = DatagraphDataType.TechnicalIndicators;
            const tiTrackData = this.getTrackDataByTrackType(tiTrackDataType, nav);
            tiTrackData.ApiEndTime = trackData.ApiEndTime;
            tiTrackData.ApiTime = trackData.ApiTime;
            tiTrackData.ApiTimeDisplay = trackData.ApiTimeDisplay;
            tiTrackData.IsDataReady = trackData.IsDataReady;
            PerformanceWindowStore.updateApiTrackDataByTrackType(tiTrackDataType, tiTrackData.ApiTimeDisplay, nav);
            this.checkAllDataReady(tiTrackDataType);;
        }
    }

    beginRenderTimeTrackerByTrackType(trackType, nav = NavType.NavSymbol){
        const trackData = this.getTrackDataByTrackType(trackType, nav);
        trackData.RenderBeginTime = new Date().getTime();
        trackData.isReadyForPrint = false;
    }

    endLoadEndTimeTrackerByTrackType(trackType, nav = NavType.NavSymbol){
        const trackData = this.getTrackDataByTrackType(trackType, nav);
        trackData.LoadEndTime = new Date().getTime();
        trackData.ApiTime = trackData.ApiEndTime && trackData.LoadBeginTime ? trackData.ApiEndTime - trackData.LoadBeginTime : 0;
        trackData.ApiTimeDisplay = (trackData.ApiTime) / 1000 + " s";
        trackData.ComponentTime = trackData.LoadEndTime && trackData.RenderBeginTime ? trackData.LoadEndTime - trackData.RenderBeginTime : 0;
        trackData.ComponentTimeDisplay = (trackData.ComponentTime) / 1000 + " s";
        trackData.LoadTime = trackData.ComponentTime + trackData.ApiTime;
        trackData.LoadTimeDisplay = (trackData.LoadTime) / 1000 + " s";
        trackData.IsShow = this.getIsShowByType(trackType); 
        trackData.isReadyForPrint = true;

        if(nav === NavType.NavLists){
            trackData.isApiErrorTimeTrack = false;
            trackData.isRenderErrorTimeTrack = false;
        }
        PerformanceWindowStore.updateComponentTrackDataByTrackType(trackType, trackData.ComponentTimeDisplay, nav);
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, nav);
    }

    setErrTrackDataByTrackType(trackType, isApi, nav = NavType.NavLists){
        let trackData = this.getTrackDataByTrackType(trackType, nav);
        if(!this.getIsShowByType(trackType)){
            return;
        }
        if(isApi){
            trackData.isApiErrorTimeTrack = true;
            trackData.isRenderErrorTimeTrack = true;
        }
        else{
            trackData.isRenderErrorTimeTrack = true;
        }
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, nav);
        trackData = this.getTrackDataByTrackType(DatagraphDataType.ListManagerOverallEnd, NavType.NavLists);
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, NavType.NavLists);
    }

    beginPricePanelTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.PricePanelAndFinancial);
    }

    endPricePanelApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.PricePanelAndFinancial);
    }

    beginPricePanelRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.PricePanelAndFinancial);
    }

    endPricePanelLoadEndTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.PricePanelAndFinancial);
        const trackData = this.computeDatagraphRenderTime();
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
    }

    beginSummaryBlockTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.SummaryBlock);
    }

    endSummaryBlockApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.SummaryBlock);
    }

    beginSummaryBlockRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.SummaryBlock);
    }

    resumeEndSummaryBlockLoadEndTimeTracker(trackData) {
        clearTimeout(resumetimeOut);
        if (this.isSymbolChanged || this.isDayChange) {
            PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
            const datagraphTrackData = this.computeDatagraphRenderTime();
            PerformanceWindowStore.updateLoadTrackDataByTrackData(datagraphTrackData);
            this.isSymbolChanged = false;
            this.isDayChange = false;
        } else {
            resumetimeOut = setTimeout(() => {
                this.resumeEndSummaryBlockLoadEndTimeTracker(trackData);
            }, 100);
        }
    }

    endSummaryBlockLoadEndTimeTracker() {
        const trackType = DatagraphDataType.SummaryBlock;
        const trackData = this.getTrackDataByTrackType(trackType);
        trackData.LoadEndTime = new Date().getTime();
        trackData.ApiTime = trackData.ApiEndTime && trackData.LoadBeginTime ? trackData.ApiEndTime - trackData.LoadBeginTime : 0;
        trackData.ApiTimeDisplay = (trackData.ApiTime) / 1000 + " s";
        trackData.ComponentTime = trackData.LoadEndTime && trackData.RenderBeginTime ? trackData.LoadEndTime - trackData.RenderBeginTime : 0;
        trackData.ComponentTimeDisplay = (trackData.ComponentTime) / 1000 + " s";
        trackData.LoadTime = trackData.ComponentTime + trackData.ApiTime;
        trackData.LoadTimeDisplay = (trackData.LoadTime) / 1000 + " s";
        trackData.IsShow = this.getIsShowByType(trackType);
        trackData.isReadyForPrint = true;
        // console.log("IsseTrack endLoadEndTimeTrackerByTrackType", trackType, trackData.LoadEndTime, trackData.ApiTimeDisplay, trackData.ComponentTimeDisplay, trackData.LoadTimeDisplay, new Date());
        PerformanceWindowStore.updateComponentTrackDataByTrackType(trackType, trackData.ComponentTimeDisplay);
        this.resumeEndSummaryBlockLoadEndTimeTracker(trackData);
    }

    endSummaryBlockImageLoadTracker(){
        this.isSummaryBlockImageLoading = false;
    }

    beginFinancialOwnerShipBlockTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.FinancialOwnership);
    }

    endFinancialOwnerShipBlockApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.FinancialOwnership);
    }

    beginFinancialOwnerShipBlockRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.FinancialOwnership);
    }

    endFinancialOwnerShipBlockLoadEndTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.FinancialOwnership);
        const trackData = this.computeDatagraphRenderTime(NavType.NavSymbol);
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, NavType.NavSymbol);
    }

    beginListContextApiTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.ListContent, NavType.NavLists);
    }
    endListContextApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.ListContent, NavType.NavLists);
    }
    beginListContextRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.ListContent, NavType.NavLists);
    }
    endListContextRenderTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.ListContent, NavType.NavLists);
        const trackData = this.computeDatagraphRenderTime(NavType.NavLists);
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, NavType.NavLists);
    }

    beginSummaryStats_HistogramApiTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.SummaryStats_Histogram, NavType.NavLists);
    }
    endSummaryStats_HistogramApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.SummaryStats_Histogram, NavType.NavLists);
    }
    beginSummaryStats_HistogramRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.SummaryStats_Histogram, NavType.NavLists);
    }
    endSummaryStats_HistogramRenderTimeTracker(loadHistStat = false){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.SummaryStats_Histogram, NavType.NavLists);
        const isSummaryStatsShow = this.getIsShowByType(DatagraphDataType.SummaryStats_Histogram);
        if(isSummaryStatsShow){
            const trackData = loadHistStat ? this.getTrackDataByTrackType(DatagraphDataType.ListManagerOverallEnd, NavType.NavLists) : this.computeDatagraphRenderTime(NavType.NavLists);
            PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, NavType.NavLists);
        }
    }

    beginSummaryStats_DataApiTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.SummaryStats_Data, NavType.NavLists);
    }
    endSummaryStats_DataApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.SummaryStats_Data, NavType.NavLists);
    }
    beginSummaryStats_DataRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.SummaryStats_Data, NavType.NavLists);
    }
    endSummaryStats_DataRenderTimeTracker(isSetPageListEsBasic = false){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.SummaryStats_Data, NavType.NavLists);
        const isSummaryStatsShow = this.getIsShowByType(DatagraphDataType.SummaryStats_Data);
        if(isSummaryStatsShow){
            const trackData = isSetPageListEsBasic ? this.computeDatagraphRenderTime(NavType.NavLists) : this.getTrackDataByTrackType(DatagraphDataType.ListManagerOverallEnd, NavType.NavLists);
            PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, NavType.NavLists);
        }
    }

    beginListExplorerApiTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.ListExplorer, NavType.NavLists);
    }
    endListExplorerApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.ListExplorer, NavType.NavLists);
    }
    beginListExplorerRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.ListExplorer, NavType.NavLists);
    }
    endListExplorerRenderTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.ListExplorer, NavType.NavLists);
        const trackData = this.computeDatagraphRenderTime(NavType.NavLists);
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData, NavType.NavLists);
    }

    beginTechIndicatorTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.TIPanel);
    }

    endTechIndicatorApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.TIPanel);
    }

    beginTechIndicatorRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.TIPanel);
    }

    endTechIndicatorLoadEndTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.TIPanel);
        const trackData = this.computeDatagraphRenderTime();
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
    }

    beginTechnicalIndicatorsTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.TechnicalIndicators);
    }

    endTechnicalIndicatorsApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.TechnicalIndicators);
    }

    beginTechnicalIndicatorsRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.TechnicalIndicators);
    }

    endTechnicalIndicatorsLoadEndTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.TechnicalIndicators);
        const trackData = this.computeDatagraphRenderTime();
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
    }

    beginKPIPanelTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.KPIPanel);
    }

    endKPIPanelApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.KPIPanel);
    }

    beginKPIPanelRenderTimeTracker() {
        const trackData = this.getTrackDataByTrackType(DatagraphDataType.KPIPanel);
        /* As each kpi panel contains different data points, KPI data processing module (getKpiMenu in KpiModule.js) render multiple times based on the number of panels visible on the screen..
           So no need to re initiate the timer for each panel. The RenderBeginTime will be initiated only once when the first panel's data process start. */

        if (trackData.ApiEndTime && !trackData.RenderBeginTime) {
            trackData.RenderBeginTime = new Date().getTime();
            this.beginRenderTimeTrackerByTrackType(DatagraphDataType.KPIPanel);
        }
    }

    endKPIPanelLoadEndTimeTracker() {
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.KPIPanel);
        const trackData = this.computeDatagraphRenderTime();
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
    }

    beginExternalPanelTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.ExternalDataTIPanel);
    }

    endExternalPanelApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.ExternalDataTIPanel);
    }

    beginExternalPanelRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.ExternalDataTIPanel);
    }

    endExternalPanelLoadEndTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.ExternalDataTIPanel);
        const trackData = this.computeDatagraphRenderTime();
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
    }

    beginExternalEventsTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.ExternalDataEvents);
    }

    endExternalEventsApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.ExternalDataEvents);
    }

    beginExternalEventsRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.ExternalDataEvents);
    }

    endExternalEventsLoadEndTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.ExternalDataEvents);
        // const trackData = this.computeDatagraphRenderTime();
        // PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
    }

    beginFactorTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.Factor);
    }

    endFactorApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.Factor);
    }

    beginFactorRenderTimeTracker(){
        this.beginRenderTimeTrackerByTrackType(DatagraphDataType.Factor);
    }

    endFactorLoadEndTimeTracker(){
        this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.Factor);
        const trackData = this.computeDatagraphRenderTime();
        PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
    }

    beginAnnotationTimeTracker(){
        this.beginTimeTrackerByTrackType(DatagraphDataType.Annotation);
    }

    endAnnotationApiTimeTracker(){
        this.endApiTimeTrackerByTrackType(DatagraphDataType.Annotation);
    }

    beginAnnotationRenderTimeTracker(count = 0) {
        if (!this.trackDataList[DatagraphDataType.Annotation]) {
            this.trackDataList[DatagraphDataType.Annotation] = { DataType: DatagraphDataType.Annotation, IsDataReady: false, IsShow: true, TrackDataIndexKey: this.trackDataIndexKey, isLogin: this.isLogin, isReadyForPrint: false };
        }
        this.trackDataList[DatagraphDataType.Annotation].annotationsCount = count;
        if (PrintMode.printing ? count > 0 : true) {
            this.beginRenderTimeTrackerByTrackType(DatagraphDataType.Annotation);
        }
    }

    endAnnotationLoadEndTimeTracker() {
        this.trackDataList[DatagraphDataType.Annotation].annotationsCount--;
        if (PrintMode.printing ? this.trackDataList[DatagraphDataType.Annotation].annotationsCount <= 0 : true) {
            this.endLoadEndTimeTrackerByTrackType(DatagraphDataType.Annotation);
            const trackData = this.computeDatagraphRenderTime();
            PerformanceWindowStore.updateLoadTrackDataByTrackData(trackData);
        }
    }

    setDatagraphRenderReady(isReady = true){
        const trackData = this.getTrackDataByTrackType(DatagraphDataType.DatagraphRender);
        trackData.IsDataReady = isReady;
        trackData.isReadyForPrint = isReady;
        this.checkAllDataReady();
    }

    computeDatagraphRenderTime(nav = NavType.NavSymbol){
        let trackType = DatagraphDataType.DatagraphRender;
        switch (nav) {
            case NavType.NavSymbol:
                trackType = DatagraphDataType.DatagraphRender;
                break;
            case NavType.NavLists:
                trackType = DatagraphDataType.ListManagerOverallEnd;
                break;
            default:
                break;
        }
        const trackData = this.getTrackDataByTrackType(trackType, nav);
        trackData.LoadEndTime = new Date().getTime();
        trackData.LoadTime = trackData.LoadEndTime - trackData.LoadBeginTime;
        trackData.LoadTimeDisplay = trackData.LoadTime / 1000 + " s";
        trackData.IsDataReady = true;
        if (this.isLogin) {
            trackData.LoginTime = this.onUserLoginInitiatedTime ? trackData.LoadEndTime - this.onUserLoginInitiatedTime : 0;
            trackData.LoginTimeDisplay = trackData.LoginTime / 1000 + " s";
        }
        else{
            this.onUserLoginInitiatedTime = 0;// assigning zero value since we do not have to track this time once the user has logged in
        }
        return trackData;
    }

    isFactorEnable(){
        if(this.isFactorExist && UserInfoUtil.IsUserEntitled(EntitlementType.FACTOR_RESEARCH_Entitlement)){
            const tabDataGraphSettings = this.getTabDataGraphSettings();
            const factorSetting = tabDataGraphSettings && tabDataGraphSettings.StockViewSettings && tabDataGraphSettings.StockViewSettings.FactorResearchSettings;
            if(factorSetting){
                return factorSetting.IsVisible;
            }
            else{
                return false;
            }
        }
        else{
            return false;
        }
    }

    setExternalDataEventsStatus() {
        if (UserInfoUtil.IsUserEntitled(EntitlementType.EXT_DATA_Uploader_Entitlement)) {
            const tabDataGraphSettings = this.getTabDataGraphSettings();
            const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
            const ExternalDataSettings = DatagraphHelper.getSettingsObject(SettingsStore.getConsoleSettings()).ExternalDataSettings;
            this.isExternalDataEventsEnabled = false;

            if (majorPeriodicity !== GraphType.Intraday) {
                for (const listId in ExternalDataSettings) {
                    const listItem = ExternalDataSettings[listId][majorPeriodicity];

                    if (!StringUtil.isEmpty(listItem) && !listItem.isDisabledForCurrentSymbol) {
                        if (listItem.IsVisibleInPriceMenu && listItem.isDateBasedEvents) {
                            this.isExternalDataEventsEnabled = true;
                        }
                    }
                }
            }
        }
    }

    setPriceDataNotAvailable(priceDataNotAvailable){
        this.priceDataNotAvailable = priceDataNotAvailable;
    }

    updateIsShowByType(trackType, nav = NavType.NavSymbol){
        const trackData = this.getTrackDataByTrackType(trackType, nav);
        trackData.IsShow = this.getIsShowByType(trackType);
    }

    getIsShowByType(trackType) {
        switch (trackType) {
            case DatagraphDataType.FinancialOwnership:
                return this.getIsShowFinancialOwnershipBlock();
            case DatagraphDataType.SummaryBlock:
                return this.getIsShowSummaryBlock();
            case DatagraphDataType.TIPanel:
                return this.getIsShowTIPanel();
            case DatagraphDataType.TechnicalIndicators:
                return this.getIsShowTechPanel();
            case DatagraphDataType.KPIPanel:
                return this.getIsShowKPIPanel();
            case DatagraphDataType.ExternalDataTIPanel:
                return this.getIsShowExternalTIPanel();
            case DatagraphDataType.Factor:
                return this.isFactorEnable();
            case DatagraphDataType.PricePanelAndFinancial:
            case DatagraphDataType.DatagraphRender:
            case DatagraphDataType.Annotation:
                break;
            case DatagraphDataType.SummaryStats_Histogram:
            case DatagraphDataType.SummaryStats_Data:
                return this.getIsShowSummaryStat();
            default:
                break;
        }
        return true;
    }

    getIsShowSummaryBlock(){
        return this.isSummaryBlockAvailable && this.isSummaryBlockOpen;
    }

    getIsShowFinancialOwnershipBlock(){
        return this.isOwnershipBlcokEnabled || this.isFinancialBlockEnabled;
    }

    getIsShowTIPanel(){
        return this.isTIPanelEnabled;
    }
    getIsShowTechPanel(){
        return this.isTechPanelEnabled;
    }

    getIsShowKPIPanel(){
        return this.isKPIPanelEnabled;
    }

    getIsShowExternalTIPanel(){
        return this.isExternalPanelEnabled;
    }
    
    getIsShowSummaryStat(){
        const summaryStatStoreState = SummaryStatStore.getState();
        return summaryStatStoreState.summaryStatIsOpen && !this.isPortfolioList();
    }

    isPortfolioList () {
        const basicInfo = ONeilViewStore.getBasicEsInfo();
        return basicInfo ? basicInfo.isPortfolioList : false;
    }

    isCurrentSymbol(symbolInfo){
        if(!symbolInfo){
            return false;
        }
        return this.currentSymbol === symbolInfo.Symbol;
    }

    isValueNeedBeChange(symbolInfo, orgValue, newValue){
        return this.isCurrentSymbol(symbolInfo) && orgValue !== newValue;
    }

    setIsSummaryBlockOpen(value, symbolInfo = null){
        if(!symbolInfo){
            symbolInfo = DataGraphStore.getSymbolInfo();
        }
        if(this.isValueNeedBeChange(symbolInfo, this.isSummaryBlockOpen, value)){
            this.isSummaryBlockOpen = value;
            this.updateIsShowByType(DatagraphDataType.SummaryBlock, NavType.NavSymbol);
        }
    }
    setIsSummaryBlockImageLoading(){
        this.isSummaryBlockImageLoading = true;
    }

    setIsAlertImageLoading(){
        this.isAlertImageLoading = true;
    }

    setIsSummaryBlockAvailable(value, symbolInfo = null){
        if(!symbolInfo){
            symbolInfo = DataGraphStore.getSymbolInfo();
        }
        if(this.isValueNeedBeChange(symbolInfo, this.isSummaryBlockAvailable, value)){
            this.isSummaryBlockAvailable = value;
            this.updateIsShowByType(DatagraphDataType.SummaryBlock, NavType.NavSymbol);
        }
    }

    setIsOwnershipBlcokAvailable(value, symbolInfo = null){
        if(!symbolInfo){
            symbolInfo = DataGraphStore.getSymbolInfo();
        }
        if(this.isValueNeedBeChange(symbolInfo, this.isOwnershipBlcokEnabled, value)){
            this.isOwnershipBlcokEnabled = value;
            this.updateIsShowByType(DatagraphDataType.FinancialOwnership, NavType.NavSymbol);
        }
    }

    setIsFinancialBlockAvailable(value, symbolInfo = null){
        if(!symbolInfo){
            symbolInfo = DataGraphStore.getSymbolInfo();
        }
        if(this.isValueNeedBeChange(symbolInfo, this.isFinancialBlockEnabled, value)){
            this.isFinancialBlockEnabled = value;
            this.updateIsShowByType(DatagraphDataType.FinancialOwnership, NavType.NavSymbol);
        }
    }

    setIsTIPanelAvailable(value, symbolInfo = null){
        if(!symbolInfo){
            symbolInfo = DataGraphStore.getSymbolInfo();
        }
        if(this.isValueNeedBeChange(symbolInfo, this.isTIPanelEnabled, value)){
            this.isTIPanelEnabled = value;
            this.updateIsShowByType(DatagraphDataType.TIPanel);
        }
    }

    setIsTechPanelAvailable(value, symbolInfo = null){
        if(!symbolInfo){
            symbolInfo = DataGraphStore.getSymbolInfo();
        }
        if(this.isValueNeedBeChange(symbolInfo, this.isTechPanelEnabled, value)){
            this.isTechPanelEnabled = value;
            this.updateIsShowByType(DatagraphDataType.TechnicalIndicators);
        }
    }

    setIsKPIPanelAvailable(value, symbolInfo = null){
        if(!symbolInfo){
            symbolInfo = DataGraphStore.getSymbolInfo();
        }
        if(this.isValueNeedBeChange(symbolInfo, this.isKPIPanelEnabled, value)){
            this.isKPIPanelEnabled = value;
            this.updateIsShowByType(DatagraphDataType.KPIPanel);
        }
    }

    setIsExternalPanelAvailable(value, symbolInfo = null){
        if(!symbolInfo){
            symbolInfo = DataGraphStore.getSymbolInfo();
        }
        if(this.isValueNeedBeChange(symbolInfo, this.isExternalPanelEnabled, value)){
            this.isExternalPanelEnabled = value;
            this.updateIsShowByType(DatagraphDataType.ExternalDataTIPanel);
        }
    }
    endAlertImageLoading(){
        this.isAlertImageLoading = false;
    }

    getIsAlertImageReady(){
        return !this.isAlertImageLoading;
    }
    getTabDataGraphSettings(){
        const consoleSettings = SettingsStore.getConsoleSettings();
        return consoleSettings && consoleSettings.NavDatagraphSettings && consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    }

    getVideoMode(){
        const tabDataGraphSettings = this.getTabDataGraphSettings();
        return tabDataGraphSettings ? tabDataGraphSettings.videoMode : false;
    }
    getIsImageReadyForPrint(){
        return !this.isSummaryBlockImageLoading;
    }
    getMajorPeriodicity(){
        const tabDataGraphSettings = this.getTabDataGraphSettings();
        const periodicity = tabDataGraphSettings.Periodicity;
        return PeriodicityHelper.mapMajorPeriodicities(periodicity);
    }

    isIntraday(periodicity = undefined){
        if(periodicity){
            return PeriodicityHelper.mapMajorPeriodicities(periodicity) === GraphType.Intraday;
        }
        else{
            return this.getMajorPeriodicity() === GraphType.Intraday;
        }
    }

    isTrackTypeDateReady(trackType, nav = NavType.NavSymbol){
        const trackData = this.getTrackDataByTrackType(trackType, nav);
        return trackData.IsDataReady;
    }

    checkAllDataReady(nav = NavType.NavSymbol) {
        const isAllDataReady = this.isAllDataReady(nav);

        if (this.isMounted && isAllDataReady) {
            this.trackTime = false;
            this.endUpTimeTracker(nav);
        }
    }

    setPerformanceTIPanelStatus(tiMenus, info) {
        if (!tiMenus) return;
        const economicindicator = info && info.SymTypeEnum === SymbolType.ECONOMICINDICATOR;
        let isTiPanelVisible = false;
        this.isTIPanelEnabled = false;
        this.isTechPanelEnabled = false;
        this.isKPIPanelEnabled = false;
        this.isExternalPanelEnabled = false; // External panel will be listed under the tiMenus only when it is available for the current symbol
        
        _.each(tiMenus, (item) => {
            if (!item.isDisabled) {
                if ((item.blockType === 'OR' || item.blockType === 'FM' || item.blockType === 'PM') && !isTiPanelVisible) {
                    isTiPanelVisible = !economicindicator && item.IsVisible && item.info.some((i) => i.ischecked == true);
                    this.setIsTIPanelAvailable(isTiPanelVisible, info)
                }
                if (item.blockType === 'TI') {
                    const isTechPanelVisible = !economicindicator && item.IsVisible && item.info.some((i) => i.ischecked == true);
                    this.setIsTechPanelAvailable(isTechPanelVisible, info)
                }
                if (item.blockType === 'KPI') {
                    const isKPIPanelVisible = !economicindicator && item.IsVisible && !item.isDisabled && item.info.some((i) => i.ischecked == true);
                    this.setIsKPIPanelAvailable(isKPIPanelVisible, info)
                }
                if (item.blockType === 'ED') {
                    const isExternalPanelVisible = !economicindicator && item.IsVisible && item.info.some((i) => i.ischecked == true);
                    this.setIsExternalPanelAvailable(isExternalPanelVisible, info)
                }
            }
        });
    }

    isAllDataReady(nav = NavType.NavSymbol) {
        if (nav === NavType.NavSymbol) {
            const isPricePanelAndFinancialDataReady = this.isTrackTypeDateReady(DatagraphDataType.PricePanelAndFinancial);
            if (isPricePanelAndFinancialDataReady) {
                let isDataReady = true,
                    isAnnotationDataReady = true,
                    isFactorDataReady = true,
                    isFinancialOwnershipDataReady = true,
                    isTIPanelDataReady = true,
                    isKPIPanelDataReady = true,
                    isExternalDataReady = true;

                if (this.getIsShowFinancialOwnershipBlock()) {
                    isFinancialOwnershipDataReady = this.isTrackTypeDateReady(DatagraphDataType.FinancialOwnership);
                }
                if (isDataReady && this.getIsShowSummaryBlock() && !this.isPeriodicityChange && !this.isDayChange) {
                    isDataReady = this.isTrackTypeDateReady(DatagraphDataType.SummaryBlock);
                }
                
                const isDatagraphRender = this.isTrackTypeDateReady(DatagraphDataType.DatagraphRender);
                return isDataReady && isFinancialOwnershipDataReady && isDatagraphRender && isTIPanelDataReady && isFactorDataReady && isKPIPanelDataReady && isExternalDataReady && isAnnotationDataReady;
            } else {
                return false;
            }
        } else if (nav === NavType.NavLists) {
            return true;
        }

        return false;
    }

    /* Timer track for Print Begin */
    trackChartLoadingTime() {
        // if (this.trackTime && PrintMode.printing) {
            if (this.trackTime) {
            clearTimeout(this.timeout);
            this.startTimer();
        }
    }

    startTimer() {
        this.timeout = setTimeout(() => {
            if (this.isMounted && this.isAllDataReadyForPrint()) {
                this.trackTime = false;
                ConsoleStore.setChartLoaded();
                console.log("PRINT:--Tracker -- div is set for ", this.currentSymbol);
                clearTimeout(this.timeout);
            }
            else {
                this.startTimer();
            }
        }, this.delay);
    }

    isAllDataReadyForPrint() {
        if (!StringUtil.isEmpty(this.trackDataList)) {
            const isPricePanelDataReady = this.getIsReadyForPrint(DatagraphDataType.PricePanelAndFinancial);

            if(this.priceDataNotAvailable) {
                return true;
            }

            if (isPricePanelDataReady) {
                let isFinancialOwnershipDataReady = true,
                    isSummaryBlockDataReady = true,
                    isSummaryBlockImageReady = true,
                    isFactorDataReady = true,
                    isTIPanelDataReady = true,
                    isTechPanelDataReady = true,
                    isKPIPanelDataReady = true,
                    isExternalPanelReady = true,
                    isExternalDataEventsReady = true,
                    isAnnotationDataReady = true,
                    isAlertImageReady = true;

                if (this.getIsShowFinancialOwnershipBlock()) {
                    isFinancialOwnershipDataReady = this.getIsReadyForPrint(DatagraphDataType.FinancialOwnership);
                    console.log("PRINT:--Tracker--isFinancialOwnershipDataReady for ", this.currentSymbol, " - ", isFinancialOwnershipDataReady)
                }
                if (this.getIsShowSummaryBlock()) {
                    isSummaryBlockDataReady = this.getIsReadyForPrint(DatagraphDataType.SummaryBlock);
                    console.log("PRINT:--Tracker--isSummaryBlockDataReady for ", this.currentSymbol, " - ", isSummaryBlockDataReady)
                }
                if (this.getIsShowSummaryBlock() && this.getIsImageReadyForPrint()) {
                    isSummaryBlockImageReady = this.getIsImageReadyForPrint();
                    console.log("PRINT:--Tracker--isSummaryBlockImageReady for ", this.currentSymbol, " - ", isSummaryBlockDataReady)
                }
                if (this.isFactorEnable()) {
                    isFactorDataReady = this.getIsReadyForPrint(DatagraphDataType.Factor);
                    console.log("PRINT:--Tracker--isFactorDataReady for ", this.currentSymbol, " - ", isFactorDataReady)
                }
                if (this.getIsShowTIPanel()) {
                    isTIPanelDataReady = this.getIsReadyForPrint(DatagraphDataType.TIPanel);
                    console.log("PRINT:--Tracker--isTIPanelDataReady for ", this.currentSymbol, " - ", isTIPanelDataReady)
                }
                if (this.getIsShowTechPanel()) {
                    isTechPanelDataReady = this.getIsReadyForPrint(DatagraphDataType.TechnicalIndicators);
                    console.log("PRINT:--Tracker--isTechPanelDataReady(RSI, MACD, Stochastics) for ", this.currentSymbol, " - ", isTechPanelDataReady)
                }
                if (this.getIsShowKPIPanel()) {
                    isKPIPanelDataReady = this.getIsReadyForPrint(DatagraphDataType.KPIPanel);
                    console.log("PRINT:--Tracker--isKPIPanelDataReady for ", this.currentSymbol, " - ", isKPIPanelDataReady)
                }
                if (this.getIsShowExternalTIPanel()) {
                    isExternalPanelReady = this.getIsReadyForPrint(DatagraphDataType.ExternalDataTIPanel);
                    console.log("PRINT:--Tracker--isExternalPanelReady for ", this.currentSymbol, " - ", isExternalPanelReady)
                }
                if (this.isExternalDataEventsEnabled) {
                    isExternalDataEventsReady = this.getIsReadyForPrint(DatagraphDataType.ExternalDataEvents);
                    console.log("PRINT:--Tracker--isExternalDataEventsReady for ", this.currentSymbol, " - ", isExternalDataEventsReady)
                }
                if (DataGraphStore.getSymbolInfo() && DataGraphStore.getSymbolInfo().SymTypeEnum !== SymbolType.FUND) {
                    isAnnotationDataReady = this.getIsReadyForPrint(DatagraphDataType.Annotation);
                    console.log("PRINT:--Tracker--isAnnotationDataReady for ", this.currentSymbol, " - ", isAnnotationDataReady);
                    isAlertImageReady = this.getIsAlertImageReady();
                    console.log("PRINT:--Tracker--isAlertImageReady for ", this.currentSymbol, " - ", isAlertImageReady)
                }

                const isDatagraphReady = this.getIsReadyForPrint(DatagraphDataType.DatagraphRender);
                console.log("PRINT:--Tracker--isDatagraphReady for ", this.currentSymbol, " - ", isDatagraphReady)

                console.log("PRINT:--Tracker--*** isAllDataReady *** for ", this.currentSymbol, " - ", isDatagraphReady && isAnnotationDataReady && isFinancialOwnershipDataReady && isSummaryBlockDataReady && isFactorDataReady && isTIPanelDataReady && isKPIPanelDataReady && isExternalPanelReady && isExternalDataEventsReady && isAlertImageReady)
                return isDatagraphReady && isAnnotationDataReady && isFinancialOwnershipDataReady && isSummaryBlockDataReady && isSummaryBlockImageReady && isFactorDataReady && isTIPanelDataReady && isTechPanelDataReady && isKPIPanelDataReady && isExternalPanelReady && isExternalDataEventsReady && isAlertImageReady;
            } else {
                return false;
            }
        }
        else
            return false;
    }

    getIsReadyForPrint(type) {
        if (this.trackDataList[type] && this.trackDataList[type].isApiErrorTimeTrack) {
            console.log("PRINT:--Tracker--*** Api Error *** for ", this.currentSymbol, " - ", type)
        }
        if (this.trackDataList[type] && this.trackDataList[type].isRenderErrorTimeTrack) {
            console.log("PRINT:--Tracker--*** Data processing Error *** for ", this.currentSymbol, " - ", type)
        }

        if (this.trackDataList[type]) {
            if (this.trackDataList[type].isApiErrorTimeTrack || this.trackDataList[type].isRenderErrorTimeTrack) {
                return true;
            }
            else if (this.trackDataList[type].IsDataReady) {
                if (this.trackDataList[type].RenderBeginTime) {
                    return this.trackDataList[type].isReadyForPrint;
                }
                else {
                    console.log("PRINT:--Tracker--*** Data processing Not initiated *** for ", this.currentSymbol, " - ", type)
                    return true;
                }
            }

            return false;
        }
        else {
            console.log("PRINT:--Tracker--*** Does not exist in track list *** for ", this.currentSymbol, " - ", type);
            return true;
        }
    }

    /* Timer track for Print End */

    setSymbolType(SymType) {
        if (SymType === SymbolType.USSTOCK) {
            if(process.env.BRANCH_ENV && process.env.BRANCH_ENV.toUpperCase() === "PROD"){
                this.delay = 3000;
            }
            else{
                this.delay = 5000;
            }
        }
    }

    addEventListener() {
        document.body.addEventListener("mousemove", this.endUpTimeTracker, false);
        document.body.addEventListener("mousedown", this.endUpTimeTracker, false);
        document.body.addEventListener('keydown', this.endUpTimeTracker, false);
    }

    removeEventListener() {
        document.body.removeEventListener("mousemove", this.endUpTimeTracker, false);
        document.body.removeEventListener("mousedown", this.endUpTimeTracker, false);
        document.body.removeEventListener('keydown', this.endUpTimeTracker, false);
    }
};

const timeTrackingWindow = new TimeTrackingWindow();
export default timeTrackingWindow;