const ProdServerURL = {
    'panaray.com': 'services.panaray.com',
    'panaray.cn': 'services.panaray.cn',
    'www.panaray.cn': 'services.panaray.cn',
    'beta.panaray.com': 'services.panaray.com',
    'global.panaray.com': 'global.panaray.com',
    'global.panaray.cn': 'global.panaray.cn',
    'localhost': 'services.panaray.com',
    'panaraywebapp1': 'services.panaray.com',
    'panaraywebapp2': 'services.panaray.com',
    'panaraywebapp3': 'services.panaray.com',
    'panaraywebapp4': 'services.panaray.com',
    'panaraywebapp5': 'services.panaray.com',
    'panaraywebbeta1': 'services.panaray.com',
    'panaraywebbeta2': 'services.panaray.com'
    
  }
export default ProdServerURL;