import React from "react";
import BrowserUtil from "BrowserUtil";
import { find } from "underscore";
import ListStore from "ListStore";
import ListActions from "ListActions";

//let cellLimit = 3;
export default class ExternalRowItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHighLighted: false,
            isSelected: props.isSelected
        };
        this.staticColsWidth = 0;
        this.cellLimit = (this.props.selectedListId == -1) ? 2 : 3;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResizeWindow);
        this.listExplorerElm = document.getElementById('listExplorer');
        this.horizontalScrollElm = document.getElementById('gridHorizontalScroll');
        this.toolTip = document.getElementById('grid-row-tooltip');
        if (!this.props.gridTypeTable) {
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.isSelected != undefined && props.isSelected !== this.state.isSelected) {
            this.setSelectedState(props.isSelected);
        }
    }

    componentDidUpdate() {
        if (!this.props.gridTypeTable) {
        }
    }

    getCells(data) {
        if (data == undefined) return "Loading";
        let cells = [];
        let colWidth = this.props.getColumnWidth();
        let left=0.0;
        for (let cellIndex = 0; cellIndex < this.props.columns.length; cellIndex++) {
            let cell = this.setCellContent(cellIndex, data,colWidth,left);
            left += colWidth;
            cells.push(cell);
        }
        return cells;
    }

    setCellContent(cellIndex, data,colWidth,left) {
        let rowData = data;
        try {
            let colitem = this.props.columns[cellIndex];
            if (colitem) {
                let cellData = rowData[colitem.ordinal];
                let textAlign = 'center';
                let displayValue = '';
                let paddingLeft = 10;
                let hoverPad = 2;
                if ((BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape")) {
                    hoverPad += 1;
                }
                let textWidth = + paddingLeft + hoverPad;
                if (colWidth < textWidth) {
                    textWidth = colWidth;
                }
                let cellStyle = {
                    width: colWidth + "px",
                    minWidth: 100 + "px",
                    //paddingLeft: paddingLeft + "px",
                    left: left + "px"
                }
                if (cellData) {
                    if (colitem.type === 20) {
                        displayValue = cellData.numericValue;
                    }
                    else {
                        displayValue = cellData.stringValue;
                    }
                }
                return (this.getCellByType(cellIndex, displayValue, colitem, cellStyle));
            }
        }
        catch (e) {
            console.log('error', e);
        }
    }
    plotExternalSymbol(sym){
        ListStore.plotSymbol(sym);
        ListActions.updateMiniList(sym);
      }
    
    getCellByType(cellIndex, displayValue, colitem, cellStyle, cellStyleNew) {
        let symbolClass = colitem.isSymbolColumn ? "link-symbol" : '';
        let tupleClass = 'edu-cell';
        if(/\d+s/.test(colitem.esFieldName))
            tupleClass += ' symbol';
        else if(/dateField/.test(colitem.esFieldName) || /\d+d/.test(colitem.esFieldName))
            tupleClass += ' date';
        else if(/\d+n/.test(colitem.esFieldName))
            tupleClass += ' numeric';
        else if( /label/.test(colitem.esFieldName))
            tupleClass += ' labels';
        else if(/category/.test(colitem.esFieldName))
            tupleClass += ' category';
        else
            tupleClass = 'edu-cell';
        // onclick symbol column navigation
        // onClick={colitem.isSymbolColumn ?  () => this.plotExternalSymbol(displayValue) : undefined} 
        return (<div key={colitem.ordinal + cellIndex} className={tupleClass} style={cellStyle}>
            <span className={symbolClass} style={cellStyleNew}>
                {displayValue}
            </span>
        </div>);
    }

    render() {
        let cells = this.getCells(this.props.data);
        return (
            <div className={'edu-row'} data-id={this.props.dataId}>
                {cells}
            </div>
        )

    }
}
