import Serializable from "Serializable";
class PatternRecognitionSettings extends Serializable {

    constructor(isVisible) {
        super();
        this.IsAvailable = true;
        this.IsVisible = isVisible;
        this.TightAreaPattern = false;
        this.ChannelLines = false;
        this.ClimaxTop = false;
        this.AdvancePattern = false;
        this.PowerFromPivot = true;
        this.FlatPattern = true;
        this.ConsolidationPattern = true;
        this.CupPattern = true;
        this.DoubleBottoms = true;
        this.AscendingBasePattern = true;
        this.IPOBasePattern = true;
        this.lineColor = "PFFFFFF000000";
        this.lineTColor = "PFFFFFF000000";
        this.profitStart = 20;
        this.profitEnd = 25;
        this.pivotStart = 0;
        this.pivotEnd = 5
        this.lossStart = -8;
        this.lossEnd = -5;
        this.showKPR = true;
    }

    onSeralisedInfoRecieved(info) {
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
        this.IsAvailable = true;
        this.TightAreaPattern = info.TightAreaPattern !== undefined ? info.TightAreaPattern : false;
        this.ChannelLines = info.ChannelLines !== undefined ? info.ChannelLines : false;
        this.ClimaxTop = info.ClimaxTop !== undefined ? info.ClimaxTop : false;
        this.AdvancePattern = info.AdvancePattern !== undefined ? info.AdvancePattern : false;
        this.PowerFromPivot = info.PowerFromPivot !== undefined ? info.PowerFromPivot : true;
        this.FlatPattern = info.FlatPattern !== undefined ? info.FlatPattern : true;
        this.ConsolidationPattern = info.ConsolidationPattern !== undefined ? info.ConsolidationPattern : true;
        this.CupPattern = info.CupPattern !== undefined ? info.CupPattern : true;
        this.DoubleBottoms = info.DoubleBottoms !== undefined ? info.DoubleBottoms : true;
        this.AscendingBasePattern = info.AscendingBasePattern !== undefined ? info.AscendingBasePattern : true;
        this.IPOBasePattern = info.IPOBasePattern !== undefined ? info.IPOBasePattern : true;
        this.lineColor = info.lineColor !== undefined ? info.lineColor : "PFFFFFF000000";
        this.lineTColor = info.lineTColor !== undefined ? info.lineTColor : "PFFFFFF000000";
        this.profitStart = info.profitStart !== undefined ? info.profitStart : 20;
        this.profitEnd = info.profitEnd !== undefined ? info.profitEnd : 25;
        this.pivotStart = info.pivotStart !== undefined ? info.pivotStart : 0;
        this.pivotEnd = info.pivotEnd !== undefined ? info.pivotEnd : 5
        this.lossStart = info.lossStart !== undefined ? info.lossStart : -8;
        this.lossEnd = info.lossEnd !== undefined ? info.lossEnd : -5;
        this.showKPR = info.showKPR !== undefined ? info.showKPR : true;

    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            TightAreaPattern: this.TightAreaPattern,
            ChannelLines: this.ChannelLines,
            PowerFromPivot: this.PowerFromPivot,
            ClimaxTop: this.ClimaxTop,
            AdvancePattern: this.AdvancePattern,
            FlatPattern: this.FlatPattern,
            ConsolidationPattern: this.ConsolidationPattern,
            CupPattern: this.CupPattern,
            DoubleBottoms: this.DoubleBottoms,
            AscendingBasePattern: this.AscendingBasePattern,
            IPOBasePattern: this.IPOBasePattern,
            lineColor: this.lineColor,
            lineTColor: this.lineTColor,
            profitStart: this.profitStart,
            profitEnd: this.profitEnd,
            pivotStart: this.pivotStart,
            pivotEnd: this.pivotEnd,
            lossStart: this.lossStart,
            lossEnd: this.lossEnd,
            showKPR: this.showKPR
        };
    }

    getObjectMapKey() {
        return "PatternRecognitionSettings";
    }
}

export default PatternRecognitionSettings;
