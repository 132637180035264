import BarVisual from "BarVisual";
import BlockType from "BlockType";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import HorizontalLineVisual from "HorizontalLineVisual";
import LayoutFactor from "LayoutFactor";
import RayChart from "RayChart";
import ThemeHelper from "ThemeHelper";
import { TranslateHelper } from "../../../../../Utils/TranslateHelper";
import { updateChartRefs } from "../../../../../Actions/PricePanelActions";
import { onInsiderOpenCollapse, updateDimension } from '../../../../../Actions/InsiderChartActions';
import React, { useEffect, useRef } from 'react';

const InsiderChart = (props) => {
    const insiderLine = ThemeHelper.getThemedBrush("insiderLine");
    const mainChart = useRef();

    useEffect(() => {
        props.updateChartRefs(mainChart, BlockType.Insider)
    }, []);
    const barVisual = useRef(null);
    return (
        <>
            <div className="symbol-chart-stack symbol-insider-stack" key="insiderchart" style={{ flex: LayoutFactor["Insider"] }}>
                <div className="chartWrapper symbol-chart-wrap">
                    <RayChart ref={mainChart} scale={props.scale}
                        height={props.Height}
                        width={props.Width}
                        nodeWidth={props.nodeWidth}
                        showHorizontalGrid={false}
                        leftScaleWidth={props.leftScaleWidth}
                        TimeLine={props.TimeLine}
                        righscaleWidth={props.rightScaleWidth}
                        onChartResize={props.updateDimension}
                        trackpriceReqArgs={{ chartType: BlockType.Insider, barVisual }}>
                        <BarVisual DataSource={props.InsiderData} ref={barVisual} />
                        <HorizontalLineVisual x2={props.startXPoint} strokeWidth={'1px'} lineColor={insiderLine} dashArray={'0, 0'} />
                    </RayChart>
                    <Button onClick={props.onInsiderOpenCollapse} data-disable-track-price="true" bsSize="xsmall" className="collapse-block">
                        <div className="collapse-btn"></div>
                    </Button>
                    <div className="chart-panel-right-content">
                        <div className="symbol-chart-title gray-title">
                            <div className="text">{TranslateHelper.INSIDERS}</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

const mapStateToProps = ({ DatagraphReducers, appColor }) => {
    const { scale, Height, Width, InsiderData, } = DatagraphReducers.insiderChartReducer;
    const { nodeWidth, rightScaleWidth, leftScaleWidth, startXPoint } = DatagraphReducers.DataGraphReducer;
    const TimeLine = DatagraphReducers.TimeLineReducer.TimeLineData.dates;
    const { isThemeChanged } = appColor;
    return { scale, Height, Width, InsiderData, TimeLine, nodeWidth, rightScaleWidth, leftScaleWidth, startXPoint, isThemeChanged }
}

const mapDispatchToProps = (dispatch) => ({
    onInsiderOpenCollapse: () => dispatch(onInsiderOpenCollapse(BlockType.Insider, false)),
    updateDimension: (dimension) => dispatch(updateDimension(dimension)),
    updateChartRefs: (mainChart, blockType) => dispatch(updateChartRefs(mainChart, blockType))
})

export default connect(mapStateToProps, mapDispatchToProps)(InsiderChart);