import { RotationGraphConstants } from "Constants/RotationGraphConstants";
const { ActionTypes } = RotationGraphConstants;
import ContextMenuConstants from "ContextMenuConstants";
import { RotationMenuType } from "Constants/RotationMenuType";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import CompareTabType,{CompareMenuType} from "CompareTabType";
import { extend, map } from "underscore";
extend(ActionTypes, SettingsConstants.ActionTypes);

const initData = {
    isContextMenuVisible: false,
    contextMenuList: [],
    contextMenuObj: {}
}

const initialState = {
    selectedMenuTab : CompareMenuType.Symbols,
    SymbolsData : {...initData},
    GroupsData : {...initData},
    dimensions: {
        width: 0,
        height: 0,
    },
    selectedTab : null
}

const CenterRotationReducer = (state = initialState, action) => {

    const setStateData = (obj) => {

        if (state.selectedMenuTab === CompareMenuType.Symbols) {
          map(obj, (value, property) => state.SymbolsData[property] = value);
        }
        else {
          map(obj, (value, property) => state.GroupsData[property] = value);
        }
    
        return { ...state };
      }

    switch (action.type) {
        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
            return {
                ...state,
                selectedTab: action.selectedTab
            }

        case ActionTypes.SET_COMPARE_MENUTAB:
            if (state.selectedTab === CompareTabType.RotationGraph) {
                return {
                    ...state,
                    selectedMenuTab: action.menuTab
                }
            } else {
                return { ...state }
            }

        case ActionTypes.UPDATE_DIMENSION_CHANGE:
            return {...state,
                dimensions: action.dimensions
            };

        case ActionTypes.UPDATE_CONTEXT_MENU_VISIBILTY:
              return setStateData({
                isContextMenuVisible: action.isContextMenuVisible
            });

        case ActionTypes.PREPARE_CONTEXT_MENU_VIEW:
            const contextMenu = [];
            switch (action.contextMenuObj.selectedGraphItem) {
                case "graphLine":
                    contextMenu.push({ contextName: ContextMenuConstants.ROTATION_GRAPH_VIEW_SYMBOL, action: ContextMenuConstants.ROTATION_GRAPH_VIEW_SYMBOL });
                    if (action.listItemType === RotationMenuType.Stocks) {
                        contextMenu.push({ contextName: !action.contextMenuObj.item.isFlagged ? ContextMenuConstants.ROTATION_GRAPH_FLAG : ContextMenuConstants.ROTATION_GRAPH_REMOVE_FLAG, action: ContextMenuConstants.ROTATION_GRAPH_FLAG });
                    }
                    else {
                        contextMenu.push({ contextName: ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS, action: ContextMenuConstants.ROTATION_GRAPH_VIEW_CONSTITUENTS });
                    }
                    break;
                case "svgElement":
                    contextMenu.push({ contextName: ContextMenuConstants.ROTATION_GRAPH_RESET_TO_100, action: ContextMenuConstants.ROTATION_GRAPH_RESET_TO_100 });
                    break;
                default:
                    break;
            }
            return setStateData({
                contextMenuList: contextMenu,
                contextMenuObj: action.contextMenuObj
            });
        default:
            return state
    }
}

export default CenterRotationReducer;