
import { AnnotationConstant } from "../../Constants/AnnotationConstants";
import { TextInfo } from "../../Models/AnnotationModels/TextModelInfo";
const { ActionTypes } = AnnotationConstant;

const texts = []

const textReducer = (state = texts, action) => {

    switch (action.type) {
        case ActionTypes.INIT_LINE_COLLECTION:
            return action.EllipseCollection;
        case ActionTypes.HANDLE_TEXT_CLICK_SUCCESS:
            return [...state, action.text]
        case ActionTypes.HANDLE_TEXT_DRAW_SUCCESS:
            return state.map((item) => (item.id === action.text.id) ? action.text : item)
        case ActionTypes.HANDLE_TEXT_SAVE_ID_SUCCESS:
            return action.texts
        case ActionTypes.HANDLE_TEXT_TRANSFORM_SUCCESS:
            return state.map((item) => (item.id === action.text.id) ? action.text : item)
        case ActionTypes.HANDLE_REMOVE_TEXT_DRAW:
            return state.filter((item) => item.id !== action.annotSelectedId)
        case ActionTypes.REMOVE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new TextInfo, { ...item, isDeleted: true }) : item)
        case ActionTypes.RESTORE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new TextInfo, { ...item, isDeleted: false }) : item)
        case ActionTypes.REMOVE_TEMP_TEXT_ANNOTATION:
            return state.filter((item) => !action.annotRemoveId.includes(item.id))
        case ActionTypes.CLEAR_ANNOTATIONS:
            return [];
        default:
            return state
    }
}

export default textReducer;