import {isString, each, extend, indexOf, map, isArray} from "underscore";
import validator from 'validator';
import moment from "moment";
import BaseServiceApi from 'BaseServiceApi';
import ConsoleStore from "ConsoleStore";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListManagerTabType from "ListManagerTabType";
import SettingsStore from "SettingsStore";
import ListStore from "ListStore";
import LocalizationStore from "LocalizationStore";
import StringUtil from "StringUtil";
import FormatterUtil from "FormatterUtil";

const ValueOperatorType = BaseServiceApi.rayData["ValueOperatorType"];
const ListColumnDataType = BaseServiceApi.rayData["ListColumnDataType"];
const ListColumnFilterType = BaseServiceApi.rayData["ListColumnFilterType"];
let _open = false;

const specialDataItemIds = [2367, -4000025];

class ColumnCustomFilterControlClass {

    getName(value) {
        const key1 = `LMSymbolType_${  value.replace(/ /g, "").toUpperCase()}`;
        const key2 = `FilterBrowser_IndustryGroup_${  value.replace(/ /g, "").toUpperCase()}`;
        const key3 = `LM_GICSSubIndustry_${  value.replace(/ /g, "").toUpperCase()}`;
        const key4 = `LM_GICSIndustry_${  value.replace(/ /g, "").toUpperCase()}`;
        const key5 = `LM_GICSIndustryGroup_${  value.replace(/ /g, "").toUpperCase()}`;
        const key6 = `LM_GICSSector_${  value.replace(/ /g, "").toUpperCase()}`;
        const key7 = `LM_MajorIndustryName_${  value.replace(/ /g, "").toUpperCase()}`;
        const key8 = `LM_SectorName_${  value.replace(/ /g, "").toUpperCase()}`;
        const key9 = `LMSymbolType_${  value.replace(/ /g, "").toUpperCase()}`;
        const key10 = `Es_${  value.replace(/ /g, "")}`;
        let name;

        // let key2  = "FilterBrowser_IndustryGroup_"+(value.value).replace(/ /g,"").toUpperCase();
        if (LocalizationStore.getTranslatedData(key1, value) !== value) {
            name = LocalizationStore.getTranslatedData(key1, value);
        } else if (LocalizationStore.getTranslatedData(key2, value) !== value) {
            name = LocalizationStore.getTranslatedData(key2, value);
        } else if (LocalizationStore.getTranslatedData(key3, value) !== value) {
            name = LocalizationStore.getTranslatedData(key3, value)
        } else if (LocalizationStore.getTranslatedData(key4, value) !== value) {
            name = LocalizationStore.getTranslatedData(key4, value)
        } else if (LocalizationStore.getTranslatedData(key5, value) !== value) {
            name = LocalizationStore.getTranslatedData(key5, value)
        } else if (LocalizationStore.getTranslatedData(key6, value) !== value) {
            name = LocalizationStore.getTranslatedData(key6, value)
        } else if (LocalizationStore.getTranslatedData(key7, value) !== value) {
            name = LocalizationStore.getTranslatedData(key7, value)
        } else if (LocalizationStore.getTranslatedData(key8, value) !== value) {
            name = LocalizationStore.getTranslatedData(key8, value)
        } else if (LocalizationStore.getTranslatedData(key9, value) !== value) {
            name = LocalizationStore.getTranslatedData(key9, value)
        } else if (LocalizationStore.getTranslatedData(key10, value) !== value) {
            name = LocalizationStore.getTranslatedData(key10, value)
        } else {
            name = value;
        }
        return name;
    }

    getValues(value) {
        if (isString(value)) {
            const values = value.split(",").map((item) => item.trim());
            return values;
        }
        return value;
    }

    toDisplayTextOperator(valueOperator) {
        switch (valueOperator) {
            case ValueOperatorType.EQ_ValueOperator:
                {
                    return "=";
                }
            case ValueOperatorType.GE_ValueOperator:
                {
                    return "≥";
                }
            case ValueOperatorType.GT_ValueOperator:
                {
                    return ">";
                }
            case ValueOperatorType.LE_ValueOperator:
                {
                    return "≤";
                }
            case ValueOperatorType.LT_ValueOperator:
                {
                    return "<";
                }
            case ValueOperatorType.NOTEQ_ValueOperator:
                {
                    return "≠";
                }
            case ValueOperatorType.BETWEEN_ValueOperator:
                {
                    return "x-y";
                }
            case ValueOperatorType.CONTAINS_ValueOperator:
                {
                    return LocalizationStore.getTranslatedData("LM_Contains", "Contains");
                }
            case ValueOperatorType.NOTCONTAINS_ValueOperator:
                {
                    return LocalizationStore.getTranslatedData("LM_DoesNotContain", "Does Not Contain");
                }
            default:
                break;
        }

        return null;
    }

    getSupportedValueOperators(colDataType, dataItemId) {
        let _supportedOperatorsMap = [];
        if (specialDataItemIds.includes(dataItemId)) {
            switch (colDataType) {
                case ListColumnDataType.STRING_ListColumnData:
                    if (dataItemId == specialDataItemIds[0]) {
                        _supportedOperatorsMap = [
                            { valueOperatorType: ValueOperatorType.CONTAINS_ValueOperator, operatorString: LocalizationStore.getTranslatedData("LM_Contains", "Contains") },
                            { valueOperatorType: ValueOperatorType.NOTCONTAINS_ValueOperator, operatorString: LocalizationStore.getTranslatedData("LM_DoesNotContain", "Does Not Contain") }];
                        return _supportedOperatorsMap;
                    }
                    break;
                case ListColumnDataType.INT_ListColumnData:
                    if (dataItemId == specialDataItemIds[1]) {
                        _supportedOperatorsMap = [
                            { valueOperatorType: ValueOperatorType.EQ_ValueOperator, operatorString: "=" },
                            { valueOperatorType: ValueOperatorType.NOTEQ_ValueOperator, operatorString: "≠" },
                            { valueOperatorType: ValueOperatorType.GE_ValueOperator, operatorString: "≥" },
                            { valueOperatorType: ValueOperatorType.GT_ValueOperator, operatorString: ">" },
                            { valueOperatorType: ValueOperatorType.LE_ValueOperator, operatorString: "≤" },
                            { valueOperatorType: ValueOperatorType.LT_ValueOperator, operatorString: "<" }
                        ];
                        return _supportedOperatorsMap;
                    }
                    break;
                default:
                    break;
            }
        }
        switch (colDataType) {
            case ListColumnDataType.BOOL_ListColumnData:
                _supportedOperatorsMap = [{ valueOperatorType: ValueOperatorType.EQ_ValueOperator, operatorString: "=" }, { valueOperatorType: ValueOperatorType.NOTEQ_ValueOperator, operatorString: "≠" }];
                return _supportedOperatorsMap;
            case ListColumnDataType.STRING_ListColumnData:
                _supportedOperatorsMap = [
                    { valueOperatorType: ValueOperatorType.EQ_ValueOperator, operatorString: "=" },
                    { valueOperatorType: ValueOperatorType.NOTEQ_ValueOperator, operatorString: "≠" },
                    { valueOperatorType: ValueOperatorType.CONTAINS_ValueOperator, operatorString: LocalizationStore.getTranslatedData("LM_Contains", "Contains") },
                    { valueOperatorType: ValueOperatorType.NOTCONTAINS_ValueOperator, operatorString: LocalizationStore.getTranslatedData("LM_DoesNotContain", "Does Not Contain") }];
                return _supportedOperatorsMap;
            case ListColumnDataType.DOUBLE_ListColumnData:
            case ListColumnDataType.DATE_ListColumnData:
            case ListColumnDataType.INT_ListColumnData:
                _supportedOperatorsMap = [
                    { valueOperatorType: ValueOperatorType.EQ_ValueOperator, operatorString: "=" },
                    { valueOperatorType: ValueOperatorType.NOTEQ_ValueOperator, operatorString: "≠" },
                    { valueOperatorType: ValueOperatorType.GE_ValueOperator, operatorString: "≥" },
                    { valueOperatorType: ValueOperatorType.GT_ValueOperator, operatorString: ">" },
                    { valueOperatorType: ValueOperatorType.LE_ValueOperator, operatorString: "≤" },
                    { valueOperatorType: ValueOperatorType.LT_ValueOperator, operatorString: "<" },
                    { valueOperatorType: ValueOperatorType.BETWEEN_ValueOperator, operatorString: "x - y" }];
                return _supportedOperatorsMap;
            default: break;
        }
    }

    getColumnText(dataItemId, screenSummary = false) {
        const columns = this.columns;
        const self = this;
        let diplayText = "";
        let displayTexts = [];
        each(columns, (col) => {
            if (dataItemId == col.dataItemId) {
                if (col.dataType == ListColumnDataType.STRING_ListColumnData || col.dataType == ListColumnDataType.BOOL_ListColumnData || col.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) {
                    if (col.FieldTagExtList) {
                        if (col.FieldTagExtList.values == "") {
                            diplayText = "";
                            return;
                        }
                        diplayText = `${self.toDisplayTextOperator(col.FieldTagExtList.valueOperator)  } `;
                        let values = col.FieldTagExtList.values;
                        if (dataItemId == "-7") {
                            values = self.getTextOfValues(values);
                        }
                        const lengthVal = values.length - 1;
                        each(values, (val, i) => {
                            val = this.getName(val);
                            (lengthVal == i)
                                ? diplayText += val
                                : diplayText += `${val  }, `;
                        });
                    }
                }
                else {
                    if (col.FieldTagExtListMultiple) {
                        if (col.FieldTagExtListMultiple.length > 1 && screenSummary == false) {
                            diplayText = LocalizationStore.getTranslatedData("LM_tp_DGHeaderCriteria", "{0} Criteria").replace("{0}", col.FieldTagExtListMultiple.length);
                        }
                        else {
                            each(col.FieldTagExtListMultiple, (fdl) => {
                                if (fdl.values == "") {
                                    displayTexts = [];
                                    return;
                                }
                                diplayText = "";
                                if (fdl.valueOperator != 5) {
                                    diplayText = `${self.toDisplayTextOperator(fdl.valueOperator)  } `;
                                    if (fdl.dataItemValue) {
                                        diplayText += ListStore.getDisplayNameforMsItemID(parseInt(fdl.values));
                                    }
                                    else {
                                        const values = fdl.values;
                                        const lengthVal = values.length - 1;
                                        each(values, (val, i) => {
                                            (lengthVal == i)
                                                ? diplayText += self.parseDouble(val, col)
                                                : diplayText += `${self.parseDouble(val, col)  }, `;
                                        });
                                    }
                                    displayTexts.push(diplayText);
                                }
                                else {
                                    const values = fdl.values;
                                    const lengthVal = values.length - 1;
                                    each(values, (val, i) => {
                                        (lengthVal == i)
                                            ? diplayText += self.parseDouble(val, col)
                                            : diplayText += `${self.parseDouble(val, col)  }-`;
                                    });
                                    displayTexts.push(diplayText);

                                }
                            });
                        }

                    }

                }
            }
        });
        if (screenSummary) {
            return displayTexts;
        }
        return diplayText;
    }

    parseDouble(value, col) {
        if (col.dataType === ListColumnDataType.DOUBLE_ListColumnData || col.dataType === ListColumnDataType.INT_ListColumnData) {
            return FormatterUtil.formatNumber(parseFloat(value), 20, true);
        }
        return value;
    }

    setColumnInfoFromStore() {
        const settings = SettingsStore.getConsoleSettings();
        const navListManagerSettings = settings.NavListManagerSettings;
        let columns;
        let columnFilterDatas;
        if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Oneil) {
            columns = ONeilViewStore.getColumnInfo();
            columnFilterDatas = ONeilViewStore.getcolumnFilters();
        }
        if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Ownership) {
            columns = OwnershipViewStore.getColumnInfo();
            columnFilterDatas = OwnershipViewStore.getcolumnFilters();
        }
        if (columnFilterDatas) {
            each(columns, (col) => {
                if (col.dataType == ListColumnDataType.STRING_ListColumnData || col.dataType == ListColumnDataType.BOOL_ListColumnData || col.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) {
                    if (col["FieldTagExtList"]) delete col["FieldTagExtList"]
                    each(columnFilterDatas, (columnFilterData) => {
                        if (columnFilterData.dataItemId == col.dataItemId) {
                            if (isArray(columnFilterData.values) && columnFilterData.values.length > 0)
                                col["FieldTagExtList"] = columnFilterData;

                        }
                    });
                }
                else {
                    if (col["FieldTagExtListMultiple"]) delete col["FieldTagExtListMultiple"];
                    const colFilterMultipleData = [];
                    each(columnFilterDatas, (columnFilterData) => {
                        if (columnFilterData.dataItemId == col.dataItemId) {
                            if (col.dataType == ListColumnDataType.DATE_ListColumnData) {
                                let formatter = col.formatter.toUpperCase().replace("DD", "D").replace("MM", "M");
                                if (formatter !== "M/D/YYYY") {
                                    formatter = "M/D/YYYY";
                                }
                                columnFilterData.values = map(columnFilterData.values, (val) => {
                                    if (!this.validateDate(val, formatter)) {
                                        return StringUtil.formatDate(val, formatter);
                                    }
                                    else {
                                        return val;
                                    }
                                });
                            }
                            if (columnFilterData.values.length > 0)
                                colFilterMultipleData.push(columnFilterData);

                        }
                        if (colFilterMultipleData.length > 0) {
                            col["FieldTagExtListMultiple"] = colFilterMultipleData;
                        }

                    });

                }
            });
        }
        this.columns = columns;
    }

    getColumnValue(dataItemId) {
        const columns = this.columns;
        let activeColumnData = null;
        each(columns, (col) => {
            if (dataItemId == col.dataItemId) {
                if (col.dataType == ListColumnDataType.STRING_ListColumnData || col.dataType == ListColumnDataType.BOOL_ListColumnData || col.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) {
                    activeColumnData = col.FieldTagExtList;
                }
                else {
                    activeColumnData = col.FieldTagExtListMultiple;
                }
            }
        });
        return activeColumnData;
    }

    async setFieldTagExtListToColumn(operator, value, colInfo, pickList, ObjerrorMessage, metricItemName = null, isDataItemValue = false, multipleData = 0, multipleDataItems = null) {
        const _columns = this.columns;
        ObjerrorMessage.errorMessage = "";
        if (colInfo.dataType == ListColumnDataType.STRING_ListColumnData || colInfo.dataType == ListColumnDataType.BOOL_ListColumnData || pickList == true) {
            const ObjerrorMessage = { errorMessage: "" };
            let columnFilterData = this.getColumnFilterUpdated(parseInt(operator), value, colInfo, false, null, null, ObjerrorMessage);
            each(_columns, (col) => {
                if (columnFilterData.dataItemId == col.dataItemId) {
                    if (!col["FieldTagExtList"]) {
                        this.trackUsage();
                    }
                    columnFilterData = (pickList == true) ? extend(columnFilterData, { isPickList: true }) : extend(columnFilterData, { isPickList: false });
                    if (ObjerrorMessage.errorMessage == "") {
                        if (isArray(columnFilterData.values) && columnFilterData.values.length > 0 && columnFilterData.values[0] !== "")
                            col["FieldTagExtList"] = columnFilterData;
                        else if (col.FieldTagExtList) delete col.FieldTagExtList;
                    }
                }
            });
        }
        else {
            if (multipleDataItems != null) {
                const colfilterDatasForMultiple = [];
                let filterAndValueArray = [];
                const columnDataItemId = colInfo.dataItemId;
                for (let i = 0; i < multipleData; i++) {
                    const singleRowData = multipleDataItems[`k${  parseInt(i + 1)}`];
                    ObjerrorMessage.errorMessage = "";
                    let columnFilterDataObj = this.getColumnFilterUpdated(parseInt(singleRowData.operator), singleRowData.inputValue, colInfo, singleRowData.isDataItemValue, singleRowData.inputValue, singleRowData.metricItemName, ObjerrorMessage);
                    let filterAndValue = `${columnFilterDataObj.valueOperator}+${columnFilterDataObj.values}`;
                    columnFilterDataObj = extend(columnFilterDataObj, { isPickList: false });
                    if (ObjerrorMessage.errorMessage == "") {
                        if(columnFilterDataObj && isArray(columnFilterDataObj.values) && columnFilterDataObj.values.length > 0 && columnFilterDataObj.values !== ""
                        && !filterAndValueArray.includes(filterAndValue))
                        {
                            colfilterDatasForMultiple.push(columnFilterDataObj);
                            filterAndValueArray.push(filterAndValue);
                        }
                    }
                    else {
                        return;
                    }
                }
                each(_columns, (col) => {
                    if (columnDataItemId == col.dataItemId) {
                        if (!col["FieldTagExtListMultiple"]) {
                            this.trackUsage();
                        }
                        if (colfilterDatasForMultiple.length > 0)
                            col["FieldTagExtListMultiple"] = colfilterDatasForMultiple;
                        else if (col["FieldTagExtListMultiple"]) delete col["FieldTagExtListMultiple"];

                    }
                });
            }
        }
    }

    getColumnFilters() {
        const columns = this.columns;
        const columnFilters = [];
        each(columns, (col) => {
            if (col.FieldTagExtList)
                columnFilters.push(col.FieldTagExtList);
            else if (col.FieldTagExtListMultiple) {
                each(col.FieldTagExtListMultiple, (fdl) => {
                    columnFilters.push(fdl);
                });
            }
        });

        return columnFilters;
    }

    onClearAllFilter() {
        const activeColumnData = this.columns;
        each(activeColumnData, (col) => {
            if (col.FieldTagExtList) {
                delete col.FieldTagExtList;
            }
            else if (col.FieldTagExtListMultiple) {
                delete col.FieldTagExtListMultiple;
            }
        });
        const adl = null;
        return adl;
    }

    applyFilters() {
        return this.getAddlRequests(true);
    }

    toProtoBufData(data, fullList = null, include = false) {
        if (data == null) {
            return null;
        }
        const ReqProto = BaseServiceApi.rayData["ListColumnFilterData"];
        const listColumnFilterData = new ReqProto();
        if (fullList != null && !include && data.isPickList) {
            listColumnFilterData.include = false;
            listColumnFilterData.dataItemValue = data.dataItemValue;
            for (let i = 0; i < fullList.length; i++) {
                if (indexOf(data.values, fullList[i].toString()) != -1)
                    continue;
                listColumnFilterData.values = data.values;
            }
        }
        else {
            listColumnFilterData.include = true;
            listColumnFilterData.dataItemValue = data.dataItemValue;
            listColumnFilterData.values = this.getValues(data.values);
        }
        listColumnFilterData.dataItemId = data.dataItemId;
        listColumnFilterData.valueOperator = data.valueOperator;

        return listColumnFilterData;
    }

    getTextOfValues(values, titleCase = false) {
        const textValues = [];
        each(values, (val) => {
            let text = "";
            switch (val) {
                case "1":
                    text = "STOCK";
                    break;
                case "14":
                    text = "INDEX";
                    break;
                case "17":
                    text = "INDUSTRY GROUP";
                    break;
                case "100":
                    text = "MUTUAL FUNDS";
                    break;
                case "119":
                    text = "SECTOR";
                    break;
                case "19":
                    text = "CURRENCY";
                    break;
                case "103":
                    text = "CASH SPOT";
                    break;
                case "102":
                    text = "FUTURES CONTRACT";
                    break;
                default:
                    break;
            }
            if (titleCase) {
                text = this.toTitleCase(text);
            }
            textValues.push(text);
        });
        return textValues;
    }
    getValuesForColumnId7(values) {
        const textValues = [];
        each(values, (val) => {
            let text = "";
            switch (val.toUpperCase())//use GetInstrumentTypeFilter instead?
            {
                case "STOCK":
                    text = "1";
                    break;
                case "INDEX":
                    text = "14";
                    break;
                case "INDUSTRY GROUP":
                    text = "17";
                    break;
                case "MUTUAL FUNDS":
                    text = "100";
                    break;
                case "SECTOR":
                    text = "119";
                    break;
                case "CURRENCY":
                    text = "19";
                    break;
                case "CASH SPOT":
                    text = "103";
                    break;
                case "FUTURES CONTRACT":
                    text = "102";
                    break;
                default:
                    break;
            }
            textValues.push(text);
        });
        return textValues;
    }

    toTitleCase(str) {
        const lower = str.toLowerCase();
        return lower.replace(/(^| )(\w)/g, (x) => x.toUpperCase());
    }

    getScreenSummary() {
        const _columns = this.columns;
        const _screenDetails = [];
        const self = this;
        each(_columns, (col) => {
            let criteria;
            if (col.FieldTagExtListMultiple) {
                const criterias = self.getColumnText(col.dataItemId, true);
                let scrObj = {};
                if (criterias != "") {
                    each(criterias, (cr) => {
                        scrObj = {
                            id: col.dataItemId,
                            type: col.displayName,
                            criteria: cr
                        }
                        _screenDetails.push(scrObj);
                    });
                }
            }
            else {
                criteria = self.getColumnText(col.dataItemId);
                let scrObj = {};
                if (criteria != "") {
                    scrObj = {
                        id: col.dataItemId,
                        type: col.displayName,
                        criteria: criteria
                    }
                    _screenDetails.push(scrObj);
                }
            }
        });
        return _screenDetails;
    }

    getAddlRequests(saveColFilters, undo = false) {
        let addlRequests = null;
        const settings = SettingsStore.getConsoleSettings();
        const navListManagerSettings = settings.NavListManagerSettings;
        let columnFilters;
        if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Oneil) {
            columnFilters = undo ? ONeilViewStore.getcolumnFilters() : this.getColumnFilters();
        }
        if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Ownership) {
            columnFilters = undo ? OwnershipViewStore.getcolumnFilters() : this.getColumnFilters();
        }

        if (saveColFilters) {
            if (columnFilters != null && columnFilters.length > 0) {

                const ReqProto = BaseServiceApi.rayData["ListCatalogRequestData"];
                const listCatalogRequestData = new ReqProto();
                const d = [];

                for (let i = 0; i < columnFilters.length; i++) {
                    d.push(this.toProtoBufData(columnFilters[i]));
                }
                listCatalogRequestData.columnFilters = d;
                addlRequests = listCatalogRequestData;
            }
        }
        return addlRequests;
    }

    metricLibOpen() {
        _open = true;
    }


    isMetricLibOpen() {
        const metricVisibilities = ListStore.getMetricLibraryState();
        if (!metricVisibilities) {
            _open = false;
        }
        return _open;
    }


    tryParseBoolean(text, obj) {
        let isBool = false;
        obj.val = false;
        if (!this.isEmpty(text)) {
            isBool = this.getBool(text); // true|false | 1 | 0
            obj.val = this.getBool(text);
            if (!isBool) // yes|no
            {
                isBool = text.toLowerCase() === "yes" || text.toLowerCase() === "no";
                if (isBool)
                    obj.boolValue = true;
                else
                    obj.boolValue = false;
            }
        }

        return isBool;
    }

    getBool(val) {
        const num = +val;
        return !isNaN(num) ? !!num : !!String(val).toLowerCase().replace(!!0, '');
    }

    validateDate(dateValue, dateFormat) {
        dateFormat = dateFormat.toUpperCase();
        const pattern = dateFormat.replace("DD", "D");
        dateFormat = pattern.replace("MM", "M");
        dateFormat = FormatterUtil.getDateFormatAsLocale(dateFormat);
        return moment(dateValue, dateFormat, true).isValid();
    }

    getDateFormat(dataItemId) {
        const columnInfos = this.columns;
        let dateFormat = null;
        let dateFormats = ["M/D/YYYY"];
        each(columnInfos, (columnInfo) => {
            if (columnInfo.dataType == ListColumnDataType.DATE_ListColumnData && columnInfo.dataItemId == dataItemId) {
                dateFormats = columnInfo.formatter.split(" ");
                return;
            }
        });

        dateFormat = dateFormats[0].toUpperCase();
        const pattern = dateFormat.replace("DD", "D");
        dateFormat = pattern.replace("MM", "M");
        dateFormat = FormatterUtil.getDateFormatAsLocale(dateFormat);
        return dateFormat;
    }

    validateNumber(num) {
        return validator.isFloat(num);
    }

    ColumnFilterData(pbData) {
        const _colFilterData = {
            valueOperator: pbData.ValueOperator,
            dataItemId: pbData.DataItemId,
            values: pbData.ValuesList,
            dataItemValue: pbData.DataItemValue,
            metricItemName: pbData.MetricItemName
        };
        return _colFilterData;
    }

    ColumnFilterDataMetric(valueOperator, dataItemId, compareDataItemId, metricItemName, dataType) {
        const vals = [];
        vals.push(compareDataItemId.toString());
        const _colFilterData = {
            valueOperator: valueOperator,
            values: vals,
            dataItemId: dataItemId,
            dataType: dataType,
            metricItemName: metricItemName,
            dataItemValue: true
        };
        return _colFilterData;
    }

    ColumnFilterData(valueOperator, values, dataItemId, dataType) {
        let vals = [];
        if (isArray(values)) {
            vals = values;
        } else if (values !== "") {
            vals.push(values);
        }
        const _colFilterData = {
            valueOperator: valueOperator,
            values: vals,
            dataItemId: dataItemId,
            // displayText = ToDisplayText(this, true, CultureInfo.CurrentCulture, DataType);
            dataType: dataType,
            dataItemValue: false
        };
        return _colFilterData;
    }

    getColumnFilterUpdated(operatorType, text, columnInfo, isDataItemValue, compareDataItemId, metricItemName, ObjerrorMessage, selItemCount = 1) {
        ObjerrorMessage.errorMessage = "";
        let values = [];
        if (columnInfo == null || operatorType == null || text == null)
            return null;
        //let originalDataType = columnInfo.dataType;
        // if (columnInfo.dataItemId == "-7") {
        //     switch (text.toUpperCase())//use GetInstrumentTypeFilter instead?
        //     {
        //         case "STOCK":
        //             text = "1";
        //             break;
        //         case "INDEX":
        //             text = "14";
        //             break;
        //         case "INDUSTRY GROUP":
        //             text = "17";
        //             break;
        //         case "MUTUAL FUNDS":
        //             text = "100";
        //             break;
        //         case "SECTOR":
        //             text = "119";
        //             break;
        //         case "CURRENCY":
        //             text = "19";
        //             break;

        //     }
        // }

        //let bl = this.isEmpty(text);

        if (this.isEmpty(text) || (text.length > 0 && text[0] && text[0].toUpperCase() === "ALL") ) {
            if (!isDataItemValue)
                return this.ColumnFilterData(operatorType, values, columnInfo.dataItemId, columnInfo.dataType);
            else
                return this.ColumnFilterDataMetric(operatorType, columnInfo.dataItemId, compareDataItemId, metricItemName, columnInfo.dataType);
        }

        // List<OperatorType> supportedOperators = SupportedValueOperators(originalDataType, columnInfo.DataItemId);
        // if (supportedOperators == null || !supportedOperators.Contains(operatorType))
        // {
        //     errorMessage = "Operator " + operatorType.OperatorString + " is not supported for this column.";
        // return null;
        // }
        const isPickListEnabled = (columnInfo.colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) ? true : false;
        switch (parseInt(operatorType)) {
            case ValueOperatorType.GE_ValueOperator:
            case ValueOperatorType.GT_ValueOperator:
            case ValueOperatorType.LE_ValueOperator:
            case ValueOperatorType.LT_ValueOperator:
                // there can only be one value
                const aVal = this.parseFilterTextValueSingleEx(text, columnInfo.dataType, columnInfo.dataItemId, isDataItemValue, compareDataItemId, metricItemName, ObjerrorMessage);
                values = aVal;
                break;
            case ValueOperatorType.EQ_ValueOperator:
            case ValueOperatorType.NOTEQ_ValueOperator:
                // there can be more than 1 value

                values = this.parseFilterTextValueMultiple(text, columnInfo.dataType, columnInfo.dataItemId, ',', isDataItemValue, compareDataItemId, metricItemName, isPickListEnabled, selItemCount, ObjerrorMessage);
                break;
            case ValueOperatorType.BETWEEN_ValueOperator:
                // there can only be 2 values
                values = this.parseFilterTextValueDouble(text, columnInfo.dataItemId, columnInfo.dataType, '-', ObjerrorMessage);
                if (this.isEmpty(ObjerrorMessage.errorMessage) && values.length != 2) {
                    ObjerrorMessage.errorMessage = "Please enter the filter expression with 2 values separated by a '-' (dash) operator.";
                }
                break;
            case ValueOperatorType.CONTAINS_ValueOperator:
            case ValueOperatorType.NOTCONTAINS_ValueOperator:

                values = this.parseFilterTextValueMultiple(text, columnInfo.dataType, columnInfo.dataItemId, ',', isDataItemValue, compareDataItemId, metricItemName, isPickListEnabled, selItemCount, ObjerrorMessage);
                break;
            default:
                break;
        }

        let filter = null;
        if (this.isEmpty(ObjerrorMessage.errorMessage) && values != null && values.length > 0) {
            if (!isDataItemValue)
                filter = this.ColumnFilterData(operatorType, values, columnInfo.dataItemId, columnInfo.dataType);
            else
                filter = this.ColumnFilterDataMetric(operatorType, columnInfo.dataItemId, compareDataItemId, metricItemName, columnInfo.dataType);
        }
        return filter;
    }


    parseFilterTextValueMultiple(text, dataType, dataItemId, delimiter, isDataItemValue, compareDataItemId, metricItemName, isPickList, selItemsCount, ObjerrorMessage) {
        ObjerrorMessage.errorMessage = "";

        //Workaround fix - need to change the delimiter type for 4.0
        let multipleText = [];

        if (isPickList) {
            multipleText = text;
        }
        else {
            multipleText.push(text);
        }
        const values = [];
        const self = this;
        let error = false;
        each(multipleText, (aText) => {
            const aVal = self.parseFilterTextValueSingleEx(aText, dataType, dataItemId, isDataItemValue, compareDataItemId, metricItemName, ObjerrorMessage);
            if (self.isEmpty(ObjerrorMessage.errorMessage)) {
                values.push(aVal);
            }
            else {
                error = true;
                return;
            }
        });
        if (error) {
            return null;
        }

        return values;
    }

    parseFilterTextValueSingleEx(text, dataType, dataItemId, isDataItemValue, compareDataItemId, metricItemName, ObjerrorMessage) {
        ObjerrorMessage.errorMessage = "";

        if (isDataItemValue) {
            return compareDataItemId.toString();
        }
        let isNumber;
        switch (dataType) {
            case ListColumnDataType.INT_ListColumnData:
            case ListColumnDataType.DOUBLE_ListColumnData:
                {
                    let val;

                    // Business team require to skip symbol for % and $
                    text = text.replace("%", "").replace("$", "").replace(/,/g, "");

                    isNumber = validator.isFloat(text);
                    val = parseFloat(text);
                    if (!isNumber) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage3", "Acceptable input is numeric.");
                        return null;
                    }
                    if (text.length > 38) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage4", "Input value is too big.");
                        return null;
                    }
                    else {
                        return val.toString();
                    }
                }
            case ListColumnDataType.DATE_ListColumnData:
                {
                    let dt;
                    const dateFormat = this.getDateFormat(dataItemId);
                    const isDate = this.validateDate(text, dateFormat);
                    if (!isDate) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage5", "Acceptable input is date");
                        return null;
                    }
                    dt = moment(text, dateFormat);
                    if (dt.year() < 1800) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage5", "Acceptable input is date");
                        return null;
                    }
                    return dt.format("M/D/YYYY");
                }
            case ListColumnDataType.BOOL_ListColumnData:
                {
                    const obj = {
                        val: null
                    };
                    const isBoolean = this.tryParseBoolean(text, obj);
                    if (isBoolean === true || isBoolean === false) {
                        return text;
                    }
                    else {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage6", "Acceptable input is boolean");
                        return null;
                    }
                }
            case ListColumnDataType.STRING_ListColumnData:
                {
                    return text;
                }
            default:
                break;
        }

        return null;
    }

    parseFilterTextValueSingle(text, dataType, dataItemId, ObjerrorMessage) {
        ObjerrorMessage.errorMessage = "";

        switch (dataType) {
            case ListColumnDataType.INT_ListColumnData:
            case ListColumnDataType.DOUBLE_ListColumnData:
                {
                    let val;

                    // Business team require to skip symbol for % and $
                    text = text.replace("%", "").replace("$", "");

                    const isNumber = validator.isFloat(text);
                    val = parseFloat(text);
                    if (!isNumber) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage3", "Acceptable input is numeric.");
                        return null;
                    }
                    if (text.length > 38) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage4", "Input value is too big.");
                        return null;
                    }
                    else {
                        return dataType == ListColumnDataType.DOUBLE_ListColumnData ? val.toFixed(2).toString() : val.toString();
                    }
                }
            case ListColumnDataType.DATE_ListColumnData:
                {
                    let dt;
                    const dateFormat = this.getDateFormat(dataItemId);
                    const isDate = this.validateDate(text, dateFormat)
                    if (!isDate) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage5", "Acceptable input is date");
                        return null;
                    }
                    dt = moment(text, dateFormat);
                    if (dt.year() < 1800) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage5", "Acceptable input is date");
                        return null;
                    }
                    return dt.format("M/D/YYYY");
                }
            case ListColumnDataType.BOOL_ListColumnData:
                {
                    const obj = {
                        val: null
                    };
                    const isBoolean = this.tryParseBoolean(text, obj);
                    if (!isBoolean) {
                        ObjerrorMessage.errorMessage = LocalizationStore.getTranslatedData("ColumnFilter_errorMessage6", "Acceptable input is boolean");
                        return null;
                    }
                    else {
                        return text;
                    }
                }
            case ListColumnDataType.STRING_ListColumnData:
                {
                    return text.toString();
                }
            default:
                break;
        }

        return null;
    }

    isEmpty(value) {
        return (value == null || value.length === 0);
    }

    parseFilterTextValueDouble(text, dataItemId, dataType, delimiter, ObjerrorMessage) {
        ObjerrorMessage.errorMessage = "";
        if (this.isEmpty(text)) {
            return null;
        }

        const values = [];
        let dateFormat = null;
        if (dataType == ListColumnDataType.DATE_ListColumnData) {
            dateFormat = this.getDateFormat(dataItemId);
        }
        const strShortDatePattern = dateFormat;

        if (dataType == ListColumnDataType.DATE_ListColumnData && strShortDatePattern && strShortDatePattern.includes(delimiter)) {
            const strSubValue = text.split(delimiter);
            if (strSubValue.length == 6) {
                strValue1 = `${strSubValue[0]  }/${  strSubValue[1]  }/${  strSubValue[2]}`;
                strValue2 = `${strSubValue[3]  }/${  strSubValue[4]  }/${  strSubValue[5]}`;

                strValue1 = this.parseFilterTextValueSingle(strValue1, dataType, dataItemId, ObjerrorMessage);
                strValue2 = this.parseFilterTextValueSingle(strValue2, dataType, dataItemId, ObjerrorMessage);

                switchPos = moment(strValue1) > moment(strValue2);

                if (switchPos) {
                    // swap position
                    values.push(strValue2);
                    values.push(strValue1);
                }
                else {
                    values.push(strValue1);
                    values.push(strValue2);
                }
            }
            else {
                ObjerrorMessage.errorMessage = "Invalid filter value";
                return null;
            }
        }
        else {
            //let index;
            //Regex regex = new Regex(@"(^\-?\ ?\d+\.?\,?\d*)-(\-?\ ?\d+\.?\,?\d*)"); // the common number format.
            //Modify by HT,2016/4/1.Now supports the following Decimal symbol:"," ".";Digit grouping symbol:"," "." " "
            let regex = /(^\-?\ ?[\d*\.?\,?\ ?]+)-(\-?\ ?[\d*\.?\,?\ ?]+)$/g;
            while (true) {
                if (dataType == ListColumnDataType.INT_ListColumnData ||
                    dataType == ListColumnDataType.DOUBLE_ListColumnData) {
                    // if ((CultureInfo.CurrentCulture.NumberFormat.NumberNegativePattern == 3  // the format of negative number is 1.1-
                    //     || CultureInfo.CurrentCulture.NumberFormat.NumberNegativePattern == 4)  // the format of negative number is 1.1 -
                    //     &&
                    const ex1 = new RegExp("^([\d*\.?\,?\ ?]+\ ?\-?)-([\d*\.?\,?\ ?]+\ ?\-?)$");
                    const ex2 = new RegExp("^(\([\d*\.?\,?\ ?]+\)|[\d*\.?\,?\ ?]+)-(\([\d*\.?\,?\ ?]+\)|[\d*\.?\,?\ ?]+)$");
                    if (ex1.test(text)) {
                        regex = /(^[\d*\.?\,?\ ?]+\ ?\-?)-([\d*\.?\,?\ ?]+\ ?\-?)$/g;
                    }
                    // else if (CultureInfo.CurrentCulture.NumberFormat.NumberNegativePattern == 0 // the format of negative number is (1.1)
                    //     &&
                    else if (ex2.test(text)) {
                        regex = /(^\([\d*\.?\,?\ ?]+\)|[\d*\.?\,?\ ?]+)-(\([\d*\.?\,?\ ?]+\)|[\d*\.?\,?\ ?]+)$/g;
                    }
                    text = text.replace(" ", "");
                    const matched = regex.exec(text);
                    let leftNumber, rightNumber;
                    leftNumber = parseFloat(matched[1].toString());
                    rightNumber = parseFloat(matched[2].toString());
                    if (validator.isFloat(leftNumber.toString()) && validator.isFloat(rightNumber.toString())) {
                        // add first value to the list after parsing
                        const LeftaVal = this.parseFilterTextValueSingle(leftNumber.toString(), dataType, dataItemId, ObjerrorMessage);
                        values.push(LeftaVal);
                        const RightaVal = this.parseFilterTextValueSingle(rightNumber.toString(), dataType, dataItemId, ObjerrorMessage);
                        // got our 2 values. done with searching
                        let switchPos = false;
                        switch (dataType) {
                            case ListColumnDataType.INT_ListColumnData:
                            case ListColumnDataType.DOUBLE_ListColumnData:
                                switchPos = parseFloat(LeftaVal) > parseFloat(RightaVal);
                                break;
                            default:
                                break;
                        }

                        if (switchPos) {
                            // swap position
                            const tempHolder = values[0];
                            values[0] = RightaVal;
                            values.push(tempHolder);
                        }
                        else {
                            values.push(RightaVal);
                        }
                        break;
                    }
                    else {
                        ObjerrorMessage.errorMessage = "Invalid filter value";
                        break;
                    }
                }
                else if (dataType == ListColumnDataType.DATE_ListColumnData) {
                    const index = text.indexOf(delimiter);
                    if (index == -1) {
                        ObjerrorMessage.errorMessage = "Invalid filter value";
                        break;
                    }
                    if (this.isEmpty(text.substring(index + 1)) ||
                        this.isEmpty(text.substring(0, index))) {
                        ObjerrorMessage.errorMessage = "Invalid filter value";
                        break;
                    }
                    const LeftaVal = this.parseFilterTextValueSingle(text.substring(0, index), dataType, dataItemId, ObjerrorMessage);
                    values.push(LeftaVal);
                    const RightaVal = this.parseFilterTextValueSingle(text.substring(index + 1), dataType, dataItemId, ObjerrorMessage);
                    if (LeftaVal == null || RightaVal == null) {
                        ObjerrorMessage.errorMessage = "Invalid filter value";
                        break;
                    }
                    let switchPos = false;
                    switchPos = moment(LeftaVal) > moment(RightaVal);
                    if (switchPos) {
                        // swap position
                        const tempHolder = values[0];
                        values[0] = RightaVal;
                        values.push(tempHolder);
                    }
                    else {
                        values.push(RightaVal);
                    }
                    break;
                }
                else {
                    ObjerrorMessage.errorMessage = "Invalid filter value";
                    break;
                }
            }
        }
        if (values.length == 0) {
            // no delimited values found. Lets add the entire string into list
            values.push(text);
        }

        return values;
    }

    trackUsage() {
        //Log Usage
        ConsoleStore.trackUsage('MetricVsMetricFilterApplied');
    }

}



const ColumnCustomFilterControl = new ColumnCustomFilterControlClass();
export default ColumnCustomFilterControl;
