var SymbolType={ SymbolTypeEnumUndefined :0,
   USSTOCK :1,
   PREIPO :2,
   ADR :3,
   HISTORICSTOCK :4,
   INTERNATIONALSTOCK :5,
   MODELSTOCK :6,
   ETF :7,
   FUND :8,
   FUTURE :9,
   COMMODITY :10,
   INDEX :11,
   INDEXNOINTRADAYVOL:12,
   INDEXNOINTRADAYDATA :13,
   INDEXNOINTRADAYVOLDATA :14,
   INTERNATIONALINDEX :15,
   HISTORICINDEX :16,
   ECONOMICINDICATOR :17,
   CURRENCY :18,
   EXCHANGERATE :19,
   WONINDUSTRYGROUP197 :20,
   WONMAJORINDUSTRY89 :21,
   GIC :22,
   WONSECTOR11 :23,
   WONSUBSECTOR33 :24,
   REIT :25,
   FundClosedEnd :26,
   HISTORICALMODELSTOCK : 27,
   FUTURECONTRACTS : 28,
   CASHSPOTS : 29,
   CRYPTOCURRENCY :160
  }
export default SymbolType;
