import { connect } from 'react-redux';
import LineVisual from "LineVisual";
import { PriceChartConst } from '../../../../../../Constants/PriceChartConstants';
import React from 'react';
import ThemeHelper from "ThemeHelper";
import { updateContextHit } from '../../../../../../Actions/PricePanelActions';

function IndexLine(props) {
  const lineColorTheme = ThemeHelper.getThemedBrush(props.lineColor);
  props.indexLineSettings.lineData = props.indexLineData;
  return (<>
    {props.indexLineData && props.IsVisible && <LineVisual isToolTip={props.showToolTip} nodeWidth={props.nodeWidth}
      DataSource={props.indexLineData} LineColor={lineColorTheme} LineThickness={props.lineThickness}
      zIndex={'1'} Draggable={true} lineID={props.indexText} isIndexLine={true} updateContextHit={props.updateContextHit} />}
  </>
  );
}

const mapStateToProps = ({DatagraphReducers, appColor}) => {
  const {  nodeWidth, showToolTip } = DatagraphReducers.DataGraphReducer;
  const {  indexLineSettings, indexText, indexLineData } = DatagraphReducers.IndexLineReducer;
  const { IsVisible, lineThickness, lineColor  }  = indexLineSettings;
  const { isThemeChanged } = appColor;
  return { nodeWidth, showToolTip, indexLineSettings, indexText, indexLineData, IsVisible, lineThickness, lineColor, isThemeChanged };
}

const mapDispatchToProps = (dispatch)=>({
  updateContextHit: ()=> dispatch(updateContextHit({lineID: PriceChartConst.INDEX_LINE})),
})
export default connect(mapStateToProps, mapDispatchToProps)(IndexLine);