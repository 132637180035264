import { connect } from 'react-redux';
import DatagraphStore from "../../../../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import EditBox from "./EditBox.jsx";
import GridActions from "GridActions";
import ListManagerTabType from '../../../../../Constants/ListManagerTabType';
import ListStore from "ListStore";
import moment from 'moment';
import React from "react";
import ThemeHelper from "ThemeHelper";
import { TranslateHelper } from '../../../../../Utils/TranslateHelper.js';
class SymbolEntry extends React.Component {
  constructor(props) {
    super(props);
    this.updateGlobalFlag = this.updateGlobalFlag.bind(this);
    this.toggleSymbolEntryVolRate = this.toggleSymbolEntryVolRate.bind(this);
    this.state = { showVolumeChange: true }
  }

  updateGlobalFlag() {
    const selectedIndexes = [];
    let snapshotDt = null, msids = null;
    if (this.props.SymbolInfo) {
      if (this.props.SymbolInfo.LastTradeDate1){ 
        snapshotDt = `${moment(this.props.SymbolInfo.LastTradeDate).format('M/D/YYYY').toString()};`;
      }
        else{ 
          snapshotDt = ';';
      }
      msids = this.props.SymbolInfo.MsId;
    }
    const selectedTab = ListStore.getState().SelectedTabKey;
    const tabType = ListStore.getTabType(selectedTab);
    let dbType;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        dbType = 1;
        break;
      case ListManagerTabType.Ownership:
        dbType = 2;
        break;
      default: break;
    }
    GridActions.updateGlobalFlag(msids.toString(), snapshotDt, this.props.isFlagged === 0 ? false : true, 0, dbType, selectedIndexes);
  }
  toggleSymbolEntryVolRate(){
    this.setState({ showVolumeChange: !this.state.showVolumeChange })
  }

  render() {
    const isCryptoCurrency = DatagraphStore.getIsCryptoCurrencySymbol();
    const flagClass = this.props.isFlagged === 1 ? 'icn-flag active' : isCryptoCurrency ? '' : 'icn-flag'; // Removing flag for crypto currency symbols.
    const backgroundColor = ThemeHelper.getThemedBrush("000fff");

      if (this.props.videoMode === false) {
          return (
              <div className="auto-search medium-normal">
                  <span className={flagClass} onClick={this.updateGlobalFlag}></span>
                  <label className="org-name x-large-bold" ref={(ref) => (this.companyName = ref)}>{this.props.companyNameDisplay}</label>
                  <EditBox rightPosition={this.props.pricePercentChange ? 21 : 0}/>
                  <article className="symbol-statistics">
                        <article height='100' className="priceLabel">
                          <div className={`${this.state.showVolumeChange && this.props.volumePercentChangeStr ? "price-change x-large-bold": "price-change xx-large-bold"}`} onClick={this.toggleSymbolEntryVolRate} style={{ color: this.props.pricePercentChange >= 0 ? this.props.posDTColor :  this.props.negDTColor }}>{this.props.pricePercentChangeStr}</div>
                          {this.state.showVolumeChange && this.props.volumePercentChangeStr && <div className="price-rates" style={{ color: this.props.volumePercentChange >= 0 ? this.props.posDTColor :  this.props.negDTColor }}>
                            {this.props.volumePercentChangeStr} <span className="uppercase" style={{ color: ThemeHelper.getThemedBrush("volRateLabel") }}> {TranslateHelper.VOL_RATE} </span>
                          </div>}
                        </article>
                    </article>
              </div>
          );
      } else {
          if (this.props.SymbolInfo === undefined) {
              return (<div></div>);
          } else {
              return (
                  <div style={{ display: "grid", gridTemplateColumns: "auto auto 300px" }}>
                      <div className="auto-search medium-normal" style={{ marginLeft: "20px", width: "100%", background: backgroundColor }}>
                          <label className="org-name x-large-bold" style={{ fontSize: "120px", marginTop: "-6px" }} ref={(ref) => (this.companyName = ref)}>{this.props.SymbolInfo.CompanyName.toUpperCase()}</label>
                          <EditBox rightPosition={this.props.pricePercentChange ? 21 : 0}/>
                      </div>
                      <div></div>
                      <div>
                          <article className="symbol-statistics" >
                                <article height='100' className={"priceLabel"}>
                                  <div className="price-change x-large-bold" onClick={this.toggleSymbolEntryVolRate} style={{ color: this.props.pricePercentChange >= 0 ? this.props.posDTColor :  this.props.negDTColor, fontSize: "80px", marginTop: "-10px", marginLeft: "-130px" }}>{this.props.CPrice}</div>
                                  <div className="price-rates xx-small-normal" style={{ color: this.props.pricePercentChange >= 0 ? this.props.posDTColor :  this.props.negDTColor, fontWeight: 'Bold', fontSize: "60px", marginTop: "-40px", marginLeft: "-130px" }}>{(this.props.priceChange > 0 ? "+" : "") + this.props.priceChange}
                                      <span className="price-rates xx-small-normal" style={{ color: this.props.pricePercentChange >= 0 ? this.props.posDTColor :  this.props.negDTColor, fontWeight: 'Bold', fontSize: "60px", marginLeft: "6px" }}>{this.props.pricePercentChangeStr}  </span>
                                  </div>
                              </article>
                          </article>
                      </div>
                  </div>
              );
          }
      }
  }
}

const mapStateProps = ({ DatagraphReducers, appColor })=>{
  const { SymbolInfo, videoMode, SymbolType, CPrice, priceChange, pricePercentChange, pricePercentChangeStr } = DatagraphReducers.DataGraphReducer;
  const { isFlagged, companyNameDisplay } = DatagraphReducers.SymbolEntryReducer;
  const { volumePercentChange, volumePercentChangeStr } = DatagraphReducers.volumeChartReducer;
  const { posDTColor, negDTColor  } = appColor;

  return { SymbolInfo, videoMode, SymbolType, isFlagged, pricePercentChange, pricePercentChangeStr, volumePercentChangeStr, volumePercentChange, companyNameDisplay, posDTColor, negDTColor, CPrice, priceChange }
}

export default connect(mapStateProps)(SymbolEntry)