import { Circle } from 'react-konva';
import KeyCodes from "Constants/KeyCodes";
import { RayAnnotationType } from 'Constants/EAnnotationType';
import React, { Component } from 'react';

class AddAnchor extends Component {

    render() {
        const { x, y, anchorPos, draggable, type, direction } = this.props;
        return (
            <Circle
                id={'anchor'}
                type={type}
                direction={direction}
                x={x}
                y={y}
                radius={5}
                className={anchorPos}
                stroke={'grey'}
                fill={'grey'}
                strokeWidth={1}
                opacity={0.75}
                draggable={draggable}
                onDragStart={(e) => { this.props.handleAnchorDragStart(e.target.attrs.className) }}
                onDragMove={(e) => this.props.handleAnchorDragMove(e)}
                onDragEnd={() => this.props.handleAnchorDragEnd()} // not calling
                dragBoundFunc={() => {
                    if (RayAnnotationType.TEXT_AnnotationType) {
                        return {
                            x: x,
                            y: y,
                        };
                    }
                }
                }
                onContextMenu={() => this.props.onContextMenu(KeyCodes.ESCAPE)}
            />
        );
    }
}

export default AddAnchor