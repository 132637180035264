import React, { PureComponent, Fragment } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


class HederCell extends PureComponent {

  render() {
    const {headerText, sortType, isClickable, toggleOnClick} = this.props;
    let classes = "graph-header-column comp-grid-header";
    classes += isClickable ? " blue-txt " : "";
    let sortTypeClasses = sortType === "asc" ? "sign-arrow arrow-up" : "sign-arrow arrow-down";
    return (
            <span className={classes}  onClick={isClickable !== undefined && toggleOnClick !== undefined ? toggleOnClick : undefined}>
                {headerText !== undefined && headerText}
                {sortType !== undefined && <span className={sortTypeClasses}></span>}
            </span>
    );
}
}


HederCell.propTypes = {
  headerText: PropTypes.string,
  isClickable: PropTypes.bool,
  sortType: PropTypes.string,
  toggleOnClick: PropTypes.func
}




export default HederCell;
