import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from "BaseServiceApi";
import moment from "moment"

export default class GetNupRequest extends BaseServiceRequest {
    constructor(nupDataRequest, protoReq = []) {
        super();
        super.addRequestUri("getpricepanelnupdata.pb");
        const ReqProto = BaseServiceApi.rayData["NupDataRequest"];
        let createDataGraphReq = new ReqProto();
        const InfoProto = BaseServiceApi.rayData["HSFDataRequest"];

        nupDataRequest.forEach(item => {
            let endDate = moment().format("YYYYMMDD");
            if (item.EndDate != null) {
                endDate = item.EndDate;
            }
            let startDate = moment().format("YYYYMMDD");
            if (item.StartDate != null) {
                startDate = item.StartDate;
            }
            let hsfDataRequest = new InfoProto();
            hsfDataRequest.Symbol = item.Symbol;
            hsfDataRequest.GraphType = item.GraphType;
            hsfDataRequest.EndDate = endDate;
            hsfDataRequest.StartDate = startDate;
            hsfDataRequest.GetRTVolume = item.RTVolume;
            hsfDataRequest.isIntradayES = 1; // added to differentiate beta and prod version
            hsfDataRequest.numberOfNodes = item.numberOfNodes;
            createDataGraphReq.HsfDatarequest.push(hsfDataRequest);
        });

        const reqcontent = createDataGraphReq.encode();
        super.setContent(reqcontent);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "GetNupDataResponse";
    }
}