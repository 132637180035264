import { TimelineConstant } from "../../../Constants/TabDataGraphConstants";



const { ActionTypes } = TimelineConstant;

const intialState = {
    endDate: new Date(),
    fiscalMonthEnd: 12,
    isCalender: false,
    align: "end",
    TimeLineData: {},
    TimeLineFirstLabelIndex: 0,
    TimeLineLabels: [],
    CalenderTimeLineFirstLabelIndex: 0,
    CalenderTimeLineLabels: []
}

const TimeLineReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.PRICE_PANEL_RESPONSE_READY_TIMELINE:
            return { ...state, ...action.TimeLineState }
        case ActionTypes.UPDATE_TIMELINE:
            return { ...state, ...action.state }
        case ActionTypes.UPDATE_IS_CALENDER:
            return { ...state, isCalender: action.isCalender }
        default:
            return state;
    }
}

export default TimeLineReducer;