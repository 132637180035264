import { customTIModalConstants } from "../../Constants/TabDataGraphConstants";
const { ActionTypes } = customTIModalConstants;

const initState = {
  isSettingsDialog: false,
  isAppearanceDialog: false,
  isAboutDialog: false,
  title: '',
  menu: [],
  periodicityHeader: [],
  selectedTiTab: "",
  activePeriodicityTab: "",
  className: "",
  isInvalidInput: false,
  inputId: "",
  errorMessage: "",
  isTextRestricted: false,
  restrictedItem: '',
  url: "",
  aboutDialogTitle: "",
  isRestoring: false
}

const CustomTIModuleReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.OPEN_CLOSE_MODAL:
      return {
        ...state,
        isSettingsDialog: action.isSettingsDialog,
        isAppearanceDialog: action.isAppearanceDialog,
        isAboutDialog: action.isAboutDialog
      }
    case ActionTypes.UPDATE_PERIODICITY_HEADER:
      return {
        ...state,
        periodicityHeader: action.periodicityHeader
      }
    case ActionTypes.UPDATE_TITLE:
      return {
        ...state,
        title: action.title,
        selectedTiTab: action.selectedTiTab
      }
    case ActionTypes.UPDATE_MENU_OBJECT:
      return {
        ...state,
        menu: action.obj
      };
    case ActionTypes.ON_TAB_SELECTION_CHANGE_SUCCESS:
      return {
        ...state,
        activePeriodicityTab: action.activePeriodicityTab
      }
    case ActionTypes.UPDATE_CLASSNAME:
      return {
        ...state,
        className: action.className
      }
    case ActionTypes.VALIDATION_ERROR:
      return {
        ...state,
        inputId: action.inputId,
        isInvalidInput: action.isInvalidInput,
        errorMessage: action.errorMessage
      }
    case ActionTypes.ON_INPUT_VALIDATION_SUCCESS:
      return {
        ...state,
        isTextRestricted: action.isTextRestricted,
        restrictedItem: action.restrictedItem
      }
    case ActionTypes.UPDATE_ABOUT_DIALOG_URL:
      return {
        ...state,
        url: action.url,
        aboutDialogTitle: action.aboutDialogTitle
      }
    case ActionTypes.UPDATE_RESTORING:
      return {
        ...state,
        isRestoring: action.isRestoring
      }
    default:
      return state
  }
}

export default CustomTIModuleReducer;