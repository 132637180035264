import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AlertActions from "../../Actions/AlertActions.js";
import BaseServiceApi from "BaseServiceApi";
import HttpMethodType from "HttpMethodType";
import StringUtil from "StringUtil";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import AddMobileErrorDialog from "../Alerts/addMobileErrorDialog.jsx";
import AlertStore from "../../Stores/Alerts/AlertStore.js";
//import MaskedInput from 'react-text-mask';

export default class AddMobileNumber extends Component {
    constructor(props) {
        super(props);

        this.state = {
            validationClass: 'displayNone',
            phoneNumber: this.props.phoneNumber,
            IdStatus: this.props.phoneNumberValid
        }

        this.hideDialogMobile = this.hideDialogMobile.bind(this);
        this.hideAddMobileErrorDialog = this.hideAddMobileErrorDialog.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.saveUserNotificationSettings = this.saveUserNotificationSettings.bind(this);
        this.maskPhoneNumber = this.maskPhoneNumber.bind(this);
    }

    hideDialogMobile() {
        this.props.hideAddMobileNumberDialog();
    }

    hideAddMobileErrorDialog() {
        this.setState({
          openAddMobileErrorDialog: false
        });
        this.hideDialogMobile(); 
      }

    getMaskedNumber(number) {
        if (number) {
            var x = number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        }
    }

    handlePhoneNumberChange(event) {
        let value = event.target.value.replace(/[^0-9]+/,'');
        this.setState({
            phoneNumber: value
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.open != nextProps.open && nextProps.open == true) {
            this.setState({
                phoneNumber: nextProps.phoneNumber,
                validationClass: 'displayNone'
            });
        }
    }

    handleKeyDown(e) {
        if (e.keyCode === 13) {
            this.saveUserNotificationSettings();
            e.preventDefault();
        }
    }

    saveUserNotificationSettings() {
        let value = this.state.phoneNumber;
        let idStatus = this.state.IdStatus;

        let methodType = idStatus == 0 ? HttpMethodType.PUT: StringUtil.isEmpty(value) ? HttpMethodType.DELETE : HttpMethodType.POST;
        AlertActions.saveNotificationSubscription(value, BaseServiceApi.rayData["NotificationType"].PHONE, methodType, true);
        setTimeout(() => {
            let result = _.clone(AlertStore.getNotificationSettings())
            if (result.phoneNumber.length === 0 && value.length > 0) {
                this.setState({
                    openAddMobileErrorDialog: true
                });
            }
            else {
                this.hideDialogMobile(); 
            }
        }, 3000);
    }

    validatePhoneNumber(elementValue) {
        var phoneNumberPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
        return phoneNumberPattern.test(elementValue);
    }

    maskPhoneNumber(e) {
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }



    render() {
        return (
            <div id="addMobileNumber">
                {this.state.openAddMobileErrorDialog ? <AddMobileErrorDialog open={this.state.openAddMobileErrorDialog} hideAddMobileErrorDialog={this.hideAddMobileErrorDialog} /> : ""}
                <Modal className="modal-popup wide verification" show={this.props.open}>
                    <Modal.Body>
                        <div className="modal-region">
                            <div className="verify-credential mobile">
                                <p>{LocalizationStore.getTranslatedData("set_pre_nap_entn","Enter a Mobile Number to receive SMS Alerts.")}</p>
                                <p>{LocalizationStore.getTranslatedData("SMSRemindMessage",
                                "Enter as a continuous string (no symbols or spaces).\n" + 
                                "For Mobile Numbers outside of the United States and Canada, please include the international country code. " + 
                                "For numbers within the U.S. and Canada, Please include 1 and the area code.")}</p>
                                <form>
                                    <input  id="formControlsMobile"
                                            placeholder={LocalizationStore.getTranslatedData("set_pre_nap_emn","Enter Mobile Number")}
                                            value={this.state.phoneNumber}
                                            onChange={this.handlePhoneNumberChange}
                                            onKeyDown = {this.handleKeyDown}
                                    />
                                    <small className={this.state.validationClass + ' text-danger'}> {LocalizationStore.getTranslatedData("set_pre_nap_peav","Please Enter a valid US mobile number")}</small>
                                </form>
                            </div>
                            <div className="box clear"></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.hideDialogMobile}>{LocalizationStore.getTranslatedData('cancel', 'Cancel')}</button>
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.saveUserNotificationSettings}>{LocalizationStore.getTranslatedData('save', 'Save')}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}