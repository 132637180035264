import { Component } from 'react';
import ReactDOM from 'react-dom';
import UserInfoUtil from "UserInfoUtil";
import HelpWindowConstants from "../../Constants/HelpWindowConstants.js";
const toolTipRoot = document.getElementById('portaldiv');

export default class QuickRefWindow extends Component {
  constructor(props) {
    super(props);
    let urlWeb = "";
    let urlWeb_CN = "";
    switch (this.props.helpType){
      case  HelpWindowConstants.Quickreference:
        urlWeb = `${window.location.origin}/assets/quickreferenceguide_web.pdf`;
        urlWeb_CN = `${window.location.origin}/assets/QuickReferenceGuide_cn.pdf`;
        break;
      case HelpWindowConstants.ClipboardCopyPaste:
        urlWeb = `${window.location.origin}/assets/PanarayWeb_ClipboardSettings_5.0.pdf`;
        urlWeb_CN = `${window.location.origin}/assets/PanarayWeb_ClipboardSettings_5.0CHINA.pdf`;
        break;
      case HelpWindowConstants.Print:
        urlWeb = `${window.location.origin}/assets/PanarayPrintSettings_web.pdf`;
        urlWeb_CN = `${window.location.origin}/assets/PanarayWeb_PrintSettings_5.0CHINA.pdf`;
        break;
      default:
        break;
    }
    this.url = !UserInfoUtil.hasChinaEntitlement() ? urlWeb: urlWeb_CN;

    this.el = this.props.isCustomColumnFilter ? toolTipRoot : document.createElement('div');
  }

  componentDidMount() {
    if(!this.open){
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const w = (width * 80 / 100);
    const h = (height * 80 / 100);
    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const top = ((height / 2) - (h / 2)) + dualScreenTop;
    !this.props.isCustomColumnFilter && toolTipRoot.appendChild(this.el);
    this.externalWindow = window.open(this.url, "_blank", `width=${w},height=${h},left=${left},top=${top}`);
    let selfOpening = this;
    this.externalWindow.onbeforeunload = () => selfOpening.props.onCloseWindow();
    this.open = true;
    }
  }

  componentDidUpdate() {
    if(!this.open){
    const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop != undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const w = (width * 80 / 100);
    const h = (height * 80 / 100);
    const left = ((width / 2) - (w / 2)) + dualScreenLeft;
    const top = ((height / 2) - (h / 2)) + dualScreenTop;
    !this.props.isCustomColumnFilter && toolTipRoot.appendChild(this.el);
    this.externalWindow = window.open(this.url, "_blank", `width=${w},height=${h},left=${left},top=${top}`);
    this.open = true;
    }
  }


  componentWillUnmount() {
    !this.props.isCustomColumnFilter && toolTipRoot.removeChild(this.el);
    this.props.onCloseWindow();
    this.externalWindow.close();
  }


  render() {
    return (
      ReactDOM.createPortal(
        this.props.children,
        this.el
      )
    );
  }
}