
export const OwnershipSubCategoryType  = {
    OwnershipAllFund : 0,
    OwnershipMutFund: 1,
    OwnershipHedFund :2,
    OwnershipOther: 0,
    OwnershipOtherCorporations: 1,
    OwnershipOtherIndividuals: 2,
    OwnershipInsurance:3,
    OwnershipOtherManagers:4,
    OwnershipTopOwners:0,
    OwnershipByPosition:1,
    OwnershipByOwner:2
};