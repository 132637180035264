import React, { Component } from 'react';
import { toggleCalendar, updateInput, handleInputChange } from '../../../../../Actions/ComparisonActions';
import { connect } from 'react-redux';
import { CalendarButton } from "../Presentational";
import ComparisonCalendarEvent from "./ComparisonCalendarEvent.jsx";
import ErrorBoundary from 'ErrorBoundary';
import {CompareMenuType} from "CompareTabType";

class ComparisonCalendar extends Component {

  onCalendarClick = (event) => {
    document.body.classList.add('comparisonCalendarOpened');
    const isSetSelectedDateActive = false;
    this.props.toggleCalendar(event, isSetSelectedDateActive);
  }

  updateInput = (e) => {
    const isUserEdit = true;
    const { showCalendarLeft, startDate, endDate, viewDate } = this.props;
    let reg = new RegExp("[^a-zA-Z]+$");
    if (reg.test(e.target.value) || e.target.value === "") {
      let inputData;
      if (showCalendarLeft) {
        inputData = { startDate: e.target.value, endDate: endDate, selectedDate: e.target.value, viewDate, isUserEdit };
        this.props.updateInput(inputData);
      }
      else {
        inputData = { startDate: startDate, endDate: e.target.value, selectedDate: e.target.value, viewDate, isUserEdit };
        this.props.updateInput(inputData);
      }
    }
    else
      e.preventDefault();
  }

  handleInputChange = (e) => {
    const input = e.target.value;
    if (e.keyCode == 13 && input != '') {
      const isUserEdit = false;
      this.props.handleInputChange(input, isUserEdit);
    }
  }

  render() {
    const { showCalendarLeft, showCalendarRight, startDate, endDate, isUserEdit } = this.props;

    return (
      <div className="calendar-box pull-right">
        <ErrorBoundary><CalendarButton value={startDate} isUserEdit={isUserEdit} position="left" onCalendarClick={this.onCalendarClick} onKeyDown={this.handleInputChange} onChange={this.updateInput} /></ErrorBoundary>
        <ErrorBoundary><CalendarButton value={endDate} isUserEdit={isUserEdit} position="right" onCalendarClick={this.onCalendarClick} onKeyDown={this.handleInputChange} onChange={this.updateInput} /></ErrorBoundary>

        {showCalendarLeft ?

          <div className="comparison-calendar start"><ErrorBoundary><ComparisonCalendarEvent date={startDate} /></ErrorBoundary></div>
          :
          showCalendarRight ?
            <div className="comparison-calendar end"><ErrorBoundary><ComparisonCalendarEvent date={endDate} /></ErrorBoundary></div>
            : <span />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.calendarControl;
  const { showCalendarLeft, startDate, endDate, viewDate, showCalendarRight, isUserEdit } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

  return { showCalendarLeft, startDate, endDate, viewDate, showCalendarRight, isUserEdit };
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCalendar: (event, isSetSelectedDateActive) => dispatch(toggleCalendar(event, isSetSelectedDateActive)),
    updateInput: (inputData) => dispatch(updateInput(inputData)),
    handleInputChange: (input, isUserEdit) => dispatch(handleInputChange(input, isUserEdit))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonCalendar);
