import PropTypes from 'prop-types';
import React, { Component } from "react";
export default class DogEar extends Component {
  constructor(props) {
    super(props);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.state = {
      points: this.getPointSource(props.x),
      isOpen: false,
      x: props.x,
      isMouseDown: false
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.x !== nextProps.x) {
      this.setState({ x: nextProps.x, points: this.getPointSource(nextProps.x) });
    }
  }
  getPointSource(x, isHover = false) {
    let points = "";
    if (isHover) {
      points += `${x},1.0 ${(x + 6.0)},1.0 ${(x + 6.0)},7.0 ${x},7.0`;
    }
    else {
      points += `${x},1.0 ${(x + 6.0)},1.0 ${x},7.0`;
    }
    return points;
  }
  onMouseEnter() {
    this.setState({ points: this.getPointSource(this.state.x, true) });
  }

  onMouseLeave() {
    this.setState({ points: this.getPointSource(this.state.x) });
  }
  // getDogEarContent() {
  //   return (
  //     <g transform={`translate(${this.state.x} -63.0)`}>
  //       <rect height={53} width={155} fill="yellow">
  //         <title>{this.props.text}</title>
  //       </rect>

  //     </g>);
  // }
  handleMouseDown(e, show = false) {
    if (this.props.onShowDogEar) {
      if (show) {
        this.props.onShowDogEar({ x: this.state.x, text: this.props.text }, true);
      } else {
        this.props.onShowDogEar();
      }
    }
  }
  render() {
    return (
      <g onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
        <rect fillOpacity="0.0" x={this.state.x} y={1.0} height={this.props.height} width={this.props.width} />
        <polygon id="dogearPolygon" onMouseDown={(e) => { e?.stopPropagation(); this.handleMouseDown(e, true) }}
          onMouseLeave={(e) => { this.handleMouseDown(e, false) }}
          className="dogear" style={{ cursor: 'pointer' }} points={this.state.points} />
      </g>
    );
  }
}
DogEar.propTypes = {
  x: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onShowDogEar: PropTypes.func
};
