import RealTimeQuoteType from "../Constants/RealTimeQuoteType.js";
import LocalizationStore from 'LocalizationStore';

class ServerInfoUtil {
    constructor(type) {
        this.QuoteType = type;
        switch (this.QuoteType) {
            case RealTimeQuoteType.Delayed:
                this.Name = LocalizationStore.getTranslatedData('sc_ct', "CONNECTION STATUS");
                this.Desc = LocalizationStore.getTranslatedData('sc_qdelay', "QUOTES DELAYED");
                break;
            case RealTimeQuoteType.Nasdaq:
                this.Name = LocalizationStore.getTranslatedData("sc_nb", "NASDAQ BASIC");
                this.Desc = LocalizationStore.getTranslatedData("sc_nb_desc", "REAL TIME PRICES PROVIDED BY NASDAQ BASIC.\nReal time quote and/or trade prices are not sourced from all markets.\nAll other quotes delayed at least 20 minutes.");
                break;
            default:
                break;
        }
    }

}

export default ServerInfoUtil;