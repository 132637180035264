import HttpMethodType from "HttpMethodType";
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from "BaseServiceRequest";

class GetActiveStockAlertsRequest extends BaseServiceRequest {
    constructor(msId) {
        super();
        super.addRequestUri("getactivestockalerts.pb");
        super.addRequestParameter("msID", msId);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "ActiveAlertsResponseData";
    }
}

export default GetActiveStockAlertsRequest;
