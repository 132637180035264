import GraphType from "../../../../../Constants/GraphType.js";
import Serializable from "Serializable";
class InsiderBlockSettings extends Serializable {

  onSeralisedInfoRecieved(info) {
    this.Height = info.Height ? info.Height : 50;
    this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
    this.IsAvailable = info.IsAvailable !== undefined ? info.IsAvailable : true;
  }
  getDataObject() {
    return {
      Height: this.Height,
      IsVisible: this.IsVisible,
      IsAvailable: this.IsAvailable
    };
  }
  getObjectMapKey() {
    return "InsiderBlockSettings";
  }
  constructor(periodicity) {
    super();
    this.IsAvailable = false;
    this.IsVisible = true;

    this.Height = 50;
    switch (periodicity) {
      case GraphType.Daily:
        this.IsAvailable = true;
        break;
      case GraphType.Weekly:
        this.IsAvailable = true;
        break;
      case GraphType.Monthly:
        this.IsAvailable = true;
        break;
      case GraphType.Annual:
        this.IsAvailable = true;
        break;
      case GraphType.Quarterly:
        this.IsAvailable = true;
        break;
      default:
        this.IsAvailable = false;
    }
  }
}

export default InsiderBlockSettings;
