import StringUtil from 'StringUtil';
import { initializeTimeLineWorker } from "WebWorker";

export class MenuStates {
    constructor() {
        this.isClearAllDialogOpen = false,
            this.addListModalShowStatus = false;
        this.alertMessage = null;
        this.listName = null;
        this.showAddListAlertMessage = false;
        this.breadCrumbItems = [];
        this.isNotVisibleOsids = [];
        this.lastHighlightedOsids = [];
        this.selectedIndustry = null;
        this.rotationMenuType = null;
        this.itemName = null;
        this.countryCode = null;
        this.regionCode = null;
        this.countryAggArray = [];
        this.regionAggArray = [];
        this.countryCodeList = '';
        this.regionCodeList = '';
        this.selectedGeography = null;
        this.selectedOsid = '';
        this.listItems = [];
        this.listItemType = null;
        this.isGeographySelected = null;
        this.stockList = [];
        this.quadGraphResults = {};
        this.stocksTotalList = [];
        this.groupResult = {};
        this.quadGraphYearList = [];
        this.openGeography = false;
        this.symbolsList = [];
        this.contextManu = {
            isShowContextMenu: false,
            contextMenuData: [
                {
                    displayText: 'Delete',
                    actionType: 'delete',
                    isShow: false
                },
                {
                    displayText: 'Rename',
                    actionType: 'rename',
                    isShow: false
                }
            ],
            pos: {
                x: 0,
                y: 0
            },
            contextItem: null
        }
        this.isHighLighted = false
    }
}

export class AddSymbol {
    constructor() {
        this.searchtext = '',
            this.searchResult = [],
            this.isOpen = false,
            this.resultAvail = false,
            this.activeIndex = -1,
            this.columnWidths = 0,
            this.menuWidth = 0,
            this.hasErrors = false,
            this.errorMsg = "",
            this.enterPressed = false,
            this.selectedTab = null
    }
}

export class LiquidityFilter {
    constructor() {
        this.ratingFilterType = {
        }
        this.mktCapFilterViewModel = null,
            this.openLiquidityRatings = false,
            this.avolFilterViewModel = null,
            this.titleText = '',
            this.isOpen = false;
        this.isGlobalRatingSelected = true,
            this.isCountryRatingSelected = false,
            //   this.isLiquidityFilterOn = true,
            this.isFilterOn = true,
            this.isFilterManualOff = true,
            //   this.isLiqFilterOn = false,
            this.rangeMktCapValues = [],
            this.rangeAvolValues = [],
            this.rangeRatingFilterTypeOptions1Values = [],
            this.rangeRatingFilterTypeOptions2Values = [],
            this.showSettingsWindow = false,
            this.updateCmp = true,
            this.showSettingsWindow = false;
        this.activeSlider = { id: null, sliderIndex: null }
        this.targetInfo = {
            MinPrice: 0,
            MaxPrice: 100,
            BoxHeight: 0.0,
            VpHeight: 0.0,
            Name: "",
            MMinPrice: 0.0,
            MMaxPrice: 0.0,
            MaxVolume: 0.0,
            MinVolume: 0.0,
            VolAveMax: 0.0,
            Avolume: 0.0,
            DPI: 96.0,
            VolWidth: 0.0,
            VolHeight: 0.0,
            EpsMultiplier: 20.0,
            RpsMultiplier: 20.0,
            NodesPerInch: 28.0,
            ScaleType: "LIN",
            TradeDate: "",
            StartDate: "",
            OpenTime: "",
            CloseTime: "",
            GraphType: "",
            SymbolType: ""
        };
    }

}

export class CompRotationHelper {
    constructor() {
        this.fontSizeDictionary =
            {
                1: Number(15),
                1.25: Number(15 - (1.25 * 0.52)),
                1.5: Number(15 - (1.5 * 1.17)),
                1.75: Number(15 - (1.75 * 1.42)),
                2: Number(15 - (2 * 1.5)),
                2.25: Number(15 - (2.25 * 1.62)),
                2.5: Number(15 - (2.5 * 2)),
                2.75: Number(15 - (2.75 * 1.82)),
                3: Number(15 - (3 * 1.82)),
                3.25: Number(15 - (3.25 * 1.85)),
                3.5: Number(15 - (3.5 * 1.86)),
                3.75: Number(15 - (3.75 * 1.87)),
                4: Number(15 - (4 * 1.88)),
                4.25: Number(15 - (4.25 * 1.88)),
                4.5: Number(15 - (4.5 * 1.84)),
                4.75: Number(15 - (4.75 * 1.83)),
                5: Number(15 - (5 * 1.82)),
                5.25: Number(15 - (5.25 * 1.78)),
                5.5: Number(15 - (5.5 * 1.76)),
                5.75: Number(15 - (5.75 * 1.74)),
                6: Number(15 - (6 * 1.75)),
                6.25: Number(15 - (6.25 * 1.69)),
                6.5: Number(15 - (6.5 * 1.66)),
                6.75: Number(15 - (6.75 * 1.64)),
                7: Number(15 - (7 * 1.61)),
                7.25: Number(15 - (7.25 * 1.59)),
                7.5: Number(15 - (7.5 * 1.56)),
                7.75: Number(15 - (7.75 * 1.54)),
                8: Number(15 - (8 * 1.51)),
                8.25: Number(15 - (8.25 * 1.48)),
                8.5: Number(15 - (8.5 * 1.46)),
                8.75: Number(15 - (8.75 * 1.43)),
                9: Number(15 - (9 * 1.41)),
                9.25: Number(15 - (9.25 * 1.39)),
                9.5: Number(15 - (9.5 * 1.36)),
                9.75: Number(15 - (9.75 * 1.34)),
                10: Number(15 - (10 * 1.32))
            };
    }

    getSectorLabel(sectorLabel) {
        let label = null;
        if (!StringUtil.isEmpty(sectorLabel))
            switch (sectorLabel) {
                case "WS008":
                case "RETAIL":
                case "RTL":
                    label = "RTL";
                    break;
                case "WS007":
                case "CONSUMER CYCLICAL":
                case "CYC":
                    label = "CYC";
                    break;
                case "WMC03":
                case "FINANCIAL":
                case "FIN":
                    label = "FIN";
                    break;
                case "WS004":
                case "TECHNOLOGY":
                case "TECH":
                    label = "TECH";
                    break;
                case "WS006":
                case "HEALTH CARE":
                case "HLTH":
                    label = "HLTH";
                    break;
                case "WMC04":
                case "UTILITY":
                case "UTL":
                    label = "UTL";
                    break;
                case "WS005":
                case "CONSUMER STAPLE":
                case "STPL":
                    label = "STPL";
                    break;
                case "WS003":
                case "CAPITAL EQUIPMENT":
                case "CAP":
                    label = "CAP";
                    break;
                case "WMC02":
                case "TRANSPORTATION":
                case "TRAN":
                    label = "TRAN";
                    break;
                case "WS002":
                case "ENERGY":
                case "ENRG":
                    label = "ENRG";
                    break;
                case "WS001":
                case "BASIC MATERIAL":
                case "MAT":
                    label = "MAT";
                    break;
                default:
                    break;
            }
        return label;
    }

    getSectorColor(sectorLabel) {
        let sectorBrush = null;
        if (!StringUtil.isEmpty(sectorLabel))
            switch (sectorLabel.trim().toUpperCase()) {
                case "WS008":
                case "RETAIL":
                case "RTL":
                    sectorBrush = "#0E76BC";
                    break;
                case "WS007":
                case "CONSUMER CYCLICAL":
                case "CYC":
                    sectorBrush = "#A1B9E0";
                    break;
                case "WMC03":
                case "FINANCIAL":
                case "FIN":
                    sectorBrush = "#2F8F44";
                    break;
                case "WS004":
                case "TECHNOLOGY":
                case "TECH":
                    sectorBrush = "#95CD84";
                    break;
                case "WS006":
                case "HEALTH CARE":
                case "HLTH":
                    sectorBrush = "#E5D220";
                    break;
                case "WMC04":
                case "UTILITY":
                case "UTL":
                    sectorBrush = "#F7941E";
                    break;
                case "WS005":
                case "CONSUMER STAPLE":
                case "STPL":
                    sectorBrush = "#C13E49";
                    break;
                case "WS003":
                case "CAPITAL EQUIPMENT":
                case "CAP":
                    sectorBrush = "#A02C91";
                    break;
                case "WMC02":
                case "TRANSPORTATION":
                case "TRAN":
                    sectorBrush = "#6E59A6";
                    break;
                case "WS002":
                case "ENERGY":
                case "ENRG":
                    sectorBrush = "#6D3319";
                    break;
                case "WS001":
                case "BASIC MATERIAL":
                case "MAT":
                    sectorBrush = "#AE8B67";
                    break;
                default:
                    break;
            }
        return sectorBrush;
    }

    getSectorOrder(code) {
        let order = 0;
        switch (code) {
            case "WS008":
            case "RETAIL":
            case "RTL":
                order = 1;
                break;
            case "WS007":
            case "CONSUMER CYCLICAL":
            case "CYC":
                order = 2;
                break;
            case "WMC03":
            case "FINANCIAL":
            case "FIN":
                order = 3;
                break;
            case "WS004":
            case "TECHNOLOGY":
            case "TECH":
                order = 4;
                break;
            case "WS006":
            case "HEALTH CARE":
            case "HLTH":
                order = 5;
                break;
            case "WMC04":
            case "UTILITY":
            case "UTL":
                order = 6;
                break;
            case "WS005":
            case "CONSUMER STAPLE":
            case "STPL":
                order = 7;
                break;
            case "WS003":
            case "CAPITAL EQUIPMENT":
            case "CAP":
                order = 8;
                break;
            case "WMC02":
            case "TRANSPORTATION":
            case "TRAN":
                order = 9;
                break;
            case "WS002":
            case "ENERGY":
            case "ENRG":
                order = 10;
                break;
            case "WS001":
            case "BASIC MATERIAL":
            case "MAT":
                order = 11;
                break;
            default:
                break;
        }
        return order;
    }

    getLineColor(count) {
        if (count >= 48) {
            count = count % 48;
        }
        switch (count + 1) {
            case 1:
                return "#1682EC";
            case 2:
                return "#028B6C";
            case 3:
                return "#06A000";
            case 4:
                return "#87D900";
            //case 5:
            //    return "#ECED00";
            case 5:
                return "#E3AB00";
            case 6:
                return "#EA4602";
            case 7:
                return "#EC7702";
            case 8:
                return "#EF001B";
            case 9:
                return "#F00478";
            case 10:
                return "#E400EC";
            case 11:
                return "#6617F0";
            case 12:
                return "#783C00";
            case 13:
                return "#7DB8F6";
            case 14:
                return "#69BAA7";
            case 15:
                return "#6AC868";
            //case 16:
            //    return "#B7E767";
            //case 18:
            //    return "#F1F163";
            //case 19:
            //    return "#EDCD65";
            case 16:
                return "#EF9068";
            case 17:
                return "#F4AC62";
            case 18:
                return "#F67683";
            case 19:
                return "#F563AB";
            case 20:
                return "#EE68F4";
            case 21:
                return "#A679F4";
            case 22:
                return "#AE8B67";
            case 23:
                return "#114F90";
            case 24:
                return "#015441";
            case 25:
                return "#006100";
            case 26:
                return "#548300";
            case 27:
                return "#8F9100";
            case 28:
                return "#836300";
            case 29:
                return "#902A00";
            case 30:
                return "#904900";
            case 31:
                return "#83000E";
            case 32:
                return "#8B0045";
            case 33:
                return "#89008E";
            case 34:
                return "#400D90";
            case 35:
                return "#472301";
            case 36:
                return "#5785B4";
            case 37:
                return "#508B7D";
            case 38:
                return "#4C914A";
            case 39:
                return "#84AA4D";
            case 40:
                return "#B1B24E";
            case 41:
                return "#B19850";
            case 42:
                return "#B57051";
            case 43:
                return "#B78451";
            case 44:
                return "#B34E59";
            case 45:
                return "#B14D7D";
            case 46:
                return "#B255B6";
            case 47:
                return "#805EB6";
            case 48:
                return "#846D54";
            default:
                return;
        }
    }

    initializeTimeLineWorker(obj, groupsTimelineDateDictionary, groupsCountDataList, lastAvailableDate, selectedSliderValue,newList=false) {
        const initializeTimeLineWorkerObj = new initializeTimeLineWorker();

        return new Promise((resolve, reject) => {
            initializeTimeLineWorkerObj.postMessage({ initializeRotationGraphData: 'start', typeObjectStore: obj, groupsTimelineDateDictionary: groupsTimelineDateDictionary, groupsCountDataList: groupsCountDataList, lastAvailableDate: lastAvailableDate, selectedSliderValue: selectedSliderValue,newList:newList });

            initializeTimeLineWorkerObj.onmessage = (e) => {
                initializeTimeLineWorkerObj.terminate();
                resolve(e.data);
            }
            initializeTimeLineWorkerObj.onerror = () => {
                initializeTimeLineWorkerObj.terminate();
                reject();
            }
        });
    }

    preparePointstoRender(groupResult, groupsTimelineDateDictionary, selectedSliderValue, listItemType, listItems) {
        const initializeTimeLineWorkerObj = new initializeTimeLineWorker();

        return new Promise((resolve, reject) => {
            initializeTimeLineWorkerObj.postMessage({ initializeRotationGraphData: 'preparePointstoRender', groupResult, groupsTimelineDateDictionary, selectedSliderValue, listItemType, listItems });

            initializeTimeLineWorkerObj.onmessage = (e) => {
                initializeTimeLineWorkerObj.terminate();
                resolve(e.data);
            }
            initializeTimeLineWorkerObj.onerror = () => {
                initializeTimeLineWorkerObj.terminate();
                reject();
            }
        });
    }
}