import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
const { ActionTypes } = RiPanelConstants;
import UserInfoUtil from "../../../Utils/UserInfoUtil";

const initialState = {
    isLoading: true,
    listItems: [],
    activeIndex: -1,
    chosenIds: [],
    isOpen: false,
    defaultIds: [2, 3],
    userInfo: UserInfoUtil.getUserInfo(),
    symbolInfo: null
}

const RiPanelNewsInfo = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_NEWS_INFO:
            state.isOpen = false;
            return { ...state, ...action.newState };

        case ActionTypes.NEWS_INFO_OPEN_SETTINGS: {
            state.isOpen = action.isOpen;
            if (action.isOpen === false) {
                state.chosenIds = state.defaultIds;
            }
            return { ...state }
        }

        case ActionTypes.NEWS_INFO_CHANGE_SOURCE: {
            return { ...state, chosenIds: action.chosenIds }
        }

        case ActionTypes.NEWS_INFO_SET_DEFAULT_NEWS: {
            return { ...state, chosenIds: action.defaultChosenIds, isLoading: false }
        }

        case ActionTypes.NEWS_INFO_SET_ACTIVE_ITEM: {
            return { ...state, activeIndex: action.index }
        }

        case ActionTypes.NEWS_INFO_SET_LOADING: {
            return { ...state, isLoading: action.isLoading }
        }

        default: return state;
    }
}

export default RiPanelNewsInfo;