class DataGraphSettingHelper {



  updateTabSelection(viewSettings, smartViewType, header) {
    let selectedTabSettings = viewSettings.SelectedTabSettings;
    selectedTabSettings[smartViewType] = header;

  }
  updateSymbol(viewSettings, symbol) {
    viewSettings.symbol = symbol;
  }

}

const dataGraphSettingHelper = new DataGraphSettingHelper();
export default dataGraphSettingHelper;
