import React, { Component } from 'react';
import listExplorerStore from '../../../../../Stores/NavModules/NavList/Explorer/ListExplorerStore';
import { connect } from 'react-redux'
import { onClickListAdd, setAddListModalShowStatus } from 'Actions/ComparisonAndRotationMenuActions';
import CompareTabType from "CompareTabType";
import { PrintMode } from "../../../../../print/printmode";

class AddList extends Component {

  constructor() {
    super();
    this.source = null;
    this.modalDisplay = this.modalDisplay.bind(this);
    this.onClickListAdd = this.onClickListAdd.bind(this);
  }

  componentDidMount() {
    listExplorerStore.getListExplorer();
  }

  modalDisplay() {
    setTimeout(() => {
      if (listExplorerStore.getState().listExplorer.ONeil && listExplorerStore.getState().listExplorer.ONeil.length > 0) {
        this.props.setAddListModalShowStatus(true);
      }
    }, 10);
  }

  onClickListAdd(item) {
    this.props.onClickListAdd(item);
  }


  render() {
    return (
      <React.Fragment>
        {!PrintMode.printing && <button type="button" className="btn btn-sm btn-default btn-addlist" onClick={this.modalDisplay}>+List</button>}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onClickListAdd: (item) => dispatch(onClickListAdd(item)),
  setAddListModalShowStatus: (status) => dispatch(setAddListModalShowStatus(status)),
})

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;

  if (selectedTab === CompareTabType.ComparisonGraph) {
    const symbolsStateData = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer.ComparisonMenuStates;
    const storeSymbolsSize = state.comparisonGraph.compChartListItem.SymbolsData.length;

    return { symbolsStateData, storeSymbolsSize };
  }
  else {
    const symbolsStateData = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer.RotationMenuStates;
    const storeSymbolsSize = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer.RotationMenuStates.listItems.length;

    return { symbolsStateData, storeSymbolsSize };
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(AddList);
