
import { AnnotationConstant } from "../../Constants/AnnotationConstants";
const { ActionTypes } = AnnotationConstant;
import { EllipseInfo } from "../../Models/AnnotationModels/EllipseModelInfo";

const ellipses = []

const ellipseReducer = (state = ellipses, action) => {

    switch (action.type) {
        case ActionTypes.INIT_ELLIPSE_COLLECTION:
            return action.EllipseCollection;
        case ActionTypes.HANDLE_ELLIPSE_CLICK_SUCCESS:
            return [...state, action.ellipse]
        case ActionTypes.HANDLE_ELLIPSE_DRAW_SUCCESS:
            return state.map((item) => (item.id === action.ellipse.id) ? action.ellipse : item)
        case ActionTypes.HANDLE_ELLIPSE_SAVE_ID_SUCCESS:
            return action.ellipses
        case ActionTypes.HANDLE_ELLIPSE_TRANSFORM_SUCCESS:
            return state.map((item) => (item.id === action.ellipse.id) ? action.ellipse : item)
        case ActionTypes.HANDLE_REMOVE_ELLIPSE_DRAW:
            return state.filter((item) => item.id !== action.annotSelectedId)
        case ActionTypes.REMOVE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new EllipseInfo, { ...item, isDeleted: true }) : item)
        case ActionTypes.RESTORE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new EllipseInfo, { ...item, isDeleted: false }) : item)
        case ActionTypes.CLEAR_ANNOTATIONS:
            return [];
        default:
            return state
    }
}

export default ellipseReducer;