import keyMirror from "keymirror"

export const ExternalDataConstants = {
    ActionTypes: keyMirror({
        EXTERNAL_FILE_UPLOAD_DIALOG: null,
        EXTERNAL_FILE_UPLOAD_DIALOG_TOGGLE: null,
        EXTERNAL_FILE_UPLOAD: null,
        TOGGLE_RECURRING_UPLOAD: null,
        TOGGLE_RECURRING_UPLOAD_CHANGE: null,
        SAVE_UPLOADED_FILE: null,
        SAVE_UPLOADED_FILE_TEMP: null,
        SAVE_UPLOADED_TIME: null,
        SAVE_UPLOADED_TIME_CHANGE: null,
        APPEND_FILE_CLICK: null,
        OVERWRITE_FILE_CLICK: null,
        UPLOAD_DATA_ONCLOSE: null,
        FTP_USERNAME_CHANGE: null,
        FTP_PASSWORD_CHANGE: null,
        TOGGLE_TIME_CHANGER: null,
        TOGGLE_FREQUENCY_CHANGER: null,
        INIT_EXTERNAL_LISTS: null,
        INIT_EXTERNAL_LISTS_SUCCESS: null,
        GET_EXTERNAL_DATA_SUB_MENU: null,
        GET_CURRENT_LIST_DATA: null,
        UPDATE_TIME_SERIES_DATA_OF_PRICE_PANE: null,
        UPDATE_TIME_SERIES_DATA_OF_INDICATOR_PANE: null,
        UPDATE_EXTERNAL_DATA_API_RESULT: null,
		REDRAW_ON_CHART_RESIZE: null,
        UPDATE_HEIGHT: null,
        OPEN_EXTERNAL_DATA_EDIT_DIALOG: null,
        HIDE_MODAL_DIALOG: null,
        CLOSE_EXTERNAL_DATA_EDIT_DIALOG: null,
        HIDE_COLOR_PALETTE: null,
        UPDATE_ACTIVE_ITEM: null,
        INIT_EXTERNAL_DATA: null,
        LOAD_EXTERNAL_LIST: null,
        LOAD_EXTERNAL_LIST_SUCCESS: null,
        EXTERNAL_SELECTED_NODE: null,
        EXTERNAL_SELECTED_NODE_SUCCESSS: null,
        SHOW_ADVANCE_SETTINGS: null,
        SET_SELECTED_NODES: null,
        SET_SELECTED_NODES_SUCCESS: null,
        CREATE_EMPTY_LIST: null,
        SET_RENAME_NODE_EXTERNAL: null,
        SET_RENAME_NODE_SUCCESS: null,
        UNLOAD_EXTERNAL_STATE_DATA: null,
        SET_DUPLICATE_NODE_EXTERNAL: null,
        SET_DUPLICATE_NODE_SUCCESS: null,
        EMPTY_LIST_DATA: null,
        SET_LIST_NAME_ONCHANGE: null,
        DUPLICATE_NODE_EXTERNAL: null,
        RENAME_NODE_EXTERNAL: null,
        PUSH_NEW_LIST_NODE: null,
        UPDATE_PRICE_PANE_TIME_SERIES_DATA_TO_STORE: null,
        UPDATE_INDICATOR_PANE_TIME_SERIES_DATA_TO_STORE: null,
        UPDATE_PRICE_PANE_EVENT_SERIES_DATA_TO_STORE: null,
        SHOW_WIZARD_DIALOG: null,
        UPDATE_CURRENT_SETTINGS_OBJECT: null,
        LOAD_EXTERNAL_LIST_ON_WIZARD_ACCEPT: null,
        ROLL_BACK_ACTION: null,
        ROLL_BACK_ACTION_SUCCESS: null,
        REMOVE_DELETED_LISTS: null,
        REMOVE_LISTS_FROM_SOURCE :null,
        INIT_GRID_LOAD: null,
        INIT_GRID_LOAD_SUCCESS: null,
        ON_GRID_DIMENSION_CHANGE: null,
        ON_GRID_DIMENSION_CHANGE_SUCCESS: null,
        SET_VISIBLE_ROWS_DATA: null,
        SET_VISIBLE_ROWS_DATA_SUCCESS: null,
        CHANGE_LIST_NAME_IN_SOURCE: null,
        DRAW_FUNDAMENTAL_DATA_LINES: null,
        LOAD_START_WIZARD_ON_ACCEPT: null,
        SET_START_WIZARD_ID: null,
        SET_SYMBOL_COLUMN: null,
        SET_DATE_COLUMN: null,
        UPDATE_EVENT_SERIES_DATA: null,
        UPDATE_EXTERNAL_DATA_REQ_INITIATED: null,
        ON_CHANGE_COLUMN_SORT: null,
        ON_CHANGE_COLUMN_SORT_SUCCESS: null,
        ON_UPDATE_LOADER: null,
        ON_UPDATE_LOADER_SUCCESS: null	,
        CHANGE_SYMBOL_COLUMN_IN_SOURCE: null,
        CHANGE_DATE_COLUMN_IN_SOURCE: null,
        MOVE_NEXT_STEP: null,
        MOVE_PREVIOUS_STEP: null,
        CLOSE_DATE_FORMAT_DIALOG: null,
        FINISH_AND_SAVE_HEADERS: null,
        CLEAR_SORT_COLUMNS:null,
        CLEAR_SORT_COLUMNS_SUCCESS:null,
        SET_START_WIZARD_ID_EMPTY: null,
        LIST_UNDO_ACTION:null,
        UPDATE_LAST_PAGE_INDEX:null,
        UPDATE_LAST_PAGE_INDEX_SUCCESS:null,
        SET_DEFAULT_DATA:null,
        SHOW_DATE_FORMAT_SELECT: null,
        CLOSE_UPLOAD_WIZARD: null,
        SORT_EXTERNAL_LIST: null,
        SORT_SOURCE_LIST:null,
        RESTORE_DELETED_LISTS:null,
        SHOW_EXTERNAL_INFO_TEXT: null,
        EXTERNAL_FILE_UPLOAD_ON_DROP: null,
        CHANGE_UPLOAD_FREQUENCY: null,
        SET_NEW_DATE_FORMAT: null,
        UPDATE_DATE_FORMAT: null,
        CLEAR_TIME_SERIES_DATA: null,
        UPDATE_TO_SETTINGS: null,
        EXTERNAL_FILE_UPLOAD_DIALOG_REPEAT: null,
        EXTERNAL_FILE_UPLOAD_DIALOG_REPEAT_TOGGLE: null,
        UPDATE_EXPANDED_EVENT_BADGE_DATA: null,
        UNDO_EXTERNAL_LIST_ROLLBACK:null,
        UNDO_EXTERNAL_REUPLOAD:null,
        EXTERNAL_FILE_RE_UPLOADED: null,
        UPDATE_RI_PANEL_DATA: null,
        UPDATE_PDR_TO_REDUCER: null,
        UPDATE_PDR_TO_HEADER_STORE: null,
        IS_UPDATED: null,
        IS_UPDATED_SUCCESS: null,
        EXTERNAL_FILE_RECURRING_UPLOAD: null,
        LOAD_START_WIZARD_DATE: null,
        LOAD_START_WIZARD_DATE_FORMAT: null,
        SET_START_WIZARD_DATE: null,
        SET_START_WIZARD_DATE_FORMAT: null,
        FTP_USERNAME_CHANGE_SUCCESS: null,
        FTP_PASSWORD_CHANGE_SUCCESS: null,
        SHOW_FILE_FORMAT_EXCEPTION: null,
        SHOW_EXCEPTION_DIALOG: null,
        CLOSE_EXCEPTION_WINDOW: null,
        LOAD_EXTERNAL_LIST_FAIL: null,
        HANDLE_INPUT_VALUE_CHANGE: null,
        SAVE_LABEL: null,
        UPDATE_PRICE_AND_INDICATOR_MENU: null,
        EXD_THEME_CHANGED: null,
        SHOW_FILE_EXCEPTION_DIALOG: null,
        CLOSE_FILE_UPLOAD_WIZARD: null,
        OVERWRITE_SETTINGS_ON_REUPLOAD: null,
        IS_CREATE_LIST: null,
        IS_CREATE_LIST_SUCCESS: null,
        HOLD_ON_USER_EVENTS: null,
        HANDLE_ERROR: null,
        PROCESS_LIST_DATA: null,
        TOGGLE_FOLDER_VIEW: null,
        SET_EMPTY_NEW_USER: null,
        FIND_NEXT_AVAILABLE_LIST: null,
        SORT_EXTERNAL_SOURCE_LIST: null,
        VIEWPORT_CHANGE_EXTERNAL: null,
        DISABLE_DELETE_ICON: null,
        UPDATE_EXD_LIST_EXPLORER_RAW_DATA: null,
        TOGGLE_EXT_DATA_ITEM: null,
        UPDATE_EXTERNAL_INDICATOR: null
    })
}

export const ExternalDataListMenu = [   
    { header: 'Share', key: 'SHARE',Type: 'ExternalLists'},
    { header: 'Duplicate', key: 'DUPLICATE',Type: 'ExternalLists' },
    { header: 'Rename', key: 'RENAME',Type: 'ExternalLists' },
    { header: 'Delete', key: 'DELETE',Type: 'ExternalLists' }
  ];

export const ExternalDataListMenu_Shared = [
    { header: 'Duplicate', key: 'DUPLICATE',Type: 'ExternalLists' },
    { header: 'Remove', key: 'REMOVE',Type: 'ExternalLists' }
  ];

export const ExternalDataListMenu_MNodeSel = [
    { header: 'Share', key: 'SHARE',Type: 'ExternalLists'},
    { header: 'Delete', key: 'DELETE',Type: 'ExternalLists' }
  ];
  
export const ExternalDataListMenu_ShareMNodeSel = [
    { header: 'Remove', key: 'REMOVE',Type: 'ExternalLists' }
  ];