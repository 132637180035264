import GetDdeParametersNasdaqBasicRequest from '../Requests/GetDdeParametersNasdaqBasicRequest.js'
import BaseServiceApiWCF from 'BaseServiceApiWCF';


class PreferenceApi {
  GetDdeParametersNasdaqBasic(appversion, isNasdaqentitled, isBatsentitled) {

    const req = new GetDdeParametersNasdaqBasicRequest(appversion, isNasdaqentitled, isBatsentitled);
    return BaseServiceApiWCF.ProcessServiceCall(req);

  }
}
var preferenceApi = new PreferenceApi();
export default preferenceApi;
