import DateHelper from "DateHelper";
import LocalizationStore from 'LocalizationStore';
import _ from "underscore";
import StringUtil from 'StringUtil';

export default class TopSectorRatingData {
    constructor(topSymbols, grpRankData, grpRankSymbols, stockInfo) {
        this.labelWidth = 46;
        this.stockInfo = stockInfo;
        this.cellItemConut = 10;
        this.isSector =
            this.setTopSectorRatingData(topSymbols, grpRankData, grpRankSymbols);
    }

    setTopSectorRatingData(topSymbols, grpRankData, grpRankSymbols) {
        this.rowSource = [];
        const uniqueDates = grpRankData.map((item)=> item.date ? StringUtil.convertFromLongValueToInt(item.date) : "").filter((value, index, array)=> array.indexOf(value) === index);
        for (let i = 0; i <= topSymbols.length; i++) {
            let grpRankDataForSymbol = [];
            let grpRankSymbolDetails;

            if (i > 0) {
                grpRankDataForSymbol = grpRankData.filter((x) => x.wonIndustryCode === topSymbols[i - 1].wonIndustryCode)
                grpRankSymbolDetails = _.find(grpRankSymbols, { symbol: topSymbols[i - 1].wonIndustryCode });
            }

            if ((i === 0) || (grpRankDataForSymbol && grpRankDataForSymbol.length > 0)) {
                const row = { cells: [] };
                for (let j = 0; j < this.cellItemConut + 1; j++) {
                    let cell = {};

                    if (i === 0 && j === 0) {
                        cell.value = LocalizationStore.getTranslatedData("sb_wklylabel", "SYMBOL");
                        cell.IsLabelCell = true;
                        cell.textAlign = "center";
                    }
                    else if (i === 0 && j === 1) {
                        cell.value = LocalizationStore.getTranslatedData("sb_wklylabel", "TOP IND GRPS BY GRP RNK");
                        cell.IsLabelCell = true;
                        cell.textAlign = "center";
                    }
                    else if (i === 0 && j > 1) {
                        cell.value = DateHelper.getMonthAndDay(DateHelper.parseJsonDate(new Date(uniqueDates[this.cellItemConut - j])));
                        cell.textAlign = "center";
                    }
                    else if (i > 0 && j === 0) {
                        cell.value = topSymbols[i - 1].wonIndustryCode;
                        cell.IsLabelCell = true;
                        cell.textAlign = "left";
                        cell.isSymbol = true;
                    }
                    else if (i > 0 && j === 1) {
                        if (grpRankSymbolDetails)
                            cell.value = grpRankSymbolDetails.companyName;
                        else
                            cell.value = "";
                        cell.IsLabelCell = true;
                        cell.textAlign = "left";
                        cell.marginLeft = "5px";
                    }
                    else
                        cell = this.getDataCell(grpRankDataForSymbol, i, j);

                    row.cells.push({ cell });
                }
                this.rowSource.push(row);
            }
        }
    }

    getDataCell(grpRankData, row, col) {
        const extraItems = grpRankData.length - this.cellItemConut;
        const item = grpRankData[grpRankData.length - (col + extraItems)];

        const cell = {};
        cell.textAlign = "right";
        cell.value = "";
        if (item) {
            if (item.groupRank || item.groupRank === 0) {
                cell.value = item.groupRank;
            }
        }

        return cell;
    }
}