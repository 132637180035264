import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AnnotationLayersGetRequest extends BaseServiceRequest {

    constructor() {
        super();
        super.addRequestUri("annotationlayers.pb");
    }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return "AnnotationLayerGetResponse";
  }
}

export default AnnotationLayersGetRequest;
