import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale.js";
import BaseServiceApi from 'BaseServiceApi';
import BlockType from "../../../../Constants/BlockType.js";
import ConsoleStore from "ConsoleStore";
import { DatagraphConst } from "../../../../Utils/DatagraphHelper.js";
import DateHelper from "../../../../Utils/TimeLineHelper/Datehelper.js";
import graphApi from "../../../../ServiceApi/Apis/GraphApi.js";
import GraphType from "GraphType";
import LocalizationStore from 'LocalizationStore';
import PeriodicityHelper from "PeriodicityHelper";
import { PriceChartConst } from "../../../../Constants/PriceChartConstants.js";
import SettingsStore from "SettingsStore";
import StockMarketUtil from "../../../../Utils/StockMarketUtil.js";
import StringUtil from "../../../../Utils/StringUtil.js";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow.js";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getFundamentalLineStates, getIndicatorStates, getTimeLine, priceChartReducerselect } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors.js";
import { indicatorCommonConst, IndicatorLineConst, IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants.js";
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper.js";
import {  updateIndicatorAbsoluteData, updateIndicatorData, updateIndicatorGraphData, updateIndicatorRelativeData, updateIndicatorSettings } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions.js";

const ONeilRatingType = BaseServiceApi.rayData["ONeilRatingType"];
export const FMIndicatorConst = {
    [IndicatorLineConst.EPSR_CURR_FIS_QTR]: LocalizationStore.getTranslatedData("ipt_curqest", "Current Quarter"),
    [IndicatorLineConst.EPSR_CURR_FIS_YR]: LocalizationStore.getTranslatedData("ipt_curyrest", "Current Year"),
    [IndicatorLineConst.EPSR_NXT_FIS_YR]: LocalizationStore.getTranslatedData("ipt_ntyrest", "Next Year"),
    labelCurrYear: LocalizationStore.getTranslatedData('ipt_curyrest', "Cur Yr"),
    labelNextYear: LocalizationStore.getTranslatedData('ipt_ntyrest', "Next Yr"),
    labelCurrQtr: LocalizationStore.getTranslatedData("ipt_curqest", "Cur Qtr"),
}
const { ActionTypes } = IndicatorsConstants;
const fundamentalIndicatorChartTypes = [BlockType.PTOE, BlockType.PTOS, BlockType.EPSR];
const fundamentalIndicatorSettingConst = {
    [BlockType.PTOE]: "PTOEChartSettings",
    [BlockType.PTOS]: "PTOSChartSettings",
    [BlockType.EPSR]: "EPSRChartSettings"
}
function get_PTOE_PTOS_Chart(hsfResults, prevNode, isAbsolute = 0, isIndex = false, isShowDots = true, isEstimates = false, chartType, PTOENodeData = [], startXPoint, scale, pricePanelData, periodicity, HiLowPoints, timeLine, epsLineData) {
    const prcLength = hsfResults.length;
    const chartData = [];
    if (!pricePanelData.HsfData) {
        return chartData;
    }
    let hsfData = pricePanelData.HsfData.HSFResults;
    let epsIndex = 0;
    let firstNode = false;
    if (epsLineData.length < 1 || prcLength < 1 || PTOENodeData === undefined || !pricePanelData.HsfData) {
        return chartData;
    }

    const showDots = isShowDots && periodicity !== GraphType.Quarterly &&
         periodicity !== GraphType.Annual &&
        isAbsolute !== -1;
    let eNode = 0;

    while (epsLineData[eNode].dotted < 1) {
        eNode++;
    }

    const lastNodexAxis = epsLineData[eNode].xAxis;
    if (isAbsolute === 0 && prevNode === null) {
        hsfData = HiLowPoints.allPoints;
    }
    let jz = 0;
    while (!hsfData[jz].IsVisible) {
        jz++;
    }

    const jx = 0;
    if (isIndex) {
        hsfResults = hsfResults.filter((t) => t.Date.getTime() <= hsfData[jz].Date.getTime());
    }

    timeLine = timeLine.filter((t) => t.Date.getTime() <= hsfResults[0].Date.getTime());
    const tLength = timeLine.length;
    let j = 0;
    let t = 0;
    const nHsfData = [];
    for (; t < tLength && j < prcLength && hsfResults[j] && timeLine[t]; j++, t++) {
        while (timeLine[t] && hsfResults[j] && hsfResults[j].Date.getTime() < timeLine[t].Date.getTime()) {
            nHsfData.push({
                Close: 0,
                Date: timeLine[t].Date
            });
            t++;
        }
        // Handle partial nodes for periodicity weekly & monthly
        if (t < tLength && hsfResults[j].Date.getTime() > timeLine[t].Date.getTime()) {
            t--;
        }
        nHsfData.push({
            Close: hsfResults[j].Close,
            Date: hsfResults[j].Date
        });
    }
    hsfResults = nHsfData;

    if (isAbsolute === 0 && prevNode === null) {
        startXPoint = hsfData[0].xAxis;
    } else {
        // if (jx === 0) {
        //     while (hsfResults[jx].Date.getTime() > hsfData[0].Date.getTime()) {
        //         jx++;
        //     }
        //     jx = findIndex(hsfData, hsfResults[jx].Date);
        //     // if (jx > -1) {
        //     //     startXPoint -= DatagraphConst.nodeWidth * (jx - 1);
        //     // }
        // }
    }
    j = 0;
    let prevPoint = 0;
    let lastPoint = false;
    let yPrice = 0;

    for (; j < prcLength && hsfResults[j]; j++) {
        if (hsfResults[j].Date.getTime() > hsfData[jx].Date.getTime()) {
            continue;
        }
        const val = hsfResults[j].Close;
        startXPoint -= DatagraphConst.nodeWidth;
        if (startXPoint < 0) {
            break;
        }
        if (isNaN(val) || val === 0) {
            if (firstNode) {
                chartData.push({
                    Date: hsfResults[j].Date,
                    yPrice: yPrice,
                    xAxis: startXPoint,
                    yValue: 0
                });
            }
            continue;
        }
        if (!isIndex && (hsfResults[j].Date > epsLineData[epsIndex].ReportDate || epsLineData[epsIndex].xPoint < startXPoint && !firstNode)) {
            if (isAbsolute === 1 || isEstimates) {
                continue;
            }
        }
        if (!isIndex && !firstNode) {
            if (isAbsolute === 1) {
                startXPoint = lastNodexAxis - 2;
            }
            else if (chartType === BlockType.PTOS) {
                startXPoint += DatagraphConst.nodeWidth;
            }
            // startx = startXPoint;
        }
        firstNode = true;
        yPrice = scale.ComputeY(val);
        chartData.push({
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: startXPoint,
            yValue: val
        });
        if (showDots) {
            if (!lastPoint && prevNode) {
                PTOENodeData.push({
                    Date: prevNode.Date,
                    yPrice: prevNode.yPrice,
                    xAxis: prevNode.xAxis,
                    yValue: prevNode.yValue
                });
                lastPoint = true;
            }
            if (startXPoint === epsLineData[epsIndex].xPoint && epsLineData[epsIndex].dotted === 0) {
                prevPoint = epsLineData[epsIndex].xPoint;
                PTOENodeData.push({
                    Date: hsfResults[j].Date,
                    yPrice: yPrice,
                    xAxis: epsLineData[epsIndex].xPoint,
                    yValue: val
                });
                epsIndex++;
            } else {
                if (prevPoint === epsLineData[epsIndex].xPoint) {
                    epsIndex++;
                }
            }
        }
    }

    if (prevNode) {
        chartData.push(prevNode);
    }

    return chartData;
}
function get_PTOE_PTOS_Shade(hsfResults, chartType, startXPoint, scale, HiLowPoints, timeLine, epsLineData) {
    const prcLength = hsfResults.length;
    let startx = startXPoint;
    const chartData = [];
    let firstNode = false;
    const hsfData = HiLowPoints.allPoints;
    if (epsLineData.length < 1) {
        return chartData;
    }
    timeLine = timeLine.filter((t) => t.Date.getTime() <= hsfResults[0].Date.getTime());
    let j = 0;
    let t = 0;
    const nHsfData = [];
    for (; j < prcLength && hsfResults[j] && timeLine[t]; j++, t++) {
        while (timeLine[t] && hsfResults[j].Date < timeLine[t].Date) {
            nHsfData.push({
                Close: 0,
                Date: timeLine[t].Date
            });
            t++;
        }
        if (chartType === BlockType.PTOS && j > 0 && j < prcLength - 2 && hsfResults[j - 1].Close === 0 && hsfResults[j + 1].Close === 0) {
            hsfResults[j].Close = 0;
        }
        // Handle partial nodes for periodicity weekly & monthly
        if (hsfResults[j].Date > timeLine[t].Date) {
            t--;
        }
        nHsfData.push({
            Close: hsfResults[j].Close,
            Date: hsfResults[j].Date
        });
    }
    hsfResults = nHsfData;

    j = 0;
    while (hsfResults[j].Date > hsfData[0].Date) {
        j++;
    }

    let xAxis = hsfData[0].xAxis;
    let lastx = xAxis;
    const xDate = new Date();
    for (; j < prcLength && hsfResults[j]; j++) {
        if (hsfResults[j].Date > hsfData[0].Date) {
            continue;
        }
        xAxis -= DatagraphConst.nodeWidth;
        if (xAxis < 0) {
            break;
        }
        const val = hsfResults[j].Close;
        if (isNaN(val) || val === 0) {
            if (firstNode) {
                chartData.push({
                    Date: hsfResults[j].Date,
                    yPrice: undefined,
                    xAxis: xAxis,
                    yValue: 0
                });
            }
            continue;
        }
        //if (hsfResults[j].Date > epsLineData[epsIndex].ReportDate || epsLineData[epsIndex].xPoint < xAxis && !firstNode) {
        //    continue;
        //}
        if (!firstNode) {
            if (chartType === BlockType.PTOS) {
                xAxis += DatagraphConst.nodeWidth;
            }
            startx = xAxis;
        }
        firstNode = true;
        const yPrice = scale.ComputeY(val);
        chartData.push({
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: xAxis,
            yValue: val
        });
        lastx = xAxis;
    }
    const yPrice = scale.ComputeY(1.000);
    chartData.push({
        Date: xDate,
        yPrice: yPrice,
        xAxis: lastx,
        yValue: 1.000
    });
    chartData.push({
        Date: xDate,
        yPrice: yPrice,
        xAxis: startx,
        yValue: 1.000
    });

    return chartData;
}

function init_PTOE_PTOS_Scale(graphData, indicatorsHeight, lastNode, graphType, isAbsolute, startXPoint, timeLine) {
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;
    graphData.StockResult.map((t)=> { t.Date = new Date(DateHelper.getPSTFromLong(t.Date)); return t; });
    graphData.IndexResult.map((t)=>{ t.Date = new Date(DateHelper.getPSTFromLong(t.Date)); return t; });
    graphData.ConsensusResult.map((t)=> { t.Date = new Date(DateHelper.getPSTFromLong(t.Date)); return t; });
    graphData.HiEstResult.map((t)=>{ t.Date = new Date(DateHelper.getPSTFromLong(t.Date)); return t; });
    graphData.LoEstResult.map((t)=>{ t.Date = new Date(DateHelper.getPSTFromLong(t.Date)); return t; });
    const sLength = graphData.StockResult.length;
    let endDate;
    for (let minMaxIndex = lastNode; minMaxIndex < sLength; minMaxIndex++) {
        endDate = graphData.StockResult[minMaxIndex].Date;

        if (endDate > timeLine[0].Date) {
            continue;
        }

        if (graphData.StockResult[minMaxIndex] && graphData.StockResult[minMaxIndex].Close > 0) {
            if (minValue > graphData.StockResult[minMaxIndex].Close) {
                minValue = graphData.StockResult[minMaxIndex].Close;
            }
            if (maxValue < graphData.StockResult[minMaxIndex].Close) {
                maxValue = graphData.StockResult[minMaxIndex].Close;
            }
        }
        if (isAbsolute) {
            if (graphData.ConsensusResult[minMaxIndex] && graphData.ConsensusResult[minMaxIndex].Close > 0) {
                if (minValue > graphData.ConsensusResult[minMaxIndex].Close) {
                    minValue = graphData.ConsensusResult[minMaxIndex].Close;
                }
                if (maxValue < graphData.ConsensusResult[minMaxIndex].Close) {
                    maxValue = graphData.ConsensusResult[minMaxIndex].Close;
                }
            }
            if (graphData.HiEstResult[minMaxIndex] && graphData.HiEstResult[minMaxIndex].Close > 0) {
                if (minValue > graphData.HiEstResult[minMaxIndex].Close) {
                    minValue = graphData.HiEstResult[minMaxIndex].Close;
                }
                if (maxValue < graphData.HiEstResult[minMaxIndex].Close) {
                    maxValue = graphData.HiEstResult[minMaxIndex].Close;
                }
            }
            if (graphData.LoEstResult[minMaxIndex] && graphData.LoEstResult[minMaxIndex].Close > 0) {
                if (minValue > graphData.LoEstResult[minMaxIndex].Close) {
                    minValue = graphData.LoEstResult[minMaxIndex].Close;
                }
                if (maxValue < graphData.LoEstResult[minMaxIndex].Close) {
                    maxValue = graphData.LoEstResult[minMaxIndex].Close;
                }
            }
            if (graphData.IndexResult[minMaxIndex] && graphData.IndexResult[minMaxIndex].Close > 0) {
                if (minValue > graphData.IndexResult[minMaxIndex].Close) {
                    minValue = graphData.IndexResult[minMaxIndex].Close;
                }
                if (maxValue < graphData.IndexResult[minMaxIndex].Close) {
                    maxValue = graphData.IndexResult[minMaxIndex].Close;
                }
            }
        }
        startXPoint -= DatagraphConst.nodeWidth;
        if (startXPoint < 0) {
            break;
        }
    }

    const scaleType = isAbsolute ? 2 : 3;
    const scale = new ArithmaticScale();
    scale.InitScale(minValue, maxValue, indicatorsHeight, graphType, scaleType, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true);
    return scale;
}

function getEPSRChart(hsfResults, HiLowPoints, scale) {
    const prcLength = hsfResults.length;
    if (HiLowPoints.allPoints === undefined) {
        return [];
    }
    let xAxis = HiLowPoints.allPoints[0].xAxis;
    const chartData = new Array(prcLength);

    const width = DatagraphConst.nodeWidth;

    let x = 0;
    for (let j = 0; j < prcLength; j++) {
        if (xAxis < 0){ 
            break;
        }
        const val = hsfResults[j].Close;
        if (isNaN(val) || val === 0){ 
            continue;
        }
        const yPrice = scale.ComputeY(val);
        const info = {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: xAxis,
            yValue: val
        };
        chartData[x] = info;
        x++;
        xAxis -= width;
    }
    return chartData;
}

function getGraphData(graphData, firstNode, prNodes) {
    const prNodesLength = prNodes.length;
    const newData = [];
    const gLength = graphData.length;

    if (gLength > 0) {
        for (let graphDataIndex = 0; graphDataIndex < gLength; graphDataIndex++) {

            graphData[graphDataIndex].Date = new Date(DateHelper.getPSTFromLong(graphData[graphDataIndex].Date));

            while (firstNode < prNodesLength && prNodes[firstNode] &&
                graphData[graphDataIndex].Date <= prNodes[firstNode].Date) {
                newData.push({
                    Close: graphData[graphDataIndex].Close,
                    Date: prNodes[firstNode].Date
                });
                firstNode++;
            }
        }
    }

    return newData;
}

function expandEPSRData(graphData, HiLowPoints, periodicity) {
    const prNodes = HiLowPoints.allPoints;

    let pPeriodDate = prNodes[0].Date;
    if (periodicity === GraphType.Monthly){
        pPeriodDate = StockMarketUtil.GetMEndDate(prNodes[0].Date);
    }
    if (periodicity === GraphType.Quarterly){
        pPeriodDate = StockMarketUtil.GetQEndDate(prNodes[0].Date);
    }
    if (periodicity === GraphType.Annual){
        pPeriodDate = StockMarketUtil.GetAEndDate(prNodes[0].Date);
    }

    let firstNode = 0;
    for (; pPeriodDate < prNodes[firstNode].Date; firstNode++) { ; }
    graphData.CloseResult1 = getGraphData(graphData.curQtrRev, firstNode, prNodes); // Current Fiscal Quarter calculation
    graphData.CloseResult2 = getGraphData(graphData.curYrRev, firstNode, prNodes); // Current Fiscal Year calculation
    graphData.CloseResult3 = getGraphData(graphData.nextYrRev, firstNode, prNodes); // Next Fiscal Year calculation

    return graphData;
}

function initEPSRScale(graphData, indicatorsHeight, lastNode, graphType, startXPoint, timeLine) {
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;

    let xAxis = startXPoint;
    const g1Length = graphData.CloseResult1.length;
    if(g1Length > 0){
        maxValue = graphData.CloseResult1[0].Close;
        minValue = graphData.CloseResult1[0].Close;
    }
    for (let minMaxIndex = lastNode; minMaxIndex < g1Length; minMaxIndex++) {
        if (isNaN(graphData.CloseResult1[minMaxIndex].Date)) {
            const endDate = new Date(parseInt(graphData.CloseResult1[minMaxIndex].Date.substr(6)));
            graphData.CloseResult1[minMaxIndex].Date = endDate;
        }

        if (graphData.CloseResult1[minMaxIndex].Date > timeLine[0].Date) {
            continue;
        }

        if (graphData.CloseResult1[minMaxIndex] && graphData.CloseResult1[minMaxIndex].Close) {
            if (minValue > graphData.CloseResult1[minMaxIndex].Close) {
                minValue = graphData.CloseResult1[minMaxIndex].Close;
            }
            else if (maxValue < graphData.CloseResult1[minMaxIndex].Close) {
                maxValue = graphData.CloseResult1[minMaxIndex].Close;
            }
        }
        xAxis -= 4;
        if (xAxis < 0) {
            break;
        }
        //    index++;
    }

    xAxis = startXPoint;
    const g2Length = graphData.CloseResult2.length;
    for (let minMaxIndex = lastNode; minMaxIndex < g2Length; minMaxIndex++) {
        if (isNaN(graphData.CloseResult2[minMaxIndex].Date)) {
            const endDate = new Date(parseInt(graphData.CloseResult2[minMaxIndex].Date.substr(6)));
            graphData.CloseResult2[minMaxIndex].Date = endDate;
        }

        if (graphData.CloseResult2[minMaxIndex].Date > timeLine[0].Date) {
            continue;
        }

        if (graphData.CloseResult2[minMaxIndex] && graphData.CloseResult2[minMaxIndex].Close) {
            if (minValue > graphData.CloseResult2[minMaxIndex].Close) {
                minValue = graphData.CloseResult2[minMaxIndex].Close;
            }
            else if (maxValue < graphData.CloseResult2[minMaxIndex].Close) {
                maxValue = graphData.CloseResult2[minMaxIndex].Close;
            }
        }
        xAxis -= 4;
        if (xAxis < 0) {
            break;
        }
        //    index++;
    }

    xAxis = startXPoint;
    const g3Length = graphData.CloseResult3.length;
    for (let minMaxIndex = lastNode; minMaxIndex < g3Length; minMaxIndex++) {
        if (isNaN(graphData.CloseResult3[minMaxIndex].Date)) {
            const endDate = new Date(parseInt(graphData.CloseResult3[minMaxIndex].Date.substr(6)));
            graphData.CloseResult3[minMaxIndex].Date = endDate;
        }

        if (graphData.CloseResult3[minMaxIndex].Date > timeLine[0].Date) {
            continue;
        }
        if (graphData.CloseResult3[minMaxIndex] && graphData.CloseResult3[minMaxIndex].Close) {
            if (minValue > graphData.CloseResult3[minMaxIndex].Close) {
                minValue = graphData.CloseResult3[minMaxIndex].Close;
            }
            else if (maxValue < graphData.CloseResult3[minMaxIndex].Close) {
                maxValue = graphData.CloseResult3[minMaxIndex].Close;
            }
        }
        xAxis -= 4;
        if (xAxis < 0) {
            break;
        }
        //    index++;
    }

    const scale = new ArithmaticScale();
    scale.InitScale(minValue, maxValue, indicatorsHeight, graphType, 2, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true);
    return scale;
}
function* prepareData({ graphData, indicatorsHeight, chartType, SettingDict, timeLine, HiLowPoints, periodicity, startXPoint, epsLineData, pricePanelData }) {
    try {
        if (StringUtil.isEmpty(graphData)) {
            return;
        }
        yield put(updateIndicatorGraphData(graphData, chartType));
        if (chartType !== BlockType.EPSR) {
            SettingDict.IsRelative ? yield put(updateIndicatorRelativeData(graphData, chartType)) : yield put(updateIndicatorAbsoluteData(graphData, chartType));
        }
        yield call(processData, graphData, indicatorsHeight, chartType, SettingDict, timeLine, startXPoint, epsLineData, HiLowPoints, periodicity, pricePanelData)
    }
    catch (error) {
        console.log("Error occurs in FundamentalSaga.js, prepareData", error);
    }
}

function* processData(graphData, indicatorsHeight, chartType, SettingDict, timeLine, startXPoint, epsLineData, HiLowPoints, periodicity, pricePanelData) {
    if (graphData) {
        const indicatorVisuals = { [chartType]: [] };
        const indicatorLabels = { [chartType]: { labels: [] } };
        let scale;
        if (chartType === BlockType.EPSR) {
            let currYrColor, currYrWeight, nextYrColor, nextYrWeight, currQtrColor, currQtrWeight;
            SettingDict.CurrentFiscalYearColor.forEach((item) => {
                currYrColor = ThemeHelper.getThemedBrush(item.color);
                currYrWeight = item.weight;
            });
            SettingDict.NextFiscalYearColor.forEach((item) => {
                nextYrColor = ThemeHelper.getThemedBrush(item.color);
                nextYrWeight = item.weight;
            });
            SettingDict.CurrentFiscalQuarterColor.forEach((item) => {
                currQtrColor = ThemeHelper.getThemedBrush(item.color);
                currQtrWeight = item.weight;
            });
            scale = initEPSRScale(graphData, indicatorsHeight, 0, GraphType.Weekly, startXPoint, timeLine);
            if (SettingDict.CurrentFiscalQuarter) {
                const EPSRCurrQtrData = getEPSRChart(graphData.CloseResult1, HiLowPoints, scale);
                indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: IndicatorLineConst.EPSR_CURR_FIS_QTR, DataSource: EPSRCurrQtrData, LineColor: currQtrColor, LineThickness: currQtrWeight, Draggable: false, lineID: FMIndicatorConst[IndicatorLineConst.EPSR_CURR_FIS_QTR] });
            }
            if (SettingDict.CurrentFiscalYear) {
                const EPSRCurrYrData = getEPSRChart(graphData.CloseResult2, HiLowPoints, scale);
                indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: IndicatorLineConst.EPSR_CURR_FIS_YR, DataSource: EPSRCurrYrData, LineColor: currYrColor, LineThickness: currYrWeight, Draggable: false, lineID: FMIndicatorConst[IndicatorLineConst.EPSR_CURR_FIS_YR] });
            }

            if (SettingDict.NextFiscalYear) {
                const EPSRNextYrData = getEPSRChart(graphData.CloseResult3, HiLowPoints, scale);
                indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: IndicatorLineConst.EPSR_NXT_FIS_YR, DataSource: EPSRNextYrData, LineColor: nextYrColor, LineThickness: nextYrWeight, Draggable: false, lineID: FMIndicatorConst[IndicatorLineConst.EPSR_NXT_FIS_YR] });
            }
            indicatorLabels[chartType].isType = false;
            indicatorLabels[chartType].LabelMenu = { label: IndicatorLabelTranslateHelper[BlockType.EPSR], isTICustomModal: true, isShowSettingsDialog: true}
            if (SettingDict.CurrentFiscalYear) {
                indicatorLabels[chartType].labels.push({ backgroundColor: currYrColor, color: currYrColor, key: "Label1", value: FMIndicatorConst['labelCurrYear'] })
            }
            if (SettingDict.NextFiscalYear) {
                indicatorLabels[chartType].labels.push({ backgroundColor: nextYrColor, color: nextYrColor, key: "Label2", value: FMIndicatorConst['labelNextYear'] })
            }
            if (SettingDict.CurrentFiscalQuarter) {
                indicatorLabels[chartType].labels.push({ backgroundColor: currQtrColor, color: currQtrColor, key: "Label3", value: FMIndicatorConst['labelCurrQtr'] })
            }
        }
        else {
            const PTOENodeData = [];
            const isAbsolute = SettingDict.IsRelative ? 0 : 1;
            let CoName = '';
            let IndexName = '';
            let CoSymbol = '';
            let DataSource = null;
            if (pricePanelData && pricePanelData.SymbolInfo) {
                CoName = SettingDict.IsRelative ? undefined : pricePanelData.SymbolInfo.CompanyName;
                IndexName = pricePanelData.SymbolInfo.IndexName;
                CoSymbol = `${pricePanelData.SymbolInfo.Symbol} vs ${pricePanelData.SymbolInfo.IndexSymbol}`;
            }
            let LineThickness, LineColor, indexWeight, indexColor;
            SettingDict.StockColor.forEach((item) => {
                LineColor = ThemeHelper.getThemedBrush(item.color);
                LineThickness = item.weight;
            });
            SettingDict.IndexColor.forEach((item) => {
                indexColor = ThemeHelper.getThemedBrush(item.color);
                indexWeight = item.weight;
            });
            scale = init_PTOE_PTOS_Scale(graphData, indicatorsHeight, 0, GraphType.Weekly, !SettingDict.IsRelative, startXPoint, timeLine);
            const PTOEData = get_PTOE_PTOS_Chart(graphData.StockResult, null, isAbsolute, false, true, false, 
                                                chartType, PTOENodeData, startXPoint, scale, pricePanelData, periodicity, HiLowPoints, timeLine, epsLineData);
            
            indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: pricePanelData.SymbolInfo.Symbol, DataSource: PTOEData, LineColor, LineThickness, Draggable: false, lineID: CoName, Type: BlockType.PTOE });
            if (isAbsolute === 1) {
                if (SettingDict[`${chartType}IndexLine`]) {
                    DataSource = get_PTOE_PTOS_Chart(graphData.IndexResult, null, -1, true, true, false, 
                                                chartType, PTOENodeData, startXPoint, scale, pricePanelData, periodicity, HiLowPoints, timeLine, epsLineData);
                    indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: `${chartType}IndexLine`, DataSource, LineColor: indexColor, LineThickness: indexWeight, Draggable: false, lineID: IndexName, Type: BlockType.PTOE });
                }

                DataSource = get_PTOE_PTOS_Chart(graphData.ConsensusResult, PTOEData[0], 0, false, true, true, 
                                                chartType, PTOENodeData, startXPoint, scale, pricePanelData, periodicity, HiLowPoints, timeLine, epsLineData);
                indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: "Line3", DataSource, LineColor, LineThickness: 1, DashArray: [2, 3], Draggable: false, lineID: CoName, Type: BlockType.PTOE });

                if (SettingDict.HLEstimates) {
                    DataSource = get_PTOE_PTOS_Chart(graphData.HiEstResult, PTOEData[0], 0, false, SettingDict.HLEstimates, true, 
                                                    chartType, PTOENodeData, startXPoint, scale, pricePanelData, periodicity, HiLowPoints, timeLine, epsLineData);
                    indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: "Line4", DataSource, LineColor, LineThickness: 1, DashArray: [2, 3], Draggable: false, lineID: CoName, Type: BlockType.PTOE });

                    DataSource = get_PTOE_PTOS_Chart(graphData.LoEstResult, PTOEData[0], 0, false, SettingDict.HLEstimates, true, 
                                                        chartType, PTOENodeData, startXPoint, scale, pricePanelData, periodicity, HiLowPoints, timeLine, epsLineData);
                    indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: "Line5", DataSource, LineColor, LineThickness: 1, DashArray: [2, 3], Draggable: false, lineID: CoName, Type: BlockType.PTOE });
                }
                if (!SettingDict.IsRelative) {
                    indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: true, key: "Line6", DataSource: PTOENodeData, LineColor, LineThickness: 1, Draggable: false });
                }


            }
            else {
                DataSource = get_PTOE_PTOS_Shade(graphData.StockResult, chartType, startXPoint, scale, HiLowPoints, timeLine, epsLineData);
                indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: "Line7", DataSource, LineColor, LineThickness, Opacity: "0.4", Fill: LineColor, Draggable: false, lineID: CoSymbol, Type: BlockType.PTOE });
            }

            indicatorLabels[chartType].class = "types-n-scales hoverable";
            if (SettingDict.IsRelative) {
                indicatorLabels[chartType].scaleText = IndicatorCommonTranslateHelper[indicatorCommonConst.Relative];
                const value = `vs. ${IndicatorCommonTranslateHelper[indicatorCommonConst.SymbolType_INDEX]}`;
                indicatorLabels[chartType].labels.push({ backgroundColor: LineColor, key: "Label1", color: LineColor, value })
            }
            else {
                indicatorLabels[chartType].scaleText = IndicatorCommonTranslateHelper[indicatorCommonConst.Absolute];
                const value = pricePanelData.SymbolInfo.Symbol;
                indicatorLabels[chartType].labels.push({ backgroundColor: LineColor, key: "Label2", color: LineColor, value })
                if ((chartType === BlockType.PTOE && SettingDict.PTOEIndexLine) || (chartType === BlockType.PTOS && SettingDict.PTOSIndexLine)) {
                    const value = IndicatorCommonTranslateHelper[indicatorCommonConst.SymbolType_INDEX];
                    indicatorLabels[chartType].labels.push({ backgroundColor: indexColor, key: "Label3", color: indexColor, value })
                }
            }
            if ((chartType === BlockType.PTOE && SettingDict.PTOEIndexLine) || (chartType === BlockType.PTOS && SettingDict.PTOSIndexLine)) {
                indicatorLabels[chartType].cursor = "pointer";
                indicatorLabels[chartType].pointerEvents = "all";
                indicatorLabels[chartType].borderBottom = "1px dotted";
            }
            else {
                indicatorLabels[chartType].class = "types-n-scales";
                indicatorLabels[chartType].cursor = "default";
                indicatorLabels[chartType].pointerEvents = "none";
                indicatorLabels[chartType].borderBottom = "none";
            }
            indicatorLabels[chartType].Label = IndicatorLabelTranslateHelper[chartType];
            indicatorLabels[chartType].isType = true;
            indicatorLabels[chartType].LabelMenu = { label: IndicatorLabelTranslateHelper[chartType], isTICustomModal: true, isShowSettingsDialog: true, isShowAboutDialog: true };
        }
        yield put(updateIndicatorData(indicatorVisuals, indicatorLabels, chartType, scale));
    }
}

function* initIndicator() {
    try {
        const { indicatorResponse, isAllIndicatorDataApiInitiated} = yield select(getIndicatorStates);
        if((!isAllIndicatorDataApiInitiated)&& indicatorResponse){
            //Its end Tracker will be added in oneilRating indicator saga. It is depending upon order of watcher in root saga.
            TimeTrackingWindow.beginTechIndicatorRenderTimeTracker();
            const { viewsSettings, majorPeriodicity, startXPoint, periodicity, pricePanelData, indicatorsHeight } = yield select(getDatagraphStates);
            const timeLine = yield select(getTimeLine);
            const { processedLineData } = yield select(getFundamentalLineStates);
            const { HiLowPoints } = yield select(priceChartReducerselect);
            if(indicatorResponse[BlockType.EPSR]){
                expandEPSRData(indicatorResponse[BlockType.EPSR] || {}, HiLowPoints, periodicity);
            }
            const SettingDict = {};
            for(const item of fundamentalIndicatorChartTypes){
                SettingDict[item] = viewsSettings[fundamentalIndicatorSettingConst[item]] ? viewsSettings[fundamentalIndicatorSettingConst[item]][majorPeriodicity] : {};
                if (SettingDict[item].IsAvailable) {
                    yield fork(prepareData, { graphData: indicatorResponse[item], indicatorsHeight, chartType: item, SettingDict: SettingDict[item], timeLine, HiLowPoints, periodicity, startXPoint, epsLineData: processedLineData[PriceChartConst.EPS], pricePanelData });
                }
            }
            yield put(updateIndicatorSettings(SettingDict));
        }
    }
    catch (error) {
        console.error("Error occurs in FundamentalSaga.js, initIndicator", error);
    }

}

//This block will be executed on toggle of absolute and relative of P/S and P/E
function* togglePEPSChart({ chartType }) {
    try {
        const state = yield select(getIndicatorStates);
        const timeLine = yield select(getTimeLine);
        const { pricePanelData, periodicity, startXPoint, indicatorsHeight, Symbol} = yield select(getDatagraphStates);
        const { processedLineData } = yield select(getFundamentalLineStates);
        const { HiLowPoints } = yield select(priceChartReducerselect);
        state.SettingDict[chartType].IsRelative = !state.SettingDict[chartType].IsRelative;
        let graphData;
        if (state.SettingDict[chartType].IsRelative && !StringUtil.isEmpty(state.RelativeData[chartType])) {
            graphData = state.RelativeData[chartType];
        }
        else if (!state.SettingDict[chartType].IsRelative && !StringUtil.isEmpty(state.AbsoluteData[chartType])) {
            graphData = state.AbsoluteData[chartType];
        }
        else {
            const findGetTechIndRequest = {
                symbol: Symbol,
                graphType: PeriodicityHelper.getGraphTypeFromPeriodicity(periodicity),
                endDate: ConsoleStore.getUserEndDate() ? DateHelper.yyyymmdd(ConsoleStore.getUserEndDate()) : null,
                fytd: ``,
                sratings: `${ONeilRatingType[chartType]}-${state.SettingDict[chartType].IsRelative ? 1 : 0}`,
                cratings: ``,
                eratings: ``,
                cytd: ``,
                eytd: ``,
                wytd: ``,
                iytd: ``
            };
            const result = yield call(graphApi.getTechnicalIndicatorData, findGetTechIndRequest);
            graphData = result[chartType];
        }
        yield call(prepareData, { graphData, indicatorsHeight, chartType, SettingDict: state.SettingDict[chartType], timeLine, HiLowPoints, periodicity, startXPoint, epsLineData: processedLineData[PriceChartConst.EPS], pricePanelData });
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.log("Error occurs in FundamentalSaga.js, togglePEPSChart", error);
    }
}

function* updateFundamentalIndicator({ chartType }){
    const state = yield select(getIndicatorStates);
    const timeLine = yield select(getTimeLine);
    const { pricePanelData, periodicity, startXPoint, indicatorsHeight } = yield select(getDatagraphStates);
    const { processedLineData } = yield select(getFundamentalLineStates);
    const { HiLowPoints } = yield select(priceChartReducerselect);
    yield call(processData, state.GraphData[chartType], indicatorsHeight, chartType, state.SettingDict[chartType], timeLine, startXPoint, processedLineData[PriceChartConst.EPS], HiLowPoints, periodicity, pricePanelData)
}

function* toggleFundamentalLine({ chartType, lineType }){
    const { SettingDict } = yield select(getIndicatorStates);
    if(chartType === BlockType.EPSR){
        if(lineType === IndicatorLineConst.EPSR_CURR_FIS_QTR){
            SettingDict[chartType].CurrentFiscalQuarter = false
        }
        else if(lineType === IndicatorLineConst.EPSR_CURR_FIS_YR){
            SettingDict[chartType].CurrentFiscalYear = false;
        }
        else if(lineType === IndicatorLineConst.EPSR_NXT_FIS_YR){
            SettingDict[chartType].NextFiscalYear = false;
        }
    }
    else{
        SettingDict[chartType][`${chartType}IndexLine`] = false;
    }

    yield fork(updateFundamentalIndicator, { chartType });
}
//============================================
// FundamentalIndicator watcher sagas

export function* watchProcessFundmamentalIndicator() {
    yield takeLatest(ActionTypes.PROCESS_INDICATOR, initIndicator)
}
export function* watchInitFundmamentalIndicator() {
    yield takeLatest(ActionTypes.INIT_INDICATOR, initIndicator)
}

export function* watchPrepareFundmamentalIndicatorData() {
    yield takeLatest(ActionTypes.PREPARE_FUNDAMENTAL_INDICATOR_DATA, updateFundamentalIndicator);
}

export function* watchToggleFundmamentalChart() {
    yield takeLatest(ActionTypes.TOGGLE_INDICATOR_CHART, togglePEPSChart)
}
export function* watchToggleFundmamentalLine() {
    yield takeLatest(ActionTypes.TOGGLE_FUNDAMENTAL_LINE, toggleFundamentalLine)
}