import BaseServiceApi from 'BaseServiceApi'
import UploadExternalData from '../Requests/UploadExternalData';
import GetCurrentListData from '../Requests/GetCurrentListData';
import GetExternalListSettings from '../Requests/GetExternalListSettings';
import UpdateExternalHeaders from '../Requests/UpdateExternalHeaders';
import GetExtCategories from '../Requests/GetExtCategories';
import GetExtrecentdata from '../Requests/GetExtrecentdata';
import UpdateLableRequest from '../Requests/UpdateLableRequest';

class DataUploadsApi {
  uploadFormData(data, externalUrl = false, uploadArr) {
    const req = new UploadExternalData(data, externalUrl,uploadArr);
    return BaseServiceApi.processServiceCall(req);
  }
  async getCurrentListData(listId, msid, beginDate, endDate, sort, pageNo, pageSize, esfields, periodicity) {
    try {
      const req = new GetCurrentListData(listId, msid, beginDate, endDate, sort, pageNo, pageSize, esfields, periodicity);
      const result = await BaseServiceApi.processServiceCall(req);
      return result;
    }
    catch (ex) {
      console.log("Exception occurs in getCurrentListData.", ex);
    }
    return null;
  }
  updateHeaders(headers, listId) {
    const req = new UpdateExternalHeaders(headers, listId);
    return BaseServiceApi.processServiceCall(req);
  }
  async getListSettings(listId) {
    try {
      const req = new GetExternalListSettings(listId);
      const result = await BaseServiceApi.processServiceCall(req);
      return result;
    }
    catch (ex) {
      console.log("Exception occurs in getListSettings.", ex);
    }
    return null;
  }

  async getExtCategories(listId, headers = null) {
    try {
      const req = new GetExtCategories(listId, headers);

      if (headers !== null) {
        return BaseServiceApi.processServiceCall(req);
      }
      else {
        const result = await BaseServiceApi.processServiceCall(req);
        return result;
      }

    }
    catch (ex) {
      console.log("Exception occurs in getExtCategories.", ex);
    }
    return null;
  }

  async getExtrecentdata(msid) {
    try {
      const req = new GetExtrecentdata(msid);
      const result = await BaseServiceApi.processServiceCall(req);
      return result;
    }
    catch (ex) {
      console.log("Exception occurs in getExtrecentdata.", ex);
    }
    return null;
  }

  async getListSettings(listId) {
    try {
      const req = new GetExternalListSettings(listId);
      const result = await BaseServiceApi.processServiceCall(req);
      return result;
    }
    catch (ex) {
      console.log("Exception occurs in getListSettings.", ex);
    }
    return null;
  }

  updatelabel(listId, headers = null) {
    try {
      const req = new UpdateLableRequest(listId, headers);
      return BaseServiceApi.processServiceCall(req);
    }
    catch (ex) {
      console.log("Exception occurs in updatelabel.", ex);
    }
  }
}
const dataUploadsApi = new DataUploadsApi();
export default dataUploadsApi;