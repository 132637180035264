import React, { Component } from 'react';
import { connect } from 'react-redux';
import Measure from "react-measure";
import BenchMarkHeader from '../Presentational/BenchMarkHeader';
import BenchMarkGraph from './BenchMarkGraph';
import TimeLine from '../Presentational/TimeLine';
import { updateBenchMarkDimensionChange, toggleTrendLines } from 'Actions/RotationGraphActions';
import ThemeHelper from "ThemeHelper";
import ErrorBoundary from 'ErrorBoundary';
import { CompareMenuType } from "CompareTabType";
import AppColorStore from "../../../../../Stores/AppColor/AppColorStore";
import { ActionTypes } from ".../../../Constants/AppColorConstants";
import { PrintMode } from "../../../../../print/printmode.js";
import ConsoleStore from "ConsoleStore";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";

let shouldHandleResize = true;
class Footer extends Component {
    constructor() {
        super();
        this.AppColorStoreStateChanged = this.AppColorStoreStateChanged.bind(this);
    }

    UNSAFE_componentWillMount = () => {
        if (PrintMode.printing) {
            ConsoleStore.addChangeListener(this.ConsoleStateChange);
        }
    }

    componentWillUnmount() {
        if (PrintMode.printing) {
            ConsoleStore.removeChangeListener(this.ConsoleStateChange);
            shouldHandleResize = true;
        }
    }

    ConsoleStateChange() {
        const currentAction = ConsoleStore.getCurrentAction();
        if (currentAction === ConsoleConstants.ActionTypes.SET_CHART_LOADED) {
            shouldHandleResize = false;
        }
    }

    toggleTrendLines = (isUpTrend) => {
        this.props.toggleTrendLines(isUpTrend);
    }

    componentDidMount() {
        AppColorStore.addChangeListener(this.AppColorStoreStateChanged);
    }

    AppColorStoreStateChanged() {
        const currentAction = AppColorStore.getAction();
        if (currentAction === ActionTypes.CUSTOM_COLOR_CHANGED || ActionTypes.CUSTOM_THEME_CHANGED) {
            this.forceUpdate();
        }
    }

    onDimensionsChange = (contentRect) => {
        const dim = contentRect.bounds;
        const { dimensions } = this.props;
        if ((dimensions.width !== dim.width || dimensions.height !== dim.height) && shouldHandleResize)
            this.props.updateBenchMarkDimensionChange(dim);
    };

    getStyleProperties() {
        const tempPositiveStyle = {}, tempNegativeStyle = {};
        const { isUptrendVisible, isDowntrendVisible } = this.props;

        if (isUptrendVisible) {
            tempPositiveStyle["background"] = ThemeHelper.getThemedBrush("positiveNode")
        }
        if (isDowntrendVisible) {
            tempNegativeStyle["background"] = ThemeHelper.getThemedBrush("negativeNode")
        }

        tempPositiveStyle["stroke"] = ThemeHelper.getThemedBrush("positiveNode");
        tempNegativeStyle["stroke"] = ThemeHelper.getThemedBrush("negativeNode");

        return { tempPositiveStyle, tempNegativeStyle }
    }

    render() {
        const { isBenchMarkLoading, groupsTimelineDateDictionary, isUptrendVisible, isDowntrendVisible, benchMarkData, dimensions, countryCode, groupsCountDataList } = this.props;
        const { tempPositiveStyle, tempNegativeStyle } = this.getStyleProperties();

        return (
            <div className="footer-rotation">
                <Measure
                    bounds
                    onResize={this.onDimensionsChange}
                >
                    {({ measureRef }) =>
                        <div className="graph-footer" ref={measureRef}>
                            <ErrorBoundary><BenchMarkHeader isBenchMarkLoading={isBenchMarkLoading} countryCode={countryCode} benchMarkData={benchMarkData} tempPositiveStyle={tempPositiveStyle} tempNegativeStyle={tempNegativeStyle} toggleTrendLines={this.toggleTrendLines} /></ErrorBoundary>
                            <ErrorBoundary><BenchMarkGraph isBenchMarkLoading={isBenchMarkLoading} trendLineData={groupsCountDataList} benchMarkData={benchMarkData} timeLineData={groupsTimelineDateDictionary} dimensions={dimensions} tempPositiveStyle={tempPositiveStyle} tempNegativeStyle={tempNegativeStyle} isUptrendVisible={isUptrendVisible} isDowntrendVisible={isDowntrendVisible} /></ErrorBoundary>
                            <ErrorBoundary><TimeLine dimensions={dimensions} timeLineData={groupsTimelineDateDictionary} /></ErrorBoundary>
                        </div>
                    }
                </Measure>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    if (selectedMenuTab === CompareMenuType.Symbols) {
        const { isBenchMarkLoading, isUptrendVisible, isDowntrendVisible, benchMarkData, dimensions, groupsTimelineDateDictionary, groupsCountDataList } = state.rotationGraph.BenchMarkReducer.SymbolsData;
        const { countryCode } = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer.RotationMenuStates;

        return { isBenchMarkLoading, groupsTimelineDateDictionary, isUptrendVisible, isDowntrendVisible, benchMarkData, dimensions, countryCode, groupsCountDataList };
    }
    else {
        const { isBenchMarkLoading, isUptrendVisible, isDowntrendVisible, benchMarkData, dimensions, groupsTimelineDateDictionary, groupsCountDataList } = state.rotationGraph.BenchMarkReducer.GroupsData;
        const { countryCode } = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.RotationMenuStates;

        return { isBenchMarkLoading, groupsTimelineDateDictionary, isUptrendVisible, isDowntrendVisible, benchMarkData, dimensions, countryCode, groupsCountDataList };
    }
};

const mapDispatchToProps = (dispatch) => ({
    updateBenchMarkDimensionChange: (dimensions) => dispatch(updateBenchMarkDimensionChange(dimensions)),
    toggleTrendLines: (isUpTrend) => dispatch(toggleTrendLines(isUpTrend))
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);