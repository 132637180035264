import BaseServiceApi from 'BaseServiceApi';
import PeriodicityHelper from "PeriodicityHelper";

class CompareReqInfo {
    constructor() {
        const getCompareScaleRequestInfoProto = BaseServiceApi.rayData["GetScaleRequestData"];
        this.compareScaleInfo = new getCompareScaleRequestInfoProto();
    }

    getCompareScaleRequestData(msid,periodicity,fromDate,endDate){
        this.compareScaleInfo.msid = msid;
        this.compareScaleInfo.FromDate = fromDate;
        this.compareScaleInfo.EndDate = endDate;
        this.compareScaleInfo.graphType = this.getGraphTypeFromPeriodicity(periodicity);
        return this.compareScaleInfo
    }

    getGraphTypeFromPeriodicity(periodicity){
       return PeriodicityHelper.getGraphTypeFromPeriodicity(periodicity);
    }
}

const compareReqInfo = new CompareReqInfo();
export default compareReqInfo;