import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetAddItemsToListRequest extends BaseServiceRequest {

    constructor(targetListId, consoleId, sourceId, saveScreenFilter, undoAdd = false) {
        super();
        super.addRequestUri("instrumentsES.pb");
        super.addRequestParameter("listId", targetListId.toString());
        super.addRequestParameter("sourcelistId", sourceId.toString());
        super.addRequestParameter("cacheId", "0");
        super.addRequestParameter("insertSeqNum", "0");
        super.addRequestParameter("ucf", saveScreenFilter.toString());
        super.addRequestParameter("c", consoleId);
        super.addRequestParameter("r", "1");
        super.addRequestParameter("isUndo", undoAdd ? "1" : "0");

        super.addRequestParameter("symbols", '');
        super.addRequestParameter("msids", '');
        super.addRequestParameter("snapshotDates", '');
        super.addRequestParameter("rts", '0'); // HardCode  AddRequestParameter("rts", ((int)WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.IntradaySourceType).ToString());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "OwnershipAddRemoveResponse";
    }
}

export default GetAddItemsToListRequest;
