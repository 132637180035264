import keyMirror from "keymirror";

export const FundamentalsConstants = {
  ActionTypes: keyMirror({
    GET_REPORT_DATA: null,
    GET_OPTIONS_DATA: null,
    SET_TEMPLATE_TYPE: null,
    SET_REPORT_TYPE: null,
    SET_FILTER_TYPE: null,
    SET_SORT_TYPE: null,
    SET_FUNDAMENTALS_PERIODICITY: null,
    GET_ALL_REPORT_DATA: null,
    GET_COMPANY_INFO: null,
    SET_REPORTS_RIGHTSIDE_CLICK: null,
    GET_SNAPSHOT_DATA: null,
    GET_FUNDAMENTAL_HEADER_DATA: null,
    GET_IDEALIST_TITLE_DATA: null,
    GET_IDEALIST_PRICE_DATA: null,
    GET_IDEALIST_GRAPH_DATA: null,
    NONE: null,
    GICSSELECTED_FUNDAMENTALS: null,
    SET_METRIC_ACTION_ALRET_CLOSE_FUNDAMENTALS: null
  })
};