import Carousel from "./CarouselPanel.jsx";
import { connect } from 'react-redux';
import TabularData from "./TabularData.jsx";
import React, { Component } from "react";
import { updatePaginatedTablerendered } from "../../../../../Actions/FinancialBlockActions.js"

class PaginatedTableView extends Component {
  constructor(props) {
    super(props);
    this._tabulerData = new Map();
  }

  onHover(index, hover) {
    const currentPage = this.props.CurrentPage + 1;
    const tabularData = this._tabulerData.get(currentPage);
    if (tabularData) {
      tabularData.onHover(index, hover);
    }
  }

  onToggleHeader(index) {
    const currentPage = this.props.CurrentPage + 1;
    const tabularData = this._tabulerData.get(currentPage);
    if (tabularData) {
      tabularData.onToggleHeader(index);
    }
  }

  render() {
    this.props.updatePaginatedTablerendered()
    return (
      <Carousel interval={50} indicators={false} nextIcon={null} prevIcon={null}
        activeIndex={this.props.CurrentPage} isReset={this.props.isReset}>
        {this.props.PagintedSource.map((page) =>
          <Carousel.Item key={page.PageNum}>
            <div className="paginate-table financial-block fin-block-table-divs-class" style={{ height: this.props.Height }}>
              <TabularData syncHover={(index, hover) => this.props.syncHover(index, hover)}
                ref={(c) => this._tabulerData.set(page.PageNum, c)}
                rowHeight={this.props.RowHeight}
                rows={page.CurrentRows}
                nodeWidth={this.props.nodeWidth}
              />
            </div>
          </Carousel.Item>
        )}
      </Carousel>
    );
  }
}

const mapStateToProps = (state) => {
  const { PagintedSource, RowHeight, Height, nodeWidth, CurrentPage, isReset } = state.DatagraphReducers.financialBlockReducer;
  return { PagintedSource, RowHeight, Height, nodeWidth, CurrentPage, isReset }
}

const mapDispatchToProps = (dispatch) => ({	
  updatePaginatedTablerendered: () => dispatch(updatePaginatedTablerendered()),	
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaginatedTableView)