import React from "react";
import { updateStorewithSharedData } from "../../../Actions/ShareAction";
import LocalizationStore from 'LocalizationStore';
import PortalContainer from "PortalContainer";
import BaseServiceApi from 'BaseServiceApi';

class ExternalData extends React.Component{
    constructor(props){
        super(props);
        this.showSharedData = this.showSharedData.bind(this);
        this.handleMouseIn = this.handleMouseIn.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);

        this.state = {
            showToolTip: false,
            mouseX:0,
            mouseY:0,
            listItems: []
        };
    }
    componentDidMount(){
        const {data} = this.props;
        const listItems = [];
        const eventSeries = data.filter(itm => itm.nodeTypeId == BaseServiceApi.rayData["ListType"].EVENTSERIES_List);
        const timeSeries = data.filter(itm => itm.nodeTypeId == BaseServiceApi.rayData["ListType"].TIMESERIES_List);
        if(eventSeries.length > 0){
            listItems.push({
                listType: BaseServiceApi.rayData["ListType"].EVENTSERIES_List,
                name: "Events",
                childNodes: eventSeries,
                expandNode: true
            })
        }
        if(timeSeries.length > 0) {
            listItems.push({
                listType: BaseServiceApi.rayData["ListType"].TIMESERIES_List,
                name: "Time Series",
                childNodes: timeSeries,
                expandNode: true
            });
        }
        this.setState({listItems: listItems});
    }

     getOffset(evt) {
        let el = evt.target,
            x = 0,
            y = 0;
      
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
          x += el.offsetLeft - el.scrollLeft;
          y += el.offsetTop - el.scrollTop;
          el = el.offsetParent;
        }
      
        x = evt.clientX - x;
        y = evt.clientY - y;
      
        return { x: x, y: y };
      }

    handleMouseIn(entry) {       
        let mousedivL= this.getOffset(entry);
        let mouseXdiv = mousedivL.x ;
        let mouseYdiv = mousedivL.y ;
        let mouseXPos = (entry.clientX - mouseXdiv)+"px";
        let mouseYPos = (entry.clientY - mouseYdiv)+"px";
        
        const toolText = entry.currentTarget.innerText;
        if (toolText.length > 40) {
            this.setState({ showToolTip: true,mouseX : mouseXPos, mouseY : mouseYPos });
        }
    }

    handleMouseOut(entry) {           
        this.setState({ showToolTip: false,mouseX : 0,mouseY : 0 });
    }

    showSharedData(data){
        const sharedData = {
            showSharedDialog: true,
            id: data.nodeId,
            name: data.name,
            action: this.props.sharedAction,
            IncludeAllUsers: 0,
            ntid: BaseServiceApi.rayData["ListType"].TIMESERIES_List
        } 
        updateStorewithSharedData(sharedData);
    }
    toggleFolder(folderId,status){
        this.setState({ 
            ...this.state,
            listItems: this.state.listItems.map(
            (content) => content.listType === folderId ? {...content, expandNode: status}
                                  : content )
        })
    }
    render(){
        const listItems = this.state.listItems;
        
        return (
            <>
            {
                listItems.map((itms) => 
                <>
                    <tr className="share-head" onClick={() => this.toggleFolder(itms.listType,!itms.expandNode)} 
                    key={itms.listType}>
                        <td colSpan={2}>
                            <i className={itms.expandNode ? 'fa icn-folder-view-open' : 'fa icn-folder-view'}></i>
                            <span className="folder-type">{itms.name}</span>
                        </td>
                    </tr>
                    {itms.expandNode && itms.childNodes.map((rowData)=>
                        <tr className="share-data" key={rowData.nodeId.low} onClick={() => this.showSharedData(rowData)}>
                                    <td onMouseOver={this.handleMouseIn} onMouseOut={this.handleMouseOut}>
                                            <span className="value-name">{rowData.name}</span>
                                    </td>
                                    {
                                        this.state.showToolTip &&
                                        <PortalContainer><span style={{ left: this.state.mouseX, top: this.state.mouseY }} className="manage-title-tooltip">{rowData.name}</span></PortalContainer>
                                    }
                                    <td>
                                        <span className="value-count">
                                            {
                                                rowData.isSharedWithICS
                                                    ?
                                                    rowData.numPeople > 1
                                                        ? `${rowData.numPeople} recipients + O'Neil`
                                                        : `${rowData.numPeople} recipient + O'Neil`
                                                    :
                                                    rowData.numPeople > 1
                                                        ? `${rowData.numPeople} ${LocalizationStore.getTranslatedData("LM_TP_recipients", "recipients")}`
                                                        : `${rowData.numPeople} ${LocalizationStore.getTranslatedData("LM_TP_recipient", "recipient")}`
                                            }
                                        </span>
                                    </td>
                                </tr>
                        )
                    }
                {/* </div> */}
            </>
                )
            }
            </>
        )
    }
}
export default ExternalData;