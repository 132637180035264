import keyMirror from "keymirror";

export const OwnerShipBlockConstants = {
    ActionTypes: keyMirror({
        OWNERSHIP_BLOCK_GET_API_DATA: null,
        OWNERSHIPBLOCK_SET_DATA: null,
        OWNERSHIP_BLOCK_AUM_TOGGLE: null,
        OWNERSHIP_BLOCK_SET_AUM_TOGGLE: null,
        OWNERSHIP_BLOCK_FUND_TOGGLE: null,
        OWNERSHIP_BLOCK_SET_FUND_TOGGLE: null,
        OWNERSHIP_BLOCK_VIEW_TOGGLE: null,
        OWNERSHIP_BLOCK_SIZE_CHANGED: null,
        OWNERSHIP_BLOCK_OPEN_COLLAPSE: null,
        OWNERSHIP_BLOCK_PREPARE_HEADERMENU: null,
        OWNERSHIP_BLOCK_PREPARE_UPDATE_MENU: null,
        OWNERSHIP_BLOCK_CLEAR_DATA: null,
        OWNERSHIP_BLOCK_LIST_CHANGED: null,
        OWNERSHIP_BLOCK_RESPONSE_READY: null,
        OWNERSHIP_BLOCK_UPDATE_LIST_DATA: null,
        OWNERSHIP_BLOCK_NOT_AVAILABLE: null,
        OWNERSHIP_BLOCK_DOG_EAR_TOGGLE: null
    })

}