import { AnnotationConstant } from "Constants/AnnotationConstants";
import AppColorStore from "Stores/AppColor/AppColorStore";
import { connect } from 'react-redux';
import { MeasurementControl } from '../Presentational/index';
import {
    handleDragStart, handleDragMove, handleDragEnd, handleAnchorDragStart, handleAnchorDragEnd, handleAnchorDragMove,
    handleDeleteAnnotationDrawing, handleToggleContextMenu
} from '../../../../../../Actions/AnnotationActions';
import React, { Component } from 'react';
const { ActionTypes } = AnnotationConstant;

class GraphicsMeasurement extends Component {

    componentDidMount() {
        AppColorStore.addChangeListener(this.AppColorStoreStateChanged);
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.AppColorStoreStateChanged);
    }

    AppColorStoreStateChanged = () => {

        this.forceUpdate();
    }

    render() {
        const { line, index, lockImage, isApiReqResolved } = this.props;
        const { isDragging, activeAnchor, selectedAnnotId } = this.props.annotationChartModelReducer;

        return (

            <MeasurementControl
                key={index}
                index={index}
                line={line}

                isDragging={isDragging}
                activeAnchor={activeAnchor}
                selectedAnnotId={selectedAnnotId}
                handleDragStart={this.props.handleDragStart}
                handleDragMove={this.props.handleDragMove}
                handleDragEnd={this.props.handleDragEnd}
                handleAnchorDragStart={this.props.handleAnchorDragStart}
                handleAnchorDragEnd={this.props.handleAnchorDragEnd}
                handleAnchorDragMove={this.props.handleAnchorDragMove}
                onContextMenu={this.props.onContextMenu}
                draggable={this.props.draggable}
                lockImage={lockImage}
                isApiReqResolved={isApiReqResolved}
                handleDeleteAnnotationDrawing={this.props.handleDeleteAnnotationDrawing}
            />
        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { measurementReducer, annotationChartModelReducer } = annotationReducers;
    return ({ measurementReducer, annotationChartModelReducer });
}

const mapDispatchToProps = (dispatch) => ({
    handleDragStart: (line) => dispatch(handleDragStart(line)),
    handleDragMove: (e) => dispatch(handleDragMove(e)),
    handleDragEnd: () => handleDragEnd(),
    handleAnchorDragStart: (anchor) => dispatch(handleAnchorDragStart(anchor)),
    handleAnchorDragEnd: () => handleAnchorDragEnd(),
    handleAnchorDragMove: (e) => dispatch(handleAnchorDragMove(e, ActionTypes.HANDLE_MEASUREMENT_DRAW)),
    handleDeleteAnnotationDrawing: (charCode) => { dispatch(handleDeleteAnnotationDrawing(charCode)), dispatch(handleToggleContextMenu(false, 0, 0, '')) }
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphicsMeasurement);