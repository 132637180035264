import React from "react";
import { Modal,FormControl } from "react-bootstrap";
import { connect } from 'react-redux';
import { loadExternalOnAccept,loadStartWizard,closeUploadWizard,loadStartWizardDate,loadStartWizardDateFormat, updateToSettings } from '../../Actions/ExternalDataUploaderActions';

class ExternalDataUploadWizard extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    loadList = () => {
        this.props.loadListAfterClose(this.props.fileUploadResponse.listId.low);

        // Updating the list type to user settings which will be reflected in the NavSymbol
        this.props.updateToSettings(this.props.fileUploadResponse.listId.low, false, false, true, '', this.props.fileUploadResponse.dataSetType);
    }
    startWizard = () => {
        this.props.loadStartWizardAfterClose(this.props.fileUploadResponse.listId.low);

        // Updating the list type to user settings which will be reflected in the NavSymbol
        this.props.updateToSettings(this.props.fileUploadResponse.listId.low, false, false, true, '', this.props.fileUploadResponse.dataSetType);
    }
        
    render() {
        let {fileUploadResponse,wizardDialogShow} = this.props;
        let {symbolName,dateColumnName,dateFormat} = '';
        let uploadSuccess = false;
        let uploadFail = false;
        let isPermissionError = false;
        if(fileUploadResponse && fileUploadResponse.success){
            const symboldetails = fileUploadResponse.headers.filter((item) => item.isSymbolColumn);
            const dateColumn = fileUploadResponse.headers.filter((item) => item.isDateColumn);
            symbolName = symboldetails[0].displayName;
            dateColumnName = dateColumn[0].displayName;
            dateFormat = dateColumn[0].dateFormat;
            uploadSuccess = true;
        }else{
            uploadFail = true;
            isPermissionError = /PAN\:40104/.test(fileUploadResponse.error);
            if(isPermissionError){
                fileUploadResponse.error = "There is no edit access now, You can't modify the data Set"
            }
        }
            return (
            <div id="ExternalDataUploadWizard">
                <Modal className="modal-message upload-diversion" show={wizardDialogShow}>
                    {/* <Modal.Header>
                        <Modal.Title>
                            <span className="cap-header">UPLOAD {uploadSuccess ? "SUCCESS": "FAILED"}</span>
                        </Modal.Title>
                    </Modal.Header> */}

                    <Modal.Body>
                        <div className="inner-block modal-region upload-setting-wrap">
                            <div className="success-failed">
                            {uploadSuccess && <div className="success-failed-box">
                                    <h3>That worked your data is in PANARAY</h3>
                                    <h4>We found {fileUploadResponse.totalLineCountInFile ? fileUploadResponse.totalLineCountInFile : 0} records and {fileUploadResponse.distinctSymbolsCount ? fileUploadResponse.distinctSymbolsCount : 0} unique symbols</h4>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-xs-9">
                                                <p>You can either confirm the following settings and be done:</p>
                                                <ul className="edu-diversion-list">
                                                    <li>Unique Security Identifier - <span className="links" onClick={() => this.props.startWizarSymbol(this.props.fileUploadResponse.listId.low)}>{symbolName}</span></li>
                                                    <li>Date Column - <span className="links" onClick={() => this.props.startWizarDate(this.props.fileUploadResponse.listId.low)}>{dateColumnName}</span></li>
                                                    <li>Date Format - <span className="links" onClick={() => this.props.startWizarDateFormat(this.props.fileUploadResponse.listId.low)}>{dateFormat}</span></li>
                                                </ul>
                                            </div>
                                            <div className="col-xs-3">
                                                <button type="button" className="btn btn-xs btn-default" data-effect="fadeOut" onClick={this.loadList}>ACCEPT</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-9">
                                                <p>or you can use a wizard to look at each setting individually:</p>
                                            </div>
                                            <div className="col-xs-3">
                                                <button type="button" className="btn btn-xs btn-default" data-effect="fadeOut" onClick={this.startWizard}>START WIZARD</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {uploadFail && <div className="success-failed-box">
                                    {/* <p>Your data did not meet the following requirements:</p>
                                    <p><span className="blue-text">{fileUploadResponse && fileUploadResponse.error}</span></p>
                                    <p>Please check your file and try again.</p> */}

                                    {!isPermissionError && <h3>Your data did not meet the following requirements:</h3>}
                                    <ul>
                                        <li>
                                            {fileUploadResponse && 
                                                <div dangerouslySetInnerHTML={{ __html: fileUploadResponse.error }}/>
                                            }
                                        </li>
                                    </ul>
                                    {!isPermissionError && <p>Please check your file and try again.</p>}
                                </div>
                            }
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        {uploadFail && <div className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default" data-effect="fadeOut" onClick={this.props.closeWizard}>CLOSE</button>
                        </div>}
                        {uploadSuccess && <p className="text-center">Your data is in panaray, encrypted and stored securely using SQL Server Encryption. <a className="links" href="https://docs.microsoft.com/en-us/sql/relational-databases/security/encryption/sql-server-encryption?view=sql-server-ver15" target="_blank">Learn More</a></p>}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    loadListAfterClose : (listId) => dispatch(loadExternalOnAccept(listId)),
    loadStartWizardAfterClose : (listId) => dispatch(loadStartWizard(listId,false)),
    closeWizard: () => dispatch(closeUploadWizard()),
    startWizarSymbol: (listId) => dispatch(loadStartWizard(listId,false)),
    startWizarDate: (listId) => dispatch(loadStartWizardDate(listId,false)),
    startWizarDateFormat: (listId) => dispatch(loadStartWizardDateFormat(listId,false)),
    updateToSettings: (listId, isRename, isDelete, isUpload, name, listType) => dispatch(updateToSettings(listId, isRename, isDelete, isUpload, name, listType))
})
const mapStateToProps = ({ externalDataUploader }) => {
    const { fileUploadResponse,wizardDialogShow } = externalDataUploader.ExternalDataUploaderReducer;
    return ({ fileUploadResponse,wizardDialogShow });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataUploadWizard);