import React from 'react';
import { Modal, Table } from "react-bootstrap";
import { map } from "underscore";
import { connect } from 'react-redux';
import { finishAndSaveHeaders,setNewDateFormat } from "../../Actions/ExternalDataUploaderActions";
class ExternalDateSelectWizard extends React.Component {
    renderDtFormatSelect = (item, index, dateFormat) => {
        let spanClass = 'icn-right-icon date-checker';
        if(dateFormat == item){
            spanClass="icn-right-icon selective"
        }
        return (
            <li key={index}>
                <span className='date-text' onClick={() => this.props.setSelectedDateFormat(item)}>
                    <i className={spanClass}></i>
                    {item}
                </span>
            </li>
          );
    }
    renderSeparatorSelect = (item, index) => {
        return (
            <li key={index}>
                <span className='math-sign'>
                    {index}
                </span>
                {item}
            </li>
          );
    }
    render() {
        const { showDateSelect,dateFormats,dateSeparator,headers } = this.props;

        let dateheader = headers.find((item) => item.isDateColumn == true); 
        let dateFormat = dateheader.dateFormat && dateheader.dateFormat.replace(/[^a-zA-Z0-9]/g,' ');
        let format = dateFormat.replace(/([M])\1{2,}/g,'MM')
        format = format.replace(/[E]/g,'');
        format = format.match(/(\w)\1{1,}/ig);
        format = format.join(' ');
        format = format.replace(/\s{2,}/g,' ');
        format = format.trim();
        dateFormat = format.toUpperCase();
        return (
            <div id="ExternalWizardEditHeaders">
            <Modal className="modal-message modal-confirm-date-format" show={showDateSelect}>
                            <Modal.Body>
                                <div className="confirm-date-format">
                                    <h6>
                                        <span className="heads">Confirm Date Format</span>
                                        <button type="button" className="model-action btn btn-xs btn-default btn-secondary" onClick={() => this.props.finishSelection()}>Finish</button>
                                    </h6>
                                    <div className="date-confirmation">
                                        <div className="date-identified">
                                            <div className="date-identifier">
                                                <ul>
                                                    {dateFormats && map(dateFormats, (item, key) => this.renderDtFormatSelect(item, key, dateFormat))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="date-recognized">
                                            <div className="date-recognizer">
                                                <p>The following separators are recognized:</p>
                                                <ul>
                                                    {dateSeparator && map(dateSeparator, (item, key) => this.renderSeparatorSelect(item, key))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </Modal.Body>
                            <Modal.Footer>
                            </Modal.Footer>
                        </Modal>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    finishSelection: () => dispatch(finishAndSaveHeaders()),
    setSelectedDateFormat: (format) => dispatch(setNewDateFormat(format))
    
})
const mapStateToProps = ({ externalDataUploader }) => {
    const { source, showDateSelect,dateFormats,dateSeparator,dateColChanged } = externalDataUploader.ExternalDataListViewReducer;
    const { headers } = source;
    return ({ headers,showDateSelect,dateFormats,dateSeparator,dateColChanged });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExternalDateSelectWizard);