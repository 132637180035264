import keyMirror from "keymirror";

export const PerformanceWindowConstants = {
  ActionTypes: keyMirror({
    DATAGRAPH_DATA_LOAD_START: null,
    DATAGRAPH_DATA_LOAD_END: null,
    PRICE_PANEL_DATA_LOAD_END: null,
    SUMMARY_BLOCK_DATA_LOAD_END: null,
    FINANCIALOWNERSHIP_BLOCK_DATA_LOAD_END: null,
    ON_PERFORMANCE_DATA_CLEAR: null,
    DATAGRAPH_DATA_LOAD_FAILED: null,
    TI_PANEL_DATA_LOAD_END: null,
    TRACK_DATA_UPDATE: null
  })
};
