import React from "react";
import { connect } from 'react-redux';
import TreeParent from './TreeParent';
import { setActiveNodeToOwnershipStore } from '../../Actions/OwnerShipActions';
import {
    sourceList, setActiveNode, setActiveNodeChanged,
    setRenameNode, deleteSelectedItem, setDeleteFolder, updateOwnership
} from '../../Actions/ListViewActions';
import { expandAndFindParentNode, getParentId, sortDataSource } from "../../Utils/OwnerShipUtil.js";
import SettingsStore from "SettingsStore";
import ListStore from "ListStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListExplorerUtil from "ListExplorerUtil";
import { clone } from 'underscore';
import { Shortcuts } from 'react-shortcuts';
import BrowserUtil from "BrowserUtil";
import StringUtil from "StringUtil";
import BaseServiceApi from 'BaseServiceApi';
const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const ListType = BaseServiceApi.rayData["ListType"];
// let ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

class Owner extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ListView: this.props.ListView,
            data: this.props.dataSource
        }
        this.getNavListManagerSettings();
        this.handleShortcuts = this.handleShortcuts.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.dispatch(sourceList(this.props.dataSource));
        //this.props.dispatch(setActiveNodeChanged(true));       
    }

    componentDidMount() { 
    const { isUpdatedOwnership } = this.state.ListView;
        if(isUpdatedOwnership){    
            const selectedListItem = ListStore.getSelectedListItem();
            if(selectedListItem){
            ListExplorerUtil.getScrollPosition(selectedListItem.SelectedListId);   
            }  
        }
    }

    componentDidUpdate() {        
        const gridRefresh = OwnershipViewStore.getRefreshData();
        const { dataSource, activeNode, activeNodeChanged,isUpdatedOwnership } = this.state.ListView;        
        const selectedListItem = ListStore.getSelectedListItem();         
        const lstData = gridRefresh ? this.props.dataSource : dataSource.list;        
        if(isUpdatedOwnership){
            if(!StringUtil.isEmpty(selectedListItem)){
             if(selectedListItem.SelectedListId !="" ){             
              ListExplorerUtil.getScrollPosition(selectedListItem.SelectedListId);   
             }    
            }      
            let listExplorereScrollBar = document.getElementById("ownershipListScroll");
            if(listExplorereScrollBar !=null && listExplorereScrollBar.scrollTop){
             if(listExplorereScrollBar.scrollTop !=0)
             this.props.dispatch(updateOwnership(false));  
            }
         } 
        if (activeNodeChanged) {            
            this.props.ownershipTableData(getParentId(activeNode, lstData));
            this.props.dispatch(setActiveNodeChanged(false));
        }
        if (gridRefresh) {
            const newDataSource = clone(this.props.dataSource);
            expandAndFindParentNode(activeNode, sortDataSource(newDataSource))
            this.props.dispatch(sourceList(newDataSource));
            OwnershipViewStore.setRefreshData(false);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.ListView !== nextProps.ListView) {
            this.setState({ ListView: nextProps.ListView })
        }
    }

    getNavListManagerSettings() {        
        const ownershipSettings = SettingsStore.getConsoleSettings().NavListManagerSettings;        
        const activeNodeId = ownershipSettings.TabOwnershipSettings.SelectedListInfo.SelectedListId;        
        const newActiveNode = getParentId(activeNodeId, this.props.dataSource)
        setActiveNodeToOwnershipStore(newActiveNode);
        this.props.dispatch(setActiveNode(activeNodeId));
        expandAndFindParentNode(activeNodeId, sortDataSource(this.props.dataSource))
    }

    async handleShortcuts(key, event) {
        const activeNodeId = this.state.ListView.activeNode;
        const folderNodeId = this.state.ListView.activeFolderID;        
        const nodeToRename = this.state.ListView.nodeToRename;
        const duplicateNode = this.state.ListView.duplicateNode;
        if (BrowserUtil.getBrowserName() === 'Safari' && event.code === 'KeyV') return;
        const nodeDetails = getParentId(activeNodeId, this.props.dataSource);
        const folderNodeDetails = getParentId(folderNodeId, this.props.dataSource);
        if (!StringUtil.isEmpty(nodeDetails.nodeId.low)) {
            {
                if (nodeDetails &&
                    (nodeDetails.categoryType === ListCategoryType.USER_ListCategory ||
                        nodeDetails.categoryType === ListCategoryType.FAVORITE_ListCategory ||
                        nodeDetails.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory)
                    ||
                    folderNodeDetails &&
                    (folderNodeDetails.categoryType === ListCategoryType.USER_ListCategory
                        || folderNodeDetails.categoryType === ListCategoryType.FAVORITE_ListCategory)
                ) {
                    switch (key) {
                        case 'DELETE_ROW':
                            if(StringUtil.isEmpty(nodeToRename) && StringUtil.isEmpty(duplicateNode) && OwnershipViewStore.isListLoaded){
                                if (folderNodeId && folderNodeDetails && (folderNodeDetails.listType === ListType.FOLDER_List)) {
                                    this.props.dispatch(setDeleteFolder({ isShowPopup: true, nodeIDtoDelete: folderNodeId }))
                                }
                                else {
                                    this.props.dispatch(deleteSelectedItem());
                                }
                                OwnershipViewStore.isListLoaded = false;
                            }
                            break;
                        case 'RENAME':
                            {
                            const renameNodeId = folderNodeId ? folderNodeId : nodeDetails.nodeId.low;
                            if ((nodeDetails.categoryType !== ListCategoryType.FAVORITE_ListCategory)) {
                                this.props.dispatch(setRenameNode(renameNodeId));
                            }
                            break;
                        }
                        case 'PASTE':

                            break;
                        default:
                            return;
                    }
                }
            }
        }
    }
    // const handleShortcuts = (key, event) => {
    //     if (BrowserUtil.getBrowserName() == 'Safari' && event.code == 'KeyV') return;
    //     const nodeDetails = getParentId(activeNode, list);
    //     const folderNodeDetails = getParentId(activeFolderID, list);
    //     if (!StringUtil.isEmpty(rowslist.nodeId.low)) {
    //         {
    //             if (nodeDetails && nodeDetails.categoryType === ListCategoryType.USER_ListCategory ||
    //                 nodeDetails.categoryType === ListCategoryType.FAVORITE_ListCategory
    //                 || folderNodeDetails && folderNodeDetails.categoryType == ListCategoryType.USER_ListCategory
    //                 || folderNodeDetails.categoryType == ListCategoryType.FAVORITE_ListCategory) {
    //                 switch (key) {
    //                     case 'DELETE_ROW':
    //                         if (activeFolderID && folderNodeDetails && (folderNodeDetails.listType == ListType.FOLDER_List)) {
    //                             dispatch(setDeleteFolder({ isShowPopup: true, nodeIDtoDelete: activeFolderID }))
    //                         }
    //                         else {
    //                             dispatch(deleteSelectedItem());
    //                         }
    //                         break;
    //                     case 'RENAME':
    //                         const renameNodeId = activeFolderID ? activeFolderID : nodeDetails.nodeId.low;
    //                         if ((nodeDetails.categoryType !== ListCategoryType.FAVORITE_ListCategory)) {
    //                             dispatch(setRenameNode(renameNodeId));
    //                         }
    //                         break;
    //                     case 'PASTE':

    //                         break;
    //                     default:
    //                         return;
    //                 }
    //             }
    //         }
    //     }
    // }
    // onDeleteKeyPress(e) {
    //     if (e.key == 'Delete') {
    //         this.props.dispatch(trashIconDelete());
    //     }
    //     else if (e.key == 'F2') {
    //         let parentNodeDetails = getParentId(this.state.ListView.activeNode, this.props.dataSource);
    //         if (parentNodeDetails.listCategory == ListManagerListCategory.USER_ListCategory) {
    //             (this.state.ListView.activeFolderID !== '') ?
    //                 this.props.dispatch(setRenameNode(this.state.ListView.activeFolderID)) :
    //                 this.props.dispatch(setRenameNode(this.state.ListView.activeNode));
    //         }
    //     }
    // }

    render() {
        const {
            dataSource,
            nodeToRename,
            activeNode,
            selectedNode,
            duplicateNode,
            activeFolderID,
            dragNodeID,
            // activeNodeChanged,
            isFolderDelete
        } = this.state.ListView;

        // const isRefresh = OwnershipViewStore.getRefreshData();
        // if (isRefresh) {
        //     const newDataSource = clone(this.props.dataSource);
        //     expandAndFindParentNode(activeNode, sortDataSource(newDataSource))
        //     this.props.dispatch(sourceList(newDataSource));
        //     OwnershipViewStore.setRefreshData(false);
        // }
        const listData = OwnershipViewStore.getRefreshData() ? this.props.dataSource : dataSource.list;
        return (
            <Shortcuts
                name='NAV_LIST_MANAGER'
                handler={this.handleShortcuts}
                targetNodeSelector=".listOwner" global={true} isolate={true}>
                <div className="listOwner" >
                    {
                        dataSource && dataSource.list &&
                        <TreeParent
                            list={listData}
                            nodeToRename={nodeToRename}
                            activeNode={activeNode}
                            selectedNode={selectedNode}
                            duplicateNode={duplicateNode}
                            activeFolderID={activeFolderID}
                            dragNodeID={dragNodeID}
                            // ownershipTableData={this.props.ownershipTableData}
                            // activeNodeChanged={activeNodeChanged}
                            handleClick={this.props.handleClick}
                            ownerShipData={this.props.ownerShipData}
                            isFolderDelete={isFolderDelete}
                            dispatch={this.props.dispatch}
                            fontSizeClass={this.props.fontSizeClass}
                            showValidationWindow={this.props.showValidationWindow}
                            updateScrollBar={this.props.updateScrollBar}
                        />
                    }
                </div >
            </Shortcuts>
        )
    }
}
function mapStateToProps(state) {
    return {
        ListView: state.listView
    }
}

export default connect(mapStateToProps)(Owner);