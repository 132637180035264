import ColorPicker from '../../../../../../../RayCustomControls/ColorPicker/ColorPicker';
import { connect } from 'react-redux';
import { DialogHOC } from './DialogHOC';
import { cancelFundamentalLineDialog, changeFundamentalLineColor, changeFundamentalLineDialogTab, changeFundamentalLineEarning, changeFundamentalLineEstimate, changeFundamentalLineWeight, restoreFundamentalLineSetting, saveFundamentalLineDialog } from '../../../../../../../Actions/PricePanelActions';
import {  FundamentalLineHeaderTranslateHelper, FundamentalLineTranslateHelper, TranslateHelper } from '../../../../../../../Utils/TranslateHelper';
import { Modal, Tabs, Tab } from "react-bootstrap";
import React, { Component } from "react";

const colorPickerWidth = 100;
const thickness = [
    { weight: "thin-line", value: 1 },
    { weight: "normal-line", value: 2 },
    { weight: "weight-line", value: 3 },
    { weight: "bold-line", value: 4 },
    { weight: "heavy-line", value: 5 },
];

class FundamentalLineSettingDialog extends Component {
    getTabItemRender(tabitem) {
        //let showHighLowEstimate = tabitem.showHighLowEstimate && (this.state.symType === SymTypeEnum.USSTOCK || this.state.symType === SymTypeEnum.ADR);

        return (
            <Tab className="tabItem" key={tabitem.periodicity} eventKey={tabitem.periodicity} title={tabitem.header}>
                <div className="fm-data-line">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12">
                                <h3>{TranslateHelper.Choose_Line_Display_Setting}</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6">
                                <div className="appearance-color">
                                    <h5>{TranslateHelper.Color}</h5>
                                    <div className="appearance-color-hold">
                                        <ColorPicker width={colorPickerWidth} color={tabitem.lineColor} onChangeComplete={this.props.changeFundamentalLineColor} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="pull-right">
                                    <div className="appearance-weight">
                                        <h5>{TranslateHelper.Weight}</h5>
                                        <div className="appearance-weight-hold lining-box">
                                            {thickness.map((item, index) =>  <a data-disable-track-price="true" key={index}
                                                                            className={item.value === tabitem.lineThickness ? `${item.weight} active` : item.weight}                                                    
                                                                            onClick={() => this.props.changeFundamentalLineWeight(item.value)}>
                                                                            <div>
                                                                                <hr />
                                                                            </div>
                                                                        </a>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 flex-column">
                                {tabitem.isShowHighLowEstimateAvailable && 
                                    <span className="fm-data-checks">
                                        <input className="custom-check" type="checkbox" name="high_low_estimate" id="high_low_estimate" value={tabitem.showHighLowEstimate} checked={tabitem.showHighLowEstimate} onChange={this.props.changeFundamentalLineEstimate} />
                                        <label className="custom-check-label" htmlFor="high_low_estimate">{FundamentalLineTranslateHelper.HighLowEstimate}</label>
                                    </span>
                                }
                                {tabitem.showEarningSurprise  !== null && tabitem.showEarningSurprise !== undefined && 
                                    <span className="fm-data-checks">
                                        <input className="custom-check" type="checkbox" name="earnings" id="earnings" value={tabitem.showEarningSurprise} checked={tabitem.showEarningSurprise} onChange={this.props.changeFundamentalLineEarning} />
                                        <label className="custom-check-label" htmlFor="earnings">{FundamentalLineTranslateHelper.EarningSurprises}</label>
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12">
                                <span className="rating-default appearance-restore">
                                    <a className="link-restore" onClick={this.props.restoreFundamentalLineSetting}>{TranslateHelper.Restore_Defaults}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab>
        );
    }
    render() {
        return (
            <Modal.Body>
                <div className="oneil-rating-box">
                    <Tabs onSelect={this.props.changeFundamentalLineDialogTab} activeKey={ this.props.selectedPeriodicity } id="controlled-tab-example"> {this.props.fundamentalLinesSettingData.map((tabitem) => this.getTabItemRender(tabitem))}
                    </Tabs>
                </div>
            </Modal.Body>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers }) =>{
    const { FundamentalLineReducer } = DatagraphReducers
    const state = {}
    state.fundamentalLinesSettingData = FundamentalLineReducer.fundamentalLinesSettingData;
    state.showSettingDialog = FundamentalLineReducer.showSettingDialog;
    state.selectedPeriodicity = FundamentalLineReducer.selectedPeriodicity;
    state.HeaderText = FundamentalLineHeaderTranslateHelper[FundamentalLineReducer.selectedFundamentalLine]
    state.headerClass = "ti-indicators fundamental-data-lines"
    return state;
}
const mapDispatchToProps = (dispatch) =>({
    saveLineDialog: ()=> dispatch(saveFundamentalLineDialog()),
    cancelLineDialog: ()=> dispatch(cancelFundamentalLineDialog()),
    changeFundamentalLineDialogTab: (selectedPeriodicity)=> dispatch(changeFundamentalLineDialogTab(selectedPeriodicity)),
    changeFundamentalLineEstimate:()=> dispatch(changeFundamentalLineEstimate()),
    changeFundamentalLineEarning: ()=> dispatch(changeFundamentalLineEarning()),
    restoreFundamentalLineSetting: ()=> dispatch(restoreFundamentalLineSetting()),
    changeFundamentalLineWeight: (lineThickness)=> dispatch(changeFundamentalLineWeight(lineThickness)),
    changeFundamentalLineColor: (lineColor)=> dispatch(changeFundamentalLineColor(lineColor)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(FundamentalLineSettingDialog))