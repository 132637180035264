import AnnotationPreview from "./AnnotationPreview.jsx";
import AnnotationsCheckboxSelectors from "./AnnotationsCheckboxSelectors.jsx";
import AnnotationsColorPicker from "./AnnotationsColorPicker.jsx";
import BaseServiceApi from 'BaseServiceApi';
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import ScrollBar from "ScrollBar";
import ThemeHelper from "../../../../../../Utils/Theme/ThemeHelper";
import { handleColorChange, onLleaderLineChange } from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent } from "react";

const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
class RIPanelMeasurementInfo extends PureComponent {

  componentDidMount() {
    document.body.classList.add('riPanel');
    document.addEventListener("keydown", this.onDocumentKeyPress, false);
  }

  componentWillUnmount() {
    document.body.classList.remove('riPanel');
    document.removeEventListener("keydown", this.onDocumentKeyPress, false);
  }
  onDocumentKeyPress(e) {
    if ((document.activeElement && document.activeElement.id === "SymbolEntryInput") || (document.activeElement && document.activeElement.id.includes("annot-dropdown"))) {
      return;
    }

    const pointerLayerScroll = document.getElementById("annotateIntrumentsMeasurement");

    if (e.keyCode === KeyCodes.UP_ARROW) {

      if (pointerLayerScroll) {
        window.setTimeout(() => { pointerLayerScroll.scrollTop -= 30; }, 0);
      }
    }
    else if (e.keyCode === KeyCodes.DOWN_ARROW) {

      if (pointerLayerScroll) {
        window.setTimeout(() => { pointerLayerScroll.scrollTop += 30; }, 0);
      }
    }

  }
  onLleaderLineChange = () => {
    this.props.onLleaderLineChange();
  }

  onColorChange = (color) => {
    const lineColor = ThemeHelper.getThemedBrush(color);
    this.props.handleColorChange(lineColor);
  }

  renderLeftLine = () => {
    if (this.props.annotationMenuReducer.isLeaderLinesChecked) {
      return (
        <line x1="50" y1="45" x2="50" y2="5" strokeDasharray="6,2" style={{ stroke: this.props.annotationMenuReducer.measurementColor, strokeWidth: "2px" }}></line>
      );
    }
  }

  renderRightLine = () => {
    if (this.props.annotationMenuReducer.isLeaderLinesChecked) {
      return (
        <line x1="220" y1="45" x2="220" y2="5" strokeDasharray="6,2" style={{ stroke: this.props.annotationMenuReducer.measurementColor, strokeWidth: "2px" }}></line>
      );
    }
  }

  hideDropDownMenu = () => {
    this.colorPicker && this.colorPicker.hideDropDownMenu();
  }

  render() {
    return (
      <div className="annotation-box annotate-measurement">
        <div className="annotation-hold custom-scroll" onScroll={this.hideDropDownMenu} onClickCapture={this.hideDropDownMenu}>
          <div className="annotation-overlape" style={{ display: ((!this.props.annotSelected && this.props.selectedLayer && this.props.selectedLayer.shareAccess === ShareAccessType.SHARE_READONLY) || (this.props.annotSelected && this.props.annotSelected.isReadOnlyAnnotation)) ? '' : 'none' }}></div>
          <div className="annotation-intruments" id="annotateIntrumentsMeasurement">

            <AnnotationPreview label={`MEASUREMENT SETTINGS`} />

            <div className="annotation-properties">
              <div className="swatches-dropdown">
                <div className="col-xs-12">
                  <AnnotationsColorPicker label={true} type="line" ref={(r) => { this.colorPicker = r; }} />
                </div>
              </div>
            </div>

            <div className="annotation-properties">
              <div className="title">
                <ul>
                  <AnnotationsCheckboxSelectors type="Leaderlines" />
                </ul>
              </div>
            </div>

          </div>
          <ScrollBar scrollId="annotateIntrumentsMeasurement" vScroll={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ annotationReducers }) => ({
  annotationMenuReducer: annotationReducers.annotationMenuReducer,
  annotSelected: annotationReducers.annotationChartModelReducer.annotSelected,
  selectedLayer: annotationReducers.annotationLayerManagementReducer.selectedLayer
})

const mapDispatchToProps = (dispatch) => ({
  handleColorChange: (color) => dispatch(handleColorChange(color)),
  onLleaderLineChange: () => dispatch(onLleaderLineChange()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelMeasurementInfo);