import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import ONeilViewStore from "ONeilViewStore";
import ListExplorerStore from "ListExplorerStore";
import OwnershipViewStore from "../NavList/TabOwnership/OwnershipViewStore";
import { SettingsConstants } from "../../../Constants/SettingsConstants.js";
import ListSettingHelper from "../../ConsoleWindow/Settings/Modules/ListManager/ListManagerSettingHelper.js"
import ListManagerTabType from "ListManagerTabType";
import { ListExplorerConstants } from "ListExplorerConstants";
import { OwnershipViewConstants } from "../../../Constants/OwnershipConstants";
import TabONeilSettings from 'Stores/ConsoleWindow/Settings/Modules/ListManager/TabONeilSettings.js';
import TabOwnershipSettings from 'Stores/ConsoleWindow/Settings/Modules/ListManager/TabOwnershipSettings.js';
import TabExternalSettings from 'Stores/ConsoleWindow/Settings/Modules/ListManager/TabExternalSettings.js';
import ListApi from "ListApi";
import ConsoleStore from "ConsoleStore";
import _,{ indexBy, each, find, extend, size, uniq, debounce } from "underscore";
import BaseServiceApi from 'BaseServiceApi';
import LocalizationStore from "LocalizationStore"
import StringUtil from "Utils/StringUtil";
import DatagraphStore from "../NavDataGraph/DataGraphStore";
import SettingsAction from "Actions/SettingsActions";
import TimeTrackingWindow from "../../../RayCustomControls/TimeTrackingWindow";
import NavType from "../../../Constants/NavType";
import { ShareDialogConstants } from "../../../Constants/ShareConstants";
import { onPlotSymbol } from "../../../Actions/DatagraphActions";
import { dispatch } from "../../../Redux/dispatch";

const CHANGE_EVENT = "change";

let _currentAction = null;
let _ONeilViewAction = null;
let _currentColumnSetActions = null;
let _pressedKey = null;
let _totalDetailMetrics = [];
let _metricData = { CategoryId: null, CategoryName: null, DetailCount: null, RootDescription: null, childNode: [] }
let ListType = BaseServiceApi.rayData["ListType"];
let ErrorType = BaseServiceApi.rayData["ErrorType"];
let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];

let _state = {
  TabCollection: [],
  themeChanged: false,
  SelectedTabKey: undefined,
  columnSet: {},
  columnSetProperties: {},
  nodeId_colSetProp: null,
  metricData: {},
  metricSearchData: {},
  metricLibraryVisibility: null,
  columnFilterSearchResults: [],
  columnSetResultError: false,
  columnSetId: null,
  columnSetName: null,
  columnFilterItemData: null,
  refreshGrid: false,
  listExplorerWidthForMetricLibrary: 300,
  isColumnAlertsDialog: false,
  isCustMetricOpen: false,
  isBrowseMetricsOpen: false,
  isCatlogLoad: false,
  currentSelectedDetailId: null,
  nextAvailabDetail: null,
  searchString: "",
  isRefresh: false
};
let _selectedSymbol = null;
let _isMetricApiCall = false;

class ListStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    //Bug fixes - Max Limit error 
    this.setMaxListeners(0);
    this.metricResponseData = null;
    this.metricOwnerShipData = null;
    this.shouldUpdateMetric = false;
    this.customColumnUnits = null;
    this.activeExternalNode = {};
    this.activeOwnershipNode = {};
    this.externalListName = '';
    this.getExternalDataSetList = {};
    this.externalDataSelectedNodes=[];
    this.lastDeletedNodes=[];
    this.lastDeletedNodesIds=[];


  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  setState() {
    _state = this.getNavListManagerSettings();
  }

  resetRefresh() {
    _state.refreshGrid = false;
  }

  getState() {
    return _state;
  }

  getselectedTabKey() {
    return _state.SelectedTabKey;
  }

  getCurrentAction() {
    return _currentAction;
  }

  getONeilViewAction() {
    return _ONeilViewAction;
  }

  setONeilViewAction(action) {
    return _ONeilViewAction = action;
  }

  getCurrentColumnSetActions() {
    return _currentColumnSetActions;
  }

  clearCurrentColumnSetActions() {
    _currentColumnSetActions = null;
  }


  getTabType(tabkey) {
    if (tabkey === null || tabkey === undefined) return null;
    let tabObject = _state.TabCollection.filter((tabItem) => {
      if (tabItem.Header === tabkey) return true;
      else return false;
    });
    return tabObject.length > 0 ? tabObject[0].TabType : null;
  }

  getNavListManagerSettings() {
    return SettingsStore.getConsoleSettings().NavListManagerSettings;
  }
  getBrowseMetricState() {
    return _state.isBrowseMetricsOpen;
  }
  getCustomMetricState() {
    return _state.isCustMetricOpen;
  }
  getRenameAndDeleteColumnState() {
    return _state.isCatlogLoad;
  }
  getCurrentSelectedDetailId() {
    return _state.currentSelectedDetailId;
  }
  getNextAvailabDetail() {
    return _state.nextAvailabDetail;
  }
  closeCustomMetric() {
    _state.isCustMetricOpen = false;
  }
  getSelectedTheme() {
    return SettingsStore.getConsoleSettings().currentTheme;
  }

  getTextSize() {
    let textSize = null;
    let NavListManagerSettings = this.getNavListManagerSettings();
    let tabType = this.getTabType(_state.SelectedTabKey);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        textSize = NavListManagerSettings.TabONeilSettings.TextSize;
        break;
      case ListManagerTabType.Ownership:
        textSize = NavListManagerSettings.TabOwnershipSettings.TextSize;
        break;
      case ListManagerTabType.External:
        textSize = NavListManagerSettings.TabExternalSettings.TextSize;
        break;
      default:
        break;
    }
    return textSize;
  }

  getTextFontSize() {
    let textSize = this.getTextSize();

    if (textSize == "Small") return 14;
    else if (textSize == "Medium") return 16;
    else if (textSize == "Large") return 20;
    else return 16;
  }

  /* getTextSizeForAutoSize() {
      let textSize = this.getTextFontSize();
      if (textSize >= 14)
          return 19;
      if (textSize >= 12)
          return 16;
      return 17.777778;
   } */

   async callGetMetricAPI(){
    _isMetricApiCall = true;
    let metricLibReq = [ListApi.getMetricLibrary("ONEIL"), ListApi.getMetricLibrary("OWNERSHIP")];
    const resArr = await Promise.all(metricLibReq);
    _isMetricApiCall = false;
    this.metricResponseData = resArr[0];
    this.metricOwnerShipData = resArr[1];
    this.shouldUpdateMetric = true;
    this.emit(CHANGE_EVENT);
    return resArr;
  }

  async getUpdatedMetricData(){
     await this.callGetMetricAPI();
     return this.getMetricData();
  }
  
  getMetricData(){
   return this.getMetricLibraryData(_state.SelectedTabKey == "ONEIL" ? this.metricResponseData : this.metricOwnerShipData); 
  }

    getMetricLibrary(callapi=false) {
    if (this.metricResponseData == null) {
      return this.callGetMetricAPI();
    }
      if(callapi) {
        return this.getUpdatedMetricData()
      }
     return this.getMetricData();
 }

  getCustomUnits() {
    if (this.customColumnUnits == null) {
      return ListApi.getCustColUnits().then((result) => {
        this.customColumnUnits = result;
        this.emit(CHANGE_EVENT);
      });
    }
    else {
      return this.customColumnUnits;
    }
  }
  getPeriodicityData() {
    return _state.metricData ? indexBy(_state.metricData.periodicityData, 'periodicityText') : [];
  }

  setMetricLibraryState(isVisible) {
    if (_state.metricLibraryVisibility != isVisible) {
      _state.metricLibraryVisibility = isVisible;
      // let NavListManagerSettings = this.getNavListManagerSettings();
      // ListSettingHelper.updateIsMetricLibraryOpen(NavListManagerSettings.TabONeilSettings, isVisible);
      // SettingsStore.saveSettings();
    }
  }

  getMetricLibraryState() {
    return _state.metricLibraryVisibility;
  }

  plotSymbol(sym) {
    const state = DatagraphStore.getState();
    state.Symbol = sym;
    dispatch(onPlotSymbol(sym));
    ConsoleStore.plotSymbol(sym);
  }

  getSelectedSymbol() {
    // let NavDatagraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings;
    // if (NavDatagraphSettings) return NavDatagraphSettings.symbol;
    // else return null;
    return _selectedSymbol;
  }

  setSelectedSymbol(symbol) {
    _selectedSymbol = symbol;
  }

  updateSelectedListItem(listId, actualListId, listName, categoryType, parentNodeId, parentCategoryType, parentCategoryName, parentListType, sourceCategoryType, sourceParentCategoryName) {
    let NavListManagerSettings = this.getNavListManagerSettings();
    let tabType = this.getTabType(_state.SelectedTabKey);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        ListSettingHelper.updateSelectedListItem(NavListManagerSettings.TabONeilSettings, listId, actualListId, listName, categoryType, parentNodeId, parentCategoryType, parentCategoryName, parentListType, sourceCategoryType, sourceParentCategoryName);
        break;
      case ListManagerTabType.Ownership:
        ListSettingHelper.updateSelectedListItem(NavListManagerSettings.TabOwnershipSettings, listId, actualListId, listName, categoryType, parentNodeId, parentCategoryType, parentCategoryName, parentListType, sourceCategoryType, sourceParentCategoryName);
        break;
      case ListManagerTabType.External:
        ListSettingHelper.updateSelectedExternalListItem(NavListManagerSettings.TabExternalSettings, listId, actualListId, listName);
        SettingsStore.saveSettings();
        break;
      default:
        return;
    }
  }

  updateSelectedListItemForTempList(listId, actualListId, listName, categoryType, parentNodeId, parentCategoryType, parentCategoryName, parentListType, sourceCategoryType, sourceParentCategoryName) {
    let NavListManagerSettings = this.getNavListManagerSettings();
    let tabType = this.getTabType(_state.SelectedTabKey);
    let viewSettings = (tabType === ListManagerTabType.Ownership && categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) ? NavListManagerSettings.TabOwnershipSettings : NavListManagerSettings.TabONeilSettings;
    ListSettingHelper.updateSelectedListItem(viewSettings, listId, actualListId, listName, categoryType, parentNodeId, parentCategoryType, parentCategoryName, parentListType, sourceCategoryType, sourceParentCategoryName);
  }

getSelectedListItem(tabKey = _state.SelectedTabKey) {
  let NavListManagerSettings = this.getNavListManagerSettings();
  let listItem = {};

  if (tabKey) {
    let tabType = this.getTabType(tabKey);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        listItem.SelectedListId = NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedListId;
        listItem.SelectedActualListId = NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedActualListId;
        listItem.SelectedListName = NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedListName;
        listItem.SelectedListName = unescape(NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedListName);
        listItem.SelectedParentNodeId = NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedParentNodeId;
        listItem.SelectedParentCategoryType = NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedParentCategoryType;
        listItem.SelectedParentCategoryName = unescape(NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedParentCategoryName);
        listItem.SelectedParentListType = NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedParentListType;
        listItem.SelectedSourceCategoryType = NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedSourceCategoryType;
        listItem.SelectedCategoryType = NavListManagerSettings.TabONeilSettings.SelectedListInfo.SelectedCategoryType;
        break;
      case ListManagerTabType.Ownership:
        listItem.SelectedListId = NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedListId;
        listItem.SelectedActualListId = NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedActualListId;
        listItem.SelectedListName = NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedListName;
        listItem.SelectedListName = unescape(NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedListName);
        listItem.SelectedParentNodeId = NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedParentNodeId;
        listItem.SelectedParentCategoryType = NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedParentCategoryType;
        listItem.SelectedParentCategoryName = unescape(NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedParentCategoryName);
        listItem.SelectedParentListType = NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedParentListType;
        listItem.SelectedSourceCategoryType = NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedSourceCategoryType;
        listItem.SelectedCategoryType = NavListManagerSettings.TabOwnershipSettings.SelectedListInfo.SelectedCategoryType;       
        break;    
      case ListManagerTabType.External:
        listItem.SelectedListId = NavListManagerSettings.TabExternalSettings.SelectedListInfo.SelectedListId;
        listItem.SelectedActualListId = NavListManagerSettings.TabExternalSettings.SelectedListInfo.SelectedActualListId;
        listItem.SelectedListName = NavListManagerSettings.TabExternalSettings.SelectedListInfo.SelectedListName;
      default:
        break;
    }
  }
  return listItem;
}

getListExplorerSettings() {
  let NavListManagerSettings = this.getNavListManagerSettings();
  let tabType = this.getTabType(_state.SelectedTabKey);
  let listExplorerSettings = 0;
  switch (tabType) {
    case ListManagerTabType.Oneil:
      listExplorerSettings = NavListManagerSettings.TabONeilSettings.ListExplorer;
      break;
    case ListManagerTabType.Ownership:
      listExplorerSettings = NavListManagerSettings.TabOwnershipSettings.ListExplorer;
      break;
    case ListManagerTabType.External:
        listExplorerSettings = NavListManagerSettings.TabExternalSettings.ListExplorer;
        break;
    default:
      break;
  }
  return listExplorerSettings;
}

getListExplorerDefaultWidth() {
  let tabType = this.getTabType(_state.SelectedTabKey);
  let listExplorerDefaultWidth = 0;
  switch (tabType) {
    case ListManagerTabType.Oneil:
      let tabONeilSettings = new TabONeilSettings();
      listExplorerDefaultWidth = tabONeilSettings.getDefaultListExplorerWidth();
      break;
    case ListManagerTabType.Ownership:
      let tabOwnershipSettings = new TabOwnershipSettings();
      listExplorerDefaultWidth = tabOwnershipSettings.getDefaultListExplorerWidth();
      break;
    case ListManagerTabType.External:
        let tabExternalSettings = new TabExternalSettings();
        listExplorerDefaultWidth = tabExternalSettings.getDefaultListExplorerWidth();
        break;
    default:
      break;
  }
  return listExplorerDefaultWidth;
}

getMetricLibraryDefaultWidth() {
  let tabType = this.getTabType(_state.SelectedTabKey);
  let metricLibraryDefaultWidth = 0;
  switch (tabType) {
    case ListManagerTabType.Oneil:
      let tabONeilSettings = new TabONeilSettings();
      metricLibraryDefaultWidth = tabONeilSettings.getDefaultMetricLibraryWidth();
      break;
    case ListManagerTabType.Ownership:
      let tabOwnershipSettings = new TabOwnershipSettings();
      metricLibraryDefaultWidth = tabOwnershipSettings.getDefaultMetricLibraryWidth();
      break;
    default:
      return;
  }
  return metricLibraryDefaultWidth;
}
  updateListExplorerPreviousWidth(width) {
    if (width != this.getListExplorerDefaultWidth()) {
      let NavListManagerSettings = this.getNavListManagerSettings();
      let tabType = this.getTabType(_state.SelectedTabKey);
      switch (tabType) {
        case ListManagerTabType.Oneil:
          ListSettingHelper.updateListExplorerPreviousWidth(NavListManagerSettings.TabONeilSettings, width);
          break;
        case ListManagerTabType.Ownership:
          ListSettingHelper.updateListExplorerPreviousWidth(NavListManagerSettings.TabOwnershipSettings, width);
          break;
        case ListManagerTabType.External:
          ListSettingHelper.updateListExplorerPreviousWidth(NavListManagerSettings.TabExternalSettings, width);
          break;
        default:
          return;
      }
      SettingsStore.saveSettings();
    }
  }

  updateListExplorerWidth(width) {
    let metriclibrarywidth = 0;
    if (width > this.getListExplorerDefaultWidth()) { metriclibrarywidth = width }
    else { metriclibrarywidth = this.getListExplorerDefaultWidth() }
    this.updateMetricLibraryWidth(metriclibrarywidth);
    let NavListManagerSettings = this.getNavListManagerSettings();
    let tabType = this.getTabType(_state.SelectedTabKey);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        ListSettingHelper.updateListExplorerWidth(NavListManagerSettings.TabONeilSettings, width);
        break;
      case ListManagerTabType.Ownership:
        ListSettingHelper.updateListExplorerWidth(NavListManagerSettings.TabOwnershipSettings, width);
        break;
      case ListManagerTabType.External:
        ListSettingHelper.updateListExplorerWidth(NavListManagerSettings.TabExternalSettings, width);
        break;
      default:
        return;
    }
    SettingsStore.saveSettings();
  }

updateListExplorerPreviousWidth(width) {
  if (width != this.getListExplorerDefaultWidth()) {
    let NavListManagerSettings = this.getNavListManagerSettings();
    let tabType = this.getTabType(_state.SelectedTabKey);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        ListSettingHelper.updateListExplorerPreviousWidth(NavListManagerSettings.TabONeilSettings, width);
        break;
      case ListManagerTabType.Ownership:
        ListSettingHelper.updateListExplorerPreviousWidth(NavListManagerSettings.TabOwnershipSettings, width);
        break;
      case ListManagerTabType.External:
        ListSettingHelper.updateMetricLibraryWidth(NavListManagerSettings.TabExternalSettings, width);
        break;
      default:
        return;
    }
    SettingsStore.saveSettings();
  }
}

updateListExplorerWidth(width) {
  let metriclibrarywidth = 0;
  if (width > this.getListExplorerDefaultWidth()) { metriclibrarywidth = width }
  else { metriclibrarywidth = this.getListExplorerDefaultWidth() }
  this.updateMetricLibraryWidth(metriclibrarywidth);
  let NavListManagerSettings = this.getNavListManagerSettings();
  let tabType = this.getTabType(_state.SelectedTabKey);
  switch (tabType) {
    case ListManagerTabType.Oneil:
      ListSettingHelper.updateListExplorerWidth(NavListManagerSettings.TabONeilSettings, width);
      break;
    case ListManagerTabType.Ownership:
      ListSettingHelper.updateListExplorerWidth(NavListManagerSettings.TabOwnershipSettings, width);
      break;
    default:
      return;
  }
  SettingsStore.saveSettings();
}

updateMetricLibraryWidth(width) {
  let NavListManagerSettings = this.getNavListManagerSettings();
  let tabType = this.getTabType(_state.SelectedTabKey);
  switch (tabType) {
    case ListManagerTabType.Oneil:
      ListSettingHelper.updateMetricLibraryWidth(NavListManagerSettings.TabONeilSettings, width);
      break;
    case ListManagerTabType.Ownership:
      ListSettingHelper.updateMetricLibraryWidth(NavListManagerSettings.TabOwnershipSettings, width);
      break;
    default:
      return;
  }
  SettingsStore.saveSettings();
}
  updateTextSize(data) {
    let NavListManagerSettings = this.getNavListManagerSettings();
    let tabType = this.getTabType(_state.SelectedTabKey);
    switch (tabType) {
      case ListManagerTabType.Oneil:
        ListSettingHelper.updateTextSize(NavListManagerSettings.TabONeilSettings, data.textSize);
        break;
      case ListManagerTabType.Ownership:
        ListSettingHelper.updateTextSize(NavListManagerSettings.TabOwnershipSettings, data.textSize);
        break;
      case ListManagerTabType.External:
        ListSettingHelper.updateTextSize(NavListManagerSettings.TabExternalSettings, data.textSize);
        break;
      default:
        return;
    }
  }

  getMetricLibrarySettings() {
    let NavListManagerSettings = this.getNavListManagerSettings();
    let tabType = this.getTabType(_state.SelectedTabKey);
    let metricLibrarySettings = 0;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        metricLibrarySettings = NavListManagerSettings.TabONeilSettings.MetricLibrary;
        break;
      case ListManagerTabType.Ownership:
        metricLibrarySettings = NavListManagerSettings.TabOwnershipSettings.MetricLibrary;
        break;
      case ListManagerTabType.External:
        metricLibrarySettings = NavListManagerSettings.TabExternalSettings.MetricLibrary;
        break;
      default:
        break;
    }
    return metricLibrarySettings;
  }

getMetricLibrarySettings() {
  let NavListManagerSettings = this.getNavListManagerSettings();
  let tabType = this.getTabType(_state.SelectedTabKey);
  let metricLibrarySettings = 0;
  switch (tabType) {
    case ListManagerTabType.Oneil:
      metricLibrarySettings = NavListManagerSettings.TabONeilSettings.MetricLibrary;
      break;
    case ListManagerTabType.Ownership:
      metricLibrarySettings = NavListManagerSettings.TabOwnershipSettings.MetricLibrary;
      break;
    default:
      break;
  }
  return metricLibrarySettings;
}

getColumnSet(data) {
  let self = this;
  ListApi.getColumnSet(data.activeListId, data.SelectedTabKey).then((result) => {
    _state.columnSet = self.sortColumnSet(result.data.treeNodes);
    _currentAction = ListExplorerConstants.ActionTypes.GET_COLUMN_SET;
    self.emit(CHANGE_EVENT);
  });
}

sortColumnSet(data) {
  let columnSet = [];

  each(data, (item) => {
    if (!item.canEdit && item.listType == ListType.FOLDER_List) {
      columnSet.push(item);
    }
  });

  each(data, (item) => {
    if (!item.canEdit && item.listType == ListType.COLUMNSET_List) {
      columnSet.push(item);
    }
  });

  each(data, (item) => {
    if (item.canEdit && item.listType == ListType.FOLDER_List) {
      columnSet.push(item);
    }
  });

  each(data, (item) => {
    if (item.canEdit && item.listType == ListType.COLUMNSET_List) {
      columnSet.push(item);
    }
  });

  return columnSet;
}

addNewColumnSet(data) {
  let self = this;
  _state.refreshGrid = false;
  ListApi.addNewColumnSet(data.colSetName, data.activeListId, data.parentId, data.dbtype).then((result) => {
    _state.columnSetResultError = result.responseHeader.error;
    _currentAction = ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET;
    _currentColumnSetActions = ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET;
    if (!_state.columnSetResultError) {
      _state.columnSet = result.data.treeNodes;
      _state.refreshGrid = true;
      self.emit(CHANGE_EVENT);
    }
    else {
      if (result.responseHeader.errorType == ErrorType.ERROR_Duplicate || result.responseHeader.errorType == ErrorType.ERROR_Invalid) {
        self.emit(CHANGE_EVENT);
      }
    }
  });
}

renameColumnSet(data) {
  let self = this;
  _state.refreshGrid = false;
  ListApi.renameColumnSet(data.newColSetName, data.colsetId).then((result) => {
    _state.columnSetResultError = result.responseHeader.error;
    if (!_state.columnSetResultError) {
      let state = _state.SelectedTabKey == ListManagerTabType.Oneil ? ONeilViewStore.getState():OwnershipViewStore.getState();
      if (data.colsetId == state.basic.columnSet.nodeId.low) state.basic.columnSet.name = data.newColSetName.replace(/\+/g, " ");
      self.emit(CHANGE_EVENT);
    }
    else {
      if (result.responseHeader.errorType == ErrorType.ERROR_Duplicate || result.responseHeader.errorType == ErrorType.ERROR_Invalid) {
        self.emit(CHANGE_EVENT);
      }
    }
  });
}

addRenameColumnSetFolder(data) {
  let self = this;
  _state.refreshGrid = false;
  ListApi.addRenameColumnSetFolder(data.newColSetFolderName, data.consoleID, data.folderID, data.dbtype).then((result) => {
    _state.columnSetResultError = result.responseHeader.error;
    if (!_state.columnSetResultError) {
      self.emit(CHANGE_EVENT);
    }
    else {
      if (result.responseHeader.errorType == ErrorType.ERROR_Duplicate || result.responseHeader.errorType == ErrorType.ERROR_Invalid || result.responseHeader.errorType == null) {
        self.emit(CHANGE_EVENT);
      }
    }
  });
}

removeColumnSet(data) {
  let self = this;
  _state.refreshGrid = false;
  ListApi.removeColumnSet(data.columnsetId).then((result) => {
    _state.columnSetResultError = result.responseHeader.error;
    if (!_state.columnSetResultError) {
      let state = ONeilViewStore.getState();
      if (_state.SelectedTabKey == 'ONEIL') {
        if (data.columnsetId == state.basic.columnSet.nodeId.low) {
          _state.refreshGrid = true;
        }
      } else {
        let OwnershipState = OwnershipViewStore.getState();
        if (data.columnsetId == OwnershipState.columnSetId) {
          _state.refreshGrid = true;
        }
      }
      self.emit(CHANGE_EVENT);
    }
    else {
      _currentAction = null;
    }
  });
}

findSelectedColumnSet(colSetId) {
  return find(_state.columnSet, (col) => col.nodeId.low == colSetId);
}

isContainActiveNode(childs) {
  let activeNode = '';
  const state = ONeilViewStore.getState();
  const OwnershipState = OwnershipViewStore.getState();
  if (_state.SelectedTabKey == 'ONEIL') {
    activeNode = find(childs, (c) => c.nodeId.low == state.basic.columnSet.nodeId.low);
  } else {
    activeNode = find(childs, (c) => c.nodeId.low == OwnershipState.columnSetId);
  }
  return activeNode ? true : false;
}

removeColumnSetFolder(data) {
  const selectedColumnSet = this.findSelectedColumnSet(data.columnsetId);
  let self = this;
  let refresh = false;
  if (selectedColumnSet.childNodes.length > 0) {
    refresh = this.isContainActiveNode(selectedColumnSet.childNodes);
  }
  ListApi.removeColumnSetFolder(data.columnsetId).then((result) => {
    _state.columnSetResultError = result.responseHeader.error;
    if (!_state.columnSetResultError) {
      _state.refreshGrid = refresh;
      self.emit(CHANGE_EVENT);
    }
    else {
      _currentAction = null;
    }
  });
}

resetColumnSet(data) {
  let self = this;
  ListApi.resetColumnSet(data.columnsetId, data.activeListId).then((result) => {
    _state.columnSetResultError = result.responseHeader.error;
    if (!_state.columnSetResultError) {
      _state.refreshGrid = true;
      self.emit(CHANGE_EVENT);
    }
    else {
      _currentAction = null;
    }
  });
}

duplicateColumnSet(data) {
  let self = this;
  ListApi.duplicateColumnSet(data.duplicateColumnSetName, data.activeListId, data.srcColSetId).then((result) => {
    _state.columnSetResultError = result.responseHeader.error;
    if (!_state.columnSetResultError) {
      _state.refreshGrid = true;
      self.emit(CHANGE_EVENT);
    }
    else {
      if (result.responseHeader.error) {
        if (result.responseHeader.errorType == ErrorType.ERROR_Duplicate || result.responseHeader.errorType == ErrorType.ERROR_Invalid) {
          self.emit(CHANGE_EVENT);
        }
        else {
          _currentAction = null;
        }
      }
    }
  });
}

getNodeDetails(data) {
  let self = this;
  ListApi.getNodeDetails(data.nodeId, data.nodeType).then((result) => {
    _state.columnSetProperties = result.shareNodeDetails;
    _state.nodeId_colSetProp = data.nodeId;
    self.emit(CHANGE_EVENT);
  });
}

setItemDataforColumnFilter(itemData) {
  _state.columnFilterItemData = itemData;
}

getItemDataforColumnFilter() {
  return extend(_state.columnFilterItemData, { key: _pressedKey });
}

getSearchString() {
  return _state.searchString;
}
clearSearchString() {
  _state.searchString = "";
}
getDisplayNameforMsItemID(msitemId) {
  let self = this;
  let metricmetricDisplayName = "";
  if (self.metricResponseData) {
    each(self.metricResponseData.itemData, (itemData) => {
      if (itemData.MsItemID == msitemId) {
        metricmetricDisplayName = itemData.MetricDisplayName;
        return;
      }
    });
  }
  return LocalizationStore.getTranslatedData("DataItemExt_Description_" + msitemId, metricmetricDisplayName);
}

changeColumnSetLocation(data) {
  let self = this;
  ListApi.changeColumnSetLocation(data.parentNodeID, data.columnsetId).then((result) => {
    _state.columnSetResultError = result.responseHeader.error;
    if (!_state.columnSetResultError) {
      self.emit(CHANGE_EVENT);
    }
  });
}

getMetricLibraryData(metricData) {
  let self = this;
  let data = { categoryData: [], itemData: null, periodicityData: null, metricCount: 0 };
  if (metricData) {
    data.periodicityData = metricData.periodicityData;
    data.itemData = metricData.itemData;
    each(metricData.categoryData, (categoryData) => {
      if (!categoryData.isCustomColumn) { // bug panweb 976 && 1088
        if (categoryData.ParentCategoryId == null) {
          _metricData.CategoryId = categoryData.CategoryId;
          _metricData.CategoryName = categoryData.CategoryName;
          _metricData.DetailCount = categoryData.DetailCount;
          _metricData.RootDescription = categoryData.RootDescription;
          data.categoryData.push(_metricData);
          _metricData = { CategoryId: null, CategoryName: null, DetailCount: null, RootDescription: null, childNode: [] }
        }
        else {
          each(data.categoryData, (categorydata) => {
            if (categoryData.ParentCategoryId == categorydata.CategoryId) {
              _metricData.CategoryId = categoryData.CategoryId;
              _metricData.CategoryName = categoryData.CategoryName;
              _metricData.DetailCount = categoryData.DetailCount;
              _metricData.RootDescription = categoryData.RootDescription;
              categorydata.childNode.push(_metricData);
              _metricData = { CategoryId: null, CategoryName: null, DetailCount: null, RootDescription: null, childNode: [] }
            }
          })
        }
      } else {
        if (categoryData.ParentCategoryId == null) {
          _metricData.CategoryId = categoryData.CategoryId;
          _metricData.CategoryName = categoryData.CategoryName;
          _metricData.DetailCount = categoryData.DetailCount;
          _metricData.RootDescription = categoryData.RootDescription;
          data.categoryData.unshift(_metricData);
          // data.categoryData.push(_metricData);
          _metricData = { CategoryId: null, CategoryName: null, DetailCount: null, RootDescription: null, childNode: [] }
        }
        else {
          each(data.categoryData, (categorydata) => {
            if (categoryData.ParentCategoryId == categorydata.CategoryId) {
              _metricData.CategoryId = categoryData.CategoryId;
              _metricData.CategoryName = categoryData.CategoryName;
              _metricData.DetailCount = categoryData.DetailCount;
              _metricData.RootDescription = categoryData.RootDescription;
              categorydata.childNode.push(_metricData);
              _metricData = { CategoryId: null, CategoryName: null, DetailCount: null, RootDescription: null, childNode: [] }
            }
          })
        }
      }
    })
    _totalDetailMetrics=[];

    for (let i = 0; i < metricData.categoryData.length; i++) {
      for (let j = 0; j < data.categoryData.length; j++) {
        for (let k = 0; k < data.categoryData[j].childNode.length; k++) {
          // if (!metricData.categoryData[i].isCustomColumn) { // bug panweb 976 && 1088
          if (metricData.categoryData[i].ParentCategoryId == data.categoryData[j].childNode[k].CategoryId) {
            _metricData.CategoryId = metricData.categoryData[i].CategoryId;
            _metricData.CategoryName = metricData.categoryData[i].CategoryName;
            _metricData.DetailCount = metricData.categoryData[i].DetailCount;
            _metricData.RootDescription = metricData.categoryData[i].RootDescription;
            data.categoryData[j].childNode[k].childNode.push(_metricData);
            _totalDetailMetrics.push(_metricData);
            _metricData = { CategoryId: null, CategoryName: null, DetailCount: null, RootDescription: null, childNode: [] }
            data.metricCount = data.metricCount + 1;
          }
          // }
        }
      }
    }

    for (let j = 0; j < data.categoryData.length; j++) {
      for (let k = 0; k < data.categoryData[j].childNode.length; k++) {
        let node = data.categoryData[j].childNode[k].childNode;
        for (let m = 0; m < node.length; m++) {
          let metric = {};
          for (let i = 0; i < metricData.itemData.length; i++) {
            const text = this.getPeriodicityText(metricData, metricData.itemData[i].periodicity);
            if (metricData.itemData[i].CategoryId == node[m].CategoryId) {
              if (!metric[text]) { metric[text] = []; }
              metric[text].push(metricData.itemData[i]);
            }
          }
          let tempMetric = metric;
          let sortedMetric = {};
          let periodicityKeys = Object.keys(tempMetric);
          let timebacks = [];
          each(periodicityKeys, (periodicity) => {
            let otherlookbacks = [];
            each(tempMetric[periodicity], (periodicityItem) => {
              timebacks.push(periodicityItem.timeback)
            })
            let sortedTimebacks = timebacks.sort((a, b) => (a - b));
            each(sortedTimebacks, (timeback) => {
              each(tempMetric[periodicity], (periodicityItem) => {
                if (timeback == periodicityItem.timeback) {
                  otherlookbacks.push(periodicityItem);
                }
              })
            })
            sortedMetric[periodicity] = [];
            each(otherlookbacks, (lookback) => {
              if (self.isDuplicateEntry(sortedMetric[periodicity], lookback) != true) {
                sortedMetric[periodicity].push(lookback);
              }
            })
          })
          node[m].childNode = sortedMetric;
        }
      }
    }
  }
  _state.metricData = data;
  return data;
}

getListExplorerWidthForMetricLibrary() {
  return _state.listExplorerWidthForMetricLibrary;
}

setListExplorerWidth(data) {
  let listexplorerSettings = this.getListExplorerSettings();
  if (data.isMetricLibraryOpen == true) {
    if (data.width == null) {
      if (listexplorerSettings.Width < this.getMetricLibraryDefaultWidth()) {
        _state.listExplorerWidthForMetricLibrary = this.getMetricLibraryDefaultWidth();
        this.updateMetricLibraryWidth(_state.listExplorerWidthForMetricLibrary);
      }
      else {
        _state.listExplorerWidthForMetricLibrary = this.getMetricLibrarySettings().Width;
      }
    }
    else {
      _state.listExplorerWidthForMetricLibrary = data.width;
      this.updateMetricLibraryWidth(_state.listExplorerWidthForMetricLibrary);
    }
  }
  else if (data.isMetricLibraryOpen == false) {
    _state.listExplorerWidthForMetricLibrary = listexplorerSettings.Width;
    if (listexplorerSettings.Width >= this.getMetricLibraryDefaultWidth()) {
      this.updateMetricLibraryWidth(listexplorerSettings.Width);
    }
    else {
      this.updateMetricLibraryWidth(this.getMetricLibraryDefaultWidth());
    }
  }
  this.emit(CHANGE_EVENT);
}

getMetricLibWidth(isOpen = true) {
  let metricLibSettings = this.getMetricLibrarySettings();
  if (isOpen) {
    // if (this.getListExplorerSettings().Width < this.getMetricLibraryDefaultWidth())
    //   return this.getMetricLibraryDefaultWidth();
    // else
    return metricLibSettings.Width;
  }
}

checkKeyExist(searchKey, searchData) {
  let isStringExisit = true;
  const searchArray = searchKey ? searchKey.split(' ') : [];
  searchArray.map((searchString) => {
    if (isStringExisit && !searchData.toLowerCase().includes(searchString.toLowerCase())) {
      isStringExisit = false;
    }
  });
  return isStringExisit;
}
getSearchData(keyword) {
  let detailMetrics = _totalDetailMetrics;
  let searchmetrics = [];
  each(detailMetrics, (metric) => {
    let searchMetricString = LocalizationStore.getTranslatedData("MetricLibraryCategory_" + StringUtil.stringReplace(metric.CategoryName),
      metric.CategoryName);
    let childNodes = metric.childNode;
    let keys = Object.keys(childNodes);

    for (let i = 0; i < size(childNodes); i++) {
      let key = keys[i];
      each(childNodes[key], (childNode) => {
        if (!childNode.isCustomColumn) { // bug panweb 976 && 1088 childNode.isCustomColumn
          if (childNode.Tags) {
            searchMetricString = `${searchMetricString} ${childNode.Tags}`;
          }
          let columnDisplayName = childNode.MetricDisplayName;
          searchMetricString = `${searchMetricString} ${columnDisplayName}`;
        }
      })
    }
    if (this.checkKeyExist(keyword, searchMetricString)) {
      searchmetrics.push(metric);
    }
  })
  return uniq(searchmetrics, 'CategoryId');
}
setMetricSearchData(searchdata) {
  let searchmetrics = [];
  let self = this;
  if (searchdata.keyword == null) {
    _pressedKey = searchdata.data.key;
    let fieldTagExt = { metricType: undefined, msItemID: undefined };
    each(this.metricResponseData.itemData, (itemData) => {
      if (searchdata.data.dataItemId == itemData.MsItemID) {
        fieldTagExt.metricType = itemData.metricType;
        fieldTagExt.msItemID = itemData.MsItemID;
      }
    })
    each(_totalDetailMetrics, (totalDetailMetric) => {
      let tempDetailMetric = totalDetailMetric;
      let childNodes = tempDetailMetric.childNode;
      let keys = Object.keys(childNodes);
      let isAvailable = undefined;
      for (let i = 0; i < size(childNodes); i++) {
        let key = keys[i];
        each(childNodes[key], (childNode) => {
          if (childNode.metricType == fieldTagExt.metricType && !childNode.isCustomColumn && childNode.MsItemID != fieldTagExt.msItemID) { isAvailable = true; } // bug panweb 976 && 1088 childNode.isCustomColumn
        })
      }
      if (isAvailable) searchmetrics.push(tempDetailMetric);
      _state.columnFilterSearchResults = searchmetrics;
    })
  }
  if (searchdata && searchdata.keyword && searchdata.keyword.trim() == '') {
    searchmetrics = [];
  }
  else {
    _state.searchString = searchdata.keyword;
    let detailMetrics = searchdata.isColumnFilter == true ? _state.columnFilterSearchResults : _totalDetailMetrics;
    each(detailMetrics, (totalDetailMetric) => {
      //Bug fixes PANWEB-1557
      let searchMetricString = LocalizationStore.getTranslatedData("MetricLibraryCategory_" + StringUtil.stringReplace(totalDetailMetric.CategoryName),
        totalDetailMetric.CategoryName);
      let childNodes = totalDetailMetric.childNode;
      let keys = Object.keys(childNodes);

      for (let i = 0; i < size(childNodes); i++) {
        let key = keys[i];
        each(childNodes[key], (childNode) => {
          if (!childNode.isCustomColumn) { // bug panweb 976 && 1088 childNode.isCustomColumn
            if (childNode.Tags) {
              searchMetricString = `${searchMetricString} ${childNode.Tags}`;
            }
            let columnDisplayName = childNode.MetricDisplayName;
            searchMetricString = `${searchMetricString} ${columnDisplayName}`;
          }
        })
      }
      if (self.checkKeyExist(searchdata.keyword, searchMetricString)) {
        searchmetrics.push(totalDetailMetric);
      }
    })
  }
  _state.metricSearchData = uniq(searchmetrics, 'CategoryId');
  this.emit(CHANGE_EVENT);
}

isDuplicateEntry(searchMetrics, totalDetailMetric) {
  let isDuplicate = false;
  each(searchMetrics, (searchresult) => {
    if (searchresult == totalDetailMetric) isDuplicate = true;
  })
  return isDuplicate;
}

getMetricSearchData() {
  return _state.metricSearchData;
}

getMetricSearchDataCount() {
  return _state.metricSearchData.length;
}

getColumnFilterSearchResults() {
  return _state.columnFilterSearchResults;
}

getColumnFilterSearchResultsCount() {
  return _state.columnFilterSearchResults.length;
}

GetItemDataBySearchString(selectedMetric) {
  let searchString = _state.searchString;
  let searchmetrics = {};
  if (searchString) {
    const searchArray = searchString ? searchString.split(' ') : [];
    each(selectedMetric, (selection, key) => {
      selectedMetric[key].forEach((item) => {
        var isStringExisit = searchArray.map((searchStr) => {
          if (item.MetricDisplayName.toLowerCase().includes(searchStr.toLowerCase()) || (item.Tags && item.Tags.toLowerCase().includes(searchStr.toLowerCase()))) {
            return true;
          } else {
            return false;
          }
        });
        if (isStringExisit.indexOf(false) == -1) {
          searchmetrics[key] = {};
          searchmetrics[key] = selectedMetric[key];
        }
      });
    })
  }
  return searchmetrics;
}


getPeriodicityText(metricData, periodicity) {
  //bug fixes - panweb-1216
  const periodicityData = find(metricData.periodicityData, (p) => p.periodicity == periodicity);
  return periodicityData ? periodicityData.periodicityText : "";
  // switch (periodicity) {
  //   case 0: return "Current";
  //   case 1: return "Daily";
  //   case 2: return "Weekly";
  //   case 3: return "Monthly";
  //   case 4: return "Quarterly";
  //   case 5: return "Annual";
  //   case 6: return "Trailing 4 Quarters";
  // }
}

// Method to validate name while adding column set
GetPreFilledText(prefilledText, isFolder = false) {
  let i = 1;
  let strPrefilledText = prefilledText + " (" + i + ")";
  let tempColumnSets = [];

  if (isFolder) {
    each(_state.columnSet, (item) => {
      if (item.name.includes(prefilledText) && item.listType == ListType.FOLDER_List) {
        tempColumnSets.push(item);
      }
      each(item.childNodes, (child) => {
        if (child.listType !== ListType.FOLDER_List) {
          return;
        }
        if (child.name.includes(prefilledText))
          tempColumnSets.push(child);
      });
    });
  }
  else {
    each(_state.columnSet, (item) => {
      if (item.name.includes(prefilledText) && item.listType !== ListType.FOLDER_List) {
        tempColumnSets.push(item);
      }
      each(item.childNodes, (child) => {
        if (child.listType == ListType.FOLDER_List) {
          return;
        }
        if (child.name.includes(prefilledText))
          tempColumnSets.push(child);
      });
    });
  }

  while (find(tempColumnSets, (item) => item.name == strPrefilledText)) {
    i = i + 1;
    strPrefilledText = prefilledText + " (" + i + ")";
  }

  return strPrefilledText;
}

clearCurrentActions() {
  _currentAction = null;
}

setCatlogReload(isRenameOrDel = false, dataItemId = 0, closeDialog) {
  _state.isCatlogLoad = false;
  if (isRenameOrDel) {
    let column = ONeilViewStore.findColumn(dataItemId);
    if (column && column.dataItemId > 0) {
      _state.isCatlogLoad = true;
    }
  }
  if (closeDialog) {
    _state.isCustMetricOpen = false;
    this.emit(CHANGE_EVENT);
  }
}

setCurrentSelectedDetailId(currentSelectedDetailId) {
  _state.currentSelectedDetailId = currentSelectedDetailId;
}

setNextAvailabDetail(nextAvailabDetail) {
  _state.nextAvailabDetail = nextAvailabDetail;
}

setRefreshList() {
  _state.isRefresh = false;
  _currentAction = ListExplorerConstants.ActionTypes.GET_REFRESHED_VIEW;
  this.emit(CHANGE_EVENT);
}
  setSelectedExternalList(data){
    let self = this;
    this.activeExternalNode = data.selectedExternalList;
    self.emit(CHANGE_EVENT);
  }
  setTempListNameExternal(data){
    let self = this;
    this.externalListName = data.tempListName;    
    self.emit(CHANGE_EVENT);
  }
  addExternalList(data){
    let self = this;
    this.getExternalDataSetList = this.getExternalDataSetList.concat(data.newExternalList)
    self.emit(CHANGE_EVENT);
  }
  getExternalList(data){
    let self = this;
    this.getExternalDataSetList = data.externalList;
    self.emit(CHANGE_EVENT);
  }

  setExternalSelectedNodes(data){
    let self = this;
    this.externalDataSelectedNodes = data.selectedNodes;
    self.emit(CHANGE_EVENT);
  }

  initiateRollBackList(data){
    let self = this;
    this.externalDataSelectedNodes = data.selectedNodes;
    self.emit(CHANGE_EVENT);
  }

  removeDeletedLists(data) {
    const deletedNodes = data.deleteIds;
    const listType = data.listType;
    this.lastDeletedNodes = [];
    let sourceList= [];
    sourceList = this.getExternalDataSetList.find(itm => itm.listType == listType);
    this.lastDeletedNodesIds= deletedNodes;
    for (let sourceIndex = 0; sourceIndex < sourceList.childNodes.length; sourceIndex++) {
      for(let index=0; index< deletedNodes.length;index++) {
         if(sourceList.childNodes[sourceIndex].nodeId.low == deletedNodes[index]) {
          this.lastDeletedNodes.push(sourceList[sourceIndex]);
         }
      }
   }
    // for (let sourceIndex = 0; sourceIndex < deletedNodes.length; sourceIndex++) {
    //   sourceList = sourceList.childNodes.filter((item) => item.nodeId.low != deletedNodes[sourceIndex]);
    // }
    sourceList = this.getExternalDataSetList.map(
      content => content.listType === listType ? {...content, childNodes: content.childNodes.filter(item => !deletedNodes.includes(item.nodeId.low))}
                            : content
    );
    this.getExternalDataSetList = sourceList;
  }

  restoreExternalNode(data) {
    let newSourceList =[];
    newSourceList=[...this.getExternalDataSetList, ...this.lastDeletedNodes];
    this.getExternalDataSetList = newSourceList;
    this.lastDeletedNodes = [];
    this.lastDeletedNodesIds=[];
    ListApi.restoreTreeNode(data.nodeIds).then((result) => {
      if (!result.responseHeader.error) {

        // let sourceList = this.getExternalDataSetList;
        // sourceList = sourceList.filter((item) => item.nodeId.low != deletedNodes[k]);

        // this.restoreNodeId = data.nodeIds.toString().split(';')[0];
        // self.updateDataSource(self.sortOneilData(self.deleteDataSource), false);

        // //PANWEB-934
        // const symbol = this.contextNode.symbol ? this.contextNode.symbol : undefined;
        // if (symbol !== undefined) {
        //   this.activeNode = this.contextNode;
        //   this.activeSybol = {
        //     symbol: symbol,
        //     msId: this.contextNode.msid
        //   };
        // }

        // _state.deleteNodeId = null;
      }
    });
  }

  sortExternalData(dataSources) {
    return dataSources.map(
        content => ({...content,
          childNodes: content.childNodes.sort((a,b) => a.name.localeCompare(b.name))
        })                  
    )
    // let dataSource=[];
    // for(let index=0; index<dataSources.length; index++) {
    //   dataSources[index].orderNum = -1;
    //   dataSource.push(dataSources[index]);
    // }
    // let tempIndex = undefined, tempLists = [];
    // let sortedData = _.sortBy(this.sortExternalDataRecursively(dataSource, 'name'), 'orderNum').reverse();
    // ///PANWEB-2576 start
    // _.each(dataSource, (item) => {
    //   if(item && item.orderNum == 1) {
    //     tempLists.push(item);
    //   }
    // });
    // let sortedDataCopy = _.clone(sortedData)
    // _.each(sortedData, (item, index) => {
    //   if(item && item.orderNum == 1) {
    //       if(tempIndex == undefined) tempIndex = index;
    //       sortedDataCopy.splice(tempIndex, 1);
    //   }      
    // });
    // if(tempLists.length > 0) { sortedDataCopy.splice(tempIndex, 0, ...tempLists); }
    // return sortedDataCopy.reverse();
  }

  sortExternalDataRecursively(dataSource, propertyName) {
    let self = this;
    let hasDuplicates = false;
    dataSource.forEach((item) => {
      if (item.categoryType === ListCategoryType.PRESET_ListCategory) {
        let seen = {};
        // Fix for PANWEB-1155
        hasDuplicates = item.childNodes.some((currentObject) =>
          seen.hasOwnProperty(currentObject.orderNum) || (seen[currentObject.orderNum] = false));
        propertyName = 'orderNum';
      }
      else
        propertyName = 'name';
      let keys = _.keys(item);
      keys.forEach((key) => {
        if (_.isArray(item[key])) {
          if (hasDuplicates) {
            item[key] = self.sortExternalDataRecursively(item[key], propertyName);
          }
          else {
            item[key] = self.sortExternalDataRecursively(item[key].reverse(), propertyName);
          }
        }
      });
    });

    let groups = null;
    if (propertyName == 'orderNum')
      groups = _.groupBy(_.sortBy(dataSource, (x) => x.orderNum).reverse(), 'orderNum');
    else
      groups = _.groupBy(_.sortBy(dataSource, (x) => x.name.toLowerCase()).reverse(), 'orderNum');
    return _.flatten(_.map(groups, _.values)).reverse();
  }

  sortSourceData(sortedList) {
    this.getExternalDataSetList=sortedList.sortedData;
  }

  listPropertyStateChange(){
    _currentAction = ListExplorerConstants.ActionTypes.LIST_PROPERTY_TOGGLE;
    this.emit(CHANGE_EVENT);
  }
  
  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      case SettingsConstants.ActionTypes.LIST_EXPLORER_UPDATE_TAB:
        _currentAction = SettingsConstants.ActionTypes.LIST_EXPLORER_UPDATE_TAB;
        let viewSetting = this.getNavListManagerSettings();
        ListSettingHelper.updateTabSelection(viewSetting, data);
        _state.SelectedTabKey = data;
        // getting metrics as tabKey updates
        this.getMetricLibrary()
        this.emit(CHANGE_EVENT);
        break;

      case SettingsConstants.ActionTypes.THEME_CHANGED:
        _currentAction = SettingsConstants.ActionTypes.THEME_CHANGED;
        _state.themeChanged = true;
        this.emit(CHANGE_EVENT);
        setTimeout(() => { _state.themeChanged = false; }, 0);
        break;

      case ListExplorerConstants.ActionTypes.GET_REFRESHED_VIEW:
        _currentAction = ListExplorerConstants.ActionTypes.GET_REFRESHED_VIEW;
        _state.isRefresh = true;
        ListExplorerStore.getListExplorerData(true);
        this.emit(CHANGE_EVENT);
        break;

      case ListExplorerConstants.ActionTypes.GET_COLUMN_SET:
        debounce(this.getColumnSet(data), 200);
        break;

      case ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET:
        debounce(this.addNewColumnSet(data), 200);
        break;

      case ListExplorerConstants.ActionTypes.RENAME_COLUMN_SET:
        _currentAction = ListExplorerConstants.ActionTypes.RENAME_COLUMN_SET;
        debounce(this.renameColumnSet(data), 200);
        break;

      case ListExplorerConstants.ActionTypes.ADD_RENAME_COLUMN_SET_FOLDER:
        _currentAction = ListExplorerConstants.ActionTypes.ADD_RENAME_COLUMN_SET_FOLDER;
        debounce(this.addRenameColumnSetFolder(data), 200);
        break;

      case ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET:
        _currentAction = ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET;
        debounce(this.removeColumnSet(data), 200);
        break;

      case ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET_FOLDER:
        _currentAction = ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET_FOLDER;
        debounce(this.removeColumnSetFolder(data), 200);
        break;

      case ListExplorerConstants.ActionTypes.RESET_COLUMN_SET:
        _currentAction = ListExplorerConstants.ActionTypes.RESET_COLUMN_SET;
        debounce(this.resetColumnSet(data), 200);
        break;

      case ListExplorerConstants.ActionTypes.DUPLICATE_COLUMN_SET:
        _currentAction = ListExplorerConstants.ActionTypes.DUPLICATE_COLUMN_SET;
        debounce(this.duplicateColumnSet(data), 200);
        break;

      case ListExplorerConstants.ActionTypes.GET_NODE_DETAILS:
        _currentAction = ListExplorerConstants.ActionTypes.GET_NODE_DETAILS;
        this.getNodeDetails(data);
        break;

      case ShareDialogConstants.CLEAR_SHARE_DATA:
        _currentAction = ListExplorerConstants.ActionTypes.GET_NODE_DETAILS;
        const activeNode = ListExplorerStore.activeNode;
        const currentNodeId = activeNode && activeNode.nodeId ? typeof (activeNode.nodeId) === 'object' ? activeNode.nodeId.low : activeNode.nodeId : null;
        const nodeType = ListExplorerStore.isAnnoationList() ? ListType.ANNOTATIONLAYER_List : ListType.LIST_List
        const activeNodeData = { nodeId: currentNodeId, nodeType: nodeType };
        let tabType = this.getTabType(_state.SelectedTabKey);
        if(tabType !== ListManagerTabType.External){
          this.getNodeDetails(activeNodeData);
        }
        break;

      case ListExplorerConstants.ActionTypes.CHANGE_COLUMN_SET_LOCATION:
        _currentAction = ListExplorerConstants.ActionTypes.CHANGE_COLUMN_SET_LOCATION;
        debounce(this.changeColumnSetLocation(data), 200);
        break;

    case OwnershipViewConstants.ActionTypes.GET_REFRESHED_VIEW_OWNERSHIP:
      _currentAction = OwnershipViewConstants.ActionTypes.GET_REFRESHED_VIEW_OWNERSHIP;
      ListExplorerStore.getListExplorerData(true);
      this.emit(CHANGE_EVENT);
      break;

    case ListExplorerConstants.ActionTypes.UPDATE_NODE_DESCRIPTION:
      _currentAction = ListExplorerConstants.ActionTypes.UPDATE_NODE_DESCRIPTION;
      this.getNodeDetails(data);
      break;

    case ListExplorerConstants.ActionTypes.GET_METRIC_LIBRARY:
      _currentAction = ListExplorerConstants.ActionTypes.GET_METRIC_LIBRARY;
      if(!_isMetricApiCall){
        this.getMetricLibrary();
      }
      break;

    case ListExplorerConstants.ActionTypes.GET_METRIC_SEARCH_TEXT:
      _currentAction = ListExplorerConstants.ActionTypes.GET_METRIC_SEARCH_TEXT;
      this.setMetricSearchData(data);
      this.emit(CHANGE_EVENT);
      break;

    case ListExplorerConstants.ActionTypes.UPDATE_LIST_EXPLORER_WIDTH:
      _currentAction = ListExplorerConstants.ActionTypes.UPDATE_LIST_EXPLORER_WIDTH;
      this.setListExplorerWidth(data);
      break;

    case ListExplorerConstants.ActionTypes.SHOW_METRIC_LIBRARY_COLUMN_FILTER:
      _currentAction = ListExplorerConstants.ActionTypes.SHOW_METRIC_LIBRARY_COLUMN_FILTER;
      this.emit(CHANGE_EVENT);
      break;

    case ListExplorerConstants.ActionTypes.UPDATE_METRIC_COLUMN_FILTER:
      _currentAction = ListExplorerConstants.ActionTypes.UPDATE_METRIC_COLUMN_FILTER;
      this.setItemDataforColumnFilter(data);
      this.emit(CHANGE_EVENT);
      break;

    case ListExplorerConstants.ActionTypes.TOGGLE_METRIC_LIBRARY:
      _currentAction = ListExplorerConstants.ActionTypes.TOGGLE_METRIC_LIBRARY;
      this.emit(CHANGE_EVENT);
      break;

    case ListExplorerConstants.ActionTypes.BROWSE_METRIC_LIBRARY:
      _currentAction = ListExplorerConstants.ActionTypes.BROWSE_METRIC_LIBRARY;
      _state.isBrowseMetricsOpen = true;
      this.updateMetricLibraryWidth('400');
      this.emit(CHANGE_EVENT);
      break;
    case ListExplorerConstants.ActionTypes.CLOSE_CUSTOM_METRICS:
      _currentAction = ListExplorerConstants.ActionTypes.CLOSE_CUSTOM_METRICS;
      _state.isBrowseMetricsOpen = false;
      _state.isCustMetricOpen = data;
      this.emit(CHANGE_EVENT);
      break;
    case ListExplorerConstants.ActionTypes.OPEN_CUSTOM_METRICS:
      _currentAction = ListExplorerConstants.ActionTypes.OPEN_CUSTOM_METRICS;
      _state.isCustMetricOpen = data;
      this.emit(CHANGE_EVENT);
      break;
    case ListExplorerConstants.ActionTypes.CHANGE_TEXT_SIZE:
      _currentAction = ListExplorerConstants.ActionTypes.CHANGE_TEXT_SIZE;
      this.updateTextSize(data);
      this.emit(CHANGE_EVENT);
      SettingsStore.saveSettings();
      break;
    case ListExplorerConstants.ActionTypes.TOGGLE_DIALOG_100_COLUMNS:
      _currentAction = ListExplorerConstants.ActionTypes.TOGGLE_DIALOG_100_COLUMNS;
      _state.isColumnAlertsDialog = data.isOpen;
      this.emit(CHANGE_EVENT);
      break;
    case ListExplorerConstants.ActionTypes.OPEN_CUSTOM_METRICS_CLICK:
        _currentAction = ListExplorerConstants.ActionTypes.OPEN_CUSTOM_METRICS_CLICK;
        _state.isBrowseMetricsOpen = data;
        _state.isCustMetricOpen = data;
        this.emit(CHANGE_EVENT);
        break;
      case ListExplorerConstants.ActionTypes.CUSTOM_METRIC_CHANGED:
        _currentAction = ListExplorerConstants.ActionTypes.CUSTOM_METRIC_CHANGED;
        this.setCatlogReload(data.isCatLoad, data.dataItemId, data.closeDialog);
        this.setNextAvailabDetail(data.nextAvailabDetail);
        this.getMetricLibrary(true);
        this.emit(CHANGE_EVENT);
        break;
      case ListExplorerConstants.ActionTypes.SET_CURRENT_EXTERNAL_LIST:
        _currentAction =  ListExplorerConstants.ActionTypes.SET_CURRENT_EXTERNAL_LIST;
        this.setSelectedExternalList(data);
        break;
      case ListExplorerConstants.ActionTypes.SET_ACTIVE_LIST_NAME_EXTERNAL:        
        _currentAction =  ListExplorerConstants.ActionTypes.SET_ACTIVE_LIST_NAME_EXTERNAL;
        this.activeExternalNode.listName = data.listName;
        this.emit(CHANGE_EVENT);
        break; 
      case ListExplorerConstants.ActionTypes.LIST_RENAME_OWNERSHIP:        
        _currentAction =  ListExplorerConstants.ActionTypes.LIST_RENAME_OWNERSHIP;
        const selectedList = this.getSelectedListItem();
        this.updateSelectedListItem(selectedList.SelectedListId,selectedList.SelectedListId,data.listName)
        this.activeOwnershipNode.listName = data.listName;
        this.emit(CHANGE_EVENT);
        break;        
      case ListExplorerConstants.ActionTypes.SET_TEMP_LIST:
        this.setTempListNameExternal(data);
        break;
      case ListExplorerConstants.ActionTypes.SET_EXTERNAL_LIST:
        this.getExternalList(data);
        break;
      case ListExplorerConstants.ActionTypes.ADD_NEW_EXTERNAL_LIST:
        this.addExternalList(data);
        break;
      case ListExplorerConstants.ActionTypes.SET_EXTERNAL_SELECTED_NODES:
        this.setExternalSelectedNodes(data);
        break;

      case ListExplorerConstants.ActionTypes.SET_EXTERNAL_SELECTED_NODES:
          this.initiateRollBackList(data);
          break;
      case ListExplorerConstants.ActionTypes.REMOVE_DELETED_NODES:
          this.removeDeletedLists(data);
        break;
      case ListExplorerConstants.ActionTypes.RESTORE_EXTERNAL_NODE:
          this.restoreExternalNode(data);
        break;
      case ListExplorerConstants.ActionTypes.SORT_EXTERNAL_DATA:
          this.sortSourceData(data);
        break;
      case ListExplorerConstants.ActionTypes.EXTERNAL_HEADER_CHANGED:
        _currentAction = ListExplorerConstants.ActionTypes.EXTERNAL_HEADER_CHANGED;
        this.emit(CHANGE_EVENT);
      break;
      default:
        return;
    }
  }
}


const listStore = new ListStore();
export default listStore;
