import { connect } from "react-redux";
import React, { Component } from "react";

class AumData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  syncHover(index, hover) {
    this.props.syncHover(index, hover);
  }

  onHover(hover) {
    this.setState({ hover: hover });
  }
  render() {
    const divstyle = { height: this.props.RowHeight };
    const clsHover = this.state.hover ? "hover-on-row" : " ";
    // let aum="0";
    // let tov="0%";
    // let nsh="0";   
    let aum = "";
    let tov = "";
    let nsh = "";
    if (this.props.header.AUM || this.props.header.AUM === 0) {
      if (!isNaN(parseFloat(this.props.header.AUM))) {
        aum = parseFloat(this.props.header.AUM).toFixed(2);
      }
    }
    if (this.props.header.TurnOverValue || this.props.header.TurnOverValue === 0) {
      if (!isNaN(parseFloat(this.props.header.TurnOverValue))) {
        tov = `${Math.floor(parseFloat(this.props.header.TurnOverValue))}%`;
      }
    }
    if (this.props.header.NoOfStocksHeld || this.props.header.NoOfStocksHeld === 0) {
      if (!isNaN(parseFloat(this.props.header.NoOfStocksHeld))) {
        nsh = parseFloat(this.props.header.NoOfStocksHeld).toFixed(0);
      }
    }
    return (
      <div className={`owners-toggler-row ${clsHover}`} style={divstyle}
        onMouseOver={() => this.syncHover(this.props.index, true)}
        onFocus={() => this.syncHover(this.props.index, true)}
        onMouseLeave={() => this.syncHover(this.props.index, false)}>
        <span className="owners-toggler-cell">{aum}</span>
        <span className="owners-toggler-cell">{tov}</span>
        <span className="owners-toggler-cell">{nsh}</span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { RowHeight } = state.DatagraphReducers.ownershipBlockReducer;
  return { RowHeight }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(AumData)