
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { SettingsConstants } from "../../../Constants/SettingsConstants";

const { ActionTypes } = RiPanelConstants;

const initState = {
    loading: true,
    constituentsData: [],
    isUserEntitled: false,
    isFocusListSymbol: false,
    messageWeightOrValue: '',
    priceChangeMessage: '',
    nameMessage: '',
    itemHeight: 20,
};

const RiPanelConstituentsReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_CONTITUENTS_DATA:
            return {
                ...state,
                constituentsData: action.constituentsData,
                loading: action.loading,
                isUserEntitled: action.isUserEntitled,
                isFocusListSymbol: action.isFocusListSymbol,
                messageWeightOrValue: action.messageWeightOrValue,
                priceChangeMessage: action.priceChangeMessage,
                nameMessage: action.nameMessage,
                itemHeight: action.itemHeight,
            };
        case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
            return {
                ...initState
            }
        default:
            return state;
    }
};

export default RiPanelConstituentsReducer;
