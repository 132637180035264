import DataGraphStore from "../Stores/NavModules/NavDataGraph/DataGraphStore";
import SymbolType from "SymbolType";
import MiniListStore from "MiniListStore";

/********* Helper Functions - Start */

class MiniListHelper {
    ActiveSymbolCheck(symbol, isMiniListPlay) {
        if(isMiniListPlay){
            const storeState = MiniListStore.getState();
            return symbol === storeState.ActiveSymbol;
        }else{
            return true;
        }
    }

    /********* Helper Functions - End */
}

const miniListHelper = new MiniListHelper();
export default miniListHelper;
