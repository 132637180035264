import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  initExternalLists, loadExternalList, nodeSelection, setSelectedNodes,
  createListEmptyList, unloadStateExternal, setRenameNode, setDuplicateNode,
  saveDuplicateNode, changeListName, saveRenameNode, removeDeletedLists, handleUpdateLoader,
  sortExternalList, updateToSettings,showHideUploadDialog,uploadListRepeat,isUpdated,toggleListView,
  findNextFolder
} from '../../../../Actions/ExternalDataUploaderActions';
import ClickMenu from "ClickMenu";
import StringUtil from "StringUtil";
import ListExplorerStore from "ListExplorerStore";
import ListExplorerUtil from "ListExplorerUtil";
import {
  getListMenu
} from "../../../../Utils/ExternalDataUploaderUtil.js";
import ScrollBar from "ScrollBar";
import { Shortcuts } from 'react-shortcuts';
import ListStore from "ListStore";
import ListActions from "ListActions";
import KeyCodes from "KeyCodes";
import { dispatch } from "../../../../Redux/dispatch.js";
import GridStore from "GridStore";
import ExternalDataUploadDialog  from '../../../../RayCustomControls/ExternalData/ExternalDataUploadDialog.jsx'
import ExternalDataUploadWizard  from '../../../../RayCustomControls/ExternalData/ExternalDataUploadWizard.jsx'
import ExternalDataFileError  from '../../../../RayCustomControls/ExternalData/ExternalDataFileError.jsx'
import ExceptionReport  from '../../../../RayCustomControls/ExternalData/ExceptionReport.jsx';
import { ListExplorerConstants } from "ListExplorerConstants";
import ListManagerTabType from "ListManagerTabType";
import { updateStorewithSharedData } from "../../../../Actions/ShareAction";
import {ShareActionConstants} from "../../../../Constants/ShareConstants";
import BaseServiceApi from 'BaseServiceApi';
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
let isinit = false;

class ExplorerExternalList extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.keyCodePressed = null;
    this.listStoreStateChange = this.listStoreStateChange.bind(this);   
    this.state = {
    explorerTree: [{
      isFolder: true,
        listType: 7,
        name: "EVENTS",
        expandNode: true
      },{
        isFolder: true,
        listType: 6,
        name: "TIME SERIES",
        expandNode: true
      }]
    }
  }
  UNSAFE_componentWillMount() {
    ListStore.addChangeListener(this.listStoreStateChange);
    this.props.handleUpdateLoader(true);       
    if (!isinit) {
      this.props.initExternalLists(true);
      isinit = true;
      this.props.setLoading(true);
    } else {
      let {source,rowSource} = this.props;
      const sourceCount = source.count ? source.count : '';
      ListActions.setSelectedExternalListInfo(source.listId, source.listName, rowSource.length, sourceCount, source.lastUploadDateTime);
    }
  }
  componentDidMount() { 
   if(this.props.isUpdated){
    let selectedListItem = ListStore.getSelectedListItem();    
      if(selectedListItem){
        ListExplorerUtil.getScrollPositionDe(selectedListItem.SelectedListId);
      }
    }    
   }
   componentDidUpdate() {     
   let selectedListItem = ListStore.getSelectedListItem();
   if(this.props.isUpdated){
      if(this.props.nodeToRename){
        ListExplorerUtil.getScrollPositionDe(this.props.nodeToRename); 
      }else if(!StringUtil.isEmpty(selectedListItem) && selectedListItem.SelectedListId !="" ){
        ListExplorerUtil.getScrollPositionDe(selectedListItem.SelectedListId);   
       }

     let listExplorereScrollBar = document.getElementById("dataExternalVerticalScroll");
     if(listExplorereScrollBar !=null && listExplorereScrollBar.scrollTop){
      if(listExplorereScrollBar.scrollTop !=0)
       dispatch(isUpdated(false));
     }
  }  
  this.props.setLoading(false);
 }

  componentWillUnmount() {
    ListStore.removeChangeListener(this.listStoreStateChange);
    this.props.unloadState();          
  }
  listStoreStateChange() {
    const currentAction = ListStore.getCurrentAction();
    const state = ListStore.getState();
     if (currentAction === ListExplorerConstants.ActionTypes.LIST_PROPERTY_TOGGLE) {
      if (state.SelectedTabKey === ListManagerTabType.External) {
        this.forceUpdate();
      }
    }
  } 
  handleClick = (e) => {
    dispatch(isUpdated(false)); 
    let selectedNodes = [];
    const listType = this.props.activeListData.listType ? this.props.activeListData.listType : this.props.activeListData.dsType;
    if (e.shiftKey && this.props.activeListData.listId) {
      let activeindex, selectedIndex;
      //return if another folder is selected
      if(e.target.getAttribute('data-type-id') != listType) return;

      const allLists = this.props.explorerListItems.find(itm => itm.listType == listType);
      for (let listIndex = 0; listIndex < allLists.childNodes.length; listIndex++) {
        (allLists.childNodes[listIndex].nodeId.low === this.props.activeListData.listId) ? activeindex = listIndex : activeindex = activeindex;
        (allLists.childNodes[listIndex].nodeId.low == e.target.getAttribute('data-node-id')) ? selectedIndex = listIndex : selectedIndex = selectedIndex;
      }
      if (activeindex < selectedIndex) {
        for (let listIndex = activeindex; listIndex <= selectedIndex; listIndex++) {
          if (allLists.childNodes[listIndex].nodeId.low != this.props.activeListData.listId) {
            selectedNodes.push(allLists.childNodes[listIndex].nodeId.low);
          }
        }
      } else {
        for (let listIndex = selectedIndex; listIndex <= activeindex; listIndex++) {
          if (allLists.childNodes[listIndex].nodeId.low != this.props.activeListData.listId) {
            selectedNodes.push(allLists.childNodes[listIndex].nodeId.low);
          }
        }
      }
      this.props.setNodes(selectedNodes);
    } else if (e.ctrlKey || e.metaKey && this.props.activeListData.listId) {
      let selNodes = ListStore.externalDataSelectedNodes;
      //return if another folder is selected
      if(e.target.getAttribute('data-type-id') != listType) return;

      let  checkNodeExist = selNodes.filter((item) => item == e.target.getAttribute('data-node-id'))
      if(checkNodeExist.length) {
        selNodes = selNodes.filter((item) => item != e.target.getAttribute('data-node-id'));
      } else{
        let nodeId = e.target.getAttribute('data-node-id');
        selNodes.push(parseInt(nodeId));
      }
      selNodes = selNodes.filter((item) => item != this.props.activeListData.listId);
      this.props.setNodes(selNodes);
    } else {
      this.props.setNodes(selectedNodes);
      if(this.props.activeListData.listId != e.target.getAttribute('data-node-id')) {
        GridStore.setScrollPos(0,0);
        this.props.handleUpdateLoader(true)
        this.props.loadListData(e.target.getAttribute('data-node-id'),true);
      }
    }
  }
  
  onContextMenuClick = (e) => {
    const dataNodeId = +e.target.getAttribute('data-node-id');
    const listTypeId = +e.target.getAttribute('data-type-id');
    const isSharedList = +e.target.getAttribute('data-shared');
    if(!dataNodeId) return;
    if(!this.props.selectedNodes.includes(dataNodeId) && dataNodeId != parseInt(ListStore.activeExternalNode.listId)){
      dispatch(setSelectedNodes([]));
    }
    const dataSetList = this.props.explorerListItems.find(itm => !isSharedList ? itm.listType == listTypeId : itm.listType == listTypeId); // + 2
    const rowlist = _.find(dataSetList.childNodes, (item) => item.nodeId.low == dataNodeId);
    this.props.setRowlist(rowlist);
  }

  onBlockMenuItemLeftClicked = (option) => {    
    const selectedNode = this.props.selectedNode;
    const selectedNodeId = selectedNode.nodeId.low;
    let selectedListType = selectedNode.listType;
    const selectedListName = selectedNode.name;
    switch (option.key) {
      case 'RENAME':        
        this.props.setRenameNode(selectedNodeId,selectedListType);
        break;
      case 'DELETE':
      case 'REMOVE':
        this.deleteExternalLists(selectedNodeId,selectedListType);
        this.props.setRowlist('');
        break;
      case 'DUPLICATE':
        selectedListType = selectedListType > 7 ? selectedListType - 2 : selectedListType;
        let listName = undefined;      
        if (this.props.selectedNode) {        
          listName = this.props.selectedNode.name;
        }
        else {        
          listName = ListStore.activeExternalNode.listName;
        }
        // const nodesToSearch = this.props.explorerListItems.find(itm => itm.listType == selectedListType);
        // listName = ListExplorerStore.validateExternalListName(nodesToSearch.childNodes, listName);
        this.props.setDuplicateNode(selectedNodeId,selectedListType,listName); 
        break;
      case 'SHARE':
          let nodesToShare = [];
          const selNodes = ListStore.externalDataSelectedNodes;
          nodesToShare.push(...selNodes);
          const currActive = parseInt(ListStore.activeExternalNode.listId);
          nodesToShare.push(currActive);
          nodesToShare = nodesToShare.filter((itm, index) => {
            return nodesToShare.indexOf(itm) === index;
          });
          let nodeIds = undefined;
          let nodeNames = undefined;
          const {explorerListItems} = this.props;
          const currentFold = explorerListItems.find(itm => itm.listType == selectedListType)
          if (nodesToShare.length > 1) {
              currentFold.childNodes.map((item) => {
                if(nodesToShare.includes(item.nodeId.low)){
                  nodeIds = nodeIds ? `${nodeIds};${item.nodeId.low}` : `${item.nodeId.low}`;
                  nodeNames = nodeNames ? `${nodeNames},${item.name}` : `${item.name}`;
                }
                  return item;
              });
          }
          else {
              nodeIds = selectedNodeId;
              nodeNames = selectedListName;
          }
        const sharedListData = {
          showSharedDialog: true,
          id: nodeIds,
          name: nodeNames,
          action: ShareActionConstants.SHARE_EXTERNAL_DATA,
          IncludeAllUsers: 0,
          ntid: BaseServiceApi.rayData["ListType"].TIMESERIES_List
        };
        updateStorewithSharedData(sharedListData);
        break;
      default:
        break;
    }
  }

  getChildHoverClass() {
    return 'child-item child-hover';
  }

  getSelectedListClass() {
    return 'child-item child-hover-selected active';
  }
  onselect(input) {
    if (input)
      input.select();
  }

  removeRenameNode(evt) {
    const newNodeName = StringUtil.trim(evt.target.value);
    const duplicateNode = this.props.duplicateNode;
    
    let nodeIdToRename = "";
    if (this.props.selectedNode && this.props.selectedNode.nodeId == undefined) {
      nodeIdToRename = this.props.selectedNode.listId;
    }else if(this.props.nodeToRename){
      nodeIdToRename = this.props.nodeToRename;
    }else {
      nodeIdToRename = this.props.selectedNode.nodeId.low;
    }
    // if ((duplicateNode == undefined || duplicateNode == '') && this.props.selectedNode != '' && this.props.selectedNode.name == undefined) {
    //   this.props.selectedNode.name = this.props.source.listName;
    // }
    // let nodeName = !StringUtil.isEmpty(duplicateNode) && duplicateNode !== newNodeName ?
    //   newNodeName : StringUtil.isEmpty(newNodeName) ?
    //     this.props.selectedNode.name : newNodeName !== this.props.selectedNode.name ?
    //       newNodeName : this.props.selectedNode.name;
    let nodeName = !StringUtil.isEmpty(newNodeName) ? newNodeName : this.props.selectedNode !=null ? this.props.selectedNode.name : '';
    if (this.props.selectedNode) {
      if (this.props.selectedNode.name != newNodeName) {
        const searchNodes = this.props.explorerListItems.find(folds => folds.listType == this.props.selectedNode.listType);
        const nodesToSearch = searchNodes.childNodes.filter(item => item.nodeId.low != nodeIdToRename);
        nodeName = ListExplorerStore.validateExternalListName(nodesToSearch, newNodeName);
      }
    }
    if (duplicateNode) {
      if (StringUtil.isEmpty(nodeName)) {
        if (this.props.selectedNode) {
          nodeName = ListExplorerStore.validateExternalListName(this.props.explorerListItems, this.props.selectedNode.name);
        } else {
          nodeName = ListExplorerStore.validateExternalListName(this.props.explorerListItems, ListStore.activeExternalNode.listName);
        }
      }
      const newName = ListExplorerStore.validateExternalListName(this.props.explorerListItems, nodeName);
      let alertMsg = undefined;      
      if (this.props.selectedNode) {        
        alertMsg = this.props.selectedNode.name;
      }
      else {        
        alertMsg = ListStore.activeExternalNode.listName;
      }
      dispatch(saveDuplicateNode(newName, duplicateNode, alertMsg));
      this.props.setDuplicateNode('');
      this.props.setRowlist('');
    } else {
      const sourceNodes = this.props.explorerListItems.find(folds => folds.listType == this.props.selectedListType);
      const rowlist = sourceNodes.childNodes.filter(item => item.nodeId.low == nodeIdToRename);
      if (!StringUtil.isEmpty(newNodeName) && nodeName && rowlist && rowlist[0] && rowlist[0].name != newNodeName) {
        dispatch(saveRenameNode(nodeIdToRename, nodeName, -1));        
         // updating the edited name to settings which will be reflected in the NavSymbol - price context menu
         this.props.updateToSettings(nodeIdToRename, true, false, false, nodeName);
        //  this.props.setRowlist('');
         this.props.setRenameNode('');
      } else {
        this.props.setRenameNode('');
      }
    }
  }

  handleBlurRename = (e) => {
    if (this.keyCodePressed !== 13 && e.type === 'blur') {      
        e.target.focus();
        this.removeRenameNode(e);     
    }
    else {
      this.removeRenameNode(e);
    }
  }



  handleOnKeyup = (e) => {    
    if (KeyCodes.ENTER === e.keyCode) {
      this.keyCodePressed = e.keyCode;
      this.removeRenameNode(e);
    }
  }

  handleOnKeyDown = (e) => {
    const self = e.target;
    self.size = (self.value.length > 1) ? self.value.length : 1;
  }

  getRenameNode(entry, actionType = "rename") {
    let defaultValueForInput = entry.name;
    const nodesToSearch = this.props.explorerListItems.find(itm => itm.listType == entry.listType)
    if (actionType == "duplicate") {
      defaultValueForInput = ListExplorerStore.validateExternalListName(nodesToSearch.childNodes, entry.name);
      ListExplorerUtil.getScrollPositionDe(this.props.duplicateNode);
    }

    let renamenode = 
    // <span>
      <input
        style={{ height: "21px", minWidth: "6px" }}
        //, maxWidth: "210px"
        className={`renameClass clearButton `}
        maxLength="150"
        type="text"
        size={defaultValueForInput.length}
        defaultValue={defaultValueForInput}
        onBlur={this.handleBlurRename}
        onKeyUp={this.handleOnKeyup}
        onKeyDown={this.handleOnKeyDown}
        ref={this.onselect}
        autoComplete="off"
      />
    {/* </span> */}
    return renamenode;
  }

  deleteExternalLists(selectedNodeId,listType='') {
    // const listType = this.props.selectedNode ? this.props.selectedNode.listType : selectedListType;
    let allLists = this.props.explorerListItems.find(itm => itm.listType == listType);
    if(!allLists) return;
    if (ListStore.externalDataSelectedNodes.length) {
      let activeindex, selectedFirstIndex, selectedLastIndex, nextActiveNodeIndex, FirstIndex, prevActiveNodeIndex;
      let deleteNodes = ListStore.externalDataSelectedNodes;
      let lastIndexSelectedNode = ListStore.externalDataSelectedNodes.length - 1;
      for (let listIndex = 0; listIndex < allLists.childNodes.length; listIndex++) {
        (allLists.childNodes[listIndex].nodeId.low == deleteNodes[lastIndexSelectedNode]) ? selectedFirstIndex = listIndex : selectedFirstIndex = selectedFirstIndex;
        (allLists.childNodes[listIndex].nodeId.low == ListStore.activeExternalNode.listId) ? activeindex = listIndex : activeindex = activeindex;
      }
      (selectedFirstIndex > activeindex) ? deleteNodes.unshift(ListStore.activeExternalNode.listId) : deleteNodes.push(ListStore.activeExternalNode.listId);
      const lastDeleteNodeIndex = deleteNodes.length - 1;
      for (let listIndex = 0; listIndex < allLists.childNodes.length; listIndex++) {
        (allLists.childNodes[listIndex].nodeId.low == deleteNodes[lastDeleteNodeIndex]) ? selectedLastIndex = listIndex : selectedLastIndex = selectedLastIndex;
        (allLists.childNodes[listIndex].nodeId.low == deleteNodes[0]) ? FirstIndex = listIndex : FirstIndex = FirstIndex;
      }
      nextActiveNodeIndex = selectedLastIndex + 1;
      prevActiveNodeIndex = FirstIndex - 1;

      dispatch(removeDeletedLists(deleteNodes,listType));
      let nextActiveNode = allLists.childNodes[nextActiveNodeIndex];
      if (!nextActiveNode) {
        nextActiveNode = allLists.childNodes[prevActiveNodeIndex];
        if (!nextActiveNode) {
          for (let listIndex = 0; listIndex < deleteNodes.length; listIndex++) { 
            allLists.childNodes = allLists.childNodes.filter((item) => item.nodeId.low != deleteNodes[listIndex]);
          }
          nextActiveNode = allLists.childNodes[0];
        }
      }
      if (nextActiveNode) {
        dispatch(loadExternalList(nextActiveNode.nodeId.low,true));
        ListStore.updateSelectedListItem(nextActiveNode.nodeId.low, nextActiveNode.nodeId.low, nextActiveNode.name);
      } else {
        if(listType > 7){
          dispatch(findNextFolder(listType));
        }
        // dispatch(loadExternalList('',false,listType));  
        // ListActions.setSelectedExternalList('');      
      }
      ListActions.deleteExternalList(deleteNodes);
      let selectedNodes = [];
      dispatch(setSelectedNodes(selectedNodes));
    } else {
      if (ListStore.activeExternalNode.listId == selectedNodeId || !selectedNodeId) {
        let activeindex;
        for (let listIndex = 0; listIndex < allLists.childNodes.length; listIndex++) {
          (allLists.childNodes[listIndex].nodeId.low === ListStore.activeExternalNode.listId) ? activeindex = listIndex : activeindex = activeindex;
        }
        let nextActiveNodeIndex = activeindex + 1;
        let nextActiveNode = allLists.childNodes[nextActiveNodeIndex];
        if (!nextActiveNode) {
          nextActiveNodeIndex = activeindex - 1;
          nextActiveNode = allLists.childNodes[nextActiveNodeIndex];
        }
        let deleteNodes = [];
        deleteNodes.push(ListStore.activeExternalNode.listId);
        dispatch(removeDeletedLists(deleteNodes,listType));
        ListActions.deleteExternalList(ListStore.activeExternalNode.listId, ListStore.activeExternalNode.listName);
        if (nextActiveNode) {
          dispatch(loadExternalList(nextActiveNode.nodeId.low,true));
          ListStore.updateSelectedListItem(nextActiveNode.nodeId.low, nextActiveNode.nodeId.low, nextActiveNode.name);
        } else {
          if(listType > 7){
            dispatch(findNextFolder(listType));
            // dispatch(loadExternalList(''))
            // ListActions.setSelectedExternalList('');
          }
        }
        let selectedNodes = [];
        dispatch(setSelectedNodes(selectedNodes))
      } else {
        // allLists.childNodes = allLists.childNodes.filter((item) => item.nodeId.low == selectedNodeId);
        let deleteNodes = [];
        deleteNodes.push(selectedNodeId);
        dispatch(removeDeletedLists(deleteNodes,listType));
        
        const selectedNode = this.props.selectedNode;
        const selectedListName = selectedNode.name;

        ListActions.deleteExternalList(selectedNodeId, selectedListName);
      }
    }
    // let sortedList= ListStore.sortExternalData(this.props.explorerListItems);
    
    // dispatch(sortExternalList(sortedList));
  }

  handleUploadClick = (e) => {
    e.stopPropagation();
    this.props.reuploadData(e.target.getAttribute('data-node-id'));
  }

  uploaderDialogClose = () => {
      this.props.uploaderDialogChange(false);
  }
  nestedTree(entry, key) {
    let selectedListClass, selectedListCheck = '';
    const ExternalDataListMenu = getListMenu(this.props.selectedNodes,entry.shareAccess,entry.listType);
    const isNotEmpty = !entry.isEmpty && entry.isEmpty != null && entry.shareAccess !== ShareAccessType.SHARE_READONLY;
    let activeClass = this.getChildHoverClass();
    if (ListStore.externalDataSelectedNodes.length) {
      selectedListCheck = ListStore.externalDataSelectedNodes.filter((itm) => itm == entry.nodeId.low);
    }
    (selectedListCheck.length && entry.nodeId.low != this.props.activeListData.listId) ? selectedListClass = this.getSelectedListClass() : selectedListClass = '';
    const isClickMenuVisible = entry.nodeId && entry.nodeId.low ? true : false;
    if (entry.nodeId.low == this.props.activeListData.listId) activeClass += ' active';
    (selectedListClass) ? activeClass = '' : activeClass = activeClass;
    const name = entry.name;   
    let nodeType=null;   
    const { duplicateNode, nodeToRename } = this.props;
    let classRename;
    if (duplicateNode === entry.nodeId.low) {
      nodeType = 'duplicate';
      if(entry.nodeId.low !== this.props.activeListData.listId){
        activeClass = '';        
      }
      classRename = `${this.props.fontSizeClass} ${selectedListClass}`;      
    }
    else if (nodeToRename === entry.nodeId.low) { 
      nodeType = 'rename';
      classRename = `${this.props.fontSizeClass} ${activeClass} ${selectedListClass}`;
    }
    const className = `${this.props.fontSizeClass} ${activeClass} ${selectedListClass}`;  
    
    if (duplicateNode === entry.nodeId.low || nodeToRename === entry.nodeId.low) {      
      return (
        <div key={key}>
          {duplicateNode === entry.nodeId.low &&           
              <div data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess} className={`child-item ${className}`} onClick={this.handleClick}>
                <span className='listCategoryClass list-no-whiteSpace list-no-Border' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}>
                {!entry.isReccuring && <i className='userList fa icn-fa-file-text-userlist' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}></i>}
            {entry.isReccuring && <i className='userList fa icn-fa-file-text-reccuringlist' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}></i>}
                  <span className="edit-listname" data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess} >{name}</span>
                  {((isNotEmpty && entry.shareAccess !== ShareAccessType.SHARE_READONLY) || entry.isReccuring) && <span className="icn-ext-upload" draggable={false} ></span> }
                </span>                
            </div>
          }
          <div data-node-id={entry.nodeId.low} className={`child-item ${classRename}`}>
            <span className='listCategoryClass list-no-whiteSpace list-no-Border' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}>
            {(!entry.isReccuring || nodeType==='duplicate')  && <i className='userList fa icn-fa-file-text-userlist' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}></i>}
            {entry.isReccuring && nodeType==='rename' && <i className='userList fa icn-fa-file-text-reccuringlist' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}></i>}
              <span className="edit-listname" data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess} >
                {this.getRenameNode(entry, nodeType)}
              </span>
              {(isNotEmpty || entry.isReccuring) && <span className="icn-ext-upload" draggable={false} ></span> }
            </span>
          </div>
        </div>
      );
    }    
    else {
      const ownername =  (entry.ownerUserName && entry.shareAccess > 0) ? ` (${entry.ownerUserName})` : '';
      return (
        <div key={key} data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess} className={className} onClick={this.handleClick}>
          <span className='listCategoryClass' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}>
            {!entry.isReccuring && <i className='userList fa icn-fa-file-text-userlist' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}></i>}
            {entry.isReccuring && <i className='userList fa icn-fa-file-text-reccuringlist' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}></i>}
            <span className='text' data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess}>{name}
            {ownername && <span style={{ fontStyle: 'italic' }} data-node-id={entry.nodeId.low} data-type-id={entry.listType}>{ownername}</span>}
            </span>
          </span>
          {(isNotEmpty || entry.isReccuring) && <span className="icn-ext-upload" draggable={false} data-node-id={entry.nodeId.low} data-type-id={entry.listType} data-shared={entry.shareAccess} onClick={this.handleUploadClick}></span>}
          {
            isClickMenuVisible &&
            <ClickMenu data-disable-track-price="true" items={ExternalDataListMenu} undoActionObj = {this.props.undoActionObj} externalDataMenu="externalDataMenu" position="bottom" onselect={(option) => this.onBlockMenuItemLeftClicked(option)} />
          }
        </div>
      );
    }
  }

  handleShortcuts = (key, event) => {    
    switch (key) {
      case 'DELETE_ROW':
        if(!this.props.nodeToRename && !this.props.duplicateNode && !this.props.isCreateList && !this.props.disableDelete) {
          // const selectedNode = this.props.selectedNode;
          const selectedNodeId = this.props.activeListData.listId;
          const selectedListType = this.props.activeListData.listType ? this.props.activeListData.listType : this.props.activeListData.dsType;
          this.deleteExternalLists(selectedNodeId,selectedListType);
          event.stopPropagation();
          event.preventDefault(); 
        }
        break;
      case 'RENAME': 
      if(!this.props.isCreateList){
        const NodeToRename = ListStore.activeExternalNode;
        NodeToRename.name = NodeToRename.listName;
        const listTypeId = NodeToRename.listType;
        if(listTypeId > BaseServiceApi.rayData["ListType"].EVENTSERIES_List) return;
        dispatch(nodeSelection(NodeToRename));
        dispatch(setRenameNode(NodeToRename.listId)); 
      }      
        break;
      default:
        return;
    }
  }
  handleKeyPressed = (e) => {
    if (KeyCodes.ENTER === e.keyCode) {      
      if(this.props.undoActionObj && this.props.undoActionObj.action == ListExplorerConstants.ActionTypes.UNDO_EXTERNAL_LIST){     
        ListActions.isDeleteUndoExistExternal();
      }
      let newNodeName = StringUtil.trim(e.target.value);
      let nodeType = '';      
      if(StringUtil.isEmpty(newNodeName)){
        newNodeName = 'Untitled Data Set';
      }
      if(e.target.getAttribute('data-listtype')){
        nodeType = e.target.getAttribute('data-listtype');
      }
      this.props.createNewListName(newNodeName,nodeType);
    }
  }
  handleBlur = (e) => {
    if (e.relatedTarget == null || (e.relatedTarget && e.relatedTarget.className != 'close' && e.relatedTarget.className != 'undo-clicked')) {
      if (e.type == 'blur') {
        if(this.props.undoActionObj && this.props.undoActionObj.action == ListExplorerConstants.ActionTypes.UNDO_EXTERNAL_LIST){    
          ListActions.isDeleteUndoExistExternal();
        }
        let newNodeName = StringUtil.trim(e.target.value);
        let nodeType = '';
        if (StringUtil.isEmpty(newNodeName)) {
          newNodeName = 'Untitled Data Set';
        }
        if(e.target.getAttribute('data-listtype')){
          nodeType += e.target.getAttribute('data-listtype');
        }
        if(!this.props.disableDelete){
          this.props.createNewListName(newNodeName,nodeType);
        }
      }
    } else {
      if (e.relatedTarget.className == 'close' || e.relatedTarget.className == 'undo-clicked') {
        e.target.select();
      }
    }

  }
  handleFocus = (e) => {
    e.target.select();
  }
  onListNameChange = (e) => {
    let newNodeName = e.target.value;
    this.props.onChangeListName(newNodeName);
  }
  toggleFolder = (listType,status) => {
    this.props.isApiComplete ? this.props.toggleFolderTree(listType,status) : '';
  }
  folderNode(folder,key){
    let innerTree = '';
    if (folder != null && folder.childNodes.length > 0) {
      innerTree = folder && folder.childNodes.map((entry, key) => this.nestedTree(entry, key));
    }else{
      if(folder.listType == BaseServiceApi.rayData["ListType"].TIMESERIES_List || folder.listType == BaseServiceApi.rayData["ListType"].EVENTSERIES_List)
      innerTree = <div data-node-id={-1} className={"child-hover-selected medium-normal child-item"} >
            <span className='listCategoryClass list-no-whiteSpace list-no-Border 4' style={{marginLeft: "20px" }} data-node-id={-1}>
              <i className='userList fa icn-fa-file-text-userlist' data-node-id={-1}></i>
              <span className="edit-listname" data-node-id={-1} >
                <span> <i></i>
                  <input type="text" style={{height: '22px'}} data-listtype={folder.listType} onBlur={this.handleBlur} autoFocus onKeyUp={this.handleKeyPressed} onFocus={this.handleFocus} className="medium-normal renameClass clearButton" onChange={this.onListNameChange} value={this.props.newListName} />
                </span>
              </span>
            </span>
          </div>
    }
      return (<React.Fragment key={key}><div onClick={() => this.toggleFolder(folder.listType,!folder.expandNode)} className="tree-view_item h24 child-hover" draggable="false">
        <div className={folder.expandNode ? "icn-tree-view_arrow" : "icn-tree-view_arrow icn-tree-view_arrow-collapsed"} ></div>
        <span className="tree-node categoryClass list-no-whiteSpace " >{folder.name}</span>
      </div>
      <div className="listExplorerClass new-list-exp folder-level-2">{folder.expandNode && innerTree}</div></React.Fragment>);
  }
  getExplorerNodes(explorerListItems = []){
    this.state.explorerTree.map(folders =>
      folders.childNodes = explorerListItems.filter(itm => itm.listType == folders.listType)
    );
    return this.state.explorerTree;
  }
  render() {
    let { explorerListItems, showListLoader, newListName, uploadDialogShow, showWizardDialogError,fileExceptionDialogShow, wizardDialogShow } = this.props;
    let resTree = null;
    if (explorerListItems != null && explorerListItems.length > 0) {
      resTree = explorerListItems.map((entry, key) => this.folderNode(entry, key));
    } else if (explorerListItems.length == 0 && !showListLoader) {
      return (
        <div data-node-id={-1} className={"child-hover-selected medium-normal child-item"} >
          <span className='listCategoryClass list-no-whiteSpace list-no-Border 4' style={{marginLeft: "20px" }} data-node-id={-1}>
            <i className='userList fa icn-fa-file-text-userlist' data-node-id={-1}></i>
            <span className="edit-listname" data-node-id={-1} >
              <span> <i></i>
                <input type="text" style={{height: '22px'}}  onBlur={this.handleBlur} autoFocus onKeyUp={this.handleKeyPressed} onFocus={this.handleFocus} className="medium-normal renameClass clearButton" onChange={this.onListNameChange} value={newListName} />
              </span>
            </span>
          </span>
        </div>
      );
    } else {
      return (<div className='loading loading-spinner'></div>);
    }
    return (
      <div className='middel-inner'>
        <div className="custom-scroll">
          <div id='dataExternalVerticalScroll'>
            <Shortcuts name='NAV_LIST_MANAGER' handler={this.handleShortcuts} targetNodeSelector=".listExplorerClass" isolate={true} handleSelection={this.props.loadListData}  renameNode ={this.props.nodeToRename}>
              <div id="content" className="listExplorerClass new-list-exp ext-upload-tree" onClick={this.handleFolderClick} onContextMenu={this.onContextMenuClick}>
                {resTree}
                <img alt="" id="ghostImage" src="/Asset/images/Blank-Canvas.png" style={{ visibility: 'hidden' }} />
                <div id="list-drag-ghost-image"></div>
              </div>
            </Shortcuts>
            <ScrollBar scrollId="dataExternalVerticalScroll" vScroll={true} />
            {/*  scrollOnHover={true} */}
          </div>
        </div>
        <ExternalDataUploadDialog show={uploadDialogShow} onHide={this.uploaderDialogClose} />
        <ExceptionReport />
        {(wizardDialogShow || showWizardDialogError) && <ExternalDataUploadWizard />}
        {fileExceptionDialogShow && <ExternalDataFileError />}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  initExternalLists: (firstCall) => dispatch(initExternalLists(firstCall)),
  loadListData: (listId,init) => dispatch(loadExternalList(listId,init)),
  setRowlist: (listId) => dispatch(nodeSelection(listId)),
  setNodes: (selectedNodes) => dispatch(setSelectedNodes(selectedNodes)),
  createNewListName: (listName,nodeType) => dispatch(createListEmptyList(listName,nodeType)),
  setRenameNode: (nodeToRename, selectedListType) => dispatch(setRenameNode(nodeToRename, selectedListType)),
  unloadState: () => dispatch(unloadStateExternal()),
  setDuplicateNode: (duplicateNode,selectedListType,alertMsg) => dispatch(setDuplicateNode(duplicateNode,selectedListType,alertMsg)),
  onChangeListName: (name) => dispatch(changeListName(name)),
  handleUpdateLoader: (value) => dispatch(handleUpdateLoader(value)),
  updateToSettings: (listId, isRename, isDelete, isUpload, name) => dispatch(updateToSettings(listId, isRename, isDelete, isUpload, name)),
  uploaderDialogChange : (data) => dispatch(showHideUploadDialog(data)),
  reuploadData: (listId) => dispatch(uploadListRepeat(listId)),
  toggleFolderTree: (typeId,status) => dispatch(toggleListView(typeId,status))
})
const mapStateToProps = ({ externalDataUploader }) => {
  const { explorerListItems, showListLoader, selectedNode, selectedNodes, nodeToRename, newListName, duplicateNode,
    uploadDialogShow,wizardDialogShow,showWizardDialogError,isUpdated,fileExceptionDialogShow,isCreateList,
    selectedListType,isApiComplete  } = externalDataUploader.ExternalDataUploaderReducer;
  const {  source,rowSource,disableDelete } = externalDataUploader.ExternalDataListViewReducer;
  return ({ source, rowSource, explorerListItems, showListLoader, selectedNode, selectedNodes, nodeToRename, newListName, duplicateNode,
    uploadDialogShow,wizardDialogShow,showWizardDialogError,isUpdated,fileExceptionDialogShow,isCreateList, selectedListType,
    isApiComplete,disableDelete });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExplorerExternalList);