import React, { Component } from "react";
import BaseServiceApi from 'BaseServiceApi';
import { PopupTypes } from "../../Constants/ScheduledListsConstants";
import _ from "underscore";
import ScrollBar from "ScrollBar";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import listExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import panarayDefault from "../../ServiceApi/RequestHelper/PanarayDefault";

let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let ListType = BaseServiceApi.rayData["ListType"];
let type;

export default class Universe extends Component {
    constructor(props) {
        super(props);
        this.updateScrollBar = this.updateScrollBar.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);
        this.getSelNodes = this.getSelNodes.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);
        // let defaultSelQueue = [3, 121, 126, 1243]
        this.state = {
            selectedItems: [],
        }

        this.selItems = [];

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.dismissPanel, false);
        if (this.props.selectedNode && this.props.selectedNode != -1) {
            //get the chain of nodes for displaying.
            let nodeId = this.props.selectedNode;
            this.selItems = [];
            this.getSelNodes(this.props.source, nodeId);
            this.setState({
                selectedItems: [...this.selItems, nodeId]
            })
        }
        else if(this.props.selectedNode && this.props.selectedNode == -1){
            let nodeId = this.props.selectedNode;
            this.selItems = [];
            this.getSelNodes(this.props.source, nodeId);
            this.setState({
                selectedItems: [3,121,126]
            })
        }
        else {
            //S&P 500 and it's parents nodes.
            this.setState({
                selectedItems: [3, 121, 126, panarayDefault?.defaultListID]
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.dismissPanel, false);
    }

    static getDisplayName(listId, source) {
        if(listId.low === -1 || listId === -1) {
            return(LocalizationStore.getTranslatedData("ss_ond","O'Neil Database(ODB)"));
        }

        for (let i = 0; i < source.length; i++) {
            if(listId === source[i].nodeId.low || listId.low === source[i].nodeId.low){
                return LocalizationStore.getTranslatedData(listExplorerStore.getListTreeItemNameResKey(source[i].name,source[i].listType),source[i].name);
            }

            let result = Universe.getDisplayName(listId, source[i].childNodes);
            if (result) {
                return result;
            }
        }
    }


    onItemSelected(nodeId, subject) {
        console.log("onItemSelected: %s, %s", nodeId, subject);
        //TODO save nodeId to database.
        if (this.props.onSelected) {
            let result = { displayName: subject, id: nodeId };
            this.props.onSelected(PopupTypes.Universe, result);
        }
    }

    //get the queue nodes about the selected item and its parents.
    //we will expand the node according to the values.
    getSelNodes(children, selItmId) {
        if (children && children.length > 0) {
            for (let i = 0; i < children.length; i++) {
                if (children[i].nodeId.low === selItmId) {
                    return true;
                }

                let found = this.getSelNodes(children[i].childNodes, selItmId);
                if (found) {
                    this.selItems.push(children[i].nodeId.low)
                    return found;
                }
            }
        }
    }

    updateScrollBar() {
        this.forceUpdate();
      }


    dismissPanel(e) {
        //close this panel if it is out of this component is clicked.
        if (this.nodes && !this.nodes.contains(e.target)) {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        }
    }

    render() {
        type = this.props.type;
        return (
            <div className="scheduled-lists universe" ref={(node) => this.nodes = node}>
                {type != "Add List" ?
                    <React.Fragment>
                        <div className="title">{LocalizationStore.getTranslatedData("ss_selectuniverse", "Select a Universe:")}</div>
                        <div className="ONeilBlock" onClick={() => this.onItemSelected(-1, LocalizationStore.getTranslatedData("ss_ond", "O'Neil Database(ODB)"))}>
                            {(this.props.selectedNode && this.props.selectedNode == -1) ? <span className="icn-right-icon"></span> : null}
                            {LocalizationStore.getTranslatedData("ss_ond", "O'Neil Database(ODB)")}
                        </div>
                    </React.Fragment> : ''}

                <div className="tree-content" >
                    <div className="custom-scroll-light">
                        <div className="custom-scroll">
                            {/* <div id="ScheduledListItemODBScroll" className="top1" onMouseUp={this.updateScrollBar}> */}
                            <div className="universe-scroll" id="ScheduledListItemODBScroll" onMouseUp={this.updateScrollBar}>
                                {this.props.source ? <TreeView children={this.props.source}
                                    level={0}
                                    collpase={false}
                                    onSelected={this.onItemSelected}
                                    selectedItems={type != "Add List" ? this.state.selectedItems : ''} onClickListAdd={type == "Add List" ? this.props.onClickListAdd : ''} /> : ""}
                                <ScrollBar scrollId="ScheduledListItemODBScroll" vScroll={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


class TreeView extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const { children } = this.props;
        let marginLeft = this.props.level * 10;
        let keepIdent = { marginLeft: `${marginLeft}px` }

        return <ul className="tree-ul" style={keepIdent} data-name={this.props.name}>
            {
                //display the first level, category lists.
                children.map((item, index) => {
                    let collpase = !_.find(this.props.selectedItems, (selItm) => selItm === item.nodeId.low);

                    return <TreeViewItem
                        name={item.name = LocalizationStore.getTranslatedData(listExplorerStore.getListTreeItemNameResKey(item.listType === ListType.CATEGORY_List ? item.name.toUpperCase() : item.name, item.listType), item.name.toUpperCase())}
                        collpase={collpase}
                        level={0}
                        children={item.childNodes}
                        key={index}
                        listType={item.listType}
                        nodeId={item.nodeId.low}
                        onSelected={this.props.onSelected}
                        selectedItems={this.props.selectedItems}
                        categoryType={item.categoryType}
                        onClickListAdd={type == "Add List" ? this.props.onClickListAdd : ''}
                    />
                })
            }
        </ul>
    }
}


class TreeViewItem extends Component {
    constructor(props) {
        super(props);
        this.onToggle = this.onToggle.bind(this);
        this.getIcon = this.getIcon.bind(this);
        this.getCheckMark = this.getCheckMark.bind(this);
        this.getChildren = this.getChildren.bind(this);
        this.state = {
            collpase: this.props.collpase ? this.props.collpase : false,
            name: this.props.name,
            level: this.props.level,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.collpase !== nextProps.collpase) {
            this.setState({
                collpase: nextProps.collpase
            })
        }
    }

    onToggle = (e, nodeId, name) => {

        if (type == "Add List") {
            if (this.props.listType === 1) {
                this.props.onClickListAdd(this.props.itemInfo);
            } else {
                this.setState({
                    collpase: !this.state.collpase
                });
            }
        } else {
            this.setState({
                collpase: !this.state.collpase
            }, () => {
                if (this.props.listType === 1) {
                    console.log(nodeId);
                    if (this.props.onSelected) {
                        this.props.onSelected(nodeId, name);
                    }
                }
            });
        }
        e.stopPropagation();
    }

    getIcon() {
        let icon = "";
        switch (this.props.listType) {
            case 1:
                if(this.props.categoryType == ListCategoryType.USER_ListCategory || this.props.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory)
                    icon = "tree-view-icon icn-fa-file-text-userlist"
                else if(this.props.categoryType == ListCategoryType.APLIST_ListCategory)
                    icon = "tree-view-icon icn-fa-file-text-ap"
                else
                    icon = "tree-view-icon tree-view-icon-file"
                break;
            case 2:
                icon = "tree-view-icon tree-view-icon-folder";
                break;
            default:
                icon = "tree-view-icon tree-view-icon-arrow";
                break;
        }

        if (!this.state.collpase)
            icon += "-expanded";

        return icon;
    }

    getCheckMark() {
        let checkMark = "";
        let offset = -5 + (this.props.level - 1) * 15
        let marginLeft = { marginLeft: `${offset}px` }
        if (this.props.listType === 1) {
            let tmp = _.find(this.props.selectedItems, (selItem) => selItem === this.props.nodeId);
            if (tmp) {
                checkMark = <span className="icn-right-icon" style={marginLeft}></span>;
            }
        }
        return checkMark;
    }

    getChildren() {
        if (this.props.children && this.props.children.length > 0) {
            return <ul className={this.state.collpase ? "collpase" : "tree-ul"} >
                {
                    this.props.children.map((item, index) => {
                        let collpase = !_.find(this.props.selectedItems, (selItm) => selItm === item.nodeId.low);
                     if (item.categoryType !== ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory) {
                            return <TreeViewItem
                                name={item.name = LocalizationStore.getTranslatedData(listExplorerStore.getListTreeItemNameResKey(item.name, item.listType), item.name)}
                                level={this.props.level + 1}
                                children={item.childNodes}
                                key={index}
                                listType={item.listType}
                                collpase={collpase}
                                nodeId={item.nodeId.low}
                                onSelected={this.props.onSelected}
                                selectedItems={this.props.selectedItems}
                                categoryType={item.categoryType}
                                itemInfo={item}
                                onClickListAdd={type == "Add List" ? this.props.onClickListAdd : ''}
                            />
                        }
                    })
                }
            </ul>
        }
    }

    render() {
        let icon = this.getIcon();
        let paddingLeft = (this.props.level) * 15;
        let indent = { paddingLeft: `${paddingLeft}px` }
        let highLightCss = this.getCheckMark() ? "tree-view-item higt-light" : "tree-view-item"
        let addListClassName = this.props.listType != 1 ? "tree-view-item" : "tree-view-item listitem"
        let addOptionText = this.props.listType === 1 ? "Add" : '';

        return type != "Add List" ?
                (<li className={highLightCss} onClick={(e) => this.onToggle(e, this.props.nodeId, this.props.name)}>
                <a style={indent}>
                    {this.getCheckMark()}
                    <span className={icon}></span>
                    <span className="tree-view-naming ellipsis">{this.props.name}</span>
                </a>
                {this.getChildren()}

                </li>
                ) :
                (<li className={addListClassName} onClick={(e) => this.onToggle(e)}>
                    <a style={indent}>
                        <span className={icon}></span>
                        <span className="tree-view-naming ellipsis">{this.props.name}</span>
                        <span className="addListItem">{addOptionText}</span>
                    </a>
                    {this.getChildren()}
                </li>
            )
        }
    }
