import React from "react";
import { Alert } from "react-bootstrap";
import StringUtil from "StringUtil";
import ListActions from "ListActions";
import ListApi from "ListApi";
import { OwnershipViewConstants } from "../../Constants/OwnershipConstants";
import GridActions from "GridActions";
import GridStore from "GridStore";
import ONeilViewStore from "ONeilViewStore";
import ListManagerTabType from "ListManagerTabType";
import ListStore from "ListStore";
import OwnershipViewStore from "OwnershipViewStore";
import { findNodeByIdAndUpdateDate, saveDuplicateNode } from "../../Actions/ListViewActions";
import store from "../../Redux/store.js";
import { GridConstants } from "GridConstants";
import { ListExplorerConstants } from "ListExplorerConstants";
import BrowserUtil from "BrowserUtil";
import LocalizationStore from 'LocalizationStore';
import ReactHtmlParser from "react-html-parser";
import ConsoleStore from "ConsoleStore";
import _ from 'underscore';
import listStore from "../../Stores/NavModules/NavList/ListStore";
import { listUndoAction,rollBackListreUpload } from "../../Actions/ExternalDataUploaderActions";
import { AnnotationConstant } from '../../Constants/AnnotationConstants';
import { dispatch } from "../../Redux/dispatch.js";
import { createNotImportedList  } from "Actions/ListActions";
import ListExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import LangType from "../../Constants/LangType";
import PortalContainer from 'PortalContainer';
import textWidth from "text-width";
import BaseServiceApi from "BaseServiceApi";
let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
import { undoDeleteAnnotation } from "../../Actions/AnnotationActions";

export default class AlertDismissable extends React.Component {

    constructor(props) {
        super(props);
        this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
        this.handleAlertShow = this.handleAlertShow.bind(this);
        this.undo = this.undo.bind(this);
        this.showSymbols = this.showSymbols.bind(this);
        this.setCellTitle = this.setCellTitle.bind(this);
        this.clearCellTitle = this.clearCellTitle.bind(this);
        this.state = {
            alertVisible: false,
            showSymbols: false
        };
    }

    componentDidUpdate() {
        if (this.state.alertVisible) {
            let notAddedShow = document.getElementById('notAddedSymbol');
            let self = this;
            if (notAddedShow != null) {
                notAddedShow.addEventListener("click", self.showSymbols, false);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.alertMessage !== this.props.alertMessage) {
            this.setState({
                showSymbol: false
            }) 
        }
    }

    showSymbols() {
        this.setState({
            showSymbol: !this.state.showSymbol
        })
    }

    getNotImportedListBulkRequest(symbolsAsString) {
        const InfoProto = BaseServiceApi.rayData["ListInstrumentInfoData"];
        let listInstrumentInfoData = new InfoProto();
        listInstrumentInfoData.symbols = symbolsAsString;
        listInstrumentInfoData.msIds = "";
        listInstrumentInfoData.snapshotDts = "";
    
        const ReqProto = BaseServiceApi.rayData["ListInstrumentsRequestData"];
        let listInstrumentsRequestData = new ReqProto();
        listInstrumentsRequestData.instrumentsInfo = listInstrumentInfoData;
        let outObjBlkData = { bulkInsertData: null };
        outObjBlkData.bulkInsertData = listInstrumentsRequestData.encode();
        return outObjBlkData;
    }

    onItemsMoreClicked(exceptionSymbols) {
        const selectedTab = ListExplorerStore.getSelectedTabType();
        const listName = selectedTab === ListManagerTabType.Oneil ? ListExplorerStore.activeNode.name : OwnershipViewStore.activeNode.name;
        const databaseSourceID = selectedTab === ListManagerTabType.Oneil ? 1 : 2;
        const symbols = exceptionSymbols.join(";");
        const bulkData = this.getNotImportedListBulkRequest(symbols);
        createNotImportedList({
            listName: listName,
            exceptionSymbols: symbols,
            selectedTab: selectedTab,
            databaseSourceID: databaseSourceID,
            bulkData: bulkData.bulkInsertData
          });

        this.handleAlertDismiss();
    }

    undo() {
        switch (this.props.undoActionObj.action) {
            case ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE:
            case ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES:
                if (!StringUtil.isEmpty(this.props.undoActionObj.deleteNodeId)) {
                    BrowserUtil.disableEvents();
                    ListActions.restoreTreeNode(this.props.undoActionObj.deleteNodeId);
                }
                break;
            case ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST:
            case ListExplorerConstants.ActionTypes.ADD_SYMBOLS_TO_LIST:
                if (!StringUtil.isEmpty(this.props.undoActionObj.removeItemsFromUserList) && !StringUtil.isEmpty(this.props.undoActionObj.removeItemsFromUserList.msids)) {
                    const {targetListId, msids, cacheId} = this.props.undoActionObj.removeItemsFromUserList
                    BrowserUtil.disableEvents();
                    ListActions.removeItemsFromUserList(this.props.undoActionObj.removeItemsFromUserList);
                    GridActions.removeGridRows(targetListId, cacheId, msids);
                }
                break;
            case OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_OWNERSHIP:                
                if (!StringUtil.isEmpty(this.props.undoActionObj.removeItemsFromUserList) && !StringUtil.isEmpty(this.props.undoActionObj.removeItemsFromUserList.msids)) {
                    BrowserUtil.disableEvents();
                    const {targetListId, msids, cacheId} = this.props.undoActionObj.removeItemsFromUserList
                    let resMsIDSs = this.props.undoActionObj.removeItemsFromUserList.msids.replace(/;/g, ',');
                    try {
                        ListApi.deleteOwnerInstrumentsRequest(this.props.undoActionObj.removeItemsFromUserList.targetListId, "", resMsIDSs).then((result) => {
                            if (!result.responseHeader.error) {
                                BrowserUtil.enableEvents();
                                findNodeByIdAndUpdateDate(this.props.undoActionObj.removeItemsFromUserList.targetListId, result.responseHeader.asOfDate);
                                GridActions.removeGridRows(targetListId, cacheId, msids);
                            }
                        });
                    }
                    catch (e) {

                    }
                }
                break;
            case OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_BY_DRAG_OWNERSHIP:
                if (!StringUtil.isEmpty(this.props.undoActionObj.removeItemsFromUserList) && !StringUtil.isEmpty(this.props.undoActionObj.removeItemsFromUserList.msids)) {
                    BrowserUtil.disableEvents();
                    const {targetListId, msids, cacheId} = this.props.undoActionObj.removeItemsFromUserList
                    let resMsIDSs = msids.replace(/;/g, ',');
                    try {
                        ListApi.deleteOwnerInstrumentsRequest(this.props.undoActionObj.removeItemsFromUserList.targetListId, "", resMsIDSs).then((result) => {
                            if (!result.responseHeader.error) {                                
                                BrowserUtil.enableEvents();
                                GridActions.removeGridRows(targetListId, cacheId, msids);
                            }
                        });
                    }
                    catch (e) {

                    }
                }
                break;
            case GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST:
                let removedColumn = GridStore.getRemovedColumn();
                window.setTimeout(() => {
                    ListActions.cancelPromise()
                    this.props.setLoadState(true)
                    GridActions.addColumnToList(GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_UNDO, removedColumn.listId, removedColumn.dataItemId, removedColumn.previousColumnDataItemId, removedColumn.isFrozen, removedColumn.isQuoteboard);
                }, 0);
                break;

            case GridConstants.ActionTypes.REMOVE_GRID_ROWS:
                BrowserUtil.disableEvents();
                window.setTimeout(() => {
                    GridActions.removeGridRowsUndo();
                }, 0);
                break;

            case GridConstants.ActionTypes.PASTE_GRID_ROWS:
            case GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER:
                BrowserUtil.disableEvents();
                window.setTimeout(() => {
                    GridActions.pasteGridRowsUndo();
                }, 0);
                break;
            case OwnershipViewConstants.ActionTypes.SET_ALERT:                
                _.each(this.props.undoActionObj.node, (item) => {
                    store.dispatch(saveDuplicateNode(item, item.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory ? item.nodeId.low.toString() : item.parentNodeId.low.toString(), this.props.undoActionObj.dataSource, 'UNDO'));
                });
                break;
            case ListExplorerConstants.ActionTypes.UNDO_EXTERNAL_LIST:
                let nodeIds = listStore.lastDeletedNodesIds.join('; ');
                 ListActions.restoreExternalNodes(nodeIds);
                 dispatch(listUndoAction());
                break;
            case ListExplorerConstants.ActionTypes.UNDO_EXTERNAL_LIST_ROLLBACK:
                dispatch(rollBackListreUpload());
                // let nodeIds = listStore.lastDeletedNodesIds.join('; ');
                // ListActions.restoreExternalNodes(nodeIds);
                // dispatch(listUndoAction());
                break;
            case AnnotationConstant.ActionTypes.UNDO_DELETED_ANNOTATIONS:
                dispatch(undoDeleteAnnotation());
                break;
            default:
                BrowserUtil.disableEvents();
                break;
        }

        this.handleAlertDismiss();
    }

    isEllipsisActive = (e) => {
        return (e.offsetWidth < e.scrollWidth);
    }

    setCellTitle(e, displayValue) {
        if(this.isEllipsisActive(e.target)){
            this.showTooltip(e, displayValue);
        }
    }

    showTooltip(e, displayValue) {
        this.toolTip.style.top = (e.clientY + 20) + 'px';
        this.toolTip.style.left = (e.clientX + 5) + 'px';
        this.toolTip.style.display = 'block';
        this.toolTip.style.position = 'absolute';
        this.toolTip.style.zIndex = 99;
        this.toolTip.innerHTML = displayValue;
    }

    clearCellTitle() {
        this.toolTip.innerHTML = '';
        this.toolTip.style.display = 'none';
        this.toolTip.style.top = '0px';
        this.toolTip.style.left = '0px';
    }

    render() {
        let undoContent = null, undo = false;
        let helpContent = null;
        if (this.props.undoActionObj) {
            if (!StringUtil.isEmpty(this.props.undoActionObj.action)) {
                undo = true;
                if (this.props.undoActionObj.action == ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST || this.props.undoActionObj.action == ListExplorerConstants.ActionTypes.ADD_SYMBOLS_TO_LIST) {
                    if (!StringUtil.isEmpty(this.props.undoActionObj.removeItemsFromUserList.msids))
                        undo = true;
                    else
                        undo = false;
                }
                if(this.props.undoActionObj.action == OwnershipViewConstants.ActionTypes.SET_ALERT){
                    if (!StringUtil.isEmpty(this.props.undoActionObj.node))
                        undo = true;
                    else
                        undo = false;
                }
                
            }
        }
        if (this.props.showHelp) helpContent = <a onClick={this.props.helpAlert}><u>&nbsp;{LocalizationStore.getTranslatedData("set_hp", "Help")}</u></a>;
        if (undo) undoContent = <span tabIndex="0" className='undo-clicked' style={{ display: 'inline-block' }}>&nbsp;<a onClick={this.undo}><u>{LocalizationStore.getTranslatedData('ch_undo', 'Undo')}</u></a></span>;
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        let exceptionSymbols;
        switch (tabType) {
            case ListManagerTabType.Oneil:
                exceptionSymbols = ONeilViewStore.getExceptionSymbols();
                break;
            case ListManagerTabType.Ownership:
                exceptionSymbols = OwnershipViewStore.getExceptionSymbols();
                break;
            default: 
                break;
        }
        let hiddenItemMsg = 'items';
        if(exceptionSymbols && exceptionSymbols.length > 0){
            hiddenItemMsg = exceptionSymbols.length - 20 > 1 ? 'items' : 'item';
        }
        let exceptionSymbolsList = exceptionSymbols ? exceptionSymbols.map((item, i) => {
            if (i < 20) {
                return <li key={i} onMouseMove={(e) => this.setCellTitle(e, item)} onMouseOut={this.clearCellTitle} onBlur={this.clearCellTitle}>{item}</li>;
            }
            else if (i == 20) {
                return LocalizationStore.getLang() !== LangType.ZH_CN ? 
                        <li key={i}>{`${exceptionSymbols.length - i} ${hiddenItemMsg}`}<a onClick={() => this.onItemsMoreClicked(exceptionSymbols)}> more</a></li>
                        : <li key={i}>{`${exceptionSymbols.length - i}`}<a onClick={() => this.onItemsMoreClicked(exceptionSymbols)}>{`${LocalizationStore.getTranslatedData("LM_ItemsMore", `${hiddenItemMsg} more`)}`}</a></li>;
            }
        }) : null;
        if ((this.state.alertVisible || this.props.showAnnotAlert) && this.props.alertMessage) {
            return (
                <div className="alert-wrap">
                    {/* have to check */}
                    {/* <Alert className="custom-alert" bsStyle="warning" onDismiss={this.handleAlertDismiss}> */} 
                    <Alert className="custom-alert"onDismiss={this.handleAlertDismiss}>
                        <span style={{ display: 'inline-block' }}>{ReactHtmlParser(this.props.alertMessage)}</span>
                        {helpContent}
                        {undoContent}
                        {this.state.showSymbol ?
                            <div className="alert-status">
                                <ul>
                                    {exceptionSymbolsList}
                                </ul>
                            </div>
                            :
                            null
                        }
                    </Alert>
                    <PortalContainer>
                        <div className="exception-symbol-tooltip" ref={(r) => this.toolTip = r}></div>
                    </PortalContainer>
                </div>
            );
        }

        return (
            null
        );
    }

    handleAlertDismiss() {
        if(this.props.showAnnotAlert){
            this.props.dismissAlert();
        }
        this.setState({ alertVisible: false, showSymbol: false });
        ConsoleStore.setPrintAlertClose();
        // if(GridStore.getGridBodyAction() === GridConstants.ActionTypes.PASTE_GRID_ROWS){
        //     GridStore.resetAlertMessage()
        // }
        GridStore.resetAlertMessage()
    }

    handleAlertShow() {
        this.setState({ alertVisible: true });
    }
}
