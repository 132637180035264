import React from "react";
import ListStore from "ListStore";
import GridStore from "GridStore";
import MiniListStore from "MiniListStore";
import ONeilViewStore from "ONeilViewStore";
import Grid from "../../../../RayCustomControls/Grid/Grid";
import ColumnCustomFilterControl from "../../../../RayCustomControls/ListViewCustomFilter/ColumnCustomFilterControl.jsx";
import ListActions from "ListActions";
import GridActions from "GridActions";
import ListManagerViewType from "ListManagerViewType";
import { ListExplorerConstants } from "ListExplorerConstants";
import BrowserFilters from "../../../../RayCustomControls/BrowserFilters/BrowserFilters.jsx";
import BrowserFilterStore from "Stores/NavModules/NavList/TabONeil/BrowserFilterStore";
import SettingsStore from 'Stores/ConsoleWindow/Settings/SettingsStore';
import ListExplorerStore from "ListExplorerStore";
import { GridConstants } from "GridConstants";
import ContextMenuConstants from "ContextMenuConstants";
import ListManagerTabType from "ListManagerTabType";
import BrowserUtil from "BrowserUtil";
import StringUtil from "StringUtil";
import ConsoleStore from "ConsoleStore";
import AlertStore from "AlertStore";
import { AlertConstants } from "AlertConstants";
// import SharedDialog from "../../../../RayCustomControls/Share/ShareDialog.jsx";
// import ShareStore from "../../../../Stores/Share/ShareStore.js";
import ErrorBoundary from "ErrorBoundary";
import ExportProgressBar from "../../../../RayCustomControls/ExportListSettingsDialog/ExportProgressBar";
import SelectLimitWarningDialog from "../../../../RayCustomControls/SelectLimitWarningDialog";
import UserInfoUtil from 'UserInfoUtil';
import { MiniListConstants } from "MiniListConstants";
import DateHelper from "DateHelper";
import { SettingsConstants } from "../../../../Constants/SettingsConstants";
import BaseServiceApi from 'BaseServiceApi';
const SELECT_LIMIT_EXCEED_EVENT = "SELECT_LIMIT_EXCEED_EVENT"
let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
import GraphType from "GraphType";
import { dispatch } from "../../../../Redux/dispatch";
import { processNupListData, updateAllGlobalFlag, updateGlobalFlag, updateNupListFlages } from "../../../../Actions/NupViewActions";
import NupView from "../Nup/NupView";

export default class ONeilListView extends React.Component {

  constructor(props) {
    super(props);
    this.getListCatalog = this.getListCatalog.bind(this);
    this.oNeilViewStoreStateChange = this.oNeilViewStoreStateChange.bind(this);
    this.listStoreStateChange = this.listStoreStateChange.bind(this);
    this.gridStoreStateChange = this.gridStoreStateChange.bind(this);
    this.symbolsSelectLimitExceed = this.symbolsSelectLimitExceed.bind(this);
    this.fetchMoreDataOnScroll = this.fetchMoreDataOnScroll.bind(this);
    this.addColumnSetWidth = this.addColumnSetWidth.bind(this);
    this.addColumnToList = this.addColumnToList.bind(this);
    this.handleColumnSort = this.handleColumnSort.bind(this);
    this.handleBrowserFilters = this.handleBrowserFilters.bind(this);
    this.fetchBasicFilterData = this.fetchBasicFilterData.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.resetListCatelogData = this.resetListCatelogData.bind(this);
    this.removeColumnToList = this.removeColumnToList.bind(this);
    this.handleSplitterClick = this.handleSplitterClick.bind(this);
    this.onClearAllFilter = this.onClearAllFilter.bind(this);
    this.onHandleClickOnOff = this.onHandleClickOnOff.bind(this);
    this.alertStoreStateChange = this.alertStoreStateChange.bind(this);
    ConsoleStore.GetUserAboutModuleInfo();
    // this.shareStoreStateChange = this.shareStoreStateChange.bind(this);

    BrowserFilterStore.updateBrowserFilterDataFromSettings();
    this.state = {
      loadBrowserFilterData:false,
      gridData: [],
      browserFilterData: [],
      browserIgOneilFilterData: [],
      browserIgGicsFilterData: [],
      listId: this.props.listId,
      actualListId: this.props.actualListId,
      columns: [],
      totalItems: 0,
      basic: [],
      selectedView: ListManagerViewType.List,
      toggleBrowserFilters: false,
      screenValue: ONeilViewStore.getScreenOnOffValue(),
      screenUpdate: false,
      showModal: GridStore.getDisplayWarning(),
      limitExceedHeaderText: "COPY SYMBOLS", 
      limitExceedMessasge: "Only 10,000 symbols should be copied from the list.",
      isListChanged: false
    }
    this.listCatalog = {};
    this.pageListRows = {};
    this.labelLoading = [];
    this.keywordFilter = false;
    this.componentMounted = false;
    this.allowSettingLoadFilterDataStatus = false;
    this.selectedSymbol = ListStore.getSelectedSymbol();
  }

  UNSAFE_componentWillMount() {
    ONeilViewStore.addChangeListener(this.oNeilViewStoreStateChange);
    ListStore.addChangeListener(this.listStoreStateChange);
    GridStore.addChangeListener(this.gridStoreStateChange);
    GridStore.addChangeListener(this.symbolsSelectLimitExceed,SELECT_LIMIT_EXCEED_EVENT);
    AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
    AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
    AlertStore.addChangeListener(this.alertStoreStateChangeDeleteAlerts, AlertConstants.ActionTypes.DELETE_ALL_ALERTS_BY_MSIDS);
    // ShareStore.addChangeListener(this.shareStoreStateChange);

    this.resetListCatelogData();
  }

  componentWillUnmount() {
    this.componentMounted = false;
    ONeilViewStore.removeChangeListener(this.oNeilViewStoreStateChange);
    ListStore.removeChangeListener(this.listStoreStateChange);
    GridStore.removeChangeListener(this.gridStoreStateChange);
    GridStore.removeChangeListener(this.symbolsSelectLimitExceed,SELECT_LIMIT_EXCEED_EVENT);
    AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
    AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
    AlertStore.removeChangeListener(this.alertStoreStateChangeDeleteAlerts, AlertConstants.ActionTypes.DELETE_ALL_ALERTS_BY_MSIDS);
    // ShareStore.removeChangeListener(this.shareStoreStateChange);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      listId: props.listId,
      actualListId: props.actualListId
    });
  }

  componentDidMount(){
    this.componentMounted = true;
  }

  componentDidUpdate(){
    this.selectedSymbol = ListStore.getSelectedSymbol();
  }
  resetListCatelogData() {
    let ucf = (ONeilViewStore.getScreenOnOffValue()) ? 1 : 0;
    let filterSavedData = BrowserFilterStore.getState();
    if (BrowserFilterStore.getFilterBadge() == '') {
      this.listCatalog = {
        listId: null,
        actualListId: null,
        searchText: '',
        sortCols: '',
        initialNumRows: 90000,
        needColumnsInfo: 1,
        needBrowserCounts: filterSavedData.needBrowserCounts,
        browserFilterText: '-1;-1;-1;',
        addlCatalogRequests: null,
        useColFilters: ucf,
        saveColFilters: 0,
        customerOrder: 0,
        colsetId: '-1',
        igOneilbrowserFilters: '-1;-1;-1;',
        igGicsbrowserFilters: '-1;-1;-1;-1;',
        isOneilselected: filterSavedData.isOneilselected,
        isGicsselected: filterSavedData.isGicsselected,
        isNupview: 0,
        pageIndex: 0,
        pageSize: 250,
        isQuoteboard: 0,
        msId: null,
        fundId: "",
        indGrpCode: "",
        isExport: false,
        cacheId: -1
      }
    }
    else {
      this.listCatalog = {
        listId: null,
        actualListId: null,
        searchText: '',
        sortCols: '',
        initialNumRows: 90000,
        needColumnsInfo: 1,
        needBrowserCounts: filterSavedData.needBrowserCounts,
        browserFilterText: filterSavedData.browserFilterText,
        addlCatalogRequests: null,
        useColFilters: ucf,
        saveColFilters: 0,
        customerOrder: 0,
        colsetId: '-1',
        igOneilbrowserFilters: filterSavedData.igOneilbrowserFilters,
        igGicsbrowserFilters: filterSavedData.igGicsbrowserFilters,
        isOneilselected: filterSavedData.isOneilselected,
        isGicsselected: filterSavedData.isGicsselected,
        isNupview: 0,
        pageIndex: 0,
        pageSize: 250,
        isQuoteboard: 0,
        msId: null,
        fundId: "",
        indGrpCode: "",
        isExport: false,
        cacheId: -1
      }
    }

  }

  fetchMoreDataOnScroll(sequence) {
    let listcatlogForScroll = { ...this.listCatalog }
    listcatlogForScroll.needBrowserCounts = 0;
    listcatlogForScroll.sortCols = null;
    listcatlogForScroll.pageIndex = sequence;
    listcatlogForScroll.needColumnsInfo = true;
    listcatlogForScroll.saveColFilters = false;
    listcatlogForScroll.customerOrder = false;
    listcatlogForScroll.addlCatalogRequests = null;
    listcatlogForScroll.colsetId = '-1';
    listcatlogForScroll.cacheId = ONeilViewStore.getEsBasicCacheId();
    this.keywordFilter = false;
    ListActions.fetchMoreDataOnScroll(listcatlogForScroll);
  }

  getListCatalog(listId, actualListId, listName, pageIndex = 0, cacheId = -1, isInit = false) {
    if (listId && actualListId) {
      if (this.listCatalog.listId != listId) {
        this.listCatalog.searchText = '';
        /* data empty fix for PANWEB-2083 */
        this.setState({
          columns: [],
          gridData: []
        })
      }
      ONeilViewStore.clearSourecData();
     let listChangeStatus = ListExplorerStore.getListChangedStatus();
     if(listChangeStatus){
       this.allowSettingLoadFilterDataStatus = true;
       ONeilViewStore.setLoadFilterDataStatus(true);
       this.setState({loadBrowserFilterData:true})
     }
     else if(BrowserFilterStore.getState().dataSetTypeChanged){
       BrowserFilterStore.resetDataSetTypeChanged();
       if(this.allowSettingLoadFilterDataStatus){
         this.allowSettingLoadFilterDataStatus = false;
         ONeilViewStore.setLoadFilterDataStatus(true);
         this.setState({loadBrowserFilterData:true});
       }

     }

      this.listCatalog.listId = listId;
      this.listCatalog.actualListId = actualListId;
      this.listCatalog.listName = listName;
      this.listCatalog.pageIndex = pageIndex;
      this.listCatalog.cacheId = cacheId != -1 ? cacheId : 0;
      if (ListExplorerStore.activeNode && ListExplorerStore.activeNode.categoryType == 9) {
        if(ListExplorerStore.activeSybol.msId === undefined || ListExplorerStore.activeSybol.symbol === undefined) {
          ListExplorerStore.activeSybol.msId = ListExplorerStore.activeNode.msid;
          ListExplorerStore.activeSybol.symbol = ListExplorerStore.activeNode.symbol;
        }
        this.listCatalog.msId = ListExplorerStore.activeSybol.msId;
        this.listCatalog.indGrpCode = ListExplorerStore.activeSybol.symbol;
        this.listCatalog.fundId = ListExplorerStore.activeNode.spId;
        if (ListExplorerStore.activeNode.temporaryListType)
          this.listCatalog.tmpListType = ListExplorerStore.activeNode.temporaryListType;
      }
      else {
        ListExplorerStore.activeSybol.symbol = undefined;
        ListExplorerStore.activeSybol.msId = undefined;
      }
      ONeilViewStore.initialCall = false;

      // Clearing browser filter for Crypto currency List
      if (ListExplorerStore.getIsCryptoCurrencyList() || ListExplorerStore.isFactorList() || ListExplorerStore.getIsFuturesCashSpotsList() || ListExplorerStore.isTempList()) {
        this.listCatalog.needBrowserCounts = 1;
        this.listCatalog.browserFilterText = "-1;-1;-1;";
        this.listCatalog.igOneilbrowserFilters = "-1;-1;-1;";
        this.listCatalog.igGicsbrowserFilters = "-1;-1;-1;-1;";
      }
      if (this.listCatalog.listId === 1601 || this.listCatalog.listId === 1602 || ListExplorerStore.isFactorList()) {
        this.listCatalog.pageSize = 999999
      }
      ONeilViewStore.listCatalog = this.listCatalog;
      ListActions.getListCatalog(this.listCatalog, isInit);
    }
  }

  addColumnSetWidth(listId, colSeq, quoteBoard) {
    BrowserUtil.disableEvents();
    GridActions.addColumnSetWidth(listId, colSeq, quoteBoard);
  }

  addColumnToList(listId, dataItemId, previousColumnDataItemId, isFrozen, isQuoteboard, isColumnReorder = false) {
    GridActions.addColumnToList(GridConstants.ActionTypes.ADD_COLUMN_TO_LIST, listId, dataItemId, previousColumnDataItemId, isFrozen, isQuoteboard, isColumnReorder);
  }

  removeColumnToList(listId, dataItemId, isQuoteboard, isFrozen, previousColumnDataItemId) {
    BrowserUtil.disableEvents();
    GridActions.removeColumnToList(listId, dataItemId, isQuoteboard, isFrozen, previousColumnDataItemId);
  }

  handleSplitterClick(isSplit) {
    GridActions.splitFrozenColumns(isSplit);
  }

  handleColumnVisualization(listId, dataItemId, quoteBoard) {
    let column = ONeilViewStore.findColumn(dataItemId);
    if (column) {
      let colSeq = dataItemId + ',' + (column.isVisualizationOn ? 0 : 1);
      GridActions.handleColumnVisualization(listId, colSeq, quoteBoard);
    }
  }

  handleColumnSort(e, colData) {
    ONeilViewStore.updateSortColums(colData, e);
    this.listCatalog.sortCols = ONeilViewStore.getSortColumns();
    this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
  }

  handleCustomOrder() {
    this.listCatalog.saveColFilters = 0;
    this.listCatalog.needBrowserCounts = 0;
    this.listCatalog.customerOrder = 0;
    this.listCatalog.cacheId = -1;
    this.listCatalog.needColumnsInfo = 1;
    this.listCatalog.sortCols = [];
    let setNewCutomOrderPromise = ONeilViewStore.setNewCustomOrder(this.listCatalog.listId);
    setNewCutomOrderPromise.then((sortItem) => {
      this.listCatalog.sortCols = sortItem;
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
    })
  }

  updateMiniList() {
    let listState = ListStore.getSelectedListItem();
    MiniListStore.setCurrentAction(MiniListConstants.ActionTypes.ON_EXINTERNAL_CHANGE);
    MiniListStore.setMiniList(ONeilViewStore.getSourceData(), listState);
  }

  resetListCatalog = () => {
    this.listCatalog.customerOrder = 0;
    this.listCatalog.saveColFilters = 0;
    this.listCatalog.needBrowserCounts = 0;
    this.listCatalog.sortCols = "";
    return;
  }

  clearGridData(){
    this.setState({gridData:[]});
  }

  oNeilViewStoreStateChange() {
    if(this.componentMounted){
    let currentAction = ONeilViewStore.getCurrentAction();
    let state = ONeilViewStore.getState();
    const settings = SettingsStore.getConsoleSettings();
    const nupSettings = settings ? settings.NavListManagerSettings.NupChartSettings : null;
    let isGetBrowserFilterAction = (currentAction == ListExplorerConstants.ActionTypes.GET_BROWSER_FILTER || currentAction == ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG) ? true : false;
    if (currentAction == ListExplorerConstants.ActionTypes.GET_BROWSER_FILTER || currentAction == ListExplorerConstants.ActionTypes.GET_LIST_CATALOG || currentAction == ListExplorerConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL || currentAction == ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG || currentAction == ListExplorerConstants.ActionTypes.LIST_ONEIL_FLAGGED) {
        this.pageListRows.cacheId = state.basic.cacheId;
      this.resetListCatalog();
      this.listCatalog.colsetId = '-1';
      // this.listCatalog.searchText = '';
      let targetGridData = ONeilViewStore.getSourceData();
      const explorerStoreAction = ListExplorerStore.getCurrentAction();
      const selectedListItem = ListStore.getSelectedListItem();
      if ((currentAction == ListExplorerConstants.ActionTypes.GET_LIST_CATALOG || currentAction == ListExplorerConstants.ActionTypes.RELOAD_LIST_CATALOG) && nupSettings.showChart && (selectedListItem.SelectedCategoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory)) {
        dispatch(processNupListData(targetGridData, true, this.selectedSymbol))
      }

      if(currentAction == ListExplorerConstants.ActionTypes.LIST_ONEIL_FLAGGED && nupSettings.showChart && (selectedListItem.SelectedCategoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory)){
        dispatch(updateNupListFlages(targetGridData, ['isFlagged', 'isSelected']));
      }
      if(currentAction == ListExplorerConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL && nupSettings.showChart && (selectedListItem.SelectedCategoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory)){
        dispatch(processNupListData(targetGridData, false, null))
      }
      if(selectedListItem.SelectedCategoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && explorerStoreAction === ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE){
        const deleteListItem = ListExplorerStore.getDeleteInfo();
        if(deleteListItem){
          const deleteListNodeId = typeof (deleteListItem.nodeId) === 'object' ? deleteListItem.nodeId.low : deleteListItem.nodeId;
          const selectedListNodeId = typeof (selectedListItem.SelectedListId) === 'object' ? selectedListItem.SelectedListId.low : selectedListItem.SelectedListId;
          if(selectedListNodeId === deleteListNodeId){
            targetGridData = [];
          }
        }
      }
      this.setState({
        totalItems: state.basic.total,
        basic: state.basic,
        cacheId: ONeilViewStore.getEsBasicCacheId(),
        gridData: targetGridData,
        columns: state.columns,
        browserFilterData:isGetBrowserFilterAction ? state.browserFilterData : this.state.browserFilterData,
        browserIgOneilFilterData:isGetBrowserFilterAction ? state.browserIgOneilFilterData : this.state.browserIgOneilFilterData,
        browserIgGicsFilterData:isGetBrowserFilterAction ? state.browserIgGicsFilterData : this.state.browserIgGicsFilterData,
        isListChanged: true,
        loadBrowserFilterData:isGetBrowserFilterAction ? false : this.state.loadBrowserFilterData,
      }, ONeilViewStore.setMoredataLoaded());
      // BrowserUtil.enableEvents();
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.GET_SEARCH_TEXT) {
      this.listCatalog.searchText = state.searchKeyword;
      this.keywordFilter = true;
      ONeilViewStore.clearCurrentAction();
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
      ONeilViewStore.clearCurrentAction();
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.SELECT_COLUMN_SET) {
      this.listCatalog.colsetId = state.columnSetId;
      this.listCatalog.needBrowserCounts = 0;

      //Updating update date field in List property section in List explorer
      ListExplorerStore.findNodeByIdAndUpdateDate(this.props.listId, Date.parse(new Date()));

      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.SCREEN_ON_OFF) {
      let ucf = ONeilViewStore.getScreenOnOffValue() ? 1 : 0;
      let screenValue = ONeilViewStore.getScreenOnOffValue();
      let columnFilters = ONeilViewStore.getcolumnFilters();
      this.setState({ screenValue: screenValue });
      if (columnFilters.length > 0) {
        this.onHandleClickOnOff(ucf);
      }
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG) {
      let screenValue = ONeilViewStore.getScreenOnOffValue();
      this.resetListCatalog();
      this.setState({ screenValue: screenValue });
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.NASDAQ_LIST_UPDATE) {
      this.setState({ gridData: ONeilViewStore.getSourceData() });
    }
    else if (currentAction == ListExplorerConstants.ActionTypes.ADD_SYMBOLS_TO_LIST){
      const activeNodeId = ListExplorerStore.activeNode && ListExplorerStore.activeNode.nodeId ? typeof (ListExplorerStore.activeNode.nodeId) === 'object' ? ListExplorerStore.activeNode.nodeId.low : ListExplorerStore.activeNode.nodeId : null;
      if (!StringUtil.isEmpty(activeNodeId) && activeNodeId === state.removeItemsFromUserList.targetListId) {
          this.props.fetchListCataglogData(this.props.listId.toString(), this.props.listId.toString(), this.props.listName); 
        }
    }
  }
}

  listStoreStateChange() {
    let currentAction = ListStore.getCurrentAction();
    let currentColumnSetActions = ListStore.getCurrentColumnSetActions();
    let state = ListStore.getState();
    if (state.refreshGrid && (currentColumnSetActions == ListExplorerConstants.ActionTypes.ADD_NEW_COLUMN_SET || currentAction == ListExplorerConstants.ActionTypes.RESET_COLUMN_SET || currentAction == ListExplorerConstants.ActionTypes.DUPLICATE_COLUMN_SET || currentAction == ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET || currentAction == ListExplorerConstants.ActionTypes.REMOVE_COLUMN_SET_FOLDER)) {
      ListStore.setONeilViewAction(null);
      this.resetListCatalog();
      this.listCatalog.colsetId = '-1';
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
      BrowserUtil.enableEvents()
    }
    else if (state.isCatlogLoad && currentAction == ListExplorerConstants.ActionTypes.CUSTOM_METRIC_CHANGED) {
      ListStore.setONeilViewAction(currentAction);
      setTimeout(() => {
        this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, false);
      }, 100);
      ListStore.setCatlogReload(false);
    }

  }

  alertStoreStateChange() {

    if (AlertStore.renderListView) {
      AlertStore.renderListView = false;
      this.setState({ gridData: ONeilViewStore.getSourceData() });
    }
  }

  alertStoreStateChangeDeleteAlerts = () => {
    this.setState({ gridData: ONeilViewStore.getSourceData() });
  }

  symbolsSelectLimitExceed() {
    if (this.componentMounted && ListExplorerStore.getSelectedTabType() === ListManagerTabType.Oneil) {
      const limitExceedType = GridStore.getLimitExceedType();
      let limitExceedHeaderText = '';
      let limitExceedMessasge = '';
      switch (limitExceedType) {
        case GridConstants.ActionTypes.COPY_GRID_ROWS:
          limitExceedHeaderText = "COPY SYMBOLS";
          limitExceedMessasge = "Only 10,000 symbols should be copied from the list.";
          break;
        case GridConstants.ActionTypes.UPDATE_GLOBAL_FLAG:
          limitExceedHeaderText = "FLAG & UNFLAG SYMBOLS";
          limitExceedMessasge = "Only 10,000 symbols should be flagged/unflagged.";
          break;
        case GridConstants.ActionTypes.REMOVE_GRID_ROWS:
          limitExceedHeaderText = "REMOVE SYMBOLS";
          limitExceedMessasge = "Only 10,000 symbols should be removed from the list.";
          break;
        default:
          limitExceedHeaderText = "COPY SYMBOLS";
          limitExceedMessasge = "Only 10,000 symbols should be copied from the list.";
          break;
      }
      this.setState({ showModal: GridStore.getDisplayWarning(), limitExceedHeaderText: limitExceedHeaderText, limitExceedMessasge: limitExceedMessasge });
    }
  }

  gridStoreStateChange() {
    if( this.componentMounted){
    let currentAction = GridStore.getCurrentAction();
    let state = ONeilViewStore.getState();

    if (currentAction == GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_FROM_METRIC || currentAction == GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_UNDO) {

      this.listCatalog.colsetId = '-1';
      if (currentAction == GridConstants.ActionTypes.ADD_COLUMN_TO_LIST_UNDO) {
        ONeilViewStore.undoColumnFilters();
        const addlRequestData = ColumnCustomFilterControl.getAddlRequests(true, true);
        this.listCatalog.addlCatalogRequests = addlRequestData;
        this.listCatalog.saveColFilters = 1;
        this.listCatalog.sortCols = ONeilViewStore.getSortColumns();
      }
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, true);
    }
    else if (currentAction == GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST) {
      currentAction == GridConstants.ActionTypes.REMOVE_COLUMN_TO_LIST && GridStore.clearCurrentAction();
      this.listCatalog.colsetId = '-1';
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
      GridStore.clearGridBodyAction();
    }
    else if (currentAction == GridConstants.ActionTypes.AUTO_SIZE_ALL_COLUMNS_WIDTH) {
      GridStore.clearCurrentAction();
      this.grid.onAutoSizeAllColumnsWidthClick();
    }
    else if (currentAction == GridConstants.ActionTypes.SPLIT_FROZEN_COLUMNS) {
      this.setState({
        columns: state.columns
      });
    }
    else if (currentAction == GridConstants.ActionTypes.ADD_COLUMN_TO_LIST) {
      this.setState({
        gridData: ONeilViewStore.getSourceData(),
        columns: state.columns,
        cacheId: ONeilViewStore.getEsBasicCacheId(),
        shouldUpdateStreamingData: true
      });
    }
    else if (currentAction == GridConstants.ActionTypes.EDIT_CUSTOM_METRIC_SUCCESS) {
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, false);
    }

    if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.UPDATE_COL_SET_WIDTH || GridStore.getGridBodyAction() == GridConstants.ActionTypes.VISUALIZE_GRID_COLUMN) {
      BrowserUtil.enableEvents();
      GridStore.clearGridBodyAction();
      this.setState({
        basic: state.basic,
        columns: state.columns,
        cacheId: ONeilViewStore.getEsBasicCacheId()
      });
    }
    else if (GridStore.getGridBodyAction() == ContextMenuConstants.FLAG_ALL_SYMBOLS) {
      BrowserUtil.enableEvents();
      ONeilViewStore.updateAllFlagIn(false);
      dispatch(updateAllGlobalFlag(true));
      GridStore.clearGridBodyAction();
      this.setState({
        gridData: ONeilViewStore.getSourceData(),
        cacheId: ONeilViewStore.getEsBasicCacheId()
      });
    }
    else if (GridStore.getGridBodyAction() == ContextMenuConstants.REMOVE_ALL_FLAGS) {
      BrowserUtil.enableEvents();
      ONeilViewStore.updateAllFlagIn(true);
      dispatch(updateAllGlobalFlag(false));
      GridStore.clearGridBodyAction();
      this.setState({
        gridData: ONeilViewStore.getSourceData(),
        cacheId: ONeilViewStore.getEsBasicCacheId()
      });
    }
    else if (GridStore.getGridBodyAction() == ContextMenuConstants.ROW_FLAG) {
      BrowserUtil.enableEvents();
      ONeilViewStore.updateFlagIn(false);
      if(GridStore.getSelectedIndexes().length > 0)
        dispatch(updateGlobalFlag(true));
      GridStore.clearGridBodyAction();
      this.setState({
        gridData: ONeilViewStore.getSourceData(),
        cacheId: ONeilViewStore.getEsBasicCacheId()
      });
    }
    else if (GridStore.getGridBodyAction() == ContextMenuConstants.ROW_REMOVE_FLAG) {
      BrowserUtil.enableEvents();
      ONeilViewStore.updateFlagIn(true);
      if(GridStore.getSelectedIndexes().length > 0)
        dispatch(updateGlobalFlag(false));
      GridStore.clearGridBodyAction();
      this.setState({
        gridData: ONeilViewStore.getSourceData(),
      });
    }
    else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.REMOVE_GRID_ROWS || GridStore.getGridBodyAction() == GridConstants.ActionTypes.REMOVE_GRID_ROWS_UNDO) {
      this.updateMiniList();
      this.setState({
        totalItems: state.basic.total,
        cacheId: ONeilViewStore.getEsBasicCacheId(),
        gridData: ONeilViewStore.getSourceData(),
        browserFilterData: state.browserFilterData,
        browserIgOneilFilterData: state.browserIgOneilFilterData,
        browserIgGicsFilterData: state.browserIgGicsFilterData
      });
    }
    else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.PASTE_GRID_ROWS) {
      if (GridStore.isRefreshGrid()) {
        this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
        BrowserUtil.enableEvents();
        GridStore.clearCopyValue();
      }
    }

    else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.PASTE_GRID_ROWS_UNDO) {
      if (GridStore.isRefreshGrid()) {
        this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, false);
        BrowserUtil.enableEvents();
        GridStore.clearGridBodyAction();
        GridStore.clearCurrentAction();
      }
    }
    else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.DROP_IN_LIST_MANAGER) {
      this.updateMiniList();
      this.setState({
        cacheId: ONeilViewStore.getEsBasicCacheId(),
        gridData: ONeilViewStore.getSourceData()
      });
    }

    else if (GridStore.getGridBodyAction() == GridConstants.ActionTypes.ADD_SYMBOL_LIST_MANAGER) {
      /*PANWEB-3116-Fix*/
      if (GridStore.isRefreshGrid()) { 
        //PANWEB-6856 commenting below line to avoid blank list
        // this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName, false);
      }
    }
    else if (currentAction === SettingsConstants.ActionTypes.DDE_STATUS_CHANGED) {
      this.setState({
        gridData: ONeilViewStore.getSourceData(),
      });
      GridStore.clearCurrentAction();
    }
  }
}

  // shareStoreStateChange() {
  //   const sharedState = ShareStore.getState();
  //   this.setState({
  //     sharedDataState: sharedState
  //   });
  // }

  fetchBasicFilterData(filterParameters, labelLoading) {
    this.labelLoading = labelLoading;
    this.listCatalog.needBrowserCounts = filterParameters.needBrowserCounts;
    this.listCatalog.browserFilterText = filterParameters.browserFilterText;
    this.listCatalog.igOneilbrowserFilters = filterParameters.igOneilbrowserFilters;
    this.listCatalog.igGicsbrowserFilters = filterParameters.igGicsbrowserFilters;
    this.listCatalog.isOneilselected = filterParameters.isOneilselected;
    this.listCatalog.isGicsselected = filterParameters.isGicsselected;
    this.keywordFilter = true;
    this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
  }

  applyFilter(addlCatalogRequests) {
    this.listCatalog.addlCatalogRequests = addlCatalogRequests;
    this.listCatalog.saveColFilters = 1;
    if (!ONeilViewStore.getScreenOnOffValue()) {
      this.listCatalog.useColFilters = 0;
      ListActions.getListCatalogForScreenOff(this.listCatalog);
    }
    else {
      this.listCatalog.useColFilters = 1; // manually setting useColFilters to 1 for Screen on condition
      this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
    }
  }

  onClearAllFilter(addlCatalogRequests) {
    if (!ONeilViewStore.getScreenOnOffValue()) {
      this.setState({ screenUpdate: true });
    }
    this.applyFilter(addlCatalogRequests);
  }

  handleBrowserFilters() {
    //Log Usage
    if (!this.state.toggleBrowserFilters)
      ConsoleStore.trackUsage('ListBrowserIsSelect');

    this.setState({
      toggleBrowserFilters: !this.state.toggleBrowserFilters
    })
  }

  onHandleClickOnOff(ucf) {
    this.listCatalog.saveColFilters = 0;
    this.listCatalog.useColFilters = ucf;
    this.listCatalog.addlCatalogRequests = null;
    this.props.fetchListCataglogData(this.listCatalog.listId, this.listCatalog.actualListId, this.props.listName);
  }

  plotSymbol(sym) {
    ListStore.plotSymbol(sym);
    ListActions.updateMiniList(sym);
  }

  closeModal() {
    GridActions.CloseSymbolsSelectExceedWarningDialog();
  }

  render() {
    // const sharedDataState = this.state.sharedDataState ? this.state.sharedDataState : undefined;
    //  console.log('oneillistview this.state', this.state);
    const { gridData } = this.state;
    return (
      <div ref={(ref) => this.main = ref} className="main-list-view medium-normal">{this.props.loading}
        {/*<div className="custom-popover">
              <Popover
                id="popover-basic"
                placement="left"
                positionLeft={200}
                positionTop={50}
                title="Popover right"
              >
                And here's some <strong>amazing</strong> content. It's very engaging. right?
              </Popover>
            </div>

 */}
        <div className="list-wrap">
          <div className="list-grid-wrap">
            <div className="group-filter medium-normal">
              <ExportProgressBar />
              <BrowserFilters
                browserData={this.state.browserFilterData}
                browserIgOneilFilterData={this.state.browserIgOneilFilterData}
                browserIgGicsFilterData={this.state.browserIgGicsFilterData}
                toggleBrowserFilters={this.state.toggleBrowserFilters}
                handleBrowserFilters={this.handleBrowserFilters}
                fetchBasicFilterData={this.fetchBasicFilterData}
                categoryType={ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("categoryType") ? ListExplorerStore.activeNode.categoryType : null}
                loading={this.props.loading}
                labelLoading={this.labelLoading}
                loadBrowserFilterData={this.state.loadBrowserFilterData}
              />
            </div>
            <div id="colResizeSeparator" className="col-resize-separator" ref={(div) => { this.columnResizeSeparator = div }}></div>
            <ErrorBoundary>
              {this.state.showModal && <SelectLimitWarningDialog showModal={this.state.showModal} headerText={this.state.limitExceedHeaderText} messasge={this.state.limitExceedMessasge} closeModal={this.closeModal} />}
              <Grid ref={(g) => this.grid = g}
                onPlotSymbol={(sym) => this.plotSymbol(sym)}
                source={gridData}
                columns={this.state.columns}
                itemHeight="26"
                showChart = {ONeilViewStore.showChart}
                pageSize={this.state.basic.count}
                fetchMoreDataOnScroll={this.fetchMoreDataOnScroll}
                itemsCount={gridData.length}
                keywordFilter={this.keywordFilter}
                selectedListId={this.state.actualListId}
                loading={this.props.loading}
                columnResizeSeparator={this.columnResizeSeparator}
                selectedView={this.state.selectedView}
                addColumnSetWidth={this.addColumnSetWidth}
                addColumnToList={this.addColumnToList}
                selectedSymbol={this.selectedSymbol}
                handleColumnSort={this.handleColumnSort}
                onGridColumnContextMenuClick={this.props.onGridColumnContextMenuClick}
                onGridCustomFilterContextMenuClick={this.props.onGridCustomFilterContextMenuClick}
                onGridRowContextMenuClick={this.props.onGridRowContextMenuClick}
                updateGlobalFlag={this.props.updateGlobalFlag}
                handleSplitterClick={this.handleSplitterClick}
                applyFilter={this.applyFilter}
                totalItems={this.state.totalItems}
                onClearAllFilter={this.onClearAllFilter}
                screenValue={this.state.screenValue}
                listName={this.props.listName}
                cacheId={this.state.basic.cacheId}
                onGridRowContextMenuItemClick={this.props.onGridRowContextMenuItemClick}
                fontSizeClass={this.props.fontSizeClass}
                isListChanged={this.state.isListChanged}
                shouldUpdateStreamingData={this.state.shouldUpdateStreamingData}
              />
            </ErrorBoundary>
          </div>
          {ONeilViewStore.showChart && (!this.props.loading) && <NupView 
          fetchMoreDataOnScroll= {this.fetchMoreDataOnScroll} 
          onGridRowContextMenuClick= {this.props.onGridRowContextMenuClick}
          selectedListId={this.state.actualListId}
          onGridRowContextMenuItemClick={this.props.onGridRowContextMenuItemClick}
           /> }
        </div>
        {/* {
          sharedDataState && sharedDataState.showSharedDialog &&
          <SharedDialog
            sharedData={sharedDataState.sharedData}
            managedData={sharedDataState.managedData}
            targetData={sharedDataState.targetData}
            dialogType={sharedDataState.dialog_Type}
            sharedAction={sharedDataState.sharedAction}
            userList={sharedDataState.userList}
            showUsers={sharedDataState.showUsers}
            nameFilter={sharedDataState.nameFilter}
            savedSharedData={sharedDataState.savedSharedData}
            showConfirmation={sharedDataState.showConfirmation}
            showInProgress={sharedDataState.showInProgress}
            x_parent={this.main.getBoundingClientRect().left + 250}
            y_parent={this.main.getBoundingClientRect().top + 150}
          />
        } */}
      </div>
    );
  }
}
