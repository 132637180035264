import React from "react";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";
import NavType from "NavType";
import ConsoleStore from "ConsoleStore";
import TabDataGraphStore from "Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore.js";
import { TabDataGraphConstants } from "Constants/TabDataGraphConstants.js";
import TimeTrackingWindow from "TimeTrackingWindow";
import SettingsStore from "SettingsStore";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import TabType from "Constants/TabType.js";
import PerformanceWindowStore from "../Stores/NavModules/NavDataGraph/PerformanceWindowStore";
import { PerformanceWindowConstants } from "../Constants/PerformanceWindowConstants";
import DatagraphDataType from "../Constants/DatagraphDataType";
import ListManagerTabType from "../Constants/ListManagerTabType";

export default class PerformanceWindow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.ConsoleStateChange = this.ConsoleStateChange.bind(this);
        this.TabDataGraphStoreStateChange = this.TabDataGraphStoreStateChange.bind(this);
        this.SettingsStoreStateChange = this.SettingsStoreStateChange.bind(this);
        this.DataGraphStoreStateChage = this.DataGraphStoreStateChage.bind(this);
        this.keydownHandler = this.keydownHandler.bind(this);
        this.getCurrentDivId = this.getCurrentDivId.bind(this);
        this.PerformanceWindowStoreChange = this.PerformanceWindowStoreChange.bind(this);
        this.state = {
            isShowPerformanceWindow: false,
            isUpdatingPerformanceWindow: true,
            isChartLoaded: false,
            isMiniListOpen: SettingsStore.getConsoleSettings().NavDatagraphSettings.isMiniListOpen,
            // onPricePanelLoadTime: 0,
            // onPricePanelApiTime: 0,
            // onPricePanelComponentTime:0,
            // onSummaryBlockLoadTime: 0,
            // onSummaryBlockApiTime: 0,
            // onSummaryBlockComponentTime: 0,
            // onFinancialOwnerShipBlockApiTime: 0,
            // onFinancialOwnerShipBlockLoadTime: 0,
            // onFinancialOwnerShipBlockComponentTime: 0,
            datagraphRenderTime: 0,
            trackDataList: [] 
        };
        this.isDataLoadFailed = false;
        this.prevTrackDataList = [];
        this.prevOverallEndMsg = "Overall end to end time";
        this.prevOverallEndAvg = '-';
        this.prevOverallEndTime = '-';
        this.activeNav = null;
    }

    UNSAFE_componentWillMount = () => {
        ConsoleStore.addChangeListener(this.ConsoleStateChange);
        TabDataGraphStore.addChangeListener(this.TabDataGraphStoreStateChange);
        SettingsStore.addChangeListener(this.SettingsStoreStateChange);
        DatagraphStore.addChangeListener(this.DataGraphStoreStateChage);
        PerformanceWindowStore.addChangeListener(this.PerformanceWindowStoreChange);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler);
    }

    componentWillUnmount() {
        ConsoleStore.removeChangeListener(this.ConsoleStateChange);
        TabDataGraphStore.removeChangeListener(this.TabDataGraphStoreStateChange);
        SettingsStore.removeChangeListener(this.SettingsStoreStateChange);
        DatagraphStore.removeChangeListener(this.DataGraphStoreStateChage);
        PerformanceWindowStore.removeChangeListener(this.PerformanceWindowStoreChange);
        document.removeEventListener('keydown', this.keydownHandler);
    }

    ConsoleStateChange() {
        const currentAction = ConsoleStore.getCurrentAction();
        if (currentAction === ConsoleConstants.ActionTypes.SET_CHART_LOADED) {
            this.isDataLoadFailed = false;
            this.prevTrackDataList = this.state.trackDataList;
            this.prevOverallEndMsg = "Overall end to end time";
            this.prevOverallEndAvg = '-';
            this.prevOverallEndTime = '-';
            if (TimeTrackingWindow.isLogin) {
                this.prevOverallEndMsg = "Login - Datagraph" ;
                this.prevOverallEndAvg = '-';
                this.prevOverallEndTime = this.state.loginTime;
            }
            else {
                this.prevOverallEndMsg = "Overall end to end time";
                this.prevOverallEndAvg = PerformanceWindowStore.getState().average;
                this.prevOverallEndTime = this.state.datagraphRenderTime;
            }
            this.setState({ isUpdatingPerformanceWindow: false, isChartLoaded: true });
        }
    }

    PerformanceWindowStoreChange() {
        const action = PerformanceWindowStore.getCurrentAction();
        switch (action) {
            case PerformanceWindowConstants.ActionTypes.ON_PERFORMANCE_DATA_CLEAR:
                this.setState({
                    datagraphRenderTime: 0,
                    trackDataList: [] 
                });
                break;
            case PerformanceWindowConstants.ActionTypes.DATAGRAPH_DATA_LOAD_START:
                this.isDataLoadFailed = false;
                this.setState({ isUpdatingPerformanceWindow: true, isChartLoaded: false });
                break;
            case PerformanceWindowConstants.ActionTypes.TRACK_DATA_UPDATE:
                if(this.isDataLoadFailed == true) {
                    return;
                }
                this.state.trackDataList = [];
                const activeNav = this.getActiveNav();
                if (activeNav === NavType.NavSymbol){
                    const storeState = PerformanceWindowStore.getState();
                    const datagraphTrackData = PerformanceWindowStore.getTrackDataByTrackType(DatagraphDataType.DatagraphRender);
                    this.setState({ trackDataList : storeState.trackDataList.sort((a,b) => {return a.orderNum - b.orderNum}), datagraphRenderTime : datagraphTrackData.LoadTime, loginTime : datagraphTrackData.LoginTime, isUpdatingPerformanceWindow: false });
                } else {
                    const trackDataList = PerformanceWindowStore.getTrackDataListByNavType(activeNav);
                    const overallEndTrackData = PerformanceWindowStore.getOverallEndTrackDataByNavType(activeNav);
                    this.setState({ trackDataList : trackDataList, datagraphRenderTime : overallEndTrackData.LoadTime, loginTime : overallEndTrackData.LoginTime, 
                        average : overallEndTrackData.average, isUpdatingPerformanceWindow: false });
                }
                break;
            case PerformanceWindowConstants.ActionTypes.DATAGRAPH_DATA_LOAD_FAILED:
                this.isDataLoadFailed = true;
                this.setState({ isUpdatingPerformanceWindow: false, isChartLoaded: true, trackDataList: this.prevTrackDataList });
                break;
            default:
                break;
        }
    }


    TabDataGraphStoreStateChange() {
        const currentAction = TabDataGraphStore.getCurrentAction();
        if (currentAction === TabDataGraphConstants.ActionTypes.PERIODICITY_CHANGED) {
            this.setState({ isUpdatingPerformanceWindow: true });
            TabDataGraphStore.clearCurrentAction();
        }
        else if (currentAction === TabDataGraphConstants.ActionTypes.DAY_CHANGE) {
            this.setState({ isUpdatingPerformanceWindow: true });
            TabDataGraphStore.clearCurrentAction();
        }
    }

    getActiveNav(){
        return SettingsStore.getConsoleSettings().ActiveNav;
    }

    SettingsStoreStateChange() {
        const currentAction = SettingsStore.getCurrentAction();
        this.activeNav = SettingsStore.getConsoleSettings().ActiveNav;
        if (currentAction === SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT || currentAction === SettingsConstants.ActionTypes.SET_USER_SETTINGS) {
            if(currentAction === SettingsConstants.ActionTypes.SET_USER_SETTINGS){
                const trackDataList = PerformanceWindowStore.getTrackDataListByNavType(this.activeNav);
                const overallEndTrackData = PerformanceWindowStore.getOverallEndTrackDataByNavType(this.activeNav);
                this.setState({ trackDataList : trackDataList, 
                                datagraphRenderTime : overallEndTrackData.LoadTime, 
                                loginTime : overallEndTrackData.LoginTime, 
                                average : overallEndTrackData.average});
                                
                this.setState({ isShowPerformanceWindow: false, isUpdatingPerformanceWindow: true });
            }
            if (this.activeNav === NavType.NavSymbol) {
                this.setState({ isChartLoaded: false });
            }
            else {
                this.setState({ isShowPerformanceWindow: false, isUpdatingPerformanceWindow: false, isChartLoaded: false });
            }
        }
    }

    DataGraphStoreStateChage() {
        const currentAction = DatagraphStore.getCurrentAction();
        const datagraphState = DatagraphStore.getState();

        if (currentAction === SettingsConstants.ActionTypes.DATAGRAPH_UPDATE_TAB ||
            currentAction === SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED ||
            currentAction === SettingsConstants.ActionTypes.CURRENCY_SYMBOL_CHANGED ||
            currentAction === SettingsConstants.ActionTypes.CRYPTO_CURRENCY_SYMBOL_CHANGED ||
            currentAction === SettingsConstants.ActionTypes.ETF_SYMBOL_CHANGED ||
            currentAction === SettingsConstants.ActionTypes.CEF_SYMBOL_CHANGED ||
            currentAction === SettingsConstants.ActionTypes.FUND_SYMBOL_CHANGED ||
            currentAction === SettingsConstants.ActionTypes.INDEX_SYMBOL_CHANGED ||
            currentAction === SettingsConstants.ActionTypes.FUTURES_SYMBOL_CHANGED ||
            currentAction === SettingsConstants.ActionTypes.CASHSPOTS_SYMBOL_CHANGED
            || currentAction === SettingsConstants.ActionTypes.INDUSTRYGROUP_SYMBOL_CHANGED) {
            this.activeTab = datagraphState.SelectedTabKey;
            if (this.activeTab === TabType.Datagraph) {
                this.setState({ isChartLoaded: false });
            }
            else {
                this.setState({ isShowPerformanceWindow: false, isUpdatingPerformanceWindow: false, isChartLoaded: false });
            }
        }

        if (currentAction === TabDataGraphConstants.ActionTypes.UPDATE_MINILIST_TOGGLE_STATUS) {
            this.setState({ isMiniListOpen: datagraphState.isMiniListOpen });
            DatagraphStore.clearCurrentAction();
        }
    }

    keydownHandler(e) {
        const settings = SettingsStore.getConsoleSettings();
        const navListManagerSettings = settings.NavListManagerSettings;
        if (e.ctrlKey && e.altKey && e.keyCode === 82 && this.activeNav && ((this.activeNav === NavType.NavSymbol && this.activeTab === TabType.Datagraph) || (this.activeNav === NavType.NavLists && navListManagerSettings.SelectedTabKey == ListManagerTabType.Oneil))) {
            this.setState({ isShowPerformanceWindow: !this.state.isShowPerformanceWindow });
        }
    }

    getCurrentDivId() {
        const urlContent = this.props.history.location.pathname.split('/');
        const tab = urlContent[1] && urlContent[1].toUpperCase();
        if (urlContent[1] && urlContent[2]) {
            switch (tab) {
                case NavType.NavCompare.toUpperCase():
                    return urlContent[2].toUpperCase();
                case NavType.NavSymbol.toUpperCase():
                    let symbol = '';
                    urlContent.map((item, i) => { if (i > 1) symbol += item; });
                    return symbol.split(".").join("").toUpperCase();
                default: return "";
            }
        }else
            return "";
    }

    render() {
        const currentDivId = this.state.isChartLoaded ? this.getCurrentDivId() : '';
        let miniListToggleClass = this.state.isMiniListOpen ? "open-minilist" : "";
        const parsedSymbolId = currentDivId.replace(/[^a-zA-Z 0-9]/g, "")
        if(this.state.isChartLoaded) {
            console.log("PRINT:--Tracker--*** chart loaded div render done *** for", parsedSymbolId);
        }
        const activeNav = this.getActiveNav();
        if(activeNav && activeNav === NavType.NavLists){
            miniListToggleClass = "listManager";
        }
        let overallEndMsg = "Overall end to end time";
        let overallEndAvg = '-';
        let overallEndTime = '-';
        switch (activeNav) {
            case NavType.NavSymbol:
                if (TimeTrackingWindow.isLogin) {
                    overallEndMsg = "Login - Datagraph" ;
                    overallEndAvg = '-';
                    overallEndTime = this.state.loginTime;
                }
                else {
                    overallEndMsg = "Overall end to end time";
                    overallEndAvg = PerformanceWindowStore.getState().average;
                    overallEndTime = this.state.datagraphRenderTime;
                }
                if(this.isDataLoadFailed){
                    overallEndMsg = this.prevOverallEndMsg;
                    overallEndAvg = this.prevOverallEndAvg;
                    overallEndTime = this.prevOverallEndTime;
                }
                break;
            case NavType.NavLists:
                const overallEndTrackData = PerformanceWindowStore.getOverallEndTrackDataByNavType(this.activeNav);
                if (TimeTrackingWindow.isLogin || overallEndTrackData.isLogin) {
                    overallEndMsg = "Login - List Manager";
                }
                else {
                    overallEndMsg = "Overall end to end time";
                }
                overallEndAvg = this.state.average;
                overallEndTime = this.state.datagraphRenderTime;
                break;
            default:
                break;
        }
        return (
            <>
                {this.state.isShowPerformanceWindow &&
                    <div className={`app-performance ${miniListToggleClass}`}>
                        <div className="app-performance-box">
                            {this.state.isUpdatingPerformanceWindow && <div className="updating">
                                <span className="text">Updating...</span>
                                </div>
                            }

                            <table>
                                <thead>
                                    <tr>
                                        <th>Panel</th>
                                        <th colSpan="4">Time Taken</th>
                                    </tr>
                                    <tr>
                                        <th className="performance-cell">Name</th>
                                        <th>Api</th>
                                        <th>UI</th>
                                        <th>Overall</th>
                                        <th>Average</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.trackDataList.map((row, index) => {
                                        if (row && row.IsShow) {
                                            return <tr key={row.title}>
                                                <td>{row.title}</td>
                                                <td>{row.isApiErrorTimeTrack ? 'Error' : row.ApiTime}</td>
                                                <td>{row.isRenderErrorTimeTrack ? 'Error' : row.ComponentTime}</td>
                                                <td>{row.isApiErrorTimeTrack || row.isRenderErrorTimeTrack ? 'Error' : row.LoadTime}</td>
                                                <td>{row.average ? row.average : 0}</td>
                                            </tr>;
                                        }
                                    })}
                                    <tr>
                                        <td>{overallEndMsg}</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>{overallEndTime}</td>
                                        <td>{overallEndAvg}</td>
                                    </tr>
                                </tbody>
                            </table>




                        </div>
                    </div>}
                {this.state.isChartLoaded && <div id={`chart-loaded_${parsedSymbolId}`}></div>}
            </>
        )
    }
}
