import keyMirror from "keymirror";

export const ResizeConstants = {
  ActionTypes: keyMirror({
    UPDATE_METRIC_LIBRARY_WIDTH: null,
    UPDATE_BROWSER_FILTER_WIDTHS:null,
    GET_USER_SETTINGS: null,
    NONE: null
  })
};
