import FormatterUtil from "FormatterUtil";
import { isString } from "underscore";

class ExtremeDataValue {
    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    abbreviateValue(value, decimal, isPositive = false,isKpiOrPE) {
        let absValue = Math.abs(value);
        let result = "";
        if (value === null || value == undefined) return result;
        let sign = isPositive ? "+" : "";
        if (absValue >= 1000000000000.0) {
            if(isKpiOrPE){
                if (absValue % 100000000000 > 0)
                    result = sign + parseFloat((value / 1000000000000.0).toFixed(3)) + "T";
                else
                    result = sign + (value / 1000000000000.0) + "T";
            }else{
                result = sign + (value / 1000000000000.0).toFixed(decimal) + "T";
            }
                
        } else if (absValue >= 1000000000.0) {
            if(isKpiOrPE){
                if (absValue % 100000000 > 0)
                    result = sign + parseFloat((value / 1000000000.0).toFixed(3)) + "B";
                else
                    result = sign + (value / 1000000000.0) + "B";
            }else{
                result = sign + (value / 1000000000.0).toFixed(decimal) + "B";
            }
        } else if (absValue >= 1000000.0) {
            if(isKpiOrPE){
                if (absValue % 10000000 > 0)
                    result = sign + parseFloat((value / 1000000.0).toFixed(3)) + "M";
                else
                    result = sign + (value / 1000000.0) + "M";
            }else{
                result = sign + (value / 1000000.0).toFixed(decimal) + "M";
            }
        } else if (absValue > 9999.0) {
            if(isKpiOrPE){
                if (value % 10000000 > 0)
                    result = sign + parseFloat((value / 1000.0).toFixed(3)) + "K";
                else
                    result = sign + parseFloat((value / 1000.0)) + "K";
            }else{
                result = sign + (value / 1000.0).toFixed(decimal) + "K";
            }
        }else if (absValue > 999.0 && isKpiOrPE) {
                if (absValue % 100 > 0)
                    result = sign + parseFloat((value / 1000.0).toFixed(decimal)) + "K";
                else
                    result = sign + (value / 1000.0) + "K";
        } else if (absValue > 99.0 && value.toFixed) {
            result = sign + value.toFixed(decimal);
        } else
            result = sign + value;

        return result;
    }
    
    abbreviatePivotValue(value, decimal, isPositive = false) {
        let absValue = Math.abs(value);
        let result = "";
        if (value === null || value == undefined) return result;
        let sign = isPositive ? "+" : "";
        if (absValue >= 1000000000000.0) {
            result = sign + (value / 1000000000000.0).toFixed(decimal) + "T";
        } else if (absValue >= 1000000000.0) {
            result = sign + (value / 1000000000.0).toFixed(decimal) + "B";
        } else if (absValue >= 1000000.0) {
            result = sign + (value / 1000000.0).toFixed(decimal) + "M";
        } else if (absValue > 999.0) {
            result = sign + (value / 1000.0).toFixed(decimal) + "K";
        } else if (absValue > 99.0 && value.toFixed) {
            result = sign + value.toFixed(decimal);
        } else
            result = sign + value;

        return result;
    }
    abbreviateKpiValueFormat(value) {
        let absValue = Math.abs(value);
        let result = "";
        if (value === null || value == undefined) return result;
        if (absValue >= 1000000000000.0) {
            if (value % 100000000000 != 0)
                result = parseFloat((value / 1000000000000.0).toFixed(6)) + "T";
            else
                result = parseFloat((value / 1000000000000.0)) + "T";
        } else if (absValue >= 1000000000.0) {
            if (value % 100000000 != 0)
                result = parseFloat((value / 1000000000.0).toFixed(6)) + "B";
            else
                result = parseFloat((value / 1000000000.0)) + "B";
        } else if (absValue >= 1000000.0) {
            if (value % 10000000 != 0)
                result = parseFloat((value / 1000000.0).toFixed(6)) + "M";
            else
                result = parseFloat((value / 1000000.0)) + "M";
        } else if (absValue > 9999.0) {
            if (value % 10000000 != 0)
                result = parseFloat((value / 1000.0).toFixed(3)) + "K";
            else
                result = parseFloat((value / 1000.0)) + "K";
        }
        else if (absValue > 1000.0) {
            if (value % 100 != 0)
                result = parseFloat((value / 1000.0).toFixed(2)) + "K";
            else
                result = parseFloat(value / 1000.0) + "K";
        } else{
            if(isNaN(value)){
                if(value.indexOf(",") > -1)
                    value = value.replace(',','');
            }
            result = this.numberWithCommas(value % 1 !== 0 ? Number(value).toFixed(2) : value);
        }

        return result;
    }
    abbreviateValueFormat(value, decimal, isPositive = false) {
        let absValue = Math.abs(value);
        let result = "";
        if (value === null || value == undefined) return result;
        let sign = isPositive ? "+" : "";
        if (absValue >= 1000000000000.0) {
            result = sign + this.numberWithCommas((value / 1000000000000.0).toFixed(decimal)) + "T";
        } else if (absValue >= 1000000000.0) {
            result = sign + this.numberWithCommas((value / 1000000000.0).toFixed(decimal)) + "B";
        } else if (absValue >= 1000000.0) {
            result = sign + this.numberWithCommas((value / 1000000.0).toFixed(decimal)) + "M";
        } else if (absValue > 9999.0) {
            result = sign + this.numberWithCommas((value / 1000.0).toFixed(decimal)) + "K";
        } else if (absValue > 99.0 && value.toFixed) {
            result = sign + this.numberWithCommas(value.toFixed(decimal));
        } else
            result = sign + this.numberWithCommas(value.toFixed(decimal));

        return result;
    }

    abbreviateValue2(value, decimal) {
        if (!value) return "";
        let absValue = Math.abs(value);
        if (absValue >= 1000000000000.0)
            return (value / 1000000000000.0).toFixed(0) + "T";
        if (absValue >= 1000000000.0)
            return (value / 1000000000.0).toFixed(0) + "B";
        if (absValue >= 1000000.0)
            return (value / 1000000.0).toFixed(0) + "M";
        if (absValue > 9999.0)
            return (value / 1000.0).toFixed(0) + "K";
        let nValue = Number(value);
        if (nValue)
            return nValue.toFixed(decimal);
        return value;
    }

    abbreviateFinancialValue(value, decimal, isPositive = false) {
        let absValue = Math.abs(value);
        let result = "";
        if (!value) return result;
        let sign = isPositive ? "+" : "";
        if (absValue >= 1000000000000.0) {
            result = sign + (value / 1000000000000.0).toFixed(decimal) + "T";
        } else if (absValue >= 1000000000.0) {
            result = sign + (value / 1000000000.0).toFixed(decimal) + "B";
        } else if (absValue >= 1000000.0) {
            result = sign + (value / 1000000.0).toFixed(decimal) + "M";
        } else if (absValue > 999.0) {
            result = sign + (value / 1000.0).toFixed(decimal) + "K";
        } else if (absValue > 99.0 && value.toFixed) {
            result = sign + value.toFixed(decimal);
        } else
            result = sign + value;

        return result;
    }

    abbreviateOverflowFinancialValue(value, decimal, isPositive = false) {
        let absValue = Math.abs(value);
        let result = "";
        if (!value) return result;
        let sign = isPositive ? "+" : "";
        if (absValue >= 1000000000000.0) {
            result = sign + (value / 1000000000000.0).toFixed(decimal) + "T";
        } else if (absValue >= 1000000000.0) {
            result = sign + (value / 1000000000.0).toFixed(decimal) + "B";
        } else if (absValue >= 1000000.0) {
            result = sign + (value / 1000000.0).toFixed(decimal) + "M";
        } else if (absValue > 999.0) {
            result = sign + (value / 1000.0).toFixed(decimal) + "K";
        } else if (absValue > 99.0 && value.toFixed) {
            result = sign + value.toFixed(decimal);
        } else if (value.toFixed)
            result = sign + value.toFixed(2);

        return result;
    }

    getFormattedNumber(numberValue, decimal = 0) {
        if (!isString(numberValue)) {
            numberValue = numberValue.toString();
        }
        numberValue = numberValue.replace(/,/g, "");
        return FormatterUtil.formatNumber(numberValue, decimal);
    }

    toLocalValue(value, precision) {
        return parseInt(value.toFixed(precision)).toLocaleString(undefined, { minimumFractionDigits: precision });
    }

    formatValue(value, precision) {
        return (+(Math.round(+(value + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
        //return value.toFixed(precision);
    }

    decimalFormat(value, decimalPlaces, prefix = "", isRoundUpReq) {
        if (decimalPlaces >= 0) {
            if (isRoundUpReq) {
                return Math.round(prefix + value.toFixed(decimalPlaces));
            } else {
                return prefix + value.toFixed(decimalPlaces);
            }
        }
        return prefix + value.toString();
    }

    getPercentageString(value, decimalPlaces = -1, showNegativePositive = true) {
        if (showNegativePositive) {
            if (decimalPlaces > 0) {
                return value.toFixed(decimalPlaces) + "%";
            }
            return value.toString() + "%";
        }
        return this.decimalFormat(value, decimalPlaces) + "%";
    }

    getPositiveNegativeString(value, decimalPlaces) {
        if (value > 0) {
            return "+" + value.toFixed(decimalPlaces);
        }
        return value.toFixed(decimalPlaces);
    }

    getAbbrivatedString(value, decimalPlaces, prefix = "", format = "#", isRoundUpReq = false) {
        let formatB = "B", formatM = "M", formatK = "K";
        if (format == "###") {
            formatB = "Bil";
            formatM = "Mil";
            formatK = "K";
        }
        let absVal = Math.abs(value);
        if (absVal > 1000000000) {
            return this.decimalFormat((value / 1000000000), decimalPlaces, prefix, isRoundUpReq) + formatB;
        }
        if (absVal > 1000000) {
            return this.decimalFormat((value / 1000000), decimalPlaces, prefix, isRoundUpReq) + formatM;
        }
        if (absVal > 1000) {
            return this.decimalFormat((value / 1000), decimalPlaces, prefix, isRoundUpReq) + formatK;
        }
        return this.decimalFormat(value, decimalPlaces, prefix, isRoundUpReq);
    }

    ReplaceNumberWithCommas(yourNumber) {
        var n = yourNumber.toString().split(".");
        n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return n.join(".");
    }

    showChartPrices(price,kpi) {
        if (price === undefined || price === null) return "";
        if (typeof price === "string") {
            price = parseFloat(price);
        }
        let decimals = 0;
        if (price * 100 % 100 > 0) {
            decimals = 2;
        }
        if(kpi){
           decimals = 3;
        }
        if (Math.abs(price) > 999999999999) {
            if (price % 1000000000000 > 0)
                return this.abbreviateValue(price, decimals,'',kpi);
            else
                return this.abbreviateValue(price,'','',kpi);
        } else if (Math.abs(price) > 999999999) {
            if (price % 1000000000 > 0)
                return this.abbreviateValue(price, decimals,'',kpi);
            else
                return this.abbreviateValue(price,'','',kpi);
        } else if (Math.abs(price) > 999999) {
            if (price % 1000000 > 0)
                return this.abbreviateValue(price, decimals,'',kpi);
            else
                return this.abbreviateValue(price,'','',kpi);
        } else if (Math.abs(price) > 99999) {
            if (price % 1000 > 0)
                return this.abbreviateValue(price, decimals,'',kpi);
            else
                return this.abbreviateValue(price,'','',kpi);
        } else if (Math.abs(price) > 999.99) {
            // return price.toFixed(0);
            if (price % 100 > 0)
                return this.abbreviateValue(price, decimals,'',kpi);
            else
                return this.abbreviateValue(price,'','',kpi);
        } else {
            return price;
        }
    }

    showHiLoPrices(price) {
        if (price === undefined || price === null) return "";

        if (Math.abs(price) > 999999999999) {
            if (price % 1000000000000 > 0)
                return this.abbreviateValue(price, 2);
            else
                return this.abbreviateValue(price);
        } else if (Math.abs(price) > 999999999) {
            if (price % 1000000000 > 0)
                return this.abbreviateValue(price, 2);
            else
                return this.abbreviateValue(price);
        } else if (Math.abs(price) > 999999) {
            if (price % 1000000 > 0)
                return this.abbreviateValue(price, 2);
            else
                return this.abbreviateValue(price);
        } else if (Math.abs(price) > 99999) {
            if (price % 1000 > 0)
                return this.abbreviateValue(price, 2);
            else
                return this.abbreviateValue(price);
        } else if (Math.abs(price) > 999.99) {
            return price.toFixed(0);
        } else if (Math.abs(price) < 1.0) {
            return price.toFixed(3);
        } else
            return price.toFixed(2);
    }

    round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    showPrice(price, dec = true) {
        if (price === undefined) return "";

        const currPrice = this.round(price, 3) + (price < 0 ? -1e-6 : 1e-6);

        if (Math.abs(currPrice) > 999999) {
            return this.abbreviateValue(currPrice, 2)
        } else if (Math.abs(currPrice) > 999.99) {
            return this.ReplaceNumberWithCommas(parseInt(currPrice + (currPrice < 0 ? - 0.5 : 0.5)));
        } else if (Math.abs(currPrice) < 1.0 && dec) {
            return currPrice.toFixed(3);
        } else {
            return currPrice.toFixed(2);
        }   
    }

    formatPrice(price) {
        if (price === undefined || price === null) return "";
        const nPrice = price < 1.0 ? price.toFixed(3) : this.ReplaceNumberWithCommas(price.toFixed(2));
        return nPrice;
    }

    chgs(chg, isPrice = true) {
        let price;
        let round;
        price = Math.abs(chg);
        round = price < 1.0 && isPrice ? 3 : 2;
        price = price.toFixed(round);
        let chgs = price > 999999 ? "To Do" : price > 999999 ? price.toFixed() : price;
        return chgs;
    }

    abbreviateValWithDecLimit(value, isPositive, isFixedDecimal, oneDecimalLimit, twoDecimalLimit, threeDecimalLimit,
    fourDecimalLimit) {
        const absValue = Math.abs(value);
        const sign = isPositive ? "+" : "";
        let formattedValue;
        let decimal;

        if (isFixedDecimal) {
            decimal = 0;
            if (absValue < oneDecimalLimit) {
                decimal = 1;
            }
            if (absValue < twoDecimalLimit) {
                decimal = 2;
            }
            if (absValue < threeDecimalLimit) {
                decimal = 3;
            }
            if (absValue < fourDecimalLimit) {
                decimal = 4;
            }
        } else {
            decimal = 0;
            if (absValue < oneDecimalLimit) {
                decimal = 1;
            }
            if (absValue < twoDecimalLimit) {
                decimal = 2;
            }
            if (absValue < threeDecimalLimit) {
                decimal = 3;
            }
            if (absValue < fourDecimalLimit) {
                decimal = 4;
            }
        }

        if (absValue >= 1000000000000.0) {
            formattedValue = sign + (value / 1000000000000.00).toFixed(decimal) + "T";
        } else if (absValue >= 1000000000.0) {
            formattedValue = sign + (value / 1000000000.00).toFixed(decimal) + "B";
        } else if (absValue >= 1000000) {
            formattedValue = sign + (value / 1000000.00).toFixed(decimal) + "M";
        } else if (absValue >= 1000) {
            formattedValue = sign + (value / 1000.00).toFixed(decimal) + "K";
        } else {
            formattedValue = sign + value.toFixed(decimal);
        }

        return formattedValue;
    }

    abbreviateSmallValueFixed(value) {
        return this.abbreviateValWithDecLimit(value, false, true, 100000000.0, 1000000.0, 0.05, 0.01);
    }

    abbreviateSmallValueWithEnforedTwoDecimal(value) {
        if (value !== null) {
            return this.abbreviateValWithDecLimit(value, false, true, 10000.0, 1000.0, 0.05, 0.01);
        }

    }

    getPriceString(last, dec = 0) {
        if (last === undefined) return "";
        let Last = this.round(Math.abs(last), 3);
        if (Last < 1.0 && dec == 0) {
            Last = this.round(Last, 3) + 1e-4;
        } else {
            Last = this.round(Last, 2) + 1e-3;
        }
        const lastMult = last < 0 ? -1 : 1;
        if (Last > 999999) {
            return this.abbreviateValue(Last * lastMult, 2);
        } else if (Last > 99999) {
            return this.abbreviateValue(Last * lastMult);
        } else if (Last > 999.99) {
            if (((Last + 0.5) * lastMult * 100) % 100 === 0 || Last > 999.99) {
                const newLast = lastMult < 0 ? Math.ceil((Last + 0.5) * lastMult) : Math.floor((Last + 0.5) * lastMult) ;
                return this.ReplaceNumberWithCommas(newLast);
            } else {
                return this.ReplaceNumberWithCommas(((Last + 0.5) * lastMult).toFixed(2));
            } 
        } else if (Last < 1.0 && dec === 0) {
            return (Last * lastMult).toFixed(3);
        } else {
            return (Last * lastMult).toFixed(2);
        }
    }
}

const extremeDataValue = new ExtremeDataValue();
export default extremeDataValue;
