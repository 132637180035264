import React, {Fragment} from "react";
import { Modal, ControlLabel, FormGroup, Form } from "react-bootstrap";
import { updateStorewithSharedData, SaveSharedData, setConfirmationFlag,getLength } from "../../../Actions/ShareAction";
import { ShareActionConstants, ShareAccessType } from "../../../Constants/ShareConstants";
import SharedData from "./SharedData.jsx";
import AddRecepient from "./AddRecepient.jsx";
import RecepientsPage from "./Recepients/RecepientsPage.jsx";
import AccessLevel from "./AccessLevel.jsx";
import Permission from "./Permission.jsx";
import Confirmation from "./Confirmation.jsx";
import LocalizationStore from 'LocalizationStore';
import LangType from "../../../Constants/LangType";
// import ScrollBar from "ScrollBar";

import { find } from "underscore";

const ShareDataPage = ({
    sharedData,
    targetData,
    userList,
    sharedAction,
    showUsers,
    nameFilter,
    savedSharedData,
    showConfirmation
}) => {
    const updateStorewithData = (e) => {
        const nodetData = {
            showSharedDialog: false,
            id: undefined,
            name: undefined,
            type: undefined,
            action: sharedAction
        }
        updateStorewithSharedData(nodetData);
    }

    const header = targetData.name.split(',')
    const headerLength = header.length;
    let displayHeader = '';
    let notCountedList = 0;
    let numberofList = 0;
    let countList = '';

    for (var i = 0; i < headerLength; i++) {
        displayHeader = displayHeader === '' ? `${header[i]}` : `${displayHeader}, ${header[i]}`;
        let displayHeaderLength = getLength(displayHeader);
        if (displayHeaderLength > 64) {
            notCountedList = headerLength == 1 ? headerLength - i : headerLength - (i + 1);
            numberofList = notCountedList === 1 ? `1 ${LocalizationStore.getTranslatedData("SourceType_1","list")}` : `${notCountedList} ${LocalizationStore.getTranslatedData("set_shar_lists","lists")}`;

            if (i === 0) {
                if (headerLength > 1)
                    {
                        displayHeader = notCountedList === 1 ?
                            `${displayHeader.slice(0, 64)}... `
                            : `${displayHeader.slice(0, 63)}... `;
                        countList = `+ ${numberofList}`;
                    }
                else
                    displayHeader = `${displayHeader.slice(0, 70)}...`;
            }
            else {
                notCountedList = notCountedList + 1;
                numberofList = notCountedList === 1 ? `1 ${LocalizationStore.getTranslatedData("SourceType_1","list")}` : `${notCountedList} ${LocalizationStore.getTranslatedData("set_shar_lists","lists")}`;
                displayHeader = `${displayHeader.slice(0, displayHeader.length - (header[i].length + 2))} `;
                countList = `+ ${numberofList}`
            }
            break;
        }
    }

    const saveSharedData = (event) => {
        SaveSharedData('shared', savedSharedData, targetData, sharedAction);
    }

    const checkSaveNeeded = () => {
        setConfirmationFlag(savedSharedData, targetData.ntid, sharedAction);
    }
    const heading =
        {
            [ShareActionConstants.SHARE_LIST]: LocalizationStore.getTranslatedData("set_shar_shareList", 'SHARE LIST'),
            [ShareActionConstants.SHARE_COLUMN_SET]: LocalizationStore.getTranslatedData('LM_TP_ShareColSet', 'SHARE COLUMN SET'),
            [ShareActionConstants.SHARE_ANNOTATIONS]: LocalizationStore.getTranslatedData('ri_id30', 'SHARE ANNOTATION LAYER'),
            [ShareActionConstants.SHARE_RECIPIENTS]: LocalizationStore.getTranslatedData('set_shar_manageRecipient', 'MANAGE RECIPIENT'),
            [ShareActionConstants.SHARE_EXTERNAL_DATA]:'SHARE DATA SET',
        }[sharedAction]

    const nodeType =
        {
            [ShareActionConstants.SHARE_LIST]: 'List',
            [ShareActionConstants.SHARE_COLUMN_SET]: 'Column Set',
            [ShareActionConstants.SHARE_ANNOTATIONS]: 'Annotation',
            [ShareActionConstants.SHARE_RECIPIENTS]: 'Manage Recipient',
            [ShareActionConstants.SHARE_EXTERNAL_DATA]: 'External'
        }[sharedAction]

    const translateTitle = (nodeType) => {
        switch (nodeType) {
            case 'List':
                return LocalizationStore.getTranslatedData("set_shar_listSharMsg", "This list is shared with:")
            case 'Column Set':
                return LocalizationStore.getTranslatedData("LM_TP_ColSetShartWith", "This column set is shared with:")
            case 'Annotation':
                return LocalizationStore.getTranslatedData("ri_id34", "This annotation layer is shared with:")
            case 'Manage Recipient':
                return targetData.name
            case 'External':
                return "This data set is shared with:"
            default:
                break;
        }
    }
    const isShared = find(sharedData, rowData => rowData.isShared === true);
    const enableAccess = sharedData.length > 0;
    const showSharedData = sharedData && sharedData.length > 0;
    const title = translateTitle(nodeType);
    const titleFront = LocalizationStore.getLang() === LangType.ZH_CN ?
        `${LocalizationStore.getTranslatedData("set_shar_itemSharMsg_1", "Items shared with ", "William O'Neil + Company")} `
        : "Items shared with ";
    const titleEnd = LocalizationStore.getLang() === LangType.ZH_CN ?
        LocalizationStore.getTranslatedData("set_shar_itemSharMsg_2", "at William O'Neil + Company")
        : "at William O'Neil + Company";
    const isIcs = targetData.name.toUpperCase() === 'Institutional Client Services'.toUpperCase()    
    return (
        <Fragment>
            <div className="share-modals" data-test-id="main-div">
                <Modal.Header className="enable-drag">
                    <Modal.Title className="enable-drag">
                        <button
                            type="button"
                            className="btn btn-xs btn-default btn-secondary btn-manage"
                            data-effect="fadeOut"
                            onClick={checkSaveNeeded}
                            disabled={showUsers}
                        >
                            {LocalizationStore.getTranslatedData("ri_id20_01", "Manage")}
                        </button>
                        <span className="cap-header">{heading}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="disable-drag">
                    <div className="disable-drag modal-region">
                        <Form inline className="share-main-container">
                            {
                                sharedAction !== ShareActionConstants.SHARE_RECIPIENTS &&
                                <div className="row">
                                    <div className="col-xs-12">
                                        <span className="share-names disable-drag">{displayHeader}</span>
                                        <span className="share-counts disable-drag">&nbsp;{countList}</span>
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-xs-12">
                                {
                                    sharedAction !== ShareActionConstants.SHARE_RECIPIENTS ?
                                        <Fragment>
                                            <span className="share-heading disable-drag"> {title} </span>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <span className="share-heading disable-drag"> {titleFront} </span>
                                            <span className="share-heading disable-drag"> &nbsp;<strong>{title}</strong>&nbsp; </span>
                                            <span className="share-heading disable-drag"> {titleEnd} </span>
                                        </Fragment>
                                }
                                {
                                    sharedAction !== ShareActionConstants.SHARE_RECIPIENTS &&
                                    <AddRecepient
                                        caller={'header'}
                                        targetData={targetData}
                                        sharedAction={sharedAction}
                                    />
                                }
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12">
                                    <SharedData
                                        sharedData={sharedData}
                                        sharedAction={sharedAction}
                                        targetData={targetData}
                                        isIcs={isIcs}
                                    />
                                </div>
                            </div>

                            <div className="share-actions">
                                <li><span className="heads">{LocalizationStore.getTranslatedData("ri_id43", "Apply to all:")}</span></li>
                                <li>
                                    <AccessLevel
                                        accessLvl={ShareAccessType.SHARE_READANDEDIT}
                                        recipientid={0}
                                        isShared={enableAccess}
                                        isICS={isIcs}
                                        sharedData={sharedData}
                                        sharedAction={sharedAction}
                                    />
                                </li>
                                <li>
                                    <AccessLevel
                                        accessLvl={ShareAccessType.SHARE_READONLY}
                                        recipientid={0}
                                        isShared={enableAccess}
                                        isICS={isIcs}
                                        sharedData={sharedData}
                                        sharedAction={sharedAction}
                                    />
                                </li>
                                <li>
                                    <Permission
                                        isShared={isShared}
                                        recipientid={0}
                                        sharedData={sharedData}
                                    />
                                </li>
                            </div>
                        </Form>
                        <div className="clearfix"></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn-box righter">
                        <button type="button"
                                className="btn btn-xs btn-default btn-secondary"
                                data-effect="fadeOut"
                                onClick={updateStorewithData}
                                disabled={showUsers}
                        >
                            {LocalizationStore.getTranslatedData("cancel", "CANCEL")}
                        </button>
                        <button type="button"
                                className="btn btn-xs btn-default btn-secondary"
                                data-effect="fadeOut"
                                onClick={saveSharedData}
                                disabled={showUsers || !showSharedData}
                        >
                            {LocalizationStore.getTranslatedData("done", "DONE")}
                        </button>
                    </span>
                </Modal.Footer>
            </div>
            {
                showUsers &&
                <RecepientsPage
                    sharedData={sharedData}
                    userList={userList}
                    sharedAction={sharedAction}
                    nameFilter={nameFilter}
                    savedSharedData={savedSharedData}
                />
            }
            {
                showConfirmation &&
                <Confirmation
                    savedSharedData={savedSharedData}
                    sharedAction={sharedAction}
                    targetData={targetData}
                    x_parent={650}
                    y_parent={350}
                />
            }
        </Fragment>
    );
}

export default ShareDataPage;