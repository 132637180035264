import { RayAnnotationType } from 'Constants/EAnnotationType';

export class TextInfo {
    constructor() {
        this.AnnotationType = RayAnnotationType.TEXT_AnnotationType;
        this.Text = "";
        this.ObjectBrushHex = "#FF0066CC";
        //this.OriginalObjectBrushHex = ObjectBrushHex;
        this.TextFontFamilyName = "Calibri";
        this.TextFontSize = 14;
        this.LinkUrl = "";
        this.BackgroundBrushHex = "#FF000000";
        //this.OriginalBackgroundBrushHex = BackgroundBrushHex;
        this.Opacity = 100;
        this.BorderBrushHex = "#FF0066CC";
        //this.OriginalBorderBrushHex = BorderBrushHex;
        this.BorderLineWidth = 1.0;
        //this.AnnotationBorderDashType = AnnotationDashes.Solid;
        this.IsUnderlined = false;
        this.IsBold = true;
        this.IsItalicized = false;
        this.IsBorderEnabled = true;
        //this.TextAlignment = TextAlignment.Left;
    }
}