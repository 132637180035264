import Serializable from "Serializable";
class IndexLineSettings extends Serializable {

    constructor(isVisible) {
        super();
        this.IsAvailable = true;
        this.IsVisible = isVisible;
        this.IndexSymbol = undefined;
        this.lineColor = "P4C4C4C999999";
        this.lineThickness = 1;
        this.valueType = "default";
        this.region = "North America";
        this.country = "United States";
        this.countryIndex = "0S&P5";
        this.lineData = [];
        this.selectedGroup = 20;
    }

    onSeralisedInfoRecieved(info) {
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
        this.IsAvailable = true;
        this.IndexSymbol = info.IndexSymbol !== undefined ? info.IndexSymbol : undefined;
        this.lineColor = info.lineColor !== undefined ? info.lineColor : 'P4C4C4C999999';
        this.lineThickness = info.lineThickness !== undefined ? info.lineThickness : 1;
        this.valueType = info.valueType !== undefined ? info.valueType : "default";
        this.region = info.region !== undefined ? info.region : "North America";
        this.country = info.country !== undefined ? info.country : "United States";
        this.countryIndex = info.countryIndex !== undefined ? info.countryIndex : "0S&P5";
        this.lineData = info.lineData !== undefined ? info.lineData : [];
        this.selectedGroup = info.selectedGroup != undefined ? info.selectedGroup : 20;
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            IndexSymbol: this.IndexSymbol,
            lineColor: this.lineColor,
            lineThickness: this.lineThickness,
            valueType: this.valueType,
            region: this.region,
            country: this.country,
            countryIndex: this.countryIndex,
            lineData: this.lineData,
            selectedGroup: this.selectedGroup
        };
    }

    getObjectMapKey() {
        return "IndexLineSettings";
    }
}

export default IndexLineSettings;
