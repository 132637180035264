//import GraphType from "../../../../../Constants/GraphType.js";
import Serializable from "Serializable";
class MovingAverageSettings extends Serializable {

  onSeralisedInfoRecieved(info) {
    this.Height = info.Height ? info.Height : 50;
    this.ma = info.ma !== undefined ? info.ma : 0;
    this.maType = info.maType !== undefined ? info.maType : 'SMA';
    this.lineColor = info.lineColor !== undefined ? info.lineColor : '#008B02';
    this.lineThickness = info.lineThickness !== undefined ? info.lineThickness : 1;
    this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
    this.IsAvailable = info.IsAvailable !== undefined ? info.IsAvailable : true;
    this.AlertIsActive = false; //This property will gets updated from Alerts Data Worker
  }
  getDataObject() {
    return {
      ma: this.ma,
      maType: this.maType,
      lineColor: this.lineColor,
      lineThickness: this.lineThickness,
      Height: this.Height,
      IsVisible: this.IsVisible,
      IsAvailable: this.IsAvailable,
      AlertIsActive: this.AlertIsActive
    };
  }
  getObjectMapKey() {
    return "MovingAverageSettings";
  }
  constructor(ma, maType, isVisible, lineColor, lineThickness) {
    super();
    this.IsAvailable = true;
    this.IsVisible = isVisible;
    this.ma = ma;
    this.maType = maType;
    this.lineColor = lineColor;
    this.lineThickness = lineThickness;
    this.Height = 50;
    this.AlertIsActive = false;
    // switch (periodicity) {
    //   case GraphType.Daily:
    //     this.ma[0] = 50;
    //     this.ma[1] = 200;
    //     break;
    //   case GraphType.Weekly:
    //     this.ma[0] = 10;
    //     this.ma[1] = 40;
    //     break;
    //   case GraphType.Monthly:
    //     this.ma[0] = 6;
    //     this.ma[1] = 0;
    //     break;
    //   case GraphType.Annual:
    //     this.ma[0] = 6;
    //     this.ma[1] = 0;
    //     break;
    //   case GraphType.Quarterly:
    //     this.ma[0] = 6;
    //     this.ma[1] = 0;
    //     break;
    //   default:
    //     this.ma[0] = 0;
    //     this.ma[1] = 0;
    // }
  }
}

export default MovingAverageSettings;
