import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';

class GetSnapShotRequest extends BaseServiceRequestIIS {
  constructor(symbol, graphtype, enddate, startdate, wonOnly = 1, revWonOnly = 0) {
    super();
    super.addRequestUri("FindGetSnapShot");
    super.addPathParameter("symbol", symbol);
    super.addPathParameter("graphType", graphtype);
    super.addPathParameter("wonOnly", wonOnly);
    super.addPathParameter("revWonOnly", revWonOnly);
    super.addPathParameter("endDate", enddate);
    super.addPathParameter("startDate", startdate);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return undefined;
  }
}
export default GetSnapShotRequest;
