
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { SettingsConstants } from "../../../Constants/SettingsConstants";

const { ActionTypes } = RiPanelConstants;

const initState = {
    loading: true,
    holdingsDataList: [],
    quarterEnd13FDate: '',
    isNoDataAvailable: false,
};

const RiPanelHoldingsReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_HOLDINGS_DATA_LIST:
            return {
                ...state,
                holdingsDataList: action.holdingsDataList,
                quarterEnd13FDate: action.quarterEnd13FDate,
                loading: action.loading,
                isNoDataAvailable: action.isNoDataAvailable,
            };
        case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
            return {
                ...initState
            }
        default:
            return state;
    }
};

export default RiPanelHoldingsReducer;
