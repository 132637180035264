import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";

const revenueGrowthRateText = LocalizationStore.getTranslatedData("sb_revenuegrrt", "Revenue Growth Rate");
const earingsGrowthRateText = LocalizationStore.getTranslatedData("sb_erngrrt", "Earnings Growth Rate");
const salesGrowthRateText = LocalizationStore.getTranslatedData("sb_slsgrrt", "Sales Growth Rate");
const stabilityText = LocalizationStore.getTranslatedData("sb_stability", " Stability");

export default class GrowthRateData {
  constructor(data) {
    this.growthEarnDt = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(data.GrowthEarnDt), data.StockInfo.MomentZoneID);
    this.stockinfo = data.StockInfo;
    this.positiveColor = ThemeHelper.getThemedBrush("positiveDataText");
    this.negativeColor = ThemeHelper.getThemedBrush("negativeDataText");
    this.setGrowthRateData(data);

  }

  setGrowthRateData(miscData) {
    const data = miscData.AnnualMiscBlockData;
    if (!this.stockinfo) {
      return;
    }
    this.IsRealEstateInvestmentTrust = this.stockinfo.SymTypeEnum === SymbolType.REIT;
    if (this.stockinfo.SymTypeEnum === SymbolType.REIT) {
      this.earningHeader = "";
      this.salesHeader = revenueGrowthRateText;

    } else {
      this.earningHeader = earingsGrowthRateText;
      this.salesHeader = salesGrowthRateText;
    }

    if (this.growthEarnDt) {
      this.grthRt3YrsDisplay = `${(this.growthEarnDt.getFullYear() - 2).toString()}-${this.growthEarnDt.getFullYear().toString().substr(2, 2)}`;
      if (typeof data.EarnGrt3 === "number") {
        this.grthRt3YrsEarning = `${ExtremeDataValue.formatValue(data.EarnGrt3, 0)}%`;
        this.grthRt3YrsEarningColor = data.EarnGrt3 >= 0 ? this.positiveColor : this.negativeColor;

        this.isGrthRt3YrsEarningPositive = data.EarnGrt3 >= 0;
      }
      if (typeof data.EarnSbt3 === "number") {
        this.grthRt3YrsStabilty = `(${ExtremeDataValue.formatValue(data.EarnSbt3, 0)}${stabilityText} )`;
      }
      if (typeof data.SaleGrt3 === "number") {
        this.grthRt3YrsSales = `${ExtremeDataValue.formatValue(data.SaleGrt3, 0)}%`;
        this.grthRt3YrsSalesColor = data.SaleGrt3 >= 0 ? this.positiveColor : this.negativeColor;
        this.isGrthRt3YrsSalesPositive = data.SaleGrt3 >= 0;
      }

      this.grthRt5YrsDisplay = `${(this.growthEarnDt.getFullYear() - 4).toString()}-${this.growthEarnDt.getFullYear().toString().substr(2, 2)}`;
      if (typeof data.EarnGrt5 === "number") {
        this.grthRt5YrsEarning = `${ExtremeDataValue.formatValue(data.EarnGrt5, 0)}%`;
        this.grthRt5YrsEarningColor = data.EarnGrt5 >= 0 ? this.positiveColor : this.negativeColor;

        this.isGrthRt5YrsEarningPositive = data.EarnGrt5 >= 0;
      }
      if (typeof data.EarnSbt5 === "number") {
        this.grthRt5YrsStabilty = `(${ExtremeDataValue.formatValue(data.EarnSbt5, 0)}${stabilityText} )`;
      }
      if (typeof data.SaleGrt5 === "number") {
        this.grthRt5YrsSales = `${ExtremeDataValue.formatValue(data.SaleGrt5, 0)}%`;
        this.grthRt5YrsSalesColor = data.SaleGrt5 >= 0 ? this.positiveColor : this.negativeColor;

        this.isGrthRt5YrsScalesPositive = data.SaleGrt5 >= 0;
      }
    }
  }
}