import SettingsStore from 'SettingsStore';
import NavType from '../Constants/NavType';
import LocalizationStore from "LocalizationStore";
import LangType from '../Constants/LangType';

export const PrintMode = {
    printing: false,
    workspaceId: '',
    width: 1346, // Hard coding the value since the width of the price chart continer in print mode is 1346px always.
    ActiveNav: NavType.NavSymbol,
    symbol: LocalizationStore.getLang() === LangType.ZH_CN ? "KCM.CN" : "FB",
    updateExternalScore: (score)=>{
        const settings = SettingsStore.getConsoleSettings();
        const { msId } = settings.NavDatagraphSettings;
        settings.printSettings.externalScore[msId] = score;
        SettingsStore.saveSettings();
    }
}