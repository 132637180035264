import React from "react";
import MiniListStore from "MiniListStore";
import { each } from "underscore";
import onClickOutside from "react-onclickoutside";
import BrowserUtil from "BrowserUtil";
import ONeilViewStore from "ONeilViewStore";
import SettingsStore from "SettingsStore";
import ListManagerTabType from "ListManagerTabType";
import GridActions from "../../../../Actions/GridActions.js"
import StringUtil from "StringUtil";
import gridStore from "../../../../Stores/NavModules/NavList/Grid/GridStore.js";

class MiniListContextMenu extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClickOutside() {
        try {
            this.props.hideContextMenu();
        }
        catch (ex) {
            console.log(ex);
        }
    }

    createMiniListContextMenu() {
        let contextMenu = MiniListStore.getMiniListContextMenu();
        return contextMenu.map((entry, i) => {
            if (!StringUtil.isEmpty(entry)) {
                return <li key={i}><a onClick={this.onMiniListContextMenuItemClick.bind(this, entry.action)} >{entry.contextName}</a></li>;
            }
        });
    }

    onMiniListContextMenuItemClick(action) {
        let settings = SettingsStore.getConsoleSettings();
        let navListManagerSettings = settings.NavListManagerSettings;
        let snapshotDates;        
        if (action) {
            setTimeout(() => {
                BrowserUtil.disableEvents();
                let msIDS = this.props.contextMenuObj.msIDS;
                if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Oneil) {
                    snapshotDates = ONeilViewStore.getSnapshotDateFromMsIds(msIDS.split(';'));
                  }
                  else{
                    snapshotDates="";
                  }
                GridActions.removeGridRows(this.props.contextMenuObj.listId, this.props.contextMenuObj.cacheId, msIDS, snapshotDates, true);
                this.props.clearAlertMessageInterval();
            }, 0);
            this.props.hideContextMenu();
        }
        return null;
    }

    getMaxWidth(contextMenu, font, maxWidth = 0) {
        each(contextMenu, (item) => {
            let width = item.contextName.getTextWidth(font);
            if (maxWidth <= width)
                maxWidth = width;
        });
        return maxWidth;
    }

    createDivStyle() {
        let topCal = this.props.contextMenuObj.yPos + 10;
        let leftCal = this.props.contextMenuObj.xPos + 10;
        return {
            position: "absolute",
            left: leftCal,
            top: topCal,
            zIndex: "2",
            textAlign: "left",
            width: "93px"
        };
    }

    render() {
        if (this.props.showContextMenu) {
            let contextMenuList = this.createMiniListContextMenu();
            return (
                <div id='MiniListContextMenu' ref={(ref) => { this.MiniListContextMenu = ref }} className="context-menu dropdown" style={this.createDivStyle()}>
                    <ul className=" context-menu-inner list-contex  small-normal" style={{ padding: "0px" }} aria-labelledby="contextMenu">{contextMenuList}</ul>
                </div>
            );
        }
        return (
            null
        );
    }
}

const miniListContextMenu = onClickOutside(MiniListContextMenu);
export default miniListContextMenu;