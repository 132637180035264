/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import DgTabHeaderHoldings from "./DgTabHeaderHoldings.jsx";
import DGGridSettings from "../../../../RayCustomControls/DGGrid/DGGridSettings";
import DGGridColumn from "../../../../RayCustomControls/DGGrid/DGGridColumn.jsx";
import DgTabHoldingsHeaderRow from "./DgTabHoldingsHeaderRow.jsx";
import { getReportExtdata, changeTextSize, columnRsChanged, columnPortChanged, columnSharesChanged, showHoldingsLoader, tabSelectionChanged, subTabSelectionChanged, columnSortSeqChanged, limitChange, onSearchTextChange, updateFlag } from "./../../../../Actions/DgHoldingsAction.js";
import { connect } from "react-redux";
import textWidth from "text-width";
import StringUtil from "StringUtil";
import moment from "moment";
import DateHelper from "DateHelper";
import { map, each, find } from "underscore";
import ExtremeDataValue from "ExtremeDataValue";
import ScrollBar from "ScrollBar";
import ListStore from "ListStore";
import ListActions from "ListActions";
import DatagraphStore from "../../../../Stores/NavModules/NavDataGraph/DataGraphStore";
import { DgHoldingsSubCategoryType } from "../../../../Constants/DgHoldingsSubCategoryType.js";

let isHideToolTopBox = false;
class DgTabHoldings extends React.Component {

    onLimitChange = (limitData) => {
        this.props.showLoader(true);
        this.props.limitChange(limitData);
        isHideToolTopBox = false;
    }

    onBlockMenuItemRightClicked = (msid, isFlagged) => {
        this.updateFlag(msid, isFlagged);        
    }

    AbbreviateSharesValueForHeader = (shares) => {
        if (shares === null || shares === 0) {
            return "0";
        }
        if (shares < 10000000000) {
            if (shares < 9900 && shares > -9900)
                return ExtremeDataValue.numberWithCommas( (shares == 0.0 ? 0 : (shares).toFixed(1)));
                //return ExtremeDataValue.numberWithCommas((shares / 1000).toFixed(0));
            else
                return ExtremeDataValue.numberWithCommas(Math.round(shares, 1));
        }
        else if (shares < 100000000000) {
            return ExtremeDataValue.numberWithCommas(Math.round(shares, 1));
        }
        else {
            return ExtremeDataValue.numberWithCommas((shares).toFixed(2));
        }
    }

    onColumnRsChanged = () => {
        this.props.columnRsChanged(!this.props.isColumnRsChanged);
    }

    onColumnPortChanged = () => {
        this.props.columnPortChanged(!this.props.isColumnPortChanged);
    }

    onColumnSharesChanged = () => {
        this.props.columnSharesChanged(!this.props.isColumnSharesChanged);
    }

    onTabSelectionChanged = (tabIndex) => {
        this.props.showLoader(true);
        this.props.tabSelectionChanged(tabIndex);
    }

    onSubTabSelectionChanged = (subTabIndex) => {
        this.props.showLoader(true);
        this.props.subTabSelectionChanged(subTabIndex);
    }

    onColumnHeaderClicked = (event, val) => {
        let orderSeqArr = this.props.orderSeqArr;
        let isCtrlKey = false;

        if ((event.ctrlKey || event.metaKey)) {
            isCtrlKey = true;
        }

        let columnName;
        let orderString = "";
        
        switch (val) {
            case 1:
                columnName = "Symbol"
                break;
            case 2:
                columnName = "StockName"
                break;
            case 3:
                if (this.props.isColumnRsChanged === true)
                    columnName = "RS";
                else
                    columnName = "YTD";
                break;
            case 4:
                    columnName = "PctOfPort";
                break;
            case 5:
                if (this.props.isColumnSharesChanged === true)
                    columnName = "SharesHeld";
                else
                    columnName = "SharesHeld-Shares1QAgo";
                break;
            case 6:
                if (this.props.isColumnSharesChanged === true)
                    columnName = "Shares1QAgo";
                else
                    columnName = "Shares1QAgo-Shares2QAgo";
                break;
            case 7:
                if (this.props.isColumnSharesChanged === true)
                    columnName = "Shares2QAgo";
                else
                    columnName = "Shares2QAgo-Shares3QAgo";
                break;
            default:
                break;
        }

        const obj = find(orderSeqArr,  (obj) => obj.columnName === columnName);

        if (StringUtil.isEmpty(obj)) {
            if (!isCtrlKey)
                orderSeqArr = [];

            if(columnName === "Symbol" || columnName === "StockName")
                orderSeqArr.push({ columnName: columnName, order: "asc" });
            else 
                orderSeqArr.push({ columnName: columnName, order: "desc" });
        }
        else if (obj.order === "asc") {
            if (!isCtrlKey) {
                orderSeqArr = [];
                orderSeqArr.push({ columnName: columnName, order: "desc" });
            }
            else
                obj.order = "desc";
        }
        else if (obj.order === "desc") {
            if (!isCtrlKey) {
                orderSeqArr = [];
                orderSeqArr.push({ columnName: columnName, order: "asc" });
            }
            else
                obj.order = "asc";
        }

        each(orderSeqArr, (item) => {
            orderString += `,${  item.columnName  } ${  item.order}`;
        })

        orderString = orderString.substring(1, orderString.length);

        this.props.showLoader(true);

        this.props.columnSortSeqChanged(orderString, orderSeqArr)
    }

    ShareColumnChangeStyle = () => {
        if (this.props.isColumnSharesChanged !== 0) {
            if (value.shares1QAgo - value.shares2QAgo > 0) { color = '@ownership-pos' }
            else { color = '@ownership-neg' }
        }
    }

    onSearchTextChange = (searchText) => {
        this.props.onSearchTextChange(searchText)
    }

    plotSymbol = (sym) => {
        ListStore.plotSymbol(sym);
        ListActions.updateMiniList(sym);
    }

    getReportHeaderHTML = () => {
        const self = this;
        const reporHeadData = this.props.holdingsReportData.data;
        const activeTab = "DgHoldings";
        const tablesHeader = map(reporHeadData, (item, i) => (
                <DgTabHoldingsHeaderRow
                    key={i}
                    value={item}
                    currentTab={self.props.mainState.currentTab}
                    currentSubTab={self.props.mainState.currentSubTab}
                    isColumnPortChanged={self.props.isColumnPortChanged}
                    isColumnRsChanged={self.props.isColumnRsChanged}
                    isColumnSharesChanged={self.props.isColumnSharesChanged}
                    orderString={self.props.mainState.orderString}
                    orderSeqArr={self.props.orderSeqArr}
                    //childrenonColumnHeaderChanged={self.props.childrenonColumnHeaderChanged}
                    onColumnHeaderClicked={self.onColumnHeaderClicked}
                    i={i}
                    activeTab={activeTab}
                />
            ));
        return tablesHeader;
    }

    getReportHTML = () => {
        
        const self = this;
        const reporData = this.props.holdingsReportData.data;
        const activeTab = "DgHoldings";
        
        const tables =
            <div className="report-holder-grid">
                <DGGridColumn
                    // key={`column${i}`}
                    // i={i}
                    item={reporData}
                    isColumnPortChanged={self.props.isColumnPortChanged}
                    isColumnRsChanged={self.props.isColumnRsChanged}
                    isColumnSharesChanged={self.props.isColumnSharesChanged}
                    currentTab={self.props.mainState.currentTab}
                    currentSubTab={self.props.mainState.currentSubTab}
                    orderString={self.props.mainState.orderString}
                    orderSeqArr={self.props.orderSeqArr}
                    textSize={self.props.textSize}
                    searchText={self.props.searchText}
                    onPlotSymbol={this.plotSymbol}
                    onColumnRsChanged={self.onColumnRsChanged}
                    onColumnPortChanged={self.onColumnPortChanged}
                    onColumnSharesChanged={self.onColumnSharesChanged}
                    AbbreviateSharesValueForHeader={self.AbbreviateSharesValueForHeader}
                    onMessageMouseMove={self.onMessageMouseMove}
                    onMessageMouseLeave={self.onMessageMouseLeave}
                    onHideToolTopBox={self.onHideToolTopBox}
                    ShowToolTip={self.ShowToolTip}
                    // childrenonColumnHeaderChanged = {self.childrenonColumnHeaderChanged}
                    onColumnHeaderClicked={self.onColumnHeaderClicked}
                    onBlockMenuItemRightClicked = {self.onBlockMenuItemRightClicked}
                    activeTab={activeTab}
                />
            </div>
        
        return tables;
    }

    getTextWidth = (text) => {
        let textSize;

        switch (this.props.textSize) {
            case "xxx-small-normal":
                textSize = 10;
                break;
            case "xx-small-normal":
                textSize = 12;
                break;
            case "small-normal":
                textSize = 14;
                break;
            default: textSize = 12;
                break;
        }

        const width = textWidth(text, {
            family: "calibri",
            size: textSize
        });

        return width;
    }



    onMessageMouseMove = (e, message, name, quarterEndDate, latestQuarterEndDate) => {
        if(!isHideToolTopBox) {
            if (DateHelper.getDateString(moment(quarterEndDate)._d) !== DateHelper.getDateString(moment(latestQuarterEndDate)._d)) {
                //const d = moment(`/Date(${  quarterEndDate  })/`);
                //const str = DateHelper.getDateString(d._d);

                if(quarterEndDate !== null && quarterEndDate !== ""){
                    const str = DateHelper.getDateString(moment(quarterEndDate)._d);
                    name += ` as of ${  str}`;
                }            
            }
            if (this.getTextWidth(message) > e.target.getBoundingClientRect().width - 10
                || this.getTextWidth(name) > e.target.getBoundingClientRect().width - 10
                || message !== name) {
                this.toolTopBox.style.left = `${e.clientX - this.main.getBoundingClientRect().left + 10  }px`;
                this.toolTopBox.style.top = `${e.clientY - this.main.getBoundingClientRect().top + 10  }px`;
                this.toolTopBox.innerHTML = `<div><span>${  name  }</span></div>`;
                this.toolTopBox.style.display = '';
            }
        }
    }

    onMessageMouseLeave = () => {
        this.toolTopBox.style.left = 0;
        this.toolTopBox.style.top = 0;
        this.toolTopBox.innerHTML = '';
        this.toolTopBox.style.display = 'none';
    }
    
    onHideToolTopBox = (isHide) => {
        this.onMessageMouseLeave();
        isHideToolTopBox = isHide;
    }

    getTextSizeWidth = (text, fontSize, isBold) => {
        const fontWeight = isBold ? "bold" : "normal";
        const width = textWidth(text, {
            family: "calibri",
            size: fontSize,
            weight: fontWeight
        });

        return width;
    }

    ShowToolTip = (e) => {
        if (e.target.clientWidth < e.target.scrollWidth) {
            this.toolTopBox.style.left = `${e.clientX - this.main.getBoundingClientRect().left + 10  }px`;
            this.toolTopBox.style.top = `${e.clientY - this.main.getBoundingClientRect().top + 10  }px`;
            this.toolTopBox.innerHTML = `<div><span>${  e.target.innerText  }</span></div>`;
            this.toolTopBox.style.display = '';
        }
    }

    onTitleMouseMove = (e, message, fontSize, isBold) => {
        if (this.getTextSizeWidth(message, fontSize, isBold) > e.target.getBoundingClientRect().width) {
            this.toolTopBox.style.left = `${e.clientX - this.main.getBoundingClientRect().left + 10  }px`;
            this.toolTopBox.style.top = `${e.clientY - this.main.getBoundingClientRect().top + 10  }px`;
            this.toolTopBox.innerHTML = `<div><span>${  message  }</span></div>`;
            this.toolTopBox.style.display = '';
        }
    }

    updateFlag = (msid, isFlagged) => {
        this.props.updateFlag(msid, isFlagged);
    }

    render() {
        let riPanelOpnClass = '';

        if (this.props.isRIPanelOpen === true && this.props.isMiniListOpen === true) {
            riPanelOpnClass = 'report-ripanel-minlist-open'
        }
        else if (this.props.isRIPanelOpen === true && this.props.isMiniListOpen === false) {
            riPanelOpnClass = 'report-ripanel-open'
        }
        else if (this.props.isRIPanelOpen === false && this.props.isMiniListOpen === true) {
            riPanelOpnClass = 'report-minlist-open'
        }

        const reportHeaderHTML = this.getReportHeaderHTML();
        const reportHTML = this.getReportHTML();
        return (
            <div ref={(ref) => { this.main = ref }} className="report-console">
                <div className="extender">
                    <div className="head-list-view">
                        <div className="tool-bar-area">
                            <DGGridSettings
                                textSize={this.props.textSize}
                                limitData={this.props.limitData}
                                searchText={this.props.searchText}
                                changeTextSize={this.props.changeTextSize}
                                onLimitChange={this.onLimitChange}
                                onSearchTextChange={this.onSearchTextChange}
                                reportData={this.props.holdingsReportData.data}
                                limitDataCompleted={this.props.limitDataCompleted}
                            />
                        </div>
                    </div>
                    <div className="report-wrapper">
                        <div className="list-inner-container">
                            <div className="list-view-area">
                                <div className="flexbox-parent">
                                    {this.props.holdingsHeaderData ? 
                                    <DgTabHeaderHoldings activeTab="DgHoldings" data={this.props.holdingsHeaderData} onTitleMouseMove={this.onTitleMouseMove} onMessageMouseLeave={this.onMessageMouseLeave} /> : ""}
                                    <div className="tabstuff holding" id="all-tab-ownership">

                                        <div className="message-center-tabs">
                                            
                                            <Tabs id="controlled-tab-example" onSelect={this.onTabSelectionChanged.bind(this)} activeKey={this.props.mainState.currentTab + 1}>
                                                <Tab eventKey={1} title="All" className="report-sub-tabs">
                                                    <label>Display:</label>
                                                    <div className="template value-blue">  
                                                        <form className="form-inline">
                                                            <span className="form-group">
                                                                <a className={this.props.subCategory.ByTopHoldingsActive} onClick={() => this.onSubTabSelectionChanged(DgHoldingsSubCategoryType.DgHoldingsTopHoldings)}>By Top Holdings</a>
                                                                <a className={this.props.subCategory.ByPositionTypeActive} onClick={() => this.onSubTabSelectionChanged(DgHoldingsSubCategoryType.DgHoldingsByPosition)}>By Position Type</a>
                                                            </span>
                                                        </form>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey={2} title="BASIC MAT"> </Tab>
                                                <Tab eventKey={4} title="CAP EQUIP"></Tab>
                                                <Tab eventKey={8} title="CON CYC"></Tab>
                                                <Tab eventKey={6} title="CON STAPLE"></Tab>
                                                <Tab eventKey={3} title="ENERGY"></Tab> 
                                                <Tab eventKey={11} title="FINANCIAL"></Tab>
                                                <Tab eventKey={7} title="HEALTH CARE"></Tab>
                                                <Tab eventKey={9} title="RETAIL"></Tab>     
                                                <Tab eventKey={5} title="TECHNOLOGY"></Tab>
                                                <Tab eventKey={10} title="TRANSPORT"></Tab>
                                                <Tab eventKey={12} title="UTILITY"></Tab>                                                
                                            </Tabs>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="clearfix"></div>

                                    <div className="report-content-panel">
                                        { this.props.isLoading === true ? 
                                            
                                            <div className="loading loading-spinner"></div>
                                            : (this.props.holdingsReportData.data && this.props.holdingsReportData.data.length > 0) ?
                                            <div className="report-content">
                                                <div className={`report-content-holder ${  riPanelOpnClass}`}>
                                                    <div className="report-scrolling" id="holdings-scroll">
                                                        <div className="custom-scroll">
                                                            <div id="ownershipContentGrab" className="report-content-grab">
                                                                <div className="report-grid-header">
                                                                    <div className="report-header-grid">
                                                                        <div className="report-grids">
                                                                            {reportHeaderHTML}
                                                                        </div>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                                <div id="holdingsGridHolder" className="report-grid-holder">
                                                                    <div className="report-grid-bg"><div className="report-grid-bg-hold"><b></b><b></b><b></b><b></b></div></div>
                                                                    {reportHTML}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ScrollBar scrollId='holdings-scroll' hScroll={true} />
                                                    </div>
                                                </div>
                                            </div>: <div style={{position: 'absolute',left: '50%',top: '50%'}}>No Data Found</div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={(ref) => { this.toolTopBox = ref }} id="rowTooltip" className="tooltip-box" style={{ display: 'none' }}></div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
        holdingsHeaderData: state.holdings.headerData,
        holdingsReportData: state.holdings.reportExtData,
        textSize: state.holdings.textSize,
        limitData: state.holdings.limitData,
        isColumnRsChanged: state.holdings.isColumnRsChanged,
        isColumnPortChanged: state.holdings.isColumnPortChanged,
        isColumnSharesChanged: state.holdings.isColumnSharesChanged,
        isLoading: state.holdings.isLoading,
        mainState: state.holdings.mainState,
        subCategory: state.holdings.subCategory,
        orderSeqArr: state.holdings.orderSeqArr,
        searchText: state.holdings.searchText,
        resizeCounter: state.holdings.resizeCounter,
        limitDataCompleted: state.holdings.limitDataCompleted,
        isMiniListOpen: state.DatagraphReducers.DataGraphReducer.isMiniListOpen,
        isRIPanelOpen: state.RelatedInfoPanelReducers.RiPanelReducer.isRIPanelOpen,
    })

const mapDispatchToProps = (dispatch) => ({
    changeTextSize: (textSize) => dispatch(changeTextSize(textSize)),
    getHeaderData: (osid, ownertype, currentTab, currentSubTab, showIndex) => dispatch(getHeaderData(osid, ownertype, currentTab, currentSubTab, showIndex)),
    getReportExtdata: (osid) => dispatch(getReportExtdata(osid)),
    columnRsChanged: (isColumnRsChanged) => dispatch(columnRsChanged(isColumnRsChanged)),
    columnPortChanged: (isColumnPortChanged) => dispatch(columnPortChanged(isColumnPortChanged)),
    columnSharesChanged: (isColumnSharesChanged) => dispatch(columnSharesChanged(isColumnSharesChanged)),
    showLoader: (isLoading) => dispatch(showHoldingsLoader(isLoading)),
    tabSelectionChanged: (tabIndex) => dispatch(tabSelectionChanged(tabIndex)),
    subTabSelectionChanged: (subTabIndex) => dispatch(subTabSelectionChanged(subTabIndex)),
    columnSortSeqChanged: (orderString, orderSeqArr) => dispatch(columnSortSeqChanged(orderString, orderSeqArr)),
    limitChange: (limitData) => dispatch(limitChange(limitData)),
    onSearchTextChange: (searchText) => dispatch(onSearchTextChange(searchText)),
    updateFlag: (msid, isDeleted) => dispatch(updateFlag(msid, isDeleted))
    //updateMiniList: (sym) => dispatch(updateMiniList(sym))
});

export default connect(mapStateToProps, mapDispatchToProps)(DgTabHoldings)