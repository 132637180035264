import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetPricePanelDataRequest extends BaseServiceRequest {
    constructor(symbol, startdate, enddate, GraphType, RTVOlume, MaxMASize, lineSettings, wononly = 1, revwononly = 1, selectCurrencyCode, isMatchFinancials, nodeCount) {
        super();
        super.addRequestUri("getpricepaneldata.pb");
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("startdate", startdate);

        if (enddate !== null)
            super.addRequestParameter("enddate", enddate);

        super.addRequestParameter("gtype", GraphType);
        super.addRequestParameter("rt", RTVOlume);
        super.addRequestParameter("mxsize", MaxMASize);
        
        if (lineSettings) {
            super.addRequestParameter("lsettings", lineSettings.encode64());
        }
        super.addRequestParameter("wononly", wononly);
        super.addRequestParameter("revwononly", revwononly);
        if (selectCurrencyCode){
            super.addRequestParameter("scc", selectCurrencyCode);
        }
        if (isMatchFinancials !== null){
            super.addRequestParameter("ismf", isMatchFinancials ? 1 : 0);
        }

        super.addRequestParameter("numberOfNodes", nodeCount);
        super.addRequestParameter("isIntradayES", 1); // added to differentiate beta and prod version
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "GetPricePaneldataResponse";
    }
}

export default GetPricePanelDataRequest;