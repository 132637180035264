import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class UpsertScreenScheduleRequest extends BaseServiceRequest {
  constructor(screenId, listId, colsetId, geoFilterString, oneilFilterString, gicsFilterString, scheduleId, freqType,
    schTime, notifyByEmail, isEmbedinEmail, isExcelAttach, isCreateList, screenName, timeZone, nextRunTime, isNicknameSet, displayName2) {
    super();
    super.addRequestUri("upsertscreenschedule.pb");

    const ReqProto = BaseServiceApi.rayData["SchedulerScreenSettings"];
    const schedulerScreenSettings = new ReqProto();
    schedulerScreenSettings.screenId = screenId;
    schedulerScreenSettings.listId = listId;
    schedulerScreenSettings.colsetId = colsetId;
    schedulerScreenSettings.geoFilterString = geoFilterString;
    schedulerScreenSettings.oneilFilterString = oneilFilterString;
    schedulerScreenSettings.gicsFilterString = gicsFilterString;
    schedulerScreenSettings.scheduleId = scheduleId;
    schedulerScreenSettings.freqType = freqType;
    schedulerScreenSettings.schTime = schTime;
    schedulerScreenSettings.notifyByEmail = notifyByEmail;
    schedulerScreenSettings.isEmbedinEmail = isEmbedinEmail;
    schedulerScreenSettings.isExcelAttach = isExcelAttach;
    schedulerScreenSettings.isCreateList = isCreateList;
    schedulerScreenSettings.screenName = screenName;
    schedulerScreenSettings.timeZone = timeZone;
    schedulerScreenSettings.nextRunTime = nextRunTime;
    schedulerScreenSettings.isNicknameSet = isNicknameSet;
    schedulerScreenSettings.displayName2 = displayName2;

    const reqcontent = schedulerScreenSettings.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "Response";
  }

}
