import UserInfoUtil from "./UserInfoUtil";

class CurrencyHelper {
    get(currCode, showCurrencyCode = false, blockDisplay = false) {
        if (!currCode) {
            return "";
        }
        if (currCode.CurrencyCode) {
            if (currCode.CurrencyCode === "USD") {
                return blockDisplay ? "U$" : "$";
            }
            if (currCode.CurrencyCode === "GBP") {
                return !showCurrencyCode ? "p" : "£";
            }
            if (currCode.CurrencyCode === "EUR") {
                return "€";
            }
            if (currCode.CurrencyCode === "GBX") {
                return "p";
            }
            if (currCode.CurrencyCode === "ZAR" && !showCurrencyCode) {
                return "c";
            }
            if (currCode.CurrencyCode === "JPY") {
                return "¥";
            }
            if (currCode.CurrencyCode === "NAD") {
                return "$";
            }
            if (currCode.CurrencyCode === "INDEX") {
                if (UserInfoUtil.getCurrentLang() != LangType.RAY_DEFAULT) {
                    return "";
                } else {
                    return currCode.CurrencyCode;
                }
            }
            return currCode.CurrencyCode;
        } else {
            if (currCode.Code === "USD") {
                return blockDisplay ? "U$" : "$";
            }
            if (currCode.Code === "GBP") {
                return "£";
            }
            if (currCode.Code === "EUR") {
                return "€";
            }
            if (currCode.Code === "GBX") {
                return "p";
            }
            if (currCode.Code === "ZAR" && !showCurrencyCode) {
                return "c";
            }
            if (currCode.Code === "JPY") {
                return "¥";
            }
            if (currCode.Code === "NAD") {
                return "$";
            }
            if (currCode.Code === "INDEX") {
                if (UserInfoUtil.getCurrentLang() != LangType.RAY_DEFAULT) {
                    return "";
                } else {
                    return currCode.Code;
                }
            }
            return currCode.Code;
        }
    }
}

const currencyHelper = new CurrencyHelper();
export default currencyHelper;
