import { CalenderControlConstants } from "../../Constants/CalenderControl/CalenderControlConst";
import formatterUtil from "../../Utils/FormatterUtil";
import LocalizationStore from "LocalizationStore";
import moment from "moment";
import UserInfoUtil from "../../Utils/UserInfoUtil";

const { ActionTypes } = CalenderControlConstants;
const intialState = {
    dateTime: moment(),
    minDate: moment("01/01/1900", ['YYYY-MM-DD']),
    maxDate: moment(),
    selectedDate: moment(),
    viewDate: moment(),
    inputValue: moment().format("ddd, MMM DD, YYYY"),
    blockOutDates: [],
    daysOfWeekDisabled: [0, 6],
    format: "YYYY-MM-DD",
    inputFormat: "ddd, MMM DD, YYYY",
    browserFormat: formatterUtil.getDateFormatAsLocale('MM/DD/YYYY'),
    showToday: true,
    isPinned: false,
    isNavSymbolTab: true,
    showPicker: false,
    isEditMode: false,
    disableDateTimeField: false,
    isIntraday: false,
    widgetStyle: { display: "block", bottom: "34px", width: "inherit", padding: "0", right: "0", top: "inherit", left: "inherit" },
    widgetClasses: "bootstrap-datetimepicker-widget dropdown-menu",
    isMostRecentTradeDayTb: false,
    equityEvents: [],
    mostRecentTradeString: LocalizationStore.getTranslatedData("cal_mrtd", "Most Recent Trading Date"),
    endOfDate: "day",
    addSubtractDate: "days"
}

const CalenderControlReducer = (state = intialState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_CALENDER_IS_PINNED:
            state.isPinned = !state.isPinned;
            return { ...state };
        case ActionTypes.UPDATE_LANGUAGE: {
            const inputFormat = UserInfoUtil.hasChinaEntitlement() ? "ddd, MMM Do, YYYY" : "ddd, MMM DD, YYYY";

            return {
                ...state,
                inputFormat,
                inputValue: moment().format(inputFormat)
            };
        }
        case ActionTypes.UPDATE_INPUT_DATE_VALUE:
            state.inputValue = action.inputValue;
            return { ...state };
        case ActionTypes.UPDATE_SHOW_DATE_PICKER:
            state.showPicker = action.showPicker;
            return { ...state };
        case ActionTypes.UPDATE_CALENDER_CONTROL_PROPERTIES:
            state = { ...state, ...action.properties };
            return { ...state };
        case ActionTypes.UPDATE_CALENDER_YEAR: {
            let inputYear = parseInt(action.event.target.value);
            if (action.event.keyCode === 13 && inputYear !== '') {
                if (inputYear > state.maxDate.year()) {
                    inputYear = state.maxDate.year();
                }
                else if (inputYear < state.minDate.year()) {
                    inputYear = state.minDate.year();
                }
                const selectedDate = state.selectedDate.clone().year(inputYear);
                const viewDate = state.viewDate.clone().year(inputYear);

                return { ...state, selectedDate, viewDate }
            }
            return state;
        }
        case ActionTypes.ADD_SUBTRACT_YEAR: {
            const viewYear = state.viewDate.year() + action.value;
            if (state.maxDate.year() >= viewYear && state.minDate.year() <= viewYear) {
                const selectedDate = state.selectedDate.clone().year(state.viewDate.year() + action.value);
                const viewDate = state.viewDate.clone().year(state.viewDate.year() + action.value);
                return { ...state, selectedDate, viewDate }
            }
            return state;
        }
        case ActionTypes.UPDATE_CALENDER_MONTH: {
            const selectedDate = state.selectedDate.clone().month(action.month);
            const viewDate = state.viewDate.clone().month(action.month);
            return { ...state, selectedDate, viewDate }
        }

        case ActionTypes.OPEN_DATE_PICKER: {
            if (!state.showPicker) {
                const inputFormat = (/[\u4e00-\u9fa5a-zA-Z]/).test(state.inputValue) ? state.inputFormat : state.browserFormat;
                const inputValue = moment(state.inputValue, inputFormat, true).format(state.browserFormat);
                return { ...state, inputValue, showPicker: true, isEditMode: true }
            }
            return state;
        }

        case ActionTypes.CLOSE_DATE_PICKER: {
            if (action.event && action.event.target.id === "customDatepicker") {
                return state;
            }
            let inputValue;
            let isPinned = state.isPinned;
            if (action.isClose) {
                isPinned = false;
            }

            if (!isPinned && state.showPicker) {
                inputValue = moment(state.dateTime, state.inputFormat, true).format(state.inputFormat);

                //   if (this.dtpbutton) {
                //     this.dtpbutton.blur()
                //     this.dtpbutton.style.backgroundColor = "";
                //   }
                return { ...state, showPicker: false, isPinned: false, inputValue, isEditMode: false }
            }
        }
        default:
            return state;
    }
}

export default CalenderControlReducer;