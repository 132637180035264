import keyMirror from "keymirror";

export const ListExplorerConstants = {
  ActionTypes: keyMirror({
    GET_BROWSER_FILTER: null,
    GET_LIST_EXPLORER: null,
    GET_LIST_CATALOG: null,
    RELOAD_LIST_CATALOG: null,
    GET_SEARCH_TEXT: null,
    CLEAR_SEARCH_TEXT: null,
    GET_PAGE_LIST_ROWS: null,
    GET_ADD_ITEMS_TO_LIST: null,
    GET_DUPLICATE_LIST: null,
    GET_CREATE_TREE_NODE: null,
    GET_MORE_DATA_ON_SCROLL: null,
    GET_UPDATE_DESCRIPTION: null,
    GET_DELETE_TREE_NODE: null,
    GET_UPDATE_LIST_NODE: null,
    GET_REFRESHED_VIEW: null,
    GET_COLUMN_SET: null,
    ADD_NEW_COLUMN_SET: null,
    RENAME_COLUMN_SET: null,
    REMOVE_COLUMN_SET: null,
    REMOVE_COLUMN_SET_FOLDER: null,
    RESET_COLUMN_SET: null,
    DUPLICATE_COLUMN_SET: null,
    GET_NODE_DETAILS: null,
    UPDATE_NODE_DESCRIPTION: null,
    CHANGE_COLUMN_SET_LOCATION: null,
    ADD_RENAME_COLUMN_SET_FOLDER: null,
    ADD_TO_FAVORITES: null,
    COPY: null,
    OPEN_ALERT: null,
    COPY_PASTE: null,
    BROWSER_FILTER: null,
    BROWSER_FILTER_RESET: null,
    BROWSER_FILTER_TAB_TYPE: null,
    BROWSER_FILTER_DATASETTYPE: null,
    BROWSER_FILTER_COUNTRY_REGION: null,
    RESTORE_TREE_NODE: null,
    GET_METRIC_SEARCH_TEXT: null,
    ON_DATA_LOADING_COMPLETE: null,
    ON_ADD_SYMBOL_COMPLETE: null,
    GET_COLUMN_PICKLIST: null,
    GET_METRIC_LIBRARY: null,
    SHOW_METRIC_LIBRARY_COLUMN_FILTER: null,
    METRIC_LIBRARY_VISIBILITY: null,
    TOGGLE_METRIC_LIBRARY: null,
    BROWSE_METRIC_LIBRARY: null,
    CLOSE_CUSTOM_METRICS: null,
    OPEN_CUSTOM_METRICS: null,
    DELETE_SAME_METRICS_CLICK: null,
    UPDATE_METRIC_COLUMN_FILTER: null,
    UPDATE_LIST_EXPLORER_WIDTH: null,
    UPDATE_BROWSE_METRIC_CLICK: null,
    SHOW_ORIGINAL: null,
    REMOVE_ITEMS_FROM_USER_LIST: null,
    SCREEN_ON_OFF: null,
    GET_SCREEN_OFF_LIST_CATALOG: null,
    REMOVE_FROM_FAVORITES: null,
    ADD_SYMBOLS_TO_LIST: null,
    CHANGE_TEXT_SIZE: null,
    MOVE_LIST_ITEMS: null,
    SAVE_SCREENED_RESULTS: null,
    UPDATE_MINI_LIST: null,
    TOGGLE_DIALOG_100_COLUMNS: null,
    GET_TEMP_LIST: null,
    CREATE_OWNERSHIP_BLOCK_FAV_LIST: null,
    EDIT_OWNERSHIP_BLOCK_FAV_LIST:null,
    IS_TEMP_LIST_VISIBLE: null,
    UPDATE_SELECTED_LIST_ITEM: null,
    GET_TEMP_LIST_DATA: null,
    GET_NOT_IMPORTED_LIST_DATA: null,
    UPDATE_ACTIVE_SYMBOL: null,
    SHARE_LIST: null,
    SHARE_COLUMN_SET: null,
    SELECT_COLUMN_SET: null,
    OPEN_CUSTOM_METRICS_CLICK: null,
    CUSTOM_METRIC_CHANGED: null,
    RESET_COUNTRYBADGE_SET: null,
    SET_MINILIST_ACTION: null,
    GET_LIST_ALERT_STATUS: null,
    NASDAQ_LIST_UPDATE: null,
    NASDAQ_LIST_UPDATE_FINISH: null,
    GET_TEMP_LIST_OWNERSHIP: null,
    SET_CURRENT_EXTERNAL_LIST: null,
    SET_EXTERNAL_LIST: null,
    REMOVE_EXTERNAL_LIST: null,
    SET_TEMP_LIST: null,
    SET_LOAD_EXTERNAL_LIST: null,
    INIT_LOAD_EXTERNAL_LIST: null,
    SET_LOADED_EXTERNAL_LIST_DETAILS: null,
    SET_EXTERNAL_SELECTED_NODES: null,
    ADD_NEW_EXTERNAL_LIST: null,
    SHOW_ALERT_DUPLICATE_EXTERNAL: null,
    REMOVE_DELETED_NODES: null,
    LIST_ROLL_BACK_SUCCESS: null,
    UNDO_EXTERNAL_LIST:null,
    RESTORE_EXTERNAL_NODE:null,
    SORT_EXTERNAL_DATA:null,
    RENAME_EXTERNAL_LIST:null,
    LIST_RE_UPLOAD_SUCCESS: null,
    UNDO_EXTERNAL_LIST_ROLLBACK: null,    
    IS_DELETE_UNDO_EXIST_EXTERNAL: null,
    EXTERNAL_HEADER_CHANGED: null,
    LIST_PROPERTY_TOGGLE: null,
    SET_ACTIVE_LIST_NAME: null,
    SET_ACTIVE_LIST_NAME_EXTERNAL :null,
    LIST_RENAME_OWNERSHIP: null,
    LIST_RENAME_OENIL: null,
    LIST_ONEIL_FLAGGED: null,
    SET_SHOW_CHART: null,
  })
};

export const CategoryType = {
  TEMP_LIST_CATEGORY: 9
};

