import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';

class GetSymbolInfoRequest extends BaseServiceRequestIIS {
  constructor(symbol) {
    super();
    super.addRequestUri("GetStockInformation");
    super.addPathParameter("symbol", symbol);

  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return undefined;
  }
}

export default GetSymbolInfoRequest;
