import BlockType from "BlockType";
import { collapseFinancialBlock } from "../../.../../../../Actions/FinancialBlockActions";
import { connect } from 'react-redux';
import ConsoleStore from "ConsoleStore";
import LocalizationStore from 'LocalizationStore';
import { onInsiderOpenCollapse } from "../../../../Actions/InsiderChartActions";
import { onOpenCollapse } from "../../../../Actions/VolumeChartAction";
import { openCollapseOwnerShip } from "../../../../Actions/OwnerShipBlockActions";
import { MenuItem, Dropdown } from "react-bootstrap";
import React, { useCallback } from "react";

const BlockMenu = (props) => {
    const { videoMode, blockMenu, SymbolInfo, priceDataAvailable, leftScaleWidth } = props;
    const useClass = videoMode === false ? "leftChartArea" : "leftVideoArea";

    const onBlockMenuItemClicked = useCallback((item) => {
        if (!item.isActive) { return; }
        switch (item.blockType) {
            case BlockType.Volume:
                props.onOpenCollapse(item.blockType, !item.ischecked);
                break;

            case BlockType.Insider:
                props.onInsiderOpenCollapse(item.blockType, !item.ischecked);
                break;

            case BlockType.Financial:
                props.collapseFinancialBlock(item.blockType, !item.ischecked);
                break;

            case BlockType.Ownership:
                props.openCollapseOwnerShip(item.blockType, !item.ischecked);
                break;

            default: break;
        }
    }, [blockMenu, SymbolInfo, priceDataAvailable, leftScaleWidth])

    const getId = () => { (SymbolInfo.Symbol).split(".").join("") };

    return (
        <>
            {ConsoleStore.getState().serviceCallCount >= 1 && <div id={getId()}></div>}
            <div className={useClass} style={{ width: leftScaleWidth, pointerEvents: "none" }}>
                {priceDataAvailable &&
                    <div id="bottomMenu" className="bottomMenuButton multi-collaps-block custonBtnDD">
                        {videoMode === false && blockMenu.length > 0 &&
                            <Dropdown id="dropdown-custom-theme-contex" dropup>
                                <Dropdown.Toggle className=" bottom-contex-menu">
                                    <div className="icn-contex-menu "></div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="list-contex">
                                    {blockMenu.map((item) => {
                                        if (item.header === "Divider") {
                                            return (<MenuItem divider />);
                                        }
                                        else {
                                            return (
                                                <MenuItem
                                                    key={item.Header}
                                                    disabled={item.isDisabled}
                                                    eventKey="1"
                                                    onClick={() => onBlockMenuItemClicked(item)}>
                                                    <span className={item.ischecked ? "icn-right-icon" : ""}></span>
                                                    {LocalizationStore.getTranslatedData(item.resKey, item.Header)}
                                                </MenuItem>
                                            );
                                        }
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>}
                    </div>}
            </div>
        </>
    )

}
const mapStateToProps = (state) => {
    const { blockMenu, SymbolInfo, priceDataAvailable, leftScaleWidth, videoMode } = state.DatagraphReducers.DataGraphReducer;
    return { blockMenu, SymbolInfo, priceDataAvailable, leftScaleWidth, videoMode }
}
const mapDispatchToProps = (dispatch) => ({
    onOpenCollapse: (blockType, ischecked) => dispatch(onOpenCollapse(blockType, ischecked)),
    onInsiderOpenCollapse: (blockType, ischecked) => dispatch(onInsiderOpenCollapse(blockType, ischecked)),
    collapseFinancialBlock: (blockType, ischecked) => dispatch(collapseFinancialBlock(blockType, ischecked)),
    openCollapseOwnerShip: (blockType, ischecked) => dispatch(openCollapseOwnerShip(blockType, ischecked))
})

export default connect(mapStateToProps, mapDispatchToProps)(BlockMenu)