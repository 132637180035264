import BaseServiceApi from '../../../../../ServiceApi/BaseServiceApi'
import { connect } from 'react-redux';
import { flagIconStyleConst } from '../../../../../Sagas/NavDataGraph/TabDataGraph/IdeasSaga';
import graphApi from "../../../../../ServiceApi/Apis/GraphApi";
import { IdeaTranslateHelper } from '../../../../../Utils/TranslateHelper';
import { Link } from "react-router-dom";
import ResearchStore from "../../../../../Stores/NavModules/NavResearch/ResearchStore";
import ScrollBar from "ScrollBar";
import StringUtil from '../../../../../Utils/StringUtil';
import ThemeType from "ThemeType";
import { updateRiPanelSettingsData } from '../../../../../Actions/RelatedInfoPanelActions/RiPanelActions';
import UserInfoUtil from 'UserInfoUtil';
import validator from 'validator';
import { Button, Panel, ButtonToolbar, ButtonGroup, Collapse, Well, Modal, label } from "react-bootstrap";
import React, { Component } from 'react';
import { updateLeaderFlagStatus, updatePricePanelLeaderFlagStatus, updateShowHistoricalFlag, updatePricePanelShowHistoricalFlag, updateContactType, updateEntitlementDialogFlag, handleEntitlement, updateEntitlementUserName, updateEntitlementUserEmail, updateEntitlementUserPhone } from "../../../../../Actions/RIPanelOneilActions";

const IdeaType = BaseServiceApi.rayData["IdeaType"];
const EntitlementType = BaseServiceApi.rayData.EntitlementType;

class RIPanelOneilInfo extends Component {

    constructor(...args) {
        super(...args);
        this.state = { open: false }
        this.isResearhViewMore = false;
        this.closeDialog = this.closeDialog.bind(this);
        this.handleChangeContact = this.handleChangeContact.bind(this);
        this.handleContactMe = this.handleContactMe.bind(this);
        this.onShowHistory = this.onShowHistory.bind(this);
    }
    getResearchView() {
        const researchDataView = [];
        let research = "";
        this.isResearhViewMore = false;
        if (!UserInfoUtil.IsUserEntitled(EntitlementType.RAY_RESEARCHVIEW_Entitlement)) {
            this.isResearhViewMore = false;
            researchDataView.push(<div className="research-list">
                <span className="no-activity">For access to Research,  please contact Sales Representative</span>
            </div>)
        }
        else if (this.props.researchList && this.props.researchUrl !== '') {
            this.props.researchList.forEach((item, i) => {
                if (i < 10) {
                    if (item.entitlement === false) {
                        this.isResearhViewMore = i < 9 ? false : true;
                        research = <Well key={i}>
                            <div className="well-block">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="name">
                                            <div className="name-condition">
                                                <a className="name-text">{item.postname}</a>
                                                <span className="name-icon icn-lock" onClick={() => this.openEntitlementDialog()}>lock</span>
                                                <Modal className="modal-popup entitled-content" show={this.props.entitlementDialogFlag} onHide={this.closeDialog}>
                                                    <Modal.Header>
                                                        <Modal.Title>
                                                            <span className="cap-header">Entitled Content</span>
                                                            <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.closeDialog}><span className="icn-close"></span></button>
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <div className="modal-material">
                                                            <div className="entitle-content-box">

                                                                <div className="form-group">
                                                                    <p>If you would like access to this content, please click the button below to have your Account Executive contact you.</p>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label">Requested by</label>
                                                                    <div>
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            name="entlitmentuName"
                                                                            onChange={(e) => this.handleEntlitmentName(e)}
                                                                            onKeyDown={(e) => this.handleKeyUpEntlitmentName(e)}
                                                                            value={this.props.entitlmentUserName} />
                                                                        <small className={`${this.props.validationUserNameClass} text-danger`}>Please Enter a Name </small>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group">
                                                                    <label className="form-label">How should we contact you?</label>
                                                                    <div className="email-n-phone">
                                                                        <span className="mail-call">
                                                                            <input type="radio"
                                                                                id="email"
                                                                                name="contactDel"
                                                                                defaultChecked={this.props.contactType === 'email'}
                                                                                onChange={this.handleChangeContact}
                                                                                value="email" />
                                                                            <label htmlFor="email">Email</label>
                                                                        </span>
                                                                        <span className="mail-call">
                                                                            <input type="radio"
                                                                                id="phone"
                                                                                name="contactDel"
                                                                                defaultChecked={this.props.contactType === 'phone'}
                                                                                onChange={this.handleChangeContact}
                                                                                value="phone" />
                                                                            <label htmlFor="phone">Phone</label>
                                                                        </span>
                                                                    </div>
                                                                    <div>
                                                                        {this.props.contactType === 'email' &&
                                                                            <div>
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    id="entlitmentuEmail"
                                                                                    name="entlitmentuEmail"
                                                                                    onChange={(e) => this.handleEntlitmentEmail(e)}
                                                                                    onKeyDown={(e) => this.handleKeyUpEntlitmentEmail(e)}
                                                                                    value={this.props.entitlmentUserEmail} />
                                                                                <small className={`${this.props.validationUserEmailClass} text-danger`}>Please Enter a valid email address</small>

                                                                            </div>}
                                                                        {this.props.contactType === 'phone' &&
                                                                            <div>
                                                                                <input type="Number"
                                                                                    className="form-control"
                                                                                    id="entlitmentuPhone"
                                                                                    name="entlitmentuPhone"
                                                                                    onChange={(e) => this.handleEntlitmentPhone(e)}
                                                                                    onKeyDown={(e) => this.handleKeyUpEntlitmentPhone(e)}
                                                                                    value={this.props.entitlmentUserPhone}
                                                                                />
                                                                                <small className={`${this.props.validationUserPhoneClass} text-danger`}>Please Enter a valid mobile number</small>
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <span className="btn-box center">
                                                            <button className="btn btn-xs btn-default btn-secondary" type="button" onClick={() => this.handleContactMe(item)}>Contact me</button>
                                                        </span>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </div>
                                        <div className="date">{item.postdate}</div>
                                    </div>
                                </div>
                            </div>
                        </Well>
                        researchDataView.push(research);
                    }
                    else {
                        if (item === 'no results') {
                            this.isResearhViewMore = false;
                            researchDataView.push(<div className="research-list" key="noResearch">
                                <span className="no-activity">No Research</span>
                            </div>)
                        }
                        else {
                            this.isResearhViewMore = i < 9 ? false : true;
                            let postContent = '';
                            let icon = "";
                            if (item.ispdf === true || item.post_type === 'alerts') {
                                icon = "icn-pdfs pdf";
                            }
                            else if (item.isvideo === true) {
                                icon = "icn-urls video";
                            }
                            else {
                                icon = "";
                            }
                            if (item.ispdf || item.isvideo || item.post_type === 'alerts') {
                                postContent = <div className="name-condition"><Link to={{ pathname: '../Research' }} onClick={() => this.setResearchUrl(item, null)}><span className="name-text">{item.postname}</span><span className={`name-icon ${icon}`}></span></Link></div>
                            }
                            else {
                                postContent = <div className="name-condition"><span className="name-text">{item.postname}</span><span className={`name-icon ${icon}`}></span></div>
                            }

                            research = <Well key={i}>
                                <div className="well-block">
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <div className="name">
                                                {postContent}
                                            </div>
                                            <div className="date">{item.postdate}</div>
                                        </div>
                                    </div>
                                </div>
                            </Well>
                            researchDataView.push(research);
                        }
                    }
                }
            });

        }
        else if (StringUtil.isEmpty(this.props.researchList)) {
            this.isResearhViewMore = false;
            researchDataView.push(<div className="research-list" key="noResearch1">
                <span className="no-activity">No Research</span>
            </div>)
        }
        return researchDataView;

    }
    handleContactMe(item) {
        const { contactType, entitlmentUserName, entitlmentUserEmail, entitlmentUserPhone } = this.props;
        if (contactType === 'email' && (StringUtil.isEmpty(entitlmentUserEmail) || !validator.isEmail(entitlmentUserEmail))) {
            this.props.updateEntitlementUserEmail(entitlmentUserEmail, '');
        } else if (StringUtil.isEmpty(entitlmentUserName)) {
            this.props.updateEntitlementUserName(entitlmentUserName, '');
        } else if (contactType === 'phone' && (StringUtil.isEmpty(entitlmentUserPhone) && entitlmentUserPhone.length === 0)) {
            this.props.updateEntitlementUserPhone(entitlmentUserPhone, '');
        } else {
            const obj = {
                "category-form": item.post_cat,
                "radio-738": contactType,
                "your-email": contactType === 'email' ? entitlmentUserEmail : contactType === 'phone' ? entitlmentUserPhone : '',
                "your-name": entitlmentUserName
            };
            this.props.handleEntitlement(obj);
            this.closeDialog();
        }

    }
    handleEntlitmentName(e) {
        const { validationUserNameClass } = this.props;
        this.props.updateEntitlementUserName(e.target.value, validationUserNameClass);
    }
    handleKeyUpEntlitmentName(e) {
        const { entitlmentUserName, validationUserNameClass } = this.props;
        const value = entitlmentUserName;
        if (e.keyCode === 13) {
            if (StringUtil.isEmpty(value)) {
                this.props.updateEntitlementUserName(value, '');
            }
            else {
                this.props.updateEntitlementUserName(value, validationUserNameClass);
            }
        }
    }
    handleEntlitmentEmail(e) {
        const { validationUserEmailClass } = this.props;
        this.props.updateEntitlementUserEmail(e.target.value, validationUserEmailClass);
    }
    handleKeyUpEntlitmentEmail(e) {
        const { entitlmentUserEmail, validationUserEmailClass } = this.props;
        const value = entitlmentUserEmail;
        if (e.keyCode === 13) {
            if (StringUtil.isEmpty(value) || validator.isEmail(value)) {
                this.props.updateEntitlementUserEmail(value, '');
            }
            else {
                this.props.updateEntitlementUserEmail(value, validationUserEmailClass);
            }
        }
    }
    handleEntlitmentPhone(e) {
        const { validationUserPhoneClass } = this.props;
        this.props.updateEntitlementUserPhone(e.target.value, validationUserPhoneClass);
    }
    handleKeyUpEntlitmentPhone(e) {
        const { entitlmentUserPhone, validationUserPhoneClass } = this.props;
        const value = entitlmentUserPhone;
        if (e.keyCode === 13) {
            if (StringUtil.isEmpty(value) && value.length === 0 && value.length < 15) {
                this.props.updateEntitlementUserPhone(value, '');
            }
            else {
                this.props.updateEntitlementUserPhone(value, validationUserPhoneClass);
            }
        }
    }
    handleChangeContact(event) {
        this.props.updateContactType(event.target.value);
    }
    closeDialog() {
        this.props.updateEntitlementDialogFlag(false);
    }
    openEntitlementDialog() {
        this.props.updateEntitlementDialogFlag(!this.props.entitlementDialogFlag);
        if (!this.props.entitlementDialogFlag) {
            this.props.updateEntitlementUserName(UserInfoUtil.getUserInfo().name, 'displayNone');
            this.props.updateEntitlementUserEmail(UserInfoUtil.getUserInfo().userEmail, 'displayNone');
            this.props.updateEntitlementUserPhone('', 'displayNone');
            this.props.updateContactType('email');
        }
    }
    setResearchUrl(item, Symbol) {
        const encriptEmail = this.encriptEmail(UserInfoUtil.getUserInfo().userEmail);
        const templateColor = this.props.theme === ThemeType.Dark ? 'd' : 'l';
        let url = '';

        if (Symbol !== null) {
            url = `${this.props.researchUrl}research?tab=search&analystid=&time=clear&category=&key=&sort=desc&symbol=${Symbol}&e=${encriptEmail}&t=${templateColor}`;
        }
        else if (item.post_type === 'alerts') {
            url = `${this.props.researchUrl}research?tab=post&category=${item.content}&pid=${item.id}_alerts&pdate=${item.pcreated}&post_type=alerts&e=${encriptEmail}&t=${templateColor}&lastTab=true`;
        }
        else {
            url = `${this.props.researchUrl}research?tab=post&category=${item.post_cat}&pid=${item.id}&pdate=${item.pcreated}&e=${encriptEmail}&t=${templateColor}&lastTab=true`;
        }
        ResearchStore.updateRipanelUrl(url, true);
    }
    getFocusListView() {
        const { focusList, currentEntitlementInfo } = this.props;
        let focusListView = <div />;

        if (currentEntitlementInfo.length === 0) {
            return (<div className="focus-list">
                <span className="no-activity">
                    For access to Focus Lists, please contact Sales Representative
                </span>
            </div>)
        }
        else if (currentEntitlementInfo.length !== 0 && focusList.length === 0) {
            return (<div className="focus-list">
                <span className="no-activity">
                    {IdeaTranslateHelper.NO_ACTIVITY}
                </span>
            </div>)
        }
        else if (focusList.length > 0 && currentEntitlementInfo.length > 0) {
            focusListView = <div className="focus-list">
                {focusList.map((item, index) => {
                    const isCurrent = true;

                    if (item.ideaType === IdeaType.NONE) {
                        return (<Well key={index}>
                            <div className="well-block">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <span className="name">{item.ideaName}</span>
                                    </div>
                                    <div className="col-xs-2"></div>
                                    <div className="col-xs-4">
                                        <span className="analysis">
                                            {item.isPdfDataAvailable ? <a href={this.getDownloadIdeaAnalysisReportWebRequest(item.pdfResearchPath)}>{IdeaTranslateHelper.VIEW_ANALISIS}</a> : " "}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Well>);
                    } else {
                        const tempStyle = item.isPercNeg ? { color: this.props.negDTColor } : { color: this.props.posDTColor }
                        const leaderIdeaType = currentEntitlementInfo.find((entitlment) => entitlment.LeaderIdeaType === item.ideaType);
                        const laggardIdeaType = currentEntitlementInfo.find((entitlment) => entitlment.LaggardIdeaType === item.ideaType);
                        let flagToShow;
                        let isleaderflag = false;
                        if (leaderIdeaType) {
                            flagToShow = leaderIdeaType.IsCheckedLeaderFlag;
                            isleaderflag = true;
                        }
                        else if (laggardIdeaType) {
                            flagToShow = laggardIdeaType.IsCheckedLaggardFlag;
                            isleaderflag = false;
                        }
                        return (
                            <Well key={index}>
                                <div className="well-block">
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <div className="hold">
                                                <span className={isCurrent ? "name w1" : "name"}>{item.ideaName} </span>
                                                <span className={isCurrent ? "date" : "date"}>{item.inDt} - {isCurrent ? IdeaTranslateHelper.PRESENT : item.outDt}</span>
                                            </div>
                                            <div className="switches">
                                                <span className="switch-head">Flag :</span>
                                                <span className={`flag-focus-head ${flagIconStyleConst[item.ideaType] || ""}`}></span>
                                                <ButtonToolbar>
                                                    <ButtonGroup bsSize="" className="custom-on-off-btn" >
                                                        <Button onClick={this.props.updateLeaderFlagStatus.bind(this, item, isleaderflag)} id="screenButton">Off</Button>
                                                        <Button className={flagToShow ? "selective" : ""} onClick={this.props.updateLeaderFlagStatus.bind(this, item, isleaderflag)}>On</Button>
                                                    </ButtonGroup>
                                                </ButtonToolbar>
                                            </div>
                                        </div>
                                        <div className="col-xs-2">
                                            <span style={tempStyle} className={item.isPercNeg ? "percent" : "percent"}>{item.percChg}</span>
                                        </div>
                                        <div className="col-xs-4">
                                            <span className="analysis">
                                                {item.isPdfDataAvailable ? <a href={this.getDownloadIdeaAnalysisReportWebRequest(item.pdfResearchPath)}>{IdeaTranslateHelper.VIEW_ANALISIS}</a> : " "}
                                                {item.isVideoUrlAvailable ? <a target="_blank" href={item.videoResearchPath}>View Video</a> : " "}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Well>);
                    }
                })}
            </div>
            return focusListView;
        }
    }

    getDownloadIdeaAnalysisReportWebRequest(pdfResearchPath) {
        return graphApi.getPdfRequestURL(pdfResearchPath);
    }

    onShowHistory = () => {
        this.props.updateShowHistoricalFlag(!this.props.showHistorical);
        this.props.updatePricePanelShowHistoricalFlag(!this.props.showHistorical);
    }

    encriptEmail(email) {
        if (!email) {
            return;
        }
        const max = 4;
        const min = 1;
        const cst = 64;
        const length = email.length;
        const char = [];
        char.length = length + 1;
        const rnd = min + Math.floor(Math.random() * ((max - min) + 1));
        for (let ipos = 0; ipos < length; ipos++) {
            char[ipos] = String.fromCharCode(email[ipos].charCodeAt() + rnd);
        }
        char[length] = String.fromCharCode(rnd + cst);
        return encodeURI(char.join(""));
    }
    getPhoneNumber(phone) {
        let phoneNumber = "";
        phone = phone.replace(/[^A-Z0-9]/gi, '');
        if (phone.length === 10) {
            phoneNumber = `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 11)}`;
        }
        else if (phone.length === 12) {
            phoneNumber = `+${phone.substring(0, 2)} ${phone.substring(2, 6)}-${phone.substring(6, 12)}`;
        }
        else if (phone.length === 13) {
            phoneNumber = `+${phone.substring(0, 2)} ${phone.substring(2, 7)}-${phone.substring(7, 13)}`;
        }
        return phoneNumber;
    }

    render() {
        const focusListView = this.getFocusListView();
        const researchView = this.getResearchView();

        return (
            <div className="oneil-region custom-scroll">
                <div className="oneil-region-holder" id="scrollRIPanelOneilTab">
                    <div className="oneil-stacking">
                        <div className="oneil-stack">
                            <Button onClick={() => this.props.updateRiPanelSettingsData(!this.props.isResearchAnalystOpen, this.props.isFoucsPanelOpen, this.props.isResearchPanelOpen)}>
                                <font> Analyst </font>
                            </Button>
                            {this.props.isResearchAnalystOpen &&
                                <Panel expanded={this.props.isResearchAnalystOpen} onToggle={() => { }}>
                                    <Panel.Collapse className="custom-collap">
                                        <Panel.Body>
                                            {this.props.researchAnalystList && this.props.researchAnalystList.map((item, i) => <div className="contact-list" key={`contact${i}`}>
                                                <Well key={i} className="contacts">
                                                    <div className="well-block">
                                                        <div className="row">
                                                            <div className="col-xs-12">
                                                                <span className="avatarbox">
                                                                    {item.analystPhoto && <img src={item.analystPhoto} alt="" className="avatar" />}
                                                                </span>
                                                                <div className="contact-details">
                                                                    <div className="col-xs-12">
                                                                        <span className="phone">{this.getPhoneNumber(item.analystPhone)}</span>
                                                                        <span className="name">{item.analystName}</span>
                                                                    </div>
                                                                    <div className="col-xs-12">
                                                                        <span className="designation" rel={item.analystTitle}>{item.analystTitle}</span>
                                                                        <a className="mail" href={`mailto:${item.analystEmail}`}>Email {item.analystFirstName}</a>
                                                                    </div>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Well>
                                            </div>)}
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            }
                        </div>
                        <div className="oneil-stack">
                            <Button onClick={() => this.props.updateRiPanelSettingsData(this.props.isResearchAnalystOpen, !this.props.isFoucsPanelOpen, this.props.isResearchPanelOpen)}>
                                <font>Focus Lists</font>
                            </Button>
                            {this.props.isFoucsPanelOpen &&
                                <Panel expanded={this.props.isFoucsPanelOpen} onToggle={() => { }}>
                                    <Panel.Collapse className="custom-collap">
                                        <Panel.Body>
                                            {focusListView}
                                            <div className="history-list">
                                                <div className="heading">
                                                    <h6 className={this.state.open ? "open" : ""} onClick={() => this.setState({ open: !this.state.open })}>Historical Performance</h6>
                                                    <div className="switches">
                                                        <span className="switch-head">Show Flags:</span>
                                                        <ButtonToolbar>
                                                            <ButtonGroup bsSize="" className="custom-on-off-btn" >
                                                                <Button onClick={this.onShowHistory} id="screenButton">Off</Button>
                                                                <Button className={this.props.showHistorical ? "selective" : ""} onClick={this.onShowHistory}>On</Button>
                                                            </ButtonGroup>
                                                        </ButtonToolbar>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                                {this.state.open &&
                                                    <Panel expanded={this.state.open} onToggle={() => { }}>
                                                        <Panel.Collapse className="custom-collap">
                                                            <Panel.Body>
                                                                <Collapse in={this.state.open}>
                                                                    {this.props.historicalList.length === 0 ? <div className="history-list">
                                                                        <span className="no-activity">
                                                                            {IdeaTranslateHelper["NO_HISTORICAL_ACTIVITY"]}
                                                                        </span>
                                                                    </div> :
                                                                        <div className="">
                                                                            {this.props.historicalList.map((item, i) => {
                                                                                if (item.ideaType === IdeaType.NONE) {
                                                                                    return <Well key={i}>
                                                                                        <div className="well-block">
                                                                                            <div className="idealist-exclusive white-front"><span>{item.ideaName}</span></div>
                                                                                        </div>
                                                                                    </Well>;
                                                                                } else {
                                                                                    return <Well key={i}>
                                                                                        <div className="well-block">
                                                                                            <div className="row">
                                                                                                <div className="col-xs-6">
                                                                                                    <span className={`icon-flag ${flagIconStyleConst[item.ideaType] || ""}`}></span>
                                                                                                    <div className="hold">
                                                                                                        <span className={"name"}>{item.ideaName}</span>
                                                                                                        <span className={"date"}>{item.inDt} - {item.outDt}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-xs-2">
                                                                                                    <span style={{ color: item.isPercNeg ? this.props.negDTColor : this.props.posDTColor }} className={"percent"}>{item.percChg}</span>
                                                                                                </div>
                                                                                                <div className="col-xs-4">
                                                                                                    <span className="analysis">
                                                                                                        {item.isPdfDataAvailable ? <a href={this.getDownloadIdeaAnalysisReportWebRequest(item.pdfResearchPath)} >{IdeaTranslateHelper["VIEW_ANALISIS"]}</a> : " "}
                                                                                                        {item.isVideoUrlAvailable ? <a target="_blank" href={item.videoResearchPath}>View Video</a> : " "}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Well>
                                                                                }
                                                                            })}
                                                                        </div>}
                                                                </Collapse>
                                                            </Panel.Body>
                                                        </Panel.Collapse>
                                                    </Panel>}
                                            </div>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            }
                        </div>
                        <div className="oneil-stack">
                            <Button onClick={() => this.props.updateRiPanelSettingsData(this.props.isResearchAnalystOpen, this.props.isFoucsPanelOpen, !this.props.isResearchPanelOpen)}>
                                <font>Research</font>
                            </Button>
                            {this.props.isResearchPanelOpen &&
                                <Panel expanded={this.props.isResearchPanelOpen} onToggle={() => { }}>
                                    <Panel.Collapse className="custom-collap">
                                        <Panel.Body>
                                            <div className="research-list">
                                                {researchView}
                                                {this.isResearhViewMore &&
                                                    <div className="research-more">
                                                        <Link to={{ pathname: '../Research' }} onClick={() => this.setResearchUrl(null, this.props.SymbolInfo.Symbol)} >View more</Link>
                                                    </div>}
                                            </div>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            }
                        </div>
                    </div>
                </div>
                <ScrollBar scrollId='scrollRIPanelOneilTab' vScroll={true} scrollOnHover={true} />
            </div>);
    }
}

const mapStateToProps = ({ RelatedInfoPanelReducers, DatagraphReducers, appColor }) => {
    const { researchList, researchUrl, contactType, entitlementDialogFlag, entitlmentUserName, entitlmentUserEmail, validationUserEmailClass, validationUserNameClass, entitlmentUserPhone,
        validationUserPhoneClass, focusList, currentEntitlementInfo, showHistorical, researchAnalystList, flagToShow, historicalList } = RelatedInfoPanelReducers.RIPanelOneilReducers;
    const { theme, posDTColor, negDTColor } = appColor;
    const { SymbolInfo } = DatagraphReducers.DataGraphReducer;
    const { isResearchAnalystOpen, isFoucsPanelOpen, isResearchPanelOpen } = RelatedInfoPanelReducers.RiPanelReducer;
    return {
        theme, posDTColor, negDTColor, researchList, researchUrl, contactType, entitlementDialogFlag, entitlmentUserName, entitlmentUserEmail, validationUserEmailClass, validationUserNameClass,
        entitlmentUserPhone, validationUserPhoneClass, focusList, currentEntitlementInfo, showHistorical, researchAnalystList, flagToShow, historicalList, SymbolInfo, isResearchAnalystOpen,
        isFoucsPanelOpen, isResearchPanelOpen
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateRiPanelSettingsData: (isResearchAnalystOpen, isFoucsPanelOpen, isResearchPanelOpen) => dispatch(updateRiPanelSettingsData(isResearchAnalystOpen, isFoucsPanelOpen, isResearchPanelOpen)),
    updateLeaderFlagStatus: (item, currentEntitlementArray) => dispatch(updateLeaderFlagStatus(item, currentEntitlementArray)),
    updatePricePanelLeaderFlagStatus: () => updatePricePanelLeaderFlagStatus(),
    updateShowHistoricalFlag: (showHistorical) => dispatch(updateShowHistoricalFlag(showHistorical)),
    updatePricePanelShowHistoricalFlag: (showHistorical) => updatePricePanelShowHistoricalFlag(showHistorical),
    updateContactType: (contactType) => dispatch(updateContactType(contactType)),
    updateEntitlementDialogFlag: (entitlementDialogFlag) => dispatch(updateEntitlementDialogFlag(entitlementDialogFlag)),
    handleEntitlement: (obj) => dispatch(handleEntitlement(obj)),
    updateEntitlementUserName: (userName, validationUserNameClass) => dispatch(updateEntitlementUserName(userName, validationUserNameClass)),
    updateEntitlementUserEmail: (userEmail, validationUserEmailClass) => dispatch(updateEntitlementUserEmail(userEmail, validationUserEmailClass)),
    updateEntitlementUserPhone: (userPhone, validationUserPhoneClass) => dispatch(updateEntitlementUserPhone(userPhone, validationUserPhoneClass))
})

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelOneilInfo);