import ExtremeDataValue from "../ExtremeDataValue";
import GraphType from "GraphType";

class CalcStreetTargetPrice {
    constructor(_state) {
        this.state = _state;
    }
    Arrow(x, y) {
        return `M ${x-2} ${y + 4} L ${x-2} ${y - 4} L ${x + 4} ${y} z`;
    }
    ArrowUp(x, y) {
        return `M ${x} ${y} L ${x-4} ${y+6} L ${x+4} ${y+6} z`;
    }
    ArrowDown(x, y) {
        return `M ${x} ${y} L ${x-4} ${y-6} L ${x+4} ${y-6} z`;
    }
    linePath(x, y, x1, y1) {
        if (isNaN(y) || isNaN(y1)) {
            return "";
        } else {
            return `M ${x} ${y.toFixed(0)} L ${x1} ${y1.toFixed(0)} `;
        }
    }

    calculateArrow(value, currPrice, init, nodeCount, moveBack, length, topOverflow, bottomOverflow){
        const arrow = { priceTargetZones: '', };
        let overflow = false;
        let up = false;
        let down = false;
        let nodeYValue = this.state.scale.ComputeY(value);
        let drawIndex = nodeCount - (moveBack + 1);
        let nodeXValue = (drawIndex * this.state.nodeWidth + 1) + this.state.nodeWidth > this.state.chartWidth ? drawIndex * this.state.nodeWidth + 1 - this.state.nodeWidth : drawIndex * this.state.nodeWidth + 1;
        if (nodeYValue < 25 || nodeYValue > this.state.chartHeight - 25) {
            overflow = true;
            nodeYValue = nodeYValue < 25 ? topOverflow : bottomOverflow;
            up = nodeYValue < 25;
            down = nodeYValue > this.state.chartHeight - 25;
            drawIndex = nodeCount - 1;
            nodeXValue = (drawIndex * this.state.nodeWidth + 1) + this.state.nodeWidth > this.state.chartWidth ? drawIndex * this.state.nodeWidth + 1 - this.state.nodeWidth : drawIndex * this.state.nodeWidth + 1;
        }
        arrow.stArrow = up ? this.ArrowUp(nodeXValue, nodeYValue) : down ? this.ArrowDown(nodeXValue, nodeYValue) : this.Arrow(nodeXValue, nodeYValue);
        arrow.toolTip = overflow ? "" : "12 month price target";
        if (!overflow && length > 0) {
            arrow.priceTargetZones = this.linePath(nodeXValue, nodeYValue,
                nodeXValue -((length - init) * this.state.nodeWidth + 1), nodeYValue)
        }
        arrow.showText = ExtremeDataValue.getPriceString(value);
        const changeValue = Math.round(((value - currPrice) * 100 / currPrice),2);
        arrow.showPct = `(${(changeValue > 0 ? "+" : "")}${changeValue.toFixed(0)}%)`;
        arrow.screenX = nodeXValue;
        arrow.screenY = nodeYValue;
        arrow.overflow = overflow;
        return arrow;
    }

    CalculateSTPData(streetTargetPriceData, currPrice, TimeLine, Periodicity, endDate) {
        const showAllArrow = [];
        if (streetTargetPriceData === null) {
            return showAllArrow;
        };
        const nodeCount = Math.ceil(this.state.chartWidth / this.state.nodeWidth);
        const tDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 365);
        const timeLineList = [];
        TimeLine.forEach((item) => {
            timeLineList.push(item.Date);
        }); 
        let reIndex = timeLineList.findIndex((item)=> item <= endDate);
        reIndex = timeLineList[reIndex].getTime() === endDate.getTime() ? reIndex : reIndex - 1;
        timeLineList[reIndex] = endDate;
        const moveBack = Periodicity !== GraphType.Annual ? timeLineList.findIndex((item)=> item <= tDate) : timeLineList.filter((item)=> item >= tDate).length - 1;
        const legth = reIndex - moveBack;
        const init = Periodicity === GraphType.Weekly ? 0 : 1;
        const topOverflow = 5;
        const bottomOverflow = parseInt(this.state.chartHeight - 5);

        showAllArrow.push(this.calculateArrow(streetTargetPriceData.High, currPrice, init, nodeCount, moveBack, legth, topOverflow, bottomOverflow))
        showAllArrow.push(this.calculateArrow(streetTargetPriceData.Mean, currPrice, init, nodeCount, moveBack, legth, topOverflow + 8, bottomOverflow - 8))
        showAllArrow.push(this.calculateArrow(streetTargetPriceData.Low, currPrice, init, nodeCount, moveBack, legth, topOverflow + 16, bottomOverflow - 16))

        return showAllArrow;
    }
}
export default CalcStreetTargetPrice; 
