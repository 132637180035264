import BaseServiceApi from "../../../ServiceApi/BaseServiceApi";
import { getDatagraphStates } from "../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import GraphApi from "../../../ServiceApi/Apis/GraphApi";
import ListStore from "../../../Stores/NavModules/NavList/ListStore";
import LocalizationStore from "../../../Stores/Localization/LocalizationStore";
import moment from "moment";
import numberFormatter from "number-formatter";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { safe } from "../../ErrorModel";
import SettingStore from "../../../Stores/ConsoleWindow/Settings/SettingsStore";
import ThemeHelper from "ThemeHelper";
import { checkListInfoState, riPanelInfoState } from '../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors';
import { filter, find, each } from 'underscore';
import { PickListOperatorsTranslateHelper, RiPanelCheckListTransLateHelper, ValueOperatorsTranslateHelper, instrumentTypesTranslateHelper } from "../../../Utils/TranslateHelper";
import { select, takeLatest, call, put, fork } from "redux-saga/effects";

const { ActionTypes } = RiPanelConstants;
const ListType = BaseServiceApi.rayData["ListType"];
const ValueOperatorType = BaseServiceApi.rayData["ValueOperatorType"];

let metricLibrary = null;
const instrumentTypeId = -7;
const dataItemIdIndustryGroup = 50;

let yellowDataText, negativeDataText;


function* initCheckListInfo() {
    try {
            const { riPanelViewSettings } = yield select(riPanelInfoState);
            if (!yellowDataText || !negativeDataText) {
                yellowDataText = ThemeHelper.getThemedBrush("fff333");
                negativeDataText = ThemeHelper.getThemedBrush("negativeDataText");
            }
            const result = yield GraphApi.GetCheckListSetsRequest();
            const listItems = filter(result.data.treeNodes, (treeNode) => treeNode.databaseSourceId === 1);
    
            let activeScreen = RiPanelCheckListTransLateHelper['SELECT_SCREEN'];
            let activeListItems = listItems;
            let isShowDetailDailog = false;
            const selectedList = riPanelViewSettings.ActiveColSetId ? getSelectedItem(listItems, riPanelViewSettings.ActiveColSetId, activeScreen) : undefined;
    
            if (selectedList === undefined) {
                const defaultItem = find(listItems, (item) => item.listType !== ListType.FOLDER_List);
                if(defaultItem){
                    yield fork(getChecklistData, { colSetId: defaultItem.nodeId.low, sourceColSetId: defaultItem.sourceColumnSetID });
                }
            }
            else {
                isShowDetailDailog = selectedList.activeScreen !== RiPanelCheckListTransLateHelper['SELECT_SCREEN'];
                activeScreen = selectedList.activeScreen;
                activeListItems = selectedList.activeList;
                yield fork(getChecklistData, {colSetId: selectedList.activeNode.nodeId.low, sourceColSetId: selectedList.activeNode.sourceColumnSetID});
            }
    
            yield put({
                type: ActionTypes.UPDATE_LIST_ITEMS,
                listItems,
                activeListItems,
                isShowDetailDailog,
                activeScreen: activeScreen.toUpperCase()
            });
    } catch (error) {
        console.log(`Error occurs in RiPanelCheckListInfo.js, initCheckListInfo ${error}`);
    }
}

function* getChecklistData({ colSetId, sourceColSetId }) {
    try {
        if (sourceColSetId === null || sourceColSetId === undefined) { sourceColSetId = 0; }
        const { SymbolInfo } = yield select(getDatagraphStates);
        const { activeListItems, hideWithoutThreshold } = yield select(checkListInfoState);

        if (colSetId) {
            const columnData = yield GraphApi.GetCheckListDataRequest(SymbolInfo.MsId, colSetId, sourceColSetId, hideWithoutThreshold);
            const curData = yield safe(call(conversionColumnName, columnData, activeListItems), "RiPanelCheckListInfo.js", "conversionColumnName");
            const resultSet = yield safe(call(getColumnSetDataInfo, curData, colSetId, hideWithoutThreshold), "RiPanelCheckListInfo.js", "getColumnSetDataInfo");
            const { riPanelViewSettings } = yield select(riPanelInfoState);
            riPanelViewSettings.ActiveColSetId = colSetId;
            SettingStore.saveSettings();
            yield put({
                type: ActionTypes.UPDATE_COLUMN_SET_INFO,
                activeColSetId: colSetId,
                activeSourceColSetId: sourceColSetId,
                columnSetInfo: resultSet
            });
        }
    } catch (error) {
        console.log(`Error occurs in RiPanelCheckListInfo.js, getChecklistData ${error}`);
    }
}

function conversionColumnName(columnData, activeListItems) {
    each(activeListItems, (item) => {
        if (item.nodeId.low === columnData.nodeId.low) {
            columnData.columnName = item.name;
        }
    });

    return columnData;
}

function getColumnSetDataInfo(result, colSetId, hideWithoutThreshold) {
    let passCount = 0;
    let NACount = 0;
    let total = 0;

    const returnData = {
        columnName: result.columnName,
        isModified: result.isModified,
        nodeId: result.nodeId,
        sourceColumnSetId: result.nodeId,
        score: result.score,
        screenMetricList: []
    }
    //let filterDataList = result.filterData;
    if (result && result.filterData) {
        for (const metricData of result.filterData) {

            const symbolValue = translateFilterName(result.filterData, metricData.actualValue, metricData.metricName);
            const screenMetric = {
                isPickList: metricData.isPickList,
                dataType: metricData.dataType,
                formatter: metricData.formatter,
                isZeroVisible: metricData.isZeroVisible,
                metricName: LocalizationStore.getTranslatedData(`DataItemExt_DisplayName_${metricData.msItemID}`, metricData.metricName),
                symbolValue: symbolValue,
                colSetId: colSetId,
                metricId: metricData.msItemID,
                hasFilter: metricData.metricFilterData.length > 0,
                filterList: [],
                color: '',
                isNegFontColor: false
            }

            screenMetric.symbolValue = formatSymbolValue(screenMetric.symbolValue, screenMetric.formatter, screenMetric.isZeroVisible);

            if (screenMetric.metricId === instrumentTypeId) { screenMetric.symbolValue = instrumentTypesTranslateHelper[parseInt(metricData.actualValue)]; }

            if (screenMetric.hasFilter) {
                let betweenFlag = false;

                

                let firstFilterValue = '';

                for (const filterData of metricData.metricFilterData) {
                    const filterDO = {
                        operator: '',
                        filterValue: '',
                        // id:fiterId
                    }
                    let filterValue = '';
                    if (filterData.thresholdValue === "No Threshold") {
                        filterValue = LocalizationStore.getTranslatedData("checklist_NoThr", filterData.thresholdValue);
                    }
                    else {
                        if (screenMetric.metricId === dataItemIdIndustryGroup) {
                            const filterName = filterData.thresholdValue.replace(" ", "").toUpperCase();
                            filterValue = LocalizationStore.getTranslatedData(`FilterBrowser_IndustryGroup_${filterName.replace(/ /g, '')}`, filterName);
                        } else {
                            filterValue = translateFilterName(result.filterData, filterData.thresholdValue, metricData.metricName);
                        }
                    }

                    filterDO.filterValue = filterValue;
                    filterDO.operator = filterData.isZeroIncludedInScore ? "" : ValueOperatorsTranslateHelper[filterData.valueOperator];

                    if (screenMetric.isPickList) {
                        if (filterData.isZeroIncludedInScore) {
                            filterDO.pickListTagVisibility = false;
                            screenMetric.pickListOperatorVisibility = false;
                        }
                        else {
                            filterDO.pickListTagVisibility = true;
                            screenMetric.pickListOperatorVisibility = true;
                            screenMetric.pickListOperator = PickListOperatorsTranslateHelper[filterData.valueOperator];
                        }
                    }

                    if (screenMetric.metricId === instrumentTypeId && !filterData.isZeroIncludedInScore) { filterDO.filterValue = instrumentTypesTranslateHelper[parseInt(filterData.thresholdValue)]; }


                    if (betweenFlag && filterData.valueOperator === ValueOperatorType.BETWEEN_ValueOperator) {
                        filterDO.filterValue = `${firstFilterValue}-${filterDO.filterValue.replace(/\.00$/, '')}`;
                        betweenFlag = false;
                    }
                    else {
                        if (filterData.valueOperator === ValueOperatorType.BETWEEN_ValueOperator) { betweenFlag = true; }
                        firstFilterValue = filterDO.filterValue.replace(/\.00$/, '');
                    }

                    !betweenFlag && screenMetric.filterList.push({ filterDO });
                }

                let isAdd = true;
                const val = metricData.metricFilterData.some((e) => e.isZeroIncludedInScore);

                if (hideWithoutThreshold && val) {
                    isAdd = false;
                }
                if (isAdd) {
                    returnData.screenMetricList.push({
                        screenMetric
                    });
                }

                if (!val) {
                    if (metricData.actualValue === "N/A") { NACount++; }
                    else { total++; }

                    let anyPass = true;// metricData.metricFilterData.some((e) => e.pass);

                    metricData.metricFilterData.forEach((e) => anyPass = anyPass && e.pass);

                    if (anyPass) {
                        screenMetric.color = yellowDataText;
                        passCount++;
                    }
                    else {
                        //Set Negative Color
                        screenMetric.color = negativeDataText;
                        screenMetric.isNegFontColor = true;
                    }
                }
                else {
                    screenMetric.color = yellowDataText;
                }
            }
        }
    }

    if (returnData.screenMetricList.length > 0) { returnData.noFilterVisibility = false; }

    if (total !== 0) {
        returnData.totalScore = calculateTotalScore( passCount, total);
        returnData.totalFilter = `${RiPanelCheckListTransLateHelper['TOTAL_COUNT'].replace('{0}', passCount).replace('{1}', total)} ( ${NACount} N/A )`;
    }
    else {
        returnData.totalScore = "-%";
        returnData.totalFilter = `${RiPanelCheckListTransLateHelper['TOTAL_COUNT'].replace('{0}', '0').replace('{1}', '0')} ( ${NACount} N/A )`;
    }

    return returnData;
}

function translateFilterName(filterData, filterName, metricName) {
    let name = LocalizationStore.getTranslatedData(`FilterBrowser_IndustryGroup_${filterName.replace(/ /g, '').toUpperCase()}`, filterName);
    if (filterName.toLowerCase() === 'yes') {
        name = LocalizationStore.getTranslatedData("misc_id7", filterName);
    } else if (metricName === 'Sector Name') {
        name = LocalizationStore.getTranslatedData(`LM_SectorName_${filterName.replace(/ /g, '').toUpperCase()}`, filterName);
    } else if (metricName === "GICS IndGrp") {
        name = LocalizationStore.getTranslatedData(`LM_GICSIndustryGroup_${filterName.replace(/ /g, '').toUpperCase()}`, filterName);
    } else if (metricName === "GICS Ind") {
        name = LocalizationStore.getTranslatedData(`LM_GICSIndustry_${filterName.replace(/ /g, '').toUpperCase()}`, filterName);
    } else if (metricName === "GICS Sector") {
        name = LocalizationStore.getTranslatedData(`LM_GICSSector_${filterName.replace(/ /g, '').toUpperCase()}`, filterName);
    } else if (metricName === "GICS SubInd") {
        name = LocalizationStore.getTranslatedData(`LM_GICSSubIndustry_${filterName.replace(/ /g, '').toUpperCase()}`, filterName);
    } else if (metricName === "Ind Grp Name") {
        name = LocalizationStore.getTranslatedData(`FilterBrowser_IndustryGroup_${filterName.replace(/ /g, '').toUpperCase()}`, filterName);
    } else if (metricName === "Sector, Maj Ind Name") {
        name = LocalizationStore.getTranslatedData(`LM_MajorIndustryName_${filterName.replace(/ /g, '').toUpperCase()}`, filterName);
    } else if (metricName === "HQ Country") {
        name = LocalizationStore.getTranslatedData(`Country_${filterName.replace(/ /g, '')}`, filterName);
    } else if (metricName.indexOf("Base Type") === 0) {
        name = LocalizationStore.getTranslatedData(`BaseType_${filterName.replace(/ /g, '')}`, filterName);
    } else if (metricName.indexOf("Rotation Graph Quadrant") === 0) {
        name = LocalizationStore.getTranslatedData(`Es_${filterName.replace(/ /g, '')}`, filterName);
    } else if (metricName.indexOf("Base Status") === 0) {
        name = LocalizationStore.getTranslatedData(`Es_${filterName.replace(/ /g, '')}`, filterName);
    } else {
        for (const x of filterData) {
            if (x.metricName === filterName) {
                name = LocalizationStore.getTranslatedData(`DataItemExt_DisplayName_${x.msItemID}`, filterName);
                break;
            } else if (x.actualValue === filterName) {
                name = filterName;
                break;
            } else if (x.metricName === metricName) {
                const id = getIdFromMetric(filterName);
                name = LocalizationStore.getTranslatedData(`DataItemExt_DisplayName_${id}`, filterName);
                break;
            }
        }
    }

    return name;
}

function getIdFromMetric(filterName) {

    if (!metricLibrary) {
        metricLibrary = ListStore.getMetricLibrary();
    }

    let id;
    if (metricLibrary && metricLibrary.itemData) {
        each(metricLibrary.itemData, (item) => {
            if (item.ColumnDisplayName === filterName) {
                id = item.MsItemID;
            }
        });
    }
    return id;
}

function formatSymbolValue(value, formatter, isZeroVisible) {

    let returnVal = value;
    let isZeroValue = false;
    const ZERO_VALUE = 0.000000001;

    const dateFormatIndex = formatter.toUpperCase().indexOf("MM/DD/YYYY");

    if (dateFormatIndex !== -1) {
        if (moment(value, moment.ISO_8601).isValid()) {
            returnVal = moment(value).format(formatter.toUpperCase());
        }
    }
    else {
        const floatVal = parseFloat(value);
        if (!isNaN(floatVal) && formatter) {

            if (formatter.toUpperCase().trim() === "MM/DD/YYYY") { returnVal = ""; }
            else if (formatter.toUpperCase().trim() === "@") { returnVal = value; }
            else {
                isZeroValue = (floatVal < ZERO_VALUE && floatVal > -ZERO_VALUE)
                returnVal = setNumberFormatter(floatVal, formatter);
            }
        }

        if (floatVal === 0 && formatter) {
            isZeroValue = true;
            returnVal = setNumberFormatter(floatVal, formatter);
        }
    }

    if (isZeroValue && !isZeroVisible) { returnVal = ""; }

    return returnVal;
}

function setNumberFormatter(doubleValue, formatter) {
    let cellValue = '';

    switch (formatter) {
        case '#,##0%':
            cellValue = `${Number(doubleValue).toFixed(0)}%`;
            break;

        case '#,##0':
            cellValue = new Intl.NumberFormat().format(Number(doubleValue).toFixed(0));
            break;
        case '(#,##0)':
            cellValue = numberFormatter("(#,##)", Math.abs(doubleValue));
            break;

        case '#,##0.00':
        case '#,##0.0%':
            cellValue = numberFormatter(formatter, doubleValue);
            break;

        default:
            cellValue = numberFormatter(formatter, doubleValue);
    }
    return cellValue;
}

function calculateTotalScore(passCount, total) {
    const rval = Math.round((passCount * 100) / total);
    return `${rval}%`;
}

function getSelectedItem(pList, activeColSetId, activeScreen) {
    let returnItem = undefined;
    for (let j = 0; j < pList.length; j++) {
        if ((pList[j].listType !== ListType.FOLDER_List) && (parseInt(activeColSetId) === parseInt(pList[j].nodeId.low))) {
            return { activeList: pList, activeNode: pList[j], activeScreen };
        }
    }
    for (let i = 0; i < pList.length; i++) {
        if (pList[i].listType === ListType.FOLDER_List) {
            returnItem = getSelectedItem(pList[i].childNodes, activeColSetId, pList[i].name);
            if (returnItem) {
                return returnItem
            }
        }
    }
    return returnItem;
}

/*** watchers ***/

export function* watchInitCheckListInfo() {
    yield takeLatest(ActionTypes.INIT_CHECKLIST_INFO, initCheckListInfo);
}

export function* watchGetCheckListData() {
    yield takeLatest(ActionTypes.GET_CHECKLIST_DATA, getChecklistData)
}