import { RotationGraphConstants, ComparisonAndRotationMenuConstants } from "RotationGraphConstants";
import CompareTabType,{CompareMenuType} from "CompareTabType";
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import { SettingsConstants } from "Constants/SettingsConstants.js";
extend(ActionTypes, RotationGraphConstants.ActionTypes,SettingsConstants.ActionTypes);
import SettingsStore from "SettingsStore";
import { extend, map } from "underscore";
import RotationGraphStore from "RotationGraphStore";
import CompareStore from "CompareStore";
import { PrintMode } from '../../../print/printmode';

const initialState = {
    isLoading: {
        ComparisonGraph:{
            SymbolsLoading:false,
            GroupsLoading:false
        },
    RotationGraph:{
        SymbolsLoading:true,
        GroupsLoading:true
    }},
    settings: {},
    selectedTab:null,
    ComparisonMenuTab: null,
    RotationMenuTab: null,
    isRIPanelOpen: false
}

const MenuTabReducer = (state = initialState, action) => {

    const setLoadingStatus = (obj) => {

        if (state.selectedTab === CompareTabType.ComparisonGraph) {
            if (state.ComparisonMenuTab === CompareMenuType.Symbols) {
                map(obj, (value, property) => state[property].ComparisonGraph.SymbolsLoading = value);
            } else {
                map(obj, (value, property) => state[property].ComparisonGraph.GroupsLoading = value);
            }
        } else {
            if (state.RotationMenuTab === CompareMenuType.Symbols) {
                map(obj, (value, property) => state[property].RotationGraph.SymbolsLoading = value);
            } else {
                map(obj, (value, property) => state[property].RotationGraph.GroupsLoading = value);
            }
        }
        return { ...state };
    }
      

    switch (action.type) {

        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
            return {
                ...state,
                selectedTab: action.selectedTab
            }

        case ActionTypes.INIT_RI_PANEL_SETTINGS:
            if (state.selectedTab === CompareTabType.ComparisonGraph) {
                if (!PrintMode.printing) {
                    CompareStore.updateIsCompareMenuPanelOpen(true);
                }
                return {
                    ...state,
                    isComparisonMenuPanelOpen: action.settings.isComparisonMenuPanelOpen,
                    ComparisonMenuTab: action.settings.comparisonMenuTab
                }
            } else {
                if (!PrintMode.printing) {
                    RotationGraphStore.updateIsRotationMenuPanelOpen(true)
                }
                return {
                    ...state,
                    isRotationMenuPanelOpen: action.settings.isRotationMenuPanelOpen,
                    RotationMenuTab: action.settings.rotationMenuTab
                }
            }

        case ActionTypes.TOGGLE_RI_PANEL:
            if (state.selectedTab === CompareTabType.ComparisonGraph) {

                CompareStore.updateIsCompareMenuPanelOpen(!state.isComparisonMenuPanelOpen);

                return {
                    ...state,
                    isComparisonMenuPanelOpen: !state.isComparisonMenuPanelOpen
                }
            } else {

                RotationGraphStore.updateIsRotationMenuPanelOpen(!state.isRotationMenuPanelOpen)

                return {
                    ...state,
                    isRotationMenuPanelOpen: !state.isRotationMenuPanelOpen,
                }
            }

        case ActionTypes.SET_COMPARE_MENUTAB:
            const settings = SettingsStore.getConsoleSettings().NavCompareSettings;
            if (state.selectedTab === CompareTabType.ComparisonGraph) {
                settings.TabComparisonGraphSettings.comparisonMenuTab = action.menuTab;
                SettingsStore.saveSettings();
                return {
                    ...state,
                    ComparisonMenuTab: action.menuTab
                }
            }
            else {
                settings.TabRotationGraphSettings.rotationMenuTab = action.menuTab;
                SettingsStore.saveSettings();
                return {
                    ...state,
                    RotationMenuTab: action.menuTab
                }
            }

            case ActionTypes.MENU_LOADING:
               return setLoadingStatus({
                   isLoading:action.isLoading
               })

            case ActionTypes.HANDLE_ERROR:
                return setLoadingStatus({
                    isLoading:false
                })
          

        default:
            return { ...state }
    }
}

export default MenuTabReducer;