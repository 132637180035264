export default{
  printScreenBlock:{
    width:'100%',
    height:'100%',
    background:'#F0F0F0'
  },
  customPrintHead:{
    background:'#ffffff'
  },
  bodyTr:{
    background:'#fff'
  },
  headThName:{
    fontSize:'11px',
    textAlign:'left',
    padding:'3px 10px',
    background:'#CCC',
    fontFamily :'Arial, serif, sans-sesrif',
    borderBottom:'2px solid #999'
  },
  headThStatus:{
    width:'105px',
    fontSize:'11px',
    textAlign:'left',
    padding:'3px 10px',
    background:'#CCC',
    fontFamily :'Arial, serif, sans-sesrif',
    borderBottom:'2px solid #999'
  },
  headThProgress:{
    width:'50px',
    fontSize:'11px',
    textAlign:'left',
    padding:'3px 10px',
    background:'#CCC',
    fontFamily :'Arial, serif, sans-sesrif',
    borderBottom:'2px solid #999'
  },
  headThAction:{
    width:'50px',
    fontSize:'11px',
    textAlign:'left',
    padding:'3px 10px',
    background:'#CCC',
    fontFamily :'Arial, serif, sans-sesrif',
    borderBottom:'2px solid #999'
  },
  bodyTd:{
    fontSize:'10px',
    textAlign:'left',
    padding:'3px 10px',
    fontFamily :'Arial, serif, sans-sesrif',
    borderTop:'1px solid #ddd'
  },
  bodyTdFirst:{
    fontSize:'10px',
    textAlign:'left',
    padding:'0 10px',
    borderTop:'1px solid #ddd'
  },
  bodyTdFirstHold:{
    fontSize:'11px',
    textAlign:'left',
    float:'left',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    width:'140px',
    fontFamily:'Arial, serif, sans-sesrif'
  },
  bodyTdProgress:{
    width:'100%',
    height:'1.5em'
  },
  bodyTdButton:{
    width:'100%',
    fontSize:'10px',
    fontWeight:'bold',
    color:'#FFF',
    border:'0',
    borderRadius:'2px',
    padding:'3px',
    background:'#42424b',
    "&:hover":{
      background:"#efefef"
    }
  },
  tableResponsive:{
    overflow:'auto',
    height:'inherit',
    height:'hidden',
    minHeight:'0.01%',
    overflowX:'auto',
    width:'100%'
  },
  table:{
    width:'100%',
    maxWidth:'100%',
    marginBottom:' 20px',
    backgroundColor :'transparent'
  },
  bodyTrError:{
    background:'#FAFAFA',
    borderTop:'1px solid #F0F0F0',
    padding:'2px 10px'
  },
  errorListH5:{
    float:'left',
    minWidth:'20%',
    maxWidth:'105px',
    padding:'0',
    margin:'0',
    marginBlockStart:'0',
    marginBlockEnd:'0',
    fontFamily:'Arial, serif, sans-sesrif',
    fontSize:'10px',
    color:'#333',
    textAlign:'left'
  },
  errorListUl:{
    float:'left',
    width:'77%',
    padding:'0',
    margin:'0'
  },
  errorListLi:{
    float:'left',
    width:'100%',
    padding:'0',
    margin:'1px 0',
    fontFamily:'Arial, serif, sans-sesrif',
    fontSize:'10px',
    fontWeight:'normal',
    color:'#333',
    lineHeight:'12px',
    textAlign:'left',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    listStyle:'disc inside'
  },
  carrotR:{
    width:'0',
    height:'0',
    borderLeft:'5px solid #333',
    borderTop:'3px solid transparent',
    borderBottom:'3px solid transparent',
    display:'inline-block',
    margin:'0 0 0 1px'
  },
  carrotB:{
    width:'0',
    height:'0',
    borderTop:'5px solid #333',
    borderLeft:'3px solid transparent',
    borderRight:'3px solid transparent',
    display:'inline-block',
    margin:'0 0 0 1px'
  }
}