import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetListBrowserRequest extends BaseServiceRequest {
    constructor(esBasicInfo) {
        super();
        super.addRequestUri("esbrowserfilters.pb");
        super.addPathParameter(esBasicInfo.listId.toString());
        super.setContent(esBasicInfo.encode());
        // super.addRequestParameter("cache", cacheId.toString());
        // super.addRequestParameter("cfv", "2"); //((int)ColumnFilterVersionType.MvM).ToString());
        // super.addRequestParameter("icf", isColumnFilterEnabled ? "1" : "0");

    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListCatalogResponse";
    }
}

export default GetListBrowserRequest;
