import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetTempListRequest extends BaseServiceRequest {

  constructor(industryCode, msid, type, spId = '') {
    super();
    super.addRequestUri("tempbasic.pb");
    super.addRequestParameter("sym", industryCode);
    super.addRequestParameter("tlid", type);
    if (msid !== '') super.addRequestParameter("msid", msid);
    if (spId !== '') super.addRequestParameter("sid", spId);
    super.addRequestParameter("rts", 0);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "TemporaryListResponse";
  }
}

export default GetTempListRequest;
