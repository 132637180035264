import AppColorStore from "Stores/AppColor/AppColorStore";
import BrowserUtil from "BrowserUtil";
import { connect } from 'react-redux';
import { getSelectedAnnotationInfo } from '../../../../../../Reducers/AnnotationReducers/reselectorsdata';
import ThemeHelper from "ThemeHelper";
const textWidth = require("text-width");
import { RayAnnotationType, StrokeStyleType, TextAlignmentType } from '../../../../../../Constants/EAnnotationType';
import React, { PureComponent } from 'react';

class AnnotationPreview extends PureComponent {

    componentDidMount() {
        AppColorStore.addChangeListener(this.colorChanged);

    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.colorChanged);
    }

    colorChanged = () => {
        this.forceUpdate();
    }

    lineSettingsBox = () => {
        const { stroke, strokeStyle, opacity, lineX1, lineY1, lineY2, color, weight, startPolygonPoints, endExtendChecked } = this.props.modelInfo
        const isDotted = strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType;
        const lineColor = ThemeHelper.getThemedBrush(color);
        const lineX2 = endExtendChecked ? 266 : 220;
        let { endPolygonPoints, endArrowChecked } = this.props.modelInfo;
        endPolygonPoints = endExtendChecked && endArrowChecked ? '244,35 244,25 256,30' : endPolygonPoints;
        return (
            <svg>
                <rect className="svg-rect"></rect>
                <svg style={{ opacity: opacity }}>
                    <line strokeDasharray={isDotted ? weight : stroke} x1={lineX1} y1={lineY1} x2={lineX2} y2={lineY2} style={{ stroke: ThemeHelper.getThemedBrush(color), strokeWidth: weight }} />
                    <polygon id="endPolygon" points={endPolygonPoints} style={{ fill: lineColor, stroke: lineColor, strokeWidth: weight }} />
                    <polygon id="startPolygon" points={startPolygonPoints} style={{ fill: lineColor, stroke: lineColor, strokeWidth: weight }}></polygon>
                </svg>
            </svg>
        )
    }

    channelLinesSettingsBox = () => {
        const { stroke, strokeStyle, color, weight, lineX1, lineX2, lineY1, lineY2 } = this.props.modelInfo
        const isDotted = strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType;
        const linesColor = ThemeHelper.getThemedBrush(color);

        return (
            <svg>
                <rect className="svg-rect"></rect>
                <line x1={lineX1} y1={lineY1} x2={lineX2} y2={lineY2} strokeDasharray={isDotted ? weight : stroke} style={{ stroke: linesColor, strokeWidth: weight }} />
                <line x1={lineX1} y1={lineY1 + 20} x2={lineX2} y2={lineY2 + 20} strokeDasharray={isDotted ? weight : stroke} style={{ stroke: linesColor, strokeWidth: weight }} />
            </svg>)
    }

    fibonacciSettingsBox = () => {
        const { upperLineX1, upperLineY1, upperLineX2, upperLineY2, color, weight, upperRetracementLineX1, upperRetracementLineY1, upperRetracementLineX2, upperRetracementLineY2,
            lowerRetracementLineX1, lowerRetracementLineY1, lowerRetracementLineX2, lowerRetracementLineY2, lowerLineX1, lowerLineY1, lowerLineX2, lowerLineY2 } = this.props.modelInfo
        const lineColor = ThemeHelper.getThemedBrush(color);
        return (
            <svg>
                <rect className="svg-rect"></rect>
                <line x1={upperLineX1} y1={upperLineY1} x2={upperLineX2} y2={upperLineY2} style={{ stroke: lineColor, strokeWidth: weight }}></line>
                <line x1={upperRetracementLineX1} y1={upperRetracementLineY1} x2={upperRetracementLineX2} y2={upperRetracementLineY2} strokeDasharray={[weight * 2.5, weight]} style={{ stroke: lineColor, strokeWidth: weight }}></line>
                <line x1={lowerRetracementLineX1} y1={lowerRetracementLineY1} x2={lowerRetracementLineX2} y2={lowerRetracementLineY2} strokeDasharray={[weight * 2.5, weight]} style={{ stroke: lineColor, strokeWidth: weight }}></line>
                <line x1={lowerLineX1} y1={lowerLineY1} x2={lowerLineX2} y2={lowerLineY2} style={{ stroke: lineColor, strokeWidth: weight }}></line>
            </svg>)
    }

    measurementSettingsBox = () => {
        const { isLeaderlinesChecked, color } = this.props.modelInfo
        const boxColor = ThemeHelper.getThemedBrush(color);
        return (
            <svg>
                <rect className="svg-rect"></rect>
                {isLeaderlinesChecked ?
                    <g>
                        <line style={{ stroke: boxColor, strokeWidth: "2px" }} x1={50} y1={50} x2={220} y2={50} />
                        <line x1="50" y1="48" x2="50" y2="8" strokeDasharray="6,2" style={{ stroke: boxColor, strokeWidth: "2px" }}></line>
                        <line x1="220" y1="48" x2="220" y2="8" strokeDasharray="6,2" style={{ stroke: boxColor, strokeWidth: "2px" }}></line>
                    </g>
                    :
                    <line style={{ stroke: boxColor, strokeWidth: "2px" }} x1={50} y1={30} x2={220} y2={30} />
                }
            </svg>)
    }

    shapesSettingsBox = () => {

        const { selectedType } = this.props.modelInfo;
        const renderRectangle = () => {
            if (selectedType === RayAnnotationType.RECT_AnnotationType) {
                const { bgColor, borderColor, borderStroke, opacity, borderWeight, isBorderChecked } = this.props.modelInfo
                return (
                    <rect width="30" height="30" x="118" y="16" strokeDasharray={isBorderChecked ? borderStroke : '0, 0'} style={{ fill: ThemeHelper.getThemedBrush(bgColor), fillOpacity: opacity, stroke: isBorderChecked ? ThemeHelper.getThemedBrush(borderColor) : ThemeHelper.getThemedBrush(bgColor), strokeWidth: isBorderChecked ? borderWeight : '1px' }} />
                )
            }
        }

        const renderCircle = () => {
            if (selectedType === RayAnnotationType.ELLIPSE_AnnotationType) {
                const { bgColor, borderColor, borderStroke, opacity, borderWeight, isBorderChecked } = this.props.modelInfo
                return (
                    <ellipse cx="133" cy="31" rx="15" ry="15" strokeDasharray={isBorderChecked ? borderStroke : '0, 0'} style={{ fill: ThemeHelper.getThemedBrush(bgColor), fillOpacity: opacity, stroke: isBorderChecked ? ThemeHelper.getThemedBrush(borderColor) : ThemeHelper.getThemedBrush(bgColor), strokeWidth: isBorderChecked ? borderWeight : '1px' }} />
                )
            }
        }

        const renderTriangle = () => {
            if (selectedType === RayAnnotationType.TRIANGLE_AnnotationType) {
                const { bgColor, borderColor, borderStroke, opacity, borderWeight, isBorderChecked } = this.props.modelInfo
                return (
                    <polygon points="133,16 118,46 148,46" strokeDasharray={isBorderChecked ? borderStroke : '0, 0'} style={{ fill: ThemeHelper.getThemedBrush(bgColor), fillOpacity: opacity, stroke: isBorderChecked ? ThemeHelper.getThemedBrush(borderColor) : ThemeHelper.getThemedBrush(bgColor), strokeWidth: isBorderChecked ? borderWeight : '1px' }} />
                )
            }
        }
        return (
            <svg>
                <rect className="svg-rect"></rect>
                {renderRectangle()}
                {renderCircle()}
                {renderTriangle()}
            </svg>
        )
    }

    textSettingsBox = () => {
        const { bgColor, borderStroke, opacity, borderColor, borderWeight, color, isBorderChecked, fontStyle, fontSize, isBold, isItalic, isUnderline, textAlign } = this.props.modelInfo

        const txtWidth = textWidth("Sample", {
            family: fontStyle,
            size: fontSize,
            weight: isBold ? "bold" : ''
        });
        const rectXPlacement = borderWeight === '1' ? '40' : borderWeight === '2' ? '40' : borderWeight === '4' ? '39' : borderWeight === '6' ? '38' : borderWeight === '8' ? '37' : '33';
        const rectWidth = borderWeight === '1' ? '180' : borderWeight === '2' ? '180' : borderWeight === '4' ? '182' : borderWeight === '6' ? '184' : borderWeight === '8' ? '186' : '194';
        const textXPlacement = textAlign === TextAlignmentType.LEFT ? + `${txtWidth + 40}px` : textAlign === TextAlignmentType.RIGHT ? `${txtWidth + 39 + (180 - txtWidth)}px` : `${txtWidth + 40 + (180 - txtWidth) / 2}px`;
        const textYPlacement = fontSize === "40" ? (BrowserUtil.getBrowserName() === "IE" || BrowserUtil.getBrowserName() === "Netscape" || BrowserUtil.getBrowserName() === "Firefox") ? '71%' : '56%' :
            fontSize === "28" ? (BrowserUtil.getBrowserName() === "IE" || BrowserUtil.getBrowserName() === "Netscape" || BrowserUtil.getBrowserName() === "Firefox") ? '65%' : '55%' :
                fontSize === "20" ? (BrowserUtil.getBrowserName() === "IE" || BrowserUtil.getBrowserName() === "Netscape" || BrowserUtil.getBrowserName() === "Firefox") ? '60%' : '53%' :
                    fontSize === "16" ? (BrowserUtil.getBrowserName() === "IE" || BrowserUtil.getBrowserName() === "Netscape" || BrowserUtil.getBrowserName() === "Firefox") ? '56%' : '51%' :
                        (fontSize === "14" || fontSize === "12" || fontSize === "10" || fontSize === "8") ? (BrowserUtil.getBrowserName() === "IE" || BrowserUtil.getBrowserName() === "Netscape" || BrowserUtil.getBrowserName() === "Firefox") ? '56%' : '51%' : '51%';
        return (
            <svg>
                <rect className="svg-rect"></rect>
                {isBorderChecked ? <rect x={rectXPlacement} y="16" height="30" width={rectWidth} strokeDasharray={borderStroke} style={{ fill: ThemeHelper.getThemedBrush(bgColor), fillOpacity: opacity, stroke: ThemeHelper.getThemedBrush(borderColor), strokeWidth: borderWeight, strokeOpacity: "100" }} className="svg-rect"></rect> : <rect x="43" y="16" height="30" width="180" style={{ fill: ThemeHelper.getThemedBrush(bgColor), fillOpacity: opacity }} className="svg-rect"></rect>}
                <text x={textXPlacement} y={textYPlacement} alignmentBaseline="middle" textAnchor={textAlign === TextAlignmentType.LEFT ? 'end' : textAlign === TextAlignmentType.RIGHT ? 'end' : 'end'} style={{ fontFamily: fontStyle, fontWeight: isBold === true ? 'bold' : '', fontStyle: isItalic === true ? 'italic' : '', fontSize: fontSize, lineHeight: `${fontSize}px`, textDecoration: isUnderline === true ? 'underline' : '', fill: ThemeHelper.getThemedBrush(color), textAlign: textAlign, width: '10px' }}>
                    Sample
                </text>
            </svg>
        )
    }

    getPreview = (annotationType) => {
        switch (annotationType) {
            case RayAnnotationType.LINE_AnnotationType: return this.lineSettingsBox();
            case RayAnnotationType.TEXT_AnnotationType: return this.textSettingsBox();
            case RayAnnotationType.RECT_AnnotationType: return this.shapesSettingsBox();
            case RayAnnotationType.ELLIPSE_AnnotationType: return this.shapesSettingsBox();
            case RayAnnotationType.TRIANGLE_AnnotationType: return this.shapesSettingsBox();
            case RayAnnotationType.FIB_AnnotationType: return this.fibonacciSettingsBox();
            case RayAnnotationType.PARALLEL_LINES_AnnotationType: return this.channelLinesSettingsBox();
            case RayAnnotationType.MEASUREMENT_AnnotationType: return this.measurementSettingsBox();
            default:
                break;
        }
    }

    render() {
        const { label, annotationType } = this.props;
        return (
            <>
                <div className="title"><h5>{label}</h5></div>
                <div className="annotation-intruments-demo">
                    {this.getPreview(annotationType)}
                </div>
            </>
        );
    }
}

AnnotationPreview.propTypes = {

};

const mapStateToProps = (state) => ({
    modelInfo: getSelectedAnnotationInfo(state),
    annotationType: state.annotationReducers.annotationMenuReducer.selectedToolType
})

export default connect(mapStateToProps, null)(AnnotationPreview);