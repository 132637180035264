import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import StringUtil from "StringUtil";

export default class FlexibleModal extends Component {

    constructor(props) {
        super(props);
        this.subscribeEvents = this.subscribeEvents.bind(this);
        this.unsubscribeEvents = this.unsubscribeEvents.bind(this);
        this.isDragDisabled = this.isDragDisabled.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.updateStateDragging = this.updateStateDragging.bind(this);

        this.state = {
            isDragging: false,
            left: this.props.left,
            top: this.props.top
        };
    }

    subscribeEvents() {
        let elm = ReactDOM.findDOMNode(this.node); 
        elm.addEventListener("mousemove", this.handleMouseMove, false);
        elm.addEventListener("mouseup", this.onMouseUp, false);
    }
    unsubscribeEvents() {
        let elm = ReactDOM.findDOMNode(this.node); 
        elm.removeEventListener("mousemove", this.handleMouseMove, false);
        elm.removeEventListener("mouseup", this.onMouseUp, false);
    }

    isDragDisabled(elm) {
        if (this.props.draggable == false || (elm.className.startsWith("ssb_v_") || elm.classList.contains("disable-drag") || elm.type == "checkbox" || elm.type == "number" || elm.type == "text" || elm.type == "checkbox"))
            return true;
        else if(this.props.isDefaultDragEnabled == false && elm.classList.contains("enable-drag"))
            return false;
        else if(this.props.isDefaultDragEnabled == false)
            return true;
        else
            return false;
    }
    onMouseDown(e) {

        if (e.button === 2) {
        }
        else {
            this.subscribeEvents();
            var elm = document.elementFromPoint(e.clientX, e.clientY);
            const nodeStyle = this.node.props.style;

            this.x = e.clientX - parseInt(nodeStyle.left),
                this.y = e.clientY - parseInt(nodeStyle.top)

            if (!this.isDragDisabled(elm)) {            
                this.updateStateDragging(true);
            }
        }
    }

    handleMouseMove(e) {        
        if (this.state.isDragging == true) {
            this.setState({
                top: e.clientY - this.y,
                left: e.clientX - this.x
            });

            e.preventDefault();
            e.stopPropagation();
        }
    }

    onMouseUp(e) {        
        if (e.button === 2) {
        }
        else {
            if (this.state.isDragging) {
                this.setState({
                    top: e.clientY - this.y,
                    isDragging: false,
                    left: e.clientX - this.x
                });

                this.unsubscribeEvents();
                this.updateStateDragging(false);
            }
        }
    }

    // onDragStart = (e) => {
    //     var elm = document.elementFromPoint(e.clientX, e.clientY);

    //     if (!this.isDragDisabled(elm)) {
    //         const nodeStyle = this.node.props.style;
    //         // mouse position in a draggable element

    //     }
    // }

    // onDragEnd = (e) => {
    //     if(this.state.isDragging)
    //     {
    //         this.setState({
    //             top: e.clientY -  this.y,
    //             isDragging: false,
    //             left: e.clientX -  this.x
    //         });

    //         e.preventDefault();
    //     }
    // }

    updateStateDragging(isDragging) {
        this.setState({ isDragging: isDragging });
    }

    render() {
        const openDialogClass = this.props.open ? "dialog-open" : "";
        return (
            <Modal  className={`${this.props.className} ${openDialogClass} modal-dragable disableMiniListKeyDown disableDefaultSymbolInput`}
                    show={this.props.open}
                    ref={(node) => { this.node = node }}
                    onMouseDown={this.onMouseDown}
                    // onMouseUp={this.onMouseUp}
                    // onDragStart={this.onDragStart}
                    // onDragEnd={this.onDragEnd}
                    style={{
                        left: !StringUtil.isEmpty(this.state.left) ? this.state.left : '50%', 
                        top: !StringUtil.isEmpty(this.state.top) ? this.state.top : '50%',
                        transform: (!StringUtil.isEmpty(this.state.left) || !StringUtil.isEmpty(this.state.top)) ? '' : 'translate(-50%, -50%)',
                        height: this.props.height ? this.props.height : 'auto',
                        width: this.props.width ? this.props.width : 'auto'
                        //, overflow: 'hidden'
                    }}
                    dialogClassName={this.props.dialogClassName}
            >
                {this.props.children}
            </Modal>
        )
    }
}