import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetDefaultSymbolRequest extends BaseServiceRequest {
    constructor(entId) {
        super();
        super.addRequestUri("defaultsymbol.pb");
        super.addRequestParameter("eid", entId.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "defaultSymbolResponse";
    }
}
export default GetDefaultSymbolRequest;