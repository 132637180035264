import BarVisual from "BarVisual";
import BlockType from "BlockType";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import LabelMenu from 'LabelMenu';
import LayoutFactor from "LayoutFactor";
import LineVisual from "LineVisual";
import Pointer from "Pointer";
import RayChart from "RayChart";
import ThemeHelper from "ThemeHelper";
import { updateChartRefs } from "../../../../../Actions/PricePanelActions";
import { CopyRightTranslateHelper, ScaleLabelTranslateHelper, TranslateHelper } from "../../../../../Utils/TranslateHelper";
import { onOpenCollapse, updateDimension, updatesShowNQBox, updateVolumeOpenInterest, volumeScaleChange } from '../../../../../Actions/VolumeChartAction';
import React, { useEffect, useRef } from "react";



const VolumeChart = (props) => {
    const barVisual = useRef();
    const mainChart = useRef();

    useEffect(() => {
        props.updateChartRefs(mainChart, BlockType.Volume)
    }, []);
    const lColor = ThemeHelper.getThemedBrush("buttonLabel");
    const rColor = ThemeHelper.getThemedBrush("buttonLabelGray");
    const height = props.Height !== 0 ? props.Height : 76;
    const scaleLabel = ScaleLabelTranslateHelper[props.scaleText];
    const backgroundColor = ThemeHelper.getThemedBrush("E1E20FEFEF0");
    const dt = new Date();
    const copyRight = `\xA9 ${dt.getFullYear()} WILLIAM O'NEIL + CO. INC.`;
    const copyColor = ThemeHelper.getThemedBrush("fff000");

    let quoteMessage1 = "";
    let quoteMessage2 = "";

    if (props.isTrade) {
        if (props.IsNASDAQBasic) {
            if (props.notDelayed) {
                if (props.IsNASDAQBasic) {
                    quoteMessage1 = CopyRightTranslateHelper.REAL_TIME_PRICES;
                    quoteMessage2 = CopyRightTranslateHelper.REAL_TIME_QUOTES;
                }
            }
            else { quoteMessage1 = CopyRightTranslateHelper.DELAYED_20_MIN; }
        }
        else { quoteMessage1 = CopyRightTranslateHelper.DELAYED_20_MIN; }
    }
    const vmaColor = props.videoMode ? ThemeHelper.getThemedBrush("PFF0000670000") : "#868686";
    const LastVolume = props.lastNodeVolume.toString();
    const VolChg = props.volumePercentChangeStr.toString();

    /* for future group */
    const lineColor = ThemeHelper.getThemedBrush(props.lineColor);

    return (
        <div className="symbol-chart-stack symbol-volume-stack" key="volumechart"
            style={{ flex: LayoutFactor["Volume"] }}>
            <div key='0' className="chartWrapper symbol-chart-wrap" id="volumeChart">
                {props.showNQBox && <div style={
                    {
                        position: "absolute",
                        height: '20px',
                        width: 'Auto',
                        background: '#FFFFDD',
                        top: (height - 36) + 'px',
                        font: 'calibri',
                        fontSize: '10px',
                        fontWeight: 'Bold',
                        left: '300px', zIndex: '10',
                        color: 'black'
                    }}>
                    <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '3px' }}>{quoteMessage1}
                    </div>
                </div>
                }
                <RayChart ref={mainChart}
                    scale={props.scale}
                    height={height}
                    width={props.Width}
                    nodeWidth={props.nodeWidth}
                    leftScaleWidth={props.leftScaleWidth}
                    TimeLine={props.TimeLine}
                    isIntraday={props.isIntraday}
                    righscaleWidth={props.rightScaleWidth}
                    onChartResize={props.updateDimension}
                    showHorizontalGrid={true}
                    showVerticalGrid={true}
                    showLabels={props.videoMode === false}
                    trackpriceReqArgs={{ chartType: BlockType.Volume, barVisual }}
                >
                    <LineVisual showPointer={props.showPointer} DataSource={props.volMALineData} nodeWidth={props.nodeWidth} LineColor={vmaColor} LineThickness={1}
                        Draggable={false} />
                    <BarVisual showPointer={props.showPointer} showBox={props.showPointer} nodeWidth={props.nodeWidth} ref={barVisual} DataSource={props.volumeData} />
                    {!props.isIntraday && <Pointer hasBox={props.showPointer}
                        height={20}
                        width={115}
                        fill={ThemeHelper.getThemedBrush("pointerColor")}
                        lcolor={lColor}
                        rcolor={rColor}
                        contentL={props.lastNodeVolume}
                        contentR={props.pointerRightContent}
                        content={props.lastNodeVolume}
                        y={props.pointerYValue}
                        yValue={props.pointerYValue}
                        boxL={props.volumePercentChangeStr}
                        boxR={props.pointerRightBox}
                        numL={props.volumePercentChange} />}
                    {
                        props.isInterestVisible ?
                            <LineVisual id="test123" DataSource={props.OpenInterestLineData} LineColor={lineColor} LineThickness={props.lineThickness}
                                Draggable={false} />
                            : ""
                    }
                </RayChart>

                {props.SymbolType === "CEF" || props.SymbolType === "INDEX" || props.SymbolType === "FUTURES" ?
                    <div className="chart-panel-left-content">
                        {props.videoMode === false &&
                            <Button bsSize="xsmall" onClick={props.collapseBlock} data-disable-track-price="true" className="collapse-block"
                                style={{
                                    width: "50%",
                                    position: "absolute",
                                    left: "10px",
                                    top: "5px"
                                }}>
                                <div className="collapse-btn"
                                    style={{
                                        position: "absolute",
                                        right: "1px",
                                        bottom: "1px"
                                    }}>
                                </div>
                            </Button>
                        }
                    </div>
                    :
                    <>
                        {props.videoMode === false &&
                            <Button key='3' bsSize="xsmall" onClick={props.collapseBlock} data-disable-track-price="true" className="collapse-block">
                                <div className="collapse-btn"></div>
                            </Button>
                        }
                    </>
                }

                {props.SymbolType === "FUTURES" ?
                    <>
                        <div className="chart-panel-right-content">
                            <LabelMenu label='VOLUME' priceMenu={props.priceMenu} onClick={props.updateVolumeOpenInterest} />
                            <div className="log-n-lin" id='volumescale' key='2' data-disable-track-price="true" onClick={props.volumeScaleChange}>{scaleLabel}</div>
                        </div>
                    </>
                    :
                    <>
                        <div className="chart-panel-right-content">
                            {props.videoMode === false &&
                                <>
                                    <div className="symbol-chart-title">
                                        <span className="text">{TranslateHelper.VOLUME}</span>
                                    </div>
                                </>
                            }
                            {props.videoMode === false &&
                                <div className="log-n-lin" id="volumescale"
                                    key="2" data-disable-track-price="true"
                                    onClick={props.volumeScaleChange}
                                    style={{ cursor: 'pointer', pointerEvents: 'all' }}>{scaleLabel}</div>
                            }
                            {(props.videoMode === false && props.isIntraday && props.lastNodeVolume !== 0) &&
                                <div className="daily-intraday-volume">
                                    <div className="title">DAILY</div>
                                    <div className="title-data">
                                        <span className="daily-volume" id='volume'>{LastVolume}</span>
                                        <span className="daily-volume-percent" id='volumepct' style={{ color: props.volumePercentChange > 0 ? props.posDTColor : props.negDTColor }}>{VolChg}</span>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }

                {/*<LabelMenu label='VOLUME'
                priceMenu={props.priceMenu}
                onClick={(e, item) => onBlockMenuItemClicked(e, item)} />*/}
                {props.isTrade && props.videoMode === false &&
                    <div className="copyrighting voluming" style={{ background: backgroundColor, color: copyColor, top: `${height - 36}px` }}>
                        <div className="text" data-disable-track-price="true" onMouseDown={props.updatesShowNQBox} >
                            <p>{quoteMessage1}</p>
                            <p>{quoteMessage2}</p>
                        </div>
                    </div>
                }
                {props.videoMode === false && <div className="copyrights">{copyRight}</div>}
                {props.videoMode === true && <div className="prices-date for-end-date" style={{ color: copyColor }} id="endDateLabel">{props.endDateLabel}</div>}
            </div>
        </div>

    );
}

const mapStateToProps = ({ DatagraphReducers, appColor }) => {
    const { scaleText, lastNodeVolume, OpenInterestLineData, volumePercentChange, pointerRightBox, volumePercentChangeStr, pointerYValue,
        pointerRightContent, volumeData, volMALineData, showNQBox, isTrade, showPointer, notDelayed, Height, Width, scale, priceMenu, isInterestVisible, lineColor,
        lineThickness } = DatagraphReducers.volumeChartReducer;

    const { isIntraday, nodeWidth, rightScaleWidth, leftScaleWidth, videoMode, IsNASDAQBasic, SymbolType } = DatagraphReducers.DataGraphReducer;
    const { endDateLabel } = DatagraphReducers.PriceChartReducer;
    const TimeLine = DatagraphReducers.TimeLineReducer.TimeLineData.dates;
    const { posDTColor, negDTColor, isThemeChanged } = appColor;
    return {
        isIntraday, scaleText, endDateLabel, lastNodeVolume, SymbolType, OpenInterestLineData, volumePercentChange, pointerRightBox, volumePercentChangeStr, pointerYValue,
        pointerRightContent, volumeData, volMALineData, nodeWidth, rightScaleWidth, leftScaleWidth, Width, scale, videoMode, showNQBox, isTrade, showPointer, notDelayed,
        Height, IsNASDAQBasic, TimeLine, priceMenu, posDTColor, negDTColor, isInterestVisible, lineColor, lineThickness, isThemeChanged
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateChartRefs: (chart, chartType) => dispatch(updateChartRefs(chart, chartType)),
    updateDimension: (height, width) => dispatch(updateDimension(height, width)),
    collapseBlock: () => dispatch(onOpenCollapse(BlockType.Volume, false)),
    volumeScaleChange: (scaleText) => dispatch(volumeScaleChange(scaleText)),
    updatesShowNQBox: () => dispatch(updatesShowNQBox()),
    updateVolumeOpenInterest: (item) => dispatch(updateVolumeOpenInterest(item))
})
export default connect(mapStateToProps, mapDispatchToProps)(VolumeChart)