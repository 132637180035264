import handleActions from 'type-to-reducer';
import { combineReducers } from 'redux';
import {
  SWITCH_LiST,
  SET_SOURCE_LIST,
  SET_RENAME_NODE,
  SET_ACTIVE_NODE,
  SET_SELECTED_NODE,
  SET_DUPLICATE_NODE,
  SET_FOLDER_ACTIVE_NODE,
  SET_DRAG_NODE,
  SET_UNDO_DELETE_NODE,
  SET_SELECTED_NODE_DETAILS,
  SET_DUPLICATE_ALERT,
  SET_IS_ACTIVE_NODE_CHANGED,
  SET_DELETE_FOLDER,
  IS_UPDATED_OWNERSHIP
} from '../Actions/ListViewActions';

const initialActiveFolder = '';

const activeFolderID = handleActions({
  [SET_FOLDER_ACTIVE_NODE]: (state, action) => action.payload
}, initialActiveFolder);

const selectednodeinitialState = '';

const selectedNode = handleActions({
  [SET_SELECTED_NODE]: (state, action) => action.payload
}, selectednodeinitialState);

const switchListInitState = false;

const switchList = handleActions({
  [SWITCH_LiST]: (state, action) => action.payload
}, switchListInitState)

const isUpdatedOwnershipInitState = false;

const isUpdatedOwnership = handleActions({
  [IS_UPDATED_OWNERSHIP]: (state, action) => action.payload
}, isUpdatedOwnershipInitState)

const isFolderDeleteInitState = false;

const isFolderDelete = handleActions({
  [SET_DELETE_FOLDER]: (state, action) => action.payload
}, isFolderDeleteInitState)

const listInitialState = {
  list: []
}

const dataSource = handleActions({
  [SET_SOURCE_LIST]: (state, action) => ({
    ...listInitialState,
    list: action.payload
  })
}, listInitialState);

const initialDragNode = '';

const dragNodeID = handleActions({
  [SET_DRAG_NODE]: (state, action) => action.payload
}, initialDragNode);

const nodeToRenameInitialState = '';

const nodeToRename = handleActions({
  [SET_RENAME_NODE]: (state, action) => action.payload
}, nodeToRenameInitialState);

const activeNodeInitialState = 820712;

const activeNode = handleActions({
  [SET_ACTIVE_NODE]: (state, action) => action.payload
}, activeNodeInitialState);

const activeNodeChangedInitialState = false;

const activeNodeChanged = handleActions({
  [SET_IS_ACTIVE_NODE_CHANGED]: (state, action) => action.payload
}, activeNodeChangedInitialState);

const duplicateNodeIntState = '';

const duplicateNode = handleActions({
  [SET_DUPLICATE_NODE]: (state, action) => action.payload
}, duplicateNodeIntState);

const  alertMessageVisible= false;

const undoDeletedNode = handleActions({
  [SET_UNDO_DELETE_NODE]: (state, action) => action.payload 
}, alertMessageVisible);

const duplicateAlert = handleActions({
  [SET_DUPLICATE_ALERT]: (state, action) => action.payload 
}, alertMessageVisible);

const initialSelectedNode='';
 

const setSelectedNodeDetails=handleActions({
  [SET_SELECTED_NODE_DETAILS]: (state,action) => action.payload

},initialSelectedNode);

const listView = combineReducers({
  switchList,
  dataSource,
  nodeToRename,
  activeNode,
  selectedNode,
  duplicateNode,
  activeFolderID,
  dragNodeID,
  undoDeletedNode,
  setSelectedNodeDetails,
  duplicateAlert,
  activeNodeChanged,
  isFolderDelete,
  isUpdatedOwnership
})


export default listView
