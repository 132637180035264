import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class LabelText extends PureComponent {
    render() {
        const { textValue, textPosX, textPosY, textAnchor, dx, dy, isHighlighted } = this.props;
        return (
            <text textAnchor={textAnchor ? textAnchor : "start"} fillOpacity={isHighlighted ? "1" : "0.3"} className="label-text comp" dx={dx} dy={dy} x={textPosX} y={textPosY}>{textValue}</text>
        );
    }
}

LabelText.propTypes = {
    textValue: PropTypes.string.isRequired,
    textPosX: PropTypes.number.isRequired,
    textPosY: PropTypes.number.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    textAnchor: PropTypes.string
}

export default LabelText;