import AlertActions from './AlertActions';
import AlertStore from '../Stores/Alerts/AlertStore';
import { AnnotationConstant } from "../Constants/AnnotationConstants";
import DatagraphHelper from "../Utils/DatagraphHelper";
const { ActionTypes } = AnnotationConstant;
import { dispatch } from "../Redux/dispatch.js";
import SettingsStore from "SettingsStore";

export const initAnnotationModel = (invokeLayerApi = false, symbol = '', endDate, beginDate) => ({
    type: ActionTypes.INIT_ANNOTATION_MODEL,
    invokeLayerApi, symbol, endDate, beginDate
});

export const processAnnotationLayerData = (layerData) => ({
    type: ActionTypes.PROCESS_ANNOTATION_LAYER_DATA,
    layerData
})

export const processAnnotationData = (annotations) => ({
    type: ActionTypes.PROCESS_ANNOTATION_DATA,
    annotations
});

export const clearAnnotations = () => ({
    type: ActionTypes.CLEAR_ANNOTATIONS
});

export const onDimensionsChange = (dimension) => ({
    type: ActionTypes.ON_ANNOTATION_DIMENSION_CHANGE,
    dimension
});

export const reDrawAnnotationsOnChartResize = () => ({
    type: ActionTypes.REDRAW_ANNOTATIONS_ON_CHART_RESIZE
});

export const onFooterAction = (action) => ({
    type: ActionTypes.ON_FOOTER_ACTION_TRIGGERED,
    action
});

export const relocateLayer = (dragId, dropId) => ({
    type: ActionTypes.RELOCATE_LAYER,
    dragId,
    dropId
});

export const toggleVisibility = (layer, isCtrl = false) => ({
    type: ActionTypes.TOGGLE_VISIBILITY,
    layer,
    isCtrl
});

export const handleClick = (e) => ({
    type: ActionTypes.HANDLE_CLICK,
    e
});

export const handleMouseMove = (e) => ({
    type: ActionTypes.HANDLE_MOUSE_MOVE,
    e
});

export const handleDragStart = (draggedItem) => ({
    type: ActionTypes.UPDATE_DRAG_STATUS,
    isDragging: true,
    draggedItem
});

export const handleDragMove = (e, isEndExtend = false, isStartExtend = false, isStartArrow = false, isEndArrow = false, isLabel = false) => ({
    type: ActionTypes.HANDLE_DRAG_MOVE,
    e,
    isEndExtend,
    isStartExtend,
    isStartArrow,
    isEndArrow,
    isLabel
});

export const handleDragEnd = () => {
    dispatch({
        type: ActionTypes.UPDATE_DRAG_STATUS,
        isDragging: false,
        draggedItem: null
    });
    dispatch({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    });
}

export const handleMouseDown = (e) => ({
    type: ActionTypes.HANDLE_MOUSE_DOWN,
    e
});

/* Annotation RI Panel related actions */

export const onAnnotationToolChange = (selectedToolType, isCtrl = false, riPanelClick = false) => ({
    type: ActionTypes.HANDLE_TOOL_CHANGE,
    selectedToolType,
    isCtrl,
    riPanelClick
});

export const updateRiPanelInfoFromUserSettings = (isCtrl = false) => {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const annotationSettings = DatagraphHelper.getSettingsObject(consoleSettings, consoleSettings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;
    return {
        type: ActionTypes.UPDATE_STATE_FROM_SETTINGS,
        selectedToolType: annotationSettings.selectedToolType,
        LineSettings: annotationSettings.LineSettings,
        TextSettings: annotationSettings.TextSettings,
        ShapeSettings: annotationSettings.ShapeSettings,
        ChannelLineSettings: annotationSettings.ChannelLineSettings,
        FibonacciSettings: annotationSettings.FibonacciSettings,
        MeasurementSettings: annotationSettings.MeasurementSettings,
        isCtrl
    }
}

export const getAnnotationLayers = () => ({
    type: ActionTypes.GET_ANNOTATION_LAYERS
})

export const handleReWrite = (e, isSave) => ({
    type: ActionTypes.HANDLE_TEXT_MODIFIED,
    e,
    isSave
});

export const addAlert = (e) => ({
    type: ActionTypes.ADD_ALERT,
    e
});

export const handleColorChange = (action, color) => ({
    type: action,
    color
});

export const handleWeightChange = (action, weight) => ({
    type: action,
    weight: weight
});

export const handleStrokeChange = (action, stroke, strokeStyle) => ({
    type: action,
    stroke,
    strokeStyle
});

export const onStartArrowChange = (startArrowChecked, lineX1, startPolygonPoints) => ({
    type: ActionTypes.HANDLE_START_ARROW_CHANGE,
    startArrowChecked: startArrowChecked,
    lineX1: lineX1,
    startPolygonPoints: startPolygonPoints
});

export const onEndArrowChange = (endArrowChecked, lineX2, endPolygonPoints) => ({
    type: ActionTypes.HANDLE_END_ARROW_CHANGE,
    endArrowChecked: endArrowChecked,
    lineX2: lineX2,
    endPolygonPoints: endPolygonPoints
});

export const onStartExtendChange = (startExtendChecked, lineX1, startPolygonPoints, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1) => ({
    type: ActionTypes.HANDLE_START_EXTEND_CHANGE,
    startExtendChecked: startExtendChecked,
    lineX1: lineX1,
    startPolygonPoints: startPolygonPoints,
    upperLineX1: upperLineX1,
    lowerLineX1: lowerLineX1,
    upperRetracementLineX1: upperRetracementLineX1,
    lowerRetracementLineX1: lowerRetracementLineX1
});

export const onEndExtendChange = (endExtendChecked, lineX2, endPolygonPoints, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2) => ({
    type: ActionTypes.HANDLE_END_EXTEND_CHANGE,
    endExtendChecked: endExtendChecked,
    lineX2: lineX2,
    endPolygonPoints: endPolygonPoints,
    upperLineX2: upperLineX2,
    lowerLineX2: lowerLineX2,
    upperRetracementLineX2: upperRetracementLineX2,
    lowerRetracementLineX2: lowerRetracementLineX2
});

export const onCheckBoxChange = (event, property, newState, selectedAnnotation) => ({
    type: ActionTypes.HANDLE_CHECKBOX_CHANGE,
    event,
    property,
    newState,
    selectedAnnotation
});

export const onOpacityChange = (value, shouldSave) => ({
    type: ActionTypes.HANDLE_OPACITY_CHANGE,
    value,
    shouldSave
});

export const handleToggleContextMenu = (showContext, clientX, clientY, shape) => ({
    type: ActionTypes.HANDLE_ANNOTATION_CONTEXT_MENU_TOGGLE,
    showContext, clientX, clientY, shape
});

export const updateSelectedAnnotationId = (id) => ({
    type: ActionTypes.UPDATE_SELECTED_ANNOTATIONS_ADD,
    id
});

export const handleDeleteAnnotationDrawing = (charCode = null, isCtrlRemove = false) => ({
    type: ActionTypes.REMOVE_DRAWING_ANNOTATION,
    charCode,
    isCtrlRemove
});

export const handleDeleteAllAnnotation = (isRiPanelDelete) => ({
    type: ActionTypes.REMOVE_ALL_ANNOTATION,
    isRiPanelDelete: isRiPanelDelete
});

export const handleConfirmDeleteAllAnnotation = () => ({
    type: ActionTypes.REMOVE_ALL_ANNOTATION_CONFIRM
});

export const handleLayerDeleteConfirmPopup = (show) => ({
    type: ActionTypes.HANDLE_TOGGLE_LAYER_DELETE_POPUP,
    showDialog: show
})

export const onAnnotationShapeCHange = (shape) => ({
    type: ActionTypes.HANDLE_SHAPE_CHANGE,
    shape: shape
});

export const handleTextEffectsChange = (event, value) => ({
    type: ActionTypes.HANDLE_TEXT_EFFECTS_CHANGE,
    event: event,
    value: value
});

export const handleCtrlClick = (e) => ({
    type: ActionTypes.HANDLE_CONTROL_CLICK,
    e
});

export const handleDeleteConfirmPopup = (show) => ({
    type: ActionTypes.HANDLE_TOGGLE_DELETE_POPUP,
    showDialog: show
})

export const handleAddLineAlert = () => ({
    type: ActionTypes.ADD_TRENDLINE_ALERT
})

export const handleRemoveLineAlert = () => ({
    type: ActionTypes.REMOVE_TRENDLINE_ALERT
})

/* Anchor events begin */
export const handleAnchorDragStart = (anchor) => ({
    type: ActionTypes.HANDLE_SAVE_CLICKED_ANCHOR_POSITION,
    activeAnchor: anchor
});

export const handleAnchorDragMove = (e, action) => ({
    type: action,
    e,
    isEndExtend: false,
    isStartArrow: false,
    isEndArrow: false,
    reComputeOnSelection: false,
    isAnchorDrag: true
});

export const handleAnchorDragEnd = () => {
    dispatch({
        type: ActionTypes.HANDLE_SAVE_CLICKED_ANCHOR_POSITION,
        activeAnchor: ""
    });
    dispatch({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    });
}
/* Anchor events end */

export const setActiveLayer = (layer) => ({
    type: ActionTypes.SET_ACTIVE_LAYER,
    layer
});

export const onLayerItemRenamed = (newName) => ({
    type: ActionTypes.ON_LAYER_ITEM_RENAMED,
    newName
});

export const handleAlertHighlight = (annotId) => {
    return {
        type: ActionTypes.HANDLE_TL_ALERT_HOVER_TOGGLE,
        isHovered: true,
        annotId: annotId
    };
}

export const handleRemoveAlertHighlight = () => {
    return {
        type: ActionTypes.HANDLE_TL_ALERT_HOVER_TOGGLE,
        isHovered: false,
        annotId: 0
    };
};

export const undoDeleteAnnotation = () => ({
    type: ActionTypes.UNDO_DELETED_ANNOTATIONS
})

export const dismissAlert = () => ({
    type: ActionTypes.SHOW_UNDO_ALERT,
    showAlert: false,
    deletedId: '',
    alertMsg: ''
})

export const moveFront = () => ({
    type: ActionTypes.MOVE_ANNOTATIONS_TO_FRONT
})

export const moveBack = () => ({
    type: ActionTypes.MOVE_ANNOTATIONS_TO_BACK
})

export const handleTextAnchorUp = () => ({
    type: ActionTypes.HANDLE_TEXT_ANCHOR_LEAVE
})

export const handleTextAnchorDrag = (e) => ({
    type: ActionTypes.HANDLE_TEXT_ANCHOR_DRAG,
    e
})

export const handleRectAnchorUp = () => ({
    type: ActionTypes.HANDLE_RECT_ANCHOR_LEAVE
})

export const handleRectAnchorDrag = (e) => ({
    type: ActionTypes.HANDLE_RECT_ANCHOR_DRAG,
    e
})

export const handleEllipAnchorUp = () => ({
    type: ActionTypes.HANDLE_ELLIP_ANCHOR_LEAVE
})

export const handleEllipAnchorDrag = (e) => ({
    type: ActionTypes.HANDLE_ELLIP_ANCHOR_DRAG,
    e
})

export const handleTriAnchorUp = () => ({
    type: ActionTypes.HANDLE_TRI_ANCHOR_LEAVE
})

export const handleTriAnchorDrag = (e) => ({
    type: ActionTypes.HANDLE_TRI_ANCHOR_DRAG,
    e
})

export const handleDoubleClick = (e) => ({
    type: ActionTypes.HANDLE_TEXT_DOUBLE_CLICK,
    e
})

export const updateTargetAnnotation = (action, data, computationAction = null, shouldSave = true) => {
    if (computationAction) {
        dispatch({
            type: computationAction,
            reComputeOnSelection: true
        });
    }
    else if (action) {
        dispatch({
            type: ActionTypes.UPDATE_TARGET_ANNOTATION,
            action,
            data
        });
    }

    { /* Some of the re-computation tasks have internal save api call. shouldSave variable will define whether to call for api or not */ }
    if (shouldSave) {
        dispatch({
            type: ActionTypes.HANDLE_SAVE_ANNOTATION
        });
    }
};

export const handleLayerVisibilityConfirmPopup = (show) => ({
    type: ActionTypes.HANDLE_TOGGLE_LAYER_VISIBILTY_POPUP,
    showDialog: show
})

export const handleReadOnlyLayerConfirmPopup = (show) => ({
    type: ActionTypes.HANDLE_TOGGLE_LAYER_READONLY_POPUP,
    showDialog: show
})

export const updateTextAnnotation = (action, data) => (
    dispatch({
        type: ActionTypes.UPDATE_TARGET_TEXT_ANNOTATION,
        target: action,
        data: data
    }),
    dispatch({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    }),
    dispatch({
        type: ActionTypes.UPDATE_TEXT_HEIGHT
    })
);

export const removeLineAlertIcon = (annotId) => ({
    type: ActionTypes.REMOVE_LINE_ALERT_ICON,
    annotId: annotId
});

export const handleEscClick = () => ({
    type: ActionTypes.HANDLE_ESC_AFTER_EDIT
});

export const clearSelectedAnnotation = () => ({
    type: ActionTypes.CLEAR_SELECTED_ANNOTATIONS
});

export const toggleAlertIconToggle = (annotId, status) => ({
    type: ActionTypes.TOGGLE_LINE_ALERT_ICON,
    annotId,
    status
});

export const updateRiPanelInfo = (attrs, isUpdateSelectedAnnotation = false, isUpdateRiPanelSettingsView = true) => ({
    type: ActionTypes.UPDATE_RI_PANEL_STATUS,
    attrs, isUpdateSelectedAnnotation, isUpdateRiPanelSettingsView
});

export const handleTextClick = () => ({
    type: ActionTypes.HANDLE_TEXT_CLICK
});

export const updateTempToolSelect = (selectedToolType) => ({
    type: ActionTypes.UPDATE_TEMP_TOOL_SELECTION,
    tempTool: selectedToolType
});

export const updateStageClickCapturedFlag = (isStageClickCaptured = false) => ({
    type: ActionTypes.UPDATE_STAGE_CLICK_CAPTURED_FLAG,
    isStageClickCaptured
});

export const toggleDgRiPanel = () => ({
    type: ActionTypes.TOGGLE_DG_RI_PANEL
});
