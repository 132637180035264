import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class AnnotationUpsertRequest extends BaseServiceRequest {
    constructor(annotation) {
        super();
        super.addRequestUri("annotation.pb");
        super.setContent(annotation.encode());
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "AnnotationUpsertResponse";
    }

}
