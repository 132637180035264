
import StringUtil from "StringUtil";

class BaseServiceRequest {
  constructor() {
    this._requestCommandParams = [];
    this._postParamValuesDict = [];
    this._requestURI = "";
    this._requestContent;
  }
  getMethod() {
    return undefined;
  }

  getSubSystem() {
    return undefined;
  }

  getRequestUri() {
    return this.getUri();
  }

  getMessageName() {
    return undefined;
  }

  getRequireSecured() {
    return False;
  }

  addRequestParameter(paramName, value) {
    if (paramName) {
      this._postParamValuesDict.push({ key: paramName, value });
    }
  }

  addRequestUri(Uri) {
    this._requestURI = Uri;
  }

  addPathParameter(value) {
    this._requestCommandParams.push(this.htmlEnc(value));
  }

  getUri() {
    let retValue = "";
    const reqParams = this._requestCommandParams.join("/");

    if (reqParams) {
      retValue = StringUtil.format("{0}/{1}", [reqParams, this._requestURI]);
    }
    else {
      retValue = this._requestURI;
    }
    return retValue;
  }

  getParameterString() {
    const parameters = [];
    parameters.push(StringUtil.format("timestamp={0}", [new Date().getTime()]));
    for (let i = 0; i < this._postParamValuesDict.length; i++) {
      parameters.push(StringUtil.format("{0}={1}", [this._postParamValuesDict[i].key, StringUtil.stripHTML(this._postParamValuesDict[i].value)]));
    }
    return parameters.join("&");
  }

  getContent() {
    return this._requestContent;
  }

  setContent(reqData) {
    this._requestContent = reqData;
  }

  htmlEnc(s) {
    return s.replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&#39;')
      .replace(/"/g, '&#34;');
  }
}

export default BaseServiceRequest;
