import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import DatagraphStore from '../../Stores/NavModules/NavDataGraph/DataGraphStore';
import { setIsCurrencyConversionChecked } from '../../Actions/CurrencyActions';
import React, { Component } from 'react';

class CurrencyButton extends Component {
    constructor(props) {
        super(props);
        this.changeCurrency = this.changeCurrency.bind(this);
    }

    changeCurrency() {
        const { isCurrencyConversionChecked } = this.props.Currency;
        const isShowSelectedCurrency = DatagraphStore.getIsShowSelectedCurrency();
        if (isShowSelectedCurrency) {
            this.props.setIsCurrencyConversionChecked(!isCurrencyConversionChecked, this.props.Symbol, this.props.nodeCount, true);
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps && this.props && this.props.Currency.isMatchFinancials !== nextProps.Currency.isMatchFinancials && this.props.Currency.isCurrencyConversionChecked){
            return false;  
        }
        return true;
    }
    render() {
        const { selectedCurrencyCode, defaultCurrency, defaultCurrencySign, defaultWSCurrency, isCurrencyConversionChecked } = this.props.Currency;
        const isShowSelectedCurrency = DatagraphStore.getIsShowSelectedCurrency();
        const displayCurrency = isShowSelectedCurrency && isCurrencyConversionChecked ? `→ ${selectedCurrencyCode}`
            : `${defaultWSCurrency ? defaultWSCurrency : ''} ${defaultCurrencySign} ${defaultCurrency}`;
        return (
            <Button
                active={isShowSelectedCurrency && isCurrencyConversionChecked}
                bsSize="small"
                className="currency-button small-normal"
                onClick={this.changeCurrency}>{displayCurrency}
            </Button>
        );
    }
}

function mapStateToProps({ DatagraphReducers, currency }) {
    const { nodeCount, Symbol } = DatagraphReducers.DataGraphReducer;
    return { Currency: currency, nodeCount, Symbol }
}

const mapDispatchToProps= (dispatch)=> ({
    setIsCurrencyConversionChecked:(isCurrencyConversionChecked, symbol, nodeCount, isSymbolChange)=> dispatch(setIsCurrencyConversionChecked(isCurrencyConversionChecked, symbol, nodeCount, isSymbolChange))
})

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyButton);
