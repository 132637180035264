import { connect } from "react-redux";
import { DialogHOC } from "./DialogHOC";
import { cancelEPSAnimationDialog, changeEPSAnimationDialogProperty, changeFundamentalLineDialogTab, restoreEPSAnimationLineSetting, saveEPSAnimationDialog } from "../../../../../../../Actions/PricePanelActions";
import { Modal, Tabs, Tab } from "react-bootstrap";
import React, { Component } from 'react'

class EPSAnimationDialog extends Component {
    getLookBackRender(tabitem) {
        if (tabitem.periodicity === "Daily") {
            return (<div className="radio-li">
                        <span className="padding-h">
                            <input type="radio" name="animationlookbackDaily" value="1 month" id="1 month"
                                checked={tabitem.AnimationLookbackSpan === "1 month"}
                                onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationLookbackSpan", "1 month")} />
                            <label htmlFor="1 month">1 month</label>
                        </span>
                        <span className="padding-h">
                            <input type="radio" name="animationlookbackDaily" value="6 months" id="6 months"
                                checked={tabitem.AnimationLookbackSpan === "6 months"}
                                onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationLookbackSpan", "6 months")} />
                            <label htmlFor="6 months">6 months</label>
                        </span>
                        <span className="padding-h">
                        <input type="radio" name="animationlookbackDaily" value="Max" id="Max"
                            checked={tabitem.AnimationLookbackSpan === "Max"}
                            onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationLookbackSpan", "Max")} />
                        <label htmlFor="Max">Max</label>
                        </span>                    
                </div>);
        } else {
            return (<div className="radio-li padding-h">
                <span className="padding-h">
                    <input type="radio" name={`animationlookback${tabitem.periodicity}`} value="1 year" id={`${tabitem.periodicity}1 years`}
                        checked={tabitem.AnimationLookbackSpan === "1 year"}
                        onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationLookbackSpan", "1 year")} />
                    <label htmlFor={`${tabitem.periodicity}1 years`}>1 year</label>
                </span>
                <span className="padding-h">
                    <input type="radio" name={`animationlookback${tabitem.periodicity}`} value="3 years" id={`${tabitem.periodicity}3 years`}
                        checked={tabitem.AnimationLookbackSpan === "3 years"}
                        onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationLookbackSpan", "3 years")} />
                    <label htmlFor={`${tabitem.periodicity}3 years`}>3 years</label>
                </span>
                <span className="padding-h">
                    <input type="radio" name={`animationlookback${tabitem.periodicity}`} value="Max" id={`${tabitem.periodicity}Max`}
                        checked={tabitem.AnimationLookbackSpan === "Max"}
                        onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationLookbackSpan", "Max")} />
                    <label htmlFor={`${tabitem.periodicity}Max`}>Max</label>
                </span>
            </div>);
        }

    }

    getTabItemRender(tabitem) {
        return (
            <Tab className="tabItem" key={tabitem.periodicity} eventKey={tabitem.periodicity} title={tabitem.header}>
                <div className="oneil-rate-check">
                    <div className="data-info">
                        <div className="inner-picker sub-title2">
                            <input type="checkbox" name="chart-loads" id="chart-loads" checked={tabitem.IsAnimateWithChartLoad} onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "IsAnimateWithChartLoad", !tabitem.IsAnimateWithChartLoad)} />
                            <label htmlFor="chart-loads">Animate when chart loads</label>
                        </div>
                        <div className="inner-picker sub-title2">
                            <input type="checkbox" name="enable-loopback" id="enable-loopback" checked={tabitem.IsLoopbackEnabled} onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "IsLoopbackEnabled", !tabitem.IsLoopbackEnabled)} />
                            <label htmlFor="enable-loopback">Enable Loopback</label>
                        </div>
                    </div>

                    <div className="data-info">
                        <div className="inner-picker sub-title margin-bottom cursor-default">Default look-back:</div>
                    </div>
                    <ul className="data-info">
                        {this.getLookBackRender(tabitem)}
                    </ul>
                    <div className="bdr-link"></div>
                    <div className="data-info">
                        <div className="inner-picker sub-title cursor-default">Playback Speed:</div>
                    </div>
                    <ul className="data-info">
                        <div className="radio-li">
                            <span className="padding-h">
                                <input type="radio" name={`AnimationSpeed${tabitem.periodicity}`} value="Slow" id={`${tabitem.periodicity}slow`}
                                    checked={tabitem.AnimationSpeedEnum === "Slow"}
                                    onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationSpeedEnum", "Slow")} />
                                <label htmlFor={`${tabitem.periodicity}slow`}>Slow</label>
                            </span>
                            <span className="padding-h">
                                <input type="radio" name={`AnimationSpeed${tabitem.periodicity}`} value="Medium" id={`${tabitem.periodicity}medium`}
                                    checked={tabitem.AnimationSpeedEnum === "Medium"}
                                    onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationSpeedEnum", "Medium")} />
                                <label htmlFor={`${tabitem.periodicity}medium`}>Medium</label>
                            </span>
                            <span className="padding-h">
                                <input type="radio" name={`AnimationSpeed${tabitem.periodicity}`} value="Fast" id={`${tabitem.periodicity}fast`}
                                    checked={tabitem.AnimationSpeedEnum === "Fast"}
                                    onChange={this.props.changeEPSAnimationDialogProperty.bind(this, "AnimationSpeedEnum", "Fast")} />
                                <label htmlFor={`${tabitem.periodicity}fast`}>Fast</label>
                            </span>
                        </div>
                    </ul>
                    <div className="rating-default animation-restore"><span className="link-restore" onClick={this.props.restoreEPSAnimationSetting}>Restore Defaults</span></div>
                </div >
            </Tab >
        );
    }

    render() {
        return (
                <Modal.Body className="medium-normal">
                    <div className="inner-block dialog-content disable-drag">
                        <div className="oneil-rating-box">
                            <Tabs onSelect={this.props.changeEPSAnimationDialogTab} activeKey={this.props.selectedPeriodicity} id="controlled-tab-example"> {this.props.epsAnimationSettingsData.map((tabitem) => this.getTabItemRender(tabitem))}
                            </Tabs>
                        </div>
                    </div>
                </Modal.Body>
        );
    }
}
const mapStateToProps = ({ DatagraphReducers })=> {
    const { isAnimationDialogOpen, selectedPeriodicity, epsAnimationSettingsData } = DatagraphReducers.FundamentalLineReducer;
    
    return { isAnimationDialogOpen, selectedPeriodicity, epsAnimationSettingsData, showSettingDialog: isAnimationDialogOpen, headerClass: 'eps-animation epsAnimation-dialog',
                HeaderText: 'Earnings Line Animation' }
}

const mapDispatchToProps = (dispatch)=> ({
    saveLineDialog: ()=> dispatch(saveEPSAnimationDialog()),
    cancelLineDialog: ()=> dispatch(cancelEPSAnimationDialog()),
    changeEPSAnimationDialogTab: (selectedPeriodicity)=> dispatch(changeFundamentalLineDialogTab(selectedPeriodicity)),
    changeEPSAnimationDialogProperty: (property, value)=> dispatch(changeEPSAnimationDialogProperty( property, value)),
    restoreEPSAnimationSetting: ()=> dispatch(restoreEPSAnimationLineSetting()),
})
export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(EPSAnimationDialog))
