import _ from "underscore";
import AlertSourceType from "../../../../Constants/AlertSourceType.js";
import AlertStore from 'AlertStore';
import { connect } from "react-redux";
import FilterListDialog from "./FilterListDialog.jsx";
import FlexibleModal from "../../../../RayCustomControls/FlexibleModal.jsx";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore.js";
import { Modal } from "react-bootstrap";
import NotificationContentContainer from "../../../../RayCustomControls/Alerts/NotificationContentContainer.jsx";
import { AlertTranslateHelper, TranslateHelper } from "../../../../Utils/TranslateHelper.js";
import { closeAlertDialog, openCriteriaDialog, saveAlertData } from "../../../../Actions/AlertActions.js";
import React, { Component, Fragment } from "react";

class AndAlertsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAlertCriteria: null,
            existAlerts: this.props.existAlerts,
        }
        this.getXPosition = this.getXPosition.bind(this);
        this.getYPosition = this.getYPosition.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onNotificationPreferenceClick = this.onNotificationPreferenceClick.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.saveAlertData = this.saveAlertData.bind(this);
        this.getCriteriaStr = this.getCriteriaStr.bind(this);
        this.doneBtnClick = this.doneBtnClick.bind(this);
        this.closeAlertCriteriaWindow = this.closeAlertCriteriaWindow.bind(this);
    }
    getXPosition() {
        let x = 0;
        const w = 460;

        if (this.props.x) {
            x = this.props.x - (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2
        }

        if (x < 0) {
            x = 0;
        }

        return x;
    }

    getYPosition() {
        let y = 0;
        const h = 400;

        // if (this.props.sourceTypeId === AlertSourceType.List)
        //     h = 600;

        if (this.props.y) {
            y = this.props.y - (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2
        }

        if (y < 0) {
            y = 0;
        }

        return y;
    }

    closeDialog() {
        this.props.closeAndAlert();
    }

    onNotificationPreferenceClick() {
        this.setState({ isNCOpen: true });
    }
    hideModal() {
        this.setState({ isNCOpen: false });
    }
    saveAlertData() {
        document.getElementById("btnSaveAlert").disabled = true;
        if (this.props.isTriggered === 0) {
            this.props.saveAlertData(this.props.listAlertSettings, 2, this.props.alertId, this.props.priceAlert, this.props.isTriggered);
        } else {
            this.props.saveAlertData(this.props.listAlertSettings, 2, 0, this.props.priceAlert, this.props.isTriggered);
        }
    }

    getCriteriaStr() {

        const criteria = [];
        const str = [];

        if (this.props.listAlertSettings.AdditionSelected) {
            criteria.push({ str1: "Additions", val: "", str2: "" });
        }

        if (this.props.listAlertSettings.RemovalSelected) {
            criteria.push({ str1: "Removals", val: "", str2: "" });
        }

        if (this.props.listAlertSettings.PriceChangeSelected) {
            criteria.push({ str1: "Daily price change exceeds +/- ", val: this.props.listAlertSettings.PriceChangeThreshold, str2: "%" });
        }

        if (this.props.listAlertSettings.DailyVolumeRateSelected) {
            criteria.push({ str1: "Daily volume rate exceeds ", val: this.props.listAlertSettings.DailyVolumeRateThreshold, str2: "%" });
        }

        if (this.props.listAlertSettings.AverageDlyVolSelected) {
            criteria.push({ str1: "Average daily volume crosses ", val: this.props.listAlertSettings.AverageDlyVolThreshold, str2: " (000) shares" });
        }

        if (this.props.listAlertSettings.AverageDlyDlrVolSelected) {
            criteria.push({ str1: "Average daily $ volume, USD crosses $ ", val: this.props.listAlertSettings.AverageDlyDlrVolThreshold, str2: " (000)" });
        }

        if (this.props.listAlertSettings.DailyVolumeExceeds50SMASelected) {
            criteria.push({ str1: "Daily volume exceeds 50 SMA ", val: "", str2: "" });

        }
        if (this.props.listAlertSettings.BreakingOutToAllTimeHighSelected) {
            criteria.push({ str1: "Breaking out to all-time high ", val: "", str2: "" });

        }
        if (this.props.listAlertSettings.RSReachesNewHighSelected) {
            criteria.push({ str1: "Relative Strength Line reaches new 52-week high (based on default index)", val: "", str2: "" });
        }

        if (this.props.listAlertSettings.MovingAvg50SmaSelected) {
            criteria.push({ str1: "Price crosses 50 SMA (daily)", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.MovingAvg200SmaSelected) {
            criteria.push({ str1: "Price crosses 200 SMA (daily)", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.MovingAvg50Cross200Selected) {
            criteria.push({ str1: "50 SMA - 200 SMA crossover (daily)", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.DailyBaseChecked) {
            criteria.push({ str1: "New daily base detected", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.WeeklyBaseChecked) {
            criteria.push({ str1: "New weekly base detected", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.WeeklyTightChecked) {
            criteria.push({ str1: "Tight area detected", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.DailyFailedBaseChecked) {
            criteria.push({ str1: "Failed daily base detected)", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.WeeklyFailedBaseChecked) {
            criteria.push({ str1: "Failed weekly breakout detected", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.DailyPriceRisesSelected) {
            criteria.push({ str1: "Price is within ", val: this.props.listAlertSettings.AlertPriceRises, str2: "% of the daily pivot" });
        }

        if (this.props.listAlertSettings.WeeklyPriceRisesSelected) {
            criteria.push({ str1: "Price is within ", val: this.props.listAlertSettings.AlertPriceRises, str2: "% of the weekly pivot" });
        }

        if (this.props.listAlertSettings.DailyPriceCrossesSelected) {
            criteria.push({ str1: "Breaking out of a daily base", val: "", str2: "" });
        }

        if (this.props.listAlertSettings.WeeklyPriceCrossesSelected) {
            criteria.push({ str1: "Breaking out of a weekly base", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.PullbackTo10WeekLineSelected) {
            criteria.push({ str1: "Pullback to 10-week line", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.NegativeAlter1WeekSelected) {
            criteria.push({ str1: "Negative Alert Score, 1 Week", val: this.props.listAlertSettings.NegativeAlter1Week, str2: " (000)" });
        }
        if (this.props.listAlertSettings.NegativeAlter10WeekSelected) {
            criteria.push({ str1: "Negative Alert Score, 10 Week ", val: this.props.listAlertSettings.NegativeAlter10Week, str2: " (000)" });
        }
        if (this.props.listAlertSettings.BuybackAnnouncementSelected) {
            criteria.push({ str1: "Buyback announcement", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.StockSplitSelected) {
            criteria.push({ str1: " Stock split", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.CeoChangeSelected) {
            criteria.push({ str1: "CEO change", val: "", str2: "" });
        }

        if (this.props.listAlertSettings.EarningsReleaseSelected) {
            criteria.push({ str1: "Earnings release is in ", val: this.props.listAlertSettings.EarningsReleaseDays, str2: " days" });
        }

        if (this.props.listAlertSettings.DividendChangeSelected) {
            criteria.push({ str1: "Dividend change", val: "", str2: "" });
        }
        // if (this.props.listAlertSettings.InsiderPurchasesSelected) {
        //     criteria.push({ str1: "Insider purchase", val: "", str2: "" });
        // }
        if (this.props.listAlertSettings.InsiderTransactionsBuysSelected) {
            criteria.push({ str1: "Insider transactions - Buys", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.InsiderTransactionsSellsSelected) {
            criteria.push({ str1: "Insider transactions - Sells", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.OfferingsSelected) {
            criteria.push({ str1: "Offering", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.AnnouncementsSelected) {
            criteria.push({ str1: "Announcement", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.BreakawayGapSelected) {
            criteria.push({ str1: "Breakaway gap", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.Day50BreakOnVolumeSelected) {
            criteria.push({ str1: "50 day break on volume", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.GapUpOnEarningsSelected) {
            criteria.push({ str1: "Gap up on earnings", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.GapDownOnEarningsSelected) {
            criteria.push({ str1: "Gap down on earnings", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.DailyEnterExitProfitRangeSelected) {
            criteria.push({ str1: "Enter or exit profit range - daily", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.WeeklyEnterExitProfitRangeSelected) {
            criteria.push({ str1: "Enter or exit profit range - weekly", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.DailyEnterExitPivotRangeSelected) {
            criteria.push({ str1: "Enter or exit pivot range - daily", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.WeeklyEnterExitPivotRangeSelected) {
            criteria.push({ str1: "Enter or exit pivot range - weekly", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.DailyEnterExitLossRangeSelected) {
            criteria.push({ str1: "Enter or exit loss range - daily", val: "", str2: "" });
        }
        if (this.props.listAlertSettings.WeeklyEnterExitLossRangeSelected) {
            criteria.push({ str1: "Enter or exit loss range - weekly", val: "", str2: "" });
        }
        if (this.props.priceAlert) {
            criteria.push({ str1: `Price ${this.props.SymbolInfo ? this.props.SymbolInfo.DefaultCurrency.Code : ""}`, val: this.props.priceAlert.targetPrice, str2: "" });
        }


        let i = 0;
        _.each(criteria, (item) => {
            str.push(<li key={i++}><i className="check-sign"></i> {item.str1 + item.val + item.str2} </li>);

            if (str.length !== 0) {
                str.push();
            }
        });

        return (<ul> {str} </ul>);
    }

    doneBtnClick(listAlertSettings, priceAlertObj) {
        this.setState({
            listAlertSettings: listAlertSettings,
            priceAlertObj: priceAlertObj
        });

    }

    closeAlertCriteriaWindow() {
        this.setState({ openAlertCriteria: null });
    }

    render() {
        const criteriaStr = this.getCriteriaStr();

        return (
            <section className="alert-b">
                {this.props.showLoader ? <div className="loading-box">
                    <div className="loading-backdrop"></div>
                    <div className="loading loading-spinner"></div>
                </div>
                    :
                    <section id="AlertDialog">
                        {this.state.openColumnSet}
                        {this.state.openGeography}
                        {this.state.openAlertCriteria}
                        <FlexibleModal left={this.getXPosition()} top={this.getYPosition()} open={this.props.isAndAlertDialogOpen} ref={(modalHeader) => (this.modalHeader = modalHeader)} className="modal-popup alert-dialog and-condition-alert">
                            {!this.props.showLoader ?
                                <Modal.Header id="andAlertHeader">
                                    <Modal.Title>
                                        {this.props.sourceTypeId === AlertSourceType.List ? <span className="cap-header">{"List Alerts"}</span> : <span className="cap-header">{AlertTranslateHelper.AND_ALERT}</span> }
                                    </Modal.Title>
                                </Modal.Header>
                                : null}
                            <Modal.Body id="modalBody">
                                <div className="dialog-content and-alerts">
                                    <div className="flexbox-parent">
                                        <div className="flexbox-item">
                                            {this.props.sourceTypeId === AlertSourceType.List ?
                                                <Fragment>
                                                    <div className="alert-heading">{AlertTranslateHelper.AND_ALERT}</div>
                                                    <div className="alert-sub-heading">
                                                        <div className="tool-tip-box">
                                                            <span className="list-alert-name">
                                                                {LocalizationStore.getTranslatedData("alert_list_msg", 'Alert me when any symbol on {0} meets ALL of the following conditions:', AlertStore.getListName())}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                                :
                                                <div className="alert-sub-heading">
                                                    <div className="tool-tip-box">
                                                        <span className="list-alert-name">
                                                            {AlertTranslateHelper.FOLLOWING_CONDITION_MET_ALERT}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            {criteriaStr}
                                        </div>
                                    </div>
                                    <div className="btn-box">
                                        <button type="button" id="btnChangeCriteria" className="btn btn-xs btn-secondary disable-drag" data-effect="fadeOut" onClick={this.props.openCriteriaDialog}>Change Alert Criteria</button>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </Modal.Body>
                            {this.props.sourceTypeId === AlertSourceType.List && <FilterListDialog />}
                            {!this.props.showLoader ?
                                <Modal.Footer>
                                    <span className="anchor-link disable-drag" onClick={this.onNotificationPreferenceClick}>{AlertTranslateHelper.ALERT_PREFERENCE} </span>
                                    <span className="pull-right btn-box">
                                        <button type="button" className="model-action btn btn-xs btn-default btn-secondary pull-left small-bold disable-drag" data-effect="fadeOut" onClick={this.props.closeAlertDialog}>{TranslateHelper.Cancel}</button>
                                        <button type="button" id="btnSaveAlert" className="model-action btn btn-xs btn-default btn-secondary pull-left small-bold disable-drag" data-effect="fadeOut" onClick={this.saveAlertData}>{TranslateHelper.Save}</button>

                                    </span>
                                </Modal.Footer> : null}
                        </FlexibleModal>
                    </section>
                }
                <NotificationContentContainer open={this.state.isNCOpen} hideModal={this.hideModal} showBackButton={false} />
            </section>
        );
    }
}

const mapStateToProps = ({ alertReducer,DatagraphReducers})=> {
    const { msId, instrumentId, symbol, sourceTypeId, alertId, isTriggered, isAndAlertDialogOpen, showLoader, priceAlert, listAlertSettings } = alertReducer.AlertDialogReducer;
    const { SymbolInfo } = DatagraphReducers.DataGraphReducer;
    return { msId, instrumentId, symbol, sourceTypeId, alertId, isTriggered, isAndAlertDialogOpen, showLoader, priceAlert, listAlertSettings,SymbolInfo }
}

const mapDispatchToProps = (dispatch)=>({
    closeAlertDialog: ()=> dispatch(closeAlertDialog()),
    openCriteriaDialog: ()=> dispatch(openCriteriaDialog()),
    saveAlertData: (listAlertSettings, conditionTypeId, alertId, priceAlertObj, isTriggered)=> dispatch(saveAlertData(listAlertSettings, conditionTypeId, alertId, priceAlertObj, isTriggered))
})

export default connect(mapStateToProps, mapDispatchToProps)(AndAlertsDialog)