import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CompLineVisual, LabelText } from '../Presentational';
import { RotationMenuType } from '../../../../../Constants/RotationMenuType';
import { listItemHighlightSelected, showContextMenu,showCompGroupsChartContextMenu } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { toggleToolTip } from 'Actions/ComparisonActions';
import CompTimeLineView from './CompTimeLineView';
import { getVisibleLines } from '../../../../../Reducers/NavCompare/ComparisonGraphReducers/reselectorsdata';
import CompareTabType, { CompareMenuType } from "CompareTabType";
import { extend } from 'underscore';
import BaseServiceApi from 'BaseServiceApi';
import UserInfoUtil from "UserInfoUtil";



class CompLineVisuals extends Component {
    constructor(){
        super();
        this.EntitlementType = BaseServiceApi.rayData["EntitlementType"];
    }
    onMouseHover = (e, text, isShow = false) => {
        const { onMouseHover } = this.props;
        onMouseHover({
            isShowToolTip: isShow,
            toolTipText: text,
            pos: {
                x: e.clientX,
                y: e.clientY + 18
            }
        })
    }

    listItemHighlightSelected = (e, item) => {
        if (item) {
            if (e.ctrlKey || e.metaKey || e.shiftKey) {
                this.props.listItemHighlightSelected('Ctrl', item);
            }
            else {
                this.props.listItemHighlightSelected('Click', item);
            }
        }
    }

    handleContextMenu = (e, line, menuTabName) => {
        if (menuTabName == CompareMenuType.Symbols) {
            e.stopPropagation();
            e.preventDefault();
            //const leftX = e.clientX >= window.innerWidth ? e.clientX - 100 : e.clientX;
            this.props.showContextMenu({ x: e.clientX, y: e.clientY }, line);
        } else {
            const item = _.filter(this.props.listItems,(item) => item.itemType == RotationMenuType.Stocks?
            (UserInfoUtil.IsUserEntitled(this.EntitlementType.RAY_LOCALSYMBOLS_Entitlement)? item.localSymbol === line.symbol : item.symbol === line.symbol)
            :(item.symbol === line.symbol))[0];
            this.props.showCompGroupsChartContextMenu({ x: e.clientX, y: e.clientY }, item)
        }
    }

    isSymbolsMenu(menuTabName) {
        return (menuTabName == CompareMenuType.Symbols);
    }

    setGroupMenuSymbolName(lines, menuTabName, rotationMenuType, listItems, stockList) {
        const isStocksRotationMenuType = (rotationMenuType === RotationMenuType.Stocks);
        if (!this.isSymbolsMenu(menuTabName)) {
            let groupMenuItems;
            if (!isStocksRotationMenuType)
                groupMenuItems = listItems
            else
                groupMenuItems = stockList;

            lines.map((line) => {
                groupMenuItems.map((item) => {
                    if (item.underlyingSymbol == line.actualSymbol) {
                        extend(line, { symbol: isStocksRotationMenuType ? item.localSymbol !== "" ? item.localSymbol : item.symbol : item.symbol });
                    }
                })
            })
        }
    }

    render() {
        const { lines, menuTabName, rotationMenuType, listItems, stockList } = this.props;
        this.setGroupMenuSymbolName(lines, menuTabName, rotationMenuType, listItems, stockList);
        const allHighlightedLines = lines && lines.filter((itm) => itm.isHighlighted);
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                className="svg-comparison-grid"
                onContextMenu={(e) => e.preventDefault()}
            >
                <CompTimeLineView />
                {lines.map((line) => {
                    return (
                        (line.lineSource && line.lineSource.length > 0) && <g key={line.Osid}>
                            <CompLineVisual line={line} handleHighlight={this.listItemHighlightSelected} onMouseHover={this.onMouseHover}
                                handleContextMenu={this.handleContextMenu}
                                allHighlighted={allHighlightedLines.length === lines.length}
                                strokeColor={line.color} dataPoint={line.lineSource} isHighlighted={line.isHighlighted}
                                menuTabName={menuTabName} rotationMenuType={rotationMenuType} listItems={listItems} stockList={stockList} />
                            <LabelText textValue={this.isSymbolsMenu(menuTabName) ? line.label : line.symbol} isHighlighted={line.isHighlighted} textColor={line.color} dx="4" dy="4" textPosX={line.labelPos.x} textPosY={line.labelPos.y} />
                        </g>
                    )
                })
                }
            </svg>
        );
    }
}

const mapStateToProps = (state) => {
    const menuState = state.comparisonAndRotationMenuPanel.MenuTabReducer;
    let menuTabName = menuState.ComparisonMenuTab;
    let loadingSection = menuState.isLoading.ComparisonGraph;
    let menuData = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.ComparisonMenuStates;
    const lines = getVisibleLines(state);

    if (menuState.selectedTab === CompareTabType.RotationGraph) {
        menuTabName = menuState.RotationMenuTab;
        loadingSection = menuState.isLoading.RotationGraph;
        menuData = state.comparisonAndRotationMenuPanel.GroupsPanelReducer.RotationMenuStates;
    }

    let loadingStatus = loadingSection.SymbolsLoading;
    if (menuTabName === CompareMenuType.Groups) {
        loadingStatus = loadingSection.GroupsLoading;
    }

    const { rotationMenuType, listItems, stockList } = menuData;
    return { loadingStatus, lines, menuTabName, rotationMenuType, listItems, stockList };
};

const mapDispatchToProps = (dispatch) => ({
    onMouseHover: (toolTip) => dispatch(toggleToolTip(toolTip)),
    listItemHighlightSelected: (key, item) => dispatch(listItemHighlightSelected(key, item)),
    showContextMenu: (pos, item) => dispatch(showContextMenu(pos, item)),
    showCompGroupsChartContextMenu:(pos,item) => dispatch(showCompGroupsChartContextMenu(pos,item))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompLineVisuals);