import PropTypes from 'prop-types';
import React, { Component } from "react";
import ThemeHelper from "ThemeHelper";
import SettingsStore from "SettingsStore";
import LabelText from './LabelText.jsx';
import TimeTrackingWindow from "TimeTrackingWindow";
import { PrintMode } from "../../../print/printmode";
// let _calQtrs = [3, 6, 9, 12];
// let _f1Qtrs = [1, 4, 7, 10];
// let _f2Qtrs = [2, 5, 8, 11];

export default class TimeLine extends Component {
  constructor(props) {
    super(props);
    this.onContextMenuClick = this.onContextMenuClick.bind(this);
    this.canvasFailed = false;
    this.state = {
      source: props.source,
      leftMargin: props.leftMargin,
      rightMargin: props.rightMargin,
      nodeWidth: props.nodeWidth,
      textAlign: props.textAlign ? "Left" : props.textAlign,
      fiscalMonthEnd: props.fiscalMonthEnd,
      isCalender: props.isCalender === undefined ? true : props.isCalender,
      updateTimeline: false
    };
  }

  Qtr(month) {
    return Math.floor((month - 1) / 3.0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.fiscalMonthEnd) {
      this.setState({ fiscalMonthEnd: nextProps.fiscalMonthEnd });
    }
    if (nextProps.source) {
      this.setState({ source: nextProps.source });
    }
    if (nextProps.isCalender) {
      this.setState({ isCalender: nextProps.isCalender })
    }
    if (nextProps.textAlign) {
      this.setState({ textAlign: nextProps.textAlign });
    }
  }
  componentDidMount() { 
        if(this.canvasFailed){
            this.setState({ updateTimeline: true });
        }
    }
  getTimeLineLabel(source, useCanvas) {
    try {
        if (!this.gridCanvas || !source || source === null || source.length === 0) {
            this.canvasFailed = true;
            return;
        }
        const consoleSettings = SettingsStore.getConsoleSettings();
        const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
        let videoMode = tabDataGraphSettings.videoMode;
        let rect = this.gridCanvas.getBoundingClientRect();
        let chartAreaWidth = rect.width;
        let height = rect.height;
        let ctx = this.gridCanvas.getContext("2d");
        ctx.canvas.height = rect.height;
        ctx.canvas.width = rect.width;
        ctx.clearRect(0, 0, rect.width, rect.height);
        ctx.lineWidth = videoMode === true ? 3 : 1;
        ctx.translate(0.5, 0.5);
        ctx.imageSmoothingEnabled = false;
        ctx.save();
        ctx.fillStyle = ThemeHelper.getThemedBrush("dateText");
        ctx.fillTextWithPos = ThemeHelper.getThemedBrush("dateText");
        ctx.background = "Transparent";
        if (videoMode === true) {
            ctx.font = "600 32pt Calibri";
        } else {
            ctx.font = "normal 8pt Arial";
        }

        if (videoMode === true) {
            ctx.strokeStyle = ThemeHelper.getThemedBrush("dateText");
        } else {
            ctx.strokeStyle = ThemeHelper.getThemedBrush("financialDividerLines");
        }
        ctx.textAlign = this.state.textAlign === "Right" ? "start" : "end";
        let xMargin = this.state.textAlign === "Right" ? 4 : -4;
        if (videoMode === true) {
            xMargin = this.state.textAlign === "Right" ? 8 : -8;
        }
        let max = source.length;
        ctx.beginPath();
        for (let index = 0; index < max; index++) {
            let timeLineLabel = source[index];
            if (!timeLineLabel) break;
            let xAxis = chartAreaWidth - (timeLineLabel.XAxis * this.state.nodeWidth);
            if (xAxis < 0.0) continue;
            ctx.moveTo(xAxis, 0);
            ctx.lineTo(xAxis, height);
            if (useCanvas) {
                if (videoMode === true) {
                ctx.fillText(timeLineLabel.XLabel, xAxis + xMargin, 16 + height / 2);
                } else {
                    ctx.fillText(timeLineLabel.XLabel, xAxis + xMargin, 3 + height / 2);
                }
            }
        }
        ctx.stroke();
        ctx.closePath();
        this.canvasFailed = false;
    }
    catch (e) {
        console.error(e);
    }
  }
  getTimeLineLabel2(source, textAlign) {
    if (!this.gridCanvas || !source || source.length === 0) {
        return(<div></div>);
    }
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const videoMode = tabDataGraphSettings.videoMode;
    const rect = this.gridCanvas.getBoundingClientRect();
    const textColor = ThemeHelper.getThemedBrush("scaleText");
    const chartAreaWidth = rect.width;
    const height = rect.height;
    let sFont;
    let dY;
    if (videoMode === true) {
        sFont = "32pt";
        dY = "10";
    } else {
        sFont = "9pt";
        dY = "-0.5";
    }

    return (
        <svg className="svg financial-scale">
            {source.map((itm, i) => itm.XLabel && textAlign === "Left" &&
                <LabelText
                    key={i}
                    textAnchor="end"
                    isHighlighted={true}
                    textValue={itm.XLabel.toString()}
                    dx="-4"
                    dy={dY}
                    style={{ 
                      font: PrintMode.printing ? 'calibrib' : 'calibri', 
                      fontSize:  PrintMode.printing ? '8pt' : sFont, 
                      fill: textColor 
                    }}
                    textPosX={chartAreaWidth - (itm.XAxis * this.state.nodeWidth)}
                    textPosY={4 + height / 2} />)}
            {source.map((itm, i) => itm.XLabel && textAlign !== "Left" &&
                <LabelText
                    key={i}
                    textAnchor="begin"
                    isHighlighted={true}
                    textValue={itm.XLabel.toString()}
                    dx="4"
                    dy={dY}
                    style={{ font: 'calibri', fontSize: sFont, fill: textColor }}
                    textPosX={chartAreaWidth - (itm.XAxis * this.state.nodeWidth)}
                    textPosY={4 + height / 2} />)}
        </svg>
    );
  }

  onContextMenuClick(e) {
    e.preventDefault();
    return false;
  }

  render() {
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const useCanvas = tabDataGraphSettings.useCanvas;
    this.getTimeLineLabel(this.state.source, useCanvas);
    TimeTrackingWindow.trackChartLoadingTime();
    return (

      <div className="time-liner" id="timeLineMain">
        <canvas
            className="canvas-time-liner"
            onContextMenu={this.onContextMenuClick}
            ref={(ref) => this.gridCanvas = ref}
            style={{ fitPosition: "fill" }} />
            {!useCanvas && this.getTimeLineLabel2(this.state.source, this.state.textAlign)}
      </div>

    );
  }
}
TimeLine.propTypes = {
  source: PropTypes.array,
  nodeWidth: PropTypes.number.isRequired,
  textAlign: PropTypes.string,
  fiscalMonthEnd: PropTypes.number,
  isCalender: PropTypes.bool
};
