import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";
import AdobeUtil from "../../../Utils/AdobeUtil";
import AdobeConstants from "../../../Constants/AdobeConstants";
import NavType from "NavType";
const history = require("history").createHashHistory();

const CHANGE_EVENT = "change";
let _currentAction = null;
let _state = {
    TabCollection: []
}

class CompareStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        //Bug fixes - Max Limit error 
        this.setMaxListeners(0);
        this.isLiqVisibile = false;
    }

    setState() {
        _state = this.getNavCompareSettings();
    }

    getState() {
        return _state;
    }

    getCurrentAction() {
        return _currentAction;
    }

    getNavCompareSettings() {
        return SettingsStore.getConsoleSettings().NavCompareSettings;
    }

    getTabType(tabkey) {
        if (tabkey === null || tabkey === undefined) return null;
        let tabObject = _state.TabCollection.filter((tabItem) => {
            if (tabItem.Header === tabkey) return true;
            else return false;
        });
        return tabObject.length > 0 ? tabObject[0].TabType : null;
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    // Get current selected action.
    getCurrentAction() {
        return _currentAction;
    }

    updateIsCompareMenuPanelOpen(isCompareMenuPanelOpen) {
        let navCompareSettings = this.getNavCompareSettings();
        navCompareSettings.TabComparisonGraphSettings.isComparisonMenuPanelOpen = isCompareMenuPanelOpen;
        SettingsStore.saveSettings();
    }

    pushDatatoAdobe() {
        let setting = this.getNavCompareSettings();
        AdobeUtil.DTMTrack({
            pageName: "Panaray : Compare",
            siteSection: "Panaray : Compare",
            userType: "User",
            userIdRequired: true,
            graphSelected: setting.SelectedTabKey
          },
            AdobeConstants.COMPARE_VIEW_LOAD);
    }

    dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.data;        
        switch (action.actionType) {
            case SettingsConstants.ActionTypes.COMPARE_UPDATE_TAB:
                _currentAction = SettingsConstants.ActionTypes.COMPARE_UPDATE_TAB;
                let viewSetting = this.getNavCompareSettings();
                CompareSettingHelper.updateTabSelection(viewSetting, data);
                history.replace(`/${NavType.NavCompare}`);
                _state.SelectedTabKey = data;
                this.emit(CHANGE_EVENT);
                SettingsStore.saveSettings();
                this.pushDatatoAdobe();
                break;
            default:
                return;
        }
    }
}
const compareStore = new CompareStore();
export default compareStore;
