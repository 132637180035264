import React from "react";
import LocalizationActions from '../../Actions/LocalizationActions';
import LoginStore from "LoginStore";
import SettingsStore from '../../Stores/ConsoleWindow/Settings/SettingsStore';
import ListExplorerStore from "ListExplorerStore";
import ListStore from "ListStore";
import PanarayConsole from './PanarayConsole';
import ConsoleActions from "../../Actions/ConsoleActions";
import ConsoleStore from 'ConsoleStore';
import store from '../../Redux/store';
import { initializeData } from '../../Actions/ComparisonAndRotationMenuActions';
import { SettingsConstants } from "../../Constants/SettingsConstants";
import TimeTrackingWindow from "../../RayCustomControls/TimeTrackingWindow";
import NavType from "../../Constants/NavType";
import panarayDefault from "../../ServiceApi/RequestHelper/PanarayDefault";

export default class Loading extends React.Component {
    constructor(props) {
        super(props);
        this.settingsStateChange = this.settingsStateChange.bind(this);
        this.state = {
            settingsStateChanged: false,
        };
        this.settingsStateChanged = false;
    }

    setEntitleMentsInfo() {
        if (this.props.location.pathname == "/") return;
        ConsoleActions.setEntitleMentsInfo();
    }

    async UNSAFE_componentWillMount() {
        if (!LoginStore.isUserLoggedIn()) {
            return;
        }
        if(!panarayDefault.loginCalled){
            await panarayDefault.getDefaultSymbolData(true);
        }
        if (SettingsStore.settingsDataUpdated) {
            this.settingsStateChanged = true;
        }
        else {
            SettingsStore.addChangeListener(this.settingsStateChange);
            const localActiveNav = localStorage.getItem("_activeNav");
            if (localActiveNav === NavType.NavLists){
                TimeTrackingWindow.resetTimeTracker(true, false, NavType.NavLists);
                TimeTrackingWindow.initTimeTracker();
                TimeTrackingWindow.beginTimeTracker(NavType.NavLists);
            }
            Promise.resolve().then(() =>LocalizationActions.getLangResource())
                .then(() => this.setEntitleMentsInfo())
        }

        ConsoleStore.GetKpiDefaultInfo();


        if (this.props.location.pathname == "/") {
            if(SettingsStore.settingsDataUpdated){
                TimeTrackingWindow.beginTimeTracker(NavType.NavLists);
            }
            this.initPanarayData();
        }
    }


    componentWillUnmount() {
        SettingsStore.removeChangeListener(this.settingsStateChange);
    }

    settingsStateChange() {
        if (SettingsStore.getCurrentAction() === SettingsConstants.ActionTypes.GET_USER_SETTINGS) {
            SettingsStore.removeChangeListener(this.settingsStateChange);
            this.settingsStateChanged = SettingsStore.settingsDataUpdated;

            // making setState to re-render the component explicitly
            this.setState({ settingsStateChanged: SettingsStore.settingsDataUpdated });
        }
    }

    initPanarayData() {
        store.dispatch(initializeData(true));
        Promise.all([
            ListExplorerStore.getListExplorerData(false, false),
            ListExplorerStore.getEligibleLists(),
            ListStore.getMetricLibrary(),
            ConsoleStore.GetUserAboutModuleInfo()
        ]).then(() => true).catch((e) => {
            console.log('error in init panaray', e);
        })
    }

    render() {
        if (!this.settingsStateChanged)
            return (
                <div className="loading left100" ><img alt="" src="/Asset/images/loading.gif" /></div>
            );
        else {
            return <PanarayConsole {...this.props} />
        }
    }
};