import React, { PureComponent } from 'react';
import LocalizationStore from "Stores/Localization/LocalizationStore.js";

class Quadrants extends PureComponent {
    render() {
        const outPerforming = LocalizationStore.getTranslatedData("CRG_OUTPERFORMING", "outperforming");
        const underperforming = LocalizationStore.getTranslatedData("CRG_UNDERPERFORMING", "underperforming");
        const improving = LocalizationStore.getTranslatedData("CRG_IMPROVING", "improving");
        const weakening = LocalizationStore.getTranslatedData("CRG_WEAKENING", "weakening");

        return (
            <div className="align-items" style={{ pointerEvents: 'none' }}>
                <div className="performing-block" style={{ pointerEvents: 'none' }}>
                    <div id="out-performing-improve" className="out-performing-improve">
                        <Title1 value={outPerforming} />
                        <Title2 value={improving} />
                    </div>
                    <div id="out-performing-weake" className="out-performing-weake">
                        <Title1 value={outPerforming} />
                        <Title2 value={weakening} />
                    </div>
                    <div id="under-performing-improve" className="under-performing-improve">
                        <Title1 value={underperforming} />
                        <Title2 value={improving} />
                    </div>
                    <div id="under-performing-weake" className="under-performing-weake">
                        <Title1 value={underperforming} />
                        <Title2 value={weakening} />
                    </div>
                </div>
            </div >
        );
    }
}

const Title1 = ({ value }) =>
    <>
        <span className="highLevel">
            {value}
        </span>
        <span className="lowLevel">
            {LocalizationStore.getTranslatedData("CRG_Over26wks", "over 26 wks")}
            <br />
            &
        </span>

    </>

const Title2 = ({ value }) =>
    <>
        <span className="highLevel">
            {value}
        </span>
        <span className="lowLevel">
            {LocalizationStore.getTranslatedData("CRG_Over4wks", "over 4 wks")}
        </span>
    </>

export default Quadrants;