import React from "react";
import ReactDom from "react-dom";
import { ButtonToolbar, DropdownButton, MenuItem, FormGroup, FormControl, Overlay } from "react-bootstrap";
import { ToolTip } from "./ToolTip.jsx"
import ColumnCustomFilterControl from "./ColumnCustomFilterControl.jsx";
import ListManagerTabType from "ListManagerTabType";
import SettingsStore from "SettingsStore";
import { each } from "underscore";
import ListActions from "ListActions";
import BaseServiceApi from 'BaseServiceApi';
import ListStore from "ListStore";
import { ListExplorerConstants } from "ListExplorerConstants";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
let ListColumnDataType = BaseServiceApi.rayData["ListColumnDataType"];
let ValueOperatorType = BaseServiceApi.rayData["ValueOperatorType"];


export default class EditableFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsMoreThanFive: false,
            hasValue: false
        }
        this.setData = this.setData.bind(this);
        this.addRow = this.addRow.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.validateInputValue = this.validateInputValue.bind(this);
        this.getDiv = this.getDiv.bind(this);
        this.rows = [];
        this.dataItems = {
            k1: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator

            },
            k2: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator

            },
            k3: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator
            },
            k4: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator

            },
            k5: {
                operator: ValueOperatorType.GE_ValueOperator,
                inputValue: "",
                isDataItemValue: false,
                metricItemName: null,
                previouseValue: "",
                previouseOperator: ValueOperatorType.GE_ValueOperator

            }

        }
    }

    UNSAFE_componentWillMount() {
        let self = this;
        if (!self.props.activeColumn) {
            self.setState({ hasValue: false });
            self.rows.push(<PriceRow key={self.rows.length + 1} setData={self.setData} colData={self.props.colData} keyValue={"k" + parseInt(self.rows.length + 1)} activeColumn={null} applyFilter={self.applyFilter} setCurrentFilterWidthHeight={self.props.setCurrentFilterWidthHeight} />);
        }
        else {
            self.setState({ hasValue: true });
            each(self.props.activeColumn, (aCol, i) => {
                if (aCol.dataItemValue) {
                    self.setData(self.getOperator(aCol), self.getValue(aCol), "k" + parseInt(i + 1), true, aCol.dataItemValue);
                }
                else {
                    self.setData(self.getOperator(aCol), self.getValue(aCol), "k" + parseInt(i + 1), true);
                }
                self.rows.push(<PriceRow key={self.rows.length + 1} setData={self.setData} colData={self.props.colData} keyValue={"k" + parseInt(self.rows.length + 1)} activeColumn={aCol} applyFilter={self.applyFilter} setCurrentFilterWidthHeight={self.props.setCurrentFilterWidthHeight} />);
            });
        }
    }

    getOperatorText(operator) {
        return ColumnCustomFilterControl.toDisplayTextOperator(parseInt(operator)).toString();
    }
    getOperator(aCol) {
        return aCol.valueOperator.toString();
    }

    getValue(aCol) {
        if (aCol.valueOperator == ValueOperatorType.BETWEEN_ValueOperator) {
            return aCol.values[0].toString() + "-" + aCol.values[1].toString();
        }
        return aCol.values.toString();
    }


    setData(operator, value, key, initialValue = false, isDataItemValue = false, metricItemName = null) {
        if (initialValue) {
            this.dataItems[key] = {
                operator: operator,
                inputValue: value,
                isDataItemValue: isDataItemValue,
                metricItemName: metricItemName,
                previouseValue: value,
                previouseOperator: operator
            };
        }
        else {
            this.dataItems[key].operator = operator;
            this.dataItems[key].inputValue = value;
            this.dataItems[key].isDataItemValue = isDataItemValue;
            this.dataItems[key].metricItemName = metricItemName;
        }
    }

    getRow() {
        return this.rows;
    }

    addRow() {
        if (this.rows.length < 5) {
            this.rows.push(<PriceRow key={this.rows.length + 1} setData={this.setData} keyValue={"k" + parseInt(this.rows.length + 1)} colData={this.props.colData} activeColumn={null} applyFilter={this.applyFilter} setCurrentFilterWidthHeight={this.props.setCurrentFilterWidthHeight} />);
            this.setState({ rowsMoreThanFive: false }, this.props.setCurrentFilterWidthHeight);
        }
        if (this.rows.length == 5) {
            this.setState({ rowsMoreThanFive: true }, this.props.setCurrentFilterWidthHeight);
        }
    }

    getDiv() {
        return this.boxDiv;
    }

    validateInputValue() {

        let valid = false;
        let validDataSet = [];
        each(this.dataItems, (dataItem) => {
            if (dataItem.inputValue != dataItem.previouseValue) {
                if (dataItem.inputValue != dataItem.previouseValue || dataItem.operator != dataItem.previouseOperator) {
                    valid = true;
                }
                else {
                    valid = false;
                }
            }
            else {
                if (dataItem.operator != dataItem.previouseOperator && dataItem.inputValue !== "") {
                    valid = true;
                }
                else {
                    valid = false;
                }

            }
            validDataSet.push(valid);
        });
        return validDataSet;
    }
    applyFilter() {
        // let countValue = false;
        // let countOperator = 0;
        let validArray = this.validateInputValue();
        let valid = validArray.indexOf(true) > -1 ? true : false;
        if (valid) {
            this.props.applyFilterMultipleData(this.dataItems, this.rows.length, false, false);
        }

        else {
            this.props.applyFilterMultipleData(this.dataItems, this.rows.length, false, true);
        }
    }

    render() {
        const rows = this.getRow();
        return (
            <div className="list-filter-header" id="editableFilterComponent">
                <div className="arrow_box" id="editableFilterArrowBox" ref={(div) => { this.boxDiv = div; }}>
                    <div className="info-arrow-box">
                        <div className="head ellipsis">{LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + this.props.colData.dataItemId, this.props.colData.displayName)}</div>
                        <div className="inner-arrow-box ">
                            <div>
                                {rows}
                            </div>
                            <div className="add-row adjust-btn">
                                {(!this.state.rowsMoreThanFive && this.rows.length < 5)
                                    ? <a className="add-row-link" onClick={this.addRow}>{LocalizationStore.getTranslatedData("LM_AddRow", "Add Row")}</a>
                                    : <a className="add-row-link"></a>}
                                <a className="add-row-apply" onClick={this.applyFilter}> {LocalizationStore.getTranslatedData("LM_Apply", "Apply")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class PriceRow extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.clearField = this.clearField.bind(this);
        this.setData = this.setData.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.openMetricLibrary = this.openMetricLibrary.bind(this);
        this.listStoreStateChange = this.listStoreStateChange.bind(this);
        this.getMetricData = this.getMetricData.bind(this);
        this.setMetricData = this.setMetricData.bind(this);
        this.setPosAndData = this.setPosAndData.bind(this);
        this.isBetweenOperatorValue = this.isBetweenOperatorValue.bind(this);
        this.getMenuOperator = this.getMenuOperator.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleKeyDown=this.handleKeyDown.bind(this)
        this.state = {
            title: '=',
            operator: ValueOperatorType.GE_ValueOperator,
            inputValue: "",
            isValue: false,
            activeColumn: this.props.activeColumn,
            show: false,
            isDataItemValue: false,
            data: null,
            key: this.props.keyValue,
            filterValue: "",
            previouseOperator: ValueOperatorType.GE_ValueOperator
        }
        this.inputValues = {};
        this.isBackSpacePressed=false

    }

    UNSAFE_componentWillMount() {
        let inputValue = this.getValue();
        let operator = this.getOperator();
        let title = this.getOperatorText(operator);
        let filterValue = this.props.colData.dataItemId;
        let isDataItemValue = this.isDataItemValue();
        if (inputValue.length > 0) {
            this.setState({ isValue: true, key: this.props.keyValue });
        }
        this.setState({ inputValue: inputValue, filterValue: filterValue, operator: operator, isDataItemValue: isDataItemValue, title: title, show: false });
        ListStore.addChangeListener(this.listStoreStateChange);
    }
    componentWillUnmount() {
        this.setState({ show: false });
        ListStore.removeChangeListener(this.listStoreStateChange);
    }

    isDataItemValue() {
        if (this.props.activeColumn != null && this.props.activeColumn.dataItemValue)
            return this.props.activeColumn.dataItemValue;
        return false;
    }

    componentDidMount() {
        ReactDom.findDOMNode(this.inputText).select();
        this.setState({ show: false });
        //ListStore.addChangeListener(this.listStoreStateChange);
    }

    listStoreStateChange() {
        let currentAction = ListStore.getCurrentAction();
        //let state = ListStore.getState();
        if (currentAction == ListExplorerConstants.ActionTypes.UPDATE_METRIC_COLUMN_FILTER) {
            this.getMetricData();
        }
    }

    getOperatorText(operator) {
        return ColumnCustomFilterControl.toDisplayTextOperator(parseInt(operator)).toString();
    }
    getOperator() {
        if (this.props.activeColumn == null) {
            return ValueOperatorType.GE_ValueOperator;
        }
        if (this.props.colData.dataItemId == this.props.activeColumn.dataItemId) {
            return this.props.activeColumn.valueOperator.toString();
        }
    }

    getValue() {
        if (this.props.activeColumn == null) {
            return "";
        }
        if (this.props.colData.dataItemId == this.props.activeColumn.dataItemId) {
            if (this.props.activeColumn.valueOperator == ValueOperatorType.BETWEEN_ValueOperator) {
                if (this.props.colData.dataType == ListColumnDataType.DOUBLE_ListColumnData || this.props.colData.dataType == ListColumnDataType.INT_ListColumnData) {
                    return parseFloat(this.props.activeColumn.values[0]).toString() + "-" + parseFloat(this.props.activeColumn.values[1]).toString();
                }
                return this.props.activeColumn.values[0].toString() + "-" + this.props.activeColumn.values[1].toString();
            }
            else {
                if (!this.props.activeColumn.dataItemValue) {
                    return this.props.activeColumn.values.toString();
                }
                else {
                    let values = ListStore.getDisplayNameforMsItemID(parseInt(this.props.activeColumn.values));
                    return values.toString();
                }
            }
        }
    }

    getFilterValue() {
        if (this.props.activeColumn == null) {
            return "";
        }
        if (this.props.activeColumn.dataItemValue) {
            return this.props.activeColumn.values.toString();
        }
        return "";
    }

    getMetricData() {
        let data = ListStore.getItemDataforColumnFilter();
        this.setState({ data: data }, this.setMetricData);
    }
    setMetricData() {
        let data = this.state.data;
        if (this.props.keyValue == data.key) {
            this.setState({ inputValue: LocalizationStore.getTranslatedData("DataItemExt_Description_" + data.MsItemID, data.MetricDisplayName), filterValue: data.MsItemID.toString(), isDataItemValue: true, isValue: true }, this.setData);
            ReactDom.findDOMNode(this.inputText).select();
        }
    }


    openMetricLibrary() {
        ColumnCustomFilterControl.metricLibOpen();
        let data = { key: this.props.keyValue, dataItemId: this.props.colData.dataItemId, displayName: this.props.colData.displayName };
        ListActions.getMetricSearchData(data, null, true);
        ListActions.showMetricLibraryFromColumnFilter();

        let node = document.createElement('div');
        node.className = 'overlay';
        document.getElementById("listManagerInnerContainer").appendChild(node);
        //if (BrowserUtil.getBrowserName() == 'IE' || BrowserUtil.getBrowserName() == 'Netscape') {
        let overlayFilterNode = document.getElementById("columnFilterComponent").cloneNode(false);
        let toolBarAreaNode = document.getElementsByClassName("tool-bar-area")[0];
        let overlayHeaderNode = toolBarAreaNode.cloneNode(false);
        overlayFilterNode.className = 'overlay';
        overlayHeaderNode.className = 'overlay';
        overlayHeaderNode.style.height = toolBarAreaNode.getBoundingClientRect().height + "px";
        overlayHeaderNode.style.paddingTop = 0 + "px";
        let editableFilterNode = document.getElementById("editableFilterArrowBox");
        let attrValue = editableFilterNode.getBoundingClientRect();
        let boxWidth = attrValue.width + 12;
        overlayFilterNode.style.width = boxWidth + "px";
        overlayFilterNode.style.height = attrValue.height + "px";
        document.getElementById("editableFilterArrowBox").appendChild(overlayFilterNode);
        document.getElementsByClassName("head-list-view")[0].appendChild(overlayHeaderNode);
        // }
        this.setState({ key: this.props.keyValue });
    }

    isBetweenOperatorValue () {
        if (this.state.operator === ValueOperatorType.BETWEEN_ValueOperator) {
            return true;
        }
        else if(this.state.previouseOperator === ValueOperatorType.BETWEEN_ValueOperator) {
            return true;
        }
        else if(this.state.isDataItemValue) {
            return false;
        }
        else {
            if ((this.props.colData.dataType == ListColumnDataType.DOUBLE_ListColumnData || this.props.colData.dataType == ListColumnDataType.INT_ListColumnData) && this.state.inputValue.length > 0) {
                if (this.state.inputValue.length > 0) {
                    const reg = new RegExp("^([-]?[0-9]*)([.][0-9]*)?(([-][0-9]*)([.][0-9]*)?)?$");
                    const reg1 = new RegExp("^([-]?[0-9]*)([.][0-9]*)?$");
                    return !(reg.test(this.state.inputValue) && reg1.test(this.state.inputValue));
                }
            }
            else {
                if (this.props.colData.dataType == ListColumnDataType.DATE_ListColumnData && this.state.inputValue && this.state.inputValue.length > 0 && this.state.inputValue.includes('-')) {
                    return true;
                }
            }

        }
        return false;
    }

    setPosAndData() {
        if (this.state.inputValue && this.state.inputValue.length > 0 && this.isBetweenOperatorValue()) {
            this.clearField();
        }
        else {
            this.setData();
        }
        this.props.setCurrentFilterWidthHeight();
    }

    setData() {
        let inputText = "";
        if (this.state.isDataItemValue) {  
            if(this.state.data === null && this.state.activeColumn !== null) {
                inputText = this.state.activeColumn.values.toString();
            }else {
                inputText = this.state.filterValue;
            }
            let metricItemName = this.state.inputValue;
            this.props.setData(this.state.operator, inputText, this.props.keyValue, false, this.state.isDataItemValue, metricItemName);
        }
       
        else {
            if (this.props.colData.dataType == ListColumnDataType.DATE_ListColumnData) {
                let inputText = this.state.inputValue;
                this.props.setData(this.state.operator, inputText, this.props.keyValue, false);
            }
            else if (this.props.colData.dataType == ListColumnDataType.DOUBLE_ListColumnData || this.props.colData.dataType == ListColumnDataType.INT_ListColumnData) {
                this.props.setData(this.state.operator, this.state.inputValue, this.props.keyValue, false);
            }
        }
    }

    validateInputDate(value) {
        if (this.props.colData.dataType == ListColumnDataType.DATE_ListColumnData) {
            let formatter = this.props.colData.formatter.split(" ");
            return ColumnCustomFilterControl.validateDate(value, formatter[0]);
        }
    }
    validateInput(value) {
        if (value.length > 0) {
            if (this.props.colData.dataType == ListColumnDataType.DATE_ListColumnData) {
                const formmater = ColumnCustomFilterControl.getDateFormat(this.props.colData.dataItemId).replace("D", "DD").replace("M", "MM");
                if (this.state.operator != ValueOperatorType.BETWEEN_ValueOperator) {
                    let isValid = this.validateInputDate(value);
                    if (!isValid) {
                        this.setState({ inputValue: "", errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage7", "Acceptable date format " + formmater, formmater), show: true });
                    }
                    if (isValid) {
                        this.setState({ show: false });
                    }

                }
                else {
                    if (value && value.includes("-")) {
                        let splitValue = value.split("-");
                        let isValid = splitValue.length === 2 && this.validateInputDate(splitValue[0]) && this.validateInputDate(splitValue[1]);
                        if (!isValid) {
                            this.setState({ inputValue: "", errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage7", "Acceptable date format " + formmater, formmater), show: true });
                        }
                        if (isValid) {
                            this.setState({ show: false });
                        }
                    }
                    else {
                        this.setState({ errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage1", "Please enter the filter expression with 2 values separated by a '-' (dash) operator."), show: true });
                    }

                }
                return true;

            }
            if ((this.props.colData.dataType == ListColumnDataType.DOUBLE_ListColumnData || this.props.colData.dataType == ListColumnDataType.INT_ListColumnData)) {
                let reg = new RegExp("^([-]?[0-9]*)([.][0-9]*)?(([-][-]?[0-9]*)([.][0-9]*)?)?$");
                let isValid = reg.test(value);
                if (!isValid) {
                    this.setState({ errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage3", "Acceptable input is numeric."), show: true });
                    return false;
                }
                let reg1 = new RegExp("^([-]?[0-9]*)([.][0-9]*)?$");
                if (this.state.operator != ValueOperatorType.BETWEEN_ValueOperator) {
                    let isValidInput = reg1.test(value);
                    if (!isValidInput) {
                        this.setState({ errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage8", "Acceptable input must be one."), show: true });
                        return false;
                    }
                }

                if (value.length > 38) {
                    this.setState({ errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage4", "Input value is too big."), show: true });
                    return false;
                }
            }
        }
        return true;
    }
    handleKeyDown(e){
        var key = e?.keyCode || e?.charCode;
        if( key == 8)
        this.isBackSpacePressed=true
    }

    handleBlur() {
        if (this.props.colData.dataType == ListColumnDataType.DATE_ListColumnData) {
            if (this.state.operator != ValueOperatorType.BETWEEN_ValueOperator) {
               // let validInput = ColumnCustomFilterControl.validateDate(this.state.inputValue, this.props.colData.formatter);
               ColumnCustomFilterControl.validateDate(this.state.inputValue, this.props.colData.formatter);
            }
        }
        if (this.props.colData.dataType == ListColumnDataType.DOUBLE_ListColumnData || this.props.colData.dataType == ListColumnDataType.INT_ListColumnData) {
            //let validInput = ColumnCustomFilterControl.validateNumber(this.state.inputValue);
            ColumnCustomFilterControl.validateNumber(this.state.inputValue);

        }
        this.setState({ show: false });
    }

    handleOnChange(e) {
        if (!this.state.isDataItemValue) {
            if (!this.validateInput(e.target.value)) {
                return false;
            }
            if (this.props.colData.dataType != ListColumnDataType.DATE_ListColumnData && this.state.operator != ValueOperatorType.BETWEEN_ValueOperator) {
                this.setState({ show: false });
            }
            if ((this.props.colData.dataType == ListColumnDataType.DOUBLE_ListColumnData || this.props.colData.dataType == ListColumnDataType.INT_ListColumnData) && this.state.operator != ValueOperatorType.BETWEEN_ValueOperator) {
                // PANWEB-4050
                //let value = e.target.value;
                //let formatterDecimalPlaces = this.props.colData.FormatterDecimalPlaces;
                //if (formatterDecimalPlaces !== null) {
                //    let reg2 = new RegExp("^-?\\d+$");
                //    if (formatterDecimalPlaces == 0 && !reg2.test(value)) {
                //        this.setState({ errorMessage: LocalizationStore.getTranslatedData("Decimal_Int_Limit", "Please enter an integer"), show: true });
                //    }
                //    let reg3 = new RegExp("^-?\\d+\\.?\\d{0," + formatterDecimalPlaces + "}$");
                //    if (formatterDecimalPlaces > 0 && !reg3.test(value)) {
                //        this.setState({ errorMessage: LocalizationStore.getTranslatedData("Decimal_Limit", "Please enter no more than {0} decimals", formatterDecimalPlaces), show: true });
                //    }
                //}
            }
            if (e.target.value.length == 0) {
                if (this.state.operator == ValueOperatorType.BETWEEN_ValueOperator) {
                    this.setState({ errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage1", "Please enter the filter expression with 2 values separated by a '-' (dash) operator."), show: true });
                }
                this.setState({ inputValue: e.target.value, isValue: false }, this.setData);

            }
            else {
                if (this.state.operator == ValueOperatorType.BETWEEN_ValueOperator && this.props.colData.dataType != ListColumnDataType.DATE_ListColumnData) {
                    if (e.target.value !== undefined && (!e.target.value.includes("-") || (e.target.value.includes("-") && e.target.value.split("-").length <= 1) || (e.target.value.includes("-") && e.target.value.split("-").length === 2 && e.target.value.split("-")[1] === "")))
                        this.setState({ errorMessage: LocalizationStore.getTranslatedData("ColumnFilter_errorMessage1", "Please enter the filter expression with 2 values separated by a '-' (dash) operator."), show: true });
                    else {
                        this.setState({ errorMessage: "", show: false });
                    }
                }
                this.setState({ inputValue: e.target.value, isValue: true }, this.setData);

            }
        }
        else{
                if(this.isBackSpacePressed){
                    if (e?.target?.value !== undefined &&  e.target.value.length === 0){
                        this.setState({ isValue: false, 
                            inputValue: "", 
                            filterValue: "", 
                            isDataItemValue: false}, 
                            this.setData);
                       }
                    else{
                        this.setState({ inputValue: e.target.value, 
                            isValue: true }, 
                            this.setData);
                    }

                    this.isBackSpacePressed=false
                }
            }

    }
    clearField() {
        this.setState({ isValue: false, inputValue: "", filterValue: "", isDataItemValue: false }, this.setData);
    }

    getVisible() {
        const metricData = ListStore.getState().metricData ? ListStore.getState().metricData : ListStore.getMetricLibrary();
        let canOpenMetric = false;
        _.each(metricData.itemData,(c) => {
            if(c.MsItemID === this.props.colData.dataItemId){
                canOpenMetric = true;
            }
        });
        let settings = SettingsStore.getConsoleSettings();
        let navListManagerSettings = settings.NavListManagerSettings;
        if (navListManagerSettings.SelectedTabKey == ListManagerTabType.Ownership) {
            return false;
        }
        else if ((this.props.colData.dataType == ListColumnDataType.DOUBLE_ListColumnData || this.props.colData.dataType == ListColumnDataType.INT_ListColumnData)
            && this.state.operator != 5 && canOpenMetric) {
            return true;
        }
        return false;
    }

    getMenuOperator() {
        let valueOperator = ColumnCustomFilterControl.getSupportedValueOperators(this.props.colData.dataType, this.props.colData.dataItemId);
        return valueOperator.map((menuItem) =>
            <MenuItem key={menuItem.valueOperatorType} eventKey={menuItem.valueOperatorType} className={(this.state.operator == menuItem.valueOperatorType) ? "active" : ""}>{menuItem.operatorString}</MenuItem>
        );
    }

    onSubmit(e) {
        this.props.applyFilter();
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    onSelect(eventKey, event) {
        const prevOperator = this.state.operator; 
        this.setState({
            title: event.target.textContent,
            operator: eventKey,
            previouseOperator: prevOperator
        }, this.setPosAndData);
    }

    render() {
        let clearButton = this.state.isValue == true ?
            <span id="clear" className="icn-circle-custom1" onClick={this.clearField} ></span>
            : null;
        const sharedProps = {
            show: this.state.show,
            container: this.formgroup,
            target: () => ReactDom.findDOMNode(this.inputText)
        };
        let visibleMetricSelection = (this.getVisible()) ? "block" : "none";
        return (
            <form className="form-inline clearfix" onSubmit={this.onSubmit}>
                <FormGroup className="customListDropDown pull-left">
                    <ButtonToolbar >
                        <DropdownButton bsSize="xsmall" className="w36 pull-left" title={this.state.title} noCaret id="dd-arrow"
                            onSelect={(eventKey, event) => {this.onSelect(eventKey, event)}}>
                            {this.getMenuOperator()}
                        </DropdownButton>
                    </ButtonToolbar>
                </FormGroup>
                <FormGroup className="pull-right" style={{ display: visibleMetricSelection }}>
                    <a className="convert" onClick={this.openMetricLibrary} ><div className="icn-convert"></div></a>
                </FormGroup>
                <FormGroup className="pos-relative1 " ref={(formgroup) => { this.formgroup = formgroup; }}>
                    <div className="overflow-h display-b mart-2" >
                        <FormControl className="small-normal clearButton" ref={(input) => { this.inputText = input; }} value={this.state.inputValue} type="text" placeholder="" onChange={this.handleOnChange} onBlur={this.handleBlur} onKeyDownCapture={this.handleKeyDown}/>
                    </div>
                    {clearButton}
                    <Overlay {...sharedProps} placement="bottom">
                        <ToolTip>{this.state.errorMessage}</ToolTip>
                    </Overlay>
                </FormGroup>
            </form>

        );
    }
}