class FundamentalTabReportObject {
    constructor(length, data, settings) {
        this.cells = this.getcells(length, data, settings);
        this.cellIsNegative = this.getArrayElementsinReport(data);
        this.cssType = 19;
        this.cellsRightLineVisibilityAsc = this.getArrayElementsinReport(length);
        this.cellsRightLineVisibilityDesc = this.getArrayElementsinReport(length);
        this.cssClass = "headerLevel";
        this.ind = false;
        this.itemId = 0;
        this.noScale = false;
        this.yLabels = true;
        this.filterType = data.filterType;
    }

    getcells(length, data, settings) {
        let cells = []; let companyName = undefined;
        if (settings.SymbolInfo && settings.SymbolInfo.CompanyName) companyName = settings.SymbolInfo.CompanyName;
        let formattedTitle = data.titleFormat != null ? data.titleFormat.replace("{0}", companyName) : "";
        cells.push(formattedTitle);
        for (let i = 0; i < length - 1; i++) { cells.push(""); }
        return cells;
    }

    getArrayElementsinReport(length) {
        let cells = [];
        for (let i = 0; i < length - 1; i++) { cells.push(false); }
        return cells;
    }
}

export default FundamentalTabReportObject;

