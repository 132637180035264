import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputName from './InputName.jsx';
import { RouteToDataGraph } from '../../../../../Actions/ConsoleActions';
import NavType from "NavType";
import TimeTrackingWindow from "TimeTrackingWindow";

class CompList extends PureComponent {
    callWithStopPropagation = (e, funcName) => {
        const { isEditMode } = this.props;
        if (isEditMode) return;
        e.stopPropagation();
        funcName(e);
    }

    handleHighlight = (e) => {
        this.props.handleHighlight(e, this.props.listItem);
    }

    handleContextMenu = (e) => {
        this.props.handleContextMenu(e, this.props.listItem);
    }

    toggleVisible = (e) => {
        this.props.toggleVisible(this.props.listItem.Osid);
    }

    handleRename = (e) => {
        this.props.handleRename(this.props.listItem, e.target.value.trim());
    }

    isEllipsisActive = (e) => {
        return (e.offsetWidth < e.scrollWidth);
    }

    onMouseOver = (e) => {
        if (this.isEllipsisActive(e.target))
            this.props.onMouseHover(e, e.target.innerHTML, true);
    }

    onMouseOut = (e) => {
        if (this.isEllipsisActive(e.target))
            this.props.onMouseHover(e, e.target.innerHTML, false);
    }

    handleRedirect = (e) => {
        const { isUserCreated, label } = this.props;
        if (!isUserCreated && label) {
            RouteToDataGraph(label.trim(), NavType.NavSymbol);
            e.preventDefault();
            e.stopPropagation();
        }
    }

    render() {
        TimeTrackingWindow.trackChartLoadingTime();
        const { isVisible, isHighlighted, label, name, perCHG, color, isUserCreated, isEditMode, Osid } = this.props;
        return (
            <li data-osid={Osid} onClick={(e) => this.callWithStopPropagation(e, this.handleHighlight)} style={{ opacity: isHighlighted ? 1 : 0.5 }} onContextMenu={this.handleContextMenu}>
                <div className="comp-grid-cell">
                    <a className="symbol-view-eye">
                        <div className="toggle-button toggle-button-block">
                            <input id="toggleButton1" type="checkbox" defaultChecked={!isVisible} onClick={(e) => this.callWithStopPropagation(e, this.toggleVisible)} />
                            <div className="icn-eye-n-check"></div>
                        </div>
                    </a>
                </div>
                <div className="comp-grid-cell">
                    <div style={{ backgroundColor: color }} className="rotation-menu-rect"></div>
                    <div className="compare-symbol-block"><a className={isUserCreated ? "symbol-name ellipsis" : "symbol ellipsis"} onClick={this.handleRedirect} onMouseMove={this.onMouseOver} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>{label}</a></div>
                </div>
                <div className="comp-grid-cell">
                    {
                        isUserCreated && isEditMode
                            ? <div className="comp-name-edit"><InputName handleRename={this.handleRename} textValue={name} /></div>
                            : <a className="symbol-name rotation-menu-ellipsis" onMouseMove={this.onMouseOver} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut}>{name}</a>
                    }
                </div>
                <div className="comp-grid-cell comp-percentile ellipsis" onMouseOver={this.onMouseOver} onMouseMove={this.onMouseOver} onMouseOut={this.onMouseOut}>{perCHG}</div>
            </li>
        );
    }
}

CompList.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    isHighlighted: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    perCHG: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    toggleVisible: PropTypes.func.isRequired,
    handleHighlight: PropTypes.func.isRequired,
    handleContextMenu: PropTypes.func.isRequired,
    handleRename: PropTypes.func.isRequired,
    onMouseHover: PropTypes.func.isRequired
}

export default CompList;