import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetUserRoleRequest extends BaseServiceRequest {
  constructor(UserEmail) {
    super();
    super.addRequestUri("gethubusrroles.pb");
    super.addRequestParameter("email", UserEmail);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.HUB;
  }

  getMessageName() {
    return "hubUserRoleResponse";
  }
}

export default GetUserRoleRequest;
