import annotationData from '../../../../ServiceApi/RequestHelper/AnnotationData';
import annotationsApi from '../../../../ServiceApi/Apis/AnnotationsApi';
import AnnotationUtil from 'Utils/AnnotationUtil';
import { findWhere } from 'underscore';
import GraphicPoints from '../../../../Models/AnnotationModels/GraphicsPoints';
import { PrintMode } from '../../../../print/printmode';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import { ShareAccessType } from '../../../../Constants/ShareConstants';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
const textWidth = require("text-width");
import { AnnotationConstant, TemporaryId } from "Constants/AnnotationConstants.js";
import {
    getAnnotationIsDrawing, getAnnotationSelectedId, getAnnotationSelected,
    getText, getTargetLayer, getAnnotationMenu, getActiveAnchor, getAnnotationChartDraw,
    getAnnotationDimension, getLayersList, getVisibleAnnotIds
} from '../../../../Reducers/AnnotationReducers/selectors';
import { takeLatest, select, put, call, takeEvery } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;


/********************** Model Logic *************************/
function* saveTextEscEvent() {
    try {
        let atext = null;
        const textsAll = yield select(getText);
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const allAnnotId = yield select(getVisibleAnnotIds);
        const textShape = textsAll.find((itm) => itm.id === annotSelectedId);
        if (textShape.text.trim() === '') {
            yield put({
                type: ActionTypes.REMOVE_TEMP_TEXT_ANNOTATION,
                annotRemoveId: textShape.id
            });
        } else {
            atext = { ...textShape, isEditable: false }
            yield call(updateText, false, atext);
            const annotationSelected = yield select(getAnnotationSelected);
            annotationSelected.createdDate = annotationSelected.createdDate ? annotationSelected.createdDate : new Date();
            annotationSelected.updatedDate = new Date();

            const annotationdataProto = yield call(annotationData.setAnnotationData, annotationSelected);
            const resp = yield call(upserttext, annotationdataProto);
            const texts = yield call(updateAnnotationId, textsAll, annotationSelected.id, resp.annotationId);
            yield put({
                type: ActionTypes.HANDLE_TEXT_SAVE_ID_SUCCESS,
                texts: texts
            });
            yield put({
                type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
                annotation: resp.annotationId
            });
            if (resp.annotationId && annotationdataProto.annotationID === 0) {
                yield put({
                    type: ActionTypes.STORE_NEW_ANNOTATION_IDS,
                    newAnnotId: { 'annotId': resp.annotationId, 'layerId': annotationdataProto.layerID, deleted: false, createdDate: annotationSelected.createdDate, updatedDate: annotationSelected.updatedDate }
                });
            }
            else {
                // re-update the date/time of already drawn annotation on edit - color/stroke change, drag/resize
                const annot = findWhere(allAnnotId, { annotId: resp.annotationId });
                annot.updatedDate = new Date();

                yield put({
                    type: ActionTypes.STORE_ANNOTATION_IDS,
                    allAnnotId: [...allAnnotId]
                });
            }
            if (!AnnotationUtil.isAnnotationsTabActive()) {
                yield put({
                    type: ActionTypes.CLEAR_SELECTED_ANNOTATIONS
                })
            }
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsText.js, saveTextEscEvent ${error}`);
    }
}
function* handleClick({ e }) {
    try {
        let atext = null;
        let isDrawing = yield select(getAnnotationIsDrawing);
        let annotSelectedId = yield select(getAnnotationSelectedId);
        const texts = yield select(getText);
        const targetLayer = yield select(getTargetLayer);
        const annotationMenu = yield select(getAnnotationMenu);
        const textShape = texts.find((itm) => itm.id === annotSelectedId);
        if (isDrawing) {
            isDrawing = !isDrawing
            atext = { ...textShape, isEditable: !isDrawing }

            yield call(updateText, isDrawing, atext);
            // yield call(saveText);
            return;
        }

        if (textShape && (isNaN(textShape.id) || textShape.isEditable)) {
            yield call(saveTextEscEvent);
            return;
        }

        if (e.target !== e.target.getStage()) {
            annotSelectedId = e.target.id();
            atext = texts.find((itm) => itm.id === annotSelectedId);
            yield call(updateText, isDrawing, atext);
            return;
        }

        const stage = e.target.getStage();
        const stageBox = stage.getContainer().getBoundingClientRect();
        const pos = {
            x: e.evt.layerX + stageBox.left,
            y: e.evt.layerY + stageBox.top
        };
        const points = new GraphicPoints();
        const annotId = TemporaryId.Id + texts.length;

        atext = {
            ...annotationMenu.TextModelInfo,
            x: e.evt.layerX,
            y: e.evt.layerY,
            endMouseX: 0,
            endMouseY: 0,
            txtX: pos.x,
            txtY: pos.y,
            points: points,
            width: 0,
            height: 0,
            stroke: 4,
            isShow: false,
            isEditable: false,
            text: '',
            type: RayAnnotationType.TEXT_AnnotationType,
            id: annotId,
            layerID: targetLayer.layerID,
            isReadOnlyAnnotation: false
        }
        isDrawing = true;
        yield call(addText, isDrawing, atext);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsText.js, handleClick ${error}`);
    }
}


function* updateAnnotationId(annotation, tempId, annotResId) {
    try {
        annotation.forEach((item) => {
            if (item.id === tempId) {
                item.id = annotResId;
                item.isEditable = false
            }
        });
        return annotation;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTextSaga.js, updateAnnotationId ${error}`);
    }
}
async function upserttext(annotationdataProto) {
    const resp = await annotationsApi.UpsertAnnotation(annotationdataProto);
    return resp;
}

function* handleMouseMove({ e }) {
    try {

        let atext = null;
        const texts = yield select(getText);
        const isDrawing = yield select(getAnnotationIsDrawing);
        const annotSelectedId = yield select(getAnnotationSelectedId);

        if (isDrawing && texts.length > 0) {

            const textShape = texts.find((itm) => itm.id === annotSelectedId);

            // let newWidth = e.evt.layerX - textShape.x;
            const newHeight = e.evt.layerY - textShape.y;
            let points = textShape.points;

            let pos = {
                x: textShape.txtX,
                y: textShape.txtY
            };
            let newWidth = textShape.width;

            const stage = e.currentTarget.getStage();
            const stageBox = stage.getContainer().getBoundingClientRect();
            // if start is greater than greater than current mouse 
            if (textShape.x >= e.evt.layerX) {
                newWidth = textShape.x - e.evt.layerX;
                pos = {
                    x: e.evt.layerX + stageBox.left,
                    y: textShape.txtY
                };
            }
            // if start position is lesser than current mouse
            if (textShape.x <= e.evt.layerX) {
                newWidth = e.evt.layerX - textShape.x;
                pos = {
                    x: textShape.x + stageBox.left,
                    y: textShape.txtY
                };
            }

            points = AnnotationUtil.getAnchorPoints(newWidth, newHeight, RayAnnotationType.TEXT_AnnotationType, points);
            atext = Object.assign(textShape, {
                x: textShape.x,
                y: textShape.y,
                txtX: pos.x,
                txtY: pos.y,
                endMouseX: e.evt.layerX,
                endMouseY: pos.y,
                points: points,
                width: newWidth,
                // height: newHeight, 
                stroke: 4,
                isEditable: false,
                text: '',
                isShow: false,
                type: RayAnnotationType.TEXT_AnnotationType,
                id: textShape.id,
                textHeight: 0
            })
        }
        yield call(updateText, isDrawing, atext);
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsText.js, handleMouseMove ${error}`);
    }
}

function* addText(isDrawing, atext) {
    try {
        yield put({
            type: ActionTypes.HANDLE_TEXT_CLICK_SUCCESS,
            text: atext,
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: atext
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsText.js, addText ${error}`);
    }
}

function* updateText(isDrawing, atext) {

    try {
        yield put({
            type: ActionTypes.HANDLE_TEXT_DRAW_SUCCESS,
            text: atext
        })
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: atext
        });

        let layersList = yield select(getLayersList);
        layersList = JSON.parse(JSON.stringify(layersList));
        const selectedAnnot = yield select(getAnnotationSelected);
        for (const x in layersList) {
            layersList[x].layerID === selectedAnnot.layerID ? layersList.push(layersList.splice(x, 1)[0]) : 0;
        }
        yield put({
            type: ActionTypes.UPDATE_LAYERS_LIST,
            LayersList: layersList
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsText.js, updateText ${error}`);
    }
}
function* saveText() {
    try {
        yield put({
            type: ActionTypes.HANDLE_SAVE_ANNOTATION
        });
        if (!AnnotationUtil.isAnnotationsTabActive()) {
            yield put({
                type: ActionTypes.CLEAR_SELECTED_ANNOTATIONS
            })
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsText.js, saveRectangle ${error}`);
    }
}
function* handleTextModify({ e, isSave }) {
    try {
        const txtModified = e.target.value;
        let atext = null;
        const annotDim = yield select(getAnnotationDimension);
        const texts = yield select(getText);
        const isDrawing = yield select(getAnnotationIsDrawing);
        const annotSelectedId = yield select(getAnnotationSelectedId);

        if (texts.length > 0) {
            const textShape = texts.find((itm) => itm.id === annotSelectedId);


            let width = textShape.endMouseX - textShape.x;
            if (textShape.endMouseX <= textShape.x) {
                width = textShape.x - textShape.endMouseX;
            }

            let height = textShape.fontSize;
            let rows = 0;
            const splitText = txtModified.split('\n');

            for (let i = 0; i < splitText.length; i++) {
                const txtWidth = textWidth(splitText[i].toString(), {
                    family: textShape.fontStyle,
                    size: textShape.fontSize,
                    weight: textShape.isBold ? "bold" : ''
                });
                if (txtWidth > (width - 6)) {
                    const extraRows = Math.ceil(txtWidth / (width - 6));
                    if (extraRows > 1) {
                        rows += extraRows;
                    }
                } else {
                    rows += 1;
                }
            }
            height = height * rows;
            let textHeight = height;
            if (annotDim.height < height + textShape.y) {
                textHeight = annotDim.height - textShape.y;
            }
            atext = { ...textShape, isEditable: !isSave, isShow: !isDrawing, text: txtModified, height: height, rows: rows, textHeight: textHeight }
            yield call(updateText, isDrawing, atext);
            if (isSave) {
                if (atext.text.length > 0) {
                    yield call(saveText);
                } else {
                    yield put({
                        type: ActionTypes.REMOVE_TEMP_TEXT_ANNOTATION,
                        annotRemoveId: textShape.id
                    });
                }
            }
        }
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsText.js, handleTextModify ${error}`);
    }
}

function* processTextData({ data }) {
    try {
        let width = data.endPoint.x - data.startPoint.x;
        if (data.endPoint.x <= data.startPoint.x) {
            width = data.startPoint.x - data.endPoint.x;
        }

        let height = data.textSize;
        let rows = 0;
        const splitText = data.text.split('\n');

        for (let i = 0; i < splitText.length; i++) {
            const txtWidth = textWidth(splitText[i].toString(), {
                family: data.font,
                size: data.textSize,
                weight: data.isBold ? "bold" : ''
            });
            if (txtWidth > (width - 6)) {
                const extraRows = Math.ceil(txtWidth / (width - 6));
                if (extraRows > 1) {
                    rows += extraRows;
                }
            } else {
                rows += 1;
            }
        }
        height = height * rows;
        const convertedTextColor = ThemeHelper.convertArgbToHex(data.textColor);
        const convertedBackgroundColor = ThemeHelper.convertArgbToHex(data.backgroundColor);
        const convertedForegroundColor = ThemeHelper.convertArgbToHex(data.foregroundColor);
        const layersList = yield select(getLayersList);
        const isReadOnlyAnnotation = layersList.filter((i) => i.layerID === data.layerID)[0].shareAccess === ShareAccessType.SHARE_READONLY;

        /* commented lines may have use when importing desktop annotations 
        dont delete */
        const text = {
            id: data.annotationID,
            layerID: data.layerID,
            type: data.annotationType,
            x: data.startPoint.x,// < data.endPoint.x ? data.startPoint.x: data.endPoint.x,
            y: data.startPoint.y,
            startMouseX: 0,
            startMouseY: 0,
            isSelected: false,
            endMouseX: data.endPoint.x,// > data.startPoint.x ? data.endPoint.x : data.startPoint.x,
            endMouseY: data.endPoint.y,
            isTemporaryChecked: false,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            isDeleted: false,
            points: new GraphicPoints(),
            text: data.text,
            isItalic: data.isItalic,
            isUnderline: data.isUnderline,
            isBold: data.isBold,
            color: `P${convertedTextColor.color.substring(1)}`,
            bgColor: `P${convertedBackgroundColor.color.substring(1)}`,
            opacity: convertedBackgroundColor.opacity,
            borderColor: `P${convertedForegroundColor.color.substring(1)}`,
            textAlign: data.textAlignment,
            fontSize: data.textSize,
            width: width, //data.endPoint.x - data.startPoint.x,
            borderWeight: data.penSize,
            strokeStyle: data.strokeType,
            isEditable: false,
            isBorderChecked: data.isBorderVisible,
            fontStyle: data.font,
            height: height,
            rows: rows,
            isReadOnlyAnnotation: isReadOnlyAnnotation
        }

        yield put({
            type: ActionTypes.HANDLE_TEXT_CLICK_SUCCESS,
            text: text
        });
        yield put({
            type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
            annotation: text.id
        });

        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTextSaga.js, processTextData ${error}`);
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        PrintMode.printing && console.log("PRINT:--Tracker--*** Data processing Error in GraphicsTextSaga *** for -", TimeTrackingWindow.currentSymbol);
    }
}
function* handleAnchorDrag({ e }) {
    try {
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const lines = yield select(getText);
        const activeAnchor = yield select(getActiveAnchor);
        const chartDimension = yield select(getAnnotationDimension);

        // Fix for PANWEB-6207
        if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
            return;
        }

        let text = null;
        let textEnd = null;
        let textStart = null;
        const curText = lines.find((itm) => itm.id === annotSelectedId);

        if (e.currentTarget !== undefined && e.currentTarget !== null) {
            e.currentTarget.getStage().container().style.cursor = 'ew-resize';
        }

        if (activeAnchor === "start") {
            textStart = {
                x: e.evt.layerX,
                y: curText.y
            }
            textEnd = {
                x: curText.endMouseX,
                y: curText.endMouseY
            }
        }
        if (activeAnchor === "end") {
            textStart = {
                x: curText.x,
                y: curText.y
            }
            textEnd = {
                x: e.evt.layerX,
                y: e.evt.layerY
            }
        }
        let width = curText.width;
        if (textStart.x >= textEnd.x) {
            width = textStart.x - textEnd.x;
        }
        if (textStart.x <= textEnd.x) {
            width = textEnd.x - textStart.x;
        }
        let height = curText.fontSize;
        let rows = 0;
        const splitText = curText.text.split('\n');

        for (let i = 0; i < splitText.length; i++) {
            const txtWidth = textWidth(splitText[i].toString(), {
                family: curText.fontStyle,
                size: curText.fontSize,
                weight: curText.isBold ? "bold" : ''
            });
            if (txtWidth > (width - 6)) {
                const extraRows = Math.ceil(txtWidth / (width - 6));
                if (extraRows > 1) {
                    rows += extraRows;
                }
            } else {
                rows += 1;
            }
        }
        height = height * rows;

        text = Object.assign(curText, {
            x: textStart.x,
            y: textStart.y,
            endMouseX: textEnd.x,
            endMouseY: textEnd.y,
            id: curText.id,
            width: width,
            height: height,
            rows: rows
        });
        yield call(updateText, false, text);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTextSaga.js, handleAnchorDrag ${error}`);
    }
}
function* handleAnchorLeave() {
    yield call(saveText);
}

function* handleTextDoubleClick({ e }) {
    try {
        const annotSelectedId = yield select(getAnnotationSelectedId);
        let text = null;
        const lines = yield select(getText);
        const curText = lines.find((itm) => itm.id === annotSelectedId);
        if (curText.isReadOnlyAnnotation) {
            return;
        }
        const annotDim = yield select(getAnnotationDimension);

        const stage = e.target.getStage();
        const stageBox = stage.getContainer().getBoundingClientRect();
        let pos = {
            x: curText.x + stageBox.left,
            y: curText.y + stageBox.top
        };
        if (curText.x > curText.endMouseX) {
            pos = {
                x: curText.endMouseX + stageBox.left,
                y: curText.y + stageBox.top
            };
        }
        let textHeight = curText.height;
        if (annotDim.height < curText.height + curText.y) {
            textHeight = annotDim.height - curText.y;
        }
        text = Object.assign(curText, {
            isEditable: true,
            txtX: pos.x,
            txtY: pos.y,
            textHeight: textHeight
        });

        yield call(updateText, false, text);

    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTextSaga.js, handleTextDoubleClick ${error}`);
    }
}
function* handleAddBorder() {
    try {
        const annotSelectedId = yield select(getAnnotationSelectedId);
        let text = null;
        const allTexts = yield select(getText);
        const curText = allTexts.find((itm) => itm.id === annotSelectedId);

        text = Object.assign(curText, {
            isBorderChecked: true
        });

        const isDrawing = yield select(getAnnotationIsDrawing);
        yield call(updateText, isDrawing, text);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTextSaga.js, handleAddBorder ${error}`);
    }
}
function* handleRemoveBorder() {
    try {
        const annotSelectedId = yield select(getAnnotationSelectedId);
        let text = null;
        const allTexts = yield select(getText);
        const curText = allTexts.find((itm) => itm.id === annotSelectedId);

        text = Object.assign(curText, {
            isBorderChecked: false
        });

        const isDrawing = yield select(getAnnotationIsDrawing);
        yield call(updateText, isDrawing, text);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTextSaga.js, handleRemoveBorder ${error}`);
    }
}
function* reCalculateHeight() {
    try {
        let text = null;
        const texts = yield select(getText);
        const annotSelectedId = yield select(getAnnotationSelectedId);
        if (texts.length > 0) {
            const currentText = texts.find((itm) => itm.id === annotSelectedId);

            let height = currentText.fontSize;
            let rows = 0;
            const width = currentText.width;
            const splitText = currentText.text.split('\n');

            for (let i = 0; i < splitText.length; i++) {
                const txtWidth = textWidth(splitText[i].toString(), {
                    family: currentText.fontStyle,
                    size: currentText.fontSize,
                    weight: currentText.isBold ? "bold" : ''
                });
                if (txtWidth > (width - 6)) {
                    const extraRows = Math.ceil(txtWidth / (width - 6));
                    if (extraRows > 1) {
                        rows += extraRows;
                    }
                } else {
                    rows += 1;
                }
            }
            height = height * rows;
            text = Object.assign(currentText, {
                width: width,
                height: height,
                rows: rows
            });
            const isDrawing = yield select(getAnnotationIsDrawing);
            yield call(updateText, isDrawing, text);

        }

    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTextSaga.js, reCalculateHeight ${error}`);
    }
}
function* handleTextDrag({ e }) {
    try {
        const { isDragging } = yield select(getAnnotationChartDraw);
        const chartDimension = yield select(getAnnotationDimension);
        let text = null;
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const texts = yield select(getText);
        const curText = texts.find((itm) => itm.id === annotSelectedId); // current text to be modified
        let lineStart = {}, lineEnd = {};
        if (isDragging) {
            const dragPos = {
                x: e.currentTarget.x(), y: e.currentTarget.y()
            }
            const diff = { x: dragPos.x - curText.x, y: dragPos.y - curText.y };

            // Fix for PANWEB-6190
            if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                e.currentTarget.attrs.x = curText.x;
                e.currentTarget.attrs.y = curText.y;
                return;
            }
            else if (curText.x <= curText.endMouseX) {
                lineStart = {
                    x: dragPos.x, y: dragPos.y
                }
                lineEnd = {
                    x: curText.endMouseX + diff.x, y: curText.endMouseY + diff.y
                }
            } else {
                lineStart = {
                    x: curText.endMouseX + diff.x, y: curText.endMouseY + diff.y
                }
                lineEnd = {
                    x: dragPos.x, y: dragPos.y
                }
            }
        }

        text = Object.assign(curText, {
            x: lineStart.x,
            y: lineStart.y,
            endMouseX: lineEnd.x,
            endMouseY: lineEnd.y
        });
        yield call(updateText, false, text);
    } catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsTextSaga.js, handleTextDrag ${error}`);
    }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/


export function* watchHandleTextClick() {

    yield takeLatest(ActionTypes.HANDLE_TEXT_CLICK, handleClick);
};

export function* watchHandleTextDraw() {
    yield takeLatest(ActionTypes.HANDLE_TEXT_DRAW, handleMouseMove);
};

export function* watchUpdateText() {
    yield takeLatest(ActionTypes.HANDLE_TEXT_MODIFIED, handleTextModify);
};

export function* watchProcessTextData() {
    yield takeEvery(ActionTypes.PROCESS_TEXT_DATA, processTextData);
};

export function* watchTextAnchordrag() {
    yield takeLatest(ActionTypes.HANDLE_TEXT_ANCHOR_DRAG, handleAnchorDrag);
};

export function* watchTextAnchorLeave() {
    yield takeLatest(ActionTypes.HANDLE_TEXT_ANCHOR_LEAVE, handleAnchorLeave);
};

export function* watchTextDoubleClick() {
    yield takeLatest(ActionTypes.HANDLE_TEXT_DOUBLE_CLICK, handleTextDoubleClick);
};

export function* watchAddBorder() {
    yield takeLatest(ActionTypes.ADD_TEXT_BORDER, handleAddBorder);
}

export function* watchRemoveBorder() {
    yield takeLatest(ActionTypes.REMOVE_TEXT_BORDER, handleRemoveBorder);
}

export function* watchSettingsChange() {
    yield takeLatest(ActionTypes.UPDATE_TEXT_HEIGHT, reCalculateHeight);
};

export function* wathTextDrag() {
    yield takeLatest(ActionTypes.HANDLE_TEXT_DRAG, handleTextDrag)
}

export function* watchEscPress() {
    yield takeLatest(ActionTypes.HANDLE_ESC_AFTER_EDIT, saveTextEscEvent);
}
/******************************************************************************/