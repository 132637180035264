import React, { Component } from "react";
import { Modal, FormControl } from "react-bootstrap";
import AlertStore from "../../Stores/Alerts/AlertStore.js";
import BaseServiceApi from "BaseServiceApi";
import HttpMethodType from "HttpMethodType";
import StringUtil from "StringUtil";
import AlertActions from "../../Actions/AlertActions.js";
import LocalizationStore from 'LocalizationStore';


export default class ValidationPendingDialog extends Component {
    constructor(props) {
        super(props);
        this.hideDialog = this.hideDialog.bind(this);
        this.addNewEmail = this.addNewEmail.bind(this);
        this.state = {
            alertNotificationSettings: AlertStore.getNotificationSettings()
        }

        this.resendValidationEmail = this.resendValidationEmail.bind(this);
    }


    hideDialog() {
        this.props.hideAddEmailValidationDialog();
    }

    addNewEmail() {
        this.props.OpenAddNewEmailDialog();
    }

    resendValidationEmail() {
        let value = this.state.alertNotificationSettings.email;
        let methodType = StringUtil.isEmpty(value) ? HttpMethodType.DELETE : HttpMethodType.POST
        AlertActions.saveNotificationSubscription(value, BaseServiceApi.rayData["NotificationType"].EMAIL, methodType, true);
        this.hideDialog();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.open != nextProps.open && nextProps.open == true) {
            this.setState({
                alertNotificationSettings: AlertStore.getNotificationSettings()
            });
        }
    }

    render() {
        return (
            <div id="validationPendingDialog">
                <Modal className="modal-popup wide verification" show={this.props.open}>
                    <Modal.Body>
                        <div className="modal-region">
                            <div className="verify-credential email">
                                <h6>{LocalizationStore.getTranslatedData('set_pre_nap_vpf', 'Validation is pending for:')}</h6>
                                <h6>{this.state.alertNotificationSettings.email}</h6>
                                <p>{LocalizationStore.getTranslatedData('set_pre_nap_vpdt', 'To complete the validation process, click the link in the email that was sent to this address.')}</p>  
                                <p><a href="javascript:void('0');" onClick={this.resendValidationEmail} > {LocalizationStore.getTranslatedData('set_pre_nap_rve', 'Resend Validation Email')}</a></p>
                                <p><a href="javascript:void('0');" onClick={this.addNewEmail}>{LocalizationStore.getTranslatedData('set_pre_nap_udea', 'Use Different Email Address')}</a></p>
                            </div>
                            <div className="box clear"></div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.hideDialog}>{LocalizationStore.getTranslatedData('Close', 'Close')}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}