import keyMirror from "keymirror";

export const IndicatorsConstants = {
  ActionTypes: keyMirror({
    RESET_INDICATOR_STATE: null,
    INIT_INDICATOR: null,
    PROCESS_INDICATOR: null,
    INIT_INDICATOR_STATE: null,
    UPDATE_INDICATOR_DIMENSION: null,
    PREPARE_INDICATOR_DATA: null,
    PREPARE_FUNDAMENTAL_INDICATOR_DATA: null,
    PREPARE_ORating_INDICATOR_DATA: null,
    PREPARE_AD_INDICATOR_DATA: null,
    PREPARE_MACD_INDICATOR_DATA: null,
    PREPARE_RSI_INDICATOR_DATA: null,
    PREPARE_STATS_INDICATOR_DATA: null,
    PREPARE_YTD_INDICATOR_DATA: null,
    UPDATE_INDICATOR_SETTINGS: null,
    UPDATE_INDICATOR_GRAPH_DATA: null,
    UPDATE_INDICATOR_RELATIVE_DATA: null,
    UPDATE_INDICATOR_ABSOLUTE_DATA: null,
    UPDATE_INDICATOR_DATA: null,
    UPDATE_INDICATOR_SCALE: null,
    TOGGLE_INDICATOR_CHART: null,
    TOGGLE_FUNDAMENTAL_LINE: null,
    UPDATE_INDICATOR_MENU: null,
    SAVE_INDICATOR_TOGGLE_SECTION: null,
    PREPARE_INDICATOR_MENU: null,
    OPEN_COLLAPSE_INDICATOR_BLOCK: null,
    SORT_INDICATOR_SECTION: null,
    SORT_INDICATOR: null,
    GET_ALL_INDICATOR_DATA: null,
    UPDATE_INDICATOR_RAW_DATA: null,
    UPDATE_IS_ALL_INDICATOR_API_INITIATED: null,
    TOGGLE_ORATING_LINE: null
  })
};

export const indicatorNameConst = {
  FM: "Fundamental Metrics",
  KPI: "Key Performance Indicators",
  OR: "O'Neil Ratings",
  TI: "Technical Indicators",
  PM: "Performance Metrics",
  ED: "External Data"
}

export const blockMenuTypes = {
  FM: "FM",
  KPI: "KPI",
  OR: "OR",
  TI: "TI",
  PM: "PM",
  ED: "ED"
}

export const indicatorCommonConst = {
  Absolute: "Absolute",
  Relative: "Relative",
  Year: "Year",
  SymbolType_INDEX: "SymbolType_INDEX",
  SDRating: "SDRating",
}

export const IndicatorLineConst = {
  EPSR_CURR_FIS_QTR: "EPSR_CURR_QTR",
  EPSR_CURR_FIS_YR: "EPSR_CURR_YR",
  EPSR_NXT_FIS_YR: "EPSR_NXT_FIS_YR",
}

export const IndicatorContextConst = {
  Indicator_Panel: "Technical_Indicator",
  Indicators: "Indicators",
  External_Indicators: "External_Indicators",
  Indicator_Lines: "Indicator_Lines",
  Indicator_Index_Line: "Indicator_Index_Line"
}