import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import AlertStore from "AlertStore";
import AlertActions from 'AlertActions';
import { find, map } from "underscore";
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import AlertColumnSetDetails from './AlertColumnSetDetails.jsx';
import LocalizationStore from 'LocalizationStore';

export default class AlertColumnSet extends Component {
    constructor(props) {
        super(props);

        // this.alertStoreStateChange = this.alertStoreStateChange.bind(this);
        this.onColumnSetFolderChange = this.onColumnSetFolderChange.bind(this);
        this.onColumnSetChange = this.onColumnSetChange.bind(this);
        this.toggleDetailDailog = this.toggleDetailDailog.bind(this);
        this.handleAlertDocumentOutsideClick = this.handleAlertDocumentOutsideClick.bind(this);
        this.handleMouseDownColumnsetModal = this.handleMouseDownColumnsetModal.bind(this);
        this.handleMouseuPColumnsetModal = this.handleMouseuPColumnsetModal.bind(this);
        this.renderColumnSet = this.renderColumnSet.bind(this);

        this.columnSet = AlertStore.getColumnSet();
        this.selectedColumnSet = AlertStore.getSelectedColumnSet();
        this.columnSetDetails = null;

        this.mouseDownOnColumnsetModal = false;
        this.mouseUpOnColumnsetModal = false;
        this.parentColumnSetName = AlertStore.getParentColumnSetName();
        this.columnsetChildItems = AlertStore.getColumnsetChildItems();

        this.state = {
            toggleDetailDailog: false,
            loading: false
        }
    }

    componentDidMount() {
        this.parentColumnSetName = AlertStore.getParentColumnSetName();
        this.columnsetChildItems = AlertStore.getColumnsetChildItems();

        if (!(StringUtil.isEmpty(this.parentColumnSetName) || StringUtil.isEmpty(this.columnsetChildItems))) {
            this.loadColumnSet(null, false);
        }

        // AlertStore.addChangeListener(this.alertStoreStateChange);
        document.addEventListener('click', this.handleAlertDocumentOutsideClick, false);
    }

    componentWillUnmount() {
        //AlertStore.removeChangeListener(this.alertStoreStateChange);
        document.removeEventListener('click', this.handleAlertDocumentOutsideClick, false);
    }

    handleAlertDocumentOutsideClick(e) {
        let columnSetDetailsHeader = document.getElementById("alertColumnSetDetailsModalHeader");
        let columnSetDetailsBody = document.getElementById("alertColumnSetDetailsModalBody");
        let target = e.target || e.srcElement;
        let wasDown = this.mouseDownOnColumnsetModal;
        let wasUp = this.mouseUpOnColumnsetModal;
        this.mouseDownOnColumnsetModal = false;
        this.mouseUpOnColumnsetModal = false;

        if (!wasDown && !wasUp) {
            if ((columnSetDetailsHeader || columnSetDetailsBody) && !(columnSetDetailsHeader.contains(target) || columnSetDetailsBody.contains(target))) {
                this.props.closeColumnSetWindow();
            }
            else if (!(columnSetDetailsHeader && columnSetDetailsBody) && target.id != 'alertColumnSetBackButton') {
                this.props.closeColumnSetWindow();
            }
        }

        e.stopPropagation();
    }

    handleMouseDownColumnsetModal() {
        this.mouseDownOnColumnsetModal = true;
    }

    handleMouseuPColumnsetModal() {
        this.mouseUpOnColumnsetModal = true;
    }

    // alertStoreStateChange(e) {
    //     this.columnSet = AlertStore.getColumnSet();
    //     this.selectedColumnSet = AlertStore.getSelectedColumnSet();
    // }

    loadColumnSet(columnSetDetails, isFolderClick) {

        if (isFolderClick) {
            AlertStore.getColsetChildren(columnSetDetails);
            this.parentColumnSetName = columnSetDetails.colsetName;
        }

        this.setState({
            toggleDetailDailog: true
        })

        this.columnSetDetails =
            <AlertColumnSetDetails //columnSetDetails={columnSetDetails}
                parentColumnSetName={this.parentColumnSetName}
                selectedColumnSet={this.selectedColumnSet}
                toggleDetailDailog={this.toggleDetailDailog}
                closeColumnSetWindow={this.props.closeColumnSetWindow}
                columnSetId={this.selectedColumnSet.colsetId}
                left={this.props.left} top={this.props.top} />
    }

    onColumnSetFolderChange(e) {
        let nodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');
        let columnSetDetails = find(this.columnSet, (column) =>
            parseInt(nodeId) === parseInt(column.colsetId)
        );

        this.loadColumnSet(columnSetDetails, true);
    }

    onColumnSetChange(e) {
        let nodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');
        let columnSetDetails = find(this.columnSet, (column) =>
            parseInt(nodeId) === parseInt(column.colsetId)
        );

        this.props.closeColumnSetWindow();

        AlertActions.selectColumnSet(columnSetDetails, '');
    }

    toggleDetailDailog() {
        this.setState({
            toggleDetailDailog: !this.state.toggleDetailDailog
        })
        this.columnSetDetails = '';
    }

    renderColumnSet() {
        let self = this;

        return (<ul className="list-group">
                    {
                        map(this.columnSet, (column, key) => {
                            let activeClass = (!StringUtil.isEmpty(self.selectedColumnSet) && self.selectedColumnSet.colsetId == column.colsetId) ? 'active' : '';
                            let activeNode = (!StringUtil.isEmpty(self.selectedColumnSet) && self.selectedColumnSet.colsetId == column.colsetId) ? <span className="icn-right-icon"></span> : '';

                            let columnName = column.isShared ? "Shared - " + column.colsetName : column.colsetName;

                            if (column.isFolder == true) { // Folder
                                return (
                                    <li key={key} className="list-group-item">
                                        <a className="panal-a medium-normal" href="javascript:void(0);" onClick={(e) => self.onColumnSetFolderChange(e)} data-node-id={column.colsetId}>
                                            <span className="icn-folder-view"></span>
                                            {LocalizationStore.getTranslatedData("ColumnSet_"+columnName,columnName)}
                                        </a>
                                    </li>
                                );
                            }
                            else { // COLUMNSET
                                return (
                                    <li key={key} className={"list-group-item " + activeClass}>
                                        <a className="panal-a medium-normal" href="javascript:void(0);" onClick={(e) => self.onColumnSetChange(e)} data-node-id={column.colsetId} title={columnName}>
                                            {activeNode}
                                            <span className={column.isShared ? "icn-share pull-left" : "icn-fa-file-text-p pull-left marr0"}></span>
                                            {columnName == "No Column Set" ? LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set") : LocalizationStore.getTranslatedData("ColumnSet_"+columnName,columnName)}
                                        </a>
                                        <span className="dropdown-panel-custom nopad" id={"dropdownSpan" + key}></span>
                                    </li>
                                );
                            }
                        })
                    }
            </ul>
        );
    }


    render() {
        let columnSet = this.renderColumnSet();

        return (
            <div id="alertColumnDialog" >
                <Modal className="modal-popup alert-column-dialog alert-columnset" show={true} style={{ left: this.props.left, top: this.props.top }}>
                    <Modal.Header onMouseDown={this.handleMouseDownColumnsetModal} onMouseUp={this.handleMouseuPColumnsetModal}>
                        <Modal.Title><span className="cap-header alert-title">{LocalizationStore.getTranslatedData("LM_TP_SltAColSet","SELECT A COLUMN SET")}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body onMouseDown={this.handleMouseDownColumnsetModal} onMouseUp={this.handleMouseuPColumnsetModal}>
                        <div className="modal-area column-geo-alert custom-scroll-light">
                            <div className="custom-scroll">
                                <div id="alertColumnSetVerticalScroll">
                                    {(this.state.loading ? <div className="group-box"><div className="loading loading-light"></div></div> : columnSet)}
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId="alertColumnSetVerticalScroll" vScroll={true} />
                    </Modal.Body>
                </Modal>
                <div onMouseDown={this.handleMouseDownColumnsetModal} onMouseUp={this.handleMouseuPColumnsetModal}>
                    {this.columnSetDetails}
                </div>
            </div>
        );
    }
}



