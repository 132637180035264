class LessUtil {
   constructor() {
    this.state={};
   }
   modifyVars(newstate) {
     Object.assign(this.state, newstate);
     less.modifyVars(this.state);
     less.refreshStyles();
   }
}

const lessUtils=new LessUtil();
export default lessUtils;