import GetFundamentalReportRequest from 'ServiceApi/Requests/GetFundamentalReportRequest.js'
import GetFundamentalOptionsRequest from 'ServiceApi/Requests/GetFundamentalOptionsRequest.js'
import RatiosReport from 'ServiceApi/Requests/RatiosReport.js'
import GetStockReportRequest from 'ServiceApi/Requests/GetStockReportRequest.js'
import BaseServiceApi from 'BaseServiceApi'
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js'
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";

class FundamentalApi {

  getRequest(fundamentalsSettings) {
    let request = undefined;
    let state = DatagraphStore.getState();
    let isInternational = state.SymbolInfo.CountryCode == 1 ? 0 : 1;
    switch (fundamentalsSettings.FundamentalReportType) {
      case FundamentalTabReportType.Income:
      case FundamentalTabReportType.Balancesheet:
      case FundamentalTabReportType.Cashflow:
        request = new GetFundamentalReportRequest(state.SymbolInfo.Osid, isInternational, fundamentalsSettings.FundamentalReportType,
          fundamentalsSettings.FundamentalSortOrder, fundamentalsSettings.FundamentalFilter,
          fundamentalsSettings.FundamentalTemplate, fundamentalsSettings.FundamentalPeriodicity);
        break;
      case FundamentalTabReportType.Ratios:
        request = new RatiosReport(state.Symbol, fundamentalsSettings.FundamentalPeriodicity, isInternational);
        break;
      case FundamentalTabReportType.Wondastockreport:
        request = new GetStockReportRequest();
        break;
      default:
        break;
    }
    return request;
  }

  getdata(fundamentalSettings) {
    const req = this.getRequest(fundamentalSettings);
    return BaseServiceApi.processServiceCall(req);
  }

  getOptionsData() {
    const req = new GetFundamentalOptionsRequest(DatagraphStore.getState().SymbolInfo.Osid);
    return BaseServiceApi.processServiceCall(req);
  }

  getAllFilterdata(filterType, fundamentalSettings, datagraphSettings) {
    let isInternational = datagraphSettings.SymbolInfo.CountryCode == 1 ? 0 : 1;
    const req = new GetFundamentalReportRequest(datagraphSettings.SymbolInfo.Osid, isInternational, fundamentalSettings.FundamentalReportType,
      fundamentalSettings.FundamentalSortOrder, filterType,
      fundamentalSettings.FundamentalTemplate, fundamentalSettings.FundamentalPeriodicity);
    return BaseServiceApi.processServiceCall(req);
  }
}

var fundamentalApi = new FundamentalApi();

export default fundamentalApi;
