import LangType from "../../../../../Constants/LangType";
import ThemeType from "../../../../../Constants/ThemeTypeConstants";
import { ColorCatalog, DefaultColors, DefaultHoverColors } from "../../../../../Constants/AppColorConstants";

class AppColorSettingHelper {
    constructor() {

    }
    // default postive color is Blue in English, Red in Chinese.
    // default positive color for data table is Blue in English, Blue in Chinese
    getDefaultPosColor(isHoverColor = false, langType = LangType.RAY_DEFAULT, colorCatalog = ColorCatalog.DataGraph, themeType = ThemeType.Dark) {
        let color = DefaultColors.Blue;
        switch (langType) {
            case LangType.ZH_CN:
                if (colorCatalog === ColorCatalog.DataGraph)
                    color = !isHoverColor ? DefaultColors.Red : DefaultHoverColors.Red;
                else
                    color = !isHoverColor ? DefaultColors.Blue : DefaultHoverColors.Blue;
                break;
            default:
                if (colorCatalog === ColorCatalog.DataTable)
                    color = !isHoverColor ? DefaultColors.Blue : DefaultHoverColors.Blue;
                else {
                    if (themeType == ThemeType.Video) {
                        color = !isHoverColor ? DefaultColors.VideoDarkBlue : DefaultHoverColors.OtherDarkBlue;
                    } else {
                        if (themeType == ThemeType.Dark) {
                            color = !isHoverColor ? DefaultColors.OtherDarkBlue : DefaultHoverColors.OtherDarkBlue;
                        } else {
                            color = !isHoverColor ? DefaultColors.OtherLightBlue : DefaultHoverColors.OtherLightBlue;
                        }
                    }
                }
                break;
        }

        return color;
    }

    // default negative color is Magenta in English, Green in Chinese.
    // default negative color for data table is Blue in English, Magenta in Chinese
    getDefaultNegColor(isHoverColor = false, langType = LangType.RAY_DEFAULT, colorCatalog = ColorCatalog.DataGraph, themeType = ThemeType.Dark) {
        let color = DefaultColors.Magenta;
        switch (langType) {
            case LangType.ZH_CN:
                if (colorCatalog === ColorCatalog.DataGraph)
                    color = !isHoverColor ? DefaultColors.Green : DefaultHoverColors.Green;
                else
                    color = !isHoverColor ? DefaultColors.Magenta : DefaultHoverColors.Magenta;
                break;
            default:
                if (colorCatalog === ColorCatalog.DataTable)
                    color = !isHoverColor ? DefaultColors.Magenta : DefaultHoverColors.Magenta;
                else {
                    if (themeType == ThemeType.Dark) {
                        color = !isHoverColor ? DefaultColors.OtherDarkMagenta : DefaultHoverColors.OtherDarkMagenta;
                    }
                    else {
                        color = !isHoverColor ? DefaultColors.OtherLightMagenta : DefaultHoverColors.OtherLightMagenta;
                    }
                }
                break;
        }
        return color;
    }
}


const appColorSettingHelper = new AppColorSettingHelper();
export default appColorSettingHelper;