import BaseServiceApi from 'BaseServiceApi';
import BlockTitleBar from "./BlockTitleBar.jsx";
import BlockType from "BlockType";
import { Button } from "react-bootstrap";
import { connect } from 'react-redux';
import ErrorBoundary from "ErrorBoundary";
import FinancialBlockDialog from './FinancialBlockDialog/FinancialBlockDialog.jsx';
import HeaderCell from "./HeaderCell.jsx";
import LocalizationStore from "LocalizationStore";
import PaginatedGraphView from "./PaginatedGraphView.jsx";
import PaginatedTableView from "./PaginatedTableView.jsx";
import TimeLine from "../../../NavDataGraph/TabDataGraph/TimeLine/TimeLine"
import UserInfoUtil from 'UserInfoUtil';
import { collapseFinancialBlock, onFinancialBlockPeToggle } from '../../../../../Actions/FinancialBlockActions';
import { FinancialTransLateHelper, TranslateHelper } from "../../../../../Utils/TranslateHelper";
import React, { useCallback, useRef } from "react";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const FinancialBlock = (props) => {

    const { posDTColor, negDTColor, isThemeChanged, Height, showDataNotAvailable, PePsData, rightScaleWidth, CurrentPageHeaders, dataAvailable } = props
    //isThemeChanged is for changing theme of this component

    const _headerCell = new Map();
    const LeftColumnWidth = "220px";
    const enableGraph = UserInfoUtil.IsUserEntitled(EntitlementType.CFB_VISUALIZATION_Entitlement);
    const isUserEntitledToSnP = UserInfoUtil.IsUserEntitledtoSnP();
    let pagintedTable = useRef();

    const onMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    const syncHover = (index, hover) => {
        const header = _headerCell.get(index);
        if (pagintedTable) {
            pagintedTable.onHover(index, hover);
        }
        if (header) {
            header.onHover(hover);
        }
    }

    const getTranslatedPePsData = useCallback((label) => {
        if (!isUserEntitledToSnP && label.indexOf('S&P') > -1) {
            label = label;
        } else {
            label = LocalizationStore.getTranslatedData(`FB_${label.replace(/\s/g, '')}`, label);
        }
        if (label.indexOf('Est.') > -1) {
            label = label.replace(' Est.:', FinancialTransLateHelper.EST_FINANCIAL_BLOCK);
        }
        return label;
    }, [PePsData.blockItems])

    const maindivStyle = {
        height: `${Height + 20}px`
    };

    return (
        <div className="symbol-chart-stack symbol-financial-stack" key="financialBlock" data-disable-track-price="true">
            <ErrorBoundary key='StockFinancialBlock'>
                <div onMouseDown={onMouseDown} className="symbol-chart-wrap financial-block-grid-chart" style={maindivStyle}>
                    <div className="financial-stuff">
                        <div className="financial-block-headers">
                            <TimeLine />
                            <BlockTitleBar />
                            {dataAvailable &&
                                <div className="financial-right-heading">
                                    {
                                        <button type="button" onClick={props.onFinancialBlockPeToggle} className="btn btn-xs btn-info">
                                            <div className="text">{PePsData.currentHeaderString}</div>
                                        </button>
                                    }
                                    <div className="financial-value">{PePsData.currentValue}</div>
                                </div>
                            }
                        </div>

                        <div className="financial-data financial-block-data" data-disable-track-price="true">
                            <div className="financial-block-left-panel header-block" id="HeaderDiv" style={{ width: LeftColumnWidth }}>
                                {CurrentPageHeaders && CurrentPageHeaders.map((header, index) => {
                                    const headerRef = `header${index}`;
                                    return <HeaderCell syncHover={syncHover}
                                        ref={(c) => _headerCell.set(index, c)}
                                        key={headerRef}
                                        index={index}
                                        header={header}
                                    />;
                                })}
                                <div className="financial-grids-adjuster"></div>
                            </div>

                            <div className="main-block-carousel financial-block-carousel">
                                <PaginatedTableView
                                    syncHover={syncHover}
                                    ref={(ref) => (pagintedTable = (ref))}
                                />
                                {enableGraph && props?.paginatedTableRendered && <PaginatedGraphView key="paginationView" />}
                            </div>

                            <div className="financial-block-right-panel" style={{ width: rightScaleWidth }}>
                                <div className="financial-report">
                                    <div className="financial-report-hold">
                                        <ul>
                                            {PePsData.blockItems && PePsData.blockItems.map((blockItem, index) =>
                                                <li key={blockItem.Label + index}>
                                                    <span className="head-title">{getTranslatedPePsData(blockItem.Label)}</span>
                                                    <span className="sub-head-title">{blockItem.Value}</span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {showDataNotAvailable &&
                                <div className="data_not_available">
                                    <span>{TranslateHelper.Data_Not_Available}</span>
                                </div>}
                        </div>
                    </div>
                    <FinancialBlockDialog />
                    <Button bsSize="xsmall" onClick={() => props.collapseFinancialBlock(BlockType.Financial, false)} data-disable-track-price="true" className="collapse-block" ><div className="collapse-btn"></div></Button>
                </div>
            </ErrorBoundary></div>
    );
};

const mapStateToProps = (state) => {
    const { Height, showDataNotAvailable, PePsData, rightScaleWidth, CurrentPageHeaders, dataAvailable, paginatedTableRendered } = state.DatagraphReducers.financialBlockReducer;
    const { posDTColor, negDTColor, isThemeChanged } = state.appColor;
    const { volumePercentChange } = state.DatagraphReducers.volumeChartReducer;
    return { volumePercentChange, Height, showDataNotAvailable, PePsData, rightScaleWidth, CurrentPageHeaders, dataAvailable, isThemeChanged, posDTColor, negDTColor, paginatedTableRendered }
}
const mapDispatchToProps = (dispatch) => ({
    onFinancialBlockPeToggle: () => dispatch(onFinancialBlockPeToggle()),
    collapseFinancialBlock: () => dispatch(collapseFinancialBlock(BlockType.Financial, false))
})

export default connect(mapStateToProps, mapDispatchToProps)(FinancialBlock);
