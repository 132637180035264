import CalenderControlReducer from '../../CalenderControl/CalenderControlReducer';
import { combineReducers } from 'redux';
import ContextMenuReducer from './ContextMenuReducer';
import CorpEventsReducer from "../TabDataGraph/PriceMenuItems/CorpEventsReducer";
import DataGraphReducer from './DataGraphReducer';
import FactorResearchReducer from "./FactorResearchReducer";
import FundamentalLineReducer from "../TabDataGraph/PriceMenuItems/FundamentalLineReducer";
import HeaderReducer from './HeaderReducer';
import IndexLineReducer from '../TabDataGraph/PriceMenuItems/IndexLineReducer';
import IndicatorReducer from './Indicators/IndicatorsReducer'
import insiderChartReducer from './InsiderChart'
import MAReducer from "../TabDataGraph/PriceMenuItems/MAReducer";
import financialBlockReducer from './FinancialBlock/FinancialBlock';
import PriceChartReducer from './PriceChartReducer';
import PriceMenuReducer from "../TabDataGraph/PriceMenuItems/PriceMenuReducer";
import PRReducer from "../TabDataGraph/PriceMenuItems/PRReducer";
import RSLineReducer from "../TabDataGraph/PriceMenuItems/RSLineReducer";
import StreetTargetPriceReducer from "../TabDataGraph/PriceMenuItems/StreetTargetPriceReducer";
import SymbolEntryReducer from "./SymbolEntry";
import SymbolViewReducer from './SymbolViewReducer';
import TimeLineReducer from './TimeLineReducer';
import ownershipBlockReducer from './OwnerShipBlock';
import financialDialogBoxReducer from './FinancialBlock/FinancialBlockDialogReducer';
import volumeChartReducer from './VolumeChart';

const DatagraphReducers = combineReducers({
    ownershipBlockReducer,
    financialBlockReducer,
    volumeChartReducer,
    insiderChartReducer,
    DataGraphReducer,
    ContextMenuReducer,
    PriceChartReducer,
    SymbolViewReducer,
    HeaderReducer,
    TimeLineReducer,
    IndexLineReducer,
    RSLineReducer,
    FundamentalLineReducer,
    PRReducer,
    CorpEventsReducer,
    MAReducer,
    PriceMenuReducer,
    CalenderControlReducer,
    IndicatorReducer,
    FactorResearchReducer,
    SymbolEntryReducer,
    StreetTargetPriceReducer,
    financialDialogBoxReducer
});

export default DatagraphReducers;