import TimeLine from './TimeLine.js';
import LocalizationStore from "LocalizationStore";

class DailyTimeLine extends TimeLine {
    Padding(quarters, xDate, endDate, targetDate = null) {
        var curDate;
        var i;
        if (quarters == 0) {
            curDate = new Date(xDate);
            if (this.padding) {
                for (i = 0; i < 4; i++) {
                    curDate.setDate(curDate.getDate() + 1);
                    if (curDate.getDay() == 6)
                        curDate.setDate(curDate.getDate() + 2);
                    if (curDate.getDay() == 0)
                        curDate.setDate(curDate.getDate() + 1);
                }
            }
        }
        else {
            curDate = new Date(new Date(xDate).setMonth(xDate.getMonth() + (3 * quarters)));

            if (endDate > curDate)
                curDate = new Date(endDate);
            for (i = 0; i < 4; i++) {
                curDate.setDate(curDate.getDate() + 1);
                if (curDate.getDay() == 6)
                    curDate.setDate(curDate.getDate() + 2);
                if (curDate.getDay() == 0)
                    curDate.setDate(curDate.getDate() + 1);
            }
        }
        //PANWEB-5057
        return targetDate !== null && curDate.getTime() < targetDate.getTime() ? targetDate : curDate;
    }

    SetMCVerticalGrid(startTime, endDate, mtDates, EarnReportDate, FCQtrEstCount) {
        var calQtrs = [3, 6, 9, 12];
        var f1Qtrs = [1, 4, 7, 10];
        var f2Qtrs = [2, 5, 8, 11];
        var monthNames = ["   ",
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct",
            "Nov", "Dec"
        ];
        if (endDate.Year < 1800)
            return;

        var startDate = startTime;
        var quarters = 1;
        var padDate = new Date(endDate);

        if (quarters > 0 && FCQtrEstCount > 0 && EarnReportDate.getFullYear() > 1900)
            padDate = new Date(EarnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 4);
        }

        var pDate = new Date(this.Padding(quarters, padDate, endDate));
        pDate.setDate(pDate.getDate() + 4);
        var cDate = new Date(pDate);
        var x = 0;

        while (cDate >= startDate) {
            var xDate = new Date(cDate);

            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsMonthLabel: false
            };

            mtDates[x] = info;

            var cMonth = cDate.getMonth() + 1;
            var pMonth = pDate.getMonth() + 1;

            if (pMonth != cMonth) {
                var yr = cDate.getFullYear();
                cMonth = cDate.getMonth() + 1;
                var yDateStr = monthNames[cMonth];

                if (pDate.getYear() != cDate.getYear())
                    yDateStr = monthNames[cMonth] + " " + yr;

                mtDates[x].XLabel = yDateStr;

                if (calQtrs[super.Qtr(cMonth)] == cMonth) {
                    mtDates[x].XLine = 3;
                    mtDates[x].IsMonthLabel = true;
                    mtDates[x].IsCalendarLabel = true;
                }
                if (f1Qtrs[super.Qtr(cMonth)] == cMonth)
                    mtDates[x].IsFiscal1Label = true;
                if (f2Qtrs[super.Qtr(cMonth)] == cMonth)
                    mtDates[x].IsFiscal2Label = true;

                pDate = new Date(cDate);
            }

            x++;
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() == 6)
                cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() == 0)
                cDate.setDate(cDate.getDate() - 2);
        }
    }
    SetVerticalGrid(startTime, endDate, timeLineData, EarnReportDate, quarters = 1, mutualFunds = false, videoMode = false, targetDate = null) {
        let mtDates = timeLineData.dates;
        var calQtrs = [3, 6, 9, 12];
        var f1Qtrs = [1, 4, 7, 10];
        var f2Qtrs = [2, 5, 8, 11];
        var monthNames = ["   ",
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct",
            "Nov", "Dec"
        ];
        if (endDate.Year < 1800)
            return;
        if (mutualFunds) {}
        var startDate = startTime;
        var padDate = new Date(endDate);

        if (quarters > 0 && EarnReportDate.getFullYear() > 1900)
            padDate = new Date(EarnReportDate);
        else {
            quarters = 0;
            //padDate.setDate(padDate.getDate() + 4);
        }

        var pDate = new Date(this.Padding(quarters, padDate, endDate, targetDate));
        pDate.setDate(pDate.getDate() + 4);
        var cDate = new Date(pDate);
        var x = 0;
        timeLineData.FirstCalLabelIndex = timeLineData.FirstLabelIndex;
        timeLineData.Firstf1LabelIndex = timeLineData.FirstLabelIndex;
        timeLineData.Firstf2LabelIndex = timeLineData.FirstLabelIndex;

        while (cDate >= startDate) {
            var xDate = new Date(cDate);

            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsMonthLabel: false,
                IsFiscalLabel1: false,
                IsFiscalLabel2: false,
                IsCalendarLabel: false
            };

            mtDates[x] = info;

            var cMonth = cDate.getMonth() + 1;
            var pMonth = pDate.getMonth() + 1;

            if (pMonth != cMonth) {
                mtDates[x].XLine = 6;
                var yr = cDate.getFullYear();
                cMonth = cDate.getMonth() + 1;
                const uMonth = videoMode === true ? pMonth : cMonth;
                var yDateStr = LocalizationStore.getTranslatedData("cal_" + monthNames[uMonth], monthNames[uMonth]);

                if (pDate.getYear() != cDate.getYear())
                    yDateStr = LocalizationStore.getTranslatedData("sb_date", "{0} {1}", LocalizationStore.getTranslatedData("cal_Num_" + monthNames[uMonth], monthNames[uMonth]), yr);;

                if (calQtrs[super.Qtr(cMonth)] == cMonth) {
                    mtDates[x].XLine = 2;
                    mtDates[x].IsCalendarLine = true;
                    mtDates[x].IsCalendarLabel = true;
                    if (timeLineData.FirstCalLabelIndex === -1) {
                        timeLineData.FirstCalLabelIndex = x;
                    }
                    if (timeLineData.FirstLabelIndex === -1) {
                        timeLineData.FirstLabelIndex = x
                    }
                }
                if (f1Qtrs[super.Qtr(cMonth)] == cMonth) {
                    mtDates[x].IsFiscalLabel1 = true;
                    if (timeLineData.Firstf1LabelIndex === -1) {
                        timeLineData.Firstf1LabelIndex = x;
                    }
                }
                if (f2Qtrs[super.Qtr(cMonth)] == cMonth) {
                    mtDates[x].IsFiscalLabel2 = true;
                    if (timeLineData.Firstf2LabelIndex === -1) {
                        timeLineData.Firstf2LabelIndex = x;
                    }
                }

                mtDates[x].XLabel = yDateStr;
                mtDates[x].IsMonthLabel = true;
                if (mtDates[x].XLabel !== "") {
                    timeLineData.TimeLineLabel.push(mtDates[x])
                }
                pDate = new Date(cDate);
            }

            if (cDate.getDay() == 5) {
                if (mtDates[x].XLine == 6) {
                    mtDates[x].XLine = 4;
                    mtDates[x].IsCalendarLine = true;
                }
                if (mtDates[x].XLine == 0) {
                    mtDates[x].XLine = 1;
                    mtDates[x].IsCalendarLine = true;
                }
            }

            x++;
            cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() == 6)
                cDate.setDate(cDate.getDate() - 1);
            if (cDate.getDay() == 0)
                cDate.setDate(cDate.getDate() - 2);
        }
    }
}
const dailyTimeLine = new DailyTimeLine();
export default dailyTimeLine;
