import AnnotationUtil from 'Utils/AnnotationUtil';
import { deleteAlert } from '../../../../Actions/AlertActions';
import { find } from "underscore";
import { getDatagraphAlertStates } from '../../../../Reducers/Alert/selector';
import moment from "moment";
import { PrintMode } from '../../../../print/printmode';
import { safe } from '../../../ErrorModel';
import { ShareAccessType } from '../../../../Constants/ShareConstants';
import { StrokeStyleType } from 'Constants/EAnnotationType';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { AnnotationConstant, TemporaryId } from "Constants/AnnotationConstants.js";
import {
    getAnnotationChartDraw, getAnnotationIsDrawing, getAnnotationSelectedId, getLine, getAnnotationDimension,
    getAnnotationMenu, getActiveAnchor, getTargetLayer, getLayersList, getAnnotationSelected, getSelectedAnnotId
} from '../../../../Reducers/AnnotationReducers/selectors';
import { takeLatest, select, put, call, takeEvery } from 'redux-saga/effects';

const { ActionTypes } = AnnotationConstant;
/********************** Model Logic *************************/
let snapHigh = false, snapLow = false;

function* handleClick({ e }) {
    try {
        let line = null;
        const annotationMenu = yield select(getAnnotationMenu);
        let isDrawing = yield select(getAnnotationIsDrawing);
        let annotSelectedId = yield select(getAnnotationSelectedId);
        const targetLayer = yield select(getTargetLayer);
        const lines = yield select(getLine);

        if (isDrawing) {
            isDrawing = !isDrawing
            line = lines.find((itm) => itm.id === annotSelectedId);
            //to save alert updated in reducer, display on alert RI tab
            if (line && line.isAlertChecked) {
                yield put({
                    type: ActionTypes.HANDLE_ALERT_CHANGE,
                    isAlertUpdate: true
                })
            }
            yield call(updateLine, isDrawing, line);
            yield safe(call(saveLine), "GraphicsLineSaga.js", "saveLine");
            return;
        }

        if (e.target !== e.target.getStage()) {
            annotSelectedId = e.target.id();
            line = lines.find((itm) => itm.id === annotSelectedId);
            yield call(updateLine, isDrawing, line);
            return;
        }
        let snapStartPosition = {};
        let lineStart = {
            x: e.evt.layerX,
            y: e.evt.layerY
        }
        if (annotationMenu.LineModelInfo.isSnappingChecked) {
            snapStartPosition = yield safe(call(getSnapPosition, { x: e.evt.layerX, y: e.evt.layerY }), "GraphicsLineSaga.js", "getSnapPosition");

            lineStart = {
                x: snapStartPosition.x,
                y: snapStartPosition.y
            }
        }
        const annotId = TemporaryId.Id + Date.now();
        line = {
            ...annotationMenu.LineModelInfo,
            id: annotId,
            layerID: targetLayer.layerID,
            x: lineStart.x,
            y: lineStart.y,
            startMouseX: 0,
            startMouseY: 0,
            currentMouseX: 0,
            currentMouseY: 0,
            endMouseX: 0,
            endMouseY: 0,
            isSelected: false,
            startExtendPoint: null,
            endExtendPoint: null,
            startArrowPoint: null,
            endArrowPoint: null,
            snapStartPosition: snapStartPosition,
            snapEndPosition: null,
            snapDragPosition: null,
            diffInSnapPosX: 0,
            diffInSnapPosY: 0,
            isReadOnlyAnnotation: false
        }

        isDrawing = true;
        yield call(addLine, isDrawing, line);
        if (line.isTemporaryChecked) {
            yield put({
                type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
                annotation: line.id
            });
        }
        return;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, handleClick ${error}`);
    }
}

function* handleMouseMove({ e, isEndExtend, isStartArrow, isEndArrow, reComputeOnSelection, isAnchorDrag }) {
    try {
        let line = null;
        const { isDrawing, isDragging } = yield select(getAnnotationChartDraw);
        const annotSelectedId = yield select(getAnnotationSelectedId);
        const lines = yield select(getLine);
        const annotationMenu = yield select(getAnnotationMenu);
        const activeAnchor = yield select(getActiveAnchor);
        const chartDimension = yield select(getAnnotationDimension);

        const { startArrowChecked, endArrowChecked, startExtendChecked, endExtendChecked, isSnappingChecked, weight } = annotationMenu.LineModelInfo;

        if ((isDrawing || isDragging || reComputeOnSelection || isAnchorDrag) && lines.length > 0) {

            const curLine = lines.find((itm) => itm.id === annotSelectedId); // current line to be modified

            let lineStart = {}, lineEnd = {}, currMouse = {}, newSnapDragPosition = {}, diffInSnapPosX = curLine.diffInSnapPosX ? curLine.diffInSnapPosX : 0, diffInSnapPosY = curLine.diffInSnapPosY ? curLine.diffInSnapPosY : 0;

            if (isDragging) {
                let dragPos = {
                    x: e.currentTarget.x(), y: e.currentTarget.y()
                }

                if (e.currentTarget !== undefined && e.currentTarget !== null) {
                    e.currentTarget.getStage().container().style.cursor = 'default';
                }

                // Fix for PANWEB-6190
                if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                    if (isStartArrow) {
                        e.currentTarget.attrs.x = curLine.startArrowPoint.offSet.x;
                        e.currentTarget.attrs.y = curLine.startArrowPoint.offSet.y;
                    }
                    else if (isEndArrow) {
                        e.currentTarget.attrs.x = curLine.endArrowPoint.offSet.x;
                        e.currentTarget.attrs.y = curLine.endArrowPoint.offSet.y;
                    }
                    else if (isEndExtend) {
                        e.currentTarget.attrs.x = curLine.endMouseX;
                        e.currentTarget.attrs.y = curLine.endMouseY;
                    }
                    else {
                        e.currentTarget.attrs.x = curLine.x;
                        e.currentTarget.attrs.y = curLine.y;
                    }
                    return;
                }

                if (isSnappingChecked) {
                    newSnapDragPosition = yield safe(call(getSnapPosition, { x: e.evt.layerX, y: e.evt.layerY }), "GraphicsLineSaga.js", "getSnapPosition");

                    if (snapLow || snapHigh) {
                        diffInSnapPosX = curLine.snapDragPosition && curLine.snapDragPosition.x === newSnapDragPosition.x ? curLine.diffInSnapPosX : (e.currentTarget.x() - e.evt.layerX);
                        diffInSnapPosY = curLine.snapDragPosition && curLine.snapDragPosition.x === newSnapDragPosition.x ? curLine.diffInSnapPosY : (e.currentTarget.y() - e.evt.layerY);
                        dragPos = { x: newSnapDragPosition.x + diffInSnapPosX, y: newSnapDragPosition.y + diffInSnapPosY };

                        e.currentTarget.attrs.x = newSnapDragPosition.x + diffInSnapPosX;
                        e.currentTarget.attrs.y = newSnapDragPosition.y + diffInSnapPosY;
                    }
                }

                if (isStartArrow) { // dragged element is start arrow cap
                    const diff = { x: e.evt.movementX, y: dragPos.y - curLine.y };
                    lineStart = {
                        x: curLine.x + diff.x, y: curLine.y + diff.y
                    }
                    lineEnd = {
                        x: curLine.endMouseX + diff.x, y: curLine.endMouseY + diff.y
                    }
                    if (startExtendChecked) {
                        e.currentTarget.attrs.x = curLine.startExtendPoint.x; // overwrite x co-ordinate on drag
                    }
                    else {
                        e.currentTarget.attrs.x = curLine.x; // overwrite x co-ordinate on drag
                    }

                }
                else if (isEndArrow) { // dragged element is end arrow cap
                    const diff = { x: e.evt.movementX, y: dragPos.y - curLine.y };
                    lineStart = {
                        x: curLine.x + diff.x, y: curLine.y + diff.y
                    }
                    lineEnd = {
                        x: curLine.endMouseX + diff.x, y: curLine.endMouseY + diff.y
                    }
                    if (endExtendChecked) {
                        e.currentTarget.attrs.x = curLine.endExtendPoint.x; // overwrite x co-ordinate on drag
                    }
                    else {
                        e.currentTarget.attrs.x = curLine.endMouseX; // overwrite x co-ordinate on drag
                        e.currentTarget.attrs.y = curLine.endMouseY;
                    }

                }
                else if (isEndExtend) { // dragged element is end extended line
                    const diff = { x: dragPos.x - curLine.endMouseX, y: dragPos.y - curLine.endMouseY };
                    lineStart = {
                        x: curLine.x + diff.x, y: curLine.y + diff.y
                    }
                    lineEnd = {
                        x: dragPos.x, y: dragPos.y
                    }
                }
                else {
                    // normal line and extended line have same x & y origin values.
                    const diff = { x: dragPos.x - curLine.x, y: dragPos.y - curLine.y };
                    lineStart = {
                        x: dragPos.x, y: dragPos.y
                    }
                    lineEnd = {
                        x: curLine.endMouseX + diff.x, y: curLine.endMouseY + diff.y
                    }
                }
            }
            else if (reComputeOnSelection) {
                lineStart = {
                    x: curLine.x, y: curLine.y
                }
                lineEnd = {
                    x: curLine.endMouseX, y: curLine.endMouseY
                }
            }
            else if (isAnchorDrag) {
                // Fix for PANWEB-6207
                if (e.evt.pageX <= chartDimension.left || e.evt.pageX + 1 >= chartDimension.right || e.evt.pageY <= chartDimension.top || e.evt.pageY >= chartDimension.bottom) {
                    return;
                }

                if (e.currentTarget !== undefined && e.currentTarget !== null) {
                    e.currentTarget.getStage().container().style.cursor = 'all-scroll';
                }

                if (activeAnchor === "start") {
                    lineStart = { x: e.evt.layerX, y: e.evt.layerY };
                    lineEnd = { x: curLine.endMouseX, y: curLine.endMouseY };

                    if (isSnappingChecked) {
                        snapStartPosition = yield safe(call(getSnapPosition, lineStart), "GraphicsLineSaga.js", "getSnapPosition");

                        lineStart = { x: snapStartPosition.x, y: snapStartPosition.y };
                    }
                }
                else if (activeAnchor === "end") {
                    lineStart = {
                        x: curLine.x,
                        y: curLine.y
                    }
                    lineEnd = {
                        x: e.evt.layerX,
                        y: e.evt.layerY
                    }
                }
            }
            else {
                lineStart = {
                    x: curLine.x, y: curLine.y
                }
                lineEnd = {
                    x: e.evt.layerX, y: e.evt.layerY
                }
            }

            currMouse = {
                x: lineEnd.x - lineStart.x,
                y: lineEnd.y - lineStart.y
            }

            let isAtEnd, startExtendPoint = {}, endExtendPoint = {}, startArrowPoint = {}, endArrowPoint = {}, snapStartPosition = curLine.snapStartPosition, snapEndPosition = curLine.snapEndPosition;

            if (e && e.evt.shiftKey) {
                const position = yield safe(call(calculateShiftPosition, lineStart, lineEnd), "GraphicsLineSaga.js", "calculateShiftPosition");

                if (isAnchorDrag && activeAnchor === "start") {
                    lineStart.x = position.x ? lineEnd.x : lineStart.x;
                    lineStart.y = position.y ? lineEnd.y : lineStart.y;
                }
                else {
                    lineEnd.x = position.x ? lineStart.x : lineEnd.x;
                    lineEnd.y = position.y ? lineStart.y : lineEnd.y;
                }

                currMouse = {
                    x: lineEnd.x - lineStart.x,
                    y: lineEnd.y - lineStart.y
                }
            }

            if (isSnappingChecked && !isDragging && !reComputeOnSelection && (!isAnchorDrag || activeAnchor === "end")) {
                snapEndPosition = yield safe(call(getSnapPosition, lineEnd, snapEndPosition), "GraphicsLineSaga.js", "getSnapPosition");

                if (snapEndPosition) {  // avoid updating the null case which occurs at the first cursor move
                    currMouse = {
                        x: snapEndPosition.x - lineStart.x,
                        y: snapEndPosition.y - lineStart.y
                    }

                    // update end point of the cursor to handle further arrow / extend options.
                    lineEnd = {
                        x: snapEndPosition.x,
                        y: snapEndPosition.y
                    }
                }
            }

            if (startExtendChecked && !startArrowChecked && !endArrowChecked) {
                isAtEnd = false;
                startExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);
            }
            if (endExtendChecked && !startArrowChecked && !endArrowChecked) {
                isAtEnd = true;
                endExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);
            }
            if (startArrowChecked && !endArrowChecked) {
                isAtEnd = false;
                if (startExtendChecked) {
                    startExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);

                    startArrowPoint = yield call(makeArrowGeometryFromPoints, isAtEnd, startExtendPoint, lineStart, weight);
                }
                else {
                    startArrowPoint = yield call(makeArrowGeometryFromPoints, isAtEnd, lineStart, lineEnd, weight);
                }

                if (endExtendChecked) {
                    endExtendPoint = yield call(calculateSlope, true, lineStart, lineEnd);
                }
            }
            if (endArrowChecked && !startArrowChecked) {
                isAtEnd = true;
                if (startExtendChecked) {
                    startExtendPoint = yield call(calculateSlope, false, lineStart, lineEnd);
                }

                if (endExtendChecked) {
                    endExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);

                    endArrowPoint = yield call(makeArrowGeometryFromPoints, isAtEnd, lineEnd, endExtendPoint, weight);
                }
                else {
                    endArrowPoint = yield call(makeArrowGeometryFromPoints, isAtEnd, lineStart, lineEnd, weight);
                }
            }
            if (startArrowChecked && endArrowChecked) {
                if (startExtendChecked) {
                    startExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);

                    startArrowPoint = yield call(makeArrowGeometryFromPoints, false, startExtendPoint, lineEnd, weight);
                }
                else {
                    startArrowPoint = yield call(makeArrowGeometryFromPoints, false, lineStart, lineEnd, weight);
                }
                if (endExtendChecked) {
                    endExtendPoint = yield call(calculateSlope, true, lineStart, lineEnd);

                    endArrowPoint = yield call(makeArrowGeometryFromPoints, true, lineStart, endExtendPoint, weight);
                }
                else {
                    endArrowPoint = yield call(makeArrowGeometryFromPoints, true, lineStart, lineEnd, weight);
                }
            }

            line = Object.assign(curLine, {
                x: lineStart.x,
                y: lineStart.y,
                currentMouseX: currMouse.x,
                currentMouseY: currMouse.y,
                endMouseX: lineEnd.x,
                endMouseY: lineEnd.y,
                startExtendPoint: startExtendPoint,
                endExtendPoint: endExtendPoint,
                startArrowPoint: startArrowPoint,
                endArrowPoint: endArrowPoint,
                snapStartPosition: snapStartPosition,
                snapEndPosition: snapEndPosition,
                snapDragPosition: newSnapDragPosition,
                diffInSnapPosX: diffInSnapPosX,
                diffInSnapPosY: diffInSnapPosY
            });
            yield call(updateLine, isDrawing, line);
        }
        else {
            yield call(updateLine, isDrawing, lines);
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, handleMouseMove ${error}`);
    }
}

function calculateShiftPosition(lineStart, lineEnd) {
    const position = { x: 0, y: 0 };
    const theta = Math.abs(Math.atan2((lineEnd.y - lineStart.y), (lineEnd.x - lineStart.x)) * 180.0 / Math.PI);

    if (theta >= 0 && theta < 45) {
        position.y = 1;
    }
    else if ((theta >= 45 && theta < 90) || (theta >= 90 && theta < 135)) {
        position.x = 1;
    }
    else if (theta >= 135 && theta < 180) {
        position.y = 1;
    }

    return position;
}

function* calculateSlope(isAtEnd, lineStart, lineEnd) {
    try {
        const chartDimension = yield select(getAnnotationDimension);
        let x1, y1, x2, y2, slope = 0, returnPoint = { x: 0, y: 0 }, newX, newY;
        const extendedWidth = chartDimension.width - 6, extendedHeight = chartDimension.height;

        if (isAtEnd) {
            x1 = lineStart.x;
            y1 = lineStart.y;
            x2 = lineEnd.x;
            y2 = lineEnd.y;
        }
        else {
            x1 = lineEnd.x;
            y1 = lineEnd.y;
            x2 = lineStart.x;
            y2 = lineStart.y;
        }

        const deltaX = x2 - x1;
        const deltaY = y2 - y1;

        const xIsPositive = deltaX > 0;
        const yIsPositive = deltaY > 0;

        const xIsZero = Math.abs(deltaX - 0) < Number.EPSILON;
        const yIsZero = Math.abs(deltaY - 0) < Number.EPSILON;

        const yEquation = lineEnd.y - lineStart.y;
        const xEquation = lineEnd.x - lineStart.x;

        // Check for Quads
        const slope1 = (0 - y1) / (0 - x1);
        const slope2 = (0 - y1) / (extendedWidth - x1);
        const slope3 = (extendedHeight - y1) / (extendedWidth - x1);
        const slope4 = (extendedHeight - y1) / (0 - x1);

        if (!xIsZero) {
            slope = yEquation / xEquation;
        }

        if (isAtEnd) {
            // Extension for Y-Axis
            if (yIsZero) {
                returnPoint = lineEnd.x > lineStart.x ? { x: extendedWidth, y: lineEnd.y } : { x: 0, y: lineEnd.y };
            }
            // Extension for X-Axis
            else if (xIsZero) {
                returnPoint = lineEnd.y < lineStart.y ? { x: lineEnd.x, y: 0 } : { x: lineEnd.x, y: extendedHeight };
            }
            else if (!xIsPositive && !yIsPositive) {
                if (slope > slope1) {
                    newX = 0 / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: 0 };
                }
                else {
                    newY = slope * (0 - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: 0, y: newY };
                }
            }
            else if (xIsPositive && !yIsPositive) {
                if (slope < slope2) {
                    newX = 0 / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: 0 };
                }
                else {
                    newY = slope * (extendedWidth - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: extendedWidth, y: newY };
                }
            }
            else if (xIsPositive) {
                if (slope < slope3) {
                    newY = slope * (extendedWidth - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: extendedWidth, y: newY };
                }
                else {
                    newX = extendedHeight / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: extendedHeight };
                }
            }
            else {
                if (slope < slope4) {
                    newX = extendedHeight / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: extendedHeight };
                }
                else {
                    newY = slope * (0 - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: 0, y: newY };
                }
            }
        }
        else {
            // Extension for Y-Axis
            if (yIsZero) {
                returnPoint = lineEnd.x > lineStart.x ? { x: 0, y: lineStart.y } : { x: extendedWidth, y: lineStart.y };
            }
            // Extension for X-Axis
            else if (xIsZero) {
                returnPoint = lineEnd.y > lineStart.y ? { x: lineStart.x, y: 0 } : { x: lineStart.x, y: extendedHeight };
            }
            else if (!xIsPositive && !yIsPositive) {
                if (slope > slope1) {
                    newX = 0 / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: 0 };
                }
                else {
                    newY = slope * (0 - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: 0, y: newY };
                }
            }
            else if (xIsPositive && !yIsPositive) {
                if (slope < slope2) {
                    newX = 0 / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: 0 };
                }
                else {
                    newY = slope * (extendedWidth - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: extendedWidth, y: newY };
                }
            }
            else if (xIsPositive) {
                if (slope < slope3) {
                    newY = slope * (extendedWidth - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: extendedWidth, y: newY };
                }
                else {
                    newX = extendedHeight / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: extendedHeight };
                }
            }
            else {
                if (slope < slope4) {
                    newX = extendedHeight / slope - lineEnd.y / slope + lineEnd.x;
                    returnPoint = { x: newX, y: extendedHeight };
                }
                else {
                    newY = slope * (0 - lineEnd.x) + lineEnd.y;
                    returnPoint = { x: 0, y: newY };
                }
            }
        }
        return returnPoint;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, calculateSlope ${error}`);
    }
}

function* makeArrowGeometryFromPoints(isAtEnd, startOfLine, endOfLine, weight) {
    try {
        const headHeight = 14;
        let headWidth = 6;

        switch (weight) {
            case 2:
                headWidth += 1;
                break;
            case 4:
                headWidth += 2;
                break;
            case 6:
                headWidth += 3;
                break;
            case 8:
                headWidth += 4;
                break;
            case 16:
                headWidth += 12;
                break;
            default: break;
        }

        const p = isAtEnd ? { x: endOfLine.x, y: endOfLine.y } : { x: startOfLine.x, y: startOfLine.y };
        const lPoint = isAtEnd ? { x: endOfLine.x - headHeight, y: endOfLine.y + headWidth } : { x: startOfLine.x + headHeight, y: startOfLine.y + headWidth };
        const uPoint = isAtEnd ? { x: endOfLine.x - headHeight, y: endOfLine.y - headWidth } : { x: startOfLine.x + headHeight, y: startOfLine.y - headWidth };
        const theta = Math.atan2((endOfLine.y - startOfLine.y), (endOfLine.x - startOfLine.x)) * 180 / Math.PI;
        const offSet = isAtEnd ? { x: endOfLine.x, y: endOfLine.y } : { x: startOfLine.x, y: startOfLine.y }
        const arrowGeometry = { p, lPoint, uPoint, angle: theta, offSet };

        return arrowGeometry;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, makeArrowGeometryFromPoints ${error}`);
    }
}

function getSnapPosition(position, prevSnapPosition, isFromApi = false) {
    // TODO : Optimize this method to get smooth transition on the chart
    const range = 100;
    const mouseY = position.y;
    const chartNodes = AnnotationUtil.HiLowPoints.allPoints;
    let node = null;
    position.x = Math.round(position.x);
    snapHigh = false; snapLow = false;

    // Take the max key value and see if the node is outside of the last one and don't do anything in this case.
    if (position.x > AnnotationUtil.max_x_Axis) {
        return null;
    }

    if (isFromApi) {
        node = find(chartNodes, (item) => item.xAxis === position.x);
    }

    if (!node) {
        const TimeLineList = AnnotationUtil.getTimeLine();
        const posFromRight = AnnotationUtil.getInitialNode() - position.x;
        const curIndex = Math.round(posFromRight / AnnotationUtil.nodeWidth);
        let timeLineNode = null;
        if (TimeLineList[curIndex]) {
            timeLineNode = TimeLineList[curIndex];
        }

        node = find(chartNodes, (item) => moment(item.Date).isSame(timeLineNode.Date));
    }

    if (node) {
        const high = node.yHigh;
        const low = node.yLow;

        if (mouseY <= high && mouseY > (high - range)) {
            snapHigh = true;
            position = { x: position.x, y: high };
        }
        else if (mouseY >= low && mouseY < (low + range)) {
            snapLow = true;
            position = { x: position.x, y: low };
        }
        return position;
    }

    // If in case it was a holiday, we'll take the previous node. 
    const nodeContainerWidth = AnnotationUtil.getNodeWidth();
    const newPosition = (position.x - nodeContainerWidth);
    node = find(chartNodes, (item) => item.xAxis === newPosition);
    if (node) {
        const high = node.yHigh;
        const low = node.yLow;

        if (mouseY <= high && mouseY > (high - range)) {
            position = { x: position.x, y: high };
        }
        else if (mouseY >= low && mouseY < (low + range)) {
            position = { x: position.x, y: low };
        }
        return position;
    }

    if (prevSnapPosition !== undefined || prevSnapPosition !== null) {
        return prevSnapPosition;
    }
    else {
        return null;
    }
}

function* updateLine(isDrawing, line, markSelected = true) {
    try {
        yield put({
            type: ActionTypes.HANDLE_LINE_DRAW_SUCCESS,
            line: line
        })
        if (markSelected) {
            yield put({
                type: ActionTypes.HANDLE_CLICK_SUCCESS,
                isDrawing: isDrawing,
                annotSelected: line
            })
        }
        let layersList = yield select(getLayersList);
        layersList = JSON.parse(JSON.stringify(layersList));
        const selectedAnnot = yield select(getAnnotationSelected);
        for (const x in layersList) {
            layersList[x].layerID === selectedAnnot.layerID ? layersList.push(layersList.splice(x, 1)[0]) : 0;
        }
        yield put({
            type: ActionTypes.UPDATE_LAYERS_LIST,
            LayersList: layersList
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, updateLine ${error}`);
    }
}

function* addLine(isDrawing, line) {
    try {
        yield put({
            type: ActionTypes.HANDLE_LINE_CLICK_SUCCESS,
            line: line
        });
        yield put({
            type: ActionTypes.HANDLE_CLICK_SUCCESS,
            isDrawing: isDrawing,
            annotSelected: line
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, addLine ${error}`);
    }
}

function* saveLine() {
    yield put({
        type: ActionTypes.HANDLE_SAVE_ANNOTATION
    });
}

function* addAlert() {
    try {
        let line = null;
        const annotSelectedIds = yield select(getSelectedAnnotId);
        const annotSelectedId = annotSelectedIds[0];
        const lines = yield select(getLine);
        line = lines.find((itm) => itm.id === annotSelectedId);

        const lineStart = {
            x: line.x,
            y: line.y
        }
        const lineEnd = {
            x: line.endMouseX,
            y: line.endMouseY
        }

        const endExtendPoint = yield call(calculateSlope, true, lineStart, lineEnd);
        let endArrowPoint = line.endArrowPoint;

        if (line.endArrowChecked) {
            endArrowPoint = yield call(makeArrowGeometryFromPoints, true, lineEnd, endExtendPoint, line.weight);
        }

        line = {
            ...line,
            isAlertChecked: true,
            endExtendChecked: true,
            isTemporaryChecked: false,
            endExtendPoint: endExtendPoint,
            endArrowPoint: endArrowPoint
        }

        //to save alert updation in reducer
        yield put({
            type: ActionTypes.HANDLE_ALERT_CHANGE,
            isAlertUpdate: true
        })

        yield put({
            type: ActionTypes.HANDLE_ALERT_END_EXTEND_CHANGE
        });

        yield call(updateLine, false, line);
        yield safe(call(saveLine), "GraphicsLineSaga.js", "saveLine");
        let newStateProperties = {};
        let model = null;
        const annotationMenu = yield select(getAnnotationMenu);
        newStateProperties = { ...annotationMenu.LineModelInfo, ...line };
        model = "LineModelInfo";
        yield put({
            type: ActionTypes.UPDATE_RI_PANEL_INFO,
            model,
            newStateProperties
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, addAlert ${error}`);
    }
}
function* removeAlert() {
    try {
        let line = null;
        const annotSelectedIds = yield select(getSelectedAnnotId);
        const annotSelectedId = annotSelectedIds[0];
        const lines = yield select(getLine);
        line = lines.find((itm) => itm.id === annotSelectedId);

        //get alert id from alert store with selected annotation
        const { trendLineAlerts } = yield select(getDatagraphAlertStates);
        const alertObj = trendLineAlerts.find((item) => item.TLAlertData.annotationid === annotSelectedId);

        //delete alert from alerts table
        if (alertObj && alertObj.alertId) {
            yield put(deleteAlert(alertObj));

            line = {
                ...line,
                isAlertChecked: false
            }

            //to save alert updation in reducer
            yield put({
                type: ActionTypes.HANDLE_ALERT_CHANGE,
                isAlertUpdate: true
            })
        }
        yield call(updateLine, false, line);
        yield safe(call(saveLine), "GraphicsLineSaga.js", "saveLine");
        let newStateProperties = {};
        let model = null;
        const annotationMenu = yield select(getAnnotationMenu);
        newStateProperties = { ...annotationMenu.LineModelInfo, ...line };
        model = "LineModelInfo";
        yield put({
            type: ActionTypes.UPDATE_RI_PANEL_INFO,
            model,
            newStateProperties
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, removeAlert ${error}`);
    }
}
function* removeAlertIcon({ annotId }) {
    try {

        let line = null;
        const lines = yield select(getLine);
        line = lines.find((itm) => itm.id === annotId);
        if (line) {
            line = {
                ...line,
                isAlertChecked: false
            }

            yield call(updateLine, false, line, false);
            yield put({
                type: ActionTypes.REMOVE_ALERT_SELECTION_SAVE,
                isRemoveAlertSelection: true,
                line: line
            })
            yield safe(call(saveLine), "GraphicsLineSaga.js", "saveLine");
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, removeAlertIcon ${error}`);
    }
}
function* processLineData({ data }) {
    try {
        const lineStart = {
            x: data.startPoint.x,
            y: data.startPoint.y
        }
        const lineEnd = {
            x: data.endPoint.x,
            y: data.endPoint.y
        }

        const currMouse = {
            x: lineEnd.x - lineStart.x,
            y: lineEnd.y - lineStart.y
        }

        let isAtEnd, startExtendPoint = {}, endExtendPoint = {}, startArrowPoint = {}, endArrowPoint = {};
        const snapStartPosition = {}, snapEndPosition = {};

        // if (data.isSnap) {
        //     snapStartPosition = yield safe(call(getSnapPosition, lineStart, null, true), "GraphicsLineSaga.js", "getSnapPosition");
        //     snapEndPosition = yield safe(call(getSnapPosition, lineEnd, null, true), "GraphicsLineSaga.js", "getSnapPosition");

        //     if (snapStartPosition && snapEndPosition) {
        //         lineStart = {
        //             x: snapStartPosition.x,
        //             y: snapStartPosition.y
        //         }
        //         currMouse = {
        //             x: snapEndPosition.x - lineStart.x,
        //             y: snapEndPosition.y - lineStart.y
        //         }

        //         lineEnd = {
        //             x: snapEndPosition.x,
        //             y: snapEndPosition.y
        //         }
        //     }
        // }

        if (data.isStartExtend && !data.isStartArrow && !data.isEndArrow) {
            isAtEnd = false;
            startExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);
        }
        if (data.isEndExtend && !data.isStartArrow && !data.isEndArrow) {
            isAtEnd = true;
            endExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);
        }
        if (data.isStartArrow && !data.isEndArrow) {
            isAtEnd = false;
            if (data.isStartExtend) {
                startExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);

                startArrowPoint = yield call(makeArrowGeometryFromPoints, isAtEnd, startExtendPoint, lineStart, data.penSize);
            }
            else {
                startArrowPoint = yield call(makeArrowGeometryFromPoints, isAtEnd, lineStart, lineEnd, data.penSize);
            }

            if (data.isEndExtend) {
                endExtendPoint = yield call(calculateSlope, true, lineStart, lineEnd);
            }
        }
        if (data.isEndArrow && !data.isStartArrow) {
            isAtEnd = true;
            if (data.isStartExtend) {
                startExtendPoint = yield call(calculateSlope, false, lineStart, lineEnd);
            }

            if (data.isEndExtend) {
                endExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);

                endArrowPoint = yield call(makeArrowGeometryFromPoints, isAtEnd, lineEnd, endExtendPoint, data.penSize);
            }
            else {
                endArrowPoint = yield call(makeArrowGeometryFromPoints, isAtEnd, lineStart, lineEnd, data.penSize);
            }
        }
        if (data.isStartArrow && data.isEndArrow) {
            if (data.isStartExtend) {
                startExtendPoint = yield call(calculateSlope, isAtEnd, lineStart, lineEnd);

                startArrowPoint = yield call(makeArrowGeometryFromPoints, false, startExtendPoint, lineEnd, data.penSize);
            }
            else {
                startArrowPoint = yield call(makeArrowGeometryFromPoints, false, lineStart, lineEnd, data.penSize);
            }
            if (data.isEndExtend) {
                endExtendPoint = yield call(calculateSlope, true, lineStart, lineEnd);

                endArrowPoint = yield call(makeArrowGeometryFromPoints, true, lineStart, endExtendPoint, data.penSize);
            }
            else {
                endArrowPoint = yield call(makeArrowGeometryFromPoints, true, lineStart, lineEnd, data.penSize);
            }
        }

        const stroke = data.strokeType === StrokeStyleType.SOLID_StrokeStyleType ? [] : data.strokeType === StrokeStyleType.DASHED_StrokeStyleType ? [data.penSize * 2.5, data.penSize] : [data.penSize, data.penSize];
        const convertedColor = ThemeHelper.convertArgbToHex(data.foregroundColor);
        const layersList = yield select(getLayersList);
        const isReadOnlyAnnotation = layersList.filter((i) => i.layerID === data.layerID)[0]?.shareAccess === ShareAccessType.SHARE_READONLY;

        const line = {
            id: data.annotationID,
            layerID: data.layerID,
            type: data.annotationType,
            x: lineStart.x,
            y: lineStart.y,
            startMouseX: 0,
            startMouseY: 0,
            currentMouseX: currMouse.x,
            currentMouseY: currMouse.y,
            endMouseX: lineEnd.x,
            endMouseY: lineEnd.y,
            color: convertedColor.color,
            opacity: convertedColor.opacity,
            weight: data.penSize,
            stroke: stroke,
            strokeStyle: data.strokeType,
            startArrowChecked: data.isStartArrow,
            endArrowChecked: data.isEndArrow,
            startPolygonPoints: data.isStartArrow ? data.isStartExtend ? '12,35 12,25 0,30' : '50,35 50,25 42,30' : null,
            endPolygonPoints: data.isEndArrow ? data.isEndExtend ? '244,35 244,25 256,30' : '220,35 220,25 228,30' : null,
            startArrowPoint: startArrowPoint,
            endArrowPoint: endArrowPoint,
            startExtendChecked: data.isStartExtend,
            endExtendChecked: data.isEndExtend,
            lineX1: data.isStartExtend ? 0 : 50,
            lineX2: data.isEndExtend ? 266 : 220,
            startExtendPoint: startExtendPoint,
            endExtendPoint: endExtendPoint,
            isSnappingChecked: data.isSnap,
            snapStartPosition: snapStartPosition,
            snapEndPosition: snapEndPosition,
            isAlertChecked: data.isAlert,
            isTemporaryChecked: false,
            createdDate: data.createdDate,
            updatedDate: data.updatedDate,
            isSelected: false,
            isDeleted: false,
            isReadOnlyAnnotation: isReadOnlyAnnotation
        }

        yield put({
            type: ActionTypes.HANDLE_LINE_CLICK_SUCCESS,
            line: line
        });
        yield put({
            type: ActionTypes.ADD_TO_ANNOTATION_COLLECTION,
            annotation: line.id
        });

        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, processLineData ${error}`, error);
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        PrintMode.printing && console.log("PRINT:--Tracker--*** Data processing Error in GraphicsLineSaga *** for -", TimeTrackingWindow.currentSymbol);
    }
}
// Toggle TL alert icon on active/Inactive clisked from alerts tab
function* toggleAlertIcon({ annotId, status }) {
    try {
        let line = null;
        const lines = yield select(getLine);
        line = lines.find((itm) => itm.id === annotId);
        if (line) {
            line = {
                ...line,
                isAlertChecked: status
            }
            yield call(updateLine, false, line, false);
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in GraphicsLineSaga.js, toggleAlertIcon ${error}`);
    }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchHandleLineClick() {
    yield takeLatest(ActionTypes.HANDLE_LINE_CLICK, handleClick);
};

export function* watchHandleLineDraw() {
    yield takeLatest(ActionTypes.HANDLE_LINE_DRAW, handleMouseMove);
};

export function* watchProcessLineData() {
    yield takeEvery(ActionTypes.PROCESS_LINE_DATA, processLineData);
};

export function* watchAddAlert() {
    yield takeLatest(ActionTypes.ADD_TRENDLINE_ALERT, addAlert);
};

export function* watchRemoveAlert() {
    yield takeLatest(ActionTypes.REMOVE_TRENDLINE_ALERT, removeAlert);
};

export function* watchRemoveAlertRI() {
    yield takeLatest(ActionTypes.REMOVE_LINE_ALERT_ICON, removeAlertIcon)
}

export function* watchToggleAlertRI() {
    yield takeLatest(ActionTypes.TOGGLE_LINE_ALERT_ICON, toggleAlertIcon)
}

