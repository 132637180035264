import BaseServiceApi from 'BaseServiceApi';

export const RayAnnotationType = BaseServiceApi.rayData["AnnotationType"];

export const AnnotationType = {
    1: "LINE_AnnotationType",
    2: "PARALLEL_LINES_AnnotationType",
    3: "POLY_LINE_AnnotationType",
    4: "CIRCLE_AnnotationType",
    5: "RECT_AnnotationType",
    6: "ELLIPSE_AnnotationType",
    7: "TRIANGLE_AnnotationType",
    8: "TEXT_AnnotationType",
    9: "FIB_AnnotationType",
    10: "CUP_AnnotationType",
    11: "CUP_AND_HANDLE_AnnotationType",
    12: "DOUBLE_BOTTOM_AnnotationType",
    13: "MEASUREMENT_AnnotationType"
}

export const StrokeStyleType = BaseServiceApi.rayData["StrokeStyleType"];
export const TextAlignmentType = BaseServiceApi.rayData["TextAlignmentType"];
export const fontStyles = [
    'Alef',
    'Amiri',
    'Amiri Quran',
    'Arial',
    'Arial Black',
    'Arial Narrow',
    'Arial Unicode MS',
    'Bahnschrift',
    'Bahnschrift Condensed',
    'Bahnschrift Light',
    'Bahnschrift Light Condensed',
    'Bahnschrift Light SemiCondensed',
    'Bahnschrift SemiBold',
    'Bahnschrift SemiBold Condensed',
    'Bahnschrift SemiBold SemiConden',
    'Bahnschrift SemiCondensed',
    'Bahnschrift SemiLight',
    'Bahnschrift SemiLight Condensed',
    'Bahnschrift SemiLight SemiConde',
    'Book Antiqua',
    'Bookman Old Style',
    'Bookshelf Symbol 7',
    'Buxton Sketch',
    'Caladea',
    'Calibri',
    'Calibri Light',
    'Cambria',
    'Cambria Math',
    'Candara',
    'Candara Light',
    'Carlito',
    'Century',
    'Century Gothic',
    'Comic Sans MS',
    'Consolas',
    'Constantia',
    'Corbel',
    'Corbel Light',
    'Courier',
    'Courier New',
    'David CLM',
    'David Libre',
    'DejaVu Math TeX Gyre',
    'DejaVu Sans',
    'DejaVu Sans Condensed',
    'DejaVu Sans Light',
    'DejaVu Sans Mono',
    'DejaVu Serif',
    'DejaVu Serif Condensed',
    'EmojiOne Color',
    'Fixedsys',
    'Frank Ruehl CLM',
    'Frank Ruhl Hofshi',
    'Franklin Gothic Medium',
    'Gabriola',
    'Garamond',
    'Gentium Basic',
    'Gentium Book Basic',
    'Georgia',
    'HoloLens MDL2 Assets',
    'Impact',
    'Ink Free',
    'KacstBook',
    'KacstOffice',
    'Liberation Mono',
    'Liberation Sans',
    'Liberation Sans Narrow',
    'Liberation Serif',
    'Linux Biolinum G',
    'Linux Libertine Display G',
    'Linux Libertine G',
    'Lucida Console',
    'Lucida Sans Unicode',
    'Microsoft Sans Serif',
    'Miriam CLM',
    'Miriam Libre',
    'Miriam Mono CLM',
    'Modern',
    'Monotype Corsiva',
    'MS Outlook',
    'MS Reference Sans Serif',
    'MS Reference Specialty',
    'MS Sans Serif',
    'MS Serif',
    'Noto Kufi Arabic',
    'Noto Mono',
    'Noto Naskh Arabic',
    'Noto Naskh Arabic UI',
    'Noto Sans Arabic',
    'Noto Sans Arabic UI',
    'Noto Sans Armenian',
    'Noto Sans Georgian',
    'Noto Sans Hebrew',
    'Noto Sans Lao',
    'Noto Sans Lisu',
    'Noto Serif',
    'Noto Serif Armenian',
    'Noto Serif Cond',
    'Noto Serif Georgian',
    'Noto Serif Hebrew',
    'Noto Serif Lao',
    'Noto Serif Light',
    'OpenSymbol',
    'Palatino Linotype',
    'Reem Kufi',
    'Roman',
    'Rubik',
    'Scheherazade',
    'Script',
    'Segoe Marker',
    'Segoe MDL2 Assets',
    'Segoe Print',
    'Segoe Script',
    'Segoe UI',
    'Segoe UI Black',
    'Segoe UI Emoji',
    'Segoe UI Light',
    'Segoe UI Semibold',
    'Segoe UI Semilight',
    'Segoe UI Symbol',
    'Sitka Banner',
    'Sitka Display',
    'Sitka Heading',
    'Sitka Small',
    'Sitka Subheading',
    'Sitka Text',
    'SketchFlow Print',
    'Small Fonts',
    'Source Code Pro',
    'Source Code Pro Black',
    'Source Code Pro ExtraLight',
    'Source Code Pro Light',
    'Source Code Pro Medium',
    'Source Code Pro Semibold',
    'Source Sans Pro',
    'Source Sans Pro Black',
    'Source Sans Pro ExtraLight',
    'Source Sans Pro Light',
    'Source Sans Pro Semibold',
    'Source Serif Pro',
    'Source Serif Pro Black',
    'Source Serif Pro ExtraLight',
    'Source Serif Pro Light',
    'Source Serif Pro Semibold',
    'Symbol',
    'System',
    'Tahoma',
    'Terminal',
    'Times New Roman',
    'Trebuchet MS',
    'Verdana',
    'Webdings',
    'Wingdings',
    'Wingdings 2',
    'Wingdings 3'
];