
import { AnnotationConstant } from "../../Constants/AnnotationConstants";
const { ActionTypes } = AnnotationConstant;
import { MeasurementInfo } from "../../Models/AnnotationModels/MeasurementModelInfo";

const measurementData = []

const measurementReducer = (state = measurementData, action) => {

    switch (action.type) {
        case ActionTypes.INIT_MEASUREMENT_DATA_COLLECTION:
            return action.measurementCollection;
        case ActionTypes.HANDLE_MEASUREMENT_CLICK_SUCCESS:
            return [...state, action.measurementLine]
        case ActionTypes.HANDLE_MEASUREMENT_DRAW_SUCCESS:
            return state.map((item) => (item.id === action.measurementLine.id) ? action.measurementLine : item)
        case ActionTypes.HANDLE_MEASUREMENT_LINE_SAVE_ID_SUCCESS:
            return action.measurementData
        case ActionTypes.HANDLE_MEASUREMENT_TRANSFORM_SUCCESS:
            return state.map((item) => (item.id === action.measurementLine.id) ? action.measurementLine : item)
        case ActionTypes.HANDLE_REMOVE_MEASUREMENT_DRAW:
            return state.filter((item) => item.id !== action.annotSelectedId)
        case ActionTypes.REMOVE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new MeasurementInfo, { ...item, isDeleted: true }) : item)
        case ActionTypes.RESTORE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new MeasurementInfo, { ...item, isDeleted: false }) : item)
        case ActionTypes.CLEAR_ANNOTATIONS:
            return [];
        default:
            return state
    }
}

export default measurementReducer;