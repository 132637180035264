import { ComparisonGraph } from "Constants/ComparisonGraph";
import GraphPanelModelStates from '../../../Models/ComparisonChartModels/GraphPanelModelStates';
import { extend, map } from "underscore";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import CompareTabType,{CompareMenuType} from "CompareTabType";
const { ActionTypes } = ComparisonGraph;
const initData = new GraphPanelModelStates();
const rightMargin = 70;
extend(ActionTypes, SettingsConstants.ActionTypes);

const initialState = {
    selectedMenuTab : CompareMenuType.Symbols,
    SymbolsData : {...initData},
    GroupsData : {...initData},
    selectedTab : null,
    nodeWidth : 0,
    chartWidth : 0,
    chartHeight:0
  }
  
let filterData;
let stateData;
const compareChartViewModel = (state = initialState, action) => {

    const setStateData = (obj) => {

        if (state.selectedMenuTab === CompareMenuType.Symbols) {
          map(obj, (value, property) => state.SymbolsData[property] = value);
        }
        else {
          map(obj, (value, property) => state.GroupsData[property] = value);
        }
    
        return { ...state };
      }
    
      
      const getStateData = () => {
        if (state.selectedMenuTab === CompareMenuType.Symbols) {
           return state.SymbolsData ;
        }
        else {
          return state.GroupsData ;
        }
    }
    switch (action.type) {
        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
      return {
        ...state,
        selectedTab: action.selectedTab
      }

    case ActionTypes.SET_COMPARE_MENUTAB:
      if (state.selectedTab === CompareTabType.ComparisonGraph) {
        return {
          ...state,
          selectedMenuTab: action.menuTab
        }
      } else {
        return { ...state }
      }
        
        case ActionTypes.INIT_FROM_SETTINGS_DATA:
            const { symbolRequest, indexRequest, scaleBased, scaleType, msids } = action.settingsData;
            return setStateData({
                symbolRequest,
                indexRequest,
                scaleType,
                scaleBasedValue: scaleBased,
                msids
            });

        case ActionTypes.UPDATE_SYMBOL_REQ:
            return setStateData({
                symbolRequest: action.symbolRequest
            });

        case ActionTypes.UPDATE_INDEX_REQ:
            return setStateData({
                indexRequest: action.indexRequestData
            });
            
        case ActionTypes.REMOVE_COMPARISON_LIST_ITEM:
            if(action.isIndexLine){
                return setStateData({indexRequest:action.filteredIndexRequestData});
            }else{
                 return setStateData({symbolRequest:action.filteredSymbolRequestData,msids:action.filteredMsidData});
            }

        case ActionTypes.FILTERED_INDEX_REQUEST_DATA:
            return setStateData({ indexRequest: action.indexRequest })

        case ActionTypes.FILTERED_SYMBOL_REQUEST_DATA:
            return setStateData({ symbolRequest: action.symbolRequest, msids: action.filteredMsids })

        case ActionTypes.HANDLE_HIGHLIGHT_SUCCESS:
            let filter1 = getStateData().indexRequest.map((item) => {
                if (action.lastHighlightedOsids.includes(item.Osid)) {
                    return { ...item, isHighlighted: true }
                }
                else {
                    return { ...item, isHighlighted: false };
                }
            });

            let filter2 = getStateData().symbolRequest.map((item) => {
                if (action.lastHighlightedOsids.includes(item.Osid)) {
                    return { ...item, isHighlighted: true }
                }
                else {
                    return { ...item, isHighlighted: false };
                }
            });

          return setStateData({
                indexRequest: filter1,
                symbolRequest: filter2
            });

        case ActionTypes.ON_DIMENSION_CHANGE_SUCSESS:
            const chartWidth = (action.dimension.width - rightMargin);
            return {
                ...state,
                chartWidth: chartWidth,
                chartHeight: action.dimension.height,
                nodeWidth: action.dimension.width > 0 && getStateData().numOfChartNodes > 0 ? (chartWidth / (getStateData().numOfChartNodes - 1)) : 0
            }
        case ActionTypes.UPDATE_NODE_WIDTH:
            return {
                ...state,
                nodeWidth: state.chartWidth > 0 && getStateData().numOfChartNodes > 0 ? (state.chartWidth / (getStateData().numOfChartNodes - 1)) : 0
            }
        case ActionTypes.UPDATE_NUM_CHART_NODES:
            return setStateData({
                numOfChartNodes: action.numOfChartNodes
            });
            
        case ActionTypes.UPDATE_PRICE_SCALEINFO:
            stateData = getStateData();
            return setStateData({
                priceScaleInfo:
                {
                    ...stateData.priceScaleInfo,
                    ...action.priceScaleInfo
                }
            });

        case ActionTypes.SET_MSIDS:
            return setStateData({
                msids:action.msids
            })

        case ActionTypes.SET_MAX_MIN_CHART_VALUE:
            stateData = getStateData();
            return setStateData({
                chartMinPrice: action.maxMinValue.minValue,
                chartMaxPrice: action.maxMinValue.maxValue,
                priceScaleInfo:
                {
                    ...stateData.priceScaleInfo,
                    MaxPrice: action.maxMinValue.maxValue,
                    MMaxPrice: action.maxMinValue.maxValue,
                    MinPrice: action.maxMinValue.minValue,
                    MMinPrice: action.maxMinValue.minValue
                }
            });

        case ActionTypes.SET_TIME_LINE_DATA:
             return setStateData({
                timeLineData: action.timeLineData
            });

        case ActionTypes.CHANGE_SCALE_BASE_VALUE_SUCCESS:
             return setStateData({
                scaleBasedValue: action.scaleBasedValue
            });

        case ActionTypes.CHANGE_SCALE_TYPE_VALUE_SUCCESS:
             return setStateData({
                scaleType: action.scaleType
            });

        case ActionTypes.INIT_LIQ_FILTER:
            return setStateData({
                liqfilterdata: action.result
            });

        case ActionTypes.UPDATE_LIQ_FILTER:
            return setStateData({
                liqFilterState: action.liqFilterState
            });

        case ActionTypes.CLEAR_COMPARISON_SYMBOLS_DATA:
             return setStateData({
                symbolRequest: [],
                indexRequest: [],
                msids:[]
            });
            
        default:
            return state
    }
}

export default compareChartViewModel;