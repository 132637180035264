import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetNodeDetailsRequest extends BaseServiceRequest {

    constructor(nodeId, nodeType) {
        super();
        super.addRequestUri("nodedetails.pb");
        super.addRequestParameter("nid", nodeId.toString());
        super.addRequestParameter("ntid", parseInt(nodeType).toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.SHARE;
    }

    getMessageName() {
        return "ShareNodeDetailsResponse";
    }
}

export default GetNodeDetailsRequest;
