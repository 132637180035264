import React, { useState,useEffect,useRef } from "react";
import { connect } from "react-redux";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";

const Pointer = (props) => {
  const content = props.content;
  const contentL = props.contentL;
  const boxL = props.boxL;
  const boxR = props.boxR;
  const contentR = props.contentR;
  const y = props.y ? props.y : 0;
  const direction = props.direction ? props.direction : "left";
  const visibility = props.visibility ? props.visibility : "visible";
  const contentB = props.contentB;
  const isActive = props.isActive;
  const height = props.height;
  const width = props.width;
  const numL = props.numL;
  const fill = props.fill;
  const lcolor = props.lcolor;
  const rcolor = props.rcolor;
  const hasBox = props.hasBox;
  const x = props.x ? props.x : '';
  const mainDiv = useRef(null);
  const updatePointer = (contentL, contentR, yValue, boxL, boxR, numL) => {
     //this.setState({ contentL: contentL, contentR: contentR, content: contentL, y: yValue, boxL: boxL, boxR: boxR, numL: numL });
  }
  const movePointer = (yValue) => {
    // this.setState({ y: yValue });
  }
  const [mainheight, setHeight] = useState(0);
  useEffect(() => {
    if(mainDiv.current && mainDiv.current.clientHeight){
        setHeight(mainDiv.current.clientHeight);
    }
  });
  const getBox = () => {
    if (mainDiv && !mainDiv.current) {
      return;
    }
    let parentHeight = mainDiv.current.parentNode.clientHeight;
    let isTopAlign = y + height + 16 > parentHeight;
    let halfHeight = height / 2;
    let style = {
      top: isTopAlign ? -16 : height,
      background: ThemeHelper.getThemedBrush("000fff"),
      width: (width - halfHeight) + "px"
    };
    let colr = props.posDTColor;
    if (numL < 0) {
      colr = props.negDTColor;
    } else {
      colr = props.posDTColor;
    }
    return (
      <div className="pointer-changes" style={style}>
        <span className="pointer-changes-left" style={{ color: colr }} >{boxL}</span>
        <span className="pointer-changes-right" style={{ color: colr }} >{boxR}</span>
      </div>
    );
  }

  const getNumberOfLines = () => {
    let querySel = document.querySelectorAll('.pointer-hold.righter');
    if (mainDiv || querySel[0]) {
      const panelsHeight = mainDiv.current ? mainDiv.current.parentNode.clientHeight : querySel[0].parentNode.clientHeight;

      const pointclientY = y;
      const assignHeight = Math.floor(panelsHeight) - Math.floor(pointclientY) - 25;
      let numberOfLines = Math.floor(assignHeight / 14);
      return {
        WebkitLineClamp: parseInt(numberOfLines)
      }
    }
    return {}
  }
  const getPointer = () => {
    let mainDivStyle = {
      height: height + "px",
      left: x ? x + "px" : direction === "left" ? "100%" : "0",
      top: y + "px",
      visibility: visibility
    };


    if (contentB && isActive == 1) {
      mainDivStyle.zIndex = 103;
    }
    let halfHeight = height / 2;

    let blockDiv = {
      backgroundColor: fill,
      color: lcolor
    };

    let arrow = {
      borderTop: halfHeight + "px solid transparent",
      borderBottom: halfHeight + "px solid transparent"
    };

    if (direction === "left") {
      arrow.borderRight = halfHeight + "px solid " + fill;
    }
    else {
      arrow.borderLeft = halfHeight + "px solid " + fill;
    }
    if (contentL && contentL?.search("NaN") < 0) {
      if (direction === "left") {
        return (
          <div className="pointer-hold righter"  ref={mainDiv} style={mainDivStyle}>

            <div className="pointer-data" style={blockDiv}>
              <div className="pointer-arrow" style={arrow}></div>
              <span className="pointer-left" style={{ color: lcolor }} >{contentL}</span>
              <span className="pointer-right" style={{ color: rcolor }} >{contentR}</span>

            </div>
            {hasBox && getBox()}

            {contentB && isActive == 1 ?
              <div className="pointer-notification">
                <div className="pointer-note" style={getNumberOfLines()}>Note:&nbsp;{contentB}</div>
              </div> : ""
            }
          </div>
        );
      }
      else {
        return (
          <div className="pointer-hold lefter" style={mainDivStyle}>
            <div className="pointer-data" style={blockDiv}>
              <div className="pointer-arrow" style={arrow}></div>
              <span className="pointer-center">{content}</span>
            </div>
          </div>
        );
      }
    }
    else
      return (<div></div>);
  }

  TimeTrackingWindow.trackChartLoadingTime();
  return getPointer();
}

const mapStateToProps = ({appColor})=>{
  const { posDTColor, negDTColor, isThemeChanged  } = appColor;

  return { posDTColor, negDTColor, isThemeChanged }
}
export default connect(mapStateToProps, null, null, { forwardRef: true })(Pointer);