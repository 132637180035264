import { RotationGraphConstants, ComparisonAndRotationMenuConstants } from "Constants/RotationGraphConstants";
const { ActionTypes } = RotationGraphConstants;
import SettingsStore from "SettingsStore";
import moment from "moment";
import ConsoleStore from "ConsoleStore";
import { BenchMarkOptions } from "Constants/RotationMenuType";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import CompareTabType, { CompareMenuType } from "CompareTabType";
import { extend, map } from "underscore";
import { PrintMode } from "../../../print/printmode";

extend(ActionTypes, SettingsConstants.ActionTypes, ComparisonAndRotationMenuConstants.ActionTypes);

const initData = {
    dimensions: '',
    isUptrendVisible: false,
    isDowntrendVisible: false,
    lastAvailableDate: null,
    groupsTimelineDateDictionary: [],
    groupsCountDataList: [],
    benchmarkDataLoaded: false,
    benchMarkData: null,
    selectedSliderValue: null,
    isBenchMarkLoading: true,
    isPlaying: false,
    isSliderReset: false,
    benchMarkOption: ''
}

const initialState = {
    selectedMenuTab: CompareMenuType.Symbols,
    globalCountDataList: {},
    SymbolsData: { ...initData },
    GroupsData: { ...initData },
    selectedTab: null
}

const BenchMarkReducer = (state = initialState, action) => {

    let localStateData;
    let settings;
    const setStateData = (obj) => {

        if (state.selectedMenuTab === CompareMenuType.Symbols) {
            map(obj, (value, property) => state.SymbolsData[property] = value);
        }
        else {
            map(obj, (value, property) => state.GroupsData[property] = value);
        }

        return { ...state };
    }


    const getStateData = () => {
        if (state.selectedMenuTab === CompareMenuType.Symbols) {
            return state.SymbolsData;
        }
        else {
            return state.GroupsData;
        }
    }

    const getSettings = () => {
        const settings = SettingsStore.getConsoleSettings().NavCompareSettings.TabRotationGraphSettings;
        return state.selectedMenuTab === CompareMenuType.Symbols ? settings.rotationSymbolsMenuSettings : settings.rotationGroupsMenuSettings;
    }

    switch (action.type) {

        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
            return {
                ...state,
                selectedTab: action.selectedTab
            }

        case ActionTypes.SET_COMPARE_MENUTAB:
            if (state.selectedTab === CompareTabType.RotationGraph) {
                return {
                    ...state,
                    selectedMenuTab: action.menuTab
                }
            } else {
                return { ...state }
            }

        case ActionTypes.UPDATE_FROM_USER_SETTINGS_DATA:
            settings = getSettings();
            let selectedSliderValue = null;

            if (state.selectedMenuTab === CompareMenuType.Symbols) {
                if (PrintMode.printing) {
                    if (settings.benchMarkOption === BenchMarkOptions.Country) {
                        selectedSliderValue = settings.timeLineIndex_country;
                    }
                    else if (settings.benchMarkOption === BenchMarkOptions.Regional) {
                        selectedSliderValue = settings.timeLineIndex_regional;
                    }
                    else if (settings.benchMarkOption === BenchMarkOptions.Global) {
                        selectedSliderValue = settings.timeLineIndex_global;
                    }
                }
                else {
                    selectedSliderValue = settings.timeLineIndex_country;
                }
            }
            else {
                selectedSliderValue = settings.timeLineIndex;
            }

            return setStateData({
                isUptrendVisible: settings.isUptrendVisible,
                isDowntrendVisible: settings.isDowntrendVisible,
                isPlaying: settings.isTimeLinePlaying,
                selectedSliderValue: selectedSliderValue,
                benchMarkOption: PrintMode.printing ? settings.benchMarkOption : BenchMarkOptions.Country
            });

        case ActionTypes.UPDATE_BENCHMARK_DIMENSION_CHANGE:
            return setStateData({
                dimensions: action.dimensions
            });

        case ActionTypes.UPDATE_BENCHMARKDATA_LOADING_STATUS:
            return setStateData({
                isBenchMarkLoading: action.isBenchMarkLoading
            });

        case ActionTypes.UPDATE_BENCHMARK_DATA:
            return setStateData({
                benchMarkData: action.result
            });
        case ActionTypes.UPDATE_GROUPS_TIMELINE:
            return setStateData({
                groupsTimelineDateDictionary: action.groupsTimelineDateDictionary
            });
        case ActionTypes.TOGGLE_TREND_LINES:
            settings = getSettings();
            localStateData = getStateData();
            settings.isUptrendVisible = action.isUpTrend ? !localStateData.isUptrendVisible : localStateData.isUptrendVisible,
                settings.isDowntrendVisible = action.isUpTrend ? localStateData.isDowntrendVisible : !localStateData.isDowntrendVisible
            SettingsStore.saveSettings();
            return setStateData({
                isUptrendVisible: action.isUpTrend ? !localStateData.isUptrendVisible : localStateData.isUptrendVisible,
                isDowntrendVisible: action.isUpTrend ? localStateData.isDowntrendVisible : !localStateData.isDowntrendVisible
            });

        case ActionTypes.SET_SLIDER_VALUE:
            {
                localStateData = getStateData();
                settings = getSettings();
                if (state.selectedMenuTab === CompareMenuType.Symbols) {
                    if (localStateData.benchMarkOption === BenchMarkOptions.Country) {
                        settings.timeLineIndex_country = action.selectedSliderValue;
                    }
                    else if (localStateData.benchMarkOption === BenchMarkOptions.Regional) {
                        settings.timeLineIndex_regional = action.selectedSliderValue;
                    }
                    else if (localStateData.benchMarkOption === BenchMarkOptions.Global) {
                        settings.timeLineIndex_global = action.selectedSliderValue;
                    }
                }
                else {
                    settings.timeLineIndex = action.selectedSliderValue;
                }

                SettingsStore.saveSettings();
                return setStateData({
                    selectedSliderValue: action.selectedSliderValue
                });
            }

        case ActionTypes.UPDATE_TIMELINE_INDEX:
            // settings = getSettings();
            localStateData = getStateData();
            if (action.isSave) {
                // settings.timeLineIndex = localStateData.selectedSliderValue;
                // SettingsStore.saveSettings();
                ConsoleStore.trackUsage('SliderDate Changed', moment(localStateData.groupsTimelineDateDictionary[localStateData.selectedSliderValue]).format('MM/DD/YYYY'));
            }
            return setStateData({
                selectedSliderValue: action.selectedSliderValue !== null ? action.selectedSliderValue : localStateData.selectedSliderValue
            });

        case ActionTypes.UPDATE_IS_TIMELINE_PLAYING:
            {
                settings = getSettings();
                settings.isTimeLinePlaying = action.isTimeLinePlaying;
                SettingsStore.saveSettings();
                return setStateData({
                    isPlaying: action.isTimeLinePlaying
                });
            }
        case ActionTypes.UPDATE_IS_SLIDER_RESET:
            return setStateData({
                isSliderReset: action.isSliderReset
            });

        case ActionTypes.TOGGLE_BENCHMARK_SELECTOR_SUCCESS:
            {
                settings = getSettings();
                let selectedSliderValue = null;

                if (action.benchMarkOption === BenchMarkOptions.Country) {
                    selectedSliderValue = settings.timeLineIndex_country;
                }
                else if (action.benchMarkOption === BenchMarkOptions.Regional) {
                    selectedSliderValue = settings.timeLineIndex_regional;
                }
                else if (action.benchMarkOption === BenchMarkOptions.Global) {
                    selectedSliderValue = settings.timeLineIndex_global;
                }

                return setStateData({
                    benchMarkOption: action.benchMarkOption,
                    selectedSliderValue: selectedSliderValue
                });
            }
        case ActionTypes.UPDATE_GLOBAL_TREND_DATA:
            return {
                ...state,
                globalCountDataList: action.globalCountDataList
            }

        case ActionTypes.UPDATE_GROUPS_COUNT_DATA_LIST:
            return setStateData({
                groupsCountDataList: action.groupsCountDataList
            });

        case ActionTypes.CLEAR_ROTATION_SYMBOLS_DATA:
            return setStateData({
                benchMarkData: null
            });

        default:
            return state;
    }
}

export default BenchMarkReducer;