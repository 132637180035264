import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addIndexLine, setAddListName } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { CompareMenuType } from "CompareTabType";
import { PrintMode } from "../../../../../print/printmode";

import LocalizationStore from "LocalizationStore";

class CompCreateIndexLine extends Component {
    handleCreateIndexLine = () => {
        const { addIndexLine, isCreateIndexLineEnable } = this.props;
        if (isCreateIndexLineEnable){
            this.props.setAddListName();
            addIndexLine();
        }
            
    }

    render() {
        return (
            <div className="compare-foot-box">
                {!PrintMode.printing && <span className={`blue-txt ${!this.props.isCreateIndexLineEnable ? 'disabled' : ''}`} onClick={this.handleCreateIndexLine}>{LocalizationStore.getTranslatedData("CCG_CreateIndexLine", "Create Index Line")}</span>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.compareChartViewModel;
    const compareChartViewModel = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;
    const isCreateIndexLineEnable = compareChartViewModel.symbolRequest.length >= 2;

    return ({ isCreateIndexLineEnable });
}

const mapDispatchToProps = (dispatch) => ({
    addIndexLine: () => dispatch(addIndexLine()),
    setAddListName: () =>dispatch(setAddListName())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompCreateIndexLine);