import BaseServiceApi from 'BaseServiceApi';
import CalcFactorResearch from "../../../Utils/Calcs/CalcFactorResearch.js";
import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import DatagraphDataType from "../../../Constants/DatagraphDataType.js";
import GraphApi from "../../../ServiceApi/Apis/GraphApi.js";
import GraphType from "GraphType";
import MiniListHelper from "MiniListHelper";
import PeriodicityType from "PeriodicityType";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import TimeTrackingWindow from "TimeTrackingWindow";
import UserInfoUtil from '../../../Utils/UserInfoUtil.js';
import { priceChartReducerselect, getDatagraphStates, getTimeLine, getFactorStates } from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { takeLatest, select, call, put, fork, takeEvery } from "redux-saga/effects";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
const { ActionTypes } = DataGraphConstants;
function* ProcessFrData({ resize = false }) {
    try {
        const { isPricePanelDataReady, viewsSettings, periodicity, SymbolInfo, rightScaleWidth, leftScaleWidth, nodeCount } = yield select(getDatagraphStates);
        const factorSettings = viewsSettings.FactorResearchSettings;
        const { factorEventData } = yield select(getFactorStates);
        if (isPricePanelDataReady && (periodicity === GraphType.Daily || periodicity === GraphType.Weekly) && factorEventData.factorEventChartData?.length >= 0) {
            const { endDate, HiLowPoints, scale, chartWidth, chartHeight } = yield select(priceChartReducerselect);
            const timeline = yield select(getTimeLine);
            const state = { periodicity, endDate, HiLowPoints, scale, timeline, chartWidth, rightScaleWidth, leftScaleWidth, SymbolInfo, nodeCount, chartHeight };

            TimeTrackingWindow.beginFactorRenderTimeTracker();
            const calcFr = new CalcFactorResearch();
            const [FRData, mostRecentNode] = calcFr.CalculateFRData(factorEventData, factorSettings, !resize, state);

            if (FRData?.length > 0) {
                yield put({
                    type: ActionTypes.FR_DATA_READY, FRData, mostRecentNode, IsResize: resize, factorSettings: factorSettings,
                    Symbol: SymbolInfo.Symbol, periodicity: periodicity, _state: state
                });
                const event = mostRecentNode;
                if (event !== null && !resize) { //&& event.upToDate > state.endDate  removing for PANWEB-7284
                    yield fork(getFactorDataSource, { event, resize });
                }
            }
            else {
                yield put({
                    type: ActionTypes.CLEAR_FR_DATA
                });
                yield put({
                    type: ActionTypes.SAVE_FR_DATA_SETTINGS,
                    factorSettings
                })
            }
            TimeTrackingWindow.endFactorLoadEndTimeTracker();
        }
        else {
            const factorSettings = viewsSettings.FactorResearchSettings ? viewsSettings.FactorResearchSettings : "";
            if (factorSettings) {
                yield put({
                    type: ActionTypes.SAVE_FR_DATA_SETTINGS,
                    factorSettings
                })
            }
        }

    }
    catch (error) {
        console.error(`Error occures in FactorResearchSaga.js, ProcessFrData, ${error}`);
        TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.Factor);
        TimeTrackingWindow.endFactorLoadEndTimeTracker();
    }
}
function* getFactorDataSource({ event, resize = false }) {
    try {
        const { SymbolInfo } = yield select(getDatagraphStates);
        if (!resize && event.FactorKey) {
            const eventKeyObj = JSON.parse(event.FactorKey);
            yield put({ type: RiPanelConstants.ActionTypes.UPDATE_FR_LOADING, loading: true });
            const result = yield call(GraphApi.GetFactorEventInfoRequest, SymbolInfo.MsId, eventKeyObj.EventId, eventKeyObj.SegmentId, SymbolInfo.Osid, eventKeyObj.StartDate, eventKeyObj.EventDate)
            const symbol = SymbolInfo ? SymbolInfo.Symbol : '';
            if (MiniListHelper.ActiveSymbolCheck(symbol, SymbolInfo.IsMiniListPlay)) {
                yield put({ type: RiPanelConstants.ActionTypes.UPDATE_FR_LOADING, loading: false });
                yield put({ type: RiPanelConstants.ActionTypes.FACTOREVENT_INFO_DATA, factorEventInfo: result.factorEventInfo, event });
            }
        }
    }
    catch (error) {
        console.log(`Error occures in FactorResearchSaga.js, getFactorDataSource, ${error}`);
    }

}

function* callFRApi({ symbol, periodicity, endDate }) {
    try {
        const isFacotrEntitle = UserInfoUtil.IsUserEntitled(EntitlementType.FACTOR_RESEARCH_Entitlement)
        if (isFacotrEntitle && (periodicity === GraphType.Daily || periodicity === GraphType.Weekly)) {
            try {
                TimeTrackingWindow.isFactorExist = true;
                TimeTrackingWindow.beginFactorTimeTracker();
                const result = yield call(GraphApi.GetFactorEventDataRequest, symbol, PeriodicityType[periodicity], endDate);
                TimeTrackingWindow.endFactorApiTimeTracker();
                if (PeriodicityType[periodicity] !== result.factorEventData.periodicityType) {
                    return;
                }
                yield put({
                    type: ActionTypes.SAVE_FR_DATA_FOR_RESIZE, factorEventData: result.factorEventData
                });
                if (result.factorEventData.factorEventChartData.length === 0) {
                    yield put({ type: ActionTypes.CLEAR_FR_DATA });
                    yield put({ type: RiPanelConstants.ActionTypes.UPDATE_FR_LOADING, loading: false });
                    yield put({ type: ActionTypes.FR_DATA_AVAILABLE, factorsResultAvailable: false })
                } else {  
                    yield call(ProcessFrData, { resize: false });
                }
            }
            catch (error) {
                console.error(error);
                TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.Factor);
                TimeTrackingWindow.endFactorApiTimeTracker();
            }
        }
        else {
            yield put({ type: ActionTypes.CLEAR_FR_DATA });
            yield put({ type: RiPanelConstants.ActionTypes.UPDATE_FR_LOADING, loading: false });
        }
    }
    catch (error) {
        console.log(`Error occurs in FactorResearchSaga.js, callFRApi ${error}`);
    }
}
/****************************watchers************************/
export function* watchInitFRData() {
    yield takeLatest(ActionTypes.INIT_FACTOR_API, callFRApi)
}

export function* watchFRChartResize() {
    yield takeEvery(PriceChartConstants.ActionTypes.PRICE_FR_DATA_READY, ProcessFrData);
}

export function* watchNewFactorClick() {
    yield takeLatest(ActionTypes.CALL_FACTOR_RI_API, getFactorDataSource);
}