import { AnnotationConstant } from "Constants/AnnotationConstants";
import AppColorStore from "Stores/AppColor/AppColorStore";
import { connect } from 'react-redux';
import { LineControl } from '../Presentational/index';
import {
    handleDragStart, handleDragMove, handleDragEnd, handleDeleteAnnotationDrawing, handleToggleContextMenu,
    handleAnchorDragStart, handleAnchorDragEnd, handleAnchorDragMove, handleAlertHighlight, handleRemoveAlertHighlight
} from '../../../../../../Actions/AnnotationActions';
import React, { Component } from 'react';
const AnnotationActionTypes = AnnotationConstant.ActionTypes;

class GraphicsLine extends Component {

    componentDidMount() {
        AppColorStore.addChangeListener(this.AppColorStoreStateChanged);
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.AppColorStoreStateChanged);
    }

    AppColorStoreStateChanged = () => {
        this.forceUpdate();
    }

    render() {
        const { line, index, showAlert, defaultImage, hoverImage, lockImage, isApiReqResolved } = this.props;
        const { isDragging, activeAnchor, isAlertHover, hoveredAnnotId, selectedAnnotId } = this.props.annotationChartModelReducer;


        return (

            <LineControl
                onContextMenu={this.props.onContextMenu}
                key={index}
                index={index}
                line={line}
                handleDragStart={this.props.handleDragStart}
                handleDragMove={this.props.handleDragMove}
                handleDragEnd={this.props.handleDragEnd}
                selectedAnnotId={selectedAnnotId}
                isDragging={isDragging}
                activeAnchor={activeAnchor}
                defaultImage={defaultImage}
                hoverImage={hoverImage}
                lockImage={lockImage}
                isAlertHover={isAlertHover}
                hoveredAnnotId={hoveredAnnotId}
                handleAnchorDragStart={this.props.handleAnchorDragStart}
                handleAnchorDragEnd={this.props.handleAnchorDragEnd}
                handleAnchorDragMove={this.props.handleAnchorDragMove}
                highlightAlert={this.props.highlightAlert}
                removeHighlightAlert={this.props.removeHighlightAlert}
                draggable={this.props.draggable}
                showAlert={showAlert}
                isApiReqResolved={isApiReqResolved}
                handleDeleteAnnotationDrawing={this.props.handleDeleteAnnotationDrawing}
            />

        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { lineReducer, annotationChartModelReducer, annotationMenuReducer } = annotationReducers;
    const { showAlert } = annotationMenuReducer;
    return ({ lineReducer, annotationChartModelReducer, showAlert });
}

const mapDispatchToProps = (dispatch) => ({
    handleDragStart: (line) => dispatch(handleDragStart(line)),
    handleDragMove: (e, isEndExtend, isStartExtend, isStartArrow, isEndArrow) => dispatch(handleDragMove(e, isEndExtend, isStartExtend, isStartArrow, isEndArrow)),
    handleDragEnd: () => handleDragEnd(),
    handleAnchorDragStart: (anchor) => dispatch(handleAnchorDragStart(anchor)),
    handleAnchorDragEnd: () => handleAnchorDragEnd(),
    handleAnchorDragMove: (e) => dispatch(handleAnchorDragMove(e, AnnotationActionTypes.HANDLE_LINE_DRAW)),
    highlightAlert: (alertId) => dispatch(handleAlertHighlight(alertId)),
    removeHighlightAlert: () => dispatch(handleRemoveAlertHighlight()),
    handleDeleteAnnotationDrawing: (charCode) => { dispatch(handleDeleteAnnotationDrawing(charCode)), dispatch(handleToggleContextMenu(false, 0, 0, '')) }
});

export default connect(mapStateToProps, mapDispatchToProps)(GraphicsLine);