import { dispatch } from '../../../../../../Redux/dispatch.js';
import EventUtil from '../../../../../../Utils/EventUtil.js';
import { onPlotSymbol } from '../../../../../../Actions/DatagraphActions.js';
import React from "react";
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import { useSelector } from "react-redux";

function AlphaScopeControl() {
    const positiveBrushText = useSelector((state) => state.appColor.posDTColor)
    const negativeBrushText = useSelector((state) => state.appColor.negDTColor);
    const data = useSelector((state) => state.summaryReducer.stockData)
    const height = 226;
    const width = 350;

    const getHeader = (headerText) => (
        <div className="alpha-th">
            <div className="th-left-item">{headerText}</div>
            <div className="th-right-item">
                <div className="th-right-item-info percentage">%</div>
                <div className="th-right-item-info">{SummaryTransLateHelper.CHANGE_PERCENT}</div>
            </div>
        </div>
    )
    const setItemActive = (e, symbol) => {
        dispatch(onPlotSymbol(symbol));
    }
    const getItem = (item, index) => {
        if (item.percChange.indexOf("+") !== -1) {
            item.brush = positiveBrushText
        }
        if (item.percChange.indexOf("-") !== -1) {
            item.brush = negativeBrushText
        }

        return (
            <div className="alpha-td" key={index}>
                <div className="alpha-td-item">
                    <div className="alpha-td-item-info link-txt" onClick={(e) => setItemActive(e, item.symbol)}>{item.symbol}</div>
                    <div className="alpha-td-item-info" style={{ color: item.brush }}>{item.percChange}</div>
                </div>
                <div className="alpha-td-item">
                    <p onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, item.companyName)} onFocus={(e) => EventUtil.mouseOverShowTitle(e, item.companyName)}>{item.companyName}</p>
                </div>
            </div>
        )
    }
    const getBlankItem = (index) => (
        <div className="alpha-td" key={index}></div>
    )

    const getColumn = (headerText, data) => {
        const header = getHeader(headerText);
        const length = data.length;
        const addItems = 3 - length;
        if (data.length < 3) {
            for (let i = 0; i < addItems; i++) {
                data.push({});
            }
        }
        return (
            <div className="alpha-column">
                {header}
                {data.map((item, index) => {
                    if (item.symbol) {
                        return getItem(item, index);
                    } else {
                        return getBlankItem(index);
                    }
                })}
            </div>
        )
    }
    const newColumn = getColumn(SummaryTransLateHelper.NEW_CHANGE_PERCENT, data.stockNewSource);
    const asDecreasingColumn = getColumn(SummaryTransLateHelper.DECREASE_CHANGE_PERCENT, data.stockDecreaseSource);
    const asIncreasingColumn = getColumn(SummaryTransLateHelper.INCREASE_CHANGE_PERCENT, data.stockIncreaseSource);

    return (
        <div className="alpha-grid" style={{ height: height, width: width }}>
            <div className="info-title">ALPHASCOPE</div>
            <div className="alpha-table">
                {newColumn}
                {asIncreasingColumn}
                {asDecreasingColumn}
            </div>
        </div>
    );
}
export default AlphaScopeControl