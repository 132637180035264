import React from "react";
import FundamentalsActions from "Actions/FundamentalsActions.js";
import TabFundamentalsStore from 'Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js';
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js';
import FundamentalTabFilterType from 'Constants/FundamentalTabFilterType.js';
import FundamentalTabTemplateType from 'Constants/FundamentalTabTemplateType.js';
import TabDataGraphStore from "Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore.js"
import SymbolType from "SymbolType";
import ConsoleStore from "ConsoleStore";
import LocalizationStore from "LocalizationStore";
import SettingsStore from "SettingsStore";
import { SettingsConstants } from "Constants/SettingsConstants.js";

export default class FundamentalsExplorerPanel extends React.Component {
    constructor() {
        super();
        this.setClassName = this.setClassName.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this.filterhistory = [];
        this.rightActive = true;
    }

    UNSAFE_componentWillMount() {
        document.addEventListener("keydown", this.handleKeyPressed, false);
    }
  
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPressed, false);
    }      
  
    handleKeyPressed(e) { 
    let state =  TabFundamentalsStore.getReportState();
    this.rightActive = state;
      let settings = TabFundamentalsStore.getState();
      let currentReport = settings.FundamentalReportType;
      const consoleSettings = SettingsStore.getConsoleSettings();
      const isCurrentKeyDown = !consoleSettings.KeyDownActiveZone || consoleSettings.KeyDownActiveZone === SettingsConstants.KeyDownActiveZoneType.FUNDAMENTAL_TAB;
      if(e.keyCode === 38 && currentReport > 1 && !this.rightActive && isCurrentKeyDown) {
        this.handleClick(currentReport,'up');
      }
      if(e.keyCode === 40 && currentReport < 4 && !this.rightActive && isCurrentKeyDown) {
        this.handleClick(currentReport, null);
      }
    }
    
    handleClick(index, keyPressed=null) {
        FundamentalsActions.setReportClickState(false);
        this.rightActive = false;
        let settings = TabFundamentalsStore.getState();
        this.filterhistory.push(settings.FundamentalFilter)
        let previousFilter = settings.FundamentalFilter
        let previousReport = settings.FundamentalReportType;
        if(keyPressed === 'up') {
            FundamentalsActions.setReportType(index-1);
        }
        else {
            FundamentalsActions.setReportType(index+1);
        }
        this.props.updateLoadingState();
        if (settings.FundamentalReportType == FundamentalTabReportType.Cashflow && settings.FundamentalFilter == FundamentalTabFilterType.Commonsize) {
            FundamentalsActions.setReportFilter(FundamentalTabFilterType.All);
        }

        if (previousFilter && previousFilter == FundamentalTabFilterType.All && previousReport == FundamentalTabReportType.Cashflow && settings.FundamentalReportType != FundamentalTabReportType.Cashflow && (this.filterhistory[0] && this.filterhistory[0] == FundamentalTabFilterType.Commonsize)) {
            FundamentalsActions.setReportFilter(FundamentalTabFilterType.Commonsize);
        }

        if (settings.FundamentalFilter == FundamentalTabFilterType.All && settings.FundamentalReportType != FundamentalTabReportType.Ratios) {
            FundamentalsActions.getAllFilterReportData(settings);
        } else { FundamentalsActions.getReportData(settings); }

        if (this.filterhistory.length == 2) this.filterhistory = [];

        //Log Usage
        ConsoleStore.trackUsage(this.getFundamentalTabReportType(settings.FundamentalReportType) + 'Selected', this.getFundamentalTemplate(settings.FundamentalTemplate));
    }

    setClassName(index) {
        let currentIndex = index + 1;
        let settings = TabFundamentalsStore.getState();
        let currentReport = settings.FundamentalReportType;
        if (currentIndex == currentReport) { return "active"; }
        else { return "list"; }
    }

    getFundamentalTemplate(type) {
        let templateType = (type == FundamentalTabTemplateType.Standard) ? "Template:Standard" : "Template:Industrial";
        return TabDataGraphStore.getActiveStockType() != SymbolType.INTERNATIONALSTOCK ? templateType : null;
    }

    getFundamentalTabReportType(reportType) {
        switch (reportType) {
            case FundamentalTabReportType.Income:
                return 'IncomeStatement';
            case FundamentalTabReportType.Balancesheet:
                return 'Balancesheet';
            case FundamentalTabReportType.Cashflow:
                return 'Cashflow';
            case FundamentalTabReportType.Ratios:
                return 'Ratios';
            case FundamentalTabReportType.Wondastockreport:
                return 'Wondastockreport';
            default:
            return null;
        }
    }

    render() {
        let reportTypes = [];
        reportTypes.push(LocalizationStore.getTranslatedData('fr_reportNav_01', 'Income Statement'));
        reportTypes.push(LocalizationStore.getTranslatedData('fr_reportNav_02', 'Balance Sheet'));
        reportTypes.push(LocalizationStore.getTranslatedData('fr_reportNav_03', 'Cash Flow'));
        reportTypes.push(LocalizationStore.getTranslatedData('fr_reportNav_04', 'Ratios'));
        // reportTypes.push(LocalizationStore.getTranslatedData('fr_reportNav_05', 'Stock Report'));

        let self = this;
        let rows = reportTypes.map((reportTypes, index) => {
            let setClassNameFunc = self.setClassName(index);
            let handleClickFunc = self.handleClick.bind(null, index, null);
            return (<li key={index + 1} className={setClassNameFunc}>
                <div className="fund-explore-list medium-normal" key={index + 1} onClick={handleClickFunc}><span className="icn-explore-list" />{reportTypes}</div>
            </li>);
        });

        return (
            <ul className="exploreList">
                {rows}
            </ul>

        );
    }
}