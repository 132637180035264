import { connect } from "react-redux";
import DataRow from "./DataRow.jsx";
import Measure from "react-measure";
import ThemeHelper from "ThemeHelper";
import React, { Component } from "react";

class TabularData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: { width: 0, height: 0 }
    };
    this._dataRow = new Map();
  }

  drawGrid() {
    const rows = this.props.SponsorRanks3;
    if (!this.grid || !rows || rows === null ||
      rows.length === 0 || !this.state.dimensions ||
      this.state.dimensions.height === 0.0 || this.state.dimensions.width === 0.0
    ) {
      return;
    }

    const width = this.state.dimensions.width;
    const rowHeight = this.props.RowHeight;
    const ctx = this.grid.getContext("2d");
    ctx.canvas.height = this.state.dimensions.height;
    ctx.canvas.width = this.state.dimensions.width;
    ctx.lineWidth = 1;
    ctx.imageSmoothingEnabled = true;
    ctx.translate(0.5, 0.5);
    ctx.save();
    ctx.strokeStyle = ThemeHelper.getThemedBrush("financialDividerLines");
    ctx.fillStyle = ThemeHelper.getThemedBrush("zebraStripeColor");
    const length = rows.length;
    for (let i = 0; i < length; i++) {
      this.getVericalGrid(rows[i], ctx, i, i === length - 1);
      ctx.beginPath();
      ctx.moveTo(0, (i * rowHeight));
      ctx.lineTo(width, (i * rowHeight));
      ctx.stroke();
      ctx.closePath();
    }
  }

  getVericalGrid(row, ctx, index, isLastRow) {
    // ctx.strokeStyle = "#25C73A";
    // ctx.fillStyle = "#2A25C7";
    //ctx.lineWidth=5;
    let mergeFactor = 0;
    let mergeItemCount = 1;
    let zebraStripeIndex = 0;
    const height = isLastRow ? this.props.RowHeight + 2 : this.props.RowHeight;
    const width = this.state.dimensions.width;
    ctx.beginPath();
    const cellitems = row;
    const length = cellitems.length;
    const widthAdjust = cellitems[0].XPoint * this.props.nodeWidth;
    for (let i = 0; i < length; i++) {
      // if(i>1){
      //   break;
      // }
      let isZebraStripe = false;
      const cellItem = cellitems[i];
      if (mergeFactor > 0) {
        mergeFactor--;
        continue;
      }
      isZebraStripe = (zebraStripeIndex - mergeItemCount + 1) % 2 === 1;
      const xAxis = (width - (cellItem.XPoint * this.props.nodeWidth)) + widthAdjust;
      if (isZebraStripe) {
        const isWithIndex = i - 1 >= 0;
        if (isWithIndex) {
          const previousCell = cellitems[i - 1];
          const previousCellXAxis = (width - (previousCell.XPoint * this.props.nodeWidth)) + widthAdjust;
          ctx.fillRect(xAxis, 20 * index, (previousCellXAxis - xAxis) + 1, height);
        }
      }
      mergeItemCount = cellItem.DisplayFlag !== true && (i - 1) >= 0 && cellItem.QuarterKey === cellitems[i - 1].QuarterKey ? mergeItemCount + 1 : mergeItemCount = 1;
      zebraStripeIndex++;
      if (cellItem.DisplayFlag && cellItem.DisplayFlag === true || ((i - 1) >= 0 && cellItem.QuarterKey !== cellitems[i - 1].QuarterKey)) {
        ctx.moveTo(xAxis, 20 * index);
        ctx.lineTo(xAxis, 20 * index + height);
      }
    }
    ctx.stroke();
    ctx.closePath();
  }

  onHover(index, hover) {
    const row = this._dataRow.get(index);
    if (row) {
      row.onHover(hover);
    }
  }

  syncHover(index, hover) {
    this.props.syncHover(index, hover);
    // this.onHover(index, hover);
  }

  onToggleHeader(index) {
    const row = this._dataRow.get(index);
    if (row) {
      row.reDraw();
    }
  }
  render() {
    try {
      this.drawGrid();
      // var gridLines = grid.grid;
      // var zebraStripe = grid.zebraStripe;
    }
    catch (e) {
      console.error(e);
    }

    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          this.setState({ dimensions: contentRect.bounds })
        }}
      >
        {({ measureRef }) =>
          <div ref={measureRef} className="ownership-block-holder">
            <canvas className="ownership-block-data-canvas" ref={(ref) => { this.grid = ref; }}>
            </canvas>
            <div className="ownership-block-data-hold" id="data">
              {this.props.SponsorRanks3.map((row, index) => {
                const key = `datarow${index}`;
                return <DataRow ref={(c) => this._dataRow.set(index, c)}
                  row={row} key={key}
                  syncHover={(index, hover) => this.syncHover(index, hover)}
                  rowIndex={index}
                  width={this.state.dimensions.width}
                />;
              })}
            </div>
          </div>
        }
      </Measure>
    );
  }
}

const mapStateToProps = (state) => {
  const { RowHeight, SponsorRanks3, nodeWidth } = state.DatagraphReducers.ownershipBlockReducer;
  const { isThemeChanged } = state.appColor
  return { RowHeight, SponsorRanks3, nodeWidth, isThemeChanged }
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(TabularData)
