import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import { ActionTypes } from "../../Constants/AppColorConstants";

const CHANGE_EVENT = "change";
const CURRENT_COLORS = "current_colors";
var _state = {};
var _currentAction = "";
class AppColorStoreClass extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getAction() {
        return _currentAction;
    }
    getState() {
        return _state;
    }


    dispatcherCallback(payload) {
        const action = payload.action;
        switch (action.actionType) {
            case ActionTypes.RESTORE_DARK_COLOR:
                _currentAction = ActionTypes.RESTORE_DARK_COLOR;
                this.emit(CHANGE_EVENT);
                break;
            case ActionTypes.RESTORE_LIGHT_COLOR:
                _currentAction = ActionTypes.RESTORE_LIGHT_COLOR;
                this.emit(CHANGE_EVENT);
                break;
            case ActionTypes.CUSTOM_COLOR_CHANGED:
                _currentAction = ActionTypes.CUSTOM_COLOR_CHANGED;
                _state[[CURRENT_COLORS]] = action.data;
                this.emit(CHANGE_EVENT);
                break;
            case ActionTypes.CUSTOM_THEME_CHANGED:
                _currentAction = ActionTypes.CUSTOM_THEME_CHANGED;
                this.emit(CHANGE_EVENT);
                break;
            default:
                return true;
        }
    }
}

const AppColorStore = new AppColorStoreClass();
export default AppColorStore;
