import {
    watchInitIndexLine,
    watchOpenindexLineEditDialog,
    watchSaveIndexLineEditDialog
} from './IndexLineSaga';
import {
    watchInitMALines,
    watchOpenMALineDialog,
    watchSaveMALineDialog,
    watchDeleteMALine
} from './MALineSaga'
import {
    watchInitPatternRec,
    watchShowPatternRecDialog,
    watchSavePatternRecDialog
} from './PRSaga'
import {
    watchInitPriceMenu,
    watchPreparePriceMenu,
    watchPriceMenuItemClicked
} from './PriceMenuSaga';
import {
    watchInitRSLine,
    watchOpenRSLineEditDialog,
    watchSaveRSLineEditDialog
} from './RSLineSaga';
import {
    watchPrepareCorpEvents,
    watchOpenCorpEventEditDialog,
    watchSaveCorpEventEditDialog,
    watchRestoreCorpEventSetting
} from './CorpEventsSaga'
export const PriceMenuWatchers = {
    watchInitPriceMenu,
    watchPreparePriceMenu,
    watchPriceMenuItemClicked,
    watchInitIndexLine,
    watchOpenindexLineEditDialog,
    watchSaveIndexLineEditDialog,
    watchInitRSLine,
    watchOpenRSLineEditDialog,
    watchSaveRSLineEditDialog,
    watchInitPatternRec,
    watchShowPatternRecDialog,
    watchSavePatternRecDialog,
    watchPrepareCorpEvents,
    watchOpenCorpEventEditDialog,
    watchSaveCorpEventEditDialog,
    watchRestoreCorpEventSetting,
    watchInitMALines,
    watchOpenMALineDialog,
    watchSaveMALineDialog,
    watchDeleteMALine
};