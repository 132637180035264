import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AndAlertRetrieveRequest extends BaseServiceRequest {

    constructor(sourceId, sourceTypeId,alertId,msId,currencyCode) {
        super();
        super.addRequestUri("andalertretrieve.pb");
        super.addRequestParameter("sourceId", sourceId);
        super.addRequestParameter("sourceTypeId", sourceTypeId);
        super.addRequestParameter("alertId", alertId);
        super.addRequestParameter("msId",msId);
        super.addRequestParameter("currencyCode",currencyCode);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "AndAlertResponseData";
    }
}

export default AndAlertRetrieveRequest;
