import { connect } from "react-redux";
import { onPlotSymbol } from "../../../../Actions/DatagraphActions";
import React from "react";
import textWidth from "text-width";
import ThemeHelper from "ThemeHelper";
import VirtualList from "VirtualList";
import { RiPanelConstituentsTranslateHelper, TranslateHelper } from "../../../../Utils/TranslateHelper";

class RIPanelConstituentsInfo extends React.Component {
    getTextWidth(str) {
        const strWidth = textWidth(str, {
            family: "calibri",
            size: 13
        });
        return strWidth;
    }

    mouseOverShowTitle(event, title) {
        if (!title) {
            return;
        }
        if (event.target.hasAttribute('title')) {
            event.target.removeAttribute("title");
        }
        const symbolTextWidth = this.getTextWidth(title);
        if (symbolTextWidth + parseInt(event.target.style.paddingLeft) + parseInt(event.target.style.paddingRight) > event.target.offsetWidth && !event.target.hasAttribute('title')) {
            event.target.setAttribute("title", title);
        }
    }

    getItemRenderer(item, top, index) {
        if (item) {
            let style = "price-chg positive";
            let colr = this.props.posDTColor;
            if (item.pctOfChangevs4weeksString < 0) {
                colr = this.props.negDTColor;
                style = "price-chg negative"
            } else if (item.pctOfChangevs4weeksString > 0) {
                colr = this.props.posDTColor;
                style = "price-chg positive"
            } else {
                colr = ThemeHelper.getThemedBrush("textDefault");
                style = "price-chg"
            }
            const symbol = item.localSymbol ? item.localSymbol : item.symbol;
            return (
                <div className="constituents-item" key={index} style={{ top: top }}>
                    <div className="constituentsList-grid">
                        <div className="symbol" style={{ paddingLeft: "5px", paddingRight: "0px" }} onClick={() => { this.props.onPlotSymbol(item.symbol) }} onMouseOver={(e) => this.mouseOverShowTitle(e, symbol)}
                            onFocus={(e) => this.mouseOverShowTitle(e, symbol)}>{symbol}</div>
                        <div className="name" style={{ paddingLeft: "3px", paddingRight: "3px" }} onMouseOver={(e) => this.mouseOverShowTitle(e, item.name)}
                            onFocus={(e) => this.mouseOverShowTitle(e, item.name)}>{item.name}</div>
                        <div className="mkt-weight">{item.mrkValueString}</div>
                        <div className={style} style={{ color: colr }}>{`${item.pctOfChangevs4weeksString}%`}</div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="constituentsInfo">
                {this.props.loading ?
                    <div className="news-message large-normal"><p>{TranslateHelper.Loading}</p></div>
                    : this.props.isUserEntitled && this.props.constituentsData.length > 0 ?
                        <>
                            <div className="setting-bar-fundamental">
                                <div className="setting-constituents">
                                    <div className="symbol"><span>Symbol</span></div>
                                    <div className="name"><span>{this.props.nameMessage}</span></div>
                                    <div className="mkt-weight">{this.props.messageWeightOrValue}</div>
                                    <div className="pctChg">{this.props.priceChangeMessage}</div>
                                </div>
                            </div>
                            <div className="constituents-content">
                                <VirtualList id="RIPanelConstituentsInfo"
                                    items={this.props.constituentsData}
                                    itemHeight={this.props.itemHeight}
                                    itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                                    showScrollOnHover={true}
                                />
                            </div>
                        </>
                        : this.props.isFocusListSymbol ?
                            <div>
                                <div className="constituents-msgTitle large-normal"><p>Entitlement Required</p></div>
                                <div className="constituents-message medium-normal">
                                    <p>Please contact your William O'Neil Sales Representative or call (800) 545-8940 for constituent access</p>
                                </div>
                            </div>
                            :
                            <div className="constituents-content">
                                <div className="constituents-message large-normal"><p>{RiPanelConstituentsTranslateHelper.NOT_AVAILABLE}</p></div>
                            </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ RelatedInfoPanelReducers, appColor }) => {
    const { loading, constituentsData, isUserEntitled, isFocusListSymbol, messageWeightOrValue, priceChangeMessage, nameMessage, itemHeight } = RelatedInfoPanelReducers.RiPanelConstituentsReducer;
    const { posDTColor, negDTColor } = appColor;
    return { posDTColor, negDTColor, loading, constituentsData, isUserEntitled, isFocusListSymbol, messageWeightOrValue, priceChangeMessage, nameMessage, itemHeight };
}
const mapDispatchToProps = (dispatch) => ({
    onPlotSymbol: (symbol) => dispatch(onPlotSymbol(symbol))
})
export default connect(mapStateToProps, mapDispatchToProps)(RIPanelConstituentsInfo)