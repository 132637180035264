import React, { PureComponent } from "react";
export default class CustomToggle extends PureComponent{
    handleClick = (e) => {
      e.preventDefault();
      this.props.onClick(e);
    };
  
    render() {
      return (
        <a href="" onClick={this.handleClick}>
          {this.props.children}
        </a>
      );
    }
  }
  
  const handle = (props) => {
    const { value, dragging, index, handleClick, ...restProps } = props;
    const handleClicks = () => {
      handleClick(index);
    }
    return (
      <Handle
        key={index}
        onClick={handleClicks}
        value={value}
        {...restProps}
      />
    );
  };