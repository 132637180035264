import AnnotationUtil from "../../../../../../Utils/AnnotationUtil";
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import { Modal } from "react-bootstrap";
import {
    handleDeleteAnnotationDrawing, handleDeleteConfirmPopup, handleConfirmDeleteAllAnnotation, handleLayerDeleteConfirmPopup, onFooterAction, toggleVisibility,
    handleLayerVisibilityConfirmPopup, handleReadOnlyLayerConfirmPopup
} from "../../../../../../Actions/AnnotationActions";
import React, { Component, Fragment } from "react";

class PopUpWindow extends Component {

    constructor(props) {
        super(props);
        this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyPress, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onDocumentKeyPress, false);
    }

    onDocumentKeyPress(e) {
        const charCode = (e.which) ? e.which : e.keyCode;
        const { showDeleteConform, showDeleteLayerConfirm, layerVisibilityConfirm, readOnlyLayerSelectConfirm, selectedToolType, selectedAnnotId, orderedLayersList } = this.props;

        if (charCode === KeyCodes.ENTER && (showDeleteConform || showDeleteLayerConfirm || layerVisibilityConfirm || readOnlyLayerSelectConfirm)) {
            e.stopPropagation();
            this.onConfirm();
        }

        else if (charCode === KeyCodes.DELETE && !document.body.classList.contains("symbol-entry-focused") && document.getElementsByClassName("dialog-open").length === 0) {
            if(selectedToolType === 0 && selectedAnnotId.length === 0 && !document.body.classList.contains("annotation-active") && AnnotationUtil.isAnnotationsTabActive()) {
                e.stopPropagation();
                orderedLayersList.length > 1 && this.props.confirmLayerDelete(true);
            }
        }
    }

    onCancel = () => {
        const { showDeleteConform, showDeleteLayerConfirm, layerVisibilityConfirm } = this.props;

        if (showDeleteConform) {
            this.props.confirmDelete(false);
        }
        else if (showDeleteLayerConfirm) {
            this.props.confirmLayerDelete(false);
        }
        else if (layerVisibilityConfirm) {
            this.props.handleLayerVisibilityConfirmPopup(false);
        }
    }

    onConfirm = () => {
        const { showDeleteConform, showDeleteLayerConfirm, layerVisibilityConfirm, readOnlyLayerSelectConfirm, isDeleteAll, selectedLayer, orderedLayersList } = this.props;

        if (showDeleteConform) {
            if (isDeleteAll) {
                this.props.confirmAllDelete()
            } else {
                this.props.deleteAnnotDraw(KeyCodes.DELETE);
            }
        }

        else if (showDeleteLayerConfirm) {
            this.props.onFooterAction('delete')
            if (orderedLayersList && orderedLayersList[0].layerID === selectedLayer.layerID) {
                const layerScrollBar = document.getElementById("annotateIntrumentsSelect")
                if (layerScrollBar) {
                    window.setTimeout(() => { layerScrollBar.scrollTop = 0 }, 0);
                }
            }
            this.props.confirmLayerDelete(false);
        }

        else if (layerVisibilityConfirm) {
            this.props.toggleVisibility(selectedLayer);
            this.props.handleLayerVisibilityConfirmPopup(false);
        }

        else if (readOnlyLayerSelectConfirm) {
            this.props.handleReadOnlyLayerConfirmPopup(false);
        }
    }

    render() {
        const { showDeleteConform, showDeleteLayerConfirm, layerVisibilityConfirm, readOnlyLayerSelectConfirm, isDeleteAll, selectedLayer } = this.props;
        if (!(showDeleteConform || showDeleteLayerConfirm || layerVisibilityConfirm || readOnlyLayerSelectConfirm)) {
            return null;
        }

        const action = showDeleteLayerConfirm ? "deleteLayer" : layerVisibilityConfirm ? "toggleVisibility" : readOnlyLayerSelectConfirm ? "readOnlyLayerSelect" : "";
        const title = action === "toggleVisibility" ? "TARGET LAYER NOT VISIBLE" : action === "deleteLayer" ? "DELETE ANNOTATIONS LAYER" : action === "readOnlyLayerSelect" ? "READ ONLY TARGET LAYER SELECTED" : "DELETE ANNOTATIONS";
        let selectedLayerName = selectedLayer ? selectedLayer.isSharedLayer ? `${selectedLayer.layerName} (${selectedLayer.ownerName})` : selectedLayer.layerName : '';
        selectedLayerName = selectedLayerName.length > 20 ? `${selectedLayerName.substring(0, 20)}...` : selectedLayerName;

        return (
            <div id="delete-annotation">
                <Modal className="modal-popup annotation-confirm dialog-open" show={true}>
                    <Modal.Header>
                        <Modal.Title><span className="cap-header">{title}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-region">
                            <div className="layer-delete">
                                {action === "toggleVisibility" ? <p>The target layer {selectedLayerName} must be visible. Would you like to make {selectedLayerName} visible?</p> :
                                    action === "deleteLayer" ? <p>Delete annotations layer {selectedLayerName} and all its annotations?</p> :
                                        action === "readOnlyLayerSelect" ? <p>The target layer {selectedLayerName} is a read only layer that can&apos;t be edited. Please select a different layer from the dropdown.</p> :
                                            !isDeleteAll ?
                                                <p>{"Are you sure you want to delete all selected annotations?"}</p>
                                                :
                                                <Fragment>
                                                    <h6>{"Do you want to delete all annotations?"}</h6>
                                                    <p className="note">{"Note: all visible annotations that you are entitled to edit will be deleted."}</p>
                                                </Fragment>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box righter">
                            {action !== "readOnlyLayerSelect" && <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.onCancel}>{"CANCEL"}</button>}
                            <button type="button" className="btn btn-xs btn-default btn-secondary" onClick={this.onConfirm}>{action === "toggleVisibility" || action === "readOnlyLayerSelect" ? "OK" : "DELETE"}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { selectedToolType } = annotationReducers.annotationMenuReducer;
    const { annotSelected, showDeleteConform, isDeleteAll, selectedAnnotId } = annotationReducers.annotationChartModelReducer;
    const { LayersList, modifiedLayers, isLayerOrderChange, showDeleteLayerConfirm, selectedLayer, layerVisibilityConfirm, readOnlyLayerSelectConfirm } = annotationReducers.annotationLayerManagementReducer;
    let orderedLayersList = LayersList;

    if (isLayerOrderChange) {
        orderedLayersList = modifiedLayers;
    }

    return ({ annotSelected, showDeleteConform, isDeleteAll, showDeleteLayerConfirm, selectedLayer, layerVisibilityConfirm, readOnlyLayerSelectConfirm, orderedLayersList, selectedAnnotId, selectedToolType });
}

const mapDispatchToProps = (dispatch) => ({
    confirmDelete: (showConfirm) => dispatch(handleDeleteConfirmPopup(showConfirm)),
    confirmAllDelete: () => dispatch(handleConfirmDeleteAllAnnotation(false)),
    deleteAnnotDraw: (charCode) => dispatch(handleDeleteAnnotationDrawing(charCode)),
    confirmLayerDelete: (showConfirm) => dispatch(handleLayerDeleteConfirmPopup(showConfirm)),
    handleLayerVisibilityConfirmPopup: (showConfirm) => dispatch(handleLayerVisibilityConfirmPopup(showConfirm)),
    handleReadOnlyLayerConfirmPopup: (showConfirm) => dispatch(handleReadOnlyLayerConfirmPopup(showConfirm)),
    onFooterAction: (action) => dispatch(onFooterAction(action)),
    toggleVisibility: (selectedLayer) => dispatch(toggleVisibility(selectedLayer))
});

export default connect(mapStateToProps, mapDispatchToProps)(PopUpWindow);