import { put, takeLatest, call, select, all } from 'redux-saga/effects';
import { each, map, sortBy, find, contains, filter, some, extend } from "underscore";
import { ComparisonAndRotationMenuConstants } from "../../../Constants/RotationGraphConstants";
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import _ from 'underscore';
import { SymbolDataModel } from '../../../Models/ComparisonChartModels/LineDataModel';
import { getMenuTab, getLiquidityFilter } from '../../../Reducers/NavCompare/MenuPanelReducers/selectors';
import { getCompChartListItem } from '../../../Reducers/NavCompare/ComparisonGraphReducers/selectors';
import StringUtil from "StringUtil";
import compareApi from '../../../ServiceApi/Apis/CompareApi';
import CompareStore from "CompareStore";
import GrpsLogPriceScale from "../../../Utils/Scales/GrpsLogPriceScale.js";
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";
import SettingsStore from "SettingsStore";
import LocalizationStore from 'LocalizationStore';
import CompareTabType, { CompareMenuType } from "CompareTabType";
import KeyCodes from "../../../Constants/KeyCodes.js";
import { ComparisonGraph } from "../../../Constants/ComparisonGraph";
import { getActiveTabActiveMenuData, getSelectedTabLiqFilterData, getSelectedTabActiveMenuName } from '../../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import RatingFilterType from '../../../Constants/RatingFilterType';
import SymbolType from '../../../Constants/SymbolType';
import { cloneDeep } from "lodash";
import { CompRotationHelper } from '../../../Models/NavCompare/ComparisonAndRotationMenuModels/MenuStates';
import { filterQuadData } from "../RotationGraph/CenterRotationSaga";
import { processData } from '../ComparisionGraph/chartViewModel';

extend(ActionTypes, ComparisonGraph.ActionTypes)
function* setIsFilterOn(value, isUpdate = true) {

    if (isUpdate) {
        yield put({
            type: ActionTypes.SET_FILTER_ON,
            isFilterOn: value
        })

    }
}

function* setIsFilterManualOff(value) {

    yield put({
        type: ActionTypes.SET_FILTER_MANUAL_OFF,
        isFilterManualOff: value
    })

}

function* toggleFilterOnOff() {
    try {
        let state = yield select(getSelectedTabLiqFilterData);

        if (state.isFilterOn) {
            yield put({
                type: ActionTypes.TOGGLE_LIQ_BUTTON,
                isFilterOn: false
            })
        }
        else {
            yield put({
                type: ActionTypes.TOGGLE_LIQ_BUTTON,
                isFilterOn: true
            })
        }

        yield call(liqFilterChange);
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, toggleFilterOnOff ${error}`);
    }
}

function* updateRatingFilter(ratingType, id) {
    try {
        let states = yield select(getSelectedTabLiqFilterData)
        let option = states[id].ratingFilterTypeOptions;
        let selectedOption = option[ratingType];
        _.each(states[id].ratingFilterTypeOptions, (option, key) => {
            if (key != ratingType) {
                option.isSelected = false;
                option.isEnabled = true;
            }
            else {
                option.isSelected = true;
                option.isEnabled = false;
            }

            if (id == "ratingFilterTypeOptions1ViewModel" && states["ratingFilterTypeOptions2ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                option.isEnabled = false;
            }
            else if (id == "ratingFilterTypeOptions2ViewModel" && states["ratingFilterTypeOptions1ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                option.isEnabled = false;
            }
        });


        selectedOption.isSelected = true;
        selectedOption.isEnabled = false;

        if (id == "ratingFilterTypeOptions1ViewModel") {
            _.each(states["ratingFilterTypeOptions2ViewModel"].ratingFilterTypeOptions, (option, key) => {
                if (key == ratingType) {
                    option.isEnabled = false;
                }
                else if (states["ratingFilterTypeOptions2ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                    option.isEnabled = false;
                }
                else {
                    option.isEnabled = true;
                }
            });
        }
        else {
            _.each(states["ratingFilterTypeOptions1ViewModel"].ratingFilterTypeOptions, (option, key) => {
                if (key == ratingType) {
                    option.isEnabled = false;
                }
                else if (states["ratingFilterTypeOptions1ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                    option.isEnabled = false;
                }
                else {
                    option.isEnabled = true;
                }
            });
        }

        yield put({
            type: ActionTypes.UPDATE_RATING_FILTER,
            id: id,
            ratingFilterTypeOptions: states[id]
        });
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, updateRatingFilter ${error}`);
    }
}

function* isCompTabKeySelected() {
    return SettingsStore.getConsoleSettings().NavCompareSettings.SelectedTabKey == CompareTabType.ComparisonGraph ? true : false;
}


function* getRatingFilter(type, options) {
    switch (type.toUpperCase()) {
        case "DATAGRAPH":
            return options.datagraph;
        case "EPS":
            return options.eps;
        case "RELATIVESTRENGTH":
            return options.relativestrength;
        case "ACCUMULATIONDISTRIBUTION":
            return options.accumulationdistribution;
        default:
            return options.datagraph;
    }
}


function* updateDataFilterRange({ value, dataValueLow, dataValueHigh, id }) {
    try {
        let data = { lowSliderRangeValueIndex: value[0], highSliderRangeValueIndex: value[1], lowRangeValue: dataValueLow, highRangeValue: dataValueHigh }

        yield put({
            type: ActionTypes.UPDATE_DATA_FILTER_RANGE,
            data, id
        })

        yield call(updateFilterOn, false)

        yield call(liqFilterChange);
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, updateDataFilterRange ${error}`);
    }
}

function* onKeyPressUpdateSlider(keyValue) {
    try {
        if (keyValue === KeyCodes.RIGHT_ARROW || keyValue === KeyCodes.UP_ARROW || keyValue === KeyCodes.LEFT_ARROW || keyValue === KeyCodes.DOWN_ARROW) {
            let states = yield select(getSelectedTabLiqFilterData)
            if (states && states.activeSlider.id === null && states.activeSlider.sliderIndex === null) return;
            const id = states.activeSlider.id;
            let lowSliderIndex = states[id].lowSliderRangeValueIndex;
            let highSliderIndex = states[id].highSliderRangeValueIndex;
            const maxRangeSliderValue = states[id].maxRangeSliderValue;
            const minRangeSliderValue = states[id].minRangeSliderValue;
            const intervalData = states[id].intervalData;
            switch (keyValue) {
                case KeyCodes.RIGHT_ARROW:
                case KeyCodes.UP_ARROW:
                    if (states.activeSlider.sliderIndex === 0) {
                        lowSliderIndex = lowSliderIndex < highSliderIndex ? lowSliderIndex + 1 : highSliderIndex;
                    }
                    else {
                        highSliderIndex = highSliderIndex < maxRangeSliderValue ? highSliderIndex + 1 : maxRangeSliderValue;
                    }
                    break;
                case KeyCodes.LEFT_ARROW:
                case KeyCodes.DOWN_ARROW:
                    if (states.activeSlider.sliderIndex === 0) {
                        lowSliderIndex = lowSliderIndex > minRangeSliderValue ? lowSliderIndex - 1 : minRangeSliderValue;
                    }
                    else {
                        highSliderIndex = highSliderIndex > lowSliderIndex ? highSliderIndex - 1 : lowSliderIndex;
                    }
                    break;
                default:
                    return;
            }
            yield call(updateDataFilterRange, [lowSliderIndex, highSliderIndex], intervalData[lowSliderIndex], intervalData[highSliderIndex], id)
        }
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, onKeyPressUpdateSlider ${error}`);
    }
}

function* getDescription(ratingFilterType, prefix) {
    switch (ratingFilterType.toUpperCase()) {
        case "DATAGRAPH":
            return prefix + LocalizationStore.getTranslatedData("CCG_DatagraphRating", "Datagraph Rating");
        case "EPS":
            return prefix + LocalizationStore.getTranslatedData("CCG_EPSRank", "EPS Rank");
        case "RELATIVESTRENGTH":
            return prefix + LocalizationStore.getTranslatedData("CCG_RSR", "Relative Strength Rating");
        case "ACCUMULATIONDISTRIBUTION":
            return LocalizationStore.getTranslatedData("CCG_AD", "Accumulation Distribution");
        default:
            return "";
    }
}

function* createFilterOptions(prefix) {
    try {
        return Object.assign({}, {
            datagraph: {
                ratingFilterType: "Datagraph",
                description: yield call(getDescription, "Datagraph", prefix),
                isEnabled: true,
                isSelected: false
            },
            eps: {
                ratingFilterType: "EPS",
                description: yield call(getDescription, "EPS", prefix),
                isEnabled: true,
                isSelected: false
            },
            relativestrength: {
                ratingFilterType: "RelativeStrength",
                description: yield call(getDescription, "RelativeStrength", prefix),
                isEnabled: true,
                isSelected: false
            },
            accumulationdistribution: {
                ratingFilterType: "AccumulationDistribution",
                description: yield call(getDescription, "AccumulationDistribution", prefix),
                isEnabled: true,
                isSelected: false
            }
        });
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, createFilterOptions ${error}`);
    }
}

function* updateFilterOn(isInitialState = false) {
    try {
        let states = yield select(getSelectedTabLiqFilterData)
        if (
            (states.mktCapFilterViewModel.highRangeValue == states.mktCapFilterViewModel.maxValueText
                && states.mktCapFilterViewModel.minValueText == states.mktCapFilterViewModel.lowRangeValue)
            && (states.avolFilterViewModel.highRangeValue == states.avolFilterViewModel.maxValueText
                && states.avolFilterViewModel.minValueText == states.avolFilterViewModel.lowRangeValue)
            && (states.ratingFilterTypeOptions1ViewModel.highRangeValue == states.ratingFilterTypeOptions1ViewModel.maxValueText
                && states.ratingFilterTypeOptions1ViewModel.minValueText == states.ratingFilterTypeOptions1ViewModel.lowRangeValue)
            && (states.ratingFilterTypeOptions2ViewModel.highRangeValue == states.ratingFilterTypeOptions2ViewModel.maxValueText
                && states.ratingFilterTypeOptions2ViewModel.minValueText == states.ratingFilterTypeOptions2ViewModel.lowRangeValue)) {
            yield call(setIsFilterOn, false, false);
            yield call(setIsFilterManualOff, true);
        }
        else {
            if (!isInitialState) {
                yield call(setIsFilterOn, true);
                yield call(setIsFilterManualOff, false);
            }
        }
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, updateFilterOn ${error}`);
    }
}

function* getLiqFilterData() {
    try {
        let data = yield compareApi.getLiqFilterData().then((result) => {
            if (result.responseHeader.error) {
                //ConsoleActions.exceptionHandle(result.responseHeader);
                throw 'Error in Fetching Liquidity Filter Data.';
            }
            else {
                return { ...result.LIqFilterValues };
            }
        });

        yield put({
            type:ActionTypes.INIT_LIQ_FILTER_VALUES,
            liqFilterValues:data
        })

        return data;
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, getLiqFilterData ${error}`);
    }
}

function* initializeViewModel() {
    try {
        let menuTab = yield select(getMenuTab);
        const settings = SettingsStore.getConsoleSettings().NavCompareSettings;
        const isComparisonGraph = yield call(isCompTabKeySelected);

        let liqFilter = isComparisonGraph
            ? menuTab.ComparisonMenuTab == CompareMenuType.Symbols
                ? settings.TabComparisonGraphSettings.comparisonSymbolsMenuSettings.liqFilter
                : settings.TabComparisonGraphSettings.comparisonGroupsMenuSettings.liqFilter
            : menuTab.RotationMenuTab == CompareMenuType.Symbols
                ? settings.TabRotationGraphSettings.rotationSymbolsMenuSettings.liqFilter
                : settings.TabRotationGraphSettings.rotationGroupsMenuSettings.liqFilter

        const liqFilterState = yield select(getLiquidityFilter);
        let values = liqFilterState.liqFilterValues;

        if (values == null) {
            values = yield call(getLiqFilterData);
        }

        if (!StringUtil.isEmpty(values)) {
            let mktCapInterval = yield call(calculateIntervals, values.MktCapMin, values.MktCapMax);

            let MktCapLowSliderRangeValueIndex = liqFilter.MktCapLowSliderRangeValueIndex >= 0 && liqFilter.MktCapLowSliderRangeValueIndex < mktCapInterval.length ? liqFilter.MktCapLowSliderRangeValueIndex : 0;

            let MktCapHighSliderRangeValueIndex = liqFilter.MktCapHighSliderRangeValueIndex >= 0 && liqFilter.MktCapHighSliderRangeValueIndex < mktCapInterval.length ? liqFilter.MktCapHighSliderRangeValueIndex : mktCapInterval.length - 1;

            let MktCapLowValue = liqFilter.MktCapLowValue !== undefined && liqFilter.MktCapLowValue !== -1 ?
                (liqFilter.MktCapLowValue < mktCapInterval[0]
                    ? mktCapInterval[MktCapLowSliderRangeValueIndex]
                    : liqFilter.MktCapLowValue > mktCapInterval[MktCapHighSliderRangeValueIndex]
                        ? mktCapInterval[MktCapHighSliderRangeValueIndex]
                        : liqFilter.MktCapLowValue)
                : mktCapInterval[MktCapLowSliderRangeValueIndex];

            let MktCapHighValue = liqFilter.MktCapHighValue !== undefined && liqFilter.MktCapHighValue !== -1 ?
                (liqFilter.MktCapHighValue > mktCapInterval[mktCapInterval.length - 1]
                    ? mktCapInterval[MktCapHighSliderRangeValueIndex]
                    : liqFilter.MktCapHighValue < mktCapInterval[MktCapLowSliderRangeValueIndex]
                        ? mktCapInterval[MktCapLowSliderRangeValueIndex]
                        : liqFilter.MktCapHighValue)
                : mktCapInterval[MktCapHighSliderRangeValueIndex];

            /* Fixes - PANWEB-2374 */

            if (mktCapInterval && mktCapInterval[0] > MktCapLowValue && MktCapLowSliderRangeValueIndex === 0) {
                MktCapLowSliderRangeValueIndex = 1;
            }

            if (mktCapInterval && mktCapInterval[mktCapInterval.length - 1] > MktCapHighValue && MktCapHighSliderRangeValueIndex === (mktCapInterval.length - 1)) {
                MktCapHighSliderRangeValueIndex = MktCapHighSliderRangeValueIndex - 1;
            }


            let avolInterval = yield call(calculateIntervals, values.AvolMin, values.AvolMax);

            let AvolFilterLowSliderRangeValueIndex = liqFilter.AvolFilterLowSliderRangeValueIndex >= 0 && liqFilter.AvolFilterLowSliderRangeValueIndex < avolInterval.length ? liqFilter.AvolFilterLowSliderRangeValueIndex : 0;

            let AvolFilterHighSliderRangeValueIndex = liqFilter.AvolFilterHighSliderRangeValueIndex >= 0 && liqFilter.AvolFilterHighSliderRangeValueIndex < avolInterval.length ? liqFilter.AvolFilterHighSliderRangeValueIndex : avolInterval.length - 1;

            let AvolFilterLowValue = liqFilter.AvolFilterLowValue !== undefined && liqFilter.AvolFilterLowValue !== -1
                ? (liqFilter.AvolFilterLowValue < avolInterval[0]
                    ? avolInterval[AvolFilterLowSliderRangeValueIndex]
                    : liqFilter.AvolFilterLowValue > avolInterval[AvolFilterHighSliderRangeValueIndex]
                        ? avolInterval[AvolFilterHighSliderRangeValueIndex]
                        : liqFilter.AvolFilterLowValue)
                : avolInterval[AvolFilterLowSliderRangeValueIndex];

            let AvolFilterHighValue = liqFilter.AvolFilterHighValue !== undefined && liqFilter.AvolFilterHighValue !== -1
                ? (liqFilter.AvolFilterHighValue > avolInterval[avolInterval.length - 1]
                    ? avolInterval[AvolFilterHighSliderRangeValueIndex]
                    : liqFilter.AvolFilterHighValue < avolInterval[AvolFilterLowSliderRangeValueIndex]
                        ? avolInterval[AvolFilterLowSliderRangeValueIndex]
                        : liqFilter.AvolFilterHighValue)
                : avolInterval[AvolFilterHighSliderRangeValueIndex];
            /* Fixes - PANWEB-2374 */
            if (avolInterval && avolInterval[0] > AvolFilterLowValue && AvolFilterLowSliderRangeValueIndex === 0) {
                AvolFilterLowSliderRangeValueIndex = 1;
            }

            if (avolInterval && avolInterval[avolInterval.length - 1] > AvolFilterHighValue && AvolFilterHighSliderRangeValueIndex === (avolInterval.length - 1)) {
                AvolFilterHighSliderRangeValueIndex = AvolFilterHighSliderRangeValueIndex - 1;
            }

            let RatingFilterType2 = liqFilter.RatingFilterType2, RatingFilterType1 = liqFilter.RatingFilterType1;
            let titleText = liqFilter.IsGlobalRatingSelected ? LocalizationStore.getTranslatedData("CCG_Global", "Global ") : "";

            let options1 = yield call(createFilterOptions, titleText);
            let options2 = yield call(createFilterOptions, titleText);

            let selectedOption1 = yield call(getRatingFilter, RatingFilterType1, options1);
            let selectedOption2 = yield call(getRatingFilter, RatingFilterType2, options2);


            let ratingFilterTypeOptions1Interval = yield call(createArithmaticDict, 1, 99);
            if (RatingFilterType1.toLowerCase() == "accumulationdistribution") {
                ratingFilterTypeOptions1Interval = yield call(createArithmaticDict, -100, 100);
            }

            let RatingFilter1LowSliderRangeValueIndex = liqFilter.RatingFilter1LowSliderRangeValueIndex >= 0 && liqFilter.RatingFilter1LowSliderRangeValueIndex < ratingFilterTypeOptions1Interval.length ? liqFilter.RatingFilter1LowSliderRangeValueIndex : 0;

            let RatingFilter1HighSliderRangeValueIndex = liqFilter.RatingFilter1HighSliderRangeValueIndex >= 0 && liqFilter.RatingFilter1HighSliderRangeValueIndex < ratingFilterTypeOptions1Interval.length ? liqFilter.RatingFilter1HighSliderRangeValueIndex : ratingFilterTypeOptions1Interval.length - 1;



            let ratingFilterTypeOptions2Interval = yield call(createArithmaticDict, 1, 99);
            if (RatingFilterType2.toLowerCase() == "accumulationdistribution") {
                ratingFilterTypeOptions2Interval = yield call(createArithmaticDict, -100, 100);
            }
            let RatingFilter2LowSliderRangeValueIndex = liqFilter.RatingFilter2LowSliderRangeValueIndex >= 0 && liqFilter.RatingFilter2LowSliderRangeValueIndex < ratingFilterTypeOptions2Interval.length ? liqFilter.RatingFilter2LowSliderRangeValueIndex : 0;

            let RatingFilter2HighSliderRangeValueIndex = liqFilter.RatingFilter2HighSliderRangeValueIndex >= 0 && liqFilter.RatingFilter2HighSliderRangeValueIndex < ratingFilterTypeOptions2Interval.length ? liqFilter.RatingFilter2HighSliderRangeValueIndex : ratingFilterTypeOptions2Interval.length - 1;

            let initData = {
                isGlobalRatingSelected: liqFilter.IsGlobalRatingSelected,
                isCountryRatingSelected: liqFilter.IsCountryRatingSelected,
                isFilterOn: liqFilter.IsFilterOn,
                isFilterManualOff: liqFilter.IsFilterManualOff,

                avolFilterViewModel: Object.assign({}, {
                    id: "avolFilterViewModel",
                    minRangeSliderValue: avolInterval[0],
                    maxRangeSliderValue: avolInterval.length - 1,
                    minValueText: avolInterval[0],
                    maxValueText: avolInterval[avolInterval.length - 1],
                    intervalData: avolInterval,
                    lowRangeValue: Number(AvolFilterLowValue),
                    highRangeValue: Number(AvolFilterHighValue),
                    lowSliderRangeValueIndex: AvolFilterLowSliderRangeValueIndex,
                    highSliderRangeValueIndex: AvolFilterHighSliderRangeValueIndex,
                    title: LocalizationStore.getTranslatedData("CCG_AverageDDV", "Average Daily Dollar Volume (in millions) US$"),
                    filterType: "value"
                }),

                mktCapFilterViewModel: Object.assign({}, {
                    id: "mktCapFilterViewModel",
                    minRangeSliderValue: mktCapInterval[0],
                    maxRangeSliderValue: mktCapInterval.length - 1,
                    minValueText: mktCapInterval[0],
                    maxValueText: mktCapInterval[mktCapInterval.length - 1],
                    intervalData: mktCapInterval,
                    lowRangeValue: Number(MktCapLowValue),
                    highRangeValue: Number(MktCapHighValue),
                    lowSliderRangeValueIndex: MktCapLowSliderRangeValueIndex,
                    highSliderRangeValueIndex: MktCapHighSliderRangeValueIndex,
                    title: LocalizationStore.getTranslatedData("CCG_MarketCap", "Market Capitalization (in millions) US$"),
                    filterType: "value"
                }),

                ratingFilterTypeOptions1ViewModel: Object.assign({}, {
                    id: "ratingFilterTypeOptions1ViewModel",
                    minRangeSliderValue: 0,
                    maxRangeSliderValue: ratingFilterTypeOptions1Interval.length - 1,
                    minValueText: ratingFilterTypeOptions1Interval[0],
                    maxValueText: ratingFilterTypeOptions1Interval[ratingFilterTypeOptions1Interval.length - 1],
                    intervalData: ratingFilterTypeOptions1Interval,
                    lowRangeValue: ratingFilterTypeOptions1Interval[RatingFilter1LowSliderRangeValueIndex],
                    highRangeValue: ratingFilterTypeOptions1Interval[RatingFilter1HighSliderRangeValueIndex],
                    lowSliderRangeValueIndex: RatingFilter1LowSliderRangeValueIndex,
                    highSliderRangeValueIndex: RatingFilter1HighSliderRangeValueIndex,
                    ratingFilterTypeOptions: options1,
                    selectedRatingFilterTypeOption: selectedOption1,
                    title: selectedOption1.description,
                    filterType: "rating"
                }),

                ratingFilterTypeOptions2ViewModel: Object.assign({}, {
                    id: "ratingFilterTypeOptions2ViewModel",
                    minRangeSliderValue: 0,
                    maxRangeSliderValue: ratingFilterTypeOptions2Interval.length - 1,
                    minValueText: ratingFilterTypeOptions2Interval[0],
                    maxValueText: ratingFilterTypeOptions2Interval[ratingFilterTypeOptions2Interval.length - 1],
                    intervalData: ratingFilterTypeOptions2Interval,
                    lowRangeValue: ratingFilterTypeOptions2Interval[RatingFilter2LowSliderRangeValueIndex],
                    highRangeValue: ratingFilterTypeOptions2Interval[RatingFilter2HighSliderRangeValueIndex],
                    lowSliderRangeValueIndex: RatingFilter2LowSliderRangeValueIndex,
                    highSliderRangeValueIndex: RatingFilter2HighSliderRangeValueIndex,
                    ratingFilterTypeOptions: options2,
                    selectedRatingFilterTypeOption: selectedOption2,
                    title: selectedOption2.description,
                    filterType: "rating"
                })
            }

            yield put({
                type: ActionTypes.INIT_LIQUIDITY_FILTER_DATA,
                initData
            })

            yield call(updateRatingFilter, RatingFilterType1.toLowerCase(), "ratingFilterTypeOptions1ViewModel");

            yield call(updateRatingFilter, RatingFilterType2.toLowerCase(), "ratingFilterTypeOptions2ViewModel");

            yield call(updateFilterOn, true);

            yield call(liqFilterChange, false);

        }
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, initializeViewModel ${error}`);
    }
}


function* saveFilterDataToSettings() {
    let states = yield select(getSelectedTabLiqFilterData)
    let menuTab = yield select(getMenuTab);
    let navCompareSettings = CompareStore.getNavCompareSettings();
    if (yield call(isCompTabKeySelected)) {
        if (menuTab.ComparisonMenuTab == CompareMenuType.Symbols)
            CompareSettingHelper.updateLiqFilterData(navCompareSettings.TabComparisonGraphSettings.comparisonSymbolsMenuSettings, states);
        else
            CompareSettingHelper.updateLiqFilterData(navCompareSettings.TabComparisonGraphSettings.comparisonGroupsMenuSettings, states);
    }
    else {
        if (menuTab.RotationMenuTab == CompareMenuType.Symbols)
            CompareSettingHelper.updateLiqFilterData(navCompareSettings.TabRotationGraphSettings.rotationSymbolsMenuSettings, states);
        else
            CompareSettingHelper.updateLiqFilterData(navCompareSettings.TabRotationGraphSettings.rotationGroupsMenuSettings, states);

    }

    SettingsStore.saveSettings();
}



function* createArithmaticDict(minValue, maxValue) {
    let IntervalList = [];
    for (var i = minValue; i <= maxValue; i++) {
        IntervalList.push(i);
    }
    return IntervalList;
}

function* calculateIntervals(minValue, maxValue) {
    try {
        let targetInfo = {
            MinPrice: 0,
            MaxPrice: 100,
            BoxHeight: 0.0,
            VpHeight: 0.0,
            Name: "",
            MMinPrice: 0.0,
            MMaxPrice: 0.0,
            MaxVolume: 0.0,
            MinVolume: 0.0,
            VolAveMax: 0.0,
            Avolume: 0.0,
            DPI: 96.0,
            VolWidth: 0.0,
            VolHeight: 0.0,
            EpsMultiplier: 20.0,
            RpsMultiplier: 20.0,
            NodesPerInch: 28.0,
            ScaleType: "LIN",
            TradeDate: "",
            StartDate: "",
            OpenTime: "",
            CloseTime: "",
            GraphType: "",
            SymbolType: ""
        };

        targetInfo.MinPrice = Math.abs(minValue - 0.0) < Number.EPSILON ? 0.00000001 : minValue;
        targetInfo.MaxPrice = Math.abs(maxValue - 0.0) < Number.EPSILON ? 0.00000001 : maxValue;
        targetInfo.BoxHeight = 300;
        targetInfo.VpHeight = 300;
        targetInfo.GraphType = 2;
        let logscale = new GrpsLogPriceScale(targetInfo);
        let IntervalList = [];
        IntervalList.push(Math.round(targetInfo.MinPrice));
        if (logscale.MHLabel != null)
            for (let i = 0; i < logscale.MHLabel.length; i++) {
                let item = logscale.MHLabel[i].labels.HLabel;

                if (item != null && (i % 2 == 0 && parseFloat(item) > targetInfo.MinPrice)) {
                    IntervalList.push(item);
                }
            }
        IntervalList.push(Math.round(targetInfo.MaxPrice));
        return IntervalList;
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, calculateIntervals ${error}`);
    }
}


function* updateSelectedRatingFilterType({ ratingType, id }) {
    try {
        const states = yield select(getSelectedTabLiqFilterData);
        if (id == "ratingFilterTypeOptions1ViewModel" || id == "ratingFilterTypeOptions2ViewModel") {
            let previousRatings = [];
            switch(id){
                case "ratingFilterTypeOptions1ViewModel":
                    previousRatings = states["ratingFilterTypeOptions2ViewModel"];
                    if( previousRatings.highRangeValue == previousRatings.maxValueText 
                        && previousRatings.lowRangeValue == previousRatings.minValueText
                        && states.mktCapFilterViewModel.highRangeValue == states.mktCapFilterViewModel.maxValueText
                        && states.mktCapFilterViewModel.minValueText == states.mktCapFilterViewModel.lowRangeValue
                        && states.avolFilterViewModel.highRangeValue == states.avolFilterViewModel.maxValueText
                        && states.avolFilterViewModel.minValueText == states.avolFilterViewModel.lowRangeValue
                    ){
                        yield call(setIsFilterOn, false);
                    }
                break;
                case "ratingFilterTypeOptions2ViewModel": 
                previousRatings = states["ratingFilterTypeOptions1ViewModel"];
                    if( previousRatings.highRangeValue == previousRatings.maxValueText 
                        && previousRatings.lowRangeValue == previousRatings.minValueText
                        && states.mktCapFilterViewModel.highRangeValue == states.mktCapFilterViewModel.maxValueText
                        && states.mktCapFilterViewModel.minValueText == states.mktCapFilterViewModel.lowRangeValue
                        && states.avolFilterViewModel.highRangeValue == states.avolFilterViewModel.maxValueText
                        && states.avolFilterViewModel.minValueText == states.avolFilterViewModel.lowRangeValue
                    ){
                        yield call(setIsFilterOn, false);
                    }
                break;
            }

            let option = states[id].ratingFilterTypeOptions;
            let selectedOption = option[ratingType];
            _.each(states[id].ratingFilterTypeOptions, (option, key) => {
                if (key != ratingType) {
                    option.isSelected = false;
                    option.isEnabled = true;
                }
                else {
                    option.isSelected = true;
                    option.isEnabled = false;
                }

                if (id == "ratingFilterTypeOptions1ViewModel" && states["ratingFilterTypeOptions2ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                    //hello
                    option.isEnabled = false;
                }
                else if (id == "ratingFilterTypeOptions2ViewModel" && states["ratingFilterTypeOptions1ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                    option.isEnabled = false;
                }
            });


            selectedOption.isSelected = true;
            selectedOption.isEnabled = false;
            if (id == "ratingFilterTypeOptions1ViewModel") {
                _.each(states["ratingFilterTypeOptions2ViewModel"].ratingFilterTypeOptions, (option, key) => {
                    //hello
                    if (key == ratingType) {
                        option.isEnabled = false;
                    }
                    else if (states["ratingFilterTypeOptions2ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                        option.isEnabled = false;
                    }
                    else {
                        option.isEnabled = true;
                    }
                });
            }
            else {
                _.each(states["ratingFilterTypeOptions1ViewModel"].ratingFilterTypeOptions, (option, key) => {
                    if (key == ratingType) {
                        option.isEnabled = false;
                    }
                    else if (states["ratingFilterTypeOptions1ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                        option.isEnabled = false;
                    }
                    else {
                        option.isEnabled = true;
                    }
                });
            }



            let ratingInterVal = yield call(createArithmaticDict, 1, 99);
            if (ratingType == "accumulationdistribution") {
                ratingInterVal = yield call(createArithmaticDict, -100, 100);
            }

            states[id].minRangeSliderValue = 0;
            states[id].maxRangeSliderValue = ratingInterVal.length - 1;
            states[id].minValueText = ratingInterVal[0];
            states[id].maxValueText = ratingInterVal[ratingInterVal.length - 1];
            states[id].intervalData = ratingInterVal;
            states[id].lowRangeValue = ratingInterVal[0];
            states[id].highRangeValue = ratingInterVal[ratingInterVal.length - 1];
            states[id].lowSliderRangeValueIndex = 0;
            states[id].highSliderRangeValueIndex = ratingInterVal.length - 1;
            states[id].selectedRatingFilterTypeOption = selectedOption;
            states[id].title = selectedOption.description;

            id = "ratingFilterTypeOptions1ViewModel"
            yield put({
                type: ActionTypes.UPDATE_SELECTED_RATING_FILTERTYPE,
                ratingFilterTypeOptions: states["ratingFilterTypeOptions1ViewModel"], id
            })

            id = "ratingFilterTypeOptions2ViewModel"
            yield put({
                type: ActionTypes.UPDATE_SELECTED_RATING_FILTERTYPE,
                ratingFilterTypeOptions: states["ratingFilterTypeOptions2ViewModel"], id
            })

            yield call(liqFilterChange);

        }
    }
    catch (error) {
        console.log(`Error occurs in  liqAndRatingFilterSaga.js, updateSelectedRatingFilterType ${error}`);
    }
}

function* updateRatingFilterTitle() {
    let states = yield select(getSelectedTabLiqFilterData);
    let ratingFilterTypeOptions1ViewModel = states.ratingFilterTypeOptions1ViewModel;
    let ratingFilterTypeOptions2ViewModel = states.ratingFilterTypeOptions2ViewModel;
    let ratingFilterTypeOptions1 = Object.keys(ratingFilterTypeOptions1ViewModel.ratingFilterTypeOptions);
    let ratingFilterTypeOptions2 = Object.keys(ratingFilterTypeOptions2ViewModel.ratingFilterTypeOptions);

    if (states.isGlobalRatingSelected) {

        for (let i = 0; i < ratingFilterTypeOptions1.length; i++) {
            let { ratingFilterType } = ratingFilterTypeOptions1ViewModel.ratingFilterTypeOptions[ratingFilterTypeOptions1[i]];
            ratingFilterTypeOptions1ViewModel.ratingFilterTypeOptions[ratingFilterTypeOptions1[i]].description = yield call(getDescription, ratingFilterType, `${LocalizationStore.getTranslatedData("Country_1000", "Global")} `);
        }

        for (let i = 0; i < ratingFilterTypeOptions2.length; i++) {
            let { ratingFilterType } = ratingFilterTypeOptions2ViewModel.ratingFilterTypeOptions[ratingFilterTypeOptions2[i]];
            ratingFilterTypeOptions2ViewModel.ratingFilterTypeOptions[ratingFilterTypeOptions2[i]].description = yield call(getDescription, ratingFilterType, `${LocalizationStore.getTranslatedData("Country_1000", "Global")} `);
        }

        ratingFilterTypeOptions1ViewModel.title = ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.description = yield call(getDescription, ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType, LocalizationStore.getTranslatedData("CCG_Global", "Global "));
        ratingFilterTypeOptions2ViewModel.title = ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.description = yield call(getDescription, ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType, LocalizationStore.getTranslatedData("CCG_Global", "Global "));
    }
    else {
        for (let i = 0; i < ratingFilterTypeOptions1.length; i++) {
            let { ratingFilterType } = ratingFilterTypeOptions1ViewModel.ratingFilterTypeOptions[ratingFilterTypeOptions1[i]];
            ratingFilterTypeOptions1ViewModel.ratingFilterTypeOptions[ratingFilterTypeOptions1[i]].description = yield call(getDescription, ratingFilterType, "");
        }

        for (let i = 0; i < ratingFilterTypeOptions2.length; i++) {
            let { ratingFilterType } = ratingFilterTypeOptions2ViewModel.ratingFilterTypeOptions[ratingFilterTypeOptions2[i]];
            ratingFilterTypeOptions2ViewModel.ratingFilterTypeOptions[ratingFilterTypeOptions2[i]].description = yield call(getDescription, ratingFilterType, "");
        }

        ratingFilterTypeOptions1ViewModel.title = ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.description = yield call(getDescription, ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType, "");
        ratingFilterTypeOptions2ViewModel.title = ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.description = yield call(getDescription, ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType, "");
    }


    yield put({
        type: ActionTypes.UPDATE_RATING_FILTER__TITLE,
        id: "ratingFilterTypeOptions1ViewModel",
        ratingFilterTypeOptions: ratingFilterTypeOptions1ViewModel
    })



    yield put({
        type: ActionTypes.UPDATE_RATING_FILTER__TITLE,
        id: "ratingFilterTypeOptions2ViewModel",
        ratingFilterTypeOptions: ratingFilterTypeOptions2ViewModel
    })
}


function* filterSymbolType(symbolData) {
    try {
        const symbolTypeEnumNum = SymbolType[symbolData.SymTypeEnum];
        return symbolTypeEnumNum == SymbolType.ADR || symbolTypeEnumNum == SymbolType.USSTOCK ||
            symbolTypeEnumNum == SymbolType.ETF || symbolTypeEnumNum == SymbolType.PREIPO ||
            symbolTypeEnumNum == SymbolType.REIT || symbolTypeEnumNum == SymbolType.FundClosedEnd ||
            symbolTypeEnumNum == SymbolType.INTERNATIONALSTOCK ||
            symbolTypeEnumNum == SymbolType.MODELSTOCK ||
            symbolTypeEnumNum == SymbolType.HISTORICSTOCK;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in liquidityFilter.js, filterSymbolType ${error}`);
    }
}

function* clearAllFilter() {
    const states = yield select(getCompChartListItem);

    try {
        const newStates = states.map((item) => {
            const newItem = Object.assign(item, { isFilter: false })
            return newItem;
        });
        yield put({
            type: ActionTypes.FILTER_ALL_DATA,
            data: newStates
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in liquidityFilter.js, clearAllFilter ${error}`);
    }
}

function* isMatchRatingFilter(tab, ratingData, { ratingFilterTypeOptions1ViewModel, ratingFilterTypeOptions2ViewModel, isGlobalRatingSelected }, itemData = '') {

    const lowValue1 = parseFloat(ratingFilterTypeOptions1ViewModel.lowRangeValue);
    const highValue1 = parseFloat(ratingFilterTypeOptions1ViewModel.highRangeValue);
    const isFilteredRating1 = parseFloat(ratingFilterTypeOptions1ViewModel.maxValueText) !== highValue1 || parseFloat(ratingFilterTypeOptions1ViewModel.minValueText) !== lowValue1;

    const lowValue2 = parseFloat(ratingFilterTypeOptions2ViewModel.lowRangeValue);
    const highValue2 = parseFloat(ratingFilterTypeOptions2ViewModel.highRangeValue);
    const isFilteredRating2 = parseFloat(ratingFilterTypeOptions2ViewModel.maxValueText) !== highValue2 || parseFloat(ratingFilterTypeOptions2ViewModel.minValueText) !== lowValue2;

    let comparedValue1 = null;
    let comparedValue2 = null;

    try {
        if (isGlobalRatingSelected) {
            switch (ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType) {
                case RatingFilterType.Datagraph:
                    comparedValue1 = ratingData.GlobalDgRating;
                    break;
                case RatingFilterType.EPS:
                    comparedValue1 = ratingData.GlobalEpsRank;
                    break;
                case RatingFilterType.RelativeStrength:
                    // GlobalRsRating for Compare; GlobalRelStrength for rotation graph filter 
                    comparedValue1 = ratingData.GlobalRsRating != undefined ? ratingData.GlobalRsRating : ratingData.GlobalRelStrength;
                    break;
                default:
                    // Accdis for Compare; AccDis for rotation graph filter
                    comparedValue1 = ratingData.Accdis != undefined? ratingData.Accdis : ratingData.AccDis;
                    break;
            }
            switch (ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType) {
                case RatingFilterType.Datagraph:
                    comparedValue2 = ratingData.GlobalDgRating;
                    break;
                case RatingFilterType.EPS:
                    comparedValue2 = ratingData.GlobalEpsRank;
                    break;
                case RatingFilterType.RelativeStrength:
                    // GlobalRsRating for Compare; GlobalRelStrength for rotation graph filter
                    comparedValue2 = ratingData.GlobalRsRating != undefined ? ratingData.GlobalRsRating : ratingData.GlobalRelStrength;
                    break;
                default:
                    // Accdis for Compare; AccDis for rotation graph filter
                    comparedValue2 = ratingData.Accdis != undefined ? ratingData.Accdis : ratingData.AccDis;
                    break;
            }
        }
        else {
            switch (ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType) {
                case RatingFilterType.Datagraph:
                // DataGraphRating for Compare; DgRating for rotation graph filter
                    comparedValue1 = ratingData.DgRating != undefined ? ratingData.DgRating : ratingData.DataGraphRating;
                    break;
                case RatingFilterType.EPS:
                    comparedValue1 = ratingData.EpsRank;
                    break;
                case RatingFilterType.RelativeStrength:
                // RelativeStrength for Compare; RelStrength for rotation graph filter
                    comparedValue1 = ratingData.RelStrength != undefined ? ratingData.RelStrength : ratingData.RelativeStrength;
                    break;
                default:
                    // Accdis for Compare; AccDis for rotation graph filter
                    comparedValue1 = ratingData.Accdis != undefined ? ratingData.Accdis : ratingData.AccDis;
                    break;
            }
            switch (ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType) {
                case RatingFilterType.Datagraph:
                // DataGraphRating for Compare; DgRating for rotation graph filter
                    comparedValue2 = ratingData.DgRating != undefined ? ratingData.DgRating : ratingData.DataGraphRating;
                    break;
                case RatingFilterType.EPS:
                    comparedValue2 = ratingData.EpsRank;
                    break;
                case RatingFilterType.RelativeStrength:
                // RelativeStrength for Compare; RelStrength for rotation graph filter
                    comparedValue2 = ratingData.RelStrength != undefined ? ratingData.RelStrength : ratingData.RelativeStrength;
                    break;
                default:
                    // Accdis for Compare; AccDis for rotation graph filter
                    comparedValue2 = ratingData.Accdis != undefined ? ratingData.Accdis : ratingData.AccDis;
                    break;
            }
        }
        if (tab === CompareTabType.ComparisonGraph) {
            return ((isFilteredRating1 && (lowValue1 > comparedValue1 || comparedValue1 > highValue1)) || (isFilteredRating2 && (lowValue2 > comparedValue2 || comparedValue2 > highValue2)))
        } else {
            const item = Object.assign(new SymbolDataModel, itemData);
            let filterStatus = (lowValue1 <= comparedValue1 && comparedValue1 <= highValue1 && lowValue2 <= comparedValue2 && comparedValue2 <= highValue2)
            let newItem = Object.assign(item, { isFiltered: filterStatus });
            return newItem;
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in liquidityFilter.js, isMatchRatingFilter ${error}`);
    }
}


export function* getFilterSymbol(itemData) {
    const liqFilterState = yield select(getSelectedTabLiqFilterData);
    try {
        if (!liqFilterState.isFilterOn) return itemData;
        const item = Object.assign(new SymbolDataModel, itemData);
        const { avolFilterViewModel, mktCapFilterViewModel } = liqFilterState;
        const mktLow = parseFloat(mktCapFilterViewModel.lowRangeValue);
        const mktHigh = parseFloat(mktCapFilterViewModel.highRangeValue);
        const isFilteredMktCap = parseFloat(mktCapFilterViewModel.maxValueText) !== mktHigh || parseFloat(mktCapFilterViewModel.minValueText) !== mktLow;
        const avolLow = parseFloat(avolFilterViewModel.lowRangeValue);
        const avolHigh = parseFloat(avolFilterViewModel.highRangeValue);
        const isFilteredAvol = parseFloat(avolFilterViewModel.maxValueText) !== avolHigh || parseFloat(avolFilterViewModel.minValueText) !== avolLow;
        const isFilterType = yield call(filterSymbolType, item);
        let newItem = Object.assign(item, { isFilter: false });
        if (((!item.isUserCreated) && isFilterType)) {
            const isMatchRatingFilters = yield call(isMatchRatingFilter, CompareTabType.ComparisonGraph, item, liqFilterState);
            if (((isFilteredAvol && (item.AvDolV < avolLow || item.AvDolV > avolHigh)) || (isFilteredMktCap && (item.MktCap < mktLow || item.MktCap > mktHigh))) || isMatchRatingFilters) {
                newItem = Object.assign(item, { isFilter: true });
            }
            else {
                newItem = Object.assign(item, { isFilter: false });
            }
        }
        return newItem;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in liqAndRatingFilterSaga.js, getFilterSymbol ${error}`);
    }
}


function* setComparisonGraphFilter() {
    const liqFilterState = yield select(getSelectedTabLiqFilterData);
    try {
        const states = yield select(getCompChartListItem);
        if (states.length <= 0) return;
        if (liqFilterState.isFilterOn) {
            const cloneStates = [...states];
            const newStates = yield all(cloneStates.map((item) => call(getFilterSymbol, item)));
            yield put({
                type: ActionTypes.FILTER_ALL_DATA,
                data: newStates
            });
        }
        else {
            yield call(clearAllFilter);
        }
        yield call(processData);
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in liqAndRatingFilterSaga.js, setComparisonGraphFilter ${error}`);
    }
}

export function* setRotationGraphFilter() {
    const liqFilterState = yield select(getSelectedTabLiqFilterData);
    const menuName = yield select(getSelectedTabActiveMenuName);
    const stateData = yield select(getActiveTabActiveMenuData);
    const mktCapDictionary = liqFilterState.mktCapFilterViewModel ? liqFilterState.mktCapFilterViewModel.intervalData : null;
    const avolDictionary = liqFilterState.avolFilterViewModel ? liqFilterState.avolFilterViewModel.intervalData : null;

    if (StringUtil.isEmpty(mktCapDictionary) || StringUtil.isEmpty(avolDictionary))
        return;

    if (liqFilterState.mktCapFilterViewModel.highSliderRangeValueIndex == -1 || liqFilterState.mktCapFilterViewModel.lowSliderRangeValueIndex
        == -1 || liqFilterState.avolFilterViewModel.highSliderRangeValueIndex == -1 || liqFilterState.avolFilterViewModel.lowSliderRangeValueIndex == 1)
        return;

    if (!StringUtil.isEmpty(stateData.stockList) && !StringUtil.isEmpty(mktCapDictionary) && !StringUtil.isEmpty(avolDictionary)) {
        let listItems = cloneDeep(stateData.stockList);
        let filteredStockList = yield call(getFilteredStockData, liqFilterState, listItems);

        //Sort on itemName
        filteredStockList = _.each(_.sortBy(filteredStockList, 'itemName'), (item, i) => {
            item.itemColor = CompRotationHelper.prototype.getLineColor(i);
        });

        // if (filteredStockList.length > 0) {
            if (menuName === CompareMenuType.Symbols) {
                yield put({
                    type: ActionTypes.SET_SYMBOLS_LISTITEMS,
                    listItems: filteredStockList
                })
            } else {
                yield put({
                    type: ActionTypes.SET_GROUPS_LISTITEMS,
                    listItems: filteredStockList
                })
            }

            yield call(filterQuadData, filteredStockList)
        // }
    }
}

function* getFilteredStockData(liqFilterState, listItems) {
    if (!liqFilterState.isFilterOn || liqFilterState.isFilterManualOff)
        return listItems;
    let mktLow = parseFloat(liqFilterState.mktCapFilterViewModel.lowRangeValue);
    let mktHigh = parseFloat(liqFilterState.mktCapFilterViewModel.highRangeValue);
    let avolLow = parseFloat(liqFilterState.avolFilterViewModel.lowRangeValue);
    let avolHigh = parseFloat(liqFilterState.avolFilterViewModel.highRangeValue);

    listItems = _.filter(listItems, (item) => (item.avol >= avolLow && item.avol <= avolHigh && item.mktCp >= mktLow && item.mktCp <= mktHigh));
    const newStates = yield all(
        listItems.map((item) => {
            return call(isMatchRatingFilter, CompareTabType.RotationGraph, item.ratingData, liqFilterState, item);
        })
    );
    listItems = _.filter(newStates, (item) => (item.isFiltered == true));
    
    return listItems;
}

/***************************** Subroutines ************************************/

function* liqFilterChange(saveSettings = true) {
    const menuTab = yield select(getMenuTab);
    const selectedTab = menuTab.selectedTab;
    try {
        if (selectedTab === CompareTabType.ComparisonGraph)
            yield call(setComparisonGraphFilter);
        else
            yield call(setRotationGraphFilter);

        if (saveSettings) {
            yield put({
                type: ActionTypes.SAVE_SETTINGS
            })
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in liqAndRatingFilterSaga.js, liqFilterChange ${error}`);
    }
}


export function* watchHandleLiquidityRatingFilter() {
    yield takeLatest(ActionTypes.GET_LIQUIDITY_RATING_FILTER, getRatingFilter)
}

export function* watchHandleUpdateSelectedRatingFilterType() {
    yield takeLatest(ActionTypes.UPDATE_SELECTED_RATING_FILTER_TYPE, updateSelectedRatingFilterType)
}

export function* watchHandleInitLiqViewModel() {
    yield takeLatest(ActionTypes.INIT_LIQ_VIEW_MODEL, initializeViewModel)
}

export function* watchHandleLiqFilterOnOff() {
    yield takeLatest(ActionTypes.TOGGLE_LIQ_ON_OFF, toggleFilterOnOff)
}

export function* watchHandleUpdateDataFilterRange() {
    yield takeLatest(ActionTypes.UPDATE_DATA_FILTERRANGE, updateDataFilterRange)
}

export function* watchHandleOnKeyPressUpdateSlider() {
    yield takeLatest(ActionTypes.ON_KEYPRESS_UPDATE_SLIDER, onKeyPressUpdateSlider)
}

export function* watchHandleSaveLiquidityFilterValueToSettings() {
    yield takeLatest(ActionTypes.SAVE_LIQ_FILTER_VALUE, saveFilterDataToSettings)
}

export function* watchHandleUpdateRatingFilterTitle() {
    yield takeLatest(ActionTypes.UPDATE_RATING_FILTER_TITLE, updateRatingFilterTitle)
}

export function* watchLiqFilterChange() {
    yield takeLatest(ActionTypes.LIQ_FILTER_CHANGE, liqFilterChange);
}
