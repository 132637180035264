/* eslint-disable */
import StockMarketUtil from "../../Utils/StockMarketUtil";
import moment from "moment";
import GraphType from "GraphType";
import _ from "underscore";
import PeriodicityType from "PeriodicityType";
import { dispatch } from "../../Redux/dispatch";
import { initTimeLine } from "../../Actions/TabDataGraphAction.js";

class CalcFactorResearch {
    constructor(_state) {
        this.state = _state;
        this._alpha = 1;
    }

    NDateNode(nDate, absolute = false) {
        let _state = this.state;
        let periodicity = _state.periodicity;
        let dayOfWeek = nDate.getDay();
        if (dayOfWeek == 6) nDate.setDate(nDate.getDate() - 1);
        if (dayOfWeek == 0) nDate.setDate(nDate.getDate() - 2);

        switch (periodicity) {
            case GraphType.Weekly:
                if (nDate.getDay() != 5) {
                    let dys = 5 - dayOfWeek;
                    nDate.setDate(nDate.getDate() + dys);
                    if (nDate > _state.endDate && !absolute) nDate = _state.endDate;
                }
                break;
            case GraphType.Monthly:
                nDate = StockMarketUtil.GetMEndDate(nDate);
                if (nDate > _state.endDate && !absolute) nDate = _state.endDate;
                break;
            case GraphType.Annual:
                {
                    nDate = StockMarketUtil.GetAEndDate(nDate);
                    if (nDate > _state.endDate && !absolute) nDate = _state.endDate;
                }
                break;
            case GraphType.Quarterly:
                {
                    let year = nDate.getFullYear();
                    let add = nDate.getMonth() % 3;
                    let month = nDate.getMonth() + (add == 1 ? 2 : add == 2 ? 1 : 0) + 1;
                    if (month > 12) {
                        month = 1;
                        year++;
                    }

                    nDate = new Date(year, month - 1, 1);
                    nDate.setDate(nDate.getDate() - 1);
                    nDate = StockMarketUtil.GetMEndDate(nDate);
                    if (nDate > _state.endDate && !absolute) nDate = _state.endDate;
                }
                break;
            default:
                break;
        }
        return nDate;
    }

    SetTimelineDate(xDate) {
        let date = this.NDateNode(xDate);
        return date;
    }

    FindStockIndex(date) {
        let _state = this.state;
        let index = 0;
        let stockData = _state.HiLowPoints.allPoints;
        let lng = _state.HiLowPoints.allPoints.length;
        if (stockData[lng - 1] && date.getTime() < stockData[lng - 1].Date.getTime()) return -1;
        for (; index < lng; index++) if (date.getTime() === stockData[index].Date.getTime()) break;
        return index < lng ? index : -1;
    }

    toUppercase = (eventName) =>
        eventName
            .split("_")
            .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
            .join(" ");

    controlPoints(x) {
        let i,
            n = x.length - 1,
            m,
            a = new Array(n),
            b = new Array(n),
            r = new Array(n);
        (a[0] = 0), (b[0] = 2), (r[0] = x[0] + 2 * x[1]);
        for (i = 1; i < n - 1; ++i) (a[i] = 1), (b[i] = 4), (r[i] = 4 * x[i] + 2 * x[i + 1]);
        (a[n - 1] = 2), (b[n - 1] = 7), (r[n - 1] = 8 * x[n - 1] + x[n]);
        for (i = 1; i < n; ++i) (m = a[i] / b[i - 1]), (b[i] -= m), (r[i] -= m * r[i - 1]);
        a[n - 1] = r[n - 1] / b[n - 1];
        for (i = n - 2; i >= 0; --i) a[i] = (r[i] - a[i + 1]) / b[i];
        b[n - 1] = (x[n] + a[n - 1]) / 2;
        for (i = 0; i < n - 1; ++i) b[i] = 2 * x[i + 1] - a[i + 1];
        return [a, b];
    }
    moveTo(x, y) {
        return "M" + +x + "," + +y;
    }
    lineTo(x, y) {
        return "L" + +x + "," + +y;
    }
    bezierCurveTo(x1, y1, x2, y2, x, y) {
        return "C" + +x1 + "," + +y1 + "," + +x2 + "," + +y2 + "," + +x + "," + +y;
    }

    getMaxLength(node) {
        let _state = this.state;
        let optimalNDay = node.optimalNDay;
        let optimalNode = node.factorEventConeData[node.factorEventConeData.length - 1];
        let TradeDate = new Date(moment(optimalNode.tradeDate).format("MM/DD/YYYY"));
        let pointIndex = this.FindStockIndex(TradeDate);
        if (pointIndex === -1) {
            optimalNode.X = node.startIn + optimalNode.step * 4;
        } else {
            let stock = _state.HiLowPoints.allPoints[pointIndex];
            optimalNode.X = stock.xAxis;
        }
        if (optimalNode.X + this.getOffsetDay() * 4 >= _state.chartWidth) {
            switch (_state.periodicity) {
                case "Daily":
                    return Math.min(optimalNDay, node.nday + 63);
                case "Weekly":
                    return Math.min(optimalNDay, node.nday + 252);
                default:
                    break;
            }
        } else {
            return optimalNDay;
        }
    }
    getOffsetDay() {
        let _state = this.state;
        switch (_state.periodicity) {
            case "Daily":
                return 15;
            case "Weekly":
                return 64;
            default:
                break;
        }
    }
    concatenatePoint(that, x, y, d) {
        let x1 = that._x1,
            y1 = that._y1,
            x2 = that._x2,
            y2 = that._y2;

        if (that._l01_a > 1e-12) {
            let a = 2 * that._l01_2a + 3 * that._l01_a * that._l12_a + that._l12_2a,
                n = 3 * that._l01_a * (that._l01_a + that._l12_a);
            x1 = (x1 * a - that._x0 * that._l12_2a + that._x2 * that._l01_2a) / n;
            y1 = (y1 * a - that._y0 * that._l12_2a + that._y2 * that._l01_2a) / n;
        }

        if (that._l23_a > 1e-12) {
            let b = 2 * that._l23_2a + 3 * that._l23_a * that._l12_a + that._l12_2a,
                m = 3 * that._l23_a * (that._l23_a + that._l12_a);
            x2 = (x2 * b + that._x1 * that._l23_2a - x * that._l12_2a) / m;
            y2 = (y2 * b + that._y1 * that._l23_2a - y * that._l12_2a) / m;
        }

        d += that.bezierCurveTo(x1, y1, x2, y2, that._x2, that._y2);
        return d;
    }
    point(x, y, d) {
        (x = +x), (y = +y);

        if (this._point) {
            let x23 = this._x2 - x,
                y23 = this._y2 - y;
            this._l23_a = Math.sqrt((this._l23_2a = Math.pow(x23 * x23 + y23 * y23, this._alpha)));
        }

        switch (this._point) {
            case 0:
                this._point = 1;
                d += this.moveTo(x, y);
                break;
            case 1:
                this._point = 2;
                break;
            case 2:
                this._point = 3; // proceed
            default:
                d = this.concatenatePoint(this, x, y, d);
                break;
        }

        (this._l01_a = this._l12_a), (this._l12_a = this._l23_a);
        (this._l01_2a = this._l12_2a), (this._l12_2a = this._l23_2a);
        (this._x0 = this._x1), (this._x1 = this._x2), (this._x2 = x);
        (this._y0 = this._y1), (this._y1 = this._y2), (this._y2 = y);
        return d;
    }
    generatePath(nodeArray) {
        this._x0 = this._x1 = this._x2 = this._y0 = this._y1 = this._y2 = NaN;
        this._l01_a = this._l12_a = this._l23_a = this._l01_2a = this._l12_2a = this._l23_2a = this._point = 0;
        let length = nodeArray.length;
        let d = "";
        for (let i = 0; i <= length; i++) {
            if (i === length) {
                d = this.point(nodeArray[i - 1][0], nodeArray[i - 1][1], d);
                return d;
            }
            d = this.point(nodeArray[i][0], nodeArray[i][1], d);
        }
        return d;
    }
    prepareConeData(node) {
        let _state = this.state;
        let { factorEventConeData } = node;
        node.maxY = [node.startCloseY];
        node.middleY = [node.startCloseY];
        node.allX = [node.startIn];
        node.targetArray = [];
        node.coneArray = [[node.startIn, node.startCloseY]];
        node.targetY = [];
        node.targetX = [];
        let allPoints = _state.HiLowPoints.allPoints;
        let startDateClosePrice = node.startDateClosePrice;
        let maxLength = this.getMaxLength(node);
        node.EndYvalTargetPrice = _state.scale.ComputeY(startDateClosePrice * node.yValTargetLin);
        let coneDataLen = factorEventConeData.length;
        let offsetDay = this.getOffsetDay();

        for (let i = 0; i < coneDataLen; i++) {
            let point = factorEventConeData[i];
            point.TradeDate = new Date(moment(point.tradeDate).format("MM/DD/YYYY"));

            if (point.nday > maxLength || (point.nday !== node.optimalNDay && point.nday === node.targetNDay)) {
                break;
            }

            let TradeDate = point.TradeDate;
            let pointIndex = this.FindStockIndex(TradeDate);

            if (pointIndex === -1 && point.nday <= maxLength) {
                point.X = node.startIn + point.step * 4;
            }

            if (pointIndex !== -1 && point.nday <= maxLength) {
                let stock = allPoints[pointIndex];
                point.X = stock.xAxis;
            }

            point.YvalAvgReturnPath = _state.scale.ComputeY(startDateClosePrice * point.yValAvgLinReturnPath);
            point.Yval99Upper = _state.scale.ComputeY(startDateClosePrice * point.yVal99Upper);
            point.Yval99Lower = _state.scale.ComputeY(startDateClosePrice * point.yVal99Lower);
            point.YvalAvgGain = _state.scale.ComputeY(startDateClosePrice * point.yValAvgLinGain);
            point.YvalAvgLoss = _state.scale.ComputeY(startDateClosePrice * point.yValAvgLinLoss);
            point.YvalTarget = _state.scale.ComputeY(startDateClosePrice * point.yValTargetLin);

            node.maxY.unshift(point.YvalAvgGain);
            node.maxY.push(point.YvalAvgLoss);
            node.middleY.unshift(point.Yval99Upper);
            node.middleY.push(point.Yval99Lower);
            node.targetY.push(point.YvalAvgReturnPath);

            if (point.nday === maxLength) {
                node.upToDate = moment(point.TradeDate).add(offsetDay, "day").toDate();
                node.YvalTargetPrice = point.YvalTarget;
                node.YvalAvgGain = point.YvalAvgGain;
                node.YvalAvgLoss = point.YvalAvgLoss;
            }

            node.allX.push(point.X);
            node.allX.unshift(point.X);

            node.targetX.push(point.X);

            node.coneArray.push([point.X, point.YvalAvgLoss]);
            node.coneArray.unshift([point.X, point.YvalAvgGain]);
            node.targetArray.push([point.X, point.YvalAvgReturnPath]);
        }

        let targetNDay = node.factorEventTargetData;
        if (targetNDay && targetNDay.nday === maxLength) {
            targetNDay.YvalAvgReturnPath = _state.scale.ComputeY(startDateClosePrice * targetNDay.yValAvgLinReturnPath);
            targetNDay.Yval99Upper = _state.scale.ComputeY(startDateClosePrice * targetNDay.yVal99Upper);
            targetNDay.Yval99Lower = _state.scale.ComputeY(startDateClosePrice * targetNDay.yVal99Lower);
            targetNDay.YvalAvgGain = _state.scale.ComputeY(startDateClosePrice * targetNDay.yValAvgLinGain);
            targetNDay.YvalAvgLoss = _state.scale.ComputeY(startDateClosePrice * targetNDay.yValAvgLinLoss);
            targetNDay.YvalTarget = _state.scale.ComputeY(startDateClosePrice * targetNDay.yValTargetLin);

            node.maxY.unshift(targetNDay.YvalAvgGain);
            node.maxY.push(targetNDay.YvalAvgLoss);
            node.middleY.unshift(targetNDay.Yval99Upper);
            node.middleY.push(targetNDay.Yval99Lower);
            node.targetY.push(targetNDay.YvalTarget);

            targetNDay.X = node.startIn + targetNDay.step * 4;

            node.upToDate = moment(targetNDay.tradeDate).add(offsetDay, "day").toDate();
            node.YvalTargetPrice = targetNDay.YvalTarget;
            node.YvalAvgGain = targetNDay.YvalAvgGain;
            node.YvalAvgLoss = targetNDay.YvalAvgLoss;

            node.allX.push(targetNDay.X);
            node.allX.unshift(targetNDay.X);

            node.targetX.push(targetNDay.X);

            node.coneArray.push([targetNDay.X, targetNDay.YvalAvgLoss]);
            node.coneArray.unshift([targetNDay.X, targetNDay.YvalAvgGain]);
            node.targetArray.push([targetNDay.X, targetNDay.YvalTarget]);
        }
    }

    generatePathData(x, y) {
        if (x.length === 0 || y.length === 0) {
            return;
        }
        let n = x.length;
        let d = this.moveTo(x[0], y[0]);
        if (n === 2) {
            d += this.lineTo(x[1], y[1]);
        } else {
            let px = this.controlPoints(x);
            let py = this.controlPoints(y);
            for (let i0 = 0, i1 = 1; i1 < n; ++i0, ++i1) {
                d += this.bezierCurveTo(px[0][i0], py[0][i0], px[1][i0], py[1][i0], x[i1], y[i1]);
            }
        }
        return d;
    }

    findMostRecentGammaBin(oRt, { avgMaxRunup, avgMaxDrawdown }) {
        if (oRt > 0 && avgMaxRunup !== 0) {
            return Math.min(5, Math.round(oRt / avgMaxRunup) + 1);
        }
        if (oRt <= 0 && avgMaxDrawdown !== 0) {
            return 0 - Math.min(5, Math.round(oRt / avgMaxDrawdown) + 1);
        }
    }

    findHRt(mostRecentGammaBin, gammaBinData, node) {
        return gammaBinData.findIndex(
            (gammaBinItem) =>
                node.EventId === gammaBinItem.EventId && node.SegmentId === gammaBinItem.SegmentId && node.optimalNDay === gammaBinItem.maxNDays && node.nday === gammaBinItem.nday && gammaBinItem.gammaBin === mostRecentGammaBin,
        );
    }

    findTargetGammaBinIndex(node, gammaBinData) {
        return gammaBinData.findIndex((gammaBinItem) => node.EventId === gammaBinItem.EventId && node.SegmentId === gammaBinItem.SegmentId && node.optimalNDay === gammaBinItem.maxNDays && node.nday === gammaBinItem.nday);
    }

    checkOverflowY(y) {
        if (y < 10) {
            y = 10;
        } else if (y > this.state.chartHeight) {
            y = this.state.chartHeight - 3;
        }
        return y;
    }

    isTruncated(y, YvalAvgGain, YvalAvgLoss) {
        return y > YvalAvgGain && y < YvalAvgLoss;
    }

    updateLabels(node, endX) {
        let { YvalAvgGain, YvalAvgLoss, winnerLinPercent, losserLinPercent, startIn } = node;

        let labelWinnerX = startIn + (endX - startIn) / 2 - 35;
        let labelLoserX = labelWinnerX;
        let labelWinnerY = this.checkOverflowY(YvalAvgGain - 2);
        let labelLoserY = this.checkOverflowY(YvalAvgLoss + 10);

        node.labels = [
            {
                x: labelWinnerX,
                y: labelWinnerY,
                percent: this.toRoundPercent(winnerLinPercent * 100),
                label: "Winner",
                isTruncated: this.isTruncated(labelWinnerY, YvalAvgGain, YvalAvgLoss),
            },
            {
                x: labelLoserX,
                y: labelLoserY,
                percent: this.toRoundPercent(losserLinPercent * 100),
                label: "Loser",
                isTruncated: this.isTruncated(labelLoserY, YvalAvgGain, YvalAvgLoss),
            },
        ];
    }
    /*
    n_start = the starting nday
    n_end = the ending nday
    n_target = the nday whose return you want to estimate

    return_start = expected cumulative return as of n_start
    return_end = expected cumulative return as of n_end
    return_target = the return you want to estimate

    return_target = exp( (n_target - n_start) * ( log(1.0 + return_end) - log(1.0 + return_start) ) / (n_end - n_start) ) - 1.0

    */
    getExtrapolatedReturn(node, hRt) {
        return Math.exp((node.targetNDay - node.nday) * ((Math.log(1.0 + hRt ))) / (node.optimalNDay - node.nday)) - 1
    }

    updateRevisedTargetPrice(node, gammaBinData, endX) {
        let obj = {
            price: null,
            lineData: null,
            circleData: null,
            indicator: {
                x: endX + 10,
                y: null,
                percent: null,
            },
        };
        if (node.isActive && gammaBinData.length !== 0) {
            let mostRecentPrice;
            let mostRecentPoint = this.state.HiLowPoints.allPoints[0];
            if (this.state.isReceivedQuote) {
                mostRecentPrice = this.state.StockInformation.CPrice;
            } else {
                mostRecentPrice = mostRecentPoint.graphData.Close;
            }
            let oRt = Math.log(mostRecentPrice / node.eventPrice);
            let gammaBinIndex = this.findTargetGammaBinIndex(node, gammaBinData);
            if (gammaBinIndex !== -1) {
                let mostRecentGammaBin = this.findMostRecentGammaBin(oRt, gammaBinData[gammaBinIndex]);
                let hRtIndex = this.findHRt(mostRecentGammaBin, gammaBinData, node);
                if (hRtIndex !== -1) {
                    let hRt = gammaBinData[hRtIndex].cumLinRT;
                    if (node.targetNDay) {
                        hRt = this.getExtrapolatedReturn(node,hRt)
                    }
                    obj.price = mostRecentPrice * (hRt + 1);
                    obj.indicator.percent = this.toPercent(hRt* 100);

                    let yPrice = this.checkOverflowY(this.state.scale.ComputeY(obj.price));

                    obj.indicator.y = yPrice;
                    let cx = endX;
                    let cy = yPrice;
                    obj.lineData = this.moveTo(mostRecentPoint.xAxis, mostRecentPoint.yPrice) + this.lineTo(cx, cy);
                    obj.circleData = {
                        cx,
                        cy,
                    };
                    console.log(`%cFactor: ${node.startDate},${obj.price}`, "background:#ddd");
                }
            }
        }
        node.revisedTargetPrice = obj;
    }

    toPercent(value) {
        if (value != null) {
            return value.toFixed(2).toString() + "%";
        } else {
            return "";
        }
    }

    toRoundPercent(value) {
        if (value != null) {
            return Math.round(value).toString() + "%";
        } else {
            return "";
        }
    }

    updateIndicators(node, endX) {
        let x = endX + 10;
        let indicators = [
            {
                x,
                y: this.checkOverflowY(node.YvalAvgGain),
                percent: this.toPercent(node.avgLinGainPercent),
            },
            {
                x,
                y: this.checkOverflowY(node.YvalAvgLoss),
                percent: this.toPercent(node.avgLinLossPercent),
            },
            {
                x,
                y: this.checkOverflowY(node.YvalTargetPrice),
                percent: this.toPercent(node.targetLinPercent),
            },
        ];

        node.indicators = indicators;
    }

    updateShadings(node, endX) {
        let { startIn, startCloseY, YvalAvgGain, YvalAvgLoss } = node;

        let shadings = [
            {
                pathData: this.moveTo(startIn, startCloseY) + this.lineTo(endX, startCloseY) + this.lineTo(endX, YvalAvgGain) + this.lineTo(startIn, YvalAvgGain),
                color: "positive",
            },
            { pathData: this.moveTo(startIn, startCloseY) + this.lineTo(endX, startCloseY) + this.lineTo(endX, YvalAvgLoss) + this.lineTo(startIn, YvalAvgLoss), color: "negative" },
        ];

        node.shadings = shadings;
    }

    stringifyGammaBin(gammaBin) {
        if (!gammaBin) {
            return "";
        }
        gammaBin.forEach((item) => {
            item.EventId = item.eventId.toNumber();
            item.SegmentId = item.segmentId.toNumber();
        });
        return JSON.stringify(gammaBin);
    }

    getGammaBin(factorEventData) {
        let gammaBin = factorEventData.factorEventGammaBinData;
        window.localStorage.setItem("factor_gamma_bin", this.stringifyGammaBin(gammaBin));
        return gammaBin;
    }

    onQuoteReceived(FRData, factorEventData) {
        if (!factorEventData || !FRData) {
            return;
        }
        let gammaBinData = this.getGammaBin(factorEventData);
        let lng = FRData.length;
        for (let i = lng - 1; i > -1; i--) {
            let innerLen = FRData[i].length;
            for (let j = 0; j < innerLen; j++) {
                let node = FRData[i][j];
                if (node.isActive) {
                    let endX = node.targetX[node.targetX.length - 1];
                    this.updateRevisedTargetPrice(node, gammaBinData, endX);
                }
            }
        }
    }

    CalculateFRData(factorEventData, factorResearchSettings, isInitial, _state) {
        this.state = _state;
        let frData = factorEventData.factorEventChartData;
        

        
        if (_state.videoMode || !factorResearchSettings.IsVisible || !factorEventData || !factorEventData.factorEventChartData || !_state.HiLowPoints.allPoints || _state.HiLowPoints.allPoints.length === 0) {
            _state.FRData = frDataList;
            return [[], null];
        }

        let gammaBinData = this.getGammaBin(factorEventData);
        _state.FRData = [];
        let frDataList = [];
        let lsNodeIndex = _state.HiLowPoints.allPoints.length - 1;
        if (!_state.HiLowPoints.allPoints[lsNodeIndex]) {
            const flength = _state.HiLowPoints.allPoints.length;
            lsNodeIndex = 0;
            for (; lsNodeIndex < flength && _state.HiLowPoints.allPoints[lsNodeIndex]; lsNodeIndex++) {}
            lsNodeIndex--;
        }
        const lastNode = _state.HiLowPoints.allPoints[lsNodeIndex];
        let mostRecentNode = null;

        if (frData !== null) {
            let lng = frData.length;

            for (let i = lng - 1; i > -1; i--) {
                let node = frData[i];
                if (node == null) continue;
                node.EventId = node.eventId.toNumber();
                node.SegmentId = node.segmentId.toNumber();
                node.EventName = this.toUppercase(node.eventName);
                node.FactorKey = JSON.stringify({
                    EventId: node.EventId,
                    SegmentId: node.SegmentId,
                    StartDate: node.startDate,
                    EventDate: node.eventDate,
                });
                let { factorEventConeData } = node;

                if (factorEventConeData === null) continue;

                if (factorEventConeData.length - 1 === -1) {
                    continue;
                }

                let startDateKey = moment(node.startDate).format("MM/DD/YYYY");
                let eventDateKey = moment(node.eventDate).format("MM/DD/YYYY");
                node.EventDt = new Date(eventDateKey);
                node.StartDt = new Date(startDateKey);

                if (node.StartDt < lastNode.Date) continue; // start day is before oldest day
                if (node.StartDt > _state.endDate) continue;
                let StartIndex = this.FindStockIndex(node.StartDt);
                if (StartIndex === -1) continue;

                let startPoint = _state.HiLowPoints.allPoints[StartIndex];
                node.startIn = startPoint.xAxis;
                node.startY = node.eventIndicatorType ? startPoint.yLow : startPoint.yHigh;
                node.startYclose = startPoint.yPrice;
                node.startDateClosePrice = node.eventPrice ? node.eventPrice : startPoint.graphData.Close;
                if (node.startDate === node.eventDate) {
                    node.startCloseY = startPoint.yPrice;
                } else {
                    node.startCloseY = _state.scale.ComputeY(node.startDateClosePrice);
                }
                this.prepareConeData(node);
                const endX = node.targetX[node.targetX.length - 1];
                if (node.targetX.some((i) => i < 0)) {
                    node.InView = false;
                } else {
                    node.InView = true;
                }
                node.targetPath = this.generatePath(node.targetArray);
                node.conePath = this.generatePath(node.coneArray);

                node.targetPathData = this.generatePathData(node.targetX, node.targetY);
                node.maxPathData = this.generatePathData(node.allX, node.maxY);
                node.targetCircleData = {
                    cx: endX,
                    cy: node.YvalTargetPrice,
                };


                let markerHeight = null;
                if (node.eventIndicatorType) {
                    let line = node.startY + 70;
                    markerHeight = line > _state.chartHeight - 30 ? _state.chartHeight - 30 : line;
                } else {
                    let line = node.startY - 70;
                    markerHeight = line < 30 ? 30 : line;
                }

                node.flagPosition = {
                    right: _state.chartWidth - node.startIn,
                    markerHeight: markerHeight,
                };

                node.buttonPosition = {
                    x: node.startIn,
                    y: node.startY,
                };

                if (node.eventIndicatorType) {
                    let distance = node.startY + 70;
                    node.flagPosition.top = distance > _state.chartHeight - 30 ? _state.chartHeight - 30 - 2 : distance;
                } else {
                    let distance = _state.chartHeight - node.startY + 70;
                    node.flagPosition.bottom = distance > _state.chartHeight - 30 ? _state.chartHeight - 30 - 2 : distance;
                }

                node.chartWidth = _state.chartWidth;

                if (!mostRecentNode) {
                    mostRecentNode = node;
                }
                if (mostRecentNode && node.EventDt.getTime() === mostRecentNode.EventDt.getTime() && node.absSigTC > mostRecentNode.absSigTC && node.isActive) {
                    mostRecentNode = node;
                }
                if (mostRecentNode && node.EventDt.getTime() > mostRecentNode.EventDt.getTime() && node.StartDt <= _state.endDate && node.isActive) {
                    mostRecentNode = node;
                }

                this.updateLabels(node, endX);
                this.updateRevisedTargetPrice(node, gammaBinData, endX);
                this.updateIndicators(node, endX);
                this.updateShadings(node, endX);

                frDataList.push(node);
            }
        }
        let calculatedResult = _.chain(frDataList)
            .partition((item) => item.eventIndicatorType)
            .map((list) =>
                _.chain(list)
                    .groupBy((item) => item.startDate)
                    .values()
                    .value(),
            )
            .flatten(true)
            .value();

        if (factorEventData.osid.toNumber() === _state.SymbolInfo.Osid && PeriodicityType[_state.periodicity] === factorEventData.periodicityType) {
            _state.FRData = calculatedResult;
        }

        const nodeCount = _state.nodeCount;
        const InitialBufferSize = 200;
        // _state.mostRecentNode = mostRecentNode.upToDate.getTime() > _state.SymbolInfo.LastTradeDate.getTime() ?  mostRecentNode : null;
        _state.mostRecentNode = mostRecentNode && mostRecentNode.upToDate.getTime() > _state.endDate.getTime() ?  mostRecentNode : null;
        if (mostRecentNode && _state.FRData.length > 0 && _state.timeline[4].Date.getTime() < mostRecentNode.upToDate.getTime() && isInitial) {
            dispatch(initTimeLine(mostRecentNode.upToDate, nodeCount, InitialBufferSize, false, false, isInitial));
        }
        return [_state.FRData, _state.mostRecentNode];
    }
}
module.exports = CalcFactorResearch;
