import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CommonDialog from "../../../../../RayCustomControls/CommonDialog/CommonDialog";
import { toggleClearAllDialog, onClickClearAll } from 'Actions/ComparisonAndRotationMenuActions';
//import {getCompareChart} from '../../../../../Reducers/NavCompare/ComparisonGraphReducers/selectors'
import LocalizationStore from 'LocalizationStore';
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType from "CompareTabType";

class ClearAllDialog extends PureComponent {
    render() {
        return (
            this.props.isClearAllDialogOpen && <ErrorBoundary><CommonDialog preClass={"clear-all"}
                isOpen={this.props.isClearAllDialogOpen}
                title={LocalizationStore.getTranslatedData("CCG_DELETEALL", 'DELETE ALL')}
                backButtonShow={false}
                cancelButtonShow={true}
                submitButtonShow={true}
                submitHandler={this.props.onClickClearAll}
                hideModal={this.props.toggleClearAllDialog}
                submitButtonText={LocalizationStore.getTranslatedData('CCG_REMOVE', 'REMOVE')}
                submitButtonOnRight="true"
            >
                <div className="delete-all-region"><p>{LocalizationStore.getTranslatedData("CCG_DeleteAllMsg", "Are you sure you want to delete all contents?")}</p><div className="clearfix"></div></div>
            </CommonDialog></ErrorBoundary>
        );
    }
}

const mapStateToProps = (state) => {
    const selectedTab = state.navCompareReducer.selectedTab;
    const symbolsMenu = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
    const { isClearAllDialogOpen } = selectedTab === CompareTabType.ComparisonGraph ? symbolsMenu.ComparisonMenuStates : symbolsMenu.RotationMenuStates;

    return { isClearAllDialogOpen };
}

const mapDispatchToProps = (dispatch) => ({
    toggleClearAllDialog: () => dispatch(toggleClearAllDialog()),
    onClickClearAll: () => dispatch(onClickClearAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(ClearAllDialog);