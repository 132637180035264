import React from "react";
import KeyTypes from "../../../../Constants/KeyType";
import GridStore from "GridStore";
import ThemeHelper from "ThemeHelper";
import VirtualList from "VirtualList";
import ErrorBoundary from "ErrorBoundary";
import ONeilViewStore from "ONeilViewStore";
import KeyCodes from "../../../../Constants/KeyCodes";
import Header from "./Header.jsx";
import PriceChart from "./PriceChart.jsx";
import { connect } from "react-redux";
import periodicityHelper from "../../../../Utils/PeriodicityHelper.js";
import PeriodicityDropdown from "./PeriodicityDropdown.jsx";
import ContextMenuConstants from "ContextMenuConstants";
import { updateNupColumnPeriodicityChange, initNupChart, processNupListData, updateChartSelection, handleSymbolChange, shouldUpdateNupComponent } from "../../../../Actions/NupViewActions.js";
import Measure from "react-measure";
// import PeriodicityControl from "PeriodicityControl";
class NupView extends React.Component {
    constructor(props) {
        super(props);
        this.virtualList = null;
        this.showGraphRow = this.showGraphRow.bind(this);
        this.showGraphsColumn = this.showGraphsColumn.bind(this);
        this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
        this.onDocumentKeyUp = this.onDocumentKeyUp.bind(this);
        this.onColumnPeriodicityChange = this.onColumnPeriodicityChange.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.setDimensions = this.setDimensions.bind(this);
        this.listOnScroll = this.listOnScroll.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onWindowResize = this.onWindowResize.bind(this);
        this.mouseUpTimeout = null;
        this.initChartTimeout = null;
        this.Nupview = null;
        this.firstVisibleIndex = 0;
        this.lastVisibleIndex = this.props.rows;
        let totalPeriodicity = periodicityHelper.getValidPeriodicitiesHeader();
        const periodicities = {}
        Object.entries(totalPeriodicity).reverse().map(([key, item]) =>{
            periodicities[key] = item;
        })
        this.state = {
            height: 520,
            itemHeight: 260,
            periodicities
        }
    }

    
    componentDidMount(){
        document.addEventListener("keydown", this.onDocumentKeyPress);
        document.addEventListener("keyup", this.onDocumentKeyUp);
        window.addEventListener("resize", this.onWindowResize);
        if(this.Nupview){
            this.Nupview.addEventListener("wheel", this.onWheel);
        }
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.onDocumentKeyPress);
        document.removeEventListener("keyup", this.onDocumentKeyUp);
        window.removeEventListener("resize", this.onWindowResize);
        if(this.Nupview){
            this.Nupview.removeEventListener("wheel", this.onWheel);
        }
    }

    componentDidUpdate(){
        const { globalSelectedIndex, isScrollAvailable, cols, redraw, selected} = this.props;
        if(redraw && this.virtualList){
            this.firstVisibleIndex = 0;
            this.lastVisibleIndex = this.props.rows;
            this.virtualList.scrollToItemIndex(0);
        }
        if(globalSelectedIndex !== -1 && isScrollAvailable && this.virtualList){
            const sIndex = (selected % 2 === 0) ? (globalSelectedIndex / cols | 0): globalSelectedIndex;
            this.virtualList.scrollToItemIndex(sIndex)
            this.firstVisibleIndex = sIndex;
            this.lastVisibleIndex = sIndex + this.props.rows;

        }

    }

    onWindowResize(e){
        const newNodeCount = parseInt(e.target.innerWidth/(this.props.nodeWidth*this.props.cols));
        if((newNodeCount -18) > this.props.nodeCount){
            this.props.listData.slice(this.firstVisibleIndex, this.lastVisibleIndex).map((item)=> item.map((value)=>{ value.nupData = null; value.graphData = null; value.isLoading = true; }))
            if(this.initChartTimeout){
                clearTimeout(this.initChartTimeout)
            }
            this.initChartTimeout = setTimeout(this.props.initNupChart, 0,this.firstVisibleIndex, this.lastVisibleIndex);
        }
    }

    onWheel(e){
        if(!this.virtualList){
            return
        }
        e.preventDefault();
        e.stopPropagation();
        let index = 1
        if(e.deltaY < 0){
            index = -1;
        }
        this.virtualList.scrollToItem(index);
        // const firstVisibleIndex = Math.ceil(this.virtualList.scrollPanel.scrollTop / this.state.itemHeight);
        // if(this.mouseUpTimeout)
        //         clearTimeout(this.mouseUpTimeout);
        //     this.mouseUpTimeout =  setTimeout(this.onMouseUp, 800, firstVisibleIndex, Math.min(this.props.listDataLength, firstVisibleIndex + this.props.rows)) 
    }

    onDocumentKeyPress(e) {
        const { listData, listDataLength, cols, rows, selectedIndex } = this.props;
        if(e.srcElement.tagName !== "BODY" || !this.virtualList) return;
        if(e.keyCode === KeyCodes.UP_ARROW || e.keyCode === KeyCodes.DOWN_ARROW || e.keyCode === KeyCodes.PAGE_UP || e.keyCode === KeyCodes.PAGE_DOWN)
        {
            let index = 0;
            if (e.keyCode === KeyCodes.UP_ARROW && this.virtualList.firstVisibleIndex > 0) {
                index = -1;
            }
            else if (e.keyCode === KeyCodes.DOWN_ARROW && this.virtualList.lastVisibleIndex < listDataLength) {
                index = 1;
            }
            else if (e.keyCode === KeyCodes.PAGE_UP && this.virtualList.firstVisibleIndex > 1) {
               index = -2;
            }
            else if (e.keyCode === KeyCodes.PAGE_DOWN && this.virtualList.lastVisibleIndex < listDataLength-1) {
                index = 2;
            }
            this.virtualList.scrollToItem(index);
            // const firstVisibleIndex = Math.ceil(this.virtualList.scrollPanel.scrollTop / this.state.itemHeight);
            // if(this.mouseUpTimeout)
            //     clearTimeout(this.mouseUpTimeout);
            // this.mouseUpTimeout =  setTimeout(this.onMouseUp, 800, firstVisibleIndex, Math.min(this.props.listDataLength, firstVisibleIndex + this.props.rows)) 
        }
        if(e.keyCode === KeyCodes.ENTER && listDataLength > 0 && selectedIndex.length > 0){
          const item =  listData[selectedIndex[0]/ cols | 0][selectedIndex[0] % cols];
          this.props.handleSymbolChange(item.HeaderData.symbolData)
        }
        if(e.ctrlKey && e.keyCode === KeyCodes.KEY_V){
            this.props.onGridRowContextMenuItemClick(ContextMenuConstants.ROW_PASTE, { listId: this.props.selectedListId, selectedIndexes: -1, data: navigator.clipboard.readText() });
            GridStore.isPasteEvent = true;
        }
        if(e.keyCode === KeyCodes.SPACE && listDataLength > 0){
            e.preventDefault();
        }
    }

    onDocumentKeyUp(e){
        if((document.activeElement && !document.body.classList.contains("modal-open") && document.activeElement.tagName !== 'TEXTAREA' && !document.activeElement.isContentEditable && document.activeElement.tagName !== "INPUT")){
            const { listData, listDataLength, cols, globalSelectedIndex } = this.props
            if(e.keyCode === KeyCodes.SPACE && listDataLength > 0){
                let sIndex = globalSelectedIndex + 1;
                const item = this.props.selected % 2 === 0 ? listData[sIndex/ cols | 0][sIndex % cols] : listData[sIndex][0];
                this.props.handleSymbolChange(item.HeaderData.symbolData)
            }
        }
        
    }

    static getDerivedStateFromProps(props, state){
        return {...state, ...props.state}
    }
    
    onContexMenu(e, selectedIndex, isSelected, instrumentType){
        e.stopPropagation();
        e.preventDefault();
        let length = this.props.nupCharts[this.props.selected].per.length;
        length = length ===0 ? 1 : length;
        const selectedIndexs = [];
        this.props.selectedIndex.map((item)=> {
            let value = parseInt(item/length)
            if(!selectedIndexs.includes(value)){
                selectedIndexs.push(value)
            }
        })
        if(!isSelected){
            isSelected = true;
            this.props.updateChartSelection(selectedIndex);
            let value = selectedIndex[0]*this.props.cols + selectedIndex[1];
            value = parseInt(value/length);
            if(!selectedIndex.includes(value)){
                selectedIndexs.push(value)
            }
        }
        this.props.onGridRowContextMenuClick(e, selectedIndexs, instrumentType);
    }

    updateChartSelection(e, selectedIndex){
        if (e.shiftKey)
            this.props.updateChartSelection(selectedIndex, KeyTypes.shiftKey);
        else if (e.ctrlKey || e.metaKey)
            this.props.updateChartSelection(selectedIndex, KeyTypes.ctrlKey);
        else
            this.props.updateChartSelection(selectedIndex, KeyTypes.None);

        e.stopPropagation();
        e.preventDefault();

    }

    showGraphRow(item, top, rIndex) { return(<div 
                                            className="nup-row" 
                                            key={rIndex}
                                            style={{height: Math.ceil(this.state.height / this.props.rows)+"px", 
                                            //float:"none", position: "absolute", 
                                            top: top}}>
                    {item.map((item, index)=>this.showGraphsColumn(item, rIndex, index))}
                </div>);
    }
    showGraphsColumn  (colData, rIndex, index)
    {   let nupClass = "nup-column";
            nupClass += colData.isSelected ? " selective" : colData.globalSelected ? " clicked" :  "";
        return <div className={nupClass} 
                    key={colData.symbolId}
                    onClick = {(e)=> this.updateChartSelection(e, [rIndex, index])}
                    onContextMenu = {(e)=>this.onContexMenu(e, [rIndex, index], colData.isSelected, colData.instrumentType)}
                >
            <div className="nup-box"> {/*style={{ background: backgroundColor, color: textColor }}> */}
                <Header 
                    selectedIndex= {[rIndex, index]}/>
                {<PriceChart 
                    selectedIndex= {[rIndex, index]} />}
            </div>
        </div>;
    }

    onColumnPeriodicityChange(periodicity, event, column) {
        GridStore.resetSelectedIndexes();
        periodicity = periodicityHelper.getPeriodicityValue(periodicity);
        this.props.updateNupColumnPeriodicityChange({ periodicity, column });
        const symbolList = ONeilViewStore.getSourceData();
        this.props.processNupListData(symbolList)
    }

    onMouseUp(firstVisibleIndex, lastVisibleIndex){
        if(this.firstVisibleIndex === firstVisibleIndex || this.lastVisibleIndex === lastVisibleIndex){
          this.props.listData.slice(this.firstVisibleIndex, this.lastVisibleIndex).map((item)=> item.map((value)=> { value.isLoading = false; }));
           this.setState({});//setState is for rerending this component.
           return;
        } 
        this.firstVisibleIndex = firstVisibleIndex;
        this.lastVisibleIndex = lastVisibleIndex;
        if(this.initChartTimeout){
            clearTimeout(this.initChartTimeout)
        }
        if(this.lastVisibleIndex === this.props.listDataLength && (this.firstVisibleIndex + this.props.rows) !== this.lastVisibleIndex)
         { 
            if(this.lastVisibleIndex - this.props.rows > 0)
                this.firstVisibleIndex = this.lastVisibleIndex - this.props.rows;
            else 
                this.firstVisibleIndex = 0
        }
        this.initChartTimeout = setTimeout(this.props.initNupChart, 0,this.firstVisibleIndex, this.lastVisibleIndex);
    }

    setDimensions(dimension){
        this.setState({ height:  dimension.height, itemHeight: Math.ceil(dimension.height / this.props.rows)})
    }

    listOnScroll(index, visibleCount){
        const { listDataLength, selected, cols } = this.props;
        let multiple = selected % 2 || cols;
        if(listDataLength * multiple < ONeilViewStore.getState().basic.count && index > listDataLength - visibleCount && parseInt(this.props.listId) === parseInt(this.props.selectedListId)){
            this.props.fetchMoreDataOnScroll(listDataLength * multiple);
            return true;
        }

        return false;
    }

    render() {
        const { listData, listDataLength, shouldUpdateComponent, rows, nupCharts, selected, showChart } = this.props;
        const lyOutClass = "view-console-holder nup-view-console " + nupCharts[selected].lyUse;
        const lyoutClassHeader = "nup-header " + nupCharts[selected].lyUse;
        const selectedPeriodicities = nupCharts[selected].per;
        const showColumnPeriodicity = selectedPeriodicities.length > 0

        if(showChart){
            return (
                <div ref={(refs)=> this.Nupview = refs} className="view-console view-console-multi-chart">
                    {showColumnPeriodicity && <div className={lyoutClassHeader}>
                    <div className="nup-row">
                        {selectedPeriodicities.length > 0 &&
                        <div className="nup-column"><PeriodicityDropdown
                                                            periodicityDisplayLabel={this.state.periodicities} 
                                                            periodicity={this.state.periodicities[periodicityHelper.getPeriodicityString(selectedPeriodicities[0])]}
                                                            onClick={(periodicity, event) => this.onColumnPeriodicityChange(periodicity, event, 0)}
                                                            title = {this.state.periodicities[periodicityHelper.getPeriodicityString(selectedPeriodicities[0])]}
                                                            />
                        </div>}
                        {selectedPeriodicities.length > 1 &&
                        <div className="nup-column"><PeriodicityDropdown
                                                            periodicityDisplayLabel={this.state.periodicities} 
                                                            periodicity={this.state.periodicities[periodicityHelper.getPeriodicityString(selectedPeriodicities[1])]} 
                                                            onClick={(periodicity, event) => this.onColumnPeriodicityChange(periodicity, event, 1)}
                                                            title = {this.state.periodicities[periodicityHelper.getPeriodicityString(selectedPeriodicities[1])]}
                                                            />
                        </div>}
                        {selectedPeriodicities.length > 2 &&
                        <div className="nup-column"><PeriodicityDropdown
                                                            periodicityDisplayLabel={this.state.periodicities} 
                                                            periodicity={this.state.periodicities[periodicityHelper.getPeriodicityString(selectedPeriodicities[2])]} 
                                                            onClick={(periodicity, event) => this.onColumnPeriodicityChange(periodicity, event, 2)}
                                                            title = {this.state.periodicities[periodicityHelper.getPeriodicityString(selectedPeriodicities[2])]}
                                                            />
                        </div>}
                        {selectedPeriodicities.length > 3 &&
                        <div className="nup-column"><PeriodicityDropdown 
                                                            periodicityDisplayLabel={this.state.periodicities} 
                                                            periodicity={this.state.periodicities[periodicityHelper.getPeriodicityString(selectedPeriodicities[3])]} 
                                                            onClick={(periodicity, event) => this.onColumnPeriodicityChange(periodicity, event, 3)}
                                                            title = {this.state.periodicities[periodicityHelper.getPeriodicityString(selectedPeriodicities[3])]}
                                                            />
                        </div>}
                    </div>
                    </div>}
                    <Measure
                        bounds
                        onResize={(contentRect) => {
                        this.setDimensions(contentRect.bounds)
                        }}
                    >
                    {({ measureRef }) =>
                        <div className={lyOutClass} ref={measureRef}>
                            {(listDataLength) > 0 &&
                                <ErrorBoundary>
                                    <VirtualList id='NupViewScrollbar' ref={(ref) => { this.virtualList = ref; }} items={listData}
                                    itemHeight={Math.ceil(this.state.height / this.props.rows)} itemRenderer={this.showGraphRow}
                                    onMouseUp = {this.onMouseUp}
                                    miniListOnScroll = { this.listOnScroll}
                                    shouldUpdate=  {shouldUpdateComponent}
                                    rowsCount  = {rows}
                                    />
                                </ErrorBoundary>
                            }
                        </div>
                    }
                    </Measure>
                </div>
            );
        }
        else{
            return (<div />);
        }
        
    }
}

const mapStateToProps = ({ listViewReducer }) => {
    const { NupViewReducer } = listViewReducer;
    return ({
                listData: NupViewReducer.listData, 
                listId: NupViewReducer.listId, 
                showChart: NupViewReducer.showChart,
                shouldUpdateComponent: NupViewReducer.shouldUpdateComponent,
                rows: NupViewReducer.rows,
                cols: NupViewReducer.cols,
                redraw: NupViewReducer.redraw,
                selectedIndex: NupViewReducer.selectedIndex,
                nupCharts: NupViewReducer.nupCharts,
                selected: NupViewReducer.selected,
                globalSelectedIndex: NupViewReducer.globalSelectedIndex,
                isScrollAvailable: NupViewReducer.isScrollAvailable,
                listDataLength: NupViewReducer.listData.length,
                nodeCount: NupViewReducer.nodeCount,
                nodeWidth: NupViewReducer.nodeWidth})

}

const mapDispactchToProps = (dispatch) =>({
    updateNupColumnPeriodicityChange: (periodicityObj) => dispatch(updateNupColumnPeriodicityChange(periodicityObj)),
    processNupListData: (symbolList) => dispatch(processNupListData(symbolList)),
    initNupChart: (firstVisibleIndex, lastVisibleIndex) => dispatch(initNupChart(firstVisibleIndex, lastVisibleIndex)),
    updateChartSelection: (selectedIndex, keyType)=> dispatch(updateChartSelection(selectedIndex, keyType)),
    handleSymbolChange: (item)=> dispatch(handleSymbolChange(item))
})

export default connect(mapStateToProps, mapDispactchToProps)(NupView);