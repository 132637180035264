import { put, call, takeLatest, all, fork, select, cancel } from 'redux-saga/effects';
import { getMainState, getSubCategory, getLimitData, getOrderSeqArr, getOsid, getSymbol, getReportExtData, getMsid } from '../../Reducers/DgHoldings/DgHoldingsSelectors';
import DgHoldingsApi from '../../ServiceApi/Apis/DgHoldingsApi';
import ListApi from '../../ServiceApi/Apis/ListApi.js';
import { DgHoldingsActionConstants } from "../../Constants/DgHoldingsActionConstants";
import DgHoldingsConstants from "../../Constants/DgHoldingsConstants.js";
import {  find, each} from "underscore";
import StringUtil from "Utils/StringUtil.js";
import moment from "moment";
import DataGraphStore from '../../Stores/NavModules/NavDataGraph/DataGraphStore';
import MiniListHelper from "MiniListHelper";
import MiniListStore from '../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore';
import TabType from '../../Constants/TabType';
const { ActionTypes } = DgHoldingsActionConstants;

//let getMoreDataTask = null;
let getReportDataTask = null;

/********Watchers***********/
export function* watchDgHoldingsHandleReportExtdata() {
    yield takeLatest(ActionTypes.GET_DG_HOLDINGS_REPORT_DATA, handleReportExtdata);
}

export function* watchDgHoldingsHandleChangeTextSize() {
    yield takeLatest(ActionTypes.DG_HOLDINGS_REPORT_CHANGE_TEXT, handleChangeTextSize);
}

export function* watchDgHoldingsTabSelectionChanged() {
    yield takeLatest(ActionTypes.DG_HOLDINGS_TAB_CHANGE, handleTabSelectionChanged);
}

export function* watchDgHoldingsSubTabSelectionChanged() {
    yield takeLatest(ActionTypes.DG_HOLDINGS_SUB_TAB_CHANGE, handleSubTabSelectionChanged);
}

export function* watchDgHoldingsColumnSortSeqChanged() {
    yield takeLatest(ActionTypes.DG_HOLDINGS_COLUMN_SORT_CHANGED, handleColumnSortSeqChanged);
}

export function* watchDgHoldingsLimitChanged() {
    yield takeLatest(ActionTypes.DG_HOLDINGS_LIMIT_CHANGE, handleLimitChanged);
}

export function* watchDgHoldingsUpdateflag() {
    yield takeLatest(ActionTypes.DG_HOLDINGS_UPDATE_FLAG, handleUpdateflag);
}

export function* watchDgHoldingsResizeTab() {
    yield takeLatest(ActionTypes.DG_HOLDINGS_TAB_RESIZE, handleDgHoldingsResizeTab);
}


export function* watchClearHoldingData() {
    yield takeLatest(ActionTypes.DG_HOLDINGS_CLEAR_DATA, handleClearHoldingData);
}

/**************Workers***********/

function* handleChangeTextSize({ textSize }) {
    yield put({
        type: ActionTypes.DG_HOLDINGS_REPORT_CHANGE_TEXT_SUCCESS,
        result: textSize
    });
}

function* handleDgHoldingsResizeTab() {
    yield put({
        type: ActionTypes.DG_HOLDINGS_TAB_RESIZE_SUCCESS
    });
}

function* handleUpdateflag({ msid, isFlagged }) {
    try {
        yield call(ListApi.updateGlobalFlag, msid, "", isFlagged, 0, 1); //Call to Update Flag in DB
        const reportData = yield select(getReportExtData); //get present grid data
        let reportDataClone = JSON.parse(JSON.stringify(reportData)); // deep clone the grid data
        let obj;
        for(let i =0; i<4; i++){
            obj= find(reportDataClone.data[i].reportList, (itm) => itm.msid.low == msid);        
            if(obj)
                obj.isFlagged = !isFlagged; // find the object in the grid data and update the related details
        }   

        yield put({
            type: ActionTypes.DG_HOLDINGS_UPDATE_FLAG_SUCCESS,
            result:  { resultData: reportDataClone }
        });
    }
    catch (error) {
        console.log(`Error occurs in  handleUpdateflag - DgHoldingsDaga.js ${error}`);
    }
}
function* handleReportExtdata({ osid, symbol, msid }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const _limitData = yield select(getLimitData);
        const orderSeqArr = yield select(getOrderSeqArr);
        if (symbol === null) {
            symbol = yield select(getSymbol);
        }
        yield call(getReportData, osid, symbol, _limitData, ActionTypes.DG_HOLDINGS_GET_REPORT_DATA_SUCCESS, _state, _subCategory, orderSeqArr, msid);
    }
    catch (error) {
        console.log(`Error occurs in  handleReportExtdata - DgHoldingsDaga.js ${error}`);
    }
}

function* handleTabSelectionChanged({ tabIndex }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        let orderSeqArr = yield select(getOrderSeqArr);
        const _limitData = yield select(getLimitData);
        const osid = yield select(getOsid);
        const symbol = yield select(getSymbol);
        const msid = yield select(getMsid);
        if (tabIndex === 1) {
            _state.orderString = "PctOfPort desc"
            _state.orderSeqArr = [{ columnName: "PctOfPort", order: "desc" }]
            orderSeqArr = [{ columnName: "PctOfPort", order: "desc" }]
            _state.currentSubTab=0
        }
        switch (tabIndex) {
            case 1:
                _state.currentTab = 0;
                _subCategory.ByTopHoldingsActive = "active";
                _subCategory.ByPositionTypeActive = "";
                break;
            case 2:
                _state.currentTab = 1;
                break;
            case 3:
                _state.currentTab = 2;
                break;
            case 4:
                _state.currentTab = 3;
                break;
            case 5:
                _state.currentTab = 4;
                break;
            case 6:
                _state.currentTab = 5;
                break;
            case 7:
                _state.currentTab = 6;
                break;
            case 8:
                _state.currentTab = 7;
                break;
            case 9:
                _state.currentTab = 8;
                break;
            case 10:
                _state.currentTab = 9;
                break;
            case 11:
                _state.currentTab = 10;
                break;
            case 12:
                _state.currentTab = 11;
                break;
            default:
                _state.currentTab = 0;
                _subCategory.ByTopHoldingsActive = "active";
                _subCategory.ByPositionTypeActive = "";
                break;
        }

        if (_state.currentSubTab === null || _state.currentSubTab === undefined)
            _state.currentSubTab = 0;

        yield call(getReportData, osid, symbol, _limitData, ActionTypes.DG_HOLDINGS_TAB_CHANGE_SUCCESS, _state, _subCategory, orderSeqArr, msid);
    }
    catch (error) {
        console.log(`Error occurs in  handleTabSelectionChanged - DgHoldingsDaga.js ${error}`);
    }
}

function* handleSubTabSelectionChanged({ subTabIndex }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const _limitData = yield select(getLimitData);
        let orderSeqArr = yield select(getOrderSeqArr);
        const osid = yield select(getOsid);
        const symbol = yield select(getSymbol);
        const msid = yield select(getMsid);
        if (subTabIndex === 1) {
            _state.orderString = "SharesHeld desc"
            _state.orderSeqArr = [{ columnName: "SharesHeld", order: "desc" }]
            orderSeqArr = [{ columnName: "SharesHeld", order: "desc" }]
        }
        else {
            _state.orderString = "PctOfPort desc"
            _state.orderSeqArr = [{ columnName: "PctOfPort", order: "desc" }]
            orderSeqArr = [{ columnName: "PctOfPort", order: "desc" }]
        }
        if (_state.currentTab === null || _state.currentTab === undefined)
            _state.currentTab = 0;

        _state.currentSubTab = subTabIndex;

        if (_state.currentTab === 0 && subTabIndex === 0) {
            _state.isTopOwner = 1;
            _state.isByOwner = 0;
            _state.pageSize = 200;
            _subCategory.ByTopHoldingsActive = "active";
            _subCategory.ByPositionTypeActive = "";
        }
        else {
            _state.isTopOwner = 0;
            _state.isByOwner = 0;
            _subCategory.ByTopHoldingsActive = "";
            _subCategory.ByPositionTypeActive = "active";
        }

        yield call(getReportData, osid, symbol, _limitData, ActionTypes.DG_HOLDINGS_SUB_TAB_CHANGE_SUCCESS, _state, _subCategory, orderSeqArr, msid);
    
    }
    catch (error) {
        console.log(`Error occurs in  handleSubTabSelectionChanged - DgHoldingsDaga.js ${error}`);
    }

}

function* handleColumnSortSeqChanged({ orderString, orderSeqArr }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const _limitData = yield select(getLimitData);
        const osid = yield select(getOsid);
        const symbol = yield select(getSymbol);
        const msid = yield select(getMsid);
        _state.orderString = orderString;

        yield call(getReportData, osid, symbol, _limitData, ActionTypes.DG_HOLDINGS_COLUMN_SORT_CHANGED_SUCCESS, _state, _subCategory, orderSeqArr, msid);
    }
    catch (error) {
        console.log(`Error occurs in  handleColumnSortSeqChanged - DgHoldingsDaga.js ${error}`);
    }
}

function* handleLimitChanged({ limitData }) {
    try {
        const _state = yield select(getMainState);
        const _subCategory = yield select(getSubCategory);
        const orderSeqArr = yield select(getOrderSeqArr);
        const osid = yield select(getOsid);
        const symbol = yield select(getSymbol);
        const msid = yield select(getMsid);

        yield call(getReportData, osid, symbol, limitData, ActionTypes.DG_HOLDINGS_LIMIT_CHANGE_SUCCESS, _state, _subCategory, orderSeqArr, msid);
    }
    catch (error) {
        console.log(`Error occurs in  handleLimitChanged - DgHoldingsDaga.js ${error}`);
    }
}

function* getMoreData(limitData, _state, resultData, msid, resultHeader) {
    try {
        if(resultData.holdingReportCountModel){
            let count = StringUtil.convertFromLongValueToInt(resultData.holdingReportCountModel.column1Count) + StringUtil.convertFromLongValueToInt(resultData.holdingReportCountModel.column2Count) + StringUtil.convertFromLongValueToInt(resultData.holdingReportCountModel.column3Count) + StringUtil.convertFromLongValueToInt(resultData.holdingReportCountModel.column4Count);
            const shouldLoadMore= StringUtil.convertFromLongValueToInt(resultData.holdingReportCountModel.column1Count)>100 || StringUtil.convertFromLongValueToInt(resultData.holdingReportCountModel.column2Count)>100 || StringUtil.convertFromLongValueToInt(resultData.holdingReportCountModel.column3Count)>100 || StringUtil.convertFromLongValueToInt(resultData.holdingReportCountModel.column4Count)>100
            if(_state.currentTab === 0 && _state.currentSubTab === 0)
            {
                count = 100;
            }
            if (shouldLoadMore === true) {
                resultData = yield call(DgHoldingsApi.getReportExtdata, msid, _state.orderString, count, _state.currentTab, _state.currentSubTab, limitData);
            }
            let msidsList = [];
            for (let res of resultData.holdingsReportSet) {          
                    msidsList.push(StringUtil.convertFromLongValueToInt(res.msid));
            }

            let dataSetCopy = JSON.parse(JSON.stringify(resultData));
            yield fork(getFlagDetails, msidsList, dataSetCopy, _state, limitData, resultHeader, true);
        }
    }
    catch (error) {
        console.log(`Error occurs in  getMoreData - DgHoldingsDaga.js ${error}`);
    }
}

function* getReportData(osid, symbol, limitData, action, _state, _subCategory, orderSeqArr, msid) {
    try {
        if (getReportDataTask !== null) {
            yield cancel(getReportDataTask);
            getReportDataTask = null;

        }
        getReportDataTask = yield fork(getReportDataCall, osid, symbol, limitData, action, _state, _subCategory, orderSeqArr, msid);
    }
    catch (error) {
        console.log(`Error occurs in  getReportData - DgHoldingsDaga.js ${error}`);
    }
}

function* getFlagDetails(msidList, resultData, _state, limitData, resultHeader, firstLoad){
    try {
        let msidResponse = yield call(DgHoldingsApi.getMsidFlagRequest, "", msidList);

        if (!(msidResponse == null || msidResponse.flagData == null || (msidResponse.flagData && msidResponse.flagData.length == 0))){
            for (let item of msidResponse.flagData) {
                if (!item) continue;
                let obj;
                obj= find(resultData.holdingsReportSet, (resultItem) => StringUtil.convertFromLongValueToInt(resultItem.msid) == StringUtil.convertFromLongValueToInt(item.msid));        
                if(obj)
                    obj.isFlagged = item.isflag;           
            }   
        }

        let result;
        if (resultData.holdingsReportSet.length > 0) {
            result = yield call(setHoldingsReportData, resultData, _state.currentTab, _state.currentSubTab, resultHeader, firstLoad);
        }
        else {
            result = { data: [] };
        }    

        yield put({
            type: ActionTypes.DG_HOLDINGS_LOAD_FLAG_INFO_SUCCESS,
            result: { resultData: result, limitData: limitData }
        });
    }
    catch (error) {
        console.log(`Error occurs in  getFlagDetails - DgHoldingsDaga.js ${error}`);
    }
}

function* getReportDataCall(osid, sym, limitData, action, _state, _subCategory, orderSeqArr, msid) {
    let dataSet;
    try {
        let limitDataCopy = limitData;
        let resultCount = 400;
        if (limitData === undefined || limitData === null) {
            limitData = yield select(getLimitData);
            resultCount = 400;
        }
        if(_state.currentTab === 0 && _state.currentSubTab === 0)
        {
            resultCount = 100;
            limitDataCopy = true;
        }
        
        let resultHeader, resultData;

        [resultHeader, resultData] = yield all([
            call(DgHoldingsApi.getHeaderData, msid, sym, _state.currentTab),
            call(DgHoldingsApi.getReportExtdata, msid, _state.orderString, resultCount, _state.currentTab, _state.currentSubTab, true)//limitData set to true to get top 100 rows for initial fetch
        ]);

        const state = DataGraphStore.getState();
        const symbol = state.SymbolInfo ? state.SymbolInfo.Symbol : '';
        const isMiniListPlay= state.IsMiniListPlay;
        const isActiveSymbolValid = MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay);

        if(state.SelectedTabKey === TabType.Holdings && isMiniListPlay && isActiveSymbolValid) {
            MiniListStore.clearMiniListPlayTimeout();
        }
        
        if (resultData.holdingsReportSet.length > 0 && isActiveSymbolValid) {
            dataSet = yield call(setHoldingsReportData, resultData, _state.currentTab, _state.currentSubTab , resultHeader, true);
            yield call(UpdateAction, action, dataSet, resultHeader, limitData, _state, _subCategory, orderSeqArr, osid, symbol, limitDataCopy, msid);
        }
        else {
            dataSet = { data: [] };
            yield call(UpdateAction, action, dataSet, resultHeader, limitData, _state, _subCategory, orderSeqArr, osid, symbol, limitDataCopy, msid);
        }

        if(state.SelectedTabKey === TabType.Holdings && isMiniListPlay && isActiveSymbolValid) {
            MiniListStore.setMiniListNextInterval();
        }

        if (limitDataCopy === false) { 
            yield fork(getMoreData, limitData, _state, resultData, msid, resultHeader);
        }
        else{
            let msidsList = [];
            for (let res of resultData.holdingsReportSet) {          
                    msidsList.push(StringUtil.convertFromLongValueToInt(res.msid));
            }

            let dataSetCopy = JSON.parse(JSON.stringify(resultData));
            yield fork(getFlagDetails, msidsList, dataSetCopy, _state, limitData, resultHeader );
        }
    }
    catch (error) {
        dataSet = { data: [] };
        yield call(UpdateAction, action, dataSet, {}, limitData, _state, _subCategory, orderSeqArr, osid, symbol, limitData, msid);
        console.log(`Error occurs in  getReportDataCall - DgHoldingsDaga.js ${error}`);
        
    }
}

function getHoldingsDataSetData(reportdata, type) {
    const DataList = [];
    const SetData = {};
    // if (reportdata.length > 0) {
    //     columnSplitCount = columnSplitCount / 4;
    // }
    try {
        switch (type) {

            case "O1":
                for (let i = 0; i < 25 && i < reportdata.length; i++) {
                    DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
            case "O2":
                for (let i = 25; i < 50 && i < reportdata.length; i++) {
                    DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
            case "O3":
                for (let i = 50; i < 75 && i < reportdata.length; i++) {
                    DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
            case "O4":
                for (let i = 75; i < 100 && i < reportdata.length; i++) {
                    DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
            default:
                //const positionType;

                for (let i = 0; i < reportdata.length; i++) {
                    if (reportdata[i].position === type)
                        DataList.push(reportdata[i]);
                }
                SetData.reportList = DataList;
                break;
        }
        SetData.Key = type;    
    }
    catch (error) {
        console.log(`Error occurs in  getHoldingsDataSetData - DgHoldingsDaga.js ${error}`);
    }
    return SetData;
}

function* setHoldingsReportData(reportinfo, currentTab, currentSubTab, resultHeader, firstLoad) {

    let type = [];
    let set = {};
    let reportName = [];
    const counts = [reportinfo.holdingReportCountModel.column1Count === null ? 0 : StringUtil.convertFromLongValueToInt(reportinfo.holdingReportCountModel.column1Count),
    reportinfo.holdingReportCountModel.column2Count === null ? 0 : StringUtil.convertFromLongValueToInt(reportinfo.holdingReportCountModel.column2Count),
    reportinfo.holdingReportCountModel.column3Count === null ? 0 : StringUtil.convertFromLongValueToInt(reportinfo.holdingReportCountModel.column3Count),
    reportinfo.holdingReportCountModel.column4Count === null ? 0 : StringUtil.convertFromLongValueToInt(reportinfo.holdingReportCountModel.column4Count)];

    let reportdata = reportinfo.holdingsReportSet;    
    const setData = { data: [] };
    try {
        if(firstLoad){
            let resultHeaderdata = resultHeader.holdingReportHeader;
            //let q1EndDate = moment.utc(StringUtil.convertFromLongValueToInt(resultHeaderdata.quarterEndDate2)).format("MMM, YYYY");
    
            //let q1EndDate = new Date(resultHeaderdata.quarterEndDate1);
            let q2EndDate = new Date(resultHeaderdata.quarterEndDate2);
            let q3EndDate = new Date();
            if(resultHeaderdata.quarterEndDate3 == null || resultHeaderdata.quarterEndDate3 == undefined){
                q3EndDate = new Date(resultHeaderdata.quarterEndDate2);
                q3EndDate = moment(new Date(q3EndDate.setMonth(q2EndDate.getMonth() - 3))).endOf('quarter');
            }
            else{
                q3EndDate = new Date(resultHeaderdata.quarterEndDate3);
            }
    
            each(reportdata, (item) => {
                if(!item.carryForward){
                    let qEndDate = new Date(item.quarterEndDate);
                    if(qEndDate <= q3EndDate) {
                        item.name = `** ${item.name}`;
                        item.nameAbbr = `** ${item.nameAbbr}`;
                    } else if (qEndDate <= q2EndDate) {
                        item.name = `* ${item.name}`;
                        item.nameAbbr = `* ${item.nameAbbr}`;
                    }
                    item.carryForward = true;
                    item.LatestQuarterEndDate = resultHeaderdata.quarterEndDate1;
                }
            });
        }
    
        if (currentTab === 0 && currentSubTab === 0) {
            type = ["O1", "O2", "O3", "O4"];
            for (let i = 0; i < 4; i++) {
                const strFromTo = i == 0 ? " : 1 - 25" : i==1 ? " : 26 - 50" : i == 2 ? " : 51 - 75" : " : 76 - 100";
                set = yield call(getHoldingsDataSetData, reportdata, type[i]);
                set.reportName = "Top Holdings"+strFromTo;
                set.key = type[i];
                set.count = counts[i];
                setData.data.push(set);
            }
        }
    
        else {
    
            type = [DgHoldingsConstants.New, DgHoldingsConstants.Inc, DgHoldingsConstants.Unchg, DgHoldingsConstants.Dec];
            reportName = ["New: ", "Added: ", "Unchanged: ", "Reduced: "];
            for (let i = 0; i < 4; i++) {
                set = yield call(getHoldingsDataSetData, reportdata, type[i]);
                set.reportName = reportName[i];
                set.key = type[i];
                set.count = counts[i];//set.reportList.length//;
                setData.data.push(set);
            }
        }
    } catch (error) {
        console.log(`Error occurs in  setHoldingsReportData - DgHoldingsDaga.js ${error}`);
    }
    
    return setData;

}

function* UpdateAction(action, resultData, resultHeader, limitData, _state, _subCategory, orderSeqArr, osid, symbol, limitDataCopy, msid) {

    getReportDataTask = null;
    
    yield put({
        type: action,
        result: {
            mainState: _state,
            subCategory: _subCategory,
            resultData: resultData,
            headerData: resultHeader,
            limitData: limitData,
            orderSeqArr: orderSeqArr,
            osid: osid,
            symbol: symbol,
            limitDataCopy: limitDataCopy,
            msid: msid
        }
    });
}

function* handleClearHoldingData() {
    yield put({
        type: ActionTypes.DG_HOLDINGS_CLEAR_DATA
    });
}


