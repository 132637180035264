import React from "react";
import KeyTypes from "../../Constants/KeyType";
import GridActions from "GridActions";
import FormatterUtil from "FormatterUtil";
import light from "!less-vars-loader!../../Asset/css/lessvars/colors-light.less";
import dark from "!less-vars-loader!../../Asset/css/lessvars/colors-dark.less";
import ListStore from "ListStore";
import ONeilViewStore from "ONeilViewStore";
import ListManagerTabType from "ListManagerTabType";
import OwnershipViewStore from "OwnershipViewStore";
import ThemeType from "ThemeType";
import BrowserUtil from "BrowserUtil";
import WPFCanvasUtil from "../../Utils/WPFCanvasUtil";
import StringUtil from "StringUtil";
import ThemeHelper from "ThemeHelper";
import GridStore from "../../Stores/NavModules/NavList/Grid/GridStore";
import ListExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import BaseServiceApi from 'BaseServiceApi';
const ListColumnDataType = BaseServiceApi.rayData["ListColumnDataType"];
const cellWidth = require("text-width");
let left = 0.0;
//let canvas = null;
//let ctx = null;
//let staticColsWidth = 0;
let positiveDataText = null;
let negativeDataText = null;
let positiveDataTextHover = null;
let negativeDataTextHover = null;
let gridRowOddEvenColor = null;
let gridRowOddEvenColorHover = null;
//let cellLimit = 3;
export default class RowItem extends React.Component {

    constructor(props) {
        super(props);
        this.onMouseClick = this.onMouseClick.bind(this);
        this.setSelectedState = this.setSelectedState.bind(this);
        this.getCells = this.getCells.bind(this);
        this.getIsHighLighted = this.getIsHighLighted.bind(this);
        this.setHighLightState = this.setHighLightState.bind(this);
        this.removeHighLight = this.removeHighLight.bind(this);
        this.highLightRow = this.highLightRow.bind(this);
        this.onFlagColumnClick = this.onFlagColumnClick.bind(this);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.setCellTitle = this.setCellTitle.bind(this);
        this.clearCellTitle = this.props.tooltip.hideTooltip.bind(this);
        this.dragStart = this.dragStart.bind(this);
        this.getStaticColumnContent = this.getStaticColumnContent.bind(this);
        this.getWrappedContent = this.getWrappedContent.bind(this);
        this.getCanvasCells = this.getCanvasCells.bind(this);
        this.onResizeWindow = this.onResizeWindow.bind(this);
        this.isAlertTriggered = this.isAlertTriggered.bind(this);
        this.getSymbolTooltipDisplayValue = this.getSymbolTooltipDisplayValue.bind(this);
        this.showTooltip = this.props.tooltip.showTooltip.bind(this);
        
        this.onMouseDoubleClickOnRow = this.onMouseDoubleClickOnRow.bind(this);
        this.showCanvasToolTip = this.showCanvasToolTip.bind(this);
        this.state = {
            isHighLighted: false,
            isSelected: props.isSelected
        };

        this.canvas = null;
        this.ctx = null;
        this.staticColsWidth = 0;
        this.cellLimit = (this.props.selectedListId == -1 || this.props.selectedListId == -2) ? 2 : 3;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResizeWindow);
        this.listExplorerElm = document.getElementById('listExplorer');
        this.horizontalScrollElm = document.getElementById('gridHorizontalScroll');
        // this.toolTip = document.getElementById('grid-row-tooltip');
        if (!this.props.gridTypeTable) {
            let id = "myCanvas_F_" + this.props.visibleIndex;
            if (this.props.columns.length > 0 && this.props.columns[0].isFrozen) id = "myCanvas_N_" + this.props.visibleIndex;
            this.canvas = this.refs[id];
            this.getCanvasCells();
        }
    }

    onResizeWindow () {
        if (!this.props.gridTypeTable) {
            let id = "myCanvas_F_" + this.props.visibleIndex;
            if (this.props.columns.length > 0 && this.props.columns[0].isFrozen) id = "myCanvas_N_" + this.props.visibleIndex;
            this.canvas = this.refs[id];
            this.getCanvasCells();
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.isSelected != undefined && props.isSelected !== this.state.isSelected) {
            this.setSelectedState(props.isSelected);
        }
    }

    componentDidUpdate() {
        if (!this.props.gridTypeTable) {
            let id = "myCanvas_F_" + this.props.visibleIndex;
            if (this.props.columns.length > 0 && this.props.columns[0].isFrozen) id = "myCanvas_N_" + this.props.visibleIndex;
            this.canvas = this.refs[id];
            this.getCanvasCells();
        }
    }

    highLightRow(e) {
        if (!BrowserUtil.isDisableEventsOn()) {
            if (!this.state.isSelected) GridActions.getHighlightedRow(this.props.visibleIndex, true);
            e.preventDefault();
        }
    }

    removeHighLight(e) {
        if (!BrowserUtil.isDisableEventsOn()) {
            if (!this.state.isSelected) GridActions.getHighlightedRow(this.props.visibleIndex, false);
            e.preventDefault();
        }
    }

    setHighLightState(isHightlighted) {
        this.setState({
            isHighLighted: isHightlighted
        });
    }

    getIsHighLighted() {
        return this.state.isHighLighted;
    }

    plotSymbol(e, symbol) {
        this.props.onPlotSymbol(symbol);
    }

    onMouseDoubleClickOnRow(e) {
        if (ListExplorerStore.isNotImportedList()) return;
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        let columnIndexOfSymbol;
        switch (tabType) {
            case ListManagerTabType.Oneil:
                columnIndexOfSymbol = ONeilViewStore.findColumnIndex('-5');
                break;
            case ListManagerTabType.Ownership:
                columnIndexOfSymbol = OwnershipViewStore.findColumnIndex('-4000012');//-5
                break;
        }
        if (columnIndexOfSymbol) {
            let symbol = this.props.data.recordDatas[columnIndexOfSymbol].displayValue;
            if (!StringUtil.isEmpty(symbol))
                this.props.onPlotSymbol(symbol);
        }
    }

    getCells() {
        if (this.props.data == undefined) return "Loading";
        let cells = [];
        //let cellIndex = 0;
        let self = this;
        if (this.props.selectedListId == -1 || this.props.selectedListId == -2) this.cellLimit = 2;
        else this.cellLimit = 3;
        for (let cellIndex = 0; cellIndex < this.props.columns.length; cellIndex++) {
            let cell = this.setCellContent(cellIndex, self.props);
            cells.push(cell);
        }
        return cells;
    }

    setCellContent(cellIndex, props) {
        try {
            let colitem = props.columns[cellIndex];
            if (colitem) {
                let cellData = props.data.recordDatas[colitem.id];
                let className = '';
                let displayValue = '';
                let textAlign = 'center';
                let colWidth = this.props.getColumnWidth(colitem);
                let paddingLeft = 10;

                let hoverPad = 2;
                if ((BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape")) {
                    hoverPad += 1;
                }
                let textWidth = Math.ceil(colitem.textWidth) + paddingLeft + hoverPad;

                if (cellData) {
                    textAlign = cellData.alignment;
                    // if (cellData.stringValue) textAlign = 'left';
                    // else if (cellData.doubleValue) textAlign = 'right';

                    if (cellData.dataItemId == -3) paddingLeft = 0;  // Flag
                    else if (cellData.dataItemId == -4) { /*textAlign = 'right'; */paddingLeft = 5; } // #
                    //else if (cellData.dataItemId == -7) textAlign = 'center';   // instrumentType

                    if (cellData.className) className = cellData.className;
                    displayValue = cellData.displayValue;
                }

                if (colWidth < textWidth) {
                    textWidth = colWidth;
                }
                let cellStyle = {
                    width: colWidth + "px",
                    minWidth: colitem.minWidth + "px",
                }
                let cellStyleNew = {
                    width: textWidth + "px",
                    minWidth: colitem.minWidth + "px",
                    textAlign: textAlign,
                    paddingLeft: paddingLeft + "px",
                }

                if (cellData && cellData.dataItemId == -3) // Flag
                    return (<td key={props.rowIndex + cellIndex} className='grid-cell' style={cellStyle} onClick={(e) => (this.onFlagColumnClick(e, cellData, props.rowIndex))}>
                        <span className="customFont" style={cellStyleNew}>
                            <span className={className}></span>
                            {displayValue}
                        </span>
                    </td>)
                else if (cellData && (cellData.dataItemId == -5 || cellData.dataItemId == -4000012)) // Symbol
                    return (<td key={props.rowIndex + cellIndex} className={'grid-cell ' + className} style={cellStyle}>
                        <span className='customFont' style={cellStyleNew}
                            onMouseMove={(e) => (this.setCellTitle(e, displayValue + (cellData.snapshotDt ? ' ' + cellData.snapshotDt : '')))}
                            onMouseOut={this.clearCellTitle} onBlur={this.clearCellTitle} > <span onClick={(e) => this.plotSymbol(e, displayValue)}>{displayValue} {cellData.snapshotDt ? <span className='symbol-snapshot-date xxx-small-normal'>{cellData.snapshotDt}</span> : null}</span></span>
                    </td>)
                else if (cellData && cellData.isVisualizationOn && parseFloat(displayValue) > 0) {
                    let separatorLeft = ((colWidth - 8) * parseFloat(displayValue) / 100).toFixed(2) + 'px';
                    return (<td key={props.rowIndex + cellIndex} className='grid-cell' style={cellStyle}>
                        <div className={'grid-visualize-rect ' + className}>
                            <span className='grid-visualize-sep' style={{ left: separatorLeft }}>&nbsp;</span>
                        </div>
                    </td>)
                }
                else
                    return (<td key={props.rowIndex + cellIndex} className={(className != 'grid-visualize-rect-positive' && className != 'grid-visualize-rect-negative') ? 'grid-cell ' + className : 'grid-cell'} style={cellStyle}>
                        <span className='customFont' style={cellStyleNew}
                            onMouseMove={(e) => (this.setCellTitle(e, displayValue))}
                            onMouseOut={this.clearCellTitle}
                            onBlur={this.clearCellTitle}>
                            {displayValue}
                        </span>
                    </td>);
            }
        }
        catch (e) {
            console.log('error', e);
        }
    }

    showCanvasToolTip(e) {
        if (!BrowserUtil.isDisableEventsOn()) {
            let scrollX = this.horizontalScrollElm.scrollLeft;
            let mouseMovePos;
            let rectListExplorerInfo = this.listExplorerElm.getBoundingClientRect();
            if (e.clientX <= this.props.frozenAreaWidth + this.listExplorerElm.offsetWidth) {
                mouseMovePos = (e.clientX - rectListExplorerInfo.width - rectListExplorerInfo.left);
            }
            else {
                mouseMovePos = (e.clientX + scrollX) - (rectListExplorerInfo.width + rectListExplorerInfo.left);
            }

            let colPos = this.props.getMouseOverColumnIndex(mouseMovePos);

            if (colPos !== null) {
                let column = this.props.allColumns[colPos];

                if (column) {
                    let colWidth = this.props.getColumnWidth(column);
                    let recordDatas = this.props.data.recordDatas;

                    if (recordDatas) {
                        let cell = recordDatas[colPos];

                        if (cell) {
                            let cellContent = cell.displayValue;
                            let paddingLeft = 10;
                            if (cellContent) {
                                let ellipses = false;
                                if (this.ctx && cell.alignment == 'left') {
                                    this.ctx.textAlign = "start";
                                    ellipses = this.isEllipses(this.ctx, cell.displayValue, colWidth - paddingLeft);
                                }
                                else if (this.ctx && cell.alignment == 'right') {
                                    this.ctx.textAlign = "end";
                                    ellipses = this.isEllipses(this.ctx, cell.displayValue, colWidth - (paddingLeft / 2));
                                }
                                else if (this.ctx && cell.alignment == 'center') {
                                    this.ctx.textAlign = "center";
                                    ellipses = this.isEllipses(this.ctx, cell.displayValue, colWidth);
                                }
                                if (ellipses) {
                                    this.showTooltip(e, cellContent);
                                }
                                else this.clearCellTitle(e);
                            }
                            else {
                                this.clearCellTitle(e);
                            }
                        }
                    }
                }
            }
        }
    }

    getCanvasCells() {
        if (this.props.data == undefined) return "Loading";
        let cells = [];
        //let cellIndex = 0;
        let self = this;

        left = 0.0;
        if (this.props.selectedListId == -1 || this.props.selectedListId == -2) this.cellLimit = 2;
        else this.cellLimit = 3;

        if (this.canvas) {
            let selectedTheme = ListStore.getSelectedTheme();

            this.ctx = this.canvas.getContext("2d");
            //this.ctx.clear();
            this.ctx.clear(false, true, this.props.textSize);
            //this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);


            // To solve hardware acceleration issue of chrome
            /*if(BrowserUtil.getBrowserName() == 'Chrome') {
              this.ctx.rect(0, 1, this.canvas.width, this.canvas.height);
    
              if (selectedTheme == ThemeType.Dark) {
                if (this.props.className == 'grid-row-odd') this.ctx.fillStyle = dark.gridRowOddBg;
                else this.ctx.fillStyle = dark.gridRowEvenBg;
    
                if(this.state.isSelected) this.ctx.fillStyle = dark.gridRowSelected;
              }
              else if (selectedTheme == ThemeType.Light) {
                if (this.props.className == 'grid-row-odd') this.ctx.fillStyle = light.gridRowOddBg;
                else this.ctx.fillStyle = light.gridRowEvenBg;
    
                if(this.state.isSelected) this.ctx.fillStyle = light.gridRowSelected;
              }
              this.ctx.fill();
            }*/

            if (this.props.columns.length > 0 && this.props.columns[0].isFrozen) WPFCanvasUtil.setScale(this.canvas, this.ctx, this.props.width - this.staticColsWidth, this.props.rowHeight);
            else WPFCanvasUtil.setScale(this.canvas, this.ctx, this.props.width, this.props.rowHeight);
            let caliFont = this.props.textSize + "px calibri";
            if(caliFont !== this.ctx.font){
            this.ctx.font = caliFont;
            }
            this.ctx.textBaseline = 'middle';
            this.ctx.imageSmoothingEnabled = true;

            positiveDataText = ThemeHelper.getThemedBrush('positiveDataText');
            negativeDataText = ThemeHelper.getThemedBrush('negativeDataText');
            positiveDataTextHover = ThemeHelper.getThemedBrush('positiveDataTextHover');
            negativeDataTextHover = ThemeHelper.getThemedBrush('negativeDataTextHover');

            if (selectedTheme == ThemeType.Dark) {
                // positiveDataText = dark.positiveDataText;
                // negativeDataText = dark.negativeDataText;
                // positiveDataTextHover = dark.positiveDataTextHover;
                // negativeDataTextHover = dark.negativeDataTextHover;
                gridRowOddEvenColor = dark.gridRowOddEvenColor;
                gridRowOddEvenColorHover = dark.fff000;
            }
            else if (selectedTheme == ThemeType.Light) {
                // positiveDataText = light.positiveDataText;
                // negativeDataText = light.negativeDataText;
                // positiveDataTextHover = light.positiveDataTextHover;
                // negativeDataTextHover = light.negativeDataTextHover;
                gridRowOddEvenColor = light.gridRowOddEvenColor;
                gridRowOddEvenColorHover = light.fff000;
            }
        }

        for (let cellIndex = 0; cellIndex < this.props.columns.length; cellIndex++) {
            let cell = this.setCanvasCellContent(cellIndex, self.props);
            cells.push(cell);
        }
        return cells;
    }

    setCanvasCellContent(cellIndex, props) {
        try {
            let colitem = props.columns[cellIndex];         
            if (colitem) {
                let cellData = props.data.recordDatas[colitem.id];
                let colWidth = this.props.getColumnWidth(colitem);
                let paddingLeft = 10;
                let textWidth = Math.ceil(colitem.textWidth);
                let marginLeft = 5;
                if (this.props.columns.length > 0 && this.props.columns[0].isFrozen && cellIndex < this.cellLimit) {
                    return;
                }

                if (colWidth < textWidth + paddingLeft) {
                    textWidth = colWidth;
                }

                let textPosY = this.getBottomBaseLinePos();

                if (this.canvas && cellData) {
                    let className = cellData.className;
                    let displayValue = cellData.displayValue;
                    let w = parseInt((colWidth - textWidth) / 2);

                    if (className && !this.state.isHighLighted) {
                        if (className == 'grid-cell-positive') this.ctx.fillStyle = positiveDataText;
                        else if (className == 'grid-cell-negative') this.ctx.fillStyle = negativeDataText;
                    }
                    else if (!className && !this.state.isHighLighted) this.ctx.fillStyle = gridRowOddEvenColor;
                    //hover
                    else if (className && this.state.isHighLighted) {
                        if (className == 'grid-cell-positive') this.ctx.fillStyle = positiveDataTextHover;
                        else if (className == 'grid-cell-negative') this.ctx.fillStyle = negativeDataTextHover;
                    }
                    else if (!className && this.state.isHighLighted) this.ctx.fillStyle = gridRowOddEvenColorHover;
                    if (this.ctx && cellData.isVisualizationOn) {
                        if (displayValue !== "") {
                            let displayVal = displayValue && FormatterUtil.unformatNumber(displayValue);
                            let textPosX = left + 4;
                            displayVal = displayVal > 100 ? 100.00 : displayVal < 0 ? 0.00 : displayVal;
                            let separatorLeft = (textPosX + ((colWidth - 8) * displayVal / 100)).toFixed(2);

                            if (className == 'grid-visualize-rect-positive') this.ctx.fillStyle = positiveDataText;
                            else if (className == 'grid-visualize-rect-negative') this.ctx.fillStyle = negativeDataText;

                            this.ctx.fillRect(textPosX, textPosY - 5, (colWidth - 8), 8);

                            this.ctx.beginPath();
                            this.ctx.lineWidth = "2";
                            this.ctx.strokeStyle = gridRowOddEvenColorHover;
                            this.ctx.moveTo(separatorLeft, textPosY - 8);
                            this.ctx.lineTo(separatorLeft, textPosY + 6);
                            this.ctx.stroke();
                        }
                        else {
                            this.ctx.textAlign = "start";
                            let text = this.getWrappedContent(this.ctx, "", colWidth - paddingLeft);
                            if (w > 0) {
                                this.ctx.fillText(text, left + w + paddingLeft - marginLeft, textPosY);
                            }
                            else {
                                this.ctx.fillText(text, left + w + paddingLeft, textPosY);
                            }
                        }
                        left += colWidth;
                    }
                    else if (this.ctx && cellData.alignment == 'left') {
                        this.ctx.textAlign = "start";
                        let text = "";
                        let maxColWidth = colWidth - paddingLeft;
                        text = this.getDisplayText(this.ctx, cellData, displayValue, maxColWidth);
                        if (w > 0) {
                            this.ctx.fillText(text, left + w + paddingLeft - marginLeft, textPosY);
                        }
                        else {
                            this.ctx.fillText(text, left + w + paddingLeft, textPosY);
                        }
                        left += colWidth;
                    }
                    else if (this.ctx && cellData.alignment == 'right') {
                        this.ctx.textAlign = "end";
                        left += colWidth;
                        let text = this.getDisplayText(this.ctx, cellData, displayValue, (colWidth - (paddingLeft / 2)));
                        if (w > 0) {
                            this.ctx.fillText(text, left - w + marginLeft, textPosY);
                        }
                        else {
                            this.ctx.fillText(text, left - w, textPosY);
                        }
                    }
                    else if (this.ctx && cellData.alignment == 'center') {
                        this.ctx.textAlign = "center";
                        left += colWidth;
                        let text = this.getWrappedContent(this.ctx, displayValue, colWidth, textPosY);
                        let leftPos = Math.ceil(left - (colWidth - w - textWidth / 2));
                        if (left > 0 && leftPos >= (left - colWidth)) {
                            this.ctx.fillText(text, leftPos, textPosY);
                        }
                        else {
                            this.ctx.fillText(text, (left - (colWidth / 2)), textPosY);
                        }
                    }
                    else if (this.ctx && cellData.alignment == 'center') {
                        this.ctx.textAlign = "center";
                        left += colWidth;
                        let text = ' ';
                        this.ctx.fillText(text, (left - (colWidth / 2)), textPosY);
                    }
                }
            }
        } catch (e) {
            console.log('error', e)
        }
        return;
    }

    isEllipses(c, str, maxWidth) {
        let width = c.measureText(str).width;
        let ellipsis = "...";
        let ellipsisWidth = c.measureText(ellipsis).width;
        if (width <= maxWidth || width <= ellipsisWidth) {
            return false;
        } else {
            let len = str.length;
            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
                str = str.substring(0, len);
                width = c.measureText(str).width;
            }
            return true;
        }
    }

    getWrappedContent(c, str, maxWidth) {
        let width = c.measureText(str).width;
        let ellipsis = "...";
        let ellipsisWidth = c.measureText(ellipsis).width;
        if (width <= maxWidth || width <= ellipsisWidth) {
            return str;
        } else {
            let len = str.length;
            while (width >= maxWidth - ellipsisWidth && len-- > 0) {
                str = str.substring(0, len);
                width = c.measureText(str).width;
            }

            if (str[str.length - 1] == ".") {
                let ellipsisDot = ellipsis.substring(0, 2);
                return str + ellipsisDot;
            }
            else {
                return str + ellipsis;
            }
        }
    }

    getDisplayText(ctx, cellData, displayValue, maxColWidth){
        let text = "";
        if(cellData.dataType === ListColumnDataType.STRING_ListColumnData){
            const width = ctx.measureText(displayValue).width;
            const ellipsis = "...";
            const ellipsisWidth = ctx.measureText(ellipsis).width;
            if (width <= maxColWidth || width <= ellipsisWidth){
                text = displayValue;
                cellData.displayText = text;
                cellData.displayTextMaxWidth = maxColWidth;
            }
            else if(cellData.displayText && cellData.displayTextOriginalValue === displayValue && cellData.displayTextMaxWidth === maxColWidth){
                text = cellData.displayText;
            }
            else{
                text = this.getWrappedContent(ctx, displayValue, maxColWidth);
                cellData.displayText = text;
                cellData.displayTextOriginalValue = displayValue;
                cellData.displayTextMaxWidth = maxColWidth;
            }
        }
        else{
            text = this.getWrappedContent(ctx, displayValue, maxColWidth);
        }
        return text
    }

    getStaticColumnContent(cellIndex) {
        try {
            let colitem = this.props.columns[cellIndex];
            if (colitem) {
                let cellData = this.props.data.recordDatas[colitem.id];
                let className = '';
                let displayValue = '';
                let textAlign = 'center';
                let colWidth = this.props.getColumnWidth(colitem);
                let paddingLeft = 10;
                let hoverPad = 2;
                if ((BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape")) {
                    hoverPad += 1;
                }
                let textWidth = Math.ceil(colitem.textWidth) + paddingLeft + hoverPad;

                if (cellData) {
                    textAlign = cellData.alignment;
                    // if (cellData.stringValue) textAlign = 'left';
                    // else if (cellData.doubleValue) textAlign = 'right';

                    if (cellData.dataItemId == -3) paddingLeft = 0;  // Flag
                    else if (cellData.dataItemId == -4) { /* textAlign = 'right'; */ paddingLeft = 5; } // #
                    // else if (cellData.dataItemId == -7) textAlign = 'center';   // instrumentType

                    if (cellData.className) className = cellData.className;
                    displayValue = cellData.displayValue;
                }

                if (colWidth < textWidth) {
                    textWidth = colWidth;
                }
                let cellStyle = {
                    width: colWidth + "px",
                    minWidth: colitem.minWidth + "px",
                    background: cellData.style ? cellData.style.background : "",
                    color: cellData.style ? "#FFF" : ''
                }
                let cellStyleNew = {
                    width: textWidth + "px",
                    minWidth: colitem.minWidth + "px",
                    textAlign: textAlign,
                    paddingLeft: paddingLeft + "px",
                }
                this.staticColsWidth += colWidth;

                //Bug fix PANWEB-1805 display a mouse pointer instead of a hand on # column.
                if (cellIndex === 0 && (BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape")) {
                    cellStyleNew = { ...cellStyleNew, cursor: "default" };
                }

                if (cellData && cellData.dataItemId == -3) // Flag
                    return (<td key={this.props.rowIndex + cellIndex} className='grid-cell' style={cellStyle} onClick={(e) => (this.onFlagColumnClick(e, cellData, this.props.rowIndex))}>
                        <span className="customFont" style={cellStyleNew}>
                            <span className={className}></span>
                            {displayValue}
                        </span>
                    </td>)
                else if (cellData && (cellData.dataItemId == -5 || cellData.dataItemId == -4000012)) // Symbol
                    return (<td key={this.props.rowIndex + cellIndex} className={'grid-cell ' + className} style={cellStyle}>
                        <span className='customFont' style={cellStyleNew}
                            onMouseMove={(e) => { this.setCellTitle(e, this.getSymbolTooltipDisplayValue(cellData, displayValue), this.isAlertTriggered()) }}
                            onMouseOut={this.clearCellTitle} onBlur={this.clearCellTitle}><span onClick={(e) => this.plotSymbol(e, displayValue)}>{displayValue} {cellData.snapshotDt ? <span className='symbol-snapshot-date xxx-small-normal'>{cellData.snapshotDt}</span> : null}</span></span>
                    </td>)
                else
                    return (<td key={this.props.rowIndex + cellIndex} className={'grid-cell ' + className} style={cellStyle}>
                        <span className='customFont' style={cellStyleNew}
                            onMouseMove={(e) => (this.setCellTitle(e, displayValue))}
                            onMouseOut={this.clearCellTitle}
                            onBlur={this.clearCellTitle}>
                            {displayValue}
                        </span>
                    </td>)
            }
        }
        catch (e) {
            console.log('error', e);
        }
    }

    isAlertTriggered () {
      return this.props.data.isTriggeredInstrument && !StringUtil.isEmpty(this.props.data.ListAlertMessage);
    }

    getSymbolTooltipDisplayValue (cellData, displayValue) {
        let str = '';

        if (this.isAlertTriggered()) {
            str = this.props.data.ListAlertMessage;
        }
        else {
            str = displayValue + (cellData.snapshotDt ? ' ' + cellData.snapshotDt : '')
        }

        return str;
    }

    setCellTitle(e, displayValue, showAlways = false) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        if (target && !showAlways && (target.offsetWidth < target.scrollWidth) && !target.hasAttribute('title')) {
            this.showTooltip(e, displayValue, showAlways);
        }
        else if (showAlways) {
            this.showTooltip(e, displayValue, showAlways);
        }
    }

    // showTooltip (e, displayValue, showAlways = false) {
    //     const left = e.clientX;
    //     const top = e.clientY + 20;
    //     //const txtCount = displayValue ? displayValue.length : 0;
    //     const width = cellWidth(displayValue, {
    //         family: 'calibri',
    //         size: this.props.textSize
    //     });

    //     let leftPos = left

    //     if (!showAlways) {
    //         let wFactor = width / 3;
    //         let rightPosLeft = screen.width - left;
    //         let bottomPosLeft = screen.height - top;
    //         if (rightPosLeft > width) wFactor = 0;
    //         else if (bottomPosLeft > 220 && rightPosLeft <= width / 3) wFactor = width / 6;
    //         else if (bottomPosLeft < 220 && rightPosLeft <= width / 3) wFactor = width / 3;
    //         let leftPos = left - wFactor;
    //         if (leftPos < 0) leftPos = 100;
    //     }
        
    //     this.toolTip.style.top = top + 'px';
    //     this.toolTip.style.left = leftPos + 'px';
    //     this.toolTip.style.display = 'block';
    //     this.toolTip.innerHTML = displayValue;

    // }

    // clearCellTitle() {
    //     if (!BrowserUtil.isDisableEventsOn()) {
    //         this.toolTip.innerHTML = '';
    //         this.toolTip.style.display = 'none';
    //         this.toolTip.style.top = '0px';
    //         this.toolTip.style.left = '0px';
    //     }
    // }

    setSelectedState(isSelected) {
        // if (this.state.isSelected !== isSelected) {
        if (ListExplorerStore.isNotImportedList()) return;
        this.setState({
            isSelected: isSelected,
            isHighLighted: false
        });
        //}
    }

    onFlagColumnClick(e, cellData, rowIndex) {
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        let selectedItems = GridStore.getSelectedIndexes();
        let sourceData = tabType === ListManagerTabType.Ownership ? OwnershipViewStore.getSourceData() : ONeilViewStore.getSourceData();
        let msids = ''; 
        let i = 0;
        const length = sourceData.length < selectedItems.length ? sourceData.length : selectedItems.length;
        while (i < length) {
            msids = `${msids}${msids.length == 0 ? '' : ';'}${tabType === ListManagerTabType.Ownership ?sourceData[selectedItems[i]]['sponsorId']:sourceData[selectedItems[i]]['msId']}`;
            i++;
          };
        const msid = tabType === ListManagerTabType.Ownership ? this.props.data.sponsorId : this.props.data.msId;
        if(!selectedItems.includes(rowIndex)) {
            msids = msid;
            selectedItems = [rowIndex];
        }
        this.props.updateGlobalFlag(msids, cellData.displayValue, selectedItems);
        e.stopPropagation();
        e.preventDefault();
    }

    onMouseClick(e) {
        if (e.shiftKey)
            GridActions.handleSingleModeSelection(this.props.rowIndex, this.props.visibleIndex, KeyTypes.shiftKey);
        else if (e.ctrlKey || e.metaKey)
            GridActions.handleSingleModeSelection(this.props.rowIndex, this.props.visibleIndex, KeyTypes.ctrlKey);
        else
            GridActions.handleSingleModeSelection(this.props.rowIndex, this.props.visibleIndex, KeyTypes.None);

        e.stopPropagation();
        e.preventDefault();
    }

    onContextMenuClick(e) {
        if (!this.state.isSelected) this.onMouseClick(e);
        this.props.onGridRowContextMenuClick(e, this.props.rowIndex, this.props.visibleIndex, this.state.isSelected, this.props.instrumentType);
        e.stopPropagation();
        e.preventDefault();
    }

    dragStart(e) {
        this.clearCellTitle(e);
        if (!this.state.isSelected) GridActions.handleSingleModeSelection(this.props.rowIndex, this.props.visibleIndex, KeyTypes.None);
        this.props.dragStart(e, this.props.rowIndex, this.props.visibleIndex, this.state.isSelected);
    }

    getBottomBaseLinePos() {
        if (BrowserUtil.getBrowserName() == "Firefox") {
            return Math.ceil(this.props.rowHeight / 2) + 1;
        }
        else if ((BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape") && this.props.textSize == 16) {
            return Math.ceil(this.props.rowHeight / 2) + 1;
        }
        else {
            return Math.ceil(this.props.rowHeight / 2);
        }
    }

    render() {
        let self = this;
        //let background = '';
        let className = this.props.className;

        if (this.state.isSelected) {
            className += " grid-row-selected";
        }
        else if (this.state.isHighLighted) {
            className += " grid-row-hover";
        }
        //staticColsWidth = 0;
        let style = { width: this.props.width }
        if (this.props.gridTypeTable) {
            let cells = this.getCells();
            return (
                <tr className={'grid-row ' + className} style={style}
                    data-id={this.props.dataId}
                    draggable={true}
                    onDrop={this.props.dragDrop}
                    onDragEnd={this.props.dragEnd}
                    onDragStart={this.dragStart}
                    onDragEnter={this.props.isEditable ? this.props.dragEnter : null}
                    onDragLeave={this.props.isEditable ? this.props.dragLeave : null}
                    onDragOver={this.props.dragOver}
                    onDrag={this.props.dragMove}
                    onClick={this.onMouseClick}
                    onDoubleClick={this.onMouseDoubleClickOnRow}
                    onMouseEnter={this.highLightRow}
                    onMouseLeave={this.removeHighLight}
                    onContextMenu={this.onContextMenuClick}
                >{cells}
                </tr>
            )
        }
        else {
            if (this.props.columns.length > 0 && this.props.columns[0].isFrozen) {
                self.staticColsWidth = 0;
                let id = "myCanvas_N_" + this.props.visibleIndex;
                //let width = this.props.width - this.props.columns[0].width;
                let no = (this.props.selectedListId != -1 && this.props.selectedListId != -2) ? this.getStaticColumnContent(0) : null;
                let flag = (this.props.selectedListId != -1 && this.props.selectedListId != -2) ? this.getStaticColumnContent(1) : this.getStaticColumnContent(0);
                let symbol = (this.props.selectedListId != -1 && this.props.selectedListId != -2) ? this.getStaticColumnContent(2) : this.getStaticColumnContent(1);

                return (
                    <tr className={'grid-row ' + className} style={style}
                        data-id={this.props.dataId}
                        draggable={true}
                        onDrop={this.props.dragDrop}
                        onDragEnd={this.props.dragEnd}
                        onDragStart={this.dragStart}
                        onDragEnter={this.props.isEditable ? this.props.dragEnter : null}
                        onDragLeave={this.props.isEditable ? this.props.dragLeave : null}
                        onDragOver={this.props.dragOver}
                        onDrag={this.props.dragMove}
                        onClick={this.onMouseClick}
                        onDoubleClick={this.onMouseDoubleClickOnRow}
                        onMouseEnter={this.highLightRow}
                        onMouseLeave={this.removeHighLight}
                        onContextMenu={this.onContextMenuClick}
                    >
                        {no}
                        {flag}
                        {symbol}
                        <td><canvas ref={id} data-id={this.props.dataId} onMouseMove={this.showCanvasToolTip} onMouseLeave={this.clearCellTitle} width={this.props.width - this.staticColsWidth} height={this.props.rowHeight}></canvas></td>
                    </tr>
                )
            }
            else {
                let id = "myCanvas_F_" + this.props.visibleIndex;
                return (
                    <tr className={'grid-row ' + className} style={style}
                        data-id={this.props.dataId}
                        draggable={ListExplorerStore.isNotImportedList() ? false : true}
                        onDrop={this.props.dragDrop}
                        onDragEnd={this.props.dragEnd}
                        onDragStart={this.dragStart}
                        onDragEnter={this.props.isEditable ? this.props.dragEnter : null}
                        onDragLeave={this.props.isEditable ? this.props.dragLeave : null}
                        onDragOver={this.props.dragOver}
                        onDrag={this.props.dragMove}
                        onClick={this.onMouseClick}
                        onDoubleClick={this.onMouseDoubleClickOnRow}
                        onMouseEnter={this.highLightRow}
                        onMouseLeave={this.removeHighLight}
                        onContextMenu={this.onContextMenuClick}
                    ><td width={this.props.width}><canvas ref={id} data-id={this.props.dataId} onMouseMove={this.showCanvasToolTip} onMouseLeave={this.clearCellTitle} width={this.props.width} height={this.props.rowHeight}></canvas></td>
                    </tr>
                )
            }
        }
    }
}
