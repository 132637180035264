import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetMetricDefinitionRequest extends BaseServiceRequest {

    constructor() {
        super();
        super.addRequestUri("getMetDef.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.METRICDEFINITION;
    }

    getMessageName() {
        return "MetricDefinitionResponse";
    }
}

export default GetMetricDefinitionRequest;
