import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class ListAlertsUpsertRequest extends BaseServiceRequest {
  constructor(data, colsetId, browserfilterstringIds, sourcetypeId) {
    super();
    super.addRequestUri("listalertsupsert.pb");
    const ReqProto = BaseServiceApi.rayData["AlertData2"];
    const alertData = new ReqProto();

    if (browserfilterstringIds != null) super.addRequestParameter("browserfilterstring", browserfilterstringIds);
    if (colsetId != null) super.addRequestParameter("colsetidstring", colsetId);
    super.addRequestParameter("sourcetypeid", sourcetypeId);

    alertData.sourceId = data.sourceId;
    alertData.alertDataSetting = data.alertDataSetting;
    alertData.listName = data.listName;
    alertData.sourceIds = data.sourceIds;
    alertData.sourceTypeId = data.sourceTypeId;
    alertData.columnSet = data.columnSet;
    alertData.browser = data.browser;
    alertData.nocolumnSet = data.nocolumnSet;

    const reqcontent = alertData.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.PUT;
  }

  getSubSystem() {
    return SubSystemType.ALERTS;
  }

  getMessageName() {
    return "Response";
  }

}
