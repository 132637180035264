import React from "react";
import ExternalRowItem from "./ExternalRowItem";
import { connect } from 'react-redux'
import { map } from "underscore";
class ExternalGridView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            gridAreaWidth: 0.0
        };
        this.getColumnWidth = this.getColumnWidth.bind(this);
    }
    getColumnWidth() {
        let colWidth = 200;
        const minWidth=100;
        const { gridAreaWidth, columns } = this.props;
        if (gridAreaWidth > 0) {
            let width = gridAreaWidth / columns.length;
            colWidth = width > colWidth ? colWidth : width < minWidth? minWidth :width;
        }
        return colWidth;
    }
    getGridCell(dataRow) {
        return (
            <ExternalRowItem
                visibleIndex={dataRow.rowNumber}
                key={"row" + dataRow.rowNumber}
                width={this.props.viewportWidth}
                rowHeight={this.props.rowHeight}
                columns={this.props.columns}
                data={dataRow.rowData}
                dataId={dataRow.actuallRowIndexStart}
                getColumnWidth={this.getColumnWidth}
                gridAreaWidth={this.props.gridAreaWidth}
                selectedListId={this.props.selectedListId}
            />
        )
    }
    render() {
        return(this.props.columns ?
            <div className="edu-row-collection">
                {              
                    this.props.columns && this.props.columns.length > 0 ? map(this.props.visibleRow, (item) => {
                        if (item && item.rowData) {
                            return (
                                this.getGridCell(item)
                            )
                        }
                    }) : null
                }
                <div className="edu-row last">
                    <div className="edu-cell">&nbsp;</div>
                </div>
            </div> : null
        )
    }
}


const mapDispatchToProps = () => ({

})
const mapStateToProps = ({ externalDataUploader }) => {
    const {
        visibleRow, source, viewportWidth, gridAreaWidth
    } = externalDataUploader.ExternalDataListViewReducer;
    const { headers, listId } = source;
    return ({
        columns: headers, selectedListId: listId, visibleRow, viewportWidth, gridAreaWidth
    });
}
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ExternalGridView);