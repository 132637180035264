import ListManager from "../../../../../Constants/ListManager.js";
import Serializable from "Serializable";
import PanarayDefault from "ServiceApi/RequestHelper/PanarayDefault";

class TabOwnershipSettings extends Serializable {
  constructor() {
    super();
    this.ListExplorer = {};
    this.ListExplorer.MinWidth = 100;
    this.ListExplorer.MaxWidth = 500;

    this.MetricLibrary = {};
    this.MetricLibrary.MinHeight = 100;
    this.MetricLibrary.MaxHeight = 300;
  }

  onSeralisedInfoRecieved(serializedValue) {
    if(!this.ListExplorer){
      this.ListExplorer = {};
      this.ListExplorer.MinWidth = 100;
      this.ListExplorer.MaxWidth = 500;
    }
    if(!this.MetricLibrary){
      this.MetricLibrary = {};
      this.MetricLibrary.MinWidth = 100;
      this.MetricLibrary.MaxWidth = 300;
    }
    this.TextSize = serializedValue.TextSize ? serializedValue.TextSize : this.getDefaultTextSize();
    this.ScreenOnOff = serializedValue.ScreenOnOff ? serializedValue.ScreenOnOff : this.getDefaultScreenOnOff();
    this.SelectedListInfo = serializedValue.SelectedListInfo ? serializedValue.SelectedListInfo : this.getDefaultSelectedListInfo();
    this.ListExplorer.Width = serializedValue.ListExplorerWidth ? serializedValue.ListExplorerWidth : this.getDefaultListExplorerWidth();
    this.ListExplorer.PreviousWidth = serializedValue.ListExplorerPreviousWidth >= 0 ? serializedValue.ListExplorerPreviousWidth : this.getDefaultListExplorerWidth();
    this.MetricLibrary.IsOpen = serializedValue.IsMetricLibraryOpen ? serializedValue.IsMetricLibraryOpen : this.getDefaultMetricLibraryOpenState();
    this.MetricLibrary.Width = serializedValue.MetricLibraryWidth ? serializedValue.MetricLibraryWidth : this.getDefaultMetricLibraryWidth();
    this.MetricLibrary.Height = serializedValue.Height ? serializedValue.Height : this.getDefaultMetricLibraryHeight();
    this.SelectedView = serializedValue.SelectedView ? serializedValue.SelectedView : this.getDefaultSelectedView();
  }

  getDataObject() {
    return {
      TextSize: this.TextSize,
      ScreenOnOff: this.ScreenOnOff,
      SelectedListInfo: this.SelectedListInfo,
      ListExplorerWidth: this.ListExplorer.Width,
      ListExplorerPreviousWidth: this.ListExplorer.PreviousWidth,
      SelectedView: this.SelectedView,
      IsMetricLibraryOpen: this.MetricLibrary.IsOpen
    };
  }

  getObjectMapKey() {
    return "TabOwnershipSettings";
  }

  getDefaultTabOwnershipSettings() {
    this.TextSize = this.getDefaultTextSize();
    this.ScreenOnOff = this.getDefaultScreenOnOff();
    this.SelectedListInfo = this.getDefaultSelectedListInfo();
    this.ListExplorer.Width = this.getDefaultListExplorerWidth();
    this.ListExplorer.PreviousWidth = this.getDefaultListExplorerWidth();
    this.MetricLibrary.Height = this.getDefaultMetricLibraryHeight();
    this.MetricLibrary.IsOpen = this.getDefaultMetricLibraryOpenState();
    this.MetricLibrary.Width = this.getDefaultMetricLibraryWidth();
    this.SelectedView = this.getDefaultSelectedView();
    return this;
  }

  getDefaultTextSize() {
    return ListManager.TextSize;
  }

  getDefaultScreenOnOff() {
    return ListManager.ScreenOnOff;
  }

  getDefaultListExplorerWidth() {
    return 300;
  }

  getDefaultMetricLibraryWidth() {
    return 300;
  }

  getDefaultMetricLibraryHeight() {
    return 200;
  }

  getDefaultMetricLibraryOpenState() {
    return false;
  }

  getDefaultKey() {
    return 'List';
  }

  getDefaultSelectedView() {
    const selectedView = new Serializable();
    selectedView.Key = this.getDefaultKey();
    selectedView.List = this.getDefaultListSettings();
    return selectedView;
  }

  getDefaultSelectedListInfo() {
    const selectedListInfo = new Serializable();
    selectedListInfo.SelectedListId = PanarayDefault.DefaultOwnershipListId ? PanarayDefault.DefaultOwnershipListId : 820712;
    selectedListInfo.SelectedActualListId = PanarayDefault.DefaultOwnershipListId ? PanarayDefault.DefaultOwnershipListId : 820712;
    selectedListInfo.SelectedListName = "Leading Growth Funds";
    selectedListInfo.SelectedParentNodeId = this.getOwnerShipDefaultParentNodeId();
    selectedListInfo.SelectedParentCategoryType = "1";
    selectedListInfo.SelectedParentCategoryName = "Panaray Owner Lists";
    selectedListInfo.SelectedCategoryType = "1";
    selectedListInfo.SelectedParentListType = "3";
    selectedListInfo.SelectedSourceCategoryType = 1;
    return selectedListInfo;
  }

  getDefaultListSettings() {
    const getDefaultListSettings = new Serializable();
    getDefaultListSettings.Aggregate = ListManager.Aggregate;
    return getDefaultListSettings;
  }

  getOwnerShipDefaultParentNodeId() {
    if (process.env && process.env.BRANCH_ENV) {
      let env = process.env.BRANCH_ENV;
      switch (env && env.toUpperCase()) {
        case "DEV":
          return 772672;
        case "STAGE":
          return 776455;
        case "PROD":
          return 820709;
        default:
          return 820709;
      }
    }
  }

}

export default TabOwnershipSettings;
