import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
const { ActionTypes } = RiPanelConstants;

const initialState = {
    isLoading: true,
    isVisible: true,
    showLastEventOnly: true,
    periodicity: ""
}

const RiPanelFactorInfo = (state = initialState, action) => {
    switch (action.type) {

        default: return { ...state };
    }
}

export default RiPanelFactorInfo;