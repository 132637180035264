const { ActionTypes } = VolumeChartConstants;
import { VolumeChartConstants } from "../Constants/VolumeChartConstants";

export const prepareInsiderData = (xdata) => ({
    type: ActionTypes.PREPARE_INSIDER_CHART_DATA,
    xdata
})

export const updateDimension = (dimension) => ({
    type: ActionTypes.UPDATE_INSIDER_CHART_DIMENSIONS,
    dimension
})

export const getInsiderTrackPriceChart = (nodeIndex) => ({
    type: ActionTypes.INSIDER_CHART_GET_TRACK_PRICE_NODE_DATA,
    nodeIndex
})

export const onInsiderOpenCollapse = (blockType, isopen) => ({
    type: ActionTypes.INSIDER_CHART_COLLAPSE_BLOCK,
    blockType, isopen
})