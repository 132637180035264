import Serializable from "Serializable";
export default class OneilRatingsChartSettings extends Serializable {
  constructor() {
    super();
  }

  getDefaultRs3Months() {
    return false;
  }

  getDefaultRs6Months() {
    return false;
  }

  getDefaultRs12Months() {
    return false;
  }

  getDefaultGrpRs3Months() {
    return false;
  }

  getDefaultGrpRs6Months() {
    return false;
  }

  getDefaultDataGraph() {
    return false;
  }

  getDefaultInstSupDem() {
    return false;
  }

  getDefaultSMR() {
    return false;
  }

  getDefaultSponsorship() {
    return false;
  }

  getDefaultComposite() {
    return false;
  }

  getDefaultEpsRank() {
    return false;
  }

  getDefaultDataGraphColorObj() {
    const DataGraphObj = [{ isActive: false, color: "P9866FF340066", weight: 1 }];
    return DataGraphObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultRs3MonthsColorObj() {
    const Rs3MonthsObj = [{ isActive: true, color: "PFDA57DAE3E00", weight: 1 }];
    return Rs3MonthsObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultRs6MonthsColorObj() {
    const Rs6MonthsObj = [{ isActive: false, color: "PFC6901FD8548", weight: 1 }];
    return Rs6MonthsObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultRs12MonthsColorObj() {
    const Rs12MonthsObj = [{ isActive: false, color: "PFF0000670000", weight: 1 }];
    return Rs12MonthsObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultGrpRs3MonthsColorObj() {
    const GrpRs3MonthsObj = [{ isActive: false, color: "PCBCB01999900", weight: 1 }];
    return GrpRs3MonthsObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultGrpRs6MonthsColorObj() {
    const GrpRs6MonthsObj = [{ isActive: false, color: "P990000CC0100", weight: 1 }];
    return GrpRs6MonthsObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultInstSupDemColorObj() {
    const InstSupDemObj = [{ isActive: false, color: "P34FF33006600", weight: 1 }];
    return InstSupDemObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultSMRColorObj() {
    const SMRObj = [{ isActive: false, color: "P66CCFF013CB3", weight: 1 }];
    return SMRObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultSponsorshipColorObj() {
    const SponsorshipObj = [{ isActive: false, color: "P08980009CB01", weight: 1 }];
    return SponsorshipObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultCompositeColorObj() {
    const CompositeObj = [{ isActive: false, color: "P6600996734CC", weight: 1 }];
    return CompositeObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultEpsRankColorObj() {
    const EpsRankObj = [{ isActive: false, color: "P0066CC3399FF", weight: 1 }];
    return EpsRankObj.map((item) => this.getSerizableObject(item));
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

  onSeralisedInfoRecieved(serializedValue) {
    this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
    this.Height = serializedValue.Height ? serializedValue.Height : 100;
    this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
    this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
    this.Rs3Months = serializedValue.Rs3Months !== undefined ? serializedValue.Rs3Months : this.getDefaultRs3Months();
    this.Rs6Months = serializedValue.Rs6Months !== undefined ? serializedValue.Rs6Months : this.getDefaultRs6Months();
    this.Rs12Months = serializedValue.Rs12Months !== undefined ? serializedValue.Rs12Months : this.getDefaultRs12Months();
    this.GrpRs3Months = serializedValue.GrpRs3Months !== undefined ? serializedValue.GrpRs3Months : this.getDefaultGrpRs3Months();
    this.GrpRs6Months = serializedValue.GrpRs6Months !== undefined ? serializedValue.GrpRs6Months : this.getDefaultGrpRs6Months();
    this.DataGraph = serializedValue.DataGraph !== undefined ? serializedValue.DataGraph : this.getDefaultDataGraph();
    this.InstSupDem = serializedValue.InstSupDem !== undefined ? serializedValue.InstSupDem : this.getDefaultInstSupDem();
    this.SMR = serializedValue.SMR !== undefined ? serializedValue.SMR : this.getDefaultSMR();
    this.Sponsorship = serializedValue.Sponsorship !== undefined ? serializedValue.Sponsorship : this.getDefaultSponsorship();
    this.Composite = serializedValue.Composite !== undefined ? serializedValue.Composite : this.getDefaultComposite();
    this.EpsRank = serializedValue.EpsRank !== undefined ? serializedValue.EpsRank : this.getDefaultEpsRank();
    this.Rs3MonthsColor = serializedValue.Rs3MonthsColor ? serializedValue.Rs3MonthsColor : this.getDefaultRs3MonthsColorObj();
    this.Rs6MonthsColor = serializedValue.Rs6MonthsColor ? serializedValue.Rs6MonthsColor : this.getDefaultRs6MonthsColorObj();
    this.Rs12MonthsColor = serializedValue.Rs12MonthsColor ? serializedValue.Rs12MonthsColor : this.getDefaultRs12MonthsColorObj();
    this.GrpRs3MonthsColor = serializedValue.GrpRs3MonthsColor ? serializedValue.GrpRs3MonthsColor : this.getDefaultGrpRs3MonthsColorObj();
    this.GrpRs6MonthsColor = serializedValue.GrpRs6MonthsColor ? serializedValue.GrpRs6MonthsColor : this.getDefaultGrpRs6MonthsColorObj();
    this.CompositeColor = serializedValue.CompositeColor ? serializedValue.CompositeColor : this.getDefaultCompositeColorObj();
    this.DataGraphColor = serializedValue.DataGraphColor ? serializedValue.DataGraphColor : this.getDefaultDataGraphColorObj();
    this.EpsRankColor = serializedValue.EpsRankColor ? serializedValue.EpsRankColor : this.getDefaultEpsRankColorObj();
    this.SMRColor = serializedValue.SMRColor ? serializedValue.SMRColor : this.getDefaultSMRColorObj();
    this.SponsorshipColor = serializedValue.SponsorshipColor ? serializedValue.SponsorshipColor : this.getDefaultSponsorshipColorObj();
    this.InstSupDemColor = serializedValue.InstSupDemColor ? serializedValue.InstSupDemColor : this.getDefaultInstSupDemColorObj();
    this.isApplyToAllIntervals = serializedValue.isApplyToAllIntervals ? serializedValue.isApplyToAllIntervals : false
  }

  getDataObject() {
    return {
      IsVisible: this.IsVisible,
      Height: this.Height,
      IsAvailable: this.IsAvailable,
      IsRelative: this.IsRelative,
      Rs3Months: this.Rs3Months,
      Rs6Months: this.Rs6Months,
      Rs12Months: this.Rs12Months,
      GrpRs3Months: this.GrpRs3Months,
      GrpRs6Months: this.GrpRs6Months,
      Composite: this.Composite,
      DataGraph: this.DataGraph,
      EpsRank: this.EpsRank,
      SMR: this.SMR,
      Sponsorship: this.Sponsorship,
      InstSupDem: this.InstSupDem,
      Rs3MonthsColor: this.Rs3MonthsColor,
      Rs6MonthsColor: this.Rs6MonthsColor,
      Rs12MonthsColor: this.Rs12MonthsColor,
      GrpRs3MonthsColor: this.GrpRs3MonthsColor,
      GrpRs6MonthsColor: this.GrpRs6MonthsColor,
      CompositeColor: this.CompositeColor,
      DataGraphColor: this.DataGraphColor,
      EpsRankColor: this.EpsRankColor,
      SMRColor: this.SMRColor,
      SponsorshipColor: this.SponsorshipColor,
      InstSupDemColor: this.InstSupDemColor,
      isApplyToAllIntervals: this.isApplyToAllIntervals
    }
  }

  getDefaultOneilRatingsChartSettings() {
    this.IsVisible = true;
    this.Height = 100;
    this.IsAvailable = true;
    this.IsRelative = false;
    this.Rs3Months = this.getDefaultRs3Months();
    this.Rs6Months = this.getDefaultRs6Months();
    this.Rs12Months = this.getDefaultRs12Months();
    this.GrpRs3Months = this.getDefaultGrpRs3Months();
    this.GrpRs6Months = this.getDefaultGrpRs6Months();
    this.Composite = this.getDefaultComposite();
    this.DataGraph = this.getDefaultDataGraph();
    this.EpsRank = this.getDefaultEpsRank();
    this.SMR = this.getDefaultSMR();
    this.Sponsorship = this.getDefaultSponsorship();
    this.InstSupDem = this.getDefaultInstSupDem();
    this.Rs3MonthsColor = this.getDefaultRs3MonthsColorObj();
    this.Rs6MonthsColor = this.getDefaultRs6MonthsColorObj();
    this.Rs12MonthsColor = this.getDefaultRs12MonthsColorObj();
    this.GrpRs3MonthsColor = this.getDefaultGrpRs3MonthsColorObj();
    this.GrpRs6MonthsColor = this.getDefaultGrpRs6MonthsColorObj();
    this.CompositeColor = this.getDefaultCompositeColorObj();
    this.DataGraphColor = this.getDefaultDataGraphColorObj();
    this.EpsRankColor = this.getDefaultEpsRankColorObj();
    this.SMRColor = this.getDefaultSMRColorObj();
    this.SponsorshipColor = this.getDefaultSponsorshipColorObj();
    this.InstSupDemColor = this.getDefaultInstSupDemColorObj();
    this.isApplyToAllIntervals = false;
    return this;
  }

  getObjectMapKey() {
    return "OneilRatingsChartSettings";
  }
}
