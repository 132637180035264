import React from "react";
import { FormGroup, Form } from "react-bootstrap";
import LocalizationStore from 'Stores/Localization/LocalizationStore.js';
import TabFundamentalsStore from 'Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js';

let filters = [];

export default class FundamentalsFilter extends React.Component {
    constructor() {
        super();
    }

    setClassName(self, index) {
        let currentFilter = this.props.settings.FundamentalFilter;
        if (currentFilter != undefined && filters[index] == currentFilter) { return "active"; }
        else { return ""; }
    }

    getFilterTypeText(type) {
        if (type === 'All')
            return LocalizationStore.getTranslatedData('fr_All', type);
        if (type === 'Dollars')
            return LocalizationStore.getTranslatedData('fr_Dollars', type);
        if (type === 'Common Size')
            return LocalizationStore.getTranslatedData('fr_CommonSize', type);
        if (type === 'Year-Over-Year')
            return LocalizationStore.getTranslatedData('fr_YearOverYear', type);
        return type;
    }

    render() {
        let self = this;
        let filterButtons = undefined;
        let optionsDataHelper = TabFundamentalsStore.getOptionsDataHelper();
        filters = this.props.optionsData[optionsDataHelper.periodicity + optionsDataHelper.report + "Filters"];
        let filterTypes = TabFundamentalsStore.getFundamentalFilterDisplayLabels(filters);
        let handleChangeFunc = this.props.handleFilterChange;

        if (filterTypes.length > 0) {
            filterButtons = filterTypes.map((filterTypes, index) => {
                let handleFilterChange = handleChangeFunc.bind(self, index);
                let setClassNameFunc = self.setClassName(self, index);
                return (<FormGroup key={index + 1}><a key={index + 1} id="filter" className={setClassNameFunc} data-report-filterid={filters[index]}
                    onClick={handleFilterChange}>{this.getFilterTypeText(filterTypes)}</a></FormGroup>);
            });
        }
        return (<Form inline>
            {filterButtons}
        </Form>
        );
    }
}
