import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
// import BaseServiceApi from 'BaseServiceApi';

export default class GetExtCategories extends BaseServiceRequest {
  constructor(listId, headers) {
    super();
    super.addRequestUri("extcategories.pb");
    super.addPathParameter(listId.toString());
    if (headers) {
      super.addRequestParameter("headers", headers);
    //  const ReqProto = BaseServiceApi.rayData["CategoryDataModel"];
    //   const extcategoriesRequestData = new ReqProto();
    //     extcategoriesRequestData.category = headers.category;
    //     extcategoriesRequestData.label = headers.label;
    //   super.setContent(extcategoriesRequestData.encode());
      this.methodType = HttpMethodType.POST;
    }
    else {
      this.methodType = HttpMethodType.GET;
    }
  }

  getMethod() {
    return this.methodType;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ExtCategories";
  }

}
