import { createAction } from 'redux-actions';

import ListExplorerStore from "ListExplorerStore";
import { GetRSSSources, GetNewsDetails, GetFilterListValidation } from '../ServiceApi/Apis/NewsApi';
import { newsActions } from '../Constants/NewsConstants';
import { newsDataWorker } from "WebWorker";
import StringUtil from "StringUtil";
import SettingsStore from "SettingsStore";
import AdobeUtil from "../Utils/AdobeUtil";
import AdobeConstants from "../Constants/AdobeConstants";
import NewsSettingHelper from '../Stores/ConsoleWindow/Settings/Modules/News/NewsSettingsHelper'
import LocalizationStore from 'LocalizationStore';
import ListApi from "ListApi";
import { filter, find, clone } from "underscore";

export const SET_SCROLL_FLAG = 'SET_SCROLL_FLAG';
export const updateScrollFlag = createAction(SET_SCROLL_FLAG);

export const SET_STREAM = 'SET_STREAM';
export const saveStreamList = createAction(SET_STREAM);

export const SET_DIV_HEIGHT = 'SET_DIV_HEIGHT';
export const updateDivHeight = createAction(SET_DIV_HEIGHT);

export const updateStreamList = (streamerList) => (dispatch) => {
    updateNewsSettings(streamerList);
    dispatch(saveStreamList(streamerList));
}

export const updateNewsSettings = (streamerList) => {
    const viewSetting = SettingsStore.getConsoleSettings();

    NewsSettingHelper.updateNewsStream(viewSetting.NavNewsSettings, streamerList);
    SettingsStore.saveSettings();
}

export const NEWS_START = 'NEWS_START';
export const updateNewsStart = createAction(NEWS_START);

export const SET_SOURCE = 'SET_SOURCE';
export const setSource = createAction(SET_SOURCE);

export const SET_DELETE_NEWS = 'SET_DELETE_NEWS';
export const setDeleteNews = createAction(SET_DELETE_NEWS);

export const SET_SELECTED_STREAM = 'SET_SELECTED_STREAM';
export const updateSelectedStream = createAction(SET_SELECTED_STREAM);

export const SET_DRAG_STREAM = 'SET_DRAG_STREAM';
export const updateDragStream = createAction(SET_DRAG_STREAM);

export const IS_BACKGROUND_JOB_START = 'IS_BACKGROUND_JOB_START';
export const setBackGroundJobStatus = createAction(IS_BACKGROUND_JOB_START);

export const GET_NEW_STREAMER = 'GET_NEW_STREAMER';
export const getNewStreamer = createAction(GET_NEW_STREAMER);

export const GET_FILTER_LIST = 'GET_FILTER_LIST';
export const getFilterList = createAction(GET_FILTER_LIST);

export const setSelectedStream = (selectedStream) => (dispatch, getState) => {    
    const state = getState();
    const backGroundJobStatus = state.news.backGroundJobStatus;

    dispatch(updateSelectedStream(selectedStream));
    if (!backGroundJobStatus && !selectedStream)
        dispatch(setJob(true, newsActions.UPDATE));
}

export const setDragStream = (dragStream) => (dispatch, getState) => {
    const state = getState();
    dispatch(updateDragStream(dragStream));
}

export const SET_NAME_CHANGED = 'SET_NAME_CHANGED';
export const setNameChanged = createAction(SET_NAME_CHANGED);

export const SET_ROUTE = 'SET_ROUTE';
export const setRoute = createAction(SET_ROUTE);
let newsDataWorkerObj;
export const setJob = (isStartJob, action) => (dispatch, getState) => {
    //populate news every 10 seconds - start
    setTimeout(() => {        
        dispatch(setBackGroundJobStatus(false));
        const state = getState();
        const streamerList = state.news.streamerList;
        const newsState = state.news.newsState;
        const inNewsRoute = state.news.route === 'news';
        const getNewStreamer = state.news.getNewStreamer;

        if (inNewsRoute && isStartJob && action !== newsActions.REFRESH && !state.news.filtersymbolData.showSymbol && !state.news.sourceData.showFilter && !state.news.sourceData.showSource && !state.news.sourceData.showStreamName && !getNewStreamer) {
            dispatch(setBackGroundJobStatus(true));
            dispatch(fetchNewsListbyIntervel(streamerList, newsState, -1, isStartJob));
        }
        if (!inNewsRoute && newsDataWorkerObj) {
            newsDataWorkerObj.terminate();
            newsDataWorkerObj = undefined;
        }
        if(getNewStreamer) {
            dispatch(setJob(isStartJob, action));
        }
    }, 5000)
    //populate news every 10 seconds - end
}

export const fetchNewsListbyIntervel = (streamerList, newsState, selectedLayoutOrder, isStartJob) => (dispatch) => {
    if (typeof (newsDataWorkerObj) === "undefined") {
        newsDataWorkerObj = new newsDataWorker();
    }
    newsDataWorkerObj.postMessage(
        {
            streamerList: streamerList,
            newsState: newsState,
            selectedLayoutOrder: selectedLayoutOrder
        }
    );
    newsDataWorkerObj.onmessage = function (e) {
        dispatch(
            populateNewsToStream(
                streamerList,
                newsState,
                selectedLayoutOrder,
                e.data,
                isStartJob,
                newsActions.UPDATE
            )
        );
    }
}

export const populateNewsToStream = (streamerList, newsState, selectedLayoutOrder, response, isStartJob, action) => (dispatch, getState) => {
    const state = getState();
    const statestreamerList = state.news.streamerList;
    const getNewStreamer = state.news.getNewStreamer;

    if(getNewStreamer && selectedLayoutOrder < 0) {
        dispatch(setJob(isStartJob, action));
        return;
    }
    if (statestreamerList.length !== streamerList.length) {
        streamerList = statestreamerList;
    }
    if (streamerList) 
        if (response.newsresponselist && response.newsresponselist.length > 0) {
            if (selectedLayoutOrder >= 0) {

                const index = selectedLayoutOrder;
                const streamer = response.newsresponselist[0].newslist;

                //removing duplicate news - start
                const newsListWithoutDuplicate = streamer.filter((stream, index, self) =>
                    index === self.findIndex((t) => (
                        t.title === stream.title
                    ))
                )
                //removing duplicate news - end

                //sorting news - start
                const compareTimeString = (a, b) => b.newstime - a.newstime
                const sortedNewsList = newsListWithoutDuplicate && newsListWithoutDuplicate.sort(compareTimeString)
                //sorting news - end

                //removing news if more than 100 - start
                const finalNewsList = sortedNewsList.splice(0, 100);
                //removing news if more than 100 - end

                streamerList[index].NewsList = finalNewsList;
                if(!getNewStreamer || (getNewStreamer && selectedLayoutOrder >= 0)) {
                    streamerList[index].MessageVisibility = false;
                }
            }
            else {
                streamerList.map((rowData) => {

                    const streamer = filter(response.newsresponselist, (item) => item.id == rowData.StreamID);

                    if (streamer.length > 0) {
                        let finalNewsList = undefined;

                        if (streamer.Refresh) {
                            streamer.MessageVisibility = true;

                            dispatch(fetchNewsList(
                                streamerList,
                                newsState,
                                streamer.LayoutOrder,
                                newsActions.REFRESH)
                            );
                        }
                        else if (streamer[0].newslist && streamer[0].newslist.length > 0) {
                            const mergedNewsList = streamer[0].newslist.concat(rowData.NewsList);
                            //removing duplicate news - start
                            const newsListWithoutDuplicate = mergedNewsList.filter((stream, index, self) =>
                                index === self.findIndex((t) => (
                                    t.title === stream.title
                                ))
                            )
                            //removing duplicate news - end
                            //sorting news - start
                            const compareTimeString = (a, b) => b.newstime - a.newstime
                            const sortedNewsList = newsListWithoutDuplicate && newsListWithoutDuplicate.sort(compareTimeString)
                            //sorting news - end
                            finalNewsList = sortedNewsList.splice(0, 100);

                            rowData.NewsList = finalNewsList;

                            if(!getNewStreamer || (getNewStreamer && selectedLayoutOrder >= 0)) {
                                rowData.MessageVisibility = false;
                            }
                        }
                        else {
                            if(!getNewStreamer || (getNewStreamer && selectedLayoutOrder >= 0)) {
                                rowData.MessageVisibility = false;
                            }
                        }
                    }
                    else {
                        if(!getNewStreamer || (getNewStreamer && selectedLayoutOrder >= 0)) {
                            rowData.MessageVisibility = false;
                        }
                    }
                });

                dispatch(updateNewsStart(StringUtil.convertFromLongValueToInt(response.getNewsTime)));
            }
        }
        else {
            if (selectedLayoutOrder >= 0) {
                streamerList[selectedLayoutOrder].NewsList = [];
                streamerList[selectedLayoutOrder].MessageVisibility = false;
            }
            else {
                _.each(streamerList, (streamer) => { streamer.MessageVisibility = false });
                dispatch(updateNewsStart(StringUtil.convertFromLongValueToInt(response.getNewsTime)));
            }
        }

        let newStreamerList = [];
        state.news.streamerList.map((item, index) => {
          const stream = streamerList.filter((i) => i.StreamID == item.StreamID)[0];
          if (stream != undefined && stream != null)
            newStreamerList.push({ ...stream, LayoutOrder: index, StreamName: item.StreamName });
        });

            dispatch(updateStreamList(newStreamerList));  

            dispatch(setJob(isStartJob, action));
}

export const FETCH_NEWS_LIST = 'FETCH_NEWS_LIST';
export const fetchNewsList = (streamerList, newsState, selectedLayoutOrder, action) => (dispatch) => {

    if (selectedLayoutOrder >= 0) {
        //Update Source String - start
        const selectedStreamer = streamerList[selectedLayoutOrder];
        const selectedSources = filter(selectedStreamer.Sources, (rowSource) => rowSource.isSelect);
        let count = 0;
        for(let i = 1; i<selectedSources.length; i++){
            if(selectedSources[i].parents == selectedSources[i-1].parents){
                count++;
            }
        }
        const selectedSourceCount = selectedSources.length - count;
        const isAllSourceSelect = selectedStreamer.Sources.length === selectedSourceCount;
        const sourceString = selectedSourceCount > 0 ?
            selectedSourceCount == 1 ?
            `${LocalizationStore.getTranslatedData("news_" + selectedSources[0].parents.replace(/ /ig,""),selectedSources[0].parents)}` :
            `${LocalizationStore.getTranslatedData("news_" + selectedSources[0].parents.replace(/ /ig,""),selectedSources[0].parents)} +${selectedSourceCount - 1} ${LocalizationStore.getTranslatedData("alert_Geography_more","more")}` :
            `${LocalizationStore.getTranslatedData("sb_none","None")}`;

        selectedStreamer.IsSourcesAllSelect = isAllSourceSelect;
        selectedStreamer.SelectedSourcesStr = sourceString;
        //Update Source String - end
        if(action === newsActions.UPDATE) {
            dispatch(getNewStreamer(true));
        }
    }

    const isStartJob = selectedLayoutOrder === 0 || action === newsActions.CREATE;
    dispatch({
        type: FETCH_NEWS_LIST,
        payload: GetNewsDetails(streamerList, newsState, selectedLayoutOrder).then((response) => {
            dispatch(populateNewsToStream(streamerList, newsState, selectedLayoutOrder, response, isStartJob, action));
            dispatch(getNewStreamer(false));
        })
    });
}

export const FETCH_SOURCES = 'FETCH_SOURCES';
export const fetchNews = (streamerList, newsState, action, symbol = '') => (dispatch, getState) => {

    if (action === newsActions.ADD || streamerList.length === 0) {
        const viewSetting = SettingsStore.getConsoleSettings();
        const savedStreamerList = viewSetting.NavNewsSettings.newsStream;

        const savedstreamerListExisit = savedStreamerList && savedStreamerList.length > 0 && action === newsActions.CREATE;

        if (!savedstreamerListExisit) {
            AdobeUtil.DTMTrack({

            },
              AdobeConstants.NEWS_ADD_FEED);
            //Create empty stream - start
            const state = getState();
            if (!streamerList) {
                streamerList = state.news.streamerList;
                newsState = state.news.newsState;
            }

            const index = streamerList.length;
            const newStream = {
                ConsoleId: 0,
                DisplayIcons: false,
                IsAddNewStreamBtnVisible: false,
                IsAndOperatorChecked: false,
                IsEmptyStream: false,
                IsOrOperatorChecked: false,
                IsSourcesAllSelect: true,
                Keyword: "",
                LayoutOrder: index,
                MessageText: LocalizationStore.getTranslatedData("ri_loading", "Loading"),
                MessageVisibility: true,
                NewsList: [],
                SelectedFilterKeywords: "",
                SelectedFilterStr: symbol ? symbol : LocalizationStore.getTranslatedData("sb_none","None"),
                SelectedFilterDislayStr: symbol ? symbol : LocalizationStore.getTranslatedData("sb_none","None"),
                NewsStreamerFilter: {
                    symbols: symbol,
                    keywords: "",
                    operator: false,
                    newsStreamerListSelect: {
                        listId: 0,
                        listname: ""                  
                    }
                },
                SelectedLists: [],
                SelectedSourcesStr: "Ars Technica, CNBC - Business +49 more",
                Sources: [],
                SourcesAll: [],
                StreamID: -1 - index,
                StreamName: LocalizationStore.getTranslatedData("news_title","Untitled News Stream"),
                Symbols: symbol,
                isTemp: index === 0 ? true : false,
                isUpdate: 0,
                FilterFlag: false,
                enableNamePopup: false,
                tempStreamName:"",
                FilterListType:"",
                SelectedFilterListId:""
            };
            streamerList.push(newStream);

            dispatch(updateStreamList(streamerList));
            dispatch(getNewStreamer(true));
            //set scroll bar flag as true
            dispatch(updateScrollFlag(true));

            //Get Source for Empty stream - start
            dispatch({
                type: FETCH_SOURCES,
                payload: GetRSSSources().then((response) => {
                    if (response.newsRssSource && response.newsRssSource.length > 0) {
                        let sources = [];
                        let sourcesAll = [];
                        const newsRssSource = response.newsRssSource;

                        newsRssSource.map((rowData) => {
                            if (rowData.parents === null)
                                rowData.parents = rowData.sourceName;
                            if (rowData.sourceName && rowData.sourceId && rowData.newsType) {
                                if (rowData.IsCrawl === 1)
                                    sources.push(rowData);
                                sourcesAll.push(rowData);
                            }
                        });

                        let index = streamerList.length - 1;
                        streamerList[index].Sources = sources;
                        streamerList[index].SourcesAll = sourcesAll;
                        // }
                        dispatch(fetchNewsList(streamerList, newsState, index, action));
                    }
                }
                )
            });
            //Get Source for Empty stream - end
        }
        else {
            savedStreamerList.map((rowStreamList) => {
                rowStreamList.NewsList = [];
                rowStreamList.MessageVisibility = true;
            });
            streamerList = savedStreamerList;
            dispatch(updateStreamList(streamerList));
            dispatch(fetchNewsList(streamerList, newsState, -1, action));
        }
    }
}

export const SET_LIST_DATA = 'SET_LIST_DATA';
export const setList = createAction(SET_LIST_DATA);

export const FETCH_LIST_DATA = 'FETCH_LIST_DATA';
export const fetchListData = () => (dispatch, getState) => {
    dispatch(getFilterList(true));
    dispatch({
        type: FETCH_LIST_DATA,
        payload: ListApi.getListExplorer(1, 0, 0, 1).then((response) => {
            let responesData = response.data.explorerNodes;
            const data = filter(
                responesData, (rowData) =>
                    rowData.name === 'My Lists' || rowData.name === 'Global Focus Research' || rowData.name === 'PORTFOLIOS' ||
                    rowData.name === 'AP Lists' || rowData.name === 'Exclusive Lists'
            );

            let datasource = ListExplorerStore.sortOneilData(ListExplorerStore.setAttribute(data));
            dispatch(setList(datasource));
            dispatch(getFilterList(false));
        }
        )
    });
}

export const checkChildNodeId = (itm, selectedFilterListId) => (dispatch) => {
    let matchList = false;
    let result = false;
    for (let item of itm.childNodes) {
        if (item.nodeId.low === selectedFilterListId) {
            item.expandNode = true;
            itm.expandNode = true;
            matchList = true;
        }
        else {
            item.expandNode = false;
            if(!matchList) {
                itm.expandNode = false;
            }
        }
        if(item.childNodes.length && item.childNodes.length != 0) {
            result = dispatch(checkChildNodeId(item, selectedFilterListId));
            if(result) {
                itm.expandNode = true;
                matchList = true;
            }
            else if (!matchList) {
                itm.expandNode = false;
            }
        }
    }
    return matchList;
}

export const FETCH_STREAMER_NEWS_SOURCES = 'FETCH_STREAMER_NEWS_SOURCES';
export const fetchStreamerNewsSources = (selectedStream) => (dispatch) => {
    dispatch({
        type: FETCH_STREAMER_NEWS_SOURCES,
        payload: GetRSSSources().then((response) => {
            if (response.newsRssSource && response.newsRssSource.length > 0) {
                let sources = [];
                let sourcesAll = [];
                const newsRssSource = response.newsRssSource;
                newsRssSource.forEach((rowData) => { 
                    if (rowData.parents === null){
                        rowData.parents = rowData.sourceName;
                    }
                    if (rowData.sourceName && rowData.sourceId && rowData.newsType) {
                        if (rowData.IsCrawl === 1){
                            sources.push(rowData);
                        }
                        sourcesAll.push(rowData);
                    }
                });
                const newsTypes = sources.length > 0 && [...new Set(sources.map(item => item.newsType))];
                let allSelectedNewsType = [];
                let isNeedRefreah = false;
                newsTypes.forEach((newsType) =>{
                    const originalTypeSource = filter(selectedStream.Sources, rowsSource => rowsSource.newsType === newsType);
                    const isAllSeleted = find(originalTypeSource, rowsSource => rowsSource.isSelect === false) ? false : true;
                    const newTypeSource = filter(sources, rowsSource => rowsSource.newsType === newsType);

                    if(isAllSeleted){
                        allSelectedNewsType.push(newsType); 
                        newTypeSource.forEach((item) => item.isSelect = true);
                        if(newTypeSource.length > originalTypeSource.length){
                            isNeedRefreah = true;
                        }
                    }else{
                        const newSourceParents = [...new Set(newTypeSource.map(item => item.parents))];
                        newSourceParents.forEach((sourceParent) => { 
                            const originalSource = filter(originalTypeSource, rowsSource => rowsSource.parents === sourceParent);
                            const newSource = filter(newTypeSource, rowsSource => rowsSource.parents === sourceParent);
                            const isSelect = originalSource && originalSource.length > 0 ? originalSource[0].isSelect : false;
                            newSource.forEach((item) => item.isSelect = isSelect);
                            if(newSource.length > originalSource.length){
                                isNeedRefreah = true;
                            }
                        });
                    }
                });

                if(!isNeedRefreah){
                    for (let index = 0; index < selectedStream.Sources.length; index++) {
                        const sourceItem = selectedStream.Sources[index];
                        const targetItem = find(sources, (item) => item.sourceId === sourceItem.sourceId);
                        if(!targetItem){
                            isNeedRefreah = true;
                            break;
                        }
                    }
                }
                if(isNeedRefreah){
                    selectedStream.SourcesSelectStateBackup = [];
                    sources.forEach((item)=>{
                        selectedStream.SourcesSelectStateBackup.push({ 
                            parents: item.parents,
                            isSelect: item.isSelect,
                            sourceId: item.sourceId
                        });
                    });
                }
                selectedStream.Sources = sources;
                selectedStream.SourcesAll = sourcesAll;
                const selectedSources = filter(selectedStream.Sources, (rowSource) => rowSource.isSelect);
                let count = 0;
                for(let i = 1; i < selectedSources.length; i++){
                    if(selectedSources[i].parents === selectedSources[i-1].parents){
                        count++;
                    }
                }
                const selectedSourceCount = selectedSources.length - count;
                const isAllSourceSelect = selectedStream.Sources.length === selectedSourceCount;
                selectedStream.IsSourcesAllSelect = isAllSourceSelect;
                selectedStream.isNeedRefreah = isNeedRefreah;

                if(isNeedRefreah){
                    const sourceString = selectedSourceCount > 0 ?
                        selectedSourceCount == 1 ?
                        `${LocalizationStore.getTranslatedData("news_" + selectedSources[0].parents.replace(/ /ig,""),selectedSources[0].parents)}` :
                        `${LocalizationStore.getTranslatedData("news_" + selectedSources[0].parents.replace(/ /ig,""),selectedSources[0].parents)} +${selectedSourceCount - 1} ${LocalizationStore.getTranslatedData("alert_Geography_more","more")}` :
                        `${LocalizationStore.getTranslatedData("sb_none","None")}`;
            
                    selectedStream.SelectedSourcesStr = sourceString;
                }

                dispatch(setSelectedStream(selectedStream));
            }
        }
        )
    });
}

export const SET_SYMBOL = 'SET_SYMBOL'
export const setSymbol = createAction(SET_SYMBOL)

export const FETCH_LIST = 'FETCH_LIST'
export const FetchFilterList = (symboldata) => (dispatch) => {
    const symbolData = {
        showSymbol: true,
        data: undefined,
        selectedSymbol: symboldata.selectedSymbol,
        tempSelectedSymbol: symboldata.selectedSymbol,
        IsAndOperatorChecked: symboldata.IsAndOperatorChecked,
        searchSymbol: symboldata.searchSymbol,
        phrasesKeywords: symboldata.phrasesKeywords
    }

    dispatch(setSymbol(symbolData));
}
export const filterDataDispatch = (dataarr, symboldata) => (dispatch) => {
    {
        const data = filter(dataarr, (rowData) => rowData.name === 'My Lists' || rowData.name === 'Global Focus Research');
        const symbolData = {
            showSymbol: true,
            data: data,
            selectedSymbol: undefined,
            IsAndOperatorChecked: symboldata.IsAndOperatorChecked,
            searchSymbol: symboldata.searchSymbol,
            phrasesKeywords: symboldata.phrasesKeywords
        }
        dispatch(setSymbol(symbolData));
    }
}
export const UpdateFilterById = (data, uId) => (dispatch, getState) => {
    const state = getState();
    const streamerList = state.news.streamerList;

    streamerList.map((rowSreamer) => {
        const dataId = data.nodeIds ? data.nodeIds : data.nodeId;
        const filter = rowSreamer.NewsStreamerFilter.newsStreamerListSelect;
        if (filter.listId === dataId) {
            if (uId === 1) {
                filter.listId = 0;
                filter.listname = "";
                let slctArr = rowSreamer.SelectedFilterStr.split(',');
                rowSreamer.SelectedFilterStr = slctArr.slice(0, -1).join(',');
                rowSreamer.SelectedFilterStr = rowSreamer.SelectedFilterStr == "" ? LocalizationStore.getTranslatedData("sb_none","None") : rowSreamer.SelectedFilterStr;
                rowSreamer.SelectedFilterDislayStr = rowSreamer.SelectedFilterStr;
            }
            else {
                var reg = new RegExp('(\\b' + filter.listname + '\\b)(?!.*\\b\\1\\b)', 'i');
                rowSreamer.SelectedFilterStr = rowSreamer.SelectedFilterStr.replace(reg, data.nodeName);
                rowSreamer.SelectedFilterDislayStr = rowSreamer.SelectedFilterStr;
                filter.listId = data.nodeId;
                filter.listname = data.nodeName;
            }
        }
    });
    dispatch(updateStreamList(streamerList));
}


export const FETCH_LIST_VALIDATION = 'FETCH_LIST_VALIDATION';
export const SET_LIST_VALID = 'SET_LIST_VALID';
export const setListValidation = createAction(SET_LIST_VALID)
export const fetchListValidation = (lists, filtersymbolData, selectedStreamer) => (dispatch) =>{
    dispatch({
        type: FETCH_LIST_VALIDATION,
        payload: GetFilterListValidation(lists).then((response) => {
                let isValid = response.isValid;
                if (isValid) {
                    const fsymbol = {
                        showSymbol: false,
                        data: filtersymbolData.data,
                        selectedSymbol: filtersymbolData.tempSelectedSymbol,
                        tempSelectedSymbol: undefined,
                        IsAndOperatorChecked: filtersymbolData.IsAndOperatorChecked,
                        searchSymbol: filtersymbolData.searchSymbol,
                        phrasesKeywords: filtersymbolData.phrasesKeywords
                    }
                    dispatch(setSymbol(fsymbol));
                    selectedStreamer.FilterListType = filtersymbolData.tempSelectedSymbol[0].listType;
                    selectedStreamer.SelectedFilterListId = filtersymbolData.tempSelectedSymbol[0].selectedFilterListId;
                    dispatch(setSelectedStream(selectedStreamer));
                } else {
                    const fsymbol = {
                        showSymbol: false,
                        data: filtersymbolData.data,
                        selectedSymbol: filtersymbolData.selectedSymbol,
                        tempSelectedSymbol: undefined,
                        IsAndOperatorChecked: filtersymbolData.IsAndOperatorChecked,
                        searchSymbol: filtersymbolData.searchSymbol,
                        phrasesKeywords: filtersymbolData.phrasesKeywords
                    }
                    dispatch(setSymbol(fsymbol));
                }
                dispatch(setListValidation(isValid));
            }
        )
    });
}