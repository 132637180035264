import { connect } from 'react-redux';
import ErrorBoundary from "ErrorBoundary";
import RelatedInfoBody from './RelatedInfoBody';
import { toggleRiPanel } from '../../../../Actions/RelatedInfoPanelActions/RiPanelActions';
import React, { Component } from 'react';

class RelatedInfoPanel extends Component {
  constructor(props) {
    super(props);
  }

  toggleRiPanel = (e) => {
    e.preventDefault();
    this.props.toggleRiPanel();
    // dispatch(OnClearKPIData());
    // dispatch(toggleDgRiPanel());
    // DatagraphStore.updateIsRIPanelOpen(!this.state.isRIPanelOpen);
    // this.props.resizeOwnershipData(); uncomment when Ri panle conversion is stable
  }

  render() {
    return (
      <div id="wrapperRight" className={this.props.isRIPanelOpen ? "active-right": ""} style={{ height: "100%", position: "relative" }}>
        <div id="wrapRight" className="wrapRight">
          <div id="sidebar-wrapper-right">
            <div className="panel-container">
              <div className="panel panel-default">
                <div className="panel-body panel-bg ">
                  <ErrorBoundary><RelatedInfoBody /></ErrorBoundary>
                </div>
              </div>
            </div>
            <div id="menu-toggle-right" onClick={this.toggleRiPanel}>
              <div className="COLS">
                <div className="CELL _25PX SideBar rightBar">
                  <div className="dot-slide-bar marl5" />
                  <div className="dot-slide-bar1 graphs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ RelatedInfoPanelReducers }) => {
  const { isRIPanelOpen } = RelatedInfoPanelReducers.RiPanelReducer;
  return {
    isRIPanelOpen
  };
}

const mapDispatchToProps = (dispatch) => ({
  toggleRiPanel: () => dispatch(toggleRiPanel())
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatedInfoPanel);