import BlockType from "BlockType";
import { connect } from 'react-redux';
import { IndicatorContextConst } from "../../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants.js";
import { IndicatorHeaderTranslateHelper } from "../../../../../Utils/TranslateHelper.js";
import ScrollBar from "ScrollBar";
import UserInfoUtil from "UserInfoUtil";
import { Checkbox, Dropdown, MenuItem } from "react-bootstrap";
import { openCloseIndicatorBlock, saveIndicatorSectionVisiblity } from "../../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions.js";
import React, { useState } from 'react';
import { showContextMenu, updateContextHit } from "../../../../../Actions/PricePanelActions.js";

const IndicatorFlyouMenu = (props) => {
    const [showTi, setTiVisibility] = useState(false);
    const renderScrollBar = () => {
        setTiVisibility(!showTi);
    }

    const handleSectionChange = (index) => {
        const section = props.indicatorMenu;
        const visible = !section[index].IsVisible;
        section[index].IsVisible = !section[index].IsVisible;
        props.saveSection(section[index].blockType, visible);
    }
    function openContextMenu(event) {
        props.updateContextHit({lineID: IndicatorContextConst.Indicator_Panel});
        props.showContextMenu(event, event.clientY)
    }
    return (
        <div className={"graph-contex-menu ti-opening-dropdown shadow-none-btn"}>
            {props.priceDataAvailable && props.indicatorMenu.length > 0 ? <Dropdown id="dropdown-custom-theme-contex" >
                <Dropdown.Toggle className=" noBgMenu" onClick={() => renderScrollBar()}>
                    <div className="icn-contex-menu top" onContextMenu={openContextMenu}></div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="list-contex">
                    {
                        props.indicatorMenu && props.indicatorMenu.map((item, index) => (
                            (item.info.length !== 0 || item.blockType === BlockType.KPI) && <li key={item.Name} className={item.info.length === 0 ? "deactive" : ""}>
                                <Checkbox id="tiFundamentalMetrics" disabled={item.info.length === 0} className="custom-check" type="checkbox" checked={item.IsVisible} onChange={() => handleSectionChange(index)}>
                                    <label className="sign-check-label" />
                                    <span className="sign-check-text">{item.Name}</span>
                                </Checkbox>
                                {item.info.length > 0 && <ul rel={item.Name} className={item.Name === BlockType.ExternalDataName ? "ext-indicator" : ""}>
                                    {item.info.length > 0 && item.info.map((itm, idx) =>
                                        <MenuItem
                                            rel={IndicatorHeaderTranslateHelper[itm.blockType]}
                                            key={idx + 1} eventKey="1"
                                            onClick={() => props.onOpenCollapseBlock(item.blockType, itm.blockType, !itm.ischecked, itm, itm.listId)}>
                                            <span className={itm.ischecked ? "icn-right-icon" : ""}></span>
                                            {itm.Header}
                                        </MenuItem>)}
                                </ul>}
                            </li>))
                    }
                </Dropdown.Menu>
                {props.indicatorMenu.length > 10 && UserInfoUtil.hasExternalDataUploaderEntitlement() && <ScrollBar scrollId='scrollExternalSubMenu-ti' vScroll={true} />}
            </Dropdown>
                : <div className="icn-content-menu-default invisible" />
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    const { indicatorMenu } = state.DatagraphReducers.IndicatorReducer;
    const { priceDataAvailable } = state.DatagraphReducers.DataGraphReducer;
    return ({
        indicatorMenu, priceDataAvailable
    });
}
const mapDispatchToProps = (dispatch) => ({
    onOpenCollapseBlock: (pblockType, blockType, isOpen, item, listId) => dispatch(openCloseIndicatorBlock(pblockType, blockType, isOpen, item, listId)),
    saveSection: (block, visible) => dispatch(saveIndicatorSectionVisiblity(block, visible)),
    showContextMenu: (event, top) => dispatch(showContextMenu(event, top)),
    updateContextHit: (contextMenuObj) => dispatch(updateContextHit(contextMenuObj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorFlyouMenu);