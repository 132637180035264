import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class AddInstrumentsRequest extends BaseServiceRequest {

    constructor(listId, cacheId, consoleId, symbols, msids, snapshotDates, insertSeqNum, includeDetails, bulkInsertData = null, undoAdd = false, BrowserSelectedFilters = "-1,-1,-1", addType = 1) {
        super();
        super.addRequestUri("instrumentsES.pb");
        super.addRequestParameter("listId", listId.toString());
        super.addRequestParameter("cacheId", "0"); //Note: Desktop Bug 47327
        super.addRequestParameter("insertSeqNum", insertSeqNum.toString());
        super.addRequestParameter("c", consoleId);
        super.addRequestParameter("r", includeDetails ? "1" : "0");
        super.addRequestParameter("isUndo", undoAdd ? "1" : "0");
        super.addRequestParameter("BrowserSelectedFilters", BrowserSelectedFilters);
        super.addRequestParameter("addType", addType.toString());

        if (bulkInsertData != null) super.setContent(bulkInsertData);
        else {
            super.addRequestParameter("symbols", symbols);
            super.addRequestParameter("msids", msids);
            super.addRequestParameter("snapshotDates", snapshotDates);
        }

        super.addRequestParameter("rts", '0'); // AddRequestParameter("rts", ((int)WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.IntradaySourceType).ToString());
    }

    getMethod() {
        return HttpMethodType.PUT;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "OwnershipAddRemoveResponse";
    }
}

export default AddInstrumentsRequest;
