import React from "react";
import { Modal } from "react-bootstrap";
import LocalizationStore from "../Stores/Localization/LocalizationStore.js";

export default class SelectLimitWarningDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="warningWindow">
                <Modal className="modal-popup paste-symbol-warning" show={this.props.showModal}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="cap-header">{this.props.headerText}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-area">
                            <p>
                                <span className="icn-warning" onClick={this.openCloseErrorReport}></span>
                                <span>{this.props.messasge}</span>
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" rel="Close Window" onClick={this.props.closeModal}>{LocalizationStore.getTranslatedData("tp_pt_cw", "Close")}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}