import React from "react";
import { SummaryTransLateHelper } from "../../../../../../Utils/TranslateHelper";
import { useSelector } from "react-redux";

function MiscControl() {
  const positiveBrushText = useSelector((state) => state.appColor.posDTColor)
  const negativeBrushText = useSelector((state) => state.appColor.negDTColor);

  const data = useSelector((state) => state.summaryReducer.miscData)
  const positiveStyle = { color: positiveBrushText }
  const negativeStyle = { color: negativeBrushText }

  const renderEmployeePercentChange = () => {
    // let className = state.data.employeePercentChangeIsNegative ? "red-text" : "blue-text";
    const tempStyle = data.employeePercentChangeIsNegative ? negativeStyle : positiveStyle;

    return <div className="data-cell">
      <span className="label xx-small-normal">{data.employeePercentChangeLabel}</span>
      <span className="label-2 xx-small-normal">{data.employeeNumber === "NaN" ? "" : data.employeeNumber}</span>
      <span className={"label-3 xx-small-normal "} style={tempStyle}>&nbsp; {data.employeePercentChangeData}</span>
    </div>;
  }

  const renderBeta = () => {
    if (data && data.Beta) {
      return <div className="data-cell column-span-2">
        <span className="label">{SummaryTransLateHelper.SUMMARY_BLOCK_BETA}</span>
        <span >{data.betaDisplayValue ? data.betaDisplayValue : ""}</span>
      </div>;
    }
    return <div className="data-cell column-span-2">
      <span className="label">{SummaryTransLateHelper.SUMMARY_BLOCK_BETA}</span>
      <span className="opacity50">--</span>
    </div>;
  }

  const renderDIVGRRT = () => {
    if (data && data.AnnualizedDividendAmountDatumState) {
      // let className = data.DividendGrowth < 0 ? "red-text" : "blue-text";
      const tempStyle = data.DividendGrowth < 0 ? negativeStyle : positiveStyle;

      return <div className="data-cell column-span-3">
        <span className="label">{SummaryTransLateHelper.DIV_GR_RT_TEXT}</span>
        <span className="label-2"><span style={tempStyle}>{data.divGrowth}</span>&nbsp;<span>{data.divStab}</span></span>
      </div>;
    } else {
      return <div className="data-cell column-span-3">
        <span className="label">{SummaryTransLateHelper.DIV_GR_RT_TEXT}</span>
        <span className="label-2 opacity50">--</span>
      </div>;
    }
  }

  const renderDIV = () => {
    if (data && data.dividendAmountAndDividendYieldDisplayValue) {
      return <div className="data-cell column-span-2">
        <span className="label">{SummaryTransLateHelper.SUB_DIV} {data ? data.divCurr : ""}</span>
        <span className="label-2">{data.div}{renderDataText(data.divYield)}</span>
      </div>;
    }
    return <div className="data-cell column-span-2 opacity50">
      <span className="label">{SummaryTransLateHelper.SUB_DIV} {data ? data.divCurr : ""}</span>
      <span className="label-2 non-text">{SummaryTransLateHelper.SUMMARY_BLOCK_NONE_TEXT}</span>
    </div>;
  }

  const renderDataText = (data) => {

    if (!data || data === "") {
      return <span></span>
    }
    if (data === "--") {
      return <span className="no-data">--</span>
    }
    if (data[0] === "-") {
      return <span style={negativeStyle}>{data}</span>
    }
    if (data[0] === "(" && data.length > 1 && data[1] === "-") {
      return <span style={negativeStyle}>{data}</span>
    }
    return <span style={positiveStyle}>{data}</span>
  }

  const employeePercentChangeItem = data ? renderEmployeePercentChange() : "";

  return (
    <div className="misc-info-block">
      <div className="info-row">
        <div className="data-cell column-span-2">
          <span className="label">{SummaryTransLateHelper.DEBT_PERCENT}</span>
          <span className="label-2">{data ? data.threeYrDisplayValue : ""}</span>
        </div>
        <div className="data-cell column-span-3 avg">
          {renderDataText(data ? data.debtpct1 : "")}
          {renderDataText(data ? data.debtpct2 : "")}
          {renderDataText(data ? data.debtpct3 : "")}
        </div>
      </div>
      <div className="info-row">
        <div className="data-cell column-span-2">
          <span className="label">{SummaryTransLateHelper.TAX_RATE}</span>
          <span className="label-2">{data ? data.threeYrDisplayValue : ""}</span>
        </div>
        <div className="data-cell column-span-3 avg">
          {renderDataText(data ? data.taxpct1 : "")}
          {renderDataText(data ? data.taxpct2 : "")}
          {renderDataText(data ? data.taxpct3 : "")}
        </div>
      </div>
      <div className="info-row">
        <div className="data-cell column-span-2">
          <span className="label">{SummaryTransLateHelper.BACKLOG}</span>
          <span className="label-2">{data ? data.threeYrDisplayValue : ""}</span>
        </div>
        <div className="data-cell column-span-3 avg">
          {renderDataText(data ? data.backLog1 : "")}
          {renderDataText(data ? data.backLog2 : "")}
          {renderDataText(data ? data.backLog3 : "")}
        </div>
      </div>
      <div className="info-row">
        <div className="data-cell column-span-2">
          <span className="label">{SummaryTransLateHelper.R_AND_D}</span>
          <span className="label-2">{renderDataText(data && data.rnd ? data.rnd : "--")}</span>
        </div>
        <div className="data-cell column-span-3">
          <span className="label">{SummaryTransLateHelper.BK_VAL}</span>
          <span >{data ? data.bckValue : ""}</span>
        </div>
      </div>
      <div className="info-row">
        {renderDIV()}
        {renderDIVGRRT()}
      </div>
      <div className="info-row">
        {renderBeta()}
        <div className="data-cell column-span-3">
          <span className="label">{SummaryTransLateHelper.ALPHA_TEXT}</span>
          <span >{data ? data.alphaDisplayValue : ""}</span>
        </div>
      </div>
      <div className="info-row">
        {employeePercentChangeItem}
      </div>
      {/*<div className="divider" style={{left:"107px", top:"0"}}></div>*/}
    </div>
  );
}

export default MiscControl