import FundDescriptionControl from '../FundDescriptionControl.jsx';
import ImageSliderControl from './ImageSliderControl.jsx';
import { Panel } from "react-bootstrap";
import React from 'react';
import SectionControl from './SectionControl.jsx';
import SymbolContentControl from './SymbolContentControl.jsx';

function CurrencyPanelBody() {
    return (
        <>
            <Panel>
                <Panel.Body>
                    <div className="summary-block-catalog">
                        <div className="summary-block-log">
                            <SymbolContentControl />
                        </div>
                        <div className="summary-block-record description-currency">
                            <FundDescriptionControl id="currencyView" />
                        </div>
                    </div>
                    <div className="summary-block-chronicle for-currency">
                        <div className="summary-block-panel-bottom">
                            <ImageSliderControl />
                            <SectionControl id="peggedData" />
                            <SectionControl id="countriesData" />
                        </div>
                    </div>
                </Panel.Body>
            </Panel>
        </>
    )
}
export default CurrencyPanelBody