import Serializable from "Serializable";

class RiPanelManagementSettings extends Serializable {
    constructor() {
        super();
        this.IsRelativeYTD = false;
        this.IsDetailPage = false;
        this.ContactID = -1;
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.IsRelativeYTD = serializedValue.IsRelativeYTD ? serializedValue.IsRelativeYTD : false;
        this.IsDetailPage = serializedValue.IsDetailPage ? serializedValue.IsDetailPage : false;
        this.ContactID = serializedValue.ContactID ? serializedValue.ContactID : -1;
    }

    getObjectMapKey() {
        return "RiPanelManagementSettings";
    }

    getDataObject() {
        return {
            IsRelativeYTD: this.IsRelativeYTD,
            IsDetailPage: this.IsDetailPage,
            ContactID: this.ContactID,
        };
    }
}

export default RiPanelManagementSettings;
