class EventUtil {
    // show the title when the length is overflow, only support 1 line text.
    // example: onMouseOver={(e)=>EventUtil.mouseOverShowTitle(e, item.value)}
    mouseOverShowTitle(event, title) {
        if (!title)
            return;
        if (event.target.hasAttribute('title')) {
            event.target.removeAttribute("title");
        }
        if (event.target.scrollWidth > event.target.offsetWidth && !event.target.hasAttribute('title')) {
            event.target.setAttribute("title", title);
        }
    }
}

const eventUtil = new EventUtil();
export default eventUtil;