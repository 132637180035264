import Serializable from "Serializable";
class OpenInterestSettings extends Serializable {

    constructor(isVisible) {
        super();
        this.IsAvailable = true;
        this.IsVisible = isVisible;
        this.lineColor = "PFF0000670000";
        this.lineThickness = 1;
        this.lineData = [];
    }

    onSeralisedInfoRecieved(info) {
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
        this.IsAvailable = true;
        this.lineColor = info.lineColor !== undefined ? info.lineColor : 'P4C4C4C999999';
        this.lineThickness = info.lineThickness !== undefined ? info.lineThickness : 1;
        this.lineData = info.lineData !== undefined ? info.lineData : [];
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            lineColor: this.lineColor,
            lineThickness: this.lineThickness,
            lineData: this.lineData
        };
    }

    getObjectMapKey() {
        return "OpenInterestSettings";
    }
}

export default OpenInterestSettings;
