import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetSectorGroupSummaryBlockRequest extends BaseServiceRequest {
    constructor(symbol, endDate) {
        super();
        super.addRequestParameter("symbol", symbol);
        super.addRequestParameter("sdate", endDate);
        super.addRequestUri("sectorsumblock.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "SummaryBlockSectorDataResponse";
    }
}

export default GetSectorGroupSummaryBlockRequest;