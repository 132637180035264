import Serializable from "Serializable";
export default class MacdChartSettings extends Serializable {
  constructor() {
    super();
  }

  getDefaultFastLength() {
    return 12;
  }

  getDefaultSlowLength() {
    return 26;
  }

  getDefaultEMA() {
    return 9;
  }

  getDefaultMACDLineObj() {
    const OverboughtObj = [{ isActive: true, color: "PCCCCCC262626", weight: 2 }];
    return OverboughtObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultMovingAverageObj() {
    const OversoldObj = [{ isActive: false, color: "PFF0000670000", weight: 1 }];
    return OversoldObj.map((item) => this.getSerizableObject(item));
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

  getDefaultseqNumber(){
    return 2;
  }
  onSeralisedInfoRecieved(serializedValue) {
    this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
    this.Height = serializedValue.Height ? serializedValue.Height : 100;
    this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
    this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
    this.FastLength = serializedValue.FastLength ? serializedValue.FastLength : this.getDefaultFastLength();
    this.SlowLength = serializedValue.SlowLength ? serializedValue.SlowLength : this.getDefaultSlowLength();
    this.EMA = serializedValue.EMA ? serializedValue.EMA : this.getDefaultEMA();
    this.MACDLineColor = serializedValue.MACDLineColor ? serializedValue.MACDLineColor : this.getDefaultMACDLineObj();
    this.MovingAverageColor = serializedValue.MovingAverageColor ? serializedValue.MovingAverageColor : this.getDefaultMovingAverageObj();
    this.isApplyToAllIntervals = serializedValue.isApplyToAllIntervals ? serializedValue.isApplyToAllIntervals : false;
    this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : this.getDefaultseqNumber();
  }

  getDataObject() {
    return {
      IsVisible: this.IsVisible,
      Height: this.Height,
      IsAvailable: this.IsAvailable,
      IsRelative: this.IsRelative,
      FastLength: this.FastLength,
      SlowLength: this.SlowLength,
      EMA: this.EMA,
      MACDLineColor: this.MACDLineColor,
      MovingAverageColor: this.MovingAverageColor,
      isApplyToAllIntervals: this.isApplyToAllIntervals,
      seqNumber : this.seqNumber
    }
  }

  getDefaultMacdChartSettings() {
    this.IsVisible = true;
    this.Height = 100;
    this.IsAvailable = true;
    this.IsRelative = false;
    this.FastLength = this.getDefaultFastLength();
    this.SlowLength = this.getDefaultSlowLength();
    this.EMA = this.getDefaultEMA();
    this.MACDLineColor = this.getDefaultMACDLineObj();
    this.MovingAverageColor = this.getDefaultMovingAverageObj();
    this.seqNumber = this.getDefaultseqNumber();
    this.isApplyToAllIntervals = false;
    return this;
  }

  getObjectMapKey() {
    return "MacdChartSettings";
  }
}
