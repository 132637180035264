import EventUtil from '../../../../../../Utils/EventUtil.js';
import React from "react";
import ReactHtmlParser from "react-html-parser";
import ScrollBar from "ScrollBar";
import { useSelector } from "react-redux";

function SectionControl(props) {

    const result = useSelector((state) => state.summaryReducer)
    const countryData = result.countriesData
    const peggData = result.peggedData
    const width = 212.5;
    const height = 180;
    let data = {};

    props.id !== "peggedData" ? data = countryData : data = peggData

    const getSectionItem = (item, index) => (
        <div key={index} className="sectionItem" onFocus={(e) => EventUtil.mouseOverShowTitle(e, item)} onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, item)}>{item}</div>
    )

    const getContentView = (content) => {
        if (content) {
            const contentArray = content.split(",");
            return (<div>
                {contentArray.map((item, index) => getSectionItem(item, index))}
            </div>);
        } else {
            return <div>None</div>
        }
    }

    return (
        <div className="sb-countries" style={{ width: width, height: height }}>
            <div className="sb-countries-header">{ReactHtmlParser(data.headerText)}</div>
            <div className="sb-countries-content showScrollOnHover">
                <div className="custom-scroll">
                    <div id={`symbol-currency-${props.id}-VerticalScroll`}>
                        {getContentView(data.content)}
                    </div>
                </div>
                <ScrollBar scrollId={`symbol-currency-${props.id}-VerticalScroll`} vScroll={true} scrollOnHover={true} />
            </div>
        </div>
    );
}
export default SectionControl