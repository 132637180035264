import React, { Component } from "react";
import { connect } from 'react-redux';

import * as ColorConstants from "../../Constants/AppColorConstants";
import ThemeType from "../../Constants/ThemeTypeConstants"
import { onDarkPosColorChanged, onDarkNegColorChanged, onToggleColorPalette } from "../../Actions/AppColorActions"
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import SettingsStore from "SettingsStore";
import UserInfoUtil from "UserInfoUtil";
import LangType from "../../Constants/LangType";

class ColorRowItem extends Component {

    constructor(props) {
        super(props);

        this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
        this.onMouseLeaveHander = this.onMouseLeaveHander.bind(this);
        this.onItemClicked = this.onItemClicked.bind(this);
        this.getColor = this.getColor.bind(this);
        this.state = {
            color: this.props.color ? this.props.color :  this.getColor(),
            subject: this.props.colorType === ColorConstants.PosOrNeg.Positive ? LocalizationStore.getTranslatedData("set_pre_Positive_Color", "Positive Color") : LocalizationStore.getTranslatedData("set_pre_Negative_Color", "Negative Color"),
            type: this.props.colorType,
            theme: this.props.theme,
            onHover: false,
        }
    }

    getColor(){
        let lang = UserInfoUtil.hasChinaEntitlement() ? LangType.ZH_CN : LangType.RAY_DEFAULT;
        let color = ""
        if (this.props.colorType === ColorConstants.PosOrNeg.Positive) {
            if (lang === LangType.ZH_CN) {
                color = ColorConstants.DefaultColors.Red;
            } else if (lang === LangType.RAY_DEFAULT) {
                color = this.props.theme === ThemeType.Dark ? ColorConstants.DefaultColors.OtherDarkBlue : ColorConstants.DefaultColors.OtherLightBlue;
            }
        } else if (this.props.colorType === ColorConstants.PosOrNeg.Negative) {
            if (lang === LangType.ZH_CN) {
                color = ColorConstants.DefaultColors.Green;
            } else if (lang === LangType.RAY_DEFAULT) {
                color = this.props.theme === ThemeType.Dark ? ColorConstants.DefaultColors.OtherDarkMagenta : ColorConstants.DefaultColors.OtherLightMagenta;
            }
        }
        return color;

    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if (this.props.appColor !== nextProps.appColor) {
            let consoleSetting = SettingsStore.getConsoleSettings();
            let isDefaultDarkPosColor = consoleSetting.AppColorSettings.isDefaultDarkPosColor;
            let isDefaultLightPosColor = consoleSetting.AppColorSettings.isDefaultLightPosColor;
            let isDefaultDarkNegColor = consoleSetting.AppColorSettings.isDefaultDarkNegColor;
            let isDefaultLightNegColor = consoleSetting.AppColorSettings.isDefaultLightNegColor;

            let lang = UserInfoUtil.hasChinaEntitlement() ? LangType.ZH_CN : LangType.RAY_DEFAULT;

            switch (this.state.theme) {
                case ThemeType.Dark:
                    if (this.state.type === ColorConstants.PosOrNeg.Positive) {
                        if (isDefaultDarkPosColor === ColorConstants.UseDefaultColor.Yes) {
                            let color = lang === LangType.ZH_CN ?  ColorConstants.DefaultColors.Red : ColorConstants.DefaultColors.OtherDarkBlue;
                            this.setState({ color: color });
                        }
                        else {
                            this.setState({ color: nextProps.appColor.darkPosColor });
                        }
                    }
                    else if (this.state.type === ColorConstants.PosOrNeg.Negative) {
                        if (isDefaultDarkNegColor === ColorConstants.UseDefaultColor.Yes) {
                            let color = lang === LangType.ZH_CN ?  ColorConstants.DefaultColors.Green : ColorConstants.DefaultColors.OtherDarkMagenta;
                            this.setState({ color: color });
                        }
                        else {
                            this.setState({ color: nextProps.appColor.darkNegColor });
                        }
                    }
                    break;
                case ThemeType.Light:
                    if (this.state.type === ColorConstants.PosOrNeg.Positive) {
                        if (isDefaultLightPosColor === ColorConstants.UseDefaultColor.Yes) {

                            let color = lang === LangType.ZH_CN ?  ColorConstants.DefaultColors.Red : ColorConstants.DefaultColors.OtherLightBlue;
                            this.setState({ color: color});
                        }
                        else {
                            this.setState({ color: nextProps.appColor.lightPosColor });
                        }
                    }
                    else if (this.state.type === ColorConstants.PosOrNeg.Negative) {
                        if (isDefaultLightNegColor === ColorConstants.UseDefaultColor.Yes) {
                            let color = lang === LangType.ZH_CN ?  ColorConstants.DefaultColors.Green : ColorConstants.DefaultColors.OtherLightMagenta;
                            this.setState({ color: color});
                        }
                        else {
                            this.setState({ color: nextProps.appColor.lightNegColor });
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }

    onMouseEnterHandler() {
        this.setState({
            onHover: true,
        })
    }

    onMouseLeaveHander() {
        this.setState({
            onHover: false,
        })
    }

    onItemClicked () {
        if (this.props.appColor.isOpen !== true) {
            this.props.onToggleColorPalette(true, this.state.type, this.state.theme, this.props.appColor.lang, this.state.color);
        }

    }

    render() {
        let currentStyle = this.state.onHover ? "colorSelectionRowBorderHover" : "colorSelectionRowBorder";

        return (

            <div className="colorSelectionRow" >
                <div className={currentStyle}>
                    <div className="color-selection-square" 
                        name="colorSelectionRow"
                        onClick={this.onItemClicked}
                        style={{ backgroundColor: this.state.color }}
                        onMouseEnter={this.onMouseEnterHandler} onMouseLeave={this.onMouseLeaveHander}>
                    </div>
                </div>
                <span className="color-selection-text">{this.state.subject}</span>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        appColor: state.appColor
    }
}
const actionCreater = { onDarkPosColorChanged, onDarkNegColorChanged, onToggleColorPalette }
ColorRowItem = connect(mapStateToProps, actionCreater)(ColorRowItem);
export default ColorRowItem
