import React, { Fragment, Component } from "react";
import { Modal, ControlLabel, FormGroup, PanelGroup, Panel, Checkbox, Col, Form, Row, Table } from "react-bootstrap";
import AlertActions from 'AlertActions';
import AlertStore from 'AlertStore';
import { AlertConstants } from 'AlertConstants'
import BaseServiceApi from 'BaseServiceApi';
import AlertSourceType from "../../Constants/AlertSourceType";
import AlertConditionType from "../../Constants/AlertConditionType";
import UserInfoUtil from "UserInfoUtil";
import GeographyAlertDialog from "./GeographyAlertDialog.jsx";
import AlertColumnSet from "./AlertColumnSet.jsx";
import StringUtil from "StringUtil";
// import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions.js";
// import MenuDialogType from "../../Constants/MenuDialogType.js";
import ScrollBar from "ScrollBar";
import FlexibleModal from "../FlexibleModal.jsx";
import CommonDialog from "../../RayCustomControls/CommonDialog/CommonDialog";
import _ from "underscore";
import ErrorBoundary from "ErrorBoundary";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import NotificationContentContainer from './NotificationContentContainer';
import SymbolType from "../../Constants/SymbolType";

import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";

let InstrumentType = BaseServiceApi.rayData["InstrumentType"];
let ListAlerts = BaseServiceApi.rayData["ListAlerts"];
var EntitlementType = BaseServiceApi.rayData["EntitlementType"];
class AlertsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open,
            msId: this.props.msId,
            existAlerts: this.props.existAlerts,
            instrumentId: this.props.instrumentId,
            symbol: this.props.symbol,
            sourceTypeId: this.props.sourceTypeId,
            listAlertSettings: {},
            priceVolumeAlertable: false,
            addRemoveAlertable: false,
            corporateEventAlertable: false,
            alertTitle: '',
            alertSubTitle: '',
            openColumnSet: null,
            prEntitled: UserInfoUtil.IsUserEntitled(EntitlementType.PR_Entitlement),
            validationMessages: {},
            columnSetString: AlertStore.getSelectedColumnSetString(),
            openGeography: null,
            browserFilterText: AlertStore.getBrowserFilterText(),
            showLoader: true,
            commonDialog: null,
            isNCOpen: false,
            listAlertSubTitle: '',
            newHeight:null,
            compareHeight:null,
            symbolType: !StringUtil.isEmpty(this.props.SymbolInfo) ? this.props.SymbolInfo.SymTypeEnum : null,
            instrumentType : this.props.instrumentType,
            currencyCode: this.props.currencyCode
        }

        this.getXPosition = this.getXPosition.bind(this);
        this.getYPosition = this.getYPosition.bind(this);
        this.handleAlertStoreStateChangeOpenAlert = this.handleAlertStoreStateChangeOpenAlert.bind(this);
        this.getAlertSubTitle = this.getAlertSubTitle.bind(this);
        this.handleAlertStoreStateChangeSaveAlertData = this.handleAlertStoreStateChangeSaveAlertData.bind(this);
        this.handleAlertStoreStateChangeSelectColumnSet = this.handleAlertStoreStateChangeSelectColumnSet.bind(this);
        this.handleAlertStoreStateChangeSelectFilterBrowser = this.handleAlertStoreStateChangeSelectFilterBrowser.bind(this);
        this.handleCheckedChange = this.handleCheckedChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onNotificationPreferenceClick = this.onNotificationPreferenceClick.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.openColumnSetWindow = this.openColumnSetWindow.bind(this);
        this.closeColumnSetWindow = this.closeColumnSetWindow.bind(this);
        this.openGeographyWindow = this.openGeographyWindow.bind(this);
        this.closeGeographyWindow = this.closeGeographyWindow.bind(this);
        this.saveAlertData = this.saveAlertData.bind(this);
        this.doneBtnClick = this.doneBtnClick.bind(this);
        this.cancelBtnClick = this.cancelBtnClick.bind(this);
        this.criteriaLimitCheck = this.criteriaLimitCheck.bind(this);
        this.validationCheck = this.validationCheck.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.showAlertMessage = this.showAlertMessage.bind(this);
        this.scrollToErrorBox = this.scrollToErrorBox.bind(this);
        this.scrollToError = this.scrollToError.bind(this);
        this.setErrorDovLocation = this.setErrorDovLocation.bind(this);
        this.validateField = this.validateField.bind(this);
        this.getAlertId = this.getAlertId.bind(this);
        this.checkScrolling = this.checkScrolling.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.setState({
            msId: this.props.msId,
            instrumentId: this.props.instrumentId,
            sourceTypeId: this.props.sourceTypeId
        });

        this.editingAlertTypeId = this.props.alertTypeId;

        AlertStore.addChangeListener(this.handleAlertStoreStateChangeOpenAlert, AlertConstants.ActionTypes.OPEN_ALERT);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSaveAlertData, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSelectColumnSet, AlertConstants.ActionTypes.SELECT_ALERT_COLUMN_SET);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSelectFilterBrowser, AlertConstants.ActionTypes.SELECT_FILTER_BROWSER);
        AlertStore.addChangeListener(this.handleAlertStoreStateChangeSaveAlertData, AlertConstants.ActionTypes.ALERT_NOT_CHANGE);

        if (this.props.conditionTypeId == AlertConditionType.Or)
            AlertActions.openAlert(this.state.msId, this.state.instrumentId, this.state.symbol, this.state.sourceTypeId, this.state.existAlerts);
        else
            this.handleAlertStoreStateChangeOpenAlert();
    }

    componentWillUnmount() {

        AlertStore.removeChangeListener(this.handleAlertStoreStateChangeOpenAlert, AlertConstants.ActionTypes.OPEN_ALERT);
        AlertStore.removeChangeListener(this.handleAlertStoreStateChangeSaveAlertData, AlertConstants.ActionTypes.SAVE_ALERT_DATA);
        AlertStore.removeChangeListener(this.handleAlertStoreStateChangeSelectColumnSet, AlertConstants.ActionTypes.SELECT_ALERT_COLUMN_SET);
        AlertStore.removeChangeListener(this.handleAlertStoreStateChangeSelectFilterBrowser, AlertConstants.ActionTypes.SELECT_FILTER_BROWSER);
        AlertStore.removeChangeListener(this.handleAlertStoreStateChangeSaveAlertData, AlertConstants.ActionTypes.ALERT_NOT_CHANGE);
    }
    componentDidUpdate() {
        if (this.editingAlertTypeId) {
            let id = Object.keys(ListAlerts).find((k) => ListAlerts[k] == this.editingAlertTypeId);

            if (id == "DailyPricerisestowithinofpivot" || id == "WeeklyPricerisestowithinofpivot")
                id = "Pricerisestowithinofpivot";

            setTimeout(() => {
                if (document.getElementById('txt' + id)) {
                    document.getElementById('txt' + id).select();
                    document.getElementById('txt' + id).focus();
                    document.getElementById('txt' + id).scrollIntoView()
                }
            }, 500);

            this.editingAlertTypeId = null;
        }

        // if (this.props.open) {
        //     !document.body.classList.contains('model-open') && document.body.classList.add('model-open');
        // }
        // else {
        //     document.body.classList.contains('model-open') && document.body.classList.remove('model-open');
        // }
    }
    getXPosition() {
        let x = 0;
        let w = 460;

        if (this.props.x) {
            x = this.props.x //- (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2
        }

        if (x < 0)
            x = 0;

        return x;
    }

    getYPosition() {
        let y = 0;
        let h = 400;

        if (this.state.sourceTypeId == AlertSourceType.List)
            h = 600;

        if (this.props.y) {
            if ((this.props.y + h) > Math.max(document.documentElement.clientHeight, window.innerHeight || 0))
                y = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h;
            else
                y = this.props.y;//- (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2
        }

        if (y < 0)
            y = 0;
        if(this.props.y){
            y = this.props.y;
        }
        return y;
    }

    //Alert Store change listener - Start

    handleAlertStoreStateChangeOpenAlert() {
        this.setState(
            {
                priceAlertObj: this.props.conditionTypeId == AlertConditionType.Or ? null : this.props.sourceTypeId == AlertSourceType.Stock ? this.props.priceAlertObj : null,
                TargetPrice: this.props.conditionTypeId == AlertConditionType.And && this.props.priceAlertObj && this.props.sourceTypeId == AlertSourceType.Stock ?  this.props.priceAlertObj.targetPrice : null,
                TargetPriceSelected: this.props.conditionTypeId == AlertConditionType.And && this.props.priceAlertObj && this.props.sourceTypeId == AlertSourceType.Stock ? true : false,
                listAlertSettings: this.props.conditionTypeId == AlertConditionType.Or ? AlertStore.getAlertSettings() : this.props.listAlertSettings,
                priceVolumeAlertable: AlertStore.getPriceVolumeAlertable(),
                addRemoveAlertable: AlertStore.getAddRemoveAlertable(),
                corporateEventAlertable: AlertStore.getCorporateEventAlertable(),
                alertTitle: this.props.conditionTypeId == AlertConditionType.Or ? AlertStore.getAlertTitle() : "Select Up To 2 Alert Criteria",
                alertSubTitle:this.getAlertSubTitle(this.state.symbol),
                columnSetString: !this.state.existAlerts ? LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set") : AlertStore.getSelectedColumnSetString(),
                browserFilterText: !this.state.existAlerts ? LocalizationStore.getTranslatedData("FilterBrowser_Country_-1","All") : AlertStore.getBrowserFilterText(),
                showLoader: false,
                listAlertSubTitle: AlertStore.getListAlertSubTitle()
            }
        );
    }

    getAlertSubTitle() {
        let subTitle;
        let str = this.props.conditionTypeId == AlertConditionType.Or ? 'ANY' : 'ALL';
        if(this.props.sourceTypeId == AlertSourceType.List){
            subTitle = LocalizationStore.getTranslatedData("alert_list_msg", 'Alert me when any symbol on {0} meets {1} of the following conditions:', AlertStore.getListName(), str);
        }else{
            subTitle = LocalizationStore.getTranslatedData("ch_msa_msgalert", 'Alert me when {0} of the selected conditions are met:', str);
        }

        return subTitle;
    }


    handleAlertStoreStateChangeSaveAlertData() {
        this.closeDialog();
    }

    handleAlertStoreStateChangeSelectColumnSet() {
        this.setState(
            { columnSetString: AlertStore.getSelectedColumnSetString() }
        );
    }

    handleAlertStoreStateChangeSelectFilterBrowser() {
        this.setState(
            { browserFilterText: AlertStore.getBrowserFilterText() }
        );
    }
    //Alert Store change listener - End

    //Check box change event
    handleCheckedChange(e) {
        if (e) {
            let controlName = e.target.name;

            if (!e.target.checked || this.criteriaLimitCheck(false, controlName) || controlName == "DailyVolumeRateDelaySelected") {
                if (controlName == "TargetPriceSelected") {
                    this.setState({
                        TargetPriceSelected: !this.state.TargetPriceSelected
                    });
                }
                else if (controlName == "DailyVolumeRateSelected") {
                    let listAlertSettings = this.state.listAlertSettings;
                    let targetValue = !listAlertSettings[controlName];
                    listAlertSettings[controlName] = targetValue;
                    if(!targetValue){
                        listAlertSettings["DailyVolumeRateDelaySelected"] = targetValue;
                    }

                    this.setState({
                        listAlertSettings: listAlertSettings
                    });
                }
                else {
                    let listAlertSettings = this.state.listAlertSettings;
                    listAlertSettings[controlName] = !listAlertSettings[controlName];

                    this.setState({
                        listAlertSettings: listAlertSettings
                    });
                }
            }

        }
    }

    handleKeyUp(event) {
        if(event.target.value.length > event.target.maxLength) {
             event.target.value = event.target.value.slice(0, event.target.maxLength)
        }
        else {
            this.validationCheck();
            event.target.autofocus = true;
        }

    }
    //Textbox value change event
    handleValueChange(event) {
        if (event) {
            if (event.target.name == "TargetPrice") {
                if(parseFloat(event.target.value) > -1 || event.target.value == ""){
                    this.setState({
                        TargetPrice: parseFloat(event.target.value)
                    });
                }
                else{
                    this.setState({
                        TargetPrice: ''
                    });
                }
            }
            else {
                let listAlertSettings = this.state.listAlertSettings;

                listAlertSettings[event.target.name] = parseInt(event.target.value.slice(0, event.target.maxLength));

                this.setState({
                    listAlertSettings: listAlertSettings
                });
            }
        }
    }

    closeDialog() {
        this.props.closeAlert();
    }

    onNotificationPreferenceClick() {
        // MenuCommonDialogActions.openNotificationDialog({ eventKey: MenuDialogType.Notification, showbackButton: false });
        this.setState({ isNCOpen: true });
    }

    hideModal() {
        this.setState({ isNCOpen: false });
    }

    openColumnSetWindow() {
        let left = document.getElementById("modalBody").getBoundingClientRect().left + 69;
        let top = document.getElementById("modalBody").getBoundingClientRect().top + 15;

        let openColumnSet = <AlertColumnSet left={left} top={top} closeColumnSetWindow={this.closeColumnSetWindow} />
        this.setState({ openColumnSet: openColumnSet });
        setTimeout(function(){
            document.getElementsByClassName("alert-columnset")[0].classList.add("slide-down");
        },50)
    }

    closeColumnSetWindow() {
        let self = this;

        document.getElementsByClassName("alert-columnset")[0].classList.remove("slide-down");
        setTimeout(function(){
            self.setState({ openColumnSet: null })
        },450)
    }

    openGeographyWindow() {
        let left = document.getElementById("modalBody").getBoundingClientRect().left + 69;
        let top = document.getElementById("modalBody").getBoundingClientRect().top + 15;

        let openGeography = <GeographyAlertDialog left={left} top={top} closeGeographyWindow={this.closeGeographyWindow} existAlerts={this.state.existAlerts}/>
        this.setState({ openGeography: openGeography });
        setTimeout(function(){
            document.getElementsByClassName("alert-geography")[0].classList.add("slide-down");
        },50)
    }

    closeGeographyWindow() {
        let self = this;

        document.getElementsByClassName("alert-geography")[0].classList.remove("slide-down");
        setTimeout(function(){
            self.setState({ openGeography: null });
        },450)
    }

    saveAlertData() {
        if (this.validationCheck()) {
            document.getElementById("btnSaveAlert").disabled = true;
            AlertActions.saveAlertData(this.state.listAlertSettings, this.props.conditionTypeId, null, null);
        }
    }

    doneBtnClick () {
        if (this.validationCheck()) {
            let priceAlertObj = {};

            if (this.state.TargetPriceSelected) {
                priceAlertObj = {
                    targetPrice: parseFloat(this.state.TargetPrice),
                    msId:parseInt(this.state.sourceTypeId == AlertSourceType.Stock  && this.props.SymbolInfo ?  (this.props.SymbolInfo.MsId) : (this.state.msId)),
                    currencyCode: (this.state.currencyCode ? this.state.currencyCode : this.props.SymbolInfo ? AlertStore.getCurrencyCode(this.props.SymbolInfo.DefaultCurrency) : "")
                }
            }   
            else {
                priceAlertObj = null;
            }

            this.props.doneBtnClick(this.state.listAlertSettings, priceAlertObj);
            this.closeDialog();
        }
    }

    cancelBtnClick () {
        this.closeDialog();
    }

    criteriaLimitCheck (isZeroCheck, controlName) {

        if (this.props.conditionTypeId == AlertConditionType.And) {
            let selectedCriteriaCount = 0;

            _.each(this.state.listAlertSettings, (val, key) => {
                if(key != "DailyVolumeRateDelaySelected"){
                    if ((key.lastIndexOf("Selected") == key.length - 8) || (key.lastIndexOf("Checked") == key.length - 7)) {
                        if (val == true)
                            selectedCriteriaCount++;
                    }
                }
            });

            if (this.state.TargetPriceSelected) {
                selectedCriteriaCount++;
            }
            if(controlName == "DailyVolumeRateDelaySelected"){
                    return true;
            }

            if (!isZeroCheck && selectedCriteriaCount >= 2) {
                this.showAlertMessage("The maximum number of criteria is 2.");
                return false;
            }
            else if (isZeroCheck && selectedCriteriaCount == 0) {
                this.showAlertMessage("Select at least 1 criteria.");
                return false;
            }
        }
        return true;
    }

    validationCheck () {
        let isValid = true;
        let validationMessages = {};

        if (!this.criteriaLimitCheck(true))
            return false;

        validationMessages.PriceChangeThreshold = this.validateField(this.state.listAlertSettings.PriceChangeSelected, this.state.listAlertSettings.PriceChangeThreshold, 1, 999);
        validationMessages.DailyVolumeRateThreshold = this.validateField(this.state.listAlertSettings.DailyVolumeRateSelected, this.state.listAlertSettings.DailyVolumeRateThreshold, 50, 999)
        validationMessages.DailyVolumeRateDelayThreshold = this.validateField(this.state.listAlertSettings.DailyVolumeRateDelaySelected, this.state.listAlertSettings.DailyVolumeRateDelayThreshold, 1, 720)
        validationMessages.AverageDlyVolThreshold = this.validateField(this.state.listAlertSettings.AverageDlyVolSelected, this.state.listAlertSettings.AverageDlyVolThreshold, 100, 999999999)
        validationMessages.AverageDlyDlrVolThreshold = this.validateField(this.state.listAlertSettings.AverageDlyDlrVolSelected, this.state.listAlertSettings.AverageDlyDlrVolThreshold, 500, 999999999)
        validationMessages.EarningsReleaseDays = this.validateField(this.state.listAlertSettings.EarningsReleaseSelected, this.state.listAlertSettings.EarningsReleaseDays, 1, 365)
        validationMessages.AlertPriceRises = this.validateField(this.state.listAlertSettings.DailyPriceRisesSelected || this.state.listAlertSettings.WeeklyPriceRisesSelected, this.state.listAlertSettings.AlertPriceRises, 1, 999)
        validationMessages.NegativeAlter1Week = this.validateField(this.state.listAlertSettings.NegativeAlter1WeekSelected, this.state.listAlertSettings.NegativeAlter1Week, 1, 11)
        validationMessages.NegativeAlter10Week = this.validateField(this.state.listAlertSettings.NegativeAlter10WeekSelected, this.state.listAlertSettings.NegativeAlter10Week, 1, 11)

        if (this.props.conditionTypeId == AlertConditionType.And && this.props.sourceTypeId == AlertSourceType.Stock)
            validationMessages.TargetPrice = this.validateField(this.state.TargetPriceSelected, this.state.TargetPrice, 0, null, 1);

        else
            validationMessages.TargetPrice = '';

        if (!StringUtil.isEmpty(validationMessages.PriceChangeThreshold +
            validationMessages.DailyVolumeRateThreshold +
            validationMessages.DailyVolumeRateDelayThreshold +
            validationMessages.AverageDlyVolThreshold +
            validationMessages.AverageDlyDlrVolThreshold +
            validationMessages.EarningsReleaseDays +
            validationMessages.AlertPriceRises +
            validationMessages.TargetPrice +
            validationMessages.NegativeAlter1Week +
            validationMessages.NegativeAlter10Week)) {
            isValid = false;
        }

        this.setErrorDovLocation();

        this.scrollToErrorBox(validationMessages);

        this.setState({ validationMessages: validationMessages });

        return isValid;
    }

    hideAlertMessage () {
        this.setState({ commonDialog: null });
    }

    showAlertMessage (msg) {

        let dialog = <CommonDialog
            isOpen={true}
            title='Error'
            backButtonShow={false}
            cancelButtonShow={false}
            submitButtonShow={true}
            submitHandler={this.hideAlertMessage}
            hideModal={this.hideAlertMessage}
            submitButtonText='OK'
        >
            <Fragment><div className="alert-stuff"><p>{LocalizationStore.getTranslatedData("cm_" + msg,msg)}</p></div><div className="clearfix"></div></Fragment>
        </CommonDialog>
        this.setState({ commonDialog: dialog });
    }

    scrollToErrorBox (validationMessages) {
        if(!StringUtil.isEmpty(validationMessages.PriceChangeThreshold))
            this.scrollToError("PriceChangeThresholdGroup");
        else if(!StringUtil.isEmpty(validationMessages.DailyVolumeRateThreshold))
            this.scrollToError("DailyVolumeRateexceedsGroup");
        else if(!StringUtil.isEmpty(validationMessages.AverageDlyVolThreshold))
            this.scrollToError("AverageDailyVolumecrossesGroup");
        else if(!StringUtil.isEmpty(validationMessages.AverageDlyDlrVolThreshold))
            this.scrollToError("AverageDailyDlrVolumecrossesGroup");
        else if(!StringUtil.isEmpty(validationMessages.EarningsReleaseDays))
            this.scrollToError("EarningsreleaseiswithinGroup");
        else if(!StringUtil.isEmpty(validationMessages.AlertPriceRises))
            this.scrollToError("PricerisestowithinofpivotGroup");
        else if(!StringUtil.isEmpty(validationMessages.TargetPrice))
            this.scrollToError("TargetPriceGroup");
        else if(!StringUtil.isEmpty(validationMessages.NegativeAlter1Week))
            this.scrollToError("NegativeAlter1WeekGroup");
        else if(!StringUtil.isEmpty(validationMessages.NegativeAlter10Week))
            this.scrollToError("NegativeAlter10WeekGroup");
    }

    scrollToError (id) {
        setTimeout(() => {
            document.getElementById(id).scrollIntoView()
        }, 500);
    }

    getAlertEntitledBySymbolType(symbolType) {
        let prEntitled = true, corporateEventAlertable = true;
        switch (symbolType) {
            case SymbolType.INDEX:
            case SymbolType.INDEXNOINTRADAYDATA:
            case SymbolType.INDEXNOINTRADAYVOL:
            case SymbolType.INDEXNOINTRADAYVOLDATA:
            case SymbolType.INTERNATIONALINDEX:
            case SymbolType.HISTORICINDEX:
            case SymbolType.WONINDUSTRYGROUP197:
            case SymbolType.WONMAJORINDUSTRY89:
            case SymbolType.WONSECTOR11:
            case SymbolType.WONSUBSECTOR33:
                prEntitled = false;
                corporateEventAlertable = false;
                break;
            default:
                break;
        }
        if(this.state.sourceTypeId == AlertSourceType.List && this.state.symbol == null && this.state.corporateEventAlertable == true){
            prEntitled = true;
            corporateEventAlertable = true;
        }else if(this.state.sourceTypeId != AlertSourceType.List && this.state.symbol != null && this.state.corporateEventAlertable == true){
            if(this.state.instrumentType && this.state.instrumentType != InstrumentType.STOCK_Instrument){
                prEntitled = false;
                corporateEventAlertable = false;
            }else if((this.state.instrumentType && this.state.instrumentType == InstrumentType.STOCK_Instrument) || this.state.symbolType == null){
                prEntitled = true;
                corporateEventAlertable = true;
            }
        }
        return { prEntitled: prEntitled, corporateEventAlertable: corporateEventAlertable };
    }

    setErrorDovLocation () {
        document.getElementById("PriceChangeThresholdErrorDiv").style.right = (document.getElementById("PriceChangeThresholdGroup").getBoundingClientRect().right - document.getElementById("txtDailypricechangeexceeds").getBoundingClientRect().right).toString() + "px";
        document.getElementById("DailyVolumeRateexceedsErrorDiv").style.right = (document.getElementById("DailyVolumeRateexceedsGroup").getBoundingClientRect().right - document.getElementById("txtDailyVolumeRateexceeds").getBoundingClientRect().right).toString() + "px";
        document.getElementById("DailyVolumeRateexceedsDelayErrorDiv").style.right = (document.getElementById("DailyVolumeRateexceedsDelayGroup").getBoundingClientRect().right - document.getElementById("txtDailyVolumeRateexceedsDelay").getBoundingClientRect().right).toString() + "px";
        document.getElementById("AverageDailyVolumecrossesErrorDiv").style.right = (document.getElementById("AverageDailyVolumecrossesGroup").getBoundingClientRect().right - document.getElementById("txtAverageDailyVolumecrosses").getBoundingClientRect().right).toString() + "px";
        document.getElementById("AverageDailyDlrVolumecrossesErrorDiv").style.right = (document.getElementById("AverageDailyDlrVolumecrossesGroup").getBoundingClientRect().right - document.getElementById("txtAverageDailyDlrVolumecrosses").getBoundingClientRect().right).toString() + "px";
        if (this.state.sourceTypeId == AlertSourceType.List) {
            document.getElementById("NegativeAlter1WeekErrorDiv").style.right = (document.getElementById("NegativeAlter1WeekGroup").getBoundingClientRect().right - document.getElementById("txtNegativeAlter1Week").getBoundingClientRect().right).toString() + "px";
            document.getElementById("NegativeAlter10WeekErrorDiv").style.right = (document.getElementById("NegativeAlter10WeekGroup").getBoundingClientRect().right - document.getElementById("txtNegativeAlter10Week").getBoundingClientRect().right).toString() + "px";
        }
        if (document.getElementById("PricerisestowithinofpivotErrorDiv"))
            document.getElementById("PricerisestowithinofpivotErrorDiv").style.right = (document.getElementById("PricerisestowithinofpivotGroup").getBoundingClientRect().right - document.getElementById("txtPricerisestowithinofpivot").getBoundingClientRect().right).toString() + "px";

        if (document.getElementById("EarningsreleaseiswithinErrorDiv"))
            document.getElementById("EarningsreleaseiswithinErrorDiv").style.right = (document.getElementById("EarningsreleaseiswithinGroup").getBoundingClientRect().right - document.getElementById("txtEarningsreleaseiswithin").getBoundingClientRect().right).toString() + "px";

        if (this.props.conditionTypeId == AlertConditionType.And && this.props.sourceTypeId == AlertSourceType.Stock)
            document.getElementById("TargetPriceGroupErrorDiv").style.right = (document.getElementById("TargetPriceGroup").getBoundingClientRect().right - document.getElementById("txtTargetPrice").getBoundingClientRect().right).toString() + "px";
    }

    validateField = (isSelected, data, min, max, isPriceAlert) => {
        let message = '';

        if (isSelected) {
            if((StringUtil.isEmpty(data) || isNaN(data)) && isPriceAlert){
                message = LocalizationStore.getTranslatedData('sy_greater', 'Enter a value greater than or equal to {0}', min.toString());
            }
            else if (StringUtil.isEmpty(data) || isNaN(data)) {
                message = LocalizationStore.getTranslatedData('sy_valid', 'Enter a valid value');
            }
            else if (min != null && max != null) {
                if (data < min) {
                    message = LocalizationStore.getTranslatedData('sy_greater', 'Enter a value greater than or equal to {0}', min.toString());
                }
                else if (data > max) {
                    message = LocalizationStore.getTranslatedData('sy_less', 'Enter a value less than or equal to {0}', max.toString());
                }
            }
        }

        return message;
    }

    getAlertId () {
        this.props.alertId ? this.props.alertId : 0;
    }

    checkScrolling () {
        setTimeout(() => {
            let newHeight = null;
            let compareHeight = null;
            if(document.getElementById('alertDialogSetVerticalScroll')){
                newHeight = document.getElementById('allAlertCollapsible').clientHeight;
                compareHeight = document.getElementById('alertDialogSetVerticalScroll').clientHeight;
                this.setState({
                    newHeight:newHeight,
                    compareHeight:compareHeight
                })
            }
        }, 500);
    }

    render() {
        const {newHeight, compareHeight} = this.state;
        return (
            <ErrorBoundary>
                <section className="alert-b">
                    {this.state.showLoader ? <div className="loading-box">
                        <div className="loading-backdrop"></div>
                        <div className="loading loading-spinner"></div>
                    </div>
                        :
                        <section id="AlertDialog">
                            {this.state.openColumnSet}
                            {this.state.openGeography}
                            <FlexibleModal left={this.getXPosition()} top={this.getYPosition()} open={this.props.open} ref={(modalHeader) => this.modalHeader = modalHeader}
                                draggable={this.props.conditionTypeId == AlertConditionType.And ? false : "false"} isDefaultDragEnabled={false} className="modal-popup alert-dialog or-condition-alert">
                                {!this.state.showLoader ?
                                    <Modal.Header className="enable-drag">
                                        <Modal.Title className="enable-drag">
                                            <div className="enable-drag">
                                                {this.props.conditionTypeId == AlertConditionType.And ? <button type="button" id="btnDone" className="btn btn-xs btn-default btn-secondary btn-done " data-effect="fadeOut" onClick={this.doneBtnClick}>{LocalizationStore.getTranslatedData("done", "DONE")}</button> : null}
                                                {this.props.conditionTypeId == AlertConditionType.And ? <button type="button" id="btnCancel" className="btn btn-xs btn-default btn-secondary btn-cancel " data-effect="fadeOut" onClick={this.cancelBtnClick}>{LocalizationStore.getTranslatedData("cancel", "CANCEL")}</button> : null}
                                                <span className="cap-header enable-drag">{this.state.alertTitle}</span>
                                            </div>
                                        </Modal.Title>
                                    </Modal.Header>
                                    : null}
                                <Modal.Body id="modalBody">
                                    {/*  style={{ paddingBottom: this.props.paddingBottom ? this.props.paddingBottom : ''}} */}
                                    <div className="criteria-alert-dialog">{this.state.commonDialog}</div>
                                    <div className="dialog-content">

                                        <div className="flexbox-parent">
                                            <div className="flexbox-item">
                                                <div className="alert-heading">
                                                    {this.state.listAlertSubTitle}
                                                </div>
                                                <div className="alert-sub-heading">
                                                    <div className="tool-tip-box">
                                                        <span className="list-alert-name">{ReactHtmlParser(this.state.alertSubTitle)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="flexbox-item content">

                                            {/* <div className={"fill-area-content flexbox-item-grow or-alert-scroll custom-scroll-light or-alert-scroll " + `${newHeight > compareHeight ? " " : "scroll-hide"}`}> */}
                                                <div className={"custom-scroll-light " + `${compareHeight == null || (newHeight > compareHeight) ? " " : "scroll-hide"}`}>
                                                    <div className="custom-scroll">
                                                        <div id="alertDialogSetVerticalScroll">
                                                            <PanelGroup className="alert-panel-group" id="panel-group-alert">
                                                                <div className="alert-panel-hold" id="allAlertCollapsible">
                                                                    {this.state.addRemoveAlertable == true ?
                                                                        <Panel defaultExpanded eventKey="1">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{LocalizationStore.getTranslatedData("list_addAndRemove", "ADDITIONS AND REMOVALS")}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible >
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbAdditions" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.AdditionSelected} name="AdditionSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("list_additions", "Additions")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbRemovals" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.RemovalSelected} name="RemovalSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("list_removals", "Removals")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel> : null}
                                                                    {this.state.priceVolumeAlertable == true ?
                                                                        <Panel defaultExpanded eventKey="2">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{LocalizationStore.getTranslatedData("ch_msa_pav", "PRICE AND VOLUME")}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <ul>
                                                                                            {this.props.conditionTypeId == AlertConditionType.And && this.props.sourceTypeId == AlertSourceType.Stock ?
                                                                                                <li>
                                                                                                    <FormGroup id="TargetPriceGroup" controlId="formInlineName">
                                                                                                    <Checkbox id="rbTargetPrice" type="radio" onChange={this.handleCheckedChange} checked={this.state.TargetPriceSelected} name="TargetPriceSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("MetricLibraryCategory_Price", "Price")} {this.state.currencyCode ? this.state.currencyCode : this.props.SymbolInfo ? AlertStore.getCurrencyCode(this.props.SymbolInfo.DefaultCurrency) : ""} </div></Checkbox>
                                                                                                    <div className="input-text">
                                                                                                        <input type="number" id="txtTargetPrice" className="form-control disableDefaultSymbolInput" maxLength="100" onKeyUp={this.handleKeyUp}  onChange={this.handleValueChange} value={(this.state.TargetPrice)} name="TargetPrice" /></div>
                                                                                                    <small id="TargetPriceGroupErrorDiv" className={(this.state.validationMessages.TargetPrice ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.TargetPrice}</small>
                                                                                                </FormGroup>
                                                                                                </li> : null}
                                                                                            <li>
                                                                                                <FormGroup id="PriceChangeThresholdGroup" controlId="formInlineName">
                                                                                                    <Checkbox id="rbPriceChange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.PriceChangeSelected} name="PriceChangeSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_dpce", "Daily price change exceeds +/-")} </div></Checkbox>
                                                                                                    <div className="input-text  manage-ms-pad">
                                                                                                        <input type="number" id="txtDailypricechangeexceeds" className="form-control disableDefaultSymbolInput"   maxLength="3"  onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.state.listAlertSettings.PriceChangeThreshold} name="PriceChangeThreshold" /></div>
                                                                                                    <small id="PriceChangeThresholdErrorDiv" className={(this.state.validationMessages.PriceChangeThreshold ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.PriceChangeThreshold}</small>
                                                                                                    <div className="lbl-persent"><label>%</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="DailyVolumeRateexceedsGroup" controlId="formInlineName">
                                                                                                    <Checkbox id="rbDailyVolumeRate" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DailyVolumeRateSelected} name="DailyVolumeRateSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_dvre", "Daily volume rate exceeds")} </div></Checkbox>
                                                                                                    <div className="input-text"><input className="form-control disableDefaultSymbolInput" type="number" id="txtDailyVolumeRateexceeds" maxLength="3" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.state.listAlertSettings.DailyVolumeRateThreshold} name="DailyVolumeRateThreshold" /></div>
                                                                                                    <small id="DailyVolumeRateexceedsErrorDiv" className={(this.state.validationMessages.DailyVolumeRateThreshold ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.DailyVolumeRateThreshold}</small>
                                                                                                    <div className="lbl-persent"><label>%</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="DailyVolumeRateexceedsDelayGroup" className="fg-vol-rate-exceed" controlId="formInlineName">
                                                                                                    <Checkbox id="rbDailyVolumeRateDelay" disabled={!this.state.listAlertSettings.DailyVolumeRateSelected}
                                                                                                            onChange={this.handleCheckedChange}
                                                                                                            checked={this.state.listAlertSettings.DailyVolumeRateDelaySelected}
                                                                                                            name="DailyVolumeRateDelaySelected">
                                                                                                            <div className={this.state.listAlertSettings.DailyVolumeRateSelected ? "chk-box" : "chk-box disable "}>{LocalizationStore.getTranslatedData("set_pre_qs_del","Delay:")}</div>
                                                                                                    </Checkbox>
                                                                                                    <div className="lbl-persent">
                                                                                                        <label className={this.state.listAlertSettings.DailyVolumeRateSelected?"":"lbl-disable"}>{LocalizationStore.getTranslatedData("set_pre_qs_trigger"," minutes from")}</label>
                                                                                                    </div>
                                                                                                    <div className="input-text">
                                                                                                        {
                                                                                                            this.state.listAlertSettings.DailyVolumeRateSelected?
                                                                                                            <input className="form-control disableDefaultSymbolInput"
                                                                                                                    type="number"
                                                                                                                    id="txtDailyVolumeRateexceedsDelay"
                                                                                                                    maxLength="3"
                                                                                                                    onChange={this.handleValueChange}
                                                                                                                    value={this.state.listAlertSettings.DailyVolumeRateDelayThreshold}
                                                                                                                    name="DailyVolumeRateDelayThreshold" />
                                                                                                            :
                                                                                                            <input className="form-control disableDefaultSymbolInput"
                                                                                                                    type="number"
                                                                                                                    id="txtDailyVolumeRateexceedsDelay"
                                                                                                                    maxLength="3"
                                                                                                                    onChange={this.handleValueChange}
                                                                                                                    readOnly="readOnly"
                                                                                                                    value={this.state.listAlertSettings.DailyVolumeRateDelayThreshold}
                                                                                                                    name="DailyVolumeRateDelayThreshold" />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <small id="DailyVolumeRateexceedsDelayErrorDiv"
                                                                                                        className={(this.state.validationMessages.DailyVolumeRateDelayThreshold ? ' text-danger error-message-alert' : ' text-danger')}>
                                                                                                        {this.state.validationMessages.DailyVolumeRateDelayThreshold}
                                                                                                    </small>
                                                                                                    <div className="lbl-persent">
                                                                                                        <label className={this.state.listAlertSettings.DailyVolumeRateSelected?"":"lbl-disable"}>{LocalizationStore.getTranslatedData("set_pre_qs_mfs"," start of trading day")}</label>
                                                                                                    </div>

                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="AverageDailyVolumecrossesGroup" className="" controlId="formInlineName ">
                                                                                                    <Checkbox id="rbAverageDlyVol" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.AverageDlyVolSelected} name="AverageDlyVolSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_advcs", "Average daily volume crosses")} </div></Checkbox>
                                                                                                    <div className="input-text"><input className="form-control disableDefaultSymbolInput" type="number" id="txtAverageDailyVolumecrosses" maxLength="9" onKeyUp={this.handleKeyUp}  onChange={this.handleValueChange} value={this.state.listAlertSettings.AverageDlyVolThreshold} name="AverageDlyVolThreshold" /></div>
                                                                                                    <small id="AverageDailyVolumecrossesErrorDiv" className={(this.state.validationMessages.AverageDlyVolThreshold ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.AverageDlyVolThreshold}</small>
                                                                                                    <div className="lbl-persent"><label>(000) {LocalizationStore.getTranslatedData("sym_shrs", "shares")}</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="AverageDailyDlrVolumecrossesGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbAverageDlyDlrVol" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.AverageDlyDlrVolSelected} name="AverageDlyDlrVolSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_advdc", "Average daily $ volume, USD crosses $")} </div></Checkbox>
                                                                                                    <div className="input-text"><input className="form-control disableDefaultSymbolInput" type="number" id="txtAverageDailyDlrVolumecrosses" maxLength="9" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.state.listAlertSettings.AverageDlyDlrVolThreshold} name="AverageDlyDlrVolThreshold" /></div>
                                                                                                    <small id="AverageDailyDlrVolumecrossesErrorDiv" className={(this.state.validationMessages.AverageDlyDlrVolThreshold ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.AverageDlyDlrVolThreshold}</small>
                                                                                                    <div className="lbl-persent"><label>(000)</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="DailyVolumeExceeds50SMAGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbDailyVolumeExceeds50SMA" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DailyVolumeExceeds50SMASelected} name="DailyVolumeExceeds50SMASelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_dv","Daily volume exceeds 50 SMA")} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="BreakingOutToAllTimeHighGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbAverageDlyDlrVol" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.BreakingOutToAllTimeHighSelected} name="BreakingOutToAllTimeHighSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_bo","Breaking out to all-time high")} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="RSReachesNewHighGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbRSReachesNewHigh" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.RSReachesNewHighSelected} name="RSReachesNewHighSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_rs","Relative Strength Line reaches new 52-week high (based on default index)")} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            {this.state.corporateEventAlertable == true ? <li>
                                                                                                <FormGroup id="RSGapUpOnEarnings" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbGapUpOnEarnings" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.GapUpOnEarningsSelected} name="GapUpOnEarningsSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_gu","Gap up on earnings (U.S. only)")} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> : null}
                                                                                            {this.state.corporateEventAlertable == true ? <li>
                                                                                                <FormGroup id="GapDownOnEarnings" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbGapDownOnEarnings" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.GapDownOnEarningsSelected} name="GapDownOnEarningsSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_gd","Gap down on earnings (U.S. only)")} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> : null}
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel> : null}
                                                                    <Panel defaultExpanded eventKey="3">
                                                                        <Panel.Heading>
                                                                            <Panel.Title toggle onClick={ this.checkScrolling}>{LocalizationStore.getTranslatedData("ch_msa_ma", "MOVING AVERAGES")}</Panel.Title>
                                                                        </Panel.Heading>
                                                                        <Panel.Body collapsible>
                                                                            <Form inline>
                                                                                <div className="alert-panel-block">
                                                                                    <ul>
                                                                                        {this.state.sourceTypeId == AlertSourceType.List ?
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbMovingAvg50Sma" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.MovingAvg50SmaSelected} name="MovingAvg50SmaSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("alert_list_pc50", "Price crosses 50 SMA (daily)")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> : null}
                                                                                        {this.state.sourceTypeId == AlertSourceType.List ?
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbMovingAvg200Sma" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.MovingAvg200SmaSelected} name="MovingAvg200SmaSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("alert_list_pc200", "Price crosses 200 SMA (daily)")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> : null}
                                                                                        <li>
                                                                                            <FormGroup controlId="formInlineName">
                                                                                                <Checkbox id="rbMovingAvg50Cross200Sma" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.MovingAvg50Cross200Selected} name="MovingAvg50Cross200Selected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_50200x", "50 SMA - 200 SMA crossover (daily)")}</div></Checkbox>
                                                                                            </FormGroup>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </Form>
                                                                        </Panel.Body>
                                                                    </Panel>
                                                                    {(this.state.prEntitled == true && this.getAlertEntitledBySymbolType(this.state.symbolType).prEntitled) ?
                                                                        <Panel defaultExpanded eventKey="4">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={ this.checkScrolling}>{LocalizationStore.getTranslatedData("ch_msa_pr", "PATTERN RECOGNITION")}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <Table striped className="grids">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className="w70">{LocalizationStore.getTranslatedData("ch_msa_base", "BASE")}</th>
                                                                                                    <th className="w15">{LocalizationStore.getTranslatedData("ch_dly", "Daily")}</th>
                                                                                                    <th className="w15">{LocalizationStore.getTranslatedData("ch_wkly", "Weekly")}</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_nbd", "New base detected")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyBase" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DailyBaseChecked} name="DailyBaseChecked"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyBase" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.WeeklyBaseChecked} name="WeeklyBaseChecked"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_tad", "Tight area detected")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyTight" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.WeeklyTightChecked} name="WeeklyTightChecked"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_fbd", "Failed breakout detected")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyFailedBase" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.WeeklyFailedBaseChecked} name="WeeklyFailedBaseChecked"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {this.state.corporateEventAlertable == true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_50","50-day break on volume (U.S Only) ")} <span title={"Stock must have broken out from base and established 50-day moving average. Alert triggers after breakout when stock price falls below 50-day moving average and daily volume rate is 50% or greater."}>{"(i)"}</span></div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyDay50BreakOnVolume" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.Day50BreakOnVolumeSelected} name="Day50BreakOnVolumeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_pivot", "PIVOT")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup id="PricerisestowithinofpivotGroup" className="" controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_prwp", "Price rises to within")} </div>
                                                                                                            <div className="input-text"><input type="number" className="form-control disableDefaultSymbolInput" id="txtPricerisestowithinofpivot" maxLength="3" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.state.listAlertSettings.AlertPriceRises} name="AlertPriceRises" /></div>
                                                                                                            <small id="PricerisestowithinofpivotErrorDiv" className={(this.state.validationMessages.AlertPriceRises ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.AlertPriceRises}</small>
                                                                                                            <div className="chk-box"> {LocalizationStore.getTranslatedData("ch_msa_prwp_op", "% of pivot")}</div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyPriceRises" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DailyPriceRisesSelected} name="DailyPriceRisesSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyPriceRises" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.WeeklyPriceRisesSelected} name="WeeklyPriceRisesSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_pcop", "Price crosses over pivot (breakout)")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyPriceCrosses" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DailyPriceCrossesSelected} name="DailyPriceCrossesSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyPriceCrosses" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.WeeklyPriceCrossesSelected} name="WeeklyPriceCrossesSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_break","Breakaway Gap")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbBreakawayGap" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.BreakawayGapSelected} name="BreakawayGapSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                        <div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_pt","Pullback to 10-week line")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbPullbackTo10WeekLine" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.PullbackTo10WeekLineSelected} name="PullbackTo10WeekLineSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {this.state.sourceTypeId == AlertSourceType.Stock && this.state.prEntitled == true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_key","KEY PRICE RANGE")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                                {this.state.sourceTypeId == AlertSourceType.Stock && this.state.prEntitled == true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_eoe_profit","Enter or exit profit range")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyProfitRange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DailyEnterExitProfitRangeSelected} name="DailyEnterExitProfitRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyProfitRange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.WeeklyEnterExitProfitRangeSelected} name="WeeklyEnterExitProfitRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                                {this.state.sourceTypeId == AlertSourceType.Stock && this.state.prEntitled == true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_eoe_pivot","Enter or exit pivot range")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyPivotRange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DailyEnterExitPivotRangeSelected} name="DailyEnterExitPivotRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyPivotRange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.WeeklyEnterExitPivotRangeSelected} name="WeeklyEnterExitPivotRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                                {this.state.sourceTypeId == AlertSourceType.Stock && this.state.prEntitled == true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{LocalizationStore.getTranslatedData("ch_eoe_loss","Enter or exit loss range")}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyLossRange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DailyEnterExitLossRangeSelected} name="DailyEnterExitLossRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyLossRange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.WeeklyEnterExitLossRangeSelected} name="WeeklyEnterExitLossRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel> : null}
                                                                    {this.state.sourceTypeId == AlertSourceType.List ?
                                                                        <Panel defaultExpanded eventKey="6">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{LocalizationStore.getTranslatedData("ch_msa_nas","NEGATIVE ALERT SCORE")}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName" id="NegativeAlter1WeekGroup" className="" >
                                                                                                    <Checkbox id="rbNegativeAlter1Week" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.NegativeAlter1WeekSelected} name="NegativeAlter1WeekSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("MetricLibraryCategory_NegativeAlertscore,1Week","Negative Alert Score, 1 Week")} >=</div></Checkbox>
                                                                                                    <div className="input-text"><input type="number" className="form-control disableDefaultSymbolInput" id="txtNegativeAlter1Week" maxLength="2" onKeyUp={this.handleKeyUp}  onChange={this.handleValueChange} value={this.state.listAlertSettings.NegativeAlter1Week} name="NegativeAlter1Week" /></div>
                                                                                                    <small id="NegativeAlter1WeekErrorDiv" className={(this.state.validationMessages.NegativeAlter1Week ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.NegativeAlter1Week}</small>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName" id="NegativeAlter10WeekGroup" className="">
                                                                                                    <Checkbox id="rbNegativeAlter1Week" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.NegativeAlter10WeekSelected} name="NegativeAlter10WeekSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("MetricLibraryCategory_NegativeAlertscore,10Weeks","Negative Alert Score, 10 Week")} >=</div></Checkbox>
                                                                                                    <div className="input-text"><input type="number" className="form-control disableDefaultSymbolInput" id="txtNegativeAlter10Week" maxLength="3" onKeyUp={this.handleKeyUp}  onChange={this.handleValueChange} value={this.state.listAlertSettings.NegativeAlter10Week} name="NegativeAlter10Week" /></div>
                                                                                                    <small id="NegativeAlter10WeekErrorDiv" className={(this.state.validationMessages.NegativeAlter10Week ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.NegativeAlter10Week}</small>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel> : null}
                                                                    {(this.state.corporateEventAlertable == true && this.getAlertEntitledBySymbolType(this.state.symbolType).corporateEventAlertable) ?
                                                                        <Panel defaultExpanded eventKey="5">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{LocalizationStore.getTranslatedData("ch_msa_ceuo", "CORPORATE EVENTS (U.S. ONLY)")}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbBuybackAnnouncement" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.BuybackAnnouncementSelected} name="BuybackAnnouncementSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_ba", "Buyback announcement")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbStockSplit" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.StockSplitSelected} name="StockSplitSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_ss", "Stock split")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbCeoChange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.CeoChangeSelected} name="CeoChangeSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_ceo", "CEO change")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="EarningsreleaseiswithinGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbEarningsRelease" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.EarningsReleaseSelected} name="EarningsReleaseSelected"><div className="chk-box"> {LocalizationStore.getTranslatedData("ch_msa_er_1", "Earnings release is in")} </div></Checkbox>
                                                                                                    <div className="input-text"><input className="form-control disableDefaultSymbolInput" type="number" id="txtEarningsreleaseiswithin" maxLength="3" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.state.listAlertSettings.EarningsReleaseDays} name="EarningsReleaseDays" /></div>
                                                                                                    <small id="EarningsreleaseiswithinErrorDiv" className={(this.state.validationMessages.EarningsReleaseDays ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.EarningsReleaseDays}</small>
                                                                                                    <div className="lbl-persent"><label>{LocalizationStore.getTranslatedData("ch_msa_er_2", "days")}</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbDividendChange" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.DividendChangeSelected} name="DividendChangeSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_dc", "Dividend change")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            {/* <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbInsiderPurchases" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.InsiderPurchasesSelected} name="InsiderPurchasesSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_it", "Insider transactions")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> */}
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbInsiderTransactionsBuys" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.InsiderTransactionsBuysSelected} name="InsiderTransactionsBuysSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_buy","Insiders transactions - Buys")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbInsiderTransactionsSells" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.InsiderTransactionsSellsSelected} name="InsiderTransactionsSellsSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_sup_sell","Insiders transactions - Sells")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbAnnouncements" type="radio" onChange={this.handleCheckedChange} checked={this.state.listAlertSettings.AnnouncementsSelected} name="AnnouncementsSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_ce_anncmt", "Announcements")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel> : null}
                                                                </div>
                                                                </PanelGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ScrollBar scrollId="alertDialogSetVerticalScroll" vScroll={true} />
                                        </div>
                                    </div>
                                </Modal.Body>

                                {this.props.conditionTypeId == AlertConditionType.Or && this.state.sourceTypeId == AlertSourceType.List && !this.state.showLoader ?
                                    <Modal.Body>
                                        <div className="dialog-content">
                                            <div className="flexbox-parent">
                                                <div className="flexbox-item header">
                                                    {LocalizationStore.getTranslatedData("alert_ColumnSetDes", "Use the Column Set and Geography buttons to exclude unwanted symbols from List Alerts.")}
                                                </div>
                                                <div className="model-action-grid">
                                                    <Row className="show-grid">
                                                        <Col xs={6}>
                                                            <FormGroup>
                                                                <ControlLabel>{LocalizationStore.getTranslatedData("LM_TP_ColumnSet", "Column Set")}</ControlLabel>
                                                            </FormGroup>
                                                            <div className="model-action-popup-btn" title={this.state.columnSetString=="No Column Set" ? LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set"): LocalizationStore.getTranslatedData("ColumnSet_"+this.state.columnSetString, this.state.columnSetString)} onClick={this.openColumnSetWindow}  >{this.state.columnSetString=="No Column Set" ? LocalizationStore.getTranslatedData("alert_NoColumnSet","No Column Set"): LocalizationStore.getTranslatedData("ColumnSet_"+this.state.columnSetString, this.state.columnSetString)}</div>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <FormGroup>
                                                                <ControlLabel>{LocalizationStore.getTranslatedData("alert_Geography", "Geography")}</ControlLabel>
                                                            </FormGroup>
                                                            <div className="model-action-popup-btn" title={this.state.browserFilterText} onClick={this.openGeographyWindow} >{this.state.browserFilterText}</div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body> : null}

                                {this.props.conditionTypeId == AlertConditionType.Or && !this.state.showLoader ?
                                    <Modal.Footer>
                                        <span className="anchor-link" onClick={this.onNotificationPreferenceClick}>{LocalizationStore.getTranslatedData("set_apre", "Alert Preferences")} </span>
                                        <span className="pull-right btn-box">
                                            <button type="button" className="model-action btn btn-xs btn-default btn-secondary pull-left small-bold " data-effect="fadeOut" onClick={this.closeDialog}>{LocalizationStore.getTranslatedData("set_cancel", "Cancel")}</button>
                                            <button type="button" id="btnSaveAlert" className="model-action btn btn-xs btn-default btn-secondary pull-left small-bold " data-effect="fadeOut" onClick={this.saveAlertData}>{LocalizationStore.getTranslatedData("set_ws_sv", "Save")}</button>
                                        </span>
                                    </Modal.Footer> : null}
                                {/* </Modal> */}
                            </FlexibleModal>
                        </section>
                    }
                    <NotificationContentContainer open={this.state.isNCOpen} hideModal={this.hideModal} showBackButton={false} />
                </section>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers })=>{
    const { SymbolInfo } = DatagraphReducers.DataGraphReducer;
    return { SymbolInfo };
}

export default connect(mapStateToProps)(AlertsDialog)