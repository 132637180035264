import { AlertConstants } from "../../Constants/AlertConstants";
import { AnnotationConstant } from "../../Constants/AnnotationConstants";
import AnnotationUtil from '../../Utils/AnnotationUtil';
import DatagraphHelper from "../../Utils/DatagraphHelper";
import SettingsStore from "SettingsStore";
import stringUtil from '../../Utils/StringUtil';
import { AnnotationMenuStates, LineModelInfo, TextModelInfo, ShapesModelInfo, ChannelLineModelInfo, FibonacciModelInfo, MeasurementModelInfo } from '../../Models/AnnotationModels/AnnotationMenuStates';
import { extend, each } from "underscore";
import { RayAnnotationType, StrokeStyleType } from '../../Constants/EAnnotationType';

const { ActionTypes } = AnnotationConstant;

const initialState = new AnnotationMenuStates();
extend(initialState, { LineModelInfo: new LineModelInfo(), TextModelInfo: new TextModelInfo(), ShapesModelInfo: new ShapesModelInfo(), ChannelLineModelInfo: new ChannelLineModelInfo(), FibonacciModelInfo: new FibonacciModelInfo(), MeasurementModelInfo: new MeasurementModelInfo() })

const updateToSettings = (settingsObj, stateObj) => {

    each(stateObj, (value, key) => {
        if (settingsObj[key] !== undefined) {
            settingsObj[key] = value;
        }
    });

    SettingsStore.saveSettings();
}

const annotationMenuReducer = (state = initialState, action) => {

    const settings = SettingsStore.getConsoleSettings();
    let LineSettings, TextSettings, ShapeSettings, ChannelLineSettings, FibonacciSettings, MeasurementSettings;
    let showAlert = true;
    if (settings) {
        const AnnotationSettings = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;
        if (AnnotationSettings) {
            LineSettings = AnnotationSettings.LineSettings;
            TextSettings = AnnotationSettings.TextSettings;
            ShapeSettings = AnnotationSettings.ShapeSettings;
            ChannelLineSettings = AnnotationSettings.ChannelLineSettings;
            FibonacciSettings = AnnotationSettings.FibonacciSettings;
            MeasurementSettings = AnnotationSettings.MeasurementSettings;
        }
        showAlert = settings.UserNotificationSettings.IsShowAlertsIcon;
    }

    switch (action.type) {
        case ActionTypes.UPDATE_STATE_FROM_SETTINGS:
            AnnotationUtil.setIsPointerToolActive(action.selectedToolType === 0);
            return {
                ...state,
                selectedToolType: action.selectedToolType,
                tempToolType: !action.isCtrl ? action.selectedToolType : state.tempToolType,
                mouseMoved: false,
                showAlert: showAlert,
                riPanelToggle: false,
                LineModelInfo: {
                    ...state.LineModelInfo,
                    weight: action.LineSettings.weight,
                    color: action.LineSettings.color,
                    opacity: action.LineSettings.opacity,
                    strokeStyle: action.LineSettings.strokeStyle,
                    stroke: action.LineSettings.stroke,
                    startArrowChecked: action.LineSettings.startArrowChecked,
                    startExtendChecked: action.LineSettings.startExtendChecked,
                    endExtendChecked: action.LineSettings.endExtendChecked,
                    endArrowChecked: action.LineSettings.endArrowChecked,
                    isSnappingChecked: action.LineSettings.isSnappingChecked,
                    isAlertChecked: action.LineSettings.isAlertChecked,
                    isTemporaryChecked: action.LineSettings.isTemporaryChecked,
                    lineX1: action.LineSettings.startExtendChecked ? 0 : 50,
                    lineX2: action.LineSettings.endExtendChecked ? 266 : 220,
                    startPolygonPoints: action.LineSettings.startArrowChecked ? action.LineSettings.startExtendChecked ? '12,35 12,25 0,30' : '50,35 50,25 42,30' : null,
                    endPolygonPoints: action.LineSettings.endArrowChecked ? action.LineSettings.endExtendChecked ? '244,35 244,25 256,30' : '220,35 220,25 228,30' : null,
                },
                TextModelInfo: {
                    ...state.TextModelInfo,
                    color: action.TextSettings.color,
                    borderColor: action.TextSettings.borderColor,
                    bgColor: action.TextSettings.bgColor,
                    borderWeight: action.TextSettings.borderWeight,
                    borderStroke: action.TextSettings.borderStroke,
                    strokeStyle: action.TextSettings.strokeStyle,
                    opacity: action.TextSettings.opacity,
                    isBorderChecked: action.TextSettings.isBorderChecked,
                    fontStyle: action.TextSettings.fontStyle,
                    fontSize: action.TextSettings.fontSize,
                    isBold: action.TextSettings.isBold,
                    isItalic: action.TextSettings.isItalic,
                    isUnderline: action.TextSettings.isUnderline,
                    textAlign: action.TextSettings.textAlign
                },
                ShapesModelInfo: {
                    ...state.ShapesModelInfo,
                    bgColor: action.ShapeSettings.bgColor,
                    borderColor: action.ShapeSettings.borderColor,
                    borderWeight: action.ShapeSettings.borderWeight,
                    borderStroke: action.ShapeSettings.borderStroke,
                    strokeStyle: action.ShapeSettings.strokeStyle,
                    opacity: action.ShapeSettings.opacity,
                    isBorderChecked: action.ShapeSettings.isBorderChecked,
                    selectedType: action.ShapeSettings.selectedType,
                },
                ChannelLineModelInfo: {
                    ...state.ChannelLineModelInfo,
                    weight: action.ChannelLineSettings.weight,
                    color: action.ChannelLineSettings.color,
                    stroke: action.ChannelLineSettings.stroke,
                    strokeStyle: action.ChannelLineSettings.strokeStyle,
                    startExtendChecked: action.ChannelLineSettings.startExtendChecked,
                    endExtendChecked: action.ChannelLineSettings.endExtendChecked,
                    lineX1: action.ChannelLineSettings.startExtendChecked ? 0 : 50,
                    lineX2: action.ChannelLineSettings.endExtendChecked ? 266 : 220
                },
                FibonacciModelInfo: {
                    ...state.FibonacciModelInfo,
                    color: action.FibonacciSettings.color,
                    weight: action.FibonacciSettings.weight,
                    isLevelLabelsChecked: action.FibonacciSettings.isLevelLabelsChecked,
                    isPriceLabelsChecked: action.FibonacciSettings.isPriceLabelsChecked,
                    startExtendChecked: action.FibonacciSettings.startExtendChecked,
                    isExtensionsChecked: action.FibonacciSettings.isExtensionsChecked,
                    endExtendChecked: action.FibonacciSettings.endExtendChecked,
                    extensionLineColor: action.FibonacciSettings.extensionLineColor,
                    extensionLineWeight: action.FibonacciSettings.extensionLineWeight,
                    upperLineX1: action.FibonacciSettings.startExtendChecked ? 0 : 40,
                    upperRetracementLineX1: action.FibonacciSettings.startExtendChecked ? 0 : 40,
                    lowerRetracementLineX1: action.FibonacciSettings.startExtendChecked ? 0 : 40,
                    lowerLineX1: action.FibonacciSettings.startExtendChecked ? 0 : 40,
                    upperLineX2: action.FibonacciSettings.endExtendChecked ? 266 : 220,
                    upperRetracementLineX2: action.FibonacciSettings.endExtendChecked ? 266 : 220,
                    lowerRetracementLineX2: action.FibonacciSettings.endExtendChecked ? 266 : 220,
                    lowerLineX2: action.FibonacciSettings.endExtendChecked ? 266 : 220
                },
                MeasurementModelInfo: {
                    ...state.MeasurementModelInfo,
                    color: action.MeasurementSettings.color,
                    isLeaderlinesChecked: action.MeasurementSettings.isLeaderlinesChecked,
                }
            }
        case ActionTypes.IS_SELECTED_ANNOTATION:
            return {
                ...state,
                isSelected: action.data,
            }
        case ActionTypes.HANDLE_TOOL_CHANGE:
            if (AnnotationUtil.isAnnotationsTabActive() || AnnotationUtil.isCtrlKeyMode || action.isCtrl) {
                const annotationSettings = DatagraphHelper.getSettingsObject(settings, settings.NavDatagraphSettings.RelatedInformationSettings).AnnotationSettings;

                const annotationDiv = document.getElementsByClassName('annotation-graphics')[0];

                if (AnnotationUtil.isAnnotationsTabActive() && action.selectedToolType === 0) {
                    if (!stringUtil.isEmpty(annotationDiv) && !annotationDiv.classList.contains("selected-selectiontool")) {
                        annotationDiv.className += " selected-selectiontool";
                        annotationDiv.style.zIndex = "";
                    }
                    AnnotationUtil.setIsPointerToolActive(true);
                }
                else {
                    if (!stringUtil.isEmpty(annotationDiv)) {
                        annotationDiv.className = "annotation-graphics";
                        annotationDiv.style.zIndex = 10;
                    }
                    AnnotationUtil.setIsPointerToolActive(false);
                }
                if (action.isCtrl) {
                    annotationSettings.selectedToolType = action.selectedToolType;
                    return {
                        ...state,
                        tempToolType: state.tempToolType,
                        selectedToolType: action.selectedToolType
                    }
                } else {
                    if (action.riPanelClick) {
                        annotationSettings.selectedToolType = action.selectedToolType;
                        SettingsStore.saveSettings();
                    }
                    return {
                        ...state,
                        selectedToolType: action.selectedToolType
                    }
                }
            }
            else {
                return { ...state }
            }
        case ActionTypes.HANDLE_COLOR_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.LINE_AnnotationType:
                    state.LineModelInfo.color = action.color;
                    updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };

                case RayAnnotationType.TEXT_AnnotationType:
                    state.TextModelInfo.color = action.color;
                    updateToSettings(TextSettings, state.TextModelInfo);
                    return { ...state };

                case RayAnnotationType.PARALLEL_LINES_AnnotationType:
                    state.ChannelLineModelInfo.color = action.color;
                    updateToSettings(ChannelLineSettings, state.ChannelLineModelInfo);
                    return { ...state };

                case RayAnnotationType.FIB_AnnotationType:
                    state.FibonacciModelInfo.color = action.color;
                    updateToSettings(FibonacciSettings, state.FibonacciModelInfo);
                    return { ...state };

                case RayAnnotationType.MEASUREMENT_AnnotationType:

                    state.MeasurementModelInfo.color = action.color;
                    updateToSettings(MeasurementSettings, state.MeasurementModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_BORDER_COLOR_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.TEXT_AnnotationType:
                    state.TextModelInfo.borderColor = action.color;
                    updateToSettings(TextSettings, state.TextModelInfo);
                    return { ...state };

                case RayAnnotationType.RECT_AnnotationType:
                case RayAnnotationType.ELLIPSE_AnnotationType:
                case RayAnnotationType.TRIANGLE_AnnotationType:
                    state.ShapesModelInfo.borderColor = action.color;
                    updateToSettings(ShapeSettings, state.ShapesModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_WEIGHT_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.LINE_AnnotationType:
                    state.LineModelInfo.weight = action.weight;
                    state.LineModelInfo.stroke = state.LineModelInfo.strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? [action.weight * 2.5, action.weight] : state.LineModelInfo.strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType ? [action.weight, action.weight] : state.LineModelInfo.stroke;
                    updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };

                case RayAnnotationType.PARALLEL_LINES_AnnotationType: {
                    const stroke = state.ChannelLineModelInfo.strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? [action.weight * 2.5, action.weight] : state.ChannelLineModelInfo.strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType ? [action.weight, action.weight] : state.ChannelLineModelInfo.stroke;
                    state.ChannelLineModelInfo.weight = action.weight;
                    state.ChannelLineModelInfo.stroke = stroke;
                    updateToSettings(ChannelLineSettings, state.ChannelLineModelInfo);
                    return { ...state };
                }

                case RayAnnotationType.FIB_AnnotationType:
                    state.FibonacciModelInfo.weight = action.weight;
                    updateToSettings(FibonacciSettings, state.FibonacciModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_BORDER_WEIGHT_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.TEXT_AnnotationType: {
                    const borderStroke = state.TextModelInfo.strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? [action.weight * 2.5, action.weight] : state.TextModelInfo.strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType ? [action.weight, action.weight] : state.TextModelInfo.borderStroke;
                    state.TextModelInfo.borderWeight = action.weight;
                    state.TextModelInfo.borderStroke = borderStroke;
                    updateToSettings(TextSettings, state.TextModelInfo);
                    return { ...state };
                }

                case RayAnnotationType.RECT_AnnotationType:
                case RayAnnotationType.ELLIPSE_AnnotationType:
                case RayAnnotationType.TRIANGLE_AnnotationType: {
                    const shapeBorderStroke = state.ShapesModelInfo.strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? [action.weight * 2.5, action.weight] : state.ShapesModelInfo.strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType ? [action.weight, action.weight] : state.ShapesModelInfo.borderStroke
                    state.ShapesModelInfo.borderWeight = action.weight;
                    state.ShapesModelInfo.borderStroke = shapeBorderStroke;
                    updateToSettings(ShapeSettings, state.ShapesModelInfo);
                    return { ...state };
                }

                default:
                    return state;
            }
        case ActionTypes.HANDLE_STROKE_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.LINE_AnnotationType:
                    state.LineModelInfo.stroke = action.stroke;
                    state.LineModelInfo.strokeStyle = action.strokeStyle;
                    updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };

                case RayAnnotationType.PARALLEL_LINES_AnnotationType:
                    state.ChannelLineModelInfo.stroke = action.stroke;
                    state.ChannelLineModelInfo.strokeStyle = action.strokeStyle;
                    updateToSettings(ChannelLineSettings, state.ChannelLineModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_BORDER_STROKE_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.TEXT_AnnotationType:
                    state.TextModelInfo.borderStroke = action.stroke;
                    state.TextModelInfo.strokeStyle = action.strokeStyle;
                    updateToSettings(TextSettings, state.TextModelInfo);
                    return { ...state };

                case RayAnnotationType.RECT_AnnotationType:
                case RayAnnotationType.ELLIPSE_AnnotationType:
                case RayAnnotationType.TRIANGLE_AnnotationType:
                    state.ShapesModelInfo.borderStroke = action.stroke;
                    state.ShapesModelInfo.strokeStyle = action.strokeStyle;
                    updateToSettings(ShapeSettings, state.ShapesModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_START_ARROW_CHANGE:
            state.LineModelInfo.startArrowChecked = action.startArrowChecked;
            state.LineModelInfo.lineX1 = action.lineX1;
            state.LineModelInfo.startPolygonPoints = action.startPolygonPoints;
            updateToSettings(LineSettings, state.LineModelInfo);
            return { ...state };

        case ActionTypes.HANDLE_END_ARROW_CHANGE:
            state.LineModelInfo.endArrowChecked = action.endArrowChecked;
            state.LineModelInfo.lineX2 = action.lineX2;
            state.LineModelInfo.endPolygonPoints = action.endPolygonPoints;
            updateToSettings(LineSettings, state.LineModelInfo);
            return { ...state };

        case ActionTypes.HANDLE_ALERT_END_EXTEND_CHANGE:
            state.LineModelInfo.endExtendChecked = true;
            return { ...state };

        case ActionTypes.HANDLE_START_EXTEND_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.LINE_AnnotationType:
                    state.LineModelInfo.startExtendChecked = action.startExtendChecked;
                    state.LineModelInfo.lineX1 = action.lineX1;
                    state.LineModelInfo.startPolygonPoints = action.startPolygonPoints;
                    updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };

                case RayAnnotationType.PARALLEL_LINES_AnnotationType:
                    state.ChannelLineModelInfo.startExtendChecked = action.startExtendChecked;
                    state.ChannelLineModelInfo.lineX1 = action.lineX1;
                    updateToSettings(ChannelLineSettings, state.ChannelLineModelInfo);
                    return { ...state };

                case RayAnnotationType.FIB_AnnotationType:
                    state.FibonacciModelInfo.startExtendChecked = action.startExtendChecked;
                    state.FibonacciModelInfo.upperLineX1 = action.upperLineX1;
                    state.FibonacciModelInfo.lowerLineX1 = action.lowerLineX1;
                    state.FibonacciModelInfo.upperRetracementLineX1 = action.upperRetracementLineX1;
                    state.FibonacciModelInfo.lowerRetracementLineX1 = action.lowerRetracementLineX1;
                    updateToSettings(FibonacciSettings, state.FibonacciModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_END_EXTEND_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.LINE_AnnotationType:
                    state.LineModelInfo.endExtendChecked = action.endExtendChecked;
                    state.LineModelInfo.lineX2 = action.lineX2;
                    state.LineModelInfo.endPolygonPoints = action.endPolygonPoints;
                    updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };

                case RayAnnotationType.PARALLEL_LINES_AnnotationType:
                    state.ChannelLineModelInfo.endExtendChecked = action.endExtendChecked;
                    state.ChannelLineModelInfo.lineX2 = action.lineX2;
                    updateToSettings(ChannelLineSettings, state.ChannelLineModelInfo);
                    return { ...state };

                case RayAnnotationType.FIB_AnnotationType:
                    state.FibonacciModelInfo.endExtendChecked = action.endExtendChecked;
                    state.FibonacciModelInfo.upperLineX2 = action.upperLineX2;
                    state.FibonacciModelInfo.lowerLineX2 = action.lowerLineX2;
                    state.FibonacciModelInfo.upperRetracementLineX2 = action.upperRetracementLineX2;
                    state.FibonacciModelInfo.lowerRetracementLineX2 = action.lowerRetracementLineX2;
                    updateToSettings(FibonacciSettings, state.FibonacciModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_CHECKBOX_CHANGE:
            switch (action.event) {
                case 'alert': {
                    const isAlertCheckBoxChecked = action.newState;
                    const isEndExtendCheckBoxChecked = isAlertCheckBoxChecked ? true : state.LineModelInfo.endExtendChecked;

                    state.LineModelInfo.isAlertChecked = isAlertCheckBoxChecked;
                    state.LineModelInfo.endExtendChecked = isEndExtendCheckBoxChecked;
                    state.LineModelInfo.lineX2 = isEndExtendCheckBoxChecked ? 266 : 220;
                    state.LineModelInfo.endPolygonPoints = state.LineModelInfo.endArrowChecked ? isEndExtendCheckBoxChecked ? '244,35 244,25 256,30' : '220,35 220,25 228,30' : null;
                    if (action.newState === true) {
                        state.LineModelInfo.isTemporaryChecked = false;
                    }
                    updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };
                }

                case 'snap':
                    state.LineModelInfo[action.property] = action.newState;
                    updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };
                case 'temporary':
                    state.LineModelInfo[action.property] = action.newState;
                    if (action.newState === true) {
                        state.LineModelInfo.isAlertChecked = false;
                    }
                    updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };

                case 'border':
                    switch (state.selectedToolType) {
                        case RayAnnotationType.TEXT_AnnotationType:
                            state.TextModelInfo.isBorderChecked = !state.TextModelInfo.isBorderChecked;
                            updateToSettings(TextSettings, state.TextModelInfo);
                            return { ...state };

                        case RayAnnotationType.RECT_AnnotationType:
                        case RayAnnotationType.ELLIPSE_AnnotationType:
                        case RayAnnotationType.TRIANGLE_AnnotationType:
                            state.ShapesModelInfo.isBorderChecked = !state.ShapesModelInfo.isBorderChecked;
                            updateToSettings(ShapeSettings, state.ShapesModelInfo);
                            return { ...state };
                        default:
                            return state;
                    }
                case 'levelLabels':
                case 'priceLabels':
                case 'extensions':
                    state.FibonacciModelInfo[action.property] = action.newState;
                    updateToSettings(FibonacciSettings, state.FibonacciModelInfo);
                    return { ...state };

                case 'leaderlines':
                    state.MeasurementModelInfo[action.property] = action.newState;
                    updateToSettings(MeasurementSettings, state.MeasurementModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_OPACITY_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.LINE_AnnotationType:
                    state.LineModelInfo.opacity = action.value;
                    action.shouldSave && updateToSettings(LineSettings, state.LineModelInfo);
                    return { ...state };

                case RayAnnotationType.TEXT_AnnotationType:
                    state.TextModelInfo.opacity = action.value;
                    action.shouldSave && updateToSettings(TextSettings, state.TextModelInfo);
                    return { ...state };

                case RayAnnotationType.RECT_AnnotationType:
                case RayAnnotationType.ELLIPSE_AnnotationType:
                case RayAnnotationType.TRIANGLE_AnnotationType:
                    state.ShapesModelInfo.opacity = action.value;
                    action.shouldSave && updateToSettings(ShapeSettings, state.ShapesModelInfo);
                    return { ...state };

                default:
                    return state;
            }
        case ActionTypes.HANDLE_BG_COLOR_CHANGE:
            switch (state.selectedToolType) {
                case RayAnnotationType.TEXT_AnnotationType:
                    state.TextModelInfo.bgColor = action.color;
                    updateToSettings(TextSettings, state.TextModelInfo);
                    return { ...state };

                case RayAnnotationType.RECT_AnnotationType:
                case RayAnnotationType.ELLIPSE_AnnotationType:
                case RayAnnotationType.TRIANGLE_AnnotationType:
                    state.ShapesModelInfo.bgColor = action.color;
                    updateToSettings(ShapeSettings, state.ShapesModelInfo);
                    return { ...state };
                default:
                    return state;
            }
        case ActionTypes.HANDLE_EXTENSION_COLOR_CHANGE:
            state.FibonacciModelInfo.extensionLineColor = action.color;
            updateToSettings(FibonacciSettings, state.FibonacciModelInfo);
            return { ...state };

        case ActionTypes.HANDLE_EXTENSION_WEIGHT_CHANGE:
            state.FibonacciModelInfo.extensionLineWeight = action.weight;
            updateToSettings(FibonacciSettings, state.FibonacciModelInfo);
            return { ...state };

        case ActionTypes.HANDLE_SHAPE_CHANGE:
            state.ShapesModelInfo.selectedType = action.shape;
            updateToSettings(ShapeSettings, state.ShapesModelInfo);
            return { ...state };
        case ActionTypes.HANDLE_TEXT_EFFECTS_CHANGE: {
            const textModelInfo = { ...state.TextModelInfo };

            switch (action.event) {
                case 'fontStyle':
                    textModelInfo.fontStyle = action.value;
                    break;
                case 'fontSize':
                    textModelInfo.fontSize = action.value;
                    break;
                case 'bold':
                    textModelInfo.isBold = !state.TextModelInfo.isBold
                    break;
                case 'italic':
                    textModelInfo.isItalic = !state.TextModelInfo.isItalic
                    break;
                case 'underline':
                    textModelInfo.isUnderline = !state.TextModelInfo.isUnderline
                    break;
                case 'align':
                    textModelInfo.textAlign = action.value
                    break;
                default:
                    break;
            }

            updateToSettings(TextSettings, textModelInfo);
            return {
                ...state,
                TextModelInfo: { ...state.TextModelInfo, ...textModelInfo }
            }
        }

        case ActionTypes.UPDATE_RI_PANEL_INFO:
            return {
                ...state,
                [action.model]: {
                    ...state[action.model],
                    ...action.newStateProperties
                }
            }
        case AlertConstants.ActionTypes.TOGGLE_SHOW_ALERTS:
            return {
                ...state,
                showAlert: !state.showAlert
            };
        case ActionTypes.UPDATE_TEMP_TOOL_SELECTION:
            return {
                ...state,
                tempToolType: action.tempTool
            };
        case ActionTypes.DETECT_MOUSE_MOVE:
            return {
                ...state,
                mouseMoved: action.mouseMoved
            };
        case ActionTypes.TOGGLE_DG_RI_PANEL:
            return {
                ...state,
                riPanelToggle: !state.riPanelToggle
            };

        default:
            return state;
    }

}

export default annotationMenuReducer;