import { AlertTranslateHelper } from "../../../../Utils/TranslateHelper.js";
import { connect } from "react-redux";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore.js";
import React from "react";
import { Modal, Row, Col, ControlLabel, FormGroup } from "react-bootstrap";
import { openColumnSetFilter, openGeographyFilter } from "../../../../Actions/AlertActions.js";

function FilterListDialog (props){
    return (
        <Modal.Body>
            <div className="modal-area">
                <div className="flexbox-parent">
                    <div className="flexbox-item header">
                        {AlertTranslateHelper["COLUMN_SET_AND_GEOGRAPHY_EXCLUDE_SYMBOL"]}
                    </div>
                    <div className="model-action-grid">
                        <Row className="show-grid">
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel>{AlertTranslateHelper["COLUMN_SET"]}</ControlLabel>
                                </FormGroup>
                                <div className="model-action-popup-btn" title={props.selectedColSetString === "No Column Set" ? AlertTranslateHelper["NO_COLUMN_SET"] : LocalizationStore.getTranslatedData(`ColumnSet_${props.selectedColSetString}`, props.selectedColSetString)} onClick={props.openColumnSetFilter}  >{props.selectedColSetString === "No Column Set" ? AlertTranslateHelper["NO_COLUMN_SET"] : LocalizationStore.getTranslatedData(`ColumnSet_${props.selectedColSetString}`, props.selectedColSetString)}</div>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <ControlLabel>{AlertTranslateHelper["GEOGRAPHY"]}</ControlLabel>
                                </FormGroup>
                                <div className="model-action-popup-btn" title={props.browserFilterText} onClick={props.openGeographyFilter} >{props.browserFilterText}</div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Modal.Body>
    );
}

const mapStateToProps = ({ alertReducer }) => {
    const { browserFilterText, selectedColSetString } = alertReducer.AlertDialogReducer;
    return { browserFilterText, selectedColSetString }
}

const mapDispatchToProps = (dispatch) => ({
    openGeographyFilter: () => dispatch(openGeographyFilter()),
    openColumnSetFilter: () => dispatch(openColumnSetFilter()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterListDialog);
