import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetPageListRowsRequest extends BaseServiceRequest {

  constructor(listId, cacheId, seqNumber, pageSize, isQuoteboard = false, fundMsid = 0, spId = null) {
    super();
    super.addRequestUri("details.pb");
    super.addPathParameter(listId.toString());
    super.addRequestParameter("c", cacheId.toString());
    super.addRequestParameter("rn", seqNumber.toString());
    super.addRequestParameter("num", pageSize.toString());
    super.addRequestParameter("rts", "0");
    //  base.AddRequestParameter("rts", ((int)WorkSpaceMgr.CurrentWsModel.States.WsDdeModel.IntradaySourceType).ToString());
    super.addRequestParameter("iqb", (isQuoteboard ? "1" : "0"));
    if (spId) super.addRequestParameter("sid", spId.toString());
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ListRowsResponse";
  }
}

export default GetPageListRowsRequest;
