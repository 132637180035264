import { call, put, select, takeLatest, takeEvery, take, fork, delay } from "redux-saga/effects";
import { NupViewConstant } from "../../../Constants/NupViewConstants";
import { TabDataGraphConstants } from "../../../Constants/TabDataGraphConstants";
import { getState, getChartNode, getNodeWidth, getNupCharts, getSelected, getUserSettings, getScaleByPeriodicity, getSymbolList, getListData, getSelectdIndex, getNupQuoteSymbol } from "../../../Reducers/NavList/ListView/selector";
import WebSyncUtil from "../../../Utils/WebSyncUtil";
import ConsoleStore from 'ConsoleStore';
import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import SettingsAction from 'SettingsAction';
import NavType from "NavType";
import ONeilViewStore from "ONeilViewStore";
import ThemeHelper from "ThemeHelper";
import CurrencyHelper from "CurrencyHelper";
import ListApi from "ListApi";
import StringUtil from "StringUtil";
import SymbolType from "SymbolType";
import GraphType from "GraphType";
import LocalizationStore from "LocalizationStore";
import SettingsStore from 'SettingsStore';
import { getExternalDataSubMenu } from "../../../Actions/ExternalDataUploaderActions";
import TabDataGraphStore from "../../../Stores/NavModules/NavDataGraph/TabDataGraph/TabDataGraphStore";
import TabDataGraphActionClass from "../../../Actions/TabDataGraphAction";
import { dispatch } from "../../../Redux/dispatch";
import PeriodicityHelper from "../../../Utils/PeriodicityHelper";
import TimeLineHelper from "../../../Stores/TimeLine/TimeLineHelper";
import LogVolumeScale from "../../../Utils/Scales/LogVolumeScale";
import BaseServiceApi from 'BaseServiceApi';
import WorkSpaceRealTimePrices from "../../../Utils/RealTimeHelper/WorkSpaceRealTimePrices";
import CalcVolumeMa from "../../../Utils/Calcs/VolumeMA";
import FixedLogScale from "../../../Utils/Scales/FixedLogScale";
import ArithmaticScale from "../../../Utils/Scales/ArithmaticScale";
import WArithmaticScale from "../../../Utils/Scales/WArithmaticScale";
import LogScale from "../../../Utils/Scales/LogScale";
import CalcMaLine from "../../../Utils/Calcs/PriceMA";
import HighLow from "../../../Utils/Calcs/HighLow";
import DayOfWeek from "DayOfWeek";
import GridStore from "GridStore";
import KeyTypes from "../.../../../../Constants/KeyType";
import moment from "moment-timezone";
import { safe } from "../../ErrorModel";
import QuarterlyData from "../../../Utils/Calcs/QuarterlyData";
import StockMarketUtil from "../../../Utils/StockMarketUtil";
import { eventChannel } from "redux-saga";
import ListActions from "ListActions";
import ListStore from "ListStore";
import { updateNupSettings, updateShowChart, updateNupPeriodicity, updateNupCharts, updateNupChartSelected, updateEpsRps, updatePeriodicityOptions, updatePeriodicityButtonState, updateShowStory, updateColsRows, updateNupListData, updateRedraw, updateNupVisibleItem, updateSymbolList, updateScaleByPeriodicity, updateNasdaqBasic, updateSelectedIndex, updateNupQuoteSymbol, shouldUpdateNupComponent, updateGlobalSelectedIndex, updateIsScrollAvailable, updateNupRTVolume, updateNupNodeCount } from "../../../Actions/NupViewActions";
import { clone, each, intersection, isEqual } from "underscore";
import datagraphHelper from "../../../Utils/DatagraphHelper";
import UserInfoUtil from "UserInfoUtil";
import { initPeriodicityButton, onPlotSymbol } from "../../../Actions/DatagraphActions";
var IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];
const { ActionTypes } = NupViewConstant;
let IntradayBusy  = false;
let firstVisibleIndexs = 0;
let lastVisibleIndexs = 2;

function* expandHsfData(hsfData, timeLine, periodicity, SymbolInfo, IsIntraday, isIntradayDataSource) {
    
    let hsfPt = 0;
    let tlPt = 0;
    const mxData = timeLine.length;
    const hsLng = hsfData.length;
    const newData = [tlPt];
    const stDate = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(hsfData[0].Date, SymbolInfo, IsIntraday, isIntradayDataSource), SymbolInfo.MomentZoneID);
    while (tlPt < mxData && timeLine[tlPt]) {
        if (timeLine[tlPt].Date > stDate) {
            newData[tlPt] = { IsVisible: false, Date: timeLine[tlPt].Date };
            tlPt++;
        } else { // exclude partial nodes PANWEB-5345
            if (tlPt > 0 && timeLine[tlPt].Date < stDate &&
                periodicity !== GraphType.Weekly &&
                periodicity !== GraphType.Monthly &&
                periodicity !== GraphType.Quarterly &&
                periodicity !== GraphType.Annual) {
                tlPt--;
            }
            break;
        }
    }

    while (hsfPt < hsLng && tlPt < mxData && timeLine[tlPt] && timeLine[tlPt + 1] && hsfData[hsfPt]) {
        hsfData[hsfPt].Date = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(hsfData[hsfPt].Date, SymbolInfo, IsIntraday, isIntradayDataSource), SymbolInfo.MomentZoneID);
        if (hsfData[hsfPt].Close <= 0) {
            if (hsfData[hsfPt].Low > 0) {
                hsfData[hsfPt].Close = hsfData[hsfPt].Low;
            } else {
                if (hsfData[hsfPt].High > 0) {
                    hsfData[hsfPt].Close = hsfData[hsfPt].High;
                    if (hsfData.length > 1) {
                        let p1 = hsfData[hsfPt].Close.toFixed(4);
                        let p2 = hsfData[hsfPt + 1].Close.toFixed(4);
                        hsfData[hsfPt].UpTick = p1 >= p2;
                    }
                }
            }
        }
        if (tlPt + 1 >= mxData) {
            break;
        }
        let partialNode = timeLine.length >= tlPt + 1 && hsfData.length >= tlPt ? timeLine[tlPt].Date > hsfData[hsfPt].Date &&
            timeLine[tlPt + 1].Date < hsfData[hsfPt].Date : false;
        if (timeLine[tlPt].Date > hsfData[hsfPt].Date &&
            !partialNode) {

            let gDate = JSON.parse(JSON.stringify(hsfData[hsfPt]));
            gDate.Date = timeLine[tlPt].Date;
            gDate._volume = 0;
            gDate.IsVisible = true;
            //gDate.ShowVol = false;
            newData[tlPt] = gDate;
            tlPt++;
            continue;
        }
        hsfData[hsfPt].insiderBuySell = 0;
        hsfData[hsfPt].insiderBuy = 0;
        hsfData[hsfPt].insiderSell = 0;
        hsfData[hsfPt].IsVisible = true;
        if (hsfData[hsfPt].Volume != undefined) {
            hsfData[hsfPt]._volume = StringUtil.convertFromLongValueToInt(hsfData[hsfPt].Volume);
        } else {
            hsfData[hsfPt].Volume = hsfData[hsfPt]._volume;
        }
        newData[tlPt] = hsfData[hsfPt];
        tlPt++;
        hsfPt++;
    }

    return newData;
}

function* getPriceMA(graphData, hsfData, maPeriod, lastNode, maType) {
    let vMaValues = [];
    const calcPriceMa = new CalcMaLine();

    vMaValues = maType && maType === "SMA" ?
        calcPriceMa.CalculatePriceMA(hsfData, maPeriod, lastNode) :
        calcPriceMa.CalculateExponentialMovingAverage(hsfData, maPeriod, lastNode);
    if (vMaValues && vMaValues.length < 1) return null;
    graphData.maLineData.push(vMaValues);
}

function* getMaSettings(graphData, hsfData, periodicity, SymTypeEnum){
    const userSetting = ConsoleStore.getSettings()
    const settings = datagraphHelper.getSettingsObject(userSetting, null, SymTypeEnum, true);
    const maSettings = settings.MASettings[periodicity];
    graphData.maLineData = [];
    if (maSettings) {
        let i = 0;
        let lng = maSettings.length;
        for (; i < lng; i++) {
            let item = maSettings[i];
            if (item.IsVisible && item.ma > 0) {
                let lastNode = 0;
                yield safe(call(getPriceMA, graphData, hsfData, item.ma, lastNode, item.maType), "NupViewSaga.js", "getPriceMA");
                graphData.maLineData[i] = yield safe(call(PriceAverage, graphData.maLineData[i], graphData.scale, graphData.HiLowPoints.allPoints[0].xAxis), "NupViewSaga.js", "PriceAverage");
            }
            else{
                graphData.maLineData[i] = null;
            }
        }
    }

    return maSettings;
}

function* PriceAverage(hsfResults, scale, xAxis) {
    if (scale.getObjectMapKey() === "WArithmaticScale") {
        return yield safe(call(PriceAverageA,hsfResults, scale, xAxis), "NupViewSaga.js", "PriceAverageA");
    } else {
        return yield safe(call(PriceAverageL,hsfResults, scale, xAxis), "NupViewSaga.js", "PriceAverageL");
    }
}
function* PriceAverageL(hsfResults, scale, xAxisLocal) {
    const _state = yield select(getState);
    if (hsfResults === undefined) {
        return [];
    }
    let xAxis = xAxisLocal;//chartWidth - (45 + 0);
    const prcLength = hsfResults.length - 1;
    const padding = 4 * 4;
    const chartData = new Array(prcLength - padding);
    let x = 0;
    for (let j = 0; j < prcLength; j++) {
        if (xAxis < 0) break;
        const yPrice = scale.ComputeY(hsfResults[j].Close);
        const info = {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: xAxis,
            yValue: hsfResults[j].Close
        };
        xAxis -= _state.nodeWidth;
        chartData[x] = info;
        x++;
    }
    return chartData;
}
function* PriceAverageA(hsfResults, scale, xAxisLocal) {
    const _state = yield select(getState);
    if (hsfResults === undefined) {
        return [];
    }
    const prcLength = hsfResults.length - 1;
    const padding = 4 * 4;
    const chartData = new Array(prcLength - padding);
    let xAxis = xAxisLocal;//chartWidth - (45 + 0);
    let x = 0;
    for (let j = 0; j < prcLength; j++) {
        if (xAxis < 0) break;
        const yPrice = scale.ComputeY(hsfResults[j].Close);
        const info = {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: xAxis,
            yValue: hsfResults[j].Close
        };
        xAxis -= _state.nodeWidth;
        chartData[x] = info;
        x++;
    }
    return chartData;
}

function* index(info) {
    return (info &&
        info.SymTypeEnum &&
        (
            info.SymTypeEnum === SymbolType.WONMAJORINDUSTRY89 ||
                info.SymTypeEnum === SymbolType.WONINDUSTRYGROUP197 ||
                info.SymTypeEnum === SymbolType.INDEX ||
                info.SymTypeEnum === SymbolType.ECONOMICINDICATOR ||
                info.SymTypeEnum === SymbolType.INTERNATIONALINDEX ||
                info.SymTypeEnum === SymbolType.WONSECTOR11
        )
    );
}

function* getHighLowPoints(item, hasVolume, chartWidth) {
    const graphData = item.hsfData;
    const scale = item.scale;
    const nodeWidth = yield select(getNodeWidth);
    const lastDataNode = graphData.length - 1;
    const padding = 4*4;
    let xAxis = chartWidth - item.leftScaleWidth;
    const chartData = { highPoints: [], lowPoints: [] };
    if ((padding) <= lastDataNode)
    chartData.allPoints = new Array(lastDataNode - (padding));
    else
    chartData.allPoints = new Array(lastDataNode + 1);
    let index = 0;
    let pSize = 0;
    for (let j = 0; j <= lastDataNode; j++) {
        if (graphData[j].IsVisible) {
            pSize = j;
            if (graphData[j]._volume < 1) {
                graphData[j].High = graphData[j].Close;
                graphData[j].Low = graphData[j].Close;
            }
            break;
        }
    }
    for (let j = 0; j <= lastDataNode; j++) {
        if (!graphData[j].IsVisible || j > pSize && (graphData[j]._volume < 1 && item.HsfData.ShowVolume)) { // || !graphData[j].ShowVol) {
            xAxis -= nodeWidth;
            continue;
        }
        const yPrice = scale.ComputeY(graphData[j].Close);
        const yHigh = scale.ComputeY(graphData[j].High);
        const yLow = scale.ComputeY(graphData[j].Low);
        //const isIndex = false;
        graphData[j].UpTick = j < lastDataNode ? graphData[j].Close >= graphData[j + 1].Close : graphData[j].UpTick;
        const info = {
            Date: graphData[j].Date,
            yPrice: yPrice,
            yHigh: yHigh,
            yLow: yLow,
            xAxis: xAxis,
            UpTick: graphData[j].UpTick,
            IsVisible: hasVolume && ((graphData[j] && graphData[j]._volume > 0) || (graphData[j] && graphData[j]._volume > -1)) || !hasVolume,
            graphData: graphData[j],
            corpIndex: [],
            IdeasTr: []
        };
        xAxis -= nodeWidth;
        if (graphData[j].UpTick) {
            chartData.highPoints.push(info);
        }
        else {
            chartData.lowPoints.push(info);
        }
        chartData.allPoints[index] = info;
        index++;
    }
    return chartData;
}

function* VolumeBars(item, hasVolume, chartWidth) {
    const graphData = item.hsfData;
    const scale = item.vscale;
    const nodeWidth = yield select(getNodeWidth);
    const lastDataNode = graphData.length - 1;
    const padding = 4 * 4;
    let xAxis = chartWidth - item.leftScaleWidth;
    const chartData = { highPoints: [], lowPoints: [] };
    if ((padding) <= lastDataNode)
        chartData.allPoints = new Array(lastDataNode - (padding));
    else
        chartData.allPoints = new Array(lastDataNode + 1);
    let index = 0;
    let yVol;

    for (let j = 0; j <= lastDataNode; j++) {
        if (!graphData[j].IsVisible) {
            xAxis -= nodeWidth;
            continue;
        }
        if (xAxis < 0) break;
        const vol = graphData[j]._volume;
        yVol = scale.ComputeY(vol);
        if ((yVol - scale.Height) < 2) yVol = yVol - 2;
        const info = {
            Date: graphData[j].Date,
            yHigh: yVol,
            yLow: scale.Height,
            xAxis: xAxis,
            IsVisible: (vol && vol > 0) || !hasVolume,
            UpTick: graphData[j].UpTick
        };
        xAxis -= nodeWidth;
        if (graphData[j].UpTick) {
            chartData.highPoints.push(info);
        }
        else {
            chartData.lowPoints.push(info);
        }
        chartData.allPoints[index] = info;
        index++;
    }

    return chartData;
}

function* pctIntoTrading(item, delayed = false) {
    let currTime = new Date();
    const timeZone = item.SymbolInfo ? item.SymbolInfo.MomentZoneID !== null ? item.SymbolInfo.MomentZoneID : "US/Eastern" : "US/Eastern";
    currTime = DateHelper.returnTimeZoneDate(currTime, timeZone);
    if (currTime > item.TradeOpen && currTime < item.TradeClose) {
        item.TradeDate = currTime;
        const delay = item.SymbolInfo && item.SymbolInfo.TimeDelay ? item.SymbolInfo.TimeDelay : 20;
        item.DelayedTradeDate = new Date(currTime - delay * 60000);
    }
    else {
        item.TradeDate = item.TradeClose;
        item.DelayedTradeDate = item.TradeClose;
    }
    const delay = delayed ? delayed : !item.IsNASDAQBasic;
    return WorkSpaceRealTimePrices.PctIntoTrading(item, delay);
}

function* volumeMA(item, lastNode) {
    let vMaValues = [];
    let maPeriod = 6;
    const hsfData = item.HsfData.HSFResults;
    const calcVolumeMa = new CalcVolumeMa();
    if (item.periodicity === GraphType.Daily)
        maPeriod = 50;
    if (item.periodicity === GraphType.Weekly)
        maPeriod = 10;

    const indx = item.SymbolInfo ? yield safe(call(index,item.SymbolInfo), "NupViewSaga.js", "index") : false;
    const pctIntoTradings = yield safe(call(pctIntoTrading, item, !item.IsNASDAQBasic), "NupViewSaga.js", "pctIntoTrading");
    vMaValues = calcVolumeMa.CalculateVolumeMA(hsfData, maPeriod, lastNode);
    if (vMaValues.length < 1) return [];
    const dataNodes = hsfData.filter((t) => t._volume > -1);
    if (item.isHistoric && dataNodes && dataNodes.length >= maPeriod) {
        const lastDataNode = dataNodes[0];
        const target = vMaValues[0];
        let fSumOfPeriods = lastDataNode._volume / pctIntoTradings;
        for (let i = 1; i < maPeriod; i++) {
            fSumOfPeriods = fSumOfPeriods + dataNodes[i]._volume;
        }
        target.Close = fSumOfPeriods / maPeriod;
    }
    return vMaValues;
}

function* VolumeAverage(xAxis, item) {
    const hsfResults = item.vMaValues;
    const scale = item.vscale;
    const nodeWidth = yield select(getNodeWidth);
    const prcLength = hsfResults.length;
    const chartData = new Array(prcLength);
    let x = 0;

    for (let j = 0; j < prcLength; j++) {
        if (xAxis < 0) break;
        let yPrice = scale.ComputeY(hsfResults[j].Close);
        if ((yPrice - scale.Height) < 2) yPrice = yPrice - 2;
        const info = {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: xAxis,
            maValue: hsfResults[j].Close,
            Volume: hsfResults[j].Volume
        };
        xAxis -= nodeWidth;
        chartData[x] = info;
        x++;
    }
    return chartData;
}
function internationalStock(info) {
    return (info &&
        info.SymTypeEnum &&
        info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK
    );
}
function* getVolumeRate(item) {
    let pctChange = -101;
    if (item) {
        let dVolRate = item.HsfData.DVolRate;
        let dwVolRate = item.HsfData.DWVolRate;
        let rtVolRate = item.HsfData.RTVolRate;
        let rtwVolRate = item.HsfData.RTWVolRate;
        if (!item.isHistoric) {
            if (item.IsNASDAQBasic) {
                if ((item.periodicity === GraphType.Daily || item.IsIntraday) && rtVolRate !== -101) {
                    pctChange = rtVolRate;
                }
                if (item.periodicity === GraphType.Weekly && rtwVolRate !== -101) {
                    pctChange = rtwVolRate;
                }
            } else {
                if ((item.periodicity === GraphType.Daily || item.IsIntraday) && dVolRate !== -101) {
                    pctChange = dVolRate;
                }
                if (item.periodicity === GraphType.Weekly && dwVolRate !== -101) {
                    pctChange = dwVolRate;
                }
            }
        }
    }

    let curVol = 0;
    const international = item.SymbolInfo ? internationalStock(item.SymbolInfo) : false;
    const indx = item.SymbolInfo ? yield safe(call(index, item.SymbolInfo), "NupViewSaga.js", "index") : false;
    let volMultip = international ? 1 : 100;
    let prevAVol = item.volMALineData.length > 0 && item.volMALineData[1]
        ? item.volMALineData[1].maValue
        : 0;
    const curAVol = item.volMALineData.length > 0 && item.volMALineData[0]
        ? item.volMALineData[0].maValue
        : 0;
    let maValue = prevAVol;
    let delayed = item.SymbolInfo.type === SymbolType.INDEXNOINTRADAYVOLDATA && item.SymbolInfo.Osid<1000000 ? true : !item.IsNASDAQBasic;
    let pctIntoTradings = item.isHistoric ? 1 : yield safe(call(pctIntoTrading, item, delayed), "NupViewSaga.js", "pctIntoTrading");
    let stockHeaderUpToDate = item.HeaderData;

    if (!item.IsNASDAQBasic || !item.isReceivedQuote) {
        let volumeData = item.volumePriceData;
        if (volumeData !== undefined) {
            if (volumeData.volumeRate == 0) {
                pctChange = -100;
            } else {
                if (item.SymbolInfo.type === SymbolType.INDEXNOINTRADAYVOLDATA && item.SymbolInfo.Osid < 1000000 && (item.periodicity === GraphType.Daily || item.IsIntraday)) {
                    pctChange = 100 * ((item.HeaderData.CurrVol / (item.HeaderData.AvgVol * pctIntoTradings)) - 1);
                }
                else {
                    pctChange = volumeData.volumeRate;
                }
            }
        }
    }
 
    if ((item.periodicity === GraphType.Daily || item.IsIntraday) && maValue < 0.001) {
        if (stockHeaderUpToDate && item.HeaderData.AvgVol) {
            maValue = item.HeaderData.AvgVol * volMultip;
        }
    }
    if (item.isHistoric) {
        curVol = item.HiLowPoints.allPoints ? item.HiLowPoints.allPoints[0].graphData._volume : 0;
        if (curVol !== undefined && curAVol > 0.0) {
            pctChange = 100 * ((curVol / curAVol) - 1.0);
        }
        else if (curAVol <= 0.0) {
            pctChange = -100;
        }
    }
    else
    if (item.periodicity !== GraphType.Daily && item.periodicity !== GraphType.Weekly && !item.IsIntraday || pctChange === -101) {
        curVol = item.HiLowPoints.allPoints ? item.HiLowPoints.allPoints[0].graphData._volume : 0;
        if (pctChange === -101 && curVol !== undefined && maValue > 0.0) {
            pctChange = 100 * ((curVol / (maValue * pctIntoTradings)) - 1);
        } else {
            if (maValue <= 0.0) {
                //PANWEB-6810: Adjust the -ve volume to max of -100 
                pctChange = -100;
            }
        }
    }

    return pctChange;
}

function* getsScaleByPeriodicity(periodicity) {
    const scaleByPeriodicity = yield select(getScaleByPeriodicity);
    const scaleValue = scaleByPeriodicity.filter((f) => f.periodicity === periodicity)[0].scale;

    switch (scaleValue) {
        case "FixedWonLogScale":
            return new FixedLogScale(true);
        case "FixedLogScale":
            return new FixedLogScale();
        case "ArithmaticScale":
            return new ArithmaticScale();
        case "WArithmaticScale":
            return new WArithmaticScale();
        default:
            return new LogScale();
    }
}

function* getScalLabel(scale){
    let scaleLabal = "LOG (F)";
    let scaleType = scale.getObjectMapKey();

    if (scaleType === "FixedWonLogScale")
        scaleLabal = "LOG (W)";
    else if (scaleType === "FixedLogScale")
        scaleLabal = "LOG (F)";
    else if (scaleType === "ArithmaticScale" || scaleType === "WArithmaticScale")
        scaleLabal = "LIN";
    else if (scaleType === "LogScale")
        scaleLabal = "LOG (A)";

    return scaleLabal;
}

function isStock(symbolType){
    return symbolType === SymbolType.ADR ||
        symbolType === SymbolType.USSTOCK ||
        symbolType === SymbolType.INTERNATIONALSTOCK ||
        symbolType === SymbolType.REIT ||
        symbolType ===SymbolType.PREIPO ||
        symbolType === SymbolType.HISTORICSTOCK ||
        symbolType === SymbolType.HISTORICALMODELSTOCK;
}

function* getOValue(item){
    let curr = item.HiLowPoints.allPoints[0].graphData.Close
    let yrHigh = item.HeaderData.YrHi;
    if(item.HiLowPoints.allPoints[0].graphData.High > yrHigh && isStock(item.SymbolInfo.SymTypeEnum)){
        yrHigh = item.HiLowPoints.allPoints[0].graphData.High;
    }

    return yrHigh !== null && yrHigh > 0 ? ((curr / yrHigh) - 1) * 100 : null;
}

function* SetupLastDdeData(item, updatePrice = false) {
    if (item === undefined || item.HsfData === undefined) {
        return;
    }

    let hsfResults = item.HsfData.HSFResults;
    if (hsfResults == null || hsfResults.length < 1) return;
    var lastHsfResult = hsfResults[0];

    if (!item.IsIntraday)
        item.IdPushDown = false;
    else {
        item.IdPushDown = true;
    }
    let symbol = item.Symbol;
    if (item.LastDdeData != null && !updatePrice) {
        item.LastDdeData.DataAvailable = false;
        item.LastDdeData.HideVolume = false;
        item.LastDdeData.RTVolRate = item.HsfData.RTVolRate;
        item.LastDdeData.RTWVolRate = item.HsfData.RTWVolRate;
        item.LastDdeData.Symbol = item.Symbol;
        item.QuoteSymbol = symbol;
        item.LastDdeData.QuoteSymbol = symbol;
    }

    if (item.SymbolInfo.Symbol !== symbol)
        return;
    if (!WorkSpaceRealTimePrices.IsTradingHours(item))
        return;
    let ddeDate = item.HsfData.DDEDateNode.Date !== null ? item.HsfData.DDEDateNode.Date.getFullYear ?
        DateHelper.returnTimeZoneDate(item.HsfData.DDEDateNode.Date, item.SymbolInfo.MomentZoneID) :
        DateHelper.parseJsonDate(item.HsfData.DDEDateNode.Date, item.SymbolInfo.MomentZoneID) : new Date();
    if (!item.IsIntraday && lastHsfResult != null &&
        ddeDate.getTime() === lastHsfResult.Date.getTime() &&
        lastHsfResult._volume < 1 && item.HsfData != null &&
        item.HsfData.DDEDateNode != null && item.HsfData.DDEDateNode.Close > 1) {
        lastHsfResult.High = item.HsfData.DDEDateNode.High;
        lastHsfResult.Low = item.HsfData.DDEDateNode.Low;
        lastHsfResult.Close = item.HsfData.DDEDateNode.Close;
        lastHsfResult._volume = item.HsfData.DDEDateNode._volume * 100;
        if (WorkSpaceRealTimePrices.IsNASDAQBasic && !item.HsfData.ShowVolume && !item.isHistoric)
            item.LastDdeData.HideVolume = true;
    }

    if ((lastHsfResult != null && lastHsfResult._volume > 0) || item.IsIntraday) {
        item.LastDdeData.High = lastHsfResult.High;
        item.LastDdeData.Low = lastHsfResult.Low;
        item.LastDdeData.Price = lastHsfResult.Close;
        item.LastDdeData.Volume = lastHsfResult._volume;

        if (item.LastDdeData != null && lastHsfResult.Date >= item.LastDdeData.Date) 
        {
            item.LastDdeData.Date = lastHsfResult.Date;
            item.LastDdeData.Symbol = item.Symbol;
            symbol = item.Symbol;
            item.QuoteSymbol = symbol;
            item.LastDdeData.QuoteSymbol = symbol;
            item.LastDdeData.High = item.IsIntraday ? (lastHsfResult._volume > 0 ? Math.max(lastHsfResult.High, item.LastDdeData.High) : item.LastDdeData.High) : lastHsfResult.High;
            item.LastDdeData.Low = item.LastDdeData.Low > 0 && !item.IsIntraday ? (lastHsfResult._volume > 0 ? Math.min(lastHsfResult.Low, item.LastDdeData.Low) : item.LastDdeData.Low) : lastHsfResult.Low;
            if (item.LastDdeData.Price <= 0)
                item.LastDdeData.Price = lastHsfResult.Close;
            item.LastDdeData.PrevVolume = lastHsfResult._volume;
            if (lastHsfResult._volume > 0)
                item.LastDdeData.Volume = lastHsfResult._volume;
            if (!item.ShowVolume)
                item.LastDdeData.PrevVolume = lastHsfResult._volume;
            else if (lastHsfResult != null && lastHsfResult._volume > 0 && item.LastDdeData.Volume > 0 && item.periodicity != GraphType.Daily && item.SymbolInfo.Osid < 2000000)
                item.LastDdeData.PrevVolume = lastHsfResult._volume - item.LastDdeData.Volume;
            item.LastDdeData.DataAvailable = true;
            item.LastDdeData.Periodicity = item.periodicity;
        }
    }
    item.PrevHigh = item.LastDdeData.High;
    item.PrevLow = item.LastDdeData.Low;
}

function* IsLineGraph(hsfResults) {
    if ((hsfResults == null) || (hsfResults.length < 1))
        return false;

    let xLine = 0;

    if (hsfResults.length < xLine || hsfResults[xLine] == null)
        return false;
    let currentPriceLine = 1;
    while (!hsfResults[xLine].Close || hsfResults[xLine].Close <= 0.0) {
        xLine++;
        if (hsfResults[xLine] == null)
            return false;
    }

    if (hsfResults.length > 10) {
        for (;
            currentPriceLine < 11 && hsfResults[xLine]._volume < 2 &&
                hsfResults[xLine].High == hsfResults[xLine].Low;
            xLine++, currentPriceLine++) {
        }
    }
    return currentPriceLine > 9;
}

function* getMinMaxValues(state, chartWidth) {
    const _state = yield select(getState);
    let maxPrice = Number.NEGATIVE_INFINITY;
    let minPrice = Number.POSITIVE_INFINITY;
    let minVolume = Number.POSITIVE_INFINITY;
    let maxVolume = Number.NEGATIVE_INFINITY;
    const hsfData = state.HsfData.HSFResults;
    const nodeCount = chartWidth / _state.nodeWidth;
    const hLength = hsfData.length;
    const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(state.periodicity);
    let quarterCount = 0;
    switch (majorPeriodicity) {
        case GraphType.Weekly:
            quarterCount = 18;
            break;
        case GraphType.Montly:
            quarterCount = 24;
            break;
        case GraphType.Quarterly:
            quarterCount = 8;
            break;
        case GraphType.Annual:
            quarterCount = 2;
            break;
        default:
            quarterCount = 0;
    }


    let prcLength = nodeCount - (quarterCount + (state.leftScaleWidth / _state.nodeWidth));
    if (hLength < prcLength) {
        prcLength = hLength;
    }
    let aVolume = 0;
    let nodes = 0;
    for (let i = 1; i < prcLength; i++) {
        if (maxPrice < hsfData[i].High)
            maxPrice = hsfData[i].High;
        if (minPrice > hsfData[i].Low)
            minPrice = hsfData[i].Low;
        if (i > 0) {
            const vol = hsfData[i]._volume ? hsfData[i]._volume : parseInt(hsfData[i].Volume);
            if (maxVolume < vol)
                maxVolume = vol;
            if (minVolume > vol && vol > 1)
                minVolume = vol;
            aVolume += vol;
            nodes++;
        }
    }
    const multiplier = majorPeriodicity === GraphType.Intraday ? 0 : 0.01;
    state.minPrice = minPrice - minPrice * multiplier * 8;
    state.maxPrice = maxPrice + maxPrice * multiplier;
    state.maxVolume = maxVolume;
    state.minVolume = minVolume;
    aVolume = aVolume / nodes;
    
    state.avolume = aVolume;
}
function getEpsData(epsDataInput) {
    const epsData = [];
    if (!epsDataInput) return;
    epsDataInput.forEach((element) => {
        epsData.push({
            ReportDate: DateHelper.getPSTFromLong(element.reportDt),
            FiscalDate: DateHelper.getPSTFromLong(element.fiscalDt),
            QtrData: element.eps,
            QtrDataHigh: element.epsHigh,
            QtrDataLow: element.epsLow,
            Type: element.EpsType,
            Display: element.display
        });
    });
    return epsData;
}
function getRpsData(rpsDataInput) {
    const rpsData = [];
    if (!rpsDataInput) return;
    rpsDataInput.forEach((element) => {
        rpsData.push({
            ReportDate: DateHelper.getPSTFromLong(element.reportDt),
            FiscalDate: DateHelper.getPSTFromLong(element.fiscalDt),
            QtrData: element.eps,
            QtrDataHigh: element.epsHigh,
            QtrDataLow: element.epsLow,
            Type: element.EpsType,
            Display: element.display
        });
    });
    return rpsData;
}

function domesticStock(info) {
    return (info &&
        info.SymTypeEnum &&
        (info.SymTypeEnum === SymbolType.USSTOCK ||
            info.SymTypeEnum === SymbolType.REIT ||
            info.SymTypeEnum === SymbolType.PREIPO ||
            info.SymTypeEnum === SymbolType.ADR ||
            info.SymTypeEnum === SymbolType.HISTORICSTOCK ||
            info.SymTypeEnum === SymbolType.MODELSTOCK ||
            info.SymTypeEnum === SymbolType.HISTORICALMODELSTOCK
        )
    );
}
function commonStock(info) {
    return (info &&
        info.SymTypeEnum &&
        (info.SymTypeEnum === SymbolType.USSTOCK ||
            info.SymTypeEnum === SymbolType.PREIPO ||
            info.SymTypeEnum === SymbolType.ADR ||
            info.SymTypeEnum === SymbolType.HISTORICSTOCK ||
            info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK ||
            info.SymTypeEnum === SymbolType.MODELSTOCK ||
            info.SymTypeEnum === SymbolType.ETF ||
            info.SymTypeEnum === SymbolType.REIT ||
            info.SymTypeEnum === SymbolType.FundClosedEnd ||
            info.SymTypeEnum === SymbolType.HISTORICALMODELSTOCK ||
            info.SymTypeEnum === SymbolType.ECONOMICINDICATOR
        )
    );
}

function* setFundamentalLines(item, chartWidth) {
    let quarterlyData = new QuarterlyData();
    var xAxis = chartWidth -  item.leftScaleWidth;
    let rawEpsLineData = [];
    let rawRpsLineData = [];
    const userSetting = ConsoleStore.getSettings()
    const settings = datagraphHelper.getSettingsObject(userSetting, null, item.SymbolInfo.SymTypeEnum, true);
    let epsMultiplierSettings = settings.EPSMultiplierSettings;
    let rpsMultiplierSettings = settings.RPSMultiplierSettings;
    let majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(item.periodicity);
    let epsLineSettings = settings.EarningLineSetting[majorPeriodicity];
    let rpsLineSettings = settings.RevenueLineSetting[majorPeriodicity];
    let epsShowHighLow = epsLineSettings.showHighLowEstimate;
    let rpsShowHighLow = rpsLineSettings.showHighLowEstimate && item.SymbolInfo.SymTypeEnum === SymbolType.USSTOCK;
    let epsData = getEpsData(item.EPSSnapshotData.Results);
    let rpsData = getRpsData(item.RPSSnapshotData.Results);

    let common = commonStock(item.SymbolInfo) ||
        item.SymbolInfo.Symbol === "0DJIA" ||
        item.SymbolInfo.Symbol === "0DJIC" ||
        item.SymbolInfo.Symbol === "0DJI" ||
        item.SymbolInfo.Symbol === "0DJU" ||
        item.SymbolInfo.Symbol === "0DJUA" ||
        item.SymbolInfo.Symbol === "0DJT" ||
        item.SymbolInfo.Symbol === "0DJTA" ||
        item.SymbolInfo.Symbol === "0S&P5";

    let nav = item.StockHeader != null && item.StockHeader.Nav;
    let showEps = epsData.length > 0;
    let showRps = rpsData.length > 0;

    let eM = quarterlyData.QuarterlyDataValues(epsData,
        item.hsfData,
        rawEpsLineData,
        item.periodicity,
        xAxis,
        epsMultiplierSettings.Fixed,
        20,
        item.SymbolInfo,
        false,
        nav);
    let rM = quarterlyData.QuarterlyDataValues(rpsData,
        item.hsfData,
        rawRpsLineData,
        item.periodicity,
        xAxis,
        rpsMultiplierSettings.Fixed,
        5,
        item.SymbolInfo,
        true,
        nav,
        true,
        item.isHistoric);

    item.epsMultiplier =
        common && showEps && epsLineSettings.IsVisible && epsData.length > 0 ? eM : NaN;
    item.rpsMultiplier =
        common && showRps && rpsLineSettings.IsVisible && rpsData.length > 0 ? rM : NaN;

    if (item.rpsMultiplier < 0) item.rpsMultiplier *= -1;

    item.LastQtrEPS = undefined;
    item.LastQtrRPS = undefined;

    if (!item.IsIntraday && rawEpsLineData) {
        let actualPointer = 0;
        let startPoint = 0;
        // numberOfQuarters are both used from EPS settings.
        item.numberOfQuarters = majorPeriodicity === GraphType.Daily || majorPeriodicity === GraphType.Intraday ? 0 : majorPeriodicity === GraphType.Weekly ? 2 : 8;//epsLineSettings.NumOfQuarters;
        let lng = rawEpsLineData.length;
        for (; actualPointer < lng; actualPointer++) {
            if (rawEpsLineData[actualPointer].Actual > 0)
                break;
        }
        if (lng > 0 && actualPointer < lng)
            item.LastQtrEPS = rawEpsLineData[actualPointer].QtrData;
        startPoint = Math.max(0, actualPointer - item.numberOfQuarters);
        if (showEps) {
            item.epsLineData = yield safe(call(getFundamentalLines, rawEpsLineData, item, eM, "#FFFF00", startPoint, epsShowHighLow, chartWidth), "NupViewSaga", "getFundamentalLines");
            item.epsLineData.isDataChange = true;
        }
        actualPointer = 0;
        startPoint = 0;
        lng = item.epsLineData.length;
        item.ShowEPSPointer = lng > 0;
        for (; actualPointer < lng; actualPointer++) {
            if (item.epsLineData[actualPointer].dotted > 0)
                break;
        }
        item.LastQtrY = undefined;
        if (lng > 0 && actualPointer < lng)
            item.LastQtrY = item.epsLineData[actualPointer].yPoint;
        
        actualPointer = 0;
        lng = rawRpsLineData.length;
        for (; actualPointer < lng; actualPointer++) {
            if (rawRpsLineData[actualPointer].Actual > 0)
                break;
        }
        if (lng > 0 && actualPointer < lng)
            item.LastQtrRPS = rawRpsLineData[actualPointer].QtrData;
        startPoint = Math.max(0, actualPointer - item.numberOfQuarters);

        if (showRps) {
            item.rpsLineData = yield safe(call(getFundamentalLines,rawRpsLineData, item, rM, "#FFFF00", startPoint, rpsShowHighLow, chartWidth), "NupviewSaga.js", "getFundamentalLines");
            item.rpsLineData.isDataChange = true;
        }
        actualPointer = 0;
        lng = item.rpsLineData.length;
        item.ShowRPSPointer = lng > 0;
        for (; actualPointer < lng; actualPointer++) {
            if (item.rpsLineData[actualPointer].dotted > 0)
                break;
        }
        if (lng > 0 && actualPointer < lng)
            item.LastRQtrY = item.rpsLineData[actualPointer].yPoint;

    }
}
function GetYAxis(x, x1, y1, x2, y2) {
    return ((y1 - y2) * (x - x2) / (x1 - x2)) + y2;
}

function* getFundamentalLines(quarterlyLineData, item, multiplier, lineColor, startPoint, showHighLow, chartWidth) {
    const _state = yield select(getState);
    const scale = item.scale;
    const mtDates = item.TimeLine.dates;
    const endDate = item.endDate;
    if (!endDate) return [];
    let startx = chartWidth -  item.leftScaleWidth - (item.periodicity === GraphType.Weekly ? 8 : 0);
    let fundLineData = [];
    let rstartx = startx;
    var nodeCount = quarterlyLineData.length;
    var QtrVal = 0.0;
    var QtrHVal = 0.0;
    var QtrLVal = 0.0;
    var pQtrVal = 0.0;
    var node = 0;
    var pActual = 0;
    var startup = false;
    var upDateEst = true;
    var qtrReportDate;
    var xPrev = startx;
    var yPrev;
    var yPrevh;
    var yPrevl;
    var mtDatesLen = mtDates.length;

    if (mtDatesLen < 1) {
        return fundLineData;
    }

    for (var qtrNode = startPoint; qtrNode < nodeCount; qtrNode++) {
        QtrVal = quarterlyLineData[qtrNode].QtrData;
        QtrHVal = quarterlyLineData[qtrNode].QtrDataHigh;
        QtrLVal = quarterlyLineData[qtrNode].QtrDataLow;
        QtrVal *= multiplier;
        QtrHVal *= multiplier;
        QtrLVal *= multiplier;

        if (pQtrVal === 0)
            pQtrVal = QtrVal;

        var yAxis;

        if (QtrVal <= 0 && (scale.getObjectMapKey() === "LogScale" || scale.getObjectMapKey() === "FixedLogScale"))
            yAxis = scale.ComputeY(0.000001);
        else
            yAxis = scale.ComputeY(QtrVal);

        var qtrNodeDate = new Date(quarterlyLineData[qtrNode].Date);
        if (qtrNode > 0)
            qtrReportDate = new Date(quarterlyLineData[qtrNode].ReportDate);
        var actual = quarterlyLineData[qtrNode].Actual;

        if (mtDates[0].Date < qtrNodeDate) continue;
        var pnode = node;

        for (; node < mtDatesLen && mtDates[node] && mtDates[node].Date > qtrNodeDate; node++)
            startx -= _state.nodeWidth;
        if (!QtrVal || !mtDates[node]) continue;
        var ryAxis = 0.0;

        if (qtrReportDate != null && qtrReportDate.getFullYear() > 1910 && quarterlyLineData[qtrNode].ShowRpt == 1) {
            rstartx = xPrev;
            for (; pnode < mtDatesLen && mtDates[pnode] && mtDates[pnode].Date > qtrReportDate; pnode++)
                rstartx -= _state.nodeWidth;
            ryAxis = GetYAxis(rstartx, startx, yAxis, xPrev, yPrev);
        }
        else {
            rstartx = startx;
        }

        var yhAxis = scale.ComputeY(QtrHVal);
        var ylAxis = scale.ComputeY(QtrLVal);

        let qtrMonth = qtrNodeDate.getMonth();
        if (item.periodicity === GraphType.Annual && qtrMonth < 11) {
            continue;
        }

        if (xPrev === startx) continue;

        if (startup) {
            var info =
            {
                Date: quarterlyLineData[qtrNode].Date,
                RDate: quarterlyLineData[qtrNode].ReportDate,
                xPoint: xPrev,
                yPoint: yPrev,
                xAxis: xPrev,
                yPrice: yPrev,
                x1Line: startx,
                y1Line: yAxis,
                dotted: pActual,
                xRep: rstartx,
                yRep: ryAxis,
                LineColor: lineColor,
                ShowQtr: quarterlyLineData[qtrNode].ShowQtr,
                ShowRpt: quarterlyLineData[qtrNode].ShowRpt,
                Display: quarterlyLineData[qtrNode].Display,
                Surprise: quarterlyLineData[qtrNode].surprise,
                SurpriseDiff: quarterlyLineData[qtrNode].suprisePctDiff,
            };

            fundLineData.push(info);

            if (upDateEst && pActual === 0 && showHighLow) {
                upDateEst = yhAxis !== 0;

                yhAxis = (yhAxis === 0 && actual === 1) ? yAxis : yhAxis;

                var infoh =
                {
                    Date: quarterlyLineData[qtrNode].Date,
                    RDate: quarterlyLineData[qtrNode].ReportDate,
                    xPoint: xPrev,
                    yPoint: yPrevh,
                    xAxis: xPrev,
                    yPrice: yPrevh,
                    x1Line: startx,
                    y1Line: yhAxis,
                    dotted: 0,
                    LineColor: lineColor,
                    ShowQtr: quarterlyLineData[qtrNode].ShowQtr,
                    ShowRpt: quarterlyLineData[qtrNode].ShowRpt,
                    Display: quarterlyLineData[qtrNode].Display,
                    Surprise: quarterlyLineData[qtrNode].surprise,
                    SurpriseDiff: quarterlyLineData[qtrNode].suprisePctDiff,
                };

                fundLineData.push(infoh);

                ylAxis = (ylAxis === 0 && actual === 1) ? yAxis : ylAxis;

                var infol =
                {
                    Date: quarterlyLineData[qtrNode].Date,
                    RDate: quarterlyLineData[qtrNode].ReportDate,
                    xPoint: xPrev,
                    yPoint: yPrevl,
                    xAxis: xPrev,
                    yPrice: yPrevl,
                    x1Line: startx,
                    y1Line: ylAxis,
                    dotted: 0,
                    LineColor: lineColor,
                    ShowQtr: quarterlyLineData[qtrNode].ShowQtr,
                    ShowRpt: quarterlyLineData[qtrNode].ShowRpt,
                    Display: quarterlyLineData[qtrNode].Display,
                    Surprise: quarterlyLineData[qtrNode].surprise,
                    SurpriseDiff: quarterlyLineData[qtrNode].suprisePctDiff,
                };

                fundLineData.push(infol);
            }
        }


        pActual = actual;
        xPrev = startx;
        yPrev = yAxis;
        yPrevh = yhAxis;
        yPrevl = ylAxis;
        startup = true;
        pQtrVal = QtrVal;
    }

    return fundLineData;
}

function* getEpsRpsVaules(item){
    if (item.isHistoric) {
        if (item.EPSSnapshotData && item.EPSSnapshotData.Results && item.EPSSnapshotData.Results.length > 0) {
            let actualEpsData = item.EPSSnapshotData.Results.filter((t) => t.EpsType === 2);
            if (item.SymbolInfo.CurrEarnrptdate === undefined) {
                item.SymbolInfo.CurrEarnrptdate = new Date(item.SymbolInfo.Earnrptdate.getTime());
            }
            if (actualEpsData[0]) {
                item.SymbolInfo.Earnrptdate = DateHelper.getPSTFromLong(actualEpsData[0].fiscalDt);
            }
            else {
                item.SymbolInfo.Earnrptdate = new Date(item.endDate.getTime());
                item.SymbolInfo.FCQtrEstCount = 0;
            }
        } else {
            item.SymbolInfo.Earnrptdate = new Date(item.endDate.getTime());
            item.SymbolInfo.FCQtrEstCount = 0;
        }
    } else {
        if (item.SymbolInfo.CurrEarnrptdate !== undefined) {
            item.SymbolInfo.Earnrptdate = new Date(item.SymbolInfo.CurrEarnrptdate.getTime());
        }
    }
    if (!item.IsIntraday) {
        item.EPSSnapshotData.Results.forEach((element) => {
            element.reportDt = DateHelper.getPSTFromLong(element.reportDt);
            element.fiscalDt = DateHelper.getPSTFromLong(element.fiscalDt);
        });
        item.RPSSnapshotData.Results.forEach((element) => {
            element.reportDt = DateHelper.getPSTFromLong(element.reportDt);
            element.fiscalDt = DateHelper.getPSTFromLong(element.fiscalDt);
        });
    }
    item.EPSValues = item.EPSSnapshotData !== null ? item.EPSSnapshotData.Results : [];
    item.RPSValues = item.RPSSnapshotData !== null ? item.RPSSnapshotData.Results : [];
}
function* prepareFundamentalLines(item){
    const _state = yield select(getState);
    yield safe(call(getEpsRpsVaules, item), "NupViewSaga.js", "getEpsRpsVaules");
    item.epsDataMissing = item.EPSValues.length < 1;
    item.rpsDataMissing = item.RPSValues.length < 1;
    if(!item.epsDataMissing)
        yield safe(call(setFundamentalLines, item, _state.dimension[_state.selected].width), "NupViewSaga.js", "setFundamentalLines");
        const userSetting = ConsoleStore.getSettings()
        const settings = datagraphHelper.getSettingsObject(userSetting, null, item.SymbolInfo.SymTypeEnum, true);
    item.epsSettings = settings.EarningLineSetting[item.majorPeriodicity];
    item.rpsSettings = settings.RevenueLineSetting[item.majorPeriodicity];
    item.epsValue = Math.abs(item.LastQtrEPS) > 999 ? ExtremeDataValue.abbreviateFinancialValue(item.LastQtrEPS, 1) : ExtremeDataValue.showPrice(item.LastQtrEPS);
    item.rpsValue = Math.abs(item.LastQtrRPS) > 999 ? ExtremeDataValue.abbreviateFinancialValue(item.LastQtrRPS, 1) : ExtremeDataValue.showPrice(item.LastQtrRPS);
}

function getHeaderData(item, symbol, periodicity, cols = 2){
    const headerData = {};
    const isLocalSymbolSelected = UserInfoUtil.hasLocalSymbol();
    if(item){
        const FFO = item.SymbolInfo.SymTypeEnum === SymbolType.REIT && !item.SymbolInfo.IsNav;
        const EPSDisplay = FFO ? "FFO:" : "EPS:";
        const PEDisplay = FFO ? "P/F:" : "P/E:";
        if(cols < 3){ 
            headerData.fundamentals = [];
            let currency = CurrencyHelper.get(item.SymbolInfo.DefaultCurrency.Code ? item.SymbolInfo.DefaultCurrency : { Code: item.SymbolInfo.DefaultCurrency });
            let inPence = (currency === "GBP" || currency === "ZAR");
            if (inPence) currency = 'p';
            let multiplier = 1;
            let marketConversionRate = 1.0;
            let mktCap = "";
            if (item.HeaderData.MktCap && item.HeaderData.MktCap > 0) {
                let abrivatedValue = ExtremeDataValue.abbreviateValue((item.HeaderData.MktCap * marketConversionRate * multiplier * 1000), 1);
                mktCap = currency + abrivatedValue;
            }
            if (item.HeaderData.MktCap2 && item.HeaderData.MktCap2 > 0 && item.HeaderData.MktCap2 !== item.HeaderData.MktCap &&
                item.HeaderData.Shares2 && item.HeaderData.Shares2 > 0) {
                mktCap += " (" + currency + ExtremeDataValue.abbreviateValue(item.HeaderData.MktCap2 * marketConversionRate * multiplier * 1000, 1) + ")";
            }
            let shares = "";
            if (item.HeaderData.Shares && item.HeaderData.Shares > 0) {
                shares = ExtremeDataValue.abbreviateValue(item.HeaderData.Shares * 1000, 1);
            }
            if (item.HeaderData.Shares2 && item.HeaderData.Shares2 * 1000 > 0 && item.HeaderData.Shares2 !== item.HeaderData.Shares) {
                let shares2 = ExtremeDataValue.abbreviateValue(item.HeaderData.Shares2 * 1000, 1);
                shares += " (" + shares2 + ")";
            }
            let avgVol = "";
            if (item.HeaderData.AvgVol && item.HeaderData.AvgVol > 0) {
                let abrivatedValue = ExtremeDataValue.abbreviateValue((item.HeaderData.AvgVol), 1);
                avgVol = abrivatedValue;
            }
            let avgDlr = "";
            if (item.HeaderData.AvgVol && item.HeaderData.AvgVol > 0) {
                let abrivatedValue = ExtremeDataValue.abbreviateValue((item.HeaderData.AvgVol * item.HeaderData.CurrPrice), 1);
                avgDlr = abrivatedValue;
            }
            headerData.fundamentals = [
                {text: 'DG:', value: item.HeaderData.DgRating || "-"},
                {text: EPSDisplay, value: item.HeaderData.EpsRank || "-"},
                {text: PEDisplay, value: Math.round(item.HeaderData.Pe) || "-"},
                {text: 'MKT CAP:', value: mktCap || "-"},
                {text: 'AVG VOL:', value: avgVol || "-"},
                {text: 'GRP:', value: item.HeaderData.GrpRank || "-"},
                {text: 'A/D:', value: item.HeaderData.AccDisRating || "-"},
                {text: 'ROE:', value: Math.round(item.HeaderData.Roe * 10) / 10 || "-"},
                {text: 'SHARES:', value: shares || "-"},
                {text: 'AVG Dly $ Vol:', value: avgDlr || "-"}
            ];
            headerData.Description = item.SymbolInfo.SymTypeEnum === SymbolType.FUND ? null : item.HeaderData.Description;
        }

        let pricePctChange = item.priceVal > 0 ? (item.priceVal - item.pPrice) * 100 / item.pPrice : 0;
        let pricePct = ExtremeDataValue.showPrice(pricePctChange, false);
        const sign = pricePctChange > 0 ? "+" : "";
        const pctChange = sign + pricePct + "%";
        headerData.CompanyName = item.HeaderData.CompanyName ? item.HeaderData.CompanyName : item.SymbolInfo.CompanyName;
        headerData.Symbol = isLocalSymbolSelected && item.SymbolInfo.localSymbol ? item.SymbolInfo.localSymbol : item.SymbolInfo.Symbol
        headerData.symbolData = { Symbol: item.SymbolInfo.Symbol,  periodicity: item.periodicity}
        headerData.pctChange = pctChange;
        headerData.priceChange = pricePctChange;
        headerData.pctChangeColor = pricePctChange < 0 ? ThemeHelper.getThemedBrush("negativeDataText") : ThemeHelper.getThemedBrush("positiveDataText");
        headerData.SymbolInfo = item.SymbolInfo;
        headerData.showFundamnetals = cols < 3;
        headerData.isTriggeredInstrument = item.isTriggeredInstrument;
    }
    else{
        if(cols < 3){
            headerData.fundamentals = [{text: 'DG:', value: "-"},
                                        {text: 'EPS:', value: "-"},
                                        {text: 'P/E:', value: "-"},
                                        {text: 'MKT CAP:', value: "-"},
                                        {text: 'AVG VOL:', value: "-"},
                                        {text: 'GRP:', value: "-"},
                                        {text: 'A/D:', value: "-"},
                                        {text: 'ROE:', value: "-"},
                                        {text: 'SHARES:', value: "-"},
                                        {text: 'AVG Dly $ Vol:', value: "-"}];
            headerData.Description = '';
        }
        headerData.CompanyName = symbol.coName;
        headerData.Symbol = isLocalSymbolSelected && symbol.localSymbol? symbol.localSymbol : symbol.symbol;
        headerData.symbolData = {Symbol: symbol.symbol, periodicity};
        headerData.pctChange = '';
        headerData.pctChangeColor = '';
        headerData.SymbolInfo = { MsId: symbol.msId};
        headerData.showFundamnetals = cols < 3;
        headerData.isTriggeredInstrument = false;
    }

    return headerData;
}

function* processNupData(symbol, state) {
    try{
        const item = symbol.nupData;
        if(!item) return;
        item.GraphType = symbol.nupHSFRequest.GraphType;
        item.periodicity = PeriodicityHelper.getPeriodicityString(item.GraphType);
        item.majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(item.periodicity);
        item.IsIntraday = item.majorPeriodicity === GraphType.Intraday;
        item.daily = item.majorPeriodicity === GraphType.Daily;
        if (item.HsfData.HSFResults.length < 2 && item.IsIntraday ||
            item.HsfData.HSFResults.length < 1 && !item.IsIntraday) {
            symbol.HeaderData= { Symbol: item.SymbolInfo.Symbol, Description: item.HeaderData.Description, CompanyName: item.SymbolInfo.CompanyName, symbolData: { Symbol: item.SymbolInfo.Symbol,  periodicity: item.periodicity}, SymbolInfo: item.SymbolInfo, isTriggeredInstrument: symbol.isTriggeredInstrument}
            symbol.graphData = {
                Symbol: item.SymbolInfo.Symbol,
                chartData: {
                    HiLowPoints: null,
                    majorPeriodicity: item.majorPeriodicity,
                },
                volumeData: {
                },
            }
            return;
        }

        item.SymbolInfo.LastTradeDate = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(item.SymbolInfo.LastTradeDate), item.SymbolInfo.MomentZoneID);
        const openTime = DateHelper.parseJsonDate(item.SymbolInfo.ExchangeOpenTime, item.SymbolInfo.MomentZoneID);
        const closeTime = DateHelper.parseJsonDate(item.SymbolInfo.ExchangeCloseTime, item.SymbolInfo.MomentZoneID);
        item.LastTradeDate = DateHelper.parseJsonDate(item.SymbolInfo.LastTradeDate, item.SymbolInfo.MomentZoneID);
        item.TradeOpen = new Date(item.LastTradeDate.getFullYear(), item.LastTradeDate.getMonth(), item.LastTradeDate.getDate(), openTime.getHours(), openTime.getMinutes());
        item.TradeClose = new Date(item.LastTradeDate.getFullYear(), item.LastTradeDate.getMonth(), item.LastTradeDate.getDate(), closeTime.getHours(), closeTime.getMinutes());
        item.RTTimeOut = null;
        item.graphTimer = null;
        item.QuoteSymbol = null;
        item.PrevDay = new Date(1826, 12, 31, 0, 0, 0);
        item.Symbol = item.SymbolInfo.Symbol;
        item.SymbolInfo.Earnrptdate = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(item.SymbolInfo.Earnrptdate), item.SymbolInfo.MomentZoneID);
        item.SymbolInfo.ExchangeCloseTime = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(item.SymbolInfo.ExchangeCloseTime), item.SymbolInfo.MomentZoneID);
        item.SymbolInfo.ExchangeOpenTime = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(item.SymbolInfo.ExchangeOpenTime), item.SymbolInfo.MomentZoneID);
        item.isHistoric = false;
        item.IsNASDAQBasic = state.IsNASDAQBasic;
        item.ShowVolume = item.HsfData.ShowVolume;
        item.IntradaySourceType = IntradaySourceType.IntradaySource_NASDAQ;
        item.isTriggeredInstrument = symbol.isTriggeredInstrument;
        const userSetting = ConsoleStore.getSettings()
        const settings = datagraphHelper.getSettingsObject(userSetting, null, item.SymbolInfo.SymTypeEnum, true);
        item.EPSValues = item.EPSSnapshotData !== null ? item.EPSSnapshotData.Results : [];
        item.isEpsRpsAvailable = settings.EarningLineSetting !== undefined && settings.RevenueLineSetting !== undefined;
        if(item.isEpsRpsAvailable){
            item.isLeftScale = item.periodicity === GraphType.Daily || item.IsIntraday || (!settings.EarningLineSetting[item.majorPeriodicity].IsVisible && !settings.RevenueLineSetting[item.majorPeriodicity].IsVisible) || item.EPSValues.length < 1;
            item.leftScaleWidth = item.isLeftScale ? 0 : 42;
        }
        else{
            item.leftScaleWidth = 0;
        }
        item.SymbolInfo.nupChart = true;   
        let endDate = DateHelper.parseJsonDate(DateHelper.getPSTFromLong(item.HsfData.HSFResults[0].Date, item.SymbolInfo, item.IsIntraday, item.HsfData.isIntradayDataSource), item.SymbolInfo.MomentZoneID);
        let beginDate = DateHelper.calculateBeginDate(endDate, item.periodicity, symbol.nupHSFRequest.numberOfNodes);
        item.endDate = endDate;
        if (item.IsIntraday) {
            let closeTime = item.SymbolInfo.ExchangeCloseTime.getFullYear ? item.SymbolInfo.ExchangeCloseTime : DateHelper["default"].parseJsonDate(item.SymbolInfo.ExchangeCloseTime, item.SymbolInfo.MomentZoneID);
            let tradeClose = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), closeTime.getHours(), closeTime.getMinutes(), 0, 0); 
            const currDate = WorkSpaceRealTimePrices.getCurrentTime(item.SymbolInfo.MomentZoneID);
            if (currDate.getTime() < tradeClose.getTime()) {
                endDate = currDate;
            }
        }    
        let stDate = new Date(beginDate.getTime());
        if (item.periodicity === GraphType.Annual) {
            const nodeCount = 250;//_state.nodeCount ? _state.nodeCount : 500;
            stDate.setFullYear(endDate.getFullYear() - (nodeCount * 2));
        }

        item.SymbolInfo.targetDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        item.numberOfQuarters = item.daily ||item.IsIntraday || !item.isEpsRpsAvailable ? 0 : item.majorPeriodicity === GraphType.Weekly ? 2 : 8;
        item.TimeLine = TimeLineHelper.GetTimeLineData(stDate, endDate, item.periodicity, item.numberOfQuarters, item.SymbolInfo, false, false, false);
        item.timeLineLabel = TimeLineHelper.GetLabels(item.TimeLine, item.periodicity, 12);

        item.hsfData = yield safe(call(expandHsfData, item.HsfData.HSFResults, item.TimeLine.dates, item.periodicity, item.SymbolInfo, item.IsIntraday, item.HsfData.isIntradayDataSource), "NupViewSaga.js", "expandHsfData");
        yield safe(call(getMinMaxValues, item, state.dimension[state.selected].width), "NupViewSaga.js", "getMinMaxValues");
        item.scale = yield safe(call(getsScaleByPeriodicity, item.majorPeriodicity), "NupViewSaga.js", "getsScaleByPeriodicity");
        const noVolume = !item.HsfData.HasVolume;
        item.isFund = item.SymbolInfo.SymTypeEnum === SymbolType.FUND;
        const priceChartHeight = state.dimension[state.selected].height +  (item.HeaderData.Description ? 0 : 39);
        let vChartHeight = 0
        if(!(item.isFund || noVolume)){
            if(state.rows === 2){
                vChartHeight = priceChartHeight * 0.20;
            }
            else if(state.rows === 3){
                if(window.innerWidth > 1600){
                    vChartHeight = priceChartHeight * 0.20;
                }
                else{
                    vChartHeight = priceChartHeight * 0.25;
                }
            }
            else{
                if(window.innerWidth > 2560){
                    vChartHeight = priceChartHeight * 0.20;
                }
                else if(window.innerWidth > 1600 && window.innerWidth < 2560){
                    vChartHeight = priceChartHeight * 0.25;
                }
                else{
                    vChartHeight = priceChartHeight * 0.30;
                }
            }
        }
        const chartHeight = item.isFund || noVolume ? priceChartHeight :  priceChartHeight - vChartHeight;
            
        item.scale.InitScale(item.minPrice, item.maxPrice, chartHeight, item.periodicity, 1, 28 * state.nodeWidth, item.SymbolInfo.SymTypeEnum);
        item.scaleLabel = yield safe(call(getScalLabel, item.scale), "NupViewSaga.js", "getScalLabel");
        item.HiLowPoints = yield safe(call(getHighLowPoints,item, true, state.dimension[state.selected].width), "NupViewSaga.js", "getHighLowPoints");
        let hiLoPriceSettings = settings.HiLoPriceSettings[item.majorPeriodicity];
        let hiLoPctSettings = settings.HiLoPctSettings[item.majorPeriodicity];
        let highLow = new HighLow();
        highLow.CalculateHighLowNodes(item.HiLowPoints, item.IsIntraday, hiLoPctSettings.IsVisible, hiLoPriceSettings.IsVisible);
        item.maSettings = yield safe(call(getMaSettings, item, item.hsfData, item.majorPeriodicity, item.SymbolInfo.SymTypeEnum), "NupViewSaga.js", "getMaSettings");
            
        item.nodeData = item.HiLowPoints.allPoints[0]
        item.priceVal = item.nodeData.graphData.Close;
        let prevData = item.HiLowPoints.allPoints[1] || item.nodeData;
        item.pPrice = item.IsIntraday ? item.HeaderData.PrevPrice : prevData ? prevData.graphData.Close : item.priceVal;
        item.priceChange = item.priceVal > 0 ? item.priceVal - item.pPrice : item.pPrice;
        item.oValue = yield safe(call(getOValue,item), "NupViewSaga.js", "getOValue");
        item.showOh = item.oValue !== null && !item.IsIntraday ? true : false;
        if(item.isEpsRpsAvailable)
            yield safe(call(prepareFundamentalLines, item), "NupViewSaga.js", "prepareFundamentalLines");
            
        item.scale.InitScale(item.minPrice, item.maxPrice, chartHeight, item.periodicity, 1, 28 * state.nodeWidth, item.SymbolInfo.SymTypeEnum, item.epsMultiplier, item.rpsMultiplier);
        const mhLines = [];
        item.vscale = new LogVolumeScale();
        item.vscale.SetHorizontalGrid(mhLines, item.minVolume, item.maxVolume, item.avolume, vChartHeight);
        var maxVol = Math.max(item.maxVolume, 80);
        item.vscale.Factor = item.maxVolume <= 0 ? 1 : (vChartHeight-4) / (item.vscale.log10(maxVol) - item.vscale.MLBaseVolumeForPlot);
        item.vscale.mhLines = mhLines;
        item.volumeData = yield safe(call(VolumeBars,item, true, state.dimension[state.selected].width), "NupViewSaga.js", "VolumeBars");
        if (item.IsIntraday){
            item.vMaValues = [];
        } else {
            item.vMaValues = yield safe(call(volumeMA, item, 0), "NupViewSaga.js", "volumeMA");
        }
        const xAxis = item.HiLowPoints.allPoints[0].xAxis;
        item.volMALineData = yield safe(call(VolumeAverage,xAxis, item), "NupViewSaga.js", "VolumeAverage");
        item.volRate = yield safe(call(getVolumeRate,item), "NupViewSaga.js", "getVolumeRate");
            
        //Header data prepare
        const headerData = getHeaderData(item, symbol.isFlagged,item.periodicity, state.cols);
        item.prevVolume = item.HsfData.HSFResults && item.HsfData.HSFResults.length>0 ? item.HsfData.HSFResults[0]._volume : 0;
        let isLineGraph = yield safe(call(IsLineGraph, item.HsfData.HSFResults), "NupViewSaga.js", "IsLineGraph");
        item.isLineChart = !item.IsIntraday && isLineGraph;
        item.LastDdeData = { High: 0, Low: 0, Price: 0, Volume: 0, Date: item.HsfData.DDEDateNode.Date };
        yield safe(call(SetupLastDdeData,item), "NupViewSaga.js", "SetupLastDdeData");
        symbol.HeaderData = headerData;
        symbol.graphData = {
            Symbol: item.SymbolInfo.Symbol,
            chartData:{
                HiLowPoints: item.HiLowPoints,
                SymbolInfo: item.SymbolInfo,
                periodicity: item.periodicity,
                majorPeriodicity: item.majorPeriodicity,
                isLineChart: item.isLineChart,
                TimeLine: item.TimeLine,
                IsIntraDay: item.IsIntraday,
                maSettings: item.maSettings,
                maLineData: item.maLineData,
                scale: item.scale,
                daily: item.daily,
                isEpsRpsAvailable: item.isEpsRpsAvailable,
                isLeftScale: item.isLeftScale,
                leftScaleWidth: item.leftScaleWidth,
                epsValue: item.epsValue,
                epsMultiplier: item.epsMultiplier,
                epsSelected: state.epsSelected,
                LastQtrEPS: item.LastQtrEPS,
                epsDataMissing: item.epsDataMissing,
                epsSettings: item.epsSettings,
                epsLineData:item.epsLineData,
                epsPointerVisible: item.epsPointerVisible,
                rpsValue: item.rpsValue,
                rpsMultiplier: item.rpsMultiplier,
                LastQtrRPS: item.LastQtrRPS,
                rpsDataMissing: item.rpsDataMissing,
                rpsSettings: item.rpsSettings,
                rpsLineData: item.rpsLineData,
                rpsPointerVisible: item.rpsPointerVisible,
                scaleLabel: item.scaleLabel,
                nodeData: item.nodeData,
                curr: item.priceVal,
                lastPrice: item.priceVal,
                priceVal: ExtremeDataValue.showPrice(item.priceVal),
                pPrice: item.pPrice,
                priceChange: ExtremeDataValue.showPrice(item.priceChange),
                oValue: ExtremeDataValue.getAbbrivatedString(Math.round(item.oValue), 0),
                showOh: item.showOh,
                timeLineLabel: item.timeLineLabel,
                hasVolume: item.HsfData.HasVolume
            },
            volumeData:{
                volumeData: item.volumeData,
                volMALineData: item.volMALineData,
                volRate: item.volRate.toFixed(),
                vscale: item.vscale
            },
        }
    }
    catch(error){
        console.error(error);
    }
    
}

function* registerWebSync( quoteSymbol, symbolsToRemove, isNasdaqConnection ){
    if (WebSyncUtil.IsConnected && isNasdaqConnection) {
        yield fork(webSyncChannel, quoteSymbol, symbolsToRemove);
        yield fork(serverTickChannel);
    }
}

let webSyncChannelBuffer;
function* webSyncChannel( quoteSymbol, symbolToRemove ){
    if(webSyncChannelBuffer){
        webSyncChannelBuffer.close()
    }
    webSyncChannelBuffer =  eventChannel((emmiter) => {
        if (WebSyncUtil.IsConnected && quoteSymbol) {
            if (symbolToRemove && symbolToRemove.length > 0) {
                WebSyncUtil.UnsubscribeSymbolsAsync(symbolToRemove);
            }
            WebSyncUtil.SubscribeSymbolsAsync(quoteSymbol);
        }
        WebSyncUtil.addDataReceivedListener(emmiter);

        return ()=>{
            WebSyncUtil.removeDataReceivedListener(emmiter);
        }
    });

    while(true){
        const result = yield take(webSyncChannelBuffer);
        yield safe(call(updateLastPriceData, result), "NupviewSaga.js", "updateLastPriceData");
    }
}

let serverTickChannelBuffer;
function* serverTickChannel(){
    if(serverTickChannelBuffer){
        serverTickChannelBuffer.close();
    }
    serverTickChannelBuffer = eventChannel((emmiter)=>{
        const timer = setInterval(emmiter, 1000, new Date());

        return ()=>{
                clearInterval(timer);
        }
    });
    while(true){
        yield take(serverTickChannelBuffer);
        yield safe(call(OnServerTickReceived), "NupviewSaga.js", "onServerTickReceived");
    }
}

function* updateLastPriceData(quote) {
    const _state = yield select(getState);
    const symbolList = [];
    _state.visibleItems.map((item)=> item.map((value)=> symbolList.push(value)))
    for(let item of symbolList) {
        let t = item.graphData
        if (t.Symbol === quote.Symbol) {
            let state = item.nupData;
            state.isReceivedQuote = true;
            const tradeTime = moment(quote.TradeTime).tz(state.SymbolInfo.MomentZoneID);
            // @ts-ignore
            let lastTradeDate = new Date(tradeTime.format('Y'), parseInt(tradeTime.format('M')) - 1, tradeTime.format('D'), tradeTime.format('HH'), tradeTime.format('mm'), 0);
            quote.TradeTime = lastTradeDate;
            const hsfData = state.HsfData.HSFResults;
            const hLength = state.HsfData.HSFResults.length;
            if (quote.VolRateDaily !== null) {
                 state.HsfData.RTVolRate = quote.VolRateDaily;
            }
            if (quote.VolRateWeekly !== null) {
                 state.HsfData.RTWVolRate = quote.VolRateWeekly;
            }
            state.SymbolInfo.PPrice = quote.PrevDayClose;
            state.SymbolInfo.CPrice = quote.Last;
            t.chartData.lastPrice = quote.Last;
            t.chartData.priceVal = ExtremeDataValue.showPrice(quote.Last)
            t.chartData.priceChange = ExtremeDataValue.showPrice(quote.Last - quote.PrevDayClose);
            t.volumeData.volRate = yield safe(call(getVolumeRate, state), "NupViewSaga.js", "getVolumeRate");
            let pricePctChange = quote.Last > 0 ? (quote.Last - quote.PrevDayClose) * 100 / quote.PrevDayClose : 0;
            let pricePct = ExtremeDataValue.showPrice(pricePctChange, false);
            const sign = pricePctChange > 0 ? "+" : "";
            const pctChange = sign + pricePct + "%";
            item.HeaderData.pctChange = pctChange;
            item.HeaderData.priceChange = pricePctChange;
            item.HeaderData.pctChangeColor = pricePctChange < 0 ? ThemeHelper.getThemedBrush("negativeDataText") : ThemeHelper.getThemedBrush("positiveDataText");
            if (state.endDate.getDate() !== quote.TradeTime.getDate()) {
                if (WorkSpaceRealTimePrices.checkTradingHours(state, quote.TradeTime)) {
                    const year = quote.TradeTime.getFullYear();
                    const month = quote.TradeTime.getMonth();
                    const day = quote.TradeTime.getDate();
                    const openTime = DateHelper.parseJsonDate(state.SymbolInfo.ExchangeOpenTime, state.SymbolInfo.MomentZoneID);
                    const closeTime = DateHelper.parseJsonDate(state.SymbolInfo.ExchangeCloseTime, state.SymbolInfo.MomentZoneID);
                    state.SymbolInfo.LastTradeDate = new Date(year, month, day);
                    state.LastTradeDate = DateHelper.parseJsonDate(state.SymbolInfo.LastTradeDate, state.SymbolInfo.MomentZoneID);
                    state.TradeOpen = new Date(state.LastTradeDate.getFullYear(), state.LastTradeDate.getMonth(), state.LastTradeDate.getDate(), openTime.getHours(), openTime.getMinutes());
                    state.TradeClose = new Date(state.LastTradeDate.getFullYear(), state.LastTradeDate.getMonth(), state.LastTradeDate.getDate(), closeTime.getHours(), closeTime.getMinutes());
                    yield safe(fork(processNupData, item, _state), "NupViewSaga.js", "processNupData");
                    yield put(updateNupVisibleItem(_state.visibleItems));
                    return;
                }
            }

            if (!WorkSpaceRealTimePrices.IsTradingHours(state)) {
                state.firstTrade = false;
                state.LastDdeData.LiveData = false;
                return;
            }

            
            if (!state.firstTrade && WorkSpaceRealTimePrices.IsTradingHours(state)) {
                state.firstTrade = true;
                yield safe(fork(processNupData, item, _state), "NupViewSaga.js", "processNupData");
                yield put(updateNupVisibleItem(_state.visibleItems));
                return;
            }

            for (let i = 0; i < hLength; i++) {
                if (quote.Last && quote.Last>0) { 
                    hsfData[i].Close = quote.Last;
                    if (quote.High > hsfData[i].High) {
                        hsfData[i].High = quote.High;
                    }
                    if (quote.Low < hsfData[i].Low) {
                        hsfData[i].Low = quote.Low;
                    }
                    state.LastDdeData.PrevVolume = state.LastDdeData.Volume;
                    switch (state.majorPeriodicity) {
                    case GraphType.Daily:
                        if (hsfData[i]._volume < quote.Volume) {
                            hsfData[i]._volume = quote.Volume;
                        }
                        break;
                    case GraphType.Intraday:
                        {
                            if (state.HsfData.LastVolume < quote.Volume) {
                                let diff = state.HsfData.LastVolume<1 ? 0 : quote.Volume - state.HsfData.LastVolume;
                                state.HsfData.LastVolume = quote.Volume;
                                if (isNaN(hsfData[i]._volume)) {
                                    hsfData[i]._volume = 0;
                                }
                                state.LastDdeData.Volume = hsfData[i]._volume;
                                state.LastDdeData._volume = hsfData[i]._volume;
                                hsfData[i]._volume += diff;
                                hsfData[i].Volume = hsfData[i]._volume;
                            }
                        }
                        break;
                    default:
                        {
                            if (state.HsfData.LastVolume < quote.Volume) {
                                let diff = quote.Volume - state.HsfData.LastVolume;
                                state.HsfData.LastVolume = quote.Volume;
                                if (isNaN(hsfData[i]._volume)) {
                                    hsfData[i]._volume = 0;
                                }
                                hsfData[i]._volume += diff;
                                hsfData[i].Volume = hsfData[i]._volume;
                                state.LastDdeData.Volume = hsfData[i]._volume;
                                state.LastDdeData._volume = hsfData[i]._volume;
                            }
                        }
                        break;
                    }
                    state.LastDdeData.High = hsfData[i].High;
                    state.LastDdeData.Low = hsfData[i].Low;
                    state.LastDdeData.Price = hsfData[i].Close;
                    state.prevCumVolume = state.CumVolume ? state.CumVolume : state.hsfData.LastVolume;
                    state.CumVolume = quote.Volume !== null ? quote.Volume : 0;
                    if (quote.VolRateDaily !== null && quote.VolRateDaily !== -101)
                        state.LastDdeData.RTVolRate = quote.VolRateDaily;
                    if (quote.VolRateWeekly !== null && quote.VolRateWeekly !== -101)
                        state.LastDdeData.RTWVolRate = quote.VolRateWeekly;
                    state.LastDdeData.DataAvailable = true;
                    state.LastDdeData.LiveData = true;
                    state.LastDdeData.Periodicity = state.periodicity;
                    state.LastDdeData.Date = lastTradeDate;
                    state.PrevHigh = state.LastDdeData.High;
                    state.PrevLow = state.LastDdeData.Low;
                    break;
                }
            }
        }
    }
}

function* OnServerTickReceived() {
    const _state = yield select(getState);
    const symbolList = [];
    _state.visibleItems.map((item)=> item.map((value)=> symbolList.push(value)))
    if (IntradayBusy) return;
    for(let item of symbolList){
        let t = item.graphData
        if(!t) return;
        const states = item.nupData;
        const info = item.nupData.SymbolInfo;
        const commonStocks = info ? yield call(commonStock, info) : true;
        const domesticStocks = info ? yield call(domesticStock, info) : true;
        if (!commonStocks || states.isHistoric) continue;

        if (states.LastDdeData.Symbol !== states.Symbol) return;
        if (!WorkSpaceRealTimePrices.IsTradingHours(states)) {
            states.firstTrade = false;
            states.LastDdeData.LiveData = false;
            states.Restart = domesticStocks;
            return;
        }
        const timeZoneId = states.SymbolInfo.MomentZoneID !== null ? states.SymbolInfo.MomentZoneID : "US/Eastern";
        const currentSystemDate = WorkSpaceRealTimePrices.getCurrentTime(timeZoneId);
        const currentStreamingDate = new Date(currentSystemDate.getFullYear(), currentSystemDate.getMonth(), currentSystemDate.getDate(), 0, 0, 0);
        //const blockOutDates = _state.BlackOutDates;
        //const blkIndex = blockOutDates && blockOutDates.length > 0 ? blockOutDates.findIndex((k) => (k.Date.getTime() === currentStreamingDate.getTime())) : -1;

        let lastEndDate = states.LastDdeData.Date;

        if (WorkSpaceRealTimePrices.IsTradingHours(states) &&
            states.Restart && //lastEndDate < currentStreamingDate &&
            //blkIndex < 0 &&
            currentStreamingDate.getDay() > DayOfWeek.Sunday &&
            currentStreamingDate.getDay() < DayOfWeek.Saturday) {
            states.Restart = false;
            window.location.reload();
        }

        if (states.LastDdeData.Symbol === states.Symbol) {
            if (states.IsIntraday && states.SymbolInfo.zoneid.length > 1) {
                const endDate = moment().tz(states.SymbolInfo.MomentZoneID);
                // @ts-ignore
                lastEndDate = new Date(endDate.format('Y'), parseInt(endDate.format('M')) - 1, endDate.format('D'), endDate.format('HH'), endDate.format('mm'), 0);
            }
        }
        yield safe(call(UpdateLastPriceTask, lastEndDate, item, _state), "NupviewSaga.js", "updateLastPriceTask");
    };
    if(_state.IsNASDAQBasic){
        for(let item of _state.visibleItems){
            for(let value of item){
                yield safe(fork(processNupData, value, _state), "NupViewSaga.js", "processNupData");
            }
        }
        yield put(updateNupVisibleItem(_state.visibleItems));
    }
}

function* UpdateLastPriceTask (lastEndDate, item, _state) {
    const state = item.nupData;
    let padding = 0;
    if (state === null || typeof (state) === "undefined") return;

    if (state.IsIntraday) {
        if (!state.LastDdeData.DataAvailable ||
            state.LastDdeData.Price <= 0 ||
            state.LastDdeData.Periodicity !== state.periodicity) {
            return;
        }
    }
    else {
        if (!state.LastDdeData.LiveData || !state.LastDdeData.DataAvailable ||
            state.LastDdeData.Price <= 0 ||
            state.LastDdeData.Periodicity !== state.periodicity) {
            return;
        }
    }

    let Price = state.LastDdeData.Price;
    let High = state.LastDdeData.High;
    let Low = state.LastDdeData.Low;
    var showvol = state.ShowVolume;
    let chartNodes = state.HsfData.HSFResults;
    var firstNode = chartNodes[0];
    var previousNode = firstNode;

    if (chartNodes.length > 0)
        previousNode = chartNodes[1];

    var positiveNode = previousNode ? firstNode.Close >= previousNode.Close : true;

    let index = 0;
    if (!WorkSpaceRealTimePrices.IsTradingHours(state)) {
        state.firstTrade = false;
        return;
    }

    state.HsfData.RTVolRate = state.LastDdeData.RTVolRate;
    state.HsfData.RTWVolRate = state.LastDdeData.RTWVolRate;
    var localHsfData = state.HsfData;
    if (state.IsIntraday) {
        var pushDown = state.IsNASDAQBasic || state.IdPushDown;

        if (!pushDown ||
            state.HsfData === null ||
            state.HsfData.HSFResults === null ||
            state.SymbolInfo === null ||
            IntradayBusy)
            return;

        var cDate = lastEndDate;
        var info = state.SymbolInfo;

        var firstItem = state.HsfData.HSFResults[0];
        var idDate = firstItem != null ? firstItem.Date : cDate;
        var openTime = state.TradeOpen;
        var closeTime = state.TradeClose;
        var beginTrading = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), openTime.getHours(), openTime.getMinutes(), 0);
        var endTrading = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), closeTime.getHours(), closeTime.getMinutes(), 0);

        if (state.PrevDay.getFullYear() < 2000)
            state.PrevDay = idDate;

        if (cDate > idDate && //!IntradayBusy &&
            pushDown && cDate <= endTrading && cDate >= beginTrading) {
            //IntradayBusy = true;
            var idData = localHsfData.HSFResults;

            if (state.PrevDay.getDate() !== state.LastDdeData.Date.getDate()) {
                state.LastIdVolume = 0;
                state.PrevHigh = Price;
                state.PrevLow = Price;
                state.PrevVolume = 0;
                state.PrevDay = state.LastDdeData.Date;
            }
            var delayed = state != null && state.SymbolInfo != null && WorkSpaceRealTimePrices.Delayed(state.SymbolInfo.MomentZoneID, idData[padding].Date);
            if (!state.firstTrade) {
                idData[padding]._volume = state.LastDdeData.Volume;
                idData[padding].High = Price;
                idData[padding].Low = Price;
                idData[padding].Close = Price;
                idData[padding].UpTick = true;


                idData[padding].ShowVol = (showvol || !delayed) && idData[padding]._volume > 1;

                if (idData.length > 1) {
                    idData[padding].UpTick = idData[padding].Close >= idData[padding + 1].Close;
                }
            }
            else {
                idData[padding].High = firstNode.High;
                idData[padding].Low = firstNode.Low;
                idData[padding].Close = firstNode.Close;
                idData[padding]._volume = firstNode._volume > 0 ? firstNode._volume : idData[padding]._volume;
                idData[padding].UpTick = true;

                idData[padding].ShowVol = (showvol || !delayed) && idData[padding]._volume > 1;

                if (idData.length > 1)
                    idData[padding].UpTick = idData[padding].Close >= idData[padding + 1].Close;
            } 

            state.LastIdVolume = state.CumVolume > 0 && state.prevCumVolume > 0 && state.CumVolume - state.prevCumVolume > 0
                ? state.CumVolume - state.prevCumVolume
                : (Price !== firstNode.Close ? 1 : 0);

            if (Price > 0) {
                if (!state.firstTrade) {
                    if (state.LastDdeData.Volume < 1) return;
                    High = Price;
                    Low = Price;
                    state.firstTrade = true;
                }
                state.PrevHigh = Price;
                state.PrevLow = Price;
            }

            state.PrevDay = state.LastDdeData.Date;

            var nextNode = cDate;
            var prevNode = idData[padding].Date;
            let prevPrice = idData[padding].Close;
            prevNode = StockMarketUtil.GetNextDate(state.periodicity, prevNode);

            if (nextNode > prevNode) {
                let upTick = Price >= prevPrice;
                var iDay = {
                    High: Price,
                    Low: Price,
                    Close: Price,
                    _volume: state.LastIdVolume,
                    Date: nextNode,
                    UpTick: upTick,
                    ShowVol: true,
                    IsVisible: true,
                    insiderBuy: 0,
                    insiderBuySell: 0,
                    insiderSell: 0,
                    Volume: state.LastIdVolume
                };

                idData.unshift(iDay);
            }
            else {
                idData[padding].Date = nextNode;
            }
            IntradayBusy = false;
            state.PickNewLH = true;
            state.LastDdeData.Volume = state.LastIdVolume;
        }
    }

    if (state.PickNewLH) {
        state.PrevHigh = Price;
        state.PrevLow = Price;
        state.LastDdeData.High = Price;
        state.LastDdeData.Low = Price;
        state.PickNewLH = false;
    }
    var cLength = chartNodes.length;

    if (cLength <= index || chartNodes[index] == null) return;

    for (; index < cLength - 1; index++)
        if (chartNodes[index] != null && chartNodes[index].Close > 0) break;

    var data = chartNodes[index];
    if (data == null) return;
    if (data._volume < 1) {
        data.Close = state.LastDdeData.Price;
        data.High = state.LastDdeData.High;
        data.Low = state.LastDdeData.Low;
    }
    var beginOfMonth = StockMarketUtil.GetMBeginDate(localHsfData.HSFResults[0].Date);
    var beginOfQuarter = StockMarketUtil.GetQBeginDate(localHsfData.HSFResults[0].Date);
    var beginOfYear = StockMarketUtil.GetABeginDate(localHsfData.HSFResults[0].Date);

    var FirstTradingOfPeriod = (state.periodicity == GraphType.Daily ||
            (state.periodicity == GraphType.Weekly &&
                state.LastDdeData.Date.getDay() == DayOfWeek.Monday) ||
            (state.periodicity == GraphType.Monthly &&
                localHsfData.HSFResults[0].Date == beginOfMonth) ||
            (state.periodicity == GraphType.Annual &&
                localHsfData.HSFResults[0].Date == beginOfYear) ||
            (state.periodicity == GraphType.Quarterly &&
                localHsfData.HSFResults[0].Date == beginOfQuarter));

    if (!state.firstTrade) {
        data.Close = state.LastDdeData.Price;
        state.PrevHigh = state.LastDdeData.Price;
        state.PrevLow = state.LastDdeData.Price;
        if (FirstTradingOfPeriod) {
            data.High = state.LastDdeData.Price;
            data.Low = state.LastDdeData.Price;
        }
        data._volume = state.LastDdeData.Volume;
        state.firstTrade = true;
    }

    if (state.IsIntraday) {
        High = Math.max(High, state.PrevHigh);
        Low = Math.min(Low, state.PrevLow);
    }
    else {
        High = Math.max(High, data.High);
        Low = Math.min(Low, data.Low);
    }

    if (chartNodes.length > padding &&
        chartNodes[padding] != null) {
        firstNode.High = High;
        firstNode.Low = Low;
        firstNode.Close = Price;
        firstNode.UpTick = positiveNode;
        firstNode.IsVisible = true;
        firstNode.ShowVol = (showvol || !state.IsIntraday) && !state.LastDdeData.HideVolume;
    }   
}

function* processList(){
    try{
        yield put(updateShowChart(false));
        const userSetting = yield select(getUserSettings);
        yield safe(call(resetNupState), "NupviewSaga.js", "resetNupState");
        userSetting.showChart = false;
        yield put(updateNupSettings(userSetting));
        SettingsStore.saveSettings();
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, processList ${error}`);
    }
}

function* updateStore(){
    try{
        const settings = SettingsStore.getConsoleSettings();
        const userSetting = settings.NavListManagerSettings.NupChartSettings;
        const IsNASDAQBasic = settings.NavDatagraphSettings.PreferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption;
        const colsRows = yield safe(call(getRowsCols, userSetting.nupCharts[userSetting.selected]), "NupviewSaga.js", "getRowsCols");
        yield put({type: ActionTypes.UPDATE_NUP_CHART_DIMENSION, dimension: userSetting.dimension});
        yield put(updateNasdaqBasic(IsNASDAQBasic));
        yield put(updateColsRows(colsRows));
        yield put(updateNupCharts(userSetting.nupCharts));
        yield put(updateNupChartSelected(userSetting.selected));
        yield put(updateNupSettings(userSetting));
        ONeilViewStore.showChart = userSetting.showChart
        yield put(updateShowChart(userSetting.showChart));
        yield put(updateEpsRps(userSetting.epsSelected));
        yield put(updateNupPeriodicity(userSetting.graphType));
        yield put(updatePeriodicityOptions(userSetting.PeriodicityOptions));
        yield put(updateScaleByPeriodicity(userSetting.scaleByPeriodicity));
        return userSetting;
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateStore ${error}`);
    }
}

function* processChart({ symbolList }){
    if(symbolList.length < 1){
        return [null, null];
    }
    console.time('initNUPData');
    try{
        let userSetting = yield select(getUserSettings);
        const nupCharts = yield select(getNupCharts);
        if(userSetting === null){
            userSetting = yield safe(call(updateStore), "NupviewSaga.js", "updateStore");
        }
        const _state = yield select(getState);
        const nupHSFRequest = [];
        //const nodeCount = yield select(getChartNode);
        const nodeCount = parseInt(window.innerWidth /(_state.nodeWidth*_state.cols))
        const selectedLayout = nupCharts.filter((t) => t.sel === "icn-select")[0];
        const endDate = new Date();
        const currentDate = DateHelper.yyyymmdd(endDate);
        let intraDayBack = endDate;
        intraDayBack = DateHelper.yyyymmdd(intraDayBack);
        let dailyBack = DateHelper.calculateBeginDate(endDate, GraphType.Daily, nodeCount);
        dailyBack = DateHelper.yyyymmdd(dailyBack);
        let weeklyBack = DateHelper.calculateBeginDate(endDate, GraphType.Weekly, nodeCount);
        weeklyBack = DateHelper.yyyymmdd(weeklyBack);
        let monthlyBack = DateHelper.calculateBeginDate(endDate, GraphType.Monthly, nodeCount);
        monthlyBack = DateHelper.yyyymmdd(monthlyBack);
        let quarterBack = DateHelper.calculateBeginDate(endDate, GraphType.Quarterly, nodeCount);
        quarterBack = DateHelper.yyyymmdd(quarterBack);
        let annualBack = DateHelper.calculateBeginDate(endDate, GraphType.Annual, nodeCount);
        annualBack = DateHelper.yyyymmdd(annualBack); 
        let dayBack = intraDayBack;

        symbolList.forEach((item) => {
                if (selectedLayout.per.length > 0) {
                    selectedLayout.per.forEach((type) => {
                        const graphType = PeriodicityHelper.getPeriodicityString(type);
                        switch (type) {
                            case 1:
                                dayBack = dailyBack;
                                break;
                            case 2:
                                dayBack = weeklyBack;
                                break;
                            case 3:
                                dayBack = monthlyBack;
                                break;
                            case 4:
                                dayBack = annualBack;
                                break;
                            case 12:
                                dayBack = quarterBack;
                                break;
                            default:
                                intraDayBack = DateHelper.calculateBeginDate(endDate, graphType, nodeCount);
                                intraDayBack = DateHelper.yyyymmdd(intraDayBack);
                                dayBack = intraDayBack;
                                break;
                        }
                        nupHSFRequest.push({
                            Symbol: item.Symbol,
                            GraphType: type,
                            StartDate: dayBack,
                            EndDate: currentDate,
                            numberOfNodes: nodeCount,
                            RTVolume: _state.RTVolume
                        });
                    });
                } else {
                    const graphType = PeriodicityHelper.getPeriodicityString(_state.graphType);
                    switch (_state.graphType) {
                        case 1:
                            dayBack = dailyBack;
                            break;
                        case 2:
                            dayBack = weeklyBack;
                            break;
                        case 3:
                            dayBack = monthlyBack;
                            break;
                        case 4:
                            dayBack = annualBack;
                            break;
                        case 12:
                            dayBack = quarterBack;
                            break;
                        default:
                            intraDayBack = DateHelper.calculateBeginDate(endDate, graphType, nodeCount);
                            intraDayBack = DateHelper.yyyymmdd(intraDayBack);
                            dayBack = intraDayBack;
                            break;
                    }
                    nupHSFRequest.push({
                        Symbol: item.Symbol,
                        GraphType: _state.graphType,
                        StartDate: dayBack,
                        EndDate: currentDate,
                        numberOfNodes: nodeCount,
                        RTVolume: _state.RTVolume
                    });
                }
        });
        yield put(updateNupSettings(userSetting));
         yield put(updateNupNodeCount(nodeCount))
        SettingsStore.saveSettings();
        try{       
            const nupData = yield safe(call(ListApi.getNupData, nupHSFRequest), "NupViewSaga.js", "ListApi.getNupData");
            yield put(updateRedraw(false));
            return [nupData, nupHSFRequest];
        }
        catch(error) {
            console.log(`Error occurs in NupViewSaga.js, processChart ${error}`);
        }
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, processChart ${error}`);
    }
    
    console.timeEnd('initNUPData');
}

function* handleSymbolChange({ item }){
    try{
        const consoleStoreState = yield safe(call(ConsoleStore.getStates), "NupViewSaga.js", "ConsoleStore.getStates");
        if (consoleStoreState.firstLoadedTab === NavType.NavSymbol) {
            consoleStoreState.isTabChanged = true;
    
            // fetching latest data if any new file was uploaded
            dispatch(getExternalDataSubMenu(ConsoleStore.getSettings(), true));
        }
        else {
            consoleStoreState.isTabChanged = true;
            consoleStoreState.firstLoadedTab = NavType.NavSymbol;
        }
    
        // const path = NavType.NavSymbol;
    
        consoleStoreState.isDGApiCompleted = false;
        // SettingsAction.setUserSettings({ ActiveNav: path });
        const state = TabDataGraphStore.getState();
        const userSettings = SettingsStore.getConsoleSettings();
        userSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity = item.periodicity;
        yield put(initPeriodicityButton(userSettings))
        state.periodicity = item.periodicity;
        state.Symbol = item.Symbol;
        TabDataGraphActionClass.resetStoreState();
        yield put(onPlotSymbol(state.Symbol));
        ConsoleStore.plotSymbol(state.Symbol);
        ListActions.updateMiniList(state.Symbol);
        yield safe(call(resetNupState), "NupViewSaga.js", "resetNupState");
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, handleSymbolChange ${error}`);
    }
}

function* setScaleByPeriodicity({ periodicity, newScale }) {
    try{
        const state = yield select(getState);
        const scaleByPeriodicity = yield select(getScaleByPeriodicity);
        let indexOf = 0;
        const structLength = scaleByPeriodicity.length;
        const userSetting = yield select(getUserSettings);
        for (let i = 0; i < structLength; i++) {
            if (scaleByPeriodicity[i].periodicity === periodicity) {
                indexOf = i;
                break;
            }
        }

        let scaleType = "FixedWonLogScale";

        if (newScale === "LOG (F)")
            scaleType = "FixedLogScale";
        else
        if (newScale === "LIN")
            scaleType = "WArithmaticScale";
        else
            scaleType = "LogScale";

        scaleByPeriodicity[indexOf].scale = scaleType;
        scaleByPeriodicity[indexOf].scaleLabel = newScale;
        yield put(updateScaleByPeriodicity(scaleByPeriodicity));
        userSetting.scaleByPeriodicity = scaleByPeriodicity;
        yield put(updateNupSettings(userSetting));
        SettingsStore.saveSettings()
        for(let item of state.visibleItems){
            for(let value of item){
                yield safe(fork(processNupData, value, state), "NupViewSaga.js", "processNupData");
            }
        }
        yield put(updateNupVisibleItem(state.visibleItems));
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, setScaleByPeriodicity ${error}`);
    }
}

function* getRowsCols(layout){
    switch (layout.lyOut) {
    case "2 x 2":
        return [2, 2];
    case "2 x 3":
        return [2, 3];
    case "3 x 2":
        return [3, 2];
    case "3 x 3":
        return [3, 3];
    case "4 x 4":
        return [4, 4];
    default:
        return [2, 2];
    }
}

function* updateSelection({ newSelection }) {
    try{
        const nupCharts = yield select(getNupCharts)
        let selected = yield select(getSelected);
        nupCharts[selected].sel = "icn";
        nupCharts[newSelection].sel = "icn-select";
        selected = newSelection;
        yield put(updateNupCharts(nupCharts));
        yield put({type: ActionTypes.UPDATE_NUP_SELECTED, selected});
        const colsRows = yield safe(call(getRowsCols,  nupCharts[newSelection]), "NupviewSaga.js", "getRowsCols");
        yield put(updateColsRows(colsRows));
        const  userSetting = yield select(getUserSettings);
        userSetting.selected = newSelection;
        yield put(updateNupSettings(userSetting))
        SettingsStore.saveSettings();
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateSelection ${error}`);
    }
    
}

function* updateDimension({ dimension }){
    try{
        yield delay(1);
        const state = yield select(getState);
        if(state.isResizing) return;
        if(state.dimension[state.selected].width != dimension.width || state.dimension[state.selected].height != dimension.height){
            state.settings.dimension[state.selected].height = dimension.height;
            state.settings.dimension[state.selected].width = dimension.width;
            yield put({type: ActionTypes.UPDATE_NUP_CHART_DIMENSION, dimension: state.settings.dimension});
            SettingsStore.saveSettings();
            for(let item of state.visibleItems){
                for(let value of item){
                    yield safe(fork(processNupData, value, state), "NupViewSaga.js", "processNupData");
                }
            }
            yield put(updateNupVisibleItem(state.visibleItems));
        }
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateDimension ${error}`);
    }
}

function* updateColumnPeriodicity({ periodicityObj }){
    try{
        const _state = yield select(getState);
        _state.nupCharts[_state.selected].per[periodicityObj.column] = periodicityObj.periodicity;
        yield put(updateNupCharts(_state.nupCharts))
        SettingsStore.saveSettings();
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateColumnPeriodicity ${error}`);
    }
}

function* singlePeriodicityChange({ periodicity }){
    try{
        yield put(updateNupPeriodicity(periodicity));
        const userSetting = yield select(getUserSettings);
        userSetting.graphType = periodicity;
        SettingsStore.saveSettings();
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, singlePeriodicityChange ${error}`);
    }
}

function* updateStory({ showStory }){
    try{  
        const _state = yield select(getState);
        if(_state.nupCharts[_state.selected].per < 1){
            yield put(updateShowStory(showStory));
        }
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateStory ${error}`);
    }
}

function* splitArray(array = [], increment, periodicity, selectedSymbol){
    const data = [];
    let symbolId = 0;
    let selectedIndex = -1;
    const length = array.length;
    for(let i=0; i< length; i += increment){
        let sData = []
        for(let j=i; j<i+increment && j< length; j++){
            const HeaderData = getHeaderData(null, array[j], periodicity, increment);
            let globalSelected = false;
            if(array[j].symbol === selectedSymbol){
                globalSelected = true;
                selectedIndex = j
            }
            let triggered = array[j].isTriggeredInstrument == undefined ? false : array[j].isTriggeredInstrument;
            sData.push({ Symbol: array[j].symbol, localSymbol: array[i].localSymbol, globalSelected, isLoading: true, isFlagged: array[j].isFlagged, isSelected: array[j].isSelected, instrumentType: array[j].instrumentType, graphData: null, symbolId, HeaderData, isTriggeredInstrument: triggered });
            symbolId++
        }
        data.push(sData);
    }
  return {data, selectedIndex};
}

function* splitsArray(array = [], increment, periodicity, selectedSymbol, datagraphperiodicity){
    const data = [];
    let symbolId = 0;
    let selectedIndex = -1;
    const length = array.length;
    for(let i=0; i< length; i++){
        let sData = []
        for(let j=0; j<increment; j++){
            const HeaderData = getHeaderData(null, array[i], periodicity[j], increment);
            let globalSelected = false;
            if(array[i].symbol === selectedSymbol && periodicity[j] === datagraphperiodicity){
                selectedIndex = i;
                globalSelected = true;
            }
            let triggered = array[i].isTriggeredInstrument == undefined ? false : array[i].isTriggeredInstrument;            
            sData.push({Symbol: array[i].symbol, localSymbol: array[i].localSymbol, globalSelected, isLoading: true, isFlagged: array[i].isFlagged, isSelected: array[i].isSelected, instrumentType: array[i].instrumentType, graphData: null, symbolId, HeaderData, isTriggeredInstrument: triggered });
            symbolId++;
        }
        data.push(sData);
    }
  return {data, selectedIndex};
}
function* processListData({ symbolList, isInitChart, selectedSymbol }){
    try{

        if(!symbolList){
            symbolList = yield select(getSymbolList);
        }
        let listId = ListStore.getSelectedListItem().SelectedActualListId;
        let Datagraphperiodicity = TabDataGraphStore.getState().periodicity;
        yield put(shouldUpdateNupComponent(true));
        yield put(updateSymbolList(symbolList));
        const _state = yield select(getState);
        let length = 0, isMissingSymbols = false;
        if(!_state.showChart){
            const userSetting = yield select(getUserSettings);
            userSetting.showChart = true;
            yield put(updateShowChart(true));
            SettingsStore.saveSettings();
        }
        const selectedLayout = _state.nupCharts[_state.selected];
        let listData = [];
        if(selectedLayout.per.length > 0){
            const periodicity = []
            selectedLayout.per.forEach((type) => {
                periodicity.push(PeriodicityHelper.getPeriodicityString(type));
            });
            listData = yield safe(call(splitsArray, symbolList,  _state.cols, periodicity, selectedSymbol, Datagraphperiodicity), "NupviewSaga.js", "splitArray");
        }
        else{
            const periodicity = PeriodicityHelper.getPeriodicityString(_state.graphType)
            listData = yield safe(call(splitArray, symbolList,  _state.cols, periodicity, selectedSymbol), "NupviewSaga.js", "splitsArray");
        }
        const isScrollAvailable = listData.selectedIndex !== -1;
        let startIndex = isScrollAvailable ? (_state.selected % 2 === 0) ? (listData.selectedIndex / _state.cols | 0) : listData.selectedIndex : 0;
        if((startIndex + _state.rows) > listData.data.length){
            if((listData.data.length - _state.rows) > 0){
                startIndex = listData.data.length - _state.rows;
            }
            else{
                startIndex = 0;
            }
        }
    
        if(isInitChart){
            yield put(updateGlobalSelectedIndex(listData.selectedIndex))
        }
        listData = listData.data;
        if(!isInitChart){
            let lData = yield select(getListData);
            length = lData.length;
            if(lData[length-1].length !== listData[length-1].length ){
                isMissingSymbols = true;
                for(let i = lData[length-1].length; i < listData[length-1].length; i++){
                    lData[length-1].push(listData[length-1][i]);
                }
            }
            lData.map((item, index)=>listData[index] = item);
        }
        yield put(updateNupListData(listData, listId));
        yield put(updateIsScrollAvailable(isScrollAvailable))
        yield put(shouldUpdateNupComponent(false));
        if(isInitChart){
            if(!isScrollAvailable)
             yield put(updateRedraw(isInitChart));
             ListActions.toggleMetricLibrary();
            yield safe(call(initChart, { firstVisibleIndex: startIndex, lastVisibleIndex: startIndex + _state.rows, isScrollAvailable }), "NupviewSaga.js", "initChart");
        }
        else if(isMissingSymbols){
            yield safe(call(initChart, { firstVisibleIndex: length - 1, lastVisibleIndex: length, isScrollAvailable }), "NupviewSaga.js", "initChart");
        }
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, processListData ${error}`);
    }
}

function* initChart({ firstVisibleIndex, lastVisibleIndex, isScrollAvailable = false }){
    try{
        console.time("Nup view total loading time")
        
        yield put(shouldUpdateNupComponent(true));
        firstVisibleIndexs = firstVisibleIndex, lastVisibleIndexs = lastVisibleIndex;
        yield put({type: ActionTypes.UPDATE_NUP_CHART_LOADING, loading: true})
        const _state = yield select(getState);
        const symbolList = [];
        const visibleItems = _state.listData.slice(firstVisibleIndex, lastVisibleIndex);
        const isMarketHours = WorkSpaceRealTimePrices.IsNasdaqMarketHours();
        if(_state.nupCharts[_state.selected].per.length >0)
            visibleItems.map((item) => { if (!item[0].nupData || isMarketHours) symbolList.push({ Symbol: item[0].Symbol, isTriggeredInstrument: item[0].isTriggeredInstrument }) });
        else
            visibleItems.map((item) => item.map((value) => { if (!value.nupData || isMarketHours) symbolList.push({ Symbol: value.Symbol, isTriggeredInstrument: value.isTriggeredInstrument }) }));
        if(symbolList.length > 0){
            const [nupData, nupHSFRequest] = yield safe(call(processChart, { symbolList }), "NupviewSaga.js", "processChart");
            console.time("Nup charts processing time")
            let i = 0;
            if(nupData.CardItemReply.length > 0 && nupData.CardItemReply.length === nupHSFRequest.length){
                for(let item of visibleItems){
                    for(let value of item){
                        if((!value.graphData) || isMarketHours){
                            value.nupData = nupData.CardItemReply[i];
                            value.nupHSFRequest = nupHSFRequest[i++]
                            value.graphData = null;
                            yield safe(fork(processNupData, value, _state), "NupViewSaga.js", "processNupData");

                            /* Adding a log to know whether the data is pulled from DB or ES */
                            const period = PeriodicityHelper.getPeriodicityString(value.nupHSFRequest.GraphType);
                            const sym = value.nupHSFRequest.Symbol
                            const txt = value.nupData.HsfData.isIntradayDataSource ? 'ES' : 'DB';
                            console.log(`%c\n*** ${sym} - ${period} - Data is pulled from ${txt} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');
                        }
                    }
                }
            } 
            console.timeEnd("Nup charts processing time");
        }
        visibleItems.map((item)=> item.map((value)=> value.isLoading = false));
        yield put(updateNupVisibleItem(visibleItems));
        yield put(updatePeriodicityButtonState(false));
        yield put({type: ActionTypes.UPDATE_NUP_CHART_LOADING, loading: false})
        yield put(shouldUpdateNupComponent(false));
        if(isScrollAvailable){
            yield put(updateIsScrollAvailable(false))
        }
        console.timeEnd("Nup view total loading time")
        if(isMarketHours){
            const quoteSymbol = [];
            let retainSymbols = [], symbolsToRemove = [];
            visibleItems.map((item)=> item.map((value)=>{ if(!quoteSymbol.includes(value.Symbol)) quoteSymbol.push(value.Symbol) }))
            const qSymbol = yield select(getNupQuoteSymbol)
            let cloneSubcribeItems = clone(quoteSymbol);
            if (isEqual(qSymbol, cloneSubcribeItems))
                return;
            if (qSymbol && qSymbol.length > 0) {
                retainSymbols = intersection(qSymbol, cloneSubcribeItems);
            }
            if (retainSymbols.length > 0) {
                symbolsToRemove = [...qSymbol].filter((item) => !retainSymbols.includes(item));
                cloneSubcribeItems = [...cloneSubcribeItems].filter((item) => !retainSymbols.includes(item));
            }
            
            yield fork(registerWebSync, cloneSubcribeItems, symbolsToRemove, _state.IsNASDAQBasic);
            yield put(updateNupQuoteSymbol(quoteSymbol))
        }
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, initChart ${error}`);
    }
}

let lastSelectedIndex;
function* updateChartSelection( {selectedIndex, keyType}){
    try{
        const _state = yield select(getState);
        const listData = yield select(getListData);
        const sIndex = yield select(getSelectdIndex);
        let selectedIndexs = []
        switch(keyType){
            case KeyTypes.ctrlKey:
                sIndex.push(selectedIndex[0]*_state.cols + selectedIndex[1]);
                let set  = new Set(sIndex)
                selectedIndexs = [...set]
                listData[selectedIndex[0]][selectedIndex[1]].isSelected = true;
                break;
            case KeyTypes.shiftKey:
                sIndex.map((item)=>{
                    listData[item/_state.cols | 0][item%_state.cols].isSelected = false;
                })
                if(!lastSelectedIndex){
                    selectedIndexs.push(selectedIndex[0]*_state.cols + selectedIndex[1]);
                    listData[selectedIndex[0]][selectedIndex[1]].isSelected = true;
                }
                else{
                    let start = Math.min(lastSelectedIndex[0]*_state.cols + lastSelectedIndex[1],selectedIndex[0]*_state.cols + selectedIndex[1])
                    let end = Math.max(lastSelectedIndex[0]*_state.cols + lastSelectedIndex[1],selectedIndex[0]*_state.cols + selectedIndex[1]) + 1
                    for(; start < end; start++){
                        selectedIndexs.push(start);
                        listData[start/_state.cols | 0][start%_state.cols].isSelected = true;
                    } 
                }
                break;
            case KeyTypes.None:
                sIndex.map((item)=>{
                    listData[item/_state.cols | 0][item%_state.cols].isSelected = false;
                })
                selectedIndexs.push(selectedIndex[0]*_state.cols + selectedIndex[1]);
                listData[selectedIndex[0]][selectedIndex[1]].isSelected = true;
                break;
            default:
                sIndex.map((item)=>{
                    listData[item/_state.cols | 0][item%_state.cols].isSelected = false;
                })
                selectedIndexs.push(selectedIndex[0]*_state.cols + selectedIndex[1]);
                listData[selectedIndex[0]][selectedIndex[1]].isSelected = true;
                break;
            }
            lastSelectedIndex = selectedIndex;
            yield put(updateSelectedIndex(selectedIndexs));
            let length = _state.nupCharts[_state.selected].per.length;
            length = length ===0 ? 1 : length;
            const sIndexs = [];
            selectedIndexs.map((item)=> {
                let value = parseInt(item/length)
                if(!sIndexs.includes(value)){
                    sIndexs.push(value)
                }
            })
            GridStore.setSelectedIndexes(sIndexs)
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateChartSelection ${error}`);
    }
}
    
function* updateFlag({ selectedIndex, value }){
    try{
        const listData = yield select(getListData);
        listData[selectedIndex[0]][selectedIndex[1]].isFlagged = value;
        yield put(updateNupListData(listData));
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateFlag ${error}`);
    }
}

function* updateGlobalFlag({ value }){
    try{
        const _state = yield select(getState);
        const listData = _state.listData;
        const selectedIndex = _state.selectedIndex;
        let length = _state.nupCharts[_state.selected].per.length;
        length = length ===0 ? 1 : length;
        const sIndex = [];
        selectedIndex.map((item)=> {
            let value = parseInt(item/length)
            if(!sIndex.includes(value)){
                sIndex.push(value)
            }
        })
        sIndex.map((item)=>{
            _state.selected % 2 === 0 ? listData[item/_state.cols | 0][item%_state.cols].isFlagged = value : listData[item][0].isFlagged = value;
        });
        yield put(updateNupListData(listData));
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateGlobalFlag ${error}`);
    }
}

function* updateAllGlobalFlag({ value }){
    try{
        const listData = yield select(getListData);
        listData.map((item)=> item.map((val)=> val.isFlagged = value));
        yield put(updateNupListData(listData));
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateAllGlobalFlag ${error}`);
    }
}
function* updateSelectAll(){
    try{
        const listData = yield select(getListData);
        let i=0;
        const selectedIndexs = [];
        listData.map((item)=> item.map((val)=>{ 
            selectedIndexs.push(i++);
            val.isSelected = true 
        }));
        yield put(updateSelectedIndex(selectedIndexs));
        yield put(updateNupListData(listData));
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateSelectAll ${error}`);
    }
}
function* updateNupListFlages({ symbolList, properties }){
    try{
        const listData = yield select(getListData);
        const nupCharts = yield select(getNupCharts);
        const selected = yield select(getSelected);
         let i = 0;
         if(nupCharts[selected].per.length > 0){
            listData.map((item)=> 
                {
                    properties.map((property)=> item[0][property] = symbolList[i][property]);
                i++;
                })
         }
         else{
             listData.map((item)=> item.map((val)=>
                 {
                     properties.map((property)=> val[property] = symbolList[i][property]);
                 i++;
             }))
         }
        yield put(updateNupListData(listData));
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateNupListFlages ${error}`);
    }
}

function* updateEpsRpsWithPeriodicity({epsRps, periodicity}){
    try{
        const _state = yield select(getState);
        const settings = yield select(getUserSettings);
        _state.epsSelected[periodicity] = epsRps;
        settings.epsSelected[periodicity] = epsRps;
        yield put(updateEpsRps(_state.epsSelected));
        SettingsStore.saveSettings();
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateEpsRpsWithPeriodicity ${error}`);
    }
}

function* updateNasdaq({ IsNASDAQBasic }){
    try{
        const quoteSymbol = yield select(getNupQuoteSymbol);
        if(IsNASDAQBasic === IntradaySourceType.IntradaySource_NASDAQ){
            yield put(updateNupRTVolume(true));
            yield fork(registerWebSync, quoteSymbol, [], IsNASDAQBasic === IntradaySourceType.IntradaySource_NASDAQ);
        }
        else if(webSyncChannelBuffer && serverTickChannelBuffer){
            yield put(updateNupRTVolume(false));
            WebSyncUtil.UnsubscribeSymbolsAsync(quoteSymbol);
            webSyncChannelBuffer.close();
            serverTickChannelBuffer.close();
        }
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateNasdaq ${error}`);
    }
}

function* resetNupState(){
    try{
        if(webSyncChannelBuffer && serverTickChannelBuffer){
            const quoteSymbol = yield select(getNupQuoteSymbol)
            WebSyncUtil.UnsubscribeSymbolsAsync(quoteSymbol);
            webSyncChannelBuffer.close();
            serverTickChannelBuffer.close();
        }
        ListActions.toggleMetricLibrary();
        GridStore.resetSelectedIndexes();
        yield put(updateSelectedIndex([]));
        yield put(updateNupQuoteSymbol([]));
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, resetNupState ${error}`);
    }
}

function* updateNonImportListSelection({ isNonImported }){
    try{
        const state = yield select(getState);
        if(!isNonImported && state.showChart){
            ONeilViewStore.showChart = true;
        }
        else{
            ONeilViewStore.showChart = false;
        }
    }
    catch(error){
        console.log(`Error occurs in NupViewSaga.js, updateNonImportListSelection ${error}`);
    }
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchNupSymbolChange(){
    yield takeLatest(ActionTypes.NUP_SYMBOL_CHANGE, handleSymbolChange)
}

export function* watchProcessList() {
    yield takeLatest(ActionTypes.PROCESS_LIST, processList);
}

export function* watchProcessNupListData() {
    yield takeEvery(ActionTypes.PROCESS_NUP_LIST_DATA, processListData);
}

export function* watchInitNupChart() {
    yield takeLatest(ActionTypes.INIT_NUP_CHART, initChart);
}

export function* watchUpdateNupSelection() {
    yield takeLatest(ActionTypes.UPDATE_NUP_SELECTION, updateSelection);
}

export function* watchUpdateChatAppearance() {
    yield takeLatest(ActionTypes.UPDATE_NUP_CHART_APPEARANCE, setScaleByPeriodicity);
}

export function* watchUpdateDimension() {
    yield takeLatest(ActionTypes.UPDATE_CHART_DIMENSION, updateDimension);
}

export function* watchNupColumnPeriodicityChange(){
    yield takeLatest(ActionTypes.NUP_COLUMN_PERIODICITY_CHANGE, updateColumnPeriodicity);
}

export function* watchNupSinglePeriodicityChange(){
    yield takeLatest(ActionTypes.NUP_SINGLE_PERIODICITY_CHANGE, singlePeriodicityChange);
}

export function* watchUpdateNupStore(){
    yield takeLatest(ActionTypes.UPDATE_NUP_STORE, updateStore);
}

export function* watchUpdateStory(){
    yield takeLatest(ActionTypes.UPDATE_NUP_STORY, updateStory);
}

export function* watchUpdateChartSelection(){
    yield takeLatest(ActionTypes.UPDATE_CHART_SELECTION, updateChartSelection);
}

export function* watchUpdateNupListFlages(){
    yield takeLatest(ActionTypes.UPDATE_NUP_LIST_FLAGES, updateNupListFlages);
}

export function* watchUpdateGlobalFlag(){
    yield takeLatest(ActionTypes.UPDATE_GLOBAL_FLAG, updateGlobalFlag);
}

export function* watchUpdateAllGlobalFlag(){
    yield takeLatest(ActionTypes.UPDATE_ALL_GLOBAL_FLAG, updateAllGlobalFlag);
}

export function* watchSelectAll(){
    yield takeLatest(ActionTypes.UPDATE_SELECT_ALL, updateSelectAll);
}

export function* watchUpdateNupFlag(){
    yield takeLatest(ActionTypes.UPDATE_NUP_FLAG, updateFlag);
}

export function* watchNupUpdateEpsRps(){
    yield takeLatest(ActionTypes.UPDATE_EPS_RPS_WITH_PERIODICITY, updateEpsRpsWithPeriodicity);
}

export function* watchUpdateNasdaqBasic(){
    yield takeLatest(ActionTypes.UPDATE_NUP_ISNASDAQBASIC, updateNasdaq);
}

export function* watchResetNupState(){
    yield takeLatest(ActionTypes.RESET_NUP_STATE, resetNupState);
}

export function* watchImportListSelection(){
    yield takeLatest(ActionTypes.NUP_IS_NON_IMPORTED, updateNonImportListSelection);
}