import React, { Component } from 'react'
import AboutDialog from './AboutDialog'
import SelectionDropup from './SelectionDropup';
import Tooltip from './Tooltip';
import { connect } from "react-redux";
import ListManagerTabType from "ListManagerTabType";
import ListStore from "ListStore";
import GridStore from "GridStore";
import ONeilViewStore from "ONeilViewStore";
import ListActions from "ListActions";
import { SettingsConstants } from '../../../../Constants/SettingsConstants';
import { processList, processNupListData } from '../../../../Actions/NupViewActions';

class ListMenu extends Component {

    constructor(){
        super();

        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.hideContextMenu = this.hideContextMenu.bind(this);
        this.listStoreChange = this.listStoreChange.bind(this);
        this.setList = this.setList.bind(this);
        this.setChart = this.setChart.bind(this);

        this.state =  { 
            showContextMenu: false,
            showDailog: false,
            isOneilList: ListStore.getNavListManagerSettings().SelectedTabKey === ListManagerTabType.Oneil,
            moduleType: '',
            top: 0,
            left: 0
        }
    }

    componentDidMount(){
        document.addEventListener('click', this.hideContextMenu);
        ListStore.addChangeListener(this.listStoreChange);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.hideContextMenu);
        ListStore.removeChangeListener(this.listStoreChange);
    }

    listStoreChange(){
        const _currentAction = ListStore.getCurrentAction()
        if(_currentAction === SettingsConstants.ActionTypes.LIST_EXPLORER_UPDATE_TAB){
            this.setState({
                isOneilList: ListStore.getselectedTabKey() === ListManagerTabType.Oneil
            })
        }
      }

    onContextMenuClick(e, moduleType){
        e.preventDefault();
        this.setState({showContextMenu: true, top: e.clientY - this.componentRefs.getBoundingClientRect().top- 30, left: e.clientX - 10, moduleType})
    }
    hideContextMenu(){
        this.setState({showContextMenu: false});
    }
    handleClick(){
        this.setState({ showContextMenu: false, showDailog: true})
    }

    setList() {
        GridStore.resetSelectedIndexes();
        ONeilViewStore.showChart = false;
        this.props.processList()
    }
    
    setChart() {
    const selectedIndex = GridStore.getSelectedIndexes()
    GridStore.resetSelectedIndexes();
    const symbolList = ONeilViewStore.getSourceData();
    let selectedSymbol = null;
    if(selectedIndex.length > 0 && !ONeilViewStore.showChart){
        selectedSymbol = symbolList[selectedIndex.pop()].symbol;
        ListActions.updateMiniList(selectedSymbol);
    }
    else{
        selectedSymbol = ListStore.getSelectedSymbol()
    }
    ONeilViewStore.showChart = true;
    this.props.processNupListData(symbolList, true, selectedSymbol)
    }
    render() {
        if(this.state.isOneilList && !this.props.isNonImported)
            return (<>
            <div className="several-view" ref={ (refs) => this.componentRefs = refs}>
                    <ul>
                        <li className = {this.props.showChart ? "" : "selective"}>
                            <Tooltip text = {"Standard List View"} tooltipClass = "several-view-tooltip" placement = "top" space = {30} fixed = {true}>
                                <span onClick={this.setList} onContextMenu={(e)=> this.onContextMenuClick(e, 'LM_Browser')}>
                                    <i className="icn-list-standard">LM</i>
                                </span>
                            </Tooltip>
                        </li>
                        <li className = {this.props.showChart ? "selective" : ""}>
                            <Tooltip text = {"Multi-chart View"} tooltipClass = "several-view-tooltip" placement = "top" space = {30}  fixed = {true}>
                                <span onClick={this.setChart}  onContextMenu={(e)=> this.onContextMenuClick(e, 'Multi_Chart_View')}>
                                    <i className="icn-list-multi-chart">NUP</i>
                                </span>
                                <SelectionDropup />
                            </Tooltip>
                        </li>
                        {/* <li>
                            <Tooltip text = {"List Analytics"} tooltipClass = "several-view-tooltip" placement = "top" space = {30}  fixed = {true}>
                                <span  onContextMenu={(e)=> this.onContextMenuClick(e, 'List_Analytics')}>
                                    <i className="icn-list-analytics">Size</i>
                                </span>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip text = {"Quote board"} tooltipClass = "several-view-tooltip" placement = "top" space = {30}  fixed = {true}>
                                <span onContextMenu={(e)=> this.onContextMenuClick(e, 'Quote_Board')}>
                                    <i className="icn-list-quote-board">QT</i>
                                </span>
                            </Tooltip>
                        </li> */}
                    </ul>
                        {this.state.showContextMenu && <div className='abouts-list-view-context' style={{ top: this.state.top + "px", left: this.state.left + "px"}}>
                            <ul className='context-menu-inner list-contex'>
                                <li onClick={this.handleClick}>
                                    <span className= "default">About</span>
                                </li>
                            </ul>
                        </div>}
                    </div>
                {this.state.showDailog && <AboutDialog closeDialog= {()=> this.setState({ showDailog:false, moduleType: '' })}  moduleType = {this.state.moduleType } />}
            </>)
        else
            return null;
    }
}

const mapStateToProps = ({ listViewReducer }) => {
    const { NupViewReducer } = listViewReducer;

    return ({ showChart: NupViewReducer.showChart, isNonImported: NupViewReducer.isNonImported })
}

const mapDispactchToProps = (dispatch) =>({
    processNupListData: (symbolList, initChart, selectedSymbol)=> dispatch(processNupListData(symbolList, initChart, selectedSymbol)),
    processList: ()=> dispatch(processList())
})

export default connect(mapStateToProps, mapDispactchToProps)(ListMenu) 