import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

let _methodType = HttpMethodType.GET;

class GetOwnershipListCatalogRequest extends BaseServiceRequest {

  constructor(listId, actualListId, searchText, sortCols, needColumnsInfo, addlCatalogRequests,  useColFilters, saveColFilters, customerOrder, colsetId, reorderIds, pageIndex, size, isUserList, isMinilist, isNotImportedList = false) {
    super();
    super.addRequestUri("ownershipbasic.pb");
    super.addPathParameter(listId ? listId.toString() : '');
    super.addRequestParameter("rts","0");
    super.addRequestParameter("aid", actualListId ? actualListId.toString() : '');
    if(searchText) super.addRequestParameter("srch", searchText.trim()); // trim check

    if (sortCols !== null && sortCols.length > 0)
     {
        /* string sortParams = string.Empty;
         var sortColumnData = sortCols.ToList().Select(kvp => new { DataItemId = kvp.Key, kvp.Value.SortOrdinal, kvp.Value.SortDirection }).OrderBy(c => c.SortOrdinal);
         sortParams = sortColumnData.Aggregate(sortParams, (current, columnData) => current + (columnData.DataItemId + "," + (columnData.SortDirection == ListMgrSortDirectionEnum.Descending ? 0 : 1) + ";")); */
         let sortParams = '';
         for(let i = 0; i < sortCols.length; i++){
           const asc = sortCols[i].sortAsc ? 1 : 0;
           sortParams += `${sortCols[i].dataItemId},${asc};`;
         }
         super.addRequestParameter("sort", sortParams);//
     }

    super.addRequestParameter("rn", "1");//1
    super.addRequestParameter("c", parseInt(needColumnsInfo).toString());//1
    super.addRequestParameter("ucf", parseInt(useColFilters).toString());//1
    super.addRequestParameter("scf", parseInt(saveColFilters).toString());//0
    super.addRequestParameter("iml", parseInt(isMinilist).toString());//0
    super.addRequestParameter("iul", parseInt(isUserList).toString()); // 0  
    super.addRequestParameter("icf", "1");//1
    super.addRequestParameter("so", parseInt(customerOrder).toString());//0
    super.addRequestParameter("cfv", "2");//2
    super.addRequestParameter("pgi", pageIndex.toString());//0
    super.addRequestParameter("size", size.toString());//1000
    const isNotImportedListValue = isNotImportedList ? "1" : "0";
    super.addRequestParameter("nImpList", isNotImportedListValue);//1
  
    if (colsetId.toString() !== "-1")  super.addRequestParameter("csid", colsetId.toString());//comment

    if (addlCatalogRequests !== null) {
        _methodType = HttpMethodType.POST;
        super.setContent(addlCatalogRequests.encode());
    }
    
  }

  getMethod(){
    return _methodType;
  }

  getSubSystem(){
    return SubSystemType.LIST;
  }

  getMessageName(){
    return "ListCatalogResponse";
  }
}

export default GetOwnershipListCatalogRequest;
