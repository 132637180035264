import AppDispatcher from "AppDispatcher";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";
import { SettingsConstants } from "../Constants/SettingsConstants.js";
import { GetMetricDefinition } from "../ServiceApi/Apis/GraphApi.js";

class ConsoleActionsClass {
  exceptionHandle(errorObj, isProd = false, error = null, sessionExpired = false, header = 'Error Report', isCustomError = false) {
    AppDispatcher.handleServerAction({
      actionType: ConsoleConstants.ActionTypes.HANDLE_EXCEPTION,
      data: { errorReport: errorObj, error: error, sessionExpired: sessionExpired, header: header, isCustomError: isCustomError, isProd: isProd }
    });
  }
  exceptionHandlePrint(errorMsg) {
    AppDispatcher.handleServerAction({
      actionType: ConsoleConstants.ActionTypes.HANDLE_PRINT_ERROR_PAGE,
      data: { errorReport: errorMsg }
    });
  }
  checkSupportHours() {
    AppDispatcher.handleServerAction({
      actionType: ConsoleConstants.ActionTypes.CHECK_SUPPORT_HOURS,
      data: {}
    });
  }  
  toggleSupportDialog(isOpen) {
    AppDispatcher.handleServerAction({
      actionType: ConsoleConstants.ActionTypes.TOGGLE_SUPPORT_DIALOG,
      data: { isOpen: isOpen }
    });
  }  
  toggleLiveSupportDialog(isOpen) {
    AppDispatcher.handleServerAction({
      actionType: ConsoleConstants.ActionTypes.TOGGLE_LIVE_SUPPORT_DIALOG,
      data: { isOpen: isOpen }
    });
  } 
  startPrint(symbols, listName, symbolType, emailData, printType, periodicity, listId, searchKeyword = "") {
    AppDispatcher.handleServerAction({
      actionType : ConsoleConstants.ActionTypes.START_PRINT,
      data: { symbols: symbols, listName: listName, symbolType: symbolType, email : emailData, printType: printType, periodicity: periodicity, listId: listId, searchKeyword: searchKeyword }
    });
  }  
  ShowPrintPopup(){
    AppDispatcher.handleServerAction({
      actionType : ConsoleConstants.ActionTypes.SHOW_PRINT_POPUP
    });
  }
  cancelPrint(printRequestId) {
    AppDispatcher.handleServerAction({
      actionType : ConsoleConstants.ActionTypes.CANCEL_PRINT,
      data: { printRequestId : printRequestId }
    });
  }
  closePrintStatus(isOpen) {
    AppDispatcher.handleServerAction({
      actionType: ConsoleConstants.ActionTypes.CLOSE_PRINT_STATUS,
      data: { isOpen: isOpen }
    });
  }
  togglePopUpBlockerAlert(isOpen) {
    AppDispatcher.handleServerAction({
      actionType: ConsoleConstants.ActionTypes.TOGGLE_POPUP_ALERT,
      data: { isOpen: isOpen }
    });
  }
  setEntitleMentsInfo() {
    AppDispatcher.handleServerAction({
      actionType: ConsoleConstants.ActionTypes.SET_ENTITLEMENTS_INFO,
      data: { }
    });
  }
}

const ConsoleActions = new ConsoleActionsClass();
export default ConsoleActions;

export const SetConsoleDate = (date) => {
  AppDispatcher.handleViewAction({
    actionType: ConsoleConstants.ActionTypes.SET_CONSOLE_DATE,
    data: { endDate: date }
  });
}

export const GetMetricDefinitionAction = () => {
  GetMetricDefinition().then((result) => {
    AppDispatcher.handleViewAction({
      actionType: ConsoleConstants.ActionTypes.GET_METRIC_DEFINITION,
      data: result
    });
  });
}

export const RouteToDataGraph =  ( symbol, navType ) => { 
  AppDispatcher.handleViewAction({
    actionType: ConsoleConstants.ActionTypes.ROUT_TO_GRAPH,
    data: {symbol: symbol, navType: navType}
  });  
}

export const GetRestMetricDefinitionAction = () => {

  GetRestMetricDefinition().then((result) => {
    AppDispatcher.handleViewAction({
      actionType: ConsoleConstants.ActionTypes.GET_REST_METRIC_DEFINITION,
      data: result
    });
  });
}

export const PopulateCurrency = (currency) => {
  AppDispatcher.handleViewAction({
    actionType: ConsoleConstants.ActionTypes.POPULATE_CURRENCY,
    data: currency
  });
}

export const enableCurrencyConversion = (currencyConversionFlag) => {
  AppDispatcher.handleServerAction({
    actionType: SettingsConstants.ActionTypes.ENABLE_CURRENCY_CONVERSION,
    data: currencyConversionFlag
  });

}
export const onCurrencyChanged = (finTargetCurrencyCode) => {
  // switch (result.SymTypeEnum) {
  //   case SymbolType.USSTOCK:
  AppDispatcher.handleServerAction({
    actionType: SettingsConstants.ActionTypes.DATAGRAPH_STOCK_CURRENCY_CHANGED,
    data: finTargetCurrencyCode   
  });
  // break;
  // case SymbolType.CURRENCY:
  //   AppDispatcher.handleServerAction({
  //     actionType: SettingsConstants.ActionTypes.CURRENCY_SYMBOL_CHANGED,
  //     data: result
  //   });
  //   break;
  // case SymbolType.ETF:
  //   AppDispatcher.handleServerAction({
  //     actionType: SettingsConstants.ActionTypes.ETF_SYMBOL_CHANGED,
  //     data: result
  //   });
  //   break;
  // case SymbolType.FundClosedEnd:
  //   AppDispatcher.handleServerAction({
  //     actionType: SettingsConstants.ActionTypes.CEF_SYMBOL_CHANGED,
  //     data: result
  //   });
  //   break;
  // case SymbolType.FUND:
  //   AppDispatcher.handleServerAction({
  //     actionType: SettingsConstants.ActionTypes.FUND_SYMBOL_CHANGED,
  //     data: result
  //   });
  //   break;
  // case SymbolType.WONINDUSTRYGROUP197:
  // case SymbolType. WONMAJORINDUSTRY89:
  // case SymbolType. WONSECTOR11:
  // case SymbolType. WONSUBSECTOR33:
  //   AppDispatcher.handleServerAction({
  //     actionType: SettingsConstants.ActionTypes.INDUSTRYGROUP_SYMBOL_CHANGED,
  //     data: result
  //   });
  //   break;
  // default:
  // AppDispatcher.handleServerAction({
  //   actionType: SettingsConstants.ActionTypes.STOCK_SYMBOL_CHANGED,
  //   data: result
  // });
  // break;
  // }

}
