import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import TabAllMessageCenter from "./TabAllMessageCenter.jsx";
// import TabAlertMessageCenter from "./TabAlertMessageCenter.jsx";
// import TabSharedMessageCenter from "./TabSharedMessageCenter.jsx";
// import TabPanarayMessageCenter from "./TabPanarayMessageCenter.jsx";
import { AlertConstants } from "AlertConstants";
import AlertStore from "AlertStore";
import MessageCenterWindowType from "../../Constants/MessageCenterWindowType";
import AlertActions from "AlertActions";
import ErrorBoundary from "ErrorBoundary";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
export default class MessageCenterDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            key : 1, 
            showReadMessage : AlertStore.getMessageCenterNotifications(this.props.MessageCenterWindowType).showReadMessage 
        }
        this.onShowHideReadMessageClick = this.onShowHideReadMessageClick.bind(this);
        this.alertStoreStateChange = this.alertStoreStateChange.bind(this);
        this.alertStoreStateChangeNew = this.alertStoreStateChangeNew.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.getTabTitle = this.getTabTitle.bind(this);
    }

    onShowHideReadMessageClick() {
        AlertStore.setShowReadMessage(!this.state.showReadMessage);

        this.setState({
            showReadMessage: !this.state.showReadMessage
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState === this.state) {
            return false;
        }
        return true;
    }

    componentDidMount() {
        AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.OPEN_MESSAGE_CENTER);
        AlertStore.addChangeListener(this.alertStoreStateChangeNew, AlertConstants.ActionTypes.NW_RESET_UNREAD_MESSAGES);
        AlertStore.addChangeListener(this.alertStoreStateChangeNew, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
    }

    componentWillUnmount() {
        AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.OPEN_MESSAGE_CENTER);
        AlertStore.removeChangeListener(this.alertStoreStateChangeNew, AlertConstants.ActionTypes.NW_RESET_UNREAD_MESSAGES);
        AlertStore.removeChangeListener(this.alertStoreStateChangeNew, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
    }

    alertStoreStateChange() {
        if(this.state.open)
        {
            setTimeout(() => {
                AlertActions.resetUnreadNotificationMessages(MessageCenterWindowType.All); 
            }, 0);
           
        }
        
        AlertStore.setMessageCenterState(!this.state.open);

        this.setState({
            open: !this.state.open,
        })       
    }

    alertStoreStateChangeNew() {
        this.setState({});
    }

    closeDialog() {
        AlertActions.resetUnreadNotificationMessages(MessageCenterWindowType.All);

        this.setState({
            open: false
        })

        AlertStore.setMessageCenterState(false);
    }

    getInitialState() {
        return {
            key: 1,
        };
    }

    handleSelect(key) {
        this.setState({ key : key});
    }

    getTabTitle(messageCenterWindowType) {
        let title = "";

        let notificationData = AlertStore.getMessageCenterNotifications(messageCenterWindowType);

        switch (messageCenterWindowType) {
            case MessageCenterWindowType.All:
                title = LocalizationStore.getTranslatedData("mc_all","All");
                break;
            case MessageCenterWindowType.Alert:
                title = LocalizationStore.getTranslatedData("mc_alts","Alerts");
                break;
            case MessageCenterWindowType.SharedItems:
                title = LocalizationStore.getTranslatedData("set_shar_si","Shared Items");
                break;
            case MessageCenterWindowType.PanarayMessages:
                title = LocalizationStore.getTranslatedData("mc_pm","PANARAY Messages");
                break;
            default:
                break;
        }

        if (notificationData.unreadCount > 0) {
            title = title + " (" + notificationData.unreadCount + ")";
        }

        return title;
    }

    render() {
        const openDialogClass = this.state.open ? " dialog-open" : "";
        return (
            <ErrorBoundary>
                <div id="messageCenter" className="message-center-dialog-wrap">
                    <div className={"modal-popup mess-dialog message-center-dialog" +  openDialogClass} id="messageCenterDiv" style={{display : this.state.open ? 'block': 'none'}}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className="modal-title">
                                        <span className="cap-header">{LocalizationStore.getTranslatedData("mc_title","MESSAGE CENTER")}</span>
                                        <button type="button" onClick={this.closeDialog} className="btn btn-xxs btn-secondary btn-close" data-effect="fadeOut" ><span className="icn-close" ></span></button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    {/* <div className="inner-block"> */}
                                        <div className="message-center-tabs disable-drag">
                                            <Tabs className="messag-center-tabing" activeKey={this.state.key} onSelect={this.handleSelect} id="controlled-tab-example">
                                                <Tab className="disable-drag" eventKey={1} title={this.getTabTitle(MessageCenterWindowType.All)}><TabAllMessageCenter MessageCenterWindowType={MessageCenterWindowType.All} onShowHideReadMessageClick = {this.onShowHideReadMessageClick} showReadMessage = {this.state.showReadMessage} activeKey={this.state.key}/></Tab>
                                                <Tab className="disable-drag" eventKey={2} title={this.getTabTitle(MessageCenterWindowType.Alert)}><TabAllMessageCenter MessageCenterWindowType={MessageCenterWindowType.Alert} onShowHideReadMessageClick = {this.onShowHideReadMessageClick} showReadMessage = {this.state.showReadMessage} activeKey={this.state.key}/></Tab>
                                                <Tab className="disable-drag" eventKey={3} title={this.getTabTitle(MessageCenterWindowType.SharedItems)}><TabAllMessageCenter MessageCenterWindowType={MessageCenterWindowType.SharedItems} onShowHideReadMessageClick = {this.onShowHideReadMessageClick} showReadMessage = {this.state.showReadMessage} activeKey={this.state.key}/></Tab>
                                                <Tab className="disable-drag" eventKey={4} title={this.getTabTitle(MessageCenterWindowType.PanarayMessages)}><TabAllMessageCenter MessageCenterWindowType={MessageCenterWindowType.PanarayMessages} onShowHideReadMessageClick = {this.onShowHideReadMessageClick} showReadMessage = {this.state.showReadMessage} activeKey={this.state.key}/></Tab>
                                            </Tabs>
                                        </div>
                                        <div>
                                        </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}