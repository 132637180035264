class VolumeMA {
    CalculateVolumeMA(stockQuotes = [], period, lastNode) {
        let vMaValues = [];
        if (stockQuotes.length < 1) return vMaValues;
        let dataNodes = stockQuotes.filter((t) => t._volume > -1);
        var prcLength = dataNodes.length;
        var firstRound = false;
        var movingTotalVolume = 0;
        var xPosition = 0;
        var sPosition = 0;
        var setPrev = false;

        if (prcLength < 1) return vMaValues;

        var currentPricePoint = lastNode;

        for (; sPosition < prcLength; sPosition++) {
            if (stockQuotes[sPosition].IsVisible)
                break;
        }

        for (; currentPricePoint < prcLength - period && stockQuotes[sPosition]; currentPricePoint++ , sPosition++) {
            var info;

            while (stockQuotes[sPosition]._volume < 0) {
                info =
                    {
                        Date: stockQuotes[sPosition].Date,
                        Close: 0,
                        Volume: stockQuotes[sPosition]._volume
                    };
                vMaValues.push(info);
                sPosition++;
                xPosition++;
            }

            info =
                {
                    Date: stockQuotes[sPosition].Date,
                    Close: 0,
                    Volume: stockQuotes[sPosition]._volume
                };

            vMaValues.push(info);

            var totalPoints;
            if (!firstRound) {
                var i;
                totalPoints = 0;
                
                for (i = currentPricePoint; totalPoints < period && i < prcLength; i++) {
                    movingTotalVolume += dataNodes[i]._volume;
                    totalPoints++;
                }
                firstRound = true;
            }
            else {
                movingTotalVolume = movingTotalVolume - dataNodes[(currentPricePoint - 1)]._volume;
                movingTotalVolume = movingTotalVolume + dataNodes[(currentPricePoint + period - 1)]._volume;
            }
            vMaValues[xPosition].Close = movingTotalVolume / totalPoints;
            if (setPrev) {
                vMaValues[xPosition - 1].Close = vMaValues[xPosition].Close;
                setPrev = false;
            }
            if (vMaValues[xPosition - 1] != null)
                for (var n = xPosition - 1; n > 0 && vMaValues[n] != null && vMaValues[n].Close <= 0; n--) {
                    if (n >= 0 && vMaValues[n] != null && vMaValues[n + 1] != null && vMaValues[n].Close <= 0)
                        vMaValues[n].Close = vMaValues[n + 1].Close;
                }

            xPosition++;
        }
        return vMaValues;
    }
}

export default VolumeMA;    