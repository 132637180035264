import EstimateControl from './EstimateControl.jsx'
import GrowthRateControl from './GrowthRateControl.jsx';
import Header from './Header.jsx';
import MiscControl from './MiscControl.jsx';
import { Panel } from "react-bootstrap";
import RatingControl from './RatingControl.jsx';
import React from 'react';
import TabularView from './TabularView.jsx';


const PanelBody = () => (
    <>
        <Panel>
            <Panel.Body>
                <Header />
                <div className="summary-block-chronicle for-stock">
                    <div className="summary-block-panel-left">
                        <div className="summary-block-calendar type-one">
                            <TabularView id="annualData" />
                        </div>
                        <div className="divider-line" />
                        <div className="summary-block-calendar type-two">
                            <TabularView id="weeklyData" />
                        </div>
                    </div>
                    <div className="summary-block-panel-right">
                        <EstimateControl />
                        <GrowthRateControl />
                        <RatingControl />
                        <div className="divider-line" />
                        <MiscControl />
                    </div>
                </div>
            </Panel.Body>
        </Panel>

    </>
)
export default PanelBody