import AnnotationPreview from './AnnotationPreview';
import AnnotationsCheckboxSelectors from './AnnotationsCheckboxSelectors';
import AnnotationsColorPicker from './AnnotationsColorPicker';
import AnnotationsOpacitySettings from './AnnotationsOpacitySettings';
import AnnotationsStrokeSettings from './AnnotationsStrokeSettings';
import AnnotationsWeightSettings from './AnnotationsWeightSettings';
import BaseServiceApi from 'BaseServiceApi';
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import ScrollBar from "ScrollBar";
import { handleColorChange, handleWeightChange, handleStrokeChange, onStartArrowChange, onStartExtendChange, onEndArrowChange, onEndExtendChange, onCheckBoxChange, onOpacityChange } from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent } from 'react';
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

class RIPanelLineInfo extends PureComponent {

  hideDropDownMenu = () => {
    this.colorPicker && this.colorPicker.hideDropDownMenu();
    this.weightDropDown && this.weightDropDown.hideDropDownMenu();
    this.strokeDropDown && this.strokeDropDown.hideDropDownMenu();
  }

  componentDidMount() {
    document.body.classList.add('riPanel');
    document.addEventListener("keydown", this.onDocumentKeyPress, false);
  }

  componentWillUnmount() {
    document.body.classList.remove('riPanel');
    document.removeEventListener("keydown", this.onDocumentKeyPress, false);
  }

  onDocumentKeyPress(e) {
    const charCode = (e.which) ? e.which : e.keyCode;

    if ((document.activeElement && document.activeElement.id === "SymbolEntryInput") || (document.activeElement && document.activeElement.id.includes("annot-dropdown"))) {
      return;
    }

    const pointerLayerScroll = document.getElementById("annotateIntrumentsLine");

    if (e.keyCode === KeyCodes.UP_ARROW) {

      if (pointerLayerScroll) {
        window.setTimeout(() => { pointerLayerScroll.scrollTop -= 30; }, 0);
      }
    }
    else if (e.keyCode === KeyCodes.DOWN_ARROW) {

      if (pointerLayerScroll) {
        window.setTimeout(() => { pointerLayerScroll.scrollTop += 30; }, 0);
      }
    }

  }

  render() {
    return (
      <div className="annotation-box annotate-line">
        <div className="annotation-hold custom-scroll" onScroll={this.hideDropDownMenu} onClickCapture={this.hideDropDownMenu}>
          <div className="annotation-overlape" style={{ display: ((!this.props.annotSelected && this.props.selectedLayer && this.props.selectedLayer.shareAccess === ShareAccessType.SHARE_READONLY) || (this.props.annotSelected && this.props.annotSelected.isReadOnlyAnnotation)) ? '' : 'none' }}></div>
          <div className="annotation-intruments" id="annotateIntrumentsLine">

            <AnnotationPreview label={`LINE SETTINGS`} />

            <div className="annotation-properties">
              <div className="swatches-dropdown">
                <div className="col-xs-4"><AnnotationsColorPicker type="line" label={true} ref={(r) => { this.colorPicker = r; }} /></div>
                <div className="col-xs-4"><AnnotationsWeightSettings type="line" label={true} ref={(r) => { this.weightDropDown = r; }} /></div>
                <div className="col-xs-4"><AnnotationsStrokeSettings type="line" label={true} ref={(r) => { this.strokeDropDown = r; }} /></div>
              </div>
              <div className="swatches-range-slider">
                <div className="col-xs-12">
                  <AnnotationsOpacitySettings />
                </div>
              </div>
            </div>

            <div className="annotation-properties">
              <div className="swatches-checks">
                <div className="col-xs-6">
                  <h6>START</h6>
                  <ul>
                    <AnnotationsCheckboxSelectors type="ArrowStart" />
                    <AnnotationsCheckboxSelectors type="ExtendStart" />
                  </ul>
                </div>
                <div className="col-xs-6">
                  <h6>END</h6>
                  <ul>
                    <AnnotationsCheckboxSelectors type="ArrowEnd" />
                    <AnnotationsCheckboxSelectors type="ExtendEnd" />
                  </ul>
                </div>
              </div>
            </div>

            <div className="annotation-properties">
              <div className="swatches-checks">
                <div className="col-xs-12">
                  <ul>
                    <AnnotationsCheckboxSelectors type="Alert" />
                    <AnnotationsCheckboxSelectors type="Snap" />
                    <AnnotationsCheckboxSelectors type="Temporary" />
                  </ul>
                </div>
              </div>
            </div>



          </div>
          <ScrollBar scrollId="annotateIntrumentsLine" vScroll={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ annotationReducers }) => {
  const { selectedLayer } = annotationReducers.annotationLayerManagementReducer;
  const { annotSelected } = annotationReducers.annotationChartModelReducer;
  const { riPanelToggle } = annotationReducers.annotationMenuReducer;

  return ({ selectedLayer, annotSelected, riPanelToggle });
}

const mapDispatchToProps = (dispatch) => ({
  handleColorChange: (color) => dispatch(handleColorChange(color)),
  handleWeightChange: (weight) => dispatch(handleWeightChange(weight)),
  handleStrokeChange: (stroke) => dispatch(handleStrokeChange(stroke)),
  onStartArrowChange: (startArrowChecked, lineX1, startPolygonPoints) => dispatch(onStartArrowChange(startArrowChecked, lineX1, startPolygonPoints)),
  onStartExtendChange: (startExtendChecked, lineX1, startPolygonPoints) => dispatch(onStartExtendChange(startExtendChecked, lineX1, startPolygonPoints)),
  onEndArrowChange: (endArrowChecked, lineX2, endPolygonPoints) => dispatch(onEndArrowChange(endArrowChecked, lineX2, endPolygonPoints)),
  onEndExtendChange: (endExtendChecked, lineX2, endPolygonPoints) => dispatch(onEndExtendChange(endExtendChecked, lineX2, endPolygonPoints)),
  onCheckBoxChange: (event) => dispatch(onCheckBoxChange(event)),
  onOpacityChange: (value) => dispatch(onOpacityChange(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelLineInfo);

