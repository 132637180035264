import Serializable from "Serializable";
import SettingStore from "SettingsStore";

class NewsSettingsHelper {
  updateNewsStream(viewSettings, streamerList) {
    let i = 0;
    const isResetStart = SettingStore.getIsResetStart();
    if(isResetStart) return;
    viewSettings.newsStream = [];
    streamerList.map((rowStream) => {
      let serializableStreamList = this.getSerizableObject(rowStream);
      serializableStreamList.NewsStreamerFilter = this.getSerizableObject(rowStream.NewsStreamerFilter);
      serializableStreamList.NewsStreamerFilter.newsStreamerListSelect = this.getSerizableObject(rowStream.NewsStreamerFilter.newsStreamerListSelect);
      serializableStreamList.SelectedLists = this.getSerizableObject(rowStream.SelectedLists);

      let serializableSource = rowStream.Sources;// this.getSerizableObject(rowStream.Sources);
      let index = 0;

      rowStream.Sources.map((rowSource) => {
        serializableSource[index] = this.getSerizableObject(rowSource);
        index = index + 1;
      });
      serializableStreamList.Sources = serializableSource;

      let serializableSourceAll = rowStream.SourcesAll;// this.getSerizableObject(rowStream.SourcesAll);
      index = 0;

      rowStream.SourcesAll.map((rowSource) => {
        serializableSourceAll[index] = this.getSerizableObject(rowSource);
        index = index + 1;
      });
      serializableStreamList.SourcesAll = serializableSourceAll;
      viewSettings.newsStream.push(serializableStreamList);
      i = i + 1;
    });
  }
  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

}

const newsSettingsHelper = new NewsSettingsHelper();
export default newsSettingsHelper;
