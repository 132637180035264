import DateHelper from "DateHelper";
import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from 'LocalizationStore';

const weeklyText = LocalizationStore.getTranslatedData("sb_wklylabel", "WEEKLY");

export default class CEFWeeklyData {
  constructor(weeklyData, headers, stockInfo) {
    this.labelWidth = 46;
    this.stockInfo = stockInfo;
    // this.positiveBrush = ThemeHelper.getThemedBrush("positiveDataText");
    // this.negativeBrush = ThemeHelper.getThemedBrush("negativeDataText");
    this.cellItemConut = 9;
    this.setWeeklyData(weeklyData, headers);
  }

  setWeeklyData(weeklyData, headers) {
    this.rowSource = [];
    for (let i = 0; i <= headers.length; i++) {
      const row = {
        cells: []
      };
      for (let j = 0; j < this.cellItemConut + 1; j++) {
        let cell = {};
        if (i === 0 && j === 0) {
          cell.value = weeklyText;
          cell.IsLabelCell = true;
          cell.textAlign = "left";

        } else if (i === 0 && j > 0) {
          const weeklyDataExtraItems = weeklyData.length - this.cellItemConut;
          const weekData = weeklyData[weeklyData.length - (j + weeklyDataExtraItems)];
          let date = "";
          if (weekData) {
            if (weekData.date) {
              date = weekData.date ? DateHelper.getMonthAndDay(new Date(weekData.date.toNumber())) : "";
            }
          }
          cell.value = date;
          cell.textAlign = "center";
        } else if (j === 0) {
          cell.value = headers[i - 1];
          cell.IsLabelCell = true;
          cell.textAlign = "left";
        } else {
          cell = this.getDataCell(weeklyData, i, j);
        }
        row.cells.push({
          cell
        });
      }
      this.rowSource.push(row);
    }
  }

  getDataCell(weeklyData, row, cell) {
    const weeklyDataExtraItems = weeklyData.length - this.cellItemConut;
    const item = weeklyData[weeklyData.length - (cell + weeklyDataExtraItems)];
    // if (row === 1) {
    //   return this.getDataGraphRating(item);
    // }
    if (row === 1) {
      return this.avgDailyVol(item);
    }
    if (row === 2) {
      return this.getUpdwnVol(item);
    }
    if (row === 3) {
      return this.getAccuDistRating(item);
    }
    if (row === 4) {
      return this.getRelativeStrength(item);
    }

    // if (row === 6) {
    //   return this.getGroupRank(item);
    // }
    return {
      cell: {}
    };
  }

  avgDailyVol(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.avol || item.avol === 0) {
        /*if (this.stockInfo.SymbolTypeEnum == SymbolType.INTERNATIONALSTOCK) {
          item.avol = Math.floor(item.avol / averageVolumeDivider);
        }*/

        //    cell.value = ExtremeDataValue.toLocalValue(item.avol, 0);
        cell.value = ExtremeDataValue.getFormattedNumber(item.avol, 0);
      }
    }
    return cell;
  }

  getDataGraphRating(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.dgrt || item.dgrt === 0) {
        cell.value = item.dgrt;
      }
    }
    return cell;
  }

  getGroupRank(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.gprk || item.gprk === 0) {
        cell.value = item.gprk;
      }
    }
    return cell;
  }

  getRelativeStrength(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.rlst || item.rlst === 0) {
        cell.value = item.rlst;
      }
    }
    return cell;
  }

  getAccuDistRating(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.adnm || item.adnm === 0) {
        cell.color = item.adnm < 0 ? this.negativeBrush : this.positiveBrush;
        cell.isPositive = !(item.adnm < 0);
        cell.isUseCusColor = true;
        cell.value = item.adnm === -100 ? "" : item.adnm;
      }
    }
    return cell;
  }

  getUpdwnVol(item) {
    const cell = {};
    cell.textAlign = "right";
    cell.value = "";
    if (item) {
      if (item.updwnvol || item.updwnvol === 0) {
        cell.value = ExtremeDataValue.formatValue(item.updwnvol, 1);
      }
    }
    return cell;
  }
}