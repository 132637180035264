import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import LocalizationStore from "LocalizationStore";
import UserInfoUtil from "UserInfoUtil";
import ConsoleStore from "ConsoleStore";
import FeatureModuleType from "../../../../Constants/FeatureModuleType";
import { find } from "underscore";
export default class AboutDialog extends Component {
    constructor() {
        super();
    }

    render() {
        const { moduleType } = this.props;
        const info = find(ConsoleStore.infoList, (item) => {
            if (item.featureCode === FeatureModuleType[moduleType]) {
                return item;
            }
        });
        const aboutUrl = UserInfoUtil.hasChinaEntitlement() ? info.url.replace(/PanarayFeatureAbout/, 'PanarayFeatureAbout_China') : info.url
        return (
            <div id="AboutAlertDialog">
                <Modal className="modal-popup abouts-list-view" show={true}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="cap-header">
                                <span className="indi-about">{LocalizationStore.getTranslatedData("ipc_abt","About")}</span>
                                <span className="indica-about">{LocalizationStore.getTranslatedData("ri_"+info.name.replace(/ /ig,""),info.name)}</span>
                            </span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <div className="loading loading-spinner"></div> */}
                        <div className="modal-region">
                            <div className="abouts-list-view-box">
                                <iframe src={aboutUrl} title="About Popup" className="abouts-list-view-iframe"></iframe>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.props.closeDialog}>{LocalizationStore.getTranslatedData("Close","Close")}</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}