import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListStore from "ListStore";
import ListManagerTabType from "ListManagerTabType";
import { ManagerLayoutConstants } from "ManagerLayoutConstants";
import ListApi from "ListApi";
import _, { clone } from "underscore";
import SettingsStore from "SettingsStore"
import ListActions from "ListActions";
import { getRefreshedView } from "../../../../Actions/OwnerShipActions";
import store from "../../../../Redux/store";
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow";
import NavType from "../../../../Constants/NavType";

const CHANGE_EVENT = "change";
let _currentAction = undefined;
class ManageLayoutStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    this.state = {
      columns: [],
      frozenColumns: [],
      allColumns: [],
      isQuoteBoard: false
    }
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getViewStore() { 
    let selectedTab = ListStore.getState().SelectedTabKey;
    let tabType = ListStore.getTabType(selectedTab);
    let viewStore = ONeilViewStore;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        viewStore = ONeilViewStore;
        break;
      case ListManagerTabType.Ownership:
        viewStore = OwnershipViewStore;
        break;
      default:
        break;
    }
    return viewStore;
  }

  getState(){
    return this.state;
  }

  setIsQuoteBoard(isQuoteBoard){
    this.state.isQuoteBoard = isQuoteBoard;
  }

  updateAllColumns(columns, columnSetId){
    this.state.allColumns = [];
    _.each(columns, (item) => {
      if(item){
        this.state.allColumns.push(item);
      }
    })
    this.state.columnSetId = columnSetId;
    this.emit(CHANGE_EVENT);
  }

  updateColumns(columns){
    this.state.columns = [];
    _.each(columns, (item) => {
      if(item){
        this.state.columns.push(item);
      }
    })
  }

  updateFrozenColumns(frozenColumns){
    this.state.frozenColumns = [];
    _.each(frozenColumns, (item) => {
      if(item){
        this.state.frozenColumns.push(item);
      }
    })
  }

  reorderColumnSetItems(listId, columnSetId, dataItemIdList, removeItemIdList){

    ListApi.reorderColumnSetItems(listId, columnSetId, dataItemIdList, removeItemIdList, this.state.isQuoteBoard).then((result) => {
      _currentAction = ManagerLayoutConstants.ActionTypes.ON_REORDER_COLUMNSET_CALLBACK;
      this.emit(CHANGE_EVENT);
      if (!result.responseHeader.error) {
        const activeColumnSet = this.getActiveColumnSet();
        const activeColumnSetId = activeColumnSet ? activeColumnSet.nodeId.low : undefined;
        if(activeColumnSetId === columnSetId){
          const settings = SettingsStore.getConsoleSettings();
          const navListManagerSettings = settings.NavListManagerSettings;
          if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
            TimeTrackingWindow.resetTimeTracker(true, false, NavType.NavLists);
            TimeTrackingWindow.initTimeTracker();
            TimeTrackingWindow.beginTimeTracker(NavType.NavLists);
            ListStore.setRefreshList();
          }
          else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
            getRefreshedView();
          }
        }
        else{
          const selectedTabKey = ListStore.getselectedTabKey();
          ListActions.getColumnSet(listId, selectedTabKey);
        }
      }
    });
  }

  getActiveColumnSet(){
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    let activeColumnSet;
    if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil) {
      activeColumnSet = ONeilViewStore.getState().basic ? ONeilViewStore.getState().basic.columnSet : null;
    }
    else if (navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership) {
      activeColumnSet = OwnershipViewStore.getState().basic ? OwnershipViewStore.getState().basic.columnSet : null;
    }
    return activeColumnSet;
  }

  getColumnSetItems(listId, columnSetId){
    let state = null;
    const settings = SettingsStore.getConsoleSettings();
    const navListManagerSettings = settings.NavListManagerSettings;
    if(navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil){
      state = ONeilViewStore.getState();
    }
    else if(navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership){
      state = OwnershipViewStore.getState();
    }
    let consoleId = state.basicEsInfo.consoleId;
    let isScreensch = state.basicEsInfo.isScreensch ? state.basicEsInfo.isScreensch : 0;
    let isScreenSchEmbed = state.basicEsInfo.isScreenSchEmbed ? state.basicEsInfo.isScreenSchEmbed : 0;

    ListApi.getColumnSetItems(consoleId, listId, columnSetId, isScreensch, isScreenSchEmbed).then((result) => {
      if (!result.responseHeader.error) {
        this.state.UpdateColumnSetId = columnSetId;

        this.state.allColumns = [];
        if(navListManagerSettings.SelectedTabKey === ListManagerTabType.Oneil){
          const columns = ONeilViewStore.removeInvisibleColumns(clone(result.data.columnInfos));
          this.updateAllColumns(columns, columnSetId);
        }
        else if(navListManagerSettings.SelectedTabKey === ListManagerTabType.Ownership){
          const columns = OwnershipViewStore.removeInvisibleColumns(clone(result.data.columnInfos));
          this.updateAllColumns(columns, columnSetId);
        }
      }
    });
  }

  getCurrentAction(){
    return _currentAction;
  }

  clearCurrentAction(){
    _currentAction = null;
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      default:
        return;
    }
  }
}
const manageLayoutStore = new ManageLayoutStore();
export default manageLayoutStore;


Array.prototype.removeAt = function (iIndex) {
  let vItem = this[iIndex];
  if (vItem) {
    this.splice(iIndex, 1);
  }
  return vItem;
}

Array.prototype.containsAny = function (arr) {
  return this.some((v) => (arr.indexOf(v) >= 0))
}

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};