import { createSelector } from 'reselect';
import {CompareMenuType} from "CompareTabType";

const getMenuTab = (state) => state.comparisonAndRotationMenuPanel.MenuTabReducer;
const symbolsMenu = (state) => state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
const groupsMenu = (state) => state.comparisonAndRotationMenuPanel.GroupsPanelReducer;

export const getRotationGraphMenuData = createSelector([getMenuTab, symbolsMenu, groupsMenu], (menuTab,symbolsMenu,groupsMenu) => {
    return menuTab.RotationMenuTab == CompareMenuType.Symbols ? symbolsMenu.RotationMenuStates : groupsMenu.RotationMenuStates;
});

export const getRotationGraphActiveMenuName = createSelector([getMenuTab], (menuTab) => {
    return menuTab.RotationMenuTab;
});
