import AlertColumnSetDetails from './AlertColumnSetDetails.jsx';
import { AlertTranslateHelper } from '../../../../Utils/TranslateHelper.js';
import { connect } from 'react-redux';
import { find } from "underscore";
import LocalizationStore from 'LocalizationStore';
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import { closeFilterDialog, selectClosestChildren, selectColumnSet } from '../../../../Actions/AlertActions.js';
import React, { Component } from "react";

class AlertColumnSet extends Component {
    constructor(props) {
        super(props);

        // this.alertStoreStateChange = this.alertStoreStateChange.bind(this);
        this.onColumnSetFolderChange = this.onColumnSetFolderChange.bind(this);
        this.onColumnSetChange = this.onColumnSetChange.bind(this);
        this.toggleDetailDailog = this.toggleDetailDailog.bind(this);
        this.handleAlertDocumentOutsideClick = this.handleAlertDocumentOutsideClick.bind(this);
        this.handleMouseDownColumnsetModal = this.handleMouseDownColumnsetModal.bind(this);
        this.handleMouseuPColumnsetModal = this.handleMouseuPColumnsetModal.bind(this);
        this.renderColumnSet = this.renderColumnSet.bind(this);
        this.columnSetDetails = null;

        this.mouseDownOnColumnsetModal = false;
        this.mouseUpOnColumnsetModal = false;
        const dimension = document.getElementById("modalBody").getBoundingClientRect();
        this.state = {
            toggleDetailDailog: false,
            loading: false,
            left: dimension.left + 69,
            top: dimension.top + 15,
        }
    }

    componentDidMount() {

        if (!(StringUtil.isEmpty(this.props.parentColumnSetName) || StringUtil.isEmpty(this.props.columnsetChildItems))) {
            this.loadColumnSet(null, false);
        }
        document.addEventListener('click', this.handleAlertDocumentOutsideClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleAlertDocumentOutsideClick, false);
    }

    handleAlertDocumentOutsideClick(e) {
        const columnSetDetailsHeader = document.getElementById("alertColumnSetDetailsModalHeader");
        const columnSetDetailsBody = document.getElementById("alertColumnSetDetailsModalBody");
        const target = e.target || e.srcElement;
        const wasDown = this.mouseDownOnColumnsetModal;
        const wasUp = this.mouseUpOnColumnsetModal;
        this.mouseDownOnColumnsetModal = false;
        this.mouseUpOnColumnsetModal = false;

        if (!wasDown && !wasUp) {
            if ((columnSetDetailsHeader || columnSetDetailsBody) && !(columnSetDetailsHeader.contains(target) || columnSetDetailsBody.contains(target))) {
                this.props.closeFilterDialog();
            }
            else if (!(columnSetDetailsHeader && columnSetDetailsBody) && target.id !== 'alertColumnSetBackButton') {
                this.props.closeFilterDialog();
            }
        }

        e.stopPropagation();
    }

    handleMouseDownColumnsetModal() {
        this.mouseDownOnColumnsetModal = true;
    }

    handleMouseuPColumnsetModal() {
        this.mouseUpOnColumnsetModal = true;
    }

    loadColumnSet(columnSetDetails, isFolderClick) {

        if (isFolderClick) {
            this.props.selectClosestChildren(columnSetDetails)
        }

        this.setState({
            toggleDetailDailog: true
        })

        this.columnSetDetails =
            <AlertColumnSetDetails //columnSetDetails={columnSetDetails}
                parentColumnSetName={this.props.parentColumnSetName}
                selectedColumnSet={this.props.selectedColumnSet}
                toggleDetailDailog={this.toggleDetailDailog}
                columnSetId={this.props.selectedColumnSet.colsetId}
                left={this.state.left} top={this.state.top} />
    }

    onColumnSetFolderChange(e) {
        const nodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');
        const columnSetDetails = find(this.props.columnsetCollection, (column) =>
            parseInt(nodeId) === parseInt(column.colsetId)
        );

        this.loadColumnSet(columnSetDetails, true);
    }

    onColumnSetChange(e) {
        const nodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');
        const columnSetDetails = find(this.props.columnsetCollection, (column) =>
            parseInt(nodeId) === parseInt(column.colsetId)
        );

        // this.props.onColumnSetChange();
        this.props.closeFilterDialog()
        this.props.selectColumnSet(columnSetDetails, '');
    }

    toggleDetailDailog() {
        this.setState({
            toggleDetailDailog: !this.state.toggleDetailDailog
        })
        this.columnSetDetails = '';
    }

    renderColumnSet() {
        const self = this;

        return (<ul className="list-group">
                    {
                        this.props.columnsetCollection.map((column, key) => {
                            const activeClass = (!StringUtil.isEmpty(self.props.selectedColumnSet) && self.props.selectedColumnSet.colsetId === column.colsetId) ? 'active' : '';
                            const activeNode = (!StringUtil.isEmpty(self.props.selectedColumnSet) && self.props.selectedColumnSet.colsetId === column.colsetId) ? <span className="icn-right-icon"></span> : '';

                            const columnName = column.isShared ? `Shared - ${  column.colsetName}` : column.colsetName;

                            if (column.isFolder === true) { // Folder
                                return (
                                    <li key={key} className="list-group-item">
                                        <a className="panal-a medium-normal" href="javascript:void(0);" onClick={(e) => self.onColumnSetFolderChange(e)} data-node-id={column.colsetId}>
                                            <span className="icn-folder-view"></span>
                                            {LocalizationStore.getTranslatedData(`ColumnSet_${columnName}`,columnName)}
                                        </a>
                                    </li>
                                );
                            }
                            else { // COLUMNSET
                                return (
                                    <li key={key} className={`list-group-item ${  activeClass}`}>
                                        <a className="panal-a medium-normal" href="javascript:void(0);" onClick={(e) => self.onColumnSetChange(e)} data-node-id={column.colsetId} title={columnName}>
                                            {activeNode}
                                            <span className={column.isShared ? "icn-share pull-left" : "icn-fa-file-text-p pull-left marr0"}></span>
                                            {columnName === "No Column Set" ? AlertTranslateHelper["NO_COLUMN_SET"] : LocalizationStore.getTranslatedData(`ColumnSet_${columnName}`,columnName)}
                                        </a>
                                        <span className="dropdown-panel-custom nopad" id={`dropdownSpan${  key}`}></span>
                                    </li>
                                );
                            }
                        })
                    }
            </ul>
        );
    }


    render() {
        const columnSet = this.renderColumnSet();

        return (
            <div id="alertColumnDialog" >
                <Modal className="modal-popup alert-column-dialog alert-columnset, slide-down" show={true} style={{ left: this.state.left, top: this.state.top }}>
                    <Modal.Header onMouseDown={this.handleMouseDownColumnsetModal} onMouseUp={this.handleMouseuPColumnsetModal}>
                        <Modal.Title><span className="cap-header alert-title">{AlertTranslateHelper["SELECT_COLUMN_SET"]}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body onMouseDown={this.handleMouseDownColumnsetModal} onMouseUp={this.handleMouseuPColumnsetModal}>
                        <div className="modal-area column-geo-alert custom-scroll-light">
                            <div className="custom-scroll">
                                <div id="alertColumnSetVerticalScroll">
                                    {(this.state.loading ? <div className="group-box"><div className="loading loading-light"></div></div> : columnSet)}
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId="alertColumnSetVerticalScroll" vScroll={true} />
                    </Modal.Body>
                </Modal>
                <div onMouseDown={this.handleMouseDownColumnsetModal} onMouseUp={this.handleMouseuPColumnsetModal}>
                    {this.columnSetDetails}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ alertReducer }) => {
    const { columnsetCollection, selectedColumnSet, parentColumnSetName, columnsetChildItems } = alertReducer.AlertDialogReducer;
    return { columnsetCollection, selectedColumnSet, parentColumnSetName, columnsetChildItems }
}

const mapDispatchToProps = (dispatch) => ({
    closeFilterDialog: ()=> dispatch(closeFilterDialog()),
    selectClosestChildren: (columnSetDetails) => dispatch(selectClosestChildren(columnSetDetails)),
    selectColumnSet: (columnSetDetails, parentColSetName)=> dispatch(selectColumnSet(columnSetDetails, parentColSetName))
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertColumnSet);