import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetIndexAllocationDataRequest extends BaseServiceRequest {
    constructor(symbol) {
        super();
        super.addRequestUri("allocationsbysector.pb");
        super.addRequestParameter("symbol", symbol);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "SectorAllocationDataResponse";
    }
}

export default GetIndexAllocationDataRequest;
