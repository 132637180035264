import handleActions from 'type-to-reducer';
import { combineReducers } from 'redux';

import {
  SET_ROUTE,
  SET_STREAM,
  SET_SELECTED_STREAM,
  SET_DRAG_STREAM,
  FETCH_SOURCES,
  FETCH_NEWS_LIST,
  NEWS_START,
  NEWS_END,
  SET_SOURCE,
  SET_DELETE_NEWS,
  SET_SYMBOL,
  SET_SCROLL_FLAG,
  SET_LIST_DATA,
  SET_DIV_HEIGHT,
  SET_NAME_CHANGED,
  IS_BACKGROUND_JOB_START,
  GET_NEW_STREAMER,
  GET_FILTER_LIST,
  SET_LIST_VALID
} from '../Actions/NewsActions'

const scrolFlaglInitialState = false;

const scrollflag = handleActions({
  [SET_SCROLL_FLAG]: (state, action) => action.payload
}, scrolFlaglInitialState);

const divHeightInitialState = false;

const divHeight = handleActions({
  [SET_DIV_HEIGHT]: (state, action) => action.payload
}, divHeightInitialState);

const isNameChangedInitialState = false;

const isnameChanged = handleActions({
  [SET_NAME_CHANGED]: (state, action) => action.payload
}, isNameChangedInitialState);

const backGroundJobStatusInitialState = false;

const backGroundJobStatus = handleActions({
  [IS_BACKGROUND_JOB_START]: (state, action) => action.payload
}, backGroundJobStatusInitialState);

const asyncJobInitialState = '';

const route = handleActions({
  [SET_ROUTE]: (state, action) => action.payload
}, asyncJobInitialState);

const streamerListInitialState = [];

const streamerList = handleActions({
  [SET_STREAM]: (state, action) => action.payload
}, streamerListInitialState);

const selectedStreamInitialState = {
  selectedStreamer: null
}

const streamer = handleActions({
  [SET_SELECTED_STREAM]: (state, action) => ({
    ...selectedStreamInitialState,
    selectedStreamer: action.payload
  })
}, selectedStreamInitialState);

const dragStreamInitialState = null;

const dragStreamer = handleActions({
  [SET_DRAG_STREAM]:(state, action) =>  action.payload
}, dragStreamInitialState);


const newsSourcesInitialState = {
  pending: false,
  error: undefined,
  data: undefined
};

const newsSources = handleActions({
  [FETCH_SOURCES]: {
    PENDING: () => ({
      ...newsSourcesInitialState,
      pending: true
    }),
    FULFILLED: (state, action) => ({
      ...newsSourcesInitialState,
      data: action.payload
    }),
    REJECTED: (state, action) => ({
      ...newsSourcesInitialState,
      error: action.payload
    })
  }
}, newsSourcesInitialState);


const newsListInitialState = {
  pending: false,
  error: undefined,
  data: undefined
};

const newsList = handleActions({
  [FETCH_NEWS_LIST]: {
    PENDING: () => ({
      ...newsListInitialState,
      pending: true
    }),
    FULFILLED: (state, action) => ({
      ...newsListInitialState,
      data: action.payload
    }),
    REJECTED: (state, action) => ({
      ...newsListInitialState,
      error: action.payload
    })
  }
}, newsListInitialState);

const newsInitialState = {
  newsStart: 0,
  newsEnd: 0
};

const newsState = handleActions({
  [NEWS_START]: (state, action) => ({
    ...newsInitialState,
    newsStart: action.payload
  }),
  [NEWS_END]: (state, action) => ({
    ...newsInitialState,
    newsEnd: action.payload
  })
}, newsInitialState);

const sourceInitialState = {
  showSource: false,
  showStreamName: false,
  showFilter: false,
  showMax: false,
  clientX:0,
  clientY:0
};

const sourceData = handleActions({
  [SET_SOURCE]: (state, action) => action.payload
}, sourceInitialState)



const symbolInitState = {
  showSymbol: false,
  data: undefined,
  selectedSymbol: undefined,
  searchSymbol: "",
  phrasesKeywords: "",
  IsAndOperatorChecked: false
}

const filtersymbolData = handleActions({
  [SET_SYMBOL]: (state, action) => action.payload
}, symbolInitState)


const listDataInitialState = {
  list: []
};

const listData = handleActions({
  [SET_LIST_DATA]: (state, action) => ({
    ...listDataInitialState,
    list: action.payload
  })
}, listDataInitialState);

const deleteNewsInitialState = false;

const deleteNewsData = handleActions({
  [SET_DELETE_NEWS]: (state, action) => action.payload
}, deleteNewsInitialState)

const getNewStreamerInitialState = false;

const getNewStreamer = handleActions({
  [GET_NEW_STREAMER]: (state, action) => action.payload
}, getNewStreamerInitialState);

const getFilterListInitialState = false;

const getFilterList = handleActions({
  [GET_FILTER_LIST]: (state, action) => action.payload
}, getFilterListInitialState);

const filterListValidationInitialState = true;
const filterListValidation = handleActions({
  [SET_LIST_VALID]: (state, action) => action.payload
}, filterListValidationInitialState)

const news = combineReducers({
  route,
  newsSources,
  streamer,
  dragStreamer,
  newsList,
  newsState,
  streamerList,
  sourceData,
  filtersymbolData,
  listData,
  scrollflag,
  deleteNewsData,
  divHeight,
  isnameChanged,
  backGroundJobStatus,
  getNewStreamer,
  getFilterList,
  filterListValidation
})


export default news
