import BaseServiceApi from 'BaseServiceApi';
import { getRiPanelSimilarFundsStates } from '../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors';
import GraphApi from "../../../ServiceApi/Apis/GraphApi";
import MiniListHelper from "../../../Utils/MiniListHelper";
import MiniListStore from "../../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import SettingsStore from "SettingsStore"
import UserInfoUtil from "../../../Utils/UserInfoUtil";
import { call, put, select, takeLatest } from "redux-saga/effects";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const { ActionTypes } = RiPanelConstants;
function* getSimilarFundsData({ symbolInfo }) {
    try{
        const { similarFundsData } = yield select(getRiPanelSimilarFundsStates)
        const isFundViewEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.FUNDS_VIEW_ACNTMGR_Entitlement);
        
        if (similarFundsData.length === 0) {
            const isMiniListPlay = MiniListStore.getMiniListPlayingStatus();;
            const result = yield call(GraphApi.GetSimilarFundsRequest, symbolInfo.Symbol, isFundViewEntitled)
            if (MiniListHelper.ActiveSymbolCheck(symbolInfo.Symbol, isMiniListPlay)) {
                const similarFundsResponse = populateData(result);
                const SimilarFundSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings.RelatedInformationSettings.FUNDViewSettings.SimilarFundSettings;
                const ActiveFund = similarFundsResponse.activeFundData
                yield put({
                    type: ActionTypes.UPDATE_SIMILAR_FUNDS_DATA,
                    similarFundsData: similarFundsResponse.similarFundsData,
                    ActiveFund,
                    loading: false,
                    isFundViewEntitled,
                    isRelative: SimilarFundSettings.IsRelativeYTD,
                    SimilarFundSettings
                })
            }
    
        }
    }
    catch(error){
        console.log(`Error occurs in RiPanelSimilarFunds.js, getSimilarFundsData, ${error}`);
    }
}

function populateData(result) {
    if (result.activeFundData) {
        const activeFundData = result.activeFundData;
        activeFundData.percentageChgYTDString = activeFundData.percentageChgYTD === 0 ? `${activeFundData.percentageChgYTD.toFixed(1)}%` : activeFundData.percentageChgYTD > 0 ? `+${activeFundData.percentageChgYTD.toFixed(1)}%` : `${activeFundData.percentageChgYTD.toFixed(1)}%`;
        activeFundData.relativeYTDString = activeFundData.relativeYTD === 0 ? `${activeFundData.relativeYTD.toFixed(1)}%` : activeFundData.relativeYTD > 0 ? `+${activeFundData.relativeYTD.toFixed(1)}%` : `${activeFundData.relativeYTD.toFixed(1)}%`;
        activeFundData.YTDForegroundS = activeFundData.percentageChgYTD === 0 ? "gray-text" : activeFundData.percentageChgYTD > 0 ? "blue-text" : "red-text";
        activeFundData.YTDForegroundR = activeFundData.relativeYTD === 0 ? "gray-text" : activeFundData.relativeYTD > 0 ? "blue-text" : "red-text";
        activeFundData.similarityString = `${(activeFundData.similarity * 100).toFixed(0)}%`;
        activeFundData.abbreviateAssetsUnderMgmt = abbreviateAUM(activeFundData.hasAssets, activeFundData.assetsUnderMgmt);
    }
    result.similarFundsData.forEach((item) => {
        item.percentageChgYTDString = item.percentageChgYTD === 0 ? `${item.percentageChgYTD.toFixed(1)}% ` : item.percentageChgYTD > 0 ? `+${item.percentageChgYTD.toFixed(1)}%` : `${item.percentageChgYTD.toFixed(1)}%`;
        item.relativeYTDString = item.relativeYTD === 0 ? `${item.relativeYTD.toFixed(1)}%` : item.relativeYTD > 0 ? `+${item.relativeYTD.toFixed(1)}%` : `${item.relativeYTD.toFixed(1)}%`;
        item.YTDForegroundS = item.percentageChgYTD === 0 ? "gray-text" : item.percentageChgYTD > 0 ? "blue-text" : "red-text";
        item.YTDForegroundR = item.relativeYTD === 0 ? "gray-text" : item.relativeYTD > 0 ? "blue-text" : "red-text";
        item.similarityString = `${(item.similarity * 100).toFixed(0)}%`;
        item.abbreviateAssetsUnderMgmt = abbreviateAUM(item.hasAssets, item.assetsUnderMgmt);
    });
    return result
}

function abbreviateAUM(hasAssets, assetsUnderMgmt) {
    if (!hasAssets) {
        return "N/A";
    }
    if (assetsUnderMgmt === 0) {
        return "$0M";
    }
    if (assetsUnderMgmt > 995000) {
        return `$${(assetsUnderMgmt / 1000000).toFixed(1)}B`;
    } else {
        return `$${(assetsUnderMgmt / 1000).toFixed(1)}M`;
    }
}



export function* watchInitSimilarFundsData() {
    yield takeLatest(RiPanelConstants.ActionTypes.INIT_SIMILAR_FUNDS, getSimilarFundsData)
}