import keyMirror from "keymirror";

export const FinancialBlockDialogConstants = {
    ActionTypes: keyMirror({
        FINANCIALBLOCK_DIALOG_REORDER_ROWITEM: null,
        FINANCIALBLOCK_DIALOG_RESTORE: null,
        FINANCIALBLOCK_DIALOG_SELECT_ROWITEM_CHANGED: null,
        FINANCIALBLOCK_DIALOG_METRICITEM_MOUSEOVER: null,
        FINANCIALBLOCK_DIALOG_BACK: null,
        FINANCIALBLOCK_DIALOG_SEARCHTEXT_CHANGE: null,
        FINANCIALBLOCK_DIALOG_CLEAR_SEARCHTEXT: null,
        FINANCIALBLOCK_DIALOG_SELECTED_METRIC_CHANGE: null,
        FINANCIALBLOCK_DIALOG_SUBMIT_CHANGE: null,
        OPEN_FINANCIALBLOCK_DIALOG: null,
        SET_FINANCIALBLOCK_DIALOG_DATA: null,
        FINANCIAL_BLOCK_UPDATE_STATE: null,
        FINANCIALBLOCK_DIALOG_UPDATE_CHANGES: null,
        FINANCIALBLOCK_CALL_RESTORE: null,
        FINANCIALBLOCK_DIALOG_INPUTBOX_UPDATE: null
    })
};
