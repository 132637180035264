import Serializable from "Serializable";
class SummaryBlockSettings extends Serializable {
  constructor() {
    super();
    this.isAvailable = true;
    this.isOpen = false;
    this.showMoreStory = false;
    this.isYtdAbsolute = true;
  }

  onSeralisedInfoRecieved(serializedValue) {
    this.isAvailable = serializedValue.isAvailable ? serializedValue.isAvailable : true;
    this.isOpen = serializedValue.isOpen ? serializedValue.isOpen : false;
    this.showMoreStory = serializedValue.showMoreStory ? serializedValue.showMoreStory : false;
    this.isYtdAbsolute = serializedValue.isYtdAbsolute ? serializedValue.isYtdAbsolute : false;
  }

  getObjectMapKey() {
    return "SummaryBlockSettings";
  }

  getDataObject() {
    return {
      isAvailable: this.isAvailable,
      isOpen: this.isOpen,
      showMoreStory: this.showMoreStory,
      isYtdAbsolute: this.isYtdAbsolute,
    };
  }
}

export default SummaryBlockSettings;
