import AddAnchor from '../Presentational/CustomTransformerControl.jsx';
import AnnotationUtil from '../../../../../../Utils/AnnotationUtil';
import PropTypes from 'prop-types';
import { RayAnnotationType } from 'Constants/EAnnotationType';
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { Group, Rect, Text, Image } from 'react-konva';
import React, { Component } from 'react'

class TextControl extends Component {

    doubleClick = (e) => {
        this.props.handleDoubleClick(e);
    }

    handleAnchorDragEnd = () => {
        if (this.props.activeAnchor !== '') {
            this.props.handleAnchorDragEnd();
        }
    }

    render() {

        const { id, y, width, text, borderColor, bgColor, fontSize, color,
            isBold, isItalic, isUnderline, textAlign, fontStyle, isEditable,
            borderWeight, strokeStyle, isBorderChecked, height, rows, opacity } = this.props.text;
        let { x, endMouseX } = this.props.text;
        const { isDragging, draggable, lockImage, isApiReqResolved } = this.props;
        const borderHeight = isBorderChecked ? borderWeight : 0;
        const rectHeight = height ? height + 4 + borderHeight : parseInt(fontSize) + 4 + borderHeight;
        const selectedIds = this.props.selectedAnnotId;
        const align = textAlign === 2 ? 'right' : textAlign === 3 ? 'center' : 'left';// text alignment 
        let styleFont = isItalic ? "italic " : ''; // font style
        styleFont += isBold ? "bold" : '';

        const textColor = ThemeHelper.getThemedBrush(color);
        const textBgColor = ThemeHelper.getThemedBrush(bgColor);
        const fillOp = AnnotationUtil.hexToRGBA(textBgColor, opacity);
        const textBorderColor = ThemeHelper.getThemedBrush(borderColor)

        const anchorX = x;
        if (endMouseX < x && width !== 0) {
            x = endMouseX;
        }
        endMouseX = endMouseX === 0 ? x : endMouseX; // same anchors when start text draw
        let dash = [];
        if (strokeStyle === 3) {
            dash = [borderWeight, borderWeight];
        }
        if (strokeStyle === 2) {
            dash = [borderWeight * 2.5, borderWeight];
        }
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            <Group>
                {
                    !isEditable && <Rect
                        draggable={draggable && isApiReqResolved}
                        id={id}
                        shape={RayAnnotationType.TEXT_AnnotationType}
                        index={this.props.index}
                        x={x}
                        y={y}
                        stroke={textBorderColor}
                        strokeWidth={isBorderChecked ? borderWeight : 0}
                        width={width}
                        height={rectHeight}
                        fill={fillOp}
                        dash={dash}
                        onDragStart={() => this.props.handleDragStart(this.props.text)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        onDblClick={this.doubleClick}
                        ref={(node) => {
                            this.rect = node;
                        }}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.TEXT_AnnotationType) : undefined}
                    />
                }
                {
                    !isEditable && <Text
                        draggable={draggable && isApiReqResolved}
                        id={id}
                        shape={RayAnnotationType.TEXT_AnnotationType}
                        index={this.props.index}
                        x={x}
                        y={y}
                        text={text}
                        width={width}
                        // stroke={textColor}
                        fill={textColor}
                        height={rectHeight}
                        strokeWidth={1}
                        fontSize={fontSize}
                        onContextMenu={(e) => draggable ? this.props.onContextMenu(e, RayAnnotationType.TEXT_AnnotationType) : undefined}
                        onDragStart={() => this.props.handleDragStart(this.props.text)}
                        onDragMove={(e) => this.props.handleDragMove(e)}
                        onDragEnd={() => isDragging && this.props.handleDragEnd()}
                        fontStyle={styleFont}
                        textDecoration={isUnderline ? "underline" : ''}
                        align={align}
                        fontFamily={fontStyle}
                        onDblClick={this.doubleClick}
                        wrap={"word"}
                        verticalAlign={"middle"}
                        lineHeight={1}
                        padding={2}
                    />
                }
                {selectedIds.includes(id) && !isDragging && !isEditable && <React.Fragment>
                    <AddAnchor x={anchorX} y={y + rectHeight / 2} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'start'}
                        type={RayAnnotationType.TEXT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={x - 3} y={y + rectHeight / 2 - 3} />}
                    <AddAnchor x={endMouseX} y={y + rectHeight / 2} annotSelected={id}
                        handleAnchorDragStart={this.props.handleAnchorDragStart}
                        handleAnchorDragMove={this.props.handleAnchorDragMove}
                        handleAnchorDragEnd={this.handleAnchorDragEnd}
                        anchorPos={'end'}
                        type={RayAnnotationType.TEXT_AnnotationType}
                        draggable={draggable}
                        onContextMenu={this.props.handleDeleteAnnotationDrawing}
                    />
                    {!draggable && <Image height={6} width={6} image={lockImage} x={endMouseX - 3} y={y + rectHeight / 2 - 3} />}
                </React.Fragment>
                }
            </Group>
        );
    }
}

TextControl.propTypes = {
    index: PropTypes.number.isRequired,
    text: PropTypes.object.isRequired,
    handleDragEnd: PropTypes.func.isRequired
}

export default TextControl;