import ExtremeDataValue from "ExtremeDataValue";
import ThemeHelper from "ThemeHelper";

export default class StockData {
    constructor(data) {
        this.positiveBrush = ThemeHelper.getThemedBrush("positiveDataText");
        this.negativeBrush = ThemeHelper.getThemedBrush("negativeDataText");
        this.setStockData(data);
    }

    setStockData(data) {
        this.stockNewSource = [];
        this.stockDecreaseSource = [];
        this.stockIncreaseSource = [];
        const newListData = data.newAS;
        newListData.map((newAsListItem) =>
            this.stockNewSource.push({
                symbol: newAsListItem.symbol,
                companyName: newAsListItem.companyName,
                percChange: `${ExtremeDataValue.getPositiveNegativeString(newAsListItem.pctChg ? newAsListItem.pctChg : 0, 1)}%`,
                doubleValue: newAsListItem.pctChg ? newAsListItem.pctChg : 0,
                brush: newAsListItem.pctChg ? newAsListItem.pctChg : 0 >= 0 ? this.positiveBrush : this.negativeBrush
            })
        );

        const asDecreasingData = data.decreasingAS;
        asDecreasingData.map((decrAsListItem) =>
            this.stockDecreaseSource.push({
                symbol: decrAsListItem.symbol,
                companyName: decrAsListItem.companyName,
                percChange: `${ExtremeDataValue.getPositiveNegativeString(decrAsListItem.pctChg ? decrAsListItem.pctChg : 0, 1)}%`,
                doubleValue: decrAsListItem.pctChg,
                brush: decrAsListItem.pctChg >= 0 ? this.positiveBrush : this.negativeBrush
            })
        );

        const asIncreasingData = data.increasingAS;
        asIncreasingData.map((incAsListItem) =>
            this.stockIncreaseSource.push({
                symbol: incAsListItem.symbol,
                companyName: incAsListItem.companyName,
                percChange: `${ExtremeDataValue.getPositiveNegativeString(incAsListItem.pctChg ? incAsListItem.pctChg : 0, 1)}%`,
                doubleValue: incAsListItem.pctChg,
                brush: incAsListItem.pctChg >= 0 ? this.positiveBrush : this.negativeBrush
            })
        );

    }
}