import React from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from "react-sortable-hoc";
import ClusterControl from "./ClusterControl.jsx";

const dragHandle = sortableHandle(({ indicatorslength, indicatorName }) => (
  indicatorName != "External Data" ? <a
    onMouseOver={e => {
      e.target.closest(".symbol-stack-flyout").classList.add("sortable-drag");
    }}
    onMouseOver={e => {
      e.target.closest(".symbol-stack-flyout").classList.add("open-panel-strip");
    }}
    onMouseLeave={e => {
      e.target
        .closest(".symbol-stack-flyout")
        .classList.remove("sortable-drag");
    }}
    onMouseLeave={e => {
      e.target
        .closest(".symbol-stack-flyout")
        .classList.remove("open-panel-strip");
    }}
    className={ "move-panel" + (indicatorslength == 1 || indicatorName == "External Data" ? " deactive" : "") }
  /> : <a className={ "move-panel-ext" }/>
));

const DragHandle = sortableHandle(({ indicators, sectionlength,height }) => (
  <span className="handle-panel-group">
    {indicators.map(
      (header, item) =>
      header && header.Visible && (
          <a  data-key={item}
              key={item}
              onMouseOver={(e, item) => {
                  if (!e.target.parentElement ||
                      !e.target.parentElement.parentElement ||
                      !e.target.parentElement.parentElement.children[
                      parseInt(e.target.getAttribute("data-key")) + 1
                      ]) return;
            // if(e.target.parentElement.parentElement.children.length <= parseInt(e.target.getAttribute("data-key"))) return;
                e.target.parentElement.parentElement.children[
                  parseInt(e.target.getAttribute("data-key")) + 1
                ].classList.add("sortable-drag");
                e.target.parentElement.parentElement.classList.add(
                  "sortable-drag"
                );

                e.target.parentElement.parentElement.children[
                  parseInt(e.target.getAttribute("data-key")) + 1
                ].classList.add("open-panel-strip");
                e.target.parentElement.parentElement.classList.add(
                  "open-panel-strip"
                );

              }}
            onMouseLeave={e => {
                if (!e.target.parentElement ||
                    !e.target.parentElement.parentElement ||
                    !e.target.parentElement.parentElement.children[
                    parseInt(e.target.getAttribute("data-key")) + 1
                    ]) return;
            // if(e.target.parentElement.parentElement.children.length <= parseInt(e.target.getAttribute("data-key"))) return;
              e.target.parentElement.parentElement.children[
                parseInt(e.target.getAttribute("data-key")) + 1
              ].classList.remove("sortable-drag");
              e.target.parentElement.parentElement.classList.remove(
                "sortable-drag"
              );


              e.target.parentElement.parentElement.children[
                parseInt(e.target.getAttribute("data-key")) + 1
              ].classList.remove("open-panel-strip");
              e.target.parentElement.parentElement.classList.remove(
                "open-panel-strip"
              );
            }}
            className={
              "move-panel-group" + (sectionlength == 1 ? " deactive" : "")
            }
            style={{marginTop: height ? height*item + 'px' : ''}}
          />
        )
    )}
    <span className="select-group" />
  </span>
));

const IndicatorItem = sortableElement(
  ({ value, index, name, indicatorslength }) => (
    <div className={name != "External Data" ? name != "Key Performance Indicators" ? "symbol-stack-flyout" : "symbol-stack-flyout kpi-stack-flyout" : "symbol-stack-flyout external-stack-flyout"}
        data-indicator-id={name}>
      <div className="ray-flyout-cluster">
        <div className="ray-flyout-cluster-drag"
        
          onMouseOver={e => {
            e.target.closest(".symbol-stack-flyout").classList.add("open-panel-strip");
          }}
          onMouseLeave={e => {
            e.target.closest(".symbol-stack-flyout").classList.remove("open-panel-strip");
          }}


        >
          <div className="flyout-cluster-strip">
            <ClusterControl
              indicatorslength={indicatorslength}
              dragHandle={dragHandle}
              indicatorName = {name}
            />
          </div>
        </div>
        </div>
      {value}
    </div>
  )
);

const IndicatorList = sortableContainer(
  ({ sectionName, section, idx, sectionlength,visibileLength,height }) => {
    section.Indicators = section.Indicators.filter((item) => item && item.Visible);
    return (
      <div 
        className= {sectionName != "External Data" ? "symbol-stack-group-flyout" : "symbol-stack-group-flyout external-stack-group-flyout"}
        data-indicator-id={sectionName}
      >
      {/* {sectionName != "External Data" &&  */}
        <DragHandle
          indicators={section.Indicators}
          sectionlength={sectionlength}
          height={height}
        />
      {/* } */}
        {section.Indicators.length > 0 &&
          section.Indicators.map(
            (item, index) =>
            item && item.Visible && (
                <IndicatorItem
                  key={`item-${index + 1}`}
                  indicatorslength={section.Indicators.length}
                  disabled={visibileLength == 1}
                  name={sectionName}
                  index={index}
                  value={item.Component}
                />
              )
          )}
      </div>
    );
  }
);

class IndicatorContainer extends React.Component {
  render() {
    const {
      onSortEnd,
      section,
      index,
      sectionName,
      onSortStart,
      sectionlength,
      height
    } = this.props;
    const visibileInd = section.Indicators.filter((item) => item && item.Visible);
    return (
      <IndicatorList
        useDragHandle
        lockAxis="y"
        lockToContainerEdges
        sectionlength={sectionlength}
        sectionName={sectionName}
        section={section}
        onSortEnd={onSortEnd}
        idx={index}
        onSortStart={onSortStart}
        visibileLength={visibileInd.length}
        height={height}
      />
    );
  }
}

const SortableSection = sortableElement(
  ({ sectionName, section, index, onSortEnd, onSortStart, sectionlength, height }) => (
    <IndicatorContainer
      index={index}
      sectionName={sectionName}
      sectionlength={sectionlength}
      section={section}
      onSortEnd={onSortEnd}
      onSortStart={onSortStart}
      height={height}
    />
  )
);

const SortableSectionList = sortableContainer(
  ({
    indicators,
    onSectionSortEnd,
    onSectionSortStart,
    visibleSectionsLength,
    indexInc,
    height
  }) => {
    return ( 
      <div className="symbol-view-sortable-content">
        {indicators.map(
          (item) =>{
            const visibileIndicators = item.Indicators.filter((indi) => indi && indi.Visible);
            if(item.IsVisible && visibileIndicators.length > 0){
              indexInc = indexInc + 1;
            }
            return (item.IsVisible && visibileIndicators.length > 0 && (
              <SortableSection
                sectionlength={visibleSectionsLength}
                sectionName={item.Name}
                disabled={visibleSectionsLength == 1}
                key={`item-${indexInc}`}
                section={item}
                index={indexInc}
                onSortStart={onSectionSortStart}
                onSortEnd={onSectionSortEnd}
                height={height}
              />
            ) )
          }
        )}
      </div>
    )
  }
);
export default SortableSectionList;