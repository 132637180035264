import { combineReducers } from 'redux';
import HeaderPaneReducer from './HeaderPaneReducer';
import CenterRotationReducer from './CenterRotationReducer';
import BenchMarkReducer from './BenchMarkReducer';

const rotationGraph = combineReducers({
    HeaderPaneReducer,
    CenterRotationReducer,
    BenchMarkReducer
});
  
  
export default rotationGraph;