import { extend, find, contains, keys, isArray, sortBy, reject, findIndex, filter, clone } from "underscore";

import StringUtil from "StringUtil";
import ListManagerTabType from "ListManagerTabType";
import OwnershipViewStore from "OwnershipViewStore";
import PanarayDefault from "ServiceApi/RequestHelper/PanarayDefault";
import store from '../Redux/store';

import {
    ListMenuItem_myList,
    ListMenuItem_myList_MNodeSel,
    leftMenuItem_ownerList,
    leftMenuItem_ownerList_MNodeSel,
    leftMenuItem_favorites,
    ListMenuItem_myList_Fav,
    ListMenuItem_myList_Fav_MNodeSel,
    leftMenuItem_ownerList_Fav,
    leftMenuItem_ownerList_Fav_MNodeSel,
    ListMenuItem_myList_Folder,
    ListMenuItem_notImportedList,
    ListMenuItem_ownershipLoded_myList
} from "../Constants/OwnershipConstants.js";

import BaseServiceApi from 'BaseServiceApi';
const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
import ListManagerListCategory from "ListManagerListCategory";
import TabOwnershipSettings from "../Stores/ConsoleWindow/Settings/Modules/ListManager/TabOwnershipSettings";
const ListType = BaseServiceApi.rayData["ListType"];

// Filter list explorer datasource by category type.
export const nodesToSearch = (categoryType) =>
    _.filter(list, (item) => item.categoryType === parseInt(categoryType));

export const folderLimitReached = (parentNode) => {
    let isFolderLimitReached = false;
    if (!StringUtil.isEmpty(parentNode))
        isFolderLimitReached = parentNode.listTreeItemLevel >= 5 ? true : false;

    return isFolderLimitReached;
}
// Expand node by setting expandNode property.
export const ExpandItemLevel = (categoryType, nodeId, isOpen = false) => {
    const self = this;

    //Fix for PANWEB-661
    if (categoryType === ListCategoryType.POSITIONHISTORYLIST_ListCategory)
        categoryType = ListCategoryType.PORTFOLIOLIST_ListCategory;

    const dataSource = self.nodesToSearch(categoryType);

    if (StringUtil.isEmpty(nodeId)) {
        if (dataSource.length > 0)
            nodeId = dataSource[0].nodeId.low;
    }

    if (!StringUtil.isEmpty(dataSource)) {
        for (let index = 0; index < dataSource.length; index += 1) {
            const searchValue = this.findNodeById(nodeId, dataSource[index]);
            if (searchValue !== false) {
                if (isOpen) {
                    extend(searchValue, { expandNode: true, isVisible: true });
                }
                else
                    extend(searchValue, { expandNode: !searchValue.expandNode, isVisible: true });
            }
        }
    }
}

// Expand and get list item to be selected.
export const ExpandItemLevelAndGetItem = (itemToExpand) => {
    let nextItem = null;
    if (!StringUtil.isEmpty(itemToExpand) && itemToExpand.childNodes.length > 0) {

        if (!StringUtil.isEmpty(itemToExpand.childNodes) && itemToExpand.childNodes.length > 0) {
            const childItems = itemToExpand.childNodes;
            //if (itemToExpand.listType == ListType.CATEGORY_List)
            ExpandItemLevel(itemToExpand.categoryType, itemToExpand.nodeId.low);

            for (let i = 0; i < childItems.length; i += 1) {
                nextItem = childItems[i].listType === ListType.LIST_List ? childItems[i] : this.ExpandItemLevelAndGetItem(childItems[i]);
                if (!StringUtil.isEmpty(nextItem)) break;
            }
        }
    }
    return nextItem;
}

export const flatternDataSource = (dataSource, flatternList) => {
    if (dataSource) {
        if (dataSource.length > 0) {
            dataSource.map((entry) => {
                if (entry !== undefined && entry.childNodes.length > 0) {
                    flatternList.push(entry);
                    flatternDataSource(entry.childNodes, flatternList);
                }
                else {
                    flatternList.push(entry);
                }
                return entry;
            });
        }
    }
}


// Sorting Ownership Data
export const sortOwnerShipData = (dataSource, propertyName) => {
    // let self = this;
    dataSource.forEach((item) => {
        const keyss = keys(item);
        keyss.forEach((key) => {
            if (isArray(item[key])) {
                item[key] = sortOwnerShipData(item[key].reverse(), propertyName);
            }
        });
    });
    return sortBy(dataSource, propertyName).reverse();
}

export const sortOwnershipDataRecursively = (dataSource, propertyName) => {
    let hasDuplicates = false;
    dataSource.forEach((item) => {
        if (item.categoryType === ListCategoryType.PRESET_ListCategory) {
            const seen = {};
            hasDuplicates = item.childNodes.some((currentObject) =>
                seen.hasOwnProperty(currentObject.orderNum)
                || (seen[currentObject.orderNum] = false)
            );
            propertyName = 'orderNum';
        }
        else
            propertyName = 'name';

        const keys = _.keys(item);
        keys.forEach((key) => {
            if (_.isArray(item[key])) {
                if (hasDuplicates) {
                    item[key] = sortOwnershipDataRecursively(item[key], propertyName);
                }
                else {
                    item[key] = sortOwnershipDataRecursively(item[key].reverse(), propertyName);
                }
            }
        });
    });

    let groups = null;
    if (propertyName === 'orderNum')
        groups = _.groupBy(_.sortBy(dataSource, (x) => x.orderNum).reverse(), 'listType');
    else
        groups = _.groupBy(_.sortBy(dataSource, (x) => x.name.toLowerCase()).reverse(), 'listType');

    return _.flatten(_.map(groups, _.values)).reverse();
}

export const sortDataSource = (dataSource) => {
    let tempIndex = undefined, tempLists = [];
    const sortedData = _.sortBy(sortOwnershipDataRecursively(dataSource, 'name'), 'orderNum').reverse();
    _.each(dataSource, (item) => {
      if (item && item.orderNum == 1) {
        tempLists.push(item);
      }
    });
    const sortedDataCopy = _.clone(sortedData)
    _.each(sortedData, (item, index) => {
      if (item && item.orderNum == 1) {
        if (tempIndex == undefined) tempIndex = index;
        sortedDataCopy.splice(tempIndex, 1);
      }
    });
    if (tempLists.length > 0) { sortedDataCopy.splice(tempIndex, 0, ...tempLists); }
    return sortedDataCopy;
}

export const findParentItemByListCategory = (listCategory, dataSource) => 
    _.find(dataSource, (item) => item.listCategory === listCategory);

// Method to check drag and drop allowed or not.
export const canAcceptListDrop = (categoryType, draggedElementCategoryType = null) => {
    if (draggedElementCategoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        return false;
    }
    if (draggedElementCategoryType === ListCategoryType.TEMPORARYLIST_ListCategory)
        return (categoryType === ListCategoryType.USER_ListCategory);
    else
        return (categoryType === ListCategoryType.FAVORITE_ListCategory || categoryType === ListCategoryType.USER_ListCategory || categoryType === ListCategoryType.SHAREDLIST_ListCategory);
}

// Get parent node id while adding List/Folder from list explorer bottom bar.
export const getValidParentItem = (listCategory, dataSource) => {
    const parentNode = null;
    if (!StringUtil.isEmpty(parentNode)) {
        if ((parentNode.listCategory === ListManagerListCategory.USER_ListCategory || parentNode.listCategory === ListManagerListCategory.SHAREDLIST_ListCategory
            || parentNode.listCategory === ListManagerListCategory.FAVORITE_ListCategory)
            && parentNode.listType === ListType.FOLDER_List && canAcceptListDrop(parentNode.categoryType) && parentNode.listCategory === listCategory) {
            return parentNode;
        }
        else {
            return findParentItemByListCategory(listCategory, dataSource);
        }
    }
    else {
        return findParentItemByListCategory(listCategory, dataSource);
    }
}

//Get listCategory by category type
export const convertToListMgrListCategory = (categoryType) => {
    let categoryName;
    switch (categoryType) {
        case ListCategoryType.FAVORITE_ListCategory:
            categoryName = ListManagerListCategory.FAVORITE_ListCategory;
            break;
        case ListCategoryType.USER_ListCategory:
            categoryName = ListManagerListCategory.USER_ListCategory;
            break;
        case ListCategoryType.DATABASELIST_ListCategory:
            categoryName = ListManagerListCategory.DATABASELIST_ListCategory;
            break;
        case ListCategoryType.APLIST_ListCategory:
            categoryName = ListManagerListCategory.APLIST_ListCategory;
            break;
        case ListCategoryType.PORTFOLIOLIST_ListCategory:
            categoryName = ListManagerListCategory.PORTFOLIOLIST_ListCategory;
            break;
        case ListCategoryType.VARIABLELIST_ListCategory:
            categoryName = ListManagerListCategory.VARIABLELIST_ListCategory;
            break;
        case ListCategoryType.THEMELIST_ListCategory:
            categoryName = ListManagerListCategory.THEMELIST_ListCategory;
            break;
        case ListCategoryType.POSITIONHISTORYLIST_ListCategory:
            categoryName = ListManagerListCategory.POSITIONHISTORYLIST_ListCategory;
            break;
        case ListCategoryType.TRANSACTIONSLIST_ListCategory:
            categoryName = ListManagerListCategory.TRANSACTIONSLIST_ListCategory;
            break;
        case ListCategoryType.SHAREDLIST_ListCategory:
            categoryName = ListManagerListCategory.SHAREDLIST_ListCategory;
            break;
        case ListCategoryType.SMARTLIST_ListCategory:
            categoryName = ListManagerListCategory.SMARTLIST_ListCategory;
            break;
        default: // preset list
            categoryName = ListManagerListCategory.PRESET_ListCategory;
            break;
    }
    return categoryName;
}

// Update List explorer datasource on every action in list explorer.
export const updateListExplorer = (dataSource, newNode, parentNodeId,treeLevel) => {
    let nodeIds = [];
    if (!Array.isArray(parentNodeId))
        nodeIds.push(parseInt(parentNodeId));
    else
        nodeIds = parentNodeId;

    dataSource.map((entry) => {
        if (entry.nodeId && contains(nodeIds, entry.nodeId.low)) {
            //Updating ListCategory property
            extend(newNode, { listCategory: convertToListMgrListCategory(newNode.categoryType) });
            (treeLevel) ?  extend(newNode, { listTreeItemLevel: treeLevel, isVisible: true }) :  extend(newNode, { isVisible: true });

            extend(newNode, { parentCategoryType: entry.categoryType, parentCategoryName: entry.name, parentListType: entry.listType });
            entry.childNodes.push(newNode);
        }
        if (entry.childNodes.length > 0) {
            updateListExplorer(entry.childNodes, newNode, parentNodeId)
        }
        return entry;
    });
    return dataSource;
}

// Get ONeil category type by category id.
export const getOneilCategoryTypeById = (categoryType) => {
    let categoryName = null;
    switch (categoryType) {
        case ListCategoryType.FAVORITE_ListCategory:
            categoryName = 'FAVORITES';
            break;

        case ListCategoryType.USER_ListCategory:
            categoryName = 'MY OWNERS LISTS';
            break;

        case ListCategoryType.APLIST_ListCategory:
            categoryName = 'AP LISTS';
            break;

        case ListCategoryType.PORTFOLIOLIST_ListCategory:
            categoryName = 'PORTFOLIO LISTS';
            break;

        case ListCategoryType.SHAREDLIST_ListCategory:
            categoryName = 'SHARED LISTS';
            break;

        case ListCategoryType.SMARTLIST_ListCategory:
            categoryName = 'SMART LISTS';
            break;
        default:
            break;
    }
    return categoryName;
}

export const findNodeById = (nodeId, currentNode) => {
    let i, currentChild, result;
    if(!currentNode){
        return false;
    }
    if (parseInt(nodeId) === currentNode.nodeId.low && currentNode.isVisible) {
        return currentNode;
    } else {
        for (i = 0; i < currentNode.childNodes.length; i += 1) {
            currentChild = currentNode.childNodes[i];
            result = findNodeById(nodeId, currentChild);
            if (result !== false && result.isVisible) {
                return result;
            }
        }
        return false;
    }
}

export const getNodeIdByListCategoryType = (categoryType) =>{
    const dataSource = store.getState().listView.dataSource.list;
    const node = dataSource.find((item)=>item.categoryType == categoryType);
    return node.nodeId.low;
}

export const findlastFolderlevel = (folderNode,initial,isRecursive=true) => {    
    let i,prev,currentChild,finalCount;
    prev=initial;   
    if(!isRecursive){
        finalCount=initial;
    }   
    if (folderNode.listTreeItemLevel && folderNode.isVisible) {
        if(folderNode.listTreeItemLevel>prev){
            finalCount = folderNode.listTreeItemLevel;
        }
        if(folderNode.listTreeItemLevel<prev){
            finalCount = prev
        } 
    }  
    prev = finalCount;      
    if (folderNode.isVisible && folderNode.listTreeItemLevel) {       
        for (i = 0; i < folderNode.childNodes.length; i += 1) {
            currentChild = folderNode.childNodes[i];
            if(currentChild.listTreeItemLevel==undefined)
            break;            
            const result =  findlastFolderlevel(currentChild,prev);
            return result;            
        } 
    }
    return folderNode.listTreeItemLevel;
}

export const findFolderCount = (lastTreeItemLevel,currentListTreeItemlevel) =>{
    const count=(lastTreeItemLevel-currentListTreeItemlevel)+1
    return count;
}


export const expandAndFindParentNode = (activeNodeId, list) => {
    if (!StringUtil.isEmpty(list)) {
        for (let index = 0; index < list.length; index += 1) {
            const searchValue = findNodeById(activeNodeId, list[index]);
            if (searchValue !== false) {
                if (searchValue.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory){
                    _.extend(searchValue, { expandNode: true });
                    return list;
                }
                if (searchValue.parentNodeId === null) {
                    _.extend(searchValue, { expandNode: true });
                    return;
                }
                _.extend(searchValue, { expandNode: true });
                expandAndFindParentNode(searchValue.parentNodeId.low, list);
            }
        }
    }
    return list;
}


export const getListInFavorite = (favoriteList, nodeId) => {
    // let self = this;
    let result = null;
    nodeId = typeof (nodeId) === 'object' ? nodeId && nodeId.low : nodeId;
    if (!StringUtil.isEmpty(favoriteList)) {
        for (let index = 0; index < favoriteList.length; index += 1) {
            const entry = favoriteList[index];
            if (entry !== undefined && entry.categoryType === ListCategoryType.FAVORITE_ListCategory && entry.childNodes.length > 0 && entry.isVisible) {
                result = getListInFavorite(entry.childNodes, nodeId);
                if (!StringUtil.isEmpty(result))
                    break;
            }
            else {
                if ((entry.sourceListId !== null && entry.sourceListId !== undefined) && entry.sourceListId.low === nodeId && entry.isVisible) {
                    result = entry;
                    if (!StringUtil.isEmpty(result))
                        break;
                }
            }
        }
    }
    return result;
}

export const isExistInUserListCategory = (sourceList, nodeId) => {    
  const userList = getListByCategory(sourceList,ListCategoryType.USER_ListCategory);
  return  findNodeById(nodeId,userList);
} 

export const getListByCategory = (list, categoryId) => {
    let currentList;
    if (!StringUtil.isEmpty(list)) {
        currentList = find(list, (item) => item.categoryType === categoryId);
    }
    return currentList;
}

export const getSaveScreenedResults = (nodeId, activeNode, selectedNodeIds) => {
    let isNodeActive = false;
    let isSaveScreenedResults = false;
    if (!StringUtil.isEmpty(activeNode) && !StringUtil.isEmpty(nodeId))
        isNodeActive = (activeNode === nodeId);
    if (selectedNodeIds === '' && isNodeActive && OwnershipViewStore.getSaveScreenedResultsVisibilty()) {
        isSaveScreenedResults = true;
    }

    return isSaveScreenedResults;
}

export const getListMenu = (catType, listType, list, nodeId, selectedNodeChek = '', activeNode, selectedNodeIds) => {
    const isSreenResultEnabled = getSaveScreenedResults(nodeId, activeNode, selectedNodeIds);
    //let selectdNodLenth;
    const selectdNodLenth = selectedNodeChek.split(',').length;       
    let leftMenu = [];
    const favNode = getListInFavorite(getListByCategory(list, ListCategoryType.FAVORITE_ListCategory).childNodes, nodeId);
    if (listType === ListType.FOLDER_List) {
        leftMenu = ListMenuItem_myList_Folder;
    } else {
        leftMenu = catType === ListCategoryType.USER_ListCategory && !favNode && selectdNodLenth > 1 ? ListMenuItem_myList_MNodeSel : catType === ListCategoryType.USER_ListCategory && !favNode && !OwnershipViewStore.isListLoaded ? 
          ListMenuItem_ownershipLoded_myList : catType === ListCategoryType.USER_ListCategory && !favNode && OwnershipViewStore.isListLoaded ?ListMenuItem_myList 
           : catType === ListCategoryType.FAVORITE_ListCategory ? leftMenuItem_favorites
                : catType === ListCategoryType.USER_ListCategory && favNode && selectdNodLenth > 1 ? ListMenuItem_myList_Fav_MNodeSel : catType === ListCategoryType.USER_ListCategory && favNode ? ListMenuItem_myList_Fav
                    : favNode && selectdNodLenth > 1 ? leftMenuItem_ownerList_Fav_MNodeSel : favNode ? leftMenuItem_ownerList_Fav : selectdNodLenth > 1 ? leftMenuItem_ownerList_MNodeSel : leftMenuItem_ownerList;
    }
    let position = 1;    
    if(catType === ListCategoryType.NOTIMPORTEDLIST_ListCategory){
        return leftMenu = ListMenuItem_notImportedList;
    } 
    if(catType === ListCategoryType.FAVORITE_ListCategory || catType === ListCategoryType.SMARTLIST_ListCategory)position=2;
    const updatedLeftMenu = clone(leftMenu)
    if (isSreenResultEnabled) {        
        updatedLeftMenu.splice(position, 0, { header: 'Save Screen Result', key: 'SAVESCREEN' });
        return updatedLeftMenu;
    }
    if(isSreenResultEnabled==false && nodeId===activeNode){        
        updatedLeftMenu.splice(position, 0, { header: 'Save Screen Result',key : 'DISABLE_SAVE_SCREEN' ,className: 'ownershipItemDisabled'});
        return updatedLeftMenu;
    }
    if(catType === ListCategoryType.NOTIMPORTEDLIST_ListCategory){
        leftMenu = ListMenuItem_notImportedList;
    }
    return leftMenu;
}
//Get Next active Node
export const getNextAvailableList = (nodeId, list, rowslist) => {

    const flatternList = [];
    const nextAvailableIndexList = [];
    let index = null;
    let nextItem = null;

    flatternDataSource(list, flatternList);

    const activeNode = OwnershipViewStore.activeNode;
    if (activeNode.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        index = _.findIndex(flatternList, (item) => item.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && item.nodeId.low === nodeId);
        const templList = _.filter(flatternList, (item, key) => key > index && item.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && item.isVisible);
        if (templList.length > 0) {
            nextItem = templList[0];
        } else {
            const tabOwnershipSettings = new TabOwnershipSettings();
            const readyData = tabOwnershipSettings.getDefaultSelectedListInfo();
            parseInt(ListCategoryType.FAVORITE_ListCategory)
            let nextItemList = _.filter(flatternList, (item, key) =>  item.parentListType === parseInt(readyData.SelectedParentListType) && item.name === readyData.SelectedListName &&  item.listCategory==="Preset");
            if(nextItemList.length > 0){
                nextItem = nextItemList[0];
            }
        }
    } else {
    index = findIndex(flatternList, (item) => item.nodeId.low === nodeId);

    if (index > -1) {
        for (let i = index; i <= flatternList.length; i++) {
            nextAvailableIndexList.push(i);
        }
        nextAvailableIndexList.splice(0, 1);

        const nextItemList = reject(flatternList, (item, key) => {
            if (!nextAvailableIndexList.contains(key)) {
                return true;
            };
        });

        for (let i = 0; i < nextItemList.length; i++) {
            nextItem = nextItemList[i];

            if (nextItem.listType === ListType.LIST_List && nextItem.isVisible) {
                break;
            }
            if (!StringUtil.isEmpty(nextItem.childNodes) && nextItem.childNodes.length > 0) {
                nextItem = ExpandItemLevelAndGetItem(rowslist);
                if (!StringUtil.isEmpty(nextItem))
                    break;
            }
        }
    }
    }

    return nextItem;
}

//Get Parent NodeId
export const getParentNodeId = (type, list) => {    
    const filterList = filter(list, (rowList) => rowList.categoryType  === type);

    return filterList[0].nodeId.low;
}
//Get Parent Node
export const getParentId = (nodeId, list) => {
    const flatternList = [];

    flatternDataSource(list, flatternList);
    const filterList = filter(flatternList, (rowList) => rowList.nodeId.low === nodeId);

    return filterList[0];
}

export const getDeletedNode = (nodeId, parantNodeId, list) => {
    const flatternList = [];

    flatternDataSource(list, flatternList);
    const filterList = filter(flatternList, (rowList) => (rowList.nodeId.low === nodeId && rowList.parentNodeId && rowList.parentNodeId.low === parantNodeId) || (rowList.nodeId.low === nodeId && rowList.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory));

    return filterList[0];
}

export const getFavList = (nodeId, list) =>{
    const flatternList = [];

    flatternDataSource(list, flatternList);
    const filterList = filter(flatternList, (rowList) => rowList.categoryType == ListCategoryType.FAVORITE_ListCategory && rowList.isVisible==true && rowList.sourceListId!=null && rowList.sourceListId.low === nodeId);
    return filterList[0];
}

export const getLatestNode = (nodeId,list) => {
    const flatternList = [];
    flatternDataSource(list, flatternList);
    const filterList = filter(flatternList, (rowList) => rowList.nodeId.low === nodeId);
    let latestNode=filterList.length>0?filterList[0]:'';
    if(latestNode.updateDt!=null){
    for(let i=0;i<filterList.length;i++){
        latestNode=latestNode.updateDt.low>filterList[i].updateDt.low?latestNode:filterList[i];
    }}
 
    return latestNode;
}

export const getVisibleNode = (nodeId, list) => {
    const flatternList = [];
    flatternDataSource(list, flatternList);
    const filterList = filter(flatternList, (rowList) => rowList.nodeId.low === nodeId && rowList.isVisible === true);
    return filterList[0];
}


export const isDeleteAllowed = (categoryType) => {
    if (categoryType === ListCategoryType.USER_ListCategory || categoryType === ListCategoryType.SCHEDULESCREENLIST_ListCategory || categoryType === ListCategoryType.FAVORITE_ListCategory || categoryType === ListCategoryType.SHAREDLIST_ListCategory || categoryType === ListCategoryType.TEMPORARYLIST_ListCategory || categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
        return true;
    }
    return false;
}

//Get Next Node based on node IsActive check
export const getNextNode = (isActive, nodeId, currentNode, source, activeNode) => {
    const nextnode = isActive ? getNextAvailableList(nodeId, source, currentNode) : getParentId(activeNode, source);
    return nextnode;
}

//Validate NodeName
export const validateListName = (nodeName, parentNodeId, dataSource, nodeType) => {
    let newName = nodeName;
    let count = 0;
    const nodesToSearch = getCurrentNode(parentNodeId, dataSource, nodeType);
    if (!StringUtil.isEmpty(nodesToSearch)) {
        let duplicateNamedItem = findDuplicateList(nodesToSearch, nodeName, nodeType);

        while (duplicateNamedItem !== null && duplicateNamedItem !== undefined) {
            newName = nodeName.concat(` (${++count})`);
            duplicateNamedItem = findDuplicateList(nodesToSearch, newName, nodeType);
        };

    }
    return newName;
}

export const getCurrentNode = (nodeId, dataSource, nodeType) => {
    const ParentListTreeItem = [];
    let currentNode = [];
    findParentNode(nodeId, ParentListTreeItem, dataSource, nodeType);
    if (ParentListTreeItem.length > 0) {
        currentNode = ParentListTreeItem[0].childNodes.length > 0 ? ParentListTreeItem[0].childNodes : null;
    }
    return currentNode;
}
//Find Duplicate in Source
export const findDuplicateList = (nodesToSearch, nodeName, nodeType) => {
    if (!StringUtil.isEmpty(nodesToSearch))
        return find(nodesToSearch, (item) => {
            if (!StringUtil.isEmpty(item)) {
                if (item.isVisible) {
                    return item.name.toUpperCase() === nodeName.toUpperCase() && item.listType == nodeType;
                }
            }
        });
}

export const findParentNode = (nodeId, nodeList, dataSource) => {
    // let self = this;
    if (!StringUtil.isEmpty(dataSource)) {
        for (let i = 0; i < dataSource.length; i += 1) {
            const searchValue = findNodeById(nodeId, dataSource[i]);
            if (searchValue !== false) {
                nodeList.push(searchValue);
                if (searchValue.parentNodeId === null)
                    return;
                findParentNode(searchValue.parentNodeId.low, nodeList);
            }
        }
    }
}

export const getSelectedDBType = (tabType) => {
    let dbType = null;
    switch (tabType) {
        case ListManagerTabType.Oneil:
            dbType = 1;
            break;
        case ListManagerTabType.Ownership:
            dbType = 2;
            break;
        case ListManagerTabType.External:
            dbType = 3;
            break;
        default:
            break;
    }
    return dbType;
}

export const isEditableList = (activeNode) => {
    if (activeNode) {
        const parentCategoryType = activeNode.sourceCategoryType;

        return (activeNode.categoryType === ListCategoryType.USER_ListCategory || activeNode.categoryType === ListCategoryType.SCHEDULESCREENLIST_ListCategory
            || (activeNode.categoryType === ListCategoryType.FAVORITE_ListCategory &&
                (parentCategoryType === ListCategoryType.USER_ListCategory || parentCategoryType === ListCategoryType.SCHEDULESCREENLIST_ListCategory)));
    }
}

export const getParentNodeDescription = (activeNode) => {
    // let self = this;
    let parentNode = null;
    const nodesToSearch = nodesToSearch(activeNode.parentCategoryType);
    if (!StringUtil.isEmpty(nodesToSearch)) {
        for (let i = 0; i < nodesToSearch.length; i++) {
            parentNode = findNodeById(activeNode.parentNodeId.low, nodesToSearch[i]);
            if (parentNode !== false) return parentNode.description;
        }
    }
}

export const findActiveListAvailibility = (ownershipDataSource, listId = '') => {
    const flatternList = [];
    _.clone(flatternDataSource(ownershipDataSource, flatternList));
    const allListIds = flatternList && flatternList.map((entry) => (entry.nodeId.low));
    if (!allListIds.includes(parseInt(listId))) {
        return false;
    } else {
        return true;
    }
}