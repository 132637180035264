import Scale from "./Scale.js";

class GrpsLogPriceScale extends Scale {

    constructor(mStockInfo, maxScales = 30) {
        super();
        this.Mnodes = 900;
        this.MaxScales = maxScales;
        this.PsTargetInfo = mStockInfo;

        this.PrScl0 = [];
        this.PrScl1 = [];
        this.PrScl2 = [];
        this.InitScale();
        this.SetHorizontalGrid();
    }

    ComputePrice(y) {
        let dGraphDistance = this.PsTargetInfo.VpHeight - y;

        let dPriceDiffFromBottom = dGraphDistance / this.Factor;
        dPriceDiffFromBottom += Math.log10(this.Lowpt);

        return Math.pow(10, dPriceDiffFromBottom);
    }

    ComputeY(dPrice) {
        let dPriceDiffFromBottom = Math.log10(dPrice) - Math.log10(this.Lowpt);

        return this.PsTargetInfo.VpHeight - dPriceDiffFromBottom * this.Factor;
    }

    //Compute3Y(d1Value, d2Value, d3Value, d1Out, d2Out, d3Out)
    Compute3Y(d1Value, d2Value, d3Value) {
        let dPriceDiffFromBottom1 = Math.log10(d1Value) - Math.log10(Lowpt);
        d1Out = this.PsTargetInfo.VpHeight - dPriceDiffFromBottom1 * this.Factor;

        var dPriceDiffFromBottom2 = Math.log10(d2Value) - Math.log10(Lowpt);
        d2Out = this.PsTargetInfo.VpHeight - dPriceDiffFromBottom2 * this.Factor;

        var dPriceDiffFromBottom3 = Math.log10(d3Value) - Math.log10(Lowpt);
        d3Out = this.PsTargetInfo.VpHeight - dPriceDiffFromBottom3 * this.Factor;
    }

    InitScale() {
        let hiprc = this.PsTargetInfo.MaxPrice;
        let lowprc = this.PsTargetInfo.MinPrice;

        this.Hipt = hiprc + (hiprc * 0.25);
        this.Lowpt = lowprc - (lowprc * 0.25);

        // Compute the conversion Factor.
        this.Factor = parseFloat(((this.PsTargetInfo.VpHeight) / (Math.log10(this.Hipt) - Math.log10(this.Lowpt))));

        //var sp = new float[MaxScales + 1];
        let sp = [], k = 0, x = 10; //pt100 = 0, pt50 = 0

        /* PrScl0 = new float[Mnodes];
         PrScl1 = new float[Mnodes];
         PrScl2 = new int[Mnodes];
         */
        sp[0] = x;

        for (let i = 1; i <= this.MaxScales; i++) {
            if (x < 20)
                x = x + 1;
            else
                if (x < 50)
                    x = x + 2;
                else
                    if (x < 100)
                        x = x + 10;
            sp[i] = x;
        }
        for (let i = 1; i < this.MaxScales; i++) {
            let xl = parseFloat(Math.pow(10.0, (i - 5)));
            for (let j = 0; j <= this.MaxScales; j++) {
                this.PrScl0[k] = xl * sp[j];
                // if (this.PrScl0[k] == 100)
                //     pt100 = k;
                // if (this.PrScl0[k] == 50)
                //     pt50 = k;

                this.PrScl1[k] = parseFloat(Math.log10(this.PrScl0[k]));
                this.PrScl2[k] = 1;
                k++;
            }
        }
    }

    SetHorizontalGrid() {

        this.MHLines = [];
        this.MHLabel = [];
        this.yCount = [];
        let bottomOfGraph = parseInt(this.PsTargetInfo.BoxHeight);
        const topOfGraph = 10;
        let i = 0;
        for (; i < this.Mnodes && this.PrScl0[i] < this.Lowpt; i++);

        let mLowpt = Math.max(i, 1);
        let pricePtr = mLowpt;
        let mlLowpt = this.PrScl1[mLowpt] - this.PrScl1[1];
        let YLowpt = parseFloat((mlLowpt * this.Factor));
        let py = this.ComputeY(this.PrScl0[pricePtr]);
        pricePtr++;

        for (; pricePtr < this.Mnodes; pricePtr++) {
            if (this.PrScl2[pricePtr] != 1) {
                // No line at this point.
                continue;
            }

            let y;
            if (pricePtr == mLowpt)
                y = parseInt(bottomOfGraph + YLowpt);
            else
                y = parseInt(this.ComputeY(this.PrScl0[pricePtr]));

            if (y >= bottomOfGraph) {
                // Still below the graph.
                continue;
            }

            if (y < topOfGraph)
                break;

            if (!this.yCount.includes(y)) {
                //  MHLines.Add(y, 1);
                let price = this.PrScl0[pricePtr];

                if (price < Number.MAX_VALUE) { //float.MaxValue
                    //2009-12-21 - Discuss with Ates on how to handle StockInfo.EpsMultiplier == null
                    // set ELabel to Blank for now
                    var eLabel = "";
                    var rlabel = "";
                    var hLabel = price.toFixed(0);

                    // Need to handle smaller numbers fixed (11/12/2010)
                    hLabel = price > 999999 ? price.toFixed(0)
                        : (price > 9999 ? price.toFixed(0)
                            : (price < 0.01 ? price.toFixed(0)
                                : (price.toFixed(0))));

                    var ePrice;
                    if (this.PsTargetInfo.EpsMultiplier != null) {
                        ePrice = Math.trunc((pricePtr * 1000.0 / this.PsTargetInfo.EpsMultiplier)) / 1000.0;
                        //var ePrice = (float)(price / this.PsTargetInfo.EpsMultiplier);
                        // Need to handle smaller numbers fixed (11/12/2010)
                        eLabel = ePrice > 4.9 ? ePrice.toFixed(0)
                            : ePrice.toFixed(0);
                    }

                    if (this.PsTargetInfo.RpsMultiplier != null) {
                        ePrice = Math.trunc((pricePtr * 1000.0 / this.PsTargetInfo.RpsMultiplier)) / 1000.0;
                        //var ePrice = (float)(price / this.PsTargetInfo.RpsMultiplier);
                        // Need to handle smaller numbers fixed (11/12/2010)
                        rlabel = ePrice > 4.9 ? ePrice.toFixed(0)
                            : ePrice.toFixed(0);
                    }

                    this.yCount.push(y);
                    var labels =
                    {
                        HLabel: hLabel,
                        ELabel: eLabel
                    };

                    //let self = this;
                    this.MHLines.push(
                        {
                            y: y,
                            LType: 1,
                            Label: hLabel,
                            YAxis: y,
                            eLabel: eLabel,
                            rLabel: rlabel,
                            YGap: (py - y)
                        }
                    );

                    this.MHLabel.push({ y: y, labels });
                }
                else {
                    // price is greater than we can print - short circuit this loop
                    pricePtr = this.Mnodes + 1;
                }
            }
            py = y;
        }
    }

    SetGrid() {
        this.SetHorizontalGrid();
    }
}


export default GrpsLogPriceScale;