import { connect } from "react-redux";
import Measure from "react-measure";
import ReactHtmlParser from "react-html-parser";
import React, { Component } from "react";

class TabularView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: 0.0,
      width: 0.0
    };
    this.source = this.props.data ? this.props.data : [],
      this.headerCellWidth = 128,
      this.cellWidth = 46,
      this.columnCount = 9,
      this.rowCount = 4;
  }

  handleResize(contentRect) {
    this.setState({ height: contentRect.bounds.height, width: contentRect.bounds.width });
  }

  renderGrid() {
    if (!this.gridCanvas ||
      this.headerCellWidth === 0.0 ||
      this.cellWidth === 0.0 ||
      this.columnCount === 0 ||
      this.rowCount === 0 ||
      this.state.height === 0.0 ||
      this.state.width === 0.0
    ) {
      return;
    }
    const ctx = this.gridCanvas.getContext("2d");
    ctx.canvas.height = this.state.height;
    ctx.canvas.width = this.state.width;
    ctx.clearRect(0, 0, this.state.width, this.state.height);
    ctx.lineWidth = 1;
    ctx.translate(0.5, 0.5);
    ctx.strokeStyle = "#4B525C";
    ctx.save();
    this.drawVerticalLines(ctx);
    this.drawHorizontalLines(ctx);
  }

  drawHorizontalLines(ctx) {
    const rowheight = Math.round(this.state.height / (this.rowCount + 1));
    ctx.beginPath();
    ctx.moveTo(0.0, rowheight - 1);
    ctx.lineTo(this.state.width, rowheight - 1);
    ctx.stroke();
    const count = this.rowCount;
    for (let i = 1; i <= count; i++) {
      const yValue = ((i * rowheight) - 1);
      ctx.moveTo(0.0, yValue);
      ctx.lineTo(this.state.width, yValue);
    }
    ctx.stroke();
    ctx.closePath();
  }

  drawVerticalLines(ctx) {
    ctx.beginPath();
    ctx.moveTo(this.headerCellWidth, 0.0);
    ctx.lineTo(this.headerCellWidth, this.state.height);
    ctx.stroke();
    const colCount = this.columnCount;
    for (let i = 1; i < colCount; i++) {
      const xValue = (i * this.cellWidth) + this.headerCellWidth;
      ctx.moveTo(xValue, 0.0);
      ctx.lineTo(xValue, this.state.height);
    }
    ctx.stroke();
    ctx.closePath();
  }

  getCellClassName(cellIndex, index) {
    let className = "tabularView_cell";
    if (cellIndex === 0 && index !== 0) {
      className = "tabularView_cell swatch-color";
    }
    return className;
  }

  getCell(cell, cellIndex, index) {
    let width = "0px";
    let color = cell.cell ? cell.cell.color : undefined;

    //apply custom color
    if (cell.cell && cell.cell.isUseCusColor) {
      color = cell.cell.isPositive ? this.props.posDTColor
        : this.props.negDTColor;
    }

    const textAlign = cell.cell ? cell.cell.textAlign : "center";
    const value = cell.cell ? cell.cell.value ? cell.cell.value : "&nbsp;" : "&nbsp;";
    if (cellIndex > 0) {
      width = `${(this.cellWidth)}px`;
    }
    else {
      width = `${(this.headerCellWidth)}px`;
    }
    return (
      <div className={this.getCellClassName(cellIndex, index)} style={{ color: color, width: width, textAlign: textAlign }} key={`r${index}c${cellIndex}`}>&nbsp; {ReactHtmlParser(value)}</div>
    );
  }

  getRow(row, index) {
    if (this.state.height === 0.0) { return; }
    const rowHeight = Math.round(this.state.height / (this.rowCount + 1));
    const top = (index * rowHeight) - 1;
    return (<div className={this.getClassName(index)} key={index}
      style={{ height: rowHeight, width: "100%", position: "absolute", top: top, whiteSpace: "nowrap", cursor: "default" }}>
      {row.cells.map((cell, cellIndex) => this.getCell(cell, cellIndex, index))}
    </div>);
  }

  getClassName(index) {
    let className = "tabular-header";
    if (index === 0) {
      className = `${className} tabular-title head`;
    }
    return className;
  }

  render() {
    this.renderGrid();
    return (
      <Measure
        bounds
        onResize={(contentRect) => { this.handleResize(contentRect) }}
      >
        {({ measureRef }) =>
          <div className="tabular-box" ref={measureRef}>
            <canvas className="tabular-canvas" ref={(ref) => (this.gridCanvas = (ref))}></canvas>
            {this.source.map((row, index) => this.getRow(row, index))}
          </div>
        }
      </Measure>
    );
  }
}
const mapStateToProps = ({ summaryReducer, appColor }) => {
  const data = summaryReducer.weeklyData.rowSource;
  const { posDTColor, negDTColor } = appColor;
  return { data, posDTColor, negDTColor }
}
export default connect(mapStateToProps, null)(TabularView)