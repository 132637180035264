import React,{ useState } from 'react';

import { ListType } from "../../../Constants/ShareConstants";
import LocalizationStore from 'LocalizationStore';
import AccessLevel from "./AccessLevel.jsx";
import Permission from "./Permission.jsx";
import PortalContainer from "PortalContainer";


const RecipientData = ({ shareData, index, sharedAction, sharedData, isIcs }) => {    
    let [showToolTip,setShowToolTip]=useState(false);
    let [toolTipX,SetToolTipX]=useState(0)
    let [toolTipY,SetToolTipY]=useState(0)
    const showTooltip=(e)=>{
        if(toolTipX!=e.target.getBoundingClientRect().left)SetToolTipX(e.target.getBoundingClientRect().left);
        if(toolTipY!=e.target.getBoundingClientRect().top)SetToolTipY(e.target.getBoundingClientRect().top);
        if(e.target.offsetWidth<e.target.scrollWidth){
            setShowToolTip(true)
        }
    }
    const clearCellTitle=() =>{
            setShowToolTip(false)
    }
    sharedData[0].isICS=isIcs;
    const heading =
        {
            [ListType.LIST_List]: LocalizationStore.getTranslatedData("set_shar_lists", 'List'),
            [ListType.COLUMNSET_List]: LocalizationStore.getTranslatedData('set_shar_cols', 'Column Set'),
            [ListType.ANNOTATIONLAYER_List]: LocalizationStore.getTranslatedData('set_shar_anno', 'Annotation'),
            [ListType.EXTERNAL_List]: 'External Data Set'
        }[shareData.nodeType]
    const nodeType =
        {
            [ListType.LIST_List]: 'List',
            [ListType.COLUMNSET_List]: 'Column Set',
            [ListType.ANNOTATIONLAYER_List]: 'Annotation',
            [ListType.EXTERNAL_List]: 'External Data Set'
        }[shareData.nodeType]

    return (
        <tr key={index} data-test-id="main-div">
            <td className='form-tbl-25 column1'>
                {heading}
            </td>
            <td className='form-tbl-50 form-tbl-45 manage-data-details-td ellipsis column2' onMouseOver={showTooltip} onMouseOut={clearCellTitle}>
               {shareData.nodeName}
            </td>
            {
                showToolTip &&
                <PortalContainer><div style={{ left: toolTipX, top: toolTipY }} className="share-tooltip">{shareData.nodeName}</div></PortalContainer>
            }
            <td className='form-tbl-25 form-tbl-31 column3' colSpan="2">
                {
                    <div>
                        {
                            shareData.isShared &&
                            <AccessLevel
                                accessLvl={shareData.accessLvl}
                                recipientid={shareData.recipientId}
                                isShared={shareData.isShared}
                                isICS={isIcs}
                                sharedData={sharedData}
                                sharedAction={sharedAction}
                                type={shareData.nodeType}
                            />
                        }
                        <Permission
                            isShared={shareData.isShared}
                            recipientid={shareData.recipientId}
                            sharedData={sharedData}
                        />
                    </div>
                }
            </td>
        </tr>
    );
}

export default RecipientData;
