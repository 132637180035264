import { AnnotationConstant } from "Constants/AnnotationConstants.js";
import AnnotationsApi from "../../../../ServiceApi/Apis/AnnotationsApi";
import AnnotationUtil from "Utils/AnnotationUtil";
import BaseServiceApi from 'BaseServiceApi';
import ConsoleStore from '../../../../Stores/ConsoleWindow/ConsoleStore';
import ContextMenuConstants from "ContextMenuConstants";
import DatagraphDataType from "Constants/DatagraphDataType.js";
import DataGraphStore from '../../../../Stores/NavModules/NavDataGraph/DataGraphStore';
import DateHelper from "DateHelper";
import { find } from 'underscore';
import GraphType from "GraphType";
import Long from "long";
import moment from "moment";
import { PrintMode } from '../../../../print/printmode';
import { processAnnotationLayerData } from './AnnotationLayerManagementSaga';
import { RayAnnotationType } from "Constants/EAnnotationType";
import { safe } from '../../../ErrorModel';
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../../Constants/SmartViewType";
import TabType from "Constants/TabType";
import TimeTrackingWindow from '../../../../RayCustomControls/TimeTrackingWindow';
import { updateRiPanelInfoFromUserSettings } from '../../../../Actions/AnnotationActions';
import {
    getAnnotationDimension, getSelectedAnnotId,
    getLine, getVisibleAnnotIds, getLayersList
} from "../../../../Reducers/AnnotationReducers/selectors";
import { getDatagraphStates, priceChartReducerselect } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { takeLatest, call, put, select, take, fork, cancel } from 'redux-saga/effects';
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
const { ActionTypes } = AnnotationConstant;

let isProcessed = false;

function* reDrawAnnotationsOnChartResize() {
    // Avoid invoking getAnnotation api before the completion of layer data / first time data process.
    // AnnotationUtil is set to null incase of periodicity switch, symbol switch and on calendar events.
    try {
        if (isProcessed) {
            /*   No need to invoke getAnnotations api if the user has not started to draw or if no changes are made to existing annotations.
                Reuse the stored response. */
            if (AnnotationUtil && AnnotationUtil.annotations) {
                yield safe(call(processAnnotationData, { annotations: AnnotationUtil.annotations }), "AnnotationInitModel.js", "processAnnotationData");
            }
            else {
                yield safe(call(getAnnotationApiData), "AnnotationInitModel.js", "getAnnotationApiData");
            }
        }
    }
    catch (error) {
        console.log(`Error occurs in AnnotationInitModel.js, reDrawAnnotationsOnChartResize ${error}`);
    }
}

function* initAnnotations({ invokeLayerApi, symbol, endDate, beginDate }) {
    try {
        // Get a new instance of drawing for every change in periodicity, scale and symbol
        yield put({
            type: ActionTypes.GET_DEFAULT_DRAWING_MODE
        });

        if (invokeLayerApi) {
            TimeTrackingWindow.beginAnnotationTimeTracker();
            const response = yield call(AnnotationsApi.GetAnnotationLayers);
            AnnotationUtil.layerData = response.annotationLayerData;

            // TODO: need to verify new optimised behavior for share action
            /* if symbol is passed - it could be refresh / login / symbol change / periodicity change actions in which, 
                getAnnotations api is dependant on getLayer api. So annotations data can be processed only after the completion of 
                layer data process 
            */
            if (symbol) {
                yield safe(call(getAnnotationApiData, symbol, endDate, beginDate), "AnnotationInitModel.js", "getAnnotationApiData");
            }
            else {
                // yield put({ type: ActionTypes.CLEAR_ANNOTATION_LAYERS });
                yield safe(call(processAnnotationLayerData, { layerData: response.annotationLayerData }), "AnnotationInitModel.js", "processAnnotationLayerData");
            }
        }
        else {
            yield safe(call(getAnnotationApiData, symbol, endDate, beginDate), "AnnotationInitModel.js", "getAnnotationApiData");
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            hasError: true,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationInitModel.js, processAllAnnotations ${error}`);
    }
}

function* annotationContextMenu({ showContext, clientX, clientY, shape }) {
    try {
        const contextMenu = [];
        const selectedIds = yield select(getSelectedAnnotId);

        const symbolType = SettingsStore.getConsoleSettings().NavDatagraphSettings.symbolTypeEnum;
        let alertDisabled = false; // disable alert for futures, cashspots, currency
        if (symbolType === 9 || symbolType === 18 || symbolType === 28 || symbolType === 29) {
            alertDisabled = true;
        }
        // logic for adding and removing trendline alert
        if (shape === RayAnnotationType.LINE_AnnotationType && selectedIds.length === 1 && !alertDisabled) {
            const lines = yield select(getLine);
            const selectedAnnotation = lines.find((itm) => itm.id === selectedIds[0]);

            if (!selectedAnnotation.isAlertChecked) {
                contextMenu.push({ headerText: ContextMenuConstants.CREATE_LINE_ALERT, action: ContextMenuConstants.CREATE_LINE_ALERT });
            } else {
                contextMenu.push({ headerText: ContextMenuConstants.REMOVE_LINE_ALERT, action: ContextMenuConstants.REMOVE_LINE_ALERT });
            }
        }

        //default context menu
        contextMenu.push({ headerText: ContextMenuConstants.DELETE_SELECTED, action: ContextMenuConstants.DELETE_SELECTED })
        //Delete all option only when multiple annotations available
        // const annotationsReducers = yield select(getAnnotationsReducers);
        // let { annotationsCount } = getAnnotationsCount(annotationsReducers);

        const annotSelectedId = yield select(getVisibleAnnotIds);
        const layersList = yield select(getLayersList);
        const filteredResult = [];
        layersList.forEach((items) => {
            if (items.isVisible) {
                annotSelectedId.forEach((item) => {
                    if (item.layerId === items.layerID) {
                        filteredResult.push(item);
                    }
                });
            }
        });
        const deletableIds = [];
        const annotLayers = [];
        filteredResult.forEach((items) => {
            if (!items.deleted) {
                deletableIds.push(items.annotId);
                annotLayers.push(items.layerId);
            }
        });
        if (deletableIds.length > 1) {
            contextMenu.push({ headerText: ContextMenuConstants.DELETE_ALL, action: ContextMenuConstants.DELETE_ALL });
        }
        //default context menu
        contextMenu.push({ headerText: ContextMenuConstants.MOVE_TO_FRONT, action: ContextMenuConstants.MOVE_TO_FRONT })
        contextMenu.push({ headerText: ContextMenuConstants.MOVE_TO_BACK, action: ContextMenuConstants.MOVE_TO_BACK })

        yield put({
            type: ActionTypes.ANNOTATION_CONTEXT_MENU_TOGGLE,
            showContext, clientX, clientY, shape, contextMenu
        });
    }
    catch (error) {
        console.log(`Error occurs in AnnotationInitModel.js, annotationContextMenu ${error}`);
    }
}

export function* getAnnotationApiData(symbol = '') {
    const beginDate = DateHelper.yyyymmdd(new Date("01/01/1900"));
    const endDate = DateHelper.yyyymmdd(new Date());
    const chartDate = endDate;
    const settings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
    const periodicityType = AnnotationUtil.getPeriodicityType(settings.Periodicity);

    isProcessed = false;
    if (symbol) {
        let result;
        try {        
            AnnotationUtil.isAnnotationApiCallInitiated && TimeTrackingWindow.beginAnnotationTimeTracker();
            result = yield call(AnnotationsApi.GetAnnotations, '', periodicityType, 1, beginDate, endDate, chartDate, DataGraphStore.getCurrencyCode(''), symbol);
            AnnotationUtil.isAnnotationApiCallInitiated && TimeTrackingWindow.endAnnotationApiTimeTracker();
        } catch (error) {
            console.error(error);
            TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.Annotation);
            TimeTrackingWindow.endAnnotationApiTimeTracker();
        }
        if(result && result.annotationData){
            AnnotationUtil.annotations = result.annotationData;
        }
        AnnotationUtil.isAnnotationApiCallInitiated = false;
        AnnotationUtil.isAnnotationLoadInitiated = true;
        const priceChartState = yield select(priceChartReducerselect);
        const datagraphStates = yield select(getDatagraphStates);
        // if (!datagraphStates.pricePanelData ) {
        //     return; // invalid symbol entry
        // }
        // else 
        if (datagraphStates.pricePanelData && !isProcessed && result && result.annotationData) {
            // yield put({ type: ActionTypes.CLEAR_ANNOTATION_LAYERS });
            if (priceChartState.isPriceDataReady || datagraphStates.priceDataAvailable) {

                yield call(processAnnotationLayerData, { layerData: AnnotationUtil.layerData });
                yield safe(call(processAnnotationData, { annotations: result.annotationData }), "AnnotationInitModel.js", "processAnnotationData");
            }
        }
        else{
            isProcessed = true
            yield call(processAnnotationLayerData, { layerData: AnnotationUtil.layerData });
        }
    }
    else {
        const msId = AnnotationUtil.getMsid();
        const targetcurrencycode = AnnotationUtil.getTargetCurrencyCode();

        if (!msId){ 
            return;
        }
        AnnotationUtil.isAnnotationApiCallInitiated = true
        const result = yield call(AnnotationsApi.GetAnnotations, msId, periodicityType, 1, beginDate, endDate, chartDate, targetcurrencycode);
        if(result && result.annotationData){
            AnnotationUtil.annotations = result.annotationData;
            yield safe(call(processAnnotationData, { annotations: result.annotationData }), "AnnotationInitModel.js", "processAnnotationData");
        }
        AnnotationUtil.isAnnotationApiCallInitiated = false;
    }
}

// will be used once multiple shapes is done
// function* groupAnnotations(annotations) {
//     return annotations.reduce((groups, item) => {
//         const group = (groups[item.layerID] || []);
//         group.push(item);
//         groups[item.layerID] = group;
//         return groups;
//       }, {});
// }
function* isLayerReadOnly(layerId) {
    try {
        const allLayers = yield select(getLayersList);
        const layer = find(allLayers, (item) => item.layerID === layerId);
        return layer?.shareAccess === ShareAccessType.SHARE_READONLY;
    }
    catch (error) {
        console.log(`Error occurs in AnnotationInitModel.js, isLayerReadOnly ${error}`);
    }
}

function* processAnnotationData({ annotations }) {
    try {
        isProcessed = true;
        const allAnnotId = [];
        const allReadOnlyAnnotId = [];

        yield put({ type: ActionTypes.CLEAR_ANNOTATIONS });
        const datagraphStates = yield select(getDatagraphStates);
        if(datagraphStates.SymbolType === SmartViewType.FUND){
            return;
        }
        yield put(updateRiPanelInfoFromUserSettings());

        const annotationDimension = yield select(getAnnotationDimension);
        if (annotationDimension.width === 0 && annotationDimension.height === 0){ 
            return;
        }
        const msid = AnnotationUtil.getMsid();
        const periodicity = AnnotationUtil.getPeriodicityType();
        const priceScale = AnnotationUtil.getScaleText();

        if (AnnotationUtil.getTimeLine().length === 0){ 
            return;
        }
        TimeTrackingWindow.isAnnotationsLoading = AnnotationUtil.isAnnotationLoadInitiated && datagraphStates.priceDataAvailable;
        AnnotationUtil.isAnnotationLoadInitiated = false;
        try {
            !PrintMode.printing ? TimeTrackingWindow.isAnnotationsLoading && TimeTrackingWindow.beginAnnotationRenderTimeTracker() : TimeTrackingWindow.beginAnnotationRenderTimeTracker(annotations.length);
            for (const a of annotations) {
                yield safe(call(populateAnnotations, a, annotationDimension, msid, periodicity, priceScale), "AnnotationInitModel.js", "populateAnnotations");
                
                const isReadOnlyLayer = yield call(isLayerReadOnly, a.layerID);
                if (!isReadOnlyLayer) {
                    allAnnotId.push({ 'annotId': a.annotationID, 'layerId': a.layerID, deleted: false, createdDate: a.createdDate, updatedDate: a.updatedDate });
                }
                else {
                    allReadOnlyAnnotId.push({ 'annotId': a.annotationID, 'layerId': a.layerID, deleted: false, createdDate: a.createdDate, updatedDate: a.updatedDate });
                }
            }
            !PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        }
        catch (error) {
            console.error(error);
            !PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading && TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.Annotation);
            !PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading && TimeTrackingWindow.endAnnotationLoadEndTimeTracker();
        }

        yield put({
            type: ActionTypes.STORE_ANNOTATION_IDS,
            allAnnotId: allAnnotId,
            allReadOnlyAnnotId: allReadOnlyAnnotId
        });
    }
    catch (error) {
        console.log(`Error occurs in AnnotationInitModel.js, processAnnotationData ${error}`, error);
    }
}

function* populateAnnotations(annotation, annotationDimension, msid, periodicity, priceScale) {
    const isAllowedToShowAnnotations = yield call(isEligibleToShowAnnotationsOnChart);
    
    if (!isAllowedToShowAnnotations) {
        
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker()
        return;
    }

    if (!(annotation || annotation.msid !== msid || annotation.periodicity !== periodicity)) {
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker()
        return;
    }

    if (Long.isLong(annotation.createdDate) && Long.isLong(annotation.updatedDate)) {
        annotation.createdDate = new Date(annotation.createdDate.toNumber());
        annotation.updatedDate = new Date(annotation.updatedDate.toNumber());
    }

    const isChartInLogScale = priceScale === "LOG (Fixed)" || priceScale === "LOG (Auto)" || priceScale === "LOG (WON)" ? true : false;

    const scale = annotation.scale;
    const isAnnotationInLogScale = scale === 1 || scale === 2 || scale === 3 ? true : false;
    
    if (annotation.annotationType === RayAnnotationType.LINE_AnnotationType || annotation.annotationType === RayAnnotationType.PARALLEL_LINES_AnnotationType) {
        if ((isChartInLogScale && !isAnnotationInLogScale) || (!isChartInLogScale && isAnnotationInLogScale)) {
            (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker()
            return;
        }
    }

    const startDatePoint = {
        dateTime: DateHelper.getUtcFromLong(annotation.pointData[0].xDate)._d,
        price: annotation.pointData[0].yValue
    };

    const endDatePoint = {
        dateTime: DateHelper.getUtcFromLong(annotation.pointData[1].xDate)._d,
        price: annotation.pointData[1].yValue
    };
    
    const pricechartState = yield select(priceChartReducerselect);
    const beginDate = ConsoleStore.getState().beginDate;
    const endDate = pricechartState.pricePanelData && pricechartState.pricePanelData.HsfData.HSFResults  ? pricechartState.pricePanelData.HsfData.HSFResults[0].Date : new Date();
    if (moment(startDatePoint.dateTime).isBefore(moment(beginDate)) || moment(endDatePoint.dateTime).isBefore(moment(beginDate)) ||
        moment(startDatePoint.dateTime).isAfter(moment(endDate)) || moment(endDatePoint.dateTime).isAfter(moment(endDate))) {
        (PrintMode.printing || (!PrintMode.printing && TimeTrackingWindow.isAnnotationsLoading)) && TimeTrackingWindow.endAnnotationLoadEndTimeTracker()
        return;
    }

    annotation.startPoint = yield safe(call(lookUpXAndYAxis, startDatePoint, annotationDimension), "AnnotationInitModel.js", "lookUpXAndYAxis");
    annotation.endPoint = yield safe(call(lookUpXAndYAxis, endDatePoint, annotationDimension), "AnnotationInitModel.js", "lookUpXAndYAxis");
    annotation.Selected = false;

    switch (annotation.annotationType) {
        case RayAnnotationType.LINE_AnnotationType:
            {
                yield put({
                    type: ActionTypes.PROCESS_LINE_DATA,
                    data: annotation
                });

                // annotationsLineModel.StartPoint = startPoint;
                // annotationsLineModel.EndPoint = endPoint;

                // annotationsLineModel.ExtendedWidth = GetDrawingCanvasWidth();
                // annotationsLineModel.ExtendedHeight = GetDrawingCanvasHeight();

                //const invertableColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsLineModel.OriginalObjectBrushHex, _chartPanelModel.IsPrintMode);
                //annotationsLineModel.ObjectBrushHex = invertableColor.ToString();

                //annotationsBases.Add(annotationsLineModel);
            }
            break;
        case RayAnnotationType.TEXT_AnnotationType:
            {
                yield put({
                    type: ActionTypes.PROCESS_TEXT_DATA,
                    data: annotation
                });
                // const Left = yield call(getAdjustedXPosition, startPoint.x);
                // const Top = startPoint.y;
                // const Right = yield call(getAdjustedXPosition, endPoint.x);
                // const Bottom = endPoint.y;

                // var invertableBackgroundColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsTextModel.OriginalBackgroundBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsTextModel.BackgroundBrushHex = invertableBackgroundColor.ToString();

                // var invertableBorderColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsTextModel.OriginalBorderBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsTextModel.BorderBrushHex = invertableBorderColor.ToString();

                // var invertableObjectColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsTextModel.OriginalObjectBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsTextModel.ObjectBrushHex = invertableObjectColor.ToString();
            }
            break;
        case RayAnnotationType.RECT_AnnotationType:
            {

                yield put({
                    type: ActionTypes.PROCESS_RECTANGLE_DATA,
                    data: annotation
                });

            }
            break;
        case RayAnnotationType.ELLIPSE_AnnotationType:
            {
                yield put({
                    type: ActionTypes.PROCESS_ELLIPSE_DATA,
                    data: annotation
                });
            }
            break;
        case RayAnnotationType.TRIANGLE_AnnotationType:
            {
                yield put({
                    type: ActionTypes.PROCESS_TRIANGLE_DATA,
                    data: annotation
                });

                // var invertableColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsShapesModel.OriginalObjectBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsShapesModel.ObjectBrushHex = invertableColor.ToString();

                // var invertableBackgroundColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsShapesModel.OriginalBackgroundBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsShapesModel.BackgroundBrushHex = invertableBackgroundColor.ToString();

                // var invertableBorderColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsShapesModel.OriginalBorderBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsShapesModel.BorderBrushHex = invertableBorderColor.ToString();
            }
            break;
        case RayAnnotationType.PARALLEL_LINES_AnnotationType:
            {
                const spreadDatePoint = {
                    dateTime: DateHelper.returnFromUTCtoPacificTime(annotation.pointData[2].xDate),
                    price: annotation.pointData[2].yValue
                };
                annotation.spreadPoint = yield safe(call(lookUpXAndYAxis, spreadDatePoint, annotationDimension), "AnnotationInitModel.js", "lookUpXAndYAxis");

                yield put({
                    type: ActionTypes.PROCESS_CHANNEL_LINE_DATA,
                    data: annotation
                });

                // annotationsChannelLineModel.StartPoint = startPoint;
                // annotationsChannelLineModel.EndPoint = endPoint;
                // annotationsChannelLineModel.SpreadPoint = spreadPoint;

                // annotationsChannelLineModel.ExtendedWidth = GetDrawingCanvasWidth();
                // annotationsChannelLineModel.ExtendedHeight = GetDrawingCanvasHeight();

                // var invertableColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsChannelLineModel.OriginalObjectBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsChannelLineModel.ObjectBrushHex = invertableColor.ToString();
            }
            break;
        case RayAnnotationType.FIB_AnnotationType:
            {
                yield put({
                    type: ActionTypes.PROCESS_FIBONACCI_DATA,
                    data: annotation
                });

                // annotationsFibonacciModel.ExtendedWidth = GetDrawingCanvasWidth();
                // annotationsFibonacciModel.ExtendedHeight = GetDrawingCanvasHeight();

                // var invertableColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsFibonacciModel.OriginalObjectBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsFibonacciModel.ObjectBrushHex = invertableColor.ToString();

                // var invertableBackgroundColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsFibonacciModel.OriginalRetracementBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsFibonacciModel.RetracementBrushHex = invertableBackgroundColor.ToString();

                // var invertableBorderColor = ColorPaletteViewModel.GetSolidColorBrush(annotationsFibonacciModel.OriginalExtensionBrushHex, _chartPanelModel.IsPrintMode);
                // annotationsFibonacciModel.ExtensionBrushHex = invertableBorderColor.ToString();
            }
            break;
        case RayAnnotationType.MEASUREMENT_AnnotationType:
            {
                yield put({
                    type: ActionTypes.PROCESS_MEASUREMENT_DATA,
                    data: annotation
                });

                // let snapStartPosition = GetSnapPosition(startPoint, int.MaxValue);
                // let snapEndPosition = GetSnapPosition(endPoint, int.MaxValue);

                // let startPosition = snapStartPosition.HasValue ? snapStartPosition.Value : new Point(startPoint.X, startPoint.Y - 25);
                // graphicsMeasurementModel.ExtensionStartPoint = new Point(startPoint.X, startPosition.Y);

                // let endPosition = snapEndPosition.HasValue ? snapEndPosition.Value : new Point(endPoint.X, endPoint.Y - 25);
                // graphicsMeasurementModel.ExtensionEndPoint = new Point(endPoint.X, endPosition.Y);

                // graphicsMeasurementModel.StartPoint = startPoint;
                // graphicsMeasurementModel.EndPoint = endPoint;
                // var invertableColor = ColorPaletteViewModel.GetSolidColorBrush(graphicsMeasurementModel.OriginalObjectBrushHex);
                // graphicsMeasurementModel.ObjectBrushHex = invertableColor.ToString();
                // if (MeasurementModelCheckDictionary.ContainsKey(graphicsMeasurementModel.Id)) {
                //     graphicsMeasurementModel.IsChecked =
                //         MeasurementModelCheckDictionary[graphicsMeasurementModel.Id];
                // }
                // else {
                //     var defaultChecked = false;
                //     MeasurementModelCheckDictionary.Add(graphicsMeasurementModel.Id, defaultChecked);
                //     graphicsMeasurementModel.IsChecked = defaultChecked;
                // }
            }
            break;
        default:
            break;
    }
}

function* isEligibleToShowAnnotationsOnChart() {
    try {
        const activeTabType = AnnotationUtil.getActiveTabType();
        if (activeTabType === TabType.Datagraph) {
            return true;
        }
        //if (_chartPanelModel.ConsoleModel == null && !_chartPanelModel.States.IsModelStock) return true;
        return false;
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ANNOTATION_ERROR,
            errorMsg: error
        });
        console.log(`Error occurs in AnnotationInitModel.js, isEligibleToShowAnnotationsOnChart ${error}`);
    }
}

export function* lookUpXAndYAxis(DateTimeAndPrice, dimension) {
    if (!DateTimeAndPrice) {
        return;
    }
    const startPrice = DateTimeAndPrice.price;
    const startDateTime = DateTimeAndPrice.dateTime;

    const priceChartScale = AnnotationUtil.getScale();
    const y = priceChartScale.ComputeY(startPrice);
    const index = yield safe(call(getPositionBasedFromDate, startDateTime, dimension), "AnnotationInitModel.js", "getPositionBasedFromDate");

    return { x: index, y: y };
}

function getPositionBasedFromDate(dateTime, dimension) {
    let nodeIndex = 0;
    const TimeLineList = AnnotationUtil.getTimeLine();
    const priceNodes = AnnotationUtil.getHighLowPoints();
    const nodeWidth = AnnotationUtil.getNodeWidth();
    const width = AnnotationUtil.getInitialNode();
    const startChartIndex = 0;

    if (TimeLineList[nodeIndex].Date < dateTime) {
        dateTime = moment(dateTime);
        const timeLineDate = moment(TimeLineList[nodeIndex].Date);

        switch (AnnotationUtil.getPeriodicity()) {
            case GraphType.Daily: {
                let businessDays = 1 + (timeLineDate.diff(dateTime, 'days') * 5 - (dateTime.day() - timeLineDate.day()) * 2) / 7;
                if (timeLineDate.day() === 6) {
                    businessDays--;
                }
                if (dateTime.day() === 0) {
                    businessDays--;
                }
                nodeIndex = businessDays;
                break;
            }

            case GraphType.Weekly: {
                const dateDiff = timeLineDate.diff(dateTime, 'days');
                nodeIndex = dateDiff / 7;
                break;
            }

            case GraphType.Monthly: {
                const monthDiff = (timeLineDate.diff(dateTime, 'months')) + 12 * (timeLineDate.diff(dateTime, 'years'));
                nodeIndex = monthDiff;
                break;
            }

            case GraphType.Quarterly:
                {
                    const monthDiff = (timeLineDate.diff(dateTime, 'months')) + 12 * (timeLineDate.diff(dateTime, 'years'));
                    nodeIndex = monthDiff / 3;
                    break;
                }
            case GraphType.Annual: {
                const yearDiff = timeLineDate.diff(dateTime, 'years');
                nodeIndex = yearDiff;
                break;
            }

            default:
                break;
        }
    }
    else {
        dateTime = moment(dateTime);
        // if (priceNodes.allPoints[0].Date >= dateTime && priceNodes.allPoints[priceNodes.allPoints.length - 1].Date <= dateTime) {
        //     const node = find(priceNodes.allPoints, (item) => moment(item.Date).isSame(dateTime));
        //     nodeIndex = node ? node.xAxis : 0;
        //     return nodeIndex;
        // }
        // else {
        for (let i = 0; i < TimeLineList.length; i++) {
            const chartNode = TimeLineList[i];

            if (chartNode && !moment(chartNode.Date).isSame(dateTime)) {
                continue;
            }
            nodeIndex = i;
            break;
        }

        /* const rightPadding = PriceChartStore.getPadding();
        getPadding() {
            return StockViewStore.getPadding();
        }*/

        // }
    }

    nodeIndex = nodeIndex - startChartIndex;
    nodeIndex = nodeIndex * nodeWidth;
    const posFromRight = width - nodeIndex;
    return Math.round(posFromRight);
}

/**************************WATCHERS***************************/

export function* watchHandleInitAnnotation() {
    yield takeLatest(ActionTypes.INIT_ANNOTATION_MODEL, initAnnotations);
}

export function* watchProcessAnnotationData() {
    yield takeLatest(ActionTypes.PROCESS_ANNOTATION_DATA, processAnnotationData);
}

export function* watchHandleAnnotationsOnChartResize() {
    yield takeLatestOptimised(ActionTypes.REDRAW_ANNOTATIONS_ON_CHART_RESIZE, reDrawAnnotationsOnChartResize);
}

export function* watchHandleContexMenu() {
    yield takeLatest(ActionTypes.HANDLE_ANNOTATION_CONTEXT_MENU_TOGGLE, annotationContextMenu);
}

const takeLatestOptimised = (pattern, saga, ...args) => fork(function* () {
    let lastTask;
    while (true) {
        const action = yield take(pattern)
        if (lastTask) {
            yield cancel(lastTask);
            isProcessed = true;
        }
        lastTask = yield fork(saga, ...args.concat(action))
    }
})