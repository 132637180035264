import GraphType from "GraphType";
import DateHelper from "DateHelper";

class RSLine {
    CalculateRSLine(indexData, hsfData, lastNode, lastNd, majorPeriodicity) {
        const tot2 = hsfData.length;
        const tot = indexData.length;
        const totRs = tot2 < tot ? hsfData.filter(c => c.IsVisible).length : lastNd;
        const tot1 = Math.min(indexData.length, totRs);
        const RSLineData = [];
        let minSNP = Number.POSITIVE_INFINITY;
        const awayFromPrice =  majorPeriodicity === GraphType.Daily ? 0.35 : 0.25;

        indexData.map((item) => item.Date = DateHelper.getPSTFromLong(item.Date));

        if (tot < 1) {
            return RSLineData;
        }

        let rsIndex;
        for (rsIndex = lastNode; rsIndex < tot1; rsIndex++) {
            if (indexData[rsIndex].Close > 0 && indexData[rsIndex].Close < minSNP) {
                minSNP = indexData[rsIndex].Close;
            }
        }

        rsIndex = lastNode;
        let cIndex = 0;

        for (; cIndex < lastNd; cIndex++) {
            if (hsfData[cIndex].IsVisible) {
                break;
            }
        }
        let info = {
            Date: hsfData[cIndex].Date,
            YValue: (hsfData[cIndex].Close / indexData[rsIndex].Close),
            Close: (hsfData[cIndex].Close / indexData[rsIndex].Close) * minSNP * awayFromPrice
        };
        let addNode = false;
        for (; hsfData[cIndex] !== undefined && rsIndex < tot && cIndex < tot2; rsIndex++ , cIndex++) {
            // Synch the dates to skip missing nodes while calculating RS Line values
            while (hsfData[cIndex].Date.getTime() < indexData[rsIndex].Date.getTime() && rsIndex < tot) {
                rsIndex++; RSLineData.push(info);
            }
            while (hsfData[cIndex] !== undefined && hsfData[cIndex].Date.getTime() > indexData[rsIndex].Date.getTime() && cIndex < tot2) {
                cIndex++; RSLineData.push(info);
            }
            while (hsfData[cIndex] !== undefined && hsfData[cIndex].Date.getTime() > indexData[rsIndex].Date.getTime() && cIndex < tot2) {
                cIndex++;
                if (info !== {}) {
                    RSLineData.push(info);
                    addNode = false;
                } else {
                    addNode = true;
                }
            }
            if (hsfData[cIndex] === undefined) {
                break;
            }
            info = {
                Date: hsfData[cIndex].Date,
                YValue: (hsfData[cIndex].Close / indexData[rsIndex].Close),
                Close: (hsfData[cIndex].Close / indexData[rsIndex].Close) * minSNP * awayFromPrice
            };

            RSLineData.push(info);
            if (addNode) {
                RSLineData.push(info);
            }
        }

        return RSLineData;
    }

    MapRSRelLine(indexData, inData, lastNode, lastNd, majorPeriodicity) {
        const tot1 = Math.min(indexData.length, lastNd);
        const tot = indexData.length;
        const RSLineData = [];
        let minSNP = Number.POSITIVE_INFINITY;
        const awayFromPrice = majorPeriodicity === GraphType.Daily ? 0.35 : 0.25;

        if (tot < 1) {
            return RSLineData;
        }

        let rsIndex;
        for (rsIndex = lastNode; rsIndex < tot1; rsIndex++) {
            if (indexData[rsIndex].Close > 0 && indexData[rsIndex].Close < minSNP) {
                minSNP = indexData[rsIndex].Close;
            }
        }
        if(inData)
            inData.forEach((t) => RSLineData.push({ Date: t.Date, Close: t.yValue * minSNP * awayFromPrice }));

        return RSLineData;
    }
}

export default RSLine;   