import ExtremeDataValue from "ExtremeDataValue";
import { OhOlTranslateHelper } from "../../../../../Utils/TranslateHelper";
import { PriceChartConstants } from "../../../../../Constants/PriceChartConstants";
const { ActionTypes } = PriceChartConstants;
import React from 'react';
import { getDatagraphStates, priceChartReducerselect } from "../../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { useSelector, useDispatch } from 'react-redux';
// import ThemeHelper from "ThemeHelper";

function OhOl() {

    const dispatch = useDispatch();

    const { OHtype, oLow, oHigh } = useSelector(priceChartReducerselect);
    const { isIntraday, videoMode } = useSelector(getDatagraphStates);
    let oValue = oHigh !== undefined && oHigh !== null ? oHigh : null;
    let OHtypeTxt;
    if (OHtype === 'OL') {
        OHtypeTxt = OhOlTranslateHelper.OL;
        oValue = oLow ? oLow : 0;
    } else {
        OHtypeTxt = OhOlTranslateHelper.OH;
    }
    if (oValue !== null) {
        const formatedVal = ExtremeDataValue.getAbbrivatedString(Math.round(oValue), 0);
        if (oValue >= 0){
            oValue = `+${  formatedVal}`;
        }
        else{
            oValue = formatedVal;
        }
    }

    const showOH = oValue !== null && !isIntraday && !isNaN(oHigh) ? true : false;

    return (
        <div className="percent-and-digits">
            {videoMode === false && showOH &&
                <div className="oh-percent" id='ohigh' key='3'>
                    <span className="oh-percent-head" data-disable-track-price="true" onClick={() => dispatch({ type: ActionTypes.TOGGEL_OH_OL })}>{OHtypeTxt}</span>
                    <span className="oh-percent-data">{oValue}%</span>
                </div>
            }
        </div>)
}
export default OhOl