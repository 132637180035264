import AlertActions from 'AlertActions';
import { AlertConstants } from "AlertConstants";
import AlertStore from "AlertStore"
import { dispatch } from "../../Redux/dispatch";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import {map} from "underscore";
import MenuCommonDialogActions from '../../Actions/MenuCommonDialogActions';
import MenuDialogType from "../../Constants/MenuDialogType.js";
import MessageCenterWindowType from "../../Constants/MessageCenterWindowType";
import NavType from "NavType";
import PeriodicityHelper from "PeriodicityHelper";
import ReactHtmlParser from "react-html-parser";
import { RouteToDataGraph } from "../../Actions/ConsoleActions";
import ScrollBar from "ScrollBar";
import SettingsAction from "../../Actions/SettingsActions";
import SettingsStore from 'SettingsStore';
import StringUtil from "StringUtil";
import { Table } from "react-bootstrap";
import textWidth from "text-width";
import UserInfoUtil from "UserInfoUtil";
import { initPeriodicityButton, onPlotSymbol } from "../../Actions/DatagraphActions";
import React, { Component } from "react";

export default class TabAllMessageCenter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notificationData: AlertStore.getMessageCenterNotifications(this.props.MessageCenterWindowType),
            showToolBar: false,
            toolTipX: 0,
            toolTipY: 0,
            toolTipMessage: ''
        }
        this.alertStoreStateChange = this.alertStoreStateChange.bind(this);
        this.onSymbolClick = this.onSymbolClick.bind(this);
        this.resetUnreadMessages = this.resetUnreadMessages.bind(this);
        this.toggleStatus = this.toggleStatus.bind(this);
        this.handleNotificationClick = this.handleNotificationClick.bind(this);
        this.getTextWidth = this.getTextWidth.bind(this);
        this.onMessageMouseMove = this.onMessageMouseMove.bind(this);
        this.onMessageMouseLeave = this.onMessageMouseLeave.bind(this);
        this.getHeaderContent = this.getHeaderContent.bind(this);
    }

    componentDidMount() {
        AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.OPEN_MESSAGE_CENTER);
        AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.GET_ALL_NOTIFICATIONS);
        AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.NW_RESET_UNREAD_MESSAGES);
        AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.NW_TOGGLE_STATUS);
        AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
        AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.TOGGLE_ALERT_STATUS);
        AlertStore.addChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.SET_MESSAGE_AS_READ);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.showReadMessage != nextProps.showReadMessage) {
            this.setState({
                notificationData: AlertStore.getMessageCenterNotifications(this.props.MessageCenterWindowType)
            });
        }
    }

    componentWillUnmount() {
        AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.OPEN_MESSAGE_CENTER);
        AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.GET_ALL_NOTIFICATIONS);
        AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.NW_RESET_UNREAD_MESSAGES);
        AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.NW_TOGGLE_STATUS);
        AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.NEW_NOTIFICATIONS_RECEIVED);
        AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.TOGGLE_ALERT_STATUS);
        AlertStore.removeChangeListener(this.alertStoreStateChange, AlertConstants.ActionTypes.SET_MESSAGE_AS_READ);
    }

    shouldComponentUpdate() {
        return this.props.activeKey == this.props.MessageCenterWindowType;
    }

    alertStoreStateChange() {
        this.setState({
            notificationData: AlertStore.getMessageCenterNotifications(this.props.MessageCenterWindowType)
        })
    }

    onSymbolClick(e, symbol, row) {
        let userNotificationID = row.userNotificationId;
        const periodicity = PeriodicityHelper.convertToGraphType(row.periodicity);
        const userSettings = SettingsStore.getConsoleSettings();
        userSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity = periodicity;
        dispatch(initPeriodicityButton(userSettings))
        this.setState({curSymbol: symbol})
        setTimeout(() => {
            if (userSettings.NavDatagraphSettings.TabDataGraphSettings.Periodicity == periodicity && this.state.curSymbol == symbol) {
                SettingsAction.updateTabSelection("DATAGRAPH");

                if (userSettings.ActiveNav !== NavType.NavSymbol)
                    RouteToDataGraph(symbol.trim(), NavType.NavSymbol);
                else
                dispatch(onPlotSymbol(symbol));

                // TabDataGraphActionClass.onPeriodicityChange(periodicity, SettingsStore.getConsoleSettings());

                AlertActions.setMessageAsRead(userNotificationID);
            }
        }, 500); 
    }

    resetUnreadMessages() {
        AlertActions.resetUnreadNotificationMessages(this.props.MessageCenterWindowType);
    }

    toggleStatus(e, item) {
        AlertActions.toggleNotificationStatus(item);
    }

    handleNotificationClick() {
        MenuCommonDialogActions.openNotificationDialog({ eventKey: MenuDialogType.Notification, showbackButton: true });
    }

    getTextWidth(text) {
        let width =  textWidth(text, {
          family: "calibri",
          size: 16
        });
        return width;
    }

    onMessageMouseMove(e, message, note) {
        if(this.getTextWidth(message + (!StringUtil.isEmpty(note) ? (" Note: " + note) : "")) > e.target.getBoundingClientRect().width )
        {
            this.toolTopBox.style.left = e.clientX -  this.main.getBoundingClientRect().left + 10 + 'px';
            this.toolTopBox.style.top =  e.clientY -  this.main.getBoundingClientRect().top + 10 + 'px';
            this.toolTopBox.style.textAlign = "left";
            this.toolTopBox.style.wordWrap = "break-word";
            this.toolTopBox.style.maxWidth = this.main.getBoundingClientRect().right - e.clientX - 10 + 'px';
            this.toolTopBox.innerHTML = '<div><span>'+message+ (!StringUtil.isEmpty(note) ? `<br />Note: ${note}` : '') + '</span></div>';
            this.toolTopBox.style.display = '';
        }
    }

    onMessageMouseLeave() {
        this.toolTopBox.style.left = 0;
        this.toolTopBox.style.top = 0;
        this.toolTopBox.innerHTML = '';
        this.toolTopBox.style.display = 'none';
    }

    getHeaderContent() {
        let msg = LocalizationStore.getTranslatedData("mc_um", "Unviewed Message");
        if (this.state.notificationData.unreadCount == 1) {
            msg = LocalizationStore.getTranslatedData("mc_um", "Unviewed Message");
            if (this.props.MessageCenterWindowType == MessageCenterWindowType.Alert)
                msg = LocalizationStore.getTranslatedData("mc_alts_ua", "Unviewed Alert");
            else if (this.props.MessageCenterWindowType == MessageCenterWindowType.SharedItems)
                msg = LocalizationStore.getTranslatedData("mc_usi", "Unviewed Shared Item");
        } else {
            msg = LocalizationStore.getTranslatedData("mc_um", "Unviewed Messages");
            if (this.props.MessageCenterWindowType == MessageCenterWindowType.Alert)
                msg = LocalizationStore.getTranslatedData("mc_alts_ua", "Unviewed Alerts");
            else if (this.props.MessageCenterWindowType == MessageCenterWindowType.SharedItems)
                msg = LocalizationStore.getTranslatedData("mc_usi", "Unviewed Shared Items");
        }
        return msg;
    }

    renderData() {

        let self = this;
        const hasLocalSymbol = UserInfoUtil.hasLocalSymbol();
        return (<Table striped condensed hover className="medium-normal scroll-message-tbl ">
            <tbody>
                {
                    map(this.state.notificationData.notifications, (row, key) => {

                        let symbol = hasLocalSymbol ? row.localSymbol : row.symbol;
                        let className = row.hasBeenRead ? 'read' : 'unread';

                        return (
                            <tr key={key} className={className}>
                                <td className="w601"><span  className="highlight-stock-name disable-drag" onClick={(e) => self.onSymbolClick(e, row.symbol, row)}>{symbol} </span> <span  className="highlight-stock-details" onMouseMove={(e) => self.onMessageMouseMove(e, row.message, row.alertTypeId === 1 ? row.note : undefined)} onMouseOut={self.onMessageMouseLeave} onBlur={self.onMessageMouseLeave}> {ReactHtmlParser(row.message + (row.alertTypeId === 1 && !StringUtil.isEmpty(row.note) ? (" Note: " + row.note) : ""))} </span></td>
                                <td className="w155 highlight-stock-details">{row.triggerDate}</td>
                                {self.props.MessageCenterWindowType != MessageCenterWindowType.PanarayMessages ?
                                    (self.props.MessageCenterWindowType != MessageCenterWindowType.SharedItems && !StringUtil.isEmpty(row.status) ?
                                        (
                                            row.status != "Shared" && row.status != "Unshared" ?
                                                <td className="w109 highlight-stock-details disable-drag cursor-pointer" onClick={(e) => self.toggleStatus(e, row)} > {row.status}</td>
                                                :
                                                <td className="w109 highlight-stock-details disable-drag"> {row.status}</td>
                                        )
                                        :
                                        <td className="w109 highlight-stock-details disable-drag"> {row.status}</td>) : null}
                            </tr>
                        );

                    })
                }
            </tbody>
        </Table>);
    }
    render() {

        let allData = this.renderData();

        return (       
            <div ref={(ref) => this.main = ref}  id="tabAllMessageCenter" className="tab-region">
                <div className="tab-state">
                    <Table striped condensed hover>
                        <thead>
                            <tr>
                                <th className="w593 disable-drag">{this.state.notificationData.unreadCount} {this.getHeaderContent()} <span className="disable-drag bold" onClick={this.resetUnreadMessages}> {"( " + LocalizationStore.getTranslatedData("mc_clr","clear") + " )"}</span> <span className="disable-drag bold" onClick={this.props.onShowHideReadMessageClick}> {this.state.notificationData.showReadMessage ? LocalizationStore.getTranslatedData("mc_hr","hide read") : LocalizationStore.getTranslatedData("mc_sr","show read")}</span></th>
                                <th className="w145 disable-drag">{this.props.MessageCenterWindowType == MessageCenterWindowType.Alert ? LocalizationStore.getTranslatedData("mc_alts_tr","Triggered") : LocalizationStore.getTranslatedData("mc_date","Date")}</th>
                                {this.props.MessageCenterWindowType != MessageCenterWindowType.PanarayMessages ? <th className="w107 disable-drag">{LocalizationStore.getTranslatedData("tp_pt_status","Status")}</th> : null}
                            </tr>
                        </thead>
                    </Table>
                    <div className="custom-scroll-light message-center-scroll">
                        <div className="custom-scroll">
                            <div className="custom-scroll-holder" id={"messageCenterScroll" + this.props.MessageCenterWindowType.toString()}>
                                {allData}
                            </div>
                        </div>
                    </div>
                    <ScrollBar itemHeight = {33} scrollId={"messageCenterScroll" + this.props.MessageCenterWindowType.toString()} vScroll={true} />
                </div>
                <div className="blue-txt disable-drag" onClick={this.handleNotificationClick}> {LocalizationStore.getTranslatedData("mc_nopre","Notification Preferences")} </div>
                <div ref={(ref) => this.toolTopBox = ref} id="rowTooltip" className="tooltip-box" style={{ display:'none' }}></div>     
            </div> 
        );
    }
}
