import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class DeleteAllActiveByMsidRequest extends BaseServiceRequest {
    constructor(msId, conditionTypeId) {
        super();
        super.addRequestUri("deleteallactivebymsid.pb");
        super.addRequestParameter("msId", msId);
        super.addRequestParameter("conditionTypeId", conditionTypeId);
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "Response";
    }
}