import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetConstituentsDataRequest extends BaseServiceRequest {
    constructor(symbol,mode) {
        super();
        super.addPathParameter(mode.toString());
        super.addRequestParameter("symbol", symbol);
        super.addRequestUri("esconstituents.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "ConstituentsDataResponse";
    }
}

export default GetConstituentsDataRequest;
