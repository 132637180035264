import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputSlider from './InputSlider';
import StringUtil from 'StringUtil';
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import FormatterUtil from "FormatterUtil";
import { getBenchMarkState } from '../../../../../Reducers/NavCompare/RotationGraphReducers/selectors';
import { getLoadingStatus } from '../../../../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import { setSliderValue, preparePointsToRender, updateTimeLineIndex, updateIsTimeLinePlaying, updateIsSliderReset } from 'Actions/RotationGraphActions';
import moment from "moment";
import ConsoleStore from "ConsoleStore";
import ErrorBoundary from 'ErrorBoundary';
import { CompareMenuType } from "CompareTabType";
import SettingStore from '../../../../../Stores/ConsoleWindow/Settings/SettingsStore';

class PlayPauseControl extends Component {
    constructor() {
        super();
        this.slideronChange = this.slideronChange.bind(this);
        this.playGraph = this.playGraph.bind(this);
        this.playTimeLine = this.playTimeLine.bind(this);
        this.tick = this.tick.bind(this);
        this.onChangeComplete = this.onChangeComplete.bind(this);
        this.resetSliderByValue = this.resetSliderByValue.bind(this);
    }

    componentDidMount() {
        this.playTimeLine(this.props.isPlaying);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    playTimeLine(playing = null) {
        let isPlaying = playing;
        if (playing === null) {
            isPlaying = this.props.isPlaying
        }

        if (!isPlaying) {
            clearInterval(this.interval);
            this.props.updateTimeLineIndex();
        }
        else {
            if (this.props.selectedSliderValue === this.props.groupsTimelineDateDictionary.length - 1) {
                this.props.updateTimeLineIndex(false, 0);
            }
            this.interval = setInterval(this.tick, 350);
        }
    }

    tick() {
        if(SettingStore.getIsResetStart()) {
            clearInterval(this.interval);
            return;
        }
        if (this.props.isLoading) return;
        if (this.props.isSliderReset) {
            this.resetSliderByValue();
            return;
        }

        let { selectedSliderValue, groupsTimelineDateDictionary } = this.props;
        if (selectedSliderValue < groupsTimelineDateDictionary.length - 1) {
            selectedSliderValue = selectedSliderValue + 1;
        } else {
            this.resetSliderByValue();
        }
        this.props.timeLineSlideronChange(selectedSliderValue);
    }


    resetSliderByValue() {
        this.props.updateIsTimeLinePlaying();
        this.props.updateIsSliderReset();
        clearInterval(this.interval);
    }

    playGraph(e) {
        e.preventDefault();
        e.stopPropagation();
        const isPlaying = !this.props.isPlaying;
        this.props.updateIsTimeLinePlaying(isPlaying);
        this.playTimeLine(isPlaying);
        //Log Usage
        ConsoleStore.trackUsage('Is Playing', moment(this.props.groupsTimelineDateDictionary[this.props.selectedSliderValue]).format('MM/DD/YYYY'));
    }

    onChangeComplete() {
        this.props.updateTimeLineIndex();
    }

    slideronChange(value, e) {
        e.preventDefault();
        const sliderValue = Math.round(value);
        this.props.timeLineSlideronChange(sliderValue);
    }

    getCurrentIntervalDate() {
        const currentGraphValueIndex = this.props.selectedSliderValue;
        const timeLineData = this.props.groupsTimelineDateDictionary
        if (StringUtil.isEmpty(timeLineData)) return;
        const localeFormat = FormatterUtil.getDateFormatAsLocale("MMM DD");
        return moment.utc(timeLineData[currentGraphValueIndex]).format(LocalizationStore.getTranslatedData("CRG_MonthDayFormat", localeFormat)).toUpperCase();
    }

    render() {
        const { isPlaying, groupsTimelineDateDictionary, selectedSliderValue } = this.props;
        return (
            <div className="graph-status">
                <ErrorBoundary><InputSlider
                    min={0}
                    max={(groupsTimelineDateDictionary.length - 1)}
                    step={1}
                    value={selectedSliderValue}
                    orientation={"horizontal"}
                    tooltip={false}
                    handleLabel={this.getCurrentIntervalDate()}
                    onChange={this.slideronChange}
                    onChangeComplete={this.onChangeComplete}
                    isPlay={isPlaying}
                    onClick={this.playGraph}
                /></ErrorBoundary>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    if (selectedMenuTab === CompareMenuType.Symbols) {
        const isLoading = state.comparisonAndRotationMenuPanel.MenuTabReducer.isLoading.RotationGraph.SymbolsLoading;
        const { isPlaying, groupsTimelineDateDictionary, selectedSliderValue, isSliderReset } = state.rotationGraph.BenchMarkReducer.SymbolsData;

        return { isPlaying, groupsTimelineDateDictionary, selectedSliderValue, isSliderReset, isLoading }
    }
    else {
        const isLoading = state.comparisonAndRotationMenuPanel.MenuTabReducer.isLoading.RotationGraph.GroupsLoading;
        const { isPlaying, groupsTimelineDateDictionary, selectedSliderValue, isSliderReset } = state.rotationGraph.BenchMarkReducer.GroupsData;

        return { isPlaying, groupsTimelineDateDictionary, selectedSliderValue, isSliderReset, isLoading }
    }

};

const mapDispatchToProps = (dispatch) => ({
    timeLineSlideronChange: (sliderValue) => { dispatch(preparePointsToRender(sliderValue, false)), dispatch(setSliderValue(sliderValue)) },
    updateTimeLineIndex: (isSave, sliderValue) => dispatch(updateTimeLineIndex(isSave, sliderValue)),
    updateIsTimeLinePlaying: (isTimeLinePlaying) => dispatch(updateIsTimeLinePlaying(isTimeLinePlaying)),
    updateIsSliderReset: () => dispatch(updateIsSliderReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayPauseControl);