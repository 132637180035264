import React from "react";
import { map } from "underscore";
import BrowserUtil from "BrowserUtil";
import SummaryStatStore from "./SummaryStatStore";
import { GridConstants } from "GridConstants";
import SummaryStatRowItem from "./SummaryStatRowItem.jsx";

export default class SummaryStatBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewportWidth: this.props.viewportWidth,
            shouldUpdate: true,
            highlightedRowCol: {
                rowIndex: null,
                columnId: null,
                highlight: false
            }
        }
        this.dragged = null;
        this.summaryStatStoreChanged = this.summaryStatStoreChanged.bind(this);
        this.dragStart = this.dragStart.bind(this);
        this.dragDrop = this.dragDrop.bind(this);
        this.dragEnd = this.dragEnd.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.dragMove = this.dragMove.bind(this);
        this.dragEnter = this.dragEnter.bind(this);
        this.dragLeave = this.dragLeave.bind(this);
    }

    shouldComponentUpdate = (nextProps) => {
        if (nextProps.isResizing) return false;
        return true;
    }

    componentDidMount() {
        this.listExplorerElm = document.getElementById('listExplorer');
        this.horizontalScrollElm = document.getElementById('gridHorizontalScroll');
        // this.toolTrip = document.getElementById('grid-row-tooltip');
        this.windowDiv = document.querySelector("#frozenDiv");
        this.dragDivGhostImage = document.getElementById('grid-drag-ghost-image');
        SummaryStatStore.addChangeListener(this.summaryStatStoreChanged);
    }

    componentWillUnmount() {
        SummaryStatStore.removeChangeListener(this.summaryStatStoreChanged);
    }

    summaryStatStoreChanged () {
        if (this.props.nonFrozen) {
            let state = SummaryStatStore.getState();
            switch (state.currentAction) {
                case GridConstants.ActionTypes.SET_SUMMARYSTATE_ROW_COL_HIGHLIGHTED:
                    clearTimeout(this.t);
                    this.t = setTimeout(() => {
                        this.setState({
                            highlightedRowCol: state.highlightedRowCol
                        });
                        this.t = null;
                    }, 10);
                    break;
                default:
                    break;
            }
        }
    }


    UNSAFE_componentWillReceiveProps(props) {
        if (props.viewportWidth >= .0) {
            this.setState({
                viewportWidth: props.viewportWidth,
                shouldUpdate: true
            })
        }
        else {
            this.setState({
                shouldUpdate: false
            });
        }
    }

    shouldComponentUpdate() {
        return this.state.shouldUpdate;
    }
    // handleScroll = (scroll) => {
    //     this.refs.main.scrollLeft = scroll;
    // }

    
    dragStart (e, rowIndex) {
        let target = e.srcElement || e.target;
        target.style.visibility = "visible";
        let selectedItems = [(rowIndex - 1)];
        // if (!isSelected) {
        //     selectedItems = [];
        //     selectedItems.push(rowIndex);
        //     this.clearSelection();
        //     this.syncSingleSelection(rowIndex, visibleIndex);
        //     this.setSelectedIndexes(selectedItems);
        // }
        if (selectedItems) {
            this.dragged = selectedItems;
            e.dataTransfer.effectAllowed = 'move';

            // Firefox requires calling dataTransfer.setData
            // for the drag to properly work


            // GridStore.setDragInfo(this.dragged);
            // GridStore.setDragDestInfo(null, null, false);

            this.bRectOfWindow = this.windowDiv.getBoundingClientRect();

            this.dragDivGhostImage.style.visibility = 'visible';
            if (BrowserUtil.isIEBrowser()) {
                let cloneNode = target.cloneNode(true);
                target.parentNode.insertBefore(cloneNode, target);
                target.style.visibility = "collapse";
                setTimeout(() => {
                    target.parentNode.removeChild(cloneNode);
                    target.style.visibility = "visible";
                }, 0);
                e.dataTransfer.setData('text', 'selectedItem');
                this.dragDivGhostImage.style.position = 'fixed';
                this.dragDivGhostImage.style.zIndex = '99';
                this.dragDivGhostImage.style.top = (e.clientY + 5) + 'px';
                this.dragDivGhostImage.style.left = (e.clientX + 0) + 'px';
            }
            else {
                this.dragDivGhostImage.style.position = 'absolute';
                e.dataTransfer.setData('text/html', 'selectedItem');
            }
            this.dragDivGhostImage.innerHTML = SummaryStatStore.getDragedGhostName(this.dragged[0]);
            //if(selectedItems.length > 1) { this.dragDivGhostImage.innerHTML = `${selectedItems.length} Items selected` }
            //else {
            // GridStore.getSymbolAndName(selectedItems).then((rst) => {
            //this.dragDivGhostImage.innerHTML = 'hellow';
            //  })
            //}

            if (e.dataTransfer.setDragImage) {
                e.dataTransfer.setDragImage(this.dragDivGhostImage, -50, 0);
            }
        }
    }

    dragDrop (e) {
        let self = this;
        //this.props.setDragGridScroll(true);
        //this.scrolling = false;
        // Prevent firfox redirection
        if (!BrowserUtil.isIEBrowser()) {
            e.stopPropagation();
            e.preventDefault();
        }
        if (self.dragged) {
            let destIndex = [];
            if (self.dragged.length > 0) {
                let rowIndex = self.over.getAttribute('data-id');
                var domRect = e.currentTarget.getBoundingClientRect();

                if ((domRect.y + (e.currentTarget.offsetHeight / 2)) > e.clientY) {
                    destIndex.push(rowIndex - 1);
                }
                else {
                    destIndex.push(rowIndex)
                }
                
                SummaryStatStore.reOrder(self.dragged, destIndex);
            }
            let key = "sStatrow" + self.over.getAttribute('data-id');
            let prevKey = "sStatrow" + (self.over.getAttribute('data-id') - 1);
            self.props.highlightBottomBorder(key);
            self.props.highlightBottomBorder(prevKey);
        }
        if (self.dragDivGhostImage) {
            self.dragDivGhostImage.innerHTML = '';
            self.dragDivGhostImage.style.zIndex = '-1';
            self.dragDivGhostImage.removeAttribute('style');
        }
    }

    dragEnd ()  {
        let self = this;
        self.dragDivGhostImage.innerHTML = '';
        self.dragDivGhostImage.style.zIndex = '-1';
        self.dragDivGhostImage.removeAttribute('style');
        let key = "sStatrow" + self.over.getAttribute('data-id');

        if (self.over) {
            self.props.highlightBottomBorder(key);
        }
    }

    dragOver (e) {
        this.over = (e.currentTarget) ? e.currentTarget : e.srcElement;

        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        var domRect = target.getBoundingClientRect();
        let key = "sStatrow" + (target.getAttribute('data-id') - 1);
        let prevKey = "sStatrow" + target.getAttribute('data-id');

        if ((domRect.y + (target.offsetHeight / 2)) > e.clientY) {
            this.props.highlightBottomBorder(key, true);
            this.props.highlightBottomBorder(prevKey);
        }
        else if ((domRect.y + (target.offsetHeight / 2)) < e.clientY) {
            this.props.highlightBottomBorder(prevKey, true);
            this.props.highlightBottomBorder(key);
        }
        else{
            this.props.highlightBottomBorder(key);
        }

        e.preventDefault();
    }

    dragMove (e) {
        if (BrowserUtil.isIEBrowser()) {
            this.dragDivGhostImage.style.top = (e.clientY + 5) + 'px',
                this.dragDivGhostImage.style.left = (e.clientX + 0) + 'px';
        }
        // if (this.over) {
        //     let key = "sStatrow" + this.over.getAttribute('data-id');
        //     //this.props.highlightBottomBorder(key, true);
        //     //this.props.highlightBottomBorder(key);
        // }
    }

    dragEnter (e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        this.enterElement = target;
        let key = "sStatrow" + target.getAttribute('data-id');
        this.props.highlightBottomBorder(key, true);
    }

    dragLeave (e) {
        let target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        if (this.enterElement && this.enterElement != target) {
            let key = "sStatrow" + target.getAttribute('data-id');
            this.props.highlightBottomBorder(key);
        }
        else {
            let key = "sStatrow" + this.enterElement.getAttribute('data-id');
            this.props.highlightBottomBorder(key);
        }
    }




    render() {
        let gridBodyClasses = 'grid-body selectTextDisble disbleDragImage grid-body-canvas';
        let index = 0;
        return (
            <div style={{ height: "100%" }}>
                <div style={{ height: "100%", width: `${this.state.viewportWidth}px` }} className={gridBodyClasses}>
                    <table>
                        <tbody>
                            {this.props.rowData ? map(this.props.rowData, (item) => {
                                let className = 'grid-row-odd';
                                if (index % 2 == 0) className = 'grid-row-even';
                                index++;
                                return (
                                    <SummaryStatRowItem
                                        key={`row${index}`}
                                        rowIndex={`row${index}`}
                                        ref={`sStatrow${index}`}
                                        width={this.state.viewportWidth}
                                        highlightedRowCol={this.state.highlightedRowCol}
                                        rowHeight={this.props.rowHeight}
                                        data={item.dataRow}
                                        className={className}
                                        textSize={this.props.textSize}
                                        frozenAreaWidth={this.props.frozenAreaWidth}
                                        columns={this.props.columns}
                                        allColumns={this.props.allColumns}
                                        nonFrozen={this.props.nonFrozen}
                                        getMouseOverColumnIndex={this.props.getMouseOverColumnIndex}
                                        listExplorerElm={this.listExplorerElm}
                                        horizontalScrollElm={this.horizontalScrollElm}
                                        tooltip={this.props.tooltip}
                                        isEven={index % 2 == 0}
                                        loadHistData={this.props.loadHistData}
                                        dataId={index}
                                        dragDrop={this.dragDrop}
                                        dragEnd={this.dragEnd}
                                        dragStart={this.dragStart}
                                        dragOver={this.dragOver}
                                        dragEnter={this.dragEnter}
                                        dragLeave={this.dragLeave}
                                        dragMove={this.dragMove}
                                    />

                                )
                            })
                                : null
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
