import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import { ListExplorerConstants } from "ListExplorerConstants";
import ListApi from "ListApi";
import ConsoleActions from "Actions/ConsoleActions";
// import store from "../../../../Redux/store.js";
import { sourceList }  from 'Actions/ListViewActions';
import { dispatch } from "../../../../Redux/dispatch";
import SettingsStore from "SettingsStore";
// import ListManagerSettings from "../../../ConsoleWindow/Settings/Modules/ListManager/ListManagerSettingHelper.js"
import TabONeilSettings from '../../../ConsoleWindow/Settings/Modules/ListManager/TabONeilSettings.js';
import ListStore from "ListStore";
import ListManagerTabType from "ListManagerTabType";
import _, { debounce, clone } from 'underscore';
import StringUtil from "StringUtil";
import ContextMenuConstants, { TempListMenuItem } from "ContextMenuConstants";
import BaseServiceApi from 'BaseServiceApi';
import ONeilViewStore from "ONeilViewStore";
import ListManagerListCategory from "ListManagerListCategory";
import LocalizationStore from 'LocalizationStore';
import ConsoleStore from "ConsoleStore";
import NavType from "NavType";
import BrowserUtil from "BrowserUtil";
import { ExportExcelConstants } from "../../../../Constants/ExportExcelConstants";
import SettingsAction from "SettingsAction";
import SmartViewType from "SmartViewType";;

import AlertConditionType from "../../../../Constants/AlertConditionType";
const history = require("history").createHashHistory();
import AlertsType from "../../../../Constants/AlertsType";
import BasicEsInfo from 'ServiceApi/RequestHelper/BasicEsInfo';
import ListActions from "../../../../Actions/ListActions.js";
import OwnershipViewStore from "../TabOwnership/OwnershipViewStore.js";
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow";
import DatagraphDataType from "../../../../Constants/DatagraphDataType";
import { prepareHeaderMenu } from "../../../../Actions/OwnerShipBlockActions";
import panarayDefault from "../../../../ServiceApi/RequestHelper/PanarayDefault";

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const ListType = BaseServiceApi.rayData["ListType"];
const ListDatabaseType = BaseServiceApi.rayData["ListDatabaseType"];
const ListAlertStatusType = BaseServiceApi.rayData["ListAlertStatusType"];
const TemporaryListType = BaseServiceApi.rayData["TemporaryListType"];

const CHANGE_EVENT = "change";
let LISTPROPERTY_CHANGE = "LISTPROPERTY_CHANGE";

const _state = {
  listExplorer: { ONeil: null, Ownership: null },
  alertMessage: null,
  deleteNodeId: null,
  removeItemsFromUserList: { listId: null, cacheId: null, msids: null, snapshotdates: null, currentNodeId: null },
  deleteNodeData: false,
}

let _currentAction = null;
let _parentNodeTitle = null;
const eligibleListIDsForAlerts = [];
let _parentListType = null;

let _deleteInfo = {}
let _needGetListExplor = false;

class ListExplorerStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    //Bug fixes - Max Limit error
    this.setMaxListeners(0);
    this.duplicateNode = null;
    this.nodeType = null;
    this.selectedNodeIds = [];
    this.nodeIdTodeSelect = null;
    this.responseCount = 0;
    this.duplicateListCount = 0;
    this.restoreNodeId = null;
    this.activeNode = {};
    this.contextNode = {};
    this.sourceType = null;
    this.ItemsToAdd = [];
    this.isEnableCopyPasteAction = true;
    this.currentScrollPosition = null;
    //this.currentScrollPositionExternal = null;
    this.loadingListExplorer = true;
    this.explorerView = null;
    this.oNeilDatasource = null;
    this.ownershipDatasource = null;
    this.OneilOwnershipSource = null;
    this.isRename = false;
    this.explorerCalled = false;
    this.activeSybol = {
      symbol: undefined,
      msId: undefined
    };
    this.isOneilTabLoad = false;
    this.createNewOwnerList = false;
    this.previousListId = null;
    this.currentListId = null;
    this.currentNode = null;
    this.listChangedInit = false;
  }

  addChangeListener(callback,event = CHANGE_EVENT) {
    this.on(event, callback);
  }

  removeChangeListener(callback,event = CHANGE_EVENT) {
    this.removeListener(event, callback);
  }

  getState() {
    return _state;
  }

  getActiveSymbol() {
    return this.activeSybol;
  }

  isExplorerCalled(){
    return this.explorerCalled;
  }

  getListExplorer() {
    if ((_state.listExplorer.ONeil === null || _state.listExplorer.Ownership === null) && !this.explorerCalled)
      return this.getListExplorerData();
    else {
      this.loadingListExplorer = false;
      return _state.listExplorer;
    }
  }

  setDeleteInfo(deleteInfo){
    _deleteInfo = deleteInfo;
  }

  getDeleteInfo(){
    return _deleteInfo;
  }

  setNeedGetListExplor(value){
    _needGetListExplor = value;
  }

  getNeedGetListExplor(){
    return _needGetListExplor;
  }

  getIsCryptoCurrencyList() {
    if(this.activeNode){
      return this.activeNode.parentCategoryType === ListCategoryType.PRESET_ListCategory  && this.activeNode.categoryType === ListCategoryType.PRESET_ListCategory && this.activeNode.name === "CryptoCurrency" ? true : false;
    }else{
      return false;
    }
  }

  getIsFuturesCashSpotsList(){
    if(this.activeNode){
      return this.activeNode.parentCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory  && this.activeNode.categoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory ? true : false;
    }
    else
      return false;
  }

  // Adding custom properties in Listexplorer datasource.
  setAttribute(dataSource,tabType) {
    dataSource.map((entry) => {
      //Adding expandNode property to expand and collapse node
      _.extend(entry, { expandNode: false });

      //Adding ListCategory property to know exact category name
      _.extend(entry, { listCategory: this.convertToListMgrListCategory(entry.categoryType) });

      //Setting tree item level for List/Folder
      _.extend(entry, { listTreeItemLevel: 0 });

      //Adding isVisible property to set visibilty of node
      //Filtering "Greatest Winners", "Morgue" and "DBList" category
      if (entry.categoryType == ListCategoryType.MORGUELIST_ListCategory || entry.categoryType == ListCategoryType.MODELLIST_ListCategory || entry.categoryType == ListCategoryType.DATABASELIST_ListCategory) {
        _.extend(entry, { isVisible: false });
      }
      else if (entry.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && tabType === ListManagerTabType.Ownership && entry.isVisible !== undefined && entry.isVisible === false){
        _.extend(entry, { isVisible: false });
      }
      else
        _.extend(entry, { isVisible: true });

      if (!StringUtil.isEmpty(entry.parentNodeId)) {
        _.extend(entry, { listTreeItemLevel: entry.listTreeItemLevel + 1 });
      }

      if (entry.childNodes !== undefined && entry.childNodes.length > 0) {
        return this.updateChildNode(entry, entry.nodeId.low,tabType);
      }
    });
    return dataSource;
  }

  updateChildNode(dataSource, rootParentNodeId,tabType) {
    const childNodes = dataSource.childNodes;
    if (childNodes !== undefined) {
      childNodes.map((entry) => {
        //Adding expandNode property to expand and collapse node
        _.extend(entry, { expandNode: false });

        //Adding isVisible property to set visibilty of node
        _.extend(entry, { isVisible: true });

        //Adding ListCategory property to know exact category name
        _.extend(entry, { listCategory: this.convertToListMgrListCategory(entry.categoryType) });

        //Setting tree item level for List/Folder
        _.extend(entry, { listTreeItemLevel: 0 });

        _.extend(entry, { parentCategoryType: dataSource.categoryType, parentCategoryName: dataSource.name, parentListType: dataSource.listType, sourceParentCategoryName: null, rootParentNodeId: rootParentNodeId });

        if (!StringUtil.isEmpty(entry.parentNodeId)) {
          _.extend(entry, { listTreeItemLevel: dataSource.listTreeItemLevel + 1 });
        }

        if (!StringUtil.isEmpty(entry.sourceListId) && entry.sourceListId.low != 0) {
          const sourceNode = this.findSourceNode(entry.sourceListId.low,tabType);

          if (sourceNode)
            _.extend(entry, { sourceCategoryType: sourceNode.categoryType });
          else
            _.extend(entry, { isVisible: false });
        }

        //Adding isVisible property to set visibilty of node
        //Filtering "Greatest Winners", "Morgue" and "DBList" category
        if (entry.categoryType == ListCategoryType.FAVORITE_ListCategory) {
          if (entry.sourceCategoryType == ListCategoryType.MORGUELIST_ListCategory || entry.sourceCategoryType == ListCategoryType.MODELLIST_ListCategory) {
            _.extend(entry, { isVisible: false });
          }
        }

        if (entry.childNodes !== undefined && entry.childNodes.length > 0) {
          return this.updateChildNode(entry, rootParentNodeId,tabType);
        }
      })
    }
  }

  findSourceNode(sourceListId,tabType) {
    const self = this;
    let sourceNode = null;
    const OneilOwnershipSource = tabType === ListManagerTabType.Oneil ? self.oNeilDatasource : self.ownershipDatasource;
    if (StringUtil.isEmpty(OneilOwnershipSource)) return;
    OneilOwnershipSource.some((item) => {
      sourceNode = self.findNodeById(sourceListId, item)
      if (!StringUtil.isEmpty(sourceNode)) {
        if (sourceNode !== false) {
          return sourceNode;
        }
      }
    });
    return sourceNode;
  }

  // Expand node by setting expandNode property.
  ExpandItemLevel(categoryType, nodeId, isOpen = false) {
    const self = this;

    //Fix for PANWEB-661
    if (categoryType == ListCategoryType.POSITIONHISTORYLIST_ListCategory)
      categoryType = ListCategoryType.PORTFOLIOLIST_ListCategory;

    if (categoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory)
      categoryType = ListCategoryType.PRESET_ListCategory;

    let dataSource = self.nodesToSearch(categoryType);

    if (StringUtil.isEmpty(nodeId)) {
      if (dataSource.length > 0)
        nodeId = dataSource[0].nodeId.low;
    }

    if (!StringUtil.isEmpty(dataSource)) {
      for (let index = 0; index < dataSource.length; index += 1) {
        const searchValue = this.findNodeById(nodeId, dataSource[index]);
        if (searchValue !== false) {
          if (isOpen) {
            _.extend(searchValue, { expandNode: true, isVisible: true });
          }
          else
            _.extend(searchValue, { expandNode: !searchValue.expandNode, isVisible: true });
        }
      }
      if (isOpen) {
        _.extend(dataSource[0], { expandNode: true, isVisible: true });
      }
    }
  }

  // Sorting Ownership Data
  sortOwnerShipData(dataSource, propertyName) {
    let tempIndex = undefined, tempLists = [];
    const sortedData = _.sortBy(this.sortOwnerShipDataRecursively(dataSource, 'name'), 'orderNum').reverse();
    _.each(dataSource, (item) => {
      if (item && item.orderNum == 1) {
        tempLists.push(item);
      }
    });
    const sortedDataCopy = _.clone(sortedData)
    _.each(sortedData, (item, index) => {
      if (item && item.orderNum == 1) {
        if (tempIndex == undefined) tempIndex = index;
        sortedDataCopy.splice(tempIndex, 1);
      }
    });
    if (tempLists.length > 0) { sortedDataCopy.splice(tempIndex, 0, ...tempLists); }
    return sortedDataCopy;
  }

  sortOwnerShipDataRecursively(dataSource, propertyName) {
    const self = this;
    let hasDuplicates = false;
    dataSource.forEach((item) => {
      if (item.categoryType === ListCategoryType.PRESET_ListCategory) {
        const seen = {};
        hasDuplicates = item.childNodes.some((currentObject) =>
          seen.hasOwnProperty(currentObject.orderNum) || (seen[currentObject.orderNum] = false));
        propertyName = 'orderNum';
      }
      else
        propertyName = 'name';

      const keys = _.keys(item);
      keys.forEach((key) => {
        if (_.isArray(item[key])) {
          if (hasDuplicates) {
            item[key] = self.sortOwnerShipDataRecursively(item[key], propertyName);
          }
          else {
            item[key] = self.sortOwnerShipDataRecursively(item[key].reverse(), propertyName);
          }
        }
      });
    });
    let groups = null;
    if (propertyName == 'orderNum')
      groups = _.groupBy(_.sortBy(dataSource, (x) => x.orderNum).reverse(), 'listType');
    else
      groups = _.groupBy(_.sortBy(dataSource, (x) => x.name.toLowerCase()).reverse(), 'listType');

    return _.flatten(_.map(groups, _.values)).reverse();
  }

  // Sorting Oneil Data
  sortOneilData(dataSource) {
    let tempIndex = undefined, tempLists = [];
    const sortedData = _.sortBy(this.sortOneilDataRecursively(dataSource, 'name'), 'orderNum').reverse();
    ///PANWEB-2576 start
    _.each(dataSource, (item) => {
      if (item && item.orderNum == 1) {
        tempLists.push(item);
      }
    });
    const sortedDataCopy = _.clone(sortedData)
    _.each(sortedData, (item, index) => {
      if (item && item.orderNum == 1) {
        if (tempIndex == undefined) tempIndex = index;
        sortedDataCopy.splice(tempIndex, 1);
      }
    });
    if (tempLists.length > 0) { sortedDataCopy.splice(tempIndex, 0, ...tempLists); }
    ///PANWEB-2576 end here
    return sortedDataCopy;

    // return _.sortBy(this.sortOneilDataRecursively(dataSource, 'name'), 'orderNum').reverse();
  }

  sortOneilDataRecursively(dataSource, propertyName) {
    const self = this;
    let hasDuplicates = false;
    dataSource.forEach((item) => {
      if (item.categoryType === ListCategoryType.PRESET_ListCategory) {
        const seen = {};
        // Fix for PANWEB-1155
        hasDuplicates = item.childNodes.some((currentObject) =>
          seen.hasOwnProperty(currentObject.orderNum) || (seen[currentObject.orderNum] = false));
        propertyName = 'orderNum';
      }
      else
        propertyName = 'name';

      const keys = _.keys(item);
      keys.forEach((key) => {
        if (_.isArray(item[key])) {
          if (hasDuplicates) {
            item[key] = self.sortOneilDataRecursively(item[key], propertyName);
          }
          else {
            item[key] = self.sortOneilDataRecursively(item[key].reverse(), propertyName);
          }
        }
      });
    });

    let groups = null;
    if (propertyName == 'orderNum')
      groups = _.groupBy(_.sortBy(dataSource, (x) => x.orderNum).reverse(), 'listType');
    else
      groups = _.groupBy(_.sortBy(dataSource, (x) => x.name.toLowerCase()).reverse(), 'listType');

    return _.flatten(_.map(groups, _.values)).reverse();
  }

  getOneilList(consoleId, isUserOnly, isSharedLists) {
    const self = this;
    TimeTrackingWindow.beginListExplorerApiTimeTracker();
    return new Promise((resolve) => {
      ListApi.getListExplorer(1, consoleId, isUserOnly, isSharedLists).then((ONeil) => {
        TimeTrackingWindow.endListExplorerApiTimeTracker();
        TimeTrackingWindow.beginListExplorerRenderTimeTracker();
        try {
          const tempListNodes = _.filter(self.oNeilDatasource, (item) => item.categoryType == ListCategoryType.TEMPORARYLIST_ListCategory || item.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory);
          this.explorerCalled = false;
          if (!StringUtil.isEmpty(ONeil.data)) {
            if (ONeil.data !== undefined && ONeil.data !== null) {
              self.oNeilDatasource = null;
              self.oNeilDatasource = ONeil.data.explorerNodes;
              if (tempListNodes.length > 0)
                self.oNeilDatasource = _.union(self.oNeilDatasource, tempListNodes);
            }
          }

          _state.listExplorer.ONeil = self.sortOneilData(self.setAttribute(self.oNeilDatasource, 'ONEIL'));
          if (ONeil.responseHeader.error) {
            this.loadingListExplorer = false;
            TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListExplorer, false, NavType.NavLists);
            resolve(true);
            throw 'ListExplorer error';
          }
          TimeTrackingWindow.endListExplorerRenderTimeTracker();
          resolve(true);
        }
        catch (e) {
          this.loadingListExplorer = false;
          TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListExplorer, false, NavType.NavLists);
          resolve(true);
        }
      }).catch(() => {
        this.loadingListExplorer = false;
        TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListExplorer, true, NavType.NavLists);
        resolve(true);
      })
    });
  }

  getOwnershipList(consoleId, isUserOnly, isSharedLists) {
    const self = this;
    return new Promise((resolve) => {
      ListApi.getListExplorer(2, consoleId, isUserOnly, isSharedLists).then((Ownership) => {
        try {
          const OwnerShipTempListNodes = _.filter(_state.listExplorer.Ownership, (item) => item.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory);
          if (!StringUtil.isEmpty(Ownership)) {
            if (Ownership.data !== undefined && Ownership.data !== null) {
              self.ownershipDatasource = null;
              self.ownershipDatasource = Ownership.data.explorerNodes;
              if (OwnerShipTempListNodes.length > 0)
                self.ownershipDatasource = _.union(self.ownershipDatasource, OwnerShipTempListNodes);
            }
          }

          _state.listExplorer.Ownership = self.sortOwnerShipData(self.setAttribute(self.ownershipDatasource, 'OWNERSHIP'));
          if (Ownership.responseHeader.error) {
            this.loadingListExplorer = false;
            resolve(true);
            throw 'ListExplorer error';
          }

          ownershipBlockStore.prepareHeaderMenuAfterListexplorer();

          resolve(true);
        }
        catch (e) {
          this.loadingListExplorer = false;
          resolve(true);
        }
      }).catch(() => {
        this.loadingListExplorer = false;
        resolve(true);
      })
    });
  }

  getListExplorerData(refreshGrid = false, callAdobe = true) {
    const self = this;
    if (_state.listExplorer.ONeil === null || _state.listExplorer.Ownership === null || refreshGrid) {
      const consoleId = 0, isUserOnly = 0, isSharedLists = 1;
      this.explorerCalled = true;
      return new Promise((resolve) => {
          Promise.all([
            this.getOneilList(consoleId, isUserOnly, isSharedLists),
            this.getOwnershipList(consoleId, isUserOnly, isSharedLists)]).then(() => {
            if (refreshGrid)
              ListStore.setRefreshList();
            
            const tabType = this.getSelectedTabType();
            if(tabType === ListManagerTabType.Oneil && (_state.deleteNodeData || !this.findActiveListAvailibility(this.activeNode.nodeId))){
              _state.deleteNodeData = true;
              this.deleteUnShareList()
              _state.deleteNodeData = false;
            }
            /* Adobe */
            if (callAdobe && !refreshGrid) {
              ONeilViewStore.pushDataToAdobeWhilePageLoad();
            }
            dispatch(prepareHeaderMenu());
            _currentAction = ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER;
            self.emit(CHANGE_EVENT);
            resolve(true);
          }).catch((e) => {
            console.log('error in getListExplorerData', e);
            resolve(true);
          })
      });
    }
    else {
      _currentAction = ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER;
      if (callAdobe && !refreshGrid)
        ONeilViewStore.pushDataToAdobeWhilePageLoad();
      self.emit(CHANGE_EVENT);
    }
  }

  // Get list explorer datasource.

  // getListExplorerData(refreshGrid = false, callAdobe = true) {
  //   const self = this;
  //   if (_state.listExplorer.ONeil === null || _state.listExplorer.Ownership === null || refreshGrid) {
  //     const consoleId = 0, isUserOnly = 0, isSharedLists = 1;
  //     this.explorerCalled = true;
  //     TimeTrackingWindow.beginListExplorerApiTimeTracker();
  //     return new Promise((resolve) => {
  //       Promise.all([
  //         ListApi.getListExplorer(1, consoleId, isUserOnly, isSharedLists),
  //         /*Commenting ownership api call since its out of scope for 1.0 release*/
  //         ListApi.getListExplorer(2, consoleId, isUserOnly, isSharedLists)
  //       ]).then(([ONeil, Ownership]) => {
  //         TimeTrackingWindow.endListExplorerApiTimeTracker();
  //         TimeTrackingWindow.beginListExplorerRenderTimeTracker();
  //         try {
  //           const tempListNodes = _.filter(self.oNeilDatasource, (item) => item.categoryType == ListCategoryType.TEMPORARYLIST_ListCategory || item.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory);
  //           this.explorerCalled = false;
  //           if (!StringUtil.isEmpty(ONeil.data)) {
  //             if (ONeil.data !== undefined && ONeil.data !== null) {
  //               self.oNeilDatasource = null;
  //               self.oNeilDatasource = ONeil.data.explorerNodes;
  //               if (tempListNodes.length > 0)
  //                 self.oNeilDatasource = _.union(self.oNeilDatasource, tempListNodes);
  //             }
  //           }
  //           const OwnerShipTempListNodes = _.filter(_state.listExplorer.Ownership, (item) => item.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory);
  //           if (!StringUtil.isEmpty(Ownership)) {
  //             if (Ownership.data !== undefined && Ownership.data !== null) {
  //               self.ownershipDatasource = null;
  //               self.ownershipDatasource = Ownership.data.explorerNodes;
  //               if (OwnerShipTempListNodes.length > 0)
  //                 self.ownershipDatasource = _.union(self.ownershipDatasource, OwnerShipTempListNodes);
  //             }
  //           }

  //           _state.listExplorer.ONeil = self.sortOneilData(self.setAttribute(self.oNeilDatasource,'ONEIL'));
  //           _state.listExplorer.Ownership = self.sortOwnerShipData(self.setAttribute(self.ownershipDatasource,'OWNERSHIP'));

  //           if (ONeil.responseHeader.error || Ownership.responseHeader.error) {
  //             this.loadingListExplorer = false;
  //             TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListExplorer, false, NavType.NavLists);
  //             resolve(true);
  //             throw 'ListExplorer error';
  //           }

  //           if (refreshGrid)
  //             ListStore.setRefreshList();

  //           /* Adobe */
  //           if (callAdobe && !refreshGrid) {
  //             ONeilViewStore.pushDataToAdobeWhilePageLoad();
  //           }
  //           ownershipBlockStore.prepareHeaderMenuAfterListexplorer();
  //           _currentAction = ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER;
  //           self.emit(CHANGE_EVENT);
  //           TimeTrackingWindow.endListExplorerRenderTimeTracker();
  //           resolve(true);
  //         }
  //         catch (e) {
  //           this.loadingListExplorer = false;
  //           TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListExplorer, false, NavType.NavLists);
  //           resolve(true);
  //         }
  //       }).catch(() => {
  //         this.loadingListExplorer = false;
  //         TimeTrackingWindow.setErrTrackDataByTrackType(DatagraphDataType.ListExplorer, true, NavType.NavLists);
  //         resolve(true);
  //       })
  //     });
  //   }
  //   else {
  //     _currentAction = ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER;
  //     if (callAdobe && !refreshGrid)
  //       ONeilViewStore.pushDataToAdobeWhilePageLoad();
  //     self.emit(CHANGE_EVENT);
  //   }
  // }

  getSaveScreenedResults() {
    let isNodeActive = false;
    let isSaveScreenedResults = false;
    if (!StringUtil.isEmpty(this.activeNode) && !StringUtil.isEmpty(this.contextNode))
      isNodeActive = (this.activeNode.nodeId == this.contextNode.nodeId);
    if (this.selectedNodeIds == 0 && isNodeActive && ONeilViewStore.getSaveScreenedResultsVisibilty()) {
      isSaveScreenedResults = true;
    }

    return isSaveScreenedResults;
  }

  // Get list of items on right click.
  getContextMenu(categoryType, listType, rootParentId, selectedNodeId, nodeName, sourceListId, parentId, symbol, spId) {
    let contextMenu = [];
    let favoriteList = [];
    let selectedItems = [];
    // let className = null;
    let isNodeActive = false;
    const isSaveScreenedResults = this.getSaveScreenedResults();
    const isCryptoCurrencyList = this.getIsCryptoCurrencyList() || nodeName === "CryptoCurrency";
    const sourceListCategoryType = this.contextNode && this.contextNode.hasOwnProperty("sourceCategoryType") ? this.contextNode.sourceCategoryType : null;
    const isFuturesCashSpotList = categoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory;
    const isFactorList = categoryType === ListCategoryType.FACTORLIST_ListCategory || sourceListCategoryType === ListCategoryType.FACTORLIST_ListCategory;
    const isNotImportList = this.isNotImportedList()
    const contextMenuItemDisabled = 'contextMenuItemDisabled';

    if (!StringUtil.isEmpty(this.activeNode) && !StringUtil.isEmpty(this.contextNode))
      isNodeActive = (this.activeNode.nodeId == this.contextNode.nodeId);

    const saveScreenClassName = !isSaveScreenedResults || isCryptoCurrencyList ? contextMenuItemDisabled : null;
    const deleteClassName = !ONeilViewStore.isListLoaded ? contextMenuItemDisabled : null;
    const duplicateClassName = isCryptoCurrencyList || isFuturesCashSpotList ? contextMenuItemDisabled : null;
    const AddToFavClassName = isCryptoCurrencyList ? contextMenuItemDisabled : null;

    if (!StringUtil.isEmpty(this.selectedNodeIds)) {
      if (this.selectedNodeIds.length > 0)
        selectedItems = this.selectedNodeIds;
    } else
      selectedItems.push(selectedNodeId);

    favoriteList = this.getDataSource();
    favoriteList = _.find(favoriteList, (item) => item.categoryType === ListCategoryType.FAVORITE_ListCategory);
    this.inFavoriteList = this.getListInFavorite(favoriteList.childNodes, selectedNodeId)

    if(!isFuturesCashSpotList && !isNotImportList)
      contextMenu = this.addAlertMenuItems(categoryType, listType, rootParentId, selectedNodeId, nodeName, sourceListId, parentId);

    if (listType === ListType.LIST_List) {
      switch (categoryType) {
        case ListCategoryType.PORTFOLIOLIST_ListCategory:
        case ListCategoryType.POSITIONHISTORYLIST_ListCategory:
          if (selectedItems.length == 1) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('duplicate', ContextMenuConstants.DUPLICATE), action: ContextMenuConstants.DUPLICATE, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: duplicateClassName });
          }
          if (!StringUtil.isEmpty(this.inFavoriteList)) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_rff', ContextMenuConstants.REMOVE_FROM_FAVORITES), action: ContextMenuConstants.REMOVE_FROM_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName })
          } else {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ch_atf', ContextMenuConstants.ADD_TO_FAVORITES), action: ContextMenuConstants.ADD_TO_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: AddToFavClassName })
          }
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('delete', ContextMenuConstants.DELETE), action: ContextMenuConstants.DELETE, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName });
          break;

        case ListCategoryType.FAVORITE_ListCategory:
          contextMenu = [
            { contextName: LocalizationStore.getTranslatedData('lp_so', ContextMenuConstants.SHOW_ORIGINAL), action: ContextMenuConstants.SHOW_ORIGINAL, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, sourceListId: sourceListId },
            { contextName: LocalizationStore.getTranslatedData('duplicate', ContextMenuConstants.DUPLICATE), action: ContextMenuConstants.DUPLICATE, categoryType: categoryType, rootParentId: rootParentId, nodeId: sourceListId, nodeName: nodeName, listType: listType, className: duplicateClassName, isDupFav: true },
            { contextName: LocalizationStore.getTranslatedData('lp_rff', ContextMenuConstants.REMOVE_FROM_FAVORITES), action: ContextMenuConstants.REMOVE_FROM_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName }
          ]

          if (isNodeActive) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_ssr', ContextMenuConstants.SAVE_SCREENED_RESULTS), action: ContextMenuConstants.SAVE_SCREENED_RESULTS, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: saveScreenClassName })
          }
          break;
        case ListCategoryType.FACTORLIST_ListCategory:
        case ListCategoryType.PRESET_ListCategory:
        case ListCategoryType.APLIST_ListCategory:
        case ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory:
          if (selectedItems.length == 1) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('duplicate', ContextMenuConstants.DUPLICATE), action: ContextMenuConstants.DUPLICATE, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: duplicateClassName });

            if (isNodeActive) {
              contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_ssr', ContextMenuConstants.SAVE_SCREENED_RESULTS), action: ContextMenuConstants.SAVE_SCREENED_RESULTS, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: saveScreenClassName })
            }
          }

          if (!StringUtil.isEmpty(this.inFavoriteList)) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_rff', ContextMenuConstants.REMOVE_FROM_FAVORITES), action: ContextMenuConstants.REMOVE_FROM_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName })
          } else {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ch_atf', ContextMenuConstants.ADD_TO_FAVORITES), action: ContextMenuConstants.ADD_TO_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: AddToFavClassName })
          }
          break;

        case ListCategoryType.USER_ListCategory:
        case ListCategoryType.SCHEDULESCREENLIST_ListCategory:
          if (selectedItems.length == 1) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('duplicate', ContextMenuConstants.DUPLICATE), action: ContextMenuConstants.DUPLICATE, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: duplicateClassName });
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_TP_Share', ContextMenuConstants.SHARE), action: ContextMenuConstants.SHARE, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType })

            if (isNodeActive) {
              contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_ssr', ContextMenuConstants.SAVE_SCREENED_RESULTS), action: ContextMenuConstants.SAVE_SCREENED_RESULTS, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: saveScreenClassName })
            }

            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('rename', ContextMenuConstants.RENAME), action: ContextMenuConstants.RENAME, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType })

            if (!StringUtil.isEmpty(this.inFavoriteList)) {
              contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_rff', ContextMenuConstants.REMOVE_FROM_FAVORITES), action: ContextMenuConstants.REMOVE_FROM_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName })
            } else {
              contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ch_atf', ContextMenuConstants.ADD_TO_FAVORITES), action: ContextMenuConstants.ADD_TO_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: AddToFavClassName })
            }

            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('delete', ContextMenuConstants.DELETE), action: ContextMenuConstants.DELETE, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName });

          } else {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_TP_Share', ContextMenuConstants.SHARE), action: ContextMenuConstants.SHARE, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType })

            if (!StringUtil.isEmpty(this.inFavoriteList)) {
              contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_rff', ContextMenuConstants.REMOVE_FROM_FAVORITES), action: ContextMenuConstants.REMOVE_FROM_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName })
            } else {
              contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ch_atf', ContextMenuConstants.ADD_TO_FAVORITES), action: ContextMenuConstants.ADD_TO_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: AddToFavClassName })
            }
            contextMenu.push({
              contextName: LocalizationStore.getTranslatedData('delete', ContextMenuConstants.DELETE), action: ContextMenuConstants.DELETE, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId,
              className: deleteClassName
            });
          }
          break;

        case ListCategoryType.SHAREDLIST_ListCategory:
          if (selectedItems.length == 1) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('duplicate', ContextMenuConstants.DUPLICATE), action: ContextMenuConstants.DUPLICATE, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: duplicateClassName });
          }
          if (!StringUtil.isEmpty(this.inFavoriteList)) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_rff', ContextMenuConstants.REMOVE_FROM_FAVORITES), action: ContextMenuConstants.REMOVE_FROM_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName })
          } else {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ch_atf', ContextMenuConstants.ADD_TO_FAVORITES), action: ContextMenuConstants.ADD_TO_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: AddToFavClassName })
          }
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_remove', ContextMenuConstants.REMOVE), action: ContextMenuConstants.DELETE, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId });
          break;

        case ListCategoryType.MODELLIST_ListCategory:
          if (!StringUtil.isEmpty(this.inFavoriteList)) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_rff', ContextMenuConstants.REMOVE_FROM_FAVORITES), action: ContextMenuConstants.REMOVE_FROM_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName })
          } else {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ch_atf', ContextMenuConstants.ADD_TO_FAVORITES), action: ContextMenuConstants.ADD_TO_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: AddToFavClassName })
          }
          break;

        case ListCategoryType.SMARTLIST_ListCategory:

          if (selectedItems.length == 1) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('duplicate', ContextMenuConstants.DUPLICATE), action: ContextMenuConstants.DUPLICATE, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: duplicateClassName });
          }

          if (isNodeActive) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_ssr', ContextMenuConstants.SAVE_SCREENED_RESULTS), action: ContextMenuConstants.SAVE_SCREENED_RESULTS, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: saveScreenClassName })
          }

          if (!StringUtil.isEmpty(this.inFavoriteList)) {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('lp_rff', ContextMenuConstants.REMOVE_FROM_FAVORITES), action: ContextMenuConstants.REMOVE_FROM_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName })
          } else {
            contextMenu.push({ contextName: LocalizationStore.getTranslatedData('ch_atf', ContextMenuConstants.ADD_TO_FAVORITES), action: ContextMenuConstants.ADD_TO_FAVORITES, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, className: AddToFavClassName })
          }

          break;
        case ListCategoryType.TEMPORARYLIST_ListCategory:
          contextMenu = [];
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData("lp_stl",TempListMenuItem.SAVE_RESULTS), action: TempListMenuItem.SAVE_RESULTS, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, symbol: symbol, spId : spId });
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData("lp_remove",TempListMenuItem.REMOVE_RESULT), action: TempListMenuItem.REMOVE_RESULT, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, symbol: symbol, spId : spId });
          break;
        case ListCategoryType.NOTIMPORTEDLIST_ListCategory:
          contextMenu = [];
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData("lp_remove",TempListMenuItem.REMOVE_RESULT), action: TempListMenuItem.REMOVE_RESULT, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, symbol: symbol, spId : spId });
          break;
        default:
          break;
      }
      if(categoryType !== ListCategoryType.TEMPORARYLIST_ListCategory) {
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('LM_TP_Export', "Export"), action: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, categoryType: categoryType, sourceListId: sourceListId });
      }
      return contextMenu;
    } else if (listType === ListType.FOLDER_List) {
      contextMenu = []
      if (categoryType !== ListCategoryType.PRESET_ListCategory && categoryType !== ListCategoryType.SMARTLIST_ListCategory && categoryType !== ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory) {
        if (categoryType !== ListCategoryType.POSITIONHISTORYLIST_ListCategory) {
          contextMenu.push({ contextName: LocalizationStore.getTranslatedData('rename', ContextMenuConstants.RENAME), action: ContextMenuConstants.RENAME, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType });
        }
        contextMenu.push({ contextName: LocalizationStore.getTranslatedData('delete', ContextMenuConstants.DELETE), action: ContextMenuConstants.DELETE, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType, parentId: parentId, className: deleteClassName });
      }
    } else {
      contextMenu = null;
    }
    return contextMenu;
  }

  // Find nodeId exists in favorite section.
  getListInFavorite(favoriteList, nodeId) {
    const self = this;
    let result = null;
    nodeId = typeof (nodeId) === 'object' ? nodeId.low : nodeId;
    if (!StringUtil.isEmpty(favoriteList)) {
      for (let index = 0; index < favoriteList.length; index += 1) {
        const entry = favoriteList[index];
        if (entry !== undefined && entry.categoryType == ListCategoryType.FAVORITE_ListCategory.toString() && entry.childNodes.length > 0) {
          result = self.getListInFavorite(entry.childNodes, nodeId);
          if (!StringUtil.isEmpty(result))
            break;
        }
        else {
          if ((entry.sourceListId !== null && entry.sourceListId !== undefined) && entry.sourceListId.low == nodeId) {
            result = entry;
            if (!StringUtil.isEmpty(result))
              break;
          }
        }
      }
    }
    return result;
  }

  addAlertMenuItems(categoryType, listType, rootParentId, selectedNodeId, nodeName, sourceListId, parentId) {
    const contextMenu = [];

    let selectedDataSource = [];
    if (parseInt(categoryType) == ListCategoryType.SCHEDULESCREENLIST_ListCategory || parseInt(categoryType) == ListCategoryType.POSITIONHISTORYLIST_ListCategory)
      selectedDataSource = _.find(this.getDataSource(), (item) => ((item.categoryType === parseInt(categoryType) || parseInt(categoryType) === ListCategoryType.SCHEDULESCREENLIST_ListCategory || parseInt(categoryType) == ListCategoryType.POSITIONHISTORYLIST_ListCategory) && item.nodeId.low === parseInt(rootParentId)));
    else
      selectedDataSource = _.find(this.getDataSource(), (item) => (item.categoryType === parseInt(categoryType) && item.nodeId.low === parseInt(rootParentId)));

    let searchValue = this.findNodeById(parentId, selectedDataSource);

    if (this.isListAlertEligible(rootParentId) && searchValue.listCategory != ListManagerListCategory.POSITIONHISTORYLIST_ListCategory) {
      contextMenu.push({ contextName:LocalizationStore.getTranslatedData("ch_msa_sup_aca", ContextMenuConstants.AND_CONDITION_ALERTS), action: ContextMenuConstants.AND_CONDITION_ALERTS, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType });
      contextMenu.push({ contextName:LocalizationStore.getTranslatedData("ch_msa_sup_oca", ContextMenuConstants.ALERT), action: ContextMenuConstants.ALERT, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType });
    }

    searchValue = this.findNodeById(selectedNodeId, selectedDataSource);

    if (searchValue.alertStatusType == ListAlertStatusType.Active ||
      searchValue.alertStatusType == ListAlertStatusType.Triggered ||
      searchValue.alertStatusType == ListAlertStatusType.ActiveDisabled ||
      searchValue.alertStatusType == ListAlertStatusType.TriggeredDisabled ||
      searchValue.alertType != 0) {
        if(searchValue.alertType == AlertsType.Or || searchValue.alertType == AlertsType.Both ){
          contextMenu.push({ contextName: ContextMenuConstants.DELETE_LIST_ALERTS, action: ContextMenuConstants.DELETE_LIST_ALERTS, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType });
        }
        if(searchValue.alertType == AlertsType.And || searchValue.alertType == AlertsType.Both){
          contextMenu.splice(1, 0, { contextName: ContextMenuConstants.DELETE_LIST_ALERTS, action: ContextMenuConstants.DELETE_LIST_ALERTS, categoryType: categoryType, rootParentId: rootParentId, nodeId: selectedNodeId, nodeName: nodeName, listType: listType });
        }
      }
    return contextMenu;
  }

  isListAlertEligible(parentNodeId) {
    return _.contains(eligibleListIDsForAlerts, parentNodeId);
  }

  // Get list explorer datasource by tab type
  getDataSource(tab = null) {
    let tabType = tab;
    if(tab == null){
      tabType = this.getSelectedTabType();
    }
    let dataSource = '';
    switch (tabType) {
      case ListManagerTabType.Oneil:
        dataSource = _state.listExplorer.ONeil;
        break;
      /*Commenting ownership api call since its out of scope for 1.0 release*/
      case ListManagerTabType.Ownership:
        dataSource = _state.listExplorer.Ownership;
        break;
      default:
        break;
    }
    return dataSource;
  }

  // get current list is in explorer
  findActiveListAvailibility(listId = '') {
    const flatternList = [];
    _.clone(this.flatternDataSource(this.getDataSource(), flatternList));
    const allListIds = flatternList && flatternList.map((entry) => (entry.nodeId.low));
    if (!allListIds.includes(parseInt(listId))) {
      return false;
    } else {
      return true;
    }
  }
  // Get List Database Type
  getListDatabaseType() {
    const tabType = this.getSelectedTabType();
    let listDatabaseType = null;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        listDatabaseType = ListDatabaseType.ONEIL_ListDatabase;
        break;
      default:
        break;
    }
    return listDatabaseType;
  }

  // Get List Database Name
  getListDatabaseName() {
    return this.getListDatabaseType() === 1 ? 'ONEIL_ListDatabase' : 'OWNERSHIP_ListDatabase';
  }

  // Update list explorer datasource by tab type
  updateDataSource(dataSource, isSort = false) {
    const tabType = this.getSelectedTabType();
    switch (tabType) {
      case ListManagerTabType.Oneil:
        _state.listExplorer.ONeil = dataSource;
        break;
      case ListManagerTabType.Ownership:
        if (isSort)
          _state.listExplorer.Ownership = this.sortOwnerShipData(dataSource);
        else
          _state.listExplorer.Ownership = dataSource;
        break;
      default:
        break;
    }
  }

  // Find node in Favorites by nodeId
  findNodeInFavorites(nodeId, nodeList) {
    const self = this;
    let favDatasource = null;
    const dataSource = self.getDataSource();

    if (!StringUtil.isEmpty(dataSource))
      favDatasource = _.filter(dataSource, (item) => (item.listCategory == ListManagerListCategory.FAVORITE_ListCategory))[0];

    if (!StringUtil.isEmpty(favDatasource.childNodes)) {
      for (let i = 0; i < favDatasource.childNodes.length; i += 1) {
        const searchValue = this.findNodeBySourceListId(nodeId, favDatasource.childNodes[i]);
        if (searchValue !== false) {
          if (searchValue.parentNodeId === null)
            return;
          nodeList.push(searchValue);
          self.nodeType = searchValue.categoryType;
          this.findNodeInFavorites(searchValue.parentNodeId.low, nodeList);
        }
      }
    }
  }

  // Search Node by SourceListId
  findNodeBySourceListId(nodeId, currentNode) {
    let i, currentChild, result;

    if (!StringUtil.isEmpty(currentNode.sourceListId)) {
      if (parseInt(nodeId) == currentNode.sourceListId.low) {
        return currentNode;
      } else {
        for (i = 0; i < currentNode.childNodes.length; i += 1) {
          currentChild = currentNode.childNodes[i];
          result = this.findNodeById(nodeId, currentChild);
          if (result !== false) {
            return result;
          }
        }
        return false;
      }
    }
    return false;
  }

  findParentNode(nodeId, nodeList, dataSource = undefined) {
    if(!dataSource){
      dataSource = this.getDataSource();
    }
    const self = this;
    if (!StringUtil.isEmpty(dataSource)) {
      for (let i = 0; i < dataSource.length; i += 1) {
        const searchValue = this.findNodeById(nodeId, dataSource[i]);
        if (searchValue !== false) {
          nodeList.push(searchValue);
          self.nodeType = searchValue.categoryType;
          if (searchValue.parentNodeId === null)
            return;
          this.findParentNode(searchValue.parentNodeId.low, nodeList, dataSource);
        }
      }
    }
  }
  // Expand and find parentNodeList by node Id.
  expandAndFindParentNode(nodeId, nodeList) {
    const dataSource = this.getDataSource();
    const self = this;
    if (!StringUtil.isEmpty(dataSource)) {
      for (let index = 0; index < dataSource.length; index += 1) {
        const searchValue = this.findNodeById(nodeId, dataSource[index]);
        if (searchValue !== false) {
          if (searchValue.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && self.activeNode &&
             ((self.activeNode.SelectedCategoryType !== undefined && self.activeNode.SelectedListName !== undefined && self.activeNode.SelectedCategoryType === ListCategoryType.TEMPORARYLIST_ListCategory && self.activeNode.SelectedListName !== searchValue.name) ||
              (self.activeNode.categoryType !== undefined && self.activeNode.name !== undefined && self.activeNode.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && self.activeNode.name !== searchValue.name))){
            continue;
          }
          if (searchValue.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && self.activeNode && 
            ((self.activeNode.SelectedCategoryType !== undefined && self.activeNode.SelectedListName !== undefined && self.activeNode.SelectedCategoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && self.activeNode.SelectedListName !== searchValue.name) ||
             (self.activeNode.categoryType !== undefined && self.activeNode.name !== undefined && self.activeNode.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && self.activeNode.name !== searchValue.name))){
           continue;
         }
          nodeList.push(searchValue);
          if (searchValue.parentNodeId === null) {
            _.extend(searchValue, { expandNode: true, isVisible: true });
            return;
          }
          self.nodeType = searchValue.categoryType;
          _.extend(searchValue, { expandNode: true, isVisible: true });
          this.expandAndFindParentNode(searchValue.parentNodeId.low, nodeList);
        }
      }
    }
  }

  // Search Node by nodeId
  findNodeById(nodeId, currentNode) {
    let i, currentChild, result;
    if(!currentNode){
      return false;
    }
    let currentNodeId = currentNode && currentNode.nodeId && currentNode.nodeId.low;
    if (parseInt(nodeId) == currentNodeId) {
      return currentNode;
    } else {
      let childNodesLength = currentNode.childNodes ? currentNode.childNodes.length : 0;
      for (i = 0; i < childNodesLength; i += 1) {
        currentChild = currentNode.childNodes[i];
        result = this.findNodeById(nodeId, currentChild);
        if (result !== false) {
          return result;
        }
      }
      return false;
    }
  }

  // Search Node by symbol
  findNodeBySymbol(symbol, currentNode) {
    let i, currentChild, result;

    if (symbol.trim() == currentNode.symbol) {
      return currentNode;
    } else {
      for (i = 0; i < currentNode.length; i += 1) {
        currentChild = currentNode[i];
        result = this.findNodeBySymbol(symbol, currentChild);
        if (result !== false) {
          return result;
        }
      }
      return false;
    }
  }

  findOneilOwnershipNodeById(nodeId) {
    const dataSource = this.getDataSource();
    if (!StringUtil.isEmpty(dataSource) && dataSource.length > 0) {
      for (let index = 0; index < dataSource.length; index += 1) {
        const searchValue = this.findNodeById(nodeId, dataSource[index]);
        if (searchValue !== false) {
          return dataSource[index];
        }
      }
    }
  }

  findNodeByNodeId(nodeId,tab){
    const dataSource = this.getDataSource(tab);
    if (!StringUtil.isEmpty(dataSource) && dataSource.length > 0) {
      for (let index = 0; index < dataSource.length; index += 1) {
        const searchValue = this.findNodeById(nodeId, dataSource[index]);
        if (searchValue !== false) {
          return searchValue;
        }
      }
    }
  }

  getListChangedStatus() {
    if (!this.activeNode ||this.activeNode.nodeId == undefined) {
      return false;
    } else {
      if (this.listChangedInit == false) {
        this.previousListId = this.activeNode.nodeId;
        this.listChangedInit = true;
      }
      this.currentListId = this.activeNode.nodeId;
      this.currentNode = this.activeNode;
      if (this.previousListId !== this.currentListId) {
        this.previousListId = this.currentListId;
        return true;
      } else {
        return false;
      }
    }
  }

  // Get selected nodeId's on shift and ctrl key press
  getSelectedNodeIds(categoryType, rootParentId, parentNodeId, currentNodeId, previousNodeId, isShift, symbol = undefined) {
    categoryType = (categoryType === ListCategoryType.SCHEDULESCREENLIST_ListCategory)
      ? ListCategoryType.USER_ListCategory : categoryType;
    let selectedDataSource = [];
    if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory)
      selectedDataSource = _.filter(this.getDataSource(), (item) => (item.categoryType === parseInt(ListCategoryType.TEMPORARYLIST_ListCategory)));
    else if(categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory){
      selectedDataSource = _.filter(this.getDataSource(), (item) => (item.categoryType === parseInt(ListCategoryType.NOTIMPORTEDLIST_ListCategory)));
    }
    else if (categoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory)
      selectedDataSource = _.find(this.getDataSource(), (item) => (_.find(item.childNodes, (items) => (items.categoryType === parseInt(categoryType))) && item.nodeId.low === parseInt(rootParentId)));
    else
      selectedDataSource = _.find(this.getDataSource(), (item) => (item.categoryType === parseInt(categoryType) && item.nodeId.low === parseInt(rootParentId)));

    const dataSource = this.getDataSource();
    let previousNodeData = null;
    for (let i = 0; i < dataSource.length; i += 1) {
      let targetNode = dataSource[i];
      if (!targetNode) {
        continue;
      }
      let targetNodeId = targetNode && targetNode.nodeId.low;
      if (parseInt(previousNodeId) == targetNodeId) {
        previousNodeData = dataSource[i];
        break;
      }
      else {
        let childNodesLength = targetNode.childNodes ? targetNode.childNodes.length : 0;
        for (let j = 0; j < childNodesLength; j += 1) {
          let targetNodeChild = targetNode.childNodes[j];
          previousNodeData = this.findNodeById(previousNodeId, targetNodeChild);
          if (previousNodeData) {
            break;
          }
        }
      }
    }
    if ((categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && previousNodeData && previousNodeData.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory)) {
      if (isShift && selectedDataSource.length > 0) {
        let selectedNode = _.find(this.getDataSource(), (item) => (item.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && item.nodeId.low === parseInt(previousNodeId)));
        if (!StringUtil.isEmpty(selectedNode)) {
          this.selectedNodeIds.push(selectedNode);
        }
      }
      return;
    }
    else if (categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && previousNodeData && previousNodeData.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
      if (isShift && selectedDataSource.length > 0) {
        let previousSymbol = this.activeSybol.symbol;
        let selectedNode = _.find(this.getDataSource(), (item) => (item.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && item.symbol == previousSymbol && item.nodeId.low === parseInt(previousNodeId)));
        if (!StringUtil.isEmpty(selectedNode)) {
          this.selectedNodeIds.push(selectedNode);
        }
      }
      return;
    }
    if (isShift) {
      //Get nodeIds on shift key press
      let currentChild = null;

      if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory) {
        let previousSymbol = this.activeSybol.symbol;
        let currentSymbol = symbol === null ? undefined : symbol;

        if (selectedDataSource.length > 0) {
          for (let i = 0; i < selectedDataSource.length; i += 1) {
            const listItem = selectedDataSource[i];
            const listItemSymbol = selectedDataSource[i] && selectedDataSource[i].symbol;
            if (((currentSymbol !== undefined && listItemSymbol == currentSymbol) || (previousSymbol !== undefined && listItemSymbol == previousSymbol)) || !StringUtil.isEmpty(currentChild)) {
              if (currentSymbol !== undefined && listItemSymbol == currentSymbol)
                currentSymbol = undefined;

              if (previousSymbol !== undefined && listItemSymbol == previousSymbol)
                previousSymbol = undefined;

              currentChild = listItem;

              if (!StringUtil.isEmpty(currentChild)) {
                this.selectedNodeIds.push(currentChild);
              }

              if (StringUtil.isEmpty(currentSymbol) && StringUtil.isEmpty(previousSymbol))
                currentChild = null;
            }
          }
        }
      }
      else if(categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory){
        if (selectedDataSource && selectedDataSource.length > 0) {
          for (let i = 0; i < selectedDataSource.length; i += 1) {
            if ((selectedDataSource[i].nodeId.low == currentNodeId || selectedDataSource[i].nodeId.low == previousNodeId) || !StringUtil.isEmpty(currentChild)) {
              if (selectedDataSource[i].nodeId.low == currentNodeId)
                currentNodeId = null;

              if (selectedDataSource[i].nodeId.low == previousNodeId)
                previousNodeId = null;

              currentChild = selectedDataSource[i];

              if (!StringUtil.isEmpty(currentChild)) {
                this.selectedNodeIds.push(currentChild);
              }

              if (StringUtil.isEmpty(currentNodeId) && StringUtil.isEmpty(previousNodeId))
                currentChild = null;
            }
          }
        }
      }
      else {
        const searchValue = this.findNodeById(parentNodeId, selectedDataSource);
        if (searchValue) {
          for (let i = 0; i < searchValue.childNodes.length; i += 1) {
            if ((searchValue.childNodes[i].nodeId.low == currentNodeId || searchValue.childNodes[i].nodeId.low == previousNodeId) || !StringUtil.isEmpty(currentChild)) {
              if (searchValue.childNodes[i].nodeId.low == currentNodeId)
                currentNodeId = null;

              if (searchValue.childNodes[i].nodeId.low == previousNodeId)
                previousNodeId = null;

              currentChild = searchValue.childNodes[i];

              if (!StringUtil.isEmpty(currentChild)) {
                this.selectedNodeIds.push(currentChild);
              }

              if (StringUtil.isEmpty(currentNodeId) && StringUtil.isEmpty(previousNodeId))
                currentChild = null;
            }
          }
        }
      }
    }
    else {
      //Get nodeIds on ctrl key press
      let indexValue = -1;
      let selectedItem = null;
      if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory || categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory)
        selectedItem = _.find(selectedDataSource, (item) => (symbol !== undefined && item.symbol === symbol) || (symbol === undefined && item.nodeId.low === currentNodeId));
      else
        selectedItem = this.findNodeById(currentNodeId, selectedDataSource);
      if (!StringUtil.isEmpty(selectedItem) && selectedItem) {
        if (this.selectedNodeIds.length == 0)
          this.selectedNodeIds.push(selectedItem);
        else {
          if (categoryType === ListCategoryType.TEMPORARYLIST_ListCategory || categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory)
            indexValue = _.findIndex(this.selectedNodeIds, (item) => (symbol !== undefined && item.symbol === symbol) || (symbol === undefined && item.nodeId.low === currentNodeId));
          else
            indexValue = _.findIndex(this.selectedNodeIds, (item) => (item.nodeId.low === currentNodeId));
          if (indexValue !== -1) {
            this.nodeIdTodeSelect = this.selectedNodeIds[indexValue];
            this.selectedNodeIds.splice(indexValue, 1);
          }
          else
            this.selectedNodeIds.push(selectedItem);
        }
      }
    }
  }

  // Get current selected action.
  getCurrentAction() {
    return _currentAction;
  }

  clearCurrentAction() {
    _currentAction = null;
  }

  // Add symbols to existing list.
  getAddItemsToList(data) {
    const self = this;
    ListApi.getAddItemsToList(data.itemCopyToList).then((result) => {

      if (!result.responseHeader.error) {
        _state.removeItemsFromUserList.msids = null;
        _state.removeItemsFromUserList.targetListId = null;
        _state.removeItemsFromUserList.snapshotdates = null;
        _state.removeItemsFromUserList.currentNodeId = null;

        const addSymbolListData = result.responseData == null ? {statusData:[]} : result.responseData.symbolListData;
        let addSymbolsobj = null;

        if (!StringUtil.isEmpty(addSymbolListData) && addSymbolListData.statusData.length == 0)
          _state.alertMessage = `0 items has been added to ${data.itemCopyToList.targetNodeName}`;
        else {
          addSymbolsobj = ONeilViewStore.processAddServiceResult(addSymbolListData, data.itemCopyToList.targetNodeName);

          if (addSymbolsobj.isUndoable) {
            const addSymbols = addSymbolsobj.addSymbols;
            _state.removeItemsFromUserList.targetListId = data.itemCopyToList.targetListId;
            _state.removeItemsFromUserList.currentNodeId = data.itemCopyToList.currentNodeId;
            _state.removeItemsFromUserList.cacheId = 0;
            if (!StringUtil.isEmpty(addSymbols) && addSymbols.length > 0) {
              _state.removeItemsFromUserList.msids = addSymbols.map((e) => { if (!StringUtil.isEmpty(e.msId)) return e.msId; }).join(';');
              _state.removeItemsFromUserList.snapshotdates = ONeilViewStore.getSnapshotDateFromMsIds(_state.removeItemsFromUserList.msids.split(';'));
            }
          }

          if (addSymbolsobj.showMessage) {
            if (!StringUtil.isEmpty(addSymbolsobj.displayMessage))
              _state.alertMessage = addSymbolsobj.displayMessage;
            else if (!StringUtil.isEmpty(addSymbolsobj.rejectionMessage))
              _state.alertMessage = addSymbolsobj.rejectionMessage;
          }
        }
        this.findNodeByIdAndUpdateDate(data.itemCopyToList.targetListId, result.responseHeader.asOfDate);
        self.emit(CHANGE_EVENT);
        return;
      }
      else {
        // ConsoleActions.exceptionHandle(result.responseHeader);
        return;
      }
    });
  }

  // Duplicate and Expand list item.
  duplicateItem(nodeId, nodeName, rootParentId, cacheId, isSaveScreenedResults) {
    if (nodeId !== rootParentId) {
      this.ExpandItemLevel(ListCategoryType.USER_ListCategory, null, true);
      this.duplicateList(nodeId, nodeName, rootParentId, cacheId, isSaveScreenedResults);
    }
  }

  duplicateList(nodeId, nodeName, rootParentId, cacheId, isSaveScreenedResults) {
    if (StringUtil.isEmpty(nodeName)) {
      return;
    }
    else {
      const duplicateList = {
        symCode: '',
        parentNodeId: rootParentId,
        name: nodeName,
        sourceListId: nodeId,
        destListId: '',
        cacheId: cacheId.toString(),
        sortCols: null,
        isFav: false,
        isFlagList: false,
        spId: null,
        isDupFav: this.activeNode.categoryType == ListCategoryType.FAVORITE_ListCategory,
        message: ""
      };
      this.getDuplicateList({ duplicateList: duplicateList, parentNodeId: rootParentId, isSaveScreenedResults: isSaveScreenedResults });
    }
  }

  // Get current duplicated node
  getDuplicateNode() {
    return this.duplicateNode;
  }

  generateTmporaryListCatalogInfo(targetNode, pageIndex = 0, cacheId = -1) {
    let listCatalog = clone(ONeilViewStore.listCatalog);
    if (targetNode) {
      listCatalog.searchText = '';
      listCatalog.listId = targetNode.nodeId;
      listCatalog.actualListId = targetNode.nodeId;
      listCatalog.listName = targetNode.name;
      listCatalog.pageIndex = pageIndex;
      listCatalog.cacheId = cacheId != -1 ? cacheId : 0;
      listCatalog.msId = targetNode.msid;
      listCatalog.indGrpCode = targetNode.symbol;
      listCatalog.fundId = targetNode.spId;
      if (targetNode.temporaryListType)
        listCatalog.tmpListType = targetNode.temporaryListType;
      listCatalog.needBrowserCounts = 1;
      listCatalog.browserFilterText = "-1;-1;-1;";
      listCatalog.igOneilbrowserFilters = "-1;-1;-1;";
      listCatalog.igGicsbrowserFilters = "-1;-1;-1;-1;";
    }
    return listCatalog;
  }

  // Api call to duplicate
  getDuplicateList(data) {
    const self = this;
    let duplicateList = [];
    let basicEsInfo = ONeilViewStore.getState().basicEsInfo;


    self.responseCount = 0;
    if (!StringUtil.isEmpty(data)) {
      if (!StringUtil.isEmpty(data.duplicateList)) {
        if (!Array.isArray(data.duplicateList))
          duplicateList.push(data.duplicateList);
        else
          duplicateList = data.duplicateList;

        duplicateList.map((item) => {

          if (!StringUtil.isEmpty(this.contextNode)) {
            if(this.contextNode && this.contextNode.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && this.contextNode.nodeId !== basicEsInfo.actualListId){
              const tempListCatalog = this.generateTmporaryListCatalogInfo(this.contextNode);
              let basicEsInfoData = BasicEsInfo.setBasicEsInfoFromListCatalogData(tempListCatalog);
              basicEsInfo = basicEsInfoData;
            }
            basicEsInfo.actualListId = basicEsInfo.listId = this.contextNode.nodeId;
          }else{
            basicEsInfo.actualListId=item.sourceListId;
          }
          ListApi.getDuplicateList(item, data.isSaveScreenedResults, basicEsInfo).then((result) => {
            try {
              if (!result.responseHeader.error) {
                let dataSource = self.getDataSource();
                const targetCategoryType = self.getOneilCategoryTypeById(result.data.categoryType);
                self.duplicateNode = result.data;
                if (!StringUtil.isEmpty(data.sourceCategoryType))
                  self.duplicateNode.sourceCategoryType = data.sourceCategoryType;

                if (!StringUtil.isEmpty(item.description))
                  self.duplicateNode.description = item.description;

                //get sourceParentCategoryName
                const sourceParentCategoryName = self.getSourceParentCategory(self.duplicateNode.sourceListId.low);
                _.extend(self.duplicateNode, { sourceParentCategoryName: sourceParentCategoryName });

                dataSource = self.updateListExplorer(dataSource, self.duplicateNode, data.parentNodeId);
                self.updateDataSource(self.sortOneilData(dataSource), false);

                switch (_currentAction) {
                  case ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST:
                  case ListExplorerConstants.ActionTypes.SAVE_SCREENED_RESULTS:
                  case ListExplorerConstants.ActionTypes.COPY_PASTE:
                    if (data.isSaveScreenedResults) {
                      _state.alertMessage = LocalizationStore.getTranslatedData('lp_mes_18', 'Results saved as a new list.');
                      self.contextNode = null;
                      self.emit(CHANGE_EVENT);
                    } else {
                      if(data.duplicateList.length > 1){
                        BrowserUtil.disableEvents();
                        self.responseCount = self.responseCount + 1;
                        self.emitDuplicateList(self.responseCount, data.duplicateList.length, self, targetCategoryType, _currentAction);
                      }
                      else{
                        const nodename = !StringUtil.isEmpty(self.contextNode) ? self.contextNode.name : self.activeNode.name;
                        _state.alertMessage = LocalizationStore.getTranslatedData('was_duplicated', '{0} was duplicated.').replace('{0}', `${LocalizationStore.getTranslatedData(self.getListTreeItemNameResKey(nodename, self.duplicateNode.listType), nodename)}`);
                        self.contextNode = null;
                        self.emit(CHANGE_EVENT);
                      }
                    }
                    
                    break;
                  case ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES:
                  case ListExplorerConstants.ActionTypes.COPY:
                    self.responseCount = self.responseCount + 1;
                    self.emitDuplicateList(self.responseCount, data.duplicateList.length, self, targetCategoryType);
                    break;
                  default:
                    break;
                }
              }
            }
            catch (e) {
              BrowserUtil.enableEvents();
            }
          });
        });
      }
      else {
        if (_currentAction == ListExplorerConstants.ActionTypes.COPY || _currentAction == ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES) {
          _state.alertMessage = LocalizationStore.getTranslatedData('list_Exists', 'List already exists.');
          self.emit(CHANGE_EVENT);
        }
      }
    }
    else {
      if (_currentAction == ListExplorerConstants.ActionTypes.COPY || _currentAction == ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES) {
        _state.alertMessage = LocalizationStore.getTranslatedData('list_Exists', 'List already exists.');
        self.emit(CHANGE_EVENT);
      }
    }
  }

  emitDuplicateList(responseCount, sourceListCount, self, targetCategoryType, currentAction = null) {
    if (responseCount == sourceListCount) {
      if (sourceListCount > 0 && this.duplicateListCount > 0 && _currentAction == ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES)
        _state.alertMessage = `${LocalizationStore.getTranslatedData('lists_atf_exists', '{0} list(s) were added to FAVORITES, {1} list(s) already exist.')}`.replace('{0}', sourceListCount).replace('{1}', this.duplicateListCount);
      else if (sourceListCount > 1 && this.duplicateListCount == 0) {
        var targetCategoryTypeReskey = `TreeExplorer_CAT_${targetCategoryType.replace(' ', '').toUpperCase()}`;
        //fix-3977
        if (_currentAction == ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES) {
          _state.alertMessage = `${LocalizationStore.getTranslatedData('lists_added_tofav', '{0} lists were {1} to {2}')}`.replace('{0}', sourceListCount).replace('{1}', LocalizationStore.getTranslatedData("list_added", 'added')).replace('{2}', LocalizationStore.getTranslatedData(targetCategoryTypeReskey, targetCategoryType));
          // lists_added_tofav and list_added are for other languages
        } else {
          _state.alertMessage = `${LocalizationStore.getTranslatedData('lists_movedMsg', '{0} lists were {1} to {2}')}`.replace('{0}', sourceListCount).replace('{1}', LocalizationStore.getTranslatedData("list_copied", 'copied')).replace('{2}', LocalizationStore.getTranslatedData(targetCategoryTypeReskey, targetCategoryType));
        }
      }
      else if (sourceListCount == 1 && _currentAction == ListExplorerConstants.ActionTypes.COPY)
        _state.alertMessage = `${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(self.duplicateNode.name, self.duplicateNode.listType), self.duplicateNode.name)}
        ${LocalizationStore.getTranslatedData('List_wasCopiedTo', 'was copied to')}
        ${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(_parentNodeTitle, _parentListType), _parentNodeTitle)}.`;
      else if (sourceListCount == 1 && _currentAction == ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES)
        _state.alertMessage = `${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(self.duplicateNode.name, self.duplicateNode.listType), self.duplicateNode.name)} ${LocalizationStore.getTranslatedData('list_atf', 'was added to FAVORITES.')}`;

      if(currentAction === ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST
        || currentAction === ListExplorerConstants.ActionTypes.SAVE_SCREENED_RESULTS
        || currentAction === ListExplorerConstants.ActionTypes.COPY_PASTE){
        BrowserUtil.enableEvents();
      }
      self.emit(CHANGE_EVENT);
      self.responseCount = 0;
      this.duplicateListCount = 0;
      this.selectedNodeIds = [];
    }
  }

  getSourceParentCategory(sourceListId) {
    let nodeId;
    if (!StringUtil.isEmpty(this.activeNode)) {
      nodeId = typeof (this.activeNode.nodeId) === 'object' ? this.activeNode.nodeId.low : this.activeNode.nodeId;
    }

    if (nodeId == sourceListId)
      return this.activeNode.parentCategoryName;

    if (!StringUtil.isEmpty(this.contextNode)) {
      nodeId = typeof (this.contextNode.nodeId) === 'object' ? this.contextNode.nodeId.low : this.contextNode.nodeId;
    }

    if (nodeId == sourceListId)
      return this.contextNode.parentCategoryName;
  }

  // Get ONeil category type by category id.
  getOneilCategoryTypeById(categoryType) {
    let categoryName = null;
    switch (categoryType) {
      case ListCategoryType.FAVORITE_ListCategory:
        categoryName = 'FAVORITES';
        break;

      case ListCategoryType.USER_ListCategory:
        categoryName = 'My LISTS';
        break;

      case ListCategoryType.APLIST_ListCategory:
        categoryName = 'AP LISTS';
        break;

      case ListCategoryType.PORTFOLIOLIST_ListCategory:
        categoryName = 'PORTFOLIO LISTS';
        break;

      case ListCategoryType.SHAREDLIST_ListCategory:
        categoryName = 'SHARED LISTS';
        break;

      case ListCategoryType.SMARTLIST_ListCategory:
        categoryName = 'SMART LISTS';
        break;
      default:
        break;
    }
    return categoryName;
  }

  //Api call to add Newlist/ New folder
  getCreateTreeNode(data) {
    const self = this;
    ListApi.getCreateTreeNode(data.myList).then((result) => {
      if (!result.responseHeader.error) {
        let dataSource = self.getDataSource();
        const newNode = result.data;
        //Updating ListCategory property
        _.extend(newNode, { listCategory: this.convertToListMgrListCategory(newNode.categoryType) });
        dataSource = self.updateListExplorer(dataSource, newNode, data.myList.parentNodeId);
        self.updateDataSource(self.sortOneilData(dataSource), false);
        BrowserUtil.enableEvents();
        self.emit(CHANGE_EVENT);
      }
    });
  }

  // Api call to update description for list in list property section.
    getUpdateDescription({ updatedDecriptionList }) {
    const self = this;
     ListApi.getUpdateNodeDescription(updatedDecriptionList).then((result) => {
      if (!result.responseHeader.error) {
        let dataSource = self.getDataSource();
        const listcategory = (updatedDecriptionList.nodeType === ListType.ANNOTATIONLAYER_List) ? ListCategoryType.SMARTLIST_ListCategory : ListCategoryType.USER_ListCategory;
        const currentNode = _.find(dataSource, (item) => item.categoryType === listcategory);
        const updateNode = self.findNodeById(updatedDecriptionList.nodeId, currentNode);
        updateNode.description = updatedDecriptionList.newDescription;
        updateNode.updateDt = result.responseHeader.asOfDate;
        dataSource = self.updateListExplorer(dataSource, updateNode, listcategory, false, true);
        self.updateDataSource(dataSource, false);
        self.emit(LISTPROPERTY_CHANGE);
        BrowserUtil.enableEvents();
      }
    });
  }

  // Api call to remove added items from user list on Drag&Drop=>Undo from grid and list explorer.
  removeItemsFromUserList(data) {
    const self = this;
    ListApi.removeItemsFromUserList(data.targetListId, data.cacheId, data.msids, data.snapshotdates).then((result) => {
      if (!result.responseHeader.error) {
        this.getListAlertStatus({ listId: data.targetListId }, true);
        _state.removeItemsFromUserList = data;
        self.emit(CHANGE_EVENT);

        if (ListStore.getState().SelectedTabKey === ListManagerTabType.External || ListStore.getState().SelectedTabKey === ListManagerTabType.Ownership) {
          BrowserUtil.enableEvents();
        }
      }
    });
  }

  // Api call to update List/Folder name on rename/duplicate action.
  getRenameNode(data) {
    const self = this;
    ListApi.getUpdateListNode(data.nodeId, data.nodeName, data.parentNodeId).then((result) => {
      if (!result.responseHeader.error) {
        const parentNodeId = [];
        let dataSource = self.getDataSource();
        const renameNode = result.data;

        const favNodeId = self.getNodeIdByListCategory(ListManagerListCategory.FAVORITE_ListCategory);
        parentNodeId.push(favNodeId);
        parentNodeId.push(data.parentNodeId);

        renameNode.updateDt = result.responseHeader.asOfDate;
        dataSource = self.updateListExplorer(dataSource, renameNode, parentNodeId, false, true);
        self.updateDataSource(self.sortOneilData(dataSource), false);
        self.emit(CHANGE_EVENT);
        let activeListId = typeof (self.activeNode.nodeId) === 'object' ? self.activeNode.nodeId.low : self.activeNode.nodeId;
        if(this.isFavoriteList(self.activeNode)){
          activeListId =  typeof (self.activeNode.sourceListId) === 'object' ? self.activeNode.sourceListId.low : self.activeNode.sourceListId;;
        }
        const targetListId = typeof (data.nodeId) === 'object' ? data.nodeId.low : data.nodeId;
        if (activeListId == targetListId) {
          ListActions.listRenameOnOneilTab(data.nodeName);
          self.activeNode.name = data.nodeName;
        }
      }
    });
  }

  // Filter list explorer datasource by listcategory.
  getNodeIdByListCategory(listCategory) {
    let dataSource = [];
    dataSource = this.getDataSource();
    const favList = _.find(dataSource, (item) => (item.listCategory === listCategory));
    return favList.nodeId.low;
  }

  // Api call to update list node on drag and drop action within same category.
  moveListItems(data) {
    const self = this;
    if (data.itemToMove.length > 0) {
      data.itemToMove.map((item) => {
        item.name = this.validateListName(item.name, data.newParent.nodeId.low, null, item.listType);
        //ListApi.getUpdateListNode(item.nodeId.low, StringUtil.stripHTML(item.name), data.newParent.nodeId.low).then((result) => {
        ListApi.getUpdateListNode(item.nodeId.low, item.name, data.newParent.nodeId.low).then((result) => {
          if (!result.responseHeader.error) {
            self.responseCount = self.responseCount + 1;
            self.emitMoveListItems(self.responseCount, data, result);
          }
        });
      });
    }
  }

  emitMoveListItems(responseCount, data, result) {
    const self = this;
    const nodeIdsToDelete = [];
    _.extend(result.data, { expandNode: false, isVisible: true, sourceCategoryType: data.itemToMove[0].sourceCategoryType });
    self.ItemsToAdd.push(result.data);
    self.ItemsToAdd.updateDt = result.responseHeader.asOfDate;
    if (responseCount == data.itemToMove.length) {

      if (data.itemToMove[0].listType == ListType.FOLDER_List) {
        _state.alertMessage = `${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(data.itemToMove[0].name, data.itemToMove[0].sourceCategoryType), data.itemToMove[0].name)}
        ${LocalizationStore.getTranslatedData("list_wasMovedTo", "was moved to")}
        ${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(data.newParent.name, data.newParent.sourceCategoryType), data.newParent.name)}`;
      }
      else {
        if (self.responseCount == 1) {
          const currentNodeId = !StringUtil.isEmpty(self.activeNode.nodeId.low) ? self.activeNode.nodeId.low : self.activeNode.nodeId;
          _state.alertMessage = `${(currentNodeId ?
            LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(data.itemToMove[0].name, data.itemToMove[0].sourceCategoryType), data.itemToMove[0].name) :
            LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(self.activeNode.name, self.activeNode.sourceCategoryType), self.activeNode.name))}
          ${LocalizationStore.getTranslatedData("list_wasMovedTo", "was moved to")}
          ${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(data.newParent.name, data.newParent.sourceCategoryType), data.newParent.name)}`;
        }
        else
          _state.alertMessage = `${self.responseCount} ${LocalizationStore.getTranslatedData("list_listWasMovedTo", "lists were moved to")} ${data.newParent.name}`;
      }

      if (self.ItemsToAdd.length > 1)
        this.selectedNodeIds = self.ItemsToAdd;

      //Get nodeIds to remove from List
      data.itemToMove.map((e) => (nodeIdsToDelete.push(e.nodeId.low)));

      //Get child nodes if selected item is folder
      if (data.itemToMove[0].listType == ListType.FOLDER_List && data.itemToMove[0].childNodes.length > 0) {
        _.extend(self.ItemsToAdd[0], { childNodes: data.itemToMove[0].childNodes });
      }

      //Updating ListCategory property
      _.extend(self.ItemsToAdd[0], { listCategory: this.convertToListMgrListCategory(self.ItemsToAdd[0].categoryType) });

      //Setting tree item level for List/Folder
      _.extend(self.ItemsToAdd[0], { listTreeItemLevel: 0 });


      for (let i = 0; i < data.itemToMove.length; i++) {
        _.find(data.itemToMove, (item) => {
          if (item.nodeId.low === self.ItemsToAdd[i].nodeId.low){
            _.extend(self.ItemsToAdd[i], { alertStatusType: item.alertStatusType });
            _.extend(self.ItemsToAdd[i], { alertType: item.alertType });
          }
        });
      }


      _.extend(self.ItemsToAdd[0], { expandNode: false, isVisible: true });

      if (!StringUtil.isEmpty(self.ItemsToAdd[0].parentNodeId)) {
        _.extend(self.ItemsToAdd[0], { listTreeItemLevel: data.newParent.listTreeItemLevel + 1 });
      }

      //Temporary fix for moving list/folder in Shared list
      if (data.itemToMove[0].categoryType == ListCategoryType.SHAREDLIST_ListCategory)
        _.extend(self.ItemsToAdd[0], { categoryType: data.itemToMove[0].categoryType, parentNodeId: data.newParent.nodeId, sourceListId: null, shareAccess: data.itemToMove[0].shareAccess, ownerUserName: data.itemToMove[0].ownerUserName });

      //Fix for PANWEB: 1100 and 1101
      if (data.itemToMove[0].categoryType == ListCategoryType.FAVORITE_ListCategory)
        _.extend(self.ItemsToAdd[0], { sourceListId: data.itemToMove[0].sourceListId });

      //Adding owner name for SHARED ListCategory
      // _.extend(self.ItemsToAdd[0], {
      //   name: (data.itemToMove[0].categoryType == ListCategoryType.SHAREDLIST_ListCategory && data.itemToMove[0].listType == ListType.LIST_List)
      //     ? `${self.ItemsToAdd[0].name} (${data.itemToMove[0].ownerUserName})` : self.ItemsToAdd[0].name
      // });

      self.updateListExplorerOnMove(self.ItemsToAdd, data.newParent, nodeIdsToDelete);

      self.responseCount = 0;
      self.ItemsToAdd = [];
      self.emit(CHANGE_EVENT);
    }
  }

  // Update List explorer datasource on drag and drop action within same category.
  updateListExplorerOnMove(ItemsToAdd, newParent, nodeIdsToDelete) {
    let dataSource = this.getDataSource();
    dataSource = this.updateListExplorer(dataSource, '', nodeIdsToDelete, true);
    dataSource = this.addItemsToDataSourceById(dataSource, ItemsToAdd, newParent);
    this.updateDataSource(this.sortOneilData(dataSource), false);
  }

  // Add items to List explorer datasource on drag and drop action within same category.
  addItemsToDataSourceById(dataSource, ItemsToAdd, newParent) {
    if (!StringUtil.isEmpty(dataSource) && dataSource.length > 0) {
      for (let index = 0; index < dataSource.length; index += 1) {
        const searchValue = this.findNodeById(newParent.nodeId.low, dataSource[index]);
        if (searchValue !== false) {
          _.extend(searchValue, { expandNode: true, isVisible: true });
          _.extend(ItemsToAdd.map((item) => (searchValue.childNodes.push(item))));
          return dataSource;
        }
      }
    }
  }

  // Api call to delete List/Folder from List explorer.
  getDeleteNode(data) {
    ListApi.getDeleteTreeNode(data.nodeIds).then((result) => {
      if (!result.responseHeader.error) {
        this.deleteExplorerNode(data)
      }
    });
  }

  deleteUnShareList(){
    if (StringUtil.isEmpty(this.oNeilDatasource)){ 
      _state.deleteNodeData = true;
      return;
    }
    this.contextNode = this.activeNode;
    this.contextNode.isUnShared = true;
    const { nodeId, name, rootParentNodeId, parentNodeId, listType, categoryType } = this.activeNode;
    const deleteNodeIds = typeof (nodeId) === 'object' ? nodeId.low : nodeId;
    const dataSource = [];
    _.clone(this.flatternDataSource(this.getDataSource(), dataSource));
    _deleteInfo = {
      nodeId: deleteNodeIds, nodeName: name, 
      rootParentId: rootParentNodeId, parentId: parentNodeId, 
      listType, action: ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE, 
      categoryType, dataSource, activeNodeData: undefined
    }
    if(_state.deleteNodeData){
      _currentAction = ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE
      _state.alertMessage = LocalizationStore.getTranslatedData("lp_mes_16", "The list accessed is not available.");
      this.emit(CHANGE_EVENT);
      return;
    }
    this.deleteExplorerNode({nodeIds: deleteNodeIds, nodeName: name})
  }

  deleteExplorerNode(data){
    const self = this;
    const nodeidsToDelete = [],
      symbolsToDelete = [],
      flatternList = [];
      let dataSource = self.getDataSource();
      self.deleteDataSource = JSON.parse(JSON.stringify(dataSource));
      _state.deleteNodeId = data.nodeIds;
      if (!StringUtil.isEmpty(this.selectedNodeIds) && this.selectedNodeIds.length > 0 && _currentAction !== ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES) {
        if (this.selectedNodeIds.length > 1){
          _state.alertMessage = `${LocalizationStore.getTranslatedData('lists_deleted', '{0} lists were deleted.')}`.replace('{0}', this.selectedNodeIds.length);
        }
        else{
          _state.alertMessage = `${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(data.nodeName, self.nodeType), data.nodeName)} ${LocalizationStore.getTranslatedData('list_deleted', 'was deleted')}.`;
        }
        // _state.alertMessage = `${LocalizationStore.getTranslatedData('lists_deleted', '{0} lists were deleted.')}`.replace('{0}', this.selectedNodeIds.length);
        this.selectedNodeIds.forEach((item) => {
          nodeidsToDelete.push(parseInt(item.nodeId.low));
          item.symbol && symbolsToDelete.push(item.symbol);
        });
      }
      else {
        nodeidsToDelete.push(parseInt(data.nodeIds));
        this.contextNode && this.contextNode.symbol && symbolsToDelete.push(this.contextNode.symbol);
        if (_currentAction !== ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES){
          _state.alertMessage = `${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(data.nodeName, self.nodeType), data.nodeName)} ${LocalizationStore.getTranslatedData('list_deleted', 'was deleted')}.`;
        }
        else
            _state.alertMessage = `${LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(data.nodeName, self.nodeType), data.nodeName)} was removed from FAVORITES.`;
      }

      // If deleted node id's exists in favorites remove node id's from favorites section
      const nodeList = [];

      const nodedet = this.findSourceNode(data.nodeIds,"ONEIL");

      if (nodedet.listType === ListType.FOLDER_List) {

        _.clone(this.flatternDataSource(nodedet.childNodes, flatternList));

        if (flatternList.length > 0) {
          flatternList.forEach((item) => {
            nodeidsToDelete.push(parseInt(item.nodeId.low));
            item.symbol && symbolsToDelete.push(item.symbol);
          });

        }
      }

      if (nodeidsToDelete.length > 0){
        nodeidsToDelete.forEach((nodeId) => { this.findNodeInFavorites(nodeId, nodeList); });
      }

      if (nodeList.length > 0){
        nodeList.forEach((item) => { nodeidsToDelete.push(item.nodeId.low) });
      }
      _state.deleteNodeId = nodeidsToDelete.map((item) => item).join("; ")

      if (symbolsToDelete.length > 0) {
        symbolsToDelete.forEach((entry) => {
          dataSource = _.filter(dataSource, (item) => (item.symbol !== entry));
          this.oNeilDatasource = dataSource;
          this.updateDataSource(dataSource, true);
        });
      }
      if (nodeidsToDelete.length > 0 && this.contextNode && (this.contextNode.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory || this.contextNode.categoryType == ListCategoryType.TEMPORARYLIST_ListCategory)) {
        nodeidsToDelete.map((nodeId) => {
          dataSource = _.filter(dataSource, (item) => (item.nodeId.low !== nodeId && item.categoryType  == ListCategoryType.NOTIMPORTEDLIST_ListCategory) || item.categoryType !== ListCategoryType.NOTIMPORTEDLIST_ListCategory);
          this.oNeilDatasource = dataSource;
          this.updateDataSource(dataSource, true);
        });
      }
      else{
        dataSource = self.updateListExplorer(dataSource, '', nodeidsToDelete, true);
      }
      if(this.contextNode.isUnShared){ 
        _currentAction = ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE
        _state.alertMessage = LocalizationStore.getTranslatedData("lp_mes_16", "The list accessed is not available.");
      }
      self.emit(CHANGE_EVENT);
  }

  // Method to check whether List/Folder delete allowed or not.
  isDeleteAllowed(categoryType, folderCategoryType) {
    if (categoryType == ListCategoryType.USER_ListCategory || categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory || categoryType == ListCategoryType.FAVORITE_ListCategory || categoryType == ListCategoryType.SHAREDLIST_ListCategory || categoryType == ListCategoryType.TEMPORARYLIST_ListCategory || categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory || categoryType == ListCategoryType.PORTFOLIOLIST_ListCategory || categoryType == ListCategoryType.POSITIONHISTORYLIST_ListCategory) {
      return true;
    }
    if (folderCategoryType == ListCategoryType.USER_ListCategory || categoryType == ListCategoryType.FAVORITE_ListCategory || categoryType == ListCategoryType.SHAREDLIST_ListCategory ) {
      return true;
    }
    return false;
  }
  // Method to check whether List/Folder alert allowed or not.
  isAlertAllowed(categoryType) {
    if (categoryType == ListCategoryType.PRESET_ListCategory || categoryType == ListCategoryType.USER_ListCategory || categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory || categoryType == ListCategoryType.APLIST_ListCategory  || categoryType == ListCategoryType.PORTFOLIOLIST_ListCategory) {
      return true;
    }
    return false;
  }

  // Method to find next active list on deleting List/Folder.
  getNextAvailableList(nodeIdToDelete, rootParentId, parentId, listType, dataSource) {
    const nextAvailableIndexList = [];
    const flatternList = [];
    let nextItem = null;
    let index = null;
    const symbol = this.contextNode.symbol;
    const nodeId = typeof (this.contextNode.nodeId) === 'object' ? this.contextNode.nodeId.low : this.contextNode.nodeId;
    const nodeidsToDelete = [];
    const symbolToDelete = [];
    if (!StringUtil.isEmpty(nodeIdToDelete) && parseInt(listType) == ListType.LIST_List) {
      if (symbol || (this.contextNode.categoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory && symbol == null)) {
        this.flatternDataSource(dataSource, flatternList);
        if (this.selectedNodeIds.length > 0) {
          this.selectedNodeIds.map((item) => {
            nodeidsToDelete.push(parseInt(item.nodeId.low));
            symbolToDelete.push(item.symbol);
          });
        }
        else {
          nodeidsToDelete.push(nodeId);
          symbolToDelete.push(symbol);
        }  

        const nodeList = [];
        index = _.findIndex(flatternList, (item) => (item.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && item.symbol === symbol) || (item.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && item.nodeId.low === nodeId) );
        const templList = _.filter(flatternList, (item, key) => key > index &&((item.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && !(symbolToDelete.includes(item.symbol))) || (item.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory && !(nodeidsToDelete.includes(item.nodeId.low)))));
        if (templList.length > 0) {
          this.activeNode = nextItem = templList[0];
          this.activeSybol = {
            symbol: templList[0].symbol,
            msId: templList[0].msid
          };
        }
        else {
          this.expandAndFindParentNode(panarayDefault?.defaultListID, nodeList);
          nextItem = nodeList[0];
          this.activeNode = nodeList[0];
        }
      }
      else {
        const index = _.findIndex(dataSource, (item) => item.nodeId.low == nodeIdToDelete);
        if (index > -1) {
          for (let i = index; i <= dataSource.length; i++) {
            const nodeItem = dataSource[i];
            if(this.isNodeModelList(nodeItem)){
              continue;
            }
            if(this.isMorgueList(nodeItem) || (this.isFavoriteList(nodeItem) && !StringUtil.isEmpty(nodeItem) && nodeItem.sourceCategoryType == ListCategoryType.MORGUELIST_ListCategory)){
              continue;
            }
            if(nodeItem && !nodeItem.isVisible){//Filter out the snapshot list
              continue;
            }
            nextAvailableIndexList.push(i);
          }
          nextAvailableIndexList.splice(0, 1);
          /* eslint-disable */
          let nextItemList = _.reject(dataSource, (item, key) => {
            if (!nextAvailableIndexList.contains(key)) {
              return true;
            };
          });
          /* eslint-enable */
          for (let i = 0; i < nextItemList.length; i++) {
            nextItem = nextItemList[i];

            if (nextItem.listType == ListType.LIST_List) {
              break;
            }
            if (!StringUtil.isEmpty(nextItem.childNodes) && nextItem.childNodes.length > 0) {
              nextItem = this.ExpandItemLevelAndGetItem(nextItem);
              if (!StringUtil.isEmpty(nextItem))
                break;
            }
          }
          this.activeNode = nextItem;
        }
      }
    }
    else if (!StringUtil.isEmpty(nodeIdToDelete) && parseInt(listType) == ListType.FOLDER_List) {
      // let index = _.findIndex(dataSource, function (item) { return item.nodeId.low == nodeIdToDelete });

      // let item = dataSource[index + 1];

      // if (!StringUtil.isEmpty(item)) {
      //   console.log('settings...', test)
      //   if (item.listType == ListType.FOLDER_List) {
      //     nextItem = item;
      //   }
      // }
      const tabONeilSettings = new TabONeilSettings();
      const selectedListInfo = tabONeilSettings.getDefaultSelectedListInfo();
      // let index = _.findIndex(dataSource, function (item) { return item.nodeId.low == parseInt(selectedListInfo.SelectedListId) });
      // nextItem = dataSource[index];
      const tabType = this.getSelectedTabType();
      switch (tabType) {
        case ListManagerTabType.Oneil:
          ListStore.updateSelectedListItem(selectedListInfo.SelectedListId, selectedListInfo.SelectedActualListId, selectedListInfo.SelectedListName);
          break;
        default:
          break;
      }
      SettingsStore.saveSettings();
    }
    return nextItem;
  }

  // Expand and get list item to be selected.
  ExpandItemLevelAndGetItem(itemToExpand) {
    let nextItem = null;
    if (!StringUtil.isEmpty(itemToExpand) && itemToExpand.childNodes.length > 0) {

      if (!StringUtil.isEmpty(itemToExpand.childNodes) && itemToExpand.childNodes.length > 0) {
        const childItems = itemToExpand.childNodes;
        //if (itemToExpand.listType == ListType.CATEGORY_List)
        this.ExpandItemLevel(itemToExpand.categoryType, itemToExpand.nodeId.low);

        for (let i = 0; i < childItems.length; i += 1) {
          nextItem = childItems[i].listType == ListType.LIST_List ? childItems[i] : this.ExpandItemLevelAndGetItem(childItems[i]);
          if (!StringUtil.isEmpty(nextItem)) break;
        }
      }
    }
    return nextItem;
  }

  // Method to flattern the list explorer datasource.
  flatternDataSource(dataSource, flatternList) {
    if (!StringUtil.isEmpty(dataSource) && dataSource.length > 0) {
      dataSource.map((entry) => {
        if (entry !== undefined && entry.childNodes.length > 0) {
          flatternList.push(entry);
          this.flatternDataSource(entry.childNodes, flatternList);
        }
        else {
          flatternList.push(entry);
        }
      });
    }
  }

  // Api call for Undo action.
  restoreNode(data) {
    const self = this;
    ListApi.restoreTreeNode(data.nodeIds).then((result) => {
      if (!result.responseHeader.error) {
        this.restoreNodeId = data.nodeIds.toString().split(';')[0];
        self.updateDataSource(self.sortOneilData(self.deleteDataSource), false);

        //PANWEB-934
        const symbol = this.contextNode.symbol ? this.contextNode.symbol : undefined;
        if (symbol !== undefined || (this.contextNode.categoryType && this.contextNode.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory)) {
          this.activeNode = this.contextNode;
          this.activeSybol = {
            symbol: symbol,
            msId: this.contextNode.msid
          };
        }

        _state.deleteNodeId = null;
        this.oNeilDatasource = self.getDataSource();
        self.emit(CHANGE_EVENT);

        if (ListStore.getState().SelectedTabKey === ListManagerTabType.External || ListStore.getState().SelectedTabKey === ListManagerTabType.Ownership) {
          BrowserUtil.enableEvents();
        }
      }
    });
  }

  // Emit change on show original action.
  showOriginal() {
    this.emit(CHANGE_EVENT);
  }

  // Get the list items to be copied by eliminating duplicates.
  findListItemsToCopy(sourceList, targetList) {
    const self = this;
    let result = [];
    if (sourceList.length > 0) {
      const dataSource = self.getDataSource();
      targetList = _.find(dataSource, (item) => (item.categoryType === ListCategoryType.FAVORITE_ListCategory));
      sourceList.map((item) => {
        if (!StringUtil.isEmpty(targetList)) {
          if (targetList.childNodes.length > 0) {
            const favNode = self.getListInFavorite(targetList.childNodes, item.nodeId.low);
            if (!StringUtil.isEmpty(favNode))
              result.push(item);
          }
        }
      });
    }

    this.duplicateListCount = result.length;
    result = sourceList.filter((item) => !result.includes(item));
    return result;
  }

  // Find Node by Id in category and update date and datasource
  findNodeByIdAndUpdateDate(nodeId, asOfDate, updateDesc = "") {
    const self = this;
    let searchValue = null;
    const dataSource = self.getDataSource();
    const filteredDataSource = _.filter(dataSource, (item) =>
      item.categoryType == parseInt(ListCategoryType.USER_ListCategory) || item.categoryType == parseInt(ListCategoryType.SCHEDULESCREENLIST_ListCategory) || item.categoryType == parseInt(ListCategoryType.FAVORITE_ListCategory) || item.categoryType == parseInt(ListCategoryType.PORTFOLIOLIST_ListCategory) || item.categoryType == parseInt(ListCategoryType.SHAREDLIST_ListCategory) || item.categoryType == parseInt(ListCategoryType.SMARTLIST_ListCategory)
    );
    if (!StringUtil.isEmpty(filteredDataSource) && filteredDataSource.length > 0) {
      filteredDataSource.map((item) => {

        if (item.categoryType == parseInt(ListCategoryType.USER_ListCategory) || item.categoryType == parseInt(ListCategoryType.SCHEDULESCREENLIST_ListCategory) || item.categoryType == parseInt(ListCategoryType.SHAREDLIST_ListCategory) || item.categoryType == parseInt(ListCategoryType.PORTFOLIOLIST_ListCategory) || item.categoryType == parseInt(ListCategoryType.SMARTLIST_ListCategory) || item.categoryType == parseInt(ListCategoryType.FAVORITE_ListCategory)) {
          searchValue = self.findNodeById(nodeId, item);
        }
        const listDesc = updateDesc ? updateDesc : searchValue.description;
        if (searchValue !== false)
          _.extend(searchValue, { updateDt: asOfDate, description: listDesc });
      });
      this.updateDataSource(dataSource, false);
    }
  }


  renameListNode(entry,newNode){
    entry = _.find(entry.childNodes, (item) => {
      if (!StringUtil.isEmpty(item.sourceListId)) {
        if (item.sourceListId.low == newNode.nodeId.low)
       return true;
      }
      if (item.nodeId.low == newNode.nodeId.low){
      return true;
      }

      this.renameListNode(item,newNode);
    }
    );
    _.extend(entry, { name: newNode.name, updateDt: newNode.updateDt });
   }

  // Update List explorer datasource on every action in list explorer.
  updateListExplorer(dataSource, newNode, parentNodeId, isDelete = false, isRename = false) {
    let nodeIds = [];
    if (!Array.isArray(parentNodeId))
      nodeIds.push(parseInt(parentNodeId));
    else
      nodeIds = parentNodeId;

    dataSource.map((entry) => {
      if (entry.nodeId && _.contains(nodeIds, entry.nodeId.low)) {
        _parentNodeTitle = entry.name;
        _parentListType = entry.listType;
        if (isRename) {
          if (!StringUtil.isEmpty(entry.childNodes) && entry.childNodes.length > 0) {
            this.renameListNode(entry,newNode)
           }
        }
        else {
          //Updating ListCategory property
          _.extend(newNode, { listCategory: this.convertToListMgrListCategory(newNode.categoryType) });
          //Setting tree item level for List/Folder
          const parentNode = this.getValidParentItem(newNode.listCategory);
          _.extend(newNode, { listTreeItemLevel: 0, isVisible: true });

          if (!StringUtil.isEmpty(newNode.parentNodeId)) {
            _.extend(newNode, { listTreeItemLevel: parentNode.listTreeItemLevel + 1 });
          }
          _.extend(newNode, { parentCategoryType: entry.categoryType, parentCategoryName: entry.name, parentListType: entry.listType });
          entry.childNodes.push(newNode);
        }
      }
      else
        return this.nestedTree(entry, newNode, nodeIds, isDelete, isRename);
    });
    return dataSource;
  }

  isNodeModelList(entry){
    let actualCategoryType = null;
    if (!StringUtil.isEmpty(entry)){
      actualCategoryType = entry.categoryType == ListCategoryType.FAVORITE_ListCategory ? entry.sourceCategoryType : entry.categoryType;
    }
    return actualCategoryType === ListCategoryType.MODELLIST_ListCategory;
  }

  // Update List explorer datasource on every action in list explorer.
  nestedTree(dataSource, newNode, parentNodeId, isDelete, isRename) {
    const childs = dataSource.childNodes;
    const nodeidsToDelete = [];

    childs.map((entry) => {
      if (entry.nodeId && (_.contains(parentNodeId, entry.nodeId.low)||entry.sourceListId && _.contains(parentNodeId, entry.sourceListId.low))) {
        if (!isDelete) {
          _parentNodeTitle = entry.name;
          _parentListType = entry.listType;
          if (isRename) {
            if (!StringUtil.isEmpty(entry.childNodes) && entry.childNodes.length > 0) {
              entry = _.find(entry.childNodes, (item) => (item.nodeId.low === newNode.nodeId.low));
              _.extend(entry, { name: newNode.name, updateDt: newNode.updateDt });
            }
          }
          else {
            //Updating ListCategory property
            _.extend(newNode, { listCategory: this.convertToListMgrListCategory(newNode.categoryType) });
            //Setting tree item level for List/Folder
            const parentNode = this.getValidParentItem(newNode.listCategory);
            _.extend(newNode, { listTreeItemLevel: 0, isVisible: true });

            _.extend(newNode, { parentCategoryType: dataSource.categoryType, parentCategoryName: dataSource.name, parentListType: dataSource.listType });

            if (!StringUtil.isEmpty(newNode.parentNodeId)) {
              _.extend(newNode, { listTreeItemLevel: parentNode.listTreeItemLevel + 1 });
            }
            entry.childNodes.push(newNode);
          }
        }
        else {
          nodeidsToDelete.push(childs.indexOf(entry));
        }
      }
      else if (entry.childNodes !== undefined && entry.childNodes.length > 0)
        return this.nestedTree(entry, newNode, parentNodeId, isDelete, isRename);
    })
    if (isDelete) {
      if (nodeidsToDelete.length > 0)
        dataSource.childNodes = _.reject(childs, (item, key) => {
          if (nodeidsToDelete.contains(key)) return true;
        });
    }
  }

  // Update List explorer datasource while saving alerts
  updateListExplorerForAlerts(nodeId, alertStatusType, conditionTypeId) {
    let self = this;
    let dataSource = self.getDataSource();

    if (!StringUtil.isEmpty(dataSource)) {
      dataSource.map((entry) => this.nestedTreeForAlerts(entry, nodeId, alertStatusType, conditionTypeId));
    }
  }

  // Update List explorer datasource while saving alerts
  nestedTreeForAlerts(dataSource, nodeId, alertStatusType,conditionTypeId) {
    let childs = dataSource.childNodes;

    childs.map((entry) => {
      if(entry.childNodes.length > 0){
        this.nestedTreeForAlerts(entry, nodeId, alertStatusType,conditionTypeId);
      }
      else{
        if (entry.nodeId && entry.nodeId.low == nodeId) {
          if(alertStatusType != 0 && alertStatusType != 2){
            if(entry.alertType == null)
              entry.alertType = conditionTypeId;
            else if(entry.alertType != conditionTypeId)
              entry.alertType = 3;
          }else if(alertStatusType != 2){
            if(conditionTypeId == AlertConditionType.And){
              entry.alertType = entry.alertType == 3 ? AlertConditionType.Or : null
            }
            else{
              entry.alertType = entry.alertType == 3 ? AlertConditionType.And : null
            }
          }
          if((alertStatusType == ListAlertStatusType.Active && !entry.alertStatusType) || alertStatusType != ListAlertStatusType.Active){
            if(alertStatusType == 0 && entry.alertType != null){ return; }
            _.extend(entry, { alertStatusType: alertStatusType });
          }
        }
        else if (entry.childNodes !== undefined && entry.childNodes.length > 0)
          return this.nestedTreeForAlerts(entry, nodeId, alertStatusType);
      }
    })
  }

  // Filter list explorer datasource by category type.
  nodesToSearch(categoryType) {
    let dataSource = [];
    dataSource = this.getDataSource();
    return _.filter(dataSource, (item) => (item.categoryType === parseInt(categoryType)));
  }

  //Find the nodeId is category or not
  isCategoryList(nodeId) {
    let dataSource = [];
    dataSource = this.getDataSource();
    return _.some(dataSource, (item) => (item.nodeId.low == nodeId));
  }

  //Get listCategory by category type
  convertToListMgrListCategory(categoryType) {
    let categoryName;
    switch (categoryType) {
      case ListCategoryType.FAVORITE_ListCategory:
        categoryName = ListManagerListCategory.FAVORITE_ListCategory;
        break;
      case ListCategoryType.USER_ListCategory:
        categoryName = ListManagerListCategory.USER_ListCategory;
        break;
      case ListCategoryType.DATABASELIST_ListCategory:
        categoryName = ListManagerListCategory.DATABASELIST_ListCategory;
        break;
      case ListCategoryType.APLIST_ListCategory:
        categoryName = ListManagerListCategory.APLIST_ListCategory;
        break;
      case ListCategoryType.PORTFOLIOLIST_ListCategory:
        categoryName = ListManagerListCategory.PORTFOLIOLIST_ListCategory;
        break;
      case ListCategoryType.VARIABLELIST_ListCategory:
        categoryName = ListManagerListCategory.VARIABLELIST_ListCategory;
        break;
      case ListCategoryType.THEMELIST_ListCategory:
        categoryName = ListManagerListCategory.THEMELIST_ListCategory;
        break;
      case ListCategoryType.POSITIONHISTORYLIST_ListCategory:
        categoryName = ListManagerListCategory.POSITIONHISTORYLIST_ListCategory;
        break;
      case ListCategoryType.TRANSACTIONSLIST_ListCategory:
        categoryName = ListManagerListCategory.TRANSACTIONSLIST_ListCategory;
        break;
      case ListCategoryType.SHAREDLIST_ListCategory:
        categoryName = ListManagerListCategory.SHAREDLIST_ListCategory;
        break;
      case ListCategoryType.SMARTLIST_ListCategory:
        categoryName = ListManagerListCategory.SMARTLIST_ListCategory;
        break;
      case ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory:
        categoryName = ListManagerListCategory.FUTURESCASHSPOTSLIST_ListCategory;
        break;
      default: // preset list
        categoryName = ListManagerListCategory.PRESET_ListCategory;
        break;
    }
    return categoryName;
  }

  // Method to check drag and drop allowed or not.
  canAcceptListDrop(categoryType, parentNodeId = null, movingParentNodeToChild = null, draggedElementCategoryType = null) {
    if(movingParentNodeToChild){
      return false;
    }
    if (draggedElementCategoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
      return false;
    }
    if (draggedElementCategoryType === ListCategoryType.TEMPORARYLIST_ListCategory)
      return (categoryType == ListCategoryType.USER_ListCategory && parentNodeId === null);
    else
      return (categoryType == ListCategoryType.FAVORITE_ListCategory || categoryType == ListCategoryType.USER_ListCategory || categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory || categoryType == ListCategoryType.SHAREDLIST_ListCategory);
  }

  // Get parent node id while adding List/Folder from list explorer bottom bar.
  getValidParentItem(listCategory) {
    const parentNode = this.selectedFolder;
    if (!StringUtil.isEmpty(parentNode)) {
      if ((parentNode.listCategory == ListManagerListCategory.USER_ListCategory || parentNode.listCategory == ListManagerListCategory.SHAREDLIST_ListCategory
        || parentNode.listCategory == ListManagerListCategory.FAVORITE_ListCategory)
        && parentNode.listType == ListType.FOLDER_List && this.canAcceptListDrop(parentNode.categoryType) && parentNode.listCategory == listCategory) {
        return parentNode;
      }
      else {
        return this.findParentItemByListCategory(listCategory);
      }
    }
    else {
      return this.findParentItemByListCategory(listCategory);
    }
  }

  findParentItemByListCategory(listCategory) {
    const dataSource = this.getDataSource();
    return _.find(dataSource, (item) => (item.listCategory == listCategory));
  }

  // Validation method to check folder limit.
  folderLimitReached(parentNode) {
    let isFolderLimitReached = false;
    if (!StringUtil.isEmpty(parentNode))
      isFolderLimitReached = parentNode.listTreeItemLevel >= 5 ? true : false;

    return isFolderLimitReached;
  }

  // Get DB type by selected tab type.
  getSelectedDBType() {
    const tabType = this.getSelectedTabType();
    let dbType = null;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        dbType = 1;
        break;
      case ListManagerTabType.Ownership:
        dbType = 2;
        break;
      default:
        break;
    }
    return dbType;
  }

  // Get selected tab type.
  getSelectedTabType() {
    const NavListManagerSettings = ListStore.getNavListManagerSettings();
    return ListStore.getTabType(NavListManagerSettings.SelectedTabKey);
  }

  // Get cacheId by selected tab type.
  getSelectedCacheId() {
    const tabType = this.getSelectedTabType();
    let cacheId = null;
    switch (tabType) {
      case ListManagerTabType.Oneil:
        {
          const oNeilViewState = ONeilViewStore.getState();
          cacheId = oNeilViewState.cacheId;
        }
        break;
      default:
        break;
    }
    return cacheId;
  }

  // Save Screened Results
  saveScreenedResults() {
    let nodeName = null;
    let nodeId = typeof (this.activeNode.nodeId) === 'object' ? this.activeNode.nodeId.low : this.activeNode.nodeId;
    if (this.activeNode.categoryType == ListCategoryType.FAVORITE_ListCategory) {
      nodeId = typeof (this.activeNode.sourceListId) === 'object' ? this.activeNode.sourceListId.low : this.activeNode.sourceListId;
    }
    const rootParentNodeId = ListCategoryType.USER_ListCategory;

    nodeName = this.validateListName(this.activeNode.name, rootParentNodeId);
    nodeName = LocalizationStore.getTranslatedData(this.getListTreeItemNameResKey(this.activeNode.name, this.activeNode.listType), nodeName);
    const cacheId = this.getSelectedCacheId();
    const isSaveScreenedResults = true;
    this.duplicateItem(nodeId, nodeName, rootParentNodeId, cacheId, isSaveScreenedResults);
  }

  //Find active node from List Explorer
  getActiveNode(nodeId) {
    const listExplorer = document.querySelector("div[class='listExplorerClass']");
    if (!StringUtil.isEmpty(listExplorer)) {
      const activeNodeId = `a[data-node-id='${  nodeId  }']`;
      const elm = listExplorer.querySelector(activeNodeId);

      if (!StringUtil.isEmpty(elm))
        return JSON.parse(elm.getAttribute('data-selectednode'));
    }
  }

  //Find active node from List Explorer using symbol
  getActiveNodeUsingSymbol(symbol) {
    const listExplorer = document.querySelector("div[class='listExplorerClass']");
    if (!StringUtil.isEmpty(listExplorer)) {
      const activeSymbol = `a[data-symbol='${  symbol  }']`;
      const elm = listExplorer.querySelector(activeSymbol);

      if (!StringUtil.isEmpty(elm))
        return JSON.parse(elm.getAttribute('data-selectednode'));
    }
  }

  getEligibleLists() {
    if (eligibleListIDsForAlerts.length > 0) {
      return eligibleListIDsForAlerts;
    }
    else {
      ListApi.getEligibleLists().then((result) => {

        const validListAlertData = result.validListAlertData;

        if (!result.responseHeader.error) {
          _.each(validListAlertData, (item) => {
            eligibleListIDsForAlerts.push(item.nodeId);
          });
        }
        else {
          ConsoleActions.exceptionHandle();
        }
      });
    }
  }

  isXflList(listTreeItem = this.activeNode) {
    return (!StringUtil.isEmpty(listTreeItem) && !StringUtil.isEmpty(listTreeItem.parentNodeId) && (listTreeItem.parentCategoryType == ListCategoryType.PRESET_ListCategory && !StringUtil.isEmpty(listTreeItem.parentCategoryName) &&
      listTreeItem.parentCategoryName.toUpperCase() == "EXCLUSIVE LISTS"));
  }

  isApList(listTreeItem = this.activeNode) {
    return (!StringUtil.isEmpty(listTreeItem) && !StringUtil.isEmpty(listTreeItem.parentNodeId) && (listTreeItem.parentCategoryType == ListCategoryType.APLIST_ListCategory && !StringUtil.isEmpty(listTreeItem.parentCategoryName) &&
      listTreeItem.parentCategoryName.toUpperCase() == "AP LISTS"));
  }

  isAnnoationList(listTreeItem = this.activeNode) {

    return (!StringUtil.isEmpty(listTreeItem) && !StringUtil.isEmpty(listTreeItem.parentNodeId) && listTreeItem.parentCategoryName == "Annotations Layers" && listTreeItem.parentListType == ListType.FOLDER_List);
  }

  isSnapshotList() { //listTreeItem = this.activeNode
    return false;
    //return (!StringUtil.isEmpty(listTreeItem) && ((listTreeItem.categoryType == ListCategoryType.SNAPSHOTLIST_ListCategory) || (listTreeItem.sourceCategoryType == ListCategoryType.SNAPSHOTLIST_ListCategory) || (
    //(!StringUtil.isEmpty(listTreeItem.parentNodeId) && listTreeItem.parentCategoryType == ListCategoryType.SNAPSHOTLIST_ListCategory))));
  }

  isPortfolioList(listTreeItem = this.activeNode) {
    return !StringUtil.isEmpty(listTreeItem) && (!StringUtil.isEmpty(listTreeItem.parentNodeId) && (listTreeItem.parentCategoryType == ListCategoryType.PORTFOLIOLIST_ListCategory || listTreeItem.parentCategoryType == ListCategoryType.POSITIONHISTORYLIST_ListCategory));
  }

  isGflList(listTreeItem = this.activeNode) {
    return (!StringUtil.isEmpty(listTreeItem) && !StringUtil.isEmpty(listTreeItem.parentNodeId) && listTreeItem.parentCategoryType == ListCategoryType.PRESET_ListCategory && listTreeItem.parentCategoryName.toUpperCase() == "GLOBAL FOCUS RESEARCH");
  }

  isRussellList(listTreeItem = this.activeNode) {
    return (!StringUtil.isEmpty(listTreeItem.parentNodeId) &&
      listTreeItem.parentNodeId == 125 && listTreeItem.parentCategoryName == "Russell");
  }

  isFutureListorFavoriteUserList(listTreeItem = this.activeNode) {
    let parentCategoryType = null;
    if (!StringUtil.isEmpty(listTreeItem)) {
      parentCategoryType = listTreeItem.categoryType == ListCategoryType.FAVORITE_ListCategory ? listTreeItem.sourceCategoryType : this.activeNode.parentCategoryType;

      return (listTreeItem.categoryType == ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory
        || (listTreeItem.categoryType == ListCategoryType.FAVORITE_ListCategory &&
          parentCategoryType == ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory));
    }
    else {
      return false;
    }
  }

  isFlaggedList(listTreeItem = this.activeNode) {
    if (!StringUtil.isEmpty(listTreeItem))
      return (listTreeItem.categoryType == ListCategoryType.SMARTLIST_ListCategory && (listTreeItem.name.includes("Flagged")));
    else return false;
  }
  isFlaggedOwnerList(listTreeItem) {
    if (!StringUtil.isEmpty(listTreeItem))
      return (listTreeItem.categoryType == ListCategoryType.SMARTLIST_ListCategory && (listTreeItem.name.includes("Flagged")));
    else return false;
  }
  isUserListOrFavoriteUserList(listTreeItem = this.activeNode) {
    let parentCategoryType = null;
    if (!StringUtil.isEmpty(listTreeItem))
      parentCategoryType = listTreeItem.categoryType == ListCategoryType.FAVORITE_ListCategory ? listTreeItem.sourceCategoryType : this.activeNode.parentCategoryType;
    else
      return;

    return (listTreeItem.categoryType == ListCategoryType.USER_ListCategory || listTreeItem.categoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory
      || (listTreeItem.categoryType == ListCategoryType.FAVORITE_ListCategory &&
        (parentCategoryType == ListCategoryType.USER_ListCategory || parentCategoryType == ListCategoryType.SCHEDULESCREENLIST_ListCategory)));
  }

  isTempList(listTreeItem = this.activeNode) {
    if (!StringUtil.isEmpty(listTreeItem))
      return listTreeItem.categoryType == ListCategoryType.TEMPORARYLIST_ListCategory;
    else return false;
  }

  isModelList(listTreeItem = this.activeNode) {
    if (!StringUtil.isEmpty(listTreeItem))
      return listTreeItem.categoryType == ListCategoryType.MODELLIST_ListCategory;
    else return false;
  }

  isMorgueList(listTreeItem = this.activeNode) {
    if (!StringUtil.isEmpty(listTreeItem))
      return listTreeItem.categoryType == ListCategoryType.MORGUELIST_ListCategory;
    else return false;
  }

  isFavoriteList(listTreeItem = this.activeNode) {
    if (!StringUtil.isEmpty(listTreeItem))
      return listTreeItem.categoryType == ListCategoryType.FAVORITE_ListCategory;
  }

  getParentNodeDescription() {
    const self = this;
    let parentNode = null;
    const nodesToSearch = self.activeNode != undefined ?  self.nodesToSearch(self.activeNode.parentCategoryType): '';
    if (!StringUtil.isEmpty(nodesToSearch)) {
      for (let i = 0; i < nodesToSearch.length; i++) {
        parentNode = self.findNodeById(self.activeNode.parentNodeId.low, nodesToSearch[i]);
        if (parentNode != false) return parentNode.description;
      }
    }
  }

  getOneilOwnershipActiveNode(nodeId) {
    const listExplorer = document.querySelector("div.listExplorerDbClass");
    if (!StringUtil.isEmpty(listExplorer)) {
      const activeNodeId = `a[data-node-id='${  nodeId  }']`;
      const elm = listExplorer.querySelector(activeNodeId);

      if (!StringUtil.isEmpty(elm))
        return JSON.parse(elm.getAttribute('data-selectednode'));
    }
    else {
      const obj = this.findOneilOwnershipNodeById(nodeId);
      const selectedNode = {};
      selectedNode.nodeId = obj.nodeId.low;
      selectedNode.parentNodeId = obj.parentNodeId;
      selectedNode.rootParentNodeId = null;
      selectedNode.sourceListId = null;
      selectedNode.categoryType = obj.categoryType;
      selectedNode.name = obj.name;
      selectedNode.listType = obj.listType;
      selectedNode.className = null;
      selectedNode.listCategory = obj.listCategory;
      selectedNode.listTreeItemLevel = obj.listTreeItemLevel;
      selectedNode.sourceCategoryType = obj.sourceCategoryType;
      return selectedNode;
    }
  }

  //Validating List/Folder name
  validateListName(nodeName, parentNodeId, action = null, listType = null) {
    let newName = nodeName;
    let count = 0;
    const nodesToSearch = this.getCurrentNode(parentNodeId);
    const nodeId = listType === ListType.FOLDER_List ? (this.selectedFolder ? this.selectedFolder.nodeId : null) : typeof (this.activeNode.nodeId) === 'object' ? this.activeNode.nodeId.low : this.activeNode.nodeId;

    if (!StringUtil.isEmpty(nodesToSearch)) {
      let duplicateNamedItem = this.findDuplicateList(nodesToSearch, nodeName, false, listType);

      if (action === ContextMenuConstants.RENAME && duplicateNamedItem) {
        _.find(nodesToSearch, (item) => {
          if (item.nodeId.low === nodeId)
            item.name = nodeName;
        });
      }

      while (duplicateNamedItem !== null && duplicateNamedItem !== undefined) {
        newName = nodeName.concat(` (${  ++count  })`);
        duplicateNamedItem = this.findDuplicateList(nodesToSearch, newName, false, listType);
      };

    }
    return newName;
  }

  setActiveNodeFromSelectedList(tabKey){
    this.activeNode = ListStore.getSelectedListItem(tabKey);
  }

  validateExternalListName(nodesToSearch,nodeName){
    let newName = nodeName;
    let count = 0;
    if (!StringUtil.isEmpty(nodesToSearch)) {
      let duplicateNamedItem = this.findDuplicateList(nodesToSearch, nodeName, true);
    while (duplicateNamedItem !== null && duplicateNamedItem !== undefined) {
      newName = nodeName.concat(" (" + ++count + ")");
      duplicateNamedItem = this.findDuplicateList(nodesToSearch, newName, true);
    };
  }
  return newName;
  }

  findDuplicateList(nodesToSearch, nodeName, isExternal=false, listType = ListType.LIST_List) {
    if (!StringUtil.isEmpty(nodesToSearch))
      return _.find(nodesToSearch, (item) => {
        if (!StringUtil.isEmpty(item)) {
            return isExternal ?item.name.toLowerCase() == nodeName.toLowerCase(): (item.name == nodeName && item.listType == listType);
        }
      });
  }

  getCurrentNode(nodeId) {
    const ParentListTreeItem = [];
    let currentNode = [];
    this.findParentNode(nodeId, ParentListTreeItem);
    if (ParentListTreeItem.length > 0) {
      currentNode = ParentListTreeItem[0].childNodes.length > 0 ? ParentListTreeItem[0].childNodes : null;
    }
    return currentNode;
  }

  getListTreeItemNameResKey(listTreeItemName, listType) {
    let listTypeStr = 'UNKNOWN';
    switch (listType) {
      case ListType.LIST_List:
        listTypeStr = 'LST';
        break;
      case ListType.FOLDER_List:
        listTypeStr = 'FOL';
        break;
      case ListType.CATEGORY_List:
        listTypeStr = 'CAT';
        listTreeItemName = listTreeItemName.toUpperCase();
        break;
      default:
        break;
    }
    return `TreeExplorer_${  listTypeStr  }_${  listTreeItemName.replace(new RegExp(' ', 'g'), '')}`;
  }

  checkTempListExisit(nodeSymbol) {
    let node = [];
    node = _.filter(this.oNeilDatasource, (item) => item.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && item.symbol === nodeSymbol);

    return node;
  }

  checkTempListName(nodeName) {
    let node = [];
    node = _.filter(this.oNeilDatasource, (item) => item.categoryType === ListCategoryType.TEMPORARYLIST_ListCategory && item.name === nodeName);
    return node[0].symbol;
  }

  async updateExplorer() {
    await this.getListExplorerData();
    await this.getEligibleLists();
    return true;
  }

  routeToList(node, symbol, view) {
    if (view === SmartViewType.OWNERSHIP && node.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) {
      _state.listExplorer.Ownership.push(node);
      this.setAttribute(_state.listExplorer.Ownership, 'OWNERSHIP');
      _state.listExplorer.Ownership = this.sortOwnerShipData(_state.listExplorer.Ownership);
      dispatch(sourceList(_state.listExplorer.Ownership));
      node['rootParentNodeId'] = node.nodeId.low;
      this.activeSybol = {
        symbol: node.symbol,
        msId: node.msid
      };
      OwnershipViewStore.activeNode = node;
      ListStore.updateSelectedListItemForTempList(
        node.nodeId.low,
        node.nodeId.low,
        node.name,
        node.categoryType,
        null,
        null,
        null,
        null,
        node.sourceCategoryType,
        null,
        node.spId
      );
      return;
    }
    this.oNeilDatasource.push(node);
    this.setAttribute(this.oNeilDatasource, 'ONEIL');
    _state.listExplorer.ONeil = this.sortOneilData(this.oNeilDatasource);
    node['rootParentNodeId'] = node.nodeId.low;
    this.activeSybol = {
      symbol: node.symbol,
      msId: node.msid
    };
    this.activeNode = node;
    ListStore.updateSelectedListItemForTempList(
      node.nodeId.low,
      node.nodeId.low,
      node.name,
      node.categoryType,
      null,
      null,
      null,
      null,
      node.sourceCategoryType,
      null,
      node.spId
    );
    if (node.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory) return;
    if (view === SmartViewType.OWNERSHIP) {
      SettingsAction.updateListExplorerTabSelection('ONEIL');

      if(StringUtil.isEmpty(node.msid) && !StringUtil.isEmpty(node.spId))
        ConsoleStore.UpdateRout(symbol, NavType.NavLists)
    }
    else {
      SettingsAction.updateListExplorerTabSelection('ONEIL');
      ConsoleStore.UpdateRout(symbol, NavType.NavLists)
    }
  }

  loadTempListData(data) {
    if (!data) {
      ConsoleStore.UpdateRout(null, NavType.NavLists)
    }
    else {
      if(!data.symbol && data.spId){
        data.symbol = data.spId.toString();
      }
      const templistNode = this.checkTempListExisit(data.symbol);
      const node = data.node;

      if(node.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory){
        if (data.view === SmartViewType.OWNERSHIP) {
          this.nodeType=node.categoryType;
          OwnershipViewStore.listId =data.node.nodeId.low;
          this.routeToList(node, data.symbol, data.view);
          ListActions.getNotImportedListData();
          return;
        } else {
          this.nodeType=node.categoryType;
          ONeilViewStore.listId =data.node.nodeId.low;
          this.routeToList(node, data.symbol, data.view);
          ListActions.getNotImportedListData();
          return;
        }        
      }
      else{
      if (templistNode.length > 0) {
        this.activeSybol = {
          symbol: templistNode[0].symbol,
          msId: templistNode[0].msid
        };
        this.activeNode = templistNode[0];
        ListStore.updateSelectedListItemForTempList(
          this.activeNode.nodeId.low,
          this.activeNode.nodeId.low,
          this.activeNode.name,
          this.activeNode.categoryType,
          null,
          null,
          null,
          null,
          this.activeNode.sourceCategoryType,
          null
        );
        if (data.view === SmartViewType.OWNERSHIP) {
          SettingsAction.updateListExplorerTabSelection('ONEIL');
          this.nodeType = node.categoryType;
        }
        else {
          SettingsAction.updateListExplorerTabSelection('ONEIL');
          ConsoleStore.UpdateRout(data.symbol, NavType.NavLists, data.view);
          this.nodeType = node.categoryType;
        }
      }
      else {
        this.nodeType=node.categoryType;
        node.msid = data.msId;
        node.symbol = data.symbol;
        if (data.view === SmartViewType.OWNERSHIP) {
          node.temporaryListType = TemporaryListType.FUNDHOLDINGS_TEMPORARYLIST;
          node.spId = data.spId;
        }
         ONeilViewStore.listId =data.node.nodeId.low;
        // this.activeNode.nodeId= data.node.nodeId.low;
        this.routeToList(node, data.symbol, data.view);
      }
    }
      history.replace("/" + NavType.NavLists);
    }

    SettingsAction.updateListExplorerTabSelection("ONEIL");
  }

  setCreateNewMyOwnerListStatus(status){
    this.createNewOwnerList = status;
  }

  getCreateNewMyOwnerListStatus(){
    return this.createNewOwnerList;
  }

  getListAlertStatus(data, isRemoveFromUserList = false) {
    const self = this;
    ListApi.getListAlertStatus(data.listId).then((result) => {
      if (!result.responseHeader.error) {
        const sourcenode = this.findSourceNode(data.listId,"ONEIL");
        _.extend(sourcenode, { alertStatusType: result.listalertStatusData ? result.listalertStatusData.listalertStatusType : null });
        if(!isRemoveFromUserList)
        self.emit(CHANGE_EVENT);
      }
    });
  }


  setAlertMessage(message) {
    _state.alertMessage =message;
  }

  isFactorList() {
    if(this.activeNode && (this.activeNode.categoryType || this.activeNode.SelectedCategoryType)) {
      if (this.activeNode.categoryType == ListCategoryType.FACTORLIST_ListCategory
        || this.activeNode.SelectedCategoryType == ListCategoryType.FACTORLIST_ListCategory
        || (this.activeNode.SelectedCategoryType == ListCategoryType.FAVORITE_ListCategory && this.activeNode.SelectedSourceCategoryType == ListCategoryType.FACTORLIST_ListCategory)){
        return true;
      } else {
        let parentCategoryType = null;
        parentCategoryType = this.activeNode.categoryType == ListCategoryType.FAVORITE_ListCategory ? this.activeNode.sourceCategoryType : this.activeNode.parentCategoryType;
        return (this.activeNode.categoryType == ListCategoryType.FAVORITE_ListCategory &&
            parentCategoryType == ListCategoryType.FACTORLIST_ListCategory);
      }
    }else{
      return false;
    }
    // return this.activeNode.categoryType == ListCategoryType.FACTORLIST_ListCategory;
  }

  isNotImportedList() {
    const selectedTab = this.getSelectedTabType();
    let activeNode = null;
    switch (selectedTab) {
      case ListManagerTabType.Oneil:
        activeNode = this.activeNode;
        break;
      case ListManagerTabType.Ownership:
        activeNode = OwnershipViewStore.activeNode;
        break;
      default:
        break;
    }
    if(activeNode && activeNode.categoryType) {
      let currentCategoryType = activeNode.categoryType;
      if(selectedTab === ListManagerTabType.Oneil && activeNode.nodeId != this.currentListId){
        currentCategoryType = this.currentNode && this.currentNode.categoryType;
      }
      if (currentCategoryType == ListCategoryType.NOTIMPORTEDLIST_ListCategory){
        return true;
      } else {
        return false;
      }
    }else{
      return false;
    }
  }

  isActiveNodeDeleteAllowed(){
    if(this.activeNode){
      const categoryType = this.activeNode.categoryType ? this.activeNode.categoryType : this.activeNode.SelectedCategoryType;
      return this.isDeleteAllowed(categoryType, this.selectedFolder ? this.selectedFolder.categoryType : null);
    }
    else{
      return false;
    }
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;

    switch (action.actionType) {
      case ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER:
        _currentAction = ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER;
        this.getListExplorerData();
        this.getEligibleLists();
        break;
      case ListExplorerConstants.ActionTypes.GET_DUPLICATE_LIST:
      case ListExplorerConstants.ActionTypes.ADD_TO_FAVORITES:
      case ListExplorerConstants.ActionTypes.COPY:
      case ListExplorerConstants.ActionTypes.COPY_PASTE:
        _currentAction = action.actionType;
        debounce(this.getDuplicateList(data), 200);
        break;
      case ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST:
        _currentAction = ListExplorerConstants.ActionTypes.GET_ADD_ITEMS_TO_LIST;
        this.getAddItemsToList(data);
        break;
      case ListExplorerConstants.ActionTypes.GET_CREATE_TREE_NODE:
        _currentAction = ListExplorerConstants.ActionTypes.GET_CREATE_TREE_NODE;
        this.getCreateTreeNode(data);
        break;
      case ListExplorerConstants.ActionTypes.GET_UPDATE_LIST_NODE:
        _currentAction = ListExplorerConstants.ActionTypes.GET_UPDATE_LIST_NODE;
        this.getRenameNode(data);
        break;
      case ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE:
      case ListExplorerConstants.ActionTypes.REMOVE_FROM_FAVORITES:
        _currentAction = ListExplorerConstants.ActionTypes.GET_DELETE_TREE_NODE;
        _currentAction = action.actionType;
        this.getDeleteNode(data);
        break;
      case ListExplorerConstants.ActionTypes.RESTORE_TREE_NODE:
        _currentAction = ListExplorerConstants.ActionTypes.RESTORE_TREE_NODE;
        this.restoreNode(data);
        break;
      case ListExplorerConstants.ActionTypes.SHOW_ORIGINAL:
        _currentAction = ListExplorerConstants.ActionTypes.SHOW_ORIGINAL;
        this.showOriginal();
        break;
      case ListExplorerConstants.ActionTypes.GET_UPDATE_DESCRIPTION:
        _currentAction = ListExplorerConstants.ActionTypes.GET_UPDATE_DESCRIPTION;
        this.getUpdateDescription(data);
        break;
      case ListExplorerConstants.ActionTypes.REMOVE_ITEMS_FROM_USER_LIST:
        _currentAction = ListExplorerConstants.ActionTypes.REMOVE_ITEMS_FROM_USER_LIST;
        this.removeItemsFromUserList(data);
        break;
      case ListExplorerConstants.ActionTypes.MOVE_LIST_ITEMS:
        _currentAction = ListExplorerConstants.ActionTypes.MOVE_LIST_ITEMS;
        this.moveListItems(data);
        break;
      case ListExplorerConstants.ActionTypes.SAVE_SCREENED_RESULTS:
        _currentAction = ListExplorerConstants.ActionTypes.SAVE_SCREENED_RESULTS;
        this.saveScreenedResults();
        break;
      case ListExplorerConstants.ActionTypes.GET_TEMP_LIST:
        // if (action.data.view === SmartViewType.OWNERSHIP) {
        //   _currentAction = ListExplorerConstants.ActionTypes.GET_TEMP_LIST_OWNERSHIP;
        //   this.loadTempListData(action.data);
        // }
        // else {
        this.updateExplorer().then(() => {
          this.loadTempListData(action.data);
        })
        // }
        break;

      case ListExplorerConstants.ActionTypes.GET_TEMP_LIST_DATA:
        _currentAction = ListExplorerConstants.ActionTypes.GET_TEMP_LIST_DATA;
        this.activeSybol = {
          symbol: data.symbol,
          msId: data.msid
        };
        this.activeNode = this.getActiveNodeUsingSymbol(data.symbol);
        this.emit(CHANGE_EVENT);
        break;
      case ListExplorerConstants.ActionTypes.GET_NOT_IMPORTED_LIST_DATA:
        _currentAction = ListExplorerConstants.ActionTypes.GET_NOT_IMPORTED_LIST_DATA;
        this.emit(CHANGE_EVENT);
      break;
      case ListExplorerConstants.ActionTypes.UPDATE_ACTIVE_SYMBOL:
        this.activeSybol = action.data;
        break;
      case ListExplorerConstants.ActionTypes.CREATE_OWNERSHIP_BLOCK_FAV_LIST:
            _currentAction = ListExplorerConstants.ActionTypes.GET_CREATE_TREE_NODE;
            var myList = {
              dbType: 1,
              parentNodeId: 2,
              name: "Untitled List ",
              nodeType: 1,
              categoryType: 2,
              sourceListId: null
            };
            var parentNodeId;
            var data2= { myList: myList, parentNodeId: parentNodeId }
            this.getCreateTreeNode(data2);
            //ConsoleStore.UpdateRout("", NavType.NavLists);
            break;

      case ListExplorerConstants.ActionTypes.EDIT_OWNERSHIP_BLOCK_FAV_LIST:
             _currentAction = ListExplorerConstants.ActionTypes.GET_LIST_EXPLORER;
            //this.activeNode =this.getActiveNode("525827");
            this.getListExplorerData();
            this.getEligibleLists();

            ConsoleStore.UpdateRout("", NavType.NavLists);
            break;

      case ListExplorerConstants.ActionTypes.GET_LIST_ALERT_STATUS:
        this.getListAlertStatus(data);
        break;
      default:
        break;
    }
  }
}

const listExplorerStore = new ListExplorerStore();
export default listExplorerStore;
