import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class CreateCustomMetrics extends BaseServiceRequest {
  constructor(savedata) {
    super();
    super.addRequestUri("upsertcustomcolinfo.pb");
    const ReqProto = BaseServiceApi.rayData["customCalcInfoData"];
    const createData = new ReqProto();
    createData.dataItemId = savedata.dataItemId;
    createData.shortHand = savedata.shortHand;
    createData.interval = savedata.interval;
    createData.lookBack = savedata.lookBack;
    createData.definition = savedata.definition;
    createData.description = savedata.description;
    createData.decimals = savedata.decimals;
    createData.units = savedata.units;
    createData.formatter = savedata.formatter;
    createData.customMetricName = savedata.customMetricName;
    createData.previewSymbol = savedata.previewSymbol;
    createData.previewMsid = savedata.previewMsid;
    createData.isCalcValid = savedata.isCalcValid;
    createData.calcFormula = savedata.calcFormula;
    createData.calcSelect = savedata.calcSelect;
    createData.listId = savedata.listId;
    createData.databasesourceId = savedata.databasesourceId;
    createData.columnsetviewTypeId = savedata.columnsetviewTypeId;
    createData.deviceTypeId = savedata.deviceTypeId;
    createData.coldataType = savedata.coldataType;

    const reqcontent = createData.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "customCalcInfoResponse";
  }

}
