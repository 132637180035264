import DefaultDataGraphSetting from "./DefaultDataGraphSettings.js";
import Serializable from "../../../../../Serialization/Serializable.js";

export default class CashSpotsViewSettings extends Serializable {
  onSeralisedInfoRecieved(info) {
    this.ScaleLabelSettings = info.ScaleLabelSettings ? info.ScaleLabelSettings : DefaultDataGraphSetting.getScaleLabelSettings(true);
    this.EarningLineSetting = info.EarningLineSetting ? info.EarningLineSetting : DefaultDataGraphSetting.getEstimateSettings();
    this.HiLoPriceSettings = info.HiLoPriceSettings ? info.HiLoPriceSettings : DefaultDataGraphSetting.getHiLoPriceSetting();
    this.HiLoPctSettings = info.HiLoPctSettings ? info.HiLoPctSettings : DefaultDataGraphSetting.getHiLoPctSetting();
    this.MASettings = info.MASettings ? info.MASettings : DefaultDataGraphSetting.getMovingAverageSetting();
    this.OffHighTag = info.OffHighTag ? info.OffHighTag : "OH";
    this.ExternalDataSettings =  info.ExternalDataSettings ? info.ExternalDataSettings : DefaultDataGraphSetting.getExternalDataSettings();
    this.EDMultiplierSettings = info.EDMultiplierSettings ? info.EDMultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.ExternalDataRiPanelSettings = info.ExternalDataRiPanelSettings ? info.ExternalDataRiPanelSettings : DefaultDataGraphSetting.getExternalDataRiPanelSettings();
    this.TiMenuSettings = info.TiMenuSettings ? info.TiMenuSettings : DefaultDataGraphSetting.getTiMenuSettings();
  }

  getDataObject() {
    return {
      EarningLineSetting: this.EarningLineSetting,
      ScaleLabelSettings: this.ScaleLabelSettings,
      HiLoPriceSettings: this.HiLoPriceSettings,
      HiLoPctSettings: this.HiLoPctSettings,
      MASettings: this.MASettings,
      OffHighTag: this.OffHighTag,
      ExternalDataSettings: this.ExternalDataSettings,
      ExternalDataRiPanelSettings: this.ExternalDataRiPanelSettings,
      TiMenuSettings: this.TiMenuSettings
    };
  }

  getObjectMapKey() {
    return "CashSpotsViewSettings";
  }
  getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness) {
    return DefaultDataGraphSetting.getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness);
  }
}