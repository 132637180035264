import { getRiPanelConstituentsStates } from "../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors";
import GraphApi from "../../../ServiceApi/Apis/GraphApi";
import MiniListHelper from "../../../Utils/MiniListHelper";
import MiniListStore from "../../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { RiPanelConstituentsTranslateHelper } from "../../../Utils/TranslateHelper";
import SymbolType from "../../../Constants/SymbolType";
import UserInfoUtil from "../../../Utils/UserInfoUtil";
import { call, put, select, takeLatest } from "redux-saga/effects";

const { ActionTypes } = RiPanelConstants;

function* getConstituentsData({ symbolInfo }) {
  try {
    let { constituentsData } = yield select(getRiPanelConstituentsStates)
    if (constituentsData.length === 0) {
      const userInfo = UserInfoUtil.getUserInfo();
      const entitleIdxUserList = userInfo.entitleIdxUserList;
      const result = [];
      if (entitleIdxUserList) {
        entitleIdxUserList.forEach((item) => {
          if (item && item.symbol === symbolInfo.Symbol && item.isActive === true) {
            result.push(item);
          }
        });
      }
      const isIndustry = isIndustryGroup(symbolInfo.SymTypeEnum)
      const isUserEntitled = result.length > 0;
      if (isUserEntitled || isIndustry) {
        const isMiniListPlay = MiniListStore.getMiniListPlayingStatus();
        const toFixed = isIndustry ? 1 : 2;
        const percent = isIndustry ? "" : "%";
        const constituentsResponse = yield call(GraphApi.GetConstituentsDataRequest, symbolInfo.Symbol, isIndustry ? 1 : 0)
        if (MiniListHelper.ActiveSymbolCheck(symbolInfo.Symbol, isMiniListPlay)) {
          constituentsData = constituentsResponse.data.map((constituent) => {
            constituent.mrkValueString = `${constituent.marketValOrWeight >= 0 ? constituent.marketValOrWeight.toFixed(toFixed) : (-1 * constituent.marketValOrWeight).toFixed(toFixed)}${percent}`;
            constituent.pctOfChangevs4weeksString = constituent.pricePctChangeFourWeeks === 0 ? "0.0" : constituent.pricePctChangeFourWeeks > -100 && constituent.pricePctChangeFourWeeks < 100 ? (Math.round(constituent.pricePctChangeFourWeeks * 10) / 10).toFixed(1) : constituent.pricePctChangeFourWeeks.toFixed(0);
            return constituent
          });
        }
      }
      yield put({
        type: ActionTypes.UPDATE_CONTITUENTS_DATA,
        constituentsData,
        loading: false,
        isUserEntitled: isIndustry || isUserEntitled,
        nameMessage: RiPanelConstituentsTranslateHelper.NAME,
        itemHeight: isIndustry ? 25 : 20,
        messageWeightOrValue: isIndustry ? `${RiPanelConstituentsTranslateHelper.MARKET_VALUE} \n${RiPanelConstituentsTranslateHelper.MILLION}` : "Mkt Weight %",
        priceChangeMessage: isIndustry ? `${RiPanelConstituentsTranslateHelper.PRICE_PER_CHANGE} \n${RiPanelConstituentsTranslateHelper.FOUR_WEEKS}` : "Price, % chg vs 4 Wks",
        isFocusListSymbol: isFocusListSymbol(symbolInfo.Symbol)
      })
    }
  }
  catch (error) {
    console.log(`Error occurs in RipnanelConstituents.js, getConstituentsData, ${error}`);
  }


}

function isIndustryGroup(symbolTypeEnum) {
  return SymbolType.WONINDUSTRYGROUP197 === symbolTypeEnum || SymbolType.WONMAJORINDUSTRY89 === symbolTypeEnum || SymbolType.WONSECTOR11 === symbolTypeEnum || SymbolType.WONINDUSTRYGROUP197 === symbolTypeEnum
}

function isFocusListSymbol(symbol) {
  if (!symbol) {
    return false;
  }
  if (symbol === "0ONUL" || symbol === "0ONUS" || symbol === "0ONDL" || symbol === "0ONEL" || symbol === "0ONFL") {
    return true;
  }
  else {
    return false;
  }
}


export function* watchInitContituentsData() {
  yield takeLatest(RiPanelConstants.ActionTypes.INIT_CONTITUENTS_TAB, getConstituentsData)
}