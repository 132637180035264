import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';

class FindGetTechInd extends BaseServiceRequestIIS {
    constructor() {
        super();
    }

    getServiceMethod() {
        return "FindGetTechInd";
    }
    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return 'FindGetTechIndReply';
    }
}

export default FindGetTechInd;