import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";
import { handleEnterPress, hideArrowPress, onSelectAddSymbol, hideDropdown, setAddListName } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import VirtualList from "../../../../../RayCustomControls/VirtualList/VirtualList.jsx";
import { connect } from 'react-redux'
import KeyCodes from "../../../../../Constants/KeyCodes";
import LocalizationStore from 'LocalizationStore';
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType from "CompareTabType";

class RecentListDropDown extends Component {
    constructor() {
        super();
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    handleKeyPress(e) {
        const { searchResult, activeIndex } = this.props;
        let index = activeIndex;
        if (e.keyCode === KeyCodes.DOWN_ARROW) {
            if (searchResult && searchResult.length > 0) {
                if (index === searchResult.length - 1) {
                    index = -1;
                }
                this.menuItemList.scrollToItemIndex(index + 1);
                index = index + 1;
                this.props.handleKeyPress(index);
            }
            e.preventDefault();
            e.stopPropagation();
        }
        if (e.keyCode === KeyCodes.UP_ARROW) {
            if (searchResult && searchResult.length > 0) {
                if (index === 0) {
                    index = searchResult.length;
                }
                this.menuItemList.scrollToItemIndex(index - 1);
                index = index - 1;
                this.props.handleKeyPress(index);
            }
            e.preventDefault();
            e.stopPropagation();
        }
        if (e.keyCode === KeyCodes.ENTER) {
            const { activeIndex } = this.props;
            if (event.keyCode === KeyCodes.ENTER && activeIndex >= 0) {
                this.props.handleEnterPress(this.props.searchResult[index]);
            }
            this.props.onOutsideClick();
            e.preventDefault();
            e.stopPropagation();
        }

    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    handleClickOutside(e) {
        this.props.outsideClickDocument(e);
    }
    onSelect(item) {
        if (!item.active) return;
        this.props.onSelectAddSymbol(item.Symbol);
    }

    getItemRenderer(item, top, index) {
        if (item && this && this.props) {
            let localSymbol = item.localSymbol;
            let isActive = this.props.activeIndex === index;
            let columnWidths = this.props.columnWidths;
            let menuWidth = this.props.menuWidth;
            return (
                <div className="menuItem"
                    key={index}
                    style={{ top: top, width: menuWidth }}
                    onMouseDown={() => this.onSelect(item)}>
                    <div className={isActive ? "menuItem-content active" : "menuItem-content"}>
                        <span style={{ width: localSymbol ? columnWidths.localSymbol ? columnWidths.localSymbol : 73 : 0 }}>{localSymbol}</span>
                        <span style={{ width: columnWidths.symbol ? columnWidths.symbol : 73 }}>{item.Symbol}</span>
                        <span style={{ minWidth: columnWidths.name ? columnWidths.name : 100, flex: 1 }}>{item.CompanyName}</span>
                        <span style={{ width: columnWidths.exchange ? columnWidths.exchange : 83 }}>{item.exchange}</span>
                    </div>
                </div>
            );
        }
    }
    render() {
        const { searchResult, resultAvail } = this.props;
        let dropHeight = searchResult ? searchResult.length * 20 + 7 : 20;
        let menuWidth = this.props.menuWidth ? this.props.menuWidth : 25;
        let panelHeight = dropHeight;
        if (!resultAvail) {
            panelHeight = 20;
        }
        //let top = 40;
        let itemHeight = 20;
        let item = searchResult;
        //let index = activeIndex; /** us-user variable */
        return (
            <div className="menuPanelWrap">
                <div className="menuPanel" style={{ width: menuWidth, top: "35px" }}>
                    <div id="menuHeader" className="header noHeight small-bold ">{LocalizationStore.getTranslatedData("srh_srhRst", "SEARCH RESULTS")}</div>
                    <div className="menuItems comp-menu-item small-bold" style={{ height: panelHeight }}>
                        {resultAvail ?
                            <ErrorBoundary><VirtualList id="symbolEntry" ref={(ref) => this.menuItemList = ref} items={item}
                                itemHeight={itemHeight}
                                itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                                showScrollOnHover={false} /></ErrorBoundary>
                            :
                            LocalizationStore.getTranslatedData("srh_noMat", "No Matches")}
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    handleKeyPress: (index) => dispatch(hideArrowPress(index)),
    handleEnterPress: (index) => dispatch(handleEnterPress(index)),
    onSelectAddSymbol: (symbol) => dispatch(onSelectAddSymbol(symbol)),
    onOutsideClick: () => dispatch(hideDropdown()),
})

const mapStateToProps = (state) => {
    const selectedTab = state.navCompareReducer.selectedTab;
    const addSymbolData = state.comparisonAndRotationMenuPanel.AddSymbol;
    const { activeIndex, columnWidths, menuWidth } = selectedTab === CompareTabType.ComparisonGraph ? addSymbolData.ComparisonMenuStates : addSymbolData.RotationMenuStates;

    return { activeIndex, columnWidths, menuWidth };
}

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(RecentListDropDown));
