import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetFundamentalOptionsRequest extends BaseServiceRequest {
  constructor(instrumentid) {
    super();
    super.addRequestUri("options.pb");
    super.addRequestParameter("instrumentid", instrumentid.toString());
    super.addRequestParameter("type", "1");
    super.addRequestParameter("intl", "0");
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.FUNDAMENTAL;
  }

  getMessageName() {
    return "FundamentalTabOptionsResponse";
  }
}

export default GetFundamentalOptionsRequest;
