import keyMirror from "keymirror";

export const FinancialBlockConstants = {
    ActionTypes: keyMirror({
        FINANCIAL_BLOCK_GET_API_DATA: null,
        SET_FINANCIAL_DATA: null,
        TOGGLE_HEADER: null,
        UPDATE_CONSOLE_SETTINGS: null,
        PAGINATED_GRAPH_VIEW: null,
        FINANCIAL_PE_TOGGLE: null,
        FINANCIAL_PAGE_CHANGED: null,
        UPDATE_FINANCIAL_PAGE: null,
        UPDATE_UI_ON_PE_TOGGLE: null,
        SELECT_MENU_ITEM: null,
        SET_MENU_DATA: null,
        FINANCIAL_BLOCK_RESPONSE_READY: null,
        FINANCIAL_BLOCK_COLLAPSE: null,
        FINANCIAL_BLOCK_DEFAULT_STATE: null,
        FINANCIAL_BLOCK_UPDATE_CURRENT_ROWS: null,
        FINANCIAL_BLOCK_UPDATE_PAGINATED_SOURCE: null,
        FINANCIAL_BLOCK_UPDATE_CURRENT_PAGE_HEADERS: null,
        FINANCIAL_BLOCK_UPDATE_PICK_LIST: null,
        UPDATE_PAGINATED_TABLE_RENDER: null,
    })
}