import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BlockType from "../../../../Constants/BlockType";
import { DatagraphConst } from "../../../../Utils/DatagraphHelper";
import GraphType from "GraphType";
import { IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper";
import { IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "SettingsStore";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getIndicatorStates } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import {  updateIndicatorData, updateIndicatorGraphData, updateIndicatorSettings } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";


const { ActionTypes } = IndicatorsConstants;

function getMacdChart(hsfResults, slow = true, firstNodeIndex, startXPoint, scale) {
    const prcLength = hsfResults.length;
    const chartData = [];

    for (let j = firstNodeIndex; j < prcLength; j++) {
        if (startXPoint < 0) { break; }
        const val = slow ? hsfResults[j].S_Line : hsfResults[j].F_Line;
        if (isNaN(val)) {
            startXPoint -= DatagraphConst.nodeWidth;
            continue;
        }
        const yPrice = scale.ComputeY(val);
        const info = {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: startXPoint,
            yValue: val
        };
        startXPoint -= DatagraphConst.nodeWidth;
        chartData.push(info);
    }
    return chartData;
}
function getMacdBars(hsfResults, firstNodeIndex, startXPoint, scale, nodeCount) {
    let yAxis;
    const chartData = { highPoints: [], lowPoints: [], allPoints: [] };

    const lastDataNode = Math.min(hsfResults.length - 1, nodeCount);
    const zeroYAxis = Math.round(scale.ComputeY(0));

    for (let j = firstNodeIndex; j < lastDataNode; j++) {
        if (startXPoint < 0) { break; }
        // if (!hsfResults[j].IsVisible) {
        //     startXPoint -= DatagraphConst.nodeWidth;
        //     continue;
        // }
        const uptick = hsfResults[j].H_Line >= 0;
        yAxis = scale.ComputeY(hsfResults[j].H_Line);
        const info =
        {
            Date: hsfResults[j].Date,
            yHigh: (uptick ? yAxis : zeroYAxis),
            yLow: uptick ? zeroYAxis : yAxis,
            xAxis: startXPoint,
            UpTick: uptick,
            yValue: hsfResults[j].H_Line
        };
        startXPoint -= DatagraphConst.nodeWidth;
        if (uptick) {
            chartData.highPoints.push(info);
        }
        else {
            chartData.lowPoints.push(info);
        }
        chartData.allPoints.push(info);
    }
    return chartData;
}

function GetSmoothingConstant(lPeriod) {
    return 2.0 / (1.0 + lPeriod);
}

function getSimpleMA(nPricePtr, lPeriod, stockQuotes) {
    let fSumOfPeriods = 0.0;
    if (lPeriod === 0) { return 0; }

    let i = 0;

    if (nPricePtr >= lPeriod) {
        for (; i < lPeriod; i++) {
            fSumOfPeriods = fSumOfPeriods + stockQuotes[i].Close;
        }
    }
    return fSumOfPeriods / i;
}
function getCurrentEMA(fSmoothingConstant, fCurrentPrice, fPreviousPeriodEMA) {
    return (fSmoothingConstant * (fCurrentPrice - fPreviousPeriodEMA)) + fPreviousPeriodEMA;
}
function contains(a, obj) {
    let i = a.length;
    while (i--) {
        if (a[i].Date === obj) {
            return i;
        }
    }
    return -1;
}
function CalcEMA(stockQuotes, lPeriod, hasNoVolume = false) {
    const LastPrice = stockQuotes.length - 1;
    const CurrentEMA = [];

    const fSmoothingConstant = GetSmoothingConstant(lPeriod);
    let fCurrentEMA = 0.0;
    let nCurrentPeriod = 0;
    let nPricePtr = 1;

    for (; nCurrentPeriod <= LastPrice && nPricePtr <= LastPrice; nPricePtr++)	// iterates nPricePtr+ on each iteration -- not necessarily nCurrentPeriod due to holiday offsets
    {
        //    if (this.contains(stockQuotes, stockQuotes[nPricePtr].Date)>-1)
        //        continue;

        if (stockQuotes[nPricePtr]._volume <= 0 && !hasNoVolume)	// if no trade/holiday/disaster/etc
        {
            stockQuotes[nPricePtr].CurrentEMA = fCurrentEMA;
            continue;                                   // starts the next iteration cycle
        }

        if (stockQuotes[nPricePtr].Close === 0)			        // no price data
        {
            continue;							        // starts the next iteration cycle
        }

        fCurrentEMA = nCurrentPeriod < lPeriod - 1
            ? 0
            : (nCurrentPeriod === lPeriod - 1
                ? getSimpleMA(nPricePtr, lPeriod, stockQuotes)
                : getCurrentEMA(fSmoothingConstant, stockQuotes[nPricePtr].Close,
                    fCurrentEMA));

        if (nCurrentPeriod >= lPeriod - 1) {
            CurrentEMA.push({ CurrentEMA: fCurrentEMA, Date: stockQuotes[nPricePtr].Date });
        }
        nCurrentPeriod++;
    }
    return CurrentEMA;
}
// Fixing the issues created by PANWEB-2539. This code now matches to the Desktop
function CalculateMacd(inhsfResults, fastLength = 12, slowLength = 26, ema = 9, SettingDict) {
    inhsfResults.map((x) => {
        if (x.F_Line !== undefined) {
            x.F_Line = undefined;
        }
        if (x.S_Line !== undefined) {
            x.S_Line = undefined;
        }
        if (x.H_Line !== undefined) {
            x.H_Line = undefined;
        }
        return x;
    });
    const hsfResults = inhsfResults.filter((t) => t._volume > -1 && t.Close !== undefined);
    let tot = hsfResults.length;
    const total = Math.floor(hsfResults.length / 2) + 1;
        if (tot < 1) return;
        if (total < fastLength) {
            fastLength = total;
            SettingDict.FastLength = total; 
            SettingsStore.saveSettings();
        }
        if (total < slowLength) {
            slowLength = total;
            SettingDict.SlowLength = total;
            SettingsStore.saveSettings();
        } 
        if (total < ema) {
            ema = total;
            SettingDict.EMA = total;
            SettingsStore.saveSettings();
        }

    const prices = hsfResults;
    prices.sort((a, b) => {
        if (a.Date < b.Date) {
            return -1;
        }
        if (a.Date > b.Date) {
            return 1;
        }
        if (a.Date === b.Date) {
            return 0;
        }
        return -1;
    });
    const hasNoVolume = SetupHasNoVolume(hsfResults);
    const fast = CalcEMA(prices, fastLength, hasNoVolume);
    const slow = CalcEMA(prices, slowLength, hasNoVolume);

    const returnValues = [];

    fast.forEach((var7) => {
        const key = contains(slow, var7.Date);
        const var8 = key > -1 ? slow[key].CurrentEMA : 0.0;
        const sVal = var7.CurrentEMA > 0 && var8 > 0 ? var7.CurrentEMA - var8 : 0.0;

        if (sVal !== 0.0) {
            returnValues.push({ Date: var7.Date, Close: sVal });
        }
    }, this);

    const prices2 = [];

    prices.forEach((node2) => {
        const key = contains(returnValues, node2.Date);
        const node = key > -1 ? returnValues[key].Close : 0;

        prices2.push({ Date: node2.Date, Close: node, _volume: node2._volume });
    }, this);

    const fastSlow = CalcEMA(prices2, ema, hasNoVolume);

    const returnValues1 = [];

    fast.forEach((var7) => {
        let key = contains(slow, var7.Date);
        const var8 = key > -1 ? slow[key].CurrentEMA : 0.0;
        key = contains(fastSlow, var7.Date);
        const var9 = key > -1 ? fastSlow[key].CurrentEMA : 0.0;
        const sVal = var7.CurrentEMA > 0 && var8 > 0 && var9 !== 0 ? var7.CurrentEMA - var8 - var9 : 0.0;

        returnValues1.push({ Date: var7.Date, Close: sVal });
    }, this);

    tot = hsfResults.length - 1;
    for (; tot >= 0 && hsfResults[tot] === null || hsfResults[tot].Close <= 0; tot--) { ; }
    let i = tot;
    for (; i > 0; i--) {
        if (!hsfResults[i].IsVisible) {
            continue;
        }
        const returnValuesKey = contains(returnValues, hsfResults[i].Date);
        if (returnValuesKey < 0) {
            continue;
        }
        let key = contains(fastSlow, hsfResults[i].Date);
        const val1 = key > -1 ? fastSlow[key].CurrentEMA : 0.0;
        key = contains(returnValues1, hsfResults[i].Date);
        const val2 = key > -1 ? returnValues1[key].Close : 0.0;

        if (returnValues[0] && returnValues[0].Date <= hsfResults[i].Date) {
            hsfResults[i].F_Line = returnValues[returnValuesKey].Close;
        }
        if (fastSlow[0] && fastSlow[0].Date <= hsfResults[i].Date) {
            hsfResults[i].S_Line = val1;
        }
        if (returnValues1[0] && returnValues1[0].Date <= hsfResults[i].Date) {
            hsfResults[i].H_Line = val2;
        }
    }
    tot = inhsfResults.length;
    for (i = 0; i < tot; i++) {
        if (!inhsfResults[i].IsVisible) {
            continue;
        }
        if (inhsfResults[i].F_Line === undefined && inhsfResults[i + 1]) {
            inhsfResults[i].F_Line = inhsfResults[i + 1].F_Line;
            inhsfResults[i].S_Line = inhsfResults[i + 1].S_Line;
            inhsfResults[i].H_Line = inhsfResults[i + 1].H_Line;
        }
    }

    return inhsfResults;
}

function SetupHasNoVolume(hsfResults) {
    const len = hsfResults.Length;

    if ((hsfResults === null) || (len === 0)) {
        return;
    }

    let xLine = 0;
    if (hsfResults[xLine] === null) {
        return;
    }
    let currentPriceLine = 0;

    while (hsfResults[xLine].Close < 0.000001) {
        xLine++;
        if (hsfResults[xLine] === null) {
            return;
        }
    }
    if (len > 0) {
        for (; currentPriceLine < len && xLine < len; xLine++) {
            if (hsfResults[xLine].Volume > 0) {
                currentPriceLine++;
            }
        }
    }
    return currentPriceLine < 1;
}

function initMacdScale(graphData, lastNode, graphType, indicatorsHeight, startXPoint) {
    graphData.sort((a, b) => {
        if (a.Date > b.Date) {
            return -1;
        }
        if (a.Date < b.Date) {
            return 1;
        }
        if (a.Date === b.Date) {
            return 0;
        }
        return 0;
    });
    
    const gLength = graphData.length;
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;

    for (let minMaxIndex = lastNode; minMaxIndex < gLength; minMaxIndex++) {
        if (graphData[minMaxIndex] === null) {
            break;
        }

        if (minValue > graphData[minMaxIndex].F_Line) {
            minValue = graphData[minMaxIndex].F_Line;
        }
        if (maxValue < graphData[minMaxIndex].F_Line) {
            maxValue = graphData[minMaxIndex].F_Line;
        }

        if (minValue > graphData[minMaxIndex].S_Line) {
            minValue = graphData[minMaxIndex].S_Line;
        }
        if (maxValue < graphData[minMaxIndex].S_Line) {
            maxValue = graphData[minMaxIndex].S_Line;
        }

        if (minValue > graphData[minMaxIndex].H_Line) {
            minValue = graphData[minMaxIndex].H_Line;
        }
        if (maxValue < graphData[minMaxIndex].H_Line) {
            maxValue = graphData[minMaxIndex].H_Line;
        }

        startXPoint -= DatagraphConst.nodeWidth;
        if (startXPoint < 0) { break; }
    }
    const scale = new ArithmaticScale();
    scale.InitScale(minValue, maxValue, indicatorsHeight, graphType, 2, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true);
    return scale;
}
function* PrepareData({ chartType }) {
    try {
        const { SettingDict } = yield select(getIndicatorStates);
        const {  pricePanelData, indicatorsHeight, startXPoint, padding, nodeCount} = yield select(getDatagraphStates);
        const graphData = CalculateMacd(pricePanelData.HsfData.HSFResults, SettingDict[BlockType.MACD].FastLength, SettingDict[BlockType.MACD].SlowLength, SettingDict[BlockType.MACD].EMA, SettingDict[BlockType.MACD]);
        yield call(processData, graphData, indicatorsHeight, chartType, SettingDict[chartType], startXPoint, padding, nodeCount)
    }
    catch (error) {
        console.error("Error occurs in MacdIndicatorSaga.js, PrepareData", error);
    }
}

function* processData(graphData, indicatorsHeight, chartType = BlockType.MACD, SettingDict, startXPoint, padding, nodeCount) {
    if (graphData) {
        const lastNode = 0;
        const scale = initMacdScale(graphData, lastNode, GraphType.Weekly, indicatorsHeight, startXPoint);
        let macdLineColor, macdWeight, movingAvgLineColor, movingAvgWeight;
        SettingDict.MACDLineColor.forEach((item) => {
            macdLineColor = ThemeHelper.getThemedBrush(item.color);
            macdWeight = item.weight;
        });
        SettingDict.MovingAverageColor.forEach((item) => {
            movingAvgLineColor = ThemeHelper.getThemedBrush(item.color);
            movingAvgWeight = item.weight;
        });
        let DataSource;
        const indicatorVisuals = { [chartType]: [] };
        const indicatorLabels = { [chartType]: { labels: [] } };
        DataSource = getMacdBars(graphData, padding, startXPoint, scale, nodeCount);
        indicatorVisuals[chartType].push({ isBarVisual: true, isQtrVisual: false, DataSource, key: "Line1", nodeWidth: DatagraphConst.nodeWidth });
        DataSource = getMacdChart(graphData, false, padding, startXPoint, scale);
        indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: "Line2", DataSource, LineColor: macdLineColor, LineThickness: macdWeight, Draggable: false, lineID: BlockType.MACD });
        DataSource = getMacdChart(graphData, true, padding, startXPoint, scale);
        indicatorVisuals[chartType].push({ isBarVisual: false, isQtrVisual: false, key: "Line3", DataSource, LineColor: movingAvgLineColor, LineThickness: movingAvgWeight, Draggable: false, lineID: `${SettingDict.EMA} EMA` });

        indicatorLabels[chartType].isScale = true;
        indicatorLabels[chartType].class = "tech-indicators";
        indicatorLabels[chartType].LabelMenu = { label: IndicatorLabelTranslateHelper[BlockType.MACD], isTICustomModal: true, isShowSettingsDialog: true }
        indicatorLabels[chartType].scaleText = `(${SettingDict.FastLength}, ${SettingDict.SlowLength}, ${SettingDict.EMA})`;
        yield put(updateIndicatorData(indicatorVisuals, indicatorLabels, chartType, scale));
    }
}

function* initIndicator() {
    try {
        const { pricePanelData, viewsSettings, majorPeriodicity, indicatorsHeight, startXPoint, padding, nodeCount } = yield select(getDatagraphStates);
        //Its end Tracker will be added in oneilRating indicator saga. It is depending upon order of watcher in root saga.
        TimeTrackingWindow.beginTechnicalIndicatorsRenderTimeTracker();
        const MacdChartSettings = viewsSettings.MacdChartSettings;
        const SettingDict = {}
        SettingDict[BlockType.MACD] = MacdChartSettings ? MacdChartSettings[majorPeriodicity] : {};
        yield put(updateIndicatorSettings(SettingDict));
        if (MacdChartSettings && SettingDict[BlockType.MACD].IsAvailable) {
            const graphData = CalculateMacd(pricePanelData.HsfData.HSFResults, SettingDict[BlockType.MACD].FastLength, SettingDict[BlockType.MACD].SlowLength, SettingDict[BlockType.MACD].EMA, SettingDict[BlockType.MACD]);
            yield put(updateIndicatorGraphData(graphData, BlockType.MACD));
            yield call(processData, graphData, indicatorsHeight, BlockType.MACD, SettingDict[BlockType.MACD], startXPoint, padding, nodeCount)
        }
    }
    catch (error) {
        console.error("Error occurs in MacdIndicatorSaga.js, initIndicator", error);
    }
}
//===================================================

export function* watchInitMACDIndicator() {
    yield takeLatest(ActionTypes.PROCESS_INDICATOR, initIndicator)
}

export function* watchPrepareMACDIndicatorData() {
    yield takeLatest(ActionTypes.PREPARE_MACD_INDICATOR_DATA, PrepareData);
}

export function* watchToggleMACDChart() {
    // yield takeLatest(ActionTypes.TOGGLE_YTD_CHART, togglePTOECHart)
}

export function* watchUpdateMACDIndicatorHeight() {
    yield takeLatest(PriceChartConstants.ActionTypes.INDICATOR_RESIZE, initIndicator)
}