import GraphType from "GraphType";
import LocalizationStore from "LocalizationStore";

class DocUtil {
    constructor() {
        this._extendedHsf = false;
    }

    get Months() {
        return ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    get docMoDays() {
        return [0, 0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334, 366];
    }

    /**
     * @return {number}
     */
    DocTdc(tdc) {
        let dw = tdc % 5;
        let doc = Math.round(tdc / 5);
        doc = doc * 7 + dw;
        if (dw == 0) doc = doc - 2;
        return doc;
    }

    /**
     * @return {number}
     */
    TdcDoc(doc) {
        //  return Math.round(doc - doc / 7 * 2 + ((doc % 7 == 0) ? 1 : 0));
        let totalWeekends = Math.floor(doc / 7);
        let daysWeekend = totalWeekends * 2;
        return (doc - daysWeekend + ((doc % 7 == 0) ? 1 : 0));
    }
    GetNumberOfDays(startDate, endDate) {
        let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        let timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
        return Math.round(timeDiff / oneDay);
    }

    /**
     * @return {number}
     */
    DocYmd(dt) {
        var date2 = new Date(1826, 11, 31, 0, 0, 0);
        var date3 = new Date(1899, 11, 31, 0, 0, 0); // This date is needed for back compatibility
        var endDate = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0);
        if (this._extendedHsf) {
            return this.GetNumberOfDays(endDate, date2);
        }
        else {
            return this.GetNumberOfDays(endDate, date3);
        }
    }

    YmdDoc(doc) {
        var pDate2 = new Date(1826, 11, 31, 0, 0, 0);
        var pDate3 = new Date(1899, 11, 31, 0, 0, 0); // This date is needed for back compatibility
        var pSpan3 = doc;

        if (this._extendedHsf) {
            pDate2.setDate(pDate2.getDate() + pSpan3);
            return pDate2;
        }
        else {
            pDate3.setDate(pDate3.getDate() + pSpan3);
            return pDate3;
        }
    }

    /**
     * @return {number}
     */
    ConvertDateTimeToTdc(dt) {
        return this.TdcDoc(this.DocYmd(dt));
    }

    ConvertTdcToDateTime(lTdc) {
        let dtDate;
        dtDate = this.YmdDoc(this.DocTdc(lTdc));
        return dtDate;
    }
    
    calculateDurationForDaily(startDat, endDat) {
        // Validate
        if (endDat < startDat) {
          return 0;
        }
        const startDate = new Date(startDat);
    
        const endDate = new Date(endDat);
        // Calculate days between dates
        var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
        startDate.setHours(0, 0, 0, 1); // Start just after midnight
        endDate.setHours(23, 59, 59, 999); // End just before midnight
        var diff = endDate - startDate; // Milliseconds between datetime objects
        var days = Math.ceil(diff / millisecondsPerDay);
    
        // Subtract two weekend days for every week in between
        var weeks = Math.floor(days / 7);
        days = days - weeks * 2;
    
        // Handle special cases below
        var startDay = startDate.getDay();
        var endDay = endDate.getDay();
    
        // Remove weekend not previously removed.
        if (startDay - endDay > 1) days = days - 2;
    
        // Remove start day if span starts on Sunday but ends before Saturday
        if (startDay == 0 && endDay != 6) {
          days = days - 1;
        }
    
        // Remove end day if span ends on Saturday but starts after Sunday
        if (endDay == 6 && startDay != 0) {
          days = days - 1;
        }
    
        return days;
      }

    CalculatDuration(inDtx, outDtx, graphType, tradingDays = false) {
        var duration = 0;
        var timeDiff = Math.abs(outDtx.getTime() - inDtx.getTime());
        var dayNw = Math.ceil(timeDiff / (1000 * 3600 * 24));
        //getting date-time string for daily periodicity
        const endDateForDaily = new Date(`${(outDtx.getMonth() + 1)}"/"${outDtx.getDate()}"/"${outDtx.getFullYear()}`);
        const startDateForDaily = new Date(`${(inDtx.getMonth() + 1)}"/"${inDtx.getDate()}"/"${inDtx.getFullYear()}`);

        switch (graphType) {
            case GraphType.Weekly:
                if (dayNw % 7 === 0) {
                    duration = LocalizationStore.getTranslatedData("pt_inwks", "in {0} WKS", dayNw / 7);
                }
                else {
                    duration = LocalizationStore.getTranslatedData("pt_inwks", "in {0} WKS", (dayNw / 7).toFixed(1));
                }
                break;
            case GraphType.Monthly:
            case GraphType.Quarterly:
            case GraphType.Annual:
                //TODO: Refactor required
                //Logical solution to retrieve months would be -> (outDt.Month - InDt.Month) + (outDt.Year - InDt.Year * 12)
                //Following is used to calculate the partial month (eg 1.2, 1.3 etc) for simplicity
                if (dayNw % 30 === 0) {
                    duration = LocalizationStore.getTranslatedData("pt_inmos", "in {0} MOS", dayNw / 30);
                }
                else {
                    duration = LocalizationStore.getTranslatedData("pt_inmos", "in {0} MOS", (dayNw / 30).toFixed(1));
                }
                break;
            case GraphType.Daily:
                if (tradingDays) {
                    duration = LocalizationStore.getTranslatedData("pt_indays", "in {0} DAYS", Math.round((this.ConvertDateTimeToTdc(outDtx) - this.ConvertDateTimeToTdc(inDtx))));

                }
                else{
                    // duration = LocalizationStore.getTranslatedData("pt_indays", "in {0} DAYS", Math.round(dayNw - (dayNw * 2 / 7)));
                    let days_duration=this.calculateDurationForDaily(startDateForDaily,endDateForDaily)
                    duration = LocalizationStore.getTranslatedData("pt_indays", "in {0} DAYS", days_duration-1);
                }
                break;
            default:
                return "";
        }
        return duration;
    }

    StepDaily(startingDate, numberOfPeriods) {
        let numberOfdays = numberOfPeriods + this.ConvertDateTimeToTdc(startingDate);

        return this.ConvertTdcToDateTime(numberOfdays);
    }

    StepWeekly(startingDate, numberOfPeriods) {
        return startingDate.setDate(startingDate.getDate() + 7 * numberOfPeriods);
    }

    StepMonthly(startingDate, numberOfPeriods) {
        let months = startingDate;
        let iTdc = this.ConvertDateTimeToTdc(months);
        let iPrvMonth = months.getMonth();
        let iNode = 0;

        while (iNode < Math.abs(numberOfPeriods)) {
            if (numberOfPeriods < 0)
                iTdc--;
            else
                iTdc++;
            let iTdcMonth = this.ConvertTdcToDateTime(iTdc).getMonth();
            if (iPrvMonth == iTdcMonth) continue;
            iPrvMonth = iTdcMonth;
            iNode++;
        }

        return this.ConvertTdcToDateTime(iTdc);
    }

    StepAnnual(startingDate, numberOfPeriods) {
        let years = startingDate;
        let iTdcYear = numberOfPeriods + years.getYear();

        iTdcYear--;
        var endDate = new Date(iTdcYear, 1, 1);
        let iTdc = this.ConvertDateTimeToTdc(endDate);
        iTdc--;

        return this.ConvertTdcToDateTime(iTdc);
    }
}

const docUtil = new DocUtil();
export default docUtil;