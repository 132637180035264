import { connect } from "react-redux";
import GraphType from "GraphType";
import { map } from "underscore";
import { Modal } from "react-bootstrap";
import PeriodicityHelper from "PeriodicityHelper";
import RestoreDefaultDialog from "./RestoreDefaultDialog";
import ScrollBar from "ScrollBar";
import SettingsStore from "SettingsStore";
import {
    onGoBack, onMouseOverMetricItem, onReorderRowItem, onRowItemSelectedChngd, onSearchBoxTextChange, onSelectMetricChange,
    onSearchTextClear, onSubmitChanges, setFinancialBlockDialogState
} from "../../../../../../Actions/FinancialBlockDialogActions.js";
import React, { useCallback, useEffect, useRef } from "react";
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

const SortableItem = SortableElement(({ value }) => value);

const SortableList = SortableContainer(({ items }) => (
    <ul className="list-item">
        {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
    </ul>
));

const FinancialBlockDialog = (props) => {
    const { selectedPages, CurrentPageIndex, HeaderTabItemSource, IsFinancalRestoreDialogOpen, IsFinancialBlockDialogOpen } = props;
    const headerObj = {
        [GraphType.Daily]: "DAILY FINANCIAL PANEL", [GraphType.Weekly]: "WEEKLY FINANCIAL PANEL",
        [GraphType.Monthly]: "MONTHLY FINANCIAL PANEL"
    }

    const searchInput = useRef();
    const main = useRef();
    const toolTopBox = useRef();
    const headitem = useRef();
    const okBtn = useRef();

    let dragging = false;
    const selectedPage = selectedPages;
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity);
    const restoreLinkClass = IsFinancalRestoreDialogOpen ? "restore-link restore-link-disable" : "restore-link";


    useEffect(() => {
        if(IsFinancialBlockDialogOpen){
            setTimeout(() => {
                if(okBtn) { okBtn.current.focus(); okBtn.current.style.setProperty('border', "1px blue solid", 'important'); }
            }, 100);
         }
    }, [IsFinancalRestoreDialogOpen])
    const onBack = (e) => {
        if (e.button === 0) {
            props.onGoBack()
        }
    }
    const getTitle = (periodicity) => {
        const header = headerObj[periodicity]
        return header;
    }

    const getTabPage = useCallback((pageItem) => {
        if (!pageItem) {
            return;
        }
        if (!pageItem.IsShowDetail) {
            const cluster = [];
            _.each(pageItem.RowItems, (rowItem) => {
                cluster.push(getPageItem(rowItem));
            })
            return (
                <div className="page-list">
                    <div className="select-customize">{"Select a row to customize the metric in the panel."}</div>
                    <div className="customize-list">
                        <div className="list-containsize">
                            <div className="list-large">
                                <div className="list-rotate-large">{"LARGE"}</div>
                                <div className="list-medium">
                                    <div className="list-rotate-medium">{"MEDIUM"}</div>
                                    <div className="list-small">
                                        <div className="list-rotate-small">{"SMALL"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="list-items">
                            <SortableList items={cluster} onSortEnd={onSortEnd} onSortMove={onSortMove} helperClass="tablist-group-item-draging" lockAxis="y" distance={1} />
                            <span className="line-divide line-small" />
                            <span className="line-divide line-medium" />
                        </div>
                    </div>
                </div>
            )
        } else {
            const isFilter = pageItem.SearchText.trimLeft().length > 0;
            const headers = isFilter ? pageItem.FilterSource : pageItem.PickListItems;
            return (
                <div className="page-list">
                    <div className="input-searches">
                        <input placeholder="Search" autoComplete="off" className="disableMiniListKeyDown disableDefaultSymbolInput input-search" ref={searchInput} onChange={(e) => props.onSearchBoxTextChange(e.target.value)} onMouseDown={onSearchInputMouseDown} type="text" value={pageItem.SearchText} />
                        {isFilter && <span className="icn-rmove rmove-content" onClick={onClearSearchText} />}
                    </div>
                    {isFilter ?
                        <div className="value-lenght" style={{ textAlign: 'left' }}>
                            <span>{'Search Results: '}</span>
                            <label className="small-bold">{`${pageItem.FilterSource.length} matches`}</label>
                        </div>
                        :
                        <div className="select-metric">{`Select a metric for Row ${pageItem.SelectedRowIndex}`}</div>
                    }
                    <div className="metric-contents">
                        <div className="details-first-page custom-scroll-light">
                            <div className="custom-scroll">
                                <ul className="metric-content-list" id="metric-content-list-VerticalScroll" ref={main}>
                                    {map(headers, (item) => getFinancialBlockMetricItem(item))}
                                </ul>
                                <ScrollBar scrollId="metric-content-list-VerticalScroll" vScroll={true} scrollOnHover={true} />
                            </div>
                        </div>
                    </div>
                    <div ref={toolTopBox} id="rowTooltip" className="tooltip-box" style={{ display: 'none' }}></div>
                </div>
            )
        }
    }, [selectedPages])
    const getPageItem = (rowItem) => {
        if (rowItem && rowItem.HeaderData && rowItem.HeaderData.canToggle && rowItem.SortedHeaderOnDisplay.length === 2) {
            return (
                <li key={rowItem.RowIndex} className="tablist-group-item" onMouseUp={(e) => onRowItemSelectedChanged(e, rowItem)}>
                    <span className="tablist-group-row">ROW <span className="tablist-group-index">{rowItem.RowIndex}</span></span>
                    <span className="tablist-group-message">{rowItem.SortedHeaderOnDisplay[0].DisplayName}</span>
                    <span className="icn-double-arrow" />
                    <span>{rowItem.SortedHeaderOnDisplay[1].DisplayName}</span>
                    <i className="icn-dragable"></i>
                </li>
            )
        } else {
            return (
                <li key={rowItem.RowIndex} className="tablist-group-item" onMouseUp={(e) => onRowItemSelectedChanged(e, rowItem)}>
                    <span className="tablist-group-row">ROW <span className="tablist-group-index">{rowItem.RowIndex}</span></span>
                    <span className="tablist-group-message">{rowItem.SortedHeaderOnDisplay[0].DisplayName}</span>
                    <i className="icn-dragable"></i>
                </li>
            )
        }
    }
    const onRowItemSelectedChanged = (e, rowItem) => {
        if (e.button === 0) {
            if (!dragging) {
                props.onRowItemSelectedChngd(rowItem)
            }
        }
    }
    const onSortEnd = ({ oldIndex, newIndex }) => {
        dragging = false;
        const selectedPage = selectedPages;
        if (!selectedPage) { return; }
        let rowItems = selectedPage.RowItems;
        rowItems = arrayMove(rowItems, oldIndex, newIndex);
        props.onReorderRowItem(rowItems);
    }
    const onSortMove = (e) => {
        e.preventDefault();
        dragging = true;
    }
    const onSearchInputMouseDown = (e) => {
        if (searchInput) { searchInput.current.focus(); }
        e.stopPropagation();
    }
    const onClearSearchText = () => {
        if (searchInput) {
            searchInput.current.value = "";
        }
        props.onSearchTextClear()
    }
    const getFinancialBlockMetricItem = (item) => {
        if (item.IsRootHeader) {
            return (
                <li key={`Category-${item.CategoryId}`} className="financial-metric-item financial-metric-item-root" >{item.Header}</li>
            )
        } else {
            return (
                <li key={`Item-${item.HeaderId}`} className="financial-metric-item" onMouseEnter={(e) => onMetricItemMouseOver(e, item.HeaderId, true)} onMouseLeave={(e) => onMetricItemMouseOver(e, item.HeaderId, false)} onMouseUp={(e) => onSelectedMetricChange(e, item)} >
                    {item.IsSelected && <span className="icn-right-icon" />}
                    {item.Header}
                    {(item.IsMouseOver && item.Description) && <i className="icn-metric-item-hover" onMouseMove={() => onMessageMouseMove(item.Description)} onMouseLeave={onMessageMouseLeave} ref={headitem} />}
                </li>
            )
        }
    }
    const onMetricItemMouseOver = (e, headerId, isMouseOver) => {
        if (e.button === 0) {
            props.onMouseOverMetricItem(headerId, isMouseOver)
            e.preventDefault();
        }
    }
    const onSelectedMetricChange = (e, detailHeader) => {
        if (e.button === 0) {
            props.onSelectMetricChange(detailHeader);
        }
    }
    const onMessageMouseMove = (Description) => {
        toolTopBox.current.style.left = `${headitem.current.getBoundingClientRect().left - main.current.getBoundingClientRect().left - 145}px`;
        toolTopBox.current.style.top = `${headitem.current.getBoundingClientRect().top - main.current.getBoundingClientRect().top + 134}px`;
        toolTopBox.current.style.textAlign = "left";
        toolTopBox.current.style.wordWrap = "break-word";
        toolTopBox.current.style.maxWidth = `${200}px`;
        toolTopBox.current.innerHTML = `<div className="tooltiphold"><span className="tooltiptext">${Description}</span></div>`;
        toolTopBox.current.style.display = '';
    }
    const onMessageMouseLeave = () => {
        toolTopBox.current.style.left = 0;
        toolTopBox.current.style.top = 0;
        toolTopBox.current.innerHTML = '';
        toolTopBox.current.style.display = 'none';
    }
    const onRestoreDeafultClick = (e) => {
        if (e.button === 0) {
            props.setFinancialBlockDialogState({ IsFinancalRestoreDialogOpen: !IsFinancalRestoreDialogOpen })
        }
        okBtn.current.style.border="none"
    }
    const openDialogClass = IsFinancialBlockDialogOpen ? " dialog-open" : ""
    return (
        <div>
            <Modal className={`modal-popup metric-data-toggle${openDialogClass}`} show={IsFinancialBlockDialogOpen}>
                <Modal.Header>
                    <Modal.Title>
                        {selectedPage && selectedPage.IsShowDetail && <span className="icn-back-btn" onMouseDown={onBack}>{"Back"}</span>}
                        <span className='cap-header'>{getTitle(majorPeriodicity)}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="financial-panel">
                        <div className="financial-box oneil-rating-box">
                            <ul role="tablist" className="nav nav-tabs">
                                {map(HeaderTabItemSource, (pageItem) => (
                                    <li key={pageItem.PageIndex} role="presentation" className={pageItem.PageIndex === CurrentPageIndex ? "active" : ""}>
                                        <a role="tab" tabIndex={0} onClick={() => props.setFinancialBlockDialogState(pageItem.PageIndex)}>PAGE <span className="page-index">{pageItem.PageIndex}</span>
                                        </a>
                                    </li>))}
                            </ul>
                            <div className="tab-content">
                                {getTabPage(selectedPage)}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="restore-default">
                        <a className={restoreLinkClass} onMouseDown={onRestoreDeafultClick}>{"Restore Defaults"}</a>
                    </span>
                    <span className="btn-box righter">
                        <button type="button" className="btn btn-xs btn-default btn-secondary cancel" onClick={() => props.setFinancialBlockDialogState(false)} disabled={IsFinancalRestoreDialogOpen}>{"CANCEL"}</button>
                        <button ref={okBtn} type="button" className="btn btn-xs btn-default btn-secondary ok" onClick={props.onSubmitChanges} disabled={IsFinancalRestoreDialogOpen}>{"OK"}</button>
                    </span>
                </Modal.Footer>
                <div className="modal-restore-default">
                    {IsFinancalRestoreDialogOpen ? <RestoreDefaultDialog /> : null}
                </div>
            </Modal>
        </div>

    )
}

const mapStateToProps = (state) => {
    const { CurrentPageIndex, HeaderTabItemSource, IsFinancalRestoreDialogOpen, IsFinancialBlockDialogOpen,
        selectedPages } = state.DatagraphReducers.financialDialogBoxReducer;

    return { CurrentPageIndex, HeaderTabItemSource, IsFinancalRestoreDialogOpen, IsFinancialBlockDialogOpen, selectedPages }
}

const mapDispatchToProps = (dispatch) => ({
    onRowItemSelectedChngd: (rowItem) => dispatch(onRowItemSelectedChngd(rowItem)),
    setFinancialBlockDialogState: (data) => dispatch(setFinancialBlockDialogState(data)),
    onGoBack: () => dispatch(onGoBack()),
    onReorderRowItem: (rowItems) => dispatch(onReorderRowItem(rowItems)),
    onSearchTextClear: () => dispatch(onSearchTextClear()),
    onSearchBoxTextChange: (searchText) => dispatch(onSearchBoxTextChange(searchText)),
    onMouseOverMetricItem: (headerId, isMouseOver) => dispatch(onMouseOverMetricItem(headerId, isMouseOver)),
    onSelectMetricChange: (detailHeader) => dispatch(onSelectMetricChange(detailHeader)),
    onSubmitChanges: () => dispatch(onSubmitChanges())
})
export default connect(mapStateToProps, mapDispatchToProps)(FinancialBlockDialog)
