import ColorPicker from "../../../../../../../RayCustomControls/ColorPicker/ColorPicker.jsx";
import { connect } from "react-redux";
import { DialogHOC } from "./DialogHOC.jsx";
import { Modal } from "react-bootstrap";
import { cancelPatternRecDialogSetting, handleLossEndChange, handleLossStartChange, handlePatternColorChange, handlePivotEndChange, handlePivotStartChange, handleProfitEndChange, handleProfitStartChange, handleTightAreaColorChange, restorePatternRecSetting, savePatternRecSetting, togglePatternRecKPR, togglePRAdvancePattern, togglePRChannelLines, togglePRClimaxTop, togglePRPowerFromPivot, togglePRTightAreaPattern } from "../../../../../../../Actions/PricePanelActions.js";
import { PatternRangeTranslateHelper, PatternRectTranslateHelper, TranslateHelper } from "../../../../../../../Utils/TranslateHelper.js";
import React, { Component } from "react";
import BaseServiceApi from "BaseServiceApi";
import UserInfoUtil from "../../../../../../../Utils/UserInfoUtil.js";
const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

const colorPickerWidth = 101;
class PatternRecognitionDialog extends Component {
    constructor() {
        super();
        this.hasAdvanceEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.PR_ADVANCED_Entitlement);
        this.hasChinaEntitlement = UserInfoUtil.hasChinaEntitlement();
    }

    render() {
        
        return (
            <Modal.Body> 
                <div className="modal-material">
                    <div className="price-lines pattern-recognition">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h5>{PatternRectTranslateHelper.Choose_PR_Display_Setting}</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 color-n-weight">
                                    <span className="form-head">{PatternRectTranslateHelper.Pattern_Color}</span>
                                    <div className="form-field"><ColorPicker width={colorPickerWidth} color={this.props.PatternColor} onChangeComplete={this.props.handlePatternColorChange} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <span className="checkbox">
                                        <label htmlFor="areacheck">
                                            <input type="checkbox" id="areacheck" name="TightAreaPattern" checked={this.props.TightAreaPattern} onClick={this.props.togglePRTightAreaPattern} />
                                            {PatternRectTranslateHelper.Show_Tight_Area}
                                        </label>
                                    </span>
                                </div>
                                <div className="col-sm-12 color-n-weight">
                                    <span className="form-head">{PatternRectTranslateHelper.Tight_Area_Color}</span>
                                    <div className="form-field"><ColorPicker width={colorPickerWidth} color={this.props.TightAreaColor} onChangeComplete={this.props.handleTightAreaColorChange} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <span className="checkbox">
                                        <label htmlFor="pricecheck">
                                            <input type="checkbox" id="pricecheck" name="checkbox" checked={this.props.showKPR} onClick={this.props.togglePatternRecKPR} />
                                            {PatternRectTranslateHelper.Show_Key_Price_Range}
                                        </label>
                                    </span>
                                    
                                    <div className="prs-range">
                                        <h6>{PatternRectTranslateHelper.ch_pr_profitr}</h6>
                                        <div className="prs-range-field">
                                            <input  size='3'
                                                    type="text"
                                                    name="profitStart"
                                                    //defaultValue={this.state.profitStart}
                                                    value={this.props.profitStart}
                                                    onChange={this.props.handleProfitStartChange} 
                                            />
                                            {this.props.hasProfitStartErr && <span className="require">*</span> }
                                            <span className="text">{PatternRectTranslateHelper.to}</span>
                                            <input  size='3'
                                                    type="text"
                                                    name="profitEnd"
                                                    //defaultValue={this.state.profitEnd}
                                                    value={this.props.profitEnd}
                                                    onChange={this.props.handleProfitEndChange} 
                                            />
                                            {this.props.hasProfitEndErr && <span className="require">*</span> }
                                            <span className="select-color" style={{backgroundColor: this.hasChinaEntitlement ? "#CC0100" : "rgba(144, 238, 144, 0.8)", color: "rgba(144, 238, 144, 0.0)" }}> W </span>
                                            <span className="name">{PatternRangeTranslateHelper.PROFIT_RANGE}</span>
                                        </div>
                                    </div>

                                    <div className="prs-range">
                                        <h6>{PatternRectTranslateHelper.ch_pr_pivotr}</h6>
                                        <div className="prs-range-field">
                                            <input  size='3'
                                                    type="text"
                                                    name="pivotStart"
                                                    //defaultValue={this.state.pivotStart}
                                                    value={this.props.pivotStart}
                                                    onChange={this.props.handlePivotStartChange}
                                            />
                                            {this.props.hasPivotStartErr && <span className="require">*</span> }
                                            <span className="text">{PatternRectTranslateHelper.to}</span>
                                            <input  size='3'
                                                    type="text"
                                                    name="pivotEnd"
                                                    //defaultValue={this.state.pivotEnd}
                                                    value={this.props.pivotEnd}
                                                    onChange={this.props.handlePivotEndChange}
                                            />
                                            {this.props.hasPivotEndErr && <span className="require">*</span> }
                                            <span className="select-color" style={{ backgroundColor: "rgba(135, 206, 250, 0.8)", color: "rgba(135, 206, 250, 0.0)" }}> W </span>
                                            <span className="name">{PatternRangeTranslateHelper.PIVOT_RANGE}</span>
                                        </div>
                                    </div>

                                    <div className="prs-range">
                                        <h6>{PatternRectTranslateHelper.ch_pr_lossr}</h6>
                                        <div className="prs-range-field">
                                            <input  size='3'
                                                    type="text"
                                                    name="lossStart"
                                                    //defaultValue={this.state.lossStart}
                                                    value={this.props.lossStart}
                                                    onChange={this.props.handleLossStartChange}
                                            />
                                            {this.props.hasLossStartErr && <span className="require">*</span> }
                                            <span className="text">{PatternRectTranslateHelper.to}</span>
                                            <input  size='3'
                                                    type="text"
                                                    name="lossEnd"
                                                    //defaultValue={this.state.lossEnd}
                                                    value={this.props.lossEnd}
                                                    onChange={this.props.handleLossEndChange}
                                            />
                                            {this.props.hasLossEndErr && <span className="require">*</span> }
                                            <span className="select-color" style={{ backgroundColor: this.hasChinaEntitlement ? "#089800" : "rgba(255, 182, 193, 0.8)", color: "rgba(255, 182, 193, 0.0)" }}> W </span>
                                            <span className="name">{PatternRangeTranslateHelper.LOSS_RANGE}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="check-list">
                                        <li>
                                            <span className="checkbox">
                                                <label htmlFor="pivotcheck">
                                                    <input type="checkbox" id="pivotcheck" name="PowerFromPivot" checked={this.props.PowerFromPivot} onClick={this.props.togglePRPowerFromPivot} />
                                                    {PatternRectTranslateHelper.ch_pr_showpfp}
                                                </label>
                                            </span>
                                        </li>
                                        {this.hasAdvanceEntitlement &&
                                            <li>
                                                <span className="checkbox">
                                                    <label htmlFor="linescheck">
                                                        <input type="checkbox" id="linescheck" name="ChannelLines" checked={this.props.ChannelLines} onClick={this.props.togglePRChannelLines} />
                                                        {PatternRectTranslateHelper.ch_pr_showcl}
                                                    </label>
                                                </span>
                                            </li>
                                        }
                                        {this.hasAdvanceEntitlement &&
                                            <li>
                                                <span className="checkbox">
                                                    <label htmlFor="climaxcheck">
                                                        <input type="checkbox" id="climaxcheck" name="ClimaxTop" checked={this.props.ClimaxTop} onClick={this.props.togglePRClimaxTop} />
                                                        {PatternRectTranslateHelper.ch_pr_showct}
                                                    </label>
                                                </span>
                                            </li>
                                        }
                                        {this.hasAdvanceEntitlement &&
                                            <li>
                                                <span className="checkbox">
                                                    <label htmlFor="patterncheck">
                                                        <input type="checkbox" id="patterncheck" name="AdvancePattern" checked={this.props.AdvancePattern} onClick={this.props.togglePRAdvancePattern} />
                                                        {PatternRectTranslateHelper.ch_pr_showapp}
                                                    </label>
                                                </span>
                                            </li>
                                        }
                                    </ul>
                                    {!this.props.ButtonActive &&
                                        <div className="check-require">{this.props.errorMessage}</div>
                                    }
                                    <div className="rating-default">
                                        <a className="link-restore" onClick={this.props.restorePatternRecSetting}>{TranslateHelper.Restore_Defaults}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        );
    }
}
const mapStateToProps = ({ DatagraphReducers }) =>{
    const { PRReducer } = DatagraphReducers
    const state = {}
    state.showSettingDialog = PRReducer.showSettingDialog;
    state.profitStart = PRReducer.profitStart;
    state.profitEnd = PRReducer.profitEnd;
    state.pivotStart = PRReducer.pivotStart;
    state.pivotEnd = PRReducer.pivotEnd;
    state.lossStart = PRReducer.lossStart;
    state.lossEnd = PRReducer.lossEnd;
    state.TightAreaPattern = PRReducer.TightAreaPattern;
    state.showKPR = PRReducer.showKPR;
    state.PowerFromPivot = PRReducer.PowerFromPivot;
    state.ChannelLines = PRReducer.ChannelLines;
    state.ClimaxTop = PRReducer.ClimaxTop;
    state.AdvancePattern = PRReducer.AdvancePattern;
    state.PatternColor = PRReducer.PatternColor;
    state.TightAreaColor = PRReducer.TightAreaColor;
    state.hasProfitStartErr = PRReducer.hasProfitStartErr;
    state.hasProfitEndErr = PRReducer.hasProfitEndErr;
    state.hasPivotStartErr = PRReducer.hasPivotStartErr;
    state.hasPivotEndErr = PRReducer.hasPivotEndErr;
    state.hasLossStartErr = PRReducer.hasLossStartErr;
    state.hasLossEndErr = PRReducer.hasLossEndErr;
    state.ButtonActive = PRReducer.ButtonActive;
    state.errorMessage = PRReducer.errorMessage;
    state.HeaderText = PatternRectTranslateHelper.PR_Settings;
    state.headerClass = "pattern-recognition"
    return state;
}
const mapDispatchToProps = (dispatch) =>({
    saveLineDialog: ()=> dispatch(savePatternRecSetting()),
    cancelLineDialog: ()=> dispatch(cancelPatternRecDialogSetting()),
    handlePatternColorChange: (PatternColor)=> dispatch(handlePatternColorChange(PatternColor)),
    togglePRTightAreaPattern:()=> dispatch(togglePRTightAreaPattern()),
    handleTightAreaColorChange:(TightAreaColor)=> dispatch(handleTightAreaColorChange(TightAreaColor)),
    togglePatternRecKPR: ()=> dispatch(togglePatternRecKPR()),
    togglePRPowerFromPivot: ()=> dispatch(togglePRPowerFromPivot()),
    togglePRChannelLines: ()=> dispatch(togglePRChannelLines()),
    togglePRClimaxTop: ()=> dispatch(togglePRClimaxTop()),
    togglePRAdvancePattern: ()=> dispatch(togglePRAdvancePattern()),
    restorePatternRecSetting: ()=> dispatch(restorePatternRecSetting()),
    handleProfitStartChange: (event)=> dispatch(handleProfitStartChange(parseInt(event.target.value), event.target.value)),
    handleProfitEndChange: (event)=> dispatch(handleProfitEndChange(parseInt(event.target.value), event.target.value)),
    handlePivotStartChange: (event)=> dispatch(handlePivotStartChange(parseInt(event.target.value), event.target.value)),
    handlePivotEndChange: (event)=> dispatch(handlePivotEndChange(parseInt(event.target.value), event.target.value)),
    handleLossStartChange: (event)=> dispatch(handleLossStartChange(parseInt(event.target.value), event.target.value)),
    handleLossEndChange: (event)=> dispatch(handleLossEndChange(parseInt(event.target.value), event.target.value)),
})
export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(PatternRecognitionDialog))
