import { ExternalDataConstants } from "../../Constants/ExternalDataUploader";
// import { find } from "underscore";
const { ActionTypes } = ExternalDataConstants;

const initState = {
    source: [],
    isNewList: false,
    loading: true,
    viewportWidth: 0.0,
    viewPortHeight: 0.0,
    shouldUpdate: true,
    offsetWidth: "0px",
    recordsPerBody: 0,
    clientHeight: 0,
    columns: [],
    gridAreaWidth: 0.0,
    allColumns: [],
    selectedListId: null,
    visibleRow: [],
    showStartWizard: false,
    selectSymbolCol: false,
    selectDateCol: false,
    dimensions: {
        width: 0,
        height: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    sortCols: [],
    defaultSort: 1,
    symbolColChanged: false,
    dateColChanged: false,
    showDateSelect: false,
    showFinishButton: false,
    pageSize: 14,
    lastPageIndex:0,
    rowSource:[],
    dateFormats: ['MM DD YYYY','MM DD YY','DD MM YY','DD MM YYYY','YYYY MM DD','YY MM DD', 'YYYY DD MM', 'YY DD MM'],
    dateSeparator: {'/':"(slash)",'-':'(dash)','.':'(period)',',':'(comma)','':'(no space)'},
    identifiedSymbolCol: '',
    identifiedDateCol: '',
    identifiedDateFormat: '',
    isNewUser: false,
    emptyListType: 0,
    explorerWidth: 0,
    disableDelete: false
}

const ExternalDataListViewReducer = (state = initState, action) => {
    switch (action.type) {
        case ActionTypes.INIT_GRID_LOAD_SUCCESS:
            return {
                ...state,
                clientHeight: action.clientHeight,
                gridAreaWidth: action.gridAreaWidth,
                offsetWidth: action.offsetWidth,
                recordsPerBody: action.recordsPerBody,
                viewPortHeight: action.viewPortHeight,
                viewportWidth: action.viewportWidth
            }
        case ActionTypes.VIEWPORT_CHANGE_EXTERNAL:
            return {
                ...state,
                explorerWidth: action.explorerWidth
            }
        case ActionTypes.LOAD_EXTERNAL_LIST_SUCCESS:
            return {
                ...state,
                source: action.source,
                rowSource:action.source && action.source.rows ?[...state.rowSource,...action.source.rows]:state.rowSource,
                isNewList: action.isNewList,
                showStartWizard: action.startWizard,
                selectSymbolCol: action.selectSymbol,
                selectDateCol: action.selectDate,
                showDateSelect: action.selectDateFormat,
                showFinishButton: action.selectDate,
                visibleRow: action.source && action.source.rows ? action.source.rows : [],
                emptyListType: action.listType
            }
        case ActionTypes.ON_GRID_DIMENSION_CHANGE_SUCCESS:
            {

                return {
                    ...state,
                    dimensions: action.dimension
                }
            }
        case ActionTypes.SET_VISIBLE_ROWS_DATA_SUCCESS:

            return {
                ...state,
                visibleRow: action.rowsData
            }
        case ActionTypes.ON_CHANGE_COLUMN_SORT_SUCCESS:      
            let existCol = state.sortCols.find((item) => action.sortCols.esFieldName === item.esFieldName);
            if (!existCol) {
                return {
                    ...state,
                    sortCols: [...state.sortCols, action.sortCols]
                }
            }
            else {
                return {
                    ...state,
                    sortCols: [...state.sortCols.map((item) => (item.esFieldName === action.sortCols.esFieldName) ? { ...item, sortAsc: action.sortCols.sortAsc } : item)]
                }
            }
        case ActionTypes.SET_START_WIZARD_ID:
            return {
                ...state,
                startWizardId: action.wizardId,
                identifiedSymbolCol: action.identifiedSymbolCol,
                identifiedDateCol: action.identifiedDateCol,
                identifiedDateFormat: action.identifiedDateFormat,
                showStartWizard: true,
                selectSymbolCol: true,
                selectDateCol: false,
                showFinishButton: false,
                showDateSelect: false,
            }
        case ActionTypes.SET_START_WIZARD_DATE:
            return {
                ...state,
                startWizardId: action.wizardId,
                identifiedSymbolCol: action.identifiedSymbolCol,
                identifiedDateCol: action.identifiedDateCol,
                identifiedDateFormat: action.identifiedDateFormat,
                showStartWizard: true,
                selectSymbolCol: false,
                selectDateCol: true,
                showFinishButton: true,
                showDateSelect: false,
            }
        case ActionTypes.SET_START_WIZARD_DATE_FORMAT:
            return {
                ...state,
                startWizardId: action.wizardId,
                identifiedSymbolCol: action.identifiedSymbolCol,
                identifiedDateCol: action.identifiedDateCol,
                identifiedDateFormat: action.identifiedDateFormat,
                showStartWizard: true,
                selectSymbolCol: false,
                selectDateCol: true,
                showDateSelect: true,
                showFinishButton: false
            }
        case ActionTypes.CHANGE_SYMBOL_COLUMN_IN_SOURCE:
            return {
                ...state,
                source: { ...state.source, source: state.source.headers.filter((item) => {
                        if (item.esFieldName === action.data.esFieldName) {
                            item.isSymbolColumn = true;
                        }else{
                            item.isSymbolColumn = false;
                        }
                        return item;
                    })},
                symbolColChanged: !state.symbolColChanged
            }
        case ActionTypes.CHANGE_DATE_COLUMN_IN_SOURCE:
        return {
            ...state,
            source: { ...state.source, source: state.source.headers.filter((item) => {
                    if (item.esFieldName === action.data.esFieldName) {
                        item.isDateColumn = true;
                        item.dateFormat = action.data.dateFormat;
                    }else{
                        item.isDateColumn = false;
                        // item.dateFormat = null;
                    }
                    return item;
                })},
            dateColChanged: !state.dateColChanged
        }
        case ActionTypes.UPDATE_DATE_FORMAT:
        return {
            ...state,
            source: { ...state.source, source: state.source.headers.filter((item) => {
                    if (item.isDateColumn === true) {
                        item.dateFormat = action.dateFormat;
                    }else{
                        // item.dateFormat = null;
                    }
                    return item;
                })},
            dateColChanged: !state.dateColChanged
        }
        case ActionTypes.SHOW_DATE_FORMAT_SELECT:
            return {
                ...state,
                showDateSelect: true
            }
        case ActionTypes.MOVE_NEXT_STEP:
            return {
                ...state,
                selectSymbolCol: false,
                selectDateCol: true,
                showFinishButton: true
            }
        case ActionTypes.MOVE_PREVIOUS_STEP:
            return {
                ...state,
                selectSymbolCol: true,
                selectDateCol: false,
                showFinishButton: false
            }
        case ActionTypes.ON_UPDATE_LOADER_SUCCESS:
            return {
                ...state,
                loading: action.loading
            }
        case ActionTypes.DISABLE_DELETE_ICON:
            return {
                ...state,
                disableDelete: action.disabled
            }    
        case ActionTypes.CLOSE_DATE_FORMAT_DIALOG:
            return {
                ...state,
                showDateSelect: false,
                showFinishButton: false,                
                showStartWizard: false,
                selectDateCol: false,
                showDateSelect: false
            }
        case ActionTypes.CLEAR_SORT_COLUMNS_SUCCESS:
            return {
                ...state,
                sortCols: action.sortCols
            }
        case ActionTypes.SET_START_WIZARD_ID_EMPTY: 
            return{
                ...state,
                startWizardId: ''
            }
        case ActionTypes.UPDATE_LAST_PAGE_INDEX_SUCCESS:
            return {
                ...state,
                lastPageIndex: action.lastPageIndex
            }
        case ActionTypes.SET_DEFAULT_DATA:
            return {
                ...state,
                rowSource: [],
                lastPageIndex:0
            }
        case ActionTypes.LOAD_EXTERNAL_LIST_FAIL:
            return {
                ...state,
                isNewList:action.isNewList,
                loading: false
            }
        case ActionTypes.SET_EMPTY_NEW_USER:
            return {
                ...state,
                isNewList: action.newUserList
            }
        default:
            return state;
    }
}

export default ExternalDataListViewReducer;