import { DataGraphConstants } from "../../../Constants/DataGraphConstants";
import DatagraphHelper from "../../../Utils/DatagraphHelper";
import { extend } from "underscore";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { SettingsConstants } from "../../../Constants/SettingsConstants";
import SettingsStore from "../../../Stores/ConsoleWindow/Settings/SettingsStore";
import SymbolType from "../../../Constants/SymbolType";
import { getTabCollection, mainTitle } from "../../../Constants/RiSubCategoryType";
import PerformanceWindowStore from "../../../Stores/NavModules/NavDataGraph/PerformanceWindowStore";

const { ActionTypes } = RiPanelConstants;
extend(ActionTypes, SettingsConstants.ActionTypes);

const initState = {
    mainTitle: mainTitle,
    isRIPanelOpen: false,
    activeTab: "",
    tabCollection: [],
    symbolType: null,
    currentTheme: null,
    RiPanelTabCollection: {},
    isAlertAboutOpen: false,
};

export const RiPanelReducer = (state = initState, action) => {
    switch (action.type) {
        case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:{
            const settings = SettingsStore.getConsoleSettings();
            const riPanelSettings = settings.NavDatagraphSettings.RelatedInformationSettings;
            const riPanelViewSettings = DatagraphHelper.getSettingsObject(settings, riPanelSettings);
            const symbolType = settings.NavDatagraphSettings.symbolTypeEnum;
            const tabCollection = state.RiPanelTabCollection[symbolType];
            let activeTab = riPanelViewSettings.ActiveSelectedSubCategory;

            // These symbol types fall under similar settings view but have different tab collection 
            // Both USSTOCK & HISTORICSTOCK falls under stockViewSettings, but RiPanelTabCollection[USSTOCK] is slightly different from RiPanelTabCollection[HISTORICSTOCK]
            // If "Factors" tab is selected in "AAPL", switch to symbol "RR" which is of HISTORICSTOCK does not have "Factors", so in this case default tab "News" will be selected
            if ((symbolType === SymbolType.HISTORICSTOCK || symbolType === SymbolType.HISTORICALMODELSTOCK || symbolType === SymbolType.ECONOMICINDICATOR) && (!tabCollection.some((item) => item.SubCategory === activeTab))) {
                activeTab = tabCollection.find((item) => item.isDefaultTab).SubCategory;
            }

            return {
                ...state,
                currentTheme: settings.currentTheme,
                isRIPanelOpen: riPanelSettings.isRIPanelOpen,
                symbolType,
                activeTab,
                riPanelSettings,
                riPanelViewSettings,
                tabCollection,
                isResearchAnalystOpen: riPanelSettings.isResearchAnalystOpen,
                isFoucsPanelOpen: riPanelSettings.isFoucsPanelOpen,
                isResearchPanelOpen: riPanelSettings.isResearchPanelOpen,
            }
        }
        case ActionTypes.UPDATE_RI_PANEL_SETTINGS_DATA:
            state.riPanelSettings.isResearchAnalystOpen =  action.isResearchAnalystOpen;
            state.riPanelSettings.isFoucsPanelOpen =  action.isFoucsPanelOpen;
            state.riPanelSettings.isResearchPanelOpen =  action.isResearchPanelOpen;
            SettingsStore.saveSettings();
            return {
                ...state,
                isResearchAnalystOpen: action.isResearchAnalystOpen,
                isFoucsPanelOpen: action.isFoucsPanelOpen,
                isResearchPanelOpen: action.isResearchPanelOpen,
            }
        case ActionTypes.TOGGLE_RI_PANEL:
            state.riPanelSettings.isRIPanelOpen = !state.isRIPanelOpen;
            PerformanceWindowStore.setShouldUpdate(false);
            SettingsStore.saveSettings();
            return {
                ...state,
                isRIPanelOpen: !state.isRIPanelOpen
            };
        case ActionTypes.HANDLE_TAB_CHANGE:
            state.riPanelViewSettings.ActiveSelectedSubCategory = action.newTab;
            SettingsStore.saveSettings();
            return {
                ...state,
                activeTab: action.newTab
            }
        case ActionTypes.TOGGLE_ALERT_ABOUT_DIALOG:
            return {
                ...state,
                isAlertAboutOpen: action.isAlertAboutOpen
            }
        case DataGraphConstants.ActionTypes.INIT_SETTINGS_TO_REDUCER:
            return {
                ...state,
                RiPanelTabCollection: getTabCollection()
            }
        default:
            return state;
    }
}