import { connect } from 'react-redux';
import { Glyphicon } from "react-bootstrap";
import VirtualList from "VirtualList";
import { onOpenNewsSettingDialog, reloadRiNews, setActiveNewsItem, setLoadingState } from "../../../../../Actions/RelatedInfoPanelActions/RiPanelActions";
import React, { Component } from 'react';
import { RiPanelNewsTransLateHelper, TranslateHelper } from '../../../../../Utils/TranslateHelper';

class RiPanelNewsInfo extends Component {
    constructor(props) {
        super(props);
        this.openNewsSettingDialog = this.openNewsSettingDialog.bind(this);
        this.reloadNews = this.reloadNews.bind(this);
        this.setItemActive = this.setItemActive.bind(this)
    }

    openNewsSettingDialog() {
        this.props.onOpenNewsSettingDialog(true)
    }

    reloadNews() {
        this.props.reloadRiNews(this.props.symbolInfo)
    }

    setItemActive(e, item, index) {
        this.props.setActiveNewsItem(index);
    }

    getItemRenderer = (item, top, index) => {
        if (item) {
            const isActive = this.props.activeIndex === index;

            return (
                <div key={index} className={isActive ? "list-group-item active" : "list-group-item"} style={{ top: top }}
                    onMouseDown={(e) => this.setItemActive(e, item, index)}>
                    <a target="_blank" rel="noopener noreferrer" href={item.url}>
                        <div key={index} className="item medium-normal">
                            {item.title}
                        </div>
                    </a>
                    <div className="bottom-txt xx-small-normal">{item.strPublishDt} - {item.sourceName}</div>
                </div>
            )
        }
    }

    render() {
        const { isLoading, listItems } = this.props;

        return (
            <div className="newsInfo">
                <div className="setting-bar-fundamental">
                    <div className="setting-news medium-normal" onClick={this.openNewsSettingDialog}>
                        <Glyphicon glyph="cog" />
                    </div>
                    <div className={isLoading ? "reload-news font-green medium-normal" : "reload-news medium-normal"} onClick={this.reloadNews}>
                        <Glyphicon glyph="refresh" />
                    </div>
                </div>
                {isLoading ?
                    <div className="news-message large-normal"><p>{TranslateHelper["Loading"]}</p></div>
                    :
                    listItems.length === 0 ?
                        <div className="news-message large-normal"><p>{RiPanelNewsTransLateHelper.NO_NEWS_AVAILABLE}</p></div>
                        :
                        <div className="list-group" onMouseDown={this.onMouseDown}>
                            <VirtualList id="RIPanelNewsInfo" ref={(ref) => { this.RIPanelNewsInfoList = ref }} items={listItems}
                                itemHeight={80}
                                itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                                showScrollOnHover={true}
                            />
                        </div>}
            </div>
        );
    }
}

const mapStateToProps = ({ RelatedInfoPanelReducers }) => {
    const { isLoading, listItems, activeIndex, symbolInfo } = RelatedInfoPanelReducers.RiPanelNewsInfo;
    return { isLoading, listItems, activeIndex, symbolInfo };
};

const mapDispatchToProps = (dispatch) => ({
    onOpenNewsSettingDialog: (isOpen) => dispatch(onOpenNewsSettingDialog(isOpen)),
    reloadRiNews: (symbolInfo) => { dispatch(setLoadingState(true)), dispatch(reloadRiNews(symbolInfo)) },
    setActiveNewsItem: (index) => dispatch(setActiveNewsItem(index))
});

export default connect(mapStateToProps, mapDispatchToProps)(RiPanelNewsInfo);