import ConsoleActions from "Actions/ConsoleActions";

const byPassValidationErrorCodes =  [10401, 30111, 40104, 40103, 50001, 50002, 30201, 30400, 70000, 70001, 404, 30102]; // Not show Exception window (special cases - password change, alerts, etc)
/* List of Errors
    SESSION_INVALID_ERROR: 102,
    PASSWORD_CHANGE_ERROR: 10401,
    SQL_TIMED_OUT: 10201,
    SOCKET_EXCEPTION_ERR_DB: 10202,
    SOCKET_EXCEPTION_ERR_SVC: 10301,
    INVALID_PARAMETER: 10101,
    EXPIRED_CACHE_ID: 30111,
    LIST_NOT_EXISTED: 30102,
    SHARED_PERMISSION_REMOVED: 40104,
    SHARED_PERMISSION_CHANGEDTO_READONLY: 40103,
    MAX_ALERTS_REACHED: 50001,
    ALERTS_EXCEPTION: 50002,
    INSTRUMENT_ALERTS_REACHED: 50003,
    COLSET_NOT_EXISTED: 30201,
    CUSTOMCOL_DELETE: 30400,
    LIST_CUSTOMCALCUPSERT_FAILED: 70000,
    LIST_CUSTOMCALC_FAILED: 70001
*/


class ServiceErrorValidation {

    exceptionalErrorCaseHandle(response, ErrorType) {
        if (response && response.responseHeader && response.responseHeader.error) {
            if (byPassValidationErrorCodes.includes(response.responseHeader.errorCode)) {
                return true;
            }
            else if (response.responseHeader.errorType == ErrorType.ERROR_Duplicate || response.responseHeader.errorType == ErrorType.ERROR_Invalid) {
                return true;
            }
            else if ((response.responseHeader.errorCodeData.errorCode == 50001 || response.responseHeader.errorCodeData.errorCode == 50003)) {
                return true;
            }
            else if (response.responseHeader.errorType === null) {

                switch (response.responseHeader.serviceUriName) {
                    case "columnsetfolder.pb":
                    case "tempbasic.pb":
                    case "subscription.pb":
                    case "externalfileuploader.pb":
                    case "ownershipreportheader.pb":
                    case "updateextheaders.pb":
                    case "upsertcustomcolinfo.pb":
                    case "findgetblocks2.pb":
                    case "findgetblockses.pb":
                        return true;
                    default:
                        return false;

                }
            }
        }
        return false;
    }


    validateResponse(response, ErrorType) {
        if (response.responseHeader) {
            if (!response.responseHeader.error)
                return true;
            else if (this.exceptionalErrorCaseHandle(response, ErrorType)) // List Api Call
                return true;
            else {
                response.responseHeader.error && ConsoleActions.exceptionHandle(response.responseHeader);
                return false;
            }
        }
        else {
            return true;
        }
    }
}

const serviceErrorValidation = new ServiceErrorValidation();
export default serviceErrorValidation;
