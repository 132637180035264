const { ActionTypes } = RiPanelConstants;
import { RiPanelConstants } from "../../../../Constants/RiPanelConstants";
import { SettingsConstants } from "SettingsConstants";
import UserInfoUtil from "UserInfoUtil";
import { HubUserRole, ListType } from "../../../../Constants/HubRIPanelConstants.js";

const initialState = {
    OwnershipDatasource: [],
    loading: true,
    Initialized: false,
    hubUserRole: getHubUserRole(),
    listType: ListType.TopOwnerView
}

function getHubUserRole() {
    const user = UserInfoUtil.getUserInfo();
    let role = HubUserRole.None;
    if (user) {
        switch (user.hubRole) {
            case 1:
                role = HubUserRole.Manager;
                break;
            case 2:
                role = HubUserRole.SalesRep;
                break;
            default:
                role = HubUserRole.None;
        }
    }
    return role;
}

const RiPanelOwnershipInfo = (state = initialState, action) => {
    switch (action.type) {
        case SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT:
            return {
                ...initialState, hubUserRole: getHubUserRole()
            }
        case ActionTypes.OWNERSHIP_INFO_DEFAULT_STATE: {
            return { ...state, ...action.newState }
        }
        case ActionTypes.OWNERSHIP_INFO_SET_DATA: {
            return { ...state, ...action.newState }
        }
        case ActionTypes.OWNERSHIP_INFO_CHANGE_DISPALY_VIEW: {
            const view = action.view;
            state.listType = view;
            return { ...state }
        }

        case SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED: {
            state.OwnershipDatasource = [];
            state.loading = true;
            state.Initialized = undefined;
            state.listType = ListType.HubListView;

            return { ...state }
        }

        case ActionTypes.OWNERSHIP_INFO_UPDATE_HUB_ROLE: {
            state.hubUserRole = getHubUserRole();
            return { ...state }
        }

        default: return { ...state }
    }
}

export default RiPanelOwnershipInfo