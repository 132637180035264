import React, { Component } from "react";
import { PopupTypes } from "../../Constants/ScheduledListsConstants";
import { FrequencyType } from "./Frequency"
import moment from "moment";
import DayOfWeek from "DayOfWeek";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";

//hour: the selected hour
//time: the selected time
//onSelected(value) the function that will be executed when hour or minute is clicked
export default class TimeSelector extends Component {
    constructor(props) {
        super(props);
        this.getHoursView = this.getHoursView.bind(this);
        this.getMinutesView = this.getMinutesView.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.dismissPanel = this.dismissPanel.bind(this);
        this.state = {
            hour: this.props.hour, /* ? this.props.hour : 8, */
            minute: this.props.minute /* ? this.props.minute : 30 */
        }

        this._type = {
            minute: "minute",
            hour: "hour"
        }
    }

    getHoursView() {
        let amHours = [];
        let pmHours = [];
        for (let i = 0; i <= 23; i++) {
            let h = i > 9 ? i.toString() : `0${i}`;
            let className = "foursquare";
            if (i === this.state.hour) {
                className = `${className} foursquareSelected`;
            }
            let element = <div key={h} className={className}
                name={this._type.hour}
                onMouseDown={this.onMouseDown}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}>{h}</div>;
            if (i <= 11) {
                amHours.push(element);
            }
            else {
                pmHours.push(element);
            }
        }
        return [amHours, pmHours];
    }

    getMinutesView() {
        let minutes = [];
        for (let i = 0; i <= 55; i += 5) {
            let tmp = i > 9 ? i.toString() : `0${i}`;
            let className = "foursquare";
            if (i === this.state.minute) {
                className = `${className} foursquareSelected`;
            }

            minutes.push(
                <div key={tmp} className={className} name={this._type.minute}
                    onMouseDown={this.onMouseDown}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}>{tmp}</div>
            )
        }

        return minutes;
    }

    onMouseEnter(e) {

        e.target.className = "foursquare foursquareSelected";
    }

    onMouseLeave(e) {
        let val = e.target.innerHTML;
        let name = e.target.getAttribute("name");

        if (name === this._type.minute && Number.parseInt(val) === this.state.minute) {
            return;
        }
        else if (name === this._type.hour && Number.parseInt(val) === this.state.hour) {
            return;
        }

        e.target.className = "foursquare";
    }

    onMouseDown(e) {
        e.target.className = "foursquare foursquareSelected";
        let val = e.target.innerHTML;
        if (!isNaN(val)) {
            let name = e.target.getAttribute("name");
            switch (name) {
                case this._type.minute:
                case this._type.hour:
                    this.setState({ [name]: Number.parseInt(val) }, () => {

                        let prefix = this.state.hour >= 12 ? LocalizationStore.getTranslatedData("ss_pm","PM") : LocalizationStore.getTranslatedData("ss_am","AM");
                        let formatHour = 8;
                        formatHour = this.state.hour > 12 ? this.state.hour - 12 : this.state.hour
                        formatHour = formatHour > 9 ? formatHour.toString() : `0${formatHour}`;
                        let minute = this.state.minute > 9 ? this.state.minute.toString() : `0${this.state.minute}`;
                        let selectedTime = `${formatHour}:${minute} ${prefix}`;
                        //console.log("Selected Time %s", selectedTime);
                        let result = { displayName: selectedTime, id: this.state }
                        if (this.props.onSelected) {
                            this.props.onSelected(PopupTypes.TimeSelector, result);
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    static getDisplayName(schTime) {
        //TODO check schTime 
        let time = schTime.split(":");

        let hour = time[0];
        let minute = time[1];
        let formatHour = parseInt(hour) > 12 ? parseInt(hour) - 12 : parseInt(hour)
        formatHour = formatHour > 9 ? formatHour.toString() : `0${formatHour}`;
        let selectedTime = `${formatHour}:${minute}`;
        let prefix = parseInt(hour) >= 12 ? LocalizationStore.getTranslatedData("ss_pm","PM") : LocalizationStore.getTranslatedData("ss_am","AM");
        return {
            displayName: `${selectedTime} ${prefix}`,
            id: { hour: parseInt(hour), minute: parseInt(minute) }
        }
    }

    static dayOfweekCnToEn(dayOfWeek){
        switch(dayOfWeek){
            case 6: return 0;
            case 0: return 1;
            case 1: return 2;
            case 2: return 3;
            case 3: return 4;
            case 4: return 5;
            case 5: return 6;
            default: break;
        }
    }
    static getUTCTime(frequency, hour, minute, zone) {
        let today = new Date();
        let utcDate = moment.utc(today);
        let dayOfWeek = utcDate.tz(zone).weekday();
        if(moment.locale()=='zh-cn'){
            dayOfWeek = this.dayOfweekCnToEn(dayOfWeek)
        }
        let localDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hour, minute);
        let offset = 0;

        switch (frequency) {
            case FrequencyType.Daily:
                offset = 0;
                break;
            case FrequencyType.Monday:
                offset = DayOfWeek.Monday - dayOfWeek;
                break;
            case FrequencyType.Tuesday:
                offset = DayOfWeek.Tuesday - dayOfWeek;
                break;
            case FrequencyType.Wednesday:
                offset = DayOfWeek.Wednesday - dayOfWeek;
                break;
            case FrequencyType.Thursday:
                offset = DayOfWeek.Thursday - dayOfWeek;
                break;
            case FrequencyType.Friday:
                offset = DayOfWeek.Friday - dayOfWeek;
                break;
            case FrequencyType.Saturday:
                offset = DayOfWeek.Saturday - dayOfWeek;
                break;
            case FrequencyType.Sunday:
                offset = DayOfWeek.Sunday - dayOfWeek;
                break;
            default:
                break;
        }
        let nextRunDate = moment.tz(localDate, "MM/DD/YYYY h:mm:ss A", zone).add(offset, "days");
        
        if (moment(localDate).add(offset, "days") < (moment())) {
            if (frequency === FrequencyType.Daily) {
                nextRunDate = nextRunDate.add(1, "days");
            }
            else {
                nextRunDate = nextRunDate.add(7, "days");
            }
        }       

        return nextRunDate.utc().format();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.dismissPanel, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.dismissPanel, false);
    }

    dismissPanel(e) {
        //close this panel if it is out of this component is clicked. 
        if (this.nodes && !this.nodes.contains(e.target)) {
            if (this.props.onCancel) {
                this.props.onCancel();
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.hour && this.props.hour !== nextProps.hour) {
            this.setState({ hour: nextProps.hour });
        }

        if (nextProps.minute && this.props.minute !== nextProps.minute) {
            this.setState({ minute: nextProps.minute });
        }
    }

    render() {
        let hours = this.getHoursView();
        let minutes = this.getMinutesView();

        return (<div className="scheduled-lists select-timer" ref={(node) => this.nodes = node}>
            <div className="title">{LocalizationStore.getTranslatedData("ss_selecttime","Select a Time:")}</div>
            <div className="content">
                <div className="left">
                    <div className="subject">{LocalizationStore.getTranslatedData("ss_hour","HOUR")}</div>
                    <div>
                        <div className="hourLeft bold">{LocalizationStore.getTranslatedData("ss_am","AM")}</div>
                        <div className="hourRight">
                            {hours[0]}
                        </div>
                    </div>

                    <div>
                        <div className="hourLeft bold">{LocalizationStore.getTranslatedData("ss_pm","PM")}</div>
                        <div className="hourRight">
                            {hours[1]}
                        </div>
                    </div>
                </div>
                <div className="right">
                    <div className="subject">{LocalizationStore.getTranslatedData("ss_minute","MINUTE")}</div>
                    <div className="minute">
                        {minutes}
                    </div>
                </div>
            </div>
        </div>)
    }
}