import React, { PureComponent } from "react";
import { BreadCrumbContent } from '../Presentational';
import ErrorBoundary from 'ErrorBoundary';

class BreadCrumb extends PureComponent {

  render() {
    return (
      <div className="comp-breadcromb-box">
        <ErrorBoundary><BreadCrumbContent /></ErrorBoundary>
      </div>
    )
  }
}


export default BreadCrumb;
