import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from 'BaseServiceApi';

class GroupsQuadDataRequest extends BaseServiceRequest {

    constructor(osid, fromdate, enddate, code, type, includeFilterData) {
        super();
        super.addRequestUri("quaddata.pb");

        const ReqProto = BaseServiceApi.rayData["QuadRequestData"]
        const quadreqData = new ReqProto();

        quadreqData.Osid = osid;
        quadreqData.FromDate = fromdate;
        quadreqData.EndDate = enddate;
        quadreqData.Code = code;
        quadreqData.OsidType = type;
        quadreqData.IncludeFilterData = includeFilterData;

        const reqcontent = quadreqData.encode();
        super.setContent(reqcontent);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.GROUPS;
    }

    getMessageName() {
        return "QuadGraphResponse";
    }
}

export default GroupsQuadDataRequest;
