import cellWidth from 'text-width';
import { connect } from 'react-redux';
import GraphApi from "ServiceApi/Apis/GraphApi";
import ReactHtmlParser from "react-html-parser";
import StringUtil from 'StringUtil';
import VirtualList from "VirtualList";
import React, { Component } from 'react';
import { setVisitedItemsGrey, updateTooltipStyle } from 'Actions/RelatedInfoPanelActions/RiPanelActions';

class RiPanelBrokerReports extends Component {

    showTooltip = (e) => {
        const style = {};
        if (e.clientY > window.innerHeight - 70 || (e.target && e.target.nextSibling && e.clientY + e.target.nextSibling.offsetHeight > window.innerHeight - 70)) {
            style.top = 'auto';
            style.bottom = '20px';
        }
        this.props.updateTooltipStyle(style);
    }

    getDownloadBrokerReportWebRequest = (id, osid) => {
        if (this.url && id) {
            GraphApi.getPdfRequestWebURL(id).promise.then(
                (result) => {
                    const file = new Blob(
                        [result],
                        { type: 'application/pdf' });

                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        // IE & Edge Open links made by createObjectURL in IE11
                        window.navigator.msSaveOrOpenBlob(file, `${StringUtil.getGuid()}.pdf`);
                    }
                    else {
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL, "_blank");
                    }

                }
            )
            this.props.setVisitedItemsGrey(id, osid);
        }
    }

    getItemRenderer(item, top, index) {
        if (item) {
            const isActive = this.props.brokerReportsData.activeIndex === index;
            const width = cellWidth(item.analystNames, {
                family: 'calibrib',
                size: 13
            });
            const analystNames = item.analystNames !== null ? ReactHtmlParser(item.analystNames.replace(/,/g, ";&nbsp;")) : item.analystNames;
            const analystNamesForTooltip = item.analystNames !== null ? ReactHtmlParser(item.analystNames.replace(/,/g, ";&nbsp;&nbsp;")) : item.analystNames;
            const headingClass = item.isVisited ? 'visited' : '';
            const analystClass = width > 219;

            let heading = item.headline;
            const maxWidth = 228;
            let pgsWidth = 0;
            let lineNo = 1;
            let finalString = "";
            let textLine = '';
            const words = heading.split(' ');

            for (let i = 0; i < words.length && lineNo < 4; i++) {
                let wrappedText = textLine.concat(words[i], ' ');
                let textWidth = parseInt(cellWidth(wrappedText, { family: 'calibri', size: 14 }));

                if (lineNo === 3) {
                    pgsWidth = parseInt(cellWidth(` (${item.numPages} pgs)`, { family: 'calibri', size: 13 }));
                }
                if (textWidth > maxWidth - pgsWidth && i > 0) {
                    if (lineNo === 3 && i < words.length) {
                        let voidWidth = 0;
                        const ellipseWidth = parseInt(cellWidth(`...`, { family: 'calibri', size: 14 }) + cellWidth(` (${item.numPages} pgs)`, { family: 'calibri', size: 13 }));
                        while (textWidth >= maxWidth - ellipseWidth - voidWidth) {
                            wrappedText = wrappedText.substring(0, wrappedText.length - 1);
                            textWidth = parseInt(cellWidth(wrappedText, { family: 'calibri', size: 14 }));

                            if (textWidth < maxWidth - ellipseWidth && textWidth + ellipseWidth > 220) {
                                voidWidth = textWidth + ellipseWidth - 220;
                            }
                        }
                        finalString = finalString.concat(wrappedText, '...');
                    }
                    else {
                        finalString = finalString.concat(textLine);
                    }

                    textLine = words[i].concat(' ');
                    lineNo++;
                }
                else {
                    textLine = wrappedText;
                }
            }

            if (finalString === "" || lineNo <= 3) { finalString = finalString.concat(textLine); }

            heading = finalString;

            return (
                <div className={isActive ? "list-group-item active" : "list-group-item"} style={{ top: top }} key={index}>
                    <div className="news-time">{item.strReportDt}</div>
                    <a className="heading-pages" ref={(ref) => { this.url = ref }} target="_blank" onClick={() => this.getDownloadBrokerReportWebRequest(item.id, item.osid)}>
                        <span className={`heading item ${headingClass}`} key={index}>
                            <span className="text">{heading}</span>
                            <span className="pages" key={index}> ({item.numPages} pgs)</span>
                        </span>
                    </a>
                    <div className="aurthor-n-organization">
                        <div className="aurthor">
                            <div className="text" onMouseEnter={this.showTooltip}>{analystNames}</div>
                            {analystClass && <span style={this.props.brokerReportsData.style} className="tooltip tipsy yellow">{analystNamesForTooltip}</span>}
                        </div>
                        <div className="organization">{item.brokerName}</div>
                    </div>
                </div>
            )
        }
    }

    render() {
        const { totalCount, isLoading, report, nodata } = this.props.brokerReportsData;
        return (
            <div className="newsInfo broker-report">
                <div className="setting-bar-fundamental">
                    <div className="title">
                        <h6>Reports</h6>
                        <span className="count">({totalCount})</span>
                        <div className="clearfix"></div>
                    </div>
                </div>
                {isLoading ?
                    <div className="news-message"><p>Loading Broker Reports...</p></div>
                    :
                    nodata === true ?
                        <div className="news-message"><p>No Broker Reports Available</p></div>
                        :
                        <div className="list-group">
                            <VirtualList id="RiPanelBrokerReports" ref={(ref) => { this.RiPanelBrokerReports = ref }}
                                items={report}
                                itemHeight={130}
                                itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                                showScrollOnHover={true}
                            />
                        </div>}
            </div>
        )
    }
}

const mapStateToProps = ({ RelatedInfoPanelReducers }) => ({
    brokerReportsData: RelatedInfoPanelReducers.RiPanelBrokerReports
});

const mapDispatchToProps = (dispatch) => ({
    setVisitedItemsGrey: (id, osid) => dispatch(setVisitedItemsGrey(id, osid)),
    updateTooltipStyle: (style) => dispatch(updateTooltipStyle(style))
});

export default connect(mapStateToProps, mapDispatchToProps)(RiPanelBrokerReports);