import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';

class GetPriceInfoRequest extends BaseServiceRequestIIS {
  constructor(osid, periodicity, type, rt) {
    super();
    super.addRequestUri("GetStockPrices");
    super.addPathParameter("Osid", osid);
    super.addPathParameter("graphType", periodicity);
    super.addPathParameter("symbolType", type);
    super.addPathParameter("rt", rt);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return undefined;
  }
}

export default GetPriceInfoRequest;
