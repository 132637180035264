import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Button, ButtonToolbar, ButtonGroup } from "react-bootstrap";
import GridBody from "./GridBody";
import HeaderRow from "./HeaderRow";
import ColumnAdornerRow from "./ColumnAdorner";
import { isEmpty, each, find, map, debounce, isEqual, clone, contains, intersection, indexBy, isNull, isUndefined, has } from "underscore";
import PortalContainer from "PortalContainer";
import ScreenSummary from "../../RayCustomControls/ScreenSummary/ScreenSummary.jsx";
import CustomColumnFilter from "../ListViewCustomFilter/CustomColumnFilter.jsx";
import ColumnCustomFilterControl from "../ListViewCustomFilter/ColumnCustomFilterControl.jsx";
import SummaryStatStore from "../ListSummaryStat/SummaryStatStore";
import SummaryStat from "../ListSummaryStat/SummaryStat.jsx";
import ListManagerViewType from "ListManagerViewType";
import ListManagerTabType from "ListManagerTabType";
import SettingsStore from "SettingsStore";
import ListActions from "ListActions";
import GridStore from "GridStore";
import ListStore from 'ListStore';
import GridActions from "GridActions";
import BrowserUtil from "BrowserUtil";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ContextMenuConstants from "ContextMenuConstants";
import { Shortcuts } from 'react-shortcuts';
import ScrollBar from "ScrollBar";
import BaseServiceApi from "BaseServiceApi";
import { ReactHeight } from "react-height";
import LocalizationStore from "../../Stores/Localization/LocalizationStore.js";
import BrowserFilterStore from "../../Stores/NavModules/NavList/TabONeil/BrowserFilterStore";
import ResizeActions from "Actions/ResizeActions.js";
import { getListColumnPickList, toggleScreenOnOff } from '../../Actions/OwnerShipActions';
import ListExplorerStore from "ListExplorerStore";
import StringUtil from 'StringUtil';
import WebSyncUtil from "../../Utils/WebSyncUtil";
import FormatterUtil from "FormatterUtil";
import ManageLayoutStore from "../../Stores/NavModules/NavList/Column/ManageLayoutStore";
import ThemeHelper from "ThemeHelper";
import KeyCodes from "../../Constants/KeyCodes";

const cellWidth = require("text-width");

let ListColumnFilterType = BaseServiceApi.rayData["ListColumnFilterType"];
let timeout = null;
let interval = null;
let quoteInterval = null;
let currentStartIndex = 0;
let currentScrollWheel = 0;
let mousePos = {
    cX: 0,
    cY: 0
}
let stopColumnAutoScroll = true;
const DataItemIdFlag = -3, defaultDistColumnId = 61;
const positiveGround = ' nasdaq-positive-symbol-triggered';
const negativeGround = ' nasdaq-negative-symbol-triggered';
let isLastIndex = false;
let byPassDropEvent = false;
export default class GridControl extends Component {
    constructor(props) {
        super(props);
        this.handleMouseUp = this.handleMouseUp.bind(this);
        this.getFrozenAreaWidth = this.getFrozenAreaWidth.bind(this);
        this.getNonFrozenViewPortWidth = this.getNonFrozenViewPortWidth.bind(this);
        this.setViewPortSizes = this.setViewPortSizes.bind(this);
        this.generateItemOnScroll = this.generateItemOnScroll.bind(this);
        this.checkMoreData = this.checkMoreData.bind(this);
        this.handleColumnReorder = this.handleColumnReorder.bind(this);
        this.handleColumnResize = this.handleColumnResize.bind(this);
        this.generateResizeAdorner = this.generateResizeAdorner.bind(this);
        this.handleColumnResizeFrozen = this.handleColumnResizeFrozen.bind(this);
        this.setResizeState = this.setResizeState.bind(this);
        this.onColumnDrag = this.onColumnDrag.bind(this);
        this.getDraggedOverObject = this.getDraggedOverObject.bind(this);
        this.onAdornerMouseUp = this.onAdornerMouseUp.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleColumnSort = this.handleColumnSort.bind(this);
        this.handleColumnCustomFilter = this.handleColumnCustomFilter.bind(this);
        this.outsideColumnFilterClick = this.outsideColumnFilterClick.bind(this);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.onCustomFilterContextMenuClick = this.onCustomFilterContextMenuClick.bind(this);
        this.onAutoSizeAllColumnsWidthClick = this.onAutoSizeAllColumnsWidthClick.bind(this);
        this.onSplitterClick = this.onSplitterClick.bind(this);
        this.onClearAllFilter = this.onClearAllFilter.bind(this);
        this.onHandleClickOnOff = this.onHandleClickOnOff.bind(this);
        this.setScrollPos = this.setScrollPos.bind(this);
        this.onContextMenuClickEmptyGridArea = this.onContextMenuClickEmptyGridArea.bind(this);
        this.handleShortcuts = this.handleShortcuts.bind(this);
        this.highlightBottomBorder = this.highlightBottomBorder.bind(this);
        this.addSymbol = this.addSymbol.bind(this);
        this.highlightActiveRow = this.highlightActiveRow.bind(this);
        this.setPageScroll = this.setPageScroll.bind(this);
        this.handleVerticalScroll = this.handleVerticalScroll.bind(this);
        this.handleHorizontalScroll = this.handleHorizontalScroll.bind(this);
        this.handleWheelScroll = this.handleWheelScroll.bind(this);
        this.applyColumnFilter = this.applyColumnFilter.bind(this);
        this.scrollHorizentalGrid = this.scrollHorizentalGrid.bind(this);
        this.handleDragMove = this.handleDragMove.bind(this);
        this.changeGridTypeBehavior = this.changeGridTypeBehavior.bind(this);
        this.scrollGrid = this.scrollGrid.bind(this);
        this.setDragGridScroll = this.setDragGridScroll.bind(this);
        this.getColumnWidth = this.getColumnWidth.bind(this);
        this.getMouseOverColumnIndex = this.getMouseOverColumnIndex.bind(this);
        this.getfrozenColumnLimit = this.getfrozenColumnLimit.bind(this);
        this.handleVerticalScrollMouseUp = this.handleVerticalScrollMouseUp.bind(this);
        this.updateColumnWidth = this.updateColumnWidth.bind(this);
        this.columnFilterUpdate = this.columnFilterUpdate.bind(this);
        this.handlePasteEvent = this.handlePasteEvent.bind(this);
        this.updateWindow = this.updateWindow.bind(this);
        this.onColumnResizerDoubleClick = this.onColumnResizerDoubleClick.bind(this);
        this.getMaxHeightOfSummaryState = this.getMaxHeightOfSummaryState.bind(this);
        this.getSummaryHeight = this.getSummaryHeight.bind(this);
        this.summaryStatToggle = this.summaryStatToggle.bind(this);
        this.adjustHeight = this.adjustHeight.bind(this);
        this.isPortfolioList = this.isPortfolioList.bind(this);
        this.getFlagStatus = this.getFlagStatus.bind(this);
        this.selectedView = this.props.selectedView;
        this.quoteBoard = false;
        this.colSeq = null;
        this.textSize = ListStore.getTextFontSize();
        this.dragScrollStop = true;
        this.colDataResize = undefined;
        this.customWidth = undefined;
        this.resizeDone = false;
        this.updateWidthHeightGrid = this.updateWidthHeightGrid.bind(this);
        this.updateLastPriceData = this.updateLastPriceData.bind(this);
        this.handleHeaderMouseUp = this.handleHeaderMouseUp.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.getRowsForNewDataNew = this.getRowsForNewDataNew.bind(this);
        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
        this.state = {
            viewportWidth: 0.0,
            viewPortHeight: 0.0,
            shouldUpdate: true,
            offsetWidth: "0px",
            recordsPerBody: 0,
            clientHeight: 0,
            currentPage: 1,
            columns: [],
            resizeLeft: 0.0,
            isResizing: false,
            frozenAreaWidth: 0.0,
            frozenAreaWidthCalculatedLength: 0, 
            frozenColumns: [],
            nonFrozenAreaWidth: 0.0,
            allColumns: [],
            draggedColumn: undefined,
            leftOrRightDrop: undefined,
            dropColumn: undefined,
            isColumnReorder: false,
            source: [],
            selectedListId: null,
            openScreenSummary: false,
            customFilter: false,
            isOn: this.props.screenValue,
            gridTypeTable: false,
            visibleRow: [],
            dimensions: {
                width: 0,
                height: 0,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            },
            summaryStatOpen: SummaryStatStore.getState().summaryStatIsOpen,
            isUpdateNotRequired: false,
            tooltip: {
                style: {},
                isShowTooltip: false,
                text: ''
            }
        }
        this.CustomColumnFilters = null;
        this.spliterColumns = false;
        this.frozenDiv = null;
        this.nonFrozenDiv = null;
        this.horizontalScroll = null;
        this.verticalScroll = null;
        this.loadingMore = false;
        this.columnResizeSeparator = this.props.columnResizeSeparator;
        this.vH = 0;
        this.visibleRows = null;
        this.QuoteSymbols = [];
        this.visibleSymbols = [];
        this.lastQuoteTime = new Date();
        this.shouldUpdateStreamingData = true;
        this.count = 0;
        this.currentActiveRowIndex = -1;
    }



    componentWillUnmount() {
        if (BrowserUtil.getBrowserName() == 'Firefox') {
            if (this.frozenDiv) this.frozenDiv.removeEventListener('DOMMouseScroll', this.handleWheelScroll);
            if (this.nonFrozenDiv) this.nonFrozenDiv.removeEventListener('DOMMouseScroll', this.handleWheelScroll);
        }
        else {
            if (this.frozenDiv) this.frozenDiv.removeEventListener('mousewheel', this.handleWheelScroll);
            if (this.nonFrozenDiv) this.nonFrozenDiv.removeEventListener('mousewheel', this.handleWheelScroll);
        }
        if (BrowserUtil.getBrowserName() == 'Safari') {
            window.removeEventListener('paste', this.handlePasteEvent, false);
        }
        document.removeEventListener('mouseup', this.handleMouseUp, false);
        window.removeEventListener('resize', this.updateWindow, 200, false);
        document.removeEventListener('keydown', this.handleKeyPress, false);
        document.removeEventListener('mousedown', this.handleMouseDown, false);
        document.removeEventListener('keydown', this.highlightActiveRow);
        if (this.QuoteSymbols.length > 0) {
            WebSyncUtil.UnsubscribeSymbolsAsync(this.QuoteSymbols);
            GridStore.setStreamingSymbols(this.QuoteSymbols = []);
        }
    }

    UNSAFE_componentWillUpdate() {
        if (this.state.shouldUpdate) {
            this.setViewPortSizes(this.state, this.props);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.isColumnReorder || nextState.isResizing) {
            return false;
        }

        return true;
    }

    handlePasteEvent(e) {
        if (document.activeElement.tagName.toString().toLowerCase() != 'input') {
            window.SafariClipboard = e.clipboardData.getData('text/plain');
            this.handleShortcuts('PASTE', e, e.clipboardData.getData('text/plain'))
        }
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.handleMouseUp, false);
        window.addEventListener('resize', this.updateWindow, 200, false);
        document.addEventListener('mousedown', this.handleMouseDown, false);

        if (this.mainDiv) this.mainDiv.focus();
        this.frozenDiv = document.getElementById('frozenDiv');
        this.nonFrozenDiv = document.getElementById('nonFrozenDiv');
        this.verticalScroll = document.getElementById('verticalScroll');
        this.gridVerticalScroll = document.getElementById('gridVerticalScroll');
        this.horizontalScroll = document.getElementById('horizontalScroll');
        this.gridHorizontalScroll = document.getElementById('gridHorizontalScroll');
        this.listExplorerElm = document.getElementById('listExplorer');
        this.messureHeightElm = document.getElementById("messureHeight");
        document.addEventListener('keydown', this.highlightActiveRow);
        if (this.gridBlock) {
            this.vH = this.gridBlock.getBoundingClientRect().height;
            BrowserFilterStore.setBrowserFilterMaxHeight(this.vH);
        }
        else {
            this.vH = 0;
            BrowserFilterStore.setBrowserFilterMaxHeight(600);
        }

        if (this.selectedView == ListManagerViewType.QuoteBoard) this.quoteBoard = true;
        ManageLayoutStore.setIsQuoteBoard(this.quoteBoard);
        if (BrowserUtil.getBrowserName() == 'Firefox') {
            if (this.frozenDiv && this.frozenDiv.addEventListener) this.frozenDiv.addEventListener("DOMMouseScroll", this.handleWheelScroll, supportsPassive ? { passive: true } : false);
            if (this.nonFrozenDiv && this.nonFrozenDiv.addEventListener) this.nonFrozenDiv.addEventListener("DOMMouseScroll", this.handleWheelScroll, supportsPassive ? { passive: true } : false);
        }
        else {
            if (this.frozenDiv && this.frozenDiv.addEventListener) this.frozenDiv.addEventListener("mousewheel", this.handleWheelScroll, supportsPassive ? { passive: true } : false);
            if (this.nonFrozenDiv && this.nonFrozenDiv.addEventListener) this.nonFrozenDiv.addEventListener("mousewheel", this.handleWheelScroll, supportsPassive ? { passive: true } : false);
        }
        if (this.gridVerticalScroll) this.gridVerticalScroll.addEventListener("scroll", this.handleVerticalScroll, false);
        if (this.gridHorizontalScroll) this.gridHorizontalScroll.addEventListener("scroll", this.handleHorizontalScroll, false);

        if (BrowserUtil.getBrowserName() == 'Safari') {
            window.addEventListener('paste', this.handlePasteEvent, false);
        }
    }

    updateWindow () {
        setTimeout(() => {
            const isOpen = SummaryStatStore.state.summaryStatIsOpen;
            this.updateBrowserFilterMaxHeight(isOpen);
            if (this.mainDiv) {
                /* TODO - resize issue  Fix for PANWEB-1788*/
                // const rect = this.mainDiv.getBoundingClientRect();
                // let dimensions = {
                //     top: rect.top,
                //     right: rect.right,
                //     bottom: rect.bottom,
                //     left: rect.left,
                //     width: rect.width,
                //     height: rect.height
                // };
                //this.updateWidthHeightGrid(dimensions);
                ResizeActions.updateBrowserFilterWidths();
                this.setState({
                    shouldUpdate: true
                }, this.setViewPortSizes(this.state, this.props));
            }
        }, 0);
    }

    componentDidUpdate() {
        if(this.count === 1) {
            this.count = 2;
        }
        if(this.count === 3) {
            this.count = 0;
        }
        if (this.mainDiv.clientWidth !== this.state.frozenAreaWidth + this.state.nonFrozenAreaWidth) {
            debounce(this.setViewPortSizes(this.state, this.props), 300);
        }
        if (this.nonFrozenDiv) {
            this.horizontalScroll.style.left = parseInt(this.frozenDiv.clientWidth - 1) + 'px';
        }
        let obj = GridStore.getScrollPos();
        if(this.props.showChart){
            if (this.QuoteSymbols.length > 0) {
                WebSyncUtil.UnsubscribeSymbolsAsync(this.QuoteSymbols);
                GridStore.setStreamingSymbols(this.QuoteSymbols = []);
            }
        }
        else
            this.generateItemOnScroll(currentStartIndex, obj.scrollTop);
        if (this.frozenGridBody && this.frozenGridBody.state && this.frozenGridBody.state.selectedIndexes && this.frozenGridBody.state.selectedIndexes.length > 0 ) {
            GridStore.setSelectedIndexes(this.frozenGridBody.state.selectedIndexes);   
        }
    }

    getFlagStatus (column, isCryptoCurrencyList) {
       return isCryptoCurrencyList && column && column.displayName === "" && column.orderNum === 2 && column.className === "icn-flag-head"
    }
    
    getfrozenColumnLimit(){
        let selectedListId = Number(ListExplorerStore.getSelectedTabType() === ListManagerTabType.Oneil ? ListStore.getState().TabONeilSettings.SelectedListInfo.SelectedListId : ListStore.getState().TabOwnershipSettings.SelectedListInfo.SelectedListId);
        return (selectedListId === -1 || selectedListId === -2) ? 2 : 3

    }

    UNSAFE_componentWillReceiveProps(props, state) {
        const isCryptoCurrencyList = ListExplorerStore.getIsCryptoCurrencyList();
        let frozenColumnsLimit = this.getfrozenColumnLimit();
        
        if (props.shouldUpdateStreamingData) {
            this.updateVisibleRows(props.source).then((complete) => {
                if(complete) {
                    this.count = 3;
                    this.shouldUpdateStreamingData = true;
                    this.setState({
                        source: props.source
                    });
                }
            })
            
        }
        if (props.columns != undefined) {
            if (props.columns.length > 0) {      
                if (isEmpty(state) || !Object.compare(this.props, props) || !Object.compare(state.allColumns, props.columns) || !Object.compare(state.source, props.source)) {
                    let columns = [];
                    let frozenColumns = [];
                    for (let index = 0; index < props.columns.length; index++) {
                        let column = props.columns[index];
                        const isRemoveFlgCol = this.getFlagStatus(column, isCryptoCurrencyList);
                        if (!column.isCollapsed && !isRemoveFlgCol) {
                            if (column.isFrozen) {
                                frozenColumns.push(column);
                            }
                            else {
                                columns.push(column);
                            }
                        }
                    }
                    this.spliterColumns = frozenColumns.length > frozenColumnsLimit ? false : true;
                    this.setState({
                        source: props.source,
                        isResizing: false,
                        selectedListId: props.selectedListId,
                        frozenColumns: frozenColumns,
                        columns: columns,
                        allColumns: props.columns,
                        shouldUpdate: false,
                        isOn: props.screenValue,
                        summaryStatOpen: SummaryStatStore.state.summaryStatIsOpen
                    });
                    this.setViewPortSizes(this.state, props, frozenColumns, columns);
                }
            }
            else {
                if (isEmpty(state) || !Object.compare(this.props, props) || !Object.compare(state.allColumns, props.columns) || !Object.compare(state.source, props.source)) {
                    this.setState({
                        source: props.source,
                        shouldUpdate: false,
                        isResizing: false
                    });
                }
            }
            const textSize = ListStore.getTextFontSize();
            if (this.textSize && this.textSize != textSize) {
                this.textSize = textSize;
                this.setState({
                    shouldUpdate: true
                }, this.setViewPortSizes(state, props));
            }
        }
        if (this.loadingMore == false) {
            setTimeout(() => {
                if (this.state.selectedListId != props.selectedListId) this.setPageScroll(false);
                else this.setPageScroll();
            }, 0);
        }
        else {
            this.loadingMore = false;
            if (this.gridLoadMore) this.gridLoadMore.style.display = 'none';
        }
        if (props.source.length == 0) {
            this.visibleRows = [];
            if (this.frozenGridBody && this.nonFrozenGridBody && this.shouldUpdateStreamingData) {
                this.frozenGridBody.loadRowsOnNewData(this.visibleRows);
                this.nonFrozenGridBody.loadRowsOnNewData(this.visibleRows);
            }
        }
        if(this.state.source.length !== props.source.length){
            this.currentActiveRowIndex = -1;
            props.source.map((item, index)=>{
                if(item.isActive){
                    this.currentActiveRowIndex = index;
                }
            })
        }
        if (ListExplorerStore.isNotImportedList() && ListExplorerStore.getSelectedTabType() === ListManagerTabType.Oneil) {
            this.setState({
                summaryStatOpen: false
            })
            this.updateBrowserFilterMaxHeight(false);
            setTimeout(() => {
                SummaryStatStore.state.summaryStatIsOpen = false;
                SummaryStatStore.state.summaryStatHeight = 0;
                ONeilViewStore.updateSummaryStateSettings()
            }, 0);
        }
    }

    handleMouseUp() {
        this.resizeDone = false;
        if (this.state.isColumnReorder) {
            // this.shouldUpdateStreamingData = false;
            this.handleColumnReorder();
        }
        if (this.state.isResizing) {
            let self = this;
            self.updateColumnWidth().then(() => {
                self.resizeDone = true;
                self.props.addColumnSetWidth(self.state.selectedListId, self.colSeq, self.quoteBoard);
                self.setState({ isResizing: false, shouldUpdate: true });
            });
        }
    }

    async updateColumnWidth() {
        if (this.colDataResize && this.customWidth) {
            this.columnResizeSeparator.removeAttribute('style');
            if (this.colDataResize.isFrozen) {
                const resizeColindex = this.state.frozenColumns.indexOf(this.colDataResize);
                this.state = {
                    ...this.state, frozenColumns: {
                        ...this.state.frozenColumns,
                        [resizeColindex]: {
                            ...this.state.frozenColumns[resizeColindex],
                            customWidth: this.customWidth
                        }
                    }
                }
                /* this.state.frozenColumns[resizeColindex].customWidth = this.customWidth; */
            }
            else {
                const resizeColindex = this.state.columns.indexOf(this.colDataResize);
                this.state = {
                    ...this.state, columns: {
                        ...this.state.columns,
                        [resizeColindex]: {
                            ...this.state.columns[resizeColindex],
                            customWidth: this.customWidth
                        }
                    }
                }
                /* this.state.columns[resizeColindex].customWidth = this.customWidth; */
            }
            this.customWidth = undefined;
            this.colDataResize = undefined;
            return true;
        }
    }

    handleClick(open = true) {
        if (!open) {
            if (this.state.openScreenSummary)
                this.setState({ openScreenSummary: false });
        }
        else {
            if (this.state.openScreenSummary)
                this.setState({ openScreenSummary: false });
            else
                this.setState({ openScreenSummary: true });
        }
    }

    handleShortcuts(action, event, data) {
        if(this.props.showChart) return;
        let obj = null;
        if (BrowserUtil.getBrowserName() == 'Safari' && event.code == 'KeyV')
            return;
        if (this.frozenGridBody.state.selectedIndexes.length > 0)
            obj = { listId: this.props.actualListId !== undefined ? this.props.actualListId : this.state.selectedListId, selectedIndexes: this.frozenGridBody.state.selectedIndexes };
        let pasteObj = { listId: this.state.selectedListId, selectedIndexes: -1, data: data };
        switch (action) {
            case 'MOVE_UP':
            case 'MOVE_DOWN':
                const selectedIndex = this.frozenGridBody.onKeyUpDownSelection(event, undefined);
                this.nonFrozenGridBody.onKeyUpDownSelection(event, selectedIndex);
                break;
            case 'SELECT_ALL':
                this.props.onGridRowContextMenuItemClick(ContextMenuConstants.ROW_SELECT_ALL, '');
                event.preventDefault();
                event.stopPropagation();
                break;
            case 'COPY':
                if (obj)
                    this.props.onGridRowContextMenuItemClick(ContextMenuConstants.ROW_COPY, obj);
                break;
            case 'PASTE':
                if (pasteObj) {
                    this.props.onGridRowContextMenuItemClick(ContextMenuConstants.ROW_PASTE, pasteObj);
                    GridStore.isPasteEvent = true;
                }
                break;
            case 'DELETE_ROW':
                if(ONeilViewStore.disableDelete || OwnershipViewStore.disableDelete){ return; }
                if (obj){
                    this.props.onGridRowContextMenuItemClick(ContextMenuConstants.ROW_REMOVE, obj);
                    this.hideTooltip();
                }
                break;
            case 'ACTIVE_ROW':
                //this.highlightActiveRow(event);
                break;
            case 'SHIFT_DOWN':
                this.props.onGridRowContextMenuItemClick(ContextMenuConstants.SHIFT_DOWN_SELECTION, '');
                event.preventDefault();
                event.stopPropagation();
                break;
            case 'SHIFT_UP':
                this.props.onGridRowContextMenuItemClick(ContextMenuConstants.SHIFT_UP_SELECTION, '');
                event.preventDefault();
                event.stopPropagation();
                break;
            default:
                break;
        }
    }

    highlightActiveRow(event) {
        //PANWEB-6841 FIX
        if(event.srcElement.tagName !== 'INPUT' && event.srcElement.tagName !== 'TEXTAREA' && !event.srcElement.isContentEditable && !document.body.classList.contains("modal-open") && event.keyCode === KeyCodes.SPACE && this.state.source.length > 0 && (!this.props.showChart)){
            this.setScrollPos(event, 'moveDown', this.currentActiveRowIndex + 12);
            if(this.currentActiveRowIndex === -1){
                const selectedIndex = GridStore.getSelectedIndexes();
                if(selectedIndex.length > 0){
                    this.currentActiveRowIndex = selectedIndex[0]
                }
            }
            if(this.currentActiveRowIndex !== -1){
                this.state.source[this.currentActiveRowIndex].isActive = false;
            }
            this.state.source[++this.currentActiveRowIndex].isActive = true;
            this.props.onPlotSymbol(this.state.source[this.currentActiveRowIndex].symbol)
            this.setState({ source: this.state.source});
            // this.nonFrozenGridBody.highlightActiveRow(event)
        }
    }

    onAutoSizeAllColumnsWidthClick() {
        let colSeq = "";
        each(this.state.allColumns, (colData) => {
            colSeq += this.calculateColumnWidth(colData) + ';';
        });
        if (colSeq !== "") {
            setTimeout(() => {
                this.props.addColumnSetWidth(this.state.selectedListId, colSeq, this.quoteBoard);
            }, 0);
        }
    }

    onColumnResizerDoubleClick (colData) {
        const colSeq = this.calculateColumnWidth(colData);
        this.props.addColumnSetWidth(this.state.selectedListId, colSeq, this.quoteBoard);
    }

    onContextMenuClick(e, colData) {
        e.preventDefault();
        if (colData.isContextMenuEnable) {
            const { dataItemId, isFrozen, isVisualizationOn, displayName } = colData;
            const colSeq = this.calculateColumnWidth(colData);
            const previousColumn = this.getPreviousColumn(dataItemId);
            this.props.onGridColumnContextMenuClick(e, this.state.selectedListId, dataItemId, colSeq, this.quoteBoard, isFrozen, previousColumn.dataItemId, displayName, isVisualizationOn);
        }
    }

    getPreviousColumn(dataItemId) {
        let column = find(this.state.allColumns, (key) => dataItemId.toString() === key.dataItemId.toString());

        if (column.orderNum != 1) {
            let previousColumn = find(this.state.allColumns, (key) => ((column.orderNum - 1) == key.orderNum));
            return previousColumn;
        }
        return column;
    }

    calculateColumnWidth(colData) {
        if (colData.isFrozen) {
            return `${colData.dataItemId},${parseInt(this.AutoSizeForFixedColumn(colData))}`;
        }
        else {
            return `${colData.dataItemId},${parseInt(this.AutoSizeForNonFixedColumn(colData))}`;
        }
    }

    GetHardCodedColumnSize(dataItemId) {
        switch (dataItemId) {
            case -4000013:
            case -7:
                return 49;
            case -6:
                return 189;
            case -5:
                return 103;
            case -4:
                return 52;
            case -3:
                return 32;
            default:
                return -1;
        }
        // histogram check pending
    }


    AutoSizeForFixedColumn(colData) {
        let width = this.GetHardCodedColumnSize(colData.dataItemId);
        if (width == -1) {
            return this.AutoSizeForNonFixedColumn(colData);
        }
        let histogramWidth = 470;
        let minAutoSizeWidthFactor = 25;

        if (this.getFrozenAreaWidth() < histogramWidth) {
            if (colData.dataItemId == -5 || colData.dataItemId == -4000012) { // Symbol
                width += minAutoSizeWidthFactor * 0.4;
            }
            else if (colData.dataItemId == -6 || colData.dataItemId == -4000004) { // Name
                width += minAutoSizeWidthFactor * 0.6;
            }
        }
        return width;
    }

    AutoSizeForNonFixedColumn(colData) {

        let columnWidth = this.getDesiredWidthOfColumnUsingHeader(colData);
        const padding = 4;
        let maxWidth = columnWidth;

        let column = this.state.allColumns[colData.id];

        map(this.state.source, (rows) => {
            let cell = rows['recordDatas'][colData.id];
            if (cell != undefined) {
                if (cell.displayValue) {

                    let width = cellWidth(cell.displayValue, {
                        family: 'calibri',
                        size: this.textSize
                    });
                    if (cell.snapshotDt && (cell.dataItemId == -5 || cell.dataItemId == -4000012)) {
                        let subscriptWidth = cellWidth(cell.snapshotDt, {
                            family: 'calibri',
                            size: 10
                        });
                        width = width + subscriptWidth;
                    }
                    if (width > maxWidth) maxWidth = width;
                }
            }
        });
        let contentWidth = maxWidth + padding + 5 + 5; // in desktop => column.CellMargin.Left + column.CellMargin.Right;
        if (contentWidth < column.minWidth) contentWidth = column.minWidth;
        //  maxWidth = AutosizeForSummaryStats(column, this.textSize, maxWidth);
        return contentWidth;
    }


    getTextHeight() {
        if (this.messureHeightElm) {
            this.messureHeightElm.style.fontFamily = 'calibri';
            this.messureHeightElm.style.fontSize = this.textSize + 'px';
            const offset = 2;
            this.actualItemHeight = Math.ceil(this.messureHeightElm.getBoundingClientRect().height) + offset;
            return this.actualItemHeight;
        }
    }
    


    scrollGrid(step) {
        if(this.gridVerticalScroll){
            let scrollY = this.gridVerticalScroll.scrollTop;
            this.gridVerticalScroll.scrollTop = (scrollY + step);
            if (!this.dragScrollStop) {
                window.setTimeout(() => { this.scrollGrid(step) }, 20);
            }
        }
    }

    setDragGridScroll(scrollStop) {
        this.dragScrollStop = scrollStop;
    }


    getDesiredWidthOfColumnUsingHeader(colData) {
        if (colData == null) return 0.0;


        let displayName = colData.displayName;
        if (displayName.indexOf(" ") == -1) {
            let width = cellWidth(displayName, {
                family: 'calibri',
                size: this.textSize
            });
            return (width + 5 + 5); // in desktop => columnInfo.CellMargin.Left + columnInfo.CellMargin.Right;
        }

        let indexOfSpace = displayName.indexOf(" ", (displayName.length / 2));
        if (indexOfSpace < 0) indexOfSpace = displayName.indexOf(" ");

        let row1 = displayName.substring(0, indexOfSpace);
        let row2 = displayName.substring(indexOfSpace);

        let lengthOfRow1 = Math.ceil(cellWidth(row1, {
            family: 'calibri',
            size: this.textSize
        }));
        let lengthOfRow2 = Math.ceil(cellWidth(row2, {
            family: 'calibri',
            size: this.textSize
        }));

        return Math.max(lengthOfRow1, lengthOfRow2) + 5 + 5; // in desktop => columnInfo.CellMargin.Left + columnInfo.CellMargin.Right;
    }

    onCustomFilterContextMenuClick(e) {
        e.preventDefault();
        this.props.onGridCustomFilterContextMenuClick(e);
    }

    getColumnWidth(column) {
        let colWidth = column.minWidth;
        if (column.customWidth) colWidth = column.customWidth;
        else if (column.width) colWidth = column.width;

        if (colWidth > 500) colWidth = 500;
        return colWidth;
    }

    setPageScroll(resizeLeftScroll = true) {
        if(this.gridVerticalScroll){
            let obj = GridStore.getScrollPos();
            this.gridVerticalScroll.scrollTop = obj.scrollTop;
            if (resizeLeftScroll) this.gridHorizontalScroll.scrollLeft = obj.scrollLeft;
        }
    }

    getFrozenAreaWidth(params) {
        let cols = this.state.frozenColumns;
        if (params) cols = params;
        let frozenAreaWidth = 0;
        each(cols, (column) => {
            frozenAreaWidth += this.getColumnWidth(column);
        });
        return frozenAreaWidth;
    }

    getNonFrozenViewPortWidth(params) {
        let cols = this.state.columns;
        if (params) cols = params;
        let colWidths = 0.0;
        for (let index = 0; index < cols.length; index++) {
            let column = cols[index];
            let colWidth = this.getColumnWidth(column);
            colWidths += colWidth;
        }
        return colWidths;
    }

    setViewPortSizes(state, props, frozenColumns, columns) {
        let colWidths = 0.0;
        let itemsCount = props.itemsCount + 1;
        let actualItemHeight = this.getTextHeight();
        let itemHeight = actualItemHeight ? actualItemHeight : props.itemHeight;
        if (GridStore.isAddSymbolEnableList()) itemsCount += 1;
        const shouldUpdate = false;//state.columns.length <= 0 && props.source != undefined;
        const viewPortHeight = (itemHeight * itemsCount);
        let visibleCount = Math.floor(this.gridAreaHeight / itemHeight);
        let offsetWidth = this.gridDiv ? this.gridDiv.clientWidth : 0;
        
        frozenColumns = frozenColumns ? frozenColumns : state.frozenColumns;
        columns = columns ? columns : state.columns;

        let frozenAreaWidth = frozenColumns ? this.getFrozenAreaWidth(frozenColumns) : this.state.frozenAreaWidth;
        colWidths = columns ? this.getNonFrozenViewPortWidth(columns) : this.state.viewportWidth;
        let nonFrozenAreaWidth = this.mainDiv ? this.mainDiv.clientWidth - frozenAreaWidth : 0;
        if (this.listExplorerElm && this.listExplorerElm.clientWidth < 23) {
            nonFrozenAreaWidth -= 24;
        }
        const frozenAreaWidthCalculatedLength = frozenColumns ? frozenColumns.length : this.state.frozenAreaWidthCalculatedLength;
        this.setState({
            viewportWidth: colWidths,
            frozenAreaWidth: frozenAreaWidth,
            frozenAreaWidthCalculatedLength: frozenAreaWidthCalculatedLength,
            shouldUpdate: shouldUpdate,
            viewPortHeight: viewPortHeight,
            offsetWidth: offsetWidth,
            recordsPerBody: visibleCount,
            clientHeight: visibleCount * itemHeight,
            nonFrozenAreaWidth: nonFrozenAreaWidth
        });
    }

    setScrollPos(e, type, selectedIndex) {
        if(this.gridVerticalScroll){
            const itemHeight = this.actualItemHeight;
            if (type == 'moveDown') {
                let scrollTop = (itemHeight * selectedIndex) - this.gridAreaHeight;
                this.gridVerticalScroll.scrollTop = scrollTop;
            }
            else {
                let scrollTop = itemHeight * (selectedIndex);
                this.gridVerticalScroll.scrollTop = scrollTop;
            }
            this.handleVerticalScrollMouseUp(e);
        }
    }

    calculateWheelScroll(event) {
        let delta = 0;
        if (!event) event = window.event;
        if (event.wheelDelta) {
            delta = event.wheelDelta / 120;
        } else if (event.detail) {
            delta = -event.detail / 3;
        }
        if (event.preventDefault && !supportsPassive) event.preventDefault();
        if(!supportsPassive){
        event.returnValue = false;
        }
        if (delta) return delta;
    }

    handleWheelScroll(e) {
        if(this.gridVerticalScroll){
            let scrollTop = parseInt(this.gridVerticalScroll.scrollTop);
            currentScrollWheel = scrollTop;
            if (this.calculateWheelScroll(e) >= 1) {
                if (scrollTop > 0) {
                    currentScrollWheel = scrollTop - 100;
                }
            }
            else if (this.calculateWheelScroll(e) <= -1) {
                currentScrollWheel = scrollTop + 100;
                if (currentScrollWheel > (this.state.viewPortHeight - this.gridVerticalScroll.offsetHeight)) currentScrollWheel = this.state.viewPortHeight - this.gridVerticalScroll.offsetHeight;
            }
            if (currentScrollWheel < 0) currentScrollWheel = 0;
            this.handleVerticalScroll(e);
            this.handleVerticalScrollMouseUp(e);
        }
    }

    handleKeyPress(e) {
        if (e.keyCode === 37) {
            this.handleMoveLeftOrRight(e, 'moveLeft');
        } else if (e.keyCode === 39) {
            this.handleMoveLeftOrRight(e, 'moveRight');
        }
    }

    handleMoveLeftOrRight(e, leftOrRightMove) {
        let scrollLeft = -(parseInt(this.nonFrozenDiv.style.left ? this.nonFrozenDiv.style.left : 0) + (leftOrRightMove == 'moveLeft' ? 5 : -5));
        GridStore.setScrollPos(this.gridVerticalScroll ? parseInt(this.gridVerticalScroll.scrollTop) : null, parseInt(scrollLeft));
        let obj = GridStore.getScrollPos();
        this.gridHorizontalScroll.scrollLeft = obj.scrollLeft;
        if(this.gridHorizontalScroll.scrollLeft < obj.scrollLeft) return;
        this.nonFrozenDiv.style.left = '-' + scrollLeft + "px";
        if (this.summaryStateDiv) this.summaryStateDiv.handleScroll(scrollLeft);
        this.headerDiv.handleScroll(scrollLeft);
        e.stopPropagation();
        e.preventDefault();
    }

    handleMouseDown(e) {
        if (this.gridHorizontalScroll) {
            const gridHorizontalScrollRects = this.gridHorizontalScroll.getBoundingClientRect()
            if (e.x <= gridHorizontalScrollRects.right && e.x >= gridHorizontalScrollRects.left && e.y <= gridHorizontalScrollRects.bottom && e.y >= gridHorizontalScrollRects.top) {
                document.addEventListener('keydown', this.handleKeyPress, false);
            } else {
                document.removeEventListener('keydown', this.handleKeyPress, false);
            }
        } else {
            document.removeEventListener('keydown', this.handleKeyPress, false);
        }
    }

    handleHorizontalScroll(e) {
        let scrollLeft = e.target.scrollLeft;
        GridStore.setScrollPos(this.gridVerticalScroll ? parseInt(this.gridVerticalScroll.scrollTop) : null, parseInt(scrollLeft));

        this.nonFrozenDiv.style.left = "-" + scrollLeft + "px";
        if (this.summaryStateDiv) this.summaryStateDiv.handleScroll(scrollLeft);
        this.headerDiv.handleScroll(scrollLeft);
        e.stopPropagation();
        e.preventDefault();
    }

    handleVerticalScroll(e) {
        if(this.gridVerticalScroll){
            let scrollTop = e.target.scrollTop;
            if (e.target.id == '') {
                scrollTop = currentScrollWheel;
                if (this.state.viewPortHeight < scrollTop) scrollTop = this.state.viewPortHeight - this.gridVerticalScroll.offsetHeight;
                this.gridVerticalScroll.scrollTop = scrollTop;
                GridStore.setScrollPos(parseInt(scrollTop), parseInt(this.gridHorizontalScroll.scrollLeft));
            }
            if (e.target.scrollTop !== this.previousScrollTop) {
                GridStore.setScrollPos(parseInt(scrollTop), parseInt(this.gridHorizontalScroll.scrollLeft));
                this.generateItemOnScroll(currentStartIndex, scrollTop);
                this.checkMoreData(scrollTop);
            }
            this.previousScrollTop = scrollTop;
    
            e.stopPropagation();
            if(!supportsPassive){
            e.preventDefault();
        }
        }
    }

    handleVerticalScrollMouseUp() {
        if(this.gridVerticalScroll){
            let scrollTop = this.gridVerticalScroll.scrollTop;
            if (this.checkMoreData(scrollTop) && !GridStore.isPasteEvent) {
                // setTimeout(() => {
                this.props.fetchMoreDataOnScroll(currentStartIndex)
                // }, 0);
            }
        }

    }

    updateVisibleRows(source) {
        return new Promise((resolve) => {
            const obj = GridStore.getScrollPos();
            const firstIndex = Math.floor(obj.scrollTop / this.actualItemHeight);
            const lastIndex = Math.min(this.props.itemsCount - 1, firstIndex + this.state.recordsPerBody);
            const displayStart = firstIndex;
            const displayEnd = lastIndex + 1;

            this.getRowsForNewData(source, currentStartIndex, displayStart, displayEnd).then((rows) => {
                this.visibleRows = clone(rows);
                resolve(true);
            });
        });
    }

    async generateItemOnScroll(sequenceNo, scrollTop) {
        const itemHeight = this.actualItemHeight;
        const firstIndex = Math.floor(scrollTop / itemHeight);
        const lastIndex = Math.min(this.props.itemsCount - 1, firstIndex + this.state.recordsPerBody);
        const displayStart = firstIndex;
        const displayEnd = lastIndex + 1;
        if (this.gridVerticalScroll && scrollTop == this.gridVerticalScroll.scrollTop) {
            if (timeout) clearTimeout(timeout);
            let rows = await this.getRowsForNewDataNew(this.state.source, sequenceNo, displayStart, displayEnd);
            timeout = setTimeout(() => {
                if (this.frozenGridBody && this.nonFrozenGridBody && this.shouldUpdateStreamingData) {
                    if (this.state.source.length == 0) {
                        rows = [];
                    }
                    this.frozenGridBody.loadRowsOnNewData(rows);
                    this.nonFrozenGridBody.loadRowsOnNewData(rows);
                }
            }, 0);
            this.visibleRows = clone(rows);
            if (this.visibleRows.length > 0 && ONeilViewStore.getNasdaqConnection()) {
                this.SubscribeRealTimeData();
            }
        }
    }

    getRowsForNewDataNew = async (source, scrolledPageStart, displayStart, displayEnd) =>
        new Promise((resolve) => {
            let rows = [];
            let actuallRowIndexStart = displayStart;
            let visibleIndex = 0;
            const len = source.length;
            let newDisplayEnd = Math.min(len, displayEnd);
            if (this.state.summaryStatOpen) {
                const maxHiddenRows = displayEnd > 0 ? Math.floor(this.getMaxHeightOfSummaryState() / this.actualItemHeight) + 1 : 0;
                newDisplayEnd = Math.min(len, displayEnd + maxHiddenRows);
            }
            if (len > 0) {
                let index = displayStart;
                while (index < newDisplayEnd) {
                    if (source && index > -1) {
                        rows.push({
                            visibleIndex: visibleIndex,
                            actuallRowIndexStart: actuallRowIndexStart,
                            rowData: source[index]
                        });
                    }
                    else {
                        rows.push({
                            visibleIndex: visibleIndex,
                            actuallRowIndexStart: actuallRowIndexStart,
                            rowData: undefined
                        });

                    }

                    actuallRowIndexStart++;
                    visibleIndex++;
                    index++;
                }
            }
            resolve(rows);
        })
    getRowsForNewData = async (source, scrolledPageStart, displayStart, displayEnd) =>
        new Promise((resolve) => {
            setTimeout(() => {
                let rows = [];
                let actuallRowIndexStart = displayStart;
                let visibleIndex = 0;
                const len = source.length;
                let newDisplayEnd = Math.min(len, displayEnd);
                if(this.state.summaryStatOpen){
                    const maxHiddenRows = displayEnd > 0 ? Math.floor(this.getMaxHeightOfSummaryState() / this.actualItemHeight) + 1 : 0;
                    newDisplayEnd = Math.min(len, displayEnd + maxHiddenRows);
                }
                if (len > 0) {
                    let index = displayStart;
                    while (index < newDisplayEnd) {
                        if (source && index > -1) {
                            //let rowData = source[index];
                            /*map(rowData, function (recordDatas, k) {
                                if (k == 'recordDatas') rowData[k] = map(recordDatas, function (o) { return pick(o, ['displayValue', 'className', 'textWidth', 'isVisualizationOn', 'isNegative', 'dataType', 'dataItemId', 'alignment']); });
                            });*/

                            rows.push({
                                visibleIndex: visibleIndex,
                                actuallRowIndexStart: actuallRowIndexStart,
                                rowData: source[index]
                            });
                        }
                        else {
                            rows.push({
                                visibleIndex: visibleIndex,
                                actuallRowIndexStart: actuallRowIndexStart,
                                rowData: undefined
                            });

                        }

                        actuallRowIndexStart++;
                        visibleIndex++;
                        index++;
                    }
                }
                resolve(rows);
            }, 0);
        });


    checkMoreData(scrollTop) {      
        const itemHeight = this.actualItemHeight;
        const firstIndex = Math.floor(scrollTop / itemHeight);
        let sequenceNo = firstIndex;
        let loadMoreData = false;
        const extraPage = 10;
        if (this.props.source.length >= this.props.pageSize - 1) return false;
        else if (this.props.source.length < this.props.pageSize - 1) {
            if (sequenceNo + this.state.recordsPerBody > this.props.source.length - extraPage) {
                sequenceNo = this.props.source.length;
                loadMoreData = true;
            }
            if (loadMoreData) {
                this.loadingMore = true;
                if (this.gridLoadMore) this.gridLoadMore.removeAttribute('style');
            }
            currentStartIndex = sequenceNo;
            return this.loadingMore;
        }

        currentStartIndex = sequenceNo;
        return this.loadingMore;

        // for (let i = sequenceNo; i <= (sequenceNo + this.state.recordsPerBody + extraPage); i++) {
        //     if (this.props.source[sequenceNo + this.state.recordsPerBody] && !this.props.source[sequenceNo + this.state.recordsPerBody + extraPage]) {
        //         sequenceNo = this.props.source.length;
        //         loadMoreData = true;
        //         break;
        //     }
        //     // else if (this.props.source[sequenceNo] && this.props.source[sequenceNo]['recordDatas'].length != this.props.columns.length) {
        //     //     loadMoreData = true;
        //     //     sequenceNo = sequenceNo + 2 + this.state.recordsPerBody - this.props.pageSize;
        //     //     if (sequenceNo < 1) sequenceNo = 1;
        //     //     break;
        //     // }
        // }

    }

    handleColumnReorder() {
        let frozenWidth = this.state.frozenAreaWidth;
        let draggedColumn = this.state.draggedColumn;
        let dropColumn = this.state.dropColumn;
        let dragCol = false;
        if (draggedColumn && dropColumn && (byPassDropEvent || draggedColumn.dataItemId != dropColumn.dataItemId)) {
            let indexIncOrDec = 0;
            let draggedIndexTotalCol = this.state.allColumns.indexOf(draggedColumn);
            let dropIndexTotalCol = this.state.allColumns.indexOf(dropColumn);
            if (byPassDropEvent) {
                indexIncOrDec = 1;
                draggedColumn.isFrozen = false;
                let draggedIndex = this.state.frozenColumns.indexOf(draggedColumn);
                let dropIndex = 1;
                this.state.frozenColumns.splice(draggedIndex, 1);
                this.state.columns.splice(dropIndex, 0, draggedColumn);
                dragCol = true;
            }
            else if (this.state.leftOrRightDrop && this.state.leftOrRightDrop == "Right") {
                if (draggedColumn.isFrozen && !dropColumn.isFrozen) {
                    indexIncOrDec = 1;
                }
                else {
                    indexIncOrDec = draggedIndexTotalCol < dropIndexTotalCol ? 0 : 1;
                }
            }
            else if (this.state.leftOrRightDrop && this.state.leftOrRightDrop == "Left") {
                if (draggedColumn.isFrozen && !dropColumn.isFrozen) {
                    indexIncOrDec = 0;
                }
                else {
                    indexIncOrDec = draggedIndexTotalCol > dropIndexTotalCol ? 0 : -1;
                }
            }
            if (!byPassDropEvent) {
                if (draggedColumn.isFrozen && dropColumn.isFrozen) {
                    let draggedIndex = this.state.frozenColumns.indexOf(draggedColumn);
                    let dropIndex = this.state.frozenColumns.indexOf(dropColumn) + indexIncOrDec;
                    if (draggedIndex != dropIndex)
                        this.state.frozenColumns.move(draggedIndex, dropIndex);
                }
                else if (draggedColumn.isFrozen && !dropColumn.isFrozen) {
                    draggedColumn.isFrozen = false;
                    let draggedIndex = this.state.frozenColumns.indexOf(draggedColumn);
                    let dropIndex = this.state.columns.indexOf(dropColumn) + indexIncOrDec--;
                    this.state.frozenColumns.splice(draggedIndex, 1);
                    this.state.columns.splice(dropIndex, 0, draggedColumn);
                    // dragCol = true;
                }
                else if (!draggedColumn.isFrozen && dropColumn.isFrozen) {
                    draggedColumn.isFrozen = true;
                    let draggedIndex = this.state.columns.indexOf(draggedColumn);
                    let dropIndex = this.state.frozenColumns.indexOf(dropColumn) + indexIncOrDec;
                    this.state.columns.splice(draggedIndex, 1);
                    this.state.frozenColumns.splice(dropIndex, 0, draggedColumn);
                }
                else {
                    let draggedIndex = this.state.columns.indexOf(draggedColumn);
                    let dropIndex = this.state.columns.indexOf(dropColumn) + indexIncOrDec;
                    if (draggedIndex != dropIndex)
                        this.state.columns.move(draggedIndex, dropIndex);
                }
            }
            let draggedIndexTotal = this.state.allColumns.indexOf(draggedColumn);
            let dropIndexTotal = this.state.allColumns.indexOf(dropColumn) + indexIncOrDec;
            this.state.allColumns.move(draggedIndexTotal, dropIndexTotal);
            frozenWidth = this.getFrozenAreaWidth();
            let colWidths = this.getNonFrozenViewPortWidth();
            let offsetWidth = this.gridDiv.clientWidth;
            let nonFrozenAreaWidth = this.mainDiv ? this.mainDiv.clientWidth - frozenWidth : 0;

            let colPosition = (dragCol) ? dropIndexTotal - 2 : dropIndexTotal - 1;
            this.frozenHeaderDiv && this.frozenHeaderDiv.handleMouseUp();
            this.headerDiv && this.headerDiv.handleMouseUp();
            this.count = 1;
            
            const frozenAreaWidthCalculatedLength = this.state.frozenColumns && this.state.frozenColumns.length;

            this.setState({
                viewportWidth: colWidths,
                frozenAreaWidth: frozenWidth,
                frozenAreaWidthCalculatedLength: frozenAreaWidthCalculatedLength,
                isColumnReorder: false,
                draggedColumn: undefined,
                leftOrRightDrop: undefined,
                dropColumn: undefined,
                offsetWidth: offsetWidth,
                shouldUpdate: true,
                nonFrozenAreaWidth: nonFrozenAreaWidth
            });
            const listState = ListStore.getSelectedListItem();
            this.props.addColumnToList(listState.SelectedListId, draggedColumn.dataItemId, this.state.allColumns[colPosition].dataItemId, draggedColumn.isFrozen, this.quoteBoard, true);
        }
        else {
            this.setState({
                isColumnReorder: false,
                draggedColumn: undefined,
                leftOrRightDrop: undefined,
                dropColumn: undefined
                // ,
                // shouldUpdate: true
            });
        }
    }

    generateResizeAdorner() {
        let style = { height: this.state.clientHeight, left: this.state.resizeLeft };
        return (<div key="temp" className="grid-resize-adorner" style={style} />)
    }

    showColumnResizeSeparator(pos) {
        if (!this.columnResizeSeparator) {
            this.columnResizeSeparator = this.props.columnResizeSeparator;
        }
        this.rectListExplorerInfo = this.listExplorerElm.getBoundingClientRect();
        let posLeft = pos - this.rectListExplorerInfo.width - this.rectListExplorerInfo.left;
        if (this.columnResizeSeparator.style.display != "block")
            this.columnResizeSeparator.style.display = "block";
        this.columnResizeSeparator.style.left = posLeft + "px";
    }

    setResizeColumnDetails(colData, width) {
        this.colDataResize = colData;
        this.customWidth = width;
    }

    handleColumnResize(width, colData, pos) {
        this.setResizeColumnDetails(colData, width);
        this.colSeq = colData.dataItemId + "," + parseInt(width);
        this.showColumnResizeSeparator(pos);
        this.setResizeState(pos);
    }

    handleColumnResizeFrozen(width, colData, pos) {
        this.setResizeColumnDetails(colData, width);
        this.colSeq = colData.dataItemId + "," + parseInt(width);
        this.showColumnResizeSeparator(pos);
        this.setResizeState(pos);

    }

    setResizeState(pos) {
        let frozenWidth = this.getFrozenAreaWidth();
        let colWidths = this.getNonFrozenViewPortWidth();
        let offsetWidth = this.gridDiv.clientWidth;
        let nonFrozenAreaWidth = this.mainDiv ? this.mainDiv.clientWidth - frozenWidth : 0;
        const frozenAreaWidthCalculatedLength = this.state.frozenColumns && this.state.frozenColumns.length;
        this.setState({
            viewportWidth: colWidths,
            frozenAreaWidth: frozenWidth,
            frozenAreaWidthCalculatedLength: frozenAreaWidthCalculatedLength,
            shouldUpdate: true,
            isResizing: true,
            offsetWidth: offsetWidth,
            nonFrozenAreaWidth: nonFrozenAreaWidth,
            resizeLeft: pos
        });
    }

    getMouseOverColumnIndex(pos) {
        let currentColPos = 0.0;
        let colindex = 0;
        while (currentColPos < pos) {
            if (colindex < this.state.allColumns.length) {
                let colData = this.state.allColumns[colindex];
                if (!colData.isCollapsed) {
                    let colWidth = this.getColumnWidth(colData);
                    currentColPos += colWidth;
                }
                colindex++;
            }
            else {
                currentColPos = pos + 5;
            }
        }
        if (colindex == 0) {
            colindex = 1;
        }
        return (colindex - 1);
    }

    onColumnDrag(e, column, left, draggedpos, scrollX) {

        let dragColumnTitle = column.displayName;
        let node = document.getElementById('ColHeader' + column.dataItemId);
        if (node) {
            let el = node.getElementsByClassName('display-Name-head');
            if (el) dragColumnTitle = el[0].innerHTML;
        }

        this.adornerDiv.style.zIndex = 1;
        this.adorner.createColumnGhostImage(column, left - scrollX, dragColumnTitle);
        let draggedOverColumn = this.getDraggedOverObject(draggedpos);
        if (draggedOverColumn != undefined && stopColumnAutoScroll) {
            if (draggedOverColumn.isFrozen) {
                this.frozenHeaderDiv.setDraggedState(draggedOverColumn);
                this.headerDiv.resetDraggedState();
            }
            else {
                this.headerDiv.setDraggedState(draggedOverColumn);
                this.frozenHeaderDiv.resetDraggedState();
            }
        }

        let leftRight = "Right";
        if (draggedOverColumn !== undefined) {
            let overDragedElement = document.querySelector('[data-itemid="' + draggedOverColumn.dataItemId + '"]');
            let pos = overDragedElement ? this.getPos(overDragedElement) : null;
            let currentPosMouseX = e.clientX;
            if (currentPosMouseX && (pos && pos.left + (overDragedElement.offsetWidth / 2)) <= currentPosMouseX) {
                leftRight = "Right";

            }
            else {
                leftRight = "Left";
            }
            if (this.state.selectedListId == -1 || this.state.selectedListId == -2) { // if O'Neil or Ownership db is selected
                if (this.state.allColumns.indexOf(draggedOverColumn) == 2) leftRight = "Right";
            }
            else {
                if (this.state.allColumns.indexOf(draggedOverColumn) == 3) leftRight = "Right";
            }
        }

        if(isLastIndex && draggedOverColumn.isFrozen && column.isFrozen) {
            byPassDropEvent = true;
        }
        else {
            byPassDropEvent = false;
        }
        if(stopColumnAutoScroll){
        this.setState({
            draggedColumn: column,
            dropColumn: draggedOverColumn,
            isColumnReorder: true,
            leftOrRightDrop: leftRight,
            shouldUpdate: false
        });
        }
        this.handleDragMove(e);
    }

    getPos(ele) {
        let pos = this.offset(ele);
        let lefts = pos.left;
        return { left: lefts, top: pos.top };
    }

    offset(el) {
        let rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }

    handleDragMove(e) {
        mousePos.cX = e.clientX;
        mousePos.cY = e.clientY;
        let windowDiv = document.querySelector("#main-grid-panel");
        let bRectOfWindow = windowDiv.getBoundingClientRect();
        let scrollSpeed = 2;
        if (BrowserUtil.getBrowserName() == "IE" || BrowserUtil.getBrowserName() == "Netscape") {
            scrollSpeed = 20;
        }
        stopColumnAutoScroll = true;
        if (mousePos.cY >= parseInt(bRectOfWindow.top) && mousePos.cX < parseInt(bRectOfWindow.left)) {
            stopColumnAutoScroll = false;
            this.scrollHorizentalGrid(-scrollSpeed);
        }
        if (mousePos.cY < parseInt(bRectOfWindow.bottom) && mousePos.cX > parseInt(bRectOfWindow.right - 2)) {
            stopColumnAutoScroll = false;
            this.scrollHorizentalGrid(scrollSpeed);
        }
    }

    scrollHorizentalGrid(step) {
        if (timeout) clearTimeout(timeout);
        if(stopColumnAutoScroll){
            return;
        }
        let scrollX = this.gridHorizontalScroll.scrollLeft;
        this.gridHorizontalScroll.scrollLeft = parseInt(scrollX + step);
        GridStore.setScrollPos(this.gridVerticalScroll ? parseInt(this.gridVerticalScroll.scrollTop) : null, parseInt(this.gridHorizontalScroll.scrollLeft));
        this.nonFrozenDiv.style.left = "-" + this.gridHorizontalScroll.scrollLeft + "px";
        if (this.summaryStateDiv) this.summaryStateDiv.handleScroll(this.gridHorizontalScroll.scrollLeft);
        this.headerDiv.handleScroll(this.gridHorizontalScroll.scrollLeft);
        if (!stopColumnAutoScroll) {
            timeout = setTimeout(() => {
                this.scrollHorizentalGrid(step)
            }, 20);
        }
    }

    getDraggedOverObject(pos) {
        let currentColPos = 0.0;
        let colindex = 0;
        if (pos - this.gridHorizontalScroll.scrollLeft > this.state.dimensions.width) return false;
        while (currentColPos < pos) {
            if (colindex < this.state.allColumns.length) {
                let colData = this.state.allColumns[colindex];
                if (!colData.isCollapsed) {
                    let colWidth = this.getColumnWidth(colData);
                    currentColPos += colWidth;
                }
                colindex++;
            }
            else {
                currentColPos = pos + 5;
            }
        }
        if (colindex == 0) {
            colindex = 1;
        }
        else if(currentColPos === pos + 5 && colindex === this.state.allColumns.length && this.state.columns.length === 0) {
            isLastIndex = true;
        }
        else {
            isLastIndex = false;
        }
        // Prevent drop on first 3 or 4 default frozen columns
        if (this.state.selectedListId == -1 || this.state.selectedListId == -2) { // if O'Neil or Ownership db is selected
            if (colindex > 2) return this.state.allColumns[colindex - 1];
        }
        else if (colindex > 3) return this.state.allColumns[colindex - 1];
        else return false;
    }

    onAdornerMouseUp() {
        stopColumnAutoScroll = true;
        this.adornerDiv.style.zIndex = 0;
    }

    onPlotSymbol(sym) {
        if (this.props.onPlotSymbol) {
            this.props.onPlotSymbol(sym);
        }
    }

    handleColumnSort(e, colData) {
        if (!this.resizeDone)
            this.props.handleColumnSort(e, colData);
    }

    //ColumnCustom Filter
    outsideColumnFilterClick() {
        this.setState({ customFilter: false, isUpdateNotRequired: false });
        this.CustomColumnFilters = null;
    }

    columnFilterUpdate() {
        this.setState({ customFilter: true, isUpdateNotRequired: true });
    }

    applyColumnFilter(addlRequestData) {
        this.props.applyFilter(addlRequestData);
    }

    handleColumnCustomFilter(e, colData) {
        if (!this.resizeDone) {
            let selectedTab = ListStore.getState().SelectedTabKey;
            let tabType = ListStore.getTabType(selectedTab);
            let colFilterType;
            switch (tabType) {
                case ListManagerTabType.Oneil:
                    if (!ONeilViewStore.getIsSharedColumnSet()) {
                        colFilterType = colData.colFilterType;

                        if (colData.dataItemId == -4 || colData.dataItemId == -3) {
                            return;
                        }
                        if (colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) {
                            if (ONeilViewStore.getState().pickListData.dataItemId !== colData.dataItemId || this.props.isListChanged)
                                ListActions.getListColumnPickList(colData.dataItemId);
                        }
                    }
                    break;
                case ListManagerTabType.Ownership:
                    colFilterType = colData.colFilterType;

                    if (colData.dataItemId == -4 || colData.dataItemId == -3) {
                        return;
                    }
                    if (colFilterType == ListColumnFilterType.PICKLIST_ListColumnFilterType) {
                        if (OwnershipViewStore.getState().pickListData.dataItemId !== colData.dataItemId || this.props.isListChanged)
                            getListColumnPickList(colData.dataItemId);
                    }

                    break;
                default: break;
            }
            this.CustomColumnFilters =
                <CustomColumnFilter
                    colFilterType={colFilterType}
                    key={colData.dataItemId}
                    colData={colData}
                    outsideColumnFilterClick={this.outsideColumnFilterClick}
                    targets={e.target}
                    applyFilter={this.applyColumnFilter}
                    columnFilterUpdate={this.columnFilterUpdate}
                />

            this.setState({ customFilter: true, isUpdateNotRequired: true });
        }
    }

    getScreenSummaryDetails() {
        return ColumnCustomFilterControl.getScreenSummary();
    }

    onClearAllFilter() {
        let addlCatalogRequests = ColumnCustomFilterControl.onClearAllFilter();
        this.props.onClearAllFilter(addlCatalogRequests);
    }

    highlightBottomBorder(rowRef, highlight = false) {
        let frozenItem=ReactDOM.findDOMNode(this.frozenGridBody.refs[rowRef]);
        let nonFrozenItem=ReactDOM.findDOMNode(this.frozenGridBody.refs[rowRef]);
        if(frozenItem){
            frozenItem.style.boxShadow = (highlight ? "inset 0 -1px 0 grey" : "");
        }
        if(nonFrozenItem){
            nonFrozenItem.style.boxShadow = (highlight ? "inset 0 -1px 0 grey" : "");
        }
        // ReactDOM.findDOMNode(this.frozenGridBody.refs[rowRef]).style.boxShadow = (highlight ? "inset 0 -1px 0 grey" : "");
        // ReactDOM.findDOMNode(this.nonFrozenGridBody.refs[rowRef]).style.boxShadow = (highlight ? "inset 0 -1px 0 grey" : "");
    }

    onSplitterClick() {
        this.spliterColumns = !this.spliterColumns;
        this.props.handleSplitterClick(this.spliterColumns);
    }

    onHandleClickOnOff() {
        let isOnorOff = this.state.isOn;
        isOnorOff = !isOnorOff;
        this.setState({ isOn: isOnorOff });
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        switch (tabType) {
            case ListManagerTabType.Oneil:
                ListActions.toggleScreenOnOff(isOnorOff);
                break;
            case ListManagerTabType.Ownership:
                toggleScreenOnOff(isOnorOff);
                break;
                default: break;
        }

    }

    onContextMenuClickEmptyGridArea(e) {
        GridStore.isPasteEvent = false;
        if (GridStore.isAddSymbolEnableList()) {
            e.stopPropagation();
            e.preventDefault();
            let selectedItems = -1;
            let instrumentType = -1;
            this.props.onGridRowContextMenuClick(e, selectedItems, instrumentType);
        }
        else {
            e.preventDefault();
        }
    }

    addSymbol(symValue) { 
        let selectedListId = this.state.selectedListId;
        const selectedTab = ListStore.getState().SelectedTabKey;
        const tabType = ListStore.getTabType(selectedTab);  
        if(tabType === ListManagerTabType.Ownership && OwnershipViewStore.isExistInUserCategory)selectedListId=this.props.selectedListFav;
        BrowserUtil.disableEvents();
        GridActions.addSymbolInListManager(symValue, selectedListId, this.props.cacheId, this.state.source.length + 1, this.props.listName);
    }

    changeGridTypeBehavior() {
        if (this.state.gridTypeTable) document.getElementById('gridbody_type').innerHTML = "Canvas";
        else document.getElementById('gridbody_type').innerHTML = "Table";
        this.setState({ gridTypeTable: !this.state.gridTypeTable });
    }

    showTooltip (e, displayValue, showAlways = false) {
        const left = e.clientX;
        const top = e.clientY + 20;
        //const txtCount = displayValue ? displayValue.length : 0;
        const width = cellWidth(displayValue, {
            family: 'calibri',
            size: this.props.textSize
        });

        let leftPos = left

        if (!showAlways) {
            let wFactor = width / 3;
            let rightPosLeft = screen.width - left;
            let bottomPosLeft = screen.height - top;
            if (rightPosLeft > width) wFactor = 0;
            else if (bottomPosLeft > 220 && rightPosLeft <= width / 3) wFactor = width / 6;
            else if (bottomPosLeft < 220 && rightPosLeft <= width / 3) wFactor = width / 3;
            let leftPos = left - wFactor;
            if (leftPos < 0) leftPos = 100;
        }
        let style = { top, left:leftPos }
        this.setState({ ...this.state, tooltip: { isShowTooltip: true, style, text: displayValue }})
    }

    hideTooltip() {
        if (!BrowserUtil.isDisableEventsOn()) {
            this.setState({ ...this.state, tooltip:{ isShowTooltip: false, style: {}, text: ''}})
        }
    }

    getFrozenViewContent(frozenAreaWidth) {
        this.rowHeight = this.getTextHeight();
        let frozenView;
        switch (this.selectedView) {
            case ListManagerViewType.List:
                frozenView = <GridBody ref={(gridBody) => { this.frozenGridBody = gridBody }}
                    isUpdateNotRequired={this.state.isUpdateNotRequired}
                    shouldUpdateStreamingData={this.shouldUpdateStreamingData || this.count === 1}
                    visibleRow={this.count === 3 ? this.visibleRows : []}
                    onPlotSymbol={(sym) => this.onPlotSymbol(sym)}
                    itemHeight={this.rowHeight}
                    columns={this.state.frozenColumns}
                    source={this.state.source}
                    pageSize={this.props.pageSize}
                    displayHeight={this.state.clientHeight}
                    viewPortHeight={this.state.viewPortHeight}
                    viewportWidth={frozenAreaWidth}
                    selectedSymbol={this.props.selectedSymbol}
                    updateGlobalFlag={this.props.updateGlobalFlag}
                    onGridRowContextMenuClick={this.props.onGridRowContextMenuClick}
                    setScrollPos={this.setScrollPos}
                    selectedListId={this.state.selectedListId}
                    onGridRowContextMenuItemClick={this.props.onGridRowContextMenuItemClick}
                    highlightBottomBorder={this.highlightBottomBorder}
                    addSymbol={this.addSymbol}
                    targetNodeSelectorForKeyBoard="#frozenDiv"
                    gridTypeTable={this.state.gridTypeTable}
                    scrollGrid={this.scrollGrid}
                    setDragGridScroll={this.setDragGridScroll}
                    handleVerticalScrollMouseUp={this.handleVerticalScrollMouseUp}
                    textSize={this.textSize}
                    rowHeight={this.rowHeight}
                    allColumns={this.state.allColumns}
                    getColumnWidth={this.getColumnWidth}
                    frozenAreaWidth={frozenAreaWidth}
                    getMouseOverColumnIndex={this.getMouseOverColumnIndex}
                    loading={this.props.loading}
                    isEnableRowAdd={this.props.isEnableRowAdd}
                    tooltip = {{ showTooltip:this.showTooltip, hideTooltip: this.hideTooltip }}
                />
                break;
            default:

        }
        return frozenView;
    }

    getNonFrozenViewContent(frozenAreaWidth) {
        if (!this.rowHeight)
            this.rowHeight = this.getTextHeight();
        let nonFrozenView;
        switch (this.selectedView) {
            case ListManagerViewType.List:
                nonFrozenView = <GridBody ref={(gridBody) => { this.nonFrozenGridBody = gridBody }}
                    isUpdateNotRequired={this.state.isUpdateNotRequired}
                    shouldUpdateStreamingData={this.shouldUpdateStreamingData || this.count === 1}
                    visibleRow={this.count === 3 ? this.visibleRows : []}
                    onPlotSymbol={(sym) => this.onPlotSymbol(sym)}
                    itemHeight={this.rowHeight}
                    columns={this.state.columns}
                    source={this.state.source}
                    pageSize={this.props.pageSize}
                    displayHeight={this.state.clientHeight}
                    viewPortHeight={this.state.viewPortHeight}
                    viewportWidth={this.state.viewportWidth}
                    selectedSymbol={this.props.selectedSymbol}
                    updateGlobalFlag={this.props.updateGlobalFlag}
                    onGridRowContextMenuClick={this.props.onGridRowContextMenuClick}
                    setScrollPos={this.setScrollPos}
                    selectedListId={this.state.selectedListId}
                    onGridRowContextMenuItemClick={this.props.onGridRowContextMenuItemClick}
                    highlightBottomBorder={this.highlightBottomBorder}
                    addSymbol={this.addSymbol}
                    targetNodeSelectorForKeyBoard="#nonFrozenDiv"
                    gridTypeTable={this.state.gridTypeTable}
                    scrollGrid={this.scrollGrid}
                    setDragGridScroll={this.setDragGridScroll}
                    handleVerticalScrollMouseUp={this.handleVerticalScrollMouseUp}
                    textSize={this.textSize}
                    rowHeight={this.rowHeight}
                    allColumns={this.state.allColumns}
                    getColumnWidth={this.getColumnWidth}
                    frozenAreaWidth={frozenAreaWidth}
                    getMouseOverColumnIndex={this.getMouseOverColumnIndex}
                    loading={this.props.loading}
                    tooltip = {{ showTooltip:this.showTooltip, hideTooltip: this.hideTooltip }}
                />
                break;
            default:
        }
        return nonFrozenView;
    }

    //For export data
    exportData() {
        let selectedTab = ListStore.getState().SelectedTabKey;
        let tabType = ListStore.getTabType(selectedTab);
        switch (tabType) {
            case ListManagerTabType.Oneil:
                ONeilViewStore.exportDataCall();
                break;
            case ListManagerTabType.Ownership:
                OwnershipViewStore.exportDataCall();
                break;
            default: break;
        }

    }
    updateWidthHeightGrid(contentRect) {
        if (!isEqual(this.state.dimensions, contentRect) && this.state.dimensions.height != contentRect.height) {
            if (this.state.nonFrozenAreaWidth && this.state.nonFrozenAreaWidth > 0) {
                const {height , top} = contentRect;
                this.gridAreaHeight = parseInt(height - top);
                let itemsCount = this.props.itemsCount + 1;
                let itemHeight = this.actualItemHeight;
                if (GridStore.isAddSymbolEnableList()) itemsCount += 1;
                const viewPortHeight = (itemHeight * itemsCount);
                const visibleCount = Math.floor((contentRect.height - contentRect.top) / itemHeight);
                let offsetWidth = this.gridDiv ? this.gridDiv.clientWidth : contentRect.width;
                let nonFrozenAreaWidth = contentRect.width - this.state.frozenAreaWidth;
                this.setState({
                    viewPortHeight: viewPortHeight,
                    offsetWidth: offsetWidth,
                    recordsPerBody: visibleCount,
                    clientHeight: visibleCount * itemHeight,
                    nonFrozenAreaWidth: nonFrozenAreaWidth,
                    dimensions: contentRect,
                    shouldUpdate: false
                });
            }
            else {
                this.setState({ dimensions: contentRect, shouldUpdate: false });
            }
        }
    };

    getMaxHeightOfSummaryState () {
      return this.rowHeight * 12 + 24;
    }

    getSummaryHeight () {
        const height = SummaryStatStore.getSummaryHeight();
        const maxHeight = this.getMaxHeightOfSummaryState();
        return height && (height < maxHeight && height >= 0) ? height : maxHeight;
    }

    updateBrowserFilterMaxHeight(isOpen){
        if(isOpen){
            let maxHeight = 0
            if (this.gridBlock) {
                maxHeight = this.gridBlock.getBoundingClientRect().height;
            }
            else {
                maxHeight = 600
            }

            const summaryStateHeight = this.getSummaryHeight();
            maxHeight = maxHeight - summaryStateHeight;
            BrowserFilterStore.setBrowserFilterMaxHeight(maxHeight);
        }
        else{
            if (this.gridBlock) {
                this.vH = this.gridBlock.getBoundingClientRect().height;
                BrowserFilterStore.setBrowserFilterMaxHeight(this.vH);
            }
            else {
                this.vH = 0;
                BrowserFilterStore.setBrowserFilterMaxHeight(600);
            }
        }
    }

    summaryStatToggle() {
        const isOpen = !this.state.summaryStatOpen;
        const minHeight = 23;
        this.summaryStatHeight = isOpen && this.rowHeight > 0 ? this.getSummaryHeight() <= minHeight ? this.getMaxHeightOfSummaryState() : this.getSummaryHeight() : 0;
        this.setState({
            summaryStatOpen: isOpen,
            shouldUpdate: false
        });
        this.updateBrowserFilterMaxHeight(isOpen);
        setTimeout(() => {
            SummaryStatStore.state.summaryStatIsOpen = isOpen;
            if (isOpen && this.rowHeight > 0 && SummaryStatStore.state.summaryStatHeight != this.summaryStatHeight && this.summaryStatHeight === this.getMaxHeightOfSummaryState()) {
                SummaryStatStore.state.summaryStatHeight = this.summaryStatHeight;
            }
            ONeilViewStore.updateSummaryStateSettings()
        }, 0);

        // setTimeout(() => {
        //     this.setViewPortSizes(this.state, this.props)
        // }, 0);
    }

    adjustHeight (r, update = false) {
        if (this.gridAreaHeight && this.props) {
            if (!update) {
                this.summaryStatHeight = this.state.summaryStatOpen && this.rowHeight > 0 ? this.getSummaryHeight() : 0;
            }
            else {
                this.summaryStatHeight = r.height;
            }
            const visibleCount = Math.floor(this.gridAreaHeight / this.actualItemHeight);
            setTimeout(() => {
                this.setState({
                    recordsPerBody: visibleCount,
                    clientHeight: visibleCount * this.actualItemHeight,
                    shouldUpdate: false
                })
            }, 0);

        }


        // this.setState({
        //     dimensions: {
        //         height: this.state.dimensions.height - r.height
        //     }
        // })
    }

    isPortfolioList () {
        const basicInfo = ONeilViewStore.getBasicEsInfo();
        const portfolioList = basicInfo ? basicInfo.isPortfolioList : null;
        //Note: panweb-943
        if (portfolioList) {
            this.summaryStatHeight = 0;
        }
        return portfolioList;
    }

    /*Live Streaming Data */
    SubscribeRealTimeData() {
        let retainSymbols = [];
        let symbolsToRemove = [];
        const NavListManagerSettings = ListStore.getNavListManagerSettings();
        const tabType = ListStore.getTabType(NavListManagerSettings.SelectedTabKey);
        if (tabType != ListManagerTabType.Oneil || this.state.source == null || this.state.source.length == 0)
            return;
        const streamableObjects = GridStore.getStreamingSymbols();
        this.QuoteSymbols = [...this.visibleRows].filter((item) => !StringUtil.isEmpty(item.rowData.symbol) && !contains(item.rowData.symbol, '.')).map((item) => item.rowData.symbol);
        let cloneSubcribeItems = clone(this.QuoteSymbols);
        if (isEqual(streamableObjects, cloneSubcribeItems))
            return;
        if (streamableObjects && streamableObjects.length > 0) {
            retainSymbols = intersection(streamableObjects, cloneSubcribeItems);
        }
        if (retainSymbols.length > 0) {
            symbolsToRemove = [...streamableObjects].filter((item) => !retainSymbols.includes(item));
            cloneSubcribeItems = [...cloneSubcribeItems].filter((item) => !retainSymbols.includes(item));
        }
        this.AddQuoteSubscriptions(cloneSubcribeItems, symbolsToRemove);
        GridStore.setStreamingSymbols(this.QuoteSymbols);
    }

    AddQuoteSubscriptions(symbols, symbolToRemove) {
        if (WebSyncUtil.IsConnected && ONeilViewStore.getNasdaqConnection() && symbols) {
            if (symbolToRemove && symbolToRemove.length > 0) {
                WebSyncUtil.UnsubscribeSymbolsAsync(symbolToRemove);
            }
            WebSyncUtil.SubscribeSymbolsAsync(symbols);
        }
        WebSyncUtil.removeDataReceivedListener(this.updateLastPriceData);
        WebSyncUtil.addDataReceivedListener(this.updateLastPriceData);
    }

    updateLastPriceData(quote) {
        if (!this.visibleSymbols.contains(quote.Msid)) {
            this.visibleSymbols.push(quote.Msid)
        }
        const colDist = indexBy(this.state.allColumns, 'dataItemId');
        const colKeys = Object.keys(colDist);
        this.refreshListGridItem(quote, colKeys, colDist);
    }

    refreshListGridItem(quote, keys, colDist) {
        /*eslint-disable */
        if (this.visibleRows.length <= 0 || !this.shouldUpdateStreamingData)
            return;
        const cloneSource = JSON.parse(JSON.stringify(this.visibleRows));
        let cellStyle = "";
        const rowind = cloneSource.findIndex((itm) => itm.rowData.msId === quote.Msid);
        let row = cloneSource[rowind];
        let cloneRow = cloneSource;
        const cellSymbolIndex = row ? [...row.rowData.recordDatas].findIndex((itm) => itm.dataItemId === -5) : '';
        map(cloneSource, (rows,index) => {
            if(cloneRow[index].rowData.msId === quote.Msid)
                cloneRow[index].rowData['sec'] =  new Date().getSeconds();
            if(Math.abs(new Date().getSeconds() - rows.rowData['sec']) >= 2){
                if(cellSymbolIndex)
                    cloneRow[index].rowData.recordDatas[cellSymbolIndex].style = cellStyle;
                cloneRow[index].rowData['sec'] = 0;
            }
        });
        for (let record of quote.ReservedItemIds) {
            if (record && !(isNull(record) || isUndefined(record)) && row) {
                const colId = Number.parseInt(record.Key);
                // if (!keys.contains(colId.toString()))
                //     continue;
                let col = Number.parseInt(colId) == DataItemIdFlag && !colId ? false : (colId == undefined || colId == null) ? "" : record.Value;
                if (col == null)
                    continue;
                const cellIndex = [...row.rowData.recordDatas].findIndex((itm) => itm.dataItemId === colId);
                const cellItem = row.rowData.recordDatas[cellIndex];
                const columnInfo = colDist[colId];
                if ((cellItem && cellItem.dataItemId === defaultDistColumnId) || (colId === defaultDistColumnId && cellItem == undefined)) {
                    const lastPrice = cellItem !== undefined ? +cellItem.displayValue.replace(',','') : row.rowData.lastPrice;
                    const quotePrice = +Number(quote.Last).toFixed(2);
                    let cellClass = clone(row.rowData.recordDatas[cellSymbolIndex].className);
                    cellClass = this.updateCellClass(cellClass);
                    
                    if (lastPrice > quotePrice) {
                        //row.rowData.recordDatas[cellSymbolIndex].className = `${cellClass + negativeGround}`;
                        cellStyle = {background: ThemeHelper.getThemedBrush("negativeDataText")};
                    }
                    else if (lastPrice < quotePrice) {
                        //row.rowData.recordDatas[cellSymbolIndex].className = `${cellClass + positiveGround}`;
                        cellStyle = {background: ThemeHelper.getThemedBrush("positiveDataText")};
                    }
                    else {
                        cellStyle = row.rowData.recordDatas[cellSymbolIndex].style;
                    }
                    row.rowData.recordDatas[cellSymbolIndex].style = cellStyle
                    row.rowData.lastPrice = +Number(quote.Last).toFixed(2);
                }
                if (has(colDist, colId) && !contains(ONeilViewStore.getRemoveColumns(), colId.toString()) && cellItem) {
                    let strVal = "";
                    if (col !== "") {
                        strVal = ONeilViewStore.getCellStrVal(columnInfo, col, cellItem);
                    }
                    col = Number.POSITIVE_INFINITY == col || Number.NEGATIVE_INFINITY == col ? FormatterUtil.convertToNan() : col;
                    cellItem.displayValue = strVal;
                    if (columnInfo.usePosNegColoring && !cellItem.isNegative && col != 0)
                        cellItem.className = 'grid-cell-positive';
                    else if (columnInfo.usePosNegColoring && cellItem.isNegative)
                        cellItem.className = 'grid-cell-negative';
                    cellItem.dataItemId = colId;
                    row.rowData.isStreming = true;
                    if (keys && cellIndex >= 0 && cellIndex < keys.length) {
                        row.rowData.recordDatas.splice(cellIndex, 1, cellItem);
                    }
                }
            }

        }
        const msindex = this.state.source.findIndex((item) => item.msId == quote.Msid);
        if(msindex !== ''){
            map(quote.ReservedItemIds, (rows) => {
                if(this.state.source && this.state.source[msindex]){
                    let dataItemIndex=  this.state.source[msindex].recordDatas.findIndex((item) => item.dataItemId == rows.Key);
                    if(this.state.source[msindex].recordDatas[dataItemIndex]){
                        let val = '';
                        if(this.state.source[msindex].recordDatas[dataItemIndex].formatter)
                            val = ONeilViewStore.setNumberFormatter(rows.Value,this.state.source[msindex].recordDatas[dataItemIndex].formatter,'');
                        
                        this.state.source[msindex].recordDatas[dataItemIndex].displayValue = val;
                    }
                }
            });
        }
        // this.state.source[msindex].recordDatas[5].displayValue = quote.ReservedItemIds[7].Value.toFixed(2);
        this.updateGridData(cloneSource, row);
        if (interval) clearTimeout(interval);
        if (quoteInterval) clearTimeout(quoteInterval);
        if (this.visibleSymbols.length > 0) {
            interval = setTimeout(() => { this.refreshGridData(cloneSource, row) }, 1000);
            this.lastQuoteTime = new Date();
            quoteInterval = setTimeout(() => {  
                if((new Date() - this.lastQuoteTime) / 1000 >= 3){
                    this.setState({ isUpdateNotRequired: false });
                }
        }, 3000);
        }
    }
    updateGridData(cloneSource, row) {
         let visibleRowData = this.visibleRows && this.visibleRows.filter((item) => item && item.rowData && item.rowData.isRowVisible);
        let visibleRowDataLength = visibleRowData ? visibleRowData.length : 0;
        let cloneVisibleRow = this.visibleRows && this.visibleRows.length > 0 ? JSON.parse(JSON.stringify(this.visibleRows)) : [];
        let rowMsid = row && row.rowData.msId;
        const rowind = cloneVisibleRow.findIndex((itm) => itm.rowData.msId === rowMsid);
        if (visibleRowDataLength > 0 && this.frozenGridBody && this.nonFrozenGridBody && this.shouldUpdateStreamingData) {
            //console.log("called updateGridData");
            if (rowind >= 0) {
                this.frozenGridBody.loadRowsOnNewData(cloneSource);
                this.nonFrozenGridBody.loadRowsOnNewData(cloneSource);
            }
        }
        if(visibleRowDataLength > 0 && rowind >= 0){
            this.visibleRows = [...cloneSource];
        }
    }

    updateCellClass(cellClass) {
        if (cellClass.includes(negativeGround)) {
            cellClass = cellClass.replace(negativeGround, '');
        }
        else if (cellClass.includes(positiveGround)) {
            cellClass = cellClass.replace(positiveGround, '');
        }
        return cellClass;
    }

    refreshGridData(cloneSource, rowData) {
        each(cloneSource, (row) => {
            if (this.visibleSymbols.contains(row.rowData.msId)) {
                const cellSymbolIndex = [...row.rowData.recordDatas].findIndex((itm) => itm.dataItemId === -5);
                let rowData = clone(row.rowData.recordDatas[cellSymbolIndex].className);
                rowData = this.updateCellClass(rowData);
                row.rowData.recordDatas[cellSymbolIndex].style = "";
                row.rowData.recordDatas[cellSymbolIndex].className = rowData;
            }
        });
        this.updateGridData(cloneSource, rowData);
    }

    handleHeaderMouseUp(){
        if(this.frozenHeaderDiv){
            this.frozenHeaderDiv.handleMouseUp();
        }
    }

    render() {
        let settings = SettingsStore.getConsoleSettings();
        let navListManagerSettings = settings.NavListManagerSettings;
        let frozenAreaWidth = this.state.frozenAreaWidth;
        let nonFrozenAreaWidth = this.state.nonFrozenAreaWidth;
        const frozenColumnsLength = this.state.frozenColumns && this.state.frozenColumns.length;
        if(frozenColumnsLength !== this.state.frozenAreaWidthCalculatedLength){
            frozenAreaWidth = this.getFrozenAreaWidth();
            nonFrozenAreaWidth = this.mainDiv ? this.mainDiv.clientWidth - frozenAreaWidth : 0;
        }
        const frozenViewContent = !this.props.showChart ? this.getFrozenViewContent(frozenAreaWidth) : null;
        const nonFrozenViewContent = !this.props.hsowChart ? this.getNonFrozenViewContent(frozenAreaWidth) : null;
        if (this.listExplorerElm)
            this.vH = this.listExplorerElm.getBoundingClientRect().height;
        else {
            this.vH = 0;
        }
        if (!this.summaryStatHeight) {
            this.summaryStatHeight = 0;
        }
        const separater = true;
        const screenSummaryDetails = this.getScreenSummaryDetails();
        const { height, top } = this.state.dimensions;
        this.gridAreaHeight = parseInt(height - top);
        const maxHeightOfSummaryState = this.getMaxHeightOfSummaryState();
        const isPortfolioList = this.isPortfolioList();
        const isNotImportedList = ListExplorerStore.isNotImportedList();
        return (
            <span className="grid-block" ref={(ref) => this.gridBlock = ref}>
                {/*<span style={{ fontWeight: 'bold', position: 'absolute', right: '0', marginTop: '-23px', marginRight: '15px' }}><a id="gridbody_type" onClick={this.changeGridTypeBehavior}>{this.state.gridTypeTable ? 'Canvas' : 'Table'}</a></span>*/}
                { /*For export data*/}
                <span style={{ display: 'none', fontWeight: 'bold', position: 'absolute', right: '0', marginTop: '-23px', marginRight: '15px' }}><a id="export-data" onClick={this.exportData}>Export Data</a></span>
                <div className="group-filter medium-normal">
                    {this.state.openScreenSummary && <ScreenSummary handleClick={this.handleClick}
                        openScreenSummary={this.state.openScreenSummary}
                        screenSummaryDetails={screenSummaryDetails}
                        filterCount={this.props.pageSize}
                        totalCount={this.props.totalItems}
                        onClearAllFilter={this.onClearAllFilter}
                        selectedListId={this.state.selectedListId}
                        listName={this.props.listName}
                        cacheId={this.props.cacheId}
                        isOn={this.state.isOn}
                    />
                    }
                </div>
                {this.state.customFilter && <PortalContainer isCustomColumnFilter="true">{this.CustomColumnFilters}</PortalContainer>}
                <div className="screen-pop">
                    <ButtonToolbar>
                        <ButtonGroup bsSize="" className="custom-on-off-btn" >
                            <Button disabled={isNotImportedList} className="small-normal" onClick={this.handleClick} id="screenButton">{LocalizationStore.getTranslatedData("LM_tp_Screen", "Screen")} {(screenSummaryDetails.length > 0) ? "(" + screenSummaryDetails.length + ")" : null}</Button>
                            <Button disabled={isNotImportedList} className={(this.state.isOn) ? "state-on small-normal" : "state-off small-normal"} onClick={this.onHandleClickOnOff}>{(this.state.isOn) ? LocalizationStore.getTranslatedData("CCG_ON", "ON") : LocalizationStore.getTranslatedData("CCG_OFF", "OFF")}</Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                </div>
                {this.props.loading ?

                    <span className="grid-loading"><div className="group-box "><div className="loading loading-spinner"></div></div></span>

                    : null}
                <div>
                    <ReactHeight onHeightReady={(height) => {
                        const rect = this.mainDiv.getBoundingClientRect();
                        let dimensions = {
                            top: rect.top,
                            right: rect.right,
                            bottom: rect.bottom,
                            left: rect.left,
                            width: rect.width,
                            height: height
                        };
                        this.updateWidthHeightGrid(dimensions);
                    }}>
                           <div style={{ height:
                            `${this.state.summaryStatOpen &&
                                this.gridDiv
                            ?
                            this.vH-this.summaryStatHeight < this.gridDiv.offsetTop * 2 ?
                            (this.gridDiv.offsetTop * 2)-45
                            :
                            this.vH - this.summaryStatHeight - 45 : this.vH - this.summaryStatHeight - 45
                            }px`}}>
                            <Shortcuts
                                name='NAV_LIST_MANAGER'
                                handler={this.handleShortcuts}
                                targetNodeSelector="#main-grid-panel"
                                isolate={true}>
                                {/*eslint-disable*/}
                                <div ref={(div) => { this.mainDiv = div }} id="main-grid-panel" className="main-grid-panel" tabIndex={1}>
                                    {/*eslint-enable*/}
                                    <div className="frozon-left-wrap">
                                        <HeaderRow ref={(headerRow) => { this.frozenHeaderDiv = headerRow }}
                                            isUpdateNotRequired={this.state.isUpdateNotRequired}
                                            onColumnDragging={this.onColumnDrag}
                                            onResize={this.handleColumnResizeFrozen}
                                            onReOrderColumn={this.handleColumnReorder}
                                            handleColumnSort={this.handleColumnSort}
                                            handleColumnCustomFilter={this.handleColumnCustomFilter}
                                            columns={this.state.frozenColumns}
                                            allColumns={this.state.allColumns}
                                            selectedListId={this.state.selectedListId}
                                            viewportWidth={frozenAreaWidth}
                                            onContextMenuClick={this.onContextMenuClick}
                                            onCustomFilterContextMenuClick={this.onCustomFilterContextMenuClick}
                                            onSplitterClick={this.onSplitterClick}
                                            frozenAreaWidth={frozenAreaWidth}
                                            spliter={this.spliterColumns}
                                            separater={separater}
                                            screenOnOff={this.state.isOn}
                                            getColumnWidth={this.getColumnWidth}
                                            onColumnResizerDoubleClick={this.onColumnResizerDoubleClick}
                                        />


                                        <div id="frozenDiv" style={{ height: `${this.gridAreaHeight}px` }} onContextMenu={this.onContextMenuClickEmptyGridArea} className={"frozen-div " + this.props.fontSizeClass}>
                                            {!this.props.showChart && frozenViewContent}
                                        </div>


                                    </div>
                                    <div className="non-frozon-left-wrap" style={{ width: nonFrozenAreaWidth }} >
                                        <HeaderRow
                                            isUpdateNotRequired={this.state.isUpdateNotRequired}
                                            ref={(headerRow) => { this.headerDiv = headerRow }}
                                            onColumnDragging={this.onColumnDrag}
                                            onResize={this.handleColumnResize}
                                            onReOrderColumn={this.handleColumnReorder}
                                            handleColumnSort={this.handleColumnSort}
                                            handleMouseUp = {this.handleHeaderMouseUp}
                                            handleColumnCustomFilter={this.handleColumnCustomFilter}
                                            columns={this.state.columns}
                                            allColumns={this.state.allColumns}
                                            selectedListId={this.state.selectedListId}
                                            viewportWidth={this.state.viewportWidth}
                                            onContextMenuClick={this.onContextMenuClick}
                                            onCustomFilterContextMenuClick={this.onCustomFilterContextMenuClick}
                                            onSplitterClick={this.onSplitterClick}
                                            frozenAreaWidth={frozenAreaWidth}
                                            separater={false}
                                            screenOnOff={this.state.isOn}
                                            getColumnWidth={this.getColumnWidth}
                                            onColumnResizerDoubleClick={this.onColumnResizerDoubleClick}
                                        />
                                        <div id="nonFrozenDiv" style={{ height: `${this.gridAreaHeight}px` }} ref={(div) => { this.gridDiv = div }} className={"grid-non-frozen-div pos-relative1 " + this.props.fontSizeClass}
                                            onContextMenu={this.onContextMenuClickEmptyGridArea}>
                                            {!this.props.showChart && nonFrozenViewContent}
                                        </div>
                                    </div>

                                    <div className="grid-vertical-scrollbar" id='verticalScroll' style={{ height: `${this.gridAreaHeight}px`, marginTop: `${top - 1}px` }}>
                                        <div id='gridVerticalScroll' style={{ overflow: 'hidden' }}>
                                            <div style={{ width: "100%", height: this.state.viewPortHeight }}></div>
                                        </div>
                                    </div>
                                    {!this.props.showChart && <ScrollBar scrollId="gridVerticalScroll" vScroll={true} mouseUp={this.handleVerticalScrollMouseUp} />}

                                    {!this.props.loading ? <div id="grid-load-more" ref={(div) => { this.gridLoadMore = div }} className='grid-body-loading' style={{ display: 'none' }}><div className="list-bottom-loading x-small-bold">{LocalizationStore.getTranslatedData("ri_loading", "Loading")}</div></div> : ''}
                                    <div ref={(div) => { this.adornerDiv = div }} className="resize-grid-panel" >
                                        <ColumnAdornerRow ref={(div) => { this.adorner = div }} onAdornerMouseUp={this.onAdornerMouseUp} getColumnWidth={this.getColumnWidth} />
                                    </div>
                                </div>



                                <div className="grid-horizontal-scrollbar" id='horizontalScroll' style={{ width: nonFrozenAreaWidth }} >
                                    <div id='gridHorizontalScroll' style={{ overflow: 'hidden' }}>
                                        <div style={{ width: this.state.viewportWidth + 18, height: "100%" }}></div>
                                    </div>
                                </div>
                                {nonFrozenAreaWidth ? <ScrollBar scrollId="gridHorizontalScroll" hScroll={true} vScroll={false} /> : ''}

                            </Shortcuts>
                        </div>
                    </ReactHeight>

                    {!isPortfolioList && !this.props.loading && navListManagerSettings.SelectedTabKey == ListManagerTabType.Oneil && !this.props.showChart && <SummaryStat
                        adjustHeight={this.adjustHeight}
                        height={this.summaryStatHeight}
                        summaryStatToggle={this.summaryStatToggle}
                        summaryStatOpen={this.state.summaryStatOpen}
                        nonFrozenAreaWidth={nonFrozenAreaWidth}
                        viewportWidth={this.state.viewportWidth}
                        frozenAreaWidth={frozenAreaWidth}
                        textSize={this.textSize}
                        rowHeight={this.rowHeight}
                        loading={this.props.loading}
                        nonFrozenColumns={this.state.columns}
                        frozenColumns={this.state.frozenColumns}
                        allColumns={this.state.allColumns}
                        getMouseOverColumnIndex={this.getMouseOverColumnIndex}
                        ref={(div) => this.summaryStateDiv = div}
                        maxHeightOfSummaryState={maxHeightOfSummaryState}
                        applyColumnFilter={this.applyColumnFilter}
                        tooltip = {{ showTooltip:this.showTooltip, hideTooltip: this.hideTooltip }}
                    />}
                </div>


                <div id="grid-drag-ghost-image"></div>
                {this.state.tooltip.isShowTooltip && <PortalContainer><div id="grid-row-tooltip" className="grid-row-tooltip" style={this.state.tooltip.style}>{this.state.tooltip.text}</div></PortalContainer>}
                <div id="arrow11" className="icn-up-down-arrow">
                    {/*<div className="up"></div>
                    <div className="down"></div>*/}
                </div>
                <table id="messureHeight" style={{ visibility: 'hidden' }}><tbody><tr className="grid-row grid-row-even"><td className="grid-cell"><span className="customFont" id="textSpan">Sample Google</span></td></tr></tbody></table>
            </span>
        );
    }
}
