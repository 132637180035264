import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetListDefaultGicsBrowserRequest extends BaseServiceRequest {
    constructor() {
        super();
        super.addRequestUri("defaultigfilters.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "ListIGBrowserResponse";
    }
}
export default GetListDefaultGicsBrowserRequest;