const symbolIdentifier = ".@";
export default class SummaryBlockDescription {
  constructor(description) {
    if (!description) {
      return;
    }

    const longDescr = description.replace("%:", "");
    let splitIndex = description.indexOf(symbolIdentifier);

    if (splitIndex === -1 && description.length > 120) {
      const substring = description.substring(120, description.length - 121);
      if (substring) {
        const firstperiodindex = substring.indexOf(". ");
        this.shortDescription = description.substring(0, firstperiodindex + 121);
        this.defualtDescription = this.shortDescription;
      }
      this.longDescription = description;
    } else if (splitIndex === -1 && description.length < 120) {
      this.shortDescription = description;
      this.defualtDescription = this.shortDescription;
      this.longDescription = longDescr;
    } else {
      if (splitIndex > 120) {
        splitIndex = 120;
      }
      this.shortDescription = splitIndex < 0 ? description : description.substring(0, splitIndex).trim();
      this.defualtDescription = this.shortDescription;
      let moreInfo = description.substring(splitIndex + symbolIdentifier.length).trim();

      moreInfo = moreInfo.charAt(0).toUpperCase() + moreInfo.substring(1, moreInfo.length);
      this.longDescription = `${this.shortDescription}. ${moreInfo}`;
    }
  }
}