import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class ReactivateStockAlertRequest extends BaseServiceRequest {
  constructor(alertID, alertTypeId) {
    super();

    super.addRequestUri("reactivate2.pb");
    super.addPathParameter(alertID.toString());
    super.addRequestParameter("alertTypeId", alertTypeId.toString());
  }

  getMethod() {
    return HttpMethodType.PUT;
  }

  getSubSystem() {
    return SubSystemType.ALERTS;
  }

  getMessageName() {
    return "Response";
  }

}