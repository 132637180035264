import React, { Component } from 'react';
import { toggleCalendar, setSelectedDate, subtractYear, addYear, handleYearChangeOnEnterKey, setViewMonth, resetCalendar, setMostRecentTradeDate } from '../../../../../Actions/ComparisonActions';
import { connect } from 'react-redux';
import DateTimePicker from "../../../../../RayCustomControls/CalenderControl/DateTimePicker.jsx";
import PropTypes from 'prop-types';
import moment from "moment";
import {CompareMenuType} from "CompareTabType";

class ComparisonCalendarEvent extends Component {

  closePicker = (isSetSelectedDateActive = false) => {
    document.body.classList.remove('comparisonCalendarOpened');
    const eventObj = "close";
    this.props.toggleCalendar(eventObj, isSetSelectedDateActive);
  }

  subtractYear = () => {
    const { viewDate, minDate } = this.props;
    const prevYear = viewDate.year() - 1;
    const minDateYear = minDate.year();

    if (prevYear < minDateYear)
      return false;
    else {
      this.props.subtractYear();
    }
  }

  addYear = () => {
    const { viewDate, maxDate } = this.props;
    const nextYear = viewDate.year() + 1;
    const maxDateYear = maxDate.year();

    if (nextYear > maxDateYear) return false;
    else this.props.addYear();
  }

  handleYearChange = (e) => {
    const { minDate, maxDate, viewDate, selectedDate } = this.props;
    let displayDate;
    const inputYear = parseInt(e.target.value);
    if (e.keyCode == 13 && inputYear != '') {
      const minDateYear = minDate.year();
      const maxDateYear = maxDate.year();

      if (minDateYear > inputYear) displayDate = viewDate.year(minDateYear);
      else if (maxDateYear < inputYear) displayDate = viewDate.year(maxDateYear);
      else displayDate = viewDate.year(inputYear);

      const data = {
        selectedDate: selectedDate.year(displayDate.year()),
        viewDate
      };
      this.props.handleYearChangeOnEnterKey(data);
    }
  }

  setViewMonth = (month) => {
    this.props.setViewMonth(month);
  }

  setSelectedDate = (e) => {
    const { target } = e;
    const { selectedDate, viewDate } = this.props;
    let tempDate;

    if (target.className && !target.className.match(/disabled/g)) {
      let month;
      if (target.className.indexOf("new") >= 0) month = viewDate.month() + 1;
      else if (target.className.indexOf("old") >= 0) month = viewDate.month() - 1;
      else month = viewDate.month();

      tempDate = viewDate.clone().month(month).date(parseInt(target.innerHTML)).hour(selectedDate.hours()).minute(selectedDate.minutes());

      const data = {
        selectedDate: tempDate,
        viewDate
      }
      this.props.setSelectedDate(data);
      this.props.resetCalendar();
      this.closePicker();
    }
  }

  setMostRecentTradeDate = () => {
    this.props.setMostRecentTradeDate();
    this.props.resetCalendar();
  }

  render() {
    const { showCalendarLeft, showCalendarRight, daysOfWeekDisabled, selectedDate, maxDate, minDate, showToday,
      viewDate, widgetClasses, widgetStyle, isMostRecentTradeDayTb } = this.props;

    const tempSelectedDate = this.props.date && moment(this.props.date, "MM-DD-YYYY") === selectedDate ? moment(this.props.date, "MM-DD-YYYY") : selectedDate;
    const tempViewDate = this.props.date && moment(this.props.date, "MM-DD-YYYY") === viewDate ? moment(this.props.date, "MM-DD-YYYY") : viewDate;

    return (
      <div className="DateTimePicker">
        <div className='bootstrap-datetimepicker-overlay' />
        <DateTimePicker
          addYear={this.addYear}
          daysOfWeekDisabled={daysOfWeekDisabled}
          maxDate={maxDate}
          minDate={minDate}
          selectedDate={tempSelectedDate}
          setSelectedDate={this.setSelectedDate}
          setViewMonth={this.setViewMonth}
          showToday={showToday}
          subtractYear={this.subtractYear}
          viewDate={tempViewDate}
          widgetClasses={widgetClasses}
          widgetStyle={widgetStyle}
          handleYearChange={this.handleYearChange}
          closePicker={this.closePicker}
          showDatePicker={showCalendarLeft || showCalendarRight}
          isMostRecentTradeDayTb={isMostRecentTradeDayTb}
          setMostRecentTradeDate={this.setMostRecentTradeDate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.calendarControl;

  const { showCalendarLeft, showCalendarRight, daysOfWeekDisabled, selectedDate, maxDate, minDate, showToday,
    viewDate, widgetClasses, widgetStyle, isMostRecentTradeDayTb } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

  return {
    showCalendarLeft, showCalendarRight, daysOfWeekDisabled, selectedDate, maxDate, minDate, showToday,
    viewDate, widgetClasses, widgetStyle, isMostRecentTradeDayTb,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCalendar: (event, isSetSelectedDateActive) => dispatch(toggleCalendar(event, isSetSelectedDateActive)),
    setSelectedDate: (selectedDate) => dispatch(setSelectedDate(selectedDate)),
    subtractYear: (selectedYear) => dispatch(subtractYear(selectedYear)),
    addYear: (selectedYear) => dispatch(addYear(selectedYear)),
    handleYearChangeOnEnterKey: (selectedYear) => dispatch(handleYearChangeOnEnterKey(selectedYear)),
    setViewMonth: (month) => dispatch(setViewMonth(month)),
    resetCalendar: () => dispatch(resetCalendar()),
    setMostRecentTradeDate: () => dispatch(setMostRecentTradeDate()),
  }
}

ComparisonCalendarEvent.propTypes = {
  date: PropTypes.string.isRequired
}


export default connect(mapStateToProps, mapDispatchToProps)(ComparisonCalendarEvent);