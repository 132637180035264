import _ from "underscore";
import AlertColumnSet from "../../../../RayCustomControls/Alerts/AlertColumnSet";
import AlertConditionType from "../../../../Constants/AlertConditionType";
import AlertSourceType from "../../../../Constants/AlertSourceType";
import BaseServiceApi from 'BaseServiceApi';
import CommonDialog from "../../../../RayCustomControls/CommonDialog/CommonDialog";
import { connect } from "react-redux";
import DatagraphHelper from "../../../../Utils/DatagraphHelper";
import ErrorBoundary from "ErrorBoundary";
import FilterListDialog from "./FilterListDialog";
import FlexibleModal from "../../../../RayCustomControls/FlexibleModal.jsx";
import GeographyAlertDialog from "../../../../RayCustomControls/Alerts/GeographyAlertDialog.jsx";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import NotificationContentContainer from '../../../../RayCustomControls/Alerts/NotificationContentContainer';
import ReactHtmlParser from "react-html-parser";
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import SymbolType from "../../../../Constants/SymbolType";
import UserInfoUtil from "UserInfoUtil";
import { AlertsDialogTranslateHelper, TranslateHelper } from "../../../../Utils/TranslateHelper";
import { cancelCriteriaDialog, closeAlertDialog, saveAlertData, saveCriteriaDialog } from "../../../../Actions/AlertActions";
import { Modal, FormGroup, PanelGroup, Panel, Checkbox, Form, Table } from "react-bootstrap";
import React, { Fragment, Component } from "react";

const InstrumentType = BaseServiceApi.rayData["InstrumentType"];
const ListAlerts = BaseServiceApi.rayData["ListAlerts"];
const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

class OrAlertsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertSubTitle: this.getAlertSubTitle(),
            TargetPrice: props.TargetPrice,
            TargetPriceSelected: props.TargetPriceSelected,
            openColumnSet: null,
            prEntitled: UserInfoUtil.IsUserEntitled(EntitlementType.PR_Entitlement),
            validationMessages: {},
            openGeography: null,
            commonDialog: null,
            isNCOpen: false,
            newHeight: null,
            compareHeight: null,
            symbolType: !StringUtil.isEmpty(this.props.SymbolInfo) ? this.props.SymbolInfo.SymTypeEnum : null,
        }

        this.getXPosition = this.getXPosition.bind(this);
        this.getYPosition = this.getYPosition.bind(this);
        this.getAlertSubTitle = this.getAlertSubTitle.bind(this);
        this.handleCheckedChange = this.handleCheckedChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onNotificationPreferenceClick = this.onNotificationPreferenceClick.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.openColumnSetWindow = this.openColumnSetWindow.bind(this);
        this.closeColumnSetWindow = this.closeColumnSetWindow.bind(this);
        this.openGeographyWindow = this.openGeographyWindow.bind(this);
        this.closeGeographyWindow = this.closeGeographyWindow.bind(this);
        this.saveAlertData = this.saveAlertData.bind(this);
        this.doneBtnClick = this.doneBtnClick.bind(this);
        this.criteriaLimitCheck = this.criteriaLimitCheck.bind(this);
        this.validationCheck = this.validationCheck.bind(this);
        this.hideAlertMessage = this.hideAlertMessage.bind(this);
        this.showAlertMessage = this.showAlertMessage.bind(this);
        this.scrollToErrorBox = this.scrollToErrorBox.bind(this);
        this.scrollToError = this.scrollToError.bind(this);
        this.setErrorDovLocation = this.setErrorDovLocation.bind(this);
        this.validateField = this.validateField.bind(this);
        this.getAlertId = this.getAlertId.bind(this);
        this.checkScrolling = this.checkScrolling.bind(this);
    }

    componentDidMounto() {
        this.editingAlertTypeId = this.props.alertTypeId;
    }

    componentDidUpdate() {
        if (this.editingAlertTypeId) {
            let id = Object.keys(ListAlerts).find((k) => ListAlerts[k] === this.editingAlertTypeId);

            if (id === "DailyPricerisestowithinofpivot" || id === "WeeklyPricerisestowithinofpivot") { id = "Pricerisestowithinofpivot"; }

            setTimeout(() => {
                if (document.getElementById(`txt${id}`)) {
                    document.getElementById(`txt${id}`).select();
                    document.getElementById(`txt${id}`).focus();
                    document.getElementById(`txt${id}`).scrollIntoView()
                }
            }, 500);

            this.editingAlertTypeId = null;
        }
    }
    getXPosition() {
        let x = 0;
        const w = 460;

        if (this.props.conditionTypeId === AlertConditionType.And) {
            const left = document.getElementById("andAlertHeader").getBoundingClientRect().left - 2;
            x = left //- (w - 30) / 2;
        }
        else {
            x = (Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - w) / 2
        }

        if (x < 0) {
            x = 0;
        }

        return x;
    }

    getYPosition() {
        let y = 0;
        let h = 400;

        if (this.props.sourceTypeId === AlertSourceType.List) {
            h = 600;
        }

        if (this.props.conditionTypeId === AlertConditionType.And) {
            const top = document.getElementById("andAlertHeader").getBoundingClientRect().top - 2;
            if ((top + h) > Math.max(document.documentElement.clientHeight, window.innerHeight || 0)) { y = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h; }
            else { y = top; }//- (h - 30) / 2;
        }
        else {
            y = (Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - h) / 2
        }

        if (y < 0) {
            y = 0;
        }
        if (this.props.y) {
            y = this.props.y;
        }
        return y;
    }


    getAlertSubTitle() {
        let subTitle;
        const str = this.props.conditionTypeId === AlertConditionType.Or ? 'ANY' : 'ALL';
        if (this.props.sourceTypeId === AlertSourceType.List) {
            subTitle = LocalizationStore.getTranslatedData("alert_list_msg", 'Alert me when any symbol on {0} meets {1} of the following conditions:', this.props.listName, str);
        } else {
            subTitle = LocalizationStore.getTranslatedData("ch_msa_msgalert", 'Alert me when {0} of the selected conditions are met:', str);
        }
        return subTitle;
    }
    //Alert Store change listener - End

    //Check box change event
    handleCheckedChange(e) {
        if (e) {
            const controlName = e.target.name;

            if (!e.target.checked || this.criteriaLimitCheck(false, controlName) || controlName === "DailyVolumeRateDelaySelected") {
                if (controlName === "TargetPriceSelected") {
                    this.setState({
                        TargetPriceSelected: !this.state.TargetPriceSelected
                    });
                }
                else if (controlName === "DailyVolumeRateSelected") {
                    const listAlertSettings = this.props.alertCriteriaListSettings;
                    const targetValue = !listAlertSettings[controlName];
                    listAlertSettings[controlName] = targetValue;
                    if (!targetValue) {
                        listAlertSettings["DailyVolumeRateDelaySelected"] = targetValue;
                    }

                    this.setState({
                        listAlertSettings: listAlertSettings
                    });
                }
                else {
                    const listAlertSettings = this.props.alertCriteriaListSettings;
                    listAlertSettings[controlName] = !listAlertSettings[controlName];

                    this.setState({
                        listAlertSettings: listAlertSettings
                    });
                }
            }
        }
    }

    handleKeyUp(event) {
        if (event.target.value.length > event.target.maxLength) {
            event.target.value = event.target.value.slice(0, event.target.maxLength)
        }
        else {
            this.validationCheck();
            event.target.autofocus = true;
        }

    }
    //Textbox value change event
    handleValueChange(event) {
        if (event) {
            if (event.target.name === "TargetPrice") {
                if (parseFloat(event.target.value) > -1 || event.target.value === "") {
                    this.setState({
                        TargetPrice: parseFloat(event.target.value)
                    });
                }
                else {
                    this.setState({
                        TargetPrice: ''
                    });
                }
            }
            else {
                const listAlertSettings = this.props.alertCriteriaListSettings;

                listAlertSettings[event.target.name] = parseInt(event.target.value.slice(0, event.target.maxLength));

                this.setState({
                    listAlertSettings: listAlertSettings
                });
            }
        }
    }

    closeDialog() {
        this.props.closeAlert();
    }

    onNotificationPreferenceClick() {
        this.setState({ isNCOpen: true });
    }

    hideModal() {
        this.setState({ isNCOpen: false });
    }

    openColumnSetWindow() {
        const left = document.getElementById("modalBody").getBoundingClientRect().left + 69;
        const top = document.getElementById("modalBody").getBoundingClientRect().top + 15;

        const openColumnSet = <AlertColumnSet left={left} top={top} closeColumnSetWindow={this.closeColumnSetWindow} />
        this.setState({ openColumnSet: openColumnSet });
        setTimeout(() => {
            document.getElementsByClassName("alert-columnset")[0].classList.add("slide-down");
        }, 50)
    }

    closeColumnSetWindow() {
        const self = this;

        document.getElementsByClassName("alert-columnset")[0].classList.remove("slide-down");
        setTimeout(() => {
            self.setState({ openColumnSet: null })
        }, 450)
    }

    openGeographyWindow() {
        const left = document.getElementById("modalBody").getBoundingClientRect().left + 69;
        const top = document.getElementById("modalBody").getBoundingClientRect().top + 15;

        const openGeography = <GeographyAlertDialog left={left} top={top} closeGeographyWindow={this.closeGeographyWindow} existAlerts={this.props.existAlerts} />
        this.setState({ openGeography: openGeography });
        setTimeout(() => {
            document.getElementsByClassName("alert-geography")[0].classList.add("slide-down");
        }, 50)
    }

    closeGeographyWindow() {
        const self = this;

        document.getElementsByClassName("alert-geography")[0].classList.remove("slide-down");
        setTimeout(() => {
            self.setState({ openGeography: null });
        }, 450)
    }

    saveAlertData() {
        if (this.validationCheck()) {
            document.getElementById("btnSaveAlert").disabled = true;
            this.props.saveAlertData(this.props.alertCriteriaListSettings, this.props.conditionTypeId, null, null);
        }
    }

    doneBtnClick() {
        if (this.validationCheck()) {
            let priceAlertObj = {};

            if (this.state.TargetPriceSelected) {
                priceAlertObj = {
                    msId: this.props.msId,
                    targetPrice: parseFloat(this.state.TargetPrice),
                    currencyCode: this.props.SymbolInfo.DefaultCurrency.CurrencyCode ? this.props.SymbolInfo.DefaultCurrency.CurrencyCode : this.props.SymbolInfo.DefaultCurrency.Code
                }
            }
            else {
                priceAlertObj = null;
            }

            this.props.saveCriteriaDialog(this.props.alertCriteriaListSettings, priceAlertObj);
        }
    }

    cancelBtnClick() {
        this.closeDialog();
    }

    criteriaLimitCheck(isZeroCheck, controlName) {

        if (this.props.conditionTypeId === AlertConditionType.And) {
            let selectedCriteriaCount = 0;

            _.each(this.props.alertCriteriaListSettings, (val, key) => {
                if (key !== "DailyVolumeRateDelaySelected") {
                    if ((key.lastIndexOf("Selected") === key.length - 8) || (key.lastIndexOf("Checked") === key.length - 7)) {
                        if (val === true) { selectedCriteriaCount++; }
                    }
                }
            });

            if (this.state.TargetPriceSelected) {
                selectedCriteriaCount++;
            }
            if (controlName === "DailyVolumeRateDelaySelected") {
                return true;
            }

            if (!isZeroCheck && selectedCriteriaCount >= 2) {
                this.showAlertMessage("The maximum number of criteria is 2.");
                return false;
            }
            else if (isZeroCheck && selectedCriteriaCount === 0) {
                this.showAlertMessage("Select at least 1 criteria.");
                return false;
            }
        }
        return true;
    }

    validationCheck() {
        let isValid = true;
        const validationMessages = {};

        if (!this.criteriaLimitCheck(true)) { return false; }

        validationMessages.PriceChangeThreshold = this.validateField(this.props.alertCriteriaListSettings.PriceChangeSelected, this.props.alertCriteriaListSettings.PriceChangeThreshold, 1, 999);
        validationMessages.DailyVolumeRateThreshold = this.validateField(this.props.alertCriteriaListSettings.DailyVolumeRateSelected, this.props.alertCriteriaListSettings.DailyVolumeRateThreshold, 50, 999)
        validationMessages.DailyVolumeRateDelayThreshold = this.validateField(this.props.alertCriteriaListSettings.DailyVolumeRateDelaySelected, this.props.alertCriteriaListSettings.DailyVolumeRateDelayThreshold, 1, 720)
        validationMessages.AverageDlyVolThreshold = this.validateField(this.props.alertCriteriaListSettings.AverageDlyVolSelected, this.props.alertCriteriaListSettings.AverageDlyVolThreshold, 100, 999999999)
        validationMessages.AverageDlyDlrVolThreshold = this.validateField(this.props.alertCriteriaListSettings.AverageDlyDlrVolSelected, this.props.alertCriteriaListSettings.AverageDlyDlrVolThreshold, 500, 999999999)
        validationMessages.EarningsReleaseDays = this.validateField(this.props.alertCriteriaListSettings.EarningsReleaseSelected, this.props.alertCriteriaListSettings.EarningsReleaseDays, 1, 365)
        validationMessages.AlertPriceRises = this.validateField(this.props.alertCriteriaListSettings.DailyPriceRisesSelected || this.props.alertCriteriaListSettings.WeeklyPriceRisesSelected, this.props.alertCriteriaListSettings.AlertPriceRises, 1, 999)
        validationMessages.NegativeAlter1Week = this.validateField(this.props.alertCriteriaListSettings.NegativeAlter1WeekSelected, this.props.alertCriteriaListSettings.NegativeAlter1Week, 1, 11)
        validationMessages.NegativeAlter10Week = this.validateField(this.props.alertCriteriaListSettings.NegativeAlter10WeekSelected, this.props.alertCriteriaListSettings.NegativeAlter10Week, 1, 11)

        if (this.props.conditionTypeId === AlertConditionType.And && this.props.sourceTypeId === AlertSourceType.Stock) { validationMessages.TargetPrice = this.validateField(this.state.TargetPriceSelected, this.state.TargetPrice, 0, null, 1); }

        else { validationMessages.TargetPrice = ''; }

        if (!StringUtil.isEmpty(validationMessages.PriceChangeThreshold +
            validationMessages.DailyVolumeRateThreshold +
            validationMessages.DailyVolumeRateDelayThreshold +
            validationMessages.AverageDlyVolThreshold +
            validationMessages.AverageDlyDlrVolThreshold +
            validationMessages.EarningsReleaseDays +
            validationMessages.AlertPriceRises +
            validationMessages.TargetPrice +
            validationMessages.NegativeAlter1Week +
            validationMessages.NegativeAlter10Week)) {
            isValid = false;
        }

        this.setErrorDovLocation();

        this.scrollToErrorBox(validationMessages);

        this.setState({ validationMessages: validationMessages });

        return isValid;
    }

    hideAlertMessage() {
        this.setState({ commonDialog: null });
    }

    showAlertMessage(msg) {

        const dialog = <CommonDialog
            isOpen={true}
            title='Error'
            backButtonShow={false}
            cancelButtonShow={false}
            submitButtonShow={true}
            submitHandler={this.hideAlertMessage}
            hideModal={this.hideAlertMessage}
            submitButtonText='OK'
        >
            <Fragment><div className="alert-stuff"><p>{LocalizationStore.getTranslatedData(`cm_${msg}`, msg)}</p></div><div className="clearfix"></div></Fragment>
        </CommonDialog>
        this.setState({ commonDialog: dialog });
    }

    scrollToErrorBox(validationMessages) {
        if (!StringUtil.isEmpty(validationMessages.PriceChangeThreshold)) { this.scrollToError("PriceChangeThresholdGroup"); }
        else if (!StringUtil.isEmpty(validationMessages.DailyVolumeRateThreshold)) { this.scrollToError("DailyVolumeRateexceedsGroup"); }
        else if (!StringUtil.isEmpty(validationMessages.AverageDlyVolThreshold)) { this.scrollToError("AverageDailyVolumecrossesGroup"); }
        else if (!StringUtil.isEmpty(validationMessages.AverageDlyDlrVolThreshold)) { this.scrollToError("AverageDailyDlrVolumecrossesGroup"); }
        else if (!StringUtil.isEmpty(validationMessages.EarningsReleaseDays)) { this.scrollToError("EarningsreleaseiswithinGroup"); }
        else if (!StringUtil.isEmpty(validationMessages.AlertPriceRises)) { this.scrollToError("PricerisestowithinofpivotGroup"); }
        else if (!StringUtil.isEmpty(validationMessages.TargetPrice)) { this.scrollToError("TargetPriceGroup"); }
        else if (!StringUtil.isEmpty(validationMessages.NegativeAlter1Week)) { this.scrollToError("NegativeAlter1WeekGroup"); }
        else if (!StringUtil.isEmpty(validationMessages.NegativeAlter10Week)) { this.scrollToError("NegativeAlter10WeekGroup"); }
    }

    scrollToError(id) {
        setTimeout(() => {
            document.getElementById(id).scrollIntoView()
        }, 500);
    }

    getAlertEntitledBySymbolType(symbolType) {
        let prEntitled = true, corporateEventAlertable = true;
        switch (symbolType) {
            case SymbolType.INDEX:
            case SymbolType.INDEXNOINTRADAYDATA:
            case SymbolType.INDEXNOINTRADAYVOL:
            case SymbolType.INDEXNOINTRADAYVOLDATA:
            case SymbolType.INTERNATIONALINDEX:
            case SymbolType.HISTORICINDEX:
            case SymbolType.WONINDUSTRYGROUP197:
            case SymbolType.WONMAJORINDUSTRY89:
            case SymbolType.WONSECTOR11:
            case SymbolType.WONSUBSECTOR33:
                prEntitled = false;
                corporateEventAlertable = false;
                break;
            default:
                break;
        }
        if (this.props.sourceTypeId === AlertSourceType.List && this.props.symbol === null && this.props.corporateEventAlertable === true) {
            prEntitled = true;
            corporateEventAlertable = true;
        } else if (this.props.sourceTypeId !== AlertSourceType.List && this.props.symbol !== null && this.props.corporateEventAlertable === true) {
            if (this.props.instrumentType && this.props.instrumentType !== InstrumentType.STOCK_Instrument) {
                prEntitled = false;
                corporateEventAlertable = false;
            } else if ((this.props.instrumentType && this.props.instrumentType === InstrumentType.STOCK_Instrument) || this.state.symbolType === null) {
                prEntitled = true;
                corporateEventAlertable = true;
            }
        }
        return { prEntitled: prEntitled, corporateEventAlertable: corporateEventAlertable };
    }

    setErrorDovLocation() {
        document.getElementById("PriceChangeThresholdErrorDiv").style.right = `${(document.getElementById("PriceChangeThresholdGroup").getBoundingClientRect().right - document.getElementById("txtDailypricechangeexceeds").getBoundingClientRect().right).toString()}px`;
        document.getElementById("DailyVolumeRateexceedsErrorDiv").style.right = `${(document.getElementById("DailyVolumeRateexceedsGroup").getBoundingClientRect().right - document.getElementById("txtDailyVolumeRateexceeds").getBoundingClientRect().right).toString()}px`;
        document.getElementById("DailyVolumeRateexceedsDelayErrorDiv").style.right = `${(document.getElementById("DailyVolumeRateexceedsDelayGroup").getBoundingClientRect().right - document.getElementById("txtDailyVolumeRateexceedsDelay").getBoundingClientRect().right).toString()}px`;
        document.getElementById("AverageDailyVolumecrossesErrorDiv").style.right = `${(document.getElementById("AverageDailyVolumecrossesGroup").getBoundingClientRect().right - document.getElementById("txtAverageDailyVolumecrosses").getBoundingClientRect().right).toString()}px`;
        document.getElementById("AverageDailyDlrVolumecrossesErrorDiv").style.right = `${(document.getElementById("AverageDailyDlrVolumecrossesGroup").getBoundingClientRect().right - document.getElementById("txtAverageDailyDlrVolumecrosses").getBoundingClientRect().right).toString()}px`;
        if (this.props.sourceTypeId === AlertSourceType.List) {
            document.getElementById("NegativeAlter1WeekErrorDiv").style.right = `${(document.getElementById("NegativeAlter1WeekGroup").getBoundingClientRect().right - document.getElementById("txtNegativeAlter1Week").getBoundingClientRect().right).toString()}px`;
            document.getElementById("NegativeAlter10WeekErrorDiv").style.right = `${(document.getElementById("NegativeAlter10WeekGroup").getBoundingClientRect().right - document.getElementById("txtNegativeAlter10Week").getBoundingClientRect().right).toString()}px`;
        }
        if (document.getElementById("PricerisestowithinofpivotErrorDiv")) { document.getElementById("PricerisestowithinofpivotErrorDiv").style.right = `${(document.getElementById("PricerisestowithinofpivotGroup").getBoundingClientRect().right - document.getElementById("txtPricerisestowithinofpivot").getBoundingClientRect().right).toString()}px`; }

        if (document.getElementById("EarningsreleaseiswithinErrorDiv")) { document.getElementById("EarningsreleaseiswithinErrorDiv").style.right = `${(document.getElementById("EarningsreleaseiswithinGroup").getBoundingClientRect().right - document.getElementById("txtEarningsreleaseiswithin").getBoundingClientRect().right).toString()}px`; }

        if (this.props.conditionTypeId === AlertConditionType.And && this.props.sourceTypeId === AlertSourceType.Stock) { document.getElementById("TargetPriceGroupErrorDiv").style.right = `${(document.getElementById("TargetPriceGroup").getBoundingClientRect().right - document.getElementById("txtTargetPrice").getBoundingClientRect().right).toString()}px`; }
    }

    validateField = (isSelected, data, min, max, isPriceAlert) => {
        let message = '';

        if (isSelected) {
            if ((StringUtil.isEmpty(data) || isNaN(data)) && isPriceAlert) {
                message = LocalizationStore.getTranslatedData('sy_greater', 'Enter a value greater than or equal to {0}', min.toString());
            }
            else if (StringUtil.isEmpty(data) || isNaN(data)) {
                message = LocalizationStore.getTranslatedData('sy_valid', 'Enter a valid value');
            }
            else if (min !== null && max !== null) {
                if (data < min) {
                    message = LocalizationStore.getTranslatedData('sy_greater', 'Enter a value greater than or equal to {0}', min.toString());
                }
                else if (data > max) {
                    message = LocalizationStore.getTranslatedData('sy_less', 'Enter a value less than or equal to {0}', max.toString());
                }
            }
        }

        return message;
    }

    getAlertId() {
        this.props.alertId ? this.props.alertId : 0;
    }

    checkScrolling() {
        setTimeout(() => {
            let newHeight = null;
            let compareHeight = null;
            if (document.getElementById('alertDialogSetVerticalScroll')) {
                newHeight = document.getElementById('allAlertCollapsible').clientHeight;
                compareHeight = document.getElementById('alertDialogSetVerticalScroll').clientHeight;
                this.setState({
                    newHeight: newHeight,
                    compareHeight: compareHeight
                })
            }
        }, 500);
    }

    render() {
        const { newHeight, compareHeight } = this.state;
        return (
            <ErrorBoundary>
                <section className="alert-b">
                    {this.props.showLoader ? <div className="loading-box">
                        <div className="loading-backdrop"></div>
                        <div className="loading loading-spinner"></div>
                    </div>
                        :
                        <section id="AlertDialog">
                            {this.state.openColumnSet}
                            {this.state.openGeography}
                            <FlexibleModal left={this.getXPosition()} top={this.getYPosition()} open={this.props.isOpenAlertCriteria} ref={(modalHeader) => (this.modalHeader = modalHeader)}
                                draggable={this.props.conditionTypeId === AlertConditionType.And ? false : "false"} isDefaultDragEnabled={false} className="modal-popup alert-dialog or-condition-alert">

                                <Modal.Header className="enable-drag">
                                    <Modal.Title className="enable-drag">
                                        <div className="enable-drag">
                                            {this.props.conditionTypeId === AlertConditionType.And ? <button type="button" id="btnDone" className="btn btn-xs btn-default btn-secondary btn-done " data-effect="fadeOut" onClick={this.doneBtnClick}>{AlertsDialogTranslateHelper.DONE}</button> : null}
                                            {this.props.conditionTypeId === AlertConditionType.And ? <button type="button" id="btnCancel" className="btn btn-xs btn-default btn-secondary btn-cancel " data-effect="fadeOut" onClick={this.props.cancelCriteriaDialog}>{TranslateHelper.CANCEL}</button> : null}
                                            <span className="cap-header enable-drag">{this.props.alertTitle}</span>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body id="modalBody">
                                    <div className="criteria-alert-dialog">{this.state.commonDialog}</div>
                                    <div className="dialog-content">

                                        <div className="flexbox-parent">
                                            <div className="flexbox-item">
                                                <div className="alert-heading">
                                                    {this.props.listAlertSubTitle}
                                                </div>
                                                <div className="alert-sub-heading">
                                                    <div className="tool-tip-box">
                                                        <span className="list-alert-name">{ReactHtmlParser(this.state.alertSubTitle)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flexbox-item content">

                                                {/* <div className={"fill-area-content flexbox-item-grow or-alert-scroll custom-scroll-light or-alert-scroll " + `${newHeight > compareHeight ? " " : "scroll-hide"}`}> */}
                                                <div className={`custom-scroll-light ${compareHeight === null || (newHeight > compareHeight) ? " " : "scroll-hide"}`}>
                                                    <div className="custom-scroll">
                                                        <div id="alertDialogSetVerticalScroll">
                                                            <PanelGroup className="alert-panel-group" id="panel-group-alert">
                                                                <div className="alert-panel-hold" id="allAlertCollapsible">
                                                                    {this.props.addRemoveAlertable === true &&
                                                                        <Panel defaultExpanded eventKey="1">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{AlertsDialogTranslateHelper.ADDITION_AND_REMOVAL}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible >
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbAdditions" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.AdditionSelected} name="AdditionSelected"><div className="chk-box">{AlertsDialogTranslateHelper.ADDITION}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbRemovals" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.RemovalSelected} name="RemovalSelected"><div className="chk-box">{AlertsDialogTranslateHelper.REMOVAL}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel>}
                                                                    {this.props.priceVolumeAlertable === true &&
                                                                        <Panel defaultExpanded eventKey="2">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{AlertsDialogTranslateHelper.PRICE_AND_VOLUME}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <ul>
                                                                                            {this.props.conditionTypeId === AlertConditionType.And && this.props.sourceTypeId === AlertSourceType.Stock &&
                                                                                                <li>
                                                                                                    <FormGroup id="TargetPriceGroup" controlId="formInlineName">
                                                                                                        <Checkbox id="rbTargetPrice" type="radio" onChange={this.handleCheckedChange} checked={this.state.TargetPriceSelected} name="TargetPriceSelected"><div className="chk-box">{AlertsDialogTranslateHelper.PRICE} {this.props.SymbolInfo ? DatagraphHelper.getCurrencyCode(this.props.SymbolInfo.DefaultCurrency) : ""} </div></Checkbox>
                                                                                                        <div className="input-text">
                                                                                                            <input type="number" id="txtTargetPrice" className="form-control disableDefaultSymbolInput" maxLength="100" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={(this.state.TargetPrice)} name="TargetPrice" /></div>
                                                                                                        <small id="TargetPriceGroupErrorDiv" className={(this.state.validationMessages.TargetPrice ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.TargetPrice}</small>
                                                                                                    </FormGroup>
                                                                                                </li>}
                                                                                            <li>
                                                                                                <FormGroup id="PriceChangeThresholdGroup" controlId="formInlineName">
                                                                                                    <Checkbox id="rbPriceChange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.PriceChangeSelected} name="PriceChangeSelected"><div className="chk-box">{AlertsDialogTranslateHelper.DLY_PRICE_CHG_EXCEED} </div></Checkbox>
                                                                                                    <div className="input-text  manage-ms-pad">
                                                                                                        <input type="number" id="txtDailypricechangeexceeds" className="form-control disableDefaultSymbolInput" maxLength="3" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.props.alertCriteriaListSettings.PriceChangeThreshold} name="PriceChangeThreshold" /></div>
                                                                                                    <small id="PriceChangeThresholdErrorDiv" className={(this.state.validationMessages.PriceChangeThreshold ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.PriceChangeThreshold}</small>
                                                                                                    <div className="lbl-persent"><label>%</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="DailyVolumeRateexceedsGroup" controlId="formInlineName">
                                                                                                    <Checkbox id="rbDailyVolumeRate" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DailyVolumeRateSelected} name="DailyVolumeRateSelected"><div className="chk-box">{AlertsDialogTranslateHelper.DLY_VOLUME_RATE_EXCEED} </div></Checkbox>
                                                                                                    <div className="input-text"><input className="form-control disableDefaultSymbolInput" type="number" id="txtDailyVolumeRateexceeds" maxLength="3" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.props.alertCriteriaListSettings.DailyVolumeRateThreshold} name="DailyVolumeRateThreshold" /></div>
                                                                                                    <small id="DailyVolumeRateexceedsErrorDiv" className={(this.state.validationMessages.DailyVolumeRateThreshold ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.DailyVolumeRateThreshold}</small>
                                                                                                    <div className="lbl-persent"><label>%</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="DailyVolumeRateexceedsDelayGroup" className="fg-vol-rate-exceed" controlId="formInlineName">
                                                                                                    <Checkbox id="rbDailyVolumeRateDelay" disabled={!this.props.alertCriteriaListSettings.DailyVolumeRateSelected}
                                                                                                        onChange={this.handleCheckedChange}
                                                                                                        checked={this.props.alertCriteriaListSettings.DailyVolumeRateDelaySelected}
                                                                                                        name="DailyVolumeRateDelaySelected">
                                                                                                        <div className={this.props.alertCriteriaListSettings.DailyVolumeRateSelected ? "chk-box" : "chk-box disable "}>{AlertsDialogTranslateHelper.DELAY}</div>
                                                                                                    </Checkbox>
                                                                                                    <div className="lbl-persent">
                                                                                                        <label className={this.props.alertCriteriaListSettings.DailyVolumeRateSelected ? "" : "lbl-disable"}>{AlertsDialogTranslateHelper.MINUTE_FROM}</label>
                                                                                                    </div>
                                                                                                    <div className="input-text">
                                                                                                        {
                                                                                                            this.props.alertCriteriaListSettings.DailyVolumeRateSelected ?
                                                                                                                <input className="form-control disableDefaultSymbolInput"
                                                                                                                    type="number"
                                                                                                                    id="txtDailyVolumeRateexceedsDelay"
                                                                                                                    maxLength="3"
                                                                                                                    onChange={this.handleValueChange}
                                                                                                                    value={this.props.alertCriteriaListSettings.DailyVolumeRateDelayThreshold}
                                                                                                                    name="DailyVolumeRateDelayThreshold" />
                                                                                                                :
                                                                                                                <input className="form-control disableDefaultSymbolInput"
                                                                                                                    type="number"
                                                                                                                    id="txtDailyVolumeRateexceedsDelay"
                                                                                                                    maxLength="3"
                                                                                                                    onChange={this.handleValueChange}
                                                                                                                    readOnly="readOnly"
                                                                                                                    value={this.props.alertCriteriaListSettings.DailyVolumeRateDelayThreshold}
                                                                                                                    name="DailyVolumeRateDelayThreshold" />
                                                                                                        }
                                                                                                    </div>
                                                                                                    <small id="DailyVolumeRateexceedsDelayErrorDiv"
                                                                                                        className={(this.state.validationMessages.DailyVolumeRateDelayThreshold ? ' text-danger error-message-alert' : ' text-danger')}>
                                                                                                        {this.state.validationMessages.DailyVolumeRateDelayThreshold}
                                                                                                    </small>
                                                                                                    <div className="lbl-persent">
                                                                                                        <label className={this.props.alertCriteriaListSettings.DailyVolumeRateSelected ? "" : "lbl-disable"}>{AlertsDialogTranslateHelper.START_OF_TRADING_DAY}</label>
                                                                                                    </div>

                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="AverageDailyVolumecrossesGroup" className="" controlId="formInlineName ">
                                                                                                    <Checkbox id="rbAverageDlyVol" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.AverageDlyVolSelected} name="AverageDlyVolSelected"><div className="chk-box">{AlertsDialogTranslateHelper.AVG_DLY_VOLM_CROSSES} </div></Checkbox>
                                                                                                    <div className="input-text"><input className="form-control disableDefaultSymbolInput" type="number" id="txtAverageDailyVolumecrosses" maxLength="9" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.props.alertCriteriaListSettings.AverageDlyVolThreshold} name="AverageDlyVolThreshold" /></div>
                                                                                                    <small id="AverageDailyVolumecrossesErrorDiv" className={(this.state.validationMessages.AverageDlyVolThreshold ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.AverageDlyVolThreshold}</small>
                                                                                                    <div className="lbl-persent"><label>(000) {AlertsDialogTranslateHelper.SHARES}</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="AverageDailyDlrVolumecrossesGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbAverageDlyDlrVol" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.AverageDlyDlrVolSelected} name="AverageDlyDlrVolSelected"><div className="chk-box">{AlertsDialogTranslateHelper.AVG_DLY_USD_CROSSES} </div></Checkbox>
                                                                                                    <div className="input-text"><input className="form-control disableDefaultSymbolInput" type="number" id="txtAverageDailyDlrVolumecrosses" maxLength="9" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.props.alertCriteriaListSettings.AverageDlyDlrVolThreshold} name="AverageDlyDlrVolThreshold" /></div>
                                                                                                    <small id="AverageDailyDlrVolumecrossesErrorDiv" className={(this.state.validationMessages.AverageDlyDlrVolThreshold ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.AverageDlyDlrVolThreshold}</small>
                                                                                                    <div className="lbl-persent"><label>(000)</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="DailyVolumeExceeds50SMAGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbDailyVolumeExceeds50SMA" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DailyVolumeExceeds50SMASelected} name="DailyVolumeExceeds50SMASelected"><div className="chk-box">{AlertsDialogTranslateHelper.DLY_VOLM_EXCEED_50_SMA} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="BreakingOutToAllTimeHighGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbAverageDlyDlrVol" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.BreakingOutToAllTimeHighSelected} name="BreakingOutToAllTimeHighSelected"><div className="chk-box">{AlertsDialogTranslateHelper.BREAKING_OUT_ALL_TIME_HIGH} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="RSReachesNewHighGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbRSReachesNewHigh" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.RSReachesNewHighSelected} name="RSReachesNewHighSelected"><div className="chk-box">{AlertsDialogTranslateHelper.RS_LINE_NEW_52W_HIGH} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            {this.props.corporateEventAlertable === true ? <li>
                                                                                                <FormGroup id="RSGapUpOnEarnings" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbGapUpOnEarnings" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.GapUpOnEarningsSelected} name="GapUpOnEarningsSelected"><div className="chk-box">{AlertsDialogTranslateHelper.GAP_UP_EARNIGN_US} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> : null}
                                                                                            {this.props.corporateEventAlertable === true ? <li>
                                                                                                <FormGroup id="GapDownOnEarnings" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbGapDownOnEarnings" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.GapDownOnEarningsSelected} name="GapDownOnEarningsSelected"><div className="chk-box">{AlertsDialogTranslateHelper.GAP_DOWN_EARNING_US} </div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> : null}
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel>}
                                                                    <Panel defaultExpanded eventKey="3">
                                                                        <Panel.Heading>
                                                                            <Panel.Title toggle onClick={this.checkScrolling}>{AlertsDialogTranslateHelper.MOVING_AVG}</Panel.Title>
                                                                        </Panel.Heading>
                                                                        <Panel.Body collapsible>
                                                                            <Form inline>
                                                                                <div className="alert-panel-block">
                                                                                    <ul>
                                                                                        {this.props.sourceTypeId === AlertSourceType.List ?
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbMovingAvg50Sma" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.MovingAvg50SmaSelected} name="MovingAvg50SmaSelected"><div className="chk-box">{AlertsDialogTranslateHelper.PRICE_CROSSED_50_SMA_DLY}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> : null}
                                                                                        {this.props.sourceTypeId === AlertSourceType.List ?
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbMovingAvg200Sma" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.MovingAvg200SmaSelected} name="MovingAvg200SmaSelected"><div className="chk-box">{AlertsDialogTranslateHelper.PRICE_CROSSES_200_SMA_DLY}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> : null}
                                                                                        <li>
                                                                                            <FormGroup controlId="formInlineName">
                                                                                                <Checkbox id="rbMovingAvg50Cross200Sma" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.MovingAvg50Cross200Selected} name="MovingAvg50Cross200Selected"><div className="chk-box">{AlertsDialogTranslateHelper.SMA_50_200_CROSSOVER_DLY}</div></Checkbox>
                                                                                            </FormGroup>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </Form>
                                                                        </Panel.Body>
                                                                    </Panel>
                                                                    {(this.state.prEntitled === true && this.getAlertEntitledBySymbolType(this.state.symbolType).prEntitled) &&
                                                                        <Panel defaultExpanded eventKey="4">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{AlertsDialogTranslateHelper.PATTERN_REC}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <Table striped className="grids">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th className="w70">{AlertsDialogTranslateHelper.BASE}</th>
                                                                                                    <th className="w15">{AlertsDialogTranslateHelper.DIALY}</th>
                                                                                                    <th className="w15">{AlertsDialogTranslateHelper.WEEKLY}</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.NEW_BASE}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyBase" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DailyBaseChecked} name="DailyBaseChecked"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyBase" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.WeeklyBaseChecked} name="WeeklyBaseChecked"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.TIGHT_AREA_DETECTED}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyTight" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.WeeklyTightChecked} name="WeeklyTightChecked"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.FAILED_BREAKOUT}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyFailedBase" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.WeeklyFailedBaseChecked} name="WeeklyFailedBaseChecked"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {this.props.corporateEventAlertable === true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.VOLUME_50_DAY_BREAKE} <span title={"Stock must have broken out from base and established 50-day moving average. Alert triggers after breakout when stock price falls below 50-day moving average and daily volume rate is 50% or greater."}>{"(i)"}</span></div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyDay50BreakOnVolume" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.Day50BreakOnVolumeSelected} name="Day50BreakOnVolumeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.PIVOT}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup id="PricerisestowithinofpivotGroup" className="" controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.PRICE_RISES_TO_WITHIN} </div>
                                                                                                            <div className="input-text"><input type="number" className="form-control disableDefaultSymbolInput" id="txtPricerisestowithinofpivot" maxLength="3" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.props.alertCriteriaListSettings.AlertPriceRises} name="AlertPriceRises" /></div>
                                                                                                            <small id="PricerisestowithinofpivotErrorDiv" className={(this.state.validationMessages.AlertPriceRises ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.AlertPriceRises}</small>
                                                                                                            <div className="chk-box"> {AlertsDialogTranslateHelper.PERCENT_OF_PIVOT}</div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyPriceRises" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DailyPriceRisesSelected} name="DailyPriceRisesSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyPriceRises" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.WeeklyPriceRisesSelected} name="WeeklyPriceRisesSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.PRICE_CROSSOVER_PIVOT}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyPriceCrosses" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DailyPriceCrossesSelected} name="DailyPriceCrossesSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyPriceCrosses" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.WeeklyPriceCrossesSelected} name="WeeklyPriceCrossesSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.BREAKWAY_GAP}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbBreakawayGap" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.BreakawayGapSelected} name="BreakawayGapSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.PULL_BACK_10_WEEK}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbPullbackTo10WeekLine" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.PullbackTo10WeekLineSelected} name="PullbackTo10WeekLineSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                {this.props.sourceTypeId === AlertSourceType.Stock && this.state.prEntitled === true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.KEY_PRICE_RANGE}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {null}
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                                {this.props.sourceTypeId === AlertSourceType.Stock && this.state.prEntitled === true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.ENTER_EXIT_PROFIT_RANGE}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyProfitRange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DailyEnterExitProfitRangeSelected} name="DailyEnterExitProfitRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyProfitRange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.WeeklyEnterExitProfitRangeSelected} name="WeeklyEnterExitProfitRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                                {this.props.sourceTypeId === AlertSourceType.Stock && this.state.prEntitled === true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.ENTER_EXIT_PIVOT_RANGE}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyPivotRange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DailyEnterExitPivotRangeSelected} name="DailyEnterExitPivotRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyPivotRange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.WeeklyEnterExitPivotRangeSelected} name="WeeklyEnterExitPivotRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                                {this.props.sourceTypeId === AlertSourceType.Stock && this.state.prEntitled === true ? <tr>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <div className="chk-box">{AlertsDialogTranslateHelper.ENTER_EXIT_LOSS_RANGE}</div>
                                                                                                            <div className="input-text"></div>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbDailyLossRange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DailyEnterExitLossRangeSelected} name="DailyEnterExitLossRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <FormGroup controlId="formInlineName">
                                                                                                            <Checkbox id="rbWeeklyLossRange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.WeeklyEnterExitLossRangeSelected} name="WeeklyEnterExitLossRangeSelected"></Checkbox>
                                                                                                        </FormGroup>
                                                                                                    </td>
                                                                                                </tr> : null}
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel>}
                                                                    {this.props.sourceTypeId === AlertSourceType.List &&
                                                                        <Panel defaultExpanded eventKey="6">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{AlertsDialogTranslateHelper.NEGATIVE_ALERT_SCORE}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName" id="NegativeAlter1WeekGroup" className="" >
                                                                                                    <Checkbox id="rbNegativeAlter1Week" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.NegativeAlter1WeekSelected} name="NegativeAlter1WeekSelected"><div className="chk-box">{AlertsDialogTranslateHelper.NEGATIVE_ALERT_SOCRE_1_WEEK} >=</div></Checkbox>
                                                                                                    <div className="input-text"><input type="number" className="form-control disableDefaultSymbolInput" id="txtNegativeAlter1Week" maxLength="2" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.props.alertCriteriaListSettings.NegativeAlter1Week} name="NegativeAlter1Week" /></div>
                                                                                                    <small id="NegativeAlter1WeekErrorDiv" className={(this.state.validationMessages.NegativeAlter1Week ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.NegativeAlter1Week}</small>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName" id="NegativeAlter10WeekGroup" className="">
                                                                                                    <Checkbox id="rbNegativeAlter1Week" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.NegativeAlter10WeekSelected} name="NegativeAlter10WeekSelected"><div className="chk-box">{AlertsDialogTranslateHelper.NEGATIVE_ALERT_SOCRE_10_WEEK} >=</div></Checkbox>
                                                                                                    <div className="input-text"><input type="number" className="form-control disableDefaultSymbolInput" id="txtNegativeAlter10Week" maxLength="3" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.props.alertCriteriaListSettings.NegativeAlter10Week} name="NegativeAlter10Week" /></div>
                                                                                                    <small id="NegativeAlter10WeekErrorDiv" className={(this.state.validationMessages.NegativeAlter10Week ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.NegativeAlter10Week}</small>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel>}
                                                                    {(this.props.corporateEventAlertable === true && this.getAlertEntitledBySymbolType(this.state.symbolType).corporateEventAlertable) &&
                                                                        <Panel defaultExpanded eventKey="5">
                                                                            <Panel.Heading>
                                                                                <Panel.Title toggle onClick={this.checkScrolling}>{AlertsDialogTranslateHelper.COPORATE_EVENTS}</Panel.Title>
                                                                            </Panel.Heading>
                                                                            <Panel.Body collapsible>
                                                                                <Form inline>
                                                                                    <div className="alert-panel-block">
                                                                                        <ul>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbBuybackAnnouncement" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.BuybackAnnouncementSelected} name="BuybackAnnouncementSelected"><div className="chk-box">{AlertsDialogTranslateHelper.BUYBACK_ANNOUNCE}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbStockSplit" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.StockSplitSelected} name="StockSplitSelected"><div className="chk-box">{AlertsDialogTranslateHelper.STOCK_SPLIT}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbCeoChange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.CeoChangeSelected} name="CeoChangeSelected"><div className="chk-box">{AlertsDialogTranslateHelper.CEO_CHG}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup id="EarningsreleaseiswithinGroup" className="" controlId="formInlineName">
                                                                                                    <Checkbox id="rbEarningsRelease" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.EarningsReleaseSelected} name="EarningsReleaseSelected"><div className="chk-box"> {AlertsDialogTranslateHelper.EARNING_REALSE} </div></Checkbox>
                                                                                                    <div className="input-text"><input className="form-control disableDefaultSymbolInput" type="number" id="txtEarningsreleaseiswithin" maxLength="3" onKeyUp={this.handleKeyUp} onChange={this.handleValueChange} value={this.props.alertCriteriaListSettings.EarningsReleaseDays} name="EarningsReleaseDays" /></div>
                                                                                                    <small id="EarningsreleaseiswithinErrorDiv" className={(this.state.validationMessages.EarningsReleaseDays ? ' text-danger error-message-alert' : ' text-danger')}>{this.state.validationMessages.EarningsReleaseDays}</small>
                                                                                                    <div className="lbl-persent"><label>{AlertsDialogTranslateHelper.DAYS}</label></div>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbDividendChange" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.DividendChangeSelected} name="DividendChangeSelected"><div className="chk-box">{AlertsDialogTranslateHelper.DIVIDEND_CHANGE}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            {/* <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbInsiderPurchases" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.InsiderPurchasesSelected} name="InsiderPurchasesSelected"><div className="chk-box">{LocalizationStore.getTranslatedData("ch_msa_it", "Insider transactions")}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li> */}
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbInsiderTransactionsBuys" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.InsiderTransactionsBuysSelected} name="InsiderTransactionsBuysSelected"><div className="chk-box">{AlertsDialogTranslateHelper.INSIDER_BUY}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbInsiderTransactionsSells" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.InsiderTransactionsSellsSelected} name="InsiderTransactionsSellsSelected"><div className="chk-box">{AlertsDialogTranslateHelper.INSIDER_SELL}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                            <li>
                                                                                                <FormGroup controlId="formInlineName">
                                                                                                    <Checkbox id="rbAnnouncements" type="radio" onChange={this.handleCheckedChange} checked={this.props.alertCriteriaListSettings.AnnouncementsSelected} name="AnnouncementsSelected"><div className="chk-box">{AlertsDialogTranslateHelper.ANNOUNCEMENT}</div></Checkbox>
                                                                                                </FormGroup>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            </Panel.Body>
                                                                        </Panel>}
                                                                </div>
                                                            </PanelGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ScrollBar scrollId="alertDialogSetVerticalScroll" vScroll={true} />
                                        </div>
                                    </div>
                                </Modal.Body>

                                {this.props.conditionTypeId === AlertConditionType.Or && this.props.sourceTypeId === AlertSourceType.List && <FilterListDialog />}

                                {this.props.conditionTypeId === AlertConditionType.Or &&
                                    <Modal.Footer>
                                        <span className="anchor-link" onClick={this.onNotificationPreferenceClick}>{AlertsDialogTranslateHelper.ALERT_PREFERENCE} </span>
                                        <span className="pull-right btn-box">
                                            <button type="button" className="model-action btn btn-xs btn-default btn-secondary pull-left small-bold " data-effect="fadeOut" onClick={this.props.closeAlertDialog}>{TranslateHelper.Cancel}</button>
                                            <button type="button" id="btnSaveAlert" className="model-action btn btn-xs btn-default btn-secondary pull-left small-bold " data-effect="fadeOut" onClick={this.saveAlertData}>{TranslateHelper.Save}</button>
                                        </span>
                                    </Modal.Footer>}
                                {/* </Modal> */}
                            </FlexibleModal>
                        </section>
                    }
                    <NotificationContentContainer open={this.state.isNCOpen} hideModal={this.hideModal} showBackButton={false} />
                </section>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = ({ alertReducer,DatagraphReducers }) => {
    const { msId, instrumentId, symbol, sourceTypeId, alertId, isOpenAlertCriteria, showLoader, priceAlert, alertCriteriaListSettings, priceVolumeAlertable, addRemoveAlertable, corporateEventAlertable,
        listAlertSubTitle, browserFilterText, selectedColSetString, existAlerts, conditionTypeId, instrumentType, alertTitle, TargetPriceSelected, TargetPrice, listName } = alertReducer.AlertDialogReducer;
    const { SymbolInfo } = DatagraphReducers.DataGraphReducer;
    return {
        msId, instrumentId, symbol, sourceTypeId, alertId, isOpenAlertCriteria, showLoader, priceAlert, alertCriteriaListSettings, priceVolumeAlertable, addRemoveAlertable, corporateEventAlertable,
        listAlertSubTitle, browserFilterText, selectedColSetString, existAlerts, conditionTypeId, instrumentType, alertTitle, TargetPriceSelected, TargetPrice, listName,SymbolInfo
    }
}

const mapDispatchToProps = (dispatch) => ({
    closeAlertDialog: () => dispatch(closeAlertDialog()),
    saveCriteriaDialog: (listAlertSettings, priceAlert) => dispatch(saveCriteriaDialog(listAlertSettings, priceAlert)),
    cancelCriteriaDialog: () => dispatch(cancelCriteriaDialog()),
    saveAlertData: (listAlertSettings, conditionTypeId, alertId, priceAlertObj, isTriggered) => dispatch(saveAlertData(listAlertSettings, conditionTypeId, alertId, priceAlertObj, isTriggered))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrAlertsDialog)