import Scale from "./Scale.js";
import GraphType from "GraphType";
import ExtremeDataValue from "ExtremeDataValue";

var PrScl0 = [];
var PrScl1 = [];
var PrScl2 = [];

class LogScale extends Scale {

    getObjectMapKey() {
        return "LogScale";
    }
    ComputePrice(y) {
        var dGraphDistance = this.height - y;

        var dPriceDiffFromBottom = dGraphDistance / this.Factor;
        dPriceDiffFromBottom += this.log10(this.Lowpt);

        return Math.pow(10, dPriceDiffFromBottom);
    }
    ComputeY(dPrice) {
        var dPriceDiffFromBottom;

        if (dPrice <= 0) {
            // LogInfo of a negative or zero value would be bad...
            // dPriceDiffFromBottom = 0;
            return this.height + 100;
        }
        else {
            dPriceDiffFromBottom = this.log10(dPrice) - this.log10(this.Lowpt);
        }

        return this.height - (dPriceDiffFromBottom * this.Factor);
//        return Math.round(this.height - (dPriceDiffFromBottom * this.Factor), 2);
    }
    ComputeYL(dPrice, dLow) {
        var dPriceDiffFromBottom;

        if (dPrice <= 0) {
            // LogInfo of a negative or zero value would be bad...
            // dPriceDiffFromBottom = 0;
            return this.height + 100;
        }
        else {
            dPriceDiffFromBottom = this.log10(dPrice) - this.log10(dLow);
        }

        return this.height - (dPriceDiffFromBottom * this.Factor);
//        return Math.round(this.height - (dPriceDiffFromBottom * this.Factor), 2);
    }
    InitScale(MinPrice, MaxPrice, height, graphType, scaleType, dpi, symbolType, epsMultiplier, rpsMultiplier, t4QMultipliers, externalDataMultiplier) {
        this.GraphType = graphType;
        this.height = height;
        this.mhLines = [];
        if (this.GraphType == GraphType.Weekly ||
            this.GraphType == GraphType.Monthly ||
            this.GraphType == GraphType.Quarterly ||
            this.GraphType == GraphType.Annual) {
            this.InitMScale(MinPrice, MaxPrice, height, graphType, scaleType, dpi, symbolType);
        }
        else {
            this.InitIScale(MinPrice, MaxPrice, height, graphType, scaleType, dpi, symbolType);
        }
        let eok = isNaN(epsMultiplier) ? 0 : 1;
        let rok = isNaN(rpsMultiplier) || rpsMultiplier < 0 ? 0 : 1;
        this.SetHorizontalGrid(epsMultiplier, rpsMultiplier, 1, eok, rok, t4QMultipliers, externalDataMultiplier, dpi);
    }
    SetHorizontalGrid(epsMultiplier, rpsMultiplier, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi) {
        var height = this.height;
        if (this.GraphType == GraphType.Weekly ||
            this.GraphType == GraphType.Monthly ||
            this.GraphType == GraphType.Quarterly ||
            this.GraphType == GraphType.Annual) {
            this.SetHorizontalMGrid(epsMultiplier, rpsMultiplier, height, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi);
        }
        else {
            this.SetHorizontalIGrid(epsMultiplier, rpsMultiplier, height, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi);
        }
    }
    //InitMScale(MinPrice, MaxPrice, height, graphType, scaleType, dpi, symbolType)
    InitMScale(MinPrice, MaxPrice, height) {
        var MaxScales = 40;
        var i;
        //var Mnodes = 1600;

        this.Factor = 0;
        this.YLowpt = 0;

        this.minPrice = MinPrice;
        this.maxPrice = MaxPrice;

        var sp = [];
        // var pt100 = 0;
        //var pt50 = 0;
        var k = 0;
        var x = 10;
        var xl;
        var j;

        this.Hipt = this.maxPrice;
        this.Lowpt = this.minPrice;

        // Test the conversion Factor.

        var prDiff = this.log10(this.Hipt) - this.log10(this.Lowpt);

        if (prDiff < 0.1) {
            prDiff = 0.1;
        }

        this.Factor = height / prDiff;

        var yPr1 = this.ComputePrice(-60, height);
        var yPr2 = this.ComputePrice(height + 60, height);

        this.Hipt = yPr1;// Hipt + (yPr1 - Hipt);
        this.Lowpt = yPr2;// Lowpt - (Lowpt - yPr2);

        // Calculate the factor with top and bottom margins
        this.Factor = height / (this.log10(this.Hipt) - this.log10(this.Lowpt));

        sp[0] = x;
        for (i = 1; i <= MaxScales; i++) {
            if (x < 20)
                x = x + 1;
            else
                if (x < 50)
                    x = x + 2;
                else
                    if (x < 100)
                        x = x + 5;
            sp[i] = x;
        }
        for (i = 1; i < MaxScales; i++) {
            xl = Math.pow(10.0, (i - 5));
            for (j = 0; j <= MaxScales; j++) {
                PrScl0[k] = xl * sp[j];
                PrScl1[k] = this.log10(PrScl0[k]);
                PrScl2[k] = 1;
                k++;
            }
        }
    }
    //InitIScale(MinPrice, MaxPrice, height, graphType, scaleType, dpi, symbolType)
    InitIScale(MinPrice, MaxPrice, height) {
        var iNumberOfIncrements = 10;
        this.minPrice = MinPrice;
        this.maxPrice = MaxPrice;

        if (this.maxPrice == this.minPrice) {
            this.maxPrice = this.maxPrice + this.maxPrice / 2;
            this.minPrice = this.minPrice - this.minPrice / 2;
        }

        var prInc = (this.maxPrice - this.minPrice) / iNumberOfIncrements;
        //var margin = prInc;

        if (prInc < 1 && prInc > 0.49)
            prInc = 0.50;
        else
            if (prInc < 0.50 && prInc > 0.24)
                prInc = 0.25;
            else
                if (prInc < 0.25 && prInc > 0.09)
                    prInc = 0.10;
                else
                    if (prInc < 0.1 && prInc > 0.009)
                        prInc = 0.05;
                    else
                        if (prInc < 0.01)
                            prInc = 0.005;
                        else
                            prInc = prInc - prInc % Math.pow(10, Math.floor(this.log10(prInc), 0));

        var hiprc = this.maxPrice;
        var lowprc = this.minPrice;

        this.Hipt = hiprc;
        this.Lowpt = lowprc;

        // Test the conversion Factor.
        this.Factor = height / (this.log10(this.Hipt) - this.log10(this.Lowpt));

        var yPr1 = this.ComputePrice(-60, height);
        var yPr2 = this.ComputePrice(height + 60, height);

        this.Hipt = yPr1;// Hipt + (yPr1 - Hipt);
        this.Lowpt = yPr2;// Lowpt - (Lowpt - yPr2);

        // Calculate the factor with top and bottom margins
        this.Factor = height / (this.log10(this.Hipt) - this.log10(this.Lowpt));

        this.Hipt = Math.round((hiprc + prInc) / prInc) * prInc;
        this.mlPriceIncrement = prInc;
    }
    SetLowpt(lowprc) {
        var I1 = 0;
        var Dlr = 0;
        var lowpt = Math.floor(lowprc * 1000);
        var dlr = Math.floor(Dlr * 1000);
        var i1 = Math.floor(I1 * 1000);

        if ((lowpt + dlr) < lowprc * 1000)
            lowpt += dlr;

        var tmp = Math.floor(lowpt / dlr) * dlr;

        if (tmp <= 0)
            tmp = -i1;

        if (tmp == lowpt)// && tmp != 0)
            lowpt = tmp - dlr;
        else
            if (lowpt < tmp)
                lowpt = tmp - dlr;
            else
                lowpt = tmp;

        return lowpt / 1000;
    }
    SetHipt(hiprc) {
        var Dlr = 0;
        var hipt = Math.floor(hiprc * 1000);// +MLPriceIncrement/ 6;
        var dlr = Math.floor(Dlr * 1000);

        hipt = Math.floor((hipt / dlr) + 1) * dlr;

        return hipt / 1000;
    }
    SetHorizontalMGrid(epsMultiplier, rpsMultiplier, height, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi) {
        var minpr = this.minPrice;
        var maxpr = this.maxPrice;
        var Mnodes = PrScl1.length;

        var fMinpr = minpr < 1 ? 0 : this.log10(minpr);

        var i = 0;
        for (; i < Mnodes - 1 && PrScl1[i] < fMinpr; i++);

        var mLowpt = Math.max(i, 1);

        var mlLowpt = PrScl1[mLowpt] - fMinpr;
        this.YLowpt = (mlLowpt * this.Factor);

        // Move graph to the Center

        var mHipt = this.ComputeY(maxpr, height);
        var mMnpt = this.ComputeY(minpr, height);

        if (mHipt > 40) {
            var mid = (mMnpt + mHipt) / 2;
            var gmid = height / 2;
            var dif = gmid - mid;
            this.YLowpt -= Math.abs(dif);
        }
        else
            this.YLowpt = 0;

        this.SetLogGrid(epsMultiplier, rpsMultiplier, height, this.minPrice, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi);
    }
    SetLogGrid(epsMultiplier, rpsMultiplier, height, minPrc, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi) {
        var minPrice = this.ComputePrice(height - 10, height);
        var maxPrice = this.ComputePrice(10, height);

        if (height < 200) {
            minPrice = this.ComputePrice(height, height);
            maxPrice = this.ComputePrice(1, height);
        }

        var minPoint = 0;
        var maxPoint = 0;

        for (; minPoint < PrScl0.length && PrScl0[minPoint] < minPrice; minPoint++);
        for (; maxPoint < PrScl0.length && PrScl0[maxPoint] < maxPrice; maxPoint++);

        var pricePtr = minPoint;

        if (pricePtr >= PrScl0.length)
            return;

        var py = this.ComputeY(PrScl0[pricePtr], height);
        pricePtr++;

        var y;
        var eLine;

        if (pricePtr == maxPoint) {
            y = this.ComputeY(PrScl0[pricePtr], height);
            //    if (!this.mhLines.ContainsKey(y)) {
            eLine = {
                Type: 2,
                Label: " ",
                eLabel: " ",
                rLabel: " ",
                YAxis: y
            };
            this.mhLines.push(eLine);
            //            }
        }
        let prevE;
        let prevR;
        let plabel = "";
        let ptlabel = "";
        let pt1label = "";
        let pt2label = "";
        let pt3label = "";
        let pextLabel = "";
        if(!isFinite(maxPoint)) return;
        for (; pricePtr < maxPoint; pricePtr++) {
            if (PrScl2[pricePtr] != 1) {
                // No line at this point.
                continue;
            }
            let nodeWidth = dpi / 28;
            let dist = nodeWidth > 12 ? nodeWidth * 2 : 10;
            y = this.ComputeY(PrScl0[pricePtr], height);
            if (Math.abs(py - y) < dist || y < 30 || y + 10 > height)
                continue;

            var price = " ";
            var eprice = " ";
            var rprice = " ";
            var t0price = " ";
            var t1price = " ";
            var t2price = " ";
            var t3price = " ";
            var exdprice = " ";

            if (pok == 1) {
                price = PrScl0[pricePtr];
                if (price < 6)
                    price = PrScl0[pricePtr].toFixed(1);
                if (price < 0.6)
                    price = PrScl0[pricePtr].toFixed(2);
                if (price < 0.06)
                    price = PrScl0[pricePtr].toFixed(3);
            }
            if (eok === 1) {
                eprice = PrScl0[pricePtr] / epsMultiplier;
                if (eprice > 0.1)
                    eprice = (PrScl0[pricePtr] / epsMultiplier).toFixed(2);
                else
                    eprice = (PrScl0[pricePtr] / epsMultiplier).toFixed(3);
            }
            if (rok === 1) {
                rprice = PrScl0[pricePtr] / rpsMultiplier;
                if (rprice > 0.1)
                    rprice = (PrScl0[pricePtr] / rpsMultiplier).toFixed(2);
                else
                    rprice = (PrScl0[pricePtr] / rpsMultiplier).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[0]) {
                t0price = PrScl0[pricePtr] / t4QMultipliers[0];
                if (t0price > 0.1)
                    t0price = (PrScl0[pricePtr] / t4QMultipliers[0]).toFixed(2);
                else
                    t0price = (PrScl0[pricePtr] / t4QMultipliers[0]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[1]) {
                t1price = PrScl0[pricePtr] / t4QMultipliers[1];
                if (t1price > 0.1)
                    t1price = (PrScl0[pricePtr] / t4QMultipliers[1]).toFixed(2);
                else
                    t1price = (PrScl0[pricePtr] / t4QMultipliers[1]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[2]) {
                t2price = PrScl0[pricePtr] / t4QMultipliers[2];
                if (t2price > 0.1)
                    t2price = (PrScl0[pricePtr] / t4QMultipliers[2]).toFixed(2);
                else
                    t2price = (PrScl0[pricePtr] / t4QMultipliers[2]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[3]) {
                t3price = PrScl0[pricePtr] / t4QMultipliers[3];
                if (t3price > 0.1)
                    t3price = (PrScl0[pricePtr] / t4QMultipliers[3]).toFixed(2);
                else
                    t3price = (PrScl0[pricePtr] / t4QMultipliers[3]).toFixed(3);
            }

            if (externalDataMultiplier) {
                exdprice = PrScl0[pricePtr] / externalDataMultiplier;
                if (exdprice > 0.1)
                  exdprice = (PrScl0[pricePtr] / externalDataMultiplier).toFixed(2);
                else
                  exdprice = (PrScl0[pricePtr] / externalDataMultiplier).toFixed(3);
            }

            let label = price > 0 ? ExtremeDataValue.showChartPrices(price) : "";
            let eLabel = ExtremeDataValue.abbreviateFinancialValue(eprice, 1);
            let rLabel = ExtremeDataValue.abbreviateFinancialValue(rprice, 1);
            let tlabel = ExtremeDataValue.abbreviateFinancialValue(t0price, 1);
            let t1label = ExtremeDataValue.abbreviateFinancialValue(t1price, 1);
            let t2label = ExtremeDataValue.abbreviateFinancialValue(t2price, 1);
            let t3label = ExtremeDataValue.abbreviateFinancialValue(t3price, 1);
            let exdLabel = ExtremeDataValue.abbreviateFinancialValue(Math.abs(exdprice), 1);
            eLine =
                {
                    Type: 2,
                    Label: label !== plabel ? label : "",
                    eLabel: eLabel !== prevE ? eLabel : '',
                    rLabel: rLabel !== prevR ? rLabel : '',
                    tLabel: tlabel !== ptlabel ? tlabel : "",
                    t1Label: t1label !== pt1label ? t1label : "",
                    t2Label: t2label !== pt2label ? t2label : "",
                    t3Label: t3label !== pt3label ? t3label : "",
                    exdLabel: exdLabel !== pextLabel ? exdLabel : "" ,
                    YAxis: y
                };
            plabel = label;
            prevE = eLabel;
            prevR = rLabel;
            ptlabel = tlabel;
            pt1label = t1label;
            pt2label = t2label;
            pt3label = t3label;
            this.mhLines.push(eLine);

            py = y;
        }
    }
    SetHorizontalIGrid(epsMultiplier, rpsMultiplier, height, pok, eok, rok, t4QMultipliers, externalDataMultiplier, dpi) {
        if(!isFinite(this.Hipt) || !isFinite(this.Lowpt)) return; 
        let py = 0;
        let eLine;
        let prevE;
        let prevR;
        let plabel = "";
        let ptlabel = "";
        let pt1label = "";
        let pt2label = "";
        let pt3label = "";
        let pextLabel = "";
        for (let pricePtr = this.Hipt; pricePtr > this.Lowpt; pricePtr -= this.mlPriceIncrement) {
            const y = this.ComputeY(pricePtr, height);
            let nodeWidth = dpi / 28;
            let dist = nodeWidth > 12 ? nodeWidth * 2 : 10;
            if (Math.abs(py - y) < dist || y < 25 || y + 10 > height)
                continue;

            let price = " ";
            let eprice = " ";
            let rprice = " ";
            var t0price = " ";
            var t1price = " ";
            var t2price = " ";
            var t3price = " ";
            var exdprice = " ";

            if (pok === 1) {
                price = pricePtr.toFixed(0);
                if (this.mlPriceIncrement < 1)
                    price = pricePtr.toFixed(1);
                if (this.mlPriceIncrement < 0.1)
                    price = pricePtr.toFixed(2);
                if (this.mlPriceIncrement < 0.01)
                    price = pricePtr.toFixed(3);
            }
            if (eok === 1) {
                eprice = (pricePtr / epsMultiplier).toFixed(0);
                if (eprice > 0.1)
                    eprice = (pricePtr / epsMultiplier).toFixed(2);
                else
                    eprice = (pricePtr / epsMultiplier).toFixed(3);
            }
            if (rok === 1) {
                rprice = (pricePtr / rpsMultiplier).toFixed(0);
                if (rprice > 0.1)
                    rprice = (pricePtr / rpsMultiplier).toFixed(2);
                else
                    rprice = (pricePtr / rpsMultiplier).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[0]) {
                t0price = pricePtr / t4QMultipliers[0];
                if (t0price > 0.1)
                    t0price = (pricePtr / t4QMultipliers[0]).toFixed(2);
                else
                    t0price = (pricePtr / t4QMultipliers[0]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[1]) {
                t1price = pricePtr / t4QMultipliers[1];
                if (t1price > 0.1)
                    t1price = (pricePtr / t4QMultipliers[1]).toFixed(2);
                else
                    t1price = (pricePtr / t4QMultipliers[1]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[2]) {
                t2price = pricePtr / t4QMultipliers[2];
                if (t2price > 0.1)
                    t2price = (pricePtr / t4QMultipliers[2]).toFixed(2);
                else
                    t2price = (pricePtr / t4QMultipliers[2]).toFixed(3);
            }
            if (t4QMultipliers && t4QMultipliers[3]) {
                t3price = pricePtr / t4QMultipliers[3];
                if (t3price > 0.1)
                    t3price = (pricePtr / t4QMultipliers[3]).toFixed(2);
                else
                    t3price = (pricePtr / t4QMultipliers[3]).toFixed(3);
            }
            if (externalDataMultiplier) {
                exdprice = pricePtr / externalDataMultiplier;
                if (exdprice > 0.1)
                    exdprice = (pricePtr / externalDataMultiplier).toFixed(2);
                else
                    exdprice = (pricePtr / externalDataMultiplier).toFixed(3);
            }
           
            let label = price > 0 ? ExtremeDataValue.showChartPrices(price) : "";
            let eLabel = eok === 1 ? ExtremeDataValue.abbreviateFinancialValue(eprice, 1) : eprice;
            let rLabel = rok === 1 ? ExtremeDataValue.abbreviateFinancialValue(rprice, 1) : rprice;
            let tlabel = (t4QMultipliers && t4QMultipliers[0]) ? ExtremeDataValue.abbreviateFinancialValue(t0price, 1) : t0price;
            let t1label = (t4QMultipliers && t4QMultipliers[1]) ? ExtremeDataValue.abbreviateFinancialValue(t1price, 1) : t1price;
            let t2label = (t4QMultipliers && t4QMultipliers[2]) ? ExtremeDataValue.abbreviateFinancialValue(t2price, 1) : t2price;
            let t3label = (t4QMultipliers && t4QMultipliers[3]) ? ExtremeDataValue.abbreviateFinancialValue(t3price, 1) : t3price;
            let exdLabel = ExtremeDataValue.abbreviateFinancialValue(Math.abs(exdprice), 1);

            eLine =
                {
                    Type: 2,
                    Label: label !== plabel ? label : "",
                    eLabel: eLabel !== prevE ? eLabel : '',
                    rLabel: rLabel !== prevR ? rLabel : '',
                    tLabel: tlabel !== ptlabel ? tlabel : "",
                    t1Label: t1label !== pt1label ? t1label : "",
                    t2Label: t2label !== pt2label ? t2label : "",
                    t3Label: t3label !== pt3label ? t3label : "",
                    exdLabel: exdLabel !== pextLabel ? exdLabel : "" ,
                    YAxis: y
                };
            plabel = label;
            prevE = eLabel;
            prevR = rLabel;
            ptlabel = tlabel;
            pt1label = t1label;
            pt2label = t2label;
            pt3label = t3label;
            pextLabel = exdLabel;

            this.mhLines.push(eLine);

            py = y;
        }
    }
}
export default LogScale;
