import React, { Component } from 'react';
import { Modal, Table, Collapse } from "react-bootstrap";
import { map } from "underscore";
import { connect } from 'react-redux';
import { handleModalHide, hideColorPalette, updateActiveItem, toggleAdvancedSettings } from '../../Actions/ExternalDataUploaderActions';
import ColorPaletteDialog from './ColorPaletteDialog.jsx';
import ThemeHelper from "ThemeHelper";
import SettingsStore from "SettingsStore";
import { PeriodicityTabsConstants } from "../../Constants/PeriodicityTabsConstants.js";
import ScrollBar from "ScrollBar";

class ExternalDataEditDialog extends Component {

    componentDidUpdate = () => {
        if (this.props.externalData.isShowModal) {
            document.body.classList.add('ExternalDataEditDialogOpened');
            document.addEventListener("keydown", this.onDocumentKeyPress);
        }
        if(!this.props.externalData.shouldHoldOnUserEvents) {
            document.body.style.cursor = "default";
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.onDocumentKeyPress);
        document.body.classList.remove('ExternalDataEditDialogOpened');
    }

    onDocumentKeyPress = (e) => {
        if (document.body.classList.contains('ExternalDataEditDialogOpened')) {
            const charCode = (e.which) ? e.which : e.keyCode;
            if (charCode === 13) {
                this.handleOnSave();
                if (this.props.externalData.isShowColorPalette) {
                    this.hideColorPalette(false, this.props.externalData.activeItem);
                }
            }
        }
    }

    handleOnCancel = () => {
        this.props.toggleAdvancedSettings(false);
        this.props.handleModalHide(false, null, null, null);
        document.body.classList.remove('ExternalDataEditDialogOpened');
    }

    handleOnSave = () => {
        const { editDialogMenu, selectedMenu, editDialogAdvancedMenu } = this.props.externalData;
        document.body.style.cursor = "progress";
        this.props.toggleAdvancedSettings(false);
        this.props.handleModalHide(true, editDialogMenu, selectedMenu.listId, editDialogAdvancedMenu, true);
        document.body.classList.remove('ExternalDataEditDialogOpened');
    }

    hideColorPalette = (isShowColorPalette, activeItem = {}) => {
        this.props.hideColorPalette(isShowColorPalette);
        this.props.updateActiveItem(activeItem);
    }

    handleColorChange = (color) => {
        this.props.externalData.activeItem.Color = color;
        this.props.updateActiveItem(this.props.externalData.activeItem);
        this.hideColorPalette(false, this.props.externalData.activeItem);
    }

    selectValue = (e) => {
        this.saveButton.autofocus = false;
        e.target.focus = true;
        e.target.select();
    }

    handleChangeLabel = (e, item) => {
        item.Label = e.target.value;
    }

    handlePeriodicityVisibility = (e, item) => {
        item.IsVisible = e.currentTarget.checked;
    }

    handleLabelKeyPress = (e, item) => {
        let code = e.keyCode || e.which;
        if (code === 13) {
            item.Label = e.target.value;
            e.target.focus = false;
        }
    }

    handleAdvancedSettings = (e, item) => {
        item.IsVisible = e.target.checked;
    }

    toggleAdvancedSettings = () => {
        const { isShowAdvanceSetting } = this.props.externalData;
        this.props.toggleAdvancedSettings(!isShowAdvanceSetting);
    }
    renderAdvancedSettings = (item, esField) => {
        return (
            <tr key={esField}>
                <td>
                    <span className="check-box">
                        <input id={esField} type="checkbox" ref={(ref) => this.ads = ref} defaultChecked={item.IsVisible} onChange={(e) => this.handleAdvancedSettings(e, item)} />
                        <label></label>
                    </span>
                </td>
                <td className="xx-small-bold">{item.displayName}</td>
            </tr>
        )
    }

    renderMenu = (item, index, isDateBasedEvents,isReadOnly) => {
        const backgroundColor = ThemeHelper.getThemedBrush(item.Color);
        return (
            <tr key={index}>
                <td className="ext-set-td-width">{item.Name}</td>
                {isDateBasedEvents ? <td className="labeled-input">
                    {!isReadOnly ? <input type="text" ref={(ref) => this.label = ref} className="define-set" title={item.Label} maxLength="3" defaultValue={item.Label}
                        onChange={(e) => this.handleChangeLabel(e, item)} onKeyPress={(e) => this.handleLabelKeyPress(e, item)} onClick={(e) => this.selectValue(e)} />
                        : item.Label
                    }
                </td> : ''}
                <td style={{ backgroundColor: backgroundColor, borderTop: "1px solid " + backgroundColor }} onClick={() => this.hideColorPalette(true, item)}></td>
                {this.renderPeriodcity(index)}
            </tr>
        )
    }

    renderPeriodcity = (esField) => {
        const { editDialogMenu } = this.props.externalData;
        const periodicities = ["Daily", "Weekly", "Monthly", "Quarterly", "Annual"];
        const row = [];

        periodicities.map((periodicity) => {
            map(editDialogMenu[periodicity].children, (item, key) => {
                if (key === esField) {
                    const cell = <td key={`${periodicity}-${key}`}><span className="check-box"><input type="checkbox" defaultChecked={item.IsVisible} onChange={(e) => this.handlePeriodicityVisibility(e, item)} className="" /><label></label></span></td>
                    row.push(cell);
                }
            })
        })
        return row;

    }

    render() {
        const { isShowModal, selectedMenu, isShowColorPalette, activeItem, isShowAdvanceSetting, editDialogMenu, settings, editDialogAdvancedMenu, shouldHoldOnUserEvents } = this.props.externalData;
        const title = selectedMenu && selectedMenu.header;
        const tabDataGraphSettings = SettingsStore.getConsoleSettings().NavDatagraphSettings.TabDataGraphSettings;
        const majorPeriodicity = tabDataGraphSettings.Periodicity;
        const editMenu = editDialogMenu && editDialogMenu[majorPeriodicity] && editDialogMenu[majorPeriodicity].children;

        if (isShowModal) {
            const externalListSettings = settings.ExternalDataSettings[selectedMenu.listId][majorPeriodicity];
            selectedMenu.isDateBasedEvents = externalListSettings.isDateBasedEvents;
            const firstColumnHeader = externalListSettings.isDateBasedEvents ? "Category" : "Metric";
            const cursor = shouldHoldOnUserEvents ? "progress" : "default";
            const pointerEvents = shouldHoldOnUserEvents ? "none" : "all";
            const openDialogClass = isShowModal ? "dialog-open" : "";
            return (
                <div id="EditFidelityScore">
                    <ColorPaletteDialog activeItem={activeItem} color={activeItem.Color} show={isShowColorPalette} handleColorChange={this.handleColorChange} onHide={this.hideColorPalette} isShowCloseButtons={false} />
                    <Modal style = {{ cursor: cursor, pointerEvents: pointerEvents }} className={`modal-popup ti-indicators ext-dialog external-ti-indicator-settings ${openDialogClass}`} show={isShowModal}>
                        <Modal.Header>
                            <Modal.Title>
                                <span className="h6">Edit <span className="naming">{title}</span> (External Data)</span>
                            </Modal.Title>
                        </Modal.Header>
                        {shouldHoldOnUserEvents && <div className="loading loading-spinner"></div>}
                        <Modal.Body>
                            <div className="modal-region ext-ti-indicator settings inner-block edit-fidelity-score-data-dialog-wrap">
                                <div className="ext-ti-indicator-setting">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th className="ext-set-th-width">{firstColumnHeader}</th>
                                                {externalListSettings.isDateBasedEvents ? <th className="labeled-input">Label</th> : ''}
                                                <th>Color</th>
                                                <th colSpan="5">Display</th>
                                            </tr>
                                            <tr>
                                                <th className="ext-set-period-width"></th>
                                                {externalListSettings.isDateBasedEvents ? <th className="labeled-input"></th> : ''}
                                                <th></th>
                                                <th>{PeriodicityTabsConstants.Daily}</th>
                                                <th>{PeriodicityTabsConstants.Weekly}</th>
                                                <th>{PeriodicityTabsConstants.Monthly}</th>
                                                <th>{PeriodicityTabsConstants.Quarterly}</th>
                                                <th>{PeriodicityTabsConstants.Annual}</th>
                                            </tr>
                                        </thead>
                                    </Table>

                                    <div className="custom-scroll-light">
                                        <div className="custom-scroll">
                                            <div id='scrollWrapperEditTableVerticalScroll'>
                                                <Table>
                                                    <tbody>
                                                        {editMenu && map(editMenu, (item, key) => this.renderMenu(item, key, externalListSettings.isDateBasedEvents,selectedMenu.isReadOnly))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                        <ScrollBar scrollId='scrollWrapperEditTableVerticalScroll' vScroll={true} scrollOnHover={false} />
                                    </div>

                                </div>
                                {externalListSettings.isDateBasedEvents ? <div className="assign-note">You may assign a 1-3 letter abbreviation that will identify the date <br />on the chart.</div> : ''}
                                {externalListSettings.isDateBasedEvents ?
                                    <div className="ext-advance-setting show-hide-block">
                                        <div className="anchor-link"><a onClick={this.toggleAdvancedSettings}>{isShowAdvanceSetting ? 'Hide' : 'Show'} Advanced Settings</a></div>
                                        <Collapse in={isShowAdvanceSetting}>
                                            <div>
                                                <div className="ext-advance-content adv-setting-wrap">
                                                    <span className="sub-text">Expanded Event Badge</span>
                                                    <p className="para">Select data item that will be revealed in the <br />expanded event badge</p>
                                                    <div className="exp-event-badge">
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>INCLUDE</th>
                                                                    <th>DATA COLUMN</th>
                                                                </tr>
                                                            </thead>
                                                        </Table>
                                                        <div className="custom-scroll-light">
                                                            <div className="custom-scroll">
                                                                <div id='scrollWrapperEditTableVerticalScroll2'>
                                                                    <Table>
                                                                        <tbody>
                                                                            {editDialogAdvancedMenu && map(editDialogAdvancedMenu, (item, esField) => this.renderAdvancedSettings(item, esField))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </div>
                                                            <ScrollBar scrollId='scrollWrapperEditTableVerticalScroll2' vScroll={true} scrollOnHover={false} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Collapse>
                                    </div>
                                    :
                                    ''
                                }
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <div className="btn-box righter">
                                <button type="button" className="btn btn-xs btn-secondary" data-effect="fadeOut" onClick={this.handleOnCancel}>Cancel</button>
                                <button type="button" id="saveButton" className="btn btn-xs btn-secondary" data-effect="fadeOut" onClick={this.handleOnSave} ref={(ref) => this.saveButton = ref} >Save</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        externalData: state.externalDataUploader.externalDataUploadSymbolReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleModalHide: (isSaveObject, editDialogMenu, listId, editDialogAdvancedMenu, shouldHoldOnUserEvents) => dispatch(handleModalHide(isSaveObject, editDialogMenu, listId, editDialogAdvancedMenu, shouldHoldOnUserEvents)),
        hideColorPalette: (isShowColorPalette) => dispatch(hideColorPalette(isShowColorPalette)),
        updateActiveItem: (activeItem) => dispatch(updateActiveItem(activeItem)),
        toggleAdvancedSettings: (isShowAdvanceSetting) => dispatch(toggleAdvancedSettings(isShowAdvanceSetting))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataEditDialog);