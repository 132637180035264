import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import StringUtil from "StringUtil";

export default class UpdateNotificationSubscribeRequest extends BaseServiceRequest {
  /*eslint-disable */
  constructor(endpoint, alertType, methodType, validate) {
    super();
    this._methodType = !StringUtil.isEmpty(methodType) ? methodType : HttpMethodType.PUT;
    super.addRequestUri("subscription.pb");
    super.addRequestParameter("s", endpoint.toString());
    super.addRequestParameter("aid", alertType);
  }
  /*eslint-enable */

  getMethod() {
    return this._methodType;
  }

  getSubSystem() {
    return SubSystemType.NOTIFICATION;
  }

  getMessageName() {
    return "Response";
  }

}
