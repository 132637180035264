import React from "react";
import { Modal, FormGroup, Form } from "react-bootstrap";
import { updateStorewithSharedData } from "../../../Actions/ShareAction";
// import { ShareActionConstants, ShareAccessType } from "../../../Constants/ShareConstants";
// import SortHeader from "./SortHeader.jsx";
// import manageData from "./ManagedData.jsx";
import LocalizationStore from 'LocalizationStore';
import ManagedData from "./ManagedData.jsx";
import TabBar from "./TabBar.jsx";
const ManageDatapage = ({ nodeSharedData, sharedAction, sort, sortReversed, showInProgress}) => {
    const updateStorewithData = () => {
        const nodeData = {
            showSharedDialog: false,
            id: undefined,
            name: undefined,
            type: undefined,
            action: sharedAction
        }
        updateStorewithSharedData(nodeData);
    }
    return (
        <div id="manageDataPage" className="share-modals" data-test-id="main-div">
            <Modal.Header className="enable-drag">
                <Modal.Title className="enable-drag">
                        <span className="cap-header">{LocalizationStore.getTranslatedData("set_shar_man","MANAGE SHARED ITEMS")}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="disable-drag">
                <div className="disable-drag modal-portion">
                    <Form inline className="share-main-container manage-share">
                        <div className="share-select-list-head">
                            {/* <div className="small-bold  disable-drag"> */}
                                {
                                    <TabBar
                                        sharedAction={sharedAction}
                                    />
                                }
                            {/* </div> */}
                        </div>
                        {/* <div className="share-grid-holder"> */}
                            {/* <FormGroup controlId="formTbl1 disable-drag"> */}
                                {
                                    <ManagedData
                                        managedData={nodeSharedData}
                                        sharedAction={sharedAction}
                                        sort={sort}
                                        sortReversed={sortReversed}
                                        showInProgress={showInProgress}                                                                                
                                    />
                                }
                            {/* </FormGroup> */}
                        {/* </div> */}
                    </Form>
                    <div className="clearfix"></div>
                </div>
                <div className="clearfix"></div>
            </Modal.Body>
            <Modal.Footer>
                <span className="btn-box center">
                    <button type="button"
                            className="btn btn-xs btn-default btn-secondary"
                            data-effect="fadeOut"
                            onClick={updateStorewithData}
                    >
                        {LocalizationStore.getTranslatedData("done","DONE")}
                    </button>
                </span>
            </Modal.Footer>
        </div>
    );
}

export default ManageDatapage;