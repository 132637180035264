import CalcPatternRecognition from "../../../Utils/Calcs/CalcPatternRecognition.js";
import { cancelPatternRecDialogSetting } from "../../../Actions/PricePanelActions.js";
import DatagraphStore from "../../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants.js";
import PriceMenuHelpers from "./PriceMenuHelper.js";
import SettingsStore from "SettingsStore";
import SymbolType from "../../../Constants/SymbolType.js";
import { fork, put, select, takeLatest } from 'redux-saga/effects';
import { getDatagraphStates, getPatternRecStates, getTimeLine, priceChartReducerselect} from '../../../Reducers/NavDataGraph/TabDataGraph/selectors'

const { ActionTypes } = PriceChartConstants;
function* processPatternRec(action) {
    try {
        if(action?.isStreamingProc){
            return;
        }

        const { isPricePanelDataReady, pricePanelData, periodicity, viewsSettings, majorPeriodicity, SymTypeEnum}  = yield select(getDatagraphStates);
        const { PatternRecognitionSettings } = yield select(getPatternRecStates);

        const patternRecognitionSettings = isPricePanelDataReady ? viewsSettings.PatternRecognitionSettings ? viewsSettings.PatternRecognitionSettings[majorPeriodicity] : undefined : PatternRecognitionSettings;
        const { endDate, HiLowPoints, scale } = yield select(priceChartReducerselect);
        const showPattern = DatagraphStore.isShowPattern();
        if(showPattern && SymTypeEnum !== SymbolType.INDEX && patternRecognitionSettings && pricePanelData.patterns && HiLowPoints.allPoints.length > 0){
            const TimeLine = yield select(getTimeLine);
            const calcPr = new CalcPatternRecognition({ endDate, HiLowPoints, periodicity, scale, TimeLine, StockInformation: pricePanelData.SymbolInfo });
            const result = calcPr.CalculatePRData(pricePanelData.patterns, patternRecognitionSettings);
            
            yield put({
                type: ActionTypes.PATTERN_REC_DATA_READY,
                patternsData: result.PRData,
                from: result.from,
                ticks: result.ticks,
                units: result.units,
                baseStatus: result.baseStatus,
                color: result.color,
                priceChangeValue: result.priceChangeValue,
                IsVisible: patternRecognitionSettings.IsVisible,
                patternRecognitionSettings,
                majorPeriodicity
            })
        }
        else{
            yield put({
                type: ActionTypes.CLEAR_PATTERN_REC
            });
        }
    }
    catch (error) {
        console.log(`Error occurs in PRSaga.js, processPatternRec ${error}`);
    }
}

function* openPatternRecDialog() {
    try{
        const viewSettings = PriceMenuHelpers.getInstSettings();
        const selectedPeriodicity = PriceMenuHelpers.getMajorPeriodicity();
        const settings = viewSettings.PatternRecognitionSettings[selectedPeriodicity];
        
        yield put({
            type: PriceChartConstants.ActionTypes.UPDATE_PATTERN_REC_DIALOG_SETTING,
            showSettingDialog: true,
            AdvancePattern: settings.AdvancePattern,
            ChannelLines: settings.ChannelLines,
            ClimaxTop: settings.ClimaxTop,
            PowerFromPivot: settings.PowerFromPivot,
            TightAreaPattern: settings.TightAreaPattern,
            PatternColor: settings.lineColor,
            TightAreaColor: settings.lineTColor,
            lossEnd: settings.lossEnd,
            lossStart: settings.lossStart,
            pivotEnd: settings.pivotEnd,
            pivotStart: settings.pivotStart,
            profitEnd: settings.profitEnd,
            profitStart: settings.profitStart,
            showKPR: settings.showKPR,
            selectedPeriodicity
        });
    }
    catch(error){
        console.error("Error occured in openFundamentalLineDialog of FundamenalLineSaga.js", error);
    }
}

function* savePatternRecPopup(){
    try{
        const state = yield select(getPatternRecStates);
        const viewSettings = PriceMenuHelpers.getInstSettings();
        const settings = viewSettings.PatternRecognitionSettings[state.selectedPeriodicity];
        settings.AdvancePattern = state.AdvancePattern;
        settings.ChannelLines = state.ChannelLines;
        settings.ClimaxTop = state.ClimaxTop;
        settings.PowerFromPivot = state.PowerFromPivot;
        settings.TightAreaPattern = state.TightAreaPattern;
        settings.lineColor = state.PatternColor;
        settings.lineTColor = state.TightAreaColor;
        settings.lossEnd = state.lossEnd;
        settings.lossStart = state.lossStart;
        settings.pivotEnd = state.pivotEnd;
        settings.pivotStart = state.pivotStart;
        settings.profitEnd = state.profitEnd;
        settings.profitStart = state.profitStart;
        settings.showKPR = state.showKPR;
        yield put({
            type: ActionTypes.CLOSE_PATTERN_REC_DIALOG
        });
        yield fork(processPatternRec);
        SettingsStore.saveSettings();
    }
    catch(error){
        console.error("Error occured in saveFudamentalEditPopup of FundamenalLineSaga.js", error);
    }
}
/*****************watchers*****************/
export function* watchInitPatternRec() {
    yield takeLatest(ActionTypes.PRICE_DATA_READY, processPatternRec)
}

export function* watchShowPatternRecDialog(){
    yield takeLatest(ActionTypes.OPEN_PATTERN_REC_DIALOG, openPatternRecDialog)
}
export function* watchSavePatternRecDialog(){
    yield takeLatest(ActionTypes.SAVE_PATTERN_REC_SETTING, savePatternRecPopup)
}