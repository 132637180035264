import ChartVisual from "./ChartVisual.jsx";
import { connect } from 'react-redux';
import DateHelper from "DateHelper";
import GraphApi from "../../../ServiceApi/Apis/GraphApi.js";
import { IdeaTranslateHelper } from "../../../Utils/TranslateHelper.js";
import React from "react";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { toggleIdeaBox , setIdeaStickToReducer} from "../../../Actions/PricePanelActions.js";

class LLRVisual extends ChartVisual {
    constructor(props) {
        super(props);
        this.infoZindex = 9;
        this.state = {
            showBox: false,
            currentIdea: {},
            showText: '',
            screenX: 0,
            screenY: 0,
        };
    }

    componentDidMount() {
        this.infoZindex = 10;
    }

    setArrowansDataLocation(data) {
        data.ideaTextColor = data.isPositive ? this.props.posDTColor : this.props.negDTColor;
        if (data.Type === 0 || data.Type === 1) {
            this.state.screenX = data.xAxisIn - this.props.dimension.left - 50;
            if (data.short) {
                this.state.screenY = data.yAxisIn - this.props.dimension.top - 40;
            } else {
                this.state.screenY = data.yAxisIn - this.props.dimension.top + 160;
            }
        }
        else {
            this.state.screenX = data.xAxisLast - this.props.dimension.left - 50;
            if (data.short) {
                this.state.screenY = data.yAxisLast - this.props.dimension.top + 160;
            } else {
                this.state.screenY = data.yAxisLast - this.props.dimension.top - 40;
            }
        }
        data.screenX = this.state.screenX;
        data.screenY = this.state.screenY;
        this.state.xAxisIn = data.xAxisIn;
        this.state.yAxisIn = data.yAxisIn;
        this.state.xAxisLast = data.xAxisLast;
        this.state.yAxisLast = data.yAxisLast;
        this.state.showText = data.IdeaShortName;
        this.state.showInfo = data.IdeaInfoText;
        this.state.showDays = data.IdeaDaysText;
        this.state.showcolor = data.ideaTextColor;
        this.state.entry = data.Entry;
        this.state.exit = data.Exit;
        this.state.short = data.short;
        this.state.datasource = data;
        this.state.current = data.Type === 0;
    }
    UNSAFE_componentWillReceiveProps(props) {
        if (this.props.ideasDisplay !== null) {
            if (this.props.ideasDisplay.length > 0) {
                let data = this.props.ideasDisplay[0];
                if (this.props.SelectIdeaType) {
                    const selectIdeaType = this.props.SelectIdeaType;
                    const tempData = this.props.ideasDisplay.find((itm) => itm.IdeaType === selectIdeaType);
                    if (tempData) {
                        data = tempData
                    }
                }
                this.setArrowansDataLocation(data);
            }
        }
        this.setState({ showBox: false });
        if (props.showBox !== undefined) {
            this.setState({ showBox: props.showBox });
        }
    }
    trianglePath(x, y, x1, y1) {
        if(y> y1){
            return `M ${x} ${y} L ${x1} ${y} L ${x1} ${y1} L ${x} ${y} z`;
        }
        else{
            return `M ${x} ${y} L ${x} ${y1} L ${x1} ${y1} L ${x} ${y} z`;
        }
    }
    upArrowPath(x, y) {
        return `M ${x} ${y} L ${x} ${y+12} M ${x} ${y} L ${x+3} ${y+5} L ${x} ${y+5} z`; 
    }
    upShortArrowPath(x, y) {
        return  `M ${x} ${y} L ${x} ${y+12} M ${x} ${y} L ${x-3} ${y+5} L ${x} ${y+5} z`;
    }
    downArrowPath(x, y) {
        return `M ${x} ${y} L ${x} ${y-12} M ${x} ${y} L ${x-3} ${y-5} L ${x} ${y-5} z`;
    }
    downShortArrowPath(x, y) {
        return `M ${x} ${y} L ${x} ${y-12} M ${x} ${y} L ${x+3} ${y-5} L ${x} ${y-5} z`
    }
    handleMouseLeave(datasource) {
        const data = datasource;
        data.highLight = false;
        this.setState({ showBox: false });
    }
    handleMouseOver(datasource) {
        this.state.showBox = false;
        if (datasource) {
            const data = datasource;
            if (data.stick) { return; }
            data.highLight = true;
            this.setArrowansDataLocation(data);
            this.setState({ showBox: true });
        }
    }
    handleMouseDown(datasource) {
        if (datasource) {
            const data = datasource;
            if (!data.stick) {
                const tempData = this.props.ideasDisplay.find((itm) => itm.stick === true);
                if (tempData) { data.zIndex = tempData.zIndex + 1 }
            }
            data.stick = data.stick ? false : true;
            this.setArrowansDataLocation(data);
        }
        this.props.toggleIdeaBox([...this.props.ideasDisplay]);

        const stick = this.props.ideasDisplay.filter((c) => c?.stick);
        this.props.setIdeaStickToReducer(stick)
    }
    getPath(pathData, datasource, key, keyval, dashValue = 0, fill = "transparent", stroke = "", opacity = "1", pointerEvents = "visible") {
        let cursor = "pointer";
        let display = "inline";
        if (pointerEvents === "none") {
            cursor = "default";
            if (datasource.xAxisIn < 0 || datasource.xAxisIn === 0) {
                display = "none";
            }
        }
        return (
            <path d={pathData} data-disable-track-price="true"
                ref={(ref) => this.line = ref}
                fill={fill}
                display={display}
                key={keyval + key}
                stroke={stroke}
                strokeDasharray={dashValue}
                strokeWidth="2px"
                opacity={opacity}
                cursor={cursor}
                onMouseDown={(e) => this.handleMouseDown(datasource, key, e)}
                onMouseOver={(e) => this.handleMouseOver(datasource, e)}
                onFocus={(e) => this.handleMouseOver(datasource, e)}
                onMouseLeave={(e) => this.handleMouseLeave(datasource, e)}
                pointerEvents={pointerEvents}
            />
        );
    }
    prepareFlagCurrentArrows(current) {
        const d = [];
        if (current.length) {
            const allRecs = current.filter((r) => r.Type === 0);
            const lng = allRecs.length;
            for (let i = 0; i < lng; i++) {
                const node = allRecs[i];
                if (node === null || (!node.stick && !node.highLight) || node === undefined || isNaN(node.inPriceY)) { continue; }
                const x = node.xAxisIn + 1;
                let y = node.inPriceY + (node.short ? -30 : 20);
                y += (i - 0) * 20;
                if (node.short) { d.push(this.downShortArrowPath(x, y)); }
                else {
                    d.push(this.upArrowPath(x, y));
                }
            }
        }
        return d;
    }
    prepareCurrentArrows(current) {
        const d = [];
        if (current.length) {
            const allRecs = current.filter((r) => r.Type === 0);
            const lng = allRecs.length;
            for (let i = 0; i < lng; i++) {
                const node = allRecs[i];
                if (node === null || node === undefined || isNaN(node.inPriceY)) { continue; }
                const x = node.xAxisIn;
                let y = node.inPriceY + (node.short ? -30 : 20);
                y += (i - 0) * 20;
                if (node.short) { d.push(this.downShortArrowPath(x, y)); }
                else {
                    d.push(this.upArrowPath(x, y));
                }
            }
        }
        return d;
    }
    prepareFlagHistoricArrows(historic) {
        const cd = [];
        if (historic.length) {
            const allRecs = historic.filter((r) => r.Type > 0);
            const lng = allRecs.length;
            for (let i = 0; i < lng; i++) {
                let d = '';
                const node = allRecs[i];
                if (node === null || (!node.stick && !node.highLight) || node === undefined || isNaN(node.inPriceY)) { continue; }
                if (node.Type === 1) {
                    const x = node.xAxisIn + 1;
                    const y = (node.short ? node.outPriceY : node.inPriceY) + (node.short ? -20 : 20);
                    if (x !== undefined) {
                        if (node.short) { d += this.downShortArrowPath(x, y); }
                        else { d += this.upArrowPath(x, y); }
                    }
                }
                if (node.Type === 2) {
                    const x = node.xAxisOut - 1;
                    const y = (node.short ? node.inPriceY : node.outPriceY) + (node.short ? 20 : -20);
                    if (x !== undefined) {
                        if (node.short) { d += this.upShortArrowPath(x, y); }
                        else { d += this.downArrowPath(x, y); }
                    }
                }
                cd.push(d);
            }
        }
        return cd;
    }
    prepareHistoricArrows(historic) {
        const cd = [];
        if (historic.length) {
            const allRecs = historic.filter((r) => r.Type > 0);
            const lng = allRecs.length;
            for (let i = 0; i < lng; i++) {
                let d = '';
                const node = allRecs[i];
                if (node === null || node === undefined || isNaN(node.inPriceY)) { continue; }
                if (node.Type === 1) {
                    const x = node.xAxisIn;
                    const y = (node.short ? node.outPriceY : node.inPriceY) + (node.short ? -20 : 20);
                    if (x !== undefined) {
                        if (node.short) { d += this.downShortArrowPath(x, y); }
                        else { d += this.upArrowPath(x, y); }
                    }
                }
                if (node.Type === 2) {
                    const x = node.xAxisOut;
                    const y = (node.short ? node.inPriceY : node.outPriceY) + (node.short ? 20 : -20);
                    if (x !== undefined) {
                        if (node.short) { d += this.upShortArrowPath(x, y); }
                        else { d += this.downArrowPath(x, y); }
                    }
                }
                cd.push(d);
            }
        }
        return cd;
    }

    outputInfoBox(current, showInfo, showDays, showText, entry, exit, showcolor, screenX, screenY, key, datasource) {
        let yAxis = 0;
        let xAxis = 0;

        if (datasource.Type === 0) {
            yAxis = datasource.inPriceY + (datasource.short ? -120 : 50);
        }
        else
            if (datasource.Type === 1) {
                yAxis = (datasource.short ? datasource.outPriceY : datasource.inPriceY) + (datasource.short ? -120 : 50);
            }
            else {
                yAxis = (datasource.short ? datasource.inPriceY : datasource.outPriceY) + (datasource.short ? 50 : -120);
            }

        xAxis = screenX < 100 ? 100 : screenX + this.props.dimension.left - 150;
        const scSize = (this.props.dimension.bottom - this.props.dimension.top);
        if (yAxis > scSize - 90) { yAxis = scSize - 90; }
        if (yAxis < 0) { yAxis = 10; }

        const inDate = DateHelper.getDateString(datasource.InActionDate);
        let outDate = IdeaTranslateHelper["PRESENT"];
        if (datasource.OutDate !== undefined) {
            outDate = DateHelper.getDateString(datasource.OutActionDate);
        }

        const dates = `${inDate} - ${outDate}`;
        const xLeft = `${xAxis}px`;
        const ytop = `${yAxis}px`;
        const height = datasource && datasource.isPdfDataAvailable ? "90px" : "88px";
        const backgroundColor = ThemeHelper.getThemedBrush("E1E20FEFEF0");
        const zIndex = datasource.zIndex ? datasource.zIndex : this.infoZindex;
        const textStyle = {
            backgroundColor: backgroundColor,
            borderColor: "#333333",
            borderStyle: "solid",
            borderWidth: "1px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            fontFamily: "calibri",
            fontSize: "12px",
            fontWeight: "normal",
            color: "gray",
            textAlign: "Center",
            position: "absolute",
            left: xLeft,
            top: ytop,
            minHeight: height,
            width: "180px",
            zIndex: zIndex,
            pointerEvents: "auto"
        };
        if (!datasource.zIndex) { this.infoZindex++; }
        datasource.zIndex = zIndex;
        const textColor = showcolor;
        const grayText = ThemeHelper.getThemedBrush("scaleText");
        const exitlabel = current ? IdeaTranslateHelper["LAST"] : IdeaTranslateHelper["EXIT"];
        //let currInfo = showInfo + showDays;
        return (
            <div id="InfoBox" style={textStyle} key={key}>
                {(datasource && datasource.stick) &&
                    <div style={{
                        position: "absolute",
                        right: "-8px",
                        top: "-8px",
                        width: "16px", height: "16px", borderTopLeftRadius: "8px",
                        borderColor: "#333333",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        backgroundColor: backgroundColor,
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        borderBottomLeftRadius: "8px",
                        cursor: "pointer",
                        pointerEvents: "visible"
                    }} data-disable-track-price="true" onMouseDown={(e) => this.handleMouseDown(datasource, key, e)} >
                        <div style={{ textAlign: "center", marginLeft: "1px", marginTop: "-1px", opacity: "0.6", fontFamily: "arial", fontSize: "12px" }}>X</div>
                    </div>}
                <div style={{ color: grayText, marginTop: "4px" }}>
                    {showText}
                </div>
                <div style={{ color: grayText, marginTop: "-4px" }}>
                    {dates}
                </div>
                <div style={{ color: "#333333", marginTop: "-12px" }}>
                    ______________
                </div>
                {/*{current &&
                    <div style={{ color: textColor, marginTop: "-2px" }}>
                        {currInfo}
                    </div>}*/}
                {//!current &&
                    <div style={{ marginTop: "-2px" }}>
                        <span style={{ color: textColor, marginTop: "-2px" }}>
                            {showInfo}
                        </span>
                        <span style={{ marginLeft: "2px" }}>
                            {showDays}
                        </span>
                    </div>}
                {this.state.entry &&
                    <div style={{ marginTop: "-2px" }}>
                        <span>
                            {IdeaTranslateHelper["ENTRY"]}
                        </span>
                        <span style={{ color: grayText, marginLeft: "2px" }}>
                            {entry}
                        </span>
                        <span style={{ marginLeft: "2px" }}>
                            {exitlabel}
                        </span>
                        <span style={{ color: grayText, marginLeft: "2px" }}>
                            {exit}
                        </span>
                    </div>}
                {datasource && datasource.isPdfDataAvailable && <div style={{ marginTop: "-2px", cursor: "pointer", pointerEvents: "all" }}>
                    <a style={{ pointerEvents: "all" }} className="analysisLink" href={this.getDownloadIdeaAnalysisReportWebRequest(datasource.pdfResearchPath)}>{IdeaTranslateHelper["DETAILED_ANALYSIS"]}</a>
                </div>}
                {datasource && datasource.isVideoUrlAvailable && <div style={{ marginTop: "-2px", cursor: "pointer", pointerEvents: "all" }}>
                    <a style={{ pointerEvents: "all" }} className="analysisLink" target="_blank" href={datasource.videoResearchPath}>View Video Analysis</a>
                </div>}
            </div>
        );
    }
    outputAllInfoBox() {
        const d = [];
        if (this.props.ideasDisplay.length === undefined || this.props.ideasDisplay.length < 1) { return d; }
        const hist = this.props.ideasDisplay;
        const lng = hist.length;
        for (let x = 0; x < lng; x++) {
            const histRecord = hist[x];
            const current = histRecord.Type === 0;
            if (histRecord.stick) {
                this.setArrowansDataLocation(histRecord);
                d.push(this.outputInfoBox(current, histRecord.IdeaInfoText, histRecord.IdeaDaysText,
                    histRecord.IdeaShortName, histRecord.Entry, histRecord.Exit, histRecord.ideaTextColor,
                    histRecord.screenX, histRecord.screenY, x, histRecord));
            }
        }
        return d;
    }
    outputAllPerformance() {

        const d = [];
        if (this.props.ideasDisplay.length === undefined || this.props.ideasDisplay.length < 1) { return d; }
        const hist = this.props.ideasDisplay;
        const lng = hist.length;
        for (let x = 0; x < lng; x++) {
            const histRecord = hist[x];
            if (histRecord.stick && !isNaN(histRecord.yAxisIn)) {
                const currTriangle = this.trianglePath(histRecord.xAxisIn, histRecord.yAxisIn,
                    histRecord.xAxisLast, histRecord.yAxisLast);
                let currColor = histRecord.yAxisIn > histRecord.yAxisLast ? this.props.posDTColor : this.props.negDTColor;
                if (histRecord.short) { currColor = histRecord.yAxisIn <= histRecord.yAxisLast ? this.props.posDTColor : this.props.negDTColor; }
                d.push(this.getPath(currTriangle, histRecord, x, 700, 2, currColor, 'transparent', "0.5", "none"));
            }
        }
        return d;
    }
    outputCurrentArrows(current, flag = 0) {
        const d = [];
        const lng = current.length;
        for (let x = 0; x < lng; x++) {
            const currRecord = this.props.ideasDisplay[x];
            const color = flag === 1 ? 'white' : currRecord.IdeaColor;
            d.push(this.getPath(current[x], currRecord, x, 200, 0, color, color, "1", "visible"));
        }
        return d;
    }
    outputHistoricArrows(historic, flag = 0) {
        const d = [];
        if (this.props.ideasDisplay.length === undefined || this.props.ideasDisplay.length < 1) { return d; }
        const hist = this.props.ideasDisplay.filter((r) => r.Type > 0);
        const lng = historic.length;
        for (let x = 0; x < lng; x++) {
            const histRecord = hist[x];
            const color = flag === 1 ? 'white' : histRecord.IdeaColor;
            d.push(this.getPath(historic[x], histRecord, x, 800, 0, color, color, "1", "visible"));
        }
        return d;
    }

    getDownloadIdeaAnalysisReportWebRequest(pdfResearchPath) {
        return GraphApi.getPdfRequestURL(pdfResearchPath);
    }

    getMainSvg() {
        const lineStyle = {
            position: "absolute",
            fitPosition: "fill",
            left: "0px",
            top: "0px",
            zIndex: '9'
        };
        const current = this.prepareCurrentArrows(this.props.ideasDisplay);
        const fCurrent = this.prepareFlagCurrentArrows(this.props.ideasDisplay);
        const historic = this.prepareHistoricArrows(this.props.ideasDisplay);
        const fHistoric = this.prepareFlagHistoricArrows(this.props.ideasDisplay);
        const currTriangle = this.trianglePath(this.state.xAxisIn, this.state.yAxisIn,
            this.state.xAxisLast, this.state.yAxisLast);

        let currColor = this.state.yAxisIn > this.state.yAxisLast ? this.props.posDTColor : this.props.negDTColor;
        if (this.state.short) { currColor = this.state.yAxisIn <= this.state.yAxisLast ? this.props.posDTColor : this.props.negDTColor; }
        return (
            <svg style={lineStyle}
                id="LLRAreas"
                height='100%'
                width='100%'>
                {this.outputCurrentArrows(fCurrent, 1)}
                {this.outputCurrentArrows(current)}
                {this.props.showHistorical && this.outputHistoricArrows(fHistoric, 1)}
                {this.props.showHistorical && this.outputHistoricArrows(historic)}
                {this.state.showBox &&
                    this.getPath(currTriangle, this.state.datasource, 60, 300, 2, currColor, 'transparent', "0.5", "none")
                }
                {this.outputAllPerformance()}
            </svg>
        )
    }

    render() {
        TimeTrackingWindow.trackChartLoadingTime();

        if (this.props.ideasDisplay.length === 0 || this.props.isIntraday) {
            return null;
        }
        return (
            <div id="LLRInfo" style={{ height: '100%', width: '100%', position: 'absolute', pointerEvents: "none" }}>
                {this.getMainSvg()}
                {this.state.showBox &&
                    this.outputInfoBox(this.state.current, this.state.showInfo, this.state.showDays,
                        this.state.showText, this.state.entry, this.state.exit, this.state.showcolor,
                        this.state.screenX, this.state.screenY, 0, this.state.datasource)
                }
                {this.outputAllInfoBox()}
            </div>
        );
    }
}
const mapStateToProps = ({ DatagraphReducers, RelatedInfoPanelReducers, appColor }) => {
    const { dimension } = DatagraphReducers.PriceChartReducer;
    const { isIntraday } = DatagraphReducers.DataGraphReducer;
    const { showHistorical, SelectIdeaType, showBox, ideasDisplay } = RelatedInfoPanelReducers.RIPanelOneilReducers;
    const { posDTColor, negDTColor } = appColor;
    return { ideasDisplay, isIntraday, SelectIdeaType, showBox, showHistorical, posDTColor, negDTColor, dimension }
}

const mapDispatchToProps = (dispatch)=>({
    toggleIdeaBox: (ideasDisplay)=> dispatch(toggleIdeaBox(ideasDisplay)),
    setIdeaStickToReducer: (stick) =>dispatch(setIdeaStickToReducer(stick))
})
export default connect(mapStateToProps, mapDispatchToProps)(LLRVisual);