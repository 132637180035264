import React, { Component } from "react";
import { Button } from "react-bootstrap";
import RayChart from "RayChart";
import BarVisual from "BarVisual";
import ONeilViewStore from "ONeilViewStore";
import LineVisual from "LineVisual";
import ExtremeDataValue from "ExtremeDataValue";
import ErrorBoundary from "ErrorBoundary";
import GridLineUtil from "GridLineUtil";
import ThemeHelper from "ThemeHelper";
import LocalizationStore from 'LocalizationStore';
import ArrayUtil from "ArrayUtil";
import TimeLine from "../../../../RayCustomControls/Chart/TimeLine/TimeLine";
import SymbolType from "../../../../Constants/SymbolType";
import { LightDarkColorConstants } from "../../../../Constants/LightDarkColorConstants";
import KeyCodes from "../../../../Constants/KeyCodes";
import { connect } from "react-redux";
import QuarterlyLine from "QuarterlyLine";
import { setScaleByPeriodicity, updateDimension, updateEpsRpsWithPeriodicity } from "../../../../Actions/NupViewActions";
import MultiplierMenu from "../../../../RayCustomControls/ActionButtons/MultiplierMenu";
import GraphType from "../../../../Constants/GraphType";
import AppColorStore from "../../../../Stores/AppColor/AppColorStore";

class PriceChart extends Component{
    constructor(props){
        super(props);
        this.onChartResize = this.onChartResize.bind(this);
        this.colorChanged = this.colorChanged.bind(this)
        this.state = {
            redraw: true,
            epsValue: "EPS"
        }
    }
    shouldComponentUpdate(nextProps, nextState){
        if(this.props.selectedIndex !== nextProps.selectedIndex && this.props.isLoading === nextProps.isLoading){
            return false;
        }
        return true;
    }
    componentDidMount() {
        AppColorStore.addChangeListener(this.colorChanged);
        this.setState({redraw: true});
    }

    componentWillUnmount() {
        AppColorStore.removeChangeListener(this.colorChanged);
        this.componentMounted = false;
    }

    getBoundingClientRect() {
        if (this.Main)
            return this.PriceChart.getBoundingClientRect();
    }

    colorChanged(){
        this.forceUpdate()
    }

    onChartResize(dimension, leftScaleWidth, isHeader) {
        let timeLineHeight = this.timeLineRef ? this.timeLineRef.getBoundingClientRect().height : 20;
        dimension.width += leftScaleWidth;
        dimension.height = dimension.height - timeLineHeight - (isHeader ? 0 : 39);
        this.props.updateDimension(dimension);
       // this.props.initChart();
    }
    getMALine(maLine, maSetting, lastPrice, index, isDragging, isToolTip) {
        const maValues = maLine ? maLine : [];
        const yLastPrice = maValues && maValues.length > 0 && maValues[0] !== undefined ? maValues[0].yPrice : -1000;
        const { nodeWidth } = this.props;

        if (maLine && maLine.length > 0 && maValues[0] !== undefined) {
            let maLabel = "";
            if (maSetting && maSetting.ma) {
                maLabel = maSetting.ma + " " + maSetting.maType;
            }
            if (!maSetting.lineColor && index === 0) {
                maSetting.lineColor = "P0066CC3399FF";
            }
            if (!maSetting.lineColor && index === 1) {
                maSetting.lineColor = "PCC0100990000";
            }
            if (!maSetting.lineColor) {
                maSetting.lineColor = "P34FF33006600";
            }
            if (!maSetting.lineThickness) {
                maSetting.lineThickness = 1;
            }

            let showMarchingAnts = false;
            let dashArray = [0, 0];
            if (maSetting.highlightLine === true) {
                dashArray = [3, 4];
                showMarchingAnts = true;
            }

            const lineColor = ThemeHelper.getThemedBrush(maSetting.lineColor);
            const lineFontColor = ArrayUtil.contains(LightDarkColorConstants.LightColor, lineColor) ? "black" : "white";
            return <LineVisual DataSource={maLine}
                key = {maSetting.maType + maSetting.ma}
                nodeWidth={nodeWidth} LineColor={lineColor}
                LineThickness={maSetting.lineThickness} pointerTextColor={lineFontColor}
                Draggable={false} lineID={maLabel} DashArray={dashArray}
                messageColor={lineFontColor} maValue={maValues[0].yValue}
                zIndex={'1'} isAnyDrag={isDragging} isToolTip={isToolTip}
                lastPrice={lastPrice} ylastPrice={yLastPrice} showR={false}
                index={index} showMarchingAnts={showMarchingAnts} />;
        }
        return (<div></div>);
    }
    getViewBasedOnPeriodicity = (graphData) => {
        const positiveColor = ThemeHelper.getThemedBrush("positiveNode");
        const showChart = graphData.HiLowPoints && graphData.HiLowPoints.allPoints && graphData.HiLowPoints.allPoints[0];
        const { nodeWidth } = this.props;
        if (!showChart) return <div></div>;
        if (graphData.isLineChart) {
            return <LineVisual showPointer={true} showBox={true} nodeWidth={nodeWidth} ref={(ref) => this.LineVisual = ref}
                               DataSource={graphData.HiLowPoints.allPoints} LineColor={positiveColor}
                               LineThickness={2} Draggable={false} />;
        } else {
            return <BarVisual showPointer={true} showBox={true} nodeWidth={nodeWidth} ref={(ref) => this.BarVisual = ref} DataSource={graphData.HiLowPoints} />;
        }
    }

    handleClick(item, e) {
        e.preventDefault();
        e.stopPropagation();
        let newScale = "";
            if (item.scaleLabel === "LOG (F)")
                newScale = "LOG (A)";
            else if (item.scaleLabel === "LOG (A)")
                newScale = "LIN";
            else
                newScale = "LOG (F)";
        //}
       this.props.setScaleByPeriodicity(item.majorPeriodicity, newScale);
    }

    updateEPSRPS(epsSelected, periodicity, event){
        event.preventDefault();
        event.stopPropagation();
        this.props.updateEpsRpsWithPeriodicity(epsSelected, periodicity);
    }

    render(){
            if(this.props.isLoading){
                return (<div className="nup-box-body">
                     <div className="nup-chart"> {/*style={{ background: backgroundColor, color: textColor }}> */}
                        <span className="grid-loading">
                            <div className="group-box">
                                <div className="loading loading-spinner">
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                )
            }
            
            const { item, volItem,  nodeWidth, width, height, epsSelected, isHeader } = this.props;
            if(item === undefined) {
                return <div></div>;
            }
            if (item.HiLowPoints == null) {
                return (<div className="nup-box-body" ref={(ref) => this.Main = ref}>
                    <div className="message-empty">
                        <div className="message-text"> {LocalizationStore.getTranslatedData("price_not_ava", "Price Not Available")} </div>
                    </div>
                </div>);
            }
            const priceChartVisual = this.getViewBasedOnPeriodicity(item);
            // const backgroundColor = ThemeHelper.getThemedBrush("000fff");
            const smallChart = height < 250;
            const fund = item.SymbolInfo.SymTypeEnum === SymbolType.FUND;
            const noVolume = !item.hasVolume;
            const chartHeight = fund || noVolume ? height : height * 0.78;
            const rightScaleWidth = 45;
            let nodeData = item.HiLowPoints.allPoints[0];
           
            let sign = item.priceChange < 0 ? '' : '+';
            
            // const borderColor = GridLineUtil.getGridLineColor();
            item.IsIntraday = item.majorPeriodicity == GraphType.Intraday;
            const align = item.IsIntraday ? "Right" : "Left";
            // const style = { paddingRight: rightScaleWidth };
            let oHtypeTxt = LocalizationStore.getTranslatedData("ch_oh", "OH");
            const rateColor = volItem.volRate < 0
                ? ThemeHelper.getThemedBrush("negativeDataText")
                : ThemeHelper.getThemedBrush("positiveDataText");
            const rateSign = volItem.volRate < 0 ? "" : "+";
            let colr = ThemeHelper.getThemedBrush("positiveDataText");
            let colb = ThemeHelper.getThemedBrush("positiveNode");
            if (item.priceChange < 0) {
                colb = ThemeHelper.getThemedBrush("negativeNode");
                colr = ThemeHelper.getThemedBrush("negativeDataText");
            } else {
                colb = ThemeHelper.getThemedBrush("positiveNode");
                colr = ThemeHelper.getThemedBrush("positiveDataText");
            }
            let mainDivRight = {
                //height: "16px",
                right: (-rightScaleWidth) + "px",
                top: nodeData.yPrice - 8  + "px",
                visibility: true
            };
            let blockDivRight = {
                backgroundColor: colb,
                color: 'black'
            };
            let arrowRight = {
                // borderTop: "8px solid transparent",
                // borderBottom: "8px solid transparent",
                borderRight: "8px solid " + colb
                // ,
                // marginLeft:'2px'
            };
            
            // This is required to flip the price change to the top of the price pointer when goes into volume area
            let tSpace = chartHeight - 40 < nodeData.yPrice ? -16 : 16;
    
            let bStyle = {
                top: tSpace + "px", // Required
                background: ThemeHelper.getThemedBrush("000fff")
                // ,
                // width: 45 + "px"
            };

            //
            const epsRpsObj = {showEps: '', drawRps: false, drawEps: false, show: false };
            if(item.isEpsRpsAvailable){
                const FFO = item.SymbolInfo.SymTypeEnum === SymbolType.REIT && !item.SymbolInfo.IsNav;
                epsRpsObj.epsRps = epsSelected ? FFO ? "FFO" : "EPS" : "RPS";
                const yEpsValues = !item.daily && !item.epsDataMissing && item.epsLineData ? item.epsLineData.filter((t) => t.ShowRpt < 1) : [];
                let yEps = !item.daily && yEpsValues.length > 0 && yEpsValues[0].yPrice ? yEpsValues[0].yPrice : 0;
                epsRpsObj.showArrow = true;
                if (epsSelected) {
                    if (yEps < 45) {
                        yEps = 45;
                        epsRpsObj.showArrow = false;
                    }
                    if (item.LastQtrEPS <= 0) {
                        yEps = chartHeight - 8;
                        epsRpsObj.showArrow = false;
                    }
                }
                const yRpsValues = !item.daily && !item.rpsDataMissing && item.rpsLineData ? item.rpsLineData.filter((t) => t.ShowRpt < 1) : [];
                let yRps = !item.daily && yRpsValues.length > 0 && yRpsValues[0].yPrice ? yRpsValues[0].yPrice : 0;
                if (!epsSelected) {
                    if (yRps < 45) {
                        yRps = 45;
                        epsRpsObj.showArrow = false;
                    }
                    if (item.LastQtrRPS <= 0) {
                        yRps = chartHeight - 8;
                        epsRpsObj.showArrow = false;
                    }
                }
                epsRpsObj.epsLineFontColor = ArrayUtil.contains(LightDarkColorConstants.LightColor, item.epsSettings.lineColor) ? "black" : "white";
                epsRpsObj.rpsLineFontColor = ArrayUtil.contains(LightDarkColorConstants.LightColor, item.rpsSettings.lineColor) ? "black" : "white";
    
                epsRpsObj.showEps = !item.daily && !item.epsDataMissing ? epsRpsObj.epsRps : "";
                const epsColor = ThemeHelper.getThemedBrush(item.epsSettings.lineColor);
                const rpsColor = ThemeHelper.getThemedBrush(item.rpsSettings.lineColor);
                let mainDivLeftEps = {
                    //height: "16px",
                    left: -42 + "px",
                    top: yEps - 8 + "px",
                    visibility: true
                };
                let mainDivLeftRps = {
                    //height: "16px",
                    left: -42 + "px",
                    top: yRps - 8 + "px",
                    visibility: true
                };
                let blockDivLeftEps = {
                    backgroundColor: epsColor,
                    color: 'black'
                };
                let blockDivLeftRps = {
                    backgroundColor: rpsColor,
                    color: 'black'
                };
                let arrowLeftEps = {
                    // borderTop: "8px solid transparent",
                    // borderBottom: "8px solid transparent",
                    borderLeft: "8px solid " + epsColor
                    // ,
                    // marginRight: '1px'
                };
                let arrowLeftRps = {
                    // borderTop: "8px solid transparent",
                    // borderBottom: "8px solid transparent",
                    borderLeft: "8px solid " + rpsColor
                    // ,
                    // marginRight: '1px'
                };
                epsRpsObj.drawEps = item.epsSettings.IsVisible && !item.daily && !item.epsDataMissing;
                epsRpsObj.drawRps = item.rpsSettings.IsVisible && !item.daily && !item.rpsDataMissing;
                epsRpsObj.show = epsRpsObj.drawEps || epsRpsObj.drawRps;
                epsRpsObj.multiplier = epsSelected ? (item.epsMultiplier > 999 ? ExtremeDataValue.abbreviateFinancialValue(item.epsMultiplier, 1) : item.epsMultiplier) + " x":
                                                (item.rpsMultiplier > 999 ? ExtremeDataValue.abbreviateFinancialValue(item.rpsMultiplier, 1) : item.rpsMultiplier) + " x";
                const epsValue = Math.abs(item.LastQtrEPS) > 999 ? ExtremeDataValue.abbreviateFinancialValue(item.LastQtrEPS, 1) : ExtremeDataValue.showPrice(item.LastQtrEPS);
                const rpsValue = Math.abs(item.LastQtrRPS) > 999 ? ExtremeDataValue.abbreviateFinancialValue(item.LastQtrRPS, 1) : ExtremeDataValue.showPrice(item.LastQtrRPS);
                
                
                epsRpsObj.showPointer = epsSelected ? epsRpsObj.show && epsValue !== "" : epsRpsObj.show && rpsValue !== "";
                epsRpsObj.fValue = epsSelected ? epsValue : rpsValue;
                epsRpsObj.arrowLeft = epsSelected ? arrowLeftEps : arrowLeftRps;
                epsRpsObj.blockDivLeft = epsSelected ? blockDivLeftEps : blockDivLeftRps;
                epsRpsObj.mainDivLeft = epsSelected ? mainDivLeftEps : mainDivLeftRps;
            }
            
            const vmaColor = "#868686";
            const cHeight = fund || noVolume ? smallChart ? "90%" : "93%" : "64%";
            const plotVolume = !fund && !noVolume;
            
            return (<div className="nup-box-body" ref={(ref) => this.Main = ref}>
            <div className="nup-chart">
                <div className="symbol-chart-stack symbol-price-stack" 
                        //style={{ height: cHeight }} // Needed for mutual Fund Charts
                >
                    <RayChart ref={(ref) => this.PriceChart = ref}
                        width={width}
                        scale={item.scale}
                        nodeWidth={nodeWidth}
                        TimeLine={item.TimeLine.dates}
                        isIntraday={item.IsIntraday}
                        showEps={epsRpsObj.showEps}
                        leftScaleWidth={item.leftScaleWidth}
                        righscaleWidth={rightScaleWidth} //need change
                        onChartResize={(dimension)=>this.onChartResize(dimension, item.leftScaleWidth, isHeader)}
                        showHorizontalGrid={true}
                        showVerticalGrid={true}>
                        {priceChartVisual}
                        {(item.maLineData && item.maLineData.length > 0) &&
                            item.maSettings.map((maSetting, index) => {
                                if (maSetting.IsVisible) {
                                    return this.getMALine(item.maLineData[index], maSetting, item.lastPrice, index, false, false);
                                } else
                                    return (<div></div>);
                            })
                        }
                        {epsRpsObj.drawRps &&
                            <QuarterlyLine isAnyDrag={false} isToolTip={false} showPointer={item.ShowRPSPointer} ref={(ref) => this.RpsChart = ref}
                                DataSource={item.rpsLineData} LineColor={item.rpsSettings.lineColor} frontColor={epsRpsObj.rpsLineFontColor} LineThickness={item.rpsSettings.lineThickness} pointerVisibility={item.rpsPointerVisible}
                                Draggable={false} showR={false} BoxNodes={true} type="RPS" SurpriseNodes={false} Scale={"LOG (Fixed)"}/>
                        }
                        {epsRpsObj.drawEps &&
                            <QuarterlyLine isAnyDrag={false} isToolTip={false} showPointer={item.ShowEPSPointer} ref={(ref) => this.RpsChart = ref}
                                DataSource={item.epsLineData} LineColor={item.epsSettings.lineColor} frontColor={epsRpsObj.epsLineFontColor} LineThickness={item.epsSettings.lineThickness} pointerVisibility={item.epsPointerVisible}
                                Draggable={false} showR={false} BoxNodes={false} type="EPS" SurpriseNodes={false} Scale={"LOG (Fixed)"}/>
                        }
                        <div className="pointer-hold righter" ref={(ref) => this.mainDiv = ref} style={mainDivRight}>
                            <div className="pointer-data pull-right" style={blockDivRight}>
                                <div className="pointer-arrow" style={arrowRight}></div>
                                <span className="pointer-left" style={{ color: 'white' }}>{item.priceVal}</span> 
                                {/* , fontSize: "11px", marginTop: "-1px" */}
                            </div>
                            <div className="pointer-changes" style={bStyle}>
                                <span className="pointer-changes-left" style={{ color: colr }}>{sign + item.priceChange}</span>
                                {/* , fontSize: "11px", marginTop: "2px" */}
                            </div>
                        </div>
                        {epsRpsObj.showPointer && <div className="pointer-hold lefter" style={epsRpsObj.mainDivLeft}>
                            <div className="pointer-data" style={epsRpsObj.blockDivLeft}>
                                {epsRpsObj.showArrow &&
                                    <>
                                        <div className="pointer-arrow" style={epsRpsObj.arrowLeft}></div>
                                        {/* <span className="pointer-center" style={{ fontSize: "11px" }} >{fValue}</span> */}
                                    </>
                                }
                                <div className="pointer-arrow" style={epsRpsObj.arrowLeft}></div>
                                <span className="pointer-center">{epsRpsObj.fValue}</span>
                                {/*  style={{ fontSize: "11px" }} */}
                            </div>
                        </div>
                        }
                    </RayChart>
                    {epsRpsObj.show &&
                        <div className="chart-panel-left-content">
                            <Button
                                bsSize="xsmall"
                                bsStyle="info"
                                onClick={this.updateEPSRPS.bind(this, item.epsSettings.IsVisible && item.rpsSettings.IsVisible ? !epsSelected : item.epsSettings.IsVisible ? true: false, item.majorPeriodicity)}
                            >
                                <span className="with-dot">{epsRpsObj.epsRps}</span>
                                {/*    <div className="auto-search-error pricing-tooltip">{"EPS"}</div>*/}
                            </Button>
                            {epsRpsObj.show && <MultiplierMenu label={epsRpsObj.multiplier} defaultCursor={true}
                                menuTitle={epsRpsObj.epsRps}
                                priceMenu={epsRpsObj.epsRps}
                                shouldDisplayMenu={false} />
                            }
                        </div>
                    }
                    <div className="chart-panel-center-content">
                        <div className="chart-panel-center-content-holder" 
                        // style={{ margin: "0 45px 0 0" }}
                        >
                            <div className="price-extras righter" 
                            // style={{ position: "relative", right: "10px", pointerEvents: "none" }}
                            >
                                {item.showOh &&
                                    <div className="oh-percent" id='ohigh'>
                                        <span className="oh-percent-head" 
                                        // style={{ borderBottom: '0px', cursor: 'default' }} 
                                        data-disable-track-price="true">{oHtypeTxt}</span>
                                        <span className="oh-percent-data">{item.oValue}%</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="chart-panel-right-content">
                        <div className="symbol-chart-title"
                            //style={{ cursor: 'pointer', pointerEvents: 'all', fontSize: "11px", right: "8px" }}
                            id="scale" data-disable-track-price="true"
                            onClick={this.handleClick.bind(this, item)}>
                            <span>{item.scaleLabel}</span>
                        </div>
                    </div>
                </div>
                {plotVolume &&
                <div className="volume-panel"
                    style={{
                        // background: backgroundColor,
                        // borderWidth: '1px',
                        // borderColor: borderColor,
                        // borderStyle: 'solid',
                        // borderBottom: '0',
                        // borderLeft: '0',
                        // borderTop: '1',
                        // borderRight: '0',
                        // height: (smallChart ? "22%" : "26%"),
                        // zIndex: '100'
                    }}>
                    <RayChart ref={(ref) => this.VolumeChart = (ref)}
                        width={width}
                        scale={volItem.vscale}
                        nodeWidth={nodeWidth}
                        leftScaleWidth={item.leftScaleWidth}
                        showEps={epsRpsObj.showEps}
                        TimeLine={item.TimeLine.dates}
                        isIntraday={item.IsIntraday}
                        righscaleWidth={rightScaleWidth}
                        showHorizontalGrid={false}
                        showVerticalGrid={true}
                        showLabels={false}>
                        <LineVisual DataSource={volItem.volMALineData} nodeWidth={nodeWidth} LineColor={vmaColor} LineThickness={1}
                                    Draggable={false} />
                        <BarVisual showPointer={false} showBox={false} nodeWidth={nodeWidth} ref={(ref) => this.BarVisualv = ref} DataSource={volItem.volumeData} />
                    </RayChart>
                    <div className="chart-panel-right-content">
                        <table className="symbol-chart-title">
                            <tbody>
                                <tr>
                                    <td className="text">{"VOL RATE"}</td>
                                </tr>
                                <tr>
                                    <td className="text" style={{color: rateColor}}>{rateSign + volItem.volRate + "%"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
                <div className="time-line-panel"
                    style={{
                        // background: backgroundColor,
                        // borderWidth: '1px',
                        // borderColor: borderColor,
                        // borderStyle: 'solid',
                        // borderBottom: '0',
                        // borderLeft: '0',
                        // borderTop: '1',
                        // borderRight: '0',
                        // height: (smallChart ? "8%" : "4%"),
                        // width: "100%",
                        // zIndex: '100'
                    }}
                    ref = {(tRef)=> this.timeLineRef = tRef}
                    >
                    <ErrorBoundary>
                        <div className="time-line-holder" >
                        {/* style={style} */}
                            <TimeLine isCalender={true}
                                source={item.timeLineLabel} nodeWidth={nodeWidth}
                                fiscalMonthEnd={12} textAlign={align} />
                        </div>
                    </ErrorBoundary>
                </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch)=>({
    setScaleByPeriodicity: (periodicity, newScale)=> dispatch(setScaleByPeriodicity(periodicity, newScale)),
    updateDimension: (dimension)=> dispatch(updateDimension(dimension)),
    updateEpsRpsWithPeriodicity: (epsSelected, periodicity)=> dispatch(updateEpsRpsWithPeriodicity(epsSelected, periodicity)),
});

const mapStateToProps = ({ listViewReducer }, props) => {
    const { NupViewReducer } = listViewReducer;
    const listData = NupViewReducer.listData[props.selectedIndex[0]][props.selectedIndex[1]]
    const states = {isLoading: listData.isLoading || listData.graphData === null || NupViewReducer.isResizing};
    if(!states.isLoading){
        const graphData = listData.graphData;
        states.width = NupViewReducer.dimension[NupViewReducer.selected].width; 
        states.height= NupViewReducer.dimension[NupViewReducer.selected].height;
        states.epsSelected= NupViewReducer.epsSelected[graphData.chartData.majorPeriodicity];
        states.nodeWidth= NupViewReducer.nodeWidth;
        states.item= graphData.chartData;
        states.volItem= graphData.volumeData;
        states.isHeader = listData.HeaderData.Description !== null;
        states.visibleItems= NupViewReducer.visibleItems;
        states.selectedIndex = NupViewReducer.selectedIndex,
        states.updateTheme = NupViewReducer.updateTheme
    }
    return states
  }
export default connect(mapStateToProps, mapDispatchToProps)(PriceChart);