import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BlockType from "../../../../Constants/BlockType";
import chartApi from "../../../../ServiceApi/Apis/ChartApi";
import ConsoleStore from "ConsoleStore";
import { DatagraphConst } from "../../../../Utils/DatagraphHelper";
import DateHelper from "DateHelper";
import GraphType from "GraphType";
import PeriodicityHelper from "PeriodicityHelper";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../../Constants/SmartViewType";
import StringUtil from "../../../../Utils/StringUtil";
import SymbolType from "SymbolType";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getIndicatorStates, getTimeLine } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { indicatorCommonConst, IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper";
import { updateIndicatorAbsoluteData, updateIndicatorData, updateIndicatorGraphData, updateIndicatorRelativeData, updateIndicatorSettings } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";

const { ActionTypes } = IndicatorsConstants;
// let lastnodeIndex = 500;
const frequency = { [BlockType.YTD]: 0, [BlockType.YTD1]: 1, [BlockType.YTD3]: 3, [BlockType.YTD5]: 5, [BlockType.YTD10]: 10 };
const chartTypeConst = { 0: BlockType.YTD, 1: BlockType.YTD1, 3: BlockType.YTD3, 5: BlockType.YTD5, 10: BlockType.YTD10 };
const ytdIndicatorSettingConst = {
    0: "YTDChartSettings", 
    1: "YTD1ChartSettings", 
    3: "YTD3ChartSettings", 
    5: "YTD5ChartSettings", 
    10: "YTD10ChartSettings"
}
function getYTDChart(hsfResults, scale, firstNodeIndex, pricepanelData, startXPoint, padding) {
    const chartData = { highPoints: [], lowPoints: [] };
    const prcLength = hsfResults.length;
    if (prcLength < 1) { return chartData; }
    chartData.allPoints = new Array(prcLength);

    let xAxis = startXPoint;
    let yAxis;
    const firstNodeDate = hsfResults[0].Date.getFullYear ? hsfResults[0].Date : new Date(parseInt(hsfResults[0].Date.substr(6)));
    const zeroYAxis = Math.round(scale.ComputeY(0))

    // if (StringUtil.isEmpty(pricepanelData.HsfData) || StringUtil.isEmpty(pricepanelData.HsfData.HSFResults)) { return chartData; }

    const hsfData = pricepanelData.HsfData.HSFResults;
    let jh = padding;
    let index = 0;
    const hsfDataLength = hsfData.length
    for (; jh < hsfDataLength; jh++) {
        if (hsfData[jh] && hsfData[jh].Date > firstNodeDate) {
            xAxis -= DatagraphConst.nodeWidth;
            continue;
        }
        break;
    }

    for (let j = firstNodeIndex; j < prcLength; j++) {
        if (xAxis < 0) { break; }
        if (isNaN(hsfResults[j].Close)) {
            xAxis -= DatagraphConst.nodeWidth;
            continue;
        }
        const uptick = hsfResults[j].Close >= 0;
        yAxis = scale.ComputeY(hsfResults[j].Close);
        const info = {
            Date: hsfResults[j].Date.getFullYear ? hsfResults[j].Date : new Date(parseInt(hsfResults[j].Date.substr(6))),
            value: hsfResults[j].Close,
            yHigh: (uptick ? yAxis : zeroYAxis),
            yLow: uptick ? zeroYAxis : yAxis,
            xAxis: xAxis,
            UpTick: uptick
        };
        xAxis -= DatagraphConst.nodeWidth;
        if (uptick) {
            chartData.highPoints.push(info);
        }
        else {
            chartData.lowPoints.push(info);
        }
        chartData.allPoints[index] = info;
        index++;
    }
    return chartData;
}
function initYTDScale(graphData, lastNode, graphType, indicatorsHeight, startXPoint) {
    let maxValue = Number.NEGATIVE_INFINITY;
    let minValue = Number.POSITIVE_INFINITY;
    let xAxis = startXPoint;
    const gLength = graphData.length;

    for (let minMaxIndex = lastNode; minMaxIndex < gLength; minMaxIndex++) {
        if (graphData[minMaxIndex] === null) {
            break;
        }

        if (minValue > graphData[minMaxIndex].Close) {
            minValue = graphData[minMaxIndex].Close;
        }
        if (maxValue < graphData[minMaxIndex].Close) {
            maxValue = graphData[minMaxIndex].Close;
        }

        xAxis -= DatagraphConst.nodeWidth;
        if (xAxis < 0) { break; }
    }
    const scale = new ArithmaticScale();
    scale.InitScale(minValue, maxValue, indicatorsHeight, graphType, 2, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true);
    return scale;
}
function expandYTDData(graphData, timeLine) {
    const timeLineLength = timeLine.length;
    const g1Length = graphData.length;
    let graphDataIndex = 0;
    const newData = [];
    if (g1Length < 1) { return newData; }
    let firstNode = 0;

    graphData[0].Date = DateHelper.getPSTFromLong(graphData[0].Date);
    const pPeriodDate = graphData[0].Date;

    for (; firstNode < timeLineLength - 1 && timeLine[firstNode] && pPeriodDate <= timeLine[firstNode].Date; firstNode++) { ; }

    if (timeLine[firstNode] === undefined) {
        return newData;
    }

    if (pPeriodDate > timeLine[firstNode].Date) {
        graphData[graphDataIndex].Date = DateHelper.getPSTFromLong(graphData[graphDataIndex].Date);

        newData.push({
            Close: graphData[graphDataIndex].Close,
            Date: graphData[graphDataIndex].Date,
            UpTick: graphData[graphDataIndex].UpTick
        });
        graphDataIndex++;
    }
    for (; graphDataIndex < g1Length; graphDataIndex++) {
        graphData[graphDataIndex].Date = DateHelper.getPSTFromLong(graphData[graphDataIndex].Date);

        while (firstNode < timeLineLength && timeLine[firstNode] &&
            graphData[graphDataIndex].Date <= timeLine[firstNode].Date) {
            const tmTime = timeLine[firstNode].Date.getTime();
            const grTime = graphData[graphDataIndex].Date.getTime();

            if (tmTime === grTime) {
                newData.push({
                    Close: graphData[graphDataIndex].Close,
                    Date: timeLine[firstNode].Date,
                    UpTick: graphData[graphDataIndex].UpTick
                });
            } else {
                newData.push({
                    Close: 0,
                    Date: timeLine[firstNode].Date,
                    UpTick: graphData[graphDataIndex].UpTick
                });
            }
            firstNode++;
        }
    }
    return newData;
}
function* PrepareData({ chartType }) {
    try {
        const { SettingDict, RelativeData, AbsoluteData } = yield select(getIndicatorStates);
        const {  firstNodeIndex, pricePanelData, startXPoint, padding, indicatorsHeight, SymbolType, periodicity, Symbol } = yield select(getDatagraphStates);
        let graphData = null;
        SettingDict[chartType].IsRelative = !SettingDict[chartType].IsRelative;
        if (SettingDict[chartType] && SettingDict[chartType].IsRelative && !StringUtil.isEmpty(RelativeData[chartType])) {
            graphData = RelativeData[chartType];
        }
        else if (SettingDict[chartType] && !SettingDict[chartType].IsRelative && !StringUtil.isEmpty(AbsoluteData[chartType])) {
            graphData = AbsoluteData[chartType];
        }
        else {
            const findGetTechIndRequest = {
                symbol: Symbol,
                graphType: PeriodicityHelper.getGraphTypeFromPeriodicity(periodicity),
                endDate: ConsoleStore.getUserEndDate() ? DateHelper.yyyymmdd(ConsoleStore.getUserEndDate()) : null,
                fytd: `${frequency[chartType]}-${SettingDict[chartType].IsRelative ? 1 : 0}`,
                sratings: "",
                cratings: "",
                eratings: "",
                cytd: "",
                eytd: "",
                wytd: "",
                iytd: ""
            };

            const result = yield call(chartApi.getTechnicalIndicatorData, findGetTechIndRequest);
            if (result && result.YTDPerfResults[0].freq === frequency[chartType]) {
                graphData = result.YTDPerfResults[0].YTDResults;
            }
        }
        const timeLine = yield select(getTimeLine);
        yield call(processData, graphData, indicatorsHeight, chartType, SettingDict[chartType], firstNodeIndex, pricePanelData, startXPoint, timeLine, padding, SymbolType);
    }
    catch (error) {
        console.log("Error occurs in YTDIndicatorSaga.js, PrepareData", error)
    }
}

function* processData(graphData, indicatorsHeight, chartType, SettingDict, firstNodeIndex, pricepanelData, startXPoint, timeLine, padding, SymbolType) {
    if (graphData) {
        graphData = expandYTDData(graphData, timeLine);
        yield put(updateIndicatorGraphData(graphData, chartType));
        SettingDict.IsRelative ? yield put(updateIndicatorRelativeData(graphData, chartType)) : yield put(updateIndicatorAbsoluteData(graphData, chartType));
        const scale = initYTDScale(graphData, 0, GraphType.Weekly, indicatorsHeight, startXPoint);
        const DataSource = getYTDChart(graphData, scale, firstNodeIndex, pricepanelData, startXPoint, padding);
        const indicatorVisuals = { [chartType]: [] };
        const indicatorLabels = { [chartType]: { labels: [] } };
        indicatorVisuals[chartType].push({ isBarVisual: true, key:"Line1", isQtrVisual: false, DataSource, nodeWidth: DatagraphConst.nodeWidth });
        indicatorLabels[chartType].isScale = true;
        const label = chartType === BlockType.YTD ? IndicatorLabelTranslateHelper[BlockType.YTD] : `${frequency[chartType]}-${IndicatorCommonTranslateHelper[indicatorCommonConst.Year]}`
        indicatorLabels[chartType].LabelMenu = { label, isTICustomModal: true, isShowAppearanceDialog: false, isShowAboutDialog: true }
        if(SymbolType === SmartViewType.FUND){
            indicatorLabels[chartType].class = "types-n-scales hoverable";
            indicatorLabels[chartType].cursor = "pointer";
            indicatorLabels[chartType].pointerEvents = "all";
            indicatorLabels[chartType].borderBottom = "1px dotted";
            indicatorLabels[chartType].scaleText = `% ${SettingDict.IsRelative ? IndicatorCommonTranslateHelper[indicatorCommonConst.Relative] : IndicatorCommonTranslateHelper[indicatorCommonConst.Absolute]}`;
        }
        else{
            indicatorLabels[chartType].class = "types-n-scales";
            indicatorLabels[chartType].cursor = "default";
                indicatorLabels[chartType].pointerEvents = "none";
                indicatorLabels[chartType].borderBottom = "none";
            indicatorLabels[chartType].scaleText = `% ${IndicatorCommonTranslateHelper[indicatorCommonConst.Absolute]}`;
        }
        
        yield put(updateIndicatorData(indicatorVisuals, indicatorLabels, chartType, scale));
    }
}

function* initIndicator() {
    try {
        const { indicatorResponse, isAllIndicatorDataApiInitiated} = yield select(getIndicatorStates);
        if((!isAllIndicatorDataApiInitiated) && indicatorResponse) {
            const { majorPeriodicity, viewsSettings, firstNodeIndex, pricePanelData, startXPoint, padding, indicatorsHeight, SymbolType } = yield select(getDatagraphStates);
            const timeLine = yield select(getTimeLine);
            const SettingDict = { }
            for(const item of indicatorResponse.YTDPerfResults){
                SettingDict[chartTypeConst[item.freq]] = viewsSettings[ytdIndicatorSettingConst[item.freq]] ? viewsSettings[ytdIndicatorSettingConst[item.freq]][majorPeriodicity] : {};
                if(SettingDict[chartTypeConst[item.freq]].IsAvailable){
                    yield fork(processData, item.YTDResults, indicatorsHeight, chartTypeConst[item.freq], SettingDict[chartTypeConst[item.freq]], firstNodeIndex, pricePanelData, startXPoint, timeLine, padding, SymbolType );
                }
            }
            yield put(updateIndicatorSettings(SettingDict));
        }
    }
    catch (error) {
        console.error("Error occurs in YTDIndicatorSaga.js, initIndicator", error);
    }

}
//===================================================

export function* watchProcessYTDIndicator() {
    yield takeLatest(ActionTypes.PROCESS_INDICATOR, initIndicator)
}

export function* watchInitYTDIndicator() {
    yield takeLatest(ActionTypes.INIT_INDICATOR, initIndicator)
}

export function* watchPrepareYTDIndicatorData() {
    yield takeLatest(ActionTypes.PREPARE_YTD_INDICATOR_DATA, PrepareData);
}

export function* watchToggleYTDChart() {
    // yield takeLatest(ActionTypes.TOGGLE_YTD_CHART, togglePTOECHart)
}
export function* watchUpdateYTDIndicatorHeight() {
     yield takeLatest(PriceChartConstants.ActionTypes.INDICATOR_RESIZE, initIndicator)
}