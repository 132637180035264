import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
// import BaseServiceApi from "BaseServiceApi";

export default class UploadExternalData extends BaseServiceRequest {
  constructor(data,externalUrl,uploadArr) {
    super();
    super.addRequestUri("externalfileuploader.pb");
  if(externalUrl){
    Object.entries(uploadArr).map(([key, value]) =>
      super.addRequestParameter(key, value)
    );
  //   for(var pair of data.entries()) {
  //     super.addRequestParameter(pair[0], pair[1]);
  //  }
  }else{
    super.setContent(data);
  }
  }

  getMethod() {
    return HttpMethodType.POST;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ExternalFileUploaderResponse";
  }

}
