import BaseServiceApi from 'BaseServiceApi';
import StringUtil from 'StringUtil';
class LoginApi {

  validateUser(logindata) {
    return new Promise((resolve, reject) => {
      let email = logindata.email;
      let password = logindata.password;

      //let url = "http://tomcat.dev.panaray.com/rayweb/u/signin?dt=web";
      let servername = BaseServiceApi.getServerName();
      let protocol = "https";
      /*let env = process.env.BRANCH_ENV;
      if (env && (env.toUpperCase() == "PROD" || env.toUpperCase() == "STAGE")) {
        protocol = "https";
      }
      else {
        protocol = "https";
      }*/

      let loginurl = protocol + "://{0}/rayweb/u/signin?dt=web&timestamp=" + new Date().getTime() + "&isAPD=1";
      let url = StringUtil.format(loginurl, [servername]);
      let headers = `Basic ${btoa(email + ":" + password)}`;

      const xhr = new XMLHttpRequest();

      xhr.open("GET", url, true);
      xhr.setRequestHeader("Authorization", headers);
      xhr.withCredentials = true;
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          const signinRepsondType = xhr.getResponseHeader("sign-in-repsond-type");
          resolve({
            responseData : xhr.response,
            signinRepsondType: signinRepsondType
          });
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };

      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };

      xhr.send();
    });
  }
}

export default LoginApi;
