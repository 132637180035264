import React, {Fragment} from "react";
import LocalizationStore from "LocalizationStore";
import PortalContainer from "PortalContainer";
import ONeilViewStore from "ONeilViewStore";

let _maxHistogramMaxHeight = 230;
export default class SummaryBarGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            titleXaxis: LocalizationStore.getTranslatedData('LM_CurLst', 'Current List'),
            titleYaxis: LocalizationStore.getTranslatedData('LM_Fre', 'Frequency'),
            graphNotAppicable: LocalizationStore.getTranslatedData('Symbol_graphNotAplc', 'Graph Not Applicable'),
            toolTipData: [{ headName: 'Count', value: null }, { headName: 'Min. Range', value: null }, { headName: 'Max. Range', value: null }],
            toolTipX: 0,
            toolTipY: 0,
            offsetLeft: 0,
            offsetTop: 0
        };
        this.shouldComponentUpdate = this.shouldComponentUpdate.bind(this);
        this.moveToolTip = this.moveToolTip.bind(this);
        this.hideToolTip = this.hideToolTip.bind(this);
        this.handleOnDoubleClickApplyFilter = this.handleOnDoubleClickApplyFilter.bind(this);
        this.getHistBarGraph = this.getHistBarGraph.bind(this);
        this.getHistColumnName = this.getHistColumnName.bind(this);
        this.getXAxisLabels = this.getXAxisLabels.bind(this);
    }

    shouldComponentUpdate (nextProps) {
        if (nextProps.isResizing) return false;
        return true;
    }

    showToolTip(bar, e) {
        const toolTipData = this.state.toolTipData.map((item) => {
            let dataValue = null;
            switch (item.headName) {
                case 'Count':
                    dataValue = bar.count;
                    break;
                case 'Min. Range':
                    dataValue = bar.rangeMinValue;
                    break;
                case 'Max. Range':
                    dataValue = bar.rangeMaxValue;
                    break;
                default:
                    break;
            }
            item.name = item.headName === 'Count' ? LocalizationStore.getTranslatedData('LM_SLV_SymbolCount', item.headName + ':') : LocalizationStore.getTranslatedData('LM_SLV_' + item.headName, item.headName + ':');
            item.value = dataValue;
            return item;
        })
        const { graphArea } = this.props;
        const barGraphRect = graphArea ? graphArea.getBoundingClientRect() : null;
        if (barGraphRect) {
            const x = e.pageX;
            const y = e.pageY + 25;
            const bottomOfGraphArea = barGraphRect.bottom;
            this.setState({
                isToolTipVisible: true,
                toolTipData: toolTipData,
                offsetLeft: barGraphRect.left,
                offsetTop: barGraphRect.top,
                toolTipX: x,
                toolTipY: y,
                bottomOfGraphArea: bottomOfGraphArea
            });
        }
    }

    moveToolTip (e) {
        const { isToolTipVisible, bottomOfGraphArea } = this.state;
        const x = e.pageX;
        const y = e.pageY + 25;
        const heightTooltip = 100;
        const calY = e.pageY + heightTooltip < bottomOfGraphArea + 50 ? y : y - heightTooltip;

        isToolTipVisible && this.setState({
            isToolTipVisible: true,
            toolTipX: x,
            toolTipY: calY
        });
    }

    hideToolTip () {
        const { isToolTipVisible } = this.state;
        isToolTipVisible && this.setState({
            isToolTipVisible: false,
            toolTipX: 0,
            toolTipY: 0
        });
    }

    handleOnDoubleClickApplyFilter (bar, e) {
        e.preventDefault();
        const isSharedColSet = ONeilViewStore.getIsSharedColumnSet();
        const minVal = bar.rangeMinimum;
        const maxVal = bar.rangeMaximum;
        document.body.classList.add('selectTextDisble');
        if (!isSharedColSet)
            this.props.applyColumnFilter(minVal, maxVal);
        this.setState({
            isToolTipVisible: false
        });
    }

    getHistBarGraph(isMinHeightReached, height) {
        return this.props.histData.map((bar) => {
            const newHeight = isMinHeightReached ? (bar.count * (Number(height) - 10)) / bar.maxCount : bar.barHeight;
            return (
                <rect key={`histbar-${bar.index}`} className="bar" onMouseLeave={this.hideToolTip} onMouseMove={this.moveToolTip} onMouseEnter={this.showToolTip.bind(this, bar)} onDoubleClick={this.handleOnDoubleClickApplyFilter.bind(this, bar)} x={bar.xPos} y={bar.yPos} width={bar.barWidth} height={newHeight} />
            )
        });
    }        


    getHistColumnName () {
        let histColName = LocalizationStore.getTranslatedData("DataItemExt_DisplayName_" + this.props.histColName.dataItemId, this.props.histColName.colName);
        return <div className="title-graph" title={histColName}>{histColName}</div>
    }


    getXAxisLabels (height){
        height = (Number(height) + 10).toString();
        return this.props.xAxisLabels.map((label, i) => {
            const xPos = (i === 0) ? 0 : (i * 60) + 26;
            return (<text key={`xAxisLabel-${i}`} fill='rgb(141, 141, 141)' textAnchor={i === 0 ? "start" : "end"} x={xPos} y={height} className="xx-small-normal">{label}</text>)
        })
    }
        



    render() {
        const { histData, histColName, xAxisLabels } = this.props;
        const isMinHeightReached = this.props.height < 170;
        const height = (isMinHeightReached ? this.props.height - 60 : 110).toString();
        const histBars = histData && histData.length > 1 ? this.getHistBarGraph(isMinHeightReached, height) : null;
        const isGraphApicable = histBars ? true : false;
        const histColNameLabel = histColName && isGraphApicable ? this.getHistColumnName() : null;
        const xAxisLabelsData = xAxisLabels && isGraphApicable ? this.getXAxisLabels(height) : null;
        const { graphNotAppicable, isToolTipVisible, titleYaxis, titleXaxis, toolTipData, toolTipX, toolTipY } = this.state;
        const translateY = isMinHeightReached ? Number(height) + 15 : 125;
        let yAxisTitlePos = isMinHeightReached ? Number(height) - 100 : 10;
        let yAxisFontSize =  '12px';

        if(this.props.height < 110 && this.props.height > 100) {
            yAxisFontSize = '11px';
            yAxisTitlePos = yAxisTitlePos + 5;
        }
        else if(this.props.height <= 100 && this.props.height > 95) {
            yAxisFontSize = '10px';
            yAxisTitlePos = yAxisTitlePos + 10;
        }
        else if(this.props.height <= 95 && this.props.height > 90) {
            yAxisFontSize = '9px';
            yAxisTitlePos = yAxisTitlePos + 15;
        }

        return (
            <Fragment>
                {this.props.height >= _maxHistogramMaxHeight && isGraphApicable && histColNameLabel}
                {this.props.height >= _maxHistogramMaxHeight &&
                <div className="summary-bar-graph" ref={(d) => this.barGraphArea = d} >
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" id="ListSummaryBarGraph" >
                        <g>
                            <g transform={"translate(272," + yAxisTitlePos + ")"}>
                                <text x="55" y="5" transform="rotate(90)" className="txt-head-svg-v" style={{ fontSize: yAxisFontSize, textAnchor: 'start', transform: 'rotate(90deg)', fill: 'rgb(141, 141, 141)', textTransform: 'uppercase' }}>{titleYaxis}</text>
                            </g>
                            <g transform="translate(20,0)">
                                <text y="10" dy="0" className="txt-head-svg-h xx-small-normal" style={{ textAnchor: 'start', fill: 'rgb(141, 141, 141)', textTransform: 'uppercase' }}>{titleXaxis}</text>
                            </g>

                            <rect transform="translate(20,15)" width="240" height={height} className="bar-inner-bg" />

                            {!isGraphApicable && <g transform={`translate(140,75)`}>
                                <text className="txt-head-svg-h small-normal" style={{ textAnchor: 'middle', fill: 'rgb(141, 141, 141)' }}>{graphNotAppicable}</text></g>}


                            <line transform="translate(0,17)" x1="0" y1={height} x2="280" y2={height} style={{ stroke: 'rgb(102,102,102)', strokeWidth: '1' }} />

                            {isGraphApicable && <g>
                                <g transform="translate(22,17)">
                                    <rect fill='rgb(102,102,102)' x='60px' y={height} width='1' height='5'></rect>
                                    <rect fill='rgb(102,102,102)' x='120px' y={height} width='1' height='5'></rect>
                                    <rect fill='rgb(102,102,102)' x='180px' y={height} width='1' height='5'></rect>
                                </g>
                                <g transform="translate(0,20)">
                                    {xAxisLabelsData}
                                </g>
                                <g className="bar" transform={"translate(22, " + translateY + ") scale(1, -1)"} width="240" height={height}>
                                    {histBars}
                                </g>
                            </g>
                            }
                        </g>
                    </svg>
                    {isToolTipVisible && <PortalContainer><BarTooltip x={toolTipX} y={toolTipY} toolTipData={toolTipData} /></PortalContainer>}

                </div>
                }
            </Fragment>
        );
    }
}

const BarTooltip = (props) =>
    (
        <div id="barTooltip" className="bar-tooltip" style={{ left: props.x, top: props.y }}>
            <div className="bar-stuff">
                <table>
                    <tbody>
                        {props.toolTipData.map((item, i) =>
                            (
                                <tr key={`toolTipItem-${i}`}>
                                    <td className="head-txt">  {`${item.name}`} </td>
                                    <td className="value-txt"> {item.value} </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
