import DateTimePickerDays from "./DateTimePickerDays.jsx";
import PropTypes from 'prop-types';
import { TranslateHelper } from "../../Utils/TranslateHelper.js";
import React, { Component } from "react";

export default class DateTimePickerDate extends Component {

  constructor(props) {
    super(props);
    this.setViewMonth = this.setViewMonth.bind(this);
    this.renderDays = this.renderDays.bind(this);
  }

  setViewMonth (e) {
    this.props.setViewMonth(e.target.innerHTML);
   }

  renderDays () {
      return (
        <DateTimePickerDays
          daysOfWeekDisabled={this.props.daysOfWeekDisabled}
          maxDate={this.props.maxDate}
          minDate={this.props.minDate}
          selectedDate={this.props.selectedDate}
          setSelectedDate={this.props.setSelectedDate}
          showToday={this.props.showToday}
          viewDate={this.props.viewDate}
          addYear={this.props.addYear}
          subtractYear={this.props.subtractYear}
          setViewMonth={this.setViewMonth}
          handleYearChange={this.props.handleYearChange}
          handlePinState={this.props.handlePinState}
          closePicker={this.props.closePicker}
          blockOutDates={this.props.blockOutDates}
          />
      );
    }

  render() {
    return (
      <div className="datepicker">
        {this.renderDays() }
        { this.props.isMostRecentTradeDayTb ? <span className= "isMostRecentTradeDayTb" onClick={this.props.setMostRecentTradeDate} >{TranslateHelper.MOST_RECENT_TRADING_DATE}</span> : <span className= "isMostRecentTradeDayTb" style={{cursor : "default"}}></span>}
      </div>
    );
  }
}

DateTimePickerDate.propTypes = {
  viewDate: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  showToday: PropTypes.bool,
  daysOfWeekDisabled: PropTypes.array,
  setSelectedDate: PropTypes.func.isRequired,
  subtractYear: PropTypes.func.isRequired,
  addYear: PropTypes.func.isRequired,
  setViewMonth: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  isMostRecentTradeDayTb: PropTypes.bool,
  setMostRecentTradeDate: PropTypes.func.isRequired
}
