import AnnotationUtil from '../../../Utils/AnnotationUtil';
import { DataGraphConstants } from '../../../Constants/DataGraphConstants';
import { getActiveTab } from '../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors';
import { getDatagraphStates } from '../../../Reducers/NavDataGraph/TabDataGraph/selectors';
import { populateRIPanelOneilInfo } from '../../../Actions/RIPanelOneilActions';
import { RiPanelConstants } from '../../../Constants/RiPanelConstants';
import RiSubCategoryType from '../../../Constants/RiSubCategoryType';
import { SettingsConstants } from '../../../Constants/SettingsConstants';
import StringUtil from '../../../Utils/StringUtil';
import SymbolType from '../../../Constants/SymbolType';
import { clearSelectedAnnotation, updateRiPanelInfoFromUserSettings } from '../../../Actions/AnnotationActions';
import { put, takeLatest, select } from 'redux-saga/effects';

const { ActionTypes } = RiPanelConstants;

function* initRiPanel() {
    try {
        const { SymbolInfo, SymTypeEnum } = yield select(getDatagraphStates);
        const activeTab = yield select(getActiveTab);
       

        switch (activeTab) {
            case RiSubCategoryType.News:
                yield put({
                    type: ActionTypes.GET_NEWS_INFO,
                    symbolInfo: SymbolInfo
                });
                break;

            case RiSubCategoryType.BrokerReports:
                yield put({
                    type: ActionTypes.GET_BROKER_REPORTS,
                    symbolInfo: SymbolInfo
                });
                break;

            // case RiSubCategoryType.Annotations:
            //     break;

            case RiSubCategoryType.LlrView:
                yield put(populateRIPanelOneilInfo());
                break;

            case RiSubCategoryType.Ownership:
                yield put({
                    type: ActionTypes.GET_OWNERSHIP_INFO,
                    symbolInfo: SymbolInfo
                })
                yield put({
                    type: ActionTypes.ONWERSHIP_INFO_GET_HUB_PANEL,
                    symbolInfo: SymbolInfo
                })
                // RIPanelOwnershipStore.setDefaultState();
                // RIPanelOwnershipStore.getOwnershipDataSource();
                // RIHubPanelStore.changeToTopOwnerView();
                // RIHubPanelStore.getHubDataSource();
                break;

            case RiSubCategoryType.CheckList:
                yield put({
                    type: ActionTypes.INIT_CHECKLIST_INFO,
                    symbolInfo: SymbolInfo
                });
                break;

            case RiSubCategoryType.Holdings:
                yield put({
                    type: ActionTypes.INIT_HOLDINGS_TAB,
                    symbolInfo: SymbolInfo
                });
                break;

            case RiSubCategoryType.SimilarFunds:
                yield put({
                    type: ActionTypes.INIT_SIMILAR_FUNDS,
                    symbolInfo: SymbolInfo
                });
                break;

            case RiSubCategoryType.Management:
                yield put({
                    type: ActionTypes.GET_MANAGEMENT_INFO,
                    symbolInfo: SymbolInfo                    
                })
                // RIPanelManagementStore.setDefaultState();
                // RIPanelManagementStore.emitChange();
                // RIPanelManagementStore.getManagementData();
                break;

            case RiSubCategoryType.Allocation:
                yield put({
                    type: ActionTypes.GET_ALLOCATION_INFO,
                    symbolInfo: SymbolInfo
                })
                break;

            case RiSubCategoryType.Constituents:
                yield put({
                    type: ActionTypes.INIT_CONTITUENTS_TAB,
                    symbolInfo: SymbolInfo
                });
                break

            // case RiSubCategoryType.Factor:
            //     break;

            // case RiSubCategoryType.ExternalData:
            //     break;

            default:
                break;
        }
        if(SymTypeEnum !== SymbolType.FUND){
            yield put(clearSelectedAnnotation());
            yield put(updateRiPanelInfoFromUserSettings());
    
            // If Annotations tab is active then all the underlying pricechart mouse events must be blocked
            const annotationDiv = document.getElementsByClassName('annotation-graphics')[0];
            if (!StringUtil.isEmpty(annotationDiv)) {
                if (activeTab === RiSubCategoryType.Annotations && !AnnotationUtil.isPointerSelected) {
                    annotationDiv.className = "annotation-graphics";
                    annotationDiv.style.zIndex = 10;
                }
                else {
                    if (!annotationDiv.classList.contains("selected-selectiontool")) {
                        annotationDiv.className += " selected-selectiontool";
                        annotationDiv.style.zIndex = "";
                    }
                }
            }
        }
        
    } catch (error) {
        console.log(`Error occurs in RiPanelSaga.js, initRiPanel ${error}`, error);
    }
}

/*** Watchers ***/

export function* watchInitRipanel() {
    yield takeLatest(SettingsConstants.ActionTypes.SYMBOL_CHANGE_EVENT, initRiPanel);
}
export function* watchRipanelTabChange() {
    yield takeLatest(ActionTypes.HANDLE_TAB_CHANGE, initRiPanel);
}