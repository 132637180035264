import { getRiPanelHoldingStates } from "../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors";
import GraphApi from "../../../ServiceApi/Apis/GraphApi";
import { HoldingsTranslateHelper } from "../../../Utils/TranslateHelper";
import MathUtil from "../../../Utils/MathUtil";
import MiniListHelper from "../../../Utils/MiniListHelper";
import MiniListStore from "../../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import { call, put, select, takeLatest } from "redux-saga/effects";



function* getHoldingsData({ symbolInfo }) {
    try {
        const { holdingsDataList } = yield select(getRiPanelHoldingStates);
        if(holdingsDataList.length === 0){
            const isMiniListPlay = MiniListStore.getMiniListPlayingStatus();
            const result = yield call(GraphApi.GetFundHoldingsDataRequest, symbolInfo.Symbol);
    
            if (MiniListHelper.ActiveSymbolCheck(symbolInfo.Symbol, isMiniListPlay)) {
                const holdingsDataList = [];
                holdingsDataList.push({
                    tag: HoldingsTranslateHelper.TOP_5_LARGEST_HOLDING,
                    holdingData: getHoldingData(result.topLargestHoldings)
                });
                holdingsDataList.push({
                    tag: HoldingsTranslateHelper.TOP_NEW_HOLDINGS,
                    holdingData: getHoldingData(result.topNewHoldings)
                });
                holdingsDataList.push({
                    tag: HoldingsTranslateHelper.TOP_5_ADDED_HOLDINGS,
                    holdingData: getHoldingData(result.topAddedHoldings)
                });
                holdingsDataList.push({
                    tag: HoldingsTranslateHelper.TOP_5_REDUCED_HOLDINGS,
                    holdingData: getHoldingData(result.topReducedHoldings)
                });
    
                yield put({
                    type: RiPanelConstants.ActionTypes.UPDATE_HOLDINGS_DATA_LIST,
                    holdingsDataList,
                    quarterEnd13FDate: HoldingsTranslateHelper.AS_OF + result.quarterEnd13FDate,
                    loading: false,
                    isNoDataAvailable: result.topReducedHoldings.length + result.topAddedHoldings.length + result.topNewHoldings.length + result.topLargestHoldings.length === 0
                })
            }
        }
    }
    catch (error) {
        console.log(`Error occured in RiPanelHoldings.js, getHoldingsData ${error}`);
    }
}

function getHoldingData(holdings) {
    holdings.forEach((holding) => {
        holding.amountString = holding.amount >= 0 ? `$${MathUtil.formatMoney((holding.amount / 1000000), 1, 3)}` : `$${MathUtil.formatMoney((-1 * holding.amount / 1000000), 1, 3)}`;
        holding.pctOfPortString = `${holding.pctOfPort.toFixed(1)}%`;
        holding.pctOfChangeString = holding.pctOfChange === 0 ? "--" : holding.pctOfChange > -100 && holding.pctOfChange < 100 ? `${holding.pctOfChange.toFixed(1)}%` : `${holding.pctOfChange.toFixed(0)}%`;
    });
    return holdings;
}

/*************Watchers *****************/

export function* watchInitHoldingsData() {
    yield takeLatest(RiPanelConstants.ActionTypes.INIT_HOLDINGS_TAB, getHoldingsData)
}