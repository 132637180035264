import AppDispatcher from "AppDispatcher";
import { dispatch } from "../Redux/dispatch";
import { convertTranslateStaticString } from "../Utils/TranslateHelper";
import LocalizationApi from "../ServiceApi/Apis/LocalizationApi";
import { LocalizationConstants } from "../Constants/LocalizationConstants";
import LocalizationStore from "LocalizationStore";
import { updateLanguage } from "./CalenderControl/CalenderControlAction";
import UserInfoUtil from "UserInfoUtil";

class LocalizationActionClass {
    async getLangResource() {
        const langCode = UserInfoUtil.getCurrentLangCode();
        LocalizationStore.setLang(UserInfoUtil.getCurrentLang());
        dispatch(updateLanguage())
        if (langCode !== 'en-us') {
            await LocalizationApi.getLangResourceData(langCode).then((result) => {
                const data = JSON.parse(result.langDictData);
                AppDispatcher.handleViewAction({
                    actionType: LocalizationConstants.ActionTypes.SET_TRANSLATE,
                    data: data
                });
            });
            convertTranslateStaticString()
        } else {
            setTimeout(() => {
                AppDispatcher.handleViewAction({
                    actionType: LocalizationConstants.ActionTypes.SET_TRANSLATE,
                    data: {}
                });
            }, 0);
            convertTranslateStaticString()
        }
    }
}

const LocalizationActions = new LocalizationActionClass();

export default LocalizationActions;
