import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetEPSSnapshotsRequest extends BaseServiceRequest {

    constructor(symbol, msId, startDt, endDt, periodicity, isWON, fcQuarters) {
        super();
        super.addPathParameter(msId.toString());
        super.addRequestUri("epssnapshots.pb"); 
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("start", startDt);
        if (endDt) {
            super.addRequestParameter("end", endDt);
        }
        super.addRequestParameter("p", periodicity.toString());
        super.addRequestParameter("t", isWON.toString());
        super.addRequestParameter("q", fcQuarters.toString());
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "EPSSnapshotsResponse";
    }
}

export default GetEPSSnapshotsRequest;
