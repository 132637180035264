import { ComparisonGraph } from "../Constants/ComparisonGraph";
const { ActionTypes } = ComparisonGraph;
/*Example Actions */
export const errorOccured = (id) => ({
    type: ActionTypes.ERROR,
    id
});

export const saveSettings = () => ({
    type: ActionTypes.SAVE_SETTINGS
})

export const onDimensionsChange = (dimension) => ({
    type: ActionTypes.ON_DIMENSION_CHANGE,
    dimension
});

export const toggleToolTip = (toolTip) => ({
    type: ActionTypes.TOGGLE_TOOL_TIP,
    toolTip
});


export const toggleCalendar = (event, isSetSelectedDateActive) => ({
    type: ActionTypes.TOGGLE_CALENDAR,
    event,
    isSetSelectedDateActive
});

export const subtractYear = () => ({
    type: ActionTypes.SUBTRACT_YEAR,
});

export const addYear = () => ({
    type: ActionTypes.ADD_YEAR
});

export const setSelectedDate = (data) => ({
    type: ActionTypes.SET_SELECTED_DATE,
    data
});

export const setInputValue = (data) => ({
    type: ActionTypes.SET_INPUT_VALUE,
    data
});

export const setSelectedYear = (data) => ({
    type: ActionTypes.SET_SELECTED_YEAR,
    data
});

export const updateInterval = (data) => ({
    type: ActionTypes.UPDATE_INTERVAL,
    data
});

export const handleYearChangeOnEnterKey = (data) => ({
    type: ActionTypes.UPDATE_YEAR_ON_ENTER,
    data
})

export const setViewMonth = (month) => ({
    type: ActionTypes.SET_VIEW_MONTH,
    month
});

export const resetCalendar = (setLoading=false) => ({
    type: ActionTypes.RESET_CALENDAR,
    setLoading
})

export const updateInput = (data) => ({
    type: ActionTypes.UPDATE_INPUT_VALUE,
    data
});

export const handleInputChange = (input, isUserEdit) => ({
    type: ActionTypes.HANDLE_INPUT_CHANGE,
    input,
    isUserEdit
});


export const setMostRecentTradeDate = () => ({
    type: ActionTypes.SET_MOST_RECENT_TRADE_DATE
})


export const toggleEditMode = (id, itemValue) => ({
    type: ActionTypes.TOGGLE_EDIT_MODE,
    id,
    itemValue
});


export const setError = (hasErrors, errorMsg) => ({
    type: ActionTypes.SET_ERROR,
    hasErrors,
    errorMsg
});

export const initCompChart = (reDraw = false) => ({
    type: ActionTypes.INIT_COMP_CHART,
    reDraw
});

export const changeScaleBase = () => ({
    type: ActionTypes.CHANGE_SCALE_BASE_VALUE
});

export const changeScaleType = () => ({
    type: ActionTypes.CHANGE_SCALE_TYPE_VALUE
});

export const onRefresh = () => ({
    type: ActionTypes.HANDLE_REFRESH
});

export const gridLineColorChange = () => ({
    type: ActionTypes.GRID_LINE_COLOR_CHANGE
});