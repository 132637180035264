import React, { PureComponent } from 'react';
import { HederCell } from '../Presentational';
import { toggleSort } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { connect } from 'react-redux';
import CompareTabType, { CompareMenuType } from "CompareTabType";
import LocalizationStore from 'LocalizationStore';

class MenuHeder extends PureComponent {

  toggleSortPriceCHG = (e) => {
    e.stopPropagation();
    const { sortType } = this.props;
    const updatedSortType = sortType === 'asc' ? 'dsc' : 'asc';
    this.props.toggleSortPriceCHG(updatedSortType);
  }

  render() {
    const { headersMenu, sortType, selectedTab } = this.props;

    return (
      <React.Fragment>
        {selectedTab == CompareTabType.ComparisonGraph ? <div className="graph-header">
          {headersMenu.map((header, key) => <HederCell key={key} {...header} sortType={header.isClickable ? sortType : undefined} toggleOnClick={header.isClickable ? this.toggleSortPriceCHG : undefined} />)}
        </div> :
          <div className="graph-header">
            <div className="graph-header-column comp-grid-header"></div>
            <div className="graph-header-column comp-grid-header">{LocalizationStore.getTranslatedData("CCG_SYMBOL", "SYMBOL")}</div>
            <div className="graph-header-column comp-grid-header">{LocalizationStore.getTranslatedData("CCG_NAME", "NAME")}</div>
            <div className="graph-header-column comp-grid-header"></div>
          </div>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const compMenu = state.comparisonGraph.compareMenu;

  const { headersMenu, sortType } = compMenu.selectedMenuTab == CompareMenuType.Symbols ? compMenu.SymbolsData : compMenu.GroupsData;
  return { headersMenu, sortType, selectedTab };
};

const mapDispatchToProps = (dispatch) => ({
  toggleSortPriceCHG: (sortType) => dispatch(toggleSort(sortType))
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuHeder);




