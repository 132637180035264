import React, { Component } from 'react';
import CurrencyListData from './CurrencyListData.jsx';

export default class CurrencyList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { currencyrow, symbolName, selectedCurrencyCode } = this.props;
        return (
            <div data-test-id="main-div" >
                <span className="symbol-alphabet x-small-bold">{symbolName}</span>
                {
                    currencyrow.map((symbolrow, key) =>
                        <CurrencyListData
                            key={key}
                            symbolrow={symbolrow}
                            selectedCurrencyCode={selectedCurrencyCode}
                        />
                    )
                }
            </div>
        );
    }
}
