import CalendarEvents from "./CalendarEvents";
import classnames from "classnames";
import DateTimePickerDate from "./DateTimePickerDate.jsx";
import onClickOutside from "react-onclickoutside";
import PropTypes from 'prop-types';
import React, { Component } from "react";
// import DateTimePickerTime from "./DateTimePickerTime.jsx";
// import Constants from "./Constants.js";

class DateTimePicker extends Component {

  constructor(props) {
    super(props);
    this.renderDatePicker = this.renderDatePicker.bind(this);
  
  }

  renderDatePicker () {
    if (this.props.showDatePicker) {
      return (
        <li>
          <DateTimePickerDate
            addYear={this.props.addYear}
            daysOfWeekDisabled={this.props.daysOfWeekDisabled}
            maxDate={this.props.maxDate}
            minDate={this.props.minDate}
            selectedDate={this.props.selectedDate}
            setSelectedDate={this.props.setSelectedDate}
            setViewMonth={this.props.setViewMonth}
            showToday={this.props.showToday}
            subtractYear={this.props.subtractYear}
            viewDate={this.props.viewDate}
            handleYearChange={this.props.handleYearChange}
            isMostRecentTradeDayTb={this.props.isMostRecentTradeDayTb}
            setMostRecentTradeDate={this.props.setMostRecentTradeDate}
            handlePinState={this.props.handlePinState}
            closePicker={this.props.closePicker}
            blockOutDates={this.props.blockOutDates}
          />
        </li>
      );
    }
  }

  handleClickOutside(event) {
    try {
      this.props.closePicker(false, event);
    }
    catch (ex) {
      console.log(ex);
    }
  }

  render() {
    const year = this.props.viewDate.year();
    const openDialogClass = this.props.showDatePicker ? "dialog-open" : ""
    return (
      <div>
      <div className={`${classnames(this.props.widgetClasses)} ${openDialogClass}`} style={this.props.widgetStyle}>
       
        <ul className="list-unstyled symbol">

          {this.renderDatePicker()}

        </ul>
        {this.props.showPicker && <CalendarEvents keydates={this.props.keydates} year={year} handleClick={this.props.handleClick} />}  
    
      </div>
        </div>

    );
  }
}

DateTimePicker.propTypes = {
  showDatePicker: PropTypes.bool,
  viewDate: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  showToday: PropTypes.bool,
  daysOfWeekDisabled: PropTypes.array,
  setSelectedDate: PropTypes.func.isRequired,
  addYear: PropTypes.func.isRequired,
  setViewMonth: PropTypes.func.isRequired,
  //closePicker: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  isMostRecentTradeDayTb: PropTypes.bool,
  //setMostRecentTradeDate: PropTypes.func.isRequired
}

export default onClickOutside(DateTimePicker);