import React, { PureComponent } from "react";
import { toggleLiqFilter } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { Button } from "react-bootstrap";
import LocalizationStore from "../../../../../Stores/Localization/LocalizationStore.js";
import { connect } from 'react-redux';
import CompareTabType, { CompareMenuType } from "CompareTabType";

class LiquidityAndRatingsFilterButton extends PureComponent {

  constructor() {
    super();
    this.openLiquidityRatings = this.openLiquidityRatings.bind(this);
  }


  openLiquidityRatings = () => {
    const { openLiquidityRatings } = this.props;
    if (!openLiquidityRatings) {
      this.props.toggleLiqFilter(true);
    }
  }

  render() {
    const { isFilterOn, isFilterManualOff } = this.props;
    const liqFilterIcnClass = isFilterOn && !isFilterManualOff ? "icn-compear-filter active" : "icn-compear-filter";
    return (
      <Button bsSize="small" onClick={() => this.openLiquidityRatings()}>
        <span className={liqFilterIcnClass}></span>
        <span className="pos-relative">{LocalizationStore.getTranslatedData("CCG_Lqf_1", "LIQUIDITY & RATINGS FILTERS")}</span>
      </Button>
    );
  }
}

const mapStateToProps = (state) => {
  const selectedTab = state.navCompareReducer.selectedTab;
  const liqFilterData = state.comparisonAndRotationMenuPanel.LiqAndRatingFilterReducer;

  if (selectedTab === CompareTabType.ComparisonGraph) {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.ComparisonMenuTab;
    const { openLiquidityRatings, isFilterOn, isFilterManualOff } = selectedMenuTab == CompareMenuType.Symbols ? liqFilterData.ComparisonMenuStates.Symbols : liqFilterData.ComparisonMenuStates.Groups;

    return { openLiquidityRatings, isFilterOn, isFilterManualOff };
  }
  else {
    const selectedMenuTab = state.comparisonAndRotationMenuPanel.MenuTabReducer.RotationMenuTab;
    const { openLiquidityRatings, isFilterOn, isFilterManualOff } = selectedMenuTab == CompareMenuType.Symbols ? liqFilterData.RotationMenuStates.Symbols : liqFilterData.RotationMenuStates.Groups;

    return { openLiquidityRatings, isFilterOn, isFilterManualOff };
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    toggleLiqFilter: (isOpen) => dispatch(toggleLiqFilter(isOpen))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiquidityAndRatingsFilterButton);
