import { ActionTypes } from "../../Constants/ScheduledListsConstants";
import BaseServiceApi from 'BaseServiceApi';

let initState = {
    formData: null,
    list: [],
};

export default function scheduledLists(state = initState, action) {

    let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
    switch (action.type) {
        case ActionTypes.GET_SCHEDULED_LISTS:
            return { ...state, list: action.list };
        case ActionTypes.DELETE_SCHEDULED_LIST:
            let list = state.list;
            _.remove(list, (item) =>  item.screenId === action.screenId );
            return { ...state, list: list }
        case ActionTypes.INIT_SCHEDULED_LIST_FORM_DATA:
            //remove blocked lists.
            _.remove(action.formData.universe, (item)=>
                _.find(action.formData.blockLstIds, (blckItm)=>
                        blckItm.low === item.nodeId.low));
                        
             //remove lists are not available to scheduled lists.         
            _.remove(action.formData.universe, (item) => {
                let categoryType = item.categoryType;
                return (categoryType === ListCategoryType.SNAPSHOTLIST_ListCategory
                    || categoryType === ListCategoryType.SHAREDLIST_ListCategory
                    || categoryType === ListCategoryType.SMARTLIST_ListCategory
                    || categoryType === ListCategoryType.MORGUELIST_ListCategory
                    || categoryType === ListCategoryType.MODELLIST_ListCategory
                    || categoryType === ListCategoryType.DATABASELIST_ListCategory
                    || categoryType === ListCategoryType.TEMPORARYLIST_ListCategory
                    || categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory)
                });
           
            return { ...state, formData: action.formData }
        case ActionTypes.UPSERT_SCHEDULED_LIST:
            //because we will cose the window directly after upsert. We don't need to update the list array. after update/insert
            return { ...state }
        default:
            return state;
    }

}