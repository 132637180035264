import React, {Fragment} from 'react';

import { filter, find } from "underscore";
import { Table } from "react-bootstrap";
import ScrollBar from "ScrollBar";

import { UpdateSharedData } from "../../../../Actions/ShareAction";
import SelectAll from "./SelectAll.jsx";
import ICSData from "./ICSData.jsx";
import BoldText from "./BoldText.jsx";

const RecepientsData = ({ sharedData, userData, nameFilter, savedSharedData }) => {
    const ICSrow = userData && userData.sharedUsers &&
        filter(userData.sharedUsers, user => user.isICS);

    const searchedData = userData && userData.sharedUsers
        .filter((users) =>
            String(users.userName).toLocaleLowerCase()
                .indexOf(String(nameFilter).toLocaleLowerCase()) !== -1 ||
            String(users.organization).toLocaleLowerCase()
                .indexOf(String(nameFilter).toLocaleLowerCase()) !== -1 ||
            String(users.email).toLocaleLowerCase()
                .indexOf(String(nameFilter).toLocaleLowerCase()) !== -1
        );
  
    const unSavedRecipientData = savedSharedData && savedSharedData.length > 0 && searchedData && searchedData.length > 0
        ? filter(searchedData, user =>
            !find(savedSharedData, rowData =>
                rowData.recipientId.low === user.recipientId.low))
        : searchedData

    // const ICSrow = filter(unSavedRecipientData, user => user.isICS);

    const filteredData = filter(unSavedRecipientData, user => !user.isICS);

    const icsRowInsharedData = ICSrow && ICSrow.length > 0 && filter(sharedData, user => user.isICS);

    const isICSrowChecked = icsRowInsharedData && icsRowInsharedData.length > 0;

    const isICSExistInSavedData = savedSharedData && savedSharedData.length > 0 &&
        find(savedSharedData, rowData =>
            rowData.recipientId.low === ICSrow[0].recipientId.low)


    const handleSelectRecepient = (user) => (event) => {
        const rowData = {
            accessLvl: 1,
            email: user.email,
            isICS: false,
            isShared: true,
            organization: user.organization,
            recipientId: user.recipientId,
            userName: user.userName,
            isModified: true,
            isAdded: true
        }
        if (event.target.checked) {
            UpdateSharedData(sharedData.concat(rowData));
        } else {
            const filterData = sharedData.filter((item) => item.recipientId.low !== rowData.recipientId.low);
            UpdateSharedData(filterData);
        }
    }

    return (
        <Fragment>
            <div className="select-type" id="report-head">
                {ICSrow && ICSrow.length > 0 && !isICSExistInSavedData &&
                    <ICSData
                        ICSrow={ICSrow}
                        isICSrowChecked={isICSrowChecked}
                        sharedData={sharedData}
                    />
                }
            </div>
            <div className="select-type" id="report-all-select">
                {filteredData && filteredData.length > 0 &&
                    <SelectAll
                        filteredData={filteredData}
                        sharedData={sharedData}
                        savedSharedData={savedSharedData}
                        icsRowInsharedData={icsRowInsharedData}
                    />
                }
            </div>
            <div id="report-all-list" className="share-page-scroll custom-scroll-light">
                <div className="custom-scroll">
                    <div className="share-recipient-scroller" id="shareVerticalScroll1">
                        <Table className="share-check-list">
                            {filteredData && filteredData.length > 0
                                &&
                                <tbody>
                                    {filteredData.map((user, index) =>
                                        <tr key={index}>
                                            <td className='user-name'>
                                                <input  type='checkbox'
                                                        checked={
                                                            find(sharedData, rowData =>
                                                                rowData.recipientId.low === user.recipientId.low
                                                            )
                                                        }
                                                        onChange={handleSelectRecepient(user)}
                                                />
                                                <label className="share-value" title={user.userName}>
                                                    {
                                                        <BoldText text={user.userName} nameFilter={nameFilter} />
                                                    }
                                                </label>
                                            </td>
                                            <td className='user-orgnization'>
                                                <span className="share-value" title={user.organization}>
                                                    {
                                                        <BoldText text={user.organization} nameFilter={nameFilter} />
                                                    }
                                                </span>
                                            </td>
                                            <td className='user-email'>
                                                <span className="share-value" title={user.email}>
                                                    {
                                                        <BoldText text={user.email} nameFilter={nameFilter} />
                                                    }
                                                </span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                // :
                                // <tbody>
                                //     <tr>
                                //         <td colSpan='4'>
                                //             This item has been shared with all recipients.
                                //             </td>
                                //     </tr>
                                // </tbody>
                            }
                        </Table>
                    </div>
                </div>
                <ScrollBar scrollId="shareVerticalScroll1" vScroll={true} />
            </div >
        </Fragment>
    );
}
export default RecepientsData;