import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetNewsRequest extends BaseServiceRequest {
    constructor(symbol, sourceIds, exchange) {
        super();
        super.addRequestUri("news.pb");
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("srcids", sourceIds);
        super.addRequestParameter("exch", exchange);

    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "NewsResponse";
    }
}

export default GetNewsRequest;
