import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequestIIS from 'BaseServiceRequestIIS';

class GetDdeParametersNasdaqBasicRequest extends BaseServiceRequestIIS {
  constructor(appversion, isNasdaqentitled, isBatsentitled) {
    super();
    super.addRequestUri("GetDdeParametersNasdaqBasic");
    super.addPathParameter("appversion", appversion);
    super.addPathParameter("isNasdaqentitled", isNasdaqentitled);
    super.addPathParameter("isBatsentitled", isBatsentitled);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GRAPH;
  }

  getMessageName() {
    return undefined;
  }
}
export default GetDdeParametersNasdaqBasicRequest;