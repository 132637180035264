import React, { Fragment } from "react";
import DropdownMenu from "./Dropdown.jsx";
import ListActions from "ListActions";
import {saveScreenedResults} from "../../../../Actions/OwnerShipActions";
import { ListExplorerConstants } from "ListExplorerConstants";
import ListManagerTabType from "ListManagerTabType";
import StringUtil from "StringUtil";
import ListExplorerStore from "ListExplorerStore";
import ListStore from "ListStore";
import BaseServiceApi from 'BaseServiceApi';
import BrowserUtil from "BrowserUtil";
import {
  getExplorerUploaderWindowWidth
} from "../../../../Utils/ExternalDataUploaderUtil.js";
import ContextMenuConstants from "ContextMenuConstants";
import KeyCodes from "KeyCodes";
import ONeilViewStore from "ONeilViewStore";
import OwnershipViewStore from "OwnershipViewStore";
import ListExplorerUtil from "ListExplorerUtil";
import ConsoleStore from "ConsoleStore";
import LocalizationStore from 'LocalizationStore';
import DGContextMenu from "../../../../RayCustomControls/ContextMenu/DGContextMenu.jsx";
import CommonDialog from "../../../../RayCustomControls/CommonDialog/CommonDialog";
import AlertActions from "../../../../Actions/AlertActions.js";
import PortalContainer from "PortalContainer";
import {
  saveDuplicateNode,
  saveDeleteNode,
  createNewList,
  deleteSelectedItem,
  createNewExternalList,
  setDeleteFolder,
  setFolderActiveNode
} from '../../../../Actions/ListViewActions';
import { loadExternalList, nodeSelection,enableCreateList, createListEmptyList, setDuplicateNode, removeDeletedLists, 
  setSelectedNodes,sortExternalList,findNextFolder } from '../../../../Actions/ExternalDataUploaderActions';
import { dispatch } from "../../../../Redux/dispatch.js";

import { folderLimitReached, getParentId  } from "Utils/OwnerShipUtil"
import store from "../../../../Redux/store.js";
import AlertConditionType from "../../../../Constants/AlertConditionType";
import AlertsType from "../../../../Constants/AlertsType";
let ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
let ListType = BaseServiceApi.rayData["ListType"];


export default class ExplorerBottom extends React.Component {
  constructor(props) {
    super(props);
    this.addNewItem = this.addNewItem.bind(this);
    this.duplicateItem = this.duplicateItem.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.openAlert = this.openAlert.bind(this);
    this.createListExternalUpload = this.createListExternalUpload.bind(this);
    this.duplicateItemExternalUpload = this.duplicateItemExternalUpload.bind(this);
    this.handleTempDiv = this.handleTempDiv.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleBlurExplorerUpload = this.handleBlurExplorerUpload.bind(this);
    this.createTempTreeNode = this.createTempTreeNode.bind(this);
    this.handleOnKeyup = this.handleOnKeyup.bind(this);
    this.saveScreenedResults = this.saveScreenedResults.bind(this);
    this.openPriceAlertContextMenu = this.openPriceAlertContextMenu.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);

    this.state = { showDropdown: false, showExternalDropdown: false }
    this.keyCodePressed = null;
    this.textLimit = 150;
  }
  addNewExternalItem = (type) => {
    this.props.store.dispatch(createNewExternalList(type,this.props.selectedTabKey));
  }
  addNewItem(type, categoryType) {
    if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
      const parentNode = ListExplorerStore.getValidParentItem(categoryType);
      const isFolder = (type === 'NewList') ? false : true;
      if (!StringUtil.isEmpty(parentNode)) {
        const categoryId = parentNode.categoryType;
        let parentNodeId = parentNode.nodeId;
        parentNodeId = typeof (parentNode.nodeId) === 'object' ? parentNode.nodeId.low : parentNode.nodeId;
        this.setState({ addNewItem: type, categoryId: categoryId, parentNodeId: parentNodeId });

        //Allow user to create 5 nested folders
        if (type === 'NewFolder' && ListExplorerStore.folderLimitReached(parentNode)) {
          return this.props.showValidationWindow();
        }

        //Searching the node for append
        const elm = ListExplorerUtil.getElementByQuery(`a[data-node-id='${  parentNodeId  }']`);
        const appendedArea = elm.parentNode.parentNode.parentNode.parentNode.childNodes[1];

        ListExplorerStore.ExpandItemLevel(categoryId, parentNodeId, true);

        //creating userInput box
        const newSpan = document.createElement('span');
        newSpan.setAttribute("style", "margin-left: 7px;");
        let classFolderOrFile = '';
        (type === 'NewList') ? classFolderOrFile = 'fa icn-fa-file-text-userlist addListInput-class ' : classFolderOrFile = 'fa icn-fa-file-folder addFolderInput-class ';

        const newI = document.createElement('i');
        newI.setAttribute('class', classFolderOrFile);
        const input = document.createElement("input");

        input.maxLength = this.textLimit;
        input.style.minWidth = '6px';
        input.style.maxWidth = ListExplorerUtil.getListExplorerWindowWidth(false, parentNodeId, isFolder, parentNode.listTreeItemLevel);
        input.style.color = "black";
        input.type = "text";
        input.autofocus = 'true';
        input.onblur = this.handleBlur;
        input.onkeyup = this.handleOnKeyup;
        input.onkeydown = this.handleOnKeyDown;
        input.id = "create";
        input.className = `${this.props.fontSizeClass  } addListClass clearButton`;

        const currentName = LocalizationStore.getTranslatedData('LM_TP_Untitled_1', 'Untitled {0}');
        const defaultValueForInput = (type === 'NewList') ? currentName.replace('{0}', LocalizationStore.getTranslatedData('LM_TP_list', 'List')) : currentName.replace('{0}', LocalizationStore.getTranslatedData('LM_TP_Folder', 'Folder'));

        input.defaultValue = ListExplorerStore.validateListName(defaultValueForInput, parentNodeId, null, isFolder ? ListType.FOLDER_List : ListType.LIST_List);

        input.setAttribute('size', input.defaultValue.length);

        newSpan.appendChild(newI);
        newSpan.appendChild(input);
        appendedArea.insertBefore(newSpan, appendedArea.firstChild);
        input.select();

        //Get/Set Scrollbar position
        if (!StringUtil.isEmpty(parentNode)) {
          let selectedNodeElm = null
          selectedNodeElm = document.getElementById(parentNodeId);
          ListExplorerUtil.setScrollPosition(selectedNodeElm);
        }

        return this.props.addNewItem(type, categoryType);
      }
    }
    else {
      const state = store.getState()
      const parentNode = getParentId(state.listView.activeFolderID, state.listView.dataSource.list)
      if (type === 'NewFolder' && folderLimitReached(parentNode)) {
        return this.props.showValidationWindow();
      }
      this.props.store.dispatch(createNewList(type, categoryType, this.props.selectedTabKey));
    }
  }

  createTreeNode(event) {
    let dbType = ListExplorerStore.getSelectedDBType();
    const categoryType = this.state.categoryId;
    let nodeType = (this.state.addNewItem === 'NewList') ? nodeType = 1 : nodeType = 2;
    let listName = !StringUtil.isEmpty(StringUtil.trim(event.target.value)) ? StringUtil.trim(event.target.value) : event.target.defaultValue;
    listName = (listName === undefined) ? LocalizationStore.getTranslatedData('LM_TP_Untitled_1', 'Untitled {0}').replace('{0}', LocalizationStore.getTranslatedData('LM_TP_list', 'List')) : listName;

    listName = ListExplorerStore.validateListName(listName, this.state.parentNodeId, null, nodeType);
    if (this.props.selectedTabKey === ListManagerTabType.Ownership) dbType = 2;

    const myList = {
      dbType: dbType,
      parentNodeId: this.state.parentNodeId,
      name: listName,
      nodeType: nodeType,
      categoryType: categoryType,
      sourceListId: null
    };
    listName === null;
    ListActions.getCreateTreeNode(myList, this.state.nodeId);

    //Log Usage
    (nodeType === ListType.FOLDER_List) ?
      ConsoleStore.trackUsage("NewFolderCreated", '', [ListExplorerStore.getListDatabaseName()]) :
      ConsoleStore.trackUsage("NewListCreated", '', [ListExplorerStore.getListDatabaseName()])
  }

  handleBlur(e) {
    if (this.keyCodePressed !== 13 && e.type === 'blur') {
      BrowserUtil.disableEvents();
      this.createTreeNode(e);
      e.target.parentNode.parentNode.removeChild(e.target.parentNode);
      this.keyCodePressed = null;
    }
  }
  handleTempDiv(e) {
    const tempDataNodeId = e.target.parentNode.parentNode.getAttribute("data-node-id");
    dispatch(loadExternalList(tempDataNodeId));
  }

  createTempTreeNode(e, actionType = "add") {
    if (actionType == "add") {
      let nodesToSearch = ListStore.getExternalDataSetList;
      let listName = ListExplorerStore.validateExternalListName(nodesToSearch, StringUtil.trim(e.target.value));
      if(StringUtil.isEmpty(listName)){
        listName = e.target.defaultValue;
      }
        dispatch(createListEmptyList(listName));
        dispatch(enableCreateList(false));
    }
  }


  handleOnKeyup(e) {
    if (KeyCodes.ENTER === e.keyCode) {
      this.keyCodePressed = e.keyCode;
      if (this.props.selectedTabKey == ListManagerTabType.External) {
        this.createTempTreeNode(e, "add");
      }
      else {
        this.createTreeNode(e);
      }
      e.target.parentNode.parentNode.removeChild(e.target.parentNode);
      this.keyCodePressed = null;
    }
  }
  handleBlurExplorerUpload(e) {
    if (this.keyCodePressed != 13 && e.type == 'blur') {
      this.createTempTreeNode(e, "add");
      e.target.parentNode.parentNode.removeChild(e.target.parentNode);
      if(!StringUtil.isEmpty(e.target.value))
      BrowserUtil.disableEvents();
      this.keyCodePressed = null;
    }
  }
  handleOnKeyDown(e) {
    const self = e.target;
    self.size = (self.value.length > 1) ? self.value.length : 1;
  }


  createListExternalUpload() {
    dispatch(enableCreateList(true));
    dispatch(nodeSelection(''));
    if(this.props.undoActionObj && this.props.undoActionObj.action == ListExplorerConstants.ActionTypes.UNDO_EXTERNAL_LIST)
    ListActions.isDeleteUndoExistExternal();
    let elm = document.getElementsByClassName("listExplorerClass")[0];
    let appendedArea = elm;
    let newSpan = document.createElement('span');
    newSpan.setAttribute("style", "margin-left: 21px;");
    let classFile = '';
    classFile = 'fa icn-fa-file-text-userlist addListInput-class ';

    let newI = document.createElement('i');
    newI.setAttribute('class', classFile);
    let input = document.createElement("input");

    input.maxLength = this.textLimit;
    input.style.minWidth = '6px';
    input.style.maxWidth = getExplorerUploaderWindowWidth();
    input.style.color = "black";
    input.type = "text";
    input.autofocus = 'true';
    input.onblur = this.handleBlurExplorerUpload;
    input.onkeyup = this.handleOnKeyup;
    input.onkeydown = this.handleOnKeyDown;
    input.id = "create";
    input.className = this.props.fontSizeClass + ' addListClass clearButton';
    input.autocomplete = "off";
    let currentName = LocalizationStore.getTranslatedData('LM_TP_Untitled_1', 'Untitled {0}');
    let defaultValueForInput = currentName.replace('{0}', LocalizationStore.getTranslatedData('LM_TP_list', 'Data Set'));
    let nodesToSearch = ListStore.getExternalDataSetList;
    defaultValueForInput = defaultValueForInput ? defaultValueForInput : "Untitled Data Set";
    input.defaultValue = ListExplorerStore.validateExternalListName(nodesToSearch, defaultValueForInput);
    input.setAttribute('size', input.defaultValue.length);
    newSpan.appendChild(newI);
    newSpan.appendChild(input);
    appendedArea.insertBefore(newSpan, appendedArea.firstChild);
    input.select();
    ListExplorerUtil.setScrollPositionDe(elm);
  }

  toggleDropdown(showDropdown) {
    if(this.props.selectedTabKey !== ListManagerTabType.External){
      this.setState({
        showDropdown: showDropdown
      })
    }else{
      this.setState({
        showExternalDropdown: showDropdown
      })
    }
  }

  duplicateItemExternalUpload() {
    dispatch(nodeSelection(''));
    const activeExternalNode = ListStore.activeExternalNode;
    const activelistId = activeExternalNode.listId;
    const name = activeExternalNode.listName;
    let listType = activeExternalNode.dsType ? activeExternalNode.dsType : activeExternalNode.listType;
    listType = listType > 7 ? listType - 2 : listType;
    dispatch(setDuplicateNode(activelistId,listType,name));
  }

  duplicateItem() {
    if (this.props.selectedTabKey === ListManagerTabType.Oneil ) {
      ListExplorerStore.contextNode = null;
      if (ListExplorerStore.isEnableCopyPasteAction && !ListExplorerStore.getIsCryptoCurrencyList()) {
        BrowserUtil.disableEvents();
        let nodeName = null;
        let activeNode = null;
        //Searching the node to duplicate
        const symbolToDelete = ListExplorerStore.activeSybol && ListExplorerStore.activeSybol.symbol;

        if (symbolToDelete)
          activeNode = Number(this.props.nodeId) === -1 ? ListExplorerStore.getOneilOwnershipActiveNode(this.props.nodeId) : ListExplorerStore.getActiveNodeUsingSymbol(symbolToDelete);
        else
          activeNode = Number(this.props.nodeId) === -1 ? ListExplorerStore.getOneilOwnershipActiveNode(this.props.nodeId) : ListExplorerStore.getActiveNode(this.props.nodeId);

        nodeName = ListExplorerStore.validateListName(activeNode.name, ListCategoryType.USER_ListCategory, null, ListType.LIST_List);
        nodeName = LocalizationStore.getTranslatedData(ListExplorerStore.getListTreeItemNameResKey(activeNode.name, activeNode.listType), activeNode.name);
        const rootParentId = activeNode.rootParentNodeId;
        const sourceCategoryType = activeNode.categoryType;

        return this.props.duplicateItem(this.props.nodeId, nodeName, rootParentId, sourceCategoryType, ListExplorerConstants.ActionTypes.COPY_PASTE);
      }
    } else {
      this.props.store.dispatch(saveDuplicateNode(null, null, null, 'DUPLICATE'));
    }
  }

  deleteItem() {
    if (this.props.selectedTabKey === ListManagerTabType.Oneil && this.props.selectedTabKey !== ListManagerTabType.External) {
      if (!StringUtil.isEmpty(this.props.deleteNode) && ONeilViewStore.isListLoaded) {
        BrowserUtil.disableEvents();
        let deleteNode = null;
        let action = null;

        if (this.props.deleteNode.listType === ListType.FOLDER_List) {
          if (ListExplorerStore.isDeleteAllowed(this.props.deleteNode.categoryType))
            deleteNode = this.props.deleteNode;
        }
        else {
          deleteNode = this.props.deleteNode.nodeId === ListExplorerStore.activeNode.nodeId ? this.props.deleteNode : ListExplorerStore.activeNode;
          if (!ListExplorerStore.isDeleteAllowed(deleteNode.categoryType) && !ONeilViewStore.isListLoaded) {
            deleteNode = null;
            BrowserUtil.enableEvents();
            return;
          }
        }
        ListExplorerStore.contextNode = {};

        action = deleteNode.categoryType === ListCategoryType.FAVORITE_ListCategory ? (deleteNode.listType === ListType.FOLDER_List ?ContextMenuConstants.DELETE :ContextMenuConstants.REMOVE_FROM_FAVORITES) : ContextMenuConstants.DELETE;

        ListExplorerStore.contextNode.rootParentNodeId = deleteNode.rootParentNodeId;
        ListExplorerStore.contextNode.parentNodeId = deleteNode.parentNodeId
        ListExplorerStore.contextNode.categoryType = deleteNode.categoryType;
        ListExplorerStore.contextNode.nodeId = deleteNode.nodeId;
        ListExplorerStore.contextNode.name = deleteNode.name;
        ListExplorerStore.contextNode.listType = deleteNode.listType;
        ListExplorerStore.contextNode.symbol = deleteNode.symbol && deleteNode.symbol;
        ListExplorerStore.contextNode.msid = deleteNode.msid && deleteNode.msid;
        ListExplorerStore.contextNode.action = action;

        return this.props.onExplorerContextMenuClick(action);
      }
    }
    else if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
      const state = store.getState();
      if(OwnershipViewStore.isListLoaded) {
        if (state.listView && !StringUtil.isEmpty(state.listView.activeFolderID.low)) {
          this.props.store.dispatch(setDeleteFolder({ isShowPopup: true, nodeIDtoDelete: state.listView.activeFolderID.low }))
          this.props.store.dispatch(setFolderActiveNode(""))
        } else {
          this.props.store.dispatch(deleteSelectedItem());
        }
      }
    }
    else {
      dispatch(nodeSelection(''));
      const listType = ListStore.activeExternalNode.listType ? ListStore.activeExternalNode.listType : ListStore.activeExternalNode.dsType;
      const allLists = ListStore.getExternalDataSetList.find(itm => itm.listType == listType);
      
      // let allLists = ListStore.getExternalDataSetList;
      if (ListStore.externalDataSelectedNodes.length) {
        let activeindex, selectedFirstIndex, selectedLastIndex, nextActiveNodeIndex, FirstIndex, prevActiveNodeIndex;
        let deleteNodes = ListStore.externalDataSelectedNodes;
        let lastIndexSelectedNode = ListStore.externalDataSelectedNodes.length - 1;
        for (let listIndex = 0; listIndex < allLists.childNodes.length; listIndex++) {
          (allLists.childNodes[listIndex].nodeId.low == deleteNodes[lastIndexSelectedNode]) ? selectedFirstIndex = listIndex : selectedFirstIndex = selectedFirstIndex;
          (allLists.childNodes[listIndex].nodeId.low == ListStore.activeExternalNode.listId) ? activeindex = listIndex : activeindex = activeindex;
        }
        (selectedFirstIndex > activeindex) ? deleteNodes.unshift(ListStore.activeExternalNode.listId) : deleteNodes.push(ListStore.activeExternalNode.listId);
        const lastDeleteNodeIndex = deleteNodes.length - 1;
        for (let listIndex = 0; listIndex < allLists.childNodes.length; listIndex++) {
          (allLists.childNodes[listIndex].nodeId.low == deleteNodes[lastDeleteNodeIndex]) ? selectedLastIndex = listIndex : selectedLastIndex = selectedLastIndex;
          (allLists.childNodes[listIndex].nodeId.low == deleteNodes[0]) ? FirstIndex = listIndex : FirstIndex = FirstIndex;
        }
        nextActiveNodeIndex = selectedLastIndex + 1;
        prevActiveNodeIndex = FirstIndex - 1;
        let nextActiveNode = allLists.childNodes[nextActiveNodeIndex];
        if (!nextActiveNode) {
          nextActiveNode = allLists.childNodes[prevActiveNodeIndex];
          if (!nextActiveNode) {
            for (let listIndex = 0; listIndex < deleteNodes.length; listIndex++) {
              allLists.childNodes = allLists.childNodes.filter((item) => item.nodeId.low != deleteNodes[listIndex]);
            }
            nextActiveNode = allLists.childNodes[0];
          }
        }
        dispatch(removeDeletedLists(deleteNodes,listType));
        if (nextActiveNode) {
          dispatch(loadExternalList(nextActiveNode.nodeId.low,true));
          ListStore.updateSelectedListItem(nextActiveNode.nodeId.low, nextActiveNode.nodeId.low, nextActiveNode.name);
        } else {
          if(listType > 7){
            dispatch(findNextFolder(listType));
          }
          // dispatch(loadExternalList('',false,listType));
          // ListActions.setSelectedExternalList('');
        }
        ListActions.deleteExternalList(deleteNodes);
        let selectedNodes = [];
        dispatch(setSelectedNodes(selectedNodes));
      } else {
        let activeindex;
        for (let listIndex = 0; listIndex < allLists.childNodes.length; listIndex++) {
          (allLists.childNodes[listIndex].nodeId.low === ListStore.activeExternalNode.listId) ? activeindex = listIndex : activeindex = activeindex;
        }
        let nextActiveNodeIndex = activeindex + 1;
        let nextActiveNode = allLists.childNodes[nextActiveNodeIndex];
        if (!nextActiveNode) {
          nextActiveNodeIndex = activeindex - 1;
          nextActiveNode = allLists.childNodes[nextActiveNodeIndex];
        }
        let deleteNodes = [];
        deleteNodes.push(ListStore.activeExternalNode.listId);
        dispatch(removeDeletedLists(deleteNodes,listType));
        ListActions.deleteExternalList(ListStore.activeExternalNode.listId, ListStore.activeExternalNode.listName);
        if (nextActiveNode) {
          dispatch(loadExternalList(nextActiveNode.nodeId.low,true));
          ListStore.updateSelectedListItem(nextActiveNode.nodeId.low, nextActiveNode.nodeId.low, nextActiveNode.name);
        } else {
          if(listType > 7){
            dispatch(findNextFolder(listType));
          }
          // dispatch(loadExternalList('',false,listType));
          // ListActions.setSelectedExternalList('');
        }
        let selectedNodes = [];
        dispatch(setSelectedNodes(selectedNodes));

      }
      // let sortedList= ListStore.sortExternalData(ListStore.getExternalDataSetList);
      // dispatch(sortExternalList(sortedList));
    }
  }

  openAlert(existAlerts) {
    if (!StringUtil.isEmpty(ListExplorerStore.activeNode)) {
      //BrowserUtil.disableEvents();
      let alertNode = null;
      let action = null;

      if (!this.props.isAlertEnable) {
        return;
      }
      else {
        alertNode = ListExplorerStore.activeNode;
      }

      ListExplorerStore.contextNode = {};

      action = ContextMenuConstants.ALERT;

      ListExplorerStore.contextNode.rootParentNodeId = alertNode.rootParentNodeId;
      ListExplorerStore.contextNode.parentNodeId = alertNode.parentNodeId;
      ListExplorerStore.contextNode.parentCategoryType = alertNode.parentCategoryType;
      ListExplorerStore.contextNode.parentCategoryName = alertNode.parentCategoryName;
      ListExplorerStore.contextNode.categoryType = alertNode.categoryType;
      ListExplorerStore.contextNode.nodeId = alertNode.nodeId;
      ListExplorerStore.contextNode.name = alertNode.name;
      ListExplorerStore.contextNode.listType = alertNode.listType;
      ListExplorerStore.contextNode.action = action;
      ListExplorerStore.contextNode.alertStatusType = alertNode.alertStatusType

      return this.props.onExplorerContextMenuClick(action, existAlerts);
    }
  }
  openAndAlert(existAlerts) {
    if (!StringUtil.isEmpty(ListExplorerStore.activeNode)) {
      let alertNode = null;
      let action = null;

      if (!this.props.isAlertEnable) {
        return;
      }
      else {
        alertNode = ListExplorerStore.activeNode;
      }

      ListExplorerStore.contextNode = {};

      action = ContextMenuConstants.AND_CONDITION_ALERTS;

      ListExplorerStore.contextNode.rootParentNodeId = alertNode.rootParentNodeId;
      ListExplorerStore.contextNode.parentNodeId = alertNode.parentNodeId;
      ListExplorerStore.contextNode.parentCategoryType = alertNode.parentCategoryType;
      ListExplorerStore.contextNode.parentCategoryName = alertNode.parentCategoryName;
      ListExplorerStore.contextNode.categoryType = alertNode.categoryType;
      ListExplorerStore.contextNode.nodeId = alertNode.nodeId;
      ListExplorerStore.contextNode.name = alertNode.name;
      ListExplorerStore.contextNode.listType = alertNode.listType;
      ListExplorerStore.contextNode.action = action;
      ListExplorerStore.contextNode.alertStatusType = alertNode.alertStatusType

      return this.props.onExplorerContextMenuClick(action, existAlerts);
    }
  }
  saveScreenedResults() {
    if (this.props.selectedTabKey === ListManagerTabType.Oneil) {
    if (ONeilViewStore.getSaveScreenedResultsVisibilty()) {
      BrowserUtil.disableEvents();
      ListActions.saveScreenedResults();
    }
  }
  if (this.props.selectedTabKey === ListManagerTabType.Ownership) {
    if (OwnershipViewStore.getSaveScreenedResultsVisibilty()) {
      //BrowserUtil.disableEvents();
      saveScreenedResults();
    }
  }
  }

  openPriceAlertContextMenu(e) {
    if (!this.props.isAlertEnable) {
      return;
    }

    const contextMenuOptions = [];

    contextMenuOptions.push({
      headerText: LocalizationStore.getTranslatedData("ch_msa_sup_oca", ContextMenuConstants.AND_CONDITION_ALERTS),
      action: ContextMenuConstants.AND_CONDITION_ALERTS,
      isEnabled: true
    },
      {
      headerText: LocalizationStore.getTranslatedData("ch_msa_sup_oca", ContextMenuConstants.OR_CONDITION_ALERTS),
      action: ContextMenuConstants.OR_CONDITION_ALERTS,
      isEnabled: true
    });
    if (ListExplorerStore.findSourceNode(ListExplorerStore.activeNode.nodeId).alertStatusType,"ONEIL"){
      let alertType = ListExplorerStore.findSourceNode(ListExplorerStore.activeNode.nodeId).alertType;
      if(alertType == AlertsType.Or || alertType == AlertsType.Both){
        contextMenuOptions.push({
            headerText: ContextMenuConstants.DELETE_LIST_ALERTS,
            action: ContextMenuConstants.DELETE_LIST_ALERTS,
            isEnabled: true
          });
      }
      if(alertType == AlertsType.And || alertType == AlertsType.Both){
        contextMenuOptions.splice(1, 0,{
              headerText: ContextMenuConstants.DELETE_LIST_ALERTS,
              action: ContextMenuConstants.DELETE_LIST_ALERTS,
              isEnabled: true
            });
      }
    }

    this.setState({ showContextMenu: true, contextMenu: contextMenuOptions, contextMenuXPos: e.clientX - 25, contextMenuYPos: e.clientY - 42 });
    e.preventDefault();
  }
  closeContextMenu(itemId) {
    if (itemId !== "addAlertsIcon") {
      this.setState({
        showContextMenu: false
      });
    }
  }

  onContextMenuClick(item, existAlerts, action) {
    this.closeContextMenu();

    if (item.action == ContextMenuConstants.OR_CONDITION_ALERTS) {
      this.openAlert(existAlerts);
    }
    if (item.action == ContextMenuConstants.AND_CONDITION_ALERTS) {
      this.openAndAlert(existAlerts);
    }
    if (item.action == ContextMenuConstants.DELETE_LIST_ALERTS) {
      this.confirmListAlertDelete(action);
    }
  }


  confirmListAlertDelete(action) {
    let conditionTypeId = null, alertMessage = null, deleteHandler;

    if(this.props.selectedTabKey === ListManagerTabType.External){
      alertMessage = 'Do you want to delete this External Data List?';
      deleteHandler = this.deleteItem
    }
    else if(action === ContextMenuConstants.AND_CONDITION_ALERTS){
       conditionTypeId = AlertConditionType.And;
       alertMessage = LocalizationStore.getTranslatedData("","Do you want to delete And-condition alerts?");
    }
    else{
        conditionTypeId = AlertConditionType.Or;
        alertMessage = LocalizationStore.getTranslatedData("","Do you want to delete Or-condition alerts?");
    }
    let dialog = <CommonDialog preClass={''}
      isOpen={true}
      title={LocalizationStore.getTranslatedData("list_DelAlt", 'Delete Alerts')}
      hideModal={this.cancelDelete}
      backButtonShow={false}
      cancelButtonShow={true}
      submitButtonShow={true}
      submitHandler={() => this.deleteListAlert(conditionTypeId)}
      submitButtonText={LocalizationStore.getTranslatedData("delete_upper", 'DELETE')}
    >
      <Fragment><div className="alert-stuff"><p>{alertMessage}</p></div><div className="clearfix"></div></Fragment>
    </CommonDialog>
    this.setState({ commonDialog: dialog });
  }

  async deleteListAlert(conditionTypeId) {
    await AlertActions.deleteAllListAlerts(ListExplorerStore.activeNode.nodeId, conditionTypeId)
    this.cancelDelete();
  }

  cancelDelete() {
    this.setState({ commonDialog: null });
  }

  render() {
    let deleteClass;
    let copyClass;
    let addClass;
    let notifyClass;
    const listCountExternal = ListStore.getExternalDataSetList.length;
    const selectedListCountExternal = ListStore.externalDataSelectedNodes.length;
    let isDeleteEnable = false;
    if(this.props.selectedTabKey !== ListManagerTabType.External) {
        let isListLoaded = ONeilViewStore.isListLoaded;
        if(this.props.selectedTabKey == ListManagerTabType.Ownership)isListLoaded=OwnershipViewStore.isListLoaded;
        const listCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("categoryType") ? ListExplorerStore.activeNode.categoryType : null;
        if((this.props.isDeleteEnable == true && isListLoaded && !this.props.renameNode) || ListExplorerStore.isNotImportedList()){
          deleteClass = 'delete';
          isDeleteEnable = true;
        }
        else{
          deleteClass = ' delete disabled';
          isDeleteEnable = false;
        }
        // deleteClass = (this.props.isDeleteEnable == true && isListLoaded) ? 'delete' : ' delete disabled';
        const sourceListCategoryType = ListExplorerStore.activeNode && ListExplorerStore.activeNode.hasOwnProperty("sourceCategoryType") ? ListExplorerStore.activeNode.sourceCategoryType : null;
        const isDisableCopy = listCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory || sourceListCategoryType === ListCategoryType.FUTURESCASHSPOTSLIST_ListCategory;
        copyClass = ListExplorerStore.isEnableCopyPasteAction && !ListExplorerStore.getIsCryptoCurrencyList() && !isDisableCopy && !ListExplorerStore.isNotImportedList() && !this.props.renameNode ? 'copy' : 'copy disabled';
        addClass = 'add';
        notifyClass = this.props.isAlertEnable ? 'notifay' : 'notifay disable';
        (this.props.selectedTabKey === ListManagerTabType.Ownership) ?  notifyClass += ' hide-element' : notifyClass +='';
      } else {
        if (listCountExternal == 0 || this.props.isLoading) {
          deleteClass = 'delete disabled';
          isDeleteEnable = false;
        }
        else {
          deleteClass = 'delete';
          isDeleteEnable = true;
        }
        // deleteClass = listCountExternal == 0 || this.props.isLoading ? 'delete disabled' : 'delete';
        copyClass =   listCountExternal ==0 || selectedListCountExternal > 0 || this.props.isLoading ? 'copy disabled' : 'copy';
        addClass = (listCountExternal == 0 || this.props.isLoading) ?'add disabled':'add';
        notifyClass = this.props.isAlertEnable || this.props.isLoading? 'notifay' : 'notifay disable';
      }

    return (
      <div className="oneil-new-item">
        {this.props.selectedTabKey !== ListManagerTabType.External &&
          <DropdownMenu addNewItem={this.addNewItem} dropdown={this.dropdown} showDropdown={this.state.showDropdown} toggleDropdown={this.toggleDropdown} selectedTabKey={this.props.selectedTabKey} saveScreenedResults={this.saveScreenedResults} />
        }
        {this.props.selectedTabKey == ListManagerTabType.External &&
          <DropdownMenu addNewItem={this.addNewExternalItem} dropdown={this.dropdown} showDropdown={this.state.showExternalDropdown} toggleDropdown={this.toggleDropdown} selectedTabKey={this.props.selectedTabKey} saveScreenedResults={this.saveScreenedResults} />
        }
        <div className="tab-view-left-bottom-footer">
          <ul>
            <li className={addClass} style={ListStore.getExternalDataSetList.length ==0?{pointerEvents:'none'}:{cursor:'pointer'}}
            onClick={this.toggleDropdown} ref={(ref) => this.dropdown = ref}>
              <div className="wrap-border">
                <a className="icn-bottom-footer-tab-add" ></a>
              </div>
            </li>
            <li className={copyClass} onClick={this.props.selectedTabKey == ListManagerTabType.External ? this.props.isLoading || selectedListCountExternal>0? null: this.duplicateItemExternalUpload : ListExplorerStore.isNotImportedList() || ListExplorerStore.getIsFuturesCashSpotsList() || this.props.renameNode ? null : this.duplicateItem}>
              <div className="wrap-border">
                <a className="icn-bottom-footer-tab" ></a>
              </div>
            </li>
            <li className={deleteClass} onClick={this.props.isLoading || !isDeleteEnable? null:this.deleteItem}>
              <div className="wrap-border ">
                <a className="icn-bottom-footer-tab" ></a>
              </div>
            </li>
            {this.props.selectedTabKey != ListManagerTabType.External &&
              <li className={notifyClass} id="addAlertsIcon" onClick={this.openPriceAlertContextMenu}>
                <div className="wrap-border">
                  <a className="icn-bottom-footer-tab" ></a>
                </div>
              </li>
            }
            <li className="help pull-right w100p">
              <div className="bdrLeft pull-right">
                <div className="wrap-border pull-right">
                  <a className="icn-bottom-footer-tab" onClick={this.props.isLoading? null:this.props.toggleHelp}></a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        {this.state.showContextMenu && <PortalContainer><DGContextMenu contextMenu={this.state.contextMenu}
          contextMenuXPos={this.state.contextMenuXPos}
          contextMenuYPos={this.state.contextMenuYPos}
          closeContextMenu={(itemId) => this.closeContextMenu(itemId)}
          onContextMenuClick={(item, existAlerts, action) => this.onContextMenuClick(item, existAlerts, action)}
        /></PortalContainer>}
        {this.state.commonDialog}
      </div>
    )
  }
}
