import Serializable from "Serializable";
import RiSubCategoryType from "../../../../../../Constants/RiSubCategoryType.js";
import RiPanelNewSettings from "./RiPanelNewSettings.js";
import RiPanelManagementSettings from "./RiPanelManagementSettings.js";
import RiPanelSimilarFundSettings from "./RiPanelSimilarFundSettings.js";

class RiPanelFUNDViewSettings extends Serializable {

    getDefaulSettings() {
        this.ActiveSelectedSubCategory = RiSubCategoryType.Management;
        this.NewSettings = this.getDefaultNewsSettings();
        this.ManagementSettings = this.getDefaultManagementSettings();
        this.SimilarFundSettings = this.getDefaultSimilarFundSettings();
        return this;
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.ActiveSelectedSubCategory = serializedValue.ActiveSelectedSubCategory ? serializedValue.ActiveSelectedSubCategory : RiSubCategoryType.Management;
        this.NewSettings = serializedValue.NewSettings ? serializedValue.NewSettings : this.getDefaultNewsSettings();
        this.ManagementSettings = serializedValue.ManagementSettings ? serializedValue.ManagementSettings : this.getDefaultManagementSettings();
        this.SimilarFundSettings = serializedValue.SimilarFundSettings ? serializedValue.SimilarFundSettings : this.getDefaultSimilarFundSettings();
    }

    getObjectMapKey() {
        return "RiPanelFUNDViewSettings";
    }

    getDataObject() {
        return {
            ActiveSelectedSubCategory: this.ActiveSelectedSubCategory,
            NewSettings: this.NewSettings,
            ManagementSettings: this.ManagementSettings,
            SimilarFundSettings: this.SimilarFundSettings
        };
    }

    getDefaultNewsSettings() {
        const newsSettings = new RiPanelNewSettings();
        return newsSettings;
    }

    getDefaultManagementSettings() {
        const managementFundSettings = new RiPanelManagementSettings();
        return managementFundSettings;
    }

    getDefaultSimilarFundSettings() {
        const similarFundSettings = new RiPanelSimilarFundSettings();
        return similarFundSettings;
    }

}


export default RiPanelFUNDViewSettings;