import React, { PureComponent } from 'react';
import { Button , Table } from "react-bootstrap";
import ExternalDataUploadWizard  from '../../../../RayCustomControls/ExternalData/ExternalDataUploadWizard.jsx'
import ExternalDataFileError  from '../../../../RayCustomControls/ExternalData/ExternalDataFileError.jsx'
import DragAndDrop from "./DragAndDrop";
import { connect } from 'react-redux';
import { showHideUploadDialog,uploadFormDataOnDrop,showFormatException } from '../../../../Actions/ExternalDataUploaderActions';
import ScrollBar from "ScrollBar";
import BaseServiceApi from 'BaseServiceApi';
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

class ExternalDataUploadView extends PureComponent {
    constructor(props, context) {
        super(props, context);
    }
    
    uploaderDialogClose = () => {
        this.props.uploaderDialogChange(false);
    }
    uploaderDialogShow = () =>{
        this.props.uploaderDialogChange(true);
    }
    handleDrop = (file) => {
        if(!file) return;
        let form_data = new FormData();
        const {source} = this.props;
        let validExts = new Array(".xlsx", ".xls", ".csv", ".txt");
        let fileExt = file[0].name
        let extenstion = fileExt.substring(fileExt.lastIndexOf('.'));
        if (validExts.indexOf(extenstion) < 0) {
          this.props.showFormatException();
          return false;
        }
        form_data.append('file', file[0]);
        form_data.append('listName', source.listName);
        form_data.append('listId', source.listId);
        this.props.formsubmit(form_data,source.listId);
    }
    render() {
        let {currentListData,listIsEmpty,wizardDialogShow,fileExceptionDialogShow,source,emptyListType} = this.props;
        const listType = source.listType ? source.listType : emptyListType;
        if(!listIsEmpty && currentListData){
            return (
                <div className="main-list-view">{currentListData.rows.length}</div>
            )
        }
        return (
            <div className="external-data-Uploader">
                <div className="external-scroll-wrapper">
                    <div className="custom-scroll">
                        <div className="external-scroll-container" id='externalDataUploadWrapVerticalScroll'>
                            {source.shareAccess == ShareAccessType.SHARE_READONLY && 
                                <div className="external-data-holder empty">
                                    <div className="ext-data-uploading">
                                        <div className="uploading-hold">
                                            <h5>Data set is empty, Sender hasn't uploaded data yet.</h5>
                                        </div>
                                    </div>
                                </div>
                            }
                            {source.shareAccess != ShareAccessType.SHARE_READONLY && <div className="external-data-holder">
                                <DragAndDrop handleDrop={this.handleDrop} className="test"></DragAndDrop>
                                <div className="ext-data-uploading">
                                                                
                                    <div className="title">
                                        <h4>IMPORT YOUR DATA</h4>
                                        <a className="link-help" onContextMenu = {(e) => e.preventDefault()} href={`${window.location.origin}/assets/ExternalDataHelp_1.0.pdf`} target="_blank">Help</a>
                                    </div>

                                    <div className="uploading-hold">
                                        <h5>Drag and Drop directly into this window or</h5>
                                        <div className="uploading-box">
                                            <Button bsSize="small" className="upload-local-file" onContextMenu = {(e) => e.preventDefault()} onClick={this.uploaderDialogShow}>Upload a file</Button>
                                        </div>
                                    </div>
                                    
                                    <div className="upload-instruction">
                                        <h6>How to set up your data file:</h6>
                                        <ul>
                                            <li>All columns must include a header. Required columns are highlighted in Green.</li>
                                            <li>Acceptable identifiers : Symbols (O'Neil, Refinitiv&trade;), SEDOL and ISIN.</li>
                                            {(listType == BaseServiceApi.rayData["ListType"].EVENTSERIES_List || listType == BaseServiceApi.rayData["ListType"].EVENTSERIES_List + 2) && 
                                                <li>You may include a "Label" column to assign a 1-3 letter abbreviation that will identify the metric on the Datagraph.</li>
                                            }
                                            <li>Use one date column only.</li>
                                            <li>Only the first sheet data can be uploaded.</li>
                                        </ul>
                                    </div>
                                </div>
                                {wizardDialogShow && <ExternalDataUploadWizard />} 
                                {fileExceptionDialogShow && <ExternalDataFileError />}
                                <div className="ext-data-load-summary">
                                    {(listType == BaseServiceApi.rayData["ListType"].TIMESERIES_List || listType == BaseServiceApi.rayData["ListType"].TIMESERIES_List + 2) && <div className="ext-load-summary-box">
                                        <div className="ext-load-summary-table rate-time-series">
                                            <div className="title"><h6>RATING, TIME SERIES</h6></div>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Symbol</th>
                                                        <th>Date</th>
                                                        <th>My Rating</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>FB</td>
                                                        <td>11/20/2017</td>
                                                        <td>85</td>
                                                    </tr>
                                                    <tr>
                                                        <td>FB</td>      
                                                        <td>11/06/2017</td>
                                                        <td>72</td>
                                                    </tr>
                                                    <tr>
                                                        <td>AAPL</td>      
                                                        <td>11/30/2017</td>
                                                        <td>85</td>
                                                    </tr>
                                                    <tr>      
                                                        <td>AAPL</td>      
                                                        <td>11/27/2017</td>
                                                        <td>65</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>}
                                    {(listType == BaseServiceApi.rayData["ListType"].EVENTSERIES_List || listType == BaseServiceApi.rayData["ListType"].EVENTSERIES_List + 2) && <div className="ext-load-summary-box">
                                        <div className="ext-load-summary-table date-based-events">
                                            <div className="title"><h6>DATE-BASED EVENTS</h6></div>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Symbol</th>
                                                            <th>Date</th>
                                                            <th>Category</th>
                                                            <th>Label</th>      
                                                            <th>Notes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>FB</td>
                                                            <td>1/20/2017</td>
                                                            <td>News</td>
                                                            <td>N</td>
                                                            <td>Patent Infringement</td>
                                                        </tr>
                                                        <tr>
                                                            <td>FB</td>      
                                                            <td>1/27/2017</td>
                                                            <td>Acquisition</td>
                                                            <td>AQ</td>
                                                            <td>Notes may include URLS: Http://www.marketwatch.com/FB</td>
                                                        </tr>
                                                        <tr>
                                                            <td>AAPL</td>      
                                                            <td>11/30/2017</td>
                                                            <td>News</td>
                                                            <td>N</td>
                                                            <td>Looks promising</td>
                                                        </tr>
                                                        <tr>      
                                                            <td>AAPL</td>      
                                                            <td>1/27/2017</td>
                                                            <td>News</td>
                                                            <td>N</td>
                                                            <td>Matches my ratings</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                        </div>
                                    </div>}
                                    <div className="ext-load-summary-box" style={{display:"none"}}>
                                        <div className="ext-load-summary-table transaction">
                                            <div className="title"><h6>TRANSACTIONS</h6></div>
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>Symbol</th>
                                                            <th>Date</th>
                                                            <th>Category</th>
                                                            <th>Price</th>
                                                            <th>Shares</th> 
                                                            <th>Label</th>      
                                                            <th>Notes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>FB</td>
                                                            <td>1/20/2017</td>
                                                            <td>Sell</td>
                                                            <td>80</td>
                                                            <td>105</td>
                                                            <td>SL</td>
                                                            <td>Note 1</td>
                                                        </tr>
                                                        <tr>
                                                            <td>FB</td>
                                                            <td>1/27/2017</td>
                                                            <td>Sell Short</td>
                                                            <td>70</td>
                                                            <td>100</td>
                                                            <td>SS</td>
                                                            <td>Note 2</td>
                                                        </tr>
                                                        <tr>
                                                            <td>AAPL</td>
                                                            <td>1/30/2017</td>
                                                            <td>Buy</td>
                                                            <td>115</td>
                                                            <td>50</td>
                                                            <td>BY</td>
                                                            <td>Note 3</td>
                                                        </tr>
                                                        <tr>
                                                            <td>AAPL</td>
                                                            <td>1/27/2017</td>
                                                            <td>Cover Short</td>
                                                            <td>1152</td>
                                                            <td>50</td>
                                                            <td>CS</td>
                                                            <td>Note 4</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <ScrollBar scrollId='externalDataUploadWrapVerticalScroll' vScroll={true} />
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    uploaderDialogChange : (data) => dispatch(showHideUploadDialog(data)),
    wizardDialogChange : (data) => dispatch(showHideWizardDialog(data)),
    formsubmit : (data,listId) => dispatch(uploadFormDataOnDrop(data,listId)),
    showFormatException: () => dispatch(showFormatException())
})
const mapStateToProps = ({ externalDataUploader }) => {
    const { uploadDialogShow,currentListData,listIsEmpty,wizardDialogShow,fileExceptionDialogShow } = externalDataUploader.ExternalDataUploaderReducer;
    const { source,emptyListType } = externalDataUploader.ExternalDataListViewReducer;
    return ({ uploadDialogShow,currentListData,listIsEmpty,wizardDialogShow,fileExceptionDialogShow,source,emptyListType });
}
export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataUploadView);