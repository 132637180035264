import React, { Component } from 'react';
const cellWidth = require("text-width");

export default class MiniListListNamePopOver extends Component {
    constructor(props) {
        super(props);
        this.listMiniList = document.getElementById('listMiniList');
        this.miniListNameElm = document.getElementById('listNameDiv');
    }
    showToolTripInListName() {
        let listName = this.props.title;
        let textWidth = 0;
        let listMiniListWidth = this.listMiniList.offsetWidth;
        let textBoxRect = this.miniListNameElm.getBoundingClientRect();
        this.left = textBoxRect.left;
        this.top = textBoxRect.top;
        if (listName) {
            textWidth = cellWidth(listName, {
                family: 'calibrib',
                size: 16
            });
        }

        if (textWidth > listMiniListWidth) {
            return true;
        }
        else {
            return false;
        }
    }
    render() {
        let isToolTipShow = this.showToolTripInListName();
        if (isToolTipShow) {
            return (
                <div className="miniListPopOver"
                    style={{
                        left: this.left,
                        top: this.top
                    }}
                >
                    <span>{this.props.title}</span>
                </div>

            );
        }
        else {
            return null;
        }
    }
}