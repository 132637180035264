import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import ConsoleStore from "Stores/ConsoleWindow/ConsoleStore";
import UserInfoUtil from "UserInfoUtil";
import StringUtil from "StringUtil";
//import BaseServiceApi from "BaseServiceApi";
import DataGraphStore from "../../Stores/NavModules/NavDataGraph/DataGraphStore.js";
import LocalizationStore from "LocalizationStore";
import TabType from "../../Constants/TabType.js";
import { GraphTypeShortString } from "../../Constants/GraphType.js";
import ErrorCodes from "../../Constants/ErrorCodes.js";

//const entitlementType = BaseServiceApi.rayData["EntitlementType"];
const CHANGE_EVENT = "change";

class ExceptionClass extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    getMailAddress() {
        return 'panaraysupport@williamoneil.com';
    }

    getLastSymbolLoaded() {
        let settings = DataGraphStore.getNavDataGraphSettings();
        let state = DataGraphStore.getState();
        return state.Symbol == "" || !state.Symbol ? settings.symbol : state.Symbol;
    }

    getLastPeriodicityLoaded(){
        const state = DataGraphStore.getState();
        const settings = DataGraphStore.getNavDataGraphSettings();
        if(state.SelectedTabKey === TabType.Datagraph){
            return GraphTypeShortString[settings.TabDataGraphSettings.Periodicity];
        }
        else if(state.SelectedTabKey === TabType.Fundamental){ 
            return GraphTypeShortString[settings.TabFundamentalsSettings.StockViewSettings.Periodicity];
        }

        return GraphTypeShortString[settings.TabDataGraphSettings.Periodicity];
    }
    getEmailBody(reqJSONObj = false) {
        let emailBody = "";
        const errorReport = ConsoleStore.getErrorReport();
        if(!errorReport) return "Client Side Exception";
        const detailedMessage = this.getDetailedException(errorReport);
        const innerException = this.getInnerException(errorReport);
        let dateTime = StringUtil.formatDate(StringUtil.isEmpty(errorReport.asOfDate) ? Date.now() : errorReport.asOfDate, "M/D/YYYY h:mm:ss A Z");
        const offSet = dateTime.split(" ").pop();
        dateTime = dateTime.replace(offSet, "");
        const version = LocalizationStore.getTranslatedData("_Version", "");
        const LastSymbolLoaded = this.getLastSymbolLoaded();
        const LastPeriodicityLoaded = this.getLastPeriodicityLoaded();
        const user = UserInfoUtil.getUserInfo().userEmail;
        const server = errorReport.server;
        const serviceUriName = errorReport.serviceUriName;
        const message = LocalizationStore.getTranslatedData("er_report_02", "An unexpected error has occurred. If you would like to receive technical support, please call (310) 448-6199.");
        const newLineDelimiter = "\r\n";
        let dateTimeStr = LocalizationStore.getTranslatedData("er_dateTime", "Date/Time: ");
        let timeZoneStr = LocalizationStore.getTranslatedData("er_standardTimeZone", "Standard Time Zone: Pacific Standard Time");
        let utcOffStr = LocalizationStore.getTranslatedData("er_UTCOffset", "UTC Offset: ");
        let versionStr = LocalizationStore.getTranslatedData("set_hp_sd_v", "Version: ");
        let lastSymbolStr = LocalizationStore.getTranslatedData("er_lastSymbolLoad", "Last Symbol Loaded: ");
        let userStr = LocalizationStore.getTranslatedData("er_user", "User: ");
        let serverStr = LocalizationStore.getTranslatedData("set_hp_sd_s", "Server: ");
        let messageStr = LocalizationStore.getTranslatedData("er_msg", "Message: ");
        let serviceUrlStr = LocalizationStore.getTranslatedData("er_serviceUriName", "ServiceUriName: ");
        let errorMsgStringForJsonObj = '';
        let errorMessage = `${dateTimeStr}${dateTime}${newLineDelimiter}${timeZoneStr}${newLineDelimiter}${utcOffStr}${offSet}${newLineDelimiter}${versionStr}${version}${newLineDelimiter}${lastSymbolStr}${LastSymbolLoaded}${` (${LastPeriodicityLoaded})`}${newLineDelimiter}${userStr}${user}${newLineDelimiter}${serverStr}${server}${newLineDelimiter}${newLineDelimiter}${messageStr}${message}${newLineDelimiter}${newLineDelimiter}${serviceUrlStr}${serviceUriName}`;
        if (detailedMessage && innerException) {
            let innerExceptionStr = LocalizationStore.getTranslatedData("er_innerException", "Inner Exception: Exception of type: ");
            let detailMsgStr = LocalizationStore.getTranslatedData("er_detailMsg", "DetailedMessage: ");
            
            emailBody = `${errorMessage}${newLineDelimiter}${innerExceptionStr}${innerException}${newLineDelimiter}${detailMsgStr}${detailedMessage}`;
            errorMsgStringForJsonObj = `${innerException}-${detailedMessage}`;
        }
        else if (innerException && detailedMessage == null) {
            let innerExceptionStr = LocalizationStore.getTranslatedData("er_innerException", "Inner Exception: Exception of type: ");
            emailBody = `${errorMessage}${newLineDelimiter}${innerExceptionStr}${innerException}`;
            errorMsgStringForJsonObj = `${innerException}`;
        }
        else if (detailedMessage && innerException == "") {
            let detailMsgStr = LocalizationStore.getTranslatedData("er_detailMsg", "DetailedMessage: ");
            emailBody = `${errorMessage}${newLineDelimiter}${detailMsgStr}${detailedMessage}`;
            errorMsgStringForJsonObj = `${detailedMessage}`;
        }
        else {
            emailBody = errorMessage;
        }

        if(reqJSONObj) {
            const obj = {
                'User': user,
                'Last Symbol Loaded': LastSymbolLoaded,
                'Server': server ? server : '',
                'isClientEndIssue': !server, 
                'ServiceUriName': serviceUriName ? serviceUriName : '', 
                'Version': version,
                'ErrorMessage': errorMsgStringForJsonObj
            }
            return obj;
        }
        let encodeEmailBody = encodeURIComponent(emailBody);
        return encodeEmailBody;
    }

    getDetailedException(errorReport) {
        if(StringUtil.isEmpty(errorReport)) return;
        let detailedMessage = errorReport.errorShortDesc;
        if (errorReport.errorCode != null && errorReport.errorCode == '-1') {
            detailedMessage = errorReport.errorCodeData.errorDescription + " " + errorReport.errorShortDesc;
        }
        return detailedMessage;
    }

    getInnerException(errorReport) {
        let innerException = "";
        if (errorReport.isWCFService) {
            innerException = errorReport.errorData;
        }
        else if (StringUtil.isEmpty(errorReport.errorCodeData)) {
            return errorReport;
        }
        else {
            innerException = errorReport.errorCodeData.desktopMessage;
        }

        if (!errorReport.isWCFService && errorReport.errorCodeData.desktopMessage == "") {
            const errorCode = errorReport.errorCodeData.errorCode;

            switch (errorCode) {
                case ErrorCodes.EXPIRED_CACHE_ID:
                    innerException = "Expired cache Id";
                    break;

                case ErrorCodes.SESSION_INVALID_ERROR:
                    innerException = "Session Invalid Error";
                    break;

                case ErrorCodes.SQL_TIMED_OUT:
                    innerException = "SQL Timed Out";
                    break;

                case ErrorCodes.SOCKET_EXCEPTION_ERR_DB:
                    innerException = "Socket Exception Error DB"
                    break;

                case ErrorCodes.SOCKET_EXCEPTION_ERR_SVC:
                    innerException = "SQL Timed Out";
                    break;

                case ErrorCodes.MAX_ALERTS_REACHED:
                    innerException = "Maximum Alerts Reached";
                    break;

                case ErrorCodes.PASSWORD_CHANGE_ERROR:
                    innerException = "Password Change Error";
                    break;

                case ErrorCodes.ALERTS_EXCEPTION:
                    innerException = "Alerts Exception";
                    break;

                case ErrorCodes.INSTRUMENT_ALERTS_REACHED:
                    innerException = "Instrument Alerts Reached";
                    break;

                case ErrorCodes.LIST_NOT_EXISTED:
                    innerException = "List Not Existed";
                    break;

                case ErrorCodes.COLSET_NOT_EXISTED:
                    innerException = "Closet Not Existed";
                    break;

                case ErrorCodes.CUSTOMCOL_DELETE:
                    innerException = "Custom Column Delete";
                    break;

                case ErrorCodes.LIST_CUSTOMCALCUPSERT_FAILED:
                    innerException = "List CustomCalcupsert Failed";
                    break;

                case ErrorCodes.LIST_CUSTOMCALC_FAILED:
                    innerException = "List CustomCalc Failed";
                    break;

                default:
                    break;
            }
        }
        return innerException;
    }

    dispatcherCallback() {
        return true;
    }
}

const Exception = new ExceptionClass();
export default Exception;

