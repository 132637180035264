import DefaultDataGraphSetting from "./DefaultDataGraphSettings.js";
import Serializable from "../../../../../Serialization/Serializable.js";
import SmartViewType from "SmartViewType";

export default class EtfViewSettings extends Serializable {


  onSeralisedInfoRecieved(info) {
    this.SummaryBlockSettings = info.SummaryBlockSettings ? info.SummaryBlockSettings : DefaultDataGraphSetting.getDefaultIsSummaryBlockOpen();
    this.OwnershipBlockSettings = info.OwnershipBlockSettings ? info.OwnershipBlockSettings : DefaultDataGraphSetting.getDefaultOwnershipBlockSettings();
    this.RsiChartSettings = info.RsiChartSettings ? info.RsiChartSettings : DefaultDataGraphSetting.getRSiChartSetting();
    this.TechChartSettings = info.TechChartSettings ? info.TechChartSettings : DefaultDataGraphSetting.getTechChartSetting('ETF');
    this.VolumeBlockSettings = info.VolumeBlockSettings ? info.VolumeBlockSettings : DefaultDataGraphSetting.getDefaultVolumeBlockSettings(false);
    this.StochasticsChartSettings = info.StochasticsChartSettings ? info.StochasticsChartSettings : DefaultDataGraphSetting.getStochasticsChartSetting();
    this.WonStochasticsChartSettings = info.WonStochasticsChartSettings ? info.WonStochasticsChartSettings : DefaultDataGraphSetting.getWonStochasticsChartSetting();
    this.WonStochasticsUsageSettings = info.WonStochasticsUsageSettings ? info.WonStochasticsUsageSettings : DefaultDataGraphSetting.getWonStochasticsUsageSetting();
    this.MacdChartSettings = info.MacdChartSettings ? info.MacdChartSettings : DefaultDataGraphSetting.getMacdChartSetting();
    this.AccDistChartSettings = info.AccDistChartSettings ? info.AccDistChartSettings : DefaultDataGraphSetting.getAccDistChartSetting(false);
    this.ScaleLabelSettings = info.ScaleLabelSettings ? info.ScaleLabelSettings : DefaultDataGraphSetting.getScaleLabelSettings();
    this.CorporateEventsSettings = info.CorporateEventsSettings ? info.CorporateEventsSettings : DefaultDataGraphSetting.getCorporateEventsSetting(true);
    this.PatternRecognitionSettings = info.PatternRecognitionSettings ? info.PatternRecognitionSettings : DefaultDataGraphSetting.getPatternRecognitionSetting();
    this.YTDChartSettings = info.YTDChartSettings ? info.YTDChartSettings : DefaultDataGraphSetting.getYTDChartSetting();
    this.YTD1ChartSettings = info.YTD1ChartSettings ? info.YTD1ChartSettings : DefaultDataGraphSetting.getYTD1ChartSetting();
    this.YTD3ChartSettings = info.YTD3ChartSettings ? info.YTD3ChartSettings : DefaultDataGraphSetting.getYTD3ChartSetting();
    this.YTD5ChartSettings = info.YTD5ChartSettings ? info.YTD5ChartSettings : DefaultDataGraphSetting.getYTD5ChartSetting();
    this.YTD10ChartSettings = info.YTD10ChartSettings ? info.YTD10ChartSettings : DefaultDataGraphSetting.getYTD10ChartSetting();
    this.HiLoPriceSettings = info.HiLoPriceSettings ? info.HiLoPriceSettings : DefaultDataGraphSetting.getHiLoPriceSetting();
    this.HiLoPctSettings = info.HiLoPctSettings ? info.HiLoPctSettings : DefaultDataGraphSetting.getHiLoPctSetting();
    this.RelativeStrenghtLine1Settings = info.RelativeStrenghtLine1Settings && info.RelativeStrenghtLine1Settings[1] ? info.RelativeStrenghtLine1Settings : DefaultDataGraphSetting.getRelativeStrenghtLineSetting(1, false);
    this.RelativeStrenghtLine2Settings = info.RelativeStrenghtLine2Settings && info.RelativeStrenghtLine2Settings[1] ? info.RelativeStrenghtLine2Settings : DefaultDataGraphSetting.getRelativeStrenghtLineSetting(2, false);
    this.RelativeStrenghtLine1SettingsByPeriodicity = info.RelativeStrenghtLine1SettingsByPeriodicity ? info.RelativeStrenghtLine1SettingsByPeriodicity : DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(1, false);
    this.RelativeStrenghtLine2SettingsByPeriodicity = info.RelativeStrenghtLine2SettingsByPeriodicity ? info.RelativeStrenghtLine2SettingsByPeriodicity : DefaultDataGraphSetting.getRelativeStrenghtLineSettingByPeriodicity(2, false);
    this.IndexLineSettings = info.IndexLineSettings && info.IndexLineSettings[1] ? info.IndexLineSettings : DefaultDataGraphSetting.getIndexLineSetting(false);
    this.IndexLineSettingsByPeriodicity = info.IndexLineSettingsByPeriodicity ? info.IndexLineSettingsByPeriodicity : DefaultDataGraphSetting.getIndexLineSettingByPeriodicity(false);
    this.MASettings = info.MASettings ? info.MASettings : DefaultDataGraphSetting.getMovingAverageSetting();
    this.TiMenuSettings = info.TiMenuSettings ? info.TiMenuSettings : DefaultDataGraphSetting.getTiMenuSettings(SmartViewType.ETF);
    this.OffHighTag = info.OffHighTag ? info.OffHighTag : "OH";
    this.ExternalDataSettings =  info.ExternalDataSettings ? info.ExternalDataSettings : DefaultDataGraphSetting.getExternalDataSettings();
    this.EDMultiplierSettings = info.EDMultiplierSettings ? info.EDMultiplierSettings : DefaultDataGraphSetting.getMultiplierSetting(false);
    this.ExternalDataRiPanelSettings = info.ExternalDataRiPanelSettings ? info.ExternalDataRiPanelSettings : DefaultDataGraphSetting.getExternalDataRiPanelSettings();
  }

  getDataObject() {
    return {
      VolumeBlockSettings: this.VolumeBlockSettings,
      SummaryBlockSettings: this.SummaryBlockSettings,
      OwnershipBlockSettings: this.OwnershipBlockSettings,
      RsiChartSettings: this.RsiChartSettings,
      TechChartSettings: this.TechChartSettings,
      StochasticsChartSettings: this.StochasticsChartSettings,
      WonStochasticsChartSettings: this.WonStochasticsChartSettings,
      WonStochasticsUsageSettings: this.WonStochasticsUsageSettings,
      MacdChartSettings: this.MacdChartSettings,
      AccDistChartSettings: this.AccDistChartSettings,
      ScaleLabelSettings: this.ScaleLabelSettings,
      CorporateEventsSettings: this.CorporateEventsSettings,
      PatternRecognitionSettings: this.PatternRecognitionSettings,
      YTDChartSettings: this.YTDChartSettings,
      YTD1ChartSettings: this.YTD1ChartSettings,
      YTD3ChartSettings: this.YTD3ChartSettings,
      YTD5ChartSettings: this.YTD5ChartSettings,
      YTD10ChartSettings: this.YTD10ChartSettings,
      HiLoPriceSettings: this.HiLoPriceSettings,
      HiLoPctSettings: this.HiLoPctSettings,
      IndexLineSettings: this.IndexLineSettings,
      IndexLineSettingsByPeriodicity: this.IndexLineSettingsByPeriodicity,
      RelativeStrenghtLine1Settings: this.RelativeStrenghtLine1Settings,
      RelativeStrenghtLine2Settings: this.RelativeStrenghtLine2Settings,
      RelativeStrenghtLine1SettingsByPeriodicity: this.RelativeStrenghtLine1SettingsByPeriodicity,
      RelativeStrenghtLine2SettingsByPeriodicity: this.RelativeStrenghtLine2SettingsByPeriodicity,
      MASettings: this.MASettings,
      TiMenuSettings: this.TiMenuSettings,
      OffHighTag: this.OffHighTag,
      ExternalDataSettings: this.ExternalDataSettings,
      ExternalDataRiPanelSettings: this.ExternalDataRiPanelSettings
    };
  }

  getObjectMapKey() {
    return "EtfViewSettings";
  }
  getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness) {
    return DefaultDataGraphSetting.getMovingAverageSettingObject(ma, maType, isVisible, lineColor, lineThickness);
  }
  getDefaultDataGraphSetting() {
    return DefaultDataGraphSetting.getCorporateEventsSetting(true);
  }
}
