import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { toggleBenchMarkSelection } from 'Actions/RotationGraphActions';

class BenchMarkSelector extends PureComponent {
    render() {
        const cursor = this.props.isEmpty ? 'not-allowed' : 'pointer';
        
        return (
            <div className="benchmark" style={{ color: 'white' }}>
                <span className="heads">Benchmark:</span>
                {this.props.benchMarkOption && <span className="value" style={{ cursor: cursor, textDecoration: 'underline' }} onClick={this.props.toggleBenchMarkSelection}>{this.props.benchMarkOption} Index</span>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { benchMarkOption } = state.rotationGraph.BenchMarkReducer.SymbolsData;
    const { selectedOsid } = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer.RotationMenuStates;

    return { benchMarkOption, isEmpty: selectedOsid == "" };

}

const mapDispatchToProps = (dispatch) => ({
    toggleBenchMarkSelection: () => dispatch(toggleBenchMarkSelection())
});

export default connect(mapStateToProps, mapDispatchToProps)(BenchMarkSelector);