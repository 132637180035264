import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

import DateHelper from "../../Utils/TimeLineHelper/Datehelper.js"

export default class GetVolumeChangeRequest extends BaseServiceRequest {
    constructor(findGetDatagraphRequest,Osid,IsNASDAQBasic,isHistoric) {
        super();
        super.addRequestParameter("symbol", findGetDatagraphRequest.Symbol);
        super.addRequestParameter("Periodicity", findGetDatagraphRequest.Periodicity);
        super.addRequestParameter("IsHistoric", isHistoric);
        super.addRequestParameter("IsNASDAQBasic", IsNASDAQBasic);
        super.addRequestParameter("StartDateString", DateHelper.getDateString(findGetDatagraphRequest.beginDate) + " " + DateHelper.getTimeString(findGetDatagraphRequest.beginDate));
        super.addRequestParameter("EndDateString", DateHelper.getDateString(findGetDatagraphRequest.endDate) + " " + DateHelper.getTimeString(findGetDatagraphRequest.endDate));
        super.addRequestParameter("osid", Osid);
        super.addRequestUri("volumechange.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "VolumeDataResponse";
    }
}
