import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
class GetCfbMetricsRequest extends BaseServiceRequest {
    constructor(isQuarterly) {
        super();
        super.addRequestUri("cfbmetrics.pb");
        super.addRequestParameter("isQuarterly", isQuarterly ? "1" : "0");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "CFBMetricLibraryResponse";
    }
}

export default GetCfbMetricsRequest;