import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetDgHoldingsReportRequestExt extends BaseServiceRequest {
    constructor(msid, orderString, resultCount, currentTab, currentSubTab, limitData) {

        super();
        super.addRequestUri("holdingsreportext.pb");//holdingsreportext
        super.addRequestParameter("msid", msid);
        super.addRequestParameter("sector", currentTab.toString());
        super.addRequestParameter("subType", currentSubTab.toString());
        super.addRequestParameter("limitData", limitData.toString());
        if (orderString) {
            super.addRequestParameter("orderString", orderString);
        }
        if (resultCount) {
            super.addRequestParameter("resultCount", resultCount);
        }

    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "HoldingsReportResponseExt";
    }
}

export default GetDgHoldingsReportRequestExt;
