import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetListExplorerRequest extends BaseServiceRequest {

  constructor(dbId, consoleId, isUserOnly, isSharedLists, parent = '') {
    super();
    super.addRequestUri("explorer.pb");
    if (dbId != '') super.addPathParameter(dbId.toString());
    super.addRequestParameter("c", consoleId);
    super.addRequestParameter("uo", isUserOnly.toString());
    if (parent != '') super.addRequestParameter("p", parent.toString());

    super.addRequestParameter("is", isSharedLists);  //contains the share lists
    //super.addRequestParameter("version","4.3.15.2");
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.LIST;
  }

  getMessageName() {
    return "ListExplorerResponse";
  }
}

export default GetListExplorerRequest;
