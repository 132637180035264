import React, { Component } from 'react';
import { connect } from 'react-redux';
import SettingsStore from "SettingsStore";
import UserInfoUtil from "Utils/UserInfoUtil";
import BaseServiceApi from 'BaseServiceApi';
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import { Link, Route, Redirect, Switch } from 'react-router-dom';
import { Button } from "react-bootstrap";
import CompareTabType, { CompareMenuType } from "CompareTabType";
import ComparisonGraph from "./ComparisonGraph/ComparisonGraph.jsx";
import RotationGraph from "./RotationGraph/RotationGraph.jsx";
import { updateComparisonTabSelection } from 'SettingsAction';
import { PrintMode } from "../../../print/printmode";
import ConsoleStore from "ConsoleStore";
import ReduxStore from "../../../Redux/store";
import { initializeData } from '../../../Actions/ComparisonAndRotationMenuActions';
import { getBenchMarkState } from '../../../Reducers/NavCompare/RotationGraphReducers/selectors';
import BenchMarkSelector from './RotationGraph/Presentational/BenchMarkSelector';
import { getRotationGraphActiveMenuName } from '../../../Reducers/NavCompare/RotationGraphReducers/reselectorsdata';

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
let isinit = false;

class NavCompare extends Component {

    UNSAFE_componentWillMount = () => {

        this.props.updateCompareTabSelection(SettingsStore.getConsoleSettings().NavCompareSettings.SelectedTabKey);
    }

    getComparisionView = (selectedTabKey) => {
        if (UserInfoUtil.IsUserEntitled(EntitlementType.RAY_COMPARISION_CHART_Entitlement)) {
            return <ComparisonGraph selectedTabKey={selectedTabKey} />
        }
        else {
            this.props.updateCompareTabSelection(CompareTabType.RotationGraph);
            return <Redirect to={`/Symbol`} />;
        }
    }

    getLeftFooterInfo() {
        const year = ConsoleStore.getStates() && ConsoleStore.getStates().endDate ? ConsoleStore.getStates().endDate.getFullYear() : new Date().getFullYear();
        return `${year} WILLIAM O'NEIL + CO. INC`;
    }

    getRightFooterInfo(selectedTabKey) {
        const { groupsTimelineDateDictionary, selectedSliderValue } = this.props;
        let { startDate, endDate } = this.props;
        let today = new Date();
        let consoleState = ConsoleStore.getStates();
        if (consoleState && consoleState.endDate) {
            today = new Date(consoleState.endDate);
        }
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let dd = this.appendLeadingZeroes(today.getDate());
        let mm = months[today.getMonth()]
        let yyyy = today.getFullYear();

        const calendarInfo = dd + ' ' + mm + ' ' + yyyy;

        if (selectedTabKey === CompareTabType.ComparisonGraph) {
            startDate = startDate.replace(/-/g, '/');
            endDate = endDate.replace(/-/g, '/');

            return `Start Date: ${startDate} End Date: ${endDate} as of ${calendarInfo}`;
        }
        else if (selectedTabKey === CompareTabType.RotationGraph) {
            let benchMarkDate = groupsTimelineDateDictionary[selectedSliderValue];
            if (benchMarkDate) {
                dd = this.appendLeadingZeroes(benchMarkDate.getDate());
                mm = months[benchMarkDate.getMonth()]
                yyyy = benchMarkDate.getFullYear();
                benchMarkDate = dd + ' ' + mm + ' ' + yyyy;

                return `Date as of ${benchMarkDate} | Printed as of ${calendarInfo}`;
            }
            return;
        }
    }

    appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n
    }

    getHeaderInfo(selectedTabKey) {
        const { isSymbolsMenuTabSelected } = this.props;
        if (selectedTabKey === CompareTabType.ComparisonGraph) {
            const reducerState = ReduxStore.getState();
            const { periodicity } = reducerState.comparisonGraph.calendarControl[isSymbolsMenuTabSelected ? "SymbolsData" : "GroupsData"];

            return `${periodicity} Price % Change Comparison Graph`;
        }
        else if (selectedTabKey === CompareTabType.RotationGraph) {
            return `Rotation Graph`;
        }
    }

    renderTabs = (tabitem, selectedTabKey) => {
        let active = "", disable = false, header = "", to = "rotation";
        switch (tabitem.TabType) {
            case CompareTabType.ComparisonGraph:
                if (selectedTabKey === CompareTabType.ComparisonGraph) {
                    active = "active"; disable = false;
                }
                to = "/comparision";
                if (!UserInfoUtil.IsUserEntitled(EntitlementType.RAY_COMPARISION_CHART_Entitlement)) {
                    active = ""; disable = true;
                }
                header = LocalizationStore.getTranslatedData("CCG_" + tabitem.TabType.replace(" ", ""), tabitem.Header);
                break;
            case CompareTabType.RotationGraph:
                if (selectedTabKey === CompareTabType.RotationGraph) { active = "active"; disable = false; }
                to = "/rotation"
                header = LocalizationStore.getTranslatedData("CRG_" + tabitem.TabType.replace(" ", ""), tabitem.Header);
                break;
            default:
                header = "";
                break;
        }

        return (
            <Link replace={true} to={`${this.props.match.url}${to}`} key={tabitem.Header}>
                <Button className={active} bsSize="small" onClick={() => this.props.updateCompareTabSelection(tabitem.Header)} disabled={disable}>{header}</Button>
            </Link>
        )
    }

    render() {
        const settings = SettingsStore.getConsoleSettings().NavCompareSettings;
        const tabCollection = settings.TabCollection;
        const selectedTabKey = settings.SelectedTabKey;
        let paths = "/rotation";
        let isRotationTabActive = true;
        let printClass = "print-rotation";

        if (selectedTabKey === CompareTabType.ComparisonGraph) {
            paths = "/comparision"
            printClass = "print-comparison";
            isRotationTabActive = false;
        }
        if (PrintMode.printing) {
            paths = `${paths}?print=true&workspaceId=${PrintMode.workspaceId}`;
        }
        const consoleClass = PrintMode.printing ? `${printClass} compare_block panarayConsole print-mode` : "panarayConsole compare_block";
        const leftFooterInfo = this.getLeftFooterInfo();
        const rightFooterInfo = this.getRightFooterInfo(selectedTabKey);
        const headerInfo = this.getHeaderInfo(selectedTabKey);

        return (
            <div className={consoleClass}>
                <div className="extender">
                    <div className="print-compare-title">{headerInfo}</div>
                    {isRotationTabActive && this.props.isSymbolsMenuTabSelected && <BenchMarkSelector />}
                    {!PrintMode.printing && <div className="right-tool-items">
                        <span className="lead-tabs">
                            {tabCollection.map((tabitem) =>
                                this.renderTabs(tabitem, selectedTabKey)
                            )}
                        </span>
                    </div>
                    }
                    <Switch>
                        <Route
                            exact
                            path={this.props.match.url}
                            render={() => <Redirect exact from="/" to={`${this.props.match.path}${paths}`} />}
                        />
                        <Route exact path={`${this.props.match.path}/comparision`} render={() => this.getComparisionView(selectedTabKey)} />
                        <Route exact path={`${this.props.match.path}/rotation`} render={() => <RotationGraph selectedTabKey={selectedTabKey} />} />
                        <Redirect to={`${this.props.match.path}${paths}`} />
                    </Switch>
                    <div className="print-footer">
                        <div className="print-copyright">{leftFooterInfo}</div>
                        <div className="print-symbol-info">{rightFooterInfo}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { selectedTab, ComparisonMenuTab, RotationMenuTab } = state.comparisonAndRotationMenuPanel.MenuTabReducer;
    const isSymbolsMenuTabSelected = selectedTab === CompareTabType.ComparisonGraph ? ComparisonMenuTab === CompareMenuType.Symbols : RotationMenuTab === CompareMenuType.Symbols;

    let benchMarkState = state.rotationGraph.BenchMarkReducer.GroupsData;
    let calendarState = state.comparisonGraph.calendarControl.GroupsData;
    if (isSymbolsMenuTabSelected) {
        benchMarkState = state.rotationGraph.BenchMarkReducer.SymbolsData;
        calendarState = state.comparisonGraph.calendarControl.SymbolsData;
    }
    const { groupsTimelineDateDictionary, selectedSliderValue } = benchMarkState;
    const { startDate, endDate } = calendarState;
    return { groupsTimelineDateDictionary, selectedSliderValue, isSymbolsMenuTabSelected, startDate, endDate };
};


const mapDispatchToProps = (dispatch) => ({
    updateCompareTabSelection: (tabKey) => dispatch(updateComparisonTabSelection(tabKey)),

});

export default connect(mapStateToProps, mapDispatchToProps)(NavCompare);