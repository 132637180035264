import {
    ExternalDataListMenu,
    ExternalDataListMenu_MNodeSel,
    ExternalDataListMenu_Shared,
    ExternalDataListMenu_ShareMNodeSel
  } from "../Constants/ExternalDataUploader.js";
export const getListMenu = (selectedNodeIds,accessType,listType) => {
    const selectdNodLenth = selectedNodeIds.length;
    let leftMenu = [];
    if (selectdNodLenth<1) {
        leftMenu =  ExternalDataListMenu;
        if(accessType != null){
            leftMenu =  ExternalDataListMenu_Shared;
        }
    }
    else{
        if(listType > 7){
            leftMenu =  ExternalDataListMenu_ShareMNodeSel;
        }else{
            leftMenu =  ExternalDataListMenu_MNodeSel;
        }
    }
    return leftMenu;
}

export const getExplorerUploaderWindowWidth = () => {     
    let listExplorerElm = document.getElementById("listExplorer"); 
   
    //let resizeElement = listExplorer.getElementsByClassName('list-explorer-resize');
    let width = null;
    width = 80;
    //return resizeElement[0].clientWidth - width + 'px';
    return listExplorerElm.clientWidth - width + 'px';
}