import _ from "underscore";
import { HubUserRole } from "../../../../../../Constants/HubRIPanelConstants";
import HubUtil from "../../../../../../Utils/HubUtil";
import moment from "moment";
import React from "react";
import UserInfoUtil from "UserInfoUtil";

const formatter = new Intl.NumberFormat();

export default class HubCollapseItem extends React.Component {

    constructor(props) {
        super(props);

        this.onItemClicked = this.onItemClicked.bind(this);
    }

    getHubLink(actionID, ID) {
        let linkURL = "";
        const hubURL = HubUtil.getHubURL();
        linkURL = `${hubURL}?actionID=${actionID}&ID=${ID}`;
        return linkURL;
    }

    getContactDetail(item) {
        const data = [];
        item.hubFunds.length === 0 ? data.push(<li key={1}>No Funding Available</li>) : _.each(item.hubFunds, (funding, index) => {
            let percentageOfPortfolio = parseFloat(funding.percentageOfPortfolio * 100).toFixed(1);
            percentageOfPortfolio = percentageOfPortfolio === "NaN" ? "0.0" : percentageOfPortfolio;
            const shares = formatter.format(funding.sharesHeld);
            data.push(<li key={index ? index : 0}>
                <div onClick={(e) => this.onHubTargetClicked(e, true, true, 2, funding.hubFundID.low)} className="funding" title={funding.fundName}>
                    <span className="ellipsis hub-target">
                        {funding.fundName}
                    </span>
                </div>
                <div style={{ clear: "both" }}></div>
                <div onClick={(e) => this.onHubTargetClicked(e, false, true)} className="detail"><span className="label">{percentageOfPortfolio}%</span> of Portfolio | <span title={shares} className="label share">{shares}</span> Shares</div>
                {Boolean(item.recommendation) ? <div style={{ clear: "both" }}></div> : ''}
                {Boolean(item.recommendation) ? <span onClick={(e) => this.onHubTargetClicked(e, true, true, 3, item.hubContactID.low)} className="recommendation hub-target" title={item.recommendation}>{item.recommendation}</span> : ''}
            </li>)
        });

        return <ul>{data}</ul>;
    }

    onItemClicked(e, index) {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onItemClicked) {
            this.props.onItemClicked(index)
        }
    }

    onHubEmailClicked(e, emailID, emailItem = null) {
        e.preventDefault();
        e.stopPropagation();
        //console.log("emailItem", emailItem);

        let linkURL = "";
        const hubURL = HubUtil.getHubURL();
        linkURL = `${hubURL}?actionID=4&emailID=${emailID}`

        const user = UserInfoUtil.getUserInfo();
        if (Boolean(user) && Boolean(user.hubRole) && user.hubRole === HubUserRole.Manager) {
            linkURL += `&osid=${emailItem.Osid}&qtrEndDate=${moment(emailItem.QtrEndDate).format("YYYY-MM-DD")}`;
        }

        window.open(linkURL);
    }

    onHubTargetClicked(e, isTarget = true, isExpanded = false, actionID = 0, ID = 0) {
        if (isExpanded) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (actionID === 0 || ID === 0) { return; }

        if (isTarget) {
            window.open(this.getHubLink(actionID, ID));
        }
    }

    getSalesRepDetail(item) {
        const data = [];
        item.hubContacts.length === 0 ? data.push(<li key={1}>No Contact Available</li>) : _.each(item.hubContacts, (contact, index) => {
            data.push(<li className="rep" key={index ? index : 0}>
                <div onClick={(e) => this.onHubTargetClicked(e, true, true, 3, item.hubContacts[index].hubContactID.low)} className="contact hub-target" title={contact.contactName}>
                    <span className="ellipsis hub-target">
                        {contact.contactName}
                    </span>
                </div>
                <div style={{ clear: "both" }}></div>
                <div onClick={(e) => this.onHubTargetClicked(e, true, true, 1, item.hubContacts[index].hubSponsorID.low)} className="institution hub-target" title={contact.institution}>
                    {contact.institution}
                </div>
                {this.getContactDetail(contact)}
            </li>)
        });

        return <ul>{data}</ul>;
    }

    render() {
        const { item, index, showStar, manager, top } = this.props;
        const isEvenItem = index % 2 === 0 ? true : false;
        //console.log(`${item}`);
        //console.log(`${hubTargetLink}`);

        return (
            !Boolean(manager) ?
                <div className={isEvenItem ? "collapse-item odd" : "collapse-item"} style={{ top: top, position: "absolute" }} onClick={(e) => this.onItemClicked(e, index)}>
                    <div className="collapse-item-line1">
                        <div onClick={(e) => this.onHubTargetClicked(e, true, item.expanded, 3, item.hubContactID.low)} className={item.expanded ? "collapse-item-column1 hub-target expanded" : "collapse-item-column1 hub-target"} title={item.contactName} >{item.contactName}</div>
                        <div onClick={(e) => this.onHubEmailClicked(e, item.contactEmail, item)} className="icn-collapse-item-email"></div>
                        {showStar && <div className="icn-collapse-item-column2"></div>}
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div className={item.expanded ? "collapse-item-line2 expanded" : "collapse-item-line2"} title={item.institution} >
                        <span onClick={(e) => this.onHubTargetClicked(e, true, item.expanded, 1, item.hubSponsorID.low)} className="ellipsis hub-target">
                            {item.institution}
                        </span>
                    </div>
                    {item.expanded && <div className="collapse-item-detail">
                        <div onClick={(e) => this.onHubTargetClicked(e, false, true)} className="collapse-item-line3">Preferred Phone: <span title={item.preferredPhone}>{item.preferredPhone}</span></div>
                        {this.getContactDetail(item)}
                    </div>}
                </div> :
                <div className={isEvenItem ? "collapse-item hub-target odd" : "collapse-item hub-target"} style={{ top: top, position: "absolute" }} onClick={(e) => this.onItemClicked(e, index)}>
                    <div className="collapse-item-line1">
                        <div className={item.expanded ? "collapse-item-column1 expanded" : "collapse-item-column1"} title={item.userName} >{item.userName}</div>
                        <div onClick={(e) => this.onHubEmailClicked(e, item.email, item)} className="icn-collapse-item-email"></div>
                        {showStar && <div className="icn-collapse-item-column2"></div>}
                    </div>
                    <div className={item.expanded ? "collapse-item-line2 expanded" : "collapse-item-line2"} onClick={(e) => this.onItemClicked(e, index)}>{Boolean(item.hubContacts) ? item.numOfContact.low : 0} clients</div>
                    {item.expanded && <div className="collapse-item-detail">
                        {this.getSalesRepDetail(item)}
                    </div>}
                </div>
        )
    }
}