import { put, call, takeLatest, select ,all } from 'redux-saga/effects';
import { ExternalDataConstants } from "../../Constants/ExternalDataUploader";
import DataUploadsApi from '../../ServiceApi/Apis/DataUploadsApi';
import ListStore from "ListStore";
const { ActionTypes } = ExternalDataConstants;
import ListActions from "ListActions";
import { storedListItems, activeListId, getSortColumns, currentSelectedList, getLastPageIndex, startWizardlistId, rowSourceCount,
    startSymbolCol,startDateCol,startDateFormat,uploadResponse,updatedSymbolCol,updatedDateCol,updatedDateFormat,selectStartWizard,
    exceptionMsg } from '../../Reducers/ExternalDataUploadReducer/selectors';
import { each, find } from "underscore";
import BrowserUtil from "BrowserUtil";
import { loadExternalList } from '../../Actions/ExternalDataUploaderActions';
import ListManagerTabType from "../../Constants/ListManagerTabType.js";
/***************************** Subroutines ************************************/
const pageSize = 250;
function* initGridLoad({ gridData }) {

    try {

        yield put({
            type: ActionTypes.INIT_GRID_LOAD_SUCCESS,
            clientHeight: gridData.clientHeight,
            gridAreaWidth: gridData.gridAreaWidth,
            offsetWidth: gridData.offsetWidth,
            recordsPerBody: gridData.recordsPerBody,
            viewPortHeight: gridData.viewPortHeight,
            viewportWidth: gridData.viewportWidth
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs - , initGridLoad ${error}`);
    }
}

function* getSource({ listId,initCall=false,listType }) {
    let result = [];
    let setStartWizard = false;
    listId = parseInt(listId);
    let selectedTab = ListStore.getselectedTabKey();  
    try {        
        if (listId && listId > 0) {
            if(initCall){
                yield call(resetDefault);
            }
            
            const prevlistId = yield select(activeListId);
            let listData = yield call(findActiveListType, listId);
            if ((listData.listType != 1 || listData.isReccuring) && (!listData.isEmpty && listData.isEmpty != null)) {
                const lastPageIndex = yield select(getLastPageIndex);
                const symbolCol = yield select(startSymbolCol);
                const dateCol = yield select(startDateCol);
                const dateFormat = yield select(startDateFormat);
                result['listId'] = listId;                
                //ListActions.setSelectedExternalList(result);
                // ListActions.setInitialExternalList(listId,listData.name);
                result = yield call(DataUploadsApi.getCurrentListData, listId, 0, '', '', '', lastPageIndex, pageSize);
                BrowserUtil.enableEvents();
                
                let lastUploadList = yield select(startWizardlistId);
                const isStartWizard = yield select(selectStartWizard);
                if(lastUploadList == listId && isStartWizard )
                    setStartWizard = true;
                    
                if(result == null || (result.externalData && result.externalData.headers.length == 0)){
                    yield put({
                        type: ActionTypes.LOAD_EXTERNAL_LIST_FAIL,
                        isNewList: true,
                    })
                    result = [];
                    result['listId'] = listId;
                    result['listName'] = listData.name;
                    result['listType'] = listData.listType;
                    result['shareAccess'] = listData.shareAccess;
                    yield put({
                        type: ActionTypes.LOAD_EXTERNAL_LIST_SUCCESS,
                        source: result ? result : [],
                        isNewList: true,
                        startWizard: setStartWizard,
                        selectSymbol: symbolCol,
                        selectDate: dateCol,
                        selectDateFormat: dateFormat
                    });
                    ListActions.setSelectedExternalList(result);
                    if(selectedTab == ListManagerTabType.External){
                        ListStore.updateSelectedListItem(listId, listId, listData.name);
                    }
                    ListActions.setSelectedExternalListInfo(listId, listData.name, 0, 0);
                }else{
                if (result && result.externalData) {
                    result.externalData['listType'] = listData.listType;       
                    ListActions.setSelectedExternalList(result.externalData);
                    yield put({
                        type: ActionTypes.LOAD_EXTERNAL_LIST_SUCCESS,
                        source: result.externalData,
                        isNewList: false,
                        startWizard: setStartWizard,
                        selectSymbol: symbolCol,
                        selectDate: dateCol,
                        selectDateFormat: dateFormat
                    });
                    let rowsCount = yield select(rowSourceCount);
                      yield call(getInitialSortInfo,result.externalData.sortInfo);
                    if(initCall){
                        if(selectedTab == ListManagerTabType.External){
                            ListStore.updateSelectedListItem(result.externalData.listId, result.externalData.listId, result.externalData.listName);
                        }
                        ListActions.setSelectedExternalListInfo(result.externalData.listId, result.externalData.listName, rowsCount, result.externalData.count, result.externalData.lastUploadDateTime);
                    }
                } else {
                    console.log(`Error occurs - externalDataView.js, getSource ${error}`);
                } }
            } else {
                result['listId'] = listId;
                result['listName'] = listData.name;
                result['listType'] = listData.listType;
                result['shareAccess'] = listData.shareAccess;
                yield put({
                    type: ActionTypes.LOAD_EXTERNAL_LIST_SUCCESS,
                    source: result ? result : [],
                    isNewList: true,
                    startWizard: setStartWizard
                });
                ListActions.setSelectedExternalList(result);
                if(selectedTab == ListManagerTabType.External){
                    ListStore.updateSelectedListItem(listId, listId, listData.name);
                }
                ListActions.setSelectedExternalListInfo(listId, listData.name, 0, 0);
            }
            
            if(listId != prevlistId){
                yield put({
                    type: ActionTypes.SET_START_WIZARD_ID_EMPTY
                });
            }
        } else {
            yield put({
                type: ActionTypes.LOAD_EXTERNAL_LIST_SUCCESS,
                source: [],
                isNewList: true,
                showWizardDialog: false,
                startWizard: false,
                listType: listType
            });
        }
        yield put({
            type: ActionTypes.CLOSE_UPLOAD_WIZARD
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs - externalDataView.js, getSource ${error}`);
    }
}

function* findActiveListType(listId) {

    const explorerLists = yield select(storedListItems);
    const arrayed = [];
    explorerLists.forEach(itm => arrayed.push(itm.childNodes));
    const flatList = arrayed.reduce((arr, val) => arr.concat(val), []);
    const searchList = flatList.find((item) => item.nodeId.low == listId);;
    return searchList;
}
function* reSetOnDimensionChange({ dimension }) {

    try {
        yield put({
            type: ActionTypes.ON_GRID_DIMENSION_CHANGE_SUCCESS,
            dimension
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, reSetOnDimensionChange ${error}`);
    }

}

function* setVisibleRowsData({ rows }) {

    try {

        yield put({
            type: ActionTypes.SET_VISIBLE_ROWS_DATA_SUCCESS,
            rowsData: rows
        });
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs - , initGridLoad ${error}`);
    }
}

function* handleColumnSort({ evt, colData }) {
    try {
        yield call(resetDefault);
        yield call(updateSortColumns, evt, colData);
        let sortCols = yield select(getSortColumns);
        // console.log(sortCols);
        const listId = yield select(activeListId);
        let sort = yield call(getSortFormat, sortCols);
        if (listId > 0) {
            let result = yield call(DataUploadsApi.getCurrentListData, listId, 0, '', '', sort, 0, pageSize);
            if (result && result.externalData) {
                ListActions.setSelectedExternalList(result.externalData);
                yield put({
                    type: ActionTypes.LOAD_EXTERNAL_LIST_SUCCESS,
                    source: result.externalData,
                    isNewList: false
                });
                ListStore.updateSelectedListItem(result.externalData.listId, result.externalData.listId, result.externalData.listName);
                ListActions.setSelectedExternalListInfo(result.externalData.listId, result.externalData.listName, result.externalData.rows.length, result.externalData.count, result.externalData.lastUploadDateTime);
            }
        }
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs in chartViewModel.js, reSetOnDimensionChange ${error}`);
    }
}
function* updateSortColumns(evt, colData) {

    let sortCols = yield select(getSortColumns);
    let existCol = find(sortCols, (key) => colData.esFieldName === key.esFieldName);
   
    if (evt.ctrlKey || evt.metaKey) {
        if (existCol) {
            if (existCol.sortAsc) existCol.sortAsc = false;
            else existCol.sortAsc = true;
        }
        else {
            let obj = {};
            obj.esFieldName = colData.esFieldName;
            obj.sortAsc = true;
            obj.sortOrderNum = sortCols.length + 1;
            yield put({
                type: ActionTypes.ON_CHANGE_COLUMN_SORT_SUCCESS,
                sortCols: obj
            });
        }
    }
    else {
        yield call(clearSortColumns);
        if (existCol && !existCol.sortAsc)
        return true;
        let obj = {};
        obj.esFieldName = colData.esFieldName;
        obj.sortAsc = true;
        obj.sortOrderNum = 1;
        if (existCol && existCol.sortAsc) obj.sortAsc = false;
        yield put({
            type: ActionTypes.ON_CHANGE_COLUMN_SORT_SUCCESS,
            sortCols: obj
        });
    }
}

function getSortFormat(sortData) {
    if (sortData.length === 0)
        return 'default';
    let sortFormatedData = [];
    sortData && 
    each(sortData, (sortColumn) => {
        if (sortColumn) {
            sortFormatedData.push(`${sortColumn.esFieldName}:${+sortColumn.sortAsc}`);
        }
    });
    return sortFormatedData.join(';');
}
function* loadStartWizard({ listId,isListLoaded }) {
    try {
        BrowserUtil.disableEvents();
        let currentResponse = isListLoaded ?  yield select(currentSelectedList) : yield select(uploadResponse);
        let symbolColmn = '';
        let dateColumn = '';
        let dateFormat = '';
        let symbolCol = '';
        let dateCol = '';
        if(currentResponse && currentResponse.headers.length > 0){
            symbolColmn = currentResponse.headers.find((item) => {
                if (item.isSymbolColumn) {
                    return item.esFieldName;
                }
            })
            symbolCol = symbolColmn.esFieldName;
            dateColumn = currentResponse.headers.find((item) => {
                if (item.isDateColumn) {
                    return item.esFieldName;
                }
            }) 
            dateCol = dateColumn.esFieldName;
            dateFormat = dateColumn.dateFormat;
        }
        yield put({
            type: ActionTypes.SET_START_WIZARD_ID,
            wizardId:listId,
            identifiedSymbolCol: symbolCol,
            identifiedDateCol: dateCol,
            identifiedDateFormat: dateFormat
        })
        yield put({
            type: ActionTypes.UNLOAD_EXTERNAL_STATE_DATA
          });
        if(!isListLoaded)
            yield put(loadExternalList(listId, true));
        else
            BrowserUtil.enableEvents();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs - , loadStartWizard ${error}`);
    }
}
function* loadStartWizardDate({ listId,isListLoaded }) {
    try {
        BrowserUtil.disableEvents();
        let currentResponse = isListLoaded ?  yield select(currentSelectedList) : yield select(uploadResponse);
        let symbolColmn = '';
        let dateColumn = '';
        let dateFormat = '';
        let symbolCol = '';
        let dateCol = '';
        if(currentResponse && currentResponse.headers.length > 0){
            symbolColmn = currentResponse.headers.find((item) => {
                if (item.isSymbolColumn) {
                    return item.esFieldName;
                }
            })
            symbolCol = symbolColmn.esFieldName;
            dateColumn = currentResponse.headers.find((item) => {
                if (item.isDateColumn) {
                    return item.esFieldName;
                }
            })
            dateCol = dateColumn.esFieldName;
            dateFormat = dateColumn.dateFormat;
        }
        yield put({
            type: ActionTypes.SET_START_WIZARD_DATE,
            wizardId:listId,
            identifiedSymbolCol: symbolCol,
            identifiedDateCol: dateCol,
            identifiedDateFormat: dateFormat
        })
        yield put({
            type: ActionTypes.UNLOAD_EXTERNAL_STATE_DATA
          });
        if(!isListLoaded)
            yield put(loadExternalList(listId, true));
        else
            BrowserUtil.enableEvents();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs - , loadStartWizardDate ${error}`);
    }
}
function* loadStartWizardDateFormat({ listId,isListLoaded }) {
    try {
        BrowserUtil.disableEvents();
        let currentResponse = isListLoaded ?  yield select(currentSelectedList) : yield select(uploadResponse);
        let symbolColmn = '';
        let dateColumn = '';
        let dateFormat = '';
        let symbolCol = '';
        let dateCol = '';
        if(currentResponse && currentResponse.headers.length > 0){
            symbolColmn = currentResponse.headers.find((item) => {
                if (item.isSymbolColumn) {
                    return item.esFieldName;
                }
            })
            symbolCol = symbolColmn.esFieldName;
            dateColumn = currentResponse.headers.find((item) => {
                if (item.isDateColumn) {
                    return item.esFieldName;
                }
            })
            dateCol = dateColumn.esFieldName;
            dateFormat = dateColumn.dateFormat;
        }
        yield put({
            type: ActionTypes.SET_START_WIZARD_DATE_FORMAT,
            wizardId:listId,
            identifiedSymbolCol: symbolCol,
            identifiedDateCol: dateCol,
            identifiedDateFormat: dateFormat
        })
        yield put({
            type: ActionTypes.UNLOAD_EXTERNAL_STATE_DATA
          });
        if(!isListLoaded)
            yield put(loadExternalList(listId, true));
        else
            BrowserUtil.enableEvents();
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs - , loadStartWizardDateFormat ${error}`);
    }
}
function* setSymbolColumn({data}){
    try {
        yield put({
            type: ActionTypes.CHANGE_SYMBOL_COLUMN_IN_SOURCE,
            data:data
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs - , setSymbolColumn ${error}`);
    }
}
function* setDateColumn({data}){
    try {
        yield put({
            type: ActionTypes.CHANGE_DATE_COLUMN_IN_SOURCE,
            data:data
        })
    }
    catch (error) {
        yield put({
            type: ActionTypes.HANDLE_ERROR,
            hasError: true,
            errorMsg: error
        })
        console.log(`Error occurs - , setDateColumn ${error}`);
    }
}

function* handleLoader({ isLoad }) {
    yield put({
        type: ActionTypes.ON_UPDATE_LOADER_SUCCESS,
        loading: isLoad
    });
}
function* saveHeaders(){
    let headers = [];
    BrowserUtil.disableEvents()
    let symbolColSelect = yield select(updatedSymbolCol);
    let dateColSelect = yield select(updatedDateCol);
    let dateFormatSelect = yield select(updatedDateFormat);
    let currentSource = yield select(currentSelectedList);
    headers.push(currentSource.headers.find((item) => item.isDateColumn == true))
    headers.push(currentSource.headers.find((item) => item.isSymbolColumn == true))
    let listId = currentSource.listId;
    let showexception = false;
    let symbolColmn = '';
    let dateColumn = '';
    let dateFormat = '';
    let symbolCol = '';
    let dateCol = '';
    let actualDateFormat = dateFormatSelect.replace(/[^a-zA-Z0-9]/g,' ');
    let modifiedFormat = actualDateFormat.replace(/([M])\1{2,}/g,'MM')
    modifiedFormat = modifiedFormat.replace(/[E]/g,'');
    modifiedFormat = modifiedFormat.match(/(\w)\1{1,}/ig);
    modifiedFormat = modifiedFormat.join(' ');
    modifiedFormat = modifiedFormat.replace(/\s{2,}/g,' ');
    modifiedFormat = modifiedFormat.trim();
    actualDateFormat = modifiedFormat.toUpperCase();
    if(headers.length > 0){
        symbolColmn = headers.find((item) => {
            if (item.isSymbolColumn) {
                return item.esFieldName;
            }
        })
        symbolCol = symbolColmn.esFieldName;
        dateColumn = headers.find((item) => {
            if (item.isDateColumn) {
                return item.esFieldName;
            }
        }) 
        dateCol = dateColumn.esFieldName;
        dateFormat = dateColumn.dateFormat;
    }
    yield put({
        type: ActionTypes.CLOSE_DATE_FORMAT_DIALOG
    });
    let changedDateFormat = dateFormat.replace(/[^a-zA-Z0-9]/g,' ');
    if(symbolColSelect != symbolCol || dateColSelect != dateCol || (dateFormatSelect != dateFormat && actualDateFormat != changedDateFormat.toUpperCase())){
        
        ListActions.externalHeaderModified();
        let result = yield call(DataUploadsApi.updateHeaders,headers, listId);
        if(result.success)
            yield put(loadExternalList(listId, true));
        // }else{
            if((result.exceptionMessge != null && result.exceptionMessge != "") || result.error != ''){
                showexception = true;
              }
              yield put({
                type: ActionTypes.SHOW_WIZARD_DIALOG,
                showWizardDialog: !result.success,
                fileUploadRes: result,
                exceptionMessage: result.exceptionMessge ? result.exceptionMessge : result.error,
                exceptionDialogShow: showexception,
                isFileFormatError: (result.error != '' && result.error != null) ? true : false
              });
              if(result.success)
                yield put(loadExternalList(listId, true));
        // }
    }else{
        yield put({
            type: ActionTypes.UPDATE_DATE_FORMAT,
            dateFormat: dateFormatSelect
        });
        let exception = yield select(exceptionMsg);
        let showexception = false;
        if(exception != null && exception != ""){
            showexception = true;
        }
        yield put({
            type: ActionTypes.SHOW_WIZARD_DIALOG,
            showWizardDialog: false,
            exceptionMessage: exception,
            exceptionDialogShow: showexception
        });
    }
    BrowserUtil.enableEvents()
}

function* clearSortColumns() {
    yield put({
        type: ActionTypes.CLEAR_SORT_COLUMNS_SUCCESS,
        sortCols: []
    });
}

function* handleCurrentPageIndex({ lastIndex }) {
    yield put({
        type: ActionTypes.UPDATE_LAST_PAGE_INDEX_SUCCESS,
        lastPageIndex: lastIndex
    });
}

function* resetDefault() {
    yield put({
        type: ActionTypes.SET_DEFAULT_DATA
    });
}

function* getInitialSortInfo(sortData){
    yield call(clearSortColumns);
    yield all(sortData.map((item)=>call(updateSortInfo,item)));
}

function* updateSortInfo(sortCol) {
            let obj = {};
            obj.esFieldName = sortCol.esField;
            obj.sortAsc = sortCol.sortAsc;
            obj.sortOrderNum = sortCol.sortOrder;
            yield put({
                type: ActionTypes.ON_CHANGE_COLUMN_SORT_SUCCESS,
                sortCols: obj
            });
  }
function* saveNewDateFormat({newFormat}){ 
            const currentSource = yield select(currentSelectedList);
            const currentFormat = currentSource.headers.find((item) => item.isDateColumn == true);
            const currentDateFormat = currentFormat.dateFormat;
            const specialChar = currentDateFormat.match(/[^\w\*]/g);

            const split = newFormat.split(/[^\w\*]/g);
            let changeCase = '';
            for(let i = 0; i< split.length; i++){
                if(split[i] != 'MM' && split[i] != 'MMM'){
                    changeCase += split[i].toLowerCase()+" ";
                }else{
                    changeCase += split[i]+" ";
                }
            }
            changeCase = changeCase.trim();
            let newDateFormat = changeCase.replace(/ /g,specialChar?specialChar[0]:'');
            yield put({
                type: ActionTypes.UPDATE_DATE_FORMAT,
                dateFormat: newDateFormat
            });
}
/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchCallInitGridLoad() {
    yield takeLatest(ActionTypes.INIT_GRID_LOAD, initGridLoad);
}

export function* watchCallExternalSourceList() {
    yield takeLatest(ActionTypes.LOAD_EXTERNAL_LIST, getSource);
}

export function* watchOnGridDimensionChange() {
    yield takeLatest(ActionTypes.ON_GRID_DIMENSION_CHANGE, reSetOnDimensionChange);
}

export function* watchSetVisibleRowsData() {
    yield takeLatest(ActionTypes.SET_VISIBLE_ROWS_DATA, setVisibleRowsData);
}

export function* watchHandleColumnSort() {
    yield takeLatest(ActionTypes.ON_CHANGE_COLUMN_SORT, handleColumnSort);
}

export function* watchLoadStartWizard() {
    yield takeLatest(ActionTypes.LOAD_START_WIZARD_ON_ACCEPT, loadStartWizard);
}

export function* watchLoadStartWizardDate() {
    yield takeLatest(ActionTypes.LOAD_START_WIZARD_DATE, loadStartWizardDate);
}

export function* watchLoadStartWizardFateFormat() {
    yield takeLatest(ActionTypes.LOAD_START_WIZARD_DATE_FORMAT, loadStartWizardDateFormat);
}

export function* watchNewSymbolColumn() {
    yield takeLatest(ActionTypes.SET_SYMBOL_COLUMN, setSymbolColumn);
}

export function* watchNewDateColumn() {
    yield takeLatest(ActionTypes.SET_DATE_COLUMN, setDateColumn);
}

export function* watchHandleLoader() {
    yield takeLatest(ActionTypes.ON_UPDATE_LOADER, handleLoader);
}

export function* watchSaveHeaders() {
    yield takeLatest(ActionTypes.FINISH_AND_SAVE_HEADERS, saveHeaders);
}

export function* watchHandleLastPageIndex() {
    yield takeLatest(ActionTypes.UPDATE_LAST_PAGE_INDEX, handleCurrentPageIndex);
}
export function* watchHandleSaveDateFormat(){
    yield takeLatest(ActionTypes.SET_NEW_DATE_FORMAT, saveNewDateFormat);
}