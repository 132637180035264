import TabDatagraphSettings from "./TabDatagraphSettings.js";
import TabFundamentalsSettings from "./TabFundamentalsSettings.js";
import RelatedInformationSettings from "./RelatedInfoPanel/RelatedInformationSettings.js";
import PreferenceSettings from "./PreferenceSettings.js";
import TabType from "../../../../../Constants/TabType.js";
import SmartViewType from "../../../../../Constants/SmartViewType.js";
import Serializable from "Serializable";

import LocalizationStore from "LocalizationStore";
import PanarayDefault from "../../../../../ServiceApi/RequestHelper/PanarayDefault.js";

class dataGraphSettings extends Serializable {

  onSeralisedInfoRecieved(serializedValue) {
      this.symbol = serializedValue.symbol ? serializedValue.symbol : PanarayDefault.Symbol;
      this.symbolTypeEnum = serializedValue.symbolTypeEnum ? serializedValue.symbolTypeEnum : PanarayDefault.SymbolTypeEnum;
      this.msId = serializedValue.msId ? serializedValue.msId : PanarayDefault.Msid;
      this.isMiniListOpen = serializedValue.isMiniListOpen ? serializedValue.isMiniListOpen : false;
      // this.showOwnershipTab = serializedValue.showOwnershipTab ? serializedValue.showOwnershipTab : false;
      this.TabFundamentalsSettings = serializedValue.TabFundamentalsSettings ? serializedValue.TabFundamentalsSettings : this.getDefaultTabFundamentalSetting();
      this.RelatedInformationSettings = serializedValue.RelatedInformationSettings ? serializedValue.RelatedInformationSettings : this.getDefaultRelatedInformationSettings();
      this.TabDataGraphSettings = serializedValue.TabDatagraphSettings ? serializedValue.TabDatagraphSettings : this.getDefaultTabDataGraphSetting();
      this.TabCollection = [];
      this.TabCollection.push({ Header: TabType.Datagraph, TabType: TabType.Datagraph });
      this.TabCollection.push({ Header: TabType.Ownership, TabType: TabType.Ownership });
      this.TabCollection.push({ Header: TabType.Fundamental, TabType: TabType.Fundamental });
      this.TabCollection.push({ Header: TabType.Holdings, TabType: TabType.Holdings });
      /*this.TabCollection.push({ Header: TabType.Ownership, TabType: TabType.Ownership });*/
      this.SelectedTabSettings = serializedValue.SelectedTabSettings ? serializedValue.SelectedTabSettings : this.getDefaultSelectedTabObject();
      //if (this.SelectedTabSettings[SmartViewType.INDUSTRYGROUP] === undefined)
      //For saved workspaces from version 3 need to get missing SmartViewType in Version 4
      // this.SelectedTabSettings = this.getDefaultSelectedTabObject();
      this.PreferenceSettings = serializedValue.PreferenceSettings ? serializedValue.PreferenceSettings : this.getDefaultPreferenceSettings();
      this.isSymbolConvertedDialogAvailable = serializedValue.isSymbolConvertedDialogAvailable ? serializedValue.isSymbolConvertedDialogAvailable : false;
      this.isAnimationMessageDialogAvailable = serializedValue.isAnimationMessageDialogAvailable ? serializedValue.isAnimationMessageDialogAvailable : false;
  }

  getDefaultDatagraphSettings() {
    this.symbol = PanarayDefault.Symbol;
    this.symbolTypeEnum = PanarayDefault.SymbolTypeEnum;
    this.isMiniListOpen = false;
    // this.showOwnershipTab = false;
    this.TabDataGraphSettings = this.getDefaultTabDataGraphSetting();
    this.TabFundamentalsSettings = this.getDefaultTabFundamentalSetting();
    this.RelatedInformationSettings = this.getDefaultRelatedInformationSettings();
    this.TabCollection = [];
    this.TabCollection.push({ Header: TabType.Datagraph, TabType: TabType.Datagraph });
    this.TabCollection.push({ Header: TabType.Ownership, TabType: TabType.Ownership });
    this.TabCollection.push({ Header: TabType.Fundamental, TabType: TabType.Fundamental });
    this.TabCollection.push({ Header: TabType.Holdings, TabType: TabType.Holdings });
    /*this.TabCollection.push({ Header: TabType.Ownership, TabType: TabType.Ownership });*/
    this.SelectedTabSettings = this.getDefaultSelectedTabObject();
    this.PreferenceSettings = this.getDefaultPreferenceSettings();
    this.isSymbolConvertedDialogAvailable = false;
    this.isAnimationMessageDialogAvailable = false;
    return this;
  }

  getDefaultTabDataGraphSetting() {
    var tabDatagraphSettings = new TabDatagraphSettings();
    return tabDatagraphSettings.getDefaultTabDatagraphSettings();
  }

  getDefaultTabFundamentalSetting() {
    var tabFundamentalsSettings = new TabFundamentalsSettings();
    return tabFundamentalsSettings.getDefaultTabFundamentalsSettings();
  }
  getDefaultRelatedInformationSettings() {
    const relatedInformationSettings = new RelatedInformationSettings();
    return relatedInformationSettings.getDefaultRIPanelSettings();
  }
  getDataObject() {
    return {
        symbol: this.symbol,
        symbolTypeEnum: this.symbolTypeEnum,
        msId: this.msId,
        isMiniListOpen: this.isMiniListOpen,
        // showOwnershipTab : this.showOwnershipTab,
        TabDatagraphSettings: this.TabDataGraphSettings,
        TabFundamentalsSettings: this.TabFundamentalsSettings,
        SelectedTabSettings: this.SelectedTabSettings,
        RelatedInformationSettings: this.RelatedInformationSettings,
        PreferenceSettings: this.PreferenceSettings,
        isSymbolConvertedDialogAvailable: this.isSymbolConvertedDialogAvailable,
        isAnimationMessageDialogAvailable: this.isAnimationMessageDialogAvailable
    };
  }

  getObjectMapKey() {
    return "dataGraphSettings";
  }

  getDefaultSelectedTabObject() {
    var smartViewTabSelectionObject = new Serializable();
    for (let smartViewType in SmartViewType) {
      if (!smartViewTabSelectionObject.hasOwnProperty(smartViewType)) {
        smartViewTabSelectionObject[smartViewType] = TabType.Datagraph;
      }
    }

    return smartViewTabSelectionObject;

  }

  getDefaultPreferenceSettings() {
    return new PreferenceSettings().getDefaultSettings();
  }
}



export default dataGraphSettings;
