import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import ConsoleStore from "Stores/ConsoleWindow/ConsoleStore";
import ContactStore from "Stores/ContactWindow/ContactStore";
import { ConsoleConstants } from "Constants/ConsoleConstants.js";
import ConsoleActions from "Actions/ConsoleActions";
import UserInfoUtil from "UserInfoUtil";
import BaseServiceApi from "BaseServiceApi";
import LocalizationStore from 'LocalizationStore';
import PanaraySupport from '../Contacts/PanaraySupport';
import UserListofContact from "../Contacts/UserListofContact";

const entitlementType = BaseServiceApi.rayData["EntitlementType"];

export default class SupportDialog extends Component {
    constructor(props) {
        super(props);
        this.closeSupportDialog = this.closeSupportDialog.bind(this);
        this.ConsoleStateChange = this.ConsoleStateChange.bind(this);
        this.ContactStoreStateChanged = this.ContactStoreStateChanged.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.sendMailClick = this.sendMailClick.bind(this);
        this.state = {
            isOpen: ConsoleStore.getSupportDialogOpen(),
            isInSupportHours: ContactStore.getIsInSupportHours(),
        }
    }

    componentDidMount() {
        window.addEventListener('blur', this.handleBlur);
        ConsoleActions.checkSupportHours();
    }

    UNSAFE_componentWillMount() {
        ConsoleStore.addChangeListener(this.ConsoleStateChange);
        ContactStore.addChangeListener(this.ContactStoreStateChanged);
    }

    componentWillUnmount() {
        ConsoleStore.removeChangeListener(this.ConsoleStateChange);
        ContactStore.removeChangeListener(this.ContactStoreStateChanged);
        clearTimeout(this.mailTime);
    }

    ContactStoreStateChanged(){
        let state = ContactStore.getState();
        this.setState({
            ...this.state,
            contactsDatasource: state.contactsDatasource,
            isInSupportHours: state.isSupportHours
        });
    }

    ConsoleStateChange() {
        let currentAction = ConsoleStore.getCurrentAction();
        if (currentAction == ConsoleConstants.ActionTypes.TOGGLE_SUPPORT_DIALOG) {
            this.setState({
                ...this.state,
                isOpen: ConsoleStore.getSupportDialogOpen()
            });
            ConsoleStore.clearCurrentAction();
        }
    }

    sendMailClick() {
        this.checkMailClient();
        this.closeSupportDialog();
        //Log Usage
        ConsoleStore.trackUsage('IsSupportEmailSelected');
    }

    checkMailClient() {
        this.mailTime = setTimeout(function () {
            alert(`Could not perform this operation because the default mail client is not properly installed.
Please send your feedback to the email address listed below:
PANARAYFeedback@williamoneil.com`);
        }, 2500);
    }

    closeSupportDialog() {
        this.setState({ isOpen: false });
        ConsoleActions.toggleSupportDialog(false);
    }

    handleBlur() {
        if (this.mailTime) {
            clearTimeout(this.mailTime);
        }
    }

    render() {
        if (this.state.isOpen) {
            let supportMail = 'PANARAYFeedback@williamoneil.com';
            if (UserInfoUtil.IsUserEntitled(entitlementType.RAY_CHINA_Entitlement)) {
                supportMail = 'cs@williamoneilchina.com';
            }
            return (
                <div id="supportDialog">
                    <Modal className="modal-popup supportDialog" show={this.state.isOpen} onHide={this.closeSupportDialog}>
                        <Modal.Header>
                            <Modal.Title>
                                    {/* <span className="cap-header support-title"> */}
                                        {LocalizationStore.getTranslatedData("contacts", "Contacts")}
                                    {/* </span> */}
                                    <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.closeSupportDialog}><span className="icn-close"></span></button>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="modal-region">
                                <PanaraySupport supportMail={supportMail} isInSupportHours={this.state.isInSupportHours}/>
                                <UserListofContact dataSource={this.state.contactsDatasource}/>
                                <div className="clearfix"></div>
                            </div>
                            {/* <div className="inner-block">
                                <div className="medium-normal">{LocalizationStore.getTranslatedData("su_get", "Get assistance and answers for your questions.")}</div>
                                <div className="medium-normal">{LocalizationStore.getTranslatedData("su_click", "Click below for PANARAY support.")}</div>
                                <div>
                                    <center><a className="support-mail-btn model-action btn btn-xs btn-default btn-secondary small-bold" onClick={this.sendMailClick} href={`mailto:${supportMail}?subject=PANARAY Support`}>{LocalizationStore.getTranslatedData("su_send", "Send an Email")}</a></center>
                                </div>
                            </div> */}
                            <div className="clearfix"></div>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        }
        else {
            return null;
        }
    }
}