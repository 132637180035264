import { combineReducers } from 'redux';
import compareChart from './compareChart';
import compareMenu from './compareMenu';
import calendarControl from './calendarControl';
import compChartListItem from './compChartListItem';
import compareChartViewModel from './compareChartViewModel';
const comparisonGraph = combineReducers({
    compareChart,
    compareMenu,
    calendarControl,
    compChartListItem,
    compareChartViewModel
});
  
  
export default comparisonGraph;