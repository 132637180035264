import React, { Component } from 'react';
import { Popover } from 'react-bootstrap';

export default class QuoteStatusPopover extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Popover id="popover-trigger-focus medium-bold" title={this.props.title} {...this.props}>
        {this.props.children}
      </Popover>
    );
  }
}