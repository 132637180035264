import TabFundamentalsReportDataType from "../Constants/TabFundamentalsDataType";
import MiniListStore from "../Stores/NavModules/NavDataGraph/MiniList/MiniListStore";

class TabFundamentalsHelper {

    constructor() {
        this.currentReportDataType = TabFundamentalsReportDataType.NonReport;
        this.DataStatus = {};
    }

    clearDataStatus(){
        this.DataStatus = {};
    }

    setCurrentReportDataType(reportDataType) {
        this.currentReportDataType = reportDataType;
    }

    updateDataReadyInfo(DataType, isDataReady = true) {
        if(!this.DataStatus[DataType]){
            this.DataStatus[DataType] = {};
        }
        this.DataStatus[DataType].isDataReady = isDataReady;
    }

    updateDataRenderInfo(DataType, isDataRender = true) {
        if(!this.DataStatus[DataType]){
            this.DataStatus[DataType] = {};
        }
        this.DataStatus[DataType].isDataRender = isDataRender;

        const isAllDataReady = this.isAllDataRender();
        if (isAllDataReady) {
            MiniListStore.setMiniListNextInterval();
        }
    }

    isDataTypeReady(DataType) {
        if(!this.DataStatus[DataType]){
            return false;
        }
        return this.DataStatus[DataType].isDataReady && this.DataStatus[DataType].isDataRender;
    }

    isAllDataRender() {
        return this.isDataTypeReady(this.currentReportDataType) && this.isDataTypeReady(TabFundamentalsReportDataType.Options);
    }

}

const tabFundamentalsHelper = new TabFundamentalsHelper();
export default tabFundamentalsHelper;
