import { createSelector } from 'reselect';
import CompareTabType,{CompareMenuType} from "CompareTabType";

const getSubNavTab = (state) => state.navCompareReducer.selectedTab;
const getCompChartListItem = (state) => state.comparisonGraph.compChartListItem;
const addSymbolData = (state) => state.comparisonAndRotationMenuPanel.AddSymbol;
const getMenuTab = (state) => state.comparisonAndRotationMenuPanel.MenuTabReducer;
const getLiqFilter = (state) => state.comparisonAndRotationMenuPanel.LiqAndRatingFilterReducer;
const symbolsMenu = (state) => state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
const groupsMenu = (state) => state.comparisonAndRotationMenuPanel.GroupsPanelReducer;
const getCompareChartViewModel = (state) => state.comparisonGraph.compareChartViewModel;

export const getSelectedTabLiqFilterData = createSelector(
    [getSubNavTab,getMenuTab,getLiqFilter],
    (tab,menuTab,liqFilterData) => {
        return tab == CompareTabType.ComparisonGraph?
        menuTab.ComparisonMenuTab == CompareMenuType.Symbols ? liqFilterData.ComparisonMenuStates.Symbols : liqFilterData.ComparisonMenuStates.Groups:
        menuTab.RotationMenuTab == CompareMenuType.Symbols ? liqFilterData.RotationMenuStates.Symbols : liqFilterData.RotationMenuStates.Groups
    }
);

export const getSymbolsMenu = createSelector([getSubNavTab, symbolsMenu], (selectedTab, symbolsMenu) => {
    return selectedTab === CompareTabType.ComparisonGraph ? symbolsMenu.ComparisonMenuStates : symbolsMenu.RotationMenuStates;
});

export const getGroupsMenu = createSelector([getSubNavTab, groupsMenu], (selectedTab, groupsMenu) => {
    return selectedTab === CompareTabType.ComparisonGraph ? groupsMenu.ComparisonMenuStates : groupsMenu.RotationMenuStates;
});

export const getRotationGraphMenuData = createSelector([getMenuTab, symbolsMenu, groupsMenu], (menuTab,symbolsMenu,groupsMenu) => {
    return menuTab.RotationMenuTab == CompareMenuType.Symbols ? symbolsMenu.RotationMenuStates : groupsMenu.RotationMenuStates;
});

export const getSelectedTabActiveMenuName = createSelector([getSubNavTab, getMenuTab], (selectedTab, menu) =>
        selectedTab === CompareTabType.ComparisonGraph
        ? menu.ComparisonMenuTab
        : menu.RotationMenuTab
);

export const getStoreSymbolsDataSize = createSelector([getSubNavTab,getCompChartListItem,symbolsMenu],(selectedTab,compChartListItem,symbolsMenu)=>{
    return selectedTab === CompareTabType.ComparisonGraph ? compChartListItem.SymbolsData.length : symbolsMenu.RotationMenuStates.listItems.length
})

export const getActiveTabActiveMenuData = createSelector([getSubNavTab, getMenuTab,symbolsMenu,groupsMenu], (selectedTab, menuTab,symbolsMenu,groupsMenu) =>{
   return selectedTab === CompareTabType.ComparisonGraph
    ? menuTab.ComparisonMenuTab == CompareMenuType.Symbols ? symbolsMenu.ComparisonMenuStates : groupsMenu.ComparisonMenuStates
    : menuTab.RotationMenuTab == CompareMenuType.Symbols ? symbolsMenu.RotationMenuStates : groupsMenu.RotationMenuStates
});

export const getActiveCompNavTabName = createSelector([getSubNavTab], (selectedTab) => {
    return selectedTab;
})

export const getIsComparisonTabSelected = createSelector([getSubNavTab], (selectedTab) => {
    return selectedTab === CompareTabType.ComparisonGraph ? true : false;
})

export const getAddSymbolData = createSelector([getSubNavTab,addSymbolData],(selectedTab,addSymbolData)=>{
    return selectedTab === CompareTabType.ComparisonGraph ? addSymbolData.ComparisonMenuStates : addSymbolData.RotationMenuStates
})

export const getSelectedTabMenuStateObjName = createSelector(getSubNavTab, (selectedTab) =>
    selectedTab === CompareTabType.ComparisonGraph
        ? 'ComparisonMenuStates'
        : 'RotationMenuStates'
);

export const getCountableCountTotal = createSelector([
    getSubNavTab, symbolsMenu, getCompChartListItem, getCompareChartViewModel],
    (selectedTab, symbolsMenu, compChartListItem, compareChartViewModel) => {
        const count = selectedTab == CompareTabType.ComparisonGraph ? compChartListItem.SymbolsData.filter((c) => !c.isFilter && c.label !== "").length : symbolsMenu.RotationMenuStates.listItems.length;
        const total = selectedTab == CompareTabType.ComparisonGraph ? (compareChartViewModel.SymbolsData.symbolRequest.length + compareChartViewModel.SymbolsData.indexRequest.length) : symbolsMenu.RotationMenuStates.stockList.length;
        return ({ count: count, total: total });
    }
);

export const getLoadingStatus = createSelector([getMenuTab], (menuTab) => {
    return menuTab.selectedTab === CompareTabType.ComparisonGraph
        ? menuTab.ComparisonMenuTab == CompareMenuType.Symbols
            ? menuTab.isLoading.ComparisonGraph.SymbolsLoading
            : menuTab.isLoading.ComparisonGraph.GroupsLoading
        : menuTab.RotationMenuTab == CompareMenuType.Symbols
            ? menuTab.isLoading.RotationGraph.SymbolsLoading
            : menuTab.isLoading.RotationGraph.GroupsLoading
})

