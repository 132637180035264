import { AnnotationConstant } from "../../Constants/AnnotationConstants";
const { ActionTypes } = AnnotationConstant;
import { StrokeStyleType } from 'Constants/EAnnotationType';
import { clone, map, flatten } from "underscore";

const initialState = {
    annotationDimension: {
        width: 0,
        height: 0
    },
    isDrawing: false,
    isApiReqResolved: true,
    annotSelected: null,
    hasError: false,
    errorMsg: null,
    translatedChartNodeDict: [],
    showContextMenu: false,
    contextMenuX: 0,
    contextMenuY: 0,
    contextForShape: null,
    allVisibleAnnotId: [],
    allReadOnlyAnnotId: [],
    selectedAnnotId: [],
    showDeleteConform: false,
    isDeleteAll: false,
    isDragging: false,
    draggedItem: null,
    activeAnchor: '',
    isAlertHover: false,
    hoveredAnnotId: 0,
    showUndoAlert: false,
    deletedId: {},
    alertMsg: '',
    isRiPanelDelete: false,
    combinedAnnotations: [],
    isAlertUpdate: false,
    isDeleteAlert: false,
    alertDeleteAnnot: null,
    isAlertDeleted: false,
    isStageClickCaptured: false,
    tempIdsArray: {},
    tempAnnotId: []
}

const annotationChartModelReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.ON_ANNOTATION_DIMENSION_CHANGE:
            return {
                ...state,
                annotationDimension: action.dimension,
            }
        case ActionTypes.HANDLE_TOOL_CHANGE:
        case ActionTypes.TOGGLE_VISIBILITY:
            // Removing the all selected Annotations on moving to pointer
            return {
                ...state,
                // annotSelected: action.selectedToolType === 0 ? null : state.annotSelected,
                selectedAnnotId: action.selectedToolType === 0 && !action.isCtrl ? [] : state.selectedAnnotId,
                annotSelected: null
            }
        case ActionTypes.HANDLE_CLICK_SUCCESS:
            return {
                ...state,
                isDrawing: action.isDrawing,
                annotSelected: action.annotSelected,
                selectedAnnotId: [action.annotSelected.id]
            }
        case ActionTypes.UPDATE_PRICE_CHART_NODE_VALUES:
            return {
                ...state,
                translatedChartNodeDict: action.translatedChartNodeDict
            }
        case ActionTypes.HANDLE_ANNOTATION_ERROR:
            return {
                ...state,
                hasError: action.hasError,
                errorMsg: action.errorMsg
            }
        case ActionTypes.ANNOTATION_CONTEXT_MENU_TOGGLE:
            return {
                ...state,
                showContextMenu: action.showContext,
                contextMenuX: action.clientX,
                contextMenuY: action.clientY,
                contextMenu: action.contextMenu
            }
        case ActionTypes.REMOVE_DRAWING_ANNOTATION_AFTER_KEY:
        case ActionTypes.GET_DEFAULT_DRAWING_MODE:
        case ActionTypes.SET_ACTIVE_LAYER:
            return {
                ...state,
                isDrawing: false,
                annotSelected: null,
                selectedAnnotId: []
            }
        case ActionTypes.STORE_ANNOTATION_IDS:
            return {
                ...state,
                allVisibleAnnotId: action.allAnnotId,
                allReadOnlyAnnotId: action.allReadOnlyAnnotId ? action.allReadOnlyAnnotId : state.allReadOnlyAnnotId
            }
        case ActionTypes.REMOVE_VISIBLE_ANNOTATION_IDS: {
            const removeData = clone(state.allVisibleAnnotId);
            map(removeData, (item) => {
                if (action.deletedIds.includes(item.annotId)) {
                    item.deleted = true;
                }
            });
            return {
                ...state,
                allVisibleAnnotId: removeData,
                isRiPanelDelete: false
            }
        }
        case ActionTypes.REMOVE_TEMP_ANNOTATION_IDS: {
            const removeableData = clone(state.allVisibleAnnotId);
            map(removeableData, (item) => {
                if (action.deletedId === item.annotId) {
                    item.deleted = true;
                }
            });
            return {
                ...state,
                allVisibleAnnotId: removeableData
            }
        }
        case ActionTypes.REMOVE_TEMP_IDS_ARRAY:
            if (state.tempIdsArray.hasOwnProperty(action.deletedId)) {
                delete state.tempIdsArray[action.deletedId];
            }
            return {
                ...state,
                tempIdsArray: state.tempIdsArray
            }
        case ActionTypes.ADD_VISIBLE_ANNOTATION_IDS: {
            const addData = clone(state.allVisibleAnnotId);
            map(addData, (item) => {
                if (action.revokedIds.includes(item.annotId)) {
                    item.deleted = false;
                    item.updatedDate = new Date();
                }
            });
            return {
                ...state,
                allVisibleAnnotId: addData
            }
        }
        case ActionTypes.STORE_NEW_ANNOTATION_IDS:
            return {
                ...state,
                allVisibleAnnotId: [...state.allVisibleAnnotId, action.newAnnotId]
            }
        case ActionTypes.STORE_TEMP_ANNOTATION_IDS:
            return {
                ...state,
                tempAnnotId: [...state.tempAnnotId, action.newAnnotId]
            }
        case ActionTypes.UPDATE_SELECTED_ANNOTATIONS_ADD:
            return {
                ...state,
                selectedAnnotId: flatten([...state.selectedAnnotId, action.id])
            }
        case ActionTypes.UPDATE_SELECTED_ANNOTATIONS_REMOVE:
            return {
                ...state,
                selectedAnnotId: state.selectedAnnotId.filter((itm) => itm !== action.id)
            }
        case ActionTypes.HANDLE_TOGGLE_DELETE_POPUP:
            return {
                ...state,
                showDeleteConform: action.showDialog,
                isDeleteAll: action.isDeleteAll,
                isRiPanelDelete: action.isRiPanelDelete
            }
        case ActionTypes.UPDATE_DRAG_STATUS:
            return {
                ...state,
                isDragging: action.isDragging,
                draggedItem: action.draggedItem,
                annotSelected: action.draggedItem ? action.draggedItem : state.annotSelected,
                selectedAnnotId: action.draggedItem ? [action.draggedItem.id] : state.selectedAnnotId
            }
        case ActionTypes.HANDLE_SAVE_CLICKED_ANCHOR_POSITION:
            return {
                ...state,
                activeAnchor: action.activeAnchor
            }
        case ActionTypes.HANDLE_ALERT_CHANGE:
            return {
                ...state,
                isAlertUpdate: action.isAlertUpdate
            }
        case ActionTypes.HANDLE_TL_ALERT_HOVER_TOGGLE:
            return {
                ...state,
                isAlertHover: action.isHovered,
                hoveredAnnotId: action.annotId
            }
        case ActionTypes.SHOW_UNDO_ALERT:
            return {
                ...state,
                showUndoAlert: action.showAlert,
                deletedId: action.deletedId,
                alertMsg: action.alertMsg,
                isLayerDelete: action.isLayerDelete
            }
        case ActionTypes.UPDATE_TARGET_ANNOTATION:
            switch (action.action) {
                case ActionTypes.HANDLE_COLOR_CHANGE:
                    state.annotSelected.color = action.data;
                    break;
                case ActionTypes.HANDLE_BORDER_COLOR_CHANGE:
                    state.annotSelected.borderColor = action.data;
                    break;
                case ActionTypes.HANDLE_BG_COLOR_CHANGE:
                    state.annotSelected.bgColor = action.data;
                    break;
                case ActionTypes.HANDLE_EXTENSION_COLOR_CHANGE:
                    state.annotSelected.extensionLineColor = action.data;
                    break;
                case ActionTypes.HANDLE_OPACITY_CHANGE:
                    state.annotSelected.opacity = action.data;
                    break;
                case ActionTypes.HANDLE_STROKE_CHANGE:
                    state.annotSelected.stroke = action.data.stroke;
                    state.annotSelected.strokeStyle = action.data.style;
                    break;
                case ActionTypes.HANDLE_BORDER_STROKE_CHANGE:
                    state.annotSelected.borderStroke = action.data.stroke;
                    state.annotSelected.strokeStyle = action.data.style;
                    break;
                case ActionTypes.HANDLE_WEIGHT_CHANGE:
                    state.annotSelected.weight = action.data;
                    state.annotSelected.stroke = state.annotSelected.strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? [action.data * 2.5, action.data] : state.annotSelected.strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType ? [action.data, action.data] : state.annotSelected.stroke;
                    break;
                case ActionTypes.HANDLE_BORDER_WEIGHT_CHANGE:
                    state.annotSelected.borderWeight = action.data;
                    state.annotSelected.borderStroke = state.annotSelected.strokeStyle === StrokeStyleType.DASHED_StrokeStyleType ? [action.data * 2.5, action.data] : state.annotSelected.strokeStyle === StrokeStyleType.DOTTED_StrokeStyleType ? [action.data, action.data] : state.annotSelected.borderStroke;
                    break;
                case ActionTypes.HANDLE_EXTENSION_WEIGHT_CHANGE:
                    state.annotSelected.extensionLineWeight = action.data;
                    break;
                default: break;
            }
            return { ...state };
        case ActionTypes.UPDATE_TARGET_TEXT_ANNOTATION:
            switch (action.target) {
                case 'fontStyle':
                    state.annotSelected.fontStyle = action.data;
                    break;
                case 'fontSize':
                    state.annotSelected.fontSize = action.data;
                    break;
                case 'bold':
                    state.annotSelected.isBold = !state.annotSelected.isBold
                    break;
                case 'italic':
                    state.annotSelected.isItalic = !state.annotSelected.isItalic
                    break;
                case 'underline':
                    state.annotSelected.isUnderline = !state.annotSelected.isUnderline
                    break;
                case 'align':
                    state.annotSelected.textAlign = action.data
                    break;
                default:
                    break;
            }
            return { ...state };
        case ActionTypes.UPDATE_API_CALL_STATUS:
            return {
                ...state,
                isApiReqResolved: action.isApiReqResolved
            };
        case ActionTypes.ADD_TO_ANNOTATION_COLLECTION:
            return {
                ...state,
                combinedAnnotations: [...state.combinedAnnotations, action.annotation]
            };
        case ActionTypes.REPLACE_ANNOTATION_COLLECTION:
            return {
                ...state,
                combinedAnnotations: action.annotation
            };
        case ActionTypes.CLEAR_ANNOTATIONS:
            return {
                ...state,
                combinedAnnotations: []
            };
        case ActionTypes.CLEAR_SELECTED_ANNOTATIONS:
            return {
                ...state,
                selectedAnnotId: []
            };
        case ActionTypes.REMOVE_ALERT_SELECTION_SAVE:
            return {
                ...state,
                isDeleteAlert: action.isRemoveAlertSelection,
                alertDeleteAnnot: action.line
            };
        case ActionTypes.STORE_ALERT_DELETE_STATUS:
            return {
                ...state,
                isAlertDeleted: action.isAlertDelete
            };
        case ActionTypes.UPDATE_STAGE_CLICK_CAPTURED_FLAG:
            return {
                ...state,
                isStageClickCaptured: action.isStageClickCaptured
            }
        case ActionTypes.SAVE_ANNOTATIONS_RESPONSE:
            return {
                ...state,
                annotResponse: action.annotResponse
            }
        case ActionTypes.HANDLE_ORIGINAL_IDS_TEMP:
            return {
                ...state,
                tempIdsArray: action.tempIdsArray
            }
        default:
            return state;
    }
}

export default annotationChartModelReducer;