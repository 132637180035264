import AnimationSlider from './AnimationSlider';
import AnimationSpeedEnum from '../../../../../../Constants/AnimationSpeedEnum';
import BlockType from '../../../../../../Constants/BlockType';
import { connect } from 'react-redux';
//import PropTypes from 'prop-types'
import DateHelper from '../../../../../../Utils/TimeLineHelper/Datehelper';
import GraphType from '../../../../../../Constants/GraphType';
import KeyCodes from '../../../../../../Constants/KeyCodes';
import StringUtil from "StringUtil";
import { getEarningsAnimationSetFromController, processEPSAnimation, updateEpsIsPlaying, updateFundamentalLineStates, updateIsEarningsAnimationDocked } from '../../../../../../Actions/PricePanelActions';
import React, { Component } from 'react'

class AnimationPlayPauseControl extends Component {
    constructor(props) {
        super(props);
        this.slideronChange = this.slideronChange.bind(this);
        this.playGraph = this.playGraph.bind(this);
        this.startAnimationEPS = this.startAnimationEPS.bind(this);
        this.earningsAnimationTimerTick = this.earningsAnimationTimerTick.bind(this);
        this.onChangeComplete = this.onChangeComplete.bind(this);
        this.resetSliderByValue = this.resetSliderByValue.bind(this);
        this.onDocumentKeyPress = this.onDocumentKeyPress.bind(this);
        this.onDocumentKeyUp = this.onDocumentKeyUp.bind(this);
        this.AnimationLeftMoveTick = this.AnimationLeftMoveTick.bind(this);
        this.AnimationRightMoveTick = this.AnimationRightMoveTick.bind(this);
        this.state = {
            validValueIndex: this.props.epsNodeCount,
            dragging: false,
            currenTimeLine : []
        }
        this.interval = null;
        this.timeout = null;
        this.isLeftStartMove = false;
        this.isRightStartMove = false;
    }
    componentDidMount() {
        document.addEventListener("keydown", this.onDocumentKeyPress, false);
        document.addEventListener("keyup", this.onDocumentKeyUp, false);
        this.setCurrentIntervalDate(); 
        this.setState({currentGraphValueIndex: this.props.epsNodeCount, validValueIndex: this.props.epsNodeCount }, ()=>{
            if(this.props.isPlaying && this.props.IsAnimateWithChartLoad && this.props.IsEarningsAnimationDisplayed && !this.props.IsEarningsAnimationDocked){
                this.props.getEarningsAnimationSetFromController();
                this.startAnimationEPS(this.props.isPlaying);
            }  
        });   
    }

    componentDidUpdate(nextProps){
        if(nextProps.AnimationSpeedEnum !== this.props.AnimationSpeedEnum && this.props.isPlaying && this.props.IsAnimateWithChartLoad && this.props.IsEarningsAnimationDisplayed && !this.props.IsEarningsAnimationDocked){
            this.startAnimationEPS();
        } 
    }

    onDocumentKeyPress(e) {
        const charCode = e.which ? e.which : e.keyCode;
        if(e.ctrlKey && !this.props.IsEarningsAnimationDocked){
            if (charCode === KeyCodes.LEFT_ARROW){
                if(!this.isLeftStartMove){
                    this.isLeftStartMove = true;
                    this.AnimationLeftMoveCount = 0;
                    clearInterval(this.AnimationSliderLeftInterval);
                    clearInterval(this.interval);
                    this.AnimationSliderLeftInterval = setInterval(this.AnimationLeftMoveTick, 50);
                }
            }
            else if (charCode === KeyCodes.RIGHT_ARROW){
                if(!this.isRightStartMove){
                    this.isRightStartMove = true;
                    this.AnimationRightMoveCount = 0;
                    clearInterval(this.AnimationSliderRightInterval);
                    clearInterval(this.interval);
                    this.AnimationSliderRightInterval = setInterval(this.AnimationRightMoveTick, 50);
                }
            }
        }
    }

    AnimationLeftMoveTick(){
        const sliderValue = this.state && this.state.currentGraphValueIndex;
        if(sliderValue && !this.props.IsEarningsAnimationDocked){
            this.slideronChange(sliderValue - 1);
            this.AnimationLeftMoveCount += 1;
        }
    }

    AnimationRightMoveTick(){
        const sliderValue = this.state && this.state.currentGraphValueIndex;
        if(sliderValue && !this.props.IsEarningsAnimationDocked){
            this.slideronChange(sliderValue + 1);
            this.AnimationRightMoveCount += 1;
        }
    }

    onDocumentKeyUp() {
        if(this.isLeftStartMove){
            if(this.AnimationLeftMoveCount === 0){
                this.AnimationLeftMoveTick();
            }
            this.isLeftStartMove = false;
            clearInterval(this.AnimationSliderLeftInterval);
            if(this.props.isPlaying){
               this.startAnimationEPS();
            }
        }
        if(this.isRightStartMove){
            if(this.AnimationRightMoveCount === 0){
                this.AnimationRightMoveTick();
            }
            this.isRightStartMove = false;
            clearInterval(this.AnimationSliderRightInterval);
            if(this.props.isPlaying){
                this.startAnimationEPS();
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keyup", this.onDocumentKeyUp, false);
        document.removeEventListener("keydown", this.onDocumentKeyPress, false);
        clearInterval(this.AnimationSliderLeftInterval);
        clearInterval(this.AnimationSliderRightInterval);
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.props.updateFundamentalLineStates({currentGraphValueIndex: this.props.epsNodeCount, isHistoricEpsLine: false});
        this.onChangeComplete(true);
    }

    playGraph(e) {
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
        if(this.props.snapshots && this.props.snapshots.length > 0){
            this.props.getEarningsAnimationSetFromController();
            this.props.updateEpsIsPlaying(!this.props.isPlaying);
            this.startAnimationEPS(!this.props.isPlaying);
        }
    }
    setEPSAnimationInterval(animationSpeedEnum) {
        let epsAnimationInterval = 75;
        switch (animationSpeedEnum) {
            case AnimationSpeedEnum.Fast:
                epsAnimationInterval = 50;
                break;
            case AnimationSpeedEnum.Medium:
                epsAnimationInterval = 500;
                break;
            case AnimationSpeedEnum.Slow:
                epsAnimationInterval = 1000;
                break;
            default:
                break;
        }
        return epsAnimationInterval;
    }
    startAnimationEPS(isPlaying = null) {
        if(isPlaying === null){
            isPlaying = this.props.isPlaying
        }
        if(this.interval){
            clearInterval(this.interval);
        }
        if (this.props.earningsAnimationResults.length > 0) {
            if (!isPlaying) {
                clearInterval(this.interval);
                this.setState({ currentGraphValueIndex: this.state.currentGraphValueIndex, validValueIndex: this.state.currentGraphValueIndex, dragging: false });
            } else {
                if (this.state.validValueIndex === this.props.epsNodeCount) {
                    this.props.pricePanelData.EPSSnapshotData = this.props.snapshots[0];
                    this.props.processEPSAnimation();
                    this.timeout = setTimeout(() => {
                        this.setState({ currentGraphValueIndex: this.props.lastStartGraphValueIndex, validValueIndex: this.state.currentGraphValueIndex, dragging: false });
                    });
                }
                const epsAnimationInterval = this.setEPSAnimationInterval(this.props.AnimationSpeedEnum);
                this.interval = setInterval(this.earningsAnimationTimerTick, epsAnimationInterval, isPlaying);
            }
        }
        else {
            clearInterval(this.interval);
            
            this.props.updateEpsIsPlaying(false);
        }
    }

    updateEarningLine(selectedSliderValue) {
        
        this.setState({ currentGraphValueIndex: selectedSliderValue });
        const eLength = this.props.earningsAnimationResults.length;
        if(!this.props.epsTimeLine[this.props.epsNodeCount - selectedSliderValue]){
            return;
        }
        const nextDate = this.props.epsTimeLine[this.props.epsNodeCount - selectedSliderValue].Date;
        for (let i = 0; i < eLength; i++) {
            const earningsAnimationResult = this.props.earningsAnimationResults[i];
            if (this.props.majorPeriodicity === GraphType.Monthly && earningsAnimationResult.SnapshotDate && DateHelper.getYearMonth(earningsAnimationResult.SnapshotDate) === DateHelper.getYearMonth(nextDate)) {
                if (selectedSliderValue < this.state.currentGraphValueIndex && i > 0) {
                    this.props.pricePanelData.EPSSnapshotData = this.props.earningsAnimationResults[i - 1];
                    this.props.processEPSAnimation();
                    this.setCurrentIntervalDate(this.props.earningsAnimationResults[i - 1].SnapshotDate);
                }
                else {
                    this.props.pricePanelData.EPSSnapshotData = earningsAnimationResult;
                    this.props.processEPSAnimation();
                    this.setCurrentIntervalDate(earningsAnimationResult.SnapshotDate);
                }
                this.setState({validValueIndex: selectedSliderValue});
                this.props.updateFundamentalLineStates({ isHistoricEpsLine: true});
                break;
            }
            else if (earningsAnimationResult.SnapshotDate && DateHelper.getDateString(earningsAnimationResult.SnapshotDate) === DateHelper.getDateString(nextDate)) {
                if (selectedSliderValue < this.state.currentGraphValueIndex && i > 0) {
                    this.props.pricePanelData.EPSSnapshotData = this.props.earningsAnimationResults[i - 1];
                    this.props.processEPSAnimation();
                    this.setCurrentIntervalDate(this.props.earningsAnimationResults[i - 1].SnapshotDate);
                }
                else {
                    this.props.pricePanelData.EPSSnapshotData = earningsAnimationResult;
                    this.props.processEPSAnimation();
                    this.setCurrentIntervalDate(earningsAnimationResult.SnapshotDate);
                }
                this.setState({validValueIndex: selectedSliderValue});
                this.props.updateFundamentalLineStates({ isHistoricEpsLine: true});
                break;
            }
        }
    }
    earningsAnimationTimerTick(isPlaying) {
        let selectedSliderValue = null;
        if (this.state.currentGraphValueIndex < this.props.epsNodeCount && isPlaying) {
            selectedSliderValue = this.state.currentGraphValueIndex + 1;
            this.updateEarningLine(selectedSliderValue);
        } else {
            if(this.props.epsAnimationSettings.IsLoopbackEnabled){
                clearInterval(this.interval); 
                this.onChangeComplete();
                selectedSliderValue = this.state.currentGraphValueIndex;
                this.setState({ currentGraphValueIndex: selectedSliderValue, validValueIndex: selectedSliderValue, dragging: false });
                this.props.getEarningsAnimationSetFromController(); 
                this.interval = setInterval(this.startAnimationEPS, 3000, isPlaying); 
            }
            else {
                selectedSliderValue = this.state.currentGraphValueIndex;
                this.resetSliderByValue(selectedSliderValue);
            }
        }
        this.props.updateFundamentalLineStates({ currentGraphValueIndex: selectedSliderValue});
    }
    onChangeComplete(isUnMounting = false) {
        if (this.state.currentGraphValueIndex === this.props.epsNodeCount || isUnMounting === true) {
            // EPSAnimationStore.updateEpsLineComponent();
            if(!this.props.pricePanelData.EPSRawSnapshotData){
                this.props.pricePanelData.EPSRawSnapshotData = this.props.pricePanelData.EPSSnapshotData;
            }
            this.props.pricePanelData.EPSSnapshotData = this.props.pricePanelData.EPSRawSnapshotData;
            this.props.processEPSAnimation();
            this.props.updateFundamentalLineStates({ isHistoricEpsLine: false});
            this.setCurrentIntervalDate(this.props.epsEndDate);  
        }
    }
    resetSliderByValue(selectedSliderValue = this.props.epsNodeCount) {
        this.props.updateEpsIsPlaying(false);
        clearInterval(this.interval);
        this.onChangeComplete();
        this.setState({ currentGraphValueIndex: selectedSliderValue, validValueIndex: selectedSliderValue, dragging: false });
    }

    slideronChange(value, e) {
        if(e){
            e.preventDefault();
        }
        if (this.props.earningsAnimationResults.length === 0){
            this.props.getEarningsAnimationSetFromController(); 
            this.setState({ earningsAnimationResults: this.props.earningsAnimationResults });          
        }
        if (this.props.earningsAnimationResults.length === 0){ 
            return;
        }
        let sliderValue = Math.round(value);
        sliderValue = sliderValue >= this.props.lastVisibleGraphValueIndex ? sliderValue : this.props.lastVisibleGraphValueIndex + 1;
        sliderValue = sliderValue <= this.props.epsNodeCount ? sliderValue : this.props.epsNodeCount;
        if (sliderValue === this.props.epsNodeCount){
            this.onChangeComplete();
            this.setState({ currentGraphValueIndex: sliderValue, validValueIndex: sliderValue });
        }
        else if (this.props.earningsAnimationResults.length > 0) {
            this.updateEarningLine(sliderValue);
            this.props.updateFundamentalLineStates({ currentGraphValueIndex: sliderValue});
        }
    }

    setCurrentIntervalDate(SnapshotDate) {  
        if (StringUtil.isEmpty(this.props.epsTimeLine)){ 
            return;
        }
        let epsFormateDate = null;
        if (SnapshotDate !== null && SnapshotDate !== undefined) {
            epsFormateDate = DateHelper.getDateString(SnapshotDate);
        } else {
            epsFormateDate = this.props.epsFormateDate;
        }
        if (epsFormateDate === null){
            epsFormateDate = DateHelper.getDateString(this.props.epsEndDate)
        }
        this.props.updateFundamentalLineStates({epsFormateDate});
    }
    getAnimationSize(){
        let aboveHeight = 0;
        let belowHeight = 0;
        let chartWidth = 0;
        Object.entries(this.props.charts).forEach(([key, value])=>{
            const view = value.current;
            if (view && key === BlockType.Pricechart) {
                aboveHeight += (this.props.dimension.height + 2);
                chartWidth = this.props.dimension.width;
            }
            else if (view && (key === BlockType.Insider || key === BlockType.Volume)) {
                belowHeight += view.getBoundingClientRect().height;
            }
            else if(view){
                aboveHeight += view.getBoundingClientRect().height;
            }
        });
        return {aboveHeight, belowHeight, chartWidth};
    }

    render() {
        const animationSize = this.getAnimationSize();
        let width = 0;
        if(this.props.allPoints){
            width = animationSize.chartWidth-this.props.allPoints[0].xAxis;
        }
        else{
            return null;
        }
        const animationWidth = this.props.epsTimeLine ? this.props.epsTimeLine.length * 4 : 0;
        const areaStyle = {
            width: `${animationSize.chartWidth  + 48.5}px`,
            height: `${animationSize.aboveHeight + animationSize.belowHeight + 20}px`,
            position: "absolute",
            zIndex: "10",
            bottom: `${-animationSize.belowHeight - 22}px`,
            overflow: "hidden",
            pointerEvents: "none",
            left: "0px",
            display: this.props.IsVisible ? "block" : "block"
        }
        const animationStyle = {
            width: `${animationWidth + 97}px`,
            height: "0px",
            position: "absolute",
            bottom: `${animationSize.belowHeight + 60}px`,
            right: `${width - 48.5}px`
        }
        const validValueIndex = this.state.validValueIndex > this.props.epsNodeCount ? this.props.epsNodeCount : this.state.validValueIndex;
        return (
            <div style={areaStyle}>
                <div className="animation" style={animationStyle} ref={(ref) => (this.animation = ref)}>
                    <AnimationSlider
                        min={0}
                        max={this.props.epsNodeCount}
                        step={1}
                        value={validValueIndex}
                        orientation={"horizontal"}
                        tooltip={false}
                        handleLabel={this.props.epsFormateDate}
                        onChange={this.slideronChange}
                        onChangeComplete={this.onChangeComplete}
                        isPlay={this.props.isPlaying}
                        onClick={this.playGraph}
                        dockedEpsScrubber = {this.props.dockedEpsScrubber}
                        // getAllCharts = {this.props.charts}
                        animationSize = { animationSize }
                        rightWidth = {width}
                        snapshots = { this.props.snapshots}
                    />
                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ DatagraphReducers })=> {
    const { epsAnimationSettings, earningsAnimationResults, isEPSChartLoaded, lastVisibleGraphValueIndex, lastStartGraphValueIndex, currentGraphValueIndex,
        epsTimeLine, isEPSAnimationDataReady, epsEndDate, IsEarningsAnimationDocked, snapshots, epsFormateDate, IsEarningsAnimationDisplayed, 
        isPlaying, IsAnimateWithChartLoad, AnimationSpeedEnum } = DatagraphReducers.FundamentalLineReducer;
    const { HiLowPoints, charts, chartHeight, dimension } = DatagraphReducers.PriceChartReducer;
    const { majorPeriodicity, pricePanelData } = DatagraphReducers.DataGraphReducer;
    return { allPoints: HiLowPoints.allPoints, epsAnimationSettings, majorPeriodicity, 
        earningsAnimationResults, isEPSChartLoaded, lastVisibleGraphValueIndex, currentGraphValueIndex, epsNodeCount: epsTimeLine.length, 
        charts, epsTimeLine, lastStartGraphValueIndex, isEPSAnimationDataReady, epsEndDate, IsEarningsAnimationDocked, snapshots, 
        epsFormateDate, chartHeight, pricePanelData, IsEarningsAnimationDisplayed, dimension, isPlaying, IsAnimateWithChartLoad, AnimationSpeedEnum }
}
const mapDispatchToProps = (dispatch)=>({
    updateFundamentalLineStates: (states)=> dispatch(updateFundamentalLineStates(states)),
    getEarningsAnimationSetFromController: ()=> dispatch(getEarningsAnimationSetFromController()),
    dockedEpsScrubber: ()=> dispatch(updateIsEarningsAnimationDocked(true)),
    updateEpsIsPlaying: (isPlaying)=> dispatch(updateEpsIsPlaying(isPlaying)),
    processEPSAnimation: () => dispatch(processEPSAnimation())
})
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AnimationPlayPauseControl)