import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CompareTabType, { CompareMenuType } from "CompareTabType";

class Loading extends PureComponent {
    render() {
        return (
            this.props.isLoading && (<div className="loading internal">
                <div className="loading-spinner" />
            </div>)
        );
    }
}


const mapStateToProps = (state) => {
    const menuState = state.comparisonAndRotationMenuPanel.MenuTabReducer;
    let selectedMenuTab = menuState.ComparisonMenuTab;
    let loadingSection = menuState.isLoading.ComparisonGraph;
    if (menuState.selectedTab === CompareTabType.RotationGraph) {
        selectedMenuTab = menuState.RotationMenuTab;
        loadingSection = menuState.isLoading.RotationGraph;
    }

    let isLoading = loadingSection.SymbolsLoading;
    if (selectedMenuTab === CompareMenuType.Groups) {
        isLoading = loadingSection.GroupsLoading;
    }
    return { isLoading };
}

export default connect(mapStateToProps)(Loading);