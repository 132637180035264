import React, { Component } from 'react';
import { ListItem } from '../Presentational';
import ScrollBar from 'ScrollBar';
import ErrorBoundary from 'ErrorBoundary';
import { PrintMode } from  '../../../../../print/printmode'

class ListContent extends Component {
    
    constructor(props) {
        super(props);
        this.updateScroll = false;
    }

    updateParent = (loaded) => {
        // if(!this.updateScroll && loaded){
        //     this.updateScroll = true;
        //     this.setState({updateScroll: true})
        // }
        // if(!loaded && this.updateScroll)
        //     this.updateScroll = false;
        if(loaded)
            this.forceUpdate();
        
    }
    
    render() {
        return (
            <div className="graph-content virtualList">
                {/* showscrollOnHover */}
                <div id="comparisionMenuVerticalScroll" className="graph-item">
                    <ul id="list-compchart">
                        <ErrorBoundary><ListItem updateParent={this.updateParent}/></ErrorBoundary>
                    </ul>
                </div>
                <ScrollBar scrollId="comparisionMenuVerticalScroll" vScroll={!PrintMode.printing} scrollOnHover={false} />
            </div>);
    }
}

export default ListContent;