import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetOwnerSponsorsBlkRequest extends BaseServiceRequest {
    constructor(listId, isflaggedlist = false) {
        super();
        super.addRequestUri("getownerblkspns.pb");
        super.addRequestParameter("listId",listId.toString());
        super.addRequestParameter("isFlaggedList", isflaggedlist);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "OwnershipResponse";
    }
}

export default GetOwnerSponsorsBlkRequest;
