import { connect } from "react-redux";
import { onOwnershipFundToggle } from "../../../../../Actions/OwnerShipBlockActions";
import PortalContainer from 'PortalContainer';
import React, { Component } from "react";

class HeaderCell2 extends Component {
  constructor(props) {
    super(props);
    this.toggleFund = this.toggleFund.bind(this);
    this.state = {
      hover: false
    };
  }
  onHover(hover) {
    this.setState({ hover: hover });
  }
  toggleFund() {
    if (this.props.header) {
      this.props.onOwnershipFundToggle(2);
    }
  }
  get_tex_width(txt, font) {
    this.element = document.createElement('canvas');
    this.context = this.element.getContext("2d");
    this.context.font = font;
    return Math.ceil(this.context.measureText(txt).width);
  }
  showTooltip(e, client, displayName) {
    let len = 17;
    if (client && client === 1) {
      len = 19;
    }
    if (displayName && displayName.length >= len) {
      const labelWidth = this.get_tex_width(displayName, '14px calibri') + 28;
      let yTop = e.clientY + 18;
      if (yTop > 660) {
        yTop = yTop - 60;
      }
      const fullWidth = e.view.innerWidth;
      let x = e.clientX;
      if ((e.clientX + labelWidth) > fullWidth) {
        x = e.clientX - ((e.clientX + labelWidth) - fullWidth);
      };
      if (x <= 18) {
        x = 18;
      }
      let displayWidth = ((labelWidth - 14) > fullWidth ? fullWidth - 14 : labelWidth - 14);
      if (displayWidth >= 1266) {
        displayWidth = displayWidth - 10;
      }
      this.toolTip.style.top = `${yTop}px`;
      this.toolTip.style.left = `${x}px`;
      this.toolTip.style.width = `${displayWidth}px`;
      this.toolTip.style.display = 'block';
      this.toolTip.style.position = 'absolute';
      this.toolTip.style.zIndex = 99;
      this.toolTip.innerHTML = displayName;
    }
  }

  syncHover(index, hover) {
    this.props.syncHover(index, hover);
  }

  clearCellTitle() {
    this.toolTip.innerHTML = '';
    this.toolTip.style.display = 'none';
    this.toolTip.style.top = '0px';
    this.toolTip.style.left = '0px';
    this.toolTip.style.right = '0px';
  }

  render() {
    const divstyle = { height: this.props.RowHeight };
    const clsHover = this.state.hover ? "hover-on-row " : "";
    let ownershiprowlabel = "";
    if (this.props.RightClient && this.props.RightClient === 1) {
      ownershiprowlabel = this.props.header.SponsorName.toUpperCase();
    }
    else {
      ownershiprowlabel = this.props.header.ManagementTeam;
    }
    return (
      <div style={divstyle} className={`ownership-block-header-row ${clsHover}`}
        onMouseOver={(e) => { this.syncHover(this.props.index, true); this.showTooltip(e, this.props.RightClient, ownershiprowlabel) }}
        onFocus={() => this.syncHover(this.props.index, true)}
        onMouseLeave={() => { this.syncHover(this.props.index, false); this.clearCellTitle() }}
        onMouseDown={this.toggleFund}>
        <span style={{ cursor: 'pointer' }} className="ownership-block-header-cell" >{ownershiprowlabel}</span>
        <PortalContainer>
          <div className="tooltip-box ownership-tooltip" ref={(r) => { this.toolTip = r }}></div>
        </PortalContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { RightClient, RowHeight } = state.DatagraphReducers.ownershipBlockReducer;
  return { RightClient, RowHeight }
}
const mapDispatchToProps = (dispatch) => ({
  onOwnershipFundToggle: (side) => dispatch(onOwnershipFundToggle(side))
})
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef : true})(HeaderCell2)