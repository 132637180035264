class TranslateUtil {
    constructor(){
        this.texts = null;
    }

    getTexts() {
        return this.texts;
    }

    setTexts(texts){
        this.texts = texts;
    }

    hasTranslateKey(key) {
        return this.texts.hasOwnProperty(key);
    }

    translate(key, options){
        // if (key == null)
        //     return key;
        let trans = '';
        if(!key || !this.texts.hasOwnProperty(key)){
            trans = (options && options.notFound !== undefined) ? options.notFound : key;
        } else {
            trans = this.texts[key];
        }
        if(!options)
            return trans;
        return this.format(trans, options);
    }
    
    format(trans, options) {
        for(let f in options){
            if(f === 'notFound')
                continue;
            trans = trans.replace(new RegExp('\\{' + f + '\\}', 'g'), options[f]);
        }
        return trans;
    };
}

const translateUtil = new TranslateUtil();
export default translateUtil;
