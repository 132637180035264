import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import _ from 'underscore';
import StringUtil from "StringUtil";
import CompareStore from "CompareStore";
import RotationGraphStore from "RotationGraphStore";
import GrpsLogPriceScale from "../../../../Utils/Scales/GrpsLogPriceScale.js";
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";
import SettingsStore from "SettingsStore";
import RotationGraphActions from "RotationGraphActions";
import LocalizationStore from 'LocalizationStore';
import CompareTabType from "CompareTabType";
import { liqFilterValueChanged } from "../../../../Actions/ComparisonActions.js";
import { dispatch } from "../../../../Redux/dispatch.js";
import KeyCodes from "../../../../Constants/KeyCodes.js";

const CHANGE_EVENT = "change";

class LiquidityFilterStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        //Bug fixes - Max Limit error 
        this.setMaxListeners(0);
        this.setInitalValue();
    }
    setInitalValue() {
        this.states = {
            mktCapFilterViewModel: null,
            avolFilterViewModel: null,
            titleText: '',
            isGlobalRatingSelected: true,
            isCountryRatingSelected: false,
            isLiquidityFilterOn: true,
            isFilterOn: true,
            isFilterManualOff: true,
            rangeMktCapValues: [],
            rangeAvolValues: [],
            rangeRatingFilterTypeOptions1Values: [],
            rangeRatingFilterTypeOptions2Values: [],
            showSettingsWindow: false,
            updateCmp: true,
            activeSlider: { id: null, sliderIndex: null }
        }

        this.ratingFilterType = {

        }

        this.targetInfo = {
            MinPrice: 0,
            MaxPrice: 100,
            //BoxWidth: 100000,
            BoxHeight: 0.0,
            VpHeight: 0.0,
            Name: "",

            MMinPrice: 0.0,
            MMaxPrice: 0.0,
            MaxVolume: 0.0,
            MinVolume: 0.0,
            VolAveMax: 0.0,
            Avolume: 0.0,
            DPI: 96.0,
            VolWidth: 0.0,
            VolHeight: 0.0,
            EpsMultiplier: 20.0,
            RpsMultiplier: 20.0,
            NodesPerInch: 28.0,
            ScaleType: "LIN",
            /*public DateTime TradeDate;
            public DateTime StartDate;
            public DateTime OpenTime;
            public DateTime CloseTime;
            */
            TradeDate: "",
            StartDate: "",
            OpenTime: "",
            CloseTime: "",
            GraphType: "",
            SymbolType: ""
        };
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    //Setter Getter

    setIsGlobalRatingSelected(value) {
        this.states.isGlobalRatingSelected = value;
        const state = this.getStates();
        if (value)
            this.isCompTabKeySelected() ? dispatch(liqFilterValueChanged(state)) : RotationGraphActions.liqFilterValueChanged(true);
    }

    getIsGlobalRatingSelected() {
        return this.states.isGlobalRatingSelected;
    }

    setIsCountryRatingSelected(value) {
        this.states.isCountryRatingSelected = value;
        const state = this.getStates();
        if (value)
            this.isCompTabKeySelected() ? dispatch(liqFilterValueChanged(state)) : RotationGraphActions.liqFilterValueChanged(true);
    }

    getIsCountryRatingSelected() {
        return this.states.isCountryRatingSelected;
    }

    setIsLiquidityFilterOn(value) {
        this.states.isLiquidityFilterOn = value;
    }

    getIsLiquidityFilterOn() {
        return this.states.isLiquidityFilterOn;
    }

    setIsFilterOn(value, isUpdate = true) {
        this.states.isFilterOn = value;
        const state = this.getStates();
        if (isUpdate) {
            this.isCompTabKeySelected() ? dispatch(liqFilterValueChanged(state)) : RotationGraphActions.liqFilterValueChanged(true);
        }
    }

    getIsFilterOn() {
        return this.states.isFilterOn;
    }

    setShowSettingsWindow(value) {
        this.states.showSettingsWindow = value;
    }

    setIsFilterManualOff(value) {
        this.states.isFilterManualOff = value;
    }

    getIsFilterManualOff() {
        return this.states.isFilterManualOff;
    }

    getStates() {
        return this.states;
    }

    updateActiveSlider(index, id) {
        this.states.activeSlider = {
            id: id,
            sliderIndex: index
        }
    }

    onKeyPressUpdateSlider(keyValue) {
        if (keyValue === KeyCodes.RIGHT_ARROW || keyValue === KeyCodes.UP_ARROW || keyValue === KeyCodes.LEFT_ARROW || keyValue === KeyCodes.DOWN_ARROW) {
            let states = this.getStates();
            if (states && states.activeSlider.id === null && states.activeSlider.sliderIndex === null) return;
            const id = states.activeSlider.id;
            let lowSliderIndex = states[id].lowSliderRangeValueIndex;
            let highSliderIndex = states[id].highSliderRangeValueIndex;
            const maxRangeSliderValue = states[id].maxRangeSliderValue;
            const minRangeSliderValue = states[id].minRangeSliderValue;
            const intervalData = states[id].intervalData;
            switch (keyValue) {
                case KeyCodes.RIGHT_ARROW:
                case KeyCodes.UP_ARROW:
                    if (states.activeSlider.sliderIndex === 0) {
                        lowSliderIndex = lowSliderIndex < highSliderIndex ? lowSliderIndex + 1 : highSliderIndex;
                    }
                    else {
                        highSliderIndex = highSliderIndex < maxRangeSliderValue ? highSliderIndex + 1 : maxRangeSliderValue;
                    }
                    break;
                case KeyCodes.LEFT_ARROW:
                case KeyCodes.DOWN_ARROW:
                    if (states.activeSlider.sliderIndex === 0) {
                        lowSliderIndex = lowSliderIndex > minRangeSliderValue ? lowSliderIndex - 1 : minRangeSliderValue;
                    }
                    else {
                        highSliderIndex = highSliderIndex > lowSliderIndex ? highSliderIndex - 1 : lowSliderIndex;
                    }
                    break;
                default:
                    return;
            }
            this.updateDataFilterRange([lowSliderIndex, highSliderIndex], intervalData[lowSliderIndex], intervalData[highSliderIndex], id)
        }
    }

    updateFilterOn(isInitialState = false) {
        if (
            (this.states.mktCapFilterViewModel.highRangeValue == this.states.mktCapFilterViewModel.maxValueText && this.states.mktCapFilterViewModel.minValueText == this.states.mktCapFilterViewModel.lowRangeValue) &&
            (this.states.avolFilterViewModel.highRangeValue == this.states.avolFilterViewModel.maxValueText &&
                this.states.avolFilterViewModel.minValueText == this.states.avolFilterViewModel.lowRangeValue) && (this.states.ratingFilterTypeOptions1ViewModel.highRangeValue == this.states.ratingFilterTypeOptions1ViewModel.maxValueText && this.states.ratingFilterTypeOptions1ViewModel.minValueText == this.states.ratingFilterTypeOptions1ViewModel.lowRangeValue) && (this.states.ratingFilterTypeOptions2ViewModel.highRangeValue == this.states.ratingFilterTypeOptions2ViewModel.maxValueText && this.states.ratingFilterTypeOptions2ViewModel.minValueText == this.states.ratingFilterTypeOptions2ViewModel.lowRangeValue)) {
            this.setIsFilterOn(false, false);
            this.setIsFilterManualOff(true);
        }
        else {
            if (!isInitialState) {
                this.setIsFilterOn(true, false);
                this.setIsFilterManualOff(false);
            }
        }
    }

    getDescrition(ratingFilterType, prefix) {
        switch (ratingFilterType.toUpperCase()) {
            case "DATAGRAPH":
                return prefix + LocalizationStore.getTranslatedData("CCG_DatagraphRating", "Datagraph Rating");
            case "EPS":
                return prefix + LocalizationStore.getTranslatedData("CCG_EPSRank", "EPS Rank");
            case "RELATIVESTRENGTH":
                return prefix + LocalizationStore.getTranslatedData("CCG_RSR", "Relative Strength Rating");
            case "ACCUMULATIONDISTRIBUTION":
                return LocalizationStore.getTranslatedData("CCG_AD", "Accumulation Distribution");
            default:
                return "";
        }
    }

    createFilterOptions(prefix) {
        let self = this;
        return Object.assign({}, {
            datagraph: {
                ratingFilterType: "Datagraph",
                description: self.getDescrition("Datagraph", prefix),
                isEnabled: true,
                isSelected: false
            },
            eps: {
                ratingFilterType: "EPS",
                description: self.getDescrition("EPS", prefix),
                isEnabled: true,
                isSelected: false
            },
            relativestrength: {
                ratingFilterType: "RelativeStrength",
                description: self.getDescrition("RelativeStrength", prefix),
                isEnabled: true,
                isSelected: false
            },
            accumulationdistribution: {
                ratingFilterType: "AccumulationDistribution",
                description: self.getDescrition("AccumulationDistribution", prefix),
                isEnabled: true,
                isSelected: false
            }
        });
    }


    getRatingFilter(type, options) {
        switch (type.toUpperCase()) {
            case "DATAGRAPH":
                return options.datagraph;
            case "EPS":
                return options.eps;
            case "RELATIVESTRENGTH":
                return options.relativestrength;
            case "ACCUMULATIONDISTRIBUTION":
                return options.accumulationdistribution;
            default:
                return options.datagraph;
        }
    }


    updateRatingFilter(ratingType, id) {
        let option = this.states[id].ratingFilterTypeOptions;
        let selectedOption = option[ratingType];
        _.each(this.states[id].ratingFilterTypeOptions, (option, key) => {
            if (key != ratingType) {
                option.isSelected = false;
                option.isEnabled = true;
            }
            else {
                option.isSelected = true;
                option.isEnabled = false;
            }

            if (id == "ratingFilterTypeOptions1ViewModel" && this.states["ratingFilterTypeOptions2ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                option.isEnabled = false;
            }
            else if (id == "ratingFilterTypeOptions2ViewModel" && this.states["ratingFilterTypeOptions1ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                option.isEnabled = false;
            }
        });


        selectedOption.isSelected = true;
        selectedOption.isEnabled = false;
        if (id == "ratingFilterTypeOptions1ViewModel") {
            _.each(this.states["ratingFilterTypeOptions2ViewModel"].ratingFilterTypeOptions, (option, key) => {
                if (key == ratingType) {
                    option.isEnabled = false;
                }
                else if (this.states["ratingFilterTypeOptions2ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                    option.isEnabled = false;
                }
                else {
                    option.isEnabled = true;
                }
            });
        }
        else {
            _.each(this.states["ratingFilterTypeOptions1ViewModel"].ratingFilterTypeOptions, (option, key) => {
                if (key == ratingType) {
                    option.isEnabled = false;
                }
                else if (this.states["ratingFilterTypeOptions1ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                    option.isEnabled = false;
                }
                else {
                    option.isEnabled = true;
                }
            });
        }

    }

    isCompTabKeySelected() {
        return SettingsStore.getConsoleSettings().NavCompareSettings.SelectedTabKey == CompareTabType.ComparisonGraph ? true : false;
    }

    //Method
    initializeViewModel(valuesResult = null) {
        this.setInitalValue();
        const settings = SettingsStore.getConsoleSettings().NavCompareSettings;
        const isComparisonGraph = this.isCompTabKeySelected();
        let liqFilter = isComparisonGraph ? settings.TabComparisonGraphSettings.liqFilter : settings.TabRotationGraphSettings.liqFilter;
        let values = isComparisonGraph ? (valuesResult ? valuesResult.LIqFilterValues : null) : RotationGraphStore.states.liqfilterdata.LIqFilterValues;

        if (!StringUtil.isEmpty(values)) {
            let mktCapInterval = this.calculateIntervals(values.MktCapMin, values.MktCapMax);


            this.states.isGlobalRatingSelected = liqFilter.IsGlobalRatingSelected;
            this.states.isCountryRatingSelected = liqFilter.IsCountryRatingSelected;
            this.states.isFilterOn = liqFilter.IsFilterOn;
            this.states.isFilterManualOff = liqFilter.IsFilterManualOff;



            let MktCapLowSliderRangeValueIndex = liqFilter.MktCapLowSliderRangeValueIndex >= 0 && liqFilter.MktCapLowSliderRangeValueIndex < mktCapInterval.length ? liqFilter.MktCapLowSliderRangeValueIndex : 0;

            let MktCapHighSliderRangeValueIndex = liqFilter.MktCapHighSliderRangeValueIndex >= 0 && liqFilter.MktCapHighSliderRangeValueIndex < mktCapInterval.length ? liqFilter.MktCapHighSliderRangeValueIndex : mktCapInterval.length - 1;

            let MktCapLowValue = liqFilter.MktCapLowValue !== undefined && liqFilter.MktCapLowValue !== -1 ?
                (liqFilter.MktCapLowValue < mktCapInterval[0]
                    ? mktCapInterval[MktCapLowSliderRangeValueIndex]
                    : liqFilter.MktCapLowValue > mktCapInterval[MktCapHighSliderRangeValueIndex]
                        ? mktCapInterval[MktCapHighSliderRangeValueIndex]
                        : liqFilter.MktCapLowValue)
                : mktCapInterval[MktCapLowSliderRangeValueIndex];

            let MktCapHighValue = liqFilter.MktCapHighValue !== undefined && liqFilter.MktCapHighValue !== -1 ?
                (liqFilter.MktCapHighValue > mktCapInterval[mktCapInterval.length - 1]
                    ? mktCapInterval[MktCapHighSliderRangeValueIndex]
                    : liqFilter.MktCapHighValue < mktCapInterval[MktCapLowSliderRangeValueIndex]
                        ? mktCapInterval[MktCapLowSliderRangeValueIndex]
                        : liqFilter.MktCapHighValue)
                : mktCapInterval[MktCapHighSliderRangeValueIndex];

            /* Fixes - PANWEB-2374 */

            if (mktCapInterval && mktCapInterval[0] > MktCapLowValue && MktCapLowSliderRangeValueIndex === 0) {
                MktCapLowSliderRangeValueIndex = 1;
            }

            if (mktCapInterval && mktCapInterval[mktCapInterval.length - 1] > MktCapHighValue && MktCapHighSliderRangeValueIndex === (mktCapInterval.length - 1)) {
                MktCapHighSliderRangeValueIndex = MktCapHighSliderRangeValueIndex - 1;
            }

            this.states.mktCapFilterViewModel = Object.assign({}, {
                id: "mktCapFilterViewModel",
                minRangeSliderValue: mktCapInterval[0],
                maxRangeSliderValue: mktCapInterval.length - 1,
                minValueText: mktCapInterval[0],
                maxValueText: mktCapInterval[mktCapInterval.length - 1],
                intervalData: mktCapInterval,
                lowRangeValue: Number(MktCapLowValue),
                highRangeValue: Number(MktCapHighValue),
                lowSliderRangeValueIndex: MktCapLowSliderRangeValueIndex,
                highSliderRangeValueIndex: MktCapHighSliderRangeValueIndex,
                title: LocalizationStore.getTranslatedData("CCG_MarketCap", "Market Capitalization (in millions) US$"),
                filterType: "value"
            });
            let avolInterval = this.calculateIntervals(values.AvolMin, values.AvolMax);

            let AvolFilterLowSliderRangeValueIndex = liqFilter.AvolFilterLowSliderRangeValueIndex >= 0 && liqFilter.AvolFilterLowSliderRangeValueIndex < avolInterval.length ? liqFilter.AvolFilterLowSliderRangeValueIndex : 0;

            let AvolFilterHighSliderRangeValueIndex = liqFilter.AvolFilterHighSliderRangeValueIndex >= 0 && liqFilter.AvolFilterHighSliderRangeValueIndex < avolInterval.length ? liqFilter.AvolFilterHighSliderRangeValueIndex : avolInterval.length - 1;

            let AvolFilterLowValue = liqFilter.AvolFilterLowValue !== undefined && liqFilter.AvolFilterLowValue !== -1
                ? (liqFilter.AvolFilterLowValue < avolInterval[0]
                    ? avolInterval[AvolFilterLowSliderRangeValueIndex]
                    : liqFilter.AvolFilterLowValue > avolInterval[AvolFilterHighSliderRangeValueIndex]
                        ? avolInterval[AvolFilterHighSliderRangeValueIndex]
                        : liqFilter.AvolFilterLowValue)
                : avolInterval[AvolFilterLowSliderRangeValueIndex];

            let AvolFilterHighValue = liqFilter.AvolFilterHighValue !== undefined && liqFilter.AvolFilterHighValue !== -1
                ? (liqFilter.AvolFilterHighValue > avolInterval[avolInterval.length - 1]
                    ? avolInterval[AvolFilterHighSliderRangeValueIndex]
                    : liqFilter.AvolFilterHighValue < avolInterval[AvolFilterLowSliderRangeValueIndex]
                        ? avolInterval[AvolFilterLowSliderRangeValueIndex]
                        : liqFilter.AvolFilterHighValue)
                : avolInterval[AvolFilterHighSliderRangeValueIndex];
            /* Fixes - PANWEB-2374 */
            if (avolInterval && avolInterval[0] > AvolFilterLowValue && AvolFilterLowSliderRangeValueIndex === 0) {
                AvolFilterLowSliderRangeValueIndex = 1;
            }

            if (avolInterval && avolInterval[avolInterval.length - 1] > AvolFilterHighValue && AvolFilterHighSliderRangeValueIndex === (avolInterval.length - 1)) {
                AvolFilterHighSliderRangeValueIndex = AvolFilterHighSliderRangeValueIndex - 1;
            }

            this.states.avolFilterViewModel = Object.assign({}, {
                id: "avolFilterViewModel",
                minRangeSliderValue: avolInterval[0],
                maxRangeSliderValue: avolInterval.length - 1,
                minValueText: avolInterval[0],
                maxValueText: avolInterval[avolInterval.length - 1],
                intervalData: avolInterval,
                lowRangeValue: Number(AvolFilterLowValue),
                highRangeValue: Number(AvolFilterHighValue),
                lowSliderRangeValueIndex: AvolFilterLowSliderRangeValueIndex,
                highSliderRangeValueIndex: AvolFilterHighSliderRangeValueIndex,
                title: LocalizationStore.getTranslatedData("CCG_AverageDDV", "Average Daily Dollar Volume (in millions) US$"),
                filterType: "value"
            });

            let RatingFilterType2 = liqFilter.RatingFilterType2, RatingFilterType1 = liqFilter.RatingFilterType1;
            let titleText = this.states.isGlobalRatingSelected ? LocalizationStore.getTranslatedData("CCG_Global", "Global ") : "";
            let options1 = this.createFilterOptions(titleText);
            let options2 = this.createFilterOptions(titleText);

            let selectedOption1 = this.getRatingFilter(RatingFilterType1, options1);
            let selectedOption2 = this.getRatingFilter(RatingFilterType2, options2);



            let ratingFilterTypeOptions1Interval = this.createArithmaticDict(1, 99);
            if (RatingFilterType1.toLowerCase() == "accumulationdistribution") {
                ratingFilterTypeOptions1Interval = this.createArithmaticDict(-100, 100);
            }

            let RatingFilter1LowSliderRangeValueIndex = liqFilter.RatingFilter1LowSliderRangeValueIndex >= 0 && liqFilter.RatingFilter1LowSliderRangeValueIndex < ratingFilterTypeOptions1Interval.length ? liqFilter.RatingFilter1LowSliderRangeValueIndex : 0;

            let RatingFilter1HighSliderRangeValueIndex = liqFilter.RatingFilter1HighSliderRangeValueIndex >= 0 && liqFilter.RatingFilter1HighSliderRangeValueIndex < ratingFilterTypeOptions1Interval.length ? liqFilter.RatingFilter1HighSliderRangeValueIndex : ratingFilterTypeOptions1Interval.length - 1;

            this.states.ratingFilterTypeOptions1ViewModel = Object.assign({}, {
                id: "ratingFilterTypeOptions1ViewModel",
                minRangeSliderValue: 0,
                maxRangeSliderValue: ratingFilterTypeOptions1Interval.length - 1,
                minValueText: ratingFilterTypeOptions1Interval[0],
                maxValueText: ratingFilterTypeOptions1Interval[ratingFilterTypeOptions1Interval.length - 1],
                intervalData: ratingFilterTypeOptions1Interval,
                lowRangeValue: ratingFilterTypeOptions1Interval[RatingFilter1LowSliderRangeValueIndex],
                highRangeValue: ratingFilterTypeOptions1Interval[RatingFilter1HighSliderRangeValueIndex],
                lowSliderRangeValueIndex: RatingFilter1LowSliderRangeValueIndex,
                highSliderRangeValueIndex: RatingFilter1HighSliderRangeValueIndex,
                ratingFilterTypeOptions: options1,
                selectedRatingFilterTypeOption: selectedOption1,
                title: selectedOption1.description,
                filterType: "rating"
            });


            let ratingFilterTypeOptions2Interval = this.createArithmaticDict(1, 99);
            if (RatingFilterType2.toLowerCase() == "accumulationdistribution") {
                ratingFilterTypeOptions2Interval = this.createArithmaticDict(-100, 100);
            }
            let RatingFilter2LowSliderRangeValueIndex = liqFilter.RatingFilter2LowSliderRangeValueIndex >= 0 && liqFilter.RatingFilter2LowSliderRangeValueIndex < ratingFilterTypeOptions2Interval.length ? liqFilter.RatingFilter2LowSliderRangeValueIndex : 0;

            let RatingFilter2HighSliderRangeValueIndex = liqFilter.RatingFilter2HighSliderRangeValueIndex >= 0 && liqFilter.RatingFilter2HighSliderRangeValueIndex < ratingFilterTypeOptions2Interval.length ? liqFilter.RatingFilter2HighSliderRangeValueIndex : ratingFilterTypeOptions2Interval.length - 1;

            this.states.ratingFilterTypeOptions2ViewModel = Object.assign({}, {
                id: "ratingFilterTypeOptions2ViewModel",
                minRangeSliderValue: 0,
                maxRangeSliderValue: ratingFilterTypeOptions2Interval.length - 1,
                minValueText: ratingFilterTypeOptions2Interval[0],
                maxValueText: ratingFilterTypeOptions2Interval[ratingFilterTypeOptions2Interval.length - 1],
                intervalData: ratingFilterTypeOptions2Interval,
                lowRangeValue: ratingFilterTypeOptions2Interval[RatingFilter2LowSliderRangeValueIndex],
                highRangeValue: ratingFilterTypeOptions2Interval[RatingFilter2HighSliderRangeValueIndex],
                lowSliderRangeValueIndex: RatingFilter2LowSliderRangeValueIndex,
                highSliderRangeValueIndex: RatingFilter2HighSliderRangeValueIndex,
                ratingFilterTypeOptions: options2,
                selectedRatingFilterTypeOption: selectedOption2,
                title: selectedOption2.description,
                filterType: "rating"
            });
            this.updateRatingFilter(RatingFilterType1.toLowerCase(), "ratingFilterTypeOptions1ViewModel");

            this.updateRatingFilter(RatingFilterType2.toLowerCase(), "ratingFilterTypeOptions2ViewModel");

            this.updateFilterOn(true);
            const state = this.getStates();
            isComparisonGraph ? dispatch(liqFilterValueChanged(state)) : RotationGraphActions.liqFilterValueChanged(false);
        }
    }


    saveFilterDataToSettings() {
        let states = this.getStates();
        let navCompareSettings = CompareStore.getNavCompareSettings();
        if (this.isCompTabKeySelected()) {
            CompareSettingHelper.updateLiqFilterData(navCompareSettings.TabComparisonGraphSettings, states);
        }
        else {
            CompareSettingHelper.updateLiqFilterData(navCompareSettings.TabRotationGraphSettings, states);
        }

        SettingsStore.saveSettings();
    }



    createArithmaticDict(minValue, maxValue) {
        let IntervalList = [];
        for (var i = minValue; i <= maxValue; i++) {
            IntervalList.push(i);
        }
        return IntervalList;
    }

    calculateIntervals(minValue, maxValue) {
        this.targetInfo.MinPrice = Math.abs(minValue - 0.0) < Number.EPSILON ? 0.00000001 : minValue;
        this.targetInfo.MaxPrice = Math.abs(maxValue - 0.0) < Number.EPSILON ? 0.00000001 : maxValue;
        this.targetInfo.BoxHeight = 300;
        this.targetInfo.VpHeight = 300;
        this.targetInfo.GraphType = 2;
        let logscale = new GrpsLogPriceScale(this.targetInfo);
        let IntervalList = [];
        IntervalList.push(Math.round(this.targetInfo.MinPrice));
        if (logscale.MHLabel != null)
            for (let i = 0; i < logscale.MHLabel.length; i++) {
                let item = logscale.MHLabel[i].labels.HLabel;

                if (item != null && (i % 2 == 0 && parseFloat(item) > this.targetInfo.MinPrice)) {
                    IntervalList.push(item);
                }
            }
        IntervalList.push(Math.round(this.targetInfo.MaxPrice));
        return IntervalList;
    }

    updateDataFilterRange(value, dataValueLow, dataValueHigh, id) {
        let states = this.getStates();
        states[id].lowSliderRangeValueIndex = value[0];
        states[id].highSliderRangeValueIndex = value[1];
        states[id].lowRangeValue = dataValueLow;
        states[id].highRangeValue = dataValueHigh;
        this.updateFilterOn();
        this.isCompTabKeySelected() ? dispatch(liqFilterValueChanged(states)) : RotationGraphActions.liqFilterValueChanged(true);
        this.emit(CHANGE_EVENT);
    }

    updateSelectedRatingFilterType(ratingType, id) {
        const state = this.getStates();
        if (id == "ratingFilterTypeOptions1ViewModel" || id == "ratingFilterTypeOptions2ViewModel") {
            let option = this.states[id].ratingFilterTypeOptions;
            let selectedOption = option[ratingType];
            _.each(this.states[id].ratingFilterTypeOptions, (option, key) => {
                if (key != ratingType) {
                    option.isSelected = false;
                    option.isEnabled = true;
                }
                else {
                    option.isSelected = true;
                    option.isEnabled = false;
                }

                if (id == "ratingFilterTypeOptions1ViewModel" && this.states["ratingFilterTypeOptions2ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                    //hello
                    option.isEnabled = false;
                }
                else if (id == "ratingFilterTypeOptions2ViewModel" && this.states["ratingFilterTypeOptions1ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                    option.isEnabled = false;
                }
            });


            selectedOption.isSelected = true;
            selectedOption.isEnabled = false;
            if (id == "ratingFilterTypeOptions1ViewModel") {
                _.each(this.states["ratingFilterTypeOptions2ViewModel"].ratingFilterTypeOptions, (option, key) => {
                    //hello
                    if (key == ratingType) {
                        option.isEnabled = false;
                    }
                    else if (this.states["ratingFilterTypeOptions2ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                        option.isEnabled = false;
                    }
                    else {
                        option.isEnabled = true;
                    }
                });
            }
            else {
                _.each(this.states["ratingFilterTypeOptions1ViewModel"].ratingFilterTypeOptions, (option, key) => {
                    if (key == ratingType) {
                        option.isEnabled = false;
                    }
                    else if (this.states["ratingFilterTypeOptions1ViewModel"].selectedRatingFilterTypeOption.ratingFilterType == option.ratingFilterType) {
                        option.isEnabled = false;
                    }
                    else {
                        option.isEnabled = true;
                    }
                });
            }



            let ratingInterVal = this.createArithmaticDict(1, 99);
            if (ratingType == "accumulationdistribution") {
                ratingInterVal = this.createArithmaticDict(-100, 100);
            }
            this.states[id].minRangeSliderValue = 0;
            this.states[id].maxRangeSliderValue = ratingInterVal.length - 1;
            this.states[id].minValueText = ratingInterVal[0];
            this.states[id].maxValueText = ratingInterVal[ratingInterVal.length - 1];
            this.states[id].intervalData = ratingInterVal;
            this.states[id].lowRangeValue = ratingInterVal[0];
            this.states[id].highRangeValue = ratingInterVal[ratingInterVal.length - 1];
            this.states[id].lowSliderRangeValueIndex = 0;
            this.states[id].highSliderRangeValueIndex = ratingInterVal.length - 1;
            this.states[id].selectedRatingFilterTypeOption = selectedOption;
            this.states[id].title = selectedOption.description;
            this.emit(CHANGE_EVENT);
            this.isCompTabKeySelected() ? dispatch(liqFilterValueChanged(state)) : RotationGraphActions.liqFilterValueChanged(true);
        }

    }

    updateRatingFilterTitle() {
        if (this.states.isGlobalRatingSelected) {
            _.each(this.states.ratingFilterTypeOptions1ViewModel.ratingFilterTypeOptions, (option, key) => {
                option.description = this.getDescrition(key, `${LocalizationStore.getTranslatedData("Country_1000","Global")} `);
            });

            _.each(this.states.ratingFilterTypeOptions2ViewModel.ratingFilterTypeOptions, (option, key) => {
                option.description = this.getDescrition(key, `${LocalizationStore.getTranslatedData("Country_1000","Global")} `);
            });
            this.states.ratingFilterTypeOptions1ViewModel.title = this.states.ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.description = this.getDescrition(this.states.ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType, LocalizationStore.getTranslatedData("CCG_Global", "Global "));
            this.states.ratingFilterTypeOptions2ViewModel.title = this.states.ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.description = this.getDescrition(this.states.ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType, LocalizationStore.getTranslatedData("CCG_Global", "Global "));
        }
        else {
            _.each(this.states.ratingFilterTypeOptions1ViewModel.ratingFilterTypeOptions, (option, key) => {
                option.description = this.getDescrition(key, "");
            });

            _.each(this.states.ratingFilterTypeOptions2ViewModel.ratingFilterTypeOptions, (option, key) => {
                option.description = this.getDescrition(key, "");
            });
            this.states.ratingFilterTypeOptions1ViewModel.title = this.states.ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.description = this.getDescrition(this.states.ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType, "");
            this.states.ratingFilterTypeOptions2ViewModel.title = this.states.ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.description = this.getDescrition(this.states.ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType, "");
        }
        this.emit(CHANGE_EVENT);
    }

    saveFilterValue() {
        this.saveFilterDataToSettings();
    }

    dispatcherCallback() {
        return;
    }
}

export default new LiquidityFilterStore();
