import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from 'BaseServiceApi';

class RemoveItemsFromUserListRequest extends BaseServiceRequest {

    constructor(listId, cacheId, msids, snapshotdates) {
        super();
        super.addRequestUri("instruments2.pb");

        const ReqProto = BaseServiceApi.rayData["RemoveInstrumentsFromUserList2DO"]
        const RemoveInstrumentsFromUserList2DO = new ReqProto();

        RemoveInstrumentsFromUserList2DO.ListID = listId.toString();
        RemoveInstrumentsFromUserList2DO.CacheID = parseInt(cacheId);
        RemoveInstrumentsFromUserList2DO.MsIDS = msids.toString();
        RemoveInstrumentsFromUserList2DO.SnapshotDates = snapshotdates;

        //const reqcontent = RemoveInstrumentsFromUserList2DO.encode();
        super.setContent(RemoveInstrumentsFromUserList2DO);
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "DeleteSymbolListDataResponse";
    }
}

export default RemoveItemsFromUserListRequest;
