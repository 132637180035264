import keyMirror from "keymirror";

export const MiniListConstants = {
  ActionTypes: keyMirror({
    ON_INTERNAL_CHANGE: null,
    ON_EXINTERNAL_CHANGE: null,
    ON_ADD_SYMBOL_COMPLETE: null,
    SET_MINILIST_NEXTINTERVAL: null,
    ON_MINILIST_PLAYING: null,
    ON_UPDATE_UPDATEHISTORIC: null,
    CLEAR_MINILISTPLAY_TIMEOUT: null,
    LIST_NAME_UPDATE: null
  })
};


