import React, { PureComponent } from 'react';
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import UserInfoUtil from "Utils/UserInfoUtil";
import StringUtil from 'StringUtil';
import LangType from "Constants/LangType.js";

class BenchMarkHeader extends PureComponent {

    toggleUptrend = () => {
        this.props.toggleTrendLines(true);
    }

    toggleDowntrend = () => {
        this.props.toggleTrendLines(false);
    }

    getUptrendClassName() {
        let className = "high-node";
        if (this.props.isUptrendVisible) className += " high-node-fill"; else className += " high-node-no-fill";
        return className;
    }

    getDowntrendClassName() {
        let className = "low-node";
        if (this.props.isDowntrendVisible) className += " low-node-fill"; else className += " low-node-no-fill";
        return className;
    }

    translateCountry(item) {
        let countrycode, breadcrumbname, strRegionName, BenchMarkName;

        if (item.regionCode === "GLOBAL") {
            countrycode = item.regionKey;
            breadcrumbname = LocalizationStore.getTranslatedData("RotGraph_" + item.regionName, item.regionName);
        }
        else if (item.regionCode === "AMER" || item.regionCode === "ASIA" || item.regionCode === "EMEA") {
            countrycode = item.regionKey;
            breadcrumbname = LocalizationStore.getTranslatedData("RotGraph_" + item.countryName.replace(" ", ""), item.countryName);
        }
        else {
            countrycode = item.countryCode;
            breadcrumbname = item.countryName;
        }
        if (UserInfoUtil.getCurrentLang() !== LangType.RAY_DEFAULT) {
            switch (item.regionKey) {
                case 1000:
                    strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_Global", "World");
                    break;
                case 1001:
                    strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_Americas", "Americas");
                    break;
                case 1002:
                    strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_EMEA", "EMEA");
                    break;
                case 1003:
                    strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_AsiaPacific", "Asia Pacific");
                    break;
                default:
                    strRegionName = LocalizationStore.getTranslatedData("Country_" + countrycode, breadcrumbname);
                    break;
            }
            BenchMarkName = LocalizationStore.getTranslatedData("CRG_ONeilIndex", "O'Neil All {0} Index", strRegionName);
        }
        else {
            BenchMarkName = !StringUtil.isEmpty(this.props.benchMarkData) ? this.props.benchMarkData.BenchMarkName : '';
        }

        return BenchMarkName;
    }

    render() {
        const { isBenchMarkLoading, countryCode, tempPositiveStyle, tempNegativeStyle } = this.props;
        const benchMarkName = !StringUtil.isEmpty(countryCode) ? this.translateCountry(countryCode) : "";

        return (
            <div className="graph-head-block">
                <div className=" graph-title"> {benchMarkName}</div>
                {!isBenchMarkLoading &&
                    <div className="graph-title-action">
                        <span htmlFor="formInlineName" className="control-label">
                            {LocalizationStore.getTranslatedData("CRG_CountriesIn", "Countries in:")}
                        </span>
                        <div className="graph-uptrend">
                            <span className={this.getUptrendClassName()} style={tempPositiveStyle} onClick={this.toggleUptrend}></span>
                            <span className="txt-btm-bdr dotted-line" onClick={this.toggleUptrend}>
                                {LocalizationStore.getTranslatedData("CRG_Uptrend", "Uptrend")}
                            </span>
                        </div>
                        <div className="graph-downtrend">
                            <span className={this.getDowntrendClassName()} style={tempNegativeStyle} onClick={this.toggleDowntrend}></span>
                            <span className="txt-btm-bdr dotted-line" onClick={this.toggleDowntrend}>
                                {LocalizationStore.getTranslatedData("CRG_Downtrend", "Downtrend")}
                            </span>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default BenchMarkHeader;