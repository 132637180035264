import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import SettingsStore from "SettingsStore";
import ListApi from "ListApi";
import { reject, map, find, each, sortBy, clone, isNull, isUndefined, indexBy, contains, has, isEmpty, findIndex, uniq } from "underscore";
import StringUtil from 'StringUtil';
import numberFormatter from "number-formatter";
import { OwnershipViewConstants, OwnerShipFetchDetails } from "../../../../Constants/OwnershipConstants";
import GridStore from "GridStore";
import GridActions from "GridActions";
import { setActiveNodeFromOwnership } from "../../../../Actions/OwnerShipActions";
import BaseServiceApi from 'BaseServiceApi';
import UserInfoUtil from "UserInfoUtil";
import ListStore from "ListStore";
import BrowserUtil from "BrowserUtil";
import ListSettingHelper from "../../../ConsoleWindow/Settings/Modules/ListManager/ListManagerSettingHelper.js"
import ListManager from "../../../../Constants/ListManager.js";
import ColumnCustomFilterControl from "../../../../RayCustomControls/ListViewCustomFilter/ColumnCustomFilterControl.jsx";
import BasicEsInfo from 'ServiceApi/RequestHelper/BasicEsInfo';
import LocalizationStore from 'LocalizationStore';
// import { findParentNode } from "../../../../Utils/OwnerShipUtil.js";
import { findNodeByIdAndUpdateDate } from "../../../../Actions/ListViewActions";
import ListExplorerStore from "ListExplorerStore";
import { dataLoadingComplete } from '../../../../Actions/OwnerShipActions';
import moment from "moment";
import TabOwnershipSettings from "../../../ConsoleWindow/Settings/Modules/ListManager/TabOwnershipSettings";
import PanarayDefault from "../../../../ServiceApi/RequestHelper/PanarayDefault";

const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];
const ListColumnStyleType = BaseServiceApi.rayData["ListColumnStyleType"];
const ListColumnDataType = BaseServiceApi.rayData["ListColumnDataType"];
const InstrumentType = BaseServiceApi.rayData["InstrumentType"];

const cellWidth = require("text-width");
const CHANGE_EVENT = "change";
const DataItemIdType = -4000013,
  DataItemIdName = -4000004,
  DataItemIdSymbol = -4000012,
  DataItemIdRowid = -4,
  DataItemIdFlag = -3,
  ModelStockId = -120,
  PricePercDataItemId = 64,
  DataItemIdNotImportedSymbol = 6300,
  DataItemIdNotImportedDate = 6301,
  pricePercentageChangeColumnId = 64,
  flagColumnWidth = 15,
  defaultDistColumnId = 61;

let w = undefined, isScollBarCallRunning = false;

class OwnershipViewStore extends EventEmitter {
  constructor() {
    super();
    this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
    //Bug fixes - Max Limit error 
    this.setMaxListeners(0);
    this.initialCall = true;
    this.activeNode = {},
    this._state = {
      basic: {},
      columns: {},
      columnSetId: null,
      columnSetName: null,
      listId: null,
      actualListId: null,
      cacheId: null,
      searchKeyword: '',
      pickListData: {},
      alertMessage: null,
      removeItemsFromUserList: { listId: null, cacheId: null, msids: null, currentNodeId: null },
      undoOperationForPaste: {},
      allListSymbolMsids: [],
      highlightSymbolMsIds: [],
      isDeleteEnable: false,
      ownerShipData: {},
      distributiontChartSelectedColumnId: defaultDistColumnId,
      //activeNode: {},
      dragNodeData: '',
      isRefreshView: false
    };
    this._singleRowData = {};
    this._source = [];
    this._defaultSource = [];
    this.secHoldChekData = null;
    this._tempSource = [];
    this._removedColumns = [];
    this._sortCols = [];
    this._currentAction = null;
    this._frozenColumnsSpliter = false;
    this._columnInfo = null;
    this._columnFilters = null;
    this._exceptionSymbols = null;
    this._tempDragDataSource = [];
    this._details = {};
    this._detailsAfterAddSymbol = {};
    this.EntitlementType = BaseServiceApi.rayData["EntitlementType"];
    this.PortfolioListWeightType = BaseServiceApi.rayData["PortfolioListWeightType"];
    this.sourceType = null;
    this.isListLoaded = false;
    this.isExistInUserCategory=false;
    this.disableDelete = false;
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    return this._state;
  }

  getCurrentAction() {
    return this._currentAction;
  }

  getRefreshData() {
    return this._state.isRefreshView;
  }

  setRefreshData(isRefresh) {
    this._state.isRefreshView = isRefresh;
  }

  clearCurrentAction() {
    this._currentAction = null;
  }

  getSourceData() {
    const rows = reject(this._source, (row) => {
      if (!row.isRowVisible) return true;
    });
    return rows;
  }

  getDefaultSourceData() {
    let rows = reject(this._defaultSource, (row) => {
      if (!row.isRowVisible) return true;
    });
    return rows;
  }

  getFlagCount() {
    let flagCount = 0;
    const sourceData = this.getSourceData();
    map(sourceData, (rows) => {
      map(rows, (recordDatas, key) => {
        if (key === 'recordDatas') {
          map(recordDatas, (item) => {
            if (item && item.dataItemId === -3) { //Flag
              if (item.displayValue) flagCount++;
            }
          });
        }
      });
    });
    return flagCount;
  }

  getSelectedIndexesFlagCount() {
    const selectedIndexes = GridStore.getSelectedIndexes();
    const sourceData = this.getSourceData();
    const len = selectedIndexes.length;
    if (len >= sourceData.length) return this.getFlagCount();
    let i = 0, flagCount = 0;
    while (i < len) {
      if (sourceData[selectedIndexes[i]]) {
        const recordDatas = sourceData[selectedIndexes[i]]['recordDatas'];
        map(recordDatas, (item) => {
          if (item && item.dataItemId === -3) { //Flag
            if (item.displayValue) flagCount++;
          }
        });
      }
      i++;
    }
    /*eslint-enable */
    return flagCount;
  }

  getExceptionSymbols() {
    return this._exceptionSymbols;
  }

  undoColumnFilters() {
    //for-filters
      let filterTemp=[];
      let previousFilterTemp=[];
      let uniqueFilterTemp=[];
      if(this._prevColumnFilters?.length && this._columnFilters?.length)
      {
        previousFilterTemp=[...this._prevColumnFilters]
        let flag;
        let uniqueArr=[];
          for(var i=0; i<=this._columnFilters.length-1; i++) {
                let find_temp=this._columnFilters[i]
                flag = 0;
                for(var j=0; j<=this._prevColumnFilters?.length-1; j++) {
                  if(find_temp.dataItemId === this._prevColumnFilters[j].dataItemId)
                    flag=1
                }
            if(flag==0)
              uniqueArr.push(find_temp)
          }
        uniqueFilterTemp=[...uniqueArr]
        filterTemp=[...previousFilterTemp,...uniqueFilterTemp]
      }
      else if(this._prevColumnFilters?.length)
        filterTemp=[...this._prevColumnFilters]
      
      else if(this._columnFilters?.length)
        filterTemp=[...this._columnFilters]
      else
        filterTemp=[...this._columnFilters]
      
      this._columnFilters = clone(filterTemp);
      ColumnCustomFilterControl.setColumnInfoFromStore();
      this._prevColumnFilters = null;
    //for-sorting
    if (this._prevSortCols !== null && this._prevSortCols !== undefined) {
      this._sortCols = clone(this._prevSortCols);
      this._prevSortCols = null;
    }
  }

  removeInvisibleColumns(columnInfos) {
    this._removedColumns = [];
    const currentNode = this.activeNode;
    let isFlaggedOwners = false;
    if(currentNode.name == 'Flagged Owners' && this.isSmartList()){
      isFlaggedOwners = true;
    }
    const columns = reject(columnInfos, (column) => {
      if (!column.isColumnVisible) {
        this._removedColumns.push(column.dataItemId.toString());
        return true;
      } else if ((this._state.listId === -2 || isFlaggedOwners) && column.dataItemId === DataItemIdRowid) {
        this._removedColumns.push(column.dataItemId.toString()); return true;
      } else return false;
    });
    return columns;
  }

  getRemovedColumns(){
    return this._removedColumns;
  }

  setSortColumns() {
    this._sortCols = [];
    map(this._state.columns, (column) => {
      if (column.sortInfo) {
        const obj = {};
        obj.dataItemId = column.dataItemId;
        obj.sortAsc = column.sortInfo.sortAsc;
        obj.sortOrderNum = column.sortInfo.sortOrderNum;
        this._sortCols.push(obj);
      }
    });
    if (this._sortCols.length > 0) this._sortCols = sortBy(this._sortCols, 'sortOrderNum');
  }

  getSortColumns() {
    return this._sortCols;
  }

  async setNewCustomOrder(listId) {
    /*eslint-disable */
    let setCutomOrderExc = await this.setCustomOrder(listId);
    /*eslint-disable */
    if (StringUtil.isEmpty(this._sortCols)) this._sortCols = [];
    let sortItem = []
    sortItem.push({ dataItemId: -4, sortAsc: true, sortOrderNum: 1 });
    return sortItem;
  }

  async setCustomOrder(activeListId, isReset = false) {
    if (this.isMyOwnerList() && activeListId != "-2") {
      /*eslint-disable */
      let setReorder = await this.setReOrderBeforeCustomOrder();
      /*eslint-enable */
    }
  }

  async setReOrderBeforeCustomOrder() {
    const msidList = this.getSourceData().map((c) => c.sponsorId);
    const stringmsids = msidList.join(";");
    /*eslint-disable */
    let t = await this.reorderOrdinalNumbers(this._state.listId, "", 0, stringmsids);
    /*eslint-enable */
  }

  clearSortColumns() {
    map(this._state.columns, (column) => {
      if (column.sortInfo) {
        column.sortInfo = null;
      }
    });
    this._sortCols = [];
  }

  isListEditable() {
    if (this.isMyOwnerList()) {
      return true;
    }
    return false;
  }


  getCurrentNode(nodeId) {
    let ParentListTreeItem = [];
    ListExplorerStore.findParentNode(nodeId, ParentListTreeItem);
    const currentNode = reject(ParentListTreeItem, (item) => item.childNodes.length > 0 );
    return currentNode;
  }

  isSharedListWriteMode(nodeId = ListStore.getSelectedListItem().SelectedActualListId) {
    let SharedListWriteMode = false;
    const currentNode = this.getCurrentNode(nodeId);
    if (currentNode.length > 0)
      SharedListWriteMode = !StringUtil.isEmpty(currentNode[0].shareAccess) ? (currentNode[0].shareAccess === ShareAccessType.SHARE_READANDEDIT) : false;
    return SharedListWriteMode;
  }

  isUserList() {
    let currentNode = this.getCurrentNode(ListStore.getSelectedListItem().SelectedActualListId);
    if (currentNode.length > 0 && currentNode[0].categoryType == ListCategoryType.USER_ListCategory) {
      return true;
    }
    return false;
    // return this.isMyOwnerList();
  }

  updateSortColums(colData, e) {
    const existCol = find(this._sortCols, (key) =>  colData.dataItemId === key.dataItemId );

    if (e.ctrlKey || e.metaKey) {
      if (existCol) {
        if (existCol.sortAsc) existCol.sortAsc = false;
        else existCol.sortAsc = true;
      }
      else {
        const obj = {};
        obj.dataItemId = colData.dataItemId;
        obj.sortAsc = colData.defaultSortAsc;
        obj.sortOrderNum = this._sortCols.length + 1;
        this._sortCols.push(obj);
      }
    }
    else {
      const obj = {};
      this._sortCols = [];
      obj.dataItemId = colData.dataItemId;
      obj.sortAsc = colData.defaultSortAsc;
      if (existCol && existCol.sortAsc) obj.sortAsc = false;
      else if (existCol && !existCol.sortAsc) obj.sortAsc = true;
      obj.sortOrderNum = 1;
      this._sortCols.push(obj);
    }
  }
    /*eslint-disable */
  async processServiceCalls(data) {
    /*eslint-enable */
    const ownershipRecordInfo = data.esBasicCatalogData.ownershipRecordInfo ? JSON.parse(data.esBasicCatalogData.ownershipRecordInfo) : [];
    //const isColumnFilterEnabled = this.getScreenOnOffValue();
    this.setSortColumns();
    // let listBrowser = clone(this._state.basicEsInfo);
    // listBrowser = { ...listBrowser, cacheId: this._state.cacheId, useColFilter: isColumnFilterEnabled, isBrowserCount: true };
    // Promise.all([this.getFullListMsids(data.listId),
    //   this.setPageListEsBasic(ownershipRecordInfo)
    // ]).then(([]) => {    
      await this.setPageListEsBasic(ownershipRecordInfo);
      try {
        const listState = ListStore.getSelectedListItem();
        window.setTimeout(() => {
          dataLoadingComplete(listState)
        }, 0);
        SettingsStore.saveSettings();
        this._currentAction = OwnerShipFetchDetails.GET_OWNERSHIP_DATA;
        ListStore.resetRefresh();
        this.emit(CHANGE_EVENT);
      }
      catch (e) {
        console.error(e);
      }
  }

  async setPageListEsBasic(ownershipRecordInfo) {
    //Reset all column order number
    if (this.resetOrderNumAllColumns()) {
      /*eslint-disable */
      const t = await this.addPageData(ownershipRecordInfo, 0, -1);
      /*eslint-enable */
      this.isListLoaded = true;
      return true;
    }
    else {
      return false;
    }
  }

  findRowId(rowId) {
    const row = find(this.getSourceData(), (key) => rowId.toString() === key.rowId.toString() );
    if (row && !(isNull(row) || isUndefined(row)))
      return row.recordDatas;
  }

  async getUpdateFlagStatus(listId, msidList) {    
    try {
      const msidStr = msidList.join(";");
      const response = await ListApi.getOwnersFlagRequest(listId, msidStr);
      if (response === null || response.flagData === null || response.flagData.length === 0) return true;
      for (const item of response.flagData) {
        if (!item) continue;
        map(this._source, (listModel) => {          
          if (listModel.sponsorId === StringUtil.convertFromLongValueToInt(item.sponsorId)) {
            listModel.isFlagged = item.isflag;
            const recordData = indexBy(listModel.recordDatas, 'dataItemId');
            const colItem = recordData[DataItemIdFlag.toString()];
            if (colItem) {
              colItem.displayValue = item.isflag;
              if (listModel.isFlagged)
                colItem.className = (colItem.className && !colItem.className.includes('icn-flag') ? `${colItem.className} icn-flag active` : 'icn-flag active');
              else colItem.className = (colItem.className && !colItem.className.includes('icn-flag') ? `${colItem.className} icn-flag`: 'icn-flag');
            }
            else {
              const colDataSet = {};
              colDataSet.textWidth = flagColumnWidth;
              colDataSet.displayValue = item.isflag;
              if (listModel.isFlagged)
                colDataSet.className = (colDataSet.className ? `${colDataSet.className} icn-flag active` : 'icn-flag active');
              else colDataSet.className = (colDataSet.className ? `${colDataSet.className} icn-flag` : 'icn-flag');
              listModel.recordDatas.push(colDataSet);
            }
          }
        });
      }
      return true;
    }
    catch (e) {
      console.log(e);
    }
  }

  getAlignment(columnInfo) {
    let alignment = 'center';
    if (columnInfo.dataItemId === DataItemIdType || columnInfo.dataItemId == DataItemIdNotImportedSymbol)
      return alignment;
    else {
      // 3 - Left, 4 - Center, 5 - Right
      switch (columnInfo.dataType) {
        case ListColumnDataType.STRING_ListColumnData:
          alignment = 'left';
          break;
        case ListColumnDataType.DOUBLE_ListColumnData:
        case ListColumnDataType.INT_ListColumnData:
          alignment = 'right';
          break;
        case ListColumnDataType.BOOL_ListColumnData:
        case ListColumnDataType.DATE_ListColumnData:
        default:
          alignment = 'center';
          break;
      }
    }
    return alignment;
  }

  async addPageData(listDatas, seqNum, insertIndex = -1) { 
    if (insertIndex === -1 && seqNum === 0) this._source = [];
    if (!listDatas) {
      return false;
    }
    else{
      this.secHoldChekData = listDatas;
    }
    try {
      let startIndex = seqNum;
      const colDist = indexBy(this._state.columns, 'dataItemId');
      const colKeys = Object.keys(colDist);
      const ownerShipRecordData = listDatas;
      let rowIndex = 0;
      const msidList = [];

      for (const record of ownerShipRecordData) {
        let listdataItem = {};
        try {
          ++rowIndex;
          if (rowIndex < ownerShipRecordData.length) {
            listdataItem =
              {
                isOwnership: true,
                positionIndex: startIndex,
                isSelected: false
              };
          }
          else {
            listdataItem =
              {
                isOwnership: true,
                positionIndex: startIndex,
                isSelected: false,
                isFocus: true
              };

          }
        } catch (e) {
          console.log('error', e);
        }
        listdataItem = { ...listdataItem, instrumentType: InstrumentType.STOCK_Instrument, isRowVisible: true, isActive: false, recordDatas: [] }
        if (record && !(isNull(record) || isUndefined(record))) {
          const recordKeys = Object.keys(record);
          if (!contains(recordKeys, DataItemIdFlag.toString()))
            recordKeys.push(DataItemIdFlag.toString())
          const keys = colKeys;
          if (contains(recordKeys, "msId")) {
            const colValue = record["msId"];
            listdataItem = { ...listdataItem, msId: parseInt(colValue), instrumentId: listdataItem.msId, sponsorId: record["-4000008"] }
            msidList.push(listdataItem.sponsorId);
          }
          for (const key of keys) {
            let col = "";
            if (key === DataItemIdFlag && !record[key]) {
              col = false;
            }
            else if (!record[key] && record[key] != 0) {
              col = "";
            }
            else {
              col = record[key];
            }

            let colDataSet = {};
            if (has(colDist, key) && !contains(this._removedColumns, key.toString())) {
              colDataSet.className = "";
              colDataSet.dataType = colDist[key].dataType;
              if (!Number.parseInt(key))
                continue;
              const colId = Number.parseInt(key);
              switch (colId) {
                case DataItemIdFlag:
                  {
                    if (col) {
                      listdataItem.isFlagged = true;
                      colDataSet.className = (colDataSet.className ? `${colDataSet.className} icn-flag active` : 'icn-flag active');
                    }
                    else {
                      listdataItem.isFlagged = false;
                      colDataSet.className = (colDataSet.className ? `${colDataSet.className} icn-flag` : 'icn-flag');
                    }
                  }
                  break;
                case DataItemIdSymbol:
                  listdataItem.symbol = col;
                  colDataSet.className = `${colDataSet.className}symbol`;
                  break;
                case DataItemIdName:
                  listdataItem.coName = col;
                  break;
                case DataItemIdRowid:
                  // const rowId = 0;
                  if (col) {
                    listdataItem.rowId = parseInt(col);
                  }
                  else {
                    listdataItem.rowId = rowIndex;

                  }
                  break;
                case ModelStockId:
                  listdataItem.modelStockId = col.toString().trim();
                  break;
                  default: break;
              }

              if (has(colDist, colId)) {
                const columnInfo = colDist[colId];
                let strVal = "";
                if (col !== "") {
                  strVal = this.getCellStrVal(columnInfo, col, colDataSet);
                }
                colDataSet = { ...colDataSet, displayValue: strVal, isVisualizationOn: columnInfo.isVisualizationOn };
                if (columnInfo.usePosNegColoring && !colDataSet.isNegative)
                  colDataSet.className = 'grid-cell-positive';
                else if (columnInfo.usePosNegColoring && colDataSet.isNegative)
                  colDataSet.className = 'grid-cell-negative';

                if (colId === DataItemIdRowid) { // #
                  strVal = listdataItem.rowId;
                }
                else if (colId === DataItemIdSymbol) {
                  strVal = listdataItem.symbol;
                }
                else if (colId === DataItemIdFlag) {
                  strVal = listdataItem.isFlagged;
                }
                else if (colId === DataItemIdType) {
                  strVal = col;
                }
                else {
                  if (colId === PricePercDataItemId) {
                    listdataItem.isPricePositive = parseFloat(strVal) >= 0;
                  }
                  if (columnInfo.canVisualize && colDataSet.isVisualizationOn) {
                    listdataItem.isPricePositive = parseFloat(record[PricePercDataItemId]) >= 0;
                    colDataSet.className = listdataItem.isPricePositive ? 'grid-visualize-rect-positive' : 'grid-visualize-rect-negative';
                  }
                }
                if (listdataItem.snapshotDt) {
                  if (listdataItem.symbol !== null)
                    listdataItem.snapshotDt = StringUtil.formatDate(listdataItem.snapshotDt, "MM/DD/YYYY");
                }
                colDataSet.alignment = 'center';
                colDataSet = { ...colDataSet, alignment: this.getAlignment(columnInfo), displayValue: strVal };
                if (colId === DataItemIdFlag) // Flag
                  colDataSet.textWidth = flagColumnWidth;
                else
                  colDataSet.textWidth = this.getCellTextWidth(columnInfo, colDataSet.displayValue, listdataItem.snapshotDt);

              }
              colDataSet.dataItemId = colId;
              const index = this.findColumnIndex(colDataSet.dataItemId);
              if (colKeys && index >= 0 && index < colKeys.length) {
                listdataItem.recordDatas[index] = colDataSet;
              }
            }

          }
         
          if (insertIndex > -1) {
            this._source.splice(insertIndex, 0, listdataItem);
          }
          else {
            this._source.push(listdataItem);
          }
          }

          startIndex++;
        }

      /*eslint-disable */ 
      const t = await this.getUpdateFlagStatus(this._state.listId, msidList);
      /*eslint-enable */
      return true;
    }
    catch (e) {
      console.log('error', e);
    }
  }


  getCellStrVal(columnInfo, col, colDataSet) {

    let strVal = "";
    if (columnInfo.dataType === ListColumnDataType.DATE_ListColumnData) {
      const formatedDate = StringUtil.formatDate(col, columnInfo.formatter.toUpperCase());
      if (formatedDate !== "Invalid date") {
        strVal = formatedDate;
      }
    }
    else if (columnInfo.dataType === ListColumnDataType.STRING_ListColumnData) {
      let value = col.toString();
      if (value && contains(value, ":") && columnInfo.dataItemId === -6000004) {
        const sArray = value.split(':');
        const subStr = sArray[0];
        const num = Number.parseFloat(subStr);
        if (!isNaN(num)) {
          value = `${num.toFixed(2)}:${sArray[1]}`;
        }
      }

      strVal = this.getCellStringValue(value, columnInfo.formatter, columnInfo.isZeroVisible) //CellGenerator.GetCellStringValue(value, columnInfo.FormatString, columnInfo.IsZeroDisplayed);
    }
    else {
      strVal = this.getCellDoubleStringVal(col, columnInfo.formatter, columnInfo.isZeroVisible, columnInfo.isCustomColumn, columnInfo.styleType, colDataSet);

    }

    return strVal;
  }

  getCellStringValue(cellVal, format, isZeroDisplayed) {
    const suffix = contains(format, "%") ? "%" : "";
    let retValue;
    if (isZeroDisplayed && !cellVal) {
      retValue = `0${suffix}`;
    }
    else {
      retValue = !cellVal ? "" : cellVal;
    }
    return retValue;
  }

  getCellDoubleStringVal(cellVal, formatString, isZeroDisplayed, iscustomcolumn, styleType, colDataSet) {
    colDataSet.isNegative = false;
    let doubleValue = parseFloat(cellVal);
    if (!isNaN(doubleValue)) {
      if (doubleValue < 0)
        colDataSet.isNegative = true;
      if (!formatString) {
        doubleValue = doubleValue.toFixed(2);
        return doubleValue.toString();
      }
      if (isZeroDisplayed) {
        if (doubleValue === 0 || doubleValue === '')
          doubleValue = 0;
        doubleValue = this.setNumberFormatter(doubleValue, formatString, styleType)
        return doubleValue;
      }
      let stringval;
      if (iscustomcolumn) {
        stringval = doubleValue.toString();
      }
      else {
        stringval = doubleValue === 0 || doubleValue === '' ? "" : doubleValue.toString();
      }
      if (stringval === "") return stringval;

      stringval = this.setNumberFormatter(doubleValue, formatString, styleType);
      return stringval;
    }
    return "";
  }

  findPriceChangePercetageColumnValue(recordDatas) {
    const cell = find(recordDatas, (key) => {
      if (key && !(isNull(key) || isUndefined(key))) {
        return key.dataItemId === pricePercentageChangeColumnId;
      }
    });

    if (cell) {
      return (cell.doubleValue >= 0) ? 'grid-visualize-rect-positive' : 'grid-visualize-rect-negative';
    }
    return 'grid-visualize-rect-negative';
  }

  updateDataSourceTextWidth() {
    const obj = this.getSourceData();
    each(obj, (item) => {
      each(item.recordDatas, (recordData, key) => {
        if (recordData && !(isNull(recordData) || isUndefined(recordData)) && this._state.columns[key] !== undefined) {
          if (this._state.columns[key].dataItemId === -3) // Flag
            recordData['textWidth'] = flagColumnWidth;
          else
            recordData['textWidth'] = this.getCellTextWidth(this._state.columns[key], recordData['displayValue'], recordData['snapshotDt']);
        }
      });
    });
  }

  updateDataSourceVisualization(dataItemId, isVisualizationOn) {
    const obj = this.getSourceData();
    map(obj, (item) => {
      map(item, (recordDatas, record) => {
        if (record === 'recordDatas') {
          map(recordDatas, (recordData) => {
            if (recordData && !(isNull(recordData) || isUndefined(recordData))) {
              if (recordData.dataItemId.toString() === dataItemId.toString()) {
                recordData['isVisualizationOn'] = isVisualizationOn;
              }
            }
          });
        }
      });
    });
  }

  getCellTextWidth(colData, displayValue, snapshotDt) {
    if (displayValue) {
      let width = cellWidth(displayValue.toString(), {
        family: 'calibri',
        size: ListStore.getTextFontSize()
      });
      if (colData) {
        if (snapshotDt && colData.dataItemId === -4000012) { // symbol
          const subscriptWidth = cellWidth(displayValue.toString(), {
            family: 'calibri',
            size: 10
          });
          width += subscriptWidth;
        }
        // AutosizeForSummaryStats check is pending
        if (parseInt(colData.textWidth) < width) {
          colData.textWidth = width;
        }
      }
      return width;
    }
    return 0;
  }

  /*eslint-disable */
  async sortColumnsOnOrderNum(obj) {
    /*eslint-enable */
    let colPosition = 0;
    if (!obj) obj = this.getSourceData();
    map(obj, (recordDatas) => {
      map(recordDatas, (recordData, key) => {
        if (key === 'recordDatas') {
          const tempRecordDatas = [];
          map(recordData, (item) => {
            if (item && item.dataItemId) {
              colPosition = this.findColumnOrderNum(item.dataItemId);
              tempRecordDatas[colPosition] = item;
            }
          });
          recordDatas[key] = tempRecordDatas;
        }
      });
    });
    return true;
  }

  reorderColumns(dataItemId, previousColumnDataItemId) {
    /*eslint-disable */
    return new Promise((resolve, reject) => {
      const draggedIndex = this.findColumnIndex(dataItemId);
      const dropIndex = this.findColumnIndex(previousColumnDataItemId) + 1;
      this._state.columns.move(draggedIndex, dropIndex);
      if (this.resetOrderNumAllColumns()) {
        this.sortColumnsOnOrderNum(this._source).then((orderCmplt1) => {
          if (orderCmplt1) resolve(true);
        });
      }
    });
    /*eslint-enable */
  }

  findColumnIndex(dataItemId) {
    return findIndex(this._state.columns, (key) => dataItemId.toString() === key.dataItemId.toString() );
  }

  findColumn(dataItemId, fromColumnInfos = false) {
    const obj = fromColumnInfos ? this._state.basic.columnInfos : this._state.columns;
    const column = find(obj, (key) => dataItemId.toString() === key.dataItemId.toString());

    if (!StringUtil.isEmpty(column)) {
      return column;
    }
    return -1;
  }

  resetOrderNumAllColumns() {
    let i = 0;
    map(this._state.columns, (key) => {
      key.id = i;
      key.orderNum = i + 1;
      i++;
    });
    return true;
  }

  findColumnOrderNum(dataItemId) {
    const column = find(this._state.columns, (key) => dataItemId.toString() === key.dataItemId.toString() );
    if (!StringUtil.isEmpty(column)) {
      return column.orderNum - 1;
    }
    return -1;
  }

  updateColumnWidth(colSeq) {
    /*eslint-disable */
    return new Promise((resolve, reject) => {
      const columnsArr = colSeq.split(';');
      each(columnsArr, (col) => {
        if (col) {
          const colData = col.split(',');
          find(this._state.columns, (column) => {
            if (colData[0] === column.dataItemId.toString()) {
              column.customWidth = parseInt(colData[1]);
              return true;
            }
            else {
              return false;
            }
          })
        }
      });
      this.updateDataSourceTextWidth();      
      resolve(true);
    });
    /*eslint-enable */
  }


  updateVisualizeColumn(colSeq) {
    return new Promise((resolve) => {
      const arr = colSeq.split(',');
      const dataItemId = clone(arr[0]);
      const colVisualization = clone(arr[1]);
      const column = find(_state.columns, (colItem) => dataItemId.toString() === colItem.dataItemId.toString());
      column.isVisualizationOn = colVisualization === "1" ? true : false;
      this.updateDataSourceVisualization(dataItemId, column.isVisualizationOn, true);
      this.updateDataSourceVisualization(dataItemId, column.isVisualizationOn);
      resolve(true);
    });
  }

  getCellValue(cellData, formatter, styleType) {
    let cellValue = '';
    const dataItemId = cellData.dataItemId;
    if (cellData.dateValue) {
      //Note: Bug - 1302 fixes - temp fix, Date Type formmater issue
      if (formatter.toUpperCase() === "#,#000" || formatter.toUpperCase() === "#,##0" || formatter.toUpperCase() === "#,##0.00" || formatter.toUpperCase() === "(#,##0)" || formatter.toUpperCase() === "#,##0.0%" || formatter.toUpperCase() === "#,##0.00") {
        formatter = "MM/dd/yyyy";
      }
      const formatedDate = StringUtil.formatDate(cellData.dateValue, formatter.toUpperCase());
      if (formatedDate !== LocalizationStore.getTranslatedData("cal_invaliddate", "Invalid date")) {
        cellValue = formatedDate;
        cellData.stringValue = formatedDate;
      }
    }
    else if (cellData.doubleValue) {
      if (dataItemId === -3) {
        cellValue = (cellData.doubleValue === 1 ? true : false); // Flag column returns double value 0 or 1 instead of returning boolvalue true or false in details.pb call. for ex, "mm"
        cellData.boolvalue = cellValue;
      }
      else cellValue = this.setNumberFormatter(cellData.doubleValue, formatter, styleType);
    }
    else if (cellData.longValue) {
      cellValue = cellData.longValue.low;
      if (dataItemId === -4) cellValue = cellData.rowId; // #
      else if (dataItemId === -4000013) cellValue = cellValue; // Type
    }
    else if (cellData.boolValue) {
      cellValue = cellData.boolValue;
    }
    else if (cellData.stringValue) {
      if (cellData.dataType === 1) { // bool
        cellValue = cellData.stringValue;
      }
      else if (cellData.dataType === 2) { // int
        cellValue = cellData.stringValue;
      }
      else if (cellData.dataType === 4) { // date
        //Note: Bug - 1302 fixes - temp fix, Date Type formmater issue
        if (formatter.toUpperCase() === "#,#000" || formatter.toUpperCase() === "#,##0" || formatter.toUpperCase() === "#,##0.00" || formatter.toUpperCase() === "(#,##0)" || formatter.toUpperCase() === "#,##0.0%" || formatter.toUpperCase() === "#,##0.00") {
          formatter = "MM/dd/yyyy";
        }
        const formatedDate = StringUtil.formatDate(Date.parse(cellData.stringValue), formatter.toUpperCase());
        if (formatedDate !== LocalizationStore.getTranslatedData("cal_invaliddate", "Invalid date")) {
          cellValue = formatedDate;
        }
      }
      else if (cellData.dataType === 5) { // double
        cellValue = this.setNumberFormatter(cellData.stringValue, formatter, styleType);
      }
      else cellValue = cellData.stringValue;
    }
    return cellValue;
  }

  setNumberFormatter(doubleValue, formatter, styleType) {
    let cellValue = '';
    const strFormatter = formatter.split(";");
    if (styleType === ListColumnStyleType.POSITIVENEGATIVEPARENTHESIS_ListColumnStyle) {
      formatter = Math.sign(doubleValue) >= 0 ? strFormatter[0] : strFormatter[1];
    }

    switch (formatter) {
      case '#,##0%':
        cellValue = `${Number(doubleValue).toFixed(0)  }%`;
        break;

      case '#,##0':
        cellValue = new Intl.NumberFormat().format(Number(doubleValue).toFixed(0));
        break;
      case '(#,##0)':
        cellValue = numberFormatter("(#,##)", Math.abs(doubleValue));
        break;

      case '#,##0.00':
      case '#,##0.0%':
        cellValue = numberFormatter(formatter, doubleValue);
        break;

      default:
        cellValue = numberFormatter(formatter, doubleValue);
    }
    return cellValue;
  }

  setListCatalogSearchKeyword(keyword) {
    this._state.searchKeyword = keyword.trim();
  }

  async getMoreDataOnScroll(listCataLog) {
    if (this.getSourceData().length >= this._state.basic.count) return;
    if (!isScollBarCallRunning) {
      isScollBarCallRunning = true;
      let pageBasicEsInfoData = clone(this._state.basicEsInfo);
      pageBasicEsInfoData = { ...pageBasicEsInfoData, sortCols: null, needColumnsInfo: 1, saveColFilters: 0, customerOrder: 0, addlCatalogRequests: null, cacheId: this._state.cacheId, pageIndex: parseInt(listCataLog.pageIndex) };
      try {
        const esBasicData = await ListApi.GetOwnerShipData(pageBasicEsInfoData);
        const ownershipRecordInfo = JSON.parse(esBasicData.catalog.ownershipRecordInfo);
        this._state.cacheId = this._state.basic.cacheId = esBasicData.catalog.cacheId;
        if (this.resetOrderNumAllColumns()) {
          this.sortColumnsOnOrderNum();
          /*eslint-disable */
          let t = await this.addPageData(ownershipRecordInfo, this.getSourceData().length, -1);
          const listState = ListStore.getSelectedListItem();
          window.setTimeout(() => {
            dataLoadingComplete(listState)
          }, 0);
          /*eslint-enable */
          isScollBarCallRunning = false;
          this.emit(CHANGE_EVENT);
        }
      }
      catch (e) {
        console.log(e);
      }
    }
  }

  startWorker(data) {

    if (typeof (Worker) !== "undefined") {
      if (typeof (w) !== "undefined") {
        w.terminate();
        w = undefined;
      }
      if (typeof (w) === "undefined") {
        w = new Worker("webWorkerBundle.js");
        w.postMessage(data);
      }
      w.onmessage = (event) => {
        _this._details = clone(event.data.rows.rows);
        this._state.details = event.data.rows.rows;
        w.terminate();
        w = undefined;
        if (this.resetOrderNumAllColumns()) {
          this.sortColumnsOnOrderNum();
          this.setSourceData();
          this.emit(CHANGE_EVENT);
        }
      };
    } else {
      console.log("Sorry! No Web Worker support.");
    }
  }

  getfullmsIds() {
    return this._state.dragNodeData;
  }

  getSymbolFromMsIds(msIds) {    
    try {
      const sourceData = this.getSourceData();

      // select all scenario
      if (this._state.basic.count <= GridStore.getSelectedIndexes().length && this._state.allListSymbolMsids.length > 0 && !Array.isArray(msIds)) {
        const msidSymbol = find(this._state.allListSymbolMsids, (item) => item.sponsorId == msIds); //indexBy(this._state.allListSymbolMsids, 'msId');
        if (msidSymbol) {
          return msidSymbol.symbol ? msidSymbol.symbol : msidSymbol.sponsorId ? msidSymbol.sponsorId : null;
        }
        else return null;
      }
      // When certain list items were selected using shift or ctrl selection in the current list view
      else if (sourceData && this._state.basic.count >= sourceData.length && !Array.isArray(msIds)) {
        const msidSymbol = sourceData.find((itm) => itm.sponsorId == msIds);
        if (msidSymbol) {
          return msidSymbol.symbol ? msidSymbol.symbol : msidSymbol.sponsorId ? msidSymbol.sponsorId : null;
        }
        else return null;
      }
      else if (Array.isArray(msIds)) {
        const symbols = [];
        if (msIds.length >= this._state.basic.count && this._state.allListSymbolMsids.length > 0) {
          each(sourceData, (row) => {
            if (contains(msIds, row.sponsorId.toString())) {
              if (isEmpty(row.symbol)) {
                symbols.push(row.sponsorId.toString());// if symbol does not exist
              }
              else {
                symbols.push(row.symbol);
              }
            }
          });
          return symbols.join(';');
        }
        else {
          each(sourceData, (row) => {
            if (contains(msIds, row.sponsorId.toString())) {
              if (isEmpty(row.symbol)) {
                symbols.push(row.sponsorId.toString());// if symbol does not exist
              }
              else {
                symbols.push(row.symbol);
              }
            }
          });
        }
        return symbols.join(';');
      }
      else {
        const row = find(sourceData, (item) => item.sponsorId.toString() === msIds.toString());
        if (row) {
          return row.symbol;
        }
        else {
          return null;
        }
      }
    }
    catch (e) {
      console.log('error', this._state.allListSymbolMsids, e)
    }
  }
  //Drag and Drop required instrumentIds
  getInstrumentIdFromMsIds(msIds) {
    const recordInfos = this.getSourceData();
    if (Array.isArray(msIds)) {
      const instrumentIds = [];
      each(recordInfos, (row) => {
        if (contains(msIds, row.msId.toString())) {
          instrumentIds.push(row.instrumentId.toString());
        }
      });
      return instrumentIds;
    }
    else {
      const row = find(recordInfos, (item) => item.msId.toString() === msIds.toString() );
      return row.instrumentId;
    }
  }

  getCurrentListItemList() {
    return this.getSourceData();
  }

  formatSnapshotDt(snapshotDt) {
    if (snapshotDt) return StringUtil.formatDate(snapshotDt, 'M/D/YYYY');
    else return null;
  }

  getSnapshotDateFromMsIds(msIds) {
    const sourceData = this.getSourceData();
    if (sourceData && this._state.allListSymbolMsids.length > 0 && this._state.basic.count >= sourceData.length && !Array.isArray(msIds)) {
      const msidSymbol = find(this._state.allListSymbolMsids, (item) => item.msId === msIds); //indexBy(this._state.allListSymbolMsids, 'msId');
      return msidSymbol ? msidSymbol.snapshotDt : null;
    }
    else if (Array.isArray(msIds)) {
      const snapshotDt = [];
      if (msIds.length >= this._state.basic.count && this._state.allListSymbolMsids.length > 0) {
        each(this._state.allListSymbolMsids, (row) => {
          if (contains(msIds, row.sponsorId.toString())) {
            snapshotDt.push(row.snapshotDt);
          }
        });
        return snapshotDt.join(';');
      }
      else {
        each(sourceData, (row) => {
          if (contains(msIds, row.sponsorId.toString())) {
            snapshotDt.push(row.snapshotDt);
          }
        });
        return snapshotDt.join(';');
      }
    }
    else {
      const row = find(sourceData, (item) => item.msId.toString() === msIds.toString());
      return row && (row.snapshotDt) ? row.snapshotDt : '';
    }
  }
/*eslint-disable */
  async getMsIds(selectedIndexes, column = 'sponsorId') {
    /*eslint-enable */
    try {
      const sourceData = this.getSourceData();
      const msIds = [];
      if (this._state.basic.count < GridStore.getSelectedIndexes().length) {
        let msIds = this._state.allMsidList;
        return msIds.join(';');
      }
      else {
        map(selectedIndexes, (row) => {
          if (sourceData[row]) msIds.push(sourceData[row][column]);
        });
        // if (column == 'sponsorId')
        //   this._state.allListSymbolMsids = msIds;
        return msIds.join(';');
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  setVisibleRows(msIds, isRowVisible = true) {
    msIds = msIds.split(';');
    map(this._source, (row) => {
      if (contains(msIds, row.sponsorId.toString())) {
        row.isRowVisible = isRowVisible;
      }
    });

    if (isRowVisible) {
      this._state.basic.total = this._state.basic.total + msIds.length;
      this._state.basic.count = this._state.basic.count + msIds.length;
    }
    else {
      this._state.basic.total = this._state.basic.total - msIds.length;
      this._state.basic.count = this._state.basic.count - msIds.length;

    }
  }

  getListColumnPickList(data) {
    const consoleId = 0;
    const listId = this._state.actualListId;
    const cacheId = this._state.cacheId;
    ListApi.getListColumnPickList(consoleId, listId, cacheId, data.columnId, parseInt(this.activeNode.categoryType)).then((result) => {
      if (!result.responseHeader.error) {
        this._state.pickListData = result.pickList;
        this._currentAction = OwnershipViewConstants.ActionTypes.GET_COLUMN_PICKLIST_OWNERSHIP;
        this.emit(CHANGE_EVENT);
      }
    });
  }

  updateAllFlagIn(isDelete) {
    const sourceData = this.getSourceData();
    this.updateAllFlagInSourceData(sourceData, isDelete);
  }

  updateAllFlagInSourceData(obj, isDelete) {
    map(obj, (rows) => {
      map(rows, (recordDatas, key) => {
        if (key === 'recordDatas') {
          map(recordDatas, (item) => {
            if (item && item.dataItemId === -3) { //Flag
              if (isDelete) {
                item.className = 'icn-flag';
                item.displayValue = false;
              }
              else {
                item.className = 'icn-flag active';
                item.displayValue = true;
              }
            }
          });
        }
      });
    });
  }

  updateFlagIn(isDelete) {
    const sourceData = this.getSourceData();
    const selectedIndexes = GridStore.getSelectedIndexes();
    let i = 0;
    const length = sourceData.length < selectedIndexes.length ? sourceData.length : selectedIndexes.length;
    while (i < length) {
      let row = sourceData[selectedIndexes[i]];
      this.updateFlagInSourceData(row['recordDatas'], isDelete);
      i++;
    }
  }

  updateFlagAll(isDelete){
    const sourceData = this.getSourceData();
    sourceData.forEach((rowItem) => {
      rowItem.isFlagged = isDelete;
      this.updateFlagInSourceData(rowItem['recordDatas'], isDelete);
    });
  }

  updateFlagInFromSymbol(msid, isDelete) {
    const sourceData = this.getSourceData();
    const row = find(sourceData, (item) => item.msId == msid);
    if (row){
      row.isFlagged = isDelete;
      this.updateFlagInSourceData(row['recordDatas'], isDelete);
    }
  }

  updateFlagInSourceData(obj, isDelete) {
    const row = find(obj, (item) => item.dataItemId === -3); //Flag
    if (isDelete) {
      row.displayValue = false;
      row.className = 'icn-flag';
    }
    else {
      row.displayValue = true;
      row.className = 'icn-flag active';
    }
  }

  reOrderColumnPos() {
    let colPosition = 0;
    const tempRecordDatas = [];
    each(this._singleRowData.recordDatas, (recordData) => {
      colPosition = this.findColumnOrderNum(recordData.dataItemId);
      tempRecordDatas[colPosition] = recordData;
    });
    this._singleRowData.recordDatas = tempRecordDatas;
  }

  async updateDataSourceAfterAdd(result) {
    const responseStringData = JSON.parse(result.responseData.responseString);
    if (responseStringData.length <= 0) {
      return 'exception';
    }
    try {
      if (this.resetOrderNumAllColumns()) {
        this.sortColumnsOnOrderNum();
        this.reOrderColumnPos();
      }

      this.clearSortColumns();
      /*eslint-disable */
      const t = await this.addPageData(responseStringData, this.getSourceData().length, -1);
      /*eslint-enable */
      this._state.basic.total = this._state.basic.total + 1;
      this._state.basic.count = this._state.basic.count + 1;
    }
    catch (e) {
      console.log(e);
      return false;
    }
    return true;
  }

  filterCall() {
    //const isColumnFilterEnabled = this.getScreenOnOffValue();
    // let listBrowser = clone(this._state.basicEsInfo);
    // listBrowser = { ...listBrowser, cacheId: this._state.cacheId, useColFilter: isColumnFilterEnabled, isBrowserCount: true };
  }

  setDragedObjectDataSource(dragItems) {    
    const sourceData = this.getSourceData();
    const sourceDataClone = clone(sourceData);
    this._tempDragDataSource = [];
    each(sourceDataClone, (sourceItem) => {
      each(dragItems, (item) => {
        if (sourceItem.sponsorId === item.sponsorId) {
          this._tempDragDataSource.push(sourceItem);
        }
      })
    });
  }

  setMoredataLoaded() {    
    setTimeout(() => {
      const destDragedInfo = GridStore.getDragedDestInfo();
      if (!(isEmpty(destDragedInfo)) && this._currentAction === OwnershipViewConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL_OWNERSHIP) {
        BrowserUtil.disableEvents();
        setTimeout(() => {
          GridActions.dropInListManager(destDragedInfo.dragedInfo, destDragedInfo.destInfo);
        }, 2000);
      }
    }, 0);
  }

  updateDataSourceAfterDrop(currentListItem) {        
    const currentListItems = uniq(currentListItem, (item) => item.sponsorId);
    const items = map(currentListItems, (item, i) => {
           const items = { sponsorId: item.sponsorId, posIndex: i }
           return items;
    });
    const itemsClone = Object.assign({}, items);
    this.clearSortColumns();
    this._source = this.updateDataSourceByReorder(itemsClone, this._source);
  }

  updateDataSourceByReorder(Items, sourceData) {
    const sourceDataClone = clone(sourceData);
    const reorderDataSource = [];
    each(sourceDataClone, (sourceItem) => {
      each(Items, (item) => {
        if (sourceItem.sponsorId === item.sponsorId) {
          reorderDataSource[item.posIndex] = sourceItem
          each(this._tempDragDataSource, (tmpDragItem) => {
            if (tmpDragItem.sponsorId === item.sponsorId) {
              reorderDataSource[item.posIndex] = tmpDragItem;
            }
          });
        }
      });
    });
    return reorderDataSource;
  }

  /*eslint-disable */
  async reorderOrdinalNumbers(activeListId, range, destIndex, msid = null) {
    /*eslint-enable */
    if (this.isMyOwnerList()) {
      let stringmsids = null;
      if (msid === null) {
        const isDataItemIdRowid = find(this._sortCols, (sortItem) => sortItem.dataItemId === DataItemIdRowid);
        if (isDataItemIdRowid || this._sortCols.length === 0) {
          const msidList = this.getSourceData().map((c) => c.sponsorId);
          stringmsids = msidList.join(";");
        }
      }
      else {
        stringmsids = msid;
      }
      // const consoleId = 0; //States.consoleId
      // const success = false;
      this._state.basicEsInfo.msidSortList = stringmsids !== null ? stringmsids : "";
      // let basicEsInfoCopy = clone(this._state.basicEsInfo);
      // basicEsInfoCopy = { ...basicEsInfoCopy, cacheId: this._state.basicEsInfo.cacheId, actualListId: this._state.basicEsInfo.actualListId };      
      return true;
    }
  }

  toggleFrozenColumns(isSplit) {
    this._frozenColumnsSpliter = isSplit;
    map(this._state.columns, (column) => {
      if (column.isFrozen && column.dataItemId !== -4 && column.dataItemId !== -3 && column.dataItemId !== -4000012) { // #, Flag, Symbol
        column.isCollapsed = this._frozenColumnsSpliter;
      }
    });
  }

  toggleScreenOnOff(isOnOrOff) {
    const NavListManagerSettings = ListStore.getNavListManagerSettings();
    const screenOnOff = isOnOrOff ? ListManager.ScreenOnOff : "Off";    
    ListSettingHelper.updateScreenOnOff(NavListManagerSettings.TabOwnershipSettings, screenOnOff);   
  }

  getScreenOnOffValue() {
    try {
      let screenOnOff = null;
      const NavListManagerSettings = ListStore.getNavListManagerSettings();
      screenOnOff = NavListManagerSettings.TabOwnershipSettings.ScreenOnOff;
      if (screenOnOff !== ListManager.ScreenOnOff) return false;
      else return true;
    }
    catch (e) {
      console.error('error', e);
      return false;
    }
  }

  setColumnFilterData(data) {
    this._currentAction = OwnershipViewConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG_OWNERSHIP;
    this._state = { ...this._state, basic: data.catalog, cacheId: this._state.basic.cacheId, columnSetId: this._state.basic.columnSet.nodeId.low, columns: this.removeInvisibleColumns(clone(data.catalog.columnInfos)) }
    this._columnInfo = this._state.columns;
    this._columnFilters = data.catalog.columnFilters;
    return;
  }

  getColumnInfo() {
    return this._columnInfo;
  }

  storePrevColumnFilterAndSortsColumnsForUndo(data) {
    //for-filters
    if (this._columnFilters !== null && this._columnFilters !== undefined){
      let res=this._columnFilters?.filter((item)=>item.dataItemId==data?.dataItemId)
      if(res && res.length){
        this._prevColumnFilters = clone(res);
        this._columnFilters=this._columnFilters?.filter((item)=>item.dataItemId!==data?.dataItemId)
      }
      else
      this._prevColumnFilters=[]
    }
    // for-sorting
    if (this._sortCols !== null && this._sortCols !== undefined)
      this._prevSortCols = clone(this._sortCols);

  }

  getcolumnFilters() {
    return this._columnFilters;
  }

  /*eslint-disable */
  async processColumns() {
    /*eslint-enable */
    map(this._state.columns, (item, i) => {
      item.isCollapsed = false;
      item.isSortingEnable = true;
      item.isContextMenuEnable = true;
      if (item.dataItemId.toString() === DataItemIdFlag.toString()) {
        item.displayName = '';
        item.className = 'icn-flag-head';
      }
      else item.className = '';
      //Fixes 75301 - Flag Sorting
      if (this.isOwnershipDb() && item.dataItemId.toString() === DataItemIdFlag.toString()) {
        item.isSortingEnable = false;
        item.isContextMenuEnable = false;
      }
      item.id = i;
      item.textWidth = 0;
    });
    this.toggleFrozenColumns(this._frozenColumnsSpliter);
  }

  isOwnershipDb() {
    return this._state.listId === -2;
  }

  //---- Remove rows Operation for OwnershipListView ----
  removeGridRows(data) {

    const selectedItem = ListStore.getSelectedListItem();
    let removedRows = {
      _alertMessage: null,
      _removedRow: {}
    }
    //const sIndexs = GridStore.getSelectedIndexes();
    const resMsIDSs = data.msIDS.replace(/;/g, ',');
    this.disableDelete = true;
    try {
      return ListApi.deleteOwnerInstrumentsRequest(data.listId, "", resMsIDSs).then((result) => {
        if (!result.responseHeader.error) {
          this.disableDelete = false;
          GridStore.resetSelectedIndexes();
          const msIds = data.msIDS.split(';');
          let _alertMessage = null;
          //Updating update date field in List property section in List explorer
          findNodeByIdAndUpdateDate(data.listId, result.responseHeader.asOfDate);

          if (msIds.length > 1) {
            _alertMessage = LocalizationStore.getTranslatedData('lp_mes_1', '{0} items were removed from {1}.').replace('{0}', msIds.length).replace('{1}', selectedItem.SelectedListName);
          }
          else {
            _alertMessage = LocalizationStore.getTranslatedData('lp_mes_20', '{0} was removed from {1}.').replace('{0}', this.getSymbolFromMsIds(msIds)).replace('{1}', selectedItem.SelectedListName);
          }

          let _removedRow = clone(removedRows._removedRow);
          _removedRow = {
            ..._removedRow, /*selectedIndexes: sIndexs,*/ listId: data.listId, cacheId: data.cacheId, consoleId: 0, symbols: this.getSymbolFromMsIds(msIds), msids: data.msIDS, snapshotdates: data.snapshotDates, insertSeqNum: msIds.length,
            includeDetails: false, undoAdd: true, selectedIndexes: clone(GridStore.getSelectedIndexes())
          }

          const obj = {};
          obj.msIds = data.msIDS.toString();
          if (_removedRow.symbols !== undefined)
            obj.symbols = _removedRow.symbols.toString();
          obj.snapshotDts = _removedRow.snapshotdates.toString();

          const InfoProto = BaseServiceApi.rayData["ListInstrumentInfoData"];
          const listInstrumentInfoData = Object.assign(new InfoProto(), { ...listInstrumentInfoData, symbols: obj.symbols, msIds: obj.msIds, snapshotDts: obj.snapshotDts });

          const ReqProto = BaseServiceApi.rayData["ListInstrumentsRequestData"];
          const listInstrumentsRequestData = Object.assign(new ReqProto(), { ...listInstrumentsRequestData, instrumentsInfo: listInstrumentInfoData });          
          _removedRow = { ..._removedRow, bulkInsertData: listInstrumentsRequestData.encode() };
          removedRows = { ...removedRows, _alertMessage: _alertMessage, _removedRow: _removedRow };

          // setActiveNodeFromOwnership(true);
          this.setVisibleRows(data.msIDS, false);
          if(msIds.length > 0 && msIds.length < this._state.allMsidList.length){
            msIds.forEach((msid) => {
              let msidIndex = this._state.allMsidList.indexOf(msid);
              this._state.allMsidList.removeAt(msidIndex);
            });
          }
          else{
            this._state.allMsidList = [];
          }
          return Promise.all([this.filterCall()]).then(() => removedRows);
        }
        else {
          this.disableDelete = false;
          //Exception in Server
          return result.responseHeader;
        }
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  //---- Undo Operation for OwnershipListView Remove Grid Rows  ----

  removeGridRowsUndo(removedRow) {
    removedRow.symbols = "";
    const resMsIDSs = removedRow.msids.replace(/;/g, ',');
    return ListApi.AddOwnerInstrumentsRequest(removedRow.listId, removedRow.symbols, resMsIDSs).then((result) => {
      if (!result.responseHeader.error) {
        const msIds = [];
        //Updating update date field in List property section in List explorer
        findNodeByIdAndUpdateDate(removedRow.listId, result.responseHeader.asOfDate);

        if(!this._state.allMsidList){
          this._state.allMsidList = [];
        }
        map(result.responseData.symbolListData.statusData, (row) => {
          msIds.push(StringUtil.convertFromLongValueToInt(row.msid));
          this._state.allMsidList.push(row.msid.toString());
        });
        this.setVisibleRows(msIds.join(';'), true);
        GridStore.setSelectedIndexes(removedRow.selectedIndexes);
        return;
      }
      else {
        return;
      }
    });
  }


  //---- addSymbol Operation for OwnershipListView ----

  /*eslint-disable */
  async addSymbol(symbols, listId, cacheId, listCount, listName, /*snapShot = ""*/) {
    /*eslint-enable */
    const resultObj = {
      navMessage: null,
      error: false,
      result: null
    };
    let spIds = "";
    const conSymbols = symbols.replace(/["']/g, "");
    if (!isNaN(conSymbols)) {
      spIds = symbols;
      symbols = "";
    }
    else {
      if (symbols.indexOf(',') > -1) {
        const arSymbols = symbols.split(',');
        const i = 0;
        if (!isNaN(arSymbols[i])) {
          spIds = symbols;
          symbols = "";
        }
      }
    }

    try {
      return ListApi.AddOwnerInstrumentsRequest(listId, symbols, spIds).then((result) => {
        if (!result.responseHeader.error) {
          const addSymbolListData = result.responseData.symbolListData;
          const addSymbolsObj = this.processAddServiceResult(addSymbolListData, listId, listName);
          resultObj.navMessage = addSymbolsObj;

          //Updating update date field in List property section in List explorer
          findNodeByIdAndUpdateDate(listId, result.responseHeader.asOfDate);

          if (addSymbolsObj.addSymbols && addSymbolsObj.isUndoable) {
            if (addSymbolsObj.isUndoable) {
              const addSymbols = addSymbolsObj.addSymbols;
              if (!StringUtil.isEmpty(addSymbols) && addSymbols.length > 0) {
                const undoMsIds = map(addSymbols, (row) => row.msId);
                let stringMsids = "";
                if (undoMsIds !== null && undoMsIds.length > 0) {
                  if (undoMsIds[undoMsIds.length - 1] === 0)
                    undoMsIds.removeAt(undoMsIds.length - 1);
                  stringMsids = undoMsIds.join(";");
                }
                this._state.undoOperationForPaste = {
                  ...this._state.undoOperationForPaste,
                  listId: listId, cacheId: cacheId, msids: stringMsids.toString()
                }
                this.updateDataSourceAfterAdd(result).then((result) => {
                  if (result) {
                    resultObj.loadDataIfNORowReturn = false;
                    return Promise.all([this.filterCall()]).then(() => resultObj);
                  }
                  else {
                    resultObj.loadDataIfNORowReturn = true;
                  }
                }).catch((err) => {
                  resultObj.loadDataIfNORowReturn = true;
                  console.log(err);
                })

              }
            }
          }

          resultObj.error = false;
        }
        else {
          //Exception in Server
          resultObj.error = true;
        }
        return resultObj;
      });
    }
    catch (e) {
      console.log(e);
    }
  }


  //---- add symbols to list ----
  addSymbolsToList(data) {
    try {
      let outObj = {};
            if (this.sourceType === 'Grid') {
              const dragInfo = GridStore.getDragInfo();
              if (!StringUtil.isEmpty(dragInfo) && dragInfo.itemInfo.length > 0) {
                let items = dragInfo.itemInfo
                if (data?.maxCopySymbols && items.length > data.maxCopySymbols) {
                  items = [...items.slice(0, data.maxCopySymbols)]
                }
                outObj = {
                  msidAsString: items.map((e) => { 
                    if (!StringUtil.isEmpty(e.sponsorId)) 
                    return e.sponsorId; 
                  return null;
                }).join(','),
                  snapshotAsString: items.map((e) => { 
                    if (!StringUtil.isEmpty(e.snapshotDt)) 
                    return e.snapshotDt; 
                  return null;
                }).join(','),
                  symbolsAsString: items.map((e) => { 
                    if (!StringUtil.isEmpty(e.symbol)) 
                    return e.symbol; 
                  return null;
                }).join(','),
                  sequenceNumber: dragInfo.sequenceNumber
                };
              }
              else {
                outObj = {
                  msidAsString: '',
                  snapshotAsString: '',
                  symbolsAsString: '',
                  sequenceNumber: 0
                };
              }
            }
            else {
              outObj = {
                msidAsString: !StringUtil.isEmpty(data.msids) ? data.msids.join(',') : '',
                snapshotAsString: '',
                symbolsAsString: '',
                sequenceNumber: 0
              };
            }

            const outObjBlkData = { bulkInsertData: null };
            GridStore.getAddInstrumentsBulkRequest(outObj.msidAsString, outObj.snapshotAsString, outObj.symbolsAsString, outObjBlkData);

            ListApi.AddOwnerInstrumentsRequest(data.targetListId, '', outObj.msidAsString).then((result) => {
              if (!result.responseHeader.error) {
                this._state.removeItemsFromUserList = {
                  ...this._state.removeItemsFromUserList,
                  targetListId: null, msids: null, currentNodeId: null
                }
                //Updating update date field in List property section in List explorer
                findNodeByIdAndUpdateDate(data.targetListId, result.responseHeader.asOfDate);

                const addSymbolListData = result.responseData.symbolListData;
                let addSymbolsobj = null;
                if ((!StringUtil.isEmpty(addSymbolListData) && addSymbolListData.statusData.length == 0) || addSymbolListData == null){
                  this._state.alertMessage = `0 items has been added to ${data.targetNodeName}`;
                }
                else {
                  addSymbolsobj = this.processAddServiceResult(addSymbolListData, data.targetListId, data.targetNodeName);
                }

                if (addSymbolsobj && addSymbolsobj.isUndoable) {
                  const addSymbols = addSymbolsobj.addSymbols;
                  this._state.removeItemsFromUserList = {
                    ...this._state.removeItemsFromUserList,
                    targetListId: data.targetListId, cacheId: 0, currentNodeId: data.currentNodeId
                  }
                  if (!StringUtil.isEmpty(addSymbols) && addSymbols.length > 0) {
                    this._state.removeItemsFromUserList.msids = addSymbols.map((e) => { 
                      if (!StringUtil.isEmpty(e.msId)) 
                      return e.msId; 
                    return null;
                  }).join(';');
                  }
                }

                if (addSymbolsobj && addSymbolsobj.showMessage) {
                  if (!StringUtil.isEmpty(addSymbolsobj.displayMessage))
                    this._state.alertMessage = addSymbolsobj.displayMessage;
                  else if (!StringUtil.isEmpty(addSymbolsobj.rejectionMessage))
                    this._state.alertMessage = addSymbolsobj.rejectionMessage;
                }
                if (data.targetListId === data.currentNodeId && this.sourceType !== 'Grid')
                  setActiveNodeFromOwnership(true);
                this.emit(CHANGE_EVENT);
                return;
              }
              else {
                return;
              }
            });
    } catch (error) {
      console.log('Error while adding symbols to List.')
    }
  }

  getallMsidList() {
    return this._state.allListSymbolMsids;
  }

  //addSymbolList for paste Operation
  addSymbolsToListForPaste(listId, listName, cacheIdForPaste, outObj, outObjBlkData, symbols, msIds, snapShots, sequenceNumber, /*showMessage = true,*/ doRefreshList = true, /*includeDetails = false*/) {

    const resultObj = {
      refreshGrid: false,
      navMessage: null,
      error: false
    };
    let resSymbols = outObj.symbolsAsString.replace(/;/g, ',');
    let resMsIds = outObj.msidString.replace(/;/g, ',');


    const conresMsIds = resMsIds.replace(/["']/g, "");
    const arconresMsIds = conresMsIds.split(',');
    if (isNaN(arconresMsIds[0])) {
      resSymbols = conresMsIds;
      resMsIds = "";
    }

    try {
      return ListApi.AddOwnerInstrumentsRequest(listId, resSymbols, resMsIds).then((result) => {
        if (!result.responseHeader.error) {
          const addSymbolListData = result.responseData.symbolListData;
          const addSymbolsObj = this.processAddServiceResult(addSymbolListData, listId, listName);
          resultObj.navMessage = addSymbolsObj;

          //Updating update date field in List property section in List explorer
          findNodeByIdAndUpdateDate(listId, result.responseHeader.asOfDate);


          if (this._state.listId === listId && doRefreshList && !StringUtil.isEmpty(addSymbolsObj.addSymbols)) {
            setActiveNodeFromOwnership(true);
          }
          if (addSymbolsObj.addSymbols && addSymbolsObj.isUndoable) {
            if (addSymbolsObj.isUndoable) {
              const addSymbols = addSymbolsObj.addSymbols;
              if (!StringUtil.isEmpty(addSymbols) && addSymbols.length > 0) {
                const undoMsIds = map(addSymbols, (row) => row.msId);
                let stringMsids = "";
                if (undoMsIds !== null && undoMsIds.length > 0) {
                  if (undoMsIds[undoMsIds.length - 1] === 0)
                    undoMsIds.removeAt(undoMsIds.length - 1);
                  stringMsids = undoMsIds.join(";");
                }
                this._state.undoOperationForPaste = {
                  ...this._state.undoOperationForPaste,
                  listId: listId, cacheId: cacheIdForPaste, msids: stringMsids.toString()
                }
              }
            }
          }
          resultObj.error = false;
        }
        else {
          //Exception in Server
          resultObj.error = true;
        }
        return resultObj;
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  // Undo operation for paste
  pasteGridRowsUndo() {
    if (this._state.undoOperationForPaste.msids) {
      const resMsIDSs = this._state.undoOperationForPaste.msids.replace(/;/g, ',');
      try {
        return ListApi.deleteOwnerInstrumentsRequest(this._state.undoOperationForPaste.listId, '', resMsIDSs).then((result) => {
          if (!result.responseHeader.error) {
            const undoPasteOperation = true;
            //Updating update date field in List property section in List explorer
            findNodeByIdAndUpdateDate(this._state.undoOperationForPaste.listId, result.responseHeader.asOfDate);
            return undoPasteOperation;
          }
          else {
            return;
          }
        });
      }
      catch (e) {
        console.log(e)
      }
    }

  }

  // Process data after addSymbolsToList/addSymbol/CopyList/MoveList
  processAddServiceResult(addSymbolListData, listId, listName) {
    this._exceptionSymbols = null;
    let successCount = 0, duplicateCount = 0, invalidCount = 0, invalidSnapShotDateCount = 0, notallowedCount = 0;
    let exceptionItems = [], addSymbols = [], refreshGrid = false;
    let successMsg = "", rejectMessage = "" ;
    const addSymbolStatusType = BaseServiceApi.rayData["AddSymbolStatusType"];
    if (addSymbolListData === null) return;

    const listSymbolData = addSymbolListData.statusData;
    if (listSymbolData === null || listSymbolData.length === 0) return;
    const totalCount = listSymbolData.length;

    each(listSymbolData, (addSymbolStatusData) => {
      switch (addSymbolStatusData.status) {
        case addSymbolStatusType.Duplicate:
          // exceptionItems.push(addSymbolStatusData.symbol + ((addSymbolStatusData.snapshotDt) ? `${  addSymbolStatusData.snapshotDt.toString()}` : ""));
          duplicateCount++;
          break;
        case addSymbolStatusType.Successful:
          addSymbols.push({ msId: addSymbolStatusData.msid.toString(), symbol: addSymbolStatusData.symbol, snapshotDate: ((addSymbolStatusData.snapshotDt) ? `${  addSymbolStatusData.snapshotDt.toString()}` : null) });
          successCount++;
          break;
        case addSymbolStatusType.Invalid:
          exceptionItems.push(addSymbolStatusData.symbol + ((addSymbolStatusData.snapshotDt) ? `${  addSymbolStatusData.snapshotDt.toString()}` : ""));
          invalidCount++;
          break;
        case addSymbolStatusType.InvalidSnapshotDate:
          exceptionItems.push(addSymbolStatusData.symbol + ((addSymbolStatusData.snapshotDt) ? `${  addSymbolStatusData.snapshotDt.toString()}` : ""));
          invalidSnapShotDateCount++;
          break;

        case addSymbolStatusType.NotAllowed:
          exceptionItems.push(addSymbolStatusData.symbol + ((addSymbolStatusData.snapshotDt) ? `${  addSymbolStatusData.snapshotDt.toString()}` : ""));
          notallowedCount++;
          break;
          default: break;
      }
    });

    if (successCount > 0) refreshGrid = true;
    if (successCount > 0 && this.activeNode === listId) setActiveNodeFromOwnership(true);

    //When one or all the symbols are duplicate
    if ((totalCount === 1 && duplicateCount === 1) || (totalCount === duplicateCount)) {
      if (duplicateCount > 1) {
        rejectMessage = LocalizationStore.getTranslatedData('lp_mes_11', 'Symbols already exist in list. Not added.');
      } else {
        rejectMessage = LocalizationStore.getTranslatedData('lp_mes_12', 'Symbol already exists in list. Not added.');
      }
    }

    if(successCount == 0 && duplicateCount > 0 && invalidCount > 0) {
      rejectMessage = LocalizationStore.getTranslatedData('lp_mes_24', `{0} ${duplicateCount > 1 ? 'items' : 'item'} already ${duplicateCount > 1 ? 'exist' : 'exists'} in list.`, duplicateCount) + LocalizationStore.getTranslatedData("LM_AddItemsToList_2", `&nbsp;<a id=\"notAddedSymbol\">{1} ${invalidCount > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', invalidCount);
    }

    //Partial success
    if (successCount > 0 && (duplicateCount > 0 || invalidCount > 0 || notallowedCount > 0)) {
      rejectMessage = '';//LM_AddItemsToList_1
      successMsg = LocalizationStore.getTranslatedData("LM_AddItemsToList_1", `{0} ${successCount > 1 ? 'items' : 'item'} added.`, successCount);
      if (duplicateCount > 0) {
        successMsg += LocalizationStore.getTranslatedData('lp_mes_24', `{0} ${duplicateCount > 1 ? 'items' : 'item'} already ${duplicateCount > 1 ? 'exist' : 'exists'} in list.`, duplicateCount)
      }
      if (invalidCount > 0 || notallowedCount > 0)
      successMsg += LocalizationStore.getTranslatedData("LM_AddItemsToList_2", `&nbsp;<a id=\"notAddedSymbol\">{1} ${(invalidCount + notallowedCount) > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', (invalidCount + notallowedCount));
    }

    //Success
    if (successCount > 0 && duplicateCount === 0 && invalidCount === 0 && notallowedCount === 0) {
      if (successCount === 1) {
        successMsg = `${addSymbols[0].symbol.trim() + ((addSymbols[0].snapshotDt) ? " (" + addSymbols[0].snapshotDt + ")" : "")} ${LocalizationStore.getTranslatedData('ch_wat', 'was added to')} ${LocalizationStore.getTranslatedData("TreeExplorer_LST_" + listName.replace(/ /ig, ""), listName)}`;
      }
      else
        successMsg = LocalizationStore.getTranslatedData('lp_mes_8', '{0} items have been added to {1}').replace('{0}', successCount).replace('{1}', LocalizationStore.getTranslatedData("TreeExplorer_LST_" + listName.replace(/ /ig, ""), listName));
    }

    //Invalid symbols
    if (successCount === 0 && invalidCount === totalCount) {
      if (invalidCount > 1) {
        rejectMessage = LocalizationStore.getTranslatedData('LM_AddItemsToList_1', `{0} item added.`, successCount) + LocalizationStore.getTranslatedData('LM_AddItemsToList_2', `&nbsp;<a id=\"notAddedSymbol\">{1} ${invalidCount > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', invalidCount);
      } else {
        rejectMessage = LocalizationStore.getTranslatedData('lp_mes_7', 'Symbol is invalid. Not added.');
      }
    }

    let totalInvalidCount = invalidCount + invalidSnapShotDateCount;
    //All or some invalid date
    if (successCount === 0 && totalInvalidCount > 1 && duplicateCount === 0) {
      rejectMessage = LocalizationStore.getTranslatedData('LM_AddItemsToList_1', `{0} item added.`, successCount) + LocalizationStore.getTranslatedData("LM_AddItemsToList_2", `&nbsp;<a id=\"notAddedSymbol\">{1} ${totalInvalidCount > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', totalInvalidCount);
    }
    else if (successCount === 0 && totalInvalidCount > 1 && duplicateCount > 0) {
      rejectMessage = LocalizationStore.getTranslatedData('lp_mes_24', `{0} ${duplicateCount > 1 ? 'items' : 'item'} already ${duplicateCount > 1 ? 'exist' : 'exists'} in list.`, duplicateCount) + LocalizationStore.getTranslatedData("LM_AddItemsToList_2", `&nbsp;<a id=\"notAddedSymbol\">{1} ${totalInvalidCount > 1 ? 'items' : 'item'}</a>&nbsp;not added.`).replace('{1}', totalInvalidCount);
    }
    //All are "not allowed"
    if (successCount === 0 && notallowedCount > 0 && notallowedCount === totalCount) {
      if (notallowedCount === 1) {
        rejectMessage = LocalizationStore.getTranslatedData('lp_mes_3', 'Symbol is not allowed. Not added.');
      } else {
        rejectMessage = LocalizationStore.getTranslatedData('lp_mes_2', 'Symbols are not allowed. Not added.');
      }
    }

    const navMessage = {
      rejectionMessage: rejectMessage,
      displayMessage: successMsg,
      isException: (invalidCount > 0 || notallowedCount > 0 || invalidSnapShotDateCount > 0),
      showMessage: true,
      isUndoable: successCount > 0,
      addSymbols: addSymbols,
      exceptionItems: exceptionItems,
      refreshGrid: refreshGrid
    };

    if (navMessage.isException) {
      this._exceptionSymbols = exceptionItems;
    }

    return navMessage;
  }

  isBenchmarkTabVisible() {
    const CurrentActiveListTreeItem = this.activeNode;
    return CurrentActiveListTreeItem && CurrentActiveListTreeItem.listCategory ===
      ListCategoryType.PORTFOLIOLIST_ListCategory && UserInfoUtil.IsUserEntitled(this.EntitlementType.PORTFOLIO_BENCHMARK_Entitlement)
  }

  getSaveScreenedResultsVisibilty() {
    let isSaveScreenedResultsVisible = false;
    let screenSummaryDetails = [];
    screenSummaryDetails = ColumnCustomFilterControl.getScreenSummary();
    const screenOnOff = this.getScreenOnOffValue();
    isSaveScreenedResultsVisible = (screenSummaryDetails.length !== 0 && screenOnOff);

    return (isSaveScreenedResultsVisible || !StringUtil.isEmpty(this._state.searchKeyword))
  }

  getBasicEsInfo() {
    return this._state.basicEsInfo;
  }

  getEsBasicCacheId() {
    return this._state.esBasicCatalogData.cacheId;
  }

  async getFullListMsids(nodeId) {
    const listCatalog = {
      listId: nodeId,
      actualListId: nodeId,
      searchText: '',
      sortCols: '',
      needColumnsInfo: 1,
      addlCatalogRequests: null,
      useColFilters: 1,
      saveColFilters: 0,
      customerOrder: 0,
      colsetId: '-1',
      pageIndex: 0,
      size: 10000,
      isQuoteboard: 0,
      msId: null,
      cacheId: -1,
      reorderIds: 0,
      isUserList: 1,
      isMinilist: 0

    }
    return new Promise((resolve) => {
    ListApi.GetOwnerShipData(listCatalog).then((OwnershipBasicResult) => {
      const catalogData = OwnershipBasicResult.catalog;
      const listGridData = JSON.parse(catalogData.ownershipRecordInfo);
      const msids = [];
      let allListSymbolMsids = [];
      if (listGridData) {
          for (const record of listGridData) {
            let rowData = {};
              msids.push(record["-4000008"]);
              rowData.sponsorId = record["-4000008"];
              rowData.symbol = record[DataItemIdSymbol];
              allListSymbolMsids.push(rowData);
          }
      }
      this._state.allMsidList = msids;
      this._state.allListSymbolMsids = allListSymbolMsids;
      resolve(true);
  })});
}

  getBasicEsInfoforCopy(listId, actualListId, colsetId, destItem, isColumnFilterEnabled = this.getScreenOnOffValue()) {
    return BasicEsInfo.getBasicEsInfoforCopy(listId, actualListId, colsetId, destItem, isColumnFilterEnabled)
  }

  /*eslint-disable */
  async getActiveGridRows(selectedIndexes) {
    /*eslint-enable */
    try {
      const sourceData = this.getSourceData();
      const activeData = [];
      map(selectedIndexes, (row) => {
        if (sourceData[row]) activeData.push(sourceData[row]);
      });
      return activeData;
    }
    catch (e) {
      console.log(e);
    }
  }
  
  isMyOwnerList() {    
    const currentNode = this.activeNode;
    if(!StringUtil.isEmpty(currentNode)){
      let parentCategoryType = currentNode.sourceCategoryType;
      if(currentNode && ((currentNode.categoryType === ListCategoryType.FAVORITE_ListCategory && currentNode.sourceCategoryType === ListCategoryType.USER_ListCategory)|| 
        parentCategoryType === ListCategoryType.FAVORITE_ListCategory)){
        return true;
      }
      if (currentNode && (currentNode.categoryType === ListCategoryType.USER_ListCategory ||
        parentCategoryType === ListCategoryType.USER_ListCategory)) {
        return true;
      }
    }    
    return false;
  }

  isSmartList(){
    const currentNode = this.activeNode;
    if(!StringUtil.isEmpty(currentNode)){
      let parentCategoryType = currentNode.sourceCategoryType;
      if(currentNode && ((currentNode.categoryType === ListCategoryType.FAVORITE_ListCategory && currentNode.sourceCategoryType === ListCategoryType.SMARTLIST_ListCategory)|| 
        parentCategoryType === ListCategoryType.FAVORITE_ListCategory)){
        return true;
      }
      if (currentNode && (currentNode.categoryType === ListCategoryType.SMARTLIST_ListCategory ||
        parentCategoryType === ListCategoryType.SMARTLIST_ListCategory)) {
        return true;
      }
    }    
    return false;
  }

  setAlertMessage(msg) {
    this._state.alertMessage = msg;
  }

  clearSourecData() {
    this._source = [];
  }

  dispatcherCallback(payload) {
    const action = payload.action;
    const data = action.data;
    switch (action.actionType) {
      case OwnerShipFetchDetails.GET_OWNERSHIP_DATA:
        this._state.listId = data.listId;
        this._state.actualListId = data.actualListId;
        if (data.esBasicCatalogData) {
          this._source = [];
          this._currentAction = OwnerShipFetchDetails.GET_OWNERSHIP_DATA;
          GridStore.resetSelectedIndexes();
          GridStore.clearCurrentAction();
          this._state.esBasicCatalogData = data.esBasicCatalogData;
          this._state.basicEsInfo = data.basicEsInfo;
          this._state.basic = this._state.esBasicCatalogData;
          this._state.cacheId = this._state.basic.cacheId;
          this._state.basicEsInfo.cacheId = this._state.cacheId;
          this._state.fullListMsids = data && data.esBasicCatalogData && data.esBasicCatalogData.fullMsid && data.esBasicCatalogData.fullMsid.split(';');

          if (data && data.esBasicCatalogData && data.esBasicCatalogData.fullMsid) {
            const fullListMsids = data.esBasicCatalogData.fullMsid.split(';');
            let allListSymbolMsids = [];
            let msids = [];
            for (const item of fullListMsids) {
              let msidSymbol = item && item.split(':');
              let msid = null;
              let rowData = {};
              if(msidSymbol && msidSymbol.length > 0){
                msid = msidSymbol[0];
                msids.push(msid);
                rowData.sponsorId = msid;
                if(msidSymbol.length > 1){
                  rowData.symbol = msidSymbol[1];
                }
                allListSymbolMsids.push(rowData);
              }
            }
            this._state.allMsidList = msids;
            this._state.allListSymbolMsids = allListSymbolMsids;
          }
          this._state.columnSetId = StringUtil.convertFromLongValueToInt(this._state.basic.columnSet.nodeId);//this._state.basic.columnSet.nodeId.low;
          this._state.columnSetName = this._state.basic.columnSet.name;
          this._state.columns = this.removeInvisibleColumns(clone(this._state.basic.columnInfos));
          this._columnInfo = this._state.columns;
          this._columnFilters = this._state.basic.columnFilters;
          this.processColumns().then(() => {
            ColumnCustomFilterControl.setColumnInfoFromStore();
            this.processServiceCalls(data);
            SettingsStore.saveSettings();
          });
        }
        break;

      case OwnerShipFetchDetails.GET_OWNERSHIP_DRAG_NODE:
        this._state.dragNodeData = data.myData;
        break;

      case OwnershipViewConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.GET_MORE_DATA_ON_SCROLL_OWNERSHIP;
        this.getMoreDataOnScroll(data.listCatalog);
        break;

      case OwnershipViewConstants.ActionTypes.ISDELETE_ENABLE:
        this._currentAction = OwnershipViewConstants.ActionTypes.ISDELETE_ENABLE;
        this._state.isDeleteEnable = action.data;
        this.emit(CHANGE_EVENT);
        break;

      case OwnershipViewConstants.ActionTypes.RELOAD_LIST_CATALOG_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.RELOAD_LIST_CATALOG_OWNERSHIP;
        this.emit(CHANGE_EVENT);
        break;

      case OwnershipViewConstants.ActionTypes.GET_SEARCH_TEXT_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.GET_SEARCH_TEXT_OWNERSHIP;
        this.setListCatalogSearchKeyword(action.data);
        this.emit(CHANGE_EVENT);
        break;

      case OwnershipViewConstants.ActionTypes.CLEAR_SEARCH_TEXT_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.CLEAR_SEARCH_TEXT_OWNERSHIP;
        this.setListCatalogSearchKeyword(action.data);
        this.emit(CHANGE_EVENT);
        break;

      case OwnershipViewConstants.ActionTypes.SELECT_COLUMN_SET_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.SELECT_COLUMN_SET_OWNERSHIP;
        this._state.columnSetId = action.data;
        this.emit(CHANGE_EVENT);
        break;

      case OwnershipViewConstants.ActionTypes.GET_COLUMN_PICKLIST_OWNERSHIP:
        this.getListColumnPickList(action.data);
        break;

      case OwnershipViewConstants.ActionTypes.SCREEN_ON_OFF_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.SCREEN_ON_OFF_OWNERSHIP;
        this.toggleScreenOnOff(action.data);
        this.emit(CHANGE_EVENT);
        SettingsStore.saveSettings();
        break;

      case OwnershipViewConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.GET_SCREEN_OFF_LIST_CATALOG_OWNERSHIP;
        this.setColumnFilterData(action.data.result);
        this.emit(CHANGE_EVENT);
        break;
      case OwnershipViewConstants.ActionTypes.SET_ALERT:
        this._currentAction = OwnershipViewConstants.ActionTypes.SET_ALERT;
        this.setAlertMessage(action.data);
        this.emit(CHANGE_EVENT);
        break;
      case OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_OWNERSHIP;
        this.addSymbolsToList(data);
        break;
      case OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_BY_DRAG_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.ADD_SYMBOLS_TO_LIST_BY_DRAG_OWNERSHIP;
        this.addSymbolsToList(data);
        break;
      case OwnershipViewConstants.ActionTypes.SAVE_SCREENED_RESULTS_OWNERSHIP:
        this._currentAction = OwnershipViewConstants.ActionTypes.SAVE_SCREENED_RESULTS_OWNERSHIP;
        this.emit(CHANGE_EVENT);
        break;
      case OwnershipViewConstants.ActionTypes.GET_REFRESHED_VIEW_OWNERSHIP:
        this._state.isRefreshView = true;
        break;
      case OwnershipViewConstants.ActionTypes.SET_ACTIVE_NODE:
        this.activeNode = action.data;
        break;
        default: break;
    }
  }
}

const ownershipViewStore = new OwnershipViewStore();
export default ownershipViewStore;