import { RotationGraphConstants } from "Constants/RotationGraphConstants";
const { ActionTypes } = RotationGraphConstants;
import SettingsStore from "SettingsStore";
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";
import { SettingsConstants } from "Constants/SettingsConstants.js";
import CompareTabType,{CompareMenuType} from "CompareTabType";
import { extend, map } from "underscore";
extend(ActionTypes, SettingsConstants.ActionTypes);

const initData = {
    zoomPercentageValue: 'AUTO',
    zoomMatrix: "matrix(" + 1 + "," + 0 + "," + 0 + "," + 1 + "," + 0 + "," + 0 + ")"
}

const initialState = {
    selectedMenuTab: CompareMenuType.Symbols,
    SymbolsData: { ...initData },
    GroupsData: { ...initData },
    selectedTab: null
}

const HeaderPaneReducer = (state = initialState, action) => {
    let settings;
    
    const setStateData = (obj) => {

        if (state.selectedMenuTab === CompareMenuType.Symbols) {
            map(obj, (value, property) => state.SymbolsData[property] = value);
        }
        else {
            map(obj, (value, property) => state.GroupsData[property] = value);
        }

        return { ...state };
    }


    const getStateData = () => {
        if (state.selectedMenuTab === CompareMenuType.Symbols) {
            return state.SymbolsData;
        }
        else {
            return state.GroupsData;
        }
    }

    const getSettings = () =>{
        const settings = SettingsStore.getConsoleSettings().NavCompareSettings.TabRotationGraphSettings;
        return state.selectedMenuTab == CompareMenuType.Symbols ? settings.rotationSymbolsMenuSettings : settings.rotationGroupsMenuSettings;
    }

    switch (action.type) {
        case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
            return {
                ...state,
                selectedTab: action.selectedTab
            }

        case ActionTypes.SET_COMPARE_MENUTAB:
            if (state.selectedTab === CompareTabType.RotationGraph) {
                return {
                    ...state,
                    selectedMenuTab: action.menuTab
                }
            } else {
                return { ...state }
            }

        case ActionTypes.UPDATE_FROM_USER_SETTINGS_DATA:
            settings = getSettings();
            return setStateData({
                zoomPercentageValue: settings.rotationGraphZoomValues.zoomValue,
                zoomMatrix: settings.rotationGraphZoomValues.matrix
            });

        case ActionTypes.UPDATE_ZOOM_PERCENTAGE:
            settings = getSettings();
            CompareSettingHelper.updateRotationGraphZoomValues(settings, action.zoomPercentageValue, action.zoomMatrix);
            SettingsStore.saveSettings();
            return setStateData({
                zoomPercentageValue: action.zoomPercentageValue,
                zoomMatrix: action.zoomMatrix
            });

        case ActionTypes.RESET_ZOOM_VALUE:
            return setStateData({
                zoomPercentageValue: getStateData().zoomPercentageValue !== 'AUTO' ? '100%' : 'AUTO',
                zoomMatrix: "matrix(" + 1 + "," + 0 + "," + 0 + "," + 1 + "," + 0 + "," + 0 + ")"
            });

        default:
            return state;
    }
}

export default HeaderPaneReducer;