import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetFactorEventInfoRequest extends BaseServiceRequest {
    constructor(
        // symbol,
         msId,
         eventId,
         segmentId,
        osid,
        startDate,
        eventDate
        // startDt, endDt, graphtype, osid, type
        ) {
        super();
        super.addPathParameter(msId.toString());
        super.addRequestUri("factoreventinfo.pb");
        super.addRequestParameter("eventid", eventId);
        super.addRequestParameter("segmentid", segmentId);
        // super.addPathParameter(msId.toString());
        // super.addRequestParameter("start", startDt);
        // super.addRequestParameter("end", endDt);
        // super.addRequestParameter("p", graphtype);
        // super.addRequestParameter("t", type);
        super.addRequestParameter("osid", osid);
        super.addRequestParameter("tradedate", startDate);
        super.addRequestParameter("eventdate", eventDate);

    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "FactorEventInfoResponse";
    }
}

export default GetFactorEventInfoRequest