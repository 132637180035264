import ArrayUtil from "ArrayUtil";
import { connect } from 'react-redux'
import { LightDarkColorConstants } from "../../../../../../Constants/LightDarkColorConstants.js";
import LineVisual from "LineVisual";
import React from 'react';
import ThemeHelper from "ThemeHelper";
import { updateContextHit } from "../../../../../../Actions/PricePanelActions.js";

function MALines(props) {

    const getMALine = (maLine, maSetting, lastPrice, index) => {
        const maValues = maLine ? maLine : [];
        const yLastPrice = maValues && maValues.length > 0 && maValues[0] !== undefined ? maValues[0].yPrice : -1000;
        let dashArray = [0, 0]
        let showMarchingAnts = false;

        if (maLine && maLine.length > 0 && maValues[0] !== undefined) {
            let MALabel = "";
            if (maSetting && maSetting.ma) {
                MALabel = `${maSetting.ma} ${maSetting.maType}`;
            }
            if (!maSetting.lineColor && index === 0) {
                maSetting.lineColor = 'P0066CC3399FF';
            }
            if (!maSetting.lineColor && index === 1) {
                maSetting.lineColor = 'PCC0100990000';
            }
            if (!maSetting.lineColor) {
                maSetting.lineColor = 'P34FF33006600';
            }
            if (!maSetting.lineThickness) {
                maSetting.lineThickness = 1;
            }

            if (maSetting.highlightLine === true) {
                dashArray = [3, 4];
                showMarchingAnts = true;
            }

            const lineColor = ThemeHelper.getThemedBrush(maSetting.lineColor);
            const LineFontColor = ArrayUtil.contains(LightDarkColorConstants.LightColor, lineColor) ? "black" : "white";
            return <LineVisual key={`maLine-${index}`} DataSource={maLine}
                nodeWidth={props.nodeWidth} LineColor={lineColor}
                LineThickness={maSetting.lineThickness} pointerTextColor={LineFontColor}
                Draggable={false} lineID={MALabel} DashArray={dashArray}
                messageColor={LineFontColor} maValue={maValues[0].yValue}
                zIndex={'1'} isToolTip={props.showToolTip}
                lastPrice={lastPrice} ylastPrice={yLastPrice}
                updateContextHit={()=> props.updateContextHit(MALabel, index)}
                index={index} showMarchingAnts={showMarchingAnts} />;
        }
    }
    //MA Lines
    const { maSettings, maLineData, HiLowPoints} = props;
    const lastPrice = HiLowPoints && HiLowPoints.allPoints && HiLowPoints.allPoints[0] && HiLowPoints.allPoints.length > 0 ? HiLowPoints.allPoints[0].graphData.Close : 0;

    return (<>
        {(maLineData && maLineData.length > 0) &&
            maSettings.map((maSetting, index) => {
                if (maSetting.IsVisible) {
                    return getMALine(maLineData[index], maSetting, lastPrice, index);
                } else {
                    return (<div key={index}></div>);
                }
            })
        }
    </>
    );
}

const mapStateToProps = ({ DatagraphReducers, appColor })=> {
    const { dimension, HiLowPoints } = DatagraphReducers.PriceChartReducer;
    const { nodeWidth, showToolTip } = DatagraphReducers.DataGraphReducer;
    const { maSettings, maLineData } = DatagraphReducers.MAReducer;
    const { isThemeChanged } = appColor;
    return { dimension, HiLowPoints, nodeWidth,  maSettings, maLineData, showToolTip, isThemeChanged };
}

const mapDispatchToProps = (dispatch)=>({
    updateContextHit: (lineID, index)=> dispatch(updateContextHit({lineID, index})),
  })
export default connect(mapStateToProps, mapDispatchToProps)(MALines);
