import {extend} from 'underscore';
import { ComparisonGraph } from "Constants/ComparisonGraph";
import { SettingsConstants } from "Constants/SettingsConstants.js";
const { ActionTypes } = ComparisonGraph;
import { ComparisonAndRotationMenuConstants } from '../../../Constants/RotationGraphConstants';
extend(ActionTypes, ComparisonAndRotationMenuConstants.ActionTypes,SettingsConstants.ActionTypes); 
import CompareTabType,{CompareMenuType} from "CompareTabType";
import { SymbolDataModel } from "../../../Models/ComparisonChartModels/LineDataModel";

const initialState = {
    selectedMenuTab : CompareMenuType.Symbols,
    SymbolsData : [],
    GroupsData : [],
    selectedTab:null
};

let filterData;
let stateData;
const compChartListItem = (state = initialState, action) => {

     
    const setStateData = (value,extendData = false) => {
    
        if (state.selectedMenuTab === CompareMenuType.Symbols) {
            if(!extendData)
            state.SymbolsData = value;
            else
            {
                if(Array.isArray(value))
                state.SymbolsData.push(...value);
                else{
                    const index = state.SymbolsData.findIndex((item)=> item.id === value.id)
                    if(index === -1){
                        state.SymbolsData.push(value)
                    }{
                        state.SymbolsData[index] = value;
                    }
                }

            }
        }
        else {
            if(!extendData)
            state.GroupsData = value;

            else
            {
                if(Array.isArray(value))
                state.GroupsData.push(...value);
                else
                state.GroupsData.push(value);

            }
        }

        return {...state};
    }

    const getStateData = () => {
    
        if (state.selectedMenuTab === CompareMenuType.Symbols) {
           return state.SymbolsData ;
        }
        else {
          return state.GroupsData ;
        }
    }

        switch (action.type) {
            case ActionTypes.COMPARE_UPDATE_TAB_TO_STATE:
                return {
                    ...state,
                    selectedTab: action.selectedTab
                }

            case ActionTypes.SET_COMPARE_MENUTAB:
                if (state.selectedTab === CompareTabType.ComparisonGraph) {
                 return {  ...state,
                    selectedMenuTab:action.menuTab}
                }else{
                    return {...state}
                }
                
        case ActionTypes.INIT_LINE_COLLECTION:
            return setStateData(action.LinesCollection);
        case ActionTypes.COMP_UPDATE_LINE:
               return setStateData(action.lineData);
        case ActionTypes.ADD_LIST_ITEM:
            return setStateData(action.listItem,true);
        case ActionTypes.REMOVE_COMPARISON_LIST_ITEM:
            return setStateData(action.filteredSymbolData);
        case ActionTypes.HANDLE_HIGHLIGHT_SUCCESS:
            return setStateData(action.chartListItem);
        case ActionTypes.CLEAR_COMPARISON_SYMBOLS_DATA:
        case ActionTypes.CLEAR_ALL_SUCCESS:
        case ActionTypes.TOGGLE_VISIBLE_SUCCESS:
        case ActionTypes.FILTER_ALL_DATA:
            return setStateData(action.data);
        case ActionTypes.TOGGLE_EDIT_MODE:
            stateData = getStateData();
            filterData = stateData.map((item) => (item.Osid === action.id) ? ((item.isEditMode && action.itemValue && action.itemValue !== item.name) ? Object.assign(new SymbolDataModel, { ...item, isEditMode: !item.isEditMode, name: action.itemValue }) : Object.assign(new SymbolDataModel, { ...item, isEditMode: !item.isEditMode })) : item);
            return setStateData(filterData);
        default:
            return {...state}
    }
}

export default compChartListItem;