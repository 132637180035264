import Serializable from "Serializable";
class HiLoPriceSettings extends Serializable {

    constructor() {
        super();
        this.IsAvailable = true;
        this.IsVisible = true;
    }

    onSeralisedInfoRecieved(info) {
        this.IsVisible = info.IsVisible !== undefined ? info.IsVisible : true;
        this.IsAvailable = info.IsAvailable !== undefined ? info.IsAvailable : true;
    }

    getDataObject() {
        return {
            IsVisible: this.IsVisible,
            IsAvailable: this.IsAvailable
        };
    }

    getObjectMapKey() {
        return "HiLoPriceSettings";
    }
}

export default HiLoPriceSettings;
