import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class UpdateAlertRequest extends BaseServiceRequest {
  constructor(data) {
    super();
    super.addRequestUri("update.pb");
    const ReqProto = BaseServiceApi.rayData["PriceAlertData"];
    const settingData = new ReqProto();
    let obj = data;
    settingData.alert = obj.priceAlertData.alert;
    settingData.msid = obj.msid;
    settingData.targetPrice = obj.targetPrice;
    settingData.currencyCode = obj.priceAlertData.currencyCode;
    settingData.note = obj.note;
    const reqcontent = settingData.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.PUT;
  }

  getSubSystem() {
    return SubSystemType.ALERTS;
  }

  getMessageName() {
    return "Response";
  }
}