import React from 'react';
import { Tabs, Tab } from "react-bootstrap";
import LocalizationStore from 'LocalizationStore';
import { UpdateDialogType } from "../../../Actions/ShareAction";
import { ShareActionConstants } from "../../../Constants/ShareConstants";

const TabBar = ({
  sharedAction
}) => {

  const getManagedData = (ntId, action) => (event) => {
    if (sharedAction !== action)
      UpdateDialogType('managed', ntId, action);
  }

  const styleNames = {
    data: 'tab',
    dem: 'tab',
    matching: 'tab'
  }

  return (
    <div className='manage-tabs' data-test-id="tab-bar">
      <ul>
        <li>
          <a  className = { sharedAction === ShareActionConstants.SHARE_EXTERNAL_DATA ? "selected" : '' }
              onClick={getManagedData('6', ShareActionConstants.SHARE_EXTERNAL_DATA)}>
              EXTERNAL DATA
          </a>
        </li>
        <li>
          <a  className = { sharedAction === ShareActionConstants.SHARE_LIST ? "selected" : '' }
              onClick={getManagedData('1', ShareActionConstants.SHARE_LIST)}>
            {LocalizationStore.getTranslatedData("set_shar_lists","LISTS")}
          </a>
        </li>
        <li>
          <a  className = { sharedAction === ShareActionConstants.SHARE_COLUMN_SET ? "selected" : '' }
              onClick={getManagedData('4', ShareActionConstants.SHARE_COLUMN_SET)}>
            {LocalizationStore.getTranslatedData("set_shar_cols","COLUMN SETS")}
          </a>
        </li>
        <li>
          <a  className = { sharedAction === ShareActionConstants.SHARE_ANNOTATIONS ? "selected" : '' }
              onClick={getManagedData('5', ShareActionConstants.SHARE_ANNOTATIONS)}>
            {LocalizationStore.getTranslatedData("set_shar_anno_Cur","ANNOTATIONS")}
          </a>
        </li>
        <li>
          <a  className = { sharedAction === ShareActionConstants.SHARE_RECIPIENTS ? "selected" : '' }
              onClick={getManagedData('3', ShareActionConstants.SHARE_RECIPIENTS)}>
            {LocalizationStore.getTranslatedData("set_shar_recipients","RECIPIENTS")}
          </a>
        </li>
      </ul>
     {/* <a className=
        {
          sharedAction === ShareActionConstants.SHARE_ANNOTATIONS
            ? "selected"
            : ''
        }
        onClick={getManagedData('2', ShareActionConstants.SHARE_ANNOTATIONS)}>
        {LocalizationStore.getTranslatedData("set_shar_anno","ANNOTATIONS")}
      </a>*/}
    </div>
  )
}

export default TabBar;