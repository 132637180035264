import ColorPicker from "../../../../../../../RayCustomControls/ColorPicker/ColorPicker.jsx";
import { connect } from "react-redux";
import { DialogHOC } from "./DialogHOC.jsx";
import { Modal } from "react-bootstrap";
import { TranslateHelper } from "../../../../../../../Utils/TranslateHelper.js";
import { cancelMALineSettingDialog, changeMALineColor, changeMALineLength, changeMALineType, changeMALineWeight, saveMALineSettingDialog, setMALineError } from "../../../../../../../Actions/PricePanelActions.js";
import React, { Component } from "react";

const colorPickerWidth = 100;
        const thickness = [
            { weight: "thin-line", value: 1 },
            { weight: "normal-line", value: 2 },
            {
                weight: "weight-line", value: 3
            }];

class MovingAverageSettingDialog extends Component {
    constructor(props) {
        super(props);
        this.handleChangeLength = this.handleChangeLength.bind(this);
    }

    handleChangeLength(e) {
        if (e.target.value && !(/^[\d]+\.?\d*$/.test(e.target.value))) {
            this.props.setMALineError();
            return;
        }
        this.props.changeMALineLength(e.target.value ? parseInt(e.target.value) : '');
    }

    componentDidUpdate() {
        if (this.props.isMADialogOpen) {
            if (this.lengthinput){ 
                this.lengthinput.focus();
            }
        }
    } 

    render() {
        return (
            <Modal.Body>
                <div className="modal-region">
                    <div className="box-add-ma">
                        <ul>
                            <li>
                                <span className="head-text">{TranslateHelper.Length }</span>
                                <input autoFocus className="length-txt" id="MAlengthInput" type={"number"}
                                    value={this.props.maLength} ref={(ref) => (this.lengthinput = ref)}
                                    onChange={this.handleChangeLength}>
                                </input>
                                <span className="length-note">(1-200)</span>
                                {this.props.hasError && <div className="length-error">
                                    {this.props.errorMessage}
                                </div>}
                            </li>
                            <li>
                                <input type="radio" name="movingaverageType" value="SMA" id="sma"
                                    defaultChecked={this.props.maType === 'SMA'}
                                    onChange={this.props.changeMALineType.bind(this, 'SMA')} />
                                <label htmlFor="sma">SMA</label>
                                <input type="radio" name="movingaverageType" value="EMA" id="ema"
                                    defaultChecked={this.props.maType === 'EMA'}
                                    onChange={this.props.changeMALineType.bind(this, 'EMA')} />
                                <label htmlFor="ema">EMA</label>
                            </li>
                        </ul>
                        <div className="colors-and-weights">
                            <div className="color-panel picker">
                                <span className="head-text">{ TranslateHelper.Color}</span>
                                <ColorPicker width={colorPickerWidth} color={this.props.lineColor} defaultColor={this.props.defaultMALineColor} onChangeComplete={this.props.changeMALineColor} />
                            </div>
                            <div className="weight-picker picker">
                                <span className="head-text">{ TranslateHelper.Weight}</span>
                                <div className="inner-picker">
                                    {thickness.map((item, index) =>
                                        (<a data-disable-track-price="true" key={index}
                                            className={item.value === this.props.lineThickness ? `${item.weight} active`: item.weight}                                                        
                                            onClick={() => this.props.changeMALineWeight( item.value )}>
                                            <div>
                                                <hr />
                                            </div>
                                        </a>)
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers }) =>{
    const { MAReducer } = DatagraphReducers
    const state = {}
    state.showSettingDialog = MAReducer.isMADialogOpen;
    state.HeaderText = MAReducer.maLineHeaderText
    state.maType = MAReducer.maType
    state.maLength = MAReducer.maLength
    state.defaultMALineColor = MAReducer.defaultMALineColor
    state.lineColor = MAReducer.lineColor
    state.lineThickness = MAReducer.lineThickness
    state.hasError = MAReducer.hasError
    state.errorMessage = MAReducer.errorMessage
    state.headerClass = "add-ma-dialog"
    return state;
}
const mapDispatchToProps = (dispatch) =>({
    saveLineDialog: ()=> dispatch(saveMALineSettingDialog()),
    cancelLineDialog: ()=> dispatch(cancelMALineSettingDialog()),
    changeMALineWeight: (lineThickness)=> dispatch(changeMALineWeight(lineThickness)),
    changeMALineColor: (lineColor)=> dispatch(changeMALineColor(lineColor)),
    changeMALineLength: (maLength)=> dispatch(changeMALineLength(maLength)),
    changeMALineType: (maType)=> dispatch(changeMALineType(maType)),
    setMALineError: ()=> dispatch(setMALineError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(MovingAverageSettingDialog));
