import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import AccountStore from "../../Stores/Account/AccountStore";
import MenuDialogType from "../../Constants/MenuDialogType.js";
import ChangePassDialog from "./ChangePassDialog.jsx";
import UserInfoUtil from "UserInfoUtil";
import { MenuDialogConstants } from "../../Constants/MenuDialogConstants.js";
import BaseServiceApi from "BaseServiceApi";
import LocalizationStore from 'LocalizationStore';
const entitlementType = BaseServiceApi.rayData["EntitlementType"];

export default class AccountDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.closeAccountDialog = this.closeAccountDialog.bind(this);
        this.accountDialogStateChange = this.accountDialogStateChange.bind(this);
        this.openChangePassDialog = this.openChangePassDialog.bind(this);
        this.getUserInfoDetails = this.getUserInfoDetails.bind(this);
        this.hideChangePassDialog = this.hideChangePassDialog.bind(this);
        this.state = {
            isOpen: false,
            menuDialogType: '',
            isChangPassDialogOpen: false
        }
    }

    componentDidMount() {
        AccountStore.addChangeListener(this.accountDialogStateChange);
    }

    componentWillUnmount() {
        AccountStore.removeChangeListener(this.accountDialogStateChange);
    }

    accountDialogStateChange() {
        let currentAction = AccountStore.getCurrentAction();
        if (currentAction == MenuDialogConstants.ActionTypes.ACCOUNT) {
            let accountDialogState = AccountStore.getState();
            switch (accountDialogState.eventKey) {
                case MenuDialogType.Account:
                    let st = {
                        isOpen: accountDialogState.isOpen,
                        menuDialogType: accountDialogState.eventKey,
                        isChangPassDialogOpen: false
                    };
                    this.setState(st);
                    break;
                default: 
                    break;
            }
        }

    }

    closeAccountDialog() {
        document.body.classList.remove('changePassWordOpened');
        this.setState({ isOpen: false });
    }

    openChangePassDialog() {
        document.body.classList.add('changePassWordOpened');
        this.setState({ isChangPassDialogOpen: true });
    }

    hideChangePassDialog() {
        document.body.classList.remove('changePassWordOpened');
        this.setState({ isChangPassDialogOpen: false });
    }

    getVisibility() {
        let changePasswordVisibility = true;
        let isChangePasswordEntitled = UserInfoUtil.IsUserEntitled(entitlementType.CHANGE_PASSWORD_Entitlement);
        changePasswordVisibility = isChangePasswordEntitled ? true : false;
        return changePasswordVisibility;
    }

    getUserInfoDetails() {
        let userInfo = UserInfoUtil.getUserInfo();
        let changePasswordVisibility = this.getVisibility();
        let changePasswordLink = changePasswordVisibility ? <a  className="email-support" onClick={this.openChangePassDialog}>{LocalizationStore.getTranslatedData('set_acc_pw_ch', 'Change')}</a> : ' ';
        return (
            <div className="account-info">
                <table>
                    <tbody>
                        <tr><td>{LocalizationStore.getTranslatedData('set_acc_name', 'Name:')}</td><td>{userInfo ? userInfo.name : ''}</td></tr>
                        <tr><td>{LocalizationStore.getTranslatedData('set_acc_email', 'Email:')}</td><td>{userInfo ? userInfo.userEmail : ''}</td></tr>
                        <tr><td>{LocalizationStore.getTranslatedData('set_acc_pw', 'Password:')}</td><td>*************** {changePasswordLink} </td></tr>
                        <tr><td>{LocalizationStore.getTranslatedData('set_acc_comp', 'Company:')}</td><td>{userInfo.company}</td></tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderFooter() {
        if (UserInfoUtil.IsUserEntitled(entitlementType.RAY_CHINA_Entitlement)) {
            return (
                <ul className="account-cares">
                    <li>
                        <span className="labels">{LocalizationStore.getTranslatedData('set_acc_cs', 'Client Services: ')}</span>
                        <a className="email-support" href="mailto:cs@williamoneilchina.com">cs@williamoneilchina.com</a>
                    </li>
                    <li>
                        {/* <span className="labels">QQ：</span>
                        <span className="values">2885263221</span> */}
                    </li>
                    <li>
                        <span className="labels">{LocalizationStore.getTranslatedData('set_acc_int', 'International: ')}</span>
                        <span className="values">+1 (310) 448-6199</span>
                    </li>
                </ul>
            );
        }
        return (
            <ul className="account-cares">
                <li>
                    <span className="labels">{LocalizationStore.getTranslatedData('set_acc_cs', 'Client Services: ')}</span>
                    <a className="email-support" href="mailto:support@williamoneil.com">support@williamoneil.com</a>
                </li>
                <li>
                    <span className="labels">US:</span> <span className="values">(800) 419-6632, </span>
                    <span className="labels">UK:</span> <span className="values">0 (800) 969-581,</span>
                </li>
                <li>
                    <span className="labels">{LocalizationStore.getTranslatedData('set_acc_int', 'International: ')}</span>
                    <span className="values">(310) 448-6199</span>
                </li>
            </ul>
        );
    }

    render() {
        return (
            <div id="accountDialog">
                <ChangePassDialog open={this.state.isChangPassDialogOpen} hideChangePassDialog={this.hideChangePassDialog} forcePasswordChange ={false} />
                <Modal className="modal-popup account-dialog" show={this.state.isOpen} onHide={this.closeAccountDialog}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="cap-header">{LocalizationStore.getTranslatedData('set_acc_title', 'ACCOUNT')}</span>
                            <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.closeAccountDialog}><span className="icn-close"></span></button>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-material">
                            {this.getUserInfoDetails()}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.renderFooter()}
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}