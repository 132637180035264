import ArrayUtil from "ArrayUtil";
import BlockType from "../../../../../Constants/BlockType.js";
import { connect } from "react-redux";
import ExtremeDataValue from "ExtremeDataValue";
import FormatterUtil from "FormatterUtil";
import { LightDarkColorConstants } from "../../../../../Constants/LightDarkColorConstants.js";
import Measure from "react-measure";
import Pointer from "Pointer";
import ReactDOM from "react-dom";
import StringUtil from "../../../../../Utils/StringUtil.js";
import ThemeHelper from "ThemeHelper";
import TrackPriceHelperFile from "../PriceChart/Helper/TrackPriceHelper.js";
import { updateShowToolTip } from "../../../../../Actions/DatagraphActions.js";
import React, { Component } from "react";

class TrackPrice extends Component {

    constructor(props) {
        super(props);
        this.handleMouseDown = this.handleMouseDown.bind(this);
        this.removeTrackPrice = this.removeTrackPrice.bind(this);
        this.moveTrackPrice = this.moveTrackPrice.bind(this);
        this.onmouseleave = this.onmouseleave.bind(this);
        this.getTrackPriceTemplate = this.getTrackPriceTemplate.bind(this);
        this.PriceChartView = {};
        this.state = {
            dimensions: { width: 0.0 },
            topMargin: 25,
            crossHair: { x: -1, y: -1 },
            isMousedown: false,
            lastMove: Date.now(),
            template: null,
            dispTextR: " ",
            dispTextL: " ",
            ma1Value: " ",
            ma1PerChange: " ",
            ma1Color: " ",
            yma1: 0,
            ma2Value: " ",
            ma2PerChange: " ",
            ma2Color: " ",
            yma2: 0,
            previousNode: undefined,
            previousClient: null,
            isTopAlign: false,
            chart: null,
            pointers: [],
            lastValidPrice: 0
        };
        this.event = null;
        this.chart = null;
    }

    componentDidMount() {
        if (this.props.externalInfoText) return;
        this.subscribe(this.props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.externalInfoText) {
            this.unsubscribe(prevProps);
            this.removeTrackPrice();
            return;
        }
        if (Object.values(this.props.charts).length > 0) {
            this.unsubscribe(prevProps);
            this.subscribe(this.props);
        }
        if(this.event !== null && this.chart !== null && prevProps.HiLowPoints !== this.props.HiLowPoints){
            this.invokeTrackPrice(this.event, this.chart)
        }
    }
    componentWillUnmount() {
        this.unsubscribe(this.props);
        this.removeTrackPrice();
    }
    subscribe(props) {
        const allCharts = Object.values(props.charts);
        this.PriceChartView = props.charts[BlockType.Pricechart].current
        this.charts = allCharts;
        const length = allCharts.length;
        for (let i = 0; i < length; i++) {
            const view = allCharts[i].current;
            if (view) {
                const dom = ReactDOM.findDOMNode(view);
                dom.setAttribute("data-disable-track-price", false);
                dom.view = view;
                dom.addEventListener("mousedown", this.handleMouseDown, false);
                dom.addEventListener("mouseleave", this.onmouseleave, false);
                dom.addEventListener("mousemove", this.moveTrackPrice, false);
                dom.addEventListener("mouseup", this.removeTrackPrice, false);
            }
        }
    }

    onmouseleave(e) {
        if (this.state.isMousedown) {
            this.removeAppendLayer(e.fromElement);
            const isDisabled = this.checkDisableTrackPrice(e.toElement);
            if (isDisabled !== "false") {
                //this.unsubscribe(this.props);
                this.removeTrackPrice(e);
            }
        }
    }

    checkDisableTrackPrice(element) {
        if (!element || !element.hasAttribute) {
            return "true";
        }
        else if (!element.hasAttribute("data-disable-track-price")) {
            const parentNode = element.parentNode;
            if (parentNode) {
                return this.checkDisableTrackPrice(parentNode);
            }
        }
        const result = element.getAttribute("data-disable-track-price");
        return result;
    }
    unsubscribe(props) {
        const allCharts = Object.values(props.charts);
        this.charts = [];
        const length = allCharts.length;
        for (let i = 0; i < length; i++) {
            const view = allCharts[i].current;

            if (view) {
                const dom = ReactDOM.findDOMNode(view);
                dom.removeEventListener("mousedown", this.handleMouseDown);
                dom.removeEventListener("mouseleave", this.onmouseleave, false);
                dom.removeEventListener("mousemove", this.moveTrackPrice);
                dom.removeEventListener("mouseup", this.removeTrackPrice);
            }

        }
    }

    addLayerToChart(dom) {
        if (!this.layer && dom) {
            const layer = document.createElement('div');
            layer.className = 'track-price-layer';
            layer.id = 'trackPriceLayer';
            this.layer = layer;
            dom.appendChild(layer);
        }
    }

    removeAppendLayer(dom) {
        if (this.layer && dom) {
            try {
                dom.removeChild(this.layer);
                this.layer = undefined;
            }
            catch (e) {
                console.log('Error inside removeAppednLayer in TrackPrice.jsx', e)
            }
        }
    }
    handleMouseDown(e) {
        // Fix for PANWEB-6160 issue 1 - Avoid combined click events of trackprice and measurement annotation toggle grey box
        if (e.target.classList.contains("icn-collapser")) {
            return;
        }

        if (e.which === 1) {
            const view = e.currentTarget.view;
            const isDisabled = this.checkDisableTrackPrice(e.target);
            if (isDisabled === "false") {
                //this.addLayerToChart(e.currentTarget);
                if (this.props.setCursor) {
                    this.props.setCursor("none");
                    this.props.updateShowToolTip(false)
                }

                setTimeout(() => {
                    this.invokeTrackPrice(e, view);
                }, 0);
            }
        }
    }

    handleResize(dimensions) {
        this.setState({ dimensions: dimensions });
    }
    invokeTrackPrice(e, chart) {
        if (!chart) {
            return;
        }
        let rect = chart.getBoundingClientRect();
        if (rect === undefined) {
            return;
        }
        const clientX = e.clientX - this.state.dimensions.left;
        const clientY = e.clientY - this.state.dimensions.top;
        const chartY = e.clientY - (rect.top);
        let xAxis = clientX;
        if (xAxis < 0) {
            this.removeTrackPrice(e);
            return;
        }
        const dateNow = Date.now();
        const previousClient = {
            clientX: e.clientX,
            clientY: e.clientY
        };
        if (dateNow - this.state.lastMove > 20) {
            const diffx = clientX - this.state.crossHair.x;
            const diffy = clientY - this.state.crossHair.y;
            if (diffx >= this.props.nodeWidth || diffx <= -this.props.nodeWidth || diffy >= 1 || diffy <= -1 || this.event !== null) {

                let scale = [];
                const nodeInfo = this.getTrackPriceTemplate(clientX, chart.trackpriceReqArgs, e, chart);
                scale = chart.props.scale;
                const value = this.props.dimension.width + this.state.dimensions.left >= e.clientX;
                const arrowPointers = [{ Key: "price" }];
                if (StringUtil.isEmpty(scale)) {
                    return;
                }
                const priceAtLocation = scale.ComputePrice(chartY);
                if (!value && this.state.lastValidPrice === 0) {
                    this.setState({ lastValidPrice: priceAtLocation })
                }
                if (!nodeInfo && value) {
                    // Showing cross hair when nodedata is not available but one of the fundamental data lines are selected
                    this.setState({
                        pointers: arrowPointers,
                        rect,
                        chart,
                        dispTextR: " ",
                        dispTextL: chart.trackpriceReqArgs.chartType !== BlockType.Insider && ExtremeDataValue.showPrice(priceAtLocation),
                        crossHair: { x: clientX, y: clientY },
                        isMousedown: true,
                        lastMove: dateNow,
                        template: null,
                        previousNode: undefined,
                        previousClient: undefined,
                        contentHeight: rect.height,
                        lastValidPrice: 0
                    });
                    return;
                }
                else if (!value) {
                    return;
                }
                
                xAxis = nodeInfo.node ? nodeInfo.node.xAxis : nodeInfo.xAxis;
                let dispTextR = " ", dispTextL = " ";
                if(chart.trackpriceReqArgs.chartType === BlockType.Pricechart){
                    const changeInfo = nodeInfo.node ? (priceAtLocation - nodeInfo.node.graphData.Close) * 100 / nodeInfo.node.graphData.Close : 0;
                    const sign = changeInfo > 0 ? "+" : "";
                    dispTextL = ExtremeDataValue.showPrice(priceAtLocation);
                    dispTextR = `(${sign}${changeInfo.toFixed(0)}%)`;
                }
                else{
                    rect = this.PriceChartView.getBoundingClientRect();
                    if(chart.trackpriceReqArgs.chartType !== BlockType.Insider){
                        dispTextL = FormatterUtil.formatNumber(Math.round(priceAtLocation))
                    }
                }
                this.removeHighLightNode();
                if (nodeInfo.node.graphData.IsVisible) {
                    this.highLightNode(nodeInfo.nodeIndex);
                }
                this.setState({
                    rect,
                    chart,
                    dispTextR,
                    dispTextL,
                    pointers: nodeInfo.pointers,
                    crossHair: { x: xAxis, y: clientY },
                    isMousedown: true,
                    lastMove: dateNow,
                    template: nodeInfo.template,
                    previousNode: nodeInfo,
                    previousClient
                });
            }
        }
        setTimeout(() => {
            this.checkTopAlign();
        }, 0);
    }

    checkTopAlign() {
        if (this.content) {
            const rect = this.content.getBoundingClientRect();
            const limit = this.state.dimensions.bottom - this.state.dimensions.top;
            const isTopAlign = (this.state.crossHair.y + 15) + rect.height > limit;
            if (this.state.isTopAlign !== isTopAlign) {
                this.setState({ isTopAlign: isTopAlign, contentHeight: rect.height })
            }
        }
    }

    moveTrackPrice(e) {
        if (this.state.isMousedown) {
            if (e && e.currentTarget) {
                this.addLayerToChart(e.currentTarget);
            }
            const view = e.currentTarget.view;
            this.invokeTrackPrice(e, view);
            e.preventDefault();
            e.stopPropagation();
        }
    }

    removeTrackPrice(e) {
        if (!e) {
            return;
        }
        let target;
        if (e && e.currentTarget) {
            target = e.currentTarget;
        }
        setTimeout(() => {
            if (target) {
                this.removeAppendLayer(target)
            }
            this.event = null;
            this.chart = null;
            this.setState({ pointers: [] ,crossHair: { x: -1, y: -1 }, isMousedown: false, lastMove: Date.now(), template: null, isTopAlign: false });
            this.removeHighLightNode();

            if (this.props.setCursor) {
                this.props.setCursor("auto");
                this.props.updateShowToolTip(true)
            }
        }, 0);

    }
    removeHighLightNode() {
        const node = this.state.previousNode;
        if (node) {
            const charts = this.charts;
            const length = charts.length;
            for (let i = 0; i < length; i++) {
                const chart = charts[i];
                if (chart?.current?.trackpriceReqArgs?.barVisual?.current) {
                    chart.current.trackpriceReqArgs.barVisual.current.removeHighLightedNode(node.nodeIndex);
                }
            }
        }
    }
    highLightNode(nodeIndex) {
        const charts = this.charts;
        const length = charts.length;
        for (let i = 0; i < length; i++) {
            const chart = charts[i];
            if (chart?.current?.trackpriceReqArgs?.barVisual?.current) {
                chart.current.trackpriceReqArgs.barVisual.current.highLightNode(nodeIndex);
            }
        }
    }
    handleUpdatePrice(e, nodeInfo) {
        const clientX = e.clientX - this.state.dimensions.left;
        const clientY = e.clientY - this.state.dimensions.top;
        let xAxis = clientX;
        let template = null;
        if (nodeInfo !== null && nodeInfo) {
            template = nodeInfo.template;
            xAxis = nodeInfo.node.xAxis;
        }
        const dateNow = Date.now();
        this.setState({
            pointers: nodeInfo.pointers,
            crossHair: { x: xAxis, y: clientY },
            isMousedown: true,
            lastMove: dateNow,
            template: template,
            previousNode: nodeInfo
        });
    }
    getPointer() {
        const pointers = [];
        const textColor = "#000";//ThemeHelper.getThemedBrush("fff000");
        const noText = this.state.dispTextL === " " && this.state.dispTextR === " ";

        if (this.state.isMousedown && this.state.pointers && !noText) {
            const ptLen = this.state.pointers.length;
            for (let ptNum = 0; ptNum < ptLen; ptNum++) {
                const pt = this.state.pointers[ptNum];
                if (pt === undefined || pt === null) {
                    return pointers;
                }
                const key = pt.Key;
                if (!this.state.rect) {
                    return pointers;
                }
                const rect = this.state.rect;
                const yAxis = pt.yAxis + rect.top - 75;
                switch (key) {
                    case "tech":
                    case "price":
                    case "volume":
                        pointers.push(<Pointer key={key}
                            contentR={this.state.dispTextR}
                            contentL={this.state.dispTextL}
                            x={this.state.dimensions.width - 157} y={this.state.crossHair.y - 10} height={20} width={115} stroke="black"
                            fill={"#cccccc"} lcolor={textColor} rcolor={textColor} />);
                        break;
                    case "closePrice":
                        pointers.push(<Pointer key={key}
                            contentR={pt.dispTextR}
                            contentL={pt.dispTextL}
                            x={this.state.dimensions.width - 157} y={yAxis} height={20} width={115} stroke="black"
                            fill={"#cccccc"} lcolor="black" rcolor="black" />);
                        break;
                    default:
                        if (key.indexOf('maLine') !== -1) {
                            const lineColor = ThemeHelper.getThemedBrush(pt.Color);
                            const lineFontColor = ArrayUtil.contains(LightDarkColorConstants.LightColor, lineColor) ? "black" : "white";
                            pointers.push(<Pointer key={key}
                                contentR={pt.dispTextR}
                                contentL={pt.dispTextL}
                                x={this.state.dimensions.width - 157} y={yAxis} height={20} width={115} stroke="black"
                                fill={lineColor} lcolor={lineFontColor} rcolor={lineFontColor} />);
                        }
                        break;
                }
            }
        }
        return pointers;
    }

    getCrossHairs() {
        if (this.state.dimensions.height) {
            return `M 0 ${this.state.crossHair.y.toFixed(0)} L ${(this.state.crossHair.x - 7).toFixed(0)} ${this.state.crossHair.y.toFixed(0)} 
            M ${(this.state.crossHair.x - 3).toFixed(0)} ${this.state.crossHair.y.toFixed(0)} L ${(this.state.crossHair.x + 4).toFixed(0)} ${this.state.crossHair.y.toFixed(0)} 
            M ${(this.state.crossHair.x + 8).toFixed(0)} ${this.state.crossHair.y.toFixed(0)} L ${(this.state.dimensions.width - (this.props.rightScaleWidth + this.props.leftScaleWidth)).toFixed(0)} ${this.state.crossHair.y.toFixed(0)} 
            M ${this.state.crossHair.x} ${(this.state.topMargin + 2).toFixed(0)} L ${this.state.crossHair.x} ${(this.state.crossHair.y - 7).toFixed(0)} 
            M ${this.state.crossHair.x} ${(this.state.crossHair.y - 3).toFixed(0)} L ${this.state.crossHair.x} ${(this.state.crossHair.y + 4).toFixed(0)} 
            M ${this.state.crossHair.x} ${(this.state.crossHair.y + 8).toFixed(0)} L ${this.state.crossHair.x} ${(this.state.dimensions.height - 18)} `;
        } else {
            return " ";
        }
    }
    getTrackPriceTemplate = (clientX, trackpriceReqArgs, event, chart) => {
        const padding = this.props.padding * this.props.nodeWidth;
        const posFromRight = this.props.chartWidth - padding - clientX;
        const nodeIndex = Math.floor(posFromRight / this.props.nodeWidth);
        if(nodeIndex === 0){
            this.event = event;
            this.chart = chart;
        }
        else{
            this.event = null;
            this.chart = null;
        }
        const nodeData = TrackPriceHelperFile.getPriceChartTrackPriceNodeData(nodeIndex, trackpriceReqArgs);
        if (!nodeData) {
            return nodeData;
        }
        nodeData.template = TrackPriceHelperFile.getTrackPriceTemplate(nodeData, this.props.isVolumeOpen, trackpriceReqArgs);
        return nodeData;
    }

    render() {
        const style = { strokeWidth: "1", shapeRendering: "crispEdges" };
        const isleftAlign = this.state.crossHair.x - 194 > 0;
        const pointers = this.getPointer();
        const crossHairPath = this.getCrossHairs()
        //  var hairColor = ThemeHelper.getThemedBrush("fff666");
        return (
            <Measure
                bounds
                onResize={(contentRect) => {
                    this.handleResize(contentRect.bounds)
                }}
            >
                {({ measureRef }) =>
                    <div ref={measureRef} id="TrackPrice" className="trackPrice" style={{
                        marginLeft: `${this.props.leftScaleWidth}px`,
                        marginRight: `${this.props.rightScaleWidth}px`,
                        top: "0px",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        left: "0",
                        zIndex: "1060"
                    }}>
                        <div className="crossHair" style={{ zIndex: "1060", left: 0, width: "100%", height: "100%", top: "0", position: "absolute" }}>
                            <div ref={(ref) => (this.content = ref)} className="content cross-hair-tooltip" style={{
                                position: "absolute",
                                left: isleftAlign ? this.state.crossHair.x - 194 : this.state.crossHair.x + 14,
                                top: this.state.isTopAlign ? this.state.crossHair.y - (this.state.contentHeight + 15) : this.state.crossHair.y + 15
                            }}>
                                {this.state.template}
                            </div>
                            {pointers}
                        </div>

                        <svg ref={(ref) => (this.svg = ref)}
                            style={{ position: "absolute", width: "100%", height: this.state.dimensions.height, top: "0px", left: "0" }}>
                            <path d={crossHairPath} style={style} stroke="#999999" className="crossHair" />
                        </svg>
                    </div>
                }
            </Measure>
        );
    }
}

const mapStateToProps = ({ DatagraphReducers, externalDataUploader }) => {
    const { charts, chartWidth, HiLowPoints, dimension } = DatagraphReducers.PriceChartReducer;
    const { isVolumeOpen } = DatagraphReducers.volumeChartReducer;
    const { padding, nodeWidth, leftScaleWidth, rightScaleWidth } = DatagraphReducers.DataGraphReducer;
    const { selectedPerShare } = DatagraphReducers.FundamentalLineReducer;
    const { externalInfoText } = externalDataUploader.externalDataUploadSymbolReducer

    return { charts, chartWidth, padding, isVolumeOpen, nodeWidth, leftScaleWidth, rightScaleWidth, HiLowPoints, selectedPerShare, dimension, externalInfoText }
}

const mapDispatchToProps = (dispatch)=>({
    updateShowToolTip: (showTootTip)=> dispatch(updateShowToolTip(showTootTip))
})

export default connect(mapStateToProps, mapDispatchToProps)(TrackPrice)