import ScalesHelper from './ScalesHelper.js'
class RotationArithmeticScale {
    constructor() {
        this.mlPriceIncrement = 0;
        this.ygLowpt = 0;
        this.yfactor = 1;
        this.xgLowpt = 0;
        this.xfactor = 1;
        this.lowpt = 0;
        this.hipt = 0;
        this.mhLines = [];
        this.mhLabel = [];
    }

    computeY(dPrice) {
        let dPriceDiffFromBottom = dPrice - this.ygLowpt;
        return dPriceDiffFromBottom * this.yfactor;
    }

    computeX(dPrice) {
        let dPriceDiffFromBottom = dPrice - this.xgLowpt;
        return dPriceDiffFromBottom * this.xfactor;
    }

    initYScale(maxPrice, minPrice, scaleInterval, scaleHeight) {

        let iNumberOfIncrements = parseInt(maxPrice / scaleInterval);
        let prInc = (maxPrice - minPrice) / iNumberOfIncrements;

        if (prInc < 1 && prInc > 0.49)
            prInc = 0.50;
        else if (prInc < 0.50 && prInc > 0.24)
            prInc = 0.25;
        else if (prInc < 0.25)
            prInc = 0.10;
        else
            prInc = prInc - prInc % Math.pow(10, parseInt(ScalesHelper.log10(prInc)));
        if (scaleInterval != 0.0) {
            prInc = scaleInterval;
        }
        let prv = Math.pow(10, parseInt(ScalesHelper.log10(prInc)));
        let hiPrc = Math.ceil(maxPrice / prv) * prv;
        let lowPrc = Math.floor(minPrice / prv) * prv;
        var mFactor = 0;

        if (lowPrc == 0) {
            this.hipt = hiPrc;
            this.lowpt = lowPrc;
            mFactor = 0;
        }
        else {
            this.hipt = maxPrice;
            this.lowpt = minPrice;
        }
        this.ygLowpt = this.lowpt;

        // Compute the conversion Factor.
        this.yfactor = parseFloat(((scaleHeight) / ((this.hipt - this.lowpt) + mFactor)));

        this.mlPriceIncrement = prInc;
        if (this.lowpt == 0)
            this.ygLowpt = 0;
        this.setYHorizontalGrid();
    }

    initXScale(maxPrice, minPrice, scaleInterval, scaleWidth) {

        let iNumberOfIncrements = parseInt(maxPrice / scaleInterval);
        let prInc = (maxPrice - minPrice) / iNumberOfIncrements;

        if (prInc < 1 && prInc > 0.49)
            prInc = 0.50;
        else if (prInc < 0.50 && prInc > 0.24)
            prInc = 0.25;
        else if (prInc < 0.25)
            prInc = 0.10;
        else
            prInc = prInc - prInc % Math.pow(10, parseInt(ScalesHelper.log10(prInc)));
        if (scaleInterval != 0.0) {
            prInc = scaleInterval;
        }
        let prv = Math.pow(10, parseInt(ScalesHelper.log10(prInc)));
        let hiPrc = Math.ceil(maxPrice / prv) * prv;
        let lowPrc = Math.floor(minPrice / prv) * prv;
        let mFactor = 0;

        if (lowPrc == 0) {
            this.hipt = hiPrc;
            this.lowpt = lowPrc;
            mFactor = 0;
        }
        else {
            this.hipt = maxPrice;
            this.lowpt = minPrice;
        }
        this.xgLowpt = this.lowpt;

        // Compute the conversion Factor.
        this.xfactor = parseFloat(((scaleWidth) / ((this.hipt - this.lowpt) + mFactor)));

        this.mlPriceIncrement = prInc;
        if (this.lowpt == 0)
            this.xgLowpt = 0;
        this.setXHorizontalGrid();
    }

    setYHorizontalGrid() {
        this.mhLines = [];
        this.mhLabel = [];

        if(!isFinite(this.hipt) || !isFinite(this.lowpt)) return; 

        for (let pricePtr = this.lowpt; pricePtr <= this.hipt; pricePtr += this.mlPriceIncrement) {

            let y = this.computeY(pricePtr);

            //if (y<=0) break;

            if (this.mhLines.find((line) => line.yAxis === y)) continue;

            let eLine = {
                yAxis: y,
                width: 1
            };
            this.mhLines.push(eLine);

            let eLabel = "";


            let epsMultiplier = 20;
            eLabel = (pricePtr / epsMultiplier).toString();

            let labels =
            {
                hLabel: pricePtr === 0 ? "0" : pricePtr.toFixed(0),
                eLabel: eLabel,
                yAxis: y
            };

            this.mhLabel.push(labels);
        }
    }

    setXHorizontalGrid() {
        this.mhLines = [];
        this.mhLabel = [];
        
        if(!isFinite(this.hipt) || !isFinite(this.lowpt)) return; 

        for (let pricePtr = this.lowpt; pricePtr <= this.hipt; pricePtr += this.mlPriceIncrement) {

            let x = this.computeX(pricePtr);

            //if (y<=0) break;

            if (this.mhLines.find((line) => line.xAxis === x)) continue;

            let eLine = {
                xAxis: x,
                width: 1
            };
            this.mhLines.push(eLine);

            let eLabel = "";


            let epsMultiplier = 20;
            eLabel = (pricePtr / epsMultiplier).toString();

            let labels =
            {
                hLabel: pricePtr == 0 ? "0" : pricePtr.toFixed(0),
                eLabel: eLabel,
                xAxis: x
            };

            this.mhLabel.push(labels);
        }
    }
}
export default RotationArithmeticScale;