import Alerts from "Alerts";
import { AnnotationConstant } from '../../../../../../Constants/AnnotationConstants';
import { connect } from 'react-redux';
import { dismissAlert } from "../../../../../../Actions/AnnotationActions";
import PortalContainer from 'PortalContainer';
import TimeTrackingWindow from "TimeTrackingWindow";
import React, { Component } from 'react';

class AlertWindow extends Component {
    render() {
        const { showUndoAlert, alertMsg } = this.props;
        const undoActionObj = { action: AnnotationConstant.ActionTypes.UNDO_DELETED_ANNOTATIONS };

        if (!showUndoAlert) {
            return null;
        }
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            <PortalContainer>
                <Alerts ref={(r) => { this.alert = r; }} alertMessage={alertMsg}
                    showAnnotAlert={showUndoAlert} undoActionObj={undoActionObj}
                    dismissAlert={this.props.dismissAlert}
                />
            </PortalContainer>
        );
    }
}

const mapStateToProps = ({ annotationReducers }) => {
    const { showUndoAlert, alertMsg } = annotationReducers.annotationChartModelReducer;

    return ({ showUndoAlert, alertMsg });
}

const mapDispatchToProps = (dispatch) => ({
    dismissAlert: () => dispatch(dismissAlert())
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertWindow);