//import { fork, all } from 'redux-saga/effects';


import {
  watchHandleChannelLineClick,
  watchHandleChannelLineDraw,
  watchProcessChannelLineData
} from './GraphicsChannelLineSaga';

import {
  watchHandleClick,
  watchHandleMouseMove,
  watchHandleDrag,
  watchHandleMouseDown,
  watchSaveAnnotation,
  watchRemoveDrawing,
  watchDeleteAllAnnotations,
  watchControlSelect,
  watchConfirmDeleteAll,
  watchUndoDelete,
  watchoveFrontAnnotations,
  watchMoveBackAnnotations,
  watchUpdateRiPanelInfo
} from './AnnotationLayerSaga';

import {
  watchHandleEllipseClick,
  watchHandleEllipseDraw,
  watchProcessEllipseData,
  watchEllipseDrag,
  watchEllipAnchordrag,
  watchEllipAnchorLeave
} from './GraphicsEllipse';

import {
  watchHandleFibonacciLineClick,
  watchHandleFibonacciLineDraw,
  watchProcessFibLineData
} from './GraphicsFibonacciSaga';

import {
  watchHandleInitAnnotation,
  watchProcessAnnotationData,
  watchHandleAnnotationsOnChartResize,
  watchHandleContexMenu
} from './AnnotationInitModel';

import {
  watchHandleLineClick,
  watchHandleLineDraw,
  watchProcessLineData,
  watchAddAlert,
  watchRemoveAlert,
  watchRemoveAlertRI,
  watchToggleAlertRI
} from './GraphicsLineSaga';

import {
  watchHandleMeasurementClick,
  watchHandleMeasurementDraw,
  watchProcessMeasurementData
} from './GraphicsMeasurementSaga';

import {
  watchHandleRectangleClick,
  watchHandleRectangleDraw,
  watchProcessRectangleData,
  watchRectDrag,
  watchRectAnchordrag,
  watchRectAnchorLeave
} from './GraphicsRectangle';

import {
  watchHandleTextClick,
  watchHandleTextDraw,
  watchUpdateText,
  watchProcessTextData,
  watchTextAnchordrag,
  watchTextAnchorLeave,
  watchTextDoubleClick,
  watchAddBorder,
  watchRemoveBorder,
  watchSettingsChange,
  wathTextDrag,
  watchEscPress
} from './GraphicsTextSaga';

import {
  watchHandleTriangleClick,
  watchHandleTriangleDraw,
  watchProcessTriangleData,
  watchTriangleDrag,
  watchTriAnchordrag,
  watchTriAnchorLeave
} from './GraphicsTriangle';

import {
  watchProcessAnnotationLayerData,
  watchHandleOnFooterAction,
  watchHandleRenameLayer,
  watchHandleRelocateLayer,
  watchUndoLayerDelete
} from './AnnotationLayerManagementSaga'

export const annotationWatchers = {
  watchHandleInitAnnotation,
  watchProcessAnnotationData,
  watchHandleAnnotationsOnChartResize,
  watchHandleClick,
  watchHandleMouseMove,
  watchHandleLineClick,
  watchHandleLineDraw,
  watchHandleDrag,
  watchHandleRectangleClick,
  watchHandleRectangleDraw,
  watchHandleEllipseClick,
  watchHandleEllipseDraw,
  watchHandleTriangleClick,
  watchHandleTriangleDraw,
  watchHandleMouseDown,
  watchProcessLineData,
  watchProcessEllipseData,
  watchProcessRectangleData,
  watchProcessTriangleData,
  watchSaveAnnotation,
  watchHandleChannelLineClick,
  watchHandleChannelLineDraw,
  watchProcessChannelLineData,
  watchHandleTextClick,
  watchHandleTextDraw,
  watchUpdateText,
  watchAddAlert,
  watchRemoveAlert,
  watchRemoveAlertRI,
  watchToggleAlertRI,
  watchHandleMeasurementClick,
  watchHandleMeasurementDraw,
  watchProcessMeasurementData,
  watchHandleFibonacciLineClick,
  watchHandleFibonacciLineDraw,
  watchHandleContexMenu,
  watchRemoveDrawing,
  watchDeleteAllAnnotations,
  watchProcessFibLineData,
  watchControlSelect,
  watchConfirmDeleteAll,
  watchProcessAnnotationLayerData,
  watchHandleOnFooterAction,
  watchHandleRenameLayer,
  watchHandleRelocateLayer,
  watchUndoDelete,
  watchoveFrontAnnotations,
  watchMoveBackAnnotations,
  watchUpdateRiPanelInfo,
  watchUndoLayerDelete,
  watchProcessTextData,
  watchTextAnchordrag,
  watchTextAnchorLeave,
  watchTextDoubleClick,
  watchAddBorder,
  watchRemoveBorder,
  watchSettingsChange,
  wathTextDrag,
  watchEscPress,
  watchRectDrag,
  watchEllipseDrag,
  watchTriangleDrag,
  watchRectAnchordrag,
  watchRectAnchorLeave,
  watchEllipAnchordrag,
  watchEllipAnchorLeave,
  watchTriAnchordrag,
  watchTriAnchorLeave
};