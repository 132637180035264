import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import { RecentListDropDown } from '../Presentational';
import PortalContainer from "PortalContainer";
import KeyCodes from "../../../../../Constants/KeyCodes";
import ReactDOM from "react-dom";
import LocalizationStore from '../../../../../Stores/Localization/LocalizationStore';
import { hideError, addSymbolfetchResults, hideDropdown, addSymbolOnEnter } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import { PrintMode } from '../../../../../print/printmode';
import ErrorBoundary from 'ErrorBoundary';
import CompareTabType from "CompareTabType";

class SymbolSearchBox extends PureComponent {
    constructor(props) {
        super(props);
        this.hideErrorMsg = this.hideErrorMsg.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
        clearTimeout(this.autoHideTimeOut);
    }
    hideErrorMsg() {
        this.props.hideError();
    }
    autoHideErrorMsg() {
        clearTimeout(this.autoHideTimeOut);
        this.autoHideTimeOut = setTimeout(() => {
            if (this.props.hasErrors) {
                this.hideErrorMsg();
            }
        }, 10 * 1000);
    }
    onChange = (event) => {
        this.props.onChange(event.target.value);
    }
    onOutsideClick = () => {
        this.props.onOutsideClick();
    }
    getErrorMsg(errorMsg) {
        return (
            <div className="auto-search-error compare-symbol-search">
                {errorMsg}
                <span className="icn-close" onClick={this.props.hideError}></span>
            </div>
        );
    }
    handleKeyPress = (event) => {
        let charCode = (event.which) ? event.which : event.keyCode;
        let compNameEdit = document.getElementsByClassName('comp-name-edit');
        let datepickerVisible = document.getElementsByClassName('DateTimePicker');
        let changePassWordOpened = document.getElementsByClassName('changePassWordOpened');
        let liqudityRatings = document.getElementsByClassName("liquidity-filter-setting");
        if (compNameEdit.length > 0 || datepickerVisible.length > 0 || changePassWordOpened.length > 0 || liqudityRatings.length > 0) {
            return
        }
        if (event.altKey || event.ctrlKey || event.shiftKey || document.body.classList.contains("changePassWordOpened") ||
            document.body.classList.contains("model-open") ||
            document.body.classList.contains("bootstrap-datetimepicker-widget") ||
            document.body.classList.contains("comparisonCalendarOpened")
        ) {
            return;
        }
        let excludeKeyCodes = [
            KeyCodes.TAB,
            KeyCodes.SHIFT,
            KeyCodes.CTRL,
            KeyCodes.ALT,
            KeyCodes.LEFT_META,
            KeyCodes.CAPS_LOCK,
            KeyCodes.PAGE_UP,
            KeyCodes.PAGE_DOWN,
            KeyCodes.LEFT_ARROW,
            KeyCodes.UP_ARROW,
            KeyCodes.RIGHT_ARROW,
            KeyCodes.DOWN_ARROW,
            KeyCodes.SPACE,
            KeyCodes.ENTER
        ];
        if (document.activeElement !== ReactDOM.findDOMNode(this.input) && excludeKeyCodes.indexOf(charCode) == -1 && (charCode < 112 || charCode > 130)) {
            ReactDOM.findDOMNode(this.input).focus();
        }

        const { activeIndex } = this.props;
        if (event.keyCode === KeyCodes.ENTER && activeIndex < 0) {
            this.props.onEnter(event.target.value);
            this.input.blur();
            this.props.onOutsideClick();
        }
    }
    render() {
        let symbolText;
        const { searchtext, searchResult, isOpen, resultAvail, activeIndex, enterPressed, hasErrors, errorMsg } = this.props;
        if (enterPressed) {
            symbolText = '';
        } else {
            symbolText = searchtext;
        }
        if (hasErrors) {
            this.autoHideErrorMsg();
        }

        const printToggle = PrintMode.printing ? " p-on" : "";
        const inputBoxStyle= PrintMode.printing ? "display:none" :"";
        return (
            <div className={"add-symbol-field-hold " + printToggle}>
                {hasErrors && <PortalContainer>{this.getErrorMsg(errorMsg)}</PortalContainer>}
                {/* <input placeholder={LocalizationStore.getTranslatedData('CCG_AddSymbol', 'Add symbol')} type="text" ref={(ref) => this.input = ref} onKeyPress={this.handleKeyPress} onChange={this.onChange} value={symbolText} className="add-symbol-field" /> */}
                {!PrintMode.printing &&  <input placeholder={LocalizationStore.getTranslatedData('CCG_AddSymbol', 'Add symbol')} type="text" ref={(ref) => this.input = ref} onKeyPress={this.handleKeyPress} onChange={this.onChange} value={symbolText} className="add-symbol-field" />}
                <div className="clearfix"></div>
                {!PrintMode.printing && symbolText && !hasErrors && isOpen && <PortalContainer> <ErrorBoundary><RecentListDropDown activeIndex={activeIndex} outsideClickDocument={this.onOutsideClick} searchResult={searchResult} resultAvail={resultAvail} /></ErrorBoundary></PortalContainer>}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => ({
    onChange: (value) => dispatch(addSymbolfetchResults(value)),
    onOutsideClick: () => dispatch(hideDropdown()),
    // handleKeyPress: (index) => dispatch(hideArrowPress(index)),
    hideError: () => dispatch(hideError()),
    onEnter: (value) => dispatch(addSymbolOnEnter(value)),
})
const mapStateToProps = (state) => {
    const selectedTab = state.navCompareReducer.selectedTab;
    const addSymbolData = state.comparisonAndRotationMenuPanel.AddSymbol;
    const { searchtext, searchResult, isOpen, resultAvail, activeIndex, enterPressed, hasErrors, errorMsg } = selectedTab === CompareTabType.ComparisonGraph ? addSymbolData.ComparisonMenuStates : addSymbolData.RotationMenuStates;
    
    return { searchtext, searchResult, isOpen, resultAvail, activeIndex, enterPressed, hasErrors, errorMsg };
}
export default connect(mapStateToProps, mapDispatchToProps)(SymbolSearchBox)
