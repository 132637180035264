import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ContextMenu } from '../Presentational';
import { contextItemClick, closeContextMenu,compGroupsChartContextItemClick } from 'Actions/ComparisonAndRotationMenuActions';
import { setAddListName } from '../../../../../Actions/ComparisonAndRotationMenuActions';
import CompareTabType from "CompareTabType";
import { CompareMenuType } from '../../../../../Constants/CompareTabType';

class CompContextMenu extends Component {
    render() {
        const { pos, isShowContextMenu, contextMenuData, isCompGroupsMenu } = this.props;
        const menuListclassName = isCompGroupsMenu ? "context-menu-inner list-contex  small-normal" : "contextmenu-comperison context-menu-inner list-contex small-normal"
        return (
            isShowContextMenu && (<ContextMenu style={{ left: pos.x, top: pos.y }} outsideClickDocument={this.props.closeContextMenu}>
                <div className="context-menu dropdown">
                    <ul className={menuListclassName}>
                        {contextMenuData.map((item, i) =>
                            (item.isShow || isCompGroupsMenu) && <li key={i} onClick={() => {
                                if (item.actionType == "delete") {
                                    this.props.setAddListName("");
                                }
                                if(isCompGroupsMenu)
                                this.props.compGroupsChartContextItemClick(item.actionType)
                                else
                                this.props.contextItemClick(item.actionType)
                            }}><a>{item.displayText}</a></li>
                        )}
                    </ul>
                </div>
            </ContextMenu>)
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedTab } = state.navCompareReducer;
    const isCompGroupsMenu = selectedTab === CompareTabType.ComparisonGraph ? state.comparisonAndRotationMenuPanel.MenuTabReducer.ComparisonMenuTab === CompareMenuType.Groups ? true : false : false
    const symbolsMenu = state.comparisonAndRotationMenuPanel.SymbolsPanelReducer;
    const { contextManu } = selectedTab === CompareTabType.ComparisonGraph ? symbolsMenu.ComparisonMenuStates : symbolsMenu.RotationMenuStates;

    return ({ ...contextManu, isCompGroupsMenu });
}

const mapDispatchToProps = (dispatch) => ({
    contextItemClick: (type) => dispatch(contextItemClick(type)),
    closeContextMenu: () => dispatch(closeContextMenu()),
    setAddListName: (lName) => dispatch(setAddListName(lName)),
    compGroupsChartContextItemClick: (type) => dispatch(compGroupsChartContextItemClick(type))

});


export default connect(mapStateToProps, mapDispatchToProps)(CompContextMenu);