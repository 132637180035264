import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetFundManagementDataDetailRequest extends BaseServiceRequest {
    constructor(contactId, isEntitled = false) {
        super();
        super.addRequestUri("fundmanagementdetail.pb");
        super.addRequestParameter("cid", contactId.toString());
        super.addRequestParameter("ame", isEntitled ? "1" : "0");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "FundManagementTeamDataDetailResponse";
    }
}

export default GetFundManagementDataDetailRequest;
