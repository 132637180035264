import React from 'react';
import { UpdateSharedData } from "../../../Actions/ShareAction";
import { ShareAccessType, ShareActionConstants } from "../../../Constants/ShareConstants";
import LocalizationStore from 'LocalizationStore';
import { find } from "underscore";

const AccessLevel = ({ accessLvl, recipientid, isShared, isICS, sharedData, sharedAction, type, targetDataName }) => {
    const handleChangeAccessLevel = () => {        
        if (recipientid === 0) {
            sharedData.map((rowData) => {
                if (rowData.isICS || rowData.nodeType == 4) {
                    rowData.accessLvl = ShareAccessType.SHARE_READONLY;
                }
                else {
                    rowData.accessLvl = accessLvl;
                }
                rowData.isShared = true;
                rowData.isModified = true;
            }
            );
        }
        else {
            const updatedRow = find(sharedData, { recipientId: recipientid });

            if (accessLvl === ShareAccessType.SHARE_READONLY) {
                if(updatedRow.nodeType != 4){
                    updatedRow.accessLvl = ShareAccessType.SHARE_READANDEDIT;
                }
            }
            else {
                updatedRow.accessLvl = ShareAccessType.SHARE_READONLY;
            }
            updatedRow.isModified = true;
        }
        UpdateSharedData(sharedData);
    }
    let isArr = false;
    if(targetDataName)
    {
         var arr = targetDataName.split(",");
         isArr = arr.length > 1 ? true : false;
    }
    const accessLevelDescr =
    {
        [ShareAccessType.SHARE_NOACCESS]: LocalizationStore.getTranslatedData('ri_noAccess','No Access'),
        [ShareAccessType.SHARE_READONLY]: isArr ? LocalizationStore.getTranslatedData('ri_readonly','Read Only') : LocalizationStore.getTranslatedData('ri_id40','Read Only'),
        [ShareAccessType.SHARE_READANDEDIT]: isArr ? LocalizationStore.getTranslatedData('ri_readedit','Read & Edit') : LocalizationStore.getTranslatedData('ri_id39','Read & Edit'),
        [ShareAccessType.SHARE_MIXEDMODE]: LocalizationStore.getTranslatedData('ri_mixed','Mixed')
    }[accessLvl]

    return (
        <span className="actions access" data-test-id="main-div">
            {
                !isShared || type == 4 || sharedAction === ShareActionConstants.SHARE_COLUMN_SET || isICS
                    ?
                    <span className='read-only'>{accessLevelDescr}</span>
                    :
                    isShared &&
                    <a className="link" onClick={handleChangeAccessLevel}>
                        {accessLevelDescr}
                    </a>
            }
        </span>
    )
}

export default AccessLevel;