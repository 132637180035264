import React, { Component } from 'react'
import PortalContainer from 'PortalContainer';
const cellWidth = require("text-width");

class Tooltip extends Component {
    constructor(props){
        super(props);
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseMove = this.mouseMove.bind(this);
        this.mouseOut = this.mouseOut.bind(this);
        this.tooltipRef = null;
        this.componentRef = null;
        this.componentWidth = 0;
        this.state = {
            position : {
                top: 0,
                left: 0
            },
            space: 25,
            text: '',
            fixed: true,
            placement: 'bottom',
            show: false
        }
    }
    componentDidMount(){
        this.setState({...this.state, ...this.props})
    }
    componentDidUpdate(){
        const componentDimension = this.componentRef.getBoundingClientRect();
        this.componentWidth = componentDimension.width;
    }
    getPosition(position, placement, space){
        const pos = {top: 0, left: 0};
        switch(placement){
            case "top":
                pos.top = position.top - space;
                pos.left = position.left;
                break;
            case "bottom":
                pos.top = position.top + space;
                pos.left = position.left;
                break;
            case "left":
                pos.top = position.top;
                pos.left = position.left - space;
                break;
            case "right":
                pos.top = position.top;
                pos.left = position.left + space;
                break;
            default:
                pos.top = position.top + space;
                pos.left = position.left;
                break;
        }

        if((pos.left + this.componentWidth) > window.innerWidth){
            pos.left = window.innerWidth - 600;
        }
        return pos;
        // if(this.state.fixed){
        //     let position = this.componentRef.getBoundingClientRect();
        //     let toolTipDimension = this.tooltipRef.getBoundingClientRect();
        //     switch(placement){
        //         case "top":
        //             pos.top = position.top - this.state.space;
        //             pos.left = position.left - position.width/2 - toolTipDimension.width/2
        //             return pos;
        //         case "bottom":
        //             pos.top = position.top + this.state.space;
        //             pos.left = position.left - position.width/2 - toolTipDimension.width/2
        //             return pos;
        //         case "left":
        //             pos.top = position.top + position.height/2;
        //             pos.left = position.left - this.state.space;
        //             return pos;
        //         case "right":
        //             pos.top = position.top + position.height/2;
        //             pos.left = position.left - this.state.space;
        //             return pos;
        //         default:
        //             pos.top = position.top + this.state.space;
        //             pos.left = position.left - position.width/2 - toolTipDimension.width/2
        //             return pos;
        //     }
        // }
        
    }

    getStyle({top, left}){
        return { position: 'fixed', top, left, zIndex: 99999, display: this.state.show ? "block" : "none" }
    }
    mouseEnter(e){
        let show = false;
        const width = cellWidth(this.props.text, {
            family: 'calibri',
            size: 20
        });
        if(this.componentWidth < width){
            show = true;
        }
        let position = this.getPosition({ top: e.clientY, left: e.clientX}, this.state.placement, this.state.space); 
       this.setState({...this.state, position, text: this.props.text, show});
    }
    mouseMove(e){
        let position = this.getPosition({ top: e.clientY, left: e.clientX}, this.state.placement, this.state.space);
        this.setState({...this.state,  position});
    }
    mouseOut(){
        this.setState({...this.state, position: {top:0, left: 0}, text: '', show: false});
    }
  render() {
      const { children, tooltipClass, text } = this.props
        return <>
            {React.Children.map(children, (child) => React.cloneElement(child, {
                            onMouseEnter:this.mouseEnter, 
                            ref: (refs)=> this.componentRef = refs,
                            onMouseMove: this.state.fixed ? null : this.mouseMove,
                            onMouseOut: this.mouseOut, 
                            }))}
                
                <PortalContainer>
                    <span 
                        ref={(ref)=> this.tooltipRef = ref}
                        className= {tooltipClass}
                        style={this.getStyle(this.state.position)}
                        >{ text }</span>
                </PortalContainer>  
            </>
  }
}

export default Tooltip