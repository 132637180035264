import React, { Component } from 'react';
import TadPoleContainer from './TadPoleContainer';
import LocalizationStore from 'LocalizationStore';
import BrowserUtil from "BrowserUtil";
import StringUtil from '../../../../../Utils/StringUtil';
import ConsoleStore from "ConsoleStore";
import RotationGraphContextMenu from './RotationGraphContextMenu';
import ErrorBoundary from 'ErrorBoundary';
import RotationArithmeticScale from "Utils/Scales/RotationArithmeticScale.js";
import { flatten, min, max } from "underscore";
import ScalesHelper from "Utils/Scales/ScalesHelper.js";
import { PrintMode } from '../../../../../print/printmode';
import SettingsStore from '../../../../../Stores/ConsoleWindow/Settings/SettingsStore';

let root = document.documentElement;

class SvgZoomPan extends Component {

  componentWillMount() {
    this.setPercentageScale();
    this.auto = LocalizationStore.getTranslatedData("CRG_AUTO", "AUTO");
    this.enablePan = 1; // 1 or 0: enable or disable panning (default enabled)
    this.enableZoom = 1; // 1 or 0: enable or disable zooming (default enabled)
    this.enableDrag = 0; // 1 or 0: enable or disable dragging (default disabled)
    this.xScale = new RotationArithmeticScale();
    this.yScale = new RotationArithmeticScale();
    this.zoomButtonToggle = false;
  }

  componentDidMount() {
    this.containerDiv = document.getElementById("thediv");
    this.zoomInButton = document.getElementById('cg-zoomin-btn');
    this.zoomOutButton = document.getElementById('cg-zoomout-btn');
    this.zoomResetAuto = document.getElementById('cg-reset-btn');
    root = this.svgDiv;
    this.svgRoot = this.viewPortElm;
    this.outPerformingImprove = document.getElementById("out-performing-improve");
    this.outPerformingWeake = document.getElementById("out-performing-weake");
    this.underPerformingImprove = document.getElementById("under-performing-improve");
    this.underPerformingWeake = document.getElementById("under-performing-weake");

    if (BrowserUtil.getBrowserName() === 'Firefox')
      this.containerDiv.addEventListener("DOMMouseScroll", this.handleOnWheel, false);
    else
      this.containerDiv.addEventListener("mousewheel", this.handleOnWheel, false);

    this.zoomInButton.addEventListener('click', this.handleZoomInButton, false);
    this.zoomOutButton.addEventListener('click', this.handleZoomOutButton, false);
    this.zoomResetAuto.addEventListener('click', this.handleResetButton, false);
    this.centerRotationBBox = this.containerDiv.getBoundingClientRect();

    if (PrintMode.printing) {
      this.updateZoomMatrix();
    }
    else {
      this.updateDragPosToPrintSettings();
    }
    this.updateCenter();
    //this.updateZoomPan();
    if (document.getElementById('cg-reset-btn').getAttribute("data-rb") === "AUTO") {
      this.disbleZoomAndPan(false);
    }
    else {
      this.disbleZoomAndPan(true);
    }

    if (StringUtil.isEmpty(this.previouseCTM))
      this.previouseCTM = this.svgDiv.getCTM();
  }

  componentDidUpdate() {
    if (!StringUtil.isEmpty(this.props.quadGraphResults) && !StringUtil.isEmpty(this.props.quadGraphResults.quadGraphItemsData2))
      this.updateCenter();
  }

  subscribeEvents() {
    document.addEventListener("mousemove", this.handleMouseMove, false);
    document.addEventListener("mouseup", this.handleMouseUp, false);
  };

  unsubscribeEvents() {
    document.removeEventListener("mousemove", this.handleMouseMove, false);
    document.removeEventListener("mouseup", this.handleMouseUp, false);
  };

  handleZoomInButton = (e) => {
    this.handleMouseWheel(e, true, null);
  };

  handleZoomOutButton = (e) => {
    this.handleMouseWheel(e, null, true);
  };

  handleResetButton = (e) => {
    this.zoomButtonToggle = !this.zoomButtonToggle;
    this.backToCenter(e);
  };

  handleOnWheel = (e) => {
    this.handleMouseWheel(e, null, null)
  };

  componentWillUnmount() {
    this.zoomInButton.removeEventListener('click', this.handleZoomInButton, false);
    this.zoomOutButton.removeEventListener('click', this.handleZoomOutButton, false);
    this.zoomResetAuto.removeEventListener('click', this.handleResetButton, false);
    if (navigator.userAgent.toLowerCase().indexOf('webkit') >= 0)
      this.containerDiv.removeEventListener("mousewheel", this.handleOnWheel, false); // Chrome/Safari
    else this.containerDiv.removeEventListener("DOMMouseScroll", this.handleOnWheel, false); // Others
  }

  setPercentageScale() {
    this.scaleFactor = PrintMode.printing ? 1.045 : 1.065;
    this.perCentageScale = [];
    this.percentageIndexArray = [];

    let percentageIndex = 100;
    let scale = 1;
    while (percentageIndex <= 1000) {
      let roundedScale = scale.toFixed(6);
      this.perCentageScale.push(roundedScale);
      this.percentageIndexArray.push(percentageIndex + '%');
      scale *= this.scaleFactor;
      percentageIndex += 25;
    }
  };

  getPercentageIndex(num) {
    let closetNumber = this.perCentageScale.reduce((prev, curr) =>
      (Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev)
    );

    return this.perCentageScale.indexOf(closetNumber) != -1 ? this.percentageIndexArray[this.perCentageScale.indexOf(closetNumber)] : 100 + '%';

  };

  disbleZoomAndPan(isEnable = true) {
    setTimeout(() => {
      if (!isEnable) {
        this.enableZoom = 0;
        this.enablePan = 0;
        this.enableDrag = 0;
        this.zoomOutButton.classList.add('disable');
        this.zoomInButton.classList.add('disable');
        this.zoomResetAuto.classList.add('active');
        this.zoomResetAuto.innerHTML = this.auto;
        this.zoomResetAuto.setAttribute("data-rb", "AUTO");
        // this.props.autoFitToggle_OnClick("AUTO");
      }
      else {
        this.enableZoom = 1;
        this.enablePan = 1;
        this.enableDrag = 0;
        this.zoomOutButton.classList.remove('disable');
        this.zoomInButton.classList.remove('disable');
        this.zoomResetAuto.classList.remove('active');
        // this.props.autoFitToggle_OnClick();
      }

    }, 0)
  };

  updateZoomMatrix() {
    if (this.props.zoomPercentageValue !== "AUTO") {
      const printSettings = SettingsStore.getConsoleSettings().printSettings;

      const g = this.getRoot(this.svgDiv);
      let p = root.createSVGPoint();
      const center = this.getCenterGlobe();
      p.x = center.x - this.centerRotationBBox.left;
      p.y = center.y - this.centerRotationBBox.top;
      p = p.matrixTransform(g.getCTM().inverse());

      const powerValue = this.props.zoomPercentageValue.replace("%", '') / 25 - 4;
      const z = Math.pow(this.scaleFactor.toFixed(6), powerValue);

      let k = root.createSVGMatrix().translate(p.x, p.y).scale(z, z).translate(-p.x, -p.y);
      if (printSettings.dragX !== 0 || printSettings.dragY !== 0) {
        k = k.translate(printSettings.dragX, printSettings.dragY);
      }

      this.setCTM(g, k);
    }
  }

  updateDragPosToPrintSettings(dragX = 0, dragY = 0) {
    const printSettings = SettingsStore.getConsoleSettings().printSettings;
    printSettings.dragX = dragX;
    printSettings.dragY = dragY;
    SettingsStore.saveSettings();
  }

  updateCenter() {
    //if (!isEmpty(this.props.quadGraphResults) && !isEmpty(this.props.quadGraphResults.quadGraphItemsData2)) {
    setTimeout(() => {
      if (this.globeGroup && this.svgDiv) {
        let ctm = this.globeGroup.getCTM();
        if (this.props.zoomPercentageValue !== "AUTO") {
          this.zoomResetAuto.innerHTML = this.getPercentageIndex(ctm.a.toFixed(6));
          this.zoomResetAuto.setAttribute("data-rb", this.getPercentageIndex(ctm.a.toFixed(6)));
        }
        let globeBBox = this.globeGroup.getBBox();
        let pt = this.svgDiv.createSVGPoint();
        pt.x = globeBBox.x + globeBBox.width / 2;
        pt.y = globeBBox.y + globeBBox.height / 2;
        pt = pt.matrixTransform(ctm);

        this.center = { x: Math.floor(pt.x), y: Math.floor(pt.y) };
        if (this.l1 && this.l2) {
          this.visibilitySet();
          this.l1.setAttribute("x1", this.center.x);
          this.l1.setAttribute("x2", this.center.x);
          this.l2.setAttribute("y1", this.center.y);
          this.l2.setAttribute("y2", this.center.y);
        }
      }
    }, 0);

    // }
  };

  visibilitySet() {
    if (this.center.x > this.props.width - 210 || this.center.y < 60) {
      if (!this.outPerformingImprove.classList.contains('not-visible-div')) {
        this.outPerformingImprove.classList.add('not-visible-div');
      }
    }
    else {
      if (this.outPerformingImprove.classList.contains('not-visible-div')) {
        this.outPerformingImprove.classList.remove('not-visible-div');
      }
    }

    if (this.center.x > this.props.width - 210 || this.center.y > this.props.height - 60) {
      if (!this.outPerformingWeake.classList.contains('not-visible-div')) {
        this.outPerformingWeake.classList.add('not-visible-div');
      }
    }
    else {
      if (this.outPerformingWeake.classList.contains('not-visible-div')) {
        this.outPerformingWeake.classList.remove('not-visible-div');
      }
    }

    if (this.center.x < 210 || this.center.y < 60) {
      if (!this.underPerformingImprove.classList.contains('not-visible-div')) {
        this.underPerformingImprove.classList.add('not-visible-div');
      }
    }
    else {
      if (this.underPerformingImprove.classList.contains('not-visible-div')) {
        this.underPerformingImprove.classList.remove('not-visible-div');
      }
    }

    if (this.center.x < 210 || this.center.y > this.props.height - 60) {
      if (!this.underPerformingWeake.classList.contains('not-visible-div')) {
        this.underPerformingWeake.classList.add('not-visible-div');
      }
    }
    else {
      if (this.underPerformingWeake.classList.contains('not-visible-div')) {
        this.underPerformingWeake.classList.remove('not-visible-div');
      }
    }
  };

  /**
   * Retrieves the root element for SVG manipulation. The element is then cached into the this.svgRoot global variable.
   */
  getRoot() {
    //root
    if (this.svgRoot === null) {
      // let r = this.viewPortElm ? this.viewPortElm : root.documentElement, t = r;
      // while (t != root) {
      //   if (t.getAttribute("viewBox")) {
      //     this.setCTM(r, t.getCTM());

      //     t.removeAttribute("viewBox");
      //   }

      //   t = t.parentNode;
      // }

      this.svgRoot = this.viewPortElm;
    }

    return this.svgRoot;
  };

  /**
   * Instance an SVGPoint object with given event coordinates.
   */
  getEventPoint(evt) {
    let p = root.createSVGPoint();

    p.x = evt.clientX - this.centerRotationBBox.left;
    p.y = evt.clientY - this.centerRotationBBox.top;

    return p;
  };

  /**
   * Sets the current transform matrix of an element.
   */
  setCTM(element, matrix, Auto = false) {
    const s = "matrix(" + matrix.a + "," + matrix.b + "," + matrix.c + "," + matrix.d + "," + matrix.e + "," + matrix.f + ")";
    const zoomPercentage = Auto ? "AUTO" : this.getPercentageIndex(matrix.a.toFixed(6));
    this.props.updateZoomPercentage(zoomPercentage, s);
    this.props.updateContextMenuVisibility(false);
    // this.setState({ matrix: s, zoomPercentageValue: zoomPercentage, contextMenuVisible: false });
    // RotationGraphStore.saveRoatationGraphData(zoomPercentage, s);
    // element.setAttribute("transform", s);
  };

  /**
   * Dumps a matrix to a string (useful for debug).
   */
  dumpMatrix(matrix) {
    let s = "[ " + matrix.a + ", " + matrix.c + ", " + matrix.e + "\n  " + matrix.b + ", " + matrix.d + ", " + matrix.f + "\n  0, 0, 1 ]";

    return s;
  };

  /**
   * Sets attributes of an element.
   */
  setAttributes(element, attributes) {
    for (let i in attributes)
      element.setAttributeNS(null, i, attributes[i]);
  };

  /**
   * Handle mouse wheel event.
   */
  handleMouseWheel(evt, zoomIn = null, zoomOut = null) {
    if (this.globeGroup && this.svgDiv) {
      if (!this.enableZoom)
        return;

      if (evt.preventDefault)
        evt.preventDefault();

      evt.returnValue = false;

      let svgDoc = this.svgDiv;

      let delta;
      if (zoomIn === null && zoomOut === null) {
        if (evt.wheelDelta)
          delta = evt.wheelDelta / 360; // Chrome/Safari
        else
          delta = evt.detail / -9; // Mozilla

      }
      else if (zoomIn != null) {
        delta = 10;
      }
      else {
        delta = -10;
      }

      if (delta === 0 || delta === -0)
        return;

      if (Math.sign(delta) != -1 && this.zoomResetAuto.innerHTML === "1000%") return;
      else if (Math.sign(delta) === -1 && this.zoomResetAuto.innerHTML === "100%") return;

      let z = Math.sign(delta) === -1 ? Number(1 / this.scaleFactor) : this.scaleFactor;

      let g = this.getRoot(svgDoc);

      let p = this.getEventPoint(evt);

      if (zoomIn === null && zoomOut === null) {
        p = p.matrixTransform(g.getCTM().inverse());
      }
      else {
        let center = this.getCenterGlobe();
        p.x = center.x - this.centerRotationBBox.left;
        p.y = center.y - this.centerRotationBBox.top;
        p = p.matrixTransform(g.getCTM().inverse());
      }

      z = z.toFixed(6);

      // Compute new scale matrix in current mouse position
      let k;
      if (zoomIn === null && zoomOut === null) {
        k = root.createSVGMatrix().translate(p.x, p.y).scale(z, z).translate(-p.x, -p.y);
      }
      else {
        k = root.createSVGMatrix().translate(p.x, p.y).scale(z, z).translate(-p.x, -p.y);
      }

      if (g.getCTM().multiply(k).a.toFixed(6) <= 1.0) {
        this.backToCenter(evt, true);
        return;
      }


      this.setCTM(g, g.getCTM().multiply(k));

      if (this.stateTf === "undefined") {
        this.stateTf = g.getCTM().inverse();
        this.stateTf = this.stateTf.multiply(k.inverse());
      }

      this.updateCenter();
      //Log Usage
      ConsoleStore.trackUsage('RotationGraphZoomed');
    }
  };

  backToCenter = (evt, zoom = false) => {
    if (this.globeGroup && this.svgDiv) {
      if (evt.preventDefault)
        evt.preventDefault();

      evt.returnValue = false;

      let svgDoc = this.svgDiv;
      this.getCenterGlobe();
      let g = this.getRoot(svgDoc);

      if (!zoom && evt.target.getAttribute("data-rb") === "AUTO") {
        this.setCTM(g, this.previouseCTM);
        evt.target.innerHTML = this.getPercentageIndex(this.previouseCTM.a.toFixed(6));
        evt.target.setAttribute("data-rb", this.getPercentageIndex(this.previouseCTM.a.toFixed(6)));
        this.disbleZoomAndPan(true);
        this.updateCenter();
        return;
      }

      this.previouseCTM = g.getCTM();

      let p = this.getEventPoint(evt);
      p.x = this.props.width / 2;
      p.y = this.props.height / 2;
      p = p.matrixTransform(g.getCTM().inverse());

      let k = root.createSVGMatrix().translate(p.x, p.y).scale(1, 1).translate(-p.x, -p.y);
      if (!zoom) this.setCTM(g, k, true);
      else this.setCTM(g, k);

      if (this.stateTf === "undefined") {
        this.stateTf = g.getCTM().inverse();
        this.stateTf = this.stateTf.multiply(k.inverse());
      }
      if (!zoom) {
        evt.target.innerHTML = this.auto;
        evt.target.setAttribute("data-rb", "AUTO");
        this.disbleZoomAndPan(false);
      }

      this.updateCenter();
      this.updateDragPosToPrintSettings();
    }
  };

  /**
   * Handle mouse move event.
   */
  handleMouseMove = (evt) => {
    if (this.zoomPanState === 'pan' || this.zoomPanState === 'drag') {
      if (evt.preventDefault)
        evt.preventDefault();

      evt.stopPropagation();
      evt.returnValue = false;

      let svgDoc = this.svgDiv;

      let g = this.getRoot(svgDoc);

      if (this.zoomPanState === 'pan' && this.enablePan) {
        // Pan mode
        let p = this.getEventPoint(evt).matrixTransform(this.stateTf);

        this.setCTM(g, this.stateTf.inverse().translate(p.x - this.stateOrigin.x, p.y - this.stateOrigin.y));
      } else if (this.zoomPanState === 'drag' && this.enableDrag) {
        // Drag mode
        let p = this.getEventPoint(evt).matrixTransform(g.getCTM().inverse());

        this.setCTM(this.stateTarget, root.createSVGMatrix().translate(p.x - this.stateOrigin.x, p.y - this.stateOrigin.y).multiply(g.getCTM().inverse()).multiply(this.stateTarget.getCTM()));

        this.stateOrigin = p;

      }
      this.updateCenter();
    }
  };

  /**
   * Handle click event.
   */
  handleMouseDown = (evt) => {
    if (this.globeGroup && this.svgDiv) {
      if (evt.button === 2) {
        return;
      }

      if (this.props.zoomPercentageValue === "100%") return;

      if (evt.preventDefault)
        evt.preventDefault();
      evt.stopPropagation();

      if (this.enablePan) {
        document.body.classList.add("grabbable");
      }

      // evt.returnValue = false;

      this.subscribeEvents();

      let svgDoc = evt.target.ownerDocument;

      let g = this.getRoot(svgDoc);

      if (
        evt.target.tagName === "svg"
        || !this.enableDrag // Pan anyway when drag is disabled and the user clicked on an element
      ) {
        // Pan mode
        this.zoomPanState = 'pan';

        this.stateTf = g.getCTM().inverse();

        this.stateOrigin = this.getEventPoint(evt).matrixTransform(this.stateTf);
      } else {
        // Drag mode
        this.zoomPanState = 'drag';

        this.stateTarget = evt.target;

        this.stateTf = g.getCTM().inverse();

        this.stateOrigin = this.getEventPoint(evt).matrixTransform(this.stateTf);
      }
    }
  };

  /**
   * Handle mouse button release event.
   */
  handleMouseUp = (evt) => {
    if (this.globeGroup && this.svgDiv) {
      if (this.zoomPanState === 'pan' || this.zoomPanState === 'drag') {
        if (evt.preventDefault)
          evt.preventDefault();

        evt.stopPropagation();

        if (this.enablePan) {
          document.body.classList.remove("grabbable");
        }

        this.unsubscribeEvents();

        //let svgDoc = evt.target.ownerDocument;

        if (this.zoomPanState === 'pan' || this.zoomPanState === 'drag') {
          // Quit pan mode
          this.zoomPanState = '';
        }
        this.updateCenter();

        const svgRect = this.svgDiv.getBoundingClientRect();
        const originX = this.l1.getBBox();
        const originY = this.l2.getBBox();

        const dragX = (originX.x - Math.round(svgRect.width / 2 + 1)) / 4;
        const dragY = (originY.y - Math.round(svgRect.height / 2 + 1)) / 3;
        this.updateDragPosToPrintSettings(dragX, dragY);
      }
    }
  };

  getCenterGlobe() {
    if (this.globeGroup) {
      let bbox = this.globeGroup.getBoundingClientRect();
      return {
        x: bbox.left + bbox.width / 2,
        y: bbox.top + bbox.height / 2
      };
    }
  };

  initScale() {
    const { quadGraphResults, zoomPercentageValue } = this.props;
    const { xMax, xMin, yMax, yMin, circleRad } = quadGraphResults;
  
    try {
      if (zoomPercentageValue) {
        if (zoomPercentageValue !== "AUTO") {
          if (!StringUtil.isEmpty(quadGraphResults)) {
            //if (!isEmpty(this.props.quadGraphResults.quadGraphItemsData2)) {
            const xminvalue = Number(xMin.toFixed(6));
            const xmaxvalue = Number(xMax.toFixed(6));
            const yminvalue = Number(yMin.toFixed(6));
            const ymaxvalue = Number(yMax.toFixed(6));
  
  
            // if (Math.abs(xminvalue - xmaxvalue) < Number.EPSILON ||
            //     Math.sbs(yminvalue - ymaxvalue) < Number.EPSILON)
            // {
            //     rbLoadingGrid.Visibility = Visibility.Collapsed;
            //     Cursor = Cursors.Arrow;
            //     return;
            // }
            const xinterval = ScalesHelper.getInterval((xminvalue), (xmaxvalue), this.scaleWidth, 10);
            const yinterval = ScalesHelper.getInterval((yminvalue), (ymaxvalue), this.scaleHeight, 10);
  
            if (Math.abs(xmaxvalue - xminvalue) < Number.EPSILON || Math.abs(ymaxvalue - yminvalue) < Number.EPSILON) {
              return;
            }
  
            this.xScale.initXScale(xmaxvalue, xminvalue, xinterval, this.scaleWidth);
            this.yScale.initYScale(ymaxvalue, yminvalue, yinterval, this.scaleHeight);
            return (2 * (this.yScale.computeY(circleRad.toFixed(4)) - this.yScale.computeY(0)));
            //}
          }
        }
        else {
          if (!StringUtil.isEmpty(quadGraphResults) && !StringUtil.isEmpty(quadGraphResults.quadGraphItemsData2)) {
            const quadeItemSource = quadGraphResults.quadGraphItemsData2;
            const qData = [];
            quadeItemSource.map((p) => { if (p.isVisible && p.points !== null) qData.push(p.points) });
  
            if (qData.length <= 0) {
              return (2 * (this.yScale.computeY(circleRad.toFixed(4)) - this.yScale.computeY(0)));
            }
            const xValues = flatten(qData.map((o) => o.map((j) => Number(j.xValue))));
            const yValues = flatten(qData.map((o) => o.map((j) => Number(j.yValue))));
  
            let xminvalue = min(xValues);
            let xmaxvalue = max(xValues);
            let yminvalue = min(yValues);
            let ymaxvalue = max(yValues);
  
  
            const xvalue = Math.max(Math.abs(xminvalue), xmaxvalue);
            const yvalue = Math.max(Math.abs(yminvalue), ymaxvalue);
  
            xminvalue = -xvalue;
            yminvalue = -yvalue;
            xmaxvalue = xvalue;
            ymaxvalue = yvalue;
  
            xminvalue = Math.min(xminvalue, yminvalue);
            yminvalue = Math.min(xminvalue, yminvalue);
            xmaxvalue = Math.max(xmaxvalue, ymaxvalue);
            ymaxvalue = Math.max(xmaxvalue, ymaxvalue);
  
  
            xmaxvalue = (xmaxvalue + (xmaxvalue * 0.08));
            xminvalue = (xminvalue + (xminvalue * 0.08));
            ymaxvalue = (ymaxvalue + (ymaxvalue * 0.08));
            yminvalue = (yminvalue + (yminvalue * 0.08));
  
            if (Math.abs(xmaxvalue - xminvalue) < Number.EPSILON || Math.abs(ymaxvalue - yminvalue) < Number.EPSILON) {
              return;
            }
            const xinterval = ScalesHelper.getInterval((xminvalue), (xmaxvalue), this.scaleWidth, 10);
            const yinterval = ScalesHelper.getInterval((yminvalue), (ymaxvalue), this.scaleHeight, 10);
  
            this.xScale.initXScale(xmaxvalue, xminvalue, xinterval, this.scaleWidth);
            this.yScale.initYScale(ymaxvalue, yminvalue, yinterval, this.scaleHeight);
            return (2 * (this.yScale.computeY(circleRad.toFixed(4)) - this.yScale.computeY(0)));
          }
        }
      }
    }
    catch (error) {
      console.log(`Error occurs in  CenterRotationSaga.js, initScale ${error}`);
    }
  }

  showContextMenu = (e, id = "svg-id", item = null) => {
    e.persist();
    const contextMenuObj = {
      selectedGraphItem: 'graphLine',
      xPos: e.clientX,
      yPos: e.clientY,
      item: item,
      evt: e,
      disable: false
    }
    if (id === "svg-id") {
      contextMenuObj.selectedGraphItem = 'svgElement';
      contextMenuObj.disable = !StringUtil.isEmpty(this.zoomResetAuto) && (this.zoomResetAuto.getAttribute("data-rb") === "AUTO" || this.zoomResetAuto.innerHTML === "100%") ? true : false
    }

    this.props.updateContextMenuVisibility(true);
    this.props.prepareContextMenuView(contextMenuObj);
    // setTimeout(() => this.contextMenuOpen = false, 500);
    this.props.stopISPlaying();

    e.preventDefault();
    e.stopPropagation();
  };

  getTadPoles() {
    const zoomValue = this.props.zoomPercentageValue && this.props.zoomPercentageValue !== "AUTO" ? parseInt(this.props.zoomPercentageValue.toString().split("%")[0]) : 100;
    if (!StringUtil.isEmpty(this.props.quadGraphResults.quadGraphItemsData2)) {
      return <TadPoleContainer key="tabPoleGraph" quadGraphResults={this.props.quadGraphResults} onContextMenuClick={this.showContextMenu} zoomValue={zoomValue} strokeWidthValue={zoomValue >= 500 ? 4 : 2} scaleHeight={this.scaleHeight} xScale={this.xScale} yScale={this.yScale} zoomButtonToggle={this.zoomButtonToggle} />
    }
    return null;
  }

  render() {
    const { height, width, zoomMatrix } = this.props;
    this.scaleHeight = Math.min(width, height);
    this.scaleWidth = this.scaleHeight;
    const circleRad = this.initScale();
    const tadPole = !isNaN(circleRad) ? this.getTadPoles() : null;

    return (
      <div className="rotation-graph-main-control">
         <ErrorBoundary><RotationGraphContextMenu backToCenter={(evt, zoom) => this.backToCenter(evt, zoom)} /></ErrorBoundary>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="svg-id"
          className="rotation-graph-main-control"
          ref={(ref) => { this.svgDiv = ref }}
          onMouseDown={this.handleMouseDown}
          onContextMenu={(e) => this.showContextMenu(e, "svg-id")}
        >
          <g id="viewport" key={"viewport"} ref={(ref) => this.viewPortElm = ref} transform={zoomMatrix}>
            <g ref={(ref) => { this.globeGroup = ref }}>
              {!isNaN(circleRad) ?
                <svg preserveAspectRatio="none" width={circleRad} height={circleRad} x={((width - circleRad) / 2)} y={((height - circleRad) / 2)} viewBox="0 0 389 389"> <path d="M196.5093,310.6143C133.8553,310.3923,83.9133,259.6773,83.6613,197.0323L43.9623,162.5443C43.9623,162.5443,5.53029999999998,196.8743,5.53029999999998,197.0283L5.52429999999998,197.0323L5.53029999999998,197.0323C5.78029999999998,302.6993,91.2973,388.3233,196.9203,388.7533L163.4363,349.7373" className="fill-dark" />
                  <path d="M310.6079,197.3086C310.3889,259.9626,259.1559,310.3626,196.5089,310.6146L162.0209,350.3126C162.0209,350.3126,196.3509,388.7466,196.5059,388.7466L196.5089,388.7526L196.5089,388.7466C302.1749,388.4966,387.7989,302.9786,388.2289,197.3556L349.2129,230.8396" className="fill-light" />
                  <path d="M83.6587,197.1689C83.8787,134.5159,135.1107,84.1159,197.7577,83.8649L232.2457,44.1659C232.2457,44.1659,197.9157,5.7319,197.7607,5.7319L197.7577,5.7269L197.7577,5.7319C92.0917,5.9819,5.9607,91.4079,5.5307,197.0319L45.0537,163.6379" className="fill-light" />
                  <path d="M197.2427,83.8174C259.8967,84.0384,310.3517,135.2004,310.6037,197.8444L349.7917,231.8864C349.7917,231.8864,388.2217,197.5574,388.2217,197.4034L388.2287,197.3994L388.2217,197.3994C387.9727,91.7334,302.4547,6.10840000000002,196.8317,5.67840000000002L230.3167,44.6944" className="fill-dark" /> </svg> : null}
            </g>
          </g>
          <g key="axisLinePole" id="axisLine">
            <line ref={(line) => { this.l1 = line }} x1="50%" y1="0%" x2="50%" y2="100%" className="fill-line-axis" style={{ strokeWidth: "3", width: "100%", height: "100%" }} />
            <line ref={(line) => { this.l2 = line }} x1="0%" y1="50%" x2="100%" y2="50%" className="fill-line-axis" style={{ strokeWidth: "3", width: "100%", height: "100%" }} />
          </g>
          <g transform={zoomMatrix}>
            <svg id="graphLines" viewBox={`0 0 ${this.scaleWidth} ${this.scaleHeight}`} >
              {tadPole}
              <rect width="100%" height="100%" style={{ fill: `none`, stroke: `none` }} />
            </svg>
          </g>
        </svg>
      </div>
    );
  }
}

export default SvgZoomPan;