import Serializable from "Serializable";
import { ExportTypes } from "../../../../../Constants/ExportExcelConstants";

class ExportListSettings extends Serializable {
  constructor() {
    super();
    this.IsShowExportTypeDialog = true;
    this.ExportType = ExportTypes.Excel;
    this.IsShowGicsDialog = true;
  }

  onSeralisedInfoRecieved(info) {
        this.IsShowExportTypeDialog = info.IsShowExportTypeDialog !== undefined ? info.IsShowExportTypeDialog : true;
        this.ExportType = info.ExportType !== undefined ? info.ExportType : ExportTypes.Excel;
        this.IsShowGicsDialog = info.IsShowGicsDialog !== undefined ? info.IsShowGicsDialog : true;
    }

  getDataObject() {
    return {
      IsShowExportTypeDialog: this.IsShowExportTypeDialog,
      ExportType: this.ExportType,
      IsShowGicsDialog: this.IsShowGicsDialog,
    };
  }

  getObjectMapKey() {
    return "ExportListSettings";
  }

  getDefaultExportListSettings() {
    this.IsShowExportTypeDialog = true;
    this.ExportType = ExportTypes.Excel;
    this.IsShowGicsDialog = true;
    return this;
  }
}

export default ExportListSettings;
