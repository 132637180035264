import React from "react";
import { find, findIndex } from "underscore";
import StringUtil from "StringUtil";
import ExtremeDataValue from "ExtremeDataValue";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";

export default class DgTabHoldingsHeaderRow extends React.Component {
    renderHtmlForColumnSortOrder = (columnName) => {
        const obj = find(this.props.orderSeqArr,  (obj) => obj.columnName === columnName);
        const index = findIndex(this.props.orderSeqArr, { columnName: columnName });
        let str = "";

        if (StringUtil.isEmpty(obj))
            str = "";
        else if (obj.order === "asc")
            str = <div className="order-box"><span className='order-icon glyphicon glyphicon-triangle-top'></span><span className='order-counter'>{index + 1}</span></div>;
        else
            str = <div className="order-box"><span className='order-icon glyphicon glyphicon-triangle-bottom'></span><span className='order-counter'>{index + 1}</span></div>;

        return str;
    }


    render() {
        const { props } = this;
        const c1SortText = this.renderHtmlForColumnSortOrder("Symbol");
        const c2SortText = this.renderHtmlForColumnSortOrder("StockName");
        const c3SortText = props.isColumnRsChanged === true ? this.renderHtmlForColumnSortOrder("RS") : this.renderHtmlForColumnSortOrder("YTD"); 
        const c4SortText = this.renderHtmlForColumnSortOrder("PctOfPort");
        const c5SortText = props.isColumnSharesChanged === true ? this.renderHtmlForColumnSortOrder("SharesHeld") : this.renderHtmlForColumnSortOrder("SharesHeld-Shares1QAgo");
        const c6SortText = props.isColumnSharesChanged === true ? this.renderHtmlForColumnSortOrder("Shares1QAgo") : this.renderHtmlForColumnSortOrder("Shares1QAgo-Shares2QAgo");
        const c7SortText = props.isColumnSharesChanged === true ? this.renderHtmlForColumnSortOrder("Shares2QAgo") : this.renderHtmlForColumnSortOrder("Shares2QAgo-Shares3QAgo");
        const fromHoldingsTab = (props.activeTab && props.activeTab === "DgHoldings") ? true : false;
        
        return (
            <div key={`header_${  props.i}`} className="report-sector">
                <div className="report-heading">
                    {props.currentTab === 0 && props.currentSubTab === 0 ? props.value.reportName : props.value.reportName + ExtremeDataValue.numberWithCommas(props.value.count)}
                </div>
                <div className="report-table">
                    <div className="report-header-group">
                        <div className="report-row">
                            <div className={`report-head${fromHoldingsTab ? " holding-symbol" : ""} ${  c1SortText === '' ? '' : ' sorted'}`} onClick={(event) => props.onColumnHeaderClicked(event, 1)}>Symbol
                                {c1SortText}
                            </div>
                            <div className={`report-head${fromHoldingsTab ? " holding-name" : " ownership-name"} ${  c2SortText === '' ? '' : ' sorted'}`} onClick={(event) => props.onColumnHeaderClicked(event, 2)}><div className="report-naminghead">Name</div>
                                {c2SortText}
                            </div>
                            <div className={`report-head${fromHoldingsTab ? " holding-rs" : " ownership-rs"} ${  c3SortText === '' ? '' : ' sorted'}`} onClick={(event) => props.onColumnHeaderClicked(event, 3)}>{props.isColumnRsChanged === true ? "RS" : "YTD"}
                                {c3SortText}
                            </div>                            
                            <div className={`report-head${fromHoldingsTab ? " holding-port" : " ownership-port"} ${  c4SortText === '' ? '' : ' sorted'}`} onClick={(event) => props.onColumnHeaderClicked(event, 4)}>% of Port
                                {c4SortText}
                            </div>
                            <div className={`report-head partation${fromHoldingsTab ? " holding-recent" : " ownership-recent"} ${  c5SortText === '' ? '' : ' sorted'}`} onClick={(event) => props.onColumnHeaderClicked(event, 5)}>Recent
                                {c5SortText}
                            </div>
                            <div className={`report-head${fromHoldingsTab ? " holding-shares" : " ownership-shares"} ${  c6SortText === '' ? '' : ' sorted'}`} onClick={(event) => props.onColumnHeaderClicked(event, 6)}>
                                <div className="shares-divider">{props.isColumnSharesChanged === true ? LocalizationStore.getTranslatedData("ri_id104_02","Shares (000)") : LocalizationStore.getTranslatedData("OS_Shares3","∆Shares (000)")} </div>
                                <div className="shares-divider"> 1 Qtr Ago</div>
                                {c6SortText}
                            </div>
                            <div className={`report-head${fromHoldingsTab ? " holding-qtr" : " ownership-qtr"} ${  c7SortText === '' ? '' : ' sorted'}`} onClick={(event) => props.onColumnHeaderClicked(event, 7)}>2 Qtrs Ago
                                {c7SortText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}