import SaveSettingsRequest from "./Requests/SaveSettingsRequest.js";
import GetUserSettingRequest from "./Requests/GetUserSettingRequest.js";
import BaseServiceApi from 'BaseServiceApi'
import LocalizationStore from "../Stores/Localization/LocalizationStore";
class SettingApi {
  getUserSettingForUserId() {
    let request = new GetUserSettingRequest();
    return BaseServiceApi.processServiceCall(request);
  }

  saveUserSettings(data) {
    let settingName = "default";
    const version = LocalizationStore.getTranslatedData("_Version", "").replace(/[^\d.]/g,"");
    let request = new SaveSettingsRequest(data, settingName, version);
    return BaseServiceApi.processServiceCall(request);
  }
}
export default SettingApi;
