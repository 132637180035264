import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetAllNotificationsRequest extends BaseServiceRequest {

    constructor() {
        super();
        super.addRequestUri("getall2.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.NOTIFICATION;
    }

    getMessageName() {
        return "NotificationResponseData";
    }
}

export default GetAllNotificationsRequest;
