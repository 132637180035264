import { connect } from "react-redux";
import DogEar from "./DogEar.jsx";
import GraphType from '../../../../../Constants/GraphType.js';
import LocalizationStore from "LocalizationStore";
import OwnershipView from "../../../../../Constants/OwnershipView.js";
import PeriodicityHelper from "PeriodicityHelper";
import SettingsStore from "SettingsStore";
import { showHideDogEar } from "../../../../../Actions/OwnerShipBlockActions";
import StringUtil from "StringUtil";
import TimeTrackingWindow from "TimeTrackingWindow";
import UserInfoUtil from "UserInfoUtil";
import React, { Component } from "react";

class DataRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: props.row,
      rowHeight: props.RowHeight,
      nodeWidth: props.nodeWidth,
      width: props.width,
      hover: false,
      rowIndex: props.rowIndex,
      dogEar: null
    };
    this.showDogEar = this.showDogEar.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.width !== undefined) {
      this.setState({ width: nextProps.width });
    }
    if (nextProps.row !== undefined) {
      this.setState({ row: nextProps.row });
    }
    if(nextProps.Symbol !== this.props.Symbol){
      this.setState({ dogEar: undefined });
    }
  }
  getCellValues() {
    const row = this.state.row;
    if (row === undefined || row === null || this.state === undefined || this.state.width === 0.0) {
      return null;
    }
    const consoleSettings = SettingsStore.getConsoleSettings();
    const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
    const periodicity = PeriodicityHelper.mapMajorPeriodicities(tabDataGraphSettings.Periodicity)
    const cells = [];
    const cellItems = row;
    const length = cellItems.length;
    const widthAdjust = cellItems[0].XPoint * this.state.nodeWidth;
    const width = this.state.width + widthAdjust;
    for (let cellIndex = 0; cellIndex < length; cellIndex++) {
      const cellItem = cellItems[cellIndex];
      const xAxis = width - (cellItem.XPoint * this.state.nodeWidth) - 3;
      if (xAxis < 0 || cellItem.DisplayFlag !== true) {
        continue;
      }
      const nextCell = cellItems[cellIndex + 1];
      const nextXAxis = nextCell ? (width - (nextCell.XPoint * this.state.nodeWidth)) : width;
      let xpoint = nextXAxis + 1;
      let paddingText = 3;
      let cWidth = xAxis - nextXAxis + paddingText;
      if (cellItem.CarryForward && cellItem.CarryForward === 1) {
        let i = 1;
        let newCWidth = 0;
        while (cellItem.QuarterKey === cellItems[cellIndex + i].QuarterKey && cellItems[cellIndex + i].DisplayFlag !== true && cellItems[cellIndex + i + 1]) {
          const currentCell = cellItems[cellIndex + i + 1];
          const currentXAxis = width - (currentCell.XPoint * this.state.nodeWidth);
          newCWidth = width - (cellItems[cellIndex + 1].XPoint * this.state.nodeWidth) - currentXAxis;
          i++;
        }
        xpoint -= newCWidth;
        cWidth += newCWidth;
        const CarryOverText = periodicity !== GraphType.Daily ? this.CarryOverTextTranslation("Data carried over from previous quarter") : this.CarryOverTextTranslation("Data carried over from previous month");
        const isWithIndex = cellIndex + 1 < cellItems.length;
        if (isWithIndex && xpoint > 0) {
          const cHeight = this.state.rowHeight - 2;
          paddingText = 5;
          cells.push(<DogEar onShowDogEar={(dogEar, isDogEar) => this.showDogEar(dogEar, isDogEar)} height={cHeight} text={CarryOverText} width={cWidth} x={xpoint} key={`dogEar${cellIndex}`} />);
        }
      }
      let cellValue = "";
      switch (this.props.OwnershipViewType) {
        case OwnershipView.HoldingRank:
          cellValue = cellItem.NoOfStocksHeld;
          break;
        case OwnershipView.SharesHeld:
          cellValue = cellItem.TurnOverValue;
          break;
        case OwnershipView.SharesChange:
          cellValue = cellItem.FormattedSharesChanged;
          break;
        default:
          cellValue = "";
          break;
      }
      if (cellValue && cellValue !== "") {
        cells.push(<text key={cellIndex} className={cellItem.cellClassName} style={{ textAnchor: "end", pointerEvents: "none", fill: this.getCellTextColor(cellItem) }} x={xAxis} y={(this.state.rowHeight) - 3}>{cellValue}</text>);
      }

    }
    return cells;
  }

  /*when color is changed, fetch the latest color from ThemeHelper.
  color logic is from OwnershipBlockStore.setColorInfo*/
  getCellTextColor(cellItem) {
    let color = '';
    switch (cellItem.PivotTypeEnum) {
      case 2:
        color = this.props.posDTColor;
        break;
      case 3:
        color = this.props.negDTColor;
        break;
      default:
        color = cellItem.color;
        break;
    }
    return color;
  }


  CarryOverTextTranslation(DogEarContent) {
    if (UserInfoUtil.getCurrentLangCode() === "zh-cn") {
      let carryOverText = "";
      const contents = DogEarContent.split('\n');
      for (let i = 0; i < contents.length; i++) {
        const content = contents[i];
        if (StringUtil.isEmpty(content)) { continue; }
        if (i > 1) {
          carryOverText += `\n \n${this.DogEarContentTranslation(content)}`;
        } else {
          carryOverText += this.DogEarContentTranslation(content);
        }
      }
      return carryOverText;
    } else {
      return DogEarContent;
    }
  }

  DogEarContentTranslation(content) {
    switch (content) {
      case "Pretax non-reoccurring items are included":
        return LocalizationStore.getTranslatedData("fb_hoverpretax", content);
      case "Calculation based on a negative number in the latest period or same quarter of previous year":
        return LocalizationStore.getTranslatedData("fb_hover", content);
      case "Indicates Semi-Annual Data":
        return LocalizationStore.getTranslatedData("fb_indicates_1", content);
      case "Indicates Annual Data":
        return LocalizationStore.getTranslatedData("fb_indicates_2", content);
      default:
        return content;
    }
  }

  getDecimalPlaces(decimal) {
    let returnValue = "0.";
    for (let index = 0; index < decimal; index++) {
      returnValue += "0";
    }
    return returnValue;
  }

  onHover(hover) {
    this.setState({ hover: hover });
  }

  syncHover(index, hover) {
    this.props.syncHover(index, hover);
  }
  reDraw() {
    this.setState({});
  }
  showDogEar(dogEarObj, isDogEar = false) {
    this.setState({ dogEar: dogEarObj });
    // this.props.showDogEar(isDogEar);
    this.props.showHideDogEar(isDogEar)
  }
  getDogEarContent() {
    const properLeftStyle = this.state.dogEar?.x + 7; //  alignment of tooltip to avoid overlap from Dogear icon
    return (<div className="ownership-block-dogear" style={{ left: properLeftStyle }}>
      <div className="ownership-block-dogear-hold">
        <div className="ownership-block-dogear-content">
          {this.state.dogEar.text}
        </div>
      </div>
    </div>);
  }

  render() {
    // if (ConsoleStore.trackTime) {
    //   ConsoleStore.resetTimer();
    //   ConsoleStore.onLoadEndTime = new Date().getTime();
    //   ConsoleStore.startTimer();
    // }
    TimeTrackingWindow.trackChartLoadingTime();
    const cells = this.getCellValues();
    const clsHover = this.state.hover ? "ownersip-block-row hover-on-row" : "ownersip-block-row";
    return (
      <div className="ownersip-block-row" onMouseOver={() => this.syncHover(this.state.rowIndex, true)}
        onFocus={() => this.syncHover(this.state.rowIndex, true)}
        onMouseLeave={() => this.syncHover(this.state.rowIndex, false)}
        style={{ height: this.state.rowHeight }}>
        <div className={clsHover} style={{ height: this.state.rowHeight + 1 }}></div>
        <svg id="row" className="ownersip-block-row-item">
          {cells}
        </svg>
        {this.state.dogEar && this.getDogEarContent()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { RowHeight, OwnershipViewType, nodeWidth } = state.DatagraphReducers.ownershipBlockReducer;
  const { posDTColor, negDTColor } = state.appColor;
  const { Symbol } = state.DatagraphReducers.PriceChartReducer
  return { RowHeight, OwnershipViewType, nodeWidth, posDTColor, negDTColor, Symbol }
}
const mapDispatchToProps = (dispatch) => ({
  showHideDogEar: (isDogEar) => dispatch(showHideDogEar(isDogEar))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DataRow)
