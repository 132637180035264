import React, { Component, PureComponent } from 'react';
import { connect } from 'react-redux';
import { ToolTip } from '../Presentational';
import cellWidth from 'text-width';
import { CompareMenuType } from "CompareTabType";

class CompToolTip extends Component {
    render() {
        const { pos, isShowToolTip, toolTipText } = this.props;
        const width = cellWidth(toolTipText, {
            family: 'calibri',
            size: 14
        });
        return (
            isShowToolTip ? (<ToolTip posX={pos.x} posY={pos.y} width={width}>
                <ToolTipTextDiv toolTipText={toolTipText} />
            </ToolTip>) : null
        );
    }
}

class ToolTipTextDiv extends PureComponent {
    render() {
        return (<div className="tt-box tt-yellow">
                    <span dangerouslySetInnerHTML={{ __html: this.props.toolTipText }} />
                    {/* {this.props.toolTipText} */}
        </div>);
    }
}

const mapStateToProps = (state) => {
    const { selectedMenuTab, SymbolsData, GroupsData } = state.comparisonGraph.compareChart;
    const { toolTip } = selectedMenuTab == CompareMenuType.Symbols ? SymbolsData : GroupsData;

    return ({ ...toolTip });
}


export default connect(mapStateToProps)(CompToolTip);