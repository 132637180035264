import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetKpiItemsBySymbolRequest extends BaseServiceRequest {
    constructor(symbol,cp,kpiId='',targetcurr='') {
        super();
        super.addRequestUri("getkpidata.pb");
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("cp", cp.toString());
        if (kpiId) {
            super.addRequestParameter("k", kpiId);
        }
        if (targetcurr) {
            super.addRequestParameter("tgc", targetcurr);
        }
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "KpiItemDataResponse";
    }
}

export default GetKpiItemsBySymbolRequest;
