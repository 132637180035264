import React, {Component, Fragment } from "react";
import moment from "moment";
import GridActions from "GridActions";
import ThemeHelper from "ThemeHelper";
import { connect } from "react-redux";
import { handleSymbolChange, updateFlag, updateNupStory } from "../../../../Actions/NupViewActions";
import Tooltip from "./Tooltip";
import AppColorStore from "../../../../Stores/AppColor/AppColorStore";

class Header extends Component {
    constructor(props){
        super(props);
        this.toggleDisplay = this.toggleDisplay.bind(this);
        this.updateGlobalFlag = this.updateGlobalFlag.bind(this);
        this.colorChanged = this.colorChanged.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState){
        if(this.props.selectedIndexs !== nextProps.selectedIndexs && this.props.showStory === nextProps.showStory){
            return false;
        }
        return true;
    }

    componentDidMount(){
        AppColorStore.addChangeListener(this.colorChanged);
    }

    componentWillUnmount(){
        AppColorStore.removeChangeListener(this.colorChanged);
    }

    colorChanged(){
        setTimeout(()=>{
            this.props.item.pctChangeColor = this.props.item.priceChange < 0 ? ThemeHelper.getThemedBrush("negativeDataText") : ThemeHelper.getThemedBrush("positiveDataText");
            this.forceUpdate();
        }, 0)
    }
    toggleDisplay() {
        this.props.updateNupStory(!this.props.showStory);
    }

    showItem = (item) => {
        if (this.props.showStory) {
            return (<Tooltip text= {item.Description} tooltipClass= "several-view-tooltip nup-paragraph" placement = "bottom" space = {20} fixed = {true}><p>{item.Description}</p></Tooltip>); // style={{ color: textColor }}
        } else {
            return (<>{this.fundamentals(item.fundamentals)}</>); // style={{ color: textColor }}
        }
    }

    fundamentals (items) {
        let numOfCols = 5;
        if(this.props.width < 158)
            numOfCols = 2;
        else if( this.props.width < 330)
            numOfCols = 3;  
        else if (this.props.width < 480)
            numOfCols = 4;  
        return <table>
            <tbody>
                <tr>
                    {
                        items.slice(0, numOfCols).map((item, index)=>
                        <Fragment key={index}>
                            <td>{item.text}</td>
                            <td>{item.value}</td>
                        </Fragment>)
                    }
                </tr>
                <tr>
                    {
                        items.slice(5, 5 + numOfCols).map((item, index)=>
                        <Fragment key={index}>
                            <td>{item.text}</td>
                            <td>{item.value}</td>
                        </Fragment>)
                    }
                </tr>
            </tbody>
        </table>
        
    }

    updateGlobalFlag(e) {
        e.stopPropagation();
        let selectedIndex = []
        if(this.props.selectedIndexs.includes(this.props.selectedIndex[0]*this.props.cols + this.props.selectedIndex[1])){
            selectedIndex = this.props.selectedIndexs;
        }
        let symbolInfo = this.props.item.SymbolInfo;
        let snapshotDt = null;
          if (symbolInfo.LastTradeDate1) snapshotDt = moment(symbolInfo.LastTradeDate).format('M/D/YYYY').toString() + ';';
          else snapshotDt = ';';
        GridActions.updateGlobalFlag(symbolInfo.MsId.toString(), snapshotDt, this.props.isFlagged, 0, 1, selectedIndex);
        this.props.updateFlag( this.props.selectedIndex, !this.props.isFlagged);
      }

    render(){
        const { item, isResizing} = this.props;
        const descriptionEmpty = item.Description == null || item.Description == " " || isResizing;
        const backGroundColor = item.isTriggeredInstrument ? "Orange" : "Transparent";
        return (<>
                <div className="nup-box-head" style={{ background: backGroundColor }}>
                    <div className="symbol-n-p-and-l">
                        <span className="symbol" onClick={() => this.props.handleSymbolChange(item.symbolData)}>{item.Symbol}</span>  {/* style={{ cursor: 'pointer', color: textColor }} */}
                        <span className="p-and-l" style={{ color: item.pctChangeColor }}>{item.pctChange}</span>
                    </div>
                    <div className="title">
                        {this.props.showFlag && <span className={this.props.isFlagged ? "shape-flag active" : "shape-flag" } onClick={this.updateGlobalFlag}></span>}
                        <Tooltip text = {item.CompanyName} tooltipClass= "several-view-tooltip" placement = "bottom" space = {15} fixed = {true}><h5>{item.CompanyName}</h5></Tooltip>
                    </div>
                </div>
                {item.showFundamnetals && !descriptionEmpty &&
                <div className="nup-box-infos" onClick={this.toggleDisplay}>
                    {this.showItem(item)}
                </div>} 
            </>)
    }
}
const mapStateToProps = ({ listViewReducer }, props) => {
    const { NupViewReducer } = listViewReducer;
    const listData = NupViewReducer.listData[props.selectedIndex[0]][props.selectedIndex[1]];
    const item = listData.HeaderData;
    const isFlagged = listData.isFlagged
    const width = NupViewReducer.dimension[NupViewReducer.selected].width
    const showStory = NupViewReducer.selected % 2 === 0 ? NupViewReducer.showStory : props.selectedIndex[1] === 0;
    const showFlag = !(NupViewReducer.selected % 2 !== 0  && props.selectedIndex[1] !== 0)
    const selectedIndexs = NupViewReducer.selectedIndex;
    const isResizing = NupViewReducer.isResizing;
    return ({item, isFlagged, width, showStory, showFlag, selectedIndexs, cols: NupViewReducer.cols, isResizing})
}
const mapDispatchToProps = (dispatch) =>({
    handleSymbolChange: (item)=> dispatch(handleSymbolChange(item)),
    updateNupStory: (showStory) => dispatch(updateNupStory(showStory)),
    updateFlag: (selectedIndex, value)=> dispatch(updateFlag(selectedIndex, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);