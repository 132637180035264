import React, { PureComponent } from "react";
import {GeographyListView } from '../Presentational';
import {BreadCrumb} from '../Containers';

class GroupsPanelHeader extends PureComponent {

  render() {
    return (
      <React.Fragment>
        {/* <GeographyListView /> */}
        <BreadCrumb />
      </React.Fragment>
    )
  }
}


export default GroupsPanelHeader;
