import AlertConditionType from "../../Constants/AlertConditionType";
import { AlertConstants } from "../../Constants/AlertConstants";
import AlertsApi from "../../ServiceApi/Apis/AlertsApi";
import { alertsDataWorker } from "WebWorker";
import AlertSourceType from "../../Constants/AlertSourceType";
import AlertStore from "../../Stores/Alerts/AlertStore";
import BaseServiceApi from "BaseServiceApi";
import ConsoleActions from "../../Actions/ConsoleActions";
import ConsoleStore from "../../Stores/ConsoleWindow/ConsoleStore";
import { CorpEventsDialogConstants } from "../../Constants/CorpEventsDialogConstants";
import { CorporateEventsConst } from "../../Constants/PriceChartConstants";
import { CustomTiDialogConstant } from "../../Constants/CustomTiDialogConstants";
import { DataGraphConstants } from "../../Constants/DataGraphConstants";
import DatagraphHelper from "../../Utils/DatagraphHelper";
import DataGraphStore from "../../Stores/NavModules/NavDataGraph/DataGraphStore";
import { dispatch } from "../../Redux/dispatch";
import ExtremeDataValue from "../../Utils/ExtremeDataValue";
import { getCurrencyReducerState } from "../../Reducers/CurrencyConversionReducer/selectors";
import { getDatagraphStates } from "../../Reducers/NavDataGraph/TabDataGraph/selectors";
import LangType from "../../Constants/LangType";
import ListExplorerStore from "../../Stores/NavModules/NavList/Explorer/ListExplorerStore";
import LocalizationStore from "../../Stores/Localization/LocalizationStore";
import MiniListHelper from "../../Utils/MiniListHelper";
import MiniListStore from "../../Stores/NavModules/NavDataGraph/MiniList/MiniListStore";
import moment from "moment";
import ONeilViewStore from "../../Stores/NavModules/NavList/TabONeil/ONeilViewStore";
import PrefPeriodScaleConstants from "../../Constants/PrefPeriodScaleConstants";
import { prepareContextMenu } from "../../Actions/PricePanelActions";
import SettingsStore from "SettingsStore";
import SplitUtil from "../../Utils/SplitUtil";
import StringUtil from "../../Utils/StringUtil";
import SymbolType from "../../Constants/SymbolType";
import UserInfoUtil from "../../Utils/UserInfoUtil";
import _, { each, extend } from "underscore";
import { AlertPeriodicityTranslateHelper, AlertsDialogTranslateHelper, AlertTranslateHelper, CorpActionTranslateHelper, CorpEventsDialogTranslateHelper, IndicatorCommonTranslateHelper, PeriodicityTypeTranslateHelper } from "../../Utils/TranslateHelper";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { cancelConditionAlertDelete, closeAlertDialog, removeDataGraphAlert, updateAlertDataObject, handleHighlightAlert, updateDataGraphAlertStates, updatePriceAlertResponse, postAlertsDataWorkerAction, updateTriggeredAlertsList } from "../../Actions/AlertActions";
import { getAlertDailogStates, getDatagraphAlertStates } from "../../Reducers/Alert/selector";
import { removeLineAlertIcon, toggleAlertIconToggle } from "../../Actions/AnnotationActions";


const ListAlerts = BaseServiceApi.rayData["ListAlerts"];
const ListAlertStatusType = BaseServiceApi.rayData["ListAlertStatusType"];
const MovingAverageType = BaseServiceApi.rayData["MovingAverageType"];
const AlertType = BaseServiceApi.rayData["AlertType"];
let alertsDataWorkerObj = new alertsDataWorker();
const { ActionTypes } = AlertConstants;

function* createPriceAlert({ msid, targetPrice, currencyCode, note }) {
    try {
        if (!StringUtil.isEmpty(msid)) {
            const result = yield call(AlertsApi.createPriceAlert, msid, targetPrice, DataGraphStore.getCurrencyCode(currencyCode), note)
            if (!result.responseHeader.error) {
                yield fork(startAlertsDataWorker);
            }
            else if (result.responseHeader.error) {
                validateResponse(result);
            }
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, createPriceAlert, ${error}`);
    }

}

function* updateAlertRequest({ data }) {
    try {
        if (data.isTriggered > 0) {
            yield call(AlertsApi.dismissAlert, data.alertId);
            yield put(removeDataGraphAlert(data))
            yield call(createPriceAlert, { msid: data.msid, targetPrice: data.targetPrice, currencyCode: data.priceAlertData.currencyCode, note: data.note });
        }
        else {
            yield call(AlertsApi.updateAlertRequest, data);
            yield fork(startAlertsDataWorker);
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, updateAlertRequest`);
    }
}

function* deleteAlert({ alertObj }) {
    try {
        const { alertDataObj } = yield select(getAlertDailogStates);
        if (alertObj.isTriggered || (alertObj.MAAlertData && alertObj.MAAlertData.alert.isTriggered === 1)) {
            if (alertObj.isStockAlert) {
                yield call(AlertsApi.dismissStockAlert, alertObj.alertId, alertObj.StockAlertData.alertTypeId)
                yield put(removeDataGraphAlert(alertObj));
            }
            else {
                const result = yield call(AlertsApi.dismissAlert, alertObj.alertId);
                if (alertObj.TLAlertData && !result.responseHeader.error) {
                    yield put(toggleAlertIconToggle(alertObj.TLAlertData.annotationid, false));
                }
                yield call(startAlertsDataWorker)
                yield put(removeDataGraphAlert(alertObj))
            }
            //data.isActive = false;
        }
        else {
            const alertId = alertObj.length > 0 ? alertObj[0].alertId : alertObj.alertId;
            if (alertObj.isStockAlert) {
                const alerttypeId = alertObj.StockAlertData.alertTypeId;
                const result = yield call(AlertsApi.deleteStockAlert, alertId, alerttypeId);
                if (!result.responseHeader.error) {
                    yield call(_afterDelete, alertObj, alertDataObj);
                }

            }
            else {
                const result = yield call(AlertsApi.deleteAlert, alertId)
                if (alertObj.TLAlertData && !result.responseHeader.error) {
                    yield put(removeLineAlertIcon(alertObj.TLAlertData.annotationid));
                }
                if (!result.responseHeader.error) {
                    yield call(_afterDelete, alertObj, alertDataObj)
                }
            }
            yield put(handleHighlightAlert({ alertId: 0 }, 0, 0))
            yield put(updateAlertDataObject(alertDataObj, false))
            // if (data.AlertDataStructure.TLAlertData !== null)
            //     _navSymbolModel.States.ActiveChartPanelModel.ConsoleModel.RaiseResetTrendLineAlertFlagEvent(data.AlertDataStructure.TLAlertData.Annotationid);
        }
        if(alertObj.MAAlertData){
            const { SymbolInfo, majorPeriodicity, viewsSettings } = yield select(getDatagraphStates);
            const maSettings = viewsSettings.MASettings[majorPeriodicity];
            maSettings.forEach((maItem) => {
                if(maItem.ma === alertObj.MAAlertData.length && maItem.maType === getMAAlertType(alertObj.MAAlertData.maType) && SymbolInfo.MsId === alertObj.MAAlertData.msid){
                    maItem.AlertIsActive = false
                }
            });
        }
        yield put(prepareContextMenu())
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, deleteAlert, ${error}`, error);
    }

}

//optAlertRevisit
function* reactivateAlert({ alertObj }) {
    try {
        if (alertObj.isActive) {
            if (alertObj.isStockAlert) {
                const result = yield call(AlertsApi.dismissStockAlert, alertObj.alertId, alertObj.StockAlertData.alertTypeId)
                if (result) {
                    alertObj.isActive = false;
                }
            }
            else {
                const result = yield call(AlertsApi.dismissAlert, alertObj.alertId);
                yield put(removeDataGraphAlert(alertObj));
                if (alertObj.TLAlertData !== null && !result.responseHeader.error) {
                    dispatch(toggleAlertIconToggle(alertObj.TLAlertData.annotationid, false));
                }
                if (result) {
                    alertObj.isActive = false;
                }
            }
        }
        else {
            if (!alertObj.isAnotationDeleted) {
                if (alertObj.isStockAlert) {
                    const result = yield call(AlertsApi.reactivateStockAlert, alertObj.alertId, alertObj.StockAlertData.alertTypeId)
                    if (result) {
                        alertObj.isActive = true;
                    }
                    if (result.responseHeader.error) {
                        validateResponse(result);
                    }
                }
                else {
                    const result = yield call(AlertsApi.reactivate, alertObj.alertId)
                    if (alertObj.TLAlertData !== null && !result.responseHeader.error) {
                        yield put(toggleAlertIconToggle(alertObj.TLAlertData.annotationid, true));
                    }
                    if (result) {
                        alertObj.isActive = true;
                    }
                    if (result.responseHeader.error) {
                        validateResponse(result);
                    }
                }

                //region Usage tracking code

                //Task.Factory.StartNew(() => LogtrackingHelper.LogUsageTrackingHelper("AlertIsReactivated", string.Empty));

                //endregion
            }
        }
        const messageCenterData = AlertStore.getMessageCenterData()
        if(messageCenterData){
            const result = yield call(AlertsApi.getAllNotifications) 
            AlertStore.setMessageCenterData(result.notifications);
            yield call(alertWorkerCompleted)
        }else{
            yield call(alertWorkerCompleted)
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, reactivateAlert, ${error}`)
    }


}

function* alertWorkerCompleted(){
    const consoleStoreState = ConsoleStore.getStates();
    if(!consoleStoreState.onSwitchToSymbolTab){
        yield put(updateDataGraphAlertStates({ emitWorkerAction: true}))
        consoleStoreState.onSwitchToSymbolTab = false;
        yield call(startAlertsDataWorker);
    }
}
//optAlert
function* _afterDelete(alertDataStructure, alertDataObj) {
    markAsRead(alertDataStructure.NotificationId ? alertDataStructure.NotificationId : alertDataStructure.notificationId);
    yield put(removeDataGraphAlert(alertDataStructure))
    const { AlertDatasource } = yield select(getDatagraphAlertStates)
    removeAlertForDeleteAll(alertDataStructure, AlertDatasource, alertDataObj);
    // yield fork(startAlertsDataWorker)
}

function markAsRead(messageId) {
    const messageCenterData = AlertStore.getMessageCenterData()
    const message = messageCenterData.allNotifications?.find((m) =>
        m.userNotificationId === messageId
    );

    if (StringUtil.isEmpty(message) || message.hasBeenRead) {
        return;
    }
    else if (!StringUtil.isEmpty(message)) {
        message.hasBeenRead = true;
    }

    AlertsApi.markAsRead(messageId);
}

function removeAlertForDeleteAll(alert, AlertDatasource, alertDataObj) {
    let orCount = 0;
    let andCount = 0;
    const alertsAfterDelete = AlertDatasource.filter((item) => item !== alert);
    alertsAfterDelete.forEach((item) => {
        if (item.length > 0) {
            if (item[0].isTriggered !== 1) {
                andCount = andCount + 1;
            }
        } else {
            if (item.isTriggered !== 1) {
                orCount = orCount + 1;
            }
        }
    })
    if (orCount === 0 && andCount === 0) {
        alertDataObj.isActiveAlerts.isActiveOrAlert = 0;
        alertDataObj.isActiveAlerts.isActiveAndAlert = 0;
    } else if (orCount === 0) {
        alertDataObj.isActiveAlerts.isActiveOrAlert = 0;
    } else if (andCount === 0) {
        alertDataObj.isActiveAlerts.isActiveAndAlert = 0;
    }
}

function* getAlertsDataSource() {
    try {
        const { SymbolInfo } = yield select(getDatagraphStates);
        const { priceAlertResponseData, showActive } = yield select(getDatagraphAlertStates);
        if (SymbolInfo && priceAlertResponseData) {
            const { AlertDatasource, trendLineAlerts } = processData(priceAlertResponseData, showActive);
            const { isCurrencyConversionChecked, selectedCurrencyCode, defaultCurrency } = yield select(getCurrencyReducerState);
            const currencyStr = isCurrencyConversionChecked ? selectedCurrencyCode : defaultCurrency;
            let count = 0
            const riPanelAlertsList = [];
            AlertDatasource.forEach((alertsData) => {
                const alertDataObj = alertsData.alertId > 0 ? alertsData : alertsData.length > 0 ? alertsData[0] : alertsData;
                const alertDataObject =
                {
                    AlertDate: (alertsData.dateLastTriggered || (alertsData.length > 0 && alertsData[0].dateLastTriggered)) ? getAlertDate(moment(StringUtil.convertFromLongValueToInt(alertDataObj.dateLastTriggered))) : null,
                    UpdateDate: getAlertDate(moment(StringUtil.convertFromLongValueToInt(alertDataObj.dateLastUpdated))),
                    AlertName: getAlertName(alertsData, SymbolInfo, currencyStr),
                    AlertStatus: (alertsData.alertId > 0 && !StringUtil.isEmpty(alertDataObj.listName) || (alertsData.length > 0 && alertsData[0].StockAlertData && alertsData[0].StockAlertData.isStockAlert === false)) ? '' : (alertDataObj.isActive > 0 ? 'Active' : 'Inactive'),
                    IsActive: alertDataObj.isActive > 0,
                    IsTriggered: alertDataObj.isTriggered > 0,
                    IsAnotationDeleted: alertDataObj.TLAlertData && alertDataObj.TLAlertData.isAnnotationDeleted,
                    TriggerList: '',//TriggerControl,
                    DeleteCmd: '', //DeleteCommand,
                    EditCmd: '',//EditCommand,
                    ReactivateCmd: '', //ReactivateCommand,
                    EditVisible: isEditVisibleInRIPAnelTile(alertsData) ? 'Visible' : 'Collapsed',
                    AlertDataStructure: alertsData,
                    TextForeground: '',//ThemedBrushHelper.GetClonedThemedBrush("77CAFF-04478A"),
                    ETextForeground: '',//ThemedBrushHelper.GetClonedThemedBrush("77CAFF-04478A"),
                    IsStockAlert: alertDataObj.isStockAlert,
                    ConditionTypeId: alertsData.length > 0 ? AlertConditionType.And : AlertConditionType.Or,
                    note: alertDataObj.note ? alertDataObj.note : ''
                };

                if (alertsData.patternRecAlertData && UserInfoUtil.isPatternRecEntitlement(alertsData.patternRecAlertData.alert.alertTypeId)) {
                    alertDataObject.AlertStatus = "";
                }
                if (alertsData.StockAlertData) {
                    alertDataObject.AlertStatus = "";
                }
                if (showActive) {
                    if (alertDataObject.IsActive) {
                        alertDataObject.Even = count % 2 === 0;
                        alertDataObject.CellBackground = alertDataObject.Even ? "1E1E1F-DBDCDC" : "2C2C2C-E9E9E9";
                        riPanelAlertsList.push(alertDataObject);
                        count++;
                    }
                }
                else {
                    if (alertDataObject.IsTriggered) {
                        alertDataObject.Even = count % 2 === 0;
                        alertDataObject.CellBackground = alertDataObject.Even ? "1E1E1F-DBDCDC" : "2C2C2C-E9E9E9";
                        riPanelAlertsList.push(alertDataObject);
                        count++;
                    }
                }

            });
            const totalActAlrtStr = AlertTranslateHelper["TOTAL_ACTIVE_ALERT"];
            const remainingStr = AlertTranslateHelper["REMAINING"];
            const alertCount = ExtremeDataValue.ReplaceNumberWithCommas(priceAlertResponseData.alertCount);
            const totalCount = ExtremeDataValue.ReplaceNumberWithCommas(priceAlertResponseData.totalCount)
            const AlertsCountMessage = showActive ? `${alertCount} ${totalActAlrtStr} ${totalCount} ${remainingStr}` : '';
            yield put(updateDataGraphAlertStates({ AlertsCountMessage, riPanelAlertsList, AlertDatasource, trendLineAlerts, loading: false, Initialized: true }));
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, getAlertsDataSource, ${error}`, error)
    }

}

function isEditVisibleInRIPAnelTile(alertsData) {
    if (alertsData.priceAlertData !== null || alertsData.patternRecAlertData !== null || alertsData.length > 0) {
        return true;
    }
    else if (alertsData.StockAlertData && alertsData.StockAlertData.isStockAlert) {
        switch (alertsData.StockAlertData.alertTypeId) {
            case ListAlerts.Dailypricechangeexceeds:
            case ListAlerts.DailyVolumeRateexceeds:
            case ListAlerts.AverageDailyVolumecrosses:
            case ListAlerts.AverageDailyDlrVolumecrosses:
            case ListAlerts.DailyPricerisestowithinofpivot:
            case ListAlerts.WeeklyPricerisestowithinofpivot:
            case ListAlerts.Earningsreleaseiswithin:
                return true;
            default:
                return false;
        }
    }
    else {
        return false;
    }
}
function* getActiveStockAlerts() {
    const state = DataGraphStore.getState();
    const { SymbolInfo } = yield select(getDatagraphStates);
    const { alertDataObj } = yield select(getAlertDailogStates);
    const msid = SymbolInfo.MsId;
    const symbol = SymbolInfo.Symbol;
    const isMiniListPlay = MiniListStore.getMiniListPlayingStatus();
    if (!state.ActiveStockAlertRequestMSID || state.ActiveStockAlertRequestMSID !== msid) {
        state.ActiveStockAlertRequestMSID = msid;
        const result = yield call(AlertsApi.getActiveStockAlerts, msid)
        if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)) {
            if (result.responseHeader.error) {
                validateResponse(result);
            }
            else {
                alertDataObj.isActiveAlerts = result.activeAlertsData;
            }
        }
        yield put(updateAlertDataObject(alertDataObj, false));
    }
}
function* deleteAllActiveAlerts() {
    try {
        const { SymbolInfo } = yield select(getDatagraphStates);
        const { alertDataObj, conditionTypeId } = yield select(getAlertDailogStates);
        const result = yield call(AlertsApi.deleteAllActiveByMsid, SymbolInfo.MsId, conditionTypeId);
        if (result.responseHeader.error) {
            validateResponse(result);
        }
        else {
            if (conditionTypeId === AlertConditionType.Or) {
                alertDataObj.isActiveAlerts.isActiveOrAlert = 0;
            } else {
                alertDataObj.isActiveAlerts.isActiveAndAlert = 0;
            }
        }
        yield put(cancelConditionAlertDelete())
        yield put(updateAlertDataObject(alertDataObj, false));
        yield call(startAlertsDataWorker)
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, deleteAllActiveAlerts, ${error}`)
    }

}

function processData(alertList, showActive) {
    if (StringUtil.isEmpty(alertList)) {
        return;
    }

    const _combinedAlerts = [];
    const trendLineAlerts = [];
    alertList.priceAlerts.forEach((item) => {
        _combinedAlerts.push(convertPriceAlert(item));
    });

    alertList.movingAverageAlerts.forEach((item) => {
        _combinedAlerts.push(convertMAAlert(item));
    });

    alertList.trendlineAlerts.forEach((item) => {
        const trendLine = convertTLAAlert(item)
        trendLineAlerts.push(trendLine);
        _combinedAlerts.push(trendLine);
    });

    alertList.stockAlerts.forEach((item) => {
        _combinedAlerts.push(convertStockAlert(item));
    });

    alertList.andConditionAlerts.forEach((item) => {
        _combinedAlerts.push(convertAndConditionAlert(item));
    });
    const lastDate = showActive ? "dateLastUpdated" : "dateLastTriggered";
    const AlertDatasource = _combinedAlerts.filter((item) => item.length > 0 ? item[0][lastDate] : item[lastDate]).sort((itm, itm2) => moment(StringUtil.convertFromLongValueToInt(itm.length > 0 ? itm[0][lastDate] : itm[lastDate])).format("YYYYMMDDHHmmss") - moment(StringUtil.convertFromLongValueToInt(itm2.length > 0 ? itm2[0][lastDate] : itm2[lastDate])).format("YYYYMMDDHHmmss")).reverse();
    return { AlertDatasource, trendLineAlerts };
}

function convertPriceAlert(alertData) {
    return {
        msid: alertData.msid,
        targetPrice: alertData.targetPrice,
        alertId: alertData.alert.alertId,
        notificationId: alertData.alert.notificationId,
        isActive: alertData.alert.isActive,
        isTriggered: alertData.alert.isTriggered,
        dateLastUpdated: alertData.alert.dateLastUpdated,
        dateLastTriggered: alertData.alert.dateLastTriggered,
        priceAlertData: alertData,
        MAAlertData: null,
        TLAlertData: null,
        StockAlertData: null,
        patternRecAlertData: null,
        isStockAlert: 0,
        note: alertData.note,
        alertType: "priceAlerts"
    }
}

function convertMAAlert(alertData) {
    return {
        msid: alertData.msid,
        targetPrice: alertData.targetPrice,

        alertId: alertData.alert.alertId,
        isActive: alertData.alert.isActive,
        isTriggered: alertData.alert.isTriggered,
        notificationId: alertData.alert.notificationId,
        dateLastUpdated: alertData.alert.dateLastUpdated,
        dateLastTriggered: alertData.alert.dateLastTriggered,
        priceAlertData: null,
        MAAlertData: alertData,
        TLAlertData: null,
        patternRecAlertData: null,
        StockAlertData: null,
        isStockAlert: 0,
        maTypeDescription: getMAAlertType(alertData.maType),
        alertType: "movingAverageAlerts"
    }
}

function convertTLAAlert(alertData) {
    return {
        msid: alertData.msid,
        targetPrice: alertData.targetPrice,

        alertId: alertData.alert.alertId,
        isActive: alertData.alert.isActive,
        isTriggered: alertData.alert.isTriggered,
        notificationId: alertData.alert.notificationId,
        dateLastUpdated: alertData.alert.dateLastUpdated,
        dateLastTriggered: alertData.alert.dateLastTriggered,
        priceAlertData: null,
        MAAlertData: null,
        StockAlertData: null,
        TLAlertData: alertData,
        patternRecAlertData: null,
        isStockAlert: 0,
        alertType: "trendlineAlerts"
    }
}

function convertStockAlert(alertData) {
    return {
        alertId: alertData.alertId,
        isActive: alertData.isEnabled ? 1 : 0,
        isTriggered: alertData.isTriggered ? 1 : 0,
        notificationId: alertData.notificationId,
        dateLastUpdated: alertData.dateLastUpdated,
        dateLastTriggered: alertData.dateLastTriggered,
        isStockAlert: alertData.isStockAlert,
        priceAlertData: null,
        MAAlertData: null,
        TLAlertData: null,
        patternRecAlertData: null,
        StockAlertData: alertData,
        listName: !alertData.isStockAlert ? alertData.listName : null,
        geographyName: alertData.geographyName,
        columnSetName: alertData.columnSetName,
        alertType: "stockAlerts"
    }
}

function convertAndConditionAlert(alertData) {
    const alertObj = [];
    if (alertData.stockAlerts !== null && alertData.stockAlerts.length > 0) {
        alertData.stockAlerts.forEach((item) => {
            if (alertData.alert && item.isEnabled === true) {
                alertObj.push(
                    {
                        alertId: alertData.alert.alertId,
                        isActive: item.isEnabled && item.isStockAlert ? 1 : 0,
                        isTriggered: alertData.alert.isTriggered,
                        notificationId: alertData.alert.notificationId,
                        dateLastUpdated: item.dateLastUpdated,
                        dateLastTriggered: item.dateLastTriggered,
                        priceAlertData: null,
                        MAAlertData: null,
                        TLAlertData: null,
                        patternRecAlertData: null,
                        StockAlertData: item,
                        listName: !item.isStockAlert ? item.listName : null,
                        alertType: "andConditionAlerts"
                    }
                );
            } else if (alertData.alert && item.isTriggered === true) {
                alertObj.push(
                    {
                        alertId: alertData.alert.alertId,
                        isActive: item.isEnabled ? 1 : 0,
                        isTriggered: alertData.alert.isTriggered,
                        notificationId: alertData.alert.notificationId,
                        dateLastUpdated: item.dateLastUpdated,
                        dateLastTriggered: item.dateLastTriggered,
                        priceAlertData: null,
                        MAAlertData: null,
                        TLAlertData: null,
                        patternRecAlertData: null,
                        StockAlertData: item,
                        listName: !item.isStockAlert ? item.listName : null,
                        geographyName: item.geographyName,
                        columnSetName: item.columnSetName,
                        alertType: "andConditionAlerts"
                    }
                );
            }
        });
    }

    if (alertData.priceAlerts !== null) {
        alertObj.push({
            msid: alertData.priceAlerts.msid,
            targetPrice: alertData.priceAlerts.targetPrice,
            alertId: alertData.alert.alertId,
            notificationId: alertData.alert.notificationId,
            isActive: alertData.alert.isActive,
            isTriggered: alertData.alert.isTriggered,
            dateLastUpdated: alertData.alert.dateLastUpdated,
            dateLastTriggered: alertData.alert.dateLastTriggered,
            priceAlertData: alertData.priceAlerts,
            MAAlertData: null,
            TLAlertData: null,
            StockAlertData: null,
            patternRecAlertData: null,
            isStockAlert: 0,
            alertType: "andConditionAlerts"
        })
    }

    return alertObj;
}

function getAlertDate(date) {
    const curr = moment(new Date());
    let returnDate;
    if (curr._d.toDateString() === date._d.toDateString()) {
        if (Math.abs(curr.diff(date, 'minutes')) < 1) {
            returnDate = parseInt(curr.diff(date, 'seconds')) + AlertTranslateHelper["SEC_AGO"];
        }
        else if (Math.abs(date.diff(curr, 'minutes')) < 60) {
            returnDate = parseInt(curr.diff(date, 'minutes')) + AlertTranslateHelper["MIN_AGO"];
        }
        else {
            returnDate = parseInt(curr.diff(date, 'hours')) + AlertTranslateHelper["HRS_AGO"];
        }
    }
    else {
        returnDate = LocalizationStore.getLang() === LangType.ZH_CN ? `${LocalizationStore.getTranslatedData(`cal_${date.format('ddd').toLowerCase()}`, date.format('ddd'))} ${LocalizationStore.getTranslatedData(`cal_${date.format("MMM")}`, date.format("MMM"))}${LocalizationStore.getTranslatedData("sb_day", "{0}").replace("{0}", date.format("D"))} ${date.format("hh:mm")} ${LocalizationStore.getTranslatedData(`ss_${date.format("a").toLowerCase()}`, date.format("a").toUpperCase())}`
            : `${date.format('ddd')} ${date.format("D")} ${date.format("MMM")} ${date.format("hh:mm")} ${date.format("a").toUpperCase()}`;
    }
    return returnDate;
}

function getAlertName(alertsData, SymbolInfo, currency) {
    let alertStr = '';

    if (alertsData.alertId > 0) {
        alertStr = getAlertType(alertsData, AlertConditionType.Or, undefined, undefined, SymbolInfo, currency);
    }
    else if (alertsData.length > 0) {
        for (let i = 0; i < alertsData.length; i++) {
            if (i > 0) {
                alertStr += "<br /> AND <br />";
            }
            alertStr += getAlertType(alertsData[i], AlertConditionType.And, i, alertsData.length, SymbolInfo, currency);
        }
    }

    return alertStr;
}

function getAlertType(alertsData, alertType, andAlertCount, length, SymbolInfo, currency) {
    let strRetVal = "";
    if (alertsData.priceAlertData !== null && !StringUtil.isEmpty(alertsData.priceAlertData.targetPrice)) {
        const type = SymbolInfo.SymTypeEnum === SymbolType.USSTOCK ||
            SymbolInfo.SymTypeEnum === SymbolType.REIT ||
            SymbolInfo.SymTypeEnum === SymbolType.INTERNATIONALSTOCK ||
            SymbolInfo.SymTypeEnum === SymbolType.HISTORICSTOCK ||
            SymbolInfo.SymTypeEnum === SymbolType.ETF ||
            SymbolInfo.SymTypeEnum === SymbolType.ADR ||
            SymbolInfo.SymTypeEnum === SymbolType.FundClosedEnd;

        const alertprice = formatPrice(alertsData.priceAlertData.targetPrice);
        strRetVal = `${AlertTranslateHelper["PRICE"]} \n @ ${type ? currency : ""}${alertprice}`;
    }
    else
        if (alertsData.MAAlertData !== null) {
            strRetVal = `${IndicatorCommonTranslateHelper[CustomTiDialogConstant.MovingAverageColor] + alertsData.MAAlertData.length + (LocalizationStore.getLang() === LangType.ZH_CN ? AlertPeriodicityTranslateHelper[alertsData.MAAlertData.periodicity] : "")}\n${getMAAlertType(alertsData.MAAlertData.maType)} (${PeriodicityTypeTranslateHelper[alertsData.MAAlertData.periodicity]})`;
        }
        else
            if (alertsData.TLAlertData !== null) {
                const scaleStr = getCurrStringValue(alertsData.TLAlertData.scale);
                const direction = alertsData.TLAlertData.alertOperator.trim() === "<" ? AlertsDialogTranslateHelper["DOWNWARD"] : AlertsDialogTranslateHelper["UPWARD"];
                const targetPrice = alertsData.TLAlertData.targetPrice;
                if (alertsData.isTriggered === 1 && alertsData.isActive < 1) {
                    strRetVal = LocalizationStore.getTranslatedData("ch_msa_TrendlineCross", "Trendline \n {0} Cross @{1}{2} ({3}, {4})", direction, currency, formatPrice(targetPrice), PeriodicityTypeTranslateHelper[alertsData.TLAlertData.periodicity], scaleStr);
                }
                else {
                    strRetVal = LocalizationStore.getTranslatedData("ch_msa_Trendline", "Trendline \n @ {0}{1} ({2}, {3}) ", currency, formatPrice(targetPrice), PeriodicityTypeTranslateHelper[alertsData.TLAlertData.periodicity], scaleStr);
                }
            }
            else
                if (alertsData.patternRecAlertData !== null) {
                    switch (alertsData.patternRecAlertData.alert.alertType) {
                        case AlertType.ALERT_PATTERN_REC_NEW_BASE:
                            strRetVal = `${AlertsDialogTranslateHelper["PATTERN_RECOG"]}\n ${AlertsDialogTranslateHelper["NEW_BASE"]}(${PeriodicityTypeTranslateHelper[alertsData.patternRecAlertData.periodicity]})`;
                            break;
                        case AlertType.ALERT_PATTERN_REC_NEW_TIGHT_AREA:
                            strRetVal = `${AlertsDialogTranslateHelper["PATTERN_RECOG"]}\n ${AlertsDialogTranslateHelper["TIGHT_AREA"]}${PeriodicityTypeTranslateHelper[alertsData.patternRecAlertData.periodicity]})`;
                            break;
                        case AlertType.ALERT_PATTERN_REC_BREAKOUT:
                            strRetVal = `${AlertsDialogTranslateHelper["PATTERN_RECOG"]}\n ${AlertsDialogTranslateHelper["TIGHT_AREA"]}${PeriodicityTypeTranslateHelper[alertsData.patternRecAlertData.periodicity]})`;
                            break;
                        case AlertType.ALERT_PATTERN_REC_PERC_OFF_PIVOT:
                            strRetVal = `${AlertsDialogTranslateHelper["PATTERN_RECOG"]}\n ${LocalizationStore.getTranslatedData("ch_from_Pivot", `${alertsData.patternRecAlertData.percentBelowPivot}% from Pivot`).replace("{0}", alertsData.patternRecAlertData.percentBelowPivot)} (${PeriodicityTypeTranslateHelper[alertsData.patternRecAlertData.periodicity]})`;
                            break;
                        default:
                            break;
                    }
                }
                else if (alertsData.StockAlertData !== null) {
                    if (!alertsData.StockAlertData.isStockAlert) {
                        if (alertType === AlertConditionType.And) {
                            if (andAlertCount === 0) {
                                strRetVal = strRetVal + AlertsDialogTranslateHelper["LIST_AND_CONDITION"];
                            }
                        } else {
                            strRetVal = strRetVal + AlertsDialogTranslateHelper["LIST_ALERT"];
                        }
                    }
                    else if (alertsData.StockAlertData.isStockAlert) {
                        // strRetVal = strRetVal + "Stock Alert: ";
                    }
                    switch (alertsData.StockAlertData.alertTypeId) {
                        case ListAlerts.Additions:
                            strRetVal += AlertsDialogTranslateHelper["ADDED_TO_LIST"];
                            break;
                        case ListAlerts.Removals:
                            strRetVal += AlertsDialogTranslateHelper["REMOVE_FROM_LIST"];
                            break;

                        case ListAlerts.Dailypricechangeexceeds:
                            if (alertsData.StockAlertData.isTriggered) {
                                strRetVal += `${AlertsDialogTranslateHelper["DAILY_PRICE_CHG_EXCEED"] + (alertsData.StockAlertData.value > 0
                                    ? `+${alertsData.StockAlertData.value.toString()}`
                                    : alertsData.StockAlertData.value.toString())
                                    }%`;
                            }
                            else {
                                strRetVal += `${AlertsDialogTranslateHelper["DLY_PRICE_CHG_EXCEED"] + alertsData.StockAlertData.value}%`;
                            }
                            break;
                        case ListAlerts.DailyVolumeRateexceeds:
                            strRetVal += `${AlertsDialogTranslateHelper["DLY_VOLUME_RATE_EXCEED"] + alertsData.StockAlertData.value}%`;
                            break;
                        case ListAlerts.WeeklyVolumeRateexceeds:
                            strRetVal += `${AlertsDialogTranslateHelper["WEEKLY_VOL_EXCEED"] + alertsData.StockAlertData.value}%`;
                            break;
                        case ListAlerts.AverageDailyVolumecrosses:
                            strRetVal += `${AlertsDialogTranslateHelper["AVG_DLY_VOLM_CROSSES"] + alertsData.StockAlertData.value}(000) ${AlertsDialogTranslateHelper["SHARES"]}`;
                            break;
                        case ListAlerts.AverageDailyDlrVolumecrosses:
                            strRetVal += `${AlertsDialogTranslateHelper["AVG_DLY_$_VOL_CROSSES"] + alertsData.StockAlertData.value}(000)`;
                            break;
                        case ListAlerts.SMAdaily50:
                            if (alertsData.StockAlertData.isTriggered) {
                                const abovebelowstring = alertsData.StockAlertData.displayValue;
                                const abovebelowstringResKey = abovebelowstring.substring(0, 1).toUpperCase() + abovebelowstring.substring(1);
                                strRetVal += AlertsDialogTranslateHelper["PRICE_CROSSES"] + LocalizationStore.getTranslatedData(`EmailTitle_${abovebelowstringResKey}`, abovebelowstring) + AlertsDialogTranslateHelper["_50_SMA"];

                            }
                            else {
                                strRetVal += AlertsDialogTranslateHelper["PRICE_50_SMA_CROSS"];
                            }
                            break;
                        case ListAlerts.SMAdaily200:
                            if (alertsData.StockAlertData.isTriggered) {
                                const abovbelowstring = alertsData.StockAlertData.displayValue;
                                const abovbelowstringResKey = abovbelowstring.substring(0, 1).toUpperCase() + abovbelowstring.substring(1);
                                strRetVal += AlertsDialogTranslateHelper["PRICE_CROSSES"] + LocalizationStore.getTranslatedData(`EmailTitle_${abovbelowstringResKey}`, abovbelowstring) + AlertsDialogTranslateHelper["_200_SMA"];
                            }

                            else {
                                strRetVal += AlertsDialogTranslateHelper["PRICE_200_SMA_CROSS"];
                            }
                            break;
                        case ListAlerts.DailyVolumeExceeds50SMA:
                            strRetVal += AlertsDialogTranslateHelper["DLY_VOLM_EXCEED_50_SMA"];
                            break;
                        case ListAlerts.BreakingOutToAllTimeHigh:
                            strRetVal += AlertsDialogTranslateHelper["BREAKING_OUT_ALL_TIME_HIGH"];
                            break;
                        case ListAlerts.RSReachesNewHigh:
                            strRetVal += AlertsDialogTranslateHelper["RS_NEW_HIGH"];
                            break;
                        case ListAlerts.PullbackTo10WeekLine:
                            strRetVal += AlertsDialogTranslateHelper["PULL_BACK_10_WEEK"];
                            break;
                        case ListAlerts.SMA50200crossoverdaily:
                            strRetVal += AlertsDialogTranslateHelper["CROSSOVER_50_200"];
                            break;
                        case ListAlerts.DailyNewBasedetected:
                            strRetVal += AlertsDialogTranslateHelper["NEW_DAILY_BASE"];
                            if (alertsData.StockAlertData.displayValue) {
                                strRetVal += `,${alertsData.StockAlertData.displayValue}`;
                            }
                            break;
                        case ListAlerts.WeeklyNewBasedetected:
                            strRetVal += AlertsDialogTranslateHelper["NEW_WEEKLY_BASE"];
                            if (alertsData.StockAlertData.displayValue) {
                                strRetVal += `,${alertsData.StockAlertData.displayValue}`;
                            }
                            break;
                        case ListAlerts.TightAreadetected:
                            strRetVal += AlertsDialogTranslateHelper["TIGHT_AREA"];
                            break;
                        case ListAlerts.DailyFailedBasedetected:
                            strRetVal += AlertsDialogTranslateHelper["FAILED_DAILY_BASE_BREAKOUT"];
                            break;
                        case ListAlerts.WeeklyFailedBasedetected:
                            strRetVal += AlertsDialogTranslateHelper["FAILED_WEEKLY_BASE_BREAKOUT"];
                            break;
                        case ListAlerts.DailyBreakingOutToday:
                        case ListAlerts.DailyPricecrossesoverpivotbreakout:
                            if (alertsData.StockAlertData.displayValue === null) {
                                strRetVal += AlertsDialogTranslateHelper["DAILY_BASE_BREAKOUT"];
                            } else {
                                const dailyBaseType = LocalizationStore.getTranslatedData(`Es_${alertsData.StockAlertData.displayValue.replace(/\s+/g, "")}`, alertsData.StockAlertData.displayValue);
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_booadb_basetype", "Breaking out of a daily {0} base", dailyBaseType.replace(" Base", ""));
                            }
                            break;
                        case ListAlerts.WeeklyBreakingOutToday:
                        case ListAlerts.WeeklyPricecrossesoverpivotbreakout:
                            if (alertsData.StockAlertData.displayValue === null) {
                                strRetVal += AlertsDialogTranslateHelper["WEEKLY_BASE_BREAKOUT"];
                            } else {
                                const weeklyBaseType = alertsData.StockAlertData.displayValue === null ? "" : LocalizationStore.getTranslatedData(`Es_${alertsData.StockAlertData.displayValue.replace(/\s+/g, "")}`, alertsData.StockAlertData.displayValue);
                                strRetVal += LocalizationStore.getTranslatedData("ch_msa_booawb_basetype", "Breaking out of a weekly {0} base", weeklyBaseType.replace(" Base", ""));
                            }
                            break;
                        case ListAlerts.DailyPricerisestowithinofpivot:
                            strRetVal += LocalizationStore.getTranslatedData("ch_msa_piwotdp", `Price is within ${alertsData.StockAlertData.value}% of the daily pivot`).replace("{0}", alertsData.StockAlertData.value);
                            break;
                        case ListAlerts.WeeklyPricerisestowithinofpivot:
                            strRetVal += LocalizationStore.getTranslatedData("ch_msa_piwotwp", `Price is within ${alertsData.StockAlertData.value}% of the weekly pivot`).replace("{0}", alertsData.StockAlertData.value);
                            break;
                        case ListAlerts.BuybackAnnouncement:
                            strRetVal += AlertsDialogTranslateHelper["BUYBACK_ANNOUNCE"];
                            break;
                        case ListAlerts.StockSplit:
                            if (alertsData.StockAlertData.isTriggered) {
                                strRetVal += SplitUtil.DecToString(alertsData.StockAlertData.triggeredValue); "" //FormatEvents.DecToString(alertsData.stockAlertData.triggeredValue);
                            }
                            strRetVal += AlertsDialogTranslateHelper["STOCK_SPLIT"];
                            break;
                        case ListAlerts.CEOChange:
                            strRetVal += AlertsDialogTranslateHelper["CEO_CHG"];
                            break;
                        case ListAlerts.Earningsreleaseiswithin:
                            strRetVal += AlertsDialogTranslateHelper["EARNING_REALSE"] + alertsData.StockAlertData.value + AlertsDialogTranslateHelper["DAYS"];
                            break;
                        case ListAlerts.DividendChange:
                            strRetVal += CorpActionTranslateHelper[CorporateEventsConst.DIVIDEND];
                            break;
                        // case ListAlerts.InsiderPurchages:
                        //     strRetVal += LocalizationStore.getTranslatedData("ch_msa_it", "Insider transactions");
                        //     break;
                        case ListAlerts.InsidersTransactionsBuys:
                            strRetVal += `Insider transactions - Buys ${!StringUtil.isEmpty(alertsData.StockAlertData.displayValue) ? `${alertsData.StockAlertData.displayValue.split(":")[0]} insiders bought ${alertsData.StockAlertData.displayValue.split(":")[1]} total shares` : ""}`;
                            break;
                        case ListAlerts.InsidersTransactionsSells:
                            strRetVal += `Insider transactions - Sells ${!StringUtil.isEmpty(alertsData.StockAlertData.displayValue) ? `${alertsData.StockAlertData.displayValue.split(":")[0]} insiders sold ${alertsData.StockAlertData.displayValue.split(":")[1]} total shares` : ""}`;
                            break;
                        case ListAlerts.Offerings:
                            strRetVal += AlertsDialogTranslateHelper["OFFERING"];
                            break;
                        case ListAlerts.Announcements:
                            strRetVal += CorpEventsDialogTranslateHelper[CorpEventsDialogConstants.Ceo];
                            break;
                        case ListAlerts.BreakawayGap:
                            strRetVal += "Breakaway gap"
                            break;
                        case ListAlerts.Day50BreakOnVolume:
                            strRetVal += "50 day break on volume";
                            break;
                        case ListAlerts.GapUpOnEarnings:
                            strRetVal += "Gap up on earnings";
                            break;
                        case ListAlerts.GapDownOnEarnings:
                            strRetVal += "Gap down on earnings"
                            break;
                        case ListAlerts.NegativeAlertScore1Week:
                            if (alertsData.StockAlertData.isTriggered) {
                                strRetVal += `Negative Alert Score, 1 Week >= ${alertsData.StockAlertData.value}`;
                            }
                            break;
                        case ListAlerts.NegativeAlertScore10Week:
                            if (alertsData.StockAlertData.isTriggered) {
                                strRetVal += `Negative Alert Score, 10 Week >= ${alertsData.StockAlertData.value}`;
                            }
                            break;
                        case ListAlerts.DailyEnterExitProfitRange:
                            strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Profit Range - Daily` : "Enter exit profit range - daily";
                            break;
                        case ListAlerts.WeeklyEnterExitProfitRange:
                            strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Profit Range - Weekly` : "Enter exit profit range - weekly";
                            break;
                        case ListAlerts.DailyEnterExitPivotRange:
                            strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Pivot Range - Daily` : "Enter exit pivot range - daily";
                            break;
                        case ListAlerts.WeeklyEnterExitPivotRange:
                            strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Pivot Range - Weekly` : "Enter exit pivot range - weekly";
                            break;
                        case ListAlerts.DailyEnterExitLossRange:
                            strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Loss Range - Daily` : "Enter exit loss range - daily";
                            break;
                        case ListAlerts.WeeklyEnterExitLossRange:
                            strRetVal += alertsData.StockAlertData.isTriggered ? `Crossed ${alertsData.StockAlertData.displayValue} Loss Range - Weekly` : "Enter exit loss range - weekly";
                            break;
                        default:
                            break;
                    }
                    if (!alertsData.StockAlertData.isStockAlert) {
                        if ((alertType === AlertConditionType.And && andAlertCount === 1) || (alertType === AlertConditionType.And && length === 1) || alertType === AlertConditionType.Or) {
                            strRetVal = `${strRetVal} (${alertsData.listName}${(alertsData.columnSetName && alertsData.columnSetName.length > 0) ? ` | ${alertsData.columnSetName}` : ''}${(alertsData.geographyName && alertsData.geographyName.length > 0) ? ` | ${alertsData.geographyName}` : ''})`;
                        }
                    }

                    if (!StringUtil.isEmpty(alertsData.ListName) && alertsData.StockAlertData === null)//list alerts
                    {
                        switch (alertsData.listAlertTypeId) {
                            case 1:
                                strRetVal = LocalizationStore.getTranslatedData("ListAlertType_1", `List Alert: Added to list (${alertsData.listName})`).replace("[NAME]", alertsData.listName);
                                break;
                            case 2:
                                strRetVal = LocalizationStore.getTranslatedData("ListAlertType_2", `List Alert: Removed from list (${alertsData.listName})`).replace("[NAME]", alertsData.listName);
                                break;
                            case 3:
                                strRetVal = LocalizationStore.getTranslatedData("ListAlertType_3", `List Alert: Price % Chg exceeds ${alertsData.triggeredValue}` > 0 ? "+" : `-${alertsData.threshold}% (${alertsData.listName})`).replace("[THRESHOLDVALUE]", alertsData.threshold).replace("[NAME]", alertsData.listName);
                                break;
                            default:
                                strRetVal = LocalizationStore.getTranslatedData("ListAlertType_4", `List Alert: Volume Rate exceeds ${alertsData.triggeredValue}` > 0 ? "+" : `-${alertsData.threshold}% (${alertsData.listName})`).replace("[THRESHOLDVALUE]", alertsData.threshold).replace("[NAME]", alertsData.listName);
                                break;

                        }
                    }
                }
    return strRetVal;
}

function formatPrice(price) {
    const priceString = price.toString();

    let alertprice;
    if (priceString.indexOf('.') > -1 && priceString.split('.')[1].length <= 4) {
        alertprice = priceString;
    }
    else {
        alertprice = price > 1.00 ? price.toFixed(2).toString() : price.toFixed(3).toString();
    }

    return alertprice;
}

function getMAAlertType(type) {
    switch (type) {
        case MovingAverageType.SMA:
            return "SMA";
        case MovingAverageType.EMA:
            return "EMA"
        default:
            return "";
    }
}
function getCurrStringValue(val) {
    switch (val) {
        case PrefPeriodScaleConstants.Auto:
            return "Auto";
        case PrefPeriodScaleConstants.Lin:
            return "Lin";
        case PrefPeriodScaleConstants.Log:
            return "Log";
        default:
            break;
    }

    return val.toString();
}

function* getAlertsData({ symbol }) {
    try {
        if (symbol) {
            alertsDataWorkerObj.postMessage({ MsId: 0, CurrencyCode: DataGraphStore.getCurrencyCode(""), symbol: symbol });

            alertsDataWorkerObj.onmessage = (e) => {
                dispatch(updatePriceAlertResponse(e.data.alerts, true))
                dispatch(postAlertsDataWorkerAction());
            }
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, getAlertData, ${error}`);
    }
}

//optAlert
//Worker call to get alerts data
function* startAlertsDataWorker() {
    try {
        const { SymbolInfo } = yield select(getDatagraphStates)
        alertsDataWorkerObj = alertsDataWorkerObj ? alertsDataWorkerObj : new alertsDataWorker()

        if (SymbolInfo) {
            alertsDataWorkerObj.postMessage({ MsId: SymbolInfo.MsId, CurrencyCode: DataGraphStore.getCurrencyCode(DatagraphHelper.getCurrencyCode(SymbolInfo.DefaultCurrency)) });

            alertsDataWorkerObj.onmessage = function (e) {
                dispatch(updatePriceAlertResponse(e.data.alerts, true))
                dispatch(postAlertsDataWorkerAction());
            }
            alertsDataWorkerObj.onerror = function () {
                // alertsDataWorkerObj.terminate();
                // alertsDataWorkerObj = undefined;
                // resolve();
            }
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, startAlertDataWorker, ${error}`)
    }
}

//Worker Complete
function* postAlertsDataWorker() {
    try {
        const states = yield select(getDatagraphAlertStates);
        const { priceAlertResponseData, emitWorkerAction } = states;
        let datagraphAlertsList = states.datagraphAlertsList;
        const { SymbolInfo, periodicityType, majorPeriodicity, viewsSettings, isPricePanelDataReady } = yield select(getDatagraphStates);

        if (priceAlertResponseData && isPricePanelDataReady) {
            const maSettings = viewsSettings.MASettings[majorPeriodicity];
            if (sessionStorage.getItem("triggeredAlertsList")) {
                datagraphAlertsList = JSON.parse(sessionStorage.getItem("triggeredAlertsList"));
            }
            //price alerts
            priceAlertResponseData.priceAlerts.forEach((alert) => {
                const data = datagraphAlertsList.find((item) => item.alertId === alert.alert.alertId);
                if (data) {

                    data.targetPrice = alert.targetPrice > 1 ? alert.targetPrice.toFixed(2) : alert.targetPrice.toFixed(3);
                    data.msid = alert.msid;
                    if (data.isActive === 1 && data.isTriggered === 0 && alert.alert.isTriggered === 1) {
                        data.isTriggered = alert.alert.isTriggered;
                    }
                    data.isActive = alert.alert.isActive;
                    data.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    data.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    data.notificationId = alert.alert.notificationId;
                    data.note = alert.note;
                    data.priceAlertData = alert;
                }
                else {
                    const alertsData = convertPriceAlert(alert);
                    alertsData.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    alertsData.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    if (alertsData.isActive === 0 && alertsData.isTriggered === 1 && moment.duration(moment(new Date()).diff(alertsData.dateLastTriggered)).asSeconds() > 15) {
                        alertsData.isTriggered = 0; // Not sure about this one.
                    }
                    datagraphAlertsList.push(alertsData);
                }
            });

            //moving average alerts
            priceAlertResponseData.movingAverageAlerts.forEach((alert) => {
                const data = datagraphAlertsList.find((item) => item.alertId === alert.alert.alertId);
                if (data) {
                    data.targetPrice = alert.targetPrice;
                    data.msid = alert.msid;
                    if (data.isActive === 1 && data.isTriggered === 0 && alert.alert.isTriggered === 1) {
                        data.isTriggered = alert.alert.isTriggered;
                    }
                    data.isActive = alert.alert.isActive;
                    data.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    data.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    data.MAAlertData = alert;
                }
                else {
                    const alertsData = convertMAAlert(alert);
                    alertsData.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    alertsData.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    if (alertsData.isActive === 0 && alertsData.isTriggered === 1 && moment.duration(moment(new Date()).diff(alertsData.dateLastTriggered)).asSeconds() > 15) {
                        alertsData.isTriggered = 0; // Not sure about this one.
                    }
                    datagraphAlertsList.push(alertsData);
                }
            });


            // //trendline alerts

            priceAlertResponseData.trendlineAlerts.forEach((alert) => {
                const data = datagraphAlertsList.find((item) => item.alertId === alert.alert.alertId);
                if (data) {
                    data.targetPrice = alert.targetPrice;
                    data.msid = alert.msid;
                    data.isActive = alert.alert.isActive;
                    data.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    data.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    data.TLAlertData = alert;
                }
                else {
                    const alertsData = convertTLAAlert(alert);
                    alertsData.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastUpdated));
                    alertsData.dateLastTriggered = alert.alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.alert.dateLastTriggered)) : null;
                    if (alertsData.isActive === 0 && alertsData.isTriggered === 1) {
                        alertsData.isTriggered = 0; // Not sure about this one. 
                    }
                    datagraphAlertsList.push(alertsData);
                }
            });


            // //stock alerts
            priceAlertResponseData.stockAlerts.forEach((alert) => {
                const data = datagraphAlertsList.find((item) => item.alertId === alert.alertId);
                if (data) {
                    data.isActive = alert.isEnabled ? 1 : 0;
                    data.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.dateLastUpdated));
                    data.dateLastTriggered = alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.dateLastTriggered)) : null;
                    data.isStockAlert = true;
                    data.stockAlertData = alert;
                }
                else {
                    const alertsData = convertStockAlert(alert);
                    alertsData.dateLastUpdated = moment.utc(StringUtil.convertFromLongValueToInt(alert.dateLastUpdated));
                    alertsData.dateLastTriggered = alert.dateLastTriggered ? moment.utc(StringUtil.convertFromLongValueToInt(alert.dateLastTriggered)) : null;
                    if (alertsData.isActive === 0 && alertsData.isTriggered === 1) {
                        alertsData.isTriggered = 0; // Not sure about this one. 
                    }
                    alertsData.IsStockAlert = true;
                    datagraphAlertsList.push(alertsData);
                }
            });

            // }
            //update Moving Average Alerts. set the data structure
            const movingAverageAlerts = priceAlertResponseData.movingAverageAlerts.filter((item) => item.alert.isActive === 1 && item.periodicity === periodicityType);

            maSettings.forEach((maItem) => {

                maItem.AlertIsActive = false;
                const data = movingAverageAlerts.filter((item) => (maItem.ma === item.length && maItem.maType === getMAAlertType(item.maType) && SymbolInfo.MsId === item.msid))

                if (!StringUtil.isEmpty(data)) {
                    maItem.AlertIsActive = true;
                }
            });
            yield put(prepareContextMenu())
            //optAlert
            // priceChartStore.populateLineMenuItems();

            // ViewModel.GetChartPanelModel().GenerateLineMenuItems();

            // //nViewModel.GenerateLineMenuItems();
            // foreach(var lineChartViewModel in ViewModel.MAChartViewModelCollection)
            // {
            //     lineChartViewModel.RefreshMenuItem();
            // }
        }
        if (emitWorkerAction) {
            const userNotificationSettings = SettingsStore.getConsoleSettings().UserNotificationSettings;
            yield put(updateDataGraphAlertStates({ emitWorkerAction: false, priceAlertResponseData, datagraphAlertsList: [...datagraphAlertsList], IsShowAlertsIcon: userNotificationSettings.IsShowAlertsIcon, userNotificationSettings }));
            yield fork(getAlertsDataSource)
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, postAlertsDataWorker, ${error}`);
    }

}

function* createMovingAverageAlert({ menuItem }) {
    try {
        const { SymbolInfo, periodicityType, currencyStr } = yield select(getDatagraphStates);
        const { datagraphAlertsList } = yield select(getDatagraphAlertStates)
        if (!menuItem.maSettingsObj.AlertIsActive) {
            const maLayer = menuItem.maSettingsObj;
            const maAlertType = maLayer.maType;
            const alertData = { isActive: 1, isTriggered: 0 }
            const maAlertData =
            {
                alert: alertData,
                msid: SymbolInfo.MsId,
                length: maLayer.ma,
                periodicity: periodicityType,
                maType: maAlertType,
                currencyCode: currencyStr
            }
            const result = yield call(AlertsApi.movingAverageAlertUpsert, maAlertData)
            if (result.responseHeader.error) {
                validateResponse(result);
            }
            else {
                yield call(startAlertsDataWorker);
            }
            ConsoleStore.trackUsage('AlertIsCreated');
        }
        else {
            const maLayer = menuItem.maSettingsObj;

            for (const item of datagraphAlertsList) {
                if (!StringUtil.isEmpty(item.MAAlertData) && item.isActive && item.MAAlertData.length === maLayer.ma
                    && getMAAlertType(item.MAAlertData.maType) === maLayer.maType && item.MAAlertData.msid === SymbolInfo.MsId
                    && item.MAAlertData.periodicity === periodicityType) {
                    yield call(AlertsApi.dismissAlert, item.alertId);
                    yield put(removeDataGraphAlert(item))
                    yield call(startAlertsDataWorker);
                }
            }
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, createMovingAverageAlert, ${error}`);
    }

}

function validateResponse(result) {
    const code = result.responseHeader.errorCodeData.errorCode;
    switch (code) {
        case 50001:
        case 50003:
            ConsoleActions.exceptionHandle(null, false, 'List alerts can be created for up to 10 lists, and only for lists which have 1,000 symbols or less.', false, "LIST ALERT LIMIT", true);
            break;
        default:
            ConsoleActions.exceptionHandle();
            break;

    }
}
function* initAlertOnSymbolChange() {
    try {
        yield fork(getActiveStockAlerts);
        yield fork(postAlertsDataWorker);
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, initAlertOnSymbolChange, ${error}`);
    }


}

function buildAlertData(alertDataObj) {
    const _alertDataSettingsArr = [];

    if (alertDataObj.listAlertSettings.AdditionSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Additions, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.RemovalSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Removals, isEnabled: true })
    }

    // if (alertDataObj.listAlertSettings.PriceChangeSelected) {
    _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Dailypricechangeexceeds, isEnabled: alertDataObj.listAlertSettings.PriceChangeSelected, targetValue: alertDataObj.listAlertSettings.PriceChangeThreshold });
    //}



    //if (alertDataObj.listAlertSettings.DailyVolumeRateSelected) {
    _alertDataSettingsArr.push({
        alertTypeId: ListAlerts.DailyVolumeRateexceeds,
        isEnabled: alertDataObj.listAlertSettings.DailyVolumeRateSelected,
        targetValue: alertDataObj.listAlertSettings.DailyVolumeRateThreshold,
        rangeValue: "0",
        delayTime: alertDataObj.listAlertSettings.DailyVolumeRateDelayThreshold,
        isDelayEnabled: alertDataObj.listAlertSettings.DailyVolumeRateDelaySelected
    });
    // }

    // if (alertDataObj.listAlertSettings.AverageDlyVolSelected) {
    _alertDataSettingsArr.push({ alertTypeId: ListAlerts.AverageDailyVolumecrosses, isEnabled: alertDataObj.listAlertSettings.AverageDlyVolSelected, targetValue: alertDataObj.listAlertSettings.AverageDlyVolThreshold });
    //  }

    // if (alertDataObj.listAlertSettings.AverageDlyDlrVolSelected) {
    _alertDataSettingsArr.push({ alertTypeId: ListAlerts.AverageDailyDlrVolumecrosses, isEnabled: alertDataObj.listAlertSettings.AverageDlyDlrVolSelected, targetValue: alertDataObj.listAlertSettings.AverageDlyDlrVolThreshold });
    //}

    if (alertDataObj.listAlertSettings.DailyVolumeExceeds50SMASelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyVolumeExceeds50SMA, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.BreakingOutToAllTimeHighSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.BreakingOutToAllTimeHigh, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.RSReachesNewHighSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.RSReachesNewHigh, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.MovingAvg50SmaSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.SMAdaily50, isEnabled: true });
    }
    if (alertDataObj.listAlertSettings.MovingAvg200SmaSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.SMAdaily200, isEnabled: true });
    }
    if (alertDataObj.listAlertSettings.MovingAvg50Cross200Selected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.SMA50200crossoverdaily, isEnabled: true });
    }
    if (alertDataObj.listAlertSettings.DailyBaseChecked) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyNewBasedetected, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.WeeklyBaseChecked) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyNewBasedetected, isEnabled: true });
    }
    if (alertDataObj.listAlertSettings.WeeklyTightChecked) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.TightAreadetected, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.DailyFailedBaseChecked) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyFailedBasedetected, isEnabled: true });
    }
    if (alertDataObj.listAlertSettings.WeeklyFailedBaseChecked) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyFailedBasedetected, isEnabled: true });
    }

    //if (alertDataObj.listAlertSettings.DailyPriceRisesSelected) {
    _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyPricerisestowithinofpivot, isEnabled: alertDataObj.listAlertSettings.DailyPriceRisesSelected, targetValue: alertDataObj.listAlertSettings.AlertPriceRises });
    // }

    //if (alertDataObj.listAlertSettings.WeeklyPriceRisesSelected) {
    _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyPricerisestowithinofpivot, isEnabled: alertDataObj.listAlertSettings.WeeklyPriceRisesSelected, targetValue: alertDataObj.listAlertSettings.AlertPriceRises });
    // }


    if (alertDataObj.listAlertSettings.DailyPriceCrossesSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyPricecrossesoverpivotbreakout, isEnabled: true });
    }


    if (alertDataObj.listAlertSettings.WeeklyPriceCrossesSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyPricecrossesoverpivotbreakout, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.PullbackTo10WeekLineSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.PullbackTo10WeekLine, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.BuybackAnnouncementSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.BuybackAnnouncement, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.DividendChangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DividendChange, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.StockSplitSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.StockSplit, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.CeoChangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.CEOChange, isEnabled: true });
    }

    //if (alertDataObj.listAlertSettings.EarningsReleaseSelected) {
    _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Earningsreleaseiswithin, isEnabled: alertDataObj.listAlertSettings.EarningsReleaseSelected, targetValue: alertDataObj.listAlertSettings.EarningsReleaseDays });
    // }
    // if (alertDataObj.listAlertSettings.InsiderPurchasesSelected) {
    //     _alertDataSettingsArr.push({ alertTypeId: ListAlerts.InsiderPurchages, isEnabled: true });
    // }
    if (alertDataObj.listAlertSettings.InsiderTransactionsBuysSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.InsidersTransactionsBuys, isEnabled: true });
    }
    if (alertDataObj.listAlertSettings.InsiderTransactionsSellsSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.InsidersTransactionsSells, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.OfferingsSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Offerings, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.AnnouncementsSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Announcements, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.BreakawayGapSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.BreakawayGap, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.Day50BreakOnVolumeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.Day50BreakOnVolume, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.GapUpOnEarningsSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.GapUpOnEarnings, isEnabled: true });
    }

    if (alertDataObj.listAlertSettings.GapDownOnEarningsSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.GapDownOnEarnings, isEnabled: true });
    }

    // if (alertDataObj.listAlertSettings.NegativeAlter1WeekSelected) {
    _alertDataSettingsArr.push({ alertTypeId: ListAlerts.NegativeAlertScore1Week, isEnabled: alertDataObj.listAlertSettings.NegativeAlter1WeekSelected, targetValue: alertDataObj.listAlertSettings.NegativeAlter1Week });
    // }

    //if (alertDataObj.listAlertSettings.NegativeAlter10WeekSelected) {
    _alertDataSettingsArr.push({ alertTypeId: ListAlerts.NegativeAlertScore10Week, isEnabled: alertDataObj.listAlertSettings.NegativeAlter10WeekSelected, targetValue: alertDataObj.listAlertSettings.NegativeAlter10Week });
    //}
    const cPrice = DataGraphStore.getState().symbolPriceData ? DataGraphStore.getState().symbolPriceData.CPrice : null;

    if (alertDataObj.listAlertSettings.DailyEnterExitProfitRangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyEnterExitProfitRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.DailyEnterExitProfitRange ? alertDataObj.listAlertSettings.DailyEnterExitProfitRange : cPrice });
    }
    if (alertDataObj.listAlertSettings.WeeklyEnterExitProfitRangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyEnterExitProfitRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.WeeklyEnterExitProfitRange ? alertDataObj.listAlertSettings.WeeklyEnterExitProfitRange : cPrice });
    }
    if (alertDataObj.listAlertSettings.DailyEnterExitPivotRangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyEnterExitPivotRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.DailyEnterExitPivotRange ? alertDataObj.listAlertSettings.DailyEnterExitPivotRange : cPrice });
    }
    if (alertDataObj.listAlertSettings.WeeklyEnterExitPivotRangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyEnterExitPivotRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.WeeklyEnterExitPivotRange ? alertDataObj.listAlertSettings.WeeklyEnterExitPivotRange : cPrice });
    }
    if (alertDataObj.listAlertSettings.DailyEnterExitLossRangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.DailyEnterExitLossRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.DailyEnterExitLossRange ? alertDataObj.listAlertSettings.DailyEnterExitLossRange : cPrice });
    }
    if (alertDataObj.listAlertSettings.WeeklyEnterExitLossRangeSelected) {
        _alertDataSettingsArr.push({ alertTypeId: ListAlerts.WeeklyEnterExitLossRange, isEnabled: true, targetValue: alertDataObj.listAlertSettings.WeeklyEnterExitLossRange ? alertDataObj.listAlertSettings.WeeklyEnterExitLossRange : cPrice });
    }

    alertDataObj.alerts.alertDataSetting = _alertDataSettingsArr;
}

function* saveAlertData({ listAlertSettings, conditionTypeId, alertId, priceAlertObj, isTriggered }) {
    try {
        const { alertDataObj } = yield select(getAlertDailogStates);

        alertDataObj.listAlertSettings = listAlertSettings;
        if (priceAlertObj && priceAlertObj.currencyCode) {
            priceAlertObj.currencyCode = DatagraphHelper.getCurrencyCode({ Code: priceAlertObj.currencyCode });
        }
        isTriggered = isTriggered ? 1 : 0;
        switch (alertDataObj.sourceTypeId) {
            case AlertSourceType.Stock: {
                buildAlertData(alertDataObj);
                const result = yield call(AlertsApi.saveAlertData, alertDataObj.alerts, null, null, alertDataObj.sourceTypeId, conditionTypeId, alertId, priceAlertObj, isTriggered)
                if (!result.responseHeader.error) {
                    if (conditionTypeId === AlertConditionType.Or) {

                        if (_.findWhere(alertDataObj.alerts.alertDataSetting, { isEnabled: true })) {
                            alertDataObj.isActiveAlerts.isActiveOrAlert = 1;
                        } else {
                            alertDataObj.isActiveAlerts.isActiveOrAlert = 0;
                        }

                    } else {
                        alertDataObj.isActiveAlerts.isActiveAndAlert = 1;
                    }
                    yield put(updateAlertDataObject(alertDataObj, false))
                    yield put(closeAlertDialog())
                    yield call(startAlertsDataWorker);
                    yield put({
                        type:AlertConstants.ActionTypes.SHOW_SAVE_ALERT_POPUP,
                        isSaveAlertsPopup: true
                    })
                }
                else {
                    validateResponse(result);
                };
                break;
            }
            //Commented code - Don't delete (R5)
            //This code will not be in for now because list view is in flux.
            case AlertSourceType.List: {
                buildAlertData(alertDataObj);
                const browserfilterStringIds = `-1;${alertDataObj.browserFilterSelectedIDs},;-1;`;
                const result = yield call(AlertsApi.saveAlertData, alertDataObj.alerts, alertDataObj.selectedColumnSet.colsetId, browserfilterStringIds, alertDataObj.sourceTypeId, conditionTypeId, alertId, null, 0)
                if (!result.responseHeader.error) {
                    //TO DO SK
                    // LogUsageTracking();
                    if (!(listAlertSettings.AdditionSelected || listAlertSettings.RemovalSelected || listAlertSettings.PriceChangeSelected || listAlertSettings.DailyVolumeRateSelected
                        //|| WeeklyVolumeRateSelected
                        || listAlertSettings.AverageDlyDlrVolSelected || listAlertSettings.AverageDlyVolSelected || listAlertSettings.MovingAvg50SmaSelected || listAlertSettings.MovingAvg200SmaSelected
                        || listAlertSettings.MovingAvg200SmaSelected || listAlertSettings.MovingAvg50Cross200Selected || listAlertSettings.DailyBaseChecked || listAlertSettings.WeeklyBaseChecked
                        || listAlertSettings.WeeklyTightChecked || listAlertSettings.DailyFailedBaseChecked || listAlertSettings.WeeklyFailedBaseChecked
                        || listAlertSettings.DailyPriceRisesSelected || listAlertSettings.WeeklyPriceRisesSelected || listAlertSettings.DailyPriceCrossesSelected || listAlertSettings.WeeklyPriceCrossesSelected
                        || listAlertSettings.BuybackAnnouncementSelected || listAlertSettings.DividendChangeSelected || listAlertSettings.StockSplitSelected || listAlertSettings.InsiderTransactionsBuysSelected
                        || listAlertSettings.InsiderTransactionsSellsSelected || listAlertSettings.CeoChangeSelected || listAlertSettings.OfferingsSelected || listAlertSettings.EarningsReleaseSelected || listAlertSettings.AnnouncementsSelected
                        || listAlertSettings.DailyVolumeExceeds50SMASelected || listAlertSettings.BreakingOutToAllTimeHighSelected || listAlertSettings.RSReachesNewHighSelected || listAlertSettings.PullbackTo10WeekLineSelected
                        || listAlertSettings.BreakawayGapSelected || listAlertSettings.Day50BreakOnVolumeSelected || listAlertSettings.GapUpOnEarningsSelected || listAlertSettings.GapDownOnEarningsSelected || listAlertSettings.NegativeAlter1WeekSelected
                        || listAlertSettings.NegativeAlter10WeekSelected || listAlertSettings.DailyEnterExitProfitRangeSelected || listAlertSettings.WeeklyEnterExitProfitRangeSelected
                        || listAlertSettings.DailyEnterExitPivotRangeSelected || listAlertSettings.WeeklyEnterExitPivotRangeSelected || listAlertSettings.DailyEnterExitLossRangeSelected
                        || listAlertSettings.WeeklyEnterExitLossRangeSelected
                    )) {
                        ListExplorerStore.updateListExplorerForAlerts(alertDataObj.alerts.sourceId, 0, conditionTypeId)
                    }
                    else {
                        ListExplorerStore.updateListExplorerForAlerts(alertDataObj.alerts.sourceId, ListAlertStatusType.Active, conditionTypeId)
                    }


                    if (alertDataObj.alerts.sourceId === ONeilViewStore._state.listId) {
                        //if actived list is included
                        //turn off instrument highlight
                        each(ONeilViewStore.getSourceData(), (item) => {
                            extend(item, { 'isTriggeredInstrument': false, 'ListAlertMessage': '' })

                            const recordDataItem = find(item.recordDatas, (item) => item.dataItemId === -5)

                            recordDataItem.className = recordDataItem.className.replace(new RegExp('summary-list-symbol-triggered', 'g'), '');
                        })

                        //update instrument triggered status 
                        yield call(ONeilViewStore.getListAlertTriggeredInstruments, alertDataObj.alerts.sourceId);

                        each(ONeilViewStore.getSourceData(), (itm) => {

                            const triggeredinstrument = ONeilViewStore._state.highlightSymbolMsIds.length > 0
                                ? find(ONeilViewStore._state.highlightSymbolMsIds, (item) =>
                                    item.HighlightMsId === itm.msId)
                                : null;

                            const recordDataItem = find(itm.recordDatas, (item) => item.dataItemId === -5)

                            if (triggeredinstrument !== null) {
                                itm.isTriggeredInstrument = true;

                                if (!recordDataItem.className.includes('summary-list-symbol-triggered')) {
                                    recordDataItem.className += ' summary-list-symbol-triggered'
                                }

                                if (StringUtil.isEmpty(itm.ListAlertMessage)) {
                                    itm.ListAlertMessage = '';
                                }

                                const len = triggeredinstrument.TriggerMessages;

                                each(triggeredinstrument.TriggerMessages, (triggeredmsg, i) => {
                                    itm.ListAlertMessage += `${triggeredmsg} 
                                   ${i !== len
                                            ? "<br/>"
                                            : ''}`;
                                });
                            }
                            else {
                                recordDataItem.className = recordDataItem.className.replace(new RegExp('summary-list-symbol-triggered', 'g'), '');
                            }
                        })
                    }
                    yield put(closeAlertDialog());
                }
                else {
                    validateResponse(result);
                }
                break;
            }
            default:
                break;
        }
    } catch (error) {
        console.log(`Error occurs in DataGraphAlertSaga.js, saveAlertData, ${error}`, error)
    }

}
//++++++++++++++++++++++++ Watchers +++++++++++++++++++++++++++++++

export function* watchGetAlertDate() {
    yield takeLatest(ActionTypes.GET_ALERT_DATA, getAlertsData)
}
export function* watchCreateMovingAverageAlert() {
    yield takeLatest(ActionTypes.CREATE_MOVING_AVERAGE_ALERT, createMovingAverageAlert)
}
export function* watchDeleteAllActiveAlerts() {
    yield takeLatest(ActionTypes.DELETE_ALL_ACTIVE_ALERTS, deleteAllActiveAlerts)
}
export function* watchDeleteAllAlertByMsIds() {
    // yield takeLatest(ActionTypes.DELETE_ALL_ALERTS_BY_MSIDS, deleteAllAlertsByMsIds)
}
export function* watchAlertDataSource() {
    yield takeLatest(ActionTypes.TOGGLE_SHOW_ACTIVE, getAlertsDataSource)
}
export function* watchReactivateAlert() {
    yield takeLatest(ActionTypes.TOGGLE_ALERT_STATUS, reactivateAlert)
}
export function* watchDeletePriceAlert() {
    yield takeLatest(ActionTypes.DELETE_ALERT, deleteAlert)
}
export function* watchCreatePriceAlert() {
    yield takeLatest(ActionTypes.CREATE_PRICE_ALERT, createPriceAlert)
}
export function* watchUpdatePriceAlert() {
    yield takeLatest(ActionTypes.UPDATE_PRICE_ALERT, updateAlertRequest)
}
export function* watchInitAlertOnSymbolChange() {
    yield takeLatest(DataGraphConstants.ActionTypes.PRICE_PANEL_RESPONSE_READY, initAlertOnSymbolChange)
}
export function* watchSaveAlertData() {
    yield takeLatest(ActionTypes.SAVE_ALERT_DATA, saveAlertData)
}
export function* watchStartAlertsDataWorker() {
    yield takeLatest(ActionTypes.START_ALERTS_DATA_WORKER, startAlertsDataWorker)
    yield takeLatest(ActionTypes.UPDATE_TRIGGERED_ALERT_LIST, startAlertsDataWorker)
}
export function* watchPostAlertDataWorker() {
    yield takeLatest(ActionTypes.POST_ALERT_DATA_WORKER, postAlertsDataWorker)
}