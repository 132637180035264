import { connect } from "react-redux";
import CountryListItem from "./CountryListItem";
import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import ReactDOM from "react-dom";
import { updateBrowserFilter } from "../../../../Actions/AlertActions";
import React, { Component } from "react";

class CountryBox extends Component {
    constructor(props){
        super(props);
        this.handleFilterListClick = this.handleFilterListClick.bind(this)
    }
    createItem(item, i) {

        return <CountryListItem item={item} key={i} RowClick={this.props.RowClick} existAlerts={this.props.existAlerts} />;
    }

    createItems(items) {

        return items.map(this.createItem, this);
    }

    handleFilterListClick(e) {
        const target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        const nodeFilterId = ReactDOM.findDOMNode(target).parentNode;
        const node = nodeFilterId.childNodes;
        let filterName = '';
        const list_items = ReactDOM.findDOMNode(target.parentNode.parentNode).childNodes;
        let activeCount = 0;
        let nodeFilterIds = '';
        if (!(e.ctrlKey || e.metaKey)) {
            for (let i = 0; i < list_items.length; i++) {
                const child = list_items[i].childNodes;
                for (let j = 0; j < child.length; j++){
                    child[j].className = child[j].className.replace(/\bactive\b/g, '');
                }
            }
        }
        else {
            for (let i = 0; i < list_items.length; i++) {
                const child = list_items[i].childNodes;
                for (let j = 0; j < child.length; j++){
                    if (child[j].classList.contains('active')) {
                        if (nodeFilterIds === '') {
                            nodeFilterIds = child[j].getAttribute("data-nodefilterid");
                        }
                        else {
                            nodeFilterIds += `,${child[j].getAttribute("data-nodefilterid")}`;
                        }
                        activeCount++;
                    }
                }
            }
        }

        const nodefilterName = node[1].getAttribute("data-nodefiltername") ? node[1].getAttribute("data-nodefiltername").toString() : '';
        const moreNumbers = (node && node.length >= 3) ? (node.length - 2).toString() : '';
        const count = parseInt(moreNumbers) + parseInt(activeCount);
        if (node.length < 3) {
            filterName = node[1].getAttribute("data-nodefiltername");
        }
        else {
            filterName = `${nodefilterName} [+${count} more]`;
        }

        for (let i = 1; i < node.length; i++) {
            ReactDOM.findDOMNode(node[i]).setAttribute('class', ' active');
            if (nodeFilterIds === '') {
                nodeFilterIds = node[i].getAttribute("data-nodefilterid");
            }
            else {
                nodeFilterIds += `,${node[i].getAttribute("data-nodefilterid")}`;
            }
        }
        this.props.updateBrowserFilter(nodeFilterIds.toString(), filterName);
    }

    render() {
        return (
            <ul className="geograph-inner-block-ul" >
                {this.props.region !== 'All' ?
                    <li className="geograph-inner-block-li" onClick={this.handleFilterListClick} id="RegionLabel">
                        <span className="filter-value geograph-inner-region">{LocalizationStore.getTranslatedData(`Region_${this.props.region.replace(/ /ig, "")}`, this.props.region)}</span>
                    </li> : null}
                {this.createItems(this.props.source)}
            </ul>
        );
    }
}

const mapStateToProps = ({ alertReducer })=>{
    const { countryCollection } = alertReducer.AlertDialogReducer;
    return { countryCollection };
}

const mapDispatchToProps = (dispatch)=>({
    updateBrowserFilter: (nodeFilterId, filterName)=> dispatch(updateBrowserFilter(nodeFilterId, filterName))
})

export default connect(mapStateToProps, mapDispatchToProps)(CountryBox);