import React from "react";
import FundamentalTabSortOrderType from 'Constants/FundamentalTabSortOrderType.js';
import FundamentalTabPeriodicityType from 'Constants/FundamentalTabPeriodicityType.js';
import FundamentalsAnnualHeader from "./FundamentalsAnnualHeader.jsx";
import FundamentalsNonAnnualHeader from "./FundamentalsNonAnnualHeader.jsx";
import TabFundamentalsStore from 'Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js';
import DateHelper from "DateHelper";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import ScrollBar from "ScrollBar";
import LocalizationStore from 'LocalizationStore';
import FundamentalsActions from "Actions/FundamentalsActions.js";
import FundamentalsFrozonCell from "../FundamentalsTable/FundamentalsFrozonCell.jsx"
import FundamentalsTabularData from "./FundamentalsTabularData.jsx";
import SettingsStore from "SettingsStore";
import { SettingsConstants } from "Constants/SettingsConstants.js";

let rows = {};
let reportDetails = [];
let topLeftDivHeight = 0;
let tableWrapperWidth = "100%";
let tablePadding = "0px";
let previousScrollLeft = 0;
let fundamentalPreviousScrollTop = 0;

export default class FundamentalsReportTable extends React.Component {
    constructor() {
        super();
        this.state = {
            highLightedRowIndex: null,
            isVisiblessb_h: false,
            shouldUpdate: false,
            tableWrapperWidth: "100%"
        }
        this.handleHorizontalScroll = this.handleHorizontalScroll.bind(this);
        this.handleTableScroll = this.handleTableScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.onMouseClick = this.onMouseClick.bind(this);
        this.frozenTable = false;
        this.preventEvent = false;
        this.rightActive = false;
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this._frozenCell = new Map();
    }
    handleKeyPressed(e) {
        const state =  TabFundamentalsStore.getReportState();
        this.rightActive = state;
        let maxLen = this.props.reportData.reportDetails.length * 32;
        
        fundamentalPreviousScrollTop = fundamentalPreviousScrollTop ? fundamentalPreviousScrollTop : 0;
        const consoleSettings = SettingsStore.getConsoleSettings();
        const isCurrentKeyDown = !consoleSettings.KeyDownActiveZone || consoleSettings.KeyDownActiveZone === SettingsConstants.KeyDownActiveZoneType.FUNDAMENTAL_TAB;
        if(e.keyCode === 38 && isCurrentKeyDown && this.rightActive && fundamentalPreviousScrollTop >= 0) {
            fundamentalPreviousScrollTop = fundamentalPreviousScrollTop - 32;
        }
        if(e.keyCode === 40 && isCurrentKeyDown && this.rightActive && fundamentalPreviousScrollTop < maxLen) {
            fundamentalPreviousScrollTop = fundamentalPreviousScrollTop + 32;
        }
        if(this.rightActive){
            this.fundametalScrollid.scrollTop = fundamentalPreviousScrollTop;
            this.fundametalScrollDiv.scrollTop = fundamentalPreviousScrollTop;
        }
      }

    componentDidMount() {
        let headerElem = document.getElementsByClassName("fundamental-table-block-title")[0];
        if (this.props.isResizing == true) {
            tableWrapperWidth = headerElem.clientWidth - this.props.width;
        }
        if (this.state.tableWrapperWidth !== headerElem.clientWidth - this.props.width){
            tableWrapperWidth = headerElem.clientWidth - this.props.width;
            this.setState({tableWrapperWidth: tableWrapperWidth});
        }
        if (this.fundametalScrollid) this.fundametalScrollid.addEventListener('scroll', this.handleHorizontalScroll, false);
        window.addEventListener('resize', this.handleResize, false);
        const fundamentalTable = document.getElementById("fundamental-table");
        const isShowVscroll = fundamentalTable.offsetHeight < this.fundametalScrollid.offsetHeight ? false : true; 
        const isVisiblessb_h = fundamentalTable.offsetWidth < this.fundametalScrollid.offsetWidth - (isShowVscroll ? 18 : 0) ? false : true;
        if (isVisiblessb_h !== this.state.isVisiblessb_h) {
            this.setState({ isVisiblessb_h: isVisiblessb_h});
        }
    }
    UNSAFE_componentWillMount() {
        document.addEventListener("keydown", this.handleKeyPressed, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPressed, false);
        if (this.fundametalScrollid){
            this.fundametalScrollid.removeEventListener('scroll', this.handleHorizontalScroll, false);
        }
        window.removeEventListener('resize', this.handleResize, false);
    }   
    UNSAFE_componentWillUpdate() {
        let headerElem = document.getElementsByClassName("fundamental-table-block")[0];
        if (this.props.isResizing == false) {
            let width = (headerElem.clientWidth - this.props.width);
            let difference = TabFundamentalsStore.getState().FundamentalResizeSettings.Width - this.props.width;
            if (difference != 0) {
                let percentageDifference = difference / (width / 100);
                let calculatedWidth = 100 + percentageDifference;
                tableWrapperWidth = calculatedWidth.toString() + "%";
            }
        }
    }

    componentDidUpdate() {
        window.setTimeout(() => {
            let el = document.querySelector(".fundamental-table-wrapper .ssb_h_sb");
            if (el) el.scrollLeft = previousScrollLeft;
        }, 0);
        let headerElem = document.getElementsByClassName("fundamental-table-block")[0];
        if (this.state.tableWrapperWidth !== headerElem.clientWidth - this.props.width){
            tableWrapperWidth = headerElem.clientWidth - this.props.width;
            this.setState({tableWrapperWidth: tableWrapperWidth});
        }
        const fundamentalTable = document.getElementById("fundamental-table");
        const isShowVscroll = fundamentalTable.offsetHeight < this.fundametalScrollid.offsetHeight ? false : true; 
        const isVisiblessb_h = fundamentalTable.offsetWidth < this.fundametalScrollid.offsetWidth - (isShowVscroll ? 18 : 0) ? false : true;
        if (isVisiblessb_h !== this.state.isVisiblessb_h) {
            this.setState({ isVisiblessb_h: isVisiblessb_h, shouldUpdate: false});
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let headerElem = document.getElementsByClassName("fundamental-table-block-title")[0];
        if (this.props.isResizing == true) {
            tableWrapperWidth = headerElem.clientWidth - nextProps.width;
        }
    }

    handleResize(){
        this.setState({shouldUpdate: true});
    }
    onMouseClick(){
        FundamentalsActions.setReportClickState(true);
        fundamentalPreviousScrollTop = this.fundametalScrollDiv.scrollTop;
    }

    syncHover(index, hover) {
        const frozen = this._frozenCell.get(index);
        if(frozen){
            frozen.onHover(hover);
        }
        this.tabularDataTable.onHover(index, hover);
    }

    handleHorizontalScroll(e) {
        let scrollLeft = e.target.scrollLeft;
        document.getElementById('fundamental-thead').style.left = "-" + scrollLeft + "px";
        e.stopPropagation();
        e.preventDefault();
    }

    handleTableScroll(e) {
        if (this.preventEvent) {
            this.preventEvent = false;
            return;
        }
        if (e.target && e.target.classList.contains("reportHeightChange")) {
            let top = e.target.scrollTop;
            document.getElementById('fundamental-scroll').scrollTop = top;
            document.getElementById('fundamental-table').scrollTop = top;
            this.preventEvent = false;
            this.frozenTable = true;
        }
        else if (e.target) {
            let top = e.target.scrollTop;
            previousScrollLeft = e.target.scrollLeft;
            //if (fundamentalPreviousScrollTop == top) document.getElementById('fundamental-thead').style.left = -e.target.scrollLeft + 'px';
            fundamentalPreviousScrollTop = top;
            if (!this.frozenTable) {
                this.preventEvent = true;
                this.fundametalScrollDiv.scrollTop = top;
            }
            this.frozenTable = false;
        }
    }

    getfundamentalHeader(data, settings) {
        let fundamentalHeader = undefined;
        if (settings.FundamentalPeriodicity === FundamentalTabPeriodicityType.FundamentalsAnnual) {
            topLeftDivHeight = 33; fundamentalHeader = <FundamentalsAnnualHeader headerData={data} settings={settings} handleSortOrderChange={this.props.handleSortOrderChange} width={this.props.width} isLoading={this.props.isLoading} />
        }
        else { topLeftDivHeight = 46; fundamentalHeader = <FundamentalsNonAnnualHeader headerdata={this.props.headerData} headerData={data} settings={settings} handleSortOrderChange={this.props.handleSortOrderChange} width={this.props.width} isLoading={this.props.isLoading} /> }
        return fundamentalHeader;
    }

    getYearEnding() {
        let yearLabel = "";
        if (DatagraphStore.getState().SymbolInfo.FiscalMonthEnd) {
            let month = DateHelper.getMonthbyNumber(DatagraphStore.getState().SymbolInfo.FiscalMonthEnd);
            month = LocalizationStore.getTranslatedData('cal_' + month, month);
            yearLabel = LocalizationStore.getTranslatedData("fr_ForyearEnding", "For Year ending in {0}", month);
        } else {
            yearLabel = "";
        }
        return yearLabel;
    }

    getClassNameForSortButton() {
        let className = "icn-sort-fundamental";
        if (this.props.settings.FundamentalSortOrder == FundamentalTabSortOrderType.Descending) {
            className = "icn-sort-fundamental-reverse";
        }
        return className;
    }

    render() {
        let settings = this.props.settings;
        let width = this.props.width;
        if (this.props.reportData.reportDetails) reportDetails = this.props.reportData.reportDetails;
        let headers = undefined;
        let yearEnding = this.getYearEnding(this.props.settings);

        // let isVisiblessb_h = true;
        // if (document.querySelector(".fundamental-table-wrapper .ssb_h")) {
        //     isVisiblessb_h = document.querySelector(".fundamental-table-wrapper .ssb_h").style.display == 'none' ? false : true;
        // }
        // if (this.props.headerData && this.props.headerData.length > 0)
            // monthNum = this.props.headerData[0].length - 1;
        if (settings.FundamentalPeriodicity == FundamentalTabPeriodicityType.FundamentalsQuarterly || settings.FundamentalPeriodicity == FundamentalTabPeriodicityType.FundamentalsSemiAnnual) { tablePadding = "16px"; } else { tablePadding = "4px"; }

        if (this.props.reportData != undefined || this.props.reportData != null) {
            headers = this.getfundamentalHeader(this.props.reportData, settings);

            return (<div className="fundamental-table">
                <div className="reportHeightChange" ref={(div) => { this.fundametalScrollDiv = div }} onScroll={this.handleTableScroll} style={{ position: 'absolute', overflow: 'auto', height: '100%', width: '100%' }}>

                    <table>
                        <thead>{this.props.isLoading == false && (this.props.reportData != null) ? <tr><td className="sort-title medium-bold" style={{ width: this.props.width, height: topLeftDivHeight }}>{yearEnding}<span className={this.getClassNameForSortButton()} onClick={this.props.handleSortOrderChange}></span></td></tr> : <div></div>}</thead>
                    </table>
                    <table id="fundamental-frozen-table" style={{ position: 'relative' }}>
                        <tbody style={{ marginTop: topLeftDivHeight }}>
                            {reportDetails ? reportDetails.map((report, index) => {
                                const frozenRef = `frozen${index}`;
                                return <FundamentalsFrozonCell
                                    syncHover={(itemIndex, hover) => this.syncHover(itemIndex, hover)}
                                    onMouseClick={this.onMouseClick}
                                    key={frozenRef}
                                    index={index}
                                    width={width}
                                    report={report}
                                    settings={this.props.settings}
                                    ref={(ref) => this._frozenCell.set(index, ref)} />;
                            }) : undefined  
                        }
                        {this.state.isVisiblessb_h && <tr style={{height: "18px"}}/>}
                        </tbody>
                    </table>
                </div>
                <div className="fundamental-main-head" style={{ left: this.props.width, width: this.state.tableWrapperWidth, height: topLeftDivHeight }}>
                    <div id="fundamental-thead" style={{ width: "100%", height: "100%", position: 'relative' }} >
                        <table>
                            <thead id="t_head">{headers}</thead>
                        </table>
                    </div>
                </div>
                <div className="fundamental-table-wrapper" onScroll={this.handleTableScroll} style={{ left: this.props.width, position: 'relative', width: this.state.tableWrapperWidth, paddingBottom: tablePadding }}>
                    <div className="custom-scroll">
                        <div id='fundamental-scroll' ref={(div) => { this.fundametalScrollid = div }}>
                            <div className="fundamental-table-inner-wrapper" style={{ width: "100%", height: "100%" }} >
                                {reportDetails ? <FundamentalsTabularData
                                    syncHover={(index, hover) => this.syncHover(index, hover)}
                                    onMouseClick={this.onMouseClick}
                                    reportDetails={reportDetails}
                                    settings={this.props.settings}
                                    ref={(ref) => { this.tabularDataTable = (ref) }}
                                /> : undefined 
                                }
                            </div>
                            <ScrollBar scrollId='fundamental-scroll' vScroll={true} hScroll={true} />
                        </div>
                    </div>
                </div>
            </div>)
        }
    }
}
