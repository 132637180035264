import { put, call, select, takeLatest, delay } from 'redux-saga/effects';
import { extend } from 'underscore';
import { ComparisonAndRotationMenuConstants } from '../../../Constants/RotationGraphConstants';
const { ActionTypes } = ComparisonAndRotationMenuConstants;
import { ComparisonGraph } from '../../../Constants/ComparisonGraph';
import {handleSymbolsInputLimit} from '../MenuPanel/SymbolsGroupsCommonSaga';
import CompareTabType from "CompareTabType";
import ConsoleApi from "../../../ServiceApi/Apis/ConsoleApi";
import SymbolTypeEnum from '../../../Constants/SymbolType';
import { addSymbolToMenu } from '../../../Sagas/NavCompare/MenuPanel/SymbolsPanelSaga';
import { getActiveCompNavTabName } from '../../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import { getRotationGraphMenuData } from '../../../Reducers/NavCompare/RotationGraphReducers/reselectorsdata';
import { getCompChartListItem } from '../../../Reducers/NavCompare/ComparisonGraphReducers/selectors';
import { getStockInfo } from '../ComparisionGraph/chartViewModel';
import UserInfoUtil from '../../../Utils/UserInfoUtil';
import stringUtil from '../../../Utils/StringUtil';
import LocalizationStore from "LocalizationStore";
extend(ActionTypes, ComparisonGraph.ActionTypes)

/***************************** Subroutines ************************************/
let symbolFromSearchData = null;
let searchResults = [];
function* handleLoading(isLoading = false) {
  try {
    yield put({
      type: ActionTypes.MENU_LOADING,
      isLoading
    });
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, handleLoading ${error}`);
  }
}

function* calculateMenuSize(result) {
  let columnWidths = {};
  let itemLengths = {};
  try {
    if (result.results.length > 0) {
      let results = result.results;
      let searchResult = results.map((item) =>
        ({
          localSymbol: item.localSymbol,
          symbol: item.Symbol,
          name: item.CompanyName ? item.CompanyName.trim() : "",
          exchange: item.exchange ? item.exchange.trim() : ""
        })
      );
      searchResult.map((item) => {
        for (let key in item) {
          let itemValue = item[key] !== null && item[key] !== undefined ? item[key].trim() : '';
          if (!itemLengths[key]) {
            itemLengths[key] = 0;
          }
          if (itemValue.length + 5 >= itemLengths[key]) {
            itemLengths[key] = itemValue.length;
            if (!columnWidths[key]) {
              columnWidths[key] = 0;
            }
            let itemWidth = itemValue.getTextWidthWithStyle("font-zise:14px; font-family:calibrib;") + 15;
            if (columnWidths[key] < itemWidth) {
              columnWidths[key] = itemWidth;
            }
          }
        }
      });
    } else {
      results = null;
      columnWidths = null;
    }
    return columnWidths;
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, calculateMenuSize${error}`);
  }

}
function* calculateMenuWidth(columnWidths) {
  let menuWidth = 0;

  try {
    for (let index in columnWidths) {
      menuWidth = menuWidth + columnWidths[index];
    }
    if (menuWidth < 420) {
      menuWidth = 420;
    }
    return menuWidth;
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, calculateMenuWidth${error}`);
  }
}

function* searchSymbolFromAPI(action) {
  const selectedTab = yield select(getActiveCompNavTabName);
  let rgview = 0, isfinal = 0;
  if (selectedTab == CompareTabType.RotationGraph) {
    rgview = 1;
    isfinal = 1;
  }

  const { text } = action;
  yield put({
    type: ActionTypes.SEARCH_SYMBOL,
    text
  });
  if ((text).replace(/\s/g, "") === "") {
    let returnData = {
      resultAvail: false,
      searchResult: ''
    };
    yield put({
      type: ActionTypes.SEARCH_SYMBOL_API_SUCCESS,
      returnData
    });
    return;
  }
  try {
    symbolFromSearchData = null;
    const searchSymbol = text.replace(/\s/g, "");
    let result = yield call(ConsoleApi.getSymbolSearchRequest, searchSymbol, '', rgview, isfinal);
    let searchResult;
    let resultAvail;
    let columnWidths;
    let menuWidth;
    if(result && result.results.length > 0){
      result.results = result.results.filter((r) => r.active);
    }
    if (result && result.results.length > 0) {
      searchResults = result.results;
      yield call(processSearchData, result.results[0]);
      resultAvail = true;
      searchResult = result;
      columnWidths = yield call(calculateMenuSize, result);
      menuWidth = yield call(calculateMenuWidth, columnWidths);
    } else {
      searchResults = [];
      resultAvail = false;
      searchResult = '';
      columnWidths = 0;
      menuWidth = 0;
      symbolFromSearchData = '';
    }

    let returnData = {
      searchResult: searchResult,
      searchSymbol: text,
      resultAvail: resultAvail,
      columnWidths: columnWidths,
      menuWidth: menuWidth
    };
    yield put({
      type: ActionTypes.SEARCH_SYMBOL_API_SUCCESS,
      returnData
    });
  } catch (error) {
    yield put({
      type: ActionTypes.SET_ERROR,
      hasErrors: true,
      errorMsg: "Error Occured"
    })

    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, handleAddSymbol${error}`);
  }
}

function processSearchData(result) {
  symbolFromSearchData = {
    Osid: result.Osid,//result.symTypeId == 8 ? 40000000 + Number(result.instrumentId) : result.symTypeId == 28 || result.symTypeId == 29 ? 50000000 + Number(result.instrumentId) : result.instrumentId,
    SymTypeEnum: result.SymTypeEnum,
    Symbol: result.Symbol,
    LocalSymbol: result.localSymbol,
    CoName: result.CompanyName,
    ctrycode: result.CountryCode,
    wonrgcode: result.wonRGCode,
    MsId: result.MsId,
    refSymbol: result.refSymbol,
    sedol: result.sedol,
    bloombergSymbol: result.bloombergSymbol,
    cusip: result.cusip,
    isin: result.Isin,
    ErrorMsg: null
  };
}

/* Function written to check whther the entered string in the search box has only spaces. */
function containsOnlySpaces(str) {
  return /^\s*$/.test(str);
}

function* addSymbolOnEnter(action) {
  const selectedTab = yield select(getActiveCompNavTabName);

  const isLimitExceeded = yield call(handleSymbolsInputLimit, 1);
  if (isLimitExceeded)
    return;

  let text = action.symbol;
  if (text === '' || text === undefined) {
    return;
  }
  
  /* If only spaces are entered in the search box show invalid entry error */
  const containsSpaceStr = containsOnlySpaces(text)
  if (containsSpaceStr) {
    yield put({
      type: ActionTypes.SET_ERROR,
      hasErrors: true,
      errorMsg: "Invalid entry"
    })
    return;
  }

  if (selectedTab == CompareTabType.RotationGraph) {
    if (symbolFromSearchData == '') {
      yield call(addSymbol, '', selectedTab);
    }
    else if (symbolFromSearchData == null) {
      yield delay(100);
      yield call(addSymbolOnEnter, action);
    }
    else {
      yield call(addSymbol, text, selectedTab);
    }
  }
  else {
    yield call(addSymbol, text, selectedTab);
  }
}

function* addSymbol(text, selectedTab) {
  try {
    const symbolResult = yield call(validateSymbol, text, symbolFromSearchData, selectedTab);

    //Adding condition to check if searched symbol is Cashspot
    if (symbolResult.result?.SymTypeEnum === SymbolTypeEnum.CASHSPOTS) {
      yield put({
        type: ActionTypes.SET_ERROR,
        hasErrors: true,
        errorMsg: "Invalid entry"
      })
    }
    else if (!symbolResult.hasErrors) {
      yield put({
        type: ActionTypes.CLEAR_SYMBOL_SEARCH_TEXT,
      });

      if (selectedTab == CompareTabType.ComparisonGraph) {
        yield put({
          type: ActionTypes.ADD_TO_LIST,
          request: symbolResult
        })
        yield put({
          type: ActionTypes.SET_LISTALERT_MSG,
          alertMessage: "1 symbol is added."
        })
      }
      else {
        yield call(addSymbolToMenu, symbolFromSearchData);
      }

      yield put({
        type:ActionTypes.SET_ADDLIST_LISTNAME,
        listName:""
      })

      yield put({ type: ActionTypes.SAVE_SETTINGS });
    
    }
    else {
      yield put({
        type: ActionTypes.SET_ERROR,
        hasErrors: symbolResult.hasErrors,
        errorMsg: symbolResult.errorMsg
      })
    }
  } catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, handleEnterSearch${error}`);
  }
}

function* handleEnterSymbolAdd({ index }) {

const isLimitExceeded = yield call(handleSymbolsInputLimit, 1);
  if (isLimitExceeded)
    return;

  let arr = [];
  arr['symbol'] = index.Symbol;
  yield call(addSymbolData, arr);
}

function* addSymbolData({ symbol }) {
  const selectedTab = yield select(getActiveCompNavTabName);
  if (searchResults.length > 0) {
    const searchResult = _.filter(searchResults, (result) => result.Symbol === symbol)[0];
    yield call(processSearchData, searchResult)
  }
  const symbolResult = yield call(validateSymbol, symbol, symbolFromSearchData, selectedTab);
  
  try {
    if (selectedTab === CompareTabType.ComparisonGraph) {
      if (!symbolResult.hasErrors) {
        yield put({
          type: ActionTypes.CLEAR_SYMBOL_SEARCH_TEXT,
        })
        
        yield put({
          type: ActionTypes.ADD_TO_LIST,
          request: symbolResult
        })
        yield put({
          type: ActionTypes.SET_LISTALERT_MSG,
          alertMessage: "1 symbol is added."
        })
      } else {
        yield put({
          type: ActionTypes.SET_ERROR,
          hasErrors: symbolResult.hasErrors,
          errorMsg: symbolResult.errorMsg
        })
      }
    } else {
      yield put({
        type: ActionTypes.ADD_SYMBOL_ON_ENTER,
        symbol: symbol
      });
    }
    yield put({
      type:ActionTypes.SET_ADDLIST_LISTNAME,
      listName:""
    })

    yield put({ type: ActionTypes.SAVE_SETTINGS });
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, addSymbolData${error}`);
  }
}

function* addListSymbolsData({ symbols }) {
  let symbolsResult = [];
  try {
    //   if (Array.isArray(symbols)) {
    for (let i = 0; i < symbols.length; i++) {
      symbolsResult.push({
        result: {
          CoName: null,
          LocalSymbol: null,
          Osid: symbols[i]["osid"],
          SymTypeEnum: symbols[i]["symTypeEnum"],
          Symbol: null
        }
      });
    }
    // } 

    if (symbolsResult.length > 0) {
      yield put({
        type: ActionTypes.ADD_TO_LIST,
        request: symbolsResult,
      })

    } else {
      yield call(handleLoading, false);
    }
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, addSymbolData${error}`);
  }
}

function isHistoricStocks(symbolType) {
  return symbolType == SymbolTypeEnum.HISTORICSTOCK || symbolType == SymbolTypeEnum.HISTORICINDEX || symbolType == SymbolTypeEnum.HISTORICALMODELSTOCK || symbolType == SymbolTypeEnum.MODELSTOCK;
}

function* isSymbolInList(sym) {
  try {
    const selectedTab = yield select(getActiveCompNavTabName);
    const LineCollection = yield select(getCompChartListItem);
    const rotationMenuData = yield select(getRotationGraphMenuData);
    const result = selectedTab === CompareTabType.ComparisonGraph ? LineCollection.find((itm) => itm.Osid === sym.Osid) : rotationMenuData.stockList.find((item) => item.osid === sym.Osid);
    return !result;
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, isSymbolInList${error}`);
  }

}

function* validateSymbol(sym, symbolData = null, selectedTab) {
  let symbol = sym.trim();
  let validationResult = {
    hasErrors: false,
    errorMsg: "",
    result: null
  }

  try {
    const symUpperCase = sym.toUpperCase();
    if (symbolData == '' && selectedTab == CompareTabType.RotationGraph) {
      validationResult.hasErrors = true;
      validationResult.errorMsg = "Symbol not supported";
      return validationResult;
    }
    else if(selectedTab === CompareTabType.RotationGraph && 
            !(symUpperCase === symbolData.Symbol || symUpperCase === symbolData.LocalSymbol || 
              parseInt(sym) === symbolData.Osid || symUpperCase === symbolData.cusip || symUpperCase === symbolData.sedol ||
              symUpperCase === symbolData.isin || symUpperCase === symbolData.bloombergSymbol || symUpperCase === symbolData.refSymbol)){
      validationResult.hasErrors = true;
      validationResult.errorMsg = "Symbol not supported";
      return validationResult;
    }
    if (symbol.includes("[") || symbol.includes("]")) {
      if (!symbol.startsWith("[") || !symbol.endsWith("]")) {
        validationResult.hasErrors = true;
        validationResult.errorMsg = LocalizationStore.getTranslatedData("CCG_InvalidEntry", "Invalid Entry.");
        return validationResult;
      }
      else {
        symbol = symbol.substring(1, symbol.length - 2);
      }
    }

    let stockInfo = symbolData;
    if (symbolData == null) {
      stockInfo = yield call(getStockInfo, symbol);
    }

    let isUserEntitledToSnP = UserInfoUtil.IsUserEntitledtoSnP();
    if (stockInfo.SymTypeEnum == SymbolTypeEnum.INDEX || stockInfo.SymTypeEnum == SymbolTypeEnum.INTERNATIONALINDEX || stockInfo.SymTypeEnum == SymbolTypeEnum.HISTORICINDEX) {
      if (!isUserEntitledToSnP) {
        validationResult.hasErrors = true;
        validationResult.errorMsg = LocalizationStore.getTranslatedData("CCG_InvalidEntry", "Invalid Entry.");;
        return validationResult;
      }
    }
    if (!stockInfo || (stockInfo.ErrorMsg !== "" && stockInfo.ErrorMsg !== null) || stockInfo.SymTypeEnum == SymbolTypeEnum.SymbolTypeEnumUndefined) {
      validationResult.hasErrors = true;
      validationResult.errorMsg = LocalizationStore.getTranslatedData("CCG_InvalidEntry", "Invalid Entry.");
      return validationResult;
    }
    else {
      const comparisonRequest = { Osid: stringUtil.convertFromLongValueToInt(stockInfo.Osid), SymTypeEnum: stockInfo.SymTypeEnum, Symbol: stockInfo.Symbol, LocalSymbol: stockInfo.LocalSymbol, CoName: stockInfo.CompanyName };
      const isSymolinList = yield call(isSymbolInList, comparisonRequest);
      if (!isSymolinList) {
        validationResult.hasErrors = true;
        validationResult.errorMsg = LocalizationStore.getTranslatedData("CCG_DuplicateSymbol", "Duplicate Symbol.");
      }
      else if (isHistoricStocks(comparisonRequest.SymTypeEnum)) {
        validationResult.hasErrors = true;
        validationResult.errorMsg = LocalizationStore.getTranslatedData("CCG_InactiveSymbol", "Inactive symbols are not supported.");
      }
      else {
        validationResult.hasErrors = false;
        validationResult.errorMsg = "";
        validationResult.result = comparisonRequest;
        validationResult.apiResponse = stockInfo;
      }
    }
    return validationResult;
  }
  catch (error) {
    yield put({
      type: ActionTypes.HANDLE_ERROR,
      hasError: true,
      errorMsg: error
    })
    console.log(`Error occurs in AddSymbol.js, validateSymbol${error}`);
  }

}

/******************************************************************************/
/******************************* WATCHERS *************************************/
/******************************************************************************/

export function* watchHandleSearchSymbolFromAPI() {
  yield takeLatest(ActionTypes.SEARCH_SYMBOL_API, searchSymbolFromAPI);
}

export function* watchAddSymbolOnEnter() {
  yield takeLatest(ActionTypes.ADD_SYMBOL_ON_ENTER, addSymbolOnEnter);
}

export function* watchHandleAddSymbolList() {
  yield takeLatest(ActionTypes.ADD_SYMBOL, addSymbolData)
}

export function* watchHandleAddListSymbols() {
  yield takeLatest(ActionTypes.ADD_LIST_SYMBOLS, addListSymbolsData)
}
export function* watchHandleAddSymbolListWithIndex() {
  yield takeLatest(ActionTypes.ADD_SYMBOL_BY_INDEX, handleEnterSymbolAdd);
}