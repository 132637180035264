import Serializable from "Serializable";
export default class RSiChartSettings extends Serializable {
  constructor() {
    super();
  }

  getDefaultLength() {
    return 14;
  }

  getDefaultOverbought() {
    return 70;
  }

  getDefaultOversold() {
    return 30;
  }

  getDefaultRSILineObj() {
    const RSILine = [{ isActive: true, color: "PCCCCCC262626", weight: 2 }];
    return RSILine.map((item) => this.getSerizableObject(item));
  }

  getDefaultOverboughtObj() {
    const OverboughtObj = [{ isActive: false, color: "P670000FF0000", weight: 1 }];
    return OverboughtObj.map((item) => this.getSerizableObject(item));
  }

  getDefaultOversoldObj() {
    const OversoldObj = [{ isActive: false, color: "P00660034FF33", weight: 1 }];
    return OversoldObj.map((item) => this.getSerizableObject(item));
  }

  getSerizableObject(obj) {
    return Object.assign(new Serializable, { ...obj });
  }

  getDefaultseqNumber(){
    return 3;
  }
  onSeralisedInfoRecieved(serializedValue) {
    this.IsVisible = serializedValue.IsVisible ? serializedValue.IsVisible : false;
    this.Height = serializedValue.Height ? serializedValue.Height : 100;
    this.IsAvailable = serializedValue.IsAvailable ? serializedValue.IsAvailable : false;
    this.IsRelative = serializedValue.IsRelative ? serializedValue.IsRelative : false;
    this.Length = serializedValue.Length ? serializedValue.Length : this.getDefaultLength();
    this.Overbought = serializedValue.Overbought ? serializedValue.Overbought : this.getDefaultOverbought();
    this.Oversold = serializedValue.Oversold ? serializedValue.Oversold : this.getDefaultOversold();
    this.RSILineColor = serializedValue.RSILineColor ? serializedValue.RSILineColor : this.getDefaultRSILineObj();
    this.OverboughtColor = serializedValue.OverboughtColor ? serializedValue.OverboughtColor : this.getDefaultOverboughtObj();
    this.OversoldColor = serializedValue.OversoldColor ? serializedValue.OversoldColor : this.getDefaultOversoldObj();
    this.isApplyToAllIntervals = serializedValue.isApplyToAllIntervals ? serializedValue.isApplyToAllIntervals : false;
    this.seqNumber = serializedValue.seqNumber ? serializedValue.seqNumber : this.getDefaultseqNumber();
  }

  getDataObject() {
    return {
      IsVisible: this.IsVisible,
      Height: this.Height,
      IsAvailable: this.IsAvailable,
      IsRelative: this.IsRelative,
      Length: this.Length,
      Overbought: this.Overbought,
      Oversold: this.Oversold,
      RSILineColor: this.RSILineColor,
      OverboughtColor: this.OverboughtColor,
      OversoldColor: this.OversoldColor,
      isApplyToAllIntervals: this.isApplyToAllIntervals,
      seqNumber : this.seqNumber
    }
  }

  getDefaultRSiChartSettings() {
    this.IsVisible = true;
    this.Height = 100;
    this.IsAvailable = true;
    this.IsRelative = false;
    this.Length = this.getDefaultLength();
    this.Overbought = this.getDefaultOverbought();
    this.Oversold = this.getDefaultOversold();
    this.RSILineColor = this.getDefaultRSILineObj();
    this.OverboughtColor = this.getDefaultOverboughtObj();
    this.OversoldColor = this.getDefaultOversoldObj();
    this.seqNumber = this.getDefaultseqNumber();
    this.isApplyToAllIntervals = false;
    return this;
  }

  getObjectMapKey() {
    return "RSiChartSettings";
  }
}
