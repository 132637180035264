import AppDispatcher from "AppDispatcher";
import {ExportExcelConstants } from "../Constants/ExportExcelConstants";

import ListStore from "ListStore";
import GridStore from "GridStore";
import ONeilViewStore from "ONeilViewStore";
// import OwnershipViewStore  from "../Stores/NavModules/NavList/TabOwnership/OwnershipViewStore";
import ListExplorerStore from "ListExplorerStore";
import OwnershipViewStore from 'OwnershipViewStore';
import ExportExcelStore from "../Stores/NavModules/NavList/ExportExcel/ExportExcelStore";
import ListApi from "ListApi";
import StringUtil from 'StringUtil';
import { clone } from "underscore";
import BaseServiceApi from 'BaseServiceApi'
import UserInfoUtil from 'UserInfoUtil'

class ExportExcelActionClass{
    exportData = async (exportType, categoryType=null, nodeId=null, sourceListId=null, fileName=null)=>{
        //console.log("You are exporting %s", exportType);
        //Trigger the export progress bar.
        AppDispatcher.handleViewAction({
            actionType:ExportExcelConstants.ActionTypes.EXPORT_EXCEL_START,
            data: { exportType: exportType, categoryType:categoryType, nodeId:nodeId, sourceListId:sourceListId, fileName:fileName}
        })

        await getData(exportType);
    }

    showExportDialog=(categoryType, nodeId, sourceListId,fileName)=>{
        //console.log("Show Export Excel Dialog.");
        AppDispatcher.handleViewAction({
            actionType: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_SHOW_DIALOG,
            data:{categoryType: categoryType, nodeId: nodeId, sourceListId: sourceListId, fileName : fileName}
        })
    }
}

function getData(type) {
    const ListCategoryType = BaseServiceApi.rayData["ListCategoryType"];
    const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
    const exportExcelStore = ExportExcelStore.getState();
    const categoryType = exportExcelStore.categoryType;
    const selectedNodeId = exportExcelStore.nodeId;
    const sourceListId = exportExcelStore.sourceListId;
    const listStore = ListStore.getState();
    const currentDataBase = listStore.SelectedTabKey;
    let hasGicsEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.GICS_Entitlement);
    const exportAll = GridStore.getAllRowsSelectedStatus();
    const lang = UserInfoUtil.getCurrentLang();
    let isCryptoCurrencyList = ListExplorerStore.getIsCryptoCurrencyList();
    // let state2 = ONeilViewStore.getState();
    // console.log(state2)
    if (currentDataBase && currentDataBase.toUpperCase() === "OWNERSHIP") {
        let curractiveList = OwnershipViewStore.activeNode
        let nodeId = StringUtil.isEmpty(curractiveList.nodeId.low) ? curractiveList.nodeId : curractiveList.nodeId.low;
        if (curractiveList.categoryType === ListCategoryType.FAVORITE_ListCategory) {
            nodeId = curractiveList.sourceListId.low ? curractiveList.sourceListId.low : curractiveList.sourceListId
        }
        if (Boolean(selectedNodeId)) {
            nodeId = selectedNodeId;
            if (categoryType === ListCategoryType.FAVORITE_ListCategory) {
                nodeId = sourceListId;
            }
        }
        // let ownershipState = OwnershipViewStore.getState();
        // let ownerESBasicInfo = ownershipState.basicEsInfo;
        hasGicsEntitlement = false;
        const listId = nodeId;
        const selectedMsids = ""
        const actualListId = nodeId;
        const searchText = "";
        const sortCols = null;
        const needColumnsInfo = 1;
        const useColFilters = OwnershipViewStore.getScreenOnOffValue()? 1:0;
        const saveColFilters = 0;
        const isMinilist = 0;
        let isUserList = 0;
        if (Boolean(selectedNodeId)) {
            isUserList = categoryType == ListCategoryType.USER_ListCategory ? 1 : 0;
        }
        else{
            isUserList = curractiveList.categoryType == ListCategoryType.USER_ListCategory ? 1 : 0;
        }
        const customerOrder = 0;
        const pageIndex = 0;
        const size = 10000;
        const colsetId = -1;
        const addlCatalogRequests = null;
        const isNotImportedList = categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory;

        if (Boolean(selectedNodeId)) {
            //Export on Tree List
            ListApi.exportOwnershipList(listId, type, exportAll, selectedMsids, currentDataBase, hasGicsEntitlement, actualListId, searchText,
            sortCols, needColumnsInfo, useColFilters, saveColFilters, isMinilist, isUserList, customerOrder, pageIndex, size, colsetId, addlCatalogRequests, lang, isNotImportedList).promise.then(
                (result) => {
                    AppDispatcher.handleViewAction({
                        actionType: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_END,
                        data: result
                    })
                }
            );
        }
        else {
            //Export by clicking the gear in upper right corner 
            //Export on list rows.
            OwnershipViewStore.getMsIds(GridStore.getSelectedIndexes(), 'sponsorId').then(
                (result) => {
                    result = exportAll ? 0 : result;
                    ListApi.exportOwnershipList(listId, type, exportAll, result, currentDataBase, hasGicsEntitlement, actualListId, searchText,
                        sortCols, needColumnsInfo, useColFilters, saveColFilters, isMinilist, isUserList, customerOrder, pageIndex, size, colsetId, addlCatalogRequests, lang, isNotImportedList).promise.then(
                            (result) => {
                                AppDispatcher.handleViewAction({
                                    actionType: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_END,
                                    data: result
                                })
                            }
                        )
                });
        }
    }
    else {
        let curractiveList = ListExplorerStore.activeNode
        if (Boolean(ListStore.getSelectedListItem().SelectedListId) &&
            Boolean(ListExplorerStore.getActiveNode(ListStore.getSelectedListItem().SelectedListId))) {
            curractiveList = ListExplorerStore.getActiveNode(ListStore.getSelectedListItem().SelectedListId)
        }
        let nodeId = StringUtil.isEmpty(curractiveList.nodeId.low) ? curractiveList.nodeId : curractiveList.nodeId.low;
        if (curractiveList.categoryType === ListCategoryType.FAVORITE_ListCategory) {
            nodeId = curractiveList.sourceListId.low ? curractiveList.sourceListId.low : curractiveList.sourceListId
        }
        if (Boolean(selectedNodeId)) {
            nodeId = selectedNodeId;
            if (categoryType === ListCategoryType.FAVORITE_ListCategory) {
                nodeId = sourceListId;
            }
        }
        //console.log("nodeId",nodeId)
        const basicEsInfo = clone(ONeilViewStore.getBasicEsInfo());
        const browserFilters = basicEsInfo.browserFilter;
        const igOneilbrowserFilters = basicEsInfo.oneilBrowserFilter;
        const igGicsbrowserFilters = basicEsInfo.gicsBrowserFilter;
        const isOneilselected = basicEsInfo.isOneilSel;
        const isGicsselected = basicEsInfo.isGicsSel;
        let actualListId = curractiveList.sourceListId ? curractiveList.sourceListId : nodeId;
        if (Boolean(selectedNodeId)) {
            actualListId = nodeId;
            curractiveList = ListExplorerStore.getActiveNode(nodeId)
        }

        const isNotImportedList = curractiveList && curractiveList.categoryType === ListCategoryType.NOTIMPORTEDLIST_ListCategory;

        const esbasicinfo = ONeilViewStore.getBasicEsInfoforCopy(nodeId, actualListId, -1, isOneilselected, isGicsselected, browserFilters, igOneilbrowserFilters, igGicsbrowserFilters, curractiveList);
        esbasicinfo.actualListId = actualListId;
        esbasicinfo.listId = nodeId;
        esbasicinfo.isNotImportedList = isNotImportedList;
        //currentDataBase = "OWNERSHIP";
        if (Boolean(selectedNodeId)) {
            //Export on Tree List
            ListApi.exportList(esbasicinfo, type, exportAll, "", currentDataBase, hasGicsEntitlement, lang, isCryptoCurrencyList).promise.then(
                (result) => {
                    AppDispatcher.handleViewAction({
                        actionType: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_END,
                        data: result
                    })
                }
            )
        }
        else {
            //Export by clicking the gear in upper right corner 
            //Export on list rows.
            ONeilViewStore.getMsIds(GridStore.getSelectedIndexes()).then(
                (result) => {
                    result = exportAll ? 0 : result;
                    ListApi.exportList(esbasicinfo, type, exportAll, result, currentDataBase, hasGicsEntitlement, lang, isCryptoCurrencyList).promise.then(
                        (result) => {
                            AppDispatcher.handleViewAction({
                                actionType: ExportExcelConstants.ActionTypes.EXPORT_EXCEL_END,
                                data: result
                            })
                        });

                });
        }
    }
}

const ExportExcelAction = new ExportExcelActionClass();
export default ExportExcelAction;