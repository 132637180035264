import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class UpdateNodeDescriptionRequest extends BaseServiceRequest {

    constructor(nodeId, nodeType, newDescription) {
        super();
        super.addRequestUri("nodedetails.pb");
        super.addRequestParameter("nid", nodeId.toString());
        super.addRequestParameter("ntid", parseInt(nodeType).toString());
        super.addRequestParameter("d", newDescription);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.SHARE;
    }

    getMessageName() {
        return "Response";
    }
}

export default UpdateNodeDescriptionRequest;
