import HubIndexView from "./HubIndex";
import React from "react";
import { toggleExpandAll } from "../../../../../../Actions/RelatedInfoPanelActions/RiPanelActions";
import { useSelector, useDispatch } from 'react-redux';

const HubToolbar = () => {

    const data = useSelector((state) => state.RelatedInfoPanelReducers.RiPanelHub);
    const dispatch = useDispatch();

    const { HubDatasource, expanded } = data;
    const noContactData = HubDatasource.length === 0;

    const onExpandClicked = () => {
        dispatch(toggleExpandAll());
    }

    return (
        <>
            <div className="hub-toolbar" >
                <div>
                    {!Boolean(noContactData) && <a onClick={onExpandClicked}>{!Boolean(expanded) ? "Expand All" : "Collapse All"}</a>}
                </div>

                <HubIndexView />
            </div >
        </>
    )

}

export default HubToolbar