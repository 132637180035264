import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale";
import BlockType from "../../../../Constants/BlockType";
import { CustomTiDialogConstant } from "../../../../Constants/CustomTiDialogConstants";
import { DatagraphConst } from "../../../../Utils/DatagraphHelper";
import GraphType from "GraphType";
import { IndicatorsConstants } from "../../../../Constants/NavDataGraph/TabDataGraph/Indicators/IndicatorsConstants";
import { PriceChartConstants } from "../../../../Constants/PriceChartConstants";
import SettingsStore from "SettingsStore";
import SymbolType from "SymbolType";
import ThemeHelper from "ThemeHelper";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { getDatagraphStates, getIndicatorStates } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { IndicatorCommonTranslateHelper, IndicatorLabelTranslateHelper } from "../../../../Utils/TranslateHelper";
import { updateIndicatorData, updateIndicatorGraphData, updateIndicatorSettings } from "../../../../Actions/NavDataGraph/TabDataGraph/Indicators/IndicatorActions";

const { ActionTypes } = IndicatorsConstants;
let prevMA = 3;
function getStochasticsChart(hsfResults, slow, firstNodeIndex, startXPoint, scale, isWonStoch) {
    let prcLength = hsfResults.length;
    const chartData = [];
    const property = isWonStoch ? slow ? 'WR_Line' : 'WK_Line' : slow ? "R_Line" : "K_Line";
    for (let j = firstNodeIndex; j < prcLength; j++) {
        if (startXPoint < 0) { break; }
        const val = hsfResults[j][property];
        if (isNaN(val)) {
            if (hsfResults[j].Close && hsfResults[j].Close > 0) {
                chartData.push({
                    Date: hsfResults[j].Date,
                    yPrice: 0,
                    xAxis: startXPoint,
                    yValue: -1
                });
            }
            startXPoint -= DatagraphConst.nodeWidth;
            continue;
        }
        const yPrice = scale.ComputeY(val);
        const info = {
            Date: hsfResults[j].Date,
            yPrice: yPrice,
            xAxis: startXPoint,
            yValue: val
        };
        startXPoint -= DatagraphConst.nodeWidth;
        chartData.push(info);
    }
    prcLength = chartData.length;
    for (let i = prcLength - 1; i > 0; i--) {
        if (chartData[i].yValue < 0) {
            chartData[i].yValue = chartData[i + 1] && chartData[i + 1].yValue;
            chartData[i].yPrice = chartData[i + 1] && chartData[i + 1].yPrice;
        }
    }
    return chartData;
}

function xMin(values, st, range) {
    let i = 0;
    let min = (values[st].Low === 0 ? values[st + 1].Low : values[st].Low);
    for (i = st; values[i] && i < st + range; i++) {
        if (values[i].Low > 0 && min > values[i].Low) {
            min = values[i].Low;
        }
    }
    return min;
}

function xMax(values, st, range) {
    let i = 0;
    let max = values[st].High;
    for (i = st; values[i] && i < st + range; i++) {
        if (values[i].High > 0 && max < values[i].High) {
            max = values[i].High;
        }
    }
    return max;
}

function CalculateStochastics(inhsfResults, range = 14, movingAverage = 3, isSlow = false, SettingDict) {
    range = parseInt(range);
    movingAverage = parseInt(movingAverage);
    const hsfResults = inhsfResults.filter((t) => t._volume !== undefined && t._volume > -1 && t.Close > 0);
    let tot = hsfResults.length;
    const total = Math.floor(hsfResults.length / 2) + 1;
    if (tot < 1) {
        return;
    }
    const kFast = [];
    let count = 0;

    if (total < range) {
        range = total;
        SettingDict.Length = total;
        SettingsStore.saveSettings();
    }
    if (total < movingAverage) {
        movingAverage = total;
        SettingDict.MovingAverage = total;
        SettingsStore.saveSettings();
    }

    if (movingAverage !== 0) {
        prevMA = movingAverage;
    }
    if (movingAverage === 0) {
        movingAverage = prevMA;
    }

    for (; count < tot; count++) {
        if (count + range > tot) {
            break;
        }
        if (hsfResults[count].Close <= 0 || hsfResults[count].Close === undefined) {
            continue;
        }
        const xMn = xMin(hsfResults, count, range);
        const xMx = xMax(hsfResults, count, range);
        const dif = xMx - xMn;
        kFast[count] = (dif > 0 ? 100 * (hsfResults[count].Close - xMn) / dif : 0);
    }
    tot = hsfResults.length - (movingAverage + 1);
    if (tot < 1) { return; }
    for (; tot >= 0 && hsfResults[tot] === null || hsfResults[tot].Close <= 0; tot--) { ; }
    let i;
    let lastUpd = tot;
    for (i = tot; i >= 0 && hsfResults[i].Close > 0; i--) {
        if (movingAverage === 0) {
            continue;
        }
        let kVal = 0.0;
        for (let ix = i; ix < i + movingAverage; ix++) {
            kVal += kFast[ix];
        }

        kVal /= movingAverage;
        if (kVal === 0 || isNaN(kVal)) {
            kVal = undefined;
        }
        hsfResults[i].K_Line = isSlow ? kVal : kFast[i];

        let rVal = 0.0;

        for (let ix = i; ix < i + movingAverage; ix++) {
            rVal += hsfResults[ix].K_Line;
        }

        rVal /= movingAverage;
        if (rVal === 0 || isNaN(rVal)) {
            rVal = undefined;
        }
        hsfResults[i].R_Line = isSlow ? rVal : kVal;

        const xPosition = contains(inhsfResults, hsfResults[i].Date);
        if (xPosition > -1) {
            inhsfResults[xPosition].K_Line = hsfResults[i].K_Line;
            inhsfResults[xPosition].R_Line = hsfResults[i].R_Line;

            for (let x = lastUpd; x > xPosition; x--) {
                if (x < tot && inhsfResults[x + 1] !== null && inhsfResults[x]._volume < 0) {
                    inhsfResults[i].K_Line = inhsfResults[i + 1].K_Line;
                    inhsfResults[i].R_Line = inhsfResults[i + 1].R_Line;
                }
            }
            lastUpd = xPosition;
        }
    }
    // tot = inhsfResults.length;
    // for (i = 0; i < tot; i++) {
    //     if (!inhsfResults[i].IsVisible) {
    //         continue;
    //     }
    //     if (inhsfResults[i].K_Line === undefined && inhsfResults[i + 1]) {
    //         inhsfResults[i].K_Line = inhsfResults[i + 1].K_Line;
    //         inhsfResults[i].R_Line = inhsfResults[i + 1].R_Line;
    //     }
    // }

    return inhsfResults;
}

function wMin(values, st, range) {
    let i = 0;
    let min = (values[st].Close === 0 ? values[st + 1].Close : values[st].Close);
    for (i = st; i < st + range; i++) {
        if (values[i].Close > 0 && min > values[i].Close) { min = values[i].Close; }
    }
    return min;
}

function wMax(values, st, range) {
    let i = 0;
    let Max = values[st].Close;
    for (i = st; i < st + range; i++) {
        if (values[i].Close > 0 && Max < values[i].Close) { Max = values[i].Close; }
    }
    return Max;
}

function wCalculateStochastics(stockQuotes, range = 14, isSlow = true) {
    let tot = stockQuotes.length;
    for (let i = 0; i < tot; i++) {
        if (!isNaN(stockQuotes[i].WK_Line)) {
            stockQuotes[i].WK_Line = undefined;
            stockQuotes[i].WR_Line = undefined;
        }
    }

    if (tot < 1  || tot < range) { return stockQuotes; }
    const rValues = [];
    let count = 0;

    for (; count < tot && !stockQuotes[count].IsVisible; count++) { ; }

    for (; count < tot; count++) {
        if (count + range > tot) { break; }
        const nc = wMin(stockQuotes, count, range);
        const a = wMax(stockQuotes, count, range) - nc;
        rValues[count] = (a > 0 ? 100 * (stockQuotes[count].Close - nc) / a : 0);
    }

    tot = rValues.length;
    if (tot < 1) { return stockQuotes; }
    stockQuotes[tot - 1].WK_Line = Math.trunc(rValues[tot - 1], 0);
    stockQuotes[tot - 1].WR_Line = Math.trunc(rValues[tot - 1], 0);

    tot = rValues.length - 2;
    const movingAverage = 3;
    for (; tot >= 0 && stockQuotes[tot] === null || stockQuotes[tot].Close <= 0; tot--) { ; }
    for (let i = tot; i >= 0 && stockQuotes[i].Close > 0; i--) {
        const kVal = Math.trunc(stockQuotes[i + 1].WK_Line * 2 / movingAverage, 0) + Math.trunc(rValues[i] / movingAverage, 0);
        const rVal = Math.trunc(stockQuotes[i + 1].WR_Line * 2 / movingAverage, 0) + Math.trunc(kVal / movingAverage, 0);

        stockQuotes[i].WK_Line = isSlow ? kVal : Math.trunc(rValues[i], 0);
        stockQuotes[i].WR_Line = isSlow ? rVal : kVal;
    }

    return stockQuotes;
}

function contains(a, obj) {
    let i = a.length;
    while (i--) {
        if (a[i].Date === obj) {
            return i;
        }
    }
    return -1;
}

function initStochasticsScale(graphType, indicatorsHeight) {
    const scale = new ArithmaticScale();
    scale.InitScale(-20, 100, indicatorsHeight, graphType, 2, 28 * DatagraphConst.nodeWidth, SymbolType.USSTOCK, NaN, NaN, true, true);
    return scale;
}

function* PrepareData({ chartType }) {
    try {
        const { SettingDict } = yield select(getIndicatorStates);
        const { pricePanelData, indicatorsHeight, startXPoint, padding } = yield select(getDatagraphStates);
        const graphData = BlockType.WonStochastics === chartType ? wCalculateStochastics(pricePanelData.HsfData.HSFResults, SettingDict[BlockType.WonStochastics].Length) :
            CalculateStochastics(pricePanelData.HsfData.HSFResults, SettingDict[BlockType.Stochastics].Length, SettingDict[BlockType.Stochastics].MovingAverage, SettingDict[BlockType.Stochastics].Slow, SettingDict[BlockType.Stochastics]);
        yield call(processData, graphData, indicatorsHeight, chartType, SettingDict[chartType], startXPoint, padding);
    }
    catch (error) {
        console.error("Error occurs in StochasticsIndicatorSaga.js, PrepareData", error);
    }
}

function* processData(graphData, indicatorsHeight, chartType, SettingDict, startXPoint, padding) {
    if (graphData) {
        const scale = initStochasticsScale(GraphType.Weekly, indicatorsHeight);
        let DataSource;
        const indicatorVisuals = { [chartType]: [] };
        const indicatorLabels = { [chartType]: { labels: [] } };

        let stochasticsLineColor, stochasticsWeight, overBoughtLineColor, overBoughtWeight, overSoldLineColor, overSoldWeight, movingAvgLineColor, movingAvgWeight;
        SettingDict.StochasticsColor.forEach((item) => {
            stochasticsLineColor = ThemeHelper.getThemedBrush(item.color);
            stochasticsWeight = item.weight;
        });
        SettingDict.OverboughtColor.forEach((item) => {
            overBoughtLineColor = ThemeHelper.getThemedBrush(item.color);
            overBoughtWeight = item.weight;
        });
        SettingDict.OversoldColor.forEach((item) => {
            overSoldLineColor = ThemeHelper.getThemedBrush(item.color);
            overSoldWeight = item.weight;
        });
        SettingDict.MovingAverageColor.forEach((item) => {
            movingAvgLineColor = ThemeHelper.getThemedBrush(item.color);
            movingAvgWeight = item.weight;
        });
        const isWonStoch = BlockType.WonStochastics === chartType;
        DataSource = scale.ComputeY(SettingDict.Oversold);
        indicatorVisuals[chartType].push({ isHorizontalLine: true, key: "Line1", x2: startXPoint, y1: DataSource, y2: DataSource, strokeWidth: 1, dashArray: [5, 2], lineColor: overSoldLineColor, LineThickness: overSoldWeight, Draggable: false, lineID: IndicatorCommonTranslateHelper[CustomTiDialogConstant.Oversold] });
        DataSource = scale.ComputeY(SettingDict.Overbought);
        indicatorVisuals[chartType].push({ isHorizontalLine: true, key: "Line2", x2: startXPoint, y1: DataSource, y2: DataSource, strokeWidth: 1, dashArray: [5, 2], lineColor: overBoughtLineColor, LineThickness: overBoughtWeight, Draggable: false, lineID: IndicatorCommonTranslateHelper[CustomTiDialogConstant.Overbought] });
        DataSource = getStochasticsChart(graphData, false, padding, startXPoint, scale, isWonStoch);
        const stochasticsLineLabel = isWonStoch || SettingDict.Slow ? IndicatorCommonTranslateHelper[CustomTiDialogConstant.Slow] : IndicatorCommonTranslateHelper[CustomTiDialogConstant.Fast];
        indicatorVisuals[chartType].push({ key: "Line3", DataSource, LineColor: stochasticsLineColor, LineThickness: stochasticsWeight, Draggable: false, lineID: `${stochasticsLineLabel}  ${SettingDict.Length}` });
        if (SettingDict.MovingAverage || isWonStoch) {
            DataSource = getStochasticsChart(graphData, true, padding, startXPoint, scale, isWonStoch);
            indicatorVisuals[chartType].push({ key: "Line4", DataSource, LineColor: movingAvgLineColor, LineThickness: movingAvgWeight, Draggable: false, lineID: `${SettingDict.MovingAverage || 3} SMA` });
        }

        indicatorLabels[chartType].isScale = true;
        indicatorLabels[chartType].class = "tech-indicators";
        indicatorLabels[chartType].LabelMenu = { label: IndicatorLabelTranslateHelper[chartType], isTICustomModal: true, isShowSettingsDialog: true }
        indicatorLabels[chartType].scaleText = `(${stochasticsLineLabel}, ${SettingDict.Length}, ${SettingDict.MovingAverage || 3})`;
        yield put(updateIndicatorData(indicatorVisuals, indicatorLabels, chartType, scale));
    }
}

function* initIndicator({ }) {
    try {
        const { pricePanelData, viewsSettings, majorPeriodicity, indicatorsHeight, startXPoint, padding } = yield select(getDatagraphStates);
        const StochasticsChartSettings = viewsSettings.StochasticsChartSettings;
        const WonStochasticsChartSettings = viewsSettings.WonStochasticsChartSettings;
        const SettingDict = {}
        SettingDict[BlockType.Stochastics] = StochasticsChartSettings ? StochasticsChartSettings[majorPeriodicity] : {};
        SettingDict[BlockType.WonStochastics] = WonStochasticsChartSettings ? WonStochasticsChartSettings[majorPeriodicity] : {};
        yield put(updateIndicatorSettings(SettingDict));
        if (SettingDict[BlockType.Stochastics].IsAvailable) {
            const graphData = CalculateStochastics(pricePanelData.HsfData.HSFResults, SettingDict[BlockType.Stochastics].Length, SettingDict[BlockType.Stochastics].MovingAverage, SettingDict[BlockType.Stochastics].Slow, SettingDict[BlockType.Stochastics]);
            yield put(updateIndicatorGraphData(graphData, BlockType.Stochastics));
            yield call(processData, graphData, indicatorsHeight, BlockType.Stochastics, SettingDict[BlockType.Stochastics], startXPoint, padding);
        }
        if (SettingDict[BlockType.WonStochastics].IsAvailable) {
            const graphData = wCalculateStochastics(pricePanelData.HsfData.HSFResults, SettingDict[BlockType.WonStochastics].Length);
            yield put(updateIndicatorGraphData(graphData, BlockType.WonStochastics));
            yield call(processData, graphData, indicatorsHeight, BlockType.WonStochastics, SettingDict[BlockType.WonStochastics], startXPoint, padding);
        }
    }
    catch (error) {
        console.error("Error occurs in StochasticsIndicatorSaga.js, initIndicator", error);
    }
}
//===================================================

export function* watchInitSTATSIndicator() {
    yield takeLatest(ActionTypes.PROCESS_INDICATOR, initIndicator)
}

export function* watchPrepareSTATSIndicatorData() {
    yield takeLatest(ActionTypes.PREPARE_STATS_INDICATOR_DATA, PrepareData);
}

export function* watchToggleSTATSChart() {
    // yield takeLatest(ActionTypes.TOGGLE_YTD_CHART, togglePTOECHart)
}

export function* watchUpdateSTATSIndicatorHeight() {
    yield takeLatest(PriceChartConstants.ActionTypes.INDICATOR_RESIZE, initIndicator)
}