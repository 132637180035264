import React, { PureComponent } from "react";
//import ThemeHelper from "ThemeHelper";
import HighlightText from "./HighlightText";
import ClickMenu from "ClickMenu";
import StringUtil from "StringUtil";
import UserInfoUtil from "UserInfoUtil";
import BaseServiceApi from "BaseServiceApi";
let EntitlementType = BaseServiceApi.rayData["EntitlementType"];

export default class DGGridDataRow extends PureComponent {

    constructor(props) {
        super(props);
        this.onMessageMouseLeave = this.onMessageMouseLeave.bind(this);
    }

    onHideToolTopBox(isHide) {
        this.props.onHideToolTopBox(isHide);
    }

    onHideToolTopBox(isHide) {
        this.props.onHideToolTopBox(isHide);
    }

    onMessageMouseMove(e, nameAbbr, name, quarterEndDate, carryForward) {
        this.props.onMessageMouseMove(e, nameAbbr, name, quarterEndDate, carryForward);  
    }

    onMessageMouseLeave() {
        this.props.onMessageMouseLeave();
    }

    onBlockMenuItemLeftClicked(sponsorId) {
        this.props.onBlockMenuItemLeftClicked(sponsorId);
        this.isHideToolTopBox = false;
    }

    onBlockMenuItemRightClicked = (msid, isFlagged) => {
        const msId = StringUtil.convertFromLongValueToInt(msid);
        this.props.onBlockMenuItemRightClicked(msId, isFlagged);
    }

    plotSymbol(e, symbol) {
        this.props.onPlotSymbol(symbol);
    }

    render() {
        const { props } = this;
        const val = props.value;
        const fromHoldingsTab = (props.activeTab && props.activeTab === "DgHoldings") ? true : false;
        const isColumnSharesChanged = props.isColumnSharesChanged;
        const isFundViewEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.FUNDS_VIEW_ACNTMGR_Entitlement);       
        
        const colrSharesHeld = props.sharesHeldColor;
        const colrShares1QAgo = props.shares1QAgoColor;
        const colrShares2QAgo = props.shares2QAgoColor;
        const txtPctOfPort = props.isColumnPortChanged === true ? (val.pctOfPort !== null && val.pctOfPort !== undefined) ? val.pctOfPort.toFixed(1) : val.pctOfPort : val.ratingRank;
        const txtSharesHeld = isColumnSharesChanged === true ? props.AbbreviateSharesValueForHeader(val.sharesHeld) : val.sharesHeld - val.shares1QAgo > 0 ? `+${  props.AbbreviateSharesValueForHeader(val.sharesHeld - val.shares1QAgo)}` : props.AbbreviateSharesValueForHeader(val.sharesHeld - val.shares1QAgo);
        const txtShares1QAgo = isColumnSharesChanged === true ? props.AbbreviateSharesValueForHeader(val.shares1QAgo) : val.shares1QAgo - val.shares2QAgo > 0 ? `+${  props.AbbreviateSharesValueForHeader(val.shares1QAgo - val.shares2QAgo)}` : props.AbbreviateSharesValueForHeader(val.shares1QAgo - val.shares2QAgo);
        const txtShares2QAgo = isColumnSharesChanged === true ? props.AbbreviateSharesValueForHeader(val.shares2QAgo) : val.shares2QAgo - val.shares3QAgo > 0 ? `+${  props.AbbreviateSharesValueForHeader(val.shares2QAgo - val.shares3QAgo)}` : props.AbbreviateSharesValueForHeader(val.shares2QAgo - val.shares3QAgo);
        
        const nameAbbr = val.nameAbbr.replace("*", "").replace("*", "").trim();
        const name = val.name.replace("*", "").replace("*", "").trim();
        
        let nameId = ``;
        let menuitems = [];
        let colr = "";
        let txtRsValue = 0;
        let rankText = "";
        let stockBar = "";
        let isFlagged = false;
        let clickMenuForHoldings = <ClickMenu data-disable-TrackPrice={true} button='right' menuType='holdingsReport' items={menuitems} hideOnItemClick={true} position='bottom' onselect={() => this.onBlockMenuItemRightClicked(val.msid, isFlagged)} hideToolTip={(isHide) => this.onHideToolTopBox(isHide)}/>;
        let clickMenuForOwnership = <ClickMenu data-disable-TrackPrice={true} button='left' menuType='ownershipReport' items={menuitems} hideOnItemClick={true} position='bottom' onselect={() => this.onBlockMenuItemLeftClicked(val.sponsorId)} hideToolTip={(isHide) => this.onHideToolTopBox(isHide)}/>;
        
        if(!fromHoldingsTab){
            menuitems.push({header : 'View Constituents', key : 'VIEW_CONSTITUENTS'});
            nameId = `ownershipName${  props.i}`;
            colr = val.isIndex === 1 ? "dimgray" : "";
            rankText = props.isColumnRankChanged === true ? val.fundPerformanceRating : (val.percentageChgYTD !== null && val.percentageChgYTD !== undefined) ? val.percentageChgYTD.toFixed(1) : val.percentageChgYTD;
            let rankClass = props.isColumnRankChanged === true ? ' aligncenter' :  ' alignLeft' ;
            rankText = val.isClient  && isFundViewEntitled? <div className={"signer sign-green" + rankClass}>{rankText}</div> : <div className={"signer sign-hidden" + rankClass }  >{rankText}</div>
            //const  txtCarry = val.carryForward == "1" ? "*" : val.carryForward == "2" ? "**" : ""
        }
        else {
            nameId = `holdingsName${  props.i}`;
            colr = "";
            isFlagged = (val.isFlagged && val.isFlagged !== null && val.isFlagged !== undefined) ? true : false;
            stockBar = isFlagged ? <div className="signer sign-blue" onMouseMove={(e) => this.onMessageMouseMove(e, val.symbol,  val.symbol, '', "1")} onMouseOut={this.onMessageMouseLeave} onBlur={this.onMessageMouseLeave} >{val.symbol}</div> : <div className="signer" onMouseMove={(e) => this.onMessageMouseMove(e, val.symbol,  val.symbol, '', "1")} onMouseOut={this.onMessageMouseLeave} onBlur={this.onMessageMouseLeave}>{val.symbol}</div>
            
            if(!isFlagged){
                menuitems.push({header : 'Flag', key : 'ROW_FLAG'});
            }
            else{
                menuitems.push({header : 'Unflag', key : 'ROW_REMOVE_FLAG'});
            }

            txtRsValue = props.isColumnRsChanged === true ? val.rs !== null ? val.rs.toFixed(0) : 0 : val.ytd !== null ? val.ytd.toFixed(1) : 0;

        }
        return (
            <div key={ props.i} className="report-sector">
                <div className="report-data">
                    <div className="report-data-row-group">
                        <div style = {{color : colr}} className="report-data-row">
                            {fromHoldingsTab ? <div className="report-cell holding-symbol"
                                onClick={(e) => this.plotSymbol(e, val.symbol)}>{stockBar}     
                                {clickMenuForHoldings}
                            </div> : <div className="report-cell ownership-rank" onClick={props.onColumnRankChanged}>{rankText}
                            </div>}
                            <div className={"report-cell "  + (fromHoldingsTab ? "holding-name" : "ownership-name")}>
                                <div id = {nameId} className="name-ellipsis" onMouseMove={(e) => this.onMessageMouseMove(e, nameAbbr, name, val.quarterEndDate, val.LatestQuarterEndDate)} onMouseOut={this.onMessageMouseLeave} onBlur={this.onMessageMouseLeave} >
                                    {props.searchText === '' ? val.nameAbbr : <HighlightText
                                        searchText={props.searchText}
                                        nameAbbr={val.nameAbbr}
                                        name={val.name}
                                        quarterEndDate={val.quarterEndDate}
                                        carryForward={fromHoldingsTab ? null : val.carryForward}
                                        textSize={this.props.textSize}
                                        index = {this.props.i}
                                    />}
                                    {fromHoldingsTab ? clickMenuForHoldings
                                    : clickMenuForOwnership }
                                </div>
                            </div>
                            {fromHoldingsTab ? <div className="report-cell holding-rs" onClick={props.onColumnRsChanged} >
                                {txtRsValue}
                            </div> : <div className="report-cell ownership-port" onClick={props.onColumnPortChanged} > {txtPctOfPort}
                            </div>}
                            {fromHoldingsTab ? <div className="report-cell holding-port"> {txtPctOfPort}
                            </div> : ""}
                            <div className={"report-cell "  + (fromHoldingsTab ? "holding-recent" : "ownership-recent")}
                                onClick={props.onColumnSharesChanged}
                                onMouseMove={(e) => this.props.ShowToolTip(e)} onMouseOut={this.onMessageMouseLeave} onBlur={this.onMessageMouseLeave}
                                style={{ color: colrSharesHeld }}>
                                {txtSharesHeld}
                            </div>
                            <div className={"report-cell "  + (fromHoldingsTab ? "holding-shares" : "ownership-shares")}
                                onClick={props.onColumnSharesChanged}
                                onMouseMove={(e) => this.props.ShowToolTip(e)} onMouseOut={this.onMessageMouseLeave} onBlur={this.onMessageMouseLeave}
                                style={{ color: colrShares1QAgo }}>
                                {txtShares1QAgo}
                            </div>
                            <div className={"report-cell "  + (fromHoldingsTab ? "holding-qtr" : "ownership-qtr")}
                                onClick={props.onColumnSharesChanged}
                                onMouseMove={(e) => this.props.ShowToolTip(e)} onMouseOut={this.onMessageMouseLeave} onBlur={this.onMessageMouseLeave}
                                style={{ color: colrShares2QAgo }}>
                                {txtShares2QAgo}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}