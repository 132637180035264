import { PrintMode } from '../../../../../../print/printmode';
import SettingsStore from "SettingsStore";
import textWidth from "text-width";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";
import { filter, find } from "underscore";
import React, { Component } from 'react';

let visibleIds = [];
let latestModifiedId = '';

class MeasurementTextInfo extends Component {
    constructor() {
        super();
        this.state = {
            isShowDetails: false,
            width: (this.props && this.props.data && this.props.data.textInfo) ? Math.round(textWidth(this.props.data.textInfo.titleHeader, { family: "calibri", size: 18 })) + 10 : 100 + 10,
            highLightMeasuredArea: false,
            isHighLightGain: false
        }
        this.consoleSetting = SettingsStore.getConsoleSettings();
    }

    static getDerivedStateFromProps(props) {
        if (props.data) {
            let newWidth = Math.round(textWidth(props.data.textInfo.titleHeader, { family: "calibri", size: 18 }));
            newWidth = newWidth < 100 ? 100 : newWidth;

            if (visibleIds.includes(props.data.id) || (PrintMode.printing && SettingsStore.getConsoleSettings().printSettings.visibleMeasurementAnno.includes(props.data.id))) {
                return {
                    isShowDetails: true,
                    width: newWidth + 60
                };
            }
            else {
                return {
                    isShowDetails: false,
                    width: newWidth + 10
                };
            }
        }
    }

    handleHighLight = (value, isGain) => {
        this.setState({ highLightMeasuredArea: value, isHighLightGain: isGain });
        const annotateDiv = document.getElementsByClassName('annotation-graphics')[0];
        if (annotateDiv) {
            if (value) {
                annotateDiv.style.zIndex = 1
            }
            else {
                annotateDiv.style.zIndex = 10
            }
        }
    }

    toggleVisibility = () => {
        const isVisible = !this.state.isShowDetails;

        latestModifiedId = this.props.data.id;
        if (this.props.updateStageClickCapturedFlag) {
            this.props.updateStageClickCapturedFlag(false);
        }

        // Retain the visibile box state until refresh
        if (isVisible) {
            visibleIds.push(this.props.data.id);
            this.consoleSetting.printSettings.visibleMeasurementAnno.push(this.props.data.id);
        }
        else {
            visibleIds = filter(visibleIds, (item) => item !== this.props.data.id);
            this.consoleSetting.printSettings.visibleMeasurementAnno = filter(visibleIds, (item) => item !== this.props.data.id);
        }
        SettingsStore.saveSettings();
        const bufferWidth = isVisible ? 60 : 10;
        let newWidth = Math.round(textWidth(this.props.data.textInfo.titleHeader, { family: "calibri", size: 18 }));
        newWidth = newWidth < 100 ? 100 : newWidth;
        this.setState({ isShowDetails: isVisible, width: newWidth + bufferWidth });
    }

    getHighLightedView(info) {
        let leftCover, uncover, rightCover, rightCoverStartPoint;

        if (this.state.isHighLightGain) {
            leftCover = (info.LargestRunStartX / info.chartDimension.width) * 100;
            uncover = ((info.LargestRunEndX - info.LargestRunStartX) / info.chartDimension.width) * 100;
            rightCover = ((info.chartDimension.width - info.LargestRunEndX) / info.chartDimension.width) * 100;
            rightCoverStartPoint = info.LargestRunEndX;
        }
        else {
            leftCover = (info.LargestPullbackStartX / info.chartDimension.width) * 100;
            uncover = ((info.LargestPullbackEndX - info.LargestPullbackStartX) / info.chartDimension.width) * 100;
            rightCover = ((info.chartDimension.width - info.LargestPullbackEndX) / info.chartDimension.width) * 100;
            rightCoverStartPoint = info.LargestPullbackEndX;
        }

        return (
            <div className="annotation-highlighter">
                <div className="cover" style={{ width: `${leftCover}%`, left: '0' }}></div>
                <div className="uncover" style={{ width: `${uncover}%`, left: `${leftCover}%` }}></div>
                <div className="cover" style={{ width: `${rightCover}%`, left: rightCoverStartPoint }}></div>
            </div>
        )
    }

    getDetails(textInfo) {
        const positiveBrush = ThemeHelper.getThemedBrush("positiveNode");
        const negativeBrush = ThemeHelper.getThemedBrush("negativeNode");

        return (
            <div className="measure-stuff">
                <div className="measure-row">
                    <span className="measure-head">Largest Gain:</span>
                    <span className="measure-data">
                        <span className="measure-data-text" style={{ borderBottom: '1px dashed', color: positiveBrush }} onMouseEnter={() => this.handleHighLight(true, true)} onMouseLeave={() => this.handleHighLight(false, false)} >
                            {textInfo.gain}
                        </span>
                    </span>
                </div>
                <div className="measure-row">
                    <span className="measure-head">Largest Decline:</span>
                    <span className="measure-data">
                        <span className="measure-data-text" style={{ borderBottom: '1px dashed', color: negativeBrush }} onMouseEnter={() => this.handleHighLight(true, false)} onMouseLeave={() => this.handleHighLight(false, false)} >
                            {textInfo.decline}
                        </span>
                    </span>
                </div>
            </div>
        )
    }

    render() {
        const { data, isMeasurementAnnot, selectedAnnot, activeAnchor, isInDrawingMode, isDragging, layerList, isStageClickCaptured } = this.props;
        const { id, textInfo, x, y, currentMouseX, snapStartPosition, snapEndPosition, layerID } = data;

        if (layerList) {
            const curLayer = find(layerList, (item) => item.layerID === layerID)
            if (curLayer && !curLayer.isVisible) {
                return null;
            }
        }

        if (!(x && y && snapStartPosition && snapEndPosition)) {
            return null;
        }
        const width = this.state.width;
        const toggleIcon = this.state.isShowDetails ? 'open' : '';
        const bufferTop = (snapStartPosition.y > y && snapEndPosition.y > y) ? this.state.isShowDetails ? (-74) : (-24) : 4;
        const pointerEvents = activeAnchor !== "" || isInDrawingMode || isDragging ? 'none' : 'all';
        let zIndex = '';

        /* Displaying grey boxes based on the view priority order */
        if (isStageClickCaptured && selectedAnnot && isMeasurementAnnot) {
            zIndex = selectedAnnot.id === id ? 12 : 11;
        }
        else if (latestModifiedId) {
            zIndex = latestModifiedId === id ? 12 : 11;
        }
        TimeTrackingWindow.trackChartLoadingTime();

        return (
            textInfo ?
                <>
                    <div id={id} className="annotation-measure-popover" style={{ left: (x + currentMouseX / 2) - width / 2, top: y + bufferTop, width: `${width}px`, pointerEvents: pointerEvents, zIndex: zIndex }}>
                        <div className="measure-title" style={{ background: 'grey' }}>
                            <h6>{textInfo.titleHeader}<span className={`icn-collapser ${toggleIcon}`} onClick={() => this.toggleVisibility()}></span></h6>
                        </div>
                        {this.state.isShowDetails && this.getDetails(textInfo)}
                    </div>
                    {this.state.highLightMeasuredArea && this.getHighLightedView(data.measurementDataInfo)}
                </>
                :
                null
        );
    }
}

export default MeasurementTextInfo;