export const LightDarkColorConstants = {
    DarkColor: ['#000000', '#003333', '#000033',
            '#340033', '#330000', '#5B1B00',
            '#333300', '#003300', '#262626',
            '#006666', '#013CB3', '#340066',
            '#670000', '#AE3E00', '#676700',
            '#006600', '#4C4C4C', '#009999',
            '#0066CC', '#660099', '#990000',
            '#FC6901', '#999900', '#089800',],
    LightColor: ['#999999', '#00CCCC', '#3399FF',
            '#6734CC', '#CC0100', '#FD8548',
            '#CBCB01', '#09CB01', '#CCCCCC',
            '#33FFFF', '#66CCFF', '#9866FF',
            '#FF0000', '#FDA57D', '#FFFF00',
            '#34FF33', '#FFFFFF', '#99FFFF',
            '#99CCFF', '#CDCCFF', '#FDBCBC',
            '#FFCC99', '#FFFF9A', '#99FF99'],
};