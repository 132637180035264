import React, { Component } from 'react';
import { connect } from 'react-redux';
import RotationGraphLeftPanel from './Containers/RotationGraphLeftPanel';
import MainMenuPanel from '../ToggleMenuPanel/MainMenuPanel';
import { CompContextMenu } from '../ComparisonGraph/Containers';
import { ClearAllDialog } from '../ToggleMenuPanel/Presentational';
import { PrintMode } from "../../../../print/printmode";
import SettingsStore from "SettingsStore";
import { initRIPanelSettings } from '../../../../Actions/ComparisonAndRotationMenuActions'

let isinit = false;
class RotationGraph extends Component {


    componentWillMount() {
        const settings = SettingsStore.getConsoleSettings().NavCompareSettings.TabRotationGraphSettings;
        if (!isinit) {
            this.props.initRIPanelSettings(settings);
            isinit = true;
        }
    }

    componentDidMount() {
        if (PrintMode.printing) {
            // this.props.getHeaderAndFooterInfo();
        }
    }

    render() {
        return (
            <div className="COLS">
                <RotationGraphLeftPanel />
                <MainMenuPanel />
                <div id="rotation-tooltip"></div>
                <CompContextMenu />
                <ClearAllDialog />
            </div >
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    initRIPanelSettings: (settings) => dispatch(initRIPanelSettings(settings)),
});

export default connect(null, mapDispatchToProps)(RotationGraph);
