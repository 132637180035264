import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";
import BaseServiceApi from "BaseServiceApi";

export default class AndAlertUpsertRequest extends BaseServiceRequest {
  constructor(data, colsetId, browserfilterstringIds, sourcetypeId, alertId, priceAlertObj, isTriggered) {
    super();
    super.addRequestUri("andalertupsert.pb");
    const ReqProto = BaseServiceApi.rayData["AlertData2"];
    const alertData = new ReqProto();

    const ReqProto1 = BaseServiceApi.rayData["AndAlertData"];
    const andAlertData = new ReqProto1();

    if (browserfilterstringIds != null) super.addRequestParameter("browserfilterstring", browserfilterstringIds);
    if (colsetId != null) super.addRequestParameter("colsetidstring", colsetId);
    super.addRequestParameter("sourcetypeid", sourcetypeId);
    super.addRequestParameter("msid", data.sourceId);
    super.addRequestParameter("alertId", alertId);
    if(isTriggered !== null || isTriggered !== undefined)
      super.addRequestParameter("isTriggered", isTriggered);

    alertData.sourceId = data.sourceId;
    alertData.alertDataSetting = data.alertDataSetting;
    alertData.listName = data.listName;
    alertData.sourceIds = data.sourceIds;
    alertData.sourceTypeId = data.sourceTypeId;
    alertData.columnSet = data.columnSet;
    alertData.browser = data.browser;
    alertData.nocolumnSet = data.nocolumnSet;

    if (priceAlertObj) {
      const ReqProto2 = BaseServiceApi.rayData["AlertData"];
      const alertData1 = new ReqProto2();
      alertData1.isActive = 1;
      alertData1.isTriggered = 0;

      const ReqProtoData1 = BaseServiceApi.rayData["PriceAlertData"];
      const priceData = new ReqProtoData1();
      priceData.AlertData = alertData1;
      priceData.msid = priceAlertObj.msId ? priceAlertObj.msId : priceAlertObj.msid;
      priceData.targetPrice = priceAlertObj.targetPrice;
      priceData.currencyCode = priceAlertObj.currencyCode;

      andAlertData.priceAlert = priceData;
    }

    andAlertData.listAlertData = alertData;

    const reqcontent = andAlertData.encode();
    super.setContent(reqcontent);
  }

  getMethod() {
    return HttpMethodType.PUT;
  }

  getSubSystem() {
    return SubSystemType.ALERTS;
  }

  getMessageName() {
    return "Response";
  }

}
