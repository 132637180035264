import AppDispatcher from "AppDispatcher";
import { FundamentalsConstants } from "../Constants/FundamentalsConstants.js";
import FundamentalTabFilterType from 'Constants/FundamentalTabFilterType.js';
import FundamentalTabReportType from 'Constants/FundamentalTabReportType.js';
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import FundamentalApi from '../ServiceApi/Apis/FundamentalApi.js';
import TabFundamentalsStore from "../Stores/NavModules/NavDataGraph/TabFundamental/TabFundamentalsStore.js";

class FundamentalsActionsClass {

  getReportData(fundamentalSettings) {
    let datagraphSettings = DatagraphStore.getState();
    if(!datagraphSettings.SymbolInfo.IsFundamentalAnnaulReportsAvailable && !datagraphSettings.SymbolInfo.IsFundamentalSemiAnnaulReportsAvailable && !datagraphSettings.SymbolInfo.IsFundamentalQuarterlyReportsAvailable){
      TabFundamentalsStore.setNonReportData();
      return;
    }
    if (fundamentalSettings.FundamentalFilter == FundamentalTabFilterType.Dollars || fundamentalSettings.FundamentalReportType == FundamentalTabReportType.Ratios) {
      FundamentalApi.getdata(fundamentalSettings).then((result) => {
        AppDispatcher.handleServerAction({
          actionType: FundamentalsConstants.ActionTypes.GET_REPORT_DATA,
          data: result
        });
      });
    }
    else {
      let nonDollarFilterReq = [FundamentalApi.getAllFilterdata(FundamentalTabFilterType.Dollars, fundamentalSettings, datagraphSettings),
      FundamentalApi.getAllFilterdata(fundamentalSettings.FundamentalFilter, fundamentalSettings, datagraphSettings)];
      Promise.all(nonDollarFilterReq).then((nonDollarResponse) => {
        const resultDetails = fundamentalSettings.FundamentalFilter === FundamentalTabFilterType.Yearoveryear || fundamentalSettings.FundamentalFilter === FundamentalTabFilterType.Commonsize ? nonDollarResponse[1] : nonDollarResponse[0];
        try {
          AppDispatcher.handleServerAction({
            actionType: FundamentalsConstants.ActionTypes.GET_REPORT_DATA,
            data: resultDetails
          });
        }
        catch (e) {
          console.error(e);
        }
      })
    }
  }

  getAllFilterReportData(fundamentalSettings) {
    let datagraphSettings = DatagraphStore.getState();
    if(!datagraphSettings.SymbolInfo.IsFundamentalAnnaulReportsAvailable && !datagraphSettings.SymbolInfo.IsFundamentalSemiAnnaulReportsAvailable && !datagraphSettings.SymbolInfo.IsFundamentalQuarterlyReportsAvailable){
      TabFundamentalsStore.setNonReportData();
      return;
    } 
    let allFilterReq = [FundamentalApi.getAllFilterdata(FundamentalTabFilterType.Dollars, fundamentalSettings, datagraphSettings),
    FundamentalApi.getAllFilterdata(FundamentalTabFilterType.Yearoveryear, fundamentalSettings, datagraphSettings)];

    if (fundamentalSettings.FundamentalReportType !== FundamentalTabReportType.Cashflow)
      allFilterReq.push(FundamentalApi.getAllFilterdata(FundamentalTabFilterType.Commonsize, fundamentalSettings, datagraphSettings))

    Promise.all(allFilterReq).then((allFilterResponse) => {
      allFilterResponse[0].data.filterType = FundamentalTabFilterType.Dollars;
      allFilterResponse[allFilterResponse.length - 1].data.filterType = FundamentalTabFilterType.Yearoveryear;
      if (allFilterResponse.length == 3) {
        //If you find mismatched data in the fundamental reports, please note the data order first. 
        allFilterResponse[1].data.filterType = FundamentalTabFilterType.Yearoveryear;
        allFilterResponse[2].data.filterType = FundamentalTabFilterType.Commonsize;
      }
      let result = [];
      if (allFilterResponse.length == 3) {
        allFilterResponse[1].data.filterType = FundamentalTabFilterType.Yearoveryear;
        allFilterResponse[2].data.filterType = FundamentalTabFilterType.Commonsize;
        result.push(allFilterResponse[0]);
        result.push(allFilterResponse[2]);
        result.push(allFilterResponse[1]);
      } else {
        result = allFilterResponse;
      }

      try {
        AppDispatcher.handleServerAction({
          actionType: FundamentalsConstants.ActionTypes.GET_ALL_REPORT_DATA,
          data: result
        });
      }
      catch (e) {
        console.error(e);
      }
    })
  }

  setSortOrderType(sortId) {
    AppDispatcher.handleServerAction({
      actionType: FundamentalsConstants.ActionTypes.SET_SORT_TYPE,
      data: sortId
    });
  }

  setReportTemplate(templateId) {
    AppDispatcher.handleServerAction({
      actionType: FundamentalsConstants.ActionTypes.SET_TEMPLATE_TYPE,
      data: templateId
    });
  }

  setReportType(type) {
    AppDispatcher.handleServerAction({
      actionType: FundamentalsConstants.ActionTypes.SET_REPORT_TYPE,
      data: type
    });
  }

  setReportFilter(filterId) {
    AppDispatcher.handleServerAction({
      actionType: FundamentalsConstants.ActionTypes.SET_FILTER_TYPE,
      data: filterId
    });
  }

  setFundamentalsPeriodicity(periodicity) {
    AppDispatcher.handleServerAction({
      actionType: FundamentalsConstants.ActionTypes.SET_FUNDAMENTALS_PERIODICITY,
      data: periodicity
    });
  }

  setEPSToggleState(format) {
    AppDispatcher.handleViewAction({
      actionType: FundamentalsConstants.ActionTypes.GET_COMPANY_INFO,
      data: format
    });
  }
  setReportClickState(click) {
    AppDispatcher.handleViewAction({
      actionType: FundamentalsConstants.ActionTypes.SET_REPORTS_RIGHTSIDE_CLICK,
      data: click
    });
  }
  
}

const FundamentalsActions = new FundamentalsActionsClass();

export default FundamentalsActions;

export const isGICSSelected_Fundamentals = (data) => {
  AppDispatcher.handleViewAction({
    actionType: FundamentalsConstants.ActionTypes.GICSSELECTED_FUNDAMENTALS,
    data: data
  });
}

export const MetricActionAlert_Fundamentals = (data) => {
  AppDispatcher.handleViewAction({
    actionType: FundamentalsConstants.ActionTypes.SET_METRIC_ACTION_ALRET_CLOSE_FUNDAMENTALS,
    data: data
  });
}
