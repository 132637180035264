import { RiPanelConstants } from "../../../../Constants/RiPanelConstants";
const { ActionTypes } = RiPanelConstants;
import { SettingsConstants } from "SettingsConstants";

const initialState = {
    HubDatasource: [],
    loading: true,
    Initialized: undefined,
    expanded: false,
    indexItems: [],
    callService: true,
    indexCodes: []
}

const RiPanelHub = (state = initialState, action) => {
    switch (action.type) {

        case ActionTypes.OWNERSHIP_INFO_HUB_PANEL_SET_DEFAULT_STATE: {
            return { ...state, ...action.newState }
        }

        case ActionTypes.OWNERSHIP_INFO_SET_HUB_PANEL_DATA: {
            return { ...state, ...action.newState }
        }

        case ActionTypes.OWNERSHIP_INFO_HUB_PANEL_SET_INDEXES: {
            state.indexItems = action.indexItems
            return { ...state }
        }

        case ActionTypes.OWNERSHIP_INFO_EXPAND_HUB_PANEL: {
            state.expanded = !state.expanded;
            if (Boolean(state.HubDatasource) && state.HubDatasource.length > 0) {
                state.HubDatasource = state.HubDatasource.map((item) => {
                    item.expanded = state.expanded;
                    return item;
                })
            }
            return { ...state }
        }

        case ActionTypes.OWNERSHIP_INFO_HUB_PANEL_SET_TOGGLE: {
            return { ...state, HubDatasource: [...action.HubDatasource], expanded: action.itemExpanded }
        }

        case SettingsConstants.ActionTypes.MINILIST_SYMBOL_CHANGED: {
            state = initialState;
            return { ...state }
        }

        case ActionTypes.OWNERSHIP_INFO_UPDATE_INDEX_CODES: {
            state.indexCodes = action.indexCodes;
            return { ...state }
        }

        default: return state;
    }
}

export default RiPanelHub
