import AppDispatcher from "AppDispatcher";
import { EventEmitter } from "events";
import CompareApi from "CompareApi";
import { RotationGraphConstants } from "RotationGraphConstants";
import _ from 'underscore';
import StringUtil from "StringUtil";
import DateHelper from "DateHelper";
import BaseServiceApi from 'BaseServiceApi';
import {RotationMenuType} from "../../../../Constants/RotationMenuType";
import RotationGraphStore from "RotationGraphStore";
import ConsoleStore from "ConsoleStore";
import BrowserUtil from "BrowserUtil";
import moment from "moment";
import LiquidityFilterStore from "LiquidityFilterStore";
import RatingFilterType from "RatingFilterType";
import CompareSettingHelper from "../../../ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";
import CompareStore from "CompareStore";
import SettingsStore from "SettingsStore";
import GridActions from "GridActions";
import UserInfoUtil from "UserInfoUtil";
import LocalizationStore from 'LocalizationStore';
import LangType from '../../../../Constants/LangType';
import SettingsAction from "Actions/SettingsActions";
import { onPlotSymbol } from "../../../../Actions/DatagraphActions";
import { dispatch } from "../../../../Redux/dispatch";

let GroupType = BaseServiceApi.rayData["GroupType"];
const CHANGE_EVENT = "change";

class RotationGraphMenuStore extends EventEmitter {
    constructor() {
        super();
        this.dispatchToken = AppDispatcher.register(this.dispatcherCallback.bind(this));
        //Bug fixes - Max Limit error 
        this.setMaxListeners(0);
        this.countryCode = null;
        this.selectedGeography = null;
        this.isRotationMenuLoading = false;
        this.selectedOsid = '';
        this.selectedIndustry = null;
        this.quadGraphResults = {};
        this.currentAction = null;
        this.lastHighlightedOsids = [];
        this.lastClicked = null;
        this.liqState = null;
        this.stocksTotalList = null;
        this.isNotVisibleOsids = [];
        this.rotationMenu = null;
        this.PrevFilterValue = {};
        this.prevSelectedSliderValue = null;
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    prepareGeoGraphyData(data) {
        try {
            let self = this;
            let geoGraphyData = data;

            //Add GLOBAL
            self.addGeographyItem(null, true, 'Global');
            geoGraphyData.GroupsData.forEach((item) => self.addGeographyItem(item, false));

            //Add REGIONS
            self.addGeographyItem(null, true, 'Regions');
            let regionData = _.sortBy(geoGraphyData.RegionData, 'RegionKey');
            regionData.forEach((item)  => self.addGeographyItem(item, false));

            //Add COUNTRIES
            self.addGeographyItem(null, true, 'Countries');
            let countryData = _.sortBy(geoGraphyData.CountryData, 'CountryName');
            countryData.forEach((item) => self.addGeographyItem(item, false));
        }
        catch (e) {
            console.log(e)
        }
    }

    getIndustryData() {
        if (StringUtil.isEmpty(RotationGraphStore.getIsDataLoaded())) {
            this.getGroupsData().then(() => RotationGraphStore.states.groupsMainData["IndustryList"]);
        }
        else
            return RotationGraphStore.states.groupsMainData["IndustryList"];
    }

    addGeographyItem(item, isHeader, headerName = null) {
        let geographyData = null;
        let countryCode = null;
        if (!StringUtil.isEmpty(item)) {

            if (item.RegionCode == "GLOBAL") {
                countryCode = item.RegionKey.low;
            }
            else if (item.RegionCode == "AMER" ||
                item.RegionCode == "ASIA" ||
                item.RegionCode == "EMEA") {
                countryCode = item.RegionKey.low;
            }
            else {
                countryCode = item.CountryCode.low;
            }
            geographyData = {
                regionCode: item.RegionCode,
                regionName: item.regionName,
                regionKey: item.RegionKey.low,
                countryCode: countryCode,
                wonRegionId: item.WonRegionID.low,
                primaryIndexOsid: item.PrimaryIndexOsid.low,
                countryName: StringUtil.isEmpty(item.CountryName) ? item.RegionName : item.CountryName,
                isHeader: isHeader
            }
        }
        else {
            geographyData = {
                regionCode: null,
                regionName: null,
                regionKey: null,
                countryCode: null,
                wonRegionId: null,
                primaryIndexOsid: null,
                countryName: headerName,
                isHeader: isHeader
            }
        }
        RotationGraphStore.states.geographyDataList.push(geographyData);
    }

    transGeographyName(item) {
        let breadcrumbname = null;
        let strRegionName = null;
        let regionName = item.regionName == null ? item.regionName : item.regionName.replace(" ", "");
        if (item.regionCode == "GLOBAL") {
            breadcrumbname = LocalizationStore.getTranslatedData("RotGraph_" + regionName, regionName);
        }
        else if (item.regionCode == "AMER" || item.regionCode == "ASIA" || item.regionCode == "EMEA") {
            breadcrumbname = LocalizationStore.getTranslatedData("RotGraph_" + regionName, regionName);
        }
        else {
            breadcrumbname = item.countryName;
        }
        if (UserInfoUtil.getCurrentLang() != LangType.RAY_DEFAULT) {
            switch (item.regionKey) {
                case 1000:
                    strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_Global", "World");
                    break;
                case 1001:
                    strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_Americas", "Americas");
                    break;
                case 1002:
                    strRegionName = LocalizationStore.getTranslatedData("RotGraph_Europe,MiddleEast,Africa", "Europe, Middle East, Africa");
                    break;
                case 1003:
                    strRegionName = LocalizationStore.getTranslatedData("CRG_ONeilIndex_AsiaPacific", "Asia Pacific");
                    break;
                default:
                    strRegionName = LocalizationStore.getTranslatedData("Country_" + item.countryCode, breadcrumbname);
                    break;
            }
        } else {
            strRegionName = item.countryName;
        }
        return strRegionName
    }

    setGeographyByCountryCode(countryCode) {
        let self = this;
        if (RotationGraphStore.states.geographyDataList.length > 0 && !StringUtil.isEmpty(countryCode)) {
            if (!StringUtil.isEmpty(self.countryCode)) {
                if (self.countryCode !== countryCode) {
                    RotationGraphStore.states.benchMarkData = null;
                    this.clearRotationGraphData();
                }
            }
            self.selectedGeography = _.find(RotationGraphStore.states.geographyDataList, (item) => item.countryCode === parseInt(countryCode));
            self.selectedGeography.displayName = this.transGeographyName(self.selectedGeography);
            self.countryCode = countryCode;
            self.getBenchMarkData(countryCode);
        }
    }

    getBenchMarkData(countryCode) {
        let benchMarkRequestData = {
            //date: DateHelper.yyyymmdd(DateHelper.returnTimeZoneDate(new Date())),
            date: DateHelper.yyyymmdd(new Date()), //For Bug panweb-968 
            code: countryCode
        }
        RotationGraphStore.getBenchMarkData(benchMarkRequestData);
    }

    getGrpsInstrumentData(grpsInstrumentRequestData) {
        let self = this;
        if (StringUtil.isEmpty(self.stockList)) {
            return CompareApi.getGrpsInstrumentData(grpsInstrumentRequestData).then((result) => {
                if (!result.responseHeader.error) {
                    if (!StringUtil.isEmpty(result)) {
                        return self.addStocks(result, grpsInstrumentRequestData.itemName, grpsInstrumentRequestData.indCode, grpsInstrumentRequestData.displayName).then(() => true);
                    }
                }
            });
        }
    }

    // Get current selected action.
    getCurrentAction() {
        return this.currentAction;
    }

    addLastHighlightedOsids(listItems) {
        if (!StringUtil.isEmpty(listItems) && StringUtil.isEmpty(this.lastHighlightedOsids))
            _.map(listItems, (item) => {
                if (this.lastHighlightedOsids.indexOf(parseInt(item.osid)) === -1)
                    this.lastHighlightedOsids.push(parseInt(item.osid));
            });
    }
    setSectorName(code, countryCode, Description) {
        let countryMark = LocalizationStore.getTranslatedData("Country_Abbr_" + countryCode, "");
        let description = LocalizationStore.getTranslatedData("Sector_" + code.substring(0, 5), Description);
        let formatItemName = StringUtil.stringFormat((countryCode == -1 || countryCode == 1 || countryMark == "") ? "{0}" : "{0} ({1})", description, countryMark);
        return formatItemName;
    }
    setIndustryName(code, countryCode, Description) {
        let countryMark = LocalizationStore.getTranslatedData("Country_Abbr_" + countryCode, "");
        let description = LocalizationStore.getTranslatedData("Index_Industry_" + code.substring(0, 5), Description);
        let formatItemName = StringUtil.stringFormat((countryCode == -1 || countryCode == 1 || countryMark == "") ? "{0}" : "{0} ({1})", description, countryMark);
        return formatItemName;
    }

    transIndustryName(item) {
        var arr = item.displayName.split(" ");
        if(arr.length > 1){
            arr.pop();
        }
        var name = arr.join(" ");
        let countryName = LocalizationStore.getTranslatedData("Country_Abbr_" + item.countryCode, "");
        let displayName = LocalizationStore.getTranslatedData("LM_SectorName_" + name.replace(/ /ig, "").toUpperCase(), name);
        let formatItemName = StringUtil.stringFormat((item.countryCode == -1 || item.countryCode == 1 || countryName == "") ? "{0}" : "{0} ({1})", displayName, countryName);
        return formatItemName;
    }

    transStockName(selectedStock) {
        let formatName = selectedStock.itemName.split(" ");
        let countryMark = LocalizationStore.getTranslatedData("Country_Abbr_" + selectedStock.countryCode, "");
        let name = LocalizationStore.getTranslatedData("FilterBrowser_ONEILINDUSTRYGROU_" + selectedStock.indCode, formatName);
        let formatItemName = StringUtil.stringFormat((selectedStock.countryCode == -1 || selectedStock.countryCode == 1 || countryMark == "") ? "{0}" : "{0} {1}", name, countryMark).split(",").join(" ");
        return formatItemName;
    }
    async addSectorListItems() {
        try {
            let self = this;
            let groupsSectorData = null;
            let listItems = [];
            self.selectedOsid = '';

            if (!StringUtil.isEmpty(RotationGraphStore.states.groupsMainData))
                groupsSectorData = RotationGraphStore.states.groupsMainData["SectorDataList"];

            //  let code = self.selectedGeography.countryCode == -1 ? self.selectedGeography.regionKey : self.selectedGeography.countryCode;
            _.map(groupsSectorData, (item) => {
                if (!StringUtil.isEmpty(item)) {
                    let code = self.selectedGeography.countryCode == -1 ? self.selectedGeography.regionKey : self.selectedGeography.countryCode;
                    if (item.CountryCode.low == code) {
                        listItems.push({
                            itemName: item.Description,
                            displayName: this.setSectorName(item.Code, item.CountryCode.low.toString(), item.Description),
                            osid: item.Osid.low,
                            countryCode: item.CountryCode.low,
                            itemType: RotationMenuType.Sector,
                            symbol: (item.Code.length >= 5) ? self.getSectorLabel(item.Code.substring(0, 5)) : self.getSectorLabel(item.Code),
                            underlyingSymbol: item.Code,
                            isHighlighted: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, item.Osid.low) : true,
                            itemColor: (item.Code.length >= 5) ? self.getSectorColor(item.Code.substring(0, 5)) : self.getSectorColor(item.Code),
                            isVisible: self.isNotVisibleOsids.length > 0 ? !(_.contains(self.isNotVisibleOsids, item.Osid.low.toString())) : true,
                            isSelected: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, item.Osid.low) : true,
                            sectorOrder: item.Code.length >= 5 ? self.getSectorOrder(item.Code.substring(0, 5)) : self.getSectorOrder(item.Code),
                            isLabelHighlighted: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, item.Osid.low) : false
                        })
                        self.selectedOsid = self.selectedOsid + item.Osid + ";";
                    }
                }
            });

            if (self.lastHighlightedOsids.length == listItems.length)
                _.each(listItems, (entry) => { entry.isLabelHighlighted = false; });

            self.addLastHighlightedOsids(listItems);
            //Sort on sector code
            let sectorList = _.sortBy(listItems, 'sectorOrder');

            self.setSectorBreadCrum();

            RotationGraphStore.updateRotationGrpahListItemsData(RotationMenuType.Sector, sectorList);

            return self.prepareQuadRequestData(GroupType.SECTOR);

        }
        catch (error) {
            console.log(error);
        }
    }

    getSectorOrder(code) {
        let order = 0;
        switch (code) {
            case "WS008":
            case "RETAIL":
            case "RTL":
                order = 1;
                break;
            case "WS007":
            case "CONSUMER CYCLICAL":
            case "CYC":
                order = 2;
                break;
            case "WMC03":
            case "FINANCIAL":
            case "FIN":
                order = 3;
                break;
            case "WS004":
            case "TECHNOLOGY":
            case "TECH":
                order = 4;
                break;
            case "WS006":
            case "HEALTH CARE":
            case "HLTH":
                order = 5;
                break;
            case "WMC04":
            case "UTILITY":
            case "UTL":
                order = 6;
                break;
            case "WS005":
            case "CONSUMER STAPLE":
            case "STPL":
                order = 7;
                break;
            case "WS003":
            case "CAPITAL EQUIPMENT":
            case "CAP":
                order = 8;
                break;
            case "WMC02":
            case "TRANSPORTATION":
            case "TRAN":
                order = 9;
                break;
            case "WS002":
            case "ENERGY":
            case "ENRG":
                order = 10;
                break;
            case "WS001":
            case "BASIC MATERIAL":
            case "MAT":
                order = 11;
                break;
            default:
                break;
        }
        return order;
    }

    getSectorLabel(sectorLabel) {
        let label = null;
        if (!StringUtil.isEmpty(sectorLabel))
            switch (sectorLabel) {
                case "WS008":
                case "RETAIL":
                case "RTL":
                    label = "RTL";
                    break;
                case "WS007":
                case "CONSUMER CYCLICAL":
                case "CYC":
                    label = "CYC";
                    break;
                case "WMC03":
                case "FINANCIAL":
                case "FIN":
                    label = "FIN";
                    break;
                case "WS004":
                case "TECHNOLOGY":
                case "TECH":
                    label = "TECH";
                    break;
                case "WS006":
                case "HEALTH CARE":
                case "HLTH":
                    label = "HLTH";
                    break;
                case "WMC04":
                case "UTILITY":
                case "UTL":
                    label = "UTL";
                    break;
                case "WS005":
                case "CONSUMER STAPLE":
                case "STPL":
                    label = "STPL";
                    break;
                case "WS003":
                case "CAPITAL EQUIPMENT":
                case "CAP":
                    label = "CAP";
                    break;
                case "WMC02":
                case "TRANSPORTATION":
                case "TRAN":
                    label = "TRAN";
                    break;
                case "WS002":
                case "ENERGY":
                case "ENRG":
                    label = "ENRG";
                    break;
                case "WS001":
                case "BASIC MATERIAL":
                case "MAT":
                    label = "MAT";
                    break;
                default:
                    break;
            }
        return label;
    }

    getSectorColor(sectorLabel) {
        let sectorBrush = null;
        if (!StringUtil.isEmpty(sectorLabel))
            switch (sectorLabel.trim().toUpperCase()) {
                case "WS008":
                case "RETAIL":
                case "RTL":
                    sectorBrush = "#0E76BC";
                    break;
                case "WS007":
                case "CONSUMER CYCLICAL":
                case "CYC":
                    sectorBrush = "#A1B9E0";
                    break;
                case "WMC03":
                case "FINANCIAL":
                case "FIN":
                    sectorBrush = "#2F8F44";
                    break;
                case "WS004":
                case "TECHNOLOGY":
                case "TECH":
                    sectorBrush = "#95CD84";
                    break;
                case "WS006":
                case "HEALTH CARE":
                case "HLTH":
                    sectorBrush = "#E5D220";
                    break;
                case "WMC04":
                case "UTILITY":
                case "UTL":
                    sectorBrush = "#F7941E";
                    break;
                case "WS005":
                case "CONSUMER STAPLE":
                case "STPL":
                    sectorBrush = "#C13E49";
                    break;
                case "WS003":
                case "CAPITAL EQUIPMENT":
                case "CAP":
                    sectorBrush = "#A02C91";
                    break;
                case "WMC02":
                case "TRANSPORTATION":
                case "TRAN":
                    sectorBrush = "#6E59A6";
                    break;
                case "WS002":
                case "ENERGY":
                case "ENRG":
                    sectorBrush = "#6D3319";
                    break;
                case "WS001":
                case "BASIC MATERIAL":
                case "MAT":
                    sectorBrush = "#AE8B67";
                    break;
                default:
                    break;
            }
        return sectorBrush;
    }

    addIndustryListItems(itemName, osid, displayName) {
        let self = this;
        let industryListData = null;
        let majorIndustryList = null;
        let listItems = [];
        self.selectedOsid = '';

        if (!StringUtil.isEmpty(RotationGraphStore.states.groupsMainData)) {
            industryListData = RotationGraphStore.states.groupsMainData["IndustryList"];
            majorIndustryList = RotationGraphStore.states.groupsMainData["MajorIndustryList"];
        }

        if (!StringUtil.isEmpty(majorIndustryList)) {
            _.map(majorIndustryList, (item) => {
                if (item.SectorOsid == osid) {
                    if (!StringUtil.isEmpty(industryListData)) {
                        let selectedIndList = _.filter(industryListData, (entry) => entry.MajorIndustryOsid.low == item.Osid.low);
                        _.map(selectedIndList, (indItem) => {
                            listItems.push({
                                itemName: indItem.WonIndustryDesc,
                                displayName: this.setIndustryName(indItem.WonIndustryCode, indItem.CountryCode.low.toString(), indItem.WonIndustryDesc),
                                osid: indItem.WonIndsutryOsid.low,
                                symbol: indItem.WonIndustryCode.length >= 5 ? indItem.WonIndustryCode.substring(0, 5) : indItem.WonIndustryCode,
                                underlyingSymbol: indItem.WonIndustryCode,
                                countryCode: indItem.CountryCode.low,
                                itemType: RotationMenuType.Industry,
                                wonIndsutryOsid: indItem.WonIndsutryOsid.low,
                                indCode: indItem.WonIndustryCode.substring(1, 5),
                                itemColor: null,
                                isHighlighted: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, indItem.WonIndsutryOsid.low) : true,
                                isVisible: self.isNotVisibleOsids.length > 0 ? !(_.contains(self.isNotVisibleOsids, indItem.WonIndsutryOsid.low.toString())) : true,
                                isSelected: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, indItem.WonIndsutryOsid.low) : true,
                                isLabelHighlighted: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, indItem.WonIndsutryOsid.low) : false
                            })
                            self.selectedOsid = self.selectedOsid + indItem.WonIndsutryOsid.low + ";";
                        });
                    }
                }
            });
        }

        if (self.lastHighlightedOsids.length == listItems.length)
            _.each(listItems, (entry) => { entry.isLabelHighlighted = false; });

        self.addLastHighlightedOsids(listItems);
        //Sort on itemName
        let industryList = _.each(_.sortBy(listItems, 'itemName'), (item, i) => {
            item.itemColor = self.getLineColor(i);
        });

        self.setIndustryBreadCrum(itemName, osid, displayName);

        RotationGraphStore.updateRotationGrpahListItemsData(RotationMenuType.Industry, industryList);

        return self.prepareQuadRequestData(GroupType.INDUSTRY);
    }

    setSectorBreadCrum() {
        this.breadCrumbItems = [];
        let sectorBreadCrumbObj = {
            itemName: this.selectedGeography.countryName,
            displayName: StringUtil.isEmpty(this.selectedGeography.displayName) ? this.selectedGeography.countryName : this.selectedGeography.displayName,
            rotationMenuType: RotationMenuType.Sector,
            countryCode: this.selectedGeography.countryCode,
            osid: null,
            indCode: null
        }
        this.setBreadCrumList(sectorBreadCrumbObj);
    }

    setIndustryBreadCrum(itemName, osid, displayName) {
        this.breadCrumbItems = [];
        let sectorBreadCrumbObj = {
            itemName: this.selectedGeography.countryName,
            displayName: StringUtil.isEmpty(this.selectedGeography.displayName) ? this.selectedGeography.countryName : this.selectedGeography.displayName,
            rotationMenuType: RotationMenuType.Sector,
            countryCode: this.selectedGeography.countryCode,
            osid: null,
            indCode: null
        }
        this.setBreadCrumList(sectorBreadCrumbObj);

        let industryBreadCrumbObj = {
            itemName: itemName,
            displayName: StringUtil.isEmpty(displayName) ? itemName : displayName,
            rotationMenuType: RotationMenuType.Industry,
            countryCode: this.selectedGeography.countryCode,
            osid: osid,
            indCode: null
        }
        this.setBreadCrumList(industryBreadCrumbObj);
    }

    setStocksBreadCrum(itemName, indCode, displayName) {
        this.breadCrumbItems = [];
        let sectorBreadCrumbObj = {
            itemName: this.selectedGeography.countryName,
            displayName: StringUtil.isEmpty(this.selectedGeography.displayName) ? this.selectedGeography.countryName : this.selectedGeography.displayName,
            rotationMenuType: RotationMenuType.Sector,
            countryCode: this.selectedGeography.countryCode,
            osid: null,
            indCode: null
        }
        this.setBreadCrumList(sectorBreadCrumbObj);

        let industryBreadCrumbObj = {
            itemName: this.selectedIndustry.itemName,
            displayName: StringUtil.isEmpty(this.selectedIndustry.displayName) ? this.selectedIndustry.itemName : this.selectedIndustry.displayName,
            rotationMenuType: RotationMenuType.Industry,
            countryCode: this.selectedGeography.countryCode,
            osid: this.selectedIndustry.osid,
            indCode: null
        }
        this.setBreadCrumList(industryBreadCrumbObj);

        let stocksBreadCrumbObj = {
            itemName: itemName,
            displayName: StringUtil.isEmpty(displayName) ? itemName : displayName,
            rotationMenuType: RotationMenuType.Stocks,
            countryCode: this.selectedGeography.countryCode,
            osid: null,
            indCode: parseInt(indCode)
        }
        this.setBreadCrumList(stocksBreadCrumbObj);
    }

    getLineColor(count) {
        let lineColor = null;
        if (count >= 48) {
            count = count % 48;
        }
        switch (count + 1) {
            case 1:
                lineColor = "#1682EC";
                break;
            case 2:
                lineColor = "#028B6C";
                break;
            case 3:
                lineColor = "#06A000";
                break;
            case 4:
                lineColor = "#87D900";
                break;
            case 5:
                lineColor = "#E3AB00";
                break;
            case 6:
                lineColor = "#EA4602";
                break;
            case 7:
                lineColor = "#EC7702";
                break;
            case 8:
                lineColor = "#EF001B";
                break;
            case 9:
                lineColor = "#F00478";
                break;
            case 10:
                lineColor = "#E400EC";
                break;
            case 11:
                lineColor = "#6617F0";
                break;
            case 12:
                lineColor = "#783C00";
                break;
            case 13:
                lineColor = "#7DB8F6";
                break;
            case 14:
                lineColor = "#69BAA7";
                break;
            case 15:
                lineColor = "#6AC868";
                break;
            case 16:
                lineColor = "#EF9068";
                break;
            case 17:
                lineColor = "#F4AC62";
                break;
            case 18:
                lineColor = "#F67683";
                break;
            case 19:
                lineColor = "#F563AB";
                break;
            case 20:
                lineColor = "#EE68F4";
                break;
            case 21:
                lineColor = "#A679F4";
                break;
            case 22:
                lineColor = "#AE8B67";
                break;
            case 23:
                lineColor = "#114F90";
                break;
            case 24:
                lineColor = "#015441";
                break;
            case 25:
                lineColor = "#006100";
                break;
            case 26:
                lineColor = "#548300";
                break;
            case 27:
                lineColor = "#8F9100";
                break;
            case 28:
                lineColor = "#836300";
                break;
            case 29:
                lineColor = "#902A00";
                break;
            case 30:
                lineColor = "#904900";
                break;
            case 31:
                lineColor = "#83000E";
                break;
            case 32:
                lineColor = "#8B0045";
                break;
            case 33:
                lineColor = "#89008E";
                break;
            case 34:
                lineColor = "#400D90";
                break;
            case 35:
                lineColor = "#472301";
                break;
            case 36:
                lineColor = "#5785B4";
                break;
            case 37:
                lineColor = "#508B7D";
                break;
            case 38:
                lineColor = "#4C914A";
                break;
            case 39:
                lineColor = "#84AA4D";
                break;
            case 40:
                lineColor = "#B1B24E";
                break;
            case 41:
                lineColor = "#B19850";
                break;
            case 42:
                lineColor = "#B57051";
                break;
            case 43:
                lineColor = "#B78451";
                break;
            case 44:
                lineColor = "#B34E59";
                break;
            case 45:
                lineColor = "#B14D7D";
                break;
            case 46:
                lineColor = "#B255B6";
                break;
            case 47:
                lineColor = "#805EB6";
                break;
            case 48:
                lineColor = "#846D54";
                break;
            default:
                break;
        }
        return lineColor;
    }

    clearRotationGraphData() {
        let viewSetting = CompareStore.getNavCompareSettings();
        RotationGraphStore.resetZoomValue();
        this.quadGraphResults = {};
        this.stocksTotalList = null;
        this.stockList = null;
        this.groupResult = null;
        this.isNotVisibleOsids = [];
        this.lastHighlightedOsids = [];
        RotationGraphStore.states.quadGraphYearList = null;

        CompareSettingHelper.updateNotVisibleOsids(viewSetting.TabRotationGraphSettings, null);
        CompareSettingHelper.updateLastHighlightedOsids(viewSetting.TabRotationGraphSettings, null);
        SettingsStore.saveSettings();
    }

    addStocks(stockslist, itemName, indCode, displayName) {
        let self = this;
        self.selectedOsid = '';
        if (!StringUtil.isEmpty(stockslist["GrpInstrumentList"])) {
            let listItems = [];
            _.map(stockslist["GrpInstrumentList"], (item) => {
                listItems.push({
                    itemName: item.Name,
                    displayName: item.Name,
                    osid: item.InstrumentId.low,
                    msid: item.Msid.low,
                    countryCode: item.CountryCode.low,
                    itemType: RotationMenuType.Stocks,
                    avol: item.Avol,
                    mktCp: item.MktCp,
                    symbol: item.Symbol,
                    localSymbol: item.LocalSymbol,
                    underlyingSymbol: item.Symbol,
                    isFlagged: item.IsFlagged,
                    instrumentId: item.InstrumentId.low,
                    isHighlighted: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, item.InstrumentId.low) : true,
                    isVisible: self.isNotVisibleOsids.length > 0 ? !(_.contains(self.isNotVisibleOsids, item.InstrumentId.low.toString())) : true,
                    isSelected: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, item.InstrumentId.low) : true,
                    ratingData: _.find(stockslist["GrpInstrumentRatingList"], (r) => r.Osid.low == item.InstrumentId.low),
                    isLabelHighlighted: self.lastHighlightedOsids.length > 0 ? _.contains(self.lastHighlightedOsids, item.InstrumentId.low) : false
                })
                self.selectedOsid = self.selectedOsid + item.InstrumentId + ";";
            });

            if (self.lastHighlightedOsids.length == listItems.length)
                _.each(listItems, (entry) => { entry.isLabelHighlighted = false; });

            self.addLastHighlightedOsids(listItems);
            //Sort on itemName
            this.stockList = _.each(_.sortBy(listItems, 'itemName'), (item, i) => {
                item.itemColor = self.getLineColor(i);
            });

            RotationGraphStore.updateRotationGrpahListItemsData(RotationMenuType.Stocks, this.stockList);
            self.setStocksBreadCrum(itemName, indCode, displayName);
            self.updateRotationMenuSettings(self.getBreadCrumList(), null);

            return self.prepareQuadRequestData(GroupType.STOCKS);
        }
    }

    liqFilterValueChanged(isEmitChange = true) {
        if (this.liqFilterChanged)
            clearTimeout(this.liqFilterChanged)
        this.liqFilterChanged = setTimeout(() => {
            this.liqState = LiquidityFilterStore.states;
            const mktCapDictionary = this.liqState.mktCapFilterViewModel ? this.liqState.mktCapFilterViewModel.intervalData : null;
            const avolDictionary = this.liqState.avolFilterViewModel ? this.liqState.avolFilterViewModel.intervalData : null;
            let quadGraphItemList = null;

            if (StringUtil.isEmpty(mktCapDictionary) || StringUtil.isEmpty(avolDictionary))
                return;

            if (this.liqState.mktCapFilterViewModel.highSliderRangeValueIndex == -1 || this.liqState.mktCapFilterViewModel.lowSliderRangeValueIndex
                == -1 || this.liqState.avolFilterViewModel.highSliderRangeValueIndex == -1 || this.liqState.avolFilterViewModel.lowSliderRangeValueIndex == 1)
                return;

            // if (this.PrevFilterValue.CapFilterLowRangeValue == this.liqState.mktCapFilterViewModel.lowRangeValue &&
            //     this.PrevFilterValue.CapFilterHighRangeValue == this.liqState.mktCapFilterViewModel.highRangeValue &&
            //     this.PrevFilterValue.avolFilterLowRangeValue == this.liqState.avolFilterViewModel.lowRangeValue &&
            //     this.PrevFilterValue.avolFilterHighRangeValue == this.liqState.avolFilterViewModel.highRangeValue &&
            //     this.PrevFilterValue.ratingFilterLowRangeValue == this.liqState.ratingFilterTypeOptions1ViewModel.lowRangeValue &&
            //     this.PrevFilterValue.ratingFilterHighRangeValue == this.liqState.ratingFilterTypeOptions1ViewModel.highRangeValue &&
            //     this.PrevFilterValue.ratingFilterLowRangeValue == this.liqState.ratingFilterTypeOptions2ViewModel.lowRangeValue &&
            //     this.PrevFilterValue.ratingFilterHighRangeValue == this.liqState.ratingFilterTypeOptions2ViewModel.highRangeValue)
            //     return;
            if (!StringUtil.isEmpty(this.stockList) && !StringUtil.isEmpty(mktCapDictionary) && !StringUtil.isEmpty(avolDictionary)) {
                RotationGraphStore.states.listItems = [];
                let filteredStockList = this.getFilteredStockData();

                //Sort on itemName
                filteredStockList = _.each(_.sortBy(filteredStockList, 'itemName'), (item, i) => {
                    item.itemColor = this.getLineColor(i);
                });

                RotationGraphStore.updateRotationGrpahListItemsData(RotationMenuType.Stocks, filteredStockList);

                if (!StringUtil.isEmpty(filteredStockList)) {
                    if (!StringUtil.isEmpty(this.stocksTotalList)) {
                        quadGraphItemList = _.filter(this.stocksTotalList, (item) => _.find(filteredStockList, (s) => s.instrumentId === item.SectorOsid.low));
                        this.groupResult = this.sortQuadData(quadGraphItemList);
                    }
                    if (!StringUtil.isEmpty(quadGraphItemList)) {
                        let xScaleMax = _.max(quadGraphItemList, _.property('XScaleMax')).XScaleMax;
                        let xScaleMin = _.min(quadGraphItemList, _.property('XScaleMin')).XScaleMin;
                        let yScaleMax = _.max(quadGraphItemList, _.property('YScaleMax')).YScaleMax;
                        let yScaleMin = _.min(quadGraphItemList, _.property('YScaleMin')).YScaleMin;
                        xScaleMax = Math.max(xScaleMax, Math.abs(xScaleMin));
                        yScaleMax = Math.max(yScaleMax, Math.abs(yScaleMin));
                        xScaleMin = -xScaleMax;
                        yScaleMin = -yScaleMax;
                        xScaleMax = Math.max(xScaleMax, yScaleMax);
                        yScaleMax = xScaleMax;
                        xScaleMin = Math.min(xScaleMin, yScaleMin);
                        yScaleMin = xScaleMin;
                        yScaleMin = parseFloat(yScaleMin * 0.85);
                        yScaleMax = parseFloat(yScaleMax * 0.85);
                        xScaleMax = parseFloat(xScaleMax + (xScaleMax * 0.08));
                        xScaleMin = parseFloat(xScaleMin + (xScaleMin * 0.08));

                        _.extend(this.quadGraphResults, {
                            xMax: xScaleMax,
                            xMin: xScaleMin,
                            yMax: yScaleMax,
                            yMin: yScaleMin
                        })
                    }

                    if (this.prevSelectedSliderValue != RotationGraphStore.selectedSliderValue) {
                        this.prevSelectedSliderValue = RotationGraphStore.selectedSliderValue;
                        this.preparePointstoRender(RotationGraphStore.selectedSliderValue);
                    }
                    else
                        this.updatePointData();

                    //this.preparePointstoRender(RotationGraphStore.selectedSliderValue);

                }

                // this.PrevFilterValue = {
                //     CapFilterLowRangeValue: this.liqState.mktCapFilterViewModel.lowRangeValue,
                //     CapFilterHighRangeValue: this.liqState.mktCapFilterViewModel.highRangeValue,
                //     avolFilterLowRangeValue: this.liqState.avolFilterViewModel.lowRangeValue,
                //     avolFilterHighRangeValue: this.liqState.avolFilterViewModel.highRangeValue,
                //     ratingFilterLowRangeValue: this.liqState.ratingFilterTypeOptions1ViewModel.lowRangeValue,
                //     ratingFilterHighRangeValue: this.liqState.ratingFilterTypeOptions1ViewModel.highRangeValue,
                //     ratingFilterLowRangeValue: this.liqState.ratingFilterTypeOptions2ViewModel.lowRangeValue,
                //     ratingFilterHighRangeValue: this.liqState.ratingFilterTypeOptions2ViewModel.highRangeValue
                // };

                if (isEmitChange) {
                    this.currentAction = RotationGraphConstants.ActionTypes.LIQ_FILTER_CHANGE;
                    this.emit(CHANGE_EVENT);
                }
                else {
                    this.currentAction = RotationGraphConstants.ActionTypes.GET_GROUPS_INSTRUMENT_DATA;
                    this.emit(CHANGE_EVENT);
                }
            }

        }, 300);
    }

    getFilteredStockData() {
        if (!this.liqState.isFilterOn)
            return this.stockList;

        let mktLow = parseFloat(this.liqState.mktCapFilterViewModel.lowRangeValue);
        let mktHigh = parseFloat(this.liqState.mktCapFilterViewModel.highRangeValue);
        let avolLow = parseFloat(this.liqState.avolFilterViewModel.lowRangeValue);
        let avolHigh = parseFloat(this.liqState.avolFilterViewModel.highRangeValue);

        return (_.filter(this.stockList, (item) => (item.avol >= avolLow && item.avol <= avolHigh && item.mktCp >= mktLow && item.mktCp <= mktHigh) && this.isMatchRatingFilter(item.ratingData)));
    }

    isMatchRatingFilter(ratingData) {
        let lowValue1 = parseFloat(this.liqState.ratingFilterTypeOptions1ViewModel.lowRangeValue);
        let highValue1 = parseFloat(this.liqState.ratingFilterTypeOptions1ViewModel.highRangeValue);
        let lowValue2 = parseFloat(this.liqState.ratingFilterTypeOptions2ViewModel.lowRangeValue);
        let highValue2 = parseFloat(this.liqState.ratingFilterTypeOptions2ViewModel.highRangeValue);

        let comparedValue1 = null;
        let comparedValue2 = null;

        if (this.liqState.isGlobalRatingSelected) {
            switch (this.liqState.ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType) {
                case RatingFilterType.Datagraph:
                    comparedValue1 = ratingData.GlobalDgRating;
                    break;
                case RatingFilterType.EPS:
                    comparedValue1 = ratingData.GlobalEpsRank;
                    break;
                case RatingFilterType.RelativeStrength:
                    comparedValue1 = ratingData.GlobalRelStrength;
                    break;
                default:
                    comparedValue1 = ratingData.AccDis;
                    break;
            }
            switch (this.liqState.ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType) {
                case RatingFilterType.Datagraph:
                    comparedValue2 = ratingData.GlobalDgRating;
                    break;
                case RatingFilterType.EPS:
                    comparedValue2 = ratingData.GlobalEpsRank;
                    break;
                case RatingFilterType.RelativeStrength:
                    comparedValue2 = ratingData.GlobalRelStrength;
                    break;
                default:
                    comparedValue2 = ratingData.AccDis;
                    break;
            }
        }
        else {
            switch (this.liqState.ratingFilterTypeOptions1ViewModel.selectedRatingFilterTypeOption.ratingFilterType) {
                case RatingFilterType.Datagraph:
                    comparedValue1 = ratingData.DgRating;
                    break;
                case RatingFilterType.EPS:
                    comparedValue1 = ratingData.EpsRank;
                    break;
                case RatingFilterType.RelativeStrength:
                    comparedValue1 = ratingData.RelStrength;
                    break;
                default:
                    comparedValue1 = ratingData.AccDis;
                    break;
            }
            switch (this.liqState.ratingFilterTypeOptions2ViewModel.selectedRatingFilterTypeOption.ratingFilterType) {
                case RatingFilterType.Datagraph:
                    comparedValue2 = ratingData.DgRating;
                    break;
                case RatingFilterType.EPS:
                    comparedValue2 = ratingData.EpsRank;
                    break;
                case RatingFilterType.RelativeStrength:
                    comparedValue2 = ratingData.RelStrength;
                    break;
                default:
                    comparedValue2 = ratingData.AccDis;
                    break;
            }
        }

        return (lowValue1 <= comparedValue1 && comparedValue1 <= highValue1
            && lowValue2 <= comparedValue2 && comparedValue2 <= highValue2)
    }

    updateListItemVisibilty(item) {
        _.find(RotationGraphStore.states.listItems, (entry) => {
            if (item.osid == entry.osid) {
                if (!item.isVisible) {
                    this.isNotVisibleOsids = _.without(this.isNotVisibleOsids, item.osid.toString());
                }
                else {
                    this.isNotVisibleOsids.push(item.osid);
                }
                _.extend(entry, { isVisible: !item.isVisible });
            }
        });

        //this.preparePointstoRender(RotationGraphStore.selectedSliderValue);
        this.updatePointData();

        this.currentAction = RotationGraphConstants.ActionTypes.UPDATE_LIST_ITEM_VISIBILTY;
        this.emit(CHANGE_EVENT);


        this.updateRotationMenuSettings(null, this.isNotVisibleOsids.toString());
    }

    updateRotationMenuSettings(breadCrumbObj, isNotVisibleOsids, lastHighlightedOsids) {
        let viewSetting = CompareStore.getNavCompareSettings();
        if (!StringUtil.isEmpty(breadCrumbObj))
            CompareSettingHelper.updateRotationMenuBreadCrumList(viewSetting.TabRotationGraphSettings, breadCrumbObj);
        if (!StringUtil.isEmpty(isNotVisibleOsids))
            CompareSettingHelper.updateNotVisibleOsids(viewSetting.TabRotationGraphSettings, isNotVisibleOsids);

        CompareSettingHelper.updateLastHighlightedOsids(viewSetting.TabRotationGraphSettings, lastHighlightedOsids);

        SettingsStore.saveSettings();
    }

    prepareQuadRequestData(groupType) {
        if (!StringUtil.isEmpty(this.selectedOsid) && !StringUtil.isEmpty(RotationGraphStore.groupsTimelineDateDictionary) && !StringUtil.isEmpty(this.countryCode)) {
            let fromDate = moment.utc(RotationGraphStore.groupsTimelineDateDictionary[0]).add(-35, 'days').toDate();
            let quadRequestData = {
                osid: this.selectedOsid,
                fromdate: DateHelper.yyyymmdd(fromDate),
                //enddate: DateHelper.yyyymmdd(DateHelper.returnTimeZoneDate(new Date())), 
                enddate: DateHelper.yyyymmdd(new Date()), //Panaray Desktop 5.0 sending system date 
                code: this.countryCode.toString(),
                type: groupType.toString()
            };
            return this.getQuadData(quadRequestData);
        }
    }

    getQuadData(quadRequestData) {
        let self = this;
        try {
            if (self.quadGraphResults && StringUtil.isEmpty(this.stocksTotalList) && StringUtil.isEmpty(this.groupResult) && StringUtil.isEmpty(RotationGraphStore.states.quadGraphYearList)) {
                self.enableLoading();
                return CompareApi.getQuadData(quadRequestData).then((result) => {
                    if (!result.responseHeader.error) {
                        self.quadGraphResults = {};
                        self.stocksTotalList = result.QuadGraphItemList;
                        RotationGraphStore.states.quadGraphYearList = result.QuadGraphItemList;
                        self.groupResult = self.sortQuadData(result.QuadGraphItemList);
                        self.setQuadGraphResults(result, quadRequestData.type);
                        return true;
                    }
                }).catch((er) => { console.log(er) });
            }
            else {
                self.emitChangeOnMenuType(quadRequestData.type);
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    setQuadGraphResults(result, rotationMenuType) {
        _.extend(this.quadGraphResults, {
            xMax: result.XScaleMax,
            xMin: result.XScaleMin,
            yMax: result.YScaleMax,
            yMin: result.YScaleMin,
            circleRad: result.CircleRad,
            lastAvailableDate: result.LastAvailableDate
        })

        RotationGraphStore.lastAvailableDate = moment.utc(StringUtil.convertFromLongValueToInt(result.LastAvailableDate)).toDate();
        this.setGroupsTimelineDateDictionary(result.LastAvailableDate);
        if (RotationGraphStore.isGeographySelected) {
            RotationGraphStore.startinitializeTimeLineBasedOnDataWorker().then(() => {
                this.postInitializeTimeLineBasedOnDataWorker(rotationMenuType);
            }).catch(() => { this.emitChangeOnMenuType(rotationMenuType); });
        }
        else {
            this.postInitializeTimeLineBasedOnDataWorker(rotationMenuType);
        }
    }

    postInitializeTimeLineBasedOnDataWorker(rotationMenuType) {
        this.preparePointstoRender(RotationGraphStore.selectedSliderValue);
        this.emitChangeOnMenuType(rotationMenuType);
    }

    emitChangeOnMenuType(rotationMenuType) {
        switch (parseInt(rotationMenuType)) {
            case GroupType.SECTOR:
                this.currentAction = RotationGraphConstants.ActionTypes.UPDATE_SECTORLIST;
                this.emit(CHANGE_EVENT);
                break;
            case GroupType.INDUSTRY:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_INDUSTRY_DATA;
                this.emit(CHANGE_EVENT);
                break;
            case GroupType.STOCKS:
                CompareStore.isLiqVisibile = true;
                window.setTimeout(() => {
                    this.currentAction = RotationGraphConstants.ActionTypes.GET_GROUPS_INSTRUMENT_DATA;
                    LiquidityFilterStore.initializeViewModel();
                }, 0);

                break;
            default:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_QUAD_DATA;
                this.emit(CHANGE_EVENT);
                break
        }
    }

    sortQuadData(data) {
        //Group by 'SectorOsid', sort by 'LongDate' in descending order and sort by by 'SectorName'\
        return _.chain(data)
            .sortBy((d) => StringUtil.convertFromLongValueToInt(d.LongDate)).reverse()
            .sortBy((c) => c.SectorName)
            .groupBy((g) => StringUtil.convertFromLongValueToInt(g.SectorOsid)).value();

        /*let sectorOsidList = _.groupBy(data,(d) => StringUtil.convertFromLongValueToInt(d.SectorOsid));
        let sectorGrpSortedList = [];
        _.each(sectorOsidList, (item) => {
            sectorGrpSortedList.push(_.sortBy(item, (s) => {
                return StringUtil.convertFromLongValueToInt(s.LongDate);
            }).reverse());
        });
        return _.sortBy(sectorGrpSortedList, (list, key) => {
            if (!StringUtil.isEmpty(list))
                return list[0].SectorName;
        });*/
    }

    preparePointstoRender(selectedSliderValue = null) {
        let self = this;
        let quadGraphItemsData2 = [];
        try {
            let groupResult = this.groupResult;

            if (!StringUtil.isEmpty(groupResult)) {
                _.map(groupResult, (item) => {
                    if (!StringUtil.isEmpty(item)) {
                        let points = [];
                        if (!StringUtil.isEmpty(item) && item.length >= ((RotationGraphStore.groupsTimelineDateDictionary.length - 1) - selectedSliderValue + 6)) {
                            _.map(item, (entry, i) => {
                                if ((i >= (RotationGraphStore.groupsTimelineDateDictionary.length - 1) - selectedSliderValue) && i < (RotationGraphStore.groupsTimelineDateDictionary.length - 1) - selectedSliderValue + 6) {
                                    points.push({
                                        xValue: entry.Xval.toFixed(4), yValue: entry.Yval.toFixed(4)
                                    })
                                }
                            })
                        }
                        quadGraphItemsData2.push({
                            points: (!StringUtil.isEmpty(points) ? points : null),
                            sectorFullName: item[0].SectorName,
                            sectorName: self.getSectorLabel(item[0].SectorLabl),
                            sectorLabel: RotationGraphStore.states.listItemType == RotationMenuType.Sector ? self.getSectorLabel(item[0].SectorLabl.substring(0, 5)) : item[0].SectorLabl,
                            id: item[0].SectorOsid,
                            isVisible: self.getListItemVisibility(item[0].SectorOsid.low)
                        })
                    }
                });

                _.extend(self.quadGraphResults, {
                    quadGraphItemsData2: quadGraphItemsData2
                })
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    updatePointData() {
        if (!StringUtil.isEmpty(this.quadGraphResults)) {
            if (!StringUtil.isEmpty(this.quadGraphResults.quadGraphItemsData2)) {
                _.map(this.quadGraphResults.quadGraphItemsData2, (item) => {
                    item.isVisible = this.getListItemVisibility(item.id.low);
                })
            }
        }
    }

    getListItemVisibility(osid) {
        let listItem = _.find(RotationGraphStore.states.listItems, (item) => item.osid == osid);
        if (!StringUtil.isEmpty(listItem))
            return listItem.isVisible;
        return false;
    }

    setGroupsTimelineDateDictionary(ltDate) {
        let timelineDateDictionary = [];
        let lastAvailableDate = moment.utc(StringUtil.convertFromLongValueToInt(ltDate)).add(35, 'days').toDate();
        if (!StringUtil.isEmpty(RotationGraphStore.groupsTimelineDateDictionary))
            _.each(RotationGraphStore.groupsTimelineDateDictionary, (item) => {
                if (moment(item).isSameOrAfter(moment(lastAvailableDate))) {
                    timelineDateDictionary.push(item);
                }
            })

        RotationGraphStore.groupsTimelineDateDictionary = [];
        RotationGraphStore.groupsTimelineDateDictionary = timelineDateDictionary;

        if (RotationGraphStore.selectedSliderValue > RotationGraphStore.groupsTimelineDateDictionary.length)
            RotationGraphStore.selectedSliderValue = RotationGraphStore.groupsTimelineDateDictionary.length;
    }

    setBreadCrumList(breadCrumbObj) {
        this.breadCrumbItems.push({ itemName: breadCrumbObj.itemName, displayName: breadCrumbObj.displayName, rotationMenuType: breadCrumbObj.rotationMenuType, countryCode: breadCrumbObj.countryCode, osid: breadCrumbObj.osid, indCode: breadCrumbObj.indCode });
    }

    getBreadCrumList() {
        return this.breadCrumbItems;
    }

    addOrRemoveFlagging(flagRequestData) {
        if (flagRequestData)
            GridActions.updateGlobalFlag(flagRequestData.msids.toString(), flagRequestData.snapshotdates, flagRequestData.isDelete, flagRequestData.cacheId, flagRequestData.dbType, flagRequestData.selectedIndexes);
    }

    enableLoading() {
        this.isRotationMenuLoading = true;
        this.currentAction = RotationGraphConstants.ActionTypes.ROTATION_MENU_LOADING;
        this.emit(CHANGE_EVENT);
    }

    disableLoading() {
        this.isRotationMenuLoading = false;
        BrowserUtil.enableEvents();
    }

    createIndustryListItem(item) {
        this.selectedIndustry = null;
        this.selectedIndustry = item;
        return this.addIndustryListItems(item.itemName, item.osid, item.displayName);
    }

    createStockListItem(item) {
        let requestData = null;
        this.PrevFilterValue = {};
        if (!StringUtil.isEmpty(item))
            requestData = { indCode: item.indCode, countryCode: item.countryCode, itemName: item.itemName, displayName: item.displayName };

        return this.getGrpsInstrumentData(requestData);
    }

    navigateToSymbolView(symbol) {
        dispatch(onPlotSymbol(symbol));
        ConsoleStore.plotSymbol(symbol);
    }

    updateFlag(item) {
        if (!StringUtil.isEmpty(item)) {
            let flagRequestData = {
                msids: item.msid,
                snapshotdates: null,
                isDelete: item.isFlagged,
                cacheId: 0,
                dbType: 1,
                selectedIndexes: []
            }
            BrowserUtil.disableEvents();
            this.addOrRemoveFlagging(flagRequestData);
        }
    }

    listItemHighlightSelected(key, item, isRotationGraphItem = false) {
        let selectItemsCount = _.filter(RotationGraphStore.states.listItems, (s) => { if (s.isSelected) return s });

        if (!StringUtil.isEmpty(selectItemsCount)) {
            if (selectItemsCount.length == 1 && item.isSelected) {
                this.highlightAll(item, 'Shift');
                this.lastClicked = item;
                if (this.lastHighlightedOsids.indexOf(parseInt(selectItemsCount[0].osid)) === -1)
                    this.lastHighlightedOsids.push(parseInt(selectItemsCount[0].osid));
                _.each(RotationGraphStore.states.listItems, (entry) => { entry.isLabelHighlighted = false; });
                this.currentAction = this.getRotationMenuAction(item.itemType);
                this.updateRotationMenuSettings(null, null, null);
                this.emit(CHANGE_EVENT);
                return;
            }
        }

        if (StringUtil.isEmpty(this.lastClicked))
            this.lastClicked = RotationGraphStore.states.listItems[0];

        switch (key) {
            case 'Shift':
                this.listItemShiftClicked(item);
                break;
            case 'Ctrl':
                this.listItemCtrlClicked(item);
                if (isRotationGraphItem)
                    this.setScrollPosition(item.osid, item.isHighlighted);
                break;
            case 'Click':
                this.listItemClicked(item);
                if (isRotationGraphItem)
                    this.setScrollPosition(item.osid, item.isHighlighted);
                break;
            default:
                return;
        }
        this.lastClicked = item;
        this.updateRotationMenuSettings(null, null, this.lastHighlightedOsids.toString());
    }

    listItemClicked(item) {
        if (!this.highlightAll(item, 'Click')) {
            this.currentAction = this.getRotationMenuAction(item.itemType);
            this.emit(CHANGE_EVENT);
            return;
        }

        this.lastHighlightedOsids = [];
        _.each(RotationGraphStore.states.listItems, (entry) => {
            if (item.osid == entry.osid) {
                entry.isHighlighted = true;
                entry.isSelected = true;
                entry.isLabelHighlighted = true;
                if (this.lastHighlightedOsids.indexOf(parseInt(entry.osid)) === -1)
                    this.lastHighlightedOsids.push(parseInt(entry.osid));
            }
            else {
                entry.isHighlighted = false;
                entry.isSelected = false;
                entry.isLabelHighlighted = false;
            }
        });

        if (this.lastHighlightedOsids.length == RotationGraphStore.states.listItems.length) {
            _.each(RotationGraphStore.states.listItems, (entry) => { entry.isLabelHighlighted = false; });
        }
        this.currentAction = this.getRotationMenuAction(item.itemType);
        this.emit(CHANGE_EVENT);
    }

    listItemCtrlClicked(item) {
        if (!this.highlightAll(item, 'Ctrl')) {
            this.currentAction = this.getRotationMenuAction(item.itemType);
            this.emit(CHANGE_EVENT);
            return;
        }

        _.each(RotationGraphStore.states.listItems, (entry) => {
            if (item.osid == entry.osid) {
                if (item.isSelected) {
                    entry.isSelected = false;
                    entry.isHighlighted = false;
                    entry.isLabelHighlighted = false;
                    this.lastHighlightedOsids = _.without(this.lastHighlightedOsids, item.osid);
                }
                else {
                    entry.isSelected = true;
                    entry.isHighlighted = true;
                    entry.isLabelHighlighted = true;
                    if (this.lastHighlightedOsids.indexOf(parseInt(item.osid)) === -1)
                        this.lastHighlightedOsids.push(parseInt(item.osid))
                }
            }
        });

        if (this.lastHighlightedOsids.length == RotationGraphStore.states.listItems.length) {
            _.each(RotationGraphStore.states.listItems, (entry) => { entry.isLabelHighlighted = true; });
        }

        this.currentAction = this.getRotationMenuAction(item.itemType);
        this.emit(CHANGE_EVENT);
    }

    listItemShiftClicked(item) {
        let preSeletedItems = null;
        let listItems = null;
        let maxIndex = Math.max(RotationGraphStore.states.listItems.indexOf(item), RotationGraphStore.states.listItems.indexOf(this.lastClicked));
        let minIndex = Math.min(RotationGraphStore.states.listItems.indexOf(item), RotationGraphStore.states.listItems.indexOf(this.lastClicked));

        listItems = _.filter(RotationGraphStore.states.listItems, (s) => (RotationGraphStore.states.listItems.indexOf(s) <= maxIndex && RotationGraphStore.states.listItems.indexOf(s) >= minIndex && s.osid != this.lastClicked.osid));

        preSeletedItems = _.filter(RotationGraphStore.states.listItems, (s) => s.osid !== this.lastClicked.osid);

        if (!StringUtil.isEmpty(preSeletedItems)) {
            _.each(preSeletedItems, (s) => {
                s.isSelected = false;
                s.isHighlighted = false;
                s.isLabelHighlighted = false;
                this.unHighlightSelected(s);
                this.lastHighlightedOsids = _.without(this.lastHighlightedOsids, s.osid);
            })
        }

        if (!StringUtil.isEmpty(listItems)) {
            _.each(listItems, (s) => {
                s.isSelected = true;
                s.isHighlighted = true;
                s.isLabelHighlighted = true;
                this.highlightSelected(s);
                if (this.lastHighlightedOsids.indexOf(parseInt(s.osid)) === -1)
                    this.lastHighlightedOsids.push(parseInt(s.osid));
            })
        }

        if (this.lastHighlightedOsids.length == RotationGraphStore.states.listItems.length) {
            _.each(RotationGraphStore.states.listItems, (entry) => { entry.isLabelHighlighted = true; });
        }
        else {
            _.each(RotationGraphStore.states.listItems, (entry) => {
                entry.isLabelHighlighted = _.contains(this.lastHighlightedOsids, entry.osid) ? true : false;
            });
        }

        this.currentAction = this.getRotationMenuAction(item.itemType);
        this.emit(CHANGE_EVENT);
    }

    highlightAll(item, key) {
        if (key == 'Shift') {
            this.lastHighlightedOsids = [];
            _.each(RotationGraphStore.states.listItems, (entry) => {
                entry.isSelected = true;
                entry.isHighlighted = true;
                this.lastHighlightedOsids.push(parseInt(entry.osid));
            });
            return;
        }
        else {
            if (this.lastHighlightedOsids.length == 1) {
                if (item.osid == this.lastHighlightedOsids[0]) {
                    _.each(RotationGraphStore.states.listItems, (entry) => {
                        entry.isSelected = true;
                        entry.isHighlighted = true;

                    });
                    this.lastHighlightedOsids = [];
                    return false;
                }
            }
            return true;
        }
    }

    unHighlightSelected(item) {
        _.each(RotationGraphStore.states.listItems, (s) => {
            if (s.osid == item.osid) {
                s.isSelected = false;
                s.isHighlighted = false;
            }
        })
    }

    highlightSelected(item) {
        _.each(RotationGraphStore.states.listItems, (s) => {
            if (s.osid == item.osid) {
                s.isSelected = true;
                s.isHighlighted = true;
            }
        })
    }

    // Set Scroll position on rotation grap item click
    setScrollPosition(osid, isHighlighted) {
        if (!isHighlighted) return;

        let elm = null;
        let rotationMenuVerticalScroll = null;
        let scrollPositionVal = null;

        if (!StringUtil.isEmpty(this.rotationMenu))
            elm = this.rotationMenu.querySelector("li[data-osid='" + osid + "']");

        if (!StringUtil.isEmpty(elm)) {
            rotationMenuVerticalScroll = document.getElementById("rotationMenuVerticalScroll");
            scrollPositionVal = parseInt(elm.offsetTop);
        }
        else
            scrollPositionVal = 0;

        if (!StringUtil.isEmpty(rotationMenuVerticalScroll))
            window.setTimeout(() => { rotationMenuVerticalScroll.scrollTop = scrollPositionVal; }, 0);
    }

    getRotationMenuAction(itemType) {
        switch (itemType) {
            case RotationMenuType.Sector:
                return RotationGraphConstants.ActionTypes.UPDATE_SECTORLIST;
            case RotationMenuType.Industry:
                return RotationGraphConstants.ActionTypes.GET_INDUSTRY_DATA;
            case RotationMenuType.Stocks:
                return RotationGraphConstants.ActionTypes.GET_GROUPS_INSTRUMENT_DATA;
            default:
                return;
        }
    }
    
    async getGroupsData(caller) {
        await RotationGraphStore.getGroupsData(caller);
        return true;
    }

    routToRotationGraph(navType, infoData) {
        let industryListData = undefined;
        let majorindustrylist = undefined;
        let geographyDataItemsList = undefined;
        let sectorDataItemsList = undefined;
        let industryItem = undefined;
        let majorIndustryItem = undefined;
        let geographyItem = undefined;
        let sectorItem = undefined;

        if (!StringUtil.isEmpty(RotationGraphStore.states.groupsMainData)) {
            industryListData = RotationGraphStore.states.groupsMainData["IndustryList"];
            majorindustrylist = RotationGraphStore.states.groupsMainData["MajorIndustryList"];
            geographyDataItemsList = RotationGraphStore.states.groupsMainData["CountryList"];
            sectorDataItemsList = RotationGraphStore.states.groupsMainData["SectorDataList"];
        }        
        industryItem = industryListData.filter((industry) => industry.WonIndustryCode === infoData.industry197Symbol);

        if (industryItem.length > 0) {
            majorIndustryItem = majorindustrylist.filter((majorIndustry) => majorIndustry.Osid.low === industryItem[0].MajorIndustryOsid.low);
            if (majorIndustryItem.length > 0) {
                sectorItem = sectorDataItemsList.filter((sector) => sector.Osid.low === majorIndustryItem[0].SectorOsid.low);
                geographyItem = geographyDataItemsList.filter((geography) => geography.CountryId.low === majorIndustryItem[0].CountryCode.low);
            }
        }

        const breadCrumbObj = [
            {
                countryCode: geographyItem[0].CountryId.low,
                indCode: null,
                itemName: geographyItem[0].CountryName,
                displayName: this.transGeographyName(geographyItem[0]),
                osid: null,
                rotationMenuType: 1
            },
            {
                countryCode: geographyItem[0].CountryId.low,
                indCode: null,
                itemName: sectorItem ? sectorItem[0].Description : infoData.Sector11Symbol.trim(),
                displayName: sectorItem ? sectorItem[0].Description : infoData.Sector11Symbol.trim(),
                osid: sectorItem && sectorItem[0].Osid.low,
                rotationMenuType: 3
            },
            {
                countryCode: geographyItem[0].CountryId.low,
                indCode: infoData.IndCode,//industryItem[0].WonIndustryCode.substring(1, industryItem[0].WonIndustryCode.length),
                itemName: industryItem[0].WonIndustryDesc,
                displayName: industryItem[0].WonIndustryDesc,
                osid: null,
                rotationMenuType: 4
            }
        ]
        
        this.clearRotationGraphData();
        this.updateRotationMenuSettings(breadCrumbObj, null, undefined);

        ConsoleStore.UpdateRout(infoData.symbol, navType);

        RotationGraphStore.loadRotationMenu();
    }

    dispatcherCallback(payload) {
        const action = payload.action;
        const data = action.data;

        switch (action.actionType) {
            case RotationGraphConstants.ActionTypes.GET_QUAD_DATA:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_QUAD_DATA;
                this.getQuadData(data.quadRequestData);
                break;
            case RotationGraphConstants.ActionTypes.GET_GROUPS_INSTRUMENT_DATA:
                this.currentAction = RotationGraphConstants.ActionTypes.GET_GROUPS_INSTRUMENT_DATA;
                this.getGrpsInstrumentData(data.grpsInstrumentRequestData);
                break;
            case RotationGraphConstants.ActionTypes.LIQ_FILTER_CHANGE:
                this.currentAction = RotationGraphConstants.ActionTypes.LIQ_FILTER_CHANGE;
                this.liqFilterValueChanged(data.isEmitChange);
                break;
            case RotationGraphConstants.ActionTypes.SET_CONSOLE_ROUT:
                const navType = action.data[1];
                const infoData = action.data[0];

                if (StringUtil.isEmpty(RotationGraphStore.getIsDataLoaded())) {                    
                    this.getGroupsData('datagraph').then(() => {
                        this.routToRotationGraph(navType, infoData);
                    })
                }
                else
                    this.routToRotationGraph(navType, infoData);
                break;
            default:
                return;
        }
    }
}

const rotationGraphMenuStore = new RotationGraphMenuStore()
export default rotationGraphMenuStore;
