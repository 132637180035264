import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import TimeTrackingWindow from "TimeTrackingWindow";
import {CompareMenuType} from 'CompareTabType';
import { RotationMenuType } from '../../../../../Constants/RotationMenuType';
import { extend } from 'underscore';

class CompLineVisual extends PureComponent {

    handleHighlight = (e) => {
        this.props.handleHighlight(e, this.props.line);
    }

    handleContextMenu = (e) => {
        this.props.handleContextMenu(e, this.props.line,this.props.menuTabName);
    }

    onMouseOver = (e) => {
        const {menuTabName} = this.props;
        const label = this.isSymbolsMenu(menuTabName) ? this.props.line.label : this.props.line.symbol;
        this.props.onMouseHover(e, label, true);
    }

    onMouseOut = (e) => {
        const {menuTabName} = this.props;
        const label = this.isSymbolsMenu(menuTabName) ? this.props.line.label : this.props.line.symbol;
        this.props.onMouseHover(e, label, false);
    }

    isSymbolsMenu(menuTabName){
        return (menuTabName == CompareMenuType.Symbols);
    }

    setGroupMenuSymbolName(line, menuTabName, rotationMenuType, listItems, stockList) {
        const isStocksRotationMenuType = (rotationMenuType === RotationMenuType.Stocks);
        if (!isStocksRotationMenuType) {
            let groupMenuItems;
            if (rotationMenuType !== RotationMenuType.Stocks)
                groupMenuItems = listItems
            else
                groupMenuItems = stockList;

            groupMenuItems.map((item) => {
                if (item.underlyingSymbol == line.actualSymbol) {
                    extend(line, { symbol: isStocksRotationMenuType ? item.localSymbol !== "" ? item.localSymbol : item.symbol : item.symbol });
                }
            })
        }
    }

    getDataPointsAsPathData = (dataPoint) => {
        let dataPointsStrPath = "";
        dataPoint.forEach((p, key) => {
            if (Number.isFinite(p[0]) && Number.isFinite(p[1])) {
                if (key === 0) {
                    dataPointsStrPath += `M`;
                }
                else {
                    dataPointsStrPath += `L`;
                }

                dataPointsStrPath += `${p[0]} ${p[1]} `;
            }
        });
        return dataPointsStrPath;
    }

    render() {
        const { line, menuTabName, rotationMenuType, listItems, stockList } = this.props
        const dataPoint = this.getDataPointsAsPathData(this.props.dataPoint);
        TimeTrackingWindow.trackChartLoadingTime();
        this.setGroupMenuSymbolName(line, menuTabName, rotationMenuType, listItems, stockList);
        return (
            <Fragment>
                <path d={dataPoint} className="cursor-pointer" stroke={this.props.strokeColor} strokeOpacity={this.props.isHighlighted ? "1" : "0.3"} strokeWidth={this.props.isHighlighted && !this.props.allHighlighted ? "2" : "1"} fill="none" />
                <path d={dataPoint} className="cursor-pointer" onClick={this.handleHighlight} onContextMenu={this.handleContextMenu} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onMouseMove={this.onMouseOver} strokeWidth="3" stroke="transparent" fill="none" />
            </Fragment>
        );
    }
}

CompLineVisual.propTypes = {
    dataPoint: PropTypes.array.isRequired,
    strokeColor: PropTypes.string.isRequired,
    isHighlighted: PropTypes.bool.isRequired,
    onMouseHover: PropTypes.func.isRequired,
    handleHighlight: PropTypes.func.isRequired,
    handleContextMenu: PropTypes.func,
    allHighlighted: PropTypes.bool.isRequired
}

export default CompLineVisual;