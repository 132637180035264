import NupLayoutType from "../../../../../Constants/NupLayoutType.js";
import ListManager from "../../../../../Constants/ListManager.js";
import GraphType from "GraphType";
import PeriodicityHelper from "../../../../../Utils/PeriodicityHelper.js";
import LayoutSettings from "./LayoutSettings.js";
import Serializable from "Serializable";
import UserInfoUtil from 'UserInfoUtil';
import LocalizationStore from "../../../../Localization/LocalizationStore.js";
import LangType from "../../../../../Constants/LangType.js";
import panarayDefault from "../../../../../ServiceApi/RequestHelper/PanarayDefault.js";

class TabONeilSettings extends Serializable {
  constructor() {
    super();
    this.ListExplorer = {};
    this.ListExplorer.MinWidth = 100;
    this.ListExplorer.MaxWidth = 500;

    this.MetricLibrary = {};
    this.MetricLibrary.MinHeight = 100;
    this.MetricLibrary.MaxHeight = 300;

    this.lang = LocalizationStore.getLang();
  }

  onSeralisedInfoRecieved(serializedValue) {
    if(!this.ListExplorer){
      this.ListExplorer = {};
      this.ListExplorer.MinWidth = 100;
      this.ListExplorer.MaxWidth = 500;
    }
    if(!this.MetricLibrary){
      this.MetricLibrary = {};
      this.MetricLibrary.MinWidth = 100;
      this.MetricLibrary.MaxWidth = 300;
    }
    if(!this.lang){
      this.lang = LocalizationStore.getLang();
    }
    this.TextSize = serializedValue.TextSize ? serializedValue.TextSize : this.getDefaultTextSize();
    this.ScreenOnOff = (serializedValue.ScreenOnOff != undefined || serializedValue.ScreenOnOff != null) ? serializedValue.ScreenOnOff : this.getDefaultScreenOnOff();
    this.SelectedListInfo = serializedValue.SelectedListInfo ? serializedValue.SelectedListInfo : this.getDefaultSelectedListInfo();
    this.BrowserFilter = serializedValue.BrowserFilter ? serializedValue.BrowserFilter : this.getDefaultBrowserFilter();
    this.ListExplorer.Width = serializedValue.ListExplorerWidth >= 0 ? serializedValue.ListExplorerWidth : this.getDefaultListExplorerWidth();
    this.ListExplorer.PreviousWidth = serializedValue.ListExplorerPreviousWidth >= 0 ? serializedValue.ListExplorerPreviousWidth : this.getDefaultListExplorerWidth();
    this.MetricLibrary.Width = serializedValue.MetricLibraryWidth ? serializedValue.MetricLibraryWidth : this.getDefaultMetricLibraryWidth();
    this.MetricLibrary.IsOpen = serializedValue.IsMetricLibraryOpen ? serializedValue.IsMetricLibraryOpen : this.getDefaultMetricLibraryOpenState();
    this.MetricLibrary.Height = serializedValue.Height ? serializedValue.Height : this.getDefaultMetricLibraryHeight();
    this.SelectedView = serializedValue.SelectedView ? serializedValue.SelectedView : this.getDefaultSelectedView();
    this.SummaryStatSettings = serializedValue.SummaryStatSettings ? serializedValue.SummaryStatSettings : this.getDefaultSummaryStatSettings();
    this.frozenColumnsSpliter = serializedValue.frozenColumnsSpliter ? serializedValue.frozenColumnsSpliter : this.getDefaultFrozenColumnsSpliter();
  }

  getDataObject() {
    return {
      TextSize: this.TextSize,
      ScreenOnOff: this.ScreenOnOff,
      SelectedListInfo: this.SelectedListInfo,
      BrowserFilter: this.BrowserFilter,
      ListExplorerWidth: this.ListExplorer.Width,
      ListExplorerPreviousWidth: this.ListExplorer.PreviousWidth,
      SelectedView: this.SelectedView,
      IsMetricLibraryOpen: this.MetricLibrary.IsOpen,
      MetricLibraryWidth: this.MetricLibrary.Width,
      MetricLibraryHeight: this.MetricLibrary.Height,
      SummaryStatSettings: this.SummaryStatSettings,
      frozenColumnsSpliter: this.frozenColumnsSpliter
    };
  }

  getObjectMapKey() {
    return "TabONeilSettings";
  }

  getDefaultTabONeilSettings() {
    this.TextSize = this.getDefaultTextSize();
    this.ScreenOnOff = this.getDefaultScreenOnOff();
    this.SelectedListInfo = this.getDefaultSelectedListInfo();
    this.BrowserFilter = this.getDefaultBrowserFilter();
    this.ListExplorer.Width = this.getDefaultListExplorerWidth();
    this.ListExplorer.PreviousWidth = this.getDefaultListExplorerWidth();
    this.MetricLibrary.Width = this.getDefaultMetricLibraryWidth();
    this.MetricLibrary.Height = this.getDefaultMetricLibraryHeight();
    this.MetricLibrary.IsOpen = this.getDefaultMetricLibraryOpenState();
    this.SelectedView = this.getDefaultSelectedView();
    this.SummaryStatSettings = this.getDefaultSummaryStatSettings();
    this.frozenColumnsSpliter = this.getDefaultFrozenColumnsSpliter();
    return this;
  }

  getDefaultTextSize() {
    return ListManager.TextSize;
  }

  getDefaultScreenOnOff() {
    return ListManager.ScreenOnOff;
  }

  getDefaultListExplorerWidth() {
    return 300;
  }

  getDefaultMetricLibraryWidth() {
    return 340;
  }

  getDefaultMetricLibraryHeight() {
    return 100;
  }

  getDefaultMetricLibraryOpenState() {
    return false;
  }

  getDefaultKey() {
    return "List";
  }

  getDefaultSelectedView() {
    let selectedView = new Serializable();
    selectedView.Key = this.getDefaultKey();
    selectedView.List = this.getDefaultListSettings();
    selectedView.Nup = this.getDefaultNupSettings();
    selectedView.ListAnalytics = this.getDefaultAnalyticsSettings();
    selectedView.QuoteBoard = this.getDefaultQuoteBoardSettings();
    return selectedView;
  }

  getDefaultBrowserFilter() {
    let browserFilter = new Serializable();
    browserFilter.selectedTab = 'Geography';
    browserFilter.isOneilselected = 1;
    browserFilter.isGicsselected = 0;
    browserFilter.needBrowserCounts = 1;
    browserFilter.browserFilterText = UserInfoUtil.hasChinaEntitlement()?"-1;4,102;-1":"-1;-1;-1;";
    browserFilter.igOneilbrowserFilters = "-1;-1;-1;";
    browserFilter.igGicsbrowserFilters = "-1;-1;-1;-1;";
    browserFilter.filterBadge = "";
    browserFilter.filterObject = this.getDefaultFilterObject();
    browserFilter.filterObjectName = this.getDefaultFilterObjectName();

    if (this.lang === LangType.ZH_CN) {
      browserFilter.browserFilterText = "-1;4,102;-1;";
      browserFilter.filterBadge = LocalizationStore.getTranslatedData("Country_4", "CHINA") + "[+1" + LocalizationStore.getTranslatedData("alert_Geography_more", "more") + "]";
    }
    return browserFilter;
  }

  getDefaultFilterObject() {
    let filterObject = new Serializable();
    filterObject.Region1 = '-1';
    filterObject.Country2 = '-1';
    filterObject.Instrument3 = '-1';
    filterObject.Sectors5 = '-1';
    filterObject.MajorIndustries6 = '-1';
    filterObject.IndustryGroups7 = '-1';
    filterObject.Sectors1 = '-1';
    filterObject.IndustryGroups2 = '-1';
    filterObject.Industries3 = '-1';
    filterObject.SubIndustries4 = '-1';
    return filterObject;
  }

  getDefaultFilterObjectName() {
    let filterObjectName = new Serializable();
    filterObjectName.Region1 = '';
    filterObjectName.Country2 = '';
    filterObjectName.Instrument3 = '';
    filterObjectName.Sectors5 = '';
    filterObjectName.MajorIndustries6 = '';
    filterObjectName.IndustryGroups7 = '';
    filterObjectName.Sectors1 = '';
    filterObjectName.IndustryGroups2 = '';
    filterObjectName.Industries3 = '';
    filterObjectName.SubIndustries4 = '';
    return filterObjectName;
  }

  getDefaultSelectedListInfo() {
    let selectedListInfo = new Serializable();
    selectedListInfo.SelectedListId = UserInfoUtil.hasChinaEntitlement() ? "141" : panarayDefault?.defaultListID?.toString();
    selectedListInfo.SelectedActualListId = UserInfoUtil.hasChinaEntitlement() ? "141" : panarayDefault?.defaultListID?.toString();
    selectedListInfo.SelectedListName = panarayDefault?.defaultListName;
    selectedListInfo.SelectedCategoryType = panarayDefault?.defaultCategoryType?.toString();
    selectedListInfo.SelectedParentNodeId = panarayDefault?.parentNodeId?.toString();
    selectedListInfo.SelectedParentCategoryType = panarayDefault?.parentCategoryType?.toString();
    selectedListInfo.SelectedParentCategoryName = escape(panarayDefault?.parentCategoryName);
    selectedListInfo.SelectedParentListType = panarayDefault?.parentListType?.toString();
    selectedListInfo.SelectedSourceCategoryType = null;
    selectedListInfo.SelectedSourceParentCategoryName = null;
    
    if (this.lang === LangType.ZH_CN) {
      selectedListInfo.SelectedListId = "141";
      selectedListInfo.SelectedActualListId = "141";
      selectedListInfo.SelectedListName = LocalizationStore.getTranslatedData("TreeExplorer_LST_NewHighs","New Highs");
      selectedListInfo.SelectedParentNodeId = "135";
      selectedListInfo.SelectedParentCategoryName = escape("Technical");
    }
    return selectedListInfo;
  }

  getDefaultListSettings() {
    let getDefaultListSettings = new Serializable();
    getDefaultListSettings.Aggregate = ListManager.Aggregate;
    getDefaultListSettings.AggregateHeight = ListManager.AggregateHeight;
    return getDefaultListSettings;
  }

  getDefaultNupSettings() {
    let getDefaultNupSettings = new Serializable();
    getDefaultNupSettings.Periodicity = GraphType.Weekly;
    getDefaultNupSettings.PeriodicityOptions = PeriodicityHelper.getDefaultPeriodcityButtonOptions();
    getDefaultNupSettings.SelectedLayout = { Type: "Single", Layout: NupLayoutType.SingleLayout2X3 };
    getDefaultNupSettings.Layout = LayoutSettings.getDefaultLayoutSettings();
    return getDefaultNupSettings;
  }

  getDefaultAnalyticsSettings() {
    let getDefaultAnalyticsSettings = new Serializable();
    return getDefaultAnalyticsSettings;
  }

  getDefaultQuoteBoardSettings() {
    let getDefaultQuoteBoardSettings = new Serializable();
    getDefaultQuoteBoardSettings.QuickQuote = ListManager.QuickQuote;
    return getDefaultQuoteBoardSettings;
  }

  getDefaultSummaryStatSettings() {
    let summaryStatSettings = new Serializable();
    summaryStatSettings.isSummaryStatWindowOpen = true;
    summaryStatSettings.summaryStatWindowHeight = 384;
    summaryStatSettings.summaryStatDistColumnId = 61;
    return summaryStatSettings;
  }

  getDefaultFrozenColumnsSpliter() {
    return false;
  }
}

export default TabONeilSettings;
