import BaseServiceApiWCF from "BaseServiceApiWCF";
import ChartApi from "../../../../ServiceApi/Apis/ChartApi.js";
import { dispatch } from "../../../../Redux/dispatch.js";
import { FinancialBlockDialogConstants } from '../../../../Constants/FinancialBlockDialogConstants';
import GraphType from "GraphType";
const { ActionTypes } = FinancialBlockDialogConstants;
import StringUtil from 'StringUtil';
import { getDatagraphStates, getFinancialBlockState, getFinancialDialogBoxState } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors";
import { populatePickListData, updateFinancialBlock } from './FinancialBlock.js';
import { takeLatest, put, call, select } from 'redux-saga/effects';

const periodicityEnum = BaseServiceApiWCF.rayData["PeriodicityEnum"];
const periodicityEnumObj = {
    [GraphType.Daily]: periodicityEnum.Daily, [GraphType.Weekly]: periodicityEnum.Weekly,
    [GraphType.Monthly]: periodicityEnum.Monthly
}
function* populatePages() {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates)
        const financialBlockSetting = viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : null;
        const _pickListData = yield call(populatePickListData, null, true)
        const HeaderTabItemSource = []
        const TotalPageCount = financialBlockSetting.TotalPageCount;
        const LargeBlockSize = financialBlockSetting.LargeBlockSize;
        let CurrentHeaders = financialBlockSetting.CurrentHeaders;
        if (CurrentHeaders[0] === null) {
            const data = yield select(getFinancialBlockState);
            CurrentHeaders = data.CurrentHeaders;
        }
        const CurrentPageIndex = financialBlockSetting.CurrentPage + 1;
        let ItemDataList, PickListItems1, PickListItems2, PickListItems3;

        for (let index = 0; index < TotalPageCount; index++) {
            const startIndex = index * LargeBlockSize;
            const endIndex = (index + 1) * LargeBlockSize;
            const pageItem = {};
            pageItem.PageIndex = index + 1;
            pageItem.RowItems = [];
            pageItem.IsDirty = false;

            let rowIndex = 0;
            for (let index = startIndex; index < endIndex; index++) {
                const blockHeaderData = CurrentHeaders[index];
                const rowItem = {
                    RowIndex: rowIndex + 1,
                    HeaderData: blockHeaderData
                };

                if (blockHeaderData?.canToggle) {
                    const sorted = blockHeaderData.Headers.slice(0);
                    if (sorted.length === 2
                        && sorted[0].HeaderId !== blockHeaderData.ActiveHeader.HeaderId
                        && sorted[1].HeaderId === blockHeaderData.ActiveHeader.HeaderId) {
                        const header1 = sorted[0];
                        sorted[0] = sorted[1]
                        sorted[1] = header1;
                    }
                    rowItem.SortedHeaderOnDisplay = Array.isArray(sorted) ? sorted : [sorted];
                } else {
                    rowItem.SortedHeaderOnDisplay = Array.isArray(blockHeaderData) ? blockHeaderData : [blockHeaderData];
                }

                pageItem.RowItems.push(rowItem);
                rowIndex++;
            }
            PickListItems1 = _pickListData.PickListItems1;
            PickListItems2 = _pickListData.PickListItems2;
            PickListItems3 = _pickListData.PickListItems3;
            ItemDataList = _pickListData.ItemDataList;
            pageItem.PickListItems = index === 0 ? PickListItems1 : index === 1 ? PickListItems2 : index === 2
                ? PickListItems3 : [];
            pageItem.IsShowDetail = false;
            pageItem.SearchText = "";
            pageItem.FilterSource = [];
            HeaderTabItemSource.push(pageItem);
        }
        const IsFinancialBlockDialogOpen = true;
        const selectedPages = yield call(getSelectedPage, financialBlockSetting, HeaderTabItemSource)
        yield put({
            type: ActionTypes.SET_FINANCIALBLOCK_DIALOG_DATA,
            newState: {
                CurrentHeaders, CurrentPageIndex, HeaderTabItemSource, IsFinancialBlockDialogOpen, ItemDataList,
                LargeBlockSize, PickListItems1, PickListItems2, PickListItems3, selectedPages
            }
        })

    } catch (error) {
        console.log(`Error occurs in FinancialBlockDialogBox.js, populatePages ${error} `)
    }
}
function getSelectedPage(financialBlockSetting, HeaderTabItemSource) {
    const CurrentPageIndex = financialBlockSetting.CurrentPage + 1;
    return HeaderTabItemSource[CurrentPageIndex - 1];
}
function* onSearchTextChange({ searchText }) {
    try {
        const filterSource = [];
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const financialBlockSetting = viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : null;

        const { HeaderTabItemSource } = yield select(getFinancialDialogBoxState);
        const selectedPage = yield call(getSelectedPage, financialBlockSetting, HeaderTabItemSource);
        selectedPage.SearchText = searchText;

        if (!StringUtil.isEmpty(searchText)) {
            // const headerItems = ItemDataList;
            const data = yield select(getFinancialDialogBoxState)
            const headerItems = data.ItemDataList;
            const selectedHeader = selectedPage.PickListItems.find((item) => item.IsSelected);
            _.each(headerItems, (item) => {
                const metricItem = Object.assign({}, item);
                const searchMetric = `${metricItem.Header} ${metricItem.Tag}`;
                if (checkKeyExist(searchText, searchMetric)) {
                    if (selectedHeader && selectedHeader.HeaderId === metricItem.HeaderId) { metricItem.IsSelected = true; }
                    filterSource.push(metricItem);
                }
            });
        }
        selectedPage.FilterSource = filterSource;
        yield put({
            type: ActionTypes.FINANCIALBLOCK_DIALOG_INPUTBOX_UPDATE,
            newState: { selectedPage }
        })
    } catch (error) {
        console.log(`Error occurs in FinancialBlockDialogBox.js, onSearchTextChange ${error} `)
    }

}
function checkKeyExist(searchKey, searchData) {
    let isStringExist = true;
    const searchArray = searchKey ? searchKey.split(' ') : [];

    _.each(searchArray, (searchString) => {
        if (isStringExist && !searchData.toUpperCase().includes(searchString.toUpperCase())) {
            isStringExist = false;
        }
    });

    return isStringExist;
}
function* onSelectMetricChange({ detailHeader }) {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const financialBlockSetting = viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : null;

        const { HeaderTabItemSource } = yield select(getFinancialDialogBoxState);
        const selectedPage = yield call(getSelectedPage, financialBlockSetting, HeaderTabItemSource);
        const selectedRowItem = selectedPage.RowItems[selectedPage.SelectedRowIndex - 1];
        if (!selectedRowItem) { return; }

        if (detailHeader.CanToggle && detailHeader.ToggleObject) {
            const toggleHeader = {
                Headers: [],
                canToggle: true
            };
            const primaryheader = {
                HeaderId: detailHeader.HeaderId,
                DisplayName: detailHeader.Header,
                DigitAfterDecimals: detailHeader.DigitsAfterDecimal,
                IsPerc: detailHeader.IsPerc,
                HasEstimateZone: detailHeader.HasEstimateZone
            };
            toggleHeader.Headers.push(primaryheader);
            toggleHeader.ActiveHeader = primaryheader;
            const secondHeader = {
                HeaderId: detailHeader.ToggleObject.HeaderID,
                DisplayName: detailHeader.ToggleObject.PickListName,
                DigitAfterDecimals: detailHeader.ToggleObject.DigitsAfterDecimal,
                IsPerc: detailHeader.ToggleObject.IsPerc,
                HasEstimateZone: detailHeader.ToggleObject.HasEstimateZone
            };
            toggleHeader.Headers.push(secondHeader);

            selectedRowItem.HeaderData = toggleHeader;
            selectedRowItem.SortedHeaderOnDisplay = Array.isArray(toggleHeader.Headers) ? toggleHeader.Headers : [toggleHeader.Headers];
            selectedPage.IsDirty = true;
        } else {
            const header = {
                HeaderId: detailHeader.HeaderId,
                DisplayName: detailHeader.Header,
                DigitAfterDecimals: detailHeader.DigitsAfterDecimal,
                IsPerc: detailHeader.IsPerc,
                HasEstimateZone: detailHeader.HasEstimateZone,
                canToggle: false
            };

            selectedRowItem.HeaderData = header;
            selectedRowItem.SortedHeaderOnDisplay = Array.isArray(header) ? [...header] : [header];
            selectedPage.IsDirty = true;
        }

        const isFilter = selectedPage.SearchText.trimLeft().length > 0;
        const source = isFilter ? selectedPage.FilterSource : selectedPage.PickListItems;
        const selectedHeader = source.find((item) => item === detailHeader);
        if (selectedHeader) { selectedHeader.IsMouseOver = false; }
        selectedPage.IsShowDetail = false;
        yield put({
            type: ActionTypes.FINANCIALBLOCK_DIALOG_INPUTBOX_UPDATE,
            newState: { selectedPage }
        })
    } catch (error) {
        console.log(`Error occurs in FinancialBlockDialogBox.js, onSelectMetricChange ${error} `)

    }

}
function* onRestoreDefaultHeader() {
    const { SymbolInfo, periodicity } = yield select(getDatagraphStates);
    const periodicityEnum = yield call(getPeriodicityEnum, periodicity);

    try {
        const result = yield call([ChartApi, ChartApi.GetDefaultFinancialBlockHeaders], SymbolInfo, periodicityEnum);
        const defaultHeaders = [];

        for (const headerData of result) {
            if (headerData.ToggleHeaderID === null || headerData.ToggleHeaderID === undefined) {
                const header = {};
                header.HeaderId = headerData.HeaderId;
                header.IsPerc = headerData.IsPerc;
                header.DisplayName = headerData.DisplayName.toUpperCase();
                header.DigitAfterDecimals = headerData.DigitsAfterDecimal;
                header.HasEstimateZone = headerData.HasEstimateZone;
                header.canToggle = false;
                defaultHeaders.push(header);
            }
            else {
                if (headerData.IsPrimary) {
                    const toggleHeaderItem = {};
                    toggleHeaderItem.Headers = [];
                    const primaryHeader = {};
                    primaryHeader.HeaderId = headerData.HeaderId;
                    primaryHeader.DisplayName = headerData.DisplayName;
                    primaryHeader.IsPerc = headerData.IsPerc;
                    primaryHeader.DigitAfterDecimals = headerData.DigitsAfterDecimal;
                    primaryHeader.HasEstimateZone = headerData.HasEstimateZone;
                    toggleHeaderItem.ActiveHeader = primaryHeader;
                    toggleHeaderItem.Headers.push(primaryHeader);
                    const toggleHeaderId = headerData.ToggleHeaderID;
                    const toggleHeaderObject = result.find((item) => item.HeaderId === toggleHeaderId);
                    if (toggleHeaderObject !== null) {
                        const toggleHeader = {};
                        toggleHeader.HeaderId = toggleHeaderObject.HeaderId;
                        toggleHeader.DisplayName = toggleHeaderObject.DisplayName.toUpperCase();
                        toggleHeader.DigitAfterDecimals = toggleHeaderObject.DigitsAfterDecimal;
                        toggleHeader.IsPerc = toggleHeaderObject.IsPerc;
                        toggleHeader.HasEstimateZone = toggleHeaderObject.HasEstimateZone;
                        toggleHeaderItem.Headers.push(toggleHeader);
                    }
                    toggleHeaderItem.canToggle = true;

                    defaultHeaders.push(toggleHeaderItem);
                }
            }
        }
        dispatch({ type: ActionTypes.FINANCIALBLOCK_CALL_RESTORE, defaultHeaders })
    } catch (e) {
        console.log(`Error occurs in FinancialBlockDialogBox.js, onRestoreDefaultHeader ${e} `)
    }
}
function getPeriodicityEnum(periodicity) {
    const periodEnumType = periodicityEnumObj[periodicity];
    return periodEnumType;
}
function* restoreHeaders({ defaultHeaders }) {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const financialBlockSetting = viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : null;

        const TotalPageCount = financialBlockSetting.TotalPageCount;
        const LargeBlockSize = financialBlockSetting.LargeBlockSize;
        const { HeaderTabItemSource } = yield select(getFinancialDialogBoxState);
        if (defaultHeaders.length > 0) {
            const IsFinancalRestoreDialogOpen = false

            for (let index = 0; index < TotalPageCount; index++) {
                const pageItem = HeaderTabItemSource[index];
                pageItem.RowItems = [];

                const startIndex = index * LargeBlockSize;
                const endIndex = (index + 1) * LargeBlockSize;
                let rowIndex = 0;

                for (let index = startIndex; index < endIndex; index++) {
                    const blockHeaderData = defaultHeaders[index];

                    const rowItem = {
                        RowIndex: rowIndex + 1,
                        HeaderData: blockHeaderData
                    };

                    if (blockHeaderData?.canToggle) {
                        const sorted = blockHeaderData.Headers.slice(0);
                        if (sorted.length === 2
                            && sorted[0].HeaderId !== blockHeaderData.ActiveHeader.HeaderId
                            && sorted[1].HeaderId === blockHeaderData.ActiveHeader.HeaderId) {
                            const header1 = sorted[0];
                            sorted[0] = sorted[1]
                            sorted[1] = header1;
                        }
                        rowItem.SortedHeaderOnDisplay = Array.isArray(sorted) ? sorted : [sorted];
                    } else {
                        rowItem.SortedHeaderOnDisplay = Array.isArray(blockHeaderData) ? blockHeaderData : [blockHeaderData];
                    }

                    pageItem.RowItems.push(rowItem);
                    rowIndex++;
                }
                pageItem.IsDirty = true;
                pageItem.IsShowDetail = false;
                pageItem.SearchText = "";
                pageItem.FilterSource = [];
            }
            const selectedPages = yield call(getSelectedPage, financialBlockSetting, HeaderTabItemSource)
            yield put({
                type: ActionTypes.FINANCIALBLOCK_DIALOG_UPDATE_CHANGES,
                newState: { selectedPages, IsFinancalRestoreDialogOpen }
            })
        }
    } catch (error) {
        console.log(`Error occurs in FinancialBlockDialogBox.js, restoreHeaders ${error} `)
    }
}
function* onSubmitChanges() {
    try {
        const { viewsSettings, majorPeriodicity } = yield select(getDatagraphStates);
        const financialBlockSetting = viewsSettings.FinancialBlockSettings ? viewsSettings.FinancialBlockSettings[majorPeriodicity] : null;

        const { HeaderTabItemSource, CurrentHeaders } = yield select(getFinancialDialogBoxState);

        const LargeBlockSize = financialBlockSetting.LargeBlockSize;
        const isTabsDirty = HeaderTabItemSource.filter((item) => item.IsDirty).length > 0;
        if (isTabsDirty) {
            for (let index = 0; index < HeaderTabItemSource.length; index++) {
                const pageItem = HeaderTabItemSource[index];
                let headerIndex = index * LargeBlockSize;
                if (pageItem.IsDirty) {
                    for (const row of pageItem.RowItems) {
                        CurrentHeaders[headerIndex] = row.HeaderData;
                        headerIndex++;
                    }
                }
            }

            yield call(updateFinancialBlock, CurrentHeaders);
        }
        const IsFinancialBlockDialogOpen = false;
        yield put({
            type: ActionTypes.FINANCIALBLOCK_DIALOG_UPDATE_CHANGES,
            newState: { IsFinancialBlockDialogOpen, CurrentHeaders, HeaderTabItemSource }
        })
    } catch (error) {
        console.log(`Error occurs in FinancialBlockDialogBox.js, onSubmitChanges ${error} `, error)
    }
}
/************************* WATHCERS **************************/
export function* watcherOpenFinancialDialogBox() {
    yield takeLatest(ActionTypes.OPEN_FINANCIALBLOCK_DIALOG, populatePages)
}
export function* watcheronSearchTextChange() {
    yield takeLatest(ActionTypes.FINANCIALBLOCK_DIALOG_SEARCHTEXT_CHANGE, onSearchTextChange)
}
export function* watcheronSelectedMetricChanged() {
    yield takeLatest(ActionTypes.FINANCIALBLOCK_DIALOG_SELECTED_METRIC_CHANGE, onSelectMetricChange)
}
export function* watcheronRestoreDefaultHeaders() {
    yield takeLatest(ActionTypes.FINANCIALBLOCK_DIALOG_RESTORE, onRestoreDefaultHeader)
}
export function* watcheronSubmitChanges() {
    yield takeLatest(ActionTypes.FINANCIALBLOCK_DIALOG_SUBMIT_CHANGE, onSubmitChanges)
}
export function* watcherCallRestore() {
    yield takeLatest(ActionTypes.FINANCIALBLOCK_CALL_RESTORE, restoreHeaders)
}