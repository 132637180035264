import PropTypes from 'prop-types';
import React, { Component } from "react";
import Measure from "react-measure";
import ScrollBar from "ScrollBar";
import VirtualListBody from './VirtualListBody';

export default class VirtualList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
      items: props.items,
      itemHeight: props.itemHeight ? props.itemHeight : 0,
      showScrollOnHover: props.showScrollOnHover,
      shouldUpdate: true,
      firstVisibleIndex: 0,
      lastVisibleIndex: 0
    };
    this.firstVisibleIndex = 0;
    this.lastVisibleIndex = 0;
    this.visibleItems = [];
    this.setVirtualItem = this.setVirtualItem.bind(this);
    this.setVisibleItem = this.setVisibleItem.bind(this);
    this.getItemRenderer = this.getItemRenderer.bind(this);
    this.mouseUp = this.mouseUp.bind(this)
  }

  componentDidMount() {
    this.props.id === 'NupViewScrollbar'? this.setVirtualItem() : this.setVisibleItem();
  }

  shouldComponentUpdate(props, state) {
    return state.shouldUpdate;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.items && props.items.length > 0 && this.state.itemHeight) {
      //add the visibleItems to set the fist render the virtual list.
      let newState = {
        items: props.items,
        shouldUpdate: true
      };
      if (this.panel && this.props.id === 'NupViewScrollbar') {
        const scrollTop = this.virtualScroll.scrollTop;
        this.visibleItems.length = props.rowsCount;
        this.firstVisibleIndex = Math.ceil(scrollTop / this.state.itemHeight);
        this.lastVisibleIndex = Math.min(props.items.length, this.firstVisibleIndex + props.rowsCount);
        if(this.lastVisibleIndex === this.state.items.length && (this.firstVisibleIndex + this.props.rowsCount) !== this.lastVisibleIndex){
          if(this.lastVisibleIndex - this.props.rowsCount > 0)
            this.firstVisibleIndex = this.lastVisibleIndex - this.props.rowsCount;
          else 
            this.firstVisibleIndex = 0
        }
        if(props.itemHeight !== this.state.itemHeight){
          this.virtualScroll.scrollTop = props.itemHeight* this.firstVisibleIndex;
        }
        this.visibleItems = props.items.slice(this.firstVisibleIndex, this.lastVisibleIndex);
        this.panel.updateItems(this.visibleItems);
        newState = {
          ...newState,
          itemHeight: props.itemHeight,
          shouldUpdate: props.shouldUpdate,
          firstVisibleIndex: this.firstVisibleIndex,
          lastVisibleIndex: this.lastVisibleIndex
        }
      }
      else if(this.panel){
        const scrollTop = this.scrollPanel.scrollTop;
        const visibleCount = Math.floor(this.scrollPanel.clientHeight / this.state.itemHeight);
        this.visibleItems.length = visibleCount;
        this.firstVisibleIndex = Math.floor(scrollTop / this.state.itemHeight);
        this.lastVisibleIndex = Math.min(props.items.length, this.firstVisibleIndex + visibleCount + 2);
        this.visibleItems = props.items.slice(this.firstVisibleIndex, this.lastVisibleIndex);
        this.panel.updateItems(this.visibleItems);
      }
      Object.assign(this.state, newState);
    }
  }

  componentDidUpdate() {
    setTimeout(() => {
      if (this.scrollBar) {
        this.scrollBar.refresh();
      }
    }, 200);
  }

  setVisibleItem() {
    if (!this.panel || !this.scrollPanel) {
      return;
    }
    let scrollTop = this.scrollPanel.scrollTop;
    let visibleCount = 0
    if (this.props.miniListOnScroll) {
      visibleCount = Math.floor(window.innerHeight / this.state.itemHeight);
    } else {
      visibleCount = Math.floor(this.scrollPanel.clientHeight / this.state.itemHeight);
    }
    this.visibleItems.length = visibleCount;
    this.firstVisibleIndex = Math.floor(scrollTop / this.state.itemHeight);
    this.lastVisibleIndex = Math.min(this.state.items.length, this.firstVisibleIndex + visibleCount + 2);
    if ((this.props.id == 'showscrollOnHover' || this.props.id == 'NupViewScrollbar') && this.props.miniListOnScroll) {
      if (this.lastVisibleIndex > this.state.items.length - visibleCount)
        this.visibleItems = this.state.items.slice(this.firstVisibleIndex, this.lastVisibleIndex);
        this.panel.updateItems(this.visibleItems);
        if (this.props.miniListOnScroll(this.lastVisibleIndex, visibleCount)) return;
    }
    if (this.firstVisibleIndex !== this.state.firstVisibleIndex || this.lastVisibleIndex !== this.state.lastVisibleIndex) {
      this.visibleItems = this.state.items.slice(this.firstVisibleIndex, this.lastVisibleIndex);

      this.panel.updateItems(this.visibleItems);
      let newState = { shouldUpdate: true};
      Object.assign(this.state, newState);
    }
  }

  setVirtualItem() {
    if (!this.panel || !this.scrollPanel) {
      return;
    }
    let scrollTop = this.scrollPanel.scrollTop;
    this.firstVisibleIndex = Math.ceil(scrollTop / this.state.itemHeight);
    this.lastVisibleIndex = Math.min(this.state.items.length, this.firstVisibleIndex + this.props.rowsCount);
    if(this.lastVisibleIndex === this.state.items.length && (this.firstVisibleIndex + this.props.rowsCount) !== this.lastVisibleIndex){
      if(this.lastVisibleIndex - this.props.rowsCount > 0)
        this.firstVisibleIndex = this.lastVisibleIndex - this.props.rowsCount;
      else 
        this.firstVisibleIndex = 0
    }
    if (this.firstVisibleIndex !== this.state.firstVisibleIndex || this.lastVisibleIndex !== this.state.lastVisibleIndex) {
      if (this.props.miniListOnScroll && this.props.miniListOnScroll(this.lastVisibleIndex, this.props.rowsCount)) {}
      this.visibleItems = this.state.items.slice(this.firstVisibleIndex, this.lastVisibleIndex);
      clearTimeout(this.virtulaTimeout);
      this.virtulaTimeout = setTimeout(this.props.onMouseUp, 800, this.firstVisibleIndex, this.lastVisibleIndex)
      this.virtualScroll.scrollTop = this.firstVisibleIndex* this.props.itemHeight;
      this.visibleItems.map((item)=> item.map((value)=> value.isLoading = true));
      this.panel.updateItems(this.visibleItems);
      let newState = { shouldUpdate: this.props.shouldUpdate, firstVisibleIndex: this.firstVisibleIndex, lastVisibleIndex: this.lastVisibleIndex };
      Object.assign(this.state, newState);
    }
  }

  setDimensions(dim) {
    this.setState({ height: dim.height, width: dim.width, shouldUpdate: false }, this.props.id === 'NupViewScrollbar'? ()=>{} : this.setVisibleItem);
  }

  scrollToItemIndex(index) {
    let scrollTop = index * this.state.itemHeight;
    if(this.props.id !== 'NupViewScrollbar')
      scrollTop -= ((this.scrollPanel.clientHeight / 2) - this.state.itemHeight);
    this.scrollPanel.scrollTop = scrollTop;
  }

  scrollToItem(step){
    if(step === 0)
    {
      this.scrollPanel.scrollTop = 0;
    }
    else{
      let scrollTop = this.scrollPanel.scrollTop;
      let scrolledItem = Math.round(scrollTop / this.state.itemHeight);
      scrolledItem += step;
      this.scrollPanel.scrollTop = scrolledItem*this.state.itemHeight;
    }
    
  }

  getItemRenderer(item, index) {
    if (this.props.itemRenderer) {
      let itemIndex = this.firstVisibleIndex + index;
      let top = itemIndex * this.state.itemHeight;
      let elm = this.props.itemRenderer(item, top, itemIndex);
      return elm;
    }
    return null;
  }

  mouseUp(){
    if(typeof this.props.onMouseUp === "function") 
      this.props.onMouseUp(this.firstVisibleIndex, this.lastVisibleIndex)
  }

  render() {
    let count = this.state.items.length;
    let scrollHeight = count * this.state.itemHeight + "px";
    return (
      (this.props.id == 'NupViewScrollbar') ?
        <div className="custom-scroll">
          <div id={'symbol-' + this.props.id + '-VerticalScroll'} ref={(ref) => this.virtualScroll = ref}>
            <Measure
              bounds
              onResize={(contentRect) => {
                this.setDimensions(contentRect.bounds)
              }}
            >
              {({ measureRef }) =>
                <div ref={measureRef} className={this.props.showScrollOnHover ? "virtualList showscrollOnHover" : "virtualList"} style={{ width: "100%" }}>
                  <VirtualListBody ref={(ref) => this.panel = ref} showScrollOnHover={this.props.showScrollOnHover} scrollHeight={scrollHeight} getItemRenderer={this.getItemRenderer} />
                </div>
              }
            </Measure>
          </div>
          <div id={'symbol-'+ this.props.id + '-VirtualScrolling'} ref = {(ref)=> this.scrollPanel = ref} style ={{ visibility: "hidden", overflow: 'hidden'}} onScroll = {this.setVirtualItem}>
              <div style={{width: "100%", height: scrollHeight}}></div>
          </div>
          <ScrollBar scrollId={'symbol-'+ this.props.id + '-VirtualScrolling'} itemHeight={this.state.itemHeight} vScroll={true} hScroll={false} scrollOnHover={true} ref={(ref) => this.scrollBar = ref} mouseUp = { this.mouseUp} shouldUpdate= {this.props.shouldUpdate} />
        </div>:
      (this.props.id == 'showscrollOnHover' || this.props.id == 'RIPanelIdeaListInfo') ?
        <div className="custom-scroll">
          <div id={'symbol-' + this.props.id + '-VerticalScroll'} ref={(ref) => this.scrollPanel = ref} onScroll={this.setVisibleItem}>
            <Measure
              bounds
              onResize={(contentRect) => {
                this.setDimensions(contentRect.bounds)
              }}
            >
              {({ measureRef }) =>
                <div ref={measureRef} className={this.props.showScrollOnHover ? "virtualList showscrollOnHover" : "virtualList"} style={{ width: "100%" }}>
                  <VirtualListBody ref={(ref) => this.panel = ref} showScrollOnHover={this.props.showScrollOnHover} scrollHeight={scrollHeight} getItemRenderer={this.getItemRenderer} />
                </div>
              }
            </Measure>
          </div>
          <ScrollBar scrollId={'symbol-' + this.props.id + '-VerticalScroll'} itemHeight={this.state.itemHeight} vScroll={true} hScroll={false} scrollOnHover={true} ref={(ref) => this.scrollBar = ref}/>
        </div>
        :
        <Measure
          bounds
          onResize={(contentRect) => {
            this.setDimensions(contentRect.bounds)
          }}
        >
          {({ measureRef }) =>
            <div ref={measureRef} className={this.state.showScrollOnHover ? "virtualList showscrollOnHover" : "virtualList"}
              onScroll={this.setVisibleItem} style={{ width: "100%", height: "100%" }}>
              <div className={(this.props.id == 'symbolEntry') ? "custom-scroll custom-scroll-light" : "custom-scroll"}>
                <div id={'symbol-' + this.props.id + '-VerticalScroll'} ref={(ref) => this.scrollPanel = ref}>
                  <VirtualListBody ref={(ref) => this.panel = ref} showScrollOnHover={this.props.showScrollOnHover} scrollHeight={scrollHeight} getItemRenderer={this.getItemRenderer} />
                </div>
                <ScrollBar scrollId={'symbol-' + this.props.id + '-VerticalScroll'} itemHeight={this.state.itemHeight} vScroll={true} scrollOnHover={true} ref={(ref) => this.scrollBar = ref}/>
              </div>
            </div>
          }
        </Measure>
    );
  }
}

VirtualList.propTypes = {
  items: PropTypes.array.isRequired,
  itemRenderer: PropTypes.func.isRequired,
  itemHeight: PropTypes.number.isRequired,
  showScrollOnHover: PropTypes.bool,
  shouldUpdate: PropTypes.bool,
  miniListOnScroll: PropTypes.func,
  onMouseUp: PropTypes.func
};

VirtualList.defaultProps = {
  shouldUpdate: true
}
