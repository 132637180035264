import ArithmaticScale from "../../../Utils/Scales/ArithmaticScale.js";
import BaseServiceApi from 'BaseServiceApi';
import CalcMALine from "../../../Utils/Calcs/PriceMA.js";
import CalcRSLine from "../../../Utils/Calcs/RSLine.js";
import consoleApi from "../../../ServiceApi/Apis/ConsoleApi.js";
import ConsoleStore from "ConsoleStore";
import { DataGraphConstants } from "../../../Constants/DataGraphConstants.js";
import GraphComponent from "../../../Constants/GraphComponent.js";
import IndustryTypeConstants from "../../../Constants/IndustryTypeConstants.js";
import LocalizationStore from "Stores/Localization/LocalizationStore.js";
import PeriodicityHelper from "../../../Utils/PeriodicityHelper.js";
import { PriceChartConstants } from "../../../Constants/PriceChartConstants";
import PriceMenuHelper from "./PriceMenuHelper";
import RelativeStrenghtLineSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelativeStrengthLineSettings.js";
import SettingStore from "../../../Stores/ConsoleWindow/Settings/SettingsStore.js";
import SymbolType from "../../../Constants/SymbolType.js";
import UserInfoUtil from "../../../Utils/UserInfoUtil.js";
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { cancelRSLineDialogSetting, updateRSLength, updateRSSymbolError } from "../../../Actions/PricePanelActions.js";
import { ErrorMessageTranslateHelper, IndexNameTranslateHelper, IndustryGroupsTranslateHelper } from "../../../Utils/TranslateHelper.js";
import {
    priceChartReducerselect, getRSLineStates, getDatagraphStates
} from '../../../Reducers/NavDataGraph/TabDataGraph/selectors'

// import RelativeStrenghtLineSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/RelativeStrengthLineSettings.js";
import GraphType from "../../../Constants/GraphType.js";

const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
const { ActionTypes } = PriceChartConstants;
function internationalStock(info) {
    return (info &&
        info.SymTypeEnum &&
        info.SymTypeEnum === SymbolType.INTERNATIONALSTOCK
    );
}
function getGroups(info) {
    try {
        const international = info ? internationalStock(info) : false;
        const groups = [];
        groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONINDUSTRYGROUP197]} (197)`, type: SymbolType.WONINDUSTRYGROUP197, symbol: info.Industry197Symbol });
        if (!international) { groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONMAJORINDUSTRY89]} (89)`, type: SymbolType.WONMAJORINDUSTRY89, symbol: info.MajorIndustrySymbol }); }
        groups.push({ name: `${IndustryGroupsTranslateHelper[SymbolType.WONSECTOR11]} (11)`, type: SymbolType.WONSECTOR11, symbol: info.Sector11Symbol });
        return groups;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getGroups ${error}`);
    }
}
function getRSLineData(indexData, graphData, lastNode) {
    try {
        // const instSettings = PriceMenuHelper.getInstSettings();
        const periodicityM = PriceMenuHelper.getPeriodicity();

        const majorPeriodicity = PeriodicityHelper.mapMajorPeriodicities(periodicityM);
        // let RSLinesettings;
        // if (rsLineNum === 1){
        //     RSLinesettings = instSettings.RelativeStrenghtLine1SettingsByPeriodicity[majorPeriodicity]; 
        // }
        // else {
        //     RSLinesettings = instSettings.RelativeStrenghtLine2SettingsByPeriodicity[majorPeriodicity];
        // }
        // if (!RSLinesettings.IsVisible) {
        //     return [];
        // }
        const calcRSLine = new CalcRSLine();

        let lastnodeIndex = graphData.length;
    //    lastnodeIndex = ConsoleStore.getState().nodeCount ? ConsoleStore.getState().nodeCount : lastnodeIndex;

    //    const lastND = Math.min(lastnodeIndex, graphData.length);
        return calcRSLine.CalculateRSLine(indexData, graphData, lastNode, lastnodeIndex, majorPeriodicity);
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getRSLineData ${error}`);
    }
}
function getLowest(hsfResults, startXPoint, nodeWidth) {
    try {
        let lowest = Number.POSITIVE_INFINITY;
        let tmp;
        const prcLength = hsfResults.length;
        for (let i = 0; i < prcLength; i++) {
            startXPoint -= nodeWidth;
            if (startXPoint < 0) { break; }
            tmp = hsfResults[i].Close;
            if (tmp < lowest) { lowest = tmp; }
        }
        return lowest - lowest * 0.02;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getLowest ${error}`);
    }
}
function getLinearScale(hsfResults, startXPoint, nodeWidth) {
    try {
        const prcLength = hsfResults.length;
        let lowest = Number.POSITIVE_INFINITY;
        let highest = Number.NEGATIVE_INFINITY;
        for (let i = 0; i < prcLength; i++) {
            startXPoint -= nodeWidth;
            if (startXPoint < 0) { break; }
            if (hsfResults[i] === undefined) { break; }
            const tmp = hsfResults[i].Close;
            if (tmp < lowest) { lowest = tmp; }
            if (tmp > highest) { highest = tmp; }
        }
        const scale = new ArithmaticScale();
        const height = PriceMenuHelper.getHeight();
        scale.InitScale(lowest, highest, height / 3);
        return scale;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getLinearScale ${error}`);
    }
}

function getRSLineA(scale, hsfResults, startXPoint, nodeWidth) {
    try {
        const prcLength = hsfResults.length;
        if (prcLength < 1) {
            return [];
        }
        const height = PriceMenuHelper.getHeight();
        let x = 0;
        const chartData = new Array(prcLength);
        for (let j = 0; j < prcLength; j++) {
            if (startXPoint < 0) { break; }
            let scale1 = scale.ComputeY(hsfResults[j].Close);
            scale1 = isNaN(scale1) ? 0 : scale1;
            const yPrice = scale1 + height * 2 / 3;
            const info = {
                Date: hsfResults[j].Date,
                yPrice: yPrice,
                xAxis: startXPoint,
                price: hsfResults[j].Close
            };
            startXPoint -= nodeWidth;
            chartData[x] = info;
            x++;
        }
        return chartData;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getRSLineA ${error}`);
    }
}

function getRSLineL(scale, hsfResults, lowest, startXPoint, nodeWidth) {
    try {
        const prcLength = hsfResults.length;
        const chartData = new Array(prcLength);
        let x = 0;
        for (let j = 0; j < prcLength; j++) {
            if (startXPoint < 0) { break; }
            const yPrice = scale.ComputeYL(hsfResults[j].Close, lowest);
            const info = {
                Date: hsfResults[j].Date,
                yPrice: yPrice,
                xAxis: startXPoint,
                price: hsfResults[j].Close
            };
            startXPoint -= nodeWidth;
            chartData[x] = info;
            x++;
        }
        return chartData;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getRSLineL ${error}`);
    }
}
function getRSLine(scale, hsfResults, lowest, scaleLabel, startXPoint, nodeWidth) {
    try {
        if (scaleLabel === "LIN") {
            const rsLine = getRSLineA(scale, hsfResults, startXPoint, nodeWidth)
            return rsLine;
        } else {
            const rsLine = getRSLineL(scale, hsfResults, lowest, startXPoint, nodeWidth)
            return rsLine;
        }
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getRSLine ${error}`);
    }
}
function PriceAverageL(scale, hsfResults, startXPoint, nodeWidth) {
    try {
        if (hsfResults === undefined) {
            return [];
        }
        const prcLength = hsfResults.length;
        const chartData = new Array(prcLength);
        let x = 0;
        for (let j = 0; j < prcLength; j++) {
            if (startXPoint < 0) { break; }
            const yPrice = scale.ComputeY(hsfResults[j].Close);
            const info = {
                Date: hsfResults[j].Date,
                yPrice: yPrice,
                xAxis: startXPoint,
                yValue: hsfResults[j].Close
            };
            startXPoint -= nodeWidth;
            chartData[x] = info;
            x++;
        }
        return chartData;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, PriceAverageL ${error}`);
    }
}
function PriceAverageA(scale, hsfResults, startXPoint, nodeWidth) {
    try {
        if (hsfResults === undefined) {
            return [];
        }
        const prcLength = hsfResults.length;
        const chartData = new Array(prcLength);
        let x = 0;
        for (let j = 0; j < prcLength; j++) {
            if (startXPoint < 0) { break; }
            const yPrice = scale.ComputeY(hsfResults[j].Close);
            const info = {
                Date: hsfResults[j].Date,
                yPrice: yPrice,
                xAxis: startXPoint,
                yValue: hsfResults[j].Close
            };
            startXPoint -= nodeWidth;
            chartData[x] = info;
            x++;
        }
        return chartData;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, PriceAverageA ${error}`);
    }
}
function PriceAverage(scale, hsfResults, RSLine, startXPoint, nodeWidth) {
    try {
        if (scale.getObjectMapKey() === "WArithmaticScale" && RSLine > 0) {
            const priceAverage = PriceAverageA(scale, hsfResults, startXPoint, nodeWidth);
            return priceAverage;
        } else {
            const priceAverage = PriceAverageL(scale, hsfResults, startXPoint, nodeWidth)
            return priceAverage;
        }
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, PriceAverage ${error}`);
    }
}
function getPriceMA(scale, hsfData, maPeriod, lastNode, maType, RSLine = 0, startXPoint, nodeWidth) {
    try {
        let vMAValues = [];
        const calcPriceMA = new CalcMALine();

        vMAValues = maType && maType === "SMA" ?
            calcPriceMA.CalculatePriceMA(hsfData, maPeriod, lastNode) :
            calcPriceMA.CalculateExponentialMovingAverage(hsfData, maPeriod, lastNode);
        if (vMAValues && vMAValues.length < 1) { return; }
        const priceAverage = PriceAverage(scale, vMAValues, RSLine, startXPoint, nodeWidth)
        return priceAverage;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getPriceMA ${error}`);
    }
}
function getRSMALineData(scale, indexData, rsLineData, lastNode, rsLineNum, RSLinesettings, majorPeriodicity, startXPoint, nodeWidth) {
    try {   
        // if (!RSLinesettings.IsVisible) {
        //     return [];
        // }
        const rsData = [];
        rsLineData.forEach((t) => rsData.push({ Date: t.Date, Close: t.YValue, _volume: 1, IsVisible: true }));
        const rsMAData = getPriceMA(scale, rsData, RSLinesettings.maLineLength, 0, RSLinesettings.maLineType, rsLineNum, startXPoint, nodeWidth);
        const calcRSLine = new CalcRSLine();
        let lastnodeIndex = rsLineData.length;
        //lastnodeIndex = ConsoleStore.getState().nodeCount ? ConsoleStore.getState().nodeCount : lastnodeIndex;

        //const lastND = Math.min(lastnodeIndex, rsLineData.length);
        return calcRSLine.MapRSRelLine(indexData, rsMAData, lastNode, lastnodeIndex, majorPeriodicity);
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getRSMALineData ${error}`);
    }
}

function getTranslatedIndexName(indexNameUsed, indexSymbol) {
    if (indexNameUsed && indexSymbol) {
        if(!IndexNameTranslateHelper[indexNameUsed]){
            IndexNameTranslateHelper[indexNameUsed] = LocalizationStore.getTranslatedData(`Index_Industry_${indexSymbol}`, indexNameUsed);
        }
        return IndexNameTranslateHelper[indexNameUsed];
    } else {
        return IndexNameTranslateHelper[IndustryTypeConstants.S_P_500_Index];
    }
}
function getIndexName(stState) {
    let indexNameUsed = undefined;
    let indexSymbol = undefined;
    if (stState && stState.IndexClosingResults) {
        indexNameUsed = stState.IndexClosingResults.Coname;
        indexSymbol = stState.IndexClosingResults.Symbol;
    }
    return getTranslatedIndexName(indexNameUsed, indexSymbol);
}

function getRsName(rsLineNo, pricePanelData, rsSettings) {
    const stState = pricePanelData;
    let indexNameUsed = undefined;
    let indexSymbol = undefined;
    if (stState && rsLineNo === 1 && stState.Rs1ClosingResults) {
        indexNameUsed = stState.Rs1ClosingResults.Coname;
        indexSymbol = stState.Rs1ClosingResults.Symbol;
    } else if (stState && rsLineNo === 2 && stState.Rs2ClosingResults) {
        if (rsSettings && rsSettings.valueType === "default") {
            return rsSettings.IndexName
        }
        indexNameUsed = stState.Rs2ClosingResults.Coname;
        indexSymbol = stState.Rs2ClosingResults.Symbol;
    } else {
        return getIndexName(stState);
    }
    return getTranslatedIndexName(indexNameUsed, indexSymbol);
}
function getRSLineSettings(pricePanelData, rsLineNo, RelativeStrenghtItem, settingsByPeriodicity, ClosingResults, startXPoint, nodeWidth, majorPeriodicity, scale, scaleLabel, instSettings) {
    try {
        const state = {scale, scaleLabel};
        const info = pricePanelData.SymbolInfo;
        let country = null;
        if (info !== null && info !== undefined) { country = info.CountryCode; }
        const rsSettings = instSettings[RelativeStrenghtItem][country];
        state[settingsByPeriodicity] = rsLineNo === 1 ? instSettings.RelativeStrenghtLine1SettingsByPeriodicity[majorPeriodicity] : instSettings.RelativeStrenghtLine2SettingsByPeriodicity[majorPeriodicity]
        
        state.periodicity = majorPeriodicity;
        // state[RelativeStrenghtItem] = instSettings[RelativeStrenghtItem][country];
        state.isRSLine1DialogOpen = false;
        state.groups = getGroups(pricePanelData.SymbolInfo);
        state.errorMessage = ErrorMessageTranslateHelper.ENTER_VALID_SYMBOL;

        let rsData = [];
        const data = pricePanelData.HsfData.HSFResults;
        data.indexData = pricePanelData.IndexClosingResults
            ? pricePanelData.IndexClosingResults.HSFResults
            : null;
        const indexLineSettings = instSettings.IndexLineSettings[country]
        if (rsSettings.valueType === "default") {
            if(rsLineNo === 1) { rsSettings.IndexSymbol = indexLineSettings.IndexSymbol; }
            rsSettings.IndexName = info.IndexName;
        }
        if (rsSettings.IndexSymbol === indexLineSettings.IndexSymbol) {
            rsData = (data.indexData && data.indexData.length > 0) ? data.indexData : [];
        }
        else {
            if (pricePanelData[ClosingResults] !== null &&
                pricePanelData[ClosingResults] !== undefined) { rsData = pricePanelData[ClosingResults].HSFResults; }
        }
        // }
        const rsTextValue = rsLineNo && getRsName(rsLineNo, pricePanelData, rsSettings)
        const rsText = `RS ${rsLineNo} (${rsTextValue})`;
        state.chartHeight = scale.height;
        if (rsData) {
            const rsLineData = getRSLineData(rsData, data, 0, rsLineNo);
            const lowest = getLowest(rsLineData, startXPoint, nodeWidth);
            if (scaleLabel === "LIN") {
                state.scale = getLinearScale(rsLineData, startXPoint, nodeWidth);
            }
            state.rsLineData = getRSLine(state.scale, rsLineData, lowest, scaleLabel, startXPoint, nodeWidth);
            const rsMaLineData = getRSMALineData(state.scale, rsData, rsLineData, 0, rsLineNo, state[settingsByPeriodicity], majorPeriodicity, startXPoint, nodeWidth);
            state.rsLineMAData = getRSLine(state.scale, rsMaLineData, lowest, scaleLabel, startXPoint, nodeWidth);
            
            const maxValue = getMaxY(state.rsLineData);
            if (maxValue > state.scale.Height) {
                let diff = 10 + (maxValue - state.scale.Height);
                state.rsLineData.forEach((element) => { element.yPrice = element.yPrice - diff });
                state.rsLineMAData.forEach((element) => { element.yPrice = element.yPrice - diff });
            }

            state.rsText = rsText;
            state.rsMAText = `${state[settingsByPeriodicity].maLineLength} ${state[settingsByPeriodicity].maLineType}`;
        }
        return state;
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, getRSLineSettings ${error}`);
    }
}
function getMaxY(data) {
    let maxValue = 0;
    data.forEach((element) => { if (element.yPrice > maxValue) maxValue = element.yPrice; });
    return maxValue;
}
function* processRSLine(action) {
    try {
        if(action?.isStreamingProc){
            return;
        }
        const { line1, line2 } = action
        const { pricePanelData, startXPoint, nodeWidth, majorPeriodicity, viewsSettings, isIntraday} = yield select(getDatagraphStates);
        if(!isIntraday){
            const { scale, scaleLabel } = yield select(priceChartReducerselect)
            const rs1Settings = viewsSettings["RelativeStrenghtLine1Settings"];
            if (rs1Settings) {
                if (!line1) {
                    const RS1LineSettings = getRSLineSettings(pricePanelData, 1, 'RelativeStrenghtLine1Settings', 'rs1SettingsByPeriodicity', "Rs1ClosingResults", startXPoint, nodeWidth, majorPeriodicity, scale, scaleLabel, viewsSettings);
                    yield put({
                        type: ActionTypes.RS1_LINE_DATA_READY,
                        RS1LineSettings
                    });
                }
            } else {
                yield put({
                    type: ActionTypes.CLEAR_RS1_LINE_DATA
                });
            }
    
            const rs2Settings = viewsSettings["RelativeStrenghtLine2Settings"];
            if (rs2Settings) {
                if (!line2) {
                    const RS2LineSettings = getRSLineSettings(pricePanelData, 2, 'RelativeStrenghtLine2Settings', 'rs2SettingsByPeriodicity', "Rs2ClosingResults", startXPoint, nodeWidth, majorPeriodicity, scale, scaleLabel, viewsSettings);
                    yield put({
                        type: ActionTypes.RS2_LINE_DATA_READY,
                        RS2LineSettings
                    });
                }
            } else {
                yield put({
                    type: ActionTypes.CLEAR_RS2_LINE_DATA
                });
            }
        }
        else{
            yield put({
                type: ActionTypes.CLEAR_RS1_LINE_DATA
            });
            yield put({
                type: ActionTypes.CLEAR_RS2_LINE_DATA
            });
        }
        
    }
    catch (error) {
        console.log(`Error occurs in RSLineSaga.js, processRSLine ${error}`);
    }
}
const rsLineSettingsConst = {
    [GraphComponent.RSLine1]: 'RelativeStrenghtLine1Settings',
    [GraphComponent.RSLine2]: 'RelativeStrenghtLine2Settings'
};
const rsLineSettingsByPeriodicityConst = {
    [GraphComponent.RSLine1]: 'RelativeStrenghtLine1SettingsByPeriodicity',
    [GraphComponent.RSLine2]: 'RelativeStrenghtLine2SettingsByPeriodicity'
}

function* openRSLineEditDialog({ item }) {
    try {
        const { pricePanelData } = yield select(priceChartReducerselect);
        const SymTypeEnum = pricePanelData.SymbolInfo.SymTypeEnum
        const isSymbolIndex = SymbolType.INDEX === SymTypeEnum;
        const isSymbolGroup = SymbolType.WONINDUSTRYGROUP197 === SymTypeEnum ||
            SymbolType.WONMAJORINDUSTRY89 === SymTypeEnum ||
            SymbolType.WONSECTOR11 === SymTypeEnum ||
            SymbolType.WONSUBSECTOR33 === SymTypeEnum;
        const isFund = SymbolType.FUND === SymTypeEnum;

        const instSettings = PriceMenuHelper.getInstSettings();
        const majorPeriodicity = PriceMenuHelper.getMajorPeriodicity();
        const settings = instSettings[rsLineSettingsByPeriodicityConst[item.graphComponent]][majorPeriodicity];
        const countries = UserInfoUtil.getUserInfo().regionCountries;
        const regionCountries = {}
        const regions = [];
        const CountryCode = pricePanelData.SymbolInfo.CountryCode;
        let IndexName = '';
        let defaultRegionCountry = null;
        countries.forEach((country) => {
            if (!regionCountries[country.region]) {
                regions.push(country.region)
                regionCountries[country.region] = [];
            }
            if(country.countryCode === CountryCode){
                IndexName = country.indexName;
                defaultRegionCountry = {
                    region: country.region, 
                    country: country.name,
                    countryIndex: country.indexSymbol
                }
            }
            regionCountries[country.region].push(country);
        })
        if (instSettings[rsLineSettingsConst[item.graphComponent]][CountryCode] === undefined) {
            instSettings[rsLineSettingsConst[item.graphComponent]][CountryCode] = new RelativeStrenghtLineSettings(settings.rsLineNum, true);
        }
        const rsLineSetting = instSettings[rsLineSettingsConst[item.graphComponent]][CountryCode];
        if(defaultRegionCountry)  {            
            rsLineSetting.region = defaultRegionCountry.region;               
            rsLineSetting.country = defaultRegionCountry.country;          
            rsLineSetting.countryIndex = defaultRegionCountry.countryIndex;       
        }
        yield put({
            type: ActionTypes.UPDATE_RSLINE_DIALOG_STATES,
            isRSLineDialogOpen: true,
            regionCountries,
            regions,
            selectedRegion: rsLineSetting.region,
            countries: regionCountries[rsLineSetting.region],
            selectedCountry: rsLineSetting.country,
            selectedRSLine: item.graphComponent,
            IndexSymbol: rsLineSetting.IndexSymbol,
            countryIndex: rsLineSetting.countryIndex,
            lineColor: settings.lineColor,
            lineThickness: settings.lineThickness,
            maLineColor: settings.maLineColor,
            maLineLength: settings.maLineLength,
            maLineThickness: settings.maLineThickness,
            maLineType: settings.maLineType,
            rsLineNum: settings.rsLineNum,
            selectedGroup: rsLineSetting.selectedGroup,
            showGrade: settings.showGrade,
            showMaLine: settings.showMaLine,
            showRank: settings.showRank,
            symbolName: rsLineSetting.symbolName ? rsLineSetting.symbolName : '',
            valueType: rsLineSetting.valueType,
            isSymbolIndex,
            isSymbolGroup,
            isFund,
            CountryCode,
            IndexName
        })
    }
    catch (error) {
        console.error("Error occured in openRSLineEditDialog of RSLineSaga.js", error);
    }
}
function Validate(iSymbol) {
    const regex = /^[A-Za-z0-9\\.\\$\\/\\&]+$/;

    return regex.test(iSymbol);
}

function symbolCheck(serviceSymbol, otherSymbol) {
    if (serviceSymbol === null) {
        return false;
    }
    if (serviceSymbol.toUpperCase() === otherSymbol.toUpperCase().trim()) {
        return true;
    }
    return false;
}

function* saveRSLineEditDialog() {
    try {
        const state = yield select(getRSLineStates)
        if (state.showMALine) {
            if (state.maLineLength) {
                if (state.maLineLength > 200 || state.maLineLength < 0) {
                    yield put(updateRSLength(20))
                    return;
                }
            }
            else {
                yield put(updateRSLength(20));
                return;
            }
        }

        if (state.valueType === 'symbol') {
            let errorMsg = ErrorMessageTranslateHelper.ENTER_VALID_SYMBOL;
            if (state.symbolName.length === 0 || !Validate(state.symbolName)) {
                yield put(updateRSSymbolError(errorMsg))
                return;
            }
            const isLocalSymbolEntitlement = UserInfoUtil.IsUserEntitled(EntitlementType.RAY_LOCALSYMBOLS_Entitlement);
            const count = isLocalSymbolEntitlement ? 20 : 1;
            const symbolResults = yield call(consoleApi.getSymbolSearchRequest, state.symbolName.trim(), count);
            const filteredSymbol = isLocalSymbolEntitlement ? symbolResults.results.filter((item)=>item.Symbol === state.symbolName.toUpperCase()) : symbolResults.results;
            if (!filteredSymbol ||  filteredSymbol.length === 0) {
                yield put(updateRSSymbolError(errorMsg))
                return;
            }
            const symbolInfo = filteredSymbol[0];
            if (symbolInfo.Osid <= 0) {
                yield put(updateRSSymbolError(errorMsg));
                return;
            }
            else if (state.symbolName.toUpperCase() !== symbolInfo.Symbol && (symbolCheck(symbolInfo.localSymbol, state.symbolName) || symbolCheck(symbolInfo.PinyinSymbol, state.symbolName))) {
                errorMsg = ErrorMessageTranslateHelper.ONEIL_SYMBOL_ONLY
                yield put(updateRSSymbolError(errorMsg));
                return;
            }
            else {
                state.indexSymbol = state.symbolName.toUpperCase()
            }
        }
        const instSettings = PriceMenuHelper.getInstSettings();
        const majorPeriodicity = PriceMenuHelper.getMajorPeriodicity();
        const settings = instSettings[rsLineSettingsByPeriodicityConst[state.selectedRSLine]][majorPeriodicity];
        // const allRSLineByPeriodicitySettings = instSettings[rsLineSettingsByPeriodicityConst[state.selectedRSLine]]
        const { pricePanelData } = yield select(priceChartReducerselect);
        const CountryCode = pricePanelData.SymbolInfo.CountryCode;
        if (instSettings[rsLineSettingsConst[state.selectedRSLine]][state.CountryCode] === undefined) {
            instSettings[rsLineSettingsConst[state.selectedRSLine]][state.CountryCode] = new RelativeStrenghtLineSettings(settings.rsLineNum, true);
        }
        const rsLineSetting = instSettings[rsLineSettingsConst[state.selectedRSLine]][state.CountryCode];
        const indexLineSettings = instSettings.IndexLineSettings[state.CountryCode]
        let isCallPricePanelApi = false;
        if(CountryCode !== state.CountryCode ||
            rsLineSetting.valueType !== state.valueType ||
            (state.valueType === "group" && rsLineSetting.selectedGroup !== state.selectedGroup) ||
            (state.valueType === "symbol" && rsLineSetting.IndexSymbol !== state.symbolName)){

                isCallPricePanelApi = true;
        }
        if(state.valueType === "default"){
            // adding condition to check whether symbol is selected for Index line settings
            if (indexLineSettings.valueType === "symbol") {
                rsLineSetting.IndexSymbol =  state.selectedRSLine === GraphComponent.RSLine1 ? indexLineSettings.IndexSymbol : state.countryIndex;
            } else {
                rsLineSetting.IndexSymbol =  state.countryIndex ;
                rsLineSetting.IndexName =  state.IndexName;
            }
        }
        else if(state.valueType === "group"){
            const group = parseInt(state.selectedGroup)
            const selectedGroup = state.groups.find((item)=> group === item.type);
            if(selectedGroup){
                rsLineSetting.IndexSymbol = selectedGroup.symbol;
            }
        }
        else if(state.valueType === "symbol"){
            rsLineSetting.IndexSymbol = state.symbolName.toUpperCase();
        }
        rsLineSetting.country = state.selectedCountry;
        rsLineSetting.countryIndex = state.countryIndex;
        settings.lineColor = state.lineColor.RS;
        settings.lineThickness = state.lineThickness.RS;
        settings.maLineColor = state.lineColor.MA;
        settings.maLineThickness = state.lineThickness.MA;
        settings.maLineLength = state.maLineLength;
        settings.maLineType = state.maLineType;
        rsLineSetting.region = state.selectedRegion;
        settings.rsLineNum = state.rsLineNum;
        rsLineSetting.selectedGroup = state.selectedGroup;
        settings.showGrade = state.showGrade;
        settings.showMaLine = state.showMALine;
        settings.showRank = state.showRank;
        rsLineSetting.symbolName = state.symbolName.toUpperCase();
        rsLineSetting.valueType = state.valueType;

        /** Reverting PANWEB-7221 change because it is affecting PANWEB-7220 */
        // Updating SymbolName in all RSLineByPeriodicity Object for all periodicities (except Intraday)
        // for (let [key, value] of Object.entries(allRSLineByPeriodicitySettings)) {
        //     if(key !== GraphType.Intraday)
        //         value.symbolName = state?.symbolName
        // }
        
        yield put(cancelRSLineDialogSetting());
        SettingStore.saveSettings();
        yield put({
            type: ActionTypes.PREPARE_PRICE_MENU
        });
        if(isCallPricePanelApi){
            // redrawLines when change
            yield put({
                type: DataGraphConstants.ActionTypes.HANDLE_REDRAW_LINE
            });
        }
        else{
            const line1 = state.rsLineNum === 1 ? false : true;
            const line2 = state.rsLineNum === 2 ? false : true;
            yield call(processRSLine, { line1, line2 });
        }
    }
    catch (error) {
        console.error("Error occured in saveRSLineEditDialog of RSLineSaga.js", error);
    }
}
/*****************watchers*****************/
export function* watchInitRSLine() {
    yield takeLatest([ActionTypes.PRICE_DATA_READY,ActionTypes.REDRAW_INDEX_LINE], processRSLine)
}
export function* watchOpenRSLineEditDialog() {
    yield takeLatest(ActionTypes.OPEN_RS_LINE_DIALOG, openRSLineEditDialog)
}
export function* watchSaveRSLineEditDialog() {
    yield takeLatest(ActionTypes.SAVE_RS_DIALOG_SETTING, saveRSLineEditDialog)
}