import LocalizationStore from "../../../../Stores/Localization/LocalizationStore";
import React, { Component } from "react";

export default class CountryListItem extends Component {

    constructor(props) {
        super(props);
        this.handleFilterListClick = this.handleFilterListClick.bind(this);
        this.inlineLiPadding = {};
    }

    handleFilterListClick(e) {
        const target = (e.currentTarget) ? e.currentTarget : e.srcElement;
        this.props.RowClick(e, target, false);
    }

    render() {
        let activeClass = "";
        if (!this.props.existAlerts) {
            this.props.item.itemName === 'All' ? activeClass = 'active' : ''
        }
        else{
            activeClass = (this.props.item.isSelected) ? 'active' : '';
        }

        const countryList = (this.props.item.itemName !== 'All') ? this.inlineLiPadding : null;
        return (
            <li className={` ${activeClass}`}
                style={countryList}
                data-nodefilterid={this.props.item.itemId} data-nodefiltername={this.props.item.itemName}
                onClick={this.handleFilterListClick}>
                <span className="filter-value geograph-inner-region">{this.props.item.itemName === "All" ? LocalizationStore.getTranslatedData("FilterBrowser_Country_-1", this.props.item.itemName) : LocalizationStore.getTranslatedData(`Country_${this.props.item.itemName.replace(/ /ig, "")}`, this.props.item.itemName)}</span>
            </li>
        );
    }
}