import AnnotationUtil from '../../../Utils/AnnotationUtil.js';
import ChartVisual from "./ChartVisual.jsx";
import DatagraphStore from "Stores/NavModules/NavDataGraph/DataGraphStore.js";
import PropTypes from 'prop-types';
import React from "react";
import StringUtil from '../../../Utils/StringUtil';
import TabDataGraphActionClass from "../../../Actions/TabDataGraphAction.js";
import textHeight from "text-height";
import textWidth from "text-width";
import ThemeHelper from "ThemeHelper";
import TimeTrackingWindow from "TimeTrackingWindow";

export default class SinglePointVisual extends ChartVisual {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.RayChartContainer = undefined;
    this.labelPointsArr = [];
    const lineWidth = props.nodeWidth ? props.nodeWidth < 12 ? 1 : ((props.nodeWidth * 4) / 16) : 1;
    this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
    this.onCanvasMouseLeve = this.onCanvasMouseLeve.bind(this);
    this.state = {
      x: 0,
      y: 0,
      screenX: 0,
      screenY: 0,
      showBox: false,
      lineThickness: props.LineThickness ? props.LineThickness : lineWidth,
      lineID: props.lineID ? props.lineID : '',
      dgStore: undefined
    };
    this.clearAllTimeOuts = this.clearAllTimeOuts.bind(this);
    this.getTextWidth = this.getTextWidth.bind(this);
    this.getTextHeight = this.getTextHeight.bind(this);
    this.hitTestForLabel = this.hitTestForLabel.bind(this);
  }

  toolTipElapsed() {
    setTimeout(() => {
      this.state.dgStore = DatagraphStore.getState();
      this.state.dgStore.isToolTip = false;
    }, 1000);
  }

  componentDidMount() {
    if (this.main && !this.eventsAttached) {
      this.RayChartContainer = this.getClosestParent(this.main, "rayChartContainer");
      if (this.props.isPrintMode) {
        return;
      }
      this.RayChartContainer.addEventListener("mousemove", this.onCanvasMouseMove, false);
      this.RayChartContainer.addEventListener("mouseleave", this.onCanvasMouseLeve, false);
      this.eventsAttached = true;
    }
  }

  componentDidUpdate() {
    if (this.main && !this.eventsAttached) {
      this.RayChartContainer = this.getClosestParent(this.main, "rayChartContainer");
      if (this.props.isPrintMode) {
        return;
      }
      this.RayChartContainer.addEventListener("mousemove", this.onCanvasMouseMove, false);
      this.RayChartContainer.addEventListener("mouseleave", this.onCanvasMouseLeve, false);
      this.eventsAttached = true;
    }
  }

  componentWillUnmount() {
    if (this.RayChartContainer) {
      if (this.props.isPrintMode) {
        return;
      }
      this.RayChartContainer.removeEventListener("mousemove", this.onCanvasMouseMove, false);
      this.RayChartContainer.removeEventListener("mouseleave", this.onCanvasMouseLeve, false);
      this.clearAllTimeOuts();
    }
  }

  clearAllTimeOuts() {
    this.timeout && clearTimeout(this.timeout);
    this.timeoutMouseLeave && clearTimeout(this.timeoutMouseLeave);
    this.timeoutCanvasMouseLeve && clearTimeout(this.timeoutCanvasMouseLeve);
    this.mouseMoveTimeout && clearTimeout(this.mouseMoveTimeout);
  }
  getTextWidth(text) {
    const width = textWidth(text, {
      family: "calibri",
      size: 8
    });
    return width;
  }

  getTextHeight(text) {
    const height = textHeight(text, {
      family: "calibri",
      size: 8
    });
    return height.height;
  }

  hitTestForLabel(x, y) {
    let isHit = false;
    for (let i = 0; i < this.labelPointsArr.length; i++) {
      if (x >= this.labelPointsArr[i].x - ((this.labelPointsArr[i].width + 8) / 2) && x <= this.labelPointsArr[i].x + (this.labelPointsArr[i].width + 8) / 2
        && y >= this.labelPointsArr[i].y - ((this.labelPointsArr[i].height + 10) / 2) && y <= this.labelPointsArr[i].y + ((this.labelPointsArr[i].height) / 2)) {
        isHit = true;
        return isHit;
      }
    }

    return isHit;
  }

  onCanvasMouseLeve() {
    if (this.main && this.RayChartContainer) {
      this.timeoutCanvasMouseLeve = setTimeout(() => {
        this.toolTipElapsed();
        this.setState({ showBox: false, screenX: -1, screenY: -1 });
      }, 0);
    }
  }
  onXMouseMove(e) {
    this.mouseMoveTimeout && clearTimeout(this.mouseMoveTimeout);
    this.mouseMoveTimeout = setTimeout(() => {
      // let isToolTip = this.props.isToolTip;
      if (!this.main) { return; }
      const dimensions = this.main.getBoundingClientRect();
      let xAxis = parseInt(e.clientX - dimensions.left);
      let yAxis = parseInt(e.clientY - dimensions.top);
      const isHit = this.hitTest(xAxis, yAxis);
      if (isHit && this.state.lineID && this.state.lineID.length > 0) {
        TabDataGraphActionClass.onLineVisualSelected();
        const len = this.state.lineID.length * 6;
        xAxis = xAxis - len;
        if (xAxis < len) { xAxis = xAxis + len; }
        if (dimensions.bottom - 40 < yAxis) { yAxis = yAxis - 50; }
        if (yAxis < 40) { yAxis = yAxis + 50; }
        this.state.dgStore = DatagraphStore.getState();
        if (!this.props.isAnyDrag && !this.state.dgStore.isToolTip) {
          this.state.dgStore.isToolTip = true;
          this.setState({
            showBox: true,
            screenX: xAxis,
            screenY: yAxis
          });
        }
      }
      else {
        this.toolTipElapsed();
        this.setState({
          showBox: false
        });
      }
    }, 0);
  }
  handleMouseLeave() {
    this.timeoutMouseLeave = setTimeout(() => {
      this.toolTipElapsed();
      this.setState({ showBox: false });
    }, 0);
  }

  prepareLine(source) {
    const self = this;
    if (!this.mainCanvas) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => self.setState({}), 0);
      return;
    }

    const rect = this.mainCanvas.getBoundingClientRect();
    const ctx = this.mainCanvas.getContext("2d");
    ctx.id = "RSLayer";
    ctx.canvas.height = rect.height;
    ctx.canvas.width = rect.width;
    //  ctx.textAlign = "right";
    ctx.imageSmoothingEnabled = true;
    ctx.textAlign = "center";
    ctx.fillStyle = ThemeHelper.getThemedBrush("scaleText");
    ctx.fill('evenodd');
    ctx.font = "8pt Calibri";
    ctx.clearRect(0, 0, rect.width, rect.height);
    if (!source || source.length < 1) {
      return;
    }
    ctx.strokeStyle = this.props.LineColor;
    ctx.lineWidth = this.state.lineThickness;
    if (this.state.lineThickness === 1) {
      ctx.translate(0.5, 0.5);
    }
    ctx.setLineDash([0, 0]);

    ctx.beginPath();
    const f = source[0];
    if (f === undefined) { return; }
    if (source.length === 1) {
      ctx.moveTo(f.xAxis + 1.5, f.yPrice);
      ctx.lineTo(f.xAxis - 1.5, f.yPrice);
    }
    ctx.stroke();
    ctx.closePath();

  }

  static getDerivedStateFromProps(props) {
    if (props.DataSource) {
      const lineWidth = props.nodeWidth ? props.nodeWidth < 12 ? 1 : ((props.nodeWidth * 4) / 16) : 1;
      return {
        lineID: props.lineID || '', 
        lineThickness: props.LineThickness && lineWidth < 2 ? props.LineThickness : lineWidth
      }
    }
  }
  showInfoBox(showText, screenX, screenY, backgroundColor) {
    if (this.main === undefined) {
      return (<div />);
    }
    const dimensions = this.main.getBoundingClientRect();
    const top = screenY > dimensions.height - 40 ? (screenY - 20) : `${screenY + 20}px`;
    const color = ThemeHelper.getFontColor(backgroundColor);
    const textStyle = {
      backgroundColor,
      borderColor: "#333333",
      borderStyle: "solid",
      borderWidth: "1px",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      borderBottomLeftRadius: "4px",
      fontFamily: "calibri",
      color,
      position: "absolute",
      left: `${screenX}px`,
      top,
      height: "20px",
      width: "auto",
      zIndex: 100,
      whiteSpace: 'nowrap'
    };
    return (
      <div style={textStyle} key={screenX} >
        <div style={{
          textAlign: "Center",
          fontFamily: "calibri",
          color,
          marginLeft: '6px',
          marginRight: '6px'
        }}>
          {showText}
        </div>
      </div>
    );
  }
  onCanvasMouseMove(e) {
    this.mouseMoveTimeout && clearTimeout(this.mouseMoveTimeout);
    if (AnnotationUtil.isAnnotationsTabActive() && !AnnotationUtil.isPointerSelected) { return; }
    this.mouseMoveTimeout = setTimeout(() => {
      if (this.main) {
        // let isToolTip = this.props.isToolTip;
        const dimensions = this.main.getBoundingClientRect();
        let xAxis = parseInt(e.clientX - dimensions.left);
        let yAxis = parseInt(e.clientY - dimensions.top);
        const isHit = this.hitTest(xAxis, yAxis);
        this.state.dgStore = DatagraphStore.getState();
        if (isHit > 0 && !this.state.dgStore.isToolTip) {
          if (isHit === 1 && this.state.lineID && this.state.lineID.length > 0) {
            const len = this.state.lineID.length * 6;
            xAxis = xAxis - len;
            if (xAxis < len) { xAxis = xAxis + len; }
            if (dimensions.bottom - 40 < yAxis) { yAxis = yAxis - 50; }
            if (yAxis < 40) { yAxis = yAxis + 50; }
            if (!this.props.isAnyDrag) {
              this.state.dgStore.isToolTip = true;
              this.setState({
                showBox: true,
                screenX: xAxis,
                screenY: yAxis,
              });
            }
          }
          TabDataGraphActionClass.onLineVisualSelected();
        }
        else {
          this.toolTipElapsed();
          this.setState({ showBox: false });
        }
      }
    }, 0);
  }

  hitTest(x, y) {
    //Fix for PANWEB - 1068 - Check if any modal open
    const openModals = document.getElementsByClassName('modal-open');
    if (openModals.length >= 1) {
      return 0;
    }
    if (this.isPointInStroke(x, y)) {
      return 1;
    }
  }

  isPointInStroke(x, y) {
    const prcLength = this.props.DataSource.length;
    for (let j = 0; j < prcLength; j++) {
      const linePoint = this.props.DataSource[j];
      if (linePoint &&
        (linePoint.xAxis >= x - 3 && linePoint.xAxis <= x + 3) &&
        (linePoint.yPrice >= y - 3 && linePoint.yPrice <= y + 3)) {
        return true;
      }
    }
    return false;
  }
  onContextMenuClick(e) {
    e.preventDefault();
    return false;
  }

  getSVGLine(lineStyle) {
    const f = this.props.DataSource[0]
    const pathData = `M ${f.xAxis + 1.5} ${f.yPrice.toFixed(0)} L ${f.xAxis - 1.5} ${f.yPrice.toFixed(0)}`;

    return (<svg style={lineStyle}
      id="RSLayer"
      height='100%'
      width='100%'>
      {!StringUtil.isEmpty(pathData) && <path d={pathData} data-disable-track-price="true"
        strokeWidth={this.state.lineThickness}
        stroke={this.props.LineColor}
        strokeDasharray={0}
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseOver={this.handleMouseOver.bind(this)}
        onFocus={this.handleMouseOver.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        onClick={this.handleOnClick.bind(this)}
        fill="none"
        pointerEvents="none">
          {this.state.lineID !== '' &&
          <title>{this.state.lineID}</title>
        }
      </path>}
    </svg>);
  }

  render() {
    TimeTrackingWindow.trackChartLoadingTime();
    if (!this.props.DataSource || !this.props.DataSource[0] || this.props.DataSource.length <= 0) {
      return (<div></div>);
    }
    const lineStyle = {
      fitPosition: "fill",
      position: "absolute",
      left: "0px",
      top: "0px",
      zIndex: 0,
      pointerEvents: "none",
    }
    this.state.dgStore = DatagraphStore.getState();
    DatagraphStore.setTooltipState(false);
    return (
      <div ref={(ref) => (this.main = ref)} >
        {this.props.useCanvas ? <canvas onContextMenu={this.onContextMenuClick.bind(this)}
          className="chartVisual"
          id="RSLine"
          ref={(ref) => (this.mainCanvas = ref)}
          style={{
            fitPosition: "fill",
            width: "100%",
            height: "100%",
            position: "absolute",
            pointerEvents: "none"
          }}>
          {this.prepareLine(this.props.DataSource)}
        </canvas>:
        this.getSVGLine(lineStyle)}
        {(this.state.showBox && this.state.dgStore.isToolTip) &&
          this.showInfoBox(this.state.lineID, this.state.screenX, this.state.screenY, this.props.LineColor)
        }
      </div>
    );
  }
}
SinglePointVisual.propTypes = {
  LineThickness: PropTypes.number.isRequired,
  showBox: PropTypes.bool,
  lineID: PropTypes.string,
  isAnyDrag: PropTypes.bool,
  isToolTip: PropTypes.bool,
};