import GraphTypeEnum from 'GraphType';
import LocalizationStore from "LocalizationStore";
class IntradayTimeLine {
    AddGutterSpace(xDate, periodicity) {
        const gutterSpace = 4;
        let cDate = new Date(xDate.getTime());

        switch (periodicity) {
            case GraphTypeEnum.Intraday1Min:
                cDate = new Date(cDate.getTime() + gutterSpace * 60000);
                break;

            case GraphTypeEnum.Intraday5Min:
                cDate = new Date(cDate.getTime() + gutterSpace * 60000 * 5);
                break;

            case GraphTypeEnum.Intraday10Min:
                cDate = new Date(cDate.getTime() + gutterSpace * 60000 * 10);
                break;

            case GraphTypeEnum.Intraday15Min:
                cDate = new Date(cDate.getTime() + gutterSpace * 60000 * 15);
                break;

            case GraphTypeEnum.Intraday30Min:
                cDate = new Date(cDate.getTime() + gutterSpace * 60000 * 30);
                break;

            case GraphTypeEnum.Intraday60Min:
                cDate = new Date(cDate.getTime() + gutterSpace * 60000 * 60);
                break;

            default:
                break;
        }

        return cDate;
    }
    GetNextDate(periodicity, firstDate) {
        switch (periodicity) {
            case GraphTypeEnum.Intraday5Min:
                if (firstDate.getMinutes() % 5 !== 0)
                    firstDate = new Date(firstDate.getTime() + (5 - firstDate.getMinutes() % 5) * 60000);
                break;

            case GraphTypeEnum.Intraday10Min:
                if (firstDate.getMinutes() % 10 !== 0)
                    firstDate = new Date(firstDate.getTime() + (10 - firstDate.getMinutes() % 10) * 60000);
                break;

            case GraphTypeEnum.Intraday15Min:
                if (firstDate.getMinutes() % 15 !== 0)
                    firstDate = new Date(firstDate.getTime() + (15 - firstDate.getMinutes() % 15) * 60000);
                break;

            case GraphTypeEnum.Intraday30Min:
                if (firstDate.getMinutes() % 30 !== 0)
                    firstDate = new Date(firstDate.getTime() + (30 - firstDate.getMinutes() % 30) * 60000);
                break;

            case GraphTypeEnum.Intraday60Min:
                if (firstDate.getMinutes() % 60 !== 0)
                    firstDate = new Date(firstDate.getTime() + (60 - firstDate.getMinutes() % 60) * 60000);
                break;
            default:
                break;
        }
        return firstDate;
    }
    Padding(cDate, openTime, closeTime, periodicity) {
        const hours = openTime.getHours();
        const minutes = openTime.getMinutes();

        if (closeTime.getHours() <= cDate.getHours() && closeTime.getMinutes() <= cDate.getMinutes()) {
            // after market close.
            cDate.setDate(cDate.getDate() + 1);
            if (cDate.getDay() === 6)
                cDate.setDate(cDate.getDate() + 2);
            if (cDate.getDay() === 0)
                cDate.setDate(cDate.getDate() + 1);
            cDate = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), hours, minutes, 0, 0);
        }
        cDate = this.GetNextDate(periodicity, cDate);
        cDate = this.AddGutterSpace(cDate, periodicity);
        if (closeTime.getHours() < cDate.getHours() || hours > cDate.getHours()) {
            cDate.setDate(cDate.getDate() + 1);
            if (cDate.getDay() === 6)
                cDate.setDate(cDate.getDate() + 2);
            if (cDate.getDay() === 0)
                cDate.setDate(cDate.getDate() + 1);
            cDate = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), hours, minutes, 0, 0);
        }
        return cDate;
    }
    SetVerticalGrid(startTime, endDate, timeLineData, openTime, closeTime, graphType) {
        let mtDates = timeLineData.dates;
        if (endDate.Year < 1800)
            return;

        var monthNames = ["   ",
            "Jan", "Feb", "Mar",
            "Apr", "May", "Jun", "Jul",
            "Aug", "Sep", "Oct",
            "Nov", "Dec"
        ];

        var startDate = startTime;
        var padDate = new Date(endDate.getTime());
        //var pDate = new Date(this.Padding(padDate, endDate, openTime, closeTime, graphType));
        var pDate = new Date(this.Padding(padDate, openTime, closeTime, graphType));
        var cDate = new Date(pDate.getTime());
        var x = 0;
        var addTimeLabel = false;
        var oMinute = openTime.getMinutes();
        var oHours = openTime.getHours();
        var cMinute = closeTime.getMinutes();
        var cHours = closeTime.getHours();
        var cTime = closeTime.getHours() * 100 + cMinute;
        var oTime = openTime.getHours() * 100 + oMinute;
        //switch (graphType) {
        //    case GraphTypeEnum.Intraday10Min:
        //        if (oMinute < 20 && oMinute > 0)
        //            oTime = openTime.getHours() * 100 + 20;
        //        break;
        //    case GraphTypeEnum.Intraday30Min:
        //        if (oMinute < 30 && oMinute > 0)
        //            oTime = openTime.getHours() * 100 + 30;
        //        break;
        //    default:
        //        break;
        //}

        while (cDate >= startDate) {
            var xDate = new Date(cDate.getTime());

            var info =
            {
                Date: xDate,
                XAxis: x,
                XLine: 0,
                XLabel: "",
                IsMonthLabel: false
            };

            mtDates[x] = info;

            var crTime = cDate.getHours() * 100 + cDate.getMinutes();
            let addHours = oMinute == 0 ? 0 : 1;

            switch (graphType) {
                case GraphTypeEnum.Intraday1Min:
                    addTimeLabel = (cDate.getMinutes() % 15 === 0 && crTime < cTime);
                    break;

                case GraphTypeEnum.Intraday5Min:
                    addTimeLabel = (cDate.getMinutes() === 0 && cDate.getHours() > (oHours + addHours) && crTime < cTime);
                    break;

                case GraphTypeEnum.Intraday10Min:
                    addTimeLabel = (cDate.getMinutes() === 0 && cDate.getHours() % 2 === 0 && cDate.getHours() > (oHours + addHours) && crTime < cTime);
                    if (cDate.getHours() === cHours && cMinute > 0 || cDate.getHours() - oHours < 2) {
                        addTimeLabel = false;
                    }
                    break;

                case GraphTypeEnum.Intraday15Min:
                    addTimeLabel = (cDate.getMinutes() === 0 && cDate.getHours() % 2 === 0 && cDate.getHours() > (oHours + addHours) && crTime < cTime);
                    if (cDate.getHours() === cHours && cMinute > 0 || cHours - cDate.getHours() < 2 || cDate.getHours() - oHours <= 2) {
                        addTimeLabel = false;
                    }
                    break;

                default:
                    break;
            }

            let okToGo = oMinute > 0 ? crTime === (oHours + 1) * 100 : crTime === oTime;
            if (crTime <= oTime ||
                (graphType === GraphTypeEnum.Intraday60Min && okToGo)) {
                if (graphType === GraphTypeEnum.Intraday60Min) {
                    if (cDate.getMonth() !== pDate.getMonth()) {
                        // var nextDay = new Date(cDate.getTime());
                        // nextDay.setDate(nextDay.getDate() + 1);
                        // if (nextDay.getDay() == 6)
                        //     nextDay.setDate(nextDay.getDate() + 2);
                        // if (nextDay.getDay() == 0)
                        //     nextDay.setDate(nextDay.getDate() + 1);
                        // var info =
                        //     {
                        //         Date: nextDay,
                        //         XAxis: x + dif,
                        //         XLine: 0,
                        //         XLabel: "",
                        //         IsMonthLabel: false
                        //     };

                        // mtDates[x] = info;
                        for (var c = x; c > 0; c--) {
                            if (mtDates[c].IsCalendarLabel != undefined) {
                                mtDates[c].XLabel = monthNames[pDate.getMonth() + 1];
                                mtDates[c].XLine = 2;
                                break;
                            }
                        }
                        pDate = cDate;
                    }

                    mtDates[x].XLabel = cDate.getDate();
                    mtDates[x].IsCalendarLabel = true;
                    mtDates[x].XLine = 1;
                    mtDates[x].DarkLabel = true;
                    mtDates[x].IsCalendarLine = true;
                    if (mtDates[x].XLabel !== "") {
                        timeLineData.TimeLineLabel.push(mtDates[x])
                    }

                }
                else {
                    mtDates[x].XLabel = LocalizationStore.getTranslatedData("cal_" + monthNames[cDate.getMonth() + 1], monthNames[cDate.getMonth() + 1]) + " " + LocalizationStore.getTranslatedData("sb_day", "{0}", cDate.getDate());// cDate.ToString("MMM dd").ToUpper();
                    mtDates[x].IsCalendarLabel = true;
                    mtDates[x].IsCalendarLine = true;
                    if (graphType == GraphTypeEnum.Intraday30Min) {
                        mtDates[x].XLine = 1;
                    }
                    else {
                        mtDates[x].XLine = 2;
                    }
                    if (mtDates[x].XLabel !== "") {
                        timeLineData.TimeLineLabel.push(mtDates[x])
                    }
                }

                x++;
                cDate.setDate(cDate.getDate() - 1);
                if (cDate.getDay() === 6)
                    cDate.setDate(cDate.getDate() - 1);
                if (cDate.getDay() === 0)
                    cDate.setDate(cDate.getDate() - 2);

                cDate = new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), closeTime.getHours(), closeTime.getMinutes(), 0);
                continue;
            }
            else {
                if (addTimeLabel) {
                    var hours = cDate.getHours() > 12 ? cDate.getHours() - 12 : cDate.getHours();
                    let minutes = cDate.getMinutes();
                    if (minutes < 10) minutes = "0" + minutes;
                    if (graphType === GraphTypeEnum.Intraday15Min)
                        mtDates[x].XLabel = hours;
                    else
                        mtDates[x].XLabel = hours + ":" + minutes;
                    mtDates[x].IsCalendarLabel = true;
                    mtDates[x].XLine = 1;
                    mtDates[x].DarkLabel = true;
                    mtDates[x].IsCalendarLine = true;
                    if (mtDates[x].XLabel !== "") {
                        timeLineData.TimeLineLabel.push(mtDates[x]);
                    }
                }
            }

            addTimeLabel = false;

            x++;
            switch (graphType) {
                case GraphTypeEnum.Intraday1Min:
                    cDate = new Date(cDate.getTime() - 1 * 60000);
                    break;

                case GraphTypeEnum.Intraday5Min:
                    cDate = new Date(cDate.getTime() - (cDate.getMinutes() % 5 === 0 ? 5 : cDate.getMinutes() % 5) * 60000);
                    break;

                case GraphTypeEnum.Intraday10Min:
                    cDate = new Date(cDate.getTime() - (cDate.getMinutes() % 10 === 0 ? 10 : cDate.getMinutes() % 10) * 60000);
                    break;

                case GraphTypeEnum.Intraday15Min:
                    cDate = new Date(cDate.getTime() - (cDate.getMinutes() % 15 === 0 ? 15 : cDate.getMinutes() % 15) * 60000);
                    break;

                case GraphTypeEnum.Intraday30Min:
                    cDate = new Date(cDate.getTime() - (cDate.getMinutes() % 30 === 0 ? 30 : cDate.getMinutes() % 30) * 60000);
                    break;

                case GraphTypeEnum.Intraday60Min:
                    cDate = new Date(cDate.getTime() - (cDate.getMinutes() === 0 ? 60 : cDate.getMinutes() % 60) * 60000);
                    break;

                default:
                    break;
            }
        }
        //        return timeLineData;
    }
}
const intradayTimeLine = new IntradayTimeLine();
export default intradayTimeLine;
