import React from "react";
import { FormGroup, ControlLabel } from "react-bootstrap";
import FundamentalTabTemplateType from 'Constants/FundamentalTabTemplateType.js';
import LocalizationStore from 'Stores/Localization/LocalizationStore.js';

let fundamentalTemplate = undefined;

export default class FundamentalsFilter extends React.Component {
    constructor() {
        super();
    }

    getTemplateText(name) {
        if (!name) return name;
        return LocalizationStore.getTranslatedData('fr_' + name.replace(/ /g, ''), name);
    }

    render() {
        fundamentalTemplate = this.props.settings.FundamentalTemplate;
        let fundamentalFilter = null;
        let standardTemplate = this.props.optionsData.templateName ? "Standard" : "";
        if (this.props.optionsData.templateName) {
            fundamentalFilter = (
                <React.Fragment>
                    <span>{LocalizationStore.getTranslatedData("fr_Template", "Template:")}</span>
                    <span>
                        <input id="radio_1" type="radio" name="radio-button" onChange={this.props.handleTemplateChange} data-report-templateid="2" checked={fundamentalTemplate === FundamentalTabTemplateType.Standard} />
                        <label htmlFor="radio_1">{this.getTemplateText(standardTemplate)}</label>
                    </span>
                    <span>
                        <input id="radio_2" type="radio" name="radio-button" onChange={this.props.handleTemplateChange} data-report-templateid="1" checked={fundamentalTemplate === FundamentalTabTemplateType.Industrial} />
                        <label htmlFor="radio_2">{this.getTemplateText(this.props.optionsData.templateName)}</label>
                    </span>
                </React.Fragment>
            );
        }
        return (<FormGroup>
            {fundamentalFilter}
        </FormGroup>
        );
    }
}