import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GroupsBenchMarkDataRequest extends BaseServiceRequest {

  constructor(code, date) {
    super();
    super.addRequestUri("benchmarkdata2.pb");
    super.addRequestParameter("Code", code);
    super.addRequestParameter("Date", date);
    super.addRequestParameter("inctrend", 1);
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.GROUPS;
  }

  getMessageName() {
    return "BenchMarkDataResponse";
  }
}

export default GroupsBenchMarkDataRequest;
