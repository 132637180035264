import GraphType from "../../../../../Constants/GraphType.js";

class FundamentalTabPeriodicitySetting {

  constructor(periodicity) {
    this.Header = 16;
    this.Periodicity = GraphType.FundamentalsAnnual;
    this.IsSelected = false;

    switch (periodicity) {
      case GraphType.FundamentalsAnnual:
        this.Header = "FundamentalsAnnual";
        this.Periodicity = GraphType.FundamentalsAnnual;
        this.IsSelected = false;
        this.IsEnabled = false;
        break;
      case GraphType.FundamentalsSemiAnnual:
        this.Header = "FundamentalsSemiAnnual";
        this.Periodicity = GraphType.FundamentalsSemiAnnual;
        this.IsSelected = false;
        this.IsEnabled = false;
        break;
      case GraphType.FundamentalsQuarterly:
        this.Header = "FundamentalsQuarterly";
        this.Periodicity = GraphType.FundamentalsQuarterly;
        this.IsSelected = false;
        this.IsEnabled = false;
        break;

      default:
        this.IsAvailable = false;

    }
  }
}

export default FundamentalTabPeriodicitySetting;
