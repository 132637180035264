import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import HelpStore from "../../Stores/Help/HelpStore";
import MenuDialogType from "../../Constants/MenuDialogType.js";
import UserInfoUtil from "UserInfoUtil";
import BaseServiceApi  from "BaseServiceApi";
import LocalizationStore from 'LocalizationStore';
import QuickRefWindow from './QuickRefWindow';
import ScrollBar from "ScrollBar";
import HelpWindowConstants from "../../Constants/HelpWindowConstants.js";
import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions.js";
const entitlementType = BaseServiceApi.rayData["EntitlementType"];
export default class HelpDialog extends React.Component {
    constructor(props) {
        super(props);
        this.closeHelpDialog = this.closeHelpDialog.bind(this);
        this.helpDialogStateChange = this.helpDialogStateChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCloseWindow = this.handleCloseWindow.bind(this);
        this.openWhatsNewDialog = this.openWhatsNewDialog.bind(this);
        this.state = {
            isOpen: false,
            menuDialogType: '',
            isChangPassDialogOpen: false,
            isQuickRefDisplay: false
        }
    }

    componentDidMount() {
        HelpStore.addChangeListener(this.helpDialogStateChange);
    }

    componentWillUnmount() {
        HelpStore.removeChangeListener(this.helpDialogStateChange);
    }

    handleClick() {
        this.setState({isQuickRefDisplay: true});
    }

    handleCloseWindow() {
        this.setState({isQuickRefDisplay: false});
    }

    helpDialogStateChange() {
        let helpDialogState = HelpStore.getState();
        switch (helpDialogState.eventKey) {
            case MenuDialogType.Help:
                let st = {
                    isOpen: helpDialogState.isOpen,
                    menuDialogType: helpDialogState.eventKey,
                    isChangPassDialogOpen: false,
                    isQuickRefDisplay: false
                };
                this.setState(st);
                break;
            default: 
                break;
        }

    }

    closeHelpDialog() {
        this.setState({ isOpen: false, isQuickRefDisplay: false });
    }


    getHelpDetails() {
        return (
                    <div >

                    </div>
        );
    }

    renderContact(){
        if(UserInfoUtil.IsUserEntitled(entitlementType.RAY_CHINA_Entitlement)){
            return (
                <div className="help-contact">
                    <article><strong>{LocalizationStore.getTranslatedData("set_hp_ss_con", "Contact William O'Neil Client Services 24 Hours a day, 7 days a week.")}</strong></article>
                    <article><strong>{LocalizationStore.getTranslatedData("set_acc_cs", "Client Services: ")}</strong><ul><li><a href="mailto:cs@williamoneilchina.com">cs@williamoneilchina.com</a></li></ul></article>
                    {/* <article><strong>QQ：</strong><span>2885263221</span></article> */}
                    <article><strong>{LocalizationStore.getTranslatedData("set_acc_int", "International: ")}</strong><span>+1 (310) 448-6199</span></article>
                </div>
            );
        }
        return (
            <div className="help-contact">
                <article><strong>{LocalizationStore.getTranslatedData("set_hp_ss_con", "Contact William O'Neil Client Services 24 Hours a day, 7 days a week.")}</strong></article>
                <article><strong>{LocalizationStore.getTranslatedData("set_acc_cs", "Client Services: ")}</strong><ul><li><a href="mailto:support@williamoneil.com">support@williamoneil.com</a></li></ul></article>
                <article><strong>US: </strong><span> (800) 419-6632</span></article>
                <article><strong>UK: </strong><span> 0 (800) 969-581</span></article>
                <article><strong>{LocalizationStore.getTranslatedData("set_acc_int", "International: ")}</strong><span>(310) 448-6199</span></article>
            </div>
        );
    }

    openWhatsNewDialog(){
        MenuCommonDialogActions.openWhatsNewDialog();
    }

    render() {
      let dt = new Date();
        return (
            <div id="helpDialog">
                <Modal className="modal-popup helpDialog" show={this.state.isOpen} onHide={this.closeHelpDialog}>
                    <Modal.Header>
                        <Modal.Title>
                            <span className="cap-header">{LocalizationStore.getTranslatedData("set_hp", "Help")}</span>
                            <button type="button" className="btn btn-xxs btn-default btn-secondary btn-close" data-effect="fadeOut" onClick={this.closeHelpDialog}><span className="icn-close"></span></button>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div  className="custom-scroll-light help-dialog-stuff">
                        <div className="custom-scroll">
                            <div className="help-dialog-holder" id='helpDialogScroll'>
                                <div className="modal-material">
                                    <div className="box-help">
                                        <a className="help-logo"><img src="/Asset/images/Icon_HelpMenu_Logo.png"/></a>
                                        {/*<div className="version-info">
                                            <span className="large-normal info-version">{LocalizationStore.getTranslatedData("_Version", "")}</span>
                                            <a className="small-normal help-outside">What's New</a>
                                        </div>*/}
                                        <a className="small-normal help-outside" onClick={this.openWhatsNewDialog}>What's New</a>
                                    </div>

                                    <article className="help-details">{LocalizationStore.getTranslatedData('set_hp_cp', "Copyright © {0} William O'Neil + Co. Incorporated", dt.getFullYear())}<br/><span>{LocalizationStore.getTranslatedData("set_hp_arr", "All Rights Reserved")}</span> </article>
                                    <article className="help-details "><strong>{LocalizationStore.getTranslatedData("set_hp_warn", "Warning")}</strong> {LocalizationStore.getTranslatedData("set_hp_warn_1", "This computer program and the data contained herein is ")}{LocalizationStore.getTranslatedData("set_hp_warn_2", "protected by copyright law and international treaties. Unauthorized reproduction or distribution of this program, data or any portion thereof, may result in severe criminal penalties, and will be prosecuted to the maximum extent possible under the law.")}</article>

                                    {/*<div className="overflow-h x-small-padding-top">
                                        <button type="button" className=" model-action btn btn-xs btn-default btn-secondary pull-left small-bold">System Details </button>
                                        <button type="button" className=" model-action btn btn-xs btn-default btn-secondary pull-right small-bold">Terms & Conditions</button>
                                    </div>*/}
                                </div>
                                <div className="modal-material">
                                    <div className="service-support">
                                        <h5>{LocalizationStore.getTranslatedData("set_hp_ss", "Service & Support")}</h5>
                                        {this.renderContact()}
                                    </div>
                                </div>
                                <div className="modal-material">
                                    <div className="service-support">
                                        <h5>{LocalizationStore.getTranslatedData("set_hp_qr","Quick Reference and Knowledge Center")}</h5>
                                        <div className="help-contact">
                                            <div className="help-display">
                                                <button type="button" className=" model-action btn btn-xs btn-default btn-secondary pull-right small-bold" onClick={this.handleClick}>{LocalizationStore.getTranslatedData("set_hp_qr_d","Display")}</button>
                                            </div>
                                            {this.state.isQuickRefDisplay && <QuickRefWindow helpType={HelpWindowConstants.Quickreference} onCloseWindow={this.handleCloseWindow} ><span></span></QuickRefWindow>}
                                            <div className="quick-reference">
                                                <article><strong>{LocalizationStore.getTranslatedData("set_hp_qr_lrn","Learn about PANARAY® features so you can get")} {LocalizationStore.getTranslatedData("set_hp_qr_lrn_LanguageEmpty","started quickly.")}</strong></article>
                                                <article><u><a href={LocalizationStore.getTranslatedData("set_hp_qr_kc_url","https://www.williamoneil.com/panaray/knowledge-center/")} target="_blank">{LocalizationStore.getTranslatedData("set_hp_qr_kc","Knowledge Center")}</a></u></article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId="helpDialogScroll" vScroll={true} />
                        </div> 
                        {/*<div className="inner-block  help-inner-block ">
                            <div className="service-support">
                                <div className="help-title x-large-bold">Help & Warning Message</div>
                                <div className="contact">
                                    <article className="small-bold">Restore help and confirmaction messages you previously chose to hide</article>

                                     <div className="overflow-h x-small-padding-top">
                                         <button type="button" className=" model-action btn btn-xs btn-default btn-secondary pull-right small-bold">Reset</button>
                                         </div>
                                </div>
                            </div>
                        </div>*/}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
