import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import BaseServiceApi from "BaseServiceApi";

import DateHelper from "../../Utils/TimeLineHelper/Datehelper.js"

export default class GetDatagraphRequest extends BaseServiceRequest {
    constructor(findGetDatagraphRequest,Osid,IsNASDAQBasic,isHistoric,protoReq=[]) {
        super();
        super.addRequestUri("getdatagraph.pb");
        const ReqProto = BaseServiceApi.rayData["GetGraphDataRequest"];
        const createDataGraphReq = new ReqProto();
        createDataGraphReq.symbol = findGetDatagraphRequest.Symbol;
        createDataGraphReq.Periodicity = findGetDatagraphRequest.Periodicity;
        createDataGraphReq.IsHistoric = isHistoric;
        createDataGraphReq.IsNASDAQBasic = IsNASDAQBasic;
        createDataGraphReq.StartDateString = DateHelper.getDateString(findGetDatagraphRequest.beginDate) + " " + DateHelper.getTimeString(findGetDatagraphRequest.beginDate);
        createDataGraphReq.EndDateString = DateHelper.getDateString(findGetDatagraphRequest.endDate) + " " + DateHelper.getTimeString(findGetDatagraphRequest.endDate);
        createDataGraphReq.osid = Osid;
        createDataGraphReq.FindGetDataGraphRequestProto = protoReq.encode64();
        const reqcontent = createDataGraphReq.encode();
        super.setContent(reqcontent);
    }

    getMethod() {
        return HttpMethodType.POST;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "GetGraphDataResponse";
    }
}
