import React, { Component } from 'react'
import { Modal } from 'react-bootstrap';


export default class addMobileErrorDialog extends Component {
    constructor(props) {
        super(props);
        this.hideDialog = this.hideDialog.bind(this);
    }

    hideDialog() {
        this.props.hideAddMobileErrorDialog();
    }

    render() {
        return (
            <div id="addMobileErrorDialog">
                <Modal className="modal-popup add-mobile-error-dialog" show={this.props.open}>
                    <Modal.Header>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modal-region">
                            <p>Phone number not subscribed, please try again after few minutes.</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn-box center">
                            <button type="button" className="btn btn-xs btn-default btn-secondary" data-effect="fadeOut" onClick={this.hideDialog}>OK</button>
                        </span>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}