import React from "react";
import { Dropdown, MenuItem } from "react-bootstrap";
import MenuDialogType from "../../Constants/MenuDialogType.js";
import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions.js";
import LocalizationStore from 'LocalizationStore';
import { ShareActionConstants } from '../../Constants/ShareConstants';
import { UpdateDialogType } from "../../Actions/ShareAction";

const history = require("history").createHashHistory();
export default class ThemeSetting extends React.Component {

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate() {
    return false;
  }

  menuItemSelect(key) {
    let eventKey = key;
    switch (eventKey) {
      case MenuDialogType.Account:
        MenuCommonDialogActions.openAccountDialog({ eventKey: eventKey });
        break;
      case MenuDialogType.Help:
        MenuCommonDialogActions.openHelpDialog({ eventKey: eventKey });
        break;
      case MenuDialogType.Logout:
        history.replace("/Logout");
        break;
      case MenuDialogType.Sharing:
        UpdateDialogType('managed', "6", ShareActionConstants.SHARE_EXTERNAL_DATA);
        break;
      default:
        MenuCommonDialogActions.openMenuCommonDialog({ eventKey: eventKey });
        break;
    }
  }

  render() {
    return (
      <div className="global-menu">
        <Dropdown className="global-up-down" id="dropdown-custom-theme" dropup>
          <Dropdown.Toggle>
            <div className="logoWrap"><a className="logo-sap" ref={(ref) => this.MainChart = ref}><span className="panaray" id="ftr-logo"></span></a></div>
          </Dropdown.Toggle>
          {/*<Dropdown.Menu>
                        <MenuItem eventKey="1">New Console</MenuItem>
                        <MenuItem eventKey="2">Duplicate Console</MenuItem>
                        <MenuItem divider />
                        <MenuItem eventKey="1">Account</MenuItem>
                        <MenuItem eventKey="2">Subscriptions</MenuItem>
                        <MenuItem eventKey="1">Sharing</MenuItem>
                        <MenuItem eventKey="1">Preferences</MenuItem>
                        <MenuItem eventKey="2">Workspaces</MenuItem>
                        <MenuItem divider />
                        <MenuItem eventKey="1">Helo</MenuItem>
                        <MenuItem divider />
                        <MenuItem eventKey="2" href="#/Logout">Logout</MenuItem>
                        <MenuItem eventKey="1">Quit</MenuItem>
                    </Dropdown.Menu>*/}
          <Dropdown.Menu>
            <MenuItem className="logo-footer"></MenuItem>
            <MenuItem eventKey="1" href="#" onSelect={this.menuItemSelect}>{LocalizationStore.getTranslatedData('Account', 'Account')}</MenuItem>
            <MenuItem eventKey="7" href="#" onSelect={this.menuItemSelect}>{LocalizationStore.getTranslatedData('Sharing', 'Sharing')}</MenuItem>
            {/*<MenuItem eventKey="2">Subscriptions</MenuItem>*/}
            <MenuItem eventKey="3" href="#" onSelect={this.menuItemSelect}>{LocalizationStore.getTranslatedData('Preferences', 'Preferences')}</MenuItem>
            <hr/>
            <MenuItem eventKey="4" href="#" onSelect={this.menuItemSelect}>{LocalizationStore.getTranslatedData('Help', 'Help')}</MenuItem>
            <MenuItem eventKey="5" href="#" onSelect={this.menuItemSelect}>{LocalizationStore.getTranslatedData('LogOut', 'Log Out')}</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
