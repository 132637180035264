import HttpMethodType from "HttpMethodType";
import SubSystemType from "SubSystemType";
import BaseServiceRequest from "BaseServiceRequest";

export default class MarkNotificationAsReadBySourceTypeRequest extends BaseServiceRequest {
  constructor(sourceType) {
    super();

    super.addRequestUri("readbysourcetype.pb");

    super.addRequestParameter("nstid", sourceType.toString());
  }

  getMethod() {
    return HttpMethodType.PUT;
  }

  getSubSystem() {
    return SubSystemType.NOTIFICATION;
  }

  getMessageName() {
    return "Response";
  }

}
