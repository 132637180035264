import React, { Component } from "react";
//import * as ColorConstants from "../../Constants/AppColorConstants";

class ColorSquare extends Component {
    constructor(props) {
        super(props);
        this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
        this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);

        this.state = {
            onHover: false,
            isSelected: this.props.isColorSelected,
            color: this.props.color,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.isColorSelected !== nextProps.isColorSelected) {
            this.setState({
                isSelected: nextProps.isColorSelected
            })
        }


    }

    onMouseEnterHandler() {
        this.setState({
            onHover: true
        });
    }

    onMouseLeaveHandler() {
        this.setState({
            onHover: false
        });
    }


    render() {
        let currentStyle = "colorSquare";
        if (this.state.isSelected || this.state.onHover) {
            currentStyle = "colorSquareHover";
        }

        return (<div  className={currentStyle} style={{ backgroundColor: this.state.color }}
            onMouseDown={this.props.onColorSelected}
            onMouseEnter={this.onMouseEnterHandler}
            onMouseLeave={this.onMouseLeaveHandler}>
        </div>)
    }
}

export default ColorSquare;
