import Serializable from "Serializable";
import RiPanelStockViewSettings from "./RiPanelStockViewSettings.js";
import RiPanelETFViewSettings from "./RiPanelETFViewSettings.js";
import RiPanelCEFViewSettings from "./RiPanelCEFViewSettings.js";
import RiPanelIndexViewSettings from "./RiPanelIndexViewSettings.js";
import RiPanelFUNDViewSettings from "./RiPanelFUNDViewSettings.js";
import RiPanelCurrencyViewSettings from "./RiPanelCurrencyViewSettings.js";
import RiPanelCryptoCurrencyViewSettings from "./RiPanelCryptoCurrencyViewSettings.js";
import RiPanelIndustryGroupViewSettings from "./RiPanelIndustryGroupViewSettings.js";
import RiPanelCashSpotsViewSettings from "./RiPanelCashSpotsViewSettings.js";
import RiPanelFuturesViewSettings from "./RiPanelFuturesViewSettings.js";

class RelatedInformationSettings extends Serializable {

    getDefaultRIPanelSettings() {
        this.isRIPanelOpen = false

        /* Common O'Neil list settings */
        this.isResearchPanelOpen = true;
        this.isFoucsPanelOpen = true;
        this.isResearchAnalystOpen = true;

        this.StockViewSettings = this.getDefaultRiPanelViewSettings(RiPanelStockViewSettings);
        this.ETFViewSettings = this.getDefaultRiPanelViewSettings(RiPanelETFViewSettings);
        this.CEFViewSettings = this.getDefaultRiPanelViewSettings(RiPanelCEFViewSettings);
        this.IndexViewSettings = this.getDefaultRiPanelViewSettings(RiPanelIndexViewSettings);
        this.FUNDViewSettings = this.getDefaultRiPanelViewSettings(RiPanelFUNDViewSettings);
        this.CurrencyViewSettings = this.getDefaultRiPanelViewSettings(RiPanelCurrencyViewSettings);
        this.CryptoCurrencyViewSettings = this.getDefaultRiPanelViewSettings(RiPanelCryptoCurrencyViewSettings);
        this.IndustryGroupViewSettings = this.getDefaultRiPanelViewSettings(RiPanelIndustryGroupViewSettings);
        this.CashSpotsViewSettings = this.getDefaultRiPanelViewSettings(RiPanelCashSpotsViewSettings);
        this.FuturesViewSettings = this.getDefaultRiPanelViewSettings(RiPanelFuturesViewSettings);
        return this;
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.isRIPanelOpen = serializedValue.isRIPanelOpen !== undefined ? serializedValue.isRIPanelOpen :false;
        this.isResearchPanelOpen = serializedValue.isResearchPanelOpen !== undefined ? serializedValue.isResearchPanelOpen : true;
        this.isFoucsPanelOpen = serializedValue.isFoucsPanelOpen !== undefined ? serializedValue.isFoucsPanelOpen : true;
        this.isResearchAnalystOpen = serializedValue.isResearchAnalystOpen !== undefined ? serializedValue.isResearchAnalystOpen : true;
        this.StockViewSettings = serializedValue.StockViewSettings ? serializedValue.StockViewSettings : this.getDefaultRiPanelViewSettings(RiPanelStockViewSettings);
        this.ETFViewSettings = serializedValue.ETFViewSettings ? serializedValue.ETFViewSettings : this.getDefaultRiPanelViewSettings(RiPanelETFViewSettings);
        this.CEFViewSettings = serializedValue.CEFViewSettings ? serializedValue.CEFViewSettings : this.getDefaultRiPanelViewSettings(RiPanelCEFViewSettings);
        this.IndexViewSettings = serializedValue.IndexViewSettings ? serializedValue.IndexViewSettings : this.getDefaultRiPanelViewSettings(RiPanelIndexViewSettings);
        this.FUNDViewSettings = serializedValue.FUNDViewSettings ? serializedValue.FUNDViewSettings : this.getDefaultRiPanelViewSettings(RiPanelFUNDViewSettings);
        this.CurrencyViewSettings = serializedValue.CurrencyViewSettings ? serializedValue.CurrencyViewSettings : this.getDefaultRiPanelViewSettings(RiPanelCurrencyViewSettings);
        this.CryptoCurrencyViewSettings = serializedValue.CryptoCurrencyViewSettings ? serializedValue.CryptoCurrencyViewSettings : this.getDefaultRiPanelViewSettings(RiPanelCryptoCurrencyViewSettings);
        this.IndustryGroupViewSettings = serializedValue.IndustryGroupViewSettings ? serializedValue.IndustryGroupViewSettings : this.getDefaultRiPanelViewSettings(RiPanelIndustryGroupViewSettings);
        this.CashSpotsViewSettings = serializedValue.CashSpotsViewSettings ? serializedValue.CashSpotsViewSettings : this.getDefaultRiPanelViewSettings(RiPanelCashSpotsViewSettings);
        this.FuturesViewSettings = serializedValue.FuturesViewSettings ? serializedValue.FuturesViewSettings : this.getDefaultRiPanelViewSettings(RiPanelFuturesViewSettings);
    }

    getDataObject() {
        return {
            isRIPanelOpen: this.isRIPanelOpen,
            isResearchPanelOpen: this.isResearchPanelOpen,
            isFoucsPanelOpen: this.isFoucsPanelOpen,
            isResearchAnalystOpen: this.isResearchAnalystOpen,
            StockViewSettings: this.StockViewSettings,
            ETFViewSettings: this.ETFViewSettings,
            CEFViewSettings: this.CEFViewSettings,
            IndexViewSettings: this.IndexViewSettings,
            FUNDViewSettings: this.FUNDViewSettings,
            CurrencyViewSettings: this.CurrencyViewSettings,
            CryptoCurrencyViewSettings: this.CryptoCurrencyViewSettings,
            IndustryGroupViewSettings: this.IndustryGroupViewSettings,
            CashSpotsViewSettings: this.CashSpotsViewSettings,
            FuturesViewSettings: this.FuturesViewSettings
        };
    }

    getObjectMapKey() {
        return "RelatedInformationSettings";
    }

    getDefaultRiPanelViewSettings(view) {
        const riPanelViewSettings = new view();
        return riPanelViewSettings.getDefaulSettings();
    }

}

export default RelatedInformationSettings;