import LocalizationStore from "../Stores/Localization/LocalizationStore";

const GraphType = {
  NotAvailable: "NotAvailable",
  Intraday60Min: "Intraday60Min",
  Intraday30Min: "Intraday30Min",
  Intraday15Min: "Intraday15Min",
  Intraday10Min: "Intraday10Min",
  Intraday5Min: "Intraday5Min",
  Intraday1Min: "Intraday1Min",
  Annual: "Annual",
  Quarterly: "Quarterly",
  Monthly: "Monthly",
  Weekly: "Weekly",
  Daily: "Daily",
  Intraday: "Intraday",
  FundamentalsAnnual: "FundamentalsAnnual",
  FundamentalsSemiAnnual: "FundamentalsSemiAnnual",
  FundamentalsQuarterly: "FundamentalsQuarterly"
}

export const GraphTypeShortString = {
  [GraphType.Intraday60Min]: LocalizationStore.getTranslatedData('tp_per_60min', '60 Min'),
  [GraphType.Intraday30Min]: LocalizationStore.getTranslatedData('tp_per_30min', '30 Min'),
  [GraphType.Intraday15Min]: LocalizationStore.getTranslatedData('tp_per_15min', '15 Min'),
  [GraphType.Intraday10Min]: LocalizationStore.getTranslatedData('tp_per_10min', '10 Min'),
  [GraphType.Intraday5Min]: LocalizationStore.getTranslatedData('tp_per_5min', '5 Min'),
  [GraphType.Intraday1Min]: LocalizationStore.getTranslatedData('tp_per_1min', '1 Min'),
  [GraphType.Annual]: LocalizationStore.getTranslatedData('tp_per_aly', 'Annual'),
  [GraphType.Quarterly]: LocalizationStore.getTranslatedData('tp_per_qly', 'Quarterly'),
  [GraphType.Monthly]: LocalizationStore.getTranslatedData('tp_per_mnly', 'Monthly'),
  [GraphType.Weekly]: LocalizationStore.getTranslatedData('tp_per_wkly', 'Weekly'),
  [GraphType.Daily]: LocalizationStore.getTranslatedData('tp_per_dly', 'Daily'),
  [GraphType.FundamentalsAnnual]: LocalizationStore.getTranslatedData('fr_annual', 'Annual'),
  [GraphType.FundamentalsSemiAnnual]: LocalizationStore.getTranslatedData('fr_semiAnnual', 'Semi Annual'),
  [GraphType.FundamentalsQuarterly]: LocalizationStore.getTranslatedData('fr_quarterly', 'Quarterly')
}
export default GraphType;

export const MajorPeriodicityConst = {
  INTRADAY: "INTRADAY",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  QTRLY: "QTRLY",
  ANNUAL: "ANNUAL"
}