import { connect } from "react-redux";
import EventUtil from "../../../../Utils/EventUtil.js";
import { onPlotSymbol } from "../../../../Actions/DatagraphActions.js";
import React from "react";
import ThemeHelper from "ThemeHelper";
import { updateSimilarFundsRelative } from "../../../../Actions/RelatedInfoPanelActions/RiPanelActions.js";
import VirtualList from "VirtualList";
import { TranslateHelper, IndicatorCommonTranslateHelper, RiPanelSimilarFundsTranslateHelper } from "../../../../Utils/TranslateHelper.js";

class RIPanelSimilarFunds extends React.Component {
    getIndicatorView(item) {
        let IndicatorView = <div className="similarFundsList-item-Indicator"></div>;
        if (item.clientStatus === "Client") {
            if (!item.totalOverDueDays) {
                IndicatorView = <div className="similarFundsList-item-Indicator IndicatorBrush1"></div>
            } else {
                IndicatorView = <div className="similarFundsList-item-Indicator IndicatorBrush2"></div>
            }
        } else {
            IndicatorView = <div className="similarFundsList-item-Indicator IndicatorBrush3"></div>
        }
        return IndicatorView;
    }

    getItemRenderer(item, top, index) {
        const IndicatorView = this.getIndicatorView(item);
        if (item) {
            const positiveColor = {color: this.props.posDTColor}
            const negativeColor = {color: this.props.negDTColor}

            const tempStyleS = item.percentageChgYTD === 0 ? {} : item.percentageChgYTD > 0 ? positiveColor : negativeColor;
            const tempStyleR = item.relativeYTD === 0 ? {} : item.relativeYTD > 0 ? positiveColor : negativeColor;
            return (
                <div className="similarFundsList-item" key={index} style={{ top: top }}>
                    {this.props.isFundViewEntitled ? IndicatorView : ""}
                    <div className="similarFundsList-sponsorName medium-normal" onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, item.sponsorName)}
                        onFocus={(e) => EventUtil.mouseOverShowTitle(e, item.sponsorName)} onClick={() => this.props.onPlotSymbol(item.symbol)}>{item.sponsorName}</div>
                    <div
                        className={this.props.isRelative ? `similarFundsList-YTD ${item.YTDForegroundR}` : `similarFundsList-YTD ${item.YTDForegroundS}`}>
                        <div style={this.props.isRelative ? tempStyleR : tempStyleS}>{this.props.isRelative ? item.relativeYTDString : item.percentageChgYTDString}</div>
                    </div>
                    <div className="similarFundsList-grid medium-normal">
                        <table>
                            <tbody>
                                <tr>
                                    <td>{RiPanelSimilarFundsTranslateHelper.AUM}</td>
                                    <td className="rightLine">{item.abbreviateAssetsUnderMgmt}</td>
                                    <td className="ofStks">{RiPanelSimilarFundsTranslateHelper.STKS}</td>
                                    <td className="stockHeld">{item.stocksHeld}</td>
                                    <td>{RiPanelSimilarFundsTranslateHelper.OVERLAP}</td>
                                    <td>{item.similarityString}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }
    }
    getActiveFundView(activeFund) {
        const IndicatorView = this.getIndicatorView(activeFund);
        const positiveColor = {color: this.props.posDTColor}
        const negativeColor = {color: this.props.negDTColor}

        const tempStyleS = activeFund.percentageChgYTD === 0 ? {} : activeFund.percentageChgYTD > 0 ? positiveColor : negativeColor;
        const tempStyleR = activeFund.relativeYTD === 0 ? {} : activeFund.relativeYTD > 0 ? positiveColor : negativeColor;
        return (
            <div className="similarFundsList-item active-fund">
                {this.props.isFundViewEntitled ? IndicatorView : ""}
                <div className="similarFundsList-sponsorName white-text medium-normal"
                    onMouseOver={(e) => EventUtil.mouseOverShowTitle(e, activeFund.sponsorName)}
                    onFocus={(e) => EventUtil.mouseOverShowTitle(e, activeFund.sponsorName)}>{activeFund.sponsorName}</div>
                <div
                    className={this.props.isRelative ? `similarFundsList-YTD ${activeFund.YTDForegroundR}` : `similarFundsList-YTD ${activeFund.YTDForegroundS}`}>
                    <div style={this.props.isRelative ? tempStyleR : tempStyleS}>{this.props.isRelative ? activeFund.relativeYTDString : activeFund.percentageChgYTDString}</div>
                </div>
                <div className="similarFundsList-grid medium-normal">
                    <table>
                        <tbody>
                            <tr>
                                <td>{RiPanelSimilarFundsTranslateHelper.AUM}</td>
                                <td className="rightLine">{activeFund.abbreviateAssetsUnderMgmt}</td>
                                <td className="ofStks">{RiPanelSimilarFundsTranslateHelper.STKS}</td>
                                <td className="stockHeld">{activeFund.stocksHeld}</td>
                                <td>{RiPanelSimilarFundsTranslateHelper.OVERLAP}</td>
                                <td>--</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>)
    }

    render() {
        if (this.props.loading) {
            return (<div className="similarFundsInfo">
                <div className="news-message large-normal"><p>{TranslateHelper.Loading}</p>
                </div>
            </div>);
        } else {
            if (this.props.similarFundsData.length === 0 && !this.props.ActiveFund) {
                return (<div className="similarFundsInfo">
                    <div className="similarFunds-content">
                        <div className="news-message  large-normal"><p>{TranslateHelper.No_Data_Available}</p></div>
                    </div>
                </div>);
            }
            else {
                return (<div className="similarFundsInfo">
                    <div className="title">
                        <span className="YTDText xx-small-normal">YTD</span>
                        <span className="fundRelativeLink xx-small-normal"
                            onClick={this.props.updateSimilarFundsRelative}>{!this.props.isRelative ? IndicatorCommonTranslateHelper.Absolute : IndicatorCommonTranslateHelper.Relative}</span>
                    </div>
                    {this.getActiveFundView(this.props.ActiveFund)}
                    <div className="similarFunds-content">
                        <VirtualList id="RIPanelSimilarFundsInfo"
                            items={this.props.similarFundsData}
                            itemHeight={70}
                            itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                            showScrollOnHover={true}
                        />
                    </div>
                </div>);
            }
        }
    }
}

const mapStateToProps = ({ RelatedInfoPanelReducers, appColor }) => {
    const { similarFundsData, ActiveFund, loading, isRelative, isFundViewEntitled } = RelatedInfoPanelReducers.RiPanelSimilarFundsReducer;
    const { posDTColor, negDTColor } = appColor;
    return { similarFundsData, ActiveFund, loading, isRelative, isFundViewEntitled, posDTColor, negDTColor }
}
const mapDispatchToProps = (dispatch) => ({
    updateSimilarFundsRelative: () => dispatch(updateSimilarFundsRelative()),
    onPlotSymbol: (symbol) => dispatch(onPlotSymbol(symbol))
})

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelSimilarFunds)
