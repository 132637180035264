import Scale from "./Scale.js";

class BasicArithScale extends Scale {
    getObjectMapKey() {
        return "BasicArithScale";
    }

    ComputeY(price) {
        const dPriceDiffFromBottom = price - this.Lowpt;

        return this.height - dPriceDiffFromBottom * this.Factor;
    //    return Math.round(this.height - dPriceDiffFromBottom * this.Factor, 2);
    }

    ComputeYL(dPrice, dLow) {
        const dPriceDiffFromBottom = dPrice - dLow;

        return this.height - dPriceDiffFromBottom * this.Factor;
    //    return Math.round(this.height - dPriceDiffFromBottom * this.Factor, 2);
    }

    ComputePrice(y) {
        const dGraphDistance = this.height - y;
        let dPriceDiffFromBottom = dGraphDistance / this.Factor;

        dPriceDiffFromBottom += this.Lowpt;
        return dPriceDiffFromBottom;
    }

    InitScale(minPrice, maxPrice, height) {
        this.height = height;
        const diff = 10;

        this.Hipt = maxPrice;
        this.Lowpt = minPrice;
        //// Actual Factor
        this.Factor = (this.height) / (this.Hipt - this.Lowpt);

        const yPr1 = this.ComputePrice(-diff);
        const yPr2 = this.ComputePrice(this.height + diff);

        this.Hipt = yPr1;
        this.Lowpt = yPr2;
        // Adjusted Factor with Top/Bottom margins
        this.Factor = (this.height) / (this.Hipt - this.Lowpt);
    }

}

export default BasicArithScale;
