import { connect } from 'react-redux';
import StringUtil from "StringUtil";
import React, { Component } from 'react';
import { setIsCurrencyConversionChecked, setSelectedCurrencyCode } from '../../Actions/CurrencyActions';

class CurrencyListData extends Component {
    constructor(props) {
        super(props);
        this.onCurrencySelection = this.onCurrencySelection.bind(this);
    }

    onCurrencySelection() {
        this.props.setSelectedCurrencyCode(this.props.symbolrow.currencyCode);
        const defaultCurr = this.props.SymbolInfo.DefaultCurrency && this.props.SymbolInfo.DefaultCurrency.Code ? this.props.SymbolInfo.DefaultCurrency.Code : null;
        if (defaultCurr === this.props.symbolrow.currencyCode) {
            this.props.setIsCurrencyConversionChecked(false, this.props.Symbol, this.props.nodeCount, true);
        } else if (this.props.isCurrencyConversionChecked) {
            this.props.setIsCurrencyConversionChecked(true, this.props.Symbol, this.props.nodeCount, true);
        }
    }

    render() {
        const symbolrow = this.props.symbolrow;
        const isSelected = this.props.selectedCurrencyCode === StringUtil.trim(symbolrow.currencyCode);
        return (
            <div className={`symbol-currencySymbol-details-block data-currency-${symbolrow.currencyCode}`} onClick={this.onCurrencySelection}>
                <span className="symbol-currencySymbol-details">
                    <span className={isSelected ? 'blank-sp icn-right-icon' : 'blank-sp'} ></span>
                    {symbolrow.name}
                </span>
                <span className="symbol-currencySymbol-details">{symbolrow.currencyCode}</span>
            </div>
        );
    }
}

const mapStateToProps= ({ DatagraphReducers, currency })=> {
    const { nodeCount, Symbol, SymbolInfo } = DatagraphReducers.DataGraphReducer;
    const { isCurrencyConversionChecked } = currency;
    return { nodeCount, Symbol, SymbolInfo, isCurrencyConversionChecked }
}

const mapDispatchToProps= (dispatch)=> ({
    setIsCurrencyConversionChecked:(isCurrencyConversionChecked, symbol, nodeCount, isSymbolChange)=> dispatch(setIsCurrencyConversionChecked(isCurrencyConversionChecked, symbol, nodeCount, isSymbolChange)),
    setSelectedCurrencyCode:(selectedCurrencyCode)=> dispatch(setSelectedCurrencyCode(selectedCurrencyCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyListData);