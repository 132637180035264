import { connect } from 'react-redux';
import LineVisual from "LineVisual";
import { PriceChartConst } from '../../../../../../Constants/PriceChartConstants';
import React from 'react';
import ThemeHelper from "ThemeHelper";
import { updateContextHit } from '../../../../../../Actions/PricePanelActions';

function RSLines(props) {
  const { rs1LineData, rs2LineData, rs1MALineData, rs2MALineData, rs1Text, rs2Text, rs1MAText, rs2MAText,
    IsVisible, lineColor, maLineColor, lineThickness, maLineThickness, showRank, showGrade,
    showMaLine, rs2Settings, chartHeight, videoMode, nodeWidth, rsValue, acdcValue } = props;
  const rsLine = rsValue || 0;
  const rs1Number = videoMode === false && showRank && rsLine;
  const rs1AccDist = videoMode === false && showGrade && acdcValue;
  const rs2Number = videoMode === false && rs2Settings.showRank && rsLine;
  const rs2AccDist = videoMode === false && rs2Settings.showGrade && acdcValue;
  const rs1LineColor = ThemeHelper.getThemedBrush(lineColor);
  const rs1MaLineColor = ThemeHelper.getThemedBrush(maLineColor);
  const rs2LineColor = ThemeHelper.getThemedBrush( rs2Settings.lineColor);
  const rs2MaLineColor = ThemeHelper.getThemedBrush(rs2Settings.maLineColor);
  return (<>
    {rs1LineData && IsVisible && <LineVisual isToolTip={props.showToolTip}
      DataSource={rs1LineData}
      LineColor={rs1LineColor} LineThickness={lineThickness} Draggable={true}
      DataSource2={showMaLine ? rs1MALineData : ''} lineID2={rs1MAText} nodeWidth={nodeWidth}
      LineColor2={rs1MaLineColor} LineThickness2={maLineThickness}
      rsNumber={rs1Number}
      accDist={rs1AccDist}
      zIndex={'10'} lineID={rs1Text}
      updateContextHit={props.updateRS1ContextHit}
      chartHeight={chartHeight} />}
    {rs2LineData && rs2Settings.IsVisible && <LineVisual isToolTip={props.showToolTip}
      DataSource={rs2LineData}
      LineColor={rs2LineColor} LineThickness={rs2Settings.lineThickness} Draggable={true}
      DataSource2={rs2Settings.showMaLine ?  rs2MALineData : ''} lineID2={rs2MAText} nodeWidth={nodeWidth}
      LineColor2={rs2MaLineColor} LineThickness2={rs2Settings.maLineThickness}
      rsNumber={rs2Number}
      accDist={rs2AccDist}
      zIndex={'10'} lineID={rs2Text}
      updateContextHit={props.updateRS2ContextHit}
      chartHeight={chartHeight} />}
  </>
  );
}
const mapStateToProps = ({DatagraphReducers, appColor}) => {
  const { rs1LineData, rs2LineData, rs1MALineData, rs2MALineData, rs1Text, rs2Text, rs1MAText, rs2MAText } = DatagraphReducers.RSLineReducer;
  const { IsVisible, lineColor, maLineColor, lineThickness, maLineThickness, showRank, showGrade, showMaLine } = DatagraphReducers.RSLineReducer.rsLine1Settings;
  const rs2Settings = DatagraphReducers.RSLineReducer.rsLine2Settings;
  const { rsValue, acdcValue } = DatagraphReducers.HeaderReducer;
  const {  videoMode, nodeWidth, showToolTip } = DatagraphReducers.DataGraphReducer;
  const { isThemeChanged } = appColor;
  return {
    rs1LineData, rs2LineData, rs1MALineData, rs2MALineData, rs1Text, rs2Text, rs1MAText, rs2MAText, videoMode, nodeWidth,
    IsVisible, lineColor, maLineColor, lineThickness, maLineThickness, showRank, showGrade, rsValue, acdcValue, rs2Settings, showMaLine, showToolTip,
    isThemeChanged
  };
}
const mapDispatchToProps = (dispatch)=>({
  updateRS1ContextHit: ()=> dispatch(updateContextHit({lineID: PriceChartConst.RS1_LINE})),
  updateRS2ContextHit: ()=> dispatch(updateContextHit({lineID: PriceChartConst.RS2_LINE})),
})
export default connect(mapStateToProps, mapDispatchToProps)(RSLines);