import OpenInterestSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/OpenInterestSettings.js";
import SettingsStore from "SettingsStore";
import VolumeBlockSettings from "../../../Stores/ConsoleWindow/Settings/Modules/DataGraph/VolumeBlockSettings.js";
import { VolumeChartConstants } from "../../../Constants/VolumeChartConstants";

const { ActionTypes } = VolumeChartConstants;
const initialState = {
    volumeData: {},
    volMALineData: [],
    priceMenu: [],
    scale: {},
    Height: 0,
    Width: 0,
    scaleText: "LOG",
    showNQBox: false,
    OpenInterestLineData: [],
    isTrade: true,
    isVolumeOpen: false,
    lastNodeVolume: '',
    volumePercentChange: 0,
    volumePercentChangeStr: '',
    pointerYValue: 0,
    pointerRightContent: " ",
    pointerRightBox: " ",
    showPointer: false,
    notDelayed: true,
    isInterestVisible: false,
    lineColor: 'P4C4C4C999999',
    lineThickness: 0,
    VolumeBlockSettings: new VolumeBlockSettings(),
    OpenInterestSettings: new OpenInterestSettings(),
}

const volumeChartReducer = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypes.SET_VOLUME_CHART_DATA: {
            return { ...state, ...action.newState, isVolumeOpen: action.newState.VolumeBlockSettings.IsVisible }
        }

        case ActionTypes.UPDATE_NQ_BOX:
            return {
                ...state,
                showNQBox: !state.showNQBox
            }

        case ActionTypes.VOLUME_CHART_COLLAPSE_BLOCK: {
            if (action.blockType === "Volume") {
                state.VolumeBlockSettings.IsVisible = action.isopen;
                SettingsStore.saveSettings();
            }
            return { ...state, isVolumeOpen: action.isopen }
        }

        case ActionTypes.UPDATE_VOLUME_OPEN_INTEREST: {
            action.item.isActive = !action.item.isActive;
            state.OpenInterestSettings.IsVisible = action.item.isActive;
            SettingsStore.saveSettings();
            return { ...state, isInterestVisible: action.item.isActive }
        }
        case ActionTypes.CLEAR_VOLUME_DATA: {
            return { ...initialState }
        }

        default:
            return state
    }
}

export default volumeChartReducer