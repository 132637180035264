import { call, all, fork, put, takeLatest, select, cancel, delay, takeEvery } from 'redux-saga/effects';
import { SettingsConstants } from "Constants/SettingsConstants.js";
const { ActionTypes } = SettingsConstants;
import SettingsStore from "SettingsStore";
import CompareSettingHelper from "Stores/ConsoleWindow/Settings/Modules/Compare/CompareSettingHelper.js";
import AdobeUtil from "Utils/AdobeUtil";
import AdobeConstants from "Constants/AdobeConstants";
import { ComparisonAndRotationMenuConstants, RotationGraphConstants } from "Constants/RotationGraphConstants";
extend(ActionTypes, ComparisonAndRotationMenuConstants.ActionTypes, RotationGraphConstants.ActionTypes);
import { getIsComparisonTabSelected } from '../../Reducers/NavCompare/MenuPanelReducers/reselectorsdata';
import { getNavCompareState, getGroupsPanel, getMenuTab } from '../../Reducers/NavCompare/MenuPanelReducers/selectors';
import { getBenchMarkState, getGlobalCountDataList } from '../../Reducers/NavCompare/RotationGraphReducers/selectors';
import { extend } from "underscore";
import CompareApi from "CompareApi";
import StringUtil from 'StringUtil';
import DateHelper from "DateHelper";
import moment from "moment";
import { initializeTimelineValues, updateFromUserSettingsData } from 'Actions/RotationGraphActions';
import { initCompGroupsMenu, initRotationSymbolsMenu, initRotationGroupsMenu } from 'Actions/ComparisonAndRotationMenuActions';
import BrowserUtil from "BrowserUtil";
import { CompareMenuType } from "CompareTabType";
import { handleLoading } from './RotationGraph/RotationMenuPanelSaga';
import { prepareGeoGraphyData } from './MenuPanel/GroupsPanelSaga';

let isGroupsMainDataApiInitiated = false;

export function* getGlobalTrendData() {
    const globalTrendRequestData = {
        fromdate: DateHelper.yyyymmdd(moment.tz("US/Pacific").set({ 'year': 2011, 'month': 8, 'date': 30 }).toDate()),
        enddate: DateHelper.yyyymmdd(DateHelper.returnTimeZoneDate(new Date())),
    }
    const globalCountDataListResponse = yield CompareApi.getGlobalTrendCountData(globalTrendRequestData);

    yield put({
        type: ActionTypes.UPDATE_GLOBAL_TREND_DATA,
        globalCountDataList: globalCountDataListResponse
    });

    return globalCountDataListResponse;
}

function* getGeographyData() {
    const result = yield CompareApi.getGeographyData();

    yield call(prepareGeoGraphyData, result);
}

function* initGroupsMainData() {
    try {
        let { groupsMainData } = yield select(getNavCompareState);
        if (StringUtil.isEmpty(groupsMainData) && !isGroupsMainDataApiInitiated) {
            isGroupsMainDataApiInitiated = true;
            const groupsMainDataResponse = yield CompareApi.getGroupsMainData();

            yield put({
                type: ActionTypes.UPDATE_GROUPS_MAIN_DATA,
                response: groupsMainDataResponse
            });
        }
    }
    catch (error) {
        console.log(`Error occurs in NavCompareSaga.js, initGroupsMainData ${error}`)
    }
}

function* initializeData({ isLogin }) {
    try {
        let initalTask = null;
        if (isLogin) {
            initalTask = yield fork(initAllApi);
        }
        else {
            if (initalTask)
                yield cancel(initalTask);

            let { geographyDataList } = yield select(getGroupsPanel);
            let { groupsMainData, compGroupsMenuInit, rotationSymbolsMenuInit, rotationGroupsMenuInit } = yield select(getNavCompareState);
            const isComparisonTabSelected = yield select(getIsComparisonTabSelected);

            yield put({
                type: ActionTypes.MENU_LOADING,
                isLoading: true
            });


            if (StringUtil.isEmpty(geographyDataList)) {
                yield call(getGeographyData);
            }


            if (!isComparisonTabSelected) {
                const menuTab = SettingsStore.getConsoleSettings().NavCompareSettings.TabRotationGraphSettings.rotationMenuTab;
                const globalCountDataList = yield select(getGlobalCountDataList);

                if (StringUtil.isEmpty(groupsMainData) && menuTab === CompareMenuType.Groups) {
                    isGroupsMainDataApiInitiated = true;
                    const groupsMainDataResponse = yield CompareApi.getGroupsMainData();

                    yield put({
                        type: ActionTypes.UPDATE_GROUPS_MAIN_DATA,
                        response: groupsMainDataResponse
                    });
                }

                yield put(updateFromUserSettingsData());
                yield put(initializeTimelineValues());

                if (StringUtil.isEmpty(globalCountDataList)) {
                    yield call(getGlobalTrendData);
                }

                if (menuTab === CompareMenuType.Symbols) {
                    yield all([
                        put(initRotationSymbolsMenu()),

                        put({
                            type: ActionTypes.DATA_LOADED_SUCCESS,
                            compGroupsMenuInit: compGroupsMenuInit,
                            rotationSymbolsMenuInit: true,
                            rotationGroupsMenuInit: rotationGroupsMenuInit
                        })
                    ]);
                }
                else {
                    yield all([
                        put(initRotationGroupsMenu()),

                        put({
                            type: ActionTypes.DATA_LOADED_SUCCESS,
                            compGroupsMenuInit: compGroupsMenuInit,
                            rotationSymbolsMenuInit: rotationSymbolsMenuInit,
                            rotationGroupsMenuInit: true
                        })
                    ]);
                }
            }

            if (isComparisonTabSelected) {
                if (StringUtil.isEmpty(groupsMainData) && !isGroupsMainDataApiInitiated) {
                    isGroupsMainDataApiInitiated = true;
                    const groupsMainDataResponse = yield CompareApi.getGroupsMainData();

                    yield put({
                        type: ActionTypes.UPDATE_GROUPS_MAIN_DATA,
                        response: groupsMainDataResponse
                    });
                }

                yield all([
                    put(initCompGroupsMenu()),

                    put({
                        type: ActionTypes.DATA_LOADED_SUCCESS,
                        compGroupsMenuInit: true,
                        rotationSymbolsMenuInit: rotationSymbolsMenuInit,
                        rotationGroupsMenuInit: rotationGroupsMenuInit
                    })
                ]);
            }
        }
    }
    catch (error) {
        console.log(`Error occurs in NavCompareSaga.js, initializeData ${error}`)
    }
}

function* initAllApi() {
    yield delay(3000);  // To avoid initiating multiple calls if last loaded tab is Nav compare
    yield call(getGeographyData);
    yield call(getGlobalTrendData);
    yield call(initGroupsMainData);
}

function* setScrollPosition({ osid, isHighlighted }) {
    try {
        const { menuId } = yield select(getNavCompareState);
        if (!isHighlighted) return;

        let listElement = null;
        let menuVerticalScroll = null;
        let scrollPositionValue = null;

        if (!StringUtil.isEmpty(menuId))
            listElement = menuId.querySelector("li[data-osid='" + osid + "']");

        if (!StringUtil.isEmpty(listElement)) {
            menuVerticalScroll = document.getElementById("rotationMenuVerticalScroll");
            scrollPositionValue = parseInt(listElement.offsetTop);
        }
        else
            scrollPositionValue = 0;

        if (!StringUtil.isEmpty(menuVerticalScroll))
            window.setTimeout(() => { menuVerticalScroll.scrollTop = scrollPositionValue; }, 0);
    }
    catch (error) {
        console.log(`Error occurs in  NavCompareSaga.js, setScrollPosition ${error}`);
    }
}

function* refreshData() {
    const isComparisonTabSelected = yield select(getIsComparisonTabSelected);
    const menuTab = yield select(getMenuTab);

    try {
        if (!isComparisonTabSelected) {
            yield call(handleLoading, true);

            BrowserUtil.disableEvents();

            if (menuTab.RotationMenuTab === CompareMenuType.Groups) {

                yield call(getGeographyData);
                const groupsMainDataResponse = yield CompareApi.getGroupsMainData();

                yield put({
                    type: ActionTypes.UPDATE_GROUPS_MAIN_DATA,
                    response: groupsMainDataResponse
                });

                const { selectedSliderValue } = yield select(getBenchMarkState);
                yield put({
                    type: ActionTypes.PREPARE_POINTS_TO_RENDER,
                    selectedSliderValue: selectedSliderValue
                });
            }
            else {
                yield put({
                    type: ActionTypes.CLEAR_ROTATION_SYMBOLS_DATA
                });
                yield put(initRotationSymbolsMenu(true));
            }

            BrowserUtil.enableEvents();
            yield call(handleLoading);

        }
    }
    catch (error) {
        console.log(`Error occurs in  NavCompareSaga.js, refreshData ${error}`);
    }
}

function pushDatatoAdobe() {
    const setting = SettingsStore.getConsoleSettings().NavCompareSettings;
    AdobeUtil.DTMTrack({
        pageName: "Panaray : Compare",
        siteSection: "Panaray : Compare",
        userType: "User",
        userIdRequired: true,
        graphSelected: setting.SelectedTabKey
    },
        AdobeConstants.COMPARE_VIEW_LOAD);
}

function* updateSelectedTab({ tabKey }) {
    const viewSetting = SettingsStore.getConsoleSettings().NavCompareSettings;
    CompareSettingHelper.updateTabSelection(viewSetting, tabKey);

    try {
        yield put({
            type: ActionTypes.COMPARE_UPDATE_TAB_TO_STATE,
            selectedTab: tabKey
        });

        yield fork(pushDatatoAdobe);
        SettingsStore.saveSettings();
    }
    catch (error) {
        console.log(`Error occurs in  NavCompareSaga.js, updateSelectedTab ${error}`);
    }
}

export function* watchUpdateSelectedTab() {
    yield takeLatest(ActionTypes.COMPARE_UPDATE_TAB, updateSelectedTab);
}

export function* watchInitData() {
    yield takeLatest(ActionTypes.INITIALIZE_DATA, initializeData);
}

export function* watchSetScrollPosition() {
    yield takeLatest(ActionTypes.SET_SCROLL_POSITION, setScrollPosition);
}

export function* watchRefreshRotationGraph() {
    yield takeLatest(ActionTypes.HANDLE_REFRESH, refreshData)
}

export function* watchInitGroupsMainData() {
    yield takeEvery(ActionTypes.INIT_GROUPS_MAIN_DATA, initGroupsMainData);
}