import AlertSourceType from '../../Constants/AlertSourceType';
import ContextMenuConstants from '../../Constants/ContextMenuConstants'
import LocalizationStore from "LocalizationStore";
import PropTypes from 'prop-types';
import React from 'react';

export default class DGContextMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibility: 'collapse',
      position: props.position ? props.position : "absolute"
    };

    this.onClick = this.onClick.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);

    this.createContextMenu = this.createContextMenu.bind(this);
    this.handleStopPropagation = this.handleStopPropagation.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, false);
  }

  handleStopPropagation(e) {
    e.stopPropagation();
  }

  handleDocumentClick(e) {
    this.props.closeContextMenu(e.target.id);
  }

  // componentWillReceiveProps(nextProps) {
  //   this.setState({ show: nextProps.show })
  // }

  onClick(e, entry, existAlerts = false, action) {
    if (entry.action === ContextMenuConstants.EARNINGS_LINE_ANIMATION){
      this.props.showAnimationMessageDialog(entry);
    }else{
      this.props.onContextMenuClick(entry, existAlerts, action);
    }
    e.stopPropagation();
  }

  onContextMenuClick(e) {
    e.preventDefault();
    return false;
  }

  onDelete(e, action){
    this.props.onDelete(action);
  }

  createContextMenu() {
    const existAlerts = false;
    if (this.props.contextMenu !== undefined && this.props.contextMenu.length > 0) {
      return this.props.contextMenu.map((entry, i) => {
        if (entry !== undefined) {
          if (entry.action !== ContextMenuConstants.DELETE_LIST_ALERTS && entry.action !== ContextMenuConstants.EDIT_ANIMATION_SETTINGS) {
            if ((entry.action === ContextMenuConstants.ALERT || entry.action === ContextMenuConstants.AND_CONDITION_ALERTS) && this.props.contextMenu[i + 1] && this.props.contextMenu[i + 1].action === ContextMenuConstants.DELETE_LIST_ALERTS){
              return <li key={i} data-disable-trackprice={true}>{entry.isChecked ? <span className="icn-right-icon"></span> : null}
             <span className="default anchor-replace">
                <span style={{ display: 'inline-block' }} className=" anchor undefined pull-left cursor-pointer" onClick={(e) => this.onClick(e, entry, !existAlerts)}>{entry.headerText}</span>
                <span style={{ display: 'inline-block' }} className=" anchor undefined  right-text context-action context-alert" onClick={(e) => this.onClick(e, this.props.contextMenu[i+1],existAlerts,entry.action)}>{LocalizationStore.getTranslatedData("delete_upper","Delete")}</span>
              </span>
              </li>;
              }
            else if (entry.action === ContextMenuConstants.EARNINGS_LINE_ANIMATION){
              return <li key={i} data-disable-trackprice={true} style={{width: "200px"}}>
                        <span className="default anchor-replace" onClick={(e) => this.onClick(e, entry, !existAlerts)}>
                          {entry.isActive ? <span className="icn-right-icon"></span> : null}<span style={{ display: 'inline-block' }} className=" anchor undefined pull-left cursor-pointer">{entry.headerText}</span>
                          <span style={{ display: 'inline-block', marginTop: '4px' }} className=" anchor undefined  right-text context-action animation"><a onClick={(e) => this.onClick(e, this.props.contextMenu[i+1])}>Edit</a></span>
                        </span>
                     </li>;
            }   
            else if(this.props.sourceTypeId === AlertSourceType.Stock && this.props.isActiveAlerts.isActiveOrAlert === 1 && entry.action === ContextMenuConstants.ALERT){
                return <li key={i} data-disable-trackprice={true}>{entry.isChecked ? <span className="icn-right-icon"></span> : null}
               <span className="default anchor-replace">
                  <span style={{ display: 'inline-block' }} className=" anchor undefined pull-left cursor-pointer" onClick={(e) => this.onClick(e, entry, !existAlerts)}>{entry.headerText}</span>
                  <span style={{ display: 'inline-block' }} className=" anchor undefined  right-text context-action context-alert" onClick={(e) => this.onDelete(e, entry.action)}>{LocalizationStore.getTranslatedData("delete_upper","Delete")}</span>
                </span>
                </li>;
            }
            else if(this.props.sourceTypeId === AlertSourceType.Stock && this.props.isActiveAlerts.isActiveAndAlert === 1 && entry.action === ContextMenuConstants.AND_CONDITION_ALERTS){
                return <li key={i} data-disable-trackprice={true}>{entry.isChecked ? <span className="icn-right-icon"></span> : null}
               <span className="default anchor-replace">
                  <span style={{ display: 'inline-block' }} className=" anchor undefined pull-left cursor-pointer" onClick={(e) => this.onClick(e, entry, !existAlerts)}>{entry.headerText}</span>
                  <span style={{ display: 'inline-block' }} className=" anchor undefined  right-text context-action context-alert" onClick={(e) => this.onDelete(e, entry.action)}>{LocalizationStore.getTranslatedData("delete_upper","Delete")}</span>
                </span>
                </li>;
            }
            else{
              return <li key={i} data-disable-trackprice={true}>{entry.isChecked ? <span className="icn-right-icon"></span> : null}<a onClick={(e) => this.onClick(e, entry, existAlerts)}>{entry.headerText}</a></li>;
            }
          }
        }
      });
    }
  }

  render() {
    const self = this;
    const contextMenuList = this.createContextMenu();
    const isEpsAnimationContextMenu = this.props.contextMenu ? this.props.contextMenu.filter((item) => item.action === ContextMenuConstants.EARNINGS_LINE_ANIMATION).length > 0 : false;
    let left = this.props.contextMenuXPos;
    const clientWidth = document.documentElement.clientWidth;
    if (isEpsAnimationContextMenu && clientWidth - this.props.contextMenuXPos <= 200){
      left = this.props.contextMenuXPos - 200;
    } 
    return (
      self.props.contextMenu !== null && self.props.contextMenu.length > 0 ?
        <div id='dgContextMenu' key='1' onContextMenu={(event)=> event.preventDefault()} onClick={this.handleStopPropagation} className="blue-txt" style={{
          position: this.state.position,
          pointerEvents: "all",
          top: this.props.contextMenuYPos,
          left: left,
          zIndex: 999999
        }}

          data-disable-trackprice={true}>

          <ul className=" context-menu-inner list-contex  small-normal">{contextMenuList}
          </ul>
        </div>
        : (<div id="dgContextMenu"></div>)
    );
  }
}
DGContextMenu.proptypes = {
  contextMenu: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  position: PropTypes.string
};
