import _ from "underscore";
import { connect } from "react-redux";
import LocalizationStore from 'LocalizationStore';
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import { TranslateHelper } from "../../../../Utils/TranslateHelper";
import { closeFilterDialog, selectColumnSet } from "../../../../Actions/AlertActions";
import React, { Component } from "react";

class AlertColumnSetDetails extends Component {
    constructor(props) {
        super(props);
        this.selectColumnSet = this.selectColumnSet.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.columnSetDetails = null;//this.props.columnSetDetails;
        this.columnSetTitle = null;
        this.menuOpen = false;

        this.state = {
            showModal: false
        }
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    openModal() {
        this.setState({ showModal: true });
    }

    selectColumnSet(e) {
        const nodeId = ReactDOM.findDOMNode(e.target).getAttribute('data-node-id');

        const columnSetDetails = _.find(this.props.columnsetChildItems, (column) =>
            parseInt(nodeId) === parseInt(column.colsetId)
        );

        this.props.closeFilterDialog();

        this.props.selectColumnSet(columnSetDetails, this.props.parentColumnSetName);

        e.preventDefault();
    }

    renderColumnSetDetails() {

        const self = this;
        this.columnSetTitle = this.props.parentColumnSetName;
        return (<ul className="list-group">
            {
                this.props.columnsetChildItems.map((column, key) => {

                    const activeClass = (!StringUtil.isEmpty(self.props.selectedColumnSet) && self.props.selectedColumnSet.colsetId === column.colsetId) ? 'active' : '';
                    const activeNode = (!StringUtil.isEmpty(self.props.selectedColumnSet) && self.props.selectedColumnSet.colsetId === column.colsetId) ? <span className="icn-right-icon"></span> : '';

                    const columnName = column.isShared ? `Shared - ${  column.colsetName}` : column.colsetName;

                    return (
                        <li key={key} className={`list-group-item ${  activeClass}`}>
                            <a className="panal-a" href="javascript:void(0);" onClick={self.selectColumnSet} data-node-id={column.colsetId} title={LocalizationStore.getTranslatedData(`ColumnSet_${columnName}`,columnName)}>
                                {activeNode}
                                <span className={column.isShared ? "icn-share pull-left" : "icn-fa-file-text-p pull-left marr0"}></span>
                                {LocalizationStore.getTranslatedData(`ColumnSet_${columnName}`,columnName)}
                            </a>
                        </li>
                    );
                })
            }
        </ul>);
    }


    render() {
        const columnSetDetails = this.renderColumnSetDetails();

        return (
            <div id="alertColumnDialog" >
                <Modal className="modal-popup alert-column-dialog" show={true} style={{ left: this.props.left, top: this.props.top }}>
                    <Modal.Header id="alertColumnSetDetailsModalHeader">
                        <Modal.Title>
                            <span className="icn-back-btn" id="alertColumnSetBackButton" data-effect="fadeOut" onClick={this.props.toggleDetailDailog}>{TranslateHelper["Back"] }</span>
                            <span className="cap-header">{LocalizationStore.getTranslatedData(`ColumnSet_${  this.columnSetTitle}`,this.columnSetTitle)}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body id="alertColumnSetDetailsModalBody">
                        <div className="modal-area column-geo-alert custom-scroll-light" onMouseDown={this.props.handleMouseDownColumnsetModal} onMouseUp={this.props.handleMouseuPColumnsetModal}>
                            <div className="custom-scroll">
                                <div id="alertColumnSetVerticalScroll1">
                                    {columnSetDetails}
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId="alertColumnSetVerticalScroll1" vScroll={true} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = ({ alertReducer }) => {
    const { columnsetCollection, selectedColumnSet, parentColumnSetName, columnsetChildItems } = alertReducer.AlertDialogReducer;
    return { columnsetCollection, selectedColumnSet, parentColumnSetName, columnsetChildItems }
}

const mapDispatchToProps = (dispatch)=>({
    selectColumnSet: (columnSetDetails, parentColSetName)=> dispatch(selectColumnSet(columnSetDetails, parentColSetName)),
    closeFilterDialog: ()=> dispatch(closeFilterDialog()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlertColumnSetDetails);
