import keyMirror from "keymirror";

export const VolumeChartConstants = {
    ActionTypes: keyMirror({
        PREPARE_VOLUME_INSIDER_DATA: null,
        SET_VOLUME_CHART_DATA: null,
        UPDATE_VOLUME_CHART_DIMENSIONS: null,
        UPDATE_NQ_BOX: null,
        VOLUME_SCALE_CHANGED: null,
        PREPARE_INSIDER_CHART_DATA: null,
        SET_INSIDER_CHART_DATA: null,
        UPDATE_INSIDER_CHART_DIMENSIONS: null,
        VOLUME_CHART_COLLAPSE_BLOCK: null,
        INSIDER_CHART_COLLAPSE_BLOCK: null,
        UPDATE_VOLUME_OPEN_INTEREST: null,
        CLEAR_VOLUME_DATA: null,
        CLEAR_INSIDER_DATA: null
    })
}