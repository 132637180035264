
class TimeLine {
    constructor() {
        this.dottedGrid = [];
        this.solidGrid = [];
        this.eighteenMnth = [];
        this.padding = true;
    }
    Qtr(month) {
        return Math.floor((month - 1) / 3.0);
    }
    GetMDate(xDate) {
        var cDate = new Date(xDate);
        var year = cDate.getFullYear();
        var month = cDate.getMonth() + 1;
        if (month > 12) {
            month = 0;
            year++;
        }

        cDate = new Date(year, month, 1);
        cDate.setDate(cDate.getDate() - 1);
        if (cDate.getDay() == 6)
            cDate.setDate(cDate.getDate() - 1);
        if (cDate.getDay() == 0)
            cDate.setDate(cDate.getDate() - 2);
        return cDate;
    }


}

;
export default TimeLine;
