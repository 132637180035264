import GetDgHoldingsReportHeaderRequest from  'ServiceApi/Requests/GetDgHoldingsReportHeaderRequest';
import GetDgHoldingsReportRequestExt from 'ServiceApi/Requests/GetDgHoldingsReportRequestExt';
import BaseServiceApi from 'BaseServiceApi';
import GetMsidFlagRequest from 'ServiceApi/Requests/GetMsidFlagRequest';


class DgHoldingsApi {
    getHeaderData(osid, symbol, currentTab) {     
         const  request = new GetDgHoldingsReportHeaderRequest(osid, symbol, currentTab);       
         return BaseServiceApi.processServiceCall(request);
      }
    
       
     getReportExtdata(msid, orderString, resultCount, currentTab, currentSubTab, limitData) {
      const req = new GetDgHoldingsReportRequestExt(msid, orderString, resultCount, currentTab, currentSubTab, limitData);
        return BaseServiceApi.processServiceCall(req);
      }

      getMsidFlagRequest(listId, msidList) {
        try {
          const msidStr = msidList.join(";");
          const req = new GetMsidFlagRequest(listId, msidStr);
          return BaseServiceApi.processServiceCall(req);
        }
        catch (e) {
          console.log(e);
        }
      }      
}

var DgholdingsApi = new DgHoldingsApi();

export default DgholdingsApi;