import BaseServiceApi from 'BaseServiceApi';
import GraphApi from "../../../ServiceApi/Apis/GraphApi.js";
import MiniListHelper from "MiniListHelper";
import { RiPanelConstants } from "../../../Constants/RiPanelConstants";
import StringUtil from "StringUtil";
import UserInfoUtil from "UserInfoUtil";
import { getRiPanelManagementState, riPanelInfoState } from "../../../Reducers/NavDataGraph/RelatedInfoPanel/selectors";
import { put, select, call, takeLatest, fork } from "redux-saga/effects";

const { ActionTypes } = RiPanelConstants;
const EntitlementType = BaseServiceApi.rayData["EntitlementType"];

function* getManagementData({ symbolInfo }) {
    try {
        const state = yield select(getRiPanelManagementState);
        if (state.symbolInfo.Symbol !== symbolInfo.Symbol) {
            let IsDetailPage = false, ManagementData, HubContactNoteBaseURI, HubContactNoteBaseURIPvt, loading = true, Initialized, JumpDetailPage = false;
            const isFundViewEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.FUNDS_VIEW_ACNTMGR_Entitlement);;
            const symbol = symbolInfo ? symbolInfo.Symbol : '';
            const isMiniListPlay = symbolInfo.IsMiniListPlay;
            const { riPanelViewSettings } = yield select(riPanelInfoState);
            const result = yield call(GraphApi.GetFundManagementDataRequest, symbol, isFundViewEntitled);
            if (MiniListHelper.ActiveSymbolCheck(symbol, isMiniListPlay)) {
                ManagementData = yield call(processData, result.fundManagementTeamData);
                if (ManagementData.length > 0 && ManagementData.length < 2) {
                    yield fork(getManagementRelatedFundList, ManagementData[0], 0, symbolInfo);
                    IsDetailPage = true;
                } else { IsDetailPage = false; }
                HubContactNoteBaseURI = result.HubContactNoteBaseURI;
                HubContactNoteBaseURIPvt = result.HubContactNoteBaseURIPvt;
                if (result.fundManagementTeamData.length === 1) {
                    JumpDetailPage = true;
                }
                loading = false;
                Initialized = true;
            }
            if (!loading) {
                yield put({
                    type: ActionTypes.MANAGEMENT_INFO_SET_DATA,
                    newState: { IsDetailPage, ManagementSettings: riPanelViewSettings.ManagementSettings, isFundViewEntitled, ManagementData, HubContactNoteBaseURI, HubContactNoteBaseURIPvt, loading, Initialized, JumpDetailPage, symbolInfo }
                })
            }
        }
    } catch (error) {
        console.log(`Error occured in RiPanelManagementInfo.js, getManagementData ${error}`);
    }
}
function processData(data) {
    try {
        if (data) {
            data.forEach((item) => {
                item.managerName = !StringUtil.isEmpty(item.nickName) ? `${item.firstName} ${item.lastName} - ${item.nickName}` : `${item.firstName} ${item.lastName}`;
                item.bindingAgeString = (item.approximateAge !== 0) ? `Age (approx): ${item.approximateAge}` : "";
                item.photo = item.photo ? utf16ToUtf8(item.photo.toUTF8()) : null;
            });
        }
        return data;
    } catch (error) {
        console.log(`Error occured in RiPanelManagementInfo.js, processData ${error}`);
    }
}
function utf16ToUtf8(s) {
    try {
        if (!s) {
            return;
        }
        let i, code;
        const ret = [], len = s.length;
        for (i = 0; i < len; i++) {
            code = s.charCodeAt(i);
            if (code > 0x0 && code <= 0x7f) {
                ret.push(s.charAt(i));
            } else if (code >= 0x80 && code <= 0x7ff) {
                ret.push(
                    String.fromCharCode(0xc0 | ((code >> 6) & 0x1f)),
                    String.fromCharCode(0x80 | (code & 0x3f))
                );
            } else if (code >= 0x800 && code <= 0xffff) {
                ret.push(
                    String.fromCharCode(0xe0 | ((code >> 12) & 0xf)),
                    String.fromCharCode(0x80 | ((code >> 6) & 0x3f)),
                    String.fromCharCode(0x80 | (code & 0x3f))
                );
            }
        }
        return ret.join('');
    } catch (error) {
        console.log(`Error occured in RiPanelManagementInfo.js, utf16ToUtf8 ${error}`);
    }
}
function populateDataDetail(data, activeSymbol) {
    try {
        if (data) {
            data.forEach((detail) => {
                detail.isActiveFund = detail.fundTickerSymbol === activeSymbol;
                detail.percentageChgYTDString = detail.percentageChgYTD === 0 ? `${detail.percentageChgYTD.toFixed(1)}%` : detail.percentageChgYTD > 0 ? `+${detail.percentageChgYTD.toFixed(1)}%` : `${detail.percentageChgYTD.toFixed(1)}%`;
                detail.relativeYTDString = detail.relativeYTD === 0 ? `${detail.relativeYTD.toFixed(1)}%` : detail.relativeYTD > 0 ? `+${detail.relativeYTD.toFixed(1)}%` : `${detail.relativeYTD.toFixed(1)}%`;
                detail.YTDForegroundS = detail.percentageChgYTD === 0 ? "gray-text" : detail.percentageChgYTD > 0 ? "blue-text" : "red-text";
                detail.YTDForegroundR = detail.relativeYTD === 0 ? "gray-text" : detail.relativeYTD > 0 ? "blue-text" : "red-text";
            });
        }
        return data;
    } catch (error) {
        console.log(`Error occured in RiPanelManagementInfo.js, populateDataDetail ${error}`);
    }
}
function* getManagementRelatedFundList(detailItem, index, symbolInfo) {
    try {
        const isFundViewEntitled = UserInfoUtil.IsUserEntitled(EntitlementType.FUNDS_VIEW_ACNTMGR_Entitlement);;
        const result = yield call(GraphApi.GetFundManagementDataDetailRequest, detailItem.contactID, isFundViewEntitled);
        const ManagementRelatedFundList = yield call(populateDataDetail, result.fundManagementTeamDataDetail, symbolInfo.Symbol);
        const ManagerDetailData = detailItem;
        const loadingDetailPage = false;
        const IsDetailPage = true;
        const activeIndex = index;
        yield put({
            type: ActionTypes.MANAGEMENT_INFO_SET_LIST_DATA,
            newState: { ManagementRelatedFundList, ManagerDetailData, loadingDetailPage, activeIndex, IsDetailPage }
        })
    } catch (error) {
        console.log(`Error occured in RiPanelManagementInfo.js, getManagementRelatedFundList ${error}`);
    }
}
function* getListData({ item, index }) {
    try {
        const { symbolInfo } = yield select(getRiPanelManagementState);
        yield call(getManagementRelatedFundList, item, index, symbolInfo)
    } catch (error) {
        console.log(`Error occured in RiPanelManagementInfo.js, getListData ${error}`);
    }
}
/*************** Watchers ***************/
export function* watcherGetManagementData() {
    yield takeLatest(ActionTypes.GET_MANAGEMENT_INFO, getManagementData)
}
export function* watcherGetFundList() {
    yield takeLatest(ActionTypes.MANAGEMENT_INFO_GET_RELATED_FUND_LIST, getListData)
}