import AnnotationPreview from './AnnotationPreview';
import AnnotationsCheckboxSelectors from './AnnotationsCheckboxSelectors.jsx';
import AnnotationsColorPicker from './AnnotationsColorPicker';
import AnnotationsWeightSettings from './AnnotationsWeightSettings';
import BaseServiceApi from 'BaseServiceApi';
import { connect } from 'react-redux';
import KeyCodes from "../../../../../../Constants/KeyCodes";
import ScrollBar from "ScrollBar";
import ThemeHelper from "ThemeHelper";
import {
  handleColorChange, handleWeightChange, onStartExtendChange, onEndExtendChange, onCheckBoxChange, handleExtensionColorChange,
  handleExtensionLineWeightChange
} from "../../../../../../Actions/AnnotationActions";
import React, { PureComponent } from 'react'
const ShareAccessType = BaseServiceApi.rayData["ShareAccessType"];

export class RIPanelFibonacciInfo extends PureComponent {

  componentDidMount() {
    document.body.classList.add('riPanel');
    document.addEventListener("keydown", this.onDocumentKeyPress, false);
  }

  componentWillUnmount() {
    document.body.classList.remove('riPanel');
    document.removeEventListener("keydown", this.onDocumentKeyPress, false);
  }
  onDocumentKeyPress(e) {
    if ((document.activeElement && document.activeElement.id === "SymbolEntryInput") || (document.activeElement && document.activeElement.id.includes("annot-dropdown"))) {
      return;
    }

    const pointerLayerScroll = document.getElementById("annotateIntrumentsFibonacci");

    if (e.keyCode === KeyCodes.UP_ARROW) {

      if (pointerLayerScroll) {
        window.setTimeout(() => { pointerLayerScroll.scrollTop -= 30; }, 0);
      }
    }
    else if (e.keyCode === KeyCodes.DOWN_ARROW) {

      if (pointerLayerScroll) {
        window.setTimeout(() => { pointerLayerScroll.scrollTop += 30; }, 0);
      }
    }

  }
  onExtensionsChange = () => {
    this.props.onCheckBoxChange('extension');
  }

  onLevelLabelsChange = () => {
    this.props.onCheckBoxChange('levelLabels');
  }

  onPriceLabelsChange = () => {
    this.props.onCheckBoxChange('priceLabels');
  }

  onStartExtendChange = () => {
    let { startExtendChecked, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1 } = this.props.FibonacciModuleInfo;

    if (startExtendChecked) {
      startExtendChecked = !startExtendChecked;
    }
    else {
      startExtendChecked = !startExtendChecked;
    }
    upperLineX1 = 40;
    lowerLineX1 = 40;
    upperRetracementLineX1 = 40;
    lowerRetracementLineX1 = 40;

    this.props.onStartExtendChange(startExtendChecked, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1);
  }

  onEndExtendChange = () => {
    let { endExtendChecked, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2 } = this.props.FibonacciModuleInfo;

    if (endExtendChecked) {
      endExtendChecked = !endExtendChecked;
      upperLineX2 = 266;
      lowerLineX2 = 266;
      upperRetracementLineX2 = 266;
      lowerRetracementLineX2 = 266;
    }
    else {
      endExtendChecked = !endExtendChecked;
      upperLineX2 = 220;
      lowerLineX2 = 220;
      upperRetracementLineX2 = 220;
      lowerRetracementLineX2 = 220;
    }
    this.props.onEndExtendChange(endExtendChecked, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2);
  }

  onLineColorChange = (color) => {
    const lineColor = ThemeHelper.getThemedBrush(color);
    this.props.handleColorChange(lineColor);
  }

  onLineWeightChange = (weight) => {
    switch (weight) {
      case '1':
        weight = 1;
        break;
      case '2':
        weight = 2;
        break;
      case '4':
        weight = 4;
        break;
      case '6':
        weight = 6;
        break;
      case '8':
        weight = 8;
        break;
      case '16':
        weight = 16;
        break;
      default:
        break;
    }
    this.props.handleWeightChange(weight);
  }

  handleExtensionColorChange = (color) => {
    const extensionLineColor = ThemeHelper.getThemedBrush(color);
    this.props.handleExtensionColorChange(extensionLineColor);
  }

  extensionLineWeightChange = (weight) => {
    switch (weight) {
      case '1':
        weight = 1;
        break;
      case '2':
        weight = 2;
        break;
      case '4':
        weight = 4;
        break;
      case '6':
        weight = 6;
        break;
      case '8':
        weight = 8;
        break;
      case '16':
        weight = 16;
        break;
      default:
        break;
    }
    this.props.handleExtensionLineWeightChange(weight);
  }

  hideDropDownMenu = () => {
    this.colorPicker && this.colorPicker.hideDropDownMenu();
    this.weightDropDown && this.weightDropDown.hideDropDownMenu();
    this.colorPicker_extension && this.colorPicker_extension.hideDropDownMenu();
    this.weightDropDown_extension && this.weightDropDown_extension.hideDropDownMenu();
  }

  render() {
    return (
      <div className="annotation-box annotate-fibonacci">
        <div className="annotation-hold custom-scroll" onScroll={this.hideDropDownMenu} onClickCapture={this.hideDropDownMenu}>
          <div className="annotation-overlape" style={{ display: ((!this.props.annotSelected && this.props.selectedLayer && this.props.selectedLayer.shareAccess === ShareAccessType.SHARE_READONLY) || (this.props.annotSelected && this.props.annotSelected.isReadOnlyAnnotation)) ? '' : 'none' }}></div>
          <div className="annotation-intruments" id="annotateIntrumentsFibonacci">

            <AnnotationPreview label={`FIBONACCI SETTINGS`} />

            <div className="annotation-properties">
              <div className="swatches-dropdown">
                <div className="col-xs-6"><AnnotationsColorPicker label={true} type="line" ref={(r) => { this.colorPicker = r; }} /></div>
                <div className="col-xs-6"><AnnotationsWeightSettings label={true} type="line" ref={(r) => { this.weightDropDown = r; }} /></div>
              </div>
            </div>

            <div className="annotation-properties">
              <div className="swatches-checks">
                <div className="col-xs-6">
                  <h6>START</h6>
                  <ul>
                    <AnnotationsCheckboxSelectors type="ExtendStart" />
                  </ul>
                </div>
                <div className="col-xs-6">
                  <h6>END</h6>
                  <ul>
                    <AnnotationsCheckboxSelectors type="ExtendEnd" />
                  </ul>
                </div>
              </div>
            </div>

            <div className="annotation-properties">
              <div className="swatches-checks">
                <div className="col-xs-12">
                  <ul>
                    <AnnotationsCheckboxSelectors type="LevelLabels" />
                    <AnnotationsCheckboxSelectors type="PriceLabels" />
                  </ul>
                </div>
              </div>
            </div>

            <div className="annotation-properties">
              <div className="title">
                <ul>
                  <AnnotationsCheckboxSelectors type="Extensions" />
                </ul>
              </div>
              <div className="swatches-dropdown">
                <div className="col-xs-6"><AnnotationsColorPicker ref={(r) => { this.colorPicker_extension = r; }} /></div>
                <div className="col-xs-6"><AnnotationsWeightSettings ref={(r) => { this.weightDropDown_extension = r; }} /></div>
              </div>
            </div>

          </div>
          <ScrollBar scrollId="annotateIntrumentsFibonacci" vScroll={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ annotationReducers }) => ({
  FibonacciModuleInfo: annotationReducers.annotationMenuReducer.FibonacciModuleInfo,
  annotSelected: annotationReducers.annotationChartModelReducer.annotSelected,
  selectedLayer: annotationReducers.annotationLayerManagementReducer.selectedLayer,
  riPanelToggle: annotationReducers.annotationMenuReducer.riPanelToggle
})

const mapDispatchToProps = (dispatch) => ({
  handleColorChange: (color) => dispatch(handleColorChange(color)),
  handleWeightChange: (weight) => dispatch(handleWeightChange(weight)),
  onStartExtendChange: (startExtendChecked, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1) =>
    dispatch(onStartExtendChange(startExtendChecked, upperLineX1, lowerLineX1, upperRetracementLineX1, lowerRetracementLineX1)),
  onEndExtendChange: (endExtendChecked, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2) =>
    dispatch(onEndExtendChange(endExtendChecked, upperLineX2, lowerLineX2, upperRetracementLineX2, lowerRetracementLineX2)),
  onCheckBoxChange: (event) => dispatch(onCheckBoxChange(event)),
  handleExtensionColorChange: (extensionLineColor) => dispatch(handleExtensionColorChange(extensionLineColor)),
  handleExtensionLineWeightChange: (extensionLineWeight) => dispatch(handleExtensionLineWeightChange(extensionLineWeight))
});

export default connect(mapStateToProps, mapDispatchToProps)(RIPanelFibonacciInfo);