import Serializable from "Serializable";
import PeriodicityHelper from "../../../../../Utils/PeriodicityHelper.js";
import GraphType from 'GraphType';

class NupChartSettings extends Serializable {
    constructor() {
        super();
    }

    getObjectMapKey() {
        return "NupChartSettings";
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.showChart = serializedValue.showChart ? serializedValue.showChart : false;
        this.selected = serializedValue.selected > -1 ? serializedValue.selected : 1;
        this.epsSelected = serializedValue.epsSelected ? serializedValue.epsSelected : this.getDefaultEpsRpsSelected();
        this.graphType = serializedValue.graphType ? serializedValue.graphType : 2;
        this.PeriodicityOptions = serializedValue.PeriodicityOptions ? serializedValue.PeriodicityOptions : PeriodicityHelper.getDefaultPeriodcityButtonOptions();
        this.setOptionsForPeriodicity();
        this.nupCharts = serializedValue.nupCharts ? serializedValue.nupCharts : this.getDefaultNupCharts();
        this.scaleByPeriodicity = serializedValue.scaleByPeriodicity ? serializedValue.scaleByPeriodicity : this.getDefaultScaleByPeriodicity();
        this.dimension = serializedValue.dimension ? serializedValue.dimension : this.getDefaultDimension();
    }

    getDefaultNupCharts(){
        const nupCharts = [
            { lyOut: "2 x 2", sel: "icn", per: [], lyUse: "nvc-2X2" },
            { lyOut: "2 x 2", sel: "icn-select", per: [1,2], lyUse: "nvc-2X2" },
            { lyOut: "2 x 3", sel: "icn", per: [], lyUse: "nvc-2X3" },
            { lyOut: "2 x 3", sel: "icn", per: [1,2], lyUse: "nvc-2X3" },
            { lyOut: "3 x 2", sel: "icn", per: [], lyUse: "nvc-3X2" },
            { lyOut: "3 x 2", sel: "icn", per: [1,2,3], lyUse: "nvc-3X2" },
            { lyOut: "3 x 3", sel: "icn", per: [], lyUse: "nvc-3X3" },
            { lyOut: "3 x 3", sel: "icn", per: [1,2,3], lyUse: "nvc-3X3" },
            { lyOut: "4 x 4", sel: "icn", per: [], lyUse: "nvc-4X4" },
            { lyOut: "4 x 4", sel: "icn", per: [1,2,3,12], lyUse: "nvc-4X4" }
        ]

        return nupCharts.map((item)=> Object.assign(new Serializable, {...item}));
    }

    getDefaultScaleByPeriodicity(){
        const scaleByPeriodicity = [
            { periodicity: GraphType.Daily, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
            { periodicity: GraphType.Weekly, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
            { periodicity: GraphType.Monthly, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
            { periodicity: GraphType.Quarterly, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
            { periodicity: GraphType.Annual, scale: "FixedLogScale", scaleLabel: "LOG (F)" },
            { periodicity: GraphType.Intraday, scale: "LogScale", scaleLabel: "LOG (A)" }
        ]

        return scaleByPeriodicity.map((item)=> Object.assign(new Serializable, {...item}));
    }

    getDefaultEpsRpsSelected(){
        const epsRpsSelected = new Serializable();
        epsRpsSelected.Intraday = true;
        epsRpsSelected.Daily = true;
        epsRpsSelected.Weekly = true;
        epsRpsSelected.Monthly = true;
        epsRpsSelected.Quarterly = true;
        epsRpsSelected.Annual = true;

        return epsRpsSelected;
    }

    getDefaultDimension(){

        const dimensions = [{ height: 180, width: 728},
                            { height: 174, width: 728},
                            { height: 98, width: 728},
                            { height: 91, width: 728},
                            { height: 223, width: 728},
                            { height: 213, width: 728},
                            { height: 137, width: 728},
                            { height: 130, width: 728},
                            { height: 93, width: 728},
                            { height: 88, width: 728}];
        return dimensions.map((item)=> Object.assign(new Serializable, {...item}));
    }

    setOptionsForPeriodicity() {
        this.PeriodicityOptions.Button1.options = this.getOptions(this.PeriodicityOptions.Button1);
        this.PeriodicityOptions.Button2.options = this.getOptions(this.PeriodicityOptions.Button2);
        this.PeriodicityOptions.Button3.options = this.getOptions(this.PeriodicityOptions.Button3);
        this.PeriodicityOptions.Button4.options = this.getOptions(this.PeriodicityOptions.Button4);
    }

    getOptions(buttonobject) {
        const menu = [];
        const periodicityHeaders = PeriodicityHelper.getValidPeriodicitiesHeader();
        for (let header in periodicityHeaders) {
            if (periodicityHeaders.hasOwnProperty(header)) {
                const isActive = header === buttonobject.Periodicity;
                menu.push({
                    header: header,
                    key: header,
                    isActive: isActive,
                    buttonObject: buttonobject
                });
            }
        }
        return menu;
    }

    getDataObject() {
        return {
            showChart: this.showChart,
            selected: this.selected,
            epsSelected: this.epsSelected,
            graphType: this.graphType,
            PeriodicityOptions: this.PeriodicityOptions,
            nupCharts: this.nupCharts,
            scaleByPeriodicity: this.scaleByPeriodicity,
            dimension: this.dimension,
        };
    }
    
    getDefaultNupChartSettings() {
        this.showChart = false;
        this.selected = 1;
        this.epsSelected = this.getDefaultEpsRpsSelected();
        this.graphType = 2;
        this.PeriodicityOptions = PeriodicityHelper.getDefaultPeriodcityButtonOptions();
        this.nupCharts = this.getDefaultNupCharts();
        this.scaleByPeriodicity = this.getDefaultScaleByPeriodicity();
        this.dimension = this.getDefaultDimension();
        return this;
    }

}

export default NupChartSettings;