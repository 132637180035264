import React, { Component } from "react";
import MenuCommonDialogActions from "../../Actions/MenuCommonDialogActions.js";
import MenuDialogType from "../../Constants/MenuDialogType.js";
//import UserInfoUtil from "UserInfoUtil"
import BaseServiceApi from 'BaseServiceApi';
import CommonDialog from "../../RayCustomControls/CommonDialog/CommonDialog"
import DDEConversionSettingContent from "../../RayCustomControls/Preferences/DDEConversionSettingContent";
import PreferencesContent from "../../RayCustomControls/Preferences/PreferencesContent";
import PreferenceDialogStore  from "../../Stores/Preference/PreferenceDialogStore"
import AppearanceContent from "../../RayCustomControls/Preferences/AppearanceContent";
import ReportedEarningsContent from "../../RayCustomControls/Preferences/ReportedEarningsContent.jsx";
import NotificationContent from "../../RayCustomControls/Preferences/NotificationContent";
import LocalizationStore from 'LocalizationStore';
import { updateNasdaqBasic } from "../../Actions/NupViewActions.js";
import { dispatch } from "../../Redux/dispatch.js";
import CurrencyContent from '../../RayCustomControls/Preferences/CurrencyContent'

//const EntitlementType = BaseServiceApi.rayData["EntitlementType"];
export default class Preference extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            menuDialogType: '',
            quoteServiceDataSource: {},
            title: "",
            preClass: "",
            selectOption: null,
            backButtonShow: false,
            parentEventKey: false
        };
        this.dialogStateChange = this.dialogStateChange.bind(this);
        this.setSelectOption = this.setSelectOption.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.handleAppearanceChange = this.handleAppearanceChange.bind(this);
        this.handleNotificationChange = this.handleNotificationChange.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleReportedEarningsChange = this.handleReportedEarningsChange.bind(this);
        this.state = this.getDialogState();
    }

    componentDidMount() {
        PreferenceDialogStore.addChangeListener(this.dialogStateChange);
    }

    componentWillUnmount() {
        PreferenceDialogStore.removeChangeListener(this.dialogStateChange);
    }

    getDialogState(){
        let dialogState = PreferenceDialogStore.getState();
        let st = {
            isOpen: dialogState.isOpen,
            menuDialogType: dialogState.eventKey,
            SelectQuoteServiceOption: dialogState.SelectQuoteServiceOption
        };
        switch (dialogState.eventKey) {
            case MenuDialogType.QuoteService:
                st.parentEventKey = MenuDialogType.Preferences;
                st.title = LocalizationStore.getTranslatedData('set_pre_qs_title', "QUOTE SERVICE");
                st.preClass = "quote-service";
                st.backButtonShow = true;
                st.quoteServiceDataSource = dialogState.results;
                break;
            case MenuDialogType.Preferences:
                st.parentEventKey = MenuDialogType.Preferences;
                st.title = LocalizationStore.getTranslatedData('set_pre_title', 'PREFERENCES');
                st.preClass = "preferences";
                st.backButtonShow = false;
                break;
            case MenuDialogType.Appearance:
                st.parentEventKey = MenuDialogType.Preferences;
                st.title = LocalizationStore.getTranslatedData('set_pre_aprn_title', "APPEARANCE");
                st.preClass = "appearance";
                st.backButtonShow = true;
                break;
            case MenuDialogType.Notification:
                st.parentEventKey = MenuDialogType.Preferences;
                st.title = LocalizationStore.getTranslatedData('set_pre_nap', "NOTIFICATION & ALERT PREFERENCES");
                st.preClass = "Notification";
                st.backButtonShow = true;
                break;
            case MenuDialogType.ReportedEarnings:
                st.parentEventKey = MenuDialogType.Preferences;
                st.title = LocalizationStore.getTranslatedData('set_pre_re_title', "REVENUE & EARNINGS");
                st.preClass = "reported-earnings";
                st.backButtonShow = true;
                break;
            case MenuDialogType.Currency:
                st.parentEventKey = MenuDialogType.Preferences;
                st.title = 'CURRENCY CONVERSION';
                st.preClass = "Currency";
                st.backButtonShow = true;
                break;
           default:
                st.parentEventKey = MenuDialogType.Preferences;
                st.title = LocalizationStore.getTranslatedData('set_pre_qs_title', "QUOTE SERVICE");
                st.preClass = "quote-service";
                st.backButtonShow = true;
        }
        return st;
    }

    dialogStateChange() {
        this.setState(this.getDialogState());
    }

    handleChange() {
        //let entitlements = UserInfoUtil.getUserInfo().entitlements;
        //if (entitlements.includes(EntitlementType.NASDAQ_BASIC_WEB_Entitlement)) {
            MenuCommonDialogActions.getQuoteService({
                appversion: 4.2,
                isNasdaqentitled: true,
                isBatsentitled: true,
                eventKey: MenuDialogType.QuoteService
            });
        //}
    }

    handleAppearanceChange(){
       MenuCommonDialogActions.openApperanceDialog({eventKey: MenuDialogType.Appearance});
    }
     handleNotificationChange(){
       MenuCommonDialogActions.openNotificationDialog({eventKey: MenuDialogType.Notification,showbackButton:true});
    }
    handleReportedEarningsChange(){
        MenuCommonDialogActions.openReportedEarningsDialog({eventKey: MenuDialogType.ReportedEarnings});
    }
    handleCurrencyChange(){
        MenuCommonDialogActions.openCurrencyDialog({eventKey: MenuDialogType.Currency});
    }

    hideModal() {
        this.setState({isOpen: false});
    }

    setSelectOption(option) {
        if (!isNaN(option)) {
            MenuCommonDialogActions.setSelectQuoteServiceOption(option);
            dispatch(updateNasdaqBasic(option));
        }
    }

    getModalContentView() {
        let menuDialog = <div />;
        switch (this.state.menuDialogType) {
            case MenuDialogType.QuoteService:
                menuDialog = <DDEConversionSettingContent
                    DataSource={this.state.quoteServiceDataSource}
                    onSelected={this.setSelectOption}
                    SelectedDDeConnectType={this.state.SelectQuoteServiceOption}
                    DdeStatus={this.state.DdeStatus}
                    BrushColor={this.state.BrushColor}/>;
                break;
            case MenuDialogType.Preferences:
                menuDialog = <PreferencesContent
                    handleChange={this.handleChange}
                    handleAppearanceChange={this.handleAppearanceChange}
                    handleNotificationChange={this.handleNotificationChange}
                    handleCurrencyChange={this.handleCurrencyChange}
                    handleReportedEarningsChange={this.handleReportedEarningsChange}/>;
                break;
            case MenuDialogType.Appearance:
                menuDialog = <AppearanceContent />;
                break;
            case MenuDialogType.Notification:
                menuDialog = <NotificationContent />;
                break;
            case MenuDialogType.ReportedEarnings:
                menuDialog = <ReportedEarningsContent/>;
                break;
            case MenuDialogType.Currency:
                menuDialog = <CurrencyContent/>
                break;
            default:
        }
        return menuDialog;
    }

    render() {
        let contentModel = this.getModalContentView();
        return (
            <CommonDialog preClass={this.state.preClass}
                          isOpen={this.state.isOpen}
                          title={this.state.title}
                          hideModal={this.hideModal}
                          backButtonShow={this.state.backButtonShow}
                          parentEventKey={this.state.parentEventKey}>
                {contentModel}
            </CommonDialog>
        );
    }
}
