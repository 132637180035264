import { connect } from 'react-redux';
import HubCollapseItem from './HubCollapseItem';
import HubVirtualList from "./HubVirtualList";
import React from "react";
import { toggleSalesRep } from "../../../../../../Actions/RelatedInfoPanelActions/RiPanelActions";
import { TranslateHelper } from "../../../../../../Utils/TranslateHelper";

class HubSalesReps extends React.Component {

    constructor(props) {
        super(props);
        this.getCollapseList = this.getCollapseList.bind(this);
        this.getItemRenderer = this.getItemRenderer.bind(this);
        this.onItemClicked = this.onItemClicked.bind(this)
    }

    onItemClicked(index) {
        this.props.toggleSalesRep(index)
    }

    getItemRenderer(item, top, index) {
        return <HubCollapseItem key={index ? index : 0}
            item={item}
            index={index}
            showStar={item.isRecommended}
            top={top}
            onItemClicked={this.onItemClicked}
            manager={this.props.manager} />
    }

    getCollapseList() {
        const salesReps = this.props.HubDatasource;
        return <div className="hubInfo">
            {salesReps.length === 0 ?
                <div className="news-message large-normal"><p>{TranslateHelper["No_Data_Available"]}</p></div> :
                <div className="hub-list" >
                    <HubVirtualList id="RIHubPanelInfo" items={salesReps}
                        itemHeight={this.props.itemHeight}
                        itemRenderer={(item, top, index) => this.getItemRenderer(item, top, index)}
                    />
                </div>
            }
        </div>
    }

    render() {
        return (
            <React.Fragment>
                {this.getCollapseList()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    const { HubDatasource } = state.RelatedInfoPanelReducers.RiPanelHub;
    return { HubDatasource }
}

const mapDispatchToProps = (dispatch) => ({
    toggleSalesRep: (index) => dispatch(toggleSalesRep(index))
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(HubSalesReps)