import _ from "underscore";
import { connect } from "react-redux";
import CountryBox from "./CountryBox";
import LocalizationStore from "LocalizationStore";
import { Modal } from "react-bootstrap";
import ScrollBar from "ScrollBar";
import StringUtil from "StringUtil";
import { closeFilterDialog, updateBrowserFilter } from "../../../../Actions/AlertActions";
import React, { Component } from "react";
import { AlertTranslateHelper } from "../../../../Utils/TranslateHelper";

class GeographyAlertDialog extends Component {
    constructor(props) {
        super(props);

        const dimension = document.getElementById("modalBody").getBoundingClientRect();
        this.state = {
            left: dimension.left + 69,
            top: dimension.top + 15
        }

        // this.alertStoreStateChange = this.alertStoreStateChange.bind(this);
        this.getIndex = this.getIndex.bind(this);
        this.RowClick = this.RowClick.bind(this);
        this.toggleRow = this.toggleRow.bind(this);
        this.selectRowsBetweenIndexes = this.selectRowsBetweenIndexes.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.setSelection = this.setSelection.bind(this);
        this.handleMouseDownGeographyModal = this.handleMouseDownGeographyModal.bind(this);
        this.handleMouseUpGeographyModal = this.handleMouseUpGeographyModal.bind(this);
        this.handleAlertGeographyOutsideClick = this.handleAlertGeographyOutsideClick.bind(this);

        this.lis = null;
        this.lastSelectedRow;

        this.mouseDownOnGeographyModal = false;
        this.mouseUpOnGeographytModal = false;

    }

    componentDidMount() {

        this.lis = [];
        setTimeout(() => {
            if (this.multiFilterListCountry) {
                this.lis = this.multiFilterListCountry.querySelectorAll('li')
                this.allRows = this.multiFilterListCountry.querySelectorAll('li')
            }
        }, 0)
        document.addEventListener('click', this.handleAlertGeographyOutsideClick, false);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleAlertGeographyOutsideClick, false);
    }

    handleAlertGeographyOutsideClick(e) {
        const wasDown = this.mouseDownOnGeographyModal;
        const wasUp = this.mouseUpOnGeographytModal;
        this.mouseDownOnGeographyModal = false;
        this.mouseUpOnGeographytModal = false;

        if (!wasDown && !wasUp) {
            this.props.closeFilterDialog();
        }

        e.stopPropagation();
    }

    handleMouseDownGeographyModal() {
        this.mouseDownOnGeographyModal = true;
    }

    handleMouseUpGeographyModal() {
        this.mouseUpOnGeographytModal = true;
    }

    //Find Index of Li from Li List
    getIndex(array, element) {
        for (let i = 0; i < array.length; i++) {
            if (array[i] === element){ 
                return i;
            }
        }
        return -1;
    }

    RowClick(event, currenttr) {
        if (event.ctrlKey || event.metaKey) {
            this.toggleRow(currenttr);
        }

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey && !event.metaKey) {
                this.clearAll();
                this.toggleRow(currenttr);
            }

            if (event.shiftKey) {
                this.setSelectedRow(null, currenttr);
                this.selectRowsBetweenIndexes([this.getIndex(this.allRows, this.lastSelectedRow), this.getIndex(this.allRows, currenttr)]);
            }
        }
        this.setSelection();
    }

    setSelectedRow(row, currenttr) {
        if (row !== null){
            this.lastSelectedRow = row;
        }
        else {
            if (this.multiFilterListCountry) {
                const withActive = [];
                if (this.lis.children) {
                    for (let i = 0; i < this.lis.children.length; i++) {
                        if (StringUtil.hasClass(this.lis[i], 'active')){
                            withActive.push(i);
                        }
                    }
                }
                else {
                    for (let i = 0; i < this.lis.length; i++) {
                        if (StringUtil.hasClass(this.lis[i], 'active')) {
                            withActive.push(i);
                        }
                    }
                }
                const indexCurrenttr = this.getIndex(this.allRows, currenttr);
                if ((withActive[0] < indexCurrenttr) || indexCurrenttr > withActive.length - 1) {
                    this.lastSelectedRow = this.lis[withActive[0]];
                }
                else if (indexCurrenttr < withActive.length - 1) {
                    this.lastSelectedRow = this.lis[withActive[withActive.length - 1]];
                }
                else {
                    this.lastSelectedRow = this.lis[withActive[0]];
                }
            }
        }
    }

    setSelection() {
        this.lis[0].classList.remove('active');
        const selection = [];
        const filterNames = [];
        for (let i = 0; i < this.lis.length; i++) {
            if (this.lis[i].id === 'RegionLabel') {
                this.lis[i].classList.remove('active');
            }
            if (StringUtil.hasClass(this.lis[i], 'active') && this.lis[i].id !== 'RegionLabel') {
                selection.push(this.lis[i].getAttribute('data-nodefilterid'));
                filterNames.push(this.lis[i].getAttribute('data-nodefiltername'));
            }
        }
        let filterName = '';

        const filterNameCount = (filterNames.length > 0) ? filterNames.length - 1 : 0;
        //parentName = this.multiFilterListCountry.getAttribute('label');
        filterName = selection.length > 1 && filterNames[0] !== 'All' ?
            `${LocalizationStore.getTranslatedData(`Country_${filterNames[0].replace(/ /ig, "")}`, filterNames[0])}[+${filterNameCount} ${AlertTranslateHelper.MORE}]`
            : filterNames[0] !== 'All' && selection.length >= 1 ?
                LocalizationStore.getTranslatedData(`Country_${filterNames[0].replace(/ /ig, "")}`, filterNames[0])
                : LocalizationStore.getTranslatedData("FilterBrowser_Country_-1", filterNames[0]);
        let nodeFilterId = '';
        if (selection.length === 0) {
            this.lis[0].classList.add('active');
            nodeFilterId = -1;
        }
        _.each(selection, (selectItem, i) => {
            if (selectItem !== -1) {
                if (i === 0 || nodeFilterId === '') {
                    nodeFilterId += selectItem;
                }
                else {
                    nodeFilterId += `,${selectItem}`;
                }
            }
            else if (selectItem === -1 && selection.length === 1) {
                this.lis[0].classList.add('active');
                nodeFilterId = -1;
            }
            else {
                return;
            }
        });
        this.props.updateBrowserFilter(nodeFilterId.toString(), filterName);
    }

    toggleRow(row) {
        row.classList.toggle('active');
        if (row.classList.contains('active')){
            this.lastSelectedRow = row;
        }
        else{
            this.lastSelectedRow = undefined;
        }
    }

    selectRowsBetweenIndexes(indexes) {
        indexes.sort((a, b) => a - b);
        this.clearAll();
        for (let i = indexes[0]; i <= indexes[1]; i++) {
            this.lis[i].classList.add('active');
        }
    }

    clearAll() {
        for (let i = 0; i < this.lis.length; i++) {
            if (this.lis[i].classList.contains('active')) {
                this.lis[i].classList.remove('active');
            }
        }
    }

    createCountryItemsBox(Countrys) {

        return _.map(Countrys, this.createCountryBoxItem, this);
    }

    createCountryBoxItem(CountrysboxItem, key) {

        const self = this;
        return <CountryBox source={CountrysboxItem} region={key} key={key} RowClick={self.RowClick} existAlerts={this.props.existAlerts} />;
    }

    /* ---- Update Country Region ----------- */

    setCountryRegion() {

        const sortedCountries = _.sortBy(this.props.countryCollection, 'regionName');
        const countries = _.groupBy(sortedCountries, 'regionName');

        _.each(countries, (c) => {
            let count = 0;
            for (let i = 0; i < c.length; i++) {
                count += c[i].count;
            }
            c["count"] = count;
        });
        return countries;
    }

    render() {
        const countries = this.setCountryRegion();

        return (
            <div id="geographyAlertDialog">
                <Modal className="modal-popup geography-alert-dialog alert-geography slide-down" show={true} style={{ left: this.state.left, top: this.state.top }}>
                    <Modal.Header>
                        <Modal.Title onMouseDown={this.handleMouseDownGeographyModal} onMouseUp={this.handleMouseUpGeographyModal}>
                            <span className="cap-header">{AlertTranslateHelper.SELECT_COUNTRIES}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body onMouseDown={this.handleMouseDownGeographyModal} onMouseUp={this.handleMouseUpGeographyModal}>
                        <div className="modal-area column-geo-alert custom-scroll-light">
                            <div className="custom-scroll">
                                <div id="gergraph-block">
                                    <ul className="geograph-main-block-ul" ref={(ul) => this.multiFilterListCountry = ul}>
                                        <li className="geograph-main-block-li" >
                                            {this.createCountryItemsBox(countries)}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ScrollBar scrollId="gergraph-block" vScroll={true} />
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = ({ alertReducer })=>{
    const { countryCollection } = alertReducer.AlertDialogReducer;
    return { countryCollection };
}

const mapDispatchToProps = (dispatch)=>({
    closeFilterDialog: ()=> dispatch(closeFilterDialog()),
    updateBrowserFilter: (nodeFilterId, filterName)=> dispatch(updateBrowserFilter(nodeFilterId, filterName))
})

export default connect(mapStateToProps, mapDispatchToProps)(GeographyAlertDialog);
