import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import BrowserUtil from 'BrowserUtil';
class Refresh extends PureComponent {
    render() {
        const { onClick, isLoading } = this.props;
        isLoading ? BrowserUtil.disableEvents() : BrowserUtil.enableEvents();
        return (
            <div className="head-left-rotation">
                <a id="refresh" className={`icn-refresh ${isLoading ? 'active' : ''}`} onClick={onClick}></a>
            </div>
        );
    }
}

Refresh.propTypes = {
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool

}

export default Refresh;