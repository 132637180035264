import Serializable from "Serializable";
import RiSubCategoryType from "../../../../../../Constants/RiSubCategoryType.js";
import AnnotationSettings from "../AnnotationSettings.js";

class RiPanelCryptoCurrencyViewSettings extends Serializable {

    getDefaulSettings() {
        this.ActiveSelectedSubCategory = RiSubCategoryType.Alerts;
        this.AnnotationSettings = this.getDefaultAnnotationSettings();
        return this;
    }

    onSeralisedInfoRecieved(serializedValue) {
        this.ActiveSelectedSubCategory = serializedValue.ActiveSelectedSubCategory ? serializedValue.ActiveSelectedSubCategory : RiSubCategoryType.Alerts;
        this.AnnotationSettings = serializedValue.AnnotationSettings ? serializedValue.AnnotationSettings : this.getDefaultAnnotationSettings();
    }

    getObjectMapKey() {
        return "RiPanelCryptoCurrencyViewSettings";
    }

    getDataObject() {
        return {
            ActiveSelectedSubCategory: this.ActiveSelectedSubCategory,
            AnnotationSettings: this.AnnotationSettings
        };
    }

    getDefaultAnnotationSettings() {
        const annotationSettings = new AnnotationSettings();
        return annotationSettings;
    }

}


export default RiPanelCryptoCurrencyViewSettings;