import _ from "underscore";
import { useSelector } from 'react-redux';
import React, { useState, useRef, useEffect } from "react";

const HubIndex = () => {

    const data = useSelector((state) => state.RelatedInfoPanelReducers.RiPanelHub);
    const items = data.indexItems;
    const { indexCodes } = data;

    const [state, setState] = useState({ show: false });

    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClick])

    const handleClick = () => {
        setState({ show: true });
    }

    const handleClickOutside = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setState({ show: false })
        }
    }

    const getUserEntitlementIndices = (data) => {
        const newData = _.filter(data, (item) => indexCodes.indexOf(item.indexCode) > -1);
        return newData;
    }

    const getList = (filterItems) => {
        const result = [];
        result.push(<div key={-1} className="item-header-container">
            <div>MEMBER OF</div><div>WEIGHT</div>
        </div>
        );
        filterItems.map((item, index) => {
            const weight = item.marketValOrWeight > 0 ? (item.marketValOrWeight * 100).toFixed(2) : (-1 * (item.marketValOrWeight * 100)).toFixed(2);
            result.push(<div key={index} className="item-container"><div title={item.name} className="index-name">{item.name}</div><div>{weight}%</div></div>);
        })

        return result
    }

    const { show } = state;
    const visible = Boolean(show) ? "flex" : "none";
    const filterItems = Boolean(items) ? getUserEntitlementIndices(items) : [];
    //console.log("items", items);
    //console.log("filterItems", filterItems);

    return (
        <>
            <div ref={ref}>
                <a onClick={handleClick}>Indexes</a>
                <div className="index-container" style={{ display: visible }}>
                    {
                        !Boolean(filterItems) || filterItems.length === 0 ? <span>No Index data available.</span>
                            : <div className="list-container">{getList(filterItems)}</div>
                    }

                </div>
            </div>
        </>
    )
}
export default HubIndex;