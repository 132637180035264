import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';
import { PrintMode } from '../../print/printmode';

class UserInitRequest extends BaseServiceRequest {

  constructor() {
    super();
    super.addRequestUri("init3.pb");

    if (PrintMode.printing && PrintMode.workspaceId) {
      super.addRequestParameter('workspaceid', PrintMode.workspaceId);
    }
  }

  getMethod() {
    return HttpMethodType.GET;
  }

  getSubSystem() {
    return SubSystemType.USER;
  }

  getMessageName() {
    return "UserInitResponse";
  }
}

export default UserInitRequest;
