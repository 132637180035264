import { connect } from 'react-redux';
import { DialogHOC } from './DialogHOC';
import { TranslateHelper } from '../../../../../../../Utils/TranslateHelper';
import { cancelCorpEventDialogSetting, changeCorpEventSelectedPeriodicity, restoreCorpEventSetting, saveCorpEventSetting, toggleCorpEventProperty } from '../../../../../../../Actions/PricePanelActions';
import { Modal, Tabs, Tab, Checkbox } from "react-bootstrap";
import React, { Component } from "react";

class CorporateEventsDialog extends Component {

    getTabItemRender(tabitem) {
        return (
        <Tab className="tabItem" key={tabitem.periodicity} eventKey={tabitem.periodicity} title={tabitem.header}>
            <div className="oneil-rate-check">
                <ul className="ti-indicator-setting">{tabitem.subItems.map((subitem, index) => this.getSubItemRender(subitem, index))}</ul>
                
                <div className="rating-default"><a className="link-restore" onClick={this.props.restoreCorpEventSetting}>{TranslateHelper.Restore_Defaults}</a></div>
            </div>
        </Tab>
        );
    }
    getSubItemRender(subitem, index) {
        return (
            <li key={index}>
                <Checkbox className="custom-check" type='checkbox' name='corp' id={subitem.text} checked={subitem.value} value={subitem.text} onChange={this.props.toggleCorpEventProperty.bind(this, subitem.property)} >
                    <label className="oneil-rate-label"></label>
                    <span className="oneil-rate-txt">{subitem.text}</span>
                </Checkbox>
            </li>
        );
    }
    render() {
        return (
            <Modal.Body>
                <div className="oneil-rating-box">
                    <Tabs onSelect={this.props.changeCorpEventSelectedPeriodicity} activeKey={this.props.selectedPeriodicity} id="controlled-tab-example"> {this.props.corpEventSettings.map((tabitem) => this.getTabItemRender(tabitem))}
                    </Tabs>
                </div>
            </Modal.Body>
        );
    }
}
const mapStateToProps = ({ DatagraphReducers })=>{
    const { CorpEventsReducer } = DatagraphReducers;
    return {
        showSettingDialog: CorpEventsReducer.isCorpEventDialogOpen,
        corpEventSettings: CorpEventsReducer.corpEventSettings,
        selectedPeriodicity: CorpEventsReducer.selectedPeriodicity,
        HeaderText: CorpEventsReducer.CorpEventHeaderText,
        headerClass: "ti-indicators corporate-events"
    }
}

const mapDispatchToProps = (dispatch) =>({
    toggleCorpEventProperty: (property)=> dispatch(toggleCorpEventProperty(property)),
    saveLineDialog: ()=> dispatch(saveCorpEventSetting()),
    cancelLineDialog: ()=> dispatch(cancelCorpEventDialogSetting()),
    restoreCorpEventSetting: ()=> dispatch(restoreCorpEventSetting()),
    changeCorpEventSelectedPeriodicity: (selectedPeriodicity)=> dispatch(changeCorpEventSelectedPeriodicity(selectedPeriodicity))
})
export default connect(mapStateToProps, mapDispatchToProps)(DialogHOC(CorporateEventsDialog));
