import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from "react-onclickoutside";
import PortalContainer from 'PortalContainer';

class ContextMenu extends PureComponent {
    componentDidMount() {
        if (this.props.component !== 'rotationgraph') {
            const rect = this.contextMenu.getBoundingClientRect();
            if (rect.left + rect.width > window.innerWidth) {
                this.contextMenu.style.left = (window.innerWidth - rect.width) + "px";
            }
            if (rect.top + rect.height > window.innerHeight) {
                this.contextMenu.style.top = (rect.top - rect.height) + "px";
            }
        }
    }
    handleClickOutside(e) {
        this.props.outsideClickDocument(e);
    }
    render() {
        const { children, style } = this.props;

        return (
            <PortalContainer>
                <div ref={(ref) => this.contextMenu = ref} className="comp-index-dropdown" style={style}>
                    {children}
                </div>
            </PortalContainer>
        );
    }
}

ContextMenu.propTypes = {
    style: PropTypes.object,
    outsideClickDocument: PropTypes.func.isRequired
}

export default onClickOutside(ContextMenu);
