import PropTypes from 'prop-types';
import React from "react";
import ChartVisual from "./ChartVisual.jsx";
import TabDataGraphActionClass from "../../../Actions/TabDataGraphAction.js";
import AnnotationUtil from '../../../Utils/AnnotationUtil.js';
import { showContextMenu, updateContextHit } from '../../../Actions/PricePanelActions.js';
import { dispatch } from '../../../Redux/dispatch.js';

export default class HorizontalLineVisual extends ChartVisual {

    constructor(props) {
        super(props);
        this.onCanvasMouseMove = this.onCanvasMouseMove.bind(this);
        this.onCanvasMouseLeve = this.onCanvasMouseLeve.bind(this);
        this.onContextMenuClick = this.onContextMenuClick.bind(this);
        this.handleRightClick = this.handleRightClick.bind(this);
        this.RayChartContainer = undefined;
        this.state = {
            x1: props.x1,
            x2: props.x2,
            y1: props.y1,
            y2: props.y2,
            lineColor: props.lineColor,
            lineThickness: props.LineThickness,
            dashArray: props.dashArray,
            screenX: 0,
            screenY: 0,
            showBox: false,
            lineID: props.lineID ? props.lineID : ''
            //        className:props.className
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.x2)
            this.setState({ x2: nextProps.x2 });
        if (nextProps.y1)
            this.setState({ y1: nextProps.y1 });
        if (nextProps.y2)
            this.setState({ y2: nextProps.y2 });
        if (nextProps.lineColor) {
            this.setState({ lineColor: nextProps.lineColor });
        }
        if (nextProps.LineThickness) {
            this.setState({ lineThickness: nextProps.LineThickness });
        }
        if (nextProps.lineID) {
            this.state.lineID = nextProps.lineID;
        }
    }
    UNSAFE_componentWillUpdate() {
        if (this.main && !this.eventsAttached) {
            this.RayChartContainer = this.getClosestParent(this.main, "rayChartContainer");
            if (this.props.isPrintMode) {
                return;
            }
            this.RayChartContainer.addEventListener("mousemove", this.onCanvasMouseMove, false);
            this.RayChartContainer.addEventListener("mouseleave", this.onCanvasMouseLeve, false);
            this.RayChartContainer.addEventListener("mousedown", this.handleRightClick, false);
            this.eventsAttached = true;
        }
    }

    componentWillUnmount() {
        if (this.props.isPrintMode) {
            return;
        }
        if (this.RayChartContainer) {
            this.RayChartContainer.removeEventListener("mousemove", this.onCanvasMouseMove, false);
            this.RayChartContainer.removeEventListener("mouseleave", this.onCanvasMouseLeve, false);
            this.RayChartContainer.removeEventListener("mousedown", this.handleRightClick, false);
        }
        clearTimeout(this.mouseMoveTimeout);
    }

    componentDidMount(){
        this.setState({})
        if(this.RayChartContainer && !this.eventsAttached){
            this.RayChartContainer.addEventListener("mousedown", this.handleRightClick, false);
            this.eventsAttached = true;
        }
    }
    getState() {
        let state = {};
        let dimensions = this.mainCanvas.getBoundingClientRect();
        state.x1 = !this.state.x1 ? 0.0 : this.state.x1;
        state.x2 = !this.state.x2 ? dimensions.width : this.state.x2;
        state.y1 = !this.state.y1 ? dimensions.height / 2 : this.state.y1;
        state.y2 = !this.state.y2 ? dimensions.height / 2 : this.state.y2;
        state.lineColor = !this.state.lineColor ? "#FF0000" : this.state.lineColor;
        state.lineThickness = !this.state.lineThickness ? 1 : this.state.lineThickness,
            state.dashArray = !this.state.dashArray ? [0, 0] : this.state.dashArray;
        state.lineID = this.state.lineID;
        return state;
    }
    onCanvasMouseLeve() {
        setTimeout(() => {
            this.setState({ showBox: false, showInfoPointer: false, screenX: -1, screenY: -1 });
        }, 0);
    }
    onCanvasMouseMove(e) {
        clearTimeout(this.mouseMoveTimeout);
        if(AnnotationUtil.isAnnotationsTabActive() && !AnnotationUtil.isPointerSelected) return;
        this.mouseMoveTimeout = setTimeout(() => {
            if (this.mainCanvas) {
                const dimensions = this.mainCanvas.getBoundingClientRect();
                const chartHeight = dimensions.bottom;
                let xAxis = parseInt(e.clientX - dimensions.left);
                let yAxis = parseInt(e.clientY - dimensions.top);
                const isHit = this.hitTest(xAxis, yAxis);
                const state = this.getState();
                if (!e.showPointer && isHit && state.lineID && state.lineID.length > 0) {
                    const len = state.lineID.length * 6;
                    xAxis = xAxis - len;
                    if (xAxis < len) xAxis = xAxis + len;
                    if (chartHeight - yAxis < 60) yAxis = yAxis - 60;
                    if (this.props.isToolTip) {
                        this.setState({
                            showBox: true,
                            screenX: xAxis,
                            screenY: yAxis,
                            showInfoPointer: true
                        });
                    }
                    e.showPointer = true;
                    //TabDataGraphActionClass.onLineVisualSelected();
                    if (this.pointer1 && state.maValue > 0) {
                        const contentL = state.maValue.toFixed(2);
                        const yValue = state.ylastPrice - 10;
                        const changeInfo = (state.maValue - state.lastPrice) * 100 / state.lastPrice;
                        const sign = changeInfo > 0 ? "+" : "";
                        const contentR = "(" + sign + changeInfo.toFixed(0) + "%)";
                        this.pointer1.updatePointer(contentL, contentR, yValue, '', '', 0);
                    }
                }
                else {
                    e.showPointer = false;
                    this.setState({ showBox: false, showInfoPointer: false });
                }
            }
        }, 0);
    }

    hitTest(x, y) {
        //Fix for PANWEB - 1068 - Check if any modal open
        const openModals = document.getElementsByClassName('modal-open');
        if (openModals.length >= 1) {
            return false;
        }
        const ishit = this.isPointInStroke(x, y);
        return ishit;
    }
    isPointInStroke(x, y) {
        const state = this.getState();
        return (x >= state.x1 && x <= state.x2 && y - 3 <= state.y1 && y + 3 >= state.y2);
    }

    showInfoBox(showText, screenX, screenY, backgroundColor) {
        const dimensions = this.mainCanvas.getBoundingClientRect();
        const chartHeight = dimensions.height;
        const xLeft = screenX + "px";
        const ytop = screenY > chartHeight - 40 ? (screenY - 20) : (screenY + 20) + "px";
        const height = "20px";
        const width = "auto";
        const textStyle = {
            backgroundColor: backgroundColor,
            borderColor: "#333333",
            borderStyle: "solid",
            borderWidth: "1px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            fontFamily: "calibri",
            color: "White",
            position: "absolute",
            left: xLeft,
            top: ytop,
            height: height,
            width: width,
            zIndex: 10,
            whiteSpace: 'nowrap'
        };
        return (
            <div style={textStyle} key={screenX} >
                <div style={{
                    textAlign: "Center",
                    fontFamily: "calibri",
                    color: "White",
                    marginLeft: '6px',
                    marginRight: '6px',
                }}>
                    {showText}
                </div>
            </div>
        );
    }
    drawLine() {
        if (!this.mainCanvas) {
            return;
        }
        let state = this.getState();
        let rect = this.mainCanvas.getBoundingClientRect();
        let ctx = this.mainCanvas.getContext("2d");
        ctx.canvas.height = rect.height;
        ctx.canvas.width = rect.width;
        ctx.clearRect(0, 0, rect.width, rect.height);
        ctx.strokeStyle = state.lineColor;
        ctx.lineWidth = state.lineThickness;
        if (state.dashArray !== "0, 0") {
            if (state.lineThickness === 1) {
                ctx.translate(0.5, 0.5);
                ctx.setLineDash([6, 3]);
            }
            if (state.lineThickness === 2) {
                ctx.translate(0.5, 0.5);
                ctx.setLineDash([12, 3]);
            }
            if (state.lineThickness === 3)
                ctx.setLineDash([18, 6]);
        }
        ctx.beginPath();
        ctx.moveTo(state.x1, state.y1);
        ctx.lineTo(state.x2, state.y2);
        ctx.stroke();
        ctx.closePath();
    }

    onContextMenuClick(e) {
        e.preventDefault();
        return false;
    }

    handleRightClick(e) {

        if (!this.main || e.button !== 2) return;
    
        const dimensions = this.main.getBoundingClientRect();
        // let chartWidth = dimensions.right;
        // let chartHeight = dimensions.bottom;
        const xAxis = parseInt(e.clientX - dimensions.left);
        const yAxis = parseInt(e.clientY - dimensions.top);
        const isHit = this.hitTest(xAxis, yAxis);

        if (isHit && this.props.updateContextHit) {
          this.props.updateContextHit()
          dispatch(showContextMenu(e, dimensions.top))
          e.preventDefault();
        }
        else if (!isHit) {
          dispatch(updateContextHit({}))
          e.preventDefault();
        }
      }

    render() {
            try {
                this.drawLine();
            }

            catch (e) {
                console.error(e);
            }
            return (
                <div ref={(ref) => this.main = ref}>
                    <canvas className="chartVisual" onContextMenu={this.onContextMenuClick} ref={(ref) => this.mainCanvas = ref} style={{ fitPosition: "fill", width: "100%", height: "100%", position: "absolute" }}>
                    </canvas>
                    {
                        (this.state.showBox && this.props.isToolTip) &&
                        this.showInfoBox(this.state.lineID, this.state.screenX, this.state.screenY, this.state.lineColor)
                    }
                </div>
            );
        }
    }

//  className={this.state.className}

HorizontalLineVisual.props = {
    x1: PropTypes.number,
    x2: PropTypes.number,
    y2: PropTypes.number,
    y1: PropTypes.number,
    lineColor: PropTypes.string,
    LineThickness: PropTypes.number,
    dashArray: PropTypes.string,
    showBox: PropTypes.bool,
    lineID: PropTypes.string,
    isToolTip: PropTypes.bool
    //    className:PropTypes.string.isRequired
};
