
import { AnnotationConstant } from "../../Constants/AnnotationConstants";
const { ActionTypes } = AnnotationConstant;
import { ChannelLineModelInfo } from "../../Models/AnnotationModels/ChannelLineModelInfo";

const channelLines = []

const channelLineReducer = (state = channelLines, action) => {

    switch (action.type) {
        case ActionTypes.INIT_CHANNEL_LINE_COLLECTION:
            return action.LinesCollection;
        case ActionTypes.HANDLE_CHANNEL_LINE_CLICK_SUCCESS:
            return [...state, action.channelLine]
        case ActionTypes.HANDLE_CHANNEL_LINE_DRAW_SUCCESS:
            return state.map((item) => (item.id === action.channelLine.id) ? action.channelLine : item)
        case ActionTypes.HANDLE_CHANNEL_LINE_SAVE_ID_SUCCESS:
            return action.channelLines
        case ActionTypes.HANDLE_CHANNEL_LINE_TRANSFORM_SUCCESS:
            return state.map((item) => (item.id === action.channelLine.id) ? action.channelLine : item)
        case ActionTypes.HANDLE_REMOVE_CHANNEL_LINE_DRAW:
            return state.filter((item) => item.id !== action.annotSelectedId)
        case ActionTypes.REMOVE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new ChannelLineModelInfo, { ...item, isDeleted: true }) : item)
        case ActionTypes.RESTORE_DELETED_ANNOTATIONS:
            return state.map((item) => action.annotDeletedId.includes(item.id) ? Object.assign(new ChannelLineModelInfo, { ...item, isDeleted: false }) : item)
        case ActionTypes.CLEAR_ANNOTATIONS:
            return [];
        default:
            return state
    }
}

export default channelLineReducer;