const BlockType = {
  Insider: "Insider",
  Volume: "Volume",
  Financial: "Financial",
  Ownership: "Ownership",
  RSI: "RSI",
  Stochastics: "Stochastics",
  WonStochastics: "WonStochastics",
  MACD: "MACD",
  AccDist: "AccDist",
  YTD: "YTD",
  YTD1: "YTD1",
  YTD3: "YTD3",
  YTD5: "YTD5",
  YTD10: "YTD10",
  PTOE: "PTOE",
  PTOS: "PTOS",
  EPSR: "EPSR",
  TechIndicator: "TechIndicator",
  KeyPerfomanceIndicator: "KeyPerfomanceIndicator",
  ExternalData: "ExternalData",
  ExternalDataName: "External Data",
  KPI: "KPI",
  Pricechart: "Pricechart"
};

export default BlockType;
