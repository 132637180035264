import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class ListAlertsRetrieveRequest extends BaseServiceRequest {

    constructor(sourceId, sourcetypeid) {
        super();
        super.addRequestUri("listalertsretrieve.pb");
        super.addRequestParameter("sourceId", sourceId);
        super.addRequestParameter("sourceTypeId", sourcetypeid);
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.ALERTS;
    }

    getMessageName() {
        return "ListAlert2ResponseData";
    }
}

export default ListAlertsRetrieveRequest;
