import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class GetFundsViewSummaryBlockRequest extends BaseServiceRequest {
    constructor(msid, symbol, endDate, targetCurrencyCode) {
        super();
        super.addPathParameter(msid.toString());
        super.addRequestParameter("sym", symbol);
        super.addRequestParameter("end", endDate);
        super.addRequestParameter("tgc", targetCurrencyCode);
        super.addRequestUri("fundsumblock.pb");
    }

    getMethod() {
        return HttpMethodType.GET;
    }

    getSubSystem() {
        return SubSystemType.GRAPH;
    }

    getMessageName() {
        return "FundSummaryBlockResponse";
    }
}

export default GetFundsViewSummaryBlockRequest;
