//import GraphType from "../../../../../Constants/GraphType.js";
// import ListApi from "ListApi";
import GraphType from "../../../../../Constants/GraphType.js";
import BlockSize from "../../../../../Constants/BlockSize.js";
import OwnershipClientType from "../../../../../Constants/OwnershipClientType.js";
import OwnershipList from "../../../../../Constants/OwnershipList.js";
import OwnershipView from "../../../../../Constants/OwnershipView.js";
import PanarayDefault from "ServiceApi/RequestHelper/PanarayDefault";
const smallBlockPageSize = 4;
const mediumBlockPage = 7;
const largeBlockSize = 14;
import Serializable from "Serializable";
class OwnershipBlockSettings extends Serializable {


  onSeralisedInfoRecieved(info) {
    this.ListId = info.listId ? info.listId : (PanarayDefault.DefaultOwnershipListId ? PanarayDefault.DefaultOwnershipListId : 820712);    
    this.BlockSize = info.blockSize ? info.blockSize : BlockSize.Medium;
    this.OwnershipListType = info.listType ? info.listType : OwnershipList.Growth;
    this.IsFavList = info.isFavList ? info.isFavList : false;    
    this.IsFlaggedList = info.IsFlaggedList ? info.IsFlaggedList : false;   
    this.OwnershipViewType = info.viewType ? info.viewType : OwnershipView.HoldingRank;
    this.IsVisible = info.isVisible ? info.isVisible : false;
    this.Osid = info.osid ? info.osid : 0;
    this.AumIsVisible = info.isAumVisible ? info.isAumVisible : false; 
    this.CellMode = info.cellMode ? info.cellMode : OwnershipView.HoldingRank;
    this.IsAvailable = info.isAvailable ? info.isAvailable : false;     
    this.ListContentId = info.listContentId ? info.listContentId : 0;
    this.ListSourceId = info.listSourceId ? info.listSourceId : 0;
    this.LeftClient = info.leftClient ? info.leftClient : OwnershipClientType.Owner;
    this.RightClient = info.rightClient ? info.rightClient : OwnershipClientType.Owner;
  }
  getDataObject() {
    return ({
        listId : this.ListId,
        isFavList : this.IsFavList,  
        IsFlaggedList: this.IsFlaggedList,              
        blockSize : this.BlockSize,                
        listType : this.OwnershipListType,
        viewType : this.OwnershipViewType,
        isVisible : this.IsVisible,                
        osid : this.Osid,                          
        isAumVisible : this.AumIsVisible,                
        toggleFund : this.ToggleFund,             
        cellMode : this.CellMode,
        isAvailable: this.IsAvailable,
        listContentId : this.ListContentId,  
        listSourceId : this.ListSourceId,  
        leftClient : this.LeftClient,  
        rightClient : this.RightClient,  
    });

  }
  getObjectMapKey() {

    return "OwnershipBlockSettings";
  } 
  constructor(periodicity) {    
    super();      
    this.IsVisible = false;
    this.ListId = (PanarayDefault.DefaultOwnershipListId ? PanarayDefault.DefaultOwnershipListId : 820712);                                  
    this.ListContentId =0;
    this.ListSourceId = 0;       
    this.IsFavList = false;
    this.IsFlaggedList = false;
    this.LeftClient = OwnershipClientType.Owner;
    this.RightClient = OwnershipClientType.Owner;             
    this.BlockSize = BlockSize.Medium;               
    this.OwnershipListType = OwnershipList.Growth;
    this.OwnershipViewType = OwnershipView.HoldingRank;
    this.Osid  =  0;                     
    this.AumIsVisible =false;
    this.CellMode = OwnershipView.HoldingRank;
    switch (periodicity) {
      case GraphType.Daily:
          this.IsAvailable = true; 
          break;
      case GraphType.Weekly:
            this.IsAvailable = true; 
            break;
      default:
        this.IsAvailable = false;
    }    
    
  }

  UpdateHeaders(currentHeaders) {
    this.Headers = currentHeaders;
  }

  getPageSize(blockSize) {
    this.BlockSize = blockSize;
    switch (this.BlockSize) {
      case BlockSize.Small:
         return smallBlockPageSize;
      case BlockSize.Medium:
        return mediumBlockPage;
      case BlockSize.Large:
        return largeBlockSize;
      default:
        return BlockSize.Small;

    }

  }
  getBlockSize(blockSize) {
    this.BlockSize = blockSize;
    switch (this.BlockSize) {
      case BlockSize.Small:
         return BlockSize.Small;
      case BlockSize.Medium:
        return BlockSize.Medium;
      case BlockSize.Large:
        return BlockSize.Large;
      default:
        return BlockSize.Small;
  
    }

  }
}

export default OwnershipBlockSettings;
