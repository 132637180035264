import HttpMethodType from 'HttpMethodType';
import SubSystemType from 'SubSystemType';
import BaseServiceRequest from 'BaseServiceRequest';

class RemoveColumnSetFolderRequest extends BaseServiceRequest {

    constructor(columnsetId) {
        super();
        super.addRequestUri("columnsetfolder.pb");
        super.addRequestParameter("c", columnsetId.toString());
    }

    getMethod() {
        return HttpMethodType.DELETE;
    }

    getSubSystem() {
        return SubSystemType.LIST;
    }

    getMessageName() {
        return "Response";
    }
}

export default RemoveColumnSetFolderRequest;
