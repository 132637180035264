import _ from 'underscore';
import CommonDialog from "../../../../../RayCustomControls/CommonDialog/CommonDialog";
import { connect } from 'react-redux';
import LocalizationStore from 'LocalizationStore';
import React from "react";
import SettingsAction from "SettingsAction";
import { changeSource, onOpenNewsSettingDialog, restoreDefaultNews, submitNewsChanges } from "../../../../../Actions/RelatedInfoPanelActions/RiPanelActions";
import { RiPanelNewsTransLateHelper, TranslateHelper } from '../../../../../Utils/TranslateHelper';

const RiPanelNewsSettingDialog = (props) => {
    const { chosenIds, userInfo, isOpen } = props;

    const submitChanges = () => {
        const newIds = chosenIds
        props.submitNewsChanges(newIds)
        SettingsAction.saveUserSettings();
    }

    const restoreDefaultChosenIDs = () => {
        props.restoreDefaultNews();
    }

    const hideModal = () => {
        const defaultIds = [2, 3]
        props.onOpenNewsSettingDialog(false, defaultIds);
    }

    const changeNewsSource = (newsId) => {
        let newChosenIds = chosenIds;
        if (newsId) {
            if (_.find(newChosenIds, (c) => c === newsId)) {
                newChosenIds = newChosenIds.filter((v) => v !== newsId);
            } else {
                newChosenIds = [...newChosenIds, newsId];
            }
            props.changeSource(newChosenIds)
        }
    }

    const getModalContentView = () => {
        const user = userInfo;
        const newsSources = user ? user.newsSources : null;
        if (!newsSources) { return null; }

        newsSources.forEach((item) => {
            item.isSelected = false;
            chosenIds.forEach((chosenItem) => {
                if (item.id === chosenItem) {
                    item.isSelected = true;
                }
            });
        });
        const newsOptions = newsSources.map((news) =>
            <div className="news-sources-item" key={news.id}>
                <input type="checkbox" onChange={() => changeNewsSource(news.id)} checked={news.isSelected} />
                <label onClick={() => changeNewsSource(news.id)}>{news.id === 5 ? LocalizationStore.getTranslatedData(`news_id${news.id}`, `${news.name}™`) : LocalizationStore.getTranslatedData(`news_id${news.id}`, news.name)}</label>
            </div>
        );
        return (
            <div className="news-settings-content">
                <div className="news-top-title">{RiPanelNewsTransLateHelper.NEWS_TYPE_SELECT}</div>
                <div className="news-sources-title">
                    {RiPanelNewsTransLateHelper.THIRD_PARTY_NEWS}
                    <span className="text">{RiPanelNewsTransLateHelper.RI_PANEL_NEWS_APPEARS}</span>
                </div>
                {newsOptions}
                <div className="news-restore-link" onClick={restoreDefaultChosenIDs}>
                    {TranslateHelper.Restore_Defaults}
                </div>
                <div className="clearfix"></div>
            </div>)
    }

    const contentModel = getModalContentView();
    return (
        <>
            <CommonDialog preClass="news-settings"
                isOpen={isOpen}
                title={RiPanelNewsTransLateHelper.NEWS_SETTINGS}
                hideModal={hideModal}
                backButtonShow={false}
                cancelButtonShow={true}
                submitButtonShow={true}
                submitHandler={submitChanges}>
                {contentModel}
            </CommonDialog>
        </>
    )
}
const mapStateToProps = ({ RelatedInfoPanelReducers }) => {
    const { chosenIds, userInfo, isOpen } = RelatedInfoPanelReducers.RiPanelNewsInfo;
    return { chosenIds, userInfo, isOpen };
};

const mapDispatchToProps = (dispatch) => ({
    submitNewsChanges: (newIds) => dispatch(submitNewsChanges(newIds)),
    restoreDefaultNews: () => dispatch(restoreDefaultNews()),
    onOpenNewsSettingDialog: (defaultIds) => dispatch(onOpenNewsSettingDialog(false, defaultIds)),
    changeSource: (newChosenIds) => dispatch(changeSource(newChosenIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(RiPanelNewsSettingDialog)
