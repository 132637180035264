export const ActionTypes = {
    GET_SCHEDULED_LISTS: "GET_SCHEDULE_LISTS",
    UPSERT_SCHEDULED_LIST: "UPSERT_SCHEDULE_LIST",
    DELETE_SCHEDULED_LIST: "DELETE_SCHEDULED_LIST",
    INIT_SCHEDULED_LIST_FORM_DATA: "INIT_SCHEDULED_LIST_FORM_DATA",
}


export const ModelTypes = {
        ScheduledList: "ScheduledList",
        UpsertScheduleList: "UpsertScheduleList"
}


export const PopupTypes = {
    Universe: "Universe",
    Geography: "Geography",
    TimeSelector: "TimeSelector",
    Frequency: "Frequency",
    Columnset: "Columnset",
    Group: "Group"
}